import React from 'react'
import compact from 'lodash/compact'
import isEqual from 'lodash/isEqual'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { isDefined } from '../../../utils/functions'
import { useGet } from '../../../hooks/useNewAPI'

import Badge from '../../Badge'
import Button from '../../Button'
import Flex from '../../Flex'
import Glyph from '../../../components/Glyph'
import State from '../../State'

import { FilterDropdown } from './FilterDropdown'
import { FilterDropdownGroup } from '../filters/FilterDropdownGroup'
import { PopoverItem } from './PopoverItem'

import { useStore } from '../useStore'

const CONDITIONS: any = {
  in: 'includes',
  not_in: 'not includes',
}

const CONDITION_GLYPHS: any = {
  in: 'between',
  not_in: 'not_equal',
}

export const GlobalTagsFilter = (props: any) => {
  const { config, onUpdate, filter, filterKey } = props

  const initialCondition = Object.keys(CONDITIONS).includes(filter?.condition) ? filter?.condition : 'in'

  const [isOpen, setIsOpen] = React.useState(false)
  const [tagIds, setTagsIds]: any = React.useState(filter?.value || [])

  const [condition, setCondition] = React.useState(initialCondition)
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  const removeFilter: any = useStore((state: any) => state.removeFilter)

  const { data, isLoading }: any = useGet({
    name: compact(['global-tag-groups', config?.identifier]),
    url: `/global_tag_groups`,
    ...(config?.identifier && {
      params: {
        identifier: config?.identifier,
      },
    }),
  })

  const isEmpty = size(data) === 0

  const activeTags = React.useMemo(() => {
    const result: any = []
    const tagsMap: any = {}

    if (size(filter?.value) === 0 || size(data) === 0) return result

    for (const tagGroup of data) {
      if (!tagGroup.global_tags) continue

      for (const tag of tagGroup.global_tags) {
        tagsMap[tag.id] = tag
      }
    }

    for (const id of filter.value) {
      if (!tagsMap?.[id]) continue

      result.push(tagsMap[id])
    }

    return result
  }, [data, filter])

  const activeTagsCount = size(activeTags)
  const hasActiveTags = activeTagsCount >= 1

  const handleApply = () => {
    onUpdate?.({ condition, value: tagIds })
    setIsOpen(false)
  }

  const handleClear = () => {
    setTagsIds([])
    removeFilter(filterKey)
    setIsOpen(false)
  }

  React.useEffect(() => {
    if (!isEqual(tagIds, filter?.value)) setTagsIds(filter?.value || [])
  }, [filter?.value])

  React.useEffect(() => {
    if (isDefined(filter?.condition) && !isEqual(condition, filter?.condition)) setCondition(filter?.condition)
  }, [filter?.condition])

  return (
    <div className="flex items-center flex-nowrap">
      <FilterDropdown
        label={CONDITIONS[condition]}
        hasActiveFilters={hasActiveTags}
        open={isDropdownOpen}
        className="mr-2"
        onOpenChange={setIsDropdownOpen}
      >
        {Object.keys(CONDITIONS).map((key) => (
          <PopoverItem
            key={key}
            title={CONDITIONS[key]}
            isActive={condition === key}
            onClick={() => {
              setCondition(key)
              setIsDropdownOpen(false)

              if (size(tagIds) > 0 && !isEqual(key, filter?.condition)) {
                onUpdate?.({ condition: key, value: tagIds })
              }
            }}
            graphic={<Glyph glyph={CONDITION_GLYPHS[key]} color={COLORS.textMuted} size={16} />}
          />
        ))}
      </FilterDropdown>

      <FilterDropdown
        className="grow"
        open={isOpen}
        onOpenChange={(newIsOpen: boolean) => {
          // apply filter when dropdown is closed and values are different
          if (!newIsOpen && !isEqual(tagIds, filter?.value)) {
            onUpdate?.({ condition, value: tagIds })
          }

          if (size(tagIds) === 0) removeFilter(filterKey)

          setIsOpen(newIsOpen)
        }}
        testKey="global_tags_filter_dropdown"
        isEmpty={isEmpty}
        isLoading={isLoading}
        hasActiveFilters={hasActiveTags}
        label={
          activeTagsCount ? (
            <>
              <Badge size={16} children={activeTagsCount} className="mr-1" /> selected
            </>
          ) : (
            'Select…'
          )
        }
      >
        {!data || isLoading || isEmpty ? (
          <State title="Tags" isEmpty={isEmpty} isLoading={isLoading} emptyDescription="No tags created yet" />
        ) : (
          data?.map((tagGroup: any) => {
            if (size(tagGroup?.global_tags) === 0) return null

            return (
              <FilterDropdownGroup key={tagGroup.id} label={tagGroup.name}>
                {tagGroup.global_tags.map((tag: any) => (
                  <PopoverItem
                    key={tag.id}
                    title={tag.name}
                    graphic={<Glyph glyph={tag.glyph} size={14} color={tag.color} />}
                    isActive={tagIds.includes(tag.id)}
                    onClick={() => {
                      setTagsIds((currentValue: any = {}) => {
                        return produce(currentValue, (draft: any) => {
                          if (draft.includes(tag.id)) {
                            const index = draft.indexOf(tag.id)
                            draft.splice(index, 1)
                            return
                          }

                          draft.push(tag.id)
                        })
                      })
                    }}
                  />
                ))}
              </FilterDropdownGroup>
            )
          })
        )}

        {size(data) > 0 && (
          <div className="flex items-center px-2 py-2 sticky bottom-0 bg-white border-t border-0 border-solid border-divider overflow-hidden">
            <Button
              label="Apply"
              glyph="tick_circle"
              color="green"
              type="primary"
              size={100}
              className="flex-grow-3"
              onClick={handleApply}
            />
            <Button
              label="Clear All"
              glyph="cross"
              color="red"
              size={100}
              className="flex-grow-0 ml-2"
              isDisabled={size(tagIds) === 0}
              onClick={handleClear}
            />
          </div>
        )}
      </FilterDropdown>
    </div>
  )
}
