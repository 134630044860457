import React from 'react'
import size from 'lodash/size'

import { countWord, usDate } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import IntakeStatus from '../../components/Statuses/IntakeStatus'
import Table from '../../components/Table/Table'
import TableCell from '../../components/Table/TableCell'
import TableFilter from '../../components/Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    width: 250,
    accessor: 'title',
    Header: 'Title',
    Cell: ({ value, row }) => {
      const label = value || 'Treatment Episode'

      return <TableCell.MainLink to={to(row.original.id)} label={label} />
    },
  },
  {
    width: 200,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <IntakeStatus status={value} />,
  },
  {
    width: 200,
    accessor: 'insurance_authorizations',
    Header: 'Authorizations',
    Cell: ({ value }: any) => <TableCell value={value && countWord('Authorizations', size(value))} />,
  },
  {
    width: 200,
    accessor: 'accepted_at',
    Header: 'Accept Date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'estimated_arrival',
    Header: 'Est. Arrival Date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'admitted_at',
    Header: 'Admit Date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'estimated_discharge',
    Header: 'Est. Discharge Date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'discharged_at',
    Header: 'Discharge Date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'incoming_notes',
    Header: 'Incoming Notes',
    Cell: TableCell.Truncated,
  },
  // {
  //   width: 200,
  //   accessor: 'referral_in.name',
  //   Header: 'Referral In',
  // },
  // {
  //   width: 200,
  //   accessor: 'referral_in_notes',
  //   Header: 'Referral In Notes',
  //   Cell: TableCell.Truncated,
  // },
  // {
  //   width: 200,
  //   accessor: 'referral_in_contact.name',
  //   Header: 'Referral In Contact',
  // },
  // {
  //   width: 200,
  //   accessor: 'referral_out.name',
  //   Header: 'Referral Out',
  // },
  // {
  //   width: 200,
  //   accessor: 'referral_out_notes',
  //   Header: 'Referral Out Notes',
  //   Cell: TableCell.Truncated,
  // },
  // {
  //   width: 200,
  //   accessor: 'referral_out_contact.name',
  //   Header: 'Referral Out Contact',
  // },
  {
    width: 200,
    accessor: 'waitlist_notes',
    Header: 'Waitlist Notes',
    Cell: TableCell.Truncated,
  },
  {
    width: 200,
    accessor: 'no_show_notes',
    Header: 'No Show Notes',
    Cell: TableCell.Truncated,
  },
  {
    width: 200,
    accessor: 'discharge_notes',
    Header: 'Discharge Notes',
    Cell: TableCell.Truncated,
  },
  {
    width: 200,
    accessor: 'notes',
    Header: 'Additional Information',
    Cell: TableCell.Truncated,
  },
  {
    width: 180,
    accessor: 'accepted_by.name',
    Header: 'Accepted By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.accepted_by?.avatar} name={value} />,
  },
  {
    width: 180,
    accessor: 'admitted_by.name',
    Header: 'Admitted By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.admitted_by?.avatar} name={value} />,
  },
  {
    width: 180,
    accessor: 'discharged_by.name',
    Header: 'Discharged By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.discharged_by?.avatar} name={value} />,
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 220,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
]

export const TreatmentEpisodesTable = (props: any) => {
  const {
    data,
    emptyDescription = 'No treatment episodes have been added yet',
    icon = 'treatment_episodes',
    localStorageKey = 'treatment_episodes',
    title = 'Treatment Episodes',
    to,
    ...rest
  } = props

  const { timezone } = useSettings()

  return (
    <Table
      columns={columns(to, timezone)}
      data={data}
      emptyDescription={emptyDescription}
      icon={icon}
      localStorageKey={localStorageKey}
      title={title}
      {...rest}
    />
  )
}
