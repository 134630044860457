import React from 'react'

import Select from './Select'
import Option from './Option'

import { ADMISSION_TYPE } from '../../utils/constants'

const AdmissionTypeSelect = ({ isEditable, validations, onUpdate, value, model = 'admission_type_code' }: any) => (
  <Select
    fullWidth
    allowEmpty
    isEditable={isEditable}
    label="Admission Type"
    model={model}
    validations={validations}
    onUpdate={onUpdate}
    value={value}
    defaultValue="2"
  >
    {Object.keys(ADMISSION_TYPE).map((level: any) => (
      <Option label={ADMISSION_TYPE[level]} value={level} />
    ))}
  </Select>
)

AdmissionTypeSelect.defaultProps = {
  label: 'Admission Source',
  model: 'admission_type',
}

export default AdmissionTypeSelect
