import React from 'react'
import { transparentize, opacify, tint, darken } from 'polished'
import { useDispatch } from 'react-redux'

import { queueUpload } from '../../actions/common'
import Upload from '../../modules/upload'
import { COLORS } from '../../theme'

import Icon from '../../components/Icon'
import Glyph from '../../components/Glyph'

type Props = {
  category: string
  subcategory: string
  isDisabled: boolean
  isEditable: boolean
  acceptMultiple: boolean
}

const DnDEobsUploader: React.FC<Props> = ({ isDisabled, isEditable, acceptMultiple }) => {
  const dispatch = useDispatch()

  const onChange = (event: any) => {
    const options = {
      name: 'insurance_eobs',
      url: '/insurance_eobs',
      queryKey: 'insurance_eobs',
    }

    const files = Upload.queue({ files: event.target.files, model: 'upload', options })
    dispatch(queueUpload(files))

    Upload.start()
  }

  const canUpload = isEditable && !isDisabled
  const icon = canUpload ? 'files' : 'permissions'
  const title = canUpload ? 'Drag & Drop Files' : 'Uploading Blocked'
  const description = canUpload ? 'Or click here to upload your files' : 'You do not have permission to edit these files'

  return (
    <div data-test="dnd_uploader" css={styles.root} className={isDisabled ? 'is-disabled' : ''}>
      <div>
        {canUpload && <input type="file" multiple={acceptMultiple} onChange={onChange} css={styles.input} />}

        {canUpload ? <Glyph glyph="add_file" /> : <Icon icon={icon} />}

        <h3 css={styles.title}>{title}</h3>
        <p css={styles.description}>{description}</p>
      </div>
    </div>
  )
}

const styles: Record<string, any> = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    textAlign: 'center',
    minHeight: 200,
    padding: '2rem 1rem',
    // marginTop: '1rem',
    borderRadius: '5px',
    border: `2px dashed ${opacify(0.25, COLORS.divider)}`,
    background: transparentize(0.5, COLORS.divider),
    position: 'relative',
    flex: '0 0 auto',

    '&:hover': {
      borderColor: COLORS.blue,
      background: `${tint(0.95, COLORS.blue)}`,
    },

    '&:active': {
      borderColor: darken(0.1, COLORS.blue),
      background: `${tint(0.9, COLORS.blue)}`,
    },

    '&.is-disabled': {
      border: `2px dashed ${transparentize(0.1, COLORS.divider)} !important`,
      background: 'transparent !important',
    },
  },

  input: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    border: '1px solid #000',
    opacity: '0',
    cursor: 'pointer',
  },

  title: {
    margin: '0.5em 0 0',
    fontSize: '1.05rem',
    fontWeight: 600,
  },

  description: {
    color: COLORS.textMuted,
    margin: '0.25em 0 0',
  },
}

DnDEobsUploader.defaultProps = {
  acceptMultiple: true,
}

export default DnDEobsUploader
