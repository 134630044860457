import { RRule } from 'rrule'
import { DT, addDays, addWeeks, addMonths } from './functions'

export const RECURRING_DAYS = {
  weekly: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  biweekly: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  monthly: [
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
    '13th',
    '14th',
    '15th',
    '16th',
    '17th',
    '18th',
    '19th',
    '20th',
    '21st',
    '22nd',
    '23rd',
    '24th',
    '25th',
    '26th',
    '27th',
    '28th',
  ],
}

export const RECURRING_FREQUENCIES = {
  monthly: {
    label: 'Monthly',
    description: 'Every month',
  },
  biweekly: {
    label: 'Biweekly',
    description: 'Every two weeks',
  },
  weekly: {
    label: 'Weekly',
    description: 'Every week',
  },
  daily: {
    label: 'Daily',
    description: 'Every day',
  },
}

export const getScheduleRrule = ({ count, countPeriod, startDate, endDate, frequency, dueDay }: any) => {
  if (!startDate) throw new Error('Please provide a valid start date')

  let until = endDate
  if (countPeriod === 'days') until = addDays(startDate, count)
  if (countPeriod === 'weeks') until = addWeeks(startDate, count)
  if (countPeriod === 'months') until = addMonths(startDate, count)

  return new RRule({
    interval: 1,
    freq: RRule.DAILY,
    dtstart: DT(startDate).toUTC().toJSDate(),
    until: DT(until).toUTC().toJSDate(),

    ...(frequency === 'weekly' && {
      freq: RRule.WEEKLY,
      byweekday: parseInt(dueDay) || 0,
    }),

    ...(frequency === 'biweekly' && {
      interval: 2,
      freq: RRule.WEEKLY,
      byweekday: parseInt(dueDay) || 0,
    }),

    ...(frequency === 'monthly' && {
      freq: RRule.MONTHLY,
      bymonthday: parseInt(dueDay) || 1,
    }),
  })
}

export const getRrule = ({ count, frequency, dueDay, startDate, endDate, countPeriod }: any) => {
  let until = endDate
  if (countPeriod === 'days') until = addDays(startDate, count)
  if (countPeriod === 'weeks') until = addWeeks(startDate, count)
  if (countPeriod === 'months') until = addMonths(startDate, count)

  return new RRule({
    interval: 1,
    freq: RRule.DAILY,
    until: DT(until).toUTC().toJSDate(),

    ...(frequency === 'weekly' && {
      freq: RRule.WEEKLY,
      byweekday: parseInt(dueDay) || 0,
    }),

    ...(frequency === 'biweekly' && {
      interval: 2,
      freq: RRule.WEEKLY,
      byweekday: parseInt(dueDay) || 0,
    }),

    ...(frequency === 'monthly' && {
      freq: RRule.MONTHLY,
      bymonthday: parseInt(dueDay) || 1,
    }),
  })
}
