import React from 'react'
import startCase from 'lodash/startCase'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const ClientsCommunicationsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Contact Name',
        id: 'contact',
        model: 'data.contact.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.data.contact?.name} avatar={data.data.contact?.avatar} to={to?.(data.data)} />
        ),
      },
      {
        width: 180,
        id: 'customer',
        model: 'data.customer.name',
        title: 'Contact For',
        type: 'profile',
      },
      {
        width: 180,
        id: 'author',
        model: 'data.author.name',
        title: 'Added By',
        type: 'profile',
      },
      {
        width: 140,
        id: 'category',
        model: 'data.category',
        title: 'Contact Method',
        formatValue: ({ data, value }: any) => startCase(value),
        // filterOptions: [
        //   {
        //     value: 'phone',
        //     label: 'Phone',
        //   },
        //   {
        //     value: 'email',
        //     label: 'Email',
        //   },
        //   {
        //     value: 'text_message',
        //     label: 'Text Message',
        //   },
        //   {
        //     value: 'meeting',
        //     label: 'Meeting',
        //   },
        //   {
        //     value: 'web',
        //     label: 'Web',
        //   },
        //   {
        //     value: 'other',
        //     label: 'Other',
        //   },
        // ],
      },
      {
        width: 220,
        id: 'reason',
        model: 'data.reason',
        title: 'Notes',
      },
      {
        width: 200,
        id: 'contacted_at',
        model: 'data.contacted_at',
        title: 'Contacted At',
        type: 'date_time',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Client Communications"
      icon="communications"
      columns={columns}
      filtersConfig={FILTERS.client_communications}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="client_communications"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=client_communications'}
        />
      }
      {...props}
    />
  )
}
