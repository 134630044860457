import React from 'react'
import { DateTime } from 'luxon'
import { transparentize } from 'polished'
import compact from 'lodash/compact'
import groupBy from 'lodash/groupBy'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

import { formatURL, usDate } from '../../../../utils/functions'
import { useOverlay } from '../../../../hooks/useOverlay'

import Card from '../../../Card'
import Button from '../../../Button'
import DataList from '../../../DataList'
import Nav from '../../../Nav'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import PageGrid from '../../../PageGrid'
import ScrollMenu from '../../../ScrollMenu'
import ScrollView from '../../../ScrollView'
import Section from '../../../Section'
import Status from '../../../Status'
import SummonOverlay from '../../../SummonOverlay'
import { useGet } from '../../../../hooks/useNewAPI'

const formatDate = (date: string) => {
  if (!date) return null

  const parsed = DateTime.fromFormat(date, 'yyyyMMdd')
  return usDate(parsed)
}

export const ClaimMD = ({ data, contentAfter }: any) => {
  if (!data) return null

  const summary = data?.insurance_eligibility_summary
  const benefit = summary?.benefit

  const groupedBenefits = groupBy(benefit, 'benefit_description')
  const sortedDescriptions = sortBy(Object.keys(groupedBenefits))

  return (
    <>
      <ScrollView>
        <PageGrid scroll breakpoint={3} className="overlay-page-grid flex-[1_1_auto]">
          <Nav
            top="0"
            breakpoint={3}
            headingSize={300}
            title="Table of Contents"
            background={transparentize(0.4, 'white')}
            className="mg1024:min-w-[300px]"
          >
            <ScrollMenu />
          </Nav>

          <Overlay.Content>
            <Section
              title="Insurance Details"
              aside={
                <SummonOverlay overlay={<PreviewOverlay id={data.id} />}>
                  <Button label="Preview" size={100} display="inline-flex" type="default" glyph="view" />
                </SummonOverlay>
              }
              scrollview={{
                id: 'insurance_details',
                name: 'Insurance Details',
              }}
            >
              <Card className="px-4 py-2">
                <DataList isCompact>
                  <DataList.Item label="Insured Name" value={`${summary?.ins_name_f} ${summary?.ins_name_l}`} />
                  <DataList.Item label="Policy #" value={summary?.ins_number} />
                  <DataList.Item label="Group #" value={summary?.group_number} />
                  <DataList.Item label="Plan #" value={summary?.plan_number} />
                  <DataList.Item label="Insured DOB" value={formatDate(summary?.ins_dob)} />
                  <DataList.Item label="Insured Gender" value={summary?.ins_sex} />
                  <DataList.Item
                    label="Insured Address"
                    value={
                      <div>
                        <div>{summary?.ins_addr_1}</div>
                        <div>
                          {summary?.ins_city}, {summary?.ins_state}, {summary?.ins_zip}
                        </div>
                      </div>
                    }
                  />
                  {summary?.plan_begin_date && (
                    <DataList.Item
                      label="Plan Begin"
                      value={summary?.plan_begin_date
                        .split('-')
                        .map((date: any) => formatDate(date))
                        .join(' – ')}
                    />
                  )}
                </DataList>
              </Card>
            </Section>

            {sortedDescriptions.map((description) => {
              const benefits = groupedBenefits[description]

              return (
                <Section
                  title={description}
                  scrollview={{
                    id: description,
                    name: description,
                  }}
                  key={description}
                >
                  <Card className="px-4 py-4 grid gap-6">
                    <BenefitList benefits={benefits} />
                  </Card>
                </Section>
              )
            })}

            {contentAfter}
          </Overlay.Content>
        </PageGrid>
      </ScrollView>
    </>
  )
}

const BenefitList = ({ benefits }: any) => {
  const sections = React.useMemo(() => {
    let activeCoverage = null

    const individual: any = []
    const individualInPlan: any = []
    const individualOutOfPlan: any = []

    const family: any = []
    const familyInPlan: any = []
    const familyOutOfPlan: any = []

    for (const benefit of benefits) {
      const { benefit_coverage_code, inplan_network, benefit_level_code } = benefit

      if (benefit_coverage_code === '1' || benefit_coverage_code === 'U') {
        activeCoverage = benefit
        continue
      }

      if (benefit_level_code === 'IND') {
        if (inplan_network === 'W') {
          individual.push(benefit)
          continue
        }

        if (inplan_network === 'Y') {
          individualInPlan.push(benefit)
          continue
        }

        if (inplan_network === 'N') {
          individualOutOfPlan.push(benefit)
          continue
        }
      }

      if (benefit_level_code === 'FAM') {
        if (inplan_network === 'W') {
          family.push(benefit)
          continue
        }

        if (inplan_network === 'Y') {
          familyInPlan.push(benefit)
          continue
        }

        if (inplan_network === 'N') {
          familyOutOfPlan.push(benefit)
          continue
        }
      }
    }

    return { activeCoverage, individual, individualInPlan, individualOutOfPlan, family, familyInPlan, familyOutOfPlan }
  }, [benefits])

  const { activeCoverage, individual, individualInPlan, individualOutOfPlan, family, familyInPlan, familyOutOfPlan } = sections

  return (
    <>
      {activeCoverage && <ActiveCoverageSection data={activeCoverage} />}

      <CoverageSection title="Individual" color="blue" data={individual} />

      {size(individualInPlan) > 0 && size(individualOutOfPlan) > 0 && (
        <div className="grid gap-6 grid-cols-1 mq768:grid-cols-2 mq768:gap-5">
          <CoverageSection title="Individual In-Plan-Network" color="green" data={individualInPlan} />
          <CoverageSection title="Individual Out-Of-Plan-Network" color="red" data={individualOutOfPlan} />
        </div>
      )}

      <CoverageSection title="Family" color="purple" data={family} />

      {size(familyInPlan) > 0 && size(familyOutOfPlan) > 0 && (
        <div className="grid gap-6 grid-cols-1 mq768:grid-cols-2 mq768:gap-5">
          <CoverageSection title="Family In-Plan-Network" color="green" data={familyInPlan} />
          <CoverageSection title="Family Out-Of-Plan-Network" color="red" data={familyOutOfPlan} />
        </div>
      )}
    </>
  )
}

const ActiveCoverageSection = ({ data }: any) => {
  const items = React.useMemo(() => {
    const result: any = []

    if (!data) return result

    if (data.insurance_plan) {
      result.push(<h4 className="mt-2">{data.insurance_plan}</h4>)
    }

    if (data.date_of_last_update) {
      result.push(<DataList.Item label="Date of Last Update" value={formatDate(data.date_of_last_update)} />)
    }

    if (Array.isArray(data.entity_description) && size(data.entity_description) > 0) {
      result.push(
        <DataList.Item
          label={data.entity_description[0]}
          value={
            <div>
              {data?.entity_name && (
                <div>
                  <div>{data?.entity_name?.[0]}</div>
                </div>
              )}

              {data?.entity_addr_1 && (
                <div>
                  <div>{data?.entity_addr_1?.[0]}</div>
                </div>
              )}

              {data?.entity_city && (
                <div>
                  <div>{compact([data?.entity_city?.[0], data?.entity_state?.[0], data?.entity_zip?.[0]]).join(', ')}</div>
                </div>
              )}

              {data?.entity_website?.[0] && (
                <a href={formatURL(data.entity_website[0])} target="_blank" rel="noreferrer">
                  {data.entity_website[0]}
                </a>
              )}
            </div>
          }
        />,
      )
    }

    if (data.benefit_notes) {
      result.push(<div className="text-text-muted tracking-[0.5px] mt-1">{data.benefit_notes}</div>)
    }

    return result
  }, [data])

  return (
    <div>
      <Status label={`${data.insurance_type_description || ''} ${data.benefit_coverage_description}`} color="orange" />

      {size(items) > 0 && (
        <DataList isCompact labelWidth={180}>
          {items.map((item: any) => {
            return item || null
          })}
        </DataList>
      )}
    </div>
  )
}

const CoverageSection = ({ data, title, color }: any) => {
  if (size(data) === 0) return null

  return (
    <div>
      <Status label={title} color={color || 'gray'} className="mb-1" />

      <DataList isCompact labelWidth={180}>
        {data.map((item: any) => {
          return (
            <>
              {item.benefit_coverage_description && (
                <DataList.Item
                  label={item.benefit_coverage_description}
                  value={
                    <div>
                      {!isUndefined(item.benefit_amount) && (
                        <div>
                          <b>${item.benefit_amount}</b>{' '}
                          {item.benefit_period_description !== 'Remaining' && <span className="text-text-muted opacity-50">/</span>}{' '}
                          {item.benefit_period_description}
                        </div>
                      )}

                      {!isUndefined(item.benefit_percent) && (
                        <div>
                          <b>{item.benefit_percent}%</b>{' '}
                          {item.benefit_period_description !== 'Remaining' && <span className="text-text-muted opacity-50">/</span>}{' '}
                          {item.benefit_period_description}
                        </div>
                      )}

                      {!isUndefined(item.benefit_qnty) && (
                        <div>
                          <b>{item.benefit_qnty}</b>{' '}
                          {item.benefit_period_description !== 'Remaining' && <span className="text-text-muted opacity-50">/</span>}{' '}
                          {item.benefit_period_description}
                        </div>
                      )}

                      {item.benefit_notes && <div className="text-text-muted text-[0.94em] opacity-80 mt-1">{item.benefit_notes}</div>}
                    </div>
                  }
                />
              )}
            </>
          )
        })}
      </DataList>
    </div>
  )
}

const PreviewOverlay = (props: any) => {
  const { close } = useOverlay({ name: 'preview_eligibility' })

  const { data, isLoading } = useGet({
    name: 'preview_eligibility',
    url: `/insurance_evobs/${props.id}/preview_eligibility`,
  })

  if (isLoading) return <OverlayLoader position="right" />

  return (
    <Overlay showBackdrop closeOnBackdrop position="right" onClose={props.onClose}>
      <Overlay.Header glyph="view" title="Preview" />

      <Overlay.Content className="p-4">
        <div dangerouslySetInnerHTML={{ __html: data?.html }}></div>
      </Overlay.Content>
    </Overlay>
  )
}
