import React from 'react'
import { opacify } from 'polished'

import { COLORS } from '../../theme'

import Flex from '../Flex'
import Grid from '../Grid'
import Icon from '../Icon'
import State from '../State'
import Overlay from '../Overlay'
import Type from '../Typography/Type'
import Markup from '../Markup'

import { NotionRenderer } from '../Notion/NotionRenderer'

import { beautifulAmount, notionIDFormURL } from '../../utils/functions'
import { PRICING_UNIT_TYPES, ALL_FEATURES } from './constants'
import { request } from '../../modules/axios'

const FeaturesOverlay = ({ onClose, product }: any) => {
  const [notionData, setNotionData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const [notionID, setNotionID] = React.useState(null)

  const hasNotionPage = !!product?.notion_url

  const price = product.pricing_type !== 'free' ? parseFloat(product.discounted_price || product.price) : 'free'
  let priceSuffix = ''
  if (product.pricing_type === 'monthly') {
    priceSuffix = '/ Month'
  } else if (product.pricing_type === 'metered_monthly') {
    priceSuffix = PRICING_UNIT_TYPES[product.price_unit_type]
  }

  const features = product?.features
    ? Object.keys(product.features)
        .filter((key) => product.features[key])
        .sort()
    : []

  React.useEffect(() => {
    const getNotionData = async (notionID: string) => {
      setIsLoading(true)

      try {
        const response = await request.get(`${process.env.BH_NOTION_API_BASE}/api/page/${notionID}`, {
          headers: null,
        })
        setNotionData(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (hasNotionPage) getNotionData(notionID)
  }, [notionID])

  React.useEffect(() => {
    if (!hasNotionPage) return

    const id = notionIDFormURL(product.notion_url)
    if (id) setNotionID(id)
  }, [product])

  if (!product) return null

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={onClose} maxWidth={50}>
      <Overlay.Content css={styles.overlayContent}>
        {product.icon && (
          <div css={styles.circleIcon}>
            <Icon icon={product.icon} size={28} />
          </div>
        )}

        <div css={styles.header}>
          <Type as="h2" variant="CAPS_TITLE">
            {product.public_name}
          </Type>

          <div css={styles.price}>
            <Type as="span" variant="H3" css={styles.amount}>
              {price === 'free' ? 'Free' : beautifulAmount(price)}
            </Type>

            {priceSuffix && (
              <Type as="span" variant="TEXT_SMALL" color="textMuted">
                {' '}
                {priceSuffix}
              </Type>
            )}
          </div>
        </div>

        {product.description && !hasNotionPage && (
          <Type as="p" variant="TEXT_SMALL" color="textMuted">
            <Markup value={product.description} />
          </Type>
        )}

        {hasNotionPage ? (
          <div css={{ minHeight: 35 }}>
            {isLoading && !notionData ? (
              <State isLoading css={{ minHeight: 35 }} />
            ) : (
              <NotionRenderer disableHeader fullWidth recordMap={notionData} darkMode={false} />
            )}
          </div>
        ) : (
          product.features && (
            <Grid gap="1rem" columns="100%">
              {features.map((feature) => (
                <Flex centerY nowrap key={feature.model}>
                  <Icon icon={ALL_FEATURES[feature]?.icon} size={20} css={styles.featureIcon} />
                  <Type as="div" variant="TEXT" css={styles.featureTitle}>
                    {ALL_FEATURES[feature]?.title || feature}
                  </Type>
                </Flex>
              ))}
            </Grid>
          )
        )}
      </Overlay.Content>
    </Overlay>
  )
}

const styles = {
  overlayContent: {
    padding: '2rem',
  },

  header: {
    textAlign: 'center',
    marginBottom: '2rem',
  },

  circleIcon: {
    width: '3rem',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: COLORS.white,
    borderRadius: 500,

    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1rem',

    boxShadow: `
      0 0 0 1px ${opacify(-0.05, COLORS.divider)},
      0 3px 0 1px ${opacify(-0.07, COLORS.divider)}
    `,
  },

  price: {
    marginTop: '0.25rem',
  },

  amount: {
    textTransform: 'capitalize',
  },

  featureTitle: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  featureIcon: {
    marginRight: '0.25rem',
  },
}

export default FeaturesOverlay
