import React from 'react'

import { COLORS } from '../../theme'
import withHasPermissions from '../../hocs/withHasPermissions'

import Avatar from '../Avatar'
import Link from '../Link'
import SummonOverlay from '../SummonOverlay'

import { AdmissionQuickView, ClientQuickView, EmployeeQuickView, OrganizationQuickView, PropertyQuickView } from '../Overlays/quick'

const AvatarLink = ({ to, onClick, avatar, label }: any) => {
  const Tag = to ? Link : 'div'

  return (
    <Tag to={to} onClick={onClick} css={styles.root} className={to || onClick ? 'as-link' : ''}>
      <Avatar magnify src={avatar} size={20} css={styles.graphic} initials={label} />
      <span>{label}</span>
    </Tag>
  )
}

// TODO (Andrei): remove default hasPermission
const ResourceQuickLink = ({ resource, hasPermission = false, disableLink }: any) => {
  if (!(resource && resource.type)) return null

  // show plain text name if link is used outside the EHR app
  // or if user doesn't have permission to view the resource
  if (!hasPermission || disableLink) return <b>{resource.name}</b>

  // render quick view summon overlays based on resource type
  if (resource.type === 'resident') {
    if (resource.status === 'current' || resource.status === 'alumni') {
      return (
        <SummonOverlay overlay={<ClientQuickView client={resource} />}>
          <AvatarLink avatar={resource.avatar} label={resource.name} />
        </SummonOverlay>
      )
    }

    return (
      <SummonOverlay overlay={<AdmissionQuickView client={resource} />}>
        <AvatarLink avatar={resource.avatar} label={resource.name} />
      </SummonOverlay>
    )
  }

  if (resource.type === 'employee') {
    return (
      <SummonOverlay overlay={<EmployeeQuickView employee={resource} />}>
        <AvatarLink avatar={resource.avatar} label={resource.name} />
      </SummonOverlay>
    )
  }

  if (resource.type === 'bh_employee') {
    return <AvatarLink avatar={resource.avatar} label={resource.name} />
  }

  if (resource.type === 'organization') {
    return (
      <SummonOverlay overlay={<OrganizationQuickView organization={resource} />}>
        <AvatarLink avatar={resource.avatar} label={resource.name} />
      </SummonOverlay>
    )
  }

  if (resource.type === 'property') {
    return (
      <SummonOverlay overlay={<PropertyQuickView property={resource} />}>
        <AvatarLink avatar={resource.avatar} label={resource.name} />
      </SummonOverlay>
    )
  }

  if (resource.type === 'contact') {
    return <AvatarLink avatar={resource.avatar} label={resource.name} />
  }

  return null
}

const styles = {
  root: {
    display: 'inline-flex',
    alignItems: 'baseline',
    fontWeight: 600,
    flexWrap: 'nowrap',
    margin: '0.2em 0',

    '&.as-link': {
      color: COLORS.blue,
      cursor: 'pointer',
    },
  },

  graphic: {
    marginRight: '0.25em',
    alignSelf: 'center',
  },
}

// TODO (Andrei): enable after adding all permissions
export default withHasPermissions(ResourceQuickLink)
// export default ResourceQuickLink
