import React from 'react'
import { Input } from './Input'
import { withFormContext } from './context'

import { css } from '../../theme'

class NativeColorInput extends Input {}

const STYLES = css({
  input: {
    maxWidth: '4rem',
    padding: '0.25rem !important',
  },
})

NativeColorInput.defaultProps = {
  type: 'color',
  className: STYLES().className,
}

export default withFormContext(NativeColorInput)
