import React from 'react'

import { DEFAULT_FILTERS } from '../Filters/constants'
import withSettings from '../../hocs/withSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { VariableKey } from '../FormBuilder/components/VariableKey'

const RootVariablesDataTable = (props: any) => {
  const { title = 'Variables' } = props

  const columns = React.useMemo(
    () => [
      {
        width: 260,
        isSticky: true,
        disableSort: true,
        canToggleVisible: false,
        title: 'Name',
        model: 'name',
        formatValue: ({ data, value }: any) => (
          <MainCell as={props.mainLinkAs} to={props.to ? props.to(data) : undefined} onClick={props.onClick} value={value} />
        ),
      },
      {
        width: 180,
        title: 'Variable Key',
        model: 'variable_key',
        disableSort: true,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <VariableKey variable={value} />
        },
      },
      {
        title: 'Value',
        model: 'variable_value',
        disableSort: true,
      },
      {
        title: 'Description',
        model: 'description',
        disableSort: true,
      },
      {
        model: 'author',
        title: 'Added By',
        type: 'profile',
        disableSort: true,
      },
      {
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
        disableSort: true,
      },
      {
        model: 'updated_at',
        title: 'Date Updated',
        type: 'date_time',
        disableSort: true,
      },
    ],
    [props.to, props.onClick],
  )

  return (
    <DataTable
      testKey="variables_data_table"
      title={title}
      icon="variables"
      columns={columns}
      // filtersConfig={FILTERS_CONFIG} // ⏰ TODO: add filters
      {...props}
    />
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  variable_key: {
    label: 'Variable Key',
    type: 'string',
  },
  variable_value: {
    label: 'Value',
    type: 'string',
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  author: DEFAULT_FILTERS.author,
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}

export const VariablesDataTable = withSettings(RootVariablesDataTable)
