import React from 'react'
import { useParams } from 'react-router-dom'
import snakeCase from 'lodash/snakeCase'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'
import Permission from '../../components/Permission'

import { TreatmentPlanRecordCategorySelect } from '../TreatmentPlans/form_elements/TreatmentPlanRecordCategorySelect'

const RootTreatmentBookObjectiveOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'treatment-book-objective',
    endpoint: '/treatment_book_objectives',
    invalidate: 'treatment-book-objectives',
    options: props,
  })

  const category = snakeCase(props.category)
  const { timezone } = useSettings()

  const permissionBase = `${category}_treatment_book`

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="treatment_plans" title="Objective" />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && { category }}
        >
          <Section>
            <FormSection layout="vertical">
              <TreatmentPlanRecordCategorySelect isEditable={false} />

              <Input
                vertical
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <MultiObjectSelector
                label="Linked Goals"
                type={`treatment_book_goals.${category}`}
                selectTitle={(data) => data.name || data.description}
                model="treatment_book_goals"
              />

              <Textarea label="Description" model="description" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              glyph="check"
              label="Save"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission={isNew ? `${permissionBase}.create` : `${permissionBase}.edit`}
            />
            {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              label="Edit"
              glyph="edit"
              type="default"
              isDisabled={isDeleting}
              onClick={edit}
              flex="100 1 auto"
              permission={`${permissionBase}.edit`}
            />
            <Permission permission={`${permissionBase}.delete`}>
              <DeleteDialog
                title="Delete Objective?"
                message="Are you sure you want to delete this Objective? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button label="Delete" type="default" glyph="delete" color="red" isLoading={isDeleting} fullWidth />
              </DeleteDialog>
            </Permission>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const TreatmentBookObjectiveOverlay = withOverlayError(RootTreatmentBookObjectiveOverlay)
