import React from 'react'
import { useParams } from 'react-router-dom'
import size from 'lodash/size'

import { BehaveAIChat } from '../../BehaveAI/BehaveAIChat'
import { DropdownMenu, DropdownMenuItem } from '../../../components/DropdownMenu'
import { useCreate, useGet } from '../../../hooks/useNewAPI'
import { useFormBuilder } from '../useFormBuilder'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import State from '../../../components/State'

export const FormsBehaveAIChat = () => {
  const { id }: any = useParams()

  const isNew = id === 'new'
  const chatReferenceId = isNew ? null : id

  const aiChatId: any = useFormBuilder((state: any) => state.aiChatId)
  const setAIChatId: any = useFormBuilder((state: any) => state.setAIChatId)

  const queryKey = ['form', id, 'ai_chats']

  const { data, isLoading }: any = useGet({
    name: queryKey,
    url: `/forms/${id}/ai_chats`,
    options: { enabled: !isNew && !!id, staleTime: 0 },
  })

  const { mutateAsync: createChat, isLoading: isCreatingChat }: any = useCreate({
    name: ['start-new-chat'],
    url: `/ai/chats`,
    invalidate: 'ai_chats',
  })

  const chatsCount = size(data)

  const [currentChat, setCurrentChat] = React.useState<any>(null)

  const startNewChat = async () => {
    try {
      const response: any = await createChat({
        ai_bot_identifier: 'form_builder',
        category: 'form_builder_chat',
        ...(chatReferenceId && {
          reference_type: 'Form',
          reference_id: id,
        }),
      })

      setAIChatId(response.data.id)
    } catch (error) {
      console.error(error)
    }
  }

  // Select initial chat
  React.useEffect(() => {
    if (!!aiChatId || !data || chatsCount === 0) return

    setAIChatId(data[chatsCount - 1].id)
  }, [data, chatsCount, aiChatId])

  return (
    <div className="relative w-full h-full overflow-hidden grid grid-cols-[100%] grid-rows-[100%]">
      {(!aiChatId || isLoading) && (
        <State
          isEmpty={!aiChatId}
          isLoading={isLoading}
          icon="behave_ai"
          title="Behave AI"
          emptyDescription={
            <>
              <div>
                Use the Behave AI chat to
                <br />
                get help with your form
              </div>

              {isNew && (
                <Alert small glyph="info" className="text-left text-text mt-3">
                  Save the form before starting a chat to
                  <br />
                  ensure the chat is linked to this form
                </Alert>
              )}
            </>
          }
          emptyActions={
            <Button label="Start Chat" glyph="chat" type="primary" size={200} onClick={startNewChat} isLoading={isCreatingChat} />
          }
        />
      )}

      <div className="grid grid-cols-[100%] grid-rows-[auto_1fr] overflow-hidden">
        <header className="flex items-center justify-between px-3 py-3 border-b border-0 border-solid border-divider shadow-hard-3">
          <h3 className="flex-[1_1_auto] min-w-0 truncate pr-2">{currentChat?.name || 'Behave AI Chat'}</h3>

          <Flex nowrap centerY gap="0.5rem">
            {chatsCount > 1 && (
              <DropdownMenu
                trigger={
                  <div>
                    <Button
                      label="All Chats"
                      type="default"
                      glyph="menu"
                      size={100}
                      after={<Glyph glyph="triangle_down" size={9} className="!ml-0" />}
                      className="!whitespace-nowrap"
                    />
                  </div>
                }
              >
                {data?.map((chat: any) => (
                  <DropdownMenuItem
                    key={chat.id}
                    glyph="quick_view"
                    isActive={chat.id === aiChatId}
                    onClick={() => setAIChatId(chat.id)}
                    label={chat.name || 'Behave AI Chat'}
                  />
                ))}
              </DropdownMenu>
            )}

            <Button
              glyph="add"
              size={100}
              type="primary"
              onClick={startNewChat}
              isLoading={isCreatingChat}
              label="New Chat"
              className="!whitespace-nowrap"
            />
          </Flex>
        </header>

        {aiChatId && (
          <BehaveAIChat
            key={aiChatId}
            chatId={aiChatId}
            messageClassName="!px-3"
            mainClassName="!pb-40"
            className="min-h-full"
            footerClassName="px-2 absolute bottom-0 w-full"
            onChatUpdate={setCurrentChat}
            allChatsQueryKey={queryKey}
          />
        )}
      </div>
    </div>
  )
}
