import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'
import get from 'lodash/get'

import { fullname } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { clearData } from '@behavehealth/actions/data'
import withBackListener from '@behavehealth/hocs/withBackListener'

import { Status, PageGrid, NavGroup, NavItem, Nav, PageProfile } from '@behavehealth/components'

import PageLoader from '@behavehealth/components/Loaders/PageLoader'

import ActivityTimeline from '../employees/pages/activity_timeline'
import Calendar from '../employees/pages/calendar'
import ClinicalNotes from '../employees/pages/clinical_notes'
import PeerNotes from '../employees/pages/peer_notes'
import CustomNotes from '../employees/pages/custom_notes'
import Files from '../employees/pages/files'
import GeneralInfo from '../employees/pages/general_info'
import ShiftNotes from '../employees/pages/shift_notes'
import TestResults from '../employees/pages/test_results'
import Todos from '../employees/pages/todos/todos'

import { apiGet, apiUpdate, apiDelete } from '@behavehealth/modules/api'

class User extends Component {
  componentDidMount = async () => {
    await apiGet({ name: 'employees', url: `/employees/${this.props.user.id}` })
  }

  componentWillUnmount = () => {
    this.props.clearEmployee()
  }

  deleteEmployee = async () => {
    await apiDelete({ name: 'employees', url: `/employees/${this.props.user.id}` })
    this.props.history.push('/employees')
  }

  toggleBlockEmployee = async (isBlocked) => {
    const { match } = this.props
    if (isBlocked) {
      await apiUpdate({ name: 'employees', url: `/employees/${match.params?.employee_id}/unblock` })
    } else {
      await apiUpdate({ name: 'employees', url: `/employees/${match.params?.employee_id}/block` })
    }
  }

  updatePassword = async (password) => {
    await apiUpdate({
      name: 'employees',
      url: `/employees/${this.props.match.params?.employee_id}/update_password`,
      params: {
        new_password: password,
      },
    })
  }

  render() {
    const { match, data } = this.props
    if (!data) return <PageLoader />

    return (
      <PageGrid breakpoint={3} columns="280px 1fr">
        <Nav
          breakpoint={3}
          headingSize={400}
          record={data}
          title={fullname(data)}
          avatar={data.avatar}
          description={<Status label={data && data.position && data.position.replace('_', ' ')} />}
          desktopProps={{
            icon: 'employees',
            title: 'My Profile',
            headingSize: 300,
            avatar: undefined,
            description: undefined,
          }}
          profile={
            <PageProfile
              record={data}
              status={data.is_blocked && <Status label="Blocked" color="red" />}
              title={fullname(data)}
              description={<Status label={data && data.position && data.position.replace('_', ' ')} />}
            />
          }
        >
          <NavGroup>
            <NavItem feature="general_info" label="General Info" to={`${match.url}/general-info`} plan="lite" />
            <NavItem feature="tasks" label="To-Do" to={`${match.url}/todo`} permission="todos.view" plan="basic" featureFlagV2="todos" />
            <NavItem
              label="Calendar"
              to={`${match.url}/calendar`}
              feature="calendar"
              featureFlag="events"
              permission="events.view"
              plan="pro"
              featureFlagV2="calendar"
            />
            <NavItem
              feature="files"
              label="Files"
              to={`${match.url}/files`}
              featureFlag="files"
              permission="employees.files.view"
              plan="plus"
              featureFlagV2="files"
            />
            <NavItem
              feature="housing_notes"
              label="Shift Notes"
              to={`${match.url}/shift-notes`}
              featureFlag="shift_notes"
              permission="shift_notes.view"
              plan="plus"
              featureFlagV2="shift_notes"
            />
            <NavItem
              feature="custom_notes"
              label="Custom Notes"
              to={`${match.url}/custom-notes`}
              permission="staff_custom_notes.view"
              featureFlagV2="staff_custom_notes"
            />
            <NavItem
              feature="clinical_notes"
              label="Clinical Notes"
              to={`${match.url}/clinical-notes`}
              featureFlag="clinical_notes"
              permission="clinical_notes.view"
              addon="ehr"
              featureFlagV2="clinical_notes"
            />
            <NavItem
              feature="peer_notes"
              label="Peer Notes"
              to={`${match.url}/peer-notes`}
              featureFlag="peer_notes"
              permission="peer_notes.view"
              featureFlagV2="peer_notes"
            />
            <NavItem
              feature="test_results"
              label="Test Results"
              to={`${match.url}/test-results`}
              featureFlag="test_results"
              permission="test_results"
              plan="plus"
              featureFlagV2="test_results"
            />
          </NavGroup>
        </Nav>

        <Switch>
          <Route path={`/me/:resource_id/activity-timeline`} component={ActivityTimeline} />
          <Route path={`/me/:resource_id/general-info`} component={GeneralInfo} />
          <Route path={`/me/:resource_id/shift-notes`} component={ShiftNotes} />
          <Route path={`/me/:resource_id/clinical-notes`} component={ClinicalNotes} />
          <Route path={`/me/:resource_id/peer-notes`} component={PeerNotes} />
          <Route path={`/me/:resource_id/files`} component={Files} />
          <Route path={`/me/:resource_id/todo`} component={Todos} />
          <Route path={`/me/:resource_id/test-results`} component={TestResults} />
          <Route path={`/me/:resource_id/calendar`} component={Calendar} />
          <Route path={`/me/:resource_id/custom-notes`} component={CustomNotes} />

          <Redirect exact from={`/me/:resource_id`} to="/me/:resource_id/general-info" />
        </Switch>
      </PageGrid>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearEmployee: () => dispatch(clearData('employees')),
})

const mapStateToProps = (state, props) => {
  const user = get(state, `me.user`)

  return {
    user,
    data: get(state, `data.employees.data.${user?.id}`),
  }
}

export default withBackListener(connect(mapStateToProps, mapDispatchToProps)(withPageError(User)))
