import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import Avatar from '../../components/Avatar'
import Flex from '../../components/Flex'
import Button from '../../components/Button'
import DropdownItem from '../../components/DropdownItem'
import Roadmap from '../../components/Roadmap/Roadmap'
import RoadmapHeaderRow from '../../components/Roadmap/RoadmapHeaderRow'
import RoadmapRow from '../../components/Roadmap/RoadmapRow'

import { ProgramAssignmentBar, STYLES } from './ProgramsTimeline'

export const ProgramTimeline: React.FC<any> = (props) => {
  const { className, dates, emptyDescription, icon, isEmpty, isLoading, isRefetching, occupanciesByPhaseIds, phases, setDates, title } =
    props

  const match = useRouteMatch()

  const [isAllOpen, setIsAllOpen] = React.useState(true)

  const hasPhases = size(phases) >= 1

  return (
    <Roadmap
      showTodayLine
      className={className}
      isLoading={isLoading}
      isRefetching={isRefetching}
      dates={dates}
      onDatesChange={setDates}
      title={title}
      icon={icon}
      isEmpty={isEmpty}
      emptyDescription={emptyDescription}
    >
      {hasPhases && (
        <>
          <RoadmapHeaderRow className={STYLES.headerRow().className}>
            <Button
              onClick={() => setIsAllOpen((c) => !c)}
              label={`${isAllOpen ? 'Close' : 'Open'} All`}
              size={100}
              glyph={isAllOpen ? 'close_all_rows' : 'open_all_rows'}
              display="inline-block"
              type="minimal"
            />
          </RoadmapHeaderRow>

          {phases.map((phase: any) => {
            // const hasSeats = size(phase.seats) >= 1
            const phaseOccupancies = occupanciesByPhaseIds[phase.id]

            return (
              <RoadmapRow
                expandAsCard
                hideGridLines
                level={1}
                isOpen={isAllOpen}
                title={phase.name}
                icon="program_lists"
                after={
                  <Button
                    label="Add Assignment"
                    color="blue"
                    glyph="chair"
                    type="minimal"
                    size={100}
                    onClick={(event) => {
                      event.stopPropagation()
                      event.nativeEvent.stopImmediatePropagation()
                    }}
                    link={{
                      pathname: `${match.url}/${phase.id}/assign-seats`,
                      parent: match,
                    }}
                    className="!ml-auto"
                  />
                }
                // actions={
                //   <>
                //     <DropdownItem
                //       label="Edit List"
                //       color="blue"
                //       glyph="edit"
                //       link={{
                //         pathname: `${match.url}/${phase.id}`,
                //         parent: match,
                //       }}
                //     />

                //     <DropdownItem
                //       label="Add Seat"
                //       color="blue"
                //       glyph="add"
                //       link={{
                //         pathname: `${match.url}/${phase.id}/seats/new`,
                //         parent: match,
                //       }}
                //     />

                //     <DropdownItem
                //       label="Add Assignment"
                //       color="blue"
                //       glyph="chair"
                //       link={{
                //         pathname: `${match.url}/${phase.id}/assign-seats`,
                //         parent: match,
                //       }}
                //     />
                //   </>
                // }
              >
                {phaseOccupancies?.map((occupancy: any) => (
                  <RoadmapRow
                    key={occupancy.id}
                    level={1}
                    title={occupancy?.reference?.name}
                    graphic={<Avatar src={occupancy.reference.avatar} initials={occupancy.reference.name} size={22} />}
                    renderBars={() => <ProgramAssignmentBar data={occupancy} client={occupancy.reference} />}
                    // actions={
                    //   <>
                    //     <DropdownItem
                    //       label="Edit Program Assignment"
                    //       glyph="edit"
                    //       color="blue"
                    //       link={{
                    //         pathname: `${match.url}/assignments/${occupancy.id}`,
                    //         parent: match,
                    //       }}
                    //       permission="programs.edit"
                    //     />

                    //     <DropdownItem
                    //       label="Move Program Lists"
                    //       glyph="check_in"
                    //       color="green"
                    //       link={{
                    //         pathname: `${match.url}/${occupancy.id}/move`,
                    //         parent: match,
                    //       }}
                    //       permission="programs.edit"
                    //     />

                    //     <DropdownItem
                    //       label="Remove from List"
                    //       glyph="delete"
                    //       color="red"
                    //       link={{
                    //         pathname: `${match.url}/${occupancy.id}/remove`,
                    //         parent: match,
                    //       }}
                    //       permission="programs.edit"
                    //     />
                    //   </>
                    // }
                  />
                ))}

                <RoadmapRow
                  level={1}
                  className="!bg-[#F7F8FB]"
                  header={
                    <div className="!bg-[#F7F8FB] w-full flex-[1_1_auto] pl-[26px] py-2">
                      <div className="font-[700] uppercase text-[0.86rem] tracking-[0.5px] text-text-muted mb-2">Managing Staff</div>

                      {size(phase.employees) === 0 ? (
                        <div className="text-text-strongly-muted -mt-2">–</div>
                      ) : (
                        <div className="grid gap-2 text-[0.9rem]">
                          {phase.employees.map((record: any) => (
                            <div key={record.id}>
                              <Flex centerY gap="0.4rem">
                                <Avatar src={record.avatar} initials={record.name} size={20} />
                                <div>{record.name}</div>
                              </Flex>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  }
                />
              </RoadmapRow>
            )
          })}
        </>
      )}
    </Roadmap>
  )
}
