import React from 'react'
import { useLocation } from 'react-router-dom'
import { keyframes } from '@emotion/react'
import { Link as LinkV6 } from 'react-router-dom-v5-compat'
import { tint } from 'polished'
import { useMedia } from 'use-media'
import clsx from 'clsx'

import { COLORS, TOKENS, FEATURES, HARD_SHADOW } from '../theme'

import { Feature } from '../declarations/types/Feature'
import { Glyph as GlyphType } from '../declarations/types/Glyph'

import Avatar from './Avatar'
import Button from './Button'
import Flex from './Flex'
import Glyph from './Glyph'
import Emoji from './Emoji'
import Icon from './Icon'
import { Heading, Text } from './Typography'

import { useBackListenerStore } from '../hooks/useBackListener'

type Props = {
  aside: React.ReactNode
  avatar: string
  back: string
  description: string
  descriptionNowrap: boolean
  emoji: string
  feature: Feature
  glyph: GlyphType
  headingSize: string
  help: string
  icon: string
  isDropdown: string
  isEmpty: string
  isLoading: string
  isSticky: string
  menuGlyph: string
  menuLabel: string
  padding: string
  record: string
  showBack: string
  subtitle: string
  title: string
  titleAside: string
}

const Header = (props: any) => {
  const {
    aside,
    avatar,
    children,
    className,
    description,
    descriptionNowrap,
    emoji,
    feature,
    glyph,
    glyphColor,
    headingSize,
    help,
    icon,
    isDropdown,
    isEmpty,
    isLoading,
    isSticky,
    menuGlyph,
    menuLabel,
    padding,
    record,
    showBack,
    subtitle,
    title,
    titleAside,
    nowrapAside,
    testKey,
    graphic,
  } = props

  const location = useLocation()
  const backV6 = useBackListenerStore((state) => state.back)

  const [percent, setPercent] = React.useState(0)
  const isMobile = useMedia({ maxWidth: 480 })

  const back = React.useMemo(() => {
    let path = location.pathname || ''
    let bits = path.replace(`/`, '').split('/')

    return props.back || `/${bits[0]}` || '/'
  }, [props.back, location.pathname])

  React.useEffect(() => {
    let timeout = null

    const updatePercent = () => (timeout = setTimeout(() => setPercent(percent + 0.25), 250))
    if (percent < 1) updatePercent()
    if (isLoading === false) setPercent(1)

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [percent, isLoading])

  if (!title && !showBack && !children) return null

  const dynamicStyles = {
    ...padding,

    '&::before': {
      opacity: !isLoading && '0',
      background: tint(0.3, feature.icon.color),
      transform: `scale3d(${percent}, 1, 1)`,
      boxShadow: `
          0 1px 2px ${tint(0.75, feature?.icon?.color)},
          0 2px 4px ${tint(0.75, feature?.icon?.color)},
          0 4px 8px ${tint(0.75, feature?.icon?.color)}
        `,
    },
  }

  const classNames = clsx({
    'is-sticky': isSticky,
    'is-dropdown': isDropdown,
    [className]: className,
  })

  return (
    <header data-test={testKey} css={[styles, dynamicStyles]} className={classNames}>
      {showBack && (
        <Button
          as={backV6 ? LinkV6 : undefined}
          link={backV6 || back}
          type="list"
          glyph="long_arrow_left"
          color="gray"
          borderRadius="0"
          flex="0 0 56px"
          css={backStyles}
        />
      )}

      {title && (
        <Flex
          stretchSelf
          stretchChildrenX
          gap={12}
          alignItems="center"
          justifyContent="space-between"
          css={{
            paddingLeft: showBack && 56,
          }}
        >
          <Flex.Item flex="999 1 auto">
            <Flex nowrap gap={10} alignItems="center">
              {graphic}
              {avatar !== undefined && (
                <Avatar initials={title} src={avatar} size={32} magnify recordId={record?.id} recordType={record?.type} />
              )}
              {icon && avatar === undefined && <Icon icon={icon} size={20} hasShadow />}
              {glyph && <Glyph glyph={glyph} size={20} color={glyphColor} />}
              {emoji && <Emoji emoji={emoji} size={20} />}

              <div>
                {title && (
                  <Flex alignItems="center" gap={4}>
                    <Heading testKey={title} is="h1" size={headingSize || 300}>
                      {title}
                    </Heading>
                    {titleAside}
                    {help}
                  </Flex>
                )}

                {subtitle && <div css={{ marginTop: '0.25em' }}>{subtitle}</div>}

                {description && (
                  <Text css={{ whiteSpace: descriptionNowrap ? 'nowrap' : 'normal', marginTop: '0.15em' }}>{description}</Text>
                )}
              </div>

              {isDropdown && (
                <Button
                  hideLabel={isMobile}
                  glyph={menuGlyph}
                  label={menuLabel}
                  glyphColor={COLORS.blue}
                  color="text"
                  type="default"
                  className="!ml-auto"
                />
              )}

              {nowrapAside}
            </Flex>
          </Flex.Item>

          {aside && !isEmpty && !isDropdown && (
            <Flex.Item flex="1 1 auto">
              <Flex gap={8} stretchChildrenX>
                {aside}
              </Flex>
            </Flex.Item>
          )}
        </Flex>
      )}

      {children}
    </header>
  )
}

const animation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(-3px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const styles = {
  display: 'flex',
  alignItems: 'center',
  minHeight: TOKENS.headerHeight,
  animation: `${animation} 320ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,

  paddingTop: '0.4rem !important',
  paddingBottom: '0.4rem !important',

  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '100%',
    left: 0,
    zIndex: 0,

    height: 2,
    bottom: 0,
    borderRadius: '0 3px 3px 0',

    transition: `
        transform 200ms cubic-bezier(0.39, 0.575, 0.565, 1),
        opacity 1400ms cubic-bezier(0.39, 0.575, 0.565, 1)
      `,
    transformOrigin: 'center left',
  },

  '&.is-sticky': {
    position: 'sticky',
    zIndex: 2,

    // paddingTop: '0.25rem !important',
    // paddingBottom: '0.25rem !important',

    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    background: 'white',

    // boxShadow: `
    //   0 0.5px 0 ${COLORS.divider},
    //   0 2px 6px ${COLORS.shadow},
    //   0 4px 12px ${COLORS.shadow},
    //   0 8px 24px ${COLORS.shadow}
    // `,
  },

  '&.is-dropdown': {
    cursor: 'pointer',

    '&:hover': {
      background: 'white',

      'h1, h2, h3': {
        color: COLORS.link,
      },
    },
  },
}

const backStyles = {
  width: TOKENS.headerHeight,
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: 2,
  background: 'white',
  boxShadow: 'none',

  '&::after': {
    content: '""',
    width: 1,
    height: '100%',
    position: 'absolute',
    top: 0,
    right: -1,
    background: `linear-gradient(${COLORS.transparent}, ${COLORS.divider}, ${COLORS.transparent})`,
  },

  svg: {
    transition: 'transform 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  '&:hover': {
    cursor: 'pointer',
    background: 'white',
    boxShadow: `
      2px 0  6px ${COLORS.shadow},
      4px 0  12px ${COLORS.shadow},
      8px 0  24px ${COLORS.shadow}
    `,

    svg: {
      fill: COLORS.blue,
      transform: 'translateX(-1px) scale3d(1.1, 1.1, 1.1)',
    },
  },
}

Header.defaultProps = {
  feature: FEATURES.default,
  padding: TOKENS.wrapperPadding,
  menuGlyph: 'menu',
  menuLabel: 'Menu',
}

export default Header
