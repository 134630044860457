import React from 'react'
import { connect } from 'react-redux'

import Overlay from '../../Overlay'
import Button from '../../Button'
import Flex from '../../Flex'
import QuickView from '../../QuickView'

import { defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'

import { File } from './File'
import { withOverlayError } from '../../../hocs/withOverlayError'

class PortalFile extends File {
  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state
    const { record } = this.props

    return (
      <Overlay.Footer>
        {$editable && (
          <Flex stretchChildrenX gap={8} className="!w-full">
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="3 1 auto"
            />

            {!$new && (
              <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} flex="0 1 auto" />
            )}
          </Flex>
        )}

        {!$editable && (
          <>
            <Flex stretchChildrenX gap={8} className="!w-full">
              <QuickView record={record}>
                <Button glyph="external_link" label="View File" type="primary" flex="2 1 auto" />
              </QuickView>

              <Button onClick={() => window.open(record.download)} glyph="download" label="Download" type="primary" color="green" />
            </Flex>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'documents')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'documents')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(PortalFile))
