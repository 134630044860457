import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Status from '../../components/Status'

const NOTIFICATION_TYPE = {
  email: 'Email',
  sms: 'SMS',
  email_sms: 'Email + SMS',
}

export const NotificationLogsDataTable = (props: any) => {
  const { to, ...rest } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Subject',
        model: 'subject_output',
        width: 300,
        disableHide: true,
        formatValue: ({ value, data }: any) => <MainCell as={NavLink} id={data.id} value={value} to={data.id} />,
      },
      {
        id: 'status',
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <Status status={value} />
        },
      },
      {
        id: 'email_status',
        title: 'Email Status',
        model: 'email_status',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <Status status={value} />
        },
      },
      {
        id: 'sms_status',
        title: 'SMS Status',
        model: 'sms_status',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <Status status={value} />
        },
      },
      {
        title: 'Type',
        model: 'category',
        formatValue: ({ value }: any) => {
          return NOTIFICATION_TYPE[value]
        },
      },
      {
        title: 'Emails',
        model: 'to_emails',
        formatValue: ({ value }: any) => {
          return value?.join(', ')
        },
      },
      {
        title: 'Phone Numbers',
        model: 'to_phone_nos',
        formatValue: ({ value }: any) => {
          return value?.join(', ')
        },
      },
      {
        title: 'Layout',
        model: 'layout',
      },
      {
        title: 'Template',
        model: 'template',
      },
      {
        title: 'Email Sent At',
        model: 'email_sent_at',
        type: 'date_time',
      },
      {
        title: 'SMS Sent At',
        model: 'sms_sent_at',
        type: 'date_time',
      },
    ]
  }, [])

  return <DataTable asCard title="Notification Logs" icon="notification_logs" columns={columns} {...rest} />
}
