import React from 'react'
import clsx from 'clsx'
import { useMedia } from 'use-media'

import { COLORS } from '../theme'
import Logo from './Logo'

export const PoweredByBehave = ({ hideDivider, logoClassName }: any) => {
  const isSmall = useMedia({ minWidth: 600, maxWidth: 1023 })

  const logoClassNames = clsx(isSmall && 'is-small', logoClassName)

  return (
    <a href="https://behavehealth.com" target="_blank" css={STYLES.link}>
      {!hideDivider && <div css={STYLES.divider} />}
      <div>Powered by</div>
      <Logo small={isSmall} logo="behave_health" css={STYLES.logo} className={logoClassNames} />
    </a>
  )
}

const STYLES = {
  link: {
    display: 'block',
    textAlign: 'center',
    justifyContent: 'center',
    color: COLORS.textStronglyMuted,
    fontSize: '0.8rem',
    paddingBottom: '1rem',
    fontStyle: 'italic',
    cursor: 'pointer !important',

    '&:hover': {
      img: {
        filter: 'none !important',
        opacity: '1 !important',
      },
    },
  },

  divider: {
    width: 16,
    height: 2,
    backgroundColor: COLORS.gray,
    margin: '1.5rem auto 1rem',
    opacity: 0.25,
    borderRadius: 1,
  },

  logo: {
    width: '100%',
    maxWidth: 140,
    margin: '0.25rem auto 0',
    filter: 'grayscale(1)',
    opacity: 0.6,

    '&.is-small': {
      marginTop: '0.75rem',
    },
  },
}
