import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  in_use: {
    label: 'In Use',
    color: 'green',
  },
  active: {
    label: 'Active',
    color: 'blue',
  },
  inactive: {
    label: 'Inactive',
    color: 'yellow',
  },
  used: {
    label: 'Used',
    color: 'grey',
  },
  archived: {
    label: 'Archived',
    color: 'grey',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
