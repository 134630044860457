import React from 'react'

import { useUpdate } from '../../hooks/useNewAPI'
import TagsSelector from '../../components/TagsSelector'

export const ClientTagSelector = ({ client }: any) => {
  const clientId = client?.id

  const { mutateAsync: updateClient, isLoading }: any = useUpdate({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
    invalidate: ['clients'],
  })

  if (!clientId) return null

  return (
    <>
      <TagsSelector
        buttonLabel="Client Tags"
        tags={client?.tags}
        onSave={(tagIDs: any) => updateClient({ tag_ids: tagIDs })}
        isSaving={isLoading}
        smartCategories="current_clients"
        buttonClassName="order-first"
      />
    </>
  )
}
