import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  processing: {
    label: 'Processing',
    color: 'blue',
  },
  verification_pending: {
    label: 'Verification Pending',
    color: 'gray',
  },
  verifying: {
    label: 'Verifying',
    color: 'gray',
  },
  active: {
    label: 'Active',
    color: 'green',
  },
  errored: {
    label: 'Error',
    color: 'red',
  },
  disabled: {
    label: 'Disabled',
    color: 'gray',
  },
  expired: {
    label: 'Expired',
    color: 'gray',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
