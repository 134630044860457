import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useNavigate, useParams, useLocation } from 'react-router-dom-v5-compat'

import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { withPageError } from '@behavehealth/hocs/withPageError'
import useAPI from '@behavehealth/hooks/useAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Card, Chotomate, HelpTagIframe, Page } from '@behavehealth/components'
import LocationCheckinTable from '@behavehealth/components/Tables/LocationCheckinTable'

const pageConfig = {
  feature: 'location_gps_check_in',
  help: <HelpTagIframe id="location_check_in" />,
  marketingID: 'location_check_in',
}

const LocationCheckin: React.FC = () => {
  const { resource_id }: any = useParams()

  const data = useSelector((state) => state.data?.location_checks?.data)
  const loading = useSelector((state) => state.data?.location_checks?.loading)

  useAPI('location_checks', `/houses/${resource_id}/location_checks`)

  const isEmpty = size(data) === 0

  return (
    <Page {...pageConfig}>
      <Chotomate name="location_checkin" ready={!loading} />

      <Card>
        <LocationCheckinTable
          data={data}
          isLoading={isEmpty && loading}
          mainLinkAs={NavLink}
          state={{ data: { source: 'ehr' } }}
          to={(id) => id}
          localStorageKey="clients_location_checkin"
        />
      </Card>
    </Page>
  )
}

export default withPageError(withMarketing(LocationCheckin, pageConfig))
