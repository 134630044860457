import React from 'react'

import { MEDICATION_TIMES } from '../../utils/constants'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Status from '../../components/Status'
import SmartStatus from '../../components/SmartStatus'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'

const LOG_CATEGORY_STATUSES = {
  error_log: {
    label: 'Error',
    color: 'red',
    // glyph: 'circle_error',
  },
  issue_log: {
    label: 'Issue',
    color: 'yellow',
    // glyph: 'warning',
  },
}

export const MedicationIncidentLogsDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data?.id} value={value} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'med',
        model: 'med.name',
        title: 'Medication',
      },
      {
        width: 180,
        id: 'client',
        model: 'client',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 200,
        model: 'category',
        title: 'Log Category',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <SmartStatus statuses={LOG_CATEGORY_STATUSES} status={value} />
        },
      },
      {
        width: 200,
        model: 'notes',
        title: 'Notes',
      },
      {
        model: 'author',
        title: 'Logged By',
        type: 'profile',
      },
      {
        model: 'logged_at',
        title: 'Logged At',
        type: 'date_time',
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Medication Incident Logs"
      icon="medication_incidents"
      columns={columns}
      filtersConfig={FILTERS.medication_incidents}
      {...props}
    />
  )
}
