import React from 'react'
import axios from 'axios'
import compact from 'lodash/compact'
import produce from 'immer'
import size from 'lodash/size'

import { withFormContext } from './context'
import { useFormField } from './hooks/useFormField'

import Glyph from '../Glyph'
import Input from './Input'
import Loader from '../Loader'
import State from '../State'

import Json from '../Json'

import { PopoverMenu, PopoverMenuItem } from '../PopoverMenu'

const TOKEN = process.env.BH_MAPBOX

const parseContext = (contexts: any) => {
  if (!contexts) return {}

  let map = {}
  for (let i = 0; i < contexts.length; i++) map[contexts[i].id.split('.')[0]] = contexts[i]
  return map
}

const parseResult = (feature: any) => {
  const result = {
    title: '',
    description: '',
    city: '',
    state: '',
    zip: '',
    original: null,
    lat: feature?.geometry?.coordinates?.[1],
    lon: feature?.geometry?.coordinates?.[0],
  }

  if (!feature) return result

  const type = feature.place_type[0]

  if (type === 'poi') {
    result.title = feature.text
  } else if (type === 'address') {
    const parsed = feature.place_name?.split?.(',')
    const stateZip = parsed?.[2]?.trim?.() || ''
    const splitIndex = stateZip?.lastIndexOf?.(' ') || 0

    result.title = parsed?.[0] || feature.text
    result.city = parsed?.[1]?.trim?.() || ''
    result.state = stateZip?.slice(0, splitIndex) || ''
    result.zip = stateZip?.slice(splitIndex) || ''
    result.description = compact([result.city, result.state]).join(', ')
    result.original = feature
  }

  return result
}

export const AddressComboBox = (props: any) => {
  const { model, form, validations, className, onSelect, value, onSearchUpdate } = props

  const ref = React.useRef()

  const { formActions, formState } = useFormField({
    model: model,
    form: form,
    initialValue: value,
    validations: validations,
    isRelation: true,
    isValid: size(validations) === 0,
  })

  const [isOpen, setIsOpen]: any = React.useState(false)
  const [search, setSearch]: any = React.useState(value)
  const [results, setResults]: any = React.useState([])
  const [loading, setLoading]: any = React.useState(false)
  const [selectedAddress, setSelectedAddress]: any = React.useState(null)

  const hasResults = size(results) > 0

  const handleSearchUpdate = (input: any) => {
    setSearch(input?.value)
    onSearchUpdate?.(input)
  }

  const handleInteractOutside = (event: any) => {
    if (ref.current && ref.current.contains(event.target)) {
      event.preventDefault()
    }
  }

  const selectResult = (value: any) => {
    const parsed = parseResult(value)
    const { title, city, state } = parsed

    if (title) {
      setSearch(compact([title, city, state]).join(', '))
    }

    const result = produce(value, (draft) => {
      draft.context = parseContext(draft.context)
    })

    onSelect?.(parsed)
    setSelectedAddress(result)
  }

  React.useEffect(() => {
    if (!search) return

    const fetchResults = async () => {
      setLoading(true)

      try {
        const result = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(search)}.json`, {
          params: {
            access_token: TOKEN,
            limit: 5,
            country: 'US',
            types: 'address',
          },
        })

        const results = result.data?.features?.filter((feature) => feature?.address)

        // set up results
        setResults(results)

        if (!results[0]) return

        // select first result
        // setSelectedAddress(
        //   produce(results[0], (draft: any) => {
        //     draft.context = parseContext(draft.context)
        //   }),
        // )
      } catch (errors) {
        console.error(errors)
      } finally {
        setLoading(false)
      }
    }

    fetchResults()
  }, [search])

  // React.useEffect(() => {
  //   if (!search && selectedAddress) setSelectedAddress(null)
  // }, [search, selectedAddress])

  return (
    <>
      <div ref={ref}>
        <Input
          glyph="map_pin"
          onFocus={() => setIsOpen(true)}
          className={className}
          value={search}
          onUpdate={handleSearchUpdate}
          // graphic={loading ? <Loader size="1rem" /> : <Glyph glyph="map_pin" size="1.25rem" />}
          withHover={false}
          autoComplete="off"
          model={model}
        />
      </div>

      <PopoverMenu preventAutoFocus useTriggerWidth isOpen={isOpen} onOpenUpdated={setIsOpen} onInteractOutside={handleInteractOutside}>
        {hasResults && !loading && search ? (
          results.map((result: any) => {
            const { title, description } = parseResult(result)

            return (
              <PopoverMenuItem
                glyph="map_pin"
                key={result.id}
                label={title}
                description={description}
                onClick={() => selectResult(result)}
                isActive={selectedAddress?.id === result.id}
              />
            )
          })
        ) : (
          <State
            glyph="search"
            isEmpty={!search || !hasResults}
            isLoading={loading}
            minHeight={150}
            emptyDescription={search ? `No results found for "${search}"` : `Type an address to search`}
          />
        )}
      </PopoverMenu>

      {/* <Json data={{ parsed: parseResult(selectedAddress), selectedAddress }} /> */}
    </>
  )
}
