import React from 'react'
import { getValueAndUnit } from 'polished'
import clsx from 'clsx'

import FlexItem from './FlexItem'

const Flex = (props: any) => {
  const {
    as,
    children,
    className,
    gap,
    testKey,
    nowrap,
    onClick,
    onDoubleClick,
    vertical,

    centerX,
    centerY,

    fixChildrenX,
    fixChildrenY,
    fixSelf,

    stretchChildrenX,
    stretchChildrenY,
    stretchSelf,
    stretchSelfMax,

    flex,
    alignItems,
    alignContent,
    justifyContent,
    justifyItems,
  } = props

  const Tag = as

  const classNames = clsx({
    'is-horizontal': !vertical,
    'is-vertical': vertical,

    'center-x': centerX,
    'center-y': centerY,

    'fix-self': fixSelf,
    'fix-children-x': fixChildrenX,
    'fix-children-y': fixChildrenY,

    'stretch-self': stretchSelf,
    'stretch-self-max': stretchSelfMax,
    'stretch-children-x': stretchChildrenX,
    'stretch-children-y': stretchChildrenY,

    gap: gap,
    nowrap: nowrap,
    [className]: className,
  })

  return (
    <Tag
      data-test={testKey}
      className={classNames}
      children={children}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      css={[styles, gapStyles(gap)]}
      style={{ alignItems, alignContent, justifyItems, justifyContent, flex }}
    />
  )
}

const styles = {
  display: 'flex',
  flexWrap: 'wrap',

  '&.nowrap': { flexWrap: 'nowrap' },
  '&.fix-self': { flex: '0 0 auto' },
  '&.stretch-self': { flex: '1 1 auto' },
  '&.stretch-self-max': { flex: '999 1 auto' },

  // HORIZONTAL (default)
  '&.is-horizontal': {
    flexDirection: 'row',

    '&.center-x': { justifyContent: 'center' },
    '&.center-y': { alignItems: 'center' },

    '&.fix-children-x': { justifyItems: 'flex-start' },
    '&.fix-children-y': { alignItems: 'flex-start' },

    '&.stretch-children-x > *': { flex: '1 1 auto' },
    '&.stretch-children-y': { alignItems: 'stretch' },
  },

  // VERTICAL
  '&.is-vertical': {
    flexDirection: 'column',

    '&.center-x': { alignItems: 'center' },
    '&.center-y': { justifyContent: 'center' },

    '&.fix-children-x': { alignItems: 'flex-start' },
    '&.fix-children-y': { justifyItems: 'flex-start' },

    '&.stretch-children-x': { alignItems: 'stretch' },
    '&.stretch-children-y > *': { flex: '1 1 auto' },
  },
}

const getHalfGap = (gap) => {
  gap = getValueAndUnit(gap)
  return `${gap[0] / 2}${gap[1] || 'px'}`
}

const getHalfGapNegative = (gap) => {
  gap = getValueAndUnit(gap)
  return `${gap[0] / -2}${gap[1] || 'px'}`
}

export const gapStyles = (gap) => {
  if (!gap) return {}

  return {
    '&.gap': {
      margin: getHalfGapNegative(gap),
      '& > *': { margin: getHalfGap(gap) },
    },
  }
}

Flex.Item = FlexItem
Flex.Item.displayName = 'FlexItem'

Flex.defaultProps = {
  as: 'div',
}

export default Flex
