import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { AgreementsReportDataTable } from '@behavehealth/constructs/LiveReports/AgreementsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Agreements = () => {
  const tableProps = useDataTable({
    name: ['reports', 'agreements'],
    endpoint: `/live_reports?category=agreements`,
    localStorageKey: 'report_agreements_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.reference)}/agreements/${rowData.id}`, [])

  return (
    <Page feature="agreements" title="Live Agreements Report">
      <Grid>
        <AgreementsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Agreements)
