import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import size from 'lodash/size'

import { css, COLORS, HARD_SHADOW } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import BedOccupancyStatus from '../../components/Statuses/BedOccupancyStatus'
import Button from '../../components/Button'
import Card from '../../components/Card'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import State from '../../components/State'
import Status from '../../components/Status'

const RootBedSelectorOverlay = (props: any) => {
  const { data, fromDate, fromLabel = 'From', onClose, setData, setFromDate, setUntilDate, untilDate, untilLabel = 'Until' } = props

  const { timezone, tenant } = useSettings()

  const allowOverbook = tenant?.system_prefs?.allow_overbook === true

  const [property, setProperty] = React.useState(data?.house || null)
  const [floor, setFloor] = React.useState(data?.floor || null)
  const [room, setRoom] = React.useState(data?.room || null)
  const [bed, setBed] = React.useState(data || null)

  const propertiesParams = { category: 'housing,mixed_category' }
  const bedsParams = { from: fromDate, until: untilDate }

  const [isDatesEditable, setIsDatesEditable] = React.useState(false)

  const { data: properties, isLoading: isPropertiesLoading }: any = useGet({
    name: ['locations', propertiesParams],
    params: propertiesParams,
    url: `/houses`,
  })

  const { data: floors, isLoading: isFloorsLoading }: any = useGet({
    name: ['locations', property?.id, 'floors'],
    url: `/houses/${property?.id}/floors`,
    options: { enabled: !!property?.id },
  })

  const { data: beds, isLoading: isBedsLoading }: any = useGet({
    name: ['location', property?.id, 'room', room?.id, 'beds', bedsParams],
    url: `/rooms/${room?.id}/beds`,
    params: bedsParams,
    options: { enabled: !!(property?.id && room?.id) },
  })

  const handleSave = () => {
    setData({
      ...bed,
      house: property,
      floor: floor,
      room: room,
    })

    onClose()
  }

  React.useEffect(() => {
    if (!beds || !bed) return

    const currentBed = beds.find((o) => o.id === bed.id)

    // don't allow overbook
    if (!allowOverbook && currentBed.status === 'occupied') setBed(null)
  }, [beds, bed])

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={onClose} maxWidth={60}>
      <Overlay.Header title="Select Bed" icon="beds" />

      <Overlay.Content>
        <Section>
          <Grid gap="1rem">
            <div>
              <Flex stretchChildrenX={isDatesEditable} gap="1.2rem">
                <DateTimeInput
                  defaultToNow
                  isEditable={isDatesEditable}
                  label={fromLabel}
                  value={fromDate}
                  onUpdate={({ value }) => {
                    setFromDate?.(value)
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter a start date',
                    },
                  }}
                  timezone={timezone}
                />

                <DateTimeInput
                  defaultToEndOfToday
                  isEditable={isDatesEditable}
                  label={untilLabel}
                  value={untilDate}
                  onUpdate={({ value }: any) => {
                    setUntilDate?.(value)
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter an end date',
                    },
                    datetime: {
                      earliest: fromDate,
                      message: `Please enter a date after "${fromLabel}" date`,
                    },
                  }}
                  timezone={timezone}
                />
              </Flex>

              {!isDatesEditable && (
                <Button
                  label="Edit Dates"
                  glyph="edit"
                  size={200}
                  onClick={() => setIsDatesEditable(true)}
                  display="inline-flex"
                  className="!mt-3"
                />
              )}
            </div>

            <Alert glyph="info">Edit the dates above to find available beds within this time range.</Alert>

            <Card className={STYLES.card()}>
              <PropertiesColumn
                activeId={property?.id}
                data={properties}
                isLoading={isPropertiesLoading}
                onSelect={(value: any) => {
                  if (value?.id === property?.id) return // clicked on the same property
                  setProperty(value)
                  setFloor(null)
                  setRoom(null)
                  setBed(null)
                }}
              />

              <FloorsRoomsColumn
                activeId={room?.id}
                propertyId={property?.id}
                data={floors}
                isLoading={isPropertiesLoading || isFloorsLoading}
                onSelect={(floorValue: any, roomValue: any) => {
                  if (roomValue === room?.id) return // clicked on the same room
                  setFloor(floorValue)
                  setRoom(roomValue)
                  setBed(null)
                }}
              />

              <BedsColumn
                allowOverbook={allowOverbook}
                activeId={bed?.id}
                roomId={room?.id}
                data={beds}
                isLoading={isPropertiesLoading || isFloorsLoading || isBedsLoading}
                onSelect={(value) => {
                  if (value?.id === bed?.id) return // clicked on the same bed
                  setBed(value)
                }}
              />
            </Card>
          </Grid>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Select Bed" color="green" type="primary" onClick={handleSave} isDisabled={!bed?.id} />
      </Overlay.Footer>
    </Overlay>
  )
}

const Column: React.FC<any> = (props) => {
  const { children, title, icon, testKey } = props

  return (
    <div data-test={testKey} className={STYLES.column()}>
      {title && (
        <header className={STYLES.columnHeader()}>
          {icon && <Icon icon={icon} size={20} className={STYLES.columnHeaderIcon()} />}
          {title}
        </header>
      )}

      {children}
    </div>
  )
}

const PropertiesColumn: React.FC<any> = (props) => {
  const { activeId, data, isLoading, onSelect } = props

  const isEmpty = size(data) === 0

  if (isLoading || isEmpty) {
    return (
      <Column title="Locations" icon="locations">
        <State isLoading={isLoading} isEmpty={isEmpty} title="Locations" icon="locations" emptyDescription="No locations created yet" />
      </Column>
    )
  }

  return (
    <Column title="Locations" icon="locations">
      {data.map((property: any) => (
        <RowItem
          key={property.id}
          title={property.name}
          graphic={<Avatar src={property.avatar} initials={property.name} size={20} />}
          onClick={() => onSelect(property)}
          isActive={property.id === activeId}
        />
      ))}
    </Column>
  )
}

const FloorsRoomsColumn: React.FC<any> = (props) => {
  const { activeId, data, isLoading, onSelect, propertyId } = props

  const isEmpty = size(data) === 0

  if (!propertyId) {
    return (
      <Column title="Rooms" icon="rooms">
        <State isEmpty title="Rooms" icon="rooms" emptyDescription="Please select a location first" />
      </Column>
    )
  }

  if (isLoading || isEmpty) {
    return (
      <Column title="Rooms" icon="rooms">
        <State isLoading={isLoading} isEmpty={isEmpty} title="Rooms" icon="rooms" emptyDescription="No rooms found in this house" />
      </Column>
    )
  }

  return (
    <Column title="Rooms" icon="rooms">
      {data.map((floor: any) => (
        <React.Fragment key={floor.id}>
          <RowHeader title={floor.name} graphic={<Icon icon="floors" size={20} />} />

          {size(floor.rooms) === 0 ? (
            <div className={STYLES.rowEmptyState()}>Empty floor</div>
          ) : (
            <>
              {floor.rooms.map((room: any) => (
                <RowItem
                  key={room.id}
                  title={room.name}
                  graphic={<Icon icon="rooms" size={20} />}
                  onClick={() => onSelect(floor, room)}
                  isActive={room.id === activeId}
                  style={{ paddingLeft: 32 }}
                />
              ))}
            </>
          )}
        </React.Fragment>
      ))}
    </Column>
  )
}

const BedsColumn: React.FC<any> = (props) => {
  const { activeId, data, isLoading, onSelect, roomId, allowOverbook } = props

  const isEmpty = size(data) === 0

  if (!roomId) {
    return (
      <Column title="Beds" icon="beds">
        <State isEmpty={isEmpty} title="Beds" icon="beds" emptyDescription="Please select a room first" />
      </Column>
    )
  }

  if (isLoading || isEmpty) {
    return (
      <Column title="Beds" icon="beds">
        <State isLoading={isLoading} isEmpty={isEmpty} title="Beds" icon="beds" emptyDescription="No beds added to this room yet" />
      </Column>
    )
  }

  return (
    <Column title="Beds" icon="beds">
      {data.map((bed: any) => (
        <RowItem
          key={bed.id}
          title={bed.name}
          aside={
            bed.status && (
              <div className={STYLES.statusesWrapper()}>
                <Flex gap="0.4rem">
                  {allowOverbook && (bed.status === 'occupied' || bed.status === 'reserved') && (
                    <Status small label="Overbook" color="red" />
                  )}
                  <BedOccupancyStatus small status={bed.status} className={STYLES.occupancyStatus().className} />
                </Flex>
              </div>
            )
          }
          graphic={<Icon icon="beds" size={20} />}
          onClick={() => onSelect(bed)}
          isActive={bed.id === activeId}
          isDisabled={!allowOverbook && (bed.status === 'occupied' || bed.status === 'reserved')}
        />
      ))}
    </Column>
  )
}

const RowItem: React.FC<any> = (props) => {
  const { aside, title, subtitle, graphic, onClick, isActive, isDisabled, style } = props

  const rootClasses = clsx(STYLES.item().className, isActive && 'is-active', isDisabled && 'is-disabled')

  return (
    <div data-test="row_item" className={rootClasses} onClick={!isDisabled && onClick} style={style}>
      {graphic && <div className={STYLES.itemGraphic()}>{graphic}</div>}

      <div>
        <div className={STYLES.itemTitle()}>{title}</div>
        {subtitle && <div className={STYLES.itemSubtitle()}>{subtitle}</div>}
      </div>

      {aside}
    </div>
  )
}

const RowHeader: React.FC<any> = (props) => {
  const { title, graphic } = props

  return (
    <div className={STYLES.rowHeader()}>
      {graphic && <div className={STYLES.rowHeaderGraphic()}>{graphic}</div>}
      <div className={STYLES.rowHeaderTitle()}>{title}</div>
    </div>
  )
}

const STYLES = {
  card: css({
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(200px, 1fr)',
    overflow: 'auto !important',
    height: 450,
    fontSize: '0.9rem',
  }),

  column: css({
    overflowY: 'auto',
    borderRight: `1px solid ${COLORS.divider}`,

    '&:last-child': {
      borderRight: 'none',
    },
  }),

  columnHeaderIcon: css({
    marginRight: '0.4rem',
  }),

  columnHeader: css({
    display: 'flex',
    alignItems: 'center',
    padding: '0.4rem 0.75rem',
    fontWeight: 600,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: COLORS.white,
  }),

  rowHeader: css({
    display: 'flex',
    alignItems: 'center',
    padding: '0.2rem 0.75rem',
    paddingTop: '0.3rem',
    background: COLORS.hover,
    borderBottom: `1px solid ${COLORS.divider}`,
  }),

  rowHeaderGraphic: css({
    marginRight: '0.4rem',
  }),

  rowHeaderTitle: css({
    fontWeight: 600,
  }),

  rowEmptyState: css({
    fontWeight: 400,
    fontSize: '0.85rem',
    color: COLORS.textMuted,
    textAlign: 'center',
    padding: '0.75rem',
  }),

  item: css({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0.25rem 0.75rem',
    cursor: 'pointer',
    borderBottom: `1px solid ${COLORS.divider}`,

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-active': {
      color: COLORS.white,
      background: COLORS.blue,
    },

    '&.is-disabled': {
      background: COLORS.hover,
      cursor: 'not-allowed',
      color: tint(0.1, COLORS.textMuted),

      img: {
        opacity: 0.5,
        filter: 'grayscale(100%)',
      },
    },
  }),

  itemGraphic: css({
    marginRight: '0.4rem',
  }),

  itemTitle: css({
    // fontWeight: 600,
  }),

  itemSubtitle: css({
    fontSize: '0.85rem',
    fontWeight: 400,
    color: COLORS.textMuted,
  }),

  statusesWrapper: css({
    marginLeft: 'auto',
  }),

  occupancyStatus: css({
    minWidth: 72,
    textAlign: 'center',
    justifyContent: 'center',
  }),
}

export const BedSelectorOverlay = withOverlayError(RootBedSelectorOverlay)
