import React from 'react'
import { DateTime } from 'luxon'
import { useRouteMatch, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { COLORS } from '@behavehealth/theme'
import { DT, getLastValueFromMap, mapToArray, usDateTime, titleCase } from '@behavehealth/utils/functions'
import { LocationCalendar } from '@behavehealth/components/LocationCalendar'
import { LocationCheckinTable } from '@behavehealth/components/Tables'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import useAPI from '@behavehealth/hooks/useAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import {
  Button,
  Card,
  Chotomate,
  DataList,
  Dropdown,
  DropdownItem,
  Flex,
  Grid,
  HelpTagIframe,
  Link,
  Map,
  Page,
  PageLayout,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@behavehealth/components'

import PageLoader from '@behavehealth/components/Loaders/PageLoader'

import LocationCheckStatus from '@behavehealth/components/Statuses/LocationCheckStatus'
import { LocationCheckInsDataTable } from '@behavehealth/constructs/LocationCheckIns/LocationCheckInsDataTable'

const pageConfig = {
  feature: 'location_gps_check_in',
  help: <HelpTagIframe id="location_check_in" />,
  marketingID: 'location_check_in',
}

const LOCATION_STATUSES = {
  not_requested: {
    text: 'Send a request to the client to enable location services in their BEHAVE Client app and allow live location tracking',
    status: {
      label: 'Not Sent',
      color: 'blue',
    },
    button: {
      label: 'Request Live Location Tracking',
      glyph: 'map',
    },
  },

  pending: {
    text: 'The client has been sent a request via SMS and email to track their location while using the BEHAVE Client app',
    status: {
      label: 'Pending Client Confirmation',
      color: 'orange',
    },
    button: {
      label: 'Request Live Location Tracking Again',
      glyph: 'reset',
    },
  },

  accepted: {
    text: 'Client accepted the request to track their location while using the BEHAVE Client app',
    status: {
      label: 'Accepted',
      color: 'green',
    },
    button: null,
  },

  refused: {
    text: 'Client refused the request to track their location while using the BEHAVE Client app',
    status: {
      label: 'Refused',
      color: 'red',
    },
    button: {
      label: 'Request Live Location Tracking Again',
      glyph: 'reset',
    },
  },
}

const AUTO_CHECK_STATUS = {
  await_location_access: {
    text: 'Request live location tracking above before enabling the automatic check-in/out feature.',
    status: {
      label: 'Awaiting Live Location Tracking',
      color: 'gray',
    },
    button: null,
  },

  enabled: {
    text: 'This client is automatically checked in and out to and from all locations that have this feature enabled as well.',
    status: {
      label: 'Active',
      color: 'green',
    },
    button: {
      label: 'Disable Automatic Check-In/Out',
      glyph: 'cross',
      color: 'red',
    },
  },

  disabled: {
    text: 'Check-in and out this client from locations that have this feature enabled.',
    status: {
      label: 'Disabled',
      color: 'red',
    },
    button: {
      label: 'Enable Automatic Check-In/Out',
      glyph: 'check',
      color: 'green',
    },
  },
}

export const US_CENTER = {
  lat: 40.329796,
  lon: -96.196289,
}

const LocationCheckin = () => {
  const match = useRouteMatch()
  const resourceID = match.params?.resource_id

  const { data: client }: any = useGet({
    name: ['client', resourceID],
    url: `/residents/${resourceID}`,
  })

  const [locationStatus, setLocationStatus] = React.useState('not_requested') // not_requested | pending | accepted | refused
  const [autoCheckinStatus, setAutoCheckinStatus] = React.useState('await_location_access') // await_location_access | enabled | disabled

  const locationStatusData = LOCATION_STATUSES[locationStatus]
  const autoCheckinStatusData = AUTO_CHECK_STATUS[autoCheckinStatus]

  const didAcceptLocationTracking = locationStatus === 'accepted'
  const isAutoCheckinEnabled = autoCheckinStatus === 'enabled'

  const apiKey = ['client', resourceID, 'location-checks']

  // const { data, isLoading } = useGet({
  //   name: ['client', resourceID, 'location-checks'],
  //   url: `/residents/${resourceID}/location_checks`,
  //   options: { enabled: !!resourceID },
  // })

  const tableProps = useDataTable({
    name: ['client', resourceID, 'location-checks'],
    endpoint: `/residents/${resourceID}/location_checks`,
    // params: queryParams,
    localStorageKey: 'clients_location_checkin_v1',
  })

  const isEmpty = size(tableProps?.data) === 0

  const to = React.useMemo(
    () => (rowData: any) => ({
      pathname: `${match.url}/${rowData.id}`,
      parent: match,
      source: 'ehr',
    }),
    [match.url],
  )

  const actions = (
    <Dropdown
      label="Add Check-In's…"
      glyph="add"
      buttonType="primary"
      permission="location_checkin.create"
      featureFlagV2="manual_location_gps_check_in"
    >
      <DropdownItem
        label="Add Check-In"
        icon="recovery_navigation"
        color="blue"
        featureFlagV2="manual_location_gps_check_in"
        link={{
          pathname: `${match.url}/new`,
          data: {
            resident_id: client?.id,
          },
          parent: match,
        }}
      />

      <DropdownItem
        label="Add Many Check-In's…"
        glyph="stack"
        color="blue"
        featureFlagV2="bulk_add"
        link={{
          pathname: `${match.url}/new-batch`,
          parent: match,
        }}
      />
    </Dropdown>
  )

  if (!(client && tableProps?.data)) return <PageLoader />

  return (
    <Page actions={actions} {...pageConfig}>
      <Grid gap="1rem">
        <Chotomate name="location_checkin" ready={!tableProps?.isLoading} />

        <PageLayout>
          <Tabs defaultTab="list">
            <TabList css={tabStyles}>
              <Tab label="Location Check-in" name="list" />
              <Tab label="Map View" name="map" />
            </TabList>

            <TabPanels>
              <TabPanel name="list" className="!mt-4">
                <Grid>
                  <LocationCheckInsDataTable
                    {...tableProps}
                    to={to}
                    headerLinksConfig={[
                      {
                        type: 'report',
                        to: '/reports/location-checkins',
                      },
                    ]}
                  />
                </Grid>
              </TabPanel>

              <TabPanel type="mount" name="map" className="!mt-4">
                <ClientMap />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PageLayout>
      </Grid>
    </Page>
  )
}

const ClientMap = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const mapRef = React.useRef()

  const { tenant } = useSettings()

  const [lastLocation, setLastLocation] = React.useState(null)
  const [date, setDate] = React.useState(DateTime.local().toISODate())

  const firstDay = DT(date).startOf('month').startOf('week')
  const statsStart = firstDay.toISODate()
  const statsEnd = firstDay.plus({ days: 41 }).toISODate()

  const [showMapCheckins, setShowMapCheckins] = React.useState(true)
  const [showMapJourney, setShowMapJourney] = React.useState(true)

  const queryParams = {
    from: date,
    until: date,
    stats_start: statsStart,
    stats_end: statsEnd,
  }

  const { data, isLoading } = useGet({
    name: ['client', resource_id, 'location-tracking', { queryParams }],
    url: `/residents/${resource_id}/location_tracking`,
    params: queryParams,
    options: { enabled: !!resource_id },
  })

  const checkins = data?.checkins
  const locations = data?.locations
  const stats = data?.stats

  const isLocationsEmpty = size(locations) === 0
  const isCheckinsEmpty = size(checkins) === 0

  const centerOnLocation = () => {
    if (!lastLocation?.lat || !lastLocation?.lon) return

    const map = mapRef.current.getMap()
    map?.flyTo({
      center: [lastLocation?.lon, lastLocation?.lat],
      essential: true,
    })
  }

  React.useEffect(() => {
    if (locations) setLastLocation(getLastValueFromMap(locations))
  }, [locations])

  React.useEffect(() => {
    if (lastLocation?.lon && lastLocation?.lat) {
      const map = mapRef.current.getMap()
      map?.flyTo({
        center: [lastLocation?.lon, lastLocation?.lat],
        essential: true,
      })
    }
  }, [lastLocation])

  return (
    <LocationCalendar
      date={date}
      setDate={setDate}
      isEmpty={isLocationsEmpty}
      isLoading={isLoading}
      stats={stats}
      headerActions={
        <Flex gap={8} centerY stretchChildrenX>
          <Button
            label={showMapJourney ? 'Hide Day Journey' : 'Show Day Journey'}
            size={200}
            color="text"
            glyphColor={COLORS.blue}
            glyph="map"
            onClick={() => setShowMapJourney((c: boolean) => !c)}
            isDisabled={isLocationsEmpty}
          />

          <Button
            label={showMapCheckins ? 'Hide Check-In Locations' : 'Show Check-In Locations'}
            size={200}
            color="text"
            glyphColor="red"
            glyph="map_pin"
            onClick={() => setShowMapCheckins((c: boolean) => !c)}
            isDisabled={isCheckinsEmpty}
          />

          <Button
            label="Center on Client's Last Location"
            size={200}
            color="text"
            glyphColor={COLORS.blue}
            glyph="portal"
            onClick={centerOnLocation}
            isDisabled={!lastLocation}
          />
        </Flex>
      }
    >
      <Map
        getRef={(ref) => (mapRef.current = ref.current)}
        css={mapStyles}
        markers={{
          checkins: {
            visible: showMapCheckins,
            data: mapToArray(checkins),
          },
        }}
        traces={{
          journey: {
            visible: showMapJourney,
            data: mapToArray(locations),
          },
        }}
        lat={lastLocation?.lat}
        lon={lastLocation?.lon}
        stateName={tenant?.state}
        zoom={14}
        localStorageKey="client_location_checkins"
        popup={(record: any) => {
          const category = record?.category
          const location = record?.location

          let name = record?.name

          if (category === 'organization' && location?.name) name = location.name
          else if (category === 'property' && location?.name) name = location.name

          return (
            <Card css={popupStyles.card}>
              <LocationCheckStatus status={record.status} />

              <h3 css={popupStyles.title}>{name}</h3>

              <DataList isCompact labelWidth={120}>
                <DataList.Item label="Category" value={titleCase(record.category)} />
                <DataList.Item label="Check-In Date" value={<span css={popupStyles.dateTime}>{usDateTime(record.checkin_at)}</span>} />
                <DataList.Item label="Check-Out Date" value={<span css={popupStyles.dateTime}>{usDateTime(record.checkout_at)}</span>} />
              </DataList>

              <Button
                label="Open Location Check-In"
                type="primary"
                size={200}
                css={popupStyles.button}
                link={{
                  pathname: `${match.url}/${record.id}`,
                  parent: match,
                }}
              />
            </Card>
          )
        }}
      />
    </LocationCalendar>
  )
}

const mapStyles = {
  width: '100%',
  minHeight: '30rem',

  '@media (min-width: 1400px)': {
    minHeight: '80vh',
  },
}

const tabStyles = {
  marginTop: '-1rem',
}

const popupStyles = {
  card: {
    padding: '1rem',
  },

  title: {
    paddingRight: '1.25rem',
    margin: '0.25rem 0 0.5rem',
  },

  dateTime: {
    whiteSpace: 'nowrap',
  },

  button: {
    marginTop: '0.5rem',
  },
}

export default withPageError(withMarketing(LocationCheckin, pageConfig))
