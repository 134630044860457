import React from 'react'

import { usDateTime } from '../../../utils/functions'

import Empty from './Empty'

const UsDateTime: React.FC = ({ value, timezone }) => {
  if (!value) return <Empty />
  return usDateTime(value, timezone)
}

export default UsDateTime
