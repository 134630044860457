import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL.js'
import { useSettings } from '@behavehealth/hooks/useSettings'

import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Flex,
  Page,
  PageLayout,
  Permission,
} from '@behavehealth/components'

import { CATEGORIES } from '@behavehealth/constructs/Tags/TagSmartCategorySelector'
import { TagGroupOverlay } from '@behavehealth/constructs/Tags/TagGroupOverlay'
import { TagGroupTemplatesImportOverlay } from '@behavehealth/constructs/Tags/TagGroupTemplatesImportOverlay'
import { TagOverlay } from '@behavehealth/constructs/Tags/TagOverlay'
import { TagsTable } from '@behavehealth/constructs/Tags/TagsTable'
import TagGroupCategoryStatus from '@behavehealth/components/Statuses/TagGroupCategoryStatus'

const TagsManager = () => {
  const { url } = useRouteURL()

  return (
    <div>
      <TagsManagerIndex />

      <AnimatedRoutes>
        <Route path="import" element={<TagGroupTemplatesImportOverlay useV6Router back={url} />} />
        <Route path=":id" element={<TagGroupOverlay useV6Router back={url} />} />
        <Route path=":tag_group_id/:id" element={<TagOverlay useV6Router back={url} />} />
      </AnimatedRoutes>
    </div>
  )
}

const TagsManagerIndex = () => {
  const { isBehave } = useSettings()

  const { data, isLoading }: any = useGet({
    name: ['tag-groups'],
    url: `/tag_groups`,
  })

  const isEmpty = size(data) === 0

  const actions = (
    <>
      {isBehave && <Button as={Link} label="Import Behave Templates" type="default" glyph="add" link="import" />}

      <Button as={Link} testKey="add_group_button" label="Add Group" glyph="add" type="primary" link="new" permission="tag_groups.create" />
    </>
  )

  return (
    <Page
      testKey="tags_manager_page"
      title="Tags Manager"
      feature="tags"
      emptyDescription="Create global tags that you can apply to any records"
      isEmpty={isEmpty}
      actions={actions}
      isLoading={isEmpty && isLoading}
    >
      <Alert contrast glyph="info" css={styles.alert}>
        You can apply the tags below to any profiles (e.g. clients, staff, properties) and records (e.g. todos, events, files).
      </Alert>

      <PageLayout>
        {data?.map?.((group: any) => {
          const hasSmartCategories = size(group.smart_categories) > 0

          return (
            <Card testKey={snakeCase(group.name)} key={group.id}>
              <CardHeader
                after={
                  <Flex gap={8}>
                    <Button
                      as={Link}
                      testKey="edit_group_button"
                      label="Edit Group"
                      glyph="edit"
                      type="minimal"
                      link={group.id}
                      permission="tag_groups.edit"
                    />

                    <Button
                      as={Link}
                      testKey="add_tag_button"
                      label="Add Tag"
                      glyph="add"
                      type="minimal"
                      link={`${group.id}/new`}
                      permission="tags.create"
                    />
                  </Flex>
                }
              >
                <CardTitle title={group.name} />
                {group.description && <CardSubtitle subtitle={group.description} />}
                {hasSmartCategories && (
                  <CardSubtitle
                    subtitle={
                      <Flex gap="0.5rem" centerY>
                        <TagGroupCategoryStatus status={group?.category} />
                        <span>{group.smart_categories.map((o) => CATEGORIES[o].label).join(', ')}</span>
                      </Flex>
                    }
                  />
                )}
              </CardHeader>

              <Permission permission="tags.view">
                <CardContent padding="0">
                  <TagsTable
                    mainLinkAs={NavLink}
                    testKey="tags_table"
                    title="Tag"
                    data={group.tags}
                    to={(id: string) => `${group.id}/${id}`}
                    localStorageKey="settings_tags_manager"
                  />
                </CardContent>
              </Permission>
            </Card>
          )
        })}
      </PageLayout>
    </Page>
  )
}

const styles = {
  alert: {
    marginBottom: '1rem',
  },
}

export default TagsManager
