import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { ApplicantsReportDataTable } from '@behavehealth/constructs/LiveReports/ApplicantsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Applicants = () => {
  const queryParams = { status: 'lead', category: 'lead' }

  const tableProps = useDataTable({
    name: ['reports', 'clients'],
    // endpoint: `/live_reports/clients`,
    endpoint: `/live_reports`,
    updateDeleteEndpoint: `/residents`,
    params: queryParams,
    localStorageKey: 'report_applicants_v1',
  })

  const to = React.useMemo(() => (rowData: any) => getClientLink(rowData), [])

  return (
    <Page feature="admissions" title="Live Applicants Report">
      <Grid gap="1rem">
        <ApplicantsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Applicants)
