import { useReducer } from 'react'
import produce from 'immer'

type InitialState = {
  steps: string[]
  defaultStep: string
}

const init = (initialState: InitialState) => {
  if (!initialState || !initialState.steps || initialState.steps.length === 0) {
    return {
      steps: [],
      currentStep: null,
      isFirst: null,
      isLast: null,
    }
  }

  const { steps, defaultStep } = initialState

  let isFirst = true
  let isLast = false
  let currentStep = steps[0]

  if (defaultStep) {
    const idx = steps.indexOf(defaultStep)
    if (idx === -1) return

    currentStep = steps[idx]

    isFirst = idx === 0
    isLast = idx === steps.length - 1
  }

  return {
    steps,
    currentStep,
    defaultStep,
    isFirst,
    isLast,
  }
}

const reducer = (state: any, action: any) => {
  const { steps, currentStep } = state

  const firstStep = steps[0]
  const lastStep = steps[steps.length - 1]
  const currentIndex = steps.indexOf(currentStep)

  switch (action.type) {
    case 'SET_ALL_STEPS': {
      const { steps } = action

      return produce(state, (draft: any) => {
        draft.steps = steps
      })
    }

    case 'SET_STEP': {
      const { step } = action

      if (!steps.includes(step)) return state

      return produce(state, (draft: any) => {
        draft.currentStep = step
        draft.isFirst = step === firstStep
        draft.isLast = step === lastStep
      })
    }

    case 'GO_NEXT': {
      const nextStep = steps[currentIndex + 1]

      if (!nextStep) return state

      return produce(state, (draft: any) => {
        draft.currentStep = nextStep
        draft.isFirst = nextStep === firstStep
        draft.isLast = nextStep === lastStep
      })
    }

    case 'GO_BACK': {
      const { steps } = state
      const prevStep = steps[currentIndex - 1]

      if (!prevStep) return state

      return produce(state, (draft: any) => {
        draft.currentStep = prevStep
        draft.isFirst = prevStep === firstStep
        draft.isLast = prevStep === lastStep
      })
    }

    default: {
      return state
    }
  }
}

export const useWorkflow = (initialState: InitialState) => {
  const [state, dispatch] = useReducer(reducer, initialState, init)

  const setAllSteps = (steps: any[]) => dispatch({ type: 'SET_ALL_STEPS', steps })
  const setStep = (step: string) => dispatch({ type: 'SET_STEP', step })
  const goNext = () => dispatch({ type: 'GO_NEXT' })
  const goBack = () => dispatch({ type: 'GO_BACK' })

  return {
    ...state,
    goNext,
    goBack,
    setStep,
    setAllSteps,
  }
}
