import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import useAPI from '@behavehealth/hooks/useAPI'

import { Card } from '@behavehealth/components'
import { RostersTable } from '@behavehealth/components/Tables'

const CompletedRosters: React.FC = () => {
  const match = useRouteMatch()
  const data = useSelector((state: any) => state.data.rosters?.data)
  const loading = useSelector((state: any) => state.data.rosters?.loading)

  useAPI('rosters', '/rosters?status=completed')

  const isEmpty = size(data) === 0

  return (
    <Card>
      <RostersTable
        data={data}
        isLoading={isEmpty && loading}
        to={(id: string, category: string) => ({
          pathname: `${match.url}/${category?.replace('_', '-')}/${id}`,
          parent: match,
        })}
      />
    </Card>
  )
}

export default CompletedRosters
