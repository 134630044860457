import React from 'react'

import { INPUT_STYLES } from '../../../theme'
import { FilterCondition } from './FilterCondition'

export const StringFilter = (props: any) => {
  const { filter, onUpdate, applyFilters, setIsFiltersOpen } = props

  const ref = React.useRef(null)
  const [value, setValue] = React.useState(filter?.value || '')

  const handleUpdate = (event: any) => {
    setValue(event.target.value)
  }

  React.useEffect(() => {
    if (onUpdate) onUpdate({ value, condition: 'contains' })
  }, [value])

  React.useEffect(() => {
    if (!ref?.current) return

    ref.current.focus()
  }, [])

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      applyFilters()
      setIsFiltersOpen(false)
    }
  }

  return (
    <>
      <FilterCondition condition="contains" />
      <input
        data-test="string_filter_input"
        ref={ref}
        type="text"
        css={STYLES.input}
        value={value}
        onChange={handleUpdate}
        onKeyDown={handleKeyDown}
      />
    </>
  )
}

const STYLES = {
  input: {
    ...INPUT_STYLES,
    outline: 'none',
    minHeight: 'var(--filter-height)',
    height: 'var(--filter-height)',
    padding: '0 0.5rem',
    fontSize: '0.85rem',
  },

  segment: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.25rem',
  },

  saveButton: {
    height: '20px !important',
    minHeight: '20px !important',
    fontSize: '0.82rem',

    svg: {
      width: 12,
      height: 12,
      margin: '0 !important',
    },
  },
}
