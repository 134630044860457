import React from 'react'
import autoAnimate from '@formkit/auto-animate'
import clsx from 'clsx'
import size from 'lodash/size'

import { DndContext, DragOverlay, PointerSensor, useDraggable, useDroppable, useSensor, useSensors } from '@dnd-kit/core'

import { COLORS } from '../../../theme'
import Glyph from '../../../components/Glyph'
import Portal from '../../../components/Portal'
import State from '../../../components/State'
import Status from '../../../components/Status'

import { ElementIcon } from './ElementIcon'
import { ELEMENTS } from '../elements/elements'
import { getConditionValueLabel } from '../utils/functions'
import { useFormBuilder } from '../useFormBuilder'
import { CONDITIONS_CONFIG } from '../utils/constants'

export const PagesNav = ({ className }: any) => {
  const pagesOrder: any = useFormBuilder((state: any) => state.pagesOrder)
  const movePage: any = useFormBuilder((state: any) => state.movePage)
  const moveElementToPage: any = useFormBuilder((state: any) => state.moveElementToPage)
  const moveElementToParent: any = useFormBuilder((state: any) => state.moveElementToParent)

  const [draggingPageId, setDraggingPageId] = React.useState(null)
  const [draggingElementId, setDraggingElementId] = React.useState(null)

  const [isDraggingPage, setIsDraggingPage] = React.useState(false)

  const renders = React.useRef(0)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  )

  const animationRef = React.useRef(null)

  React.useEffect(() => {
    animationRef.current && autoAnimate(animationRef.current)
  }, [animationRef])

  const onDragStart = React.useCallback((event) => {
    const { active } = event

    if (!active) return

    const { id: activeId } = active
    const { type: activeType } = active.data.current

    if (activeType === 'PAGE') {
      setDraggingPageId(activeId)
    } else if (activeType === 'ELEMENT') {
      setDraggingElementId(activeId)
    }
  }, [])

  const onDragOver = (event) => {
    const { active, over } = event

    if (!active || !over) return

    // enable flag so elements lists can close when dragging a page
    if (active.data.current?.type === 'PAGE') setIsDraggingPage(true)
  }

  const onDragEnd = React.useCallback((event) => {
    const { active, over } = event

    setDraggingPageId(null)
    setDraggingElementId(null)
    setIsDraggingPage(false)

    if (!over) return

    const currentActive = active.data.current
    const currentOver = over.data.current

    if (!currentActive || !currentOver) return

    const { pageId: fromPageId, type: activeType } = currentActive
    const { index: toIndex, pageId: toPageId, elementId: toElementId, type: overType } = currentOver

    // handle page drag and drop
    if (activeType === 'PAGE' && overType === 'PAGE_DROPPABLE') {
      movePage(fromPageId, toIndex)
    }

    // handle element drag and drop
    if (activeType === 'ELEMENT') {
      const { elementId } = currentActive

      // element dropped within page elements list at specific index
      if (overType === 'PAGE_ELEMENT_DROPPABLE') {
        moveElementToPage({ elementId, toPageId, toIndex })

        return
      }

      // element dropped on page nav item; move to top of page's elements list
      if (overType === 'PAGE_NAV_ITEM_DROPPABLE') {
        moveElementToPage({ elementId, toPageId, toIndex: 0 })

        return
      }

      // element dropped within another element's children list at specific index
      if (overType === 'CHILD_ELEMENT_DROPPABLE') {
        moveElementToParent({ elementId, toElementId, toIndex })

        return
      }
    }
  }, [])

  if (size(pagesOrder) === 0) {
    return <State isEmpty glyph="documents" glyphColor={COLORS.textMuted} emptyDescription="No pages created yet" />
  }

  return (
    <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} onDragOver={onDragOver} sensors={sensors}>
      {/* <div>Page Nav: {renders.current++}</div> */}

      <div ref={animationRef} className={className}>
        <PageDroppable index={0} />

        {pagesOrder.map((pageId: any, index) => {
          return (
            <React.Fragment key={pageId}>
              <PageNavItem pageId={pageId} index={index} isDraggingPage={isDraggingPage}>
                {!isDraggingPage && <PageElementsList pageId={pageId} />}
              </PageNavItem>
              <PageDroppable index={index + 1} />
            </React.Fragment>
          )
        })}
      </div>

      <Portal type="radix">
        <DragOverlay>
          {draggingPageId && <PagePreview key={draggingPageId} pageId={draggingPageId} />}
          {draggingElementId && <ElementPreview key={draggingElementId} elementId={draggingElementId} />}
        </DragOverlay>
      </Portal>
    </DndContext>
  )
}

const PageNavItem: any = (props: any) => {
  const { before, pageId, onClick, children, index, isDraggingPage } = props

  const page: any = useFormBuilder((state: any) => state.pages?.[pageId])
  const isEditable: any = useFormBuilder((state: any) => state.isEditable)
  const activePageId: any = useFormBuilder((state: any) => state.activePageId)
  const setActivePageId: any = useFormBuilder((state: any) => state.setActivePageId)
  const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)
  const setPageOpen: any = useFormBuilder((state: any) => state.setPageOpen)

  const isOpen = page?._isOpen
  const isActive = activePageId === pageId

  const { attributes, listeners, setNodeRef, setActivatorNodeRef } = useDraggable({
    id: pageId,
    data: {
      type: 'PAGE',
      pageId,
    },
    disabled: !isEditable,
  })

  const {
    setNodeRef: setDroppableNodeRef,
    isOver,
    active,
  } = useDroppable({
    id: `droppable-page-${pageId}`,
    data: {
      type: 'PAGE_NAV_ITEM_DROPPABLE',
      pageId,
      index,
    },
    disabled: !isEditable || isDraggingPage,
  })

  const renders = React.useRef(0)
  const isElementOver = isOver && active?.data?.current?.type === 'ELEMENT'

  const toggleOpen = () => {
    setPageOpen({ pageId, isOpen: !isOpen })
  }

  const handleClick = () => {
    setActivePageId(page.uuid)

    if (onClick) onClick(page)
  }

  if (!page) return null

  const isDisabled = !!page.config?.is_disabled

  const rootClasses = clsx(
    'sticky top-0 bg-white z-[3]',
    'flex items-center cursor-pointer rounded-[5px] font-[500] mt-[1px] relative hover:bg-[#f7f8fa] hover:shadow-[inset_0_0_0_1px] hover:shadow-divider hover:text-blue-500',
    isActive && !isDisabled && '!font-[700] !bg-vivid-blue-100 shadow-[inset_0_0_0_1px] shadow-divider',
    isActive && isDisabled && '!font-[700] !bg-hover shadow-[inset_0_0_0_1px] shadow-divider',
    isElementOver && '!shadow-[0_0_0_2px] !shadow-blue-500 !transition-shadow !rounded-[5px] z-[5]',
    isOpen && !isDraggingPage && 'shadow-[inset_0_0_0_1px] shadow-divider',
  )

  const innerClasses = clsx('truncate flex items-center flex-[1_1_auto] cursor-pointer flex-nowrap')

  const linkClasses = clsx('truncate flex items-center flex-[1_1_auto] min-h-[34px] min-w-0 py-0 px-2')

  return (
    <>
      <div ref={setDroppableNodeRef} className={rootClasses}>
        <div ref={setNodeRef} className={innerClasses} {...attributes}>
          {before}

          {isEditable && !isPreviewMode && (
            <>
              <div
                ref={setActivatorNodeRef}
                className="w-8 flex-[0_0_auto] flex self-stretch items-center justify-center !cursor-move hover:bg-hover"
                {...listeners}
              >
                <Glyph glyph="drag_and_drop" size={14} />
              </div>

              <button
                type="button"
                className="flex flex-[0_0_auto] items-center justify-center self-stretch w-6 border-none bg-transparent p-0 cursor-pointer hover:bg-hover"
                onClick={toggleOpen}
              >
                <Glyph
                  glyph="triangle_down"
                  size={10}
                  color={isDisabled ? COLORS.gray : COLORS.paleBlue}
                  className={clsx(!isOpen && '-rotate-90', isDisabled && 'opacity-80')}
                />
              </button>
            </>
          )}

          <div className={linkClasses} onClick={handleClick}>
            <Glyph
              glyph="document"
              size={18}
              color={isDisabled ? COLORS.gray : COLORS.paleBlue}
              className={clsx('mr-1.5 flex-[0_0_auto]', isDisabled && 'opacity-60')}
            />
            <div className="truncate min-w-0 flex-[1_1_auto]">{page.name}</div>
            {isDisabled && <Status small glyph="hide" glyphColor={COLORS.gray} label="Disabled" color="gray" className="!pl-1" />}
            {/* <div> – Renders: {renders.current++}</div> */}
          </div>
        </div>
      </div>

      {isOpen && isEditable && !isPreviewMode && children}
    </>
  )
}

const PageDroppable: any = React.memo(
  ({ index, isEmpty }: any) => {
    const isEditable: any = useFormBuilder((state: any) => state.isEditable)

    const { setNodeRef, isOver, active } = useDroppable({
      id: `page-droppable-${index}`,
      data: {
        type: 'PAGE_DROPPABLE',
        index,
      },
      disabled: !isEditable,
    })

    const isPageOver = isOver && active?.data?.current?.type === 'PAGE'

    if (isEmpty) {
      const emptyClasses = clsx(
        'h-24 bg-white rounded-[5px] border-dashed border-divider text-center flex items-center justify-center p-4 text-text-strongly-muted italic text-[0.88rem]',
        isPageOver && 'bg-vivid-blue-100 border-vivid-blue-400 !opacity-100',
      )

      return (
        <div ref={setNodeRef} className={emptyClasses}>
          No elements added yet
        </div>
      )
    }

    const rootClasses = clsx(
      'h-[2px] w-full relative z-[10]',
      isPageOver && 'bg-blue-500 !opacity-100 border-t border-b border-vivid-blue-400',
    )

    return <div ref={setNodeRef} className={rootClasses} />
  },
  (prevProps, nextProps) => {
    return prevProps.index === nextProps.index && prevProps.isEmpty === nextProps.isEmpty
  },
)

const PageElementsList: any = ({ pageId }: any) => {
  const activePage: any = useFormBuilder((state: any) => state.pages?.[pageId]?.elements_order)
  const elementsOrder: any = useFormBuilder((state: any) => state.pages?.[pageId]?.elements_order)

  const animationRef = React.useRef(null)

  const renders = React.useRef(0)

  React.useEffect(() => {
    animationRef.current && autoAnimate(animationRef.current)
  }, [animationRef])

  if (!activePage) return null

  const isEmpty = size(elementsOrder) === 0

  return (
    <div className="pl-7 py-1">
      {/* <div>Page Elements List: {renders.current++}</div> */}

      <PageElementDroppable pageId={pageId} index={0} isEmpty={isEmpty} />

      <div ref={animationRef}>
        {elementsOrder.map((elementId: any, index: number) => {
          return (
            <React.Fragment key={elementId}>
              <ElementDraggable elementId={elementId} pageId={pageId} index={index} />
              <PageElementDroppable pageId={pageId} index={index + 1} />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

const PagePreview: any = React.memo(
  ({ pageId }: any) => {
    const pageName: any = useFormBuilder((state: any) => state.pages?.[pageId]?.name)

    const renders = React.useRef(0)

    if (!pageName) return null

    const rootClasses = clsx(
      'flex items-center cursor-pointer rounded-[5px] font-[500] mt-[1px] relative truncate',
      '!bg-white bg-opacity-90 rounded-[5px] shadow-soft-3 shadow-divider pr-4 whitespace-nowrap',
    )

    const linkClasses = clsx('flex items-center flex-[1_1_auto] min-h-[34px] py-0 px-2 truncate')

    return (
      <div className={rootClasses}>
        <div className={linkClasses}>
          <Glyph glyph="document" size={18} color={COLORS.paleBlue} className="mr-1.5" />
          <div className="truncate">{pageName}</div>
          {/* <div> – Renders: {renders.current++}</div> */}
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.pageId === nextProps.pageId
  },
)

const PageElementDroppable: any = React.memo(
  ({ pageId, index, isEmpty }: any) => {
    const isEditable: any = useFormBuilder((state: any) => state.isEditable)

    const { setNodeRef, isOver, active } = useDroppable({
      id: `element-droppable-${index}-${pageId}`,
      data: {
        type: 'PAGE_ELEMENT_DROPPABLE',
        pageId,
        index,
      },
      disabled: !isEditable,
    })

    const isElementOver = isOver && active?.data?.current?.type === 'ELEMENT'

    if (isEmpty) {
      const emptyClasses = clsx(
        'h-24 bg-white rounded-[5px] border-dashed border-divider text-center flex items-center justify-center p-4 text-text-strongly-muted italic text-[0.88rem]',
        isElementOver && 'bg-vivid-blue-100 border-vivid-blue-400 !opacity-100',
      )

      return (
        <div ref={setNodeRef} className={emptyClasses}>
          No elements added yet
        </div>
      )
    }

    const rootClasses = clsx('h-[2px] w-full', isElementOver && 'bg-blue-500 !opacity-100 border-t border-b border-vivid-blue-400')

    return <div ref={setNodeRef} className={rootClasses} />
  },
  (prevProps, nextProps) => {
    return (
      prevProps.pageId === nextProps.pageId &&
      prevProps.index === nextProps.index &&
      prevProps.isEmpty === nextProps.isEmpty &&
      prevProps.type === nextProps.type
    )
  },
)

const ElementDraggable: any = React.memo(
  ({ elementId, pageId, index }: any) => {
    const isEditable: any = useFormBuilder((state: any) => state.isEditable)
    const element: any = useFormBuilder((state: any) => state.allElements?.[elementId])
    const setActiveElementId: any = useFormBuilder((state: any) => state.setActiveElementId)
    const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)

    const conditionalDependantElement: any = useFormBuilder((state: any) => state.allElements?.[element?.config?.element_uuid])

    const [isOpen, setIsOpen] = React.useState(false)

    const { attributes, listeners, setNodeRef, setActivatorNodeRef, isDragging } = useDraggable({
      id: elementId,
      data: {
        type: 'ELEMENT',
        elementId,
        pageId,
        index,
      },
      disabled: !isEditable,
    })

    const ELEMENT = React.useMemo(() => {
      return ELEMENTS?.[element?.category]
    }, [element])

    const renders = React.useRef(0)

    const handleClick = () => {
      setActiveElementId(elementId)
    }

    const toggleOpen = () => {
      setIsOpen((c) => !c)
    }

    if (!element || !ELEMENT) return null

    const isActive = !!element?._isActive

    const allowChildren = !!ELEMENT?.allowChildren

    let title = ELEMENT.renderTitle?.(element.config) || ELEMENT.name

    if (element.category === 'conditional') {
      const conditionValueLabel = getConditionValueLabel(element, conditionalDependantElement)
      const conditionLabel = CONDITIONS_CONFIG?.[element?.config?.condition]

      title = conditionalDependantElement?.config?.label ? (
        <>
          <span>Show when</span>
          <span> "{conditionalDependantElement.config.label}" </span>
          {conditionLabel && <span> {conditionLabel} </span>}
          <span>{conditionValueLabel ? ` "${conditionValueLabel}" ` : '…'}</span>
        </>
      ) : (
        'Show when…'
      )
    }

    const rootClasses = clsx(
      'flex items-center flex-nowrap cursor-pointer bg-white rounded-[5px]',
      isActive && '!font-[700] !bg-vivid-blue-100 shadow-[inset_0_0_0_1px] shadow-divider',
      isDragging && 'opacity-50 transition-shadow',
      !isDragging && 'hover:bg-hover hover:text-blue-500',
    )

    const linkClasses = clsx('flex items-center flex-nowrap flex-[1_1_auto] min-h-[34px] py-0 px-2 truncate')

    return (
      <>
        <div ref={setNodeRef} className={rootClasses} {...attributes}>
          {isEditable && !isPreviewMode && (
            <>
              <div
                ref={setActivatorNodeRef}
                className="flex-[0_0_auto] w-7 flex self-stretch items-center justify-center !cursor-move hover:bg-hover"
                {...listeners}
              >
                <Glyph glyph="drag_and_drop" size={14} />
              </div>

              {allowChildren ? (
                <button
                  type="button"
                  className="flex flex-[0_0_auto] items-center justify-center self-stretch w-7 border-none bg-transparent p-0 cursor-pointer hover:bg-hover"
                  onClick={toggleOpen}
                >
                  <Glyph glyph="triangle_down" size={10} className={isOpen ? '' : '-rotate-90'} />
                </button>
              ) : (
                <div className="flex flex-[0_0_auto] items-center justify-center w-7">
                  <Glyph glyph="circle" size={4} color={COLORS.textMuted} className="opacity-20" />
                </div>
              )}
            </>
          )}

          <div className={linkClasses} onClick={handleClick}>
            <ElementIcon elementCategory={element.category} className="mr-1.5 w-[20px] h-[20px]" />
            <div className="text-[0.88rem] font-[500] py-1.5 truncate flex-[1_1_auto]">{title}</div>
            {element?._isConditionalTrigger && <div css={STYLES.conditionalTrigger}>Condition Trigger</div>}

            {/* <div className="text-[0.88rem] ml-auto">{renders.current++}</div> */}
          </div>
        </div>

        {isOpen && allowChildren && <ElementChildrenList parentElementId={elementId} isDraggingParent={isDragging} />}
      </>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.elementId === nextProps.elementId && prevProps.pageId === nextProps.pageId && prevProps.index === nextProps.index
  },
)

const ElementPreview: any = React.memo(
  ({ elementId }: any) => {
    const element: any = useFormBuilder((state: any) => state.allElements?.[elementId])
    const setActiveElementId: any = useFormBuilder((state: any) => state.setActiveElementId)

    const ELEMENT = React.useMemo(() => {
      return ELEMENTS?.[element?.category]
    }, [element])

    const renders = React.useRef(0)

    const handleClick = () => {
      setActiveElementId(elementId)
    }

    if (!element || !ELEMENT) return null

    let title = ELEMENT.renderTitle?.(element.config) || ELEMENT.name

    const rootClasses = clsx(
      'flex items-center flex-nowrap cursor-pointer bg-white rounded-[5px] truncate',
      '!bg-white bg-opacity-90 rounded-[5px] shadow-soft-3 shadow-divider pr-4 whitespace-nowrap',
    )

    return (
      <div className={rootClasses}>
        <div className="w-8 flex self-stretch items-center justify-center !cursor-move hover:bg-hover">
          <Glyph glyph="drag_and_drop" size={14} />
        </div>
        <ElementIcon elementCategory={element.category} className="mr-1.5 w-[20px] h-[20px]" />
        <div className="text-[0.88rem] font-[500] py-1.5 truncate">{title}</div>
        {/* <div className="text-[0.88rem] ml-auto">{renders.current++}</div> */}
      </div>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.elementId === nextProps.elementId
  },
)

const ElementChildrenList: any = React.memo(({ parentElementId, isDraggingParent }: any) => {
  const elementsOrder: any = useFormBuilder((state: any) => state.allElements?.[parentElementId]?.elements_order)
  const isEditable: any = useFormBuilder((state: any) => state.isEditable)

  const animationRef = React.useRef(null)

  React.useEffect(() => {
    animationRef.current && autoAnimate(animationRef.current)
  }, [animationRef])

  const isEmpty = size(elementsOrder) === 0

  const rootClasses = clsx('pl-7 py-1', isDraggingParent && 'pointer-events-none opacity-50')

  return (
    <div className={rootClasses}>
      {/* <div>Page Elements List: {renders.current++}</div> */}

      <ChildElementDroppable elementId={parentElementId} index={0} isEmpty={isEmpty} isDisabled={isDraggingParent} />

      <div ref={animationRef}>
        {elementsOrder.map((elementId: any, index: number) => {
          return (
            <React.Fragment key={elementId}>
              <ElementDraggable elementId={elementId} index={index} />
              <ChildElementDroppable elementId={parentElementId} index={index + 1} isDisabled={isDraggingParent} />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
})

const ChildElementDroppable: any = React.memo(({ elementId, index, isEmpty, isDisabled }: any) => {
  const isEditable: any = useFormBuilder((state: any) => state.isEditable)

  const { setNodeRef, isOver, active } = useDroppable({
    id: `child-element-droppable-${index}-${elementId}`,
    data: {
      type: 'CHILD_ELEMENT_DROPPABLE',
      elementId,
      index,
    },
    disabled: !isEditable || isDisabled,
  })

  const isElementOver = isOver && active?.data?.current?.type === 'ELEMENT'

  if (isEmpty) {
    const emptyClasses = clsx(
      'h-24 bg-white rounded-[5px] border-dashed border-divider text-center flex items-center justify-center p-4 text-text-strongly-muted italic text-[0.88rem]',
      isElementOver && 'bg-vivid-blue-100 border-vivid-blue-400 !opacity-100',
    )

    return (
      <div ref={setNodeRef} className={emptyClasses}>
        No elements added yet
      </div>
    )
  }

  const rootClasses = clsx('h-[2px] w-full', isElementOver && 'bg-blue-500 !opacity-100 border-t border-b border-vivid-blue-400')

  return <div ref={setNodeRef} className={rootClasses} />
})

const STYLES = {
  conditionalTrigger: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    borderRadius: 4,
    fontWeight: 600,
    padding: '0.05rem 0.35rem',
    pointerEvents: 'none !important',
    color: COLORS.white,
    background: COLORS.green,
    letterSpacing: 0.2,
    textTransform: 'uppercase',
  },
}
