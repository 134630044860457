import React from 'react'
import { v4 as uuid } from 'uuid'
import * as SwitchUI from '@radix-ui/react-switch'

import { isDefined } from '../../utils/functions'
import { withFormContext } from './context'

import Value from '../Value'
import FieldBase from './FieldBase'

import { COLORS, SHADOW } from '../../theme'

class Switch extends FieldBase {
  constructor(props) {
    super(props)

    let value = props.value
    if (props.form) {
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)
      if (isDefined(initialModelVal)) value = initialModelVal
    }

    this.state = {
      type: 'SWITCH',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      label: props.label,
      value: value,
      isChecked: props.inverse ? !value : value,
      isDisabled: props.isDisabled,
      isValidations: null,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValid: true,
      isInvalid: false,
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value,
      isValid: true,
      isInvalid: false,
    }

    this.updateType = 'DATA'
  }

  toggle = () => {
    const newValue = !this.state.value
    this.changeValue(newValue)
    this.setState({ isChecked: this.props.inverse ? !newValue : newValue })
  }

  editRender = () => (
    <SwitchUI.Root css={[styles.switch, sizeStyles[this.props.size].switch]} checked={this.state.isChecked} onClick={this.toggle}>
      <SwitchUI.Thumb css={[styles.thumb, sizeStyles[this.props.size].thumb]} />
    </SwitchUI.Root>
  )

  readOnlyRender = () => (
    <Value
      data-test={this.props.testKey}
      value={this.props.label}
      description={this.props.description}
      glyph={this.state.isChecked ? this.props.trueIcon : this.props.falseIcon}
    />
  )
}

export const PlainSwitch = ({ size = 200, isChecked, onCheckedChange, className }: any) => {
  return (
    <SwitchUI.Root
      css={[styles.switch, sizeStyles[size].switch]}
      className={className}
      checked={isChecked}
      onCheckedChange={onCheckedChange}
    >
      <SwitchUI.Thumb css={[styles.thumb, sizeStyles[size].thumb]} />
    </SwitchUI.Root>
  )
}

Switch.defaultProps = {
  size: 300,
  value: false,
  isEditable: true,
  isDisabled: false,
  trueIcon: 'check',
  falseIcon: 'cross',
  inverse: false,
  label: ' ',
  validateOn: 'blur-change',
}

const styles = {
  switch: {
    all: 'unset',
    backgroundColor: COLORS.lightGray,
    borderRadius: '9999px',
    position: 'relative',
    boxShadow: SHADOW(1),
    cursor: 'pointer',
    marginBottom: '0 !important',
    flex: '0 0 auto',
    '&[data-state="checked"]': { backgroundColor: COLORS.green },
  },
  thumb: {
    display: 'block',
    backgroundColor: 'white',
    borderRadius: '9999px',
    boxShadow: SHADOW(1),
    transition: 'transform 100ms',
    transform: 'translateX(2px)',
    willChange: 'transform',
  },
}

const sizeStyles = {
  100: {
    switch: {
      width: 30,
      height: 18,
    },
    thumb: {
      width: 14,
      height: 14,
      '&[data-state="checked"]': { transform: 'translateX(14px)' },
    },
  },
  200: {
    switch: {
      width: 38,
      height: 22,
    },
    thumb: {
      width: 18,
      height: 18,
      '&[data-state="checked"]': { transform: 'translateX(18px)' },
    },
  },
  300: {
    switch: {
      width: 42,
      height: 25,
    },
    thumb: {
      width: 21,
      height: 21,
      '&[data-state="checked"]': { transform: 'translateX(19px)' },
    },
  },
}

export default withFormContext(Switch)
