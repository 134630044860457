import React from 'react'

import Card from './Card'
import DonutChart from './DonutChart'
import Flex from './Flex'
import Grid from './Grid'
import Loader from './Loader'
import SummonOverlay from './SummonOverlay'

import { Text } from './Typography'
import { COLORS } from '../theme'

import ShareBedAvailabilityFormOverlay from './Overlays/actions/ShareBedAvailabilityFormOverlay'

import { apiGet } from '../modules/api'
import Notifications from '../modules/notifications'
import Tenant from '../declarations/Tenant.d'

import withSettings from '../hocs/withSettings'

type Props = {
  tenant: Tenant
}

type BedAvailabilityResponse = {
  beds_available: number
  beds_total: number
}

const BedAvailabilityWidgetCTA: React.FC<Props> = ({ tenant }) => {
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState({ beds_available: 0, beds_total: 0 })

  React.useEffect(() => {
    setLoading(true)

    const fetchAvailability = async () => {
      try {
        let result = await apiGet<BedAvailabilityResponse>({ url: `/apps/availability/beds/${tenant?.external_id}` })
        setData(result.data)
      } catch (errors) {
        Notifications.send('Something went wrong fetching Bed Availability', 'negative')
      } finally {
        setLoading(false)
      }
    }

    fetchAvailability()
  }, [])

  const percentage = data.beds_available && data.beds_total ? Math.round((data.beds_available * 100) / data.beds_total) : 0

  return (
    <SummonOverlay overlay={<ShareBedAvailabilityFormOverlay subdomain={tenant.subdomain} timezone={tenant.timezone} />} flex="1 1 auto">
      <Card css={{ padding: '.4rem .5rem', marginBottom: '1rem', cursor: 'pointer', display: 'flex' }}>
        <Grid columns="38px auto" gap={5}>
          <Flex justifyContent="center" alignItems="center" className="!h-full">
            {loading ? <Loader color={COLORS.paleGreen} /> : <DonutChart value={percentage} size={36} strokeWidth={4} labelSize={11} />}
          </Flex>
          <Flex vertical className="!text-[1rem]">
            <Text color={COLORS.blue} fontWeight={600} className="text-[1.1rem]">
              Bed Availability Widget
            </Text>
            <Text color={COLORS.text}>Share on your website</Text>
          </Flex>
        </Grid>
      </Card>
    </SummonOverlay>
  )
}

export default withSettings(BedAvailabilityWidgetCTA)
