import React from 'react'

import { COLORS, ICONS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PropertyProfileHeader from '../../components/PropertyProfileHeader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Tooltip from '../../components/Tooltip'

const RootLocationBedOverlay = (props: any) => {
  const { position = 'center' } = props

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialData,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    params,
  } = useOverlay({
    name: 'bed',
    endpoint: '/beds',
    invalidate: 'beds',
    invalidateKeys: ['bed-management-timeline', 'location'],
    options: props,
  })

  const { resource_id, floor_id, room_id }: any = params

  // get property data from API if not passed via router
  const { data: apiProperty, isLoading: isPropertyLoading }: any = useGet({
    name: ['location', resource_id],
    url: `/houses/${resource_id}`,
    options: { enabled: !!resource_id && !initialData?.property },
  })

  // get property data from router or from API
  const propertyData = initialData?.property || apiProperty

  const { timezone, isBehave } = useSettings()

  if (!room_id) return null

  if (isOverlayLoading || isPropertyLoading) {
    return <OverlayLoader position={position} />
  }

  return (
    <Overlay showBackdrop position={position} onClose={close}>
      <Overlay.Header
        icon={ICONS.bed_management}
        title={isNew ? 'Add New Bed' : data?.name}
        description={propertyData && <PropertyProfileHeader property={propertyData} />}
      />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew &&
            propertyData && {
              place_id: room_id,
              place_type: 'room',
            }
          }
        >
          <Section>
            <FormSection>
              <Flex stretchChildrenX gap="1rem">
                <Input
                  model="name"
                  label="Bed Name"
                  defaultValue={`Bed ${initialData?.noOfBeds + 1}`}
                  validations={{
                    presence: {
                      message: 'Please enter a bed name',
                    },
                  }}
                />

                <Select
                  label="Accepted Gender"
                  model="sex"
                  layout="vertical-dense"
                  validations={{
                    presence: {
                      message: 'Please select the accepted gender',
                    },
                  }}
                  defaultValue="mixed"
                >
                  <Option label="Male" value="male" />
                  <Option label="Female" value="female" />
                  <Option label="Mixed" value="mixed" />
                </Select>
              </Flex>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            {data && (
              <Tooltip
                color={COLORS.red}
                show={!isBehave && !data?.is_deletable}
                content={
                  <>
                    <div>Deleting a bed with connected occupancies cannot be done manually,</div>
                    <div>please contact support for assistance with this action.</div>
                  </>
                }
              >
                <DeleteDialog
                  title="Delete Bed?"
                  message="Are you sure you want to delete this bed? This action cannot be undone."
                  onYes={deleteRecord}
                  isDisabled={isBehave ? false : !data?.is_deletable}
                >
                  <Button
                    fullWidth
                    glyph="delete"
                    label="Delete"
                    type="default"
                    color="red"
                    isLoading={isDeleting}
                    isDisabled={isBehave ? false : !data?.is_deletable}
                  />
                </DeleteDialog>
              </Tooltip>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const LocationBedOverlay = withOverlayError(RootLocationBedOverlay)
