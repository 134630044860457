import React from 'react'
import { transparentize } from 'polished'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../theme'

import Dropdown from './Dropdown'
import NavGroup from './NavGroup'
import NavItem from './NavItem'
import SummonOverlay from './SummonOverlay'
import Permission from './Permission'

import { CommunicationOverlay } from '../constructs/Communications/CommunicationOverlay'
import { BreathalyzerTestOverlay } from '../constructs/TestResults/BreathalyzerTestOverlay'
import { UATestOverlay } from '../constructs/TestResults/UATestOverlay'
import { ShiftNoteOverlay } from '../constructs/ShiftNotes/ShiftNoteOverlay'

import EventBuilderOverlay from './Overlays/pages/Calendar/EventBuilderOverlay'
import TodoBuilderOverlay from './Overlays/pages/Calendar/TodoBuilderOverlay'

import { NewApplicantOverlay } from '../constructs/Clients/NewApplicantOverlay'

import { MeetingContext } from './Meeting/context'

import { BreathalyzerTestWorksheetOverlay } from '../constructs/TestResults/BreathalyzerTestWorksheetOverlay'
import { CommunicationWorksheetOverlay } from '../constructs/Communications/CommunicationWorksheetOverlay'
import { GeolocatedLocationCheckInWorksheetOverlay } from '../constructs/LocationCheckIns/GeolocatedLocationCheckInWorksheetOverlay'
import { MedicationLogOverlay } from '../constructs/Medications/MedicationLogOverlay'
import { MedicationLogWorksheetOverlay } from '../constructs/Medications/MedicationLogWorksheetOverlay'
import { MedicationWorksheetOverlay } from '../constructs/Medications/MedicationWorksheetOverlay'
import { PaymentWorksheetOverlay } from '../constructs/Financials/PaymentWorksheetOverlay'
import { ShiftNoteWorksheetOverlay } from '../constructs/ShiftNotes/ShiftNoteWorksheetOverlay'
import { UATestWorksheetOverlay } from '../constructs/TestResults/UATestWorksheetOverlay'

const QuickAdd = ({ className, hideLabel, isFloating }: any) => {
  const author = useSelector((state: any) => state.me?.user)

  const [eventOpen, setEventOpen] = React.useState(false)
  const [taskOpen, setTaskOpen] = React.useState(false)
  const [shiftNoteOpen, setShiftNoteOpen] = React.useState(false)
  const [applicantOpen, setApplicantOpen] = React.useState(false)
  const [communicationLogOpen, setCommunicationLogOpen] = React.useState(false)
  const [uaTestOpen, setUaTestOpen] = React.useState(false)
  const [breathalyzerTestOpen, setBreathalyzerTestOpen] = React.useState(false)
  const [medicationLogOpen, setMedicationLogOpen] = React.useState(false)

  const [breathalyzerTestWorksheetOpen, setBreathalyzerTestWorksheetOpen] = React.useState(false)
  const [medicationWorksheetOpen, setMedicationWorksheetOpen] = React.useState(false)
  const [medicationLogWorksheetOpen, setMedicationLogWorksheetOpen] = React.useState(false)
  const [shiftNoteWorksheetOpen, setShiftNoteWorksheetOpen] = React.useState(false)
  const [uaTestWorksheetOpen, setUaTestWorksheetOpen] = React.useState(false)
  const [communicationLogWorksheetOpen, setCommunicationLogWorksheetOpen] = React.useState(false)
  const [locationChecksWorksheetOpen, setLocationChecksWorksheetOpen] = React.useState(false)
  const [paymentsWorksheetOpen, setPaymentsWorksheetOpen] = React.useState(false)

  const { createQuickMeeting }: any = React.useContext(MeetingContext)

  const classNames = clsx(className, { 'is-floating': isFloating })

  return (
    <div className={classNames} css={styles}>
      <Dropdown
        label="Add New…"
        minWidth={200}
        buttonSize={300}
        buttonType="primary"
        glyph="add"
        hideLabel={hideLabel}
        hideCloseButton={isFloating}
        buttonClassName="quick-add-button"
      >
        <div className="!px-4 !py-2">
          <Permission featureFlagV2="bulk_add">
            <NavGroup label="Add Many">
              <NavItem
                skipActive
                asLink={false}
                icon="housing_notes"
                label="Shift Notes"
                onClick={() => setShiftNoteWorksheetOpen(true)}
                permission="shift_notes.create"
                featureFlagV2="shift_notes"
              />
              <NavItem
                skipActive
                asLink={false}
                icon="recovery_navigation"
                label="Location Check-In's"
                onClick={() => setLocationChecksWorksheetOpen(true)}
                permission="location_checkin.create"
                featureFlagV2="location_gps_check_in"
              />
              <NavItem
                skipActive
                asLink={false}
                icon="communications"
                label="Communication Logs"
                onClick={() => setCommunicationLogWorksheetOpen(true)}
                permission="communications.create"
                featureFlagV2="communication_logs"
              />
              <NavItem
                skipActive
                asLink={false}
                icon="financials"
                label="Payments"
                onClick={() => setPaymentsWorksheetOpen(true)}
                permission="ledger.create"
                featureFlagV2="financials"
              />
              <NavItem
                skipActive
                asLink={false}
                icon="test_results"
                label="UA Tests"
                onClick={() => setUaTestWorksheetOpen(true)}
                permission="test_results.ua_tests.create"
                featureFlagV2="ua_tests"
              />
              <NavItem
                skipActive
                asLink={false}
                icon="test_results"
                label="Breathalyzer Tests"
                onClick={() => setBreathalyzerTestWorksheetOpen(true)}
                permission="test_results.breathalyzer_tests.create"
                featureFlagV2="breathalyzer_tests"
              />
              <NavItem
                skipActive
                asLink={false}
                icon="medication"
                label="Medications"
                onClick={() => setMedicationWorksheetOpen(true)}
                permission="medications.create"
                featureFlagV2="medications"
              />
              <NavItem
                skipActive
                asLink={false}
                icon="med_pass"
                label="Medication Logs"
                onClick={() => setMedicationLogWorksheetOpen(true)}
                permission="medication_logs.create"
                featureFlagV2="medication_logs"
              />
            </NavGroup>
          </Permission>

          <NavGroup label="Add One">
            <NavItem
              skipActive
              asLink={false}
              icon="tasks"
              label="To-Do"
              onClick={() => setTaskOpen(true)}
              permission="todos.create"
              featureFlagV2="todos"
            />
            <NavItem
              icon="calendar"
              label="Event"
              onClick={() => setEventOpen(true)}
              skipActive
              asLink={false}
              featureFlag="events"
              permission="events.create"
              featureFlagV2="calendar"
            />
            <NavItem
              skipActive
              asLink={false}
              icon="housing_notes"
              label="Shift Note"
              onClick={() => setShiftNoteOpen(true)}
              permission="shift_notes.create"
              featureFlagV2="shift_notes"
            />
            <NavItem
              skipActive
              asLink={false}
              icon="admissions"
              label="Applicant"
              onClick={() => setApplicantOpen(true)}
              permission="applications.create"
              featureFlagV2="admissions"
            />
            <NavItem
              skipActive
              asLink={false}
              icon="communications"
              label="Communication Log"
              onClick={() => setCommunicationLogOpen(true)}
              permission="communications.create"
              featureFlagV2="communication_logs"
            />
            <NavItem
              skipActive
              asLink={false}
              icon="test_results"
              label="UA Test"
              onClick={() => setUaTestOpen(true)}
              permission="test_results.ua_tests.create"
              featureFlagV2="ua_tests"
            />
            <NavItem
              skipActive
              asLink={false}
              icon="test_results"
              label="Breathalyzer Test"
              onClick={() => setBreathalyzerTestOpen(true)}
              permission="test_results.breathalyzer_tests.create"
              featureFlagV2="breathalyzer_tests"
            />
            <NavItem
              skipActive
              asLink={false}
              icon="med_pass"
              label="Medication Log"
              onClick={() => setMedicationLogOpen(true)}
              permission="medication_logs.create"
              featureFlagV2="medication_logs"
            />
          </NavGroup>

          <NavGroup label="Actions">
            <NavItem
              skipActive
              asLink={false}
              icon="telehealth"
              label="Start Quick Meeting"
              onClick={createQuickMeeting}
              permission="telehealth.create"
              featureFlagV2="telehealth"
            />
          </NavGroup>
        </div>
      </Dropdown>

      <SummonOverlay overlay={<TodoBuilderOverlay skipReduxBucket />} isOpen={taskOpen} onClose={() => setTaskOpen(false)} />
      <SummonOverlay overlay={<EventBuilderOverlay skipReduxBucket />} isOpen={eventOpen} onClose={() => setEventOpen(false)} />

      <SummonOverlay
        overlay={
          <ShiftNoteOverlay
            skipReduxBucket
            dataID="new"
            initialData={{
              employees: [author],
            }}
          />
        }
        isOpen={shiftNoteOpen}
        onClose={() => setShiftNoteOpen(false)}
      />
      <SummonOverlay
        overlay={<ShiftNoteWorksheetOverlay />}
        isOpen={shiftNoteWorksheetOpen}
        onClose={() => setShiftNoteWorksheetOpen(false)}
      />

      <SummonOverlay
        overlay={<GeolocatedLocationCheckInWorksheetOverlay />}
        isOpen={locationChecksWorksheetOpen}
        onClose={() => setLocationChecksWorksheetOpen(false)}
      />

      <SummonOverlay overlay={<PaymentWorksheetOverlay />} isOpen={paymentsWorksheetOpen} onClose={() => setPaymentsWorksheetOpen(false)} />

      <SummonOverlay
        overlay={<NewApplicantOverlay skipReduxBucket dataID="new" />}
        isOpen={applicantOpen}
        onClose={() => setApplicantOpen(false)}
      />

      <SummonOverlay
        overlay={<CommunicationWorksheetOverlay />}
        isOpen={communicationLogWorksheetOpen}
        onClose={() => setCommunicationLogWorksheetOpen(false)}
      />
      <SummonOverlay
        overlay={<CommunicationOverlay skipReduxBucket dataID="new" initialData={{ employee: author }} />}
        isOpen={communicationLogOpen}
        onClose={() => setCommunicationLogOpen(false)}
      />

      <SummonOverlay overlay={<UATestOverlay skipReduxBucket dataID="new" />} isOpen={uaTestOpen} onClose={() => setUaTestOpen(false)} />
      <SummonOverlay overlay={<UATestWorksheetOverlay />} isOpen={uaTestWorksheetOpen} onClose={() => setUaTestWorksheetOpen(false)} />

      <SummonOverlay
        overlay={<BreathalyzerTestOverlay skipReduxBucket dataID="new" />}
        isOpen={breathalyzerTestOpen}
        onClose={() => setBreathalyzerTestOpen(false)}
      />

      <SummonOverlay
        overlay={<BreathalyzerTestWorksheetOverlay />}
        isOpen={breathalyzerTestWorksheetOpen}
        onClose={() => setBreathalyzerTestWorksheetOpen(false)}
      />

      <SummonOverlay
        overlay={<MedicationLogOverlay skipReduxBucket dataID="new" />}
        isOpen={medicationLogOpen}
        onClose={() => setMedicationLogOpen(false)}
      />
      <SummonOverlay
        overlay={<MedicationLogWorksheetOverlay />}
        isOpen={medicationLogWorksheetOpen}
        onClose={() => setMedicationLogWorksheetOpen(false)}
      />
      <SummonOverlay
        overlay={<MedicationWorksheetOverlay />}
        isOpen={medicationWorksheetOpen}
        onClose={() => setMedicationWorksheetOpen(false)}
      />
    </div>
  )
}

const styles = {
  '&.is-floating': {
    position: 'fixed',
    bottom: 10,
    right: 10,
    zIndex: 3,

    '.quick-add-button': {
      width: 56,
      height: 56,
      borderRadius: '50%',
      boxShadow: `${SHADOW(4, transparentize(0.7, COLORS.blue))}`,

      svg: {
        width: 20,
        height: 20,
      },
    },
  },
}

export default QuickAdd
