import React from 'react'
import size from 'lodash/size'

import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import Tooltip from '../../../components/Tooltip'
import { useFormBuilder } from '../useFormBuilder'

export const MoveElementActions = ({ elementId, className }) => {
  const moveElement: any = useFormBuilder((state) => state.moveElement)
  const findElementPageId: any = useFormBuilder((state) => state.findElementPageId)

  const parentElementId: any = useFormBuilder((state) => state.allElements?.[elementId]?.parent_uuid)
  const parentElement: any = useFormBuilder((state) => state.allElements?.[parentElementId])

  const pageId: any = findElementPageId(elementId)
  const parentPage: any = useFormBuilder((state) => state.pages[pageId])

  const parentElementsOrder = parentElement ? parentElement.elements_order : parentPage?.elements_order
  const elementsOrderCount = size(parentElementsOrder)

  let canMoveUp = false
  let canMoveDown = false

  const moveUp = () => {
    moveElement(elementId, 'up')
  }

  const moveDown = () => {
    moveElement(elementId, 'down')
  }

  if (elementsOrderCount > 1) {
    const index = parentElementsOrder?.indexOf?.(elementId)

    canMoveUp = index > 0
    canMoveDown = index < elementsOrderCount - 1
  }

  if (!canMoveUp && !canMoveDown) return null

  return (
    <div className={className}>
      {(canMoveUp || canMoveDown) && (
        <Flex stretchChildrenX gap="0.5rem">
          <Tooltip
            show={canMoveUp}
            className="flex-[1_1_auto]"
            content={
              <div className="text-center italic text-[0.9rem]">
                or use{' '}
                <span className="inline-block px-1 py-0 bg-white rounded-[4px] border border-solid border-text-strongly-muted">↑</span>{' '}
                arrow on keyboard
              </div>
            }
          >
            <Button
              label="Move Up"
              type="default"
              size={100}
              glyph="triangle_up"
              onClick={moveUp}
              isDisabled={!canMoveUp}
              color={canMoveUp ? 'blue' : 'text'}
              className="flex-[1_1_auto]"
            />
          </Tooltip>

          <Tooltip
            show={canMoveDown}
            className="flex-[1_1_auto]"
            content={
              <div className="text-center italic text-[0.9rem]">
                or use{' '}
                <span className="inline-block px-1 py-0 bg-white rounded-[4px] border border-solid border-text-strongly-muted">↓</span>{' '}
                arrow on keyboard
              </div>
            }
          >
            <Button
              label="Move Down"
              type="default"
              size={100}
              glyph="triangle_down"
              onClick={moveDown}
              isDisabled={!canMoveDown}
              color={canMoveDown ? 'blue' : 'text'}
              className="flex-[1_1_auto]"
            />
          </Tooltip>
        </Flex>
      )}
    </div>
  )
}
