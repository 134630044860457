import React from 'react'

import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks'

import HelpTagIframe from '../Help/HelpTagIframe'
import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const billingColumn = (timezone: string) => ({
  width: 140,
  accessor: 'billed_at',
  Header: 'Billed',
  Cell: ({ row, value }) => {
    if (!row.original.is_billed) return '–'
    return usDateTime(value, timezone)
  },
  Filter: TableFilter.Date,
  filter: 'date',
})

const columns = (to: Function = () => {}, timezone: string, isBehave: boolean) => [
  {
    width: 220,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'collected_at',
    Header: 'Date & Time',
    Cell: ({ row, value }) => <TableCell.MainLink to={to(row.original.id)} label={usDateTime(value, timezone)} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  ...(isBehave ? [billingColumn(timezone)] : []),
  {
    width: 180,
    id: 'client',
    accessor: 'resident',
    Header: 'Client',
    Cell: ({ value }: any) => <TableCell.Profile avatar={value?.avatar} name={value?.name} />,
  },
  {
    width: 180,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }) => {
      if (value === 'refused') return <Status color="red" label="Refused Collection" />
      if (value === 'collected') return <Status color="blue" label="Collected" />
      return <TableCell.NotApplicable />
    },
  },
  {
    accessor: 'result',
    width: 120,
    Header: 'Result',
    Cell: ({ value }) => {
      if (value === 'positive') return <Status color="red" label="Positive" />
      if (value === 'negative') return <Status color="green" label="Negative" />
      if (value === 'not_available') return <Status color="grey" label="N/A" />
      return <TableCell.NotApplicable />
    },
  },
  {
    accessor: 'value',
    width: 120,
    Header: 'Result Value',
    Cell: ({ row, value }) => {
      if (row.original.result === 'negative') return <TableCell.NotApplicable />
      return value
    },
  },
  {
    accessor: 'notes',
    width: 200,
    Header: 'Notes',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const BreathalyzerTestsTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, hiddenColumns, batchActionsConfig, localStorageKey, titleAfter } = props
  const { timezone, user } = useSettings()

  const isBehave = user?.type === 'bh_employee'

  return (
    <Table
      title="Breathalyzer Tests"
      titleAfter={titleAfter}
      icon="test_results"
      data={data}
      columns={columns(to, timezone, isBehave)}
      hiddenColumns={hiddenColumns}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No breathalyzer tests added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      help={<HelpTagIframe id="test_results_breathalyzer_tests" />}
    />
  )
}

BreathalyzerTestsTable.defaultProps = {
  localStorageKey: 'breathalyzer_tests',
}

export default BreathalyzerTestsTable
