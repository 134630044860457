import React from 'react'
import { connect } from 'react-redux'

import Button from '../../Button'
import Chotomate from '../../Chotomate'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Overlay from '../../Overlay'
import PhoneInput from '../../Forms/PhoneInput'
import Select from '../../Forms/Select'
import Option from '../../Forms/Option'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Input from '../../Forms/Input'
import EmailInput from '../../Forms/EmailInput'
import LevelOfCareSelect from '../../Forms/LevelOfCareSelect'
import DateInput from '../../Forms/DateInput'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class PayerAuthorizationOverlay extends OverlayBase {
  onSaveSuccessful = () => {
    if (this.props.onSaveSuccessful) this.props.onSaveSuccessful()
  }

  renderHeader = () => {
    return <Overlay.Header icon="checklist" title="Authorization" />
  }

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { record, timezone } = this.props

    return (
      <Overlay.Content>
        <Chotomate ready name="authorization_overlay" />

        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          timezone={timezone}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
        >
          <Section>
            <FormSection>
              <DateInput label="Date Start" model="date_requested" />

              <Select label="Authorization Status" model="status">
                <Option label="Authorized" value="authorized" />
                <Option label="Not Authorized" value="not_authorized" />
              </Select>

              <LevelOfCareSelect label="Level Of Care" model="level_of_care" />

              <DateInput label="Date Start" model="date_start" />
              <DateInput label="Date End" model="date_end" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'authorizations')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'authorizations')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(PayerAuthorizationOverlay))
