import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withSettings from '@behavehealth/hocs/withSettings'

import { Grid, Page } from '@behavehealth/components'

// NOTE: This component is not used in the app, but is a an example I (Robert) used to test Sourcemaps with Sentry
const ErrorTest = () => {
  return (
    <Page feature="dashboard" plan="lite" breakpoint="0" flex="3rem">
      <Grid gap="1rem">
        <Grid gap={12}>{testingSentry && <div>Testing Sentry</div>}</Grid>
      </Grid>
    </Page>
  )
}

export default withPageError(withSettings(ErrorTest))
