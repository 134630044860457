import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { ArchivedInsurancePayersDataTable } from '@behavehealth/constructs/Insurance/ArchivedInsurancePayersDataTable'

const ArchivedInsurancePayers: React.FC = () => {
  return <ArchivedInsurancePayersDataTable />
}

export default withPageError(ArchivedInsurancePayers)
