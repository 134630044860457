import React from 'react'
import isFunction from 'lodash/isFunction'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { useFuse } from '../../../hooks/useFuse'
import State from '../../State'

import { PopoverMenu, PopoverMenuItem } from '../../../components/PopoverMenu'
import { FilterDropdownSearch } from './FilterDropdownSearch'

export const FilterSelect = (props: any) => {
  const { activeFilters, filtersConfig, trigger, onSelect, isOpen, onOpenUpdated } = props

  const [searchTerm, setSearchTerm]: any = React.useState('')

  const searchData = useFuse(filtersConfigToArray(filtersConfig), searchTerm, { keys: ['label'] })
  const isEmpty = size(searchTerm) > 0 && size(searchData) === 0

  const clearSearch = () => {
    setSearchTerm('')
  }

  if (!filtersConfig || !trigger) return null

  return (
    <PopoverMenu
      stopPropagation
      isOpen={isOpen}
      onOpenUpdated={onOpenUpdated}
      trigger={isFunction(trigger) ? trigger({ isOpen }) : trigger}
    >
      <FilterDropdownSearch value={searchTerm} onChange={setSearchTerm} onClear={clearSearch} />

      {isEmpty ? (
        <State isEmpty key="empty-state" glyph="search" emptyDescription="No filters found" />
      ) : (
        searchData?.map?.((filter: any) => {
          const isActive = activeFilters.includes(filter.key)

          if (isActive) return null

          return (
            <PopoverMenuItem
              key={filter.key}
              label={filter.label}
              glyph={filter.glyph || 'filter'}
              glyphColor={COLORS.blue}
              onClick={() => {
                onSelect?.(filter.key)
                clearSearch()
              }}
            />
          )
        })
      )}
    </PopoverMenu>
  )
}

const filtersConfigToArray = (filtersConfig: any) => {
  const result: any = []

  if (!filtersConfig) return result

  for (const key in filtersConfig) {
    const filter = filtersConfig[key]

    result.push({ key, ...filter })
  }

  return result
}
