import React from 'react'
import { useParams } from 'react-router-dom'

import { age } from '../../../utils/functions'
import { apiUpdate } from '../../../modules/api'
import { invalidateQueries } from '../../../hooks/useNewAPI'
import { useSettings } from '../../../hooks/useSettings'

import DateOfBirthInput from '../../../components/Forms/DateOfBirthInput'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import GenderIdentitySelect from '../../../components/Forms/elements/GenderIdentitySelect'
import Input from '../../../components/Forms/Input'
import Option from '../../../components/Forms/Option'
import PreferredPronounsSelect from '../../../components/Forms/elements/PreferredPronounsSelect'
import Select from '../../../components/Forms/Select'
import SexSelect from '../../../components/Forms/elements/SexSelect'
import Workflow from '../../../components/Workflow/Workflow'

const PersonalDetails = ({ client, setStatus }: any) => {
  const form = React.useRef()
  const { timezone } = useSettings()

  const [isValid, setIsValid] = React.useState(false)
  const { resource_id }: any = useParams()
  const { goNext }: any = React.useContext(Workflow.Context)

  const [requestLoading, setRequestLoading] = React.useState(false)

  const onContinue = async () => {
    setRequestLoading(true)

    await apiUpdate({
      name: 'clients',
      url: `/residents/${resource_id}`,
      params: form.current?.getFormValue(),
      notify: false,
    })

    setRequestLoading(false)
    invalidateQueries(null, 'client')

    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  return (
    <Form useLocalModel getForm={form} initialModel={client} timezone={timezone} onValidationUpdate={setIsValid}>
      <FormSection>
        <Input
          horizontal
          label="First Name"
          model="first_name"
          validations={{
            presence: {
              message: "Please enter the client's first name",
            },
          }}
        />

        <Input horizontal label="Middle Name" model="middle_name" />

        <Input
          horizontal
          label="Last Name"
          model="last_name"
          validations={{
            presence: {
              message: "Please enter the client's last name",
            },
          }}
        />

        {/* <Input horizontal label="Preferred Name" model="preferred_name" /> */}

        <DateOfBirthInput
          horizontal
          label="Date of Birth"
          model="dob"
          smartDescription={(date) => `Age: ${age(date)} y/o`}
          validations={{
            presence: {
              message: 'Please enter a date of birth',
            },
          }}
        />

        {/* <Select allowEmpty horizontal label="Ethnicity" model="ethnicity">
          <Option label="American Indian or Alaska Native" value="american_indian_or_alaska_native" />
          <Option label="Asian" value="asian" />
          <Option label="Black or African American" value="black_or_african_american" />
          <Option label="Hispanic or Latino" value="hispanic_or_latino" />
          <Option label="Native Hawaiian or Other Pacific Islander" value="native_hawaiian_or_other_pacific_islander" />
          <Option label="White" value="white" />
          <Option label="Other" value="other" />
        </Select> */}

        {/* <SexSelect label="Sex Assigned at Birth" model="sex" />
        <GenderIdentitySelect label="Gender Identity" model="gender_identity" />
        <PreferredPronounsSelect label="Preferred Pronouns" model="preferred_pronouns" /> */}

        <Workflow.Buttons>
          <Workflow.ContinueButton label="Save & Continue →" onClick={onContinue} isLoading={requestLoading} isDisabled={!isValid} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default PersonalDetails
