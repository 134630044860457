import React from 'react'

import Select from '../common/Select'

type Props = { column: any }

const CollectionStatusFilter = (props: Props) => {
  const {
    column: { filterValue, setFilter },
  } = props

  const filterOptions = [
    {
      value: 'collected',
      label: 'Collected',
    },
    {
      value: 'refused',
      label: 'Refused Collection',
    },
  ]

  return <Select setFilter={setFilter} filterValue={filterValue} filterOptions={filterOptions} />
}

export default CollectionStatusFilter
