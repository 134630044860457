import React from 'react'
import clsx from 'clsx'
import mermaid from 'mermaid'

import Card, { cardStyles } from './Card'
import Loader from './Loader'
import Glyph from './Glyph'

mermaid.initialize({
  startOnLoad: false,
  theme: 'default',
  securityLevel: 'loose',
  themeCSS: `
    rect.actor {
      fill: #e0f3ff;
      stroke: #cadff0;
    }

    .cluster rect {
      fill: rgba(141, 112, 255, 0.1);
      stroke: rgba(141, 112, 255, 0.2);
    }

    .cluster .basic.label-container {
      fill: rgba(141, 112, 255, 0.1);
      stroke: rgba(141, 112, 255, 0.2);
    }

    .statediagram-cluster.statediagram-cluster-alt .inner {
      fill: rgba(255, 255, 255, 0.5);
      stroke: rgba(141, 112, 255, 0.2);
    }
    
    .basic.label-container {
      fill: #CFDCFF;
      stroke: #BAC9EF;
    }

    .section-type-1 {
      fill: #CFDCFF;
      stroke: #BAC9EF;
    }
    
    .task-type-1 {
      fill: #CFDCFF;
      stroke: #BAC9EF;
    }
    `,
  fontFamily: 'Fira Code',
})

const Mermaid = ({ id, className, style, value, onValidationUpdate }: any) => {
  const [svg, setSvg]: any = React.useState(null)
  const [isError, setIsError]: any = React.useState(false)

  React.useEffect(() => {
    const render = async () => {
      try {
        const { svg } = await mermaid.render(`mermaid-${id}`, value)

        setSvg(svg)

        if (onValidationUpdate) onValidationUpdate(true)
      } catch (error) {
        setIsError(true)

        if (onValidationUpdate) onValidationUpdate(false)

        console.error(error)
      }
    }

    render()
  }, [id, value])

  if (!svg && !isError)
    return (
      <Card style={style} className={clsx('flex flex-col items-center justify-center text-center py-12 px-4', className)}>
        <div className="MERMAID_INNER flex flex-col items-center justify-center text-center py-12 px-4">
          <Loader size={16} />
          <div className="text-text-muted mt-3">Loading…</div>
        </div>
      </Card>
    )

  if (isError)
    return (
      <Card className="flex flex-col items-center justify-center text-center py-12 px-4">
        <Glyph glyph="circle_error" size={20} />

        <div className="text-text mt-3">Diagram could not be rendered</div>
        <div className="text-text-muted mt-1">Please check the syntax and try again.</div>
      </Card>
    )

  return (
    <div
      css={cardStyles}
      style={style}
      className={clsx('p-4 grid place-items-center', className)}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  )
}

export default Mermaid
