import React from 'react'

import { countWord, usDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, isSelectable: boolean, timezone: string) => [
  {
    Header: 'Name',
    accessor: 'name',
    isSticky: true,
    canToggleVisible: false,
    Cell: ({ row, value }: any) => <TableCell.MainLink to={isSelectable && to(row.original.id)} label={value} />,
  },
  {
    accessor: 'total_amount',
    Header: 'Amount',
    Cell: TableCell.Amount,
  },
  {
    width: 160,
    accessor: 'should_auto_charge',
    Header: 'Automatic Collection',
    Cell: ({ value }: any) => <TableCell.TrueFalse isTrue={value} />,
    Filter: TableFilter.TrueFalse,
  },
  {
    width: 200,
    accessor: 'payment_method',
    Header: 'Payment Method',
    Cell: ({ value }: any) => <TableCell value={value?.name || '–'} />,
  },
  {
    accessor: 'transactioned_at',
    Header: 'Next Renewal Date',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    accessor: 'end_in_months',
    Header: 'Charging Period',
    Cell: ({ value }: any) => <TableCell value={value && countWord('Months', value)} />,
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 220,
    accessor: 'updated_at',
    Header: 'Date Updated',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
]

type Props = {
  data: Object
  hiddenColumns?: string[]
  isLoading: boolean
  isSelectable: boolean
  localStorageKey: string
  timezone: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const AutoRepeatingChargesTable = (props: Props) => {
  const { to, hiddenColumns, data, isLoading, isSelectable, batchActionsConfig, localStorageKey, timezone, titleAfter, testKey } = props

  return (
    <Table
      testKey={testKey}
      title="Auto-Repeating Services"
      titleAfter={titleAfter}
      icon="financials"
      isLoading={isLoading}
      hiddenColumns={hiddenColumns}
      emptyDescription="Add charges and select the auto-repeat option to generate charges automatically"
      batchActionsConfig={batchActionsConfig}
      columns={columns(to, isSelectable, timezone)}
      data={data}
      localStorageKey={localStorageKey}
    />
  )
}

AutoRepeatingChargesTable.defaultProps = {
  localStorageKey: 'auto_repeating_charges',
}

export default withSettings(AutoRepeatingChargesTable)
