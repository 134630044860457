import React from 'react'
import EmbedContainer from 'react-oembed-container'

import { IframeOverlay } from '../../components/IframeOverlay'
import { formatURL } from '../../utils/functions'
import { useGet } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import Loader from '../../components/Loader'
import Overlay from '../../components/Overlay'
import SummonOverlay from '../../components/SummonOverlay'

export const QuoteIframeButton = (props: any) => {
  const { model, data = {} } = props

  const enabledModel = `use_${model}_iframe`
  const labelModel = `${model}_label`
  const urlModel = `${model}_url`

  const isEnabled = !!data.iframes_settings?.[enabledModel]
  const label = data.iframes_settings?.[labelModel] || 'More Details'
  const url = data.iframes_settings?.[urlModel]
  const formattedURL = formatURL(url)

  if (!isEnabled || !formattedURL) return null

  const isCanva = url?.includes?.('canva.com')

  if (isCanva) return <CanvaOverlay url={url} label={label} />

  return (
    <SummonOverlay overlay={<IframeOverlay closeOnBackdrop showBackdrop title={label} url={url} maxWidth={80} glyph="info" />}>
      <Button label={label} size={100} glyph="info" type="primary" />
    </SummonOverlay>
  )
}

const CanvaOverlay = ({ url, label }) => {
  const queryParams = { url }

  const { data, isLoading }: any = useGet({
    name: ['canva_oembed', queryParams],
    url: `/apps/vendors/canva_oembed`,
    params: queryParams,
    options: { enabled: !!url },
  })

  return (
    <SummonOverlay
      overlay={
        <Overlay closeOnBackdrop showBackdrop maxWidth={80}>
          <Overlay.Header title={label} glyph="info" />

          <Overlay.Content css={STYLES.iframeWrapper}>
            {isLoading ? (
              <Loader css={STYLES.loader} />
            ) : (
              data?.html && (
                <EmbedContainer markup={data.html}>
                  <div dangerouslySetInnerHTML={{ __html: data.html }} />
                </EmbedContainer>
              )
            )}
          </Overlay.Content>
        </Overlay>
      }
    >
      <Button label={label} size={100} glyph="info" type="primary" />
    </SummonOverlay>
  )
}

const STYLES = {
  iframeWrapper: {
    width: '100%',
    position: 'relative',
    margin: '0 auto',

    iframe: {
      position: 'absolute',
      width: '100% !important',
      height: '100% !important',
      borderRadius: '0 !important',
    },
  },

  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}
