import React from 'react'

import ContextShow from '../../Forms/ContextShow'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const HealthcarePracticeDetailsFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="care_level_5" />
        <Type as="h2" variant="H3">
          Healthcare Practice Details
        </Type>
      </Flex>

      <RadioGroup
        label="Healthcare Practice Type"
        layout="vertical-dense"
        model="questionnaire.healthcare_practice_details.healthcare_practice_category"
      >
        <Radio label="Solo Practice" value="solo_practice" />
        <Radio label="Group Practice" value="group_practice" />
      </RadioGroup>

      <RadioGroup
        label="Medication Prescribers"
        layout="vertical-dense"
        model="questionnaire.healthcare_practice_details.has_medication_prescribers"
      >
        <Radio label="We have prescribers on staff" value={true} />
        <Radio label="We do NOT have prescribers on staff" value={false} />
      </RadioGroup>

      <ContextShow when="questionnaire.healthcare_practice_details.has_medication_prescribers" is={true}>
        <RadioGroup
          label="Medication Management & Prescribers"
          layout="vertical-dense"
          model="questionnaire.healthcare_practice_details.does_prescribe_controlled_medications"
        >
          <Radio label="We prescribe all medications including controlled substances" value={true} />
          <Radio label="We do NOT prescribe controlled medications" value={false} />
        </RadioGroup>
      </ContextShow>
    </FormSection>
  )
}

export const HealthcarePracticeDetails: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <HealthcarePracticeDetailsFields />
    </Form>
  )
}
