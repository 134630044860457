import { usePermissions } from '../../hooks/usePermissions'
import { useSettings } from '../../hooks/useSettings'

export const useCommunityPermissions = () => {
  const { isBehave, isCommunity } = useSettings()

  const { allowed: canViewCommunityExplore } = usePermissions({
    featureFlag: 'community',
    permission: 'community_explore.view',
  })

  const { allowed: canSaveCommunityOrganizations } = usePermissions({
    featureFlag: 'organizations',
    permission: 'organizations.actions.create',
  })

  const { allowed: canInviteToPartnerUp } = usePermissions({
    featureFlag: 'community',
    permission: 'community_explore.actions.invite_partners',
  })

  const { allowed: canViewCommunityInvites } = usePermissions({
    featureFlag: 'community',
    permission: 'community_invites.view',
  })

  const { allowed: canAcceptDeclineCancelCommunityInvites } = usePermissions({
    featureFlag: 'community',
    permission: 'community_invites.actions.accept_decline_cancel',
  })

  const { allowed: canViewCommunityProfiles } = usePermissions({
    featureFlag: 'community',
    permission: 'community_profiles.view',
  })

  const { allowed: canEditCommunityProfiles } = usePermissions({
    featureFlag: 'community',
    permission: 'community_profiles.edit',
  })

  const { allowed: canViewOrganizations } = usePermissions({
    featureFlag: 'organizations',
    permission: 'organizations.view',
  })

  const { allowed: canViewCommunityPartners } = usePermissions({
    featureFlag: 'community',
    permission: 'community_partners.view',
  })

  const { allowed: canRevokeReactivatePartnerships } = usePermissions({
    featureFlag: 'community',
    permission: 'community_partners.actions.revoke_reactivate',
  })

  const { allowed: canViewPartnerShareSettings } = usePermissions({
    featureFlag: 'community',
    permission: 'community_partners.share_settings.view',
  })

  const { allowed: canEditPartnerShareSettings } = usePermissions({
    featureFlag: 'community',
    permission: 'community_partners.share_settings.edit',
  })

  const { allowed: canViewPartnerSharedFiles } = usePermissions({
    featureFlag: 'community',
    permission: 'community_partners.shared_files.view',
  })

  const { allowed: canCreatePartnerSharedFiles } = usePermissions({
    featureFlag: 'community',
    permission: 'community_partners.shared_files.create',
  })

  const { allowed: canEditPartnerSharedFiles } = usePermissions({
    featureFlag: 'community',
    permission: 'community_partners.shared_files.edit',
  })

  const { allowed: canDeletePartnerSharedFiles } = usePermissions({
    featureFlag: 'community',
    permission: 'community_partners.shared_files.delete',
  })

  const { allowed: canViewPartnerReferrals } = usePermissions({
    featureFlag: 'community',
    permission: 'community_partners.referrals.view',
  })

  const canViewSomeCommunity =
    canViewOrganizations || canViewCommunityExplore || canViewCommunityInvites || canViewCommunityProfiles || canViewCommunityPartners

  return {
    canViewCommunityExplore: isCommunity || isBehave || canViewCommunityExplore,
    canSaveCommunityOrganizations: isCommunity || isBehave || canSaveCommunityOrganizations,
    canInviteToPartnerUp: isCommunity || isBehave || canInviteToPartnerUp,
    canViewCommunityInvites: isCommunity || isBehave || canViewCommunityInvites,
    canAcceptDeclineCancelCommunityInvites: isCommunity || isBehave || canAcceptDeclineCancelCommunityInvites,
    canViewCommunityProfiles: isCommunity || isBehave || canViewCommunityProfiles,
    canEditCommunityProfiles: isCommunity || isBehave || canEditCommunityProfiles,
    canViewCommunityPartners: isCommunity || isBehave || canViewCommunityPartners,
    canRevokeReactivatePartnerships: isCommunity || isBehave || canRevokeReactivatePartnerships,
    canViewPartnerShareSettings: isCommunity || isBehave || canViewPartnerShareSettings,
    canEditPartnerShareSettings: isCommunity || isBehave || canEditPartnerShareSettings,
    canViewPartnerSharedFiles: isCommunity || isBehave || canViewPartnerSharedFiles,
    canCreatePartnerSharedFiles: isCommunity || isBehave || canCreatePartnerSharedFiles,
    canEditPartnerSharedFiles: isCommunity || isBehave || canEditPartnerSharedFiles,
    canDeletePartnerSharedFiles: isCommunity || isBehave || canDeletePartnerSharedFiles,
    canViewPartnerReferrals: isCommunity || isBehave || canViewPartnerReferrals,
    canViewSomeCommunity: isCommunity || isBehave || canViewSomeCommunity,
  }
}
