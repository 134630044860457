import React from 'react'

import { Divider, Page } from '@behavehealth/components'

import FlowChart from '@behavehealth/components/FlowChart/FlowChart'
import FlowChartContent from '@behavehealth/components/FlowChart/FlowChartContent'
import FlowChartGroup from '@behavehealth/components/FlowChart/FlowChartGroup'
import FlowChartItem from '@behavehealth/components/FlowChart/FlowChartItem'

const FlowChartPage = () => {
  return (
    <Page title="Flow Chart" feature="roster">
      <FlowChart direction="horizontal" spaces={3}>
        <FlowChartGroup>
          <FlowChartItem row="1 / -1">
            <FlowChartContent number={50} icon="applicants" description="Applicants" />
          </FlowChartItem>
        </FlowChartGroup>

        <FlowChartGroup>
          <FlowChartItem lineLeft row="1 / 3">
            <FlowChartContent number={40} icon="tasks" description="Accepted" />
          </FlowChartItem>

          <FlowChartItem lineLeft row="3 / 4">
            <FlowChartContent number={10} icon="calendar" description="Declined" />
          </FlowChartItem>
        </FlowChartGroup>

        <FlowChartGroup>
          <FlowChartItem lineLeft lineRight>
            <FlowChartContent number={10} icon="intake_forms" description="Admitted" />
          </FlowChartItem>

          <FlowChartItem lineLeft>
            <FlowChartContent number={10} icon="calendar" description="Not Admitted" />
          </FlowChartItem>
        </FlowChartGroup>

        <FlowChartGroup>
          <FlowChartItem row="1 / 3">
            <FlowChartContent number={25} icon="clients" description="Clients" />
          </FlowChartItem>
        </FlowChartGroup>

        <FlowChartGroup>
          <FlowChartItem lineLeft>
            <FlowChartContent number={15} icon="discharge_forms" description="Discharged" />
          </FlowChartItem>
          <FlowChartItem lineLeft lineTop>
            <FlowChartContent number={5} icon="intake_forms" description="Re-admitted" />
          </FlowChartItem>
        </FlowChartGroup>
      </FlowChart>

      <Divider />

      <FlowChart direction="vertical" spaces={3}>
        <FlowChartGroup>
          <FlowChartItem column="1 / -1">
            <FlowChartContent number={50} icon="applicants" description="Applicants" />
          </FlowChartItem>
        </FlowChartGroup>

        <FlowChartGroup>
          <FlowChartItem lineTop column="1 / 3">
            <FlowChartContent number={40} icon="tasks" description="Accepted" />
          </FlowChartItem>

          <FlowChartItem lineTop column="3 / 4">
            <FlowChartContent number={10} icon="calendar" description="Declined" />
          </FlowChartItem>
        </FlowChartGroup>

        <FlowChartGroup>
          <FlowChartItem lineTop lineBottom>
            <FlowChartContent number={10} icon="intake_forms" description="Admitted" />
          </FlowChartItem>

          <FlowChartItem lineTop>
            <FlowChartContent number={10} icon="calendar" description="Not Admitted" />
          </FlowChartItem>
        </FlowChartGroup>

        <FlowChartGroup>
          <FlowChartItem column="1 / 3">
            <FlowChartContent number={25} icon="clients" description="Clients" />
          </FlowChartItem>
        </FlowChartGroup>

        <FlowChartGroup>
          <FlowChartItem lineTop>
            <FlowChartContent number={15} icon="discharge_forms" description="Discharged" />
          </FlowChartItem>

          <FlowChartItem lineTop lineLeft>
            <FlowChartContent number={5} icon="intake_forms" description="Re-admitted" />
          </FlowChartItem>
        </FlowChartGroup>
      </FlowChart>
    </Page>
  )
}

export default FlowChartPage
