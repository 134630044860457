import React from 'react'

import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useCreate } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'

const RootAccountingInvoiceCreditNoteOverlay = (props: any) => {
  const {
    cancel,
    close,
    edit,
    form,
    id,
    initialModel,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'accounting-payment',
    endpoint: '/accounting/invoice_credit_notes',
    invalidate: 'accounting',
    options: props,
  })

  const syncMutation = useCreate({
    name: ['sync-accounting-payment'],
    url: `/accounting/invoice_credit_notes/${id}/sync`,
    invalidate: 'accounting-payment',
  })

  const [category, setCategory]: any = React.useState('credit')

  const [client, setClient]: any = React.useState(null)
  const [credit, setCredit]: any = React.useState(null)
  const [writeOff, setWriteOff]: any = React.useState(null)

  const sync = () => {
    syncMutation.mutateAsync({})
  }

  const save = () => {
    let reference_id = ''

    if (category === 'credit') reference_id = credit?.id
    else if (category === 'write_off') reference_id = writeOff?.id

    saveWithData({ reference_id, reference_type: 'FinancialTransaction' })
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="accounting" title="Refund" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            ...initialModel,

            category: initialModel?.reference?.category,
            client: initialModel?.reference?.client,

            ...(initialModel?.reference?.category === 'credit' && {
              credit: initialModel?.reference,
            }),

            ...(initialModel?.reference?.category === 'write_off' && {
              write_off: initialModel?.reference,
            }),
          }}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection>
              <Select
                label="Category"
                defaultValue="credit"
                className="!flex-1"
                model="category"
                onUpdate={({ value }) => {
                  setCategory(value)
                }}
              >
                <Option label="Credit" value="credit" />
                <Option label="Write-Off" value="write_off" />
              </Select>

              <ObjectSelector
                icon={ICONS.clients}
                label="Client"
                type="clients"
                model="client"
                onUpdate={({ object }) => {
                  setClient(object)
                }}
                selectTitle={(data: any) => data.name}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
              />

              {category === 'credit' && (
                <ObjectSelector
                  key={client?.id + category}
                  icon={ICONS.financials}
                  label="Credit"
                  type="client.financial_transactions.credits"
                  model="credit"
                  onUpdate={({ object }) => {
                    setCredit(object)
                  }}
                  dependentValue={client?.id}
                  selectTitle={(data: any) => data.name}
                  validations={{
                    presence: {
                      message: 'Please select a credit',
                    },
                  }}
                />
              )}

              {category === 'write_off' && (
                <ObjectSelector
                  key={client?.id + category}
                  icon={ICONS.financials}
                  label="Write-Off"
                  type="client.financial_transactions.write_offs"
                  model="write_off"
                  onUpdate={({ object }) => {
                    setWriteOff(object)
                  }}
                  dependentValue={client?.id}
                  selectTitle={(data: any) => data.name}
                  validations={{
                    presence: {
                      message: 'Please select a write-off',
                    },
                  }}
                />
              )}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {/* <Button
          glyph="refresh"
          label="Sync"
          type="default"
          isDisabled={isLoading}
          onClick={sync}
          isLoading={syncMutation.isLoading}
          flex="100 1 auto"
        /> */}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit Credit Note" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const AccountingInvoiceCreditNoteOverlay = withOverlayError(RootAccountingInvoiceCreditNoteOverlay)
