import React from 'react'

import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Chotomate from '../../components/Chotomate'
import ContextHide from '../../components/Forms/ContextHide'
import DateInput from '../../components/Forms/DateInput'
import Diagnoses from '../../components/SmartContent/Diagnoses'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Switch from '../../components/Forms/Switch'
import Textarea from '../../components/Forms/Textarea'

import GoalsAndObjectivesV2 from '../../components/Overlays/pages/DataForms/common/GoalsAndObjectivesV2'
import ProblemsV2 from '../../components/Overlays/pages/DataForms/common/ProblemsV2'
import SNAP from '../../components/Overlays/pages/DataForms/common/SNAP'
import WileyPracticePlannerOverlay from '../../components/Overlays/actions/Wiley/WileyPracticePlannerOverlay'

import { DataFormOverlay } from '../DataFormOverlay'

const RootTreatmentPlanOverlayV2 = () => {
  const [wileyData, setWileyData]: any = React.useState()

  return (
    <DataFormOverlay
      enableEmployeeSignatures
      title="Treatment Plan"
      category="treatment_plan"
      subcategory="treatment_plan"
      version="2.0"
      icon="treatment_plans"
      helpID="treatment_plans"
      requestClientSignature={true}
      requestAuthorSignature={true}
      requireSupervisor={true}
    >
      {({ client, isEditable }: any) => (
        <>
          <Chotomate ready name="treatment_plan_overlay" />

          <Permission featureFlagV2="wiley_treatment_plans">
            {isEditable && (
              <>
                <Section headingType="h2" title="Wiley Practice Planner" help={<Status contrast label="Beta" color="orange" />}>
                  <SummonOverlay overlay={<WileyPracticePlannerOverlay clientID={client?.id} onSave={setWileyData} />}>
                    <Button type="primary" label="Open Wiley Planner" glyph="add_file" />
                  </SummonOverlay>
                </Section>

                <Divider />
              </>
            )}
          </Permission>

          <Section
            scrollview={{
              id: 'appointments',
              name: 'Appointments',
            }}
            headingType="h2"
            title="Appointments"
            commentsModel="data.appointments"
          >
            <FormSection layout="vertical">
              <DateInput label="Review Date" model="data.appointments.review_date" />
              <Flex gap={8} stretchChildrenX>
                <Input label="Frequency of Appointments" model="data.appointments.frequency" />
                <Input label="Estimated Length of Treatment" model="data.appointments.estimated_length" />
              </Flex>
            </FormSection>
          </Section>

          <Divider />

          <Section
            scrollview={{
              id: 'diagnoses',
              name: 'Diagnoses',
            }}
            headingType="h2"
            title="Diagnoses"
            commentsModel="data.smart_diagnoses_comments"
          >
            <Diagnoses
              model="data.smart_diagnoses"
              isEditable={isEditable}
              client={client}
              icon="diagnosis"
              title="Diagnoses"
              emptyDescription="No diagnoses added yet"
            />
          </Section>

          <Divider />

          <Section
            scrollview={{
              id: 'problems',
              name: 'Problems',
            }}
            headingType="h2"
            title="Problems"
            commentsModel="data.problems_section"
          >
            <ProblemsV2 isEditable={isEditable} wileyData={wileyData} model="data.problems" />
          </Section>

          <Divider />

          <Section
            scrollview={{
              id: 'goals_and_objectives',
              name: 'Goals & Objectives',
            }}
            headingType="h2"
            title="Goals & Objectives"
            commentsModel="data.goals_and_objectives"
          >
            <GoalsAndObjectivesV2 isEditable={isEditable} wileyData={wileyData} model="data.goals" />
          </Section>

          <Divider />

          <Section
            title="Strengths, Needs, Abilities, Preferences"
            aside={<Switch horizontal inverse model="data.snap.not_applicable" />}
            scrollview={{
              id: 'snap',
              name: 'S.N.A.P.',
            }}
            commentsModel="data.snap"
          >
            <ContextHide when="data.snap.not_applicable" is={true}>
              <SNAP />
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Summary"
            aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
            scrollview={{
              id: 'summary',
              name: 'Summary',
            }}
            commentsModel="data.summary"
          >
            <ContextHide when="data.summary.not_applicable" is={true}>
              <FormSection layout="vertical" maxWidth="100%">
                <Textarea label="Interpretive Summary" model="data.summary.interpretive_summary" />
                <Textarea label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
                <Textarea useQuickText label="Recommendations" model="data.summary.recommendations" />
              </FormSection>
            </ContextHide>
          </Section>
        </>
      )}
    </DataFormOverlay>
  )
}

export const TreatmentPlanOverlayV2 = withOverlayError(RootTreatmentPlanOverlayV2)
