import React from 'react'

import { TYPOGRAPHY_VARIANTS } from '../theme'
import { CARD_CLASSES } from './Card'
import Type from './Typography/Type'

type Props = {
  quote: string
}

const CardQuote: React.FC<Props> = ({ quote }) => {
  return (
    <Type as="blockquote" className={`${CARD_CLASSES.TITLE} !m-0`} variant={TYPOGRAPHY_VARIANTS.CARD_QUOTE}>
      {`“${quote}”`}
    </Type>
  )
}

export default CardQuote
