import React from 'react'
import * as HoverCard from '@radix-ui/react-hover-card'

import { COLORS, SHADOW } from '../../../theme'
import { titleCase } from '../../../utils/functions'

import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import Icon from '../../../components/Icon'
import RoadmapGraphic from '../../../components/Roadmap/RoadmapGraphic'

import { CardTooltip } from './CardTooltip'
import { CardTooltipContent } from './CardTooltipContent'

export const RecordTooltip = (props: any) => {
  const { color, startDate, children, icon, title, recordLink, graphicShape = 'circle', recordType } = props

  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const graphic = <RoadmapGraphic startDate={startDate} onClick={openTooltip} background={color} shape={graphicShape} />

  if (!children) return graphic

  return (
    <CardTooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
      <HoverCard.Trigger asChild>{graphic}</HoverCard.Trigger>

      <CardTooltipContent>
        {title && (
          <Flex nowrap centerY css={{ padding: '0.5rem 1rem 0.25rem' }}>
            {icon && <Icon icon={icon} size={18} className="!mr-2" />}
            <div>
              <div className="text-[1.1rem] font-[700]">{title}</div>
              {recordType && (
                <Flex nowrap centerY gap="0.3rem">
                  <div css={{ background: color, ...STYLES.graphic }} className={`shape-${graphicShape}`}></div>
                  <div css={STYLES.graphicText}>{titleCase(recordType)}</div>
                </Flex>
              )}
            </div>

            {recordLink && <Button label="Open" size={100} link={recordLink} css={{ marginLeft: 'auto' }} target="_blank" />}
          </Flex>
        )}

        {children}
      </CardTooltipContent>
    </CardTooltip>
  )
}

const STYLES = {
  graphic: {
    width: 12,
    height: 12,
    border: `1px solid ${COLORS.white}`,
    boxShadow: `inset 0 0 0 1px ${COLORS.divider}, ${SHADOW(1)}`,

    '&.shape-circle': {
      borderRadius: '50%',
    },

    '&.shape-square': {
      borderRadius: '3px',
    },
  },

  graphicText: {
    fontSize: '0.9rem',
    color: COLORS.textMuted,
  },
}
