import React from 'react'

import { withOverlayError } from '../../hocs/withOverlayError'

import Card from '../../components/Card'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Score from '../../components/Overlays/pages/DataForms/common/Score'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import { DataFormOverlay } from '../DataFormOverlay'

const RootRecoveryCapitalScaleAndPlanOverlay = (props: any) => {
  const { category = 'recovery_coaching', icon = 'recovery_coaching', permissionName } = props

  return (
    <DataFormOverlay
      minimizeEnabled
      clientPortalCanEdit
      enableTreatmentPlanSelector
      title="Recovery Capital Scale & Plan"
      category={category}
      subcategory="recovery_capital_scale_and_plan"
      icon={icon}
      requestClientSignature={false}
      requestAuthorSignature={false}
      requestSupervisorSignature={false}
      requireSupervisor={false}
      permissionName={permissionName}
      {...props}
    >
      <>
        <Section
          title="Description"
          headingType="h2"
          scrollview={{
            id: 'description',
            name: 'Description',
          }}
        >
          <p>
            Robert Granfield and William Cloud introduced and elaborated on the concept of “recovery capital” in a series of articles and a
            1999 book, Coming Clean: Overcoming Addiction without Treatment. They define recovery capital as the volume of internal and
            external assets that can be brought to bear to initiate and sustain recovery from alcohol and other drug problems. Recovery
            capital, or recovery capacity, differs from individual to individual and differs within the same individual at multiple points
            in time. Recovery capital also interacts with problem severity to shape the intensity and duration of supports needed to achieve
            recovery. This interaction dictates the intensity or level of care one needs in terms of professional treatment and the
            intensity and duration of post-treatment recovery support services. The figure below indicates how these combinations of problem
            severity and recovery capital could differ.
          </p>

          <p>
            People with high problem severity but very high recovery capital may require few resources to initiate and sustain recovery than
            an individual with moderate problem severity but very low recovery capital. Where the former may respond very well to outpatient
            counseling, linkage to recovery mutual aid groups and a moderate level of ongoing supervision, the latter may require a higher
            intensity of treatment, greater enmeshment in a culture of recovery (e.g., placement in a recovery home, greater intensity of
            mutual aid involvement, involvement in recovery-based social activities), and a more rigorous level of ongoing monitoring and
            supervision.
          </p>

          <p>
            Traditional addiction assessment instruments do a reasonably good job of evaluating problem severity and some of the newer
            instruments improve the assessment of problem complexity (e.g., co-occurring medical/psychiatric problems), but few instruments
            measure recovery capital. The scale on the following page is intended as a self-assessment instrument to help a client measure
            his or her degree of recovery capital. The scale can be completed and discussed in an interview format, or it can be completed
            by the client and then discussed with the professional helper.
          </p>

          <Card className="!max-w-[500px]">
            <img
              src="https://behave-images.s3.amazonaws.com/recovery_capital_scale_diagram.png"
              alt="Recovery Capital Scale Diagram"
              className="!w-full !block"
            />
          </Card>
        </Section>

        <Divider />

        <Section
          title="Questionnaire & Scoring"
          headingType="h2"
          scrollview={{
            id: 'questionnaire_scoring',
            name: 'Questionnaire & Scoring',
          }}
          commentsModel="data.questionnaire_scoring"
        >
          <Questionnaire />
        </Section>

        <Divider />

        <Section
          title="Recovery Capital Plan"
          headingType="h2"
          scrollview={{
            id: 'recovery_capital_plan',
            name: 'Recovery Capital Plan',
          }}
          commentsModel="data.recovery_capital_plan"
        >
          <FormSection>
            <p className="!m-0">
              After completing and reviewing the Recovery Capital Scale, below are my recovery goals for the next month. To move closer to
              each goal, I will increase my recovery capital by doing the following daily and/or weekly activities.
            </p>

            <h3 className="!-mb-3">Goal #1</h3>
            <SmartTextarea useDictation label="Goal #1 – What" model="data.recovery_capital_plan.goal_1.what" />
            <SmartTextarea useDictation label="Goal #1 – When" model="data.recovery_capital_plan.goal_1.when" />
            <SmartTextarea useDictation label="Goal #1 – How Often" model="data.recovery_capital_plan.goal_1.how_often" />
            <SmartTextarea useDictation label="Goal #1 – With Whom" model="data.recovery_capital_plan.goal_1.with_whom" />

            <h3 className="!-mb-3">Goal #2</h3>
            <SmartTextarea useDictation label="Goal #2 – What" model="data.recovery_capital_plan.goal_2.what" />
            <SmartTextarea useDictation label="Goal #2 – When" model="data.recovery_capital_plan.goal_2.when" />
            <SmartTextarea useDictation label="Goal #2 – How Often" model="data.recovery_capital_plan.goal_2.how_often" />
            <SmartTextarea useDictation label="Goal #2 – With Whom" model="data.recovery_capital_plan.goal_2.with_whom" />

            <h3 className="!-mb-3">Goal #3</h3>
            <SmartTextarea useDictation label="Goal #3 – What" model="data.recovery_capital_plan.goal_3.what" />
            <SmartTextarea useDictation label="Goal #3 – When" model="data.recovery_capital_plan.goal_3.when" />
            <SmartTextarea useDictation label="Goal #3 – How Often" model="data.recovery_capital_plan.goal_3.how_often" />
            <SmartTextarea useDictation label="Goal #3 – With Whom" model="data.recovery_capital_plan.goal_3.with_whom" />
          </FormSection>
        </Section>

        <Divider />

        <Section title="References" headingType="h2">
          <p>Cloud, W. (1987). From down under: A qualitative study on heroin addiction recovery. Ann Arbor, MI: Dissertation Abstracts.</p>
          <p>
            Cloud, W. & Granfield, R. (1994). Natural recovery from addictions: Treatment implications. <i>Addictions Nursing</i>, 6,
            112-116.
          </p>
          <p>
            Cloud, W. & Granfield, R. (1994). Terminating addiction naturally: Post-addict identity and the avoidance of treatment.{' '}
            <i>Clinical Sociology Review</i>, 12, 159-174.
          </p>
          <p>
            Cloud, W. & Granfield, R. (2001). Natural recovery from substance dependency: Lessons for treatment providers.{' '}
            <i>Journal of Social Work Practice in the Addictions</i>, 1(1), 83-104.
          </p>
          <p>
            Granfield, R. & Cloud, W. (1996). The elephant that no one sees: Natural recovery among middle-class addicts.{' '}
            <i>Journal of Drug Issues</i>, 26(1), 45-61.
          </p>
          <p>
            Granfield, R. & Cloud, W. (1999). <i>Coming Clean: Overcoming Addiction Without Treatment</i>. New York: New York University
            Press.
          </p>
        </Section>
      </>
    </DataFormOverlay>
  )
}

const RadioQuestion = ({ label, model, setScore }: any) => (
  <RadioGroup
    label={label}
    model={`data.questionnaire.${model}`}
    layout="vertical-dense"
    onUpdate={({ value }: any) => setScore((o: any) => ({ ...o, [model]: value || 0 }))}
  >
    <Radio label="5 = Strongly Agree" value={5} />
    <Radio label="4 = Agree" value={4} />
    <Radio label="3 = Sometimes" value={3} />
    <Radio label="2 = Disagree" value={2} />
    <Radio label="1 = Strongly Disagree" value={1} />
  </RadioGroup>
)

const INITIAL_STATE = {
  q1: 0,
  q2: 0,
  q3: 0,
  q4: 0,
  q5: 0,
  q6: 0,
  q7: 0,
  q8: 0,
  q9: 0,
  q10: 0,
  q11: 0,
  q12: 0,
  q13: 0,
  q14: 0,
  q15: 0,
  q16: 0,
  q17: 0,
  q18: 0,
  q19: 0,
  q20: 0,
  q21: 0,
  q22: 0,
  q23: 0,
  q24: 0,
  q25: 0,
  q26: 0,
  q27: 0,
  q28: 0,
  q29: 0,
  q30: 0,
  q31: 0,
  q32: 0,
  q33: 0,
  q34: 0,
  q35: 0,
}

const Questionnaire = () => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b, 0)

  return (
    <>
      <FormSection>
        <RadioQuestion label="I have the financial resources to provide for myself and my family." model="q1" setScore={setScore} />

        <RadioQuestion label="I have personal transportation or access to public transportation." model="q2" setScore={setScore} />

        <RadioQuestion label="I live in a home and neighborhood that is safe and secure." model="q3" setScore={setScore} />

        <RadioQuestion label="I live in an environment free from alcohol and other drugs." model="q4" setScore={setScore} />

        <RadioQuestion label="I have an intimate partner supportive of my recovery process." model="q5" setScore={setScore} />

        <RadioQuestion label="I have family members who are supportive of my recovery process." model="q6" setScore={setScore} />

        <RadioQuestion label="I have friends who are supportive of my recovery process." model="q7" setScore={setScore} />

        <RadioQuestion
          label="I have people close to me (intimate partner, family members, or friends) who are also in recovery."
          model="q8"
          setScore={setScore}
        />

        <RadioQuestion
          label="I have a stable job that I enjoy and that provides for my basic necessities."
          model="q9"
          setScore={setScore}
        />

        <RadioQuestion
          label="I have an education or work environment that is conducive to my long-term recovery."
          model="q10"
          setScore={setScore}
        />

        <RadioQuestion
          label="I continue to participate in a continuing care program of an addiction treatment program, (e.g., groups, alumni association meetings, etc.)"
          model="q11"
          setScore={setScore}
        />

        <RadioQuestion
          label="I have a professional assistance program that is monitoring and supporting my recovery process."
          model="q12"
          setScore={setScore}
        />

        <RadioQuestion label="I have a primary care physician who attends to my health problems." model="q13" setScore={setScore} />

        <RadioQuestion label="I am now in reasonably good health." model="q14" setScore={setScore} />

        <RadioQuestion
          label="I have an active plan to manage any lingering or potential health problems."
          model="q15"
          setScore={setScore}
        />

        <RadioQuestion
          label="I am on prescribed medication that minimizes my cravings for alcohol and other drugs."
          model="q16"
          setScore={setScore}
        />

        <RadioQuestion
          label="I have insurance that will allow me to receive help for major health problems."
          model="q17"
          setScore={setScore}
        />

        <RadioQuestion label="I have access to regular, nutritious meals." model="q18" setScore={setScore} />

        <RadioQuestion
          label="I have clothes that are comfortable, clean and conducive to my recovery activities."
          model="q19"
          setScore={setScore}
        />

        <RadioQuestion label="I have access to recovery support groups in my local community." model="q20" setScore={setScore} />

        <RadioQuestion label="I have established close affiliation with a local recovery support group." model="q21" setScore={setScore} />

        <RadioQuestion
          label="I have a sponsor (or equivalent) who serves as a special mentor related to my recovery."
          model="q22"
          setScore={setScore}
        />

        <RadioQuestion label="I have access to Online recovery support groups." model="q23" setScore={setScore} />

        <RadioQuestion
          label="I have completed or am complying with all legal requirements related to my past."
          model="q24"
          setScore={setScore}
        />

        <RadioQuestion label="There are other people who rely on me to support their own recoveries." model="q25" setScore={setScore} />

        <RadioQuestion
          label="My immediate physical environment contains literature, tokens, posters or other symbols of my commitment to recovery."
          model="q26"
          setScore={setScore}
        />

        <RadioQuestion label="I have recovery rituals that are now part of my daily life." model="q27" setScore={setScore} />

        <RadioQuestion
          label="I had a profound experience that marked the beginning or deepening of my commitment to recovery."
          model="q28"
          setScore={setScore}
        />

        <RadioQuestion label="I now have goals and great hopes for my future." model="q29" setScore={setScore} />

        <RadioQuestion
          label="I have problem solving skills and resources that I lacked during my years of active addiction."
          model="q30"
          setScore={setScore}
        />

        <RadioQuestion
          label="I feel like I have meaningful, positive participation in my family and community."
          model="q31"
          setScore={setScore}
        />

        <RadioQuestion label="Today I have a clear sense of who I am." model="q32" setScore={setScore} />

        <RadioQuestion label="I know that my life has a purpose." model="q33" setScore={setScore} />

        <RadioQuestion label="Service to others is now an important part of my life." model="q34" setScore={setScore} />

        <RadioQuestion
          label="My personal values and sense of right and wrong have become clearer and stronger in recent years."
          model="q35"
          setScore={setScore}
        />
      </FormSection>

      <Divider />

      <Grid gap="1rem">
        <Score title="My Score" finalScore={finalScore} description="Possible Score: 175" />
        <SmartTextarea useDictation label="Item numbers on which I scored lowest:" model="data.questionnaire.lowest_score_areas" />
        <SmartTextarea useDictation label="Item numbers on which I scored highest:" model="data.questionnaire.highest_score_areas" />
      </Grid>
    </>
  )
}

export const RecoveryCapitalScaleAndPlanOverlay = withOverlayError(RootRecoveryCapitalScaleAndPlanOverlay)
