import React from 'react'

import ASAMCriteria from '../../components/Overlays/pages/DataForms/common/ASAMCriteria'
import Chotomate from '../../components/Chotomate'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootASAMCriteriaOverlay = () => (
  <DataFormOverlay
    title="ASAM Criteria"
    category="clinical_assessment"
    subcategory="asam_criteria"
    icon="clinical_assessments"
    helpID="clinical_assessments_asam_criteria"
    requestClientSignature={false}
    requestAuthorSignature={true}
    requireSupervisor={true}
    enableTreatmentPlanSelector
  >
    <Chotomate ready name="asam_criteria_overlay" />
    <ASAMCriteria />
  </DataFormOverlay>
)

export const ASAMCriteriaOverlay = withOverlayError(RootASAMCriteriaOverlay)
