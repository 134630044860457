import React from 'react'
import { saturate } from 'polished'
import { v4 as uuid } from 'uuid'

import { COLORS } from '../../theme'
import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { isDefined } from '../../utils/functions'

import Flex from '../Flex'
import Value from '../Value'

import { validate } from './validators'
import { withFormContext } from './context'
import FieldBase from './FieldBase'

const COLOR_PALETTE: any = [
  saturate(0, '#f44336'),
  saturate(0, '#e91e63'),
  saturate(0, '#9c27b0'),
  saturate(0, '#673ab7'),
  saturate(0, '#3f51b5'),
  saturate(0, '#2196f3'),
  saturate(0, '#03a9f4'),
  saturate(0, '#00bcd4'),
  saturate(0, '#009688'),
  saturate(0, '#4caf50'),
  saturate(0, '#8bc34a'),
  saturate(0, '#cddc39'),
  saturate(0, '#ffeb3b'),
  saturate(0, '#ffc107'),
  saturate(0, '#ff9800'),
  saturate(0, '#ff5722'),
  saturate(0, '#795548'),
  saturate(0, '#274879'),
  saturate(0, '#222222'),
  saturate(0, '#7f8c8d'),
  saturate(0, '#bdc3c7'),
  saturate(0, '#ecf0f1'),
]

export class ColorInput extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    const vs = { ...props.defaultValidations, ...props.validations }
    let value = props.value
    if (!value) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

      if (props.forceEmptyValue) value = ''
      else if (isDefined(modelVal)) value = modelVal
      else if (isDefined(initialModelVal)) value = initialModelVal
      else if (isDefined(props.defaultValue)) value = props.defaultValue
      else value = ''
    }

    if (vs) errors = validate(value, vs)

    if (props.type === 'number') value = parseFloat(value)

    this.state = {
      type: 'COLOR_INPUT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value,
      isNested: props.isNested || false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isBlur: false,
      isValidations: vs,
      isRequired: vs?.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }
    this.updateType = 'DATA'
  }

  editRender = () => {
    const { colors = COLOR_PALETTE } = this.props

    return (
      <>
        <div style={{ '--selected-color': this.state.value }}>
          <Flex gap="1.2rem" id={this.state.id}>
            {colors.map((color) => (
              <button
                data-test="color_picker_button"
                key={color}
                type="button"
                css={[styles.swatch, { backgroundColor: color }]}
                className={this.state.value === color ? 'is-active' : ''}
                onClick={() => this.changeValue(color)}
              />
            ))}
          </Flex>
        </div>
      </>
    )
  }

  readOnlyRender = () => {
    const { value } = this.state

    if (!value) return DEFAULT_EMPTY_VALUE

    return <div className="is-readonly" css={[styles.swatch, { backgroundColor: value }]} />
  }
}

const styles = {
  swatch: {
    display: 'inline-block',
    width: 28,
    height: 28,
    borderRadius: '50%',
    border: 'none',
    margin: '0.4rem',
    cursor: 'pointer',
    transition: 'all 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    boxShadow: `inset 0 0 0 1px rgba(12, 62, 106, 0.15)`,

    '&:hover': {
      transform: 'scale3d(1.15, 1.15, 1.15)',
    },

    '&.is-active': {
      boxShadow: `
        0 0 0 2px ${COLORS.white},
        0 0 0 4px var(--selected-color)
      `,
    },

    '&.is-readonly': {
      width: 20,
      height: 20,
      transform: 'none !important',
      cursor: 'auto !important',
      margin: 0,
    },
  },
}

ColorInput.defaultProps = {
  type: 'text',
  isEditable: true,
  isDisabled: false,
  defaultValidations: null,
}

export default withFormContext(ColorInput)
