import React from 'react'
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom-v5-compat'
import { transparentize } from 'polished'
import compact from 'lodash/compact'
import isUndefined from 'lodash/isUndefined'
import produce from 'immer'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'
import sortBy from 'lodash/sortBy'

import { create } from '../../modules/api/requests'
import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { MEDIA_QUERY } from '../../theme'
import { titleCase } from '../../utils/functions'
import { useDataTable } from '../../components/DataTable/useDataTable'
import { useGet, useCreate, invalidateQueries } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useRouteURL } from '../../hooks/useRouteURL'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AddendumCard from '../../components/AddendumCard'
import AddendumOverlay from '../Overlays/AddendumOverlay'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import DropdownItem from '../../components/DropdownItem'
import EventOverlay from '../../components/Overlays/pages/Calendar/EventOverlay'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Header from '../../components/Header'
import Input from '../../components/Forms/Input'
import LevelOfCareSelect from '../../components/Forms/LevelOfCareSelect'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import Nav from '../../components/Nav'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PageGrid from '../../components/PageGrid'
import PageSection from '../../components/PageSection/PageSection'
import Permission from '../../components/Permission'
import Portal from '../../components/Portal'
import ScrollMenu from '../../components/ScrollMenu'
import ScrollView from '../../components/ScrollView'
import State from '../../components/State'
import SummonOverlay from '../../components/SummonOverlay'
import Tabs from '../../components/Tabs'
import Tooltip from '../../components/Tooltip'
import WileyPracticePlannerOverlay from '../../components/Overlays/actions/Wiley/WileyPracticePlannerOverlay'

// Treatment Plan Components
import { TreatmentPlanAppointmentOverlay } from './TreatmentPlanAppointmentOverlay'
import { TreatmentPlanAppointmentsDataTable } from './TreatmentPlanAppointmentsDataTable'
import { TreatmentPlanDetails } from './components/TreatmentPlanDetails'
import { TreatmentPlanDiagnoses } from './components/TreatmentPlanDiagnoses'
import { TreatmentPlanProblems } from './components/TreatmentPlanProblems'
import { TreatmentPlanReviewsDataTable } from './TreatmentPlanReviewsDataTable'
import { TreatmentPlanSection } from './components/TreatmentPlanSection'
import { TreatmentPlanSignees } from './components/TreatmentPlanSignees'
import { TreatmentPlanSummary } from './components/TreatmentPlanSummary'
import { TreatmentPlanUpdatesDataTable } from './TreatmentPlanUpdatesDataTable'

// Treatment Plan Overlays
import { AutoGenerateReviewsCheckbox, ReviewOverlay } from './components/TreatmentPlanReviews'
import { TreatmentBookImportOverlay } from '../../constructs/TreatmentBook/TreatmentBookImportOverlay'
import { TreatmentPlanDiagnosisOverlay } from './TreatmentPlanDiagnosisOverlay'
import { TreatmentPlanGoalOverlay } from './TreatmentPlanGoalOverlay'
import { TreatmentPlanInterventionOverlay } from './TreatmentPlanInterventionOverlay'
import { TreatmentPlanLinkedDiagnosesOverlay } from './TreatmentPlanLinkedDiagnosesOverlay'
import { TreatmentPlanObjectiveOverlay } from './TreatmentPlanObjectiveOverlay'
import { TreatmentPlanProblemOverlay } from './TreatmentPlanProblemOverlay'
import { TreatmentPlanSettingsOverlay } from './TreatmentPlanSettingsOverlay'
import { TreatmentPlanStatus } from './components/TreatmentPlanStatus'
import { TreatmentPlanUpdatesOverlay } from './TreatmentPlanUpdatesOverlay'

import { TreatmentPlanCustomSections } from './form_elements/TreatmentPlanCustomSections'
import { TreatmentPlanGOIForm } from './TreatmentPlanGOIForm'
import { TreatmentPlanProblemsForm } from './TreatmentPlanProblemsForm'

import { SECTIONS } from './constants'

const processSigneesToSign = ({ settings = {}, signees = [] }: any) => {
  let result: any[] = []

  const signaturesRequired = settings?.initial_signatures_required

  const clientSignatureRequired = !!signaturesRequired?.client
  const supervisorsSignatureRequired = !!signaturesRequired?.supervisors
  const staffSignatureRequired = !!signaturesRequired?.staff

  if (!clientSignatureRequired && !supervisorsSignatureRequired && !staffSignatureRequired) {
    return result
  }

  for (const signee of signees) {
    const { category } = signee

    if (signee.initial_signature && signee.initial_signed_at) continue

    const shouldSign =
      (category === 'client' && clientSignatureRequired) ||
      (category === 'supervisor' && supervisorsSignatureRequired) ||
      (category === 'staff' && staffSignatureRequired)

    if (!shouldSign) continue

    result.push(signee)
  }

  return result
}

const stopPropagation = (e: any) => {
  e.stopPropagation()
  e.nativeEvent.stopImmediatePropagation()
}

const RootTreatmentPlanOverlay = (props: any) => {
  const { canEdit: propsCanEdit = true, allSectionsOpen } = props

  const { url } = useRouteURL()
  const { timezone } = useSettings()

  const autoReviewsForm = React.useRef()

  const {
    cancel,
    close,
    data: treatmentPlan,
    deleteRecord,
    edit,
    form,
    id: planId,
    isDeleting,
    isEditable,
    isNew,
    isOverlayLoading,
    isSaving,
    params,
    saveWithData,
    updateAsync: updatePlan,
  } = useOverlay({
    name: 'treatment_plans',
    endpoint: '/treatment_plans',
    invalidate: 'treatment_plans',
    invalidateKeys: ['treatment_plans', 'treatment-problem', 'treatment-goal', 'treatment-objective', 'treatment-intervention'],
    options: props,
  })

  const [tab, setTab] = React.useState('treatment_plan')
  const [goiData, setGOIData]: any = React.useState()
  const [problemsData, setProblemsData]: any = React.useState()
  const [isStarting, setIsStarting] = React.useState(false)

  const { plan_category }: any = params

  const category = snakeCase(plan_category || treatmentPlan?.category)

  const handleSave = () => {
    const formData = form.current.getFormValue()

    const formattedGOIData = produce(goiData, (draft) => {
      if (!draft?.treatment_goals_attributes) return

      // clean up full treatment_problems objects from treatment_goals
      for (let i = 0; i < size(draft.treatment_goals_attributes); i++) {
        if (draft.treatment_goals_attributes[i]?.treatment_problems) {
          delete draft.treatment_goals_attributes[i].treatment_problems
        }
      }
    })

    const saveData = { ...formData, ...formattedGOIData, ...problemsData }

    saveWithData(saveData)
  }

  const appointmentsProps = useDataTable({
    name: ['treatment_plans', planId, 'treatment-appointments'],
    endpoint: `/treatment_plans/${planId}/treatment_appointments`,
    enabled: !!planId && tab === 'appointments',
  })

  const reviewsTableProps = useDataTable({
    name: ['treatment_plans', planId, 'treatment-reviews'],
    endpoint: `/treatment_plans/${planId}/treatment_reviews`,
    enabled: !!planId && tab === 'plan_reviews',
  })

  const { data: signees, isLoading: isLoadingSignees } = useGet({
    name: ['treatment_plans', planId, 'treatment-signees'],
    url: `/treatment_plans/${planId}/treatment_signees`,
    options: { enabled: !!planId },
  })

  const { mutateAsync: importWiley } = useCreate({
    name: ['treatment_plans', planId, 'import_wiley'],
    url: `/treatment_plans/${planId}/import_wiley`,
    invalidate: ['treatment_plans'],
  })

  const { data: problems, isLoading: isLoadingProblems } = useGet({
    name: ['treatment_plans', planId, 'treatment-problems'],
    url: `/treatment_plans/${planId}/treatment_problems`,
    options: { enabled: !!planId },
  })

  const { data: diagnoses, isLoading: isLoadingDiagnoses } = useGet({
    name: ['treatment_plans', planId, 'treatment-diagnoses'],
    url: `/treatment_plans/${planId}/treatment_diagnoses`,
    options: { enabled: !!planId },
  })

  const { data: originalGoalsObjectivesInterventions, isLoading: isLoadingGoalsObjectivesInterventions } = useGet({
    name: ['treatment_plans', planId, 'treatment-goals-objectives-interventions'],
    url: `/treatment_plans/${planId}/treatment_goals_objectives_interventions`,
    options: { enabled: !!planId },
  })

  const { data: addendums } = useGet({
    name: ['treatment_plans', planId, 'treatment-addendums'],
    url: `/treatment_plans/${planId}/addendums`,
    options: { enabled: !!planId && treatmentPlan?.status === 'closed' },
  })

  const permissionBase = `${category}_treatment_plans`
  const treatmentBookPermissionBase = `${category}_treatment_book`

  const useDiagnoses = treatmentPlan?.settings?.enabled_sections?.diagnoses === true
  const useProblems = treatmentPlan?.settings?.enabled_sections?.problems === true
  const useGoals = treatmentPlan?.settings?.enabled_sections?.goals === true
  const useObjectives = treatmentPlan?.settings?.enabled_sections?.objectives === true || false
  const useInterventions = treatmentPlan?.settings?.enabled_sections?.interventions === true
  const useCustomSections = treatmentPlan?.settings?.use_custom_sections === true
  const useSummary = treatmentPlan?.settings?.enabled_sections?.summary === true
  const usePlanReviews = treatmentPlan?.settings?.use_plan_reviews === true
  const useAppointments = treatmentPlan?.settings?.use_appointments === true
  const useSignatures = treatmentPlan?.settings?.use_signatures === true
  const useUpdates = treatmentPlan?.settings?.use_updates === true

  const signeesToSign = React.useMemo(() => {
    if (!treatmentPlan || !signees) return

    return processSigneesToSign({ settings: treatmentPlan.settings, signees })
  }, [treatmentPlan, signees])

  const hasSigneesToSign = size(signeesToSign) > 0

  const diagnosisCodes = React.useMemo(() => {
    if (size(diagnoses) === 0) return null

    return diagnoses.map((o: any) => o?.diagnosis?.code)
  }, [diagnoses])

  const goalsObjectivesInterventions = React.useMemo(() => {
    return sortBy(originalGoalsObjectivesInterventions, 'created_at')
  }, [originalGoalsObjectivesInterventions])

  const appointmentLinkTo = React.useCallback((appointment: any) => {
    return `appointments/${appointment.id}`
  }, [])

  const reviewLinkTo = React.useCallback((review: any) => {
    return `reviews/${review.id}`
  }, [])

  const reviewReferenceLinkTo = React.useCallback((reference: any) => {
    if (!reference) return null

    if (reference.type === 'treatment_problem') return `problems/${reference.id}`
    if (reference.type === 'treatment_goal') return `goals/${reference.id}`
    if (reference.type === 'treatment_objective') return `objectives/${reference.id}`
    if (reference.type === 'treatment_intervention') return `interventions/${reference.id}`

    return null
  }, [])

  const eventLinkTo = React.useCallback((event: any) => {
    return `events/${event.id}`
  }, [])

  const handleStartTreatmentPlan = async () => {
    setIsStarting(true)

    try {
      await updatePlan({ status: 'active' })
    } catch (error) {
      console.error(error)
    } finally {
      setIsStarting(false)
    }
  }

  const handleCloseTreatmentPlan = async () => {
    try {
      await updatePlan({ status: 'closed' })
    } catch (error) {
      console.error(error)
    }
  }

  const handleWileyImport = async (importData: any) => {
    const autoReviewsFormValue = autoReviewsForm.current?.getFormValue?.()

    const result: any = {
      treatment_problems: [],
      treatment_goals: [],
      auto_generate_treatment_reviews: !!autoReviewsFormValue?.auto_generate_treatment_reviews,
    }

    const diagnoses: any = Object.values(importData)

    for (const diagnosis of diagnoses) {
      const newProblem: any = {
        category,
        name: diagnosis.problem_description,
        status: 'in_progress',
        behavioral_definitions: Object.values(diagnosis.definitions)
          .map((o) => o.description)
          .join('\n\n'),
      }

      result.treatment_problems.push(newProblem)

      const goals: any = Object.values(diagnosis.goals)

      for (const goal of goals) {
        const newGoal: any = {
          category,
          description: goal.description,
          status: 'in_progress',
          treatment_objectives: [],
        }

        const objectives: any = Object.values(goal.objectives)

        for (const objective of objectives) {
          const newObjective: any = {
            category,
            description: objective.description,
            status: 'in_progress',
            treatment_interventions: [],
          }

          const interventions: any = Object.values(objective.interventions)

          for (const intervention of interventions) {
            newObjective?.treatment_interventions.push({
              category,
              description: intervention.description?.description,
              status: 'in_progress',
            })
          }

          newGoal.treatment_objectives.push(newObjective)
        }

        result.treatment_goals.push(newGoal)
      }
    }

    await importWiley(result)
  }

  const getIsDatesEnabled = (section: any) => {
    // if setting is not present, section is enabled by default
    if (isUndefined(treatmentPlan?.settings?.sections?.[section])) return true

    return (
      treatmentPlan?.settings?.sections?.[section]?.start_date ||
      treatmentPlan?.settings?.sections?.[section]?.target_date ||
      treatmentPlan?.settings?.sections?.[section]?.completion_date
    )
  }

  const handleProblemsImport = React.useCallback(async (data: any, options: any) => {
    if (!data) return

    const result: any = []

    for (const problem of data) {
      result.push({
        category,
        status: 'in_progress',
        name: problem.name,
        behavioral_definitions: problem.behavioral_definitions,
      })
    }

    await create(`/treatment_plans/${planId}/import_treatment_problems`, {
      auto_generate_treatment_reviews: !!options?.auto_generate_treatment_reviews,
      treatment_problems: result,
    })

    invalidateQueries(null, 'treatment_plans')
    invalidateQueries(null, 'treatment-plan-updates')
  }, [])

  const handleGoalsImport = React.useCallback(async (data: any, options: any) => {
    if (!data) return

    const result: any = []

    for (const goal of data) {
      result.push({
        category,
        name: goal.name,
        status: 'in_progress',
        description: goal.description,
        evidence_based_treatment: goal.evidence_based_treatment,
        evidence_based_treatment_other: goal.evidence_based_treatment_other,
      })
    }

    await create(`/treatment_plans/${planId}/import_treatment_goals`, {
      auto_generate_treatment_reviews: !!options?.auto_generate_treatment_reviews,
      treatment_goals: result,
    })

    invalidateQueries(null, 'treatment_plans')
    invalidateQueries(null, 'treatment-plan-updates')
  }, [])

  const handleObjectivesImport = React.useCallback(async (goalId: string, data: any, options: any) => {
    if (!goalId || !data) return

    const result: any = []

    for (const objective of data) {
      result.push({
        category,
        status: 'in_progress',
        name: objective.name,
        description: objective.description,
      })
    }

    await create(`/treatment_goals/${goalId}/import_treatment_objectives`, {
      auto_generate_treatment_reviews: !!options?.auto_generate_treatment_reviews,
      treatment_objectives: result,
    })

    invalidateQueries(null, 'treatment_plans')
    invalidateQueries(null, 'treatment-plan-updates')
  }, [])

  const handleInterventionsImport = React.useCallback(async (goalId: string, objectiveId: string, data: any, options: any) => {
    if (!goalId || !objectiveId || !data) return

    const result: any = []

    for (const intervention of data) {
      result.push({
        category,
        status: 'in_progress',
        name: intervention.name,
        description: intervention.description,
        treatment_objective_id: objectiveId,
      })
    }

    await create(`/treatment_objectives/${objectiveId}/import_treatment_interventions`, {
      auto_generate_treatment_reviews: !!options?.auto_generate_treatment_reviews,
      treatment_interventions: result,
    })

    invalidateQueries(null, 'treatment_plans')
    invalidateQueries(null, 'treatment-plan-updates')
  }, [])

  const diagnosesActions = !isEditable && useDiagnoses && (
    <Permission permission="diagnoses.view">
      <Flex nowrap gap="0.75rem">
        <Button
          nowrap
          as={Link}
          label="Add Diagnosis"
          glyph="add"
          type="primary"
          size={100}
          link={`diagnoses/new`}
          onClick={stopPropagation}
          permission={`${permissionBase}.edit`}
        />

        <Button
          nowrap
          as={Link}
          label="Select Client Diagnoses to Link…"
          glyph="add_file"
          type="default"
          size={100}
          link={`diagnoses`}
          onClick={stopPropagation}
          permission={`${permissionBase}.edit`}
        />
      </Flex>
    </Permission>
  )

  const problemActions = !isEditable && useProblems && (
    <Flex nowrap gap="0.75rem">
      <Button
        nowrap
        as={Link}
        label="Add Problem"
        type="primary"
        glyph="add"
        size={100}
        link="problems/new"
        state={{
          data: { name: `Problem ${size(problems) + 1}` },
        }}
        onClick={stopPropagation}
        permission={`${permissionBase}.edit`}
      />

      <Permission featureFlagV2="treatment_book" permission={`${treatmentBookPermissionBase}.view`}>
        <Button
          nowrap
          as={Link}
          label="Import Problem from Treatment Book…"
          type="default"
          glyph="add_file"
          size={100}
          link={`import-problems`}
          permission={`${permissionBase}.edit`}
          onClick={stopPropagation}
        />
      </Permission>
    </Flex>
  )

  const goalsActions = !isEditable && useGoals && (
    <Flex nowrap gap="0.75rem">
      <Button
        nowrap
        as={Link}
        label="Add Goal"
        glyph="add"
        type="primary"
        size={100}
        link="goals/new"
        state={{
          data: { name: `Goal ${size(goalsObjectivesInterventions) + 1}` },
        }}
        onClick={stopPropagation}
        permission={`${permissionBase}.edit`}
      />

      <Permission featureFlagV2="treatment_book" permission={`${treatmentBookPermissionBase}.view`}>
        <Button
          nowrap
          as={Link}
          label="Import Goal from Treatment Book…"
          glyph="add_file"
          type="default"
          size={100}
          link={`import-goals`}
          onClick={stopPropagation}
          permission={`${permissionBase}.edit`}
        />
      </Permission>
    </Flex>
  )

  const problemsShowSettings = React.useMemo(() => {
    const problemsSettings = treatmentPlan?.settings?.sections?.problems || {}
    const { start_date, target_date, completion_date } = problemsSettings

    return {
      ...problemsSettings,
      start_date: isUndefined(start_date) || start_date === true,
      target_date: isUndefined(target_date) || target_date === true,
      completion_date: isUndefined(completion_date) || completion_date === true,
    }
  }, [treatmentPlan])

  const goalsShowSettings = React.useMemo(() => {
    const goalsSettings = treatmentPlan?.settings?.sections?.goals || {}
    const { start_date, target_date, completion_date } = goalsSettings

    return {
      ...goalsSettings,
      start_date: isUndefined(start_date) || start_date === true,
      target_date: isUndefined(target_date) || target_date === true,
      completion_date: isUndefined(completion_date) || completion_date === true,
    }
  }, [treatmentPlan])

  const objectivesShowSettings = React.useMemo(() => {
    const objectivesSettings = treatmentPlan?.settings?.sections?.objectives || {}
    const { start_date, target_date, completion_date } = objectivesSettings

    return {
      ...objectivesSettings,
      start_date: isUndefined(start_date) || start_date === true,
      target_date: isUndefined(target_date) || target_date === true,
      completion_date: isUndefined(completion_date) || completion_date === true,
    }
  }, [treatmentPlan])

  const interventionsShowSettings = React.useMemo(() => {
    const interventionsSettings = treatmentPlan?.settings?.sections?.interventions || {}
    const { start_date, target_date, completion_date } = interventionsSettings

    return {
      ...interventionsSettings,
      start_date: isUndefined(start_date) || start_date === true,
      target_date: isUndefined(target_date) || target_date === true,
      completion_date: isUndefined(completion_date) || completion_date === true,
    }
  }, [treatmentPlan])

  if (isOverlayLoading || !treatmentPlan) return <OverlayLoader position="right" maxWidth={120} />

  const status = treatmentPlan?.status
  const isClosed = status === 'closed'
  const canEdit = propsCanEdit && !isClosed

  const showProblemDates = getIsDatesEnabled('problems')
  const showGoalDates = getIsDatesEnabled('goals')
  const showObjectiveDates = getIsDatesEnabled('objectives')
  const showInterventionDates = getIsDatesEnabled('interventions')

  // force refresh overlay scroll view when the treatment plan sections are updated
  // const updateKey = React.useMemo(() => {
  //   const enabledSections = [
  //     useDiagnoses && 'diagnoses',
  //     useProblems && 'problems',
  //     (useGoals || useObjectives || useInterventions) && 'goals_objectives_interventions',
  //     useCustomSections && 'custom_sections',
  //     useSummary && 'summary',
  //     useSignatures && 'signatures',
  //   ]

  //   return enabledSections.join('-')
  // }, [
  //   useDiagnoses,
  //   useProblems,
  //   useGoals,
  //   useObjectives,
  //   useInterventions,
  //   useCustomSections,
  //   useSummary,
  //   useSignatures,
  //   treatmentPlan?.updated_at,
  // ])

  return (
    <>
      <Overlay
        key={treatmentPlan?.id}
        // animateOnEnter={!!treatmentPlan}
        position="right"
        onClose={close}
        maxWidth={120}
        showBackdrop={isEditable}
      >
        <Overlay.Header
          icon="treatment_plans"
          title="Treatment Plan"
          titleAside={
            <Permission permission="clients.actions.export">
              <ExportPDFButton url={`/treatment_plans/${planId}/pdf`} />
            </Permission>
          }
        />

        <Tabs
          activeTab={tab}
          onChange={setTab}
          defaultTab="treatment_plan"
          className="grid grid-rows-[auto_1fr] grid-cols-1 w-full h-full overflow-hidden"
        >
          <Overlay.SubHeader className="!px-4 !py-0 relative z-[3]">
            <Tabs.List className="!border-none">
              <Tabs.Item label="Treatment Plan" icon="treatment_plans" name="treatment_plan" />
              <Tooltip show={isEditable} content="Please save the Treatment Plan frist">
                {usePlanReviews && <Tabs.Item label="Plan Reviews" icon="addendums" name="plan_reviews" isDisabled={isEditable} />}
                {useAppointments && <Tabs.Item label="Appointments" icon="calendar" name="appointments" isDisabled={isEditable} />}
                {useUpdates && <Tabs.Item label="Updates" icon="reports" name="updates" isDisabled={isEditable} />}
              </Tooltip>
            </Tabs.List>
          </Overlay.SubHeader>

          <div className="grid grid-rows-[100%] grid-cols-[100%] bg-[#F7F8FB] overflow-hidden">
            <Tabs.Panel name="treatment_plan" css={STYLES.tabsPanel}>
              <ScrollView>
                <PageGrid scroll breakpoint={3} className="overlay-page-grid" css={STYLES.pageGrid}>
                  <Nav
                    top="0"
                    breakpoint={3}
                    headingSize={300}
                    title="Table of Contents"
                    background={transparentize(0.4, 'white')}
                    css={STYLES.nav}
                  >
                    <ScrollMenu />
                  </Nav>

                  <div className="grid grid-rows-[auto_1fr] grid-cols-[100%] overflow-hidden">
                    <Header isSticky>
                      <Flex centerY justifyContent="space-between" gap="1rem" className="flex-[1_1_auto]">
                        <TreatmentPlanStatus status={treatmentPlan.status} className="!mr-auto" />

                        <Tooltip show={canEdit && isEditable} content="Please save Treatment Plan first">
                          {canEdit && (
                            <div>
                              <Flex gap="0.75rem">
                                <Button
                                  as={Link}
                                  label="Settings"
                                  glyph="settings"
                                  size={200}
                                  onClick={stopPropagation}
                                  link="settings"
                                  type="minimal"
                                  permission={`${permissionBase}.edit`}
                                  isDisabled={isEditable}
                                />

                                <Permission permission={`${permissionBase}.edit`}>
                                  <Button
                                    as={Link}
                                    label="Import from Wiley"
                                    glyph="add_file"
                                    size={200}
                                    type="primary"
                                    color="green"
                                    link={`wiley-builder`}
                                    featureFlagV2="wiley_treatment_plans"
                                    permission="wiley_treatment_plans.view"
                                    isDisabled={isEditable}
                                  />
                                </Permission>

                                {status === 'draft' && (
                                  <Tooltip
                                    show={hasSigneesToSign}
                                    content={
                                      hasSigneesToSign && (
                                        <div>
                                          <div>The following signees are yet to sign-off this Treatment Plan before it can be started:</div>
                                          <div>
                                            <b>{signeesToSign?.map((o) => o.reference?.name)?.join(', ')}</b>
                                          </div>
                                        </div>
                                      )
                                    }
                                  >
                                    <Button
                                      label="Start Treatment Plan"
                                      glyph="signature"
                                      color="blue"
                                      type="primary"
                                      size={200}
                                      isDisabled={hasSigneesToSign}
                                      onClick={handleStartTreatmentPlan}
                                      isLoading={isStarting}
                                      permission={`${permissionBase}.edit`}
                                      isDisabled={isEditable || hasSigneesToSign}
                                    />
                                  </Tooltip>
                                )}

                                {status === 'active' && (
                                  <Permission permission={`${permissionBase}.edit`}>
                                    <DeleteDialog
                                      glyph="cross"
                                      title="Close Treatment Plan"
                                      yesLabel="Close Treatment Plan"
                                      message="Are you sure you want to close this Treatment Plan? This action cannot be undone."
                                      onYes={handleCloseTreatmentPlan}
                                      isDisabled={isEditable}
                                    >
                                      <Button label="Close Plan" glyph="cross" color="red" size={200} isDisabled={isEditable} />
                                    </DeleteDialog>
                                  </Permission>
                                )}
                              </Flex>
                            </div>
                          )}
                        </Tooltip>
                      </Flex>
                    </Header>

                    <Overlay.Content>
                      <Form
                        getForm={form}
                        key={treatmentPlan?.updated_at}
                        isCompact
                        isEditable={isEditable}
                        initialModel={treatmentPlan}
                        autoFocus={false}
                        className="grid gap-4 grid-cols-[100%] w-full"
                        timezone={timezone}
                      >
                        <PageSection>
                          <PageSection.Content className="grid gap-0 grid-cols-[100%] pb-[100px]">
                            <TreatmentPlanSection isOpen {...SECTIONS.general_info}>
                              <Card className="px-4 py-3">
                                {treatmentPlan?.settings?.instructions?.treatment_plan && (
                                  <div className="pr-4 mb-3">
                                    <Alert contrast small glyph="info">
                                      {treatmentPlan.settings.instructions?.treatment_plan}
                                    </Alert>
                                  </div>
                                )}

                                <div className="grid gap-4 grid-cols-1 mq800:grid-cols-2 mq1000:grid-cols-3 mq1200:grid-cols-3 mq1400:grid-cols-5">
                                  <Input
                                    vertical
                                    label="Name"
                                    model="name"
                                    validations={{
                                      presence: {
                                        message: 'Please enter a name',
                                      },
                                    }}
                                  />

                                  <DateTimeInput
                                    defaultToNow
                                    model="started_at"
                                    label="Start Date and Time"
                                    validations={{
                                      presence: {
                                        message: 'Please enter a date and time',
                                      },
                                    }}
                                  />

                                  <DateTimeInput isEditable={false} model="ended_at" label="End Date and Time" />

                                  <LevelOfCareSelect model="level_of_care" />
                                  <DateInput cypress="appointments_date_and_time_input" label="Review Date" model="last_reviewed_at" />
                                  <Input label="Frequency of Appointments" model="appointment_frequency" />
                                  <Input label="Estimated Length of Treatment" model="estimated_length" />

                                  <MultiOverlaySelector
                                    isPolymorphic
                                    label="Supervisors"
                                    model="supervisors"
                                    type="employees.clinical_supervisors"
                                    icon="employees"
                                    selectTitle={(data: any) => data.name}
                                    selectDescription={(data: any) => titleCase(data.position)}
                                  />

                                  <MultiOverlaySelector
                                    label="Staff Members"
                                    model="employees"
                                    type="employees.active"
                                    icon="employees"
                                    selectTitle={(data: any) => data.name}
                                    selectDescription={(data: any) => titleCase(data.position)}
                                  />
                                </div>
                              </Card>
                            </TreatmentPlanSection>

                            {useDiagnoses && (
                              <TreatmentPlanDiagnoses
                                data={diagnoses}
                                isLoading={isLoadingDiagnoses}
                                setEditLink={isEditable ? undefined : (id: string) => `diagnoses/${id}`}
                                headerActions={canEdit && diagnosesActions}
                                isOpen={allSectionsOpen || true}
                                diagnosesActions={diagnosesActions}
                              />
                            )}

                            {useProblems && (
                              <TreatmentPlanSection {...SECTIONS.problems}>
                                {isEditable && (
                                  <TreatmentPlanProblemsForm
                                    planId={planId}
                                    data={problems}
                                    onUpdate={setProblemsData}
                                    showDates={showProblemDates}
                                    useDiagnoses={useDiagnoses}
                                    useGoals={useGoals}
                                    useObjectives={useObjectives}
                                    useInterventions={useInterventions}
                                    usePlanReviews={usePlanReviews}
                                    problemsShowSettings={problemsShowSettings}
                                  />
                                )}

                                {!isEditable && (
                                  <TreatmentPlanProblems
                                    data={problems}
                                    isLoading={isLoadingProblems}
                                    setEditLink={(id: string) => `problems/${id}`}
                                    isOpen={allSectionsOpen || true}
                                    canEdit={canEdit && !isEditable}
                                    planId={planId}
                                    permissionBase={permissionBase}
                                    showDates={showProblemDates}
                                    usePlanReviews={usePlanReviews}
                                    problemActions={problemActions}
                                    problemsShowSettings={problemsShowSettings}
                                  />
                                )}
                              </TreatmentPlanSection>
                            )}

                            {useGoals && (
                              <>
                                <TreatmentPlanSection
                                  {...SECTIONS.goals_objectives_interventions}
                                  title={compact([
                                    useGoals && 'Goals',
                                    useObjectives && 'Objectives',
                                    useInterventions && 'Interventions',
                                  ]).join(', ')}
                                >
                                  {isEditable && (
                                    <TreatmentPlanGOIForm
                                      planId={planId}
                                      useProblems={useProblems}
                                      data={goalsObjectivesInterventions}
                                      onUpdate={setGOIData}
                                      showGoalDates={showGoalDates}
                                      showObjectiveDates={showObjectiveDates}
                                      showInterventionDates={showInterventionDates}
                                      useGoals={useGoals}
                                      useObjectives={useObjectives}
                                      useInterventions={useInterventions}
                                      usePlanReviews={usePlanReviews}
                                      goalsShowSettings={goalsShowSettings}
                                      objectivesShowSettings={objectivesShowSettings}
                                      interventionsShowSettings={interventionsShowSettings}
                                    />
                                  )}

                                  {!isEditable && (
                                    <TreatmentPlanDetails
                                      canEdit={canEdit && !isEditable}
                                      planId={planId}
                                      showGoalDates={showGoalDates}
                                      showObjectiveDates={showObjectiveDates}
                                      showInterventionDates={showInterventionDates}
                                      permissionBase={permissionBase}
                                      data={goalsObjectivesInterventions}
                                      isLoading={isLoadingGoalsObjectivesInterventions}
                                      useGoals={useGoals}
                                      useObjectives={useObjectives}
                                      useInterventions={useInterventions}
                                      usePlanReviews={usePlanReviews}
                                      setProblemLink={(id: string) => `problems/${id}`}
                                      setGoalLink={(id: string) => `goals/${id}`}
                                      setObjectiveLink={(id: string) => `objectives/${id}`}
                                      setInterventionLink={(id: string) => `interventions/${id}`}
                                      isOpen={allSectionsOpen || true}
                                      goalActions={goalsActions}
                                      goalsShowSettings={goalsShowSettings}
                                      objectivesShowSettings={objectivesShowSettings}
                                      interventionsShowSettings={interventionsShowSettings}
                                      setObjectiveAddLink={
                                        canEdit
                                          ? (parentGoal: any, name) => ({
                                              link: `objectives/new`,
                                              state: { data: { treatment_goal: parentGoal, name } },
                                            })
                                          : null
                                      }
                                      setInterventionAddLink={
                                        canEdit
                                          ? (parentObjective: any, name) => ({
                                              link: `interventions/new`,
                                              state: { data: { treatment_objective: parentObjective, name } },
                                            })
                                          : null
                                      }
                                      setObjectiveImportLink={
                                        canEdit
                                          ? (parentGoal: any) => ({
                                              pathname: `import-objectives-to-goals/${parentGoal.id}`,
                                            })
                                          : null
                                      }
                                      setInterventionImportLink={
                                        canEdit
                                          ? (parentGoal: any, parentObjective: any) => ({
                                              pathname: `import-interventions-to-goal/${parentGoal.id}/objective/${parentObjective.id}`,
                                            })
                                          : null
                                      }
                                      objectiveActions={
                                        useInterventions && (
                                          <>
                                            <DropdownItem
                                              label="Add New Intervention"
                                              glyph="add"
                                              color="blue"
                                              permission={`${permissionBase}.edit`}
                                            />

                                            <Permission featureFlagV2="treatment_book" permission={`${treatmentBookPermissionBase}.view`}>
                                              <DropdownItem label="Import Interventions" glyph="add_file" color="blue" />
                                            </Permission>
                                          </>
                                        )
                                      }
                                    />
                                  )}
                                </TreatmentPlanSection>
                              </>
                            )}

                            {useCustomSections && (
                              <TreatmentPlanSection {...SECTIONS.custom_sections}>
                                <Card className="px-4 py-3">
                                  <TreatmentPlanCustomSections
                                    form={form}
                                    isEditable={isEditable}
                                    planId={planId}
                                    canEdit={canEdit && !isEditable}
                                    permissionBase={permissionBase}
                                    model="custom_sections"
                                  />
                                </Card>
                              </TreatmentPlanSection>
                            )}

                            {useSummary && (
                              <TreatmentPlanSummary
                                isEditable={isEditable}
                                planId={planId}
                                canEdit={canEdit && !isEditable}
                                permissionBase={permissionBase}
                              />
                            )}

                            {useSignatures && (
                              <TreatmentPlanSection {...SECTIONS.signatures}>
                                <TreatmentPlanSignees
                                  canEdit={canEdit && !isEditable}
                                  signees={signees}
                                  settings={treatmentPlan.settings}
                                  permissionBase={permissionBase}
                                />
                              </TreatmentPlanSection>
                            )}

                            {isClosed && propsCanEdit && (
                              <>
                                <TreatmentPlanSection
                                  {...SECTIONS.addendums}
                                  after={
                                    <SummonOverlay
                                      overlay={
                                        <AddendumOverlay
                                          reference={treatmentPlan}
                                          onUpdate={() => {
                                            invalidateQueries('treatment_plans')
                                          }}
                                        />
                                      }
                                    >
                                      <Button
                                        size={200}
                                        label="Add New Addendum"
                                        glyph="add"
                                        type="primary"
                                        display="inline-flex"
                                        className="!mt-4"
                                      />
                                    </SummonOverlay>
                                  }
                                >
                                  <Grid gap="1rem">
                                    {addendums?.map?.((addendum: any) => {
                                      return (
                                        <AddendumCard
                                          key={addendum.id}
                                          data={addendum}
                                          onUpdate={() => {
                                            invalidateQueries('treatment_plans')
                                          }}
                                        />
                                      )
                                    })}
                                  </Grid>
                                </TreatmentPlanSection>
                              </>
                            )}
                          </PageSection.Content>
                        </PageSection>
                      </Form>
                    </Overlay.Content>
                  </div>
                </PageGrid>
              </ScrollView>
            </Tabs.Panel>

            <Tabs.Panel name="plan_reviews" css={STYLES.tabsPanel}>
              <Overlay.Content>
                <div className="-mt-4">
                  <TreatmentPlanSection
                    {...SECTIONS.plan_reviews}
                    after={
                      canEdit && (
                        <Button
                          as={Link}
                          label="Add Plan Review"
                          size={200}
                          glyph="add"
                          type="primary"
                          onClick={stopPropagation}
                          link={`reviews/new`}
                        />
                      )
                    }
                  >
                    <TreatmentPlanReviewsDataTable
                      asCard
                      {...reviewsTableProps}
                      to={reviewLinkTo}
                      referenceLinkTo={reviewReferenceLinkTo}
                    />
                  </TreatmentPlanSection>
                </div>
              </Overlay.Content>
            </Tabs.Panel>

            <Tabs.Panel name="appointments" css={STYLES.tabsPanel}>
              <Overlay.Content>
                <div className="-mt-4">
                  <TreatmentPlanSection
                    {...SECTIONS.appointments}
                    after={
                      canEdit && (
                        <Button
                          as={Link}
                          label="Add Appointment"
                          size={200}
                          glyph="add"
                          type="primary"
                          onClick={stopPropagation}
                          link={`appointments/new`}
                          permission={`${permissionBase}.edit`}
                        />
                      )
                    }
                  >
                    <TreatmentPlanAppointmentsDataTable
                      {...appointmentsProps}
                      asCard
                      appointmentLinkTo={appointmentLinkTo}
                      eventLinkTo={eventLinkTo}
                    />
                  </TreatmentPlanSection>
                </div>
              </Overlay.Content>
            </Tabs.Panel>

            <Tabs.Panel name="updates" css={STYLES.tabsPanel} type="mount">
              <Overlay.Content>
                <div className="-mt-4">
                  <TreatmentPlanSection
                    {...SECTIONS.updates}
                    after={
                      <Button
                        as={Link}
                        label="View All Updates"
                        size={200}
                        glyph="view"
                        type="minimal"
                        onClick={stopPropagation}
                        link={`view-updates`}
                      />
                    }
                  >
                    <TreatmentPlanUpdatesDataTable asCard planId={planId} />
                  </TreatmentPlanSection>
                </div>
              </Overlay.Content>
            </Tabs.Panel>
          </div>
        </Tabs>

        {canEdit && tab === 'treatment_plan' && (
          <Overlay.Footer>
            {isEditable && (
              <>
                <Button
                  label="Save"
                  glyph="check"
                  type="primary"
                  color="green"
                  onClick={handleSave}
                  isLoading={isSaving}
                  isDisabled={isSaving}
                  flex="100 1 auto"
                />

                {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
              </>
            )}

            {!isEditable && canEdit && (
              <>
                <Button
                  glyph="edit"
                  label="Edit Treatment Plan"
                  type="default"
                  isDisabled={isDeleting}
                  onClick={edit}
                  flex="100 1 auto"
                  permission={`${permissionBase}.edit`}
                />

                {status === 'draft' && (
                  <DeleteDialog
                    title="Delete Treatment Plan?"
                    message="Are you sure you want to delete this Treatment Plan? This action cannot be undone."
                    onYes={deleteRecord}
                    permission={`${permissionBase}.delete`}
                  >
                    <Button
                      glyph="delete"
                      label="Delete"
                      type="default"
                      color="red"
                      isLoading={isDeleting}
                      fullWidth
                      permission={`${permissionBase}.delete`}
                    />
                  </DeleteDialog>
                )}
              </>
            )}
          </Overlay.Footer>
        )}
      </Overlay>

      <Portal type="overlay">
        <Routes>
          <Route path="view-updates" element={<TreatmentPlanUpdatesOverlay useV6Router back={url} planId={planId} />} />

          <Route
            path="/import-problems/*"
            element={
              <Permission featureFlag="treatment_book" permission={`${treatmentBookPermissionBase}.view`}>
                {canEdit && (
                  <TreatmentBookImportOverlay
                    useV6Router
                    back={url}
                    planId={planId}
                    category={category}
                    importType="problems"
                    onMultiSelect={handleProblemsImport}
                    onSingleSelect={(data: any, options: any) => handleProblemsImport([data], options)}
                    permissionBase={permissionBase}
                  />
                )}
              </Permission>
            }
          />

          <Route
            path="import-goals"
            element={
              <Permission featureFlagV2="treatment_book" permission={`${treatmentBookPermissionBase}.view`}>
                {canEdit && (
                  <TreatmentBookImportOverlay
                    useV6Router
                    back={url}
                    planId={planId}
                    category={category}
                    importType="goals"
                    onMultiSelect={handleGoalsImport}
                    onSingleSelect={(data: any, options: any) => handleGoalsImport([data], options)}
                    permissionBase={permissionBase}
                  />
                )}
              </Permission>
            }
          />

          <Route
            path="import-objectives-to-goals/:goal_id"
            element={
              canEdit && (
                <ObjectivesImportOverlay
                  back={url}
                  planId={planId}
                  category={category}
                  permissionBase={permissionBase}
                  onImport={handleObjectivesImport}
                />
              )
            }
          />

          <Route
            path="wiley-builder"
            element={
              <Permission featureFlagV2="wiley_treatment_plans" permission="wiley_treatment_plans.view">
                <Permission permission={`${permissionBase}.edit`}>
                  {canEdit && (
                    <WileyPracticePlannerOverlay
                      useV6Router
                      back={url}
                      showBackdrop
                      position="right"
                      maxWidth={90}
                      onSave={handleWileyImport}
                      diagnosisCodes={diagnosisCodes}
                      reviewStepAfter={
                        <Form getForm={autoReviewsForm} className="mt-4">
                          <AutoGenerateReviewsCheckbox planId={planId} />
                        </Form>
                      }
                    />
                  )}
                </Permission>
              </Permission>
            }
          />

          <Route
            path={`import-interventions-to-goal/:goal_id/objective/:objective_id`}
            element={
              canEdit && (
                <InterventionsImportOverlay
                  back={url}
                  planId={planId}
                  category={category}
                  permissionBase={permissionBase}
                  onImport={handleInterventionsImport}
                />
              )
            }
          />

          <Route
            path={`problems/:id`}
            element={
              <TreatmentPlanProblemOverlay
                useV6Router
                back={url}
                planId={planId}
                planCategory={category}
                canEdit={canEdit && !isEditable}
                permissionBase={permissionBase}
                treatmentBookPermissionBase={treatmentBookPermissionBase}
                showDates={showProblemDates}
              />
            }
          />

          <Route
            path={`goals/:id`}
            element={
              <TreatmentPlanGoalOverlay
                useV6Router
                back={url}
                planId={planId}
                planCategory={category}
                canEdit={canEdit && !isEditable}
                permissionBase={permissionBase}
                treatmentBookPermissionBase={treatmentBookPermissionBase}
                showDates={showGoalDates}
              />
            }
          />

          <Route
            path={`objectives/:id`}
            element={
              <TreatmentPlanObjectiveOverlay
                useV6Router
                back={url}
                planId={planId}
                planCategory={category}
                canEdit={canEdit && !isEditable}
                permissionBase={permissionBase}
                treatmentBookPermissionBase={treatmentBookPermissionBase}
                showDates={showObjectiveDates}
              />
            }
          />

          <Route
            path={`interventions/:id`}
            element={
              <TreatmentPlanInterventionOverlay
                useV6Router
                back={url}
                planId={planId}
                planCategory={category}
                canEdit={canEdit && !isEditable}
                permissionBase={permissionBase}
                treatmentBookPermissionBase={treatmentBookPermissionBase}
                showDates={showInterventionDates}
              />
            }
          />

          <Route
            path={`diagnoses`}
            element={
              canEdit && <TreatmentPlanLinkedDiagnosesOverlay useV6Router back={url} planId={planId} permissionBase={permissionBase} />
            }
          />

          <Route
            path={`settings`}
            element={canEdit && <TreatmentPlanSettingsOverlay useV6Router back={url} permissionBase={permissionBase} />}
          />

          <Route
            path={`diagnoses/:id`}
            element={
              <Permission permission="diagnoses.view">
                <TreatmentPlanDiagnosisOverlay
                  useV6Router
                  back={url}
                  planId={planId}
                  canEdit={canEdit && !isEditable}
                  permissionBase={permissionBase}
                />
              </Permission>
            }
          />

          <Route
            path={`appointments/:id`}
            element={
              <TreatmentPlanAppointmentOverlay
                useV6Router
                back={url}
                planId={planId}
                planCategory={category}
                canEdit={canEdit && !isEditable}
                permissionBase={permissionBase}
              />
            }
          />

          <Route
            path={`reviews/:id`}
            element={
              <ReviewOverlay
                useV6Router
                back={url}
                planId={planId}
                planCategory={category}
                canEdit={canEdit && !isEditable}
                permissionBase={permissionBase}
                position="right"
              />
            }
          />

          <Route
            path={`events/:id`}
            element={
              <EventOverlay useV6Router back={url} maxWidth={37} invalidateKeys={['treatment-appointments', 'treatment-appointment']} />
            }
          />
        </Routes>
      </Portal>
    </>
  )
}

export const TreatmentPlanOverlay = withOverlayError(RootTreatmentPlanOverlay)

const ObjectivesImportOverlay = (props: any) => {
  const { back, planId, category, permissionBase, onImport } = props

  const { goal_id: goalId } = useParams()

  const treatmentBookPermissionBase = `${category}_treatment_book`

  return (
    <Permission featureFlagV2="treatment_book" permission={`${treatmentBookPermissionBase}.view`}>
      <TreatmentBookImportOverlay
        useV6Router
        back={back}
        planId={planId}
        category={category}
        importType="objectives"
        onMultiSelect={(data: any, options: any) => onImport(goalId, data, options)}
        onSingleSelect={(data: any, options: any) => onImport(goalId, [data], options)}
        permissionBase={permissionBase}
      />
    </Permission>
  )
}

const InterventionsImportOverlay = (props: any) => {
  const { back, planId, category, permissionBase, onImport } = props

  const { goal_id: goalId, objective_id: objectiveId } = useParams()

  const treatmentBookPermissionBase = `${category}_treatment_book`

  return (
    <Permission featureFlagV2="treatment_book" permission={`${treatmentBookPermissionBase}.view`}>
      <TreatmentBookImportOverlay
        useV6Router
        back={back}
        planId={planId}
        category={category}
        importType="interventions"
        onMultiSelect={(data: any, options: any) => onImport(goalId, objectiveId, data, options)}
        onSingleSelect={(data: any, options: any) => onImport(goalId, objectiveId, [data], options)}
        permissionBase={permissionBase}
      />
    </Permission>
  )
}

const STYLES = {
  pageGrid: {
    flex: '1 1 auto',
  },

  nav: {
    [MEDIA_QUERY[3]]: {
      maxWidth: 300,
    },
  },

  formContract: {
    '&:last-of-type': { boxShadow: 'none !important' },
  },

  tabsPanel: {
    '&.is-active': {
      display: 'grid',
      gridTemplateRows: '100%',
      gridTemplateColumns: '100%',
      overflow: 'hidden',
    },
  },
}
