import React from 'react'
import { useParams } from 'react-router-dom'

import { titleCase } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Avatar from '@behavehealth/components/Avatar'
import Card from '@behavehealth/components/Card'
import Page from '@behavehealth/components/Page'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import State from '@behavehealth/components/State'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const CATEGORY = {
  housing: 'Housing',
  office: 'Office',
  mixed_category: 'Mixed (Housing & Office)',
}

const RootLocations = () => {
  const params: any = useParams()
  const { resource_id: id } = params

  const { data: partnership, isLoading, isError, error }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  const isShared = partnership?.other_partner_settings?.locations === true

  const tableProps: any = useDataTable({
    name: ['organization', id, 'shared-in-locations'],
    endpoint: `/organizations/${id}/other_partner_locations`,
    options: { enabled: isShared },
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ value }: any) => (
          <div className="flex flex-nowrap items-center font-[600]">
            <Avatar initials={value} src="" size={22} className="mr-1.5" />
            {value}
          </div>
        ),
      },
      {
        width: 120,
        model: 'category',
        title: 'Category',
        formatValue: ({ data, value }: any) => CATEGORY?.[value],
      },
      {
        width: 160,
        model: 'gender',
        title: 'Accepted Gender',
        type: 'title',
      },
      {
        width: 170,
        id: 'beds_count',
        model: 'beds_count',
        title: 'Current Availability',
        formatValue: ({ data }: any) => `${data.beds_count - data.current_residents_count} Available / ${data.beds_count} Beds`,
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'phone_no',
        title: 'Phone',
        type: 'phone',
      },
      {
        width: 160,
        id: 'email',
        model: 'email',
        title: 'Email',
        type: 'email',
      },
      {
        title: 'Address',
        model: 'address',
        type: 'address',
        disableSort: true,
        width: 280,
      },
    ],
    [],
  )

  if (!partnership || isLoading) return <PageLoader />

  const partner = partnership.other_partner

  if (!isShared) {
    return (
      <Page icon="locations" title="Locations">
        <Card>
          <State isEmpty icon="locations" title="Locations" emptyDescription={`${partner?.name || '–'} has not shared any locations`} />
        </Card>
      </Page>
    )
  }

  return (
    <Page title="Locations" icon="locations">
      <DataTable {...tableProps} icon="locations" title="Locations" columns={columns} />
    </Page>
  )
}

export const Locations = withPageError(RootLocations)
