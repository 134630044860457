import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS } from '../../../../theme'

import { ELEMENTS } from '../elements'

export const Box: React.FC<any> = React.forwardRef((props, ref) => {
  const {
    as = 'div',
    element,
    children,
    className,
    style,
    hoverElement,
    href,
    target,
    onClick,
    to,
    showEmptyState,
    emptyDescription,
    isEditable,
  } = props

  if (!element) return null

  const ELEMENT = ELEMENTS[element.category]

  const isEmpty = size(element.elements_order) === 0 && ELEMENT.allowChildren

  const rootClasses = clsx('BOX', showEmptyState && isEmpty && 'is-empty', isEditable && 'is-editable', className)

  return (
    <div ref={ref} as={as} style={style} className={rootClasses} href={href} target={target} onClick={onClick} to={to} css={STYLES.root}>
      {hoverElement}

      {children}

      {isEditable && isEmpty && showEmptyState && ELEMENT && (
        <div css={STYLES.emptyContent}>{emptyDescription || `Empty ${ELEMENT.name}`} </div>
      )}
    </div>
  )
})

const STYLES = {
  root: {
    '&.is-editable.is-empty': {
      minHeight: 100,
      border: `1px dashed ${COLORS.divider}`,
      borderRadius: 4,
      position: 'relative',
    },
  },

  emptyContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    color: COLORS.textMuted,
    opacity: 0.6,
    pointerEvents: 'none !important',
  },
}
