import React from 'react'
import { useMedia } from 'use-media'
import size from 'lodash/size'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import InsuranceERAReportStatus from '../Statuses/InsuranceERAReportStatus'

import { titleCase, niceAmount, countWord } from '../../utils/functions'

import withSettings from '../../hocs/withSettings'

const mainColumn = (to) => ({
  width: 240,
  isSticky: true,
  canToggleVisible: false,
  accessor: 'report_name',
  Header: 'Name',
  Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} avatar={row.original.avatar} />,
})

const columns = (to: Function = () => {}, isDesktop: boolean, timezone: string) => [
  ...(isDesktop ? [mainColumn(to)] : []),
  {
    width: 160,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <InsuranceERAReportStatus status={value} />,
  },
  {
    width: 160,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ value }: any) => titleCase(value) || '–',
  },
  {
    width: 320,
    accessor: 'payer_name',
    Header: 'Payer',
  },
  {
    width: 205,
    accessor: 'production_date',
    Header: 'Production Date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 205,
    accessor: 'effective_date',
    Header: 'Check Issue Date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 160,
    accessor: 'total_payment_amount',
    Header: 'Total Amount',
    Cell: ({ value }: any) => niceAmount(value),
  },
  {
    width: 160,
    accessor: 'checks',
    Header: 'Checks',
    Cell: ({ value }: any) => countWord('check', size(value)),
  },
  {
    width: 160,
    accessor: 'payments',
    Header: 'Payments',
    Cell: ({ value }: any) => countWord('payment', size(value)),
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  timezone: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const InsuranceERAReportsTable = (props: Props) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    batchActionsConfig,
    title,
    to,
    timezone,
    hiddenColumns,
    titleAfter,
    ...rest
  } = props

  const isDesktop = useMedia({ minWidth: 600 })

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, isDesktop, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

InsuranceERAReportsTable.defaultProps = {
  title: 'Insurance ERA Reports',
  icon: 'insurance',
  emptyDescription: 'No reports added yet',
  localStorageKey: 'rcm_insurance_era_reports',
}

export default withSettings(InsuranceERAReportsTable)
