import React from 'react'
import size from 'lodash/size'

import Button from '../../Button'
import DigitsPin from '../../Forms/DigitsPin'
import NoInternet from '../../Alerts/NoInternet'
import Overlay from '../../Overlay'

import withSettings from '../../../hocs/withSettings'
import { apiGet, apiUpdate } from '../../../modules/api'
import { withOverlayError } from '../../../hocs/withOverlayError'

import useStore from '../../../modules/store'

const UpdatePIN = ({ onClose, online, title, saveButton }: any) => {
  const [loading, setLoading] = React.useState(false)
  const [code, setCode] = React.useState(null)

  const update = useStore((state: any) => state.update)

  const close = () => {
    if (onClose) onClose()
  }

  const savePin = async () => {
    setLoading(true)
    try {
      await apiUpdate({ reducer: 'me', url: '/me/pin', params: { secure_pin: code } })
      const me = await apiGet({ url: '/me', reducer: 'me' })
      update({ newRecord: me.data.new_record })

      close()
    } catch (errors) {
      console.warn({ errors })
    } finally {
      setLoading(false)
    }
  }

  const codeValid = size(code) === 4

  return (
    <Overlay onClose={close} position="center" showBackdrop>
      <Overlay.Header glyph="lock" title={title} />

      <Overlay.Content>
        <div className="p-4 h-[150px]">
          <DigitsPin inputStyles={{ width: '6rem', height: '8rem' }} onUpdate={(state: any) => setCode(state.value)} />
        </div>
      </Overlay.Content>

      <Overlay.Footer>
        {online && (
          <Button
            testKey="save_pin_button"
            label={saveButton}
            type="primary"
            color="green"
            isLoading={loading}
            onClick={savePin}
            isDisabled={!codeValid}
          />
        )}
        {!online && <NoInternet />}
      </Overlay.Footer>
    </Overlay>
  )
}

UpdatePIN.defaultProps = {
  title: 'Update your PIN',
  saveButton: 'Update PIN',
}

export default withOverlayError(withSettings(UpdatePIN))
