import React from 'react'

import DropdownItem from '../DropdownItem'
import Divider from '../Divider'
import DeleteDialog from '../Dialogs/DeleteDialog'

const missingPhone = 'Missing phone no'

const Property = ({ data: { name, phone_no }, onDelete }) => (
  <>
    <DropdownItem
      label={phone_no ? `Call ${name}` : missingPhone}
      glyph="phone"
      color="green"
      href={`tel:${phone_no}`}
      isDisabled={!phone_no}
    />

    <Divider />

    <DeleteDialog
      title="Delete Location?"
      message={
        <>
          <p>
            Are you sure you want to delete this Property? This <strong>will also remove</strong> all Floors, Rooms, Beds and Bed Assignment
            under this Property.
          </p>
          <span>This action cannot be undone.</span>
        </>
      }
      messageGlyph="warning"
      messageGlyphColor="red"
      yesLabel="Yes, Delete"
      onYes={onDelete}
    >
      <DropdownItem label="Delete Location…" glyph="delete" color="red" permission="properties.actions.delete" />
    </DeleteDialog>
  </>
)

export default Property
