import React from 'react'
import startCase from 'lodash/startCase'

import { useSettings } from '../../hooks'

import DataFormStatus from '../Statuses/DataFormStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'subcategory',
    Header: 'Name',
    Cell: ({ row, value }: any) => <TableCell.MainLink to={to(row.original.id, row.original.subcategory)} label={startCase(value)} />,
  },
  {
    width: 140,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <DataFormStatus status={value} />,
    Filter: TableFilter.DataFormStatus,
    filter: 'dataFormStatus',
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  isLoading: boolean
  to?: Function
  localStorageKey: string
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const IntakeFormsTable = ({ to, data, isLoading, emptyActions, batchActionsConfig, localStorageKey, titleAfter }: Props) => {
  const { timezone } = useSettings()

  return (
    <Table
      title="Intake Forms"
      titleAfter={titleAfter}
      icon="intake_forms"
      data={data}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No intake forms added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

IntakeFormsTable.defaultProps = {
  localStorageKey: 'intake_forms',
}

export default IntakeFormsTable
