import React from 'react'
import { connect } from 'react-redux'

import Button from '../../Button'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Overlay from '../../Overlay'
import Section from '../../Section'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class FloorOverlay extends OverlayBase {
  renderHeader = () => <Overlay.Header icon="properties" title="Floor" />

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { record, reference, timezone } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          timezone={timezone}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          linked={
            $new && {
              reference_id: reference?.id,
              reference_type: reference?.type,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              <Input
                model="name"
                label="Name"
                validations={{
                  presence: {
                    message: 'Please enter a floor name',
                  },
                }}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button glyph="edit" label="Edit" type="default" isDisabled={this.props.loading} onClick={this.edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Floor?"
              message="Are you sure you want to delete this floor? This action cannot be undone."
              onYes={this.delete}
            >
              <Button fullWidth glyph="delete" label="Delete" type="default" color="red" isLoading={this.props.loading} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'floors')
const mapStateToProps = (state, props) => ({
  ...defaultMapStateToProps(state, props.match, 'floors'),
  reference: state?.data?.locations?.data?.[props.match?.params?.resource_id],
})

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(FloorOverlay))
