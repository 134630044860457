import React from 'react'

import { H3 } from '../../Typography'
import Alert from '../../Alert'
import ContextShow from '../ContextShow'
import DateInput from '../DateInput'
import DateOfBirthInput from '../DateOfBirthInput'
import EmailInput from '../EmailInput'
import Input from '../Input'
import PhoneInput from '../PhoneInput'

import AddressInputs from './AddressInputs'
import YesNoRadioGroup from './YesNoRadioGroup'
import RelationshipToClientCodeSelector from './RelationshipToClientCodeSelector'
import SexSelect from './SexSelect'

const InsurancePolicyInputs = ({ model }) => (
  <>
    <YesNoRadioGroup
      label="Are you the policy holder?"
      model={`${model}.is_client_policy_holder`}
      validations={{
        presence: {
          message: 'Please select an answer',
        },
      }}
    />

    <Input
      label="Insurance Company Name"
      model={`${model}.company_name`}
      validations={{
        presence: {
          message: "Please enter the insurance company's name",
        },
      }}
    />

    <Alert glyph="info" css={{ marginTop: '-0.5rem' }}>
      Please enter the <b>exact company name</b> from your insurance card.
    </Alert>

    <Input
      label="Plan Name"
      model={`${model}.plan_name`}
      validations={{
        presence: {
          message: "Please enter the plan's name",
        },
      }}
    />

    <Input
      label="Insurance Group ID"
      model={`${model}.group_id`}
      validations={{
        presence: {
          message: 'Please enter a group ID',
        },
      }}
    />

    <Input
      label="Insurance Member ID"
      model={`${model}.member_id`}
      validations={{
        presence: {
          message: 'Please enter a member ID',
        },
      }}
    />

    <DateInput label="Card Issued Date" model={`${model}.card_issued_date`} />

    <PhoneInput label="Claims Phone Number (Insurance Line)" model={`${model}.claims_phone_no`} />

    <ContextShow when={`${model}.is_client_policy_holder`} is={false}>
      <H3 className="!mt-2">Policy Holder Details</H3>
      <Input
        label="First Name"
        model={`${model}.policy_holder.first_name`}
        validations={{
          presence: {
            message: 'Please enter a first name',
          },
        }}
      />

      <Input label="Middle Name" model={`${model}.policy_holder.middle_name`} />

      <Input
        label="Last Name"
        model={`${model}.policy_holder.last_name`}
        validations={{
          presence: {
            message: 'Please enter a last name',
          },
        }}
      />

      <DateOfBirthInput label="Date of Birth" model={`${model}.policy_holder.dob`} />

      <SexSelect label="Sex" model={`${model}.policy_holder.sex`} />

      <RelationshipToClientCodeSelector label="Relationship" model={`${model}.policy_holder.relationship_to_customer`} />

      <EmailInput label="Email" model={`${model}.policy_holder.email`} />
      <PhoneInput label="Phone Number" model={`${model}.policy_holder.phone_no`} />

      <AddressInputs isRequired model={`${model}.policy_holder.address`} />
    </ContextShow>
  </>
)

InsurancePolicyInputs.defaultProps = {
  model: 'insurance_policy',
}

export default InsurancePolicyInputs
