import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, HelpTagIframe, Link, Page, Grid } from '@behavehealth/components'
import { ClientContactsTable } from '@behavehealth/components/Tables'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

type Props = {
  canCreate: boolean
}

const pageConfig = {
  feature: 'contacts',
  title: 'Contacts',
}

const RootContacts: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()
  const [filters, setFilters] = React.useState({})

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'contacts', { filters }],
    url: `/residents/${resource_id}/contacts`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const { mutate: deleteContacts } = useDelete({
    name: ['client', resource_id, 'contacts'],
    url: '/contacts',
    invalidate: 'contacts',
  })

  const isEmpty = size(data) === 0

  return (
    <Page
      feature="contacts"
      plan="lite"
      help={<HelpTagIframe id="client_contacts_and_communications" />}
      actions={
        <>{canCreate && <Button label="Add Contact" glyph="add" type="primary" link={`${match.url}/new`} permission="contacts.create" />}</>
      }
    >
      <Grid gap="1rem" columns="100%">
        <Filters config={FILTERS.client_contacts} onUpdate={setFilters} localStorageKey="client_contacts" />

        <Card>
          <ClientContactsTable
            data={data}
            isLoading={isEmpty && isLoading}
            localStorageKey="clients_contacts"
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            titleAfter={<Link to="/reports/clients-contacts">View Full Report →</Link>}
            emptyActions={
              canCreate && <Button label="Add Contact" glyph="add" type="primary" link={`${match.url}/new`} permission="contacts.create" />
            }
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'contacts.delete',
                action: async ({ ids }: any) => {
                  await deleteContacts(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(RootContacts, pageConfig))
