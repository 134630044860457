import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'

import { RecordTooltip } from '../components/RecordTooltip'
import { CommunicationInfo } from '../../StaffJourney/records/CommunicationInfo'

export const Communication = ({ data, client }: any) => {
  if (!data) return null

  return (
    <RecordTooltip
      title={data.contact?.name || 'Communication Log'}
      icon="communications"
      color={tint(0.7, COLORS.orange)}
      startDate={data.contacted_at}
      recordLink={`/communications/${data.id}`}
      client={client}
    >
      <CommunicationInfo data={data} />
    </RecordTooltip>
  )
}
