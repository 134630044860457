import React from 'react'

import { COLORS } from '../../../theme'
import Glyph from '../../../components/Glyph'

export const Filter: React.FC<any> = (props) => {
  const { onClear, children } = props

  return (
    <div css={STYLES.filter}>
      {children}

      {onClear && (
        <button type="button" onClick={onClear} css={STYLES.clearButton}>
          <Glyph glyph="close" color={COLORS.text} size={10} />
        </button>
      )}
    </div>
  )
}

const STYLES = {
  filter: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    border: `1px solid ${COLORS.divider}`,
    background: COLORS.white,
    borderRadius: 5,
    fontSize: '0.85rem',
  },

  clearButton: {
    background: 'none',
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'var(--filter-height)',
    height: 'var(--filter-height)',
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.hover,
      svg: { fill: COLORS.red },
    },
  },
}
