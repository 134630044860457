import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import startCase from 'lodash/startCase'

import { RELATIONSHIP_TO_CLIENT_CODE } from '../../components/RCM/constants'
import EVOBCoverageStatus from '../../components/Statuses/EVOBCoverageStatus'
import EVOBStatus from '../../components/Statuses/EVOBStatus'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { FILTERS } from '../Filters/config'

export const InsurancePoliciesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'insurance_local_payer',
        model: 'data.insurance_local_payer.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.insurance_local_payer?.name} to={to?.(data.data)} />,
      },
      {
        width: 180,
        id: 'resident',
        model: 'data.resident.name',
        title: 'Client',
      },
      {
        width: 150,
        title: 'eVOB Status',
        id: 'evob_status',
        model: 'data.evob_status',
        formatValue: ({ data, value }: any) => (value ? <EVOBStatus status={value} /> : '-'),
        // { value: 'processing', label: 'Processing' },
        // { value: 'issue', label: 'Issue' },
        // { value: 'processed', label: 'Processed' },
      },
      {
        width: 200,
        title: 'eVOB Coverage Status',
        id: 'coverage_status',
        model: 'data.coverage_status',
        formatValue: ({ data, value }: any) => (value ? <EVOBCoverageStatus status={value} /> : '-'),
        // filterOptions: [
        //   {
        //     value: 'Active',
        //     label: 'Active',
        //   },
        //   {
        //     value: 'Inactive',
        //     label: 'Inactive',
        //   },
        // ],
      },
      {
        width: 110,
        title: 'Type',
        id: 'category',
        model: 'data.category',
        formatValue: ({ data, value }: any) => startCase(value),
      },
      {
        width: 160,
        id: 'plan',
        model: 'data.plan',
        title: 'Plan Name',
      },
      {
        width: 160,
        id: 'member_id',
        model: 'data.member_id',
        title: 'Member ID',
      },
      {
        width: 160,
        id: 'group_id',
        model: 'data.group_id',
        title: 'Group ID',
      },
      {
        width: 140,
        id: 'is_client_policy_holder',
        model: 'data.is_client_policy_holder',
        title: 'Policy Holder',
        formatValue: ({ data, value }: any) => {
          if (value) {
            return data.data.policy_holder_name
          } else {
            return `${data.data.policy_holder_name} (${data.data.relationship_to_customer})`
          }
        },
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Insurance Policies"
      icon="insurance"
      columns={columns}
      filtersConfig={FILTERS.live_reports.insurance_policies}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="insurance_policies"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=insurance_policies'}
        />
      }
    />
  )
}
