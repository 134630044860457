import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'

import { COLORS } from '../../../theme'
import Glyph from '../../../components/Glyph'

export const FilterDropdownItem: React.FC<any> = (props) => {
  const { description, graphic, isActive, onClick, showSeparator, title } = props

  const rootClasses = clsx(isActive && 'is-active', !!graphic && 'has-graphic', showSeparator && 'show-separator')
  const activeGlyphClasses = clsx(isActive && 'is-visible')

  return (
    <div css={STYLES.menuItem} className={rootClasses} onClick={onClick}>
      <div css={STYLES.activeGlyph} className={activeGlyphClasses}>
        <Glyph glyph="check" size={10} />
      </div>

      <div css={STYLES.menuItemMain}>
        {graphic && <div css={STYLES.graphic}>{graphic}</div>}

        <div>
          <div css={STYLES.title}>{title}</div>
          {description && <div css={STYLES.description}>{description}</div>}
        </div>
      </div>
    </div>
  )
}

const STYLES = {
  menuItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    fontSize: '0.88rem',
    width: '100%',
    lineHeight: 'normal',
    paddingTop: '0.3rem',
    paddingBottom: '0.3rem',
    paddingRight: '1rem',

    '&.show-separator': {
      borderBottom: `1px solid ${COLORS.divider}`,
    },

    '&.is-active': {
      backgroundColor: tint(0.92, COLORS.green),

      '&:hover': {
        backgroundColor: tint(0.92, COLORS.green),
      },
    },

    '&:hover': {
      fontWeight: 600,
      background: COLORS.hover,
    },

    '&:last-child': {
      borderBottom: 'none',
    },
  },

  menuItemMain: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flex: '1 1 auto',

    '.has-graphic > &': {
      paddingLeft: 0,
    },
  },

  activeGlyph: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '0.35rem',
    paddingRight: '0.25rem',
    visibility: 'hidden',

    '&.is-visible': {
      visibility: 'visible',
    },
  },

  graphic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '0.2rem',
    marginRight: '0.5rem',
    // width: '2.5rem',
  },

  title: {
    fontWeight: 600,
  },

  description: {
    fontWeight: 400,
    color: COLORS.textMuted,
    fontSize: '0.95em',
    marginTop: '0.1em',
  },
}
