import React from 'react'

import { useGet } from '../../../hooks/useNewAPI'

import Card from '../../../components/Card'
import ClaimNotesTable from './ClaimNotesTable'

export const ClaimNotes = ({ claimID, to, onClick, titleAfter }: any) => {
  const { data: notes, isLoading: isLoadingNotes }: any = useGet({
    name: ['insurance-claims', claimID, 'insurance-claim-notes'],
    url: `/insurance_claims/${claimID}/insurance_claim_notes`,
    options: { enabled: !!claimID },
  })

  if (!claimID) return null

  return (
    <Card>
      <ClaimNotesTable data={notes} isLoading={isLoadingNotes} to={to} onClick={onClick} titleAfter={titleAfter} />
    </Card>
  )
}
