import React from 'react'
import { useMedia } from 'use-media'

import { BREAKPOINT, TOKENS } from '../theme'
import NavGroup from './NavGroup'

type Props = {
  title: string
}

const AppNavGroup: React.FC<any> = ({ title, children, labelAfter }: any) => {
  const isCompact = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavCompactBreakpoint] })
  const isExpanded = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavExpandBreakpoint] })
  const show = isExpanded || !isCompact

  return <NavGroup label={show && title} children={children} labelAfter={labelAfter} />
}

export default AppNavGroup
