import React from 'react'
import { useLocation } from 'react-router-dom'

import Alert from '../../components/Alert'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import TreeItem from '../../components/TreeItem'

import Score from './common/Score'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const MALE_SCORING = {
  3: 'negative',
  12: 'positive (in men, a score of 4 or more is considered positive, optimal for identifying hazardous drinking or active alcohol use disorders.)',
}

const FEMALE_SCORING = {
  2: 'negative',
  12: 'positive (in women, a score of 3 or more is considered positive, optimal for identifying hazardous drinking or active alcohol use disorders.)',
}

const MALE_PSYCHOMETRIC_SCORING_A = {
  3: 'Sens: 0.95 / Spec. 0.60',
  12: 'Sens: 0.86 / Spec. 0.72',
}

const FEMALE_PSYCHOMETRIC_SCORING_A = {
  3: 'Sens: 0.66 / Spec. 0.94',
  12: 'Sens: 0.48 / Spec. 0.99',
}

const MALE_PSYCHOMETRIC_SCORING_B = {
  3: 'Sens: 0.90 / Spec. 0.45',
  12: 'Sens: 0.79 / Spec. 0.56',
}

const FEMALE_PSYCHOMETRIC_SCORING_B = {
  3: 'Sens: 0.80 / Spec. 0.87',
  12: 'Sens: 0.67 / Spec. 0.94',
}

const INITIAL_STATE = {
  drinking_frequency: 0,
  drinks_per_day: 0,
  six_drinks_frequency: 0,
}

const RootAUDITCOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="AUDIT-C"
      category="clinical_measurement"
      subcategory="audit_c"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Overview"
        headingType="h2"
        scrollview={{
          id: 'overview',
          name: 'Overview',
        }}
      >
        <p>
          The AUDIT-C is a 3-item alcohol screen that can help identify persons who are hazardous drinkers or have active alcohol use
          disorders (including alcohol abuse or dependence). The AUDIT-C is a modified version of the 10 question AUDIT instrument.
        </p>

        <p>
          <b>Clinical Utility:</b> The AUDIT-C is a brief alcohol screen that reliably identifies patients who are hazardous drinkers or
          have active alcohol use disorders.
        </p>
      </Section>

      <Divider />

      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <Questionnaire />
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <ol>
          <li>
            Bush K, Kivlahan DR, McDonell MB, et al. The AUDIT Alcohol Consumption Questions (AUDIT-C): An effective brief screening test
            for problem drinking. Arch Internal Med. 1998 (3): 1789-1795.
          </li>

          <li>
            Bradley KA, Bush KR, Epler AJ, et al. Two brief alcohol-screening tests from the Alcohol Use Disorders Identification Test
            (AUDIT): Validation in a female veterans affairs patient population. Arch Internal Med Vol 163, April 2003: 821-829.
          </li>

          <li>
            Frequently Asked Questions guide to using the AUDIT-C can be found via the website:{' '}
            <a target="_blank" href="www.oqp.med.va.gov/general/uploads/FAQ%20AUDIT-C">
              www.oqp.med.va.gov/general/uploads/FAQ%20AUDIT-C
            </a>
          </li>
        </ol>

        <p>
          <b>Copyright:</b> AUDIT-C is available for use in the public domain.
        </p>
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <RadioGroup
          label="How often do you have a drink containing alcohol?"
          model="data.questionnaire.drinking_frequency"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, drinking_frequency: state.value }))}
        >
          <Radio label="a. Never" value={0} />
          <Radio label="b. Monthly or less" value={1} />
          <Radio label="c. 2-4 times a month" value={2} />
          <Radio label="d. 2-3 times a week" value={3} />
          <Radio label="e. 4 or more times a week" value={4} />
        </RadioGroup>

        <RadioGroup
          label="How many standard drinks containing alcohol do you have on a typical day?"
          model="data.questionnaire.drinks_per_day"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, drinks_per_day: state.value }))}
        >
          <Radio label="a. 1 or 2" value={0} />
          <Radio label="b. 3 or 4" value={1} />
          <Radio label="c. 5 or 6" value={2} />
          <Radio label="d. 7 to 9" value={3} />
          <Radio label="e. 10 or more" value={4} />
        </RadioGroup>

        <RadioGroup
          label="How often do you have six or more drinks on one occasion?"
          model="data.questionnaire.six_drinks_frequency"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, six_drinks_frequency: state.value }))}
        >
          <Radio label="a. Never" value={0} />
          <Radio label="b. Less than monthly" value={1} />
          <Radio label="c. Monthly" value={2} />
          <Radio label="d. Weekly" value={3} />
          <Radio label="e. Daily or almost daily" value={4} />
        </RadioGroup>
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Grid gap="1rem">
            <Alert type="warning" glyph="warning">
              <p css={{ marginTop: 0 }}>
                <b>Please note:</b> when the points are all from Question #1 alone (#2 & #3 are zero), it can be assumed that the patient is
                drinking below recommended limits and it is suggested that the provider review the patient’s alcohol intake over the past
                few months to confirm accuracy.
              </p>

              <p className="!mb-0">
                Generally, the higher the score, the more likely it is that the patient’s drinking is affecting his or her safety.
              </p>
            </Alert>

            <Score title="Final Score (Men)" finalScore={finalScore} scoring={MALE_SCORING} />
            <Score title="Final Score (Women)" finalScore={finalScore} scoring={FEMALE_SCORING} />

            <TreeItem title="Psychometric Properties">
              <p>For identifying patients with heavy/hazardous drinking and/or Active-DSM alcohol abuse or dependence</p>

              <Grid gap="1rem">
                <Score
                  title="Psychometric Properties (Men)"
                  description="For identifying patients with heavy/hazardous drinking and/or Active-DSM alcohol abuse or dependence"
                  finalScore={finalScore}
                  scoring={MALE_PSYCHOMETRIC_SCORING_A}
                />
                <Score
                  title="Psychometric Properties (Women)"
                  description="For identifying patients with heavy/hazardous drinking and/or Active-DSM alcohol abuse or dependence"
                  finalScore={finalScore}
                  scoring={FEMALE_PSYCHOMETRIC_SCORING_A}
                />
                <Score
                  title="Psychometric Properties (Men)"
                  description="For identifying patients with active alcohol abuse or dependence"
                  finalScore={finalScore}
                  scoring={MALE_PSYCHOMETRIC_SCORING_B}
                />
                <Score
                  title="Psychometric Properties (Women)"
                  description="For identifying patients with active alcohol abuse or dependence"
                  finalScore={finalScore}
                  scoring={FEMALE_PSYCHOMETRIC_SCORING_B}
                />
              </Grid>
            </TreeItem>
          </Grid>
        </>
      )}
    </>
  )
}

export const AUDITCOverlay = withOverlayError(RootAUDITCOverlay)
