import React from 'react'
import size from 'lodash/size'
import isPlainObject from 'lodash/isPlainObject'
import startCase from 'lodash/startCase'

import { titleCase, usDateTime } from '../../../../utils/functions'
import withSettings from '../../../../hocs/withSettings'

import CardTreeItem from '../../../CardTreeItem'
import DataList from '../../../DataList'
import EVOBStatus from '../../../Statuses/EVOBStatus'
import Grid from '../../../Grid'
import Section from '../../../Section'
import Status from '../../../Status'
import TreeItem from '../../../TreeItem'

import { CLEARING_HOUSES, PVERIFY_PRACTICE_TYPE_CODES } from '../../../../utils/constants'

const isArray = (o) => Array.isArray(o)

const ServicesTree = ({ services }: any) => {
  if (!services || size(services) === 0) return null

  return (
    <DataList className="text-[0.95rem]">
      {services.map(({ service_name, eligibility_details }: any, serviceIndex: number) => (
        <TreeItem shouldUnmount title={service_name} key={`service-${serviceIndex}`}>
          {size(eligibility_details) === 1 ? (
            <EligibilityDetailsList data={eligibility_details} />
          ) : (
            eligibility_details.map((section: any, serviceIndex: number) => (
              <TreeItem shouldUnmount title={`${service_name} Details #${serviceIndex + 1}`} key={`section-${serviceIndex}`}>
                <EligibilityDetailsList data={section} />
              </TreeItem>
            ))
          )}
        </TreeItem>
      ))}
    </DataList>
  )
}

const EligibilityDetailsList = ({ data }: any) => {
  if (!data || size(data) === 0) return null

  return (
    <DataList className="text-[0.95rem]">
      {Object.entries(data).map(([key, value], index) => {
        if (value === null) {
          return <DataList.Item key={index} label={titleCase(key)} value={value} />
        }

        if (typeof value === 'string') {
          return <DataList.Item key={index} label={titleCase(key)} value={value} />
        }

        if (isArray(value) && size(value) >= 1 && isPlainObject(value[0])) {
          return value?.map?.((item, itemIndex) => (
            <TreeItem shouldUnmount title={titleCase(key)} key={`item-${itemIndex}`}>
              <DataList>
                {Object.entries?.(item).map(([subItemKey, subItemValue], subItemIndex) => (
                  <DataList.Item key={`sub-item-${subItemIndex}`} label={titleCase(subItemKey)} value={JSON.stringify(subItemValue)} />
                ))}
              </DataList>
            </TreeItem>
          ))
        }

        if (isArray(value) && size(value) >= 1) {
          return <DataList.Item key={index} label={titleCase(key)} value={JSON.stringify(value?.join(' '), null, 2)} />
        }

        if (isPlainObject(value)) {
          return <EligibilityDetailsList key={`list-${index}`} data={value} />
        }

        return null
      })}
    </DataList>
  )
}

const PVerify = ({ data, timezone }: any) => {
  if (!data) return null

  const summary = data?.insurance_eligibility_summary

  return (
    <>
      <Section>
        <DataList labelWidth={180} className="text-[0.95rem]">
          <DataList.Item label="Date Requested:" value={usDateTime(data.created_at, timezone)} />
          <DataList.Item label="Clearing House:" value={CLEARING_HOUSES[data.clearing_house]} />
          <DataList.Item label="eVOB Status:" value={<EVOBStatus status={data.status} />} />
          <DataList.Item label="Practice Type:" value={PVERIFY_PRACTICE_TYPE_CODES?.[data?.practice_type_code]?.label || '–'} />
          <DataList.Item label="Provider NPI:" value={data.credential?.license_number} />
          <DataList.Item label="Provider Type:" value={startCase(data.credential?.level)} />
          {data.credential?.level === 'organization' && (
            <DataList.Item label="Provider Organization Name:" value={data.credential?.organization} />
          )}
          {data.credential?.level === 'individual' && (
            <>
              <DataList.Item label="Provider First Name:" value={data.credential?.first_name} />
              <DataList.Item label="Provider Last Name:" value={data.credential?.last_name} />
            </>
          )}
          <DataList.Item label="Error Message:" value={data.error_message} />
        </DataList>
      </Section>

      <Section title="General Info">
        <DataList className="text-[0.95rem]">
          <DataList.Item label="Payer Name:" value={summary?.payer_name} />
          {/* <DataList.Item label="In/Out Network:" value={<Status label="In Network" color="green" />} /> */}
          <DataList.Item label="Verification Type:" value={summary?.verification_type} />
          <DataList.Item label="HMO Plan:" value={summary?.hmo_plan} />
          <DataList.Item label="DOS:" value={summary?.dos} />
          <DataList.Item label="Location:" value={summary?.location} />
          <DataList.Item label="Result Practice Type:" value={summary?.result_practice_type} />
          <DataList.Item label="Additional Info:" value={summary?.addtional_info} />
        </DataList>
      </Section>

      <Section title="Details">
        <Grid gap="0.5rem">
          <CardTreeItem shouldUnmount isOpen title="Plan Coverage Summary">
            <DataList labelWidth={'200px'}>
              <DataList.Item label="Status" value={summary?.plan_coverage_summary?.status} />
              <DataList.Item label="Effective Date" value={summary?.plan_coverage_summary?.effective_date} />
              <DataList.Item label="Expiry Date" value={summary?.plan_coverage_summary?.expiry_date} />
              <DataList.Item label="Plan Name" value={summary?.plan_coverage_summary?.plan_name} />
              <DataList.Item label="Policy Type" value={summary?.plan_coverage_summary?.policy_type} />
              <DataList.Item label="Group Number" value={summary?.plan_coverage_summary?.group_number} />
              <DataList.Item label="Group Name" value={summary?.plan_coverage_summary?.group_name} />
              <DataList.Item label="Plan Network ID" value={summary?.plan_coverage_summary?.plan_network_id} />
              <DataList.Item label="Plan Network Name" value={summary?.plan_coverage_summary?.plan_network_name} />
              <DataList.Item label="Subscriber Relationship" value={summary?.plan_coverage_summary?.subscriber_relationship} />
              <DataList.Item label="Plan Number" value={summary?.plan_coverage_summary?.plan_number} />
              <DataList.Item
                label="HRA or HSA Limitations Remaining"
                value={summary?.plan_coverage_summary?.hra_or_hsa_limitations_remaining}
              />
              <DataList.Item label="Last Updated Date Of EDI" value={summary?.plan_coverage_summary?.last_updated_date_of_edi} />
            </DataList>
          </CardTreeItem>

          <CardTreeItem shouldUnmount title="Eligibility Result">
            <pre>{summary?.eligibility_result}</pre>
          </CardTreeItem>

          <CardTreeItem shouldUnmount title="Subscriber Details">
            <DataList className="text-[0.95rem]">
              <DataList.Item label="Subscriber Name" value={summary?.subscriber_details?.subscriber_name} />
              <DataList.Item label="Address Line 1" value={summary?.subscriber_details?.address_line_1} />
              <DataList.Item label="Address Line 2" value={summary?.subscriber_details?.address_line_2} />
              <DataList.Item label="City" value={summary?.subscriber_details?.city} />
              <DataList.Item label="State" value={summary?.subscriber_details?.state} />
              <DataList.Item label="Zip" value={summary?.subscriber_details?.zip} />
              <DataList.Item label="Communication Number" value={summary?.subscriber_details?.communication_number} />
              <DataList.Item label="Sex" value={summary?.subscriber_details?.gender} />
              <DataList.Item label="Date of Birth" value={summary?.subscriber_details?.date_of_birth} />

              {summary?.subscriber_details?.identification &&
                summary?.subscriber_details?.identification.map((identification: any) => (
                  <DataList.Item key={identification.type} label={identification.type} value={identification.date} />
                ))}

              {summary?.subscriber_details?.dates &&
                summary?.subscriber_details?.dates?.map((date: any) => (
                  <DataList.Item key={date.type} label={`${date.type} Date`} value={date.date} />
                ))}
            </DataList>
          </CardTreeItem>

          <CardTreeItem shouldUnmount title="Dependent Details">
            <DataList className="text-[0.95rem]">
              <DataList.Item label="Dependent Name" value={summary?.dependent_details?.full_name} />
              <DataList.Item label="Address Line 1" value={summary?.dependent_details?.address_line_1} />
              <DataList.Item label="Address Line 2" value={summary?.dependent_details?.address_line_2} />
              <DataList.Item label="City" value={summary?.dependent_details?.city} />
              <DataList.Item label="State" value={summary?.dependent_details?.state} />
              <DataList.Item label="Zip" value={summary?.dependent_details?.zip} />

              <DataList.Item label="Communication Number" value={summary?.dependent_details?.communication_number} />
              <DataList.Item label="Sex" value={summary?.dependent_details?.gender} />
              <DataList.Item label="Date of Birth" value={summary?.dependent_details?.date_of_birth} />

              {summary?.dependent_details?.identification &&
                summary?.dependent_details?.identification.map((identification: any) => (
                  <DataList.Item key={identification.type} label={identification.type} value={identification.date} />
                ))}

              {summary?.dependent_details?.dates &&
                summary?.dependent_details?.dates.map((date: any) => (
                  <DataList.Item key={date.type} label={`${date.type} Date`} value={date.date} />
                ))}
            </DataList>
          </CardTreeItem>

          <CardTreeItem shouldUnmount title="PCP Auth Info Summary">
            <DataList className="text-[0.95rem]">
              <DataList.Item label="Primary Care Provider Name" value={summary?.pcp_auth_info_summary?.primary_care_provider_name} />
              <DataList.Item
                label="Primary Care Provider Phone Number"
                value={summary?.pcp_auth_info_summary?.primary_care_provider_phone_number}
              />
              <DataList.Item
                label="In Net HBPC Authorization Info"
                value={summary?.pcp_auth_info_summary?.in_net_hbpc_authorization_info}
              />
              <DataList.Item
                label="Out Net HBPC Authorization Info"
                value={summary?.pcp_auth_info_summary?.out_net_hbpc_authorization_nfo}
              />
              <DataList.Item
                label="Utilization Management Organization Name"
                value={summary?.pcp_auth_info_summary?.utilization_management_organization_name}
              />
              <DataList.Item label="UMO Telephone" value={summary?.pcp_auth_info_summary?.umo_telephone} />
              <DataList.Item label="Capitation Facility Name" value={summary?.pcp_auth_info_summary?.capitation_facility_name} />
            </DataList>
          </CardTreeItem>

          <CardTreeItem shouldUnmount title="Substance Abuse Professional Summary – In Network">
            <DataList labelWidth={'240px'}>
              <DataList.Item label="UMO Name:" value={summary?.substance_abuse_professional_summary_in_network?.umo_name} />
              <DataList.Item label="UMO Telephone:" value={summary?.substance_abuse_professional_summary_in_network?.umo_telephone} />
              <DataList.Item
                label="Service Authorization Info:"
                value={summary?.substance_abuse_professional_summary_in_network?.service_authorization_info}
              />
            </DataList>

            <DataList className="mt-6">
              <Status label="Co Pay / Insurance" color="blue" />
              <DataList.Item label="Co Pay:" value={summary?.substance_abuse_professional_summary_in_network?.co_pay} />
              <DataList.Item label="Co Insurance:" value={summary?.substance_abuse_professional_summary_in_network?.co_insurance} />
            </DataList>

            <Grid gap="1.5rem" columns="repeat(auto-fit, minmax(220px, 1fr))" className="!mt-4">
              <DataList className="text-[0.95rem]">
                <Status label="Individual Deductible" color="blue" />
                <DataList.Item
                  label="Amount:"
                  value={summary?.substance_abuse_professional_summary_in_network?.individual_deductible?.amount}
                />
                <DataList.Item
                  label="Remaining:"
                  value={summary?.substance_abuse_professional_summary_in_network?.individual_deductible?.remaining}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family Deductible" color="blue" />
                <DataList.Item label="Amount:" value={summary?.substance_abuse_professional_summary_in_network?.family_deductible.amount} />
                <DataList.Item
                  label="Remaining:"
                  value={summary?.substance_abuse_professional_summary_in_network?.family_deductible?.remaining}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Individual OOP" color="blue" />
                <DataList.Item label="Amount:" value={summary?.substance_abuse_professional_summary_in_network?.individual_oop?.amount} />
                <DataList.Item
                  label="Remaining:"
                  value={summary?.substance_abuse_professional_summary_in_network?.individual_oop?.remaining}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family OOP" color="blue" />
                <DataList.Item label="Amount:" value={summary?.substance_abuse_professional_summary_in_network?.family_oop?.amount} />
                <DataList.Item label="Remaining:" value={summary?.substance_abuse_professional_summary_in_network?.family_oop?.remaining} />
              </DataList>
            </Grid>
          </CardTreeItem>

          <CardTreeItem shouldUnmount title="Substance Abuse Professional Summary – Out of Network">
            <DataList labelWidth={'240px'}>
              <DataList.Item label="UMO Name:" value={summary?.substance_abuse_professional_summary_in_network?.umo_name} />
              <DataList.Item label="UMO Telephone:" value={summary?.substance_abuse_professional_summary_in_network?.umo_telephone} />
              <DataList.Item
                label="Service Authorization Info:"
                value={summary?.substance_abuse_professional_summary_in_network?.service_authorization_info}
              />
            </DataList>

            <DataList className="mt-6">
              <Status label="Co Pay / Insurance" color="blue" />
              <DataList.Item label="Co Pay:" value={summary?.substance_abuse_professional_summary_in_network?.co_pay} />
              <DataList.Item label="Co Insurance:" value={summary?.substance_abuse_professional_summary_in_network?.co_insurance} />
            </DataList>

            <Grid gap="1.5rem" columns="repeat(auto-fit, minmax(220px, 1fr))" className="!mt-4">
              <DataList className="text-[0.95rem]">
                <Status label="Individual Deductible" color="blue" />
                <DataList.Item
                  label="Amount:"
                  value={summary?.substance_abuse_professional_summary_in_network?.individual_deductible?.amount}
                />
                <DataList.Item
                  label="Remaining:"
                  value={summary?.substance_abuse_professional_summary_in_network?.individual_deductible?.remaining}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family Deductible" color="blue" />
                <DataList.Item label="Amount:" value={summary?.substance_abuse_professional_summary_in_network?.family_deductible.amount} />
                <DataList.Item
                  label="Remaining:"
                  value={summary?.substance_abuse_professional_summary_in_network?.family_deductible?.remaining}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Individual OOP" color="blue" />
                <DataList.Item label="Amount:" value={summary?.substance_abuse_professional_summary_in_network?.individual_oop?.amount} />
                <DataList.Item
                  label="Remaining:"
                  value={summary?.substance_abuse_professional_summary_in_network?.individual_oop?.remaining}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family OOP" color="blue" />
                <DataList.Item label="Amount:" value={summary?.substance_abuse_professional_summary_in_network?.family_oop?.amount} />
                <DataList.Item label="Remaining:" value={summary?.substance_abuse_professional_summary_in_network?.family_oop?.remaining} />
              </DataList>
            </Grid>
          </CardTreeItem>

          <CardTreeItem shouldUnmount title="HBPC Deductible OOP Summary – In Network">
            <Grid gap="1.5rem" columns="repeat(auto-fit, minmax(220px, 1fr))" className="!mt-4">
              <DataList className="text-[0.95rem]">
                <Status label="Individual Deductible" color="blue" />
                <DataList.Item label="Amount" value={summary?.hbpc_deductible_oop_summary_in_network?.individual_deductible?.amount} />
                <DataList.Item
                  label="Remaining"
                  value={summary?.hbpc_deductible_oop_summary_in_network?.individual_deductible?.remaining}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family Deductible" color="blue" />
                <DataList.Item label="Amount" value={summary?.hbpc_deductible_oop_summary_in_network?.family_deductible.amount} />
                <DataList.Item label="Remaining" value={summary?.hbpc_deductible_oop_summary_in_network?.family_deductible?.remaining} />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Individual OOP" color="blue" />
                <DataList.Item label="Amount" value={summary?.hbpc_deductible_oop_summary_in_network?.individual_oop?.amount} />
                <DataList.Item label="Remaining" value={summary?.hbpc_deductible_oop_summary_in_network?.individual_oop?.remaining} />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family OOP" color="blue" />
                <DataList.Item label="Amount" value={summary?.hbpc_deductible_oop_summary_in_network?.family_oop?.amount} />
                <DataList.Item label="Remaining" value={summary?.hbpc_deductible_oop_summary_in_network?.family_oop?.remaining} />
              </DataList>
            </Grid>
          </CardTreeItem>

          <CardTreeItem shouldUnmount title="HBPC Deductible OOP Summary – Out of Network">
            <Grid gap="1.5rem" columns="repeat(auto-fit, minmax(220px, 1fr))" className="!mt-4">
              <DataList className="text-[0.95rem]">
                <Status label="Individual Deductible" color="blue" />
                <DataList.Item label="Amount" value={summary?.hbpc_deductible_oop_summary_out_network?.individual_deductible?.amount} />
                <DataList.Item
                  label="Remaining"
                  value={summary?.hbpc_deductible_oop_summary_out_network?.individual_deductible?.remaining}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family Deductible" color="blue" />
                <DataList.Item label="Amount" value={summary?.hbpc_deductible_oop_summary_out_network?.family_deductible.amount} />
                <DataList.Item label="Remaining" value={summary?.hbpc_deductible_oop_summary_out_network?.family_deductible?.remaining} />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Individual OOP" color="blue" />
                <DataList.Item label="Amount" value={summary?.hbpc_deductible_oop_summary_out_network?.individual_oop?.amount} />
                <DataList.Item label="Remaining" value={summary?.hbpc_deductible_oop_summary_out_network?.individual_oop?.remaining} />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family OOP" color="blue" />
                <DataList.Item label="Amount" value={summary?.hbpc_deductible_oop_summary_out_network?.family_oop?.amount} />
                <DataList.Item label="Remaining" value={summary?.hbpc_deductible_oop_summary_out_network?.family_oop?.remaining} />
              </DataList>
            </Grid>
          </CardTreeItem>

          <CardTreeItem shouldUnmount title="Mental Health Summary – In Network">
            <DataList labelWidth={'240px'}>
              <DataList.Item label="UMO Name:" value={JSON.stringify(summary?.mental_health_summary_in_network?.umo_name)} />
              <DataList.Item label="UMO Telephone:" value={JSON.stringify(summary?.mental_health_summary_in_network?.umo_telephone)} />
              <DataList.Item
                label="Service Authorization Info:"
                value={JSON.stringify(summary?.mental_health_summary_in_network?.service_authorization_info)}
              />
            </DataList>

            <DataList className="mt-6">
              <Status label="Co Pay / Insurance" color="blue" />
              <DataList.Item label="Co Pay:" value={JSON.stringify(summary?.mental_health_summary_in_network?.co_pay)} />
              <DataList.Item label="Co Insurance:" value={JSON.stringify(summary?.mental_health_summary_in_network?.co_insurance)} />
            </DataList>

            <Grid gap="1.5rem" columns="repeat(auto-fit, minmax(220px, 1fr))" className="!mt-4">
              <DataList className="text-[0.95rem]">
                <Status label="Individual Deductible" color="blue" />
                <DataList.Item
                  label="Amount:"
                  value={JSON.stringify(summary?.mental_health_summary_in_network?.individual_deductible?.amount)}
                />
                <DataList.Item
                  label="Remaining:"
                  value={JSON.stringify(summary?.mental_health_summary_in_network?.individual_deductible?.remaining)}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family Deductible" color="blue" />
                <DataList.Item
                  label="Amount:"
                  value={JSON.stringify(summary?.mental_health_summary_in_network?.family_deductible.amount)}
                />
                <DataList.Item
                  label="Remaining:"
                  value={JSON.stringify(summary?.mental_health_summary_in_network?.family_deductible?.remaining)}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Individual OOP" color="blue" />
                <DataList.Item label="Amount:" value={JSON.stringify(summary?.mental_health_summary_in_network?.individual_oop?.amount)} />
                <DataList.Item
                  label="Remaining:"
                  value={JSON.stringify(summary?.mental_health_summary_in_network?.individual_oop?.remaining)}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family OOP" color="blue" />
                <DataList.Item label="Amount:" value={JSON.stringify(summary?.mental_health_summary_in_network?.family_oop?.amount)} />
                <DataList.Item
                  label="Remaining:"
                  value={JSON.stringify(summary?.mental_health_summary_in_network?.family_oop?.remaining)}
                />
              </DataList>
            </Grid>
          </CardTreeItem>

          <CardTreeItem shouldUnmount title="Mental Health Summary – Out of Network">
            <DataList labelWidth={'240px'}>
              <DataList.Item label="UMO Name:" value={JSON.stringify(summary?.mental_health_summary_out_network?.umo_name)} />
              <DataList.Item label="UMO Telephone:" value={JSON.stringify(summary?.mental_health_summary_out_network?.umo_telephone)} />
              <DataList.Item
                label="Service Authorization Info:"
                value={JSON.stringify(summary?.mental_health_summary_out_network?.service_authorization_info)}
              />
            </DataList>

            <DataList className="mt-6">
              <Status label="Co Pay / Insurance" color="blue" />
              <DataList.Item label="Co Pay:" value={JSON.stringify(summary?.mental_health_summary_out_network?.co_pay)} />
              <DataList.Item label="Co Insurance:" value={JSON.stringify(summary?.mental_health_summary_out_network?.co_insurance)} />
            </DataList>

            <Grid gap="1.5rem" columns="repeat(auto-fit, minmax(220px, 1fr))" className="!mt-4">
              <DataList className="text-[0.95rem]">
                <Status label="Individual Deductible" color="blue" />
                <DataList.Item
                  label="Amount:"
                  value={JSON.stringify(summary?.mental_health_summary_out_network?.individual_deductible?.amount)}
                />
                <DataList.Item
                  label="Remaining:"
                  value={JSON.stringify(summary?.mental_health_summary_out_network?.individual_deductible?.remaining)}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family Deductible" color="blue" />
                <DataList.Item
                  label="Amount:"
                  value={JSON.stringify(summary?.mental_health_summary_out_network?.family_deductible.amount)}
                />
                <DataList.Item
                  label="Remaining:"
                  value={JSON.stringify(summary?.mental_health_summary_out_network?.family_deductible?.remaining)}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Individual OOP" color="blue" />
                <DataList.Item label="Amount:" value={JSON.stringify(summary?.mental_health_summary_out_network?.individual_oop?.amount)} />
                <DataList.Item
                  label="Remaining:"
                  value={JSON.stringify(summary?.mental_health_summary_out_network?.individual_oop?.remaining)}
                />
              </DataList>

              <DataList className="text-[0.95rem]">
                <Status label="Family OOP" color="blue" />
                <DataList.Item label="Amount:" value={JSON.stringify(summary?.mental_health_summary_out_network?.family_oop?.amount)} />
                <DataList.Item
                  label="Remaining:"
                  value={JSON.stringify(summary?.mental_health_summary_out_network?.family_oop?.remaining)}
                />
              </DataList>
            </Grid>
          </CardTreeItem>

          <CardTreeItem shouldUnmount title="Miscellaneous Info">
            <DataList layout="vertical">
              <DataList.Item label="Remaining Spend Down" value={summary?.miscellaneous_info?.remaining_spend_down} />
              <DataList.Item label="Is NPI in Network" value={summary?.miscellaneous_info?.is_npi_in_network} />
            </DataList>
          </CardTreeItem>
        </Grid>
      </Section>

      <Section title="Services">
        <ServicesTree services={summary?.services} />
      </Section>
    </>
  )
}

export default withSettings(PVerify)
