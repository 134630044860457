import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { useFormField } from '../../Forms/hooks/useFormField'
import { useStore } from '../utils/useStore'
import { withFormContext } from '../../Forms/context'

import { DisplayValue } from './DisplayValue'

export const RootTextareaCell = (props: any) => {
  const { form, formatDisplayValue, isEditing, model, onBlur, rowData, setValue, validations, value } = props

  const ref: any = React.useRef()

  const { formActions, formState } = useFormField({
    model: model,
    form: form,
    validations: validations,
    isValid: size(validations) === 0,
  })

  const { isInvalid } = formState

  const updateField: any = useStore((state: any) => state.updateField)
  const showInvalid: any = useStore((state: any) => state.showInvalid)

  const displayValue = React.useMemo(() => {
    if (isUndefined(formatDisplayValue)) return value

    return formatDisplayValue({ value, rowData })
  }, [value, formatDisplayValue])

  const handleChange = (event: any) => {
    setValue(event.target.value)
  }

  const handleBlur = () => {
    updateField({ model, value })
    onBlur?.()
  }

  // Sync props value with state value
  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  // Update form state
  React.useEffect(() => {
    if (isUndefined(value)) return

    formActions.setValue(value)
  }, [value])

  // Focus input when editing
  React.useEffect(() => {
    if (!ref.current || !isEditing) return

    setTimeout(() => {
      ref.current.focus() // focus works only with setTimeout
    }, 0)
  }, [isEditing])

  const rootClasses = clsx(isEditing && 'is-editing', isInvalid && showInvalid && 'is-invalid')

  return (
    <>
      <textarea ref={ref} css={STYLES.root} className={rootClasses} value={value} onChange={handleChange} onBlur={handleBlur} />
      <DisplayValue isEditing={isEditing}>{displayValue}</DisplayValue>
    </>
  )
}

const STYLES = {
  root: {
    borderRadius: 3,
    border: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0 0.4rem',
    outline: 'none',

    fontSize: '0.92rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',

    display: 'none',

    '&:focus': {
      boxShadow: 'var(--input-focus-box-shadow)',
    },

    '&.is-editing': {
      display: 'block',
      background: COLORS.white,
      minHeight: 80,
    },

    '&.is-invalid': {
      display: 'block',
      background: tint(0.85, COLORS.red),
    },
  },
}

export const TextareaCell = withFormContext(RootTextareaCell)
