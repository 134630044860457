import React from 'react'
import { opacify, tint, transparentize } from 'polished'
import clsx from 'clsx'

import { COLORS, INPUT_STYLES, SHADOW } from '../../theme'
import { isDefined } from '../../utils/functions'

import Avatar from '../Avatar'
import Glyph from '../Glyph'
import Icon from '../Icon'
import Tooltip from '../Tooltip'
import Status from '../Status'

const RadioCheckBase = (props) => {
  const {
    count,
    after,
    avatar,
    avatarInitials,
    children,
    className,
    description,
    glyph,
    glyphColor,
    group_id,
    icon,
    id,
    isChecked,
    isDisabled,
    label,
    onChange,
    onFocus,
    reverse,
    showCheck,
    tooltip,
    type,
    value,
    variant,
    testKey,
    graphic,
    isCompact,
  } = props

  const classNames = clsx(`variant-${variant}`, {
    RadioCheckBase: true,
    'is-checked': isChecked,
    'is-disabled': isDisabled,
    'hide-label': !label,
    [className]: className,
  })

  const labelClassNames = clsx(`item-label variant-${variant}`, {
    'is-reversed': reverse,
  })

  const checkElementClassNames = clsx('check-element', {
    'is-checked': isChecked,
    'is-disabled': isDisabled,
    'is-radio': type === 'radio',
    'is-compact': isCompact,
  })

  return (
    <>
      <div css={styles.root} className={classNames} data-test={testKey}>
        <input
          id={id}
          name={group_id || id}
          type={type}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          css={styles.input}
          disabled={isDisabled}
        />

        <label htmlFor={id} css={styles.label} className={labelClassNames}>
          {showCheck && (
            <div css={checkElementStyles} className={checkElementClassNames}>
              {type === 'checkbox' && isChecked && <Glyph glyph="check" size={16} color="white" />}
            </div>
          )}

          <div css={styles.content}>
            {graphic && <div css={graphicStyles}>{graphic}</div>}

            {icon && <Icon icon={icon} size="1.1rem" css={graphicStyles} />}

            {glyph && <Glyph glyph={glyph} color={glyphColor} size="1.1rem" css={graphicStyles} />}

            {avatar !== undefined && (
              <Avatar magnify src={avatar} initials={avatarInitials} size="1.75rem" magnifyPlacement="left" css={graphicStyles} />
            )}

            <div className="radio-checkbox-label">
              {label}
              {description && <div css={styles.description}>{description}</div>}
            </div>

            {tooltip && <Tooltip content={tooltip} css={styles.tooltip} />}

            {after}

            {isDefined(count) && <Status css={styles.count} color="gray" small label={count} />}
          </div>
        </label>
      </div>

      {children}
    </>
  )
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    ...INPUT_STYLES,

    padding: 0,
    width: 'auto',
    cursor: 'pointer',

    '&:hover': {
      ...INPUT_STYLES['&:hover'],
      boxShadow: SHADOW(3),
      zIndex: 2,

      '& .check-element': {
        borderColor: tint(0.2, COLORS.green),
        boxShadow: `0 0 0 1px ${tint(0.85, COLORS.green)}, ${SHADOW(1)}`,
      },
    },

    '&.is-checked': {
      background: tint(0.96, COLORS.green),
      borderColor: `${transparentize(0.5, COLORS.green)} !important`,

      zIndex: 1,
      // position: 'relative',

      '&:hover': {
        boxShadow: SHADOW(2, transparentize(0.95, COLORS.green)),

        '.check-element': {
          borderColor: COLORS.green,
        },
      },
    },

    '&.is-disabled': {
      cursor: 'not-allowed !important',
      boxShadow: 'none !important',
      zIndex: '3 !important',
      color: transparentize(0.2, COLORS.textMuted),

      '&:hover': {
        cursor: 'not-allowed !important',

        '.check-element': {
          borderColor: opacify(0.08, COLORS.divider),
          boxShadow: 'none !important',
        },
      },
    },

    '&.hide-label': {
      width: 'fit-content',

      '.check-element': {
        marginRight: 0,
      },
    },

    '&.variant-small': {
      minHeight: 'auto',
    },

    '&.variant-skinny': {
      minHeight: 'auto',
      background: 'none',
      whiteSpace: 'nowrap',
      border: 'none',
      boxShadow: 'none !important',

      ':not(.is-checked)': {
        '.check-element': {
          background: 'white',
        },
      },
    },
  },

  input: {
    display: 'none',
    WebkitAppearance: 'none',
  },

  content: {
    display: 'inline-flex',
    alignItems: 'center',
    lineHeight: '1.35em',
    flex: '1 1 auto',
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    padding: '0.25em 0.65em',
    fontWeight: 500,
    cursor: 'inherit',

    '&.is-reversed': {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',

      '.check-element': {
        marginLeft: 10,
      },
    },

    '&.variant-small': {
      padding: 0,
    },

    '&.variant-skinny': {
      padding: 0,
    },
  },

  description: {
    display: 'block',
    fontWeight: 400,
    fontSize: '0.9em',
    color: COLORS.textMuted,
    marginTop: '0.1em',
  },

  tooltip: {
    marginLeft: 'auto',
  },

  count: {
    position: 'absolute',
    right: 10,
  },
}

const graphicStyles = {
  marginRight: '0.5rem',
}

const checkElementStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 0 auto',

  width: 20,
  height: 20,
  marginRight: 10,

  borderRadius: 4,
  border: `1px solid ${opacify(0.08, COLORS.divider)}`,
  transition: INPUT_STYLES.transition,

  '&.is-compact': {
    width: 18,
    height: 18,
    marginRight: 6,
  },

  '&.is-checked': {
    transition: 'all 50ms ease',
    background: COLORS.green,
    boxShadow: `0 0 0 0 ${COLORS.green} !important`,
  },

  '&.is-disabled': {
    opacity: 0.5,
  },

  '&.is-radio': {
    borderRadius: '50%',

    '&.is-checked::after': {
      content: '""',
      display: 'inline-block',
      width: 8,
      height: 8,
      background: 'white',
      borderRadius: '50%',
    },
  },

  '.input:hover &': {
    borderColor: tint(0.1, COLORS.green),
    boxShadow: `0 0 0 2px ${tint(0.5, COLORS.green)}`,
  },
}

RadioCheckBase.defaultProps = {
  type: 'checkbox',
  variant: 'default',
  showCheck: true,
}

export default RadioCheckBase
