import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Flex from '@behavehealth/components/Flex'
import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const STATUSES = {
  paid: {
    label: 'Paid',
    color: 'green',
  },
  open: {
    label: 'Open',
    color: 'yellow',
  },
  partiallyPaid: {
    label: 'Partially Paid',
    color: 'yellow',
  },
  void: {
    label: 'Void',
    color: 'yellow',
  },
  draft: {
    label: 'Archived',
    color: 'gray',
  },
  unknown: {
    label: 'Unknown',
    color: 'gray',
  },
}

const RootBankAccounts = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting', 'bank_accounts'],
    endpoint: `/accounting/bank_accounts`,
    // params: {...},
    localStorageKey: 'accounting_bank_accounts_v1',
  })

  const { mutateAsync: syncRailzToBh, isLoading: isSyncingRailz } = useCreate({
    name: ['sync-railz-to-bh'],
    url: '/accounting/bank_accounts/sync_railz_to_bh',
    invalidate: tableProps.queryKey,
  })

  const { mutateAsync: syncQbToRailz, isLoading: isSyncingQb } = useCreate({
    name: ['sync-qb-to-railz'],
    url: '/accounting/bank_accounts/sync_qb_to_railz',
  })

  const handleSyncRailzToBh = async () => {
    await syncRailzToBh()
    tableProps.refetch()
  }

  const handleSyncQbToRailz = () => {
    syncQbToRailz()
  }

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Account Name',
        model: 'account_name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell value={data.account_name} />,
      },
      {
        title: 'Account ID',
        model: 'account_id',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Current Balance',
        model: 'current_balance',
        width: 200,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Available Balance',
        model: 'available_balance',
        width: 200,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Limit',
        model: 'limit',
        width: 200,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Masked Account Number',
        model: 'masked_account_number',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Official Account Name',
        model: 'official_account_name',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Account Type',
        model: 'account_type',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Account Sub Type',
        model: 'account_sub_type',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Institution Name',
        model: 'institution_name',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Currency',
        model: 'currency',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Last Updated',
        model: 'source_modified_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
      // {
      //   title: 'Quickbooks Status',
      //   model: 'status',
      //   width: 160,
      //   disableHide: true,
      //   formatValue: ({ value }) => <SmartStatus statuses={STATUSES} status={value} />,
      // },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Bank Accounts">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Bank Accounts"
          emptyDescription="No bank accounts have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          icon="accounting"
          columns={columns}
          {...tableProps}
          headerAfter={
            isBehave && (
              <Flex gap="0.5rem">
                <AccountingSyncButton
                  forceShow={true}
                  category="bank_accounts"
                  invalidate={tableProps.queryKey}
                  refetchUrl={'/accounting/sync?category=bank_accounts'}
                />
              </Flex>
            )
          }
        />
      </Grid>
    </Page>
  )
}

export const BankAccounts = withPageError(RootBankAccounts)

const FILTERS_CONFIG = {
  account_name: {
    label: 'Account Name',
    type: 'string',
  },
  account_id: {
    label: 'Account ID',
    type: 'string',
  },
  masked_account_number: {
    label: 'Masked Account Number',
    type: 'string',
  },
  official_account_name: {
    label: 'Official Account Name',
    type: 'string',
  },
  account_type: {
    label: 'Account Type',
    type: 'string',
  },
  account_sub_type: {
    label: 'Account Subtype',
    type: 'string',
  },
  institution_name: {
    label: 'Institution Name',
    type: 'string',
  },
  current_balance: {
    label: 'Current Balance',
    type: 'number',
  },
  available_balance: {
    label: 'Available Balance',
    type: 'number',
  },
  limit: {
    label: 'Limit',
    type: 'number',
  },
  currency: {
    label: 'Currency',
    type: 'string',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'date_time',
  },
}
