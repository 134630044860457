import React from 'react'

import { COLORS } from '../../theme'
import Glyph from '../Glyph'

export const TableCellEditButton = ({ onClick }: any) => {
  return (
    <button type="button" css={styles.button} onClick={onClick}>
      <Glyph glyph="edit" size={12} />
    </button>
  )
}

const styles = {
  button: {
    position: 'absolute',
    top: 0,
    right: 1,

    width: 24,
    height: 24,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    background: COLORS.white,
    border: 'none',
    borderLeft: `1px solid ${COLORS.divider}`,
    borderBottom: `1px solid ${COLORS.divider}`,
    borderBottomLeftRadius: 4,
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.blue,
      svg: { fill: COLORS.white },
    },
  },
}
