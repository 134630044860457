import React from 'react'

import { useCreate } from '../../hooks/useNewAPI'
import { useDataTable } from '../../components/DataTable/useDataTable'

import Alert from '../../components/Alert'
import Glyph from '../../components/Glyph'
import Loader from '../../components/Loader'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

import { AdminProductTemplatesDataTable } from '../InternalBilling/AdminProductTemplatesDataTable'

export const QuoteProductsImportOverlay = (props: any) => {
  const { onImport, onClose } = props

  const tableProps = useDataTable({
    name: ['products'],
    endpoint: '/internal_templates',
    params: { category: 'product' },
    localStorageKey: 'products_import_v1',
  })

  const { mutateAsync: createProduct, isLoading: isCreatingProduct } = useCreate({
    name: ['create-product'],
    url: '/products',
    invalidate: 'products',
  })

  const [isImporting, setIsImporting] = React.useState(false)

  const handleSingleImport = async (selectedRow: any) => {
    if (!selectedRow) return

    setIsImporting(true)

    try {
      const newDiscount = await createProduct({
        name: selectedRow.name,
        status: selectedRow.status,
        category: selectedRow.subcategory,
        ...selectedRow.data,
      })

      if (!newDiscount?.data) return

      await onImport?.(newDiscount?.data)

      onClose?.()
    } catch (error) {
      console.error(error)
    } finally {
      setIsImporting(false)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={90} onClose={onClose}>
      <Overlay.Header icon="certificate" title="Import from Catalog Product Templates" />

      <Overlay.Content>
        <Section>
          <Alert
            small
            contrast
            className="-mt-2 mb-4"
            graphic={isCreatingProduct || isImporting ? <Loader size={16} className="mr-4" /> : <Glyph glyph="info" size={16} />}
          >
            {isCreatingProduct || isImporting ? 'Importing Product…' : 'Select a product template to import it into the quote.'}
          </Alert>

          <AdminProductTemplatesDataTable {...tableProps} title="Product Templates" onClick={handleSingleImport} />
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}
