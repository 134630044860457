import React from 'react'
import { lighten } from 'polished'

import { COLORS } from '../../../theme'
import { formatURL } from '../../../utils/functions'
import Glyph from '../../Glyph'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

export const ExternalUrlCell: React.FC = (props: any) => {
  const { value } = props

  const formattedURL = formatURL(value)

  return (
    <DataTableCell hoverExpand {...props}>
      {formattedURL ? (
        <a href={formattedURL} css={STYLES.root} target="_blank">
          <span css={STYLES.text}>{value}</span>
          <Glyph glyph="external_link" size={12} css={STYLES.glyph} color={COLORS.blue} />
        </a>
      ) : (
        <EmptyCell />
      )}
    </DataTableCell>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: '1 1 auto',
    flexWrap: 'nowrap',
    overflow: 'hidden',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0 0.5rem',

    '&:hover': {
      background: `${lighten(0.34, COLORS.vividBlue)}`,
      boxShadow: `inset 0 0 0 1px ${COLORS.divider}`,
    },
  },

  text: {
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  glyph: {
    marginLeft: 'auto',
  },
}
