import React from 'react'

import { initials } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { DailyNotesReportDataTable } from '@behavehealth/constructs/LiveReports/DailyNotesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const DailyNotes = () => {
  const tableProps = useDataTable({
    name: ['reports', 'daily_notes'],
    endpoint: `/live_reports?category=daily_notes`,
    updateDeleteEndpoint: `/daily_notes`,
    localStorageKey: 'report_daily_notes_v1',
  })

  const to = React.useMemo(
    () => (rowData: any) => {
      return `/clients/${rowData.client?.id}/daily-notes/all/${initials(rowData.category)}/${rowData.id}`
    },
    [],
  )

  return (
    <Page feature="daily_notes" title="Live Daily Notes Report">
      <Grid gap="1rem" columns="100%">
        <DailyNotesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(DailyNotes)
