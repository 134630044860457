import React from 'react'

import { DataTable } from '../../../../DataTable/DataTable'
import { MainCell } from '../../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../../DataTable/useDataTable'

import IntakeStatus from '../../../../Statuses/IntakeStatus'
import ClientColorPicker from '../../../../ColorPickers/ClientColorPicker'
import { FILTERS } from '../../../../../constructs/Filters/config'

export const AdmissionsClientsTable = ({ onSelect, dependentValue, options = {}, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-clients-admissions'],
    endpoint: options?.endpoint || '/residents',
    params: options?.params || {
      status: 'lead,intake',
    },
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_clients_admissions_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.name} avatar={data.avatar} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
      },
      {
        title: 'Sex',
        model: 'sex',
        type: 'title',
        width: 140,
      },
      {
        title: 'Last Application Date',
        id: 'last_application_date',
        model: 'current_intake_application.created_at',
        type: 'date_time',
      },
      {
        title: 'Est. Arrival Date',
        id: 'admission_estimated_arrival',
        model: 'current_admission.estimated_arrival',
        type: 'date_time',
      },
      {
        title: 'Admission Status',
        id: 'admission_status',
        model: 'current_admission_status',
        disableSort: true,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <IntakeStatus status={value} />
        },
      },
      {
        title: 'Admission Notes',
        model: 'current_admission_notes',
        disableSort: true,
        hoverExpand: false,
      },
      {
        title: 'Phone',
        type: 'phone',
        model: 'phone_no',
        canEdit: true,
      },
      {
        title: 'Email',
        type: 'email',
        model: 'email',
        canEdit: true,
      },
      {
        title: 'Color',
        model: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => <ClientColorPicker clientID={data.id} color={data.color_code} />,
      },
      {
        title: 'Client ID',
        model: 'behave_id',
        width: 180,
        formatValue: ({ data }: any) => `#${data.behave_id}`,
      },
      {
        title: 'Referral In',
        id: 'referral_source',
        model: 'referral_source',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Referral In Notes',
        model: 'referral_notes',
        hoverExpand: true,
      },
      {
        title: 'Referral Out',
        id: 'referral_out',
        model: 'referral_out',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Referral Out Notes',
        model: 'referral_out_notes',
        hoverExpand: true,
      },
      {
        title: 'Last Name',
        model: 'last_name',
        width: 180,
        canEdit: true,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable {...tableProps} {...rest} title="Admissions" columns={columns} filtersConfig={FILTERS.intakes} searchBarFilterKey="name" />
  )
}
