import React from 'react'

import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import DateInput from '../../../components/Forms/DateInput'
import Input from '../../../components/Forms/Input'
import LevelOfCareSelect from '../../../components/Forms/LevelOfCareSelect'
import Medications from '../../../components/SmartContent/Medications'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import Textarea from '../../../components/Forms/Textarea'

import {
  MENTAL_STATUS_EXAM_FIELDS,
  MOTIVATION_FOR_CHANGE_FIELDS,
  SUICIDE_SAFETY_RISK_FIELDS,
  VITAL_SIGNS_FIELDS,
  WITHDRAWAL_SYMPTOMS_FIELDS,
} from './common'

import { ASAM_CRITERIA_SECTIONS } from './asam_criteria'

export const PROGRESS_REVIEW_SECTIONS = [
  {
    title: 'General Info',
    model: 'general_info',
    fields: [
      {
        label: 'Current Level of Care (read-only)',
        model: 'current_level_of_care',
        component: ({ client }: any) => (
          <LevelOfCareSelect
            isEditable={false}
            label="Current Level of Care"
            model="data.general_info.level_of_care"
            value={client && client.level_of_care}
          />
        ),
      },
      {
        label: 'Clinical Admit Date',
        model: 'clinical_admit_date',
        component: ({ client }: any) => (
          <DateInput
            label="Clinical Admit Date"
            model="data.general_info.admitted_at"
            default={client && client.current_admission && client.current_admission.admitted_at}
          />
        ),
      },
      {
        label: 'Does the client have any allergies?',
        model: 'client_allergies',
        component: () => (
          <>
            <RadioGroup layout="horizontal-dense" label="Does the client have any allergies?" model="data.general_info.has_allergies">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.general_info.has_allergies" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please list what the client is allergic to and the corresponding reaction."
                model="data.general_info.allergies"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Presenting Problem and Behavioral Health Issues (Symptomology)',
        model: 'symptomology',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="Presenting Problem and Behavioral Health Issues (Symptomology)"
            model="data.general_info.problems_and_symptomology"
          />
        ),
      },
    ],
  },
  {
    title: 'Current Medications',
    model: 'medications',
    fields: [
      {
        label: 'Medications List',
        model: 'medications_list',
        component: ({ isEditable, client }: any) => (
          <Medications
            model="data.medications.meds"
            isEditable={isEditable}
            client={client}
            icon="medications"
            title="Medications"
            emptyDescription="No medications added yet"
          />
        ),
      },
    ],
  },
  {
    title: 'Withdrawal / Post-acute Symptoms',
    model: 'withdrawal_symptoms',
    fields: WITHDRAWAL_SYMPTOMS_FIELDS,
  },
  {
    title: 'Bio-Medical',
    model: 'bio_medical',
    fields: [
      ...VITAL_SIGNS_FIELDS,
      {
        label: "How is the client's sleep currently?",
        model: 'sleep_quality',
        component: () => (
          <RadioGroup model="data.bio_medical.sleep_quality" label="How is the client's sleep currently?" layout="horizontal">
            <Radio label="Poor" value="poor" />
            <Radio label="Average" value="average" />
            <Radio label="Good" value="good" />
          </RadioGroup>
        ),
      },
      {
        label: 'How many hours does the client sleep in a night?',
        model: 'sleep_hours',
        component: () => (
          <Input model="data.bio_medical.sleep_hours" label="How many hours does the client sleep in a night?" size={2} suffix="hours" />
        ),
      },
      {
        label: 'Does the client have trouble falling asleep or staying asleep?',
        model: 'sleep',
        component: () => (
          <CheckboxGroup label="Does the client have trouble falling asleep or staying asleep?" layout="vertical-dense">
            <Checkbox label="Falling asleep" model="data.bio_medical.sleep.trouble_falling_asleep" />
            <Checkbox label="Staying asleep" model="data.bio_medical.sleep.trouble_staying_asleep" />
            <Checkbox label="Waking up frequently" model="data.bio_medical.sleep.trouble_waking_up_frequently" />
            <Checkbox label="Nightmares" model="data.bio_medical.sleep.trouble_nightmares" />
            <Checkbox label="Using dreams" model="data.bio_medical.sleep.trouble_using_dreams" />
          </CheckboxGroup>
        ),
      },
      {
        label: 'How many meals per day is the client eating?',
        model: 'meals_per_day',
        component: () => (
          <Input model="data.bio_medical.meals_per_day" label="How many meals per day is the client eating?" suffix="meals" size={2} />
        ),
      },
      {
        label: 'What percentage of meals is the client currently eating?',
        model: 'percentage_meals_eating',
        component: () => (
          <Input
            model="data.bio_medical.percentage_meals_eating"
            label="What percentage of meals is the client currently eating?"
            suffix="%"
            size={2}
          />
        ),
      },
      {
        label: 'Does the client have a diagnosed medical condition?',
        model: 'has_diagnosed_medical_condition',
        component: () => (
          <>
            <RadioGroup
              label="Does the client have a diagnosed medical condition?"
              model="data.bio_medical.has_diagnosed_medical_condition"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.bio_medical.has_diagnosed_medical_condition" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Is condition interfering with treatment?"
                model="data.bio_medical.condition_interfering_with_treatment"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: "Have there been any significant changes to the client's medical condition since last review?",
        model: 'had_significant_changes',
        component: () => (
          <>
            <RadioGroup
              label="Have there been any significant changes to the client's medical condition since last review?"
              model="data.bio_medical.had_significant_changes"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.bio_medical.had_significant_changes" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please explain:"
                model="data.bio_medical.had_significant_changes_extra"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Have there been any medical interventions required since last review?',
        model: 'has_required_medical_interventions',
        component: () => (
          <>
            <RadioGroup
              label="Have there been any medical interventions required since last review?"
              model="data.bio_medical.has_required_medical_interventions"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.bio_medical.has_required_medical_interventions" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please explain:"
                model="data.bio_medical.has_required_medical_interventions_extra"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Withdrawal Narrative',
        model: 'withdrawal_narrative',
        component: () => (
          <>
            <SmartTextarea useQuickText useDictation label="Withdrawal Narrative" model="data.bio_medical.withdrawal_narrative" />
          </>
        ),
      },
    ],
  },
  {
    title: 'Suicide/Safety Risk',
    model: 'suicide_safety_risk',
    fields: SUICIDE_SAFETY_RISK_FIELDS,
  },
  {
    title: 'Mental Status Exam',
    model: 'mental_status_exam',
    fields: MENTAL_STATUS_EXAM_FIELDS,
  },
  {
    title: 'Motivation for Change',
    model: 'motivation_for_change',
    fields: MOTIVATION_FOR_CHANGE_FIELDS,
  },
  {
    title: 'Assignments & Appointments',
    model: 'assignments',
    fields: [
      {
        label: 'Please list specific assignments that are being worked on or will be assigned to client',
        model: 'assignments_assigned_to_client_during_treatment',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list specific assignments that are being worked on or will be assigned to client:"
            model="data.assignments.assignments_assigned_to_client_during_treatment"
          />
        ),
      },
      {
        label: 'What issues are being addressed in individual sessions and how? (List assignments given to address presenting issues)',
        model: 'issues_addressed_in_sessions',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="What issues are being addressed in individual sessions and how? (List assignments given to address presenting issues)"
            model="data.assignments.issues_addressed_in_sessions"
          />
        ),
      },
      {
        label: 'Will the client be seen by a psychologist, psychiatrist or other outside physician during treatment?',
        model: 'has_future_appointments',
        component: () => (
          <>
            <RadioGroup
              label="Will the client be seen by a psychologist, psychiatrist or other outside physician during treatment?"
              model="data.assignments.has_future_appointments"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.assignments.has_future_appointments" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please list provider name and reason for appointment:"
                model="data.assignments.provider_name_and_reason"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />

              <RadioGroup
                label="Has an appointment already been scheduled?"
                model="data.assignments.is_physician_appointment_scheduled"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.assignments.is_physician_appointment_scheduled" is={true}>
                <SmartTextarea
                  useQuickText
                  useDictation
                  label="Please list provider name and date of appointment:"
                  model="data.assignments.provider_name_and_appointment"
                  validations={{
                    presence: {
                      message: 'Please provide this information',
                    },
                  }}
                />
              </ContextShow>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Has the client been seen by a psychologist, psychiatrist or other outside physician since admission?',
        model: 'has_appointments',
        component: () => (
          <>
            <RadioGroup
              label="Has the client been seen by a psychologist, psychiatrist or other outside physician since admission?"
              model="data.assignments.has_appointments"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.assignments.has_appointments" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please list provider name, date seen, reason and follow up appointment:"
                model="data.assignments.provider_name_and_appointment"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>

            <SmartTextarea
              useQuickText
              useDictation
              label="How are mental health issues or medical concerns being addressed?"
              model="data.assignments.how_are_issues_addressed"
            />
          </>
        ),
      },
    ],
  },
  {
    title: 'Relapse Potential',
    model: 'relapse_potential',
    fields: [
      {
        label: "What is the client's current craving level? (1 = low, 5 = high)",
        model: 'craving_level',
        component: () => (
          <RadioGroup
            label="What is the client's current craving level? (1 = low, 5 = high)"
            model="data.relapse_potential.craving_level"
            layout="horizontal"
          >
            <Radio label="1" value="1" />
            <Radio label="2" value="2" />
            <Radio label="3" value="3" />
            <Radio label="4" value="4" />
            <Radio label="5" value="5" />
          </RadioGroup>
        ),
      },
      {
        label: 'What types of things trigger the client to want to relapse?',
        model: 'what_triggers_client_to_relapse',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="What types of things trigger the client to want to relapse?"
            model="data.relapse_potential.what_triggers_client_to_relapse"
          />
        ),
      },
      {
        label: 'How is treatment helping to manage and reduce the effect of triggers?',
        model: 'how_is_treatment_helping_with_triggers',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="How is treatment helping to manage and reduce the effect of triggers?"
            model="data.relapse_potential.how_is_treatment_helping_with_triggers"
          />
        ),
      },
      {
        label: 'How is facility/providers helping client to meet these specific goal?',
        model: 'how_is_facility_helping_to_meet_goals',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="How is facility/providers helping client to meet these specific goal?"
            model="data.relapse_potential.how_is_facility_helping_to_meet_goals"
          />
        ),
      },
      {
        label: 'List any triggers the client has identified',
        model: 'identified_triggers',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="List any triggers the client has identified:"
            model="data.relapse_potential.identified_triggers"
          />
        ),
      },
      {
        label: 'List any coping skills learned or demonstrated by the client',
        model: 'coping_triggers',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="List any coping skills learned or demonstrated by the client:"
            model="data.relapse_potential.coping_triggers"
          />
        ),
      },
      {
        label: "What is the client's current risk of relapse (low, medium, high) and why?",
        model: 'risk_of_relapse',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="What is the client's current risk of relapse (low, medium, high) and why?"
            model="data.relapse_potential.risk_of_relapse"
          />
        ),
      },
      {
        label: 'Date of last use',
        model: 'date_of_last_use',
        component: () => <DateInput label="Date of last use:" model="data.relapse_potential.date_of_last_use" />,
      },
    ],
  },
  {
    title: 'Recovery Environment',
    model: 'recovery_environment',
    fields: [
      {
        label: 'Has client had any family sessions?',
        model: 'has_had_family_sessions',
        component: () => (
          <>
            <RadioGroup
              label="Has client had any family sessions?"
              model="data.recovery_environment.has_had_family_sessions"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.recovery_environment.has_had_family_sessions" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="List date, family members attended and whether in person or by phone:"
                model="data.recovery_environment.family_members_attending_sessions"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>

            <ContextShow when="data.recovery_environment.has_had_family_sessions" is={false}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Why not?"
                model="data.recovery_environment.family_members_not_attending_reason"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Where is client currently living?',
        model: 'current_living_location',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="Where is client currently living?"
            model="data.recovery_environment.current_living_location"
          />
        ),
      },
      {
        label: 'Anticipated Discharge Date',
        model: 'anticipated_discharge_date',
        component: () => <DateInput label="Anticipated Discharge Date:" model="data.recovery_environment.anticipated_discharge_date" />,
      },
      {
        label: 'Notes',
        model: 'notes',
        component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.recovery_environment.notes" />,
      },
    ],
  },
  ...ASAM_CRITERIA_SECTIONS.filter((section) => section?.model !== 'summary'),
  {
    title: 'Summary',
    model: 'summary',
    fields: [
      {
        label: 'Clinician Perspective',
        model: 'clinician_perspective',
        component: () => <Textarea useQuickText label="Clinician Perspective" model="data.summary.clinician_perspective" />,
      },
      {
        label: 'Interpretive Summary',
        model: 'interpretive_summary',
        component: () => <Textarea useQuickText label="Interpretive Summary" model="data.summary.interpretive_summary" />,
      },
    ],
  },
]
