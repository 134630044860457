import React from 'react'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import Divider from '../../../../Divider'
import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Section from '../../../../Section'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const ASAMCriteria = () => {
  return (
    <>
      <Section
        title="Dimension 1: Detoxification/Withdrawal Potential Assessment"
        scrollview={{
          id: 'dimension_1',
          name: 'Dimension 1: Detoxification/Withdrawal Potential Assessment',
          parent: { id: 'asam_criteria', name: 'ASAM Criteria' },
        }}
        commentsModel="data.asam_criteria.dimension_1"
      >
        <FormSection>
          <RadioGroup
            label="Patients Opioid Use Disorder requires monitoring or withdrawal management services that can be provided effectively only at the present level of care."
            layout="horizontal-dense"
            model="data.asam_criteria.dimension_1.does_require_monitoring_or_withdrawal_management"
          >
            <Radio label="True" value={true} />
            <Radio label="False" value={false} />
          </RadioGroup>

          <CheckboxGroup label="0 = no problem or stable" layout="vertical-dense">
            <Checkbox
              label="Individual fully functioning w/ good ability to tolerate, cope with withdrawal discomfort"
              model="data.asam_criteria.dimension_1.is_fully_functioning_coping_with_withdrawal_discomfort"
            />
            <Checkbox
              label="No signs or symptoms of withdrawal present or are resolving and if alcohol, a CIWA-Ar score of less than 3"
              model="data.asam_criteria.dimension_1.has_no_symptoms_of_withdrawal"
            />
            <Checkbox label="No signs or symptoms of intoxication" model="data.asam_criteria.dimension_1.has_no_symptoms_of_intoxication" />
          </CheckboxGroup>

          <CheckboxGroup label="1 = mild" layout="vertical-dense">
            <Checkbox
              label="Adequate ability to tolerate or cope with withdrawal discomfort."
              model="data.asam_criteria.dimension_1.is_adequate_coping_with_withdrawal_discomfort"
            />
            <Checkbox
              label="Mild to moderate intoxication, or signs, symptoms interfere w/daily functioning, but not a danger to self or others"
              model="data.asam_criteria.dimension_1.has_mild_to_moderate_intoxication"
            />
            <Checkbox
              label="Minimal risk of severe withdrawal resolving and if alcohol, a CIWA-Ar score of 3-7"
              model="data.asam_criteria.dimension_1.has_minimal_risk_of_severe_withdrawal"
            />
            <Checkbox label="Sub intoxication level" model="data.asam_criteria.dimension_1.is_sub_intoxication_level" />
          </CheckboxGroup>

          <CheckboxGroup label="2 = moderate" layout="vertical-dense">
            <Checkbox
              label="Some difficulty tolerating and coping w/withdrawal discomfort"
              model="data.asam_criteria.dimension_1.has_some_difficulty_with_withdrawal_discomfort"
            />
            <Checkbox
              label="Intoxication may be severe, but responds to treatment so individual does not pose imminent danger to self or others"
              model="data.asam_criteria.dimension_1.is_intoxication_possibly_severe_but_responds_to_treatment"
            />
            <Checkbox
              label="Moderate signs and symptoms with moderate risk of severe withdrawal"
              model="data.asam_criteria.dimension_1.has_moderate_symptoms_and_risk_of_severe_withdrawal"
            />
            <Checkbox label="Somewhat intoxicated" model="data.asam_criteria.dimension_1.is_somewhat_intoxicated" />
            <Checkbox label="If alcohol, a CIWA-Ar score if 8-11" model="data.asam_criteria.dimension_1.if_alcohol_ciwa_ar_score_8_11" />
          </CheckboxGroup>

          <CheckboxGroup label="3 = substantial" layout="vertical-dense">
            <Checkbox
              label="Demonstrates poor ability to tolerate and cope with withdrawal discomfort."
              model="data.asam_criteria.dimension_1.has_poor_ability_with_withdrawal_discomfort"
            />
            <Checkbox
              label="Severe signs and symptoms of intoxication indicating possible imminent danger to self & others"
              model="data.asam_criteria.dimension_1.has_severe_symptoms_of_intoxication"
            />
            <Checkbox
              label="Severe signs and symptoms or risk of severe but manageable withdrawal; or withdrawal is worsening despite detoxification at less intensive level of care"
              model="data.asam_criteria.dimension_1.has_severe_symptoms_of_severe_but_manageable_withdrawal"
            />
            <Checkbox label="Very intoxicated" model="data.asam_criteria.dimension_1.is_very_intoxicated" />
            <Checkbox label="If alcohol, a CIWA-Ar score if 12-15" model="data.asam_criteria.dimension_1.if_alcohol_ciwa_ar_score_12_15" />
          </CheckboxGroup>

          <CheckboxGroup label="4 = severe" layout="vertical-dense">
            <Checkbox
              label="Incapacitated, with severe signs and symptoms of withdrawal"
              model="data.asam_criteria.dimension_1.is_incapacitated_with_severe_symptoms_of_withdrawal"
            />
            <Checkbox
              label="Severe withdrawal presents danger (e.g. seizures)"
              model="data.asam_criteria.dimension_1.has_severe_withdrawal_presenting_danger"
            />
            <Checkbox
              label="Continued use poses an imminent threat to life"
              model="data.asam_criteria.dimension_1.does_continued_use_pose_imminent_threat_to_life"
            />
            <Checkbox label="Stuporous" model="data.asam_criteria.dimension_1.is_stuporous" />
            <Checkbox label="If alcohol, a CIWA-Ar score over 15" model="data.asam_criteria.dimension_1.if_alcohol_ciwa_ar_score_over_15" />
          </CheckboxGroup>

          <SmartTextarea useDictation label="Notes" model="data.asam_criteria.dimension_1.notes" />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Dimension 2: Biomedical Conditions and Complications"
        scrollview={{
          id: 'dimension_2',
          name: 'Dimension 2: Biomedical Conditions and Complications',
          parent: { id: 'asam_criteria', name: 'ASAM Criteria' },
        }}
        commentsModel="data.asam_criteria.dimension_2"
      >
        <FormSection>
          <RadioGroup
            label="The physical health problem that required admission to the present level of care or a new problem requires biomedical services that can be provided effectively only at the present level of care."
            layout="horizontal-dense"
            model="data.asam_criteria.dimension_2.does_require_biomedical_services"
          >
            <Radio label="True" value={true} />
            <Radio label="False" value={false} />
          </RadioGroup>

          <CheckboxGroup label="0 = no problem or stable" layout="vertical-dense">
            <Checkbox
              label="Fully functioning with good ability to tolerate or cope w/ physical discomfort"
              model="data.asam_criteria.dimension_2.is_fully_functioning_coping_with_physical_discomfort"
            />
            <Checkbox
              label="No biomedical signs or symptoms are present, or biomedical problems stable"
              model="data.asam_criteria.dimension_2.has_no_biomedical_symptoms"
            />
            <Checkbox
              label="No biomedical conditions that will interfere with treatment or create risk"
              model="data.asam_criteria.dimension_2.has_no_biomedical_conditions_to_create_risk"
            />
          </CheckboxGroup>

          <CheckboxGroup label="1 = mild" layout="vertical-dense">
            <Checkbox
              label="Demonstrates adequate ability to tolerate and cope with physical discomfort"
              model="data.asam_criteria.dimension_2.is_adequate_coping_with_physical_discomfort"
            />
            <Checkbox
              label="Mild to moderate signs or symptoms interfere with daily functioning, but would likely not interfere with recovery treatment nor create risk"
              model="data.asam_criteria.dimension_2.has_mild_to_moderate_symptoms_interfere_with_daily_functioning"
            />
          </CheckboxGroup>

          <CheckboxGroup label="2 = moderate" layout="vertical-dense">
            <Checkbox
              label="Some difficulty tolerating and coping with physical problems and/or has other biomedical problems"
              model="data.asam_criteria.dimension_2.has_some_difficulty_with_physical_problems"
            />
            <Checkbox
              label="Has a biomedical problem, which may interfere with recovery treatment"
              model="data.asam_criteria.dimension_2.has_biomedical_problem_which_may_interfere"
            />
            <Checkbox
              label="Has a need for medical services which might interfere with recovery treatment (e.g., kidney dialysis)"
              model="data.asam_criteria.dimension_2.does_need_medical_services_which_may_interfere"
            />
            <Checkbox
              label="Neglects to care for serious biomedical problems"
              model="data.asam_criteria.dimension_2.does_neglect_serious_biomedical_problems_care"
            />
            <Checkbox
              label="Acute, non-life threatening medical signs and symptoms are present"
              model="data.asam_criteria.dimension_2.has_acute_non_life_threatening_medical_symptoms"
            />
          </CheckboxGroup>

          <CheckboxGroup label="3 = substantial" layout="vertical-dense">
            <Checkbox
              label="Demonstrates poor ability to tolerate and cope with physical problems and/or general health is poor"
              model="data.asam_criteria.dimension_2.has_poor_ability_with_physical_problems"
            />
            <Checkbox
              label="Has serious medical problems he/she neglects during outpatient treatment that require frequent medical attention"
              model="data.asam_criteria.dimension_2.does_neglect_serious_medical_problems"
            />
            <Checkbox
              label="Severe medical problems are present but stable."
              model="data.asam_criteria.dimension_2.has_severe_but_stable_medical_problems"
            />
            <Checkbox
              label="Medical problem(s) present that would be severely exacerbated by a relapse"
              model="data.asam_criteria.dimension_2.will_relapse_severely_exacerbate_medical_problems"
            />
            <Checkbox
              label="Medical problem(s) present that would be severely exacerbated by withdrawal (e.g., diabetes, hypertension)"
              model="data.asam_criteria.dimension_2.will_withdrawal_severely_exacerbate_medical_problems"
            />
            <Checkbox
              label="Medical problems that require medical or nursing services"
              model="data.asam_criteria.dimension_2.has_medical_problems_requiring_services"
            />
          </CheckboxGroup>

          <CheckboxGroup label="4 = severe" layout="vertical-dense">
            <Checkbox
              label="Incapacitated, with severe medical problems"
              model="data.asam_criteria.dimension_2.is_incapacitated_with_severe_medical_problems"
            />
            <Checkbox
              label="Severe medical problems that are life threatening risk"
              model="data.asam_criteria.dimension_2.has_severe_life_threatening_medical_problems"
            />
          </CheckboxGroup>

          <SmartTextarea useDictation label="Notes" model="data.asam_criteria.dimension_2.notes" />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Dimension 3: Emotional/Behavioral/Cognitive Conditions and Complications"
        scrollview={{
          id: 'dimension_3',
          name: 'Dimension 3: Emotional/Behavioral/Cognitive Conditions and Complications ',
          parent: { id: 'asam_criteria', name: 'ASAM Criteria' },
        }}
        commentsModel="data.asam_criteria.dimension_3"
      >
        <FormSection>
          <RadioGroup
            label="The emotional, behavioral, and/or cognitive problem that required admission to the present level of care continues, or a new problem has appeared. This problem requires interventions that can be provided effectively only at the present level of care."
            layout="horizontal-dense"
            model="data.asam_criteria.dimension_3.does_require_interventions_at_present_level_of_care"
          >
            <Radio label="True" value={true} />
            <Radio label="False" value={false} />
          </RadioGroup>

          <CheckboxGroup label="0 = no problem or stable" layout="vertical-dense">
            <Checkbox
              label="No or stable mental health problems"
              model="data.asam_criteria.dimension_3.has_stable_mental_health_problems"
            />
          </CheckboxGroup>

          <CheckboxGroup label="1 = mild" layout="vertical-dense">
            <Checkbox label="Sub-clinical mental disorder" model="data.asam_criteria.dimension_3.has_sub_clinical_mental_disorder" />
            <Checkbox
              label="Emotional concerns relate to negative consequences and effects of addiction."
              model="data.asam_criteria.dimension_3.has_emotional_concerns_relating_to_consequences_and_addiction"
            />
            <Checkbox label="Suicidal ideation without plan" model="data.asam_criteria.dimension_3.has_suicidal_ideation_without_plan" />
            <Checkbox
              label="Social role functioning impaired, but not endangered by substance use; mild symptoms that do not impair role functioning (e.g. social, school, or work)"
              model="data.asam_criteria.dimension_3.has_social_role_functioning_impaired"
            />
            <Checkbox
              label="Mild to moderate signs and symptoms with good response to treatment in the past."
              model="data.asam_criteria.dimension_3.has_mild_to_moderate_symptoms_with_good_past_treatment_response"
            />
            <Checkbox
              label="Or past serious problems have long period of stability or are chronic, but do not pose high risk of harm"
              model="data.asam_criteria.dimension_3.do_past_serious_problems_have_long_stability_period_without_high_risk_of_harm"
            />
          </CheckboxGroup>

          <CheckboxGroup label="2 = moderate" layout="vertical-dense">
            <Checkbox
              label="Suicidal ideation or violent impulses require more than routine monitoring"
              model="data.asam_criteria.dimension_3.has_suicidal_ideation_requiring_more_than_routine_monitoring"
            />
            <Checkbox
              label="Emotional, behavioral, or cognitive problems distract from recovery efforts."
              model="data.asam_criteria.dimension_3.do_emotional_behavioral_cognitive_problems_distract_from_recovery_efforts"
            />
            <Checkbox
              label="Symptoms are causing moderate difficulty in role functioning (e.g. school, work)"
              model="data.asam_criteria.dimension_3.do_symptoms_cause_moderate_difficulty_in_role_functioning"
            />
            <Checkbox
              label="Frequent and/or intense symptoms with a history of significant problems that are not well stabilized, but not imminently dangerous"
              model="data.asam_criteria.dimension_3.has_frequent_or_intense_symptoms_and_history_of_not_imminently_dangerous_significant_problems"
            />
            <Checkbox
              label="Emotional/behavioral/cognitive problems/symptoms distract from recovery efforts"
              model="data.asam_criteria.dimension_3.do_emotional_behavioral_cognitive_problems_or_symptoms_distract_from_recovery_efforts"
            />
            <Checkbox
              label="Problems with attention or distractibility interfere with recovery efforts"
              model="data.asam_criteria.dimension_3.does_distractibility_interfere_with_recovery_efforts"
            />
            <Checkbox
              label="History of non-adherence with required psychiatric medications"
              model="data.asam_criteria.dimension_3.has_history_of_non_adherence_with_required_psychiatric_medications"
            />
          </CheckboxGroup>

          <CheckboxGroup label="3 = substantial" layout="vertical-dense">
            <Checkbox
              label="Frequent impulses to harm self or others which are potentially destabilizing, but not imminently dangerous"
              model="data.asam_criteria.dimension_3.has_frequent_impulses_to_harm_not_imminently_dangerous"
            />
            <Checkbox
              label="Adequate impulse control to deal with thoughts of harm to self or others"
              model="data.asam_criteria.dimension_3.has_adequate_impulse_control_to_deal_with_thoughts_of_harm"
            />
            <Checkbox
              label="Uncontrolled behavior and cognitive deficits limit capacity for self-care, ADL’s"
              model="data.asam_criteria.dimension_3.has_uncontrolled_behavior_cognitive_deficits_limiting_self_care_capacity"
            />
            <Checkbox
              label="Acute symptoms dominate clinical presentation (e.g. impaired reality testing, communication, thought processes, judgment, personal hygiene, etc.) and significantly compromise community adjustment and follow through with treatment recommendations"
              model="data.asam_criteria.dimension_3.do_acute_symptoms_dominate_clinical_presentation"
            />
          </CheckboxGroup>

          <CheckboxGroup label="4 = severe" layout="vertical-dense">
            <Checkbox
              label="Individual has severe and unstable psychiatric symptoms and requires secure confinement"
              model="data.asam_criteria.dimension_3.has_severe_unstable_psychiatric_symptoms_requiring_secure_confinement"
            />
            <Checkbox
              label="Severe and acute psychotic symptoms that pose immediate danger to self or others (e.g. imminent risk of suicide; gross neglect of self-care; psychosis with unpredictable, disorganized, or violent behavior)"
              model="data.asam_criteria.dimension_3.has_severe_acute_psychotic_symptoms_that_pose_immediate_danger"
            />
            <Checkbox
              label="Recent history of psychiatric instability and/or escalating symptoms requiring high intensity services to prevent dangerous consequences"
              model="data.asam_criteria.dimension_3.has_recent_history_of_psychiatric_instability_requiring_high_intensity_services"
            />
          </CheckboxGroup>

          <SmartTextarea useDictation label="Notes" model="data.asam_criteria.dimension_3.notes" />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Dimension 4: Readiness to Change"
        scrollview={{
          id: 'dimension_4',
          name: 'Dimension 4: Readiness to Change',
          parent: { id: 'asam_criteria', name: 'ASAM Criteria' },
        }}
        commentsModel="data.asam_criteria.dimension_4"
      >
        <FormSection>
          <RadioGroup
            label="The patient continues to demonstrate a need for engagement and motivations enhancement that can be provided effectively only at the present level of care."
            layout="horizontal-dense"
            model="data.asam_criteria.dimension_4.demonstrates_need_for_engagement_and_motivations_enhancement"
          >
            <Radio label="True" value={true} />
            <Radio label="False" value={false} />
          </RadioGroup>

          <CheckboxGroup label="0 = no problem or stable" layout="vertical-dense">
            <Checkbox
              label="Willingly engaged in treatment as a proactive participant, is aware of/admits to having an addiction problem and is committed to addiction treatment and changing substance use and adherence with psychiatric medications"
              model="data.asam_criteria.dimension_4.is_willingly_engaged_in_treatment"
            />
            <Checkbox
              label="Can articulate personal recovery goals"
              model="data.asam_criteria.dimension_4.can_articulate_personal_recovery_goals"
            />
            <Checkbox
              label="Willing to cut negative influences"
              model="data.asam_criteria.dimension_4.is_willing_to_cut_negative_influences"
            />
            <Checkbox
              label="Is in Preparation or Action Transtheoretical Stage of Change"
              model="data.asam_criteria.dimension_4.is_in_preparation_or_action_transtheoretical_stage_of_change"
            />
          </CheckboxGroup>

          <CheckboxGroup label="1 = mild" layout="vertical-dense">
            <Checkbox
              label="Willing to enter treatment and explore strategies for changing AODA use or dealing with mental health disorder but is ambivalent about need for change ( is in Contemplation Stage of Change)"
              model="data.asam_criteria.dimension_4.is_willing_to_enter_treatment"
            />
            <Checkbox
              label="Willing to explore the need for treatment and strategies to reduce or stop substance use"
              model="data.asam_criteria.dimension_4.is_willing_to_explore_need_for_treatment"
            />
            <Checkbox
              label="Willing to change AODA use but believes it will not be difficult or will not accept a full recovery treatment plan or does not recognize that he/she has a substance use problem"
              model="data.asam_criteria.dimension_4.is_willing_to_change_aoda"
            />
          </CheckboxGroup>

          <CheckboxGroup label="2 = moderate" layout="vertical-dense">
            <Checkbox
              label="Reluctant to agree to treatment for substance use or mental health problems but willing to be compliant to avoid negative consequences or may be legally required to engage in treatment"
              model="data.asam_criteria.dimension_4.is_reluctant_to_substance_use_or_mental_health_problems_treatment"
            />
            <Checkbox
              label="Able to articulate negative consequences of AODA use but has low commitment to change use of substances"
              model="data.asam_criteria.dimension_4.is_able_to_articulate_negative_consequences_of_aoda"
            />
            <Checkbox
              label="Low readiness to change and is only passively involved in treatment"
              model="data.asam_criteria.dimension_4.has_low_readiness_to_change"
            />
            <Checkbox
              label="Variably compliant with outpatient treatment, self help or other support groups"
              model="data.asam_criteria.dimension_4.is_variably_compliant_with_outpatient_treatment"
            />
          </CheckboxGroup>

          <CheckboxGroup label="3 = substantial" layout="vertical-dense">
            <Checkbox
              label="Exhibits inconsistent follow through and shows minimal awareness of AODA or mental health disorder and need for treatment"
              model="data.asam_criteria.dimension_4.shows_inconsistent_follow_through_and_minimal_awareness_of_aoda"
            />
            <Checkbox
              label="Appears unaware of need to change and unwilling or only partially able to follow through with treatment recommendations"
              model="data.asam_criteria.dimension_4.is_unaware_of_need_to_change"
            />
          </CheckboxGroup>

          <CheckboxGroup label="4 = severe" layout="vertical-dense">
            <Checkbox
              label="Unable to follow through, has little or no awareness of substance use or mental health problems and associated negative consequences"
              model="data.asam_criteria.dimension_4.has_little_substance_use_or_mental_health_problems_awareness"
            />
            <Checkbox
              label="Not willing to explore change and is in denial regarding illness and its implications"
              model="data.asam_criteria.dimension_4.is_unwilling_to_explore_change"
            />
            <Checkbox
              label="Is not in imminent danger or unable to care for self – no immediate action required"
              model="data.asam_criteria.dimension_4.is_not_in_imminent_danger"
            />
            <Checkbox
              label="Unable to follow through with treatment recommendations resulting in imminent danger of harm to self/others or inability to care for self"
              model="data.asam_criteria.dimension_4.is_unable_to_follow_through_with_treatment_recommendations"
            />
          </CheckboxGroup>

          <SmartTextarea useDictation label="Notes" model="data.asam_criteria.dimension_4.notes" />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Dimension 5: Relapse/Continued Use/ Continued Problem Potential"
        scrollview={{
          id: 'dimension_5',
          name: 'Dimension 5: Relapse/Continued Use/ Continued Problem Potential',
          parent: { id: 'asam_criteria', name: 'ASAM Criteria' },
        }}
        commentsModel="data.asam_criteria.dimension_5"
      >
        <FormSection>
          <RadioGroup
            label="The patient continues to demonstrate a problem, or has developed a new problem that requires coping skills to prevent relapse, continued use or continued problems. These strategies can be provided effectively only at the present level of care."
            layout="horizontal-dense"
            model="data.asam_criteria.dimension_5.does_require_coping_skills_to_avoid_relapse"
          >
            <Radio label="True" value={true} />
            <Radio label="False" value={false} />
          </RadioGroup>

          <CheckboxGroup label="0 = no problem or stable" layout="vertical-dense">
            <Checkbox
              label="No potential for further AODA or MH problems"
              model="data.asam_criteria.dimension_5.no_potential_for_further_aoda_or_mh_problems"
            />
            <Checkbox
              label="Low relapse or continued use potential and good coping skills"
              model="data.asam_criteria.dimension_5.low_relapse_or_continued_use_potential_and_good_coping_skills"
            />
            <Checkbox
              label="Is engaged with ongoing recovery/support groups"
              model="data.asam_criteria.dimension_5.is_engaged_with_ongoing_recovery_support_groups"
            />
            <Checkbox
              label="Has positive expectancies about treatment"
              model="data.asam_criteria.dimension_5.has_positive_expectancies_about_treatment"
            />
            <Checkbox label="No use of illicit drugs" model="data.asam_criteria.dimension_5.no_use_of_illicit_drugs" />
            <Checkbox
              label="Has no demographic risk factor (under 25 years of age, never married or having lived as married, unemployed, no high school diploma or GED)"
              model="data.asam_criteria.dimension_5.has_no_demographic_risk_factor"
            />
            <Checkbox label="No current craving" model="data.asam_criteria.dimension_5.has_no_current_craving" />
            <Checkbox label="No impulsivity noted" model="data.asam_criteria.dimension_5.no_impulsivity_noted" />
            <Checkbox label="Appropriately self-confident" model="data.asam_criteria.dimension_5.appropriately_self_confident" />
            <Checkbox label="Not risk-taking or thrill-seeking" model="data.asam_criteria.dimension_5.not_risk_taking_or_thrill_seeking" />
            <Checkbox
              label="No psychiatric medication required or adherent with psychiatric medications"
              model="data.asam_criteria.dimension_5.no_psychiatric_medication_required"
            />
          </CheckboxGroup>

          <CheckboxGroup label="1 = mild" layout="vertical-dense">
            <Checkbox
              label="Minimal relapse potential with some vulnerability"
              model="data.asam_criteria.dimension_5.minimal_relapse_potential_with_some_vulnerability"
            />
            <Checkbox
              label="Some craving with ability to resist"
              model="data.asam_criteria.dimension_5.some_craving_with_ability_to_resist"
            />
            <Checkbox
              label="One or two changeable demographic risk factors"
              model="data.asam_criteria.dimension_5.one_or_two_changeable_demographic_risk_factors"
            />
            <Checkbox
              label="Marginally affected by external influences"
              model="data.asam_criteria.dimension_5.marginally_affected_by_external_influences"
            />
            <Checkbox label="Mostly non-impulsive" model="data.asam_criteria.dimension_5.mostly_non_impulsive" />
            <Checkbox label="Mostly confident" model="data.asam_criteria.dimension_5.mostly_confident" />
            <Checkbox
              label="Low level of risk-taking or thrill-seeking"
              model="data.asam_criteria.dimension_5.low_level_of_risk_taking_or_thrill_seeking"
            />
            <Checkbox
              label="Fair self-management and relapse prevention skills"
              model="data.asam_criteria.dimension_5.fair_self_management_and_relapse_prevention_skills"
            />
            <Checkbox
              label="Needs support and counseling to maintain abstinence, deal with craving, peer pressure, and lifestyle and attitude changes"
              model="data.asam_criteria.dimension_5.needs_support_and_counseling_to_maintain_abstinence"
            />
            <Checkbox
              label="Mostly adherent with prescribed psychiatric medications"
              model="data.asam_criteria.dimension_5.mostly_adherent_with_prescribed_psychiatric_medications"
            />
            <Checkbox label="Episodic use of alcohol (less than weekly)" model="data.asam_criteria.dimension_5.episodic_use_of_alcohol" />
            <Checkbox label="Sporadic use of drugs (<1/week), not injected" model="data.asam_criteria.dimension_5.sporadic_use_of_drugs" />
          </CheckboxGroup>

          <CheckboxGroup label="2 = moderate" layout="vertical-dense">
            <Checkbox
              label="Impaired recognition and understanding of substance use relapse issues"
              model="data.asam_criteria.dimension_5.has_impaired_understanding_of_substance_use_relapse_issues"
            />
            <Checkbox
              label="Difficulty maintaining abstinence despite engagement in treatment"
              model="data.asam_criteria.dimension_5.has_difficulty_maintaining_abstinence"
            />
            <Checkbox
              label="Able to self-manage with prompting"
              model="data.asam_criteria.dimension_5.is_able_to_self_manage_with_prompting"
            />
            <Checkbox
              label="Some craving with minimal/sporadic ability to resist"
              model="data.asam_criteria.dimension_5.has_some_craving_with_minimal_sporadic_ability_to_resist"
            />
            <Checkbox
              label="One or two durable demographic risk factors"
              model="data.asam_criteria.dimension_5.has_one_or_two_durable_demographic_risk_factors"
            />
            <Checkbox
              label="Moderately affected by external influences"
              model="data.asam_criteria.dimension_5.is_moderately_affected_by_external_influences"
            />
            <Checkbox label="Neither-impulsive nor deliberate" model="data.asam_criteria.dimension_5.is_neither_impulsive_nor_deliberate" />
            <Checkbox
              label="Uncertain about ability to recover or ambivalent"
              model="data.asam_criteria.dimension_5.is_uncertain_about_ability_to_recover"
            />
            <Checkbox
              label="Moderate level of risk-taking or thrill-seeking"
              model="data.asam_criteria.dimension_5.has_moderate_level_of_risk_taking"
            />
            <Checkbox
              label="Mostly adherent with prescribed psychiatric medications with failure likely to result in moderate to severe problems"
              model="data.asam_criteria.dimension_5.is_mostly_adherent_with_prescribed_psychiatric_medications"
            />
            <Checkbox
              label="Regular use of alcohol (once or twice a week)"
              model="data.asam_criteria.dimension_5.has_regular_use_of_alcohol"
            />
            <Checkbox
              label="Moderate use of drugs (1-3X/week), not injected"
              model="data.asam_criteria.dimension_5.has_moderate_use_of_drugs"
            />
          </CheckboxGroup>

          <CheckboxGroup label="3 = substantial" layout="vertical-dense">
            <Checkbox
              label="Little recognition and understanding of substance use relapse"
              model="data.asam_criteria.dimension_5.has_little_understanding_of_substance_use_relapse"
            />
            <Checkbox
              label="Has poor skills to cope with and interrupt addiction problems, or to avoid or limit relapse or continued use"
              model="data.asam_criteria.dimension_5.has_poor_skills_to_cope_with_and_interrupt_addiction_problems"
            />
            <Checkbox
              label="Severe craving with minimal/sporadic ability to resist"
              model="data.asam_criteria.dimension_5.has_severe_craving"
            />
            <Checkbox label="Three demographic risk factors" model="data.asam_criteria.dimension_5.three_demographic_risk_factors" />
            <Checkbox
              label="Substantially affected by external influences"
              model="data.asam_criteria.dimension_5.is_substantially_affected_by_external_influences"
            />
            <Checkbox label="Somewhat impulsive" model="data.asam_criteria.dimension_5.is_somewhat_impulsive" />
            <Checkbox label="Dubious about ability to recover" model="data.asam_criteria.dimension_5.is_dubious_about_ability_to_recover" />
            <Checkbox
              label="High level of risk-taking or thrill-seeking"
              model="data.asam_criteria.dimension_5.has_high_level_of_risk_taking"
            />
            <Checkbox
              label="Mostly non-adherent with prescribed psychiatric medications with failure likely to result in moderate to severe problems"
              model="data.asam_criteria.dimension_5.is_mostly_non_adherent_with_prescribed_psychiatric_medications"
            />
            <Checkbox
              label="Frequent use of alcohol (3 or more times a week)"
              model="data.asam_criteria.dimension_5.frequent_use_of_alcohol"
            />
            <Checkbox
              label="Frequent use of drugs (more than 3X/week) and/or smoking drugs"
              model="data.asam_criteria.dimension_5.frequent_use_of_drugs"
            />
          </CheckboxGroup>

          <CheckboxGroup label="4 = severe" layout="vertical-dense">
            <Checkbox
              label="Repeated treatment episodes had little positive effect on functioning"
              model="data.asam_criteria.dimension_5.had_little_positive_functioning_effect_from_repeated_treatment_episodes"
            />
            <Checkbox
              label="No skills to cope with and interrupt addiction problems or prevent/limit relapse or continued use"
              model="data.asam_criteria.dimension_5.has_no_skills_to_cope_with_addiction_problems"
            />
            <Checkbox
              label="Severe craving with no ability to resist"
              model="data.asam_criteria.dimension_5.has_severe_craving_with_no_ability_to_resist"
            />
            <Checkbox
              label="Four or more significant demographic risks"
              model="data.asam_criteria.dimension_5.four_or_more_significant_demographic_risks"
            />
            <Checkbox label="Totally outer-directed" model="data.asam_criteria.dimension_5.is_totally_outer_directed" />
            <Checkbox label="Very impulsive" model="data.asam_criteria.dimension_5.is_very_impulsive" />
            <Checkbox
              label="Very pessimistic or inappropriately confident about ability to recover but is not in imminent danger or unable to care for self – no immediate action required"
              model="data.asam_criteria.dimension_5.is_very_pessimistic_about_ability_to_recover"
            />
            <Checkbox
              label="Dangerous level of risk-taking or thrill-seeking"
              model="data.asam_criteria.dimension_5.has_dangerous_level_of_risk_taking"
            />
            <Checkbox
              label="Not at all adherent with prescribed psychiatric medications with failure likely to result in severe problems"
              model="data.asam_criteria.dimension_5.has_no_adherence_with_prescribed_psychiatric_medications"
            />
            <Checkbox label="Daily intoxication" model="data.asam_criteria.dimension_5.daily_intoxication" />
            <Checkbox
              label="Daily use of illicit drugs and/or IV drug use"
              model="data.asam_criteria.dimension_5.daily_use_of_illicit_drugs"
            />
            <Checkbox
              label="Is in imminent danger or unable to care for self"
              model="data.asam_criteria.dimension_5.is_in_imminent_danger"
            />
          </CheckboxGroup>

          <SmartTextarea useDictation label="Notes" model="data.asam_criteria.dimension_5.notes" />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Dimension 6: Recovery Environment"
        scrollview={{
          id: 'dimension_6',
          name: 'Dimension 6: Recovery Environment',
          parent: { id: 'asam_criteria', name: 'ASAM Criteria' },
        }}
        commentsModel="data.asam_criteria.dimension_6"
      >
        <FormSection>
          <RadioGroup
            label="The patient continues to demonstrate a problem in his or her recovery environment, or has a new problem that requires coping skills and support system interventions. These interventions can be provided effectively only at the present level of care."
            layout="horizontal-dense"
            model="data.asam_criteria.dimension_6.does_require_support_system_interventions"
          >
            <Radio label="True" value={true} />
            <Radio label="False" value={false} />
          </RadioGroup>

          <CheckboxGroup label="0 = no problem or stable" layout="vertical-dense">
            <Checkbox
              label="Has a supportive environment or is able to cope with poor supports"
              model="data.asam_criteria.dimension_6.has_a_supportive_environment"
            />
            <Checkbox label="Living in a dry, drug-free home" model="data.asam_criteria.dimension_6.is_living_in_a_dry_drug_free_home" />
            <Checkbox
              label="Few liquor outlets/no overt drug dealing"
              model="data.asam_criteria.dimension_6.few_liquor_outlets_no_overt_drug_dealing"
            />
            <Checkbox
              label="Subcultural norms strongly discourage abusive use"
              model="data.asam_criteria.dimension_6.subcultural_norms_strongly_discourage_abusive_use"
            />
            <Checkbox
              label="Positive leisure/recreational activities not associated with use"
              model="data.asam_criteria.dimension_6.has_positive_recreational_activities"
            />
            <Checkbox
              label="No risk for emotional, physical or sexual abuse"
              model="data.asam_criteria.dimension_6.has_no_risk_for_emotional_physical_or_sexual_abuse"
            />
            <Checkbox
              label="No logistical barriers to treatment or recovery"
              model="data.asam_criteria.dimension_6.has_no_logistical_barriers_to_treatment_or_recovery"
            />
          </CheckboxGroup>

          <CheckboxGroup label="1 = mild" layout="vertical-dense">
            <Checkbox
              label="Has passive support in environment; family/significant other support system need to learn techniques to support the individual’s recovery effort (e.g. limit setting, communication skills, etc.)"
              model="data.asam_criteria.dimension_6.has_has_passive_support_in_environment"
            />
            <Checkbox
              label="Significant others are not interested in supporting addiction recovery, but individual is not too distracted by this situation, and is able to cope with the environment"
              model="data.asam_criteria.dimension_6.significant_others_are_not_interested_in_supporting_addiction_recovery"
            />
            <Checkbox
              label="Individual demonstrates motivation and willingness to obtain a positive social support system"
              model="data.asam_criteria.dimension_6.is_willing_to_obtain_positive_social_support_system"
            />
            <Checkbox
              label="Safe supportive living situation in a non-dry or non drug-free home"
              model="data.asam_criteria.dimension_6.has_safe_supportive_living_situation"
            />
            <Checkbox label="Alcohol & drugs readily obtainable" model="data.asam_criteria.dimension_6.alcohol_drugs_readily_obtainable" />
            <Checkbox
              label="Subcultural norms discourage abusive use"
              model="data.asam_criteria.dimension_6.subcultural_norms_discourage_abusive_use"
            />
            <Checkbox
              label="Leisure/recreational activities conducive to recovery available"
              model="data.asam_criteria.dimension_6.recreational_activities_conducive_to_recovery_available"
            />
            <Checkbox
              label="Some risk for emotional, physical or sexual abuse"
              model="data.asam_criteria.dimension_6.has_some_risk_for_emotional_physical_or_sexual_abuse"
            />
            <Checkbox
              label="Logistical barriers to treatment or recovery can be readily overcome"
              model="data.asam_criteria.dimension_6.logistical_treatment_barriers_can_be_readily_overcome"
            />
          </CheckboxGroup>

          <CheckboxGroup label="2 = moderate" layout="vertical-dense">
            <Checkbox
              label="Environment is not supportive of addiction recovery, but with clinical structure, individual is able to cope most of the time"
              model="data.asam_criteria.dimension_6.environment_is_not_supportive_of_addiction_recovery"
            />
            <Checkbox label="Living alone" model="data.asam_criteria.dimension_6.is_living_alone" />
            <Checkbox
              label="Ready access to alcohol & drugs near home"
              model="data.asam_criteria.dimension_6.has_ready_access_to_alcohol_drugs_near_home"
            />
            <Checkbox
              label="Subcultural norms inconsistent about abusive use"
              model="data.asam_criteria.dimension_6.subcultural_norms_inconsistent_about_abusive_use"
            />
            <Checkbox
              label="Leisure/recreational activities neutral for recovery"
              model="data.asam_criteria.dimension_6.recreational_activities_neutral_for_recovery"
            />
            <Checkbox
              label="Above average risk for emotional, physical or sexual abuse"
              model="data.asam_criteria.dimension_6.has_above_average_risk_for_emotional_physical_or_sexual_abuse"
            />
            <Checkbox
              label="Logistical barriers to treatment or recovery serious but resolvable"
              model="data.asam_criteria.dimension_6.logistical_treatment_barriers_serious_but_resolvable"
            />
          </CheckboxGroup>

          <CheckboxGroup label="3 = substantial" layout="vertical-dense">
            <Checkbox
              label="Environment is not supportive of addiction recovery, and coping is difficult, even with clinical structure"
              model="data.asam_criteria.dimension_6.environment_not_supportive_of_addiction_recovery"
            />
            <Checkbox
              label="Someone in the household currently dependent or abusing"
              model="data.asam_criteria.dimension_6.someone_in_the_household_currently_dependent_or_abusing"
            />
            <Checkbox
              label="Bars/liquor stores/dealers prevalent"
              model="data.asam_criteria.dimension_6.bars_liquor_stores_dealers_prevalent"
            />
            <Checkbox
              label="Subcultural norms encourage abusive use"
              model="data.asam_criteria.dimension_6.subcultural_norms_encourage_abusive_use"
            />
            <Checkbox
              label="Alcohol and drugs readily available at preferred leisure/recreational activities"
              model="data.asam_criteria.dimension_6.alcohol_and_drugs_readily_available"
            />
            <Checkbox
              label="Substantial risk for emotional, physical or sexual abuse in current environment"
              model="data.asam_criteria.dimension_6.substantial_risk_for_emotional_physical_sexual_abuse"
            />
            <Checkbox
              label="Substantial logistical impediments to treatment or recovery"
              model="data.asam_criteria.dimension_6.substantial_logistical_impediments_to_treatment_or_recovery"
            />
          </CheckboxGroup>

          <CheckboxGroup label="4 = severe" layout="vertical-dense">
            <Checkbox
              label="Environment is not supportive of addiction recovery and treatment progress"
              model="data.asam_criteria.dimension_6.environment_not_supportive_of_addiction_recovery_treatment_progress"
            />
            <Checkbox
              label="Unstable residence, living in shelter or mission, homeless"
              model="data.asam_criteria.dimension_6.unstable_residence_living_in_shelter_or_mission_homeless"
            />
            <Checkbox
              label="Extensive drug dealing/solicitation is hostile and toxic to recovery or"
              model="data.asam_criteria.dimension_6.extensive_drug_dealing_solicitation_is_hostile"
            />
            <Checkbox
              label="Subcultural norms strongly encourage abusive use"
              model="data.asam_criteria.dimension_6.subcultural_norms_strongly_encourage_abusive_use"
            />
            <Checkbox
              label="Leisure/recreational activities poise severe risks"
              model="data.asam_criteria.dimension_6.recreational_activities_poise_severe_risks"
            />
            <Checkbox
              label="Currently being emotionally, physically or sexually abused"
              model="data.asam_criteria.dimension_6.is_currently_emotionally_physically_or_sexually_abused"
            />
            <Checkbox
              label="Extreme logistical impediments to treatment or recovery"
              model="data.asam_criteria.dimension_6.has_extreme_logistical_impediments_to_treatment_or_recovery"
            />
            <Checkbox
              label="Unable to cope with negative effects of the living environment on recovery - no immediate action required"
              model="data.asam_criteria.dimension_6.is_unable_to_cope_with_negative_effects_of_living_environment"
            />
            <Checkbox
              label="Environment is not supportive of addiction recovery, and is actively hostile to recovery, posing an immediate threat to safety and well-being - immediate action required"
              model="data.asam_criteria.dimension_6.environment_not_supportive_of_addiction_recovery_is_actively_hostile_to_recovery"
            />
          </CheckboxGroup>

          <SmartTextarea useDictation label="Notes" model="data.asam_criteria.dimension_6.notes" />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Recommendation"
        scrollview={{
          id: 'recommendation',
          name: 'Recommendation',
        }}
        commentsModel="data.asam_criteria.recommendation"
      >
        <FormSection>
          <RadioGroup layout="vertical-dense" model="data.asam_criteria.recommendation.criteria">
            <Radio label="Patient meets criteria for admission" value="admission" />
            <Radio label="Patient meets criteria for continued stay" value="continued_stay" />
            <Radio label="Patient meets criteria for transfer/discharge" value="transfer_discharge" />
          </RadioGroup>

          <ContextShow when="data.asam_criteria.recommendation.criteria" is="transfer_discharge">
            <RadioGroup layout="vertical-dense" model="data.asam_criteria.transfer_discharge_info">
              <Radio
                label="The patient has achieved the goals in their individualized treatment plan, thus resolving the problem(s) that justified admission to the present level of care. Continuing the chronic disease management of the patient's condition at a less intensive level of care is indicated."
                value="did_achieve_treatment_plan_goals"
              />
              <Radio
                label="The patient has been unable to resolve the problem(s) that justified admission to the present level of care, despite amendments to the treatment plan.  The patient is determined to have achieved the maximum possible benefit from engagement in services at the current level of care (more or less intensive) in the same type of service, or discharge from treatment is indicated."
                value="did_not_resolve_problems"
              />
              <Radio
                label="The patient has demonstrated a lack of capacity due to diagnostic or co-occurring conditions that limit his or her ability to resolve his or her problem(s).  Treatment at a qualitatively different level of care or type of service, or discharge from treatment is therefore indicated."
                value="did_demonstrate_lack_of_capacity"
              />
              <Radio
                label="The patient has experienced an intensification of his or her problem(s), or has developed a new problem(s) and can be treated effectively only at a  more intensive level of care."
                value="did_experience_problems_intensification"
              />
            </RadioGroup>
          </ContextShow>

          <SmartTextarea useDictation label="Notes" model="data.asam_criteria.recommendation.notes" />
        </FormSection>
      </Section>
    </>
  )
}

export default ASAMCriteria
