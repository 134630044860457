import React from 'react'

import DateInput from '../../../components/Forms/DateInput'
import Divider from '../../../components/Divider'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import Input from '../../../components/Forms/Input'
import Label from '../../../components/Label'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Textarea from '../../../components/Forms/Textarea'

export const CLIENT_HEALTH_QUESTIONNAIRE_SECTIONS = [
  {
    title: 'Scoring Key',
    model: 'scoring_key',
    fields: [
      {
        label: 'Scoring Key',
        model: 'scoring_key',
        component: () => (
          <>
            <p>
              This self-administered questionnaire is designed to provide programs with a set of general guidelines to assist in determining
              an individual’s <b>suitability for treatment/recovery services in a non-medical facility</b>. It is intended as a guideline
              only and should not be substituted for common sense or any other available data which contradicts this questionnaire. When in
              doubt, always consider the severity of the issue and, above all, the well-being of the client. The potential value of a
              thorough Health Screening administered by a nurse practitioner or physician should never be underestimated.
            </p>

            <p>
              The high incidence of illness at time of admission to a program calls for caution and attention to detail. No client can
              benefit from a program if he or she is too ill to participate fully. Conversely, no program can succeed if its clients are
              unable to utilize the services offered.
            </p>

            <h3>Section 1</h3>

            <p>
              A <b>yes</b> answer to any of the questions in section 1 indicates the existence of a potentially life threatening condition.
              You should strongly consider referring the individual to a qualified physician, requesting that they provide you with a
              medical clearance to participate in a program. Enrollment in the program prior to receiving a medical clearance is at the
              discretion of the program.
            </p>

            <h3>Section 2</h3>

            <p>
              A <b>yes</b> answer to any of the questions in section 2 indicates the existence of a serious health condition. Although
              admission into your program may be appropriate, a thorough Health Screening should be scheduled at the time of admission.
              Continuing participation in the program should be at the discretion of program.
            </p>

            <h3>Section 3</h3>

            <p>
              A <b>yes</b> answer to any of the questions in section 3 does not necessarily indicate the existence of a serious health
              condition. However, <b>multiple yes</b> answers could be cause for concern and indicative of a generally poor health
              condition. Multiple yes answers in section 3 may warrant a Health Screening. At a minimum information gathered in section 3
              should be available to staff in order to better serve the client.
            </p>
          </>
        ),
      },
    ],
  },
  {
    title: 'Section 1',
    model: 'section_1',
    fields: [
      {
        label:
          'Does the client have any serious health problems or illnesses (such as tuberculosis or active pneumonia) that may be contagious to others around them? If yes, please give details.',
        model: 'q1',
        component: () => (
          <>
            <RadioGroup
              label="Does the client have any serious health problems or illnesses (such as tuberculosis or active pneumonia) that may be contagious to others around them? If yes, please give details."
              model="data.q1"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q1_date" />
            <Textarea useQuickText label="Details" model="data.q1_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Has the client ever had a stroke? If yes, please give details.',
        model: 'q2',
        component: () => (
          <>
            <RadioGroup label="Has the client ever had a stroke? If yes, please give details." model="data.q2" layout="horizontal-dense">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q2_date" />
            <Textarea useQuickText label="Details" model="data.q2_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Has the client ever had a head injury that resulted in a period of loss of consciousness? If yes, please give details.',
        model: 'q3',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever had a head injury that resulted in a period of loss of consciousness? If yes, please give details."
              model="data.q3"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q3_date" />
            <Textarea useQuickText label="Details" model="data.q3_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Has the client ever had any form of seizures, delirium tremens or convulsions? If yes, please give details.',
        model: 'q4',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever had any form of seizures, delirium tremens or convulsions? If yes, please give details."
              model="data.q4"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q4_date" />
            <Textarea useQuickText label="Details" model="data.q4_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Has the client experienced or suffered any chest pains? If yes, please give details.',
        model: 'q5',
        component: () => (
          <>
            <RadioGroup
              label="Has the client experienced or suffered any chest pains? If yes, please give details."
              model="data.q5"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q5_date" />
            <Textarea useQuickText label="Details" model="data.q5_details" />
          </>
        ),
      },
    ],
  },
  {
    title: 'Section 2',
    model: 'section_2',
    fields: [
      {
        label: 'Has the client ever had a heart attack or any problem associated with the heart? If yes, please give details.',
        model: 'q6',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever had a heart attack or any problem associated with the heart? If yes, please give details."
              model="data.q6"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q6_date" />
            <Textarea useQuickText label="Details" model="data.q6_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Does the client take any medications for a heart condition? If yes, please give details.',
        model: 'q7',
        component: () => (
          <>
            <RadioGroup
              label="Does the client take any medications for a heart condition? If yes, please give details."
              model="data.q7"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q7_date" />
            <Textarea useQuickText label="Details" model="data.q7_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Has the client ever had blood clots in the legs or elsewhere that required medical attention? If yes, please give details.',
        model: 'q8',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever had blood clots in the legs or elsewhere that required medical attention? If yes, please give details."
              model="data.q8"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q8_date" />
            <Textarea useQuickText label="Details" model="data.q8_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Has the client ever had high-blood pressure or hypertension? If yes, please give details.',
        model: 'q9',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever had high-blood pressure or hypertension? If yes, please give details."
              model="data.q9"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q9_date" />
            <Textarea useQuickText label="Details" model="data.q9_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Does the client have a history of cancer? If yes, please give details.',
        model: 'q10',
        component: () => (
          <>
            <RadioGroup
              label="Does the client have a history of cancer? If yes, please give details."
              model="data.q10"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q10_date" />
            <Textarea useQuickText label="Details" model="data.q10_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label:
          'Does the client have a history of any other illness that may require frequent medical attention? If yes, please give details.',
        model: 'q11',
        component: () => (
          <>
            <RadioGroup
              label="Does the client have a history of any other illness that may require frequent medical attention? If yes, please give details."
              model="data.q11"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q11_date" />
            <Textarea useQuickText label="Details" model="data.q11_details" />
          </>
        ),
      },
    ],
  },
  {
    title: 'Section 3',
    model: 'section_3',
    fields: [
      {
        label:
          'Does the client have any allergies to medications, foods, animals, chemicals, or any other substance. If yes, please give details.',
        model: 'q12',
        component: () => (
          <>
            <RadioGroup
              label="Does the client have any allergies to medications, foods, animals, chemicals, or any other substance. If yes, please give details."
              model="data.q12"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q12_date" />
            <Textarea useQuickText label="Details" model="data.q12_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label:
          'Has the client ever had an ulcer, gallstones, internal bleeding, or any type of bowel or colon inflammation? If yes, please give details.',
        model: 'q13',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever had an ulcer, gallstones, internal bleeding, or any type of bowel or colon inflammation? If yes, please give details."
              model="data.q13"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q13_date" />
            <Textarea useQuickText label="Details" model="data.q13_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label:
          'Has the client ever been diagnosed with diabetes? If yes, please give details, including insulin, oral medications, or special diet.',
        model: 'q14',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever been diagnosed with diabetes? If yes, please give details, including insulin, oral medications, or special diet."
              model="data.q14"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q14_date" />
            <Textarea useQuickText label="Details" model="data.q14_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Has the client ever been diagnosed with any type of hepatitis or other liver illness? If yes, please give details.',
        model: 'q15',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever been diagnosed with any type of hepatitis or other liver illness? If yes, please give details."
              model="data.q15"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q15_date" />
            <Textarea useQuickText label="Details" model="data.q15_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label:
          'Has the client ever reported problems with their thyroid gland, been treated for it, or told they need to be treated for it, or any other type of glandular disease? If yes, please give details.',
        model: 'q16',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever reported problems with their thyroid gland, been treated for it, or told they need to be treated for it, or any other type of glandular disease? If yes, please give details."
              model="data.q16"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q16_date" />
            <Textarea useQuickText label="Details" model="data.q16_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label:
          'Does the client currently have any lung diseases such as asthma, emphysema, or chronic bronchitis? If yes,please give details.',
        model: 'q17',
        component: () => (
          <>
            <RadioGroup
              label="Does the client currently have any lung diseases such as asthma, emphysema, or chronic bronchitis? If yes,please give details."
              model="data.q17"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q17_date" />
            <Textarea useQuickText label="Details" model="data.q17_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label:
          'Has the client ever had kidney stones or kidney infections, or had problems, or been told they have problems with their kidneys or bladder? If yes, please give details.',
        model: 'q18',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever had kidney stones or kidney infections, or had problems, or been told they have problems with their kidneys or bladder? If yes, please give details."
              model="data.q18"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q18_date" />
            <Textarea useQuickText label="Details" model="data.q18_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label:
          'Does the client have any of the following; arthritis, back problems, bone injuries, muscle injuries, or joint injuries? If yes,please give details, including any ongoing pain or disabilities.',
        model: 'q19',
        component: () => (
          <>
            <RadioGroup
              label="Does the client have any of the following; arthritis, back problems, bone injuries, muscle injuries, or joint injuries? If yes,please give details, including any ongoing pain or disabilities."
              model="data.q19"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q19_date" />
            <Textarea useQuickText label="Details" model="data.q19_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Please describe any surgeries or hospitalizations due to illness or injury that the client has had.',
        model: 'q20',
        component: () => (
          <>
            <Textarea
              useQuickText
              label="Please describe any surgeries or hospitalizations due to illness or injury that the client has had."
              model="data.q20_details"
            />
            <DateInput label="Date" model="data.q20_date" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'When was the last time the client saw a physician? What was the purpose of the visit?',
        model: 'q21',
        component: () => (
          <>
            <Textarea
              useQuickText
              label="When was the last time the client saw a physician? What was the purpose of the visit?"
              model="data.q21_details"
            />
            <DateInput label="Date" model="data.q21_date" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label:
          'Does the client take any prescription medications including psychiatric medications? If yes, please list type(s) and dosage(s).',
        model: 'q22',
        component: () => (
          <>
            <RadioGroup
              label="Does the client take any prescription medications including psychiatric medications? If yes, please list type(s) and dosage(s)."
              model="data.q22"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q22_date" />
            <Textarea useQuickText label="Details" model="data.q22_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label:
          'Does the client take over-the-counter pain medications such as aspirin, Tylenol, or Ibuprofen? If yes, please list the medication(s) and how often they are taken.',
        model: 'q23',
        component: () => (
          <>
            <RadioGroup
              label="Does the client take over-the-counter pain medications such as aspirin, Tylenol, or Ibuprofen? If yes, please list the medication(s) and how often they are taken."
              model="data.q23"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q23_date" />
            <Textarea useQuickText label="Details" model="data.q23_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label:
          'Does the client take over-the-counter digestive medications such as Tums or Maalox? If yes, list the medication(s) and how often you take it.',
        model: 'q24',
        component: () => (
          <>
            <RadioGroup
              label="Does the client take over-the-counter digestive medications such as Tums or Maalox? If yes, list the medication(s) and how often you take it."
              model="data.q24"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q24_date" />
            <Textarea useQuickText label="Details" model="data.q24_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Does the client wear or need to wear glasses, contact lenses, or hearing aids? If yes, please give details.',
        model: 'q25',
        component: () => (
          <>
            <RadioGroup
              label="Does the client wear or need to wear glasses, contact lenses, or hearing aids? If yes, please give details."
              model="data.q25"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q25_date" />
            <Textarea useQuickText label="Details" model="data.q25_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: "When was the client's last dental exam?",
        model: 'q26',
        component: () => (
          <>
            <DateInput label="When was the client's last dental exam? Date:" model="data.q26_date" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Is the client in need of dental care? If yes, please give details.',
        model: 'q27',
        component: () => (
          <>
            <RadioGroup
              label="Is the client in need of dental care? If yes, please give details."
              model="data.q27"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q27_date" />
            <Textarea useQuickText label="Details" model="data.q27_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label:
          'Does the client wear or need to wear dentures or other dental appliances that may require dental care? If yes, please give details.',
        model: 'q28',
        component: () => (
          <>
            <RadioGroup
              label="Does the client wear or need to wear dentures or other dental appliances that may require dental care? If yes, please give details."
              model="data.q28"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Date" model="data.q28_date" />
            <Textarea useQuickText label="Details" model="data.q28_details" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'Is the client pregnant?',
        model: 'q29',
        component: () => (
          <>
            <RadioGroup label="Is the client pregnant?" model="data.q29" layout="horizontal-dense">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <DateInput label="Due Date" model="data.q29_date" />

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'In the past seven days, what types of drugs, including alcohol, has the client used?',
        model: 'q30',
        component: () => (
          <>
            <Label label="In the past seven days, what types of drugs, including alcohol, has the client used?" />

            <Grid gap="0.5rem">
              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q30_1" />
                <Input label="Route of Administration" model="data.q30_route_1" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q30_2" />
                <Input label="Route of Administration" model="data.q30_route_2" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q30_3" />
                <Input label="Route of Administration" model="data.q30_route_3" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q30_4" />
                <Input label="Route of Administration" model="data.q30_route_4" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q30_5" />
                <Input label="Route of Administration" model="data.q30_route_5" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q30_6" />
                <Input label="Route of Administration" model="data.q30_route_6" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q30_7" />
                <Input label="Route of Administration" model="data.q30_route_7" />
              </Flex>
            </Grid>

            <Divider className="!m-0" />
          </>
        ),
      },
      {
        label: 'In the past year, what types of drugs, including alcohol, has the client used?',
        model: 'q31',
        component: () => (
          <>
            <Label label="In the past year, what types of drugs, including alcohol, has the client used?" />

            <Grid gap="0.5rem">
              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q31_1" />
                <Input label="Route of Administration" model="data.q31_route_1" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q31_2" />
                <Input label="Route of Administration" model="data.q31_route_2" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q31_3" />
                <Input label="Route of Administration" model="data.q31_route_3" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q31_4" />
                <Input label="Route of Administration" model="data.q31_route_4" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q31_5" />
                <Input label="Route of Administration" model="data.q31_route_5" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q31_6" />
                <Input label="Route of Administration" model="data.q31_route_6" />
              </Flex>

              <Flex gap="0.5rem">
                <Input label="Type of Drug" model="data.q31_7" />
                <Input label="Route of Administration" model="data.q31_route_7" />
              </Flex>
            </Grid>
          </>
        ),
      },
    ],
  },
]
