import React from 'react'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import DnDUploader from '../../../components/DnDUploader'
import FilesTable from '../../../components/Tables/FilesTable'

import { ReferralColumn } from '../components/ReferralColumn'

export const Attachments = (props: any) => {
  const { client } = props

  return (
    <ReferralColumn icon={props.icon} title={props.title}>
      <Card>
        <FilesTable data={[]} />

        <CardContent>
          <DnDUploader category="general" queryKey={[]} resource={client} isEditable={true} isDisabled={false} />
        </CardContent>
      </Card>
    </ReferralColumn>
  )
}
