import React from 'react'

import Type from './Typography/Type'

type Props = {
  subtitle: string
} & React.HTMLAttributes<any>

const CardSubtitle: React.FC<Props> = ({ subtitle, className }) => {
  return (
    <Type as="span" className={className} variant="CARD_SUBTITLE">
      {subtitle}
    </Type>
  )
}

export default CardSubtitle
