import React from 'react'

import Button from '../Button'
import Link from '../Link'

import { COLORS } from '../../theme'

type Props = {
  className?: any
  css?: any
  label: string
  phone_no: string
}

const PhoneButton = ({ phone_no, className, ...rest }: Props) => {
  return (
    <Link href={`tel:${phone_no}`} className={className}>
      <Button type="default" glyph="phone" glyphColor={COLORS.green} {...rest} />
    </Link>
  )
}

export default PhoneButton
