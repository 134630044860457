import React from 'react'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { useGet } from '../../../hooks/useNewAPI'
import Glyph from '../../../components/Glyph'

import { FilterDropdown } from './FilterDropdown'
import { FilterDropdownValue } from './FilterDropdownValue'
import { PopoverItem } from './PopoverItem'

const CONDITIONS: any = {
  in: 'includes',
  not_in: 'not includes',
}

const CONDITION_GLYPHS: any = {
  in: 'between',
  not_in: 'not_equal',
}

export const SmartFlagsFilter = (props: any) => {
  const { config, onUpdate, filter, onClear } = props

  const initialCondition = Object.keys(CONDITIONS).includes(filter?.condition) ? filter?.condition : 'in'

  const [flagIds, setFlagIds]: any = React.useState(filter?.value || [])
  const [condition, setCondition] = React.useState(initialCondition)
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  const { data, isLoading }: any = useGet({
    name: ['smart-flags'],
    url: `/smart_flags`,
  })

  const isEmpty = size(data) === 0

  const activeFlags = React.useMemo(() => {
    const result: any = []
    const flagsMap: any = {}

    if (size(filter?.value) === 0 || size(data) === 0) return result

    for (const flag of data) {
      flagsMap[flag.id] = flag
    }

    for (const id of filter.value) {
      if (!flagsMap?.[id]) continue

      result.push(flagsMap[id])
    }

    return result
  }, [data, filter])

  const hasActiveFlags = size(activeFlags) >= 1

  React.useEffect(() => {
    if (onUpdate) onUpdate({ condition, value: flagIds })
  }, [condition, flagIds])

  return (
    <>
      <FilterDropdown label={CONDITIONS[condition]} open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        {Object.keys(CONDITIONS).map((key) => (
          <PopoverItem
            key={key}
            title={CONDITIONS[key]}
            isActive={condition === key}
            onClick={() => {
              setCondition(key)
              setIsDropdownOpen(false)
            }}
            graphic={<Glyph glyph={CONDITION_GLYPHS[key]} color={COLORS.textMuted} size={16} />}
          />
        ))}
      </FilterDropdown>

      <FilterDropdown
        isEmpty={isEmpty}
        isLoading={isLoading}
        activeValues={
          hasActiveFlags &&
          activeFlags?.map?.((flag) => (
            <FilterDropdownValue
              value={flag.name}
              graphic={<Glyph glyph={flag.glyph || 'flag'} size={14} color={flag.color || COLORS.textStronglyMuted} />}
            />
          ))
        }
      >
        {!isEmpty &&
          data.map((flag: any) => {
            return (
              <PopoverItem
                key={flag.id}
                title={flag.name}
                graphic={<Glyph glyph={flag.glyph || 'flag'} size={14} color={flag.color || COLORS.textStronglyMuted} />}
                isActive={flagIds.includes(flag.id)}
                onClick={() => {
                  setFlagIds((currentValue: any = {}) => {
                    return produce(currentValue, (draft: any) => {
                      if (draft.includes(flag.id)) {
                        const index = draft.indexOf(flag.id)
                        draft.splice(index, 1)
                        return
                      }

                      draft.push(flag.id)
                    })
                  })
                }}
              />
            )
          })}
      </FilterDropdown>
    </>
  )
}
