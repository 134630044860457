import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const STATUSES = {
  internal: {
    label: 'Internal Only',
    color: 'gray',
    infoTooltip: `This Organization is only visible to you. Submit a Partnership Request if you would like us to reach out and connect with them.`,
  },
  community_profile_request: {
    label: 'Partnership Requested',
    color: 'orange',
    infoTooltip: `We are currently reviewing your request. We'll reach out to this Organization in your name and invite them to connect with you. We will let you know about the status of your request as soon as we hear back from them. This Organization is only visible to you.`,
  },
  ready_to_partner: {
    label: 'Pending Invitation',
    color: 'blue',
    infoTooltip: `This Organization has an active Community Profile connection. Submit a Partnership Request or Invite to Partner Up to connect with them. This Organization is still only visible to you.`,
  },
  partner_invite_request: {
    label: 'Partnership Requested',
    color: 'orange',
    infoTooltip: `We are currently reviewing your request. We'll reach out to this Organization in your name and invite them to connect with you. We will let you know about the status of your request as soon as we hear back from them.`,
  },
  partner_invite_sent: {
    label: 'Invite Sent',
    color: 'orange',
    infoTooltip: `You've sent a Partner Up Invite to this Organization. We've notified them and waiting for their reply.`,
  },
  active_partnership: {
    label: 'Active Partnership',
    color: 'green',
    infoTooltip: `You are currently connected with this Organization.`,
  },
  inactive_partnership: {
    label: 'Inactive Partnership',
    color: 'gray',
    infoTooltip: `You are no longer connected with this Organization.`,
  },
}

export const EHROrganizationStatus = ({ ...rest }) => {
  return <SmartStatus statuses={STATUSES} {...rest} />
}
