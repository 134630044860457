import React from 'react'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { titleCase, niceAmount, countWord, usDateTime } from '../../utils/functions'
import { useGet, useCreate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../Button'
import Card from '../Card'
import ClaimTypeStatus from '../Statuses/ClaimTypeStatus'
import DataList from '../DataList'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Grid from '../Grid'
import GridTable from '../GridTable'
import Overlay from '../Overlay'
import OverlayLoader from '../OverlayLoader'
import Section from '../Section'
import State from '../State'

import InsuranceERAReportStatus from '../Statuses/InsuranceERAReportStatus'

const columns = [
  {
    name: 'Status',
    width: 'minmax(150px, 1fr)',
    accessor: 'status',
    render: (value: any) => titleCase(value),
  },
  {
    name: 'Patient Name',
    width: 'minmax(150px, 1fr)',
    accessor: 'patient_name',
  },
  {
    name: 'Member ID',
    width: 'minmax(150px, 1fr)',
    accessor: 'member_id',
  },
  {
    name: 'Patient Control Number',
    width: 'minmax(150px, 1fr)',
    accessor: 'patient_control_number',
  },
  {
    name: 'Status Code',
    width: 'minmax(150px, 1fr)',
    accessor: 'status_code',
  },
  {
    name: 'Total Charge Amount',
    width: 'minmax(150px, 1fr)',
    accessor: 'total_charge_amount',
  },
  {
    name: 'Payment Amount',
    width: 'minmax(150px, 1fr)',
    accessor: 'payment_amount',
  },
  {
    name: 'Patient Responsibility Amount',
    width: 'minmax(150px, 1fr)',
    accessor: 'patient_responsibility_amount',
  },
  {
    name: 'Filing Indicator Code',
    width: 'minmax(150px, 1fr)',
    accessor: 'filing_indicator_code',
  },
  {
    name: 'Payer Control Number',
    width: 'minmax(150px, 1fr)',
    accessor: 'payer_control_number',
  },
  {
    name: 'Facility Type Code',
    width: 'minmax(150px, 1fr)',
    accessor: 'facility_type_code',
  },
  {
    name: 'Frequency Code',
    width: 'minmax(150px, 1fr)',
    accessor: 'frequency_code',
  },
  {
    name: 'Remark Codes',
    width: 'minmax(150px, 1fr)',
    accessor: 'remark_codes',
    render: (value: any) => value?.join(' ,'),
  },
]

const gridColumns = `40px ${columns.map((column) => column.width).join(' ')}`

const InsuranceERAReportOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialData,
    isDeleting,
    isEditable,
    isEmpty,
    isInvalid,
    isLoading,
    isSaving,
    onValidationUpdate,
    queryKey,
    save,
    isOverlayLoading,
    isNew,
  } = useOverlay({
    name: 'insurance-era-report',
    endpoint: '/insurance_era_reports',
    invalidate: 'insurance-era-reports',
    options: props,
  })

  const { data: claims, isLoading: isLoadingClaims }: any = useGet({
    name: ['insurance-era-reports', id, 'claims'],
    url: `/insurance_era_reports/${id}/claims`,
  })

  const { mutate: reprocess, isLoading: isProcessing } = useCreate({
    name: ['insurance-era-reports', id, 'claims'],
    url: `/insurance_era_reports/${id}/reprocess`,
    invalidate: ['insurance-era-reports', id, 'claims'],
  })

  const isEmptyClaims = size(claims) === 0

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader showBackdrop position="center" maxWidth={120} />
  }

  return (
    <Overlay showBackdrop fullheight position="center" maxWidth={120}>
      <Overlay.Header icon="invoices" title="Report" titleAside={!isNew && <ClaimTypeStatus status={data.category} />} />

      <Overlay.Content>
        <Section>
          <Grid gap="1rem">
            <Card css={styles.card}>
              <DataList>
                <DataList.Item
                  label="Status"
                  value={
                    <Flex gap="1rem" centerY>
                      <InsuranceERAReportStatus status={data.status} />
                      <Button
                        label={data.status === 'processed' ? 'Re-Process Report' : 'Process Report'}
                        glyph="reset"
                        type="default"
                        size={200}
                        isLoading={isProcessing}
                        onClick={reprocess}
                      />
                    </Flex>
                  }
                />
                <DataList.Item label="Category" value={titleCase(data.category)} />
                <DataList.Item label="Payer Name" value={data.payer_name} />
                <DataList.Item label="Production Date" value={usDateTime(data.production_date, timezone)} />
                <DataList.Item label="Effective Date" value={usDateTime(data.effective_date, timezone)} />
                <DataList.Item label="Total Payment Amount" value={niceAmount(data.total_payment_amount)} />
                <DataList.Item label="Checks" value={countWord('check', size(data.checks))} />
                <DataList.Item label="Payments" value={countWord('payment', size(data.payments))} />
              </DataList>
            </Card>

            <Card>
              {isLoadingClaims || isEmptyClaims ? (
                <State
                  icon="invoices"
                  isLoading={isLoadingClaims}
                  isEmpty={isEmptyClaims}
                  title="Claims"
                  emptyDescription="No claims to display"
                />
              ) : (
                <>
                  <GridTable templateColumns={gridColumns}>
                    <GridTable.Header>
                      <GridTable.Cell></GridTable.Cell>
                      {columns.map((column) => (
                        <GridTable.Cell key={column.name}>{column.name}</GridTable.Cell>
                      ))}
                    </GridTable.Header>

                    {claims.map((claim: any) => (
                      <ClaimRow key={claim.id} data={claim} />
                    ))}
                  </GridTable>
                </>
              )}
            </Card>
          </Grid>
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}

const ClaimRow = ({ data }: any) => {
  const [isOpen, setIsOpen] = React.useState(true)

  const toggleOpen = () => {
    setIsOpen((c) => !c)
  }

  return (
    <>
      <GridTable.Row>
        <GridTable.Cell>
          <div onClick={toggleOpen}>
            <Glyph glyph="triangle_down" css={styles.triangle} className={isOpen ? 'is-open' : ''} size={15} />
          </div>
        </GridTable.Cell>

        {columns.map((column: any) => (
          <GridTable.Cell>{column.render ? column.render(data[column.accessor]) : data[column.accessor]}</GridTable.Cell>
        ))}
      </GridTable.Row>

      {isOpen && (
        <div css={styles.servicesTableWrapper}>
          <div css={styles.servicesTable}>
            <GridTable templateColumns="120px 1200px 1500px 300px">
              <GridTable.Header>
                <GridTable.Cell>Service Date</GridTable.Cell>

                <GridTable.Cell css={styles.groupCell}>
                  <div css={styles.groupHeaderCell}>
                    <GridTable.Cell>Service Adjustments</GridTable.Cell>
                  </div>

                  <div css={{ display: 'grid', gridTemplateColumns: 'repeat(4, 25%)' }}>
                    <GridTable.Cell css={styles.groupChildCell}>Adjustment Amount 1</GridTable.Cell>
                    <GridTable.Cell css={styles.groupChildCell}>Adjustment Reason Code 1</GridTable.Cell>
                    <GridTable.Cell css={styles.groupChildCell}>Claim Adjustment Group Code</GridTable.Cell>
                    <GridTable.Cell css={styles.groupChildCell}>Claim Adjustment Group Code Value</GridTable.Cell>
                  </div>
                </GridTable.Cell>

                <GridTable.Cell css={styles.groupCell}>
                  <div css={styles.groupHeaderCell}>
                    <GridTable.Cell>Service Payment Information</GridTable.Cell>
                  </div>

                  <div css={{ display: 'grid', gridTemplateColumns: 'repeat(5, 20%)' }}>
                    <GridTable.Cell css={styles.groupChildCell}>Line Item Charge Amount</GridTable.Cell>
                    <GridTable.Cell css={styles.groupChildCell}>Adjudicated Procedure Code</GridTable.Cell>
                    <GridTable.Cell css={styles.groupChildCell}>Product Or Service ID Qualifier</GridTable.Cell>
                    <GridTable.Cell css={styles.groupChildCell}>Line Item Provider Payment Amount</GridTable.Cell>
                    <GridTable.Cell css={styles.groupChildCell}>Product Or Service ID Qualifier Value</GridTable.Cell>
                  </div>
                </GridTable.Cell>

                <div css={styles.groupCell}>
                  <GridTable.Cell css={styles.groupHeaderCell}>Service Supplemental Amounts</GridTable.Cell>
                  <GridTable.Cell css={styles.groupChildCell}>Allowed Actual</GridTable.Cell>
                </div>
              </GridTable.Header>

              {data.service_lines?.map?.((service, index) => {
                const adjustments = service.service_adjustments?.[0]
                const paymentInfo = service.service_payment_information

                return (
                  <GridTable.Row key={index}>
                    <GridTable.Cell>{service.service_date}</GridTable.Cell>

                    <div css={{ display: 'grid', gridTemplateColumns: 'repeat(4, 25%)' }}>
                      {adjustments && (
                        <>
                          <GridTable.Cell>{niceAmount(adjustments.adjustment_amount1)}</GridTable.Cell>
                          <GridTable.Cell>{adjustments.adjustment_reason_code1}</GridTable.Cell>
                          <GridTable.Cell>{adjustments.claim_adjustment_group_code}</GridTable.Cell>
                          <GridTable.Cell>{adjustments.claim_adjustment_group_code_value}</GridTable.Cell>
                        </>
                      )}
                    </div>

                    <div css={{ display: 'grid', gridTemplateColumns: 'repeat(5, 20%)' }}>
                      {paymentInfo && (
                        <>
                          <GridTable.Cell>{niceAmount(paymentInfo.line_item_charge_amount)}</GridTable.Cell>
                          <GridTable.Cell>{paymentInfo.adjudicated_procedure_code}</GridTable.Cell>
                          <GridTable.Cell>{paymentInfo.product_or_service_id_qualifier}</GridTable.Cell>
                          <GridTable.Cell>{niceAmount(paymentInfo.line_item_provider_payment_amount)}</GridTable.Cell>
                          <GridTable.Cell>{paymentInfo.product_or_service_id_qualifier_value}</GridTable.Cell>
                        </>
                      )}
                    </div>

                    <GridTable.Cell>{service.service_supplemental_amounts?.allowed_actual}</GridTable.Cell>
                  </GridTable.Row>
                )
              })}
            </GridTable>
          </div>
        </div>
      )}
    </>
  )
}

const styles = {
  card: { padding: '0.5rem 1rem' },

  triangle: {
    transform: 'rotate(-90deg)',
    marginRight: '0.5rem',

    '&.is-open': {
      transform: 'rotate(0deg)',
    },
  },

  servicesTableWrapper: {
    padding: '1rem 1rem 1rem 40px',
    borderBottom: `2px solid ${COLORS.divider}`,
  },

  servicesTable: {
    border: `1px solid ${COLORS.divider}`,
    borderRadius: 5,
    overflow: 'hidden',
  },

  groupCell: {
    padding: 0,
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
  },

  groupHeaderCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${COLORS.divider}`,
    textAlign: 'center',
  },

  groupChildCell: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.94em',
  },
}

export default withOverlayError(InsuranceERAReportOverlay)
