import React from 'react'
import size from 'lodash/size'
import { Link, Route, Routes, useParams, useLocation, useNavigate, useMatch } from 'react-router-dom-v5-compat'

import { APP_URLS } from '@behavehealth/constructs/MyAccount/utils'
import { COLORS } from '@behavehealth/theme'
import { CommunityProfileEditorOverlay } from '@behavehealth/constructs/Community/CommunityProfileEditorOverlay'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { Button, PageHeader, Card, Avatar, Glyph, PageLoader } from '@behavehealth/components'
import { DropdownMenu, DropdownMenuItem } from '@behavehealth/components/DropdownMenu'
import { useCommunityEditor } from '@behavehealth/constructs/Community/useCommunityEditor'
import Loader from '@behavehealth/components/Loader'

import { CommunityProfileShare } from '@behavehealth/constructs/Community/CommunityProfileShare'
import { useCommunityPermissions } from '@behavehealth/constructs/Community/useCommunityPermissions'

// list of slugs that should be synced between iframe website and editor overlay
const SYNC_SLUGS = [
  'locations',
  'team',
  'insurance-payers',
  'healthcare-services',
  'services',
  'gallery',
  'website',
  'populations-served',
  'partners',
]

export const CommunityProfile = () => {
  const { url } = useRouteURL()

  return (
    <>
      <CommunityProfileIndex />

      <Routes>
        <Route path="edit/*" element={<CommunityProfileEditorOverlay useV6Router back={url} />} />
      </Routes>
    </>
  )
}

const CommunityProfileIndex = () => {
  const { url } = useRouteURL()
  const { slug }: any = useParams()

  const navigate = useNavigate()
  const location = useLocation()

  const editorMatch = useMatch(`${url}/edit/:subpageSlug`)

  const { connectedEntities } = useSettings()
  const connectedEntitiesCount = size(connectedEntities)
  const hasManyEntities = connectedEntitiesCount > 1

  const [activeEntity, setActiveEntity] = React.useState<any>(null)

  const updatedAt = useCommunityEditor((store) => store.updatedAt)
  const isEditorOpen = location.pathname.includes('/edit')

  const { canEditCommunityProfiles } = useCommunityPermissions()

  const subpageSlug = React.useMemo(() => {
    const editSlug = editorMatch?.params?.subpageSlug

    if (!editSlug || !SYNC_SLUGS.includes(editSlug)) return ''

    return editSlug
  }, [editorMatch])

  React.useEffect(() => {
    if (!slug) return

    const entity = connectedEntities?.find?.((entity: any) => entity.slug === slug)

    if (entity) {
      setActiveEntity(entity)
    }
  }, [slug, connectedEntities])

  if (!activeEntity || !connectedEntities) return <PageLoader />

  const publicURL = `${APP_URLS.community}/profiles/${activeEntity.slug}`
  const iframeURL = `${publicURL}/${subpageSlug}?updated=${updatedAt}`

  return (
    <div css={STYLES.root}>
      <PageHeader
        title="My Profiles"
        icon="community_profile"
        aside={
          <>
            <Button
              as={Link}
              label="Explore Community →"
              icon="community"
              type="minimal"
              link="/community/explore"
              size={200}
              permission="community_explore.view"
            />
            <Button
              useGlyphForTarget
              label="Open Profile in New Tab"
              icon="community_profile"
              type="minimal"
              size={200}
              href={publicURL}
              target="_blank"
            />
            <Button
              as={Link}
              label="Edit Profile"
              glyph="edit"
              type="primary"
              size={200}
              link="edit"
              permission={canEditCommunityProfiles}
            />
          </>
        }
        titleAside={
          <>
            <div className="font-[600] text-text-muted opacity-30 relative -top-[1px] !ml-2">/</div>

            {hasManyEntities ? (
              <DropdownMenu
                trigger={
                  <div>
                    <div className="flex items-center px-2 py-1 bg-white bg-opacity-90 bg:hover-hover cursor-pointer">
                      <Avatar size={24} initials="O N" className="mr-2" />
                      <div css={{ color: COLORS.blue, fontWeight: 600, marginRight: '0.35rem' }}>{activeEntity.name}</div>
                      <Glyph size={10} glyph="triangle_down" className="ml-auto" />
                    </div>
                  </div>
                }
              >
                {connectedEntities.map?.((entity: any) => (
                  <DropdownMenuItem
                    key={entity.id}
                    isActive={entity.slug === slug}
                    label={entity.name}
                    onClick={() => {
                      setActiveEntity(entity)
                      navigate(`/community/profiles/${entity.slug}`)
                    }}
                    before={<Avatar src={entity.avatar} initials={entity.name} size={24} className="mr-2" />}
                  />
                ))}
              </DropdownMenu>
            ) : (
              <Card className="flex items-center px-2 py-1">
                <Avatar size={24} initials="O N" className="mr-2" />
                <div className="font-[600]">{activeEntity.name}</div>
              </Card>
            )}

            <CommunityProfileShare profiles={connectedEntities} activeProfileId={activeEntity?.id} />
          </>
        }
      />

      <div css={STYLES.content} className={isEditorOpen ? 'is-editor-open' : ''}>
        <Card className="relative">
          <iframe
            title="iframe"
            key={iframeURL}
            src={iframeURL}
            border="0"
            cellSpacing="0"
            css={{
              borderStyle: 'none',
              width: '100%',
              height: '100%',
              minHeight: '80vh',
              zIndex: 3,
              position: 'relative',
            }}
            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
          />

          <Loader css={STYLES.loader} />
        </Card>
      </div>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    overflow: 'hidden',
  },

  content: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
    padding: '1rem',

    '&.is-editor-open': {
      '@media(min-width: 1500px)': {
        paddingRight: 650,
      },
    },
  },

  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}
