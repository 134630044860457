import { COLORS } from '../../theme'

export const TREATMENT_PLAN_VIEWS = {
  table: 'table',
  cards: 'cards',
}

export const SECTIONS = {
  general_info: {
    id: 'general_info',
    title: 'General Info',
    glyph: 'info',
  },
  appointments: {
    id: 'appointments',
    title: 'Appointments',
    glyph: 'calendar',
    glyphColor: COLORS.red,
  },
  updates: {
    id: 'updates',
    title: 'Updates',
    glyph: 'multi_select',
  },
  plan_reviews: {
    id: 'plan_reviews',
    title: 'Plan Reviews',
    icon: 'treatment_plans',
  },
  diagnoses: {
    id: 'diagnoses',
    title: 'Linked Diagnoses',
    icon: 'diagnosis',
  },
  problems: {
    id: 'problems',
    title: 'Problems',
    icon: 'treatment_plans',
  },
  goals_objectives_interventions: {
    id: 'goals_objectives_interventions',
    title: 'Goals, Objectives & Interventions',
    icon: 'treatment_plans',
  },
  custom_sections: {
    id: 'custom_sections',
    title: 'Custom Sections',
    icon: 'treatment_plans',
  },
  summary: {
    id: 'summary',
    title: 'Summary',
    icon: 'treatment_plans',
  },
  files: {
    id: 'files',
    title: 'Files',
    icon: 'files',
  },
  signatures: {
    id: 'signatures',
    title: 'Signatures',
    icon: 'signature',
  },
  addendums: {
    id: 'addendums',
    title: 'Addendums',
    icon: 'addendums',
  },
} as const
