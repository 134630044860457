import React from 'react'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import { css, COLORS } from '@behavehealth/theme'

import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import Button from '@behavehealth/components/Button'
import Page from '@behavehealth/components/Page'
import Tabs from '@behavehealth/components/Tabs'
import SummonOverlay from '@behavehealth/components/SummonOverlay'

import { InsuranceReportOverlay } from '@behavehealth/constructs/RCM/InsuranceReportOverlay'
import { InsuranceReportsStatusOverlay } from '@behavehealth/constructs/RCM/InsuranceReportsStatusOverlay'
import { InsuranceReportsNotesOverlay } from '@behavehealth/constructs/RCM/InsuranceReportsNotesOverlay'

import { InsuranceReportsAll } from './insurance_reports_all'
import { InsuranceReportsDraft } from './insurance_reports_draft'
import { InsuranceReportsProcessed } from './insurance_reports_processed'
import { InsuranceReportsDone } from './insurance_reports_done'
import { InsuranceReportsArchived } from './insurance_reports_archived'

import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

export const ClearingHouseMailbox = () => {
	const match = useRouteMatch()
	const { isBehave } = useSettings()

	const [isNotesOverlayOpen, setIsNotesOverlayOpen] = React.useState(false)
	const [isStatusOverlayOpen, setIsStatusOverlayOpen] = React.useState(false)

	const [selectedInsuranceReports, setSelectedInsuranceReports]: any = React.useState([])

	const { mutateAsync: syncReports, isLoading } = useCreate({
		name: 'insurance_clearing_house_reports',
		url: '/insurance_clearing_house_reports/sync',
		invalidate: ['insurance_clearing_house_reports']
	})

	return (
		<>
			<Page
				feature="clearinghouse"
				title="Clearinghouse"
				breakpoint="0"
				actions={
					<>
						{isBehave && (
							<Button
								label="Sync Reports"
								glyph="add"
								type="primary"
								onClick={async () => {
									await syncReports()
								}}
								isLoading={isLoading}
								permission="insurance_clearinghouse.create"
							/>
						)}
					</>
				}
			>
				<Tabs defaultTab="all">
					<Tabs.List className="-mt-4 mb-4">
						<Tabs.Item label="Inbox" to={`${match.url}/inbox`} />
						<div className={STYLES.verticalSeparator()} />
						<Tabs.Item label="New" to={`${match.url}/new`} />
						<Tabs.Item label="Done" to={`${match.url}/done`} />
						<Tabs.Item label="Archived" to={`${match.url}/archived`} />
						{isBehave && <Tabs.Item label="🧬 Pending" to={`${match.url}/pending`} />}
					</Tabs.List>
				</Tabs>

				<Switch>
					<Route
						path={`${match.url}/inbox`}
						children={
							<InsuranceReportsAll
								onItemSelect={setSelectedInsuranceReports}
								onNotesOpen={setIsNotesOverlayOpen}
								onStatusOpen={setIsStatusOverlayOpen}
							/>
						}
					/>
					<Route
						path={`${match.url}/pending`}
						children={
							<InsuranceReportsDraft
								onItemSelect={setSelectedInsuranceReports}
								onNotesOpen={setIsNotesOverlayOpen}
								onStatusOpen={setIsStatusOverlayOpen}
							/>
						}
					/>
					<Route
						path={`${match.url}/new`}
						children={
							<InsuranceReportsProcessed
								onItemSelect={setSelectedInsuranceReports}
								onNotesOpen={setIsNotesOverlayOpen}
								onStatusOpen={setIsStatusOverlayOpen}
							/>
						}
					/>
					<Route
						path={`${match.url}/done`}
						children={
							<InsuranceReportsDone
								onItemSelect={setSelectedInsuranceReports}
								onNotesOpen={setIsNotesOverlayOpen}
								onStatusOpen={setIsStatusOverlayOpen}
							/>
						}
					/>
					<Route
						path={`${match.url}/archived`}
						children={
							<InsuranceReportsArchived
								onItemSelect={setSelectedInsuranceReports}
								onNotesOpen={setIsNotesOverlayOpen}
								onStatusOpen={setIsStatusOverlayOpen}
							/>
						}
					/>

					<Redirect exact from={match.url} to={`${match.url}/inbox`} />
				</Switch>
			</Page>

			<AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
				<Route path={`${match.url}/:type/:id`} component={InsuranceReportOverlay} />
			</AnimatedSwitch>

			<SummonOverlay
				isOpen={isStatusOverlayOpen}
				onClose={() => {
					setSelectedInsuranceReports([])
					setIsStatusOverlayOpen(false)
				}}
				overlay={<InsuranceReportsStatusOverlay selectedInsuranceReports={selectedInsuranceReports} />}
			/>

			<SummonOverlay
				isOpen={isNotesOverlayOpen}
				onClose={() => {
					setSelectedInsuranceReports([])
					setIsNotesOverlayOpen(false)
				}}
				overlay={<InsuranceReportsNotesOverlay selectedInsuranceReports={selectedInsuranceReports} />}
			/>
		</>
	)
}

const STYLES = {
	verticalSeparator: css({
		width: 1,
		height: 14,
		background: COLORS.textMuted,
		opacity: 0.25,
		borderRadius: '50%',
		margin: '0 1rem 0 -0.5rem'
	})
}
