import React from 'react'
import { DateTime } from 'luxon'
import * as Toast from '@radix-ui/react-toast'
import { keyframes } from '@emotion/react'
import { SHADOW, COLORS } from '../theme'
import { css } from '@emotion/react'
import { lighten, darken } from 'polished'

import useInterval from '../hooks/useInterval'
import useAnalytics from '../hooks/useAnalytics'
import Button from './Button'
import Dialog from './Dialog'

const INTERVAL = 5 * 60 * 1000 // every 5 minutes
const SHOW_TOAST = 60 * 60 * 1000 // 60 minutes

export const SessionTimerPopup: React.FC<any> = () => {
  const [softExpiresSoon, setSoftExpiresSoon] = React.useState(false)
  const [hardExpiresSoon, setHardExpiresSoon] = React.useState(false)
  const [expiresIn, setExpiresIn] = React.useState(0)

  const [thirtyConfirmed, setThirtyConfirmed] = React.useState(false)
  const [tenConfirmed, setTenConfirmed] = React.useState(false)

  const { trackEvent } = useAnalytics()

  useInterval(() => {
    const tokenExpiresOn = localStorage.getItem('bh.ea')

    const now = DateTime.local().toUTC()
    const expiryDate = DateTime.fromISO(tokenExpiresOn).toUTC()

    const withinThirtyMinutes = now.plus({ minutes: 30 })
    const withinTenMinutes = now.plus({ minutes: 10 })
    const withinFiveMinutes = now.plus({ minutes: 5 })

    if (now <= expiryDate && expiryDate <= withinFiveMinutes) {
      setHardExpiresSoon(true)
      setSoftExpiresSoon(false)

      setExpiresIn(5)

      trackEvent({ name: 'Session Expires Popup' })
    } else if (now <= expiryDate && expiryDate <= withinTenMinutes && !tenConfirmed) {
      setSoftExpiresSoon(true)
      setExpiresIn(10)

      trackEvent({ name: 'Session Expires Toast', params: { expiresIn: '10' } })
    } else if (now <= expiryDate && expiryDate <= withinThirtyMinutes && !thirtyConfirmed) {
      setSoftExpiresSoon(true)
      setExpiresIn(30)

      trackEvent({ name: 'Session Expires Toast', params: { expiresIn: '30' } })
    }
  }, INTERVAL)

  return (
    <>
      <Toast.Provider swipeDirection="right" duration={SHOW_TOAST}>
        <Toast.Root open={softExpiresSoon} onOpenChange={setSoftExpiresSoon} css={[STYLES.notification, borderAngle, warning]}>
          <Toast.Title css={STYLES.title}>Your Session is ending soon</Toast.Title>

          <Toast.Description asChild css={STYLES.description}>
            <p>
              Your session is about to expire in <strong>{expiresIn}</strong> minutes. Please <strong>save your work</strong> and{' '}
              <strong>log out</strong> to start a new session.
            </p>
          </Toast.Description>

          <Toast.Action asChild altText="I Understand" css={STYLES.action}>
            <Button
              type="primary"
              color="red"
              label="I Understand"
              onClick={() => {
                if (!thirtyConfirmed && expiresIn === 30) setThirtyConfirmed(true)
                if (!tenConfirmed && expiresIn === 10) setTenConfirmed(true)

                setSoftExpiresSoon(false)

                trackEvent({ name: 'Session Expires Toast Closed' })
              }}
            />
          </Toast.Action>
        </Toast.Root>

        <Toast.Viewport css={STYLES.viewport} />
      </Toast.Provider>

      <Dialog
        glyph="warning"
        setOpen={hardExpiresSoon}
        showNoButton={false}
        title="Your Session is ending soon"
        yesLabel="I Understand"
        yesColor="red"
        message={
          <p>
            Your session is about to expire in <strong>{expiresIn}</strong> minutes. Please <strong>save your work</strong> and{' '}
            <strong>log out & log back in</strong> to start a new session.
          </p>
        }
        onYes={() => {
          setHardExpiresSoon(false)

          trackEvent({ name: 'Session Expires Popup Closed' })
        }}
      />
    </>
  )
}

const hide = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

const slideIn = keyframes`
  0% {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }

  100% {
    transform: translateX(0);
  }
`

const swipeOut = keyframes`
  0% {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }

  100% {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
`

const bgSpin = keyframes`
  0% {
    '--border-angle': '0turn'
  }

  50% {
    '--border-angle': '0.5turn'
  }

  100% {
    '--border-angle': '1turn'
  }
`

const STYLES = {
  viewport: {
    '--viewport-padding': '25px',

    position: 'fixed',

    bottom: 10,
    right: 10,

    display: 'flex',
    flexDirection: 'column',
    gap: '10px',

    width: 490,
    maxWidth: '100vw',
    margin: 0,
    listStyle: 'none',
    zIndex: 2147483647,

    outline: 'none',
  },

  notification: {
    background: '#fff',
    padding: '1rem',

    borderRadius: 10,
    fontWeight: 500,

    boxShadow: SHADOW(2),

    // grid
    display: 'grid',
    gridTemplateAreas: "'title action' 'description action'",
    gridTemplateColumns: 'auto max-content',
    columnGap: '15px',
    alignItems: 'center',

    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },

    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`,
    },

    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },

    '&[data-swipe="cancel"]': {
      transform: 'translateX(0)',
      transition: 'transform 200ms ease-out',
    },

    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`,
    },
  },

  warning: {
    '--border-size': 3,
    '--border-angle': '0turn',

    backgroundImage: `conic-gradient(from var(--border-angle), #213, #112 50%, #213) conicGradient(from var(--border-angle), transparent 20%, #08f, #f03)`,
    backgroundSize: `calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',

    animation: `${bgSpin} 3s linear infinite`,

    '@property --border-angle': {
      syntax: '<angle>',
      inherits: 'true',
      initialValue: '0turn',
    },
  },

  title: {
    gridArea: 'title',
    marginBottom: 5,

    fontWeight: 600,
  },

  description: {
    gridArea: 'description',
    margin: 0,

    color: COLORS.textLight,
    fontWeight: 400,
  },

  action: {
    gridArea: 'action',
  },
}

const warning = css`
  border-radius: 10px;
  overflow: hidden;
  padding: 2rem;

  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;

    z-index: -2;
    left: -50%;
    top: -200%;
    width: 200%;
    height: 500%;

    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;

    background-image: linear-gradient(${lighten(0.2, COLORS.red)}, ${lighten(0.2, COLORS.red)}),
      linear-gradient(${lighten(0.1, COLORS.red)}, ${lighten(0.1, COLORS.red)}),
      linear-gradient(${darken(0, COLORS.red)}, ${darken(0, COLORS.red)}),
      linear-gradient(${darken(0.2, COLORS.red)}, ${darken(0.2, COLORS.red)});

    animation: rotate 5s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;

    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);

    background: white;
    border-radius: 5px;
  }
`

const borderAngle = css`
  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`
