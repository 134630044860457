import React from 'react'
import size from 'lodash/size'

import { singular, countWord } from '../../utils/functions'
import { COLORS } from '../../theme'
import { withFormContext } from '../Forms/context'

import Button from '../Button'
import Icon from '../Icon'
import Label from '../Label'
import Loader from '../Loader'
import Switch from '../Forms/Switch'

import { useStore } from './utils/useStore'

const RootWorksheetHeader = (props: any) => {
  const {
    title = 'Rows',
    icon,
    isEditable,
    isRefetching,
    form,
    withWrapToggle,
    withShowInvalidToggle,
    titleClassName,
    withFullScreenToggle,
    isFullScreen,
    toggleFullScreen,
  } = props

  const addRow: any = useStore((state: any) => state.addRow)
  const allowCreate: any = useStore((state: any) => state.allowCreate)
  const dataMap: any = useStore((state: any) => state.dataMap)
  const setWrapping: any = useStore((state: any) => state.setWrapping)
  const showInvalid: any = useStore((state: any) => state.showInvalid)
  const toggleShowInvalid: any = useStore((state: any) => state.toggleShowInvalid)
  const wrapping: any = useStore((state: any) => state.wrapping)

  const rowsCount = size(dataMap)
  const invalidFieldsCount = size(form?.invalidInputs)

  return (
    <header css={STYLES.root} data-type="worksheet-header-row">
      {icon && <Icon icon={icon} size={18} />}

      <div css={STYLES.title} className={titleClassName}>
        {title && countWord(title, rowsCount)}
      </div>

      {isRefetching && (
        <div css={STYLES.loaderWrapper}>
          <Loader size={15} />
        </div>
      )}

      {withFullScreenToggle && (
        <Button
          hideLabel={!isFullScreen}
          label={isFullScreen && 'Minimize'}
          glyph={isFullScreen ? 'compress' : 'expand'}
          type={isFullScreen ? 'primary' : 'default'}
          color="text"
          size={100}
          onClick={toggleFullScreen}
          className="mr-2"
        />
      )}

      {isEditable && allowCreate && (
        <Button label={title ? `Add ${singular(title)}` : 'Add Row'} glyph="add" type="primary" size={100} onClick={addRow} />
      )}

      {withShowInvalidToggle && invalidFieldsCount > 0 && (
        <Button
          label={showInvalid ? 'Hide Invalid Fields' : 'Show Invalid Fields'}
          type={showInvalid ? 'primary' : 'default'}
          glyph="asterisk"
          color="orange"
          size={100}
          onClick={toggleShowInvalid}
          className="!ml-2"
        />
      )}

      {withWrapToggle && (
        <div css={STYLES.actions}>
          <Label label="Wrap Lines" />
          <Switch
            label=""
            size={200}
            withHover={false}
            value={wrapping === 'wrap'}
            onUpdate={({ value: isChecked }: any) => {
              setWrapping(isChecked ? 'wrap' : 'clip')
            }}
            layout={null}
          />
        </div>
      )}
    </header>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.divider}`,
    padding: '0.2rem',
    minHeight: '2.25rem',
    position: 'relative',
    zIndex: 1,
  },

  title: {
    fontWeight: 600,
    fontSize: '1rem',
    margin: '0 0.5rem',
  },

  loaderWrapper: {
    margin: '0 0.75rem',
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginLeft: 'auto',
    padding: '0.1rem 0.25rem',
  },
}

export const WorksheetHeader = withFormContext(RootWorksheetHeader)
