import React from 'react'
import { tint } from 'polished'

import { apiGet } from '../../modules/api'
import { COLORS, SHADOW } from '../../theme'

import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Loader from '../../components/Loader'
import Overlay from '../../components/Overlay'

import { SearchResults } from './SearchResults'
import { useSearchStore } from './store'

type Props = {
  onClose: Function
}

export const SearchOverlay: React.FC<Props> = ({ onClose }) => {
  const searchInput = useSearchStore((state: any) => state.searchInput)
  const setSearchInput = useSearchStore((state: any) => state.setSearchInput)

  const [results, setResults] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const getSearchResults = async (input: string) => {
      try {
        setLoading(true)

        const result = await apiGet({
          url: `/search_v2?q=${encodeURIComponent(input)}`,
        })

        // update results
        setResults(result.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (searchInput?.length >= 2) {
      getSearchResults(searchInput)
    } else {
      setResults([])
    }
  }, [searchInput])

  return (
    <Overlay maxWidth={45} minHeight={50} position="center" onClose={onClose} css={styles.overlay}>
      <div css={styles.columns}>
        <div css={styles.searchColumn}>
          <div css={styles.inputWrapper}>
            <div css={styles.inputGraphic}>{loading ? <Loader color={COLORS.blue} size={20} /> : <Icon icon="search" size={20} />}</div>
            <Input
              autoFocus
              debounce={0}
              placeholder="Search…"
              onUpdate={({ value }) => setSearchInput(value)}
              value={searchInput}
              css={styles.input}
            />
          </div>

          <SearchResults results={results} isLoading={loading} close={onClose} />
        </div>
      </div>
    </Overlay>
  )
}

const styles = {
  overlay: {
    '.dialog': {
      borderRadius: '9px !important',
      boxShadow: `${SHADOW(10, COLORS.backdrop)} !important`,
    },
  },

  columns: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '1fr',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    height: '100%',
    overflow: 'hidden',

    '--header-height': '42px',
  },

  searchColumn: {
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: COLORS.white,
    borderRight: `1px solid ${COLORS.divider}`,
  },

  activeResultColumn: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  inputWrapper: {
    position: 'sticky',
    paddingLeft: '1rem',
    top: 0,
    zIndex: 1,
    backgroundColor: COLORS.white,
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  inputGraphic: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 3,
  },

  input: {
    width: '100%',
    fontSize: '1.1rem',

    outline: 'none',
    border: 'none !important',
    boxShadow: 'none !important',
    background: 'white !important',

    input: {
      padding: '0',
      paddingLeft: '2.5rem',
      height: '56px !important',
      border: '1px solid red',
    },

    '&::placeholder': {
      color: COLORS.textMuted,
      fontStyle: 'normal',
    },
  },

  subtitle: {
    fontSize: '.9rem',
    textTransform: 'uppercase',
    color: COLORS.textMuted,
    marginBottom: '1rem',
  },

  stats: {
    fontSize: '0.85rem',

    span: {
      textTransform: 'uppercase',
      fontWeight: 600,
      color: COLORS.gray,
    },
  },

  pinButton: {
    color: `${COLORS.text} !important`,
    marginRight: '2rem',

    img: {
      width: '1rem',
      height: '1rem',
      transform: 'rotate3d(1, 1, 1, 0)',
      transition: 'transform 200ms ease',
    },

    '&.is-pinned': {
      backgroundColor: `${tint(0.85, COLORS.violet)} !important`,
      boxShadow: `0 0 0 1px ${tint(0.2, COLORS.violet)}, 0 0 0 4px ${tint(0.6, COLORS.violet)}`,

      img: {
        transform: 'rotate3d(0, 0, 1, -45deg)',
      },
    },
  },
}
