import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import ClientColorPicker from '../../components/ColorPickers/ClientColorPicker'
import Table from '../../components/Table/Table'
import TableCell from '../../components/Table/TableCell'

import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { FILTERS } from '../Filters/config'

export const PaymentMethodsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        id: 'profile',
        model: 'data.profile.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.data.id} value={data.data.profile?.name} avatar={data.data.profile.avatar} to={to?.(data.data)} />
        ),
      },
      {
        title: 'Color',
        id: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => (
          <ClientColorPicker isDisabled clientID={data.data?.profile?.id} color={data.data?.profile?.color_code} />
        ),
      },
      {
        width: 180,
        id: 'name',
        model: 'data.name',
        title: 'Name',
      },
      {
        width: 180,
        id: 'reference',
        model: 'data.reference.name',
        title: 'Reference',
        type: 'profile',
      },
      {
        width: 180,
        id: 'category',
        model: 'data.category',
        title: 'Category',
      },
      {
        width: 180,
        id: 'status',
        model: 'data.status',
        title: 'Status',
      },
      {
        width: 200,
        id: 'formatted_service_details',
        model: 'data.formatted_service_details',
        title: 'Details',
        disableSort: true,
      },
      {
        width: 140,
        id: 'author',
        model: 'data.author.name',
        title: 'Added By',
        type: 'profile',
      },
      {
        width: 140,
        id: 'created_at',
        model: 'data.created_at',
        title: 'Create Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 140,
        id: 'updated_at',
        model: 'data.updated_at',
        title: 'Last Update Date',
        type: 'date_time',
        disableSort: false,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Payment Methods"
      icon="financials"
      columns={columns}
      filtersConfig={FILTERS.live_reports.payment_methods}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="payment_methods"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=payment_methods'}
        />
      }
      {...props}
    />
  )
}
