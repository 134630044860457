import React from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import size from 'lodash/size'
import startCase from 'lodash/startCase'
import { useSelector } from 'react-redux'

import { ICONS } from '../../../theme'
import { niceAmount } from '../../../utils/functions'
import { apiCreate } from '../../../modules/api'
import { request } from '../../../modules/axios'

import Card from '../../Card'
import State from '../../State'
import Alert from '../../Alert'
import FormSection from '../../Forms/FormSection'
import Grid from '../../Grid'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Status from '../../Status'
import Workflow from '../../Workflow/Workflow'

import { useGet } from '../../../hooks/useNewAPI'

const PaymentStep = ({ customer, finalAmount, source, paymentMethodReferenceCategory }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)

  const stripe = useStripe()
  const elements = useElements()

  const [error, setError] = React.useState('')
  const [processing, setProcessing] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [amount, setAmount] = React.useState(finalAmount)
  const [selectedPaymentMethodID, setSelectedPaymentMethodID] = React.useState(null)

  const { data: paymentMethods, isLoading }: any = useGet({
    name: ['client', customer.id, 'payment_methods'],
    url: `/residents/${customer?.id}/payment_methods`,
    params: {
      reference_category: paymentMethodReferenceCategory,
    },
  })

  const hasPaymentMethods = size(paymentMethods) > 0

  const payWithExistingCard = async () => {
    setProcessing(true)

    if (!stripe || !elements) return

    try {
      const response = await apiCreate({
        notify: false,
        url: `/apps/stripe/connect/charge_payment_method`,
        params: {
          amount: finalAmount,
          payment_method_id: selectedPaymentMethodID,
          reference_id: customer.id,
          reference_type: customer.type,
          source: source,
        },
      })

      if (response.data.status === 'requires_action') {
        const { error: errorAction, paymentIntent } = await stripe.handleCardAction(response.data.client_secret)

        if (errorAction) {
          // Show error from Stripe.js in payment form
          setError(errorAction.message)
          setProcessing(false)
          return
        } else {
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server
          const confirmResponse = await apiCreate({
            notify: false,
            url: `/apps/stripe/connect/confirm_payment_intent`,
            params: { payment_intent: paymentIntent.id },
          })

          if (confirmResponse.data.status === 'succeeded') {
            setError(null)
            setProcessing(false)
            goNext()
          } else {
            setError(confirmResponse.data)
            setProcessing(false)
          }
        }
      } else if (response.data.status === 'succeeded') {
        setError(null)
        goNext()
      } else if (response.data.status === 'processing') {
        setError(null)
        goNext()
      }
    } catch (error) {
      console.debug(error)

      setError(`Payment failed: ${error?.error?.message || error?.error?.data?.errors[0]}`)
      setProcessing(false)
    }
  }

  React.useEffect(() => {
    const getLatestFees = async () => {
      let payment = null
      for (let i = 0; i < paymentMethods?.length; i++) {
        if (selectedPaymentMethodID === paymentMethods[i].id) {
          payment = paymentMethods[i]
          break
        }
      }

      try {
        setLoading(true)
        const fees = await request.get(`/apps/ledger/fees/${finalAmount * 100}?payment_method=${payment?.category}`)
        setAmount(fees.data.total)

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (finalAmount >= 5) getLatestFees()
  }, [selectedPaymentMethodID])

  return (
    <FormSection>
      {isLoading && (
        <Card>
          <State title="Payment Methods" emptyDescription="No payment method added yet" isLoading={isLoading} minHeight={100} />
        </Card>
      )}

      {hasPaymentMethods ? (
        <RadioGroup
          layout="vertical-dense"
          model="payment_method"
          defaultValue={customer?.default_payment_method_id}
          onChange={(state: any) => setSelectedPaymentMethodID(state.value)}
        >
          {paymentMethods?.map((paymentMethod: any) => {
            if (paymentMethod.category === 'card') {
              const { id, service_details } = paymentMethod

              return (
                <Radio
                  key={id}
                  value={id}
                  icon={ICONS[service_details?.brand] || ICONS.billing}
                  label={paymentMethod.name}
                  description={
                    <Grid gap="0.25rem">
                      <div>
                        {startCase?.(service_details?.brand)} **** {service_details?.last4}, Expires {service_details?.exp_month}/
                        {service_details?.exp_year}
                      </div>
                      {customer?.default_payment_method_id === id && <Status label="Default" color="blue" />}
                    </Grid>
                  }
                />
              )
            } else if (paymentMethod.category === 'ach') {
              const { id, service_details } = paymentMethod

              return (
                <Radio
                  glyph="bank"
                  key={id}
                  value={id}
                  label={paymentMethod.name}
                  description={
                    <Grid gap="0.25rem">
                      <div>
                        {service_details.bank_name} **** {service_details.last4}
                      </div>
                      {customer?.default_payment_method_id === id && <Status label="Default" color="blue" />}
                    </Grid>
                  }
                />
              )
            }
          })}
        </RadioGroup>
      ) : (
        <Alert type="negative" glyph="circle_error">
          Please add a Payment Method before paying
        </Alert>
      )}

      {hasPaymentMethods && error && (
        <Alert type="negative" glyph="circle_error">
          {error}
        </Alert>
      )}

      <Workflow.ContinueButton
        label={`Pay ${niceAmount(amount)} Securely`}
        color="green"
        glyph="lock"
        isDisabled={!hasPaymentMethods || !selectedPaymentMethodID}
        isLoading={loading || processing}
        onClick={payWithExistingCard}
      />
    </FormSection>
  )
}

export default PaymentStep
