import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { ServiceEpisodesReportDataTable } from '@behavehealth/constructs/LiveReports/ServiceEpisodesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ServiceEpisodes = () => {
  const queryParams = { category: 'service_episodes' }

  const tableProps = useDataTable({
    name: ['reports', 'service_episodes'],
    endpoint: `/live_reports`,
    updateDeleteEndpoint: `/service_episodes`,
    params: queryParams,
    localStorageKey: 'report_service_episodes_v2',
  })

  const to = React.useMemo(() => (rowData: any) => getClientLink(rowData), [])

  return (
    <Page feature="service_episodes" title="Live Service Episodes">
      <Grid gap="1rem">
        <ServiceEpisodesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ServiceEpisodes)
