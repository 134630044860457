import React from 'react'
import { darken, tint } from 'polished'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS } from '../../theme'

import Button from '../Button'
import Dropdown from '../Dropdown'
import DropdownItem from '../DropdownItem'
import Flex from '../Flex'
import Tooltip from '../Tooltip'
import Divider from '../Divider'

import MessageAuthor from './MessageAuthor'

import File from './messages/FileMessage'
import GIF from './messages/GifMessage'
import Poll from './messages/PollMessage'
import Records from './messages/RecordsMessage'
import Text from './messages/TextMessage'

import { ChatChannelContext } from './context'

const CONTENT_TYPES = {
  file: File,
  gif: GIF,
  poll: Poll,
  records: Records,
  text: Text,
}

const REACTIONS = {
  thumbs_up: '👍',
  thumbs_down: '👎',
  hooray: '🎉',
  eyes: '👀',
  laugh: '😃',
  confused: '😕',
}

const MessageType = ({ type, message }: any) => {
  if (!type) return null

  const Tag = CONTENT_TYPES[type]
  return <Tag message={message} />
}

const buildReactionLabel = (reactions: Array<any>, emoji: string) => {
  let names = []
  let isAuthor = false

  for (let i = 0; i < reactions.length; i++) {
    if (reactions[i].is_author) {
      names.unshift('You')
      isAuthor = true
    } else {
      names.push(reactions[i]?.author?.name)
    }
  }

  return (
    <div css={{ textAlign: 'center' }}>
      {`${names.join(', ')} reacted with `}
      <i>{`:${emoji}:`}</i>
      {isAuthor && <div> (click to remove) </div>}
    </div>
  )
}

const Message = ({ timezone, message, permissions }: any) => {
  if (!message) return null

  const ref = React.useRef()
  const { toggleMessageStatus, addReaction, removeReaction }: any = React.useContext(ChatChannelContext)

  const isHidden = message.status === 'hidden'
  const reactions = message?.chat_reactions
  // const isReply = isDefined(message.replies_to)

  const classNames = clsx({
    'is-own-message': message.is_author,
    // 'is-reply': isReply,
  })

  const hasReactions = size(reactions) > 0

  const toggleHidden = async () => {
    await toggleMessageStatus({
      id: message.id,
      params: {
        status: isHidden ? 'visible' : 'hidden',
      },
    })
  }

  const toggleReaction = async (reactionID: string, emoji: string) => {
    if (reactionID) {
      await removeReaction({
        message: message,
        reactionID: reactionID,
      })
    } else {
      await addReaction({
        message: message,
        emoji: emoji,
      })
    }
  }

  if (isHidden) {
    return (
      <div ref={ref} css={styles.root} className={classNames}>
        <div css={styles.bubble} className="hidden-message">
          This message has been hidden.
        </div>

        <Dropdown trigger={<Button hideLabel glyph="more_vertical" color="blue" size={200} type="minimal" className="!ml-2" />}>
          <DropdownItem label="Reveal message for everyone…" glyph="view" color="gray" onClick={toggleHidden} />
        </Dropdown>
      </div>
    )
  }

  return (
    <div ref={ref} css={styles.root} className={classNames}>
      <div css={styles.bubble} className="message-bubble">
        <Flex alignItems="center" justifyContent="space-between">
          <MessageAuthor author={message.author} sentAt={message.sent_at} timezone={timezone} />

          <Dropdown trigger={<Button hideLabel glyph="more_vertical" color="blue" size={200} type="minimal" className="!ml-2" />}>
            {/* {!isOwnMessage && (
              <>
                <DropdownItem label="Reply…" glyph="text_message" color="blue" size={200} />
                <DropdownItem label="Mark as unread" glyph="in_progress" color="green" size={200} />
              </>
            )} */}

            <DropdownItem
              label="Hide message for everyone…"
              glyph="view"
              color="gray"
              onClick={toggleHidden}
              size={200}
              permission={permissions?.hide}
            />

            <Divider />

            <Flex className="!p-2">
              {Object.keys(REACTIONS).map((emoji) => (
                <Button
                  hideLabel
                  key={emoji}
                  emoji={REACTIONS[emoji]}
                  size={200}
                  type="minimal"
                  onClick={() => toggleReaction(reactions[emoji]?.authored_reaction, emoji)}
                />
              ))}
            </Flex>
          </Dropdown>
        </Flex>

        {/* {isReply && <MessageType isReply message={message.replies_to} />} */}
        <MessageType type={message.category} message={message} />

        {hasReactions && (
          <div css={styles.reactions}>
            <Flex gap={6}>
              {Object.keys(reactions).map(
                (emoji) =>
                  reactions[emoji].count > 0 && (
                    <Tooltip key={emoji} content={buildReactionLabel(reactions[emoji].reactions, emoji)}>
                      <Button
                        className={reactions[emoji].authored_reaction ? 'is-author' : ''}
                        type="default"
                        color="text"
                        emoji={REACTIONS[emoji]}
                        label={reactions[emoji].count}
                        size={200}
                        onClick={() => toggleReaction(reactions[emoji].authored_reaction, emoji)}
                        emojiSize={15}
                      />
                    </Tooltip>
                  ),
              )}
            </Flex>
          </div>
        )}
      </div>
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.75rem',

    '&.is-own-message': {
      marginTop: '1rem',
      justifyContent: 'flex-end',

      '& + .is-own-message': {
        marginTop: '0.5rem',
      },

      '.message-bubble': {
        color: darken(0.04, COLORS.text),
        backgroundColor: tint(0.85, COLORS.link),
        boxShadow: `0 0 0 1px ${tint(0.75, COLORS.link)}`,
      },
    },
  },

  bubble: {
    padding: '6px 10px',
    borderRadius: 7,
    backgroundColor: COLORS.white,
    maxWidth: '90%',
    boxShadow: `0 0 0 1px ${tint(0.1, COLORS.divider)}`,

    '&.is-reply': {
      padding: '10px 10px 6px 10px',

      fontStyle: 'italic',
      backgroundColor: COLORS.divider,
    },

    '&.hidden-message': {
      opacity: 0.7,
      fontStyle: 'italic',
    },
  },

  reactions: {
    marginTop: '0.5rem',

    '.is-author': {
      backgroundColor: tint(0.8, COLORS.link),
      boxShadow: `0 0 0 1px ${tint(0.5, COLORS.link)}`,
    },
  },
}

export default Message
