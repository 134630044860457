import React from 'react'
import { useRouteMatch, useLocation, useParams } from 'react-router-dom'

import { initials } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import HelpTagIframe from '@behavehealth/components/Help/HelpTagIframe'

import { PeerNotesDataTable } from '@behavehealth/constructs/PeerNotes/PeerNotesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

type Props = {
  canCreate: boolean
}

const IndividualPeerNotes: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const location = useLocation()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const tableProps = useDataTable({
    name: ['client', resource_id, 'peer_notes'],
    endpoint: `/residents/${resource_id}/peer_notes`,
    updateDeleteEndpoint: `/peer_notes`,
    params: { category: 'individual' },
    // localStorageKey: 'client_peer_notes_individual_v1',
  })

  const { deleteRecords } = tableProps

  const to = React.useMemo(
    () => (id: string, category: string) => ({
      pathname: `${match.url}/${initials(category)}/${id}`,
      parent: match,
    }),
    [match.url],
  )

  const duplicateFormLink = React.useMemo(
    () => (record: any) => {
      let routePrefix = ''

      if (record.category === 'individual') routePrefix = 'i'
      else if (record.category === 'group_note') routePrefix = 'gn'

      return {
        pathname: `${match.url}/${routePrefix}/new`,
        parent: match,
        data: record,
      }
    },
    [],
  )

  return (
    <Grid gap="1rem" columns="100%">
      <PeerNotesDataTable
        {...tableProps}
        title="Individual Peer Notes"
        to={to}
        hiddenColumnIds={['client']}
        duplicateFormLink={duplicateFormLink}
        help={<HelpTagIframe id="peer_notes_individual" />}
        getCanSelect={(data: any) =>
          data.status === 'signed_off'
            ? { canSelect: false, message: 'Signed Off records cannot be edited' }
            : data.status === 'closed'
            ? { canSelect: false, message: 'Closed records cannot be edited' }
            : { canSelect: true }
        }
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/peer-notes',
          },
        ]}
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'peer_notes.delete',
            action: async ({ ids }: any) => {
              await deleteRecords(ids.join(','))
            },
          },
        ]}
        emptyActions={
          canCreate && (
            <Button
              label="Add Individual Note"
              type="primary"
              glyph="add"
              link={{
                pathname: `${location.pathname}/i/new`,
                parent: { url: location.pathname },
                data: { client },
              }}
              permission="peer_notes.create"
            />
          )
        }
      />
    </Grid>
  )
}

export default IndividualPeerNotes
