import React from 'react'

import { getColor } from '../../theme'
import SmartStatus from '../SmartStatus'

const statuses = {
  alert: {
    label: 'Alert',
    color: 'red',
    glyph: 'circle_error',
  },
  warning: {
    label: 'Warning',
    color: 'orange',
    glyph: 'warning',
  },
  info: {
    label: 'Info',
    color: 'blue',
    glyph: 'info',
  },
  note: {
    label: 'Note',
    color: 'gray',
    glyph: 'note',
  },
}

const MessageStatus = (props) => {
  return (
    <SmartStatus
      statuses={statuses}
      glyph={statuses[props.status]?.glyph}
      glyphColor={getColor(statuses[props.status]?.color)}
      {...props}
    />
  )
}

export default MessageStatus
