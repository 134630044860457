import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const statuses = {
  draft: {
    label: 'Draft',
    color: 'blue',
  },
  in_progress: {
    label: 'In Progress',
    color: 'yellow',
    isReviewStatus: true,
  },
  pending_review: {
    label: 'Pending Review',
    color: 'orange',
    isReviewStatus: true,
  },
  updates_required: {
    label: 'Updates Requested',
    color: 'red',
    isReviewStatus: true,
  },
  approved: {
    label: 'Approved',
    color: 'paleGreen',
    isReviewStatus: true,
    isSupervisorOnly: true,
  },
  rejected: {
    label: 'Rejected',
    color: 'red',
    isReviewStatus: true,
  },
  closed: {
    label: 'Closed',
    color: 'yellow',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
}

export const DataFormStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}
