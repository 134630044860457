import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { NaatpReportDataTable } from '@behavehealth/constructs/LiveReports/NaatpReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Naatp = () => {
  const tableProps = useDataTable({
    name: ['reports', 'naatp-reports'],
    endpoint: `/live_reports/vendors?category=naatp`,
    localStorageKey: 'report_naatp_reports_v1',
  })

  return (
    <Page feature="outcome_measures" title="NAATP Reports">
      <Grid>
        <NaatpReportDataTable {...tableProps} />
      </Grid>
    </Page>
  )
}

export default withPageError(Naatp)
