import React from 'react'

import Card from '../../components/Card'
import Text from '../../components/Typography/Text'
import Flex from '../../components/Flex'

import ERXButton from '../../components/Buttons/ERXButton'
import TooltipButton from '../../components/TooltipButton'

import { useCreate } from '../../hooks/useNewAPI'

export const EPrescribingAccess = ({ tenant, client }: any) => {
  if (!tenant) return null
  if (!client) return null

  const showERXFeatures = tenant?.feature_flags?.erx === true
  if (!showERXFeatures) return null

  const [loading, setLoading] = React.useState(false)

  const { mutateAsync: syncPatient }: any = useCreate({
    name: ['client', client.id],
    url: `/apps/rcopia/sync_patient/${client.id}`,
    invalidate: ['clients'],
  })

  const isSyncedWithERX = client.erx_status === 'synced'

  const syncRcopia = async () => {
    try {
      setLoading(true)

      await syncPatient()

      setLoading(false)
    } catch (errors) {
      console.error(errors)
      setLoading(false)
    }
  }

  return (
    <Card className="!p-4">
      {isSyncedWithERX ? (
        <Flex gap="1rem" css={{ justifyContent: 'space-between' }}>
          <Text>Use the button to the side to enter the e-Prescribe Portal</Text>
          <ERXButton rcopiaID={client.erx_id} rcopiaExternalID={client.external_id} />
        </Flex>
      ) : (
        <Flex gap="1rem" css={{ justifyContent: 'space-between' }}>
          <Text>
            To e-Prescribe you must first Sync with the eRX system. To do so, the Client must have a valid First Name, Last Name, Sex and
            Date of Birth.
          </Text>

          <TooltipButton
            label="Sync Client with eRX"
            glyph="pill_bottle"
            color="red"
            isDisabled={!client.erx_valid}
            show={!client.erx_valid}
            isLoading={loading}
            message={
              <>
                To Sync, the Client must have a valid <b>First Name</b>, <b>Last Name</b>, <b>Sex</b> and <b>Date of Birth</b>
              </>
            }
            onClick={syncRcopia}
          />
        </Flex>
      )}
    </Card>
  )
}
