import React from 'react'
import { produce } from 'immer'
import { arrayMove } from '@dnd-kit/sortable'
import { createStore } from 'zustand'
import { DateTime } from 'luxon'
import { immer } from 'zustand/middleware/immer'
import { Liquid } from 'liquidjs'
import { useStore } from 'zustand'
import { v4 as uuid } from 'uuid'
import compact from 'lodash/compact'
import isFinite from 'lodash/isFinite'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { useSettings } from '../../hooks/useSettings'

import { createInitialPageWithContent, moveArrayItem, parseAnchorTimeVariable } from './utils/functions'
import { ELEMENTS, FORM_ELEMENTS } from './elements/elements'
import { ELEMENT_EDITOR_STATES, FORM_ELEMENTS_VALUE_TYPES, VARIABLE_SUPPORT_FIELDS } from './utils/constants'
import { DT, usDate } from '../../utils/functions'

const DEBUG = false

const DEFAULT_STATE = {
  activeElementId: null,
  activePageId: null,
  apiData: null,
  environment: 'builder',
  isEditable: false,
  isNew: false,
  pages: {},
  pagesOrder: [],
  localAIInputs: [],
  globalAIInputs: [],
  formSettings: {
    name: 'New Form',
    category: '',
    status: 'draft',
  },
}

const liquid = new Liquid()

const NewFormBuilderContext: any = React.createContext(null)

export const NewFormBuilderProvider = ({ children, ...props }: any) => {
  const storeRef = React.useRef<any>()

  const { formVariables = [], timezone, isHQApp } = useSettings()

  const globalFormVariables = [...formVariables]

  if (!storeRef.current) {
    storeRef.current = initializeStore({
      ...props,
      globalFormVariables,
      timezone,
      isHQApp,
    })
  }

  return <NewFormBuilderContext.Provider value={storeRef.current}>{children}</NewFormBuilderContext.Provider>
}

export const useFormBuilder = (selector: any, equalityFn?: any) => {
  const store: any = React.useContext(NewFormBuilderContext)

  if (!store) throw new Error('Missing NewFormBuilderProvider.Provider in the tree')

  return useStore(store, selector, equalityFn)
}

const ENVIRONMENTS = ['builder', 'submission', 'preview']

const initializeStore = (initialState = DEFAULT_STATE) => {
  const { apiData, isNew, timezone, globalFormVariables, previousBuilderState }: any = initialState

  const pages = {}
  const pagesOrder: string[] = []
  const hasApiData = size(apiData) > 0

  const allElements: any = {}
  const formVariables: any = []

  let localAIInputs: any = []
  let globalAIInputs: any = []

  let variablesTimeAnchor = DateTime.local().setZone(timezone).toISO()

  const formSettings = hasApiData
    ? {
        name: apiData?.name || 'New Form',
        category: apiData?.category || '',
        status: apiData?.status || 'draft',
        success_message: apiData?.success_message || '',
        internal_notes: apiData?.internal_notes || '',
        shared_links: apiData?.shared_links || '',
        use_public_sharing: !!apiData?.use_public_sharing,
        public_sharing_settings: apiData?.public_sharing_settings || {},
        use_reference: !!apiData?.use_reference,
        reference_settings: apiData?.reference_settings || {},
        use_notifications: !!apiData?.use_notifications,
        notification_settings: apiData?.notification_settings || {},
        use_portal_permissions: !!apiData?.use_portal_permissions,
        portal_permissions_settings: apiData?.portal_permissions_settings || {},
        use_review_and_signoff_workflow: !!apiData?.use_review_and_signoff_workflow,
        review_and_signoff_workflow_settings: apiData?.review_and_signoff_workflow_settings || {},
        use_pdf_export: !!apiData?.use_pdf_export,
        pdf_export_settings: apiData?.pdf_export_settings || {},
        excel_csv_export_category: apiData?.excel_csv_export_category || '',
      }
    : DEFAULT_STATE.formSettings

  // create initial page with content if new form
  if (isNew && !hasApiData) {
    const initialContent = createInitialPageWithContent()

    pages[initialContent.page.uuid] = initialContent.page
    pagesOrder.push(initialContent.page.uuid)

    for (const element of initialContent.elements) {
      allElements[element.uuid] = element
    }
  }
  // parse API data if available
  else if (hasApiData) {
    const { form_pages = [], elements_order = [], variables_time_anchor, form_variables, ai_inputs, global_ai_inputs } = apiData

    // process anchor time for variables
    if (variables_time_anchor) {
      variablesTimeAnchor = DateTime.fromISO(variables_time_anchor).setZone(timezone).toISO()
    }

    // process form variables
    if (size(form_variables) > 0) {
      for (const variable of form_variables) {
        formVariables.push(variable)
      }
    }

    // process AI Inputs
    if (ai_inputs) localAIInputs = ai_inputs
    if (global_ai_inputs) globalAIInputs = global_ai_inputs

    const pageIds: string[] = []
    const disabledPageIds: string[] = []

    let isElementsOrderValid = true

    for (const page of form_pages) {
      if (!page) {
        console.error('Invalid page', page)
        continue
      }

      if (!elements_order?.includes?.(page.uuid)) {
        isElementsOrderValid = false
      }

      const { id, parent_uuid, name, config } = page

      if (initialState.environment === 'submission' && config.is_disabled) {
        disabledPageIds.push(page.uuid)
        continue
      }

      pageIds.push(page.uuid)

      pages[page.uuid] = {
        id,
        uuid: page.uuid,
        parent_uuid,
        name,
        config,
        elements_order: page.elements_order || [],
      }

      if (size(page.form_blocks) > 0) {
        for (const elementId in page.form_blocks) {
          const element = page.form_blocks[elementId]

          allElements[element.uuid] = {
            ...element,
            _isOpen: undefined,
            _isActive: undefined,
            _isConditionalTrigger: undefined,
          }

          if (initialState.isEditable && previousBuilderState?.activeElementId === element.uuid) {
            allElements[element.uuid]._isActive = true
          }
        }
      }
    }

    // rebuild open state for all pages
    if (initialState.isEditable && size(previousBuilderState?.openPageIds) > 0) {
      for (const pageId of previousBuilderState.openPageIds) {
        if (!pages[pageId]) continue

        pages[pageId]._isOpen = true
      }
    }

    if (isElementsOrderValid) {
      pagesOrder.push(...elements_order.filter((id: string) => pageIds.includes(id)))
    } else {
      pagesOrder.push(...pageIds)
    }
  }

  const nowDT = DateTime.local().setZone(timezone)
  const anchorDT = DT(variablesTimeAnchor, timezone)

  const LOCAL_TIME_VARIABLES = [
    {
      name: 'Today',
      category: 'time',
      variable_key: 'TV_TODAY',
      variable_value: usDate(nowDT, timezone),
      description: "Today's Date",
    },
    {
      name: 'Tomorrow',
      category: 'time',
      variable_key: 'TV_TOMORROW',
      variable_value: usDate(nowDT.plus({ days: 1 }), timezone),
      description: "Tomorrow's Date",
    },
    {
      name: 'Anchor Date',
      category: 'time',
      variable_key: 'TV_ANCHOR_DATE',
      variable_value: usDate(anchorDT, timezone),
      description: 'Anchor Date',
    },
    {
      name: '1 Day from Anchor Date',
      category: 'time',
      variable_key: 'TV_1_DAY_FROM_ANCHOR_DATE',
      variable_value: usDate(anchorDT.plus({ days: 1 }), timezone),
      description: '1 Day from Anchor Date',
    },
    {
      name: '1 Week from Anchor Date',
      category: 'time',
      variable_key: 'TV_1_WEEK_FROM_ANCHOR_DATE',
      variable_value: usDate(anchorDT.plus({ weeks: 1 }), timezone),
      description: '1 Week from Anchor Date',
    },
    {
      name: '2 Weeks from Anchor Date',
      category: 'time',
      variable_key: 'TV_2_WEEKS_FROM_ANCHOR_DATE',
      variable_value: usDate(anchorDT.plus({ weeks: 1 }), timezone),
      description: '2 Weeks from Anchor Date',
    },
    {
      name: '1 Month from Anchor Date',
      category: 'time',
      variable_key: 'TV_1_MONTH_FROM_ANCHOR_DATE',
      variable_value: usDate(anchorDT.plus({ months: 1 }), timezone),
      description: '1 Month from Anchor Date',
    },
  ]

  // process variables
  const variables = isNew ? [...globalFormVariables, ...LOCAL_TIME_VARIABLES, ...formVariables] : formVariables
  const variableValues = {}

  if (!isNew && initialState.isHQApp) {
    for (const timeVariable of LOCAL_TIME_VARIABLES) {
      const variable = variables.find((v: any) => v.variable_key === timeVariable.variable_key)

      if (variable) continue

      variables.push(timeVariable)
    }
  }

  for (const variable of variables) {
    const { variable_key, variable_value } = variable

    if (!variable_key || !variable_value) continue

    variableValues[variable_key] = variable_value
  }

  return createStore(
    immer((set: any, get: any) => ({
      ...DEFAULT_STATE,
      ...initialState,

      timezone,

      // --- Environment ---
      setEnvironment: (value: 'builder' | 'submission' | 'preview') => {
        if (!value) return

        if (!ENVIRONMENTS.includes(value)) {
          console.error('Invalid environment', value)
          return
        }

        set((state: any) => {
          state.environment = value
        })
      },

      // --- Edit State ---
      isEditable: !!initialState.isEditable,
      setIsEditable: (isEditable: boolean) => {
        set((state: any) => {
          state.isEditable = isEditable
        })
      },

      // --- Mermaid Editor ---
      isMermaidOpen: false,
      setIsMermaidOpen: (isOpen: boolean) => {
        set((state: any) => {
          state.isMermaidOpen = !!isOpen
        })
      },

      // --- AI Inputs ---
      localAIInputs,
      globalAIInputs,

      upsertLocalAIInput: (input: any) => {
        set((state: any) => {
          // update existing input
          for (let i = 0; i < state.localAIInputs.length; i++) {
            if (state.localAIInputs[i].external_id === input.external_id) {
              state.localAIInputs[i] = input
              return
            }
          }

          // add new input
          state.localAIInputs.push(input)
        })
      },

      deleteLocalAIInput: (externalId: any) => {
        if (!externalId) return

        set((state: any) => {
          state.localAIInputs = state.localAIInputs.filter((input: any) => input.external_id !== externalId)
        })
      },

      // --- Preview Mode ---
      isPreviewMode: false,
      exitPreviewMode: () => {
        set((state: any) => {
          state.isPreviewMode = false
        })

        get().setEnvironment('builder')
      },

      togglePreviewMode: async () => {
        const setActiveElementId = get().setActiveElementId
        const parseAllElementConfigVariables = get().parseAllElementConfigVariables

        const currentValue = get().isPreviewMode
        const nextValue = !currentValue

        set((state: any) => {
          state.isPreviewMode = nextValue
        })

        if (nextValue === true) {
          setActiveElementId(null)
          await parseAllElementConfigVariables()
          get().setEnvironment('preview')
        } else {
          get().setEnvironment('builder')
        }
      },

      // --- Form Settings ---
      formSettings,
      setFormSettings: (settings: any) => {
        set((state: any) => {
          state.formSettings = {
            ...state.formSettings,
            ...settings,
          }
        })
      },

      // --- Unsaved Changes ---
      hasUnsavedChanges: false,
      setHasUnsavedChanges: (value: boolean) => {
        set((state: any) => {
          state.hasUnsavedChanges = !!value
        })
      },

      // --- AI Chat ---
      aiChatId: null,
      setAIChatId: (id: string) => {
        set((state: any) => {
          state.aiChatId = id
        })
      },

      // --- API ---
      getFormattedApiData: async () => {
        DEBUG && console.debug('📝 getFormattedApiData()')

        const pages = get().pages
        const formSettings = get().formSettings
        const deletedPageIds = get().deletedPageIds
        const parseAllElementConfigVariables = get().parseAllElementConfigVariables
        const variables = get().variables
        const variableValues = get().variableValues
        const variablesTimeAnchor = get().variablesTimeAnchor

        const pagesOrder = get().pagesOrder
        const form_pages_attributes: any = []
        const form_variables_attributes: any = []

        await parseAllElementConfigVariables({ forceProcessAll: true })

        // parse all pages to construct the API data
        for (const pageId of pagesOrder) {
          const page = produce(pages[pageId], (draft) => {
            if (draft?._isOpen) delete draft._isOpen
            if (draft?._isActive) delete draft._isActive
          })

          if (!page) continue

          const form_blocks_attributes: any = []
          const deletedElements = get().deletedElementsByPageId[pageId] || []

          DEBUG && console.debug(`📝 processing page "${page.name}" ${pageId}`)

          // collect all page elements and their children
          for (const elementId of page.elements_order) {
            const element = get().allElements[elementId]
            const isDeleted = element?.id && deletedElements.includes(element.id)

            if (!element || isDeleted) continue

            const childElements = get().collectAllChildren(elementId)
            const formattedChildElements: any = []

            const formattedElement = produce(element, (draft) => {
              if (draft?._isOpen) delete draft._isOpen
              if (draft?._isActive) delete draft._isActive
              if (draft?._isConditionalTrigger) delete draft._isConditionalTrigger
            })

            for (const childElement of childElements) {
              const formattedChildElement = produce(childElement, (draft) => {
                if (draft?._isOpen) delete draft._isOpen
                if (draft?._isActive) delete draft._isActive
                if (draft?._isConditionalTrigger) delete draft._isConditionalTrigger
              })

              formattedChildElements.push(formattedChildElement)
            }

            form_blocks_attributes.push(formattedElement, ...formattedChildElements)
          }

          // collect all deleted elements
          for (const deletedElementId of deletedElements) {
            form_blocks_attributes.push({ id: deletedElementId, _destroy: 1 })
          }

          // construct form page with form blocks
          form_pages_attributes.push({ ...page, form_blocks_attributes })
        }

        for (const deletedPageId of deletedPageIds) {
          form_pages_attributes.push({ id: deletedPageId, _destroy: 1 })
        }

        // construct form variables
        for (const variable of variables) {
          // if (get().isHQApp && variable.category === 'time') {
          //   form_variables_attributes.push({
          //     ...variable,
          //     variable_value: variableValues[variable.variable_key],
          //   })

          //   continue
          // }

          if (variable.category !== 'form') continue

          form_variables_attributes.push({
            ...variable,
            variable_value: variableValues[variable.variable_key],
          })
        }

        return {
          ...formSettings,
          elements_order: pagesOrder,
          form_pages_attributes,
          form_variables_attributes,
          variables_time_anchor: variablesTimeAnchor,
        }
      },

      // --- Pages ---
      pages,
      pagesOrder,

      addPage: () => {
        const newPageId = uuid()
        const setActivePageId = get().setActivePageId
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        set((state: any) => {
          state.pages[newPageId] = {
            uuid: newPageId,
            name: 'New Page',
            elements_order: [],
            config: {
              content_width: 'large',
            },
          }

          state.pagesOrder.push(newPageId)
        })

        setActivePageId(newPageId)
        setHasUnsavedChanges(true)
      },

      disablePage: (pageId) => {
        if (!pageId) return

        const setHasUnsavedChanges = get().setHasUnsavedChanges

        set((state: any) => {
          if (!state.pages[pageId]?.config) return

          state.pages[pageId].config.is_disabled = true
        })

        setHasUnsavedChanges(true)
      },

      activatePage: (pageId) => {
        if (!pageId) return

        const setHasUnsavedChanges = get().setHasUnsavedChanges

        set((state: any) => {
          if (!state.pages[pageId]?.config) return

          state.pages[pageId].config.is_disabled = false
        })

        setHasUnsavedChanges(true)
      },

      deletedPageIds: [],
      deletePage: (pageId) => {
        if (!pageId) return

        const page = get().pages[pageId]
        const pagesOrder = get().pagesOrder
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        const index = pagesOrder.indexOf(pageId)
        const wasActivePage = get().activePageId === pageId
        const nextActivePageId = pagesOrder[index - 1] || pagesOrder[index + 1]

        if (!page) {
          console.error('Invalid page', pageId)
          return
        }

        const elementIdsToDelete: any = []
        const pageRootElements = page.elements_order || []

        for (const elementId of pageRootElements) {
          elementIdsToDelete.push(elementId, ...get().collectAllChildrenIds(elementId))
        }

        set((state: any) => {
          // handle page elements deletion
          for (const elementId of elementIdsToDelete) {
            const serverId = state.allElements[elementId].id

            // handle server element deletion
            if (serverId) {
              // create deletedElementsByPageId array if it doesn't exist
              state.deletedElementsByPageId[pageId] = state.deletedElementsByPageId[pageId] || []

              // mark server element for deletion
              state.deletedElementsByPageId[pageId].push(serverId)

              continue
            }

            // handle local element deletion
            delete state.allElements[elementId]
          }

          // mark for deletion if server page
          if (!!page.id) {
            state.deletedPageIds.push(page.id)
            state.pages[pageId]._destroy = 1
          }
          // else remove local page
          else {
            delete state.pages[pageId]
          }

          // remove page from pages order
          state.pagesOrder = state.pagesOrder.filter((id) => id !== pageId)
        })

        // set next active page if the deleted page was active
        if (wasActivePage && nextActivePageId) {
          const setActivePageId = get().setActivePageId
          setActivePageId(nextActivePageId)
        }

        setHasUnsavedChanges(true)
      },

      updatePage: ({ pageId, name, config }) => {
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        set((state: any) => {
          if (!state.pages[pageId]) return

          if (name) state.pages[pageId].name = name

          if (config) state.pages[pageId].config = { ...state.pages[pageId].config, ...config }
        })

        setHasUnsavedChanges(true)
      },

      setPageOpen: ({ pageId, isOpen }) => {
        set((state: any) => {
          if (!state.pages[pageId]) return

          state.pages[pageId]._isOpen = !!isOpen
        })
      },

      movePage: (pageId, toIndex) => {
        if (isUndefined(pageId) || isUndefined(toIndex)) return

        const pagesOrder = get().pagesOrder
        const fromIndex = pagesOrder.indexOf(pageId)
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        if (fromIndex === -1) return

        const lastIndex = size(pagesOrder) - 1
        const moveToIndex = fromIndex < toIndex ? toIndex - 1 : toIndex
        const finalToIndex = moveToIndex < 0 ? 0 : moveToIndex > lastIndex ? lastIndex : moveToIndex

        set((state: any) => {
          state.pagesOrder = arrayMove(pagesOrder, pagesOrder.indexOf(pageId), finalToIndex)
        })

        setHasUnsavedChanges(true)
      },

      moveElement: (elementId: string, direction: 'up' | 'down') => {
        const allElements = get().allElements
        const findElementPageId = get().findElementPageId
        const pages = get().pages
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        const elementToMove = allElements?.[elementId]

        if (!elementToMove) return

        const parentId = elementToMove.parent_uuid
        const parent = allElements?.[parentId]

        // move child element within parent
        if (parent) {
          const newParentElementsOrder = moveArrayItem(elementId, parent.elements_order, direction)

          set((state: any) => {
            state.allElements[parentId].elements_order = newParentElementsOrder
          })

          setHasUnsavedChanges(true)

          return
        }

        // move root element within page
        const pageId = findElementPageId(elementId)
        const page = pages?.[pageId]

        if (!page) return

        const newPageElementsOrder = moveArrayItem(elementId, page.elements_order, direction)

        set((state: any) => {
          state.pages[pageId].elements_order = newPageElementsOrder
        })

        setHasUnsavedChanges(true)
      },

      moveElementToPage: ({ elementId, toPageId, toIndex }) => {
        if (!elementId || !toPageId || !isFinite(toIndex)) return

        const element = get().allElements[elementId]
        const toPage = get().pages[toPageId]
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        if (!element || !toPage) {
          console.error('Invalid element or page', { elementId, toPageId, toIndex })
          return
        }

        const isRootElement = !element.parent_uuid
        const fromPageId = get().findElementPageId(elementId)
        const fromPage = get().pages[fromPageId]
        const isSamePage = fromPageId === toPageId

        // delete server elements from previous page if moving to a new page
        if (!isSamePage) {
          const nestedChildren = get().collectAllChildren(elementId)

          set((state: any) => {
            const serverId = state.allElements[elementId].id

            // if server element, mark it for deletion on the previous page
            if (serverId) {
              state.deletedElementsByPageId[fromPageId] = state.deletedElementsByPageId[fromPageId] || []
              state.deletedElementsByPageId[fromPageId].push(state.allElements[elementId].id)

              // remove server id from the element, effectively creating a new element on the new page
              delete state.allElements[elementId].id
            }

            // if any child server elements, mark them for deletion on the previous page
            for (const child of nestedChildren) {
              if (!child.id) continue

              state.deletedElementsByPageId[fromPageId] = state.deletedElementsByPageId[fromPageId] || []
              state.deletedElementsByPageId[fromPageId].push(child.id)

              delete state.allElements[child.uuid].id
            }
          })
        }

        // handle reordering elements within the same page
        if (isRootElement && isSamePage) {
          const fromIndex = fromPage.elements_order.indexOf(elementId)
          const finalToIndex = fromIndex < toIndex ? toIndex - 1 : toIndex

          // element not found in the from page
          if (fromIndex === -1) {
            console.error('Invalid from index', { elementId, fromPageId, toPageId })
            return
          }

          // element is already at the specified index in the same page
          if (fromIndex === toIndex) return

          // reorder page elements
          set((state: any) => {
            const newElementsOrder = arrayMove(state.pages[toPageId].elements_order, fromIndex, finalToIndex)
            state.pages[toPageId].elements_order = newElementsOrder
          })

          return
        }

        // handle moving page root element to another page
        if (isRootElement && !isSamePage) {
          const fromIndex = fromPage.elements_order.indexOf(elementId)

          // element not found in the from page
          if (fromIndex === -1) {
            console.error('Invalid from index', { elementId, fromPageId, toPageId })
            return
          }

          // set updated elements order for previous and next page
          set((state: any) => {
            state.pages[fromPageId].elements_order.splice(fromIndex, 1)
            state.pages[toPageId].elements_order.splice(toIndex, 0, elementId)
          })

          return
        }

        // handle moving nested element to page root
        if (!isRootElement) {
          const parentElement = get().allElements[element.parent_uuid]

          // parent element not found
          if (!parentElement) {
            console.error('Invalid parent element', { elementId, toPageId, toIndex })
            return
          }

          const fromIndex = parentElement.elements_order.indexOf(elementId)

          // element not found in the parent element
          if (fromIndex === -1) {
            console.error('Element not found in parent', { elementId, toPageId, toIndex })
            return
          }

          set((state: any) => {
            // remove element from parent's children
            state.allElements[element.parent_uuid].elements_order.splice(fromIndex, 1)

            // add element to page root at the specified index
            state.pages[toPageId].elements_order.splice(toIndex, 0, elementId)

            // remove element's reference to parent
            state.allElements[elementId].parent_uuid = null
          })
        }

        setHasUnsavedChanges(true)
      },

      moveElementToParent: ({ elementId, toElementId, toIndex }) => {
        if (!elementId || !toElementId || !isFinite(toIndex)) return

        const element = get().allElements[elementId]
        const toParent = get().allElements[toElementId]

        const setHasUnsavedChanges = get().setHasUnsavedChanges

        if (!element || !toParent) {
          console.error('Invalid element or parent', { elementId, toElementId, toIndex })
          return
        }

        const fromPageId = get().findElementPageId(elementId)
        const toPageId = get().findElementPageId(toElementId)
        const isSamePage = fromPageId === toPageId

        // delete server elements from previous page if moving to a new page
        if (!isSamePage) {
          const nestedChildren = get().collectAllChildren(elementId)

          set((state: any) => {
            const serverId = state.allElements[elementId].id

            // if server element, mark it for deletion on the previous page
            if (serverId) {
              state.deletedElementsByPageId[fromPageId] = state.deletedElementsByPageId[fromPageId] || []
              state.deletedElementsByPageId[fromPageId].push(state.allElements[elementId].id)

              // remove server id from the element, effectively creating a new element on the new page
              delete state.allElements[elementId].id
            }

            // if any child server elements, mark them for deletion on the previous page
            for (const child of nestedChildren) {
              if (!child.id) continue

              state.deletedElementsByPageId[fromPageId] = state.deletedElementsByPageId[fromPageId] || []
              state.deletedElementsByPageId[fromPageId].push(child.id)

              delete state.allElements[child.uuid].id
            }
          })
        }

        const fromParentId = element.parent_uuid
        const fromParent = get().allElements[element.parent_uuid]

        const isRootElement = !fromParentId
        const isSameParent = !!fromParentId && fromParentId === toElementId

        // handle moving element within the same parent
        if (!isRootElement && isSameParent) {
          const fromIndex = fromParent.elements_order.indexOf(elementId)

          // element not found in the parent element
          if (fromIndex === -1) {
            console.error('Invalid from index', { elementId, toElementId, toIndex })
            return
          }

          // element is already at the specified index in the same parent
          if (fromIndex === toIndex) return

          // reorder parent's children
          set((state: any) => {
            const newElementsOrder = arrayMove(state.allElements[toElementId].elements_order, fromIndex, toIndex)
            state.allElements[toElementId].elements_order = newElementsOrder
          })

          return
        }

        // handle moving element from one parent to another
        if (!isRootElement && !isSameParent) {
          const fromIndex = fromParent.elements_order.indexOf(elementId)

          // element not found in the parent element
          if (fromIndex === -1) {
            console.error('Invalid from index', { elementId, toElementId, toIndex })
            return
          }

          set((state: any) => {
            // remove element from previous parent
            state.allElements[fromParentId].elements_order.splice(fromIndex, 1)

            // add element to new parent at the specified index
            state.allElements[toElementId].elements_order.splice(toIndex, 0, elementId)

            // set element's parent to the new parent
            state.allElements[elementId].parent_uuid = toElementId
          })

          return
        }

        // handle moving element from page root to parent
        if (isRootElement) {
          const fromPageId = get().findElementPageId(elementId)
          const fromPage = get().pages[fromPageId]

          if (!fromPage) {
            console.error('Invalid from page', { elementId, toElementId, toIndex })
            return
          }

          const fromIndex = fromPage.elements_order.indexOf(elementId)

          // element not found in the from page
          if (fromIndex === -1) {
            console.error('Invalid from index', { elementId, toElementId, toIndex })
            return
          }

          set((state: any) => {
            // remove element from page root
            state.pages[fromPageId].elements_order.splice(fromIndex, 1)

            // create new parent's children array if it doesn't exist
            if (!state.allElements[toElementId].elements_order) {
              state.allElements[toElementId].elements_order = []
            }

            // add element to parent's children at the specified index
            state.allElements[toElementId].elements_order.splice(toIndex, 0, elementId)

            // set element's parent to the new parent
            state.allElements[elementId].parent_uuid = toElementId
          })

          return
        }

        setHasUnsavedChanges(true)
      },

      // --- Elements Deletion ---
      deletedElementsByPageId: {},

      deleteElement: (elementId) => {
        if (!elementId) return

        const setActiveElementId = get().setActiveElementId
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        const element = get().allElements[elementId]
        const pageId = get().findElementPageId(elementId)
        const page = get().pages[pageId]

        const isRootElement = !element.parent_uuid && page?.elements_order?.includes(elementId)

        if (!element || !pageId) return

        const childrenIds = get().collectAllChildrenIds(elementId)
        const elementIdsToDelete = [elementId, ...childrenIds]

        // Find the next element to select
        let nextActiveElementId = null

        if (isRootElement) {
          const currentIndex = page.elements_order.indexOf(elementId)
          const nextElementId = page.elements_order[currentIndex + 1]
          const prevElementId = page.elements_order[currentIndex - 1]

          nextActiveElementId = nextElementId || prevElementId || null
        } else {
          const parentElement = get().allElements[element.parent_uuid]
          const currentIndex = parentElement?.elements_order?.indexOf?.(elementId)
          const nextElementId = parentElement?.elements_order?.[currentIndex + 1]
          const prevElementId = parentElement?.elements_order?.[currentIndex - 1]

          nextActiveElementId = nextElementId || prevElementId || element.parent_uuid || null
        }

        set((state: any) => {
          for (const deleteId of elementIdsToDelete) {
            const serverId = state.allElements[deleteId].id
            const parentId = state.allElements[deleteId].parent_uuid

            // handle conditional element deletion
            if (state.allElements?.[deleteId]?.category === 'conditional' && state?.allElements?.[deleteId]?.config?.element_uuid) {
              delete state.allElements?.[state.allElements?.[deleteId]?.config?.element_uuid]?._isConditionalTrigger
            }

            // if element is nested, remove it from parent
            if (parentId && state.allElements[parentId]?.elements_order) {
              state.allElements[parentId].elements_order = state.allElements[parentId].elements_order.filter(
                (childId) => childId !== deleteId,
              )
            } else {
              // else remove element from page root
              state.pages[pageId].elements_order = state.pages[pageId].elements_order.filter((childId) => childId !== deleteId)
            }

            // handle server element deletion
            if (serverId) {
              // create deletedElementsByPageId array if it doesn't exist
              if (!state.deletedElementsByPageId[pageId]) state.deletedElementsByPageId[pageId] = []

              // mark server element for deletion
              state.deletedElementsByPageId[pageId].push(serverId)
            } else {
              // handle local element deletion
              delete state.allElements[deleteId]
            }
          }
        })

        setActiveElementId(nextActiveElementId)
        setHasUnsavedChanges(true)
      },

      // --- Active Page ---
      activePageId: previousBuilderState?.activePageId || pagesOrder[0] || null,

      setActivePageId: (pageId: string) => {
        const currentActivePageId = get().activePageId

        // clicked on the same page
        if (currentActivePageId === pageId) return

        set((state: any) => {
          // skip if page is not found
          if (pageId && !state.pages[pageId]) return

          // set active page
          state.activePageId = pageId
        })

        const activeElementId = get().activeElementId
        const activeElementPageId = get().findElementPageId(activeElementId)

        // deselect active element if it's not on the active page
        if (activeElementPageId !== pageId) {
          const setActiveElementId = get().setActiveElementId
          setActiveElementId(null)
        }
      },

      // --- Active Tab ---
      activeTab: previousBuilderState?.activeTab || 'pages',
      setActiveTab: (tab: 'pages' | 'settings' | 'variables') => {
        set((state: any) => {
          state.activeTab = tab
        })
      },

      // --- Insert Tab ---
      insertTab: 'basic_elements',
      setInsertTab: (tab: 'basic_elements' | 'smart_elements') => {
        set((state: any) => {
          state.insertTab = tab
        })
      },

      addElement: ({ category, config, insertSiblings, insertChildren, insertAfterActiveElement }) => {
        const newElementId = uuid()
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        set((state) => {
          const activePageId = state.activePageId
          const activePage = state.pages[activePageId]

          const activeElementId = state.activeElementId
          const activeElementCategory = state.allElements[activeElementId]?.category
          const activeParentId = state.allElements[activeElementId]?.parent_uuid
          const isActiveElementAtRoot = !activeParentId

          const ELEMENT = ELEMENTS[category]
          const ACTIVE_ELEMENT = ELEMENTS?.[activeElementCategory]

          if (!ELEMENT || !activePage) return

          const defaultConfig = { ...config }

          for (const key in ELEMENT.defaultConfig) {
            // skip if a default value is already set via params
            if (defaultConfig.hasOwnProperty(key)) continue

            // add all default values from the element's config
            if (typeof ELEMENT.defaultConfig[key] === 'function') {
              defaultConfig[key] = ELEMENT.defaultConfig[key]()
            } else {
              defaultConfig[key] = ELEMENT.defaultConfig[key]
            }
          }

          const newElement = {
            uuid: newElementId,
            parent_uuid: insertAfterActiveElement && activeElementId && activeParentId ? activeParentId : null,
            config: defaultConfig,
            category,
            elements_order: ELEMENT.allowChildren ? [] : undefined,
          }

          // set conditional trigger if new element is a conditional and prev selected element can be used as a trigger
          if (category === 'conditional' && FORM_ELEMENTS_VALUE_TYPES.hasOwnProperty(activeElementCategory)) {
            newElement.config.element_uuid = activeElementId
          }

          // process any default children the element has defined
          const children: any = []

          if (ELEMENT.allowChildren) {
            if (size(ELEMENT.defaultChildren) > 0) {
              for (const childConfig of ELEMENT.defaultChildren) {
                // skip if child element config is not found
                if (!ELEMENTS[childConfig.category]) continue

                // set up child and link to newly created element
                children.push({
                  uuid: uuid(),
                  parent_uuid: newElementId,
                  config: ELEMENTS[childConfig.category].defaultConfig,
                  category: childConfig.category,
                })
              }
            }

            if (size(insertChildren) > 0) {
              for (const childConfig of insertChildren) {
                // skip if child element config is not found
                if (!ELEMENTS[childConfig.category]) continue

                // set up child and link to newly created element
                children.push({
                  uuid: uuid(),
                  parent_uuid: newElementId,
                  config: {
                    ...ELEMENTS[childConfig.category].defaultConfig,
                    ...childConfig.defaultConfig,
                  },
                  category: childConfig.category,
                })
              }
            }
          }

          // add new element to the all elements object
          state.allElements[newElementId] = newElement

          // add new element to page after the active element
          if (insertAfterActiveElement && activeElementId && isActiveElementAtRoot) {
            const activeElementIndex = activePage.elements_order.indexOf(activeElementId)
            const newElementsOrder = [...activePage.elements_order]

            newElementsOrder.splice(activeElementIndex + 1, 0, newElementId)
            state.pages[activePageId].elements_order = newElementsOrder
          }
          // add new element to active element's children
          else if (insertAfterActiveElement && activeElementId && activeParentId) {
            const activeElementIndex = state.allElements[activeParentId].elements_order.indexOf(activeElementId)
            const newElementsOrder = [...state.allElements[activeParentId].elements_order]

            newElementsOrder.splice(activeElementIndex + 1, 0, newElementId)
            state.allElements[activeParentId].elements_order = newElementsOrder
          }
          // add new element to the end of the active page
          else {
            state.pages[activePageId].elements_order.push(newElementId)
          }

          // add new element's children
          for (const child of children) {
            state.allElements[child.uuid] = child
            state.allElements[newElementId].elements_order.push(child.uuid)
          }
        })

        // select newly added element
        const setActiveElementId = get().setActiveElementId
        setActiveElementId(newElementId)

        if (insertSiblings) {
          const toInsert = insertSiblings(get().allElements[newElementId])

          for (const sibling of toInsert) {
            get().addElement({
              category: sibling.category,
              config: sibling.defaultConfig,
              insertChildren: sibling.insertChildren,
              insertAfterActiveElement: true,
            })
          }
        }

        setHasUnsavedChanges(true)
      },

      duplicateElement: (elementId: string) => {
        if (!elementId) return

        const setActiveElementId = get().setActiveElementId
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        const element = get().allElements[elementId]
        const elementChildren = get().collectAllChildren(elementId)
        const elementChildrenIds = elementChildren.map((child) => child.uuid)

        const parentPageId = get().findElementPageId(elementId)
        const parentElementId = element?.parent_uuid
        const parentElement = get().allElements?.[parentElementId]
        const isNested = !!parentElement

        if (!element) return

        const updatedIds: any = {}

        const getNewId = (id: string | null) => {
          if (!id) return null

          if (updatedIds[id]) return updatedIds[id]

          const newId = uuid()
          updatedIds[id] = newId

          return newId
        }

        const newElement: any = produce(element, (draft) => {
          delete draft.id
          delete draft._isOpen
          delete draft._isActive
          delete draft._isConditionalTrigger

          draft.uuid = getNewId(draft.uuid)

          if (draft.elements_order) {
            draft.elements_order = draft.elements_order.map((id: string) => getNewId(id))
          }
        })

        const newChildren: any = elementChildren.map((child) =>
          produce(child, (draft) => {
            delete draft.id
            delete draft._isOpen
            delete draft._isActive
            delete draft._isConditionalTrigger

            draft.uuid = getNewId(draft.uuid)
            draft.parent_uuid = getNewId(draft.parent_uuid)

            if (draft.elements_order) {
              draft.elements_order = draft.elements_order.map((id: string) => getNewId(id))
            }

            // update conditional's trigger if the trigger element is also being duplicated
            if (draft.category === 'conditional' && elementChildrenIds.includes(draft.config.element_uuid)) {
              draft.config.element_uuid = getNewId(draft.config.element_uuid)
            }
          }),
        )

        set((state: any) => {
          // create duplicated root element
          state.allElements[newElement.uuid] = newElement

          // create duplicated children
          for (const child of newChildren) {
            state.allElements[child.uuid] = child
          }

          if (isNested) {
            // insert duplicated element to parent's children
            const currentIndex = parentElement.elements_order.indexOf(elementId)

            state.allElements[parentElementId].elements_order.splice(currentIndex + 1, 0, newElement.uuid)
          } else {
            // insert duplicated element to page
            const currentIndex = state.pages[parentPageId].elements_order.indexOf(elementId)

            state.pages[parentPageId].elements_order.splice(currentIndex + 1, 0, newElement.uuid)
          }
        })

        setTimeout(() => {
          setActiveElementId(newElement.uuid)
        }, 0)

        setHasUnsavedChanges(true)
      },

      // --- Active Element ---
      activeElementId: previousBuilderState?.activeElementId || null,

      setActiveElementId: (elementId) => {
        const element = get().allElements?.[elementId]
        const isEditable = get().isEditable
        const prevActiveElementId = get().activeElementId
        const prevActiveElement = get().allElements?.[prevActiveElementId]

        set((state) => {
          // handle deselect
          if (elementId === null) {
            state.activeElementId = null
          }

          // remove active state from previous element
          if (state.allElements[prevActiveElementId]?._isActive) {
            delete state.allElements[prevActiveElementId]?._isActive
          }

          // add active state to next element
          if (!state.allElements[elementId]) return

          state.activeElementId = elementId
          state.allElements[elementId]._isActive = true
        })

        // set active page based on the selected element
        const findElementPageId = get().findElementPageId
        const activePageId = get().activePageId
        const setActivePageId = get().setActivePageId

        const elementPageId = findElementPageId(elementId)

        if (elementPageId && elementPageId !== activePageId) {
          setActivePageId(elementPageId)
        }

        // if previous conditional element, remove dependant highlight
        if (prevActiveElement?.category === 'conditional' && prevActiveElement?.config?.element_uuid) {
          const dependantId = prevActiveElement?.config?.element_uuid

          set((state) => {
            if (state.allElements[dependantId]) {
              delete state.allElements[dependantId]._isConditionalTrigger
            }
          })
        }

        // if conditional element, add dependant highlight to next element
        if (isEditable && element?.category === 'conditional' && !!element?.config?.element_uuid) {
          const dependantId = element?.config?.element_uuid

          set((state) => {
            if (state.allElements[dependantId]) {
              state.allElements[dependantId]._isConditionalTrigger = true
            }
          })
        }
      },

      // --- Elements ---

      allElements,

      editElement: (element: any) => {
        const { uuid, data } = element

        if (!uuid || size(data) === 0) return

        const setHasUnsavedChanges = get().setHasUnsavedChanges

        set((state: any) => {
          const element = state.allElements[uuid]

          if (!element) return

          state.allElements[uuid] = Object.assign(state.allElements[uuid], data)
        })

        setHasUnsavedChanges(true)
      },

      editElementConfig: (element: any) => {
        const { uuid, config } = element

        if (!uuid || size(config) === 0) return

        const timezone = get().timezone
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        set((state: any) => {
          const element = state.allElements[uuid]

          if (!element) return

          state.allElements[uuid].config = Object.assign(state.allElements[uuid].config, config)
          state.allElements[uuid].config.updated_at = DateTime.local().setZone(timezone).toISO()
        })

        setHasUnsavedChanges(true)
      },

      // directly set a value in the element's config without merging the previous value
      setElementConfigValue: (element: any) => {
        const { uuid, key, value } = element

        if (!uuid || !key) return

        const timezone = get().timezone
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        set((state: any) => {
          const element = state.allElements[uuid]

          if (!element) return

          state.allElements[uuid].config[key] = value
          state.allElements[uuid].config.updated_at = DateTime.local().setZone(timezone).toISO()
        })

        setHasUnsavedChanges(true)
      },

      // --- Variables ---
      variables,
      variableValues,
      variablesTimeAnchor,

      appendVariableToElementModel: ({ elementId, model, variableKey }) => {
        const element = get().allElements[elementId]

        if (!element?.config || !variableKey) return

        const setHasUnsavedChanges = get().setHasUnsavedChanges
        const currentValue = element.config[model] || ''
        const nextValue = compact([currentValue, `{{ ${variableKey} }}`]).join(' ')

        set((state: any) => {
          state.allElements[elementId].config[model] = nextValue
        })

        setHasUnsavedChanges(true)
      },

      setVariablesTimeAnchor: async (value: string) => {
        if (!value) return

        const timezone = get().timezone
        const newAnchorDT = DT(value, timezone)

        const environment = get().environment
        const parseAllElementConfigVariables = get().parseAllElementConfigVariables

        set((state: any) => {
          state.variablesTimeAnchor = value

          for (const key in parseAnchorTimeVariable) {
            state.variableValues[key] = parseAnchorTimeVariable[key](newAnchorDT, timezone)
          }
        })

        if (environment === 'submission') {
          await parseAllElementConfigVariables({ forceProcessAll: true })
        }
      },

      parseAllElementConfigVariables: async (options: any) => {
        const forceProcessAll = !!options?.forceProcessAll
        const startTime = new Date().getTime()

        const variableValues = get().variableValues
        const allElements = get().allElements
        const timezone = get().timezone

        DEBUG && console.debug('🏁 START PROCESSING VARIABLES')
        DEBUG && forceProcessAll && console.debug('🏁 Using forceProcessAll flag')

        for (const elementId in allElements) {
          const element = allElements[elementId]

          // skip if config is not found or element is marked for deletion
          if (!element.config || element?._destroy) continue

          // skip if variables are disabled for this element
          if (!!element.config?.skip_variables_check) {
            DEBUG && console.debug(`🚫 Skip parsing – variables are disabled for ${element.category}`, elementId)

            set((state) => {
              delete state.allElements[elementId].variable_keys
              delete state.allElements[elementId].config.parsed
              state.allElements[elementId].config.processed_at = DateTime.local().setZone(timezone).toISO()
            })

            continue
          }

          // ⏰ TODO: add back after implementing logic to re-parse variables when dynamic variable values update
          // if (!forceProcessAll && element.config.processed_at && element.config.updated_at) {
          //   const processedAt = DateTime.fromISO(element.config.processed_at).setZone(timezone).toMillis()
          //   const updatedAt = DateTime.fromISO(element.config.updated_at).setZone(timezone).toMillis()

          //   if (updatedAt <= processedAt) {
          //     DEBUG && console.debug(`⏩ Skip parsing – no changes since last processing for ${element.category}`, elementId)
          //     continue
          //   }
          // }

          const variableKeys: any = []
          let parsed = {}

          // collect all fields to be parsed from the element's config
          for (const variableField of VARIABLE_SUPPORT_FIELDS) {
            if (!element.config[variableField]) continue

            parsed[variableField] = element.config[variableField]
          }

          // parse element's config variables
          try {
            const parseResult: any = await liquid.parse(JSON.stringify(parsed))
            const renderResult = await liquid.render(parseResult, variableValues)

            if (renderResult) parsed = JSON.parse(renderResult)

            // collect all variable keys used in the element's config
            for (const item of parseResult) {
              // skip if token is not found or was already added
              if (!item?.token?.content || variableKeys.includes(item.token.content)) continue

              variableKeys.push(item.token.content)
            }
          } catch (error) {
            DEBUG && console.debug('🚨 Error parsing element config', { element, error })
            console.error('Error parsing element config', { elementId, error })
          }

          DEBUG && console.debug(`✅ Setting parsed config for ${element.category}`, variableKeys, parsed)

          const processedAt = DateTime.local().setZone(timezone).toISO()

          set((state: any) => {
            state.allElements[elementId].variable_keys = variableKeys
            state.allElements[elementId].config.parsed = parsed
            state.allElements[elementId].config.processed_at = processedAt
            state.allElements[elementId].config.updated_at = state.allElements[elementId].config.updated_at || processedAt
          })
        }

        const endTime = new Date().getTime()

        if (DEBUG) {
          console.debug('🏁 FINISH PROCESSING VARIABLES', { duration: endTime - startTime })
        }
      },

      editFormVariable: (data: any) => {
        if (!data) return

        const { name, variable_key, variable_value, external_id } = data

        if (!name || !variable_value || !variable_key) return

        const setHasUnsavedChanges = get().setHasUnsavedChanges

        set((state: any) => {
          state.variables = state.variables.map((variable: any) => {
            if (variable.external_id === external_id) {
              return { ...variable, ...data }
            }

            return variable
          })

          state.variableValues[variable_key] = variable_value
        })

        setHasUnsavedChanges(true)
      },

      addFormVariable: (data: any) => {
        if (!data) return

        const { name, description, variable_key, variable_value } = data

        if (!name || !variable_value || !variable_key) return

        const setHasUnsavedChanges = get().setHasUnsavedChanges
        const checkVariableKeyDuplicate = get().checkVariableKeyDuplicate

        const { isDuplicate } = checkVariableKeyDuplicate(variable_key)

        if (isDuplicate) return

        const newVariable = {
          name,
          description,
          variable_key,
          variable_value,
          category: 'form',
          external_id: uuid(),
        }

        set((state: any) => {
          state.variableValues[variable_key] = variable_value
          state.variables.push(newVariable)
        })

        setHasUnsavedChanges(true)
      },

      deleteFormVariable: (externalId: any) => {
        if (!externalId) return

        const variables = get().variables
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        const index = variables.findIndex((variable: any) => variable.external_id === externalId)

        if (index === -1) return

        const currentVariable = variables[index]

        set((state: any) => {
          if (currentVariable?.id) {
            state.variables[index] = {
              ...state.variables[index],
              _destroy: 1,
            }
          } else {
            state.variables.splice(index, 1)
          }

          delete state.variableValues[currentVariable.variable_key]
        })

        setHasUnsavedChanges(true)
      },

      checkVariableKeyDuplicate: (variableKey: string) => {
        const currentVariableValues = get().variableValues

        const result = { isDuplicate: false }

        if (!currentVariableValues) return result

        const allVariableKeys = Object.keys(currentVariableValues)

        if (allVariableKeys.includes(variableKey)) {
          result.isDuplicate = true
        }

        return result
      },

      getUniqueVariableKey: (variableKey: string) => {
        let isDuplicate = get().checkVariableKeyDuplicate(variableKey).isDuplicate
        let nextKey = variableKey
        let nextIndex = 0

        while (isDuplicate) {
          nextIndex = nextIndex + 1
          nextKey = `${variableKey}_${nextIndex}`
          isDuplicate = get().checkVariableKeyDuplicate(nextKey).isDuplicate
        }

        return nextKey
      },

      toggleSkipVariablesCheck: (elementId: string, value?: boolean) => {
        if (!elementId) return

        const timezone = get().timezone
        const setHasUnsavedChanges = get().setHasUnsavedChanges

        const element = get().allElements[elementId]

        if (!element) return

        set((state: any) => {
          const shouldSkip = isUndefined(value) ? !state.allElements[elementId].config.skip_variables_check : value

          state.allElements[elementId].config.skip_variables_check = shouldSkip
          state.allElements[elementId].config.updated_at = DateTime.local().setZone(timezone).toISO()

          if (shouldSkip) {
            delete state.allElements[elementId].variable_keys
            delete state.allElements[elementId].config.parsed
          }
        })

        setHasUnsavedChanges(true)
      },

      // --- Utils ---
      collectAllChildrenIds: (elementId: string) => {
        const element = get().allElements[elementId]

        if (!element) return []

        const result: any = []

        if (size(element.elements_order) > 0) {
          for (const childId of element.elements_order) {
            const child = get().allElements[childId]

            if (!child) continue

            result.push(childId, ...get().collectAllChildrenIds(childId))
          }
        }

        return result
      },

      collectAllChildren: (elementId: string) => {
        const element = get().allElements[elementId]

        if (!element) return []

        const result: any = []

        if (size(element.elements_order) > 0) {
          for (const childId of element.elements_order) {
            const child = get().allElements[childId]

            if (!child) continue

            result.push(child, ...get().collectAllChildren(childId))
          }
        }

        return result
      },

      findElementPageId: (elementId: string) => {
        if (!elementId) return

        const element = get().allElements[elementId]
        const pages = get().pages

        if (!element) {
          console.error('Invalid element', elementId)
          return
        }

        const rootParentId = get().findRootParentId(elementId)

        if (!rootParentId) {
          console.error('Invalid root parent for elementId', elementId)
          return
        }

        for (const pageId in pages) {
          if (pages[pageId].elements_order?.includes(rootParentId)) return pageId
        }

        return null
      },

      findRootParentId: (elementId: string) => {
        const element = get().allElements[elementId]

        if (!element) return

        if (!element.parent_uuid) return elementId

        return get().findRootParentId(element.parent_uuid)
      },

      getAllFormElementsByPage: () => {
        const pages = get().pages
        const pagesOrder = get().pagesOrder

        const result: any = []

        for (const pageId of pagesOrder) {
          const page = pages[pageId]

          if (page?._destroy) continue

          const pageResult: any = {
            uuid: page.uuid,
            name: page.name,
            elements: [],
          }

          for (const elementId of page.elements_order) {
            const element = get().allElements[elementId]
            const childElements = get().collectAllChildren(elementId)

            if (FORM_ELEMENTS.includes(element.category) && !element?._destroy) {
              pageResult.elements.push(element)
            }

            for (const child of childElements) {
              if (FORM_ELEMENTS.includes(child.category) && !child?._destroy) {
                pageResult.elements.push(child)
              }
            }
          }

          result.push(pageResult)
        }

        return result
      },

      getCurrentBuilderState: () => {
        const pages = get().pages

        const openPageIds: any = []

        for (const pageId in pages) {
          if (pages[pageId]._isOpen) {
            openPageIds.push(pageId)
          }
        }

        return {
          activeTab: get().activeTab,
          activePageId: get().activePageId,
          activeElementId: get().activeElementId,
          openPageIds,
        }
      },
    })),
  )
}
