import React from 'react'

import Dialog from '../Dialog'

const DeleteDialog = ({ children, ...rest }) => {
  return (
    <Dialog skipCloseHotkey {...rest}>
      {children}
    </Dialog>
  )
}

DeleteDialog.defaultProps = {
  glyph: 'delete',
  title: 'Delete?',
  message: 'Are you sure you want to delete this record?',
  noLabel: 'No, Go Back',
  noColor: 'gray',
  yesLabel: 'Delete',
  yesColor: 'red',
}

export default DeleteDialog
