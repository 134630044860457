import React from 'react'

import { parseQuoteVariables } from './functions'
import { QuoteProvider, useQuoteContext } from '../QuoteElements/context'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate } from '../../hooks/useNewAPI'

import Button from '../../components/Button'

export const QuoteRefreshVariablesButton = (props: any) => {
  const { data } = props

  const { timezone } = useSettings()

  return (
    <QuoteProvider data={data} timezone={data?.facility?.timezone || data?.timezone || timezone}>
      <InnerQuoteRefreshVariables data={data} />
    </QuoteProvider>
  )
}

const InnerQuoteRefreshVariables = ({ data }: any) => {
  const { variableValues }: any = useQuoteContext()

  const { mutateAsync, isLoading } = useUpdate({
    name: ['quotes', data?.id],
    url: `/quotes/${data?.id}`,
    invalidate: ['quotes'],
  })

  const handleRefreshVariables = async () => {
    if (!data?.id || !variableValues) return

    const parsedData = await parseQuoteVariables(data, variableValues)

    await mutateAsync({
      ...data,
      ...parsedData,
    })
  }

  return <Button label="Refresh Variables" glyph="reset" size={100} isLoading={isLoading} onClick={handleRefreshVariables} />
}
