import React from 'react'

import Alert from '../../Alert'
import ContextShow from '../ContextShow'
import EmailInput from '../EmailInput'
import Input from '../Input'
import Markup from '../../Markup'
import PhoneInput from '../PhoneInput'
import Textarea from '../Textarea'

import YesNoRadioGroup from './YesNoRadioGroup'
import RelationshipSelect from './RelationshipSelect'

const ContactInputs = ({ model, relationship, roiDisclaimerMessage }) => (
  <>
    <Input
      label="First Name"
      model={`${model}.first_name`}
      validations={{
        presence: {
          message: 'Please enter a first name',
        },
      }}
    />
    <Input
      label="Last Name"
      model={`${model}.last_name`}
      validations={{
        presence: {
          message: 'Please enter a last name',
        },
      }}
    />
    <PhoneInput label="Phone Number" model={`${model}.phone_no`} />
    <EmailInput label="Email Address" model={`${model}.email`} />
    <RelationshipSelect label="Relationship" model={`${model}.relationship`} defaultValue={relationship} />
    <YesNoRadioGroup
      label="Can we release information to this person?"
      model={`${model}.roi`}
      validations={{
        presence: {
          message: 'Please select a response',
        },
      }}
    />

    {roiDisclaimerMessage && (
      <ContextShow when={`${model}.roi`} is={true}>
        <Alert small contrast glyph="info" type="warning">
          <Markup value={roiDisclaimerMessage} />
        </Alert>
      </ContextShow>
    )}

    <YesNoRadioGroup label="Is this person an emergency contact?" model={`${model}.is_emergency`} />
    <Textarea label="Notes" model={`${model}.notes`} />
  </>
)

ContactInputs.defaultProps = {
  model: 'contact',
}

export default ContactInputs
