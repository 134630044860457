import React from 'react'
import ReactECharts, { EChartsReactProps } from 'echarts-for-react'

type Props = {
  height?: string | number
  maxWidth?: string | number
  minWidth?: string | number
  option: EChartsReactProps['option']
  opts?: EChartsReactProps['opts']
  renderer?: 'svg' | 'canvas'
}

const Chart: React.FC<Props> = ({ renderer = 'canvas', option, opts, maxWidth, minWidth, height = 500, ...rest }) => {
  return (
    <ReactECharts
      option={{
        grid: {
          top: 24,
          bottom: 16,
          left: 16,
          right: 16,
          containLabel: true,
        },
        ...option,
      }}
      opts={{ renderer, ...opts }}
      style={{ maxWidth, minWidth, height }}
      {...rest}
    />
  )
}

export default Chart
