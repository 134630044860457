import React from 'react'

import ContextShow from '../ContextShow'
import Input from '../Input'
import Option from '../Option'
import Select from '../Select'

const PrimaryLanguageSelect = ({ label, model, isRequired }) => (
  <>
    <Select
      allowEmpty
      label={label}
      model={model}
      validations={
        isRequired && {
          presence: {
            message: 'Please select a primary language',
          },
        }
      }
    >
      <Option label="English" value="english" />
      <Option label="Spanish or Spanish Creole" value="spanish_or_spanish_creole" />
      <Option label="French (including Patois, Cajun)" value="french_including_patois_cajun" />
      <Option label="French Creole" value="french_creole" />
      <Option label="Italian" value="italian" />
      <Option label="Portuguese or Portuguese Creole" value="portuguese_or_portuguese_creole" />
      <Option label="German" value="german" />
      <Option label="Yiddish" value="yiddish" />
      <Option label="Other West Germanic languages" value="other_west_germanic_languages" />
      <Option label="Scandinavian languages" value="scandinavian_languages" />
      <Option label="Greek" value="greek" />
      <Option label="Russian" value="russian" />
      <Option label="Polish" value="polish" />
      <Option label="Serbo-Croatian" value="serbo_croatian" />
      <Option label="Other Slavic languages" value="other_slavic_languages" />
      <Option label="Armenian" value="armenian" />
      <Option label="Persian" value="persian" />
      <Option label="Gujarathi" value="gujarathi" />
      <Option label="Hindi" value="hindi" />
      <Option label="Urdu" value="urdu" />
      <Option label="Other Indic languages" value="other_indic_languages" />
      <Option label="Other Indo-European languages" value="other_indo_european_languages" />
      <Option label="Chinese" value="chinese" />
      <Option label="Japanese" value="japanese" />
      <Option label="Korean" value="korean" />
      <Option label="Mon-Khmer, Cambodian" value="mon_khmer_cambodian" />
      <Option label="Hmong" value="hmong" />
      <Option label="Thai" value="thai" />
      <Option label="Laotian" value="laotian" />
      <Option label="Vietnamese" value="vietnamese" />
      <Option label="Other Asian languages" value="other_asian_languages" />
      <Option label="Tagalog" value="tagalog" />
      <Option label="Other Pacific Island languages" value="other_pacific_island_languages" />
      <Option label="Navajo" value="navajo" />
      <Option label="Other Native North American languages" value="other_native_north_american_languages" />
      <Option label="Hungarian" value="hungarian" />
      <Option label="Arabic" value="arabic" />
      <Option label="Hebrew" value="hebrew" />
      <Option label="African languages" value="african_languages" />

      <Option label="Other" value="other" />
    </Select>

    <ContextShow when={model} is="other">
      <Input
        label="Please add your primary language"
        model={`${model}_other`}
        validations={
          isRequired && {
            presence: {
              message: 'Please enter a primary language',
            },
          }
        }
      />
    </ContextShow>
  </>
)

PrimaryLanguageSelect.defaultProps = {
  label: 'Primary Language',
  model: 'primary_language',
}

export default PrimaryLanguageSelect
