import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import withMarketing from '@behavehealth/hocs/withMarketing'
import useStore from '@behavehealth/modules/store'

import { Accordion, Alert, Button, Form, FormSection, Grid, Link, Page, SummonOverlay } from '@behavehealth/components'

import { useGet, useCreate, useUpdate, useDelete } from '@behavehealth/hooks/useNewAPI'
import { usDateTime, nicePhoneNo } from '@behavehealth/utils/functions'

import ClientPortalRevoke from '@behavehealth/components/Overlays/actions/ClientPortalRevoke'
import ClientPortalReinstate from '@behavehealth/components/Overlays/actions/ClientPortalReinstate'
import Notifications from '@behavehealth/modules/notifications'

import Flex from '@behavehealth/components/Flex'
import Avatar from '@behavehealth/components/Avatar'
import Card from '@behavehealth/components/Card'
import CardTitle from '@behavehealth/components/CardTitle'
import CardHeader from '@behavehealth/components/CardHeader'
import CardSubtitle from '@behavehealth/components/CardSubtitle'
import ConfirmDialog from '@behavehealth/components/Dialogs/ConfirmDialog'
import Loader from '@behavehealth/components/Loader'
import Divider from '@behavehealth/components/Divider'
import EmailInput from '@behavehealth/components/Forms/EmailInput'
import PhoneInput from '@behavehealth/components/Forms/PhoneInput'
import CheckboxGroup from '@behavehealth/components/Forms/CheckboxGroup'
import Checkbox from '@behavehealth/components/Forms/Checkbox'

import { Text } from '@behavehealth/components/Typography'

const pageConfig = {
  title: 'Client Portal',
  glyph: 'portal',
}

const STATUSES = {
  not_invited: 'not_invited',
  pending: 'pending',
  accepted: 'accepted',
  declined: 'declined',
  expired: 'expired',
  cancelled: 'cancelled',
}

const getLabelsForDetails = (details: any) => {
  let label = []

  if (!!details.email) label.push(`email (${details.email})`)
  if (!!details.phone_no) label.push(`cell number (${details.phone_no})`)

  return label.join(' and ')
}

export const ClientPortalAccess = ({ client }: any) => {
  const match = useRouteMatch()
  const timezone = useStore((state) => state.tenant?.timezone)

  const [inviteStatus, setInviteStatus] = React.useState(STATUSES.not_invited)
  const [invite, setInvite] = React.useState(null)
  const [user, setUser] = React.useState(null)

  const { mutateAsync: revokeRecord, isLoading: isRevoking }: any = useCreate({
    name: ['client', client?.id, 'revoke'],
    url: `/residents/${client?.id}/revoke_client_portal_invite`,
    invalidate: 'client',
    invalidateKeys: ['client', 'clients', 'invites', 'invite'],
  })

  const { mutateAsync: asyncResend, isLoading: isResending }: any = useCreate({
    name: ['invite', client?.current_invite?.id, 'resend'],
    url: `/invites/${client?.current_invite?.id}/reprocess`,
    invalidate: 'client',
    invalidateKeys: ['client', 'clients', 'invites', 'invite'],
  })

  React.useEffect(() => {
    if (!client.current_invite) return

    setInvite(client.current_invite)
    setUser(client.current_user)
  }, [client.current_invite])

  React.useEffect(() => {
    if (!invite) return

    setInviteStatus(invite.status)
  }, [invite])

  const resendInvite = async () => {
    try {
      await asyncResend()

      Notifications.send('New Invite Sent', 'positive')
    } catch (error) {
      console.debug(error)
    }
  }

  const revokeInvite = async () => {
    try {
      await revokeRecord({ status: 'cancelled' })

      Notifications.send('Invite Revoked', 'positive')
    } catch (error) {
      console.debug(error)
    }
  }

  return (
    <>
      {inviteStatus === STATUSES.not_invited && (
        <>
          <Alert type="neutral" glyph="info">
            {client?.first_name} is not invited to the Client Portal
          </Alert>

          <Button
            type="primary"
            color="blue"
            label={`Invite ${client?.first_name} to Client Portal`}
            link={`${match.url}/invite-to-portal`}
            glyph="portal"
            featureFlagV2="client_portal"
            permission="clients.actions.invite_to_client_portal"
          />
        </>
      )}

      {inviteStatus === STATUSES.cancelled && (
        <Grid gap="0.5rem">
          <Alert type="neutral" glyph="info">
            {client?.first_name}'s Client Portal Invite has been Revoked
          </Alert>

          <Grid horizontal gap="0.5rem">
            <ConfirmDialog
              title="Re-send Invite?"
              message="Are you sure you want to re-send the Invite?"
              yesColor="green"
              onYes={resendInvite}
            >
              <Button
                label="Re-send Invite…"
                glyph="reset"
                color="blue"
                isLoading={isResending}
                permission="clients.actions.invite_to_client_portal"
              />
            </ConfirmDialog>

            <ConfirmDialog
              glyph="behave_health"
              title={`Remove all Invites?`}
              message={`Are you sure you want to remove all invites?`}
              onYes={async () => {
                await revokeInvite()

                setInviteStatus(STATUSES.not_invited)
                setInvite(null)
                setUser(null)
              }}
            >
              <Button color="red" glyph="decline" label="Remove All Client Portal Invites…" isLoading={isRevoking} />
            </ConfirmDialog>
          </Grid>
        </Grid>
      )}

      {inviteStatus === STATUSES.pending && (
        <Grid gap="0.5rem">
          <Alert type="positive" glyph="check">
            {client?.first_name} has been invited via {getLabelsForDetails(invite?.sent_to_details)} at{' '}
            <strong>{usDateTime(invite?.sent_at, timezone)}</strong>.{' '}
            <Link to={`/user-invites/client-portal/${client.current_invite.id}`}>View Invite</Link>
          </Alert>

          <Alert type="neutral" glyph="info">
            <strong>Next Step:</strong> {client?.first_name} must accept the Invite by{' '}
            <strong>{usDateTime(invite?.expired_at, timezone)}</strong>, or a new Invite has to be sent.
          </Alert>

          <Divider />

          <Alert type="default" glyph="info">
            If you would like to change the <strong>{getLabelsForDetails(invite?.sent_to_details)}</strong> you used for the Invite, please
            "Clear All Client Portal Invites" below and then send a new invite.
          </Alert>

          <Grid horizontal gap="0.5rem">
            <ConfirmDialog
              title="Re-send Invite?"
              message="Are you sure you want to re-send the Invite?"
              yesColor="green"
              onYes={resendInvite}
            >
              <Button
                label="Re-send this Invite…"
                glyph="reset"
                color="blue"
                isLoading={isResending}
                permission="clients.actions.invite_to_client_portal"
              />
            </ConfirmDialog>

            <ConfirmDialog
              glyph="behave_health"
              title={`Clear All Portal Invites?`}
              message={`This is useful if you would like to use a different record to invite. Are you sure you want to clear all Invites for ${client.first_name}?`}
              onYes={async () => {
                await revokeInvite()

                setInviteStatus(STATUSES.not_invited)
                setInvite(null)
                setUser(null)
              }}
            >
              <Button color="red" glyph="decline" label="Clear all Client Portal Invites…" isLoading={isRevoking} />
            </ConfirmDialog>
          </Grid>
        </Grid>
      )}

      {inviteStatus === STATUSES.expired && (
        <Grid gap="0.5rem">
          <Alert type="positive" glyph="check">
            {client?.first_name} has been invited via {getLabelsForDetails(invite?.sent_to_details)} at{' '}
            <strong>{usDateTime(invite?.sent_at, timezone)}</strong>.
          </Alert>

          <Alert type="neutral" glyph="time">
            The Client Portal Invite expired on {usDateTime(invite?.expired_at, timezone)}
          </Alert>

          <Grid horizontal gap="0.5rem">
            <ConfirmDialog
              title="Re-send Invite?"
              message="Are you sure you want to re-send the Invite?"
              yesColor="green"
              onYes={resendInvite}
            >
              <Button
                label="Re-send Invite…"
                glyph="reset"
                color="blue"
                isLoading={isResending}
                permission="clients.actions.invite_to_client_portal"
              />
            </ConfirmDialog>

            <ConfirmDialog
              glyph="behave_health"
              title={`Revoke Portal Invite?`}
              message={`Are you sure you want to revoke this invite?`}
              onYes={async () => {
                await revokeInvite()

                setInviteStatus(STATUSES.not_invited)
                setInvite(null)
                setUser(null)
              }}
            >
              <Button color="red" glyph="decline" label="Revoke Client Portal Invite…" isLoading={isRevoking} />
            </ConfirmDialog>
          </Grid>
        </Grid>
      )}

      {inviteStatus === STATUSES.accepted && (
        <Grid gap="0.5rem">
          <Alert type="positive" glyph="check">
            {client?.first_name} has been invited to the Client Portal on <strong>{usDateTime(invite?.sent_at, timezone)}</strong>
          </Alert>

          <Alert type="positive" glyph="check">
            {client?.first_name} set up their account & accepted the Invite on <strong>{usDateTime(invite?.accepted_at, timezone)}</strong>
          </Alert>
        </Grid>
      )}

      {inviteStatus === STATUSES.declined && (
        <Grid gap="0.5rem">
          <Alert type="positive" glyph="check">
            {client?.first_name} has been invited to the Client Portal on <strong>{usDateTime(invite?.sent_at, timezone)}</strong>
          </Alert>

          <Alert type="positive" glyph="cross">
            {client?.first_name} set up their account but declined the Invite on{' '}
            <strong>{usDateTime(invite?.accepted_at, timezone)}</strong>
          </Alert>

          <Alert type="positive">Decline reason: {invite?.declined_reason || 'No reason provided'}</Alert>
        </Grid>
      )}
    </>
  )
}

export const UserAccess = ({ client }: any) => {
  const { data: user, isLoading }: any = useGet({
    name: ['client', client?.id, 'user'],
    url: `/residents/${client?.id}/user`,
  })

  const { mutateAsync: removeUserAccess, isLoading: isRemoving }: any = useDelete({
    name: ['client', client?.id, 'user_access'],
    url: `/residents/${client?.id}/user_access`,
    invalidate: 'client',
    invalidateKeys: ['client', 'clients', 'invites', 'invite'],
  })

  const removeUser = async () => {
    try {
      await removeUserAccess()

      Notifications.send('User removed successfully from Client', 'positive')
    } catch (error) {
      console.debug(error)
    }
  }

  if (isLoading)
    return (
      <Flex centerX centerY>
        <Loader />
      </Flex>
    )

  if (!user)
    return (
      <Alert type="neutral" glyph="info">
        {client?.first_name} has not registered for their MyAccount yet
      </Alert>
    )

  if (user.status === 'blocked') {
    return (
      <Grid gap="1rem">
        <Alert glyph="warning" type="negative">
          {client?.first_name}'s access to the Client Portal has been revoked
        </Alert>
      </Grid>
    )
  }

  if (user.status === 'active') {
    return (
      <Grid gap="1rem">
        <Alert type="positive" glyph="check">
          {client?.first_name} has successfully registered and has full access to{' '}
          <Link href="https://myaccount.behavehealth.com">MyAccount</Link>
        </Alert>

        <Card baseline={'70px'} className="!p-1">
          <CardHeader contentGap="0.5rem" graphic={<Avatar magnify size={48} initials={user.name} />}>
            <CardTitle title="User Details" />
            <CardSubtitle
              subtitle={
                <Grid gap="0.5rem">
                  <Text glyph="email" label="Email Address: " muted={false} description={user.email || 'Not set yet'} />
                  <Text glyph="phone" label="Phone Number: " muted={false} description={user.phone_no || 'Not set yet'} />
                  <Text glyph="not_verified_person" label="ID: " muted={false} description={user.external_id} />
                </Grid>
              }
            />
          </CardHeader>
        </Card>

        <Grid vertical gap="0.5rem">
          {user?.can_do_magic_link && (
            <Button
              label="Send Magic Login"
              emoji="🔮"
              color="pink"
              link={{
                pathname: `${location.pathname}/magic-login`,
                parent: location.pathname,
              }}
            />
          )}

          {user?.can_set_password && (
            <Button
              label="Update Password"
              emoji="🔓"
              type="default"
              color="green"
              link={{
                pathname: `${location.pathname}/update-password`,
                parent: location.pathname,
              }}
            />
          )}

          {client?.client_portal_status === 'access' && (
            <SummonOverlay overlay={<ClientPortalRevoke client={client} />}>
              <Button label="Revoke Client Portal Access" glyph="portal" color="red" permission="clients.actions.invite_to_client_portal" />
            </SummonOverlay>
          )}

          {client?.client_portal_status === 'blocked' && (
            <SummonOverlay overlay={<ClientPortalReinstate client={client} />}>
              <Button
                label="Reinstate Client Portal Access"
                glyph="portal"
                color="green"
                permission="clients.actions.invite_to_client_portal"
              />
            </SummonOverlay>
          )}

          <Divider className="!my-1 !mx-0" />

          <Alert type="default" glyph="info">
            If Client does not have access to the login credentials (Email [{user.email}] or Cell Number [{nicePhoneNo(user.phone_no)}]),
            use the button below to <strong>Reset Login Credentials</strong> and send a new Invite.
          </Alert>

          <ConfirmDialog
            glyph="behave_health"
            title={`Remove User Account from ${client.first_name}?`}
            message="
              Step 1. After deleting the user go update in General Info the Client Details.
              Step 2. Resend Client Portal invite to the new details.
              Step 3. Confirm the Clients have access?
            "
            onYes={removeUser}
          >
            <Button
              type="primary"
              color="red"
              glyph="decline"
              label={`Reset Login Credentials…`}
              permission="clients.actions.invite_to_client_portal"
              isLoading={isRemoving}
            />
          </ConfirmDialog>
        </Grid>
      </Grid>
    )
  }

  return null
}

const ClientPortal = () => {
  const match = useRouteMatch()

  const { data: client }: any = useGet({
    name: ['client', match.params.resource_id],
    url: `/residents/${match.params.resource_id}`,
  })

  const { mutateAsync: updateClient }: any = useUpdate({
    name: ['client', match.params.resource_id],
    url: `/residents/${match.params.resource_id}`,
    invalidate: ['client', 'clients'],
  })

  if (!client) return null

  return (
    <Page title="Client Portal" glyph="portal">
      <Grid gap="0.5rem">
        <Alert contrast type="warning">
          The 3 Steps to set up Client Portal access for <strong>{client?.first_name}</strong>:
          <ol>
            <li>
              Make sure <strong>{client?.first_name}</strong>'s Contact Details are up to date
            </li>
            <li>
              Invite <strong>{client?.first_name}</strong> to the Client Portal via Email or SMS
            </li>
            <li>
              If <strong>{client?.first_name}</strong> does not have a <Link href="https://myaccount.behavehealth.com">MyAccount</Link> they
              receive an Email / SMS from us to <u>Register</u>. If they have a{' '}
              <Link href="https://myaccount.behavehealth.com">MyAccount</Link> they can <u>Accept the Invite</u> in their account.
            </li>
          </ol>
        </Alert>

        <Accordion
          isOpen
          activateEditMode
          initialModel={client}
          title="Step 1 – Contact Details"
          glyph="contact_details"
          permission="clients.general_info.contact_details.view"
          editPermission="clients.general_info.contact_details.edit"
          onSubmit={updateClient}
        >
          <Form>
            <FormSection labelWidth="200px" maxWidth="700px">
              <PhoneInput
                horizontal
                label="Cell Number"
                model="phone_no"
                description="Please note that this is different to the Login Cell Number (see below)"
              />

              <EmailInput
                horizontal
                label="Email Address"
                model="email"
                description="Please note that this is different to the Login Email (see below)"
              />

              <Grid gap={8}>
                <CheckboxGroup
                  label="Consent to Contact"
                  trueIcon="check"
                  falseIcon="cross"
                  falseStyle="faded"
                  description={
                    <Alert glyph="info" type="warning" className="!mt-2">
                      Do not enable consent to contact without a valid, signed authorization from the client
                    </Alert>
                  }
                >
                  <Checkbox
                    testKey="consent_checkbox"
                    label="I acknowledge that the Client has given valid Consent to be contacted via SMS or email"
                    model="is_allowed_to_contact"
                  />
                </CheckboxGroup>
              </Grid>
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen
          initialModel={client}
          glyph="portal"
          title="Step 2 – Invite Details"
          permission="clients.actions.invite_to_client_portal"
        >
          <Form>
            <FormSection maxWidth="550px">
              <ClientPortalAccess client={client} />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen
          initialModel={client}
          glyph="lock"
          title="Step 3 – Registration / User Account"
          permission="clients.general_info.contact_details.view"
        >
          <Form>
            <FormSection maxWidth="550px">
              <UserAccess client={client} />
            </FormSection>
          </Form>
        </Accordion>

        {/* {client?.client_portal_status === 'access' && (
          <Alert contrast type="default">
            <DataList layout="horizontal">
              <DataList.Title title="The following data is accessible via the Client Portal:" />

              <DataList.Item
                label="General Info"
                value="Can see and update Personal Details, Contact Details, Medical Information, Sensitive Information, Insurance Information and Home Address"
              />
              <DataList.Item label="To-Do's" value="Can see and update only the To-Do's of Type Client" />
              <DataList.Item label="Contacts" value="Can see and update, but cannot delete" />
              <DataList.Item label="Ledger" value="Can see, but cannot update or delete" />
              <DataList.Item label="Treatment Plans" value="Can see, but cannot update or delete" />
              <DataList.Item label="Medications" value="Can see, but cannot update or delete" />
              <DataList.Item label="Test Results" value="Can see, but cannot update or delete" />
              <DataList.Item label="Staff" value="Can see limited information, and only if shared with the Portal" />
              <DataList.Item label="Locations" value="Can see limited information, and only if shared with the Portal" />
              <DataList.Item label="Organizations" value="Can see limited information, and only if shared with the Portal" />
            </DataList>
          </Alert>
        )} */}

        {/* <Accordion
          title="Client Portal Bookmarks"
          icon="bookmarks"
          actions={
            <SummonOverlay overlay={<ClientBookmarksOverlay />}>
              <Button label="Manage Client Bookmarks" type="primary" display="inline-flex" size={200} />
            </SummonOverlay>
          }
        >
          <ClientBookmarksGrid clientId={client.id} />
        </Accordion> */}
      </Grid>
    </Page>
  )
}

export default withMarketing(ClientPortal, pageConfig)
