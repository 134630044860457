import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  todo: {
    label: 'To-Do',
    color: 'blue',
  },
  done: {
    label: 'Done',
    color: 'green',
  },
}

const Status = (props) => {
  if (props.status === 'none') return null

  return <SmartStatus statuses={statuses} {...props} />
}

export default Status
