import React from 'react'
import { DateTime } from 'luxon'
import { motion } from 'framer-motion'
import { transparentize, tint } from 'polished'
import clsx from 'clsx'

import { css, COLORS, SHADOW, HARD_SHADOW } from '../../theme'
import { useSettings } from '../../hooks/useSettings'

import RowHeader from './RowHeader'
import { RoadmapContext } from './context'

const RoadmapRow: React.FC<any> = (props) => {
  const {
    actions,
    after,
    avatar,
    children,
    className,
    enableDropdown,
    enableMobileView,
    expandAsCard = false,
    graphic,
    headerStyles,
    hideGridLines,
    icon,
    isDisabled,
    isOpen: defaultIsOpen,
    level,
    renderBars,
    subtitle,
    title,
    titleAfter,
    header,
  } = props

  const { timezone } = useSettings()

  const now = DateTime.local().setZone(timezone)
  const todayDate = now.startOf('day').toFormat('MM-dd-yyyy')

  const { datesList, period }: any = React.useContext(RoadmapContext)

  const [isOpen, setIsOpen] = React.useState(defaultIsOpen)

  const toggleOpen = () => setIsOpen((c) => !c)

  const rootClasses = clsx(STYLES.root().className, isOpen && 'is-open', expandAsCard && 'expand-as-card', `level-${level}`)
  const mainClasses = clsx(STYLES.main().className, 'roadmap-row', isOpen && 'is-open', `level-${level}`, className)

  React.useEffect(() => {
    setIsOpen(props.isOpen)
  }, [props.isOpen])

  return (
    <motion.div className={rootClasses} animate={{ y: expandAsCard && isOpen ? 6 : 0 }} transition={{ y: animationProps }}>
      <div className={mainClasses}>
        <RowHeader
          actions={actions}
          after={after}
          avatar={avatar}
          enableMobileView={enableMobileView}
          graphic={graphic}
          hasSubrows={!!children}
          icon={icon}
          isDisabled={isDisabled}
          isOpen={isOpen}
          level={level}
          onClick={toggleOpen}
          styles={headerStyles}
          subtitle={subtitle}
          title={title}
          titleAfter={titleAfter}
          enableDropdown={enableDropdown}
          expandAsCard={expandAsCard}
        >
          {header}
        </RowHeader>

        <div className={STYLES.content()}>
          <div className={STYLES.verticalBars()}>
            {datesList?.map?.((date: any, index: number) => {
              const isToday = todayDate === date.toFormat('MM-dd-yyyy')

              const barClasses = clsx(STYLES.verticalBar().className, hideGridLines && 'hide', period !== 'day' && isToday && 'is-today')

              return <div key={index} className={barClasses} />
            })}
          </div>

          {renderBars?.({ isOpen })}
        </div>
      </div>

      {isOpen && children}
    </motion.div>
  )
}

const animationProps = {
  type: 'tween',
  duration: 0.35,
  ease: 'circOut',
}

const STYLES = {
  root: css({
    '&.expand-as-card.is-open': {
      marginBottom: 12,
      boxShadow: SHADOW(4),
      border: 'none',

      '& > *': {
        background: COLORS.white,
      },

      '& > *:first-child': {
        boxShadow: `${HARD_SHADOW(2)}, ${SHADOW(2)}`,
        zIndex: 3,
        position: 'relative',
        background: transparentize(0.04, tint(0.5, COLORS.lightBackground)),
      },
    },
  }),

  main: css({
    display: 'grid',
    // background: COLORS.white,
    gridTemplateColumns: 'var(--row-columns)',
    position: 'relative',
    // zIndex: 0,

    '&:hover': {
      background: COLORS.hover,
    },

    // '&.level-1.is-open': {
    //   margin: '0.5rem 0 0',
    //   borderRadius: 5,
    //   boxShadow: SHADOW(4),
    // },
  }),

  content: css({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    borderBottom: `1px solid ${COLORS.divider}`,
    position: 'relative',
    overflow: 'hidden',
  }),

  verticalBars: css({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'var(--days-columns)',
  }),

  verticalBar: css({
    height: '100%',
    boxShadow: `-1px 0 0 ${COLORS.divider}`,
    opacity: 0.4,

    '&.hide': {
      opacity: 0,
    },

    '&.is-today': {
      opacity: '1 !important',
      background: transparentize(0.85, COLORS.vividBlue),
    },
  }),
}

export default RoadmapRow
