import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useLocation, Switch } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

type Props = {
  animation: string
  timeout: any
} & React.HTMLAttributes<any> &
  RouteComponentProps

const AnimatedSwitch: React.FC<Props> = ({ animation, className, children, timeout }: any) => {
  const location = useLocation()

  return (
    <TransitionGroup className={className}>
      <CSSTransition in={true} key={location.key} classNames={animation} timeout={timeout}>
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default AnimatedSwitch

AnimatedSwitch.defaultProps = {
  timeout: { enter: 400, exit: 400 },
}
