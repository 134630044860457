import React from 'react'

import { DataFormOverlay } from '../DataFormOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../components/Forms/elements/YesNoRadioGroupWithTextarea'

import Score from './common/Score'

const SCORING = {
  10: 'Minimal',
  20: 'Mild',
  40: 'Moderate',
  60: 'Severe',
  80: 'Very Severe',
}

const INITIAL_STATE = {
  q1: 0,
  q2: 0,
  q3: 0,
  q4: 0,
  q5: 0,
  q6: 0,
  q7: 0,
  q8: 0,
  q9: 0,
  q10: 0,
  q11: 0,
  q12: 0,
  q13: 0,
  q14: 0,
  q15: 0,
  q16: 0,
  q17: 0,
  q18: 0,
  q19: 0,
  q20: 0,
}

const RootCPSSVSROverlay = (props: any) => {
  return (
    <DataFormOverlay
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="The Child PTSD Symptom Scale For DSM-V (CPSS-V SR)"
      category="clinical_measurement"
      subcategory="cpss_v_sr"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <p>
          The CPSS-SR-5 is a modified version of Child PTSD Symptom Scale self-report (CPSS-SR) for DSM-5. The 20 PTSD symptom items are
          rated on a 5-point scale of frequency and severity from 0 (not at all) to 4 (6 or more times a week /severe). The 7 functioning
          items are rated on yes/no.
        </p>

        <p>
          Use the 20 symptom items to calculate a total symptom severity score. The CPSS-SR-5 has excellent internal consistency for total
          symptom severity (Cronbach's alpha = .924) and good test-retest reliability (r = .800). The CPSS-SR-5 also demonstrates convergent
          validity with CPSS-I-5 (r = .904), and discriminant validity with the Multidimensional Anxiety Scale (MASC) for Children and Child
          Depression Inventory (CDI). A cut off score of 31 can be used for identifying a probable PTSD diagnosis in children. In sum, the
          CPSS- SR-5 is a valid and reliable self-report instrument for assessing DSM-5 PTSD diagnosis and severity for children and
          adolescents.
        </p>

        {/* <Card className="px-3 py-2 max-w-fit">
          <h3 className="mb-2">CPSS Symptom Severity Ranges</h3>

          <DataList className="max-w-[300px]">
            <DataList.Item className="!border-b-2" label="Symptom Severity" value={<span className="font-[600]">Range</span>} />
            <DataList.Item label="Minimal" value="0-10" />
            <DataList.Item label="Mild" value="11-20" />
            <DataList.Item label="Moderate" value="21-40" />
            <DataList.Item label="Severe" value="41-60" />
            <DataList.Item label="Very Severe" value="61-80" />
          </DataList>
        </Card> */}

        <p>
          Note: We have included on the following page a trauma screen checklist in the event the clinician would find this helpful prior to
          doing the CPSS-V SR. Completing it is optional.
        </p>
      </Section>

      <Divider />

      <Section
        title="Trauma Screen (Optional – If Needed)"
        headingType="h2"
        scrollview={{
          id: 'trauma_screen',
          name: 'Trauma Screen',
        }}
        commentsModel="data.trauma_screen"
      >
        <Alert contrast glyph="info" className="text-[1.1rem] mb-4">
          Many children go through frightening or stressful events. Below is a listed of frightening or stressful events that can happen.
          Mark YES if you have experienced any of these events. Mark NO if you have not experienced these events.
        </Alert>

        <FormSection>
          <YesNoRadioGroup
            label="1. A severe natural disaster such as a flood, tornado, hurricane, earthquake, or fire"
            model="data.trauma_screen.q1"
          />
          <YesNoRadioGroup
            label="2. Serious accident or injury caused by a car or bike crash, being bitten by a dog, or caused by playing sports"
            model="data.trauma_screen.q2"
          />
          <YesNoRadioGroup label="3. Being robbed by threat, force, or weapon" model="data.trauma_screen.q3" />
          <YesNoRadioGroup label="4. Being slapped, punished, or beaten by a relative" model="data.trauma_screen.q4" />
          <YesNoRadioGroup label="5. Being slapped, knifed, or beaten by a stranger" model="data.trauma_screen.q5" />
          <YesNoRadioGroup label="6. Seeing a relative get slapped, punished, or beaten" model="data.trauma_screen.q6" />
          <YesNoRadioGroup label="7. Seeing somebody in your community being slapped, punished, or beaten" model="data.trauma_screen.q7" />
          <YesNoRadioGroup
            label="8. Being touched in your sexual/private parts by an adult/someone older who should not be touching you there"
            model="data.trauma_screen.q8"
          />
          <YesNoRadioGroup
            label="9. Being forced/pressured to have sex at a time when you could not say no"
            model="data.trauma_screen.q9"
          />
          <YesNoRadioGroup
            label="10. A family member or somebody close dying suddenly or in a violent way"
            model="data.trauma_screen.q10"
          />
          <YesNoRadioGroup label="11. Being attacked, shot, stabbed, or seriously injured" model="data.trauma_screen.q11" />
          <YesNoRadioGroup
            label="12. Seeing someone be attacked, shot, stabbed, or seriously injured or killed"
            model="data.trauma_screen.q12"
          />
          <YesNoRadioGroup label="13. Having a stressful or frightening medical procedure" model="data.trauma_screen.q13" />
          <YesNoRadioGroup label="14. Being around a war" model="data.trauma_screen.q14" />
          <YesNoRadioGroupWithTextarea label="15. Any other stressful or frightening event" model="data.trauma_screen.q15" />

          <Textarea label="Which of these events bothers you most?" model="data.trauma_screen.most_bothering_event" />

          <Divider />

          <div>
            <div className="text-[1.1rem]">
              If you answered <span className="font-[600]">NO</span> to all of the above questions, <span className="font-[600]">STOP</span>
              .
            </div>

            <div className="text-[1.1rem] mt-2">
              If you answered <span className="font-[600]">YES</span> to any of the above questions, please answer the following questions:
            </div>
          </div>

          <h3 className="mt-2 -mb-2">When the event happened, did you feel:</h3>

          <YesNoRadioGroup
            label="Fear that you were going to die or be seriously injured?"
            model="data.trauma_screen.was_afraid_of_death_or_injury"
          />
          <YesNoRadioGroup label="Fear that someone else was seriously hurt?" model="data.trauma_screen.was_afraid_for_others" />
          <YesNoRadioGroup label="Unable to help yourself?" model="data.trauma_screen.was_unable_to_help_self" />
          <YesNoRadioGroup label="Shame or disgust?" model="data.trauma_screen.was_ashamed_or_disgusted" />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="CPSS – V"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'CPSS – V',
        }}
        commentsModel="data.cpss_v"
      >
        <Grid gap="1.25rem">
          <div className="text-[1.1rem]">
            Sometimes scary or upsetting things happen to kids. It might be something like a car accident, getting beaten up, living through
            an earthquake, being robbed, being touched in a way you didn't like, having a parent get hurt or killed, or some other very
            upsetting event.
          </div>

          <Textarea
            maxWidth="100%"
            label="Please write down the scary or upsetting thing that bothers you the most when you think about it (this should be the event you listed in the Trauma Screen, if the Trauma Screen was used):"
            model="data.cpss_v.most_bothering_event"
          />

          <Input label="When did it happen?" model="data.cpss_v.most_bothering_event_date" />
        </Grid>

        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem" className="mt-6">
        <Alert contrast glyph="info" className="text-[1.1rem] mt-3 -mb-4">
          These questions ask about how you feel about the upsetting thing you wrote down. Read each question carefully. Then select the
          number (0-4) that best describes how often that problem has bothered you IN THE LAST MONTH.
        </Alert>

        {QUESTIONS.map((question, index) => (
          <RadioGroup
            key={`q-${index}`}
            label={question}
            model={`data.questionnaire.q${index + 1}`}
            layout="vertical-dense"
            onUpdate={(state: any) => setScore((o) => ({ ...o, [`q${index + 1}`]: state.value }))}
          >
            <Radio label="0 – Not at all" value={0} />
            <Radio label="1 – Once a week or less/a little" value={1} />
            <Radio label="2 – 2 to 3 times a week/somewhat" value={2} />
            <Radio label="3 – 4 to 5 times a week/a lot" value={3} />
            <Radio label="4 – 6 or more times a week/almost always" value={4} />
          </RadioGroup>
        ))}

        <h3 className="mt-3 -mb-3">Have the problems above been getting in the way of these parts of your life IN THE PAST MONTH?</h3>
        <YesNoRadioGroup label="21. Fun things you want to do" model="data.questionnaire_extra.q21" />
        <YesNoRadioGroup label="22. Doing your chores" model="data.questionnaire_extra.q22" />
        <YesNoRadioGroup label="23. Relationships with your friends" model="data.questionnaire_extra.q23" />
        <YesNoRadioGroup label="24. Praying" model="data.questionnaire_extra.q24" />
        <YesNoRadioGroup label="25. Schoolwork" model="data.questionnaire_extra.q25" />
        <YesNoRadioGroup label="26. Relationships with your family" model="data.questionnaire_extra.q26" />
        <YesNoRadioGroup label="27. Being happy with your life" model="data.questionnaire_extra.q27" />

        {!isPortal && (
          <>
            <Divider />

            <Score title="CPSS Symptom Severity Score" finalScore={finalScore} scoring={SCORING} />
          </>
        )}
      </Grid>
    </>
  )
}

const QUESTIONS = [
  `Having upsetting thoughts or pictures about it that came into your head when you didn't want them to`,
  `Having bad dreams or nightmares`,
  `Acting or feeling as if it was happening again (seeing or hearing something and feeling as if you are there again)`,
  `Feeling upset when you remember what happened (for example, feeling scared, angry, sad, guilty, confused)`,
  `Having feelings in your body when you remember what happened (for example, sweating, heart beating fast, stomach or head hurting)`,
  `Trying not to think about it or have feelings about it`,
  `Trying to stay away from anything that reminds you of what happened (for example, people, places, or conversations about it)`,
  `Not being able to remember an important part of what happened`,
  `Having bad thoughts about yourself, other people, or the world (for example, “I can't do anything right”, “All people are bad”, “The world is a scary place”)`,
  `Thinking that what happened is your fault (for example, “I should have known better”, “I shouldn't have done that”, “I deserved it”)`,
  `Having strong bad feelings (like fear, anger, guilt, or shame)`,
  `Having much less interest in doing things you used to do `,
  `Not feeling close to your friends or family or not wanting to be around them `,
  `Trouble having good feelings (like happiness or love) or trouble having any feelings at all `,
  `Getting angry easily (for example, yelling, hitting others, throwing things) `,
  `Doing things that might hurt yourself (for example, taking drugs, drinking alcohol, running away, cutting yourself)`,
  `Being very careful or on the lookout for danger (for example, checking to see who is around you and what is around you)`,
  `Being jumpy or easily scared (for example, when someone walks up behind you, when you hear a loud noise)`,
  `Having trouble paying attention (for example, losing track of a story on TV, forgetting what you read, unable to pay attention in class)`,
  `Having trouble falling or staying asleep`,
]

export const CPSSVSROverlay = withOverlayError(RootCPSSVSROverlay)
