import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'

import { COLORS, SHADOW } from '../../../theme'

import Avatar from '../../Avatar'
import Glyph from '../../Glyph'
import NavLink from '../../NavLink'

import { EmptyCell } from './EmptyCell'

export const LinkCell: React.FC<any> = (props: any) => {
  const { value, to, href, avatar, isExternal, className, before, glyph, onClick } = props

  if (!value) return <EmptyCell />

  const graphicElement = !isUndefined(avatar) && <Avatar magnify src={avatar} initials={value} size={22} css={STYLES.avatar} />

  const glyphElement = glyph && <Glyph glyph={glyph} size={16} className="mr-1" />

  if (to) {
    const Tag = props.as || NavLink

    return (
      <div className={className} css={STYLES.root}>
        <Tag to={to} css={STYLES.navLink} target={isExternal ? '_blank' : undefined}>
          {before}
          {graphicElement}
          {glyphElement}
          <span css={STYLES.value}>{value}</span>
          {isExternal && <Glyph glyph="external_link" size={12} color={COLORS.blue} className="ml-1" />}
        </Tag>
      </div>
    )
  }

  if (href) {
    return (
      <div className={className} css={STYLES.root}>
        <a href={href} css={STYLES.navLink} target={isExternal ? '_blank' : undefined}>
          {before}
          {graphicElement}
          {glyphElement}
          <span css={STYLES.value}>{value}</span>
          {isExternal && <Glyph glyph="external_link" size={12} color={COLORS.blue} className="ml-1" />}
        </a>
      </div>
    )
  }

  return (
    <div className={clsx(className, !!onClick && 'as-link')} css={STYLES.root} onClick={onClick}>
      {before}
      {graphicElement}
      {glyphElement}
      <span css={STYLES.value}>{value}</span>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: '1 1 auto',
    fontWeight: 500,

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0 0.5rem',

    '&:hover': {},

    '&.as-link': {
      cursor: 'pointer',
      color: COLORS.blue,

      // '&:hover': {
      //   textDecoration: 'underline',
      // },
    },
  },

  avatar: {
    marginRight: '0.5rem',
  },

  navLink: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: '1 1 auto',
    flexWrap: 'nowrap',
    overflow: 'hidden',

    '&.is-active::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: tint(0.8, COLORS.vividBlue),
      boxShadow: `inset 0 0 0 1px ${COLORS.divider}, ${SHADOW(3)} !important`,
      zIndex: -1,
    },
  },

  value: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flex: '1 1 auto',
  },
}
