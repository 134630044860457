import React from 'react'
import size from 'lodash/size'

import Alert from '../../components/Alert'
import Card from '../../components/Card'
import DataList from '../../components/DataList'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

import Score from './common/Score'

const INITIAL_STATE = {
  mindful_relaxation: {
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
  },
  physical_care: {
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    q5: 0,
  },
  self_compassion_and_purpose: {
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
  },
  supportive_relationships: {
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
  },
  supportive_structure: {
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
  },
  mindful_awareness: {
    q1: 0,
    q2: 0,
    q3: 0,
  },
} as const

const SECTION_SCORES = {
  mindful_relaxation: { total: 0, average: 0 },
  physical_care: { total: 0, average: 0 },
  self_compassion_and_purpose: { total: 0, average: 0 },
  supportive_relationships: { total: 0, average: 0 },
  supportive_structure: { total: 0, average: 0 },
  mindful_awareness: { total: 0, average: 0 },
}

const RootMSCSBOverlay = (props: any) => {
  return (
    <DataFormOverlay
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Mindful Self-Care Scale – Brief"
      category="clinical_measurement"
      subcategory="mscsb"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <p>
          The Mindful Self-Care Scale – Brief is a 24-item scale that measures the self-reported frequency of behaviors that measure
          self-care behavior.
        </p>

        <p>
          Self-care is defined as the daily process of being aware of and attending to one's basic physiological and emotional needs
          including the shaping of one's daily routine, relationships, and environment as needed to promote self-care. Mindful self-care
          addresses self-care and adds the component of mindful awareness.
        </p>

        <p>
          Mindful self-care is seen as the foundational work required for physical and emotional well- being. Self-care is associated with
          positive physical health, emotional well-being, and mental health. Steady and intentional practice of mindful self-care is seen as
          protective by preventing the onset of mental health symptoms, job/school burnout, and improving work and school productivity.
        </p>

        <p>
          This scale is intended to help individuals identify areas of strength and weakness in mindful self-care behavior as well as assess
          interventions that serve to improve self-care. The scale addresses 6 domains of self-care: mindful relaxation, physical care,
          self-compassion and purpose, supportive relationships, supportive structure, and mindful awareness.
        </p>
      </Section>

      <Divider />

      <QuestionnaireAndScoring />

      <Divider />

      <Section title="Sources" headingType="h2">
        <div className="grid gap-3">
          <div>For a long version of the scale and a detailed description of the source scale see:</div>

          <div>
            Cook-Cottone, C. P. (2015). <i>Mindfulness and yoga for embodied self-regulation: A primer for mental health professionals.</i>{' '}
            New York, NY: Springer Publishing.
          </div>
        </div>
      </Section>
    </DataFormOverlay>
  )
}

const QuestionnaireAndScoring = (props: any) => {
  const [score, setScore] = React.useState(SECTION_SCORES)

  // const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Grid gap="1.5rem">
          <Alert contrast glyph="info">
            Select the number that reflects the frequency of your behavior (how much or how often) within past week (7 days):
          </Alert>

          {SECTIONS.map((section, index) => (
            <QuestionnaireSection
              key={`section-${index}`}
              {...section}
              onScoreUpdate={(newScore: any) => {
                setScore((o) => ({ ...o, [section.model]: newScore }))
              }}
            />
          ))}
        </Grid>
      </Section>

      {!isPortal && (
        <>
          <Divider />
          <Section
            title="Scoring"
            headingType="h2"
            scrollview={{
              id: 'scoring',
              name: 'Scoring',
            }}
          >
            <Card className="px-3 py-2 max-w-[520px] mt-4 mb-4">
              <DataList labelWidth={200}>
                <DataList.Item className="!border-b-2" label="Subscale" value={<span className="font-[600]">Averaged Score</span>} />

                {SECTIONS.map((section, index) => (
                  <DataList.Item key={`score-${index}`} label={section.title} value={score[section.model].average?.toFixed(2)} />
                ))}
              </DataList>
            </Card>
          </Section>
        </>
      )}
    </>
  )
}

const QuestionnaireSection = (props: any) => {
  const { title, model, questions, onScoreUpdate } = props

  const [score, setScore]: any = React.useState(INITIAL_STATE[model])

  const { isPortal } = useSettings()

  const totalScore = Object.values(score).reduce((a, b) => a + b) || 0
  const averageScore = totalScore / size(questions)

  React.useEffect(() => {
    if (!onScoreUpdate) return

    onScoreUpdate({
      total: totalScore,
      average: averageScore,
    })
  }, [totalScore, averageScore])

  return (
    <div>
      <h3 className="text-[1.35rem]">{title}</h3>

      <Grid gap="1rem">
        {questions.map((question, index) => {
          const isReversed = model === REVERSE_MODEL && index === REVERSE_INDEX

          if (isReversed) {
            return (
              <RadioGroup
                key={`q-${index}`}
                label={question}
                model={`data.questionnaire.${model}.q${index + 1}`}
                layout="vertical-dense"
                onUpdate={(state: any) => setScore((o) => ({ ...o, [`q${index + 1}`]: state.value }))}
              >
                <Radio label="5 – Never (0 days)" value={5} />
                <Radio label="4 – Rarely (1 day)" value={4} />
                <Radio label="3 – Sometimes (2 to 3 days)" value={3} />
                <Radio label="2 – Often (4 to 5 days)" value={2} />
                <Radio label="1 – Regularly (6 to 7 days)" value={1} />
              </RadioGroup>
            )
          }

          return (
            <RadioGroup
              key={`q-${index}`}
              label={question}
              model={`data.questionnaire.${model}.q${index + 1}`}
              layout="vertical-dense"
              onUpdate={(state: any) => setScore((o) => ({ ...o, [`q${index + 1}`]: state.value }))}
            >
              <Radio label="1 – Never (0 days)" value={1} />
              <Radio label="2 – Rarely (1 day)" value={2} />
              <Radio label="3 – Sometimes (2 to 3 days)" value={3} />
              <Radio label="4 – Often (4 to 5 days)" value={4} />
              <Radio label="5 – Regularly (6 to 7 days)" value={5} />
            </RadioGroup>
          )
        })}
      </Grid>

      {!isPortal && (
        <Card className="px-3 py-2 max-w-[520px] mt-4 mb-4">
          <h3>{title} Score</h3>

          <DataList>
            <DataList.Item label="Total" value={totalScore} />
            <DataList.Item label="Average for Subscale" value={averageScore?.toFixed?.(2)} />
          </DataList>
        </Card>
      )}
    </div>
  )
}

const REVERSE_MODEL = 'physical_care'
const REVERSE_INDEX = 3

const SECTIONS = [
  {
    title: 'Mindful Relaxation',
    model: 'mindful_relaxation',
    questions: [
      `I did something creative to relax (e.g., drew, played instrument, wrote creatively, sang, organized)`,
      `I listened to relax (e.g., to music, a podcast, radio show, rainforest sounds)`,
      `I sought out images to relax (e.g., art, film, window shopping, nature)`,
      `I sought out smells to relax (lotions, nature, candles/incense, smells of baking)`,
    ],
  },
  {
    title: 'Physical Care',
    model: 'physical_care',
    questions: [
      `I ate a variety of nutritious foods (e.g., vegetables, protein, fruits, and grains)`,
      `I exercised at least 30 to 60 minutes`,
      `I took part in sports, dance or other scheduled physical activities (e.g., sports teams, dance classes)`,
      `I did sedentary activities instead of exercising (e.g., watched tv, worked on the computer) *reverse scored*`,
      `I practiced yoga or another mind/body practice (e.g., Tae Kwon Do, Tai Chi) `,
    ],
  },
  {
    title: 'Self-Compassion and Purpose',
    model: 'self_compassion_and_purpose',
    questions: [
      `I kindly acknowledged my own challenges and difficulties`,
      `I engaged in supportive and comforting self-talk (e.g., “My effort is valuable and meaningful”)`,
      `I gave myself permission to feel my feelings (e.g., allowed myself to cry)`,
      `I experienced meaning and/or a larger purpose in my work/school life (e.g., for a cause)`,
    ],
  },
  {
    title: 'Supportive Relationships',
    model: 'supportive_relationships',
    questions: [
      `I spent time with people who are good to me (e.g., support, encourage, and believe in me)`,
      `I felt supported by people in my life`,
      `I felt confident that people in my life would respect my choice if I said “no”`,
      `I felt that I had someone who would listen to me if I became upset (e.g., friend, counselor, group)`,
    ],
  },
  {
    title: 'Supportive Structure',
    model: 'supportive_structure',
    questions: [
      `I maintained a manageable schedule`,
      `I kept my work/schoolwork area organized to support my work/school tasks`,
      `I maintained balance between the demands of others and what is important to me`,
      `I maintained a comforting and pleasing living environment`,
    ],
  },
  {
    title: 'Mindful Awareness',
    model: 'mindful_awareness',
    questions: [`I had a calm awareness of my thoughts`, `I had a calm awareness of my feelings`, `I had a calm awareness of my body`],
  },
]

export const MSCSBOverlay = withOverlayError(RootMSCSBOverlay)
