import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'

import Alert from '../../../components/Alert'
import Card from '../../../components/Card'

type Props = {
  children?: React.ReactNode
  description?: string
  finalScore: number
  scoring?: any
  title: string
}

const Score = ({ children, description, finalScore, scoring, title = 'Score' }: Props) => {
  const [activeScore, setActiveScore] = React.useState(0)

  React.useEffect(() => {
    if (!scoring) return

    for (const score in scoring) {
      const scoreInt = parseInt(score)

      if (finalScore <= scoreInt) {
        setActiveScore(scoreInt)
        break
      }
    }
  }, [scoring, finalScore])

  const scores = scoring && Object.keys(scoring)

  const showFinalScore = !isNaN(finalScore)

  return (
    <Card css={styles.root}>
      <h3 css={styles.title}>
        {title}: {showFinalScore ? finalScore : '–'}
      </h3>

      {!showFinalScore && <Alert glyph="info">Please answer all questions to calculate the final score</Alert>}

      {description && <p>{description}</p>}

      {children}

      {scoring && (
        <div css={styles.scoreList}>
          {scores.map((score, index) => (
            <div css={styles.scoreItem} className={showFinalScore && parseInt(score) === activeScore ? 'is-active' : ''}>
              <span css={styles.scoreNumbers}>
                {index === 0 ? 0 : parseInt(scores[index - 1]) + 1} {parseInt(score) !== 0 && `– ${score}`}
              </span>
              → {scoring[score]}
            </div>
          ))}
        </div>
      )}
    </Card>
  )
}

const styles = {
  root: {
    padding: '1rem',
  },

  title: {
    margin: '0 0 0.5rem',
  },

  scoreNumbers: {
    minWidth: '3.75rem',
    display: 'inline-block',
  },

  scoreList: {
    marginLeft: '0.5rem',
    paddingLeft: '1rem',
    boxShadow: `-1px 0 0 0 ${tint(0.85, COLORS.gray)}`,
    zIndex: 0,
  },

  scoreItem: {
    color: tint(0.5, COLORS.text),
    position: 'relative',
    margin: '0.5rem 0',

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-1rem',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      flex: '0 0 auto',

      width: 7,
      height: 7,
      borderRadius: '50%',
      background: COLORS.white,
      boxShadow: `0 0 0 1px ${tint(0.75, COLORS.gray)}`,
      zIndex: 1,
    },

    '&.is-active': {
      color: COLORS.text,
      fontWeight: 700,

      '&::before': {
        background: tint(0.75, COLORS.gray),
        boxShadow: `0 0 0 2px ${COLORS.gray}`,
      },
    },
  },
}

export default Score
