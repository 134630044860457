import React from 'react'
import size from 'lodash/size'
import { Redirect, Route, Switch, useRouteMatch, useParams, useLocation } from 'react-router-dom'

import { COLORS } from '../../theme'
import { formatURL, notionIDFormURL } from '../../utils/functions'
import { useGet, useUpdate } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Flex from '../../components/Flex'
import Iframe from '../../components/Iframe'
import Page from '../../components/Page'
import State from '../../components/State'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Tabs from '../../components/Tabs'

import { CustomPageIframe } from './CustomPageIframe'
import { CustomPageScreenshot } from './CustomPageScreenshot'
import { CustomPagesReorderOverlay } from './CustomPagesReorderOverlay'
import { NotionBrowser } from '../Notion/NotionBrowser'
import { getCustomPageIcon } from './functions'

const URL_BASE = process.env.BH_APP_PAGES_URL

type Props = {
  showActions?: boolean
}

export const CustomPage: React.FC<Props> = ({ showActions }) => {
  const { id }: any = useParams()
  const { url } = useRouteMatch()
  const { pathname } = useLocation()

  const { data: page, isLoading: isLoadingPage }: any = useGet({
    name: ['blocks', { id }],
    url: `/blocks/${id}`,
  })

  const { data: tabs, isLoading: isLoadingTabs }: any = useGet({
    name: ['blocks', { id }, 'blocks'],
    url: `/blocks/${id}/blocks`,
  })

  const { mutateAsync }: any = useUpdate({
    name: ['blocks'],
    url: `/blocks/reorder`,
    invalidate: 'blocks',
  })

  const reorder = async (items: any) => {
    await mutateAsync({ reorder: items })
  }

  const isEmpty = size(tabs) === 0
  const showReorder = size(tabs) > 1
  const firstPage = tabs?.[0]

  const addTabAction = showActions && <Button label="Add Tab" glyph="add" type="primary" link={`${url}/tab-new`} />

  const pageIcon = getCustomPageIcon(page)

  return (
    <Page
      icon={pageIcon}
      title={page?.name}
      isLoading={isLoadingPage || isLoadingTabs}
      titleAside={
        showActions && (
          <Flex centerY gap={8}>
            <Status
              label={page?.published ? 'Published' : 'Not Published'}
              color={page?.published ? 'green' : 'red'}
              glyph={page?.published ? 'public' : 'cross'}
              glyphColor={COLORS.text}
            />

            {page?.password && <Status label="Password Protected" color="violet" glyph="lock" glyphColor={COLORS.text} />}

            <Button
              label="Edit"
              glyph="edit"
              type="minimal"
              size={200}
              css={{ marginLeft: '0.5rem !important' }}
              link={`${pathname}/edit`}
            />
            {page?.published && (
              <Button
                useGlyphForTarget
                label="Open Link"
                target="_blank"
                type="minimal"
                size={200}
                href={`${URL_BASE}/${page.external_id}`}
              />
            )}
          </Flex>
        )
      }
      actions={
        showActions && (
          <>
            {showReorder && (
              <SummonOverlay overlay={<CustomPagesReorderOverlay data={tabs} onSave={reorder} />}>
                <Button label="Reorder Tabs" glyph="drag_and_drop" size={200} />
              </SummonOverlay>
            )}

            {!isEmpty && addTabAction}
          </>
        )
      }
    >
      {isEmpty && (
        <Card>
          <State isEmpty title="Page Tabs" icon={pageIcon} emptyDescription="No tabs added to this page yet" emptyActions={addTabAction} />
        </Card>
      )}

      {!isEmpty && (
        <Tabs>
          <Tabs.List css={{ marginTop: '-1rem', marginBottom: '1rem' }}>
            {tabs &&
              tabs.map((tab) => (
                <Tabs.Item
                  key={tab.id}
                  label={tab.name}
                  icon={getCustomPageIcon(tab)}
                  name={tab.id}
                  to={`${url}/${tab.id}`}
                  css={styles.tab}
                  after={
                    showActions && (
                      <Button
                        hideLabel
                        glyph="edit"
                        type="minimal"
                        size={100}
                        link={`${url}/tab-${tab.id}`}
                        display="inline-flex"
                        css={styles.editButton}
                      />
                    )
                  }
                />
              ))}
          </Tabs.List>

          <Switch>
            {tabs &&
              tabs.map((tab) => (
                <Route key={tab.id} path={`${url}/${tab.id}`}>
                  {tab.block_type === 'notion' && (
                    <NotionBrowser
                      homeID={notionIDFormURL(tab.url)}
                      actionsAfter={
                        showActions && (
                          <Button
                            useGlyphForTarget
                            label="Open in Notion"
                            href={tab.url}
                            color="text"
                            glyph="notion"
                            target="_blank"
                            size={200}
                          />
                        )
                      }
                    />
                  )}

                  {tab.block_type === 'iframe' && (
                    <>
                      {showActions && (
                        <Flex justifyContent="flex-end" css={styles.actions}>
                          <Button
                            useGlyphForTarget
                            label="Open Link"
                            href={formatURL(tab.url)}
                            color="text"
                            glyph="website"
                            target="_blank"
                            size={200}
                          />
                        </Flex>
                      )}

                      <CustomPageIframe data={tab} />
                    </>
                  )}

                  {tab.block_type === 'screenshot' && (
                    <>
                      <div css={styles.actions}>
                        <Flex centerY justifyContent="flex-end" gap="0.5rem">
                          {/* <Iframe title={tab?.name} url={formatURL(tab?.url)} overlayWidth={90}>
                            <Button
                              useGlyphForTarget
                              label="Click to visit page"
                              // href={formatURL(tab.url)}
                              color="text"
                              glyph="website"
                              // target="_blank"
                              size={200}
                            />
                          </Iframe> */}

                          <Button
                            useGlyphForTarget
                            label="Click to visit page"
                            href={formatURL(tab.url)}
                            color="text"
                            glyph="website"
                            target="_blank"
                            size={200}
                          />
                        </Flex>
                      </div>

                      <CustomPageScreenshot data={tab} />
                    </>
                  )}
                </Route>
              ))}

            {firstPage && <Redirect exact from={url} to={`${url}/${firstPage.id}`} />}
          </Switch>
        </Tabs>
      )}
    </Page>
  )
}

const styles = {
  tab: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  editButton: {
    marginLeft: '0.25rem',
  },

  actions: {
    marginBottom: '0.75rem',
  },
}
