import React from 'react'
import { useParams } from 'react-router-dom'
import dompurify from 'dompurify'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'

import ICDCodeSelector from '../../components/Forms/elements/ICDCodeSelector'

const RootDiagnosisOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'diagnosis',
    endpoint: '/diagnoses',
    invalidate: 'diagnosis',
    invalidateKeys: ['diagnoses', 'treatment-diagnoses'],
    options: props,
  })

  const { timezone, isPortal } = useSettings()
  const { resource_id: clientId }: any = useParams()

  const [selectedCode, setSelectedCode] = React.useState<any>(null)
  const [selectedCodeDescription, setSelectedCodeDescription] = React.useState<any>(data?.description)

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay showBackdrop={isEditable} onClose={close}>
      <Overlay.Header icon="diagnosis" title={isNew ? 'Add Diagnosis' : 'Diagnosis'} />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && { client_id: clientId }}
        >
          <Section>
            <FormSection layout="vertical">
              <>
                {isNew && (
                    <Alert contrast glyph="info">
                      Try searching for the code or for a keyword in the code description. For example "Opioid Dependence".
                    </Alert>
                )}

                <ICDCodeSelector
                  label="ICD-10 Code"
                  model="code"
                  onUpdate={(model: any) => {
                    setSelectedCode(model.value)
                    setSelectedCodeDescription(model?.object?.description)
                  }}
                  validations={{
                    presence: {
                      message: 'Please add a code',
                    },
                  }}
                />

                <Textarea label="Description" model="description" value={selectedCodeDescription} />

                <Divider />
              </>

              <Select model="status" label="Status" defaultValue="active">
                <Option label="Active" value="active" />
                <Option label="To Review" value="to_review" />
                <Option label="Inactive" value="inactive" />
                <Option label="Resolved" value="resolved" />
              </Select>

              <Select model="category" label="Type" defaultValue="primary">
                <Option label="Admitting" value="admitting" />
                <Option label="Primary" value="primary" />
                <Option label="Principal" value="principal" />
                <Option label="Secondary" value="secondary" />
              </Select>

              <Flex stretchChildrenX gap="1rem">
                <DateInput
                  defaultToNow
                  label="From Date"
                  model="from"
                  validations={{
                    presence: {
                      message: 'Please enter a date',
                    },
                  }}
                />
                <DateInput label="Until Date" model="until" />
              </Flex>

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this diagnosis">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {/* {!isNew && data && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} disableLinks={isPortal} />
              </Section>
            </>
          )} */}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="diagnoses.create"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Diagnosis"
              type="default"
              isDisabled={isDeleting}
              onClick={edit}
              flex="100 1 auto"
              permission="diagnoses.edit"
            />

            <DeleteDialog
              title="Delete Diagnosis?"
              message="Are you sure you want to delete this diagnosis? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                permission="diagnoses.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const DiagnosisOverlay = withOverlayError(RootDiagnosisOverlay)
