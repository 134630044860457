import React from 'react'
import { useParams } from 'react-router-dom'
import size from 'lodash/size'

import { apiUpdate } from '../../../modules/api'
import { invalidateQueries } from '../../../hooks/useNewAPI'
import { useSettings } from '../../../hooks/useSettings'

import AddressSelectorInput from '../../../components/Forms/AddressSelectorInput'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Workflow from '../../../components/Workflow/Workflow'

const HomeAddress = ({ client, setStatus }: any) => {
  const form = React.useRef()
  const { timezone } = useSettings()

  const { resource_id }: any = useParams()
  const { goNext }: any = React.useContext(Workflow.Context)

  const [requestLoading, setRequestLoading] = React.useState(false)

  const onContinue = async () => {
    setRequestLoading(true)

    const formData = form.current?.getFormValue()

    const response = await apiUpdate({
      name: 'clients',
      url: `/residents/${resource_id}`,
      params: {
        encrypted_data_attributes: {
          address: formData.address,
        },
        lat: formData.lat,
        lon: formData.lon,
      },
      notify: false,
    })

    const address = response?.data?.data?.encrypted_data?.address

    setRequestLoading(false)
    invalidateQueries(null, 'client')

    if (size(address) > 0) {
      setStatus('completed')
    }

    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  return (
    <Form useLocalModel getForm={form} initialModel={client} timezone={timezone}>
      <FormSection labelWidth="150px">
        <AddressSelectorInput actionLabel="Update Home Address" model="address" value={client?.encrypted_data?.address} />

        <Workflow.Buttons>
          <Workflow.ContinueButton label="Save & Continue →" onClick={onContinue} isLoading={requestLoading} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default HomeAddress
