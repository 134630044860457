import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'

import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Dialog from '../../Dialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Overlay from '../../Overlay'
import OverlayLoader from '../../OverlayLoader'
import Section from '../../Section'
import Timeline from '../../Timeline/Timeline'

import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Textarea from '../../Forms/Textarea'

import CustomNoteSections from '../../Elements/CustomNoteSections'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../../Overlays/pages/OverlayBase'

import withRouterV6 from '../../../hocs/withRouterV6'

class CustomNoteTemplateOverlay extends OverlayBase {
  onPreviewOpen = () => {
    this.setState({ formData: this.form.current?.getFormValue() })
  }

  render = () => {
    const { $new, $editable, isValid, params } = this.state
    const { allowEditing = true, dataID, initialData, record, timezone, online, loading } = this.props

    const data = { ...params, ...record }
    const hasInitialData = initialData && dataID

    if (!$new && !hasInitialData && size(data) === 0) {
      return <OverlayLoader position="right" showBackdrop={this.state.$editable} />
    }

    return (
      <Overlay
        position="right"
        isLoading={!data}
        showBackdrop={this.state.$editable}
        maxWidth={82}
        closeWrapper={(element) => (
          <Dialog
            glyph="delete"
            title="Close without saving?"
            message="All changes will be lost. This action cannot be undone."
            yesColor="red"
            yesLabel="Yes, Close Without Saving"
            onYes={this.close}
            skip={!$editable}
          >
            {element}
          </Dialog>
        )}
      >
        <Overlay.Header title={data?.name || 'New Custom Note Template'} icon="legal_agreement_alt" />

        <Overlay.Content>
          <Form
            useFullModel
            getForm={this.form}
            timezone={timezone}
            initialModel={data}
            isEditable={$editable}
            onValidationUpdate={this.onValidationUpdate}
            linked={{ variant: 'property' }}
          >
            <Section title="Template Details" headingType="h2">
              <FormSection maxWidth="100%">
                <Flex gap="1rem" alignItems="flex-end">
                  <Input
                    autoFocus
                    label="Template Name"
                    model="name"
                    validations={{
                      presence: {
                        message: 'Please enter a template name',
                      },
                    }}
                    className="!grow-[2]"
                  />

                  <CheckboxGroup layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                    <Checkbox label="Require Supervisor Signature" model="settings.require_supervisor_signature" />
                  </CheckboxGroup>
                </Flex>

                <Textarea useQuickText label="Description" model="notes" className="!grow !basis-[300px] !self-start" />
              </FormSection>
            </Section>

            <Divider />

            <CustomNoteSections
              model="sections"
              validations={{
                presence: {
                  message: 'Please add at least one section',
                },
              }}
            />

            <Section
              headingType="h2"
              title="Signatures"
              aside={
                <CheckboxGroup layout="horizontal-dense" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox label="Staff Signature" model="settings.require_staff_signature" />
                </CheckboxGroup>
              }
            ></Section>

            <Divider />

            {!$new && (
              <>
                <Section headingType="h2" title="Timeline">
                  <Timeline isLoadingRecord={loading} recordID={data.id} recordType={data.type} />
                </Section>
              </>
            )}
          </Form>
        </Overlay.Content>

        {allowEditing && (
          <Overlay.Footer online={online}>
            {$editable && (
              <>
                <Button
                  glyph="check"
                  label="Save Template"
                  type="primary"
                  color="green"
                  isLoading={loading}
                  onClick={this.save}
                  isDisabled={!isValid}
                  flex="100 1 240px"
                />

                {!$new && <Button glyph="cross" label="Cancel" type="default" isDisabled={loading} onClick={this.cancel} />}
              </>
            )}

            {!$editable && (
              <>
                <Button
                  label="Edit"
                  glyph="edit"
                  type="default"
                  isDisabled={loading}
                  onClick={this.edit}
                  flex="100 1 auto"
                  permission="settings.property_custom_note_templates.edit"
                />

                <DeleteDialog
                  title="Delete Template?"
                  message="Are you sure you want to delete this template? This action cannot be undone."
                  onYes={this.delete}
                >
                  <Button
                    label="Delete"
                    type="default"
                    glyph="delete"
                    color="red"
                    isLoading={loading}
                    fullWidth
                    permission="settings.property_custom_note_templates.delete"
                  />
                </DeleteDialog>
              </>
            )}
          </Overlay.Footer>
        )}
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'custom_note_templates')

export default connect(mapStateToProps, mapDispatchToProps)(CustomNoteTemplateOverlay)

export const PropertyCustomNoteTemplateOverlayV6 = withRouterV6(connect(mapStateToProps, mapDispatchToProps)(CustomNoteTemplateOverlay))
