import React from 'react'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom-v5-compat'

import { address } from '@behavehealth/utils/functions'
import { clearData, updateData } from '@behavehealth/actions/data'
import { store } from '@behavehealth/setup/store'
import { useGet, useUpdate, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Notifications from '@behavehealth/modules/notifications'
import PropertyQuickActions from '@behavehealth/components/QuickActions/Property'
import withBackListener from '@behavehealth/hocs/withBackListener'

import { HelpTagIframe, Nav, PageGrid, PageProfile, PropertyNavItems, Status, TagsSelector, Text } from '@behavehealth/components'
import { JoinMeetingButton } from '@behavehealth/components/Buttons/JoinMeetingButton'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'

import CustomNotes from './pages/custom_notes'
import ShiftNotes from './pages/shift_notes'

// Migrated
import ActivityTimeline from './pages/activity_timeline'
import Calendar from './pages/calendar'
import Files from './pages/files'
import FloorsRoomsBeds from './pages/floors_rooms_beds'
import FormSubmissions from './pages/form_submissions'
import GeneralInfo from './pages/general_info'
import LocationCheckIn from './pages/location_checkin'
import Locations from './pages/locations'
import Todos from './pages/todos/todos'
import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'

const Property = () => {
  const navigate = useNavigate()
  const { isBehave } = useSettings()
  const { resource_id }: any = useParams()

  const { data: location, isLoading, isError, error }: any = useGet({
    name: ['location', resource_id],
    url: `/houses/${resource_id}`,
  })

  const { mutateAsync: updateLocation }: any = useUpdate({
    name: ['location', resource_id],
    url: `/houses/${resource_id}`,
    invalidate: ['locations'],
  })

  const { mutateAsync: deleteLocation }: any = useDelete({
    name: ['location', resource_id],
    url: `/houses/${resource_id}`,
    invalidate: ['locations'],
    onSuccess: () => {
      navigate('/locations')
    },
  })

  const handleDelete = async () => {
    if (!location?.is_deletable && !isBehave) {
      Notifications.send(
        'Cannot delete this property because it has Clients assigned to it. Move out all people using the Bed Management and then try again',
        'negative',
      )
      return
    }

    await deleteLocation()
  }

  React.useEffect(() => {
    if (!isError) return

    console.error(error)
    Notifications.send(error?.error?.data?.errors?.[0], 'negative')

    // go back to the Locations list if unauthorized
    if (error?.error?.status === 401) navigate('/locations')
  }, [isError])

  React.useEffect(() => {
    store.dispatch(updateData(`locations.data.${location?.id}`, location))

    return () => {
      store.dispatch(clearData('locations'))
    }
  }, [location])

  if (!location || isLoading) return <PageLoader />

  return (
    <PageGrid breakpoint={3} columns="280px 1fr">
      <Nav
        showBack
        breakpoint={3}
        headingSize={400}
        record={location}
        title={location.name}
        avatar={location.avatar}
        description={address(location.address, '')}
        desktopProps={{
          icon: 'locations',
          title: 'Location',
          headingSize: 300,
          avatar: undefined,
          description: undefined,
        }}
        help={<HelpTagIframe id="location_profile" />}
        profile={
          <PageProfile
            record={location}
            actions={<PropertyQuickActions data={location} onDelete={handleDelete} />}
            title={location.name}
            description={
              <>
                {location.category === 'house' && <Status color="blue" label={`${location.gender} Property`} />}
                {location.category === 'office' && <Status color="blue" label="Office" />}

                <Text>{address(location.address, '')}</Text>
              </>
            }
            underActions={
              <>
                <JoinMeetingButton
                  title={location.name}
                  meetingID={location.personal_meeting_room?.id}
                  meetingName={`${location.name} Meeting Room`}
                />

                <div className="!mt-3 w-full">
                  <TagsSelector
                    tags={location?.tags}
                    onSave={(tagIDs: any) => updateLocation({ tag_ids: tagIDs })}
                    smartCategories="locations"
                  />
                </div>
              </>
            }
          />
        }
      >
        <PropertyNavItems propertyID={resource_id} />
      </Nav>

      <Routes>
        <Route index element={<Navigate to="general-info" replace />} />
        <Route path="*" element={<Navigate to="general-info" replace />} />

        <Route
          path="activity-timeline/*"
          element={
            <ProtectedRouteV6 permission="activity_timeline.view">
              <ActivityTimeline />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="calendar/*"
          element={
            <ProtectedRoute permission="events.view" featureFlagV2="calendar">
              <Calendar />
            </ProtectedRoute>
          }
        />
        <Route
          path="custom-notes/*"
          element={
            <ProtectedRouteV6 permission="property_custom_notes.view" featureFlagV2="property_custom_notes">
              <CustomNotes />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="files/*"
          element={
            <ProtectedRouteV6 permission="properties.files.view" featureFlagV2="files">
              <Files />
            </ProtectedRouteV6>
          }
        />
        <Route path="floor-plan/*" element={<FloorsRoomsBeds />} />
        <Route
          path="form-submissions/*"
          element={
            <ProtectedRouteV6 featureFlagV2="form_submissions" permission="form_submissions.view">
              <FormSubmissions />
            </ProtectedRouteV6>
          }
        />
        <Route path="general-info/*" element={<GeneralInfo />} />
        <Route
          path="location-checkin/*"
          element={
            <ProtectedRouteV6 permission="location_checkin.view" featureFlagV2="location_gps_check_in">
              <LocationCheckIn />
            </ProtectedRouteV6>
          }
        />
        <Route path="locations/*" element={<Locations />} />
        <Route
          path="shift-notes/*"
          element={
            <ProtectedRouteV6 permission="properties.view" featureFlagV2="properties">
              <ShiftNotes />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="todo/*"
          element={
            <ProtectedRouteV6 permission="todos.view" featureFlagV2="todos">
              <Todos />
            </ProtectedRouteV6>
          }
        />
      </Routes>
    </PageGrid>
  )
}

export default withBackListener(withPageError(Property))
