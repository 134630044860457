import React from 'react'
import clsx from 'clsx'

type Props = {
  direction: 'vertical' | 'horizontal'
  className?: any
  spaces?: number
}

const FlowChart: React.FC<Props> = ({ children, direction = 'horizontal', spaces = 1, className }) => {
  const classNames = clsx({
    'is-horizontal': direction === 'horizontal',
    'is-vertical': direction === 'vertical',
    [className]: className,
  })

  return (
    <div css={styles.root} style={{ '--grid-spaces': spaces }} className={classNames}>
      <div css={styles.grid}>{children}</div>
    </div>
  )
}

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'visible',
    paddingBottom: '1rem',

    '&.is-horizontal': {
      '--gap-x': '3rem',
      '--gap-y': '1.5rem',
      gridRowGap: 'var(--gap-x)',
    },

    '&.is-vertical': {
      '--gap-x': '1.5rem',
      '--gap-y': '3rem',
      gridColumnGap: 'var(--gap-y)',
    },
  },

  grid: {
    display: 'grid',

    '.is-horizontal &': {
      gridAutoFlow: 'column',
      gridColumnGap: 'var(--gap-x)',
    },

    '.is-vertical &': {
      gridAutoFlow: 'row',
      gridRowGap: 'var(--gap-y)',
    },
  },
}

export default FlowChart
