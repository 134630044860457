import React from 'react'

import { COLORS } from '../theme'
import useIsMounted from '../hooks/useIsMounted'

const SCRIPT_ID = 'behave-health-billflow-script'
const SCRIPT_URL = 'https://js.billflow.io/billflow-embed.js'

const createWidget = () => {
  var s = document.createElement('script')
  s.id = SCRIPT_ID
  s.src = SCRIPT_URL
  s.async = true
  s.type = 'text/javascript'

  var x = document.getElementsByTagName('script')[0]
  x.parentNode?.insertBefore(s, x)
}

const removeWidget = () => {
  let element = document.getElementById(SCRIPT_ID)
  if (!element?.parentNode) return

  element.parentNode.removeChild(element)
}

type Props = {
  billingPageID: string
  customerID: string
  hash: string
  onResponse: Function
}

const BillflowEmbed: React.FC<Props> = ({ billingPageID, customerID, hash, onResponse }) => {
  const isMounted = useIsMounted()

  React.useEffect(() => {
    if (!isMounted) return

    window.billflowSettings = {
      billing_page_id: billingPageID,
      customer_id: customerID,
      ...(process.env.NODE_ENV === 'production' && { hash: hash }),
      handleResponse: async (payload: any) => {
        if (onResponse) onResponse(payload)
      },
    }

    createWidget()
    return removeWidget
  }, [])

  return (
    <div css={styles}>
      <div id="billflow-embed" />
    </div>
  )
}

const styles = {
  margin: '1rem 0',

  '.servicebot-pricing-table.__column-count-1': {
    '.__tier': {
      maxWidth: `320px !important`,
      margin: '0 !important',
    },
  },

  '.servicebot-base.subscription-list > div, .servicebot-embed.__subscription-overview, .servicebot-embed.__plan-settings, .servicebot-embed.__payment-settings, .servicebot-embed.__invoices':
    {
      borderRadius: 7,
      background: `${COLORS.white} !important`,
      boxShadow: `
        0 0 0 1px ${COLORS.shadow},
        0 3px 0 1px ${COLORS.shadow}
      `,
      marginBottom: '1rem',
      paddingTop: 10,
      paddingBottom: 10,
    },
}

export default BillflowEmbed
