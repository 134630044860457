import React from 'react'
import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
import isEqual from 'react-fast-compare'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../../../../theme'
import { usDate } from '../../../../../utils/functions'
import { usePrevious } from '../../../../../hooks/usePrevious'
import { withFormContext } from '../../../../Forms/context'
import withSettings from '../../../../../hocs/withSettings'

import Button from '../../../../Button'
import CardTreeItem from '../../../../CardTreeItem'
import DataList from '../../../../DataList'
import DateInput from '../../../../Forms/DateInput'
import FormSection from '../../../../Forms/FormSection'
import Grid from '../../../../Grid'
import Input from '../../../../Forms/Input'
import Option from '../../../../Forms/Option'
import Section from '../../../../Section'
import Select from '../../../../Forms/Select'
import State from '../../../../State'
import Status from '../../../../Status'
import SmartTextarea from '../../../../Forms/SmartTextarea'

import { useFormField } from '../../../../Forms/hooks/useFormField'

const ProblemsV2 = ({ model, form, wileyData = [], isEditable, timezone }: any) => {
  const { formActions } = useFormField({
    model: model,
    form: form,
    defaultEmptyValue: [],
  })

  const [data, setData] = React.useState(wileyData)
  const prevWileyData = usePrevious(wileyData)

  const isEmpty = size(data) === 0

  const addProblem = () => {
    setData(
      produce(data, (draft: any) => {
        draft?.push?.({
          id: `problems-${uuid()}`,
          status: 'inactive',
          name: '',
          created_at: DateTime.now().toISO(),
        })
      }),
    )
  }

  const removeProblem = (idx: any) => {
    setData(
      produce(data, (draft: any) => {
        draft.splice(idx, 1)
      }),
    )
  }

  const updateProblem = (idx: any, model: any, value: any) => {
    setData(
      produce(data, (draft: any) => {
        draft[idx][model] = value
      }),
    )
  }

  const renderAction = () => {
    if (!isEditable) return null
    return <Button type="default" glyph="add" label="Add Problem" onClick={addProblem} display="inline-flex" />
  }

  // ON MOUNT
  React.useEffect(() => {
    let localData = form?.getInitialInputFieldValue(model)
    if (localData) setData(localData)
  }, [])

  // UPDATE FIELD
  React.useEffect(() => {
    formActions.setValue(data)
  }, [JSON.stringify(data)])

  // ADD WILEY DATA
  React.useEffect(() => {
    if (isEqual(wileyData, prevWileyData)) return

    const newProblems: any = []

    for (const icd10 in wileyData) {
      const diagnosis = wileyData[icd10]
      const newDefinitions: any = []

      for (const definition_id in diagnosis.definitions) {
        newDefinitions.push(diagnosis.definitions[definition_id].description)
      }

      newProblems.push({
        id: `problems-${uuid()}`,
        status: 'inactive',
        created_at: DateTime.now().toISO(),
        name: diagnosis.problem_description,
        behavioral_definitions: newDefinitions.join('\r\n'),
      })
    }

    setData(
      produce(data, (draft: any) => {
        draft?.push?.(...newProblems)
      }),
    )
  }, [wileyData])

  if (isEmpty) {
    return <State isEmpty emptyDescription="No problems added yet" emptyActions={renderAction()} />
  }

  if (isEditable) {
    return (
      <div>
        <p>It has been determined that the client is in need of and/or has requested assistance for the following problems:</p>

        {data?.map?.((problem, idx) => (
          <Section
            key={problem.id}
            title="Problem"
            aside={
              isEditable && (
                <Button label="Remove" glyph="delete" type="minimal" color="red" size="small" onClick={() => removeProblem(idx)} />
              )
            }
            css={styles.editCard}
          >
            <FormSection layout="vertical" maxWidth="100%">
              <FormSection horizontal>
                <Select allowEmpty label="Status" value={data[idx].status} onUpdate={(state) => updateProblem(idx, 'status', state.value)}>
                  <Option label="Inactive" value="inactive" />
                  <Option label="In Progress" value="in_progress" />
                  <Option label="Completed" value="completed" />
                  <Option label="Extended" value="extended" />
                  <Option label="Cancelled" value="cancelled" />
                  <Option label="Unmet" value="unmet" />
                </Select>

                <DateInput
                  defaultToNow
                  label="Start Date"
                  value={data[idx].created_at}
                  onUpdate={(state) => updateProblem(idx, 'created_at', state.value)}
                />
              </FormSection>

              <Input value={data[idx].name} onUpdate={(state) => updateProblem(idx, 'name', state.value)} label="Name" />

              <SmartTextarea
                useDictation
                label="Behavioral Definitions"
                value={data[idx].behavioral_definitions}
                onUpdate={(state) => updateProblem(idx, 'behavioral_definitions', state.value)}
              />
            </FormSection>
          </Section>
        ))}

        {renderAction()}
      </div>
    )
  }

  return (
    <Grid gap="0.5rem">
      {data?.map?.((problem: any, index: number) => (
        <CardTreeItem
          isOpen
          key={problem.id}
          title={problem.name}
          subtitle={<Status small label={`Problem #${index + 1}: ${problem.status}`} color="green" />}
        >
          <DataList css={styles.dataList}>
            <DataList.Item label="Start Date" value={usDate(problem?.created_at, timezone)} />
            <DataList.Item label="Behavioral Definitions" value={problem.behavioral_definitions} />
          </DataList>
        </CardTreeItem>
      ))}
    </Grid>
  )
}

const styles = {
  editCard: {
    border: `2px solid ${COLORS.divider}`,
    borderRadius: 7,
    padding: '0.75rem',
  },

  dataList: {
    fontSize: '0.9rem',
  },
}

export default withFormContext(withSettings(ProblemsV2))
