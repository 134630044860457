import React from 'react'
import Chart from '../Chart'
import size from 'lodash/size'

export const PropertyOccupancyRateChart = (props: any) => {
  const series: any = []
  const days: any = props.data?.[0]?.x

  for (let i = 0; i < size(props.data); i++) {
    series.push({
      name: props.data[i].name,
      type: 'line',
      data: props.data[i].y,
    })
  }

  return (
    <Chart
      option={{
        tooltip: { trigger: 'axis' },
        legend: {
          data: series.map((o: any) => o.name),
          type: 'scroll',
          left: '5%',
          top: 0,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: days,
        },
        yAxis: {
          max: 150,
          type: 'value',
          axisLabel: { formatter: '{value}%' },
        },
        series,
      }}
      {...props}
    />
  )
}
