import React from 'react'
import { DateTime } from 'luxon'
import { persist } from 'zustand/middleware'
import create from 'zustand'

import { DT, mapToArray } from '../../utils/functions'

const getDayList = (mode, view, startDate, currentDate) => {
  let days = []
  let daysCount = 0
  let firstDate = startDate

  if (mode === MODES.months && view === VIEWS.calendar) {
    daysCount = 42
  } else if (mode === MODES.months) {
    daysCount = currentDate.endOf('month').day
  } else if (mode === MODES.weeks) {
    daysCount = 7
    firstDate = currentDate.set({ hour: 0, minute: 0, millisecond: 0 }).startOf('week')
  }

  for (let i = 0; i < daysCount; i++) {
    days.push(firstDate.plus({ days: i }))
  }

  return days
}

const getWeekDays = (day) => {
  let days = []
  const zero = day.set({ hour: 0, minute: 0, millisecond: 0 }).startOf('week')
  for (let i = 0; i < 7; i++) days.push(zero.plus({ days: i }))
  return days
}

const getStartDate = (mode, view, currentDate) => {
  if (mode === MODES.months && view === VIEWS.calendar) return currentDate.startOf('month').startOf('week')
  if (mode === MODES.months) return currentDate.startOf('month')
  if (mode === MODES.weeks) return currentDate.startOf('week')

  return currentDate
}

const getEndDate = (mode, view, startDate, currentDate) => {
  if (mode === MODES.months && view === VIEWS.calendar) return startDate.plus({ days: 41 })
  if (mode === MODES.months) return currentDate.endOf('month')
  if (mode === MODES.weeks) return currentDate.startOf('week').plus({ days: 6 })

  return currentDate
}

const getEventsByDate = (events, timezone, startDateKey) => {
  const eventsByDate: any = {}

  mapToArray(events).forEach((event) => {
    const startedAt = DT(event[startDateKey], timezone)
    const startedAtKey = startedAt?.toFormat('yyyy-MM-dd')

    if (!eventsByDate[startedAtKey]) eventsByDate[startedAtKey] = []

    eventsByDate[startedAtKey].push(event)
  })

  return eventsByDate
}

const CalendarBase = (props: any) => {
  const {
    className,
    defaultMode = 'weeks',
    defaultView = 'calendar',
    events = [],
    initialDate = DateTime.local().toISO(),
    isLoading,
    listStyle,
    localStorageKey,
    onChange,
    onSelectedEvent,
    renderEvent,
    setTableColumns,
    startDateKey = 'started_at',
    tableProps,
    timezone,
    to,
  } = props

  const settings: any = useCalendarSettings((state) => state.settings)
  const updateSettings: any = useCalendarSettings((state) => state.updateSettings)

  const calendarSettings = settings?.[localStorageKey] || {}

  const [mode, setMode] = React.useState(calendarSettings?.mode || defaultMode)
  const [view, setView] = React.useState(calendarSettings?.view || defaultView)
  const [currentDate, setCurrentDate] = React.useState(DT(calendarSettings?.currentDate || initialDate))

  const startDate = getStartDate(mode, view, currentDate)
  const endDate = getEndDate(mode, view, startDate, currentDate)

  const onNext = () => setCurrentDate(currentDate.plus({ [MODES[mode]]: 1 }))
  const onPrev = () => setCurrentDate(currentDate.minus({ [MODES[mode]]: 1 }))
  const onToday = () => setCurrentDate(DateTime.local().setZone(timezone))

  const days = getDayList(mode, view, startDate, currentDate)
  const week = getWeekDays(currentDate)

  React.useEffect(() => {
    updateSettings(localStorageKey, {
      mode,
      view,
      currentDate: currentDate.toISODate(),
    })

    if (!onChange) return

    onChange({
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
      currentDate: currentDate.toISODate(),
    })
  }, [onChange, mode, view, currentDate])

  return props.children({
    className,
    currentDate,
    days,
    events,
    eventsByDate: getEventsByDate(events, timezone, startDateKey),
    isLoading,
    listStyle,
    localStorageKey,
    mode,
    onNext,
    onPrev,
    onSelectedEvent,
    onToday,
    renderEvent,
    setCurrentDate,
    setMode,
    setTableColumns,
    setView,
    tableProps,
    to,
    today: DateTime.local().setZone(timezone),
    view,
    week,
  })
}

const useCalendarSettings = create(
  persist(
    (set: any, get: any) => ({
      settings: {},
      updateSettings: (localStorageKey: any, newSettings: any) => {
        if (!localStorageKey || !newSettings) return

        const current = get().settings?.[localStorageKey] || {}

        const updated = { ...current, ...newSettings }

        set({
          settings: {
            ...get().settings,
            [localStorageKey]: updated,
          },
        })
      },
    }),
    { name: 'bh.calendar-settings' },
  ),
)

export const MODES: any = {
  months: 'months',
  weeks: 'weeks',
}

export const VIEWS: any = {
  agenda: 'agenda',
  calendar: 'calendar',
  table: 'table',
}

export default CalendarBase
