import { darken } from 'polished'
import { COLORS } from '../../theme'

export const LOCATION_CATEGORIES = {
  community: {
    label: 'Community',
    model: 'community',
    icon: 'community_all',
    background: COLORS.vividBlue,
    border: darken(0.3, COLORS.vividBlue),
  },

  organizations: {
    label: 'Organizations',
    model: 'organizations',
    icon: 'organizations',
    background: COLORS.purple,
    border: darken(0.2, COLORS.yellow),
  },
  partners: {
    label: 'Partners',
    model: 'partners',
    icon: 'community_partners',
    background: COLORS.green,
    border: darken(0.2, COLORS.green),
  },
  your_locations: {
    label: 'Your Locations',
    model: 'your_locations',
    icon: 'gps_check_in_out',
    background: COLORS.gray,
    border: 'red',
  },
}

export const LOCATION_CATEGORIES_FILTERS = {
  community: true,
  your_locations: true,
  favorites: true,
  invites: true,
  partners: true,
}

export const POPULATIONS_SERVED_GROUPS = [
  {
    label: 'People Served',
    model: 'people_served',
    groupItems: [
      { label: 'Men only', model: 'men_only' },
      { label: 'Women only', model: 'women_only' },
      { label: 'Women + children', model: 'women_children' },
      { label: 'Men + children', model: 'men_children' },
      { label: 'Pregnant women', model: 'pregnant_women' },
      { label: 'Veterans', model: 'veterans' },
      { label: 'Indigenous', model: 'indigenous' },
      { label: 'Re-entry population', model: 're_entry_population' },
      { label: 'Justice involved', model: 'justice_involved' },
      { label: 'Homeless & housing insecure', model: 'homeless_housing_insecure' },
      { label: 'LBGTQIA+ friendly', model: 'lbgtqia_friendly' },
      { label: 'Co-occurring mental health disorder', model: 'co_occurring_mental_health_disorder' },
      { label: 'Deaf & hard of hearing', model: 'deaf_hard_of_hearing' },
      { label: 'Blind & visually impaired', model: 'blind_visually_impaired' },
      { label: 'Felons currently on probation or parole', model: 'felons_currently_on_probation_or_parole' },
      { label: 'Registered violent offenders', model: 'registered_violent_offenders' },
      { label: 'Registered sex offenders', model: 'registered_sex_offenders' },
      { label: 'Other', model: 'other' },
    ],
  },
  {
    label: 'Special Programs/Groups Offered',
    model: 'special_programs_groups_offered',
    groupItems: [
      { label: 'Young adults', model: 'young_adults' },
      { label: 'Seniors or older adults', model: 'seniors_or_older_adults' },
      {
        label: 'Lesbian, gay, bisexual, transgender, or queer/questioning (LGBTQ)',
        model: 'lesbian_gay_bisexual_transgender_or_queer_questioning_lgbtq',
      },
      { label: 'Veterans', model: 'veterans' },
      { label: 'Active duty military', model: 'active_duty_military' },
      { label: 'Members of military families', model: 'members_of_military_families' },
      { label: 'Criminal justice (other than DUI/DWI/Forensic clients)', model: 'criminal_justice_other_than_dui_dwi_forensic_clients' },
      {
        label: 'Clients with co-occurring mental and substance use disorders',
        model: 'clients_with_co_occurring_mental_and_substance_use_disorders',
      },
      { label: 'Clients with HIV or AIDS', model: 'clients_with_hiv_or_aids' },
      {
        label: 'Clients who have experienced intimate partner violence, domestic violence',
        model: 'clients_who_have_experienced_intimate_partner_violence_domestic_violence',
      },
      { label: 'Clients who have experienced trauma', model: 'clients_who_have_experienced_trauma' },
      {
        label: 'Clients who have experience accidental poisoning / overdose',
        model: 'clients_who_have_experience_accidental_poisoning_overdose',
      },
      {
        label: 'Children who lost their parents',
        model: 'children_who_lost_their_parents',
      },
    ],
  },
  {
    label: 'Age Groups Accepted',
    model: 'age_groups_accepted',
    groupItems: [
      { label: 'Children/Adolescents', model: 'children_adolescents' },
      { label: 'Young Adults', model: 'young_adults' },
      { label: 'Adults', model: 'adults' },
      { label: 'Seniors', model: 'seniors' },
    ],
  },
  {
    label: 'Drugs of Choice Programs',
    model: 'drugs_of_choice_programs',
    groupItems: [
      { label: 'Opioids', model: 'opioids' },
      { label: 'Stimulants', model: 'stimulants' },
      { label: 'Benzodiazepines', model: 'benzodiazepines' },
      { label: 'Hallucinogens / Marijuana', model: 'hallucinogens_marijuana' },
      { label: 'Depressants', model: 'depressants' },
    ],
  },
]

export const SAMSHA_DESCRIPTIONS = {
  detoxification: 'Treatment given to people who are addicted to drugs or alcohol in order to stop them from being addicted.',
  transitional_housing_halfway_house_or_sober_home:
    'Housing for individuals recovering from substance abuse that is designed to provide a drug and alcohol-free living environment and appropriate support services to facilitate movement to independent living. Such housing includes transitional living, sober houses, sober living, recovery houses, and 3/4 houses.',
  treatment_for_co_occurring_serious_mental_health_illness_serious_emotional_disturbance_and_substance_use_disorders:
    'Housing for individuals recovering from substance abuse that is designed to provide a drug and alcohol-free living environment and appropriate support services to facilitate movement to independent living. Such housing includes transitional living, sober houses, sober living, recovery houses, and 3/4 houses.',
  department_of_defense: 'Responsible for providing the military forces needed to deter war and protect the security of our country.',
  private_for_profit_organization: 'A business or other organization whose primary goal is making money (a profit).',
  private_non_profit_organization: 'A charitable organization that does not qualify as a public charity.',
  state_government:
    'Government of a country subdivision in a federal form of government, which shares political power with the federal or national government and must meet certain standards set by the federal government, but are free to expand beyond what exists at the federal level and improve services, access, and protections for consumers, such as mental health and substance abuse services, in that state.',
  tribal_government:
    'A governing body of a group of Native American Indians or Alaska Natives that qualifies as an Indian tribal government determined by the Internal Revenue Services.',
  u_s_department_of_veterans_affairs:
    'Facility operated by the U.S. Department of Veterans Affairs, including general hospitals, and/or residential treatment programs, and/or psychiatric out­patient clinics.',
  state_substance_abuse_agency:
    'Government organization responsible for planning, organizing, delivering, and monitoring substance use disorder services in their respective state.',
  state_mental_health_department:
    'Government organization responsible for planning, organizing, delivering, and monitoring critical mental health services in their respective state.',
  commission_on_accreditation_of_rehabilitation_facilities: 'An independent non-profit accreditor of health and human services.',
  council_on_accreditation:
    'Partners with human service organizations worldwide to improve service delivery outcomes by developing, applying and promoting accreditation standards.',
  the_joint_commission:
    'An independent, not-for-profit group in the United States that administers voluntary accreditation programs for hospitals and other healthcare organizations.',
  national_committee_for_quality_assurance:
    'Independent 501(c)(3) nonprofit organization in the United States that works to improve health care quality through the administration of evidence-based standards, measures, programs, and accreditation.',
  federally_qualified_health_center:
    'An entity may qualify as a FQHC if it meets one of these requirements (CMS, 2017): Is receiving a grant under Section 330 of the Public Health Service (PHS) Act or is receiving funding from such a grant and meets other requirements; Is not receiving a grant under Section 330 of the PHS Act, but is determined by the Secretary of the Department of Health and Human Services (HHS) to meet the requirements for receiving such a grant (i.e., qualifies as a FQHC "look-alike") based on the recommendation of the Health Resources and Services Administration (HRSA); Was treated by the Secretary of the Department of HHS for purposes of Medicare Part B as a comprehensive Federally-funded health center as of January 1, 1990; Is operating as an outpatient health program or facility of a tribe or tribal organization under the Indian Self-Determination Act or as an urban Indian organization receiving funds under Title V of the Indian Health Care Improvement Act as of October 1, 1991.',
  federal_or_any_government_funding_for_substance_use_programs:
    'Financial assistance provided by the federal, state, or local government for substance use treatment.',
  ihs_tribal_urban_itu_funds:
    'Direct funds from the Indian Health Service. They consist of tribal funds through "638 contracts" (named after the public law under which they were authorized) and/or urban funds through federal Title 5 grants. These funds are considered part of the India health care system and can be used for programs that provide behavioral health services as well as for programs that provide other health-related services.',
  medicare: 'The federal health insurance program for people age 65 and older and people with disabilities.',
  medicaid:
    'A joint federal and state program that helps with medical costs for some people with low incomes and limited resources. Medicaid programs vary from state to state.',
  cash_or_self_payment:
    'Payment for treatment is made by the person directly, through cash or other means, rather than using health insurance.',
  payment_assistance_check_with_facility_for_details:
    'A program which helps low-income, uninsured, or underinsured patients who need help paying for all or part of their medical bills.',
  sliding_fee_scale_fee_is_based_on_income_and_other_factors: 'Variable prices for services based on a person’s ability to pay.',
  adolescents: 'Facility has a program or group specifically tailored for Adolescents.',
  transitional_age_young_adults: 'Facility has a program or group specifically tailored for Transitional age young adults.',
  adult_women: 'Facility has a program or group specifically tailored for adult women.',
  pregnant_postpartum_women: 'Facility has a program or group specifically tailored for Pregnant/postpartum women.',
  adult_men: 'Facility has a program or group specifically tailored for adult men.',
  seniors_or_older_adults: 'Facility has a program or group specifically tailored for Seniors or older adults.',
  lesbian_gay_bisexual_transgender_or_queer_questioning_lgbtq_clients:
    'Facility has a program or group specifically tailored for LGBT clients.',
  veterans: 'Facility has a program or group specifically tailored for Veterans.',
  active_duty_military: 'Facility has a program or group specifically tailored for active duty military persons. ',
  military_families: 'Facility has a program or group specifically tailored for Military families. ',
  clients_referred_from_the_court_judicial_system:
    'Facility has a program or group specifically tailored for clients referred from the court/judicial system. ',
  persons_with_co_occurring_mental_and_substance_use_disorders:
    'Facility has a program or group specifically tailored for persons with co-occurring mental and substance abuse disorders.',
  persons_with_hiv_or_aids: 'Facility has a program or group specifically tailored for persons with HIV or AIDS.',
  persons_who_have_experienced_sexual_abuse:
    'Facility has a program or group specifically tailored for persons who have experienced sexual abuse.',
  persons_who_have_experienced_intimate_partner_violence_domestic_violence:
    'Facility has a program or group specifically tailored for persons who have experienced intimate partner violence, domestic violence. ',
  persons_who_have_experienced_trauma: 'Facility has a program or group specifically tailored for persons who have experienced trauma.',
  telemedicine_telehealth:
    'The ability for healthcare providers, working from a distance using telecommunications technology, to communicate with patients, diagnose conditions, provide treatment, and discuss healthcare issues with other providers to ensure quality healthcare services are provided. Other names used for this treatment approach are: e-medicine, e-therapy, e-psychiatry, and telepsychiatry.',
  comprehensive_mental_health_assessment:
    'An examination used to ascertain whether or not a patient is functioning on a healthy psychological, social, or developmental level. It can also be used to aid diagnosis of some neurological disorders, specific diseases, or possible drug abuse.',
  breathalyzer_or_blood_alcohol_testing: 'A device for estimating blood alcohol content (BAC) from a breath sample. ',
  drug_or_alcohol_urine_screening: 'Analyzes your urine for the presence of certain illegal drugs and prescription medications.',
  hiv_testing:
    'Determines whether you are infected with HIV, a virus that weakens the immune system and can lead to acquired immunodeficiency syndrome. ',
  screening_for_hepatitis_b: 'Involves blood test that measure HBV (Hepatitis B virus)  antigens and antibodies.',
  screening_for_hepatitis_c:
    'Test for Hepatitis C, which is usually done and recommended for persons currently injecting drugs, ever injected drugs, were prior recipients of transfusions or organ transplants, or have certain medical conditions, including persons: 1. who received clotting factor concentrates produced before 1987 2. who were ever on long-term hemodialysis 3. with persistently abnormal alanine aminotransferase levels (ALT) 4. who have HIV infection ',
  screening_for_mental_disorders:
    'Test to determine whether a person is experiencing symptoms of mental health conditions and needs treatment.',
  screening_for_substance_use: 'Test to determine whether a person is experiencing symptoms of substance use and needs treatment.',
  std_testing: 'Testing to determine the type of sexually transmitted disease a person may be carrying.',
  tb_screening:
    'Skin tests that are used to screen people who are at high risk for TB exposure such as people with diseases or conditions that weaken their immune system. ',
  discharge_planning:
    'A process that aims to improve the coordination of services after discharge from the hospital by considering the patient’s needs in the community.',
  acupuncture:
    'An alternative medicine treatment option that relies on stimulating various points on the body, most often with needles, to relieve pain or treat other physical, mental and emotional conditions.',
  assistance_with_obtaining_social_services:
    'Assistance obtaining government services provided for the benefit of the community designed to promote social well being (i.e. education, medical care, housing, etc.).',
  residential_beds_for_clients_children:
    'A residential treatment program that offers beds for children while their parents are in treatment.',
  child_care_for_clients_children: 'A treatment program that offers child care for children while their parents are in treatment.',
  case_management:
    'Helps people arrange for appropriate services and supports through a case manager who monitors the needs of clients/patients and their families and coordinates services, such as mental health, social work, health, educational, vocational, recreational, transportation, advocacy, and respite care, as needed.',
  domestic_violence_services_family_or_partner: 'Provide safety assistance to victims of domestic violence.',
  early_intervention_for_hiv:
    'Early detection and treatment of HIV, which can help prevent the onset of AIDS and other opportunistic infections. ',
  housing_services: 'Are designed to assist individuals with finding and maintaining appropriate housing arrangements. ',
  mental_health_services:
    'Assessment, diagnosis, treatment or counseling in a professional relationship to assist an individual or group in alleviating mental or emotional illness, symptoms, conditions or disorders.',
  recovery_coach:
    'Are provided by mental health consumers and include mental health treatment or support services, such as social clubs, peer-support groups, and other peer-organized or consumer-run activities (e.g., consumer satisfaction evaluations of mental health treatment).',
  self_help_groups:
    'Groups in which members share the same issue, condition, or situation and thus are in a position to provide help and support to each other.',
  treatment_for_non_substance_use_addiction_disorder:
    'Treatment for behavioral addictions or process addictions and occur when a person is dependent upon a specific behavior (i.e. gambling addiction, compulsive shopping disorder, etc.).',
  hospital_inpatient: 'Medical treatment that is provided in a hospital or other facility and requires at least one overnight stay. ',
  outpatient: 'Describes patients who receive treatment services without an overnight stay at a treatment facility or hospital.',
  benzodiazepines_detoxification:
    'Used to help patients safely and successfully purge their bodies of Benzodiazepines, or Benzos, so that they can proceed to the next stage of addiction treatment.',
  individual_counseling:
    'Process through which clients work one-on-one with a trained mental health clinician in a safe, caring, and confidential environment.',
  group_counseling: 'Form of therapy where people with similar experiences/issues come together with a professional therapist.',
  family_counseling:
    'A type of psychological counseling (psychotherapy) that can help family members improve communication and resolve conflicts.',
  hepatitis_education_counseling_or_support:
    'Provides education, counseling, and guidance and support for individuals who are at risk for or potentially infected with the hepatitis virus.',
  hiv_or_aids_education_counseling_or_support:
    'Access to education, counseling, and support groups to ‘at risk’ individuals and also individuals who have been infected with the virus.',
  health_education_services_other_than_hiv_aids_or_hepatitis:
    'Any combination of learning experiences designed to help individuals and communities improve their health, by increasing their knowledge or influencing their attitudes.',
  employment_counseling_or_training:
    'Advises, coaches, provides information to and supports people who are planning, seeking and managing their life/work direction.',
  nicotine_replacement_therapy:
    'Administers nicotine to the body by means other than tobacco, without other harmful chemicals found in tobacco. Common forms of nicotine replacement therapy are nicotine patches, nicotine gum or lozenges, nasal spray and inhaler. The goal of nicotine replacement is to prevent cravings in a tobacco user, allowing the person to abstain from tobacco.',
  non_nicotine_smoking_tobacco_cessation_medications:
    "Are medications that do not contain nicotine but act on the brain to reduce a person's craving for tobacco. Some common medications are Bupropion (Zyban, Wellbutrin), and Nortriptyline (Pamelor). Medications are often prescribed in conjunction with behavioral counseling or support groups to provide the best chance for achieving long-term smoking abstinence. (http://www.mayoclinic.com) ",
  screening_for_tobacco_use:
    "Determines a client's use of tobacco products, such as cigarettes, cigars, pipe tobacco, or smokeless tobacco. It is generally recommended that providers screen for tobacco use on a regular basis by asking clients, as they are seen, about their current and past use of tobacco products and their exposure to secondhand smoke or tobacco. ",
  smoking_tobacco_cessation_counseling:
    'Includes interventions for persons who use tobacco and want help with stopping, including behavioral support or counseling in groups or individually.',
  smoking_not_permitted: 'Smoking is not allowed.',
  smoking_permitted_without_restriction: 'Smoking permitted with no restriction.',
  smoking_permitted_in_designated_area: 'A designated area in which smoking is permitted.',
  adults: 'Facility accepts adults (26 - 64) for treatment.',
  children_adolescents: 'Facility accepts children/adolescents (12 or younger) for treatment.',
  seniors_65_or_older: 'Facility accepts seniors (65 or older) for treatment.',
  young_adults: 'Facility accepts young adults (18 -25) for treatment. ',
  female: 'Female ',
  male: 'Male ',
  serves_only_dui_dwi_clients:
    'An outpatient substance use treatment facility that serves driving under the influence (DUI) or driving while intoxicated (DWI) clients exclusively.',
  services_for_the_deaf_and_hard_of_hearing: 'Service provided for persons who are deaf and hard of hearing.',
  hopi: 'Staff counselors provide treatment in Hopi.',
  lakota: 'Staff counselors provide treatment in Lakota.',
  navajo: 'Staff counselors provide treatment in Navajo.',
  ojibwa: 'Staff counselors provide treatment in Ojibwa.',
  yupik: 'Staff counselors provide treatment in Yupik.',
  spanish: 'Staff counselors provide treatment in Spanish.',
  arabic: 'Staff counselors provide treatment in Arabic.',
  any_chinese_language: 'Any principal language group of eastern Asia, belonging to the Sino-Tibetan language family.',
  creole: 'Staff counselors provide treatment in Creole.',
  farsi: 'Staff counselors provide treatment in Farsi.',
  french: 'Staff counselors provide treatment in French.',
  german: 'Staff counselors provide treatment in German.',
  greek: 'Staff counselors provide treatment in Greek.',
  hebrew: 'Staff counselors provide treatment in Hebrew.',
  hindi: 'Staff counselors provide treatment in Hindi.',
  hmong: 'Staff counselors provide treatment in Hmong.',
  italian: 'Staff counselors provide treatment in Italian.',
  japanese: 'Staff counselors provide treatment in Japanese.',
  korean: 'Staff counselors provide treatment in Korean.',
  polish: 'Staff counselors provide treatment in Polish.',
  portuguese: 'Staff counselors provide treatment in Portuguese.',
  russian: 'Staff counselors provide treatment in Russian.',
  tagalog: 'Staff counselors provide treatment in Tagalog.',
  vietnamese: 'Staff counselors provide treatment in Vietnamese.',
  general_hospital_including_va_hospital: 'A hospital in which patients with many different types of ailments are given care.',
  psychiatric_hospital:
    'Facility licensed and operated as a state/public psychiatric hospital or as a private psychiatric hospital licensed by the state that primarily provides 24-hour inpatient care to persons with mental illness. It may also provide 24-hour residential care and/or less than 24-hour care (e.g., outpatient, day treatment, partial hospitalization), but these additional service settings are not requirements.',
  buprenorphine_used_in_treatment:
    'Buprenorphine is used in medication-assisted treatment (MAT) to help people reduce or quit their use of heroin or other opiates.',
  buprenorphine_maintenance_for_predetermined_time:
    'Buprenorphine maintenance occurs when a patient is doing well on a steady dose of buprenorphine. The length of time of the maintenance phase is tailored to each patient.',
  samhsa_certified_opioid_treatment_program:
    'The process by which the SAMHSA Center for Substance Abuse Treatment, Division of Pharmacologic Therapies determines that an OTP is qualified to provide opioid treatment under 42 CFR Part 8. For additional information on the OTP certification process, see http://www.samhsa.gov/medication-assisted-treatment/opioid-treatment-programs/.',
  methadone_maintenance:
    'Comprehensive treatment program that involves the long-term prescribing of methadone as an alternative to the opioid on which the client was dependent.',
  relapse_prevention_with_naltrexone:
    'Program or group specifically tailored for relapse prevention from Naltrexone which helps people retrain their minds and behaviors to avoid alcohol as a solution to emotional or psychological triggers.',
  buprenorphine_sub_dermal_implant_probuphine:
    'A subdermal implant consisting of four small devices, about the size of a matchstick, surgically placed under the skin in the patient’s upper arm. The device releases a steady, measured dose of buprenorphine for six months.',
  buprenorphine_with_naloxone_ex_suboxone:
    'A prescription medication that combines buprenorphine (which helps relieve symptoms of opiate withdrawal) and naloxone (reverse the effects of narcotics) used to treat opioid addiction.',
  buprenorphine_without_naloxone:
    'An opioid used to treat opioid addiction by relieving the symptoms of withdrawal. It can be used under the tongue, by injection, as a skin patch, or as an implant.',
  buprenorphine_extended_release_injectable_for_example_sublocade:
    'An injection used to treat adults with moderate to severe opioid use disorder (prescription or illegal) who have received an oral transmucosal (used under the tongue or inside the cheek) buprenorphine-containing medicine at a dose that controls withdrawal symptoms for a minimum of 7 days.',
  anger_management:
    'Uses strategies to address the anger cycle, conflict resolution, assertiveness skills, and anger-control plans. The goal of anger management is to reduce both emotional feelings and the physiological arousal that anger causes. ',
  brief_intervention: 'A short-term intervention, usually one to five sessions, for substance abusers who are not yet dependent.',
  cognitive_behavioral_therapy:
    'Involves recognizing unhelpful patterns of thinking and reacting, and then modifying or replacing these with more realistic or helpful ones. The therapy can be conducted with individuals, families, or groups, and clients are generally expected to be active participants in their own therapy.',
  contingency_management_motivational_incentives:
    'Often used in the treatment of drug and alcohol abuse, the approach employs a positive-reinforcement treatment method in which patients are given rewards for constructive actions taken toward their recovery.',
  community_reinforcement_plus_vouchers:
    'An intensive outpatient therapy in which individuals focus on improving family relations, receive vocational training, and learn a variety of skills to minimize drug dependency. An incentive program (vouchers whereby individuals can earn points exchangeable for retail items) is used to encourage individuals to remain in treatment and be abstinent.',
  dialectical_behavior_therapy:
    'A cognitive behavioral treatment approach with two key characteristics: a behavioral, problem-solving focus blended with acceptance-based strategies, and an emphasis on dialectical processes. "Dialectical" refers to the issues involved in treating patients with multiple disorders and to the type of thought processes and behavioral styles used in the treatment strategies. DBT has five components: (1) capability enhancement (skills training); (2) motivational enhancement (individual behavioral treatment plans); (3) generalization (access to therapist outside clinical setting, homework, and inclusion of family in treatment); (4) structuring of the environment (programmatic emphasis on reinforcement of adaptive behaviors); and (5) capability and motivational enhancement of therapists (therapist team consultation group). DBT emphasizes balancing behavioral change, problem-solving, and emotional regulation with validation, mindfulness, and acceptance.',
  motivational_interviewing:
    'A counseling approach which acknowledges that many people experience ambivalence when deciding to make changes. Its aim is not to focus immediately on the action of changing, but to work to enhance motivation to change.',
  matrix_model:
    'Provides a framework for substance abuse users to obtain the ability to cease drug use, stay in treatment, and participate in an educational program on addiction and relapse. Users are provided with direction and support from a trained therapist and are introduced to self-help programs.',
  rational_emotive_behavioral_therapy:
    'A therapeutic approach that places the focus on present issues, such as currently held attitudes, painful emotions, and maladaptive behaviors that can disrupt life. Treatment includes an REBT practitioner who personalizes a set of techniques for helping individuals examine their own thoughts, beliefs, and actions and replace those that are self-defeating with more life-enhancing alternatives.',
  relapse_prevention:
    'A cognitive behavioral therapy developed for the treatment of problem drinking and adapted later for cocaine addicts. Cognitive behavioral strategies are based on the theory that learning processes play a critical role in the development of maladaptive behavioral patterns. Individuals learn to identify and correct problematic behaviors. Relapse prevention encompasses several cognitive behavioral strategies that facilitate abstinence as well as provide help for people who experience relapse.',
  substance_abuse_counseling:
    'A short-term treatment that has been generalized for a variety of disorders including opiate drug dependence and cocaine abuse. The therapy includes supportive techniques which encourage the patient to discuss personal experiences, and expressive techniques, which enable the patient to work through interpersonal relationship issues and gain greater self-understanding. ',
  trauma_related_counseling:
    'Cognitive behavior techniques adapted for clients suffering from post-traumatic stress disorder (PTSD) and other effects of abuse and trauma.',
  '12_step_facilitation':
    'A 12-step program is a support group made up of people who share the same addiction. The "12 steps" refer to the steps recovering addicts must take to overcome their addiction as part of this program. Attendees at group meetings share their experiences, challenges, successes and failures, and provide peer support for each other.',
}
