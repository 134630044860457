import React from 'react'
import { useMedia } from 'use-media'
import clsx from 'clsx'

import { BREAKPOINT, TOKENS, COLORS } from '../theme'

import Flex from './Flex'
import Glyph from './Glyph'

const HelpInfo: React.FC<any> = ({ className }) => {
  const isExpanded = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavExpandBreakpoint] })
  const isCompact = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavCompactBreakpoint] })

  const classNames = clsx(className, {
    'hide-label': isCompact && !isExpanded,
  })

  return (
    <Flex css={styles.root} alignItems="center" className={classNames} onClick={() => window.Beacon('open')}>
      <Glyph glyph="info" size={20} color={COLORS.blue} />
      <span className="label" css={styles.label}>
        Ask for Help
      </span>
    </Flex>
  )
}

const styles = {
  root: {
    width: '100%',
    alignItems: 'center',
    minHeight: 42,
    padding: '0.4em 1em',
    cursor: 'pointer',
    background: COLORS.white,
    boxShadow: `inset 0 -1px 0 ${COLORS.divider}`,

    '&:hover': {
      background: COLORS.hover,
    },

    '&.hide-label': {
      justifyContent: 'center',

      '.label': {
        display: 'none',
      },
    },
  },

  label: {
    display: 'inline-block',
    marginLeft: '0.5em',
    fontWeight: 500,
  },
}

export default HelpInfo
