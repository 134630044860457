import React from 'react'
import clsx from 'clsx'

import BHSexSelect from '../../../../components/Forms/elements/SexSelect'

import { getFormElementProps } from '../../utils/functions'

export const SexSelectInput: React.FC<any> = (props) => {
  const { children, element, className, hoverElement, useParsedConfig, environment } = props

  if (!element) return null

  const rootClasses = clsx('SEX_SELECT_INPUT', className)
  const inputProps = getFormElementProps(element, { useParsedConfig, environment })

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}
      <BHSexSelect {...inputProps} />
    </div>
  )
}
