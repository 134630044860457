import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { ClientWorkflowsMessageTemplateOverlay } from '@behavehealth/constructs/ClientWorkflows/ClientWorkflowsMessageTemplateOverlay'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'

import Button from '@behavehealth/components/Button'
import Page from '@behavehealth/components/Page'

const ClientWorkflowsMessageTemplates = () => {
  return (
    <div>
      <ClientWorkflowsMessageTemplatesIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<ClientWorkflowsMessageTemplateOverlay useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const ClientWorkflowsMessageTemplatesIndex = () => {
  const columns = React.useMemo(() => {
    return [
      {
        title: 'Template Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} id={data.id} value={value} to={data.id} />,
      },
      {
        title: 'Workflow',
        model: 'category',
        type: 'title',
        width: 200,
      },
      {
        title: 'Message',
        model: 'message',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} id={data.id} value={value} to={data.id} />,
      },
      {
        width: 200,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'updated_at',
        title: 'Last Edited',
        type: 'date_time',
      },
      {
        width: 180,
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [])

  const tableProps = useDataTable({
    name: ['secure_message_templates'],
    endpoint: `/secure_message_templates`,
  })

  return (
    <>
      <Page
        icon="client_workflows_message_templates"
        title="Client Workflows Message Templates"
        actions={
          <Button
            as={Link}
            label="Add Message Template"
            glyph="add"
            type="primary"
            size={300}
            link="new"
            permission="settings.client_workflows_message_templates.create"
          />
        }
      >
        <DataTable
          asCard
          title="Client Workflows Message Templates"
          icon="client_workflows_message_templates"
          filtersConfig={FILTERS_CONFIG}
          columns={columns}
          {...tableProps}
        />
      </Page>
    </>
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Template Name',
    type: 'string',
  },
  category: {
    label: 'Workflow',
    type: 'multi_select',
    options: [
      { label: 'Accept', value: 'accept' },
      { label: 'Decline', value: 'decline' },
      { label: 'Admit', value: 'admit' },
      { label: 'Discharge', value: 'discharge' },
      { label: 'Readmit', value: 'readmit' },
    ],
  },
  message: {
    label: 'Message',
    type: 'string',
  },
  created_at: {
    label: 'Date Added',
    type: 'date_time',
    glyph: 'date',
  },
  updated_at: {
    label: 'Last Edited',
    type: 'date_time',
    glyph: 'date',
  },
  author: DEFAULT_FILTERS.author,
}

export default withPageError(ClientWorkflowsMessageTemplates)
