import React from 'react'
import { parse } from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { isUUID } from '../../utils/functions'
import { NotionBrowser } from '../../constructs/Notion'
import type { NotionPage } from '../../constructs/Notion/NotionBrowser'

import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

const HOME_TAG = 'onboarding_home'

const HOME_PAGE = {
  name: 'Onboarding Home',
  page_id: '7f0c548ff7ee497c8b65823d83cd2cb1',
  url: 'https://www.notion.so/behave-help/Onboarding-Center-7f0c548ff7ee497c8b65823d83cd2cb1',
}

const OnboardingView: React.FC<any> = () => {
  const location = useLocation()
  const history = useHistory()

  let params: any = parse(location.search)
  const helpTag: any = isUUID(params.onboarding) ? '' : params.onboarding

  const onboardingPages = useSelector((state: any) => state.me?.settings?.onboarding_center)
  const onboardingTags = useSelector((state: any) => state.me?.settings?.onboarding_center_tags)

  const page = onboardingPages?.[helpTag]
  const homePage = onboardingPages?.[HOME_TAG] || HOME_PAGE

  const handlePageChange = (page: NotionPage) => {
    if (!page?.id) return

    const tag = onboardingTags?.[page.id]

    const searchParams = new URLSearchParams({ onboarding: tag || page.id })
    history.replace({ search: searchParams.toString() })
  }

  if (!page && !homePage) return null

  return (
    <>
      <AppSidebarView>
        <AppSidebarHeader title="Onboarding" icon="startup" />

        <NotionBrowser
          homeTitle={homePage?.name}
          homeID={homePage?.page_id}
          pageTitle={page?.name}
          pageID={page?.page_id}
          onPageChange={handlePageChange}
          css={styles.notionBrowser}
          feedbackCategory="onboarding_article"
        />
      </AppSidebarView>
    </>
  )
}

const styles = {
  subHeader: {
    padding: '0.5rem',
  },

  notionBrowser: {
    padding: '1rem 1rem 0',
  },
}

export default OnboardingView
