import React from 'react'

import { COLORS } from '../../../theme'
import { formatURL } from '../../../utils/functions'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { H2 } from '../../Typography'
import Avatar from '../../Avatar'
import Button from '../../Button'
import Flex from '../../Flex'
import Grid from '../../Grid'
import Overlay from '../../Overlay'

type Props = {
  avatar?: string
  children?: React.ReactNode
  data: any
  maxWidth?: number
  onClose: Function
  subheader?: React.ReactNode
  title: string
  titleAside?: React.ReactNode
}

const QuickViewOverlay = (props: Props) => {
  const { avatar, children, data, maxWidth, onClose, subheader, title, titleAside, headerAfter } = props

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={onClose} maxWidth={maxWidth}>
      <Overlay.Header glyph="quick_view" title="Quick View" />

      <Overlay.Content css={{ padding: '1rem 1.25rem' }}>
        <Flex nowrap gap={12} css={{ marginBottom: '0.75rem', alignItems: 'center' }}>
          {avatar !== undefined && <Avatar size={72} src={avatar} magnify />}

          <Grid gap={4}>
            <Flex gap={8} alignItems="center">
              <H2>{title}</H2>
              {titleAside}
            </Flex>
            {subheader}
          </Grid>
        </Flex>

        {data && (
          <div className="!mt-4">
            <Flex gap={8}>
              {data.phone_no && (
                <>
                  <Button
                    label={`Call: ${data.phone_no}`}
                    glyph="phone"
                    glyphColor={COLORS.green}
                    color="text"
                    href={`tel:${data.phone_no}`}
                    size={300}
                  />
                  <Button
                    label="Send Text"
                    glyph="sms"
                    glyphColor={COLORS.paleBlue}
                    color="text"
                    href={`sms:${data.phone_no}`}
                    size={300}
                  />
                </>
              )}
              {data.email && (
                <Button
                  label={`Email: ${data.email}`}
                  glyph="email"
                  glyphColor={COLORS.gray}
                  color="text"
                  href={`mailto:${data.email}`}
                  size={300}
                />
              )}
              {data.whatsapp && (
                <Button
                  label="Open WhatsApp Chat"
                  glyph="whatsapp"
                  glyphColor={COLORS.whatsapp}
                  color="text"
                  href={`https://wa.me/+1${data.whatsapp}`}
                  size={300}
                  target="_blank"
                  useGlyphForTarget={false}
                />
              )}
              {data.facebook && (
                <Button
                  label="Open Facebook Messenger"
                  glyph="facebook_messenger"
                  glyphColor={COLORS.facebookMessenger}
                  color="text"
                  href={`http://m.me/${data.facebook}`}
                  size={300}
                  target="_blank"
                  useGlyphForTarget={false}
                />
              )}
              {data.skype && (
                <Button
                  label="Open Skype Chat"
                  glyph="skype"
                  glyphColor={COLORS.skype}
                  color="text"
                  href={`skype:${data.skype}?chat`}
                  size={300}
                  target="_blank"
                  useGlyphForTarget={false}
                />
              )}
              {data.zoom && (
                <Button
                  label="Start Zoom Meeting"
                  glyph="zoom"
                  glyphColor={COLORS.zoom}
                  color="text"
                  href={formatURL(data.zoom)}
                  size={300}
                  target="_blank"
                  useGlyphForTarget={false}
                />
              )}
              {data.google_meet && (
                <Button
                  label="Start Google Meeting"
                  glyph="google_meet"
                  glyphColor={COLORS.googleMeet}
                  color="text"
                  href={formatURL(data.google_meet)}
                  size={300}
                  target="_blank"
                  useGlyphForTarget={false}
                />
              )}
            </Flex>
          </div>
        )}

        {headerAfter}

        <div css={styles}>{children}</div>
      </Overlay.Content>
    </Overlay>
  )
}

const styles = {
  display: 'grid',
  gridGap: '1.5rem',
  marginTop: '1.5rem',
  gridTemplateColumns: 'repeat(auto-fit, minmax(260px, 1fr))',

  '.nav-group': {
    margin: 0,
    borderBottom: '',
  },

  '.nav-group-label': {
    borderBottom: `2px solid ${COLORS.divider}`,
    paddingBottom: '0.5rem',
  },
}

QuickViewOverlay.defaultProps = {
  maxWidth: 32,
}

export default withOverlayError(QuickViewOverlay)
