import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { useSettings } from '@behavehealth/hooks/useSettings'

import Button from '@behavehealth/components/Button'
import State from '@behavehealth/components/State'

import { CommunityProfile } from './community_profiles/community_profile'

export const CommunityProfiles = () => {
  const { connectedEntities } = useSettings()
  const connectedEntitiesCount = size(connectedEntities)

  if (connectedEntitiesCount === 0) {
    return (
      <State
        isEmpty
        icon="community"
        title="Community Profiles"
        emptyDescription="You do not have any Community Profiles created yet. Explore the community map to find your profile and request ownership of it."
        emptyActions={
          <Button
            label="Find Your Community Profile →"
            icon="community"
            type="default"
            link="/community/explore"
            permission="community_explore.view"
          />
        }
      />
    )
  }

  return (
    <>
      <Routes>
        <Route index element={<Navigate to={connectedEntities[0].slug} replace />} />
        <Route path=":slug/*" element={<CommunityProfile />} />
      </Routes>
    </>
  )
}
