import React from 'react'
import compact from 'lodash/compact'

import { useSettings } from '../../hooks'
import withSettings from '../../hocs/withSettings'

import Button from '../../components/Button'
import GlobalTags from '../../components/Table/Cells/GlobalTags'
import SmartFlags from '../../components/Table/Cells/SmartFlags'
import Table from '../../components/Table/Table'
import TableCell from '../../components/Table/TableCell'
import TableFilter from '../../components/Table/TableFilter'

const STATUSES: any = {
  active: 'Active',
  archived: 'Archived',
}

const LEVELS: any = {
  public_template: 'Public',
  behave_template: 'Behave',
}

const RootFormInternalTemplatesTable = (props: any) => {
  const {
    data,
    emptyDescription = 'No form templates created yet',
    localStorageKey = 'form_templates',
    onClick,
    queryKey,
    title = 'Form Templates',
    icon = 'web_form',
    to,
    duplicateFormLink,
    ...rest
  } = props

  const { timezone } = useSettings()

  return (
    <Table
      data={data}
      title={title}
      icon={icon}
      columns={columns(to, onClick, duplicateFormLink, queryKey, timezone, props.mainLinkAs, props.duplicateFormColumn)}
      emptyDescription={emptyDescription}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

const columns = (
  to: Function = () => {},
  onClick: Function = () => {},
  duplicateFormLink: any,
  queryKey: any,
  timezone: string,
  mainLinkAs,
  duplicateFormColumn,
) =>
  compact([
    {
      width: 260,
      isSticky: true,
      canToggleVisible: false,
      accessor: 'data.name',
      Header: 'Name',
      Cell: ({ value, row }: any) => (
        <TableCell.MainLink
          as={mainLinkAs}
          to={to(row.original.id, row.original.category)}
          onClick={() => onClick?.(row.original)}
          label={value}
        />
      ),
    },
    {
      width: 110,
      accessor: 'status',
      Header: 'Status',
      Cell: ({ value }: any) => <TableCell value={STATUSES[value]} />,
      Filter: TableFilter.Select,
      filter: 'equals',
      filterOptions: [
        { label: 'active', value: 'active' },
        { label: 'archived', value: 'archived' },
      ],
    },
    {
      width: 140,
      accessor: 'level',
      Header: 'Access',
      Cell: ({ value }: any) => <TableCell value={LEVELS[value]} />,
      Filter: TableFilter.Select,
      filter: 'equals',
      filterOptions: [
        { label: 'Public', value: 'public_template' },
        { label: 'Behave', value: 'behave_template' },
      ],
    },
    {
      width: 240,
      Header: 'Tags',
      accessor: 'global_tags',
      disableFilters: true,
      Cell: ({ row }: any) => (
        <GlobalTags
          queryKey={queryKey}
          tags={row.original.global_tags}
          updateId={row.original.id}
          updateKey="internal-template"
          updateEndpoint="/internal_templates"
          invalidate="internal-templates"
        />
      ),
    },
    {
      width: 240,
      Header: 'Smart Flags',
      accessor: 'smart_flags',
      disableFilters: true,
      Cell: ({ row }: any) => (
        <SmartFlags
          // queryKey={queryKey}
          value={row.original.smart_flags}
          updateId={row.original.id}
          updateKey="internal-template"
          updateEndpoint="/internal_templates"
          invalidate="internal-templates"
        />
      ),
    },
    {
      width: 200,
      accessor: 'created_at',
      Header: 'Date Added',
      Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
      Filter: TableFilter.Date,
      filter: 'date',
    },
    {
      width: 200,
      accessor: 'updated_at',
      Header: 'Date Updated',
      Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
      Filter: TableFilter.Date,
      filter: 'date',
    },
    {
      width: 180,
      accessor: 'author',
      Header: 'Added By',
      Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
    },
    duplicateFormLink && {
      width: 180,
      accessor: 'id',
      id: 'actions',
      Header: 'Actions',
      Cell: ({ row }: any) => <Button label="Duplicate" glyph="add" size={100} type="default" link={duplicateFormLink(row.original)} />,
    },
    duplicateFormColumn,
  ])

export const FormInternalTemplatesTable = withSettings(RootFormInternalTemplatesTable)
