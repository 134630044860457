import React from 'react'

import PieChart from '../ChartTypes/PieChart'

export const ClientDischargeTypeChart = (props: any) => {
  const data: any = [
    {
      name: 'Successfully Completed',
      value: Math.floor(Math.random() * 100),
    },
    {
      name: 'Therapeutic Discharge',
      value: Math.floor(Math.random() * 100),
    },
    {
      name: 'AMA',
      value: Math.floor(Math.random() * 100),
    },
    {
      name: 'MIA',
      value: Math.floor(Math.random() * 100),
    },
    {
      name: 'Administrative Discharge',
      value: Math.floor(Math.random() * 100),
    },
    {
      name: 'Other',
      value: Math.floor(Math.random() * 100),
    },
  ]

  return <PieChart data={data} {...props} />
}
