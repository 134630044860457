import React from 'react'

import Select from './Select'
import Option from './Option'

import { LOC } from '../../utils/constants'

const LevelOfCareSelect = ({ isEditable, label, model, validations, onUpdate, value, testKey, ...rest }: any) => (
  <Select
    fullWidth
    allowEmpty
    isEditable={isEditable}
    testKey={testKey}
    label={label}
    model={model}
    validations={validations}
    onUpdate={onUpdate}
    value={value}
    {...rest}
  >
    {Object.keys(LOC).map((level: any) => (
      <Option label={LOC[level]} value={level} />
    ))}
  </Select>
)

LevelOfCareSelect.defaultProps = {
  label: 'Level of Care',
  model: 'level_of_care',
}

export default LevelOfCareSelect
