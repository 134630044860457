import React from 'react'

import { ICONS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PropertyProfileHeader from '../../components/PropertyProfileHeader'
import Section from '../../components/Section'

const RootLocationFloorOverlay = (props: any) => {
  const { position = 'center' } = props

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialData,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    params,
  } = useOverlay({
    isEditable: true,
    name: 'floor',
    endpoint: '/floors',
    invalidate: 'floors',
    invalidateKeys: ['bed-management-timeline', 'location'],
    options: props,
  })

  const { resource_id }: any = params

  // get property data from API if not passed via router
  const { data: apiProperty, isLoading: isPropertyLoading }: any = useGet({
    name: ['location', resource_id],
    url: `/houses/${resource_id}`,
    options: { enabled: !!resource_id && !initialData?.property },
  })

  // get property data from router or from API
  const propertyData = initialData?.property || apiProperty

  const { timezone, isBehave } = useSettings()

  if (isOverlayLoading || isPropertyLoading) {
    return <OverlayLoader position={position} />
  }

  return (
    <Overlay showBackdrop position={position} onClose={close}>
      <Overlay.Header
        icon={ICONS.floors}
        title={isNew ? 'Add New Floor' : data?.name}
        description={propertyData && <PropertyProfileHeader property={propertyData} />}
      />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew &&
            propertyData && {
              reference_id: propertyData.id,
              reference_type: propertyData.type,
            }
          }
        >
          <Section>
            <FormSection>
              <Input
                model="name"
                label="Floor Name"
                validations={{
                  presence: {
                    message: 'Please enter a floor name',
                  },
                }}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Floor?"
              message="Are you sure you want to delete this floor? This action cannot be undone."
              onYes={deleteRecord}
              isDisabled={isBehave ? false : !data?.is_deletable}
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                isDisabled={isBehave ? false : !data?.is_deletable}
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const LocationFloorOverlay = withOverlayError(RootLocationFloorOverlay)
