import DateInput from './DateInput'
import { withFormContext } from './context'

class DateOfBirthInput extends DateInput {}

DateOfBirthInput.defaultProps = {
  firstYear: 1930,
  futureYears: 0,
  validateOn: 'blur-change',
}

export default withFormContext(DateOfBirthInput)
