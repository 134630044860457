import React from 'react'
import { useLocation } from 'react-router-dom'

import Card from '../../components/Card'
import CardTitle from '../../components/CardTitle'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Label from '../../components/Label'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const INITIAL_STATE = {
  physical_health: 0,
  trouble_falling_or_staying_asleep: 0,
  days_depressed_anxious_angry: 0,
  days_drinking_alcohol: 0,
  days_drinking_above_recommended_limit: 0,
  days_using_drugs_or_abuse_prescriptions: 0,
  bothered_by_alcohol_drugs_cravings: 0,
  confidence_in_staying_abstinent_for_a_month: 0,
  days_attending_self_help_meetings: 0,
  days_around_risky_people: 0,
  is_religion_or_spirituality_supportive: 0,
  days_at_work_school_or_volunteering: 0,
  has_adequate_income: 0,
  bothered_by_problems_with_family_or_friends: 0,
  days_spent_with_supportive_family_or_friends: 0,
  satisfied_with_progress_toward_recovery: 0,
}

const RootBAMOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="BAM Brief Addiction Monitor"
      category="clinical_measurement"
      subcategory="bam"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <p>This is a standard set of questions about several areas of your life such as your health, alcohol and drug use, etc.</p>
        <p>The questions generally ask about the past 30 days.</p>
        <p>Please consider each question and answer as accurately as possible. </p>
      </Section>

      <Divider />

      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <RadioGroup
          label="1. In the past 30 days, would you say your physical health has been?"
          model="data.questionnaire.physical_health"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, physical_health: state.value }))}
        >
          <Radio label="Excellent (0)" value={0} />
          <Radio label="Very Good (1)" value={1} />
          <Radio label="Good (2)" value={2} />
          <Radio label="Fair (3)" value={3} />
          <Radio label="Poor (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="2. In the past 30 days, how many nights did you have trouble falling asleep or staying asleep?"
          model="data.questionnaire.trouble_falling_or_staying_asleep"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, trouble_falling_or_staying_asleep: state.value }))}
        >
          <Radio label="0 (0)" value={0} />
          <Radio label="1-3 (1)" value={1} />
          <Radio label="4-8 (2)" value={2} />
          <Radio label="9-15 (3)" value={3} />
          <Radio label="16-30 (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="3. In the past 30 days, how many days have you felt depressed, anxious, angry or very upset throughout most of the day?"
          model="data.questionnaire.days_depressed_anxious_angry"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, days_depressed_anxious_angry: state.value }))}
        >
          <Radio label="0 (0)" value={0} />
          <Radio label="1-3 (1)" value={1} />
          <Radio label="4-8 (2)" value={2} />
          <Radio label="9-15 (3)" value={3} />
          <Radio label="16-30 (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="4. In the past 30 days, how many days did you drink ANY alcohol?"
          model="data.questionnaire.days_drinking_alcohol"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, days_drinking_alcohol: state.value }))}
        >
          <Radio label="0 (Skip to #6) (0)" value={0} />
          <Radio label="1-3 (1)" value={1} />
          <Radio label="4-8 (2)" value={2} />
          <Radio label="9-15 (3)" value={3} />
          <Radio label="16-30 (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="5. In the past 30 days, how many days did you have at least 5 drinks (if you are a man) or at least 4 drinks (if you are a woman)?"
          model="data.questionnaire.days_drinking_above_recommended_limit"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, days_drinking_above_recommended_limit: state.value }))}
        >
          <Radio label="0 (0)" value={0} />
          <Radio label="1-3 (1)" value={1} />
          <Radio label="4-8 (2)" value={2} />
          <Radio label="9-15 (3)" value={3} />
          <Radio label="16-30 (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="6. In the past 30 days, how many days did you use any illegal/street drugs or abuse any prescription medications?"
          model="data.questionnaire.days_using_drugs_or_abuse_prescriptions"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, days_using_drugs_or_abuse_prescriptions: state.value }))}
        >
          <Radio label="0 (Skip to #8) (0)" value={0} />
          <Radio label="1-3 (1)" value={1} />
          <Radio label="4-8 (2)" value={2} />
          <Radio label="9-15 (3)" value={3} />
          <Radio label="16-30 (4)" value={4} />
        </RadioGroup>

        <Label label="7. In the past 30 days, how many days did you use any of the following drugs:" />

        <Grid gap="1rem" className="!pl-[1.75rem]">
          <RadioGroup label="7A. Marijuana (cannabis, pot, weed)?" model="data.questionnaire.marijuana_use" layout="vertical-dense">
            <Radio label="0" value={0} />
            <Radio label="1-3" value={1} />
            <Radio label="4-8" value={2} />
            <Radio label="9-15" value={3} />
            <Radio label="16-30" value={4} />
          </RadioGroup>

          <RadioGroup
            label={`7B. Sedatives/Tranquilizers (e.g., "benzos", Valium, Xanax, Ativan, Ambien, "barbs", Phenobarbital, downers, etc.)?`}
            model="data.questionnaire.sedatives_tranquilizers_use"
            layout="vertical-dense"
          >
            <Radio label="0" value={0} />
            <Radio label="1-3" value={1} />
            <Radio label="4-8" value={2} />
            <Radio label="9-15" value={3} />
            <Radio label="16-30" value={4} />
          </RadioGroup>

          <RadioGroup label="7C. Cocaine/Crack?" model="data.questionnaire.cocaine_crack_use" layout="vertical-dense">
            <Radio label="0" value={0} />
            <Radio label="1-3" value={1} />
            <Radio label="4-8" value={2} />
            <Radio label="9-15" value={3} />
            <Radio label="16-30" value={4} />
          </RadioGroup>

          <RadioGroup
            label={`7D. Other Stimulants (e.g., amphetamine, methamphetamine, Dexedrine, Ritalin, Adderall, speed", "crystal meth", "ice", etc.)?`}
            model="data.questionnaire.other_stimulants_use"
            layout="vertical-dense"
          >
            <Radio label="0" value={0} />
            <Radio label="1-3" value={1} />
            <Radio label="4-8" value={2} />
            <Radio label="9-15" value={3} />
            <Radio label="16-30" value={4} />
          </RadioGroup>

          <RadioGroup
            label="7E. Opiates (e.g., Heroin, Morphine, Dilaudid, Demerol, Oxycontin, oxy, codeine (Tylenol 2,3,4), Percocet, Vicodin, Fentanyl, etc.)?"
            model="data.questionnaire.opiates_use"
            layout="vertical-dense"
          >
            <Radio label="0" value={0} />
            <Radio label="1-3" value={1} />
            <Radio label="4-8" value={2} />
            <Radio label="9-15" value={3} />
            <Radio label="16-30" value={4} />
          </RadioGroup>

          <RadioGroup
            label="7F. Inhalants (glues/adhesives, nail polish remover, paint thinner, etc.)?"
            model="data.questionnaire.inhalants_use"
            layout="vertical-dense"
          >
            <Radio label="0" value={0} />
            <Radio label="1-3" value={1} />
            <Radio label="4-8" value={2} />
            <Radio label="9-15" value={3} />
            <Radio label="16-30" value={4} />
          </RadioGroup>

          <RadioGroup
            label="7G. Other drugs (steroids, non-prescription sleep/diet pills, Benadryl, Ephedra, other overthe-counter/unknown medications)?"
            model="data.questionnaire.other_drugs_use"
            layout="vertical-dense"
          >
            <Radio label="0" value={0} />
            <Radio label="1-3" value={1} />
            <Radio label="4-8" value={2} />
            <Radio label="9-15" value={3} />
            <Radio label="16-30" value={4} />
          </RadioGroup>
        </Grid>

        <RadioGroup
          label="8. In the past 30 days, how much were you bothered by cravings or urges to drink alcohol or use drugs?"
          model="data.questionnaire.bothered_by_alcohol_drugs_cravings"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, bothered_by_alcohol_drugs_cravings: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (1)" value={1} />
          <Radio label="Moderately (2)" value={2} />
          <Radio label="Considerably (3)" value={3} />
          <Radio label="Extremely (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="9. How confident are you in your ability to be completely abstinent (clean) from alcohol and drugs in the next 30 days?"
          model="data.questionnaire.confidence_in_staying_abstinent_for_a_month"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, confidence_in_staying_abstinent_for_a_month: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (1)" value={1} />
          <Radio label="Moderately (2)" value={2} />
          <Radio label="Considerably (3)" value={3} />
          <Radio label="Extremely (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="10. In the past 30 days, how many days did you attend self-help meetings like AA or NA to support your recovery?"
          model="data.questionnaire.days_attending_self_help_meetings"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, days_attending_self_help_meetings: state.value }))}
        >
          <Radio label="0 (0)" value={0} />
          <Radio label="1-3 (1)" value={1} />
          <Radio label="4-8 (2)" value={2} />
          <Radio label="9-15 (3)" value={3} />
          <Radio label="16-30 (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="11. In the past 30 days, how many days were you in any situations or with any people that might put you at an increased risk for using alcohol or drugs (i.e., around risky “people, places or things”)?"
          model="data.questionnaire.days_around_risky_people"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, days_around_risky_people: state.value }))}
        >
          <Radio label="0 (0)" value={0} />
          <Radio label="1-3 (1)" value={1} />
          <Radio label="4-8 (2)" value={2} />
          <Radio label="9-15 (3)" value={3} />
          <Radio label="16-30 (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="12. Does your religion or spirituality help support your recovery?"
          model="data.questionnaire.is_religion_or_spirituality_supportive"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, is_religion_or_spirituality_supportive: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (1)" value={1} />
          <Radio label="Moderately (2)" value={2} />
          <Radio label="Considerably (3)" value={3} />
          <Radio label="Extremely (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="13. In the past 30 days, how many days did you spend much of the time at work, school, or doing volunteer work?"
          model="data.questionnaire.days_at_work_school_or_volunteering"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, days_at_work_school_or_volunteering: state.value }))}
        >
          <Radio label="0 (0)" value={0} />
          <Radio label="1-3 (1)" value={1} />
          <Radio label="4-8 (2)" value={2} />
          <Radio label="9-15 (3)" value={3} />
          <Radio label="16-30 (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="14. Do you have enough income (from legal sources) to pay for necessities such as housing, transportation, food and clothing for yourself and your dependents?"
          model="data.questionnaire.has_adequate_income"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, has_adequate_income: state.value }))}
        >
          <Radio label="No (0)" value={0} />
          <Radio label="Yes (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="15. In the past 30 days, how much have you been bothered by arguments or problems getting along with any family members or friends?"
          model="data.questionnaire.bothered_by_problems_with_family_or_friends"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, bothered_by_problems_with_family_or_friends: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (1)" value={1} />
          <Radio label="Moderately (2)" value={2} />
          <Radio label="Considerably (3)" value={3} />
          <Radio label="Extremely (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="16. In the past 30 days, how many days were you in contact or spent time with any family members or friends who are supportive of your recovery?"
          model="data.questionnaire.days_spent_with_supportive_family_or_friends"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, days_spent_with_supportive_family_or_friends: state.value }))}
        >
          <Radio label="0 (0)" value={0} />
          <Radio label="1-3 (1)" value={1} />
          <Radio label="4-8 (2)" value={2} />
          <Radio label="9-15 (3)" value={3} />
          <Radio label="16-30 (4)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="17. How satisfied are you with your progress toward achieving your recovery goals?"
          model="data.questionnaire.satisfied_with_progress_toward_recovery"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, satisfied_with_progress_toward_recovery: state.value }))}
        >
          <Radio label="Not at all (4)" value={4} />
          <Radio label="Slightly (3)" value={3} />
          <Radio label="Moderately (2)" value={2} />
          <Radio label="Considerably (1)" value={1} />
          <Radio label="Extremely (0)" value={0} />
        </RadioGroup>
      </Grid>

      <Divider />

      <Grid gap="1rem">
        <DateTimeInput defaultToNow label="Time Started" model="data.started_at" />
        <DateTimeInput label="Time Ended" model="data.ended_at" />
      </Grid>

      {!isPortal && (
        <Grid gap="1rem">
          <Divider />

          <Card className="!p-4">
            <CardTitle title="Specific items to attend to, and suggested referrals, include:" />

            <ul className="!mt-2">
              <ScoreListItem isActive={score.physical_health >= 3} children="#1 (health), if scored 3 or 4, refer to primary care" />
              <ScoreListItem
                isActive={score.days_depressed_anxious_angry >= 2}
                children="#3 (mood), if scored 2, 3, or 4, proceed to further assessment and address within SUD specialty care or refer to mental health clinic if indicated"
              />
              <ScoreListItem
                isActive={score.days_drinking_above_recommended_limit >= 1 || score.days_using_drugs_or_abuse_prescriptions >= 1}
                children="#5,6,7 (heavy alcohol use, any drug use, specific drug use), if any scored 1 or higher, discuss with patient and consider adjusting treatment (e.g., higher level of care or changing modality)"
              />
              <ScoreListItem
                isActive={score.bothered_by_alcohol_drugs_cravings >= 3}
                children="#8 (craving), if scored 3 or 4, consider medication such as Naltrexone"
              />
              <ScoreListItem
                isActive={score.has_adequate_income === 0}
                children="#14 (adequate income), if scored 0, refer to case management"
              />
              <ScoreListItem
                isActive={score.days_spent_with_supportive_family_or_friends <= 2}
                children="#16 (social support), if scored 0, 1, or 2 consider adding network support"
              />
              <ScoreListItem
                isActive={score.satisfied_with_progress_toward_recovery >= 3}
                children="#17 (satisfaction with progress), if scored 3 or 4, discuss modifying or supplementing treatment"
              />
            </ul>
          </Card>

          <Score title="Final Score" finalScore={finalScore}>
            <p>
              Note: Examining scores from individual items as described above is the most clinically relevant use of this measure. Summary
              scores are more useful for aggregating across patients. Aggregate scoring, or subscale scoring, is supplementary and very
              preliminary, based on clinical judgment rather than empirical data.
            </p>

            <Divider />

            <h4>Preliminary Subscale Scoring information:</h4>

            <ul>
              <li>Sum of Items 4, 5, & 6 = Use (Scores range from 0 to 12 with higher scores meaning more Use)</li>
              <li>Sum of Items 1, 2, 3, 8, 11, & 15 = Risk factors (Scores range from 0 to 24 with higher scores meaning more Risk)</li>
              <li>
                Sum of Items 9, 10, 12, 13, 14, & 16 = Protective factors (Scores range from 0 to 24 with higher scores meaning more
                Protection)
              </li>
              <li>Number in ( ) is points for each response</li>
              <li>Item 7 (7A-7G) are not scored as part of the subscales but provide elaboration for item 6.</li>
              <li>
                Item 17 can be used as an overall assessment of treatment progress, but is not scored on any of the specific subscales.
              </li>
            </ul>

            <Divider />

            <h4>Clinical guidelines:</h4>

            <p>The three subscales include:</p>

            <ul>
              <li>
                <p>
                  <b>
                    <u>Use:</u> If a patient scores a 1 or greater, it calls for further examination and clinical attention, e.g. consider
                    addition of pharmacotherapy or higher level of care, add motivational interviewing.
                  </b>
                </p>
                <ul>
                  <li>Any alcohol use (item #4)</li>
                  <li>Heavy alcohol use (item #5)</li>
                  <li>Any drug use (item #6)</li>
                </ul>
              </li>

              <li>
                <p>
                  <b>
                    <u>Risk Factors:</u> If a patient scores a 12 or greater, it calls for further examination and clinical attention, e.g.
                    refer for medical or mental health consulation, add CBT or relapse prevention skills training.
                  </b>
                </p>
                <ul>
                  <li>Cravings (item #8)</li>
                  <li>Physical Health (item #1)</li>
                  <li>Sleep (item #2)</li>
                  <li>Mood (item #3)</li>
                  <li>Risky situations (item #11)</li>
                  <li>Family/social problems (item #15)</li>
                </ul>
              </li>

              <li>
                <p>
                  <b>
                    <u>Protective Factors:</u> If a patient scores a 12 or below, it calls for further examination and clinical attention,
                    e.g. treatment plan might include building sober support networks, 12 step facilitation, or work with a case manager for
                    work or income assistance.
                  </b>
                </p>
                <ul>
                  <li>Self-efficacy (item #9)</li>
                  <li>Self-help behaviors (item #10)</li>
                  <li>Religion/spirituality (item #12)</li>
                  <li>Work/school participation (item #13)</li>
                  <li>Adequate Income (item #14)</li>
                  <li>Sober support (item #16)</li>
                </ul>
              </li>
            </ul>
          </Score>
        </Grid>
      )}
    </>
  )
}

const ScoreListItem = ({ children, isActive }: any) => {
  return (
    <li css={styles.scoreListItem} className={isActive ? 'is-active' : ''}>
      {children}
    </li>
  )
}

const styles = {
  scoreListItem: {
    opacity: 0.4,

    '&.is-active': {
      opacity: 1,
      fontWeight: 700,
    },
  },
}

export const BAMOverlay = withOverlayError(RootBAMOverlay)
