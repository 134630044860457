import React from 'react'

import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
import padStart from 'lodash/padStart'
import produce from 'immer'

import { withFormContext } from './context'
import { usDateTime, isDefined } from '../../utils/functions'
import { validate } from './validators'

import BaseInput from './BaseInput'
import Flex from '../Flex'
import { Text } from '../Typography'
import Value from '../Value'
import Select from './Select'
import Option from './Option'

import FieldBase from './FieldBase'
import { DatePicker } from '../DatePicker'

const MIN_YEAR = 1950
const MAX_YEAR = 2030

const processDay = (day: any, month: any, year: any) => {
  if (isNaN(day)) return null

  const parsedMonth = month || DateTime.local().month
  const parsedYear = year || DateTime.local().year
  const daysInMonth = DateTime.local(parsedYear, parsedMonth).daysInMonth

  if (day < 1) return 1
  else if (day > daysInMonth) return daysInMonth
  else return day
}

const processMonth = (month: any) => {
  if (isNaN(month)) return null
  else if (month < 1) return 1
  else if (month > 12) return 12
  else return month
}

const processYear = (year: any) => {
  if (isNaN(year)) return null
  else if (year < 0) return MIN_YEAR
  else if (year >= 0 && year <= 30) return 2000 + year
  else if (year > 30 && year < 100) return 1900 + year
  else if (year >= 100 && year <= MIN_YEAR) return MIN_YEAR
  else if (year >= MAX_YEAR) return MAX_YEAR
  else return year
}

const processHour = (hour: any, meridiem: any) => {
  if (isNaN(hour)) return null

  if (meridiem === 'AM') {
    if (hour < 0) return 11
    if (hour > 11) return 0
  } else if (meridiem === 'PM') {
    if (hour > 12) return 1
    if (hour < 1) return 12
  }

  return hour
}

const processMinute = (minute: any) => {
  if (isNaN(minute)) return null
  else if (minute < 0) 59
  else if (minute > 59) 0
  else return minute
}

const allVisualFieldsCompleted = (visual: any) => {
  if (!visual) return false

  return (
    visual.year.length === 4 &&
    visual.month.length === 2 &&
    visual.day.length === 2 &&
    visual.hour.length === 2 &&
    visual.minute.length === 2
  )
}

class DateTimeInput extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    let timezone = props.timezone

    const vs = props.validations
    let date = DateTime.fromISO(props.value, { zone: timezone })

    if (!date.isValid) {
      const model = props.form?.getField(props.model)
      const initialModel = props.form?.getInitialInputFieldValue(props.model)
      const val = model || initialModel || props.default

      if (val) {
        date = DateTime.fromISO(val, { zone: timezone })
      } else {
        let localTime = DateTime.local().set({ millisecond: 0 })

        if (props.defaultToNow) {
          date = DateTime.fromObject({
            year: localTime.year,
            month: localTime.month,
            day: localTime.day,
            hour: localTime.hour,
            minute: localTime.minute,
            second: localTime.second,
            millisecond: localTime.millisecond,
            zone: timezone,
          })
        } else if (props.defaultToEndOfToday) {
          localTime = localTime.endOf('day')
          date = DateTime.fromObject({
            year: localTime.year,
            month: localTime.month,
            day: localTime.day,
            hour: localTime.hour,
            minute: localTime.minute,
            second: localTime.second,
            millisecond: localTime.millisecond,
            zone: timezone,
          })
        } else if (props.defaultToTomorrow) {
          localTime = localTime.plus({ days: 1 })
          date = DateTime.fromObject({
            year: localTime.year,
            month: localTime.month,
            day: localTime.day,
            hour: localTime.hour,
            minute: localTime.minute,
            second: localTime.second,
            millisecond: localTime.millisecond,
            zone: timezone,
          })
        } else if (props.defaultToOneMonthAgo) {
          localTime = localTime.minus({ months: 1 })
          date = DateTime.fromObject({
            year: localTime.year,
            month: localTime.month,
            day: localTime.day,
            hour: localTime.hour,
            minute: localTime.minute,
            second: localTime.second,
            millisecond: localTime.millisecond,
            zone: timezone,
          })
        } else if (props.defaultInOneMonth) {
          localTime = localTime.plus({ months: 1 })
          date = DateTime.fromObject({
            year: localTime.year,
            month: localTime.month,
            day: localTime.day,
            hour: localTime.hour,
            minute: localTime.minute,
            second: localTime.second,
            millisecond: localTime.millisecond,
            zone: timezone,
          })
        }
      }
    }

    const value = date.toISO()
    if (vs) errors = validate(value, vs)

    const meridiem = date.hour >= 12 ? 'PM' : 'AM'
    const finalHour = date.hour - (meridiem === 'PM' && date.hour > 12 ? 12 : 0)

    const day = date.day ? padStart(date.day.toString(), 2, '0') : ''
    const month = date.month ? padStart(date.month.toString(), 2, '0') : ''
    const year = date.year ? date.year.toString() : ''
    const hour = isNaN(date.hour) ? '' : padStart(finalHour, 2, '0')
    const minute = isNaN(date.minute) ? '' : padStart(date.minute.toString(), 2, '0')

    const isRequired = props.validations?.hasOwnProperty('presence')

    this.state = {
      type: 'DATEINPUT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      date: date,
      value: value,
      day: date.isValid ? date.day : null,
      month: date.isValid ? date.month : null,
      year: date.isValid ? date.year : null,
      hour: date.isValid ? date.hour : null,
      minute: date.isValid ? date.minute : null,
      meridiem: meridiem,
      visual: {
        day: day,
        month: month,
        year: year,
        hour: hour,
        minute: minute,
      },
      smartValue: props.smartDescription ? props.smartDescription(null) : '',
      allowErrors: value ? true : false,
      allFieldsCompleted: value ? true : false,
      isNested: props.isNested || false,
      inFocus: null,
      focused: {
        day: false,
        month: false,
        year: false,
        hour: true,
        minute: true,
      },
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isRequired: isRequired,
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
      isPickerOpen: false,
    }

    this.initialData = {
      value: value,
      date: date,
      day: day,
      month: month,
      year: year,
      hour: hour,
      minute: minute,
      meridiem: meridiem,
      visual: {
        day: day,
        month: month,
        year: year,
        hour: hour,
        minute: minute,
      },
      smartValue: props.smartDescription ? props.smartDescription(null) : '',
      allFieldsCompleted: value ? true : false,
      focused: {
        day: false,
        month: false,
        year: false,
        hour: true,
        minute: true,
      },
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.updateType = 'DATA'
  }

  /*
    CUSTOM FUNCTIONS
  */
  onChange = (event: any) => {
    const target = event.target
    const value = target.value

    // if the target is not a number return
    if (target.id !== 'meridiem' && value !== '' && isNaN(parseInt(value))) return

    // parse the event for the UI
    const newState = produce(this.state, (draft: any) => {
      if (target.id === 'day') draft.visual.day = value
      else if (target.id === 'month') draft.visual.month = value
      else if (target.id === 'year') draft.visual.year = value
      else if (target.id === 'hour') draft.visual.hour = value
      else if (target.id === 'minute') draft.visual.minute = value

      if (target.id === 'meridiem') {
        // update meridiem
        draft.meridiem = value

        // process date with the new meridiem
        const date = this.processDate({
          day: draft.visual.day,
          month: draft.visual.month,
          year: draft.visual.year,
          hour: draft.visual.hour,
          minute: draft.visual.minute,
          meridiem: value,
        })

        draft.date = date
        draft.value = date?.toISO() || null

        if (this.props.smartDescription) {
          draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
        }

        if (draft.date?.isValid) {
          draft.year = date.year
          draft.month = date.month
          draft.day = date.day
          draft.hour = date.hour
          draft.minute = date.minute
        }
      }
    })

    if (target.id === 'meridiem') {
      this.setState({
        visual: newState.visual,
        meridiem: newState.meridiem,

        year: newState.year,
        month: newState.month,
        day: newState.day,
        hour: newState.hour,
        minute: newState.minute,

        date: newState.date,
        value: newState.value,

        allFieldsCompleted: newState.allFieldsCompleted,
        smartValue: newState.smartValue,
      })

      // update form engine
      if (this.props.form && this.props.model) this.props.form.change(newState)
    } else {
      // update visual state
      this.setState({ visual: newState.visual })
    }
  }

  processValidation = (queueEvent: any) => {
    const fieldsCompleted = allVisualFieldsCompleted(queueEvent?.state?.visual)

    const date = DateTime.fromISO(queueEvent.value, { zone: this.props.timezone })
    let errors = []

    if (fieldsCompleted || this.state.isRequired) {
      errors = date.isValid
        ? validate(date, { ...this.props.defaultValidations, ...this.props.validations })
        : [{ message: 'Please enter a valid date time' }]
    }

    const newState = produce(this.state, (draft: any) => {
      draft.isValid = errors.length ? false : true
      draft.isInvalid = errors.length ? true : false
      draft.errors = errors
    })

    // update local state
    this.setState({
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      errors: errors,
    })

    return newState
  }

  getVisualStrings = (state: any) => {
    const finalHour = state.hour - (state.meridiem === 'PM' && state.hour > 12 ? 12 : 0)

    const monthString = state.month ? padStart(state.month?.toString(), 2, '0') : ''
    const dayString = state.day ? padStart(state.day?.toString(), 2, '0') : ''
    const yearString = state.year ? state.year.toString() : ''

    const hourString = isDefined(state.hour) ? padStart(finalHour?.toString(), 2, '0') : ''
    const minuteString = isDefined(state.minute) ? padStart(state.minute?.toString(), 2, '0') : ''

    return {
      year: yearString,
      month: monthString,
      day: dayString,
      hour: hourString,
      minute: minuteString,
    }
  }

  processUpdate = (_queueEvent: any) => {
    const time = DateTime.fromISO(this.props.value || this.props.defaultValue, { zone: this.props.timezone })

    const newState = produce(this.state, (draft: any) => {
      if (time) {
        draft.year = time.year
        draft.month = time.month
        draft.day = time.day
        draft.hour = time.hour - (draft.meridiem === 'PM' && time.hour > 12 ? 12 : 0)
        draft.minute = time.minute
        draft.meridiem = time.toFormat('a')

        draft.date = time
        draft.value = draft.date.toISO()

        draft.allFieldsCompleted = true

        // update the final hour that gets saved

        if (this.props.smartDescription) {
          draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
        }

        draft.visual = this.getVisualStrings(draft)

        draft.isPristine = false
        draft.isDirty = true
      }
    })

    this.setState({
      year: newState.year,
      month: newState.month,
      day: newState.day,
      hour: newState.hour,
      minute: newState.minute,
      meridiem: newState.meridiem,
      visual: newState.visual,
      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      smartValue: newState.smartValue,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })

    return newState
  }

  processDate = ({ day, month, year, hour, minute, meridiem }: any) => {
    // try to parse the strings into numbers
    const parsedDay = parseInt(day)
    const parsedMonth = parseInt(month)
    const parsedYear = parseInt(year)
    const parsedHour = parseInt(hour)
    const parsedMinute = parseInt(minute)

    // check if all the numbers are all ok
    const dayDefined = Number.isInteger(parsedDay)
    const monthDefined = Number.isInteger(parsedMonth)
    const yearDefined = Number.isInteger(parsedYear)
    const hourDefined = Number.isInteger(parsedHour)
    const minuteDefined = Number.isInteger(parsedMinute)
    const meridiemDefined = isDefined(meridiem)

    // if yes, create a date object
    let date = null
    if (yearDefined && monthDefined && dayDefined && hourDefined && minuteDefined && meridiemDefined) {
      let meridiemExtra = 0

      // add meridiems
      if (meridiem === 'PM' && parsedHour < 12) meridiemExtra = 12
      if (meridiem === 'AM' && parsedHour >= 12) meridiemExtra = -12

      date = DateTime.fromObject({
        year: parsedYear,
        month: parsedMonth,
        day: parsedDay,
        hour: parsedHour + meridiemExtra,
        minute: parsedMinute,
        zone: this.props.timezone,
      })
    }

    return date
  }

  processFocus = (queueEvent: any) => {
    if (queueEvent.event.target) queueEvent.event.target.select()

    const newState = produce(this.state, (draft: any) => {
      if (queueEvent.event.target.id === 'day') {
        draft.focused.day = true
        draft.inFocus = 'day'
      } else if (queueEvent.event.target.id === 'month') {
        draft.focused.month = true
        draft.inFocus = 'month'
      } else if (queueEvent.event.target.id === 'year') {
        draft.focused.year = true
        draft.inFocus = 'year'
      } else if (queueEvent.event.target.id === 'hour') {
        draft.focused.hour = true
        draft.inFocus = 'hour'
      } else if (queueEvent.event.target.id === 'minute') {
        draft.focused.minute = true
        draft.inFocus = 'minute'
      }

      draft.isTouched = true
      draft.isUntouched = true
    })

    this.setState({
      focused: newState.focused,
      inFocus: newState.inFocus,
      isTouched: true,
      isUntouched: true,
    })

    return newState
  }

  processBlur = (_queueEvent: any) => {
    const newState = produce(this.state, (draft: any) => {
      draft.isBlur = true
      draft.isHighlighted = false

      // beautify day
      if (draft.inFocus === 'day') {
        if (draft.visual.day === '0' || draft.visual.day === '00') draft.visual.day = '01'
        else {
          const dayDefined = Number.isInteger(parseInt(draft.visual.day))
          if (dayDefined) draft.visual.day = padStart(draft.visual.day, 2, '0')
        }
      }

      // beautify month
      if (draft.inFocus === 'month') {
        if (draft.visual.month === '0' || draft.visual.month === '00') draft.visual.month = '01'
        else {
          const monthDefined = Number.isInteger(parseInt(draft.visual.month))
          if (monthDefined) draft.visual.month = padStart(draft.visual.month, 2, '0')
        }
      }

      // beautify year
      if (draft.inFocus === 'year') {
        if (draft.visual.year.length === 1) {
          draft.visual.year = `200${draft.visual.year}`
        } else if (draft.visual.year.length === 2) {
          const parsed = parseInt(draft.visual.year)

          if (parsed >= 10 && parsed <= 30) {
            draft.visual.year = `20${draft.visual.year}`
          } else if (parsed > 30 && parsed <= 99) {
            draft.visual.year = `19${draft.visual.year}`
          }
        } else if (draft.visual.year.length === 3) {
          draft.visual.year = '2000'
        }
      }

      // beautify hour
      if (draft.inFocus === 'hour') {
        if (draft.visual.hour === '00' || draft.visual.hour === '0' || draft.visual.hour === null) {
          if (draft.meridiem === 'AM') draft.visual.hour = '00'
          else if (draft.meridiem === 'PM') draft.visual.hour = '12'
        } else {
          const hourDefined = Number.isInteger(parseInt(draft.visual.hour))
          if (hourDefined) draft.visual.hour = padStart(draft.visual.hour, 2, '0')
        }
      }

      // beautify minute
      if (draft.inFocus === 'minute') {
        if (draft.visual.minute === '0' || draft.visual.minute === null) {
          draft.visual.minute = '00'
        } else {
          const minuteDefined = Number.isInteger(parseInt(draft.visual.minute))
          if (minuteDefined) draft.visual.minute = padStart(draft.visual.minute, 2, '0')
        }
      }

      const date = this.processDate({
        day: draft.visual.day,
        month: draft.visual.month,
        year: draft.visual.year,
        hour: draft.visual.hour,
        minute: draft.visual.minute,
        meridiem: draft.meridiem,
      })

      draft.date = date
      draft.value = date?.toISO() || null

      if (this.props.smartDescription) {
        draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
      }

      if (draft.date?.isValid) {
        draft.year = date.year
        draft.month = date.month
        draft.day = date.day
        draft.hour = date.hour
        draft.minute = date.minute
      }
    })

    this.setState({
      visual: newState.visual,
      isBlur: true,
      isHighlighted: false,

      year: newState.year,
      month: newState.month,
      day: newState.day,
      hour: newState.hour,
      minute: newState.minute,

      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      smartValue: newState.smartValue,

      // isPickerOpen: false,
    })

    return newState
  }

  onKeyDown = (event: any) => {
    const upOrDownPressed = event.keyCode === 38 || event.keyCode === 40
    if (!upOrDownPressed) return

    const newState = produce(this.state, (draft: any) => {
      if (event.keyCode === 40) {
        // DOWN
        if (event.target.id === 'day') {
          const parsedDay = parseInt(draft.visual.day)
          const dayDefined = Number.isInteger(parsedDay)

          if (dayDefined) draft.day = processDay(parsedDay - 1, draft.month, draft.year)
          else draft.day = 1

          draft.visual.day = padStart(draft.day.toString(), 2, '0')
        } else if (event.target.id === 'month') {
          const parsedMonth = parseInt(draft.visual.month)
          const monthDefined = Number.isInteger(parsedMonth)

          if (monthDefined) draft.month = processMonth(parsedMonth - 1)
          else draft.month = 1

          draft.visual.month = padStart(draft.month.toString(), 2, '0')
        } else if (event.target.id === 'year') {
          const parsedYear = parseInt(draft.visual.year)
          const yearDefined = Number.isInteger(parsedYear)

          if (yearDefined) draft.year = processYear(parsedYear - 1)
          else draft.year = 1

          draft.visual.year = draft.year.toString()
        } else if (event.target.id === 'hour') {
          const parsedHour = parseInt(draft.visual.hour)
          const hourDefined = Number.isInteger(parsedHour)

          if (hourDefined) draft.hour = processHour(parsedHour - 1, draft.meridiem)
          else draft.hour = draft.meridiem === 'AM' ? 0 : 12

          draft.visual.hour = padStart(draft.hour.toString(), 2, '0')
        } else if (event.target.id === 'minute') {
          const parsedMinute = parseInt(draft.visual.minute)
          const minuteDefined = Number.isInteger(parsedMinute)

          if (minuteDefined) draft.minute = processMinute(parsedMinute - 1)
          else draft.minute = 0

          draft.visual.minute = padStart(draft.minute.toString(), 2, '0')
        }
      } else if (event.keyCode === 38) {
        // UP
        if (event.target.id === 'day') {
          const parsedDay = parseInt(draft.visual.day)
          const dayDefined = Number.isInteger(parsedDay)

          if (dayDefined) draft.day = processDay(parsedDay + 1, draft.month, draft.year)
          else draft.day = 1

          draft.visual.day = padStart(draft.day.toString(), 2, '0')
        } else if (event.target.id === 'month') {
          const parsedMonth = parseInt(draft.visual.month)
          const monthDefined = Number.isInteger(parsedMonth)

          if (monthDefined) draft.month = processMonth(parsedMonth + 1)
          else draft.month = 1

          draft.visual.month = padStart(draft.month.toString(), 2, '0')
        } else if (event.target.id === 'year') {
          const parsedYear = parseInt(draft.visual.year)
          const yearDefined = Number.isInteger(parsedYear)

          if (yearDefined) draft.year = processYear(parsedYear + 1)
          else draft.year = 1

          draft.visual.year = draft.year.toString()
        } else if (event.target.id === 'hour') {
          const parsedHour = parseInt(draft.visual.hour)
          const hourDefined = Number.isInteger(parsedHour)

          if (hourDefined) draft.hour = processHour(parsedHour + 1, draft.meridiem)
          else draft.hour = draft.meridiem === 'AM' ? 0 : 12

          draft.visual.hour = padStart(draft.hour.toString(), 2, '0')
        } else if (event.target.id === 'minute') {
          const parsedMinute = parseInt(draft.visual.minute)
          const minuteDefined = Number.isInteger(parsedMinute)

          if (minuteDefined) draft.minute = processMinute(parsedMinute + 1)
          else draft.minute = 0

          draft.visual.minute = padStart(draft.minute.toString(), 2, '0')
        }
      }

      const date = this.processDate({
        day: draft.visual.day,
        month: draft.visual.month,
        year: draft.visual.year,
        hour: draft.visual.hour,
        minute: draft.visual.minute,
        meridiem: draft.meridiem,
      })

      draft.date = date
      draft.value = date?.toISO()

      if (draft.date?.isValid) {
        draft.year = date.year
        draft.month = date.month
        draft.day = date.day

        draft.hour = date.hour
        draft.minute = date.minute
      }

      if (this.props.smartDescription) {
        draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
      }

      draft.isPristine = false
      draft.isDirty = true
    })

    this.setState({
      year: newState.year,
      month: newState.month,
      day: newState.day,
      hour: newState.hour,
      minute: newState.minute,
      meridiem: newState.meridiem,
      visual: newState.visual,
      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      smartValue: newState.smartValue,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })
  }

  onPickerOpenUpdated = (isOpen: boolean) => {
    this.setState({ isPickerOpen: isOpen })
  }

  onPickerSelected = (date: any) => {
    if (!date) return

    const newState = produce(this.state, (draft: any) => {
      draft.date = date
      draft.value = draft.date.toISO()
      draft.allFieldsCompleted = true

      draft.year = date.year
      draft.month = date.month
      draft.day = date.day
      draft.hour = date.hour
      draft.minute = date.minute
      draft.meridiem = date.toFormat('a')

      if (this.props.smartDescription) {
        draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
      }

      draft.visual = this.getVisualStrings(draft)

      draft.isPristine = false
      draft.isDirty = true
    })

    this.setState({
      year: newState.year,
      month: newState.month,
      day: newState.day,
      minute: newState.minute,
      hour: newState.hour,
      meridiem: newState.meridiem,
      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      visual: newState.visual,
      smartValue: newState.smartValue,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })

    if (this.props.validateOn?.includes('blur')) this.queue({ type: 'VALIDATE', value: newState.value })

    // update Form Engine
    if (this.props.form && this.props.model) this.props.form.change(newState)
  }

  renderPicker = () => {
    return (
      <DatePicker
        withTime
        isInline={this.props.showPickerInline}
        date={this.state.date}
        timezone={this.props.timezone}
        isOpen={this.state.isPickerOpen}
        onOpenUpdated={this.onPickerOpenUpdated}
        minYear={MIN_YEAR}
        maxYear={MAX_YEAR}
        onSelect={this.onPickerSelected}
      />
    )
  }

  /*
    RENDER
  */
  editRender = () => {
    return (
      <>
        <Flex className="DateTimeInput" alignItems="center" gap={8}>
          <div>
            <Flex className="DateInput" alignItems="center" gap={2} nowrap>
              <BaseInput
                id="month"
                type="text"
                placeholder="MM"
                value={this.state.visual?.month}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
                size={2}
                maxLength={2}
              />
              <Text fontWeight={500} muted>
                –
              </Text>
              <BaseInput
                id="day"
                type="text"
                placeholder="DD"
                value={this.state.visual?.day}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
                size={2}
                maxLength={2}
              />
              <Text fontWeight={500} muted>
                –
              </Text>
              <BaseInput
                id="year"
                type="text"
                placeholder="YYYY"
                value={this.state.visual?.year}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
                size={4}
                maxLength={4}
              />
            </Flex>
          </div>

          <div>
            <Flex className="TimeInput" alignItems="center" gap={4} nowrap>
              <BaseInput
                id="hour"
                type="text"
                placeholder="HH"
                value={this.state.visual?.hour}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
                size={2}
                maxLength={2}
              />
              <Text fontWeight={500} muted>
                :
              </Text>
              <BaseInput
                id="minute"
                type="text"
                placeholder="MM"
                value={this.state.visual?.minute}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
                size={2}
                maxLength={2}
              />
              <Select id="meridiem" defaultValue="AM" value={this.state.meridiem} onChange={this.onChange}>
                <Option label="AM" value="AM" />
                <Option label="PM" value="PM" />
              </Select>

              {this.props.withDatePicker && !this.props.showPickerInline && this.renderPicker()}
            </Flex>
          </div>
        </Flex>

        {this.props.withDatePicker && this.props.showPickerInline && this.renderPicker()}
      </>
    )
  }

  readOnlyRender = () => <Value value={usDateTime(this.state.value, this.props.timezone)} />
}

DateTimeInput.defaultProps = {
  isEditable: true,
  withDatePicker: true,
  validateOn: 'blur-change',
}

export default withFormContext(DateTimeInput)
