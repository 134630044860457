import React from 'react'

import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Textarea from '../../Forms/Textarea'
import Tooltip from '../../Tooltip'
import Type from '../../Typography/Type'
import URLInput from '../../Forms/URLInput'

import Stepper from '../../Stepper/Stepper'

export const BusinessDescriptionFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Textarea
        label="Where did you first hear about Behave Health?"
        model="questionnaire.general_information.where_did_you_hear_about_behave_health"
      />

      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="general_info" />
        <Type as="h2" variant="H3">
          Business Description
        </Type>
      </Flex>

      <URLInput
        label="Your Website"
        model="questionnaire.business_description.website"
        description="If you do not have a business website yet, use a business profile you maintain on LinkedIn, Facebook or Google"
      />

      <Textarea
        label="Business Description"
        labelAfter={<Tooltip content="If you do not have a website to link to, please describe your business in the section above" />}
        model="questionnaire.business_description.description"
        validations={{
          length: {
            minimum: 10,
            tooShort: 'Please enter %{count} or more characters',
          },
        }}
      />
    </FormSection>
  )
}

export const BusinessDescription: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData, setValid }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData} onValidationUpdate={setValid}>
      <BusinessDescriptionFields />
    </Form>
  )
}
