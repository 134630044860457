import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { history } from '../setup/store'

class SentryTracking {
  constructor() {
    if (SentryTracking.instance instanceof SentryTracking) {
      return SentryTracking.instance
    }

    Object.freeze(this)
    SentryTracking.instance = this
  }

  init = (dsn, version) => {
    if (process.env.NODE_ENV === 'development') return null

    Sentry.init({
      dsn: dsn,
      release: version,
      environment: process.env.NODE_ENV,
      attachStacktrace: true,
      maxBreadcrumbs: 50,
      tracesSampleRate: 1.0,
      blacklistUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      integrations: [
        new Integrations.BrowserTracing({
          // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      beforeSend(event) {
        if (event?.exception?.values[0].mechanism?.type === 'onunhandledrejection') return null
        else return event
      },
      debug: false,
    })
  }

  identify = (params: any) => {
    if (process.env.NODE_ENV === 'development') return null
    Sentry.setUser(params)
  }

  client = () => Sentry
}

export default new SentryTracking()
