import { ICONS } from '../../theme'

import ClientNavItems from '../../components/Navs/ClientNavItems'
import AlumnusNavItems from '../../components/Navs/AlumnusNavItems'
import AdmissionNavItems from '../../components/Navs/AdmissionNavItems'
import EmployeeNavItems from '../../components/Navs/EmployeeNavItems'

export const RESOURCES: any = {
  resident: {
    current: {
      icon: ICONS.clients,
      label: 'Client',
      nav: ClientNavItems,
    },
    lead: {
      icon: ICONS.admissions,
      label: 'Applicant',
      nav: AdmissionNavItems,
    },
    intake: {
      icon: ICONS.admissions,
      label: 'Accepted Applicant',
      nav: AdmissionNavItems,
    },
    alumni: {
      icon: ICONS.alumni_relations,
      label: 'Alumni',
      nav: AlumnusNavItems,
    },
  },
  employee: {
    icon: ICONS.employees,
    label: 'Staff',
    nav: EmployeeNavItems,
  },
}

export const getResource = (type: string, status: string) => {
  return RESOURCES[type]?.[status] || RESOURCES[type] || {}
}
