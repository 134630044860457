import React from 'react'

import { withOverlayError } from '../../hocs/withOverlayError'

import Chotomate from '../../components/Chotomate'
import ContextHide from '../../components/Forms/ContextHide'
import DateInput from '../../components/Forms/DateInput'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Section from '../../components/Section'
import Switch from '../../components/Forms/Switch'
import Textarea from '../../components/Forms/Textarea'

import Diagnoses from '../../components/Overlays/pages/DataForms/common/Diagnoses'
import GoalsAndObjectives from '../../components/Overlays/pages/DataForms/common/GoalsAndObjectives'
import Problems from '../../components/Overlays/pages/DataForms/common/Problems'
import SNAP from '../../components/Overlays/pages/DataForms/common/SNAP'

import { DataFormOverlay } from '../DataFormOverlay'

const RootTreatmentPlanOverlayV1 = () => (
  <DataFormOverlay
    title="Treatment Plan"
    category="treatment_plan"
    subcategory="treatment_plan"
    version="1.0"
    icon="treatment_plans"
    helpID="treatment_plans"
    requestClientSignature={true}
    requestAuthorSignature={true}
    requireSupervisor={true}
  >
    {({ isEditable }: any) => (
      <>
        <Chotomate ready name="treatment_plan_overlay" />

        <Section
          scrollview={{
            id: 'appointments',
            name: 'Appointments',
          }}
          headingType="h2"
          title="Appointments"
          commentsModel="data.appointments"
        >
          <FormSection layout="vertical">
            <DateInput label="Review Date" model="data.appointments.review_date" />
            <Flex gap={8} stretchChildrenX>
              <Input label="Frequency of Appointments" model="data.appointments.frequency" />
              <Input label="Estimated Length of Treatment" model="data.appointments.estimated_length" />
            </Flex>
          </FormSection>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'problems',
            name: 'Problems',
          }}
          headingType="h2"
          title="Problems"
          commentsModel="data.problems_section"
        >
          <Problems isEditable={isEditable} />
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'goals_and_objectives',
            name: 'Goals & Objectives',
          }}
          headingType="h2"
          title="Goals & Objectives"
          commentsModel="data.goals_and_objectives"
        >
          <GoalsAndObjectives isEditable={isEditable} />
        </Section>

        <Divider />

        <Section
          title="Strengths, Needs, Abilities, Preferences"
          aside={<Switch horizontal inverse model="data.snap.not_applicable" />}
          scrollview={{
            id: 'snap',
            name: 'S.N.A.P.',
          }}
          commentsModel="data.snap"
        >
          <ContextHide when="data.snap.not_applicable" is={true}>
            <SNAP />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'diagnoses',
            name: 'Diagnoses',
          }}
          headingType="h2"
          title="Diagnoses"
          commentsModel="data.diagnosis_section"
        >
          <Diagnoses isEditable={isEditable} />
        </Section>

        <Divider />

        <Section
          title="Summary"
          aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
          scrollview={{
            id: 'summary',
            name: 'Summary',
          }}
          commentsModel="data.summary"
        >
          <ContextHide when="data.summary.not_applicable" is={true}>
            <FormSection layout="vertical" maxWidth="100%">
              <Textarea label="Interpretive Summary" model="data.summary.interpretive_summary" />
              <Textarea label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
              <Textarea label="Recommendations" model="data.summary.recommendations" />
            </FormSection>
          </ContextHide>
        </Section>
      </>
    )}
  </DataFormOverlay>
)

export const TreatmentPlanOverlayV1 = withOverlayError(RootTreatmentPlanOverlayV1)
