import React from 'react'

import Form from '../../components/Forms/Form'
import RichTextEditor from '../../components/Forms/RichTextEditor'

import { QuoteSection } from './QuoteSection'
import { QuoteIframeForm } from './QuoteIframeForm'
import { PreviewData } from './PreviewData'

export const QuoteSummaryForm = (props: any) => {
  const { isEditable, formData, onValidationUpdate, onUpdate } = props

  const form = React.useRef(null)

  return (
    <Form
      isCompact
      getForm={form}
      onUpdate={onUpdate}
      isEditable={isEditable}
      initialModel={formData}
      onValidationUpdate={onValidationUpdate}
    >
      <QuoteSection
        showVariables
        title="Summary"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={!!formData?.use_summary_section}
        enabledModel="use_summary_section"
      >
        <QuoteIframeForm model="summary" isEditable={isEditable} onUpdate={onUpdate} formData={formData} />
        <RichTextEditor label="Summary" model="summary_section_text" />

        <PreviewData isEditable={isEditable} model="summary_section_text" />
      </QuoteSection>
    </Form>
  )
}
