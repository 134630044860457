import React from 'react'

import Animated from './Animated'
import Overlay from './Overlay'
import Portal from './Portal'
import { Text } from './Typography'

import useIsMounted from '../hooks/useIsMounted'
import withPermissions from '../hocs/withPermissions'

const Iframe = (props: any) => {
  const {
    afterOpen,
    children,
    className,
    closeOnBackdrop,
    content,
    glyph,
    icon,
    message,
    overlayPosition = 'center',
    overlayWidth,
    processURL,
    show,
    title,
    url,
    stopPropagation,
    beforeClose,
    showBackdrop = true,
    headerAside,
  } = props

  const [isOpen, setIsOpen] = React.useState(show)
  const [isLoading, setIsLoading] = React.useState(false)
  const [uri, setURI] = React.useState(url)

  const isMounted = useIsMounted()

  const open = async (event) => {
    if (stopPropagation) {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
    }

    if (processURL && !isLoading) {
      setIsLoading(true)

      const processedURL = await processURL()
      setURI(processedURL)
      setIsLoading(false)
    }

    setIsOpen(true)

    if (afterOpen) afterOpen()
  }

  const close = async () => {
    if (beforeClose) await beforeClose()

    if (!isMounted) return
    setIsOpen(false)
  }

  return (
    <>
      <div onClick={open} className={className}>
        {children}
      </div>

      <Portal type="iframe">
        <Animated show={isOpen} animation="animation">
          <Overlay
            fullheight
            stopPropagation
            showBackdrop={showBackdrop}
            closeOnBackdrop={closeOnBackdrop}
            position={overlayPosition}
            onClose={close}
            maxWidth={overlayWidth}
          >
            <Overlay.Header title={title} glyph={glyph} icon={icon} aside={headerAside} />

            <Overlay.Content style={{ position: 'relative', padding: '1rem', overflow: 'hidden' }}>
              {message && <Text description={message} />}
              {uri && (
                <iframe
                  title="iframe"
                  src={uri}
                  frameBorder="0"
                  border="0"
                  cellSpacing="0"
                  css={{
                    borderStyle: 'none',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                />
              )}
              {content}
            </Overlay.Content>
          </Overlay>
        </Animated>
      </Portal>
    </>
  )
}

Iframe.defaultProps = {
  title: 'Title',
  overlayWidth: 50,
  closeOnBackdrop: false,
  show: false,
  stopPropagation: true,
}

export default withPermissions(Iframe)
