import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import produce from 'immer'

import { apiGet } from '../../../../../modules/api'
import { mapToArray, arrayToMapWithKeyPrefix } from '../../../../../utils/functions'
import { TabsContext } from '../../../../../hocs/withTabsContext'

import Button from '../../../../Button'
import Card from '../../../../Card'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import Form from '../../../../Forms/Form'
import FormSection from '../../../../Forms/FormSection'
import State from '../../../../State'
import Status from '../../../../Status'

import DiagnosisAccordion from '../components/DiagnosisAccordion'
import Footer from '../components/Footer'
import Main from '../components/Main'

import { icd10ToKey, keyToID } from './utils'

const setInitialPageData = (wileyData: any) => {
  const res: any = {}

  for (const icd10 of Object.keys(wileyData)) {
    res[icd10ToKey(icd10)] = {}
  }

  return res
}

const Goals = ({ formData, setFormData, wileyData, setWileyData }: any) => {
  const { onSelect: selectTab }: any = React.useContext(TabsContext)

  const form: any = React.useRef()

  const initialPageData = setInitialPageData(wileyData)
  const [pageData, setPageData] = React.useState(initialPageData)

  const updatePageData = (icd10: any, data: any) => {
    setPageData(
      produce((draft: any) => {
        draft[icd10] = data
      }),
    )
  }

  const updateWileyData = () => {
    const icd10Keys = Object.keys(wileyData)
    const { selected_diagnoses } = form.current?.getFormValue()

    let newWileyData = cloneDeep(wileyData)

    for (const icd10 of icd10Keys) {
      const { selected_goals } = selected_diagnoses[icd10]

      const newGoals: any = {}

      for (const goal_id in selected_goals) {
        // skip if goal is not selected
        if (!selected_goals[goal_id]) continue

        const id = keyToID(goal_id)
        const description = pageData[icd10][goal_id].description

        newGoals[goal_id] = {
          goal_id: id,
          description: description,
        }
      }

      // map selected goals to wiley data
      newWileyData = produce(newWileyData, (draft: any) => {
        draft[icd10].goals = newGoals
      })
    }

    // set goals for each diagnosis
    setWileyData(newWileyData)
  }

  const goNext = () => {
    updateWileyData()
    selectTab('objectives')
  }

  if (!formData) return null

  return (
    <>
      <Main>
        <Form useFullModel getForm={form} initialModel={formData} onUpdate={setFormData}>
          <FormSection maxWidth="100%">
            {mapToArray(wileyData).map((diagnosis: any) => (
              <GoalSection
                key={diagnosis.icd10}
                icd10={diagnosis.icd10}
                icd10Description={diagnosis.description}
                problemGroupID={diagnosis.problem_group_id}
                problemID={diagnosis.problem_id}
                problemGroupDescription={diagnosis.problem_group_description}
                problemDescription={diagnosis.problem_description}
                updatePageData={updatePageData}
              />
            ))}
          </FormSection>
        </Form>
      </Main>

      <Footer>
        <Button label="Continue →" type="primary" onClick={goNext} />
      </Footer>
    </>
  )
}

const GoalSection: React.FC<any> = (props) => {
  const { icd10, icd10Description, problemDescription, problemGroupDescription, problemGroupID, problemID, updatePageData } = props

  const [data, setData]: any = React.useState([])
  const [loading, setLoading] = React.useState(false)

  // Get Wiley Data
  React.useEffect(() => {
    if (!problemGroupID && !problemID) return

    const getAPIData = async () => {
      try {
        setLoading(true)

        const result = await apiGet({ url: `/wiley/goals?problem_group_id=${problemGroupID}&problem_id=${problemID}` })
        const data = result?.data?.data
        const pageData = arrayToMapWithKeyPrefix(data, 'goal_id', 'id_')

        setData(data)
        updatePageData(icd10ToKey(icd10), pageData)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getAPIData()
  }, [problemGroupID, problemID])

  if (!problemGroupID && !problemID) return null

  if (loading) {
    return (
      <Card>
        <State isLoading />
      </Card>
    )
  }

  return (
    <DiagnosisAccordion
      icd10={icd10}
      icd10Description={icd10Description}
      problemGroupDescription={problemGroupDescription}
      problemDescription={problemDescription}
    >
      <Status label="Goals:" css={styles.status} color="blue" />

      <CheckboxGroup layout="vertical-dense" withHover={false} css={styles.checkboxGroup}>
        {data.map((goal: any) => (
          <Checkbox
            key={goal.goal_id}
            label={goal.description}
            model={`selected_diagnoses.${icd10ToKey(icd10)}.selected_goals.id_${goal.goal_id}`}
          />
        ))}
      </CheckboxGroup>
    </DiagnosisAccordion>
  )
}

const styles = {
  checkboxGroup: {
    paddingBottom: '1rem',
  },

  status: {
    marginTop: '0.75rem',
    marginBottom: '0.75rem',
  },
}

export default Goals
