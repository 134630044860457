import React from 'react'
import clsx from 'clsx'

import PageSection from '../../../components/PageSection/PageSection'
import Icon from '../../../components/Icon'

export const ReferralColumn = (props: any) => {
  const { title, icon, children, headerAfter } = props

  const classNames = clsx(
    'p-4 h-full overflow-y-auto grow',
    props.asColumn && 'border-r border-0 border-solid border-divider',
    props.className,
  )

  return (
    <main className={classNames}>
      {title && (
        <PageSection>
          <PageSection.Header graphic={<Icon icon={icon} size={20} />} after={headerAfter}>
            <PageSection.Title title={title} />
          </PageSection.Header>
        </PageSection>
      )}

      {children}
    </main>
  )
}
