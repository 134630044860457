import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { usDateTime } from '../../../utils/functions'
import { useAPIwithInitialClear } from '../../../hooks/useAPI'
import withSettings from '../../../hocs/withSettings'

import Card from '../../../components/Card'
import FormSection from '../../../components/Forms/FormSection'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import Workflow from '../../../components/Workflow/Workflow'

import VOBSummaryOverlay from '../../../components/Overlays/pages/VOBSummaryOverlay'

const columns = (timezone: string) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'dated_at',
    Header: 'Date Added',
    Cell: ({ value, row }: any) => {
      return (
        <SummonOverlay overlay={<VOBSummaryOverlay showBackdrop dataID={row.original.id} initialData={row.original} />}>
          <TableCell.MainLink label={usDateTime(value, timezone)} />
        </SummonOverlay>
      )
    },
  },
  {
    width: 160,
    accessor: 'insurance_policy.insurance_global_payer.name',
    Header: 'Insurance Payer',
  },
  {
    width: 160,
    accessor: 'insurance_policy.plan',
    Header: 'Plan Name',
  },
]

const InsuranceFullVobs = ({ setStatus, timezone }: any) => {
  const match = useRouteMatch()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = match.params

  const data = useSelector((state: any) => state.data.insurance_vob_summaries?.data)
  const loading = useSelector((state: any) => state.data.insurance_vob_summaries?.loading)
  const isEmpty = size(data) === 0

  useAPIwithInitialClear('insurance_vob_summaries', `/residents/${resource_id}/insurance_vobs/summaries`, false)

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  if (isEmpty || loading) {
    return (
      <Card>
        <State isLoading={loading} isEmpty={isEmpty} title="Full VOBs" icon="financials" emptyDescription="No full VOBs added yet" />
      </Card>
    )
  }

  return (
    <FormSection maxWidth="100%">
      <Card>
        <Table icon="financials" title="Full VOB Summaries" showSettings={false} data={data} columns={columns(timezone)} />
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        <Workflow.SkipButton onClick={onSkip} />
      </Workflow.Buttons>
    </FormSection>
  )
}

export default withSettings(InsuranceFullVobs)
