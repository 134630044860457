import { success, error, abort } from '@redux-requests/core'

import produce from 'immer'
import set from 'lodash/set'
import unset from 'lodash/unset'
import omit from 'lodash/omit'

import { CLEAR_DATA, UPDATE_DATA } from '../actions/data'
import { arrayToMap } from '../utils/functions'

export const INITIAL_STATE = {}

export const reducer = (state = INITIAL_STATE, action) => {
  if (!action || !action.meta || action.meta.reducer !== 'data') return state

  const name = action.meta.name
  if (!name) return state

  const CONSTANT = action.meta.origin

  switch (action.type) {
    case UPDATE_DATA:
      return produce(state, (draft) => {
        set(draft, action.meta.key, action.data)
      })
    case CONSTANT:
      return produce(state, (draft) => {
        set(draft, `${name}.loading`, true)
      })
    case success(CONSTANT):
      const isDelete = action.meta.requestAction.request.method === 'delete'
      const isCreate = action.meta.requestAction.request.method === 'post'

      const newState = produce(state, (draft) => {
        // check if it should delete or not
        if (isDelete) {
          // if array remove multiple
          if (Array.isArray(action.response?.data?.deleted)) {
            draft[name].data = omit(draft[name].data, action.response?.data?.deleted)
          } else {
            // unset one key if not array
            unset(draft, `${name}.data.${action.response?.data.deleted}`)
          }
        } else if (Array.isArray(action.response?.data?.data)) {
          // process array
          set(draft, `${name}.data`, {
            ...draft[name]?.data,
            ...arrayToMap(action.response.data.data),
          })
        } else if (action.response?.data?.data?.id) {
          // process single entity (like update)
          set(draft, `${name}.data.${action.response?.data?.data?.id}`, action.response?.data?.data)
        } else {
          set(draft, `${name}.data`, action.response?.data?.data)
        }

        // add stats if they exist
        if (action.response?.data?.data?.stats) set(draft, `${name}.stats`, action.response?.data?.data?.stats)

        // handle the meta data local update
        if (draft[name]?.meta) {
          if (isDelete) {
            set(draft, `${name}.meta`, {
              ...draft[name].meta,
              count: draft[name].meta.count - 1,
            })
          } else if (isCreate) {
            set(draft, `${name}.meta`, {
              ...draft[name].meta,
              count: draft[name].meta.count + 1,
            })
          }
        }

        // update the meta data from the server if received
        if (action.response?.data?.meta) {
          set(draft, `${name}.meta`, action.response?.data?.meta)
        }

        set(draft, `${name}.loading`, false)
      })

      return newState
    case error(CONSTANT):
      return produce(state, (draft) => {
        set(draft, `${name}.loading`, false)
        set(draft, `${name}.error`, true)
      })
    case abort(CONSTANT):
      return produce(state, (draft) => {
        set(draft, `${name}.loading`, false)
        set(draft, `${name}.meta`, {})
      })
    case CLEAR_DATA:
      return produce(state, (draft) => {
        unset(draft, name)
      })
    default:
      return state
  }
}

export default reducer
