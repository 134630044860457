import React from 'react'

import Checkbox from '../Checkbox'
import CheckboxGroup from '../CheckboxGroup'
import ContextShow from '../ContextShow'
import Textarea from '../Textarea'

const GamblingTypesCheckboxGroup = ({ label, model }) => (
  <>
    <CheckboxGroup label={label} layout="vertical-dense">
      <Checkbox label="Bingo" model={`${model}.bingo`} />
      <Checkbox label="Cards" model={`${model}.cards`} />
      <Checkbox label="Casinos" model={`${model}.casinos`} />
      <Checkbox label="Internet" model={`${model}.internet`} />
      <Checkbox label="Lotteries" model={`${model}.lotteries`} />
      <Checkbox label="Scratch tickets" model={`${model}.scratch_tickets`} />
      <Checkbox label="Slots" model={`${model}.slots`} />
      <Checkbox label="VLT’s" model={`${model}.vlts`} />
      <Checkbox label="Other" model={`${model}.other`} />
    </CheckboxGroup>

    <ContextShow when={`${model}.other`} is={true}>
      <Textarea label="Please list the other types of gambling" model={`${model}_other`} />
    </ContextShow>
  </>
)

GamblingTypesCheckboxGroup.defaultProps = {
  model: 'gambling_types',
}

export default GamblingTypesCheckboxGroup
