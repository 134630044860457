import React from 'react'
import clsx from 'clsx'

import BHInput from '../../../../components/Forms/Input'
import BHPhoneInput from '../../../../components/Forms/PhoneInput'

import { getFormElementProps } from '../../utils/functions'

export const LandlineInput: React.FC<any> = (props) => {
  const { children, element, className, hoverElement, useParsedConfig, environment } = props

  if (!element) return null

  const rootClasses = clsx('LANDLINE_INPUT', className)

  const { model, ...formElementProps }: any = getFormElementProps(element, { useParsedConfig, environment })

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}
      <BHPhoneInput
        {...formElementProps}
        model={model}
        glyph="landline"
        layout="horizontal"
        after={<BHInput glyph="phone_ext" model={`${model}_ext`} placeholder="Ext" size={6} />}
      />
    </div>
  )
}
