import React from 'react'

import ResourceQuickLink from '../../QuickLinks/ResourceQuickLink'

const MESSAGES: any = {
  create: (props: any) => (
    <>
      {props.author} requested a new {props.record} for{' '}
      <ResourceQuickLink resource={props.event.recipient} disableLink={props.disableLink} />.
    </>
  ),
}

export default (props: any) => MESSAGES[props.event?.action_type]?.(props) || null
