import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'

import { Button, Card, CardContent, CardHeader, CardTitle, Icon } from '@behavehealth/components'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { MedicationDestructionLogsDataTable } from '@behavehealth/constructs/Medications/MedicationDestructionLogsDataTable'

type Props = {
  canCreate: boolean
}

const DestructionLogs: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const tableProps: any = useDataTable({
    name: ['client', resource_id, 'medication-destruction-logs'],
    endpoint: `/residents/${resource_id}/medication_destruction_logs`,
    updateDeleteEndpoint: '/medication_destruction_logs',
    localStorageKey: 'client_medication_destruction_logs_v1',
  })

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'medication-destruction-logs'],
    url: `/residents/${resource_id}/medication_destruction_logs`,
  })

  const { mutate: deleteRecords } = useDelete({
    name: ['client', resource_id, 'meds'],
    url: '/medication_destruction_logs',
    invalidate: 'medication-destruction-logs',
  })

  const isEmpty = size(data) === 0

  return (
    <Card>
      <CardHeader
        graphic={<Icon icon="medication_inventory" size={20} />}
        after={
          canCreate && (
            <Button
              glyph="add"
              type="primary"
              label="Log Destruction"
              link={{ pathname: `${match.url}/destruction-logs/new`, parent: match }}
              permission="medications.create"
              size={200}
            />
          )
        }
      >
        <CardTitle title="Medication Destruction Logs" />
      </CardHeader>

      <CardContent className="!p-0" showDivider={!isEmpty}>
        <MedicationDestructionLogsDataTable
          {...tableProps}
          to={(row) => ({
            pathname: `${match.url}/destruction-logs/${row?.id}`,
            parent: match,
          })}
          hiddenColumnIds={['resident']}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'medications.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </CardContent>
    </Card>
  )
}

export default DestructionLogs
