import React from 'react'
import size from 'lodash/size'

import { COLORS, SHADOW } from '../../../theme'
import { withFormContext } from '../../../components/Forms/context'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import Grid from '../../../components/Grid'
import GridTable from '../../../components/GridTable'
import AmountInput from '../../../components/Forms/AmountInput'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import Status from '../../../components/Status'
import DataArray from '../../../components/Forms/DataArray'

const columns = [
  {
    name: 'Service*',
    width: 'minmax(250px, 1fr)',
  },
  {
    name: 'Insurance Code*',
    width: 'minmax(250px, 1fr)',
  },
  {
    name: 'Price',
    width: 'minmax(180px, 1fr)',
  },
  {
    name: '',
    width: '50px',
    hideOnReadonly: true,
  },
]

const gridColumnsEdit = columns.map((column) => column.width).join(' ')
const readonlyColumns = [...columns].slice(0, -1)
const gridColumnsReadonly = readonlyColumns.map((column) => column.width).join(' ')

const FeeScheduleTable = ({ model = 'insurance_fee_schedule_services', isEditable, className, isValid = false }: any) => (
  <DataArray isNested model={model}>
    {({ orderedIds, elements, add, remove }: any) => {
      const isEmpty = size(orderedIds) === 0

      return (
        <Card className={className}>
          <CardHeader
            after={
              isEditable &&
              isValid && <Button label="Add Service" glyph="add" type="minimal" display="inline-flex" size={200} onClick={add} />
            }
          >
            <CardTitle title="Services" css={styles.cardTitle} />
          </CardHeader>

          <CardContent padding="0" css={styles.cardContent}>
            <GridTable templateColumns={isEditable ? gridColumnsEdit : gridColumnsReadonly}>
              <GridTable.Header>
                {columns?.map((column) => {
                  if (!isEditable && column.hideOnReadonly) return null
                  return <GridTable.Cell key={column.name}>{column.name}</GridTable.Cell>
                })}
              </GridTable.Header>

              {isEmpty && (
                <div css={styles.emptyState}>
                  <div>No services added yet</div>
                </div>
              )}

              {orderedIds.map((id: string, idx: number) => {
                const item = elements[id]

                return (
                  <GridTable.Row key={id} css={styles.gridTableRow}>
                    <GridTable.Cell>
                      <ObjectSelector
                        label=""
                        icon="financials"
                        type="insurance_services"
                        model={`${id}.service`}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => <Status label={data.collection_type} color="gray" />}
                        validations={{
                          presence: {
                            message: 'Please select a Service',
                          },
                        }}
                      />
                    </GridTable.Cell>

                    <GridTable.Cell>
                      <ObjectSelector
                        label=""
                        icon="insurance"
                        type="insurance_codes"
                        isDisabled={!isValid}
                        model={`${id}.insurance_code`}
                        selectTitle={(data: any) => `${data.code}`}
                        selectDescription={(data: any) => (
                          <Grid gap="0.2rem">
                            <span>{data?.name}</span>
                            <Status label={`Rev: ${data.revenue_code || '–'}`} />
                          </Grid>
                        )}
                        validations={{
                          presence: {
                            message: 'Please select an Insurance Code',
                          },
                        }}
                      />
                    </GridTable.Cell>

                    <GridTable.Cell>
                      <AmountInput prefix="$" model={`${id}.price`} defaultValue={0} />
                    </GridTable.Cell>

                    {isEditable && (
                      <GridTable.Cell>
                        <Button hideLabel glyph="delete" type="minimal" color="red" onClick={() => remove(id)} />
                      </GridTable.Cell>
                    )}
                  </GridTable.Row>
                )
              })}
            </GridTable>
          </CardContent>
        </Card>
      )
    }}
  </DataArray>
)

const styles = {
  emptyState: {
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
    padding: '1rem',
    color: COLORS.textMuted,
    opacity: 0.8,
  },

  cardTitle: {
    fontSize: '1rem',
  },

  cardContent: {
    height: '100%',
  },

  gridTableRow: {
    '&:last-of-type': {
      borderBottom: `1px solid ${COLORS.divider}`,
    },
  },

  draggable: {
    left: 'auto !important',
    right: '0 !important',

    '&.is-dragging': {
      borderRadius: 4,
      background: COLORS.white,
      boxShadow: SHADOW(3, COLORS.divider),
    },
  },

  moveGlyphCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      svg: {
        opacity: 1,
        fill: COLORS.blue,
      },
    },
  },

  moveGlyph: {
    opacity: 0.7,
    cursor: 'drag',
  },
}

export default withFormContext(FeeScheduleTable)
