import React from 'react'

export default class FramesPerSecond extends React.Component {
  constructor(props) {
    super(props)

    const currentTime = new Date().getTime()

    this.state = {
      frames: 0,
      startTime: currentTime,
      prevTime: currentTime,
      fps: 0,
    }

    this.unmounted = null
  }

  shouldComponentUpdate = (_nextProps, nextState) => this.state.fps !== nextState.fps

  componentDidMount = () => {
    let that = this

    let onRequestAnimationFrame = () => {
      that.calcFPS()
      window.requestAnimationFrame(onRequestAnimationFrame)
    }

    window.requestAnimationFrame(onRequestAnimationFrame)
  }

  componentWillUnmount = () => (this.unmounted = true)

  calcFPS = () => {
    if (this.unmounted) return

    let currentTime = new Date().getTime()

    this.setState({
      frames: this.state.frames + 1,
    })

    if (currentTime > this.state.prevTime + 1000) {
      let fps = Math.round((this.state.frames * 1000) / (currentTime - this.state.prevTime))

      this.setState({
        fps: fps,
        frames: 0,
        prevTime: currentTime,
      })
    }
  }

  render = () => (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div css={{ width: '45px' }}>{this.state.fps} FPS</div>
    </div>
  )
}
