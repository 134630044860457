import React from 'react'
import { useMedia } from 'use-media'
import produce from 'immer'

import { getPrefix, useUpdate } from '../../hooks/useNewAPI'
import withSettings from '../../hocs/withSettings'

import PayerNetworkStatus from '../Statuses/PayerNetworkStatus'
import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'
import PayerEnrollmentStatus from '../Statuses/PayerEnrollmentStatus'

import { CLEARING_HOUSES } from '../../utils/constants'
import { address } from '../../utils/functions'

const mainColumn = (to: any) => ({
  width: 320,
  isSticky: true,
  canToggleVisible: false,
  accessor: 'name',
  Header: 'Name',
  Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} avatar={row.original.avatar} />,
})

const tagsColumn = (apiKey: any) => ({
  width: 200,
  Header: 'Tags',
  accessor: 'tags',
  disableFilters: true,
  Cell: ({ value, row }: any) => {
    const { mutateAsync, isLoading }: any = useUpdate({
      name: ['insurance-local-payer', row.original.id],
      url: `/insurance_local_payers/${row.original.id}`,
      invalidateKeys: ['insurance_local_payers', 'tag-live-reports'],
      onSuccess: (_data: any, variables: any, queryClient: any) => {
        const prefix = getPrefix()

        queryClient.setQueryData([prefix, apiKey].flat(), (oldData: any) => {
          if (!oldData?.data) return

          const index = oldData.data.findIndex((o) => o.id === row.original.id)
          if (index === -1) return

          const newData = produce(oldData, (draft: any) => {
            draft.data[index].tags = variables.tags
          })

          return newData
        })
      },
    })

    return (
      <TableCell.Tags
        value={value}
        save={(tagIDs: any, tags: any) => mutateAsync({ tag_ids: tagIDs, tags })}
        isLoading={isLoading}
        smartCategories="insurance_payers"
      />
    )
  },
})

const columns = (to: Function = () => {}, isDesktop: boolean, apiKey: string) => [
  ...(isDesktop ? [mainColumn(to)] : []),
  // {
  //   width: isDesktop ? 160 : 240,
  //   disableFilters: true,
  //   id: 'quick_view',
  //   accessor: 'id',
  //   Header: 'Quick View',
  //   Cell: ({ row }: any) => (
  //     <TableCell.QuickViewOverlay overlay={<PayerQuickView payer={row.original} />}>
  //       {!isDesktop && <TableCell.MainLink label={row.original.name} avatar={row.original.avatar} glyphAfter="quick_view" />}
  //     </TableCell.QuickViewOverlay>
  //   ),
  // },
  ...(apiKey ? [tagsColumn(apiKey)] : []),
  {
    width: 100,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <Status label={value === 'active' ? 'Active' : 'Archived'} color={value === 'active' ? 'green' : 'grey'} />,
  },
  {
    width: 160,
    accessor: 'network_status',
    Header: 'Network Status',
    Cell: ({ value }: any) => <PayerNetworkStatus status={value} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'in_network',
        label: 'In Network',
      },
      {
        value: 'out_of_network',
        label: 'Out of Network',
      },
    ],
  },
  {
    width: 160,
    accessor: 'enrolled_status',
    Header: 'Enrollment Status',
    Cell: ({ value }: any) => {
      return <PayerEnrollmentStatus status={value} />
    },
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'is_enrolled',
        label: 'Enrolled',
      },
      {
        value: 'not_enrolled',
        label: 'Not Enrolled',
      },
    ],
  },
  {
    width: 150,
    accessor: 'insurance_clearing_house_config.states_covered',
    Header: 'States Covered',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell.Empty />
      if (value.length >= 50) return <TableCell value="National" />

      return <TableCell value={value?.join(', ') || '–'} />
    },
  },
  {
    width: 240,
    accessor: 'insurance_clearing_house_config.clearing_house',
    Header: 'Clearing House',
    Cell: ({ value }: any) => <TableCell value={CLEARING_HOUSES[value] || '–'} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'Clearing House 1 (CH)', value: 'change_health' },
      { label: 'Clearing House 2 (PV)', value: 'pverify' },
    ],
  },
  {
    width: 110,
    id: 'payer_id',
    accessor: 'insurance_clearing_house_config.payer_id',
    Header: 'Payer ID',
  },
  {
    width: 150,
    id: 'eligibility',
    accessor: 'supports.eligibility',
    Header: 'eVOBs',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />,
  },
  {
    width: 150,
    id: 'claims',
    accessor: 'supports.claims',
    Header: 'Claims',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />,
  },
  {
    width: 150,
    id: 'secondary_claims',
    accessor: 'supports.secondary_claims',
    Header: 'Secondary Claims',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />,
  },
  {
    width: 150,
    id: 'claim_status',
    accessor: 'supports.claim_status',
    Header: 'Claim Status',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />,
  },
  {
    width: 150,
    id: 'remittance',
    accessor: 'supports.remittance',
    Header: 'Remittance',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />,
  },
  {
    width: 150,
    id: 'attachments',
    accessor: 'supports.attachments',
    Header: 'Attachments',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />,
  },
  {
    width: 220,
    id: 'address',
    accessor: 'address',
    Header: 'Claim Submission Address',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell.Empty />

      return address(value)
    },
  },
  {
    width: 160,
    id: 'phone_no',
    accessor: 'phone_no',
    Header: 'Main Phone Line',
    Cell: TableCell.Phone,
  },
]

const InsuranceLocalPayersTable = (props: any) => {
  const {
    apiKey,
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    isLoading,
    localStorageKey,
    timezone,
    title,
    titleAfter,
    to,
    testKey,
    ...rest
  } = props

  const isDesktop = useMedia({ minWidth: 600 })

  return (
    <Table
      testKey={testKey}
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, isDesktop, apiKey)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

InsuranceLocalPayersTable.defaultProps = {
  title: 'Payers',
  icon: 'insurance',
  emptyDescription: 'No payers added yet',
  localStorageKey: 'insurance_local_payers',
}

export default withSettings(InsuranceLocalPayersTable)
