import React from 'react'
import { Link, Route } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'

import { apiUpdate } from '@behavehealth/modules/api'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { ApplicationFormOverlay } from '@behavehealth/constructs/ApplicationForms/ApplicationFormOverlay'
import { ApplicationFormsDataTable } from '@behavehealth/constructs/ApplicationForms/ApplicationFormsDataTable'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'

import Accordion from '@behavehealth/components/Accordion'
import Button from '@behavehealth/components/Button'
import Checkbox from '@behavehealth/components/Forms/Checkbox'
import CheckboxGroup from '@behavehealth/components/Forms/CheckboxGroup'
import ContextShow from '@behavehealth/components/Forms/ContextShow'
import Form from '@behavehealth/components/Forms/Form'
import FormSection from '@behavehealth/components/Forms/FormSection'
import Grid from '@behavehealth/components/Grid'
import Input from '@behavehealth/components/Forms/Input'
import Textarea from '@behavehealth/components/Forms/Textarea'
import Page from '@behavehealth/components/Page'
import Select from '@behavehealth/components/Forms/Select'
import Option from '@behavehealth/components/Forms/Option'

const RootApplicationForms = () => {
	const tableProps = useDataTable({
		name: ['application_forms'],
		endpoint: `/application_forms`,
		localStorageKey: 'application_forms_v2'
	})

	const record = useSelector((state: any) => state.me?.tenant)

	return (
		<>
			<Page
				title="Application Forms"
				feature="custom_application_form"
				actions={<Button as={Link} label="Add Application Form" glyph="add" type="primary" link="new" />}
			>
				<Grid gap="1rem">
					<Accordion
						isOpen={true}
						activateEditMode
						initialModel={record}
						title="Settings"
						description="Set the company's preference for Application settings"
						permission="settings.company_profile.account_owner.view"
						editPermission="settings.company_profile.account_owner.edit"
						onSubmit={(data) =>
							apiUpdate({
								name: 'tenant',
								url: '/me/tenant',
								params: { system_prefs: Object.assign({}, record.system_prefs, data.system_prefs) },
								reducer: 'me'
							})
						}
					>
						<Form>
							<FormSection layout="horizontal" labelWidth={150}>
								<CheckboxGroup
									label="Block Domains"
									layout="vertical-dense"
									trueIcon="check"
									falseIcon="cross"
									falseStyle="faded"
								>
									<Checkbox label="Activate Domain Blocking" model="system_prefs.block_email_domains" />
								</CheckboxGroup>

								<ContextShow when="system_prefs.block_email_domains" is={true}>
									<Input
										model="system_prefs.blocked_email_domains"
										type="email"
										label="Domains to Block"
										description="Enumerate the domains you would like to block, separated by a comma (,)"
									/>
								</ContextShow>

								<CheckboxGroup
									label="Auto-Decline Applications"
									layout="vertical-dense"
									trueIcon="check"
									falseIcon="cross"
									falseStyle="faded"
									description="Automatically decline Applicants after a certain period of time, unless they move to Accepted."
								>
									<Checkbox label="Activate Auto-Decline" model="system_prefs.auto_decline_applications" />
								</CheckboxGroup>

								<ContextShow when="system_prefs.auto_decline_applications" is={true}>
									<Select label="Decline Applicant After" model="system_prefs.auto_decline_applications_after">
										<Option label="7 Days" value="7_days" />
										<Option label="14 Days" value="14_days" />
										<Option label="21 Days" value="21_days" />
										<Option label="30 Days" value="30_days" />
										<Option label="60 Days" value="60_days" />
									</Select>

									<Textarea
										model="system_prefs.auto_decline_message"
										label="Auto-Decline Message"
										description="This message will be sent to applicants when their application is auto-declined."
									/>
								</ContextShow>
							</FormSection>
						</Form>
					</Accordion>

					<ApplicationFormsDataTable {...tableProps} />
				</Grid>
			</Page>

			<AnimatedRoutes>
				<Route path=":id" element={<ApplicationFormOverlay useV6Router />} />
			</AnimatedRoutes>
		</>
	)
}

export const ApplicationForms = withPageError(RootApplicationForms)
