import React from 'react'

import Button from '../Button'
import Iframe from '../Iframe'

import { apiGet, apiCreate } from '../../modules/api'
import { URL } from '../../utils/globals'

const ERXButton = ({ rcopiaID, rcopiaExternalID }: any) => {
  const [loading, setLoading] = React.useState(false)

  const getSSOURL = async () => {
    let params = {
      rcopia_user_external_id: 'ext_doc_123',
      startup_screen: 'patient',
      rcopia_patient_id: rcopiaID,
    }

    try {
      setLoading(true)
      const result = await apiGet({ url: `${URL.api}/apps/rcopia/sso`, params: params })
      setLoading(false)
      return result.data.data
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const syncPatient = () => {
    try {
      apiCreate({
        url: `${URL.api}/apps/rcopia/async_patient`,
        params: { external_id: rcopiaExternalID },
        notify: false,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Iframe overlayWidth={180} title="e-Prescribing Portal" processURL={getSSOURL} beforeClose={syncPatient}>
      <Button
        isLoading={loading}
        label="Open e-Prescribing Portal"
        display="inline-flex"
        glyph="pill_bottle"
        color="red"
        featureFlagV2="erx"
      />
    </Iframe>
  )
}

export default ERXButton
