import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Button, HelpTagIframe, PageHeader } from '@behavehealth/components'
import { ClearSampleDataBanner } from '../../components/ClearSampleDataBanner'
import { ClientsDataTable } from '@behavehealth/constructs/Clients/ClientsDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'

const ClientsPage: React.FC = () => {
  const match = useRouteMatch()
  const { tenant } = useSettings()

  const isSL = tenant?.category === 'support_facility'

  const actions = isSL && <Button type="primary" glyph="add" label="Add Client" link={`${match.url}/new`} />

  return (
    <div css={STYLES.root}>
      <PageHeader showFeedbackTool icon="clients" title="Current Clients" aside={actions} help={<HelpTagIframe id="clients" />} />

      <main css={STYLES.main}>
        {tenant?.has_seed_data && <ClearSampleDataBanner isFullWidth />}
        <ClientsDataTable testKey="clients_data_table" />
      </main>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'min-content minmax(100px, 1fr)',
    overflow: 'hidden',
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
}

export default withPageError(ClientsPage)
