import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../../theme'
import Chart from '../Chart'

export const NotificationsChannelsChart = (props: any) => {
  const days: any = Array.from(new Set([...props.notification_sms.x, ...props.notification_emails.x]))
  const series: any = [
    {
      name: 'Email Notifications',
      type: 'bar',
      stack: 'total',
      color: COLORS.paleBlue,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: props.notification_emails.y,
    },
    {
      name: 'SMS Notifications',
      type: 'bar',
      stack: 'total',
      color: COLORS.gold,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: props.notification_sms.y,
    },
  ]

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: ['Email Notifications', 'SMS Notifications'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: days,
    },
    yAxis: {
      type: 'value',
    },
    series,
  }

  return <Chart option={option} {...props} />
}
