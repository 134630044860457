import React from 'react'
import { useLocation } from 'react-router-dom'

import useActivePath from '../../hooks/useActivePath'
import { amount, titleCase } from '../../utils/functions'

import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'
import Status from '../Status'
import { Text } from '../Typography'

type Props = {
  location: any
  to: any
  transaction: any
}

const CashTransaction = ({ transaction, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  let color = ''
  let sign = ''

  color = transaction.category === 'fund' ? 'green' : 'red'
  sign = transaction.category === 'fund' ? '+' : '-'

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={`${sign}${amount(transaction.value, true)}`} />
      <CardMeta>
        <Status color={color} label={titleCase(transaction.category)} />
        <Text glyph="date" description={transaction.date} />
      </CardMeta>
      <CardSubtitle subtitle={transaction.notes} />
    </CardLink>
  )
}

export default CashTransaction
