import React from 'react'
import { tint } from 'polished'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'

import { SHADOW, COLORS } from '../theme'
import { usePortal } from '../hooks/usePortal'

import Glyph from './Glyph'
import Icon from './Icon'

export const DropdownMenu: React.FC<any> = (props: any) => {
  const { defaultOpen, children, trigger, className, side, align, isDisabled, portal = 'radix' } = props

  const [isOpen, setIsOpen] = React.useState(false)

  // TODO: handle portal containers differently to avoid zIndex issues
  const portalContainer = usePortal(portal)

  const isControlled = !isUndefined(props.isOpen) && !isUndefined(props.onOpenChange)

  const { current: collisionBoundary } = React.useRef([document.getElementById('portals'), document.getElementById('app'), document.body])

  return (
    <Dropdown.Root
      defaultOpen={defaultOpen}
      open={isControlled ? props.isOpen : isOpen}
      onOpenChange={isControlled ? props.onOpenChange : setIsOpen}
    >
      {isDisabled ? trigger : <Dropdown.Trigger asChild>{trigger}</Dropdown.Trigger>}

      <Dropdown.Portal container={portalContainer}>
        <Dropdown.Content
          asChild
          avoidCollisions
          collisionPadding={20}
          side={side}
          align={align}
          collisionBoundary={collisionBoundary}
          onInteractOutside={() => (isControlled ? props.onOpenChange(false) : setIsOpen(false))}
        >
          <div css={STYLES.content} className={className}>
            {children}
          </div>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  )
}

export const DropdownMenuItem: React.FC<any> = (props) => {
  const { glyph, label, onClick, isActive, glyphColor, before, description, className, children, icon } = props

  const rootClasses = clsx(isActive && 'is-active', className)

  return (
    <Dropdown.Item asChild>
      <div onClick={onClick} className={rootClasses} css={STYLES.menuItem}>
        {before}
        {children}
        {icon && <Icon icon={icon} size={18} />}
        {glyph && <Glyph glyph={glyph} size={18} color={glyphColor} />}
        {(label || description) && (
          <div>
            {label}
            {description && <div className="text-[0.85rem] text-text-muted font-[400] max-w-[400px] truncate">{description}</div>}
          </div>
        )}
      </div>
    </Dropdown.Item>
  )
}

export const DropdownMenuSub: React.FC<any> = (props) => {
  const { glyph, label, glyphColor } = props

  const portalContainer = usePortal('radix')

  return (
    <Dropdown.Sub>
      <Dropdown.SubTrigger asChild>
        <div css={STYLES.menuItem}>
          {glyph && <Glyph glyph={glyph} size={18} color={glyphColor} />}
          {label}
          <Glyph glyph={'chevron'} size={12} color={COLORS.gray} css={STYLES.chevron} />
        </div>
      </Dropdown.SubTrigger>

      <Dropdown.Portal container={portalContainer}>
        <Dropdown.SubContent css={STYLES.content}>{props.children}</Dropdown.SubContent>
      </Dropdown.Portal>
    </Dropdown.Sub>
  )
}

const STYLES = {
  content: {
    '& > hr': {
      margin: '0 !important',
    },

    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',

    borderRadius: 7,
    background: 'white',
    boxShadow: SHADOW(10, COLORS.divider),

    transformOrigin: 'center top',
    outline: `0 !important`,

    maxHeight: 'var(--radix-dropdown-menu-content-available-height, 50vh)',
    minWidth: '220px',
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '34px',
    padding: '0.1rem 0.6rem',
    fontSize: '0.92rem',
    fontWeight: 600,
    outline: 'none',

    svg: {
      marginRight: '0.4rem',
    },

    '&:hover, &[data-state=open]': {
      background: COLORS.hover,
    },

    '&.is-active': {
      background: tint(0.9, COLORS.paleBlue),
    },
  },

  chevron: {
    marginLeft: 'auto',
    opacity: 0.5,
  },
}
