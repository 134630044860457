// TODO: This is a basic implementation to be completed

import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import pluralize from 'pluralize'
import capitalize from 'lodash/capitalize'

import { isDefined } from '../../utils/functions'
import { withFormContext } from './context'

import Value from '../Value'
import Flex from '../Flex'
import Glyph from '../Glyph'
import FieldBase from './FieldBase'

import { COLORS, INPUT_STYLES } from '../../theme'

class Stepper extends FieldBase {
  constructor(props) {
    super(props)

    let value = props.value
    if (props.form) {
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)
      if (isDefined(initialModelVal)) value = initialModelVal
    }

    this.state = {
      type: 'STEPPER',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      label: props.label,
      value: value,
      isDisabled: props.isDisabled,
      isValidations: null,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValid: true,
      isInvalid: false,
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value,
      isValid: true,
      isInvalid: false,
    }

    this.updateType = 'DATA'
  }

  capByRange = (value) => {
    const { min, max } = this.props
    let result = value

    if (value > max) result = max
    if (value < min) result = min

    return result
  }

  increase = () => {
    const { value } = this.state
    const modifiedValue = this.capByRange(value + this.props.valueModifier)

    if (modifiedValue !== value) this.changeValue(modifiedValue)
  }

  decrease = () => {
    const { value } = this.state
    const modifiedValue = this.capByRange(value - this.props.valueModifier)

    if (modifiedValue !== value) this.changeValue(modifiedValue)
  }

  get value() {
    const { noun } = this.props
    const { value } = this.state

    return noun ? `${value} ${capitalize(pluralize(noun, value))}` : value
  }

  editRender = () => {
    const classNames = clsx({
      'is-disabled': this.props.isDisabled,
    })

    return (
      <Flex css={styles.root()} className={classNames}>
        <button type="button" css={styles.button(this.props)} onClick={this.decrease} data-testid="decrease">
          <Glyph glyph={this.props.decreaseGlyph} size={14} />
        </button>
        <Flex css={styles.value(this.props)} vertical justifyContent="center" data-testid="value">
          {this.value}
        </Flex>
        <button type="button" css={styles.button(this.props)} onClick={this.increase} data-testid="increase">
          <Glyph glyph={this.props.increaseGlyph} size={14} />
        </button>
      </Flex>
    )
  }

  readOnlyRender = () => {
    return <Value value={this.props.label} description={this.props.description} />
  }
}

Stepper.defaultProps = {
  value: 0,
  valueModifier: 1,
  min: 0,
  max: 100,
  color: COLORS.blue,
  increaseGlyph: 'add',
  decreaseGlyph: 'subtract',
  showValue: false,
  isEditable: true,
  isDisabled: false,
}

const styles = {
  root: () => {
    return {
      ...INPUT_STYLES,
      padding: 2,
      minWidth: 100,
      width: '100%',
      userSelect: 'none',
      background: COLORS.white,

      '&.is-disabled': {
        cursor: 'inherit',
        pointerEvents: 'none',
      },
    }
  },

  button: ({ color }) => {
    return {
      outline: 0,
      backgroundColor: COLORS.white,
      border: 0,
      width: '10%',
      minWidth: 34,
      maxWidth: 40,
      padding: 0,
      borderRadius: 3,
      cursor: 'pointer',
      transition: 'background-color .1s',
      pointerEvents: 'auto',

      svg: {
        transition: 'fill .1s',
        fill: color,
      },

      '.is-disabled &': {
        svg: {
          fill: COLORS.gray,
        },
      },

      '&:hover': {
        backgroundColor: color,
        svg: {
          fill: COLORS.white,
        },

        '.is-disabled &': {
          backgroundColor: COLORS.white,

          svg: {
            fill: COLORS.gray,
          },
        },
      },
    }
  },

  value: () => {
    return {
      flexGrow: 1,
      textAlign: 'center',
    }
  },
}

export default withFormContext(Stepper)
