import React from 'react'

import Icon from '../../Icon'
import { DEFAULT_EMPTY_VALUE } from '../../../utils/constants'

const IconValue: React.FC = ({ icon, value, className }) => {
  if (!value) return DEFAULT_EMPTY_VALUE

  return (
    <span css={{ display: 'inline-flex', alignItems: 'center' }} className={className}>
      {icon && <Icon icon={icon} size="1rem" className="!mr-2" />}
      <span>{value}</span>
    </span>
  )
}

export default IconValue
