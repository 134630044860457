import React from 'react'

import { MEDIA_QUERY, COLORS } from '../theme'
import { DEFAULT_EMPTY_VALUE } from '../utils/constants'

type Props = {
  title: string
  description: string
}

const DescriptionItem: React.FC<Props> = ({ title, description, testKey }) => (
  <div css={styles}>
    <dt>{title}</dt>
    <dd data-test={testKey}>{description}</dd>
  </div>
)

const styles: any = {
  padding: '0.5em 0',
  borderBottom: `1px solid ${COLORS.divider}`,

  '&:last-child, &:only-child': {
    borderBottom: 'none',
  },

  dt: {
    flex: '0 0 160px',
    fontWeight: 700,
    paddingRight: '0.5em',
  },

  dd: {
    flex: '1 1 auto',
    margin: 0,
    whiteSpace: 'pre-line',
  },

  [MEDIA_QUERY[1]]: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}

DescriptionItem.defaultProps = {
  description: DEFAULT_EMPTY_VALUE,
}

export default DescriptionItem
