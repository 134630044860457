import React from 'react'
import clsx from 'clsx'

import Header from '../Header'

type Props = {
  children: React.ReactNode
  className?: string
  testKey?: string
  help?: string
}

const OverlayHeader = ({ children, testKey, help, className, ...rest }: Props) => (
  <>
    <Header isSticky testKey={testKey} help={help} className={clsx(className, 'overlay-header')} {...rest}>
      {children}
    </Header>
  </>
)

export default OverlayHeader
