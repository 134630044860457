import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'

import { Page, Timeline, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

const ActivityTimeline = () => {
  const { resource_id } = useParams()

  return (
    <Page feature="activity_timeline" plan="lite" help={<HelpTagIframe id="activity_timeline" />}>
      <Timeline asRecipient recordType="program" recordID={resource_id} />
    </Page>
  )
}

export default withPageError(ActivityTimeline)
