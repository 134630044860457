import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../theme'

import Avatar from '../Avatar'
import Glyph from '../Glyph'
import Icon from '../Icon'
import CardActions from '../CardActions'

const RowHeader: React.FC<any> = ({
  actions,
  after,
  avatar,
  enableDropdown = true,
  enableMobileView,
  expandAsCard,
  graphic,
  hasSubrows,
  icon,
  isDisabled,
  isOpen,
  level,
  onClick,
  styles,
  subtitle,
  title,
  titleAfter,
  children,
}) => {
  const hasGraphic = avatar !== undefined || icon || graphic

  const classNames = clsx({
    [`level-${level}`]: level,
    'is-open': isOpen,
    'has-subrows': hasSubrows,
    'expand-as-card': expandAsCard,
    'mobile-view-enabled': enableMobileView,
  })

  return (
    <div css={{ ...STYLES.root, ...styles }} className={classNames}>
      {children || (
        <div css={STYLES.content} onClick={enableDropdown ? onClick : null}>
          {enableDropdown && <Glyph glyph="triangle_down" className="row-triangle" size={12} />}

          <div css={STYLES.subcontent}>
            <div css={STYLES.label} className={hasSubrows ? 'has-subrows' : ''}>
              {hasGraphic && (
                <>
                  <div css={STYLES.graphic} className={isDisabled ? 'is-disabled' : ''}>
                    {graphic}
                    {avatar !== undefined && <Avatar initials={title} size={28} />}
                    {icon && <Icon icon={icon} size={20} />}
                  </div>
                </>
              )}

              <div css={STYLES.text} className={isDisabled ? 'is-disabled' : ''}>
                <div css={STYLES.title} className={subtitle ? 'is-bolded' : ''}>
                  {title}
                </div>
                {subtitle && <div css={STYLES.subtitle}>{subtitle}</div>}
              </div>

              {titleAfter}
            </div>
          </div>

          {after}
        </div>
      )}

      {actions && <CardActions actions={actions} />}
    </div>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    borderLeft: `1px solid ${COLORS.divider}`,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: `
      1px 0 0 ${COLORS.divider},
      4px 0 0 ${COLORS.shadow}
    `,

    position: 'sticky',
    left: 0,
    background: COLORS.white,
    zIndex: 1,

    '.row-triangle': {
      visibility: 'hidden',
      marginRight: '0.5rem',
      transform: 'rotateZ(-90deg)',
    },

    '&.is-open.expand-as-card': {
      borderTop: `1px solid ${COLORS.divider}`,
    },

    '&.is-open .row-triangle': {
      transform: 'rotateZ(0)',
    },

    '&.has-subrows': {
      '.row-triangle': {
        visibility: 'visible',
      },
    },

    // wordBreak: 'break-all',
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flex: '1 1 auto',
    padding: '0.3rem',

    '.has-subrows &': {
      cursor: 'pointer',

      '&:hover': {
        background: COLORS.hover,
      },
    },

    '.level-1 &': { paddingLeft: '0.3rem' },
    '.level-2 &': { paddingLeft: '0.6rem' },
    '.level-3 &': { paddingLeft: '0.9rem' },
    '.level-4 &': { paddingLeft: '1.2rem' },
    '.level-5 &': { paddingLeft: '1.5rem' },
    '.level-6 &': { paddingLeft: '1.8rem' },
    '.level-7 &': { paddingLeft: '2.1rem' },

    '@media (min-width: 768px)': {
      '.level-1 &': { paddingLeft: '0.5rem' },
      '.level-2 &': { paddingLeft: '1.5rem' },
      '.level-3 &': { paddingLeft: '2.5rem' },
      '.level-4 &': { paddingLeft: '3.5rem' },
      '.level-5 &': { paddingLeft: '4.5rem' },
      '.level-6 &': { paddingLeft: '5.5rem' },
      '.level-7 &': { paddingLeft: '6.5rem' },
    },
  },

  subcontent: {
    display: 'grid',
    gridGap: '0.5rem',
  },

  label: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',

    '&.has-subrows': {
      fontWeight: 600,
    },
  },

  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '0.9rem',

    '@media (min-width: 768px)': {
      whiteSpace: 'normal',
    },

    '&.is-bolded': {
      fontWeight: 600,
    },
  },

  subtitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 400,
    fontSize: '0.84rem',
    whiteSpace: 'nowrap',
    color: COLORS.textMuted,
    marginTop: -2,

    '@media (min-width: 768px)': {
      whiteSpace: 'normal',
    },
  },

  text: {
    display: 'grid',

    '&.is-disabled': {
      opacity: 0.6,
    },
  },

  graphic: {
    marginRight: '0.5rem',

    '&.is-disabled': {
      filter: 'grayscale(0.8)',
      opacity: 0.6,
    },

    '.mobile-view-enabled &': {
      display: 'none',

      '@media (min-width: 768px)': {
        display: 'block',
      },
    },
  },
}

export default RowHeader
