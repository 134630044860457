import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import size from 'lodash/size'

import { css, COLORS, HARD_SHADOW } from '../../theme'
import { useCreate, useGet } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import BedOccupancyStatus from '../../components/Statuses/BedOccupancyStatus'
import Button from '../../components/Button'
import Card from '../../components/Card'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import State from '../../components/State'
import Status from '../../components/Status'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

import { FILTERS } from '../../constructs/Filters/config'

const RootSeatSelectorOverlay = (props: any) => {
  const { data, fromDate, fromLabel = 'From', onClose, setData, setFromDate, setUntilDate, untilDate, untilLabel = 'Until' } = props

  const { timezone, tenant } = useSettings()

  const allowOverbook = tenant?.system_prefs?.allow_overbook === true

  const [program, setProgram] = React.useState(data?.program || null)
  const [phase, setPhase] = React.useState(data?.phase || null)
  const [seat, setSeat] = React.useState(data || null)

  const [isDatesEditable, setIsDatesEditable] = React.useState(false)

  const programsTableProps: any = useDataTable({
    name: ['programs'],
    endpoint: `/programs`,
  })

  const { data: programs, isLoading: isProgramsLoading } = programsTableProps

  const { data: phases, isLoading: isPhasesLoading }: any = useGet({
    name: ['programs', program?.id, 'phases'],
    url: `/programs/${program?.id}/phases`,
    options: { enabled: !!program?.id },
  })

  const seatsParams = { from: fromDate, until: untilDate }

  const seatsQueryKey = ['programs', program?.id, 'phase', phase?.id, 'seats', seatsParams]

  const { data: seats, isLoading: isSeatsLoading }: any = useGet({
    name: seatsQueryKey,
    url: `/phases/${phase?.id}/seats`,
    params: seatsParams,
    options: { enabled: !!(program?.id && phase?.id) },
  })

  const { mutateAsync: batchCreateSeats, isLoading: isCreatingSeat }: any = useCreate({
    name: ['phase', phase?.id, 'bulk-create-seats'],
    url: `/phases/${phase?.id}/bulk_create_seats`,
    invalidate: seatsQueryKey,
    invalidateKeys: ['programs-timeline', 'program-timeline'],
  })

  const createSeat = () => {
    batchCreateSeats({ amount: 1 })
  }

  const handleSave = () => {
    setData({
      ...seat,
      program: program,
      phase: phase,
      seat: seat,
    })

    onClose()
  }

  React.useEffect(() => {
    if (!seats || !seat) return

    const currentBed = seats.find((o) => o.id === seat.id)

    // don't allow overbook
    if (!allowOverbook && currentBed.status === 'occupied') setSeat(null)
  }, [seats, seat])

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={onClose} maxWidth={100}>
      <Overlay.Header title="Select Seat" glyph="chair" />

      <Overlay.Content>
        <Section>
          <Grid gap="1rem">
            <div>
              <Flex stretchChildrenX={isDatesEditable} gap="1.2rem">
                <DateTimeInput
                  defaultToNow
                  isEditable={isDatesEditable}
                  label={fromLabel}
                  value={fromDate}
                  onUpdate={({ value }) => {
                    setFromDate?.(value)
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter a start date',
                    },
                  }}
                  timezone={timezone}
                />

                <DateTimeInput
                  defaultToEndOfToday
                  isEditable={isDatesEditable}
                  label={untilLabel}
                  value={untilDate}
                  onUpdate={({ value }: any) => {
                    setUntilDate?.(value)
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter an end date',
                    },
                    datetime: {
                      earliest: fromDate,
                      message: `Please enter a date after "${fromLabel}" date`,
                    },
                  }}
                  timezone={timezone}
                />
              </Flex>

              {!isDatesEditable && (
                <Button
                  label="Edit Dates"
                  glyph="edit"
                  size={200}
                  onClick={() => setIsDatesEditable(true)}
                  display="inline-flex"
                  className="!mt-3"
                />
              )}
            </div>

            <Alert small contrast glyph="info">
              Edit the dates above to find available beds within this time range.
            </Alert>

            <Card css={STYLES.card}>
              <ProgramsColumn
                activeId={program?.id}
                tableProps={programsTableProps}
                isLoading={isProgramsLoading}
                onSelect={(programValue: any) => {
                  if (programValue?.id === program?.id) return // clicked on the same program
                  setProgram(programValue)
                  setPhase(null)
                  setSeat(null)
                }}
              />

              <PhasesColumn
                activeId={phase?.id}
                programId={program?.id}
                data={phases}
                isLoading={isProgramsLoading || isPhasesLoading}
                onSelect={(phaseValue: any) => {
                  if (phaseValue === phase?.id) return // clicked on the same phase
                  setPhase(phaseValue)
                  setSeat(null)
                }}
              />

              <SeatsColumn
                allowOverbook={allowOverbook}
                activeId={seat?.id}
                phaseId={phase?.id}
                data={seats}
                isCreatingSeat={isCreatingSeat}
                createSeat={createSeat}
                isLoading={isProgramsLoading || isPhasesLoading || isSeatsLoading}
                onSelect={(seatValue: any) => {
                  if (seatValue?.id === seat?.id) return // clicked on the same seat
                  setSeat(seatValue)
                }}
              />
            </Card>
          </Grid>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Select Seat" color="green" type="primary" onClick={handleSave} isDisabled={!seat?.id} />
      </Overlay.Footer>
    </Overlay>
  )
}

const Column: React.FC<any> = (props) => {
  const { children, title, icon, glyph, headerAfter, className } = props

  return (
    <div css={STYLES.column} className={className}>
      {title && (
        <header css={STYLES.columnHeader}>
          {glyph && <Glyph glyph={glyph} size={20} css={STYLES.columnHeaderIcon} />}
          {icon && <Icon icon={icon} size={20} css={STYLES.columnHeaderIcon} />}
          {title}
          {headerAfter}
        </header>
      )}

      {children}
    </div>
  )
}

const ProgramsColumn: React.FC<any> = (props) => {
  const { activeId, tableProps, onSelect } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 200,
        disableHide: true,
        formatValue: ({ value, data }: any) => (
          <MainCell
            id={data.id}
            value={value}
            avatar={data.avatar}
            isActive={data.id === activeId}
            onClick={() => onSelect(data)}
            className="[&.is-active::before]:!bg-blue-500 [&.is-active]:!text-white [&.is-active:hover]:!text-blue-500"
          />
        ),
      },
      {
        title: 'Description',
        model: 'description',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [activeId, onSelect],
  )

  return (
    <Column title="Programs" icon="programs" className="h-full !overflow-hidden grid grid-cols-[100%] grid-rows-[auto_1fr]">
      <DataTable
        asCard={false}
        title="Programs"
        icon="programs"
        columns={columns}
        filtersConfig={FILTERS.programs}
        {...tableProps}
        className="overflow-hidden"
      />
    </Column>
  )
}

const PhasesColumn: React.FC<any> = (props) => {
  const { activeId, data, isLoading, onSelect, programId } = props

  const isEmpty = size(data) === 0

  if (!programId) {
    return (
      <Column title="Lists" icon="housing_shifts">
        <State isEmpty title="Lists" icon="housing_shifts" emptyDescription="Please select a program first" />
      </Column>
    )
  }

  if (isLoading || isEmpty) {
    return (
      <Column title="Lists" icon="housing_shifts">
        <State
          isLoading={isLoading}
          isEmpty={isEmpty}
          title="Lists"
          icon="housing_shifts"
          emptyDescription="No lists found in this program"
        />
      </Column>
    )
  }

  return (
    <Column title="Lists" icon="housing_shifts">
      {data.map((phase: any) => (
        <RowItem
          key={phase.id}
          title={phase.name}
          graphic={<Icon icon="housing_shifts" size={20} />}
          onClick={() => onSelect(phase)}
          isActive={phase.id === activeId}
        />
      ))}
    </Column>
  )
}

const SeatsColumn: React.FC<any> = (props) => {
  const { activeId, data, isLoading, onSelect, phaseId, allowOverbook, createSeat, isCreatingSeat } = props

  const isEmpty = size(data) === 0

  if (!phaseId) {
    return (
      <Column title="Seats" glyph="chair">
        <State isEmpty={isEmpty} title="Seats" glyph="chair" emptyDescription="Please select a list first" />
      </Column>
    )
  }

  if (isLoading || isEmpty || isCreatingSeat) {
    return (
      <Column title="Seats" glyph="chair">
        <State
          isLoading={isLoading || isCreatingSeat}
          isEmpty={isEmpty}
          title="Seats"
          glyph="chair"
          emptyDescription="No seats added to this list yet"
          emptyActions={<Button label="Add Seat" type="link" glyph="add" size={200} onClick={createSeat} />}
        />
      </Column>
    )
  }

  return (
    <Column
      title="Seats"
      glyph="chair"
      headerAfter={
        !isEmpty && (
          <Button
            label="Add Seat"
            glyph="add"
            type="link"
            size={200}
            onClick={createSeat}
            isLoading={isCreatingSeat}
            css={STYLES.headerButton}
          />
        )
      }
    >
      {data.map((seat: any) => (
        <RowItem
          key={seat.id}
          title={seat.name}
          aside={
            seat.status && (
              <div css={STYLES.statusesWrapper}>
                <Flex gap="0.4rem">
                  {allowOverbook && (seat.status === 'occupied' || seat.status === 'reserved') && (
                    <Status small label="Overbook" color="red" />
                  )}
                  <BedOccupancyStatus small status={seat.status} css={STYLES.occupancyStatus} />
                </Flex>
              </div>
            )
          }
          graphic={<Glyph glyph="chair" size={20} />}
          onClick={() => onSelect(seat)}
          isActive={seat.id === activeId}
          isDisabled={!allowOverbook && (seat.status === 'occupied' || seat.status === 'reserved')}
        />
      ))}
    </Column>
  )
}

const RowItem: React.FC<any> = (props) => {
  const { aside, title, subtitle, graphic, onClick, isActive, isDisabled, style } = props

  const rootClasses = clsx(isActive && 'is-active', isDisabled && 'is-disabled')

  return (
    <div css={STYLES.item} className={rootClasses} onClick={!isDisabled && onClick} style={style}>
      {graphic && <div css={STYLES.itemGraphic}>{graphic}</div>}

      <div>
        <div css={STYLES.itemTitle}>{title}</div>
        {subtitle && <div css={STYLES.itemSubtitle}>{subtitle}</div>}
      </div>

      {aside}
    </div>
  )
}

const RowHeader: React.FC<any> = (props) => {
  const { title, graphic } = props

  return (
    <div css={STYLES.rowHeader}>
      {graphic && <div css={STYLES.rowHeaderGraphic}>{graphic}</div>}
      <div css={STYLES.rowHeaderTitle}>{title}</div>
    </div>
  )
}

const STYLES = {
  card: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'minmax(200px, 2fr) minmax(200px, 1fr) minmax(200px, 1fr)',
    // gridAutoColumns: 'minmax(200px, 1fr)',
    overflow: 'auto !important',
    height: 450,
    fontSize: '0.9rem',
  },

  column: {
    overflowY: 'auto',
    borderRight: `1px solid ${COLORS.divider}`,

    '&:last-child': {
      borderRight: 'none',
    },
  },

  columnHeaderIcon: {
    marginRight: '0.4rem',
  },

  columnHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.4rem 0.75rem',
    fontWeight: 600,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background: COLORS.white,
  },

  rowHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.2rem 0.75rem',
    paddingTop: '0.3rem',
    background: COLORS.hover,
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  rowHeaderGraphic: {
    marginRight: '0.4rem',
  },

  rowHeaderTitle: {
    fontWeight: 600,
  },

  rowEmptyState: {
    fontWeight: 400,
    fontSize: '0.85rem',
    color: COLORS.textMuted,
    textAlign: 'center',
    padding: '0.75rem',
  },

  item: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0.25rem 0.75rem',
    cursor: 'pointer',
    borderBottom: `1px solid ${COLORS.divider}`,

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-active': {
      color: COLORS.white,
      background: COLORS.blue,
    },

    '&.is-disabled': {
      background: COLORS.hover,
      cursor: 'not-allowed',
      color: tint(0.1, COLORS.textMuted),

      img: {
        opacity: 0.5,
        filter: 'grayscale(100%)',
      },
    },
  },

  itemGraphic: {
    marginRight: '0.4rem',
  },

  itemTitle: {
    // fontWeight: 600,
  },

  itemSubtitle: {
    fontSize: '0.85rem',
    fontWeight: 400,
    color: COLORS.textMuted,
  },

  statusesWrapper: {
    marginLeft: 'auto',
  },

  occupancyStatus: {
    minWidth: 72,
    textAlign: 'center',
    justifyContent: 'center',
  },

  headerButton: {
    height: 20,
    minHeight: 20,
    marginLeft: 'auto',
  },
}

export const SeatSelectorOverlay = withOverlayError(RootSeatSelectorOverlay)
