import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '../../constructs/Filters/constants'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

const ACCOUNT_TYPES: any = {
  healthcare_facility: 'Healthcare Facility',
  healthcare_practice: 'Healthcare Practice',
  support_facility: 'Support Facility',
  support_practice: 'Support Practice',
  provider_affiliate: 'Provider Partner',
}

const SALES_STEP: any = {
  signed_up: 'Account just signed up',
  invited: 'Owner invited to create password',
  registered: 'Owner password created',
  profile_completed: 'Owner completed the Business Profile',
  activated: 'Trial/Account activated',
}

export const InternalEHRAccessDataTable = ({ onClick, href }: any) => {
  const tableProps = useDataTable({
    name: ['internal-access'],
    endpoint: '/me/access/behave',
    params: { app: 'ehr' },
    localStorageKey: 'internal_access_ehr_apps_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        // id: 'facility_name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            id={data.id}
            value={value}
            onClick={onClick ? () => onClick(data) : undefined}
            href={href ? href(data, 'ehr') : undefined}
          />
        ),
      },
      {
        title: 'Account Status',
        model: 'plan_status',
        id: 'facility_plan_status',
        type: 'title',
      },
      {
        title: 'Sales Status',
        model: 'sales_step',
        id: 'facility_review_status',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return SALES_STEP[value] || value
        },
      },
      {
        title: 'Account Type',
        model: 'category',
        id: 'facility_category',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return ACCOUNT_TYPES[value] || value
        },
      },
      {
        title: 'Subdomain',
        model: 'subdomain',
        id: 'tenant',
      },
      {
        title: 'Timezone',
        model: 'timezone',
        id: 'facility_timezone',
      },
      {
        title: 'State',
        model: 'state',
        id: 'facility_state',
      },
      {
        title: 'Hubspot Deal',
        model: 'hubspot_deal_id',
        id: 'facility_hubspot_deal_id',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal value={value} href={`https://app.hubspot.com/contacts/2828210/deal/${value}`} />
        },
      },
      {
        title: 'Stripe Customer',
        model: 'stripe_customer_id',
        id: 'facility_stripe_customer_id',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal value={value} href={`https://dashboard.stripe.com/customers/${value}`} />
        },
      },
      {
        title: 'Trial Ends On',
        model: 'trial_ended_at',
        id: 'facility_trial_ended_at',
        type: 'date_time',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        id: 'facility_created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        id: 'facility_updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable
      {...tableProps}
      data={tableProps?.data?.ehr?.data}
      meta={tableProps?.data?.ehr?.meta}
      title="EHR Accounts"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      searchBarFilterKey="name"
    />
  )
}

const FILTERS_CONFIG = {
  name: DEFAULT_FILTERS.name,
  plan_status: {
    label: 'Account Status',
    type: 'multi_select',
    options: [
      { label: 'Trial Requested', value: 'trial_requested' },
      { label: 'Trialing', value: 'trialing' },
      { label: 'Trial Expired', value: 'trial_expired' },
      { label: 'Active', value: 'active' },
      { label: 'Unpaid', value: 'unpaid' },
      { label: 'Cancelled', value: 'canceled' },
      { label: 'Community', value: 'community' },
      { label: 'Archived', value: 'archived' },
    ],
  },
  review_status: {
    label: 'Sales Status',
    type: 'multi_select',
    options: Object.entries(SALES_STEP).map(([value, label]) => ({ label, value })),
  },
  category: {
    label: 'Account Type',
    type: 'multi_select',
    options: Object.entries(ACCOUNT_TYPES).map(([value, label]) => ({ label, value })),
  },
  tenant: {
    label: 'Subdomain',
    type: 'string',
  },
  timezone: {
    label: 'Timezone',
    type: 'string',
  },
  state: {
    label: 'State',
    type: 'string',
  },
  hubspot_deal_id: {
    label: 'Hubspot Deal',
    type: 'string',
  },
  stripe_customer_id: {
    label: 'Stripe Customer',
    type: 'string',
  },
  trial_ended_at: {
    label: 'Trial Ends On',
    glyph: 'date',
    type: 'date_time',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
