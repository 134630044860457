import React from 'react'
import { v4 as uuid } from 'uuid'
import cloneDeep from 'lodash/cloneDeep'

import RadioPillBoxBase from './RadioPillBoxBase'

export default class RadioPillBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      type: 'RADIO',
      id: `radio-${uuid()}`,
      value: props.value,
      isTouched: false,
      isUntouched: true,
    }
  }

  /*
    CUSTOM
  */
  onChange = () => {
    const state = cloneDeep(this.state)

    state.value = this.props.value
    this.setState(state)

    if (this.props.onChange) this.props.onChange(state)
  }

  onFocus = () => {
    if (this.state.isTouched) return
    this.setState({ isTouched: true, isUntouched: false })
  }

  /*
    RENDER
  */
  render() {
    const { group_id, label, value, parentValue, description, tooltip, maxWidth, glyph } = this.props
    const { id } = this.state

    const $checked = parentValue === value

    return (
      <RadioPillBoxBase
        id={id}
        group_id={group_id}
        isChecked={$checked}
        label={label}
        onChange={this.onChange}
        onFocus={this.onFocus}
        type="radio"
        glyph={glyph}
        value={value}
        description={description}
        tooltip={tooltip}
        maxWidth={maxWidth}
        css={styles}
      />
    )
  }
}

const styles = {
  flexDirection: 'vertical',
}
