import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { COLORS } from '@behavehealth/theme'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Card, CardsGrid, Page, PageLayout, PageSection, Glyph } from '@behavehealth/components'
import { InsuranceChargesTable } from '@behavehealth/components/Tables'
import FinancialCard from '@behavehealth/components/Cards/FinancialCard'

const pageConfig = {
  feature: 'insurance',
  title: 'Insurance Charges',
}

const InsuranceCharges: React.FC = () => {
  const match = useRouteMatch()

  return (
    <Page {...pageConfig}>
      <PageLayout>
        <PageSection>
          <PageSection.Header graphic={<Glyph glyph="cash" color={COLORS.green} />}>
            <PageSection.Title title="Aging Buckets" />
          </PageSection.Header>

          <PageSection.Content>
            <CardsGrid columnWidth={260}>
              <FinancialCard title="30 Days" value={1000} valueColor="green" />
              <FinancialCard title="60 Days" value={1000} valueColor="green" />
              <FinancialCard title="90 Days" value={1000} valueColor="green" />
              <FinancialCard title="120 Days" value={1000} valueColor="green" />
            </CardsGrid>
          </PageSection.Content>
        </PageSection>

        <PageSection>
          <PageSection.Header graphic={<Glyph glyph="cash" color={COLORS.green} />}>
            <PageSection.Title title="Financial Statistics" />
          </PageSection.Header>

          <PageSection.Content>
            <CardsGrid columnWidth={260}>
              <FinancialCard title="Total Billed Amount" value={1000} valueColor="red" />
              <FinancialCard title="Total Allowed Amount" value={1000} valueColor="orange" />
              <FinancialCard title="Total Payments Collected Amount" value={1000} valueColor="green" />

              <FinancialCard title="Percentage Allowed Of Billed" renderValue={() => '10%'} valueColor="red" />
              <FinancialCard title="Percentage Paid Of Billed" renderValue={() => '10%'} valueColor="red" />

              <FinancialCard
                title="Average Number Of Days Between Service Date And Billing Date"
                renderValue={() => '12 days'}
                valueColor="red"
              />
              <FinancialCard
                title="Average Number Of Days Between Billing Date And Payment Date"
                renderValue={() => '12 days'}
                valueColor="red"
              />

              <FinancialCard title="Total Write-off Amount" value={1000} valueColor="black" />

              <FinancialCard title="Co-Insurance Due" value={1000} valueColor="red" />
              <FinancialCard title="Co-Pay Due" value={1000} valueColor="red" />
              <FinancialCard title="Co-Insurance Paid" value={1000} valueColor="green" />
              <FinancialCard title="Co-Pay Paid" value={1000} valueColor="green" />
            </CardsGrid>
          </PageSection.Content>
        </PageSection>

        <Card>
          <InsuranceChargesTable
            useAutoPageSize
            data={DATA}
            localStorageKey="clients_insurance_charges"
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
          />
        </Card>
      </PageLayout>
    </Page>
  )
}

const DATA = [
  {
    id: 'Oglw6y',
    type: 'insurance_transaction',
    updated_at: '2021-08-23T18:36:17.264-04:00',
    created_at: '2021-08-23T18:36:17.264-04:00',
    claim_id: '156495159',
    charge_id: '344072996',
    adjustment_id: '948026374',
    status: 'BALANCE DUE PATIENT',
    detailed_status: 'BALANCE DUE PATIENT',
    set_to_status_date: null,
    first_bill_date: null,
    first_bill_date_age: '65',
    follow_up_check_date: null,
    units: '1',
    diagnosis_pointer_list: null,
    rev_code: '0912',
    tos_code: '1',
    tos_description: 'Medical Care',
    pos_code: '57',
    pos_description: 'Non-residential Substance Abuse Treatment Facility',
    payment_check_number: '32012278',
    follow_up_check_number: null,
    check_payment: '0.0',
    cpt_code: 'S0201',
    cpt_description: 'PARTIAL HOSPITALIZATION ALCOHOL/DRUG SERV',
    insurance_payments: '0.0',
    unpaid_amount: null,
    deductible_amount: '0.0',
    credit_adjustment_code: null,
    adjustment_amount: '0.0',
    insurance_adjustment_amount: '0.0',
    follow_up_paid_amount: null,
    primary_payer_id: null,
    primary_payer_name: 'HEALTH PARTNERS CLAIMS',
    primary_payer_type: 'Commercial Insurance Company',
    primary_payment_date: null,
    primary_balance: '0.0',
    primary_paid: '0.0',
    secondary_payer_id: '0',
    secondary_payer_name: 'No Insurance',
    secondary_payer_type: 'Self Pay',
    secondary_payment_date: null,
    secondary_balance: '0.0',
    secondary_paid: '0.0',
  },
  {
    id: 'agOwgn',
    type: 'insurance_transaction',
    updated_at: '2021-08-23T18:36:17.269-04:00',
    created_at: '2021-08-23T18:36:17.269-04:00',
    claim_id: '156495159',
    charge_id: '344072996',
    adjustment_id: null,
    status: 'BALANCE DUE PATIENT',
    detailed_status: 'BALANCE DUE PATIENT',
    set_to_status_date: null,
    first_bill_date: null,
    first_bill_date_age: '65',
    follow_up_check_date: null,
    units: '1',
    diagnosis_pointer_list: null,
    rev_code: '0912',
    tos_code: '1',
    tos_description: 'Medical Care',
    pos_code: '57',
    pos_description: 'Non-residential Substance Abuse Treatment Facility',
    payment_check_number: '32012278',
    follow_up_check_number: null,
    check_payment: '0.0',
    cpt_code: 'S0201',
    cpt_description: 'PARTIAL HOSPITALIZATION ALCOHOL/DRUG SERV',
    insurance_payments: '0.0',
    unpaid_amount: '0.0',
    deductible_amount: '0.0',
    credit_adjustment_code: null,
    adjustment_amount: '0.0',
    insurance_adjustment_amount: '0.0',
    follow_up_paid_amount: null,
    primary_payer_id: null,
    primary_payer_name: 'HEALTH PARTNERS CLAIMS',
    primary_payer_type: 'Commercial Insurance Company',
    primary_payment_date: null,
    primary_balance: '0.0',
    primary_paid: '0.0',
    secondary_payer_id: '0',
    secondary_payer_name: 'No Insurance',
    secondary_payer_type: 'Self Pay',
    secondary_payment_date: null,
    secondary_balance: '0.0',
    secondary_paid: '0.0',
  },
  {
    id: 'Oglw6y',
    type: 'insurance_transaction',
    updated_at: '2021-08-23T18:36:17.264-04:00',
    created_at: '2021-08-23T18:36:17.264-04:00',
    claim_id: '156495160',
    charge_id: '344072996',
    adjustment_id: '948026374',
    status: 'BALANCE DUE PATIENT',
    detailed_status: 'BALANCE DUE PATIENT',
    set_to_status_date: null,
    first_bill_date: null,
    first_bill_date_age: '65',
    follow_up_check_date: null,
    units: '1',
    diagnosis_pointer_list: null,
    rev_code: '0912',
    tos_code: '1',
    tos_description: 'Medical Care',
    pos_code: '57',
    pos_description: 'Non-residential Substance Abuse Treatment Facility',
    payment_check_number: '32012278',
    follow_up_check_number: null,
    check_payment: '0.0',
    cpt_code: 'S0201',
    cpt_description: 'PARTIAL HOSPITALIZATION ALCOHOL/DRUG SERV',
    insurance_payments: '0.0',
    unpaid_amount: null,
    deductible_amount: '0.0',
    credit_adjustment_code: null,
    adjustment_amount: '0.0',
    insurance_adjustment_amount: '0.0',
    follow_up_paid_amount: null,
    primary_payer_id: null,
    primary_payer_name: 'HEALTH PARTNERS CLAIMS',
    primary_payer_type: 'Commercial Insurance Company',
    primary_payment_date: null,
    primary_balance: '0.0',
    primary_paid: '0.0',
    secondary_payer_id: '0',
    secondary_payer_name: 'No Insurance',
    secondary_payer_type: 'Self Pay',
    secondary_payment_date: null,
    secondary_balance: '0.0',
    secondary_paid: '0.0',
  },
  {
    id: 'agOwgn',
    type: 'insurance_transaction',
    updated_at: '2021-08-23T18:36:17.269-04:00',
    created_at: '2021-08-23T18:36:17.269-04:00',
    claim_id: '156495160',
    charge_id: '344072996',
    adjustment_id: null,
    status: 'BALANCE DUE PATIENT',
    detailed_status: 'BALANCE DUE PATIENT',
    set_to_status_date: null,
    first_bill_date: null,
    first_bill_date_age: '65',
    follow_up_check_date: null,
    units: '1',
    diagnosis_pointer_list: null,
    rev_code: '0912',
    tos_code: '1',
    tos_description: 'Medical Care',
    pos_code: '57',
    pos_description: 'Non-residential Substance Abuse Treatment Facility',
    payment_check_number: '32012278',
    follow_up_check_number: null,
    check_payment: '0.0',
    cpt_code: 'S0201',
    cpt_description: 'PARTIAL HOSPITALIZATION ALCOHOL/DRUG SERV',
    insurance_payments: '0.0',
    unpaid_amount: '0.0',
    deductible_amount: '0.0',
    credit_adjustment_code: null,
    adjustment_amount: '0.0',
    insurance_adjustment_amount: '0.0',
    follow_up_paid_amount: null,
    primary_payer_id: null,
    primary_payer_name: 'HEALTH PARTNERS CLAIMS',
    primary_payer_type: 'Commercial Insurance Company',
    primary_payment_date: null,
    primary_balance: '0.0',
    primary_paid: '0.0',
    secondary_payer_id: '0',
    secondary_payer_name: 'No Insurance',
    secondary_payer_type: 'Self Pay',
    secondary_payment_date: null,
    secondary_balance: '0.0',
    secondary_paid: '0.0',
  },
  {
    id: 'Oglw6y',
    type: 'insurance_transaction',
    updated_at: '2021-08-23T18:36:17.264-04:00',
    created_at: '2021-08-23T18:36:17.264-04:00',
    claim_id: '156495161',
    charge_id: '344072996',
    adjustment_id: '948026374',
    status: 'BALANCE DUE PATIENT',
    detailed_status: 'BALANCE DUE PATIENT',
    set_to_status_date: null,
    first_bill_date: null,
    first_bill_date_age: '65',
    follow_up_check_date: null,
    units: '1',
    diagnosis_pointer_list: null,
    rev_code: '0912',
    tos_code: '1',
    tos_description: 'Medical Care',
    pos_code: '57',
    pos_description: 'Non-residential Substance Abuse Treatment Facility',
    payment_check_number: '32012278',
    follow_up_check_number: null,
    check_payment: '0.0',
    cpt_code: 'S0201',
    cpt_description: 'PARTIAL HOSPITALIZATION ALCOHOL/DRUG SERV',
    insurance_payments: '0.0',
    unpaid_amount: null,
    deductible_amount: '0.0',
    credit_adjustment_code: null,
    adjustment_amount: '0.0',
    insurance_adjustment_amount: '0.0',
    follow_up_paid_amount: null,
    primary_payer_id: null,
    primary_payer_name: 'HEALTH PARTNERS CLAIMS',
    primary_payer_type: 'Commercial Insurance Company',
    primary_payment_date: null,
    primary_balance: '0.0',
    primary_paid: '0.0',
    secondary_payer_id: '0',
    secondary_payer_name: 'No Insurance',
    secondary_payer_type: 'Self Pay',
    secondary_payment_date: null,
    secondary_balance: '0.0',
    secondary_paid: '0.0',
  },
  {
    id: 'agOwgn',
    type: 'insurance_transaction',
    updated_at: '2021-08-23T18:36:17.269-04:00',
    created_at: '2021-08-23T18:36:17.269-04:00',
    claim_id: '156495161',
    charge_id: '344072996',
    adjustment_id: null,
    status: 'BALANCE DUE PATIENT',
    detailed_status: 'BALANCE DUE PATIENT',
    set_to_status_date: null,
    first_bill_date: null,
    first_bill_date_age: '65',
    follow_up_check_date: null,
    units: '1',
    diagnosis_pointer_list: null,
    rev_code: '0912',
    tos_code: '1',
    tos_description: 'Medical Care',
    pos_code: '57',
    pos_description: 'Non-residential Substance Abuse Treatment Facility',
    payment_check_number: '32012278',
    follow_up_check_number: null,
    check_payment: '0.0',
    cpt_code: 'S0201',
    cpt_description: 'PARTIAL HOSPITALIZATION ALCOHOL/DRUG SERV',
    insurance_payments: '0.0',
    unpaid_amount: '0.0',
    deductible_amount: '0.0',
    credit_adjustment_code: null,
    adjustment_amount: '0.0',
    insurance_adjustment_amount: '0.0',
    follow_up_paid_amount: null,
    primary_payer_id: null,
    primary_payer_name: 'HEALTH PARTNERS CLAIMS',
    primary_payer_type: 'Commercial Insurance Company',
    primary_payment_date: null,
    primary_balance: '0.0',
    primary_paid: '0.0',
    secondary_payer_id: '0',
    secondary_payer_name: 'No Insurance',
    secondary_payer_type: 'Self Pay',
    secondary_payment_date: null,
    secondary_balance: '0.0',
    secondary_paid: '0.0',
  },
]

export default withPageError(withMarketing(InsuranceCharges, pageConfig))
