import React from 'react'

import Button from '../Button'
import DataFormStatus from '../Statuses/DataFormStatus'
import Flex from '../Flex'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import withSettings from '../../hocs/withSettings'

const columns = (to: Function = () => {}, duplicateLink: any, timezone: string, mainLinkAs) => [
  {
    isSticky: true,
    canToggleVisible: false,
    id: 'name',
    width: 250,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }) => <TableCell.MainLink as={mainLinkAs} to={to(row.original.id)} label={value} />,
  },
  {
    width: 200,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <DataFormStatus status={value} />,
    Filter: TableFilter.DataFormStatus,
    filter: 'dataFormStatus',
  },
  {
    width: 200,
    accessor: 'started_at',
    Header: 'Start Date and Time',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'ended_at',
    Header: 'End Date and Time',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 220,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
  ...(duplicateLink
    ? [
        {
          width: 130,
          id: 'duplicate',
          accessor: 'id',
          Cell: ({ row }) => {
            return (
              <Flex gap="0.5rem">
                <Button
                  label="Duplicate"
                  glyph="add"
                  size={200}
                  link={duplicateLink(row.original)}
                  permission="client_custom_notes.create"
                />
              </Flex>
            )
          },
        },
      ]
    : []),
]

const CustomNotesTable = ({
  title = 'Custom Notes',
  emptyDescription = 'No custom notes have been added yet',
  icon = 'clinical_assessments',
  data,
  to,
  isLoading,
  timezone,
  localStorageKey,
  emptyActions,
  batchActionsConfig,
  titleAfter,
  duplicateLink,
  mainLinkAs,
  ...rest
}: any) => {
  return (
    <Table
      testKey="custom_notes_table"
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, duplicateLink, timezone, mainLinkAs)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

CustomNotesTable.defaultProps = {
  localStorageKey: 'custom_notes',
}

export default withSettings(CustomNotesTable)
