import React from 'react'
import { lighten } from 'polished'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import { NavGroupLabel } from '../../components/NavGroup'
import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import NavItem from '../../components/NavItem'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'

import { FormRenderer } from '../FormBuilder/components/FormRenderer'
import { NewFormBuilderProvider, useFormBuilder } from '../FormBuilder/useFormBuilder'

const FormSubmissionOverlayWithContext = (props: any) => {
  const { partner } = props
  const { form_id }: any = useParams()

  const {
    cancel,
    close,
    data,
    edit,
    form,
    highlightRequired,
    initialModel,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'form_submissions',
    endpoint: '/form_submissions',
    invalidate: 'form_submissions',
    options: props,
    closeOnSave: false,
    headers: {
      'X-Tenant': partner.subdomain,
    },
  })

  const isNewForm = !!(isNew && form_id)

  const { data: formData, isLoading: isLoadingForm }: any = useGet({
    name: ['form', form_id],
    url: `/forms/${form_id}`,
    options: { enabled: !!(isNewForm && partner.subdomain) },
    headers: {
      'X-Tenant': partner.subdomain,
    },
  })

  const newSubmissionSettings = React.useMemo(() => {
    if (!isNewForm || !formData) return {}

    const {
      pdf_export_settings,
      public_sharing_settings,
      reference_settings,
      review_and_signoff_workflow_settings,
      use_pdf_export,
      use_review_and_signoff_workflow,
    } = formData

    return {
      pdf_export_settings,
      portal_permissions_settings: 'can_view_edit',
      public_sharing_settings,
      reference_settings,
      review_and_signoff_workflow_settings,
      use_pdf_export,
      use_portal_permissions: true,
      use_public_sharing: false, // don't apply public sharing by default
      use_reference: true,
      use_review_and_signoff_workflow,
    }
  }, [formData, isNewForm])

  const apiData = React.useMemo(() => {
    if (isNewForm) return formData

    return { ...data?.schema, form_variables: data?.form_variables }
  }, [isNewForm, formData, data])

  const showOverlayLoader = isNewForm ? isLoadingForm || !formData : isOverlayLoading || !data

  if (showOverlayLoader) return <OverlayLoader maxWidth={90} position="right" />

  return (
    <NewFormBuilderProvider apiData={apiData} isEditable={false} isNew={false} environment="submission">
      <InnerFormSubmissionOverlay
        apiData={apiData}
        cancel={cancel}
        close={close}
        edit={edit}
        form={form}
        highlightRequired={highlightRequired}
        initialModel={initialModel}
        isEditable={isEditable}
        isInvalid={isInvalid}
        isNew={isNew}
        isNewForm={isNewForm}
        isSaving={isSaving}
        newSubmissionSettings={newSubmissionSettings}
        onValidationUpdate={onValidationUpdate}
        saveWithData={saveWithData}
      />
    </NewFormBuilderProvider>
  )
}

const InnerFormSubmissionOverlay = (props: any) => {
  const {
    apiData,
    cancel,
    close,
    edit,
    form,
    highlightRequired,
    initialModel,
    isEditable,
    isInvalid,
    isNew,
    isNewForm,
    isSaving,
    newSubmissionSettings,
    onValidationUpdate,
    saveWithData,
  } = props

  const { form_id }: any = useParams()

  const pagesCount = size(apiData?.form_pages)
  const mainClasses = clsx(pagesCount >= 2 && 'show-pages-nav')
  const parseAllElementConfigVariables: any = useFormBuilder((state: any) => state.parseAllElementConfigVariables)

  const handleSave = () => {
    saveWithData({
      ...form.current?.getFormValue?.(),
      ...(isNewForm && {
        form_id,
        ...newSubmissionSettings,
        ...(initialModel?.reference && {
          reference_id: initialModel.reference.id,
          reference_type: initialModel.reference.type,
        }),
      }),
    })
  }

  React.useEffect(() => {
    parseAllElementConfigVariables()
  }, [])

  return (
    <Overlay
      fullheight
      closeOnEscape={false}
      showBackdrop={isEditable}
      closeOnBackdrop={!isEditable}
      maxWidth={90}
      position="right"
      onClose={close}
      css={STYLES.root}
    >
      <Overlay.Header icon="web_form" title="Form Submission" />

      <Overlay.Content css={STYLES.overlayContent}>
        <Form
          getForm={form}
          isEditable={isEditable}
          initialModel={initialModel}
          deregisterOnUnmount={false}
          css={STYLES.grid}
          onValidationUpdate={onValidationUpdate}
        >
          <main className={mainClasses} css={STYLES.main}>
            <PagesNav className="form-pages-nav" />
            <Flex centerX className="py-2">
              <FormRenderer />
            </Flex>
          </main>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              onClick={handleSave}
              isLoading={isSaving}
              isDisabled={isSaving}
              flex="100 1 auto"
              permission={isNew ? 'form_submissions.create' : 'form_submissions.edit'}
            />

            {isInvalid && (
              <Button
                label="Highlight Required Fields"
                glyph="view"
                type="default"
                color="orange"
                onClick={highlightRequired}
                isDisabled={isSaving}
              />
            )}

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <Flex stretchChildrenX gap="0.75rem">
            <Button label="Edit Form Submission" glyph="edit" onClick={edit} permission="form_submissions.edit" flex="3 1 auto" />
          </Flex>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const PagesNav = () => {
  const activePageId: any = useFormBuilder((state: any) => state.activePageId)
  const pages: any = useFormBuilder((state: any) => state.pages)
  const pagesOrder: any = useFormBuilder((state: any) => state.pagesOrder)
  const setActivePageId: any = useFormBuilder((state: any) => state.setActivePageId)

  return (
    <div className="form-pages-nav">
      <NavGroupLabel label="Form Pages" css={{ marginBottom: '0.4rem' }} />

      {pagesOrder?.map?.((pageId: any) => {
        const isActive = activePageId === pageId
        const page = pages[pageId]

        if (!page || page._destroy) return null

        return (
          <NavItem
            key={pageId}
            glyph="document"
            color={COLORS.vividBlue}
            isActive={isActive}
            label={page.name}
            onClick={() => setActivePageId(page.uuid)}
          />
        )
      })}
    </div>
  )
}

const STYLES = {
  root: {
    '--header-height': '2.8rem',
    '--header-background': COLORS.white,
    '--subheader-height': '2.4rem',
    '--subheader-background': lighten(0.01, '#F7F8FB'),
    '--field-max-width': '100% !important',
  },

  overlayContent: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    zIndex: 0,

    '@media(min-width: 800px)': {
      overflow: 'hidden !important',
    },
  },

  grid: {
    '@media(min-width: 800px)': {
      display: 'grid',
      overflow: 'hidden',
      gridTemplateRows: '100%',
      gridTemplateColumns: '1fr',
    },
  },

  main: {
    padding: '0.75rem',
    overflowY: 'auto',
    overflow: 'hidden',

    '@media(min-width: 800px)': {
      overflowY: 'auto',
    },

    '&.show-pages-nav': {
      display: 'grid',
      gridGap: '1rem',
      alignContent: 'start',

      '.form-pages-nav': {
        display: 'block',
      },

      '@media(min-width: 960px)': {
        gridTemplateColumns: '230px 1fr',

        '.form-pages-nav': {
          top: 0,
          position: 'sticky',
          height: 'fit-content',
        },
      },
    },

    '& > .form-pages-nav': {
      display: 'none',
    },
  },
}

export const OrganizationFormSubmissionOverlay = withOverlayError(FormSubmissionOverlayWithContext)
