import React from 'react'
import groupBy from 'lodash/groupBy'

import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Permission from '../../../components/Permission'
import SummonOverlay from '../../../components/SummonOverlay'
import Tooltip from '../../../components/Tooltip'

import { AnchorDatePicker } from './AnchorDatePicker'
import { FormVariableOverlay } from './FormVariableOverlay'
import { FormVariablesTable } from './FormVariablesTable'
import { SystemVariablesTable } from './SystemVariablesTable'
import { TimeVariablesTable } from './TimeVariablesTable'
import { useFormBuilder } from '../useFormBuilder'

import { HQ_ACCOUNT_VARIABLES } from '../utils/constants'

export const VariablesMenu = () => {
  const isEditable: any = useFormBuilder((state: any) => state.isEditable)
  const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)

  const variables: any = useFormBuilder((state: any) => state.variables) || []
  const variablesByCategory = React.useMemo(() => groupBy(variables, 'category'), [variables])

  const { isEHRApp, isHQApp } = useSettings()

  const accountVariables = React.useMemo(() => {
    return variablesByCategory?.account || []
  }, [variablesByCategory])

  const customAccountVariables = React.useMemo(() => {
    return variablesByCategory?.custom_account || []
  }, [variablesByCategory])

  return (
    <Permission featureFlagV2="variables">
      <div className="grid gap-6 mt-1">
        <div className="grid gap-2">
          <header className="flex items-center justify-between">
            <div className="flex items-center flex-nowrap">
              <h3 className="text-[1.1rem]">Form Variables</h3>

              <Tooltip
                small
                glyph="info"
                className="ml-2"
                content="Used to define reusable pieces of data that can be referenced throughout this form only and any submissions created from it"
              />
            </div>

            {isEditable && !isPreviewMode && (
              <SummonOverlay overlay={<FormVariableOverlay />}>
                <Button label="Add Form Variable" glyph="add" size={100} type="default" />
              </SummonOverlay>
            )}
          </header>

          <Card>
            <FormVariablesTable isEditable={isEditable && !isPreviewMode} />
          </Card>
        </div>

        <div className="grid gap-2">
          <header className="flex items-center justify-between">
            <div className="flex items-center flex-nowrap">
              <h3 className="text-[1.1rem]">Time Variables</h3>

              <Tooltip
                small
                glyph="info"
                className="ml-2"
                content="Used to reference a specific time relative to the form submission date or the anchor date"
              />
            </div>

            <AnchorDatePicker isEditable={isEditable} />
          </header>

          <Card>
            <TimeVariablesTable />
          </Card>
        </div>

        {isEHRApp && (
          <div className="grid gap-2">
            <header className="flex items-center justify-between">
              <div className="flex items-center flex-nowrap">
                <h3 className="text-[1.1rem]">Custom Account Variables</h3>

                <Tooltip
                  small
                  glyph="info"
                  className="ml-2"
                  content="Editable account-level variables used to reference any data you want to share across all forms and submissions."
                />
              </div>

              <Button
                useGlyphForTarget
                label="Manage"
                glyph="settings"
                link="/settings/variables/custom-account"
                target="_blank"
                size={100}
              />
            </header>

            <Card>
              <SystemVariablesTable title="Custom Account Variables" variables={customAccountVariables} />
            </Card>
          </div>
        )}

        {isEHRApp && (
          <div className="grid gap-2">
            <header>
              <div className="flex items-center flex-nowrap">
                <h3 className="text-[1.1rem]">Account Variables</h3>

                <Tooltip
                  small
                  glyph="info"
                  className="ml-2"
                  content="Account-level variables shared by all forms and submissions; used to reference system-wide data such as your company name, address, etc."
                />
              </div>
            </header>

            <Card>
              <SystemVariablesTable title="Account Variables" variables={accountVariables} />
            </Card>
          </div>
        )}

        {isHQApp && (
          <div className="grid gap-2">
            <header>
              <div className="flex items-center flex-nowrap">
                <h3 className="text-[1.1rem]">Account Variables</h3>

                <Tooltip
                  small
                  glyph="info"
                  className="ml-2"
                  content="Account-level variables shared by all forms and submissions; used to reference system-wide data such as your company name, address, etc."
                />
              </div>
            </header>

            <Card>
              <SystemVariablesTable title="Account Variables" variables={HQ_ACCOUNT_VARIABLES} />
            </Card>
          </div>
        )}
      </div>
    </Permission>
  )
}
