import React from 'react'
import { useParams } from 'react-router-dom'

import Alert from '../../../components/Alert'
import Grid from '../../../components/Grid'
import Overlay from '../../../components/Overlay'

import { useGet } from '../../../hooks/useNewAPI'

import { ClaimStatuses } from '../components/ClaimStatuses'

export const ClaimOverlayElectronicStatuses = () => {
	const params = useParams()

	const { data } = useGet({
		name: ['insurance_claims', params?.id, 'insurance-claim-statuses'],
		url: `/insurance_claims/${params?.id}/insurance_claim_statuses`
	})

	const hasClaimStatuses = data?.length > 0

	return (
		<Overlay.Content className="z-0">
			<Grid gap="1rem" className="!p-4">
				{hasClaimStatuses ? (
					<ClaimStatuses allOpen statuses={data} />
				) : (
					<Alert contrast type="neutral" glyph="info">
						No Claim Statuses found.
					</Alert>
				)}
			</Grid>
		</Overlay.Content>
	)
}
