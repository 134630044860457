import React from 'react'

import ContextHide from '../../components/Forms/ContextHide'
import Diagnoses from '../../components/SmartContent/Diagnoses'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Medications from '../../components/SmartContent/Medications'
import Section from '../../components/Section'
import Switch from '../../components/Forms/Switch'
import Textarea from '../../components/Forms/Textarea'

import MedicalSystemsReview from '../../components/Overlays/pages/DataForms/common/MedicalSystemsReview'
import MentalStatusExam from '../../components/Overlays/pages/DataForms/common/MentalStatusExam'
import SubstanceUseDiagnosisCodes from '../../components/Overlays/pages/DataForms/common/SubstanceUseDiagnosisCodes'
import VitalSignsForm from '../../components/Overlays/pages/DataForms/common/VitalSignsForm'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootHistoryAndPhysicalFollowUpOverlay = () => (
  <DataFormOverlay
    title="History And Physical Follow Up"
    category="medical_assessment"
    subcategory="history_and_physical_follow_up"
    icon="medical_assessments"
    helpID="medical_assessments_history_and_physical_follow_up"
    requestClientSignature={false}
    requireSupervisor={true}
    requestSupervisorSignature={true}
    enableTreatmentPlanSelector
  >
    {({ client, data, isEditable }) => (
      <>
        {/* Medication List */}
        <Section
          title="Medication List"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.medications.not_applicable" />}
          scrollview={{
            id: 'medications',
            name: 'Medication List',
          }}
          commentsModel="data.medications"
        >
          <ContextHide when="data.medications.not_applicable" is={true}>
            <Medications
              model="data.medications.meds"
              isEditable={isEditable}
              client={client}
              icon="medications"
              title="Medications"
              emptyDescription="No medications added yet"
            />
          </ContextHide>
        </Section>

        <Divider />

        {/* Medical Systems Review */}
        <Section
          title="Medical Systems Review"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.medical_systems_review.not_applicable" />}
          scrollview={{
            id: 'medical_systems_review',
            name: 'Medical Systems Review',
          }}
          commentsModel="data.medical_systems_review"
        >
          <ContextHide when="data.medical_systems_review.not_applicable" is={true}>
            <MedicalSystemsReview />
          </ContextHide>
        </Section>

        <Divider />

        {/* Mental Status Exam */}
        <Section
          title="Mental Status Exam"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.mental_status_exam.not_applicable" />}
          scrollview={{
            id: 'mental_status_exam',
            name: 'Mental Status Exam',
          }}
          commentsModel="data.mental_status_exam"
        >
          <ContextHide when="data.mental_status_exam.not_applicable" is={true}>
            <MentalStatusExam />
          </ContextHide>
        </Section>

        <Divider />

        {/* Vital Signs */}
        <Section
          title="Vital Signs"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.vital_signs.not_applicable" />}
          scrollview={{
            id: 'vital_signs',
            name: 'Vital Signs',
          }}
          commentsModel="data.vital_signs"
        >
          <ContextHide when="data.vital_signs.not_applicable" is={true}>
            <VitalSignsForm />
          </ContextHide>
        </Section>

        <Divider />

        {/* Follow Up Plan */}
        <Section
          title="Follow Up Plan"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.follow_up_plan.not_applicable" />}
          scrollview={{
            id: 'follow_up_plan',
            name: 'Follow Up Plan',
          }}
          commentsModel="data.follow_up_plan"
        >
          <ContextHide when="data.follow_up_plan.not_applicable" is={true}>
            <Textarea model="data.follow_up_plan.description" />
          </ContextHide>
        </Section>

        <Divider />

        {/* Diagnoses */}
        <Section
          title="Diagnoses"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.diagnoses.not_applicable" />}
          scrollview={{
            id: 'diagnoses',
            name: 'Diagnoses',
          }}
          commentsModel="data.diagnoses_section"
        >
          <ContextHide when="data.diagnoses.not_applicable" is={true}>
            <Diagnoses
              model="data.diagnoses.diagnoses"
              isEditable={isEditable}
              client={client}
              icon="diagnosis"
              title="Diagnoses"
              emptyDescription="No diagnoses added yet"
            />
          </ContextHide>
        </Section>

        {/* Diagnostic Criteria */}
        {data?.diagnoses?.substance && (
          <>
            <Section
              title="Previous Diagnoses Section"
              headingType="h2"
              scrollview={{
                id: 'diagnostic_criteria',
                name: 'Previous Diagnoses Section',
              }}
              commentsModel="data.previous_diagnoses_section"
            >
              <ContextHide when="data.diagnoses.not_applicable" is={true}>
                <FormSection>
                  <SubstanceUseDiagnosisCodes />
                </FormSection>
              </ContextHide>
            </Section>

            <Divider />
          </>
        )}
      </>
    )}
  </DataFormOverlay>
)

export const HistoryAndPhysicalFollowUpOverlay = withOverlayError(RootHistoryAndPhysicalFollowUpOverlay)
