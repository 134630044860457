import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'

import TagSmartCategorySelector from './TagSmartCategorySelector'

const RootTagGroupOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'tag-groups',
    endpoint: '/tag_groups',
    invalidate: 'tag-groups',
    options: props,
  })

  if (isOverlayLoading) return <OverlayLoader position="right" />

  return (
    <Overlay showBackdrop={isEditable} onClose={close}>
      <Overlay.Header icon="tags" title="Tags Group" />

      <Overlay.Content>
        <Form getForm={form} initialModel={initialModel} isEditable={isEditable} onValidationUpdate={onValidationUpdate}>
          <Section>
            <FormSection>
              <FormSection>
                <Input
                  label="Group Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a group name',
                    },
                  }}
                />

                <Textarea useQuickText label="Description" model="description" />

                <RadioGroup
                  model="category"
                  label="Category"
                  layout="horizontal-dense"
                  description="Use the Smart Categories to limit Tags only to certain records"
                  defaultValue="general"
                >
                  <Radio label="General" value="general" />
                  <Radio label="Smart" value="smart" />
                </RadioGroup>
              </FormSection>

              <ContextShow when="category" is="smart">
                <TagSmartCategorySelector model="smart_categories" value={initialModel?.smart_categories} />
              </ContextShow>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save Group"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              // permission={true}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Group"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="tag_groups.edit"
            />

            <DeleteDialog
              title="Delete Group?"
              message="Are you sure you want to delete this group? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="tag_groups.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const TagGroupOverlay = withOverlayError(RootTagGroupOverlay)
