import React from 'react'

import { COLORS } from '../../../theme'
import { daysToWords, secondsBetween } from '../../../utils/functions'

import Avatar from '../../Avatar'
import Button from '../../Button'
import CommentStatus from '../../Statuses/CommentStatus'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Option from '../../Forms/Option'
import Select from '../../Forms/Select'
import Textarea from '../../Forms/Textarea'

const Comment = ({ canEdit, comment, onSave, onDelete }: any) => {
  const [isEditable, setIsEditable] = React.useState(false)
  const [content, setContent] = React.useState(comment.content)
  const [status, setStatus] = React.useState(comment.status)

  const didEdit = secondsBetween(comment.created_at, comment.updated_at) > 3

  return (
    <div>
      <div css={styles.author}>
        <Avatar initials={comment.author.name} src={comment.author.avatar} size="1.5rem" />

        <Flex centerY justifyContent="space-between" flex="1 1 auto">
          <Flex alignItems="baseline">
            <span css={styles.name}>{comment.author.name}</span>
            <span css={styles.info}>({didEdit ? `edited ${daysToWords(comment.updated_at)}` : daysToWords(comment.created_at)})</span>
            <CommentStatus status={comment.status} css={styles.status} />
          </Flex>

          {canEdit && !isEditable && (
            <div>
              <span css={styles.editButton} onClick={() => setIsEditable(true)}>
                Edit
              </span>

              <DeleteDialog
                title="Delete Comment?"
                message="Are you sure you want to delete this comment? This action cannot be undone."
                onYes={onDelete}
                css={{ display: 'inline-block' }}
              >
                <span css={styles.deleteButton}>Delete</span>
              </DeleteDialog>
            </div>
          )}
        </Flex>
      </div>

      <div css={styles.contentWrapper}>
        {!isEditable && <p css={styles.content}>{comment.content}</p>}

        {canEdit && isEditable && (
          <div css={styles.editWrapper}>
            <Textarea
              isEditable
              value={content}
              onUpdate={({ value }) => setContent(value)}
              placeholder="Edit comment…"
              validations={{
                presence: {
                  message: 'Please add your comment',
                },
              }}
            />

            <Select allowEmpty isEditable label="Status" value={status} onUpdate={({ value }) => setStatus(value)}>
              <Option label="None" value="none" />
              <Option label="Todo" value="todo" />
              <Option label="Done" value="done" />
            </Select>

            <Button
              label="Save"
              type="primary"
              color="green"
              onClick={() => {
                onSave({ content, status })
                setIsEditable(false)
              }}
              display="inline-flex"
              size={300}
              className="!mt-3"
            />

            <Button
              label="Cancel"
              onClick={() => setIsEditable(false)}
              display="inline-flex"
              size={300}
              css={{ marginTop: '0.75rem', marginLeft: '0.75rem' }}
            />
          </div>
        )}
      </div>

      <Divider />
    </div>
  )
}

const styles = {
  status: {
    marginLeft: '0.5rem',
  },

  author: {
    display: 'flex',
    alignItems: 'center',
  },

  name: {
    fontWeight: 600,
    fontSize: '0.95rem',
    display: 'inline-block',
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
  },

  info: {
    opacity: 0.8,
    fontSize: '0.9rem',
    fontWeight: 400,
  },

  contentWrapper: {
    paddingLeft: '2rem',
    marginBottom: '1rem',
  },

  editWrapper: {
    marginTop: '0.5rem',
  },

  content: {
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
  },

  editButton: {
    color: COLORS.link,
    fontWeight: 500,
    marginLeft: '0.8rem',
    fontSize: '0.9rem',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  deleteButton: {
    color: COLORS.red,
    fontWeight: 500,
    marginLeft: '0.8rem',
    fontSize: '0.9rem',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}

export default Comment
