import React from 'react'
import startCase from 'lodash/startCase'

import { COLORS } from '../../../theme'
import { usDate, fullname } from '../../../utils/functions'
import { LOC } from '../../../utils/constants'
import { RELATIONSHIP_TO_CLIENT_CODE } from '../constants'

import Button from '../../../components/Button'
import DataList from '../../../components/DataList'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import ClaimInternalStatus from '../../../components/Statuses/ClaimInternalStatus'

import { useCreate } from '../../../hooks/useNewAPI'

const ClaimInfo = ({ clientID, claim }: any) => {
  if (!claim) return null

  const canRunElectronicStatus = claim.status === 'processed'

  const { mutateAsync: verifyStatus, isLoading: isVerifying } = useCreate({
    name: ['insurance-claims', claim?.id, 'insurance-claim-statuses'],
    url: `/insurance_claims/${claim?.id}/verify_status`,
    invalidate: ['client', clientID, 'insurance-claims'],
  })

  return (
    <Flex gap="1.5rem">
      <DataList layout="horizontal" css={styles.dataList} paddingY="0.4rem">
        <DataList.Title title="Claim Info" css={styles.title} />
        <DataList.Item label="Claim Type" value={startCase(claim.category)} />
        <DataList.Item label="Internal Status" value={claim.status ? <ClaimInternalStatus status={claim.status} /> : null} />
        <DataList.Item
          label="Last Electronic Status"
          value={
            canRunElectronicStatus ? (
              <>
                {claim?.last_insurance_claim_status ? (
                  <div>
                    <div>
                      {claim.last_insurance_claim_status.status.code_value} (
                      {usDate(claim.last_insurance_claim_status.status.effective_date)})
                    </div>
                    <Button
                      size={100}
                      type="link"
                      label="Re-Verify Status"
                      display="inline-flex"
                      className="!pl-0"
                      onClick={async () => {
                        await verifyStatus()
                      }}
                      isLoading={isVerifying}
                    />
                  </div>
                ) : (
                  <div>
                    <div>Not verified yet</div>
                    <Button
                      size={100}
                      type="link"
                      label="Verify Status"
                      display="inline-flex"
                      className="!pl-0"
                      onClick={async () => {
                        await verifyStatus()
                      }}
                      isLoading={isVerifying}
                    />
                  </div>
                )}
              </>
            ) : (
              <div>
                <div>
                  The Claim must be <ClaimInternalStatus status="processed" css={{ display: 'inline', padding: '2px 0 1px 0' }} /> before
                  verifying the Electronic Status
                </div>
              </div>
            )
          }
        />
        <DataList.Item label="Assignee" value={claim.assignee?.name} />
        <DataList.Item
          label="Authorization"
          value={
            <Grid gap="0.25rem">
              <div>Level of Care: {LOC[claim?.insurance_authorization?.level] || '–'}</div>
              <div>
                Time covered: {usDate(claim?.insurance_authorization?.started_at)} to {usDate(claim?.insurance_authorization?.ended_at)}
              </div>
              <div>
                Sessions Authorized: {claim?.insurance_authorization?.sessions || 0} Used:{' '}
                {claim?.insurance_authorization?.used_sessions || 0}
              </div>
            </Grid>
          }
        />
      </DataList>

      <DataList layout="horizontal" css={styles.dataList} paddingY="0.4rem">
        <DataList.Title title="Client Info" css={styles.title} />
        <DataList.Item label="Client #" value={claim.resident?.behave_id} />
        <DataList.Item label="Client Name" value={claim.resident?.name} />
        <DataList.Item label="Client DOB" value={usDate(claim.resident?.dob)} />
        <DataList.Item
          label="Insured Name"
          value={claim.primary_insurance?.is_client_policy_holder ? claim.resident?.name : fullname(claim.primary_insurance?.policy_holder)}
        />
        <DataList.Item
          label="Relationship"
          value={
            claim.primary_insurance?.is_client_policy_holder
              ? 'Self'
              : RELATIONSHIP_TO_CLIENT_CODE[claim.primary_insurance?.policy_holder?.relationship_to_customer] || '–'
          }
        />
        <DataList.Item label="Member ID" value={claim.primary_insurance?.member_id} />
      </DataList>

      <DataList layout="horizontal" css={styles.dataList} paddingY="0.4rem">
        <DataList.Title title="Billing Provider" css={styles.title} />
        <DataList.Item label="Provider" value={claim?.billing_provider?.credential || '–'} />
        <DataList.Item label="Name" value={claim?.billing_provider?.organization || '–'} />
      </DataList>
    </Flex>
  )
}

const styles = {
  dataList: {
    fontSize: '0.95rem',
    flex: '1 1 300px',
  },

  conditionCode: {
    '& + &': {
      marginTop: '0.4rem',
    },
  },

  title: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    color: COLORS.textMuted,
  },
}

export default ClaimInfo
