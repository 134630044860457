import React from 'react'
import size from 'lodash/size'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import Button from '@behavehealth/components/Button'
import Permission from '@behavehealth/components/Permission'
import SummonOverlay from '@behavehealth/components/SummonOverlay'

import { BatchApplyPermissionTemplateOverlay } from '@behavehealth/components/Permissions/BatchApplyPermissionTemplateOverlay'
import { EmployeesDataTable } from '@behavehealth/constructs/Employees/EmployeesDataTable'

const Archived: React.FC = () => {
  const staffTableProps = useDataTable({
    name: ['employees'],
    endpoint: '/employees',
    params: { status: 'archived' },
    localStorageKey: 'staff_v1',
  })

  return (
    <div className="grid">
      <EmployeesDataTable
        {...staffTableProps}
        canBatchSelect
        showPermissionsColumn
        title="Archived Staff Members"
        testKey="employees_table"
        to={(employee: any) => `/employees/${employee.id}`}
        hiddenColumnIds={['status']}
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/employees',
          },
        ]}
        renderBatchActions={({ selectedRows }: any) => {
          const isSelectionEmpty = size(selectedRows) === 0

          return (
            <Permission permission="employees.permissions.view">
              <SummonOverlay overlay={<BatchApplyPermissionTemplateOverlay employees={selectedRows} />} isDisabled={isSelectionEmpty}>
                <Button label="Apply Staff Permissions" type="primary" size={100} className="!ml-3" isDisabled={isSelectionEmpty} />
              </SummonOverlay>
            </Permission>
          )
        }}
      />
    </div>
  )
}

export default Archived
