import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

import withMarketing from '@behavehealth/hocs/withMarketing'
import useAPI from '@behavehealth/hooks/useAPI'

import { Card, HelpTagIframe, Page } from '@behavehealth/components'

import AgreementTemplatesTable from '@behavehealth/components/Templates/tables/AgreementTemplatesTable'

const pageConfig = {
  feature: 'organization_agreement_templates',
  title: 'Organization Agreement Templates',
  help: <HelpTagIframe id="settings_organization_agreement_templates" />,
}

const OrganizationAgreementTemplates = () => {
  const data = useSelector((state: any) => state.data?.agreement_templates?.data)
  const loading = useSelector((state: any) => state.data?.agreement_templates?.loading)
  const isEmpty = size(data) === 0

  useAPI('agreement_templates', '/agreement_templates?variant=organization')

  const sortedData = React.useMemo(() => {
    if (!data) return []
    return sortBy(data, 'order', 'created_at')
  }, [data])

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Card testKey="organization_agreement_template_table">
        <AgreementTemplatesTable
          mainLinkAs={NavLink}
          title="Organization Agreement Templates"
          data={sortedData}
          isLoading={isEmpty && loading}
          to={(id: string) => id}
        />
      </Card>
    </Page>
  )
}

export default withMarketing(OrganizationAgreementTemplates, pageConfig)
