import React from 'react'
import { DateTime } from 'luxon'
import { lighten } from 'polished'
import { PanelGroup, Panel } from 'react-resizable-panels'
import { produce } from 'immer'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { styles } from '../../components/DropdownItem'
import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { setHeadersTenant } from '@behavehealth/modules/axios'

import Dropdown from '../../components/Dropdown'
import Form from '../../components/Forms/Form'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Select from '../../components/Forms/Select'
import TreeItem from '../../components/TreeItem'

import { ActivePage } from './components/ActivePage'
import { MainMenu } from './components/MainMenu'
import { PanelResize } from './components/PanelResize'

import { NewFormBuilderProvider, useFormBuilder } from './useFormBuilder'
import { useFormBuilderHotkeys } from './useFormBuilderHotkeys'

const BH_TENANT = process.env.NODE_ENV === 'development' ? 'demo' : 'behave-health-514lpk'

const formatApiData = (data: any) => {
  if (!data?.data) return null

  const { form_pages_attributes = [], form_variables_attributes = [], ...rest } = data.data

  const form_pages: any = []
  const elements_order: any = data.data?.elements_order || []

  for (const page of form_pages_attributes) {
    const { form_blocks_attributes: form_blocks = [], ...pageRest } = page

    if (!elements_order.includes(page.uuid)) {
      elements_order.push(page.uuid)
    }

    form_pages.push({
      ...pageRest,
      form_blocks,
    })
  }

  return {
    ...rest,
    form_pages,
    elements_order,
    form_variables: form_variables_attributes,
    global_ai_inputs: data.global_ai_inputs,
    ai_inputs: data.ai_inputs,
  }
}

const formatNewInitialData = (initialData: any) => {
  if (size(initialData) === 0) return null

  const newIds = {}

  const getNewId = (id: string) => {
    if (!id) return null

    if (newIds[id]) return newIds[id]

    const newId = uuid()

    newIds[id] = newId

    return newId
  }

  return produce(initialData, (draft: any) => {
    delete draft.data.id
    delete draft.data.created_at
    delete draft.data.updated_at

    draft.data.status = 'draft'
    draft.data.external_id = getNewId(draft.data.external_id)

    if (draft.data.elements_order) {
      draft.data.elements_order = draft.data.elements_order?.map?.((id: string) => getNewId(id)) || []
    }

    // format pages
    for (const page of draft.data.form_pages_attributes) {
      delete page.id
      delete page.created_at
      delete page.updated_at

      page.uuid = getNewId(page.uuid)
      page.elements_order = page.elements_order?.map?.((id: string) => getNewId(id)) || []

      // format elements
      for (const element of page.form_blocks_attributes) {
        delete element.id
        delete element.created_at
        delete element.updated_at

        element.uuid = getNewId(element.uuid)
        element.parent_uuid = getNewId(element.parent_uuid)

        if (element.elements_order) {
          element.elements_order = element.elements_order.map((id: string) => getNewId(id))
        }

        if (element.category === 'conditional' && element.config?.element_uuid) {
          element.config.element_uuid = getNewId(element.config.element_uuid)
        }
      }
    }

    if (draft.data.form_variables_attributes) {
      // keep only form variables
      draft.data.form_variables_attributes = draft.data.form_variables_attributes.filter((variable: any) => variable.category === 'form')

      // format form variables
      for (const variable of draft.data.form_variables_attributes) {
        delete variable.id
        delete variable.created_at
        delete variable.updated_at

        variable.external_id = getNewId(variable.external_id)
      }
    }
  })
}

export const RootFormInternalTemplateOverlay = (props: any) => {
  const { allowEditing = true } = props

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    id,
    initialData,
    isDeleting,
    isEditable,
    isNew,
    isOverlayLoading,
    isSaving,
    saveWithData,
  } = useOverlay({
    name: 'internal-form-template',
    endpoint: '/internal_templates',
    invalidate: 'internal-templates',
    options: props,
  })

  const [apiData, setApiData] = React.useState(data)
  const [previousBuilderState, setPreviousBuilderState] = React.useState(null)

  const [status, setStatus] = React.useState('active')
  const [level, setLevel] = React.useState('behave_template')

  const hasInitialData = size(initialData) > 0

  const handleCancel = () => {
    setApiData({ ...data, updated_at: DateTime.local().toISO() })
    cancel()
  }

  React.useEffect(() => {
    if (isNew && initialData) {
      const newInitialData = formatNewInitialData(initialData)

      setApiData(newInitialData)
      setStatus(initialData?.status)
      setLevel(initialData?.level)

      return
    }

    if (!isNew && data) {
      setApiData(data)
      setStatus(data?.status)
      setLevel(data?.level)
    }
  }, [isNew, data, initialData])

  if (isOverlayLoading) return <OverlayLoader showBackdrop={isEditable} maxWidth={180} position="right" />

  if (!isNew && !apiData) return null

  if (isNew && hasInitialData && !apiData) return null

  return (
    <NewFormBuilderProvider
      key={`updated-${apiData?.updated_at}`}
      apiData={formatApiData(apiData)}
      isEditable={isEditable}
      isNew={isNew}
      previousBuilderState={previousBuilderState}
    >
      <InnerOverlay
        allowEditing={allowEditing}
        cancel={handleCancel}
        deleteRecord={deleteRecord}
        edit={edit}
        id={id}
        isDeleting={isDeleting}
        isEditable={isEditable}
        isNew={isNew}
        isSaving={isSaving}
        level={level}
        onClose={close}
        saveWithData={saveWithData}
        setLevel={setLevel}
        setPreviousBuilderState={setPreviousBuilderState}
        setStatus={setStatus}
        status={status}
        data={data}
      />
    </NewFormBuilderProvider>
  )
}

const InnerOverlay = (props: any) => {
  const {
    allowEditing,
    cancel,
    deleteRecord,
    edit,
    id,
    isDeleting,
    isEditable,
    isNew,
    isSaving,
    level,
    onClose,
    saveWithData,
    setLevel,
    setPreviousBuilderState,
    setStatus,
    status,
    data,
  } = props

  setHeadersTenant(BH_TENANT)

  const exitPreviewMode: any = useFormBuilder((state: any) => state.exitPreviewMode)
  const getCurrentBuilderState: any = useFormBuilder((state: any) => state.getCurrentBuilderState)
  const getFormattedApiData: any = useFormBuilder((state: any) => state.getFormattedApiData)
  const parseAllElementConfigVariables: any = useFormBuilder((state: any) => state.parseAllElementConfigVariables)
  const setIsEditable: any = useFormBuilder((state: any) => state.setIsEditable)
  const setActiveElementId: any = useFormBuilder((state: any) => state.setActiveElementId)

  const handleSave = async ({ keepEditing = false }) => {
    // save the current builder state to restore it after save
    // e.g. active page, active element, etc.
    const currentState = getCurrentBuilderState()

    if (currentState) {
      setPreviousBuilderState(currentState)
    }

    // get the formatted data from the builder to save
    const formattedData = await getFormattedApiData()

    await saveWithData(
      {
        data: formattedData,
        category: 'form',
        subcategory: 'general',
        status,
        level,
      },
      { keepEditing },
    )

    if (!keepEditing) setActiveElementId(null)

    exitPreviewMode()
  }

  React.useEffect(() => {
    setIsEditable(isEditable)
  }, [isEditable])

  React.useEffect(() => {
    parseAllElementConfigVariables()
  }, [])

  useFormBuilderHotkeys()

  return (
    <Overlay
      fullheight
      showBackdrop={isEditable}
      closeOnEscape={false}
      maxWidth={180}
      position="right"
      onClose={onClose}
      css={STYLES.root}
      isDirty={isEditable}
    >
      <Overlay.Header
        icon="web_form"
        title="Form Builder"
        titleAside={
          !isNew && (
            <>
              <div className="h-[16px] w-[1px] bg-divider !ml-3 !-mr-1" />

              <Dropdown label={<div className="flex items-center">Download Form PDF</div>} buttonType="link" glyph="download">
                <ExportPDFButton url={`/internal_templates/${id}/pdf`} label="Readonly PDF" css={dropdownItemStyles} />

                <ExportPDFButton
                  url={`/internal_templates/${id}/pdf`}
                  label="Editable PDF"
                  css={dropdownItemStyles}
                  params={{ editable: true }}
                />
              </Dropdown>
            </>
          )
        }
      />

      <Overlay.Content className="grid grid-rows-[100%] grid-cols-[100%] !overflow-hidden">
        <PanelGroup direction="horizontal">
          <Panel
            id="menu"
            maxSize={50}
            minSize={20}
            defaultSize={20}
            className="grid grid-rows-[100%] grid-cols-[100%] shadow-right-hard-3 z-[3] border-r border-0 border-solid border-divider"
          >
            <MainMenu
              isNew={isNew}
              allowEditing={allowEditing}
              deleteRecord={deleteRecord}
              isDeleting={isDeleting}
              onSave={handleSave}
              isSaving={isSaving}
              cancel={cancel}
              edit={edit}
              pagesBefore={
                <TreeItem title="Template Settings" isOpen={isNew} withHover={false}>
                  <div className="pb-4">
                    <InternalTemplateInputs
                      isEditable={isEditable}
                      level={level}
                      setLevel={setLevel}
                      setStatus={setStatus}
                      status={status}
                    />
                  </div>
                </TreeItem>
              }
            />
          </Panel>

          <PanelResize direction="vertical" />

          <Panel id="page" maxSize={80} defaultSize={60}>
            <ActivePage
              isEditable={isEditable}
              isNew={isNew}
              cancel={cancel}
              onSave={handleSave}
              isSaving={isSaving}
              edit={edit}
              allowEditing={allowEditing}
              data={data}
            />
          </Panel>
        </PanelGroup>
      </Overlay.Content>
    </Overlay>
  )
}

const InternalTemplateInputs = (props: any) => {
  const { isEditable, status, setStatus, level, setLevel } = props

  return (
    <Form isCompact>
      <div className="grid gap-4">
        <Select
          isCompact
          isEditable={isEditable}
          model="status"
          label="Status"
          fullWidth
          value={status}
          onUpdate={({ value }) => {
            setStatus(value)
          }}
          className="!flex-[1_1_140px]"
        >
          <Option label="Active" value="active" />
          <Option label="Archived" value="archived" />
        </Select>

        <Select
          isCompact
          isEditable={isEditable}
          model="level"
          label="Level"
          fullWidth
          value={level}
          onUpdate={({ value }) => {
            setLevel(value)
          }}
          className="!flex-[1_1_140px]"
        >
          <Option label="Behave Template" value="behave_template" />
          <Option label="Public Template" value="public_template" />
        </Select>
      </div>
    </Form>
  )
}

const dropdownItemStyles = {
  ...styles(COLORS.blue),
  display: 'flex !important',
}

const STYLES = {
  root: {
    '--header-height': '2.8rem',
    '--header-background': COLORS.white,
    '--subheader-height': '2.4rem',
    '--subheader-background': lighten(0.01, '#F7F8FB'),
    '--main-menu-width': '440px',
    '--field-max-width': '100% !important',
  },
}

export const FormInternalTemplateOverlay = withOverlayError(RootFormInternalTemplateOverlay)
