import React from 'react'

import { possessiveNoun } from '../../utils/functions'
import { withOverlayError } from '../../hocs/withOverlayError'

import { P, Strong } from '../../components/Typography'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import ClientCard from '../../components/Cards/Client'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

const RootDuplicateClientOverlay = ({ client, close, save, createNew }) => {
  console.log('client', client)

  const name = `${client?.first_name} ${client?.last_name}`
  const possessiveName = possessiveNoun(client?.first_name)

  if (!client) return null

  return (
    <Overlay position="center" showBackdrop={true} onClose={close}>
      <Overlay.Header icon="clients" title="Duplicate Profile Found" />
      <Overlay.Content>
        <Section>
          <Grid gap={16}>
            <Alert contrast type="positive" glyph="tick_circle">
              <P className="!m-0">
                <Strong>{name} already has a profile</Strong>
              </P>
              <P className="!m-0">Save the application below and attach it to the existing profile</P>
            </Alert>

            <ClientCard client={client} />

            <Button
              label={`Save Application to ${possessiveName} profile`}
              type="primary"
              color="green"
              onClick={() => {
                close()
                save()
              }}
            />

            <Button
              label={'Create new Profile'}
              type="default"
              onClick={() => {
                close()
                createNew()
              }}
            />
          </Grid>
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}

export const DuplicateClientOverlay = withOverlayError(RootDuplicateClientOverlay)
