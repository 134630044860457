import React from 'react'

import Input from '../../../../Forms/Input'
import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import ContextShow from '../../../../Forms/ContextShow'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'

import VitalSignsForm from './VitalSignsForm'

const BioMedicalV1 = () => {
  return (
    <FormSection layout="vertical">
      <VitalSignsForm showDateTime={false} showPainScale={false} showComments={false} requireHeight />

      <RadioGroup
        model="data.bio_medical.general_condition"
        label="What is the general condition of the client's health?"
        layout="horizontal"
      >
        <Radio label="Poor" value="poor" />
        <Radio label="Average" value="average" />
        <Radio label="Good" value="good" />
      </RadioGroup>

      <Input
        model="data.bio_medical.ongoing_conditions"
        label="Does the client have any ongoing medical conditions (i.e., asthma, high blood pressure, diabetes, chronic pain, etc.)?"
      />

      <Input model="data.bio_medical.undiagnosed_medical_complaints" label="Does the client have any undiagnosed medical complaints?" />

      <RadioGroup
        model="data.bio_medical.had_seizure"
        label="Has the client ever had a seizure as a result of substance abuse?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.had_seizure" is={true}>
        <SmartTextarea
          useDictation
          label="In the box list the circumstance that precipitated the seizure, the amount of episodes, approximate date(s) and if hospitalization was needed:"
          model="data.bio_medical.had_seizure_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.bio_medical.had_dts"
        label="Has the client ever experienced DT's (delirium tremens)?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.had_dts" is={true}>
        <CheckboxGroup label="Check all that apply:" layout="vertical-dense">
          <Checkbox label="Tremors" model="data.bio_medical.dts.dt_tremors" />
          <Checkbox label="Nightmares" model="data.bio_medical.dts.dt_nightmares" />
          <Checkbox label="Confusion" model="data.bio_medical.dts.dt_confusion" />
          <Checkbox label="Paranoia" model="data.bio_medical.dts.dt_paranoia" />
          <Checkbox label="Other" model="data.bio_medical.dts.dt_other" />
        </CheckboxGroup>
      </ContextShow>

      <ContextShow when="data.bio_medical.dts.dt_other" is={true}>
        <SmartTextarea
          useDictation
          label="Other"
          model="data.bio_medical.dts.dt_other_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup model="data.bio_medical.has_overdosed" label="Has the client ever overdosed on any substance?" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.has_overdosed" is={true}>
        <SmartTextarea
          useDictation
          label="Please list approximate dates and substance(s):"
          model="data.bio_medical.overdosed_substances"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        label="Was the client chemically revived (with Narcan, etc.)?"
        model="data.bio_medical.has_been_chemically_revived"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.has_been_chemically_revived" is={true}>
        <SmartTextarea
          useDictation
          label="Please give a short description of event:"
          model="data.bio_medical.has_been_chemically_revived_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.bio_medical.sleep_without_substances"
        label="How is the client's sleep without alcohol, drugs, or medication?"
        layout="horizontal"
      >
        <Radio label="Poor" value="poor" />
        <Radio label="Average" value="average" />
        <Radio label="Good" value="good" />
      </RadioGroup>

      <Input model="data.bio_medical.sleep_hours" label="How many hours does the client sleep in a night?" size={2} suffix="hours" />

      <CheckboxGroup label="Does the client have trouble falling asleep or staying asleep?" layout="vertical-dense">
        <Checkbox label="Falling asleep" model="data.bio_medical.sleep.trouble_falling_asleep" />
        <Checkbox label="Staying asleep" model="data.bio_medical.sleep.trouble_staying_asleep" />
        <Checkbox label="Waking up frequently" model="data.bio_medical.sleep.trouble_waking_up_frequently" />
        <Checkbox label="Nightmares" model="data.bio_medical.sleep.trouble_nightmares" />
        <Checkbox label="Using dreams" model="data.bio_medical.sleep.trouble_using_dreams" />
      </CheckboxGroup>

      <Input model="data.bio_medical.meals_per_day" label="How many meals per day is the client eating?" suffix="meals" size={2} />

      <Input
        model="data.bio_medical.percentage_meals_eating"
        label="What percentage of meals is the client currently eating?"
        suffix="%"
        size={2}
      />

      <RadioGroup
        model="data.bio_medical.has_significant_weight_changes"
        label="Have there been any significant changes in the client's weight recently?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.has_significant_weight_changes" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.bio_medical.has_significant_weight_changes_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <SmartTextarea
        useDictation
        label="Please list the doctors/therapists/all outpatient providers the client has seen recently (including the person who prescribed their medications)"
        model="data.bio_medical.list_of_providers"
      />

      <RadioGroup
        model="data.bio_medical.has_ben_hospitalized_for_mental_illness"
        label="Has the client ever been hospitalized for mental illness?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.has_ben_hospitalized_for_mental_illness" is={true}>
        <SmartTextarea
          useDictation
          label="Please list the reason, duration and location of treatment:"
          model="data.bio_medical.has_ben_hospitalized_for_mental_illness_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.bio_medical.has_ben_hospitalized"
        label="Has the client been hospitalized for any reason within the last 30 days?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.has_ben_hospitalized" is={true}>
        <SmartTextarea
          useDictation
          label="Please list the reason, duration and location of treatment:"
          model="data.bio_medical.has_ben_hospitalized_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>
    </FormSection>
  )
}

export default BioMedicalV1
