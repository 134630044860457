import size from 'lodash/size'

export const getClientAttendance = (clientId: string, attendances: any[]) => {
  const result: any = { is_attending: true }

  if (!clientId || size(attendances) === 0) return result

  const attendance = attendances.find((a) => a.resident?.id === clientId)

  if (!attendance) return result

  if (attendance.status === 'present') {
    result.present_status = attendance.present_status
  } else if (attendance.status === 'absent') {
    result.is_attending = false
    result.absentee_note = attendance.absentee_notes
    result.absent_status = attendance.absent_status
  }

  return result
}
