import React from 'react'

import SmartStatus from '../SmartStatus'

export const statuses = {
  reserved: {
    label: 'Reserved',
    color: 'yellow',
  },
  occupied: {
    label: 'Occupied',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
