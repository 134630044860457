import React from 'react'
import { tint } from 'polished'
import * as Popover from '@radix-ui/react-popover'
import size from 'lodash/size'

import { COLORS, SHADOW } from '../../theme'
import { countWord } from '../../utils/functions'
import { usePortal } from '../../hooks/usePortal'

import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Permission from '../../components/Permission'

import { BooleanFilter } from './filters/BooleanFilter'
import { DateTimeFilter } from './filters/DateTimeFilter'
import { GlobalTagsFilter } from './filters/GlobalTagsFilter'
import { MultiObjectFilter } from './filters/MultiObjectFilter'
import { MultiSelectFilter } from './filters/MultiSelectFilter'
import { NumberFilter } from './filters/NumberFilter'
import { StringFilter } from './filters/StringFilter'
import { TagsFilter } from './filters/TagsFilter'

import { useFilters } from './useFilters'
import { useSettings } from '../../hooks/useSettings'

export const FILTERS: any = {
  boolean: BooleanFilter,
  date_time: DateTimeFilter,
  global_tags: GlobalTagsFilter,
  multi_object: MultiObjectFilter,
  multi_select: MultiSelectFilter,
  number: NumberFilter,
  string: StringFilter,
  tags: TagsFilter,
}

export const Filters = ({ config, onUpdate, localStorageKey, defaultFilters, onClear }: any) => {
  const { addFilter, filters, isEmpty, removeFilter, resetFilters, setFilter } = useFilters(onUpdate, localStorageKey, defaultFilters)
  const { timezone } = useSettings()

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(true)

  const portalContainer = usePortal('radix')

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const toggleFiltersOpen = () => {
    setIsFiltersOpen((c) => !c)
  }

  if (!config) return null

  const activeFilters = Object.keys(filters)
  const activeFiltersCount = size(activeFilters)
  const hasActiveFilters = activeFiltersCount >= 1

  return (
    <Popover.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
      <div css={styles.root}>
        <Flex centerY gap="0.5rem" css={styles.actions}>
          <Popover.Trigger asChild>
            <div>
              <Button
                label="Add Filter"
                glyph="filter"
                display="inline-flex"
                type="primary"
                size={200}
                color="green"
                css={styles.actionButton}
              />
            </div>
          </Popover.Trigger>

          {/* {hasActiveFilters && (
            <Button
              label={isFiltersOpen ? 'Hide Filters' : `Show ${countWord('Filters', activeFiltersCount)}`}
              glyph="view"
              display="inline-flex"
              type="minimal"
              size={100}
              css={styles.actionButton}
              onClick={toggleFiltersOpen}
            />
          )} */}

          <div className="mx-auto flex-[1_1_auto]">
            {hasActiveFilters && (
              <Flex centerY gap="0.5rem" css={styles.filtersWrapper} className={isFiltersOpen ? '' : 'hide-filters'}>
                {activeFilters.map((key) => {
                  const FilterTag = FILTERS?.[config?.[key]?.type]

                  if (!FilterTag) return null

                  return (
                    <FilterTag
                      key={key}
                      config={config[key]}
                      filter={filters[key]}
                      timezone={timezone}
                      onClear={() => {
                        removeFilter(key)
                        onClear?.(key)
                      }}
                      onUpdate={(value: any) => {
                        setFilter(key, value)
                      }}
                    />
                  )
                })}
              </Flex>
            )}
          </div>

          {!isEmpty && (
            <Button
              label="Clear All Filters"
              glyph="cross"
              color="red"
              type="primary"
              // glyphColor="red"
              display="inline-flex"
              size={100}
              css={styles.actionButton}
              onClick={resetFilters}
            />
          )}
        </Flex>

        <Popover.Portal container={portalContainer}>
          <Popover.Content asChild side="bottom" align="start" sticky="always">
            <div css={styles.menu}>
              {Object.keys(config).map((key) => {
                const filter = config[key]
                const isActive = activeFilters.includes(key)

                return (
                  <div
                    key={key}
                    onClick={() => {
                      if (isActive) {
                        removeFilter(key)
                        return
                      }

                      addFilter(key, '')
                      closeMenu()
                    }}
                    css={styles.menuItem}
                    className={isActive ? 'is-active' : ''}
                  >
                    <Glyph glyph={isActive ? 'check' : filter.glyph || 'filter'} color={isActive ? COLORS.green : COLORS.blue} size={14} />

                    <span>{filter.label}</span>

                    {isActive && (
                      <div css={styles.clearButton} onClick={() => removeFilter(key)}>
                        <Glyph glyph="circle_error" size={14} color={COLORS.textMuted} />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </div>
    </Popover.Root>
  )
}

const styles = {
  root: {
    '--filter-height': '1.6rem',
  },

  menu: {
    border: `1px solid ${COLORS.divider}`,
    boxShadow: SHADOW(5),
    background: COLORS.white,
    width: 220,
    borderRadius: 5,
    outline: 'none',
    maxHeight: '80vh',
    overflowY: 'auto',
    zIndex: 3,
    position: 'relative',
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.3rem 0.5rem',
    cursor: 'pointer',
    fontSize: '0.94rem',
    fontWeight: 500,

    '&:hover': {
      color: COLORS.blue,
      background: COLORS.hover,
    },

    '&.is-active': {
      backgroundColor: tint(0.92, COLORS.green),

      '&:hover': {
        color: COLORS.text,
        backgroundColor: tint(0.92, COLORS.green),
      },
    },

    '& > svg': { marginRight: '0.5rem' },
  },

  actionButton: {
    fontWeight: 600,
    height: 'var(--filter-height)',

    svg: {
      width: 14,
      height: 14,
      margin: '2px !important',
    },
  },

  actions: {
    marginBottom: '0.75rem',
  },

  filtersWrapper: {
    '&.hide-filters': {
      display: 'none',
    },
  },

  clearButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '2rem',
    marginLeft: 'auto',

    svg: {
      transition: 'transform 80ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    },

    '&:hover': {
      svg: {
        fill: COLORS.red,
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
    },
  },
}
