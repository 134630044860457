import React from 'react'

import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Status from '../../components/Status'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const BreathalyzerTestsReportDataTable = (props: any) => {
  const { to } = props

  const { timezone, user } = useSettings()

  const isBehave = user?.type === 'bh_employee'

  const billingColumn = (timezone: string) => ({
    width: 140,
    id: 'billed_at',
    model: 'data.billed_at',
    title: 'Billed',
    type: 'date_time',
    disableSort: false,
  })

  const columns = React.useMemo(() => {
    return [
      {
        id: 'collected_at',
        title: 'Date & Time',
        model: 'data.collected_at',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.data.id} value={usDateTime(value, timezone)} to={to?.(data.data)} />,
      },
      ...(isBehave ? [billingColumn(timezone)] : []),
      {
        id: 'resident',
        title: 'Client',
        model: 'data.resident',
        type: 'profile',
      },
      {
        id: 'status',
        width: 180,
        model: 'data.status',
        title: 'Status',
        // NOTE @Andrei: Implement NotApplicable component
        formatValue: ({ data, value }) => {
          if (value === 'refused') return <Status color="red" label="Refused Collection" />
          if (value === 'collected') return <Status color="blue" label="Collected" />
          return 'N/A'
          // return <TableCell.NotApplicable />
        },
      },
      {
        id: 'result',
        model: 'data.result',
        width: 120,
        title: 'Result',
        formatValue: ({ data, value }) => {
          if (value === 'positive') return <Status color="red" label="Positive" />
          if (value === 'negative') return <Status color="green" label="Negative" />
          if (value === 'not_available') return <Status color="grey" label="N/A" />
          return '–'
        },
      },
      {
        id: 'value',
        model: 'data.value',
        width: 180,
        title: 'Result Value',
        formatValue: ({ data, value }) => {
          // NOTE @Andrei: Implement NotApplicable component
          // if (row.original.result === 'negative') return <TableCell.NotApplicable />
          if (data.data.result === 'negative') return 'N/A'
          return value
        },
      },
      {
        id: 'notes',
        model: 'data.notes',
        width: 200,
        title: 'Notes',
      },
      {
        id: 'author',
        width: 180,
        model: 'data.author.name',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to, timezone, isBehave])

  return (
    <DataTable
      asCard
      title="Breathalyzer Tests"
      icon="test_results"
      columns={columns}
      filtersConfig={FILTERS.breathalyzer_tests}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="breathalyzer_tests"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=breathalyzer_tests'}
        />
      }
      {...props}
    />
  )
}
