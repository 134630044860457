import React from 'react'

import Switch from '../../../components/Forms/Switch'
import Section from '../../../components/Section'
import Divider from '../../../components/Divider'

import { InlineInsuranceBillingFormSection } from './InlineInsuranceBillingFormSection'

export const InsuranceBillingSection = ({ initialModel, isNew, model = 'insurance_new_code' }: any) => {
  const [isBillable, setIsBillable] = React.useState(initialModel?.is_billable || false)

  return (
    <>
      <Section
        headingType="h2"
        title="Billing"
        description="Use this section to record the billable service"
        aside={<Switch defaultValue={false} model="is_billable" onUpdate={(state: any) => setIsBillable(state.value)} />}
      >
        {isBillable && <InlineInsuranceBillingFormSection isBillable isNew={isNew} model={model} initialModel={initialModel} />}
      </Section>

      <Divider />
    </>
  )
}
