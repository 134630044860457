import React from 'react'
import { DateTime } from 'luxon'
import { tint } from 'polished'
import { useMedia } from 'use-media'
import * as HoverCard from '@radix-ui/react-hover-card'
import clsx from 'clsx'
import pluralize from 'pluralize'
import size from 'lodash/size'

import { css, COLORS } from '../../theme'
import { age, DT, usDate, titleCase, getClientLink } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Avatar from '../../components/Avatar'
import BedOccupancyStatus from '../../components/Statuses/BedOccupancyStatus'
import Button from '../../components/Button'
import Card from '../../components/Card'
import ClientStatus from '../../components/Statuses/ClientStatus'
import DataList from '../../components/DataList'
import DateInput from '../../components/Forms/DateInput'
import DatePickerSelector from '../../components/Forms/DatePickerSelector'
import Flex from '../../components/Flex'
import GridTable from '../../components/GridTable'
import Icon from '../../components/Icon'
import Link from '../../components/Link'
import Loader from '../../components/Loader'

import { HoverCardRoot, HoverCardContent } from './BedsTimeline'

export const BedsBoard = (props: any) => {
  const { isLoading, isRefetching, className, setDate, houses } = props

  const { timezone } = useSettings()

  const today = DateTime.local().setZone(timezone)
  const [currentDate, setCurrentDate] = React.useState(today)

  const [cachedHouses, setCachedHouses] = React.useState(houses)
  const [cachedBedsCount, setCachedBedsCount] = React.useState(0)

  const isDesktop = useMedia({ minWidth: 600 })
  const rootClasses = clsx(STYLES.root().className, className)

  const isHousesEmpty = size(cachedHouses) === 0
  const maxBedsCount = getMaxBedsCount(houses)
  const bedIndicesArray = Array.from(Array(cachedBedsCount).keys())

  const isToday = usDate(currentDate) === usDate(today)

  const onToday = () => {
    setCurrentDate(today)
  }

  const onPrev = () => {
    setCurrentDate((current) => current.minus({ days: 1 }))
  }

  const onNext = () => {
    setCurrentDate((current) => current.plus({ days: 1 }))
  }

  React.useEffect(() => {
    if (!currentDate) return

    setDate(DT(currentDate, timezone).toISODate())
  }, [currentDate, timezone])

  React.useEffect(() => {
    if (!houses) return

    setCachedHouses(houses)
  }, [houses])

  React.useEffect(() => {
    if (!maxBedsCount) return

    setCachedBedsCount(maxBedsCount)
  }, [maxBedsCount])

  return (
    <Card className={rootClasses}>
      <GridTable firstColumnSticky={isDesktop} templateColumns="280px" columnsWidth="80px" className={STYLES.table().className}>
        <GridTable.Header className={STYLES.tableHeader().className}>
          <GridTable.Cell className={STYLES.tableHeaderCell().className}>
            <div className={STYLES.heading()}>
              <span className={STYLES.dayMonth()}>{currentDate.toFormat('LLL dd')}</span>
              <span className={STYLES.year()}> {currentDate.toFormat('kkkk')}</span>
            </div>

            <DatePickerSelector
              onChange={(date) => {
                if (!date) return

                setCurrentDate(DateTime.fromJSDate(date).setZone(timezone))
              }}
              trigger={<Button hideLabel color="blue" glyph="date" size={100} css={STYLES.navButton} isDisabled={isLoading} />}
            />

            {(isLoading || isRefetching) && <Loader css={STYLES.loader} color={COLORS.blue} size={15} />}

            <Flex nowrap gap="0.25rem" css={STYLES.headerActions}>
              {!isToday && <Button color="text" label="Today" size={100} onClick={onToday} css={STYLES.navButton} isDisabled={isLoading} />}

              <Flex nowrap css={STYLES.buttonsGroup}>
                <Button
                  hideLabel
                  color="text"
                  size={100}
                  glyph="chevron_left"
                  glyphSize="1em"
                  onClick={onPrev}
                  css={STYLES.navButton}
                  isDisabled={isLoading}
                />
                <Button
                  hideLabel
                  color="text"
                  size={100}
                  glyph="chevron_right"
                  glyphSize="1em"
                  onClick={onNext}
                  css={STYLES.navButton}
                  isDisabled={isLoading}
                />
              </Flex>
            </Flex>

            <div css={{ display: 'none' }}>
              <DateInput
                defaultToNow
                allowInput={false}
                withHover={false}
                timezone={timezone}
                model="date"
                onUpdate={({ value }) => {
                  if (!value) return

                  setDate(DT(value, timezone).toISODate())
                }}
              />
            </div>
          </GridTable.Cell>

          {!isHousesEmpty &&
            bedIndicesArray.map((bedIndex) => (
              <GridTable.Cell key={bedIndex} className="header-cell">
                <Flex centerY nowrap gap="0.75rem">
                  <Icon icon="beds" size={20} />#{bedIndex + 1}
                </Flex>
              </GridTable.Cell>
            ))}
        </GridTable.Header>

        <div className={STYLES.equalRowsGrid()}>
          {!isHousesEmpty &&
            cachedHouses.map((house: any) => (
              <GridTable.Row className={isLoading ? 'is-loading' : ''}>
                <GridTable.Cell className="side-header-cell">
                  <Flex centerY nowrap gap="0.5rem">
                    <Avatar src={house.avatar} initials={house.name} size={24} />
                    <div>{house.name}</div>
                  </Flex>
                </GridTable.Cell>

                {bedIndicesArray.map((bedIndex) => (
                  <BedsBoardCell key={`${house.id}-${bedIndex}`} bed={house.beds[bedIndex]} house={house} />
                ))}
              </GridTable.Row>
            ))}
        </div>
      </GridTable>
    </Card>
  )
}

const BedsBoardCell = ({ bed, house }: any) => {
  if (!bed || !house) return <GridTable.Cell className="no-bed" />

  const clientsCount = size(bed.clients)

  return (
    <HoverCardRoot>
      <HoverCard.Trigger asChild>
        <GridTable.Cell className={`${bed.status}-bed`}>{titleCase(bed.status)}</GridTable.Cell>
      </HoverCard.Trigger>

      <HoverCardContent>
        <DataList isCompact withPadding labelWidth={80}>
          <DataList.Item
            label="Location"
            value={
              <Link to={`/locations/${house.id}`} css={STYLES.resourceLink}>
                <Flex centerY gap="0.25rem">
                  <Avatar src={house.avatar} initials={house.name} size={18} />
                  <div css={STYLES.resourceLinkTitle}>{house.name}</div>
                </Flex>
              </Link>
            }
          />

          <DataList.Item
            label="Bed"
            value={
              <Flex centerY gap="0.25rem">
                <Icon icon="beds" size={18} />
                <div>{bed.name}</div>
              </Flex>
            }
          />

          {bed?.room && (
            <DataList.Item
              label="Room"
              value={
                <Flex centerY gap="0.25rem">
                  <Icon icon="rooms" size={18} />
                  <div>{bed?.room?.name}</div>
                </Flex>
              }
            />
          )}

          {bed?.floor && (
            <DataList.Item
              label="Floor"
              value={
                <Flex centerY gap="0.25rem">
                  <Icon icon="floors" size={18} />
                  <div>{bed?.floor?.name}</div>
                </Flex>
              }
            />
          )}

          <DataList.Item label="Status" value={<BedOccupancyStatus small status={bed.status} />} />

          {clientsCount > 0 && (
            <DataList.Item
              label={pluralize('Client', clientsCount)}
              value={
                <>
                  <Flex centerY gap="0.5rem">
                    {bed.clients.map((client: any) => (
                      <Link key={client.id} to={getClientLink(client)} css={STYLES.resourceLink}>
                        <Flex centerY nowrap gap="0.25rem">
                          <Avatar src={client.avatar} initials={client.name} size={18} />
                          <div css={STYLES.resourceLinkTitle}>{client.name}</div>
                          <ClientStatus small status={client.status} />
                        </Flex>

                        <div css={STYLES.resourceLinkDescription}>
                          {`${titleCase(client.sex)}, ${age(client.dob)} y/o, #${client.behave_id}`}
                        </div>
                      </Link>
                    ))}
                  </Flex>
                </>
              }
            />
          )}
        </DataList>
      </HoverCardContent>
    </HoverCardRoot>
  )
}

const getMaxBedsCount = (houses: any) => {
  let result = 0

  if (size(houses) === 0) return result

  for (const house of houses) {
    const bedsCount = size(house.beds)

    if (bedsCount > result) result = bedsCount
  }

  return result
}

const STYLES = {
  root: css({
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
  }),

  heading: css({
    fontSize: '1.05rem',
    fontWeight: 400,
    marginRight: '0.25rem',
  }),

  dayMonth: css({
    fontWeight: 700,
  }),

  year: css({
    fontSize: '0.92em',
    color: COLORS.textMuted,
  }),

  table: css({
    overflow: 'auto !important',

    '.header-cell': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.94rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '& *': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },

    '.is-loading > *': {
      opacity: 0.35,
    },

    '.side-header-cell': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: '0.94rem',
      paddingLeft: '0.55rem',
      opacity: '1 !important',
    },

    '.no-bed': {
      background: `${COLORS.white} !important`,
    },

    '.empty-bed': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.95rem',
      // fontStyle: 'italic',
      color: COLORS.textMuted,
      background: `${tint(0.88, COLORS.green)} !important`,
    },

    '.occupied-bed': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.95rem',
      // fontStyle: 'italic',
      color: COLORS.textMuted,
      background: `${tint(0.7, COLORS.red)} !important`,
    },

    '.reserved-bed': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.95rem',
      // fontStyle: 'italic',
      color: COLORS.textMuted,
      background: `${tint(0.8, COLORS.gold)} !important`,
    },
  }),

  tableHeader: css({
    position: 'sticky',
    top: 0,
    zIndex: 3,
  }),

  tableHeaderCell: css({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingLeft: '0.95rem',
  }),

  equalRowsGrid: css({
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: '1fr',
  }),

  loadingState: {
    minHeight: '200px !important',
  },

  navButton: {
    minHeight: 24,
    padding: '0.2em 0.5em',
  },

  buttonsGroup: {
    '& > *': {
      marginLeft: -1,
      borderRadius: '0 !important',

      '&:first-child': {
        borderRadius: '5px 0 0 5px !important',
      },

      '&:last-child': {
        borderRadius: '0 5px 5px 0 !important',
      },
    },
  },

  headerActions: {
    marginLeft: 'auto !important',
  },

  loader: {
    marginLeft: '0.5rem',
  },

  resourceLink: {
    display: 'block',
    textDecoration: 'none',
  },

  resourceLinkTitle: {
    fontWeight: 600,
    color: COLORS.blue,
  },

  resourceLinkDescription: {
    fontWeight: 400,
    color: COLORS.textMuted,
  },
}
