import React from 'react'
import { useParams } from 'react-router-dom'

import { APP_URLS } from '@behavehealth/constructs/MyAccount/utils'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Card from '@behavehealth/components/Card'
import PageHeader from '@behavehealth/components/PageHeader'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import Glyph from '@behavehealth/components/Glyph'
import Icon from '@behavehealth/components/Icon'
import Flex from '@behavehealth/components/Flex'
import Button from '@behavehealth/components/Button'

export const RootCommunitySetup = () => {
  const params: any = useParams()
  const { resource_id: id } = params

  const {
    data: organization,
    isLoading,
    isError,
    error,
  }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  if (!organization || isLoading) return <PageLoader />

  const slug = organization?.other_profile?.slug

  const communityURL = slug && `${APP_URLS.community}/profiles/${slug}`

  return (
    <div css={STYLES.root}>
      <PageHeader title="Join the Community" icon="community_profile" />

      <div css={STYLES.content}>
        <Card>
          <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                <div>
                  <Flex gap="1rem" alignItems="center">
                    <Icon size={48} icon="community" />
                    <div>
                      <h2 className="text-base font-semibold leading-7 text-indigo-600">Step 1.</h2>
                      <div className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Joining the Behave Community</div>
                    </div>
                  </Flex>

                  <p className="mt-6 text-base leading-7 text-gray-600">
                    Joining the Behave Community is your first step into a world of endless opportunities. This platform is designed for
                    Organizations seeking to expand their horizons and tap into a diverse marketplace. When you sign up, you're not just
                    accessing a service; you're becoming part of a vibrant, collaborative ecosystem.
                  </p>

                  <p>Welcome aboard, where your journey towards growth and collaboration begins.</p>
                </div>

                <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-2 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                  <Flex horizontal nowrap gap="1rem">
                    <Glyph glyph="tick" className="h-5 w-5 !mt-[30px]" />

                    <Flex vertical gap="0.5rem" className="inline">
                      <dt className="font-semibold text-gray-900">Submit Community Join Request</dt>

                      <dd className="ms-0 mt-2">
                        Send us information about <b>{organization.name}</b> and we'll reach out to them to get you connected
                      </dd>

                      <Button label={`Submit Details about ${organization.name}…`} type="primary" color="green" className="w-fit" />
                    </Flex>
                  </Flex>
                </dl>

                <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-2 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                  <div className="relative">
                    <Flex nowrap gap="1rem" alignItems="center">
                      <Glyph glyph="circle_outline" className="h-5 w-5" color="gray" />

                      <Flex vertical gap="0.5rem" className="inline">
                        <dt className="font-semibold text-gray-900">Behave Health Reviewing Join Request</dt>
                        <dd className="ms-0 mt-2">
                          We are reviewing your submitted information. We are going to let you know when we've added this Organization to
                          the Community.
                        </dd>

                        <Button
                          glyph="behave_health"
                          label={`Review ${organization.name}…`}
                          type="primary"
                          color="blue"
                          className="w-fit"
                        />
                      </Flex>
                    </Flex>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          <div className="bg-white py-24 sm:py-32 opacity-50">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                <div>
                  <Flex nowrap gap="1rem" alignItems="center">
                    <Icon size={48} icon="connections" />
                    <div>
                      <h2 className="text-base font-semibold leading-7 text-indigo-600">Step 2.</h2>
                      <div className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Connecting with Members</div>
                    </div>
                  </Flex>

                  <p className="mt-6 text-base leading-7 text-gray-600">
                    Step 2 is all about building connections within the Behave Community. Here, you'll find a network of like-minded
                    organizations and professionals, each bringing their unique skills and services to the table. Utilizing our advanced
                    matching algorithms, we make it easy for you to identify and reach out to members who align with your business goals and
                    values. Whether it's through participating in community discussions or engaging in direct messaging our platform offers
                    various avenues for you to interact and forge productive relationships. Connect, collaborate, and create with peers who
                    are just as passionate about innovation and success as you are.
                  </p>
                </div>

                <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-2 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                  <div className="relative">
                    <Flex nowrap gap="1rem" alignItems="center">
                      <Glyph glyph="tick" className="h-5 w-5" />

                      <div>
                        <dt className="font-semibold text-gray-900">Invite to Partner Up</dt>
                        <dd className="ms-0 mt-2">Rerum repellat labore necessitatibus reprehenderit molestiae praesentium.</dd>
                      </div>
                    </Flex>
                  </div>
                </dl>

                <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-2 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                  <div className="relative">
                    <Flex nowrap gap="1rem" alignItems="center">
                      <Glyph glyph="circle_outline" className="h-5 w-5" />

                      <div>
                        <dt className="font-semibold text-gray-900">Behave Health Reviewing Request</dt>
                        <dd className="ms-0 mt-2">Rerum repellat labore necessitatibus reprehenderit molestiae praesentium.</dd>
                      </div>
                    </Flex>
                  </div>
                </dl>

                <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-2 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                  <div className="relative">
                    <Flex nowrap gap="1rem" alignItems="center">
                      <Glyph glyph="circle_outline" className="h-5 w-5" />

                      <div>
                        <dt className="font-semibold text-gray-900">{organization.name} Reviewing Your Invite</dt>
                        <dd className="ms-0 mt-2">Rerum repellat labore necessitatibus reprehenderit molestiae praesentium.</dd>
                      </div>
                    </Flex>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          <div className="bg-white py-24 sm:py-32 opacity-50">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                <div>
                  <Flex nowrap gap="1rem" alignItems="center">
                    <Icon size={48} icon="community_partners" />
                    <div>
                      <h2 className="text-base font-semibold leading-7 text-indigo-600">Step 3.</h2>
                      <div className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Managing your Partnerships</div>
                    </div>
                  </Flex>

                  <p className="mt-6 text-base leading-7 text-gray-600">
                    In the third step, you take charge of nurturing and managing the partnerships you've formed within the Behave Community.
                    Our platform offers a comprehensive suite of tools designed to streamline the management of these connections. Track
                    your interactions, set up meetings, manage and share Bed Management Availability and evaluate the success of each
                    partnership with ease. The dashboard provides real-time insights into your collaborations, allowing you to make informed
                    decisions and adjustments as needed. Here, effective partnership management means more than just maintaining
                    connections; it's about maximizing the potential of each relationship for mutual growth and success.
                  </p>
                </div>

                <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-2 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                  <div className="relative">
                    <Flex nowrap gap="1rem" alignItems="center">
                      <Glyph glyph="tick" className="h-5 w-5" />

                      <div>
                        <dt className="font-semibold text-gray-900">Review Your Data Sharing Settings</dt>
                        <dd className="ms-0 mt-2">Rerum repellat labore necessitatibus reprehenderit molestiae praesentium.</dd>
                      </div>
                    </Flex>
                  </div>
                </dl>

                <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-2 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                  <div className="relative">
                    <Flex nowrap gap="1rem" alignItems="center">
                      <Glyph glyph="circle_outline" className="h-5 w-5" />

                      <div>
                        <dt className="font-semibold text-gray-900">Invite team members</dt>
                        <dd className="ms-0 mt-2">Rerum repellat labore necessitatibus reprehenderit molestiae praesentium.</dd>
                      </div>
                    </Flex>
                  </div>
                </dl>

                <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-2 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                  <div className="relative">
                    <Flex nowrap gap="1rem" alignItems="center">
                      <Glyph glyph="circle_outline" className="h-5 w-5" />

                      <div>
                        <dt className="font-semibold text-gray-900">Invite team members</dt>
                        <dd className="ms-0 mt-2">Rerum repellat labore necessitatibus reprehenderit molestiae praesentium.</dd>
                      </div>
                    </Flex>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    overflow: 'hidden',
    height: '100%',
  },

  content: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
    padding: '1rem',
  },
}

export const CommunitySetup = withPageError(RootCommunitySetup)
