import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { Card, Grid, Page } from '@behavehealth/components'
import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'

import { StaffJourneyTimeline } from '@behavehealth/constructs/StaffJourney/StaffJourneyTimeline'
import PageHeader from '@behavehealth/components/PageHeader'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

const StaffJourney = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const [dates, setDates]: any = React.useState({})
  const [filters, setFilters] = React.useState({})

  const hasDates = !!dates?.startDate && !!dates?.endDate

  const queryParams = {
    filters,
    ...(hasDates && {
      from: dates.startDate,
      until: dates.endDate,
    }),
  }

  const { data, isLoading, isRefetching }: any = useGet({
    name: ['employee', resource_id, 'staff-journey', queryParams],
    url: `/employees/${resource_id}/staff_journey`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: hasDates },
  })

  return (
    <div css={STYLES.root}>
      <PageHeader showFeedbackTool title="Staff Journey" icon="staff_journey" />

      <main css={STYLES.main}>
        <StaffJourneyTimeline data={data} dates={dates} setDates={setDates} isLoading={isLoading} isRefetching={isRefetching} />
      </main>
    </div>
  )
}

const STYLES: any = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },

  main: {
    display: 'grid',
    padding: '1rem',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    overflow: 'hidden',
  },
}

export default StaffJourney
