import React from 'react'
import { useParams } from 'react-router-dom'

import { apiUpdate } from '../../../modules/api'
import withSettings from '../../../hocs/withSettings'

import Alert from '../../../components/Alert'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import EmailInput from '../../../components/Forms/EmailInput'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import PhoneInput from '../../../components/Forms/PhoneInput'
import Workflow from '../../../components/Workflow/Workflow'

const ContactDetails = ({ client, setStatus, timezone }: any) => {
  const { resource_id }: any = useParams()
  const { goNext }: any = React.useContext(Workflow.Context)

  const form = React.useRef()
  const [requestLoading, setRequestLoading] = React.useState(false)

  const onContinue = async () => {
    //setRequestLoading(true)

    //await apiUpdate({ name: 'clients', url: `/residents/${resource_id}`, params: form.current?.getFormValue() })

    //setRequestLoading(false)

    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  return (
    <Form useLocalModel getForm={form} initialModel={client} timezone={timezone}>
      <FormSection maxWidth="100%">
        <PhoneInput label="Cell Number" model="phone_no" />
        <PhoneInput label="Landline Number" model="landline_no" glyph="landline" />
        <EmailInput label="Email Address" model="email" />

        <CheckboxGroup
          label="Consent to Contact"
          trueIcon="check"
          falseIcon="cross"
          falseStyle="faded"
          description={
            <Alert glyph="info" type="warning" className="!mt-2">
              Do not enable consent to contact without a valid, signed authorization from the client
            </Alert>
          }
        >
          <Checkbox
            testKey="consent_to_contact_checkbox"
            label="I acknowledge that the Client has given valid Consent to be contacted via SMS or email"
            model="is_allowed_to_contact"
          />
        </CheckboxGroup>

        <Workflow.Buttons>
          <Workflow.ContinueButton label="Save & Continue →" onClick={onContinue} isLoading={requestLoading} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default withSettings(ContactDetails)
