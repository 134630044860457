import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import Notification from './Notification'

const NotificationsContainer = ({ notifications }) => {
  return (
    <div css={styles} className="NotificationsContainer">
      <TransitionGroup css={animations}>
        {notifications.map((notification) => (
          <CSSTransition key={notification.id} classNames="notification" timeout={{ enter: 350, exit: 350 }}>
            <Notification
              key={notification.id}
              id={notification.id}
              type={notification.type}
              message={notification.description}
              time={notification.time}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  )
}

const styles = {
  position: 'fixed',
  top: '1rem',
  left: 0,
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  pointerEvents: 'none',

  zIndex: 200,
}

const animations = {
  '.notification-enter': {
    opacity: 0,
    transform: 'translate3d(0, -50%, 0)',
  },

  '.notification-enter-active': {
    opacity: 1,
    transform: 'translate3d(0, 0%, 0)',
    transition: 'all 350ms ease-in',
  },

  '.notification-exit': {
    opacity: 1,
    transform: 'translate3d(0, 0, 0)',
  },

  '.notification-exit-active': {
    opacity: 0,
    transform: 'translate3d(0, -50%, 0)',
    transition: 'all 350ms ease-in',
  },

  '.notification-exit-done': {
    opacity: 0,
    transform: 'translate3d(0, -50%, 0)',
  },
}

const mapStateToProps = (state) => {
  return {
    notifications: state.common.notifications,
  }
}

export default connect(mapStateToProps)(NotificationsContainer)
