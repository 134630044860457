import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Page, Grid, Tab, TabList, Tabs, HelpTagIframe } from '@behavehealth/components'

import TodoPageActions from '../../../../components/TodoPageActions'

import Active from './active'
import Completed from './completed'

const pageConfig = {
  feature: 'todos',
  help: <HelpTagIframe id="todos" />,
  marketingID: 'todos',
}

const Tasks = ({ canCreate = true }) => {
  const { params, url } = useRouteMatch()

  const employee = useSelector((state) => state.data?.employees?.data?.[params?.resource_id])

  return (
    <Page breakpoint="0" actions={canCreate && <TodoPageActions data={{ lead_employees: [employee] }} />} {...pageConfig}>
      <Grid gap={16}>
        <Tabs>
          <TabList css={styles.tabList}>
            <Tab label="Active" to={`${url}/active`} />
            <Tab label="Completed" to={`${url}/completed`} />
          </TabList>
        </Tabs>

        <Switch>
          <Route path="/:resource_type/:resource_id/todo/active" children={<Active canCreate={canCreate} />} />
          <Route path="/:resource_type/:resource_id/todo/completed" children={<Completed canCreate={canCreate} />} />

          <Redirect exact from={url} to={`${url}/active`} />
        </Switch>
      </Grid>
    </Page>
  )
}

const styles = {
  tabList: {
    marginTop: '-1rem',
  },
}

export default withPageError(withMarketing(Tasks, pageConfig))
