import React from 'react'
import { transparentize } from 'polished'

import Notifications from '../modules/notifications'
import { COLORS } from '../theme'

const DISSAPEAR_IN = 10000

const dismiss = (id: any) => Notifications.remove(id)

const Notification = ({ id, type, message, time }: any) => {
  React.useEffect(() => {
    let timer = setTimeout(() => dismiss(id), time || DISSAPEAR_IN)
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [id, time])

  return (
    <div data-test="notification" css={styles} className={type} onClick={() => dismiss(id)}>
      <span css={styles.message}>{message}</span>
    </div>
  )
}

const styles = {
  pointerEvents: 'all',

  position: 'relative',
  overflow: 'hidden',
  zIndex: 1,

  padding: '0.5rem 1rem',
  marginBottom: '0.5rem',

  background: '#fff',
  borderRadius: '5px',
  fontWeight: '500',
  boxShadow: `
    0 1px 2px 1px ${transparentize(0.01, COLORS.shadow)},
    0 2px 4px 1px ${COLORS.shadow},
    0 4px 8px 1px ${transparentize(0.01, COLORS.shadow)}`,

  '&::after': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 0,
    background: `${transparentize(0.88, '#007AFF')}`,
  },

  '&.positive': {
    color: '#096348',
    border: '1px solid #a0eace',
    '&::after': {
      background: `${transparentize(0.7, '#a0eace')}`,
    },
  },

  '&.negative': {
    color: COLORS.white,
    border: `1px solid ${COLORS.red}`,

    '&::after': {
      background: `${transparentize(0.1, COLORS.red)}`,
    },
  },

  '&.warning': {
    color: '#66460a',
    border: '1px solid #fff1b1',
    '&::after': {
      background: `${transparentize(0.9, '#fff1b1')}`,
    },
  },

  message: {
    position: 'relative',
    zIndex: 1,
  },
}

Notification.defaultProps = {
  type: 'neutral',
}

export default Notification
