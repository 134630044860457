import React from 'react'
import size from 'lodash/size'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Glyph from '../../components/Glyph'
import Status from '../../components/Status'

import { amount } from '../../utils/functions'
import { PAYMENT_METHODS } from '../../utils/constants'

export const InvoicePaymentsDataTable = (props) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={props.to?.(data)} />,
      },
      {
        width: 180,
        model: 'client',
        title: 'Payer',
        type: 'profile',
      },
      {
        width: 180,
        model: 'amount',
        title: 'Amount',
        formatValue: ({ data, value }: any) => <Status color="green" label={`+$${amount(value)}`} />,
      },
      {
        width: 250,
        id: 'payed_with',
        model: 'payed_with',
        title: 'Payed With',
        formatValue: ({ data, value }: any) => {
          if (!value) return null

          return (
            <>
              <Glyph size={18} glyph={PAYMENT_METHODS[value]?.glyph} /> {PAYMENT_METHODS[value]?.label}
            </>
          )
        },
      },
      {
        title: 'Payment Date',
        model: 'transactioned_at',
        type: 'date_time',
      },
      {
        model: 'payment_reference',
        title: 'Payment Reference',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ],
    [],
  )

  return (
    <DataTable
      asCard
      title="Payments"
      icon="online_card_payment"
      columns={columns}
      data={props.data}
      meta={{ count: size(props.data) }}
      {...props}
    />
  )
}
