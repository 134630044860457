import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const CustomNoteTemplatesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.data.id} value={data.data.name} avatar={data.data.avatar} to={to?.(data.data)} />
        ),
      },
      {
        title: 'Template Variant',
        id: 'variant',
        model: 'data.variant',
        type: 'title',
      },
      {
        title: 'Created by',
        id: 'author',
        model: 'data.author.name',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Custom Note Templates"
      icon="custom_notes"
      columns={columns}
      filtersConfig={FILTERS.live_reports.custom_note_templates}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="custom_note_templates"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=custom_note_templates'}
        />
      }
      {...props}
    />
  )
}
