import React from 'react'

import { usTime } from '../../utils/functions'

import CardLink from '../CardLink'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'
import Status from '../Status'

import { ROSTER_CATEGORIES } from '../../utils/constants'

const TimetableEvent = ({ event, to }: any) => {
  return (
    <CardLink to={to} css={styles.root}>
      <CardTitle title={event.reference?.name} css={styles.title} />

      {event.is_all_day && <CardSubtitle subtitle="All-day event" />}
      {!event.is_all_day && <CardSubtitle subtitle={`${usTime(event.started_at)} – ${usTime(event.ended_at)}`} />}

      {event?.reference?.has_roster && <Status label={ROSTER_CATEGORIES[event.reference?.roster_category]} color="blue" />}
    </CardLink>
  )
}

const styles = {
  root: {
    marginBottom: '0.25rem',
    borderRadius: 4,
  },

  title: {
    fontSize: '1rem',
  },
}

export default TimetableEvent
