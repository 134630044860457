import React from 'react'
import { useParams } from 'react-router-dom'

import { titleCase } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Avatar from '@behavehealth/components/Avatar'
import Icon from '@behavehealth/components/Icon'
import Card from '@behavehealth/components/Card'
import Page from '@behavehealth/components/Page'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import State from '@behavehealth/components/State'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'
import { APP_URLS } from '@behavehealth/constructs/MyAccount/utils'

const RootPartners = () => {
  const params: any = useParams()
  const { resource_id: id } = params

  const { data: organization, isLoading, isError, error }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  const isShared = organization?.other_partner_settings?.partners === true

  const tableProps: any = useDataTable({
    name: ['organizations'],
    endpoint: `/organizations`,
    options: { enabled: isShared },
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Partner Business Name',
        id: 'name',
        model: 'other_partner.name',
        width: 260,
        disableHide: true,
        formatValue: ({ value }: any) => (
          <div className="flex flex-nowrap items-center font-[600]">
            <Avatar initials={value} src="" size={22} className="mr-1.5" />
            {value}
          </div>
        ),
      },
      {
        title: 'Community Profile',
        model: 'other_partner.primary_profile.name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => {
          if (!data.other_partner?.primary_profile) return null

          const communityURL = `${APP_URLS.community}/profiles/${data.other_partner.primary_profile.slug}`

          return (
            <LinkCell
              isExternal
              before={<Icon icon="community" size={16} className="mr-1.5" />}
              value={data.other_partner.primary_profile.name}
              href={communityURL}
            />
          )
        },
      },
      {
        title: 'Phone',
        model: 'other_partner.phone_no',
        type: 'phone',
      },
      {
        title: 'Email',
        model: 'other_partner.email',
        type: 'email',
      },
      {
        title: 'Website',
        model: 'other_partner.url',
        type: 'external_url',
        disableSelect: true,
      },
      {
        title: 'Address',
        model: 'other_partner.address',
        type: 'address',
        width: 280,
        disableSort: true,
      },
    ],
    [],
  )

  if (!organization || isLoading) return <PageLoader />

  const partner = organization.other_partner

  if (!isShared) {
    return (
      <Page icon="community_partners" title="Partners">
        <Card>
          <State
            isEmpty
            icon="community_partners"
            title="Partners"
            emptyDescription={`${partner?.name || '–'} has not shared any partners`}
          />
        </Card>
      </Page>
    )
  }

  return (
    <Page title="Partners" icon="community_partners">
      <DataTable {...tableProps} icon="community_partners" title="Partners" columns={columns} />
    </Page>
  )
}

export const Partners = withPageError(RootPartners)
