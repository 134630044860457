import React from 'react'
import * as Popover from '@radix-ui/react-popover'

import { COLORS, css, keyframes, SHADOW } from '../../../theme'
import { usePortal } from '../../../hooks/usePortal'

import Glyph from '../../../components/Glyph'
import Grid from '../../../components/Grid'
import State from '../../../components/State'

import { INPUT_STYLES } from '../styles'

export const FilterDropdown: React.FC<any> = (props) => {
  const {
    activeValues,
    children,
    className,
    testKey,
    hasActiveFilters,
    isEmpty,
    isLoading,
    isSearchEmpty,
    label = 'Select…',
    onClick,
    onClose,
    onOpenChange,
    open,
  } = props

  const portalContainer = usePortal('radix')

  return (
    <div onClick={onClick} className={className}>
      <Popover.Root open={open} onOpenChange={onOpenChange}>
        <Popover.Trigger asChild>
          <div data-test={testKey} css={STYLES.trigger} className={!hasActiveFilters ? 'is-empty' : ''}>
            {label}
            <div className="flex items-center justify-center ml-auto pl-1">
              <Glyph glyph="triangle_down" size={10} css={STYLES.triangle} />
            </div>
          </div>
        </Popover.Trigger>

        <Popover.Portal container={portalContainer}>
          <Popover.Content asChild side="bottom" align="start" onInteractOutside={onClose}>
            <div data-test="filter_dropdown_menu" css={STYLES.menu}>
              {isLoading || isEmpty ? (
                <State isLoading={isLoading} isEmpty={isEmpty} emptyDescription="No data available to display" glyph="filter" />
              ) : (
                <>
                  {!isLoading && !isEmpty && children}

                  {isSearchEmpty && <State isEmpty emptyDescription="No search results founds" glyph="search" />}
                </>
              )}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>

      {activeValues && (
        <Grid gap="0.4rem" alignItems="flex-start" css={STYLES.activeValuesGrid}>
          {activeValues}
        </Grid>
      )}
    </div>
  )
}

export const FilterDropdownTrigger = ({ testKey, label, hasActiveFilters, onClick }: any) => {
  return (
    <div data-test={testKey} css={STYLES.trigger} className={!hasActiveFilters ? 'is-empty' : ''} onClick={onClick}>
      {label}
      <div className="flex items-center justify-center ml-auto pl-1">
        <Glyph glyph="triangle_down" size={10} css={STYLES.triangle} />
      </div>
    </div>
  )
}

const animation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale3d(0.96, 0.96, 0.96)',
  },
  '100%': {
    opacity: 1,
    transform: 'scale3d(1, 1, 1)',
  },
})

const STYLES = {
  trigger: {
    ...INPUT_STYLES,
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.95rem',
    fontWeight: 400,
    height: '1.75rem',
    cursor: 'pointer',

    '&.is-empty': {
      color: COLORS.textStronglyMuted,
      fontStyle: 'italic',
    },
  },

  menu: {
    '& > hr': {
      margin: '0 !important',
    },

    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',

    borderRadius: 7,
    background: 'white',
    boxShadow: SHADOW(10, COLORS.divider),

    transformOrigin: 'center top',
    outline: `0 !important`,

    maxHeight: 'var(--radix-popper-available-height, 50vh)',
    maxWidth: 'var(--menu-max-width)',
    minWidth: '220px',
    minHeight: 'var(--menu-min-height)',

    width: 'var(--menu-width)',
    animation: `${animation} 120ms cubic-bezier(0.39, 0.575, 0.565, 1)`,
  },

  value: {
    display: 'flex',
    alignItems: 'center',
  },

  valueGraphic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '0.2rem',
  },

  triangle: {
    marginLeft: 'auto',
    alignSelf: 'center',
  },

  activeValuesGrid: {
    padding: '0.35rem 0',
  },
}
