import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useAPI } from '@behavehealth/hooks'

import { Button, Card, Chotomate, Page, HelpTagIframe } from '@behavehealth/components'
import { PayersRulesTable } from '@behavehealth/components/Tables'

const Rules: React.FC = () => {
  const match = useRouteMatch()
  const data = useSelector((state) => state.data.insurance_global_payer_rules?.data)
  const loading = useSelector((state) => state.data.insurance_global_payer_rules?.loading)

  useAPI('insurance_global_payer_rules', `/insurance_local_payers/${match.params?.resource_id}/insurance_global_payer_rules`)

  const isEmpty = size(data) === 0

  const actions = (
    <Button
      label="Add Rule"
      type="primary"
      glyph="add"
      link={{
        pathname: `${match.url}/new`,
      }}
    />
  )

  return (
    <Page feature="payer_rules" actions={!isEmpty && actions} help={<HelpTagIframe id="payer_rules" />}>
      <Chotomate name="rules" ready={!loading} />

      <Card>
        <PayersRulesTable
          data={data}
          isLoading={isEmpty && loading}
          emptyActions={actions}
          localStorageKey="insurance_global_payer_rules"
          to={(id) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
        />
      </Card>
    </Page>
  )
}

export default Rules
