import React from 'react'
import clsx from 'clsx'

const PageLayout = ({ layout, children, className, isCompact, gap, columnWidth }: any) => {
  const classNames = clsx({
    'is-list': layout === 'list',
    'is-grid': layout === 'grid',
    'is-compact': isCompact,
    [className]: className,
  })

  return (
    <div css={styles({ gap, columnWidth })} className={classNames}>
      {children}
    </div>
  )
}

const styles = ({ gap, columnWidth }) => ({
  display: 'grid',
  gridGap: gap,

  '&.is-grid': {
    [`@media (min-width: ${columnWidth * 2}px)`]: {
      gridTemplateColumns: `repeat(auto-fit, minmax(${columnWidth}px, 1fr))`,
    },
  },

  '&.is-list': {
    gridTemplateColumns: '1fr',
  },

  '&.is-compact': {
    gridGap: 0,
  },
})

PageLayout.defaultProps = {
  gap: '1.75rem',
  layout: 'list',
  columnWidth: 600,
}

export default PageLayout
