import React from 'react'

import ContextShow from '../../../components/Forms/ContextShow'
import DateInput from '../../../components/Forms/DateInput'
import Diagnoses from '../../../components/SmartContent/Diagnoses'
import GenderIdentitySelect from '../../../components/Forms/elements/GenderIdentitySelect'
import Input from '../../../components/Forms/Input'
import LevelOfCareSelect from '../../../components/Forms/LevelOfCareSelect'
import Medications from '../../../components/SmartContent/Medications'
import PreferredPronounsSelect from '../../../components/Forms/elements/PreferredPronounsSelect'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import SexSelect from '../../../components/Forms/elements/SexSelect'
import SmartTextarea from '../../../components/Forms/SmartTextarea'

import ThoughtsPlansSuicidalIntent from '../../../components/Overlays/pages/DataForms/common/ThoughtsPlansSuicidalIntent'
import TreatmentHistoryForm from '../../../components/Overlays/pages/DataForms/common/TreatmentHistoryForm'

import {
  BIO_MEDICAL_FIELDS,
  COGNITIVE_EMOTIONAL_BEHAVIORAL_FIELDS,
  LEVEL_OF_RISK_AND_INTERVENTIONS_FIELDS,
  MOTIVATION_FOR_CHANGE_FIELDS,
  PROTECTIVE_FACTORS_FIELDS,
  RECOVERY_ENVIRONMENT_FIELDS,
  RELAPSE_POTENTIAL_FIELDS,
  RISK_FACTORS_FIELDS,
  SNAP_FIELDS,
  SUBSTANCE_USE_HISTORY,
  SUICIDE_RISK_DOCUMENTATION_FIELDS,
  WITHDRAWAL_SYMPTOMS_FIELDS,
} from './common'

export const BIOPSYCHOSOCIAL_SECTIONS = [
  {
    title: 'General Info',
    model: 'general_info',
    fields: [
      {
        label: 'Current Level of Care (read-only)',
        model: 'current_level_of_care',
        component: ({ client }: any) => (
          <LevelOfCareSelect
            isEditable={false}
            label="Current Level of Care"
            model="data.general_info.level_of_care"
            value={client && client.level_of_care}
          />
        ),
      },
      {
        label: 'Clinical Admit Date',
        model: 'clinical_admit_date',
        component: ({ client }: any) => (
          <DateInput
            label="Clinical Admit Date"
            model="data.general_info.admitted_at"
            default={client && client.current_admission && client.current_admission.admitted_at}
          />
        ),
      },
      {
        label: 'Does the client have any allergies?',
        model: 'client_allergies',
        component: () => (
          <>
            <RadioGroup layout="horizontal-dense" label="Does the client have any allergies?" model="data.general_info.has_allergies">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.general_info.has_allergies" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please list what the client is allergic to and the corresponding reaction."
                model="data.general_info.allergies"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Presenting Problem and Behavioral Health Issues (Symptomology)',
        model: 'symptomology',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="Presenting Problem and Behavioral Health Issues (Symptomology)"
            model="data.general_info.problems_and_symptomology"
          />
        ),
      },
      {
        label: 'Preferred Name',
        model: 'preferred_name',
        component: () => <Input label="Preferred Name" model="data.general_info.preferred_name" />,
      },
      {
        label: 'Assigned Sex at Birth',
        model: 'assigned_sex_at_birth',
        component: () => <SexSelect label="Assigned Sex at Birth" model="data.general_info.sex" />,
      },
      {
        label: 'Gender Identity',
        model: 'gender_identity',
        component: () => <GenderIdentitySelect label="Gender Identity" model="data.general_info.gender_identity" />,
      },
      {
        label: 'Preferred Pronouns',
        model: 'preferred_pronouns',
        component: () => <PreferredPronounsSelect label="Preferred Pronouns" model="data.general_info.preferred_pronouns" />,
      },
      {
        label: 'Sexuality',
        model: 'sexuality',
        component: () => <Input label="Sexuality" model="data.general_info.sexuality" />,
      },
      {
        label: 'What made the client decide to seek treatment now? (i.e. family intervention, medical problem, legal issues, etc.)',
        model: 'reason_for_seeking_treatment',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="What made the client decide to seek treatment now? (i.e. family intervention, medical problem, legal issues, etc.)"
            description="Please be specific"
            model="data.general_info.reason_for_seeking_treatment"
          />
        ),
      },
    ],
  },
  {
    title: 'Substance Use History',
    model: 'substance_use_history',
    navParent: { id: 'history', name: 'History' },
    fields: SUBSTANCE_USE_HISTORY,
  },
  {
    title: 'Mental Health History',
    model: 'mental_health_history',
    navParent: { id: 'history', name: 'History' },
    fields: [
      {
        label: 'Notes',
        model: 'notes',
        component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.mental_health_history.notes" />,
      },
    ],
  },
  {
    title: 'Physical Health History',
    model: 'physical_health_history',
    navParent: { id: 'history', name: 'History' },
    fields: [
      {
        label: 'Notes',
        model: 'notes',
        component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.physical_health_history.notes" />,
      },
    ],
  },
  {
    title: 'Treatment History for Mental Health and Addiction',
    model: 'treatment_history',
    navParent: { id: 'history', name: 'History' },
    fields: [
      {
        label: 'Treatment History Form',
        model: 'treatment_history_form',
        component: () => <TreatmentHistoryForm />,
      },
    ],
  },
  {
    title: 'Work and Education History',
    model: 'work_and_education_history',
    navParent: { id: 'history', name: 'History' },
    fields: [
      {
        label: 'What is the highest level of schooling completed by the client?',
        model: 'highest_level_of_schooling',
        component: () => (
          <Input
            label="What is the highest level of schooling completed by the client?"
            model="data.work_and_education_history.highest_level_of_schooling"
          />
        ),
      },
      {
        label: "Did the client's substance use have any impact on their schooling?",
        model: 'has_using_affected_school',
        component: () => (
          <>
            <RadioGroup
              label="Did the client's substance use have any impact on their schooling?"
              model="data.work_and_education_history.has_using_affected_school"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.work_and_education_history.has_using_affected_school" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please explain:"
                model="data.work_and_education_history.has_using_affected_school_extra"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: "Has the client's substance use affected their work in any way?",
        model: 'has_using_affected_work',
        component: () => (
          <>
            <RadioGroup
              label="Has the client's substance use affected their work in any way?"
              model="data.work_and_education_history.has_using_affected_work"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.work_and_education_history.has_using_affected_work" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please explain:"
                model="data.work_and_education_history.has_using_affected_work_extra"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Has the client ever used drugs or alcohol at work?',
        model: 'has_used_at_work',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever used drugs or alcohol at work?"
              model="data.work_and_education_history.has_used_at_work"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.work_and_education_history.has_used_at_work" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please explain:"
                model="data.work_and_education_history.has_used_at_work_extra"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Has the client ever been fired or quit a job due to using alcohol or drugs?',
        model: 'has_been_fired_for_using',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever been fired or quit a job due to using alcohol or drugs?"
              model="data.work_and_education_history.has_been_fired_for_using"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.work_and_education_history.has_been_fired_for_using" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please explain:"
                model="data.work_and_education_history.has_been_fired_for_using_extra"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Has the client ever used drugs or alcohol with spouse or significant other?',
        model: 'has_used_with_spouse',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever used drugs or alcohol with spouse or significant other?"
              model="data.work_and_education_history.has_used_with_spouse"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.work_and_education_history.has_used_with_spouse" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please explain:"
                model="data.work_and_education_history.has_used_with_spouse_extra"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Has the client ever been involved with AA/NA?',
        model: 'has_been_involved_with_aa_na',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever been involved with AA/NA?"
              model="data.work_and_education_history.has_been_involved_with_aa_na"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.work_and_education_history.has_been_involved_with_aa_na" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="How often attended?"
                model="data.work_and_education_history.aa_na.how_often"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />

              <RadioGroup
                label="Did the client have a sponsor?"
                model="data.work_and_education_history.aa_na.have_sponsor"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="Did the client work the 12 steps?"
                model="data.work_and_education_history.aa_na.has_worked_12_steps"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.work_and_education_history.aa_na.has_worked_12_steps" is={true}>
                <Input
                  label="Highest step completed?"
                  model="data.work_and_education_history.aa_na.highest_step_completed"
                  type="number"
                  size={4}
                />
              </ContextShow>
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Current Medications',
    model: 'medications',
    navParent: { id: 'history', name: 'History' },
    fields: [
      {
        label: 'Medications List',
        model: 'medications_list',
        component: ({ isEditable, client }: any) => (
          <Medications
            model="data.medications.meds"
            isEditable={isEditable}
            client={client}
            icon="medications"
            title="Medications"
            emptyDescription="No medications added yet"
          />
        ),
      },
    ],
  },
  {
    title: 'Dimension 1: Withdrawal Symptoms',
    model: 'withdrawal_symptoms',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: WITHDRAWAL_SYMPTOMS_FIELDS,
  },
  {
    title: 'Dimension 2: Bio-Medical',
    model: 'bio_medical',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: BIO_MEDICAL_FIELDS,
  },
  {
    title: 'Dimension 3: Emotional, Behavioral or Cognitive Conditions and Complications',
    model: 'cognitive_emotional_behavioral',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: COGNITIVE_EMOTIONAL_BEHAVIORAL_FIELDS,
  },
  {
    title: 'Dimension 4: Readiness for Change',
    model: 'motivation_for_change',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: MOTIVATION_FOR_CHANGE_FIELDS,
  },
  {
    title: 'Dimension 5: Relapse, Continued Use, or Continued Problem Potential',
    model: 'relapse_potential',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: RELAPSE_POTENTIAL_FIELDS,
  },
  {
    title: 'Dimension 6: Recovery / Living Environment',
    model: 'recovery_environment',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: RECOVERY_ENVIRONMENT_FIELDS,
  },
  {
    title: 'Risk Factors',
    model: 'risk_factors',
    navParent: { id: 'suicide_assessment', name: 'Suicide Assessment' },
    fields: RISK_FACTORS_FIELDS,
  },
  {
    title: 'Protective Factors',
    model: 'protective_factors',
    navParent: { id: 'suicide_assessment', name: 'Suicide Assessment' },
    fields: PROTECTIVE_FACTORS_FIELDS,
  },
  {
    title: 'Thoughts, Plans, and Suicidal Intent',
    model: 'thoughts_plans_and_suicidal_intent',
    navParent: { id: 'suicide_assessment', name: 'Suicide Assessment' },
    fields: [
      {
        label: 'Thoughts, Plans, and Suicidal Intent Form',
        model: 'thoughts_plans_suicidal_intent_form',
        component: () => <ThoughtsPlansSuicidalIntent hideNotes />,
      },
      {
        label: 'Notes',
        model: 'notes',
        component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.thoughts_plans_and_suicidal_intent.notes" />,
      },
    ],
  },
  {
    title: 'Level of Risk and Interventions',
    model: 'level_of_risk_and_interventions',
    navParent: { id: 'suicide_assessment', name: 'Suicide Assessment' },
    fields: LEVEL_OF_RISK_AND_INTERVENTIONS_FIELDS,
  },
  {
    title: 'Documentation',
    model: 'suicide_risk_documentation',
    navParent: { id: 'suicide_assessment', name: 'Suicide Assessment' },
    fields: SUICIDE_RISK_DOCUMENTATION_FIELDS,
  },
  {
    title: 'Strengths, Needs, Abilities, Preferences',
    model: 'snap',
    fields: SNAP_FIELDS,
  },
  {
    title: 'Diagnoses',
    model: 'diagnoses',
    fields: [
      {
        label: 'Diagnoses',
        model: 'diagnoses',
        component: ({ client, isEditable }: any) => (
          <Diagnoses
            model="data.diagnoses.diagnoses"
            isEditable={isEditable}
            client={client}
            icon="diagnosis"
            title="Diagnoses"
            emptyDescription="No diagnoses added yet"
          />
        ),
      },
    ],
  },
  {
    title: 'Summary',
    model: 'summary',
    fields: [
      {
        label: 'Interpretive Summary',
        model: 'interpretive_summary',
        component: () => <SmartTextarea useQuickText useDictation label="Interpretive Summary" model="data.summary.interpretive_summary" />,
      },
      {
        label: 'Additional Relevant Information',
        model: 'additional_relevant_info',
        component: () => (
          <SmartTextarea useQuickText useDictation label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
        ),
      },
      {
        label: 'Recommendations',
        model: 'recommendations',
        component: () => <SmartTextarea useQuickText useDictation label="Recommendations" model="data.summary.recommendations" />,
      },
    ],
  },
  {
    title: 'Single Case Agreement',
    model: 'single_case_agreement',
    fields: [
      {
        label: 'Agreement Justification',
        model: 'agreement_justification',
        component: () => (
          <SmartTextarea useQuickText useDictation label="Agreement Justification" model="data.single_case_agreement.justification" />
        ),
      },
    ],
  },
]
