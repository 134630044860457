import React from 'react'
import { Route, Switch, Redirect, useRouteMatch, useParams } from 'react-router-dom'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import All from './clinical_notes/all'
import Individual from './clinical_notes/individual'
import Group from './clinical_notes/group'
import CaseManagement from './clinical_notes/case_management'
import FamilySession from './clinical_notes/family_session'

import { Grid, HelpTagIframe, Page, Tab, TabList, Tabs, Divider } from '@behavehealth/components'
import { ClinicalNotesDropdown } from '@behavehealth/constructs/ClinicalNotes/ClinicalNotesDropdown'

const pageConfig = {
  feature: 'clinical_notes',
  help: <HelpTagIframe id="clinical_notes" />,
  marketingID: 'clinical_notes',
}

const ClinicalNotes = ({ canCreate = true }: any) => {
  const { url } = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  return (
    <Page {...pageConfig} actions={canCreate && <ClinicalNotesDropdown client={client} />}>
      <Grid gap={16}>
        <Tabs className="-mt-4">
          <TabList>
            <Tab label="All Notes" to={`${url}/all`} />
            <Tab label="Individual" to={`${url}/individual`} />
            <Tab label="Group" to={`${url}/group`} />
            <Tab label="Case Management" to={`${url}/case-management`} />
            <Tab label="Family Session" to={`${url}/family-session`} />
          </TabList>
        </Tabs>

        <Switch>
          <Route path="/:resource/:resource_id/clinical-notes/all" children={<All canCreate={canCreate} />} />
          <Route path="/:resource/:resource_id/clinical-notes/individual" children={<Individual canCreate={canCreate} />} />
          <Route path="/:resource/:resource_id/clinical-notes/group" children={<Group canCreate={canCreate} />} />
          <Route path="/:resource/:resource_id/clinical-notes/case-management" children={<CaseManagement canCreate={canCreate} />} />
          <Route path="/:resource/:resource_id/clinical-notes/family-session" children={<FamilySession canCreate={canCreate} />} />

          <Redirect exact from={url} to={`${url}/all`} />
        </Switch>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ClinicalNotes, pageConfig))
