import React from 'react'
import { DateTime } from 'luxon'
import size from 'lodash/size'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useGet, useDelete } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Icon from '../../components/Icon'
import PageSection from '../../components/PageSection/PageSection'
import SummonOverlay from '../../components/SummonOverlay'

import { CommunityProfileHealthcareServiceOverlay } from './CommunityProfileHealthcareServiceOverlay'
import { countWord } from '../../utils/functions'
import { useCommunityEditor } from './useCommunityEditor'
import { useCommunityPermissions } from './useCommunityPermissions'

const RootCommunityProfileHealthcareServicesTab: React.FC<any> = (props: any) => {
  const { entityId } = props

  const { canEditCommunityProfiles } = useCommunityPermissions()

  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  const { data, isLoading }: any = useGet({
    name: ['community', entityId, 'entity_healthcare_services'],
    url: `/community/entities/${entityId}/entity_healthcare_services`,
  })

  const { mutateAsync: deleteRecord } = useDelete({
    name: ['delete_entity_healthcare_services'],
    url: `/community/entity_healthcare_services`,
    invalidate: ['community', entityId, 'entity_healthcare_services'],
    onSuccess: () => {
      setUpdatedAt(DateTime.now().toMillis())
    },
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 200,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <SummonOverlay overlay={<CommunityProfileHealthcareServiceOverlay entityId={entityId} dataID={data.id} initialData={data} />}>
            <MainCell id={data.id} value={data.name} />
          </SummonOverlay>
        ),
      },
      {
        title: 'Description',
        model: 'description',
        width: 200,
      },
      {
        title: 'Actions',
        id: 'actions',
        flexChildren: true,
        disableSort: true,
        width: 100,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          if (!canEditCommunityProfiles) return null

          return (
            <DeleteDialog
              title="Delete Healthcare Service?"
              message="Are you sure you want to delete this Healthcare Service?"
              onYes={async () => await deleteRecord(data.id)}
            >
              <Button glyph="delete" label="Delete" type="minimal" color="red" size={100} />
            </DeleteDialog>
          )
        },
      },
    ]
  }, [canEditCommunityProfiles])

  return (
    <div className="grid grid-cols-[100%]">
      <PageSection>
        <PageSection.Header
          graphic={<Icon icon="healthcare_services" />}
          after={
            canEditCommunityProfiles && (
              <SummonOverlay overlay={<CommunityProfileHealthcareServiceOverlay entityId={entityId} dataID="new" />}>
                <Button label="Add New Healthcare Service" glyph="add" size={200} type="primary" display="inline-flex" />
              </SummonOverlay>
            )
          }
        >
          <PageSection.Title title={size(data) ? countWord('Healthcare Services', size(data)) : 'Healthcare Services'} />
        </PageSection.Header>
      </PageSection>

      <DataTable
        asCard
        data={data}
        isLoading={isLoading}
        title="Healthcare Services"
        icon="healthcare_services"
        columns={columns}
        mainHeaderHidden
        className="mt-4"
      />
    </div>
  )
}

export const CommunityProfileHealthcareServicesTab = withOverlayError(RootCommunityProfileHealthcareServicesTab)
