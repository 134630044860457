import React from 'react'
import { useLocation } from 'react-router-dom'

import { usDate } from '../../utils/functions'
import { Text } from '../Typography'

import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import DiagnosisStatus from '../Statuses/DiagnosisStatus'
import DiagnosisCategoryStatus from '../Statuses/DiagnosisCategoryStatus'

import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'

type Props = {
  diagnosis: any
  location: any
  timezone: string
  to: any
  onClick: Function
}

const Diagnosis = ({ diagnosis, timezone, to, onClick }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive} onClick={onClick}>
      <CardTitle title={diagnosis?.code} />
      <CardSubtitle subtitle={diagnosis.description} />
      <CardMeta>
        {diagnosis.status && <DiagnosisStatus status={diagnosis.status} />}
        {diagnosis.category && <DiagnosisCategoryStatus status={diagnosis.category} />}
        <Text avatar={diagnosis?.author?.avatar} label={diagnosis?.author?.name} />
        <Text glyph="date" label="From: " description={usDate(diagnosis.from, timezone)} />
        <Text glyph="date" label="Until: " description={usDate(diagnosis.until, timezone)} />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(Diagnosis)
