import React from 'react'

import Divider from '../../../../Divider'
import Grid from '../../../../Grid'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'

import Score from './Score'

const SCORING = {
  4: 'no withdrawal',
  12: 'mild withdrawal',
  24: 'moderate withdrawal',
  36: 'moderately severe withdrawal',
  48: 'severe withdrawal',
}

const COWSSymptoms = () => {
  const [score, setScore] = React.useState({
    bpm: 0,
    sweating: 0,
    restlessness: 0,
    pupil_size: 0,
    bone_or_joint_aches: 0,
    runny_nose_or_tearing: 0,
    gi_upset: 0,
    tremors: 0,
    yawning: 0,
    anxiety_or_irritability: 0,
    gooseflesh_skin: 0,
  })

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  return (
    <>
      <Grid gap={32}>
        <RadioGroup
          layout="vertical-dense"
          label="Resting Heart Rate (BPM)"
          description="Measured after patient is sitting or lying for one minute"
          model="data.cows_symptoms.bpm"
          onUpdate={(state) => setScore((o) => ({ ...o, bpm: state.value }))}
        >
          <Radio label="1 = <80 BPM" value={1} />
          <Radio label="2 = 81-100 BPM" value={2} />
          <Radio label="3 = 101-120 BPM" value={3} />
          <Radio label="4 = >120 BPM" value={4} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Sweating"
          description="Sweating not accounted for by room temperature or patient activity over the last 30 minutes"
          model="data.cows_symptoms.sweating"
          onUpdate={(state) => setScore((o) => ({ ...o, sweating: state.value }))}
        >
          <Radio label="0 = No report of chills or flushing" value={0} />
          <Radio label="1 = Subjective report of chills or flushing" value={1} />
          <Radio label="2 = Flushed or observable moistness on face" value={2} />
          <Radio label="3 = Beads of sweat on brow or face" value={3} />
          <Radio label="4 = Sweat streaming off face" value={4} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Restlessness"
          description="Observation during assessment"
          model="data.cows_symptoms.restlessness"
          onUpdate={(state) => setScore((o) => ({ ...o, restlessness: state.value }))}
        >
          <Radio label="0 = Able to sit still" value={0} />
          <Radio label="1 = Reports difficulty sitting still, but is able to do so" value={1} />
          <Radio label="3 = Frequent shifting or extraneous movements of legs/arms" value={3} />
          <Radio label="5 = Unable to sit still for more than a few seconds" value={5} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Pupil Size"
          model="data.cows_symptoms.pupil_size"
          onUpdate={(state) => setScore((o) => ({ ...o, pupil_size: state.value }))}
        >
          <Radio label="0 = Pupils pinned or normal size for room light" value={0} />
          <Radio label="1 = Pupils possibly larger than normal for room light" value={1} />
          <Radio label="2 = Pupils moderately dilated" value={2} />
          <Radio label="5 = Pupils so dilated that only the rim of the iris is visible" value={5} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Bone or joint aches"
          description="If patient was having pain previously, only the additional component attributed to opiate withdrawal is scored"
          model="data.cows_symptoms.bone_or_joint_aches"
          onUpdate={(state) => setScore((o) => ({ ...o, bone_or_joint_aches: state.value }))}
        >
          <Radio label="0 = Not present" value={0} />
          <Radio label="1 = Mild diffuse discomfort" value={1} />
          <Radio label="2 = Patient reports severe diffuse aching of joints/ muscles" value={2} />
          <Radio label="4 = Patient is rubbing joints or muscles and is unable to sit still because of discomfort" value={4} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Runny nose or tearing"
          description="Not accounted for by cold symptoms or allergies"
          model="data.cows_symptoms.runny_nose_or_tearing"
          onUpdate={(state) => setScore((o) => ({ ...o, runny_nose_or_tearing: state.value }))}
        >
          <Radio label="0 = Not present" value={0} />
          <Radio label="1 = Nasal stuffiness or unusually moist eyes" value={1} />
          <Radio label="2 = Nose running or tearing" value={2} />
          <Radio label="4 = Nose constantly running or tears streaming down cheeks" value={4} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="GI upset"
          description="During the last 30 minutes"
          model="data.cows_symptoms.gi_upset"
          onUpdate={(state) => setScore((o) => ({ ...o, gi_upset: state.value }))}
        >
          <Radio label="0 = None present" value={0} />
          <Radio label="1 = Stomach cramps" value={1} />
          <Radio label="2 = Nausea or loose stools" value={2} />
          <Radio label="3 = Vomiting or diarrhea" value={3} />
          <Radio label="5 = Multiple episodes of vomiting or diarrhea" value={5} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Tremors"
          description="Observation of outstretched hands"
          model="data.cows_symptoms.tremors"
          onUpdate={(state) => setScore((o) => ({ ...o, tremors: state.value }))}
        >
          <Radio label="0 = None present" value={0} />
          <Radio label="1 = Tremor can be felt, but not observed" value={1} />
          <Radio label="2 = Slight tremor observable" value={2} />
          <Radio label="4 = Gross tremor or muscle twitching" value={4} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Yawning"
          description="Observation during assessment"
          model="data.cows_symptoms.yawning"
          onUpdate={(state) => setScore((o) => ({ ...o, yawning: state.value }))}
        >
          <Radio label="0 = No yawning" value={0} />
          <Radio label="1 = Yawning once or twice during assessment" value={1} />
          <Radio label="2 = Yawning three or more times during assessment" value={2} />
          <Radio label="4 = Yawning several times/minute" value={4} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Anxiety or Irritability"
          model="data.cows_symptoms.anxiety_or_irritability"
          onUpdate={(state) => setScore((o) => ({ ...o, anxiety_or_irritability: state.value }))}
        >
          <Radio label="0 = None" value={0} />
          <Radio label="1 = Patient reports increasing irritability or anxiousness" value={1} />
          <Radio label="2 = Patient obviously irritable/anxious" value={2} />
          <Radio label="4 = Patient so irritable or anxious that participation in the assessment is difficult" value={4} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Gooseflesh skin"
          model="data.cows_symptoms.gooseflesh_skin"
          onUpdate={(state) => setScore((o) => ({ ...o, gooseflesh_skin: state.value }))}
        >
          <Radio label="0 = Skin is smooth" value={0} />
          <Radio label="3 = Piloerection of skin can be felt or hairs standing up on arms" value={3} />
          <Radio label="5 = Prominent piloerection" value={5} />
        </RadioGroup>
      </Grid>

      <Divider />

      <Score title="Final Score" finalScore={finalScore} scoring={SCORING} />
    </>
  )
}

COWSSymptoms.defaultProps = {
  showAllergies: false,
  showSymptomology: false,
}

export default COWSSymptoms
