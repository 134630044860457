import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const statuses = {
  draft: {
    label: 'Draft',
    color: 'blue',
  },
  in_progress: {
    label: 'In Progress',
    color: 'yellow',
  },
  pending_review: {
    label: 'Pending Review',
    color: 'orange',
  },
  updates_requested: {
    label: 'Updates Requested',
    color: 'red',
  },
  closed: {
    label: 'Closed',
    color: 'yellow',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
}

export const RoundStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}
