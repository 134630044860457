import React from 'react'
import { tint } from 'polished'
import * as Popover from '@radix-ui/react-popover'

import { COLORS, SHADOW } from '../../../theme'
import { usePortal } from '../../../hooks/usePortal'

import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import State from '../../../components/State'

export const FilterDropdown: React.FC<any> = (props) => {
  const { activeValues, children, isEmpty, isLoading, isSearchEmpty, label = 'Select…', onClose, onOpenChange, open, testKey } = props

  const portalContainer = usePortal('radix')

  const displayLabel = isLoading ? 'Loading…' : label

  return (
    <Popover.Root open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild>
        <div css={STYLES.trigger}>
          {!activeValues && displayLabel}

          {activeValues && (
            <Flex gap="0.4rem" alignItems="stretch" className="!py-1 !px-0">
              {activeValues}
            </Flex>
          )}

          <Glyph glyph="triangle_down" size={10} css={STYLES.triangle} />
        </div>
      </Popover.Trigger>

      <Popover.Portal container={portalContainer}>
        <Popover.Content asChild side="bottom" align="start" onCloseAutoFocus={onClose}>
          <div css={STYLES.menu}>
            {isLoading || isEmpty ? (
              <State isLoading={isLoading} isEmpty={isEmpty} emptyDescription="No data available to display" glyph="filter" />
            ) : (
              <>
                {!isLoading && !isEmpty && children}

                {isSearchEmpty && <State isEmpty emptyDescription="No search results founds" glyph="search" />}
              </>
            )}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

const STYLES = {
  trigger: {
    padding: '0 0.4rem',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    color: COLORS.blue,
    fontWeight: 500,
    minHeight: 'var(--filter-height)',

    '&:hover': {
      backgroundColor: tint(0.92, COLORS.blue),
    },
  },

  menu: {
    border: `1px solid ${COLORS.divider}`,
    boxShadow: SHADOW(5),
    background: COLORS.white,
    width: '100%',
    minWidth: 200,
    maxWidth: 280,
    borderRadius: 5,
    outline: 'none',
    overflow: 'auto',
    maxHeight: '75vh',
  },

  value: {
    display: 'flex',
    alignItems: 'center',
  },

  valueGraphic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '0.2rem',
  },

  triangle: {
    marginLeft: '0.25rem',
    alignSelf: 'center',
  },
}
