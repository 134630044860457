import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'
import { titleCase } from '../../../utils/functions'

import { RecordTooltip } from '../components/RecordTooltip'
import { ShiftNoteInfo } from '../../StaffJourney/records/ShiftNoteInfo'

export const ShiftNote = ({ data, client }: any) => {
  if (!data) return null

  return (
    <RecordTooltip
      title={titleCase(data.category)}
      icon="housing_notes"
      color={tint(0.7, COLORS.blue)}
      startDate={data.dated_at}
      recordLink={`/shift-notes/${data.id}`}
      client={client}
    >
      <ShiftNoteInfo data={data} />
    </RecordTooltip>
  )
}
