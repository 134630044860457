import React from 'react'

import Select from '../Forms/Select'
import Option from '../Forms/Option'

import { STAFF_USER_ROLES } from '../../utils/constants'

const UserTypeSelect = ({ label = 'Staff Type', model = 'price_unit_subtype' }: any) => {
  return (
    <Select label={label} model={model} defaultValue="owner">
      {STAFF_USER_ROLES.map(({ name, value }) => (
        <Option key={value} label={name} value={value} />
      ))}
    </Select>
  )
}

export default UserTypeSelect
