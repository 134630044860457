import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const statuses = {
  trialing: {
    label: 'Trialing',
    color: 'yellow',
  },
  active: {
    label: 'Active',
    color: 'blue',
  },
  past_due: {
    label: 'Past Due',
    color: 'orange',
  },
  canceled: {
    label: 'Canceled',
    color: 'grey',
  },
  unpaid: {
    label: 'Unpaid',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
