import React from 'react'
import { tint, transparentize } from 'polished'
import produce from 'immer'
import size from 'lodash/size'

import { arrayToMapWithKey, countWord, isDefined } from '../../utils/functions'
import { COLORS, HARD_SHADOW } from '../../theme'
import { useSettings } from '../../hooks/useSettings'

import { PopoverMenu } from '../PopoverMenu'
import Button from '../Button'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Grid from '../Grid'

import { Filter } from './filters/Filter'
import { INPUT_STYLES } from './styles'
import { FILTERS } from './filters'
import { FilterSelect } from './filters/FilterSelect'

import { useStore } from './useStore'

const getActiveFilters = (filters: any[]) => {
  const result: any = []

  if (size(filters) === 0) return result

  for (const filter of filters) {
    if (filter?.key) {
      result.push(filter.key)
    }
  }

  return result
}

export const DataTableFilters = (props: any) => {
  const { onFiltersUpdate, isFiltersOpen, setIsFiltersOpen } = props

  const scrollRef = React.useRef<HTMLDivElement>(null)

  const addFilter: any = useStore((state: any) => state.addFilter)
  const filters: any = useStore((state: any) => state.filters)
  const filtersConfig: any = useStore((state: any) => state.filtersConfig)
  const removeFilter: any = useStore((state: any) => state.removeFilter)
  const resetFilters: any = useStore((state: any) => state.resetFilters)
  const setFilter: any = useStore((state: any) => state.setFilter)

  const { isBehave, timezone } = useSettings()

  const filtersMap = React.useMemo(() => {
    return arrayToMapWithKey(filters, 'key')
  }, [filters])

  const closeMenu = () => {
    setIsFiltersOpen(false)
  }

  const applyFilters = () => {
    if (onFiltersUpdate) onFiltersUpdate(filtersMap)
  }

  const handleClearAll = () => {
    resetFilters()
    closeMenu()
    onFiltersUpdate?.({})
  }

  if (!filtersConfig && !isBehave) return null // TODO @Andrei: check why filters button doesn't always show in prod

  if (size(filtersConfig) === 0) return null

  const activeFilters = getActiveFilters(filters)
  const activeFiltersCount = size(activeFilters)
  const hasActiveFilters = activeFiltersCount > 0

  return (
    <>
      <PopoverMenu
        testKey="filter_menu"
        isOpen={isFiltersOpen}
        onOpenUpdated={setIsFiltersOpen}
        menuCss={STYLES.menuWrapper}
        onInteractOutside={applyFilters}
        trigger={
          <div>
            <Button
              testKey="data_table_filter_button"
              label={activeFiltersCount ? countWord('Filters', activeFiltersCount) : 'Filters'}
              glyph="filter"
              type="minimal"
              display="inline-flex"
              size={100}
              className={hasActiveFilters ? 'has-filters' : ''}
              css={STYLES.trigger}
              permission={true}
            />
          </div>
        }
      >
        <>
          <header css={STYLES.menuHeader}>
            <div data-test="data_table_filter_counter" css={STYLES.menuHeaderTitle}>
              {countWord('Filter', activeFiltersCount)}
            </div>

            <FilterSelect
              activeFilters={activeFilters}
              filtersConfig={filtersConfig}
              onSelect={(key: any) => {
                addFilter({ key, value: null })

                if (scrollRef.current) {
                  setTimeout(() => {
                    scrollRef.current?.scrollTo({ top: scrollRef.current?.scrollHeight, behavior: 'smooth' })
                  }, 0)
                }
              }}
              trigger={
                <div>
                  <Button testKey="add_filter_button" label="Add Filter" glyph="add" type="minimal" display="inline-flex" size={100} />
                </div>
              }
            />

            {hasActiveFilters && (
              <Button
                testKey="clear_all_filter_button"
                label="Clear All"
                glyph="close"
                type="minimal"
                color="red"
                display="inline-flex"
                size={100}
                onClick={handleClearAll}
                css={{ marginLeft: 'auto' }}
              />
            )}
          </header>

          <main ref={scrollRef} css={STYLES.menuContent}>
            {!hasActiveFilters && (
              <Grid centerX centerY gap="0.75rem" css={{ padding: '1rem', minHeight: 200 }}>
                <Glyph glyph="filter" size={20} />
                <Grid gap="0.25rem" css={{ textAlign: 'center' }}>
                  <h4 css={{ textTransform: 'uppercase', letterSpacing: 1, fontSize: '0.85rem' }}>No filters applied</h4>
                  <div css={{ color: COLORS.textMuted, fontSize: '0.9rem', opacity: 0.9 }}>Click "Add Filter" to get started.</div>
                </Grid>
              </Grid>
            )}

            {hasActiveFilters &&
              activeFilters.map((filterKey: any, index: number) => {
                const FilterTag = FILTERS?.[filtersConfig?.[filterKey]?.type]
                const config = filtersConfig?.[filterKey]

                if (!FilterTag || !config) return null

                return (
                  <Filter testKey={`${filterKey}_filter`} key={`${filterKey}-${index}`} onClear={() => removeFilter(filterKey)}>
                    <FilterSelect
                      activeFilters={activeFilters}
                      filtersConfig={filtersConfig}
                      value={filterKey}
                      onSelect={(newFilterKey: any) => {
                        setFilter(filterKey, { key: newFilterKey, value: null })
                      }}
                      trigger={({ isOpen }: any) => (
                        <div css={STYLES.filterSelect} className={isOpen ? 'is-focused' : ''}>
                          <Glyph glyph={config.glyph || 'filter'} size={12} css={STYLES.filterSelectGlyph} color={COLORS.blue} />
                          <div css={STYLES.filterSelectLabel}>{config.label}</div>
                          <Glyph glyph="triangle_down" size={10} css={STYLES.filterSelectTriangle} />
                        </div>
                      )}
                    />

                    <FilterTag
                      key={filterKey}
                      config={filtersConfig[filterKey]}
                      filter={filtersMap?.[filterKey]}
                      timezone={timezone}
                      applyFilters={applyFilters}
                      setIsFiltersOpen={setIsFiltersOpen}
                      onUpdate={(newFilter: any) => {
                        setFilter(filterKey, { ...newFilter })
                      }}
                    />
                  </Filter>
                )
              })}
          </main>

          <footer css={STYLES.menuFooter}>
            <Flex gap="0.75rem" justifyContent="space-between">
              <Button
                testKey="apply_filter_button"
                label="Apply Filters"
                glyph="tick_circle"
                type="primary"
                color="green"
                size={100}
                onClick={() => {
                  applyFilters()
                  closeMenu()
                }}
                flex="100 1 auto"
                isDisabled={!hasActiveFilters}
              />
            </Flex>
          </footer>
        </>
      </PopoverMenu>
    </>
  )
}

const STYLES = {
  menuWrapper: {
    '--filter-height': '26px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'hidden !important',
    minHeight: 280,

    '@media(min-width: 600px)': {
      minWidth: 500,
    },
  },

  menuHeader: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    overflow: 'hidden',
    padding: '0.4rem 0.75rem',
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(3),
    fontWeight: 600,
  },

  menuHeaderGlyph: {
    marginRight: '0.25rem',
  },

  menuHeaderTitle: {
    marginRight: '0.25rem',
  },

  menuContent: {
    '--filter-height': '26px',
    flex: '1 1 auto',
    overflowY: 'auto',
    padding: '0.25rem 0.75rem',
  },

  menuFooter: {
    flex: '0 0 auto',
    overflow: 'hidden',
    padding: '0.4rem 0.75rem',
    borderTop: `1px solid ${COLORS.divider}`,
  },

  filterSelect: {
    ...INPUT_STYLES,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },

  filterSelectLabel: {
    marginRight: '0.25rem',
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  filterSelectGlyph: {
    marginRight: '0.25rem',
  },

  filterSelectTriangle: {
    marginLeft: 'auto',
  },

  trigger: {
    '&.has-filters': {
      background: tint(0.8, COLORS.vividBlue),
      boxShadow: `inset 0 0 0 1px ${transparentize(0.86, COLORS.blue)}`,
    },
  },
}
