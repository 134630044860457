import React from 'react'

import { useSettings } from '../../../hooks/useSettings'
import { titleCase, usDate, usDateTime } from '../../../utils/functions'
import DataList from '../../../components/DataList'
import Status from '../../../components/Status'

export const ShiftNoteInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item label="Note" value={data.description} />
      <DataList.Item
        label="Category"
        value={<Status small label={titleCase(data.category)} color={data.category === 'incident_report' ? 'red' : 'blue'} />}
      />
      <DataList.Item label="Date and Time" value={usDateTime(data.dated_at, timezone)} />
      <DataList.Item label="Date Added" value={usDateTime(data.created_at, timezone)} />
      <DataList.Item label="Last Edited" value={usDateTime(data.updated_at, timezone)} />
    </DataList>
  )
}
