import React from 'react'

import Chart from '../Chart'

import { COLORS } from '../../theme'

type Props = {
  color?: string
  option?: any
  xData: any[]
  yData: any[]
}

const BarChart: React.FC<Props> = ({ xData, yData, color = COLORS.blue, option, ...rest }) => {
  return (
    <Chart
      option={{
        tooltip: {
          trigger: 'axis',
        },
        xAxis: [{ type: 'category', data: xData }],
        yAxis: [{ type: 'value' }],
        series: [
          {
            data: yData,
            color,
            type: 'bar',
            showBackground: false,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)',
            },
          },
        ],
        ...option,
      }}
      {...rest}
    />
  )
}

export default BarChart
