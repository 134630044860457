import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../../../theme'
import { usDate } from '../../../utils/functions'

import { DateRangeSelector } from '../../../components/Forms/DateRangeSelector'
import Glyph from '../../../components/Glyph'
import { DatePicker } from '../../../components/DatePicker'

import { Filter } from '../common/Filter'
import { FilterDropdown } from '../common/FilterDropdown'
import { FilterDropdownItem } from '../common/FilterDropdownItem'
import { FilterDropdownTrigger } from '../common/FilterDropdownTrigger'
import { FilterLabel } from '../common/FilterLabel'
import { FilterSegment } from '../common/FilterSegment'

export const CONDITIONS = {
  between: 'between',
  less_than: 'before',
  greater_than: 'after',
  eq: 'on',
}

const CONDITION_GLYPHS = {
  between: 'between',
  less_than: 'less_than',
  greater_than: 'greater_than',
  eq: 'equals',
}

export const DateTimeFilter: React.FC<any> = (props) => {
  const { config, onClear, filter, onUpdate } = props

  const [condition, setCondition] = React.useState(filter?.condition || 'between')
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  const initialStartDate = filter?.condition === 'between' ? filter?.value?.from : filter?.value
  const initialEndDate = filter?.value?.until

  const [startDate, setStartDate] = React.useState(initialStartDate)
  const [endDate, setEndDate] = React.useState(initialEndDate)

  React.useEffect(() => {
    if (condition === 'between' && startDate && endDate) {
      onUpdate?.({ condition, value: { from: startDate, until: endDate } })
      return
    }

    onUpdate?.({ condition, value: startDate })
  }, [condition, startDate, endDate])

  if (!config) return null

  const { label, glyph } = config

  return (
    <Filter onClear={onClear}>
      <FilterLabel label={label} glyph={glyph} />

      <FilterSegment>
        <FilterDropdown label={CONDITIONS[condition]} open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
          {Object.keys(CONDITIONS).map((key) => (
            <FilterDropdownItem
              title={CONDITIONS[key]}
              isActive={condition === key}
              onClick={() => {
                setCondition(key)
                setIsDropdownOpen(false)
              }}
              graphic={<Glyph glyph={CONDITION_GLYPHS[key]} color={COLORS.textMuted} size={16} />}
            />
          ))}
        </FilterDropdown>
      </FilterSegment>

      <FilterSegment>
        {condition === 'between' ? (
          <DateRangeSelector
            startDate={initialStartDate}
            endDate={initialEndDate}
            defaultPeriod={null}
            onChange={(rangeDates) => {
              setStartDate(rangeDates?.[0])
              setEndDate(rangeDates?.[1])
            }}
            renderTrigger={() => {
              return <FilterDropdownTrigger label={startDate && endDate ? `${usDate(startDate)} → ${usDate(endDate)}` : 'Select…'} />
            }}
          />
        ) : (
          <>
            <DatePicker
              date={startDate}
              maxYear={2030}
              minYear={1950}
              trigger={<FilterDropdownTrigger label={startDate ? usDate(startDate) : 'Select…'} />}
              onSelect={(date) => {
                if (!date) return
                setStartDate(date.toFormat('yyyy-MM-dd'))
              }}
            />
          </>
        )}
      </FilterSegment>
    </Filter>
  )
}

{
  /* <DatePickerSelector
  date={startDate}
  firstYear={1950}
  trigger={<FilterDropdownTrigger label={startDate ? usDate(startDate) : 'Select…'} />}
  onChange={(date) => {
    if (!date) return
    setStartDate(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'))
  }}
/> */
}
