import React from 'react'
import { connect } from 'react-redux'

import BlockDialog from '../Dialogs/BlockDialog'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Divider from '../Divider'
import DropdownItem from '../DropdownItem'
import PasswordDialog from '../Dialogs/PasswordDialog'
import Permission from '../Permission'

import ContactQuickActions from './common/ContactQuickActions'

const Employee = (props) => {
  const { data, user, updatePassword, toggleBlockEmployee, onDelete, toggleArchiveEmployee } = props
  const { is_blocked, status } = data

  const isArchived = status === 'archived'

  return (
    <>
      <ContactQuickActions data={data} />

      <Divider />

      <BlockDialog
        glyph={is_blocked ? 'unlock' : 'lock'}
        yesButtonLabel={is_blocked ? 'Yes, Unblock' : 'Yes, Block'}
        title={is_blocked ? 'Unblock ?' : 'Block ?'}
        message={`Are you sure you want to ${is_blocked ? 'unblock' : 'block'} this staff member?`}
        onYes={() => toggleBlockEmployee(is_blocked)}
      >
        <DropdownItem
          label={is_blocked ? 'Unblock' : 'Block'}
          glyph={is_blocked ? 'unlock' : 'lock'}
          color="blue"
          permission="employees.actions.block"
        />
      </BlockDialog>

      <BlockDialog
        glyph="delete"
        yesButtonLabel={isArchived ? 'Yes, Active' : 'Yes, Archive'}
        title={isArchived ? 'Active?' : 'Archive?'}
        message={`Are you sure you want to ${isArchived ? 'activate' : 'archive'} this staff member?`}
        onYes={() => toggleArchiveEmployee(isArchived ? 'active' : 'archived')}
      >
        <DropdownItem label={isArchived ? 'Activate' : 'Archive'} glyph="delete" color="blue" permission="employees.actions.archive" />
      </BlockDialog>

      {status === 'archived' && (
        <DeleteDialog
          title="Delete Staff Member?"
          message="Are you sure you want to delete this staff member? This action cannot be undone."
          onYes={onDelete}
        >
          <DropdownItem label="Delete" glyph="delete" color="red" permission="employees.actions.delete" />
        </DeleteDialog>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state?.me?.user,
})

export default connect(mapStateToProps)(Employee)
