import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import compact from 'lodash/compact'
import size from 'lodash/size'

import Button from '../../components/Button'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Grid from '../../components/Grid'
import Glyph from '../../components/Glyph'

import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import OverlayLoader from '../../components/OverlayLoader'

import SmartPasswordInput from '../../components/Forms/SmartPasswordInput'
import PasswordInput from '../../components/Forms/PasswordInput'

import { get } from '../../modules/api/requests'
import { useUpdate } from '../../hooks/useNewAPI'

import Notifications from '../../modules/notifications'

export const SetPasswordOverlay = () => {
  const history: any = useHistory()
  const location: any = useLocation()

  const { resource_id, resource }: any = useParams()

  const [formData, setFormData]: any = React.useState({})
  const [user, setUser]: any = React.useState(null)
  const [isValid, setIsValid] = React.useState()
  const [passwordValid, setPasswordValid] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const [isSuccessful, setIsSuccessful] = React.useState(false)
  const [errors, setErrors]: any = React.useState(null)

  const isSamePassword = React.useMemo(() => {
    const hasMinLength = size(formData?.new_password) >= 10 && size(formData?.confirm_password) >= 10

    return hasMinLength && formData?.new_password === formData?.confirm_password
  }, [formData])

  const isFormValid = React.useMemo(() => {
    return isValid && isSamePassword && passwordValid
  }, [isValid, isSamePassword, passwordValid])

  const { mutateAsync: setPassword, isLoading: isSettingPassword }: any = useUpdate({
    name: ['user-set-password'],
    url: `/users/${user?.id}/set_password`,
    invalidate: ['users'],
    invalidateKeys: ['users'],
  })

  const close = () => {
    if (location?.parent) {
      if (location.parent.url) history.push(location.parent.url)
      else history.push(location.parent)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  const handleSetPassword = async () => {
    try {
      await setPassword(formData)

      Notifications.send('Magic Login Sent Successfully', 'positive')

      setIsSuccessful(true)
    } catch (error) {
      console.error(error)
      setErrors(errors)
    }
  }

  React.useEffect(() => {
    const request = async () => {
      setIsLoading(true)

      try {
        if (resource === 'admissions' || resource === 'clients' || resource === 'alumni') {
          const result = await get(`/residents/${resource_id}/user`)
          setUser(result?.data)
        } else if (resource === 'employees') {
          const result = await get(`/employees/${resource_id}/user`)
          setUser(result?.data)
        }
      } catch (errors) {
        console.error(errors)
      } finally {
        setIsLoading(false)
      }
    }

    request()
  }, [])

  if (isLoading) {
    return <OverlayLoader position="center" onClose={close} maxWidth={40} />
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={40} onClose={close}>
      <Overlay.Header title="🔓 Update Password" />

      <Overlay.Content>
        <Section>
          {isSuccessful ? (
            <Grid gap="0.5rem" justifyItems="center" alignItems="flex-start" className="p-4">
              <Glyph glyph="tick_circle" size={28} />
              <div>
                <p>
                  We successfully changed the password for <strong>{compact([user?.email, user?.phone_no]).join(' and ')}</strong>.
                </p>
              </div>
            </Grid>
          ) : (
            <Form onValidationUpdate={setIsValid} onUpdate={setFormData}>
              <FormSection maxWidth="100%">
                <Input
                  label="Email / Cell Number"
                  glyph="user_neutral"
                  value={user?.behave_email}
                  model="username"
                  css={{ display: 'none' }}
                />

                <SmartPasswordInput
                  isEditable
                  showRules
                  showFeedback
                  username={user?.behave_email}
                  onPasswordValidation={setPasswordValid}
                  label="New Password"
                  model="new_password"
                  validations={{
                    presence: {
                      message: 'Please enter your new password',
                    },
                    length: {
                      minimum: 10,
                      message: 'Your password should have at least 10 characters',
                    },
                    format: {
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])((?=.*\d)|(?=.*\W))[a-zA-Z0-9_@.#$=!%^)(\]:*;?/,}{'|<>[&+-]{10,}$/,
                      message: 'Password must include both lower and upper case characters, and at least one digit or symbol',
                    },
                  }}
                />

                <PasswordInput
                  label="Confirm Password"
                  model="confirm_password"
                  validations={{
                    presence: {
                      message: 'Please re-enter your new password',
                    },
                    length: {
                      minimum: 10,
                      message: 'Your password should have at least 10 characters',
                    },
                    format: {
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])((?=.*\d)|(?=.*\W))[a-zA-Z0-9_@.#$=!%^)(\]:*;?/,}{'|<>[&+-]{10,}$/,
                      message: 'Password must include both lower and upper case characters, and at least one digit or symbol',
                    },
                  }}
                />
              </FormSection>
            </Form>
          )}
        </Section>
      </Overlay.Content>

      {!isSuccessful && (
        <Overlay.Footer>
          <Button
            label="Set Password"
            type="primary"
            color="green"
            isDisabled={!isFormValid}
            isLoading={isSettingPassword}
            onClick={handleSetPassword}
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}
