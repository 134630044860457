import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { apiUpdate } from '@behavehealth/modules/api'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useAPI from '@behavehealth/hooks/useAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Alert, Button, Card, Form, Grid, HelpTagIframe, Page, Radio, RadioGroup } from '@behavehealth/components'

import AccessListTable from '@behavehealth/components/Tables/AccessListTable'

const pageConfig = {
  feature: 'staff_clients_access',
  title: 'Staff Access',
  help: <HelpTagIframe id="staff_access_lists" />,
}

const AccessList: React.FC = () => {
  const { resource_id }: any = useParams()
  const { url }: any = useRouteMatch()

  const form = React.useRef()

  const loading = useSelector((state: any) => state.data?.access_controls?.loading)
  const data: any = useSelector((state: any) => state.data?.access_controls?.data)

  const employee: any = useSelector((state: any) => state.data?.employees?.data?.[resource_id])
  const loadingEmployee: any = useSelector((state: any) => state.data?.employees?.loading)

  useAPI('access_controls', `/employees/${resource_id}/access_controls`)

  const isEmpty = size(data) === 0

  if (!(data && employee)) return <Page isLoading {...pageConfig} />

  const hasFullAccess = employee.access_level === 'full'
  const isOwner = employee.position === 'owner'

  return (
    <Page
      actions={
        !hasFullAccess && (
          <Button label="Add Clients" type="primary" glyph="add" link={`${url}/import`} permission="access_controls.actions.import" />
        )
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        {isOwner && <Alert glyph="info">Owners have full access to all clients by default. This setting cannot be changed.</Alert>}

        {!isOwner && (
          <>
            <Form getForm={form} initialModel={{ access_level: employee.access_level }}>
              <RadioGroup
                label="Client Access Level"
                layout="vertical-dense"
                model="access_level"
                isDisabled={loadingEmployee}
                onChange={({ value }: any) =>
                  apiUpdate({ name: 'employees', url: `/employees/${resource_id}`, params: { access_level: value } })
                }
              >
                <Radio label="Full access to All Clients" value="full" />
                <Radio label="Restricted access to selected Clients only" value="restricted" />
              </RadioGroup>
            </Form>

            {!hasFullAccess && (
              <div className="grid gap-4">
                <Alert contrast glyph="warning" type="warning">
                  <b>Please note:</b> when restricting access to clients, staff members who have permissions for features such as Live
                  Reports, Generated Reports, Programs, Bed Management and more will still be able to view all clients. To restrict access
                  to these features, please update the staff member's permissions.
                </Alert>

                <Card>
                  <AccessListTable data={data} isLoading={isEmpty && loading} to={(id: string) => `/clients/${id}`} />
                </Card>
              </div>
            )}
          </>
        )}
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(AccessList, pageConfig))
