import React from 'react'
import clsx from 'clsx'
import get from 'lodash/get'
import createReact from 'zustand'

import { FormContext } from './context'
import { BHFormEngine } from './engine'

const DEBUG = false

const Form = (props: any) => {
  const { autoFocus = false } = props

  // initialize the Form Engine
  const engine = React.useRef(
    new BHFormEngine({
      initialValue: { ...props.initialModel, ...props.linked },
      useFullModel: props.useFullModel,
      linkedValue: props.linked,
      deregisterOnUnmount: props.deregisterOnUnmount,
    }),
  )

  // create React based store based on the vanilla version used in the Engine
  const store = React.useRef(createReact(engine.current?.store))

  // access store values
  const isValid = store.current((state: any) => state.isValid)
  const formValue = store.current((state: any) => state.value)

  const isPristine = store.current((state: any) => state.isPristine)
  const lastUpdatedInput = store.current((state: any) => state.lastUpdatedInput)

  const resetForm = () => engine.current?.resetForm()
  const reset = (input: any) => engine.current?.reset(input)

  const submit = async () => {
    if (!props.onSubmit) return

    const submission = await props.onSubmit(engine.current?.value)
    return submission
  }

  // * update on Initial Model changes
  React.useEffect(() => {
    if (props.useLocalModel) return

    // engine.current?.updateInitialValue(props.initialModel)
  }, [props.initialModel])

  // * Callbacks
  React.useEffect(() => {
    if (props.onValidationUpdate) props.onValidationUpdate(isValid)
  }, [isValid])

  React.useEffect(() => {
    if (props.onUpdate) props.onUpdate(engine.current.value)
  }, [engine.current.value])

  // pass the form engine higher up
  React.useEffect(() => {
    if ('getForm' in props) props.getForm.current = engine.current
  }, [engine.current])

  const Tag = props.as
  const data = engine.current?.value
  const initialData = engine.current?.initialModel

  const classNames = clsx(props.isCompact && 'is-compact', props.className)

  // if decorate prop is found, keep an updated version of it
  if (props.decorate) engine.current.setDecorate(props.decorate)

  const children =
    typeof props.children === 'function'
      ? props.children({
          form: engine.current,
          reset: reset,
          data: data,
          isValid: isValid,
          isInvalid: !isValid,
          isPristine: isPristine,
          isDirty: !isPristine,
          isEditable: props.isEditable,
        })
      : props.children

  return (
    <FormContext.Provider
      value={{
        store: store.current,
        form: engine.current,
        data: data,
        initialData: initialData,
        lastUpdatedInput: lastUpdatedInput,
        isCompact: props.isCompact,
        isEditable: props.isEditable,
        defaultDebounce: props.defaultDebounce,
        timezone: props.timezone,
        '👽': engine.current,
      }}
    >
      <Tag key={props.key} onSubmit={props.onSubmit} className={classNames} css={STYLES.form}>
        {/* used to move focus on form so user can start tabbing through inputs */}
        {autoFocus && <button autoFocus type="button" css={STYLES.autoFocusButton} />}

        {children}
      </Tag>
    </FormContext.Provider>
  )
}

const STYLES = {
  autoFocusButton: {
    width: '0 !important',
    height: '0 !important',
    position: 'absolute !important',
    opacity: '0 !important',
    outline: 'none !important',
  },

  form: {
    '&.is-compact': {
      '--input-min-height': '30px',
      '--input-font-size': '0.9rem',

      // border: '3px solid blue',
    },
  },
}

Form.defaultProps = {
  as: 'form',
  isEditable: true,
  defaultDebounce: 0,
  useLocalModel: false,
  useFullModel: false,
  deregisterOnUnmount: true,
}

Form.displayName = 'BHForm'

export default Form
