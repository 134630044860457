import React from 'react'

import { useSettings } from '../../hooks'
import { OUTCOME_MEASURES_TITLES } from '../../utils/constants'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink
        to={to(row.original.id, row.original.subcategory)}
        label={value || OUTCOME_MEASURES_TITLES?.[row.original?.subcategory]}
      />
    ),
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  help?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
  isBehave?: boolean
}

const OutcomeMeasuresTable = (props: Props) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    help,
    hiddenColumns,
    isLoading,
    batchActionsConfig,
    localStorageKey,
    title,
    to,
    titleAfter,
    isBehave,
  } = props

  const { timezone } = useSettings()

  return (
    <Table
      title={title}
      testKey="outcome_measures_table"
      titleAfter={titleAfter}
      icon="outcome_measures"
      data={data}
      columns={columns(to, timezone)}
      hiddenColumns={hiddenColumns}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      help={help}
      isBehave={isBehave}
    />
  )
}

OutcomeMeasuresTable.defaultProps = {
  title: 'Outcome Measures',
  emptyDescription: 'No outcome measures added yet',
  localStorageKey: 'outcome_measures',
}

export default OutcomeMeasuresTable
