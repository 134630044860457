import React from 'react'

import Chart from '@behavehealth/components/Chart'

const data = [
  ['2017-01-01', 4963],
  ['2017-01-02', 4646],
  ['2017-01-03', 3177],
  ['2017-01-04', 9271],
  ['2017-01-05', 5966],
  ['2017-01-06', 6494],
  ['2017-01-07', 5748],
  ['2017-01-08', 4122],
  ['2017-01-09', 7783],
  ['2017-01-10', 4405],
  ['2017-01-11', 8618],
  ['2017-01-12', 6584],
  ['2017-01-13', 2542],
  ['2017-01-14', 8473],
  ['2017-01-15', 766],
  ['2017-01-16', 8931],
  ['2017-01-17', 2297],
  ['2017-01-18', 4815],
  ['2017-01-19', 9173],
  ['2017-01-20', 7538],
  ['2017-01-21', 5493],
  ['2017-01-22', 6157],
  ['2017-01-23', 2500],
  ['2017-01-24', 6638],
  ['2017-01-25', 9834],
  ['2017-01-26', 1747],
  ['2017-01-27', 817],
  ['2017-01-28', 2331],
  ['2017-01-29', 3400],
  ['2017-01-30', 7449],
  ['2017-01-31', 139],
  ['2017-02-01', 2502],
  ['2017-02-02', 3380],
  ['2017-02-03', 1166],
  ['2017-02-04', 8987],
  ['2017-02-05', 2653],
  ['2017-02-06', 7114],
  ['2017-02-07', 3097],
  ['2017-02-08', 9305],
  ['2017-02-09', 9308],
  ['2017-02-10', 1432],
  ['2017-02-11', 4305],
  ['2017-02-12', 5756],
  ['2017-02-13', 7306],
  ['2017-02-14', 9633],
  ['2017-02-15', 2437],
  ['2017-02-16', 1517],
  ['2017-02-17', 5699],
  ['2017-02-18', 3530],
  ['2017-02-19', 4695],
  ['2017-02-20', 1114],
  ['2017-02-21', 5153],
  ['2017-02-22', 923],
  ['2017-02-23', 5498],
  ['2017-02-24', 5395],
  ['2017-02-25', 6906],
  ['2017-02-26', 8351],
  ['2017-02-27', 4066],
  ['2017-02-28', 7663],
  ['2017-03-01', 2566],
  ['2017-03-02', 4854],
  ['2017-03-03', 7729],
  ['2017-03-04', 6920],
  ['2017-03-05', 252],
  ['2017-03-06', 1644],
  ['2017-03-07', 2545],
  ['2017-03-08', 8043],
  ['2017-03-09', 555],
  ['2017-03-10', 5667],
  ['2017-03-11', 7721],
  ['2017-03-12', 417],
  ['2017-03-13', 2528],
  ['2017-03-14', 1914],
  ['2017-03-15', 4163],
  ['2017-03-16', 6991],
  ['2017-03-17', 6557],
  ['2017-03-18', 1216],
  ['2017-03-19', 9118],
  ['2017-03-20', 5619],
  ['2017-03-21', 9132],
  ['2017-03-22', 8399],
  ['2017-03-23', 370],
  ['2017-03-24', 1242],
  ['2017-03-25', 505],
  ['2017-03-26', 7391],
  ['2017-03-27', 5722],
  ['2017-03-28', 3216],
  ['2017-03-29', 5648],
  ['2017-03-30', 5334],
  ['2017-03-31', 5998],
  ['2017-04-01', 7748],
  ['2017-04-02', 5246],
  ['2017-04-03', 2999],
  ['2017-04-04', 1694],
  ['2017-04-05', 6028],
  ['2017-04-06', 9796],
  ['2017-04-07', 8900],
  ['2017-04-08', 2016],
  ['2017-04-09', 9251],
  ['2017-04-10', 6764],
  ['2017-04-11', 3069],
  ['2017-04-12', 9666],
  ['2017-04-13', 7517],
  ['2017-04-14', 8820],
  ['2017-04-15', 7519],
  ['2017-04-16', 9601],
  ['2017-04-17', 1006],
  ['2017-04-18', 7406],
  ['2017-04-19', 9161],
  ['2017-04-20', 8162],
  ['2017-04-21', 3103],
  ['2017-04-22', 6279],
  ['2017-04-23', 7520],
  ['2017-04-24', 3407],
  ['2017-04-25', 981],
  ['2017-04-26', 6450],
  ['2017-04-27', 21],
  ['2017-04-28', 6984],
  ['2017-04-29', 4608],
  ['2017-04-30', 4510],
  ['2017-05-01', 3250],
  ['2017-05-02', 3038],
  ['2017-05-03', 7289],
  ['2017-05-04', 6574],
  ['2017-05-05', 824],
  ['2017-05-06', 9568],
  ['2017-05-07', 5294],
  ['2017-05-08', 8460],
  ['2017-05-09', 7510],
  ['2017-05-10', 3720],
  ['2017-05-11', 9780],
  ['2017-05-12', 4904],
  ['2017-05-13', 2468],
  ['2017-05-14', 1077],
  ['2017-05-15', 5232],
  ['2017-05-16', 6249],
  ['2017-05-17', 750],
  ['2017-05-18', 4934],
  ['2017-05-19', 8509],
  ['2017-05-20', 6300],
  ['2017-05-21', 4084],
  ['2017-05-22', 5651],
  ['2017-05-23', 9707],
  ['2017-05-24', 8048],
  ['2017-05-25', 9616],
  ['2017-05-26', 3473],
  ['2017-05-27', 5107],
  ['2017-05-28', 3185],
  ['2017-05-29', 2151],
  ['2017-05-30', 6644],
  ['2017-05-31', 5505],
  ['2017-06-01', 6569],
  ['2017-06-02', 2242],
  ['2017-06-03', 7992],
  ['2017-06-04', 4016],
  ['2017-06-05', 6236],
  ['2017-06-06', 3127],
  ['2017-06-07', 4904],
  ['2017-06-08', 2869],
  ['2017-06-09', 8210],
  ['2017-06-10', 7426],
  ['2017-06-11', 2664],
  ['2017-06-12', 1424],
  ['2017-06-13', 534],
  ['2017-06-14', 3521],
  ['2017-06-15', 2287],
  ['2017-06-16', 1885],
  ['2017-06-17', 3119],
  ['2017-06-18', 8738],
  ['2017-06-19', 497],
  ['2017-06-20', 7432],
  ['2017-06-21', 9187],
  ['2017-06-22', 3845],
  ['2017-06-23', 9009],
  ['2017-06-24', 5446],
  ['2017-06-25', 408],
  ['2017-06-26', 4098],
  ['2017-06-27', 8121],
  ['2017-06-28', 6962],
  ['2017-06-29', 9040],
  ['2017-06-30', 1980],
  ['2017-07-01', 4027],
  ['2017-07-02', 9275],
  ['2017-07-03', 7777],
  ['2017-07-04', 335],
  ['2017-07-05', 8386],
  ['2017-07-06', 9557],
  ['2017-07-07', 2630],
  ['2017-07-08', 8637],
  ['2017-07-09', 8278],
  ['2017-07-10', 9841],
  ['2017-07-11', 7133],
  ['2017-07-12', 4321],
  ['2017-07-13', 6016],
  ['2017-07-14', 7111],
  ['2017-07-15', 4303],
  ['2017-07-16', 4644],
  ['2017-07-17', 6161],
  ['2017-07-18', 5229],
  ['2017-07-19', 7316],
  ['2017-07-20', 3063],
  ['2017-07-21', 8688],
  ['2017-07-22', 6018],
  ['2017-07-23', 7280],
  ['2017-07-24', 3912],
  ['2017-07-25', 4074],
  ['2017-07-26', 3747],
  ['2017-07-27', 7294],
  ['2017-07-28', 4423],
  ['2017-07-29', 8837],
  ['2017-07-30', 5241],
  ['2017-07-31', 7536],
  ['2017-08-01', 3395],
  ['2017-08-02', 8803],
  ['2017-08-03', 778],
  ['2017-08-04', 6697],
  ['2017-08-05', 7546],
  ['2017-08-06', 1646],
  ['2017-08-07', 2562],
  ['2017-08-08', 2401],
  ['2017-08-09', 3136],
  ['2017-08-10', 2748],
  ['2017-08-11', 8148],
  ['2017-08-12', 3868],
  ['2017-08-13', 356],
  ['2017-08-14', 3034],
  ['2017-08-15', 4415],
  ['2017-08-16', 4295],
  ['2017-08-17', 2438],
  ['2017-08-18', 2562],
  ['2017-08-19', 9989],
  ['2017-08-20', 8495],
  ['2017-08-21', 9239],
  ['2017-08-22', 7563],
  ['2017-08-23', 2744],
  ['2017-08-24', 9299],
  ['2017-08-25', 2601],
  ['2017-08-26', 8153],
  ['2017-08-27', 1372],
  ['2017-08-28', 5937],
  ['2017-08-29', 7536],
  ['2017-08-30', 73],
  ['2017-08-31', 4366],
  ['2017-09-01', 1952],
  ['2017-09-02', 5254],
  ['2017-09-03', 3042],
  ['2017-09-04', 3736],
  ['2017-09-05', 9428],
  ['2017-09-06', 5179],
  ['2017-09-07', 85],
  ['2017-09-08', 7068],
  ['2017-09-09', 6707],
  ['2017-09-10', 8587],
  ['2017-09-11', 2749],
  ['2017-09-12', 2738],
  ['2017-09-13', 1324],
  ['2017-09-14', 593],
  ['2017-09-15', 5418],
  ['2017-09-16', 6602],
  ['2017-09-17', 6458],
  ['2017-09-18', 4158],
  ['2017-09-19', 7007],
  ['2017-09-20', 7480],
  ['2017-09-21', 2873],
  ['2017-09-22', 9830],
  ['2017-09-23', 2304],
  ['2017-09-24', 9744],
  ['2017-09-25', 6063],
  ['2017-09-26', 7323],
  ['2017-09-27', 712],
  ['2017-09-28', 3726],
  ['2017-09-29', 4390],
  ['2017-09-30', 9665],
  ['2017-10-01', 5983],
  ['2017-10-02', 5516],
  ['2017-10-03', 7360],
  ['2017-10-04', 652],
  ['2017-10-05', 7899],
  ['2017-10-06', 5093],
  ['2017-10-07', 9154],
  ['2017-10-08', 6319],
  ['2017-10-09', 7227],
  ['2017-10-10', 6915],
  ['2017-10-11', 4677],
  ['2017-10-12', 3019],
  ['2017-10-13', 1818],
  ['2017-10-14', 2198],
  ['2017-10-15', 7417],
  ['2017-10-16', 6343],
  ['2017-10-17', 3676],
  ['2017-10-18', 7997],
  ['2017-10-19', 3050],
  ['2017-10-20', 691],
  ['2017-10-21', 7703],
  ['2017-10-22', 2357],
  ['2017-10-23', 1405],
  ['2017-10-24', 1702],
  ['2017-10-25', 3056],
  ['2017-10-26', 628],
  ['2017-10-27', 6396],
  ['2017-10-28', 9621],
  ['2017-10-29', 9368],
  ['2017-10-30', 4955],
  ['2017-10-31', 123],
  ['2017-11-01', 6337],
  ['2017-11-02', 2443],
  ['2017-11-03', 2023],
  ['2017-11-04', 5211],
  ['2017-11-05', 1096],
  ['2017-11-06', 4528],
  ['2017-11-07', 2704],
  ['2017-11-08', 9451],
  ['2017-11-09', 2132],
  ['2017-11-10', 3178],
  ['2017-11-11', 2137],
  ['2017-11-12', 6857],
  ['2017-11-13', 7458],
  ['2017-11-14', 887],
  ['2017-11-15', 5421],
  ['2017-11-16', 7913],
  ['2017-11-17', 116],
  ['2017-11-18', 6843],
  ['2017-11-19', 6115],
  ['2017-11-20', 6638],
  ['2017-11-21', 8168],
  ['2017-11-22', 6941],
  ['2017-11-23', 141],
  ['2017-11-24', 3546],
  ['2017-11-25', 1489],
  ['2017-11-26', 832],
  ['2017-11-27', 4717],
  ['2017-11-28', 1773],
  ['2017-11-29', 3167],
  ['2017-11-30', 6228],
  ['2017-12-01', 2448],
  ['2017-12-02', 3867],
  ['2017-12-03', 3941],
  ['2017-12-04', 6225],
  ['2017-12-05', 9548],
  ['2017-12-06', 6277],
  ['2017-12-07', 7151],
  ['2017-12-08', 5785],
  ['2017-12-09', 676],
  ['2017-12-10', 6998],
  ['2017-12-11', 7808],
  ['2017-12-12', 3694],
  ['2017-12-13', 2346],
  ['2017-12-14', 4124],
  ['2017-12-15', 3313],
  ['2017-12-16', 2940],
  ['2017-12-17', 4840],
  ['2017-12-18', 6295],
  ['2017-12-19', 8330],
  ['2017-12-20', 497],
  ['2017-12-21', 981],
  ['2017-12-22', 6376],
  ['2017-12-23', 5087],
  ['2017-12-24', 7262],
  ['2017-12-25', 7187],
  ['2017-12-26', 5494],
  ['2017-12-27', 830],
  ['2017-12-28', 7911],
  ['2017-12-29', 4502],
  ['2017-12-30', 5947],
  ['2017-12-31', 3850],
]

const option = {
  tooltip: {
    trigger: 'item',
  },
  visualMap: {
    show: false,
    min: 0,
    max: 10000,
  },
  calendar: {
    range: '2017',
  },
  series: {
    type: 'heatmap',
    coordinateSystem: 'calendar',
    data,
  },
}

const ChartExample = (props) => {
  return <Chart option={option} {...props} />
}

export default ChartExample
