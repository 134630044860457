import React from 'react'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { COLORS } from '../../../../theme'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import { apiCreate, apiUpdate } from '../../../../modules/api'
import AdmissionSourceSelect from '../../../Forms/AdmissionSourceSelect'
import AdmissionTypeSelect from '../../../Forms/AdmissionTypeSelect'
import AmountInput from '../../../Forms/AmountInput'
import Button from '../../../Button'
import Card from '../../../Card'
import ClaimBillTypeSelector from '../../../Forms/ClaimBillTypeSelector'
import ClientProfileHeader from '../../../ClientProfileHeader'
import ConditionCodesSelector from '../../../Forms/elements/ConditionCodesSelector'
import DataArray from '../../../Forms/DataArray'
import Flex from '../../../Flex'
import Form from '../../../Forms/Form'
import Grid from '../../../Grid'
import GridTable from '../../../GridTable'
import Input from '../../../Forms/Input'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import Section from '../../../Section'
import Status from '../../../Status'
import Type from '../../../Typography/Type'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../OverlayBase'

import ServiceLines from './components/ServiceLines'

class ClaimOverlay extends OverlayBase {
  submit = async () => {
    await apiUpdate({
      name: 'insurance_claims',
      url: `insurance_claims/${this.props.record.id}/submit`,
    })
    this.close()
  }

  render = () => {
    const { $new, $editable, isInvalid }: any = this.state
    const { record, timezone, loading }: any = this.props

    if (!$new && size(record) === 0) {
      return <OverlayLoader position={this.props.position} showBackdrop={this.props.showBackdrop} />
    }

    return (
      <Overlay showBackdrop position="center" maxWidth={90} onClose={this.close}>
        <Overlay.Header
          icon="invoices"
          title="Claim"
          description={<ClientProfileHeader client={this.props.record?.client || this.props.current} />}
        />

        <Overlay.Content>
          <Section>
            <Form
              useFullModel
              getForm={this.form}
              initialModel={record}
              isEditable={$editable}
              timezone={timezone}
              onValidationUpdate={this.onValidationUpdate}
              linked={{ resident_id: this.props.match?.params?.resource_id }}
            >
              <div css={styles.columns}>
                <Grid gap="1rem" autoRows="min-content">
                  <Type as="h4" variant="H4" css={styles.heading}>
                    Claim Info
                  </Type>
                  <ObjectSelector
                    label="Insurance Policy"
                    icon="insurance"
                    model="insurance_policy"
                    type="client.insurance_policies"
                    dependentValue={this.props.match?.params?.resource_id}
                    selectTitle={(data: any) => data.insurance_name}
                    selectDescription={(data: any) => (
                      <>
                        <Status label={data.id} color="lightGray" />
                        <Status label={data.category} color="gray" />
                      </>
                    )}
                    validations={{
                      presence: {
                        message: 'Please select an insurance policy to run claim',
                      },
                    }}
                  />
                  <ObjectSelector
                    label="Authorization Number"
                    type="client.insurance_authorizations"
                    model="insurance_authorization"
                    selectTitle={(data: any) => data.identifier}
                    selectDescription={(data: any) => `Sessions Authorized: ${data.sessions || 0} Used: ${data.used_sessions || 0}`}
                    dependentValue={this.props.match?.params?.resource_id}
                  />
                  <AdmissionTypeSelect label="Admission Type" model="admission_type" />
                  <AdmissionSourceSelect label="Admission Source" model="admission_source" />
                  <ClaimBillTypeSelector label="Type of Bill" model="bill_type" />
                  <ConditionCodesSelector model="condition_codes" />
                  {/* <ObjectSelector label="Rendering Provider" model="rendering_provider" isEditable={false} /> */}
                  <Input
                    label="Rendering Provider Name"
                    value={`${this.props.tenant?.provider?.npi_first_name} ${this.props.tenant?.provider?.npi_last_name}`}
                    isEditable={false}
                  />
                  <Input label="Rendering Provider NPI#" value={this.props.tenant?.provider?.npi_number} isEditable={false} />
                </Grid>
                <Grid gap="1rem" autoRows="min-content">
                  <Type as="h4" variant="H4" css={styles.heading}>
                    Diagnosis Codes
                  </Type>
                  <ObjectSelector label="Principal Diagnosis Code" model="principal_diagnosis_code" />
                  <ObjectSelector label="Admitting Diagnosis" model="admitting_diagnosis" />
                  <ObjectSelector label="Additional Diagnoses" model="additional_diagnoses" />
                </Grid>
              </div>

              <Type as="h4" variant="H4" css={styles.heading}>
                Value Codes
              </Type>

              <DataArray model="value_codes">
                {({ ids, add, remove }: any) => (
                  <>
                    <Card className="w-[380px]">
                      <GridTable templateColumns="120px 180px 80px">
                        <GridTable.Header>
                          <GridTable.Cell>Code</GridTable.Cell>
                          <GridTable.Cell>Amount</GridTable.Cell>
                          {$editable && <GridTable.Cell>Remove</GridTable.Cell>}
                        </GridTable.Header>

                        {ids.length === 0 && (
                          <div css={styles.emptyState}>
                            <div>No value codes added yet</div>
                          </div>
                        )}

                        {ids.map((id: string) => (
                          <GridTable.Row key={id}>
                            <GridTable.Cell>
                              <Input model={`${id}.code`} defaultValue="01" />
                            </GridTable.Cell>

                            <GridTable.Cell>
                              <AmountInput model={`${id}.amount`} />
                            </GridTable.Cell>

                            <GridTable.Cell>
                              {$editable ? <Button hideLabel glyph="delete" type="link" color="red" onClick={() => remove(id)} /> : '–'}
                            </GridTable.Cell>
                          </GridTable.Row>
                        ))}
                      </GridTable>
                    </Card>

                    {$editable && (
                      <Button
                        label="Add Value Code"
                        glyph="add"
                        type="link"
                        display="inline-flex"
                        size={200}
                        onClick={add}
                        css={styles.button}
                      />
                    )}
                  </>
                )}
              </DataArray>

              <Type as="h4" variant="H4" css={styles.heading}>
                Service Lines
              </Type>

              <ServiceLines model="service_lines" />
            </Form>
          </Section>
        </Overlay.Content>

        <Overlay.Footer>
          {$editable && (
            <Flex stretchChildrenX gap={8}>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={loading}
                onClick={this.save}
                isDisabled={isInvalid}
                flex="3 1 auto"
                // permission="claims.edit"
              />

              {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={loading} onClick={this.cancel} flex="0 1 auto" />}
            </Flex>
          )}

          {!$editable && (
            <Flex stretchChildrenX gap={8}>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={loading}
                onClick={this.edit}
                flex="1 1 auto"
                // permission="claims.create"
              />

              <Button
                label="Submit Claim"
                glyph="check"
                type="primary"
                color="blue"
                isLoading={loading}
                isDisabled={isInvalid}
                onClick={this.submit}
                flex="1 1 auto"
                // permission="claims.edit"
              />
            </Flex>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const styles = {
  heading: {
    marginTop: '1.5rem',
    marginBottom: '0.75rem',
  },

  columns: {
    maxWidth: 800,

    h4: {
      marginTop: '0 !important',
      marginBottom: '0 !important',
    },

    '& > *': {
      flex: '1 1 auto',
    },

    '& > *:first-child': {
      marginBottom: '1rem',
    },

    '@media (min-width: 800px)': {
      display: 'flex',

      '& > *:first-child': {
        marginRight: '1rem',
        paddingRight: '1rem',
        borderRight: `1px solid ${COLORS.divider}`,
      },
    },
  },

  button: {
    marginTop: '1rem',
  },

  emptyState: {
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
    padding: '1rem',
    color: COLORS.textMuted,
    background: COLORS.hover,
    opacity: 0.8,
  },
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_claims')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(ClaimOverlay))
