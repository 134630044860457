import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'

import { css, COLORS, SHADOW } from '../../theme'
import { formatURL } from '../../utils/functions'

import Iframe from '../../components/Iframe'

export const CustomPageScreenshot = ({ data }: any) => {
  if (!data?.screenshot_url) return null

  return (
    <div className={STYLES.root()}>
      <div className={clsx(STYLES.hoverBanner().className, 'hover-banner')}>Click on image to open website in a new tab</div>

      <a href={formatURL(data?.url)} target="_blank" rel="noopener noreferrer">
        <img src={data.screenshot_url} className={STYLES.image()} />
      </a>
    </div>
  )
}

const STYLES = {
  root: css({
    // padding: '1rem',
    height: 'fit-content',
    position: 'relative',
    minHeight: 100,
    borderRadius: 5,
    boxShadow: SHADOW(3),
    overflow: 'hidden',

    '&:hover': {
      '.hover-banner': {
        opacity: 1,
        transform: 'translateY(0)',
      },

      img: {
        opacity: 0.7,
        transform: 'translateY(-5px)',
      },
    },
  }),

  hoverBanner: css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    padding: '0.75rem 1rem',
    textAlign: 'center',
    opacity: 0,
    pointerEvents: 'none',
    zIndex: 1,
    background: tint(0.8, COLORS.gold),
    borderBottom: `1px solid ${tint(0.6, COLORS.gold)}`,
    transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
    transform: 'translateY(-6px)',
    boxShadow: SHADOW(3),
    fontWeight: 600,
  }),

  image: css({
    display: 'block',
    width: '100%',
    cursor: 'pointer',
    transition: 'opacity 0.15s ease-in-out, transform 0.15s ease-in-out',
  }),
}
