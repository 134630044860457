import React from 'react'
import size from 'lodash/size'

import { useDataTable } from '../../../DataTable/useDataTable'
import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'

export const FeatureFlagConfigsTable = ({ onSelect }: any) => {
	const tableProps = useDataTable({
		name: ['selector_feature_flag_configs'],
		endpoint: '/feature_flag_configs',
		localStorageKey: 'selector_feature_flag_configs_v1'
	})

	const columns = React.useMemo(
		() => [
			{
				title: 'Name',
				model: 'name',
				width: 300,
				disableHide: true,
				formatValue: ({ data }: any) => (
					<MainCell id={data.id} value={data.name} onClick={onSelect ? () => onSelect(data) : undefined} />
				)
			},
			{
				title: 'Tags',
				model: 'global_tags',
				type: 'global_tags',
				editPermission: true
			},
			{
				title: 'Description',
				model: 'description',
				type: 'rich_text',
				width: 300
			},
			{
				title: 'Enabled Feature Flags',
				id: 'count',
				formatValue: ({ data }: any) => {
					if (!data?.config) return null

					const allCount = size(data.config)
					const enabledCount = size(Object.values(data.config).filter((c: any) => c === true))

					return `${enabledCount} / ${allCount}`
				}
			},
			{
				title: 'Date Added',
				model: 'created_at',
				type: 'date_time'
			},
			{
				title: 'Date Updated',
				model: 'updated_at',
				type: 'date_time'
			}
		],
		[]
	)

	return (
		<DataTable
			{...tableProps}
			asCard
			title="Feature Flag Configs"
			icon="feature_flags"
			columns={columns}
			onSelect={onSelect}
		/>
	)
}
