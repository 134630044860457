import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { COLORS } from '@behavehealth/theme'
import { countWord, usDate } from '@behavehealth/utils/functions'
import { LOC } from '@behavehealth/utils/constants'
import { useAPI } from '@behavehealth/hooks'
import { useSelector } from 'react-redux'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'
import withSettings from '@behavehealth/hocs/withSettings'

import { Button, Flex, Card, Link, CardTitle, Calendar, HelpTagIframe, Page, Table, TableCell, TableFilter } from '@behavehealth/components'

import URBillingStatus from '@behavehealth/components/Statuses/URBillingStatus'
import BillingRosterStatus from '@behavehealth/components/Statuses/BillingRosterStatus'

const pageConfig = {
  feature: 'billing_rosters',
  help: <HelpTagIframe id="billing_rosters" />,
  marketingID: 'billing_rosters',
}

const BillingRosters: React.FC = ({ timezone }: any) => {
  const match = useRouteMatch()
  const data = useSelector((state) => state.data.billing_day_reviews?.data)
  const user = useSelector((state: any) => state.me?.user)

  const isBehave = user?.type === 'bh_employee'

  useAPI('billing_day_reviews', `/residents/${match.params?.resource_id}/billing_day_reviews`)

  return (
    <Page
      breakpoint="0"
      actions={<Button type="primary" glyph="add" label="Add Roster" link={`${match.url}/new`} permission={isBehave} />}
      {...pageConfig}
    >
      <Calendar
        events={data}
        isLoading={false}
        timezone={timezone}
        startDateKey="started_at"
        defaultView="agenda"
        defaultMode="months"
        setTableColumns={setTableColumns}
        to={(id: string) => `${match.url}/${id}`}
        localStorageKey="clients_billing_roster"
        renderEvent={(data: any) => (
          <Link to={`${match.url}/${data.id}`} css={styles.link}>
            <Card css={styles.card}>
              <Flex gap={6}>
                <CardTitle
                  title={`${data.documented_hours} / ${data.insurance_authorization.required_daily_hours} documented hrs`}
                  css={styles.title}
                />
                <BillingRosterStatus status={data.status} css={styles.status} />
                {/* TODO: show statuses for bh employees only */}
                <URBillingStatus setLabel={(status: string) => `UR ${status}`} status={data.ur_status} css={styles.status} />
                <URBillingStatus setLabel={(status: string) => `Billing ${status}`} status={data.billing_status} css={styles.status} />
              </Flex>

              <div css={styles.description}>{LOC[data.authorized_level_of_care]}</div>
            </Card>
          </Link>
        )}
        batchActionsConfig={[
          {
            // permission: 'rosters.update',
            glyph: 'check',
            color: 'green',
            action: async ({ ids }: any) => {},
            label: (count: number) => `Approve ${countWord('Rosters', count)}`,
          },
        ]}
      />
    </Page>
  )
}

const setTableColumns = (to: Function = () => {}, timezone: string) => [
  {
    width: 200,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'started_at',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={usDate(value, timezone)} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <BillingRosterStatus status={value} />,
  },
  {
    width: 300,
    accessor: 'insurance_authorization.level',
    Header: 'Authorized LoC',
    Cell: ({ value }: any) => <TableCell.Truncated value={LOC[value]} />,
  },
  {
    width: 180,
    accessor: 'ur_status',
    Header: 'UR Sign-Off',
    Cell: ({ value }: any) => <URBillingStatus status={value} />,
  },
  {
    width: 180,
    accessor: 'billing_status',
    Header: 'Billing Sign-Off',
    Cell: ({ value }: any) => <URBillingStatus status={value} />,
  },
  {
    width: 120,
    accessor: 'insurance_authorization.used_sessions',
    Header: 'Sessions Used',
    Cell: ({ value }: any) => `${value}`,
  },
  {
    width: 140,
    accessor: 'insurance_authorization.required_daily_hours',
    Header: 'Insurance LOC Expectation',
    Cell: ({ value }: any) => `${value} hours`,
  },
  {
    width: 140,
    accessor: 'program_calendar_hours',
    Header: 'Program Calendar Hours',
    Cell: ({ value }: any) => `${value} hours`,
  },
  {
    width: 140,
    accessor: 'documented_hours',
    Header: 'Documented Hours',
    Cell: ({ value }: any) => `${value} hours`,
  },
  {
    width: 140,
    accessor: 'undocumented_hours',
    Header: 'Undocumented Hours',
    Cell: ({ value }: any) => `${value} hours`,
  },
  {
    width: 300,
    accessor: 'customer_comments',
    Header: 'Customer Comments',
    Cell: ({ value }: any) => value,
  },
  {
    width: 300,
    accessor: 'ur_comments',
    Header: 'Behave UR Comments',
    Cell: ({ value }: any) => value,
  },
  {
    width: 300,
    accessor: 'billing_comments',
    Header: 'Behave Billing Comments',
    Cell: ({ value }: any) => value,
  },
  {
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    accessor: 'updated_at',
    Header: 'Date Updated',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

const styles = {
  link: {
    display: 'block',
  },

  card: {
    padding: '0.25rem 0.4rem',
  },

  title: {
    fontSize: '0.9rem',
    color: COLORS.link,
  },

  description: {
    fontSize: '0.9rem',
    color: COLORS.textMuted,
    marginTop: '0.2rem',
  },

  status: {
    fontSize: '0.78rem',
  },
}

const DATA = [
  {
    id: 1,
    status: 'draft',
    authorized_level_of_care: 'medically_monitored_inpatient_detoxification',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'pending_review',
    billing_status: 'pending_review',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-10T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
  },
  {
    id: 2,
    status: 'draft',
    authorized_level_of_care: 'clinically_managed_residential_detoxification',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'pending_review',
    billing_status: 'signed_off',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-11T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
  },
  {
    id: 3,
    status: 'approved',
    authorized_level_of_care: 'partial_hospitalization_services',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'rejected',
    billing_status: 'rejected',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-12T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
  },
  {
    id: 4,
    status: 'approved',
    authorized_level_of_care: 'ambulatory_detoxification_with_extended_on_site_monitoring',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'signed_off',
    billing_status: 'signed_off',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-13T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
  },
  {
    id: 5,
    status: 'pending_review',
    authorized_level_of_care: 'ambulatory_detoxification_with_extended_on_site_monitoring',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'signed_off',
    billing_status: 'signed_off',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-13T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
  },
]

export default withPageError(withSettings(withMarketing(BillingRosters, pageConfig)))
