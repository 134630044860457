import React from 'react'
import { connect } from 'react-redux'
import produce from 'immer'

import { ICONS } from '../../theme'
import { titleCase } from '../../utils/functions'
import { apiCreate, apiUpdate, apiDelete } from '../../modules/api'

import AmountInput from '../Forms/AmountInput'
import Button from '../Button'
import Checkbox from '../Forms/Checkbox'
import CheckboxGroup from '../Forms/CheckboxGroup'
import ContextShow from '../Forms/ContextShow'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Dialog from '../Dialog'
import Flex from '../Flex'
import Form from '../Forms/Form'
import FormSection from '../Forms/FormSection'
import Icon from '../Icon'
import Input from '../Forms/Input'
import ObjectSelector from '../Forms/Selectors/Object/ObjectSelector'
import Option from '../Forms/Option'
import Overlay from '../Overlay'
import RichTextEditor from '../Forms/RichTextEditor'
import Section from '../Section'
import Select from '../Forms/Select'
import URLInput from '../Forms/URLInput'
import UserTypeSelect from './UserTypeSelect'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../Overlays/pages/OverlayBase'

import {
  GENERAL_FEATURES,
  ERP_FEATURES,
  CONTACTS_FEATURES,
  CHAT_FEATURES,
  CARE_COORDINATION_FEATURES,
  RCM_FEATURES,
  INSURANCE_FEATURES,
  NOTES_FEATURES,
  MEDICAL_FEATURES,
  CLINICAL_FEATURES,
  SECURITY_FEATURES,
  PRICING_UNIT_TYPES,
  YEARLY_PRICING_UNIT_TYPES,
} from './constants'

const icons = Object.entries(ICONS).map(([key]) => ({
  id: key,
  name: titleCase(key),
}))

const FeaturesCheckboxGroup = ({ label, data }: any) => {
  if (!data) return null

  return (
    <CheckboxGroup withToggle label={label} layout="vertical-dense" trueIcon="check" falseStyle="hidden" falseIcon="cross">
      {data.map((feature: any) => (
        <Checkbox key={feature.model} icon={feature.icon?.id || 'default'} label={feature.title} model={`features.${feature.model}`} />
      ))}
    </CheckboxGroup>
  )
}

class AddonOverlay extends OverlayBase {
  save = async () => {
    const { $new } = this.state
    const { match, type, history, location } = this.props

    let request = null
    let model = this.form.current.getFormValue()

    // alter the model data
    model = produce(model, (draft) => {
      draft.icon = model.icon_id
      delete draft['icon_id']
    })

    if ($new) {
      request = await apiCreate({
        name: 'addons',
        url: '/products',
        params: model,
      })
    } else {
      request = await apiUpdate({
        name: 'addons',
        url: `/products/${this.state.id || match?.params?.id}`,
        params: model,
      })
    }

    await this.onSaveSuccessful()
    if (this.props.onSaveSuccessful) this.props.onSaveSuccessful(request.data.data)

    if (type === 'summon' && $new) this.close()
    else if (type === 'route' && $new) {
      history.push({
        pathname: `${request.data.data.id}`,
        parent: location.parent,
      })
    }

    this.setState({ $editable: false })
  }

  delete = async () => {
    const { match } = this.props

    await apiDelete({
      name: 'addons',
      url: `/products/${this.state.id || match?.params?.id}`,
    })

    await this.onDeleteSuccessful()

    this.close()
  }

  toggleArchive = async () => {
    try {
      await apiUpdate({
        name: 'addons',
        url: `/products/${this.props.record?.id}`,
        params: {
          status: this.props.record?.status === 'archived' ? 'active' : 'archived',
        },
        notify: false,
      })

      this.close()
    } catch (errors) {
      console.error(errors)
    }
  }

  render() {
    const { $editable, isInvalid, params } = this.state
    const { record, online, loading } = this.props

    let data = { ...params, ...record }
    for (let i = 0; i < icons.length; i++) {
      if (icons[i].id === data.icon) {
        data.icon = icons[i]
        break
      }
    }

    return (
      <Overlay onClose={this.close} showBackdrop={$editable}>
        <Overlay.Header title={titleCase(data?.category) || '–'} />

        <Overlay.Content>
          <Form
            getForm={this.form}
            initialModel={data}
            isEditable={$editable}
            onValidationUpdate={this.onValidationUpdate}
            linked={{
              category: 'addon',
            }}
          >
            <Section>
              <FormSection>
                <Input
                  label="Internal Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a name',
                    },
                  }}
                />

                <Input label="Public Name" model="public_name" />

                <URLInput label="Notion Link" model="notion_url" />

                <RichTextEditor label="Description" model="description" />

                <ObjectSelector
                  isRelation={false}
                  label="Icon"
                  model="icon"
                  apiData={icons}
                  value={data?.icon}
                  selectTitle={(data: any) => (
                    <Flex nowrap centerY gap="0.5rem">
                      <Icon icon={data.id} size={20} />
                      <div>{data.name}</div>
                    </Flex>
                  )}
                />

                <Select
                  allowEmpty
                  label="Pricing Strategy"
                  model="pricing_type"
                  layout="vertical-dense"
                  validations={{
                    presence: {
                      message: 'Please select a pricing strategy',
                    },
                  }}
                >
                  <Option label="Monthly Subscription" value="monthly" />
                  <Option label="Metered (Monthly)" value="metered_monthly" />

                  <Option label="Metered (Annually)" value="metered_annually" />
                  <Option label="Annual Subscription" value="annually" />

                  <Option label="One-Time Payment" value="one_time" />
                  <Option label="Free" value="free" />
                </Select>

                <Select
                  allowEmpty
                  label="Billed"
                  model="bill_type"
                  layout="vertical-dense"
                  defaultValue="end_of_cycle"
                  validations={{
                    presence: {
                      message: 'Please select a billing type',
                    },
                  }}
                >
                  <Option label="At the End of Cycle" value="end_of_cycle" />
                  <Option label="Upfront" value="upfront" />
                </Select>

                <Flex stretchChildrenX gap="1rem">
                  <AmountInput
                    label="Price"
                    model="price"
                    validations={{
                      presence: {
                        message: 'Please enter a price',
                      },
                    }}
                  />

                  <ContextShow when="pricing_type" is="metered_monthly">
                    <Select label="Unit Type" model="price_unit_type" defaultValue="client_per_month">
                      {Object.entries(PRICING_UNIT_TYPES).map(([key, value]) => (
                        <Option label={value} value={key} />
                      ))}
                    </Select>
                  </ContextShow>

                  <ContextShow when="pricing_type" is="metered_annually">
                    <Select label="Unit Type" model="price_unit_type" defaultValue="client_per_year">
                      {Object.entries(YEARLY_PRICING_UNIT_TYPES).map(([key, value]) => (
                        <Option label={value} value={key} />
                      ))}
                    </Select>
                  </ContextShow>

                  <ContextShow when="price_unit_type" is="user_per_month">
                    <UserTypeSelect label="User Type" model="price_unit_subtype" />
                  </ContextShow>
                </Flex>

                <RichTextEditor label="Internal Notes" model="internal_notes" />

                <CheckboxGroup label="Required Product">
                  <Checkbox label="Show as required" model="is_required" />
                </CheckboxGroup>

                <CheckboxGroup label="Recommend Add-On">
                  <Checkbox label="Show as recommended" model="is_recommended" />
                </CheckboxGroup>

                <Input label="Dynamic Filter 1" model="dynamic_filters.filter_1" />
                <Input label="Dynamic Filter 2" model="dynamic_filters.filter_2" />
                <Input label="Dynamic Filter 3" model="dynamic_filters.filter_3" />
              </FormSection>
            </Section>

            <Section title="Features">
              <FormSection>
                <FeaturesCheckboxGroup label="General" data={GENERAL_FEATURES} />
                <FeaturesCheckboxGroup label="ERP" data={ERP_FEATURES} />
                <FeaturesCheckboxGroup label="Contacts" data={CONTACTS_FEATURES} />
                <FeaturesCheckboxGroup label="Chat" data={CHAT_FEATURES} />
                <FeaturesCheckboxGroup label="Care Coordination" data={CARE_COORDINATION_FEATURES} />
                <FeaturesCheckboxGroup label="RCM" data={RCM_FEATURES} />
                <FeaturesCheckboxGroup label="Insurance" data={INSURANCE_FEATURES} />
                <FeaturesCheckboxGroup label="Notes" data={NOTES_FEATURES} />
                <FeaturesCheckboxGroup label="Medical" data={MEDICAL_FEATURES} />
                <FeaturesCheckboxGroup label="Clinical" data={CLINICAL_FEATURES} />
                <FeaturesCheckboxGroup label="Security" data={SECURITY_FEATURES} />
              </FormSection>
            </Section>
          </Form>
        </Overlay.Content>

        <Overlay.Footer online={online}>
          {$editable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                onClick={this.save}
                isLoading={loading}
                isDisabled={isInvalid}
              />
              <Button label="Cancel" glyph="cross" onClick={this.cancel} isDisabled={loading} />
            </>
          )}

          {!$editable && (
            <>
              <Button label="Edit" glyph="edit" onClick={this.edit} flex="100 1 auto" />

              <Dialog
                title={this.props.record?.status === 'archived' ? 'Activate Add-On?' : 'Archive Add-On?'}
                message="Are you sure you want to update this add-on?"
                onYes={this.toggleArchive}
              >
                <Button
                  label={this.props.record?.status === 'archived' ? 'Activate' : 'Archive'}
                  glyph="empty"
                  color="text"
                  isLoading={loading}
                />
              </Dialog>

              {this.props.record?.status === 'archived' && (
                <DeleteDialog title="Delete Add-On?" message="Are you sure you want to delete this add-on?" onYes={this.delete}>
                  <Button label="Delete" glyph="delete" color="red" isLoading={loading} />
                </DeleteDialog>
              )}
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'addons')

export default connect(mapStateToProps, mapDispatchToProps)(AddonOverlay)
