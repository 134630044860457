import React from 'react'
import { Link } from 'react-router-dom-v5-compat'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { invalidateQueries } from '../../../hooks/useNewAPI'
import { request } from '../../../modules/axios'
import { usDateTime } from '../../../utils/functions'
import { useCreate, useGet } from '../../../hooks/useNewAPI'
import { useOverlay } from '../../../hooks/useOverlay'
import { useRouteURL } from '../../../hooks/useRouteURL'
import { useSettings } from '../../../hooks/useSettings'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Checkbox from '../../../components/Forms/Checkbox'
import ContextShow from '../../../components/Forms/ContextShow'
import DataList from '../../../components/DataList'
import DateInput from '../../../components/Forms/DateInput'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import Divider from '../../../components/Divider'
import Dropdown from '../../../components/Dropdown'
import DropdownItem from '../../../components/DropdownItem'
import Flex from '../../../components/Flex'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Glyph from '../../../components/Glyph'
import Input from '../../../components/Forms/Input'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../../components/Forms/Option'
import Overlay from '../../../components/Overlay'
import OverlayLoader from '../../../components/OverlayLoader'
import Permission from '../../../components/Permission'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Section from '../../../components/Section'
import Select from '../../../components/Forms/Select'
import SmartStatus from '../../../components/SmartStatus'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import Tooltip from '../../../components/Tooltip'

import { TreatmentPlanRecordStatus } from '../form_elements/TreatmentPlanRecordStatus'

export const STATUSES = {
  significant_progress: {
    label: 'Score: Significant Progress',
    color: 'green',
  },
  progress: {
    label: 'Score: Progress',
    color: 'blue',
  },
  no_change: {
    label: 'Score: No Change',
    color: 'gray',
  },
  regression: {
    label: 'Score: Regression',
    color: 'orange',
  },
  significant_regression: {
    label: 'Score: Significant Regression',
    color: 'red',
  },
}

export const REVIEW_STATUSES = {
  draft: {
    label: 'Draft',
    color: 'blue',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
}

export const TreatmentPlanReviews = (props: any) => {
  const { data, reference, invalidate, canEdit, permissionBase, isEditable, planId, isNew } = props

  const isEmpty = size(data) === 0

  const [isAdding, setIsAdding] = React.useState(false)
  const [isImporting, setIsImporting] = React.useState(false)

  const action = canEdit && (
    <Permission permission={`${permissionBase}.edit`}>
      <Tooltip glyph="info" show={isEditable} content="Save this record first to add plan reviews" color={COLORS.orange}>
        <Dropdown label="Add Plan Review…" glyph="add" buttonType="primary" buttonSize={200} isDisabled={isEditable}>
          <DropdownItem
            label="Add Plan Review…"
            color="blue"
            glyph="add"
            size={200}
            isDisabled={isEditable}
            onClick={() => setIsAdding(true)}
          />

          <DropdownItem
            label={
              <div>
                <div>Auto-Generate Plan Reviews…</div>
                <div className="font-400 opacity-70">Using treatment plan's review settings</div>
              </div>
            }
            color="blue"
            glyph="multi_select"
            size={200}
            isDisabled={isEditable}
            onClick={() => setIsImporting(true)}
          />
        </Dropdown>
      </Tooltip>

      <SummonOverlay
        portalType="z120"
        isOpen={isAdding}
        onClose={() => setIsAdding(false)}
        overlay={
          <ReviewOverlay
            dataID="new"
            planId={planId}
            reference={reference}
            invalidate={invalidate}
            permissionBase={permissionBase}
            canEdit={canEdit}
          />
        }
        isDisabled={isEditable}
      />

      <SummonOverlay
        portalType="z120"
        isOpen={isImporting}
        onClose={() => setIsImporting(false)}
        overlay={<AutoGenerateReviewsOverlay dataID={planId} reference={reference} invalidate={invalidate} />}
        isDisabled={isEditable}
      />
    </Permission>
  )

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h2>Plan Reviews</h2>
        {!isNew && action}
      </div>

      {isNew ? (
        <>
          <AutoGenerateReviewsCheckbox planId={planId} />
        </>
      ) : isEmpty ? (
        <Card>
          <State
            isEmpty
            isCompact
            icon="treatment_plans"
            title="Plan Reviews"
            emptyDescription="No plan reviews added yet"
            minHeight={180}
          />
        </Card>
      ) : (
        <>
          <Flex stretchChildrenX gap="0.75rem">
            <Card className="px-3 pb-2">
              <DateTimeInput isEditable={false} label="Last Review Date & Time" value={reference?.last_treatment_review?.dated_at} />
            </Card>
            <Card className="px-3 pb-2">
              <DateTimeInput isEditable={false} label="Next Review Date & Time" value={reference?.next_treatment_review?.dated_at} />
            </Card>
          </Flex>

          {data?.map?.((review) => (
            <Review
              key={review.id}
              data={review}
              planId={planId}
              invalidate={invalidate}
              reference={reference}
              canEdit={canEdit}
              permissionBase={permissionBase}
              isEditDisabled={isEditable}
            />
          ))}
        </>
      )}
    </div>
  )
}

export const AutoGenerateReviewsCheckbox = (props: any) => {
  const { planId } = props

  const { data, isLoading } = useGet({
    name: ['treatment_plans', planId],
    url: '/treatment_plans',
    options: { enabled: !!planId },
  })

  const { url } = useRouteURL()
  const baseURL = url && url.substring(0, url.lastIndexOf('/'))

  if (!planId || !data || isLoading) return null

  const isEnabled = data?.settings?.reviews_config_enabled && size(data?.settings?.reviews_config) > 0

  if (isEnabled) {
    return (
      <div>
        <Checkbox
          defaultChecked
          label="Auto-Generate Plan Reviews"
          description="Using treatment plan's review settings"
          model="auto_generate_treatment_reviews"
        />

        <div className="flex justify-end mt-2">
          <Button
            as={Link}
            useGlyphForTarget
            label="Manage Review Periods"
            glyph="settings"
            size={100}
            link={`${baseURL}/settings`}
            target="_blank"
            type="link"
            display="inline-flex"
          />
        </div>
      </div>
    )
  }

  return (
    <Card>
      <State
        isEmpty
        isCompact
        minHeight={180}
        glyph="settings"
        title="Review Periods Settings"
        emptyDescription="Tip: you can create review periods for this treatment plan to generate review records automatically next time."
        emptyActions={
          baseURL && (
            <Button
              as={Link}
              useGlyphForTarget
              label="Manage Review Periods"
              glyph="settings"
              size={200}
              link={`${baseURL}/settings`}
              target="_blank"
            />
          )
        }
      />
    </Card>
  )
}

const Review = ({ data, invalidate, canEdit, permissionBase, isEditDisabled, planId }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <Card className="flex justify-between items-center py-2 px-2 mt-2">
      <div>
        <Flex centerY gap="0.5rem">
          <SmartStatus small statuses={REVIEW_STATUSES} status={data.status || 'draft'} />
          <SmartStatus small statuses={STATUSES} status={data.score} />
        </Flex>

        {isEditDisabled ? (
          <div className="font-[600] mt-1 mb-1">{data.name}</div>
        ) : (
          <SummonOverlay
            portalType="z120"
            overlay={
              <ReviewOverlay
                planId={planId}
                dataID={data.id}
                invalidate={invalidate}
                isDisabled={isEditDisabled}
                permissionBase={permissionBase}
                canEdit={canEdit}
              />
            }
          >
            <div className="font-[600] mt-1 mb-1 text-blue-500 hover:cursor-pointer">{data.name}</div>
          </SummonOverlay>
        )}

        <div className="mt-1 mb-1">{data.notes}</div>

        <Flex gap="0.5rem">
          <div className="flex flex-nowrap items-center text-[0.88rem] text-text-muted">
            <Glyph glyph="user_neutral" size={14} className="mr-1" color={COLORS.textMuted} />
            <span>{data.author?.name || '–'}</span>
          </div>

          <div className="flex flex-nowrap items-center text-[0.88rem] text-text-muted">
            <Glyph glyph="date" size={14} className="mr-1" color={COLORS.textMuted} />
            <span>{usDateTime(data.dated_at, timezone)}</span>
          </div>
        </Flex>
      </div>

      {canEdit && (
        <Permission permission={`${permissionBase}.edit`}>
          <Tooltip glyph="info" show={isEditDisabled} content="Save this record first to edit reviews" color={COLORS.orange}>
            <SummonOverlay
              portalType="z120"
              overlay={
                <ReviewOverlay
                  planId={planId}
                  dataID={data.id}
                  invalidate={invalidate}
                  isDisabled={isEditDisabled}
                  permissionBase={permissionBase}
                  canEdit={canEdit}
                />
              }
            >
              <Button label="Edit" glyph="edit" size={100} isDisabled={isEditDisabled} />
            </SummonOverlay>
          </Tooltip>
        </Permission>
      )}
    </Card>
  )
}

const INVALIDATE_KEYS = [
  'treatment-plan-updates',
  'treatment-goals-objectives-interventions',
  'treatment-problem',
  'treatment-problems',
  'treatment-goal',
  'treatment-goals',
  'treatment-objective',
  'treatment-objectives',
  'treatment-intervention',
  'treatment-interventions',
]

const PARENT_UPDATES: any = {
  treatment_problem: {
    title: 'Problem',
    url: '/treatment_problems',
  },
  treatment_goal: {
    title: 'Goal',
    url: '/treatment_goals',
  },
  treatment_objective: {
    title: 'Objective',
    url: '/treatment_objectives',
  },
  treatment_intervention: {
    title: 'Intervention',
    url: '/treatment_interventions',
  },
}

export const ReviewOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    data,
    updateAsync,
  } = useOverlay({
    name: 'treatment-review',
    endpoint: '/treatment_reviews',
    invalidate: 'treatment-reviews',
    invalidateKeys: INVALIDATE_KEYS,
    closeOnSave: true,
    options: props,
  })

  const { planId, canEdit, permissionBase, reference } = props

  const { timezone } = useSettings()

  const [selectedReferenceType, setSelectedReferenceType]: any = React.useState(
    isNew ? reference?.type || initialModel?.reference?.type || 'treatment_problem' : '',
  )

  const parentUpdate = PARENT_UPDATES?.[selectedReferenceType]
  const parentStatus = reference?.status

  const hasInitialReference = (reference?.id && reference?.type) || (initialModel?.reference?.id && initialModel?.reference?.type)

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    const selectedReferenceId = formData?.[`${selectedReferenceType}_id`]

    await saveWithData({
      ...(isNew && {
        reference_id: selectedReferenceId,
        reference_type: selectedReferenceType,
      }),
      score: formData.score,
      name: formData.name,
      notes: formData.notes,
      status: formData.status,
      dated_at: formData.dated_at,
    })

    if (isNew && parentUpdate && selectedReferenceId && selectedReferenceType) {
      const updatedStatus = formData.parent_updates.status

      await request.put(`${parentUpdate.url}/${selectedReferenceId}`, {
        status: updatedStatus,
        ...(updatedStatus === 'completed' && { completed_at: formData.parent_updates.completed_at }),
        // last_reviewed_at: formData.parent_updates.last_reviewed_at,
      })
    }

    for (const key of INVALIDATE_KEYS) {
      invalidateQueries('', key)
    }
  }

  const [isCompleting, setIsCompleting] = React.useState(false)

  const markAsCompleted = async () => {
    setIsCompleting(true)

    await updateAsync({ status: 'completed' })

    setIsCompleting(false)
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay showBackdrop={isEditable} position="right" onClose={close}>
      <Overlay.Header icon="treatment_plans" title={isNew ? 'Add Plan Review' : 'Plan Review'} />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={{
            ...(isNew && { selected_reference_type: selectedReferenceType }),
            ...initialModel,
          }}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          key={`updated-${data?.updated_at}`}
        >
          <Section>
            <FormSection layout="vertical">
              <h2>Plan Review</h2>

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a review name',
                  },
                }}
              />

              <DateTimeInput
                defaultToNow={isNew && !initialModel?._dated_in_one_month}
                defaultInOneMonth={isNew && !!initialModel?._dated_in_one_month}
                label="Review Date & Time"
                model="dated_at"
                validations={{
                  presence: {
                    message: 'Please enter a review date & time',
                  },
                }}
              />

              {isNew ? (
                <div className={clsx('grid gap-4', hasInitialReference && 'hidden')}>
                  <RadioGroup
                    label="Add Review To:"
                    layout="vertical-dense"
                    onUpdate={({ value }) => setSelectedReferenceType(value)}
                    model="selected_reference_type"
                  >
                    <Radio label="Problem" value="treatment_problem" />
                    <Radio label="Goal" value="treatment_goal" />
                    <Radio label="Objective" value="treatment_objective" />
                    <Radio label="Intervention" value="treatment_intervention" />
                  </RadioGroup>

                  {selectedReferenceType === 'treatment_problem' && (
                    <ObjectSelector
                      label="Problem"
                      type="treatment_plan.treatment_problems"
                      dependentValue={planId}
                      selectTitle={(data) => data.name || data.description}
                      model="treatment_problem"
                      defaultValue={reference?.type === 'treatment_problem' ? reference : undefined}
                      validations={{
                        presence: {
                          message: 'Please select a problem',
                        },
                      }}
                    />
                  )}

                  {selectedReferenceType === 'treatment_goal' && (
                    <ObjectSelector
                      label="Goal"
                      type="treatment_plan.treatment_goals"
                      dependentValue={planId}
                      selectTitle={(data) => data.name || data.description}
                      model="treatment_goal"
                      defaultValue={reference?.type === 'treatment_goal' ? reference : undefined}
                      validations={{
                        presence: {
                          message: 'Please select a goal',
                        },
                      }}
                    />
                  )}

                  {selectedReferenceType === 'treatment_objective' && (
                    <ObjectSelector
                      label="Objective"
                      type="treatment_plan.treatment_objectives"
                      dependentValue={planId}
                      selectTitle={(data) => data.name || data.description}
                      model="treatment_objective"
                      defaultValue={reference?.type === 'treatment_objective' ? reference : undefined}
                      validations={{
                        presence: {
                          message: 'Please select an objective',
                        },
                      }}
                    />
                  )}

                  {selectedReferenceType === 'treatment_intervention' && (
                    <ObjectSelector
                      label="Intervention"
                      type="treatment_plan.treatment_interventions"
                      dependentValue={planId}
                      selectTitle={(data) => data.name || data.description}
                      model="treatment_intervention"
                      defaultValue={reference?.type === 'treatment_intervention' ? reference : undefined}
                      validations={{
                        presence: {
                          message: 'Please select an intervention',
                        },
                      }}
                    />
                  )}
                </div>
              ) : (
                data && (
                  <Flex gap="1rem">
                    <ObjectSelector
                      isEditable={false}
                      label={`Added to ${PARENT_UPDATES?.[data?.reference?.type]?.title}`}
                      selectTitle={(data) => data.name || data.description}
                      model="reference"
                    />
                  </Flex>
                )
              )}

              <Select allowEmpty model="score" label="Score">
                {Object.entries(STATUSES).map(([value, { label }]) => (
                  <Option key={value} label={label} value={value} />
                ))}
              </Select>

              <Select allowEmpty model="status" label="Status">
                {Object.entries(REVIEW_STATUSES).map(([value, { label }]) => (
                  <Option key={value} label={label} value={value} />
                ))}
              </Select>

              <SmartTextarea useQuickText useDictation label="Notes" model="notes" />

              {isNew && parentUpdate && (
                <>
                  <Divider />

                  <h2 className="-mb-2">Update {parentUpdate.title}</h2>

                  <TreatmentPlanRecordStatus model="parent_updates.status" defaultValue={parentStatus} />

                  {/* <DateInput defaultToNow label="Last Review Date" model="parent_updates.last_reviewed_at" /> */}

                  <ContextShow when="parent_updates.status" is="completed">
                    <DateInput defaultToNow label="Completion Date" model="parent_updates.completed_at" />
                  </ContextShow>
                </>
              )}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      {canEdit && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                glyph="check"
                label="Save Plan Review"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={handleSave}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission={`${permissionBase}.edit`}
              />
              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <div className="grid gap-3">
              {/* {data?.status !== 'completed' && (
                <Button
                  label="Mark as Completed"
                  glyph="tick_circle"
                  color="green"
                  type="default"
                  flex="1 1 auto"
                  isDisabled={isSaving || isDeleting}
                  onClick={markAsCompleted}
                  isLoading={isCompleting}
                  permission={`${permissionBase}.edit`}
                />
              )} */}

              <Flex gap="0.75rem">
                <Button
                  label="Edit"
                  glyph="edit"
                  type="default"
                  isDisabled={isDeleting}
                  onClick={edit}
                  flex="100 1 auto"
                  permission={`${permissionBase}.edit`}
                />

                <DeleteDialog
                  title="Delete Review?"
                  message="Are you sure you want to delete this Review? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button
                    label="Delete"
                    type="default"
                    glyph="delete"
                    color="red"
                    isLoading={isDeleting}
                    fullWidth
                    permission={`${permissionBase}.edit`}
                  />
                </DeleteDialog>
              </Flex>
            </div>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const AutoGenerateReviewsOverlay = (props: any) => {
  const { close, data, id, isOverlayLoading } = useOverlay({
    name: 'treatment_plans',
    endpoint: '/treatment_plans',
    invalidate: 'treatment-plans',
    invalidateKeys: [props.invalidate].flat(),
    closeOnSave: true,
    options: props,
  })

  const { url } = useRouteURL()
  const baseURL = url && url.substring(0, url.lastIndexOf('/'))

  const { reference, invalidate } = props
  const parentUpdate = PARENT_UPDATES?.[reference?.type]

  const { mutateAsync, isLoading: isSaving } = useCreate({
    name: ['generate_reviews'],
    url: `${parentUpdate.url}/${reference?.id}/generate_reviews`,
    invalidate,
    invalidateKeys: INVALIDATE_KEYS,
  })

  const handleGenerate = async () => {
    await mutateAsync({ auto_generate_treatment_reviews: true })

    close()
  }

  if (isOverlayLoading || !data) {
    return <OverlayLoader showBackdrop closeOnBackdrop position="center" />
  }

  const isEnabled = data?.settings?.reviews_config_enabled && size(data?.settings?.reviews_config) > 0

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={close}>
      <Overlay.Header glyph="multi_select" title="Auto-Generate Plan Reviews" />

      <Overlay.Content>
        <>
          {isEnabled ? (
            <div className="px-4 py-4">
              <Card className="px-3 py-2">
                <Alert small contrast glyph="info">
                  This action will generate plan reviews based on the following settings.
                </Alert>

                <h3 className="mt-3">Review Periods</h3>

                <DataList isCompact layout="vertical">
                  {data?.settings?.reviews_config.map((item, index) => (
                    <DataList.Item
                      key={`${item.name}-${index}`}
                      label={`${item.name || 'Review Period'}: ${item.days_from_start} days`}
                      value={item.description && <div className="font-[400] text-text-strongly-muted">{item.description}</div>}
                    />
                  ))}
                </DataList>
              </Card>

              <Button
                label="Generate Plan Reviews"
                glyph="multi_select"
                type="primary"
                color="green"
                className="mt-4"
                onClick={handleGenerate}
                isDisabled={isSaving}
              />
            </div>
          ) : (
            <div>
              <State
                isEmpty
                glyph="settings"
                title="Review Periods Settings"
                emptyDescription="This treatment plan doesn't have any review periods set up. Go to the plan's settings to configure them."
                emptyActions={
                  baseURL && <Button as={Link} label="Open Plan's Settings →" glyph="settings" size={200} link={`${baseURL}/settings`} />
                }
              />
            </div>
          )}
        </>
      </Overlay.Content>
    </Overlay>
  )
}
