import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { Card, Grid } from '@behavehealth/components'
import ClaimPaymentsTable from '@behavehealth/components/RCM/ClaimPaymentsTable'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { store } from '@behavehealth/setup/store'
import { clearData, updateData } from '@behavehealth/actions/data'
import { arrayToMap } from '@behavehealth/utils/functions'

const Posting = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'insurance-payments'],
    url: `/residents/${resource_id}/insurance_payments`,
  })

  React.useEffect(() => {
    store.dispatch(updateData('insurance_payments.data', arrayToMap(data)))

    return () => {
      clearData('insurance_payments')
    }
  }, [data])

  return (
    <Grid gap="1rem">
      {/* <ClaimFinancialStats /> */}

      <Card>
        <ClaimPaymentsTable
          title="Payments"
          data={data}
          isLoading={isLoading}
          to={(id: string) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
        />
      </Card>
    </Grid>
  )
}

export default Posting
