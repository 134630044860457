import React from 'react'

import Tooltip from '../Tooltip'

const TemplateLegalTooltip = () => {
  return (
    <Tooltip content='The Behave Health Corp Template Datasets are designed to provide a quick and easy way to get started using the Behave Health software. They are imported with the understanding that the publisher is not engaged in rendering professional services. If legal, accounting, medical, psychological, or other expert assistance is required, the services of a competent professional person should be sought. THE BEHAVE HEALTH CORP TEMPLATE DATASETS AND ALL INFORMATION THEREIN ARE PROVIDED "AS IS" AND EXPRESS AND IMPLIED WARRANTIES AND REPRESENTATIONS OF ANY KIND, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, OR QUALITY, ACCURACY, COMPLETENESS AND/OR SUITABILITY ARE DISCLAIMED.' />
  )
}

export default TemplateLegalTooltip
