export const VARIABLE_SUPPORT_FIELDS = [
  'business_associate_agreement_text',
  'confidentiality_acknowledgement_legal_text',
  'confidentiality_text',
  'nda_acknowledgement_legal_text',
  'nda_text',
  'payment_legal_language_text',
  'payment_text',
  'price_matching_text',
  'price_summary_text',
  'privacy_policy_text',
  'service_terms_text',
  'signature_legal_language_text',
  'software_access_agreement_text',
  'software_terms_of_use_agreement_text',
  'summary_section_text',
  'terms_and_conditions_text',
]
