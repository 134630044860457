import React from 'react'
import produce from 'immer'

import { countWord } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Button from '../Button'
import Flex from '../Flex'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (
  to: Function = () => {},
  mainLinkAs: any,
  duplicateFormLink: Function = () => {},
  duplicateFormLinkProps,
  timezone: string,
) => [
  {
    width: 200,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink as={mainLinkAs} to={to(row.original.id)} label={value} />,
  },
  {
    width: 150,
    accessor: 'insurance_local_payers',
    Header: 'Payers',
    Cell: ({ value }: any) => <TableCell value={value?.map((o: any) => o.name)?.join(', ') || '–'} />,
  },
  {
    width: 200,
    accessor: 'employees',
    Header: 'Healthcare Providers',
    Cell: ({ value }: any) => <TableCell value={countWord('Providers', value?.length)} />,
  },
  {
    width: 180,
    accessor: 'houses',
    Header: 'Healthcare Locations',
    Cell: ({ value }: any) => <TableCell value={countWord('Locations', value?.length)} />,
  },
  {
    width: 100,
    accessor: 'insurance_fee_schedule_services',
    Header: 'Services',
    Cell: ({ value }: any) => <TableCell value={countWord('Services', value?.length)} />,
  },
  {
    width: 180,
    accessor: 'updated_at',
    Header: 'Last Edited',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 100,
    id: 'duplicate',
    accessor: 'id',
    Header: '',
    Cell: ({ row }: any) => {
      const duplicated = produce(row.original, (draft: any) => {
        // add copy to the name
        draft.name = draft.name + ' Copy'

        // update the services
        draft.insurance_fee_schedule_services.map((o: any) => {
          delete o.id

          o.insurance_code_id = o.insurance_code?.id
          o.service_id = o.service?.id
        })
      })

      return (
        <Flex gap="0.5rem">
          <Button
            label="Duplicate"
            glyph="add"
            size={200}
            link={duplicateFormLink?.(duplicated)}
            {...duplicateFormLinkProps?.(duplicated)}
          />
        </Flex>
      )
    },
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  isLoading: boolean
  localStorageKey: string
  timezone: string
  titleAfter?: React.ReactNode
  to?: Function
  user?: any
  duplicateFormLink?: Function
}

const FeeSchedulesTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    duplicateFormLink,
    emptyActions,
    isLoading,
    localStorageKey,
    timezone,
    titleAfter,
    to,
    user,
    duplicateFormLinkProps,
    mainLinkAs,
    ...rest
  } = props

  return (
    <Table
      title="Fee Schedules"
      titleAfter={titleAfter}
      icon="insurance"
      data={data}
      columns={columns(to, mainLinkAs, duplicateFormLink, duplicateFormLinkProps, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No fee schedules added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

FeeSchedulesTable.defaultProps = {
  localStorageKey: 'fee_schedules',
}

export default withSettings(FeeSchedulesTable)
