import React from 'react'

import { titleCase } from '../../../utils/functions'

import Table from '../../Table/Table'
import TableCell from '../../Table/TableCell'
import TableFilter from '../../Table/TableFilter'
import TaskStatus from '../../Statuses/TaskStatus'

import withSettings from '../../../hocs/withSettings'

const TASK_TYPES: any = {
  one_to_one: 'One-to-One',
  group_meeting: 'Group Task',
}

const columns = (to, onClick, mainLinkAs) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'title',
    Header: 'Title',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink
        as={mainLinkAs}
        to={to?.(row.original.id)}
        onClick={() => onClick?.(row.original)}
        label={value}
        avatar={row.original.avatar}
      />
    ),
  },
  {
    width: 110,
    accessor: 'meeting_type',
    Header: 'Type',
    Cell: ({ value }: any) => <TableCell value={TASK_TYPES[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'One-to-One', value: 'one_to_one' },
      { label: 'Group Task', value: 'group_meeting' },
    ],
  },
  {
    width: 140,
    accessor: 'meeting_place',
    Header: 'Meeting Place',
    Cell: ({ value }: any) => <TableCell value={titleCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'None', value: 'none' },
      { label: 'Online', value: 'online' },
      { label: 'Location', value: 'property' },
      { label: 'Organization', value: 'organization' },
      { label: 'Other', value: 'other' },
    ],
  },
  {
    width: 160,
    accessor: 'sharing_type',
    Header: 'Permissions',
    Cell: ({ value }: any) => {
      return <TaskStatus status={value} />
    },
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'Staff Only', value: 'internal' },
      { label: 'Client Portal', value: 'client' },
    ],
  },
  {
    width: 200,
    accessor: 'public_description',
    Header: 'Public Description',
    Cell: ({ value }: any) => <TableCell.Markup value={value} />,
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  timezone: string
  title: string
  to?: Function
}

const TodoTemplatesTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    localStorageKey,
    onClick,
    timezone,
    title,
    to,
    ...rest
  } = props

  return (
    <Table
      title={title}
      testKey="todo_templates_table"
      icon={icon}
      data={data}
      columns={columns(to, onClick, props.mainLinkAs)}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

TodoTemplatesTable.defaultProps = {
  title: 'To-Do Templates',
  icon: 'tasks',
  emptyDescription: 'No to-do templates to display',
  localStorageKey: 'todo_templates',
}

export default withSettings(TodoTemplatesTable)
