import React from 'react'
import { connect } from 'react-redux'

import Alert from '../Alert'
import AmountInput from '../Forms/AmountInput'
import Button from '../Button'
import ConfirmDialog from '../Dialogs/ConfirmDialog'
import ContextShow from '../Forms/ContextShow'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Form from '../Forms/Form'
import FormSection from '../Forms/FormSection'
import Input from '../Forms/Input'
import Overlay from '../Overlay'
import PercentageInput from '../Forms/PercentageInput'
import Radio from '../Forms/Radio'
import Select from '../Forms/Select'
import Option from '../Forms/Option'
import RadioGroup from '../Forms/RadioGroup'
import RichTextEditor from '../Forms/RichTextEditor'
import Section from '../Section'
import MultiObjectSelector from '../Forms/Selectors/MultiObject/MultiObjectSelector'

import { beautifulAmount } from '../../utils/functions'
import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../Overlays/pages/OverlayBase'
import { withOverlayError } from '../../hocs/withOverlayError'
import { PRICING_STRATEGIES, PRICING_UNIT_TYPES } from './constants'

import { apiUpdate } from '../../modules/api'

import withRouterV6 from '../../hocs/withRouterV6'

class BillingDiscountOverlay extends OverlayBase {
  archiveDiscount = async () => {
    const { match } = this.props

    await apiUpdate({
      url: `/discounts/${this.state.id || match?.params?.id}`,
      params: {
        status: 'archived',
      },
    })

    this.close()
  }

  renderHeader = () => {
    return <Overlay.Header title="Discount" glyph="dollar" />
  }

  renderContent = () => {
    const { $editable, params } = this.state
    const { record, timezone } = this.props

    return (
      <Overlay.Content>
        <Section>
          <Form
            getForm={this.form}
            initialModel={{ ...record, ...params }}
            isEditable={$editable}
            timezone={timezone}
            onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
          >
            <FormSection>
              <Alert type="positive">Discount has been used at least once. It cannot be updated anymore.</Alert>

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <RichTextEditor label="Internal Notes" model="internal_notes" />

              <Select label="Status" model="status">
                <Option label="Inactive" value="inactive" />
                <Option label="Active" value="active" />
                {/* <Option label="In Use" value="in_use" /> */}
                <Option label="Used Up" value="used" />
                <Option label="Archived" value="archived" />
              </Select>

              <RadioGroup label="Type" layout="vertical-dense" model="discount_type" defaultValue="fixed">
                <Radio label="Fixed Amount" value="fixed" />
                <Radio label="Percentage" value="percentage" />
              </RadioGroup>

              <ContextShow when="discount_type" is="fixed">
                <AmountInput
                  label="Amount"
                  prefix="$"
                  size={6}
                  model="amount_off"
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                  }}
                />
              </ContextShow>

              <ContextShow when="discount_type" is="percentage">
                <PercentageInput label="Percentage" model="percent_off" />
              </ContextShow>

              <RadioGroup label="Number of Redemptions" layout="vertical-dense" model="duration" defaultValue="once">
                <Radio label="Once" value="once" />
                <Radio label="Max Redemptions" value="number_of_months" />
                <Radio label="Forever" value="forever" />
              </RadioGroup>

              <ContextShow when="duration" is="number_of_months">
                <Input
                  label="Max Redemptions"
                  suffix="months"
                  size={6}
                  model="max_redemptions"
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                  }}
                />
              </ContextShow>

              <ContextShow when="usage" is="promo_code">
                <Input label="Promo Code" model="promo_code" />
              </ContextShow>

              <ContextShow when="usage" is="manual">
                <MultiObjectSelector
                  label="Apply to products"
                  model="products"
                  type="products"
                  selectTitle={(data: any) => data.name}
                  selectDescription={(data: any) => {
                    return `${PRICING_STRATEGIES[data.pricing_type]} – ${beautifulAmount(data.price)} ${
                      PRICING_UNIT_TYPES[data.price_unit_type]
                    }`
                  }}
                  searchKeys={['name', 'category', 'account_type']}
                />
              </ContextShow>
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state
    const { record } = this.props

    // don't allow editing anymore if it's in use
    if (record.status === 'in_use') {
      return (
        <Overlay.Footer>
          <ConfirmDialog
            title="Archive Discount?"
            message="Are you sure you want to archive this discount? This action cannot be undone."
            yesLabel="Yes, Archive Discount"
            yesColor="green"
            onYes={this.archiveDiscount}
          >
            <Button fullWidth glyph="delete" label="Archive" type="default" color="gray" isLoading={this.props.loading} />
          </ConfirmDialog>
        </Overlay.Footer>
      )
    } else if (record.status === 'archived') return null

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Discount"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
            />

            <DeleteDialog
              title="Delete Discount?"
              message="Are you sure you want to delete this discount? This action cannot be undone."
              onYes={this.delete}
            >
              <Button fullWidth glyph="delete" label="Delete" type="default" color="red" isLoading={this.props.loading} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'discounts')

export default connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(BillingDiscountOverlay))

export const BillingDiscountOverlayV6 = withRouterV6(
  connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(BillingDiscountOverlay)),
)
