import React from 'react'

import GlobalTags from '../../../Table/Cells/GlobalTags'
import SmartFlags from '../../../Table/Cells/SmartFlags'
import Table from '../../../Table/Table'
import TableCell from '../../../Table/TableCell'
import TableFilter from '../../../Table/TableFilter'

import withSettings from '../../../../hocs/withSettings'

const CATEGORIES: any = {
  general: 'General',
  client_admission: 'Client Admission',
  recovery_coaching: 'Recovery Coaching',
  housing_activity: 'Housing Activity',
  discharge: 'Discharge',
  psych_appointment: 'Psych Appointment',
  medical_appointment: 'Medical Appointment',
  clinical_appointment: 'Clinical Appointment',
  progress_appointment: 'Progress Appointment',
  individual_therapy: 'Individual Therapy',
  individual_peer_note: 'Individual Peer Note',
  group_peer_notes: 'Group Peer Note',
  individual_daily_note: 'Individual Daily Note',
  group_daily_notes: 'Group Daily Note',
  group_therapy: 'Group Therapy',
  meds_pass: 'Meds Pass',
  urinalysis: 'Urinalysis',
  breathalyzer: 'Breathalyzer',
  vitals: 'Vitals',
  communicable_tests: 'Communicable Tests',
  bed_check: 'Bed Check',
  housing_intake: 'Housing Intake',
  show_as_busy: 'Show as Busy',
}

const STATUSES: any = {
  active: 'Active',
  archived: 'Archived',
}

const LEVELS: any = {
  public_template: 'Public',
  behave_template: 'Behave',
}

const columns = (to, onClick, mainLinkAs) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'data.title',
    Header: 'Title',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink
        as={mainLinkAs}
        to={to?.(row.original.id)}
        onClick={() => onClick?.(row.original)}
        label={value}
        avatar={row.original.avatar}
      />
    ),
  },
  {
    width: 110,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <TableCell value={STATUSES[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'active', value: 'active' },
      { label: 'archived', value: 'archived' },
    ],
  },
  {
    width: 240,
    Header: 'Tags',
    accessor: 'global_tags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <GlobalTags
        // queryKey={queryKey} // TODO
        tags={row.original.global_tags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 240,
    Header: 'Smart Flags',
    accessor: 'smart_flags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <SmartFlags
        // queryKey={queryKey}
        value={row.original.smart_flags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 140,
    accessor: 'level',
    Header: 'Access',
    Cell: ({ value }: any) => <TableCell value={LEVELS[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'Public', value: 'public_template' },
      { label: 'Behave', value: 'behave_template' },
    ],
  },
  {
    width: 160,
    accessor: 'data.category',
    Header: 'Category',
    Cell: ({ value }: any) => {
      if (!(value in CATEGORIES)) return <TableCell.Empty />

      return CATEGORIES[value]
    },
  },
  {
    width: 200,
    accessor: 'data.public_description',
    Header: 'Public Description',
    Cell: ({ value }: any) => <TableCell.Truncated value={value} />,
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  setTableColumns?: Function
  timezone: string
  title: string
  to?: Function
}

const EventTemplatesTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    isLoading,
    localStorageKey,
    onClick,
    setTableColumns,
    timezone,
    title,
    to,
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={setTableColumns ? setTableColumns(to, timezone) : columns(to, onClick, props.mainLinkAs)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

EventTemplatesTable.defaultProps = {
  title: 'Event Templates',
  icon: 'calendar',
  emptyDescription: 'No event templates to display',
  localStorageKey: 'event_internal_templates',
}

export default withSettings(EventTemplatesTable)
