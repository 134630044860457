import React from 'react'

import Alert from '../../components/Alert'
import Button from '../../components/Button'

import { COLORS } from '../../theme'
import { sleep } from '../../utils/functions'

const RESEND_SECONDS = process.env.NODE_ENV === 'development' ? 10 : 240

export const ResendOTP = (props: any) => {
  const { onResend } = props

  const [isVisible, setIsVisible] = React.useState(false)
  const [didResend, setDidResend] = React.useState(false)
  const [isResending, setIsResending] = React.useState(false)
  const [counter, setCounter] = React.useState(RESEND_SECONDS)

  const canResend = counter === 0

  const resendOTP = async () => {
    if (!onResend) return

    setIsResending(true)

    try {
      await onResend()
      setDidResend(true)

      // reset timer
      await sleep(10 * 1000)
      setCounter(RESEND_SECONDS)
      setDidResend(false)
    } catch (error) {
      console.error(error)
    } finally {
      setIsResending(false)
    }
  }

  // set initial timer to show resend button
  React.useEffect(() => {
    if (isVisible) return

    const timer = setTimeout(() => {
      setIsVisible(true)
    }, 5 * 1000)

    return () => clearTimeout(timer)
  }, [isVisible])

  // update resend counter
  React.useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000)
    }
  }, [counter, didResend])

  if (!isVisible) return null

  if (didResend) {
    return (
      <Alert glyph="tick_circle" type="positive" css={STYLES.resend}>
        A new verification code has been sent
      </Alert>
    )
  }

  return (
    <div css={STYLES.resend}>
      <div>
        <b>Didn't receive the code yet?</b>
      </div>

      {canResend ? (
        <div>
          <Button label="Resend" glyph="reset" type="link" display="inline-flex" size={100} onClick={resendOTP} isLoading={isResending} />
        </div>
      ) : (
        <div>You can resend in {counter} seconds</div>
      )}
    </div>
  )
}

const STYLES = {
  resend: {
    display: 'grid',
    gridGap: '0.5rem',
    textAlign: 'center',
    fontWeight: 400,
    color: COLORS.textMuted,

    b: {
      fontWeight: 600,
      color: COLORS.text,
    },
  },
}
