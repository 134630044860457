import React from 'react'
import Alert from '../Alert'

const NoInternet: React.FC = () => (
  <Alert type="negative" glyph="decline">
    Your device lost its internet connection. Please copy your data to a file on your computer then fix your connection.
  </Alert>
)

export default NoInternet
