import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import LevelOfCareStatus from '../../components/Statuses/LevelOfCareStatus'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const AuthorizationsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Level of Care',
        id: 'level',
        model: 'data.level',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.data.id} value={value} />,
      },
      {
        title: 'Client',
        id: 'client',
        model: 'data.client.name',
        type: 'profile',
      },
      {
        title: 'Sessions Authorized',
        width: 100,
        id: 'sessions',
        model: 'data.sessions',
      },
      {
        title: 'Sessions Used',
        width: 100,
        id: 'used_sessions',
        model: 'data.used_sessions',
      },
      {
        title: 'Customer Notes',
        width: 200,
        id: 'notes',
        model: 'data.notes',
      },
      {
        title: 'Started On',
        width: 100,
        id: 'started_at',
        model: 'data.started_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Ending On',
        width: 100,
        id: 'ended_at',
        model: 'data.ended_at',
        type: 'date_time',
        disableSort: false,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Authorizations"
      icon="authorizations"
      columns={columns}
      filtersConfig={FILTERS.live_reports.insurance_authorizations}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="authorizations"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=authorizations'}
        />
      }
      {...props}
    />
  )
}
