import React from 'react'
import { useLocation } from 'react-router-dom'

import { usDateTime, titleCase } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'
import Status from '../Status'

const colors = {
  medication: 'red',
  tests: 'blue',
}

type Props = {
  data: any
  location: any
  timezone: any
  to: any
}

const PhysicianOrder = ({ data, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={data?.orders.join(' + ')} />
      <CardMeta>
        <Status label={`${titleCase(data?.order_type)} order`} color={colors[data?.order_type]} />
        <Text avatar={data?.author?.avatar} label={data?.author?.name} />
        <Text glyph="date" label="Last Edited: " description={usDateTime(data?.updated_at, timezone)} />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(PhysicianOrder)
