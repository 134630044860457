import produce from 'immer'

const LOCAL_STORAGE_KEY = 'bh.data_tables'

export const setTableSettings = (localStorageKey: string, settings: any) => {
  if (!localStorageKey || !settings) return null

  // set main key if it doesn't exist
  if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({}))
  }

  // update table settings
  const current = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))

  const updated = produce(current, (draft: any) => {
    draft[localStorageKey] = {
      ...draft[localStorageKey],
      ...settings,
    }
  })

  // save updated settings
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updated))
}

export const getTableSettings = (localStorageKey: string) => {
  if (!localStorageKey || !localStorage.getItem(LOCAL_STORAGE_KEY)) return null

  const current = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))

  return current[localStorageKey] || null
}

export const clearTableSettings = (localStorageKey: string) => {
  if (!localStorageKey) return null

  const current = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))

  const updated = produce(current, (draft: any) => {
    if (draft[localStorageKey]) delete draft[localStorageKey]
  })

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updated))
}
