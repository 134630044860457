import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import {
  Button,
  Card,
  Chotomate,
  CardContent,
  DnDUploader,
  Page,
  Grid,
  Uploader,
  HelpTagIframe,
  Link,
  Permission,
} from '@behavehealth/components'
import { FilesTable } from '@behavehealth/components/Tables'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

const pageConfig = {
  feature: 'files',
  help: <HelpTagIframe id="files" />,
  marketingID: 'files',
}

const Files = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const [filters, setFilters] = React.useState({})

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const queryKey = ['client', resource_id, 'files', { filters }]

  const { data, isLoading }: any = useGet({
    name: queryKey,
    url: `/residents/${resource_id}/documents`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const { mutate: deleteFiles } = useDelete({
    name: ['client', resource_id, 'files'],
    url: '/documents',
    invalidate: 'files',
  })

  const isEmpty = size(data) === 0

  const actions = (
    <Uploader category="general" resource={client} queryKey={queryKey}>
      <Button label="Upload Files" glyph="add_file" type="primary" size={300} permission="clients.files.create" />
    </Uploader>
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Grid gap="1rem">
        <Chotomate name="files" ready={!isLoading} />

        <Filters config={FILTERS.files} onUpdate={setFilters} localStorageKey="client_files" />

        <Card>
          <FilesTable
            data={data}
            isLoading={isLoading}
            emptyActions={canCreate && actions}
            localStorageKey="clients_files"
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            titleAfter={<Link to="/reports/clients-files">View Full Report →</Link>}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'clients.files.delete',
                action: async ({ ids }: any) => {
                  await deleteFiles(ids.join(','))
                },
              },
            ]}
          />
          {canCreate && (
            <CardContent>
              <Permission permission="clients.files.create">
                <DnDUploader category="general" queryKey={queryKey} resource={client} isEditable={true} isDisabled={false} />
              </Permission>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Files, pageConfig))
