import React from 'react'

import FallRiskAssessmentForm from '../../../components/Overlays/pages/DataForms/common/FallRiskAssessmentForm'

export const FALL_RISK_ASSESSMENT_SECTIONS = [
  {
    title: 'Fall Risk Assessment',
    model: 'fall_risk_assessment',
    fields: [
      {
        label: 'Fall Risk Assessment Form',
        model: 'fall_risk_assessment_form',
        component: () => <FallRiskAssessmentForm />,
      },
    ],
  },
]
