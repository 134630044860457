import React from 'react'
import { DateTime } from 'luxon'

import { ReviewSection } from './ReviewSection'
import { usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import RichTextEditor from '../../../components/Forms/RichTextEditor'
import Text from '../../../components/Typography/Text'

export const Comments = (props: any) => {
  const { before, data, title = 'Comments', model = 'comments', canEdit = true, className, updateQuote, isOpen } = props

  const { timezone: settingsTimezone } = useSettings()

  const timezone = props.timezone || settingsTimezone
  const initialComments = data?.comments?.[model]

  const [comments, setComments] = React.useState(initialComments || '')
  const [commentsEditable, setCommentsEditable] = React.useState(!initialComments)

  const [isLoading, setIsLoading] = React.useState(false)

  const handleSave = async () => {
    const timestamp = DateTime.local().setZone(timezone).toISO()

    setIsLoading(true)

    try {
      await updateQuote({
        comments: {
          ...data?.comments,
          [model]: comments,
          [`${model}_created`]: data?.comments?.[`${model}_created`] || timestamp,
          [`${model}_updated`]: timestamp,
        },
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }

    setCommentsEditable(false)
  }

  if (!data) return null

  return (
    <ReviewSection isOpen={isOpen} title={title} icon="chat" sectionModel="comments" className={className}>
      <div className="grid gap-4 pt-2">
        {before}

        <RichTextEditor
          value={comments}
          isEditable={canEdit && commentsEditable}
          onUpdate={({ value }) => {
            setComments(value)
          }}
        />

        <div>
          {canEdit && commentsEditable ? (
            <Flex gap="0.75rem">
              <Button
                label="Save Comments"
                size={200}
                display="inline-flex"
                glyph="tick_circle"
                type="primary"
                color="green"
                onClick={handleSave}
                isLoading={isLoading}
                isDisabled={isLoading}
              />

              <Button
                label="Cancel"
                size={200}
                display="inline-flex"
                onClick={() => {
                  setCommentsEditable(false)
                  setComments(initialComments)
                }}
              />
            </Flex>
          ) : (
            <div>
              {data?.comments?.[`${model}_updated`] && (
                <div>
                  <Text
                    glyph="date"
                    label="Last Updated: "
                    description={usDateTime(data.comments[`${model}_updated`], timezone)}
                    className="mt-1 text-[0.9rem]"
                  />
                </div>
              )}

              {canEdit && (
                <Button
                  label="Edit"
                  size={200}
                  display="inline-flex"
                  glyph="edit"
                  className="mt-4"
                  onClick={() => {
                    setCommentsEditable(true)
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </ReviewSection>
  )
}
