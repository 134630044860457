import React from 'react'
import { useMedia } from 'use-media'

import { BREAKPOINT, TOKENS, findFeature } from '../theme'

import NavItem from './NavItem'
import Tooltip from './Tooltip'

const AppNavItem: React.FC<any> = ({ feature, label, to, color, className, ...rest }: any) => {
  const isCompact = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavCompactBreakpoint] })
  const isExpanded = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavExpandBreakpoint] })

  const foundFeature = findFeature(feature)
  const icon = foundFeature?.icon
  const show = isCompact && !isExpanded

  return (
    <Tooltip
      position="right"
      className="!w-full !block"
      color={icon?.color || color}
      show={show}
      content={<span className="!font-[500]">{label}</span>}
    >
      <NavItem feature={feature} label={label} to={to} hideLabel={show} className={className} color={color} {...rest} />
    </Tooltip>
  )
}

export default AppNavItem
