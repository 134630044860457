import React from 'react'
import { connect } from 'react-redux'

import ContextShow from '../../../../Forms/ContextShow'
import Divider from '../../../../Divider'
import FormSection from '../../../../Forms/FormSection'
import Grid from '../../../../Grid'
import HelpTagIframe from '../../../../Help/HelpTagIframe'
import Input from '../../../../Forms/Input'
import Label from '../../../../Label'
import Overlay from '../../../../Overlay'
import PhoneInput from '../../../../Forms/PhoneInput'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Section from '../../../../Section'

import ClientProfileHeader from '../../../../ClientProfileHeader'
import DataFormStatus from '../../../../Statuses/DataFormStatus'

import { DataFormsBase, mapDispatchToProps, mapStateToProps } from '../DataFormsBase'
import { withOverlayError } from '../../../../../hocs/withOverlayError'

class IntakeFormOverlay extends DataFormsBase {
  renderHeader = () => (
    <Overlay.Header
      icon="outcome_measures"
      title={this.props.data_form?.name || this.props.title}
      titleAside={<DataFormStatus status={this.props.data_form?.status} />}
      description={<ClientProfileHeader client={this.props.data_form?.client || this.props.client} />}
      help={<HelpTagIframe id="outcome_measures_locator_form" />}
    />
  )

  renderContent = () => (
    <>
      <Section
        title="Introduction"
        headingType="h2"
        scrollview={{
          id: 'introduction',
          name: 'Introduction',
        }}
      >
        <p>
          Thank you for your help with this research! You shared a lot of information with us about how you are doing and how you are
          feeling. This information will be kept private. To complete the research, we will need to contact you approximately one month,
          three months, six months, nine months and one year from today. To do this, we need some information about where and how to reach
          you and your preferences on the best way to do this. We will keep this information separate from your interview files and it will
          be kept in a locked cabinet.
        </p>
      </Section>

      <Divider />

      <Section
        title="Contact Information"
        headingType="h2"
        scrollview={{
          id: 'contact_information',
          name: 'Contact Information',
        }}
        commentsModel="data.contact_information"
      >
        <Grid gap="1rem">
          <FormSection label="1. What address and phone numbers would be best to use when we need to reach you?">
            <Input
              label="Street Address"
              model="data.contact_information.address.address_line_1"
              validations={{
                presence: {
                  message: 'Please enter your street address',
                },
              }}
            />
            <Input label="Address Line 2" model="data.contact_information.address.address_line_2" />

            <Input label="City" model="data.contact_information.address.city" />
            <Input label="State" model="data.contact_information.address.state" />
            <Input label="Zip Code" model="data.contact_information.address.zip_code" />

            <PhoneInput label="Home Phone" model="data.contact_information.home_phone" />
            <PhoneInput label="Work Phone" model="data.contact_information.work_phone" />
            <PhoneInput label="Mobile Phone" model="data.contact_information.mobile_phone" />
          </FormSection>

          <FormSection label="2. Is there an address at which you can receive mail other than the address above?">
            <Input
              label="Street Address"
              model="data.contact_information.alt.address.address_line_1"
              validations={{
                presence: {
                  message: 'Please enter your street address',
                },
              }}
            />
            <Input label="Address Line 2" model="data.contact_information.alt.address.address_line_2" />

            <Input label="City" model="data.contact_information.alt.address.city" />
            <Input label="State" model="data.contact_information.alt.address.state" />
            <Input label="Zip Code" model="data.contact_information.alt.address.zip_code" />

            <PhoneInput label="Home Phone" model="data.contact_information.alt.home_phone" />
            <PhoneInput label="Work Phone" model="data.contact_information.alt.work_phone" />
            <PhoneInput label="Mobile Phone" model="data.contact_information.alt.mobile_phone" />
          </FormSection>
        </Grid>
      </Section>

      <Divider />

      <Section
        title="Alternate Contact Information"
        headingType="h2"
        scrollview={{
          id: 'alt_contact_information',
          name: 'Alternate Contact Info',
        }}
        commentsModel="data.alt_contact_information"
      >
        <Grid gap="1rem">
          <FormSection label="3. Please provide information of at least one family member or friend who knows how to reach you:">
            <Input
              label="Street Address"
              model="data.alt_contact_information.family.address.address_line_1"
              validations={{
                presence: {
                  message: 'Please enter your street address',
                },
              }}
            />
            <Input label="Address Line 2" model="data.alt_contact_information.family.address.address_line_2" />

            <Input label="City" model="data.alt_contact_information.family.address.city" />
            <Input label="State" model="data.alt_contact_information.family.address.state" />
            <Input label="Zip Code" model="data.alt_contact_information.family.address.zip_code" />

            <PhoneInput label="Home Phone" model="data.alt_contact_information.family.home_phone" />
            <PhoneInput label="Work Phone" model="data.alt_contact_information.family.work_phone" />
            <PhoneInput label="Mobile Phone" model="data.alt_contact_information.family.mobile_phone" />

            <Input label="Relationship" model="data.alt_contact_information.family.relationship" />
          </FormSection>

          <FormSection label="4. Do you have an AA, NA, DRA, or other self-help group sponsor or fellow that would know how to reach you?">
            <Input
              label="Street Address"
              model="data.alt_contact_information.sponsor.address.address_line_1"
              validations={{
                presence: {
                  message: 'Please enter your street address',
                },
              }}
            />

            <Input label="City" model="data.alt_contact_information.sponsor.address.city" />
            <Input label="State" model="data.alt_contact_information.sponsor.address.state" />
            <Input label="Zip Code" model="data.alt_contact_information.sponsor.address.zip_code" />

            <PhoneInput label="Home Phone" model="data.alt_contact_information.sponsor.home_phone" />
            <PhoneInput label="Work Phone" model="data.alt_contact_information.sponsor.work_phone" />
            <PhoneInput label="Mobile Phone" model="data.alt_contact_information.sponsor.mobile_phone" />

            <Input label="Relationship" model="data.alt_contact_information.sponsor.relationship" />
          </FormSection>

          <FormSection label="5. Is there a residence where you visit/stay frequently?">
            <Input
              label="Street Address"
              model="data.alt_contact_information.visit_residence.address.address_line_1"
              validations={{
                presence: {
                  message: 'Please enter your street address',
                },
              }}
            />
            <Input label="Address Line 2" model="data.alt_contact_information.visit_residence.address.address_line_2" />

            <Input label="City" model="data.alt_contact_information.visit_residence.address.city" />
            <Input label="State" model="data.alt_contact_information.visit_residence.address.state" />
            <Input label="Zip Code" model="data.alt_contact_information.visit_residence.address.zip_code" />

            <PhoneInput label="Home Phone" model="data.alt_contact_information.visit_residence.home_phone" />
            <PhoneInput label="Work Phone" model="data.alt_contact_information.visit_residence.work_phone" />
            <PhoneInput label="Mobile Phone" model="data.alt_contact_information.visit_residence.mobile_phone" />

            <Input label="Relationship" model="data.alt_contact_information.visit_residence.relationship" />
          </FormSection>

          <FormSection label="6. Are there any other people who may know of your whereabouts, such as a doctor, caseworker, community clinic, or counselor that you see regularly?">
            <Input
              label="Street Address"
              model="data.alt_contact_information.counselor.address.address_line_1"
              validations={{
                presence: {
                  message: 'Please enter your street address',
                },
              }}
            />
            <Input label="Address Line 2" model="data.alt_contact_information.counselor.address.address_line_2" />

            <Input label="City" model="data.alt_contact_information.counselor.address.city" />
            <Input label="State" model="data.alt_contact_information.counselor.address.state" />
            <Input label="Zip Code" model="data.alt_contact_information.counselor.address.zip_code" />

            <PhoneInput label="Home Phone" model="data.alt_contact_information.counselor.home_phone" />
            <PhoneInput label="Work Phone" model="data.alt_contact_information.counselor.work_phone" />
            <PhoneInput label="Mobile Phone" model="data.alt_contact_information.counselor.mobile_phone" />

            <Input label="Relationship" model="data.alt_contact_information.counselor.relationship" />
          </FormSection>
        </Grid>
      </Section>

      <Divider />

      <Section
        title="Other"
        headingType="h2"
        scrollview={{
          id: 'other',
          name: 'Other',
        }}
        commentsModel="data.other"
      >
        <Grid gap="1rem">
          <FormSection>
            <RadioGroup
              label="7. Are you currently on probation/parole?"
              model="data.other.currently_incarcerated"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value="yes" />
              <Radio label="No" value="no" />
            </RadioGroup>
            <ContextShow when="data.other.currently_incarcerated" is="yes">
              <FormSection className="!pl-4">
                <RadioGroup label="7a. If yes, which one?" model="data.other.incarceration_type" layout="horizontal-dense">
                  <Radio label="Probation" value="probation" />
                  <Radio label="Parole" value="parole" />
                </RadioGroup>

                <div>
                  <Label label="7b. When did it start?" />
                  <FormSection horizontal>
                    <Input label="Month" model="data.other.incarceration_start_month" size={5} />
                    <Input label="Year" model="data.other.incarceration_start_year" size={5} />
                  </FormSection>
                </div>

                <div>
                  <Label label="7c. When does it end?" />
                  <FormSection horizontal>
                    <Input label="Month" model="data.other.incarceration_end_month" size={5} />
                    <Input label="Year" model="data.other.incarceration_end_year" size={5} />
                  </FormSection>
                </div>

                <FormSection label="7d. Name of supervising official?">
                  <Input label="Full Name" model="data.other.supervising_official.name" />
                  <Input
                    label="Street Address"
                    model="data.other.supervising_official.address.address_line_1"
                    validations={{
                      presence: {
                        message: 'Please enter a street address',
                      },
                    }}
                  />
                  <Input label="Address Line 2" model="data.other.supervising_official.address.address_line_2" />

                  <Input label="City" model="data.other.supervising_official.address.city" />
                  <Input label="State" model="data.other.supervising_official.address.state" />
                  <Input label="Zip Code" model="data.other.supervising_official.address.zip_code" />

                  <PhoneInput label="Phone" model="data.other.supervising_official.home_phone" />
                </FormSection>
              </FormSection>
            </ContextShow>

            <RadioGroup
              label="8. May we ask clinical staff or review your medical record at XXX to collect additional phone numbers, addresses, or other contact information if necessary?"
              model="data.other.can_ask_for_additional_contact_information"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value="yes" />
              <Radio label="No" value="no" />
            </RadioGroup>

            <RadioGroup
              label="9. When we contact you or contact others who may know of your whereabouts, it is OK to say that we are with XXX ? We will not share any information about your treatment only that you are participating in “The National Survey”."
              model="data.other.can_share_details"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value="yes" />
              <Radio label="No" value="no" />
            </RadioGroup>
          </FormSection>

          <p>
            If we cannot reach you on the phone, we may email and text message you. Again, the survey will be referred to as “The National
            Survey” to protect your confidentiality.
          </p>
        </Grid>
      </Section>

      <Divider />

      <Section>
        <p>
          <b>Copyright:</b> The NAATP Addiction Treatment Outcomes Measurement Program
        </p>
      </Section>
    </>
  )
}

IntakeFormOverlay.defaultProps = {
  title: 'Locator Form',
  category: 'outcome_measure',
  subcategory: 'locator_form',
  requestClientSignature: false,
  requestAuthorSignature: false,
  requireSupervisor: false,
  maxWidth: 85,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(IntakeFormOverlay))
