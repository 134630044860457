import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Global } from '@emotion/react'

import 'sanitize.css/sanitize.css'

import RedirectURL from './redirect'
import Login from './access/login'
import Logout from './access/logout'
import App from './app'
import Setup from './setup'
import Security from './security'
import SelectPlan from './select_plan'
import Unpaid from './unpaid'
import Proposal from './proposal'
import Testing from './testing'
import { ApplicationForms } from './forms/application_forms'

import ErrorBoundary from '@behavehealth/components/ErrorBoundary'
import DevStats from '@behavehealth/components/DevStats/DevStats'
import NetworkStatus from '@behavehealth/components/NetworkStatus'
import VersionUpdater from '@behavehealth/components/VersionUpdater'

import { GLOBAL_STYLES } from '@behavehealth/theme'
import Network from '@behavehealth/modules/network'

import { QueryProvider, QueryDevTools } from '@behavehealth/modules/api/QueryProvider'

const Entry = () => {
  React.useEffect(() => {
    Network.start()

    return () => {
      Network.stop()
    }
  }, [])

  return (
    <QueryProvider>
      <Global styles={GLOBAL_STYLES} />

      <ErrorBoundary>
        <DevStats />
        <NetworkStatus />
        <VersionUpdater />

        <Switch>
          <Route exact path="/redirect" component={RedirectURL} />
          <Route exact path="/:tenant_id/forms/:slug" component={ApplicationForms} />

          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />

          <Route exact path="/select-plan" component={SelectPlan} />
          <Route exact path="/proposal" component={Proposal} />
          <Route exact path="/unpaid" component={Unpaid} />
          <Route exact path="/setup" component={Setup} />
          <Route exact path="/security" component={Security} />

          <Route path="/testing" component={Testing} />

          <Route path="/*" component={App} />

          <Redirect to="/login" />
        </Switch>
      </ErrorBoundary>

      {process.env.NODE_ENV === 'development' && <QueryDevTools />}
    </QueryProvider>
  )
}

export default Entry
