import React from 'react'
import { DateTime } from 'luxon'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Textarea from '../../components/Forms/Textarea'
import Section from '../../components/Section'

import { useCommunityEditor } from './useCommunityEditor'

const RootCommunityProfileHealthcareServiceOverlay = (props: any) => {
  const { entityId } = props

  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  const { isNew, close, saveWithData, form, data, onValidationUpdate, isOverlayLoading, initialModel, isInvalid, isSaving } = useOverlay({
    name: 'entity_healthcare_service',
    endpoint: `/community/entity_healthcare_services`,
    invalidate: 'entity_healthcare_services',
    invalidateKeys: ['community-entity', 'entity_healthcare_services'],
    isEditable: true,
    options: props,
    closeOnSave: true,
    onSaveSuccessful: (newData: any) => {
      setUpdatedAt(DateTime.now().toMillis())
    },
  })

  const { timezone } = useSettings()

  const handleSave = () => {
    saveWithData({
      ...form.current?.getFormValue(),
      ...(isNew && { entity_id: entityId }),
    })
  }

  if (isOverlayLoading) return <OverlayLoader position="center" maxWidth={40} />

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" maxWidth={40} onClose={close}>
      <Overlay.Header title="Healthcare Service" icon="healthcare_services" />

      <Overlay.Content>
        <Section>
          <Form
            isEditable
            getForm={form}
            initialModel={initialModel}
            timezone={timezone}
            onValidationUpdate={onValidationUpdate}
            className="grid gap-4"
          >
            <FormSection maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <MultiObjectSelector
                label="Locations"
                model="locations"
                icon="locations"
                type="community_profile.locations"
                dependentValue={entityId}
                selectTitle={(data: any) => data.name}
              />

              <MultiObjectSelector
                label="Insurance Payers"
                model="entity_insurance_payers"
                icon="bank_building"
                type="community_profile.insurance_payers"
                dependentValue={entityId}
                selectTitle={(data: any) => data.name}
              />

              <Textarea label="Description" model="description" />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save Healthcare Service"
          glyph="tick_circle"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={isSaving}
          isDisabled={isInvalid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const CommunityProfileHealthcareServiceOverlay = withOverlayError(RootCommunityProfileHealthcareServiceOverlay)
