import React from 'react'
import clsx from 'clsx'
import { darken } from 'polished'

import { COLORS } from '../../theme'
import { CSS_VALUES } from './css'
import { WorkflowContext, WorkflowPanelContext } from './context'

import Glyph from '../Glyph'
import Card from '../Card'
import Markup from '../Markup'

const WorkflowContent = (props: any) => {
  if (!props.isOpen) return null

  const { children, render, goNext, showIndent, alignToBaseline } = props
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context: any = React.useContext(WorkflowContext)

  const { step, hasPermission, noPermissionUI }: any = React.useContext(WorkflowPanelContext)

  const instructions = context?.stepInstructions?.[step]

  const classNames = clsx({
    'show-indent': showIndent || context.showIndent,
    'align-to-baseline': alignToBaseline,
  })

  if (!hasPermission) return noPermissionUI || null

  return (
    <div css={styles} className={classNames}>
      {instructions && (
        <Card className="flex items-center flex-nowrap px-3 py-2 mb-4">
          <Glyph glyph="info" size={20} className="mr-2" />
          <Markup value={instructions} />
        </Card>
      )}
      {children}
      {render && render({ goNext })}
    </div>
  )
}

const styles = {
  position: 'relative',
  padding: `${CSS_VALUES.paddingY} ${CSS_VALUES.paddingX}`,

  '@media (min-width: 600px)': {
    '&.align-to-baseline': {
      paddingLeft: CSS_VALUES.baseline,
    },

    '&.show-indent': {
      paddingLeft: CSS_VALUES.baseline,

      '&::before': {
        content: '""',
        position: 'absolute',
        height: '100%',
        width: 1,
        top: 0,
        left: `calc(${CSS_VALUES.baseline} / 2)`,
        background: darken(0.2, COLORS.divider),
      },
    },
  },
}

export default WorkflowContent
