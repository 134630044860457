import React from 'react'
import { connect } from 'react-redux'

import Card from '../../../../Card'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import Divider from '../../../../Divider'
import FormSection from '../../../../Forms/FormSection'
import Grid from '../../../../Grid'
import GridTable from '../../../../GridTable'
import HelpTagIframe from '../../../../Help/HelpTagIframe'
import Input from '../../../../Forms/Input'
import Overlay from '../../../../Overlay'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Section from '../../../../Section'

import ClientProfileHeader from '../../../../ClientProfileHeader'
import DataFormStatus from '../../../../Statuses/DataFormStatus'

import { DataFormsBase, mapDispatchToProps, mapStateToProps } from '../DataFormsBase'
import { withOverlayError } from '../../../../../hocs/withOverlayError'

const SERVICES = [
  { name: '1a. Days of outpatient detoxification', model: 'days_of_outpatient_detox', suffix: 'days' },
  { name: '1b. Days of inpatient detoxification', model: 'days_of_inpatient_detox', suffix: 'days' },
  { name: '1c. Days of medically managed inpatient', model: 'days_of_medically_managed_inpatient', suffix: 'days' },
  { name: '1d. Days of medically monitored inpatient', model: 'days_of_medically_monitored_inpatient', suffix: 'days' },
  { name: '1e. Days of clinically managed residential', model: 'days_of_clinically_managed_residential', suffix: 'days' },
  { name: '1f. Days of population-specific residential', model: 'days_of_population_specific_residential', suffix: 'days' },
  { name: '1g. Days of low intensity residential', model: 'dats_of_low_intensity_residential', suffix: 'days' },
  { name: '1h. Days of monitored sober living', model: 'days_of_monitored_sober_living', suffix: 'days' },
  { name: '1i. Hours of partial hospitalization services', model: 'hours_of_partial_hospitalization_services', suffix: 'hours' },
  { name: '1j. Hours of intensive outpatient services', model: 'hours_of_intensive_outpatient_services', suffix: 'hours' },
  { name: '1k. Hours of outpatient services', model: 'hours_of_outpatient_services', suffix: 'hours' },
  { name: '1l. Hours of early intervention/pretreatment', model: 'hours_of_early_intervention', suffix: 'hours' },
]

const TREATMENT_GROUP_UTILIZED = [
  { name: 'Not used/Not applicable', model: 'not_applicable' },
  { name: 'Once or twice', model: 'once_or_twice' },
  { name: 'More than once or twice', model: 'more_than_once_or_twice' },
  { name: 'Weekly', model: 'weekly' },
  { name: 'Several times per week', model: 'several_times_per_week' },
]

const TREATMENT_GROUPS = [
  { name: '6a. Group therapy', model: 'group_therapy' },
  { name: '6b. Individual counseling', model: 'individual_counseling' },
  { name: '6c. Lectures and education', model: 'lectures_and_education' },
  { name: '6d. Working the AA/NA steps', model: 'working_the_aa_na_steps' },
  { name: '6e. Peer group meetings (e.g AA)', model: 'peer_group_meetings' },
  { name: '6f. Family portion of program', model: 'family_portion_of_program' },
]

const SUPPORT_LEVEL = [
  { name: 'No contact/NA', model: 'not_applicable' },
  { name: 'Not supportive', model: 'not_supportive' },
  { name: 'Somewhat unsupportive', model: 'somewhat_unsupportive' },
  { name: 'Neutral', model: 'neutral' },
  { name: 'Somewhat supportive', model: 'somewhat_supportive' },
  { name: 'Very supportive', model: 'very_supportive' },
]

const FAMILY_AND_FRIENDS = [
  { name: '11a. Spouse / mate / partner', model: 'partner' },
  { name: '11b. Closest friend', model: 'closest_friend' },
  { name: '11c. Father', model: 'father' },
  { name: '11d. Mother', model: 'mother' },
  { name: '11e. Brother', model: 'brother' },
  { name: '11f. Sister', model: 'sister' },
  { name: '11f. Other close family member', model: 'other_close_family_member' },
  { name: '11f. Other close friends', model: 'other_close_friend' },
  { name: '11f. Fellow workers', model: 'fellow_workers' },
]

class ServiceSummaryFormOverlay extends DataFormsBase {
  renderHeader = () => (
    <Overlay.Header
      icon="outcome_measures"
      title={this.props.data_form?.name || this.props.title}
      titleAside={<DataFormStatus status={this.props.data_form?.status} />}
      description={<ClientProfileHeader client={this.props.data_form?.client || this.props.client} />}
      help={<HelpTagIframe id="outcome_measures_service_summary_form" />}
    />
  )

  renderContent = () => (
    <>
      <Section
        title="Service Summary Form"
        headingType="h2"
        scrollview={{
          id: 'service_summary',
          name: 'Service Summary Form',
        }}
        commentsModel="data.service_summary"
      >
        <Grid gap="1rem">
          <FormSection
            label="1. Please indicate the duration of services you have received in each of the following, since the intake date"
            maxWidth="100%"
          >
            <Card css={{ marginBottom: '1rem', maxWidth: 500 }}>
              <GridTable firstColumnSticky templateColumns="180px 260px">
                <GridTable.Header>
                  <GridTable.Cell></GridTable.Cell>
                  <GridTable.Cell>Duration of Service</GridTable.Cell>
                </GridTable.Header>

                {SERVICES.map((service) => (
                  <GridTable.Row key={service.model}>
                    <GridTable.Cell>{service.name}</GridTable.Cell>

                    <GridTable.Cell css={checkboxCellStyles}>
                      <Input
                        model={`data.service_summary.duration_of_received_services.${service.model}`}
                        type="number"
                        size={4}
                        suffix={service.suffix}
                      />
                    </GridTable.Cell>
                  </GridTable.Row>
                ))}
              </GridTable>
            </Card>
          </FormSection>

          <FormSection>
            <RadioGroup
              label="2. Please indicate your current treatment status, since the intake date:"
              model="data.service_summary.current_treatment_status"
              layout="vertical-dense"
            >
              <Radio label="Did not engage in treatment" value="did_not_engage_in_treatment" />
              <Radio label="Stepped up to higher level of care" value="stepped_up_to_higher_loc" />
              <Radio label="Trasferred to another provider" value="transferred_to_another_provider" />
              <Radio label="Discharged for noncompliance" value="discharged_for_noncompliance" />
              <Radio label="Left against staff advice" value="left_against_staff_advice" />
              <Radio
                label="Successfully completed intensive treatment phase and I will no longer be with this program"
                value="completed_intensive_treatment_and_will_no_longer_be_in_program"
              />
              <Radio
                label="Successfully completed intensive treatment phase and I plan to be in continuing care with this program"
                value="completed_intensive_treatment_and_will_continue_in_program"
              />
            </RadioGroup>

            <RadioGroup
              label="3. Did you follow all treatment recommendations?"
              model="data.service_summary.did_follow_treatment_recommendations"
              layout="vertical-dense"
            >
              <Radio label="Yes, all recommended services were delivered" value="yes" />
              <Radio
                label="No, I refused some or all of treatment for personal reasons"
                value="no_refused_some_or_all_treatment_for_personal_reasons"
              />
              <Radio label="No, due to funding/reimbursement limitations" value="no_due_to_funding_limitations" />
              <Radio label="No, due to unavailability of services" value="no_due_to_unavailability_of_services" />
              <Radio label="No for other reasons" value="no_for_other_reasons" />
            </RadioGroup>

            <ContextShow when="data.service_summary.did_follow_treatment_recommendations" is="no_for_other_reasons">
              <Input
                label="3a. Please, specify reason to not follow treatment recommendations"
                model="data.service_summary.did_not_follow_treatment_recommendations_reason"
              />
            </ContextShow>

            <RadioGroup label="4. How was your treatment funded?" model="data.service_summary.treatment_funded" layout="vertical-dense">
              <Radio label="Insurance" value="insurance" />
              <Radio label="Self pay" value="selfpay" />
              <Radio label="Combination of insurance and self-pay" value="insurance_and_selfpay" />
              <Radio label="Other" value="other" />
            </RadioGroup>

            <ContextShow when="data.service_summary.treatment_funded" is="other">
              <Input label="4a. Please, specify how your treatment funded:" model="data.service_summary.treatment_funded_other" />
            </ContextShow>

            <RadioGroup
              label="5. Was the funding for your treatment sufficient or all services required?"
              model="data.service_summary.was_funding_sufficient"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>
          </FormSection>

          <FormSection label="6. Which of the following program components did you utilize during treatment?" maxWidth="100%">
            <Card css={{ marginBottom: '1rem', maxWidth: 940 }}>
              <GridTable firstColumnSticky templateColumns="180px repeat(5, 140px)">
                <GridTable.Header>
                  <GridTable.Cell></GridTable.Cell>
                  <GridTable.Cell>Not used/not applicable</GridTable.Cell>
                  <GridTable.Cell>Once or twice</GridTable.Cell>
                  <GridTable.Cell>More than once or twice</GridTable.Cell>
                  <GridTable.Cell>Weekly</GridTable.Cell>
                  <GridTable.Cell>Several times per week</GridTable.Cell>
                </GridTable.Header>

                {TREATMENT_GROUPS.map((treatmentGroup) => (
                  <GridTable.Row key={treatmentGroup.model}>
                    <GridTable.Cell>{treatmentGroup.name}</GridTable.Cell>

                    {TREATMENT_GROUP_UTILIZED.map((treatmentGroupUtilized) => (
                      <GridTable.Cell key={treatmentGroupUtilized.model} css={checkboxCellStyles}>
                        <Checkbox
                          trueIcon="check"
                          model={`data.service_summary.treatment_group_utilization.${treatmentGroup.model}.${treatmentGroupUtilized.model}`}
                        />
                      </GridTable.Cell>
                    ))}
                  </GridTable.Row>
                ))}
              </GridTable>
            </Card>
          </FormSection>

          <FormSection label="7. Were any of the following medications administered?">
            <FormSection className="!pl-4">
              <RadioGroup
                label="7a. Detox medications (non-opiate)"
                model="data.service_summary.medications_administered.non_opiate_detox_medications"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="7b. Opiate taper medications"
                model="data.service_summary.medications_administered.opiate_taper_medications"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="7c. Psychotropic medications"
                model="data.service_summary.medications_administered.psychotropic_medications"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="7d. Anti-craving medications"
                model="data.service_summary.medications_administered.anti_craving_medications"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>
            </FormSection>
          </FormSection>

          <FormSection label="8. Were opiate replacement maintenance medications prescribed?">
            <FormSection className="!pl-4">
              <RadioGroup
                label="8a. Buprenorphine (Subutex®)"
                model="data.service_summary.opiate_replacement_medications_prescribed.buprenorphine"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="8b. Buprenorphine-naloxone (Suboxone)"
                model="data.service_summary.opiate_replacement_medications_prescribed.buprenorphine_naloxone"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="8c. Vivitrol extended release Naltrexone"
                model="data.service_summary.opiate_replacement_medications_prescribed.vivitrol"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="8d. Naltrexone in other form"
                model="data.service_summary.opiate_replacement_medications_prescribed.naltrexone"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="8e. Methadone"
                model="data.service_summary.opiate_replacement_medications_prescribed.methadone"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="8f. LAAM"
                model="data.service_summary.opiate_replacement_medications_prescribed.laam"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="8g. Other"
                model="data.service_summary.opiate_replacement_medications_prescribed.other"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.service_summary.opiate_replacement_medications_prescribed.other" is={true}>
                <Input
                  label="8h. Please, specify which opiate replacement maintenance medications were prescribed:"
                  model="data.service_summary.opiate_replacement_medications_prescribed.other_medications_prescribed"
                />
              </ContextShow>
            </FormSection>
          </FormSection>

          <FormSection label="9. Were you diagnosed with any of the following conditions during treatment?">
            <FormSection className="!pl-4">
              <RadioGroup
                label="9a. Major depressive episodes"
                model="data.service_summary.diagnosed_with_conditions_during_treatment.major_depressive_episodes"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="9b. Bipolar episodes"
                model="data.service_summary.diagnosed_with_conditions_during_treatment.bipolar_episodes"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="9c. PTSD"
                model="data.service_summary.diagnosed_with_conditions_during_treatment.ptsd"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="9d. Other trauma disorder"
                model="data.service_summary.diagnosed_with_conditions_during_treatment.other_trauma_disorder"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="9e. Anxiety disorder(s)"
                model="data.service_summary.diagnosed_with_conditions_during_treatment.anxiety_disorder"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="9f. Psychosis/schizophrenia"
                model="data.service_summary.diagnosed_with_conditions_during_treatment.psychosis_schizophrenia"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="9g. Antisocial personality"
                model="data.service_summary.diagnosed_with_conditions_during_treatment.antisocial_personality"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup
                label="9h. Other"
                model="data.service_summary.diagnosed_with_conditions_during_treatment.other"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.service_summary.diagnosed_with_conditions_during_treatment.other" is={true}>
                <Input
                  label="9h. Please, specify which conditions were you diagnosed during treatment:"
                  model="data.service_summary.diagnosed_with_conditions_during_treatment.other_conditions_diagnosed_during_treatment"
                />
              </ContextShow>
            </FormSection>
          </FormSection>

          <FormSection>
            <CheckboxGroup
              label="10. What arrangements have you made for continuing services?"
              labelDescription="(Mark all that apply)"
              layout="vertical-dense"
              trueIcon="check"
            >
              <Checkbox
                label="Regularly scheduled appointments here (continuing care)"
                model="data.service_summary.arrangements_made_for_continuing_services.regularly_scheduled_appointments"
              />
              <Checkbox
                label="Referred to another provider for continuing care"
                model="data.service_summary.arrangements_made_for_continuing_services.referred_another_provider"
              />
              <Checkbox
                label="Given contact for services as needed"
                model="data.service_summary.arrangements_made_for_continuing_services.given_contact_for_services_as_needed"
              />
              <Checkbox
                label="Currently participating in peer-support groups"
                model="data.service_summary.participating_in_peer_support_groups"
              />
              <Checkbox
                label="Continues in facility-sponsored non-treatment meetings/activities"
                model="data.service_summary.arrangements_made_for_continuing_services.continues_in_facility_sponsored_activities"
              />
              <Checkbox
                label="Continues to receive services for mental health issues"
                model="data.service_summary.arrangements_made_for_continuing_services.continues_to_receive_services_for_mental_health_issues"
              />
              <Checkbox label="Other arrangement:" model="data.service_summary.arrangements_made_for_continuing_services.other" />
            </CheckboxGroup>

            <ContextShow when="data.service_summary.arrangements_made_for_continuing_services.other" is={true}>
              <div className="!pl-4">
                <Input
                  label="10b. Please, specify which conditions were you diagnosed during treatment:"
                  model="data.service_summary.arrangements_made_for_continuing_services.other_arrangement"
                />
              </div>
            </ContextShow>
          </FormSection>

          <FormSection label="11. How supportive or non-supportive were the following people during your treatment?" maxWidth="100%">
            <Card className="!mb-4 max-w-[960px]">
              <GridTable firstColumnSticky templateColumns="180px repeat(6, 120px)">
                <GridTable.Header>
                  <GridTable.Cell></GridTable.Cell>
                  <GridTable.Cell>No contact/NA</GridTable.Cell>
                  <GridTable.Cell>Not supportive</GridTable.Cell>
                  <GridTable.Cell>Somewhat unsupportive</GridTable.Cell>
                  <GridTable.Cell>Neutral</GridTable.Cell>
                  <GridTable.Cell>Somewhat supportive</GridTable.Cell>
                  <GridTable.Cell>Very supportive</GridTable.Cell>
                </GridTable.Header>

                {FAMILY_AND_FRIENDS.map((familyOrFriend) => (
                  <GridTable.Row key={familyOrFriend.model}>
                    <GridTable.Cell>{familyOrFriend.name}</GridTable.Cell>

                    {SUPPORT_LEVEL.map((supportLevel) => (
                      <GridTable.Cell key={supportLevel.model} css={checkboxCellStyles}>
                        <Checkbox
                          trueIcon="check"
                          model={`data.service_summary.family_and_friends_support.${familyOrFriend.model}.${supportLevel.model}`}
                        />
                      </GridTable.Cell>
                    ))}
                  </GridTable.Row>
                ))}
              </GridTable>
            </Card>
          </FormSection>
        </Grid>
      </Section>

      <Divider />

      <Section>
        <p>
          <b>Copyright:</b> The NAATP Addiction Treatment Outcomes Measurement Program
        </p>
      </Section>
    </>
  )
}

const checkboxCellStyles = {
  display: 'flex',
  justifyContent: 'center',
}

ServiceSummaryFormOverlay.defaultProps = {
  title: 'Service Summary Form',
  category: 'outcome_measure',
  subcategory: 'service_summary',
  requestClientSignature: false,
  requestAuthorSignature: false,
  requireSupervisor: false,
  maxWidth: 85,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(ServiceSummaryFormOverlay))
