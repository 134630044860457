import React from 'react'
import { transparentize } from 'polished'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../../../theme'
import { FILE_TYPES } from '../../../../utils/constants'
import { FormContext } from '../../../../components/Forms/context'
import { readFileAsDataURL } from '../../../../utils/functions'
import { useCreate } from '../../../../hooks/useNewAPI'
import { useFormField } from '../../../../components/Forms/hooks/useFormField'
import Notifications from '../../../../modules/notifications'

import CardLink from '../../../../components/CardLink'
import CardSubtitle from '../../../../components/CardSubtitle'
import CardTitle from '../../../../components/CardTitle'
import DropdownItem from '../../../../components/DropdownItem'
import Glyph from '../../../../components/Glyph'
import Label from '../../../../components/Label'
import Loader from '../../../../components/Loader'
import QuickView from '../../../../components/QuickView'

import { formatInputModel } from '../../utils/functions'
import { getFormElementProps } from '../../utils/functions'

export const MultiAttachmentInput = (props: any) => {
  const { children, element, className, hoverElement, useParsedConfig, environment } = props

  const { form, isEditable }: any = React.useContext(FormContext)

  const isBuilder = environment === 'builder'
  const isSubmission = environment === 'submission'
  const showActions = isBuilder || (isSubmission && isEditable)

  const model = formatInputModel(element)

  const { formActions, initialValue } = useFormField({
    model: model,
    form: form,
  })

  const { mutateAsync: upload, isLoading: isUploading }: any = useCreate({
    name: ['create-file-attachments'],
    url: `/file_attachments`,
  })

  const [data, setData]: any = React.useState(initialValue || [])
  const [uploadingCount, setUploadingCount]: any = React.useState(null)

  const isEmpty = size(data) === 0

  // Functions
  const handleChange = async (event: any) => {
    const payload: any = []
    const items = event.target.files
    const itemsCount = size(items)

    if (itemsCount === 0) return

    setUploadingCount(itemsCount)

    for (const item of items) {
      payload.push({
        attachment_name: item.name,
        attachment_data: await readFileAsDataURL(item),
      })
    }

    try {
      const response = await upload(payload)

      const newData = produce(data, (draft: any) => {
        for (const item of response.data) {
          draft.push({
            ...item,
            url: item.url,
          })
        }
      })

      setData(newData)
    } catch (error) {
      console.error(error)
    }
  }

  const handleLabelClick = (event: any) => {
    if (!isSubmission) {
      event.preventDefault()
      Notifications.send('Files can only be uploaded from Form Submissions', 'neutral')
    }
  }

  const handleDelete = (id: string) => {
    if (!id || size(data) === 0) return

    const newData = produce(data, (draft: any) => {
      const index = data.findIndex((item: any) => item.id === id)

      if (index > -1) draft.splice(index, 1)
    })

    setData(newData)
  }

  // Effects
  React.useEffect(() => {
    formActions.setValue(data)
  }, [model, data])

  if (!element) return null

  const rootClasses = clsx('MULTI_ATTACHMENT_INPUT', className)

  const inputProps = getFormElementProps(element, { useParsedConfig, environment })

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}

      {inputProps?.label && <Label label={inputProps?.label} tooltip={inputProps?.tooltip} />}
      {inputProps?.description && <div css={STYLES.description}>{inputProps?.description}</div>}

      {!isEmpty &&
        data?.map((item: any) => (
          <QuickView key={item.id} record={item}>
            {({ open }: any) => {
              const fileType = item?.content_type ? FILE_TYPES?.[item.content_type]?.name : 'Other'

              return (
                <CardLink
                  baseline="2.5rem"
                  paddingY="0.1rem"
                  graphic={<Glyph glyph="tick_circle" size={18} />}
                  css={STYLES.card}
                  onClick={open}
                  actions={
                    <>
                      <DropdownItem label="View File" glyph="view" color="blue" target="_blank" href={item.url} />
                      <DropdownItem label="Download" glyph="download" color="blue" target="_blank" href={item.download} />
                      {showActions && <DropdownItem label="Delete…" glyph="delete" color="red" onClick={() => handleDelete(item.id)} />}
                    </>
                  }
                >
                  <CardTitle title={item.filename} css={STYLES.title} />
                  <CardSubtitle subtitle={fileType} css={STYLES.subtitle} />
                </CardLink>
              )
            }}
          </QuickView>
        ))}

      {isEditable && (
        <label css={STYLES.label} onClick={handleLabelClick}>
          {isSubmission && !isUploading && <input multiple type="file" onChange={handleChange} css={STYLES.input} />}

          <CardLink
            baseline="2.5rem"
            paddingY="0.4rem"
            graphic={isUploading ? <Loader size={18} /> : <Glyph glyph="upload" size={16} />}
            css={STYLES.cardEmpty}
          >
            <CardTitle
              title={isUploading ? `Uploading ${uploadingCount} Files…` : 'Drag & Drop or click to upload files'}
              css={STYLES.title}
            />
          </CardLink>
        </label>
      )}
    </div>
  )
}

const STYLES = {
  label: {
    display: 'block',
  },

  description: {
    color: COLORS.textMuted,
    fontSize: '0.95rem',
  },

  title: {
    fontSize: '0.95rem',
  },

  subtitle: {
    fontSize: '0.9rem',
  },

  card: {
    margin: '0.5rem 0 0.75rem',
  },

  input: {
    display: 'none',
  },

  cardEmpty: {
    margin: '0.5rem 0 0.75rem',
    boxShadow: 'none',
    border: `2px dashed ${COLORS.divider}`,
    borderRadius: 5,
    cursor: 'pointer',

    '&:hover': {
      background: transparentize(0.1, COLORS.divider),
      border: `2px dashed ${COLORS.divider}`,
    },
  },
}
