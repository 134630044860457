import React from 'react'
import dompurify from 'dompurify'

import MultiSearchSelector from '../Selectors/MultiSearchObject/MultiSearchSelector'

const sanitize = dompurify.sanitize

const PerDiemServiceCodeSelector = ({ label = 'Per Diem Service Code', model = 'per_diem_service_code', ...rest }) => (
  <MultiSearchSelector
    showAvatars={false}
    label={label}
    model={model}
    type="insurance_codes.per_diem_service_code"
    selectTitle={(data, highlights) => {
      let title = data?.code
      if (!highlights) return title

      for (let i = 0; i < highlights?.length; i++) {
        if (highlights[i].field === 'code') {
          title = highlights[i].snippet
          break
        }
      }

      return <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
    }}
    selectDescription={(data, highlights) => {
      let description = data.description
      if (!highlights) return description

      for (let i = 0; i < highlights?.length; i++) {
        if (highlights[i].field === 'description') {
          description = highlights[i].snippet
          break
        }
      }

      return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
    }}
    {...rest}
  />
)

export default PerDiemServiceCodeSelector
