import React from 'react'

import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import PhoneInput from '../../Forms/PhoneInput'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const AdditionalContactInformationFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Type as="h2" variant="H3">
        Additional Contact Information
      </Type>

      <FormSection
        heading="Admissions & Intake Contact"
        description="Who does a new client contact when they want to apply to receive services?"
        css={{ gridGap: 0 }}
      >
        <Flex horizontal stretchChildrenX gap="1rem" css={{ marginTop: 0 }}>
          <Input label="Name" model="questionnaire.contacts.admissions.name" />
          <PhoneInput label="Phone Number" model="questionnaire.contacts.admissions.phone_no" />
        </Flex>
      </FormSection>

      <FormSection
        heading="Service Delivery Contact"
        description="Who does a client contact when they have a question about ongoing services?"
        css={{ gridGap: 0 }}
      >
        <Flex horizontal stretchChildrenX gap="1rem" css={{ marginTop: 0 }}>
          <Input label="Name" model="questionnaire.contacts.services_delivery.name" />
          <PhoneInput label="Phone Number" model="questionnaire.contacts.services_delivery.phone_no" />
        </Flex>
      </FormSection>

      <FormSection
        heading="Accounts Receivable Contact"
        description="Who does a client contact regarding payment for the service you deliver?"
        css={{ gridGap: 0 }}
      >
        <Flex horizontal stretchChildrenX gap="1rem" css={{ marginTop: 0 }}>
          <Input label="Name" model="questionnaire.contacts.accounts_receivable.name" />
          <PhoneInput label="Phone Number" model="questionnaire.contacts.accounts_receivable.phone_no" />
        </Flex>
      </FormSection>
    </FormSection>
  )
}

export const AdditionalContactInformation: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <AdditionalContactInformationFields />
    </Form>
  )
}
