import React from 'react'

import { COLORS } from '../../theme'
import BarChart from '../ChartTypes/BarChart'

export const NewApplicantsSourceChart = (props: any) => {
  const values: any = []
  const sources: any = []

  const setValuesSources = () => {
    props.data.forEach((element) => {
      sources.push(element.x)
      values.push(element.y)
    })
  }

  setValuesSources()

  return (
    <BarChart
      option={{
        xAxis: {
          type: 'value',
        },
        yAxis: {
          type: 'category',
          data: sources,
        },
        series: [
          {
            data: values,
            type: 'bar',
            color: COLORS.purple,
          },
        ],
      }}
      {...props}
    />
  )
}
