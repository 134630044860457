import React from 'react'
import { Picker } from 'emoji-mart'

import { PopoverMenu } from './PopoverMenu'

type Props = {
  onAddEmoji: Function
} & React.HTMLAttributes<any>

const EmojiPicker: React.FC<Props> = ({ children, onAddEmoji, className }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [menuRef, setMenuRef] = React.useState(null)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const handleSelect = (emoji) => {
    onAddEmoji(emoji)
    close()
  }

  React.useEffect(() => {
    if (!menuRef) return

    const handleOutsideClick = (event) => {
      if (menuRef?.contains?.(event.target)) return
      close()
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [menuRef])

  return (
    <PopoverMenu
      trigger={
        <div className={className}>
          <div onClick={open}>{children}</div>
        </div>
      }
    >
      <Picker onSelect={handleSelect} showPreview={false} showSkinTones={false} style={stylesEmojiPicker} />
    </PopoverMenu>
  )
}

const stylesEmojiPicker = {
  width: '100%',
  maxWidth: '24rem',
}

export default EmojiPicker
