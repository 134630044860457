import React from 'react'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import FormSection from '../../../../Forms/FormSection'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const ProtectiveFactors = () => (
  <FormSection layout="vertical" maxWidth={570}>
    <CheckboxGroup label="Internal" layout="vertical-dense" trueIcon="tick">
      <Checkbox label="Ability to cope with stress" model="data.protective_factors.internal.ability_to_cope_with_stress" />
      <Checkbox label="Frustration tolerance" model="data.protective_factors.internal.frustration_tolerance" />
      <Checkbox label="Religious beliefs" model="data.protective_factors.internal.religious_beliefs" />
      <Checkbox label="Fear of death or the actual act of killing self" model="data.protective_factors.internal.fear_of_death" />
      <Checkbox label="Identifies reasons for living" model="data.protective_factors.internal.identifies_reasons_for_living" />
    </CheckboxGroup>

    <CheckboxGroup label="External" layout="vertical-dense" trueIcon="tick">
      <Checkbox
        label="Cultural, spiritual and/or moral attitudes against suicide"
        model="data.protective_factors.external.cultural_spiritual_moral_attitudes_against_suicide"
      />
      <Checkbox label="Responsibility to children" model="data.protective_factors.external.responsibility_to_children" />
      <Checkbox label="Beloved pets" model="data.protective_factors.external.beloved_pets" />
      <Checkbox label="Supportive social network of family or friends" model="data.protective_factors.external.supportive_social_network" />
      <Checkbox label="Positive therapeutic relationships" model="data.protective_factors.external.positive_relationships" />
      <Checkbox label="Engaged in work or school" model="data.protective_factors.external.engaged_in_work_or_school" />
    </CheckboxGroup>

    <SmartTextarea useDictation label="Notes" model="data.protective_factors.notes" />
  </FormSection>
)

export default ProtectiveFactors
