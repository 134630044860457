import React from 'react'
import size from 'lodash/size'

import { QuoteContext } from './context'

import Button from '../../components/Button'
import TreeItem from '../../components/TreeItem'
import Markup from '../../components/Markup'

export const PreviewData = (props: any) => {
  const { model, isEditable } = props

  const context: any = React.useContext(QuoteContext)

  if (!context?.parsePreviewData || size(model) === 0) return null

  const { data: apiData, previewData, parsePreviewData } = context

  const value = isEditable ? previewData?.[`parsed_${model}`] : apiData?.[`parsed_${model}`] || apiData?.[model]

  return (
    <div className="border-solid border-[1px] rounded-[5px] overflow-hidden border-divider bg-hover">
      <TreeItem isOpen={!isEditable} title="Preview">
        <div className="pt-1.5 pb-3">
          <Markup value={value} />

          {isEditable && (
            <Button
              label="Refresh Previews"
              glyph="reset"
              size={100}
              onClick={() => {
                parsePreviewData()
              }}
              display="inline-flex"
              className="!mt-2"
            />
          )}
        </div>
      </TreeItem>
    </div>
  )
}
