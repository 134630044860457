import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'

import { apiUpdate } from '../../../../modules/api'
import { LOC } from '../../../../utils/constants'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import Alert from '../../../Alert'
import Button from '../../../Button'
import Card from '../../../Card'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import DataList from '../../../DataList'
import DateInput from '../../../Forms/DateInput'
import Flex from '../../../Flex'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Option from '../../../Forms/Option'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import Section from '../../../Section'
import Select from '../../../Forms/Select'
import Textarea from '../../../Forms/Textarea'

import RosterDocumentationTable from '../../../Tables/RosterDocumentationTable'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../OverlayBase'

class BillingRosterOverlay extends OverlayBase {
  submit = async () => {
    await apiUpdate({
      name: 'billing_day_reviews',
      url: `billing_day_reviews/${this.props.record.id}`,
      params: { status: 'approved' },
    })
    this.close()
  }

  render = () => {
    const { $new, $editable, isInvalid }: any = this.state
    const { record, timezone, loading }: any = this.props

    if (!$new && size(record) === 0) {
      return <OverlayLoader position={this.props.position} showBackdrop={this.props.showBackdrop} />
    }

    return (
      <Overlay showBackdrop={$editable}>
        <Overlay.Header icon="roster" title="Billing Roster" />

        <Overlay.Content>
          <Section>
            <Alert glyph="info" css={styles.bottomMargin}>
              Behave Health Auto-populates your roster based on the authorized level of care and days. For insurance companies that do not
              require for a level of care Behave Health creates a placeholder to automate per diem roster generation.
            </Alert>

            <Form
              getForm={this.form}
              initialModel={record}
              isEditable={$editable}
              timezone={timezone}
              onValidationUpdate={this.onValidationUpdate}
              linked={{ resident_id: this.props.match?.params?.resource_id }}
            >
              <FormSection>
                <DateInput defaultToNow label="Date" model="started_at" />

                <ObjectSelector
                  label="Level of Care Authorized"
                  type="client.insurance_authorizations"
                  model="insurance_authorization"
                  selectTitle={(data: any) => `#${data.identifier}  (${data?.service_episodes?.map((o: any) => o.name)})`}
                  selectDescription={(data: any) => `Sessions Authorized: ${data.sessions || 0} Used: ${data.used_sessions || 0}`}
                  dependentValue={this.props.match?.params?.resource_id}
                  validations={{
                    presence: {
                      message: 'Please select an Authorization',
                    },
                  }}
                />

                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded">
                  <Checkbox label="Session Used" model="is_session_used" />
                </CheckboxGroup>

                <DataList labelWidth="230px">
                  <DataList.Item
                    label="Sessions Remaining"
                    value={record?.insurance_authorization?.sessions - record?.insurance_authorization?.used_sessions}
                  />
                  <DataList.Item label="Insurance LOC Expectation" value={record?.insurance_authorization?.required_daily_hours} />
                  <DataList.Item label="Program Calendar Hours" value={record?.program_calendar_hours} />
                  <DataList.Item label="Documented Hours" value={record?.documented_hours} />
                  <DataList.Item label="Undocumented Hours" value={record?.undocumented_hours} />
                </DataList>

                <Card>
                  <RosterDocumentationTable data={ROSTER_DOCUMENTATION} />
                </Card>

                <div>
                  <Button label="Add More Documentation" size={200} type="link" glyph="add" display="inline-flex" />
                </div>

                <Select allowEmpty label="UR Sign-Off" model="ur_status">
                  <Option label="Pending Review" value="pending_review" />
                  <Option label="Rejected" value="rejected" />
                  <Option label="Signed Off" value="signed_off" />
                </Select>

                <Select allowEmpty label="Billing Sign-Off" model="billing_status">
                  <Option label="Pending Review" value="pending_review" />
                  <Option label="Rejected" value="rejected" />
                  <Option label="Signed Off" value="signed_off" />
                </Select>

                <Textarea label="Customer Comments" model="customer_comments" />
                <Textarea label="Behave UR Comments" model="ur_comments" />
                <Textarea label="Behave Billing Comments" model="billing_comments" />
              </FormSection>
            </Form>
          </Section>
        </Overlay.Content>

        <Overlay.Footer>
          {$editable && (
            <Flex stretchChildrenX gap={8}>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={loading}
                onClick={this.save}
                isDisabled={isInvalid}
                flex="3 1 auto"
                // permission="rosters.edit"
              />

              {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={loading} onClick={this.cancel} flex="0 1 auto" />}
            </Flex>
          )}

          {!$editable && (
            <Flex stretchChildrenX gap={8}>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={loading}
                onClick={this.edit}
                flex="1 1 auto"
                // permission="rosters.create"
              />

              <Button
                label="Confirm Roster & Submit for Billing"
                glyph="check"
                type="primary"
                color="blue"
                isLoading={loading}
                onClick={this.submit}
                isDisabled={isInvalid}
                flex="1 1 auto"
                // permission="rosters.edit"
              />
            </Flex>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const styles = {
  heading: {
    marginTop: '1.5rem',
    marginBottom: '0.75rem',
  },

  bottomMargin: {
    marginBottom: '1rem',
  },
}

const buildRosterDocumnetation = () => {
  const res: any = []

  for (let i = 0; i < 5; i++) {
    res.push(
      {
        id: 'demo',
        name: 'UA Test',
        type: 'test_results',
        updated_at: '2021-08-23T18:36:17.269-04:00',
        created_at: '2021-08-23T18:36:17.269-04:00',
      },
      {
        id: 'demo',
        name: 'Clinical Note',
        type: 'clinical_notes',
        updated_at: '2021-08-23T18:36:17.269-04:00',
        created_at: '2021-08-23T18:36:17.269-04:00',
      },
      {
        id: 'demo',
        name: 'Vital Signs',
        type: 'test_results',
        updated_at: '2021-08-23T18:36:17.269-04:00',
        created_at: '2021-08-23T18:36:17.269-04:00',
      },
      {
        id: 'demo',
        name: 'Biopsychosocial Assessment',
        type: 'clinical_assessments',
        updated_at: '2021-08-23T18:36:17.269-04:00',
        created_at: '2021-08-23T18:36:17.269-04:00',
      },
      {
        id: 'demo',
        name: 'Pre-Screen',
        type: 'clinical_assessments',
        updated_at: '2021-08-23T18:36:17.269-04:00',
        created_at: '2021-08-23T18:36:17.269-04:00',
      },
      {
        id: 'demo',
        name: 'Progress Review',
        type: 'progress_reviews',
        updated_at: '2021-08-23T18:36:17.269-04:00',
        created_at: '2021-08-23T18:36:17.269-04:00',
      },
    )
  }

  return res
}

const ROSTER_DOCUMENTATION = buildRosterDocumnetation()

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'billing_day_reviews')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(BillingRosterOverlay))
