import { Input } from './Input'
import { withFormContext } from './context'

import { isBlank } from '../../utils/functions'

class PercentageInput extends Input {
  readOnlyRender = () => {
    if (isBlank(this.state.value)) return '0%'
    else return `${this.state.value}%`
  }
}

PercentageInput.defaultProps = {
  type: 'number',
  suffix: '%',
  size: 4,
  min: 0,
  max: 100,
  step: 1,
  validateOn: 'blur-change',
  defaultValidations: {
    greaterThanOrEqualTo: {
      value: 0,
      message: 'Please enter a whole number between 0 and 100',
    },
    lessThanOrEqualTo: {
      value: 100,
      message: 'Please enter a whole number between 0 and 100',
    },
  },
}

export default withFormContext(PercentageInput)
