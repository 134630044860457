import React from 'react'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'

import Button from '../../Button'
import ContextHide from '../../Forms/ContextHide'
import Divider from '../../Divider'
import Flex from '../../Flex'
import FormSection from '../../Forms/FormSection'
import HelpTagIframe from '../../Help/HelpTagIframe'
import Input from '../../Forms/Input'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Status from '../../Status'
import SummonOverlay from '../../SummonOverlay'
import Switch from '../../Forms/Switch'
import Textarea from '../../Forms/Textarea'
import Permission from '../../Permission'

import Diagnoses from '../../Overlays/pages/DataForms/common/Diagnoses'
import GoalsAndObjectivesV2 from '../../Overlays/pages/DataForms/common/GoalsAndObjectivesV2'
import ProblemsV2 from '../../Overlays/pages/DataForms/common/ProblemsV2'
import SNAP from '../../Overlays/pages/DataForms/common/SNAP'

import { DataFormTemplateBase, mapStateToProps, mapDispatchToProps } from '../../Overlays/pages/DataFormTemplates/base'

import WileyPracticePlannerOverlay from '../../Overlays/actions/Wiley/WileyPracticePlannerOverlay'

import withRouterV6 from '../../../hocs/withRouterV6'

class TreatmentPlanTemplateOverlay extends DataFormTemplateBase {
  shouldComponentUpdate = (nextProps, nextState) => {
    if (!isEqual(nextProps.data_form, this.props.data_form)) return true
    if (!isEqual(nextState.wileyData, this.state.wileyData)) return true
    if (nextProps.loading !== this.props.loading) return true
    if (nextProps.idle !== this.props.idle) return true
    if (nextState.$editable !== this.state.$editable) return true
    if (nextState.isValid !== this.state.isValid) return true
    if (nextState.dated_at !== this.state.dated_at) return true

    return false
  }

  setWileyData = (wileyData: any) => {
    this.setState({ wileyData: wileyData })
  }

  renderHeader = () => (
    <Overlay.Header icon="treatment_plans" title="Treatment Plan Template" help={<HelpTagIframe id="treatment_plans" />} />
  )

  renderContent = () => {
    const { $editable, wileyData } = this.state

    return (
      <>
        <Permission featureFlagV2="wiley_treatment_plans">
          {$editable && (
            <>
              <Section
                scrollview={{
                  id: 'wiley_practice_planner',
                  name: 'Wiley Practice Planner',
                }}
                headingType="h2"
                title="Wiley Practice Planner"
                help={<Status contrast label="Beta" color="orange" />}
              >
                <SummonOverlay overlay={<WileyPracticePlannerOverlay onSave={this.setWileyData} />}>
                  <Button type="primary" label="Open Wiley Planner" glyph="add_file" />
                </SummonOverlay>
              </Section>

              <Divider />
            </>
          )}
        </Permission>

        <Section
          scrollview={{
            id: 'appointments',
            name: 'Appointments',
          }}
          headingType="h2"
          title="Appointments"
        >
          <FormSection layout="vertical" maxWidth="100%">
            <Flex gap={8} stretchChildrenX>
              <Input label="Frequency of Appointments" model="data.appointments.frequency" />
              <Input label="Estimated Length of Treatment" model="data.appointments.estimated_length" />
            </Flex>
          </FormSection>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'problems',
            name: 'Problems',
          }}
          headingType="h2"
          title="Problems"
        >
          <ProblemsV2 isEditable={$editable} wileyData={wileyData} model="data.problems" />
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'goals_and_objectives',
            name: 'Goals & Objectives',
          }}
          headingType="h2"
          title="Goals & Objectives"
        >
          <GoalsAndObjectivesV2 isEditable={$editable} wileyData={wileyData} model="data.goals" />
        </Section>

        <Divider />

        <Section
          title="Strengths, Needs, Abilities, Preferences"
          aside={<Switch horizontal inverse model="data.snap.not_applicable" />}
          scrollview={{
            id: 'snap',
            name: 'S.N.A.P.',
          }}
        >
          <ContextHide when="data.snap.not_applicable" is={true}>
            <SNAP />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'diagnoses',
            name: 'Diagnoses',
          }}
          headingType="h2"
          title="Diagnoses"
        >
          <Diagnoses isEditable={$editable} />
        </Section>

        <Divider />

        <Section
          title="Summary"
          aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
          scrollview={{
            id: 'summary',
            name: 'Summary',
          }}
        >
          <ContextHide when="data.summary.not_applicable" is={true}>
            <FormSection layout="vertical" maxWidth="100%">
              <Textarea label="Interpretive Summary" model="data.summary.interpretive_summary" />
              <Textarea label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
              <Textarea useQuickText label="Recommendations" model="data.summary.recommendations" />
            </FormSection>
          </ContextHide>
        </Section>
      </>
    )
  }
}

TreatmentPlanTemplateOverlay.defaultProps = {
  defaultDebounce: 0,
  title: 'Treatment Plan',
  category: 'treatment_plan',
  subcategory: 'treatment_plan',
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanTemplateOverlay)

export const TreatmentPlanTemplateOverlayV6 = withRouterV6(connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanTemplateOverlay))
