import React from 'react'
import { useMedia } from 'use-media'
import produce from 'immer'
import startCase from 'lodash/startCase'

import { useSettings } from '../../hooks/useSettings'
import { useUpdate, getPrefix } from '../../hooks/useNewAPI'
import withSettings from '../../hocs/withSettings'

import ClientColorPicker from '../ColorPickers/ClientColorPicker'
import Flex from '../Flex'
import HelpTagIframe from '../Help/HelpTagIframe'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import AdmissionQuickView from '../Overlays/quick/AdmissionQuickView'
import { ICONS } from '../../theme'

const mainColumn = (to) => ({
  width: 240,
  isSticky: true,
  canToggleVisible: false,
  accessor: 'name',
  Header: 'Name',
  Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} avatar={row.original.avatar} />,
})

const columns = (to: Function = () => {}, isDesktop: boolean, apiKey: any, timezone: any) => [
  ...(isDesktop ? [mainColumn(to)] : []),
  {
    width: isDesktop ? 160 : 240,
    disableFilters: true,
    disableSortBy: true,
    id: 'quick_view',
    accessor: 'id',
    Header: 'Quick View',
    Cell: ({ row }: any) => (
      <TableCell.QuickViewOverlay overlay={<AdmissionQuickView client={row.original} />}>
        {!isDesktop && <TableCell.MainLink label={row.original.name} avatar={row.original.avatar} glyphAfter="quick_view" />}
      </TableCell.QuickViewOverlay>
    ),
  },
  {
    width: 200,
    Header: 'Tags',
    accessor: 'tags',
    disableSortBy: true,
    Cell: ({ value, row }: any) => {
      const { mutateAsync, isLoading }: any = useUpdate({
        name: ['client', row.original.id],
        url: `/residents/${row.original.id}`,
        invalidate: ['clients', { status: 'declined' }],
        invalidateKeys: ['tag-live-reports'],
        onSuccess: (_data: any, variables: any, queryClient: any) => {
          const prefix = getPrefix()

          // update clients list
          queryClient.setQueryData([prefix, apiKey].flat(), (oldData: any) => {
            if (!oldData?.data) return

            const index = oldData.data.findIndex((o) => o.id === row.original.id)
            if (index === -1) return

            const newData = produce(oldData, (draft: any) => {
              draft.data[index].tags = variables.tags
            })

            return newData
          })
        },
      })

      return (
        <TableCell.Tags
          value={value}
          save={(tag_ids: any, tags: any) => mutateAsync({ tag_ids, tags })}
          isLoading={isLoading}
          smartCategories="declined"
        />
      )
    },
  },
  {
    width: 140,
    accessor: 'color_code',
    Header: 'Color',
    Cell: ({ row, value }: any) => (
      <Flex centerX>
        <ClientColorPicker name="leads" clientID={row.original?.id} color={value} css={{ margin: '0 auto' }} />
      </Flex>
    ),
  },
  {
    width: 200,
    accessor: 'current_intake_application.created_at',
    Header: 'Last Application Date',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 140,
    accessor: 'behave_id',
    Header: 'Client ID',
    Cell: ({ value }: any) => <TableCell value={value && `#${value}`} />,
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 240,
    accessor: 'email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
  {
    width: 100,
    accessor: 'sex',
    Header: 'Sex',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'male',
        label: 'Male',
      },
      {
        value: 'female',
        label: 'Female',
      },
      {
        value: 'decline_to_respond',
        label: 'Decline To Respond',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  {
    width: 220,
    accessor: 'referral_source',
    Header: 'Referral In',
    Cell: ({ value }: any) => <TableCell.IconValue icon={ICONS.properties} value={value?.name} />,
  },
  {
    width: 220,
    accessor: 'referral_notes',
    Header: 'Referral In Notes',
    Cell: ({ value }: any) => <TableCell value={value} />,
  },
  {
    width: 220,
    accessor: 'referral_out',
    Header: 'Referral Out',
    Cell: ({ value }: any) => <TableCell.IconValue icon={ICONS.properties} value={value?.name} />,
  },
  {
    width: 220,
    accessor: 'referral_out_notes',
    Header: 'Referral Out Notes',
    Cell: ({ value }: any) => <TableCell value={value} />,
  },
  {
    width: 140,
    accessor: 'last_name',
    Header: 'Last Name',
    Cell: ({ value }: any) => <TableCell value={value} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  timezone: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const DeclinedClientsTable = (props: Props) => {
  const { apiKey, to, data, isLoading, emptyActions, hiddenColumns, localStorageKey, batchActionsConfig, titleAfter, ...rest } = props

  const { timezone } = useSettings()
  const isDesktop = useMedia({ minWidth: 600 })

  return (
    <Table
      title="Declined Applicants"
      titleAfter={titleAfter}
      icon="intake_forms"
      data={data}
      columns={columns(to, isDesktop, apiKey, timezone)}
      hiddenColumns={hiddenColumns}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No declined added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      help={<HelpTagIframe id="admissions_declined" />}
      {...rest}
    />
  )
}

DeclinedClientsTable.defaultProps = {
  localStorageKey: 'declined_clients',
}

export default withSettings(DeclinedClientsTable)
