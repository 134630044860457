import React from 'react'
import startCase from 'lodash/startCase'

import { useSettings } from '../../hooks'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 180,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'contact.name',
    Header: 'Contact Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} />,
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Contacted By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original?.author?.avatar} name={row.original.author?.name} />,
  },
  {
    width: 140,
    accessor: 'category',
    Header: 'Contact Method',
    Cell: ({ value }: any) => {
      return <TableCell value={startCase(value)} />
    },
  },
  {
    width: 220,
    accessor: 'reason',
    Header: 'Notes',
  },
  {
    width: 200,
    accessor: 'contacted_at',
    Header: 'Contacted At',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  isLoading: boolean
  localStorageKey: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const CommunicationsTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, localStorageKey, batchActionsConfig, titleAfter } = props
  const { timezone } = useSettings()

  return (
    <Table
      title="Communication"
      testKey="communications_table"
      titleAfter={titleAfter}
      icon="communications"
      data={data}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No communication logs added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

CommunicationsTable.defaultProps = {
  localStorageKey: 'communications',
}

export default CommunicationsTable
