import React from 'react'
import { useSelector } from 'react-redux'
import size from 'lodash/size'
import { useRouteMatch } from 'react-router-dom'

import { COLORS } from '@behavehealth/theme'
import { Header, Button, Flex, SummonOverlay } from '@behavehealth/components'

import ChannelContent from '@behavehealth/components/Chat/ChannelContent'
import CompanyChannelOverlay from '@behavehealth/components/Chat/overlays/CompanyChannelOverlay'

const Channel: React.FC = () => {
  const match = useRouteMatch()
  const loading = useSelector((state: any) => state.data?.channels?.loading)
  const channels = useSelector((state: any) => state.data?.channels?.data?.[match.params?.category])
  const timezone = useSelector((state: any) => state.me?.tenant?.timezone)

  if (!channels) return null

  let channel = null
  // find the channel data
  for (let i = 0; i < channels.length; i++) {
    if (channels[i].id === match.params?.id) {
      channel = channels[i]
      break
    }
  }

  if (!channel) return null

  return (
    <div css={styles}>
      <Header isSticky avatar="" title={`${channel?.name} Channel`} isLoading={loading} />

      <ChannelContent
        icon="company_chat"
        channel={channel}
        timezone={timezone}
        permissions={{
          hide: 'company_chat.edit',
        }}
        header={
          <Flex justifyContent="flex-end">
            <SummonOverlay overlay={<CompanyChannelOverlay channel={channel} />}>
              <Button label={`${size(channel.members)} Members`} size={200} type="default" glyph="menu" />
            </SummonOverlay>
          </Flex>
        }
      />
    </div>
  )
}

const styles = {
  display: 'grid',
  gridTemplateRows: 'min-content 1fr',
  gridTemplateColumns: '1fr',
  overflow: 'hidden',
  background: COLORS.lightBackground,
}

export default Channel
