import React from 'react'

import { usTime } from '../../../utils/functions'
import Empty from './Empty'

const UsTime: React.FC = ({ value, timezone }) => {
  if (!value) return <Empty />
  return usTime(value, timezone)
}

export default UsTime
