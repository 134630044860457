import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  synced: {
    label: 'Synced with ERX',
    color: 'green',
  },
  not_synced: {
    label: 'ERX Not Synced',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus glyph="pill_bottle" glyphColor="black" statuses={statuses} {...rest} />
}

export default Status
