import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

import { CustomNoteTemplatesDataTable } from './CustomNoteTemplatesDataTable'
import { useCreate } from '../../hooks/useNewAPI'
import { useDataTable } from '../../components/DataTable/useDataTable'

export const ClientCustomNotesImportOverlay = ({ onClose, referenceId, referenceType }) => {
  const [selectedRows, setSelectedRows]: any = React.useState([])

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const tableProps = useDataTable({
    name: ['custom_note_templates'],
    endpoint: `/custom_note_templates`,
    localStorageKey: 'custom_note_templates_v1',
    params: { variant: 'client' },
  })

  const { mutateAsync, isLoading: isCreating } = useCreate({
    name: ['custom_notes_import'],
    url: `/custom_notes/import`,
    invalidate: 'custom_notes',
  })

  const importRecords = async () => {
    if (!referenceId || !referenceType) return

    const custom_note_templates: any = {}

    for (const row of selectedRows) {
      custom_note_templates[row.id] = true
    }

    try {
      await mutateAsync({
        custom_note_templates,
        reference_id: referenceId,
        reference_type: referenceType,
      })

      onClose?.()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={100} onClose={onClose}>
      <Overlay.Header icon="custom_notes" title="Import Multiple Custom Notes" />

      <Overlay.Content>
        <Section margin="0 0 1.5rem 0">
          <Card className="!mt-4">
            <CustomNoteTemplatesDataTable canBatchSelect title="Custom Notes" {...tableProps} onRowSelectionUpdate={setSelectedRows} />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Custom Notes', selectedRowsCount)}` : 'Select Custom Notes to Import'}
          isDisabled={!hasSelectedRows}
          isLoading={isCreating}
          type="primary"
          color="green"
          onClick={importRecords}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
