import React from 'react'
import size from 'lodash/size'

import { niceAmount } from '../../../../utils/functions'
import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

const UNITS = {
  per_day: 'Per Day',
  per_unit: 'Per Unit',
}

const CODE_TYPES = {
  cpt: 'CPT',
  hcpc_mod: 'HCPC/MOD',
  hcpcs: 'HCPCS',
}

export const InsuranceNewFeeScheduleServicesTable = ({ onSelect, dependentValue, tenant, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['insurance_new_fee_schedules', dependentValue, 'insurance_new_fee_schedule_services'],
    endpoint: `/insurance_new_fee_schedules/${dependentValue}/insurance_new_fee_schedule_services`,
    localStorageKey: 'selector_insurance_new_fee_schedule_services_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Insurance Code',
        model: 'insurance_new_code.service_name',
        width: 200,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data?.insurance_new_code?.service_name}
            onClick={onSelect ? () => onSelect(data) : undefined}
          />
        ),
      },
      {
        title: 'Description',
        model: 'insurance_new_code.description',
        width: 160,
      },
      {
        title: 'Procedure Code',
        model: 'insurance_new_code.procedure_code',
        width: 150,
      },
      {
        title: 'Revenue Code',
        model: 'insurance_new_code.revenue_code',
        width: 150,
      },
      {
        title: 'Unit',
        model: 'insurance_new_code.unit',
        width: 90,
        formatValue: ({ value }: any) => UNITS?.[value] || value,
      },
      {
        title: 'Modifier Code',
        model: 'insurance_new_code.modifier_codes',
        width: 150,
        formatValue: ({ data }: any) => {
          if (size(data?.insurance_new_code?.modifier_codes) === 0) return null

          return data?.insurance_new_code?.modifier_codes?.map((o: any) => o.code)?.join(', ')
        },
      },
      {
        title: 'Code Type',
        model: 'insurance_new_code.code_type',
        width: 100,
        formatValue: ({ data }: any) => {
          return CODE_TYPES?.[data?.insurance_new_code?.code_type] || null
        },
      },
      {
        title: 'UCR',
        model: 'price',
        width: 100,
        formatValue: ({ data }: any) => {
          return niceAmount(data.price)
        },
      },
      {
        title: 'Contract Rate',
        model: 'contractable_rate',
        width: 120,
        formatValue: ({ data }: any) => {
          return niceAmount(data.contractable_rate)
        },
      },
    ],
    [],
  )

  return <DataTable {...tableProps} {...rest} icon="insurance" title="Fee Schedule Insurance Codes" columns={columns} />
}
