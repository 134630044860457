import React from 'react'

import CheckboxGroup from '../../../../components/Forms/CheckboxGroup'
import Checkbox from '../../../../components/Forms/Checkbox'

export const NotificationsEditor = (props: any) => {
  const { editActiveElementFromInput: onUpdate } = props

  return (
    <CheckboxGroup label="Send Notification to Inputted Value">
      <Checkbox label="Email Receipt on Submission" model="send_notification" defaultValue={false} onUpdate={onUpdate} />
    </CheckboxGroup>
  )
}
