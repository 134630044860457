import React from 'react'
import { DateTime } from 'luxon'
import isFinite from 'lodash/isFinite'

import { withFormContext } from '../../components/Forms/context'
import { useSettings } from '../../hooks/useSettings'

import { hoursBetweenRelative } from '../../utils/functions'

import Alert from '../../components/Alert'

const RootFormFutureDateWarning = (props) => {
  const { model, value, form, dateLabel } = props

  const { timezone } = useSettings()

  let date = null

  if (model && form) {
    date = form?.getFieldValue?.(model)
  } else {
    date = value
  }

  if (!date) return null

  const now = DateTime.now().setZone(timezone).toISO()
  const hours = hoursBetweenRelative(now, date, timezone)

  if (!isFinite(hours) || hours < 23) return null

  return (
    <Alert small contrast glyph="warning" type="warning">
      <span className="font-[600]">Note:</span> {dateLabel || 'this date'} appears to be in the future, please ensure it is correct.
    </Alert>
  )
}

export const FormFutureDateWarning = withFormContext(RootFormFutureDateWarning)
