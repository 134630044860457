import React from 'react'
import { produce } from 'immer'
import size from 'lodash/size'

import { apiGet } from '../../modules/api'
import { useCreate } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Form from '../../components/Forms/Form'
import NumberInput from '../../components/Forms/NumberInput'
import Overlay from '../../components/Overlay'
import State from '../../components/State'
import SummonOverlay from '../../components/SummonOverlay'

import { QUOTE_CELLS } from './constants'
import { QuoteEstimatorAmounts } from './QuoteEstimatorAmounts'

export const QuoteEstimatorSummonOverlay = (props: any) => {
  const { quote, quoteOfferId, isPublic } = props

  const [salesTax, setSalesTax] = React.useState(null)
  const [salesTaxType, setSalesTaxType] = React.useState(null)
  const [quoteLines, setQuoteLines] = React.useState(props.quoteLines || [])

  const form = React.useRef(null)

  const [isLoading, setIsLoading] = React.useState(true)
  const [isOpen, setIsOpen] = React.useState(false)

  const {
    mutateAsync,
    data: amounts,
    isLoading: isLoadingAmounts,
  } = useCreate({
    name: ['estimate-amounts'],
    url: isPublic ? '/apps/quotes/quote_offers/estimate' : '/quote_offers/estimate',
  })

  const handleCalculate = async () => {
    setIsLoading(true)

    try {
      await mutateAsync({
        quote_lines: quoteLines,
        sales_tax: salesTax,
        sales_tax_type: salesTaxType,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOpen = async () => {
    setIsOpen(true)
    setIsLoading(true)

    const res = await apiGet({
      url: isPublic ? `/apps/quotes/${quote?.external_id}/quote_offers/${quoteOfferId}` : `/quote_offers/${quoteOfferId}`,
    })

    const offerLines = res?.data?.data?.quote_lines
    const offerSalesTax = res?.data?.data?.sales_tax
    const offerSalesTaxType = res?.data?.data?.sales_tax_type

    if (!offerLines) return

    setQuoteLines(offerLines)
    setSalesTax(offerSalesTax)
    setSalesTaxType(offerSalesTaxType)

    await mutateAsync({
      quote_lines: offerLines,
      sales_tax: offerSalesTax,
      sales_tax_type: offerSalesTaxType,
    })

    setIsLoading(false)
  }

  const columns = React.useMemo(
    () => [
      {
        title: 'Product',
        width: 'minmax(360px, 1fr)',
        render: (line: any) => {
          return QUOTE_CELLS.product({
            name: line?.product?.public_name,
            icon: line?.product?.icon,
          })
        },
      },
      {
        title: 'List Price',
        width: '300px',
        render: (line: any) => {
          return QUOTE_CELLS.amount(line?.product)
        },
      },
      {
        title: 'Estimated Units',
        width: '160px',
        render: (line: any) => {
          const isMetered = line?.product?.pricing_type === 'metered_annually' || line?.product?.pricing_type === 'metered_monthly'

          if (!line || !isMetered) return <span className="text-text-muted">N/A</span>

          return (
            <NumberInput
              withHover={false}
              defaultValue={1}
              size={4}
              value={line.quantity || 1}
              onUpdate={({ value }) => {
                setQuoteLines((prev: any) => {
                  return produce(prev, (draft: any) => {
                    const index = line?.id ? draft.findIndex((o) => o.id === line.id) : draft.findIndex((o) => o._id === line?._id)

                    if (index === -1) return

                    draft[index].quantity = value
                  })
                })
              }}
            />
          )
        },
      },
      {
        title: 'Billed',
        width: '150px',
        render: (line: any) => {
          return QUOTE_CELLS.billingType(line?.product)
        },
      },
      {
        title: 'Discount',
        width: '150px',
        render: (line: any) => {
          return QUOTE_CELLS.discount(line?.discount)
        },
      },
    ],
    [],
  )

  const filteredQuoteLines = React.useMemo(() => {
    const result: any = []

    for (const line of quoteLines) {
      if (line?.product?.is_required || line?.is_selected) {
        result.push(line)
      }
    }

    return result
  }, [quoteLines])

  const isEmpty = size(filteredQuoteLines) === 0

  const gridTemplateColumns = React.useMemo(() => columns.map((column) => column.width).join(' '), [columns])

  return (
    <>
      <Button label="Estimate Calculator" glyph="calculator" color="blue" type="default" size={200} onClick={handleOpen} />

      <SummonOverlay
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        overlay={
          <Overlay showBackdrop closeOnBackdrop position="center" maxWidth={100}>
            <Overlay.Header title="Estimate Calculator" glyph="calculator" />

            <Overlay.Content className="px-6 py-5 text-[0.95rem]">
              <Card className="p-4 !overflow-auto">
                {isLoading || isEmpty || !quoteLines ? (
                  <State
                    isLoading={isLoading}
                    isEmpty={isEmpty || !quoteLines}
                    icon="quotes"
                    title="Quote Offer"
                    emptyDescription="No products subscribed to or required for this quote offer"
                  />
                ) : (
                  <Form isCompact getForm={form} initialModel={amounts} className="min-w-fit">
                    <div
                      className="grid text-[0.84rem] tracking-[1px] uppercase text-text-muted font-[700] opacity-[0.85] pb-1.5"
                      style={{ gridTemplateColumns }}
                    >
                      {columns.map((column) => (
                        <div key={column.title}>{column.title}</div>
                      ))}
                    </div>

                    {quoteLines.map((line: any, index: number) => {
                      const { product, discount } = line

                      if (!product) return null

                      const isSelected = line?.is_selected
                      const isRequired = line?.product?.is_required

                      if (!isSelected && !isRequired) return null

                      return (
                        <div
                          key={line.id}
                          className="grid py-2 border-b !last:border-none border-solid border-0 border-divider last:border-none"
                          style={{ gridTemplateColumns }}
                        >
                          {columns.map((column) => (
                            <div key={column.title} className="flex items-center">
                              {column?.render?.(line) || null}
                            </div>
                          ))}
                        </div>
                      )
                    })}
                  </Form>
                )}
              </Card>

              {!isLoading && !isLoadingAmounts && amounts && (
                <div className="mt-4">
                  <QuoteEstimatorAmounts amounts={amounts} />
                </div>
              )}
            </Overlay.Content>

            {!isEmpty && !isLoading && !isLoadingAmounts && amounts && size(quoteLines) > 0 && (
              <Overlay.Footer>
                <Button
                  label="Re-Calculate"
                  glyph="reset"
                  color="blue"
                  type="default"
                  flex="100 1 auto"
                  size={200}
                  onClick={handleCalculate}
                  isLoading={isLoadingAmounts}
                />
              </Overlay.Footer>
            )}
          </Overlay>
        }
      />
    </>
  )
}
