import React from 'react'

import { PieChart } from '@behavehealth/components'

const data = [
  { value: 1048, name: 'A' },
  { value: 735, name: 'B' },
  { value: 580, name: 'C' },
  { value: 484, name: 'D' },
  { value: 300, name: 'E' },
]

const PieChartExample = (props: any) => {
  return <PieChart data={data} {...props} />
}

export default PieChartExample
