import React from 'react'

import appStoreImg from '../assets/img/app_store_badge.png'
import playStoreImg from '../assets/img/play_store_badge.png'

type Props = {
  app: 'client' | 'staff'
  store: 'apple' | 'google'
}

const LINKS = {
  apple: {
    staff:
      'https://apps.apple.com/us/developer/behave/id1531441728?utm_campaign=Apple%20App%20Store&utm_source=behavehealth.com&utm_medium=website&utm_term=apple%20store&utm_content=button',
    client:
      'https://apps.apple.com/us/developer/behave/id1531441728?utm_campaign=Apple%20App%20Store&utm_source=behavehealth.com&utm_medium=website&utm_term=apple%20store&utm_content=button',
  },

  google: {
    staff:
      'https://play.google.com/store/apps/developer?id=Behave%20Health&utm_campaign=Google%20Play%20Store&utm_source=behavehealth.com&utm_medium=website&utm_term=google%20store&utm_content=button',
    client:
      'https://play.google.com/store/apps/developer?id=Behave%20Health&utm_campaign=Google%20Play%20Store&utm_source=behavehealth.com&utm_medium=website&utm_term=google%20store&utm_content=button',
  },
}

const MobileAppBadge = ({ app, store }: Props) => {
  if (!(store && app)) return null
  if (!LINKS[store]) return null
  if (!LINKS[store][app]) return null

  return (
    <a href={LINKS[store][app]} target="_blank">
      <img
        src={store === 'apple' ? appStoreImg : playStoreImg}
        alt="Download Behave Health Staff App from the Apple App Store"
        css={styles}
      />
    </a>
  )
}

const styles = {
  width: 'auto',
  height: 46,
  display: 'block',
}

export default MobileAppBadge
