import React from 'react'

import FormSection from '../../../components/Forms/FormSection'
import Input from '../../../components/Forms/Input'

import { COLORS } from '../../../theme'

export const GoogleCalendarIntegrationSettings = () => {
  return (
    <>
      <Input isEditable={false} label="SID" model="config.sid" />
      <Input isEditable={false} label="Provider" model="config.provider" />
      <Input isEditable={false} label="Email Address" model="config.email_address" />
      <Input isEditable={false} label="Access Token" model="config.access_token" />
    </>
  )
}

const styles = {
  title: {
    fontWeight: 600,
  },
  description: {
    color: COLORS.textMuted,
  },
}
