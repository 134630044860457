import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { apiDelete } from '@behavehealth/modules/api'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useServerFunctionality } from '@behavehealth/hooks/useServerFunctionality'

import { Chotomate, DnDUploader, Card, CardContent, Permission } from '@behavehealth/components'
import { FilesTable } from '@behavehealth/components/Tables'
import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'
import Grid from '@behavehealth/components/Grid'

import { Tenant } from '@behavehealth/declarations'

const General: React.FC = () => {
  const match = useRouteMatch()
  const tenant: Tenant = useSelector((state: any) => state.me.tenant)

  const { server, sets, tableProps } = useServerFunctionality()

  const queryKey = ['facilities', tenant.id, 'files', { category: 'general', ...server.keys }]

  const { data, isLoading, meta, isFetching }: any = useGet({
    name: queryKey,
    url: `/facilities/${tenant.id}/documents`,
    params: {
      category: 'general',
      ...server.params,
    },
    options: server.options,
  })

  const isEmpty = size(data) === 0

  return (
    <Grid gap="1rem">
      <Filters config={FILTERS.files} onUpdate={sets.filters} localStorageKey="files" />

      <Card>
        <Chotomate name="files_general" ready={!isLoading} />

        <FilesTable
          // {...tableProps(meta)}
          testKey="general_files_table"
          title="General Files"
          data={data}
          isLoading={isEmpty && isLoading}
          isFetching={!isLoading && isFetching}
          localStorageKey="files"
          to={(id: string) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'companies.files.delete',
              action: async ({ ids }: any) => {
                await apiDelete({ name: 'documents', url: `/documents/${ids.join(',')}` })
              },
            },
          ]}
        />
        <CardContent>
          <Permission permission="companies.files.create">
            <DnDUploader isEditable category="general" resource={tenant} isDisabled={false} queryKey={queryKey} />
          </Permission>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default General
