import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import ColorInput from '../../components/Forms/ColorInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import GlyphSelector from '../../components/Forms/GlyphSelector'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'

export const COLOR_PALETTE: any = [
  '#7f8c8d',
  '#222222',
  '#795548',
  '#274879',
  '#8bc34a',
  '#4caf50',
  '#009688',
  '#00bcd4',
  '#03a9f4',
  '#2196f3',
  '#3f51b5',
  '#673ab7',
  '#9c27b0',
  '#e91e63',
  '#f44336',
  '#ffc107',
  '#ff9800',
  '#ff5722',
]

const DEFAULT_MODEL = {
  color: COLOR_PALETTE[0],
  glyph: 'circle',
}

const RootTagOverlay = (props) => {
  const {
    cancel,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    params,
    close,
  } = useOverlay({
    name: 'tags',
    endpoint: '/tags',
    invalidate: 'tag-groups',
    options: props,
  })

  const { tag_group_id } = params

  if (isOverlayLoading) return <OverlayLoader position="right" />

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="tags" title="Tag" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={isNew ? DEFAULT_MODEL : initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && { tag_group_id }}
        >
          {({ data }) => (
            <Section>
              <FormSection>
                <ColorInput label="Color" model="color" colors={COLOR_PALETTE} />

                <Flex gap="0.5rem">
                  <Flex.Item flex="0 0 4.5rem">
                    <GlyphSelector label="Icon" model="glyph" color={data.color} />
                  </Flex.Item>

                  <Flex.Item flex="1 1 auto">
                    <Input
                      label="Name"
                      model="name"
                      validations={{
                        presence: {
                          message: 'Please enter a tag name',
                        },
                      }}
                    />
                  </Flex.Item>
                </Flex>

                <Textarea useQuickText label="Description" model="description" />
              </FormSection>
            </Section>
          )}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save Tag"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Tag"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="tags.edit"
            />

            <DeleteDialog
              title="Delete Tag?"
              message="Are you sure you want to delete this tag? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button fullWidth glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} permission="tags.delete" />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const TagOverlay = withOverlayError(RootTagOverlay)
