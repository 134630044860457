import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withSettings from '@behavehealth/hocs/withSettings'

const KitchenSink = () => {
  const form = React.useRef()
  const [editable, setEditable] = React.useState(false)

  return <div className="p-4"></div>
}

export default withPageError(withSettings(KitchenSink))
