import React from 'react'

import Card from '../../components/Card'
import Flex from '../../components/Flex'

import { QuoteLinesAmounts } from './QuoteLinesAmounts'

export const QuoteEstimatorAmounts = ({ amounts = {} }: any) => (
  <>
    <Flex gap="1rem">
      {amounts?.upfront?.total > 0 && (
        <Card className="p-4 flex-[1_1_320px]">
          <h3 className="mb-4 text-right pr-[40px] mq1200:pr-0 mq1200:text-center text-[1.2rem] tracking-[1px] uppercase font-[700]">
            To Pay on Subscribe
          </h3>
          <div className="flex justify-end mq1200:justify-center">
            <QuoteLinesAmounts amounts={amounts.upfront} />
          </div>
        </Card>
      )}

      {amounts?.monthly?.total > 0 && (
        <Card className="p-4 flex-[1_1_320px]">
          <h3 className="mb-4 text-right pr-[40px] mq1200:pr-0 mq1200:text-center text-[1.2rem] tracking-[1px] uppercase font-[700]">
            Monthly You Would Pay
          </h3>
          <div className="flex justify-end mq1200:justify-center">
            <QuoteLinesAmounts amounts={amounts.monthly} />
          </div>
        </Card>
      )}

      {amounts?.annually?.total > 0 && (
        <Card className="p-4 flex-[1_1_320px]">
          <h3 className="mb-4 text-right pr-[40px] mq1200:pr-0 mq1200:text-center text-[1.2rem] tracking-[1px] uppercase font-[700]">
            Yearly You Would Pay
          </h3>
          <div className="flex justify-end mq1200:justify-center">
            <QuoteLinesAmounts amounts={amounts.annually} />
          </div>
        </Card>
      )}
    </Flex>
  </>
)
