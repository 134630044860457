import React from 'react'
import { keyframes } from '@emotion/react'
import { transparentize } from 'polished'
import clsx from 'clsx'

import { COLORS } from '../theme'

type Props = {
  color: string
  size: number
  textColor: string
  className?: string
  isAnimated?: boolean
}

const Badge: React.FC<Props> = ({ children, isAnimated, className, color = COLORS.blue, textColor = COLORS.white, size = 20 }) => {
  const classNames = clsx({
    'is-animated': isAnimated,
    [className]: className,
  })

  return (
    <div css={styles({ color, textColor, size })} className={classNames}>
      {children}
    </div>
  )
}

const pulseAnimation = (color: string) => {
  return keyframes({
    '0%': {
      transform: 'scale(0.9)',
      boxShadow: `0 0 0 0 ${transparentize(0.3, color)}`,
    },

    '70%': {
      transform: 'scale(1)',
      boxShadow: `0 0 0 7px ${transparentize(1, color)}`,
    },

    '100%': {
      transform: 'scale(0.9)',
      boxShadow: `0 0 0 0 ${transparentize(1, color)}`,
    },
  })
}

const styles = ({ color, textColor, size }: Props): any => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',

  width: size,
  height: size,

  color: textColor,
  fontSize: 11,
  lineHeight: 0,
  fontWeight: 500,

  background: color,
  borderRadius: '50%',
  textShadow: 'none',

  '&.is-animated': {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      borderRadius: '50%',
      animation: `${pulseAnimation(color)} 2s infinite`,
    },
  },
})

Badge.defaultProps = {
  size: 20,
  color: COLORS.blue,
  textColor: COLORS.white,
}

export default Badge
