import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { OUTCOME_MEASURES_SLUGS } from '@behavehealth/utils/constants'

import { DemographicInfoFormReportDataTable } from '@behavehealth/constructs/LiveReports/DemographicInfoFormReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const DemographicInfoForm = () => {
  const tableProps = useDataTable({
    name: ['reports', 'demographic_information_form'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_demographic_information_form_v1',
    params: { category: 'demographic_information_form' },
  })

  const to = React.useMemo(
    () => (rowData: any) =>
      `${getResourceLink(rowData.client)}/outcome-measures/${OUTCOME_MEASURES_SLUGS?.[rowData?.subcategory]}/${rowData.id}`,
    [],
  )

  return (
    <Page feature="outcome_measures" title="Live Demographic Info Report">
      <Grid>
        <DemographicInfoFormReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(DemographicInfoForm)
