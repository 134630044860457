import React from 'react'
import { produce } from 'immer'
import { v4 as uuid } from 'uuid'
import size from 'lodash/size'

import Radio from '../../../../components/Forms/Radio'
import RadioGroup from '../../../../components/Forms/RadioGroup'
import Label from '../../../../components/Label'
import Flex from '../../../../components/Flex'
import Checkbox from '../../../../components/Forms/Checkbox'
import Input from '../../../../components/Forms/Input'
import Select from '../../../../components/Forms/Select'
import Option from '../../../../components/Forms/Option'
import CheckboxGroup from '../../../../components/Forms/CheckboxGroup'

import Json from '../../../../components/Json'

import { Worksheet } from '../../../../components/Worksheet/Worksheet'

export const GridTableEditor = (props: any) => {
  const { activeElement, editElementConfig, editActiveElementFromInput: onUpdate } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        model: 'title',
        width: 120,
      },
      {
        title: 'Type',
        model: 'type',
        type: 'select',
        width: 140,
        config: {
          options: [
            { label: 'Rich Text', value: 'rich_text' },
            { label: 'Glyph', value: 'glyph' },
            { label: 'Icon', value: 'icon' },
          ],
          onUpdate: ({ value, rowId: columnId }: any) => {
            const column = activeElement.config.grid_table_columns?.find((column: any) => column._id === columnId)
            const prevType = column?.type

            if (prevType === value) return

            const updatedRows = produce(activeElement.config.grid_table_rows, (draft: any) => {
              for (const rowId in draft) {
                const row = draft[rowId]

                row[columnId] = ''
              }
            })

            editElementConfig({
              uuid: activeElement.uuid,
              config: {
                grid_table_rows: updatedRows,
              },
            })
          },
        },
        validations: {
          presence: {
            message: 'Please select a column type',
          },
        },
      },
      {
        title: 'Width Type',
        model: 'width_type',
        type: 'select',
        width: 140,
        config: {
          options: [
            { label: 'Fixed', value: 'fixed' },
            { label: 'Flexible', value: 'flexible' },
          ],
        },
        validations: {
          presence: {
            message: 'Please select a width type',
          },
        },
      },
      {
        title: 'Width (px)',
        model: 'width',
        type: 'number',
        width: 140,
      },
      {
        title: 'Sorting',
        model: 'can_sort',
        type: 'select',
        width: 140,
        config: {
          options: [
            { label: 'Enabled', value: true },
            { label: 'Disabled', value: false },
          ],
        },
      },
    ]
  }, [activeElement])

  if (!activeElement?.config) return null

  const {
    grid_table_columns = [],
    max_width = 100,
    max_width_type = 'percentage',
    updated_at,
    default_sort_column = null,
    default_sort_order = 'asc',
  } = activeElement.config

  return (
    <>
      <Checkbox label="Enable Row Banding" model="row_banding" onUpdate={onUpdate} />

      <Flex nowrap gap="0.75rem">
        <Input label="Width" type="number" model="max_width" size={4} value={max_width || 100} onUpdate={onUpdate} />
        <Select label="Type" model="max_width_type" value={max_width_type} onUpdate={onUpdate} flex="1 1 auto">
          <Option label="Percent" value="percentage" />
          <Option label="Pixels" value="pixels" />
        </Select>
      </Flex>

      <Checkbox
        label="Center Table"
        model="should_center_x"
        onUpdate={onUpdate}
        tooltip={
          <>
            <b>Please note: </b> this setting is only applicable when the table width is less than the page width.
          </>
        }
      />

      <div>
        <Label isCompact label="Columns" className="!mb-1" />
        <Worksheet
          key={`updated-${updated_at}`}
          asCard
          withFullScreenToggle
          withWrapToggle={false}
          withShowInvalidToggle={false}
          wrapping="clip"
          title="Columns"
          allow="create-update-delete"
          titleClassName="!mr-auto"
          columns={columns}
          defaultNewRow={DEFAULT_NEW_ROW}
          initialData={grid_table_columns}
          onDataChange={(dataMap: any, dataIds: any) => {
            const newColumns: any = []

            if (!dataMap || !dataIds) return newColumns

            for (let i = 0; i < dataIds.length; i++) {
              const _id = dataIds[i]
              const column = dataMap[_id]

              newColumns.push(column)
            }

            editElementConfig({
              uuid: activeElement.uuid,
              config: {
                grid_table_columns: newColumns,
              },
            })
          }}
        />
      </div>

      <Flex nowrap gap="0.75rem">
        <Select label="Default Sort Column" model="default_sort_column" value={default_sort_column} onUpdate={onUpdate} flex="1 1 auto">
          <Option label="None" value={null} />

          {grid_table_columns.map((column) => (
            <Option key={column._id} label={column.title} value={column._id} />
          ))}
        </Select>

        <Select
          label="Sort Order"
          model="default_sort_order"
          value={default_sort_order}
          onUpdate={onUpdate}
          flex="1 1 auto"
          defaultValue="asc"
        >
          <Option label="Ascending" value="asc" />
          <Option label="Descending" value="desc" />
        </Select>
      </Flex>
    </>
  )
}

const DEFAULT_NEW_ROW = (dataMap: any) => {
  const count = size(dataMap) + 1

  const id = uuid()

  return {
    _id: id,
    title: `Column ${count}`,
    type: 'rich_text',
    width_type: 'flexible',
    width: 250,
    can_sort: true,
  }
}
