import React from 'react'
import { persist } from 'zustand/middleware'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { useMedia } from 'use-media'
import clsx from 'clsx'
import create from 'zustand'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Card, Calendar, CalendarEvent, HelpTagIframe, Page, Grid, SummonOverlay, Button, Flex } from '@behavehealth/components'
import { NavGroupLabel } from '@behavehealth/components/NavGroup'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'

import Avatar from '@behavehealth/components/Avatar'
import Badge from '@behavehealth/components/Badge'
import EventBuilderOverlay from '@behavehealth/components/Overlays/pages/Calendar/EventBuilderOverlay'
import EventOverlay from '@behavehealth/components/Overlays/pages/Calendar/EventOverlay'
import RadioCheckElement from '@behavehealth/components/Forms/RadioCheckElement'
import State from '@behavehealth/components/State'
import Tabs from '@behavehealth/components/Tabs'

import { CalendarDataTable } from '@behavehealth/constructs/Calendar/CalendarDataTable'
import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'
import { EventsBatchEditOverlay } from '@behavehealth/constructs/Calendar/EventsBatchEditOverlay'
import { DropdownMenu } from '@behavehealth/components/DropdownMenu'
import { COLORS } from '@behavehealth/theme'

import { CalendarPageActionsV6 } from '../../components/CalendarPageActionsV6'

type CalendarDates = {
  currentDate: string
  endDate: string
  startDate: string
}

const pageConfig = {
  feature: 'calendar',
  help: <HelpTagIframe id="company_calendar" />,
  marketingID: 'calendar',
}

type Props = {
  timezone: string
}

const TABS = {
  table: 'table-view',
  calendar: 'calendar-view',
}

const CalendarPage: React.FC<Props> = () => {
  const defaultTab = useCalendarTabSettings((store) => store.defaultTab)
  const setDefaultTab = useCalendarTabSettings((store) => store.setDefaultTab)

  const defaultTabRoute = TABS?.[defaultTab] || TABS.calendar

  return (
    <>
      <Page breakpoint="0" actions={<CalendarPageActionsV6 />} {...pageConfig}>
        <Grid gap="1rem" columns="100%">
          <Tabs>
            <Tabs.List className="-mt-4">
              <Tabs.Item
                as={NavLink}
                label="All Calendars"
                to={`calendar-view`}
                onClick={() => {
                  setDefaultTab('calendar')
                }}
              />

              <Tabs.Item
                as={NavLink}
                label="My Calendar"
                to={`my-calendar`}
                onClick={() => {
                  setDefaultTab('my-calendar')
                }}
              />

              <Tabs.Item
                as={NavLink}
                label="Batch Edit View"
                to={`table-view`}
                onClick={() => {
                  setDefaultTab('table')
                }}
                permission="events.admin_view.view"
              />
            </Tabs.List>
          </Tabs>

          <Routes>
            <Route index element={<Navigate to={defaultTabRoute} replace />} />

            <Route path={'calendar-view/*'} element={<CalendarViewPage />} />
            <Route path={'my-calendar/*'} element={<MyCalendarPage />} />
            <Route path={'table-view/*'} element={<TableViewPage />} />
          </Routes>
        </Grid>
      </Page>

      <AnimatedRoutes>
        <Route
          path={`:view/event-builder`}
          element={
            <ProtectedRouteV6 permission="events.view" featureFlag="calendar">
              <EventBuilderOverlay useV6Router />
            </ProtectedRouteV6>
          }
        />

        <Route
          path={`:view/:id`}
          element={
            <ProtectedRouteV6 permission="events.view" featureFlag="calendar">
              <EventOverlay useV6Router />
            </ProtectedRouteV6>
          }
        />
      </AnimatedRoutes>
    </>
  )
}

const TableViewPage = () => {
  const tableProps = useDataTable({
    name: ['events'],
    endpoint: `/events`,
    params: { event_type: 'event' },
  })

  const to = React.useMemo(() => (rowData: any) => `${rowData.id}`, [])

  return (
    <CalendarDataTable
      {...tableProps}
      to={to}
      mainLinkAs={NavLink}
      renderBatchActions={({ selectedRows, selectNone }: any) => (
        <Flex gap="1rem">
          <SummonOverlay overlay={<EventsBatchEditOverlay data={selectedRows} afterSave={selectNone} />}>
            <Button label="Batch Edit" glyph="edit" type="minimal" size={100} onClick={selectNone} isDisabled={selectedRows.length === 0} />
          </SummonOverlay>
        </Flex>
      )}
      batchActionsConfig={[
        {
          type: 'delete',
          permission: 'events.delete',
          action: async ({ ids }: any) => {
            await tableProps.deleteRecords(ids.join(','))
          },
        },
      ]}
    />
  )
}

const CalendarViewPage: React.FC<Props> = () => {
  const isDesktop = useMedia({ minWidth: 1300 })

  const { timezone } = useSettings()

  const [dates, setDates] = React.useState<CalendarDates | null>(null)
  const [filters, setFilters] = React.useState({})

  const staffIds = useCalendarCustomFilters((store) => store.staffIds)

  const queryParams = {
    event_type: 'event',
    from: dates?.startDate,
    until: dates?.endDate,
    filters,
    ...(staffIds.length > 0 && { staff: staffIds.join(',') }),
  }

  const { data, isLoading }: any = useGet({
    name: ['events', queryParams],
    url: `/events`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: !!dates },
  })

  const { mutateAsync: deleteEvents } = useDelete({ name: 'events', url: '/events', invalidate: 'events' })

  return (
    <Grid gap="1rem" columns="100%">
      <Card className="px-3 py-2">
        <Filters config={FILTERS.events} onUpdate={setFilters} localStorageKey="calendar_v2" />
      </Card>

      <Calendar
        useV6Router
        events={data}
        isLoading={isLoading}
        timezone={timezone}
        renderEvent={(event: any) => <CalendarEvent useV6Router event={event} timezone={timezone} link={event.id} />}
        onChange={setDates}
        to={(event: any) => event.id}
        localStorageKey="main_calendar_v2"
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'events.delete',
            action: async ({ ids }: any) => {
              await deleteEvents(ids.join(','))
            },
          },
        ]}
        sidebarAfter={
          isDesktop ? (
            <div className="max-h-[650px] overflow-y-auto">
              <StaffFilters />
            </div>
          ) : (
            <div className="px-3 pb-3">
              <DropdownMenu
                trigger={
                  <div>
                    <Button
                      label="Staff Calendars"
                      after={
                        size(staffIds) > 0 && (
                          <Badge color={COLORS.white} textColor={COLORS.blue} className="!font-[600]" size={18}>
                            {size(staffIds)}
                          </Badge>
                        )
                      }
                      glyph="user_group"
                      type="primary"
                      size={200}
                    />
                  </div>
                }
              >
                <div className="px-1 py-2">
                  <StaffFilters />
                </div>
              </DropdownMenu>
            </div>
          )
        }
      />
    </Grid>
  )
}

const MyCalendarPage: React.FC<Props> = () => {
  const { timezone, record } = useSettings()

  const [dates, setDates] = React.useState<CalendarDates | null>(null)
  const [filters, setFilters] = React.useState({})

  const queryParams = {
    event_type: 'event',
    from: dates?.startDate,
    until: dates?.endDate,
    staff: record?.id,
    filters,
  }

  const { data, isLoading }: any = useGet({
    name: ['events', queryParams],
    url: `/events`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: !!dates },
  })

  const { mutateAsync: deleteEvents } = useDelete({ name: 'events', url: '/events', invalidate: 'events' })

  return (
    <Grid gap="1rem" columns="100%">
      <Card className="px-3 py-2">
        <Filters config={FILTERS.events} onUpdate={setFilters} localStorageKey="calendar" />
      </Card>

      <Calendar
        useV6Router
        events={data}
        isLoading={isLoading}
        timezone={timezone}
        renderEvent={(event: any) => <CalendarEvent useV6Router event={event} timezone={timezone} link={event.id} />}
        onChange={setDates}
        to={(event: any) => event.id}
        localStorageKey="my_calendar"
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'events.delete',
            action: async ({ ids }: any) => {
              await deleteEvents(ids.join(','))
            },
          },
        ]}
      />
    </Grid>
  )
}

const StaffFilters = () => {
  const staffQueryParams = { status: 'active' }

  const { data: staff, isLoading }: any = useGet({
    name: ['employees', staffQueryParams],
    url: `/employees`,
    params: staffQueryParams,
  })

  const staffIds = useCalendarCustomFilters((store) => store.staffIds)
  const setStaffIds = useCalendarCustomFilters((store) => store.setStaffIds)
  const toggleStaffId = useCalendarCustomFilters((store) => store.toggleStaffId)

  const isAllSelected = React.useMemo(() => {
    if (!staff) return false

    return staff.every((employee: any) => staffIds.includes(employee.id))
  }, [staffIds, staff])

  const toggleSelectAll = () => {
    isAllSelected ? setStaffIds([]) : setStaffIds(staff.map((employee: any) => employee.id))
  }

  const isSomeSelected = size(staffIds) > 0

  if (isLoading || !staff) return <State isLoading />

  return (
    <div className="px-2 truncate">
      <div className="flex w-full items-center justify-between px-1 mb-1">
        <NavGroupLabel label="Staff Calendars" />

        <button
          className="text-left border-none bg-transparent inline-flex items-center flex-nowrap cursor-pointer hover:bg-hover text-[0.92rem] leading-none font-[500] text-text px-1.5 py-1.5 rounded-[4px]"
          onClick={toggleSelectAll}
        >
          <RadioCheckElement
            type="checkbox"
            isChecked={isAllSelected}
            isIndeterminate={isSomeSelected && !isAllSelected}
            size={14}
            className="mr-1.5"
          />
          <div className="min-w-0">Select All</div>
        </button>
      </div>

      {staff.map((employee: any) => {
        const isChecked = staffIds.includes(employee.id)

        return (
          <button
            key={employee.id}
            className={clsx(
              isChecked && '!bg-[#e2f3e2]',
              'text-left border-none bg-transparent flex items-center flex-nowrap w-full cursor-pointer hover:bg-hover text-[0.98rem] font-[500] text-text px-1.5 py-1.5 rounded-[4px] mb-[1.5px]',
            )}
            onClick={() => toggleStaffId(employee.id)}
          >
            <RadioCheckElement type="checkbox" isChecked={isChecked} size={16} className="mr-2" />
            <Avatar src={employee.avatar} size={20} initials={employee.name} className="mr-1.5" />
            <div className="flex-[1_1_auto] truncate min-w-0">{employee.name}</div>
          </button>
        )
      })}
    </div>
  )
}

const useCalendarCustomFilters = create(
  persist(
    (set: any, get: any) => ({
      staffIds: [],
      toggleStaffId: (id: string) => {
        const staffIds = get().staffIds

        if (staffIds.includes(id)) {
          set({ staffIds: staffIds.filter((i: string) => i !== id) })
        } else {
          set({ staffIds: [...staffIds, id] })
        }
      },
      setStaffIds: (ids: string[]) => {
        if (!Array.isArray(ids)) return

        set({ staffIds: ids })
      },
    }),
    { name: 'bh.calendar-custom-filters' },
  ),
)

const useCalendarTabSettings = create(
  persist(
    (set: any, get: any) => ({
      defaultTab: 'table',
      setDefaultTab: (tab: any) => {
        if (!TABS.hasOwnProperty(tab)) return

        set({ defaultTab: tab })
      },
    }),
    { name: 'bh.todos-tabs' },
  ),
)

export default withPageError(withMarketing(CalendarPage, pageConfig))
