import React from 'react'

import TypeBase from './TypeBase'
import Graphic from '../Graphic'
import { COLORS } from '../../theme'

const Text = ({
  avatar,
  avatarInitials,
  avatarMagnify,
  avatarMagnifyScale,
  children,
  className,
  color,
  testKey,
  testKeyLabel,
  description,
  fontWeight,
  glyph,
  glyphColor,
  glyphSize,
  icon,
  iconSize,
  label,
  is,
  align,
  nowrap = true,
  graphic,
  // muted,
}) => (
  <TypeBase
    is={is}
    className={className}
    css={{
      lineHeight: '1.2',
      alignItem: 'center',
      display: 'inline-flex',
    }}
  >
    <span css={{ display: 'flex', justifyContent: align, alignItems: 'center', flexWrap: nowrap ? 'nowrap' : 'wrap' }}>
      <Graphic
        avatar={avatar}
        avatarInitials={avatarInitials || label || description}
        glyph={glyph}
        glyphColor={glyphColor}
        size={glyphSize}
        icon={icon}
        iconSize={iconSize}
        avatarSize={18}
        glyphSize={16}
        avatarMagnify={avatarMagnify}
        avatarMagnifyScale={avatarMagnifyScale}
        graphic={graphic}
        css={{ marginRight: '0.25em' }}
      />

      {label && (
        <span data-test={testKeyLabel} css={{ fontWeight: 600, color: COLORS.text, marginRight: 2 }}>
          {label}
        </span>
      )}

      <span
        data-test={testKey}
        css={{
          whiteSpace: 'pre-line',
          fontWeight: fontWeight,
          color,
          // color: !muted ? COLORS.text : color,
        }}
      >
        {children || description}
      </span>
    </span>
  </TypeBase>
)

Text.defaultProps = {
  muted: true,
  fontWeight: 400,
  color: COLORS.textMuted,
  is: 'span',
  // align: 'center',
}

export default Text
