import React from 'react'

import { Status } from '../'

export const STATUSES = {
  active: {
    label: 'Active',
    color: 'green',
  },
  to_review: {
    label: 'To Review',
    color: 'orange',
  },
  inactive: {
    label: 'Inactive',
    color: 'gray',
  },
  resolved: {
    label: 'Resolved',
    color: 'gray',
  },
}

const DiagnosisStatus = ({ status, ...rest }: any) => <Status label={STATUSES[status]?.label} color={STATUSES[status]?.color} {...rest} />
export default DiagnosisStatus
