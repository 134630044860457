import React from 'react'
import clsx from 'clsx'

import { AIInput } from '../../components/AIInput'
import { getFormElementProps } from '../../utils/functions'
import { FormContext } from '../../../../components/Forms/context'

import BHInput from '../../../../components/Forms/Input'

export const TextInput: React.FC<any> = (props) => {
  const { children, element, className, hoverElement, useParsedConfig, environment, isEditable, editInputDefaultValue } = props

  const [value, setValue] = React.useState('')

  const formContext = React.useContext(FormContext)

  if (!element?.config) return null

  const rootClasses = clsx('TEXT_INPUT', className)
  const inputProps: any = getFormElementProps(element, { useParsedConfig, environment })

  const { ai_input_enabled } = element.config

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}

      <BHInput
        {...inputProps}
        value={value}
        onUpdate={(input) => {
          setValue(input.value)
          if (isEditable) editInputDefaultValue?.(input)
        }}
      />

      {ai_input_enabled && formContext.isEditable && (
        <AIInput
          key={`ai-input-${element.config.local_ai_input_value}-${element.config.global_ai_input_value}`}
          elementConfig={element.config}
          getInput={() => value}
          onInsert={(aiResult) => {
            setValue(value + ' ' + aiResult)
          }}
          onReplace={(aiResult) => {
            setValue(aiResult)
          }}
        />
      )}
    </div>
  )
}
