import React from 'react'

import Checkbox from '../../../../components/Forms/Checkbox'

export const ElementVisibilityEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate } = props

  const { is_readonly_hidden } = activeElement.config

  return (
    <>
      <Checkbox
        label="Hide Element"
        model="is_readonly_hidden"
        defaultValue={true}
        onUpdate={onUpdate}
        // value={is_readonly_hidden}
      />
    </>
  )
}
