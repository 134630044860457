import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { PastClientChargesReportDataTable } from '@behavehealth/constructs/LiveReports/PastClientChargesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const PastClientCharges = () => {
  const queryParams = { status: 'past_client_charges', category: 'past_client_charges' }

  const tableProps = useDataTable({
    name: ['reports', 'past_client_charges'],
    // endpoint: `/live_reports/clients`,
    endpoint: `/live_reports`,
    updateDeleteEndpoint: `/residents`,
    params: queryParams,
    localStorageKey: 'report_past_client_charges_v2',
  })

  const to = React.useMemo(() => (rowData: any) => getClientLink(rowData), [])

  return (
    <Page feature="alumni" title="Live Past Clients w/ New Charges Report">
      <Grid gap="1rem">
        <PastClientChargesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(PastClientCharges)
