import React from 'react'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { ClientsJourneyTimeline } from '@behavehealth/constructs/ClientsJourney/ClientsJourneyTimeline'

const RootCurrentClients = ({ dates, setDates }: any) => {
  const hasDates = !!dates?.startDate && !!dates?.endDate

  const queryParams = {
    status: 'current',
    ...(hasDates && {
      from: dates.startDate,
      until: dates.endDate,
    }),
  }

  const { data, isLoading, isRefetching }: any = useGet({
    name: ['clients-journey-timeline', queryParams],
    url: `/clients_journeys/timeline`,
    params: queryParams,
    options: { enabled: hasDates },
  })

  return (
    <div css={styles.root}>
      <ClientsJourneyTimeline data={data} isLoading={isLoading} isRefetching={isRefetching} dates={dates} setDates={setDates} />
    </div>
  )
}

const styles: any = {
  root: {
    display: 'grid',
    padding: '1rem',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    overflow: 'hidden',
  },
}

export const CurrentClients = withPageError(RootCurrentClients)
