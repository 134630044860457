import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { plural } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { Button, Card, Page, Grid, SummonOverlay } from '@behavehealth/components'

import { NewIntegrationOverlay } from '@behavehealth/constructs/Integrations/NewIntegrationOverlay'
import IntegrationsTable from '@behavehealth/components/Tables/IntegrationsTable'
import useStore from '@behavehealth/modules/store'

const pageConfig = {
  feature: 'connections',
  title: 'My Integrations',
}

const Integrations: React.FC = () => {
  const user = useStore((state) => state.user)

  const { data, isLoading }: any = useGet({
    name: 'personal-integrations',
    url: `/${plural(user?.type)}/${user?.id}/integrations?level=personal`,
  })

  const isEmpty = size(data) === 0

  const actions = (
    <SummonOverlay overlay={<NewIntegrationOverlay useV6Router level="personal" />}>
      <Button label="Setup Integration" type="primary" glyph="add" />
    </SummonOverlay>
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Grid gap="1rem">
        <Card>
          <IntegrationsTable mainLinkAs={NavLink} data={data} isLoading={isLoading} emptyActions={actions} to={(id) => id} />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Integrations, pageConfig))
