import React from 'react'
import size from 'lodash/size'

import { useDataTable } from '../../../components/DataTable/useDataTable'
import { useRouteMatch } from 'react-router-dom'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import FormSection from '../../../components/Forms/FormSection'
import Permission from '../../../components/Permission'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { MedicationsDataTable } from '../../Medications/MedicationsDataTable'
import { MedicationOverlay } from '../../Medications/MedicationOverlay'

const AddButton = ({ client }) => {
  const match = useRouteMatch()

  return (
    <Permission permission="medications.create">
      <SummonOverlay
        overlay={
          <MedicationOverlay showBackdrop testKey="add_medication_overlay" dataID="new" match={match} initialData={{ resident: client }} />
        }
      >
        <Button
          testKey="add_medication_button"
          label="Add Medication"
          glyph="add"
          size={200}
          type="primary"
          display="inline-flex"
          permission="medications.create"
        />
      </SummonOverlay>
    </Permission>
  )
}

const Medications = ({ data, client, setStatus, setData }: any) => {
  const match = useRouteMatch()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = match.params

  const [openMed, setOpenMed] = React.useState(null)

  const tableProps = useDataTable({
    name: ['client', resource_id, 'meds'],
    endpoint: `/residents/${resource_id}/meds`,
    updateDeleteEndpoint: '/meds',
    enabled: !!resource_id,
    localStorageKey: 'client_meds_v1',
  })

  const isEmpty = size(tableProps.data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <MedicationsDataTable emptyActions={<AddButton client={client} />} onClick={(record) => setOpenMed(record)} {...tableProps} />

          <CardContent>
            <AddButton client={client} />
          </CardContent>
        </Card>

        <CheckboxGroup>
          <Checkbox
            label="Set All Medications as Inactive Upon Discharge"
            model="deactivate_medications"
            value={data?.deactivate_medications}
            onUpdate={(state: any) => {
              setData({ deactivate_medications: state.value })
            }}
          />
        </CheckboxGroup>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        key={`summon-${openMed?.id}`}
        isOpen={!!openMed}
        onClose={() => setOpenMed(null)}
        overlay={<MedicationOverlay showBackdrop testKey="add_medication_overlay" dataID={openMed?.id} match={match} />}
      />
    </>
  )
}

export default Medications
