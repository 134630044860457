import { mapToArray } from '../../utils/functions'

export const CLAIM_PAYMENTS: any = []

export const ELECTRONIC_STATUSES: any = [
  {
    id: 'a',
    created_at: '2022-03-01T09:18:02.821-05:00',
    category: 'Payer Administrative',
    status: 'Claim has been adjudicated and is awaiting payment cycle',
  },
  {
    id: 'b',
    created_at: '2022-01-01T07:54:02.821-05:00',
    category: 'Finalized',
    status: 'Claim/line has been paid',
  },
]

export const CLAIM_TASKS: any = {
  abc: {
    id: 'abc',
    status: 'todo',
    created_at: '2021-12-02T07:54:02.821-05:00',
    updated_at: '2021-12-02T07:54:02.821-05:00',
    notes: 'Todo task details lorem ipsum dolor sit amet consectetur adipisicing elit aut aliquid',
    assignee: {
      id: 'a',
      name: 'John Doe',
    },
    author: {
      id: 'a',
      name: 'John Doe',
    },
  },
  def: {
    id: 'def',
    status: 'done',
    created_at: '2021-12-02T07:54:02.821-05:00',
    updated_at: '2021-12-02T07:54:02.821-05:00',
    notes: 'Done task details lorem ipsum dolor sit amet consectetur adipisicing elit aut aliquid',
    assignee: {
      id: 'a',
      name: 'John Doe',
    },
    author: {
      id: 'a',
      name: 'John Doe',
    },
  },
  ghi: {
    id: 'ghi',
    status: 'in_progress',
    created_at: '2021-12-02T07:54:02.821-05:00',
    updated_at: '2021-12-02T07:54:02.821-05:00',
    notes: 'In Progress task details lorem ipsum dolor sit amet consectetur adipisicing elit aut aliquid',
    assignee: {
      id: 'a',
      name: 'John Doe',
    },
    author: {
      id: 'a',
      name: 'John Doe',
    },
  },
  jkl: {
    id: 'jkl',
    status: 'on_hold',
    created_at: '2021-12-02T07:54:02.821-05:00',
    updated_at: '2021-12-02T07:54:02.821-05:00',
    notes: 'On Hold task details lorem ipsum dolor sit amet consectetur adipisicing elit aut aliquid',
    assignee: {
      id: 'a',
      name: 'John Doe',
    },
    author: {
      id: 'a',
      name: 'John Doe',
    },
  },
  mno: {
    id: 'mno',
    status: 'urgent',
    created_at: '2021-12-02T07:54:02.821-05:00',
    updated_at: '2021-12-02T07:54:02.821-05:00',
    notes: 'Urgent task details lorem ipsum dolor sit amet consectetur adipisicing elit aut aliquid',
    assignee: {
      id: 'a',
      name: 'John Doe',
    },
    author: {
      id: 'a',
      name: 'John Doe',
    },
  },
}

export const CLAIM_NOTES: any = {
  abc: {
    id: 'abc',
    status: 'documentation_required',
    notes:
      'Documentation required claim notes lorem ipsum dolor sit amet, consectetur adipisicing elit. sed sunt architecto minus adipisci amet incidunt fuga soluta repellendus iure animi cupiditate, iste odit quis eveniet fugiat est vel. veritatis, sequi.',
    created_at: '2021-12-02T07:54:02.821-05:00',
    updated_at: '2021-12-02T07:54:02.821-05:00',
    author: {
      id: 'a',
      avatar: '',
      name: 'John Doe',
    },
  },
  def: {
    id: 'def',
    status: 'to_review',
    notes:
      'To review claim notes lorem ipsum dolor sit amet, consectetur adipisicing elit. sed sunt architecto minus adipisci amet incidunt fuga soluta repellendus iure animi cupiditate, iste odit quis eveniet fugiat est vel. veritatis, sequi.',
    created_at: '2021-12-02T07:54:02.821-05:00',
    updated_at: '2021-12-02T07:54:02.821-05:00',
    author: {
      id: 'a',
      avatar: '',
      name: 'John Doe',
    },
  },
  ghi: {
    id: 'ghi',
    status: 'verified',
    notes:
      'Verified claim notes lorem ipsum dolor sit amet, consectetur adipisicing elit. sed sunt architecto minus adipisci amet incidunt fuga soluta repellendus iure animi cupiditate, iste odit quis eveniet fugiat est vel. veritatis, sequi.',
    created_at: '2021-12-02T07:54:02.821-05:00',
    updated_at: '2021-12-02T07:54:02.821-05:00',
    author: {
      id: 'a',
      avatar: '',
      name: 'John Doe',
    },
  },
}

export const CLAIM_STATUSES: any = [
  'draft',
  'verified',
  'submitted_to_clearing_house',
  'documentation_required',
  'updates_required',
  'to_review',
  'fully_paid',
  'denied',
]

export const CLAIM = {
  id: 'claim-8',
  category: 'professional',
  status: 'denied',
  dates: '12-01-2021 – 12-07-2021',
  created_at: '2021-12-02T07:54:02.821-05:00',
  updated_at: '2021-12-02T07:54:02.821-05:00',
  tasks: [
    {
      id: 'abc',
      status: 'todo',
      created_at: '2021-12-02T07:54:02.821-05:00',
      updated_at: '2021-12-02T07:54:02.821-05:00',
      notes: 'Todo task details lorem ipsum dolor sit amet consectetur adipisicing elit aut aliquid',
      assignee: { id: 'a', name: 'John Doe' },
      author: { id: 'a', name: 'John Doe' },
    },
    {
      id: 'def',
      status: 'done',
      created_at: '2021-12-02T07:54:02.821-05:00',
      updated_at: '2021-12-02T07:54:02.821-05:00',
      notes: 'Done task details lorem ipsum dolor sit amet consectetur adipisicing elit aut aliquid',
      assignee: { id: 'a', name: 'John Doe' },
      author: { id: 'a', name: 'John Doe' },
    },
    {
      id: 'ghi',
      status: 'in_progress',
      created_at: '2021-12-02T07:54:02.821-05:00',
      updated_at: '2021-12-02T07:54:02.821-05:00',
      notes: 'In Progress task details lorem ipsum dolor sit amet consectetur adipisicing elit aut aliquid',
      assignee: { id: 'a', name: 'John Doe' },
      author: { id: 'a', name: 'John Doe' },
    },
    {
      id: 'jkl',
      status: 'on_hold',
      created_at: '2021-12-02T07:54:02.821-05:00',
      updated_at: '2021-12-02T07:54:02.821-05:00',
      notes: 'On Hold task details lorem ipsum dolor sit amet consectetur adipisicing elit aut aliquid',
      assignee: { id: 'a', name: 'John Doe' },
      author: { id: 'a', name: 'John Doe' },
    },
    {
      id: 'def',
      status: 'urgent',
      created_at: '2021-12-02T07:54:02.821-05:00',
      updated_at: '2021-12-02T07:54:02.821-05:00',
      notes: 'Urgent task details lorem ipsum dolor sit amet consectetur adipisicing elit aut aliquid',
      assignee: { id: 'a', name: 'John Doe' },
      author: { id: 'a', name: 'John Doe' },
    },
  ],
  notes: [
    {
      id: 'abc',
      status: 'documentation_required',
      notes:
        'Claim notes lorem ipsum dolor sit amet, consectetur adipisicing elit. sed sunt architecto minus adipisci amet incidunt fuga soluta repellendus iure animi cupiditate, iste odit quis eveniet fugiat est vel. veritatis, sequi.',
      created_at: '2021-12-02T07:54:02.821-05:00',
      updated_at: '2021-12-02T07:54:02.821-05:00',
      author: { id: 'a', avatar: '', name: 'John Doe' },
    },
    {
      id: 'def',
      status: 'to_review',
      notes:
        'Claim notes lorem ipsum dolor sit amet, consectetur adipisicing elit. sed sunt architecto minus adipisci amet incidunt fuga soluta repellendus iure animi cupiditate, iste odit quis eveniet fugiat est vel. veritatis, sequi.',
      created_at: '2021-12-02T07:54:02.821-05:00',
      updated_at: '2021-12-02T07:54:02.821-05:00',
      author: { id: 'a', avatar: '', name: 'John Doe' },
    },
    {
      id: 'ghi',
      status: 'verified',
      notes:
        'Claim notes lorem ipsum dolor sit amet, consectetur adipisicing elit. sed sunt architecto minus adipisci amet incidunt fuga soluta repellendus iure animi cupiditate, iste odit quis eveniet fugiat est vel. veritatis, sequi.',
      created_at: '2021-12-02T07:54:02.821-05:00',
      updated_at: '2021-12-02T07:54:02.821-05:00',
      author: { id: 'a', avatar: '', name: 'John Doe' },
    },
  ],
  assignee: { id: 'jd', name: 'John Doe' },
  resident_id: 'vNwNjp2',
  resident: {
    id: 'vNwNjp2',
    type: 'resident',
    behave_id: 'ABVI000001',
    name: 'Abbigail Villarreal',
    sex: 'female',
    dob: '1993-05-27T20:00:00.000-04:00',
  },
  service_lines: [],
  // status: 'submitted_to_clearing_house',
  assignee_id: 'jd',
  insurance_policy_id: 'RPL0PD',
  insurance_authorization_id: 'X6Xqga',
  bill_type: '348',
  admission_type: '2',
  admission_source: '4',
  value_codes: [],
  insurance_policy: {
    id: 'RPL0PD',
    type: 'insurance_policy',
    updated_at: '2021-12-09T11:16:18.725-05:00',
    created_at: '2021-12-09T11:16:18.725-05:00',
    status: null,
    category: 'primary',
    is_client_policy_holder: true,
    insurance_company_name: null,
    policy_holder: { city: 'Beaver Springs', state: 'PA', zip_code: '17843', address_line_1: '8253 Stratford Drive', address_line_2: '' },
    payer_source: 'clearing_house',
    custom_insurance_payer_name: null,
    member_id: '1231231231',
    group_id: '',
    group_name: null,
    plan: 'Primary',
    plan_type: null,
    card_issued_at: null,
    check_summary: null,
    claims_phone_no: '',
    notes: '',
    evob_status: null,
    coverage_status: null,
    resets_at: null,
    effective_at: null,
    terminates_at: null,
    insurance_name: 'Aetna Better Health (CA)',
    insurance_global_payer: {
      id: 'NgdDg3',
      type: 'insurance_global_payer',
      updated_at: '2021-12-02T07:53:56.375-05:00',
      created_at: '2021-12-02T07:53:56.375-05:00',
      name: 'Aetna Better Health (CA)',
      status: 'active',
      category: null,
      website: null,
      notes: null,
      open_time: null,
      close_time: null,
      timezone: null,
      states_covered: ['CA'],
    },
    insurance_local_payer: {
      id: 'RPL0PD',
      type: 'insurance_local_payer',
      updated_at: '2021-12-09T11:16:18.940-05:00',
      created_at: '2021-12-09T11:16:18.940-05:00',
      avatar: null,
      name: 'Aetna Better Health (CA)',
      opening_times: null,
      website: null,
      network_status: 'out_of_network',
      enrolled_status: 'not_enrolled',
      collaboratemd_reference_no: null,
    },
  },
  insurance_authorization: {
    id: 'X6Xqga',
    type: 'insurance_authorization',
    updated_at: '2021-12-09T13:38:39.246-05:00',
    created_at: '2021-12-09T13:38:39.246-05:00',
    status: null,
    level: null,
    started_at: null,
    ended_at: null,
    sessions: '',
    send_reminders: true,
    notes: '',
    internal_notes: '',
    required_daily_hours: null,
    required_weekly_hours: null,
    identifier: '',
    used_sessions: '',
    not_required: false,
    ur_communication_method: 'web_portal',
    ur_activity_started_at: null,
    ur_activity_ended_at: null,
    pre_cert_no: '',
    care_manager_name: '',
    care_manager_phone_no: '',
    care_manager_phone_ext: '',
    care_manager_fax_no: '',
    peer_to_peer_name: '',
    peer_to_peer_phone_no: '',
    peer_to_peer_phone_ext: '',
    peer_to_peer_fax_no: '',
    admission: {
      id: 'vNwNjp2',
      type: 'admission',
      updated_at: '2021-12-02T07:54:02.861-05:00',
      created_at: '2021-12-02T07:54:02.861-05:00',
      accepted_at: null,
      admitted_at: '2021-11-25T07:54:02.845-05:00',
      decline_reason: null,
      discharge_notes: null,
      discharge_type: null,
      discharged_at: null,
      estimated_arrival: null,
      estimated_discharge: null,
      incoming_notes: null,
      levels_of_care: [],
      no_show_notes: null,
      notes: null,
      other_discharge_type: null,
      reserved_at: null,
      return_eligibility: null,
      status: 'admitted',
      waitlist_notes: null,
    },
    author: {
      id: 'wgWOgG',
      type: 'bh_employee',
      updated_at: '2021-12-02T07:53:56.321-05:00',
      created_at: '2021-12-02T07:53:56.321-05:00',
      position: 'dev',
      name: 'Robert Eles',
      first_name: 'Robert',
      middle_name: null,
      last_name: 'Eles',
      email: 'robert.eles@behavehealth.com',
      avatar: null,
      phone_no: null,
      phone_no_ext: null,
    },
    client: {
      id: 'vNwNjp2',
      type: 'resident',
      updated_at: '2021-12-02T07:54:02.872-05:00',
      created_at: '2021-12-02T07:54:02.821-05:00',
      external_id: '3dafed21-f344-408f-9302-645173dde352',
      behave_id: 'ABVI000001',
      first_name: 'Abbigail',
      middle_name: '',
      last_name: 'Villarreal',
      name: 'Abbigail Villarreal',
      avatar: null,
      sex: 'female',
      dob: '1993-05-27T20:00:00.000-04:00',
      email: 'abbigail.villarreal@gmail.com',
      phone_no: '349-463-9704',
      level_of_care: null,
      status: 'current',
      is_insured: null,
      is_the_policy_holder: null,
      financial_notes: null,
    },
  },
  condition_codes: [
    {
      category: 'condition',
      code: 'AM',
      description: 'Non-emergency medically necessary stretcher transport required.',
      id: 'condition_code:AM',
      type: 'condition_code',
    },
    {
      category: 'condition',
      code: 'W0',
      description: 'United Mine Workers of America demonstration indicator.',
      id: 'condition_code:W0',
      type: 'condition_code',
    },
    {
      category: 'condition',
      code: 'B1',
      description: 'Beneficiary is ineligible for Full Demonstration Program.',
      id: 'condition_code:B1',
      type: 'condition_code',
    },
  ],
}

const CLAIMS: any = {}

for (let i = 0; i < CLAIM_STATUSES.length; i++) {
  const id = `claim-${i + 1}`

  CLAIMS[id] = {
    ...CLAIM,
    id: id,
    category: 'professional',
    status: CLAIM_STATUSES[i],
    dates: '12-01-2021 – 12-07-2021',
    tasks: mapToArray(CLAIM_TASKS),
    notes: mapToArray(CLAIM_NOTES),
    assignee: {
      id: 'jd',
      name: 'John Doe',
    },
  }
}

export { CLAIMS }
