import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import sortBy from 'lodash/sortBy'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { FILTERS } from '@behavehealth/constructs/MessageBoard/MessageBoardDataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import MessageStatus from '@behavehealth/components/Statuses/MessageStatus'

export const CommunityMessageBoard = () => {
  const match = useRouteMatch()

  const tableProps: any = useDataTable({
    name: ['organizations', 'profile_messages'],
    endpoint: `/organizations/profile_messages`,
  })

  const sortedData = React.useMemo(() => {
    if (!tableProps.data) return []

    return sortBy(tableProps.data, 'order')
  }, [tableProps.data])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        model: 'title',
        width: 200,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value} />,
      },
      {
        title: 'Category',
        model: 'category',
        formatValue: ({ data, value }: any) => <MessageStatus status={value} className={data.is_expired ? 'opacity-50' : ''} />,
      },
      {
        title: 'Description',
        model: 'description',
        type: 'rich_text',
      },
      {
        title: 'Link',
        model: 'link',
        type: 'external_url',
      },
      {
        title: 'Shared by Partner',
        model: 'added_by_partner',
        type: 'profile',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
    ]
  }, [])

  return (
    <DataTable
      {...tableProps}
      asCard
      title="All Partner Messages"
      icon="message_board"
      data={sortedData}
      columns={columns}
      filtersConfig={FILTERS}
    />
  )
}
