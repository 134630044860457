import React from 'react'

import Status from '../Status'
import OrganizationStatus from './OrganizationStatus'

const SigneeStatus = ({ signee }: any) => {
  if (!signee) return null

  if (signee.type === 'resident') return <Status label="Client" color="blue" />
  if (signee.type === 'contact') return <Status label={signee.relationship} color="orange" />
  if (signee.type === 'organization') return <OrganizationStatus organization={signee} />

  return null
}

export default SigneeStatus
