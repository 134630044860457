import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const CLIENT_PORTAL_PERMISSION_STATUSES = {
  no_access: {
    label: 'No Access',
    color: 'red',
  },
  can_view: {
    label: 'View Only',
    color: 'orange',
  },
  can_view_edit: {
    label: 'View & Edit',
    color: 'green',
  },
}

export const FormSubmissionClientPortalPermissionStatus = ({ ...rest }) => {
  return <SmartStatus statuses={CLIENT_PORTAL_PERMISSION_STATUSES} {...rest} />
}
