import React from 'react'
import pluralize from 'pluralize'
import { daysBetween, DT } from '../../../utils/functions'

import Empty from './Empty'

const DaysBetweenCell: React.FC = ({ date1, date2, timezone }) => {
  if (!date1 || !date2) return <Empty />

  const parsedDate1 = DT(date1, timezone)
  const parsedDate2 = DT(date2, timezone)

  const result = daysBetween(parsedDate1, parsedDate2, timezone)

  return `${result} ${pluralize('day', result)}`
}

export default DaysBetweenCell
