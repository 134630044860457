import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  signed_off: {
    label: 'Sent to Facility',
    color: 'green',
  },
}

const VOBStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default VOBStatus
