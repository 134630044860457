import React from 'react'
import { connect } from 'react-redux'

import Alert from '../../Alert'
import AmountInput from '../../Forms/AmountInput'
import Button from '../../Button'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Select from '../../Forms/Select'
import Textarea from '../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

import ModifierCodesSelector from '../../Forms/elements/ModifierCodesSelector'
import RevenueCodeSelector from '../../Forms/elements/RevenueCodeSelector'

class PayerInsuranceCodeOverlay extends OverlayBase {
  renderHeader = () => <Overlay.Header icon="insurance" title="Insurance Code" />

  renderContent = () => {
    const { $editable } = this.state
    const { record, reference } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={record}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          decorate={() => ({ reference_id: reference.id, reference_type: reference.type })}
        >
          <Section>
            <FormSection>
              <Flex stretchChildrenX gap="0.5rem">
                <Input
                  label="Procedure Code"
                  model="code"
                  validations={{
                    presence: {
                      message: 'Please enter a code',
                    },
                  }}
                />

                <Select label="Code Type" model="code_type" defaultValue="cpt">
                  <Option label="CPT" value="cpt" />
                  <Option label="HCPC/MOD" value="hcpc_mod" />
                  <Option label="HCPCS" value="hcpcs" />
                </Select>
              </Flex>

              <Flex stretchChildrenX gap="1rem">
                <RevenueCodeSelector label="Revenue Code" model="revenue_code" />
                <ModifierCodesSelector label="Modifier Codes" model="modifier_codes" />
              </Flex>

              <Flex gap="0.5rem">
                <AmountInput
                  label="Price"
                  model="price"
                  validations={{
                    presence: {
                      message: 'Please enter a rate',
                    },
                  }}
                />

                <Select
                  label="Unit"
                  model="unit"
                  className="!flex-1"
                  validations={{
                    presence: {
                      message: 'Please select a unit',
                    },
                  }}
                  defaultValue="per_occurence"
                >
                  <Option label="per occurrence" value="per_occurrence" />
                  <Option label="per day" value="per_day" />
                  <Option label="per hour" value="per_hour" />
                  <Option label="per test" value="per_test" />
                </Select>
              </Flex>

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a description',
                  },
                }}
              />

              <Textarea label="Description" model="description" />
              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Alert glyph="warning" type="warning">
              Please note, when using a third-party billing company, Behave Health Corp. does not take responsibility for the accuracy of
              this data.
            </Alert>

            <Button
              label="Save Code"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="settings.out_of_network_services.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Service"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="settings.out_of_network_services.edit"
            />

            <DeleteDialog
              title="Delete Insurance Service?"
              message="Are you sure you want to delete this insurance service? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                fullWidth
                permission="settings.out_of_network_services.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapStateToProps = (state, props) => ({
  ...defaultMapStateToProps(state, props.match, 'insurance_codes'),
  reference: state.data?.insurance_local_payers?.data?.[props.match?.params?.resource_id],
})

export default connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(PayerInsuranceCodeOverlay))
