import React from 'react'
import produce from 'immer'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { DraggableProvided, DroppableProvided, DraggableSnapshot } from 'react-beautiful-dnd'

import { COLORS, SHADOW } from '../../theme'
import { mapToArray } from '../../utils/functions'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardTitle from '../../components/CardTitle'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Overlay from '../../components/Overlay'

import { getCustomPageIcon } from './functions'

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const [removed] = list.splice(startIndex, 1)
  list.splice(endIndex, 0, removed)

  return list
}

export const CustomPagesReorderOverlay = ({ onClose, data, onSave, title = 'Reorder' }: any) => {
  const [items, setItems] = React.useState(mapToArray(data))
  const [loading, setLoading] = React.useState(false)

  const handleDragEnd = (result: any) => {
    if (!result.destination) return
    if (result.destination.index === result.source.index) return

    const newItems = produce(items, (draft: any) => {
      draft = reorder(draft, result.source.index, result.destination.index)
      for (let i = 0; i < draft.length; i++) {
        draft[i].order = i + 1
      }
    })

    setItems(newItems)
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose}>
      <Overlay.Header title={title} />

      <Overlay.Content css={styles.overlayContent}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list">
            {(droppableProvided: DroppableProvided) => (
              <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {items.map((item: any, index: number) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(draggableProvided: DraggableProvided, draggableSnapshot: DraggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        css={styles.itemDraggable}
                        className={draggableSnapshot.isDragging ? 'is-dragging' : ''}
                      >
                        <Card baseline="2.75rem">
                          <CardHeader graphic={<Glyph glyph="drag_and_drop" color="textMuted" size={20} css={styles.dragHandle} />}>
                            <Flex centerY gap={8}>
                              <Icon icon={getCustomPageIcon(item)} size={18} />
                              <CardTitle title={item.name || item.data?.name} css={styles.title} />
                            </Flex>
                          </CardHeader>
                        </Card>
                      </div>
                    )}
                  </Draggable>
                ))}

                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save"
          color="green"
          type="primary"
          isLoading={loading}
          onClick={async () => {
            setLoading(true)
            await onSave(items)
            setLoading(false)
            onClose()
          }}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const styles = {
  overlayContent: {
    padding: '1rem',
  },

  itemDraggable: {
    marginBottom: '0.5rem',
    cursor: 'move !important',

    '&.is-dragging': {
      borderRadius: 4,
      background: COLORS.white,
      boxShadow: SHADOW(3, COLORS.divider),

      // quick fix: when used inside a portal container, the placeholder element's position is not calculated correctly
      left: 'auto !important',
      right: '0 !important',
    },
  },

  dragHandle: {
    opacity: 0.6,
  },

  title: {
    fontSize: '0.95rem',
  },
}
