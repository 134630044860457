import {
  SET_IDLE,
  SET_IDLE_SAVING,
  SET_ONLINE,
  SET_OFFLINE,
  NEW_VERSION_AVAILABLE,
  NEW_VERSION_RESET,
  TOGGLE_DEVBAR,
  RESET_QUEUE,
  BUILD_QUEUE,
  UPDATE_QUEUE,
  UPDATE_STATS,
  PROCESSING_STARTED,
  PROCESSING_FINISHED,
  SET_CURRENT_UPLOAD,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SET_BACK_ROUTE,
  CLEAR_BACK_ROUTE,
} from '../actions/common'

import { LOCATION_CHANGE } from 'connected-react-router'

export const INITIAL_STATE = {
  global: {
    idle: false,
    online: true,
    devBar: false,
    newVersion: false,
    newVersionReset: false,
    version: 'development',
  },
  uploader: {
    status: 'pending',
    files: null,
    current: null,
    percentage: 0,
  },
  notifications: [],
  recentSearches: [],
  routes: {
    back: null,
    prev: null,
  },
}

let shortHistory = [null, null]
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_IDLE:
      return {
        ...state,
        global: {
          ...state.global,
          idle: action.idle,
        },
      }
    case SET_IDLE_SAVING:
      return {
        ...state,
        global: {
          ...state.global,
          idleSaving: action.saving,
        },
      }
    case SET_ONLINE:
      return {
        ...state,
        global: {
          ...state.global,
          online: true,
        },
      }
    case SET_OFFLINE:
      return {
        ...state,
        global: {
          ...state.global,
          online: false,
        },
      }
    case NEW_VERSION_AVAILABLE:
      return {
        ...state,
        global: {
          ...state.global,
          newVersion: true,
          version: action.version,
        },
      }
    case NEW_VERSION_RESET:
      return {
        ...state,
        global: {
          ...state.global,
          newVersionReset: true,
        },
      }
    case TOGGLE_DEVBAR:
      return {
        ...state,
        global: {
          ...state.global,
          devBar: !state.global.devBar,
        },
      }
    case RESET_QUEUE:
      return {
        ...state,
        uploader: Object.assign({}, state.uploader, {
          status: 'pending',
          files: null,
          percentage: 0,
        }),
      }
    case BUILD_QUEUE:
      return {
        ...state,
        uploader: Object.assign({}, state.uploader, {
          files: action.data,
        }),
      }
    case UPDATE_QUEUE:
      return {
        ...state,
        uploader: Object.assign({}, state.uploader, {
          files: action.data,
        }),
      }
    case UPDATE_STATS:
      return {
        ...state,
        uploader: Object.assign({}, state.uploader, {
          percentage: action.percentage,
          step: action.step,
        }),
      }
    case PROCESSING_STARTED:
      return {
        ...state,
        uploader: Object.assign({}, state.uploader, {
          status: 'uploading',
          percentage: 0,
        }),
      }
    case PROCESSING_FINISHED:
      return {
        ...state,
        uploader: Object.assign({}, state.uploader, {
          status: 'completed',
          percentage: 100,
          current: null,
        }),
      }
    case SET_CURRENT_UPLOAD:
      return {
        ...state,
        uploader: Object.assign({}, state.uploader, {
          files: action.files,
          current: action.current,
        }),
      }
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.data, ...state.notifications],
      }
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((o) => o.id !== action.data),
      }
    case LOCATION_CHANGE:
      shortHistory[0] = shortHistory[1]
      shortHistory[1] = action.payload.location

      return {
        ...state,
        routes: {
          ...state.routes,
          prev: shortHistory[0],
        },
      }
    case SET_BACK_ROUTE:
      return {
        ...state,
        routes: {
          ...state.routes,
          back: state.routes.prev ? state.routes.prev : state.routes.back,
        },
      }
    case CLEAR_BACK_ROUTE:
      return {
        ...state,
        routes: {
          ...state.routes,
          back: null,
        },
      }
    default:
      return state
  }
}

export default reducer
