import React from 'react'

import { Extension } from '@tiptap/core'
import { ReactRenderer } from '@tiptap/react'
import { tint } from 'polished'
import Suggestion from '@tiptap/suggestion'
import tippy from 'tippy.js'

import { COLORS, SHADOW } from '../../theme'
import Glyph from '../Glyph'

export const SlashCommands = Extension.create({
  name: 'commands',

  addOptions() {
    return {
      suggestion: {
        char: '/',
        command: ({ editor, range, props }) => {
          props.command({ editor, range })
        },
      },
    }
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})

export const CommandsList = React.forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const selectItem = (index) => {
    const item = props.items[index]

    if (item) {
      props.command(item)
    }
  }

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  React.useEffect(() => setSelectedIndex(0), [props.items])

  React.useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className="items" css={STYLES.menu}>
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
            key={index}
            onClick={() => selectItem(index)}
            css={STYLES.menuItem}
          >
            <Glyph glyph={item.glyph} size={18} />
            {item.title}
          </button>
        ))
      ) : (
        <div className="item p-4">No result</div>
      )}
    </div>
  )
})

export const suggestion = {
  items: ({ query }) => {
    return [
      {
        title: 'Heading 1',
        glyph: 'heading_1',
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).setNode('heading', { level: 1 }).run()
        },
      },
      {
        title: 'Heading 2',
        glyph: 'heading_2',
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).setNode('heading', { level: 2 }).run()
        },
      },
      {
        title: 'Heading 3',
        glyph: 'heading_3',
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).setNode('heading', { level: 3 }).run()
        },
      },
      {
        title: 'Bold',
        glyph: 'bold',
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).setMark('bold').run()
        },
      },
      {
        title: 'Italic',
        glyph: 'italic',
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).setMark('italic').run()
        },
      },
      {
        title: 'Underline',
        glyph: 'underline',
        command: ({ editor, range }) => {
          editor.chain().focus().deleteRange(range).setMark('underline').run()
        },
      },
    ]
      .filter((item) => item.title.toLowerCase().startsWith(query.toLowerCase()))
      .slice(0, 10)
  },

  render: () => {
    let component
    let popup

    return {
      onStart: (props) => {
        component = new ReactRenderer(CommandsList, {
          // using vue 2:
          // parent: this,
          // propsData: props,
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup?.[0]?.destroy?.()
        component?.destroy?.()
      },
    }
  },
}

const STYLES = {
  menu: {
    background: 'white',
    borderRadius: 5,
    boxShadow: SHADOW(3),
    display: 'grid',
  },

  menuItem: {
    border: 'none',
    background: 'none',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',

    svg: { marginRight: '0.5rem' },

    '&:hover': {
      background: tint(0.9, COLORS.vividBlue),
    },

    '&.is-selected': {
      background: tint(0.9, COLORS.vividBlue),
    },
  },
}
