import React from 'react'

import { FEATURES, ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Divider from '../../components/Divider'
import EmailInput from '../../components/Forms/EmailInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import GoogleMeetInput from '../../components/Forms/GoogleMeetInput'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'
import ZoomInput from '../../components/Forms/ZoomInput'

const RootNewProgramOverlay = (props: any) => {
  const { cancel, close, edit, form, initialModel, isEditable, isInvalid, isNew, isOverlayLoading, isSaving, onValidationUpdate, save } =
    useOverlay({
      name: 'program',
      endpoint: '/programs',
      invalidate: 'programs',
      invalidateKeys: ['programs-timeline', 'program-timeline'],
      options: props,
    })

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay animateOnEnter onClose={close}>
      <Overlay.Header icon={FEATURES.programs.icon} title={isNew ? 'New Program' : 'Program'} help={<HelpTagIframe id="add_program" />} />

      <Overlay.Content>
        <Form
          getForm={form}
          isEditable={isEditable}
          initialModel={initialModel}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
        >
          <Section title="Program Details">
            <FormSection layout="vertical">
              <Input
                autoFocus
                label="Program Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a program name',
                  },
                }}
              />

              <Textarea label="Description" model="description" />

              <MultiObjectSelector
                model="organizations"
                label="Linked Organizations"
                type="program_organizations"
                icon={ICONS.organizations}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section title="Seat Assignee Types">
            <CheckboxGroup
              layout="vertical-dense"
              validations={{
                presence: {
                  message: 'Please select at least one assignee type category',
                },
              }}
            >
              <Checkbox defaultChecked label="Applicants" model="assignees.applicants" icon="applicants" />
              <Checkbox defaultChecked label="Current Clients" model="assignees.current_clients" icon="clients" />
              <Checkbox defaultChecked label="Past Clients" model="assignees.past_clients" icon="alumni_relations" />
              <Checkbox defaultChecked label="Staff" model="assignees.staff" icon="employees" />
              <Checkbox label="Client Contacts" model="assignees.client_contacts" icon="contacts" />
              <Checkbox label="Organization Contacts" model="assignees.organization_contacts" icon="contacts" />
            </CheckboxGroup>
          </Section>

          <Divider />

          <Section title="Meeting Locations">
            <FormSection>
              <MultiObjectSelector model="properties" label="Properties" type="properties" icon={ICONS.properties} />
              {/* <MultiObjectSelector model="organizations" label="Organizations" type="program_organizations" icon={ICONS.organizations} /> */}
              <ZoomInput label="Zoom URL" model="zoom" />
              <GoogleMeetInput label="Google Meet URL" model="google_meet" />
            </FormSection>
          </Section>

          <Divider />

          <Section title="Contact Information">
            <FormSection>
              <EmailInput label="Email Address" model="email" />
              <PhoneInput label="Phone" model="phone_no" />
              <Input glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" permission="todos.edit" />
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const NewProgramOverlay = withOverlayError(RootNewProgramOverlay)
