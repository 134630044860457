import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  full: {
    label: 'Full Access',
    color: 'green',
  },
  restricted: {
    label: 'Restricted Access',
    color: 'red',
  },
}

const AccessLevelStatus = ({ isLocked, ...rest }) => {
  return <SmartStatus statuses={statuses} glyph={isLocked ? 'lock' : undefined} glyphColor="textMuted" {...rest} />
}

export default AccessLevelStatus
