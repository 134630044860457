import React from 'react'
import size from 'lodash/size'

import withSettings from '../../hocs/withSettings'

import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: any, timezone: string) => [
  {
    width: 300,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'message',
    Header: 'Message',
    Cell: ({ row, value }: any) => <TableCell.MainLink isTruncated to={to?.(row.original.id)} label={value} />,
  },
  {
    width: 180,
    accessor: 'category',
    Header: 'Message Type',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell.Empty />
      if (value === 'text') return <Status label="Text" color="blue" />
      if (value === 'poll') return <Status label="Poll" color="green" />
      return null
    },
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Sent By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
  {
    width: 180,
    accessor: 'slugs',
    Header: 'Sent to',
    Cell: ({ value }: any) => <TableCell value={`${size(value)} Channels`} />,
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Sent At',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
]

type Props = {
  data: Object
  isLoading: boolean
  localStorageKey: string
  timezone: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const ClientsMessageBlastsTable = ({ data, isLoading, localStorageKey, batchActionsConfig, timezone, to, titleAfter }: Props) => {
  return (
    <Table
      title="Clients Message Blasts"
      titleAfter={titleAfter}
      icon="clients_chat"
      data={data}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyDescription="Send a message to multiple clients in a program or property"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

ClientsMessageBlastsTable.defaultProps = {
  localStorageKey: 'clients_message_blasts',
}

export default withSettings(ClientsMessageBlastsTable)
