import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { fullname, titleCase } from '@behavehealth/utils/functions'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Status from '../../components/Status'

import { INSURANCE_POLICY_FILTERS } from '../Filters/constants'
import { RELATIONSHIP_TO_CLIENT_CODE } from '../../components/RCM/constants'

export const InsurancePoliciesDataTable = (props) => {
  const match = useRouteMatch()

  const { to, onClick, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Insurance Payer',
        model: 'insurance_local_payer',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return (
            <MainCell id={data.id} to={to ? to(data) : undefined} onClick={onClick ? () => onClick(data) : undefined} value={value?.name} />
          )
        },
      },
      {
        title: 'Status',
        model: 'status',
        width: 100,
        formatValue: ({ value }: any) => (
          <Status label={value === 'active' ? 'Active' : 'Archived'} color={value === 'active' ? 'green' : 'grey'} />
        ),
      },
      {
        title: 'Type',
        model: 'category',
        width: 120,
        formatValue: ({ value }: any) => titleCase(value),
      },
      {
        title: 'Plan Name',
        model: 'plan',
        width: 160,
      },
      {
        title: 'Member ID',
        model: 'member_id',
        width: 160,
      },
      {
        title: 'Group ID',
        model: 'group_id',
        width: 160,
      },
      {
        title: 'Policy Holder',
        model: 'is_client_policy_holder',
        formatValue: ({ data, value }: any) => {
          if (value === true) return 'Client'

          if (!data?.policy_holder) return null

          return `${fullname(data.policy_holder)} (${RELATIONSHIP_TO_CLIENT_CODE[data?.policy_holder?.relationship_to_customer] || ''})`
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [match, to, onClick],
  )

  return <DataTable title="Insurance Policies" icon="insurance" columns={columns} filtersConfig={INSURANCE_POLICY_FILTERS} {...rest} />
}
