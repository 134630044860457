import React from 'react'

import Select from '../common/Select'

type Props = { column: any }

const LevelOfCareFilter = (props: Props) => {
  const {
    column: { filterValue, setFilter },
  } = props

  const filterOptions = [
    {
      value: 'medically_managed_intensive_inpatient_detoxification',
      label: '4-D Medically Managed Intensive Inpatient Detoxification',
    },
    {
      value: 'medically_managed_intensive_inpatient_services',
      label: '4 Medically Managed Intensive Inpatient Services',
    },
    {
      value: 'medically_monitored_inpatient_detoxification',
      label: '3.7-D Medically Monitored Inpatient Detoxification',
    },
    {
      value: 'medically_monitored_high_intensity_inpatient_services',
      label: '3.7 Medically Monitored High-Intensity Inpatient Services',
    },
    {
      value: 'clinically_managed_medium_intensity_residential_services',
      label: '3.5 Clinically Managed Medium-Intensity Residential Services',
    },
    {
      value: 'clinically_managed_population_specific_high_intensity_residential_services',
      label: '3.3 Clinically Managed Population-Specific High-Intensity Residential Services',
    },
    {
      value: 'clinically_managed_residential_detoxification',
      label: '3.2-D Clinically Managed Residential Detoxification',
    },
    {
      value: 'clinically_managed_low_intensity_residential_services',
      label: '3.1 Clinically Managed Low-Intensity Residential Services',
    },
    {
      value: 'residential_inpatient_detoxification',
      label: '3-D Residential/Inpatient Detoxification',
    },
    {
      value: 'partial_hospitalization_services',
      label: '2.5 Partial Hospitalization Services',
    },
    {
      value: 'intensive_outpatient_services',
      label: '2.1 Intensive Outpatient Services',
    },
    {
      value: 'ambulatory_detoxification_with_extended_on_site_monitoring',
      label: '2-D Ambulatory Detoxification With Extended On-Site Monitoring',
    },
    {
      value: 'ambulatory_detoxification_without_extended_on_site_monitoring',
      label: '1-D Ambulatory Detoxification Without Extended On-Site Monitoring',
    },
    {
      value: 'outpatient_services',
      label: '1 Outpatient Services',
    },
    {
      value: 'early_intervention',
      label: '0.5 Early Intervention',
    },
  ]

  return <Select setFilter={setFilter} filterValue={filterValue} filterOptions={filterOptions} />
}

export default LevelOfCareFilter
