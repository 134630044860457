import size from 'lodash/size'
import compact from 'lodash/compact'

export const getQuoteDetailsFromFacility = (facility: any) => {
  if (!facility) return {}

  const email = facility.contacts?.billing?.email || facility.contacts?.owner?.email || facility.email
  const phone = facility.contacts?.billing?.phone_no || facility.contacts?.owner?.phone_no || facility.phone_no
  const address = size(facility.billing_address) ? facility.billing_address : facility.mailing_address

  return {
    name: `Quote for ${facility.name}`,
    timezone: facility.timezone,
    billed_to_company_name: facility.name,
    billed_to_name: compact([facility?.first_name, facility?.last_name]).join(' '),
    billed_to_email: email,
    billed_to_phone_no: phone,
    billed_to_address: address,
  }
}
