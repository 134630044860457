import React from 'react'
import size from 'lodash/size'

import { useDataTable } from '../../../components/DataTable/useDataTable'
import { useRouteMatch } from 'react-router-dom'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Divider from '../../../components/Divider'
import FormSection from '../../../components/Forms/FormSection'
import Permission from '../../../components/Permission'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { InsurancePoliciesDataTable } from '../../Insurance/InsurancePoliciesDataTable'
import { InsurancePolicyOverlay } from '../../Insurance/InsurancePolicyOverlay'

const AddButton = () => {
  const match = useRouteMatch()

  return (
    <SummonOverlay overlay={<InsurancePolicyOverlay showBackdrop testKey="add_insurance_policy_overlay" dataID="new" match={match} />}>
      <Button
        testKey="add_insurance_policy_button"
        label="Add Insurance Policy"
        glyph="add"
        size={200}
        type="primary"
        display="inline-flex"
        permission="insurance_policies.create"
      />
    </SummonOverlay>
  )
}

const InsurancePolicies = ({ setStatus, setData }: any) => {
  const match = useRouteMatch()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = match.params

  const [openPolicy, setOpenPolicy] = React.useState(null)

  const tableProps = useDataTable({
    name: ['client', resource_id, 'insurance_policies'],
    endpoint: `/residents/${resource_id}/insurance_policies`,
    updateDeleteEndpoint: '/insurance_policies',
    enabled: !!resource_id,
    localStorageKey: 'client_insurance_policies_v1',
  })

  const isEmpty = size(tableProps.data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  if (isEmpty || tableProps.isLoading) {
    return (
      <FormSection maxWidth="100%">
        <Card>
          <State
            isLoading={tableProps.isLoading}
            isEmpty={isEmpty}
            title="Insurance Policies"
            icon="insurance"
            emptyDescription="No insurance policies have been added yet"
            emptyActions={<AddButton />}
          />
        </Card>

        <Permission featureFlagV2="insurance_claims">
          <Divider />

          <FormSection maxWidth="100%">
            <Alert glyph="info">We will automatically generate Roster Items for this Client if set as Billable</Alert>

            <CheckboxGroup>
              <Checkbox
                label="Set Client as Billable Client"
                model="is_insurance_billable"
                value={true}
                onUpdate={(state: any) => {
                  setData({ is_insurance_billable: state.value })
                }}
              />

              <Checkbox
                label="Automatic Eligibility Run"
                model="automatic_eligibility_run"
                onUpdate={(state: any) => {
                  setData({ automatic_eligibility_run: state.value })
                }}
              />
            </CheckboxGroup>
          </FormSection>
        </Permission>
      </FormSection>
    )
  }

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <InsurancePoliciesDataTable emptyActions={<AddButton />} onClick={(policy) => setOpenPolicy(policy)} {...tableProps} />

          <CardContent>
            <AddButton />
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        isOpen={!!openPolicy}
        onClose={() => setOpenPolicy(null)}
        overlay={<InsurancePolicyOverlay showBackdrop testKey="add_insurance_policy_overlay" dataID={openPolicy?.id} match={match} />}
      />
    </>
  )
}

export default InsurancePolicies
