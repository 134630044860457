import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import BHObjectSelector from '../../../../components/Forms/Selectors/Object/ObjectSelector'

import { getFormElementProps } from '../../utils/functions'

const selectTitle = (o) => o?.name
const selectDescription = (o) => o?.description || null

export const ObjectSelector: React.FC<any> = (props) => {
  const { isEditable, children, element, className, hoverElement, useParsedConfig, environment } = props

  const object_selector_options = React.useMemo(() => {
    if (!element?.config?.object_selector_options) return []

    const { parsed, object_selector_options } = element.config

    if (useParsedConfig && size(parsed?.object_selector_options) === size(object_selector_options)) {
      return parsed.object_selector_options || []
    }

    return object_selector_options || []
  }, [element, useParsedConfig])

  if (!element?.config) return null

  const rootClasses = clsx('OBJECT_SELECTOR', className)
  const inputProps = getFormElementProps(element, { useParsedConfig, environment })

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}

      <BHObjectSelector
        key={isEditable ? `updated-${element.config?.updated_at}` : undefined}
        {...inputProps}
        isRelation={false}
        apiData={object_selector_options}
        selectTitle={selectTitle}
        selectDescription={selectDescription}
      />
    </div>
  )
}
