import React from 'react'
import { Link, useParams } from 'react-router-dom-v5-compat'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import Notifications from '../../modules/notifications'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Divider from '../../components/Divider'
import Switch from '../../components/Forms/Switch'
import ContextShow from '../../components/Forms/ContextShow'

import { TreatmentPlanSettings } from './form_elements/TreatmentPlanSettings'
import { TreatmentPlanSignatureSettings } from './form_elements/TreatmentPlanSignatureSettings'
import { TreatmentPlanPlanReviewsSettings } from './form_elements/TreatmentPlanPlanReviewsSettings'
import { TreatmentPlanCustomSections } from './form_elements/TreatmentPlanCustomSections'

const RootTreatmentPlanSettingsOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'treatment_plans',
    endpoint: '/treatment_plans',
    invalidate: 'treatment_plans',
    invalidateKeys: ['client'],
    options: props,
    isEditable: true,
    closeOnSave: true,
    onSaveSuccessful: () => {
      Notifications.send('Treatment Plan Settings Updated', 'positive')
    },
  })

  const { timezone } = useSettings()
  const params = useParams()

  const [useCustomSection, setCustomSection] = React.useState(initialModel?.settings?.use_custom_sections)

  const categorySlug = params.plan_category?.replaceAll?.('_', '-')

  const { permissionBase } = props

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay showBackdrop onClose={close}>
      <Overlay.Header glyph="settings" title="Treatment Plan Settings" />

      <Overlay.Content>
        <Form
          isCompact
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
        >
          <Section isOpen size={200} title="Settings">
            <FormSection layout="vertical">
              <Alert small contrast glyph="info">
                <b>Tip: </b> you can create{' '}
                <Link
                  to={`/settings/treatment-plan-templates${categorySlug ? `/${categorySlug}` : ''}`}
                  className="inline-flex items-center"
                  target="_blank"
                >
                  Treatment Plan Templates <Glyph glyph="external_link" className="!fill-blue-500 ml-1" size={12} />
                </Link>{' '}
                with pre-defined settings to save time next time you create a Treatment Plan.
              </Alert>

              <TreatmentPlanSettings />
            </FormSection>
          </Section>

          <Divider />

          <Section isOpen size={200} title="Signatures" aside={<Switch size={200} defaultValue={false} model="settings.use_signatures" />}>
            <ContextShow when="settings.use_signatures" is={true}>
              <FormSection maxWidth="100%">
                <TreatmentPlanSignatureSettings />
              </FormSection>
            </ContextShow>
          </Section>

          <Divider />

          <Section
            title="Custom Sections"
            aside={
              <Switch
                size={200}
                defaultValue={false}
                model="settings.use_custom_sections"
                onUpdate={({ value }: any) => setCustomSection(value)}
              />
            }
          >
            {useCustomSection && (
              <FormSection maxWidth="100%">
                <TreatmentPlanCustomSections isEditable={isEditable} model="custom_sections" />
              </FormSection>
            )}
          </Section>

          <Divider />

          <Section title="Plan Reviews" aside={<Switch size={200} defaultValue={false} model="settings.use_plan_reviews" />}>
            <ContextShow when="settings.use_plan_reviews" is={true}>
              <FormSection maxWidth="100%">
                <TreatmentPlanPlanReviewsSettings isEditable={isEditable} />
              </FormSection>
            </ContextShow>
          </Section>

          <Divider />

          <Section title="Appointments" aside={<Switch size={200} defaultValue={false} model="settings.use_appointments" />} />

          <Divider />

          <Section title="Updates" aside={<Switch size={200} defaultValue={false} model="settings.use_updates" />} />
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          glyph="check"
          label="Save"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={save}
          isDisabled={isInvalid}
          flex="100 1 auto"
          permission={`${permissionBase}.edit`}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const TreatmentPlanSettingsOverlay = withOverlayError(RootTreatmentPlanSettingsOverlay)
