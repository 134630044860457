import React from 'react'
import get from 'lodash/get'
import { withFormContext } from '../../../../Forms/context'

import Button from '../../../../Button'
import DateInput from '../../../../Forms/DateInput'
import FormSection from '../../../../Forms/FormSection'
import Input from '../../../../Forms/Input'
import LevelOfCareSelect from '../../../../Forms/LevelOfCareSelect'
import Section from '../../../../Section'
import State from '../../../../State'

class TreatmentHistoryFormV1 extends React.Component {
  state = {
    episodes: get(this.props.form.getInitialModel(), 'data.treatment_history.episodes', []),
  }

  /*
    CUSTOM
  */
  addEpisode = () => {
    let episodes = this.state.episodes

    episodes.push({
      name: '',
      level_of_care: '',
      episode_length: '',
      abstinence_days: 0,
    })

    this.setState({ episodes: episodes })
  }

  removeEpisode = (idx) => {
    let episodes = this.state.episodes
    episodes.splice(idx, 1)
    this.setState({ episodes: episodes })
  }

  renderTrigger = () =>
    this.props.isEditable && <Button type="minimal" glyph="add" label="Add Treatment Episode" onClick={this.addEpisode} />

  /*
    RENDER
  */
  render() {
    const { episodes } = this.state
    const { isEditable } = this.props

    const isEmpty = episodes.length === 0

    return (
      <State
        isEmpty={isEmpty}
        icon="clinical_assessments"
        title="Treatment History"
        emptyDescription="No treatment episodes added yet"
        emptyActions={this.renderTrigger()}
      >
        {episodes.map((_episode, idx) => (
          <Section
            key={idx}
            title="Treatment Episode"
            aside={
              isEditable && <Button label="Delete" glyph="delete" type="minimal" color="red" onClick={() => this.removeEpisode(idx)} />
            }
          >
            <FormSection>
              <Input model={`data.treatment_history.episodes[${idx}].name`} label="Provider Name" />
              <FormSection horizontal>
                <DateInput label="Start Date" model={`data.treatment_history.episodes[${idx}].start_date`} />
                <DateInput label="End Date" model={`data.treatment_history.episodes[${idx}].end_date`} />
              </FormSection>

              <LevelOfCareSelect label="Level of Care" model={`data.treatment_history.episodes[${idx}].level_of_care`} />

              <Input label="Length of Treatment Episode" model={`data.treatment_history.episodes[${idx}].length`} size={2} suffix="Days" />
              <Input
                label="Length of Abstinence After Treatment"
                model={`data.treatment_history.episodes[${idx}].abstinence_days`}
                size={2}
                suffix="Days"
              />
            </FormSection>
          </Section>
        ))}
        {this.renderTrigger()}
      </State>
    )
  }
}

TreatmentHistoryFormV1.defaultProps = {
  isEditable: false,
}

export default withFormContext(TreatmentHistoryFormV1)
