import React from 'react'

import { usDate } from '../../../utils/functions'

import Alert from '../../../components/Alert'
import DataList from '../../../components/DataList'
import Markup from '../../../components/Markup'

import { ReviewSection } from './ReviewSection'
import { QuoteIframeButton } from '../../QuoteElements/QuoteIframeButton'

export const Summary = ({ data, isOpen }: any) => {
  const timezone = data?.timezone || data?.facility?.timezone

  if (!data || !data.use_summary_section || !data.summary_section_text) {
    return null
  }

  return (
    <ReviewSection
      isOpen={isOpen}
      data={data}
      title="Summary"
      icon="general_info"
      titleAfter={<QuoteIframeButton data={data} model="summary" />}
      sectionModel="summary"
    >
      <Alert glyph="info" className="mb-4">
        <DataList isCompact labelWidth={140}>
          <DataList.Item label="Proposal sent on:" value={usDate(data.sent_at, timezone)} />
          <DataList.Item label="Proposal expires on:" value={usDate(data.expires_at, timezone)} />
        </DataList>
      </Alert>

      <Markup value={data.parsed_summary_section_text || data.summary_section_text} />
    </ReviewSection>
  )
}
