import React from 'react'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'

const withRouter = (Wrapped) => (props) => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()

  return <Wrapped history={history} location={location} match={match} {...props} />
}

withRouter.displayName = 'withRouter'
export default withRouter
