import React from 'react'
import dompurify from 'dompurify'

import Button from '../../../../Button'
import Form from '../../../../Forms/Form'
import MultiSearchSelector from '../../../../Forms/Selectors/MultiSearchObject/MultiSearchSelector'
import Overlay from '../../../../Overlay'
import Section from '../../../../Section'

const sanitizer = dompurify.sanitize

const AddDiagnosesOverlay = ({
  title = 'Add Diagnoses',
  buttonLabel = 'Add Diagnoses',
  buttonGlyph = 'add',
  onClose,
  onSave,
  before,
}: any) => {
  const form: any = React.useRef()
  const [valid, setValid] = React.useState(false)

  const save = () => {
    const formData = form.current?.getFormValue()

    if (onSave) onSave(formData?.diagnoses)

    onClose()
  }

  return (
    <Overlay showBackdrop onClose={onClose} position="center">
      <Overlay.Header title={title} icon="diagnosis" />

      <Overlay.Content>
        <Section>
          <Form getForm={form} onValidationUpdate={setValid}>
            {before}

            <MultiSearchSelector
              showAvatars={false}
              label="Search ICD-10 Codes"
              model="diagnoses"
              type="medical_codes.icd10_cm"
              selectTitle={(data: any, highlights: any) => {
                let title = data?.code
                if (!highlights) return title

                for (let i = 0; i < highlights?.length; i++) {
                  if (highlights[i].field === 'code') {
                    title = highlights[i].snippet
                    break
                  }
                }

                return <span dangerouslySetInnerHTML={{ __html: sanitizer(title) }} />
              }}
              selectDescription={(data: any, highlights: any) => {
                let description = data?.description
                if (!highlights) return description

                for (let i = 0; i < highlights?.length; i++) {
                  if (highlights[i].field === 'description') {
                    description = highlights[i].snippet
                    break
                  }
                }

                return <span dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
              }}
              validations={{
                presence: {
                  message: 'Please add at least one diagnosis code',
                },
              }}
            />
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label={buttonLabel} color="blue" type="primary" glyph={buttonGlyph} isDisabled={!valid} onClick={save} />
      </Overlay.Footer>
    </Overlay>
  )
}

export default AddDiagnosesOverlay
