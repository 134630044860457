import React from 'react'
import { tint } from 'polished'
import { useLocation } from 'react-router-dom'

import { COLORS, getColor } from '../theme'
import { daysToWords } from '../utils/functions'

import useActivePath from '../hooks/useActivePath'
import withSettings from '../hocs/withSettings'

import CardLink from './CardLink'
import CardMeta from './CardMeta'
import CardSubtitle from './CardSubtitle'
import CardTitle from './CardTitle'
import Glyph from './Glyph'
import Status from './Status'
import Text from './Typography/Text'

import Message from '../declarations/Message.d'

export const CATEGORIES = {
  alert: {
    color: 'red',
    glyph: 'circle_error',
    label: 'Alert',
  },
  warning: {
    color: 'orange',
    glyph: 'warning',
    label: 'Warning',
  },
  info: {
    color: 'blue',
    glyph: 'info',
    label: 'Info',
  },
  note: {
    color: 'gray',
    glyph: 'note',
    label: 'Note',
  },
}

type Props = {
  message: Message
  location: any
  timezone: string
  to: any
}

type MessageLinkProps = {
  link: string
}

const MessageLink: React.FC<MessageLinkProps> = ({ link }) => {
  const handleClick = (event: any) => {
    event.preventDefault()

    if (link.includes('://')) {
      window.open(new URL(link))
    } else {
      window.open(new URL('https://' + link))
    }
  }

  return (
    <span
      onClick={handleClick}
      css={{
        display: 'inline-block',
        width: 'fit-content',
        color: COLORS.blue,
        cursor: 'pointer',

        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      {link}
    </span>
  )
}

const MessageCard: React.FC<Props> = ({ message, timezone, to }) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  const { title, description, link, category, expires_at } = message

  const color = CATEGORIES[category].color

  return (
    <CardLink
      to={to}
      type={message.category}
      isActive={isActive}
      activeColor={color}
      css={styles(color)}
      className={message.is_expired ? 'is-expired' : ''}
      graphic={<Glyph glyph={CATEGORIES[category].glyph} color={getColor(color)} />}
    >
      <CardTitle title={title} css={message.is_expired && { textDecoration: 'line-through' }} />

      {description && <CardSubtitle subtitle={description} />}

      {link && <MessageLink link={link} />}

      <CardMeta>
        <Status color={color} label={CATEGORIES[category].label} />

        {expires_at && (
          <Text label={message.is_expired ? 'Expired:' : 'Expires:'} glyph="time" description={daysToWords(expires_at, timezone)} />
        )}

        {message.reference && <Text label="Created by: " glyph="user_neutral" description={<Text description={message.author.name} />} />}
      </CardMeta>
    </CardLink>
  )
}

const styles = (color: string) => ({
  '&::before': {
    content: '""',
    width: 3,
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    background: getColor(color),
  },

  background: tint(0.95, getColor(color)),

  '&.is-expired': {
    opacity: 0.6,
    background: COLORS.white,
  },
})

export default withSettings(MessageCard)
