import React from 'react'

import Button from '../Button'
import Link from '../Link'

import { COLORS } from '../../theme'

type Props = {
  className?: any
  css?: any
  email: string
  label: string
}

const EmailButton = ({ email, className, ...rest }: Props) => {
  return (
    <Link href={`mailto:${email}`} className={className}>
      <Button type="default" glyph="email" glyphColor={COLORS.gray} {...rest} />
    </Link>
  )
}

export default EmailButton
