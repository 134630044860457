import React from 'react'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'

import { useSettings } from '../../../hooks/useSettings'
import { usDateShort } from '../../../utils/functions'

import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import Link from '../../../components/Link'
import Status from '../../../components/Status'
import TreeItem from '../../../components/TreeItem'

const stopPropagation = (e: any) => {
  e.stopPropagation()
  e.nativeEvent.stopImmediatePropagation()
}

const titleClassName = 'font-[500] text-[0.92rem] text-text inline-block mr-1'
const linkTitleClassName = `${titleClassName} !text-blue-500 hover:underline`

const DateSegment = ({ withGlyph, label, date, className }: any) => {
  const { timezone } = useSettings()

  const classNames = clsx('flex items-center flex-nowrap text-[0.84rem]', className)

  return (
    <div className={classNames}>
      {withGlyph && <Glyph glyph="date" size={12} className="mr-1" />}
      <div>
        {label && <span className="font-[600] text-text">{label}</span>}{' '}
        <span className="font-[400] text-text-muted">{date ? usDateShort(date, timezone) : 'N/A'}</span>
      </div>
    </div>
  )
}

export const TreatmentPlanTreeItem = (props: any) => {
  const {
    isOpen,
    startDate,
    endDate,
    lastReviewedDate,
    completedDate,
    status,
    capsLabel,
    title,
    titleLink,
    children,
    menuChildren,
    firstSegmentMinWidth = 100,
  } = props

  const firstSegmentStyle = React.useMemo(() => {
    return { minWidth: firstSegmentMinWidth }
  }, [firstSegmentMinWidth])

  return (
    <TreeItem
      showDivider
      isOpen={isOpen}
      headerClassName="!py-0.5 min-h-[2.25rem]"
      headerContent={
        <div className="flex items-center w-full">
          <div className="flex items-center flex-1">
            <div className="flex flex-nowrap" style={firstSegmentStyle}>
              {capsLabel && <Status small label={capsLabel} className="!bg-transparent !text-text-muted" />}
              {status}
            </div>

            {titleLink ? (
              <Link to={titleLink} onClick={stopPropagation} className={linkTitleClassName}>
                {title}
              </Link>
            ) : (
              <div className={titleClassName}>{title}</div>
            )}
          </div>

          <div className="hidden mq1400:block">
            <Flex gap="1rem" className="items-center text-text-muted">
              {(startDate || endDate) && (
                <div>
                  <Flex nowrap gap="0.35rem">
                    <DateSegment withGlyph date={startDate} className="min-w-[5.5rem]" />
                    <div className="text-[0.8rem] font-[400] text-text-strongly-muted">→</div>
                    <DateSegment date={endDate} className="min-w-[5.5rem]" />
                  </Flex>
                </div>
              )}

              {!isUndefined(lastReviewedDate) && (
                <DateSegment withGlyph label="Reviewed:" date={lastReviewedDate} className="min-w-[11rem]" />
              )}
              {!isUndefined(completedDate) && <DateSegment withGlyph label="Completed:" date={completedDate} className="min-w-[11rem]" />}
            </Flex>
          </div>
        </div>
      }
      after={
        menuChildren && (
          <Button
            hideLabel
            type="minimal"
            glyph="more_vertical"
            display="inline-flex"
            className="my-1"
            size={100}
            onClick={stopPropagation}
          />
        )
      }
    >
      {children}
    </TreeItem>
  )
}
