import React from 'react'
import { connect } from 'react-redux'

import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import ContextShow from '../../Forms/ContextShow'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Overlay from '../../Overlay'
import RosterCategorySelect from '../../Forms/elements/RosterCategorySelect'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class EventManagerOverlay extends OverlayBase {
  renderHeader = () => <Overlay.Header icon="calendar" title={this.state.$new ? 'Add Event' : 'Event'} />

  renderContent = () => {
    const { $editable } = this.state
    const { record } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={record}
          isEditable={$editable}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
          linked={{
            scheduleId: this.props.match.params.schedule_id,
          }}
        >
          <Section>
            <FormSection>
              <Input
                model="name"
                label="Title"
                validations={{
                  presence: {
                    message: 'Please enter an event title',
                  },
                }}
              />

              <Textarea label="Description" model="description" />
            </FormSection>
          </Section>

          <Divider />

          <Section title="Roster" description={$editable && 'Enable rosters to track who attended your events'}>
            <FormSection>
              <Checkbox
                defaultChecked
                model="has_roster"
                label="Enable Roster"
                trueIcon="check"
                falseIcon="cross"
                falseStyle="faded-linethrough"
              />

              <ContextShow when="has_roster" is={true}>
                <RosterCategorySelect
                  label="Roster Type"
                  model="roster_category"
                  validations={{
                    presence: {
                      message: 'Please select a roster type',
                    },
                  }}
                />
              </ContextShow>
            </FormSection>
          </Section>

          <Divider />

          <Section title="Attachments">
            <FormSection>
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save Event"
              type="primary"
              color="green"
              glyph="check"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button label="Edit Event" glyph="edit" isDisabled={this.props.loading} onClick={this.edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Event?"
              message="Are you sure you want to delete this event? This action cannot be undone."
              onYes={this.delete}
            >
              <Button glyph="delete" label="Delete" color="red" isLoading={this.props.loading} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'managerEvents')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(EventManagerOverlay))
