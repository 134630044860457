import { encodeObjectToURL } from '../../utils/functions'
import { getSyncToken } from '../../modules/axios'

const isDevelopment = process.env.NODE_ENV === 'development'

export const APP_URLS = {
  ehr: process.env.BH_APP_EHR_URL,
  mini_apps: isDevelopment ? 'http://apps.localhost:4000' : 'https://apps.behavehealth.com',
  client_portal: isDevelopment ? 'http://portal.localhost:3004/app/' : 'https://portal.behavehealth.com/app/',
  website: isDevelopment ? 'http://sites.localhost:3007' : 'https://sites.behavehealth.com',
  members: isDevelopment ? 'http://members.localhost:3009' : 'https://members.behavehealth.com',
  community: isDevelopment ? 'http://localhost:3012' : 'https://community.behavehealth.com',
  my_account: isDevelopment ? 'http://myaccount.localhost:3008' : 'https://myaccount.behavehealth.com',
  hq: isDevelopment ? 'http://hq.localhost:3005' : 'https://hq.behavehealth.com',
  sales: isDevelopment ? 'http://sales.localhost:3013' : 'https://sales.behavehealth.com',
  community_admin: isDevelopment ? 'http://community-admin.localhost:3009' : 'https://community-admin.behavehealth.com',
}

export const generateLoginURL = (data: any, category: string) => {
  if (!data) return null

  const tokenResult = getSyncToken()
  let params = {}

  if (category === 'ehr') {
    params = {
      tenant: data?.subdomain,
      ...(tokenResult?.tokenType === 'jwt' && { token: tokenResult.token }),
      ...(tokenResult?.tokenType === 'session' && { session_token: tokenResult.token }),
    }
  } else if (category === 'client_portal') {
    params = {
      tenant: data?.subdomain,
      ...(tokenResult?.tokenType === 'jwt' && { token: tokenResult.token }),
      ...(tokenResult?.tokenType === 'session' && { session_token: tokenResult.token }),
    }
  } else if (category === 'community') {
    params = {
      community_id: data?.id,
      ...(tokenResult?.tokenType === 'jwt' && { token: tokenResult.token }),
      ...(tokenResult?.tokenType === 'session' && { session_token: tokenResult.token }),
    }
  } else if (category === 'members') {
    params = {
      community_id: data?.id,
      ...(tokenResult?.tokenType === 'jwt' && { token: tokenResult.token }),
      ...(tokenResult?.tokenType === 'session' && { session_token: tokenResult.token }),
    }
  } else if (category === 'my_account') {
    params = {
      ...(tokenResult?.tokenType === 'jwt' && { token: tokenResult.token }),
      ...(tokenResult?.tokenType === 'session' && { session_token: tokenResult.token }),
    }
  }

  return `${APP_URLS[category]}?${encodeObjectToURL(params)}`
}
