import React from 'react'
import { Link, NavLink, useParams } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { apiUpdate } from '@behavehealth/modules/api'
import useAPI from '@behavehealth/hooks/useAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, Form, Grid, Page, Radio, RadioGroup, HelpTagIframe } from '@behavehealth/components'

import AllowedIPsTable from '@behavehealth/components/Tables/AllowedIPsTable'

const pageConfig = {
  feature: 'staff_ip_restrictions',
  title: 'IP Restrictions',
  help: <HelpTagIframe id="settings_staff_ip_restrictions" />,
}

const AllowedIPs: React.FC = () => {
  const { id }: any = useParams()

  const form = React.useRef()

  const loading = useSelector((state: any) => state.data?.allowed_ips?.loading)
  const data: any = useSelector((state: any) => state.data?.allowed_ips?.data)

  const employee: any = useSelector((state: any) => state.data?.employees?.data?.[id])
  const loadingEmployee: any = useSelector((state: any) => state.data?.employees?.loading)

  useAPI('allowed_ips', `/employees/${id}/allowed_ips`)

  const isEmpty = size(data) === 0

  if (!(data && employee)) return <Page isLoading {...pageConfig} />

  const usingIPWhitelist = employee.prefs?.use_ip_whitelist

  return (
    <Page
      actions={usingIPWhitelist && <Button as={Link} label="Add IP Restriction" type="primary" glyph="add" link="new" />}
      {...pageConfig}
    >
      <Grid gap="1rem">
        <Form getForm={form} initialModel={{ prefs: employee.prefs }}>
          <RadioGroup
            label="Whitelist by IP"
            layout="vertical-dense"
            model="prefs.use_ip_whitelist"
            isDisabled={loadingEmployee}
            onChange={({ value }: any) =>
              apiUpdate({
                name: 'employees',
                url: `/employees/${id}`,
                params: {
                  prefs: {
                    ...employee.prefs,
                    use_ip_whitelist: value,
                  },
                },
              })
            }
          >
            <Radio label="No IP restrictions" value={false} />
            <Radio label="Yes, restrict by IP" value={true} />
          </RadioGroup>
        </Form>

        {usingIPWhitelist && (
          <Card>
            <AllowedIPsTable mainLinkAs={NavLink} data={data} isLoading={isEmpty && loading} to={(id: string) => id} />
          </Card>
        )}
      </Grid>
    </Page>
  )
}

export default withMarketing(AllowedIPs, pageConfig)
