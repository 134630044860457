import React from 'react'
import { useParams } from 'react-router-dom'
import kebabCase from 'lodash/kebabCase'

import { titleCase } from '@behavehealth/utils/functions'

import Alert from '../../../components/Alert'
import Card from '../../../components/Card'
import Form from '../../../components/Forms/Form'
import Permission from '../../../components/Permission'
import State from '../../../components/State'
import Tabs from '../../../components/Tabs'
import Workflow from '../../../components/Workflow/Workflow'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { TreatmentPlanStatus } from '@behavehealth/constructs/TreatmentPlans/components/TreatmentPlanStatus'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useTreatmentPlanPermissions } from '@behavehealth/constructs/TreatmentPlans/useTreatmentPlanPermissions'

const TreatmentPlans = ({ children, client, setStatus, data, setData }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)

  const { resource, resource_id }: any = useParams()

  const {
    viewCaseManagementTreatmentPlans,
    viewClinicalTreatmentPlans,
    viewMedicalTreatmentPlans,
    viewPeerTreatmentPlans,
    viewSomeTreatmentPlans,
  } = useTreatmentPlanPermissions()

  const allowedTabs = React.useMemo(() => {
    const result = []

    if (viewClinicalTreatmentPlans) result.push('clinical')
    if (viewMedicalTreatmentPlans) result.push('medical')
    if (viewCaseManagementTreatmentPlans) result.push('case_management')
    if (viewPeerTreatmentPlans) result.push('peer')

    return result
  }, [viewClinicalTreatmentPlans, viewMedicalTreatmentPlans, viewCaseManagementTreatmentPlans, viewPeerTreatmentPlans])

  const firstAllowedTab = allowedTabs[0]

  const [category, setCategory] = React.useState(firstAllowedTab)

  const clientId = client?.id

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  const tableProps = useDataTable({
    name: ['client', clientId, 'treatment_plans'],
    endpoint: `/residents/${clientId}/treatment_plans`,
    params: { category },
    options: { enabled: !!clientId && !!category },
  })

  const permissionBase = `${category}_treatment_plans`

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'id',
        width: 260,
        formatValue: ({ data }: any) => (
          <MainCell
            // as={NavLink}
            href={`/${resource}/${resource_id}/treatment-plans/${kebabCase(category)}/${data?.id}`}
            target="_blank"
            value={data.name}
          />
        ),
      },
      {
        title: 'Status',
        model: 'status',
        width: 200,
        formatValue: ({ value }: any) => <TreatmentPlanStatus status={value} />,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
    ],
    [],
  )

  if (!viewSomeTreatmentPlans) {
    return (
      <Card>
        <State
          isEmpty
          icon="treatment_plans"
          title="Treatment Plans"
          emptyDescription="You do not have permissions to access Clinical, Medical nor Peer Treatment Plans. If you're looking for the old Treatment Plans, please click on the menu item under Clinical Archived in the Client's menu."
        />
      </Card>
    )
  }

  return (
    <Form useLocalModel initialModel={data} onUpdate={setData}>
      <Alert small contrast glyph="info" className="mb-2">
        Open treatment plans below in a new tab to view/edit them, then click refresh in the table below to see the updates.
      </Alert>

      <Tabs activeTab={category} onChange={setCategory}>
        <Tabs.List className="mb-4">
          <Tabs.Item icon="treatment_plans" label="Clinical" name="clinical" permission={viewClinicalTreatmentPlans} />
          <Tabs.Item icon="treatment_plans" label="Medical" name="medical" permission={viewMedicalTreatmentPlans} />
          <Tabs.Item icon="treatment_plans" label="Case Management" name="case_management" permission={viewCaseManagementTreatmentPlans} />
          <Tabs.Item icon="treatment_plans" label="Peer" name="peer" permission={viewPeerTreatmentPlans} />
        </Tabs.List>
      </Tabs>

      <Permission permission={`${permissionBase}.view`}>
        <DataTable asCard title={`${titleCase(category)} Treatment Plans`} icon="treatment_plans" columns={columns} {...tableProps} />
      </Permission>

      {children}
    </Form>
  )
}

export default TreatmentPlans
