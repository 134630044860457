import React from 'react'
import { connect } from 'react-redux'

import Button from '../../Button'
import Chotomate from '../../Chotomate'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Overlay from '../../Overlay'
import PhoneInput from '../../Forms/PhoneInput'
import Select from '../../Forms/Select'
import Option from '../../Forms/Option'
import Input from '../../Forms/Input'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class PhoneBookOverlay extends OverlayBase {
  onSaveSuccessful = () => {
    if (this.props.onSaveSuccessful) this.props.onSaveSuccessful()
  }

  renderHeader = () => {
    return <Overlay.Header icon="phone" title="Number" />
  }

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { record, timezone } = this.props

    return (
      <Overlay.Content>
        <Chotomate ready name="phones_overlay" />

        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          timezone={timezone}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
        >
          <Section>
            <FormSection>
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <PhoneInput
                label="Phone Number"
                model="phone_no"
                validations={{
                  presence: {
                    message: 'Please enter a valid phone number',
                  },
                }}
              />
              <Input glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />

              <Select
                label="Category"
                model="category"
                validations={{
                  presence: {
                    message: 'Please select a category',
                  },
                }}
              >
                <Option label="Main Line" value="main_line" />
                <Option label="Pre Cert" value="pre_cert" />
                <Option label="Authorizations" value="authorizations" />
                <Option label="Denials" value="denials" />
                <Option label="Appeals" value="appeals" />
              </Select>

              <Select
                label="Type"
                model="phone_type"
                validations={{
                  presence: {
                    message: 'Please select a type',
                  },
                }}
              >
                <Option label="Phone" value="phone" />
                <Option label="Fax" value="fax" />
              </Select>

              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button glyph="edit" label="Edit Phone" type="default" isDisabled={this.props.loading} onClick={this.edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Phone?"
              message="Are you sure you want to delete this phone? This action cannot be undone."
              onYes={this.delete}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={this.props.loading} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'insurance_global_payer_phone_numbers')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_global_payer_phone_numbers')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(PhoneBookOverlay))
