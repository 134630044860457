import React from 'react'
import clsx from 'clsx'

import { css, COLORS, SHADOW, HARD_SHADOW } from '../../theme'

const RoadmapHeaderRow: React.FC<any> = (props) => {
  const { className, children, isSticky = true } = props

  const rootClasses = clsx(STYLES.root().className, isSticky && 'is-sticky', className)

  return <div className={rootClasses}>{children}</div>
}

const STYLES = {
  root: css({
    position: 'sticky',
    left: 0,
    zIndex: 4,
    background: COLORS.white,
    padding: '0.25rem 0.5rem',
    top: 'calc(var(--timeline-header-height) - 1px)',
    boxShadow: `1px 0 0 ${COLORS.divider}, ${SHADOW(2)}`,
    width: 'var(--first-column-width)',
    borderLeft: `1px solid ${COLORS.divider}`,
    borderRadius: '0 0 4px 4px',

    '& + .roadmap-row': {
      borderTop: `1px solid ${COLORS.divider}`,
    },

    '&.is-sticky': {
      top: 'calc(var(--timeline-header-height) - 1px)',
      boxShadow: `1px 0 0 ${COLORS.divider}, ${SHADOW(2)}`,
    },
  }),
}

export default RoadmapHeaderRow
