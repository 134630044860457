import React from 'react'
import { Manager, Reference } from 'react-popper'
import { keyframes } from '@emotion/react'

import { SHADOW, COLORS, MEDIA_QUERY, TOKENS } from '../theme'
import { isDefined } from '../utils/functions'

import SmartPortal from './SmartPortal'
import Button from './Button'
import Glyph from './Glyph'

import withPermissions from '../hocs/withPermissions'

class Dropdown extends React.Component {
  state = { $open: this.props.isOpen }
  mounted = true
  dropdown = document.getElementById('portal-dropdown')

  componentWillUnmount = () => {
    this.mounted = false

    if (!this.state.$open) return
    document.removeEventListener('click', this.close)
  }

  componentDidUpdate = (prevProps) => {
    const { isOpen }: any = this.props

    if (prevProps.isOpen === isOpen) return

    if (isOpen) {
      this.open()
    } else {
      this.close()
    }
  }

  /*
    CUSTOM FUNCTIONS
  */
  open = () => {
    if (this.props.isDisabled) return

    this.setState({ $open: true })
    document.addEventListener('click', this.close)
  }

  close = () => {
    if (!this.mounted) return

    this.props.onClose?.()

    this.setState({ $open: false })
    document.removeEventListener('click', this.close)
  }

  toggle = (event) => {
    if (this.props.isDisabled) return

    event.stopPropagation()

    if (this.props.onClick) this.props.onClick()

    this.state.$open ? this.close() : this.open()
  }

  handle = (event) => {
    if (!this.props.closeOnMenuClick) {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
    }
  }

  /*
    RENDER
  */
  render() {
    const {
      avatar,
      buttonClassName,
      buttonSize,
      buttonType,
      children,
      className,
      testKey,
      display,
      glyph,
      hideCloseButton,
      hideLabel,
      icon,
      label,
      menuWrapperCSS,
      maxWidth = 380,
      minWidth = 260,
      position,
      showChildren,
      trigger,
      triggerStyles,
      buttonColor,
      portal = 'dropdown',
    } = this.props

    if (showChildren) {
      return (
        <>
          {trigger}
          {children}
        </>
      )
    }

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} onClick={this.toggle} css={[localTriggerStyles, triggerStyles]} className={className}>
              {trigger || (
                <Button
                  className={buttonClassName}
                  hideLabel={hideLabel}
                  icon={icon}
                  glyph={glyph}
                  label={label}
                  testKey={testKey || 'dropdown_btn'}
                  type={buttonType}
                  size={buttonSize}
                  after={!hideLabel && <Glyph glyph="triangle_down" size={12} css={{ marginLeft: '0.5em' }} />}
                  avatar={avatar}
                  display={display}
                  color={buttonColor}
                  isDisabled={this.props.isDisabled}
                  isLoading={this.props.isLoading}
                />
              )}
            </div>
          )}
        </Reference>

        {(this.state.$open || this.props.isOpen) && (
          <SmartPortal data-test={testKey} portal={portal} position={position}>
            <div css={{ ...menuWrapperStyles, ...menuWrapperCSS }} onClick={this.handle} className="dropdown-menu-wrapper">
              {!hideCloseButton && (
                <div css={closeStyles} onClick={this.close}>
                  <Glyph glyph="cross" color="text" size={14} />
                </div>
              )}
              <div css={[menuStyles, { minWidth, maxWidth }]}>{children}</div>
            </div>
          </SmartPortal>
        )}
      </Manager>
    )
  }
}

const localTriggerStyles = {
  '*:hover': {
    cursor: 'pointer',
  },

  '& > *': {
    width: '100%',
  },
}

const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.97, 0.97, 0.97) translateY(-16px);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translateY(0);
  }
`

const closeButtonAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5) rotate(-45deg);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) rotate(0deg);
  }
`

const menuWrapperStyles = {
  padding: '0.5rem 1rem',
  position: 'relative',
  zIndex: 1,
}

const menuStyles = {
  '& > hr': {
    margin: '0 !important',
  },

  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',

  borderRadius: 7,
  background: 'white',
  boxShadow: SHADOW(10, COLORS.divider),
  // border: `1px solid ${COLORS.lightBackground}`,

  transformOrigin: 'center top',
  animation: `${animation} 160ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards`,
  outline: `0 !important`,

  maxHeight: '80vh',

  '@media (min-width: 1000px)': {
    maxHeight: '95vh',
  },
}

const closeStyles = {
  position: 'absolute',
  top: -10,
  right: -10,
  zIndex: 1,

  width: 34,
  height: 34,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  borderRadius: '100%',
  background: 'white',
  boxShadow: SHADOW(4, COLORS.divider),
  border: `1px solid ${COLORS.lightBackground}`,

  cursor: 'pointer',
  animation: `${closeButtonAnimation} 120ms cubic-bezier(0.39, 0.575, 0.565, 1)`,
  transition: 'transform 160ms cubic-bezier(0.39, 0.575, 0.565, 1)',

  [`${MEDIA_QUERY[TOKENS.appNavCompactBreakpoint]}`]: {
    display: 'none',
  },

  '&::before': {
    content: '""',
    width: 48,
    height: 48,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },

  '&:hover': {
    transform: 'scale3d(1.1, 1.1, 1.1) translateY(-1px)',

    svg: {
      fill: COLORS.blue,
    },
  },
}

Dropdown.defaultProps = {
  position: 'auto',
  buttonType: 'default',
  closeOnMenuClick: true,
}

export default withPermissions(Dropdown)
