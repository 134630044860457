import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'

import { ICONS } from '@behavehealth/theme'
import { mapToArray } from '@behavehealth/utils/functions'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import {
  Accordion,
  Alert,
  Checkbox,
  CheckboxGroup,
  DataList,
  EmailInput,
  Form,
  FormSection,
  GoogleMeetInput,
  HelpTagIframe,
  Input,
  MultiObjectSelector,
  Page,
  PhoneInput,
  Textarea,
  ZoomInput,
} from '@behavehealth/components'

const GeneralInfo: React.FC = () => {
  const { resource_id: id }: any = useParams()

  const { data: program, isLoading }: any = useGet({
    name: ['program', id],
    url: `/programs/${id}`,
  })

  const { mutateAsync: updateProgram }: any = useUpdate({
    name: ['program', id],
    url: `/programs/${id}`,
    invalidate: ['programs'],
  })

  return (
    <Page feature="general_info" plan="lite" isLoading={!program && isLoading} help={<HelpTagIframe id="program_general_info" />}>
      {/* Program Details */}
      <Accordion
        isOpen
        activateEditMode
        initialModel={program}
        editPermission="programs.edit"
        title="Program Details"
        glyph="personal_details"
        onSubmit={updateProgram}
      >
        <Form>
          <FormSection>
            <Input
              label="Name"
              model="name"
              validations={{
                presence: {
                  message: 'Please enter a program name',
                },
              }}
            />

            <Textarea label="Description" model="description" />

            <MultiObjectSelector
              model="organizations"
              label="Program Management Organizations"
              type="program_organizations"
              icon={ICONS.organizations}
            />

            <CheckboxGroup
              label="Seat Assignee Types"
              layout="vertical-dense"
              validations={{
                presence: {
                  message: 'Please select at least one assignee type',
                },
              }}
            >
              <Checkbox defaultChecked label="Staff" model="assignees.staff" icon="employees" />
              <Checkbox label="Applicants" model="assignees.applicants" icon="applicants" />
              <Checkbox label="Current Clients" model="assignees.current_clients" icon="clients" />
              <Checkbox label="Past Clients" model="assignees.past_clients" icon="alumni_relations" />
              <Checkbox label="Client Contacts" model="assignees.client_contacts" icon="contacts" />
              <Checkbox label="Organization Contacts" model="assignees.organization_contacts" icon="contacts" />
            </CheckboxGroup>
          </FormSection>
        </Form>
      </Accordion>

      {/* Meeting Locations */}
      <Accordion
        isOpen
        permission
        activateEditMode
        initialModel={program}
        editPermission="programs.edit"
        title="Meeting Locations"
        glyph="map_pin"
        onSubmit={updateProgram}
      >
        <Form>
          <FormSection>
            <ZoomInput label="Zoom URL" model="zoom" />
            <GoogleMeetInput label="Google Meet URL" model="google_meet" />
          </FormSection>
        </Form>
      </Accordion>

      {/* Program Contact Information */}
      <Accordion
        permission
        activateEditMode
        initialModel={program}
        editPermission="programs.edit"
        title="Program Contact Information"
        glyph="contact_details"
        onSubmit={updateProgram}
      >
        <Form>
          <FormSection>
            <EmailInput label="Email Address" model="email" />
            <PhoneInput label="Phone" model="phone_no" />
            <Input glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />
          </FormSection>
        </Form>
      </Accordion>

      {/* Client Portal Details */}
      <Accordion
        permission
        activateEditMode
        initialModel={program}
        editPermission="programs.edit"
        title="Client Portal Details"
        featureFlagV2="client_portal"
        glyph="portal"
        featureFlag="client_portal"
        newFeatureFlag="client_portal"
        onSubmit={updateProgram}
      >
        <Form>
          <FormSection labelWidth="150px">
            <Textarea minRows={4} maxWidth="100%" label="Portal Notes" model="portal_notes" />

            <CheckboxGroup label="Access" trueIcon="check" falseIcon="cross" falseStyle="none">
              <Checkbox label="Share details below with the Client Portal?" model="is_shared_with_portal" />

              <Alert type="neutral" className="!my-8 !mx-0">
                <DataList>
                  <DataList.Item label="Name" value={program?.name} />
                  <DataList.Item
                    label="Program Lists"
                    value={
                      <ol className="p-0 pl-4">
                        {mapToArray(program?.phases).map((o) => (
                          <li key={o.id}>{o.name}</li>
                        ))}
                      </ol>
                    }
                  />
                  <DataList.Item label="Email" value={program?.email} />
                  <DataList.Item label="Phone" value={program?.phone_no} />
                  <DataList.Item label="Zoom" value={program?.zoom} />
                  <DataList.Item label="Google Meet" value={program?.google_meet} />
                  <DataList.Item label="Notes" value={program?.portal_notes} />
                </DataList>
              </Alert>
            </CheckboxGroup>
          </FormSection>
        </Form>
      </Accordion>

      {/* Additional Information */}
      <Accordion
        permission
        activateEditMode
        initialModel={program}
        editPermission="programs.edit"
        title="Additional Information"
        glyph="additional_information"
        onSubmit={updateProgram}
      >
        <Form>
          <FormSection>
            <Textarea useQuickText label="Additional Info" model="notes" minRows={4} maxWidth="100%" />
          </FormSection>
        </Form>
      </Accordion>
    </Page>
  )
}

export default withPageError(GeneralInfo)
