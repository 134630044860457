import React from 'react'
import clsx from 'clsx'
import { lighten } from 'polished'

import { COLORS } from '../../../../theme'
import { Box } from './Box'

import Glyph from '../../../../components/Glyph'

export const Group: React.FC<any> = React.forwardRef((props: any, ref) => {
  const { isEditable, children, element, className, hoverElement } = props

  if (!element) return null

  const isActive = element?._isActive

  const rootClasses = clsx('GROUP grid gap-1 !p-0', isEditable && 'is-editable !pt-2', isActive && 'is-active', className)

  return (
    <Box ref={ref} element={element} className={rootClasses} hoverElement={hoverElement} css={STYLES.root}>
      {isEditable && (
        <div css={STYLES.tag}>
          <Glyph glyph="folder" size={14} color={COLORS.gray} />
          Group
        </div>
      )}

      {children}
    </Box>
  )
})

const STYLES = {
  root: {
    '&.is-editable': {
      position: 'relative',
      marginTop: '0.5rem',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        border: `1px dashed ${lighten(0.03, COLORS.gray)}`,
        borderRadius: 5,
        opacity: 0.4,
        pointerEvents: 'none !important',
      },
    },
  },

  tag: {
    width: 'fit-content',
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '0 0 4px 4px',
    background: lighten(0.4, COLORS.textStronglyMuted),
    padding: '0.1rem 0.25rem',
    fontSize: '0.75rem',
    position: 'absolute',
    top: 0,
    left: '0.5rem',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    fontWeight: 600,
    svg: { marginRight: '0.5rem' },
  },
}
