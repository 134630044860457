import React from 'react'

import Input from '../../../../components/Forms/Input'

export const OEmbedEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate } = props

  const { oembed_url } = activeElement.config

  return (
    <>
      <Input label="OEmbed URL" model="oembed_url" value={oembed_url} onUpdate={onUpdate} />
    </>
  )
}
