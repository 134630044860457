import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button } from '@behavehealth/components'
import { CommunityInviteTemplateOverlay } from '@behavehealth/constructs/Community/CommunityInviteTemplateOverlay'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'

import Page from '@behavehealth/components/Page'

const CommunityInviteTemplates = () => {
  const columns = React.useMemo(() => {
    return [
      {
        title: 'Subject',
        model: 'title',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} id={data.id} value={value} to={data.id} />,
      },
      {
        title: 'Message',
        model: 'message',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} id={data.id} value={value} to={data.id} />,
      },
      {
        width: 200,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'updated_at',
        title: 'Last Edited',
        type: 'date_time',
      },
      {
        width: 180,
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [])

  const tableProps = useDataTable({
    name: ['partnership-invite-templates'],
    endpoint: `/partnership_invite_templates`,
  })

  return (
    <>
      <Page
        icon="community_partners"
        title="Community Invite Templates"
        actions={
          <Button
            as={Link}
            label={`Add Invite Template`}
            glyph="add"
            type="primary"
            size={300}
            link="new"
            permission="settings.community_invite_templates.create"
          />
        }
      >
        <DataTable asCard title="Community Invite Templates" icon="community_partners" columns={columns} {...tableProps} />
      </Page>

      <AnimatedRoutes>
        <Route path=":id" element={<CommunityInviteTemplateOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

export default withPageError(CommunityInviteTemplates)
