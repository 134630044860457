import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useParams } from 'react-router-dom-v5-compat'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants.js'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { RoundsTemplateOverlay } from '@behavehealth/constructs/Rounds/RoundsTemplateOverlay'

const RootRoundsTemplates = () => {
  return (
    <div>
      <RoundsTemplatesIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<RoundsTemplateOverlay useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const RoundsTemplatesIndex = () => {
  const tableProps = useDataTable({
    name: ['round_templates'],
    endpoint: `/round_templates`,
    localStorageKey: 'round_templates',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell as={NavLink} id={data.id} to={data.id} value={value} />
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Actions',
        id: '_actions',
        disableSort: true,
        hoverExpand: false,
        formatValue: ({ data }) => {
          return <Button as={Link} label="Duplicate" glyph="add" size={100} type="default" link="new" state={{ data }} />
        },
      },
    ],
    [],
  )

  return (
    <Page
      title="Rounds Templates"
      icon="rounds"
      actions={<Button as={Link} label="Add Rounds Template" glyph="add" type="primary" link="new" permission="rounds_templates.create" />}
    >
      <Grid>
        <DataTable asCard icon="rounds" title="Rounds Templates" columns={columns} filtersConfig={FILTERS_CONFIG} {...tableProps} />
      </Grid>
    </Page>
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}

export const RoundsTemplates = withPageError(RootRoundsTemplates)
