import React from 'react'
import { useHistory } from 'react-router-dom'

import { encodeObjectToURL } from '../../utils/functions'
import { getToken } from '../../modules/axios'

import Button from '../Button'
import Flex from '../Flex'

type Props = {
  url: string
  label?: string
  className?: string
  params?: any
  tenant?: string
}

export const ExportPDFButton = ({ url, label = 'Download PDF', className, params, tenant }: Props) => {
  const history = useHistory()

  if (!url) return null

  const downloadPDF = async () => {
    const tokenResult = await getToken()

    // if no token is found, redirect to logout
    if (!tokenResult) {
      return history.push('/logout', {
        type: 'session_expired',
        message: 'Your Session has expired for security purposes. Please log back in to continue',
      })
    }

    const encoded = encodeObjectToURL({
      ...params,
      tenant: tenant || sessionStorage.getItem('bh.stn') || localStorage.getItem('bh.ftn') || localStorage.getItem('bh.stn'),
      ...(tokenResult?.tokenType === 'session' && { session_token: tokenResult.token }),
      ...(tokenResult?.tokenType === 'jwt' && { token: tokenResult.token }),
    })

    const protocol = process.env.BH_API_BASE_PROTOCOL
    const apiBaseUrl = process.env.BH_API_BASE_URL
    const version = process.env.BH_API_VERSION

    window.open(`${protocol}://${apiBaseUrl}/${version}${url}?${encoded}`)
  }

  return (
    <>
      <Button
        type="link"
        display="inline-flex"
        glyph="download"
        label={label}
        size={200}
        css={styles}
        onClick={downloadPDF}
        className={className}
      />
    </>
  )
}

const styles = {
  lineHeight: 'normal',
  paddingRight: 0,
  marginLeft: 'auto',
}
