import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'
import produce from 'immer'

import { countWord } from '@behavehealth/utils/functions'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import Flex from '@behavehealth/components/Flex'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Status from '@behavehealth/components/Status'

import { FeeScheduleOverlay } from '@behavehealth/constructs/RCM/FeeScheduleOverlay'

const pageConfig = {
  title: 'Fee Schedules',
  feature: 'insurance',
}

const FeeSchedules = () => {
  return (
    <>
      <FeeSchedulesIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<FeeScheduleOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const FeeSchedulesIndex: React.FC = () => {
  const { timezone } = useSettings()

  const tableProps: any = useDataTable({
    name: ['insurance_new_fee_schedules'],
    endpoint: `/insurance_new_fee_schedules`,
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 200,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} to={`${data.id}`} value={value} />,
      },
      {
        title: 'Status',
        model: 'status',
        width: 120,
        formatValue: ({ value }: any) => (
          <Status label={value === 'active' ? 'Active' : 'Archived'} color={value === 'active' ? 'green' : 'grey'} />
        ),
      },
      {
        title: 'Payers',
        model: 'insurance_local_payers',
        width: 300,
        formatValue: ({ value }: any) => value?.map((o: any) => o.name)?.join(', ') || '–',
      },
      {
        title: 'Healthcare Providers',
        id: 'employees_count',
        model: 'employees',
        width: 200,
        formatValue: ({ value }: any) => countWord('Providers', value?.length),
      },
      {
        title: 'Healthcare Locations',
        id: 'houses_count',
        model: 'houses',
        width: 180,
        formatValue: ({ value }: any) => countWord('Locations', value?.length),
      },
      {
        title: 'Services',
        id: 'services_count',
        model: 'insurance_new_fee_schedule_services',
        width: 100,
        formatValue: ({ value }: any) => countWord('Services', value?.length),
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Actions',
        model: 'id',
        width: 120,
        disableSort: true,
        disableHide: true,
        formatValue: ({ data }: any) => {
          const duplicated = produce(data, (draft: any) => {
            draft.name = draft.name + ' Copy'
            draft.insurance_new_fee_schedule_services.map((o: any) => {
              delete o.id
              o.insurance_new_code_id = o.insurance_new_code?.id
            })
          })

          return (
            <Flex gap="0.5rem">
              <Button as={Link} label="Duplicate" glyph="add" size={100} link="new" state={{ data: duplicated }} />
            </Flex>
          )
        },
      },
    ],
    [],
  )

  return (
    <Page
      {...pageConfig}
      actions={
        <Button as={Link} label="Add Fee Schedule" type="primary" glyph="add" link={`new`} permission="settings.fee_schedules.create" />
      }
    >
      <Grid gap="1rem">
        <Card>
          <DataTable
            {...tableProps}
            title="Fee Schedules"
            icon="insurance"
            columns={columns}
            timezone={timezone}
            filtersConfig={FILTERS_CONFIG}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'credentials.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  insurance_local_payers: {
    label: 'Payers',
    type: 'string',
  },
  employees_count: {
    label: 'Healthcare Providers',
    type: 'number',
  },
  houses_count: {
    label: 'Healthcare Locations',
    type: 'number',
  },
  services_count: {
    label: 'Services',
    type: 'number',
  },
  updated_at: {
    label: 'Last Edited',
    type: 'date_time',
  },
}

export default withPageError(withMarketing(FeeSchedules, pageConfig))
