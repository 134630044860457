import React from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom-v5-compat'

import { withOverlayError } from '../../hocs/withOverlayError'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

const RootTreatmentPlanLinkedDiagnosesOverlay = (props: any) => {
  const { planId, permissionBase } = props

  const form = React.useRef(null)
  const location: any = useLocation()
  const navigate: any = useNavigate()

  const { data, isLoading } = useGet({
    name: ['treatment_plans', planId, 'treatment-diagnoses'],
    url: `/treatment_plans/${planId}/treatment_diagnoses`,
    options: { enabled: !!planId },
  })

  const { mutateAsync, isLoading: isSaving } = useUpdate({
    name: ['treatment_plans', planId, 'treatment-diagnoses'],
    url: `/treatment_plans/${planId}`,
  })

  const { resource_id: clientId }: any = useParams()

  const close = () => {
    if (props.type === 'summon' && props.onClose) return props.onClose()

    const url = location.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    navigate(url)
  }

  const save = async () => {
    const formData = form.current.getFormValue()

    await mutateAsync(formData)

    close()
  }

  const initialModel = React.useMemo(() => {
    if (!data) return null

    return { diagnosis: data.map((o) => o.diagnosis) }
  }, [data])

  if (!initialModel || isLoading) {
    return <OverlayLoader showBackdrop closeOnBackdrop position="right" />
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={close}>
      <Overlay.Header icon="treatment_plans" title="Treatment Plan Diagnoses" />

      <Overlay.Content>
        <Form isEditable getForm={form} initialModel={initialModel}>
          <Section>
            <FormSection layout="vertical">
              <MultiObjectSelector
                label="Imported Diagnoses"
                dependentValue={clientId}
                type="client.diagnoses"
                selectTitle={(data) => data?.code}
                selectDescription={(data) => data.description}
                model="diagnosis"
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          glyph="check"
          label="Save"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={save}
          flex="100 1 auto"
          permission={`${permissionBase}.edit`}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const TreatmentPlanLinkedDiagnosesOverlay = withOverlayError(RootTreatmentPlanLinkedDiagnosesOverlay)
