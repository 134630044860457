import React from 'react'

import Checkbox from '../Checkbox'
import CheckboxGroup from '../CheckboxGroup'
import ContextHide from '../ContextHide'
import ContextShow from '../ContextShow'
import Textarea from '../Textarea'

const MedicalEquipmentCheckboxGroup = ({ label, model, isRequired }) => (
  <>
    <CheckboxGroup
      label={label}
      layout="vertical-dense"
      validations={
        isRequired && {
          presence: {
            message: 'Please select at least one option',
          },
        }
      }
    >
      <Checkbox label="None Applicable" model={`${model}.none`} />
      <ContextHide when={`${model}.none`} is={true}>
        <Checkbox label="Walker" model={`${model}.walker`} />
        <Checkbox label="Cane" model={`${model}.cane`} />
        <Checkbox label="Glucose Meter" model={`${model}.glucose_meter`} />
        <Checkbox label="C-Pap Machine" model={`${model}.c_pap_machine`} />
        <Checkbox label="Specialized Pillow" model={`${model}.specialized_pillow`} />
        <Checkbox label="Other" model={`${model}.other`} />
      </ContextHide>
    </CheckboxGroup>

    <ContextHide when={`${model}.none`} is={true}>
      <ContextShow when={`${model}.other`} is={true}>
        <Textarea
          label="Please list the other types of medical equipment"
          model={`${model}_other`}
          validations={
            isRequired && {
              presence: {
                message: 'Please list the other types of medical equipment',
              },
            }
          }
        />
      </ContextShow>
    </ContextHide>
  </>
)

MedicalEquipmentCheckboxGroup.defaultProps = {
  model: 'medical_equipment',
}

export default MedicalEquipmentCheckboxGroup
