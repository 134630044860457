import React from 'react'

import { formatURL } from '../../../utils/functions'
import DropdownItem from '../../DropdownItem'

const missingPhone = 'Missing phone no'
const missingEmail = 'Missing email'

const ContactQuickActions = ({ data }) => {
  const { first_name, email, phone_no, whatsapp, facebook, skype, zoom, google_meet } = data

  return (
    <>
      <DropdownItem
        label={phone_no ? `Call ${first_name}` : missingPhone}
        glyph="phone"
        color="green"
        href={`tel:${phone_no}`}
        isDisabled={!phone_no}
      />
      <DropdownItem
        label={phone_no ? `Text ${first_name}` : missingPhone}
        glyph="sms"
        color="paleBlue"
        href={`sms:${phone_no}`}
        isDisabled={!phone_no}
      />
      <DropdownItem
        label={email ? `Email ${first_name}` : missingEmail}
        glyph="email"
        color="gray"
        href={`mailto:${email}?Subject=Hello%20${first_name}`}
        isDisabled={!email}
      />
      {whatsapp && (
        <DropdownItem
          label="Open WhatsApp Chat"
          glyph="whatsapp"
          color="whatsapp"
          href={`https://wa.me/+1${whatsapp}`}
          target="_blank"
          useGlyphForTarget={false}
        />
      )}
      {facebook && (
        <DropdownItem
          label="Open Facebook Messenger"
          glyph="facebook_messenger"
          color="facebookMessenger"
          href={`http://m.me/${facebook}`}
          target="_blank"
          useGlyphForTarget={false}
        />
      )}
      {skype && (
        <DropdownItem
          label="Open Skype Chat"
          glyph="skype"
          color="skype"
          href={`skype:${skype}?chat`}
          target="_blank"
          useGlyphForTarget={false}
        />
      )}
      {zoom && (
        <DropdownItem
          label="Start Zoom Meeting"
          glyph="zoom"
          color="zoom"
          href={formatURL(zoom)}
          target="_blank"
          useGlyphForTarget={false}
        />
      )}
      {google_meet && (
        <DropdownItem
          label="Start Google Meeting"
          glyph="google_meet"
          color="googleMeet"
          href={formatURL(google_meet)}
          target="_blank"
          useGlyphForTarget={false}
        />
      )}
    </>
  )
}

export default ContactQuickActions
