import React from 'react'
import { tint } from 'polished'
import { useParams } from 'react-router-dom'

import {
  CLINICAL_ASSESSMENTS_SLUGS,
  CLINICAL_MEASURES_SLUGS,
  CLINICAL_NOTES_TITLES,
  COMMUNICABLE_DISEASE_TESTS,
  MEDICAL_ASSESSMENTS_SLUGS,
  NURSING_SLUGS,
  OUTCOME_MEASURES_SLUGS,
  RECOVERY_NOTES_SLUGS,
} from '../../utils/constants'

import { COLORS } from '../../theme'
import { getClientLink, getVersionSlug, initials, titleCase, usDate } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Roadmap from '../../components/Roadmap/Roadmap'

import { TodoInfo } from './records/TodoInfo'
import { EventInfo } from './records/EventInfo'
import { AgreementInfo } from './records/AgreementInfo'
import { DischargeInfo } from './records/DischargeInfo'
import { CommunicationInfo } from './records/CommunicationInfo'
import { ShiftNoteInfo } from './records/ShiftNoteInfo'
import { CustomNoteInfo } from './records/CustomNoteInfo'
import { RecoveryNoteInfo } from './records/RecoveryNoteInfo'
import { DiagnosisInfo } from './records/DiagnosisInfo'
import { ClinicalAssessmentInfo } from './records/ClinicalAssessmentInfo'
import { TreatmentPlanInfo } from './records/TreatmentPlanInfo'
import { ProgressReviewInfo } from './records/ProgressReviewInfo'
import { ClinicalNoteInfo } from './records/ClinicalNoteInfo'
import { ClinicalMeasureInfo } from './records/ClinicalMeasureInfo'
import { OutcomeMeasureInfo } from './records/OutcomeMeasureInfo'
import { UATestInfo } from './records/UATestInfo'
import { BreathalyzerTestInfo } from './records/BreathalyzerTestInfo'
import { VitalSignInfo } from './records/VitalSignInfo'
import { CommunicableDiseaseTestInfo } from './records/CommunicableDiseaseTestInfo'
import { LabInfo } from './records/LabInfo'
import { MedicationInfo } from './records/MedicationInfo'
import { MedicationLogInfo } from './records/MedicationLogInfo'
import { MedicalAssessmentInfo } from './records/MedicalAssessmentInfo'
import { PhysicianOrderInfo } from './records/PhysicianOrderInfo'
import { NursingAssessmentInfo } from './records/NursingAssessmentInfo'
import { ProgressNoteInfo } from './records/ProgressNoteInfo'

import { GroupHeader } from './components/GroupHeader'
import { RoadmapRecordRow } from './components/RoadmapRecordRow'

export const StaffJourneyTimeline = (props: any) => {
  const { className, data, dates, isLoading, isRefetching, setDates } = props

  const { timezone } = useSettings()
  const { resource_id: employeeID }: any = useParams()

  const [isGeneralOpen, setIsGeneralOpen] = React.useState(true)
  const [isCareCoordinationOpen, setIsCareCoordinationOpen] = React.useState(true)
  const [isNotesOpen, setIsNotesOpen] = React.useState(true)
  const [isClinicalOpen, setIsClinicalOpen] = React.useState(true)
  const [isMedicalOpen, setIsMedicalOpen] = React.useState(true)

  return (
    <Roadmap showTodayLine className={className} isLoading={isLoading} isRefetching={isRefetching} dates={dates} onDatesChange={setDates}>
      {data && (
        <>
          {/* General */}
          <GroupHeader
            title="General"
            icon="general_info"
            isAllOpen={isGeneralOpen}
            onToggleOpen={() => {
              setIsGeneralOpen((c) => !c)
            }}
          />

          <RoadmapRecordRow
            title="To-Do's"
            icon="tasks"
            color={tint(0.6, COLORS.green)}
            isOpen={isGeneralOpen}
            data={data.general.todos}
            renderTitle={(record: any) => record.title}
            renderTooltip={(record: any) => <TodoInfo data={record} />}
            recordLink={(record: any) => `/todo/active/${record.id}`}
          />

          <RoadmapRecordRow
            title="Events"
            icon="calendar"
            color={tint(0.6, COLORS.red)}
            isOpen={isGeneralOpen}
            data={data.general.events}
            renderTitle={(record: any) => record.title}
            renderTooltip={(record: any) => <EventInfo data={record} />}
            recordLink={(record: any) => `/calendar/${record.id}`}
          />

          {/* Care Coordination */}
          <GroupHeader
            title="Care Coordination"
            icon="community"
            isAllOpen={isCareCoordinationOpen}
            onToggleOpen={() => {
              setIsCareCoordinationOpen((c) => !c)
            }}
          />

          <RoadmapRecordRow
            title="Agreements"
            icon="legal_agreement_alt"
            color={tint(0.6, COLORS.orange)}
            isOpen={isCareCoordinationOpen}
            data={data.care_coordinations.agreements}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <AgreementInfo data={record} />}
            recordLink={(record: any) => {
              if (record.client) {
                return `${getClientLink(record.client)}/agreements/${record.id}`
              } else {
                return null
              }
            }}
            startDateKey="effective_at"
          />

          <RoadmapRecordRow
            title="Discharges"
            icon="discharge_forms"
            color={tint(0.6, COLORS.orange)}
            isOpen={isCareCoordinationOpen}
            data={data.care_coordinations.discharges}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <DischargeInfo data={record} />}
            recordLink={(record: any) => `${getClientLink(record.client)}/discharges/${record.id}`}
            startDateKey="dated_at"
          />

          <RoadmapRecordRow
            title="Communications"
            icon="communications"
            color={tint(0.6, COLORS.orange)}
            isOpen={isCareCoordinationOpen}
            data={data.care_coordinations.communications}
            renderTitle={(record: any) => record.contact.name}
            renderTooltip={(record: any) => <CommunicationInfo data={record} />}
            recordLink={(record: any) => {
              if (record.organization) {
                return `/community/organizations/${record.organization.id}/communications/${record.id}`
              } else if (record.client) {
                return `${getClientLink(record.client)}/communications/${record.id}`
              } else {
                return null
              }
            }}
            startDateKey="contacted_at"
          />

          {/* Notes */}
          <GroupHeader
            title="Notes"
            icon="custom_notes"
            isAllOpen={isNotesOpen}
            onToggleOpen={() => {
              setIsNotesOpen((c) => !c)
            }}
          />

          <RoadmapRecordRow
            title="Shift Notes"
            icon="housing_notes"
            color={tint(0.6, COLORS.paleBlue)}
            isOpen={isNotesOpen}
            data={data.notes.shift_notes}
            renderTitle={(record: any) => titleCase(record.category)}
            renderTooltip={(record: any) => <ShiftNoteInfo data={record} />}
            recordLink={(record: any) => `/employees/${employeeID}/shift-notes/${record.id}`}
            startDateKey="dated_at"
          />

          <RoadmapRecordRow
            title="Custom Notes"
            icon="custom_notes"
            color={tint(0.6, COLORS.blue)}
            isOpen={isNotesOpen}
            data={data.notes.custom_notes}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <CustomNoteInfo data={record} />}
            recordLink={(record: any) => `/employees/${employeeID}/custom-notes/${record.id}`}
          />

          <RoadmapRecordRow
            title="Recovery Notes"
            icon="recovery_coaching"
            color={tint(0.6, COLORS.orange)}
            isOpen={isNotesOpen}
            data={data.notes.recovery_notes}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <RecoveryNoteInfo data={record} />}
            recordLink={(record: any) =>
              `${getClientLink(record.client)}/recovery-coaching/${RECOVERY_NOTES_SLUGS[record.subcategory]}/${record.id}`
            }
            startDateKey="dated_at"
          />

          {/* Clinical */}
          <GroupHeader
            title="Clinical"
            icon="clinical_department"
            isAllOpen={isClinicalOpen}
            onToggleOpen={() => {
              setIsClinicalOpen((c) => !c)
            }}
          />

          <RoadmapRecordRow
            title="Diagnoses"
            icon="diagnosis"
            color={tint(0.6, COLORS.vividBlue)}
            isOpen={isClinicalOpen}
            data={data.clinical.diagnoses}
            renderTitle={(record: any) => record.code}
            renderTooltip={(record: any) => <DiagnosisInfo data={record} />}
            recordLink={(record: any) => `${getClientLink(record.client)}/diagnoses/${record.id}`}
            startDateKey="from"
          />

          <RoadmapRecordRow
            title="Clinical Assessments"
            icon="clinical_assessments"
            color={tint(0.6, COLORS.vividBlue)}
            isOpen={isClinicalOpen}
            data={data.clinical.clinical_assessments}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <ClinicalAssessmentInfo data={record} />}
            recordLink={(record: any) => {
              const formattedVersion = !record.version || record.version === '1.0' ? '' : `-${getVersionSlug(record.version)}`

              return `${getClientLink(record.client)}/clinical-assessments/${CLINICAL_ASSESSMENTS_SLUGS[record.subcategory]}/${
                record.id
              }${formattedVersion}`
            }}
            startDateKey="dated_at"
          />

          <RoadmapRecordRow
            title="Treatment Plans"
            icon="treatment_plans"
            color={tint(0.6, COLORS.red)}
            isOpen={isClinicalOpen}
            data={data.clinical.treatment_plans}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <TreatmentPlanInfo data={record} />}
            recordLink={(record: any) => `${getClientLink(record.client)}/treatment-plans/${record.id}-${getVersionSlug(record.version)}`}
            startDateKey="dated_at"
          />

          <RoadmapRecordRow
            title="Progress Reviews"
            icon="progress_reviews"
            color={tint(0.6, COLORS.violet)}
            isOpen={isClinicalOpen}
            data={data.clinical.progress_reviews}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <ProgressReviewInfo data={record} />}
            recordLink={(record: any) => `${getClientLink(record.client)}/progress-reviews/${record.id}`}
            startDateKey="dated_at"
          />

          <RoadmapRecordRow
            title="Clinical Notes"
            icon="clinical_notes"
            color={tint(0.6, COLORS.red)}
            isOpen={isClinicalOpen}
            data={data.clinical.clinical_notes}
            renderTitle={(record: any) => CLINICAL_NOTES_TITLES[record.category]}
            renderTooltip={(record: any) => <ClinicalNoteInfo data={record} />}
            recordLink={(record: any) => `/employees/${employeeID}/clinical-notes/all/${initials(record.category)}/${record.id}`}
          />

          <RoadmapRecordRow
            title="Clinical Measures"
            icon="clinical_measurements"
            color={tint(0.6, COLORS.vividBlue)}
            isOpen={isClinicalOpen}
            data={data.clinical.clinical_measures}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <ClinicalMeasureInfo data={record} />}
            recordLink={(record: any) =>
              `${getClientLink(record.client)}/clinical-measurements/${CLINICAL_MEASURES_SLUGS[record.subcategory]}/${record.id}`
            }
            startDateKey="dated_at"
          />

          <RoadmapRecordRow
            title="Outcome Measures"
            icon="outcome_measures"
            color={tint(0.6, COLORS.purple)}
            isOpen={isClinicalOpen}
            data={data.clinical.outcome_measures}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <OutcomeMeasureInfo data={record} />}
            recordLink={(record: any) =>
              `${getClientLink(record.client)}/outcome-measures/${OUTCOME_MEASURES_SLUGS[record.subcategory]}/${record.id}`
            }
            startDateKey="dated_at"
          />

          {/* Medical */}
          <GroupHeader
            title="Medical"
            icon="medical_department"
            isAllOpen={isMedicalOpen}
            onToggleOpen={() => {
              setIsMedicalOpen((c) => !c)
            }}
          />

          <RoadmapRecordRow
            title="UA Tests"
            icon="test_results"
            color={tint(0.6, COLORS.blue)}
            isOpen={isMedicalOpen}
            data={data.medical.ua_tests}
            renderTitle={(record: any) => `UA Test ${usDate(record.collected_at, timezone)}`}
            renderTooltip={(record: any) => <UATestInfo data={record} />}
            recordLink={(record: any) => `/employees/${employeeID}/test-results/ua-tests/${record.id}`}
            startDateKey="collected_at"
          />

          <RoadmapRecordRow
            title="Breathalyzer Tests"
            icon="test_results"
            color={tint(0.6, COLORS.blue)}
            isOpen={isMedicalOpen}
            data={data.medical.breathalyzer_tests}
            renderTitle={(record: any) => `Breathalyzer Test ${usDate(record.collected_at, timezone)}`}
            renderTooltip={(record: any) => <BreathalyzerTestInfo data={record} />}
            recordLink={(record: any) => `/employees/${employeeID}/test-results/breathalyzer-tests/${record.id}`}
            startDateKey="collected_at"
          />

          <RoadmapRecordRow
            title="Vital Signs"
            icon="test_results"
            color={tint(0.6, COLORS.blue)}
            isOpen={isMedicalOpen}
            data={data.medical.vital_signs}
            renderTitle={(record: any) => `Vital Signs ${usDate(record.recorded_at, timezone)}`}
            renderTooltip={(record: any) => <VitalSignInfo data={record} />}
            recordLink={(record: any) => `/employees/${employeeID}/test-results/vital-signs/${record.id}`}
            startDateKey="recorded_at"
          />

          <RoadmapRecordRow
            title="Communicable Disease Tests"
            icon="test_results"
            color={tint(0.6, COLORS.blue)}
            isOpen={isMedicalOpen}
            data={data.medical.communicable_disease_tests}
            renderTitle={(record: any) => COMMUNICABLE_DISEASE_TESTS[record.category]}
            renderTooltip={(record: any) => <CommunicableDiseaseTestInfo data={record} />}
            recordLink={(record: any) => `/employees/${employeeID}/test-results/communicable-disease-tests/${record.id}`}
            startDateKey="recorded_at"
          />

          <RoadmapRecordRow
            title="Labs"
            icon="test_results"
            color={tint(0.6, COLORS.blue)}
            isOpen={isMedicalOpen}
            data={data.medical.labs}
            renderTitle={(record: any) => `Lab Result ${usDate(record.recorded_at, timezone)}`}
            renderTooltip={(record: any) => <LabInfo data={record} />}
            recordLink={(record: any) => `/employees/${employeeID}/test-results/labs/${record.id}`}
            startDateKey="recorded_at"
          />

          {/* <RoadmapRecordRow
            title="Medications"
            icon="medications"
            color={tint(0.6, COLORS.red)}
            isOpen={isMedicalOpen}
            data={data.medical.meds}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <MedicationInfo data={record} />}
            // recordLink={(record: any) => `${getClientLink(record.client)}/__SLUG_TODO__/${record.id}`}
          /> */}

          <RoadmapRecordRow
            title="Medication Logs"
            icon="med_pass"
            color={tint(0.6, COLORS.red)}
            isOpen={isMedicalOpen}
            data={data.medical.medication_logs}
            renderTitle={(record: any) => record.med?.name || 'Medication Log'}
            renderTooltip={(record: any) => <MedicationLogInfo data={record} />}
            recordLink={(record: any) => `${getClientLink(record.client)}/medications/logs/${record.id}`}
            startDateKey="administered_at"
          />

          <RoadmapRecordRow
            title="Medical Assessments"
            icon="medical_assessments"
            color={tint(0.6, COLORS.red)}
            isOpen={isMedicalOpen}
            data={data.medical.medical_assesments}
            renderTitle={(record: any) => record.name || 'Medical Assessment'}
            renderTooltip={(record: any) => <MedicalAssessmentInfo data={record} />}
            recordLink={(record: any) =>
              `${getClientLink(record.client)}/medical-assessments/${MEDICAL_ASSESSMENTS_SLUGS[record.subcategory]}/${record.id}`
            }
            startDateKey="dated_at"
          />

          <RoadmapRecordRow
            title="Physician Orders"
            icon="physician_orders"
            color={tint(0.6, COLORS.red)}
            isOpen={isMedicalOpen}
            data={data.medical.physician_orders}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <PhysicianOrderInfo data={record} />}
            recordLink={(record: any) => `${getClientLink(record.client)}/physician-orders/${record.id}`}
            startDateKey="dated_at"
          />

          <RoadmapRecordRow
            title="Nursing Assessments"
            icon="nursing"
            color={tint(0.6, COLORS.red)}
            isOpen={isMedicalOpen}
            data={data.medical.nursing_assessments}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <NursingAssessmentInfo data={record} />}
            recordLink={(record: any) => `${getClientLink(record.client)}/${NURSING_SLUGS[record.subcategory]}/${record.id}`}
            startDateKey="dated_at"
          />

          <RoadmapRecordRow
            title="Progress Notes"
            icon="progress_notes"
            color={tint(0.6, COLORS.violet)}
            isOpen={isMedicalOpen}
            data={data.medical.progress_notes}
            renderTitle={(record: any) => record.name}
            renderTooltip={(record: any) => <ProgressNoteInfo data={record} />}
            recordLink={(record: any) => `${getClientLink(record.client)}/progress-notes/${record.id}`}
            startDateKey="dated_at"
          />
        </>
      )}
    </Roadmap>
  )
}
