import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { Button, Card, Page, Grid } from '@behavehealth/components'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { BedOccupanciesTable } from '@behavehealth/components/Tables/BedOccupanciesTable'

const Occupancies = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const queryParams = { category: 'bed_management' }

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'occupancies', queryParams],
    url: `/residents/${resource_id}/occupancies`,
    params: queryParams,
  })

  return (
    <Page
      feature="occupancies"
      isLoading={isLoading}
      actions={
        <Button
          label="Add Bed Assignment"
          link={{
            pathname: `${match.url}/new`,
            data: { reference: client },
          }}
          glyph="add"
          type="primary"
          permission="bed_management.create"
        />
      }
    >
      <Grid>
        <Card>
          <BedOccupanciesTable data={data} to={(id: string) => `${match.url}/${id}`} />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(Occupancies)
