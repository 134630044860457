import React from 'react'
import size from 'lodash/size'
import { tint } from 'polished'

import 'emoji-mart/css/emoji-mart.css'

import { COLORS, SHADOW } from '../../theme'

import Button from '../Button'
import EmojiPicker from '../EmojiPicker'
import Textarea from '../Forms/Textarea'

const MessageInput = ({ allowRecords, allowPoll, isLoading, onSubmit }: any) => {
  const [messageType, setMessageType]: any = React.useState('text')
  const [files, setFiles]: any = React.useState([])
  const [records, setRecords]: any = React.useState(null)
  const [isRecordsOpen, setIsRecordsOpen]: any = React.useState(false)
  const [message, setMessage] = React.useState('')

  const addEmoji = (emoji) => {
    setMessage(`${message}${emoji.native}`)
  }

  // Poll
  // const createPoll = () => setMessageType('poll')
  // const cancelPoll = () => setMessageType('text')

  // Files
  // const attachFiles = () => {
  //   setMessageType('file')
  //   setFiles(
  //     [1, 2, 3, 4, 5].map((id) => {
  //       return {
  //         id,
  //         name: 'Screenshot 2020-11-06 at 10.53.07',
  //       }
  //     }),
  //   )
  // }

  // const removeFile = (id) => {
  //   setFiles((current) => current.filter((file) => file.id !== id))
  //   if (files.length === 1) setMessageType('text')
  // }

  // Records
  // const closeRecordsOverlay = () => setIsRecordsOpen(false)
  // const openRecordsOverlay = () => setIsRecordsOpen(true)

  // const attachRecords = (formData: any) => {
  //   setMessageType('records')
  //   setRecords(formData)
  // }

  // const cancelRecords = () => {
  //   setRecords(null)
  //   setMessageType('text')
  // }

  const didAttachFiles = size(files) >= 1

  const onKeyPress = (event: any) => {
    // Control + Enter
    if (event.ctrlKey && event.key === 'Enter') {
      submit()
    }
  }

  const submit = async () => {
    await onSubmit({ message: message, category: messageType })
    setMessage('')
  }

  return (
    <div css={styles.root}>
      <div css={styles.main}>
        <Textarea
          maxWidth="100%"
          model="message"
          placeholder="Write a message…"
          value={message}
          onUpdate={(state: any) => setMessage(state.value)}
          onKeyPress={onKeyPress}
          minRows={3}
          css={styles.textareaField}
        />

        {/* {messageType === 'file' && didAttachFiles && (
          <Flex gap={6}>
            {files.map((file) => (
              <Card key={file.id} css={styles.file}>
                <Flex nowrap alignItems="center">
                  <Glyph glyph="attachment" color={COLORS.blue} size={18} />
                  <div css={styles.fileName}>{file.name}</div>
                  <ClearButton onClick={() => removeFile(file.id)} css={{ position: 'static' }} />
                </Flex>
              </Card>
            ))}
          </Flex>
        )} */}

        {/* {allowPoll && messageType === 'poll' && (
          <Grid columns="1fr min-content">
            <div>
              <b>Poll: </b>
              get quick <Status label="Yes" color="green" inline /> / <Status label="No" color="red" inline /> answers to your question.
            </div>

            <ClearButton onClick={cancelPoll} css={{ position: 'static', marginLeft: 'auto' }} />
          </Grid>
        )} */}

        {/* {records && allowRecords && messageType === 'records' && (
          <Grid columns="1fr min-content">
            <Flex alignItems="center">
              <Avatar initials={records.client?.name} size={24} />
              <div css={{ marginLeft: '0.25rem' }}>
                <b>{records.client?.name}'s Records: </b> <span>2 Shift Notes, 1 Clinical Note</span>
              </div>
            </Flex>

            <ClearButton onClick={cancelRecords} css={{ position: 'static', marginLeft: 'auto' }} />
          </Grid>
        )} */}

        <div css={styles.footer}>
          <div css={styles.footerLeft}>
            <EmojiPicker onAddEmoji={addEmoji}>
              <Button hideLabel type="minimal" glyphColor={COLORS.blue} glyph="emoji" color="text" size={200} />
            </EmojiPicker>

            {/* {allowPoll && <Tooltip content="Add Poll Question…">
              <Button hideLabel type="minimal" glyphColor={COLORS.blue} glyph="question" color="text" size={300} onClick={createPoll} />
            </Tooltip>}

            {allowRecords && <Tooltip content="Attach EHR Records">
              <Button
                hideLabel
                type="minimal"
                glyphColor={COLORS.blue}
                glyph="add_file"
                color="text"
                size={300}
                onClick={openRecordsOverlay}
              />
            </Tooltip>}

            <Tooltip content="Attach Files…">
              <Button hideLabel type="minimal" glyphColor={COLORS.blue} glyph="attachment" color="text" size={300} onClick={attachFiles} />
            </Tooltip>  */}
          </div>

          <div css={styles.footerRight}>
            <Button
              label="Send (Ctrl + Enter)"
              type="primary"
              glyph="paper_plane"
              size={200}
              onClick={submit}
              isDisabled={!message}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>

      {/* <SummonOverlay isOpen={isRecordsOpen} onClose={closeRecordsOverlay} overlay={<RecordsOverlay onAttach={attachRecords} />} /> */}
    </div>
  )
}

const styles = {
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '0.8rem',
    paddingBottom: 75,
    background: `linear-gradient(0deg, ${tint(0.25, COLORS.lightBackground)} 50%, ${COLORS.transparent})`,

    '@media (min-width: 600px)': {
      paddingTop: 0,
      paddingBottom: '0.8rem',
      position: 'static',
      width: 'auto',
    },
  },

  main: {
    borderRadius: 7,
    background: COLORS.white,
    boxShadow: SHADOW(3),
    border: `1px solid ${COLORS.divider}`,
    maxWidth: 1000,
    margin: '0 auto',
    overflow: 'hidden',
  },

  textareaField: {
    border: 'none !important',
    boxShadow: 'none !important',

    textarea: {
      padding: '0.8rem',
      border: 'none !important',
      boxShadow: 'none !important',
      borderRadius: '0 !important',
    },
  },

  footer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '0.5rem',
    borderTop: `1px solid ${tint(0.08, COLORS.divider)}`,
  },

  footerLeft: {
    display: 'flex',
    flex: '999 1 auto',
  },

  footerRight: {
    flex: '1 1 auto',
  },

  file: {
    position: 'relative',
    padding: '0.2rem 0.4rem',
  },

  fileName: {
    maxWidth: 160,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: '0.25rem',
    color: COLORS.textMuted,
    fontWeight: 500,
    fontSize: '0.9rem',
  },

  record: {
    position: 'relative',
    padding: '0.2rem 0.4rem',
  },

  recordName: {
    maxWidth: 160,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: '0.25rem',
    color: COLORS.textMuted,
    fontWeight: 500,
    fontSize: '0.9rem',
  },
}

export default MessageInput
