import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { COLORS } from '../../theme'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { DEFAULT_FILTERS } from '../Filters/constants'

export const NotificationConfigsDataTable = (props) => {
  const { to, onClick, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return (
            <MainCell
              as={NavLink}
              id={data.id}
              to={to ? to(data) : undefined}
              onClick={onClick ? () => onClick(data) : undefined}
              value={value}
              glyph={props.glyph}
              glyphColor={COLORS.text}
            />
          )
        },
      },
      {
        title: 'Tags',
        model: 'global_tags',
        type: 'global_tags',
        editPermission: true,
      },
      {
        title: 'Description',
        model: 'description',
        type: 'rich_text',
        width: 300,
      },
      {
        title: 'Category',
        model: 'category',
        type: 'title',
        disableSort: true,
      },
      {
        title: 'Record Type',
        model: 'record_type',
        type: 'title',
        disableSort: true,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
        disableSort: true,
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
        disableSort: true,
      },
    ],
    [],
  )

  return (
    <DataTable asCard title="Notification Configs" icon="notification_configs" filtersConfig={FILTERS_CONFIG} columns={columns} {...rest} />
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  global_tags: {
    label: 'Tags',
    type: 'global_tags',
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  category: {
    label: 'Category',
    type: 'multi_select',
    options: [{ label: 'Internal Billing', value: 'internal_billing' }],
  },
  record_type: {
    label: 'Record Type',
    type: 'multi_select',
    options: [{ label: 'Invoice', value: 'invoice' }],
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
