import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { countWord } from '../../../utils/functions'
import useAPI from '../../../hooks/useAPI'

import Button from '../../Button'
import Card from '../../Card'
import Overlay from '../../Overlay'
import Section from '../../Section'
import SummonOverlay from '../../SummonOverlay'
import Tabs from '../../Tabs'

import TemplateLegalTooltip from '../TemplateLegalTooltip'
import CustomNoteInternalTemplatesTable from '../tables/internal/CustomNoteInternalTemplatesTable'
import ClientCustomNoteTemplateOverlay from './ClientCustomNoteTemplateOverlay'

import { apiCreate, apiGet } from '../../../modules/api'

const HIDDEN_COLUMNS = ['global_tags', 'smart_flags']

const ClientCustomNoteTemplatesImportOverlay = () => {
  const navigate = useNavigate()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [preview, setPreview]: any = React.useState(null)
  const [loading, setLoading]: any = React.useState(false)
  const [selectedType, setSelectedType]: any = React.useState('public_template')

  const user = useSelector((state: any) => state.me?.user)
  const data = useSelector((state: any) => state.data?.internal_client_custom_note_templates?.data)
  const dataLoading = useSelector((state: any) => state.data?.internal_client_custom_note_templates?.loading)
  const isEmpty = size(data) === 0

  const isBehave = user?.type === 'bh_employee'

  useAPI(
    'internal_client_custom_note_templates',
    `/internal_templates?category=custom_note&subcategory=client&level=${selectedType}&status=active`,
  )

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    setLoading(true)

    try {
      const ids = selectedRows.map((o: any) => o.original.id)
      await apiCreate({
        url: '/internal_templates/import',
        params: { ids: ids, category: 'custom_note', subcategory: 'client' },
      })
      await apiGet({ name: 'custom_note_templates', url: '/custom_note_templates?variant=client' })

      navigate(-1)
    } catch (errors) {
      console.debug(errors)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import Client Custom Note Templates" titleAside={<TemplateLegalTooltip />} />

      <Overlay.Content>
        {preview && (
          <SummonOverlay
            isOpen={!!preview}
            onClose={() => setPreview(null)}
            overlay={<ClientCustomNoteTemplateOverlay allowEditing={false} dataID={preview.id} initialData={preview.data} />}
          />
        )}

        <Section margin="0 0 1.5rem 0">
          {isBehave && (
            <Tabs onChange={setSelectedType} defaultTab="public_template">
              <Tabs.List>
                <Tabs.Item label="Public Templates" name="public_template" />
                <Tabs.Item glyph="behave_health" label="Behave Templates" name="behave_template" />
              </Tabs.List>
            </Tabs>
          )}

          <Card className="!mt-4">
            <CustomNoteInternalTemplatesTable
              title="Client Custom Note Templates"
              data={data}
              isLoading={isEmpty && dataLoading}
              onSelect={setSelectedRows}
              onClick={setPreview}
              hiddenColumns={!isBehave && HIDDEN_COLUMNS}
            />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Templates', selectedRowsCount)}` : 'Select Templates to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={loading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default ClientCustomNoteTemplatesImportOverlay
