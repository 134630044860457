import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect, useLocation, useRouteMatch } from 'react-router-dom'

import { LocalPayer } from '@behavehealth/declarations'

import { Button, Grid, Chotomate, Page, Tabs, TabList, TabPanels, TabPanel, Tab, HelpTagIframe } from '@behavehealth/components'

import FacilityContacts from './contacts/facility'
import PublicContacts from './contacts/public'

const Contacts: React.FC = () => {
  const { pathname } = useLocation()
  const { url, params } = useRouteMatch()

  const loading = useSelector((state) => state.data.contacts?.loading)
  const payer: LocalPayer = useSelector((state) => state.data.insurance_local_payers?.data?.[params?.resource_id])

  const actions = (
    <Button
      label="Add Contact"
      type="primary"
      glyph="add"
      link={{
        pathname: `${pathname}/new`,
        data: {
          payers: [payer],
        },
      }}
    />
  )

  return (
    <Page feature="contacts" actions={actions} help={<HelpTagIframe id="payer_contacts" />}>
      <Chotomate name="contacts" ready={!loading} />

      <Grid gap={16}>
        <Tabs>
          <TabList>
            <Tab label="Facility Contacts" to={`${url}/facility`} />
            <Tab label="Public Contacts" to={`${url}/public`} />
          </TabList>
        </Tabs>

        <TabPanels>
          <TabPanel>
            <Switch>
              <Route path="/:resource_type/:resource_id/contacts/facility" component={FacilityContacts} />
              <Route path="/:resource_type/:resource_id/contacts/public" component={PublicContacts} />

              <Redirect exact from={url} to={`${url}/facility`} />
            </Switch>
          </TabPanel>
        </TabPanels>
      </Grid>
    </Page>
  )
}

export default Contacts
