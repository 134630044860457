import React from 'react'
import { useParams } from 'react-router-dom'

import { MEDICATION_TIMES, SLUG_RESOURCE_TYPES } from '../../utils/constants'
import { titleCase } from '../../utils/functions'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import ContextShow from '../../components/Forms/ContextShow'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MedicationDescriptionSelect from '../../components/Forms/elements/MedicationDescriptionSelect'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'

const RootMedicationOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'med',
    endpoint: '/meds',
    invalidate: 'meds',
    options: props,
    onSaveSuccessful: props.onSaveSuccessful,
    onDeleteSuccessful: props.onDeleteSuccessful,
  })

  const { resource, resource_id }: any = useParams()
  const { isPortal, timezone, tenant } = useSettings()

  const isTrialing = tenant?.plan_status === 'trialing'
  const isResident = SLUG_RESOURCE_TYPES?.[resource] === 'resident'

  const [isNotesRequired, setIsNotesRequired] = React.useState(false)

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
    options: { enabled: !!resource_id && isResident },
  })

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header title="Medication" icon="medications" />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/meds/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && client && { resident_id: client.id }}
        >
          <Section>
            <FormSection layout="vertical">
              <OverlaySelector
                isEditable={isNew}
                initialModelRequired={isNew}
                model="resident"
                label="Client"
                type="clients.admissions_and_current"
                icon="clients"
                showDescription={false}
                disableLink={isPortal}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
                description="We load clients from Applicants, Accepted and Current Clients"
              />

              <Flex gap={16} stretchChildrenX>
                <Select
                  fullWidth
                  label="Status"
                  model="status"
                  defaultValue="active"
                  validations={{
                    presence: {
                      message: 'Please select a status',
                    },
                  }}
                >
                  <Option label="Active" value="active" />
                  <Option label="Inactive" value="inactive" />
                </Select>

                <Select
                  fullWidth
                  label="Source"
                  model="source"
                  defaultValue="internal"
                  validations={{
                    presence: {
                      message: 'Please select a source',
                    },
                  }}
                >
                  <Option label={tenant.name} value="internal" />
                  <Option label="eRX (Dr.First)" value="erx" />
                  <Option label="External Provider (during treatment)" value="external_during" />
                  <Option label="External Provider (prior to treatment)" value="external_prior" />
                  <Option label="Private Providers" value="private_provider" />
                </Select>
              </Flex>

              <Input
                model="name"
                label="Medication Name"
                validations={{
                  presence: {
                    message: 'Please enter a medication name',
                  },
                }}
              />

              <Flex gap={16} stretchChildrenX>
                <MedicationDescriptionSelect
                  label="Medication Description"
                  model="description"
                  validations={{
                    presence: {
                      message: 'Please select a description',
                    },
                  }}
                />
              </Flex>

              <Flex gap={8} stretchChildrenX>
                <Input
                  model="dosage"
                  label="Dosage"
                  type="number"
                  step="0.001"
                  min="0"
                  validations={{
                    presence: {
                      message: 'Please enter a dosage',
                    },
                  }}
                />

                <Input
                  model="unit"
                  label="Unit Type"
                  labelDescription="(eg. mg, g, mL)"
                  validations={{
                    presence: {
                      message: 'Please enter a unit',
                    },
                  }}
                />

                <Input
                  model="quantity"
                  label="Quantity"
                  type="number"
                  size={5}
                  validations={{
                    presence: {
                      message: 'Please enter a quantity',
                    },
                  }}
                />
              </Flex>

              <Input
                label="Standard Dose Quantity"
                model="default_quantity"
                description={
                  isEditable &&
                  'This is the default quantity that will be administered to the client. Used as reference when logging medications.'
                }
                placeholder="e.g. 1-2 pills by mouth every 4 hours"
              />

              <ContextShow when="dates_unknown" is={false}>
                <Flex gap={8} stretchChildrenX horizontal>
                  <DateInput
                    defaultToNow
                    model="started_at"
                    label="Start Date"
                    inputGroupMaxWidth={false}
                    validations={{
                      presence: {
                        message: 'Please enter a start date',
                      },
                    }}
                  />

                  <DateInput
                    model="ended_at"
                    label="End Date"
                    inputGroupMaxWidth={false}
                    validations={{
                      presence: {
                        message: 'Please enter an end date',
                      },
                    }}
                  />
                </Flex>
              </ContextShow>

              <CheckboxGroup layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
                <Checkbox label="Start / End Date Unknown" model="dates_unknown" />
              </CheckboxGroup>

              <CheckboxGroup label="Medication Time" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
                {Object.entries(MEDICATION_TIMES).map(([key, value]) => (
                  <Checkbox
                    testKey={`${key}_checkbox`}
                    label={value}
                    model={`medication_time.${key}`}
                    onUpdate={(o) => {
                      if (o.model === 'medication_time.prn') {
                        setIsNotesRequired(o.value)
                      }
                    }}
                  />
                ))}
              </CheckboxGroup>

              <Flex gap={8}>
                <Input label="Prescription #" model="prescription_no" />
                <Input className="!flex-auto" label="Prescribing Physician" model="prescription_by" />

                {/* <ObjectSelector
                  className="!flex-auto"
                  label="Prescribing Physician"
                  model="prescribed_by"
                  type="employees"
                  icon="employees"
                  selectTitle={(data: any) => data.name}
                  selectDescription={(data: any) => titleCase(data.position)}
                /> */}
              </Flex>

              {isNotesRequired ? (
                <Textarea
                  useQuickText
                  key="notes-required"
                  model="notes"
                  label="Instructions / Notes"
                  validations={{
                    presence: {
                      message: 'Please enter a note',
                    },
                  }}
                />
              ) : (
                <Textarea useQuickText key="notes-not-required" model="notes" label="Instructions / Notes" />
              )}

              <Textarea useQuickText model="refill" label="Refill Notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this medication">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} disableLinks={isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {!isPortal && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission={isNew ? 'medications.create' : 'medications.edit'}
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="medications.edit"
              />

              {!props.hideDelete && (
                <DeleteDialog
                  title="Delete Medication?"
                  message="Are you sure you want to delete this medication? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button
                    glyph="delete"
                    label="Delete"
                    type="default"
                    color="red"
                    isLoading={isDeleting}
                    fullWidth
                    permission="medications.delete"
                  />
                </DeleteDialog>
              )}
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const MedicationOverlay = withOverlayError(RootMedicationOverlay)
