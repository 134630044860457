import React from 'react'

import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const RootASILiteOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="ASI The Addiction Severity Index - Lite"
      category="clinical_measurement"
      subcategory="asi_lite"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <p>
          I'm going to ask you some questions about your alcohol and other drug use and any problems you may have had in these areas. I
          would like to remind you that the information you give me is confidential, and will only be used for research purposes.
        </p>

        <p>
          For the following questions, the time frames will be for two different periods; for the{' '}
          <b>past 30 days prior to treatment entry</b> and <b>in your lifetime</b>. For lifetime use I am interested in the number of years
          that you used 3 or more times per week.
        </p>
      </Section>

      <Divider />

      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <FormSection>
          <h3>1. Alcohol - any use at all</h3>
          <Input
            label="In the past 30 days, how many days would you have used alcohol (any use at all)?"
            model="data.alcohol_any_use.last_month_use"
          />
          <Input
            label="In you lifetime, how many years would you have used alcohol (any use at all)?"
            model="data.alcohol_any_use.lifetime_use"
          />
          <Select label="How have you most commonly used alcohol (any use at all)?" model="data.alcohol_any_use.administration_route">
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>2. Alcohol - to Intoxication</h3>
          <Input
            label="In the past 30 days, how many days would you have used alcohol (to intoxication)?"
            model="data.alcohol_to_intoxication.last_month_use"
          />
          <Select
            label="How have you most commonly used alcohol (to intoxication)?"
            model="data.alcohol_to_intoxication.administration_route"
          >
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>3. Heroin</h3>
          <Input label="In the past 30 days, how many days would you have used heroin?" model="data.heroin.last_month_use" />
          <Input label="In you lifetime, how many years would you have used heroin?" model="data.heroin.lifetime_use" />
          <Select label="How have you most commonly used heroin?" model="data.heroin.administration_route">
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>4. Methadone (illicit)</h3>
          <Input
            label="In the past 30 days, how many days would you have used methadone (illicit)?"
            model="data.methadone_illicit.last_month_use"
          />
          <Input
            label="In you lifetime, how many years would you have used methadone (illicit)?"
            model="data.methadone_illicit.lifetime_use"
          />
          <Select label="How have you most commonly used methadone (illicit)?" model="data.methadone_illicit.administration_route">
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>5. Other opiates/analgesics</h3>
          <Input
            label="In the past 30 days, how many days would you have used other opiates/analgesics?"
            model="data.other_opiates_analgesics.last_month_use"
          />
          <Input
            label="In you lifetime, how many years would you have used other opiates/analgesics?"
            model="data.other_opiates_analgesics.lifetime_use"
          />
          <Select
            label="How have you most commonly used other opiates/analgesics?"
            model="data.other_opiates_analgesics.administration_route"
          >
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>6. Barbiturates</h3>
          <Input label="In the past 30 days, how many days would you have used barbiturates?" model="data.barbiturates.last_month_use" />
          <Input label="In you lifetime, how many years would you have used barbiturates?" model="data.barbiturates.lifetime_use" />
          <Select label="How have you most commonly used barbiturates?" model="data.barbiturates.administration_route">
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>7. Other sedatives, hypnotics, tranquillisers</h3>
          <Input
            label="In the past 30 days, how many days would you have used other sedatives, hypnotics, tranquillisers?"
            model="data.other_sedatives_hypnotics_tranquillisers.last_month_use"
          />
          <Input
            label="In you lifetime, how many years would you have used other sedatives, hypnotics, tranquillisers?"
            model="data.other_sedatives_hypnotics_tranquillisers.lifetime_use"
          />
          <Select
            label="How have you most commonly used other sedatives, hypnotics, tranquillisers?"
            model="data.other_sedatives_hypnotics_tranquillisers.administration_route"
          >
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>8. Cocaine</h3>
          <Input label="In the past 30 days, how many days would you have used cocaine?" model="data.cocaine.last_month_use" />
          <Input label="In you lifetime, how many years would you have used cocaine?" model="data.cocaine.lifetime_use" />
          <Select label="How have you most commonly used cocaine?" model="data.cocaine.administration_route">
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>9. Amphetamines</h3>
          <Input label="In the past 30 days, how many days would you have used amphetamines?" model="data.amphetamines.last_month_use" />
          <Input label="In you lifetime, how many years would you have used amphetamines?" model="data.amphetamines.lifetime_use" />
          <Select label="How have you most commonly used amphetamines?" model="data.amphetamines.administration_route">
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>10. Cannabis</h3>
          <Input label="In the past 30 days, how many days would you have used cannabis?" model="data.cannabis.last_month_use" />
          <Input label="In you lifetime, how many years would you have used cannabis?" model="data.cannabis.lifetime_use" />
          <Select label="How have you most commonly used cannabis?" model="data.cannabis.administration_route">
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>11. Hallucinogens</h3>
          <Input label="In the past 30 days, how many days would you have used hallucinogens?" model="data.hallucinogens.last_month_use" />
          <Input label="In you lifetime, how many years would you have used hallucinogens?" model="data.hallucinogens.lifetime_use" />
          <Select label="How have you most commonly used hallucinogens?" model="data.hallucinogens.administration_route">
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>12. Inhalants</h3>
          <Input label="In the past 30 days, how many days would you have used inhalants?" model="data.inhalants.last_month_use" />
          <Input label="In you lifetime, how many years would you have used inhalants?" model="data.inhalants.lifetime_use" />
          <Select label="How have you most commonly used inhalants?" model="data.inhalants.administration_route">
            <Option label="1 = Oral" value={1} />
            <Option label="2 = Nasal" value={2} />
            <Option label="3 = Smoking" value={3} />
            <Option label="4 = Non IV injection" value={4} />
            <Option label="5 = IV injection" value={5} />
            <Option label="9 = Never Used" value={9} />
          </Select>

          <Divider />

          <h3>13. More than one substance per day (include alcohol)</h3>
          <Input
            label="In the past 30 days, how many days would you have used more than one substance per day (include alcohol)?"
            model="data.more_than_one_substance_per_day.last_month_use"
          />
          <Input
            label="In you lifetime, how many years would you have used more than one substance per day (include alcohol)?"
            model="data.more_than_one_substance_per_day.lifetime_use"
          />
        </FormSection>
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <p>
          <b>Adapted from:</b> McLellan, AT, Luborsky, L, Woody, GE, O'Brien CP (1980). An improved diagnostic evaluation instrument for
          substance abuse patients. The Addiction Severity Index. Journal of Nervous and Mental Disease, 168(1): 26-33.
        </p>
      </Section>
    </DataFormOverlay>
  )
}

export const ASILiteOverlay = withOverlayError(RootASILiteOverlay)
