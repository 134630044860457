import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useDataForms } from '@behavehealth/hooks/useDataForms'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { Chotomate, Button, Card, Grid, Page, HelpTagIframe, Link } from '@behavehealth/components'
import { ProgressReviewsTable } from '@behavehealth/components/Tables'
import { DataFormAddDropdown } from '@behavehealth/constructs/DataFormsBuilder/DataFormAddDropdown'

import { Filters } from '@behavehealth/constructs/Filters/Filters'
import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { getVersionSlug } from '@behavehealth/utils/functions.js'

const pageConfig = {
  feature: 'progress_reviews',
  help: <HelpTagIframe id="progress_reviews" />,
  marketingID: 'progress_reviews',
}

type Props = {
  canCreate: boolean
}

const ProgressReviews: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()

  const { data, isEmpty, isLoading, batchDelete, setFilters } = useDataForms({ category: 'progress_review' })
  const { isBehave } = useSettings()

  const actions = (
    <>
      <DataFormAddDropdown category="progress_review" />

      {/* <Button
        label="Add Progress Review"
        glyph="add"
        type="primary"
        link={{
          pathname: `${match.url}/new`,
          parent: match,
        }}
        permission="progress_reviews.create"
      /> */}
    </>
  )

  return (
    <Page actions={!isEmpty && canCreate && actions} {...pageConfig}>
      <Grid gap="1rem" columns="100%">
        <Chotomate name="progress_reviews" ready={!isLoading} />

        <Filters config={FILTERS.progress_reviews} onUpdate={setFilters} localStorageKey="progress_reviews" />

        <Card testKey="progress_reviews_table">
          <ProgressReviewsTable
            data={data}
            isLoading={isLoading}
            emptyActions={canCreate && actions}
            isBehave={isBehave}
            localStorageKey="clients_progress_reviews"
            to={(record) => {
              const { id, version } = record

              const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

              return {
                pathname: `${match.url}${version === '3.0' ? '/progress-review' : ''}/${id}${formattedVersion}`,
                parent: match,
              }
            }}
            titleAfter={<Link to="/reports/progress-reviews">View Full Report →</Link>}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'progress_reviews.delete',
                action: batchDelete,
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ProgressReviews, pageConfig))
