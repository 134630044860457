import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { address } from '../../../utils/functions'
import { COLORS } from '../../../theme'
import { useFormField } from '../../Forms/hooks/useFormField'
import { useStore } from '../utils/useStore'
import { withFormContext } from '../../Forms/context'

import { PopoverMenu, CustomPopoverMenuItem } from '../../../components/PopoverMenu'
import Glyph from '../../../components/Glyph'
import SummonOverlay from '../../../components/SummonOverlay'

import AddressSelectorOverlay from '../../../components/Overlays/actions/AddressSelectorOverlay'
import ManualAddressEditOverlay from '../../../components/Overlays/actions/ManualAddressEditOverlay'

export const RootAddressCell = (props: any) => {
  const { canEdit, form, isEditing, model, onBlur, setValue, validations, value } = props

  const { formActions, formState } = useFormField({
    model: model,
    form: form,
    validations: validations,
    isValid: size(validations) === 0,
  })

  const { isInvalid } = formState

  const updateField: any = useStore((state: any) => state.updateField)
  const showInvalid: any = useStore((state: any) => state.showInvalid)

  const [isOpen, setIsOpen] = React.useState(isEditing)
  const [openOverlay, setOpenOverlay]: any = React.useState(null)

  const handleSelectAddress = (address: any) => {
    if (!address) return

    const result = {
      address_line_1: `${address.address} ${address.text}`,
      address_line_2: address.addressLine2,
      city: address?.context?.place?.text,
      state: address?.context?.region?.short_code.split('-')[1],
      zip_code: address?.context?.postcode?.text,
      country: 'USA',
      coords: {
        lat: address.center[1],
        lon: address.center[0],
      },
    }

    setValue(result)
  }

  const handleOverlayClose = () => {
    setOpenOverlay(null)
    setIsOpen(false)
    onBlur()
  }

  // Sync props value with state value
  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  // Update form state
  React.useEffect(() => {
    if (isUndefined(value)) return

    formActions.setValue(value)
    updateField({ model, value })
  }, [model, value])

  const triggerClasses = clsx(
    size(value) === 0 && 'is-empty',
    // isEditing && 'is-editing',
    isInvalid && showInvalid && 'is-invalid',
  )

  const displayValueClasses = clsx(size(value) === 0 && 'is-empty', isEditing && 'is-editing', isInvalid && showInvalid && 'is-invalid')

  return (
    <>
      <PopoverMenu
        isOpen={isOpen}
        trigger={
          <div className={triggerClasses} css={STYLES.trigger}>
            {canEdit && <Glyph glyph="triangle_down" size={9} className="select-trigger-triangle" />}
          </div>
        }
        onOpenUpdated={(currentIsOpen: boolean) => {
          setIsOpen(currentIsOpen)
          if (!currentIsOpen) onBlur()
        }}
      >
        <SelectItem
          label="Find Address…"
          glyph="map"
          color="blue"
          onClick={() => {
            setOpenOverlay('address')
          }}
        />
        <SelectItem
          label="Edit Manually…"
          glyph="edit"
          color="gray"
          onClick={() => {
            setOpenOverlay('manual')
          }}
        />
      </PopoverMenu>

      {size(value) > 0 && (
        <div css={STYLES.displayValue} className={displayValueClasses}>
          {address(value)}
        </div>
      )}

      <SummonOverlay
        isOpen={openOverlay === 'address'}
        onClose={handleOverlayClose}
        overlay={<AddressSelectorOverlay afterSelect={handleSelectAddress} />}
      />

      <SummonOverlay
        isOpen={openOverlay === 'manual'}
        onClose={handleOverlayClose}
        overlay={<ManualAddressEditOverlay address={value} onUpdate={setValue} />}
      />
    </>
  )
}

const SelectItem = (props: any) => {
  const { label, isActive, onClick, color: propsColor, glyph = 'check' } = props

  const color = propsColor ? COLORS[propsColor] || propsColor : COLORS.green

  const style: any = React.useMemo(() => {
    return { '--highlight-color': tint(0.92, color) }
  }, [color])

  return (
    <CustomPopoverMenuItem style={style} css={STYLES.selectItem} isActive={isActive} onClick={onClick}>
      <div className="select-item-graphic">
        <Glyph glyph={glyph} size={10} color={color} />
      </div>

      <div>{label}</div>
    </CustomPopoverMenuItem>
  )
}

const STYLES = {
  trigger: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
    padding: '0.2rem',
    paddingLeft: '0.4rem',
    paddingRight: '1.2rem',
    overflow: 'hidden',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    '.select-trigger-triangle': {
      position: 'absolute',
      right: '0.25rem',
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },

    '&.is-empty': {
      fontStyle: 'italic',
      color: COLORS.textStronglyMuted,
    },

    '&.is-editing': {
      borderRadius: 3,
      background: COLORS.white,
      boxShadow: 'var(--input-focus-box-shadow)',
    },

    '&.is-invalid': {
      display: 'block',
      background: tint(0.85, COLORS.red),
    },
  },

  selectItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    fontSize: '0.88rem',
    width: '100%',
    lineHeight: 'normal',
    paddingTop: '0.4rem',
    paddingBottom: '0.4rem',
    paddingRight: '1rem',
    fontWeight: 600,

    '&:hover': {
      fontWeight: 600,
      background: COLORS.hover,
    },

    '.select-item-graphic': {
      width: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '&.is-active': {
      backgroundColor: 'var(--highlight-color)',

      '&:hover': {
        backgroundColor: 'var(--highlight-color)',
      },
    },
  },

  displayValue: {
    flex: '1 1 auto',
    paddingRight: '1rem',

    '.wrapping-wrap &': {
      display: 'grid',
      gridGap: '0.25rem',
    },

    '.wrapping-clip &': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '& > *': {
        marginLeft: '0.55rem',
      },
    },
  },
}

export const AddressCell: any = withFormContext(RootAddressCell)
