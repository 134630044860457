import pluralize from 'pluralize'
// import userflow from 'userflow.js'

import Sentry from '../vendors/sentry'
import LogRocket from '../vendors/logrocket'
import AnnounceKit from '../vendors/announce_kit'
import { titleCase } from '../utils/functions'

const DO_NOT_TRACK = [
  'ben.weiss@behavehealth.com',
  'robert.eles@behavehealth.com',
  'andrei.ciursa@behavehealth.com',
  'george.chkhvirkia@behavehealth.com',
  'jennifer.spangler@behavehealth.com',
]

class ThirdParty {
  constructor() {
    this.config = {}
    this.isBehave = false
  }

  initialize = async (opts = {}) => {
    this.config = opts

    if (opts.logrocket?.id) LogRocket.init(opts.logrocket.id, opts.logrocket.url, opts.version)
    // if (opts.userflow) userflow.init(opts.userflow)
    if (opts.announcekit) AnnounceKit.init(opts.announcekit)
    if (opts.sentry) Sentry.init(opts.sentry, opts.version)
    if (opts.helpscout && window.Beacon) window.Beacon('init', opts.helpscout)
  }

  identify = ({ user, tenant }) => {
    if (DO_NOT_TRACK.includes(user.email)) {
      this.isBehave = true
    }

    // LOGROCKET
    if (this.config.logrocket) {
      if (this.isBehave) {
        LogRocket.abortRocket()
      } else {
        LogRocket.getRocket().identify(user.external_id, {
          name: user.name,
          email: user.email,
          phone_no: user.phone_no,
          externalId: user.external_id,
          tenant: tenant?.subdomain,
          planStatus: tenant?.plan_status,
          company: tenant?.name,
        })
      }
    }

    // USERFLOW
    // if (this.config.userflow) {
    //   userflow.identify('behave-health', {
    //     name: user.name,
    //     email: user.email,
    //     position: user.position,
    //   })
    // }

    // SENTRY
    if (this.config.sentry) {
      Sentry.identify({
        id: user.external_id,
        email: user.email,
        phone_no: user.phone_no,
        tenant: tenant.subdomain,
      })
    }

    // HELPSCOUT
    if (this.config.helpscout) {
      if (this.config.logrocket && !this.isBehave) {
        LogRocket.getSessionURL((sessionURL: any) => {
          window.Beacon('identify', {
            ...(user.chat_hash && { signature: user.chat_hash }),
            name: user.name,
            email: user.email,
            Phone: user.phone_no,
            company: tenant.name,
            tenant: tenant.subdomain,
            'Plan Status': titleCase(tenant.plan_status),
            'User Link': `https://app.behavehealth.com/${pluralize(user.type)}/${user.id}/general-info`,
            sessionURL: sessionURL,
          })
        })
      } else {
        window.Beacon('identify', {
          ...(user.chat_hash && { signature: user.chat_hash }),
          name: user.name,
          email: user.email,
          Phone: user.phone_no,
          company: tenant.name,
          tenant: tenant.subdomain,
          'Plan Status': titleCase(tenant.plan_status),
          'User Link': `https://app.behavehealth.com/${pluralize(user.type)}/${user.id}/general-info`,
        })
      }
    }
  }

  getConfig = () => this.config
}

export default new ThirdParty()
