import { useReducer } from 'react'
import produce from 'immer'
import difference from 'lodash/difference'

type InitialState = {
  defaultStep?: string
  initialData?: any
  isValid?: boolean
  steps: string[]
}

const init = (initialState: InitialState) => {
  if (!initialState || !initialState.steps || initialState.steps.length === 0) {
    return {
      currentStep: null,
      data: {},
      hiddenSteps: [],
      isFirst: null,
      isLast: null,
      isValid: true,
      progress: 0,
      steps: [],
    }
  }

  const { steps, defaultStep, initialData = {}, isValid = true } = initialState

  let isFirst = true
  let isLast = false
  let currentStep = steps[0]

  if (defaultStep) {
    const idx = steps.indexOf(defaultStep)
    if (idx === -1) return

    currentStep = steps[idx]

    idx === 0 ? (isFirst = true) : (isFirst = false)
    idx === steps.length - 1 ? (isLast = true) : (isLast = false)
  }

  return {
    currentStep,
    data: initialData,
    defaultStep,
    hiddenSteps: [],
    isFirst,
    isLast,
    isValid,
    steps,
  }
}

const reducer = (state: any, action: any) => {
  const { steps, currentStep, hiddenSteps } = state

  const visibleSteps = difference(steps, hiddenSteps)
  const firstStep = visibleSteps[0]
  const lastStep = visibleSteps[steps.length - 1]
  const currentIndex = visibleSteps.indexOf(currentStep)

  switch (action.type) {
    case 'SET_DATA': {
      const { data } = action

      return produce(state, (draft: any) => {
        draft.data = {
          ...draft.data,
          ...data,
        }
      })
    }

    case 'SET_VALID': {
      const { valid } = action

      return produce(state, (draft: any) => {
        draft.isValid = valid
      })
    }

    case 'SET_ALL_STEPS': {
      const { steps } = action

      return produce(state, (draft: any) => {
        draft.steps = steps
        draft.progress = (((steps.indexOf(currentStep) + 1) / steps.length) * 100).toFixed()
      })
    }

    case 'SET_STEP': {
      const { step } = action

      if (!steps.includes(step)) return state

      return produce(state, (draft: any) => {
        draft.currentStep = step
        draft.isFirst = step === firstStep
        draft.isLast = step === lastStep
        draft.progress = (((steps.indexOf(step) + 1) / steps.length) * 100).toFixed()
      })
    }

    case 'GO_NEXT': {
      const nextStep = visibleSteps[currentIndex + 1]

      if (!nextStep) return state

      return produce(state, (draft: any) => {
        draft.currentStep = nextStep
        draft.isFirst = nextStep === firstStep
        draft.isLast = nextStep === lastStep
        draft.progress = (((steps.indexOf(nextStep) + 1) / steps.length) * 100).toFixed()
      })
    }

    case 'GO_BACK': {
      const prevStep = visibleSteps[currentIndex - 1]

      if (!prevStep) return state

      return produce(state, (draft: any) => {
        draft.currentStep = prevStep
        draft.isFirst = prevStep === firstStep
        draft.isLast = prevStep === lastStep
        draft.progress = (((steps.indexOf(prevStep) + 1) / steps.length) * 100).toFixed()
      })
    }

    case 'HIDE_STEP': {
      const { step } = action

      return produce(state, (draft: any) => {
        draft.hiddenSteps.push(step)
      })
    }

    case 'SHOW_STEP': {
      const { step } = action

      if (!hiddenSteps.includes(step)) return state

      const index = hiddenSteps?.indexOf(step)

      return produce(state, (draft: any) => {
        draft.hiddenSteps.splice(index, 1)
      })
    }

    default: {
      return state
    }
  }
}

export const useStepper = (initialState: InitialState) => {
  const [state, dispatch] = useReducer(reducer, initialState, init)

  const setData = (data: any) => dispatch({ type: 'SET_DATA', data })
  const setValid = (valid: boolean) => dispatch({ type: 'SET_VALID', valid })

  const setAllSteps = (steps: any[]) => dispatch({ type: 'SET_ALL_STEPS', steps })
  const setStep = (step: string) => dispatch({ type: 'SET_STEP', step })

  const goBack = () => dispatch({ type: 'GO_BACK' })
  const goNext = () => dispatch({ type: 'GO_NEXT' })

  const hideStep = (step: string) => dispatch({ type: 'HIDE_STEP', step })
  const showStep = (step: string) => dispatch({ type: 'SHOW_STEP', step })

  return {
    ...state,
    goBack,
    goNext,
    setAllSteps,
    setData,
    setStep,
    setValid,
    hideStep,
    showStep,
  }
}
