import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../../theme'
import Chart from '../Chart'

export const NotificationsCategoriesChart = (props: any) => {
  const days: any = Array.from(
    new Set([
      ...props.notifications_account?.x,
      ...props.notifications_admissions?.x,
      ...props.notifications_utilization_review?.x,
      ...props.notifications_user_lifecycle?.x,
      ...props.notifications_financial?.x,
      ...props.notifications_coordination?.x,
      ...props.notifications_insurance?.x,
    ]),
  )

  const series: any = [
    {
      name: 'Account Notifications',
      type: 'bar',
      stack: 'total',
      color: COLORS.blue,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: props.notifications_account.y,
    },
    {
      name: 'Admissions Notifications',
      type: 'bar',
      stack: 'total',
      color: COLORS.green,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: props.notifications_admissions.y,
    },
    {
      name: 'Utilization Review Notifications',
      type: 'bar',
      stack: 'total',
      color: COLORS.orange,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: props.notifications_utilization_review.y,
    },
    {
      name: 'User Lifecycle Notifications',
      type: 'bar',
      stack: 'total',
      color: COLORS.purple,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: props.notifications_user_lifecycle.y,
    },
    {
      name: 'Financial Notifications',
      type: 'bar',
      stack: 'total',
      color: COLORS.red,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: props.notifications_financial.y,
    },
    {
      name: 'Coordination Notifications',
      type: 'bar',
      stack: 'total',
      color: COLORS.violet,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: props.notifications_coordination.y,
    },
    {
      name: 'Insurance Notifications',
      type: 'bar',
      stack: 'total',
      color: COLORS.yellow,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: props.notifications_insurance.y,
    },
  ]

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: [
        'Account Notifications',
        'Admissions Notifications',
        'Utilization Review Notifications',
        'User Lifecycle Notifications',
        'Financial Notifications',
        'Coordination Notifications',
        'Insurance Notifications',
      ],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: days,
    },
    yAxis: {
      type: 'value',
    },
    series,
  }

  return <Chart option={option} {...props} />
}
