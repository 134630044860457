import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  // ms office
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    label: 'Excel File',
    color: 'vividBlue',
  },
  'application/vnd.ms-excel': {
    label: 'Excel File',
    color: 'vividBlue',
  },
  'application/msword': {
    label: 'Word File',
    color: 'vividBlue',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    label: 'Word File',
    color: 'vividBlue',
  },
  // document files
  'application/pdf': {
    label: 'PDF File',
    color: 'vividBlue',
  },
  'text/csv': {
    label: 'CSV File',
    color: 'vividBlue',
  },
  // images
  'image/jpeg': {
    label: 'JPEG Image',
    color: 'vividBlue',
  },
  'image/png': {
    label: 'PNG Image',
    color: 'vividBlue',
  },
  'image/svg+xml': {
    label: 'SVG Image',
    color: 'vividBlue',
  },
  'image/bmp': {
    label: 'BMP Image',
    color: 'vividBlue',
  },
  'image/gif': {
    label: 'GIF Image',
    color: 'vividBlue',
  },
  'audio/x-m4a': {
    label: 'M4A Audio',
    color: 'orange',
  },
  'audio/mpeg': {
    label: 'MP3 Audio',
    color: 'orange',
  },
  'audio/wav': {
    label: 'WAV Audio',
    color: 'orange',
  },
  // default
  default: {
    label: 'File',
    color: 'vividBlue',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
