import React from 'react'
import startCase from 'lodash/startCase'

import { amount, niceAmount, usDate } from '../../../../../utils/functions'
import withSettings from '../../../../../hocs/withSettings'

import Status from '../../../../Status'
import Flex from '../../../../Flex'
import Table from '../../../../Table/Table'
import TableCell from '../../../../Table/TableCell'
import TableFilter from '../../../../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
	{
		width: 200,
		isSticky: true,
		canToggleVisible: false,
		id: 'client',
		Header: 'Client',
		accessor: 'client',
		Cell: ({ value }: any) => <TableCell.MainLink to={to(value)} label={value.name} avatar={value.avatar} />
	},
	{
		width: 250,
		id: 'name',
		Header: 'Name',
		accessor: 'name'
	},
	{
		width: 100,
		Header: 'Amount',
		accessor: 'total_amount',
		Cell: ({ row, value }: any) => {
			let valueColor = 'red'
			let sign = '+'

			if (row.values.category === 'payment') valueColor = 'green'
			else if (row.values.category === 'refund') valueColor = 'green'
			else if (row.values.category === 'write_off') valueColor = 'green'
			else if (row.values.category === 'credit') valueColor = 'orange'
			else sign = '-'

			const hasCustomCollection = row.values.is_custom_collection

			return (
				<Flex gap="0.5rem" nowrap justifyContent="flex-end">
					<Status color={valueColor} label={`${sign}$${amount(value)}`} />
					{hasCustomCollection && <div>(Collecting {niceAmount(row.values.custom_collection_amount)})</div>}
				</Flex>
			)
		}
	},
	{
		id: 'category',
		Header: 'Transaction Type',
		accessor: 'category',
		Cell: ({ value, row }: any) => {
			if (value === 'charge' || value === 'subcharge') {
				return row.original.status === 'future' ? 'Future Charge' : 'Charge'
			}

			return startCase(value)
		}
	},
	{
		Header: 'Transaction Date',
		accessor: 'transactioned_at',
		Cell: ({ value }: any) => usDate(value, timezone),
		Filter: TableFilter.Date,
		filter: 'date'
	},
	{
		Header: 'Notes',
		accessor: 'notes'
	},
	{
		width: 180,
		id: 'author',
		accessor: 'author',
		Header: 'Added By',
		Cell: ({ row, value }: any) => (
			<TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />
		)
	}
]

type Props = {
	data: Object
	hiddenColumns?: string[]
	isLoading: boolean
	isSelectable: boolean
	localStorageKey: string
	timezone: string
	to?: Function
}

const TransactionsTable = ({ data, hiddenColumns, isLoading, localStorageKey, to, timezone }: Props) => (
	<Table
		title="Transactions"
		icon="financials"
		data={data}
		columns={columns(to, timezone)}
		isLoading={isLoading}
		hiddenColumns={hiddenColumns}
		emptyDescription="No transactions added yet"
		localStorageKey={localStorageKey}
	/>
)

TransactionsTable.defaultProps = {
	localStorageKey: 'transactions'
}

export default withSettings(TransactionsTable)
