import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { css, COLORS, INPUT_STYLES } from '../../theme'
import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { withFormContext } from '../../components/Forms/context'

import Field from '../../components/Forms/Field'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import SummonOverlay from '../../components/SummonOverlay'

import { SeatSelectorOverlay } from './SeatSelectorOverlay'
import { useFormField } from '../Forms/hooks/useFormField'

const SeatSelector = (props: any) => {
  const {
    form,
    fromDate,
    fromLabel,
    isEditable,
    label = 'Seat',
    model,
    afterChange,
    setFromDate,
    setUntilDate,
    untilDate,
    untilLabel,
    validations,
    value,
  } = props

  const { formActions, formState } = useFormField({
    model: model,
    form: form,
    isRelation: true,
    isPolymorphic: true,
    validations: validations,
    afterChange: afterChange,
  })

  const [data, setData]: any = React.useState(form?.getInitialInputFieldValue(model))
  const isEmpty = size(data) === 0

  React.useEffect(() => {
    formActions.setValue(data)
  }, [data])

  const inputClasses = clsx(STYLES.input().className, { 'is-empty': isEmpty })

  return (
    <Field label={label} isEditable={isEditable} isRequired={formState.isRequired} isValid={formState.isValid}>
      {!isEditable && (
        <>
          {isEmpty && DEFAULT_EMPTY_VALUE}

          {data && (
            <Flex centerY gap="0.4rem">
              <Item label={data.program?.name} icon="programs" />
              <Item label={data.phase?.name} icon="housing_shifts" />
              <Item label={data?.name} glyph="chair" />
            </Flex>
          )}
        </>
      )}

      {isEditable && (
        <SummonOverlay
          overlay={
            <SeatSelectorOverlay
              data={data}
              setData={setData}
              fromDate={fromDate}
              setFromDate={setFromDate}
              untilDate={untilDate}
              setUntilDate={setUntilDate}
              fromLabel={fromLabel}
              untilLabel={untilLabel}
            />
          }
        >
          <div className={inputClasses}>
            {isEmpty && (
              <>
                <div className={STYLES.emptyGraphic()}>
                  <Glyph glyph="chair" size={20} />
                </div>

                <div className={STYLES.emptyPlaceholder()}>Select Seat…</div>
                <Glyph glyph="selector" size={20} className={STYLES.selectorGlyph()} />
              </>
            )}

            {data && (
              <Flex centerY gap="0.4rem">
                <Item label={data.program?.name} icon="programs" />
                <Item label={data.phase?.name} icon="housing_shifts" />
                <Item label={data?.name} glyph="chair" />
              </Flex>
            )}
          </div>
        </SummonOverlay>
      )}
    </Field>
  )
}

const Item = ({ label, icon, glyph }: any) => {
  return (
    <div className={STYLES.item()}>
      <div className={STYLES.itemGraphic()}>
        {icon && <Icon icon={icon} size={20} />}
        {glyph && <Glyph glyph={glyph} size={20} />}
      </div>

      <div>{label}</div>

      <Glyph glyph="chevron" size={12} className="item-chevron" color={COLORS.textMuted} />
    </div>
  )
}

const STYLES = {
  input: css({
    ...INPUT_STYLES,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexWrap: 'wrap',

    '&.is-empty': {
      paddingLeft: 0,
      flexWrap: 'nowrap',
    },
  }),

  emptyGraphic: css({
    width: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),

  emptyPlaceholder: css({
    color: COLORS.textMuted,
    opacity: 0.6,
    fontStyle: 'italic',
  }),

  selectorGlyph: css({
    marginLeft: 'auto',
  }),

  item: css({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',

    '.item-chevron': {
      margin: '0 0.4rem',
      opacity: 0.6,
    },

    '&:last-child .item-chevron': {
      display: 'none',
    },
  }),

  itemGraphic: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    marginRight: '0.4rem',
  }),
}

export default withFormContext(SeatSelector)
