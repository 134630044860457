import React from 'react'

import { usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'
import DataList from '../../../components/DataList'
import TestResultStatus from '../../../components/Statuses/TestResultStatus'

export const VitalSignInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item label="Status" value={<TestResultStatus status={data.status} />} />
      {data.client && <DataList.Item label="Client" value={data.client.name} avatar={data.client.avatar} />}
      <DataList.Item label="Date & Time" value={usDateTime(data.recorded_at, timezone)} />
    </DataList>
  )
}
