import React from 'react'
import { Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom'
import omit from 'lodash/omit'

import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import Button from '@behavehealth/components/Button'
import Divider from '@behavehealth/components/Divider'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import NavGroup from '@behavehealth/components/NavGroup'
import Page from '@behavehealth/components/Page'
import Tabs from '@behavehealth/components/Tabs'
import Loader from '@behavehealth/components/Loader'
import SummonOverlay from '@behavehealth/components/SummonOverlay'

import { ClaimTemplateOverlay } from '@behavehealth/constructs/RCM/ClaimTemplateOverlay'
import { NewClaimOverlay } from '@behavehealth/constructs/RCM/NewClaimOverlay'
import { RosterOverlay } from '@behavehealth/constructs/RCM/RosterOverlay'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import { Roster } from './roster'
import { Claims } from './claims'
import { ClaimTemplates } from './claim_templates'

import { ClaimNoteOverlay } from '@behavehealth/constructs/RCM/components/ClaimNoteOverlay'
import { ClaimTaskOverlay } from '@behavehealth/constructs/RCM/components/ClaimTaskOverlay'

import { ClaimsNotesOverlay } from '@behavehealth/constructs/RCM/ClaimsNotesOverlay'
import { FEATURES } from '@behavehealth/theme'

export const ClaimsRCM = () => {
	const match = useRouteMatch()
	const location = useLocation()

	const { pathname } = location

	const claimsSelected = pathname.includes(`${match.url}/claims`)
	const claimTemplatesSelected = pathname.includes(`${match.url}/claim-templates`)

	const [selectedClaims, setSelectedClaims]: any = React.useState([])
	const [isNotesOverlayOpen, setIsNotesOverlayOpen] = React.useState(false)

	const { data: insuranceClaimTemplates, isLoading: loadingInsuranceClaimTemplates } = useGet({
		name: 'insurance_claim_templates',
		url: '/insurance_claim_templates',
		params: { status: 'active' }
	})

	return (
		<>
			<Page
				useWrapper={false}
				feature="claims"
				title="Claims"
				breakpoint="0"
				actions={
					<>
						{claimsSelected && (
							<>
								<Dropdown label="Create Claim" buttonType="primary" permission="insurance_claims.create">
									<DropdownItem
										label="Create Blank Claim"
										icon="rcm_claim_status"
										link={{
											pathname: `${location.pathname}/new/form`,
											parent: location.pathname
										}}
									/>

									<Divider />

									<div className="px-3 pt-2">
										<NavGroup skipVisibleCheck label="Create From Template" />
									</div>

									{loadingInsuranceClaimTemplates ? (
										<Loader />
									) : (
										insuranceClaimTemplates?.map((template: any) => (
											<DropdownItem
												key={template?.id}
												label={template?.name}
												icon="claims"
												link={{
													pathname: `${location.pathname}/new/form`,
													parent: location.pathname,
													data: {
														category: template.category,
														// claim data
														assignee: template.assignee,
														insurance_local_payer: template.insurance_local_payer,
														insurance_new_fee_schedule: template.insurance_new_fee_schedule,
														claim_filing_code: template.claim_filing_code,
														claim_frequency_code: template.claim_frequency_code,
														place_of_service_code: template.place_of_service_code,
														bill_type: template.bill_type,
														admission_type_code: template.admission_type_code,
														admission_source_code: template.admission_source_code,
														condition_codes: template.condition_codes,
														patient_status_code: template.patient_status_code,
														value_codes: template.value_codes,
														// providers
														facility_provider: template.facility_provider,
														billing_provider: template.billing_provider,
														rendering_provider: template.rendering_provider,
														supervising_provider: template.supervising_provider,
														ordering_provider: template.ordering_provider,
														referring_provider: template.referring_provider,
														attending_provider: template.attending_provider,
														// service lines
														insurance_claim_service_lines: template.insurance_claim_template_service_lines.map(
															(o: any) => ({
																...omit(o, ['id']),
																insurance_new_code_id: o?.insurance_new_code?.id
															})
														)
													}
												}}
											/>
										))
									)}
								</Dropdown>
							</>
						)}

						{claimTemplatesSelected && (
							<Button
								label="Add Claim Template"
								glyph="add"
								type="primary"
								link={`${location.pathname}/new`}
								permission="insurance_claim_templates.create"
							/>
						)}
					</>
				}
			>
				<Tabs>
					<Tabs.List className="px-4 bg-white">
						<Tabs.Item label="Roster" icon="roster" to={`${match.url}/roster`} permission="insurance_roster.view" />
						<Tabs.Item
							label="Claims"
							icon={FEATURES.claims.icon}
							to={`${match.url}/claims`}
							permission="insurance_claims.view"
						/>
						<Tabs.Item
							label="Claim Templates"
							icon="claims"
							to={`${match.url}/claim-templates`}
							permission="insurance_claim_templates.view"
						/>
					</Tabs.List>
				</Tabs>

				<Switch>
					<Route path={`${match.url}/roster`} component={Roster} />
					<Route
						path={`${match.url}/claims`}
						children={
							<Claims
								onAddClaimNotes={(items: any) => {
									setSelectedClaims(items)
									setIsNotesOverlayOpen(true)
								}}
							/>
						}
					/>
					<Route path={`${match.url}/claim-templates`} component={ClaimTemplates} />

					<Redirect exact from={match.url} to={`${match.url}/roster`} />
				</Switch>
			</Page>

			<Switch>
				<Route path={`${match.url}/roster/:type/claim/:id`} component={NewClaimOverlay} />
				<Route path={`${match.url}/roster/:type/:id`} component={RosterOverlay} />

				<Route path={`${match.url}/claims/:id`} component={NewClaimOverlay} />
				<Route path={`${match.url}/claim-templates/:id`} component={ClaimTemplateOverlay} />
			</Switch>

			<AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
				<Route path={`${match.url}/claims/:id/notes/:id`} component={ClaimNoteOverlay} />
				<Route path={`${match.url}/claims/:id/tasks/:id`} component={ClaimTaskOverlay} />
			</AnimatedSwitch>

			<SummonOverlay
				isOpen={isNotesOverlayOpen}
				onClose={() => {
					setSelectedClaims([])
					setIsNotesOverlayOpen(false)
				}}
				overlay={<ClaimsNotesOverlay selectedClaims={selectedClaims} />}
			/>
		</>
	)
}
