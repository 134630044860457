import React from 'react'
import clsx from 'clsx'
import pluralize from 'pluralize'
import size from 'lodash/size'

import InfiniteScrollLoader from '../InfiniteScrollLoader'
import Overlay from '../Overlay'
import State from '../State'
import TimelineItem from './TimelineItem'

import { apiGet } from '../../modules/api'
import { encodeObjectToURL } from '../../utils/functions'

type Props = {
  asAuthor?: boolean
  asRecipient?: boolean
  disableLinks?: boolean
  isLoadingRecord?: boolean
  onClose?: any
  recordID: string
  recordType: string
  url?: string
}

const TimelineOverlay = (props: Props) => {
  const { asAuthor, asRecipient, disableLinks, isLoadingRecord, onClose, recordType, recordID, url } = props

  const [dirty, setDirty] = React.useState(false)
  const [paginationLoading, setPaginationLoading] = React.useState(false)
  const [events, setEvents] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [pagination, setPagination] = React.useState(1)
  const [meta, setMeta] = React.useState({})

  const isEmpty = size(events) === 0

  const classNames = clsx({
    'is-loading': loading || paginationLoading,
  })

  React.useEffect(() => {
    if (isLoadingRecord) return

    const getData = async () => {
      try {
        setLoading(true)

        const params = {
          as_author: asAuthor,
          as_recipient: asRecipient,
          desc: true,
          page: pagination,
        }

        const finalURL = url ? url : `${pluralize(recordType)}/${recordID}/timeline`
        const result = await apiGet({ url: `/${finalURL}?${encodeObjectToURL(params)}` })

        setEvents(result?.data?.data)
        setMeta(result?.data?.meta)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [isLoadingRecord, recordType, recordID])

  React.useEffect(() => {
    if (isLoadingRecord) return

    const getPaginatedMessages = async () => {
      try {
        setPaginationLoading(true)

        const params = {
          as_author: asAuthor,
          as_recipient: asRecipient,
          desc: true,
          page: pagination,
        }

        const finalURL = url ? url : `${pluralize(recordType)}/${recordID}/timeline`
        const result = await apiGet({ url: `/${finalURL}?${encodeObjectToURL(params)}` })

        setEvents(events.concat(result.data?.data))
        setMeta(result.data?.meta)
      } catch (error) {
        console.error(error)
      } finally {
        setPaginationLoading(false)
        setDirty(false)
      }
    }

    if (pagination > meta?.pages) return
    if (!loading && !paginationLoading) getPaginatedMessages()
  }, [pagination])

  const fetchNextTimelinePage = () => {
    if (dirty) return
    if (pagination >= meta?.pages) return

    setDirty(true)
    setPagination((page) => page + 1)
  }

  const onScroll = (event: any) => {
    const scrollThreshold = 10
    const scrollLimit = event.target.scrollHeight - event.target.offsetHeight - scrollThreshold
    const scrolledAmount = event.target.scrollTop

    if (scrolledAmount > scrollLimit) fetchNextTimelinePage()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center">
      <Overlay.Header icon="lists" title="Full Activity Timeline" />

      <Overlay.Content css={timelineOverlayStyles} className={classNames} onScroll={onScroll}>
        <div css={{ padding: '0 0.5rem', minHeight: '20rem' }}>
          {isEmpty ? (
            <>
              <State isEmpty={isEmpty && !loading} icon="lists" title="Timeline" emptyDescription="No timeline events to display yet" />
            </>
          ) : (
            <>
              {!loading && events?.map?.((event: any) => <TimelineItem key={event?.id} event={event} disableLinks={disableLinks} />)}
              {meta?.page === meta?.pages && <TimelineItem event={{ object_type: 'end', action_type: 'end' }} />}
            </>
          )}
        </div>
      </Overlay.Content>

      <InfiniteScrollLoader loading={paginationLoading || loading} />
    </Overlay>
  )
}

const timelineOverlayStyles = {
  transition: 'opacity .2s',

  '&.is-loading': {
    opacity: 0.5,
  },
}

export default TimelineOverlay
