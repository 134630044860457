import React from 'react'
import size from 'lodash/size'

import { useSettings } from '../../hooks/useSettings'

import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Section from '../../components/Section'

import { CustomQuestion } from './components/CustomQuestion'

const getIsAnyTrue = (obj: any) => {
  if (!obj) return false

  for (let key in obj) {
    if (obj[key] === true) return true
  }

  return false
}

export const DataFormRenderer = (props: any) => {
  const { sections, client, schema, useScrollView, isEditable }: any = props

  const { timezone } = useSettings()

  if (!sections || !schema) return null

  return (
    <>
      {sections.map((section: any, index: number) => {
        const isLast = index === size(sections) - 1

        const show = schema[section.model]?.show

        if (!show) return null

        const anyFieldsTrue = getIsAnyTrue(schema[section.model]?.fields)

        if (!anyFieldsTrue) return null

        return (
          <React.Fragment key={section.model}>
            <Section
              id={`section_${section.model}`}
              key={section.model}
              title={section.title}
              className="!my-0 !py-6"
              scrollview={
                useScrollView && {
                  id: section.model,
                  name: section.title,
                  parent: section.navParent,
                }
              }
            >
              <FormSection>
                {schema?.[section.model]?.custom_questions_before?.map?.((question: any) => {
                  return <CustomQuestion key={question._id} question={question} sectionModel={section.model} />
                })}

                {section.fields?.map?.((field: any) => {
                  const FieldTag = field.component
                  const showField = schema[section.model]?.fields?.[field.model]

                  if (!showField) return null

                  return (
                    <FieldTag
                      key={field.model}
                      client={client}
                      isEditable={isEditable}
                      timezone={props.timezone || timezone}
                      schema={schema}
                    />
                  )
                })}

                {schema?.[section.model]?.custom_questions_after?.map?.((question: any) => {
                  return <CustomQuestion key={question._id} question={question} sectionModel={section.model} />
                })}
              </FormSection>
            </Section>

            {!isLast && <Divider />}
          </React.Fragment>
        )
      })}
    </>
  )
}
