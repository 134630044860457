import React from 'react'
import clsx from 'clsx'

import { Player, Video as VideoPlayer, Ui, Youtube, DefaultUi } from '@vime/react'
import '@vime/core/themes/default.css'

import { Box } from './Box'

import State from '../../../../components/State'

const extractYouTubeId = (url) => {
  if (!url) return null

  // Regular expression for extracting the YouTube ID from various URL formats
  const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)

  // Check if there was a match and the ID is the correct length
  if (!match || match?.[2]?.length !== 11) return null

  return match[2]
}

export const Video = React.forwardRef((props: any, ref: any) => {
  const { element, className, hoverElement, isEditable } = props

  const player = React.useRef()

  const youtubeId = React.useMemo(() => {
    return extractYouTubeId(element?.config?.video_url)
  }, [element?.config?.video_url])

  if (!element?.config) return null

  const {
    video_max_width = 100,
    video_max_width_type = 'percentage',
    video_provider,
    video_src,
    video_url,
    video_thumbnail,
    video_markers,
  } = element.config

  const rootClasses = clsx('VIDEO', className)

  return (
    <Box
      ref={ref}
      element={element}
      css={STYLES.root}
      className={rootClasses}
      hoverElement={hoverElement}
      style={{ maxWidth: `${video_max_width}${WIDTH_VALUE_TYPES[video_max_width_type]}` }}
    >
      {hoverElement}

      {video_url ? (
        <Player ref={player}>
          {youtubeId ? (
            <Youtube videoId={youtubeId} />
          ) : (
            <VideoPlayer poster={video_thumbnail}>
              <source data-src={video_url} type="video/mp4" />
            </VideoPlayer>
          )}

          <DefaultUi />
        </Player>
      ) : (
        <>{isEditable && <State isEmpty glyph="video_circle" title="Video" emptyDescription="Please enter a video URL" />}</>
      )}
    </Box>
  )
})

const WIDTH_VALUE_TYPES = {
  percentage: '%',
  pixels: 'px',
}

const STYLES = {
  root: {
    width: '100%',
    margin: '0 auto',

    '.hover-element': {
      // remove hover background so it doesn't affect the element's background
      background: 'none !important',
    },
  },
}
