import React from 'react'
import { tint } from 'polished'
import { v4 as uuid } from 'uuid'
import produce from 'immer'

import map from 'lodash/map'
import remove from 'lodash/remove'

import { apiGet } from '../../../../modules/api'
import { DEFAULT_EMPTY_VALUE } from '../../../../utils/constants'
import { encodeObjectToURL } from '../../../../utils/functions'
import { INPUT_STYLES, INPUT_FOCUS_STYLES, COLORS } from '../../../../theme'

import { validate } from '../../validators'
import { withFormContext } from '../../context'

import Glyph from '../../../Glyph'
import Search from '../../../Search'
import SelectorBase from '../SelectorBase'
import SmartPortal from '../../../SmartPortal'
import Value from '../../../Value'

import MultiSearchMenu from './MultiSearchMenu'

import { getDefaultGroupBy } from '../SelectorBase'
import { size } from 'lodash'

class MultiSearchPillSelector extends SelectorBase {
  constructor(props: any) {
    super(props)

    let errors = []
    let vs = props.validations

    let object = props.value || props.form?.getInitialInputFieldValue(props.model)
    if (JSON.stringify(object) === '{}') object = [] // if the object is empty, force it to array

    if (vs) errors = validate(object, vs)

    this.state = {
      type: 'OBJECT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      submodel: props.submodel,
      object: object || [],
      value: object || [],
      apiData: [],
      search: '',
      isLoading: false,
      isRelations: props.isRelations,
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValidations: props.validations,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      object: object,
      value: object,
      apiData: props.apiData ? props.apiData : [],
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.updateType = 'DATA'
    this.referenceRef = null
    this.fieldRef = null

    this.eventsQueue = []
    this.processedEventsQueue = []
  }

  /*
    CUSTOM FUNCTIONS
  */
  queryData = async (query = '') => {
    const types = this.props.type.split('.')

    const params = {
      q: query,
      type: types[0],
      subtype: types[1],
      excluded: this.state.object?.map((o: any) => o?.id).join(','),
    }

    const response = await apiGet({ url: `/search_by?${encodeObjectToURL(params)}` })
    return response.data.data
  }

  processChangeValue = (items: any) => {
    if (this.props.isDisabled) return

    const newState = produce(this.state, (draft: any) => {
      draft.object = items
      draft.value = items
      draft.isOpen = false

      draft.isDirty = true
      draft.isPristine = false
    })

    this.setState({
      object: newState.object,
      value: newState.value,
      isOpen: newState.isOpen,
      isDirty: newState.isDirty,
      isPristine: newState.isPristine,
    })

    return newState
  }

  select = (item: any) => {
    if (this.props.isDisabled) return

    // add new item to object
    const items = produce(this.state.object, (draft: any) => {
      let itemFound = false

      // check that the items doesn't already exist
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].id === item.id) {
          itemFound = true
          break
        }
      }

      // if item is not added to the object, add it
      if (!itemFound) draft.push(item)
    })

    this.changeValue(items)
  }

  removeItem = (id) => {
    if (this.props.isDisabled) return
    const vs = this.props.validations
    let errors = []

    const newState = produce(this.state, (draft) => {
      remove(draft.object, { id: id })

      if (vs) errors = validate(draft.object, vs)
      let value = this.props.asItems ? draft.object : map(draft.object, 'id')

      draft.value = value
      draft.isOpen = false
      draft.isDirty = true
      draft.isPristine = false
      draft.isValid = errors.length ? false : true
      draft.isInvalid = errors.length ? true : false
      draft.errors = errors
    })

    this.setState(newState, () => {
      this.onSearch(newState.search)
    })
  }

  onSearch = async (search: string) => {
    this.setState({ isLoading: true, search: search })

    try {
      const result = await this.queryData(search)
      this.setState({ isLoading: false, apiData: result })
    } catch (error) {
      this.setState({ isLoading: false })
    }
  }

  /*
    RENDER
  */
  renderEdit = () => {
    const { selectTitle } = this.props
    const { object, isOpen } = this.state

    return (
      <div css={styles.editRoot} className={isOpen ? 'is-focused' : ''}>
        <div css={styles.editInput}>
          {object?.map((item: any) => (
            <div key={item?.id} isDisabled={item?.isRequired} css={styles.editPill} onClick={this.open}>
              {selectTitle?.(item) || item?.name}
              <div onClick={() => this.removeItem(item?.id)} css={styles.editPillClear}>
                <Glyph glyph="close" size={10} color={COLORS.text} />
              </div>
            </div>
          ))}

          <Search
            showClearButton={false}
            showGraphic={!!this.props.icon}
            icon={this.props.icon}
            placeholder={this.props.placeholder}
            onChange={this.onSearch}
            onFocus={this.open}
            value={this.props.selectTitle?.(this.state.value)}
            css={styles.searchInput}
          />
        </div>
      </div>
    )
  }

  renderReadOnly = () => {
    const { object } = this.state

    if (size(object) === 0) return DEFAULT_EMPTY_VALUE

    const itemTitles = object.map((o) => this.props.selectTitle?.(o) || o?.name)

    return <Value value={itemTitles.join(', ')} />
  }

  renderSelector = () => {
    const { icon, type, selectTitle, selectDescription, showAvatars, emptyActions, groupBy } = this.props
    const { apiData } = this.state

    const groupByConfig = groupBy || getDefaultGroupBy(type)

    return (
      <SmartPortal useReferenceWidth portal="selector" position="bottom-start">
        <MultiSearchMenu
          icon={icon}
          type={type}
          items={apiData}
          showAvatars={showAvatars}
          selectTitle={selectTitle}
          selectDescription={selectDescription}
          emptyActions={emptyActions}
          onSelect={this.select}
          onClose={this.close}
          groupBy={groupByConfig}
        />
      </SmartPortal>
    )
  }
}

const styles = {
  editRoot: {
    ...INPUT_STYLES,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: 0,
    overflow: 'hidden',

    '&.is-focused': INPUT_FOCUS_STYLES,
  },

  editInput: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: '1 1 auto',
    paddingLeft: '0.5rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    lineHeight: 'normal',
  },

  searchInput: {
    flex: '1 1 120px',
    height: 'auto',
    minHeight: 'auto',
    border: 'none !important',
    boxShadow: 'none !important',

    input: {
      borderRadius: 0,
      padding: '0 !important',
      paddingLeft: '0.25rem !important',
      border: 'none !important',
      boxShadow: 'none !important',
      height: 'auto !important',
      // minHeight: '32px !important',
      // height: '38px !important',
    },
  },

  editPill: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5rem',
    flexWrap: 'nowrap',
    margin: 2,
    background: tint(0.84, COLORS.blue),
    color: COLORS.black,
    borderRadius: 4,
    lineHeight: '1',
    fontWeight: 600,
    fontSize: '0.8rem',
    overflow: 'hidden',
  },

  editPillClear: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    minHeight: 18,
    padding: '0.2rem 0.4rem',
    borderLeft: `1px solid ${COLORS.divider}`,
    marginLeft: '0.5rem',
    cursor: 'pointer',
    svg: { opacity: 0.6 },

    '&:hover': {
      background: tint(0.8, COLORS.red),

      svg: {
        fill: COLORS.red,
        opacity: 1,
      },
    },
  },
}

MultiSearchPillSelector.defaultProps = {
  showAs: 'inline',
  showAvatars: true,
  prefetch: false,
  asItems: false,
  isEditable: true,
  isDisabled: false,
  initialModelRequired: false,
  isRelations: false,
  layout: 'vertical-dense',
  isOpen: false,
  placeholder: 'Add…',
  openAfterSelect: true,
}

export default withFormContext(MultiSearchPillSelector)
