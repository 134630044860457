import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { COLORS } from '@behavehealth/theme'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Alert, Dropdown, DropdownItem, Glyph, Link, PageLayout, PageSection } from '@behavehealth/components'

import OrganizationTransactions from '@behavehealth/components/Elements/transactions/OrganizationTransactions'
import OrganizationPaymentMethodsSection from '@behavehealth/constructs/Stripe/OrganizationPaymentMethodsSection'
import OrganizationOnlineFinancialCards from '@behavehealth/constructs/Financials/OrganizationOnlineFinancialCards'
import OrganizationFinancialPlanCard from '@behavehealth/constructs/Financials/OrganizationFinancialPlanCard'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import useStore from '@behavehealth/modules/store'

const pageConfig = {
  feature: 'financials',
  title: 'Financials',
}

type Props = {
  canCreate: boolean
}

const Funding: React.FC<Props> = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { url } = match

  const tenant = useStore((state) => state.tenant)

  const { data: organization }: any = useGet({
    name: ['organization', resource_id],
    url: `/organizations/${resource_id}`,
  })

  return (
    <PageLayout>
      {/* Stripe Connection */}
      {!tenant?.is_stripe_connected && (
        <Alert contrast small type="warning" glyph="info">
          Set up <Link to="/settings/online-payments">Online Payments</Link> and share the Funding & Donations links with{' '}
          {organization?.name} to automatically collect Funding & Donations
        </Alert>
      )}

      {tenant?.is_stripe_connected && <OrganizationOnlineFinancialCards organization={organization} />}

      <OrganizationFinancialPlanCard organization={organization} />

      {tenant?.is_stripe_connected && organization && (
        <OrganizationPaymentMethodsSection title="Payment Methods" organization={organization} tenant={tenant} />
      )}

      <PageSection>
        <PageSection.Header
          graphic={<Glyph glyph="tag" color={COLORS.green} />}
          after={
            <Dropdown label="Add New…" glyph="add" buttonType="primary" buttonSize={300} permission="ledger.create">
              <DropdownItem
                icon="financials"
                color="green"
                label="Charges"
                link={{
                  pathname: `${url}/charge-builder`,
                  parent: match,
                }}
              />
              <DropdownItem
                icon="financials"
                color="green"
                label="Payment"
                link={{
                  pathname: `${url}/payments/new`,
                  parent: match,
                }}
              />
              <DropdownItem
                icon="financials"
                color="green"
                label="Credit"
                link={{
                  pathname: `${url}/credits/new`,
                  parent: match,
                }}
              />
              <DropdownItem
                icon="financials"
                color="green"
                label="Refund"
                link={{
                  pathname: `${url}/refunds/new`,
                  parent: match,
                }}
              />
              <DropdownItem
                icon="financials"
                color="green"
                label="Write-Off"
                link={{
                  pathname: `${url}/write-offs/new`,
                  parent: match,
                }}
              />
            </Dropdown>
          }
        >
          <PageSection.Title title="Ledger" className="!mr-2" />
        </PageSection.Header>

        <PageSection.Content>
          <OrganizationTransactions
            showReportLinks
            name={['organization', resource_id, 'financial-transactions']}
            url={`/organizations/${resource_id}/financial_transactions`}
            afterStats={
              <Alert small contrast glyph="info" type="negative" className="mt-4">
                If you are looking for client transaction for organization payers click to visit{' '}
                <Link to={`${match.url.replace('/financials/organization', '/financials/client')}`} className="font-[600]">
                  Clients tab
                </Link>{' '}
                above.
              </Alert>
            }
          />
        </PageSection.Content>
      </PageSection>
    </PageLayout>
  )
}

export default withPageError(withMarketing(Funding, pageConfig))
