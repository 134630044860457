import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../theme'
import withPermissions from '../../hocs/withPermissions'

const OverlaySubHeader: React.FC<any> = ({ children, className }) => {
  const classNames = clsx('overlay-subheader', className)

  return (
    <div css={styles} className={classNames}>
      {children}
    </div>
  )
}

const styles = {
  padding: '0.2rem 1.25rem',
  borderBottom: `1px solid ${COLORS.divider}`,
}

export default withPermissions(OverlaySubHeader)
