import React from 'react'

import { SankeyChart } from '@behavehealth/components'

const data = [
  {
    name: 'a',
  },
  {
    name: 'b',
  },
  {
    name: 'a1',
  },
  {
    name: 'a2',
  },
  {
    name: 'b1',
  },
  {
    name: 'c',
  },
]

const links = [
  {
    source: 'a',
    target: 'a1',
    value: 5,
  },
  {
    source: 'a',
    target: 'a2',
    value: 3,
  },
  {
    source: 'b',
    target: 'b1',
    value: 8,
  },
  {
    source: 'a',
    target: 'b1',
    value: 3,
  },
  {
    source: 'b1',
    target: 'a1',
    value: 1,
  },
  {
    source: 'b1',
    target: 'c',
    value: 2,
  },
]

const SankeyChartExample = (props: any) => {
  return <SankeyChart data={data} links={links} {...props} />
}

export default SankeyChartExample
