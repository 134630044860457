import React from 'react'
import { opacify, tint, transparentize, darken } from 'polished'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../../theme'
import Glyph from '../Glyph'
import Flex from '../Flex'

const RadioPillBoxBase = (props) => {
  const { description, group_id, id, isChecked, isDisabled, label, onChange, onFocus, glyph, type, value, reverse, className } = props

  const classNames = clsx({
    'is-checked': isChecked,
    'is-disabled': isDisabled,
    [className]: className,
  })

  const labelClassNames = clsx({
    'is-reversed': reverse,
  })

  return (
    <div css={styles.root} className={classNames}>
      <input
        id={id}
        name={group_id || id}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        css={styles.input}
        disabled={isDisabled}
      />

      <label htmlFor={id} className={labelClassNames} css={styles.label}>
        <Flex className="!mb-4">
          <Glyph glyph={glyph} css={styles.glyph} color={COLORS.blue} className={isChecked ? 'is-checked' : ''} />
        </Flex>

        <div>
          <div>{label}</div>
          {description && <div css={styles.description}>{description}</div>}
        </div>
      </label>
    </div>
  )
}

const styles = {
  root: {
    flex: '1',
    flexBasis: 0,
    borderRadius: 7,
    background: 'white',
    boxShadow: `inset 0 0 0 0.5px ${COLORS.divider}, ${SHADOW(1)}`,
    overflow: 'hidden',
    padding: '0.75em',
    justifyContent: 'center',

    '&:hover': {
      boxShadow: SHADOW(4),
      transform: 'translateY(-1px)',
      cursor: 'pointer',
    },

    '&.is-checked': {
      background: tint(0.96, COLORS.green),
      borderColor: `${transparentize(0.5, COLORS.green)} !important`,
      boxShadow: `
        inset 0 0 0 1px ${darken(0.02, COLORS.green)},
        ${SHADOW(2, transparentize(0.9, COLORS.green))}
      `,

      '&:hover': {
        background: tint(0.92, COLORS.green),
        boxShadow: `
          inset 0 0 0 1px ${darken(0.02, COLORS.green)},
          ${SHADOW(2, transparentize(0.9, COLORS.green))}
        `,
      },
    },

    '&.is-disabled': {
      cursor: 'not-allowed !important',
      boxShadow: 'none !important',
      zIndex: '3 !important',
      color: COLORS.textMuted,

      '&:hover': {
        cursor: 'not-allowed !important',

        '& .check-element': {
          borderColor: opacify(0.08, COLORS.divider),
          boxShadow: 'none !important',
        },
      },
    },
  },

  input: {
    display: 'none',
    WebkitAppearance: 'none',
  },

  glyph: {
    '&.is-checked': {
      fill: `${COLORS.green} !important`,
    },
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    flexDirection: 'column',
    padding: '0.5em 0.65em',
    fontWeight: 500,
    cursor: 'inherit',

    '&.is-reversed': {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',

      '.check-element': {
        marginLeft: 10,
      },
    },
  },

  description: {
    display: 'block',
    fontWeight: 400,
    fontSize: '0.9em',
    color: COLORS.textMuted,
    marginTop: '0.25em',
  },
}

RadioPillBoxBase.defaultProps = {
  type: 'checkbox',
}

export default RadioPillBoxBase
