import React from 'react'

import { apiUpdate } from '@behavehealth/modules/api'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'
import useStore from '@behavehealth/modules/store'

import { COLORS } from '@behavehealth/theme'

import { Accordion, Alert, Page, Card, Form, FormSection, Radio, RadioGroup } from '@behavehealth/components'

const pageConfig = {
  feature: 'financials',
  title: 'Accounting',
}

const Accounting: React.FC = () => {
  const tenant = useStore((state: any) => state.tenant)

  const scriptLoaded = () => {
    try {
      const widget = new window.RailzConnect()
      widget.mount({
        parentElement: document.getElementById('railz-connect'),
        widgetId: process.env.BH_RAILZ_WIDGET_ID,
        businessName: tenant.subdomain,
        brandColor: COLORS.blue,
        closeEnable: true,
        removeRailzWatermark: true,
      })
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://connect.railz.ai/v1/railz-connect.js'
    script.onload = (): void => scriptLoaded()

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Page {...pageConfig}>
      <Card>
        <div className="railz-container" css={styles}>
          <div id="railz-connect"></div>
        </div>
      </Card>

      <Accordion
        isOpen
        title="HIPAA"
        activateEditMode
        initialModel={tenant}
        onSubmit={(data) =>
          apiUpdate({
            name: 'tenant',
            url: '/me/tenant',
            params: { accounting_prefs: Object.assign({}, tenant.accounting_prefs, data.accounting_prefs) },
            reducer: 'me',
          })
        }
        editPermission="settings.accounting.edit"
      >
        <Form>
          <FormSection maxWidth="600px">
            <Alert type="neutral" glyph="info">
              Collecting more information for accounting platform can help you with associating BehaveHealth data with your accounting data.
            </Alert>

            <RadioGroup
              label="Are you HIPAA compliant?"
              model="accounting_prefs.hipaa_compliant"
              layout="vertical-dense"
              defaultValue={true}
              description=""
            >
              <Radio label="Yes - This prevents any identifiable information from being sent to accounting system." value={true} />
              <Radio label="No - This allows identifiable information to be sent to accounting system." value={false} />
            </RadioGroup>
          </FormSection>
        </Form>
      </Accordion>
    </Page>
  )
}

const styles = {
  position: 'relative',

  '&::before': {
    content: '""',
    width: 180,
    height: 80,
    position: 'absolute',
    top: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    background: 'white',
  },

  '&::after': {
    content: '""',
    left: 0,
    right: 0,
    height: 50,
    position: 'absolute',
    bottom: 130,
    background: 'white',
  },

  '.railz-connect-base .jss3': {
    display: 'none !important',
  },
}

export default withPageError(withMarketing(Accounting, pageConfig))
