import React from 'react'

import withSettings from '@behavehealth/hocs/withSettings'
import { Chotomate, CouponTagIframe, Flex, HelpTagIframe, Page, Tabs } from '@behavehealth/components'
import { COLORS } from '@behavehealth/theme'

import BillflowEmbed from '@behavehealth/components/BillflowEmbed'

const isDev = process.env.NODE_ENV !== 'production'

const BILLFLOW_IDS = {
  healthcare_main_plan: isDev ? 'BL3g3rVDgBmIRubN1jsb' : '8PEtkiWZN7RfwiARCvkx',
  support_main_plan: isDev ? 'JDGfDSouLxALVic4bKer' : 'nUEalDq7vS22RYa6ygn',
  chat: 'mfxiNdlkq76n7VqNhRXg',
  rcm_consumer: 'cLfeSYb9FAYCPdsWUONF',
}

const SupportPlans = () => {
  const tenant = {
    id: 'GLWOzL',
    type: 'facility',
    updated_at: '2021-01-25T17:53:26.154+00:00',
    created_at: '2018-02-20T18:16:29.765+00:00',
    subdomain: 'test',
    avatar:
      'https://healthcareblocks-storage-behavehealth.s3.us-west-2.amazonaws.com/assets/test/avatars/facility/10ubjhz9ovs8r5ok6nfscvfr20m6?response-content-disposition=inline%3B%20filename%3D%22ic_bh_logo.png%22%3B%20filename%2A%3DUTF-8%27%27ic_bh_logo.png&response-content-type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIMEODVJP72FKTHXA%2F20210219%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20210219T155530Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&X-Amz-Signature=26d4f7f0229dbec48b2a093d4e27ea3345dcb6b981e17173b97931fd7fa3ca15',
    name: 'Behave Health Corp.',
    phone_no: null,
    email: null,
    mailing_address: {
      city: 'San Francisco',
      state: 'CA',
      zip_code: '94105',
      address_line_1: 'Main Street',
      address_line_2: '123',
    },
    billing_address: {
      city: 'San Francisco',
      state: 'CA',
      zip_code: '94105',
      address_line_1: 'Main Street',
      address_line_2: '123',
    },
    plan: {
      id: 'JLQ3oe',
      type: 'master_plan',
      updated_at: '2020-03-12T16:15:24.974+00:00',
      created_at: '2020-03-12T16:15:24.974+00:00',
      identifier: 'behave',
      name: 'Behave',
      description: null,
      category: 'plan',
      value: '0.0',
    },
    plan_status: 'active',
    plan_started_at: '2018-03-15T00:00:00.000+00:00',
    plan_ended_at: '2023-11-01T00:00:00.000+00:00',
    first_name: 'Robert',
    last_name: 'Eles',
    category: 'support_facility',
    is_blocked: false,
    is_billed: false,
    contacts: {
      billing: {
        email: '',
        phone_no: '',
      },
    },
    process_invoice_before_days_no: 5,
    card_fee_percentage: 0.03,
    ach_fee_percentage: 0.02,
    is_payment_by_card_allowed: true,
    is_payment_by_ach_allowed: true,
    external_id: 'd80f8002-ba93-4356-8149-400efa703817',
    preferred_clinical_note_format: 'dap',
    stripe_customer_id: 'cus_IpDZ97SY0FOPu9',
    stripe_subscription_id: null,
    stripe_account_id: 'acct_19iYhQJ9wdQLzHF8',
    is_onboarding: true,
    is_stripe_connected: true,
    is_set_for_billing: true,
    pass_fees_to_payers: true,
    provider: {
      npi_number: '1346786696',
      npi_last_name: 'Jackson',
      npi_first_name: 'Chris',
    },
    timezone: 'Europe/London',
    accepted_timezone: true,
    tax_id: null,
    lat: null,
    lon: null,
    admin_invoices: [
      {
        link: '',
        paid_at: '',
        sent_at: '',
        identifier: 'asdasdasdasd',
      },
      {
        link: 'asdsad',
        identifier: '123123123',
      },
    ],
    authorizations_email: '',
    applications_email: 'robert.eles@behavehealth.com',
    bridge_status: null,
    bridge_data: {},
    lead_settings: {
      schema: {
        custom: {
          show: false,
          questions: [
            {
              id: '888acc9f-a3c6-4652-9e37-465f6ccffce7',
              _id: '3c482b5e-c6ec-491a-9d23-8d705d01d219',
              question: 'When would you like to arrive?',
              required: true,
            },
            {
              id: 'e74b5c69-45e3-4862-96b6-bf4f2b0887c0',
              _id: '9acfa422-36c1-417f-a120-81d5ca363844',
              question: 'What age are you?',
              required: false,
            },
            {
              _id: 'f2011938-7bb5-4fc5-a966-d1ea5b22cb96',
              question: 'xcvcxvcx',
              required: true,
            },
            {
              _id: '5dfa1fa6-177c-483c-90cb-2b40d28a0954',
              question: 'qweewq',
              required: true,
            },
          ],
        },
        family: {
          show: false,
          fields: {
            children: {
              show: true,
              required: true,
            },
            marital_status: {
              show: true,
              required: true,
            },
            domestic_violence: {
              show: true,
              required: true,
            },
            personal_contacts: {
              show: true,
              required: true,
            },
            family_reunification: {
              show: true,
              required: true,
            },
          },
        },
        contact: {
          show: true,
          fields: {
            preferred_methods: {
              show: true,
              required: true,
            },
          },
        },
        medical: {
          show: false,
          fields: {
            conditions: {
              show: true,
              required: true,
            },
            has_allergies: {
              show: true,
              required: true,
            },
            current_providers: {
              show: true,
              required: true,
            },
            has_medical_equipment: {
              show: true,
              required: true,
            },
            has_history_of_seizures: {
              show: true,
              required: true,
            },
            has_upcoming_appointments_or_ongoing_physical_needs: {
              show: true,
              required: true,
            },
            has_physical_health_medical_conditions_or_disabilities: {
              show: true,
              required: true,
            },
          },
        },
        recovery: {
          show: false,
          fields: {
            sober_date: {
              show: true,
              required: true,
            },
            has_sponsor: {
              show: true,
              required: true,
            },
            has_case_manager: {
              show: true,
              required: true,
            },
            has_recovery_coach: {
              show: true,
              required: true,
            },
            twelve_step_meetings_attending: {
              show: true,
              required: true,
            },
            will_attend_90_meetings_in_90_days: {
              show: true,
              required: true,
            },
          },
        },
        admissions: {
          show: false,
          fields: {
            target_move_in_date: {
              show: true,
              required: true,
            },
            has_applied_previously: {
              show: true,
              required: true,
            },
            preferred_length_of_stay: {
              show: true,
              required: true,
            },
            does_expect_to_move_in_on_time: {
              show: true,
              required: true,
            },
            issues_for_completing_the_program: {
              show: true,
              required: true,
            },
            personal_relationship_with_employee: {
              show: true,
              required: true,
            },
          },
        },
        employment: {
          show: false,
          fields: {
            occupation: {
              show: true,
              required: true,
            },
            is_employed: {
              show: true,
              required: true,
            },
            weekly_income: {
              show: true,
              required: true,
            },
            is_willing_to_work_full_time: {
              show: true,
              required: true,
            },
          },
        },
        medications: {
          show: false,
          fields: {
            does_use_otc_medications: {
              show: true,
              required: true,
            },
            drug_replacement_programs: {
              show: true,
              required: true,
            },
            does_use_prescription_medications: {
              show: true,
              required: true,
            },
          },
        },
        demographics: {
          show: true,
          fields: {
            sex: {
              show: true,
              required: false,
            },
            height: {
              show: false,
            },
            school: {
              show: false,
            },
            weight: {
              show: false,
            },
            birth_sex: {
              show: false,
            },
            ethnicity: {
              show: false,
            },
            is_veteran: {
              show: true,
              required: false,
            },
            gender_identity: {
              show: false,
            },
            primary_language: {
              show: false,
            },
            religious_preference: {
              show: false,
            },
            highest_education_level: {
              show: false,
            },
          },
        },
        program_cost: {
          show: true,
          fields: {
            program_payment_options: {
              show: true,
              required: true,
            },
          },
          description:
            'fdskjfj sdbhfjhb dsjhfbdjshbf jhdsbf jhbdsjhf bdjshbf jhdsbfjhbdj bfdsjhb fjhdsbfjh bdsjh bfjdhsb fjhbsdj hfbdsjhfdskjfj sdbhfjhb dsjhfbdjshbf jhdsbf jhbdsjhf bdjshbf jhdsbfjhbdj bfdsjhb fjhdsbfjh bdsjh bfjdhsb fjhbsdj hfbdsjhfdskjfj ',
        },
        restrictions: {
          show: false,
          fields: {
            legal_requirements: {
              show: true,
              required: true,
            },
            has_restraining_orders: {
              show: true,
              required: true,
            },
            is_required_to_register_as_sex_offender: {
              show: true,
              required: true,
            },
            is_required_to_register_with_other_authority: {
              show: true,
              required: true,
            },
          },
        },
        mental_health: {
          show: false,
          fields: {
            has_history_of_self_harm: {
              show: true,
              required: true,
            },
            was_sex_trafficking_victim: {
              show: true,
              required: true,
            },
            was_involved_in_prostitution: {
              show: true,
              required: true,
            },
            does_need_mental_health_services: {
              show: true,
              required: true,
            },
            has_experienced_suicidal_ideations: {
              show: true,
              required: true,
            },
            has_mental_health_issues_or_diagnosis: {
              show: true,
              required: true,
            },
            has_eating_disorder_or_body_image_disorder: {
              show: true,
              required: true,
            },
          },
        },
        transportation: {
          show: false,
          fields: {
            drivers_license: {
              show: true,
              required: false,
            },
            primary_transportation_mode: {
              show: true,
              required: false,
            },
          },
        },
        program_details: {
          show: true,
          fields: {
            can_share_room: {
              show: true,
              required: true,
            },
            can_sleep_in_bunk_bed: {
              show: true,
              required: true,
            },
            can_perform_household_chores: {
              show: true,
              required: true,
            },
            is_ok_with_faith_based_house: {
              show: false,
            },
          },
        },
        client_statement: {
          show: false,
          fields: {
            was_referred: {
              show: true,
              required: true,
            },
            referral_place: {
              show: true,
              required: true,
            },
            goals_and_expectations: {
              show: true,
              required: true,
            },
            accomplishments_wish_list: {
              show: true,
              required: true,
            },
            is_good_fit_for_sober_living: {
              show: true,
              required: true,
            },
            other_information_to_consider: {
              show: true,
              required: true,
            },
            reason_to_live_in_sober_house: {
              show: true,
              required: true,
            },
            issues_that_led_to_seek_housing: {
              show: true,
              required: true,
            },
          },
        },
        living_situation: {
          show: true,
          fields: {
            situation: {
              show: true,
              required: true,
            },
          },
        },
        personal_details: {
          show: true,
          fields: {
            dob: {
              show: true,
              required: true,
            },
            last_name: {
              show: true,
              required: true,
            },
            first_name: {
              show: true,
              required: true,
            },
            middle_name: {
              show: true,
              required: false,
            },
            alternate_name: {
              show: true,
              required: false,
            },
          },
        },
        personal_finance: {
          show: false,
          fields: {
            weekly_expenses: {
              show: true,
              required: true,
            },
            is_on_disability: {
              show: true,
              required: true,
            },
            financial_help_contacts: {
              show: true,
              required: true,
            },
            previous_sober_living_debt: {
              show: true,
              required: true,
            },
            additional_financial_information: {
              show: true,
              required: true,
            },
            does_receive_ongoing_financial_reimbursement: {
              show: true,
              required: true,
            },
          },
        },
        treatment_history: {
          show: true,
          fields: {
            has_current_treatment_program: {
              show: true,
              required: true,
            },
            has_previous_treatment_programs: {
              show: true,
              required: true,
            },
          },
        },
        addictive_behaviors: {
          show: false,
          fields: {
            gambling: {
              show: true,
              required: true,
            },
            addiction_areas: {
              show: true,
              required: true,
            },
          },
        },
        assistance_and_help: {
          show: false,
          fields: {
            food_programs_assistance: {
              show: true,
              required: true,
            },
            support_group_assistance: {
              show: true,
              required: true,
            },
            does_need_clothing_or_toiletries: {
              show: true,
              required: true,
            },
            will_need_help_to_renew_identification_forms: {
              show: true,
              required: true,
            },
            has_learning_disability_or_difficulty_reading: {
              show: true,
              required: true,
            },
            does_need_assistance_with_vocational_dropping_classes: {
              show: true,
              required: true,
            },
            does_need_assistance_with_poa_will_insurance_unpaid_bills: {
              show: true,
              required: true,
            },
          },
        },
        infectious_diseases: {
          show: false,
          fields: {
            is_at_coronavirus_exposure_risk: {
              show: true,
              required: true,
            },
            is_at_infectious_diseases_exposure_risk: {
              show: true,
              required: true,
            },
            is_experiencing_flu_or_similar_symptoms: {
              show: true,
              required: true,
            },
            has_traveled_outside_country_in_past_month: {
              show: true,
              required: true,
            },
          },
        },
        sensitive_information: {
          show: false,
          fields: {
            ssn: {
              show: true,
              required: false,
            },
            state_id_no: {
              show: true,
              required: false,
            },
            drivers_license_no: {
              show: true,
              required: false,
            },
          },
        },
        substance_use_history: {
          show: false,
          fields: {
            tobacco: {
              show: true,
              required: true,
            },
            drugs_of_choice: {
              show: true,
              required: true,
            },
            last_drug_used_and_date: {
              show: true,
              required: true,
            },
            number_years_using_alcohol_or_drugs: {
              show: true,
              required: true,
            },
          },
        },
        additional_information: {
          show: true,
          fields: {
            other_information: {
              show: true,
              required: false,
            },
          },
        },
        courts_criminal_justice: {
          show: false,
          fields: {
            has_attorney: {
              show: true,
              required: true,
            },
            has_gang_affiliation: {
              show: true,
              required: true,
            },
            has_pending_sentencing: {
              show: true,
              required: true,
            },
            department_of_corrections_number: {
              show: true,
              required: true,
            },
            does_consent_to_background_check: {
              show: true,
              required: true,
            },
            was_charged_or_convicted_of_arson: {
              show: true,
              required: true,
            },
            was_charged_or_convicted_of_felony: {
              show: true,
              required: true,
            },
            court_ordered_treatment_requirements: {
              show: true,
              required: true,
            },
            has_requirement_for_community_service: {
              show: true,
              required: true,
            },
            was_charged_or_convicted_of_violent_crimes: {
              show: true,
              required: true,
            },
            was_charged_or_convicted_of_abuse_or_neglect: {
              show: true,
              required: true,
            },
            was_charged_or_convicted_of_cruelty_to_animals: {
              show: true,
              required: true,
            },
            has_legal_proceedings_or_criminal_justice_issues: {
              show: true,
              required: true,
            },
          },
        },
      },
      sms_message:
        'We got your initial contact, but we require more details to better understand how we can help you – would you mind taking a few minutes to finalize your application?',
      welcome_message: 'Below you will find some questions that help us learn a bit about you so we can determine how we can help.',
      thank_you_message: "Thank you for submitting your Application. We'll read through it shortly and get in touch with you soon.",
    },
    payment_methods: {
      ach: false,
      card: false,
      cash: true,
      check: true,
      other: false,
      venmo: false,
      zelle: false,
      online: false,
      pay_pal: false,
      cash_app: false,
      money_order: true,
    },
    billflow_hash: 'bb88c64b7148e1cd203975ab7ddb5b5dd7529f2e7bebfca68f0b9f15f88a1f9a',
    authorization_subscriber: null,
  }

  return (
    <Page title="Active Plans" feature="active_plans" help={<HelpTagIframe id="settings_active_plans" />}>
      <Chotomate ready name="active_plans" />

      <Tabs defaultTab="support_main_plan" css={styles.wrapper}>
        <Tabs.List css={styles.tabsList}>
          <Tabs.Item label="🏣 Main Plan" name="support_main_plan" />
          <Tabs.Item label="💳 RCM Consumer" name="rcm_consumer" />
          <Tabs.Item label="💬 Chat" name="chat" />
        </Tabs.List>

        <Tabs.Panels>
          <Tabs.Panel type="mount" name="support_main_plan">
            <Flex gap={8} justifyContent="flex-end">
              <CouponTagIframe id="active_plans_support_main_plan_coupon" />
              <HelpTagIframe id="active_plans_support_main_plan" />
            </Flex>

            <BillflowEmbed
              billingPageID={BILLFLOW_IDS.support_main_plan}
              customerID={tenant.stripe_customer_id}
              hash={tenant.billflow_hash}
            />
          </Tabs.Panel>

          <Tabs.Panel type="mount" name="chat">
            <Flex gap={8} justifyContent="flex-end">
              <CouponTagIframe id="active_plans_chat_coupon" />
              <HelpTagIframe id="active_plans_chat" />
            </Flex>

            <BillflowEmbed billingPageID={BILLFLOW_IDS.chat} customerID={tenant.stripe_customer_id} hash={tenant.billflow_hash} />
          </Tabs.Panel>

          <Tabs.Panel type="mount" name="rcm_consumer">
            <Flex gap={8} justifyContent="flex-end">
              <CouponTagIframe id="active_plans_rcm_consumer_coupon" />
              <HelpTagIframe id="active_plans_rcm_consumer" />
            </Flex>

            <BillflowEmbed billingPageID={BILLFLOW_IDS.rcm_consumer} customerID={tenant.stripe_customer_id} hash={tenant.billflow_hash} />
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </Page>
  )
}

const styles = {
  wrapper: {
    '.servicebot-pricing-table.__column-count-1': {
      '.__tier': {
        maxWidth: `320px !important`,
        margin: '0 !important',
      },
    },

    '.servicebot-embed': {
      '&.__subscription-overview, &.__plan-settings, &.__payment-settings, &.__invoices': {
        borderRadius: 7,
        background: 'white',
        boxShadow: `
          0 0 0 1px ${COLORS.shadow},
          0 3px 0 1px ${COLORS.shadow}
        `,
        marginBottom: '1rem',
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
  },

  tabsList: {
    marginBottom: '2rem',
  },
}

export default withSettings(SupportPlans)
