import React from 'react'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { useFormField } from '../../Forms/hooks/useFormField'
import { useStore } from '../utils/useStore'
import { withFormContext } from '../../Forms/context'

import RadioCheckElement from '../../Forms/RadioCheckElement'

export const RootCheckboxCell = (props: any) => {
  const { canEdit, enterEdit, form, isEditing, model, onBlur, onKeyDown, setValue, validations, value } = props

  const ref: any = React.useRef()

  const { formActions } = useFormField({
    model: model,
    form: form,
    validations: validations,
    isValid: size(validations) === 0,
  })

  const updateField: any = useStore((state: any) => state.updateField)

  const handleChange = (event: any) => {
    setValue(event.target.checked)
  }

  const handleFocus = () => {
    enterEdit()
  }

  const handleBlur = () => {
    updateField({ model, value })
    onBlur?.()
  }

  // Sync props value with state value
  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  // Update form state
  React.useEffect(() => {
    if (isUndefined(value)) return

    let newValue = value

    formActions.setValue(newValue)
  }, [value])

  // Focus input when editing
  React.useEffect(() => {
    if (!ref.current || !isEditing) return

    setTimeout(() => {
      ref.current.focus() // focus works only with setTimeout
    }, 0)
  }, [isEditing])

  return (
    <>
      <input
        ref={ref}
        type="checkbox"
        checked={!!value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        disabled={!canEdit}
        css={STYLES.root}
      />

      <RadioCheckElement isChecked={!!value} type="checkbox" size={16} className="mx-auto" />
    </>
  )
}

const STYLES = {
  root: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 3,
  },
}

export const CheckboxCell = withFormContext(RootCheckboxCell)
