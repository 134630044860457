import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import ClientProfileHeader from '../../../ClientProfileHeader'
import Overlay from '../../../Overlay'
import Section from '../../../Section'
import DataFormStatus from '../../../Statuses/DataFormStatus'
import DateInput from '../../../Forms/DateInput'
import Textarea from '../../../Forms/Textarea'
import Input from '../../../Forms/Input'
import Flex from '../../../Flex'
import FormSection from '../../../Forms/FormSection'
import LevelOfCareSelect from '../../../Forms/LevelOfCareSelect'

import { DataFormsBase, mapDispatchToProps, mapStateToProps } from './DataFormsBase'
import { withOverlayError } from '../../../../hocs/withOverlayError'

class InsuranceAuthorization extends DataFormsBase {
  renderHeader = () => (
    <Overlay.Header
      icon="clinical_assessments"
      title={this.props.data_form?.name || this.props.title}
      titleAside={<DataFormStatus status={this.props.data_form?.status} />}
      description={<ClientProfileHeader client={this.props.data_form?.client || this.props.client} />}
    />
  )

  renderContent = () => {
    return (
      <Fragment>
        <Section
          scrollview={{
            id: 'authorization',
            name: 'Authorization',
          }}
          headingType="h2"
          title="Authorization"
          commentsModel="data.authorization"
        >
          <FormSection layout="vertical">
            <LevelOfCareSelect label="Level of Care" model="data.authorization.level_of_care" />

            <Flex>
              <DateInput label="Authorized From" model="data.authorization.started_at" />
              <DateInput label="Authorized Until" model="data.authorization.ended_at" />
              <Input label="Authorized Length of Stay" model="data.authorization.length_of_authorization" suffix="days" size={2} />
            </Flex>

            <DateInput label="Progress Review Due Date" model="data.authorization.progress_review_due_at" />
            <Textarea label="Notes" model="data.authorization.notes" />
          </FormSection>
        </Section>
      </Fragment>
    )
  }
}

InsuranceAuthorization.defaultProps = {
  title: 'Insurance Authorization',
  category: 'insurance_authorization',
  subcategory: 'insurance_authorization',
  signoffWithoutSupervisor: true,
  requireSupervisor: false,
  requestClientSignature: false,
  requestAuthorSignature: false,
  requestSupervisorSignature: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(InsuranceAuthorization))
