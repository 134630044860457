import React from 'react'

import PasswordInput from './PasswordInput'
import { apiCreate } from '../../modules/api'

import useDebounceValue from '../../hooks/useDebounceValue'

const SmartPasswordInput = (props: any) => {
  const { username, ...rest } = props

  const [password, setPassword] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [validPassword, setValidPassword] = React.useState(false)

  const [passwordResult, setPasswordResult] = React.useState({})

  const debouncedPassword = useDebounceValue(password, 1000)

  const runPasswordCheck = async (username: string, password: string) => {
    setIsLoading(true)

    try {
      const result = await apiCreate({
        url: `/password/check`,
        params: {
          username: username,
          password: password,
        },
        notify: false,
      })

      setPasswordResult({
        passwordStrength: { score: result.data.score },
        breached_password: result.data.breached_password,
        feedback: result.data.feedback,
        valid_password: result.data.valid_password,
      })

      setValidPassword(result.data.valid_password)

      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (!debouncedPassword) return

    runPasswordCheck(username, debouncedPassword)
  }, [debouncedPassword])

  React.useEffect(() => {
    if (props.onPasswordValidation) props.onPasswordValidation(validPassword)
  }, [validPassword])

  const onChange = (state: any) => {
    setIsLoading(true)
    setValidPassword(false)

    setPassword(state.value)
  }

  return (
    <PasswordInput
      {...rest}
      isLoading={isLoading}
      onChange={onChange}
      username={props.username}
      passwordStrength={passwordResult?.passwordStrength}
      breachedPassword={passwordResult?.breached_password}
      feedback={passwordResult?.feedback}
      validPassword={passwordResult?.valid_password}
    />
  )
}

export default SmartPasswordInput
