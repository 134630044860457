import React from 'react'
import { v4 as uuid } from 'uuid'
import isEqual from 'react-fast-compare'
import Rating from 'react-rating'
import { transparentize } from 'polished'

import { withFormContext } from './context'
import { COLORS } from '../../theme'

import Field from './Field'
import Flex from '../Flex'
import Glyph from '../Glyph'

import FieldBase from './FieldBase'

class RatingInput extends FieldBase {
  constructor(props) {
    super(props)

    this.state = {
      type: 'RATINGINPUT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: props.form?.getField(props.model) || 0,
      hover: null,
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: props.form?.getField(props.model) || 0,
    }
    this.updateType = 'DATA'
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.isEditable !== this.props.isEditable || nextProps.layout !== this.props.layout) {
      this.updateType = 'STATE'
      return true
    }

    if (!isEqual(this.state, nextState)) {
      this.updateType = 'DATA'
      return true
    }

    if (this.props.value !== nextProps.value) {
      this.updateType = 'PROPS'
      return true
    }

    return false
  }

  /*
    CUSTOM FUNCTIONS
  */
  onHover = (rate) => this.setState({ hover: rate })

  render = () => {
    const { layout, model, type, glyph, fullColor, ...others } = this.props
    const { id, isRequired } = this.state

    return (
      <Field {...others} id={id} isRequired={isRequired}>
        <Flex horizontal>
          <Rating
            stop={10}
            initialRating={this.state.value}
            onChange={this.changeValue}
            onHover={this.onHover}
            readonly={!this.props.isEditable}
            emptySymbol={<Glyph glyph={glyph} size={24} color={transparentize(0.8, COLORS.gray)} />}
            fullSymbol={<Glyph glyph={glyph} size={24} color={fullColor} />}
          />
          {this.state.hover && <div css={styles}>({this.state.hover}/10)</div>}
          {!this.state.hover && <div css={styles}>({this.state.value}/10)</div>}
        </Flex>
      </Field>
    )
  }
}

const styles = {
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '1em',
  marginTop: 4,
  marginLeft: 7,
}

RatingInput.defaultProps = {
  isEditable: true,
  glyph: 'behave',
  fullColor: 'blue',
}

export default withFormContext(RatingInput)
