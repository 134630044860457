import React from 'react'

import { COLORS } from '../../../theme'
import Avatar from '../../../components/Avatar'

export const FilterDropdownValue: React.FC<any> = ({ value, graphic, showAvatar, avatar }) => {
  return (
    <div css={STYLES.value}>
      {(graphic || showAvatar) && (
        <div css={STYLES.valueGraphic}>
          {graphic}
          {showAvatar && <Avatar src={avatar} initials={value} size={18} />}
        </div>
      )}
      {value}
    </div>
  )
}

const STYLES = {
  value: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.text,
  },

  valueGraphic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '0.2rem',
  },
}
