import create from 'zustand'
import { devtools } from 'zustand/middleware'
import produce from 'immer'

const useStore = (set: Function) => ({
  user: {},
  client: {},
  tenant: {},
  tenants: {},
  newUser: {},
  invites: [],
  access: {},
  permissions: {},
  feature_flags: {},
  addons: {},
  debug: {},
  notifications: {},
  subscriptions: [],
  blocks: [],
  ehrBlocks: [],
  myAccountBlocks: [],
  membersBlocks: [],
  membersStateBlocks: [],
  appMessages: [],
  idle: false,
  devBar: false,
  online: true,
  isBehave: false,
  showSecurity: true,
  update: (newState: any) => {
    set(
      produce((draft) => {
        Object.assign(draft, newState)
      }),
    )
  },
})

const store = process.env.NODE_ENV !== 'production' ? create(devtools(useStore)) : create(useStore)
export default store
