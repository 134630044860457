import React from 'react'
import { useSelector } from 'react-redux'

import NavGroup from '../NavGroup'
import NavItem from '../NavItem'
import Glyph from '../Glyph'
import { COLORS } from '../../theme'

type Props = {
  payerID: string
  id?: string
}

const PayerNavItems = ({ payerID, id }: Props) => {
  const basePath = `/insurance-payers/${payerID || id}`

  const user = useSelector((state) => state.me?.user)
  const isBehave = user?.type === 'bh_employee'

  return (
    <>
      <NavGroup label="General">
        <NavItem feature="general_info" label="General Info" to={`${basePath}/general-info`} plan="lite" />
        <NavItem
          feature="files"
          label="Files"
          to={`${basePath}/files`}
          permission="insurance_local_payers.files.view"
          plan="plus"
          featureFlagV2="files"
        />
        <NavItem
          feature="clients"
          label="Clients"
          to={`${basePath}/clients`}
          permission="insurance_local_payers.clients.view"
          plan="lite"
        />
      </NavGroup>

      {/* <NavGroup label="Contacts [TODO]">
        <NavItem
          feature="address_book"
          label="Address Book"
          to={`${basePath}/address-book`}
          permission={isBehave}
          aside={<Glyph glyph="behave_health" size={18} color={COLORS.backdrop} />}
        />
        <NavItem
          feature="phone_book"
          label="Phone Book"
          to={`${basePath}/phone-book`}
          permission={isBehave}
          aside={<Glyph glyph="behave_health" size={18} color={COLORS.backdrop} />}
        />
        <NavItem
          feature="contacts"
          label="Contacts"
          to={`${basePath}/contacts`}
          permission={isBehave}
          aside={<Glyph glyph="behave_health" size={18} color={COLORS.backdrop} />}
        />
        <NavItem
          feature="files"
          label="Faxes & Voicemails"
          to={`${basePath}/faxes-and-voicemails`}
          permission={isBehave}
          aside={<Glyph glyph="behave_health" size={18} color={COLORS.backdrop} />}
        />
      </NavGroup>

      <NavGroup label="Insurance">
        <NavItem
          isDev
          color="green"
          icon="financials"
          label="RCM"
          to={`${basePath}/rcm`}
          permission={isBehave}
          // featureFlagV2="rcm"
        />
      </NavGroup> */}
    </>
  )
}

export default PayerNavItems
