import React from 'react'

import Flex from '../Flex'
import Field from './Field'
import { getToken } from '../../modules/axios'
import { withFormContext } from './context'

import LocalDnD from './LocalDnD'
import AttachmentCard from './AttachmentCard'

import { useFormField } from './hooks/useFormField'

const SingleAttachment = (props: any) => {
  const {
    form,
    model,
    allowPin,
    checkSignatureByTime,
    isEditable,
    isUpdateable,
    title,
    showSignature,
    showHeader,
    showFooter,
    showSubtitle,
    showSignedBy,
    person,
    label,
    timezone,
    width,
    height,
    prefix,
    size,
    suffix,
    placeholder,
    description,
    onUpload,
    testKey,
    validations,
    ...others
  } = props

  const { initialValue, formState, formActions } = useFormField({
    model: model,
    form: form,
    isValid: true,
    defaultEmptyValue: null,
    afterMount: () => {
      setData(form?.getInitialInputFieldValue(model))
      setName(form?.getInitialInputFieldValue(`${model}_name`))
      setContentType(form?.getInitialInputFieldValue(`${model}_content_type`))
    },
    afterReset: () => {
      setData(form?.getInitialInputFieldValue(model))
      setName(form?.getInitialInputFieldValue(`${model}_name`))
      setContentType(form?.getInitialInputFieldValue(`${model}_content_type`))
    },
  })

  const [url, setUrl]: any = React.useState(initialValue)

  const [base64, setBase64]: any = React.useState(null)
  const [data, setData]: any = React.useState(null)
  const [name, setName]: any = React.useState(null)
  const [contentType, setContentType]: any = React.useState(null)

  const hasURL = !!url
  const hasData = !!data

  const hasValue = hasURL || hasData

  const [token, setToken]: any = React.useState(null)

  React.useEffect(() => {
    const findToken = async () => {
      const tokenResult = await getToken()
      setToken(tokenResult?.token)
    }

    findToken()
  }, [])

  // UPDATE FORM
  React.useEffect(() => {
    if (onUpload) onUpload({ model: `${model}_data`, value: data, name: name, contentType: contentType })

    // set the main value
    form.setFieldValue(`${props.model}_data`, base64)

    // update other form fields
    form.setFieldValue(`${props.model}_name`, name)
    form.setFieldValue(`${props.model}_content_type`, contentType)
  }, [data, name, contentType])

  return (
    <Field id={formState.id} isEditable={isEditable} isValid={formState.isValid} description={description} {...others}>
      <Flex vertical gap={8} className="!w-full">
        {hasData && <AttachmentCard isInline title={name} description={contentType} isEditable={false} />}

        {isEditable && !hasValue && (
          <LocalDnD
            acceptMultiple={false}
            onChange={(files: any) => {
              setBase64(files[0].file_data)
              setData(files[0].file_blob)
              setContentType(files[0].content_type)
              setName(files[0].title)
            }}
          />
        )}
      </Flex>

      {!isEditable && !hasValue && <p>No files attached yet</p>}
    </Field>
  )
}

SingleAttachment.defaultProps = {
  type: 'text',
  isEditable: true,
  isBlocked: false,
  isInline: false,
}

export default withFormContext(SingleAttachment)
