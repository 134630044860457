import React from 'react'

import AddressInput from '../../components/Forms/AddressInput'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'

import { SectionCard } from '../../components/SectionCard'

export const InvoiceBilledToSection = () => {
  return (
    <SectionCard title="Billed To" className="!p-3">
      <FormSection maxWidth="100%" className="!gap-1">
        <div className="grid gap-4">
          <Input
            label="Company"
            model="billed_to_company_name"
            validations={{
              presence: {
                message: 'Please add a company name',
              },
            }}
          />
          <Input label="Name" model="billed_to_name" />
          <Input
            label="Email"
            model="billed_to_email"
            validations={{
              presence: {
                message: 'Please add an email',
              },
            }}
          />
          <Input label="Phone" model="billed_to_phone_no" />
          <AddressInput actionLabel="Find Address" model="billed_to_address" />
        </div>
      </FormSection>
    </SectionCard>
  )
}
