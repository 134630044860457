import React from 'react'
import IframeResizer from 'iframe-resizer-react'

import { niceAmount } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withPermissions from '@behavehealth/hocs/withPermissions'
import withSettings from '@behavehealth/hocs/withSettings'

import {
  Button,
  CardLink,
  CardSubtitle,
  CardTitle,
  Glyph,
  Grid,
  H1,
  Header,
  Icon,
  Page,
  Iframe,
  HelpTagIframe,
} from '@behavehealth/components'
import NotionOverlay from '@behavehealth/components/Notion/NotionOverlay'

import Form from '@behavehealth/components/Forms/Form'
import FormSection from '@behavehealth/components/Forms/FormSection'
import TableArrayForm from '@behavehealth/components/Forms/TableArrayForm'
import Input from '@behavehealth/components/Forms/Input'
import AmountInput from '@behavehealth/components/Forms/AmountInput'
import EmailInput from '@behavehealth/components/Forms/EmailInput'
import PhoneInput from '@behavehealth/components/Forms/PhoneInput'
import MultiObjectSelector from '@behavehealth/components/Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '@behavehealth/components/Forms/Selectors/Object/ObjectSelector'

type Props = {
  url?: string
  icon: string
  glyph: string
  title: string
  subtitle: string
  testKey: string
  titleAfter?: React.ReactNode
}

const IframeLinkCard = withPermissions((props: Props) => {
  const { icon, glyph, title, subtitle, titleAfter, url } = props

  return (
    <Iframe
      title={title}
      icon="behave_health"
      url={url}
      overlayWidth={180}
      children={
        <CardLink href="#" showChevron graphic={(icon && <Icon icon={icon} />) || (glyph && <Glyph glyph={glyph} />)}>
          <CardTitle title={title} after={titleAfter} />
          <CardSubtitle subtitle={subtitle} />
        </CardLink>
      }
    />
  )
})

const Marketing = () => {
  const form = React.createRef()

  const [editable, setEditable] = React.useState(false)

  return (
    <Page feature="marketing" plan="lite" breakpoint="0" flex="3rem" help={<HelpTagIframe id="marketing" />}>
      <Grid gap="1rem">
        {/* DEFAULT STATS */}
        <div>
          <Header icon="dashboard" title="Analytics Services" className="!py-3 !px-0" />
          <Grid columns="repeat(auto-fit, minmax(280px, 1fr))" gap={8}>
            <IframeLinkCard icon="behave_health" title="Umami" subtitle="View Umami" url="https://zebra.xyz123hq.com" />
            <IframeLinkCard icon="behave_health" title="Plausible" subtitle="View Plausible" url="https://hippo.xyz123hq.com" />
            <IframeLinkCard icon="behave_health" title="Countly" subtitle="View Countly" url="https://falcon.xyz123hq.com" />
          </Grid>
        </div>

        <div>
          <Header icon="dashboard" title="Planning Services" className="!py-3 !px-0" />
          <Grid columns="repeat(auto-fit, minmax(280px, 1fr))" gap={8}>
            <IframeLinkCard icon="behave_health" title="Calendar" subtitle="View Calendar" url="https://calendar.behavehealth.com" />
            <IframeLinkCard icon="behave_health" title="Chatwoot" subtitle="View Chatwoot" url="https://bobcat.xyz123hq.com" />
            <IframeLinkCard icon="behave_health" title="Outline" subtitle="View Outline" url="https://cobra.xyz123hq.com" />
          </Grid>
        </div>

        <div>
          <Header icon="dashboard" title="Notion" className="!py-3 !px-0" />
          <Grid columns="repeat(auto-fit, minmax(280px, 1fr))" gap={8}>
            <NotionOverlay icon="behave_health" title="Notion Test" blockID="60ee12335d8346f0979aabf217f0bcb8">
              <CardLink href="#" showChevron graphic={<Icon icon="behave_health" />}>
                <CardTitle title="Calendar" />
              </CardLink>
            </NotionOverlay>
          </Grid>
        </div>

        <div>
          <Header icon="checklist" title="Table Array Input" className="!py-3 !px-0" />

          <Form
            useFullModel
            getForm={form}
            isEditable={editable}
            initialModel={{
              services: INITIAL_DATA,
            }}
          >
            {({ data }: any) => (
              <FormSection layout="vertical" maxWidth="100%">
                <TableArrayForm name="Services" model="services" inputs={INPUTS} />
                <Button label={editable ? 'Cancel' : 'Edit'} onClick={() => setEditable((c) => !c)} />

                <pre>{JSON.stringify({ formData: data }, null, 2)}</pre>
              </FormSection>
            )}
          </Form>
        </div>

        <IframeResizer log src="https://behavehealth.com" style={{ width: '1px', minWidth: '100%' }} />
      </Grid>
    </Page>
  )
}

const INPUTS = [
  {
    name: 'Name',
    width: 'minmax(240px, 1fr)',
    isRequired: true,
    element: (id: string) => (
      <Input
        model={`${id}.name`}
        validations={{
          presence: {
            message: 'Please enter a name',
          },
        }}
      />
    ),
  },
  {
    name: 'Email',
    width: 'minmax(240px, 1fr)',
    element: (id: string) => <EmailInput model={`${id}.email`} />,
  },
  {
    name: 'Phone',
    width: 'minmax(240px, 1fr)',
    element: (id: string) => <PhoneInput model={`${id}.phone`} />,
  },
  {
    name: 'Units',
    width: '160px',
    element: (id: string) => <Input type="number" model={`${id}.units`} />,
  },
  // {
  //   name: 'Price',
  //   width: '160px',
  //   element: (id: string) => <AmountInput model={`${id}.price`} />,
  // },
  // {
  //   name: 'Total Amount',
  //   width: '160px',
  //   element: (id: string, data: any) => {
  //     const { price, units } = data[id]

  //     if (!price || !units) return null

  //     return niceAmount(units * price)
  //   },
  // },
  {
    name: 'Client',
    width: 'minmax(240px, 1fr)',
    element: (id: string) => (
      <ObjectSelector
        type="clients"
        icon="clients"
        selectTitle={(data: any) => data?.name}
        selectDescription={(data: any) => `#${data?.behave_id}`}
        model={`${id}.client`}
      />
    ),
  },
  {
    name: 'Clients',
    width: 'minmax(240px, 1fr)',
    element: (id: string) => (
      <MultiObjectSelector
        type="clients"
        icon="clients"
        selectTitle={(data: any) => data?.name}
        selectDescription={(data: any) => `#${data?.behave_id}`}
        model={`${id}.clients`}
      />
    ),
  },
]

const INITIAL_DATA = [
  {
    name: '111',
    price: 111,
    client: {
      id: 'vNwNjp2',
      avatar: null,
      behave_id: 'ABVI000001',
      care_level: 'sober_living',
      client_portal_status: null,
      collaboratemd_id: null,
      color_code: null,
      created_at: '2022-01-04T18:08:28.790-05:00',
      dob: '1993-11-15T19:00:00.000-05:00',
      email: 'abbigail.villarreal@gmail.com',
      first_name: 'Abbigail',
      gender: 'female',
      has_portal_access: true,
      invited_to_portal_at: '2022-01-04T18:08:28.782-05:00',
      is_billed_by_behave: false,
      last_logged_in_at: '2022-01-22T11:54:05.893-05:00',
      last_name: 'Villarreal',
      middle_name: '',
      name: 'Abbigail Villarreal',
      phone_no: '682-887-1978',
      status: 'current',
      type: 'Resident',
      updated_at: '2022-01-04T23:23:34.898-05:00',
    },
    client_id: 'vNwNjp2',
    client_ids: ['vNwNjp2', 'xW0WJAb', 'rM0MmNX'],
    clients: [
      {
        id: 'vNwNjp2',
        avatar: null,
        behave_id: 'ABVI000001',
        care_level: 'sober_living',
        client_portal_status: null,
        collaboratemd_id: null,
        color_code: null,
        created_at: '2022-01-04T18:08:28.790-05:00',
        dob: '1993-11-15T19:00:00.000-05:00',
        email: 'abbigail.villarreal@gmail.com',
        first_name: 'Abbigail',
        gender: 'female',
        has_portal_access: true,
        invited_to_portal_at: '2022-01-04T18:08:28.782-05:00',
        is_billed_by_behave: false,
        last_logged_in_at: '2022-01-22T11:54:05.893-05:00',
        last_name: 'Villarreal',
        middle_name: '',
        name: 'Abbigail Villarreal',
        phone_no: '682-887-1978',
        status: 'current',
        type: 'Resident',
        updated_at: '2022-01-04T23:23:34.898-05:00',
        checked: true,
      },
      {
        id: 'xW0WJAb',
        avatar: null,
        behave_id: 'ALBA000063',
        care_level: null,
        client_portal_status: null,
        collaboratemd_id: null,
        color_code: null,
        created_at: '2022-01-04T18:08:34.407-05:00',
        dob: '1983-06-14T20:00:00.000-04:00',
        email: 'britt@example.org',
        first_name: 'Alberta',
        gender: 'female',
        has_portal_access: false,
        invited_to_portal_at: null,
        is_billed_by_behave: false,
        last_logged_in_at: null,
        last_name: 'Bailey',
        middle_name: 'Krajcik',
        name: 'Alberta Krajcik Bailey',
        phone_no: '(714) 348-5346',
        status: 'current',
        type: 'Resident',
        updated_at: '2022-01-04T18:08:34.429-05:00',
        checked: true,
      },
      {
        id: 'rM0MmNX',
        avatar: null,
        behave_id: 'ALCA000001',
        care_level: 'sober_living',
        client_portal_status: null,
        collaboratemd_id: null,
        color_code: null,
        created_at: '2022-01-04T18:08:28.576-05:00',
        dob: '1981-11-25T19:00:00.000-05:00',
        email: 'alexia.cannon@gmail.com',
        first_name: 'Alexia',
        gender: 'female',
        has_portal_access: false,
        invited_to_portal_at: null,
        is_billed_by_behave: false,
        last_logged_in_at: null,
        last_name: 'Cannon',
        middle_name: '',
        name: 'Alexia Cannon',
        phone_no: '271-933-9226',
        status: 'current',
        type: 'Resident',
        updated_at: '2022-01-04T18:08:28.631-05:00',
        checked: true,
      },
    ],
    _id: '70463369-10e1-42b7-b3be-54ab372f73c9',
    email: '',
    phone: '',
    units: 1,
  },
  {
    name: '222',
    price: 222,
    client_id: null,
    client_ids: [],
    _id: '2ba14fd8-35a6-45c1-8aba-155b7263b0ee',
    email: '',
    phone: '',
    units: 2,
  },
  {
    name: '333',
    price: 333,
    client_id: null,
    client_ids: [],
    _id: '60f73e7c-bea2-477a-88ff-3975abe312ba',
    email: '',
    phone: '',
    units: 3,
  },
]

export default withPageError(withSettings(Marketing))
