import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  refused: {
    label: 'Refused Collection',
    color: 'red',
  },
  collected: {
    label: 'Collected',
    color: 'blue',
  },
}

const Status = (props: any) => {
  if (!props.status) return null

  return <SmartStatus statuses={statuses} {...props} />
}

export default Status
