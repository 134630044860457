import React from 'react'

const MESSAGES: any = {
  create: (props: any) => (
    <>
      {props.author} added the {props.record} <i>"{props.event.trackable?.title}"</i>.
    </>
  ),
  update: (props: any) => (
    <>
      {props.author} updated the {props.record} <i>"{props.event.trackable?.title}"</i>.
    </>
  ),
  destroy: (props: any) => (
    <>
      {props.author} deleted the todo <i>"{props.event.trackable?.title}"</i>.
    </>
  ),
  check: (props: any) => (
    <>
      {props.author} completed the {props.record} <i>"{props.event.trackable?.title}"</i>.
    </>
  ),
  uncheck: (props: any) => (
    <>
      {props.author} uncompleted the {props.record} <i>"{props.event.trackable?.title}"</i>.
    </>
  ),
  subtask_change: (props: any) => (
    <>
      {props.author} updated a task of the {props.record} <i>"{props.event.trackable?.title}"</i>.
    </>
  ),
}

export default (props: any) => MESSAGES[props.event?.action_type]?.(props) || null
