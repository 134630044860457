import React from 'react'

import Input from '../Input'
import StateSelect from './StateSelect'

const AddressInputs = ({ model, isRequired = false }: any) => (
  <>
    <Input
      label="Address Line 1"
      model={`${model}.address_line_1`}
      validations={
        isRequired && {
          presence: {
            message: 'Please enter an address line',
          },
        }
      }
    />
    <Input label="Address Line 2" model={`${model}.address_line_2`} />
    <Input
      label="City"
      model={`${model}.city`}
      validations={
        isRequired && {
          presence: {
            message: 'Please enter a city',
          },
        }
      }
    />

    <StateSelect
      label="State"
      model={`${model}.state`}
      validations={
        isRequired && {
          presence: {
            message: 'Please enter a state',
          },
        }
      }
    />

    <Input
      label="Zip Code"
      model={`${model}.zip_code`}
      size={8}
      validations={
        isRequired && {
          presence: {
            message: 'Please enter a state code',
          },
        }
      }
    />
  </>
)

AddressInputs.defaultProps = {
  model: 'address',
}

export default AddressInputs
