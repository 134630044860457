import React from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'

import { COLORS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import NavGroup from '../../components/NavGroup'
import NavItem from '../../components/NavItem'
import Overlay from '../../components/Overlay'
import SummonOverlay from '../../components/SummonOverlay'

import { ProfileContactDetails } from './components/ProfileContactDetails'
import { ProfileWithTooltip } from './components/ProfileWithTooltip'
import { ReferralMessages } from './components/ReferralMessages'

import { Attachments } from './constructs/Attachments'
import { ClientDetails } from './constructs/ClientDetails'
import { ConsentForms } from './constructs/ConsentForms'
import { DataCategories } from './constructs/DataCategories'
import { PartnerServicesOverlay } from './constructs/PartnerServicesOverlay'
import { ReferralDetails } from './constructs/ReferralDetails'
import { TransportDetails } from './constructs/TransportDetails'

import { useMockReferralsStore } from './useMockReferralsStore'

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const RootReferralReceivedOverlay = (props: any) => {
  const { dataID } = props

  const match = useRouteMatch()
  const isNew = !dataID || dataID === 'new'

  const referralsData = useMockReferralsStore((state) => state.data)
  const saveReferral = useMockReferralsStore((state) => state.saveReferral)
  const deleteReferral = useMockReferralsStore((state) => state.deleteReferral)
  const addUpdate = useMockReferralsStore((state) => state.addUpdate)

  const { data: partnership }: any = useGet({
    name: ['organizations', match.params?.['resource_id']],
    url: `/organizations/${match.params?.['resource_id']}`,
  })

  const [messagesVisible, setMessagesVisible] = React.useState(true)

  const partnerName = partnership?.other_partner?.name

  const storeRecord = React.useMemo(() => {
    return referralsData?.find((o: any) => o.id === dataID)
  }, [referralsData, dataID])

  const [initialData] = React.useState(isNew ? props.initialData : storeRecord)

  const location = useLocation()
  const history = useHistory()

  const { timezone, tenant, user } = useSettings()

  const [tab, setTab] = React.useState(TABS[0].id)
  const [data, setData] = React.useReducer(dataReducer, initialData)

  const [client, setClient] = React.useState(data?.client)
  const [partners, setPartners] = React.useState(data?.partners || [])
  const [partner, setPartner] = React.useState(data?.partner || [])

  const [isEditable, setIsEditable] = React.useState(false)
  const [formValid, setFormValid] = React.useState(false)

  const [selectedPartner, setSelectedPartner] = React.useState<any>(null)

  const activeTabConfig = React.useMemo(() => {
    return TABS.find((o) => o.id === tab)
  }, [tab])

  const ActiveComponent = React.useMemo(() => {
    return activeTabConfig?.component
  }, [activeTabConfig])

  const close = () => {
    if (props.onClose) return props.onClose()

    if (location.parent) {
      if (location.parent.url) history.push(location.parent.url)
      else history.push(location.parent)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  const handleDataAccept = () => {
    saveReferral({
      id: data.id,
      sent_to_status: 'accepted_data',
    })

    addUpdate(data.id, {
      name: `${partnerName} accepted Client Records`,
      author: { id: 1, name: 'John Doe', avatar: '' },
      action: 'sent_to_accepted_data',
    })
  }

  const handleReferralAccept = () => {
    saveReferral({
      id: data.id,
      sent_to_status: 'accepted_referral',
    })

    addUpdate(data.id, {
      name: `${partnerName} accepted referral`,
      author: { id: 1, name: 'John Doe', avatar: '' },
      action: 'sent_to_accepted_referral',
    })
  }

  const handleReferralDecline = () => {
    saveReferral({
      id: data.id,
      sent_to_status: 'declined_referral',
    })

    addUpdate(data.id, {
      name: `${partnerName} declined referral`,
      author: { id: 1, name: 'John Doe', avatar: '' },
      action: 'sent_to_declined_referral',
    })
  }

  const deleteRecord = () => {
    if (!data?.id) return

    deleteReferral(data.id)
    close()
  }

  React.useEffect(() => {
    if (storeRecord) setData(storeRecord)
  }, [storeRecord])

  if (!isNew && !initialData) return null

  return (
    <Overlay
      fullheight
      showBackdrop={isEditable}
      closeOnBackdrop
      onClose={close}
      position={isNew ? 'center' : 'right'}
      maxWidth={messagesVisible ? 110 : 90}
    >
      <Overlay.Header icon="referrals" title={'Referral Received'} />

      <SummonOverlay
        isOpen={!!selectedPartner}
        onClose={() => {
          setSelectedPartner(null)
        }}
        overlay={<PartnerServicesOverlay name={selectedPartner?.name} />}
      ></SummonOverlay>

      <Overlay.SubHeader className="flex items-center text-[0.92rem] !py-2 !px-3">
        <div className="flex items-center mr-5">
          <div className="font-[600] mr-1">Referring Client:</div>
          <ProfileWithTooltip name={data.client.name} tooltipContent={<ProfileContactDetails name={data.client.name} />} />
        </div>

        <div className="flex items-center mr-5">
          <div className="font-[600] mr-1">Referring From:</div>
          <ProfileWithTooltip
            name={data.sent_from.name}
            description="Healthcare Facility"
            tooltipContent={
              <ProfileContactDetails
                name={data.sent_from.name}
                after={
                  <Button
                    label="View Services"
                    type="primary"
                    size={200}
                    onClick={() => {
                      setSelectedPartner(data.sent_from)
                    }}
                  />
                }
              />
            }
          />
        </div>

        <div className="flex items-center mr-5">
          <div className="font-[600] mr-1">Referring To:</div>
          <ProfileWithTooltip
            name={data.sent_to.name}
            description="Healthcare Facility"
            tooltipContent={
              <ProfileContactDetails
                name={data.sent_to.name}
                after={
                  <Button
                    label="View Services"
                    type="primary"
                    size={200}
                    onClick={() => {
                      setSelectedPartner(data.sent_to)
                    }}
                  />
                }
              />
            }
          />
        </div>

        <div className="ml-auto">
          <Button
            label={messagesVisible ? 'Hide Messages' : 'Show Messages'}
            size={100}
            type="minimal"
            icon="community_invites"
            onClick={() => {
              setMessagesVisible((c) => !c)
            }}
          />
        </div>
      </Overlay.SubHeader>

      {data.sent_to_status === 'accepted_data' && (
        <Alert small glyph="info" className="!rounded-none border-b border-0 border-solid border-divider">
          You confirmed availability for this referral. You can now review the Client Records and accept or decline the referral.
        </Alert>
      )}

      {data.sent_to_status === 'declined_data' && (
        <Alert small glyph="decline" type="negative" className="!rounded-none border-b border-0 border-solid border-divider">
          You declined this referral Client Records. You can still accept and review the Client Records while the referral request is
          pending.
        </Alert>
      )}

      {data.sent_to_status === 'declined_referral' && (
        <Alert small glyph="decline" type="negative" className="!rounded-none border-b border-0 border-solid border-divider">
          You declined this referral
        </Alert>
      )}

      {data.sent_to_status === 'accepted_referral' && (
        <Alert small glyph="check" type="positive" className="!rounded-none border-b border-0 border-solid border-divider">
          You accepted this referral
        </Alert>
      )}

      <Overlay.Content className="flex flex-nowrap !overflow-hidden">
        <nav className="p-4 h-full overflow-y-auto grow-0 shrink-0 basis-[220px] border-r border-solid border-0 border-divider">
          {TAB_GROUPS.map((group) => (
            <NavGroup key={group.label} label={group.label}>
              {group.tabs.map((o) => (
                <NavItem
                  key={o.id}
                  size={200}
                  isActive={tab === o.id}
                  label={o.label}
                  icon={o.icon}
                  color={o.color}
                  onClick={() => {
                    setTab(o.id)
                  }}
                />
              ))}
            </NavGroup>
          ))}
        </nav>

        {activeTabConfig && ActiveComponent && (
          <ActiveComponent
            isNew={isNew}
            icon={activeTabConfig.icon}
            title={activeTabConfig.label}
            data={data}
            setData={setData}
            timezone={timezone}
            initialData={initialData}
            isEditable={isEditable}
            client={client}
            setClient={setClient}
            partner={partner}
            setPartner={setPartner}
            partners={partners}
            setPartners={setPartners}
            setFormValid={setFormValid}
          />
        )}

        {messagesVisible && <ReferralMessages />}
      </Overlay.Content>

      <Grid gap="0.75rem" className="px-4 pb-4 bg-[#F7F8FB]">
        {data.sent_from_status === 'pending' &&
          data.sent_to_status !== 'accepted_referral' &&
          data.sent_to_status !== 'declined_referral' && (
            <>
              <Flex gap="0.75rem">
                <>
                  {data.sent_to_status !== 'accepted_data' && (
                    <>
                      <ConfirmDialog
                        title="Confirm Availability"
                        message="Are you sure you want to accept and review this referral Client Records?"
                        onYes={handleDataAccept}
                        className="flex-[3_1_auto]"
                        yesLabel="Confirm Availability"
                      >
                        <Button label="Confirm Availability" glyph="check" color="green" type="primary" />
                      </ConfirmDialog>

                      <ConfirmDialog
                        title="Decline Referral"
                        glyph="decline"
                        message="Are you sure you want to decline this Referral?"
                        onYes={handleReferralDecline}
                        className="flex-[1_1_auto]"
                        yesLabel="Decline Referral"
                        yesColor="red"
                      >
                        <Button label="Decline Referral" glyph="decline" color="red" type="primary" flex="1 1 auto" />
                      </ConfirmDialog>
                    </>
                  )}
                </>

                {data.sent_to_status === 'accepted_data' && (
                  <>
                    <ConfirmDialog
                      title="Accept Referral"
                      message="Are you sure you want to accept and review this referral Referral?"
                      onYes={handleReferralAccept}
                      className="flex-[3_1_auto]"
                      yesLabel="Accept Referral"
                    >
                      <Button label={`Accept Referral from ${tenant.name}`} glyph="check" color="green" type="primary" />
                    </ConfirmDialog>

                    <ConfirmDialog
                      title="Decline Referral"
                      glyph="decline"
                      message="Are you sure you want to decline this Referral?"
                      onYes={handleReferralDecline}
                      className="flex-[1_1_auto]"
                      yesLabel="Decline Referral"
                      yesColor="red"
                    >
                      <Button label={`Decline Referral from ${tenant.name}`} glyph="decline" color="red" type="primary" flex="1 1 auto" />
                    </ConfirmDialog>
                  </>
                )}
              </Flex>
            </>
          )}
      </Grid>
    </Overlay>
  )
}

const TAB_GROUPS = [
  {
    // label: '1. Referral Details',
    tabs: [
      {
        id: 'referral_details',
        label: 'Summary',
        icon: 'dashboard',
        color: COLORS.vividBlue,
        component: ReferralDetails,
      },
    ],
  },
  {
    label: '1. Client & Consent',
    tabs: [
      {
        id: 'client_details',
        label: 'Client Details',
        icon: 'clients',
        color: COLORS.vividBlue,
        component: ClientDetails,
      },
      {
        id: 'consent_form',
        label: 'Consent Forms',
        icon: 'checklist',
        color: COLORS.green,
        component: ConsentForms,
      },
    ],
  },
  {
    label: '2. Client Records',
    tabs: [
      {
        id: 'data_transfer',
        label: 'Client Records',
        icon: 'web_form',
        color: COLORS.blue,
        component: DataCategories,
      },
      {
        id: 'attachments',
        label: 'Attachments',
        icon: 'files',
        color: COLORS.vividBlue,
        component: Attachments,
      },
    ],
  },
  {
    label: '3. Transport Details',
    tabs: [
      {
        id: 'transport_details',
        label: 'Transport Details',
        icon: 'vehicles',
        color: COLORS.vividBlue,
        component: TransportDetails,
      },
    ],
  },
]

export const TABS = TAB_GROUPS.reduce((acc, group) => {
  return [...acc, ...group.tabs]
}, [])

export const ReferralReceivedOverlay = withOverlayError(RootReferralReceivedOverlay)
