import React from 'react'

import Chart from '@behavehealth/components/Chart'

var dataAxis = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T']
var data = [220, 182, 191, 234, 290, 330, 310, 123, 442, 321, 90, 149, 210, 122, 133, 334, 198, 123, 125, 220]
var yMax = 500
var dataShadow = []

for (var i = 0; i < data.length; i++) {
  dataShadow.push(yMax)
}

const option = {
  tooltip: {
    trigger: 'axis',
  },
  xAxis: {
    data: dataAxis,
    axisLabel: {
      inside: true,
      textStyle: {
        color: '#fff',
      },
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    z: 10,
  },
  yAxis: {
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      textStyle: {
        color: '#999',
      },
    },
  },
  dataZoom: [
    {
      type: 'inside',
    },
  ],
  series: [
    {
      type: 'bar',
      showBackground: true,
      data: data,
    },
  ],
}

const ChartExample = (props) => {
  return <Chart option={option} {...props} />
}

export default ChartExample
