import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { MEDIA_QUERY } from '@behavehealth/theme'
import { PropertiesTable } from '@behavehealth/components/Tables'
import { Tenant, User } from '@behavehealth/declarations'
import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useServerFunctionality } from '@behavehealth/hooks/useServerFunctionality'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withSettings from '@behavehealth/hocs/withSettings'

import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

import {
  Button,
  Card,
  Chotomate,
  Grid,
  HelpTagIframe,
  Map,
  MapPopup,
  Page,
  PageLayout,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@behavehealth/components'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

import { ClearSampleDataBanner } from '../../components/ClearSampleDataBanner'
import { NewPropertyLocationOverlay } from '@behavehealth/constructs/Locations/NewPropertyLocationOverlay'

const REDIRECT_BASE_URL = {
  house: '/locations',
  office: '/offices',
  organization: '/community/organizations',
}

type Props = {
  user: User
  tenant: Tenant
}

const PropertiesPage = () => {
  return (
    <>
      <PropertiesPageIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<NewPropertyLocationOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const PropertiesPageIndex: React.FC<Props> = ({ user }) => {
  const { tenant } = useSettings()
  const { server, sets, tableProps } = useServerFunctionality()

  const apiKey = ['locations', server.keys]

  const { data, meta, isFetching, isLoading }: any = useGet({
    name: apiKey,
    url: `/houses`,
    params: {
      only_parents: true,
      ...server.params,
    },
    options: server.options,
  })

  const { mutate: deleteRecords } = useDelete({
    name: ['locations'],
    url: '/houses',
    invalidate: 'locations',
  })

  const isEmpty = size(data) <= 0

  const actions = <Button as={Link} label="Add Location" type="primary" glyph="add" link="new" permission="properties.actions.create" />

  const allProperties = React.useMemo(() => {
    const result: any = []

    if (!data) return result

    for (const property of data) {
      result.push(property)
      if (size(property.houses) > 0) result.push(...property.houses)
    }

    return result
  }, [data])

  return (
    <Page
      useWrapper={false}
      feature="places"
      title="Locations"
      help={<HelpTagIframe id="locations" />}
      breakpoint="0"
      actions={!isEmpty && actions}
    >
      <Chotomate name="properties" ready={!isLoading} />

      {tenant?.has_seed_data && <ClearSampleDataBanner />}

      <PageLayout className="!py-0 !px-4">
        <Tabs defaultTab="list">
          <TabList className="!mb-4">
            <Tab label="List View" name="list" />
            <Tab label="Map View" name="map" />
          </TabList>

          <Filters config={filtersConfig} onUpdate={sets.filters} localStorageKey="locations" />

          <TabPanels>
            <TabPanel name="list" className="!mt-4">
              <Grid>
                <Card>
                  <PropertiesTable
                    {...tableProps(meta)}
                    mainLinkAs={NavLink}
                    testKey="locations_table"
                    useAutoPageSize
                    data={data}
                    apiKey={apiKey}
                    isLoading={isLoading}
                    isFetching={!isLoading && isFetching}
                    emptyActions={actions}
                    localStorageKey="properties"
                    to={(id) => `/locations/${id}`}
                    batchActionsConfig={[
                      {
                        type: 'delete',
                        permission: 'properties.actions.delete',
                        action: async ({ ids }: any) => {
                          await deleteRecords(ids.join(','))
                        },
                      },
                    ]}
                  />
                </Card>
              </Grid>
            </TabPanel>

            <TabPanel type="mount" name="map" className="!mt-4">
              <Card>
                <Map
                  css={mapStyles}
                  lat={tenant?.lat}
                  lon={tenant?.lon}
                  stateName={tenant?.state}
                  theme={user?.prefs?.map_style}
                  localStorageKey="properties"
                  popup={(location: any) => <MapPopup location={location} to={`${REDIRECT_BASE_URL[location.type]}/${location.id}`} />}
                  markers={{
                    properties: {
                      visible: true,
                      data: allProperties,
                    },
                  }}
                />
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PageLayout>
    </Page>
  )
}

const mapStyles = {
  width: '100%',
  minHeight: '30rem',
  height: 'calc(100vh - 12rem)',

  [MEDIA_QUERY[1]]: {
    height: 'calc(100vh - 10rem)',
  },
}

const filtersConfig = {
  name: {
    label: 'Name',
    type: 'string',
  },
  tags: DEFAULT_FILTERS.tags,
  category: {
    label: 'Category',
    type: 'multi_select',
    options: [
      { label: 'Housing', value: 'housing' },
      { label: 'Office', value: 'office' },
      { label: 'Mixed category', value: 'mixed_category' },
    ],
  },
  phone_no: {
    label: 'Phone',
    type: 'string',
  },
  gender: {
    type: 'multi_select',
    label: 'Accepted Gender',
    options: [
      { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
      { label: 'Mixed', value: 'mixed' },
      { label: 'Empty', value: null },
    ],
  },
  is_shared_with_portal: {
    label: 'Client Portal Sharing',
    type: 'boolean',
  },
  address: {
    label: 'Address',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}

export default withPageError(withSettings(PropertiesPage))
