import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'

import { COLORS } from '../../../theme'
import Glyph from '../../../components/Glyph'
import Tooltip from '../../../components/Tooltip'

export const InsertMenuItem = (props: any) => {
  const { className, glyph, isActive, isAnimated, isDisabled, label, onClick, showChevron, after, tooltip, description } = props

  const color = COLORS[props.color] || props.color || COLORS.default

  const rootClasses = clsx(className, isActive && 'is-active', isAnimated && 'is-animated', isDisabled && 'is-disabled')

  return (
    <div onClick={onClick} css={STYLES.menuItem} className={rootClasses}>
      <div css={STYLES.glyphWrapper} className="graphic" style={{ backgroundColor: tint(0.1, color) }}>
        {glyph && <Glyph glyph={glyph} size={16} />}
      </div>

      <div>
        <div>{label}</div>
        {description && <div className="text-text-muted opacity-80 text-[0.92rem] font-[400] -mt-0.5">{description}</div>}
      </div>

      {tooltip && <Tooltip content={tooltip} className="ml-auto mr-4" />}

      {showChevron && (
        <Glyph glyph="chevron" size={15} color={COLORS.blue} css={{ marginLeft: 'auto', marginRight: '-0.5rem !important' }} />
      )}

      {after || <Glyph glyph="add" size={14} className="add-glyph" color={COLORS.blue} />}
    </div>
  )
}

const STYLES = {
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.6rem 1.25rem',
    cursor: 'pointer',
    fontSize: '0.94rem',
    fontWeight: 600,
    color: COLORS.text,
    borderBottom: `1px solid ${COLORS.divider}`,
    position: 'relative',
    transformOrigin: 'left',
    backgroundColor: COLORS.white,

    '&:hover': {
      backgroundColor: tint(0.9, COLORS.vividBlue),

      '.graphic': {
        transform: 'scale3d(1.15, 1.15, 1.15)',
      },

      '.add-glyph': {
        display: 'block',
      },
    },

    '&.is-active': {
      fontWeight: 700,
      backgroundColor: tint(0.8, COLORS.vividBlue),
    },

    '&.is-disabled': {
      opacity: 0.5,

      svg: {
        fill: COLORS.white,
      },
    },

    '.add-glyph': {
      display: 'none',
      position: 'absolute',
      top: '50%',
      right: '0.75rem',
      transform: 'translateY(-50%)',
    },
  },

  glyphWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    width: 22,
    height: 22,
    marginRight: '0.5rem',
    transition: 'transform 50ms cubic-bezier(0.39, 0.575, 0.565, 1)',

    svg: { fill: COLORS.white },
  },
}
