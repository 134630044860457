import React from 'react'
import { opacify } from 'polished'
import { useParams } from 'react-router-dom'

import { COLORS } from '../../theme'
import { usDate, usTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate, useDelete } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardContent from '../../components/CardContent'
import CardHeader from '../../components/CardHeader'
import CardMeta from '../../components/CardMeta'
import CardTitle from '../../components/CardTitle'
import DataFormStatus from '../../components/Statuses/DataFormStatus'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Markup from '../../components/Markup'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SignatureView from '../../components/SignatureView'
import SummonOverlay from '../../components/SummonOverlay'
import Text from '../../components/Typography/Text'

import { DataFormAddendumOverlay } from './DataFormAddendumOverlay'

export const DataFormAddendum = (props: any) => {
  const { data, variant } = props
  const { isPortal, timezone, user } = useSettings()
  const { id }: any = useParams()

  const isDraft = data.status?.includes?.('draft')
  const isPendingReview = data?.status?.includes('pending_review')
  const isSignedOff = data.status === 'signed_off'

  const isAuthor = user?.id === data?.author?.id
  const isSupervisor = props.isSupervisor || user?.id === data?.supervisor?.id

  const { mutateAsync: updateAsync } = useUpdate({
    name: ['update_addendum'],
    url: `/addendums/${data.id}`,
    invalidate: 'data_forms',
  })

  const { mutateAsync: deleteAsync } = useDelete({
    name: ['delete_addendum'],
    url: `/addendums/${data.id}`,
    invalidate: 'data_forms',
  })

  const deleteAddendum = async () => {
    try {
      await deleteAsync()
    } catch (error) {
      console.error(error)
    }
  }

  const signOff = async (signature: any) => {
    try {
      await updateAsync({ [signature.model]: signature.value })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Card variant={variant}>
        <CardHeader
          paddingY="0.65rem"
          css={{
            borderRadius: 0,
            background: COLORS.white,
            boxShadow: `0 0 0 1px ${opacify(-0.05, COLORS.divider)}`,
          }}
        >
          <Flex nowrap gap="1rem">
            <div className="grid gap-1 flex-[1_1_auto]">
              <CardTitle title={data.title} />

              <CardMeta>
                <DataFormStatus status={data?.status} />
                <Text glyph="user_neutral" description={data?.author?.name} />
                <Text glyph="date" description={usDate(data?.created_at, timezone)} />
                <Text glyph="time" description={usTime(data?.created_at, timezone)} />
              </CardMeta>
            </div>

            {!isPortal && !isSignedOff && (
              <Flex nowrap centerY gap="0.5rem" className="pr-2">
                <SummonOverlay overlay={<DataFormAddendumOverlay data={data} />}>
                  <Button label="Edit" glyph="edit" type="default" size={100} />
                </SummonOverlay>

                <DeleteDialog
                  title={`Delete Addendum?`}
                  message={`Are you sure you want to delete this Addendum? This action cannot be undone.`}
                  onYes={deleteAddendum}
                >
                  <Button label="Delete" glyph="delete" color="red" type="default" size={100} />
                </DeleteDialog>
              </Flex>
            )}
          </Flex>
        </CardHeader>

        <CardHeader
          after={
            !isPortal && (
              <>
                {isDraft && (
                  <>
                    {isSupervisor && (
                      <SignatureDialog
                        model="supervisor"
                        title="Sign Off as Supervisor"
                        yesLabel="Apply Signature & Sign Off"
                        onYes={signOff}
                      >
                        <Button size={200} label="Sign Off as Supervisor…" glyph="signature" type="default" color="green" />
                      </SignatureDialog>
                    )}

                    {!isSupervisor && isAuthor && (
                      <SignatureDialog
                        model="author"
                        title="Sign & Send for Review"
                        yesLabel="Apply Signature & Send for Review"
                        onYes={signOff}
                      >
                        <Button size={200} label="Sign & Send for Review…" glyph="signature" type="default" />
                      </SignatureDialog>
                    )}
                  </>
                )}

                {isPendingReview && isSupervisor && (
                  <SignatureDialog model="supervisor" title="Sign Off as Supervisor" yesLabel="Apply Signature & Sign Off" onYes={signOff}>
                    <Button size={200} label="Sign Off as Supervisor…" glyph="signature" type="default" color="green" />
                  </SignatureDialog>
                )}
              </>
            )
          }
        >
          <CardMeta>
            <Text label="Supervisor: " description={data?.supervisor?.name} />
          </CardMeta>
        </CardHeader>

        <CardContent>
          <Markup value={data?.notes} />
        </CardContent>

        {(data?.author_signature || data?.supervisor_signature) && (
          <CardContent>
            {data?.author_signature && (
              <SignatureView
                label="Author Signature"
                value={data.author_signature}
                signedBy={data.author}
                signedAt={data.author_signed_at}
              />
            )}

            {data?.supervisor_signature && (
              <SignatureView
                label="Supervisor Signature"
                value={data.supervisor_signature}
                signedBy={data.supervisor}
                signedAt={data.supervisor_signed_at}
              />
            )}
          </CardContent>
        )}
      </Card>
    </>
  )
}
