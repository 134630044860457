import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  in_network: {
    label: 'In Network',
    color: 'green',
  },
  out_network: {
    label: 'Out of Network',
    color: 'orange',
  },
}

const InOutNetworkStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default InOutNetworkStatus
