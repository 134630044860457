import React from 'react'

import Checkbox from '../../../../components/Forms/Checkbox'
import NumberInput from '../../../../components/Forms/NumberInput'

export const TextareaEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput } = props

  const { textarea_max_rows } = activeElement.config

  return (
    <>
      <NumberInput
        label="Max. Rows Before Scroll"
        tooltip={
          <>
            <div>The maximum number of rows that can be displayed in the textarea before it starts scrolling</div>
            <div className="italic">
              <b>Note: </b>
              set to "0" to disable scrolling and display all rows at once.
            </div>
          </>
        }
        model="textarea_max_rows"
        value={textarea_max_rows}
        onUpdate={editActiveElementFromInput}
      />

      <Checkbox label="Enable Copy Button" model="copy_enabled" onUpdate={editActiveElementFromInput} />
    </>
  )
}
