import React from 'react'

import { Input } from './Input'
import { withFormContext } from './context'

import { COLORS, INPUT_STYLES } from '../../theme'

import BaseInput from './BaseInput'
import Grid from '../Grid'
import Flex from '../Flex'
import Glyph from '../Glyph'
import ProgressBar from '../ProgressBar'
import Loader from '../Loader'
import { Text } from '../Typography'

const passwordDescription = (score) => {
  switch (score) {
    case 0:
      return { description: 'Very Weak', color: 'red' }
    case 1:
      return { description: 'Weak', color: 'red' }
    case 2:
      return { description: 'So-so', color: 'orange' }
    case 3:
      return { description: 'Good', color: 'green' }
    case 4:
      return { description: 'Great! 🎉', color: 'green' }
    default:
      return { description: 'Very Weak', color: 'red' }
  }
}

const validatePassword = (pw) => ({
  digits: /\d/.test(pw),
  lower: /[a-z]/.test(pw),
  upper: /[A-Z]/.test(pw),
  nonWords: /\W/.test(pw),
  length: pw.length >= 10,
})

class PasswordInput extends Input {
  editRender = () => {
    const {
      autoFocus,
      max,
      maxLength,
      min,
      placeholder,
      prefix,
      size,
      step,
      suffix,
      testKey,
      autocomplete,
      showRules,
      showStrength,
      showFeedback,
      isLoading,
      passwordStrength,
      breachedPassword,
      feedback,
      validPassword,
    } = this.props

    const { id, value, isValid } = this.state

    const validations = validatePassword(value)
    const progress = passwordDescription(passwordStrength?.score)

    return (
      <Grid gap={8}>
        <div css={styles.root}>
          <BaseInput
            id={id}
            name={id}
            value={value}
            placeholder={placeholder}
            size={size}
            type={this.state.passwordVisible ? 'text' : 'password'}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            prefix={prefix}
            suffix={suffix}
            step={step}
            min={min}
            max={max}
            maxLength={maxLength}
            autoFocus={autoFocus}
            testKey={testKey}
            autocomplete={autocomplete}
            css={INPUT_STYLES}
          />

          {isLoading && <Loader css={styles.loader} />}

          <div onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })} css={styles.button}>
            <Glyph glyph={this.state.passwordVisible ? 'hide' : 'view'} size={this.state.passwordVisible ? 16 : 20} />
          </div>
        </div>

        {showFeedback && (
          <Grid
            gap={32}
            css={{
              padding: '1rem',
              background: COLORS.darkBackground,
              borderRadius: 7,
            }}
          >
            <Grid gap={16}>
              <Text fontWeight={600} muted={false}>
                Password Validation:
              </Text>

              {isLoading ? (
                <Text color="blue" fontWeight={600}>
                  Verifying password...
                </Text>
              ) : (
                <Text
                  glyph={isValid && validPassword ? 'check' : 'cross'}
                  color={isValid && validPassword ? 'green' : 'red'}
                  fontWeight={600}
                >
                  {isValid && validPassword ? 'This password is safe to use.' : 'This password is not safe to use.'}
                </Text>
              )}

              {feedback?.warning && <Text muted={false} label={`${feedback.warning}`} />}
              {feedback?.suggestions && <Text muted={false} description={feedback.suggestions.join(' ')} />}

              {breachedPassword && (
                <Text
                  glyph="cross"
                  color="red"
                  fontWeight={600}
                  description={`This password shows up in our list of Breached Passwords. Please try a different one.`}
                />
              )}
            </Grid>
          </Grid>
        )}

        {(showStrength || showRules) && (
          <Grid
            gap={32}
            css={{
              padding: '1rem',
              background: COLORS.darkBackground,
              borderRadius: 7,
            }}
          >
            {showStrength && (
              <Grid gap={16}>
                <Flex>
                  <Text label="Password Strength: " description={progress.description} muted={false} />
                </Flex>
                <ProgressBar percent={25 * passwordStrength?.score} color={progress.color} width={240} />
              </Grid>
            )}

            {showRules && (
              <Grid gap={16}>
                <Text fontWeight={600} muted={false}>
                  Your Password also needs to:
                </Text>

                <Text
                  glyph={validations.lower && validations.upper ? 'check' : 'cross'}
                  color={validations.lower && validations.upper ? 'green' : 'red'}
                  fontWeight={600}
                >
                  include both lower and upper case characters
                </Text>
                <Text
                  glyph={validations.digits || validations.nonWords ? 'check' : 'cross'}
                  color={validations.digits || validations.nonWords ? 'green' : 'red'}
                  fontWeight={600}
                >
                  include at least one digit or symbol
                </Text>
                <Text glyph={validations.length ? 'check' : 'cross'} color={validations.length ? 'green' : 'red'} fontWeight={600}>
                  be at least 10 characters long
                </Text>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    )
  }
}

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'relative',

    '& > *': {
      flex: '1 1 auto',
    },

    input: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  button: {
    ...INPUT_STYLES,

    width: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 'none',

    '&:hover': {
      background: COLORS.transparent,
    },
  },

  loader: {
    position: 'absolute',
    right: 52,
    top: 10,
  },

  viewButton: {
    marginLeft: '1rem',
  },
}

PasswordInput.defaultProps = {
  label: 'Password',
  debounce: 1000,
  validateOn: 'blur-change',
}

export default withFormContext(PasswordInput)
