import React from 'react'
import isEqual from 'react-fast-compare'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import Form from '../../../components/Forms/Form'
import Json from '../../../components/Json'
import State from '../../../components/State'

import { ElementIcon } from '../components/ElementIcon'
import { ELEMENTS } from './elements'
import { MoveElementActions } from '../components/MoveElementActions'
import { useFormBuilder } from '../useFormBuilder'

export const ElementEditor = (props: any) => {
  const { richTextEditor } = props

  const activeTab: any = useFormBuilder((state: any) => state.activeTab)
  const setActiveTab: any = useFormBuilder((state: any) => state.setActiveTab)

  const isEditable: any = useFormBuilder((state: any) => state.isEditable)
  const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)

  const activeElementId: any = useFormBuilder((state: any) => state.activeElementId)
  const activeElement: any = useFormBuilder((state: any) => state.allElements?.[activeElementId])
  const deleteElement: any = useFormBuilder((state: any) => state.deleteElement)
  const duplicateElement: any = useFormBuilder((state: any) => state.duplicateElement)

  const editElement: any = useFormBuilder((state: any) => state.editElement)
  const editElementConfig: any = useFormBuilder((state: any) => state.editElementConfig)
  const setElementConfigValue: any = useFormBuilder((state: any) => state.setElementConfigValue)
  const toggleSkipVariablesCheck: any = useFormBuilder((state: any) => state.toggleSkipVariablesCheck)
  const appendVariableToElementModel: any = useFormBuilder((state: any) => state.appendVariableToElementModel)

  const form = React.useRef()
  const renders = React.useRef(0)

  const ELEMENT = React.useMemo(() => ELEMENTS?.[activeElement?.category], [activeElement])
  const EDITORS = React.useMemo(() => ELEMENT?.editors, [ELEMENT])

  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false)

  if (!activeElement?._isActive || activeElement?._destroy) return null

  const editActiveElementFromInput = (input) => {
    if (!input) return

    const { model, value } = input

    // skip if values are the same
    if (isEqual(activeElement?.config?.[model], value)) return

    // update the active element
    editElementConfig({
      uuid: activeElement.uuid,
      config: { [model]: value },
    })
  }

  const handleDeleteClick = (e) => {
    // delete immediately if shift key is pressed
    const isShiftPressed = e.shiftKey

    if (isShiftPressed) {
      deleteElement(activeElementId)
      return
    }

    // show delete confirmation dialog
    setDeleteConfirmation(true)
  }

  const deleteActiveElement = () => {
    deleteElement(activeElementId)
  }

  const handleDuplicate = () => {
    duplicateElement(activeElementId)
  }

  return (
    <>
      {!activeElement ? (
        <div key="empty-state" className="h-full">
          <State
            isEmpty
            title="No Element Selected"
            glyph="form_builder_basic_elements"
            emptyDescription="Select an element to view and edit its settings"
          />
        </div>
      ) : (
        <div key={`data-state-${activeElementId}`} className="flex flex-col w-full h-full overflow-hidden">
          <header className="flex items-center px-3 py-2 border-b border-0 border-solid border-divider flex-[0_0_auto]">
            <ElementIcon elementCategory={activeElement?.category} className="mr-1.5" />
            <h3 className="text-[1.1rem]">{ELEMENT.name}</h3>
          </header>

          <MoveElementActions elementId={activeElementId} className="px-3 mt-2" />

          <div className="flex-[1_1_auto] px-3 pt-3 pb-8 overflow-y-auto">
            <Button label={`Duplicate ${ELEMENT.name}`} glyph="copy" size={200} onClick={handleDuplicate} className="mb-4" />

            {EDITORS && (
              <Form key={activeElementId} getForm={form} isEditable isCompact initialModel={activeElement.config}>
                <div className="grid gap-3">
                  {EDITORS.map((EditorTag: any, index: number) => {
                    return (
                      <EditorTag
                        key={`${activeElementId}-${index}`}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        activeElement={activeElement}
                        activeElementId={activeElementId}
                        editElement={editElement}
                        editElementConfig={editElementConfig}
                        editActiveElementFromInput={editActiveElementFromInput}
                        toggleSkipVariablesCheck={toggleSkipVariablesCheck}
                        isPreviewMode={isPreviewMode}
                        isEditable={isEditable}
                        appendVariableToElementModel={appendVariableToElementModel}
                        setElementConfigValue={setElementConfigValue}
                        richTextEditor={richTextEditor}
                      />
                    )
                  })}
                </div>

                {/* <Json data={activeElement} /> */}
              </Form>
            )}
          </div>

          <footer className="px-3 pb-3 flex-[0_0_auto]">
            <Button
              label={`Delete ${ELEMENT.name}…`}
              glyph="delete"
              color="text"
              glyphColor="red"
              type="default"
              size={200}
              onClick={handleDeleteClick}
            />
          </footer>
        </div>
      )}

      <DeleteDialog
        setOpen={deleteConfirmation}
        onOpenChange={setDeleteConfirmation}
        title={`Delete ${ELEMENT.name}?`}
        message="Are you sure you want to delete this element? This action cannot be undone."
        content={
          <Alert small contrast glyph="info" className="mt-3">
            <b>Tip: </b> to delete elements immediately without confirmation, hold the "Shift" key while clicking the delete button.
          </Alert>
        }
        yesLabel={`Delete ${ELEMENT.name}`}
        onYes={() => {
          deleteActiveElement()
        }}
      />
    </>
  )
}
