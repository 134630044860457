import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../theme'
import { DT } from '../utils/functions'
import useSettings from '../hooks/useSettings'

import Button from './Button'
import Card from './Card'
import Flex from './Flex'
import MiniCalendar from './Calendar/MiniCalendar'

export const LocationCalendar = (props: any) => {
  const { children, date, headerActions, isLoading, setDate, stats } = props
  const { timezone } = useSettings()

  const dateObj = DT(date)
  const today = DateTime.local().setZone(timezone)
  const isToday = dateObj.toFormat('MM-dd-yyyy') === today.toFormat('MM-dd-yyyy')

  const handlePrevClick = () => {
    setDate(dateObj.minus({ months: 1 }).toISODate())
  }

  const handleNextClick = () => {
    setDate(dateObj.plus({ months: 1 }).toISODate())
  }

  const handleTodayClick = () => {
    setDate(today.toISODate())
  }

  const handleDayClick = (dateObj: any) => {
    setDate(dateObj.toISODate())
  }

  return (
    <Card css={styles.root}>
      <div css={styles.sidebar}>
        {headerActions && <div css={styles.sidebarHeader}>{headerActions}</div>}

        <Flex centerY nowrap stretchSelf gap={8}>
          <div css={styles.heading}>
            <span css={styles.month}>{dateObj.toFormat('LLL dd')}</span>
            <span css={styles.year}> {dateObj.toFormat('kkkk')}</span>
          </div>

          {!isToday && (
            <Button color="text" label="Today" size={100} onClick={handleTodayClick} css={styles.navButton} isDisabled={isLoading} />
          )}

          <Flex nowrap css={styles.buttonsGroup}>
            <Button
              hideLabel
              color="text"
              size={100}
              glyph="chevron_left"
              glyphSize="1em"
              onClick={handlePrevClick}
              css={styles.navButton}
              isDisabled={isLoading}
            />
            <Button
              hideLabel
              color="text"
              size={100}
              glyph="chevron_right"
              glyphSize="1em"
              onClick={handleNextClick}
              css={styles.navButton}
              isDisabled={isLoading}
            />
          </Flex>
        </Flex>

        <MiniCalendar
          mode="days"
          stats={stats}
          currentDate={dateObj}
          setCurrentDate={handleDayClick}
          today={today}
          css={styles.miniCalendar}
        />
      </div>

      <div css={styles.main}>{children}</div>
    </Card>
  )
}

const styles = {
  root: {
    display: 'grid',

    '@media (min-width: 1400px)': {
      minHeight: '80vh',
      gridTemplateColumns: '300px 1fr',
    },
  },

  sidebar: {
    padding: '0.75rem 1rem',
    borderBottom: `1px solid ${COLORS.divider}`,

    '@media (min-width: 1400px)': {
      gridTemplateColumns: '300px 1fr',
      borderBottom: 'none',
      borderRight: `1px solid ${COLORS.divider}`,
    },
  },

  sidebarHeader: {
    marginBottom: '1rem',
  },

  miniCalendar: {
    marginTop: '1rem',
  },

  main: {
    position: 'relative',
  },

  heading: {
    fontSize: '1.25rem',
    fontWeight: 400,
    marginRight: 'auto !important',
  },

  buttonsGroup: {
    '& > *': {
      marginLeft: -1,
      borderRadius: '0 !important',
      flex: '1 1 auto',

      '&:first-child': {
        borderRadius: '5px 0 0 5px !important',
      },

      '&:last-child': {
        borderRadius: '0 5px 5px 0 !important',
      },
    },
  },

  month: {
    fontWeight: 700,
  },

  year: {
    fontSize: '0.95em',
    color: COLORS.textMuted,
  },

  navButton: {
    minHeight: 26,
  },
}
