import React from 'react'

import { COLORS } from '../../../theme'
import Glyph from '../../Glyph'

import Empty from './Empty'
import Link from './Link'
import Truncated from './Truncated'

import { nicePhoneNo } from '../../../utils/functions'

const Phone: React.FC = ({ value }) => {
  if (!value) return <Empty />

  return (
    <Link
      title={value}
      href={`tel:${value}`}
      glyph={<Glyph glyph="phone" />}
      label={<Truncated value={nicePhoneNo(value)} />}
      className="text-text"
    />
  )
}

export default Phone
