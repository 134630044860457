import React from 'react'

import { useSettings } from '../../../../hooks/useSettings'
import Input from '../../../../components/Forms/Input'

export const FieldModelEditor = (props: any) => {
  return null
  // const { isBehave } = useSettings()

  // if (!isBehave) return null

  // const { activeElement, editActiveElementFromInput: onUpdate } = props

  // return (
  //   <>
  //     <Input label="Field Model" model="model" value={activeElement?.config?.model || activeElement?.uuid} onUpdate={onUpdate} />
  //   </>
  // )
}
