import React from 'react'
import { connect } from 'react-redux'

import FormSection from '../../../../Forms/FormSection'
import HelpTagIframe from '../../../../Help/HelpTagIframe'
import DateInput from '../../../../Forms/DateInput'
import Option from '../../../../Forms/Option'
import Overlay from '../../../../Overlay'
import Section from '../../../../Section'
import Select from '../../../../Forms/Select'
import Divider from '../../../../Divider'

import ClientProfileHeader from '../../../../ClientProfileHeader'
import DataFormStatus from '../../../../Statuses/DataFormStatus'

import { DataFormsBase, mapDispatchToProps, mapStateToProps } from '../DataFormsBase'
import { withOverlayError } from '../../../../../hocs/withOverlayError'

class DischargeReminderFormOverlay extends DataFormsBase {
  renderHeader = () => (
    <Overlay.Header
      icon="outcome_measures"
      title={this.props.data_form?.name || this.props.title}
      titleAside={<DataFormStatus status={this.props.data_form?.status} />}
      description={<ClientProfileHeader client={this.props.data_form?.client || this.props.client} />}
      help={<HelpTagIframe id="outcome_measures_discharge_reminder_form" />}
    />
  )

  renderContent = () => (
    <>
      <Section
        title="Introduction"
        headingType="h2"
        scrollview={{
          id: 'introduction',
          name: 'Introduction',
        }}
      >
        <p>
          When you enrolled in treatment, you also enrolled in a research study. As a reminder: Our organization is conducting research on
          the impact of treatment. Now that you are no longer in treatment, you will be contacted on the phone (or via email) for several
          confidential follow-up surveys throughout the course of the next year. The information collected from the surveys will help us
          understand how to improve services for individuals who receive addiction treatment services. Thank you in advance for
          participating in this research!
        </p>
      </Section>

      <Divider />

      <Section
        title="Discharge Information"
        headingType="h2"
        scrollview={{
          id: 'discharge_information',
          name: 'Discharge Information',
        }}
        commentsModel="data.discharge_information"
      >
        <FormSection>
          <DateInput label="1. Date Participant was Discharged" model="data.discharge_information.discharge_date" />

          <Select
            label="2. If participant was not discharged, please indicate why:"
            model="data.discharge_information.no_discharged_reason"
          >
            <Option label="Deceased" value="deceased" />
            <Option label="Didn't complete program" value="program_not_completed" />
            <Option label="Incarcerated" value="incarcerated" />
            <Option label="Left without being discharged" value="left_without_being_discharged" />
            <Option label="Refused" value="refused" />
            <Option label="Other" value="other" />
          </Select>
        </FormSection>
      </Section>

      <Divider />

      <Section>
        <p>
          <b>Copyright:</b> The NAATP Addiction Treatment Outcomes Measurement Program
        </p>
      </Section>
    </>
  )
}

DischargeReminderFormOverlay.defaultProps = {
  title: 'Discharge Reminder Form',
  category: 'outcome_measure',
  subcategory: 'discharge_reminder_form',
  requestClientSignature: false,
  requestAuthorSignature: false,
  requireSupervisor: false,
  maxWidth: 85,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(DischargeReminderFormOverlay))
