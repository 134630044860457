import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

import Button from '../../components/Button'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Textarea from '../../components/Forms/Textarea'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import QuickView from '../../components/QuickView'
import Section from '../../components/Section'
import Timeline from '../../components/Timeline/Timeline'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Option from '../../components/Forms/Option'
import Select from '../../components/Forms/Select'
import ContextShow from '../../components/Forms/ContextShow'

import { withOverlayError } from '../../hocs/withOverlayError'

const PERMISSIONS = {
  admissions: 'clients',
  clients: 'clients',
  alumni: 'clients',
  employees: 'employees',
  locations: 'properties',
  organizations: 'organizations',
  payers: 'insurance_local_payers',
}

const File = (props: any) => {
  const { timezone } = useSettings()
  const match = useRouteMatch()

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialData,
    isDeleting,
    isEditable,
    isEmpty,
    isInvalid,
    isLoading,
    isNew,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'file',
    endpoint: '/documents',
    invalidate: 'files',
    options: props,
  })

  const { canEdit = true } = props

  if (isLoading || isEmpty) return <OverlayLoader position="right" />

  let permissionPrefix = 'companies.'
  if (match?.params?.resource && match.params.resource in PERMISSIONS) permissionPrefix = PERMISSIONS[match.params.resource] + '.'

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="files" title="File" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{ ...initialData, ...data }}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection layout="vertical">
              <Input model="title" label="Title" />
              <MultiObjectSelector model="file_tags" label="Tags" type="files.file_tags" />
              <Textarea useQuickText label="Notes" model="notes" />

              <Select allowEmpty label="Review Cycle" model="review_cycle">
                <Option label="Daily" value="daily" />
                <Option label="Weekly" value="weekly" />
                <Option label="Monthly" value="monthly" />
                <Option label="Quarterly" value="quarterly" />
                <Option label="Semi-annually" value="semi_annually" />
                <Option label="Annually" value="annually" />
              </Select>

              <DateTimeInput
                defaultToNow
                model="last_review_at"
                label="Last Review Date"
                validations={{
                  presence: {
                    message: 'Please enter a date and time',
                  },
                }}
              />

              <Flex gap={16} horizontal stretchChildrenX alignItems="flex-end">
                <DateTimeInput
                  defaultToNow
                  model="next_review_at"
                  label="Next Review Date"
                  validations={{
                    presence: {
                      message: 'Please enter a date and time',
                    },
                  }}
                />

                <Checkbox label="Send Internal Email Reminders" model="should_send_next_review_date_reminders" />
              </Flex>

              <ContextShow when="should_send_next_review_date_reminders" is={true}>
                <CheckboxGroup
                  label="Send Reminders Options"
                  layout="vertical-dense"
                  validations={{
                    presence: {
                      message: 'Please select at least one option',
                    },
                  }}
                >
                  <Checkbox label="3 months before effective date" model="send_reminders.next_review_date.three_months" />
                  <Checkbox label="1 month before effective date" model="send_reminders.next_review_date.one_month" />
                  <Checkbox label="2 weeks before effective date" model="send_reminders.next_review_date.two_weeks" />
                  <Checkbox label="1 week before effective date" model="send_reminders.next_review_date.one_week" />
                  <Checkbox label="1 day before effective date" model="send_reminders.next_review_date.one_day" />
                </CheckboxGroup>
              </ContextShow>

              <Divider />

              <Flex gap={16} horizontal stretchChildrenX alignItems="flex-end">
                <DateTimeInput
                  defaultToNow
                  model="expired_at"
                  label="Expiry Date"
                  validations={{
                    presence: {
                      message: 'Please enter a date and time',
                    },
                  }}
                />

                <Checkbox label="Send Internal Email Reminders" model="should_send_expiry_date_reminders" />
              </Flex>

              <ContextShow when="should_send_expiry_date_reminders" is={true}>
                <CheckboxGroup
                  label="Send Reminders Options"
                  layout="vertical-dense"
                  validations={{
                    presence: {
                      message: 'Please select at least one option',
                    },
                  }}
                >
                  <Checkbox label="3 months before effective date" model="send_reminders.expiry_date.three_months" />
                  <Checkbox label="1 month before effective date" model="send_reminders.expiry_date.one_month" />
                  <Checkbox label="2 weeks before effective date" model="send_reminders.expiry_date.two_weeks" />
                  <Checkbox label="1 week before effective date" model="send_reminders.expiry_date.one_week" />
                  <Checkbox label="1 day before effective date" model="send_reminders.expiry_date.one_day" />
                </CheckboxGroup>
              </ContextShow>

              <Divider />

              <ObjectSelector
                isEditable={false}
                model="author"
                label="Uploaded By"
                type="employees"
                icon="employees"
                selectTitle={(data) => data.name}
                selectDescription={(data) => data.position}
              />
              <DateTimeInput isEditable={false} model="created_at" label="Date Uploaded" />
              <DateTimeInput isEditable={false} model="updated_at" label="Last Edited" />
              <Input isEditable={false} model="content_type" label="Type" />
            </FormSection>
          </Section>

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {canEdit && (
        <Overlay.Footer>
          {isEditable && (
            <Flex stretchChildrenX gap={8}>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="3 1 auto"
                permission={`${permissionPrefix}files.edit`}
              />

              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} flex="0 1 auto" />}
            </Flex>
          )}

          {!isEditable && (
            <Grid gap={8}>
              <Flex stretchChildrenX gap={8}>
                <QuickView record={data}>
                  <Button glyph="external_link" label="View File" type="primary" flex="2 1 auto" />
                </QuickView>

                <Button onClick={() => window.open(data.download)} glyph="download" label="Download" type="primary" color="green" />
              </Flex>

              <Flex stretchChildrenX gap={8}>
                <Button
                  glyph="edit"
                  label="Edit"
                  type="default"
                  isDisabled={isLoading}
                  onClick={edit}
                  flex="2 1 auto"
                  permission={`${permissionPrefix}files.edit`}
                />

                <DeleteDialog
                  title="Delete File?"
                  message="Are you sure you want to delete this file? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button
                    glyph="delete"
                    label="Delete"
                    type="default"
                    color="red"
                    isLoading={isDeleting}
                    fullWidth
                    permission={`${permissionPrefix}files.delete`}
                  />
                </DeleteDialog>
              </Flex>
            </Grid>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const FileOverlay = withOverlayError(File)
