import React from 'react'

import { CLAIM_TASK_STATUSES } from '../../../utils/constants'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

const ClaimInternalStatusSelect: React.FC<any> = ({ model }) => {
  return (
    <Select label="Task Status" model={model} defaultValue="todo">
      {Object.entries(CLAIM_TASK_STATUSES).map(([key, label]) => (
        <Option key={key} label={label} value={key} />
      ))}
    </Select>
  )
}

export default ClaimInternalStatusSelect
