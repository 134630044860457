import React from 'react'

import { useSettings } from '../../../hooks'
import { withOverlayError } from '../../../hocs/withOverlayError'

import Alert from '../../Alert'
import Button from '../../Button'
import Overlay from '../../Overlay'
import Section from '../../Section'

import { useUpdate } from '../../../hooks/useNewAPI'

const ClientPortalRevoke = ({ client, onClose }: any) => {
  const { online } = useSettings()

  const [loading, setLoading] = React.useState(false)
  const { mutateAsync: togglePortalAccess }: any = useUpdate({
    name: ['client', client?.id],
    url: `/residents/${client?.id}/toggle_access_to_client_portal`,
    invalidate: ['clients'],
  })

  const revokePortalAccess = async () => {
    setLoading(true)

    try {
      await togglePortalAccess()
      setLoading(false)

      if (onClose) onClose()
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  if (!client) return null

  return (
    <Overlay showBackdrop position="center" onClose={onClose} isLoading={!client}>
      <Overlay.Header glyph="portal" title="Revoke Client Portal Access" />

      <Overlay.Content>
        <Section>
          <Alert glyph="circle_error" type="negative">
            Access to the Client Portal will be blocked immediately after revoking it. You can re-enable portal access at any time.
          </Alert>
        </Section>
      </Overlay.Content>

      <Overlay.Footer online={online}>
        <Button
          label="Revoke Client Portal Access"
          type="primary"
          color="red"
          glyph="circle_error"
          isLoading={loading}
          onClick={revokePortalAccess}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default withOverlayError(ClientPortalRevoke)
