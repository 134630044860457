import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Notifications from '../../modules/notifications'

import { useSimpleOverlay } from '../../hooks/useSimpleOverlay'
import { useUpdate } from '../../hooks/useNewAPI'

import { ClaimFlagSelect } from './components/ClaimFlagSelect'

const RootClaimFlagOverlay = (props: any) => {
  const { form, isInvalid, onValidationUpdate } = useSimpleOverlay({})

  const [isSaving, setIsSaving] = React.useState(false)
  const [error, setError] = React.useState(null)

  const { selectedClaims } = props

  const { timezone } = useSettings()

  const { mutateAsync: updateClaims }: any = useUpdate({
    name: ['insurance_claims'],
    url: `/insurance_claims/${selectedClaims?.map((o: any) => o.id).join(',')}`,
    invalidateKeys: ['insurance_claims'],
  })

  if (size(selectedClaims) === 0) {
    throw new Error('Select one or more roster items to update the Status')
  }

  const save = async () => {
    setIsSaving(true)
    const formData = form.current.getFormValue()

    try {
      await updateClaims(formData)

      Notifications.send('Successfully updated Claim Statuses', 'positive')

      props.onClose()
    } catch (error) {
      console.error(error)
      setError(error?.error?.data?.errors?.[0])
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={props.onClose} position="center">
      <Overlay.Header glyph="warning" title="Flag Claims" />

      <Overlay.Content>
        <Form isEditable getForm={form} timezone={timezone} onValidationUpdate={onValidationUpdate}>
          <Section>
            <FormSection maxWidth="100%">
              <Alert contrast type="default" glyph="info">
                Flag the selected {countWord('Claims', size(selectedClaims))}
              </Alert>

              <ClaimFlagSelect label="Flag Claims To:" model="flagged_status" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {error && (
          <Alert contrast type="negative" glyph="warning">
            {error}
          </Alert>
        )}

        <Button
          label={`Flag ${countWord('Claims', size(selectedClaims))}`}
          glyph="check"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={save}
          isDisabled={isInvalid}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClaimFlagOverlay = withOverlayError(RootClaimFlagOverlay)
