import { usePermissions } from '../../hooks/usePermissions'

export const useTreatmentPlanPermissions = () => {
  // Treatment Plans
  const { allowed: viewClinicalTreatmentPlans } = usePermissions({
    featureFlag: 'treatment_plans',
    permission: 'clinical_treatment_plans.view',
  })

  const { allowed: viewMedicalTreatmentPlans } = usePermissions({
    featureFlag: 'treatment_plans',
    permission: 'medical_treatment_plans.view',
  })

  const { allowed: viewCaseManagementTreatmentPlans } = usePermissions({
    featureFlag: 'treatment_plans',
    permission: 'case_management_treatment_plans.view',
  })

  const { allowed: viewPeerTreatmentPlans } = usePermissions({
    featureFlag: 'treatment_plans',
    permission: 'peer_treatment_plans.view',
  })

  // Treatment Plan Templates
  const { allowed: viewClinicalTreatmentPlanTemplates } = usePermissions({
    featureFlag: 'treatment_plan_templates',
    permission: 'clinical_treatment_plan_templates.view',
  })

  const { allowed: viewMedicalTreatmentPlanTemplates } = usePermissions({
    featureFlag: 'treatment_plan_templates',
    permission: 'medical_treatment_plan_templates.view',
  })

  const { allowed: viewCaseManagementTreatmentPlanTemplates } = usePermissions({
    featureFlag: 'treatment_plan_templates',
    permission: 'case_management_treatment_plan_templates.view',
  })

  const { allowed: viewPeerTreatmentPlanTemplates } = usePermissions({
    featureFlag: 'treatment_plan_templates',
    permission: 'peer_treatment_plan_templates.view',
  })

  // Treatment Book
  const { allowed: viewClinicalTreatmentBook } = usePermissions({
    featureFlag: 'treatment_book',
    permission: 'clinical_treatment_book.view',
  })

  const { allowed: viewMedicalTreatmentBook } = usePermissions({
    featureFlag: 'treatment_book',
    permission: 'medical_treatment_book.view',
  })

  const { allowed: viewCaseManagementTreatmentBook } = usePermissions({
    featureFlag: 'treatment_book',
    permission: 'case_management_treatment_book.view',
  })

  const { allowed: viewPeerTreatmentBook } = usePermissions({
    featureFlag: 'treatment_book',
    permission: 'peer_treatment_book.view',
  })

  const viewSomeTreatmentPlans =
    viewClinicalTreatmentPlans || viewMedicalTreatmentPlans || viewCaseManagementTreatmentPlans || viewPeerTreatmentPlans

  const viewSomeTreatmentPlanTemplates =
    viewClinicalTreatmentPlanTemplates ||
    viewMedicalTreatmentPlanTemplates ||
    viewCaseManagementTreatmentPlanTemplates ||
    viewPeerTreatmentPlanTemplates

  const viewSomeTreatmentBooks =
    viewClinicalTreatmentBook || viewMedicalTreatmentBook || viewCaseManagementTreatmentBook || viewPeerTreatmentBook

  return {
    // Treatment Plans,
    viewSomeTreatmentPlans,
    viewClinicalTreatmentPlans,
    viewMedicalTreatmentPlans,
    viewCaseManagementTreatmentPlans,
    viewPeerTreatmentPlans,

    // Treatment Plan Templates
    viewSomeTreatmentPlanTemplates,
    viewClinicalTreatmentPlanTemplates,
    viewMedicalTreatmentPlanTemplates,
    viewCaseManagementTreatmentPlanTemplates,
    viewPeerTreatmentPlanTemplates,

    // Treatment Book
    viewSomeTreatmentBooks,
    viewClinicalTreatmentBook,
    viewMedicalTreatmentBook,
    viewCaseManagementTreatmentBook,
    viewPeerTreatmentBook,
  }
}
