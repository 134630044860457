import React from 'react'

import Button from '../Button'
import Overlay from '../Overlay'
import Form from '../Forms/Form'
import RichTextEditor from '../Forms/RichTextEditor'

import { apiGet, apiUpdate } from '../../modules/api'

const InternalNotesOverlay = ({ data, onClose, title, model }: any) => {
  const form = React.useRef()

  const [loading, setLoading] = React.useState(false)

  const onSave = async () => {
    const formData = form.current.getFormValue()

    setLoading(true)
    await apiUpdate({ url: '/me/tenant', params: formData })

    setLoading(false)
    await apiGet({ url: '/me', reducer: 'me' })

    onClose()
  }

  return (
    <Overlay showBackdrop position="center" onClose={onClose} maxWidth={50} minHeight={40}>
      <Overlay.Header title={title} />

      <Overlay.Content css={styles.overlayContent}>
        <Form getForm={form} initialModel={data}>
          <RichTextEditor label="Internal Notes" model={model} css={{ height: '100%', minHeight: '100%' }} />
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save Notes" color="green" type="primary" isLoading={loading} onClick={onSave} />
      </Overlay.Footer>
    </Overlay>
  )
}

const styles = {
  overlayContent: {
    padding: '1rem',
  },
}

export default InternalNotesOverlay
