import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import SmartStatus from '@behavehealth/components/SmartStatus'

const STATUSES = {
  active: {
    label: 'Active',
    color: 'green',
  },
  archived: {
    label: 'Archived',
    color: 'red',
  },
  unknown: {
    label: 'Unknown',
    color: 'gray',
  },
}

const RootTrackingCategories = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting', 'tracking_categories'],
    endpoint: `/accounting/tracking_categories`,
    // params: {...},
    localStorageKey: 'accounting_tracking_categories_v1',
  })

  const { mutateAsync: syncRailzToBh, isLoading: isSyncingRailz } = useCreate({
    name: ['sync-railz-to-bh'],
    url: '/accounting/tracking_categories/sync_railz_to_bh',
    invalidate: tableProps.queryKey,
  })

  const { mutateAsync: syncQbToRailz, isLoading: isSyncingQb } = useCreate({
    name: ['sync-qb-to-railz'],
    url: '/accounting/tracking_categories/sync_qb_to_railz',
  })

  const handleSyncRailzToBh = async () => {
    await syncRailzToBh()
    tableProps.refetch()
  }

  const handleSyncQbToRailz = () => {
    syncQbToRailz()
  }

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Quickbooks Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} />,
      },
      {
        title: 'Parent Name',
        model: 'parent_ref_name',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Quickbooks Status',
        model: 'status',
        width: 160,
        disableHide: true,
        formatValue: ({ value }) => <SmartStatus statuses={STATUSES} status={value} />,
      },
      {
        title: 'is Editable',
        model: 'is_editable',
        width: 160,
        disableHide: true,
        type: 'boolean',
      },
      {
        title: 'Quickbooks Last Updated',
        model: 'source_modified_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Tracking Categories">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Tracking Categories"
          icon="accounting"
          emptyDescription="No tracking categories have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          columns={columns}
          {...tableProps}
          headerAfter={
            isBehave && (
              <Flex gap="0.5rem">
                <AccountingSyncButton
                  forceShow={true}
                  category="tracking_categories"
                  invalidate={tableProps.queryKey}
                  refetchUrl={'/accounting/sync?category=tracking_categories'}
                />
              </Flex>
            )
          }
        />
      </Grid>
    </Page>
  )
}

export const TrackingCategories = withPageError(RootTrackingCategories)

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  parent_ref_name: {
    label: 'Parent Name',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'string',
  },
  is_editable: {
    label: 'Is Editable',
    type: 'boolean',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'date_time',
  },
}
