import React from 'react'

import { loadStripe } from '@stripe/stripe-js'
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import Markup from '../../../components/Markup'
import Status from '../../../components/Status'
import SummonOverlay from '../../../components/SummonOverlay'
import Text from '../../../components/Typography/Text'

import { COLORS } from '../../../theme'
import { QuoteIframeButton } from '../../QuoteElements/QuoteIframeButton'
import { ReviewSection } from './ReviewSection'
import { usDateTime } from '../../../utils/functions'

import { beautifulAmount } from '../../../utils/functions'
import { invalidateQueries, useCreate } from '../../../hooks/useNewAPI'

import { EHRInvoiceOverlay } from '../../InternalBilling/EHRInvoiceOverlay'
import { ExportPDFButton } from '../../../components/Buttons/ExportPDFButton'
import { PaymentMethod } from '../../BillingElements/PaymentMethodElements'

const stripePromise = loadStripe(process.env.BH_STRIPE_BEHAVE_PUBLIC_KEY)

export const QuotePayment = (props: any) => {
  const { data, enabled, isPublic, isOpen } = props

  const isPaid = data?.status === 'paid' || data?.status === 'completed'
  const isPaymentFailed = data?.status === 'payment_failed'
  const isProcessingPayment = data?.status === 'payment_processing'

  const [paymentMethod, setPaymentMethod] = React.useState('card')

  const shouldPassFees = !!data?.pass_fee_to_payer
  const upfrontAmount = data?.selected_offer_calculated_amounts?.upfront?.total || 0

  const cardUpfrontAmount = data?.selected_offer_calculated_amounts?.upfront?.card_total
  const achUpfrontAmount = data?.selected_offer_calculated_amounts?.upfront?.ach_total
  const finalAmount = shouldPassFees ? (paymentMethod === 'card' ? cardUpfrontAmount : achUpfrontAmount) : upfrontAmount

  const cardUpfrontFees = data?.selected_offer_calculated_amounts?.upfront?.card_fees || 0
  const achUpfrontFees = data?.selected_offer_calculated_amounts?.upfront?.ach_fees || 0
  const fees = paymentMethod === 'card' ? cardUpfrontFees : achUpfrontFees

  const timezone = data?.timezone || data?.facility?.timezone

  const options = React.useMemo(() => {
    return {
      mode: 'payment',
      amount: parseFloat(finalAmount) * 100,
      currency: 'usd',
      setupFutureUsage: 'off_session',
      captureMethod: 'automatic',
      appearance: {},
      paymentMethodTypes: ['card', 'us_bank_account'],
    }
  }, [finalAmount])

  return (
    <>
      <ReviewSection
        isOpen={isOpen}
        data={data}
        title="Payment"
        icon="online_card_payment"
        titleAfter={<QuoteIframeButton data={data} model="payment" />}
        sectionModel="payment"
        className="!border-none"
      >
        {isPaid ? (
          <>
            {data?.paid_at ? (
              <div className="flex items-center mb-4">
                <Status label="Completed" glyph="tick_circle" color="green" className="mr-2" />
                <Text glyph="date" label="Paid On:" description={usDateTime(data.paid_at, timezone)} />
              </div>
            ) : (
              <Status label="Action Required" glyph="warning" color="orange" />
            )}

            {data?.global_payment_method && (
              <div className="flex items-center mb-4">
                <div className="whitespace-nowrap mr-1.5 font-[600]">Payment Method:</div>
                <PaymentMethod paymentMethod={data?.global_payment_method} />
              </div>
            )}

            <Alert glyph="tick_circle" type="positive">
              Thank you, your payment has been received and we will be in touch shortly. If you have any questions, please{' '}
              <a href="mailto:contact@behavehealth.com>">contact us</a>.
            </Alert>

            <div className="mt-4">
              <Flex gap="1rem">
                {data?.invoice?.external_id && (
                  <SummonOverlay
                    overlay={<EHRInvoiceOverlay isPublic={isPublic} dataID={isPublic ? data?.invoice?.external_id : data?.invoice?.id} />}
                  >
                    <Button label="View Invoice" glyph="document" size={300} type="primary" display="inline-flex" className="mt-4" />
                  </SummonOverlay>
                )}

                {data?.invoice?.id && !isPublic && <ExportPDFButton url={`/invoices/${data.invoice.id}/pdf`} tenant="public" />}
              </Flex>
            </div>
          </>
        ) : isProcessingPayment ? (
          <>
            <Alert glyph="info" type="warning">
              The payment for this quote is currently being processed. The quote products will be activated once the payment is completed.
            </Alert>

            {data?.global_payment_method && (
              <div className="flex items-center mt-4">
                <div className="whitespace-nowrap mr-1.5 font-[600]">Payment Method:</div>
                <PaymentMethod paymentMethod={data?.global_payment_method} />
              </div>
            )}
          </>
        ) : !enabled ? (
          <Alert glyph="info">Please complete the previous sections to view the payment options</Alert>
        ) : (
          <div className="grid gap-5 w-full max-w-[500px]">
            {isPaymentFailed && (
              <Alert glyph="circle_error" type="negative">
                <div>The payment of this quote hasn't gone through, please try again.</div>

                {data?.global_payment_method && (
                  <div className="flex items-center mt-2">
                    <div className="whitespace-nowrap mr-1.5 font-[600]">Payment Method:</div>
                    <PaymentMethod paymentMethod={data?.global_payment_method} />
                  </div>
                )}
              </Alert>
            )}

            {data?.billed_to_email ? (
              <Alert glyph="info">
                A receipt for your payment will be sent to <strong>{data.billed_to_email}</strong>. If this is incorrect, email us at
                <a href="mailto:contact@behavehealth.com">contact@behavehealth.com</a> to update your email.
              </Alert>
            ) : (
              <Alert glyph="circle_error" type="negative">
                No email address found for billing. Please <a href="mailto:contact@behavehealth.com>">contact us</a> to update it.
              </Alert>
            )}

            {data?.payment_text && <Markup value={data.parsed_payment_text || data.payment_text} />}

            {!!(data?.billed_to_email && upfrontAmount) && (
              <div className="grid gap-5 py-6">
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm
                    isPublic={isPublic}
                    data={data}
                    upfrontAmount={finalAmount}
                    shouldPassFees={shouldPassFees}
                    fees={fees}
                    setPaymentMethod={setPaymentMethod}
                  />
                </Elements>

                <Card className="px-4 py-3 grid gap-3 leading-normal">
                  <div>
                    <b>After successful payment:</b>
                  </div>

                  <div className="flex items-center flex-nowrap">
                    <Glyph glyph="lock" size={18} color={COLORS.text} className="flex-0 mr-3" />
                    <div>Quote will be locked and no further changes can be made</div>
                  </div>

                  <div className="flex items-center flex-nowrap">
                    <Glyph glyph="email" size={18} color={COLORS.blue} className="flex-0 mr-3" />
                    <div>You will receive the invoice and payment receipt via email</div>
                  </div>

                  <div className="flex items-center flex-nowrap">
                    <Glyph glyph="tick_circle" size={18} color={COLORS.green} className="flex-0 mr-3" />
                    <div>Your Behave Health EHR account will be activated and you can start using it immediately</div>
                  </div>
                </Card>
              </div>
            )}
          </div>
        )}
      </ReviewSection>
    </>
  )
}

const CheckoutForm = ({ data, isPublic, upfrontAmount, shouldPassFees, fees, setPaymentMethod }: any) => {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isPaymentElementComplete, setIsPaymentElementComplete] = React.useState(false)

  const { mutateAsync: payQuote, isLoading: isPayingQuote } = useCreate({
    name: ['pay-quote'],
    url: isPublic ? `/apps/quotes/${data?.external_id}/pay` : `/quotes/${data?.id}/pay`,
  })

  const handlePaymentElementChange = (event: any) => {
    if (event.value?.type) setPaymentMethod(event.value.type)
    setIsPaymentElementComplete(event.complete && !event.error)
  }

  const handleServerResponse = async (response: any) => {
    if (response.error) {
      // Show error from server on payment form
    } else if (response.status === 'requires_action') {
      // Use Stripe.js to handle the required next action
      const { error, paymentIntent } = await stripe.handleNextAction({
        clientSecret: response.clientSecret,
      })

      if (error) {
        // Show error from Stripe.js in payment form
      } else {
        // Actions handled, show success message
      }
    } else {
      // No actions needed, show success message
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (elements == null) return

    setIsLoading(true)

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message)
      return
    }

    const { error: confirmationTokenError, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {
        payment_method_data: {
          billing_details: {
            name: data.signing_name,
          },
        },
      },
    })

    if (confirmationTokenError) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
      setIsLoading(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      // SEND REQUEST TO API TO CONFIRM PAYMENT
      try {
        // run
        const payQuoteResult = await payQuote({ confirmation_token: confirmationToken.id })

        handleServerResponse(payQuoteResult)
      } catch (error) {
        setIsLoading(false)
      } finally {
        setIsLoading(false)
        invalidateQueries('quotes')
      }
    }
  }

  React.useEffect(() => {
    elements?.update({ amount: upfrontAmount * 100 })
  }, [elements, upfrontAmount])

  return (
    <>
      <PaymentElement
        options={{
          layout: 'tabs',
          business: { name: 'Behave Health Corp.' },
          fields: {
            billingDetails: {
              name: 'auto',
            },
          },
          defaultValues: {
            billingDetails: {
              name: data.signing_name,
              email: data.billed_to_email,
              phone: data.billed_to_phone_no,
            },
          },
        }}
        onChange={handlePaymentElementChange}
      />

      {data?.payment_legal_language_text && (
        <Alert small contrast type="warning" glyph="info" className="mt-4">
          {data.parsed_payment_legal_language_text || data.payment_legal_language_text}
        </Alert>
      )}

      {errorMessage && <Alert glyph="error">{errorMessage}</Alert>}

      <div className="grid gap-1">
        {shouldPassFees && fees && (
          <Alert small glyph="info">
            Amount displayed includes payment processing fees of {beautifulAmount(fees)}
          </Alert>
        )}

        <Button
          label={`Pay ${beautifulAmount(upfrontAmount)} to Behave Health`}
          color="green"
          type="primary"
          glyph="tick_circle"
          className="mt-4"
          onClick={handleSubmit}
          isDisabled={!isPaymentElementComplete}
          isLoading={isLoading}
        />
      </div>
    </>
  )
}
