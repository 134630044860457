import React from 'react'

import SmartStatus from '../SmartStatus'

// NOTE: We don't know the list of all possible responses yet
const statuses = {
  Active: {
    label: 'Active',
    color: 'green',
  },
  Inactive: {
    label: 'Inactive',
    color: 'red',
  },
}

const EVOBCoverageStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default EVOBCoverageStatus
