import React from 'react'
import { useSelector } from 'react-redux'

import CardsGrid from '../../CardsGrid'
import FinancialCard from '../../Cards/FinancialCard'

import { useAPIwithNoAbort } from '../../../hooks/useAPI'

const PayerTransactionStats = ({ url }: any) => {
  const stats = useSelector((state: any) => state.data['payer_transactions_stats']?.data)

  useAPIwithNoAbort('payer_transactions_stats', `${url}/payer_stats`)

  return (
    <CardsGrid columnWidth={200}>
      <FinancialCard title="Current Balance" value={parseFloat(stats?.balance)} />
      <FinancialCard title="Total Charges" value={parseFloat(stats?.charges)} valueColor="red" prefix="-" />
      <FinancialCard title="Total Successful Payments" value={parseFloat(stats?.payments)} valueColor="green" prefix="+" />
      <FinancialCard title="Payments Processing" value={parseFloat(stats?.processing_payments)} valueColor="gray" prefix=" " />
      <FinancialCard title="Failed Payments" value={parseFloat(stats?.failed_payments)} valueColor="red" prefix=" " />
      <FinancialCard title="Total Credits" value={parseFloat(stats?.credits)} valueColor="orange" prefix="+" />
      <FinancialCard title="Total Refunds" value={parseFloat(stats?.refunds)} valueColor="gray" prefix="-" />
      <FinancialCard title="Total Write-Off's" value={parseFloat(stats?.write_offs)} valueColor="paleGreen" prefix="+" />
      <FinancialCard title="Total Future Planned Charges" value={parseFloat(stats?.future_charges)} valueColor="black" />
    </CardsGrid>
  )
}

export default PayerTransactionStats
