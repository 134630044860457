import React from 'react'

import Stepper from '../Stepper/Stepper'

import { AdmissionsIntake } from './pages/AdmissionsIntake'
import { BusinessDescription } from './pages/BusinessDescription'
import { Clients } from './pages/Clients'
import { CommunicationsOperations } from './pages/CommunicationsOperations'
import { DigitalMarketing } from './pages/DigitalMarketing'
import { ElectronicHealthRecordsFeatures } from './pages/ElectronicHealthRecordsFeatures'
import { FinancialDetails } from './pages/FinancialDetails'
import { GeneralInformation } from './pages/GeneralInformation'
import { HealthcareFacilityDetails } from './pages/HealthcareFacilityDetails'
import { HealthcarePracticeDetails } from './pages/HealthcarePracticeDetails'
import { Locations } from './pages/Locations'
import { NPI } from './pages/NPI'
import { RecoveryAccountability } from './pages/RecoveryAccountability'
import { RevenueCycleManagement } from './pages/RevenueCycleManagement'
import { ServiceCodes } from './pages/ServiceCodes'
import { Staff } from './pages/Staff'

import { SubmitButton } from './components/SubmitButton'

const HEALTHCARE_FACILITIES = ['healthcare_facility', 'healthcare_practice']
const SUPPORT_FACILITIES = ['support_facility', 'support_practice']

const SteppedOnboardingForm = ({ accountType, onSave, isLoading, initialData }: any) => {
  if (!accountType) return null

  const isHealthcare = HEALTHCARE_FACILITIES.includes(accountType)
  const isSupport = SUPPORT_FACILITIES.includes(accountType)

  const isHealthcareOrSupport = isHealthcare || isSupport
  const isHealthcareFacility = accountType === 'healthcare_facility'
  const isHealthcarePractice = accountType === 'healthcare_practice'

  return (
    <Stepper initialData={initialData}>
      <Stepper.ProgressBar css={styles.progressBar} />

      <Stepper.BackButton css={styles.backButton} />

      <Stepper.Page step="business_description">
        <BusinessDescription />
      </Stepper.Page>

      <Stepper.Page step="npi" permission={isHealthcare}>
        <NPI />
      </Stepper.Page>

      <Stepper.Page step="general_information">
        <GeneralInformation isHealthcareFacility={isHealthcareFacility} isHealthcare={isHealthcare} />
      </Stepper.Page>

      <Stepper.Page step="locations">
        <Locations />
      </Stepper.Page>

      <Stepper.Page step="clients" permission={isHealthcareOrSupport}>
        <Clients isHealthcare={isHealthcare} />
      </Stepper.Page>

      <Stepper.Page step="staff" permission={isHealthcareOrSupport}>
        <Staff isHealthcare={isHealthcare} />
      </Stepper.Page>

      <Stepper.Page step="healthcare_facility_details" permission={isHealthcareFacility}>
        <HealthcareFacilityDetails />
      </Stepper.Page>

      <Stepper.Page step="healthcare_practice_details" permission={isHealthcarePractice}>
        <HealthcarePracticeDetails />
      </Stepper.Page>

      <Stepper.Page step="service_codes" permission={isHealthcare}>
        <ServiceCodes />
      </Stepper.Page>

      <Stepper.Page step="electronic_health_records_features" permission={isHealthcare}>
        <ElectronicHealthRecordsFeatures />
      </Stepper.Page>

      <Stepper.Page step="admissions_intake">
        <AdmissionsIntake />
      </Stepper.Page>

      <Stepper.Page step="recovery_accountability">
        <RecoveryAccountability />
      </Stepper.Page>

      <Stepper.Page step="communications_operations">
        <CommunicationsOperations />
      </Stepper.Page>

      <Stepper.Page step="financial_details" onChange={handleFinancialsChange}>
        <FinancialDetails isHealthcare={isHealthcare} />
      </Stepper.Page>

      <Stepper.Page step="revenue_cycle_management" permission={isHealthcare}>
        <RevenueCycleManagement />
      </Stepper.Page>

      <Stepper.Page step="digital_marketing">
        <DigitalMarketing />
      </Stepper.Page>

      <Stepper.ContinueButton css={styles.continueButton} />

      <SubmitButton onClick={onSave} isLoading={isLoading} css={styles.continueButton} />
    </Stepper>
  )
}

const handleFinancialsChange = (context: any) => {
  const { currentStep, data, hideStep, hiddenSteps, showStep } = context

  if (currentStep !== 'financial_details') return

  const isRCMHidden = hiddenSteps.includes('revenue_cycle_management')
  const acceptedPaymentMethods = data?.questionnaire?.financial_details?.accepted_payment_methods

  let showRCM =
    acceptedPaymentMethods?.cash_or_self_payment ||
    acceptedPaymentMethods?.private_health_insurance_out_of_network ||
    acceptedPaymentMethods?.private_health_insurance_in_network ||
    acceptedPaymentMethods?.medicare ||
    acceptedPaymentMethods?.medicaid

  if (isRCMHidden && showRCM) {
    showStep('revenue_cycle_management')
  }

  if (!isRCMHidden && !showRCM) {
    hideStep('revenue_cycle_management')
  }
}

const styles = {
  progressBar: {
    marginBottom: '2rem',
    display: 'grid',
    justifyContent: 'center',
  },

  continueButton: {
    marginTop: '1rem',
  },

  backButton: {
    marginBottom: '1rem',
  },
}

export default SteppedOnboardingForm
