import React from 'react'

import { address } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

import { SectionCard } from '../../components/SectionCard'

import AddressInput from '../../components/Forms/AddressInput'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import DataList from '../../components/DataList'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Overlay from '../../components/Overlay'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'

import { InvoiceDetails } from './common/InvoiceDetails'
import { InvoiceSettings } from './common/InvoiceSettings'
import { ClientInvoiceLines } from './common/ClientInvoiceLines'

export const ClientInvoiceBuilderOverlay = (props: any) => {
  const { id, data, isNew, close, form, edit, cancel, isEditable, initialModel, onValidationUpdate } = useOverlay({
    name: 'invoice',
    options: props,
  })

  const { tenant } = useSettings()

  const [invoiceLines, setInvoiceLines]: any = React.useState([])
  const [deletedInvoiceLines, setDeletedInvoiceLines]: any = React.useState([])

  const [customBilledFrom, setCustomBilledFrom] = React.useState(false)
  const [customBilledTo, setCustomBilledTo] = React.useState(false)

  return (
    <Overlay showBackdrop onClose={close} position="right" maxWidth={120}>
      <Overlay.Header title="New Client Invoice" icon="invoices_blue" />

      <Overlay.Content className="p-5">
        <Form
          isCompact
          getForm={form}
          isEditable={isEditable}
          initialModel={{
            billed_from_name: tenant.name,
            billed_from_email: tenant.email,
            billed_from_phone_no: tenant.phone_no,
            billed_from_address: tenant.billing_address,
          }}
          onValidationUpdate={onValidationUpdate}
          className="grid gap-4"
        >
          <div className="grid grid-cols-1 mq1200:grid-cols-3 gap-5">
            <InvoiceDetails />

            <SectionCard title="Billed From">
              <FormSection maxWidth="100%" className="!gap-1">
                <Label
                  isCompact
                  as="div"
                  label="Billed From"
                  after={
                    <Checkbox
                      label="Custom Details"
                      model="is_custom"
                      value={customBilledFrom}
                      onUpdate={({ value }) => setCustomBilledFrom(value)}
                      variant="skinny"
                    />
                  }
                />

                {!customBilledFrom && tenant && (
                  <div className="flex items-center">
                    <Avatar src={tenant.logo || ''} initials={tenant.name} size={24} />
                    <div className="ml-2">{tenant.name}</div>
                  </div>
                )}

                {customBilledFrom && isEditable ? (
                  <>
                    <Input label="Name" model="billed_from_name" />
                    <Input label="Email" model="billed_from_email" />
                    <Input label="Phone" model="billed_from_phone_no" />
                    <AddressInput model="billed_from_address" />
                  </>
                ) : (
                  <DataList layout="horizontal" isCompact>
                    <DataList.Item label="Name" value={tenant['name']} />
                    <DataList.Item label="Email" value={tenant['email']} />
                    <DataList.Item label="Phone" value={tenant['phone_no']} />
                    <DataList.Item label="Address" value={address(tenant['billing_address'])} />
                  </DataList>
                )}
              </FormSection>
            </SectionCard>

            <SectionCard title="Billed To">
              <FormSection maxWidth="100%" className="!gap-1">
                <OverlaySelector
                  withHover={false}
                  label="Billed To"
                  type="clients.all"
                  model="billed_to"
                  icon="clients"
                  isDisabled={customBilledTo}
                  className={customBilledTo ? 'opacity-70' : ''}
                  labelAfter={
                    <Checkbox
                      label="Custom Details"
                      model="is_custom"
                      value={customBilledTo}
                      onUpdate={({ value }) => setCustomBilledTo(value)}
                      variant="skinny"
                    />
                  }
                />

                {customBilledTo && isEditable ? (
                  <>
                    <Input label="Name" model="billed_to_name" />
                    <Input label="Email" model="billed_to_email" />
                    <Input label="Phone" model="billed_to_phone_no" />
                    <AddressInput model="billed_to_address" />
                  </>
                ) : (
                  <DataList layout="horizontal" isCompact>
                    <DataList.Item label="Name" value={data?.[`billed_to_name`]} />
                    <DataList.Item label="Email" value={data?.[`billed_to_email`]} />
                    <DataList.Item label="Phone" value={data?.[`billed_to_phone_no`]} />
                    <DataList.Item label="Address" value={address(data?.[`billed_to_address`])} />
                  </DataList>
                )}
              </FormSection>
            </SectionCard>
          </div>

          <ClientInvoiceLines
            isEditable
            data={data}
            invoiceLines={invoiceLines}
            setInvoiceLines={setInvoiceLines}
            deletedInvoiceLines={deletedInvoiceLines}
            setDeletedInvoiceLines={setDeletedInvoiceLines}
          />
          <InvoiceSettings />
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save Invoice" color="green" type="primary" glyph="tick_circle" />
      </Overlay.Footer>
    </Overlay>
  )
}
