import React from 'react'

const FlowChartGroup = ({ children }: any) => {
  return <div css={styles}>{children}</div>
}

const styles = {
  display: 'grid',
  gridRowGap: 'var(--gap-y)',
  gridColumnGap: 'var(--gap-x)',

  '.is-horizontal &': {
    gridAutoFlow: 'row',
    gridTemplateRows: `repeat(var(--grid-spaces), 1fr)`,
  },

  '.is-vertical &': {
    gridAutoFlow: 'column',
    gridTemplateColumns: `repeat(var(--grid-spaces), 1fr)`,
  },
}

export default FlowChartGroup
