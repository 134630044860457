import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  in: {
    label: 'Checked-In',
    color: 'blue',
  },
  out: {
    label: 'Checked-Out',
    color: 'green',
  },
  late: {
    label: 'Late Checkout',
    color: 'orange',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
