import React from 'react'
import { tint } from 'polished'
import { keyframes } from '@emotion/react'

import { Color } from '../declarations/types'

import Flex from './Flex'
import { COLORS } from '../theme'

type Props = {
  color: Color
  size: number | string
  speed: number
} & React.HTMLAttributes<any>

const Loader: React.FC<Props> = ({ color = 'violet', size = 20, speed = 320, className, ...rest }) => {
  const dynamicStyles: any = {
    width: size,
    height: size,
    borderColor: tint(0.25, COLORS[color] || color),
    animation: `${rotate} ${speed}ms linear infinite`,
  }

  return (
    <Flex fixSelf className={className} {...rest}>
      <div css={[styles, dynamicStyles]} />
    </Flex>
  )
}

const styles = {
  borderRadius: '50%',
  border: '2px solid transparent',
  borderBottomColor: 'transparent !important',
  borderLeftColor: 'transparent !important',
  willChange: 'animation',
}

const rotate = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(359deg) }
`

export default Loader
