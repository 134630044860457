import React from 'react'

import { COLORS } from '../../../theme'
import useCopyToClipboard from '../../../hooks/useCopyToClipboard'

import Empty from './Empty'
import Button from '../../Button'
import Flex from '../../Flex'

const JSONCell: React.FC<any> = ({ value }) => {
  if (!value) return <Empty />

  const stringifiedValue = JSON.stringify(value)
  const { copy } = useCopyToClipboard({ text: stringifiedValue })

  return (
    <Flex gap="0.3rem" justifyContent="space-between" nowrap>
      <pre
        title={stringifiedValue}
        css={{
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {stringifiedValue}
      </pre>

      <Button
        label="Copy"
        onClick={copy}
        type="minimal"
        size={100}
        color="text"
        glyph="copy"
        glyphColor={COLORS.blue}
        className="!grow-0 !shrink-0"
      />
    </Flex>
  )
}

export default JSONCell
