import React from 'react'
import { tint } from 'polished'

import { CLINICAL_ASSESSMENTS_SLUGS, CLINICAL_ASSESSMENTS_TITLES } from '../../../utils/constants'
import { COLORS } from '../../../theme'
import { getVersionSlug, usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'
import DataFormStatus from '../../../components/Statuses/DataFormStatus'
import DataList from '../../../components/DataList'

import { DataFormTooltip } from '../components/DataFormTooltip'

export const ClinicalAssessment = ({ data, client }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  const { version, id, subcategory } = data

  const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

  return (
    <DataFormTooltip
      title={data.name || CLINICAL_ASSESSMENTS_TITLES[data.subcategory] || 'Clinical Assessment'}
      icon="clinical_assessments"
      color={tint(0.4, COLORS.blue)}
      startDate={data.dated_at}
      recordLink={`/clinical-assessments/${CLINICAL_ASSESSMENTS_SLUGS[subcategory]}/${id}${formattedVersion}`}
      status={data.status}
      client={client}
    >
      <DataList isCompact withPadding labelWidth={120}>
        <DataList.Item label="Status" value={<DataFormStatus status={data.status} />} />
        <DataList.Item label="Date and Time" value={usDateTime(data.dated_at, timezone)} />
        <DataList.Item label="Date Added" value={usDateTime(data.created_at, timezone)} />
        <DataList.Item label="Last Edited" value={usDateTime(data.updated_at, timezone)} />
        <DataList.Item label="Added By" value={data.author?.name} />
      </DataList>
    </DataFormTooltip>
  )
}
