import React from 'react'

import FormSection from '../../../../Forms/FormSection'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const SNAP = ({ model = 'data', isEditable }) => (
  <FormSection layout="vertical" maxWidth="100%">
    <SmartTextarea
      useDictation
      minRows={5}
      isEditable={isEditable}
      label="Strengths"
      description="What personal qualities does the client have which can be built upon in treatment?"
      placeholder="eg. Open minded, Friendly, Creative, Good Listener, Quick Learner, Good Grooming, Organized, Good Problem Solver, Good Decision Maker, Dependable, Motivation, Good health"
      model={`${model}.snap.strengths`}
    />
    <SmartTextarea
      useDictation
      isEditable={isEditable}
      label="Needs"
      description="What would help the client achieve their goals?"
      model={`${model}.snap.needs`}
      placeholder="eg. Increase my knowledge of support resources, Access to medical care, Staying in a sober environment, Learn how to empower myself, Increasing effective communication skill"
    />
    <SmartTextarea
      useDictation
      isEditable={isEditable}
      minRows={5}
      label="Abilities"
      description="What skills does the client possess?"
      model={`${model}.snap.abilities`}
      placeholder="eg. Basic ability to read and write, Computer skills, Work effectively with others, Ability to have positive relationships, Education / Training, Job Skills, Leisure Skills"
    />
    <SmartTextarea
      useDictation
      isEditable={isEditable}
      minRows={5}
      label="Preferences"
      description="How does the client want their treatment?"
      model={`${model}.snap.preferences`}
      placeholder="eg. Family or friends to be involved in treatment, have Family Meetings, Learn Face to face, Be hands on, Reading written material alone or together with others, Be independent or be with others"
    />
  </FormSection>
)

export default SNAP
