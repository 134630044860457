import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { ProgramListsReportDataTable } from '@behavehealth/constructs/LiveReports/ProgramListsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ProgramLists = () => {
  const tableProps = useDataTable({
    name: ['reports', 'program-lists'],
    endpoint: `/live_reports?category=program_lists`,
    localStorageKey: 'report_program_lists_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `/programs/${rowData?.program?.id}/lists/today/${rowData.id}`, [])

  return (
    <Page feature="programs" title="Live Program Lists Report">
      <Grid>
        <ProgramListsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ProgramLists)
