import React from 'react'

import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Accordion from '../../components/Accordion'
import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import EmailInput from '../../components/Forms/EmailInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'

const PARENT_TYPES = {
  location: 'House',
  organization: 'Organization',
}

const RootNewPropertyLocationOverlay = (props: any) => {
  const { parentID, bucket = 'locations' } = props

  const { close, form, parent, initialModel, isEditable, isInvalid, isOverlayLoading, isSaving, onValidationUpdate, saveWithData } =
    useOverlay({
      name: bucket,
      endpoint: '/houses',
      invalidate: bucket,
      invalidateKeys: ['locations'],
      options: props,
      parentType: 'location',
      parentRequest: '/houses',
    })

  const { timezone } = useSettings()

  const handleSave = () => {
    saveWithData({
      ...form.current?.getFormValue(),
      ...(parentID && { parent_id: parentID }),
    })
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon={ICONS.gps_check_in_out} title="Create a New Location" help={<HelpTagIframe id="add_location" />} />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={{
            variant: 'property',
            parent_id: parent.id,
            parent_type: PARENT_TYPES[parent?.type] || 'House',
          }}
        >
          <Section>
            <Accordion isOpen title="Details">
              <FormSection>
                <Input
                  autoFocus
                  label="Location Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a property name',
                    },
                  }}
                />

                <Flex alignItems="flex-end" gap="1rem">
                  <Select
                    className="!flex-1"
                    label="Category"
                    model="category"
                    defaultValue="housing"
                    validations={{
                      presence: {
                        message: 'Please select a use',
                      },
                    }}
                  >
                    <Option label="Housing" value="housing" />
                    <Option label="Office" value="office" />
                    <Option label="Mixed (Housing & Office)" value="mixed_category" />
                  </Select>

                  <Checkbox label="Enable Healthcare" model="insurance_billing_enabled" />
                </Flex>

                <Flex stretchChildrenX gap="1rem">
                  <ContextShow when="category" within={['housing', 'mixed_category']}>
                    <Select
                      label="Accepted Gender"
                      model="gender"
                      layout="vertical-dense"
                      validations={{
                        presence: {
                          message: 'Please select the accepted gender',
                        },
                      }}
                      defaultValue="mixed"
                    >
                      <Option label="Mixed" value="mixed" />
                      <Option label="Female" value="female" />
                      <Option label="Male" value="male" />
                    </Select>
                  </ContextShow>

                  <ContextShow when="insurance_billing_enabled" is={true}>
                    <Select label="Organization Type" model="organization_type" defaultValue="facility">
                      <Option label="Facility" value="facility" />
                      <Option label="Practice" value="practice" />
                    </Select>
                  </ContextShow>

                  <ContextShow when="organization_type" is="practice">
                    <Select label="Practice Type" model="practice_type" defaultValue="solo_practice">
                      <Option label="Solo Practice" value="solo_practice" />
                      <Option label="Partnership" value="partnership" />
                      <Option label="Professional Association" value="professional_association" />
                      <Option label="Clinic" value="clinic" />
                      <Option label="Hospital / Single Entity Facility" value="hospital" />
                      <Option label="Hospital Unit / Distinct Part Facility" value="hospital_unit" />
                      <Option label="Individual (Corporation filling under an Individual)" value="individual" />
                    </Select>
                  </ContextShow>
                </Flex>
              </FormSection>
            </Accordion>

            <ContextShow when="insurance_billing_enabled" is={true}>
              <Accordion isOpen title="Healthcare Details">
                <FormSection>
                  <Flex stretchChildrenX gap="1rem">
                    <Input label="NPI" model="npi" />
                    <Input label="Taxonomy" model="taxonomy" />
                  </Flex>

                  <Divider className="!m-0" />

                  <Flex stretchChildrenX gap="1rem">
                    <Input label="Tax ID" model="tax_identifier" />
                    <Input label="Site ID" model="site_identifier" />
                  </Flex>

                  <Flex stretchChildrenX gap="1rem">
                    <Input label="BlueCross ID" model="bluecross_identifier" />
                    <Input label="BlueShield ID" model="blueshield_identifier" />
                  </Flex>

                  <Flex stretchChildrenX gap="1rem">
                    <Input label="Medicare ID" model="medicare_identifier" />
                    <Input label="Medicaid ID" model="medicaid_identifier" />
                  </Flex>
                </FormSection>
              </Accordion>
            </ContextShow>

            <Accordion isOpen title="Contact Details">
              <FormSection>
                <EmailInput label="Email" model="email" />

                <Flex gap="1rem">
                  <PhoneInput className="!flex-auto" label="Phone Number" model="phone_no" />
                  <Input size={5} glyph="phone_ext" label="Phone Ext" model="phone_no_ext" />
                </Flex>
              </FormSection>
            </Accordion>

            <Accordion isOpen title="Address">
              <FormSection>
                <AddressSelectorInput model="address" actionLabel="Find Address" />
              </FormSection>
            </Accordion>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Create Property"
          glyph="check"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={handleSave}
          isDisabled={isInvalid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const NewPropertyLocationOverlay = withOverlayError(RootNewPropertyLocationOverlay)
