import React from 'react'

import pluralize from 'pluralize'
import startCase from 'lodash/startCase'
import kebabCase from 'lodash/kebabCase'

import { amount, usDate, niceAmount } from '../../utils/functions'
import { PAYMENT_METHODS } from '../../utils/constants'
import withSettings from '../../hocs/withSettings'

import Status from '../Status'
import Glyph from '../Glyph'
import Flex from '../Flex'
import ChargeStatus from '../Statuses/ChargeStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, isSelectable: boolean, timezone: string) => [
	{
		isSticky: true,
		canToggleVisible: false,
		id: 'name',
		Header: 'Name',
		accessor: 'name',
		Cell: ({ value, row }: any) => (
			<TableCell.MainLink
				to={isSelectable && to({ id: row.original.id, category: kebabCase(pluralize(row.original.category)) })}
				label={value}
			/>
		)
	},
	{
		Header: 'Amount',
		accessor: 'total_amount',
		width: 250,
		Cell: ({ row, value }: any) => {
			let valueColor = 'red'
			let sign = '+'

			if (row.values.category === 'payment') {
				valueColor = 'green'
			} else if (row.values.category === 'refund') {
				valueColor = 'gray'
				sign = '-'
			} else if (row.values.category === 'write_off') {
				valueColor = 'green'
			} else if (row.values.category === 'credit') {
				valueColor = 'orange'
			} else sign = '-'

			const hasCustomCollection = row.original.is_custom_collection

			return (
				<Flex gap="0.5rem" nowrap justifyContent="flex-end">
					<Status color={valueColor} label={`${sign}$${amount(value)}`} />
					{hasCustomCollection && (
						<Status
							color="green"
							label={`${sign}$${amount(row.original.collection_amount)}`}
							infoTooltip={`Collecting ${niceAmount(row.original.collection_amount)} when Charged`}
						/>
					)}
				</Flex>
			)
		}
	},
	{
		id: 'category',
		Header: 'Transaction Type',
		accessor: 'category',
		Cell: ({ value }: any) => startCase(value)
	},
	{
		id: 'status',
		Header: 'Status',
		accessor: 'status',
		Cell: ({ value }: any) => <ChargeStatus status={value} />
	},
	{
		Header: 'Transaction Date',
		accessor: 'transactioned_at',
		Cell: ({ value }: any) => usDate(value, timezone),
		Filter: TableFilter.Date,
		filter: 'date'
	},
	{
		width: 250,
		accessor: 'payed_with',
		Header: 'Payed With',
		Cell: ({ value }: any) => {
			if (!PAYMENT_METHODS?.[value]) return <TableCell.Empty />

			return (
				<>
					<Glyph glyph={PAYMENT_METHODS[value]?.glyph} /> {PAYMENT_METHODS[value]?.label}
				</>
			)
		}
	},
	{
		width: 250,
		accessor: 'payment_method',
		Header: 'Payment Method',
		Cell: ({ value }: any) => <TableCell value={value?.name || '–'} />
	},
	{
		Header: 'Notes',
		accessor: 'notes',
		Cell: ({ value }: any) => <TableCell.Truncated value={value} />
	},
	{
		width: 200,
		accessor: 'created_at',
		Header: 'Date Added',
		Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
		Filter: TableFilter.Date,
		filter: 'date'
	},
	{
		width: 220,
		accessor: 'updated_at',
		Header: 'Date Updated',
		Filter: TableFilter.Date,
		filter: 'date',
		Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />
	},
	{
		id: 'author',
		width: 180,
		accessor: 'author',
		Header: 'Added By',
		Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />
	}
]

type Props = {
	data: Object
	hiddenColumns?: string[]
	isLoading: boolean
	isSelectable: boolean
	localStorageKey: string
	timezone: string
	to?: Function
	titleAfter?: React.ReactNode
}

const TransactionsTable = ({
	data,
	hiddenColumns,
	isLoading,
	isSelectable,
	batchActionsConfig,
	localStorageKey,
	to,
	timezone,
	titleAfter,
	testKey
}: Props) => {
	return (
		<Table
			testKey={testKey}
			title="Transactions"
			titleAfter={titleAfter}
			icon="financials"
			data={data}
			columns={columns(to, isSelectable, timezone)}
			isLoading={isLoading}
			hiddenColumns={hiddenColumns}
			emptyDescription="No transactions added yet"
			batchActionsConfig={batchActionsConfig}
			localStorageKey={localStorageKey}
		/>
	)
}

TransactionsTable.defaultProps = {
	localStorageKey: 'transactions'
}

export default withSettings(TransactionsTable)
