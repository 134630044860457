import React from 'react'

import { age, titleCase, usDateTime } from '../../../utils/functions'
import { COLORS } from '../../../theme'
import { useSettings } from '../../../hooks/useSettings'

import Alert from '../../../components/Alert'
import Avatar from '../../../components/Avatar'
import BedOccupancyStatus from '../../../components/Statuses/BedOccupancyStatus'
import BedSelector from '../../../components/Forms/BedSelector'
import Card from '../../../components/Card'
import CardHeader from '../../../components/CardHeader'
import CardSubtitle from '../../../components/CardSubtitle'
import CardTitle from '../../../components/CardTitle'
import Checkbox from '../../../components/Forms/Checkbox'
import ContextShow from '../../../components/Forms/ContextShow'
import DataList from '../../../components/DataList'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Flex from '../../../components/Flex'
import Form from '../../../components/Forms/Form'
import Glyph from '../../../components/Glyph'
import Grid from '../../../components/Grid'
import Icon from '../../../components/Icon'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Section from '../../../components/Section'
import TreeItem from '../../../components/TreeItem'
import Workflow from '../../../components/Workflow/Workflow'
import { VisualHide } from '../../../components/VisualHide'

const MoveTo = ({ client, data, setData, setStatus }: any) => {
  const { timezone } = useSettings()
  const [valid, setValid] = React.useState()

  const [place, setPlace] = React.useState(data?.place)
  const [showCurrentDetails, setShowCurrentDetails] = React.useState(false)
  const [showNextDetails, setShowNextDetails] = React.useState(false)

  const { goNext }: any = React.useContext(Workflow.Context)

  const toggleCurrentDetails = () => setShowCurrentDetails((c) => !c)
  const toggleNextDetails = () => setShowNextDetails((c) => !c)

  const handleUpdate = (value: any) => {
    setData({
      ...value,
      place: place,
    })
  }

  const handleContinue = () => {
    setStatus('completed')
    goNext()
  }

  React.useEffect(() => {
    valid ? setStatus('completed') : setStatus('todo')
  }, [valid])

  if (!client || !data) return null

  const fromDateLabel = data.status === 'reserved' ? 'Reserve Bed From' : 'Occupy Bed From'
  const untilDateLabel = data.status === 'reserved' ? 'Bed Reserved Until' : 'Bed Occupied Until'
  const periodLabel = data.status === 'reserved' ? 'Bed Reserved Until…' : 'Bed Occupied Until…'

  const currentOccupancy = client.current_bed_occupancy
  const isIndefinite = currentOccupancy?.period_type === 'indefinite'

  return (
    <Form
      useLocalModel
      initialModel={{ ...data, skip_new_occupancy: false }}
      onValidationUpdate={setValid}
      onUpdate={handleUpdate}
      timezone={timezone}
    >
      <Section paddingLeft={0} paddingRight={0} css={{ marginTop: 0 }}>
        <Grid gap="1rem">
          <Card baseline="50px">
            <CardHeader graphic={<Avatar initials={client.name} src={client.avatar} size={32} />}>
              <CardTitle title={client.name} className="text-[1.04rem]" />
              <CardSubtitle
                subtitle={`${titleCase(client.sex) || '–'}, ${age(client.dob)} y/o, #${client.behave_id}`}
                className="text-[0.95rem]"
              />
            </CardHeader>
          </Card>

          {currentOccupancy && (
            <Card css={{ padding: '0.2rem 0' }}>
              <Flex centerY gap="0.75rem" css={{ padding: '0.2rem 1rem' }}>
                <Icon icon="beds" />
                <div css={{ fontWeight: 700, fontSize: '1.2rem' }}>
                  Current Bed {currentOccupancy.status === 'reserved' ? 'Reservation' : 'Assignment'}
                </div>
              </Flex>

              <DataList isCompact withPadding>
                <DataList.Item label="Status" value={<BedOccupancyStatus status={currentOccupancy.status} />} />

                <DataList.Item
                  label="Location"
                  value={
                    <Flex centerY gap="0.4rem">
                      <Avatar src={currentOccupancy.place?.house.avatar} initials={currentOccupancy.place?.house?.name} size={24} />
                      <div className="!font-[600]">{currentOccupancy.place?.house?.name}</div>
                    </Flex>
                  }
                />

                <TreeItem title="More Details" isOpen={showCurrentDetails} onClick={toggleCurrentDetails} />

                <VisualHide when={!showCurrentDetails}>
                  <DataList.Item
                    label="Current Bed"
                    value={
                      <Flex centerY gap="0.5rem">
                        <div>
                          <Flex nowrap centerY gap="0.3rem">
                            <Icon icon="floors" size={20} />
                            <div>{currentOccupancy.place?.floor?.name}</div>
                          </Flex>
                        </div>

                        <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                        <div>
                          <Flex nowrap centerY gap="0.3rem">
                            <Icon icon="rooms" size={20} />
                            <div>{currentOccupancy.place?.room?.name}</div>
                          </Flex>
                        </div>

                        <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                        <div>
                          <Flex nowrap centerY gap="0.3rem">
                            <Icon icon="beds" size={20} />
                            <div>{currentOccupancy.place?.name}</div>
                          </Flex>
                        </div>
                      </Flex>
                    }
                  />

                  <DataList.Item
                    label={currentOccupancy.status === 'occupied' ? 'Occupied From' : 'Reserved From'}
                    value={usDateTime(currentOccupancy.started_at, timezone)}
                  />
                  <DataList.Item
                    label={currentOccupancy.status === 'occupied' ? 'Occupied Until' : 'Reserved Until'}
                    value={isIndefinite ? 'Discharged' : usDateTime(currentOccupancy.ended_at, timezone)}
                  />
                </VisualHide>
              </DataList>

              <VisualHide when={!showCurrentDetails}>
                <Grid gap="0.75rem" css={{ padding: '1rem 1rem 0.5rem' }}>
                  <RadioGroup
                    label={`When would you like to end the Current Bed ${
                      currentOccupancy.status === 'reserved' ? 'Reservation' : 'Assignment'
                    }?`}
                    model="should_end_current_bed_occupancy"
                    layout="horizontal-dense"
                    defaultValue={true}
                  >
                    <Radio label={`End Now`} value={true} />
                    <Radio label={`Another Time`} value={false} />
                  </RadioGroup>

                  <ContextShow when="should_end_current_bed_occupancy" is={false}>
                    <DateTimeInput
                      defaultToNow
                      label={`Current Bed ${currentOccupancy.status === 'reserved' ? 'Reservation' : 'Assignment'} End Date`}
                      model="current_bed_occupancy_ended_at"
                      validations={{
                        presence: {
                          message: 'Please enter an end date',
                        },
                      }}
                    />

                    <Alert type="warning">
                      Please select the date when the above{' '}
                      <strong>Bed {currentOccupancy.status === 'reserved' ? 'Reservation' : 'Assignment'}</strong> should end
                    </Alert>
                  </ContextShow>
                </Grid>
              </VisualHide>
            </Card>
          )}

          <Card headingType="h2" title="Move to…" paddingLeft={0} paddingRight={0}>
            <Flex justifyContent="space-between">
              <Flex centerY gap="0.75rem" css={{ padding: '0.4rem 1rem' }}>
                <Icon icon="beds" />
                <div css={{ fontWeight: 700, fontSize: '1.2rem' }}>Move To & Start New Bed Assignment</div>
              </Flex>

              {currentOccupancy && (
                <div css={{ padding: '0.25rem 0.5rem' }}>
                  <Checkbox label="Unassign from Bed" model="skip_new_occupancy" css={STYLES.checkbox} />
                </div>
              )}
            </Flex>

            {!data.skip_new_occupancy && (
              <Grid gap="0.5rem">
                <div css={{ padding: '0 1rem 0' }}>
                  <BedSelector
                    isPolymorphic
                    quickFinderEnabled
                    clientId={client?.id}
                    fromDate={data?.started_at}
                    fromLabel={fromDateLabel}
                    setFromDate={(date) => {
                      setData({ started_at: date })
                    }}
                    setUntilDate={(date) => {
                      setData({ ended_at: date })
                    }}
                    untilDate={data?.ended_at}
                    untilLabel={untilDateLabel}
                    label="Bed"
                    model="place"
                    validations={{
                      presence: {
                        message: 'Please select a bed',
                      },
                    }}
                    afterChange={({ value }: any) => setPlace(value)}
                  />
                </div>

                <TreeItem title="More Details" isOpen={showNextDetails} onClick={toggleNextDetails} />

                <VisualHide when={!showNextDetails}>
                  <Grid gap="1rem" css={{ padding: '0 1rem 0.75rem' }}>
                    <RadioGroup
                      label="Bed Assignment Type"
                      model="status"
                      defaultValue="occupied"
                      validations={{
                        presence: {
                          message: 'Please select a status',
                        },
                      }}
                      layout="horizontal-dense"
                    >
                      <Radio label="Bed Reserved" value="reserved" />
                      <Radio label="Bed Occupied" value="occupied" />
                    </RadioGroup>

                    <DateTimeInput
                      defaultToNow
                      label={fromDateLabel}
                      model="started_at"
                      value={data?.started_at}
                      onUpdate={({ value }) => {
                        setData({ started_at: value })
                      }}
                      validations={{
                        presence: {
                          message: 'Please enter a start date',
                        },
                      }}
                    />

                    <RadioGroup label={periodLabel} model="period_type" layout="horizontal-dense" defaultValue="indefinite">
                      <Radio label="Client Discharge" value="indefinite" />
                      <Radio label="Date" value="set_datetime" />
                    </RadioGroup>

                    <ContextShow when="period_type" is="set_datetime">
                      <DateTimeInput
                        defaultToTomorrow
                        label={untilDateLabel}
                        model="ended_at"
                        value={data?.ended_at}
                        onUpdate={({ value }) => {
                          setData({ ended_at: value })
                        }}
                        validations={{
                          presence: {
                            message: 'Please enter an end date',
                          },
                          datetime: {
                            earliest: data?.started_at,
                            message: `Please enter a date after "${fromDateLabel}" date`,
                          },
                        }}
                      />
                    </ContextShow>
                  </Grid>
                </VisualHide>
              </Grid>
            )}

            {data.skip_new_occupancy && (
              <Alert type="warning" className="!m-4">
                {client.first_name} will be moved to <strong>Currently Unassigned</strong>
              </Alert>
            )}
          </Card>
        </Grid>
      </Section>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={handleContinue} isDisabled={!valid} />
      </Workflow.Buttons>
    </Form>
  )
}

const STYLES = {
  checkbox: {
    border: 'none !important',
    boxShadow: 'none !important',
    background: 'none !important',
  },
}

export default MoveTo
