import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  queued: {
    label: 'Queued',
    color: 'gray',
  },
  sent: {
    label: 'Sent',
    color: 'blue',
  },
  failed: {
    label: 'Failed',
    color: 'red',
  },
  delivered: {
    label: 'Delivered',
    color: 'green',
  },
  received: {
    label: 'Received',
    color: 'green',
  },
  read: {
    label: 'Read',
    color: 'green',
  },
}

const NotificationLogStatus = (props: any) => {
  return <SmartStatus statuses={statuses} {...props} />
}

export default NotificationLogStatus
