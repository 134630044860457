import React from 'react'

import ContextShow from '../../../../Forms/ContextShow'
import FormSection from '../../../../Forms/FormSection'
import Input from '../../../../Forms/Input'
import Option from '../../../../Forms/Option'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Select from '../../../../Forms/Select'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import Type from '../../../../Typography/Type'
import YesNoRadioGroup from '../../../../Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupExtra from '../../../../Forms/elements/YesNoRadioGroupExtra'

import MentalStatusForm from './MentalStatusForm'

const CognitiveEmotionalBehavioral = () => {
  return (
    <>
      <FormSection layout="vertical">
        <RadioGroup
          model="data.bio_medical.has_ben_hospitalized_for_mental_illness"
          label="Has the client ever been hospitalized for mental illness?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.bio_medical.has_ben_hospitalized_for_mental_illness" is={true}>
          <SmartTextarea
            useDictation
            label="Please list the reason, duration and location of treatment:"
            model="data.bio_medical.has_ben_hospitalized_for_mental_illness_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_suffered_eating_disorder"
          label="Has the client ever suffered from an eating disorder?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_suffered_eating_disorder" is={true}>
          <SmartTextarea
            useDictation
            label="Please list dates and duration of symptoms:"
            model="data.cognitive_emotional_behavioral.has_suffered_eating_disorder_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_suffered_physical_abuse"
          label="Has the client ever been the victim of physical abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_suffered_physical_abuse" is={true}>
          <SmartTextarea
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.has_suffered_physical_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.is_victim_of_emotional_abuse"
          label="Has the client ever been the victim of emotional abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_emotional_abuse" is={true}>
          <SmartTextarea
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.is_victim_of_emotional_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.is_victim_of_verbal_abuse"
          label="Has the client ever been the victim of verbal abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_verbal_abuse" is={true}>
          <SmartTextarea
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.is_victim_of_verbal_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse"
          label="Has the client ever been the victim of sexual abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse" is={true}>
          <SmartTextarea
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <YesNoRadioGroup
          label="Has the client ever been a victim of exploitation?"
          model="data.cognitive_emotional_behavioral.is_victim_of_exploit"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_exploit" is={true}>
          <Select
            label="What type of abuse has the client been a victim of?"
            model="data.cognitive_emotional_behavioral.is_victim_of_exploit_type"
          >
            <Option label="Sexually" value="sexually" />
            <Option label="Drugs and alcohol" value="drugs_and_alcohol" />
            <Option label="Economic" value="economic" />
          </Select>

          <SmartTextarea useDictation label="Please explain:" model="data.cognitive_emotional_behavioral.is_victim_of_exploit_extra" />
        </ContextShow>

        <YesNoRadioGroupExtra
          label="Has the client ever been a part of an environmental trauma?"
          model="data.cognitive_emotional_behavioral.is_part_of_environmental_trauma"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever been a victim of domestic violence?"
          model="data.cognitive_emotional_behavioral.is_victim_of_domestic_violence"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever been a victim of neglect?"
          model="data.cognitive_emotional_behavioral.is_victim_of_neglect"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever experienced a traumatic death or loss?"
          model="data.cognitive_emotional_behavioral.has_experienced_traumatic_death_loss"
        />

        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_harmed_another_person"
          label="Has the client ever tried to harm another person?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_harmed_another_person" is={true}>
          <SmartTextarea
            useDictation
            label="Please explain:"
            model="data.cognitive_emotional_behavioral.has_harmed_another_person_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <YesNoRadioGroup
          label="Does the client have a history of causing harm to animals?"
          model="data.cognitive_emotional_behavioral.has_animals_harm_history"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.has_animals_harm_history" is={true}>
          <Input label="Last episode:" model="data.cognitive_emotional_behavioral.has_animals_harm_history_last_episode" />
          <SmartTextarea useDictation label="Please describe:" model="data.cognitive_emotional_behavioral.has_animals_harm_history_extra" />
        </ContextShow>

        <YesNoRadioGroup
          label="Does the client have a history of setting fires?"
          model="data.cognitive_emotional_behavioral.has_setting_fires_history"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.has_setting_fires_history" is={true}>
          <Input label="Last episode:" model="data.cognitive_emotional_behavioral.has_setting_fires_history_last_episode" />
          <SmartTextarea
            useDictation
            label="Please describe:"
            model="data.cognitive_emotional_behavioral.has_setting_fires_history_extra"
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_family_mental_illness_history"
          label="Is there a history of mental illness in the client's family?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_family_mental_illness_history" is={true}>
          <SmartTextarea
            useDictation
            label="Please list family member and explain:"
            model="data.cognitive_emotional_behavioral.has_family_mental_illness_history_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <YesNoRadioGroup
          label="Is there a history of substance abuse in the client's family?"
          model="data.cognitive_emotional_behavioral.has_family_substance_abuse_history"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.has_family_substance_abuse_history" is={true}>
          <SmartTextarea
            useDictation
            label="Family Member(s)"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.family_members"
          />

          <YesNoRadioGroup
            label="Are they actively using now?"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.actively_using"
          />
          <SmartTextarea
            useDictation
            label="More Details"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.actively_using_extra"
          />

          <YesNoRadioGroup
            label="Are they in recovery?"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.in_recovery"
          />
          <SmartTextarea
            useDictation
            label="More Details"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.in_recovery_extra"
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_family_suicide_history"
          label="Is there a history of suicide in the client's family?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_family_suicide_history" is={true}>
          <SmartTextarea
            useDictation
            label="Please explain:"
            model="data.cognitive_emotional_behavioral.has_family_suicide_history_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </FormSection>

      <Type as="h4" variant="H4" css={styles.heading}>
        Current Mental Status
      </Type>

      <FormSection>
        <MentalStatusForm />

        <SmartTextarea useDictation label="Notes" model="data.cognitive_emotional_behavioral.notes" />
      </FormSection>
    </>
  )
}

const styles = {
  heading: {
    margin: '2rem 0 1rem',
  },
}

export default CognitiveEmotionalBehavioral
