import React from 'react'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

import { ScrollContext } from '../hocs/withScrollContext'

const DEBUG = false

export default class ScrollView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sections: {},
    }

    this.finishRegistering = debounce(this.onRegistrationFinished, 100)

    this.sections = {}
    this.container = null
    this.viewUnmounted = false
  }

  /*
    LIFECYCLE
  */
  componentWillUnmount = () => {
    this.viewUnmounted = true
  }

  /*
    INITIALIZATION
  */
  register = (section) => {
    if (this.viewUnmounted) return

    if (section.id) {
      this.sections[section.id] = section
    } else {
      const key = Object.keys(section)[0]
      if (this.sections[key]) {
        this.sections[key] = {
          name: section[key].name,
          sections: {
            ...this.sections[key].sections,
            ...section[key].sections,
          },
        }
      } else {
        this.sections[key] = {
          name: section[key].name,
          sections: section[key].sections,
        }
      }
    }

    this.finishRegistering()
  }

  registerContainer = (container) => {
    if (this.viewUnmounted) return
    this.container = container
  }

  onRegistrationFinished = () => {
    if (this.viewUnmounted) return

    let t0 = performance.now()
    this.setState({ sections: this.sections })

    let t1 = performance.now()
    if (DEBUG) console.debug('SCROLLS: Registration Finish took ' + (t1 - t0) + ' milliseconds.')
  }

  /*
    CUSTOM
  */
  scrollTo = (name) => {
    if (this.viewUnmounted) return

    let section = get(this.sections, name)
    if (section) {
      const offset = section.ref.current.offsetTop - 20
      if (this.container.current) {
        this.container.current.scrollTop = offset
        this.container.current.parentNode.scrollTop = offset - 53 // 53 = the sticky header height
      }
    }
  }

  updateActive = (name, active) => {
    get(this.sections, name).active = active
    this.setState({ sections: this.sections })
  }

  /*
    RENDER
  */
  render() {
    return (
      <ScrollContext.Provider
        value={{
          registerSection: this.register,
          registerContainer: this.registerContainer,
          updateActive: this.updateActive,
          scrollTo: this.scrollTo,
          sections: this.state.sections,
        }}
      >
        {this.props.children}
      </ScrollContext.Provider>
    )
  }
}
