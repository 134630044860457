import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { Button, Card, Page, PageLayout, HelpTagIframe } from '@behavehealth/components'
import { CustomNotesTable } from '@behavehealth/components/Tables'
import { withPageError } from '@behavehealth/hocs/withPageError'

import useAPI from '@behavehealth/hooks/useAPI'

const AccountNotes: React.FC = () => {
  const match = useRouteMatch()

  const user = useSelector((state) => state.me?.user)

  const data = useSelector((state) => state.data.custom_notes?.data)
  const loading = useSelector((state) => state.data.custom_notes?.loading)

  useAPI('custom_notes', '/custom_notes?variant=account')

  const isBehave = user?.type === 'bh_employee'
  const isEmpty = size(data) === 0
  const actions = isBehave && (
    <>
      <Button label="Import Behave Templates" type="default" glyph="add" link={`${match.url}/import`} />
      <Button label="Add Note" type="primary" glyph="add" link={`${match.url}/new`} />
    </>
  )

  return (
    <Page feature="account_notes" breakpoint="0" actions={!isEmpty && actions} help={<HelpTagIframe id="account_notes" />}>
      <PageLayout>
        <Card>
          <CustomNotesTable
            useAutoPageSize
            title="Account Notes"
            emptyDescription="No account notes have been added yet"
            icon="management_department"
            data={data}
            isLoading={isEmpty && loading}
            emptyActions={actions}
            to={(id) => `account-notes/${id}`}
            localStorageKey="account_notes"
          />
        </Card>
      </PageLayout>
    </Page>
  )
}

export default withPageError(AccountNotes)
