import React from 'react'
import clsx from 'clsx'

import { ELEMENTS } from './elements'
import { useFormBuilder } from '../useFormBuilder'
import { COLORS } from '../../../theme'
import { transparentize } from 'polished'

export const Element = (props: any) => {
  const { children, elementId } = props

  const editElementConfig: any = useFormBuilder((state: any) => state.editElementConfig)
  const element: any = useFormBuilder((state: any) => state.allElements[elementId])
  const isBuilderEditable: any = useFormBuilder((state: any) => state.isEditable)
  const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)
  const setActiveElementId: any = useFormBuilder((state: any) => state.setActiveElementId)
  const variableValues: any = useFormBuilder((state: any) => state.variableValues)
  const environment: any = useFormBuilder((state: any) => state.environment)

  const ELEMENT = React.useMemo(() => ELEMENTS?.[element?.category], [element?.category])
  const Tag = React.useMemo(() => ELEMENT?.component, [ELEMENT])

  const isHidden = element?.config?.is_hidden
  const isActive = element?._isActive
  const isEditable = isBuilderEditable && !isPreviewMode

  const renders = React.useRef(0)

  if (!ELEMENT || !element || !Tag || isHidden) return null

  const isConditionalTrigger = element._isConditionalTrigger

  const rootClasses = clsx(
    'ELEMENT relative p-2',
    isEditable && !isActive && '[&>*]:pointer-events-none',
    isActive && '[&>*]:pointer-events-auto',
    isConditionalTrigger && 'is-conditional-trigger',
    isEditable && 'is-editable',
    isEditable && element?.config?.is_readonly_hidden && 'opacity-40',
    !isEditable && element?.config?.is_readonly_hidden && '!hidden',
  )

  const hoverClasses = clsx(
    'HOVER_ELEMENT absolute -inset-0 bg-vivid-blue-300 opacity-0 hover:opacity-20 !pointer-events-auto border-1 border-solid border-blue-500 rounded-[5px]',
    isActive && '!pointer-events-none opacity-20 !bg-vivid-blue-400',
  )

  return (
    <Tag
      isEditable={isEditable}
      editElementConfig={editElementConfig}
      className={rootClasses}
      element={element}
      environment={environment}
      useParsedConfig={isPreviewMode || !isEditable}
      variableValues={variableValues}
      css={STYLES.root}
      hoverElement={
        <>
          {isEditable && (
            <div
              className={hoverClasses}
              onClick={(e) => {
                setActiveElementId(elementId)
              }}
            />
          )}
        </>
      }
    >
      {element.elements_order?.map((childId: any) => (
        <Element key={childId} elementId={childId} />
      ))}

      {children}

      {/* <Json data={element} /> */}
      {/* <div>Renders: {renders.current++}</div> */}
    </Tag>
  )
}

const STYLES = {
  root: {
    '&.is-editable.is-conditional-trigger': {
      '&::after': {
        content: '"CONDITION TRIGGER"',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: -3,
        left: 0,
        transform: 'translateY(-50%)',
        fontSize: '0.75rem',
        borderRadius: 4,
        fontWeight: 600,
        padding: '0.05rem 0.35rem',
        pointerEvents: 'none !important',
        color: COLORS.white,
        background: COLORS.green,
        letterSpacing: 0.2,
      },

      '&::before': {
        content: '""',
        position: 'absolute',
        top: -3,
        bottom: -6,
        left: -6,
        right: -6,
        border: `1px dashed ${COLORS.green}`,
        borderRadius: 5,
        opacity: 0.4,
        pointerEvents: 'none !important',
        background: transparentize(0.75, COLORS.green),
      },
    },
  },
}
