import React from 'react'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withSettings from '@behavehealth/hocs/withSettings'

import { Page } from '@behavehealth/components'

const Referrals = () => (
  <Page title="Customer Referrals" icon="authorizations" breakpoint="0">
    <iframe
      title="iframe"
      src="https://apps.behavehealth.com/forms/behave-hq-crm-secjol/5c5fa41e-5405-4f69-b380-5d725319081e/d5bb82e1-150d-4ffd-9973-044583b31ab6"
      frameBorder="0"
      border="0"
      cellSpacing="0"
      className="border-none w-full min-h-[2500px] h-full"
      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
    />
  </Page>
)

export default withPageError(withSettings(Referrals))
