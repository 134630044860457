import React from 'react'
import { useSelector } from 'react-redux'
import size from 'lodash/size'
import { useRouteMatch } from 'react-router-dom'

import { COLORS } from '@behavehealth/theme'

import { Button, Flex, Header, SummonOverlay, Text, State } from '@behavehealth/components'
import { apiGet } from '@behavehealth/modules/api'

import ChannelContent from '@behavehealth/components/Chat/ChannelContent'
import ClientAccessListOverlay from '@behavehealth/components/Chat/overlays/ClientAccessListOverlay'
import SubchannelsDropdown from '@behavehealth/components/Chat/SubchannelsDropdown'

const ClientChannelPage: React.FC = () => {
  const match = useRouteMatch()
  const timezone = useSelector((state: any) => state.me?.tenant?.timezone)

  const loading = useSelector((state: any) => state.data?.client_channels?.loading)
  const channels = useSelector((state: any) => state.data?.client_channels?.data)

  const [selectedChannel, setSelectedChannel] = React.useState(null)

  const mainChannel = channels?.main
  const secondaryChannels = channels?.secondary
  const channelMembers = mainChannel?.members

  React.useEffect(() => {
    const getSubchannels = async () => {
      try {
        await apiGet({ name: 'client_channels', url: `/chat/channels/clients/${match.params?.id}` })
      } catch (error) {
        console.error(error)
      }
    }

    getSubchannels()
  }, [match.params.id])

  React.useEffect(() => {
    if (mainChannel) setSelectedChannel(mainChannel)
  }, [mainChannel])

  if (!mainChannel) return <State isLoading />
  if (!selectedChannel) return null

  const hasMembers = size(channelMembers) > 0
  const isMainSelected = selectedChannel?.id === mainChannel?.id

  return (
    <div css={styles}>
      <Header isSticky avatar="" title={mainChannel?.client_name} isLoading={loading} />

      <ChannelContent
        icon="clients_chat"
        channel={selectedChannel}
        timezone={timezone}
        permissions={{
          hide: 'clients_chat.edit',
        }}
        header={
          <Flex centerY justifyContent="space-between" gap={12}>
            <SubchannelsDropdown
              selectedChannel={selectedChannel}
              mainChannel={mainChannel}
              secondaryChannels={secondaryChannels}
              onSelect={(channel: any) => setSelectedChannel(channel)}
            />

            {isMainSelected && (
              <SummonOverlay overlay={<ClientAccessListOverlay channel={mainChannel} clientID={match.params?.id} />}>
                <Button
                  label="Edit Access List"
                  size={200}
                  type="default"
                  glyph="edit"
                  permission="clients_chat.actions.access_list.edit"
                />
              </SummonOverlay>
            )}
          </Flex>
        }
        subheader={
          isMainSelected && (
            <Flex gap={8}>
              <Text label="Access List: " />
              {hasMembers ? (
                Object.keys(channelMembers)?.map((member_id: any) => <Text avatar="" description={channelMembers[member_id]?.name} />)
              ) : (
                <Text description="No Members" />
              )}
            </Flex>
          )
        }
      />
    </div>
  )
}

const styles = {
  display: 'grid',
  gridTemplateRows: 'min-content 1fr',
  gridTemplateColumns: '1fr',
  overflow: 'hidden',
  background: COLORS.lightBackground,
}

export default ClientChannelPage
