import React from 'react'

import Glyph from '../../Glyph'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

export const GlyphCell: React.FC<any> = (props) => {
  return (
    <DataTableCell {...props} css={STYLES.root} hoverExpand={false}>
      {props.value ? <Glyph glyph={props.value} color={props.rowData?.color} /> : <EmptyCell />}
    </DataTableCell>
  )
}

const STYLES = {
  root: {
    textAlign: 'center',
    justifyContent: 'center',

    '& > *': {
      flex: '0 0 auto !important',
    },
  },
}
