import React from 'react'
import get from 'lodash/get'
import { withFormContext } from '@behavehealth/components/Forms/context'

import Attachments from '@behavehealth/components/Forms/Attachments'
import Button from '@behavehealth/components/Button'
import ContextShow from '@behavehealth/components/Forms/ContextShow'
import Flex from '@behavehealth/components/Flex'
import FormSection from '@behavehealth/components/Forms/FormSection'
import Grid from '@behavehealth/components/Grid'
import Input from '@behavehealth/components/Forms/Input'
import State from '@behavehealth/components/State'
import Radio from '@behavehealth/components/Forms/Radio'
import RadioGroup from '@behavehealth/components/Forms/RadioGroup'
import Textarea from '@behavehealth/components/Forms/Textarea'
import { Heading } from '@behavehealth/components/Typography'

const H2 = ({ children }) => {
  return (
    <Heading is="h2" className="!m-0">
      {children}
    </Heading>
  )
}

class Team extends React.Component {
  constructor(props) {
    super(props)

    const modelVal = props.form?.getField(props.model)
    const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

    this.state = {
      team_members: modelVal || initialModelVal || [],
    }
  }

  /*
    CUSTOM
  */
  addMember = () => {
    this.setState({
      team_members: [
        ...this.state.team_members,
        {
          fullName: '',
          jobTitle: '',
          description: '',
          isLicensedHealthcareProvider: null,
          credentials: '',
          providerNpi: '',
          shouldCheckBackground: null,
        },
      ],
    })
  }

  removeMember = (idx) => {
    let team_members = this.state.team_members
    team_members.splice(idx, 1)
    this.setState({ team_members: team_members })
  }

  renderTrigger = () => {
    return (
      this.props.isEditable && (
        <div className="!mt-4">
          <Button glyph="add" label="Add Team Member" onClick={this.addMember} />
        </div>
      )
    )
  }

  /*
    RENDER
  */
  render() {
    const { team_members } = this.state
    const isEmpty = team_members.length === 0

    return (
      <State isEmpty={isEmpty} emptyDescription="No team members" emptyActions={this.renderTrigger()}>
        <FormSection maxWidth={600}>
          {team_members.map((_member, idx) => (
            <Grid gap={16} key={idx}>
              <Flex wrap justifyContent="space-between" alignItems="center">
                <H2>Team Member #{idx + 1}</H2>
                {this.props.isEditable && (
                  <Button
                    //label="Delete"
                    glyph="delete"
                    type="minimal"
                    color="red"
                    size={300}
                    onClick={() => this.removeMember(idx)}
                  />
                )}
              </Flex>
              <Input label="Staff Public Profile Name" model={`team_members[${idx}].fullName`} />
              <Input label="Staff Public Profile Job Title" model={`team_members[${idx}].jobTitle`} />
              <Textarea label="Staff Public Profile Biography" model={`team_members[${idx}].description`} />
              <Attachments isInline label="Staff Public Profile Photo" model={`team_members[${idx}].photo`} />

              <RadioGroup
                label="Is this staff a licensed healthcare provider?"
                model={`team_members[${idx}].isLicensedHealthcareProvider`}
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when={`team_members[${idx}].isLicensedHealthcareProvider`} is={true}>
                <Input label="Staff Credentials" model={`team_members[${idx}].credentials`} />
                <Input label="Staff Provider NPI #" model={`team_members[${idx}].providerNpi`} />
                <Attachments isInline label="Staff License" model={`team_members[${idx}].licensePhoto`} />
              </ContextShow>

              <RadioGroup
                label="Would you like to submit your staff for a background check to participate in the BEHAVE|BRIDGE verified provider program?"
                model={`team_members[${idx}].shouldCheckBackground`}
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>
            </Grid>
          ))}
        </FormSection>

        {this.renderTrigger()}
      </State>
    )
  }
}

export default withFormContext(Team)
