import React from 'react'

import { COLORS } from '../theme'

import Glyph from './Glyph'
import GridTableRow from './GridTableRow'

const GridTableGroup: React.FC = (props: any) => {
  const { children, title, className } = props

  const [isOpen, setIsOpen] = React.useState(props.isOpen)

  const toggleOpen = () => {
    setIsOpen((c) => !c)
  }

  return (
    <div css={styles} className={className}>
      <header css={styles.header} onClick={toggleOpen}>
        <Glyph glyph="triangle_down" css={styles.triangle} className={isOpen ? 'is-open' : ''} size={15} />
        {title}
      </header>

      {isOpen && children}
    </div>
  )
}

const styles = {
  root: {
    gridColumn: '1 / -1',
    background: 'grey',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: COLORS.white,
    borderBottom: `1px solid ${COLORS.divider}`,
    padding: '0.4rem',
    fontWeight: 600,

    '&:hover': {
      background: COLORS.hover,
    },
  },

  triangle: {
    transform: 'rotate(-90deg)',
    marginRight: '0.5rem',

    '&.is-open': {
      transform: 'rotate(0deg)',
    },
  },
}

export default GridTableGroup
