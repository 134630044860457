import React from 'react'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'

import { COLORS } from '../../../theme'

import Glyph from '../../../components/Glyph'
import BaseInput from '../../../components/Forms/BaseInput'

import { useFormBuilder } from '../useFormBuilder'

export const NameEditInput = (props: any) => {
  const [value, setValue] = React.useState(props.value || '')
  const [isEditable, setIsEditable] = React.useState(false)

  const isFormEditable: any = useFormBuilder((state: any) => state.isEditable)

  const canEdit = isUndefined(props.isEditable) ? isFormEditable : props.isEditable

  const handleEnterKey = (event) => {
    if (event.keyCode === 13 && props.onSave) {
      setIsEditable(false)
      props.onSave(value)
    }
  }

  const inputClasses = clsx('!min-h-[auto]', props.inputClassName)

  React.useEffect(() => {
    if (props.value !== value) setValue(props.value)
  }, [props.value])

  if (isEditable && canEdit) {
    return (
      <BaseInput
        autoFocus
        className={inputClasses}
        value={value}
        onKeyDown={handleEnterKey}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        onBlur={(e) => {
          setIsEditable(false)
          props.onSave?.(e.target.value)
        }}
      />
    )
  }

  const classes = clsx(
    'flex items-center flex-nowrap cursor-text pl-2 pr-1.5 py-0.5 min-w-0',
    canEdit && 'rounded-[5px] hover:bg-hover',
    props.className,
  )

  return (
    <div
      className={classes}
      onClick={() => {
        if (!canEdit) return

        setIsEditable(true)
      }}
    >
      <span className={props.truncate ? 'truncate' : ''}>{props.value}</span>

      {canEdit && (
        <span className="inline-flex items-center ml-auto pl-1.5">
          <Glyph glyph="edit" size={10} color={COLORS.textStronglyMuted} />
        </span>
      )}
    </div>
  )
}
