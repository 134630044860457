import React from 'react'

import { useGet } from '@behavehealth/hooks/useNewAPI'

import Card from '@behavehealth/components/Card'
import Flex from '@behavehealth/components/Flex'
import Icon from '@behavehealth/components/Icon'
import PageSection from '@behavehealth/components/PageSection/PageSection'
import State from '@behavehealth/components/State'

import { TreatmentPlanTreeItem } from '@behavehealth/constructs/TreatmentPlans/components/TreatmentPlanTreeItem'

export const TreatmentPlanBookTree = ({ category }: any) => {
  const { data, isLoading } = useGet({
    name: ['treatment_book', { category }],
    url: `/treatment_book`,
    params: { category },
  })

  if (!data || isLoading)
    return (
      <Card>
        <State isLoading />
      </Card>
    )

  return (
    <PageSection>
      <PageSection.Header>
        <Flex centerY gap="0.5rem">
          <Icon icon="treatment_plans" size={22} />
          <PageSection.Title title="Treatment Book Tree View" />
        </Flex>
      </PageSection.Header>

      <PageSection.Content>
        <Card>
          {data?.map?.((goal: any, goalIndex: number) => {
            return (
              <TreatmentPlanTreeItem key={goal.id} capsLabel={`Goal #${goalIndex + 1}`} title={goal.name}>
                {goal.treatment_book_objectives?.map((objective: any, objectiveIndex: number) => {
                  return (
                    <TreatmentPlanTreeItem
                      key={objective.id}
                      capsLabel={`Obj. #${goalIndex + 1}.${objectiveIndex + 1}`}
                      title={objective.name}
                    >
                      {objective.treatment_book_interventions?.map((intervention: any, interventionIndex: number) => {
                        return (
                          <TreatmentPlanTreeItem
                            key={intervention.id}
                            capsLabel={`Int. #${goalIndex + 1}.${objectiveIndex + 1}.${interventionIndex + 1}`}
                            title={intervention.name}
                          ></TreatmentPlanTreeItem>
                        )
                      })}
                    </TreatmentPlanTreeItem>
                  )
                })}
              </TreatmentPlanTreeItem>
            )
          })}
        </Card>
      </PageSection.Content>
    </PageSection>
  )
}
