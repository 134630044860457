import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Flex from '@behavehealth/components/Flex'
import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const RootBillPayments = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting', 'bill_payments'],
    endpoint: `/accounting/bill_payments`,
    // params: {...},
    localStorageKey: 'accounting_bill_payments_v1',
  })

  const { mutateAsync: syncRailzToBh, isLoading: isSyncingRailz } = useCreate({
    name: ['sync-railz-to-bh'],
    url: '/accounting/bill_payments/sync_railz_to_bh',
    invalidate: tableProps.queryKey,
  })

  const { mutateAsync: syncQbToRailz, isLoading: isSyncingQb } = useCreate({
    name: ['sync-qb-to-railz'],
    url: '/accounting/bill_payments/sync_qb_to_railz',
  })

  const handleSyncRailzToBh = async () => {
    await syncRailzToBh()
    tableProps.refetch()
  }

  const handleSyncQbToRailz = () => {
    syncQbToRailz()
  }

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Vendor',
        model: 'vendor_ref_name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.vendor_ref_name} />,
      },
      {
        title: 'Memo',
        model: 'memo',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Total Amount',
        model: 'total_amount',
        width: 200,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Date',
        model: 'date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
      {
        title: 'Quickbooks Last Updated',
        model: 'source_modified_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Bill Payments">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Bill Payments"
          icon="accounting"
          emptyDescription="No bill payments have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          columns={columns}
          {...tableProps}
          headerAfter={
            isBehave && (
              <Flex gap="0.5rem">
                <AccountingSyncButton
                  forceShow={true}
                  category="bill_payments"
                  invalidate={tableProps.queryKey}
                  refetchUrl={'/accounting/sync?category=bill_payments'}
                />
              </Flex>
            )
          }
        />
      </Grid>
    </Page>
  )
}

export const BillPayments = withPageError(RootBillPayments)

const FILTERS_CONFIG = {
  vendor_ref_name: {
    label: 'Vendor',
    type: 'string',
  },
  memo: {
    label: 'Memo',
    type: 'string',
  },
  currency_ref_id: {
    label: 'Currency Ref Id',
    type: 'string',
  },
  total_amount: {
    label: 'Total Amount',
    type: 'string',
  },
  date: {
    label: 'Phone',
    type: 'date_time',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'date_time',
  },
}
