import React from 'react'
import startCase from 'lodash/startCase'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

import { FILTERS } from '../../../../constructs/Filters/config'

export const AllOrganizationContactsTable = ({ onSelect, dependentValue, includeColumns, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-all-organization-contacts'],
    endpoint: '/contacts',
    params: {
      reference_type: 'organization',
    },
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_all_organization_contacts_v1',
  })

  const defaultColumns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.name} avatar={data.avatar} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        width: 180,
        id: 'organization',
        model: 'reference',
        title: 'Organization',
        type: 'profile',
      },
      {
        width: 130,
        id: 'dob',
        model: 'dob',
        title: 'Date of Birth',
        type: 'date',
      },
      {
        width: 100,
        id: 'sex',
        model: 'sex',
        title: 'Sex',
        formatValue: ({ data, value }: any) => startCase(value),
      },
      {
        width: 130,
        id: 'role',
        model: 'role',
        title: 'Role',
        formatValue: ({ value }: any) => startCase(value),
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'phone_no',
        title: 'Phone',
      },
      {
        width: 240,
        id: 'email',
        model: 'email',
        title: 'Email',
      },
      {
        width: 160,
        id: 'notes',
        model: 'notes',
        title: 'Notes',
      },
    ],
    [],
  )

  const columns = React.useMemo(() => {
    if (!includeColumns) return defaultColumns

    return defaultColumns.filter((column) => {
      return includeColumns.includes(column.id) || includeColumns.includes(column.model)
    })
  }, [includeColumns])

  return (
    <DataTable
      {...tableProps}
      {...rest}
      icon="contacts"
      title="Organization Contacts"
      columns={columns}
      filtersConfig={FILTERS.organization_contacts}
    />
  )
}
