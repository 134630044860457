import React from 'react'
import size from 'lodash/size'

import { COLORS } from '../../theme'

import ProgressBar from '../ProgressBar'
import { StepperContext } from './context'

const StepperProgressBar: React.FC<any> = ({ className }) => {
  const { progress, steps, currentStep }: any = React.useContext(StepperContext)

  const stepsCount = size(steps)
  const currentStepCount = steps.indexOf(currentStep) + 1

  return (
    <div className={className}>
      <ProgressBar percent={progress} width={200} color="green" css={styles.progressBar} />
      <div css={styles.text}>{((currentStepCount / stepsCount) * 100).toFixed(0)}%</div>
    </div>
  )
}

const styles = {
  progressBar: {
    display: 'block',
  },

  text: {
    marginTop: 8,
    textAlign: 'center',
    fontSize: '0.9rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    color: COLORS.textMuted,
  },
}

export default StepperProgressBar
