import React from 'react'
import { useParams } from 'react-router-dom'

import { useGet } from '../../hooks/useNewAPI'

import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootFoRSEDemographicInformationOverlay = (props: any) => {
  const { resource_id }: any = useParams()

  const { data: client, isLoading: isLoadingClient }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  if (isLoadingClient || !client) {
    return <OverlayLoader position="center" />
  }

  return (
    <DataFormOverlay
      disableSignOff
      minimizeEnabled
      enableTreatmentPlanSelector
      title="Demographic Information"
      category="outcome_measure"
      subcategory="forse_demographic_information"
      icon="outcome_measures"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      requestSupervisorSignature={false}
      signoffWithoutSupervisor={true}
      {...props}
    >
      <Section
        title="Questions"
        headingType="h2"
        scrollview={{
          id: 'questions',
          name: 'Questions',
        }}
      >
        <FormSection>
          <Input
            label="Age at Admission"
            type="number"
            size={4}
            suffix="y/o"
            model="data.age_at_admission"
            validations={{
              numericality: {
                greaterThan: 0,
                lessThanOrEqualTo: 89,
                message: 'Please enter an age between 1 and 89 years old',
              },
            }}
          />

          <Select label="Gender" type="number" model="data.gender">
            <Option />
            <Option label="Female" value="female" />
            <Option label="Male" value="male" />
            <Option label="Trans Female" value="trans_female" />
            <Option label="Trans Male" value="trans_male" />
            <Option label="Non-binary" value="non_binary" />
            <Option label="Gender not listed" value="gender_not_listed" />
          </Select>

          <Select label="Sexual orientation" type="number" model="data.sexual_orientation">
            <Option />
            <Option label="Bisexual" value="bisexual" />
            <Option label="Lesbian or gay" value="lesbian_or_gay" />
            <Option label="Straight or heterosexual" value="straight_or_hetero" />
            <Option label="Sexual orientation not listed here" value="not_listed" />
            <Option label="Unknown" value="unknown" />
          </Select>

          <CheckboxGroup label="Race/Ethnicity (choose all that apply)" layout="vertical-dense">
            <Checkbox label="American Indian or Alaska Native" model="data.race_ethnicity.american_indian_or_alaska_native" />
            <Checkbox label="Asian" model="data.race_ethnicity.asian" />
            <Checkbox label="Black or African American" model="data.race_ethnicity.black_or_african_american" />
            <Checkbox label="Hispanic or Latinx" model="data.race_ethnicity.hispanic_or_latinx" />
            <Checkbox label="Middle Eastern or North African" model="data.race_ethnicity.middle_eastern_or_north_african" />
            <Checkbox
              label="Native Hawaiian or Other Pacific Islander"
              model="data.race_ethnicity.native_hawaiian_or_other_pacific_islander"
            />
            <Checkbox label="White" model="data.race_ethnicity.white" />
            <Checkbox label="Other" model="data.race_ethnicity.other" />
          </CheckboxGroup>
        </FormSection>
      </Section>
    </DataFormOverlay>
  )
}

export const FoRSEDemographicInformationOverlay = withOverlayError(RootFoRSEDemographicInformationOverlay)
