import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../../theme'

export const DisplayValue = ({ children, isEmpty, isEditing }: any) => {
  const rootClasses = clsx(isEmpty && 'is-empty', isEditing && 'is-editing')

  return (
    <div css={STYLES.root} className={rootClasses}>
      {children}
    </div>
  )
}

const STYLES = {
  root: {
    flex: '1 1 auto',

    '&.is-empty': {
      fontStyle: 'italic',
      fontSize: '0.84rem',
      color: COLORS.textStronglyMuted,
    },

    '&.is-editing': {
      // hide display value while editing
      visibility: 'hidden',
    },

    '.wrapping-clip &': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}
