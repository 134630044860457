import React from 'react'

import Radio from '../Radio'
import RadioGroup from '../RadioGroup'

const PrimaryTransportationModeRadioGroup = ({ label, model, isRequired }) => (
  <RadioGroup
    label={label}
    model={model}
    layout="vertical-dense"
    validations={
      isRequired && {
        presence: {
          message: 'Please select a primary mode of transportation',
        },
      }
    }
  >
    <Radio label="Personal Vehicle" value="personal_vehicle" />
    <Radio label="Family / Friend" value="family_or_friend" />
    <Radio label="Public Transit" value="public_transit" />
  </RadioGroup>
)

PrimaryTransportationModeRadioGroup.defaultProps = {
  model: 'primary_transportation_mode',
}

export default PrimaryTransportationModeRadioGroup
