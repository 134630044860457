import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { apiCreate } from '../../../modules/api'
import { ICONS } from '../../../theme'
import { withOverlayError } from '../../../hocs/withOverlayError'

import AddressSelectorInput from '../../Forms/AddressSelectorInput'
import Button from '../../Button'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Textarea from '../../Forms/Textarea'
import Overlay from '../../Overlay'
import Section from '../../Section'

const NewAddress = ({ history, location }: any) => {
  const [valid, setValid] = React.useState(false)
  const loading = useSelector((state: any) => state.data?.insurance_global_payer_addresses?.loading)
  const form = React.useRef()
  const match: any = useRouteMatch()
  const payer = useSelector((state) => state.data?.insurance_local_payers?.data?.[match?.params?.resource_id])

  const onSave = async () => {
    const model = form.current.getFormValue()
    await apiCreate({
      name: 'insurance_global_payer_addresses',
      url: `/insurance_global_payer_addresses`,
      params: {
        ...model,
        insurance_global_payer_id: payer?.insurance_global_payer?.id,
        lat: model.address?.coords?.lat,
        lon: model.address?.coords?.lon,
      },
    })

    history.goBack()
  }

  return (
    <Overlay>
      <Overlay.Header icon={ICONS.gps_check_in_out} title="Add New Address" />

      <Overlay.Content>
        <Form getForm={form} initialModel={location?.data} onValidationUpdate={setValid}>
          <Section>
            <FormSection>
              <Input
                label="Address Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter the address reference name',
                  },
                }}
              />

              <AddressSelectorInput actionLabel="Select Address" model="address" />

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" glyph="check" type="primary" color="green" isLoading={loading} onClick={onSave} isDisabled={!valid} />
      </Overlay.Footer>
    </Overlay>
  )
}

export default withOverlayError(NewAddress)
