import React from 'react'
import startCase from 'lodash/startCase'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'

import Status from '@behavehealth/components/Status'
import TableCell from '@behavehealth/components/Table/TableCell'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const ClientsContactsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave, timezone } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.data.id} value={data.data.name} avatar={data.data.avatar} to={to?.(data.data)} />
        ),
      },
      {
        width: 180,
        id: 'client',
        model: 'data.reference.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 130,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ data, value }: any) => {
          if (value == 'active') return <Status color="green" label="Active" />
          else if (value == 'inactive') return <Status color="red" label="Inactive" />
        },
      },
      {
        width: 130,
        id: 'dob',
        model: 'data.dob',
        title: 'Date of Birth',
        type: 'date',
      },
      {
        width: 100,
        id: 'sex',
        model: 'data.sex',
        title: 'Sex',
        formatValue: ({ data, value }: any) => startCase(value),
        // filterOptions: [
        //   {
        //     value: 'male',
        //     label: 'Male',
        //   },
        //   {
        //     value: 'female',
        //     label: 'Female',
        //   },
        //   {
        //     value: 'decline_to_respond',
        //     label: 'Decline To Respond',
        //   },
        //   {
        //     value: 'other',
        //     label: 'Other',
        //   },
        // ],
      },
      {
        width: 130,
        id: 'role',
        model: 'data.role',
        title: 'Role',
        formatValue: ({ value }: any) => startCase(value),
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'data.phone_no',
        title: 'Phone',
      },
      {
        width: 240,
        id: 'email',
        model: 'data.email',
        title: 'Email',
      },
      {
        width: 160,
        id: 'notes',
        model: 'data.notes',
        title: 'Notes',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Client Contacts"
      icon="contacts"
      columns={columns}
      filtersConfig={FILTERS.client_contacts}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="client_contacts"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=client_contacts'}
        />
      }
    />
  )
}
