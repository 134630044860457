import React from 'react'
import { keyframes } from '@emotion/react'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { SHADOW, COLORS } from '../../../../theme'

import State from '../../../State'
import Card, { CARD_CLASSES } from '../../../Card'
import Avatar from '../../../Avatar'
import CardHeader from '../../../CardHeader'
import CardSubtitle from '../../../CardSubtitle'
import CardTitle from '../../../CardTitle'

const Item = ({ avatar, onMouseDown, title, subtitle }: any) => {
  return (
    <Card tabIndex="0" onMouseDown={onMouseDown} variant="variant-list" css={itemStyles}>
      <CardHeader graphic={avatar && <Avatar magnify initials={title} src={avatar} magnifyPlacement="left" size="2rem" />}>
        {title && <CardTitle title={title} css={titleStyles} />}
        {subtitle && <CardSubtitle subtitle={subtitle} css={descriptionStyles} />}
      </CardHeader>
    </Card>
  )
}

const MultiSearchMenu = ({ emptyActions, items, icon, onSelect, selectDescription, selectTitle, type }) => {
  // * CUSTOM FUNCTIONS
  const stopPropagation = (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const hasResults = size(items) > 0

  return (
    <div tabIndex="0" css={rootStyles} onClick={stopPropagation}>
      <div css={contentStyles}>
        {hasResults ? (
          items.map(({ document, highlights }: any) => (
            <Item
              key={document.id}
              avatar={document?.avatar}
              title={selectTitle?.(document, highlights)}
              subtitle={selectDescription?.(document, highlights)}
              onMouseDown={() => onSelect(document)}
            />
          ))
        ) : (
          <State
            isEmpty
            icon={icon}
            title={type && startCase(type)}
            emptyActions={emptyActions}
            emptyDescription={type ? `No ${startCase(type)} found` : 'No data found'}
          />
        )}
      </div>
    </div>
  )
}

const mountAnimation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(-4px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const rootStyles = {
  background: 'white',
  WebkitOverflowScrolling: 'touch',

  borderRadius: 7,
  boxShadow: `${SHADOW(10, COLORS.divider)}, 0 0 0 0.5px ${COLORS.divider}`,

  top: '100%',
  width: '100%',
  height: 380,
  overflow: 'hidden',
  outline: 0,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  transformOrigin: 'top center',
  animation: `${mountAnimation} 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,

  marginTop: 5,
}

const titleStyles = {
  fontSize: '1rem',
}

const descriptionStyles = {
  fontSize: '0.9rem',
}

const contentStyles = {
  width: '100%',
  flex: '1 1 auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  position: 'relative',
  background: COLORS.white,
}

const itemStyles = {
  '&:hover': {
    cursor: 'pointer',
    background: COLORS.hover,

    [`.${CARD_CLASSES.TITLE}`]: {
      color: COLORS.blue,
    },
  },
}

export default MultiSearchMenu
