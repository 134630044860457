import React from 'react'

import Table from '../../Table/Table'
import TableCell from '../../Table/TableCell'

import withSettings from '../../../hocs/withSettings'

const columns = (to, mainLinkAs) => [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Name',
    width: 240,
    Cell: ({ value, row }) => {
      return <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} label={value} />
    },
  },
  {
    width: 240,
    accessor: 'data.appointments.frequency',
    Header: 'Frequency of Appointments',
  },
  {
    width: 240,
    accessor: 'data.appointments.estimated_length',
    Header: 'Estimated Length of Treatment',
  },
  {
    id: 'supervisor.name',
    accessor: 'supervisor.name',
    Header: 'Supervisor',
    width: 160,
  },
  {
    id: 'author.name',
    accessor: 'author.name',
    Header: 'Created by',
    width: 160,
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  timezone: string
  title: string
  to?: Function
}

const TreatmentPlanTemplatesTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    localStorageKey,
    onClick,
    timezone,
    title,
    to,
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={columns(to, props.mainLinkAs)}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

TreatmentPlanTemplatesTable.defaultProps = {
  title: 'Treatment Plan Templates',
  icon: 'treatment_plans',
  emptyDescription: 'No treatment plan templates to display',
  localStorageKey: 'treatment_plan_templates',
}

export default withSettings(TreatmentPlanTemplatesTable)
