import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  processing: {
    label: 'Processing',
    color: 'orange',
  },
  generated: {
    label: 'Generated',
    color: 'green',
  },
  issue: {
    label: 'Issue',
    color: 'red',
  },
}

const ReportStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default ReportStatus
