import { v4 as uuid } from 'uuid'
import produce from 'immer'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'
import sortBy from 'lodash/sortBy'
import compact from 'lodash/compact'
import { arrayToInternalMap, usDate } from '../../../utils/functions'
import { ARRAY_CONDITIONS, FORM_ELEMENTS_VALUE_TYPES, PRIMITIVE_VALUE_CONDITIONS, VARIABLE_SUPPORT_FIELDS } from './constants'

export const formatVariableKey = (str: string) => {
  if (!str) return ''

  const trimmed = str?.trim()

  return snakeCase(trimmed).toUpperCase().trim()
}

export const parseAnchorTimeVariable = {
  TV_ANCHOR_DATE: (anchorDT, timezone) => usDate(anchorDT, timezone),
  TV_1_DAY_FROM_ANCHOR_DATE: (anchorDT, timezone) => usDate(anchorDT.plus({ days: 1 }), timezone),
  TV_1_WEEK_FROM_ANCHOR_DATE: (anchorDT, timezone) => usDate(anchorDT.plus({ weeks: 1 }), timezone),
  TV_2_WEEKS_FROM_ANCHOR_DATE: (anchorDT, timezone) => usDate(anchorDT.plus({ weeks: 1 }), timezone),
  TV_1_MONTH_FROM_ANCHOR_DATE: (anchorDT, timezone) => usDate(anchorDT.plus({ months: 1 }), timezone),
}

export const createInitialPageWithContent = () => {
  const pageId = uuid()

  const elements: any = []

  // create heading element
  elements.push({
    uuid: uuid(),
    parent_uuid: null,
    category: 'heading',
    config: {
      content: '<h1>Example Form Page</h1>',
    },
    elements_order: [],
  })

  // create paragraph element
  elements.push({
    uuid: uuid(),
    parent_uuid: null,
    category: 'paragraph',
    config: {
      content:
        '<p>This is the first page of your form. Use the insert menu on the left to add more elements and select an element to configure it.</p>',
    },
    elements_order: [],
  })

  const page = {
    uuid: pageId,
    parent_uuid: null,
    name: 'Example Form Page',
    config: {
      content_width: 'large',
    },
    elements_order: elements.map((o) => o.uuid),
    _isOpen: true,
  }

  return { page, elements }
}

export const formatFormPages = ({ pages, order = [], blocksKey = 'form_blocks' }) => {
  const newPages = {}
  let newOrder: any = [...order]

  if (!pages) return { pages: newPages, order: newOrder }

  if (size(order) !== size(pages)) {
    newOrder = sortBy(pages, ['created_at', 'name']).map((o) => o.uuid)
  }

  for (let i = 0; i < pages.length; i++) {
    const page = pages[i]

    const elements = {}

    if (page[blocksKey]) {
      for (const element of page[blocksKey]) {
        elements[element.uuid] = {
          id: element.id,
          uuid: element.uuid,
          parent_uuid: element.parent_uuid,
          category: element.category,
          config: element.config,
          documents: element.documents,
          elements_order: element.elements_order,
        }
      }
    }

    newPages[page.uuid] = {
      id: page.id,
      uuid: page.uuid,
      parent_uuid: page.parent_uuid,
      name: page.name,
      config: page.config,
      elements: elements,
      elements_order: page.elements_order,
    }
  }

  return {
    pages: newPages,
    order: newOrder,
  }
}

export const getConditionValueLabel = (conditionalElement: any, formElement: any) => {
  if (size(conditionalElement?.config) === 0 || size(formElement?.config) === 0) return null

  const { condition, value, array_value, radio_checkbox_options } = conditionalElement.config
  const { single_select_input_values, multi_input_values } = formElement.config

  // handle checkbox conditional
  const isCheckbox = formElement?.category === 'checkbox_input'

  if (isCheckbox) {
    const result: any = []
    const checkboxesMap = arrayToInternalMap(multi_input_values)
    const conditionValues = radio_checkbox_options

    if (size(checkboxesMap) === 0 || size(conditionValues) === 0) return null

    for (const item of conditionValues) {
      const checkboxValue = checkboxesMap[item._id]

      if (item.is_active && checkboxValue) result.push(checkboxValue.label)
    }

    return compact(result).join(', ')
  }

  // handle radio conditional
  const isRadio = FORM_ELEMENTS_VALUE_TYPES[formElement.category] === 'radio'

  if (isRadio) {
    const result: any = []
    const radiosMap = arrayToInternalMap(single_select_input_values)
    const conditionValues = radio_checkbox_options

    if (size(radiosMap) === 0 || size(conditionValues) === 0) return null

    for (const item of conditionValues) {
      const radioValue = radiosMap[item._id]

      if (item.is_active && radioValue) result.push(radioValue.label)
    }

    return compact(result).join(', ')
  }

  // handle primitive value conditional
  const isPrimitive = PRIMITIVE_VALUE_CONDITIONS.includes(condition)

  if (isPrimitive) {
    return value
  }

  // handle text array conditional
  const isArray = ARRAY_CONDITIONS.includes(condition)

  if (isArray && size(array_value) > 0) {
    return array_value.map((o) => o.value).join(', ')
  }

  return null
}

export const toSnakeCase = (str: string) => {
  const formatted = str?.trim()?.toLowerCase?.() || ''

  return snakeCase(formatted)
}

export const updateElementsIds = ({ elements_order = [], elements = {} }) => {
  const ids: any = {}
  const newElements: any = {}
  const newElementsOrder: any = []

  for (let i = 0; i < elements_order.length; i++) {
    const id = elements_order[i]

    if (ids.hasOwnProperty(id)) {
      newElementsOrder[i] = ids[id]
    } else {
      // create new element ID
      const newID = uuid()

      ids[id] = newID
      newElementsOrder[i] = newID
    }
  }

  for (const oldID in elements) {
    const element = elements[oldID]
    let newID = ids[oldID]

    // create new ID if it doesn't exist
    if (!newID) {
      newID = uuid()
      ids[oldID] = newID
    }

    const newElement = produce(element, (draft) => {
      // remove server ID
      delete draft.id

      // set element's new UUID
      draft['uuid'] = newID

      // set parent UUID
      if (draft['parent_uuid']) {
        let newParentId = ids[draft.parent_uuid]

        if (!newParentId) {
          newParentId = uuid()
          ids[draft.parent_uuid] = newParentId
        }

        draft['parent_uuid'] = newParentId
      }

      // set element's children IDs
      if (draft['elements_order']) {
        for (let i = 0; i < draft['elements_order'].length; i++) {
          const oldChildID = draft['elements_order'][i]
          let newChildID = ids[oldChildID]

          if (!newChildID) {
            newChildID = uuid()
            ids[oldChildID] = newChildID
          }

          draft['elements_order'][i] = newChildID
        }
      }
    })

    // add new element
    newElements[newID] = newElement
  }

  return {
    elements_order: newElementsOrder,
    elements: newElements,
  }
}

export const getFormElementProps = (element: any, options = {}) => {
  const { useParsedConfig, environment }: any = options

  const result = {}

  if (!element || !element.config) return result

  const config: any = produce(element.config, (draft) => {
    if (!useParsedConfig) return

    for (const key in draft) {
      if (!VARIABLE_SUPPORT_FIELDS.includes(key)) continue

      draft[key] = draft.parsed?.[key] || draft[key]
    }

    draft['defaultValue'] = draft.parsed?.['default_value'] || draft['default_value']
  })

  return produce(result, (draft) => {
    if (config.is_required) {
      draft['validations'] = {
        presence: { message: config.invalid_message || 'This field is required' },
      }
    }

    if (config.tooltip) draft['tooltip'] = config.tooltip
    if (config.default_value) {
      draft['defaultValue'] = config.default_value

      if (environment === 'builder' || environment === 'preview') {
        draft['value'] = config.default_value
      }
    }

    draft['description'] = config.description
    draft['label'] = config.label
    draft['model'] = formatInputModel(element)
    draft['placeholder'] = config.placeholder
    draft['prefix'] = config.prefix
    draft['suffix'] = config.suffix
    draft['glyph'] = config.glyph
    draft['title'] = config.title
  })
}

export const formatInputModel = (element: any) => {
  if (!element?.uuid && !element?.config?.model) return null

  return `data.${element.config.model || element.uuid}`
}

export const moveArrayItem = (item: string, array: any = [], direction: 'up' | 'down') => {
  return produce(array, (draft: any) => {
    const index = draft.indexOf(item)

    // item not found
    if (index === -1) return

    // item is already at the top
    if (direction === 'up' && index === 0) return

    // item is already at the bottom
    if (direction === 'down' && index === draft.length - 1) return

    const temp = draft[index]
    const newIndex = direction === 'up' ? index - 1 : index + 1

    draft[index] = draft[newIndex]
    draft[newIndex] = temp
  })
}

export const getNonDeletedItems = (obj: any) => {
  const result = {}

  if (!obj) return result

  for (const _key in obj) {
    if (obj[_key]._destroy) continue

    result[_key] = obj[_key]
  }

  return result
}
