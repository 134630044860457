import React from 'react'

import { useSettings } from '../../hooks'
import { countWord, usDate } from '../../utils/functions'

import URBillingStatus from '../Statuses/URBillingStatus'
import ClaimStatus from '../Statuses/ClaimStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'
import LevelOfCareStatus from '../../components/Statuses/LevelOfCareStatus'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'from_date',
    Header: 'Service Dates',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink to={to(row.original.id)} label={`${usDate(value, timezone)} - ${usDate(row.original.to_date, timezone)}`} />
    ),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 140,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <ClaimStatus status={value} />,
  },
  {
    width: 140,
    accessor: 'authorized_level_of_care',
    Header: 'Authorized LoC',
    Cell: ({ value }: any) => {
      if (!value || value.length === 0) return <TableCell.Empty />
      return <LevelOfCareStatus status={value} />
    },
  },
  {
    width: 180,
    accessor: 'claim_status',
    Header: 'Claim Status',
  },
  {
    width: 180,
    accessor: 'insurance_policy.insurance_name',
    Header: 'Insurance Policy',
  },
  {
    width: 180,
    accessor: 'claim_id',
    Header: 'Claim #',
  },

  {
    width: 180,
    accessor: 'total_amount',
    Header: 'Claim Total',
  },
  {
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    accessor: 'updated_at',
    Header: 'Date Updated',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription?: string
  icon?: string
  isLoading?: boolean
  localStorageKey?: string
  title?: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const ClaimsTable = (props: Props) => {
  const { data, emptyActions, emptyDescription, icon, isLoading, localStorageKey, batchActionsConfig, title, to, titleAfter } = props

  const { timezone } = useSettings()

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

ClaimsTable.defaultProps = {
  title: 'claims',
  icon: 'invoices',
  emptyDescription: 'No claims added yet',
  localStorageKey: 'claims',
}

export default ClaimsTable
