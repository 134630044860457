import React from 'react'

import { usDate } from '../../../utils/functions'

import Glyph from '../../../components/Glyph'
import { DatePicker } from '../../../components/DatePicker'

import { useFormBuilder } from '../useFormBuilder'

export const AnchorDatePicker = ({ isEditable }: any) => {
  const timezone: any = useFormBuilder((state: any) => state.timezone)
  const variablesTimeAnchor: any = useFormBuilder((state: any) => state.variablesTimeAnchor)
  const setVariablesTimeAnchor: any = useFormBuilder((state: any) => state.setVariablesTimeAnchor)

  const [isPickerOpen, setIsPickerOpen] = React.useState(false)

  if (isEditable) {
    return (
      <DatePicker
        isOpen={isPickerOpen}
        onOpenUpdated={setIsPickerOpen}
        date={variablesTimeAnchor}
        maxYear={2030}
        minYear={1950}
        trigger={
          <div className="text-[0.9rem]">
            <span className="font-[600]">Anchor Date:</span>{' '}
            <span className="inline-flex flex-nowrap items-center text-blue-500">
              {usDate(variablesTimeAnchor, timezone)} <Glyph glyph="triangle_down" size={10} className="ml-0.5" />
            </span>
          </div>
        }
        onSelect={(dateObject: any) => {
          if (!dateObject) return

          setVariablesTimeAnchor(dateObject.setZone(timezone).toISO())
        }}
      />
    )
  }

  return (
    <div className="text-[0.9rem]">
      <span className="font-[600]">Anchor Date:</span>{' '}
      <span className="inline-flex flex-nowrap items-center text-text-muted">{usDate(variablesTimeAnchor, timezone)}</span>
    </div>
  )
}
