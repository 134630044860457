import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { setIdle, setIdleSaving } from '@behavehealth/actions/common'
import { logout } from '@behavehealth/modules/auth'
import { store } from '@behavehealth/setup/store'

const Logout = () => {
  const location = useLocation()
  const history = useHistory()

  React.useEffect(() => {
    const handleLogout = async () => {
      store.dispatch(setIdle(false))
      store.dispatch(setIdleSaving(false))

      const isLoggedOut = await logout()
      let logoutPath = '/login'
      if (location?.state?.from) logoutPath += `?goto=${location.state?.from}`
      if (isLoggedOut) history.push(logoutPath, location.state)
    }

    handleLogout()
  }, [])

  return (
    <div className="Logout">
      <span>Logging out...</span>
    </div>
  )
}

export default Logout
