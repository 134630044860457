import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Tenant } from '@behavehealth/declarations'
import { useAPI } from '@behavehealth/hooks'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, Page, HelpTagIframe, Grid } from '@behavehealth/components'
import CredentialsTable from '@behavehealth/components/Tables/CredentialsTable'

import { CompanyCredentialOverlayV6 } from '@behavehealth/components/Overlays/pages/CompanyCredentialOverlay'

const pageConfig = {
  feature: 'company_credentials',
  help: <HelpTagIframe id="settings_company_credentials" />,
  title: 'Credentials',
}

const CompanyCredentials = () => {
  return (
    <div>
      <CompanyCredentialsIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<CompanyCredentialOverlayV6 useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const CompanyCredentialsIndex: React.FC = () => {
  const data = useSelector((state: any) => state.data?.credentials?.data)
  const loading = useSelector((state: any) => state.data?.credentials?.loading)
  const record: Tenant = useSelector((state) => state.me.tenant)

  useAPI('credentials', `/facilities/${record.id}/credentials?category=custom`)

  const isEmpty = size(data) === 0

  const actions = <Button as={Link} label="Add Credential" type="primary" glyph="add" link="new" permission="settings.credentials.create" />

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Grid gap="1rem">
        <Card>
          <CredentialsTable mainLinkAs={NavLink} data={data} isLoading={isEmpty && loading} emptyActions={actions} to={(id) => id} />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CompanyCredentials, pageConfig))
