import React from 'react'
import { Link, NavLink, Route, useLocation } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useStore from '@behavehealth/modules/store'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, Page, HelpTagIframe } from '@behavehealth/components'
import { ExportCSVButton } from '@behavehealth/components/Buttons/ExportCSVButton'

import TodoTemplateOverlay from '@behavehealth/components/Templates/overlays/TodoTemplateOverlay'
import TodoTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/TodoTemplatesImportOverlay'
import TodoTemplatesTable from '@behavehealth/components/Templates/tables/TodoTemplatesTable'

const pageConfig = {
  feature: 'todo_templates',
  title: 'To-Do Templates',
  help: <HelpTagIframe id="settings_todo_templates" />,
}

const TodoTemplates = () => {
  return (
    <div>
      <TodoTemplatesIndex />

      <AnimatedRoutes>
        <Route path="import" element={<TodoTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<TodoTemplateOverlay useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const TodoTemplatesIndex = () => {
  const { pathname } = useLocation()

  const isBehave = useStore((state) => state?.isBehave)

  const { data, isLoading }: any = useGet({
    name: 'todo-templates',
    url: `/event_templates`,
    params: { event_type: 'task' },
  })

  return (
    <Page
      breakpoint={3}
      actions={
        <>
          {isBehave && (
            <Button
              as={Link}
              label="Import Behave Templates"
              type="default"
              glyph="add"
              link={`${pathname}/import`}
              permission="todo_templates.create"
            />
          )}

          <Button
            as={Link}
            label="Add To-Do Template"
            type="primary"
            glyph="add"
            link={`${pathname}/new`}
            permission="todo_templates.create"
          />
        </>
      }
      {...pageConfig}
    >
      <Card>
        <TodoTemplatesTable
          mainLinkAs={NavLink}
          title="To-Do Templates"
          titleAfter={<ExportCSVButton url={`/event_templates/csv`} params={{ event_type: 'task' }} />}
          data={data}
          isLoading={isLoading}
          to={(id: string) => id}
        />
      </Card>
    </Page>
  )
}

export default withPageError(withMarketing(TodoTemplates, pageConfig))
