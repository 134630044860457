import React from 'react'

import { formatURL } from '../../utils/functions'

import { DataTable } from '../../components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '../../constructs/Filters/constants'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

export const InternalCommunityAccessDataTable = ({ onClick, href }: any) => {
  const tableProps = useDataTable({
    name: ['internal-access'],
    endpoint: '/me/access/behave',
    params: { app: 'members' },
    localStorageKey: 'internal_access_community_apps_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            id={data.id}
            value={value}
            onClick={onClick ? () => onClick(data) : undefined}
            href={href ? href(data, 'members') : undefined}
          />
        ),
      },
      {
        title: 'URL',
        model: 'url',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal value={value} href={formatURL(value)} />
        },
      },
      {
        title: 'Primary State',
        model: 'primary_state',
      },
      {
        title: 'All Covered States',
        model: 'covered_states',
      },
      {
        title: 'Status',
        model: 'status',
        type: 'title',
      },
      {
        title: 'Title',
        model: 'title',
        type: 'title',
      },
      {
        title: 'Type',
        model: 'entity_type',
        type: 'title',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable
      asCard
      {...tableProps}
      data={tableProps?.data?.members?.data}
      meta={tableProps?.data?.members?.meta}
      title="Community Accounts"
      icon="community"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      searchBarFilterKey="name"
    />
  )
}

const FILTERS_CONFIG = {
  name: DEFAULT_FILTERS.name,
  url: {
    label: 'URL',
    type: 'string',
  },
  primary_state: {
    label: 'Primary State',
    type: 'string',
  },
  covered_states: {
    label: 'All Covered State',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'string',
  },
  title: {
    label: 'Title',
    type: 'string',
  },
  entity_type: {
    label: 'Type',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
