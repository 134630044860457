import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'

import { AGREEMENTS } from '../../../../utils/constants'
import { apiCreate, apiUpdate, apiDelete } from '../../../../modules/api'

import Button from '../../../Button'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Dialog from '../../../Dialog'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import Section from '../../../Section'

import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import ContextShow from '../../../Forms/ContextShow'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Input from '../../../Forms/Input'
import Option from '../../../Forms/Option'
import Select from '../../../Forms/Select'
import Textarea from '../../../Forms/Textarea'

import AgreementSections from '../../../Elements/AgreementSections'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../../../Overlays/pages/OverlayBase'

import withRouterV6 from '../../../../hocs/withRouterV6'

class ClientAgreementTemplateOverlay extends OverlayBase {
  save = async () => {
    const { $new } = this.state
    const { match } = this.props

    let model = this.form.current.getFormValue()

    if ($new)
      await apiCreate({
        name: 'internal_client_agreement_templates',
        url: `/internal_templates`,
        params: {
          ...model,
          category: 'agreement',
          subcategory: 'client',
        },
      })
    else
      await apiUpdate({
        name: 'internal_client_agreement_templates',
        url: `/internal_templates/${match?.params?.id}`,
        params: model,
      })

    this.setState({ $editable: false })
    this.close()
  }

  delete = async () => {
    const { match } = this.props
    await apiDelete({
      name: 'internal_client_agreement_templates',
      url: `/internal_templates/${this.state.id || match?.params?.id}`,
    })

    this.close()
  }

  render = () => {
    const { $new, $editable, isInvalid, params } = this.state
    const { dataID, initialData, loading, online, record, timezone } = this.props

    const data = { ...params, ...record }
    const hasInitialData = initialData && dataID

    if (!$new && !hasInitialData && size(data) === 0) {
      return <OverlayLoader position="right" showBackdrop={this.state.$editable} />
    }

    return (
      <Overlay
        position="right"
        isLoading={!data}
        showBackdrop={this.state.$editable}
        maxWidth={82}
        closeWrapper={(element) => (
          <Dialog
            glyph="delete"
            title="Close without saving?"
            message="All changes will be lost. This action cannot be undone."
            yesColor="red"
            yesLabel="Yes, Close Without Saving"
            onYes={this.close}
            skip={!$editable}
          >
            {element}
          </Dialog>
        )}
      >
        <Overlay.Header title={data?.data?.name || 'New Client Agreement Template'} icon="legal_agreement_alt" />

        <Overlay.Content>
          <Form
            useFullModel
            getForm={this.form}
            timezone={timezone}
            initialModel={data}
            isEditable={$editable}
            onValidationUpdate={this.onValidationUpdate}
            decorate={(model: any) => ({
              data: { ...model.data, template_type: 'custom', variant: 'client' },
            })}
          >
            <Section title="Agreement Details" headingType="h2">
              <FormSection maxWidth="100%">
                <Input
                  label="Agreement Name"
                  model="data.name"
                  validations={{
                    presence: {
                      message: 'Please enter a template name',
                    },
                  }}
                  className="!grow-[2]"
                />

                <Flex stretchChildrenX gap="1rem">
                  <Select model="status" label="Status" fullWidth defaultValue="active">
                    <Option label="Active" value="active" />
                    <Option label="Archived" value="archived" />
                  </Select>

                  <Select model="level" label="Level" fullWidth defaultValue="behave_template">
                    <Option label="Behave Template" value="behave_template" />
                    <Option label="Public Template" value="public_template" />
                  </Select>
                </Flex>

                <FormSection horizontal maxWidth="100%">
                  <Select
                    css={{ flexGrow: 1, flexBasis: 300 }}
                    label="Category"
                    model="data.category"
                    validations={{
                      presence: {
                        message: 'Please select a category',
                      },
                    }}
                  >
                    {Object.keys(AGREEMENTS).map((option) => (
                      <Option label={AGREEMENTS[option]} value={option} />
                    ))}
                  </Select>

                  <ContextShow when="data.category" is="other">
                    <div css={{ flexGrow: 1, flexBasis: 300 }}>
                      <Input label="Other Category Name" model="data.category_other" />
                    </div>
                  </ContextShow>
                </FormSection>

                <Select label="Review Cycle" model="data.review_cycle">
                  <Option label="Daily" value="daily" />
                  <Option label="Weekly" value="weekly" />
                  <Option label="Monthly" value="monthly" />
                  <Option label="Quarterly" value="quarterly" />
                  <Option label="Semi-annually" value="semi_annually" />
                  <Option label="Annually" value="annually" />
                </Select>

                <Textarea useQuickText label="Notes" model="data.notes" className="!grow !basis-[300px] !self-start" />
              </FormSection>
            </Section>

            <Divider />

            <AgreementSections
              model="data.sections"
              validations={{
                presence: {
                  message: 'Please add at least one section',
                },
              }}
            />

            <Section
              title="Staff"
              headingType="h2"
              aside={
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox
                    label="Require Staff signature"
                    model="data.request_cosigner_signature"
                    onUpdate={(state) => this.setState({ requestCoSignerSignature: state.value })}
                  />
                </CheckboxGroup>
              }
            />

            <Divider />

            <Section
              title="Signees"
              headingType="h2"
              aside={
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox
                    label="Require Signees signature"
                    model="data.should_sign"
                    onUpdate={(state) => this.setState({ shouldSign: state.value })}
                  />
                </CheckboxGroup>
              }
            />
          </Form>
        </Overlay.Content>

        <Overlay.Footer online={online}>
          {$editable && (
            <>
              <Button
                glyph="check"
                label="Save Agreement"
                type="primary"
                color="green"
                isLoading={loading}
                onClick={this.save}
                isDisabled={isInvalid}
                flex="100 1 240px"
              />

              {!$new && <Button glyph="cross" label="Cancel" type="default" isDisabled={loading} onClick={this.cancel} />}
            </>
          )}

          {!$editable && (
            <>
              <Button
                label="Edit"
                glyph="edit"
                type="default"
                isDisabled={loading}
                onClick={this.edit}
                flex="100 1 auto"
                permission="settings.agreement_templates.edit"
              />

              <DeleteDialog
                title="Delete Agreement?"
                message="Are you sure you want to delete this agreement? This action cannot be undone."
                onYes={this.delete}
              >
                <Button
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  isLoading={loading}
                  fullWidth
                  permission="settings.agreement_templates.edit"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'internal_client_agreement_templates')

export default connect(mapStateToProps, mapDispatchToProps)(ClientAgreementTemplateOverlay)

export const ClientAgreementInternalTemplateOverlayV6 = withRouterV6(
  connect(mapStateToProps, mapDispatchToProps)(ClientAgreementTemplateOverlay),
)
