import React from 'react'

import { DataTable } from '../../../DataTable/DataTable'
import { DEFAULT_FILTERS } from '../../../../constructs/Filters/constants'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

export const CommunityEmployeesTable = ({ onSelect, dependentValue, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-community-employees'],
    endpoint: `/community/employees`,
    params: {
      status: 'active',
    },
    headers: {
      'X-Community-Id': dependentValue,
    },
    localStorageKey: 'selector_community_employees_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.name} avatar={data.avatar} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'Title',
        model: 'title',
        type: 'title',
        width: 150,
      },
      {
        title: 'Phone Number',
        model: 'phone_no',
        type: 'phone',
      },
      {
        title: 'Email',
        model: 'email',
        type: 'email',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable {...tableProps} {...rest} title="Employees" columns={columns} filtersConfig={FILTERS_CONFIG} searchBarFilterKey="name" />
  )
}

const FILTERS_CONFIG = {
  name: DEFAULT_FILTERS.name,
  email: DEFAULT_FILTERS.email,
  phone_no: DEFAULT_FILTERS.phone_no,
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
