import React from 'react'

import Button from './Button'
import Grid from './Grid'
import TipAlert from './TipAlert'

const OnboardingFormAlert = () => {
  return (
    <TipAlert contrast glyph={null} type="default" localStorageKey="signup_questionnaire" className="!p-4">
      <Grid gap="0.5rem">
        <p className="!m-0">Please complete this setup questionnaire to finish setting up your account.</p>
        <div>
          {/* <Emoji emoji="🎉" size={40} /> */}
          <Button
            label="🎉 Complete Business Profile →"
            size={300}
            type="primary"
            display="inline-flex"
            link={`/settings/business-profile`}
          />
        </div>
      </Grid>
    </TipAlert>
  )
}

export default OnboardingFormAlert
