import React from 'react'

import Alert from '../../components/Alert'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Section from '../../components/Section'

import { DataFormOverlay } from '../DataFormOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import YesNoRadioGroupWithTextarea from '../../components/Forms/elements/YesNoRadioGroupWithTextarea'

const RootSCOFFOverlay = (props: any) => {
  return (
    <DataFormOverlay
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="SCOFF Questionnaire"
      category="clinical_measurement"
      subcategory="scoff"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="About"
        headingType="h2"
        scrollview={{
          id: 'about',
          name: 'About',
        }}
      >
        <div>
          The SCOFF Questionnaire is a five-question screening tool that has been validated in specialist and primary care settings. It has
          a sensitivity of 100% and specificity of 90% for anorexia nervosa. Though not diagnostic, a score of 2 or more positive answers
          should raise your index of suspicion of a case, highlighting need for more detailed history as delineated below. The questions can
          be delivered either verbally or in written form.
        </div>
      </Section>

      <Divider />

      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Questionnaire />
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <div>
          Luck, A.J., Morgan, J.F., Reid, F., O'Brien, A., Brunton, J., Price, C., Perry, L., Lacey, J.H. (2002), ‘The SCOFF questionnaire
          and clinical interview for eating disorders in general practice: comparative study’, British Medical Journal, 325,7367, 755 - 756.
        </div>
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1rem">
        {!isPortal && (
          <Alert glyph="info">
            An answer of 'yes' to two or more questions warrants further questioning and more comprehensive assessment.
          </Alert>
        )}

        <YesNoRadioGroupWithTextarea
          label="S – Do you make yourself Sick because you feel uncomfortably full?"
          model="data.questionnaire.sick"
          textareaLabel="More details (optional):"
        />

        <YesNoRadioGroupWithTextarea
          label="C – Do you worry you have lost Control over how much you eat?"
          model="data.questionnaire.control"
          textareaLabel="More details (optional):"
        />

        <YesNoRadioGroupWithTextarea
          label="O – Have you recently lost more than One stone (6.35 kg) in a three-month period?"
          model="data.questionnaire.one_stone"
          textareaLabel="More details (optional):"
        />

        <YesNoRadioGroupWithTextarea
          label="F – Do you believe yourself to be Fat when others say you are too thin?"
          model="data.questionnaire.fat"
          textareaLabel="More details (optional):"
        />

        <YesNoRadioGroupWithTextarea
          label="F – Would you say Food dominates your life?"
          model="data.questionnaire.food"
          textareaLabel="More details (optional):"
        />

        <Divider />

        {!isPortal && (
          <Alert glyph="info">
            A further two questions have been shown to indicate a high sensitivity and specificity for bulimia nervosa. These questions
            indicate a need for further questioning and discussion.
          </Alert>
        )}

        <YesNoRadioGroupWithTextarea
          label="1. Are you satisfied with your eating patterns?"
          model="data.questionnaire.is_satisfied_with_eating_patterns"
          textareaLabel="More details (optional):"
          showTextareaWhen={false}
        />

        <YesNoRadioGroupWithTextarea
          label="2. Do you ever eat in secret?"
          model="data.questionnaire.does_eat_in_secret"
          textareaLabel="More details (optional):"
        />
      </Grid>
    </>
  )
}

export const SCOFFOverlay = withOverlayError(RootSCOFFOverlay)
