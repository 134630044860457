import React from 'react'

import { useDataTable } from '../../../DataTable/useDataTable'

import { HubspotContactsDataTable } from '../../../../constructs/Hubspot/HubspotContactsDataTable'

export const HubspotContactsTable = ({ onSelect, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector_hubspot_contacts'],
    endpoint: '/apps/hubspot/contacts',
    localStorageKey: 'selector_hubspot_contacts_v2',
  })

  return <HubspotContactsDataTable {...tableProps} {...rest} onSelect={onSelect} />
}
