import React from 'react'

import Glyph from '../../Glyph'

const GlyphValue: React.FC = ({ glyph, value }) => (
  <span css={{ display: 'inline-flex', alignItems: 'center' }}>
    {glyph && <Glyph glyph={glyph} size="1rem" css={{ marginRight: '0.25rem' }} />}
    <span>{value}</span>
  </span>
)

export default GlyphValue
