import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../../../theme'

import CalendarEventsList from '../CalendarEventsList'

const AgendaView = ({ eventsByDate, renderEvent, days, listStyle }: any) => {
  const today = DateTime.local()

  return (
    <div>
      {days.map((day, index) => {
        const isToday = day.hasSame(today, 'day') && day.hasSame(today, 'month') && day.hasSame(today, 'year')

        return (
          <div key={index} css={styles.root}>
            <div css={styles.header}>
              <div css={styles.date} className={isToday ? 'is-today' : ''}>
                {day.toFormat('dd')}
              </div>
              <div css={styles.weekDay}>{day.weekdayShort}</div>
            </div>

            <CalendarEventsList
              events={eventsByDate[day.toFormat('yyyy-MM-dd')]}
              renderEvent={renderEvent}
              css={[styles.list, listStyle]}
            />
          </div>
        )
      })}
    </div>
  )
}

const styles = {
  root: {
    padding: '.5rem',
    boxShadow: `1px 1px 0 0 ${COLORS.divider}`,
    display: 'grid',
    gridTemplateColumns: '3rem 1fr',
    minWidth: 160,
  },

  header: {
    textAlign: 'center',
    paddingRight: '0.5rem',
  },

  date: {
    display: 'inline-block',
    fontSize: '1.1rem',
    fontWeight: 600,

    '@media (min-width: 1024px)': {
      fontSize: '0.9rem',
    },

    '&.is-today': {
      fontSize: '1rem',
      color: COLORS.white,
      position: 'relative',
      zIndex: 0,
      textAlign: 'center',
      marginBottom: '0.25rem',

      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
        background: COLORS.red,
        width: '1.75rem',
        height: '1.75rem',
        zIndex: -1,
        borderRadius: '50%',
      },
    },
  },

  weekDay: {
    fontSize: '0.88rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: 500,
    color: COLORS.textMuted,
  },

  list: {
    padding: 0,
  },
}

export default AgendaView
