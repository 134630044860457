import React from 'react'
import dompurify from 'dompurify'

import MultiSearchSelector from '../Selectors/MultiSearchObject/MultiSearchSelector'

const sanitize = dompurify.sanitize

const InsurancePayersSelector = ({
  icon = 'insurance',
  label = 'Insurance Payers',
  model = 'insurance_global_payers',
  placeholder,
  testKey,
}: any) => (
  <MultiSearchSelector
    showAvatars={false}
    icon={icon}
    testKey={testKey}
    label={label}
    model={model}
    type="insurance_clearing_house_configs"
    placeholder={placeholder}
    selectTitle={(data: any, highlights: any) => {
      let title = data?.name
      if (!highlights) return title

      for (let i = 0; i < highlights?.length; i++) {
        if (highlights[i].field === 'name') {
          title = highlights[i].snippet
          break
        }
      }

      return <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
    }}
    selectDescription={(data: any) => {
      const isAllStates = data.states.length === 59

      if (isAllStates) {
        return `Available in all States`
      } else {
        return `Available in ${data.states.join(', ')}`
      }
    }}
  />
)

export default InsurancePayersSelector
