import React from 'react'
import { useLocation } from 'react-router-dom'

import { H4 } from '../../components/Typography'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const RootACEScoreOverlay = (props: any) => {
  const { permissionName } = props

  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="ACE Score"
      category="clinical_measurement"
      subcategory="ace_score"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      permissionName={permissionName}
      {...props}
    >
      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState({
    verbal_domestic_violence: 0,
    physical_domestic_violence: 0,
    sexual_abuse: 0,
    emotional_neglect: 0,
    physical_neglect: 0,
    lost_biological_parent: 0,
    abused_mother: 0,
    lived_with_addicted_person: 0,
    lived_with_suicidal_person: 0,
    lived_with_prisoner: 0,
  })

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <H4>Prior to your 18th birthday:</H4>

        <RadioGroup
          label="1. Did a parent or other adult in the household often or very often… Swear at you, insult you, put you down, or humiliate you? or Act in a way that made you afraid that you might be physically hurt?"
          model="data.questionnaire.verbal_domestic_violence"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, verbal_domestic_violence: state.value }))}
        >
          <Radio label="No" value={0} />
          <Radio label="Yes (1)" value={1} />
        </RadioGroup>

        <RadioGroup
          label="2. Did a parent or other adult in the household often or very often… Push, grab, slap, or throw something at you? or Ever hit you so hard that you had marks or were injured?"
          model="data.questionnaire.physical_domestic_violence"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, physical_domestic_violence: state.value }))}
        >
          <Radio label="No" value={0} />
          <Radio label="Yes (1)" value={1} />
        </RadioGroup>

        <RadioGroup
          label="3. Did an adult or person at least 5 years older than you ever… Touch or fondle you or have you touch their body in a sexual way? or Attempt or actually have oral, anal, or vaginal intercourse with you?"
          model="data.questionnaire.sexual_abuse"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, sexual_abuse: state.value }))}
        >
          <Radio label="No" value={0} />
          <Radio label="Yes (1)" value={1} />
        </RadioGroup>

        <RadioGroup
          label="4. Did you often or very often feel that… No one in your family loved you or thought you were important or special? or Your family didn’t look out for each other, feel close to each other, or support each other?"
          model="data.questionnaire.emotional_neglect"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, emotional_neglect: state.value }))}
        >
          <Radio label="No" value={0} />
          <Radio label="Yes (1)" value={1} />
        </RadioGroup>

        <RadioGroup
          label="5. Did you often or very often feel that… You didn’t have enough to eat, had to wear dirty clothes, and had no one to protect you? or Your parents were too drunk or high to take care of you or take you to the doctor if you needed it?"
          model="data.questionnaire.physical_neglect"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, physical_neglect: state.value }))}
        >
          <Radio label="No" value={0} />
          <Radio label="Yes (1)" value={1} />
        </RadioGroup>

        <RadioGroup
          label="6. Was a biological parent ever lost to you through divorce, abandonment, or other reason?"
          model="data.questionnaire.lost_biological_parent"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, lost_biological_parent: state.value }))}
        >
          <Radio label="No" value={0} />
          <Radio label="Yes (1)" value={1} />
        </RadioGroup>

        <RadioGroup
          label="7. Was your mother or stepmother: Often or very often pushed, grabbed, slapped, or had something thrown at her? or Sometimes, often, or very often kicked, bitten, hit with a fist, or hit with something hard? or Ever repeatedly hit over at least a few minutes or threatened with a gun or knife?"
          model="data.questionnaire.abused_mother"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, abused_mother: state.value }))}
        >
          <Radio label="No" value={0} />
          <Radio label="Yes (1)" value={1} />
        </RadioGroup>

        <RadioGroup
          label="8. Did you live with anyone who was a problem drinker or alcoholic or who used street drugs?"
          model="data.questionnaire.lived_with_addicted_person"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, lived_with_addicted_person: state.value }))}
        >
          <Radio label="No" value={0} />
          <Radio label="Yes (1)" value={1} />
        </RadioGroup>

        <RadioGroup
          label="9. Was a household member depressed or mentally ill or did a household member attempt suicide?"
          model="data.questionnaire.lived_with_suicidal_person"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, lived_with_suicidal_person: state.value }))}
        >
          <Radio label="No" value={0} />
          <Radio label="Yes (1)" value={1} />
        </RadioGroup>

        <RadioGroup
          label="10. Did a household member go to prison?"
          model="data.questionnaire.lived_with_prisoner"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, lived_with_prisoner: state.value }))}
        >
          <Radio label="No" value={0} />
          <Radio label="Yes (1)" value={1} />
        </RadioGroup>
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Score title="Final ACE Score" finalScore={finalScore} />
        </>
      )}
    </>
  )
}

export const ACEScoreOverlay = withOverlayError(RootACEScoreOverlay)
