import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { Button, Card, Chotomate, HelpTagIframe, Link, Page, PageLayout, TipAlert } from '@behavehealth/components'
import { ClearSampleDataBanner } from '../../components/ClearSampleDataBanner'
import { ClientsTable } from '@behavehealth/components/Tables'
import { withPageError } from '@behavehealth/hocs/withPageError'

import useAPI from '@behavehealth/hooks/useAPI'

import { Tenant, Clients } from '@behavehealth/declarations'

const Billed: React.FC = () => {
  const match = useRouteMatch()
  const tenant: Tenant = useSelector((state) => state.me.tenant)
  const data: Clients = useSelector((state) => state.data.clients?.data)
  const loading = useSelector((state) => state.data.clients?.loading)

  useAPI('clients', '/residents?billed=true')

  const isSL = tenant.category === 'support_facility'
  const isEmpty = size(data) === 0

  const actions = isSL && <Button type="primary" glyph="add" label="Add Client" link={`${match.url}/new`} />

  return (
    <Page feature="billed_clients" breakpoint="0" actions={!isEmpty && actions} help={<HelpTagIframe id="clients" />}>
      <Chotomate name="billed_clients" ready={!loading} />
      {tenant?.has_seed_data && <ClearSampleDataBanner />}

      <PageLayout>
        <Card>
          <ClientsTable
            data={data}
            isLoading={isEmpty && loading}
            emptyActions={actions}
            to={(id) => `clients/${id}`}
            titleAfter={<Link to="/reports/clients">View Full Report →</Link>}
            localStorageKey="clients"
          />
        </Card>
      </PageLayout>
    </Page>
  )
}

export default withPageError(Billed)
