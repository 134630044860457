import React from 'react'
import startCase from 'lodash/startCase'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'
import { usTime, usDateTime, titleDate, fullname } from '../../utils/functions'

import Status from '../Status'
import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'
import CardSubtitle from '../CardSubtitle'

type Props = {
  location: any
  test: any
  timezone: any
  to: any
  user: any
}

const BreathalyzerTest = ({ test, timezone, to, user }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  const isBehave = user?.type === 'bh_employee'
  const isBilled = test.is_billed ? `Yes (${usDateTime(test.billed_at, timezone)})` : 'No'
  const isNegative = test.result === 'negative'

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle
        title={titleDate(test.created_at, timezone)}
        after={
          <>
            <Status
              color={isNegative ? 'green' : 'red'}
              label={`${startCase(test.result)}` + (!isNegative && test.value ? ` (${test.value})` : '')}
            />
          </>
        }
      />
      <CardMeta>
        <Text avatar={test?.author?.avatar} label={fullname(test?.author)} />
        <Text glyph="time" label="Time: " description={usTime(test.created_at, timezone)} />
        {isBehave && <Text icon="behave_health" label="Billed: " description={isBilled} />}
      </CardMeta>

      <CardSubtitle subtitle={test.notes} />
    </CardLink>
  )
}

const mapStateToProps = (state) => ({
  user: state.me.user,
})

export default withSettings(connect(mapStateToProps)(BreathalyzerTest))
