import React from 'react'

import withPermissions from '../../hocs/withPermissions'
import { StepperContext } from './context'

const StepperPage: React.FC<any> = ({ step, children }) => {
  const { currentStep }: any = React.useContext(StepperContext)

  if (currentStep !== step) return null

  return <div>{children}</div>
}

export default withPermissions(StepperPage)
