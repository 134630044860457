import React from 'react'
import { Link, Route, useNavigate } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import useSettings from '@behavehealth/hooks/useSettings'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import Button from '@behavehealth/components/Button'
import PageHeader from '@behavehealth/components/PageHeader'

import { CommunityBrowser } from '@behavehealth/constructs/Community/CommunityBrowser'
import { CommunityMapFeatureOverlay } from '@behavehealth/constructs/Community/CommunityMapFeatureOverlay'
import { CommunityProfileShare } from '@behavehealth/constructs/Community/CommunityProfileShare'
import { CommunityMemberSuggestionOverlay } from '@behavehealth/constructs/Community/CommunityMemberSuggestionOverlay'

export const CommunityExplore = () => {
  return (
    <>
      <CommunityExploreIndex />

      <AnimatedRoutes>
        <Route path=":slug" element={<CommunityMapFeatureOverlay useV6Router />} />
        <Route path="suggest" element={<CommunityMemberSuggestionOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const CommunityExploreIndex: React.FC = () => {
  const navigate = useNavigate()

  const { connectedEntities } = useSettings()
  const hasConnectedEntities = size(connectedEntities) > 0

  return (
    <>
      <div css={STYLES.root}>
        <PageHeader
          title="Explore Community"
          titleAside={
            hasConnectedEntities && (
              <div className="!ml-3">
                <CommunityProfileShare profiles={connectedEntities} label="Share Your Profile…" />
              </div>
            )
          }
          icon="community"
          aside={<Button as={Link} label="Suggest Community Member" icon="community" type="minimal" link="suggest" />}
        />

        <div css={STYLES.content}>
          <CommunityBrowser
            useV6Router
            onFeatureSelect={(feature: any) => {
              if (!feature) return

              navigate(
                `${feature.properties?.entity_slug || feature.properties?.entity_id}?lat=${feature.properties?.lat}&lon=${feature.properties
                  ?.lon}`,
              )
            }}
          />
        </div>
      </div>
    </>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    overflow: 'hidden',
  },

  content: {
    padding: '1rem',
    overflowY: 'auto',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
  },
}
