import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Notifications from '../../modules/notifications'

import { useSimpleOverlay } from '../../hooks/useSimpleOverlay'
import { useUpdate } from '../../hooks/useNewAPI'

import { RosterServiceStatusSelect } from './components/RosterServiceStatusSelect'

const RootRosterServiceStatusOverlay = (props: any) => {
  const { form, isInvalid, onValidationUpdate } = useSimpleOverlay({})

  const [isSaving, setIsSaving] = React.useState(false)
  const [error, setError] = React.useState(null)

  const { selectedRosterItems } = props

  const { timezone } = useSettings()

  const { mutateAsync: updateRosterItems }: any = useUpdate({
    name: ['insurance_roster_items'],
    url: `/insurance_roster_items/${selectedRosterItems?.map((o: any) => o.id).join(',')}`,
    invalidateKeys: ['insurance_roster_items'],
  })

  if (size(selectedRosterItems) === 0) {
    throw new Error('Select one or more roster items to update the Status')
  }

  const save = async () => {
    setIsSaving(true)
    const formData = form.current.getFormValue()

    try {
      await updateRosterItems(formData)

      Notifications.send('Successfully updated the Status for the selected Roster Items', 'positive')

      props.onClose()
    } catch (error) {
      console.error(error)
      setError(error?.error?.data?.errors?.[0])
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={props.onClose} position="center">
      <Overlay.Header icon="accounting" title="Batch Update Roster Item Status" />

      <Overlay.Content>
        <Form isEditable getForm={form} timezone={timezone} onValidationUpdate={onValidationUpdate}>
          <Section>
            <FormSection maxWidth="100%">
              <Alert contrast type="default" glyph="info">
                Update the Roster Item Status for the selected {countWord('roster items', size(selectedRosterItems))}.
              </Alert>

              <RosterServiceStatusSelect
                label="Update Roster Item Status To:"
                model="status"
                validations={{
                  presence: {
                    message: 'Please select a Status',
                  },
                }}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {error && (
          <Alert contrast type="negative" glyph="warning">
            {error}
          </Alert>
        )}

        <Button
          label={`Update ${size(selectedRosterItems)} Roster Item Statuses`}
          glyph="check"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={save}
          isDisabled={isInvalid}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const RosterServiceStatusOverlay = withOverlayError(RootRosterServiceStatusOverlay)
