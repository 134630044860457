import React from 'react'

import { COLORS } from '../../theme'

import Button from '../Button'
import CardLink from '../CardLink'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

const AttachmentCard = (props: any) => {
  const { description, title, isEditable, isDisabled, onRemove, isInline, href, target, onClick, isProvisional, url } = props

  return (
    <Tooltip
      show={isProvisional}
      className="!w-full"
      content={
        isEditable
          ? 'This file will be uploaded and available after saving the form'
          : 'Please refresh to view the recently-added attachment'
      }
    >
      <CardLink
        baseline="2.5rem"
        isLink={!isEditable}
        graphic={<Icon icon="files" size={18} />}
        variant={isEditable && 'variant-list'}
        href={isProvisional ? undefined : href}
        target={target}
        onClick={isProvisional ? undefined : onClick}
        className="!w-full"
        after={
          <div css={STYLES.after}>
            {!isEditable && !isInline && url && <Button hideLabel target="_blank" href={url} type="minimal" size={100} />}
            {isEditable && !isDisabled && <Button hideLabel glyph="cross" type="minimal" color="red" onClick={onRemove} size={200} />}
          </div>
        }
      >
        <CardTitle title={title} css={STYLES.title} className={isProvisional ? 'is-provisional' : ''} />
        {description && <CardSubtitle subtitle={description} />}
      </CardLink>
    </Tooltip>
  )
}

const STYLES = {
  title: {
    fontSize: '0.95rem',

    '&.is-provisional': {
      color: `${COLORS.textMuted} !important`,
    },
  },

  after: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

AttachmentCard.defaultProps = {
  isEditable: true,
  isDisabled: false,
}

export default AttachmentCard
