import React from 'react'
import { DateTime } from 'luxon'

import { usDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import VOBStatus from '../Statuses/VOBStatus'
import EVOBCoverageStatus from '../Statuses/EVOBCoverageStatus'

const columns = (to?: Function, timezone?: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'created_at',
    Header: 'Date Requested',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to?.(row.original.id)} label={usDate(value, timezone)} />,
  },
  {
    width: 160,
    accessor: 'status',
    Header: 'VOB Status',
    Cell: ({ value }: any) => <VOBStatus status={value} />,
  },
  {
    width: 160,
    accessor: 'insurance_policy.coverage_status',
    Header: 'Coverage Status',
    Cell: ({ value }: any) => <EVOBCoverageStatus status={value} />,
  },
  {
    width: 160,
    accessor: 'date_of_service',
    Header: 'Date Of Service',
    Cell: ({ value }: any) => <TableCell.UsDate value={value && DateTime.fromFormat(value, 'LL/dd/yyyy').toISO()} timezone={timezone} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription?: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  timezone?: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const PayersVOBSummariesTable = ({
  data,
  emptyActions,
  emptyDescription,
  icon,
  isLoading,
  localStorageKey,
  batchActionsConfig,
  timezone,
  title,
  to,
  titleAfter,
}: Props) => {
  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

PayersVOBSummariesTable.defaultProps = {
  emptyDescription: 'No VOB Summaries added yet',
  icon: 'financials',
  localStorageKey: 'insurance_evobs_summaries',
  title: 'VOB Summaries',
}

export default withSettings(PayersVOBSummariesTable)
