import React from 'react'
import { DateTime } from 'luxon'

import { COLORS, HARD_SHADOW } from '../../../theme'

import CalendarEventsList from '../CalendarEventsList'

const WeekView = ({ week, eventsByDate, renderEvent }: any) => {
  const today = DateTime.local()

  return (
    <div css={styles.root}>
      {week.map((day, i) => {
        const isToday = day.hasSame(today, 'day') && day.hasSame(today, 'month') && day.hasSame(today, 'year')

        return (
          <div key={i} css={styles.column}>
            <div css={styles.header}>
              <div css={styles.date} className={isToday ? 'is-today' : ''}>
                {day.toFormat('dd')}
              </div>
              <div css={styles.weekDay}>{day.weekdayShort}</div>
            </div>

            <CalendarEventsList events={eventsByDate[day.toFormat('yyyy-MM-dd')]} renderEvent={renderEvent} css={styles.list} />
          </div>
        )
      })}
    </div>
  )
}

const styles = {
  root: {
    '@media (min-width: 1024px)': {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      overflowY: 'visible',
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
    },
  },

  column: {
    display: 'grid',
    gridTemplateColumns: '3rem 1fr',
    padding: '0.5rem',
    minWidth: 180,
    boxShadow: `0 1px 0 ${COLORS.divider}`,

    '@media (min-width: 1024px)': {
      display: 'block',
      padding: 0,
      boxShadow: `1px 0 0 ${COLORS.divider}`,
      minHeight: '80vh',
    },
  },

  header: {
    fontSize: '0.9rem',
    fontWeight: 600,
    paddingRight: '0.5rem',
    textAlign: 'center',

    '@media (min-width: 1024px)': {
      padding: '0.5rem',
      boxShadow: `1px 0 0 ${COLORS.divider}, ${HARD_SHADOW(2)}`,
      borderBottom: `1px solid ${COLORS.divider}`,
    },
  },

  date: {
    display: 'inline-block',
    fontSize: '1.1rem',
    fontWeight: 600,

    '@media (min-width: 1024px)': {
      fontSize: '0.9rem',
      marginRight: '0.4rem',
    },

    '&.is-today': {
      fontSize: '1rem',
      color: COLORS.white,
      position: 'relative',
      zIndex: 0,
      textAlign: 'center',
      marginBottom: '0.25rem',

      '@media (min-width: 1024px)': {
        fontSize: '0.9rem',
        marginRight: '0.6rem',
        marginBottom: 0,
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
        background: COLORS.red,
        width: '1.75rem',
        height: '1.75rem',
        zIndex: -1,
        borderRadius: '50%',
      },
    },
  },

  weekDay: {
    fontSize: '0.88rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: 500,
    color: COLORS.textMuted,

    '@media (min-width: 1024px)': {
      display: 'inline-block',
    },
  },

  list: {
    '@media (min-width: 1024px)': {
      padding: '0.5rem',
    },
  },
}

export default WeekView
