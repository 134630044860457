import React from 'react'
import { darken, transparentize } from 'polished'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { COLORS, MEDIA_QUERY, SHADOW } from '@behavehealth/theme'
import { useAPIwithNoAbort } from '@behavehealth/hooks/useAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Nav, NavGroup, NavItem, Option, Page, Select, HelpTagIframe } from '@behavehealth/components'

import MessageBlasts from './message_blasts'
import Channel from './channel'

const pageConfig = {
  feature: 'staff_chat',
  help: <HelpTagIframe id="staff_chat" />,
  marketingID: 'company_chat',
}

const CompanyChat: React.FC = () => {
  const [selection, setSelection] = React.useState('all')
  const { url } = useRouteMatch()

  const channels = useSelector((state: any) => state.data?.channels?.data)
  const isLoading = useSelector((state: any) => state.data?.channels?.loading)

  useAPIwithNoAbort('channels', `/chat/channels/company`)

  if (isLoading) {
    return <Page title="Staff Chat" isLoading={isLoading} emptyDescription="There are no staff chats to display" {...pageConfig} />
  }

  return (
    <div css={styles.root}>
      <Nav
        breakpoint={4}
        headingSize={300}
        top="0"
        icon="company_chat"
        title="Staff Chat"
        help={<HelpTagIframe id="company_chat" />}
        menuLabel="All Channels"
        menuGlyph="chat"
      >
        <NavItem
          icon="company_chat"
          label="Message Blasts"
          description="Send multiple messages"
          to={`${url}/message-blasts`}
          css={styles.messageBlasts}
          labelStyles={styles.navItemLabel}
          permission="company_chat_message_blasts.view"
          featureFlagV2="staff_message_blasts"
        />

        <div className="!my-3 !mx-0">
          <Select value={selection} onUpdate={(state: string) => setSelection(state.value)}>
            <Option label="All Channels" value="all" />
            <Option label="Staff" value="employees" />
            <Option label="Locations" value="properties" />
            <Option label="Programs" value="programs" />
          </Select>
        </div>

        {selection === 'all' && (
          <>
            <NavGroup label="Staff">
              {channels?.employees?.map((channel: any) => (
                <NavItem
                  showAvatarInitials
                  key={channel.id}
                  label={channel.name}
                  description={`${size(channel.members)} Members`}
                  to={`${url}/employees/${channel.id}`}
                  labelStyles={styles.navItemLabel}
                />
              ))}
            </NavGroup>

            <NavGroup label="Locations">
              {channels?.properties?.map((channel: any) => {
                const channelSize = size(channel.members)
                // if (channelSize === 0) return null

                return (
                  <NavItem
                    showAvatarInitials
                    key={channel.id}
                    avatar={channel.avatar}
                    label={channel.name}
                    description={`${channelSize} Members`}
                    to={`${url}/properties/${channel.id}`}
                    labelStyles={styles.navItemLabel}
                  />
                )
              })}
            </NavGroup>

            <NavGroup label="Programs">
              {channels?.programs?.map((channel: any) => {
                const channelSize = size(channel.members)
                // if (channelSize === 0) return null

                return (
                  <NavItem
                    showAvatarInitials
                    key={channel.id}
                    avatar={channel.avatar}
                    label={channel.name}
                    description={`${channelSize} Members`}
                    to={`${url}/programs/${channel.id}`}
                    labelStyles={styles.navItemLabel}
                  />
                )
              })}
            </NavGroup>

            <NavGroup label="Departments">
              {channels?.departments?.map((channel: any) => {
                const channelSize = size(channel.members)
                // if (channelSize === 0) return null

                return (
                  <NavItem
                    showAvatarInitials
                    key={channel.id}
                    label={channel.name}
                    description={`${channelSize} Members`}
                    to={`${url}/departments/${channel.id}`}
                    labelStyles={styles.navItemLabel}
                  />
                )
              })}
            </NavGroup>

            <NavGroup label="Roles">
              {channels?.roles?.map((channel: any) => {
                const channelSize = size(channel.members)
                // if (channelSize === 0) return null

                return (
                  <NavItem
                    showAvatarInitials
                    key={channel.id}
                    label={channel.name}
                    description={`${channelSize} Members`}
                    to={`${url}/roles/${channel.id}`}
                    labelStyles={styles.navItemLabel}
                  />
                )
              })}
            </NavGroup>
          </>
        )}

        {selection === 'employees' && (
          <NavGroup label="Staff">
            {channels?.employees?.map((channel: any) => (
              <NavItem
                showAvatarInitials
                key={channel.id}
                label={channel.name}
                description={`${size(channel.members)} Members`}
                to={`${url}/employees/${channel.id}`}
                labelStyles={styles.navItemLabel}
              />
            ))}
          </NavGroup>
        )}

        {selection === 'properties' && (
          <NavGroup label="Locations">
            {channels?.properties?.map((channel: any) => {
              const channelSize = size(channel.members)
              // if (channelSize === 0) return null

              return (
                <NavItem
                  showAvatarInitials
                  key={channel.id}
                  avatar={channel.avatar}
                  label={channel.name}
                  description={`${channelSize} Members`}
                  to={`${url}/properties/${channel.id}`}
                  labelStyles={styles.navItemLabel}
                />
              )
            })}
          </NavGroup>
        )}

        {selection === 'programs' && (
          <NavGroup label="Programs">
            {channels?.programs?.map((channel: any) => {
              const channelSize = size(channel.members)
              // if (channelSize === 0) return null

              return (
                <NavItem
                  showAvatarInitials
                  key={channel.id}
                  avatar={channel.avatar}
                  label={channel.name}
                  description={`${channelSize} Members`}
                  to={`${url}/programs/${channel.id}`}
                  labelStyles={styles.navItemLabel}
                />
              )
            })}
          </NavGroup>
        )}
      </Nav>

      <main css={styles.main}>
        <Switch>
          <Route path={`${url}/message-blasts`} component={MessageBlasts} />
          <Route path={`${url}/:category/:id`} component={Channel} />

          {channels && <Redirect exact from={url} to={`${url}/employees/all-employees`} />}
        </Switch>
      </main>
    </div>
  )
}

const styles = {
  root: {
    display: 'grid',
    width: '100%',
    height: '100%',

    overflow: 'hidden',
    gridTemplateRows: 'min-content 1fr',
    gridTemplateColumns: '1fr',

    position: 'relative',
    zIndex: 0,
    paddingBottom: '100px',

    [`${MEDIA_QUERY[1]}`]: {
      paddingBottom: 0,
    },

    [`${MEDIA_QUERY[4]}`]: {
      gridTemplateRows: '100%',
      gridTemplateColumns: '320px 1fr',
    },
  },

  main: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    overflow: 'hidden',
  },

  messageBlasts: {
    background: COLORS.white,
    boxShadow: `
			inset 0 0 0 1px ${transparentize(0.85, COLORS.gray)},
			${SHADOW(1, transparentize(0.95, COLORS.blue))}
    `,
    margin: 0,
  },

  navItemLabel: {
    color: darken(0.03, COLORS.link),
    '.is-active &': { color: COLORS.black },
  },
}

export default withPageError(withMarketing(CompanyChat, pageConfig))
