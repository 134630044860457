import { produce } from 'immer'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import React from 'react'
import size from 'lodash/size'

import { isDefined, niceAmount } from '../../../utils/functions'
import { useGet } from '../../../hooks/useNewAPI'

import AmountInput from '../../../components/Forms/AmountInput'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Divider from '../../../components/Divider'
import Dropdown from '../../../components/Dropdown'
import DropdownItem from '../../../components/DropdownItem'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import Icon from '../../../components/Icon'
import Input from '../../../components/Forms/Input'
import NumberInput from '../../../components/Forms/NumberInput'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../../components/Forms/Option'
import PageSection from '../../../components/PageSection/PageSection'
import Select from '../../../components/Forms/Select'

import { DragAndDrop } from '../../../components/DragAndDrop'

import { InvoiceLinesAmounts } from './InvoiceLinesAmounts'

export const ClientInvoiceLines = (props: any) => {
  const { data, isEditable, invoiceLines, setInvoiceLines, deletedInvoiceLines, setDeletedInvoiceLines } = props

  const servicesQuery = useGet({
    name: ['client_services'],
    url: '/services',
  })

  return (
    <PageSection>
      <PageSection.Header>
        <PageSection.Title title="Invoice Lines" className="!text-[1.65rem]" />
      </PageSection.Header>

      <PageSection.Content>
        <Card>
          <div className="grid px-3 py-2 !overflow-x-auto">
            <div className="grid grid-cols-[60px_320px_minmax(280px,1fr)_100px_150px_100px_50px] py-2 border-b !last:border-none border-solid border-0 border-divider text-[0.84rem] tracking-[1px] uppercase text-text-muted font-[700] opacity-[0.85]">
              <div>#</div>
              <div>Service Name</div>
              <div>Service Description</div>
              <div>Quantity</div>
              <div>Amount</div>
              <div>Line Total</div>
              <div></div>
            </div>

            <DragAndDrop
              items={invoiceLines}
              onUpdate={setInvoiceLines}
              isDisabled={!isEditable}
              getId={(item) => item._id || item.id}
              renderOverlay={({ item, index }) => {
                const name = item?.custom_service?.name

                return (
                  <Card className="w-fit px-3 py-2 flex items-center flex-nowrap !select-none">
                    <Glyph glyph="drag_and_drop" size={14} />
                    <div className="ml-2 mr-4 font-[500] text-text-muted">{index + 1}.</div>
                    <Icon icon="financials" size={20} className="mr-2" />
                    <div className="font-[600]">{name}</div>
                  </Card>
                )
              }}
              renderItem={({ renderDragElement, item: invoiceLine, index }: any) => {
                const amount = parseFloat(invoiceLine.amount)
                const quantity = parseInt(invoiceLine.quantity)
                const lineAmount = amount * quantity

                return (
                  <div
                    key={invoiceLine.id || invoiceLine._id}
                    className="grid grid-cols-[60px_320px_minmax(280px,1fr)_100px_150px_100px_50px] py-1.5 border-b !last:border-none border-solid border-0 border-divider"
                  >
                    <div className="grid grid-cols-1 items-start">
                      {renderDragElement({
                        children: <div className="ml-2 font-[500] text-text-muted">{index + 1}.</div>,
                        className: clsx('!justify-start !py-2', isEditable && '!pl-2'),
                      })}
                    </div>

                    <div className="font-[600] mr-4">
                      {invoiceLine.use_custom_service ? (
                        <ObjectSelector
                          value={invoiceLine.custom_service}
                          icon="financials"
                          type="client_services"
                          selectTitle={(service: any) => service.name}
                          selectDescription={(service: any) => service.description}
                          selectGraphic={(service: any) => <Icon icon="financials" />}
                        />
                      ) : (
                        <Input defaultValue={invoiceLine.name} />
                      )}
                    </div>

                    <div className="font-[400] mr-4">
                      {invoiceLine.use_custom_service ? (
                        <>{invoiceLine.custom_service.description || <span className="text-text-muted">–</span>}</>
                      ) : (
                        <Input defaultValue={`Service description ${index + 1}`} />
                      )}
                    </div>

                    <div className="font-[400] mr-4">
                      <NumberInput defaultValue={invoiceLine.quantity} />
                    </div>

                    <div className="font-[400] mr-4">
                      {invoiceLine.use_custom_service ? (
                        <>{niceAmount(invoiceLine.custom_service.amount)}</>
                      ) : (
                        <AmountInput defaultValue={invoiceLine.amount} />
                      )}
                    </div>

                    <div>{isFinite(lineAmount) ? niceAmount(lineAmount) : '–'}</div>

                    <div>
                      {isEditable && (
                        <Button
                          hideLabel
                          glyph="delete"
                          color="red"
                          type="minimal"
                          size={100}
                          className="!w-7 !h-7"
                          onClick={() => {
                            // if server item, mark for deletion and remove from state
                            if (isDefined(invoiceLine.id)) {
                              setDeletedInvoiceLines((prev: any) => [...prev, { id: invoiceLine.id, _destroy: 1 }])

                              setInvoiceLines((prev: any) => {
                                return produce(prev, (draft: any) => {
                                  const foundIndex = draft.findIndex((o: any) => o.id === invoiceLine.id)

                                  if (foundIndex !== -1) {
                                    draft.splice(foundIndex, 1)
                                  }
                                })
                              })

                              return
                            }

                            // if local item, remove from state
                            setInvoiceLines((prev: any) => {
                              return produce(prev, (draft: any) => {
                                const foundIndex = draft.findIndex((o: any) => o._id === invoiceLine._id)

                                if (foundIndex !== -1) {
                                  draft.splice(foundIndex, 1)
                                }
                              })
                            })
                          }}
                        />
                      )}
                    </div>
                  </div>
                )
              }}
            />
          </div>

          {isEditable && (
            <div className="px-3 py-1.5 border-b !last:border-none border-solid border-0 border-divider flex">
              <Dropdown label="Add Service" buttonSize={200} buttonType="primary" glyph="add" display="inline-flex">
                {servicesQuery.data?.map((service: any) => (
                  <DropdownItem
                    label={service.name}
                    icon={service.icon || 'financials'}
                    onClick={() => {
                      setInvoiceLines((prev: any) => [
                        ...prev,
                        {
                          _id: uuid(),
                          quantity: 1,
                          amount: parseFloat(service.amount) || 0,
                          use_custom_service: true,
                          custom_service: service,
                        },
                      ])
                    }}
                  />
                ))}

                {size(servicesQuery.data) > 0 && <Divider />}

                <DropdownItem label="Custom Invoice Line" glyph="add" />
              </Dropdown>
            </div>
          )}

          {!isEditable && (
            <div className="flex justify-end mt-2 pb-5">
              <InvoiceLinesAmounts amounts={data?.calculated_amounts || {}} />
            </div>
          )}

          <div className={clsx('justify-end px-3 pb-4 pt-2 font-[600]', isEditable ? 'flex' : 'hidden')}>
            <div className="flex items-center justify-end pr-4">Sales Tax:</div>
            <Flex nowrap gap="0.5rem">
              <NumberInput defaultValue={10} size={4} />
              <Select defaultValue="percentage" model="sales_tax_type">
                <Option label="%" value="percentage" />
                <Option label="USD" value="amount" />
              </Select>
            </Flex>
          </div>
        </Card>
      </PageSection.Content>
    </PageSection>
  )
}
