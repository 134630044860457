import React from 'react'
import clsx from 'clsx'

import withTabsContext from '../hocs/withTabsContext'

const TabPanel = withTabsContext(({ currentTab, name, children, type, className }) => {
  const classNames = (type) =>
    clsx({
      [className]: className,
      'is-active': type === 'mount' || currentTab === name,
    })

  if (type === 'style') {
    return (
      <div css={[styles]} className={classNames(type)}>
        {children}
      </div>
    )
  }

  if (type === 'mount') {
    if (currentTab !== name) return null

    return (
      <div css={[styles]} className={classNames(type)}>
        {children}
      </div>
    )
  }

  return null
})

const styles = {
  display: 'none',

  '&.is-active': {
    display: 'block',
  },
}

TabPanel.defaultProps = {
  type: 'style',
}

export default TabPanel
