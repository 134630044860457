import React from 'react'

import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import Textarea from '../../../components/Forms/Textarea'

export const TreatmentPlanEvidenceBasedInput = (props) => {
  return (
    <>
      <CheckboxGroup
        label="Evidence-based treatment modalities"
        layout="vertical-dense"
        trueIcon="check"
        falseStyle="hidden"
        falseIcon="cross"
        {...props}
      >
        <Checkbox label="CBT - Cognitive Behavioral Therapy" model="evidence_based_treatment.cbt" />
        <Checkbox label="DBT - Dialectical Behavior Therapy" model="evidence_based_treatment.dbt" />
        <Checkbox label="CPT - Cognitive Processing Therapy" model="evidence_based_treatment.cpt" />
        <Checkbox label="IPT - Interpersonal Therapy" model="evidence_based_treatment.ipt" />
        <Checkbox label="EMDR - Eye Movement Desensitization and Reprocessing Therapy" model="evidence_based_treatment.emdr" />
        <Checkbox label="PE - Exposure Therapy" model="evidence_based_treatment.pe" />
        <Checkbox label="FT - Family Therapy" model="evidence_based_treatment.ft" />
        <Checkbox label="MI - Motivational Interview" model="evidence_based_treatment.mi" />
        <Checkbox label="SFBT - Solution-Focused Brief Therapy" model="evidence_based_treatment.sfbt" />
        <Checkbox label="Medication Managment" model="evidence_based_treatment.medication_managment" />
        <Checkbox label="MBCT - Mindfulness-Based Cognitive Therapy" model="evidence_based_treatment.mbct" />
        <Checkbox label="Group Interventions" model="evidence_based_treatment.group_interventions" />
        <Checkbox label="Holistic Approach" model="evidence_based_treatment.holistic_approach" />
        <Checkbox label="Parent Training" model="evidence_based_treatment.parent_training" />
        <Checkbox label="Play Therapy" model="evidence_based_treatment.play_therapy" />
        <Checkbox label="Psychotherapy" model="evidence_based_treatment.psychotherapy" />
        <Checkbox label="Psychoeducation" model="evidence_based_treatment.psychoeducation" />
        <Checkbox label="School-Based Interventions" model="evidence_based_treatment.school_based_interventions" />
        <Checkbox label="Social-Emotional Therapy" model="evidence_based_treatment.social_emotional_therapy" />
        <Checkbox label="Residential Specific Treatments" model="evidence_based_treatment.residential_specific_treatments" />
        <Checkbox label="Psychopharmacology Information" model="evidence_based_treatment.psychopharmacology_information" />
        <Checkbox label="Behavior Therapy" model="evidence_based_treatment.behavior_therapy" />
        <Checkbox label="Animal-Assisted Therapy" model="evidence_based_treatment.animal_assisted_therapy" />
        <Checkbox label="Emotion Focused Therapy" model="evidence_based_treatment.emotion_focused_therapy" />
        <Checkbox label="Creative Arts Therapy" model="evidence_based_treatment.creative_arts_therapy" />
        <Checkbox label="Other" model="evidence_based_treatment.other" />
      </CheckboxGroup>

      <ContextShow when="evidence_based_treatment.other" is={true}>
        <Textarea label="Other" model="evidence_based_treatment_other" />
      </ContextShow>
    </>
  )
}
