import React from 'react'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Input from '../../../components/Forms/Input'
import Markup from '../../../components/Markup'
import Overlay from '../../../components/Overlay'
import SignaturePad from '../../../components/Forms/SignaturePad'
import Status from '../../../components/Status'

export const SigningOverlay = ({ onClose, data, onSign, didSign, isLoading }: any) => {
  const form = React.useRef(null)

  const [isValid, setIsValid] = React.useState(false)
  const [hasSignature, setHasSignature] = React.useState(false)

  if (!data) return null

  const handleAgree = async () => {
    try {
      await onSign?.(form.current?.getFormValue())
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} maxWidth={60} position="center">
      <Overlay.Header title="Signing" icon="signature" />

      <Overlay.Content className="p-5">
        <Form
          isCompact
          initialModel={{
            signing_name: data.signing_name,
            signing_date: data.signing_date,
            quote_signature: data.quote_signature,
          }}
          isEditable={!didSign}
          getForm={form}
          onValidationUpdate={setIsValid}
        >
          {data.signature_legal_language_text && (
            <Markup value={data.parsed_signature_legal_language_text || data.signature_legal_language_text} />
          )}

          <Card className="p-4 mt-5">
            <FormSection maxWidth="100%">
              {!didSign && <Status label="Action Required" glyph="warning" color="orange" />}

              <Input
                label="Your Full Name"
                model="signing_name"
                validations={{
                  presence: {
                    message: 'Please enter your full name',
                  },
                }}
              />

              <DateTimeInput
                defaultToNow
                label="Signing Date and Time"
                model="signing_date"
                validations={{
                  presence: {
                    message: 'Please enter the signing date and time',
                  },
                }}
              />

              <SignaturePad
                label="Sign Below"
                model="quote_signature"
                validations={{
                  presence: {
                    message: 'Please add your signature',
                  },
                }}
                onUpdate={({ value }: any) => {
                  setHasSignature(!!value)
                }}
              />
            </FormSection>
          </Card>
        </Form>
      </Overlay.Content>

      {!didSign && (
        <Overlay.Footer>
          <div className="grid gap-3">
            {data.signature_acknowledgement_legal_text && (
              <Alert small contrast type="warning" glyph="info">
                {data.parsed_signature_acknowledgement_legal_text || data.signature_acknowledgement_legal_text}
              </Alert>
            )}

            <Button
              label="Agree"
              color="green"
              glyph="tick_circle"
              type="primary"
              onClick={handleAgree}
              isDisabled={!isValid || !hasSignature}
              isLoading={isLoading}
            />
          </div>
        </Overlay.Footer>
      )}
    </Overlay>
  )
}
