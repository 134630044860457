import React from 'react'

import { minutesBetween, titleCase } from '../../utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import LocationCheckStatus from '../../components/Statuses/LocationCheckStatus'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const LocationCheckinsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.name} to={to?.(data.data)} />,
      },
      {
        width: 140,
        id: 'category',
        model: 'data.category',
        title: 'Category',
        formatValue: ({ value }: any) => titleCase(value),
      },
      {
        width: 180,
        id: 'resident',
        model: 'data.resident',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 160,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ value }: any) => <LocationCheckStatus status={value} />,
        // Filter: TableFilter.Select,
        // filterOptions: [
        //   {
        //     value: 'in',
        //     label: 'Checked-In',
        //   },
        //   {
        //     value: 'out',
        //     label: 'Checked-Out',
        //   },
        //   {
        //     value: 'late',
        //     label: 'Late Checkout',
        //   },
        // ],
      },
      {
        width: 200,
        id: 'checkin_at',
        model: 'data.checkin_at',
        title: 'Check-in Time',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'checkout_at',
        model: 'data.checkout_at',
        title: 'Check-out Time',
        type: 'date_time',
      },
      {
        width: 120,
        id: 'duration',
        model: 'data.duration',
        title: 'Duration',
        formatValue: ({ value }: int) => `${value || '–'} minutes`,
      },
      {
        width: 200,
        id: 'notes',
        model: 'data.notes',
        title: 'Notes',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Location Check-In's"
      icon="recovery_navigation"
      columns={columns}
      filtersConfig={FILTERS.live_reports.location_checks}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="location_checks"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=location_checks'}
        />
      }
      {...props}
    />
  )
}
