import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import withPermissions from '@behavehealth/hocs/withPermissions'

import { Chotomate, Card, Grid, Button, Link } from '@behavehealth/components'
import { UATestsTable } from '@behavehealth/components/Tables'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

const UATests = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const [filters, setFilters] = React.useState({})

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'ua-tests', { filters }],
    url: `/residents/${resource_id}/ua_tests`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const { mutate: deleteUATests } = useDelete({
    name: ['client', resource_id, 'ua-tests'],
    url: '/ua_tests',
    invalidate: 'ua-tests',
  })

  return (
    <Grid gap="1rem" columns="100%">
      <Filters config={FILTERS.ua_tests} onUpdate={setFilters} localStorageKey="client_ua_tests" />

      <Card>
        <Chotomate name="test_results_ua_tests" ready={!isLoading} />

        <UATestsTable
          data={data}
          isLoading={isLoading}
          localStorageKey="clients_ua_tests"
          to={(id: string) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
          titleAfter={<Link to="/reports/ua-tests">View Full Report →</Link>}
          hiddenColumns={['reference']}
          emptyActions={
            <Button
              label="Add UA Test"
              type="primary"
              glyph="add"
              link={{
                pathname: `${match.url}/new`,
                data: {
                  reference: client,
                },
              }}
              permission="test_results.ua_tests.create"
            />
          }
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'test_results.ua_tests.delete',
              action: async ({ ids }: any) => {
                await deleteUATests(ids.join(','))
              },
            },
          ]}
        />
      </Card>
    </Grid>
  )
}

export default withPermissions(UATests)
