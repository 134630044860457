import React from 'react'
import { transparentize } from 'polished'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../../theme'
import { arrayToMapWithKey, countWord, isDefined } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { PopoverMenu, PopoverMenuItem } from '../PopoverMenu'
import Glyph from '../Glyph'
import Icon from '../Icon'
import Permission from '../Permission'

import { DataTableColumnResizer } from './DataTableColumnResizer'
import { useStore } from './useStore'
import { Filter } from './filters/Filter'
import { HEADER_FILTERS } from './headerFilters'

import { StringFilter } from './headerFilters/StringFilter'
import { MultiSelectFilter } from './headerFilters/MultiSelectFilter'

export const DataTableHeaderCell = (props: any) => {
  const { children, column, icon, index, isSticky, openFilters, onFiltersUpdate, columnFiltersEnabled, autoFocusFirstFilter } = props

  const { timezone } = useSettings()

  const addFilter: any = useStore((state: any) => state.addFilter)
  const addSorting: any = useStore((state: any) => state.addSorting)
  const canBatchSelect: any = useStore((state: any) => state.canBatchSelect)
  const filters: any = useStore((state: any) => state.filters)
  const filtersConfig: any = useStore((state: any) => state.filtersConfig)
  const columnIds: any = useStore((state: any) => state.columnIds)
  const hiddenColumnIds: any = useStore((state: any) => state.hiddenColumnIds)
  const removeFilter: any = useStore((state: any) => state.removeFilter)
  const reorderColumns: any = useStore((state: any) => state.reorderColumns)
  const resetSorting: any = useStore((state: any) => state.resetSorting)
  const sorting: any = useStore((state: any) => state.sorting)
  const toggleHideColumn: any = useStore((state: any) => state.toggleHideColumn)
  const filtersVisible: any = useStore((state: any) => state.filtersVisible)
  const setFilter: any = useStore((state: any) => state.setFilter)

  const sortedColumnIds = sorting?.map?.((sort: any) => sort.name)

  const columnId = column?.id
  const canSort = !!columnId && !column?.disableSort
  const canHide = !column?.disableHide
  const canFilter = filtersConfig?.hasOwnProperty(columnId)
  const isSorted = sortedColumnIds?.includes(columnId)
  const isHidden = hiddenColumnIds.includes(columnId)

  const columnSort = React.useMemo(() => {
    return sorting?.find?.((sort: any) => sort.name === columnId)
  }, [sorting, columnId])

  const isSortedAsc = isSorted && columnSort?.sort === 'ASC'
  const isSortedDesc = isSorted && columnSort?.sort === 'DESC'

  const activeFilters = filters?.map?.((filter: any) => filter.key)
  const isFilterActive = activeFilters?.includes(columnId)

  const filtersMap = React.useMemo(() => {
    return arrayToMapWithKey(filters, 'key')
  }, [filters])

  const applyFilters = () => {
    if (onFiltersUpdate) onFiltersUpdate(filtersMap)
  }

  const rootClasses = clsx(
    'DataTableHeaderCell',
    isSticky && 'is-sticky',
    column?.canEdit && 'can-edit',
    canSort && 'can-sort',
    canBatchSelect && 'can-batch-select',
  )

  const mainClasses = clsx(canSort && 'can-sort')

  const filterKey = column?.id || column?.model
  const filterType = filtersConfig?.[filterKey]?.type

  const FilterTag = React.useMemo(() => {
    if (!filterType) return null

    return HEADER_FILTERS[filterType] || null
  }, [filterType])

  const handleHideColumn = () => {
    toggleHideColumn(columnId)
  }

  const handleAddFilter = () => {
    addFilter({ key: columnId })
    openFilters()
  }

  const handleClearFilter = () => {
    removeFilter(columnId)
  }

  const sortAscending = () => {
    if (!canSort) return
    addSorting(columnId, 'ASC')
  }

  const sortDescending = () => {
    if (!canSort) return
    addSorting(columnId, 'DESC')
  }

  // ***************
  // Drag & Drop
  // ***************

  // const handleDrop = ({ source, destination }: any) => {
  //   if (!source || !destination) return

  //   reorderColumns(source.index, destination.index)
  // }

  // const [{ isDragging }, dragRef] = useDrag(
  //   () => ({
  //     type: 'DATA_TABLE_HEADER_CELL',
  //     item: { id: columnId, index: index },
  //     end: (item, monitor) => {
  //       const dropResult: any = monitor.getDropResult()

  //       if (!dropResult || item.id === dropResult.id) return // cannot drop on itself

  //       handleDrop({ source: item, destination: dropResult })
  //     },
  //     collect: (monitor) => ({
  //       isDragging: monitor.isDragging(),
  //       handlerId: monitor.getHandlerId(),
  //     }),
  //   }),
  //   [handleDrop, columnIds], // required dependency array, otherwise handleDrop function doesn't work
  // )

  // const [{ canDrop, isOverCurrent }, dropRef] = useDrop(() => ({
  //   accept: ['DATA_TABLE_HEADER_CELL'],
  //   drop: (item: any, monitor) => {
  //     const didDrop = monitor.didDrop()

  //     if (didDrop) return

  //     return { id: columnId, index: index, item }
  //   },
  //   collect: (monitor) => {
  //     const item: any = monitor.getItem()
  //     const canDrop: any = monitor.canDrop()
  //     const isNotItself: any = item?.id !== columnId
  //     const isOverCurrent = monitor.isOver({ shallow: true })

  //     return {
  //       canDrop: canDrop && isOverCurrent && isNotItself,
  //       isOver: monitor.isOver(),
  //       isOverCurrent: monitor.isOver({ shallow: true }),
  //     }
  //   },
  // }))

  if (isHidden) return null

  return (
    <div className={rootClasses} css={STYLES.root}>
      <div
        // ref={(node) => dragRef(dropRef(node))}
        css={STYLES.header}
        data-test={`column_header_${columnId}`}
        className="column-header-name"
      >
        {/* {isOverCurrent && <div className={STYLES.dropLine()} />} */}

        <PopoverMenu
          testKey="column_options_menu"
          trigger={
            <div data-test="column_options_trigger" className={mainClasses} css={STYLES.main}>
              {isFilterActive && <Glyph size={14} glyph="filter" color={COLORS.blue} style={{ marginRight: '0.4rem' }} />}

              {icon && <Icon icon={icon} size={16} className="mr-1.5" />}

              <div css={STYLES.cellContent}>{children}</div>

              {isSorted && (
                <Glyph
                  size={10}
                  glyph={columnSort?.sort === 'ASC' ? 'triangle_up' : 'triangle_down'}
                  css={STYLES.sortTriangle}
                  color={COLORS.blue}
                />
              )}

              <button type="button" css={STYLES.button}>
                <Glyph glyph="more_vertical" size={13} color={COLORS.textMuted} />
              </button>
            </div>
          }
        >
          {canFilter && !isFilterActive && (
            <Permission featureFlagV2="filters">
              <PopoverMenuItem label="Add Filter…" glyph="filter" onClick={handleAddFilter} />
            </Permission>
          )}

          {canSort && (
            <>
              <PopoverMenuItem label="Sort Ascending" glyph="sort_ascending" onClick={sortAscending} isActive={isSortedAsc} />
              <PopoverMenuItem label="Sort Descending" glyph="sort_descending" onClick={sortDescending} isActive={isSortedDesc} />
            </>
          )}

          {canHide && <PopoverMenuItem label="Hide Column" glyph="view" onClick={handleHideColumn} />}

          {canFilter && isFilterActive && (
            <PopoverMenuItem label="Clear Filter" glyph="close" glyphColor={COLORS.red} onClick={handleClearFilter} />
          )}

          {isSorted && <PopoverMenuItem label="Clear Sorting" glyph="close" glyphColor={COLORS.red} onClick={resetSorting} />}
        </PopoverMenu>
      </div>

      {filtersVisible && columnFiltersEnabled && (
        <div className={`HeaderFilterCell`} css={STYLES.filterWrapper}>
          {FilterTag && (
            <FilterTag
              key={filterKey}
              autoFocus={autoFocusFirstFilter && index === 0}
              filterKey={filterKey}
              config={filtersConfig[filterKey]}
              filter={filtersMap?.[filterKey]}
              timezone={timezone}
              applyFilters={applyFilters}
              onUpdate={(newFilter: any) => {
                setFilter(filterKey, { ...newFilter })
              }}
            />
          )}
        </div>
      )}

      <DataTableColumnResizer index={index} column={column} css={STYLES.resizer} />
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    alignSelf: 'stretch',
    boxShadow: `inset 1px 0 0 ${COLORS.divider}`,
    position: 'relative',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    background: COLORS.white,

    '&:last-child': {
      borderRight: 'none',
    },

    '&.is-sticky': {
      '@media(min-width: 600px)': {
        position: 'sticky',
        left: 0,
        zIndex: 3,
        boxShadow: `3px 0 0 ${COLORS.shadow}`,

        '&.can-batch-select': {
          left: 'var(--checkbox-width)',
        },
      },
    },

    '.sort-triangle': {
      display: 'none',
    },

    '&:first-child': {
      '.column-header-name': {
        boxShadow: 'none',
      },
    },
  },

  header: {
    display: 'flex',
    alignItems: 'stretch',
    alignSelf: 'stretch',
    boxShadow: `inset 1px 0 0 ${COLORS.divider}`,
    position: 'relative',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    background: COLORS.white,
    minHeight: 'var(--row-min-height)',
  },

  main: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.4rem',
    flex: '1 1 auto',
    cursor: 'pointer',
    overflow: 'hidden',

    '&:hover': {
      boxShadow: SHADOW(3),
      color: COLORS.blue,

      svg: {
        fill: COLORS.blue,
      },
    },
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    borderRadius: 0,
    width: 24,
    outline: 'none',
    marginLeft: 'auto',

    '&:hover': {
      background: COLORS.white,
      boxShadow: 'none !important',

      svg: {
        fill: COLORS.blue,
      },
    },
  },

  sortTriangle: {
    marginLeft: '0.25rem',
  },

  resizer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: -2,
    height: 'auto',
    zIndex: 1,

    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 12,
      height: '100%',
      transform: 'translateX(-50%)',
    },
  },

  cellContent: {
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  dropLine: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 2,
    height: '100vh',
    background: transparentize(0.25, COLORS.blue),
    zIndex: 10,

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 32,
      background: `linear-gradient(90deg, ${transparentize(0.9, COLORS.blue)}, ${COLORS.transparent})`,
    },
  },

  filterWrapper: {
    minHeight: 'var(--row-min-height)',
    padding: '0.25rem 0.3rem',
  },
}
