import React from 'react'

import Button from '../../components/Button'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'

import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Notifications from '../../modules/notifications'

import { useCreate } from '../../hooks/useNewAPI'

const ImportFeatureFlagConfigOverlay = (props: any) => {
  const { onClose } = props

  const [isValid, setIsValid] = React.useState(false)
  const [featureFlagConfig, setFeatureFlagConfig] = React.useState(null)

  const closeOverlay = async () => {
    if (onClose) onClose()
  }

  const { mutateAsync: asyncApplyFeatureFlag, isLoading } = useCreate({
    name: ['apply-feature-flag'],
    url: '/facilities/current/apply_feature_flag_config',
  })

  const applyFeatureFlagConfig = async () => {
    try {
      await asyncApplyFeatureFlag({ feature_flag_config_id: featureFlagConfig.id })

      Notifications.send('Feature Flag Config Applied Successfully', 'positive')

      closeOverlay()

      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay showBackdrop position="center" onClose={closeOverlay}>
      <Overlay.Header title="Import Feature Flag Config" glyph="behave_health" />

      <Overlay.Content>
        <Section>
          <Form onValidationUpdate={setIsValid}>
            <FormSection>
              <OverlaySelector
                label="Select Feature Flag Config to Apply"
                model="app"
                icon="feature_flags"
                type="admin.feature_flag_configs"
                onChange={(input: any) => setFeatureFlagConfig(input.value)}
                validations={{
                  presence: {
                    message: 'Please select the Feature Flag Config you want to apply',
                  },
                }}
              />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Apply Feature Flag Config"
          glyph="check"
          color="green"
          type="primary"
          isDisabled={!isValid}
          onClick={applyFeatureFlagConfig}
          isLoading={isLoading}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const styles = {
  dataList: {
    marginBottom: '1rem',
  },
  message: {
    root: {
      textAlign: 'center',
      padding: '2rem 0',
    },

    title: {
      margin: '0.25rem 0 0.5rem',
    },

    description: {
      margin: 0,
    },
  },
}

export default ImportFeatureFlagConfigOverlay
