import React from 'react'

import GlobalTags from '../../../Table/Cells/GlobalTags'
import Table from '../../../Table/Table'
import TableCell from '../../../Table/TableCell'

import withSettings from '../../../../hocs/withSettings'

const COLLECTION_TYPE: any = {
  insurance: 'Insurance',
  cash: 'Cash',
}

const UNIT_TYPE: any = {
  per_diem: 'Per Diem',
  per_session: 'Per Session',
  per_item: 'Per Item',
  per_hour: 'Per Hour',
  per_minutes: 'Per Minutes',
}

const columns = (to: any, mainLinkAs) => [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Name',
    width: 240,
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to?.(row.original.id)} label={value} as={mainLinkAs} />,
  },
  {
    width: 240,
    accessor: 'data.description',
    Header: 'Description',
  },
  {
    width: 240,
    Header: 'Tags',
    accessor: 'global_tags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <GlobalTags
        // queryKey={queryKey} // TODO
        tags={row.original.global_tags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 160,
    accessor: 'data.collection_type',
    Header: 'Collection Type',
    Cell: ({ value }: any) => <TableCell value={COLLECTION_TYPE[value] || '–'} />,
  },
  {
    width: 160,
    accessor: 'data.unit',
    Header: 'Unit',
    Cell: ({ value }: any) => <TableCell value={UNIT_TYPE[value] || '–'} />,
  },
  {
    width: 160,
    accessor: 'data.amount',
    Header: 'Amount',
    Cell: ({ value }: any) => <TableCell.Amount value={value} />,
  },
  {
    width: 240,
    accessor: 'data.internal_notes',
    Header: 'Internal Notes',
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  isLoading: boolean
  localStorageKey: string
  title?: string
  titleAfter?: React.ReactNode
  to?: Function
}

const InsuranceServicesTemplatesTable = (props: Props) => {
  const { batchActionsConfig, data, isLoading, localStorageKey, title, titleAfter, to, ...rest } = props

  return (
    <Table
      data={data}
      columns={columns(to, props.mainLinkAs)}
      title={title}
      titleAfter={titleAfter}
      icon="insurance"
      isLoading={isLoading}
      emptyDescription="No insurance services added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

InsuranceServicesTemplatesTable.defaultProps = {
  title: 'Insurance Services',
  localStorageKey: 'insurance_services_templates',
}

export default withSettings(InsuranceServicesTemplatesTable)
