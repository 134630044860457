import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  dtx: {
    label: 'Detox',
    color: 'yellow',
  },
  dtx_acute: {
    label: 'Detox - Acute',
    color: 'yellow',
  },
  dtx_subacute: {
    label: 'Detox - Sub-acute',
    color: 'yellow',
  },
  rtc: {
    label: 'Residential',
    color: 'yellow',
  },
  rtc_medical: {
    label: 'Residential - Medical',
    color: 'yellow',
  },
  rtc_nonmedical: {
    label: 'Residential - Non-Medical',
    color: 'yellow',
  },
  php: {
    label: 'Partial Hospitalization',
    color: 'yellow',
  },
  five_day_iop: {
    label: '5-Day Intensive Outpatient',
    color: 'yellow',
  },
  four_day_iop: {
    label: '4-Day Intensive Outpatient',
    color: 'yellow',
  },
  three_day_iop: {
    label: '3-Day Intensive Outpatient',
    color: 'yellow',
  },
  op: {
    label: 'Outpatient',
    color: 'yellow',
  },

  medically_managed_intensive_inpatient_detoxification: {
    label: '4-D Medically Managed Intensive Inpatient Detoxification',
    color: 'yellow',
  },
  medically_managed_intensive_inpatient_services: {
    label: '4 Medically Managed Intensive Inpatient Services',
    color: 'yellow',
  },
  medically_monitored_inpatient_detoxification: {
    label: '3.7-D Medically Monitored Inpatient Detoxification',
    color: 'yellow',
  },
  medically_monitored_high_intensity_inpatient_services: {
    label: '3.7 Medically Monitored High-Intensity Inpatient Services',
    color: 'yellow',
  },
  clinically_managed_medium_intensity_residential_services: {
    label: '3.5 Clinically Managed Medium-Intensity Residential Services',
    color: 'yellow',
  },
  clinically_managed_population_specific_high_intensity_residential_services: {
    label: '3.3 Clinically Managed Population-Specific High-Intensity Residential Services',
    color: 'yellow',
  },
  clinically_managed_residential_detoxification: {
    label: '3.2-D Clinically Managed Residential Detoxification',
    color: 'yellow',
  },
  clinically_managed_low_intensity_residential_services: {
    label: '3.1 Clinically Managed Low-Intensity Residential Services',
    color: 'yellow',
  },
  residential_inpatient_detoxification: {
    label: '3-D Residential/Inpatient Detoxification',
    color: 'yellow',
  },
  partial_hospitalization_services: {
    label: '2.5 Partial Hospitalization Services',
    color: 'yellow',
  },
  intensive_outpatient_services: {
    label: '2.1 Intensive Outpatient Services',
    color: 'yellow',
  },
  ambulatory_detoxification_with_extended_on_site_monitoring: {
    label: '2-D Ambulatory Detoxification With Extended On-Site Monitoring',
    color: 'yellow',
  },
  ambulatory_detoxification_without_extended_on_site_monitoring: {
    label: '1-D Ambulatory Detoxification Without Extended On-Site Monitoring',
    color: 'yellow',
  },
  outpatient_services: {
    label: '1 Outpatient Services',
    color: 'yellow',
  },
  early_intervention: {
    label: '0.5 Early Intervention',
    color: 'yellow',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
