import React from 'react'

import Overlay from '../../Overlay'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Textarea from '../../Forms/Textarea'
import { withOverlayError } from '../../../hocs/withOverlayError'

const EDITABLE = true

class List extends React.Component {
  render() {
    return (
      <Overlay isOpen={true}>
        <Overlay.Header icon="ic_lists" title="List Name" description="10 Clients, 3 Staff Members"></Overlay.Header>
        <Overlay.Content>
          <Form isEditable={EDITABLE}>
            <FormSection layout="vertical">
              <Input label="Lists Name" />
              <Textarea label="List Description" />
            </FormSection>
          </Form>
        </Overlay.Content>
      </Overlay>
    )
  }
}

export default withOverlayError(List)
