import React from 'react'
import { transparentize } from 'polished'

import { COLORS } from '../../theme'
import { mapToArray, usDate } from '../../utils/functions'

import Button from '../Button'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Status from '../Status'

import TimetableEvent from './TimetableEvent'

const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const getEventsByDay = (data: any, week: number) => {
  data = mapToArray(data)
  const res: any = [[], [], [], [], [], [], []]

  for (const item of data) {
    if (item.week === week) {
      res[item.day].push(item)
    }
  }

  return res
}

type Props = {
  events: any
  render: Function
  weeksCount: number
  startDate: string
  startDateEditLink: string
}

const Timetable = ({ events, render, weeksCount, startDate, startDateEditLink }: Props) => {
  const currentWeek = 1
  const [week, setWeek] = React.useState(currentWeek)

  const eventsByDay = getEventsByDay(events, week)

  const canGoPrev = week >= 2
  const canGoNext = week < weeksCount

  const isCurrentWeek = week === currentWeek
  const hasMultipleWeeks = weeksCount >= 2

  const goThisWeek = () => setWeek(currentWeek)
  const goNextWeek = () => canGoNext && setWeek((c) => c + 1)
  const goPrevWeek = () => canGoPrev && setWeek((c) => c - 1)

  if (!events || !render) return null

  return (
    <div>
      <Flex fillContent css={styles.header} gap={8} alignItems="center" justifyContent="space-between">
        <Flex gap={8} alignItems="center" css={{ flex: '999 1 auto' }}>
          <h3 css={styles.title}>
            Week #{week} {hasMultipleWeeks && <span css={styles.weeksCount}>/ {weeksCount} weeks</span>}
          </h3>

          {startDate && (
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <span>
                <b>Start Date:</b> {usDate(startDate)}
              </span>
              <Button hideLabel glyph="edit" type="minimal" size={200} link={startDateEditLink} className="!ml-2" />
            </div>
          )}
        </Flex>

        {hasMultipleWeeks && (
          <Flex fillContent gap={8} alignItems="center">
            <Button
              label={canGoPrev ? `Week ${week - 1}` : 'Prev'}
              size={200}
              onClick={goPrevWeek}
              isDisabled={!canGoPrev}
              color={canGoPrev ? 'blue' : 'text'}
              before={<Glyph glyph="chevron_left" size="0.9em" />}
            />

            <Button
              label={`Current Week (${currentWeek})`}
              size={200}
              onClick={goThisWeek}
              isDisabled={isCurrentWeek}
              color={isCurrentWeek ? 'text' : 'blue'}
              before={<Glyph glyph="date" size="1.2em" />}
              css={styles.currentWeekButton}
            />

            <Button
              label={canGoNext ? `Week ${week + 1}` : 'Next'}
              size={200}
              onClick={goNextWeek}
              isDisabled={!canGoNext}
              color={canGoNext ? 'blue' : 'text'}
              after={<Glyph glyph="chevron_right" size="0.9em" />}
            />
          </Flex>
        )}
      </Flex>

      <div css={styles.schedule}>
        {DAYS.map((day, index) => (
          <div key={day} css={styles.column}>
            <header css={styles.columnHeader}>
              <div>
                <h3 css={styles.columnTitle}>
                  {day}
                  {week === 1 && index === 4 && <Status label="Today" color="blue" className="!ml-2" />}
                </h3>
                <p css={styles.columnSubtitle}>Day #{7 * (week - 1) + (index + 1)}</p>
              </div>

              <Button hideLabel glyph="add" type="minimal" size={200} />
            </header>

            <div css={styles.content}>{eventsByDay[index]?.map((event: any) => render(event))}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const styles = {
  header: {
    padding: '1rem',
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  title: {
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: 1,
  },

  weeksCount: {
    opacity: 0.75,
    fontSize: '0.85em',
    color: COLORS.textMuted,
    fontWeight: 500,
    textTransform: 'none',
    letterSpacing: 'normal',
  },

  schedule: {
    display: 'grid',
    gridTemplateColumns: '1fr',

    '@media (min-width: 500px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
    },
  },

  column: {
    boxShadow: `
      1px 0 0 ${COLORS.divider},
      0 1px 0 ${COLORS.divider}
    `,

    '&:hover': {
      background: COLORS.hover,
    },
  },

  columnHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '0.65rem 1rem',
    boxShadow: `0 1px 0 ${COLORS.divider}`,
    background: transparentize(0.75, COLORS.white),
  },

  columnTitle: {
    display: 'inline-flex',
    alignItems: 'center',

    fontSize: '1rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: 600,
    margin: 0,
  },

  columnSubtitle: {
    fontSize: '0.9rem',
    color: COLORS.textMuted,
    fontWeight: 400,
    margin: 0,
  },

  content: {
    padding: '0.75rem 0.5rem',
    minHeight: 200,
  },

  currentWeekButton: {
    order: '1',

    '@media (min-width: 375px)': {
      order: 'initial',
    },
  },
}

Timetable.Event = TimetableEvent
Timetable.Event.displayName = 'TimetableEvent'

Timetable.defaultProps = {
  weeksCount: 1,
}

export default Timetable
