import React from 'react'
import { useLocation } from 'react-router-dom'

import { LOC } from '../../utils/constants'
import { usDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'

type Props = {
  authorization: any
  location: any
  timezone: any
  to: any
}

const Authorization = ({ authorization, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={LOC[authorization.level]} />
      <CardSubtitle subtitle={authorization.notes} />
      <CardMeta>
        <Text avatar={authorization?.author?.avatar} label={authorization?.author?.name} />
        <Text glyph="date" label="From: " muted={false} description={<Text description={usDate(authorization.started_at, timezone)} />} />
        <Text glyph="date" label="Until: " muted={false} description={<Text description={usDate(authorization.ended_at, timezone)} />} />
        <Text
          glyph="email"
          label="Reminders: "
          muted={false}
          description={<Text description={authorization.send_reminders ? 'Yes' : 'No'} />}
        />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(Authorization)
