import React from 'react'

import { useParams, useLocation, useHistory } from 'react-router-dom'
import pluralize from 'pluralize'
import size from 'lodash/size'

import { GlobalSummonOverlayContext } from '../components/GlobalSummonOverlay'

type Args = {
  closeOnSave?: boolean
  filters?: any
  isEditable?: boolean
  options?: any
  Overlay?: any
  skipClose?: boolean
  parentType?: string
  parentRequest?: string
}

const CLIENT_RESOURCE_TYPES = ['residents', 'resident', 'clients', 'client', 'admissions', 'admission', 'alumni']

const processResourceType = (type: string) => {
  if (CLIENT_RESOURCE_TYPES.includes(type)) return 'client'
  else return pluralize.singular(type)
}

const getResourceType = ({ options, params }: any) => {
  // type passed through props
  if (options?.parent?.type) {
    return processResourceType(options.parent.type)
  }

  // structured type found in URL param
  if (params?.resource || params?.resource_type) {
    return processResourceType(params?.resource || params?.resource_type)
  }

  // unstructured type found in URL
  if (CLIENT_RESOURCE_TYPES.includes(params['0'])) {
    return processResourceType(params['0'])
  }

  return null
}

export const useSimpleOverlay = (args: Args) => {
  const { options = {}, Overlay } = args

  const form: any = React.useRef()
  const history: any = useHistory()
  const location: any = useLocation()
  const params: any = useParams()

  const { addOverlay } = React.useContext(GlobalSummonOverlayContext)

  // setup initial form data
  const initialData = options.initialData || location.data
  const id = options.dataID || params.id || initialData?.id
  const shouldAnonymize = options.anonymize || location.anonymize
  const isNew = id === 'new' || !id

  // find parent id and type
  const parent = {
    id: options.parent?.id || params.resource_id,
    type: getResourceType({ options, params }),
  }

  const record = initialData?.client

  // set up local state
  const [isEditable, setIsEditable] = React.useState(isNew || !!args.isEditable)
  const [isValid, setIsValid] = React.useState(false)

  // set up initial form model
  const initialModel = Object.assign({}, initialData)

  // set up reaction state
  const isEmpty = !isNew && size(initialModel) === 0
  const isOverlayLoading = isEmpty

  const close = () => {
    if (options.type === 'summon' && options.onClose) return options.onClose()

    const url = location.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    history.push(url)
  }

  const cancel = () => {
    form.current?.resetForm()
    setIsEditable(false)
  }

  const exitEdit = () => {
    setIsEditable(false)
  }

  const onIdle = () => {} // TODO

  const handleMinimize = () => {
    if (options?.type === 'summon') return

    addOverlay(<Overlay {...options} isMinimized type="summon" dataID={id} parent={parent} initialData={initialData} />)

    close()
  }

  const handleFullyMinimize = () => {
    if (options?.type === 'summon') return

    addOverlay(<Overlay {...options} isMinimized isFullyMinimized type="summon" dataID={id} parent={parent} initialData={initialData} />)

    close()
  }

  return {
    anonymize: shouldAnonymize,
    cancel,
    client: record,
    record,
    close,
    edit: () => setIsEditable(true),
    exitEdit,
    form,
    handleFullyMinimize,
    handleMinimize,
    highlightRequired: form.current?.highlightInvalid,
    id,
    initialData,
    initialModel,
    isEditable,
    isEmpty,
    isInvalid: !isValid,
    isNew,
    isOverlayLoading,
    isValid,
    onIdle,
    onValidationUpdate: setIsValid,
    parent,
    setIsEditable,
  }
}
