import React from 'react'

import Select from './Select'
import Option from './Option'

import { ADMISSION_SOURCE } from '../../utils/constants'

const AdmissionSourceSelect = ({ isEditable, validations, onUpdate, value, model = 'admission_source_code' }: any) => (
  <Select
    fullWidth
    allowEmpty
    isEditable={isEditable}
    label="Admission Source"
    model={model}
    validations={validations}
    onUpdate={onUpdate}
    value={value}
    defaultValue="9"
  >
    {Object.keys(ADMISSION_SOURCE).map((level: any) => (
      <Option label={ADMISSION_SOURCE[level]} value={level} />
    ))}
  </Select>
)

AdmissionSourceSelect.defaultProps = {
  label: 'Admission Source',
  model: 'admission_source',
}

export default AdmissionSourceSelect
