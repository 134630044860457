import React from 'react'

import Textarea from '../../../components/Forms/Textarea'
import Input from '../../../components/Forms/Input'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'

const COMPONENTS = {
  textarea: Textarea,
  input: Input,
  true_false: YesNoRadioGroup,
}

export const CustomQuestion = ({ question, sectionModel }: any) => {
  if (!question) return null

  const Tag = COMPONENTS[question.category]

  if (!Tag) return null

  return (
    <Tag
      label={question.label}
      model={`data.${sectionModel}.custom_questions.${question._id}`}
      validations={
        question.is_required && {
          presence: {
            message: 'This field is required',
          },
        }
      }
    />
  )
}
