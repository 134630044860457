import React from 'react'
import groupBy from 'lodash/groupBy'
import size from 'lodash/size'

import Grid from '../../components/Grid'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Glyph from '../../components/Glyph'
import Flex from '../../components/Flex'
import Tooltip from '../../components/Tooltip'

import useStore from '../../modules/store'
import Notifications from '../../modules/notifications'

import { get } from '../../modules/api/requests'
import { nicePhoneNo } from '../../utils/functions'

import useCopyToClipboard from '../../hooks/useCopyToClipboard'

const AccessListTooltip = ({ client, accessList }: any) => (
  <Tooltip
    position="bottom"
    content={
      <Grid gap="0.5rem">
        <strong>Client</strong>
        <ul className="!pl-4">
          <li>
            {accessList?.client?.name}
            <ul className="!pl-4">
              <li>Email: {accessList?.client?.email || '–'}</li>
              <li>Cell: {nicePhoneNo(accessList?.client?.phone_no)}</li>
            </ul>
          </li>
        </ul>

        <strong>{client?.first_name}'s Contacts</strong>
        <ul className="!pl-4">
          {accessList?.contacts?.map((contact: any, index: number) => (
            <li key={index}>
              {contact?.name || '–'}
              <ul className="!pl-4">
                <li>Email: {contact?.email || '–'}</li>
                <li>Cell: {nicePhoneNo(contact?.phone_no)}</li>
              </ul>
            </li>
          ))}

          {size(accessList?.contacts) === 0 && <li>{client?.first_name} has no contacts</li>}
        </ul>

        <strong>Payer Organizations</strong>
        <ul className="!pl-4">
          {accessList?.organizations?.map((org: any, index: number) => (
            <li key={index}>
              {org?.name || '–'}
              <ul className="!pl-4">
                <li>Email: {org?.email || '–'}</li>
                <li>Cell: {nicePhoneNo(org?.phone_no)}</li>
              </ul>
            </li>
          ))}

          {size(accessList?.organizations) === 0 && <li>No Payer Organization found</li>}
        </ul>
      </Grid>
    }
  >
    <Button size={100} type="link" label="Who can access?" />
  </Tooltip>
)

const OnlinePaymentsCollectionCards = ({ client }: any) => {
  const tenant = useStore((state) => state.tenant)

  const [accessList, setAccessList] = React.useState([])

  const paymentLink = `${process.env.BH_APP_LEDGER_URL}/${tenant.subdomain}/pay/${client?.external_id}`
  const ledgerLink = `${process.env.BH_APP_LEDGER_URL}/${tenant.subdomain}/client/${client?.external_id}`

  const { copy: copyPaymentLink, didCopy: paymentLinkCopied } = useCopyToClipboard({
    text: paymentLink,
    notification: 'Payment Link copied!',
  })

  const { copy: copyLedgerLink, didCopy: ledgerLinkCopied } = useCopyToClipboard({
    text: ledgerLink,
    notification: 'Ledger Link copied!',
  })

  React.useEffect(() => {
    const getAccess = async () => {
      try {
        const result = await get(`/residents/${client?.id}/access_list`)

        const grouped = groupBy(result.data, 'type')
        setAccessList({
          client: grouped.client[0],
          contacts: grouped?.contact,
          organizations: grouped?.organization,
        })
      } catch (error) {
        console.error(error)
      }
    }

    if (client?.id) getAccess()
  }, [client])

  return (
    <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
      <Card className="px-3 py-2">
        <Grid gap="1rem">
          <Flex gap="1rem" justifyContent="space-between">
            <h3 className="text-[1.1rem]">
              <Flex gap="0.5rem" alignItems="center">
                <Glyph glyph="cash_hand" size={24} className="" />
                <span>Online Ledger Payments</span>
              </Flex>
            </h3>
          </Flex>

          <div className="pl-[32px] -mt-[12px] -mb-1.5 text-[0.95rem]">
            Use the <strong>Online Payments</strong> to securely collect Payments <strong>without sharing</strong> any Ledger details.
          </div>

          <Flex gap="0.25rem" className="pl-[32px]">
            <Button
              size={100}
              glyph={paymentLinkCopied ? 'check' : 'copy'}
              label={paymentLinkCopied ? 'Link Copied!' : 'Copy Payments Link'}
              onClick={copyPaymentLink}
            />

            <Button size={100} type="link" label="Open in New Tab" href={paymentLink} target="_blank" />
          </Flex>
        </Grid>
      </Card>

      <Card className="px-3 py-2">
        <Grid gap="1rem">
          <Flex gap="1rem" justifyContent="space-between">
            <h3 className="text-[1.1rem]">
              <Flex gap="0.5rem" alignItems="center">
                <Glyph glyph="ledger" size={24} className="" />
                <span>Online Ledger</span>
              </Flex>
            </h3>

            <AccessListTooltip client={client} accessList={accessList} />
          </Flex>

          <div className="pl-[32px] -mt-[12px] -mb-1.5 text-[0.95rem]">
            Use the <strong>Online Ledger</strong> to securely <strong>share</strong> some of the ledger details and collect Payments.
          </div>

          <Flex gap="0.25rem" className="pl-[32px]">
            <Button
              size={100}
              glyph={ledgerLinkCopied ? 'check' : 'copy'}
              label={ledgerLinkCopied ? 'Link Copied!' : 'Copy Ledger Link'}
              onClick={copyLedgerLink}
            />

            <Button size={100} type="link" label="Open in New Tab" href={ledgerLink} target="_blank" />
          </Flex>
        </Grid>
      </Card>
    </div>
  )
}

export default OnlinePaymentsCollectionCards
