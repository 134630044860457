import React from 'react'

import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const ServiceCodesFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="behave_health" />
        <div>
          <Type as="h2" variant="H3">
            Service Codes
          </Type>
          <Type as="p" variant="TEXT" css={{ margin: '0.2em 0 0' }}>
            Add any services your providers deliver to patients
          </Type>
        </div>
      </Flex>

      <MultiObjectSelector
        isRelations={false}
        label="Service Codes"
        model="questionnaire.service_codes.codes"
        icon="behave_health"
        type="insurance_global_services"
        selectTitle={(data: any) => data.short_description}
        selectDescription={(data: any) => `${data.code} – ${data.long_description}`}
        searchKeys={['short_description', 'long_description', 'code']}
      />
    </FormSection>
  )
}

export const ServiceCodes: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <ServiceCodesFields />
    </Form>
  )
}
