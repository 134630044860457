import React from 'react'
import clsx from 'clsx'
import get from 'lodash/get'

import { withFormContext } from '../../context'

import SelectorBase from '../SelectorBase'
import SelectorInput from '../SelectorInput'
import Value from '../../../Value'
import Overlay from '../../../Overlay'
import Card from '../../../Card'
import SummonOverlay from '../../../SummonOverlay'
import Field from '../../../Forms/Field'

import { LINKS } from '../SelectorBase'
import { TABLES_CONFIG } from '../tables'
import snakeCase from 'lodash/snakeCase'

class OverlaySelector extends SelectorBase {
  renderEdit = () => {
    const { object, isDisabled } = this.state
    const { selectTitle, className, testKey, selectGraphic } = this.props

    return (
      <SelectorInput
        showFocusMessage={!this.state.value}
        label={selectTitle && object ? selectTitle(object) : object?.name}
        testKey={testKey}
        object={object}
        icon={this.props.icon}
        selectGraphic={selectGraphic}
        avatar={object?.avatar}
        onOpen={this.open}
        onClear={(event: any) => {
          if (this.props.onClear) this.props.onClear(this.state)
          this.changeValue(null)
          this.props.onSelect?.(null)

          event.stopPropagation()
        }}
        isDisabled={isDisabled || object?.isRequired}
        className={className}
      />
    )
  }

  renderReadOnly = () => {
    const { object } = this.state
    const { icon, selectTitle, showDescription, type, disableLink, selectGraphic } = this.props

    const value = selectTitle && object ? selectTitle(object) : object?.name

    return (
      <Value
        avatarMagnify
        graphic={selectGraphic ? <div className="mr-1">{selectGraphic(object)}</div> : undefined}
        value={value}
        icon={icon}
        avatar={object?.avatar}
        avatarMagnifyScale={2.5}
        description={showDescription && object?.description}
        avatarInitials={value}
        link={!disableLink && LINKS[type]?.(object?.id)}
      />
    )
  }

  renderSelector = () => {
    const config = get(TABLES_CONFIG, this.props.type)

    if (!config?.component) return null

    const Table = config.component

    return (
      <Overlay testKey={`${snakeCase(config?.title)}_selector_overlay`} showBackdrop stopPropagation position="center" maxWidth={140}>
        <Overlay.Header title={`Select ${config?.title || 'record'}…`} icon={config?.icon} />

        <Overlay.Content>
          <Card className="m-4">
            <Table
              testKey={`${snakeCase(config?.title)}_selector_table`}
              icon={config?.icon}
              dependentValue={this.props.dependentValue}
              tenant={this.props.tenant}
              onSelect={(o: any) => {
                this.changeValue(o)
                this.props.onSelect?.(o)
              }}
              includeColumns={this.props.includeColumns}
              hiddenColumnIds={this.props.hiddenColumnIds}
              excludeRowIds={this.props.excludeRowIds}
              config={config}
            />
          </Card>
        </Overlay.Content>
      </Overlay>
    )
  }

  render() {
    const {
      isEditable,
      label,
      labelWidth,
      layout,
      showLabel,
      maxWidth,
      className,
      hide,
      description,
      withHover,
      testKey,
      isCompact,
      labelAfter,
      isDisabled,
    } = this.props
    const { id, isOpen, isRequired, isValid, isLoading, isHighlighted, errors } = this.state

    if (hide) return null

    const classNames = clsx(className, 'ObjectSelector', { 'is-open': isOpen })

    return (
      <Field
        getRef={(ref) => (this.fieldRef = ref)}
        className={classNames}
        errors={errors}
        id={id}
        isEditable={isEditable}
        isLoading={isLoading}
        isRequired={isRequired}
        isValid={isValid}
        isHighlighted={isHighlighted}
        label={label}
        showLabel={showLabel}
        labelWidth={labelWidth}
        layout={layout}
        maxWidth={maxWidth}
        description={description}
        withHover={withHover}
        testKey={`${testKey}_field`}
        isCompact={isCompact}
        labelAfter={labelAfter}
        isDisabled={isDisabled}
      >
        {isEditable ? (
          <>
            <SummonOverlay isOpen={isOpen} overlay={this.renderSelector()} onClose={this.close}>
              {this.renderEdit()}
            </SummonOverlay>

            {this.renderSelected()}
          </>
        ) : (
          this.renderReadOnly()
        )}
      </Field>
    )
  }
}

OverlaySelector.defaultProps = {
  prefetch: false,
  searchKeys: ['name', 'description'],
  isEditable: true,
  isDisabled: false,
  initialModelRequired: false,
  showDescription: true,
  isOpen: false,
  isRelation: true,
  isPolymorphic: false,
  validateOn: 'blur-change',
  layout: 'grid',
}

export default withFormContext(OverlaySelector)
