import React from 'react'

import { useSettings } from '../../../hooks/useSettings'
import { usDate, usDateTime } from '../../../utils/functions'
import DataList from '../../../components/DataList'

export const MedicationLogInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item label="Medication" value={data.med?.name} />
      {data.client && <DataList.Item label="Client" value={data.client.name} avatar={data.client.avatar} />}
      <DataList.Item label="Administered At" value={usDateTime(data.administered_at, timezone)} />
      <DataList.Item label="Dosage" value={data.dosage} />
      <DataList.Item label="Quantity" value={data.unit} />
      <DataList.Item label="Quantity Remaining" value={data.remaining_units} />
    </DataList>
  )
}
