import React from 'react'

import SankeyChart from '../ChartTypes/SankeyChart'

export const MarketingFunnelChart = (props: any) => {
  return <SankeyChart data={DATA} links={LINKS} {...props} />
}

const DATA = [
  { name: 'Total Monthly Unique Website Visitors' },

  { name: 'CTA Success' },
  { name: 'CTA Failure' },

  { name: 'Learn More Form' },
  { name: 'Form Submission Fail' },
  { name: 'Form Submission Success' },
  { name: 'Form Qualification Success' },
  { name: 'Form Qualification Fail' },
  { name: 'Phone Calls Outbound' },
  { name: 'Call not-connected' },
  { name: 'Call connected' },
  { name: 'Call Qualification Fail' },
  { name: 'Call Qualification Success' },

  { name: 'Verify Insurance Form' },
  { name: 'Call Now Button' },
  { name: 'Chat Now Widget' },
  { name: 'Email Now Button' },
]

const LINKS = [
  {
    source: 'Total Monthly Unique Website Visitors',
    target: 'CTA Success',
    value: 2000,
  },
  {
    source: 'Total Monthly Unique Website Visitors',
    target: 'CTA Failure',
    value: 8000,
  },
  {
    source: 'CTA Success',
    target: 'Learn More Form',
    value: 500,
  },
  {
    source: 'CTA Success',
    target: 'Verify Insurance Form',
    value: 250,
  },
  {
    source: 'CTA Success',
    target: 'Call Now Button',
    value: 400,
  },
  {
    source: 'CTA Success',
    target: 'Chat Now Widget',
    value: 750,
  },
  {
    source: 'CTA Success',
    target: 'Email Now Button',
    value: 100,
  },

  // Learn More Form
  {
    source: 'Learn More Form',
    target: 'Form Submission Fail',
    value: 100,
  },
  {
    source: 'Learn More Form',
    target: 'Form Submission Success',
    value: 400,
  },
  {
    source: 'Form Submission Success',
    target: 'Form Qualification Success',
    value: 200,
  },
  {
    source: 'Form Submission Success',
    target: 'Form Qualification Fail',
    value: 200,
  },
  {
    source: 'Form Qualification Success',
    target: 'Phone Calls Outbound',
    value: 200,
  },
  {
    source: 'Phone Calls Outbound',
    target: 'Call not-connected',
    value: 100,
  },
  {
    source: 'Phone Calls Outbound',
    target: 'Call connected',
    value: 100,
  },
  {
    source: 'Call connected',
    target: 'Call Qualification Success',
    value: 50,
  },
  {
    source: 'Call connected',
    target: 'Call Qualification Fail',
    value: 50,
  },

  // Verify Insurance Form
  {
    source: 'Verify Insurance Form',
    target: 'Form Submission Fail',
    value: 100,
  },
  {
    source: 'Verify Insurance Form',
    target: 'Form Submission Success',
    value: 400,
  },
  {
    source: 'Form Submission Success',
    target: 'Form Qualification Success',
    value: 200,
  },
  {
    source: 'Form Submission Success',
    target: 'Form Qualification Fail',
    value: 200,
  },
  {
    source: 'Form Qualification Success',
    target: 'Phone Calls Outbound',
    value: 200,
  },
  {
    source: 'Phone Calls Outbound',
    target: 'Call not-connected',
    value: 100,
  },
  {
    source: 'Phone Calls Outbound',
    target: 'Call connected',
    value: 100,
  },
  {
    source: 'Call connected',
    target: 'Call Qualification Success',
    value: 50,
  },
  {
    source: 'Call connected',
    target: 'Call Qualification Fail',
    value: 50,
  },
]
