import React from 'react'

import { ICONS, COLORS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import EmailInput from '../../components/Forms/EmailInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import InviteStatus from '../../components/Statuses/InviteStatus'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import Grid from '../../components/Grid'
import Label from '../../components/Label'
import Flex from '../../components/Flex'
import Status from '../../components/Status'

import InvitedClientCard from '../../components/Cards/InvitedClient'

// from the APP side
import { useCreate, useUpdate } from '../../hooks/useNewAPI'
import { getClientLink } from '../../utils/functions'

import ClientNotificationLogsTable from './ClientNotificationLogsTable'

const RootClientPortalInviteOverlay = (props: any) => {
  const { id, close, data, form, initialModel, isOverlayLoading, onValidationUpdate, isDeleting, deleteRecord } = useOverlay({
    name: 'invite',
    endpoint: '/invites',
    invalidate: 'invites',
    options: props,
  })

  const { mutateAsync: asyncResend, isLoading: isResending }: any = useCreate({
    name: ['invite', id],
    url: `/invites/${id}/reprocess`,
    invalidateKeys: ['client', 'clients', 'invites'],
  })

  const { mutateAsync: revokeRecord, isLoading: isRevoking }: any = useUpdate({
    name: ['invite', id],
    url: `/invites/${id}`,
    invalidateKeys: ['client', 'clients', 'invites'],
  })

  const { timezone, isEHRApp } = useSettings()

  const resendInvite = async () => {
    try {
      await asyncResend()
    } catch (error) {
      console.debug(error)
    }
  }

  const revokeInvite = async () => {
    try {
      await revokeRecord({ status: 'cancelled' })
    } catch (error) {
      console.debug(error)
    }
  }

  if (isOverlayLoading) return <OverlayLoader position="right" />

  const sentVia = !!data.sent_to_details?.email ? 'Email' : 'Text Message'

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon={ICONS.employees} title="Client Portal Invite" subtitle={<InviteStatus status={data?.status} />} />

      <Overlay.SubHeader>
        <Flex gap="0.5rem" centerY>
          <span>Invited Via:</span>
          <Status inline color="blue" label={sentVia} />
        </Flex>
      </Overlay.SubHeader>

      <Overlay.Content>
        <Form isEditable={false} getForm={form} timezone={timezone} initialModel={initialModel} onValidationUpdate={onValidationUpdate}>
          <Section>
            <FormSection>
              <Input label="Full Name" model="sent_to_details.full_name" />

              <EmailInput label="Email" model="sent_to_details.email" />
              <PhoneInput label="Cell Number" model="sent_to_details.phone_no" />

              <Grid>
                <Label label="Invited Client" />
                <InvitedClientCard to={isEHRApp ? getClientLink(data.sent_to_record) : null} client={data.sent_to_record} />
              </Grid>

              <DateTimeInput label="Date Invited" model="created_at" />
            </FormSection>
          </Section>

          <Section>
            <FormSection heading="Notifications">
              <ClientNotificationLogsTable data={data?.notification_logs} />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {data?.status !== 'accepted' && (
          <ConfirmDialog
            title="Re-send Invite?"
            message="Are you sure you want to re-send the Invite?"
            yesColor="green"
            onYes={resendInvite}
          >
            <Button label="Re-send Invite" glyph="reset" color="blue" isLoading={isResending} />
          </ConfirmDialog>
        )}

        {(data?.status === 'pending' || data?.status === 'declined') && (
          <ConfirmDialog title="Revoke Invite?" message="Are you sure you want to revoke this invite?" yesColor="gray" onYes={revokeInvite}>
            <Button label="Revoke" glyph="decline" color="gray" fullWidth isLoading={isRevoking} />
          </ConfirmDialog>
        )}

        <DeleteDialog
          title="Delete Invite?"
          message="Are you sure you want to delete this invite? This action cannot be undone."
          onYes={deleteRecord}
        >
          <Button label="Delete" type="default" glyph="delete" color="red" fullWidth isLoading={isDeleting} />
        </DeleteDialog>
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClientPortalInviteOverlay = withOverlayError(RootClientPortalInviteOverlay)
