import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useAPI } from '@behavehealth/hooks'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'
import withSettings from '@behavehealth/hocs/withSettings'

import { Button, Card, HelpTagIframe, Page } from '@behavehealth/components'
import ClaimsTable from '@behavehealth/components/Tables/ClaimsTable'

const pageConfig = {
  feature: 'claims',
  help: <HelpTagIframe id="claims" />,
  marketingID: 'claims',
}

const Claims: React.FC = () => {
  const match = useRouteMatch()
  const data = useSelector((state) => state.data.insurance_claims?.data)
  const loading = useSelector((state) => state.data.insurance_claims?.loading)

  useAPI('insurance_claims', `/residents/${match.params?.resource_id}/insurance_claims`)

  return (
    <Page
      breakpoint="0"
      actions={
        <>
          <Button
            type="default"
            glyph="add"
            label="Create Claim From Rosters"
            link={`${match.url}/claim-builder`}
            // permission="claims.create"
          />
          <Button
            type="primary"
            glyph="add"
            label="Add New Claim"
            link={`${match.url}/new`}
            // permission="claims.create"
          />
        </>
      }
      {...pageConfig}
    >
      <Card>
        <ClaimsTable
          data={data}
          to={(id) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
        />
      </Card>
    </Page>
  )
}

export default withPageError(withSettings(withMarketing(Claims, pageConfig)))
