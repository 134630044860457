import React from 'react'
import { useParams } from 'react-router-dom'

import { useSettings } from '../../../../hooks/useSettings'
import { PlainSwitch } from '../../../../components/Forms/Switch'

import Alert from '../../../../components/Alert'
import Button from '../../../../components/Button'
import Card from '../../../../components/Card'
import DataList from '../../../../components/DataList'
import Divider from '../../../../components/Divider'
import Glyph from '../../../../components/Glyph'
import Icon from '../../../../components/Icon'
import Label from '../../../../components/Label'
import ObjectSelector from '../../../../components/Forms/Selectors/Object/ObjectSelector'
import Radio from '../../../../components/Forms/Radio'
import RadioGroup from '../../../../components/Forms/RadioGroup'
import SummonOverlay from '../../../../components/SummonOverlay'

import { LocalAIInputOverlay } from '../../../AIInputs/LocalAIInputOverlay'
import { useFormBuilder } from '../../useFormBuilder'

export const AIInputEditor = (props: any) => {
  const { activeElement, editElementConfig } = props

  const { id: formId }: any = useParams()
  const { isBehave, isHQApp } = useSettings()

  const localAIInputs: any = useFormBuilder((state: any) => state.localAIInputs)
  const globalAIInputs: any = useFormBuilder((state: any) => state.globalAIInputs)

  const upsertLocalAIInput: any = useFormBuilder((state: any) => state.upsertLocalAIInput)
  const deleteLocalAIInput: any = useFormBuilder((state: any) => state.deleteLocalAIInput)

  const [localInputId, setLocalInputId] = React.useState(activeElement?.config?.local_ai_input_value || null)
  const [globalInputId, setGlobalInputId] = React.useState(activeElement?.config?.global_ai_input_value || null)

  const localAIInput = React.useMemo(() => localAIInputs?.find((o) => o.external_id === localInputId), [localAIInputs, localInputId])

  if (!(isBehave || isHQApp)) return null

  if (!activeElement?.config) return null

  const { ai_input_enabled, ai_input_category } = activeElement.config

  const toggleAIInput = () => {
    editElementConfig({
      uuid: activeElement.uuid,
      config: {
        ai_input_enabled: !ai_input_enabled,
      },
    })
  }

  return (
    <div>
      <Divider />

      <h4 className="text-[0.88rem] text-text mb-2 font-[700] tracking-[1px] uppercase">AI Input</h4>

      <Card className="px-3 py-1">
        <div className="flex items-center flex-nowrap cursor-pointer py-1.5">
          <PlainSwitch size={100} isChecked={ai_input_enabled} onCheckedChange={toggleAIInput} />

          <Label
            isCompact
            label="Enable AI Input"
            onClick={toggleAIInput}
            className="whitespace-nowrap truncate min-w-0 flex-[1_1_auto] ml-2 cursor-pointer"
          />
        </div>

        {ai_input_enabled && (
          <div className="grid gap-3 mt-2 pb-2">
            {formId === 'new' ? (
              <div className="grid gap-2 place-items-center p-4">
                <Icon icon="ai_prompts" size={20} />
                <h4>AI Inputs</h4>
                <Alert small glyph="info" type="warning">
                  Please save the form first to use AI Inputs
                </Alert>
              </div>
            ) : (
              <>
                <RadioGroup
                  defaultValue="global"
                  value={ai_input_category}
                  layout="horizontal-dense"
                  className="flex-[1_1_auto] w-full"
                  onUpdate={({ value }) => {
                    editElementConfig({
                      uuid: activeElement.uuid,
                      config: {
                        ai_input_category: value,
                      },
                    })
                  }}
                >
                  <Radio label="Behave AI Input" value="global" className="!flex-[1_1_auto]" />
                  <Radio label="Custom AI Input" value="local" className="!flex-[1_1_auto]" />
                </RadioGroup>

                {ai_input_category === 'global' && (
                  <ObjectSelector
                    apiData={globalAIInputs}
                    label="Behave AI Input"
                    icon="ai_prompts"
                    maxWidth="100%"
                    selectTitle={(o) => o.name}
                    selectDescription={(o) => o.description}
                    selectGraphic={(o) => <Glyph glyph={o.icon} color={o.color} size={18} />}
                    value={globalAIInputs?.find((o) => o.external_id === globalInputId) || null}
                    onUpdate={(input: any) => {
                      setGlobalInputId(input?.value?.external_id || null)

                      editElementConfig({
                        uuid: activeElement.uuid,
                        config: {
                          global_ai_input_value: input?.value?.external_id || null,
                        },
                      })
                    }}
                  />
                )}

                {ai_input_category === 'local' && (
                  <>
                    {localAIInput && (
                      <DataList isCompact>
                        <DataList.Item label="Name" value={localAIInput.name} />
                        <DataList.Item label="Description" value={localAIInput.description} />
                        <DataList.Item label="System Prompt" value={localAIInput.system_prompt} />
                        <DataList.Item label="Input Prompt" value={localAIInput.input_prompt} />
                        <DataList.Item label="Temperature" value={localAIInput.temperature} />
                        <DataList.Item label="Max Tokens" value={localAIInput.max_tokens} />
                      </DataList>
                    )}

                    <SummonOverlay
                      overlay={
                        <LocalAIInputOverlay
                          dataID={localAIInput?.id || 'new'}
                          category={isHQApp ? 'hq_template_form_input' : 'custom_form_input'}
                          referenceType={isHQApp ? 'internal_template' : 'form'}
                          referenceId={formId}
                          onSaveSuccessful={(newInput: any) => {
                            upsertLocalAIInput(newInput)
                            setLocalInputId(newInput.external_id)

                            editElementConfig({
                              uuid: activeElement.uuid,
                              config: {
                                local_ai_input_value: newInput?.external_id || null,
                              },
                            })
                          }}
                          onDeleteSuccessful={() => {
                            deleteLocalAIInput(localInputId)
                            setLocalInputId(null)

                            editElementConfig({
                              uuid: activeElement.uuid,
                              config: {
                                local_ai_input_value: null,
                              },
                            })
                          }}
                        />
                      }
                    >
                      <Button
                        label={localAIInput ? 'Edit Custom AI Input' : 'Add Custom AI Input'}
                        glyph={localAIInput ? 'edit' : 'add'}
                        size={200}
                      />
                    </SummonOverlay>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </Card>
    </div>
  )
}
