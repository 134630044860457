import React from 'react'
import { connect } from 'react-redux'

import { getToken } from '../../../modules/axios'

import Button from '../../Button'
import DateTimeInput from '../../Forms/DateTimeInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Grid from '../../Grid'
import Input from '../../Forms/Input'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import QuickView from '../../QuickView'
import Section from '../../Section'
import Timeline from '../../Timeline/Timeline'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class File extends OverlayBase {
  componentDidMount = async () => {
    this.token = await getToken()
  }

  // RENDER
  renderHeader = () => <Overlay.Header icon="files" title="File" />

  renderContent = () => {
    const { $new, $editable } = this.state
    const { record, timezone, loading } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={record}
          isEditable={$editable}
          timezone={timezone}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
        >
          <Section>
            <FormSection layout="vertical">
              <Input model="title" label="Title" />
              <MultiObjectSelector model="file_tags" label="Tags" type="files.file_tags" />
              <ObjectSelector
                isEditable={false}
                model="author"
                label="Uploaded By"
                type="employees"
                icon="employees"
                selectTitle={(data) => data.name}
                selectDescription={(data) => data.position}
              />
              <DateTimeInput isEditable={false} model="created_at" label="Date Uploaded" />
              <DateTimeInput isEditable={false} model="updated_at" label="Last Edited" />
              <Input isEditable={false} model="content_type" label="Type" />
            </FormSection>
          </Section>

          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state
    const { record } = this.props

    return (
      <Overlay.Footer>
        {$editable && (
          <Flex stretchChildrenX gap={8}>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="3 1 auto"
              permission="files.edit"
            />

            {!$new && (
              <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} flex="0 1 auto" />
            )}
          </Flex>
        )}

        {!$editable && (
          <Grid gap={8}>
            <Flex stretchChildrenX gap={8}>
              <QuickView record={record}>
                <Button glyph="external_link" label="View File" type="primary" flex="2 1 auto" />
              </QuickView>

              <Button onClick={() => window.open(record.download)} glyph="download" label="Download" type="primary" color="green" />
            </Flex>

            <Flex stretchChildrenX gap={8}>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={this.props.loading}
                onClick={this.edit}
                flex="2 1 auto"
                permission="files.edit"
              />

              <DeleteDialog
                title="Delete File?"
                message="Are you sure you want to delete this file? This action cannot be undone."
                onYes={this.delete}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={this.props.loading}
                  fullWidth
                  permission="files.delete"
                />
              </DeleteDialog>
            </Flex>
          </Grid>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'documents')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'documents')

export { File }
export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(File))
