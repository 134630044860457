import React from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import { isBlank } from '../utils/functions'
import { useSettings } from '../hooks/useSettings'
import Notifications from '../modules/notifications'
import usePermissionsStatus from '../hooks/usePermissionsStatus'

const DEBUG = false

export const ProtectedRouteV6 = ({ permission, featureFlag, children }: any) => {
  const navigate = useNavigate()
  const { allowed } = usePermissionsStatus({ permission: permission, featureFlag: featureFlag })

  const { isPortal } = useSettings()

  if (DEBUG) {
    console.log('Route Debug', {
      permission,
      featureFlag,
      allowed,
    })
  }

  // if there is no permission && feature flag set up, allow access
  if (isBlank(permission) && isBlank(featureFlag)) return children

  if (allowed === 'not_allowed') {
    Notifications.send('You do not have Permission to this page. Contact your Account Administrator for more details.', 'negative')

    navigate(isPortal ? '/app/dashboard' : '/dashboard')

    return null
  }

  if (allowed === 'unknown') return null
  if (allowed === 'allowed') return children
}
