import React from 'react'

import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import EmailInput from '../../components/Forms/EmailInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import StateSelect from '../../components/Forms/elements/StateSelect'
import Tabs from '../../components/Tabs'
import TimezoneInput from '../../components/Forms/TimezoneInput'

import { FacilitiesDataTable } from './FacilitiesDataTable'
import { useDataTable } from '../../components/DataTable/useDataTable'

const RootEHRAccountBuilderOverlay = (props: any) => {
  const { showBackdrop, onClose, onSave, onLink, initialModel, createButtonLabel = 'Create EHR', position = 'left' } = props

  const form = React.useRef(null)
  const [valid, setValid] = React.useState(false)

  const [isLoading, setIsLoading] = React.useState(false)

  const [tab, setTab] = React.useState('connect')

  const tableProps = useDataTable({
    name: ['facilities'],
    endpoint: `/facilities`,
    localStorageKey: 'facilities_selector_v1',
    options: { enabled: tab === 'connect' },
  })

  // const { mutateAsync: createEHR, isLoading }: any = useCreate({
  //   name: ['setup-ehr'],
  //   url: `/facilities/setup_ehr`,
  //   invalidateKeys: ['community_accounts'],
  // })

  const handleSave = async () => {
    setIsLoading(true)

    try {
      const formData = form.current?.getFormValue()
      await onSave?.(formData)

      onClose?.()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Overlay showBackdrop={showBackdrop} closeOnBackdrop position={position} onClose={onClose} maxWidth={tab === 'connect' ? 80 : 60}>
      <Overlay.Header title="Set Up EHR Account" icon="enterprise" />

      <Overlay.Content>
        <Tabs activeTab={tab} onChange={setTab} className="px-4">
          <Tabs.List>
            <Tabs.Item label="Connect Existing EHR Account" name="connect" />
            <Tabs.Item label="Add New EHR Account" name="create" />
          </Tabs.List>
        </Tabs>

        {tab === 'connect' && (
          <div className="px-4 py-3">
            <FacilitiesDataTable
              {...tableProps}
              onClick={(selected: any) => {
                onLink(selected)
                onClose()
              }}
            />
          </div>
        )}

        {tab === 'create' && (
          <Form
            isCompact
            isEditable
            getForm={form}
            onValidationUpdate={setValid}
            initialModel={initialModel}
            linked={{ connected_entity_id: initialModel.connected_entity_id }}
          >
            <Section>
              <FormSection maxWidth="100%">
                <Input
                  label="Company Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a company name',
                    },
                  }}
                />

                <div className="grid gap-4 grid-cols-1 mq600:grid-cols-4">
                  <StateSelect
                    label="State"
                    model="state"
                    validations={{
                      presence: {
                        message: 'Please select a state',
                      },
                    }}
                  />

                  <TimezoneInput
                    allowEmpty
                    label="Timezone"
                    model="timezone"
                    validations={{
                      presence: {
                        message: 'Please select a timezone',
                      },
                    }}
                  />

                  <Select
                    label="Account Type"
                    model="category"
                    defaultValue="healthcare_facility"
                    validations={{
                      presence: {
                        message: 'Please select an Account Type',
                      },
                    }}
                  >
                    <Option label="Healthcare Facility" value="healthcare_facility" />
                    <Option label="Healthcare Practice" value="healthcare_practice" />
                    <Option label="Support Facility" value="support_facility" />
                    <Option label="Support Practice" value="support_practice" />
                    <Option label="Provider Affiliate" value="provider_affiliate" />
                  </Select>

                  <Select label="Account Status" model="plan_status" defaultValue="community">
                    <Option label="Community" value="community" />
                    <Option label="Trial Requested" value="trial_requested" />
                    <Option label="Trialing" value="trialing" />
                    <Option label="Active" value="active" />
                    <Option label="Cancelled" value="canceled" />
                    <Option label="Unpaid" value="unpaid" />
                    <Option label="Archived" value="archived" />
                  </Select>

                  <Select label="Sales Status" model="sales_status">
                    <Option label="–" value={null} />
                    <Option label="Yes on Value" value="yes_on_value" />
                    <Option label="Yes on Price/Unpaid" value="yes_on_price_unpaid" />
                    <Option label="Onboarding" value="onboarding" />
                  </Select>
                </div>

                <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
                  <Input
                    label="First Name"
                    model="first_name"
                    validations={{
                      presence: {
                        message: 'Please enter a first name',
                      },
                    }}
                  />

                  <Input
                    label="Last Name"
                    model="last_name"
                    validations={{
                      presence: {
                        message: 'Please enter a last name',
                      },
                    }}
                  />
                </div>

                <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
                  <EmailInput
                    label="Email"
                    model="email"
                    validations={{
                      presence: {
                        message: 'Please enter an email',
                      },
                    }}
                  />

                  <PhoneInput label="Phone Number" model="phone_no" />
                </div>
              </FormSection>
            </Section>
          </Form>
        )}
      </Overlay.Content>

      {tab === 'create' && (
        <Overlay.Footer>
          <Button
            label={createButtonLabel}
            glyph="tick_circle"
            type="primary"
            color="green"
            onClick={handleSave}
            isDisabled={!valid}
            isLoading={isLoading}
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const EHRAccountBuilderOverlay = withOverlayError(RootEHRAccountBuilderOverlay)
