import React from 'react'
import size from 'lodash/size'

import { apiCreate, apiGet } from '../../../modules/api'

import Alert from '../../../components/Alert'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Grid from '../../../components/Grid'
import SendNotificationForm from '../../../components/Forms/elements/SendNotificationForm'
import SigneeCard from '../../../components/Cards/SigneeCard'
import State from '../../../components/State'
import Workflow from '../../../components/Workflow/Workflow'

const NotifySignees = ({ match, setStatus }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)

  const form = React.useRef()
  const [valid, setValid] = React.useState(false)
  const [requestLoading, setRequestLoading] = React.useState(false)
  const { resource_id } = match.params

  const [signees, setSignees] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const isEmpty = size(signees) === 0

  React.useEffect(() => {
    const request = async () => {
      try {
        const res = await apiGet({
          url: `/residents/${resource_id}/signees?only_with_contracts=true`,
        })
        setSignees(res.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    request()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onContinue = async () => {
    setRequestLoading(true)

    await apiCreate({
      name: 'agreements',
      url: `/residents/${resource_id}/agreements/notifications`,
      params: form.current.getFormValue(),
    })

    setRequestLoading(false)

    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  if (loading) return <State isLoading />

  if (isEmpty) {
    return (
      <FormSection maxWidth="100%">
        <Alert glyph="warning" type="warning">
          Add at least one agreement to view the signee notification options, or skip this step.
        </Alert>

        <Workflow.SkipButton onClick={onSkip} />
      </FormSection>
    )
  }

  return (
    <Form getForm={form} onValidationUpdate={setValid}>
      <FormSection maxWidth="100%">
        <Alert glyph="info">Send agreements to signees via email / SMS</Alert>

        <Grid gap="1.75rem">
          {signees?.map((signee) => (
            <Grid key={signee.id} gap="0.75rem">
              <SigneeCard signee={signee} variant="variant-plain" />

              <SendNotificationForm
                model={`notifications.${signee?.id}`}
                email={signee?.signer?.email}
                phoneNo={signee?.signer?.phone_no}
                firstName={signee?.signer?.first_name}
              />
            </Grid>
          ))}
        </Grid>

        <Workflow.Buttons>
          <Workflow.ContinueButton
            label="Send Notifications & Continue →"
            onClick={onContinue}
            isDisabled={!valid}
            isLoading={requestLoading}
          />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default NotifySignees
