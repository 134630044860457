import React from 'react'
import clsx from 'clsx'

type Props = {
  flex?: string | number
  width?: string | number
  isActive?: boolean
  centerContent?: boolean
  innerCSS?: object
} & React.HTMLAttributes<any>

const Scroll = React.forwardRef<HTMLElement, Props>((props, ref) => {
  const { flex, width, children, isActive, centerContent, innerCSS, className } = props

  const classNames = clsx({
    'is-active': isActive,
    [className]: className,
  })

  const innerClassNames = clsx({
    'is-centered': centerContent,
  })

  return (
    <div
      ref={ref}
      className={classNames}
      css={[
        styles.outer,
        {
          flex: flex,
          flexBasis: width,
          flexGrow: width ? 0 : 1,
        },
      ]}
    >
      <div className={innerClassNames} css={[styles.inner, innerCSS]}>
        {children}
      </div>
    </div>
  )
})

const styles: any = {
  outer: {
    flexShrink: 1,
    minHeight: '100%',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',

    '&.is-active': {
      overflowY: 'auto',
      overflowX: 'hidden',
      WebkitOverflowScrolling: 'touch',
    },
  },

  inner: {
    minHeight: '100%',

    '&.is-centered': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: ' center',
    },
  },
}

Scroll.defaultProps = {
  isActive: true,
  flex: '1 1 auto',
  width: 'auto',
}

export default Scroll
