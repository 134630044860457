import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { FormSubmissionsDataTable } from '@behavehealth/constructs/FormSubmissions/FormSubmissionsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { COLORS } from '@behavehealth/theme'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

export const ContactsFormSubmissions = () => {
  const { resource_id }: any = useParams()
  const match = useRouteMatch()

  const tableProps = useDataTable({
    name: ['organization', resource_id, 'form_submissions'],
    endpoint: `/organizations/${resource_id}/form_submissions`,
    updateDeleteEndpoint: `/form_submissions`,
    localStorageKey: 'form_submissions_v2',
  })

  const { data: forms }: any = useGet({ name: 'forms', url: `/forms` })

  const { data: organization }: any = useGet({
    name: ['organization', resource_id],
    url: `/organizations/${resource_id}`,
  })

  return (
    <Page
      title="Organization Contacts Form Inbox"
      icon="web_form"
      actions={
        <>
          <Button label="Manage Forms" glyph="settings" type="minimal" link="/settings/forms-builder" featureFlagV2="forms_builder" />

          {organization && size(forms) >= 1 && (
            <Dropdown label="Add New Form" glyph="add" buttonType="primary">
              {forms.map((form: any) => (
                <div css={STYLES.dropdownItemWrapper}>
                  <DropdownItem
                    icon="web_form"
                    label={form.name}
                    link={{
                      pathname: `${location.pathname}/new-${form.id}`,
                      parent: match,
                    }}
                    css={STYLES.dropdownItem}
                  />
                </div>
              ))}
            </Dropdown>
          )}
        </>
      }
    >
      <Grid>
        <FormSubmissionsDataTable
          asCard
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'form_submissions.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

const STYLES = {
  dropdownItemWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  dropdownItem: {
    flex: '1 1 auto',
    borderRight: `1px solid ${COLORS.divider}`,
  },
}
