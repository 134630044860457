import React from 'react'

import ContextShow from '../ContextShow'
import Input from '../Input'
import Option from '../Option'
import Select from '../Select'

const ReligiousPreferenceSelect = ({ label, model, isRequired }) => (
  <>
    <Select
      allowEmpty
      label={label}
      model={model}
      validations={
        isRequired && {
          presence: {
            message: 'Please select a religious preference',
          },
        }
      }
    >
      <Option label="Decline to respond" value="decline_to_respond" />
      <Option label="Christian" value="christian" />
      <Option label="Catholic" value="catholic" />
      <Option label="Baptist" value="baptist" />
      <Option label="Protestant" value="protestant" />
      <Option label="Methodist/Wesleyan" value="methodist_wesleyan" />
      <Option label="Lutheran" value="lutheran" />
      <Option label="Presbyterian" value="presbyterian" />
      <Option label="Pentecostal/Charismatic" value="pentecostal_charismatic" />
      <Option label="Episcopalian/Anglican" value="episcopalian_anglican" />
      <Option label="Mormon/Latter-Day Saints" value="mormon_latter_day_saints" />
      <Option label="Churches of Christ" value="churches_of_christ" />
      <Option label="Jehovah's Witness" value="jehovah_s_witness" />
      <Option label="Seventh-Day Adventist" value="seventh_day_adventist" />
      <Option label="Assemblies of God" value="assemblies_of_god" />
      <Option label="Holiness/Holy" value="holiness_holy" />
      <Option label="Congregational/United Church of Christ" value="congregational_united_church_of_christ" />
      <Option label="Church of the Nazarene" value="church_of_the_nazarene" />
      <Option label="Church of God" value="church_of_god" />
      <Option label="Orthodox (Eastern)" value="orthodox_eastern" />
      <Option label="Evangelical/Born Again" value="evangelical_born_again" />
      <Option label="Mennonite" value="mennonite" />
      <Option label="Christian Science" value="christian_science" />
      <Option label="Church of the Brethren" value="church_of_the_brethren" />
      <Option label="Nondenominational" value="nondenominational" />
      <Option label="Disciples of Christ" value="disciples_of_christ" />
      <Option label="Reformed/Dutch Reform" value="reformed_dutch_reform" />
      <Option label="Apostolic/New Apostolic" value="apostolic_new_apostolic" />
      <Option label="Quaker" value="quaker" />
      <Option label="Christian Reform" value="christian_reform" />
      <Option label="Foursquare Gospel" value="foursquare_gospel" />
      <Option label="Independent Christian Church" value="independent_christian_church" />
      <Option label="Other Christian" value="other_christian" />
      <Option label="Jewish" value="jewish" />
      <Option label="Muslim" value="muslim" />
      <Option label="Buddhist" value="buddhist" />
      <Option label="Unitarian/Universalist" value="unitarian_universalist" />
      <Option label="Hindu" value="hindu" />
      <Option label="Native American" value="native_american" />
      <Option label="Sikh" value="sikh" />
      <Option label="Wiccan" value="wiccan" />
      <Option label="Pagan" value="pagan" />
      <Option label="Spiritualist" value="spiritualist" />
      <Option label="Atheist" value="atheist" />
      <Option label="Agnostic" value="agnostic" />
      <Option label="Humanist" value="humanist" />
      <Option label="No Religion" value="no_religion" />

      <Option label="Other" value="other" />
    </Select>

    <ContextShow when={model} is="other">
      <Input
        label="Please add your religious preference"
        model={`${model}_other`}
        validations={
          isRequired && {
            presence: {
              message: 'Please enter a religious preference',
            },
          }
        }
      />
    </ContextShow>
  </>
)

ReligiousPreferenceSelect.defaultProps = {
  label: 'Religious Preference',
  model: 'religious_preference',
}

export default ReligiousPreferenceSelect
