import React from 'react'

import { useSettings } from '../../hooks'

import DataFormStatus from '../Statuses/DataFormStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const TITLES: any = {
  gad7: 'GAD-7 Anxiety',
  phq9: 'PHQ-9 Depression',
  phq15: 'PHQ-15 Somatic / Physical Symptoms',
  dast10: 'DAST-10 Drug Abuse Screening Test',
  mdi: 'MDI Major Depression Inventory',
  bam: 'BAM Brief Addiction Monitor',
  audit_c: 'AUDIT-C',
  pclc: 'PCL-C PTSD CheckList Civilian Version',
  ace_score: 'ACE Score',
  asrm: 'ASRM Altman Self-Rating Mania Scale',
  asi_lite: 'ASI The Addiction Severity Index (Lite)',
  pdss: 'PDSS Panic Disorder Severity Scale (Self Report)',
  barc10: 'BARC-10 Brief Assessment of Recovery Capital',
  whoqol: 'The World Health Organization Quality of Life (WHOQOL)-BREF',
  asi5: 'ASI-5 Addiction Severity Index',
  dow: 'Dimensions of Wellness',
}

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink to={to(row.original.id, row.original.subcategory)} label={value || TITLES[row.original?.subcategory]} />
    ),
  },
  {
    width: 200,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value, row }: any) => <DataFormStatus status={row.original.data?.temp?.status || value} />,
  },
  {
    width: 200,
    accessor: 'score',
    Header: 'Score',
    Cell: ({ value }: any) => {
      return <TableCell value={value} />
    },
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  help?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
  isBehave?: boolean
}

const ClinicalMeasurementsTable = (props: Props) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    help,
    hiddenColumns,
    isLoading,
    batchActionsConfig,
    localStorageKey,
    title,
    to,
    titleAfter,
    isBehave,
  } = props

  const { timezone } = useSettings()

  return (
    <Table
      testKey="clinical_measurements_table"
      title={title}
      titleAfter={titleAfter}
      icon="clinical_measurements"
      data={data}
      columns={columns(to, timezone)}
      hiddenColumns={hiddenColumns}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      help={help}
      isBehave={isBehave}
    />
  )
}

ClinicalMeasurementsTable.defaultProps = {
  title: 'Clinical Measures',
  emptyDescription: 'No clinical measurements added yet',
  localStorageKey: 'clinical_measurements',
}

export default ClinicalMeasurementsTable
