import React from 'react'

import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Checkbox from '../../../components/Forms/Checkbox'

import { InlineInsuranceBillingFormSection } from './InlineInsuranceBillingFormSection'

export const InsuranceBillingFormSection = ({ initialModel, isNew, feeScheduleModel, model }: any) => {
  const [isBillable, setIsBillable] = React.useState(initialModel?.is_billable || false)

  return (
    <>
      <CheckboxGroup label="Insurance" trueIcon="check" falseIcon="cross" falseStyle="faded">
        <Checkbox defaultValue={false} label="Set as Billable" model="is_billable" onUpdate={(state: any) => setIsBillable(state.value)} />
      </CheckboxGroup>

      {isBillable && (
        <InlineInsuranceBillingFormSection
          isBillable
          isNew={isNew}
          feeScheduleModel={feeScheduleModel}
          model={model}
          initialModel={initialModel}
        />
      )}
    </>
  )
}
