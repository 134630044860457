import React from 'react'

import Glyph from '../../Glyph'
import clsx from 'clsx'

type Props = {
  className: string
  falseGlyph: string
  falseVariant: 'faded' | 'linethrough' | 'faded-linethrough'
  isTrue: boolean
  label: string
  trueGlyph: string
}

const TrueFalse: React.FC<Props> = (props) => {
  const { className, falseGlyph, falseVariant, isTrue, label, trueGlyph } = props

  const classNames = clsx({
    [falseVariant]: !isTrue && falseVariant,
    [className]: className,
  })

  return (
    <span css={styles} className={classNames}>
      <Glyph glyph={isTrue ? trueGlyph : falseGlyph} size="1rem" css={{ marginRight: '0.25rem' }} />
      <span>{isTrue ? 'Yes' : 'No'}</span>
      <span>{label}</span>
    </span>
  )
}

const styles = {
  display: 'inline-flex',
  alignItems: 'center',
}

TrueFalse.defaultProps = {
  trueGlyph: 'check',
  falseGlyph: 'cross',
  falseVariant: 'faded-linethrough',
}

export default TrueFalse
