import React from 'react'

import withSettings from '../../../hocs/withSettings'
import { titleCase } from '../../../utils/functions'

import Table from '../../Table/Table'
import TableCell from '../../Table/TableCell'

const LOC: any = {
  medically_managed_intensive_inpatient_detoxification: '4-D Medically Managed Intensive Inpatient Detoxification',
  medically_managed_intensive_inpatient_services: '4 Medically Managed Intensive Inpatient Services',
  medically_monitored_inpatient_detoxification: '3.7-D Medically Monitored Inpatient Detoxification',
  medically_monitored_high_intensity_inpatient_services: '3.7 Medically Monitored High-Intensity Inpatient Services',
  clinically_managed_medium_intensity_residential_services: '3.5 Clinically Managed Medium-Intensity Residential Services',
  clinically_managed_population_specific_high_intensity_residential_services:
    '3.3 Clinically Managed Population-Specific High-Intensity Residential Services',
  clinically_managed_residential_detoxification: '3.2-D Clinically Managed Residential Detoxification',
  clinically_managed_low_intensity_residential_services: '3.1 Clinically Managed Low-Intensity Residential Services',
  residential_inpatient_detoxification: '3-D Residential/Inpatient Detoxification',
  partial_hospitalization_services: '2.5 Partial Hospitalization Services',
  intensive_outpatient_services: '2.1 Intensive Outpatient Services',
  ambulatory_detoxification_with_extended_on_site_monitoring: '2-D Ambulatory Detoxification With Extended On-Site Monitoring',
  ambulatory_detoxification_without_extended_on_site_monitoring: '1-D Ambulatory Detoxification Without Extended On-Site Monitoring',
  transitional_supportive_housing: 'Transitional / Supportive Housing (Sober Living)',
  outpatient_services: '1 Outpatient Services',
  early_intervention: '0.5 Early Intervention',
}

const columns = () => [
  {
    width: 240,
    // isSticky: true,
    // canToggleVisible: false,
    accessor: 'setting',
    Header: 'Setting',
    Cell: ({ value, row }: any) => <TableCell value={titleCase(value)} />,
  },
  {
    width: 260,
    accessor: 'level_of_care',
    Header: 'Level of Care',
    Cell: ({ value }: any) => <TableCell value={LOC[value]} />,
  },
  {
    width: 200,
    accessor: 'male_open_beds',
    Header: 'Male Open Beds',
  },
  {
    width: 200,
    accessor: 'female_open_beds',
    Header: 'Female Open Beds',
  },
  {
    width: 200,
    accessor: 'mixed_open_beds',
    Header: 'Mixed Open Beds',
  },
  {
    width: 200,
    accessor: 'total_open_beds',
    Header: 'Total Open Beds',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  moveBedsTo?: Function
  timezone: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const BedsVacancyAnalyticsTable = (props: Props) => {
  const {
    data = DATA,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    batchActionsConfig,
    moveBedsTo,
    title,
    to,
    timezone,
    titleAfter,
    ...rest
  } = props

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      groupBy="setting"
      columns={columns(to, moveBedsTo, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

BedsVacancyAnalyticsTable.defaultProps = {
  icon: 'bed_management',
  localStorageKey: 'beds_vacancy_analytics',
}

const DATA = [
  {
    id: 'a2688368',
    level_of_care: 'medically_managed_intensive_inpatient_detoxification',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'inpatient',
  },
  {
    id: '2d3ef09e',
    level_of_care: 'medically_managed_intensive_inpatient_services',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'inpatient',
  },
  {
    id: 'd8adef72',
    level_of_care: 'medically_monitored_inpatient_detoxification',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'inpatient',
  },
  {
    id: '236ea2fc',
    level_of_care: 'medically_monitored_high_intensity_inpatient_services',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'inpatient',
  },
  {
    id: '9ef74ace',
    level_of_care: 'clinically_managed_medium_intensity_residential_services',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'residential',
  },
  {
    id: 'c6488e92',
    level_of_care: 'clinically_managed_population_specific_high_intensity_residential_services',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'residential',
  },
  {
    id: '42f35f92',
    level_of_care: 'clinically_managed_residential_detoxification',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'residential',
  },
  {
    id: '5febf1e1',
    level_of_care: 'clinically_managed_low_intensity_residential_services',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'residential',
  },
  {
    id: '8ef1cb5d',
    level_of_care: 'residential_inpatient_detoxification',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'residential',
  },
  {
    id: '1d059f25',
    level_of_care: 'partial_hospitalization_services',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'outpatient',
  },
  {
    id: '01435825',
    level_of_care: 'intensive_outpatient_services',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'outpatient',
  },
  {
    id: '41c4e100',
    level_of_care: 'ambulatory_detoxification_with_extended_on_site_monitoring',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'outpatient',
  },
  {
    id: '588c1442',
    level_of_care: 'ambulatory_detoxification_without_extended_on_site_monitoring',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'outpatient',
  },
  {
    id: '48e5fa84',
    level_of_care: 'transitional_supportive_housing',
    male_open_beds: 10,
    female_open_beds: 12,
    mixed_open_beds: 8,
    total_open_beds: 30,
    setting: 'other',
  },
]

export default withSettings(BedsVacancyAnalyticsTable)
