import React from 'react'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const OtherDiagnosisCodes = () => {
  return (
    <>
      <CheckboxGroup label="Other Diagnosis Codes" layout="vertical-dense">
        <Checkbox label="E10.9 Diabetes (Type 1)" model="data.diagnoses.other.E10_9" />
        <Checkbox label="E11.9 Diabetes (Type 2)" model="data.diagnoses.other.E11_9" />
        <Checkbox label="I10 Hypertension" model="data.diagnoses.other.I10" />
        <Checkbox label="F31.81 Bipolar II disorder" model="data.diagnoses.other.F31_81" />
        <Checkbox label="F31.9 Bipolar disorder unspecified" model="data.diagnoses.other.F31_9" />
        <Checkbox label="F33 Major depressive disorder, recurrent" model="data.diagnoses.other.F33" />
        <Checkbox label="F33.0 Major depressive disorder, recurrent, mild" model="data.diagnoses.other.F33_0" />
        <Checkbox label="F33.1 Major depressive disorder, recurrent, moderate" model="data.diagnoses.other.F33_1" />
        <Checkbox label="F33.40 Unspecified" model="data.diagnoses.other.F33_40" />
        <Checkbox label="F33.9 Major depressive disorder, recurrent, unspecified" model="data.diagnoses.other.F33_9" />

        <Checkbox label="F33.2 Major depressive disorder recurrent severe without psychotic features" model="data.diagnoses.other.F33_2" />
        <Checkbox label="F41.0 Panic Disorder (episodic paroxysmal anxiety)" model="data.diagnoses.other.F41_0" />
        <Checkbox label="F41.9 Anxiety disorder unspecified" model="data.diagnoses.other.F41_9" />
        <Checkbox label="F42 Obsessive-compulsive disorder" model="data.diagnoses.other.F42" />
        <Checkbox label="F43.0 Acute stress reaction" model="data.diagnoses.other.F43_0" />
        <Checkbox label="F43.21 Adjustment disorder with depressed mood" model="data.diagnoses.other.F43_21" />
        <Checkbox label="F43.22 Adjustment disorder with anxiety" model="data.diagnoses.other.F43_22" />
        <Checkbox label="F43.11 Post-traumatic stress disorder acute" model="data.diagnoses.other.F43_11" />
        <Checkbox label="F43.12 Post-traumatic stress disorder chronic" model="data.diagnoses.other.F43_12" />
        <Checkbox label="F43.10 Post-traumatic stress disorder unspecified" model="data.diagnoses.other.F43_10" />
        <Checkbox label="F50.9 Eating disorder unspecified" model="data.diagnoses.other.F50_9" />
        <Checkbox label="F60.0 Paranoid personality disorder" model="data.diagnoses.other.F60_0" />
        <Checkbox label="F60.2 Antisocial personality disorder" model="data.diagnoses.other.F60_2" />
        <Checkbox label="F60.3 Borderline personality disorder" model="data.diagnoses.other.F60_3" />
        <Checkbox label="F84.5 Asperger’s syndrome" model="data.diagnoses.other.F84_5" />
        <Checkbox label="F90.8 Attention-deficit hyperactivity disorder other type" model="data.diagnoses.other.F90_8" />

        <Checkbox label="Other" model="data.diagnoses.other.other" />
      </CheckboxGroup>

      <ContextShow when="data.diagnoses.other.other" is={true}>
        <SmartTextarea
          useDictation
          label="Other:"
          rows={2}
          model="data.diagnoses.other.other_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>
    </>
  )
}

export default OtherDiagnosisCodes
