import React from 'react'
import isUndefined from 'lodash/isUndefined'

import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Notifications from '../../modules/notifications'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import { TreatmentBookImportOverlay } from '../TreatmentBook/TreatmentBookImportOverlay'
import { TreatmentPlanRecordDates } from './form_elements/TreatmentPlanRecordDates'
import { TreatmentPlanRecordStatus } from './form_elements/TreatmentPlanRecordStatus'
import { ReviewOverlay, TreatmentPlanReviews } from './components/TreatmentPlanReviews'

const RootTreatmentPlanObjectiveOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    queryKey,
  } = useOverlay({
    name: 'treatment-objective',
    endpoint: '/treatment_objectives',
    invalidate: 'treatment-objectives',
    invalidateKeys: ['treatment-plan-updates', 'treatment-goals-objectives-interventions', 'treatment-intervention'],
    options: props,
  })

  const { canEdit, planId, planCategory, permissionBase, treatmentBookPermissionBase } = props

  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGet({
    name: ['treatment_plans', planId],
    url: `/treatment_plans/${planId}`,
    options: { enabled: !!planId },
  })

  const { timezone } = useSettings()

  const [isCompleting, setIsCompleting] = React.useState(false)

  const [name, setName] = React.useState<string>(initialModel?.name || '')
  const [description, setDescription] = React.useState<string>(initialModel?.description || '')

  const objectivesShowSettings = treatmentPlan?.settings?.sections?.objectives
  const usePlanReviews = treatmentPlan?.settings?.use_plan_reviews === true

  const showStart = isUndefined(objectivesShowSettings?.start_date) || objectivesShowSettings?.start_date === true
  const showTarget = isUndefined(objectivesShowSettings?.target_date) || objectivesShowSettings?.target_date === true
  const showCompletion = isUndefined(objectivesShowSettings?.completion_date) || objectivesShowSettings?.completion_date === true

  const showDates = showStart || showTarget || showCompletion

  const handleSave = () => {
    saveWithData({
      ...form.current.getFormValue(),
      name,
      description,
    })
  }

  React.useEffect(() => {
    if (!data) return

    setName(data.name)
    setDescription(data.description)
  }, [data])

  const formKey = React.useMemo(() => {
    if (!data) return null

    const goalKey = data.treatment_goal?.updated_at

    return `${data.updated_at}-${goalKey}`
  }, [data])

  if (isOverlayLoading || isLoadingTreatmentPlan) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="treatment_plans" title="Objective" />

      <Overlay.Content>
        <Form
          key={formKey}
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              category: planCategory,
              treatment_plan_id: planId,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              <h2>Objective</h2>

              {treatmentPlan?.settings?.instructions?.objective && (
                <Alert contrast glyph="info">
                  {treatmentPlan.settings.instructions?.objective}
                </Alert>
              )}

              {
                <ObjectSelector
                  label="Linked Goal"
                  dependentValue={planId}
                  type="treatment_plan.treatment_goals"
                  selectTitle={(data) => data.name || data.description}
                  model="treatment_goal"
                  validations={{
                    presence: {
                      message: 'Please select a parent goal',
                    },
                  }}
                />
              }

              <TreatmentPlanRecordStatus />

              <Input
                label="Name"
                value={name}
                onUpdate={({ value }) => {
                  setName(value)
                }}
              />

              <SmartTextarea
                useQuickText
                useDictation
                label="Description"
                value={description}
                onUpdate={({ value }) => {
                  setDescription(value)
                }}
              />

              {showDates && (
                <>
                  <Divider />
                  <TreatmentPlanRecordDates showStart={showStart} showTarget={showTarget} showCompletion={showCompletion} />
                </>
              )}

              <Divider />

              {usePlanReviews && (
                <TreatmentPlanReviews
                  planId={planId}
                  isNew={isNew}
                  reference={data}
                  invalidate={queryKey}
                  data={data?.treatment_reviews}
                  canEdit={canEdit}
                  permissionBase={permissionBase}
                  isEditable={isEditable}
                />
              )}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      {canEdit && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                glyph="check"
                label="Save"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={handleSave}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission={`${permissionBase}.edit`}
              />
              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <div className="grid gap-3">
              {data && data.status !== 'completed' && (
                <>
                  <Flex gap="0.75rem">
                    <SummonOverlay
                      portalType="z100"
                      overlay={
                        <ReviewOverlay
                          dataID="new"
                          initialData={{
                            parent_updates: { status: 'completed' },
                          }}
                          planId={planId}
                          reference={data}
                          invalidate={queryKey}
                          canEdit={canEdit}
                          permissionBase={permissionBase}
                        />
                      }
                    >
                      <Button
                        label="Mark as Completed"
                        glyph="tick_circle"
                        color="green"
                        type="default"
                        flex="1 1 auto"
                        isDisabled={isSaving || isDeleting}
                        isLoading={isCompleting}
                        permission={`${permissionBase}.edit`}
                      />
                    </SummonOverlay>
                  </Flex>

                  <Divider className="!my-2" />
                </>
              )}

              <Flex gap="0.75rem">
                <Button
                  label="Edit"
                  glyph="edit"
                  type="default"
                  isDisabled={isSaving || isDeleting}
                  onClick={edit}
                  flex="100 1 auto"
                  permission={`${permissionBase}.edit`}
                />

                <DeleteDialog
                  title="Delete Objective?"
                  message="Are you sure you want to delete this Objective? This action cannot be undone."
                  onYes={deleteRecord}
                  content={
                    <Alert small contrast type="negative" glyph="warning" className="mt-3">
                      <b>Please note:</b> all associated interventions will also be permanently removed.
                    </Alert>
                  }
                >
                  <Button
                    label="Delete"
                    type="default"
                    glyph="delete"
                    color="red"
                    isLoading={isDeleting}
                    isDisabled={isSaving || isDeleting}
                    permission={`${permissionBase}.edit`}
                    fullWidth
                  />
                </DeleteDialog>
              </Flex>
            </div>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const TreatmentPlanObjectiveOverlay = withOverlayError(RootTreatmentPlanObjectiveOverlay)
