import React from 'react'

export const ScrollContext = React.createContext({})

const onClick = (scrollTo, scrollToSection) => {
  if (scrollTo && scrollToSection) scrollTo(scrollToSection)
}

const withScrollContext = (Wrapped) => (props) => {
  const context = React.useContext(ScrollContext)
  return (
    <Wrapped
      {...props}
      registerSection={context.registerSection}
      registerContainer={context.registerContainer}
      updateActive={context.updateActive}
      scrollTo={context.scrollTo}
      sections={context.sections}
      localSections={context.localSections}
      onClick={() => {
        if (props.onClick) props.onClick()
        onClick(context.scrollTo, props.scrollToSection)
      }}
    />
  )
}

withScrollContext.displayName = 'withScrollContext'
export default withScrollContext
