import React from 'react'
import { useHistory } from 'react-router-dom'

import { FEATURES } from '../../theme'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useOverlay } from '../../hooks/useOverlay'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import InsurancePayerSelector from '../../components/Forms/elements/InsurancePayerSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'

const RootNewPayerOverlay = (props: any) => {
  const { close, form, initialModel, isInvalid, isSaving, onValidationUpdate, saveWithData } = useOverlay({
    name: 'insurance_payers',
    endpoint: '/insurance_local_payers',
    invalidate: 'insurance_payers',
    options: {
      ...props,
      dataID: 'new',
    },
  })

  const history = useHistory()

  const handleSave = () => {
    saveWithData({
      ...form.current.getFormValue(),
      avatar: null,
    })

    history.goBack()
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon={FEATURES.insurance_payers.icon} title="Import Payer" help={<HelpTagIframe id="import_payer" />} />

      <Overlay.Content>
        <Form getForm={form} initialModel={initialModel} onValidationUpdate={onValidationUpdate}>
          <Section>
            <FormSection>
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter the payer reference name',
                  },
                }}
              />

              <InsurancePayerSelector
                label="Connect Clearing House Payer"
                description="If you cannot find your Payer in this list please get in touch with us"
                icon="insurance"
                model="insurance_clearing_house_config"
                type="insurance_clearing_house_configs"
              />

              <Select label="Network Status" model="network_status" defaultValue="out_of_network">
                <Option label="Out Of Network" value="out_of_network" />
                <Option label="In Network" value="in_network" />
              </Select>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Add New Payer"
          glyph="check"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={handleSave}
          isDisabled={isInvalid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const NewPayerOverlay = withOverlayError(RootNewPayerOverlay)
