import React from 'react'
import isEqual from 'react-fast-compare'
import debounce from 'lodash/debounce'
import produce from 'immer'

import { validate } from './validators'
import { isDefined } from '../../utils/functions'
import snakeCase from 'lodash/snakeCase'

import Field from './Field'

const DEBUG = false
const DEFAULT_DEBOUNCE = 0

export default class FieldBase extends React.Component {
	fieldRef = null
	eventsQueue = []
	processedEventsQueue = []

	/*
    LIFECYCLE
  */
	componentDidMount = () => {
		// register element with the form
		this.queue({ type: 'REGISTRATION' })

		if (this.afterMount) this.afterMount(this.state)
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		if (this.updateType === 'RESET') {
			this.updateType = 'DATA'
			return true
		}

		if (
			nextProps.isEditable !== this.props.isEditable ||
			nextProps.layout !== this.props.layout ||
			nextProps.maxWidth !== this.props.maxWidth
		) {
			this.updateType = 'STATE'
			return true
		}

		if (!isEqual(this.state, nextState)) {
			this.updateType = 'DATA'
			return true
		}

		if (
			this.props.value !== nextProps.value ||
			this.props.defaultValue !== nextProps.defaultValue ||
			this.props.model !== nextProps.model ||
			this.props.isLoading !== nextProps.isLoading ||
			this.props.isDisabled !== nextProps.isDisabled ||
			!isEqual(nextProps.validations, this.props.validations) ||
			!isEqual(nextProps.tooltip, this.props.tooltip)
		) {
			this.updateType = 'PROPS'
			return true
		}

		if (
			this.props.placeholder !== nextProps.placeholder ||
			this.props.description !== nextProps.description ||
			this.props.labelAfter !== nextProps.labelAfter ||
			this.props.isDisabled !== nextProps.isDisabled ||
			this.props.min !== nextProps.min ||
			this.props.max !== nextProps.max ||
			this.props.color !== nextProps.color ||
			this.props.label !== nextProps.label ||
			this.props.count !== nextProps.count ||
			this.props.suffix !== nextProps.suffix ||
			this.props.rows !== nextProps.rows ||
			this.props.maxRows !== nextProps.maxRows ||
			this.props.children !== nextProps.children
		) {
			this.updateType = 'EXTERNAL'
			return true
		}

		return this.props.shouldAlwaysUpdate || false
	}

	componentDidUpdate = () => {
		if (this.updateType === 'STATE') return

		// states update
		if (this.updateType === 'DATA') this.debouncedUpdate()
		// internal update
		else if (this.updateType === 'PROPS') this.queue({ type: 'UPDATE' })
	}

	componentWillUnmount = () => {
		if (this.props.form && !this.props.skipDeregister) this.props.form.deregister(this.state)
	}

	/*
    CUSTOM FUNCTIONS
  */
	// EVENT BASED CHANGES
	onFocus = (event: any) => this.queue({ type: 'FOCUS', event })
	onBlur = (event: any) => this.queue({ type: 'BLUR', event })
	onChange = (event: any) => {
		if (this.props.processValueBeforeChange) {
			this.queue({ type: 'CHANGE_VALUE', value: this.props.processValueBeforeChange(event.target.value) })
		} else {
			this.queue({ type: 'CHANGE_VALUE', value: event.target.value })
		}
	}

	// ACTION BASED CHANGES
	reset = () => this.queue({ type: 'RESET' })
	onReset = () => this.queue({ type: 'RESET' }) // old name (backwards compatible)
	validate = () => this.queue({ type: 'VALIDATE' })
	onValidate = () => this.queue({ type: 'VALIDATE' }) // old name (backwards compatible)
	changeValue = (value: any) => this.queue({ type: 'CHANGE_VALUE', value })
	onHighlight = () => this.queue({ type: 'HIGHLIGHT' })
	scrollIntoView = () => {
		if (!this.fieldRef.current) return
		this.fieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
	}

	// Callbacks
	afterMount = () => {
		if (this.props.onUpdate) this.props.onUpdate(this.state)
	}

	afterChange = (newState: any) => {
		if (this.props.onChange) this.props.onChange(newState)
		if (this.props.onChanged) this.props.onChanged(newState)
		if (this.onChanged) this.onChanged(newState)
	}

	afterUpdate = () => {
		if (this.props.onUpdate) this.props.onUpdate(this.state)
		if (this.props.onUpdated) this.props.onUpdated(this.state)
		if (this.onUpdated) this.onUpdated(this.state)
	}

	afterFocus = (newState: any) => {
		if (this.props.onFocus) this.props.onFocus(newState)
	}

	afterBlur = (newState: any) => {
		if (this.props.onBlur) this.props.onBlur(newState)
	}

	debouncedUpdate = debounce(this.afterUpdate, DEFAULT_DEBOUNCE)

	// PROCESSING FUNCTIONS
	processUpdate = (_queueEvent: any) => {
		const vs = { ...this.props.defaultValidations, ...this.props.validations }

		const newState = produce(this.state, (draft: any) => {
			draft.model = this.props.model
			draft.value = this.props.value

			draft.isRequired = vs?.hasOwnProperty('presence')
			draft.defaultValue = this.props.defaultValue
		})

		this.setState({
			isHighlighted: false,
			model: this.props.model,
			value: newState.value,
			isRequired: newState.isRequired,
			defaultValue: newState.defaultValue
		})

		return newState
	}

	processChangeValue = (value: any) => {
		const newState = produce(this.state, (draft: any) => {
			draft.value = value
			draft.isPristine = false
			draft.isDirty = true
		})

		this.setState({
			value: value,
			isPristine: false,
			isDirty: true,
			isHighlighted: false
		})

		return newState
	}

	processFocus = (_queueEvent: any) => {
		const newState = produce(this.state, (draft: any) => {
			draft.isTouched = true
			draft.isUntouched = true
		})

		this.setState({
			isTouched: true,
			isUntouched: true,
			isHighlighted: false
		})

		return newState
	}

	processBlur = (_queueEvent: any) => {
		const newState = produce(this.state, (draft: any) => {
			draft.isBlur = true
		})

		this.setState({ isBlur: true, isHighlighted: false })

		return newState
	}

	processValidation = (queueEvent: any) => {
		const errors = validate(queueEvent.value, { ...this.props.defaultValidations, ...this.props.validations })

		const newState = produce(this.state, (draft: any) => {
			draft.isValid = errors.length ? false : true
			draft.isInvalid = errors.length ? true : false
			draft.errors = errors
		})

		// update local state
		this.setState({
			isValid: errors.length ? false : true,
			isInvalid: errors.length ? true : false,
			errors: errors
		})

		return newState
	}

	processReset = (_queueEvent: any) => {
		this.updateType = 'RESET'

		// check if the input has any initial data defined by itself or not
		const hasInitialData = isDefined(this.initialData)

		const newState = produce(this.state, (draft: any) => {
			// if input has initial data use that / otherwise use the calculated value
			if (hasInitialData) {
				// update each draft key based on keys found in the initial data
				Object.keys(this.initialData).map((key: string) => {
					draft[key] = this.initialData[key]
				})

				draft.isPristine = true
				draft.isDirty = false
			} else {
				let value = this.props.value
				if (!value) {
					const initialModelVal = this.props.form?.getInitialInputFieldValue(this.props.model)

					if (isDefined(initialModelVal)) value = initialModelVal
					else if (isDefined(this.props.defaultValue)) value = this.props.defaultValue
					else value = ''
				}

				draft.value = value
				draft.isPristine = true
				draft.isDirty = false
			}
		})

		// if input has initial data use that / otherwise use the calculated value
		if (hasInitialData) {
			this.setState({
				...this.initialData,
				value: this.props.form?.getInitialInputFieldValue(this.props.model),
				isPristine: newState.isPristine,
				isDirty: newState.isDirty
			})
		} else {
			this.setState({
				value: newState.value,
				isPristine: newState.isPristine,
				isDirty: newState.isDirty
			})
		}

		return newState
	}

	processHighlight = (_queueEvent: any) => {
		const newState = produce(this.state, (draft: any) => {
			draft.isHighlighted = true
		})

		this.setState({ isHighlighted: true })

		return newState
	}

	/*
    RENDER
  */
	editRender = () => null
	readOnlyRender = () => null

	// * Queue
	queue = (data: any) => {
		this.eventsQueue.push(data)
		if (DEBUG || this.props.debug)
			console.debug(`👉 ${data.type} `, { type: data.type, value: data.value, event: data.event })

		this.process()
	}

	// * Process
	process = () => {
		if (this.eventsQueue.length <= 0) return
		const isDebugging = DEBUG || this.props.debug
		let t0 = null

		if (isDebugging) t0 = performance.now()

		// process normal queue
		let event = this.eventsQueue.shift()

		let newState = this.state
		const shouldUpdateFormEngine = this.props.form && this.props.model

		// process
		switch (event.type) {
			case 'REGISTRATION':
				// update Form Engine
				if (shouldUpdateFormEngine) this.props.form.register(this.state)

				// run validation if we should validate on registration
				if (this.props.validateOn?.includes('registration')) this.queue({ type: 'VALIDATE' })

				break

			case 'UPDATE':
				newState = this.processUpdate(event)

				// update Form Engine
				if (shouldUpdateFormEngine) this.props.form.change(newState)

				// run validation if we should validate on update
				if (this.props.validateOn?.includes('update')) this.queue({ type: 'VALIDATE', value: newState.value })

				break

			case 'CHANGE_VALUE':
				newState = this.processChangeValue(event.value)

				// update Form Engine
				if (shouldUpdateFormEngine) this.props.form.change(newState)

				// run validation if we should validate on change value
				if (this.props.validateOn?.includes('change')) this.queue({ type: 'VALIDATE', value: newState.value })

				this.afterChange(newState)

				break

			case 'FOCUS':
				newState = this.processFocus(event)

				// update Form Engine
				if (shouldUpdateFormEngine) this.props.form.focus(newState)

				// run validation if we should validate on focus
				if (this.props.validateOn?.includes('focus')) this.queue({ type: 'VALIDATE', value: newState.value })

				this.afterFocus(newState)

				break

			case 'BLUR':
				newState = this.processBlur(event)

				// update Form Engine
				if (shouldUpdateFormEngine) this.props.form.blur(newState)

				// run validation if we should validate on blur
				if (this.props.validateOn?.includes('blur'))
					this.queue({ type: 'VALIDATE', value: newState.value, state: newState })

				this.afterBlur(newState)

				break

			case 'VALIDATE':
				newState = this.processValidation(event)

				// update Form Engine
				if (shouldUpdateFormEngine) this.props.form.validate(newState)

				break

			case 'HIGHLIGHT':
				newState = this.processHighlight(event)

				// update Form Engine
				// if (shouldUpdateFormEngine) this.props.form.reset(resetState)

				// run validation if we should validate on blur
				// this.queue({ type: 'VALIDATE', value: resetState.value })

				break

			case 'RESET':
				newState = this.processReset(event)

				// update Form Engine
				if (shouldUpdateFormEngine) this.props.form.reset(newState)

				// run validation if we should validate on blur
				// this.queue({ type: 'VALIDATE', value: newState.value })

				break
		}

		// Add Event to the Processed Queue
		this.processedEventsQueue.push(event)

		// Re-Update Form Input
		if (shouldUpdateFormEngine) this.props.form.updateInputState(newState)

		// DEBUG
		if (isDebugging) {
			console.debug(`✅ ${event.type} `, { event, state: newState, props: this.props })

			let t1 = performance.now()

			console.debug(`${event.type} took ` + (t1 - t0) + ' milliseconds.')
		}

		// go recursively through it until there is nothing to process
		this.process()
	}

	render() {
		const {
			autoFocus,
			className,
			layout,
			max,
			maxLength,
			maxWidth,
			min,
			model,
			placeholder,
			prefix,
			size,
			step,
			suffix,
			type,
			validations,
			inputGroupMaxWidth,
			after,
			testKey,
			isCompact,
			alwaysShowDescription,
			labelClassName,
			...rest
		} = this.props

		const { id, isValid, errors, smartValue, isHighlighted } = this.state

		const isRequired = validations?.hasOwnProperty?.('presence')

		const isEditable = this.props.isEditable && !this.props.isLocked

		return (
			<Field
				{...rest}
				id={id}
				errors={errors}
				isValid={isValid}
				isRequired={isRequired}
				isHighlighted={isHighlighted}
				layout={layout}
				smartValue={smartValue}
				className={className}
				maxWidth={maxWidth}
				testKey={testKey}
				getRef={(ref: any) => (this.fieldRef = ref)}
				isCompact={isCompact}
				alwaysShowDescription={alwaysShowDescription}
				labelClassName={labelClassName}
				quickLinksVendors={this.props.quickLinksVendors}
				quickLinksValue={this.state.value}
			>
				{isEditable && this.editRender()}
				{!isEditable && this.readOnlyRender()}
				{after}
			</Field>
		)
	}
}

FieldBase.defaultProps = {
	shouldAlwaysUpdate: false,
	isLocked: false,
	validateOn: 'blur-focus-change'
}
