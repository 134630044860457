import React from 'react'
import { connect } from 'react-redux'

import { apiCreate, apiUpdate } from '../../../modules/api'
import Notifications from '../../../modules/notifications'

import AddressInputs from '../../Forms/elements/AddressInputs'
import Alert from '../../Alert'
import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import Chotomate from '../../Chotomate'
import ContextShow from '../../Forms/ContextShow'
import DateInput from '../../Forms/DateInput'
import DateOfBirthInput from '../../Forms/DateOfBirthInput'
import Divider from '../../Divider'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import InsurancePlanTypeSelect from '../../Forms/elements/InsurancePlanTypeSelect'
import Link from '../../Link'
import Status from '../../Status'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import Permission from '../../Permission'
import PhoneInput from '../../Forms/PhoneInput'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import RelationshipToClientCodeSelector from '../../Forms/elements/RelationshipToClientCodeSelector'
import Section from '../../Section'
import Select from '../../Forms/Select'
import SummonOverlay from '../../SummonOverlay'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'
import Glyph from '../../Glyph'
import Grid from '../../Grid'
import SexSelect from '../../Forms/elements/SexSelect'

import RequestEvobOverlay from '../actions/RequestEvobOverlay'
import RequestVOBOverlay from '../actions/RequestVOBOverlay'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { ExportPDFButton } from '../../Buttons/ExportPDFButton'

const InsurancePayerSupport = ({ payer }: any) => (
	<Alert type="warning">
		<span>Features supported by {payer.name}:</span>
		<Grid className="!mt-1.5">
			<div>
				<Glyph glyph={payer?.supports?.eligibility ? 'check' : 'cross'} size="1.1rem" /> Electronic Verification of
				Benefits (eVOB)
			</div>
			<div>
				<Glyph glyph={payer?.supports?.claims ? 'check' : 'cross'} size="1.1rem" /> Claims Submission
			</div>
			<div>
				<Glyph glyph={payer?.supports?.secondary_claims ? 'check' : 'cross'} size="1.1rem" /> Secondary Claims
			</div>
			<div>
				<Glyph
					glyph={payer?.supports?.claim_status || payer?.supports?.claims_status ? 'check' : 'cross'}
					size="1.1rem"
				/>{' '}
				Claim Status
			</div>
		</Grid>
	</Alert>
)

class InsurancePolicy extends OverlayBase {
	runEVOB = async () => {
		const insurancePolicyID = this.state.params?.id || this.props.match?.params?.id

		try {
			await apiCreate({
				name: 'insurance_evobs',
				url: '/insurance_evobs',
				params: { insurance_policy_id: insurancePolicyID },
				notify: false
			})
			Notifications.send('eVOB request sent successfully', 'positive')
		} catch (error) {
			Notifications.send('eVOB request could not be sent. If the issue persists, please contact us.', 'negative')
		}
	}

	archivePolicy = async () => {
		this.setState({ isArchiving: true })

		try {
			await apiUpdate({
				name: 'insurance_policies',
				url: `/insurance_policies/${this.state?.id}`,
				params: { status: 'archived' },
				notify: false
			})

			Notifications.send('Insurance Policy Archived', 'positive')
		} catch (error) {
			Notifications.send('Insurance Policy Archive unsuccessful. If the issue persists, please contact us.', 'negative')
		} finally {
			this.setState({ isArchiving: false })
		}
	}

	unArchivePolicy = async () => {
		this.setState({ isArchiving: true })

		try {
			await apiUpdate({
				name: 'insurance_policies',
				url: `/insurance_policies/${this.state?.id}`,
				params: { status: 'active' },
				notify: false
			})

			Notifications.send('Insurance Policy Un-Archived', 'positive')
		} catch (error) {
			Notifications.send(
				'Insurance Policy Un-Archived unsuccessful. If the issue persists, please contact us.',
				'negative'
			)
		} finally {
			this.setState({ isArchiving: false })
		}
	}

	renderHeader = () => {
		const { tenant, record, initialData } = this.props
		const isTrialing = tenant?.plan_status === 'trialing'

		const status = record?.status || initialData?.status

		return (
			<>
				<Overlay.Header
					icon="insurance"
					title={this.state.$new ? 'Add Insurance Policy' : 'Insurance Policy'}
					subtitle={
						<Status
							label={status === 'active' ? 'Active' : 'Archived'}
							color={status === 'active' ? 'green' : 'grey'}
						/>
					}
				/>

				{!this.state.$editable && !isTrialing && (
					<Permission permission="clients.actions.export">
						<Overlay.SubHeader>
							<ExportPDFButton url={`/insurance_policies/${this.state.id}/pdf`} />
						</Overlay.SubHeader>
					</Permission>
				)}
			</>
		)
	}

	renderContent = () => {
		const { $editable, $new, params, insurance_local_payer } = this.state
		const { current, record, timezone, loading } = this.props

		const formModel = this.form.current?.getFormValue()

		return (
			<Overlay.Content>
				<Chotomate ready name="insurance_policy_overlay" />

				<Form
					getForm={this.form}
					timezone={timezone}
					initialModel={{
						...record,
						...params,
						...($new && {
							policy_holder: current?.encrypted_data?.address || {}
						})
					}}
					isEditable={$editable}
					onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
					decorate={(model: any) => ({
						...($new && { client_id: current?.id })
						// ...(model.insurance_global_payer?.api_encoded_id && {
						//   insurance_global_payer_id: model.insurance_global_payer.api_encoded_id,
						// }),
					})}
				>
					<Section>
						<Select label="Type" model="category" defaultValue="primary">
							<Option label="Primary" value="primary" />
							<Option label="Secondary" value="secondary" />
							<Option label="Tertiary" value="tertiary" />
							<Option label="Unknown" value="unknown" />
						</Select>
					</Section>

					<Divider />

					<Section title="Insurance Details">
						<FormSection>
							<ObjectSelector
								label="Insurance Payer"
								icon="insurance"
								type="insurance_local_payers"
								model="insurance_local_payer"
								description={
									<>
										Add / Import new <Link to="/payers">Insurance Payers</Link> if you cannot find it above
									</>
								}
								selectTitle={(data) => data.name}
								selectDescription={() => null}
								validations={{
									presence: {
										message: 'Please select an insurance payer'
									}
								}}
								onUpdate={(model: any) => {
									this.setState({ insurance_local_payer: model.object })
								}}
							/>

							{insurance_local_payer && <InsurancePayerSupport payer={insurance_local_payer} />}

							<Input label="Plan Name" model="plan" />

							<Input label="Insurance Group ID" model="group_id" />

							<Input
								label="Insurance Member ID (Policy Number)"
								model="member_id"
								validations={{
									presence: {
										message: 'Please enter a member ID'
									}
								}}
							/>

							<PhoneInput label="Insurance Phone Number" model="claims_phone_no" />

							<InsurancePlanTypeSelect model="plan_type" />
							<DateInput label="Policy Effective Date" model="effective_at" />
							<DateInput label="Policy Termination Date" model="terminates_at" />
							<DateInput label="Policy Reset Date" model="resets_at" />

							<Textarea useQuickText label="Notes" model="notes" />
						</FormSection>
					</Section>

					<Divider />

					<Section
						title="Policy Holder Details"
						aside={
							<CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
								<Checkbox label="Client is the Policy Holder" model="is_client_policy_holder" defaultChecked />
							</CheckboxGroup>
						}
					>
						<FormSection>
							<ContextShow when="is_client_policy_holder" is={false}>
								<Input
									label="First Name"
									model="policy_holder.first_name"
									validations={{
										presence: {
											message: 'Please enter a first name'
										}
									}}
								/>

								<Input label="Middle Name" model="policy_holder.middle_name" />

								<Input
									label="Last Name"
									model="policy_holder.last_name"
									validations={{
										presence: {
											message: 'Please enter a last name'
										}
									}}
								/>

								<DateOfBirthInput
									label="Date of Birth"
									model="policy_holder.dob"
									validations={{
										presence: {
											message: 'Please enter a Date of Birth'
										}
									}}
								/>
								<SexSelect isRequired label="Sex" model="policy_holder.sex" />

								<RelationshipToClientCodeSelector
									label="Relationship to Client"
									model="policy_holder.relationship_to_customer"
									value={this.state.policy_holder?.relationship_to_customer}
								/>

								<Input type="email" label="Email" model="policy_holder.email" />

								<Input type="tel" label="Phone Number" model="policy_holder.phone_no" />
							</ContextShow>

							<ContextShow when="is_client_policy_holder" is={true}>
								<Alert type="warning" className="!mt-4">
									Please confirm that the Client Address is correct
								</Alert>
							</ContextShow>

							<AddressInputs isRequired model="policy_holder" />
						</FormSection>
					</Section>

					<Divider />

					<Section title="Attachments">
						<Attachments label="Attachments" model="documents" labelAlign="top" labelJustify="top" />
					</Section>

					{!$new && (
						<>
							<Divider />

							<Section headingType="h2" title="Timeline">
								<Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
							</Section>
						</>
					)}
				</Form>
			</Overlay.Content>
		)
	}

	renderFooter = () => {
		const { $new, $editable, isInvalid, insurance_local_payer } = this.state
		const { tenant, record, current } = this.props

		const hasInsurancePayer =
			this.props.record?.insurance_local_payer?.id ||
			this.state.params?.insurance_local_payer?.id ||
			insurance_local_payer
		const canRunEVOB = record?.insurance_local_payer?.supports?.eligibility
		const isTenantBilledByBehave = tenant?.is_billed

		return (
			<Overlay.Footer>
				{$editable && (
					<>
						<Button
							label="Save"
							glyph="check"
							type="primary"
							color="green"
							isLoading={this.props.loading}
							onClick={this.save}
							isDisabled={isInvalid}
							flex="100 1 auto"
							permission="insurance_policies.create"
						/>
						{!$new && (
							<Button
								label="Cancel"
								glyph="cross"
								type="default"
								isDisabled={this.props.loading}
								onClick={this.cancel}
							/>
						)}
					</>
				)}

				{!$editable && (
					<>
						{!canRunEVOB && (
							<Alert contrast type="warning" glyph="info">
								<p css={{ margin: 0, fontWeight: 600 }}>Set Up NPI Details to Run eVOBs</p>
								<p css={{ margin: '0.2em 0 0' }}>
									Go to <Link to="/settings/providers">Settings → Providers</Link> to set up the NPI details.
								</p>
							</Alert>
						)}

						{hasInsurancePayer && canRunEVOB && (
							<div css={{ flex: '1 1 400px !important' }}>
								<SummonOverlay overlay={<RequestEvobOverlay insurancePolicy={record} />}>
									<Button
										label="Request New eVOB"
										color="green"
										glyph="circle_tick"
										permission="insurance_evobs.create"
									/>
								</SummonOverlay>
							</div>
						)}

						{isTenantBilledByBehave && (
							<div css={{ flex: '1 1 400px !important' }}>
								<SummonOverlay overlay={<RequestVOBOverlay client={current} />}>
									<Button label="Request Full VOB" glyph="behave_health" color="green" />
								</SummonOverlay>
							</div>
						)}

						<Button
							glyph="edit"
							label="Edit Insurance Policy"
							type="default"
							isDisabled={this.props.loading}
							onClick={this.edit}
							flex="100 1 auto"
							permission="insurance_policies.edit"
						/>

						{record?.status === 'archived' ? (
							<Button
								label="Un-Archive"
								glyph="delete"
								type="default"
								color="gray"
								onClick={this.unArchivePolicy}
								isLoading={this.state.isArchiving}
							/>
						) : (
							<Button
								label="Archive"
								glyph="delete"
								type="default"
								color="gray"
								onClick={this.archivePolicy}
								isLoading={this.state.isArchiving}
							/>
						)}
					</>
				)}
			</Overlay.Footer>
		)
	}
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'insurance_policies')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_policies')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(InsurancePolicy))
