import React from 'react'

import CardLink from './CardLink'
import Avatar from './Avatar'

type Props = {
  actions: React.ReactNode
  baseline: string | number
  children: React.ReactNode
  testKey: string
  glyph: string
  src: string
  to: any
  record?: any
  featureFlag: string | boolean
  permission: string | boolean
}

const CardLinkPhoto: React.FC<Props> = ({
  actions,
  baseline,
  children,
  testKey,
  glyph,
  src,
  to,
  featureFlag,
  permission,
  record,
  ...rest
}) => {
  return (
    <CardLink
      showChevron
      to={to}
      actions={actions}
      testKey={testKey}
      permission={permission}
      featureFlag={featureFlag}
      baseline={baseline}
      children={children}
      graphic={
        <Avatar
          magnify
          initials={record?.name}
          isEditable={false}
          size={48}
          src={src}
          recordId={record?.id}
          recordType={record?.type}
          className="!m-1"
        />
      }
      {...rest}
    />
  )
}

const photoStyles = (src: string) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: '0.75rem',
  bottom: 0,

  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
})

CardLinkPhoto.defaultProps = {
  glyph: 'photo',
  baseline: '5.5rem',
}

export default CardLinkPhoto
