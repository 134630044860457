import React from 'react'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

import { COLORS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'

import Button from '../Button'
import State from '../State'
import Type from '../Typography/Type'

import { AppSidebarContent } from './AppSidebarContent'
import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

const BookmarksView: React.FC<any> = () => {
  const { data: bookmarks, isLoading }: any = useGet({
    name: ['bookmarks'],
    url: `/bookmarks`,
  })

  const isEmpty = size(bookmarks) === 0

  const personalBookmarks = sortBy(
    bookmarks?.filter((bookmark: any) => bookmark.category === 'personal'),
    'order',
  )
  const companyBookmarks = sortBy(
    bookmarks?.filter((bookmark: any) => bookmark.category === 'company'),
    'order',
  )

  const hasPersonalBookmarks = size(personalBookmarks) > 0
  const hasCompanyBookmarks = size(companyBookmarks) > 0

  return (
    <AppSidebarView>
      <AppSidebarHeader
        title="Bookmarks"
        icon="bookmarks"
        actions={!isEmpty && <Button hideLabel color="text" glyph="settings" size={200} link="/settings/bookmarks-manager" />}
      />

      <AppSidebarContent css={styles.sidebarContent}>
        {(isLoading || isEmpty) && (
          <State
            isLoading={isLoading}
            isEmpty={isEmpty}
            icon="bookmarks"
            title="Bookmarks"
            emptyDescription="Add personal and company bookmarks from settings"
            emptyActions={
              <Button label="Manage Bookmarks" color="blue" type="default" glyph="settings" size={200} link="/settings/bookmarks-manager" />
            }
          />
        )}

        {hasPersonalBookmarks && (
          <>
            <Type as="h3" variant="CAPS_TITLE_SMALL" children="Personal Bookmarks" css={styles.heading} />
            <div css={styles.content}>
              {personalBookmarks?.map((bookmark: any) => (
                <a key={bookmark.id} css={styles.item} href={bookmark.url} target="_blank">
                  <img src={bookmark.icon} />
                  <div css={styles.label}>{bookmark.name}</div>
                </a>
              ))}
            </div>
          </>
        )}

        {hasCompanyBookmarks && (
          <>
            <Type as="h3" variant="CAPS_TITLE_SMALL" children="Company Bookmarks" css={styles.heading} />
            <div css={styles.content}>
              {companyBookmarks?.map((bookmark: any) => (
                <a key={bookmark.id} css={styles.item} href={bookmark.url} target="_blank">
                  <img src={bookmark.icon} />
                  <div css={styles.label}>{bookmark.name}</div>
                </a>
              ))}
            </div>
          </>
        )}
      </AppSidebarContent>
    </AppSidebarView>
  )
}

const styles = {
  sidebarContent: {
    padding: 0,
  },

  heading: {
    padding: '0.7rem',
    fontSize: '0.9rem',
    fontWeight: 700,
  },

  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
    borderTop: `1px solid #e6e9ef`,
    borderBottom: `1px solid #e6e9ef`,
    marginBottom: '0.5rem',

    '&:last-of-type': {
      marginBottom: 0,
    },
  },

  item: {
    boxShadow: `1px 1px 0 #e6e9ef`,
    cursor: 'pointer',
    textAlign: 'center',
    padding: '0.7rem',
    display: 'block',
    textDecoration: 'none',

    img: {
      width: '1.75rem',
      marginBottom: '0.5rem',
      display: 'inline-block',
    },

    '&:hover': {
      background: COLORS.hover,
    },
  },

  label: {
    fontSize: '0.95rem',
    fontWeight: 500,
    color: COLORS.blue,
  },
}

export default BookmarksView
