import React from 'react'

import { DEFAULT_FILTERS } from '../../../../constructs/Filters/constants'
import { FORM_SHARE_STATUSES } from '../../../../utils/constants'
import { FormBuilderShareStatus } from '../../../../constructs/FormBuilder/FormBuilderShareStatus'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

export const FormsTable = ({ onSelect, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['forms'],
    endpoint: `/forms`,
    localStorageKey: 'selector_forms_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        width: 260,
        isSticky: true,
        canToggleVisible: false,
        model: 'name',
        title: 'Name',
        formatValue: ({ data, value }: any) => (
          <MainCell id={data.id} onClick={onSelect ? () => onSelect(data) : undefined} value={value} />
        ),
      },
      {
        width: 160,
        model: 'use_public_sharing',
        title: 'Share Status',
        formatValue: ({ value }: any) => {
          return <FormBuilderShareStatus status={value ? 'public' : 'private'} />
        },
      },
      {
        width: 200,
        model: 'internal_notes',
        title: 'Internal Notes',
      },
      {
        width: 200,
        model: 'shared_links',
        title: 'Shared Links',
      },
      {
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        model: 'updated_at',
        title: 'Date Updated',
        type: 'date_time',
      },
    ],
    [],
  )

  return <DataTable {...tableProps} {...rest} title="Forms" columns={columns} filtersConfig={FILTERS_CONFIG} />
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  internal_notes: {
    label: 'Internal Notes',
    type: 'string',
  },
  use_public_sharing: {
    label: 'Share Status',
    type: 'boolean',
    trueLabel: 'Public',
    falseLabel: 'Internal',
  },
  shared_links: {
    label: 'Shared Links',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
