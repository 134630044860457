import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'

import { formatVariableKey } from '../FormBuilder/utils/functions'
import { VariableKey } from '../FormBuilder/components/VariableKey'
import BaseInput from '../../components/Forms/BaseInput'

const RootCustomAccountVariableOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    saveWithData,
  } = useOverlay({
    name: 'form_variables',
    endpoint: '/form_variables',
    invalidate: 'form_variables',
    options: props,
  })

  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [variableKey, setVariableKey] = React.useState('')
  const [variableValue, setVariableValue] = React.useState('')

  const isInSync = React.useMemo(() => {
    const keyByName = formatVariableKey(name)
    return keyByName === variableKey
  }, [name, variableKey])

  const handleSave = () => {
    saveWithData({
      name,
      description,
      variable_key: `CV_${variableKey}`,
      variable_value: variableValue,
      category: 'custom_account',
    })
  }

  React.useEffect(() => {
    if (!data) return

    setName(data.name || '')
    setDescription(data.description || '')
    setVariableKey(data.variable_key?.replace('CV_', '') || '')
    setVariableValue(data.variable_value || '')
  }, [data])

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay showBackdrop={isEditable} onClose={close} position="right">
      <Overlay.Header title="Custom Account Variable" icon="variables" />

      <Overlay.Content>
        <Form isEditable={isEditable} getForm={form} initialModel={initialModel}>
          <Section>
            <FormSection>
              <Input
                label="Name"
                value={name}
                onUpdate={({ value }) => {
                  setName(value)

                  if (isInSync) {
                    setVariableKey(formatVariableKey(value))
                  }
                }}
                validations={{
                  presence: {
                    message: 'Please enter a variable name',
                  },
                }}
              />

              {isEditable ? (
                <div>
                  <Label isRequired label="Variable Key" isValid={!!variableKey} />
                  <BaseInput
                    value={variableKey}
                    onChange={(event) => {
                      const newValue = event.target.value || ''
                      setVariableKey(formatVariableKey(newValue?.toUpperCase()))
                    }}
                    validations={{
                      presence: {
                        message: 'Please enter a variable key',
                      },
                    }}
                  />
                </div>
              ) : (
                <div>
                  <Label label="Variable Key" />
                  <VariableKey canCopy variable={data?.variable_key} />
                </div>
              )}

              {isEditable && (
                <div className="text-text-muted -mt-2">
                  Variable key will be formatted as <VariableKey canCopy={false} variable={`CV_${variableKey || ''}`} />, where{' '}
                  <span className="text-text bg-divider px-1.5 py-0.5 rounded-[4px] text-[0.8rem] font-mono">CV</span> is a prefix applied
                  to all Custom Account Variables
                </div>
              )}

              <Input
                label="Value"
                value={variableValue}
                onUpdate={({ value }) => {
                  setVariableValue(value)
                }}
                validations={{
                  presence: {
                    message: 'Please enter a variable value',
                  },
                }}
              />

              <Textarea
                label="Description"
                value={description}
                onUpdate={({ value }) => {
                  setDescription(value)
                }}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label={isNew ? 'Add Variable' : 'Save Changes'}
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={handleSave}
              isDisabled={!name || !variableKey || !variableValue}
              flex="100 1 auto"
              permission={isNew ? 'form_variables.create' : 'form_variables.edit'}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Variable"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="form_variables.edit"
            />

            <DeleteDialog
              title="Delete Variable?"
              message="Are you sure you want to delete this variable? This action cannot be undone."
              onYes={deleteRecord}
              permission="form_variables.delete"
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                permission="form_variables.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const CustomAccountVariableOverlay = withOverlayError(RootCustomAccountVariableOverlay)
