import ICONS from './icons'

export const USER_ICONS = {
  behave_health: ICONS.behave_health,
  access_lists: ICONS.access_lists,
  applications: ICONS.applications,
  applicants: ICONS.applicants,
  authorizations: ICONS.authorizations,
  bridge: ICONS.bridge,
  beds: ICONS.beds,
  calendar: ICONS.calendar,
  care_level_1: ICONS.care_level_1,
  care_level_2: ICONS.care_level_2,
  care_level_3: ICONS.care_level_3,
  care_level_4: ICONS.care_level_4,
  care_level_5: ICONS.care_level_5,
  care_level_6: ICONS.care_level_6,
  clients_chat: ICONS.clients_chat,
  company_chat: ICONS.company_chat,
  access: ICONS.access,
  add_patient: ICONS.add_patient,
  alumni_relations: ICONS.alumni_relations,
  billing: ICONS.billing,
  breathalyzer: ICONS.breathalyzer,
  certificate: ICONS.certificate,
  clients: ICONS.clients,
  clinical_assessments: ICONS.clinical_assessments,
  clinical_department: ICONS.clinical_department,
  clinical_notes: ICONS.clinical_notes,
  peer_notes: ICONS.peer_notes,
  clinical_measurements: ICONS.clinical_measurements,
  communications: ICONS.communications,
  community: ICONS.community,
  company_profile: ICONS.company_profile,
  connections: ICONS.connections,
  contacts: ICONS.contacts,
  dashboard: ICONS.dashboard,
  diagnosis: ICONS.diagnosis,
  discharge_forms: ICONS.discharge_forms,
  drug_test: ICONS.drug_test,
  electronic_prescription: ICONS.electronic_prescription,
  employees: ICONS.employees,
  enterprise: ICONS.enterprise,
  files: ICONS.files,
  financials: ICONS.financials,
  general_info: ICONS.general_info,
  gps_check_in_out: ICONS.gps_check_in_out,
  housing_department: ICONS.housing_department,
  housing_notes: ICONS.housing_notes,
  housing_shifts: ICONS.housing_shifts,
  insurance: ICONS.insurance,
  intake_forms: ICONS.intake_forms,
  invoices: ICONS.invoices,
  keyboard: ICONS.keyboard,
  legal_agreement_alt: ICONS.legal_agreement_alt,
  legal_agreement_circle: ICONS.legal_agreement_circle,
  legal_agreement: ICONS.legal_agreement,
  licence: ICONS.licence,
  lists: ICONS.lists,
  log_out: ICONS.log_out,
  management_department: ICONS.management_department,
  med_pass: ICONS.med_pass,
  medical_assessments: ICONS.medical_assessments,
  medical_department: ICONS.medical_department,
  medication_inventory: ICONS.medication_inventory,
  medication_incidents: ICONS.medication_incidents,
  medications: ICONS.medications,
  mobile: ICONS.mobile,
  nursing: ICONS.nursing,
  offices: ICONS.offices,
  online_payment_alt: ICONS.online_payment_alt,
  online_payment_circle: ICONS.online_payment_circle,
  online_payment: ICONS.online_payment,
  operations_department: ICONS.operations_department,
  optimization: ICONS.optimization,
  organizations_providers: ICONS.organizations_providers,
  organizations_resident_resources: ICONS.organizations_resident_resources,
  organizations_vendors: ICONS.organizations_vendors,
  organizations: ICONS.organizations,
  phone: ICONS.phone,
  phone_call: ICONS.phone_call,
  patient_progress_measure: ICONS.patient_progress_measure,
  patient: ICONS.patient,
  permissions: ICONS.permissions,
  physician_orders: ICONS.physician_orders,
  profile: ICONS.profile,
  progress_reviews: ICONS.progress_reviews,
  progress_notes: ICONS.progress_notes,
  properties: ICONS.properties,
  recovery_coaching: ICONS.recovery_coaching,
  recovery_navigation: ICONS.recovery_navigation,
  residents: ICONS.residents,
  rooms: ICONS.rooms,
  roster: ICONS.roster,
  schedule: ICONS.schedule,
  settings: ICONS.settings,
  search: ICONS.search,
  service_episodes: ICONS.service_episodes,
  startup: ICONS.startup,
  tasks: ICONS.tasks,
  test_results: ICONS.test_results,
  timeline: ICONS.timeline,
  transportation: ICONS.transportation,
  treatment_data: ICONS.treatment_data,
  treatment_episodes: ICONS.treatment_episodes,
  treatment_insight: ICONS.treatment_insight,
  treatment_plans: ICONS.treatment_plans,
  vehicles: ICONS.vehicles,
  vital_signs: ICONS.vital_signs,
  web_form: ICONS.web_form,
  amex: ICONS.amex,
  discover: ICONS.discover,
  mastercard: ICONS.mastercard,
  visa: ICONS.visa,
  reports: ICONS.reports,
  outcome_measures: ICONS.outcome_measures,
  staff_credentials: ICONS.staff_credentials,
  video_call: ICONS.video_call,
  custom_notes: ICONS.custom_notes,
  grievance_notes: ICONS.grievance_notes,
  rcm_verifications: ICONS.rcm_verifications,
  rcm_utilization_review: ICONS.rcm_utilization_review,
  claims: ICONS.claims,
  help_center: ICONS.help_center,
  bookmarks: ICONS.bookmarks,
  pins: ICONS.pins,
  locations: ICONS.locations,
  sticky_notes: ICONS.sticky_notes,
  tags: ICONS.tags,
  quick_text: ICONS.quick_text,
  templates: ICONS.templates,
  timeline_view: ICONS.timeline_view,
  bed_management_board: ICONS.bed_management_board,
  bed_management_calendar: ICONS.bed_management_calendar,
  bed_management_locations: ICONS.bed_management_locations,
  bed_management_timeline: ICONS.bed_management_timeline,
  floors: ICONS.floors,
  client_journey: ICONS.client_journey,
  programs_table: ICONS.programs_table,
  programs_timeline: ICONS.programs_timeline,
  floor_plan: ICONS.floor_plan,
  program_plan: ICONS.program_plan,
  program_lists: ICONS.program_lists,
  partners: ICONS.partners,
  star: ICONS.star,
  community_partners: ICONS.community_partners,
  community_profile: ICONS.community_profile,
  community_all: ICONS.community_all,
  community_invites: ICONS.community_invites,
  community_outreach: ICONS.community_outreach,
  global_pages: ICONS.global_pages,
  myaccount_pages: ICONS.myaccount_pages,
  company_pages: ICONS.company_pages,
  blank_pages: ICONS.blank_pages,
  smart_flags: ICONS.smart_flags,
  feature_flags: ICONS.feature_flags,
  ehr_pages: ICONS.ehr_pages,
  hq_pages: ICONS.hq_pages,
  community_pages: ICONS.community_pages,
  community_admin_pages: ICONS.community_admin_pages,
  ai_assistant: ICONS.ai_assistant,
  ai_prompts: ICONS.ai_prompts,
  app_messages: ICONS.app_messages,
  login_messages: ICONS.login_messages,
  microphone: ICONS.microphone,
  microphone_recording: ICONS.microphone_recording,
  behave_ai: ICONS.behave_ai,
  accounting: ICONS.accounting,
  personal_inventory: ICONS.personal_inventory,
  personal_journal: ICONS.personal_journal,
  gratitude_journal: ICONS.gratitude_journal,
  passcode: ICONS.passcode,
  magic_link: ICONS.magic_link,
  bank_building: ICONS.bank_building,
  binary_file: ICONS.binary_file,
  call_transfer: ICONS.call_transfer,
  cashbook: ICONS.cashbook,
  day_view: ICONS.day_view,
  deposit: ICONS.deposit,
  exchange: ICONS.exchange,
  id_verified: ICONS.id_verified,
  lightning_bolt: ICONS.lightning_bolt,
  online_card_payment: ICONS.online_card_payment,
  outbox: ICONS.outbox,
  safe_in: ICONS.safe_in,
  sell: ICONS.sell,
  non_healthcare_services: ICONS.non_healthcare_services,
  healthcare_services: ICONS.healthcare_services,
  gallery: ICONS.gallery,
  assets: ICONS.assets,
  client_workflows: ICONS.client_workflows,
  client_workflows_message_templates: ICONS.client_workflows_message_templates,
  admin_contacts: ICONS.admin_contacts,
  hubspot: ICONS.hubspot,
  helpscout: ICONS.helpscout,
  google: ICONS.google,
  message_board: ICONS.message_board,
  gmail: ICONS.gmail,
  outlook: ICONS.outlook,
  google_calendar: ICONS.google_calendar,
  postmark: ICONS.postmark,
  short_links: ICONS.short_links,
  addendums: ICONS.addendums,
  signature: ICONS.signature,
  variables: ICONS.variables,
  company_integrations: ICONS.company_integrations,
}
