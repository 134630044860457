import React from 'react'

import { COLORS, INPUT_STYLES } from '../../theme'
import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'

import { Input } from './Input'
import { withFormContext } from './context'

import BaseInput from './BaseInput'
import Button from '../Button'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Value from '../Value'

class PasswordShowHideInput extends Input {
  editRender = () => {
    return (
      <div css={styles.root}>
        <BaseInput
          id={this.state.id}
          name={this.state.id}
          value={this.state.value}
          placeholder={this.props.placeholder}
          size={this.props.size}
          type={this.state.passwordVisible ? 'text' : 'password'}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyDown={this.props.onKeyDown}
          prefix={this.props.prefix}
          suffix={this.props.suffix}
          step={this.props.step}
          min={this.props.min}
          max={this.props.max}
          maxLength={this.props.maxLength}
          autoFocus={this.props.autoFocus}
          testKey={this.props.testKey}
          autocomplete={this.props.autocomplete}
          className={this.props.className}
          glyph={this.props.glyph}
        />
        <div onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })} css={styles.button}>
          <Glyph glyph={this.state.passwordVisible ? 'hide' : 'view'} size={this.state.passwordVisible ? 16 : 20} />
        </div>
      </div>
    )
  }

  readOnlyRender = () => {
    const { value } = this.state

    return (
      <Flex centerY justifyContent="space-between">
        <Value value={renderValue(value, this.state.passwordVisible)} />
        <Button
          label={this.state.passwordVisible ? 'Hide' : 'Show'}
          glyph="view"
          type="minimal"
          size={100}
          css={styles.viewButton}
          onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}
        />
      </Flex>
    )
  }
}

const renderValue = (value: string, isVisible: boolean) => {
  if (!value) return DEFAULT_EMPTY_VALUE

  if (!isVisible) return Array(value.length).join('•')

  return value
}

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'nowrap',

    '& > *': {
      flex: '1 1 auto',
    },

    input: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  button: {
    ...INPUT_STYLES,

    width: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 'none',

    '&:hover': {
      background: COLORS.transparent,
    },
  },

  viewButton: {
    marginLeft: '1rem',
  },
}

export default withFormContext(PasswordShowHideInput)
