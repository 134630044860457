import React from 'react'

import Button from '../../Button'
import Grid from '../../Grid'
import Input from '../../Forms/Input'
import Form from '../../Forms/Form'
import Overlay from '../../Overlay'
import Section from '../../Section'
import StateSelect from '../../Forms/elements/StateSelect'

import withSettings from '../../../hocs/withSettings'
import { withOverlayError } from '../../../hocs/withOverlayError'

const ManualAddressEditOverlay = ({ address, onClose, onUpdate }: any) => {
  const form = React.useRef()
  const [valid, setValid] = React.useState(false)

  const onAddressSelect = () => {
    const address = form.current.getFormValue()

    if (onUpdate) onUpdate(address)
    onClose()
  }

  return (
    <Overlay position="center" showBackdrop closeOnBackdrop onClose={onClose}>
      <Overlay.Header glyph="map_pin" title="Manually Update Address" />

      <Overlay.Content>
        <Form getForm={form} initialModel={address} onValidationUpdate={setValid}>
          <Section>
            <Grid rows="min-content 1fr" className="!h-full" gap="1rem">
              <Input
                label="Address Line 1"
                model="address_line_1"
                validations={{
                  presence: {
                    message: 'Please enter the first line of address',
                  },
                }}
              />
              <Input label="Address Line 2" model="address_line_2" />
              <Input
                label="City"
                model="city"
                validations={{
                  presence: {
                    message: 'Please enter city',
                  },
                }}
              />
              <StateSelect
                model="state"
                validations={{
                  presence: {
                    message: 'Please enter state',
                  },
                }}
              />
              <Input
                label="Zip Code"
                model="zip_code"
                validations={{
                  presence: {
                    message: 'Please enter zip code',
                  },
                }}
              />
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save Address" glyph="check" type="primary" color="green" onClick={onAddressSelect} isDisabled={!valid} />
      </Overlay.Footer>
    </Overlay>
  )
}

export default withOverlayError(withSettings(ManualAddressEditOverlay))
