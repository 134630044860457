import React from 'react'

import Animated from './Animated'
import Portal from './Portal'
import withPermissionsProps from '../hocs/withPermissionsProps'

const SummonOverlay = ({
  isDisabled,
  isOpen,
  children,
  overlay,
  onOpen,
  onClose,
  portalType = 'overlay',
  isPermissible = true,
  cloneProps,
}: any) => {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    isOpen ? setOpen(true) : setOpen(false)
  }, [isOpen])

  const handleOpen = (event: any) => {
    if (isDisabled) return
    if (!isPermissible) return

    if (onOpen) onOpen(event)
    setOpen(true)

    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const close = () => {
    setOpen(false)
    if (onClose) onClose()
  }

  if (!overlay) return null

  if (!isPermissible) return children

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          onClick: handleOpen,
        })
      })}

      <Portal type={portalType}>
        <Animated show={open} animation="animation">
          {React.cloneElement(overlay, {
            onClose: close,
            type: 'summon',

            ...cloneProps,
          })}
        </Animated>
      </Portal>
    </>
  )
}

export default withPermissionsProps(SummonOverlay)
