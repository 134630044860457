import React from 'react'

import { countWord } from '../../../../utils/functions'
import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

export const ProgramsTable = ({ onSelect, dependentValue, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-programs'],
    endpoint: '/programs',
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_programs_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            onClick={onSelect ? () => onSelect(data) : undefined}
          />
        ),
      },
      {
        width: 280,
        model: 'description',
        title: 'Description',
      },
      {
        width: 160,
        model: 'phase_count',
        title: 'Program List Count',
        formatValue: ({ value }: any) => `${countWord('Program Lists', value)}`,
      },
    ],
    [],
  )

  return (
    <DataTable
      {...tableProps}
      {...rest}
      icon="checklist"
      title="Programs"
      columns={columns}
      // filtersConfig={FILTERS.locations}
      // searchBarFilterKey="name"
    />
  )
}
