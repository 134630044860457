import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useGet } from '@behavehealth/hooks/useNewAPI'

import Card from '@behavehealth/components/Card'
import Flex from '@behavehealth/components/Flex'
import Icon from '@behavehealth/components/Icon'
import PageSection from '@behavehealth/components/PageSection/PageSection'
import Tooltip from '@behavehealth/components/Tooltip'
import UnassignedClientsTable from '@behavehealth/components/Tables/UnassignedClientsTable'
import Wrapper from '@behavehealth/components/Wrapper'

const UnassignedClients = () => {
  const match = useRouteMatch()
  const queryParams = { unassigned: true }

  const { data, isLoading }: any = useGet({
    name: ['clients', queryParams],
    url: `/residents`,
    params: queryParams,
  })

  return (
    <Wrapper>
      <PageSection>
        <PageSection.Header>
          <Flex centerY gap="0.5rem">
            <Icon icon="clients" size={22} />
            <PageSection.Title title="Currently Unassigned" />
            <Tooltip className="!ml-auto !mr-2" content="Current Clients who do not have an active Bed Assignment / Reservation today" />
          </Flex>
        </PageSection.Header>

        <Card>
          <UnassignedClientsTable
            data={data}
            isLoading={isLoading}
            title="Clients"
            localStorageKey="bed_management_unassigned_clients"
            to={(id) => `/clients/${id}/general-info`}
            assignTo={(id) => ({
              pathname: `${match.url}/assign-bed/${id}`,
              parent: match,
            })}
          />
        </Card>
      </PageSection>
    </Wrapper>
  )
}

export default UnassignedClients
