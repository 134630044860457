import React from 'react'
import { useParams } from 'react-router-dom'

import { useGet } from '../../hooks/useNewAPI'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Chotomate from '../../components/Chotomate'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Glyph from '../../components/Glyph'
import Link from '../../components/Link'

import AmountInput from '../../components/Forms/AmountInput'
import Attachments from '../../components/Forms/Attachments'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import SigneeStatus from '../../components/Statuses/SigneeStatus'
import Textarea from '../../components/Forms/Textarea'
import OverlayLoader from '../../components/OverlayLoader'

import { ICONS } from '../../theme'
import { titleCase } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import { PAYMENT_METHODS_LIST } from '../../utils/constants'

const ORGANIZATION_TYPES = {
  vendor: 'Vendor',
  resource: 'Community Resource',
  provider: 'Provider',
}

const RootInvoicePaymentOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'TODO',
    endpoint: '/TODO',
    invalidate: 'TODO',
    options: props,
  })

  const [formData, setFormData] = React.useState(initialModel)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(null)

  const { timezone } = useSettings()

  const { client } = props

  const save = () => {
    close()
    // saveWithData({ ...formData, payed_with: formData.payed_with?.model })
  }

  // if (isOverlayLoading) {
  //   return <OverlayLoader position="right" />
  // }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon={ICONS.online_card_payment} title={isNew ? 'Add Payment' : 'Payment'} />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          onUpdate={setFormData}
        >
          <Section>
            <FormSection layout="vertical">
              <Input
                autoFocus
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a payment name',
                  },
                }}
              />

              <Flex gap="1rem">
                <AmountInput
                  label="Amount"
                  model="amount"
                  isEditable={isNew || (isEditable && initialModel.source === 'app')}
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                    numericality: {
                      greaterThan: 0,
                      message: 'Please enter an amount',
                    },
                  }}
                />

                <ObjectSelector
                  isRelation={false}
                  className="!flex-auto"
                  model="payed_with"
                  label="Payed With"
                  apiData={PAYMENT_METHODS_LIST}
                  showAvatars={false}
                  selectTitle={(data: any) => (
                    <>
                      <Glyph glyph={data.glyph} /> {data.name}
                    </>
                  )}
                  selectDescription={(data: any) => data.description}
                  validations={{
                    presence: {
                      message: 'Please select a Payment Method',
                    },
                  }}
                />
              </Flex>

              {formData?.payed_with?.model && (
                <>
                  {formData.payed_with.model === 'online' && (
                    <>
                      <ObjectSelector
                        icon="billing"
                        type="client.payment_methods"
                        label="Payment Method"
                        model="payment_method"
                        dependentValue={client?.id}
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => data?.reference?.name}
                        onUpdate={(model: any) => {
                          setSelectedPaymentMethod(model.object)
                        }}
                      />

                      {(selectedPaymentMethod || initialModel?.payment_method) && (
                        <>
                          <Divider className="!m-0" />

                          <RadioGroup
                            isEditable={false}
                            label="Payer Type"
                            model="reference_category"
                            layout="horizontal-dense"
                            value={selectedPaymentMethod?.reference_category || initialModel.payment_method?.reference_category}
                          >
                            <Radio label="Client" value="client" />
                            <Radio label="Contact" value="contact" />
                          </RadioGroup>

                          <ObjectSelector
                            isEditable={false}
                            isPolymorphic
                            label="Payer"
                            model="reference"
                            value={selectedPaymentMethod?.reference || initialModel.payment_method?.reference}
                          />

                          <Divider className="!mt-2 !mx-0 !mb-0" />
                        </>
                      )}
                    </>
                  )}

                  {formData.payed_with.model === 'online_apps' && (
                    <>
                      <Divider className="!m-0" />

                      <RadioGroup
                        label="Payer Type"
                        model="reference_category"
                        layout="horizontal-dense"
                        defaultValue={isNew ? 'client' : null}
                      >
                        <Radio label="Client" value="client" />
                        <Radio label="Contact" value="contact" />
                      </RadioGroup>

                      <ContextShow when="reference_category" is="client">
                        <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="reference" value={client} />
                      </ContextShow>

                      <ContextShow when="reference_category" is="contact">
                        <ObjectSelector
                          isPolymorphic
                          icon="contacts"
                          label="Payer"
                          model="reference"
                          type="client.contacts"
                          description={
                            <>
                              Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                            </>
                          }
                          dependentValue={client?.id}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => titleCase(data?.relationship)}
                        />
                      </ContextShow>

                      <Divider className="!mt-2 !mx-0 !mb-0" />
                    </>
                  )}

                  {formData.payed_with.model !== 'online' && formData.payed_with.model !== 'online_apps' && (
                    <>
                      <Divider className="!m-0" />

                      <RadioGroup label="Payer Type" model="reference_category" layout="horizontal-dense" defaultValue="client">
                        <Radio label="Client" value="client" />
                        <Radio label="Contact" value="contact" />
                      </RadioGroup>

                      <ContextShow when="reference_category" is="client">
                        <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="reference" value={client} />
                      </ContextShow>

                      <ContextShow when="reference_category" is="contact">
                        <ObjectSelector
                          isPolymorphic
                          icon="contacts"
                          label="Payer"
                          model="reference"
                          type="client.contacts"
                          description={
                            <>
                              Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                            </>
                          }
                          dependentValue={client?.id}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => titleCase(data?.relationship)}
                        />
                      </ContextShow>

                      <Divider className="!mt-2 !mx-0 !mb-0" />
                    </>
                  )}
                </>
              )}

              <DateTimeInput
                isEditable={isNew || (isEditable && initialModel.source === 'app')}
                defaultToNow
                label="Payment Date"
                model="transactioned_at"
                validations={{
                  presence: {
                    message: 'Please enter a payment date',
                  },
                }}
              />

              <Input label="Payment Reference" model="payment_reference" />
              <Textarea useQuickText label="Notes" model="notes" />

              {!isNew && <Input isEditable={false} label="Transaction ID" model="uuid" />}

              {!isNew && data && (
                <Flex gap="1rem">
                  <DateTimeInput isEditable={false} label="Date Created" model="created_at" />
                  <DateTimeInput isEditable={false} label="Date Updated" model="updated_at" />
                </Flex>
              )}
            </FormSection>
          </Section>

          <Divider />

          {initialModel.payer && (
            <>
              <Section glyph="warning" title="Payer">
                <Grid gap="1rem">
                  <Alert glyph="unavailable" type="negative">
                    We replaced <strong>Payers</strong> with <strong>Payment Methods</strong>. Payers will not show up for new Transactions.
                  </Alert>

                  <ObjectSelector
                    isEditable={false}
                    apiData={client?.financial_plan?.payers}
                    icon="financials"
                    model="payer"
                    label="Payer"
                    type="client.payers"
                    selectTitle={(data: any) => data?.reference?.name}
                    selectDescription={(data: any) => <SigneeStatus signee={data?.reference} />}
                  />
                </Grid>
              </Section>

              <Divider />
            </>
          )}

          <Section headingType="h2" title="Attachments" description="Upload the files related to this payment">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable ? (
          <>
            <Button
              label="Save Payment"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              flex="100 1 auto"
              isDisabled={isInvalid}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" onClick={cancel} isDisabled={isSaving} />}
          </>
        ) : (
          <>
            <Button glyph="edit" label="Edit Payment" type="default" isDisabled={isSaving} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Payment?"
              message="Are you sure you want to delete this payment? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button fullWidth glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const InvoicePaymentOverlay = withOverlayError(RootInvoicePaymentOverlay)
