import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { CommunicableDiseaseTestsReportDataTable } from '@behavehealth/constructs/LiveReports/CommunicableDiseaseTestsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const CommunicableDiseaseTests = () => {
  const tableProps = useDataTable({
    name: ['reports', 'communicable-disease-tests'],
    endpoint: `/live_reports?category=communicable_disease_tests`,
    localStorageKey: 'report_communicable_disease_tests_v1',
  })

  const to = React.useMemo(
    () => (rowData: any) => `${getResourceLink(rowData.client)}/test-results/communicable-disease-tests/${rowData.id}`,
    [],
  )

  return (
    <Page feature="test_results" title="Live Communicable Disease Tests Report">
      <Grid>
        <CommunicableDiseaseTestsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(CommunicableDiseaseTests)
