import React from 'react'
import { useSelector } from 'react-redux'
import DailyIframe from '@daily-co/daily-js'

import { COLORS } from '../../theme'
import { apiCreate, apiGet } from '../../modules/api'

import { MeetingContext } from './context'

export const MeetingContextProvider: React.FC = ({ children }) => {
  const [ref, setRef]: any = React.useState(null)
  const [meetingRoom, setMeetingRoom]: any = React.useState(null)
  const [meetingName, setMeetingName]: any = React.useState(null)
  const [callFrame, setCallFrame]: any = React.useState(null)
  const [transcript, setTranscript]: any = React.useState([])

  const [isLoading, setIsLoading] = React.useState(false)
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false)

  const user: any = useSelector((state) => state?.me?.user)

  const openOverlay = () => {
    setIsOverlayOpen(true)
  }

  const closeOverlay = () => {
    setIsOverlayOpen(false)
  }

  const leaveMeeting = () => {
    if (callFrame) callFrame.destroy()
    setMeetingRoom(null)
    setCallFrame(null)
    setIsLoading(false)
    closeOverlay()
  }

  const isMeetingActive = !!(callFrame && meetingRoom) || isLoading || isOverlayOpen

  const startMeeting = React.useCallback(() => {
    if (!ref && meetingRoom?.url && meetingRoom?.token) return

    const newCallFrame = DailyIframe.createFrame(ref, {
      showLeaveButton: true,
      userName: user?.name,
      theme: {
        colors: {
          accent: COLORS.blue,
          background: '#1E2434',
          backgroundAccent: '#33436A',
          accentText: '#FFFFFF',
          baseText: '#FFFFFF',
          border: '#435178',
          mainAreaBg: '#1E2434',
          mainAreaBgAccent: '#33436A',
          mainAreaText: '#FFFFFF',
          supportiveText: '#7A7F8E',
        },
      },
    })

    newCallFrame.join({ url: meetingRoom.url, token: meetingRoom.token })
    newCallFrame.on('left-meeting', leaveMeeting)
    newCallFrame.on('app-message', (event) => {
      if (event?.fromId === 'transcription' && event.data?.is_final) {
        setTranscript((prevTranscript: any) => [
          ...prevTranscript,
          {
            name: event?.data?.user_name,
            text: event?.data?.text,
          },
        ])
      }
    })

    newCallFrame.on('transcription-error', (event) => {
      console.error(event)
    })

    setCallFrame(newCallFrame)
  }, [ref, meetingRoom, setCallFrame])

  const createQuickMeeting = async () => {
    if (!user) return

    openOverlay()
    setIsLoading(true)
    setMeetingName('Quick Meeting')

    try {
      const quickMeeting = await apiCreate({
        url: '/meeting_rooms/quick_meeting',
        params: {
          reference_type: user.type,
          reference_id: user.id,
        },
        notify: false,
      })

      const joinMeeting = await apiGet({ url: `/meeting_rooms/${quickMeeting.data.data.id}/join` })

      const { data } = joinMeeting.data

      setMeetingRoom({
        url: data.url,
        token: data.token,
        share_url: data.share_url,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const joinMeetingByID = async (id: string, name?: string) => {
    openOverlay()
    setIsLoading(true)
    setMeetingName(name || 'Meeting Room')

    try {
      const res = await apiGet({ url: `/meeting_rooms/${id}/join` })
      const { data } = res.data

      setMeetingRoom({
        url: data.url,
        token: data.token,
        share_url: data.share_url,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (!(ref && meetingRoom?.url && meetingRoom?.token)) return

    startMeeting()
  }, [ref, meetingRoom])

  return (
    <MeetingContext.Provider
      value={{
        ref,
        setRef,

        meetingName,
        meetingRoom,
        setMeetingRoom,

        callFrame,
        setCallFrame,

        isLoading,
        isMeetingActive,

        openOverlay,
        closeOverlay,
        isOverlayOpen,

        joinMeetingByID,
        leaveMeeting,
        createQuickMeeting,

        transcript,
      }}
    >
      {children}
    </MeetingContext.Provider>
  )
}
