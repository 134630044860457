export const URL = {
  connect: process.env.BH_APP_CONNECT_URL,
  ledger: process.env.BH_APP_LEDGER_URL,
  subdomain: 'demo',
  app: '',
  api: '',
}

export const CONFIG = {
  stripe_client_id: process.env.BH_STRIPE_CLIENT_ID,
  is_demo: false,
  active_employee_roles: [],
}
