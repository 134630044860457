import React from 'react'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import Status from '@behavehealth/components/Status'

import NotificationLogStatus from '@behavehealth/components/Statuses/NotificationLogStatus'

import { nicePhoneNo } from '../../utils/functions'

const CATEGORIES = {
  email: {
    name: 'Email',
    color: 'blue',
  },
  sms: {
    name: 'SMS',
    color: 'green',
  },
}

const NotificationLogsTable: React.FC<any> = (props: any) => {
  const columns = React.useMemo(() => {
    return [
      {
        title: 'Type',
        model: 'category',
        width: 80,
        disableSort: true,
        disableHide: true,
        formatValue: ({ value }: any) => <Status inline color={CATEGORIES[value].color} label={CATEGORIES[value].name} />,
      },
      {
        title: 'Status',
        model: 'status',
        width: 120,
        disableSort: true,
        disableHide: true,
        formatValue: ({ value }: any) => <NotificationLogStatus status={value} />,
      },
      {
        title: 'Sent To',
        id: 'sent_to',
        width: 140,
        formatValue: ({ data }: any) => {
          if (!!data.to_phone_no) return nicePhoneNo(data.to_phone_no)
          else if (!!data.to_email) return data.to_email

          return '–'
        },
      },
      {
        title: 'Delivered At',
        model: 'delivered_at',
        type: 'date_time',
      },
      {
        title: 'Sent At',
        model: 'sent_at',
        type: 'date_time',
      },
      {
        title: 'Body',
        model: 'body',
        width: 200,
      },
    ]
  }, [])

  return <DataTable {...props} asCard title="Notifications" columns={columns} mainHeaderHidden />
}

export default NotificationLogsTable
