import React, { Component, Fragment } from 'react'

import { Button, Card, Heading, Page, P, Section } from '@behavehealth/components'

class BillingIssues extends Component {
  render() {
    return (
      <Page centerContent>
        <Card className="!max-w-[512px]">
          <Section>
            <Heading is="h3" style={{ marginTop: 0 }}>
              Hello [firstName],
            </Heading>

            {/* For users WITH billing permissions */}
            {true && (
              <Fragment>
                <P>
                  Like all good things, your free trial has unfortunately come to an end. We hope you found the product useful for your
                  business.
                </P>
                <P>
                  Contact us to discuss your pricing and continue using your Behave Health App. We're happy to answer any questions you may
                  have and look forward to having you on board.
                </P>
                <P>
                  Best Regards,
                  <br />
                  The Behave Health Team
                </P>
                <Button
                  label="Contact Us Now for Plans & Pricing"
                  type="primary"
                  color="green"
                  href="mailto:contact@behavehealth.com"
                  fullWidth
                />
              </Fragment>
            )}

            {/* For users WITHOUT billing permissions */}
            {false && (
              <Fragment>
                <P>
                  The Behave Health App free trial of [currentFacilityName] has come to an end. Usage is currently restricted until the
                  company owner signs up for a premium plan.
                </P>
                <P>
                  If you have any questions, please don't hesitate to reach us at{' '}
                  <a href="mailto:contact@behavehealth.com">contact@behavehealth.com</a>.
                </P>
                <P>
                  Best Regards,
                  <br />
                  The Behave Health Team
                </P>
              </Fragment>
            )}
          </Section>
        </Card>
      </Page>
    )
  }
}

export default BillingIssues
