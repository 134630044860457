import React from 'react'

import { PlainSwitch } from '../../../../components/Forms/Switch'
import Tooltip from '../../../../components/Tooltip'
import Card from '../../../../components/Card'
import Label from '../../../../components/Label'

export const ConfigVariablesEditor = (props) => {
  const { activeElement, toggleSkipVariablesCheck, activeTab, setActiveTab } = props

  if (!activeElement?.config) return null

  const { skip_variables_check } = activeElement.config

  return (
    <Card className="px-3 pt-1 pb-1">
      <div className="flex items-center flex-nowrap cursor-pointer py-1.5">
        <PlainSwitch
          size={100}
          isChecked={skip_variables_check}
          onCheckedChange={(newValue) => toggleSkipVariablesCheck(activeElement.uuid, newValue)}
        />

        <Label
          isCompact
          label="Skip processing variables for element"
          onClick={() => {
            toggleSkipVariablesCheck(activeElement.uuid)
          }}
          className="whitespace-nowrap truncate min-w-0 flex-[1_1_auto] ml-2 cursor-pointer"
        />

        <Tooltip
          small
          contrast
          glyph="info"
          content={
            <>
              <div>When checked, this will skip processing any variable values in this element.</div>
              <div>
                This is useful when you need to use the variable notion <b>{`{{ … }}`}</b> as is in the element content.
              </div>
              <div>
                <span
                  className="font-[600] text-blue-500 cursor-pointer"
                  onClick={() => {
                    setActiveTab('variables')
                  }}
                >
                  Go to Variables tab
                </span>{' '}
                to view and manage all variables in this form.
              </div>
            </>
          }
        />
      </div>
    </Card>
  )
}
