import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'

import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import ProgramProfileHeader from '../../components/ProgramProfileHeader'
import Section from '../../components/Section'
import Status from '../../components/Status'
import Textarea from '../../components/Forms/Textarea'

const RootProgramListOverlay = (props: any) => {
  const { resource_id }: any = useParams()

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialData,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'program-list',
    endpoint: '/phases',
    invalidate: 'program-lists',
    invalidateKeys: [
      'program-timeline',
      'programs-timeline',
      ['program', resource_id, 'phases-with-residents'],
      ['program', resource_id, 'phases-with-seats'],
    ],
    options: props,
    isEditable: props.isEditable,
  })

  const [managingStaff, setManagingStaff] = React.useState([])

  // get program data from API if not passed via router
  const { data: apiProgram, isLoading: isProgramLoading }: any = useGet({
    name: ['program', resource_id],
    url: `/programs/${resource_id}`,
    options: { enabled: !!resource_id && !initialData?.program },
  })

  // get program data from router or from API
  const programData = initialData?.program || apiProgram

  const { timezone } = useSettings()

  // const save = () => {
  //   const model = form.current.getFormValue()

  //   const employee_ids: any = []
  //   const contact_ids: any = []

  //   if (size(managingStaff) >= 1) {
  //     for (const record of managingStaff) {
  //       if (record.type === 'employee') employee_ids.push(record.id)
  //       else if (record.type === 'contact') contact_ids.push(record.id)
  //     }
  //   }

  //   const saveData = produce(model, (draft: any) => {
  //     delete draft.employees_ids

  //     draft.employee_ids = employee_ids
  //     draft.contact_ids = contact_ids
  //   })

  //   saveWithData(saveData)
  // }

  React.useEffect(() => {
    if (!data?.employees) return

    setManagingStaff(data.employees)
  }, [data])

  if (isOverlayLoading || isProgramLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay showBackdrop={isEditable} position="right" onClose={close}>
      <Overlay.Header
        icon="program_lists"
        title={isNew ? 'Add New List' : 'Edit List'}
        description={programData && <ProgramProfileHeader program={programData} />}
      />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && { program_id: resource_id }}
        >
          <Section>
            <FormSection>
              <Input
                label="List Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a list name',
                  },
                }}
              />

              <Textarea label="Description" model="description" />

              <MultiObjectSelector
                showAvatars
                isPolymorphic
                label="Managing Staff"
                model="employees"
                blankLabel="Select Staff…"
                type="employees"
                icon="employees"
                // dependentValue={programData?.id}
                onUpdate={({ object }) => {
                  setManagingStaff(object)
                }}
                selectDescription={(record: any) => {
                  if (record.type === 'employee') {
                    return (
                      <Flex>
                        <Status small label="Staff" color="blue" />
                      </Flex>
                    )
                  }

                  if (record.type === 'contact') {
                    return (
                      <Flex centerY gap="0.5rem">
                        <Status small label="Contact" color="orange" />

                        {record.reference && (
                          <Flex centerY nowrap>
                            <Icon icon="organizations" size={18} className="!mr-2" />
                            <div>{record.reference.name}</div>
                          </Flex>
                        )}
                      </Flex>
                    )
                  }

                  return null
                }}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Seat?"
              message="Are you sure you want to delete this seat? This action cannot be undone."
              onYes={deleteRecord}
              isDisabled={!data?.is_deletable}
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                isDisabled={!data?.is_deletable}
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ProgramListOverlay = withOverlayError(RootProgramListOverlay)
