import React from 'react'

import { COLORS } from '../../../theme'
import Glyph from '../../../components/Glyph'

export const Filter = (props: any) => {
  const { children, onClear, testKey } = props

  return (
    <div data-test={testKey} css={STYLES.filter}>
      <div css={STYLES.filterMain}>{children}</div>

      {onClear && (
        <button data-test="delete_filter_button" type="button" onClick={onClear} css={STYLES.clearButton}>
          <Glyph glyph="close" color={COLORS.text} size={10} />
        </button>
      )}
    </div>
  )
}

const STYLES = {
  filter: {
    display: 'grid',
    alignItems: 'flex-start',
    gridGap: '0.25rem',
    gridTemplateColumns: '1fr min-content',
    flexWrap: 'nowrap',
    fontSize: '0.85rem',
    padding: '0.75rem 0',

    '& + &': {
      borderTop: `1px solid ${COLORS.divider}`,
    },

    '@media(min-width: 600px)': {
      padding: '0.5rem 0',
    },
  },

  filterMain: {
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateColumns: '1fr',
    gridAutoFlow: 'row',
    gridGap: '0.5rem',

    '@media(min-width: 600px)': {
      gridTemplateColumns: '160px 110px 160px',
    },
  },

  clearButton: {
    background: 'none',
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'var(--filter-height)',
    height: 'var(--filter-height)',
    cursor: 'pointer',
    borderRadius: 4,
    outline: 'none',

    '&:hover': {
      background: COLORS.hover,
      svg: { fill: COLORS.red },
    },
  },
}
