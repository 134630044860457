import React from 'react'
import { useLocation } from 'react-router-dom'

import { usDate } from '../../utils/functions'
import { LOC } from '../../utils/constants'

import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'
import DataFormStatus from '../Statuses/DataFormStatus'

type Props = {
  data: any
  location: any
  timezone: any
  to: any
}

const InsuranceAuthorization = ({ data, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={LOC[data?.data?.authorization?.level_of_care]} />
      <CardMeta>
        <DataFormStatus status={data.status} />
        <Text avatar={data?.author?.avatar} label={data?.author?.name} />
        <Text glyph="date" label="From: " description={usDate(data?.data?.authorization?.started_at, timezone)} />
        <Text glyph="date" label="Until: " description={usDate(data?.data?.authorization?.ended_at, timezone)} />
        <Text
          glyph="date"
          label="Progress Review Due Date: "
          description={usDate(data?.data?.authorization?.progress_review_due_at, timezone)}
        />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(InsuranceAuthorization)
