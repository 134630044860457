import React from 'react'

import { apiCreate } from '../../../modules/api'
import { useSettings } from '../../../hooks'
import { withOverlayError } from '../../../hocs/withOverlayError'

import Alert from '../../Alert'
import Button from '../../Button'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Textarea from '../../Forms/Textarea'
import Checkbox from '../../Forms/Checkbox'
import Status from '../../Status'
import Grid from '../../Grid'
import Emoji from '../../Emoji'
import Flex from '../../Flex'

import Notifications from '../../../modules/notifications'

const RequestVOBOverlay = ({ client, onClose }: any) => {
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [valid, setValid] = React.useState(false)
  const [confirmed, setConfirmed] = React.useState(false)

  const form = React.useRef()
  const { online } = useSettings()

  const requestFullVOB = async () => {
    setLoading(true)

    try {
      await apiCreate({
        url: `/insurance_vob_requests`,
        params: {
          client_id: client?.id,
          client_type: 'Resident',
          ...form.current.getFormValue(),
        },
        notify: false,
      })

      Notifications.send("We registered your Full VOB Request and we'll get back to you shortly", 'positive', 10000)

      setSuccess(true)
      // if (onClose) onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header glyph="behave_health" title="Request Full VOB" />

      <Overlay.Content>
        {!success && (
          <Form getForm={form} onValidationUpdate={setValid}>
            <Section>
              <FormSection>
                <Alert glyph="warning" type="warning">
                  Any Full VOBs requested before 8AM or after 5PM PST may have limited benefits information or may not be completed until
                  normal insurance business hours resume.
                </Alert>

                <ObjectSelector
                  label="Insurance Policy"
                  icon="insurance"
                  model="insurance_policy"
                  type="client.insurance_policies"
                  dependentValue={client.id}
                  selectTitle={(data: any) => data.insurance_name}
                  selectDescription={(data: any) => <Status label={data.category} color="gray" />}
                  validations={{
                    presence: {
                      message: 'Please select an insurance policy to verify',
                    },
                  }}
                />

                <Textarea useQuickText label="Notes for Behave Health" model="notes" />

                <Checkbox
                  model="confirmation"
                  label="I confirm that the Client Demographics and the Insurance Details are up to date before sending this request"
                  onUpdate={(state: any) => setConfirmed(state.value)}
                />
              </FormSection>
            </Section>
          </Form>
        )}

        {success && (
          <div css={{ padding: '2rem', textAlign: 'center' }}>
            <Grid centerX centerY>
              <Emoji emoji="🎉" size={40} />
              <h2>Request Sent</h2>
            </Grid>
            <p>You successfully sent us a Full VOB Request</p>
          </div>
        )}
      </Overlay.Content>

      {!success && (
        <Overlay.Footer online={online}>
          <Button
            label="Send Full VOB Request"
            type="primary"
            color="green"
            isLoading={loading}
            onClick={requestFullVOB}
            isDisabled={!valid || !confirmed}
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export default withOverlayError(RequestVOBOverlay)
