import React from 'react'
import startCase from 'lodash/startCase'

import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'
import { useSettings } from '../../hooks'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 250,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} avatar="" />,
  },
  {
    width: 130,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => {
      if (value == 'active') return <Status color="green" label="Active" />
      else if (value == 'inactive') return <Status color="red" label="Inactive" />
      return <TableCell.NotApplicable />
    },
  },
  {
    width: 130,
    accessor: 'dob',
    Header: 'Date of Birth',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
  },
  {
    width: 130,
    accessor: 'relationship',
    Header: 'Relationship',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
  },
  {
    width: 200,
    accessor: 'is_emergency',
    Header: 'Emergency Contact',
    Cell: ({ value }: any) => {
      if (!value) return 'Non-emergency'
      return <Status color="yellow" label="Emergency Contact" />
    },
  },
  {
    width: 180,
    accessor: 'roi',
    Header: 'Release of Information',
    Cell: ({ value }: any) => {
      if (!value) return 'Not allowed'
      return <Status color="green" label="R.O.I. Allowed" />
    },
  },
  {
    width: 210,
    accessor: 'preferred_contact_method',
    Header: 'Preferred Contact Method',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell.NotApplicable />
      return <TableCell.GlyphValue glyph={value === 'any' ? null : value} value={startCase(value)} />
    },
  },
  {
    width: 130,
    accessor: 'notes',
    Header: 'Notes',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  isLoading: boolean
  localStorageKey: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const ClientContactsTable = ({ data, emptyActions, isLoading, batchActionsConfig, localStorageKey, to, titleAfter }: Props) => {
  const { timezone } = useSettings()

  return (
    <Table
      title="Contacts"
      testKey="client_contacts_table"
      titleAfter={titleAfter}
      icon="contacts"
      data={data}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No contacts added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

ClientContactsTable.defaultProps = {
  localStorageKey: 'client_contacts',
}

export default ClientContactsTable
