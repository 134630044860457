import React from 'react'

import DateInput from '../../../components/Forms/DateInput'
import Flex from '../../../components/Flex'

export const TreatmentPlanRecordDates = ({ showStart = true, showTarget = true, showCompletion = true }) => {
  if (showStart === false && showTarget === false && showCompletion === false) return null

  return (
    <>
      <Flex stretchChildrenX gap="1rem">
        {showStart && <DateInput defaultToNow label="Start" model="started_at" />}
        {showTarget && <DateInput label="Target" model="target_at" />}
        {showCompletion && <DateInput label="Completion" model="completed_at" />}
      </Flex>
    </>
  )
}
