import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useAPI from '@behavehealth/hooks/useAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Alert, Button, Card, Grid, Page } from '@behavehealth/components'

import { InsuranceCodeOverlayV6 } from '@behavehealth/components/Overlays/pages/InsuranceCodeOverlay'
import InsuranceCodesTable from '@behavehealth/components/Tables/InsuranceCodesTable'
import InsuranceCodeTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/InsuranceCodeTemplatesImportOverlay'

const pageConfig = {
  title: 'Insurance Codes',
  plan: 'basic',
  feature: 'insurance',
}

const InsuranceCodes = () => {
  return (
    <>
      <InsuranceCodesIndex />

      <AnimatedRoutes>
        <Route path="import" element={<InsuranceCodeTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<InsuranceCodeOverlayV6 useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const InsuranceCodesIndex: React.FC = () => {
  const data = useSelector((state: any) => state.data?.insurance_codes?.data)
  const loading = useSelector((state: any) => state.data?.insurance_codes?.loading)

  useAPI('insurance_codes', '/insurance_codes')

  return (
    <Page
      {...pageConfig}
      actions={
        <>
          <Button as={Link} label="Import Behave Templates" type="default" glyph="add" link={`import`} />
          <Button
            as={Link}
            label="Add Insurance Code"
            type="primary"
            glyph="add"
            link={`new`}
            permission="settings.out_of_network_services.create"
          />
        </>
      }
    >
      <Grid gap="1rem">
        <Alert contrast glyph="info">
          This list is used when defining the Fee Schedule for your payers
        </Alert>
        <Card>
          <InsuranceCodesTable mainLinkAs={NavLink} data={data} to={(id: string) => `${id}`} isLoading={loading} />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(InsuranceCodes, pageConfig))
