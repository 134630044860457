import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Grid, Tabs, TabList, Tab, HelpTagIframe, Page, Uploader } from '@behavehealth/components'

import Reports from './reports'
import Files from './files'

const pageConfig = {
  feature: 'reports',
  help: <HelpTagIframe id="rcm_reports" />,
  marketingID: 'rcm_reports',
}

const RCM: React.FC = () => {
  const tenant = useSelector((state: any) => state.me?.tenant)

  const { pathname } = useLocation()
  const { url } = useRouteMatch()

  let category = 'rcm'
  if (pathname.includes(`${url}/reports`)) {
    category = 'rcm'
  } else if (pathname.includes(`${url}/files`)) {
    category = 'rcm_files'
  }

  return (
    <Page
      breakpoint="0"
      actions={
        <Uploader category={category} resource={tenant}>
          <Button type="primary" glyph="upload" label="Upload Files" permission="rcm_reports.create" />
        </Uploader>
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        <Tabs>
          <TabList>
            <Tab label="Reports" to="reports" />
            <Tab label="Files" to="files" />
          </TabList>
        </Tabs>

        <Switch>
          <Route path={`${url}/reports`} component={Reports} />
          <Route path={`${url}/files`} component={Files} />

          <Redirect exact from={url} to={`${url}/reports`} />
        </Switch>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(RCM, pageConfig))
