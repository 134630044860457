import React from 'react'
import { produce } from 'immer'
import { v4 as uuid } from 'uuid'
import isEqual from 'react-fast-compare'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'

import { Worksheet as BHWorksheet } from '../../../../components/Worksheet/Worksheet'

import Alert from '../../../../components/Alert'
import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Forms/Checkbox'
import Input from '../../../../components/Forms/Input'
import Label from '../../../../components/Label'
import Overlay from '../../../../components/Overlay'
import Section from '../../../../components/Section'
import SummonOverlay from '../../../../components/SummonOverlay'
import Textarea from '../../../../components/Forms/Textarea'
import Tooltip from '../../../../components/Tooltip'

import { VariablesDropdownMenu } from '../../components/VariablesDropdownMenu'

const toSnakeCase = (str: string) => {
  const formatted = str?.trim()?.toLowerCase?.() || ''

  return snakeCase(formatted)
}

export const WorksheetEditor = (props: any) => {
  const {
    activeElement,
    editElementConfig,
    editActiveElementFromInput,
    activeElementId,
    appendVariableToElementModel,
    setElementConfigValue,
  } = props

  const {
    initial_data,
    worksheet_columns,
    label = '',
    description = '',
    title = '',
    tooltip = '',
    allow_create_delete_rows,
  } = activeElement.config

  const [modelSelectOptions, setModelSelectOptions] = React.useState(null)

  const currentSelectOptions = React.useMemo(() => {
    return worksheet_columns.find((column) => column.model === modelSelectOptions)?.config?.options || []
  }, [worksheet_columns, modelSelectOptions])

  const clearInitialData = (model: string) => {
    if (!initial_data || !model) return

    const newInitialData = produce(initial_data, (draft) => {
      for (const row of draft) {
        delete row[model]
      }
    })

    setElementConfigValue({
      uuid: activeElementId,
      key: 'initial_data',
      value: newInitialData,
    })
  }

  const COLUMNS = React.useMemo(
    () => [
      {
        title: 'Title',
        model: 'title',
        width: 120,
      },
      {
        title: 'Type',
        model: 'type',
        type: 'select',
        width: 140,
        config: {
          options: [
            { label: 'Single-Line Text', value: 'input' },
            { label: 'Multi-Line Text', value: 'textarea' },
            { label: 'Number', value: 'number' },
            { label: 'Checkbox', value: 'checkbox' },
            { label: 'Date & Time', value: 'date_time' },
            { label: 'Rich Text', value: 'rich_text' },
            { label: 'Single Select', value: 'select' },
          ],
          renderAfterCell: ({ value, rowData }) => {
            if (value !== 'select' || !rowData?.model) return null

            return (
              <Tooltip content="Edit select options">
                <Button
                  hideLabel
                  glyph="selector"
                  size={100}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setModelSelectOptions(rowData.model)
                  }}
                />
              </Tooltip>
            )
          },
          renderMenuBefore: () => {
            return (
              <div className="p-3">
                <Alert small glyph="info">
                  <b>Please note:</b> changing the column type
                  <br />
                  will reset any column data.
                </Alert>
              </div>
            )
          },
          validations: {
            presence: {
              message: 'Please select a column type',
            },
          },
        },
      },
    ],
    [modelSelectOptions, setModelSelectOptions],
  )

  return (
    <>
      <Input
        label="Label"
        model="label"
        value={label}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'label' })
            }}
          />
        }
      />

      <Input
        label="Title"
        model="title"
        value={title}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'title' })
            }}
          />
        }
      />

      <Textarea
        label="Description"
        model="description"
        value={description}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'description' })
            }}
          />
        }
      />
      <Textarea
        label="Info Tooltip"
        model="tooltip"
        value={tooltip}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'tooltip' })
            }}
          />
        }
      />

      <Checkbox
        defaultChecked={isUndefined(allow_create_delete_rows) ? true : undefined}
        label="Allow Creating & Deleting Rows"
        model="allow_create_delete_rows"
        value={isUndefined(allow_create_delete_rows) ? true : allow_create_delete_rows}
        onUpdate={editActiveElementFromInput}
      />

      <div>
        <Label isCompact label="Columns" className="!mb-1" />
        <BHWorksheet
          asCard
          withFullScreenToggle
          withWrapToggle={false}
          withShowInvalidToggle={false}
          key={`updated-${activeElement.config?.updated_at}`}
          wrapping="clip"
          title="Columns"
          allow="create-update-delete"
          titleClassName="!mr-auto"
          columns={COLUMNS}
          initialData={worksheet_columns}
          defaultNewRow={DEFAULT_NEW_ROW}
          onDataChange={(dataMap: any, dataIds: any) => {
            const newColumns: any = []

            if (!dataMap || !dataIds) return newColumns

            for (let i = 0; i < dataIds.length; i++) {
              const _id = dataIds[i]
              const column = dataMap[_id]

              const newColumn = {
                _id,
                model: _id,
                id: column.id,
                title: column.title,
                config: column.config,
                type: column.type || 'input',
              }

              newColumns.push(newColumn)

              const previousType = worksheet_columns?.find((c) => c._id === newColumn._id)?.type

              if (previousType !== newColumn.type) {
                clearInitialData(newColumn.model)
              }
            }

            if (isEqual(worksheet_columns, newColumns)) return

            editElementConfig({
              uuid: activeElement.uuid,
              config: {
                worksheet_columns: newColumns,
              },
            })
          }}
        />
      </div>

      <SummonOverlay
        isOpen={!!modelSelectOptions && !!currentSelectOptions}
        onClose={() => {
          setModelSelectOptions(null)
        }}
        overlay={
          <SelectOptionsOverlay
            initialValue={currentSelectOptions}
            onSave={(options = []) => {
              const newColumns: any = []

              for (const column of worksheet_columns) {
                if (column.model !== modelSelectOptions) {
                  newColumns.push(column)
                  continue
                }

                newColumns.push({
                  ...column,
                  type: 'select',
                  config: { options },
                })
              }

              setElementConfigValue({
                uuid: activeElementId,
                key: 'worksheet_columns',
                value: newColumns,
              })
            }}
          />
        }
      />
    </>
  )
}

const DEFAULT_NEW_ROW = (dataMap: any) => {
  const count = size(dataMap) + 1

  const id = uuid()

  return {
    _id: id,
    title: `Column ${count}`,
    type: 'input',
    model: id,
  }
}

const SelectOptionsOverlay = (props) => {
  const { onSave, onClose, initialValue } = props

  const [options, setOptions] = React.useState(initialValue?.map((o) => o.label)?.join('\n') || '')

  const handleSave = () => {
    const optionsArray = options.split('\n') || []
    const newOptions: any = []

    for (const option of optionsArray) {
      const trimmed = option?.trim?.()

      if (!trimmed || newOptions.includes(trimmed)) continue

      newOptions.push(trimmed)
    }

    onSave(newOptions.map((o) => ({ label: o, value: toSnakeCase(o) })))
    onClose()
  }

  return (
    <Overlay showBackdrop onClose={onClose}>
      <Overlay.Header title="Edit Select Options" glyph="selector" />

      <Overlay.Content>
        <Section>
          <Textarea
            label="Options"
            description="Separate values with new lines. Please note: only unique values will be saved"
            value={options}
            minRows={16}
            onUpdate={({ value }) => setOptions(value)}
          />
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" glyph="tick_circle" color="green" type="primary" onClick={handleSave} />
      </Overlay.Footer>
    </Overlay>
  )
}
