import React from 'react'
import startCase from 'lodash/startCase'

import { COMMUNICABLE_DISEASE_TESTS } from '../../utils/constants'
import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks'

import HelpTagIframe from '../Help/HelpTagIframe'
import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 200,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'recorded_at',
    Header: 'Date & Time',
    Cell: ({ row, value }: any) => <TableCell.MainLink to={to(row.original.id)} label={usDateTime(value, timezone)} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    id: 'client',
    accessor: 'client',
    Header: 'Client',
    Cell: ({ value }: any) => <TableCell.Profile avatar={value?.avatar} name={value?.name} />,
  },
  {
    width: 180,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }) => {
      if (value === 'refused') return <Status color="red" label="Refused Collection" />
      if (value === 'collected') return <Status color="blue" label="Collected" />
      return <TableCell.NotApplicable />
    },
  },
  {
    width: 220,
    accessor: 'category',
    Header: 'Test Type',
    Cell: ({ value }: any) => COMMUNICABLE_DISEASE_TESTS[value] || <TableCell.Empty />,
  },
  {
    width: 160,
    accessor: 'result',
    Header: 'Result',
    Cell: ({ row, value }) => {
      if (row.original.status === 'refused') return <TableCell.NotApplicable />
      if (value === 'positive') return <Status color="red" label="Positive" />
      if (value === 'negative') return <Status color="green" label="Negative" />
      if (value === 'not_available') return <Status color="grey" label="N/A" />
      return <TableCell.NotApplicable />
    },
  },
  {
    width: 160,
    accessor: 'quantitative_measure',
    Header: 'Quantitative Measure',
    Cell: ({ value }: any) => (value ? startCase(value) : <TableCell.Empty />),
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const CommunicableDiseaseTestsTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, localStorageKey, batchActionsConfig, hiddenColumns, titleAfter } = props
  const { timezone } = useSettings()

  return (
    <Table
      title="Communicable Disease Tests"
      testKey="communicable_disease_tests_table"
      titleAfter={titleAfter}
      icon="test_results"
      data={data}
      columns={columns(to, timezone)}
      hiddenColumns={hiddenColumns}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No communicable disease tests added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      help={<HelpTagIframe id="test_results_communicable_disease_tests" />}
    />
  )
}

CommunicableDiseaseTestsTable.defaultProps = {
  localStorageKey: 'communicable_disease_tests',
}

export default CommunicableDiseaseTestsTable
