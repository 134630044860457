import React from 'react'

import DateInput from './DateInput'
import FormSection from './FormSection'
import Glyph from '../Glyph'

import { COLORS } from '../../theme'
import { DT } from '../../utils/functions'

import withSettings from '../../hocs/withSettings'

const WeekRangeInput = ({ form, startModel, endModel, startLabel, endLabel, startInitialDate, endInitialDate, timezone, ...rest }) => {
  const [selectedStartDate, setSelectedStartDate] = React.useState(startInitialDate)
  const [selectedEndDate, setSelectedEndDate] = React.useState(endInitialDate)

  const isDayMonday = (date) => date.getDay() === 1
  const minEndDate = selectedStartDate ? DT(selectedStartDate).plus({ week: 1 }).startOf('week').toJSDate() : null

  const onSelectedStartDate = (date) => {
    setSelectedStartDate(date)

    if (selectedEndDate) {
      if (DT(selectedEndDate, timezone).diff(DT(date, timezone), 'days').values?.days <= 0) setSelectedEndDate(null)
    }
  }

  return (
    <FormSection horizontal className="items-center">
      <DateInput
        label={startLabel}
        model={startModel}
        value={startInitialDate}
        filterDate={isDayMonday}
        allowInput={false}
        css={styles}
        onChange={onSelectedStartDate}
        {...rest}
      />
      <Glyph glyph="chevron" color={COLORS.blue} />
      <DateInput
        label={endLabel}
        model={endModel}
        value={selectedEndDate}
        filterDate={isDayMonday}
        allowInput={false}
        css={styles}
        onChange={setSelectedEndDate}
        minDate={minEndDate}
        {...rest}
      />
    </FormSection>
  )
}

const styles = {
  minWidth: 140,
}

WeekRangeInput.defaultProps = {
  startModel: 'start_date',
  endModel: 'end_date',
  startLabel: 'Start Date',
  endLabel: 'End Date',
}

export default withSettings(WeekRangeInput)
