import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { CUSTOM_NOTE_PORTAL_SETTINGS } from '../../utils/constants'

export const CustomNoteTemplatesDataTable = (props: any) => {
  const { to, title = 'Custom Note Templates', icon = 'custom_notes', mainLinkAs } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell as={mainLinkAs} id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 200,
        model: 'settings.require_supervisor_signature',
        title: 'Supervisor Signature',
        type: 'boolean',
      },
      {
        width: 200,
        model: 'settings.require_reference_signature',
        title: 'Client Signature',
        type: 'boolean',
      },
      {
        width: 200,
        model: 'portal_settings',
        title: 'Portal Settings',
        formatValue: ({ value }: any) => CUSTOM_NOTE_PORTAL_SETTINGS[value] || null,
      },
      {
        width: 180,
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return <DataTable asCard testKey="custom_notes_data_table" title={title} icon={icon} columns={columns} {...props} />
}
