import React from 'react'

import Tabs from '../../../Tabs'

import { AdmissionsClientsTable } from './clients/AdmissionsClientsTable'
import { CurrentClientsTable } from './clients/CurrentClientsTable'

export const AdmissionsAndCurrentClientsTable = (props: any) => (
  <Tabs defaultTab="current_clients">
    <Tabs.List className="ml-2">
      <Tabs.Item icon="admissions" label="Admissions" name="admissions" />
      <Tabs.Item icon="clients" label="Current Clients" name="current_clients" />
    </Tabs.List>

    <Tabs.Panels>
      <Tabs.Panel name="admissions" type="mount">
        <AdmissionsClientsTable {...props} />
      </Tabs.Panel>

      <Tabs.Panel name="current_clients" type="mount">
        <CurrentClientsTable {...props} />
      </Tabs.Panel>
    </Tabs.Panels>
  </Tabs>
)
