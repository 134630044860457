import React, { Component } from 'react'
import { keyframes } from '@emotion/react'
import Fuse from 'fuse.js'
import isEqual from 'react-fast-compare'
import produce from 'immer'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { SHADOW, COLORS } from '../../../../theme'

import Button from '../../../Button'
import Checkbox from '../../Checkbox'
import Flex from '../../../Flex'
import Search from '../../../Search'
import State from '../../../State'

export default class MultiSelectorMenu extends Component {
  constructor(props) {
    super(props)

    // reconcile data between selected and the api data
    const reconciliatedData = produce(props.apiData, (draft) => {
      for (let i = 0; i < size(props.selectedData); i++) {
        for (let j = 0; j < size(draft); j++) {
          if (props.selectedData[i].id === draft[j].id) {
            draft[j].checked = true
            draft[j].isRequired = props.selectedData[i].isRequired || false
          }
        }
      }
    })

    let groupedData: any = {}
    if (props.groupBy) groupedData = this.groupData({ data: reconciliatedData, groupBy: props.groupBy })

    this.state = {
      search: '',
      apiData: props.apiData,
      groupedData: groupedData,
      reconciliatedData: reconciliatedData,
      selectedData: props.selectedData,
      searchResults: reconciliatedData,
      autoFocus: false,
    }

    this.fuseOptions = {
      shouldSort: true,
      keys: props.searchKeys,
      threshold: 0.4,
    }

    this.fuse = new Fuse(reconciliatedData, this.fuseOptions)
  }

  // * LIFECYCLE
  componentDidMount(): void {
    setTimeout(() => {
      this.setState({ autoFocus: true })
    }, 0)
  }

  componentDidUpdate = (prevProps: any) => {
    if (!isEqual(prevProps.apiData, this.props.apiData)) {
      // reconcile data between selected and the api data
      const reconciliatedData = produce(this.props.apiData, (draft) => {
        for (let i = 0; i < size(this.props.selectedData); i++) {
          for (let j = 0; j < size(draft); j++) {
            if (this.props.selectedData[i].id === draft[j].id) {
              draft[j].checked = true
              draft[j].isRequired = this.props.selectedData[i].isRequired || false
            }
          }
        }
      })

      let groupedData: any = {}
      if (this.props.groupBy) groupedData = this.groupData({ data: reconciliatedData, groupBy: this.props.groupBy })

      this.fuse = new Fuse(reconciliatedData, this.fuseOptions)

      this.setState({
        reconciliatedData: reconciliatedData,
        searchResults: reconciliatedData,
        groupedData: groupedData,
      })
    }
  }

  componentWillUnmount = () => {
    let selected = []
    for (let i = 0; i < this.state.reconciliatedData.length; i++) {
      if (this.state.reconciliatedData[i].checked) selected.push(this.state.reconciliatedData[i])
    }

    this.props.onSelect(selected)
  }

  // * CUSTOM FUNCTIONS
  stopPropagation = (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  onSearch = (search: string) => {
    const { reconciliatedData } = this.state

    if (size(reconciliatedData) === 0) return
    let results = []

    if (search?.length > 0) {
      const searchResults = this.fuse.search(search)
      for (let i = 0; i < searchResults.length; i++) results.push(searchResults[i].item)
    } else {
      results = this.state.reconciliatedData
    }

    this.setState({ searchResults: results, search: search })
  }

  onClear = () => {
    const newState = produce(this.state, (draft) => {
      for (let i = 0; i < draft.reconciliatedData.length; i++) {
        if (draft.reconciliatedData[i].checked) draft.reconciliatedData[i].checked = false
      }

      draft.searchResults = draft.reconciliatedData
    })

    this.setState(newState)
  }

  processItem = (item, value) => {
    const state = produce(this.state, (draft) => {
      for (let i = 0; i < draft.reconciliatedData.length; i++) {
        if (draft.reconciliatedData[i].id === item.id) draft.reconciliatedData[i].checked = value
      }
    })

    // apply search
    if (state.search) {
      this.fuse = new Fuse(state.reconciliatedData, this.fuseOptions)
      this.setState({
        reconciliatedData: state.reconciliatedData,
        // searchResults: this.fuse.search(state.search),
      })
    } else {
      this.setState({ reconciliatedData: state.reconciliatedData })
    }
  }

  groupData = ({ data, groupBy }: any) => {
    const groups: any = {}

    const { accessor, sections } = groupBy
    const sectionKeys = Object.keys(sections)

    for (const record of data) {
      const value = record[accessor]

      if (sectionKeys.includes(value)) {
        if (!groups[value]) groups[value] = []
        groups[value].push(record)
      } else {
        if (!groups['_other']) groups['_other'] = []
        groups['_other'].push(record)
      }
    }

    return groups
  }

  // * RENDER
  render() {
    const { showAvatars, emptyActions, groupBy, icon, isLoading, onClose, selectDescription, selectTitle, type, testKey, selectGraphic } =
      this.props
    const { groupedData, reconciliatedData, search, searchResults } = this.state

    const shouldShowGroups = !search && groupBy && groupedData

    const hasAPIData = size(reconciliatedData) === 0
    const hasResults = shouldShowGroups ? size(groupedData) > 0 : size(searchResults) > 0

    return (
      <div data-test={testKey} tabIndex="0" css={rootStyles} onClick={this.stopPropagation}>
        {!hasAPIData && (
          <div css={headerStyles}>
            <Search autoFocus={this.state.autoFocus} onChange={this.onSearch} placeholder="Search…" />
          </div>
        )}

        <div css={contentStyles}>
          {isLoading ? (
            <State isLoading />
          ) : (
            <>
              {hasResults ? (
                <>
                  {shouldShowGroups &&
                    Object.keys(groupedData).map((groupKey: string) => (
                      <div key={groupKey}>
                        <h3 css={groupHeaderStyles}>{groupBy.sections[groupKey]}</h3>

                        {groupedData[groupKey].map((item: any) => (
                          <Checkbox
                            key={`${item.id}_${item.checked}`}
                            item={item}
                            label={selectTitle?.(item) || item.name}
                            description={selectDescription?.(item) || item.description}
                            avatar={showAvatars ? item.avatar || '' : item.avatar}
                            avatarInitials={selectTitle?.(item) || item.name}
                            value={item.checked}
                            isDisabled={item.isRequired}
                            onUpdate={(state) => this.processItem(item, state.value)}
                            css={checkboxStyles}
                            testKey="selector_item"
                          />
                        ))}
                      </div>
                    ))}

                  {!shouldShowGroups &&
                    searchResults.map((item) => (
                      <Checkbox
                        key={`${item.id}_${item.checked}`}
                        item={item}
                        graphic={selectGraphic?.(item)}
                        label={selectTitle?.(item) || item.name}
                        description={selectDescription?.(item) || item.description}
                        avatar={showAvatars ? item.avatar || '' : item.avatar}
                        avatarInitials={selectTitle?.(item) || item.name}
                        value={item.checked}
                        isDisabled={item.isRequired}
                        onUpdate={(state) => this.processItem(item, state.value)}
                        css={checkboxStyles}
                        testKey="selector_item"
                      />
                    ))}
                </>
              ) : (
                <State
                  isEmpty
                  icon={icon}
                  title={type && startCase(type)}
                  emptyActions={emptyActions}
                  emptyDescription={type ? `No ${startCase(type)} found` : 'No data found'}
                />
              )}
            </>
          )}
        </div>

        {hasResults && (
          <Flex nowrap gap={8} css={{ padding: 8, flex: '0 0 auto' }}>
            <Button
              glyph="add"
              testKey="add_selected_button"
              label="Add Selected"
              type="primary"
              color="blue"
              onClick={onClose}
              flex="2 1 auto"
            />
            <Button glyph="close" testKey="clear_all_button" label="Clear All" type="minimal" color="red" onClick={this.onClear} />
          </Flex>
        )}
      </div>
    )
  }
}

const mountAnimation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(-4px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const rootStyles = {
  background: 'white',
  WebkitOverflowScrolling: 'touch',

  borderRadius: 7,
  boxShadow: `${SHADOW(10, COLORS.divider)}, 0 0 0 0.5px ${COLORS.divider}`,

  top: '100%',
  width: '100%',
  height: 380,
  overflow: 'hidden',
  outline: 0,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  transformOrigin: 'top center',
  animation: `${mountAnimation} 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,
}

const headerStyles = {
  flex: '0 0 auto',
  boxShadow: `0 0.5px 0 ${COLORS.divider}`,
  position: 'relative',
  zIndex: 3,

  input: {
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  },
}

const contentStyles = {
  width: '100%',
  flex: '1 1 auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  position: 'relative',
  background: COLORS.white,
}

const checkboxStyles = {
  borderRadius: 0,
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',

  '&:first-of-type': {
    borderTop: `1px solid ${COLORS.divider}`,
  },
}

const groupHeaderStyles = {
  position: 'sticky',
  top: 0,
  zIndex: 3,
  margin: 0,
  padding: '0.3rem 1rem',
  fontSize: '0.88rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  letterSpacing: 1,
  background: COLORS.lightGray,
}
