import React from 'react'

import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootFoRSEOutcomesSurveyOverlay = (props: any) => {
  return (
    <DataFormOverlay
      disableSignOff
      minimizeEnabled
      clientPortalCanEdit
      enableTreatmentPlanSelector
      title="Outcomes Survey"
      category="outcome_measure"
      subcategory="forse_outcomes_survey"
      icon="outcome_measures"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      requestSupervisorSignature={false}
      signoffWithoutSupervisor={true}
      {...props}
    >
      <Section
        title="Questions"
        headingType="h2"
        scrollview={{
          id: 'questions',
          name: 'Questions',
        }}
      >
        <FormSection>
          <RadioGroup
            label="Over the last 30 days, have you used any of the following services?"
            model="data.prior_month_services"
            layout="vertical-dense"
          >
            <Radio label="None" value="none" />
            <Radio label="Detox" value="detox" />
            <Radio label="Residential" value="residential" />
            <Radio label="Partial Hospitalization (≥ 20 hrs of services weekly)" value="partial_hospitalization" />
            <Radio label="Intensive Outpatient (9-20 hrs of services weekly)" value="intensive_outpatient" />
            <Radio label="Outpatient (< 9 hrs of services weekly)" value="outpatient" />
            <Radio label="Sober Living/Recovery Residence" value="sober_living_recovery_residence" />
            <Radio label="Recovery Support (e.g., recovery coaching)" value="recovery_support" />
          </RadioGroup>

          <Input
            label="Over the last 30 days, how many recovery support meetings (e.g., AA, NA, or other peer support groups) have you attended?"
            type="number"
            size={4}
            model="data.prior_month_recovery_support_meetings"
          />

          <Input
            label="Over the last 30 days, how many total days have you used alcohol and/or drugs?"
            type="number"
            size={4}
            model="data.prior_month_substance_use_days"
          />

          <CheckboxGroup
            label="Which of the following medications are you currently taking in support of your recovery? (select all that apply)"
            layout="vertical-dense"
          >
            <Checkbox label="None" model="data.current_medication.none" />
            <Checkbox label="Acamprosate/Campral" model="data.current_medication.acamprosate_campral" />
            <Checkbox
              label="Buprenorphine (Subutex/Suboxone Film)"
              model="data.current_medication.buprenorphine_buprenorphine_subutex_suboxone_film"
            />
            <Checkbox label="Buprenorphine (Sublocade injection)" model="data.current_medication.buprenorphine_sublocade_injection" />
            <Checkbox label="Disulfiram/Antabuse" model="data.current_medication.disulfiram_antabuse" />
            <Checkbox label="Naltrexone (ReVia Pill)" model="data.current_medication.naltrexone_re_via_pill" />
            <Checkbox label="Naltrexone (Vivitrol Injection)" model="data.current_medication.naltrexone_vivitrol_injection" />
            <Checkbox label="Methadone" model="data.current_medication.methadone" />
            <Checkbox
              label="Medications for co-occurring disorder (e.g., anxiety or depression)"
              model="data.current_medication.co_occurring_disorder_medications"
            />
            <Checkbox label="Medication not listed" model="data.current_medication.medication_not_listed" />
            <Checkbox label="Other" model="data.current_medication.other" />
          </CheckboxGroup>

          <Input
            label="Over the last 30 days, how many days did you visit the hospital or ER, related to your substance use or recovery?"
            type="number"
            size={4}
            model="data.prior_month_hospital_visits"
          />

          <Input
            label="Over the last 30 days, how many incidents have you had with law enforcement?"
            type="number"
            size={4}
            model="data.prior_month_law_enforcement_incidents"
          />

          <RadioGroup label="What is your current employment status?" model="data.current_employment_status" layout="vertical-dense">
            <Radio label="Full Time" value="full_time" />
            <Radio label="Part Time" value="part_time" />
            <Radio label="Unemployed - seeking employment" value="unemployed_seeking_employment" />
            <Radio
              label="Unemployed - not seeking employment (retired, on disability, other source of income, etc…)"
              value="unemployed_not_seeking_employment"
            />
          </RadioGroup>

          <RadioGroup
            label="Have you experienced a relapse since discharging from treatment?"
            model="data.did_relapse_since_discharge"
            layout="horizontal-dense"
          >
            <Radio label="No" value={false} />
            <Radio label="Yes" value={true} />
          </RadioGroup>

          <RadioGroup label="How would you rate your quality of life?" model="data.quality_of_life_rating" layout="vertical-dense">
            <Radio label="Very poor" value="very_poor" />
            <Radio label="Poor" value="poor" />
            <Radio label="Neither poor nor good" value="neither_poor_nor_good" />
            <Radio label="Good" value="good" />
            <Radio label="Very good" value="very_good" />
          </RadioGroup>

          <RadioGroup
            label="How frequently are you using nicotine (smoking, vaping, or dipping)?"
            model="data.nicotine_use_frequency"
            layout="vertical-dense"
          >
            <Radio label="Daily" value="daily" />
            <Radio label="Weekly" value="weekly" />
            <Radio label="Less than weekly" value="less_than_weekly" />
            <Radio label="Not at all" value="not_at_all" />
          </RadioGroup>
        </FormSection>
      </Section>
    </DataFormOverlay>
  )
}

export const FoRSEOutcomesSurveyOverlay = withOverlayError(RootFoRSEOutcomesSurveyOverlay)
