import React from 'react'
import { Input } from './Input'
import { withFormContext } from './context'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { isBlank } from '../../utils/functions'

import LinkValue from '../LinkValue'

class MessengerInput extends Input {
  readOnlyRender = () => {
    if (isBlank(this.state.value)) return DEFAULT_EMPTY_VALUE

    return <LinkValue href={`http://m.me/${this.state.value}`} value={this.state.value} glyph={this.props.glyph} />
  }
}

MessengerInput.defaultProps = {
  glyph: 'facebook_messenger',
}

export default withFormContext(MessengerInput)
