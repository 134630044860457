import React from 'react'

import { address, titleCase } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useCreate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import ContextShow from '../../components/Forms/ContextShow'
import Button from '../../components/Button'
import Chotomate from '../../components/Chotomate'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Input from '../../components/Forms/Input'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Status from '../../components/Status'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import Timeline from '../../components/Timeline/Timeline'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'

import { useAccountingStore } from './store'

const RootAccountingVendorOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'accounting-vendor',
    endpoint: '/accounting/vendors',
    invalidate: 'accounting',
    options: props,
  })

  const addCategoryStatusCheck = useAccountingStore((state: any) => state.addCategoryStatusCheck)

  const syncMutation = useCreate({
    name: ['sync-accounting-vendor'],
    url: `/accounting/vendors/${id}/sync`,
    invalidate: ['accounting', 'status', { category: 'vendors' }],
    invalidateKeys: ['accounting-vendors'],
    onSuccess: () => {
      addCategoryStatusCheck('vendors')
    },
  })

  const [selectedReference, setSelectedReference]: any = React.useState(null)

  const sync = () => {
    syncMutation.mutateAsync({})
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const syncEnabled = selectedReference?.sync_with_accounting === true

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="accounting" title="Vendor" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          linked={{ reference_type: 'LocalOrganization' }}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection>
              <ObjectSelector
                icon={ICONS.organizations}
                label="Associated Record"
                type="local_organizations"
                model="reference"
                selectTitle={(data: any) => data.name}
                onUpdate={({ object }) => {
                  setSelectedReference(object)
                }}
                validations={{
                  presence: {
                    message: 'Please select an organizations',
                  },
                }}
              />

              {!isEditable && (
                <>
                  {syncEnabled && selectedReference ? (
                    <Button
                      glyph="reset"
                      label="Sync"
                      type="default"
                      isDisabled={isLoading}
                      onClick={sync}
                      isLoading={syncMutation.isLoading}
                      flex="100 1 auto"
                    />
                  ) : (
                    <>
                      {selectedReference ? (
                        <Alert type="warning" children={`Sync is disabled for ${selectedReference.name}`} />
                      ) : (
                        <Alert type="warning" children={`Please select an associated record to enable sync `} />
                      )}
                    </>
                  )}
                </>
              )}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit Vendor" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const AccountingVendorOverlay = withOverlayError(RootAccountingVendorOverlay)
