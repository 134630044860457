import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'

import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Select from '../../components/Forms/Select'
import Option from '../../components/Forms/Option'
import Input from '../../components/Forms/Input'
import Textarea from '../../components/Forms/Textarea'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

import ModifierCodesSelector from '../../components/Forms/elements/ModifierCodesSelector'
import RevenueCodeSelector from '../../components/Forms/elements/RevenueCodeSelector'

const RootInsuranceCodeOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isSaving,
    isOverlayLoading,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'insurance_new_codes',
    endpoint: '/insurance_new_codes',
    invalidate: 'insurance_new_codes',
    options: props,
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) return <OverlayLoader position="right" />

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="insurance" title="Insurance Code" />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
          maxWidth="100%"
        >
          <Section>
            <FormSection>
              <Input
                label="Service Name"
                model="service_name"
                validations={{
                  presence: {
                    message: 'Please enter a Service Name',
                  },
                }}
                description="Hint: Use a Service Name that will help your provider staff easily know what insurance code to select for a service."
              />

              <Grid gap="1rem" columns="1fr 1fr">
                <Input label="Procedure Code" model="procedure_code" />

                <Select label="Code Type" model="code_type" defaultValue="cpt">
                  <Option label="CPT" value="cpt" />
                  <Option label="HCPC/MOD" value="hcpc_mod" />
                  <Option label="HCPCS" value="hcpcs" />
                </Select>

                <RevenueCodeSelector label="Revenue Code" model="revenue_code" />
                <ModifierCodesSelector label="Modifier Codes" model="modifier_codes" />
              </Grid>

              <Select
                label="Unit Type"
                model="unit"
                className="!flex-1"
                validations={{
                  presence: {
                    message: 'Please select a unit',
                  },
                }}
                defaultValue="per_unit"
              >
                <Option label="Per Unit" value="per_unit" />
                <Option label="Per Day" value="per_day" />
              </Select>

              <Textarea label="Description" model="description" />
              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Alert glyph="warning" type="warning">
              Please note, when using a third-party billing company, Behave Health Corp. does not take responsibility for the accuracy of
              this data.
            </Alert>

            <Button
              label="Save Code"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Code"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="settings.insurance_codes.edit"
            />

            <DeleteDialog
              title="Delete Code?"
              message="Are you sure you want to delete this Code? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="settings.insurance_codes.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const InsuranceCodeOverlay = withOverlayError(RootInsuranceCodeOverlay)
