import React from 'react'

import { DataTable } from '../../../components/DataTable/DataTable'
import { useDataTable } from '../../../components/DataTable/useDataTable'

import ClaimStatus from '../ClaimStatus'
import ClaimServiceLineStatus from '../ClaimServiceLineStatus'

const UNIT_TYPES = {
  UN: 'Units',
  DA: 'Days',
}

export const ClaimServiceLinesDataTable = ({ claimID }: any) => {
  const tableProps = useDataTable({
    name: ['insurance_claims', claimID, 'service_lines'],
    endpoint: `/insurance_claims/${claimID}/service_lines`,
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Service Line',
        model: 'identifier',
      },
      {
        title: 'Internal Claim Status',
        model: 'insurance_claim.status',
        width: 170,
        formatValue: ({ value }: any) => (value ? <ClaimStatus status={value} /> : '–'),
      },
      {
        title: 'Service Line Status',
        model: 'status',
        width: 160,
        formatValue: ({ value }: any) => <ClaimServiceLineStatus status={value} />,
      },
      {
        title: 'Start Date',
        model: 'service_date_start',
        type: 'date',
        width: 120,
      },
      {
        title: 'End Date',
        model: 'service_date_end',
        type: 'date',
        width: 120,
      },
      {
        width: 140,
        title: 'Procedure Code',
        model: 'insurance_new_code.procedure_code',
      },
      {
        width: 140,
        title: 'Revenue Code',
        model: 'insurance_new_code.revenue_code',
      },
      {
        title: 'Units',
        model: 'units',
        width: 100,
      },
      {
        title: 'Unit Type',
        model: 'unit_type',
        width: 120,
        formatValue: ({ value }: any) => UNIT_TYPES[value] || '–',
      },
      {
        title: 'Billed Amount',
        model: 'total_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Payment ID',
        model: 'insurance_new_payment_service_line.identifier',
        width: 140,
      },
      {
        title: 'Allowed Amount',
        model: 'insurance_new_payment_service_line.allowed_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Insurance Paid',
        model: 'insurance_new_payment_service_line.insurance_paid_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Deductible',
        model: 'insurance_new_payment_service_line.deductible_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Co-Pay',
        model: 'insurance_new_payment_service_line.copay_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Co-Insurance',
        model: 'insurance_new_payment_service_line.coinsurance_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Adjustment Amount',
        model: 'insurance_new_payment_service_line.adjustment_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Balance Due Client',
        model: 'insurance_new_payment_service_line.balance_client_due_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Invoiced Amount',
        model: 'insurance_new_payment_service_line.balance_billed_amount',
        type: 'amount',
        width: 150,
      },
      // {
      //   title: 'Client Paid Amount',
      //   model: 'insurance_new_payment_service_line.client_paid_amount',
      //   type: 'amount',
      // },
    ],
    [],
  )

  return <DataTable {...tableProps} testKey="service_lines_data_table" title="Service Lines" icon="accounting" columns={columns} />
}
