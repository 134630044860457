import React from 'react'
import { connect } from 'react-redux'

import { defaultMapStateToProps } from '../Overlays/pages/OverlayBase'
import EventOverlay from '../Overlays/pages/Calendar/EventOverlay'

const SidebarEventOverlay = (props: any) => <EventOverlay reduxName="sidebar_events" {...props} />

const mapStateToProps = (state: any, props: any) => defaultMapStateToProps(state, props.match, 'sidebar_events')

export default connect(mapStateToProps)(SidebarEventOverlay)
