import React from 'react'

import Radio from '../../../../components/Forms/Radio'
import RadioGroup from '../../../../components/Forms/RadioGroup'

export const RichTextTypeEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput } = props

  const { config } = activeElement

  return (
    <RadioGroup
      model="rich_text_input_type"
      layout="vertical-dense"
      value={config.rich_text_input_type}
      onUpdate={editActiveElementFromInput}
    >
      <Radio label="Full Editor" value="full" />
      <Radio label="Mini Editor" value="mini" />
    </RadioGroup>
  )
}
