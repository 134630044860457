import React from 'react'
import { Panel } from 'react-resizable-panels'
import { useMedia } from 'use-media'
import clsx from 'clsx'

import State from '../../../components/State'

import { useFormBuilder } from '../useFormBuilder'
import { useFormOptionsStore } from '../useFormOptionsStore'
import { ElementEditor } from '../elements/ElementEditor'
import { PanelResize } from './PanelResize'

export const SideMenu = (props: any) => {
  const { richTextEditor } = props

  const isEditable: any = useFormBuilder((state: any) => state.isEditable)
  const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)

  const activeElementId: any = useFormBuilder((state: any) => state.activeElementId)
  const activeElement: any = useFormBuilder((state: any) => state.allElements?.[activeElementId])

  const elementEditorState: any = useFormOptionsStore((state: any) => state.elementEditorState)

  const isDesktop = useMedia({ minWidth: 1500 })

  const panelClasses = clsx(
    'grid grid-rows-[100%] grid-cols-[100%] shadow-left-hard-3 border-l border-0 border-solid border-divider bg-white bg-opacity-60',
  )

  const isActive = activeElement?.uuid === activeElementId && !!activeElement?._isActive

  if (!isEditable || isPreviewMode || elementEditorState === 'closed') return null

  if (elementEditorState === 'auto' && !isActive) return null

  return (
    <>
      <PanelResize direction="vertical" />

      <Panel id="editor" order={2} minSize={30} maxSize={isDesktop ? 32 : 40} className={panelClasses}>
        {!isActive ? (
          <div key="empty-state" className="h-full">
            <State
              isEmpty
              title="No Element Selected"
              glyph="form_builder_basic_elements"
              emptyDescription="Select an element to view and edit its settings"
            />
          </div>
        ) : (
          <div className="h-full overflow-hidden">
            <ElementEditor richTextEditor={richTextEditor} />
          </div>
        )}
      </Panel>
    </>
  )
}
