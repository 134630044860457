import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import size from 'lodash/size'

import { apiGet, apiUpdate } from '../../../../modules/api'
import { daysToWords, usDateTime, titleCase, plural } from '../../../../utils/functions'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import Button from '../../../Button'
import Card from '../../../Card'
import CardHeader from '../../../CardHeader'
import CardMeta from '../../../CardMeta'
import CardTitle from '../../../CardTitle'
import ContractStatus from '../../../Statuses/ContractStatus'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Dialog from '../../../Dialog'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import Permission from '../../../Permission'
import Section from '../../../Section'
import SigneeRelationshipStatus from '../../../Statuses/SigneeRelationshipStatus'
import Status from '../../../Status'
import SummonOverlay from '../../../SummonOverlay'
import Text from '../../../Typography/Text'
import Timeline from '../../../Timeline/Timeline'

import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import DateTimeInput from '../../../Forms/DateTimeInput'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Input from '../../../Forms/Input'
import MultiObjectSelector from '../../../Forms/Selectors/MultiObject/MultiObjectSelector'
import OverlaySelector from '../../../Forms/Selectors/OverlaySelector/OverlaySelector'
import SignatureDialog from '../../../Dialogs/SignatureDialog'
import SignaturePad from '../../../Forms/SignaturePad'
import Option from '../../../Forms/Option'
import Select from '../../../Forms/Select'
import Textarea from '../../../Forms/Textarea'

import AgreementSections from '../../../Elements/AgreementSections'
import SignAgreementOverlay from './SignAgreementOverlay'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../OverlayBase'

import { ExportPDFButton } from '../../../Buttons/ExportPDFButton'

const Contract = ({ agreement, contract, reference, isPortal = false }: any) => (
  <Card variant="variant-list" paddingX="0" paddingY="0.5rem" css={{ '&:last-of-type': { boxShadow: 'none !important' } }}>
    <CardHeader
      contentGap="0.35rem"
      after={
        agreement.should_sign && (
          <SummonOverlay
            overlay={
              <SignAgreementOverlay
                showBackdrop
                useContractsEndpoint
                agreement={agreement}
                contract={contract}
                onSignedSuccessfully={async () => {
                  await apiGet({ name: 'agreements', url: `/${plural(reference?.type)}/${reference?.id}/agreements` })
                }}
              />
            }
          >
            {contract.status === 'signed' ? (
              <Button label="View Agreement" glyph="tick_circle" type="default" size={200} display="inline-flex" color="green" />
            ) : (
              <Button label="Sign Agreement" glyph="signature" type="primary" size={200} display="inline-flex" />
            )}
          </SummonOverlay>
        )
      }
    >
      <CardTitle title={contract?.signee?.signer?.name} className="!text-[1rem] !font-[700]" />

      <CardMeta>
        <SigneeRelationshipStatus signee={contract?.signee?.signer} />
        {agreement.should_sign && <ContractStatus status={contract?.status} />}
      </CardMeta>

      <CardMeta className="!text-[0.9rem]">
        {contract?.status !== 'signed' && !isPortal && (
          <>
            <Text label="Email Notification: " description={daysToWords(contract?.email_sent_at)} glyph="email" glyphColor="gray" />
            <Text label="SMS Notification: " description={daysToWords(contract?.sms_sent_at)} glyph="sms" glyphColor="gray" />
          </>
        )}
        {contract?.status === 'signed' && <Text label="Signed At: " glyph="signature" description={usDateTime(contract?.signed_at)} />}
      </CardMeta>
    </CardHeader>
  </Card>
)

class ClientAgreementOverlay extends OverlayBase {
  signAgreement = async (signature) => {
    await apiUpdate({
      name: 'agreements',
      url: `/agreements/${this.props.match.params.id}`,
      params: {
        [signature.model]: signature.value,
      },
    })

    this.setState({ $editable: false })
  }

  render = () => {
    const { $new, $editable, isInvalid, params, shouldSign } = this.state
    const { record, timezone, current, online, loading, user, tenant } = this.props

    const data = { ...params, ...record }

    const isTypeOfAdmin = this.props?.location?.data?.agreement_type == 'admin'

    if (!$new && size(data) === 0) {
      return <OverlayLoader position="right" maxWidth={82} />
    }

    let signees = []
    for (let i = 0; i < data?.contracts?.length; i++) {
      if (data.contracts[i].signee?.reference.type !== 'resident') {
        signees.push(data.contracts[i].signee)
      }
    }

    // if signature was originally required and is now unchecked in edit mode
    const contractsSigned = data?.contracts?.filter((o: any) => o.status === 'signed').length || 0
    const contractsLength = data?.contracts?.length || 0
    const userAndCosignerDifferent = record.cosigner?.id !== user?.id
    const isTrialing = tenant?.plan_status === 'trialing'

    return (
      <Overlay
        showBackdrop={$editable}
        position="right"
        isLoading={!data || !current}
        onClose={this.close}
        maxWidth={82}
        closeWrapper={(element, onClose) => (
          <Dialog
            glyph="delete"
            title="Close without saving?"
            message="All changes will be lost. This action cannot be undone."
            yesColor="red"
            yesLabel="Yes, Close Without Saving"
            onYes={onClose}
            skip={!$editable || !onClose}
          >
            {element}
          </Dialog>
        )}
      >
        <Overlay.Header title={data?.name} icon="legal_agreement_alt" />

        {!this.state.$editable && !isTrialing && (
          <Permission permission="clients.actions.export">
            <Overlay.SubHeader>
              <ExportPDFButton url={`/agreements/${this.state.id}/pdf`} />
            </Overlay.SubHeader>
          </Permission>
        )}

        <Overlay.Content>
          <Form
            useLocalModel
            isEditable={$editable}
            getForm={this.form}
            timezone={timezone}
            onValidationUpdate={this.onValidationUpdate}
            initialModel={data}
            linked={{
              agreement_template_id: params?.id,
              reference_id: current?.id,
              reference_type: 'Resident',
              template_type: 'custom',
              variant: 'client',
              ...($new && isTypeOfAdmin && { agreement_type: 'admin' }),
            }}
          >
            <Section title="Agreement Details">
              <FormSection maxWidth="100%">
                <Input
                  label="Agreement Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter an agreement name',
                    },
                  }}
                />

                <Select allowEmpty label="Review Cycle" model="review_cycle">
                  <Option label="Daily" value="daily" />
                  <Option label="Weekly" value="weekly" />
                  <Option label="Monthly" value="monthly" />
                  <Option label="Quarterly" value="quarterly" />
                  <Option label="Semi-annually" value="semi_annually" />
                  <Option label="Annually" value="annually" />
                </Select>

                <Flex gap={16} horizontal stretchChildrenX>
                  <DateTimeInput
                    defaultToNow
                    model="dated_at"
                    label="Effective Date"
                    validations={{
                      presence: {
                        message: 'Please enter a date and time',
                      },
                    }}
                  />
                  <DateTimeInput model="expired_at" label="Expiry Date" />
                </Flex>

                <DateTimeInput model="reviewed_at" label="Last Review Date" />

                <Textarea useQuickText label="Notes" model="notes" />
              </FormSection>
            </Section>

            <Divider />

            <FormSection maxWidth="100%">
              <div>
                <AgreementSections
                  model="sections"
                  validations={{
                    presence: {
                      message: 'Please add at least one section',
                    },
                  }}
                />
              </div>
            </FormSection>

            {record.cosigner_signature && (
              <Section>
                <SignaturePad
                  isValid
                  label="Supervisor Signature"
                  person={record.cosigner}
                  allowPin={false}
                  model="cosigner_signature"
                  value={record.cosigner_signature}
                  signedAtModel="cosigner_signed_at"
                />
              </Section>
            )}

            <Section
              title="Staff"
              aside={
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox label="Require Staff signature" model="request_cosigner_signature" />
                </CheckboxGroup>
              }
            >
              <Flex alignItems="center" gap={8} justifyContent="space-between">
                <OverlaySelector
                  isPolymorphic
                  disableLink
                  className="!grow !shrink !basis-[240px]"
                  label="Staff"
                  blankLabel="Select Staff…"
                  icon="employees"
                  type="employees.active"
                  model="cosigner"
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => titleCase(data.position)}
                />

                {!this.isPortal && record.request_cosigner_signature && record.cosigner && !record.cosigner_signature && (
                  <SignatureDialog
                    isDisabled={userAndCosignerDifferent}
                    allowPin={false}
                    model="cosigner"
                    title="Sign Agreement"
                    yesLabel="Sign"
                    onYes={this.signAgreement}
                  >
                    <Button
                      fullWidth
                      label="Sign Agreement…"
                      glyph="signature"
                      type="default"
                      size={200}
                      isDisabled={loading || userAndCosignerDifferent}
                    />
                  </SignatureDialog>
                )}

                {record.cosigner_signature && (
                  <Button label="Agreement Signed" glyph="tick_circle" type="tick_circle" color="green" size={200} />
                )}
              </Flex>
            </Section>

            <Divider />

            <Section
              title="Signees"
              aside={
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox
                    label="Require Signees signature"
                    model="should_sign"
                    onUpdate={(state) => this.setState({ shouldSign: state.value })}
                  />
                </CheckboxGroup>
              }
              description={
                !$new && shouldSign && <Status label={`${contractsSigned} / ${contractsLength} Signatures Received`} color="green" />
              }
            >
              {$new && (
                <FormSection maxWidth="100%" className="!mt-2">
                  <Card>
                    <CardHeader contentGap="0.35rem">
                      <CardTitle title={current?.name} className="!text-[1rem] !font-[700]" />
                      <SigneeRelationshipStatus signee={current} />
                    </CardHeader>
                  </Card>

                  <MultiObjectSelector
                    maxWidth="500px"
                    icon="contacts"
                    label="Other Signees"
                    model="signees"
                    type="client.signees"
                    // value={signees}
                    dependentValue={current?.id}
                    selectTitle={(data) => data.signer.name}
                    selectDescription={(data) => data.signer?.relationship}
                  />
                </FormSection>
              )}

              {!$new &&
                data?.contracts?.map((contract: any) => (
                  <Contract key={contract.id} isPortal={this.isPortal} agreement={data} contract={contract} reference={data?.reference} />
                ))}
            </Section>

            {!$new && (
              <>
                <Divider />
                <Section headingType="h2" title="Timeline">
                  <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} disableLinks={this.isPortal} />
                </Section>
              </>
            )}
          </Form>
        </Overlay.Content>

        {!this.isPortal && (
          <Overlay.Footer online={online}>
            {$editable && (
              <>
                <Button
                  glyph="check"
                  label="Save Agreement"
                  type="primary"
                  color="green"
                  isLoading={this.props.loading}
                  onClick={this.save}
                  isDisabled={isInvalid}
                  flex="100 1 240px"
                  permission="agreements.create"
                />

                {!$new && <Button glyph="cross" label="Cancel" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
              </>
            )}

            {!$editable && (
              <>
                {!contractsSigned && (
                  <Button
                    label="Edit"
                    glyph="edit"
                    type="default"
                    isDisabled={this.props.loading}
                    onClick={this.edit}
                    flex="100 1 auto"
                    permission="agreements.edit"
                  />
                )}

                <DeleteDialog
                  title="Delete Agreement?"
                  message="Are you sure you want to delete this agreement? This action cannot be undone."
                  onYes={this.delete}
                >
                  <Button
                    label="Delete"
                    type="default"
                    glyph="delete"
                    color="red"
                    isLoading={this.props.loading}
                    fullWidth
                    permission="agreements.delete"
                  />
                </DeleteDialog>
              </>
            )}
          </Overlay.Footer>
        )}
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)

const mapStateToProps = (state, props) => ({
  ...defaultMapStateToProps(state, props.match, 'agreements'),
  current: get(state, `data.clients.data.${props.match?.params?.resource_id}`, {}),
})

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(ClientAgreementOverlay))
