import React from 'react'
import { useLocation } from 'react-router-dom'

import Status from '../Status'
import { Text } from '../Typography'

import { usDate, usTime } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardQuote from '../CardQuote'

const ShiftNote = ({ note, timezone, to }) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardQuote quote={note.description} />
      <CardMeta>
        <Status label={note?.category?.replace('_', ' ')} color={note.category === 'incident_report' ? 'red' : 'blue'} />
        <Text avatar={note?.author?.avatar} label={note?.author?.name} />
        <Text glyph="date" label="Date: " description={usDate(note.created_at, timezone)} />
        <Text glyph="time" label="Time: " description={usTime(note.created_at, timezone)} />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(ShiftNote)
