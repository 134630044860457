import { v4 as uuid } from 'uuid'
import { addNotification, removeNotification } from '../actions/common'
import { store } from '../setup/store'

class Notifications {
  send = (message, type = 'neutral', time = null) => {
    store.dispatch(
      addNotification({
        id: uuid(),
        description: message,
        type: type,
        time: time,
      }),
    )
  }

  remove = (id) => {
    store.dispatch(removeNotification(id))
  }
}

export default new Notifications()
