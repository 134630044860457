import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'
import { usDate } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import DataList from '../../../components/DataList'
import DiagnosisCategoryStatus from '../../../components/Statuses/DiagnosisCategoryStatus'
import DiagnosisStatus from '../../../components/Statuses/DiagnosisStatus'

import { RecordTooltip } from '../components/RecordTooltip'

export const Diagnosis = ({ data, client }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <RecordTooltip
      title={data?.code || 'Diagnosis'}
      icon="diagnosis"
      color={tint(0.7, COLORS.blue)}
      startDate={data.dated_at}
      recordLink={`/diagnoses/${data.id}`}
      client={client}
    >
      <DataList isCompact withPadding labelWidth={120}>
        <DataList.Item label="Status" value={<DiagnosisStatus status={data.status} />} />
        <DataList.Item label="Category" value={<DiagnosisCategoryStatus status={data.category} />} />
        <DataList.Item label="From Date" value={usDate(data.from, timezone)} />
        <DataList.Item label="Until Date" value={usDate(data.until, timezone)} />
      </DataList>
    </RecordTooltip>
  )
}
