import React from 'react'
import startCase from 'lodash/startCase'

import { fullname, age } from '../utils/functions'
import { Employee } from '../declarations/Employee'

import ClientStatus from './Statuses/ClientStatus'
import { Text } from './Typography'
import Flex from './Flex'

type Props = {
  employee: Employee
}

const StaffProfileHeader: React.FC<Props> = ({ employee }) => {
  if (!employee) return null

  return (
    <Flex gap={6}>
      <Text avatar={employee.avatar} label={fullname(employee)} />
      <ClientStatus status={employee.status} />
      <Text>{`${startCase(employee?.position?.replace('_', ' '))}`}</Text>
    </Flex>
  )
}

export default StaffProfileHeader
