import React from 'react'
import { DateTime } from 'luxon'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import capitalize from 'lodash/capitalize'
import size from 'lodash/size'

import { countWord } from '@behavehealth/utils/functions'
import { css, COLORS } from '@behavehealth/theme'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Button, Nav, NavGroup, NavItem, PageGrid, State, Grid } from '@behavehealth/components'
import { NotionNotice } from '@behavehealth/components/NotionNotice'

import RoomManagement from './room_management'
import UnassignedClients from './unassigned_clients'

const RootBedsLocations: React.FC = () => {
  const { url } = useRouteMatch()
  const { timezone } = useSettings()

  const today = DateTime.local().setZone(timezone)

  const propertiesParams = { category: 'housing,mixed_category', only_parents: true }

  const { data: properties, isLoading: isPropertiesLoading }: any = useGet({
    name: ['locations', propertiesParams],
    params: propertiesParams,
    url: `/houses`,
  })

  const { data: unassignedStats, isLoading: isUnassignedStatsLoading }: any = useGet({
    name: ['clients-unassigned-stats'],
    url: `/residents/unassigned_stats`,
  })

  const isPropertiesEmpty = size(properties) === 0

  if (isPropertiesLoading || isPropertiesEmpty) {
    return (
      <State
        isLoading={isPropertiesLoading}
        isEmpty={isPropertiesEmpty}
        icon="locations"
        title="Locations"
        emptyDescription="There are no locations added yet"
        emptyActions={<Button label="Add First Location" type="primary" glyph="add" link="/locations/all/new" />}
      />
    )
  }

  const firstPropertyID = properties[0]?.id

  return (
    <>
      <PageGrid breakpoint={3} columns="280px 1fr">
        <Nav
          breakpoint={3}
          headingSize={300}
          icon="gps_check_in_out"
          title="Locations"
          desktopProps={{ icon: null, title: null }}
          css={STYLES.nav}
        >
          <NavGroup>
            <div className={STYLES.heading()}>
              <span className={STYLES.dayMonth()}>{today.toFormat('LLL dd')}</span>
              <span className={STYLES.year()}> {today.toFormat('kkkk')}</span>
            </div>

            {properties.map((property: any) => (
              <PropertyNavItem key={property.id} property={property} />
            ))}
          </NavGroup>

          <NavGroup label="Currently Unassigned">
            <NavItem feature="clients" label={`${unassignedStats?.clients || '…'} Unassigned Clients`} to={`${url}/unassigned`} />
          </NavGroup>
        </Nav>

        <Switch>
          <Route path={`${url}/unassigned`} component={UnassignedClients} />
          <Route path={`${url}/:property_id`} component={RoomManagement} />

          {firstPropertyID && <Redirect exact from={url} to={`${url}/${firstPropertyID}`} />}
        </Switch>
      </PageGrid>
    </>
  )
}

const PropertyNavItem = ({ property }: any) => {
  const { url } = useRouteMatch()

  return (
    <>
      <NavItem
        showAvatarInitials
        key={property.id}
        avatar={property.avatar}
        label={`${property.name}, ${capitalize(property.gender)}`}
        description={
          <div className={STYLES.description()}>
            {property.beds_count - property.current_residents_count} Available / {countWord('Beds', property.beds_count)}
          </div>
        }
        to={`${url}/${property.id}`}
      />

      {size(property?.houses) > 0 && (
        <div className="pl-4 ml-4 border-l border-0 border-solid border-divider">
          {property.houses.map((house: any) => (
            <PropertyNavItem key={house.id} property={house} />
          ))}
        </div>
      )}
    </>
  )
}

const STYLES = {
  nav: {
    position: 'static !important',

    'header.is-sticky': {
      background: 'none !important',
      boxShadow: 'none !important',
    },
  },

  description: css({
    fontSize: '0.95rem',
    fontWeight: 400,
    color: COLORS.textMuted,
    marginTop: -1,
  }),

  heading: css({
    fontSize: '1.1rem',
    fontWeight: 400,
    marginBottom: '0.75rem',
  }),

  dayMonth: css({
    fontWeight: 700,
  }),

  year: css({
    fontSize: '0.92em',
    color: COLORS.textMuted,
  }),
}

export const BedsLocations = withPageError(RootBedsLocations)
