import React from 'react'
import get from 'lodash/get'

import Button from '../../Button'
import DateInput from '../../Forms/DateInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Grid from '../../Grid'
import Input from '../../Forms/Input'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'

import { OverlayBase } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class ClientCashFunds extends OverlayBase {
  save = () => {}

  renderHeader = () => <Overlay.Header icon="financials" title="Add Funds" />

  renderContent = () => {
    const { data, $editable } = this.state

    if (!data) return null

    return (
      <Overlay.Content>
        <Form
          form={this.formRef}
          getForm={this.formRef}
          data={data}
          isEditable={$editable}
          onUpdate={(data) => {
            this.setState({ formData: data })
          }}
        >
          <Section>
            <FormSection layout="vertical">
              {/* TODO(Robert): prefill with current client */}
              <ObjectSelector
                label="For"
                blankLabel="Select Applicant or Client"
                icon="clients"
                type="applicants_and_clients"
                model="model"
                selectTitle={(data) => data.name}
                selectDescription={(data) => data.description}
                validations={{
                  presence: {
                    message: 'Please select an applicant or client to charge',
                  },
                }}
              />

              <Grid columns="max-content max-content" gap={1}>
                <Input
                  label="Amount"
                  model="amount"
                  prefix="$"
                  size={8}
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                  }}
                />

                <DateInput
                  label="Date"
                  model="date"
                  defaultToNow
                  validations={{
                    presence: {
                      message: 'Please enter a date',
                    },
                  }}
                />
              </Grid>

              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, form } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save Funds"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={get(form, 'isInvalid', true)}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button glyph="edit" label="Edit Funds" type="default" isDisabled={this.props.loading} onClick={this.edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Funds?"
              message="Are you sure you want to delete this fund? This action cannot be undone."
              onYes={this.delete}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={this.props.loading} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

export default withOverlayError(ClientCashFunds)
