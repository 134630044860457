import React from 'react'
import { useSelector } from 'react-redux'

import { useAPI } from '@behavehealth/hooks'
import { Clients } from '@behavehealth/declarations'

import { Chotomate, Card, Page, HelpTagIframe } from '@behavehealth/components'
import { ClientsTable } from '@behavehealth/components/Tables'
import { useParams } from 'react-router-dom'

const ClientsPage: React.FC = () => {
  const { resource_id } = useParams()

  const loading = useSelector((state) => state.data.clients?.loading)
  const data: Clients = useSelector((state) => state.data.clients?.data)

  useAPI('clients', `/insurance_local_payers/${resource_id}/residents`)

  return (
    <Page feature="clients" breakpoint="0" help={<HelpTagIframe id="payer_clients" />}>
      <Chotomate name="clients" ready={!loading} />

      <Card>
        <ClientsTable data={data} isLoading={loading} to={(id) => `/clients/${id}`} localStorageKey="clients" />
      </Card>
    </Page>
  )
}

export default ClientsPage
