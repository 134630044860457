import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../theme'

import Checkbox from '../Forms/Checkbox'
import CheckboxGroup from '../Forms/CheckboxGroup'
import ContextShow from '../Forms/ContextShow'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

import PermissionCheckbox from './PermissionCheckbox'

const PermissionRow = ({ title, icon, models, true_value, false_value, warningTooltip }: any) => (
  <div css={styles.root}>
    <header css={styles.header} className="header">
      {icon && <Icon icon={icon} className="!ml-3" size={18} />}

      <div>
        <h3 css={styles.title}>{title}</h3>
      </div>

      {warningTooltip && (
        <div className="ml-auto px-4">
          <ContextShow when={models.view} is={true_value}>
            <Tooltip glyph="warning" content={warningTooltip} color={COLORS.orange} />
          </ContextShow>
        </div>
      )}
    </header>

    <div css={styles.columns} className="columns">
      {models?.view && (
        <>
          <PermissionCheckbox label="View" model={models.view} true_value={true_value} false_value={false_value} css={{ gridColumn: 1 }} />

          <ContextShow when={models.view} is={true_value}>
            <PermissionCheckbox label="Create" model={models.create} css={{ gridColumn: 2 }} />
            <PermissionCheckbox
              label="Edit"
              model={models.edit}
              css={{ gridColumn: 3 }}
              true_value={true_value}
              false_value={false_value}
            />
            <PermissionCheckbox
              label="Delete"
              model={models.delete}
              css={{ gridColumn: 4 }}
              true_value={true_value}
              false_value={false_value}
            />

            {models.actions && (
              <CheckboxGroup
                layout="vertical-dense"
                trueIcon="check"
                falseIcon="cross"
                falseStyle="faded-linethrough"
                css={{ gridColumn: 5 }}
              >
                {models.actions.map((action: any) => (
                  <Checkbox key={action.model} label={action.label} model={action.model} css={{ display: 'flex' }} />
                ))}
              </CheckboxGroup>
            )}
          </ContextShow>
        </>
      )}
    </div>
  </div>
)

const styles = {
  root: {
    display: 'flex',
    alignItems: 'stretch',
    fontSize: '1rem',

    '&:hover > *': {
      background: tint(0.25, COLORS.lightBackground),
    },

    '&:first-of-type > *': {
      border: 'none !important',
    },
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 270px',
    padding: '0.75rem 0',

    background: COLORS.white,
    borderTop: `1px solid ${COLORS.divider}`,

    '@media (min-width: 700px)': {
      position: 'sticky',
      left: 0,
      zIndex: 1,
      boxShadow: `
        1px 0 0 ${COLORS.divider},
        3px 0 0 ${COLORS.shadow}
      `,
    },
  },

  title: {
    fontSize: '1rem',
    fontWeight: 600,
    margin: 0,
    lineHeight: 1,
    paddingLeft: '0.75rem',
  },

  columns: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '100px 100px 100px 100px',
    gridGap: '1rem',
    flex: '1 1 750px',

    fontSize: '0.95rem',
    padding: '0.5rem 1rem',
    borderTop: `1px solid ${COLORS.divider}`,

    zIndex: 0,
  },
}

PermissionRow.defaultProps = {
  true_value: true,
  false_value: false,
}

export default PermissionRow
