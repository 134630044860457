import React from 'react'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const SubstanceUseDiagnosisCodes = () => {
  return (
    <>
      <CheckboxGroup layout="vertical-dense" label="Substance Use Disorder Diagnosis Codes">
        <Checkbox label="F10.20 Alcohol use disorder, severe" model="data.diagnoses.substance.F10_20" />
        <Checkbox label="F11.20 Opioid use disorder, severe" model="data.diagnoses.substance.F11_20" />
        <Checkbox label="F12.20 Cannabis use disorder, severe" model="data.diagnoses.substance.F12_20" />
        <Checkbox label="F13.20 Sedative, hypnotic or anxiolytic disorder, severe" model="data.diagnoses.substance.F13_20" />
        <Checkbox label="F14.20 Cocaine use disorder, severe" model="data.diagnoses.substance.F14_20" />
        <Checkbox label="F15.20 Other stimulant use disorder, severe" model="data.diagnoses.substance.F15_20" />
        <Checkbox label="F16.20 Hallucinogen use disorder, severe" model="data.diagnoses.substance.F16_20" />
        <Checkbox label="F17.20 Nicotine dependence" model="data.diagnoses.substance.F17_20" />
        <Checkbox label="F18.20 Inhalant related disorder, severe" model="data.diagnoses.substance.F18_20" />
        <Checkbox label="F19.20 Other psychoactive substance dependence, severe" model="data.diagnoses.substance.F19_20" />
        <Checkbox label="Other" model="data.diagnoses.substance.other" />
      </CheckboxGroup>

      <ContextShow when="data.diagnoses.substance.other" is={true}>
        <SmartTextarea
          useDictation
          label="Other:"
          rows={2}
          model="data.diagnoses.substance.other_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>
    </>
  )
}

export default SubstanceUseDiagnosisCodes
