import { v4 as uuid } from 'uuid'

// Elements
import { Accordion } from './components/Accordion'
import { AddressSelectorInput } from './components/AddressSelectorInput'
import { Alert } from './components/Alert'
import { AmountInput } from './components/AmountInput'
import { CanvaOEmbed } from './components/CanvaOEmbed'
import { CheckboxInput } from './components/CheckboxInput'
import { Conditional } from './components/Conditional'
import { DateTimeInput } from './components/DateTimeInput'
import { Divider } from './components/Divider'
import { Documents } from './components/Documents'
import { EmailInput } from './components/EmailInput'
import { GenderIdentitySelectInput } from './components/GenderIdentitySelectInput'
import { GoogleMeetInput } from './components/GoogleMeetInput'
import { GridTable } from './components/GridTable'
import { Group } from './components/Group'
import { Iframe } from './components/Iframe'
import { Image } from './components/Image'
import { LandlineInput } from './components/LandlineInput'
import { LottieAnimation } from './components/LottieAnimation'
import { Mermaid } from './components/Mermaid'
import { MessengerInput } from './components/MessengerInput'
import { MultiAttachmentInput } from './components/MultiAttachmentInput'
import { MultiObjectSelector } from './components/MultiObjectSelector'
import { NumberInput } from './components/NumberInput'
import { ObjectSelector } from './components/ObjectSelector'
import { OEmbed } from './components/OEmbed'
import { PhoneInput } from './components/PhoneInput'
import { PreferredPronounsSelectInput } from './components/PreferredPronounsSelectInput'
import { RichTextInput } from './components/RichTextInput'
import { SexSelectInput } from './components/SexSelectInput'
import { SignatureInput } from './components/SignatureInput'
import { SingleAttachmentInput } from './components/SingleAttachmentInput'
import { SingleSelectInput } from './components/SingleSelectInput'
import { SkypeInput } from './components/SkypeInput'
import { SliderInput } from './components/SliderInput'
import { StateSelectInput } from './components/StateSelectInput'
import { Text } from './components/Text'
import { TextareaInput } from './components/TextareaInput'
import { TextInput } from './components/TextInput'
import { Video } from './components/Video'
import { WhatsAppInput } from './components/WhatsAppInput'
import { Worksheet } from './components/Worksheet'
import { ZoomInput } from './components/ZoomInput'

// Editors
import { AccordionEditor } from './editors/AccordionEditor'
import { AIInputEditor } from './editors/AIInputEditor'
import { AlertEditor } from './editors/AlertEditor'
import { AmountInputDefaultValue } from './editors/AmountInputDefaultValue'
import { AttachmentInputEditor } from './editors/AttachmentInputEditor'
import { BasicInputEditor } from './editors/BasicInputEditor'
import { CanvaOEmbedEditor } from './editors/CanvaOEmbedEditor'
import { CheckboxInputEditor } from './editors/CheckboxInputEditor'
import { ConditionalEditor } from './editors/ConditionalEditor'
import { ConfigVariablesEditor } from './editors/ConfigVariablesEditor'
import { DateTimeEditor } from './editors/DateTimeEditor'
import { DocumentsEditor } from './editors/DocumentsEditor'
import { ElementVisibilityEditor } from './editors/ElementVisibilityEditor'
import { FieldModelEditor } from './editors/FieldModelEditor'
import { GenericInputEditor } from './editors/GenericInputEditor'
import { GridTableEditor } from './editors/GridTableEditor'
import { GroupEditor } from './editors/GroupEditor'
import { IframeEditor } from './editors/IframeEditor'
import { ImageEditor } from './editors/ImageEditor'
import { InputEditPermissions } from './editors/InputEditPermissions'
import { LottieAnimationEditor } from './editors/LottieAnimationEditor'
import { MermaidEditor } from './editors/MermaidEditor'
import { MultiObjectSelectorEditor } from './editors/MultiObjectSelectorEditor'
import { NotificationsEditor } from './editors/NotificationsEditor'
import { NumberInputDefaultValue } from './editors/NumberInputDefaultValue'
import { ObjectSelectorEditor } from './editors/ObjectSelectorEditor'
import { OEmbedEditor } from './editors/OEmbedEditor'
import { PDFThumbnailEditor } from './editors/PDFThumbnailEditor'
import { RichTextAIActionsEditor } from './editors/RichTextAIActionsEditor'
import { RichTextTypeEditor } from './editors/RichTextTypeEditor'
import { SingleSelectInputBooleanEditor } from './editors/SingleSelectInputBooleanEditor'
import { SingleSelectInputEditor } from './editors/SingleSelectInputEditor'
import { SingleSelectInputNumberEditor } from './editors/SingleSelectInputNumberEditor'
import { SingleSelectInputTextEditor } from './editors/SingleSelectInputTextEditor'
import { SliderInputEditor } from './editors/SliderInputEditor'
import { TextareaEditor } from './editors/TextareaEditor'
import { TextInputPrefixSuffix } from './editors/TextInputPrefixSuffix'
import { VideoEditor } from './editors/VideoEditor'
import { WorksheetEditor } from './editors/WorksheetEditor'

import {
  UATests,
  LOC,
  RELATIONSHIP_OPTIONS,
  ETHNICITY,
  SEX_ASSIGNED_AT_BIRTH,
  GENDER_IDENTITY,
  PREFERRED_PRONOUNS,
  HIGHEST_LEVEL_OF_EDUCATION,
  RELIGIOUS_PREFERENCE,
  PRIMARY_LANGUAGE,
  MARITAL_STATUS,
  STATES,
} from '../../../utils/constants'

const setCheckboxValues = () => {
  return [
    { _id: uuid(), label: 'Option 1' },
    { _id: uuid(), label: 'Option 2' },
    { _id: uuid(), label: 'Option 3' },
  ]
}

const setTextSelectValues = () => {
  return [
    { _id: uuid(), label: 'Option 1', value: 'option_1' },
    { _id: uuid(), label: 'Option 2', value: 'option_2' },
    { _id: uuid(), label: 'Option 3', value: 'option_3' },
  ]
}

const setNumberSelectValues = () => {
  return [
    { _id: uuid(), label: '1', value: 1 },
    { _id: uuid(), label: '2', value: 2 },
    { _id: uuid(), label: '3', value: 3 },
  ]
}

const setBooleanSelectValues = () => {
  return [
    { _id: uuid(), label: 'Yes', value: true },
    { _id: uuid(), label: 'No', value: false },
  ]
}

const setWorksheetColumns = () => {
  return [
    {
      _id: uuid(),
      title: 'Column 1',
      model: 'column_1',
      type: 'input',
    },
    {
      _id: uuid(),
      title: 'Column 2',
      model: 'column_2',
      type: 'input',
    },
    {
      _id: uuid(),
      title: 'Column 3',
      model: 'column_3',
      type: 'input',
    },
  ]
}

const setWorksheetInitialData = () => {
  const rowsCount = 7
  const result: any = []

  for (let i = 0; i < rowsCount; i++) {
    result.push({ _id: uuid() })
  }

  return result
}

const setGridTableDefaultConfig = () => {
  const colId1 = uuid()
  const colId2 = uuid()
  const colId3 = uuid()

  const rowId1 = uuid()
  const rowId2 = uuid()
  const rowId3 = uuid()

  const grid_table_columns_order = [colId1, colId2, colId3]
  const grid_table_rows_order = [rowId1, rowId2, rowId3]
  const grid_table_rows = {}

  for (const rowId of grid_table_rows_order) {
    for (const colId of grid_table_columns_order) {
      if (!grid_table_rows[rowId]) grid_table_rows[rowId] = {}

      grid_table_rows[rowId][colId] = ''
    }
  }

  return {
    grid_table_rows_order,
    grid_table_rows,
    grid_table_columns: [
      {
        _id: colId1,
        title: 'Column 1',
        type: 'rich_text',
        width_type: 'flexible',
        width: 250,
        can_sort: false,
      },
      {
        _id: colId2,
        title: 'Column 2',
        type: 'rich_text',
        width_type: 'flexible',
        width: 250,
        can_sort: false,
      },
      {
        _id: colId3,
        title: 'Column 3',
        type: 'rich_text',
        width_type: 'flexible',
        width: 250,
        can_sort: false,
      },
    ],
  }
}

export const ELEMENTS = {
  mermaid: {
    name: 'Mermaid',
    glyph: 'mermaid',
    color: 'mermaid',
    component: Mermaid,
    editors: [MermaidEditor, PDFThumbnailEditor],
    allowChildren: false,
    defaultConfig: {
      mermaid_value: '',
      aspect_ratio_high: 9,
      aspect_ratio_wide: 16,
      mermaid_sizing_strategy: 'aspect_ratio',
      mermaid_width: 100,
      mermaid_width_type: 'percentage',
    },
    // menuAfterComponent: MermaidAIChat,
  },

  worksheet: {
    name: 'Worksheet',
    glyph: 'table',
    color: 'green',
    component: Worksheet,
    editors: [WorksheetEditor, ConfigVariablesEditor],
    allowChildren: false,
    insertDescription: 'Define tabular form inputs for data entry',
    defaultConfig: {
      worksheet_columns: setWorksheetColumns,
      initial_data: setWorksheetInitialData,
      allow_create_delete_rows: true,
    },
  },

  grid_table: {
    name: 'Display Table',
    glyph: 'table',
    color: 'blue',
    component: GridTable,
    editors: [GridTableEditor, ConfigVariablesEditor],
    allowChildren: false,
    insertDescription: 'Display read-only tabular content',
    defaultConfig: setGridTableDefaultConfig(),
  },

  object_selector: {
    name: 'Single-Item Dropdown Selector',
    glyph: 'selector',
    color: 'blue',
    component: ObjectSelector,
    editors: [GenericInputEditor, ObjectSelectorEditor, ElementVisibilityEditor, InputEditPermissions, ConfigVariablesEditor],
    allowChildren: false,
    defaultConfig: {
      label: 'Single-Item Dropdown Selector',
      placeholder: 'Select…',
      description: '',
      tooltip: '',
      object_selector_options: [
        {
          id: 1,
          _id: uuid(),
          name: 'Option 1',
          model: 'option_1',
        },
        {
          id: 2,
          _id: uuid(),
          name: 'Option 2',
          model: 'option_2',
        },
        {
          id: 3,
          _id: uuid(),
          name: 'Option 3',
          model: 'option_3',
        },
      ],
    },
  },

  multi_object_selector: {
    name: 'Multi-Item Dropdown Selector',
    glyph: 'selector',
    color: 'blue',
    component: MultiObjectSelector,
    editors: [GenericInputEditor, MultiObjectSelectorEditor, ElementVisibilityEditor, InputEditPermissions, ConfigVariablesEditor],
    allowChildren: false,
    defaultConfig: {
      label: 'Multi-Item Dropdown Selector',
      placeholder: 'Select…',
      description: '',
      tooltip: '',
      multi_object_selector_options: [
        {
          id: 1,
          _id: uuid(),
          name: 'Option 1',
          model: 'option_1',
        },
        {
          id: 2,
          _id: uuid(),
          name: 'Option 2',
          model: 'option_2',
        },
        {
          id: 3,
          _id: uuid(),
          name: 'Option 3',
          model: 'option_3',
        },
      ],
    },
  },

  alert: {
    name: 'Alert',
    glyph: 'info',
    color: 'blue',
    component: Alert,
    editors: [AlertEditor, ElementVisibilityEditor],
    allowChildren: true,
    defaultConfig: {
      category: 'info',
    },
    defaultChildren: [{ category: 'paragraph' }],
  },

  group: {
    name: 'Group',
    glyph: 'folder',
    color: 'blue',
    component: Group,
    editors: [GroupEditor, ElementVisibilityEditor],
    allowChildren: true,
    defaultConfig: {},
  },

  image: {
    name: 'Image',
    glyph: 'photo',
    color: 'orange',
    component: Image,
    editors: [ImageEditor, ElementVisibilityEditor],
    allowChildren: false,
    defaultConfig: {
      image_max_width: 100,
      image_max_width_type: 'percentage',
    },
  },

  iframe: {
    name: 'Iframe',
    glyph: 'website',
    color: 'violet',
    component: Iframe,
    editors: [IframeEditor, ElementVisibilityEditor, PDFThumbnailEditor],
    allowChildren: false,
    renderTitle: (config) => config?.label,
    defaultConfig: {
      aspect_ratio_high: 1,
      aspect_ratio_wide: 3,
      iframe_height_type: 'pixels',
      iframe_height: 800,
      iframe_sizing_strategy: 'custom',
      iframe_width_type: 'percentage',
      iframe_width: 100,
      loading_strategy: 'none',
      show_reload_button: false,
      show_width_controls: false,
    },
  },

  video: {
    name: 'Video',
    glyph: 'video_circle',
    color: 'blue',
    component: Video,
    editors: [VideoEditor, PDFThumbnailEditor],
    allowChildren: false,
    defaultConfig: {
      // video_provider: 'youtube',
      video_max_width: 100,
      video_max_width_type: 'percentage',
    },
  },

  lottie_animation: {
    name: 'Lottie Animation',
    glyph: 'lottie',
    color: 'lottie',
    component: LottieAnimation,
    editors: [LottieAnimationEditor, PDFThumbnailEditor],
    allowChildren: false,
  },

  accordion: {
    name: 'Toggle Content',
    glyph: 'triangle_down',
    color: 'blue',
    component: Accordion,
    editors: [AccordionEditor, ElementVisibilityEditor, ConfigVariablesEditor],
    allowChildren: true,
    renderTitle: (config) => config?.title?.replace?.(/<[^>]*>/g, ' ') || 'Toggle Content',
    defaultConfig: {
      is_default_open: false,
      title: '<p>Toggle Content (click to edit…)</p>',
    },
    defaultChildren: [{ category: 'paragraph' }],
  },

  text: {
    name: 'Text',
    glyph: 'paragraph',
    color: 'vividBlue',
    component: Text,
    editors: [RichTextAIActionsEditor, ElementVisibilityEditor, ConfigVariablesEditor],
    allowChildren: false,
    defaultConfig: {
      content: '<p>Click to edit…</p>',
    },
  },

  paragraph: {
    name: 'Paragraph',
    glyph: 'paragraph',
    color: 'vividBlue',
    component: Text,
    editors: [RichTextAIActionsEditor, ElementVisibilityEditor, ConfigVariablesEditor],
    allowChildren: false,
    defaultConfig: {
      content: '<p>Click to edit…</p>',
    },
    renderTitle: (config) => config?.content?.replace?.(/<[^>]*>/g, ' ') || 'Empty content',
  },

  heading: {
    name: 'Heading',
    glyph: 'heading_1',
    color: 'blue',
    component: Text,
    editors: [RichTextAIActionsEditor, ElementVisibilityEditor, ConfigVariablesEditor],
    allowChildren: false,
    defaultConfig: {
      content: '<h1>Click to edit your text…</h1>',
    },
    renderTitle: (config) => config?.content?.replace?.(/<[^>]*>/g, ' ') || 'Empty content',
  },

  heading_1: {
    name: 'Heading 1',
    glyph: 'heading_1',
    color: 'text',
    component: Text,
    editors: [RichTextAIActionsEditor, ElementVisibilityEditor, ConfigVariablesEditor],
    allowChildren: false,
    defaultConfig: {
      content: '<h1>Heading 1</h1>',
    },
  },

  heading_2: {
    name: 'Heading 2',
    glyph: 'heading_2',
    color: 'text',
    component: Text,
    editors: [RichTextAIActionsEditor, ElementVisibilityEditor, ConfigVariablesEditor],
    allowChildren: false,
    defaultConfig: {
      content: '<h2>Heading 2</h2>',
    },
  },

  heading_3: {
    name: 'Heading 3',
    glyph: 'heading_3',
    color: 'text',
    component: Text,
    editors: [RichTextAIActionsEditor, ElementVisibilityEditor, ConfigVariablesEditor],
    allowChildren: false,
    defaultConfig: {
      content: '<h3>Heading 3</h3>',
    },
  },

  text_input: {
    name: 'Single-Line Text',
    glyph: 'text_input',
    color: 'vividBlue',
    component: TextInput,
    allowChildren: false,
    editors: [
      GenericInputEditor,
      TextInputPrefixSuffix,
      ElementVisibilityEditor,
      ConfigVariablesEditor,
      FieldModelEditor,
      InputEditPermissions,
      AIInputEditor,
    ],
    defaultConfig: {
      label: 'Single-Line Text',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  textarea: {
    name: 'Multi-Line Text',
    glyph: 'textarea_input',
    color: 'blue',
    component: TextareaInput,
    allowChildren: false,
    editors: [
      GenericInputEditor,
      TextareaEditor,
      ElementVisibilityEditor,
      FieldModelEditor,
      InputEditPermissions,
      ConfigVariablesEditor,
      AIInputEditor,
    ],
    defaultConfig: {
      label: 'Multi-Line Text',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  rich_text: {
    name: 'Rich Text Editor',
    glyph: 'highlight',
    color: 'orange',
    component: RichTextInput,
    allowChildren: false,
    editors: [
      RichTextTypeEditor,
      GenericInputEditor,
      ElementVisibilityEditor,
      FieldModelEditor,
      InputEditPermissions,
      ConfigVariablesEditor,
      AIInputEditor,
    ],
    defaultConfig: {
      label: 'Rich Text',
      is_required: false,
      description: '',
      placeholder: '',
      rich_text_input_type: 'full',
    },
    renderTitle: (config) => config?.label,
  },

  number_input: {
    name: 'Number Input',
    glyph: 'numbers',
    color: 'text',
    component: NumberInput,
    allowChildren: false,
    editors: [
      GenericInputEditor,
      NumberInputDefaultValue,
      TextInputPrefixSuffix,
      ElementVisibilityEditor,
      FieldModelEditor,
      InputEditPermissions,
      ConfigVariablesEditor,
    ],
    defaultConfig: {
      label: 'Number Input',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  phone_input: {
    name: 'Phone Number',
    glyph: 'phone',
    color: 'green',
    component: PhoneInput,
    allowChildren: false,
    editors: [GenericInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Phone Number',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  landline_input: {
    name: 'Landline Number',
    glyph: 'landline',
    color: 'paleBlue',
    component: LandlineInput,
    allowChildren: false,
    editors: [GenericInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Landline Number',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  email_input: {
    name: 'Email Address',
    glyph: 'email',
    color: 'text',
    component: EmailInput,
    allowChildren: false,
    editors: [
      GenericInputEditor,
      ElementVisibilityEditor,
      NotificationsEditor,
      FieldModelEditor,
      InputEditPermissions,
      ConfigVariablesEditor,
    ],
    defaultConfig: {
      label: 'Email Address',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config: any) => config?.label,
  },

  amount_input: {
    name: 'Amount Input',
    glyph: 'dollar_circle',
    color: 'green',
    component: AmountInput,
    allowChildren: false,
    editors: [
      GenericInputEditor,
      AmountInputDefaultValue,
      TextInputPrefixSuffix,
      ElementVisibilityEditor,
      FieldModelEditor,
      InputEditPermissions,
      ConfigVariablesEditor,
    ],
    defaultConfig: {
      label: 'Amount',
      is_required: false,
      description: '',
      placeholder: '',
      prefix: '$',
    },
    renderTitle: (config) => config?.label,
  },

  checkbox_input: {
    name: 'Multi-Select Input',
    glyph: 'checkbox_input',
    color: 'blue',
    component: CheckboxInput,
    allowChildren: false,
    editors: [CheckboxInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Please Select',
      is_required: false,
      description: '',
      placeholder: '',
      multi_input_values: setCheckboxValues,
    },
    renderTitle: (config) => config?.label,
  },

  single_select_input_text: {
    name: 'Single-Select (Text)',
    glyph: 'radio_input',
    color: 'blue',
    component: SingleSelectInput,
    allowChildren: false,
    editors: [
      SingleSelectInputEditor,
      SingleSelectInputTextEditor,
      ElementVisibilityEditor,
      FieldModelEditor,
      InputEditPermissions,
      ConfigVariablesEditor,
    ],
    defaultConfig: {
      label: 'Please Select',
      is_required: false,
      description: '',
      placeholder: '',
      single_select_input_type: 'radio',
      single_select_input_values: setTextSelectValues,
    },
    renderTitle: (config) => config?.label,
  },

  single_select_input_number: {
    name: 'Single-Select (Number)',
    glyph: 'radio_input',
    color: 'blue',
    component: SingleSelectInput,
    allowChildren: false,
    editors: [
      SingleSelectInputEditor,
      SingleSelectInputNumberEditor,
      ElementVisibilityEditor,
      FieldModelEditor,
      InputEditPermissions,
      ConfigVariablesEditor,
    ],
    defaultConfig: {
      label: 'Please Select',
      is_required: false,
      description: '',
      placeholder: '',
      single_select_input_type: 'radio',
      single_select_input_values: setNumberSelectValues,
    },
    renderTitle: (config) => config?.label,
  },

  single_select_input_boolean: {
    name: 'Single-Select (Yes/No)',
    glyph: 'radio_input',
    color: 'blue',
    component: SingleSelectInput,
    allowChildren: false,
    editors: [
      SingleSelectInputEditor,
      SingleSelectInputBooleanEditor,
      ElementVisibilityEditor,
      FieldModelEditor,
      InputEditPermissions,
      ConfigVariablesEditor,
    ],
    defaultConfig: {
      label: 'Please Select',
      is_required: false,
      description: '',
      placeholder: '',
      single_select_input_type: 'radio',
      single_select_input_values: setBooleanSelectValues,
      options_layout: 'horizontal',
    },
    renderTitle: (config) => config?.label,
  },

  address_selector_input: {
    name: 'Address Selector',
    glyph: 'map_pin',
    color: 'red',
    component: AddressSelectorInput,
    allowChildren: false,
    editors: [BasicInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Address',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  signature_input: {
    name: 'Signature Input',
    glyph: 'signature_input',
    color: 'text',
    component: SignatureInput,
    allowChildren: false,
    editors: [BasicInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Signature',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  date_time_input: {
    name: 'Date-Time Input',
    glyph: 'calendar',
    color: 'red',
    component: DateTimeInput,
    allowChildren: false,
    editors: [DateTimeEditor, BasicInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Date-Time',
      is_required: false,
      description: '',
      placeholder: '',
      date_time_input_type: 'date_time_input',
    },
    renderTitle: (config) => config?.label,
  },

  slider_input: {
    name: 'Slider Input',
    glyph: 'sliders',
    color: 'blue',
    component: SliderInput,
    allowChildren: false,
    editors: [BasicInputEditor, SliderInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Slider',
      is_required: false,
      description: '',
    },
    renderTitle: (config) => config?.label,
  },

  whatsapp_input: {
    name: 'WhatsApp',
    glyph: 'whatsapp',
    color: 'whatsapp',
    component: WhatsAppInput,
    allowChildren: false,
    editors: [GenericInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'WhatsApp',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  skype_input: {
    name: 'Skype ID',
    glyph: 'skype',
    color: 'skype',
    component: SkypeInput,
    allowChildren: false,
    editors: [GenericInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Skype',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  zoom_input: {
    name: 'Zoom URL',
    glyph: 'zoom',
    color: 'zoom',
    component: ZoomInput,
    allowChildren: false,
    editors: [GenericInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Zoom URL',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  google_meet_input: {
    name: 'Google Meet URL',
    glyph: 'google_meet',
    color: 'googleMeet',
    component: GoogleMeetInput,
    allowChildren: false,
    editors: [GenericInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Google Meet URL',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  messenger_input: {
    name: 'Facebook ID',
    glyph: 'facebook_messenger',
    color: 'facebookMessenger',
    component: MessengerInput,
    allowChildren: false,
    editors: [GenericInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Facebook ID',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  sex_select_input: {
    name: 'Sex Assigned at Birth Select',
    // glyph: '',
    color: '',
    component: SexSelectInput,
    allowChildren: false,
    editors: [GenericInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Sex Assigned at Birth',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  gender_identity_select_input: {
    name: 'Gender Identity Select',
    // glyph: '',
    color: '',
    component: GenderIdentitySelectInput,
    allowChildren: false,
    editors: [GenericInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Gender Identity',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  preferred_pronouns_select_input: {
    name: 'Preferred Pronouns Select',
    // glyph: '',
    color: '',
    component: PreferredPronounsSelectInput,
    allowChildren: false,
    editors: [GenericInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    defaultConfig: {
      label: 'Preferred Pronouns',
      is_required: false,
      description: '',
      placeholder: '',
    },
    renderTitle: (config) => config?.label,
  },

  divider: {
    name: 'Divider',
    glyph: 'horizontal_line',
    color: 'textLight',
    component: Divider,
    allowChildren: false,
    defaultConfig: {},
  },

  // CONDITIONAL
  conditional: {
    name: 'Conditional',
    glyph: 'code_fork',
    color: 'blue',
    component: Conditional,
    editors: [ConditionalEditor],
    allowChildren: true,
  },

  // CONSTRUCTS
  state_select: {
    name: 'State Selector',
    glyph: 'radio_input',
    color: 'blue',
    component: StateSelectInput,
    editors: [BasicInputEditor, ElementVisibilityEditor, FieldModelEditor, InputEditPermissions, ConfigVariablesEditor],
    allowChildren: false,
    defaultConfig: {
      label: 'State',
      is_required: false,
      description: '',
      tooltip: '',
    },
  },

  // ATTACHMENTS
  single_attachment_input: {
    name: 'Single-File Attachment',
    glyph: 'document',
    color: 'blue',
    component: SingleAttachmentInput,
    editors: [AttachmentInputEditor, ElementVisibilityEditor, ConfigVariablesEditor],
    allowChildren: false,
    defaultConfig: {
      label: 'Attachment',
      is_required: false,
      description: '',
      tooltip: '',
    },
  },

  multi_attachment_input: {
    name: 'Multiple-File Attachments',
    glyph: 'documents',
    color: 'blue',
    component: MultiAttachmentInput,
    editors: [AttachmentInputEditor, ElementVisibilityEditor, ConfigVariablesEditor],
    allowChildren: false,
    defaultConfig: {
      label: 'Attachments',
      is_required: false,
      description: '',
      tooltip: '',
    },
  },

  // DOCUMENTS
  documents: {
    name: 'Documents',
    glyph: 'add_file',
    color: 'blue',
    component: Documents,
    editors: [DocumentsEditor],
    allowChildren: false,
  },

  // OEmbed
  oembed: {
    name: 'OEmbed',
    glyph: 'embed',
    color: 'text',
    component: OEmbed,
    editors: [OEmbedEditor, PDFThumbnailEditor],
    allowChildren: false,
  },

  canva_oembed: {
    name: 'Canva OEmbed',
    glyph: 'embed',
    color: 'purple',
    component: CanvaOEmbed,
    editors: [CanvaOEmbedEditor, PDFThumbnailEditor],
    allowChildren: false,
    defaultConfig: {
      canva_url: '',
      aspect_ratio_high: 9,
      aspect_ratio_wide: 16,
      iframe_sizing_strategy: 'aspect_ratio',
      iframe_width: 100,
      iframe_width_type: 'percentage',
    },
  },
}

export const SMART_ELEMENTS = {
  drugs_of_choice_selector: {
    name: 'Drugs Of Choice Selector',
    glyph: 'selector',
    color: 'blue',
    category: 'multi_object_selector',
    defaultConfig: {
      label: 'Drugs Of Choice',
      placeholder: 'Select…',
      description: '',
      tooltip: '',
      multi_object_selector_options: UATests.map((o) => ({ ...o, _id: uuid() })),
    },
  },

  level_of_care: {
    name: 'Level of Care Selector',
    glyph: 'selector',
    color: 'blue',
    category: 'single_select_input_text',
    defaultConfig: {
      label: 'Level of Care',
      single_select_input_type: 'select',
      single_select_input_values: Object.entries(LOC).map(([value, label]) => ({ value, label, _id: uuid() })),
    },
  },

  relationship_selector: {
    name: 'Relationship Selector',
    glyph: 'selector',
    color: 'blue',
    category: 'single_select_input_text',
    defaultConfig: {
      label: 'Relationship',
      single_select_input_type: 'select',
      single_select_input_values: Object.values(RELATIONSHIP_OPTIONS).map((o) => ({
        value: o.value,
        label: o.label,
        group: o.group,
        _id: uuid(),
      })),
    },
    insertSiblings: (element) => insertOtherConditionalForSingleSelect({ element, label: 'Other Relationship' }),
  },

  ethnicity_selector: {
    name: 'Ethnicity Selector',
    glyph: 'selector',
    color: 'blue',
    category: 'single_select_input_text',
    defaultConfig: {
      label: 'Ethnicity',
      single_select_input_type: 'select',
      single_select_input_values: Object.values(ETHNICITY).map((o) => ({
        value: o.value,
        label: o.label,
        _id: uuid(),
      })),
    },
    insertSiblings: (element) => insertOtherConditionalForSingleSelect({ element, label: 'Other Ethnicity' }),
  },

  sex_assigned_at_birth_selector: {
    name: 'Sex Assigned at Birth Selector',
    glyph: 'selector',
    color: 'blue',
    category: 'single_select_input_text',
    defaultConfig: {
      label: 'Sex Assigned at Birth',
      single_select_input_type: 'select',
      single_select_input_values: Object.values(SEX_ASSIGNED_AT_BIRTH).map((o) => ({
        value: o.value,
        label: o.label,
        _id: uuid(),
      })),
    },
    insertSiblings: (element) => insertOtherConditionalForSingleSelect({ element, label: 'Other Sex Assigned at Birth' }),
  },

  gender_identity_selector: {
    name: 'Gender Identity Selector',
    glyph: 'selector',
    color: 'blue',
    category: 'single_select_input_text',
    defaultConfig: {
      label: 'Gender Identity',
      single_select_input_type: 'select',
      single_select_input_values: Object.values(GENDER_IDENTITY).map((o) => ({
        value: o.value,
        label: o.label,
        _id: uuid(),
      })),
    },
    insertSiblings: (element) => insertOtherConditionalForSingleSelect({ element, label: 'Other Gender Identity' }),
  },

  preferred_pronouns_selector: {
    name: 'Preferred Pronouns Selector',
    glyph: 'selector',
    color: 'blue',
    category: 'single_select_input_text',
    defaultConfig: {
      label: 'Preferred Pronouns',
      single_select_input_type: 'select',
      single_select_input_values: Object.values(PREFERRED_PRONOUNS).map((o) => ({
        value: o.value,
        label: o.label,
        _id: uuid(),
      })),
    },
    insertSiblings: (element) => insertOtherConditionalForSingleSelect({ element, label: 'Other Preferred Pronouns' }),
  },

  highest_level_of_education_selector_selector: {
    name: 'Highest Level of Education Selector',
    glyph: 'selector',
    color: 'blue',
    category: 'single_select_input_text',
    defaultConfig: {
      label: 'Highest Level of Education',
      single_select_input_type: 'select',
      single_select_input_values: Object.values(HIGHEST_LEVEL_OF_EDUCATION).map((o) => ({
        value: o.value,
        label: o.label,
        _id: uuid(),
      })),
    },
    insertSiblings: (element) => insertOtherConditionalForSingleSelect({ element, label: 'Other Ethnicity' }),
  },

  religious_preference_selector: {
    name: 'Religious Preference Selector',
    glyph: 'selector',
    color: 'blue',
    category: 'single_select_input_text',
    defaultConfig: {
      label: 'Religious Preference',
      single_select_input_type: 'select',
      single_select_input_values: Object.values(RELIGIOUS_PREFERENCE).map((o) => ({
        value: o.value,
        label: o.label,
        group: o.group,
        _id: uuid(),
      })),
    },
    insertSiblings: (element) => insertOtherConditionalForSingleSelect({ element, label: 'Other Religious Preference' }),
  },

  primary_language_selector: {
    name: 'Primary Language Selector',
    glyph: 'selector',
    color: 'blue',
    category: 'single_select_input_text',
    defaultConfig: {
      label: 'Primary Language',
      single_select_input_type: 'select',
      single_select_input_values: Object.values(PRIMARY_LANGUAGE).map((o) => ({
        value: o.value,
        label: o.label,
        group: o.group,
        _id: uuid(),
      })),
    },
  },

  marital_status_selector: {
    name: 'Marital Status Selector',
    glyph: 'selector',
    color: 'blue',
    category: 'single_select_input_text',
    defaultConfig: {
      label: 'Marital Status',
      single_select_input_type: 'select',
      single_select_input_values: Object.values(MARITAL_STATUS).map((o) => ({
        value: o.value,
        label: o.label,
        _id: uuid(),
      })),
    },
  },

  state_select: {
    name: 'State Select',
    glyph: 'selector',
    color: 'blue',
    category: 'single_select_input_text',
    defaultConfig: {
      label: 'State',
      single_select_input_type: 'select',
      single_select_input_values: STATES.map((o) => ({
        value: o.abbreviation,
        label: o.name,
        _id: uuid(),
      })),
    },
  },
}

export const FORM_ELEMENTS = [
  'text_input',
  'textarea',
  'rich_text',
  'number_input',
  'phone_input',
  'landline_input',
  'email_input',
  'amount_input',
  'checkbox_input',
  'single_select_input_text',
  'single_select_input_number',
  'single_select_input_boolean',
  'address_selector_input',
  'signature_input',
  'date_time_input',
  'slider_input',
  'whatsapp_input',
  'skype_input',
  'zoom_input',
  'google_meet_input',
  'messenger_input',
  'sex_select_input',
  'gender_identity_select_input',
  'preferred_pronouns_select_input',
  'state_select',
]

const insertOtherConditionalForSingleSelect = ({ element, label = 'Other' }) => {
  if (!element) return null

  const conditionOptions: any = []

  for (const option of element.config.single_select_input_values) {
    conditionOptions.push({
      _id: option._id,
      is_active: option.value === 'other',
    })
  }

  return [
    {
      uuid: uuid(),
      parent_uuid: element.parent_uuid,
      category: 'conditional',
      defaultConfig: {
        element_uuid: element.uuid,
        condition: 'is_within',
        radio_checkbox_options: conditionOptions,
      },
      insertChildren: [{ category: 'text_input', defaultConfig: { label } }],
    },
  ]
}
