import React, { Component } from 'react'
import { connect } from 'react-redux'

import { DropdownItem, Page, Grid, Status, Table, Text, Heading, DataGrid } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { FinancialCard, CashTransaction } from '@behavehealth/components/Cards'

class ClientCash extends Component {
  constructor(props) {
    super(props)

    this.columns = [
      {
        id: 'date',
        title: 'Date',
        minWidth: 120,
        render: (data) => {
          return data.date
        },
      },
      {
        id: 'added_by',
        title: 'Added by',
        minWidth: 180,
        render: (data) => {
          return <Text avatar={data.author.avatar}>{data.author.name}</Text>
        },
      },
      {
        id: 'notes',
        title: 'Notes',
        minWidth: 300,
        render: (data) => {
          return data.notes || '–'
        },
      },
      {
        id: 'amount',
        title: 'Amount',
        minWidth: 120,
        render: (data) => {
          return <Status color={data.category === 'fund' ? 'green' : 'red'} label={data.amount} />
        },
      },
      {
        id: 'balance',
        title: 'Balance',
        minWidth: 120,
        render: (data) => {
          return <Status color={data.balanceType === 'positive' ? 'green' : 'red'} label={data.balance} />
        },
      },
    ]
  }

  render() {
    const { match } = this.props

    const tableData = [
      {
        id: 1,
        author: { name: 'Antonio Hawkins', avatar: 'avatar1' },
        category: 'refund',
        date: '11-28-2018',
        value: 40,
        amount: '-$40.00',
        balance: '+$60.00',
        balanceType: 'positive',
        notes: null,
      },
      {
        id: 2,
        author: { name: 'Armando Michael', avatar: 'avatar1' },
        category: 'fund',
        date: '11-22-2018',
        value: 100,
        amount: '+$100.00',
        balance: '+$100.00',
        balanceType: 'positive',
        notes: null,
      },
      {
        id: 3,
        author: { name: 'Aryanna Lara', avatar: 'avatar1' },
        category: 'fund',
        date: '10-30-2018',
        value: 50,
        amount: '+$50.00',
        balance: '$0.00',
        balanceType: 'positive',
        notes: 'Loan paid back',
      },
      {
        id: 4,
        author: { name: 'Ben Jimenezards', avatar: 'avatar1' },
        category: 'refund',
        date: '10-29-2018',
        value: 50,
        amount: '-$50.00',
        balance: '-$50.00',
        balanceType: 'negative',
        notes: 'Loan (promises to pay back in two days)',
      },
      {
        id: 5,
        author: { name: 'Bobbie Hopper', avatar: 'avatar1' },
        category: 'refund',
        date: '10-28-2018',
        value: 200,
        amount: '-$200.00',
        balance: '$0.00',
        balanceType: 'positive',
        notes: null,
      },
      {
        id: 6,
        author: { name: 'Brenna Gomez', avatar: 'avatar1' },
        category: 'refund',
        date: '10-22-2018',
        value: 130,
        amount: '-$130.00',
        balance: '+$200.00',
        balanceType: 'positive',
        notes: '',
      },
      {
        id: 7,
        author: { name: 'Britney Beck', avatar: 'avatar1' },
        category: 'refund',
        date: '10-21-2018',
        value: 50,
        amount: '-$50.00',
        balance: '+$330.00',
        balanceType: 'positive',
        notes: null,
      },
      {
        id: 8,
        author: { name: 'Britni Hubbard', avatar: 'avatar1' },
        category: 'fund',
        date: '10-20-2018',
        value: 100,
        amount: '+$100.00',
        balance: '+$380.00',
        balanceType: 'positive',
        notes: null,
      },
      {
        id: 9,
        author: { name: 'Caitlin Skinner', avatar: 'avatar1' },
        category: 'refund',
        date: '10-06-2018',
        value: 20,
        amount: '-$20.00',
        balance: '+$280.00',
        balanceType: 'positive',
        notes: null,
      },
      {
        id: 10,
        author: { name: 'Casandra Mccray', avatar: 'avatar1' },
        category: 'fund',
        date: '10-04-2018',
        value: 300,
        amount: '+$300.00',
        balance: '+$300.00',
        balanceType: 'positive',
        notes: 'Initial funds deposit',
      },
    ]

    return (
      <Page feature="client_cash">
        <Grid gap={32}>
          <Grid columns="repeat(auto-fit, minmax(220px, 1fr))" gap={16}>
            <FinancialCard
              title="Total Funds Added"
              value={1000}
              valueColor="green"
              prefix="+"
              actions={
                <DropdownItem color="blue" glyph="add" label="Add Funds" link={{ pathname: `${match.url}/funds/new`, parent: match }} />
              }
            />

            <FinancialCard
              title="Total Refunds"
              value={200}
              valueColor="red"
              prefix="-"
              actions={
                <DropdownItem
                  color="blue"
                  glyph="reset"
                  label="Make a Refund"
                  link={{ pathname: `${match.url}/refund/new`, parent: match }}
                />
              }
            />

            <FinancialCard title="Current Balance" value={800} valueColor="green" />
          </Grid>

          <Table
            columns={this.columns}
            data={tableData}
            mobileView={
              <>
                <Heading is="h2" style={{ marginBottom: '0.75rem' }}>
                  Transactions
                </Heading>
                <DataGrid
                  useSearch={false}
                  data={tableData}
                  render={(transaction) => <CashTransaction key={transaction.id} transaction={transaction} />}
                />
              </>
            }
          />
        </Grid>
      </Page>
    )
  }
}

function mapStateToProps(state) {
  return {
    data: state.data.current,
    loading: state.data.loading,
  }
}

export default connect(mapStateToProps)(withPageError(ClientCash))
