import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { niceAmount, usDate } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import DataList from '../../components/DataList'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import GridTable from '../../components/GridTable'
import Input from '../../components/Forms/Input'

import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'

import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'

import Section from '../../components/Section'

import { SectionCard, SectionCardTitle } from '../../components/SectionCard'
import { Text } from '../../components/Typography'

import { InsuranceReportStatus } from './components/InsuranceReportStatus'
import { FEATURES } from '../../theme'

const REPORT_CATEGORIES = {
  batch_level_rejections: 'Batch Level Rejection',
  claim_level_rejections: 'Claim Level Rejection',
  claim_notifications: 'Claim Notification',
  claim_status: 'Claim Status',
  claim_validation_rejections: 'Claim Validation Rejection',
  human_readable_notifications: 'Notification',
  recreated_claims: 'Recreated Claim',
  remittance_advice: 'Remittance Advice',
  remittance_error: 'Remittance Error',
  remittance_reconciliation: 'Remittance Reconciliation',
  cancelled: 'Cancelled',
  error_correction: 'Error Correction',
  final_report: 'Final Report',
  reissue_resubmit: 'Re-Issue / Re-Submit',
  self_explanatory: 'Self Explanatory',
  statistical_report: 'Statistical Report',
  unable_to_assign: 'Unable to Assign',
  unique_event: 'Unique Event',
  test_sample: 'Test Sample',
}

const RootInsuranceReportOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    data,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isSaving,
    isOverlayLoading,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'insurance_clearing_house_report',
    endpoint: '/insurance_clearing_house_reports',
    invalidate: 'insurance_clearing_house_reports',
    options: props,
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={87} />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} maxWidth={87}>
      <Overlay.Header icon={FEATURES.clearinghouse.icon} title="Report" />
      <Overlay.SubHeader>
        <Flex centerY gap="1rem">
          <div>
            <Flex gap="0.5rem" className="!py-3">
              <Text fontWeight={600}>ID:</Text>
              <Text>{data.identifier}</Text>
            </Flex>
          </div>

          <div>
            <Flex gap="0.5rem" className="!py-3">
              <Text fontWeight={600}>Internal Name:</Text>
              <Text>{data.internal_name}</Text>
            </Flex>
          </div>

          <div>
            <Flex gap="0.5rem" className="!py-3">
              <Text fontWeight={600}>Type:</Text>
              <Text>{REPORT_CATEGORIES[data.category]}</Text>
            </Flex>
          </div>

          <div>
            <Flex gap="0.5rem" className="!py-3">
              <Text fontWeight={600}>Status:</Text>
              <InsuranceReportStatus status={data.status} />
            </Flex>
          </div>
        </Flex>
      </Overlay.SubHeader>

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <Grid gap="0.75rem">
              <SectionCard title="Details">
                <FormSection maxWidth="100%">
                  <Input label="Name" model="name" />

                  <MiniRichTextEditor label="Notes" model="notes" />
                </FormSection>
              </SectionCard>

              {data.category === 'remittance_advice' ? (
                <div className="grid gap-4">
                  <RemittanceAdvice data={data?.content?.transactions?.[0]} />

                  <Card>
                    <div className="w-full h-full overflow-y-auto pl-4 bg-white bg-opacity-20">
                      <pre>{JSON.stringify(initialModel?.content, null, 2)}</pre>
                    </div>
                  </Card>
                </div>
              ) : data.category === 'claim_status' ? (
                <Card>
                  <div className="w-full h-full overflow-y-auto pl-4 bg-white bg-opacity-20">
                    <pre>{JSON.stringify(initialModel?.content, null, 2)}</pre>
                  </div>
                </Card>
              ) : (
                <Card>
                  <div className="w-full h-full overflow-y-auto pl-4 bg-white bg-opacity-20">
                    <pre>{initialModel?.content?.report_content}</pre>
                  </div>
                </Card>
              )}
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Roster?"
              message="Are you sure you want to delete this Roster? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const RemittanceAdvice = ({ data }: any) => {
  if (!data) return null

  const { payee, payer } = data

  const payerContactMethods = payer?.technicalContactInformation
  const detailInfo = data.detailInfo?.[0]
  const paymentInfo = detailInfo?.paymentInfo
  const financialInformation = data?.financialInformation
  const providerSummaryInformation = detailInfo?.providerSummaryInformation
  const paymentAndRemitReassociationDetails = data?.paymentAndRemitReassociationDetails

  return (
    <>
      <div className="grid gap-4 grid-cols-1 mq800:grid-cols-3">
        {payee && (
          <SectionCard title="Provider Details">
            <DataList isCompact labelWidth={100}>
              <DataList.Item label="Name" value={payee.name} />
              <DataList.Item label="NPI" value={payee.npi} />
              {payee.address && (
                <DataList.Item
                  label="Address"
                  value={
                    <>
                      {payee.address.address1}
                      <br />
                      {payee.address.city}, {payee.address.state} {payee.address.postalCode}
                    </>
                  }
                />
              )}
            </DataList>
          </SectionCard>
        )}

        {payer && (
          <SectionCard title="Payer Details">
            <DataList isCompact labelWidth={100}>
              <DataList.Item label="Name" value={payer.name} />
              <DataList.Item label="Payer ID" value={payer.payerIdentificationNumber} />

              {payer.address && (
                <DataList.Item
                  label="Address"
                  value={
                    <>
                      {payer.address.address1}
                      <br />
                      {payer.address.city}, {payer.address.state} {payer.address.postalCode}
                    </>
                  }
                />
              )}

              <DataList.Item
                label="Contact"
                value={
                  <>
                    {payerContactMethods?.map?.((contact: any) => (
                      <div>
                        <div className="font-medium">{contact.contactName}</div>

                        {contact.contactMethods.map((method: any) => {
                          if (method.phone) {
                            return (
                              <a href={`tel:${method.phone}`} className="flex items-center flex-nowrap">
                                <Glyph glyph="phone" size={12} className="mr-1" />
                                {method.phone}
                              </a>
                            )
                          }

                          if (method.email) {
                            return (
                              <a href={`mailto:${method.email}`} className="flex items-center flex-nowrap">
                                <Glyph glyph="email" size={12} className="mr-1" />
                                {method.email}
                              </a>
                            )
                          }

                          return null
                        })}
                      </div>
                    ))}
                  </>
                }
              />
            </DataList>
          </SectionCard>
        )}

        <SectionCard title="Remittance Details">
          <DataList isCompact labelWidth={150}>
            <DataList.Item label="Check/EFT #" value={paymentAndRemitReassociationDetails?.checkOrEFTTraceNumber} />
            <DataList.Item label="Effective Date" value={usDate(financialInformation?.checkIssueOrEFTEffectiveDate)} />
            <DataList.Item label="Payment Method" value={financialInformation?.paymentMethodCode} />
            <DataList.Item label="Payer Trace #" value={paymentAndRemitReassociationDetails?.originatingCompanyIdentifier} />
            <DataList.Item label="Total Claims" value={providerSummaryInformation?.totalClaimCount} />
            <DataList.Item label="Total Charge Amount" value={niceAmount(providerSummaryInformation?.totalClaimChargeAmount)} />
          </DataList>
        </SectionCard>
      </div>

      {paymentInfo?.map((claim: any, index: number) => {
        const { patientName, serviceLines } = claim

        return (
          <>
            <SectionCard className="!pb-1">
              <h4 className="mb-1.5">#{index + 1} Claim Details</h4>

              <DataList isCompact labelWidth={150}>
                <DataList.Item label="Patient Last Name" value={patientName?.lastName} />
                <DataList.Item label="Patient First Name" value={patientName?.firstName} />
                <DataList.Item label="Patient Middle Name" value={patientName?.middleName} />
                <DataList.Item label="Member ID" value={patientName?.memberId} />
              </DataList>

              {serviceLines && <ServiceLines data={serviceLines} title={<SectionCardTitle title="Service Lines" className="mt-3" />} />}
            </SectionCard>
          </>
        )
      })}
    </>
  )
}

const ServiceLines = ({ title, data }: any) => {
  const initialOpenIndexes = [...Array(size(data)).keys()]
  const [openIndexes, setOpenIndexes] = React.useState(initialOpenIndexes)

  const toggleOpen = (index: number) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index))
    } else {
      setOpenIndexes([...openIndexes, index])
    }
  }

  const isSomeOpen = size(openIndexes) > 0

  return (
    <div>
      <header className="flex items-center">
        {title}

        <Button
          label={isSomeOpen ? 'Close All' : 'Open All'}
          glyph={isSomeOpen ? 'close_all_rows' : 'open_all_rows'}
          display="inline-flex"
          type="link"
          size={100}
          className="!ml-auto"
          onClick={() => {
            if (isSomeOpen) {
              setOpenIndexes([])
            } else {
              setOpenIndexes(initialOpenIndexes)
            }
          }}
        />
      </header>

      <GridTable
        templateColumns="30px 100px repeat(6, minmax(100px, 1fr))"
        className="!-mx-[0.65rem] text-[0.92rem] border-t border-0 border-solid border-divider"
        useBanding={false}
      >
        <GridTable.Header>
          <GridTable.Cell></GridTable.Cell>
          <GridTable.Cell>Line #</GridTable.Cell>
          <GridTable.Cell>Service Date From</GridTable.Cell>
          <GridTable.Cell>Service Date To</GridTable.Cell>
          <GridTable.Cell>Procedure Code</GridTable.Cell>
          <GridTable.Cell>Charges</GridTable.Cell>
          <GridTable.Cell>Paid</GridTable.Cell>
          <GridTable.Cell>Adjustment</GridTable.Cell>
        </GridTable.Header>

        {data?.map?.((serviceLine: any, serviceLineIndex: number) => {
          const { serviceAdjustments, servicePaymentInformation } = serviceLine
          const isOpen = openIndexes.includes(serviceLineIndex)

          return (
            <div className={clsx(serviceLineIndex > 0 && 'border-t border-0 border-solid border-divider', isOpen && '!border-t-2')}>
              <GridTable.Row key={`service-line-${serviceLineIndex}`}>
                <GridTable.Cell
                  className="flex items-center justify-center cursor-pointer !bg:hover-black"
                  onClick={() => toggleOpen(serviceLineIndex)}
                >
                  <Glyph glyph="triangle_down" size={12} className={clsx('-rotate-90', isOpen && '!rotate-0')} />
                </GridTable.Cell>
                <GridTable.Cell>{serviceLineIndex + 1}</GridTable.Cell>
                <GridTable.Cell>{usDate(serviceLine.serviceStartDate)}</GridTable.Cell>
                <GridTable.Cell>{usDate(serviceLine.serviceEndDate)}</GridTable.Cell>
                <GridTable.Cell>{servicePaymentInformation?.adjudicatedProcedureCode}</GridTable.Cell>
                <GridTable.Cell>{niceAmount(servicePaymentInformation?.lineItemChargeAmount)}</GridTable.Cell>
                <GridTable.Cell>{niceAmount(servicePaymentInformation?.lineItemProviderPaymentAmount)}</GridTable.Cell>
                <GridTable.Cell></GridTable.Cell>
              </GridTable.Row>

              {isOpen && (
                <GridTable templateColumns="30px 100px repeat(6, minmax(100px, 1fr))" useBanding={false}>
                  <GridTable.Row className="font-[600]">
                    <GridTable.Cell></GridTable.Cell>
                    <GridTable.Cell>Group Code</GridTable.Cell>
                    <GridTable.Cell>Reason Code</GridTable.Cell>
                    <GridTable.Cell>Explanation</GridTable.Cell>
                    <GridTable.Cell></GridTable.Cell>
                    <GridTable.Cell></GridTable.Cell>
                    <GridTable.Cell></GridTable.Cell>
                    <GridTable.Cell>Adjustment</GridTable.Cell>
                  </GridTable.Row>

                  {serviceAdjustments?.map?.((adjustment: any, adjustmentIndex: number) => {
                    return (
                      <>
                        <GridTable.Row key={`service-line-${serviceLineIndex}-adjustment-line-${adjustmentIndex}`}>
                          <GridTable.Cell></GridTable.Cell>
                          <GridTable.Cell>{adjustment.claimAdjustmentGroupCode}</GridTable.Cell>
                          <GridTable.Cell>{adjustment.adjustmentReasonCode1}</GridTable.Cell>
                          <GridTable.Cell></GridTable.Cell>
                          <GridTable.Cell></GridTable.Cell>
                          <GridTable.Cell></GridTable.Cell>
                          <GridTable.Cell></GridTable.Cell>
                          <GridTable.Cell>{niceAmount(adjustment.adjustmentAmount1)}</GridTable.Cell>
                        </GridTable.Row>

                        {adjustment.adjustmentAmount2 && (
                          <GridTable.Row>
                            <GridTable.Cell></GridTable.Cell>
                            <GridTable.Cell>{adjustment.claimAdjustmentGroupCode}</GridTable.Cell>
                            <GridTable.Cell>{adjustment.adjustmentReasonCode2}</GridTable.Cell>
                            <GridTable.Cell></GridTable.Cell>
                            <GridTable.Cell></GridTable.Cell>
                            <GridTable.Cell></GridTable.Cell>
                            <GridTable.Cell></GridTable.Cell>
                            <GridTable.Cell>{niceAmount(adjustment.adjustmentAmount2)}</GridTable.Cell>
                          </GridTable.Row>
                        )}
                      </>
                    )
                  })}
                </GridTable>
              )}
            </div>
          )
        })}
      </GridTable>
    </div>
  )
}

export const InsuranceReportOverlay = withOverlayError(RootInsuranceReportOverlay)
