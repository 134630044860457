import React from 'react'

import Button from '../../../components/Button'
import Glyph from '../../../components/Glyph'

import { useFormBuilder } from '../useFormBuilder'

export const SaveAndContinueButton = (props: any) => {
  const { onSave } = props

  const isNew: any = useFormBuilder((state: any) => state.isNew)
  const isEditable: any = useFormBuilder((state: any) => state.isEditable)
  const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)

  const hasUnsavedChanges: any = useFormBuilder((state: any) => state.hasUnsavedChanges)
  const setHasUnsavedChanges: any = useFormBuilder((state: any) => state.setHasUnsavedChanges)

  const [isSaving, setIsSaving] = React.useState(!!props.isSaving)

  if (isNew || !isEditable || !hasUnsavedChanges || isPreviewMode) return null

  return (
    <Button
      label="Save & Continue Editing…"
      type="default"
      color="green"
      size={200}
      onClick={async () => {
        setIsSaving(true)

        await onSave({ keepEditing: true })

        setIsSaving(false)
        setHasUnsavedChanges(false)
      }}
      isLoading={isSaving}
      isDisabled={props.isSaving}
      before={
        isSaving || props.isSaving ? null : (
          <span className="relative flex items-center justify-center h-4 w-4 flex-[0_0_auto]">
            <Glyph
              glyph="check"
              size={8}
              color="white !important"
              className="absolute !left-[50%] !top-[50%] !translate-x-[-50%] !translate-y-[-50%] z-10 !scale-none"
            />
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-700 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-4 w-4 bg-green-600"></span>
          </span>
        )
      }
    />
  )
}
