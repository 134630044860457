import React from 'react'
import sortBy from 'lodash/sortBy'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import Avatar from '@behavehealth/components/Avatar'
import Icon from '@behavehealth/components/Icon'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'

const CATEGORY = {
  housing: 'Housing',
  office: 'Office',
  mixed_category: 'Mixed (Housing & Office)',
}

export const PartnersLocations = () => {
  const tableProps: any = useDataTable({
    name: ['organizations', 'partners_shared_locations'],
    endpoint: `/organizations/partners_shared_locations`,
  })

  const sortedData = React.useMemo(() => {
    if (!tableProps.data) return []

    return sortBy(tableProps.data, 'order')
  }, [tableProps.data])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ value }: any) => (
          <div className="flex flex-nowrap items-center font-[600]">
            <Avatar initials={value} src="" size={22} className="mr-1.5" />
            {value}
          </div>
        ),
      },
      {
        width: 160,
        model: 'tenant',
        title: 'Partner',
        formatValue: ({ value }: any) => (
          <LinkCell
            before={<Icon icon="community" size={16} className="mr-1.5" />}
            value={value.name}
            to={`/community/organizations/${value?.organization_id}/shared/community-profile`}
          />
        ),
      },
      {
        width: 120,
        model: 'category',
        title: 'Category',
        formatValue: ({ value }: any) => CATEGORY?.[value],
      },
      {
        width: 160,
        model: 'gender',
        title: 'Accepted Gender',
        type: 'title',
      },
      {
        width: 170,
        id: 'beds_count',
        model: 'beds_count',
        title: 'Current Availability',
        formatValue: ({ data }: any) => `${data.beds_count - data.current_residents_count} Available / ${data.beds_count} Beds`,
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'phone_no',
        title: 'Phone',
        type: 'phone',
      },
      {
        width: 160,
        id: 'email',
        model: 'email',
        title: 'Email',
        type: 'email',
      },
      {
        title: 'Address',
        model: 'address',
        type: 'address',
        disableSort: true,
        width: 280,
      },
    ]
  }, [])

  return (
    <DataTable
      {...tableProps}
      asCard
      mainHeaderHidden
      title="All Partner Messages"
      icon="message_board"
      data={sortedData}
      columns={columns}
    />
  )
}
