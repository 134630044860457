import React from 'react'
import { Link, NavLink, Route, useLocation } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { withPageError } from '@behavehealth/hocs/withPageError'
import useAPI from '@behavehealth/hooks/useAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Card, Page, PageLayout, TipAlert, HelpTagIframe } from '@behavehealth/components'
import { ExportCSVButton } from '@behavehealth/components/Buttons/ExportCSVButton'

import PermissionTemplatesTable from '@behavehealth/components/Templates/tables/PermissionTemplatesTable'
import useStore from '@behavehealth/modules/store'

import { PermissionsTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/PermissionsTemplateOverlay'
import PermissionTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/PermissionTemplatesImportOverlay'

const pageConfig = {
  feature: 'permission_templates',
  title: 'Permission Templates',
  help: <HelpTagIframe id="settings_permission_templates" />,
}

const PermissionTemplates = () => {
  return (
    <>
      <PermissionTemplatesIndex />

      <AnimatedRoutes>
        <Route path="import" element={<PermissionTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<PermissionsTemplateOverlayV6 useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const PermissionTemplatesIndex = () => {
  const { pathname } = useLocation()

  const isBehave = useStore((state) => state?.isBehave)
  const data = useSelector((state: any) => state.data?.permission_templates?.data)
  const loading = useSelector((state: any) => state.data?.permission_templates?.loading)
  const isEmpty = size(data) === 0

  useAPI('permission_templates', '/permission_templates')

  return (
    <Page
      breakpoint={3}
      actions={
        <>
          {isBehave && <Button as={Link} label="Import Behave Templates" type="default" glyph="add" link={`${pathname}/import`} />}
          <Button
            as={Link}
            label="Add Permissions Template"
            type="primary"
            glyph="add"
            link={`${pathname}/new`}
            permission="settings.permissions.create"
          />
        </>
      }
      {...pageConfig}
    >
      <PageLayout>
        <TipAlert contrast type="warning" localStorageKey="alumni_table_columns_moved">
          <b>Please note:</b> to change your staff permissions, navigate to <Link to="/employees/active">Active Staff</Link>. Select the
          staff member and navigate to their permissions sub-page. Click the apply Staff Permissions button to use your Staff Permission
          Templates or go fully custom by changing it for just the selected staff member.
        </TipAlert>

        <Card>
          <PermissionTemplatesTable
            mainLinkAs={NavLink}
            title="Permission Templates"
            titleAfter={<ExportCSVButton url={`/permission_templates/csv`} />}
            data={data}
            isLoading={isEmpty && loading}
            to={(id: string) => id}
          />
        </Card>
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(PermissionTemplates, pageConfig))
