import React from 'react'

import { usDate } from '../../../utils/functions'

import Table from '../../Table/Table'
import TableCell from '../../Table/TableCell'
import TableFilter from '../../Table/TableFilter'

import withSettings from '../../../hocs/withSettings'

const columns = (to: any, mainLinkAs, timezone: any) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} label={value} avatar={row.original.avatar} />
    ),
  },
  {
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    accessor: 'updated_at',
    Header: 'Date Updated',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  timezone: string
  title: string
  to?: Function
}

const PermissionTemplatesTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    isLoading,
    localStorageKey,
    onClick,
    timezone,
    title,
    to,
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={columns(to, props.mainLinkAs, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

PermissionTemplatesTable.defaultProps = {
  title: 'Permission Templates',
  icon: 'permissions',
  emptyDescription: 'No permission templates to display',
  localStorageKey: 'permission_templates',
}

export default withSettings(PermissionTemplatesTable)
