import React from 'react'

import { useDataTable } from '../../../DataTable/useDataTable'

import { ProductsDataTable } from '../../../../constructs/InternalBilling/ProductsDataTable'

export const PlansAddonsServicesTable = (props: any) => {
  const { onSelect } = props

  const tableProps = useDataTable({
    name: ['products'],
    endpoint: props?.config?.endpoint || '/products',
    params: { status: 'active' },
    localStorageKey: 'products_v1',
  })

  return <ProductsDataTable {...tableProps} onClick={onSelect} />
}
