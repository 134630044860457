import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import isFinite from 'lodash/isFinite'
import produce from 'immer'
import size from 'lodash/size'

import { countWord, titleCase } from '../../utils/functions'
import { css, COLORS } from '../../theme'
import { DEFAULT_EMPTY_VALUE, MEDICATION_TIMES } from '../../utils/constants'
import { useCreate } from '../../hooks/useNewAPI'
import Notifications from '../../modules/notifications'

import { PlainSwitch } from '../../components/Forms/Switch'
import { QUICK_ADD } from '../../components/Worksheet/config'
import { Worksheet } from '../../components/Worksheet/Worksheet'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Grid from '../../components/Grid'
import Label from '../../components/Label'
import Overlay from '../../components/Overlay'
import Status from '../../components/Status'
import useAnalytics from '../../hooks/useAnalytics'

const formatMedTimes = (medTimes: any) => {
  if (!medTimes) return ''

  const result = []

  for (const key in medTimes) {
    if (medTimes[key]) result.push(MEDICATION_TIMES[key])
  }

  return result.join(', ')
}

export const MedicationLogWorksheetOverlay = (props: any) => {
  const { onClose, onSuccess, invalidateKeys, initialData, save, isSaving, allow = 'create-update-delete', editDisabledColumns } = props

  const { trackEvent } = useAnalytics()

  const form = React.useRef()
  const history = useHistory()
  const location = useLocation()

  const [isValid, setIsValid] = React.useState(false)
  const [rowsCount, setRowsCount] = React.useState(0)

  const [autoFillQuantityRemaining, setAutoFillQuantityRemaining] = React.useState(false)

  const createMutation: any = useCreate({
    name: ['create-medication-logs'],
    url: `/medication_logs/batch`,
    invalidate: 'medication-logs',
    invalidateKeys: invalidateKeys,
    onSuccess: () => {
      Notifications.send(`Successfully created ${countWord('Medication Logs', rowsCount)}`, 'positive')

      trackEvent({ name: 'Worksheet Saved', params: { worksheetType: 'Medication Log' } })

      if (onSuccess) onSuccess()
      if (onClose) onClose()
    },
  })

  const close = () => {
    if (onClose) return onClose()

    const url = location?.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    history.push(url)
  }

  const handleSave = async () => {
    const data = form.current.getFormValue()

    const result: any = []

    for (const id in data) {
      const row = data[id]
      let expectedRemaining: any = null

      const quantity = parseFloat(row.quantity)
      const latestQuantity = parseFloat(row.latest_remaining_units)

      if (isFinite(quantity) && isFinite(latestQuantity)) {
        expectedRemaining = latestQuantity - quantity
      }

      result.push(
        produce(row, (draft: any) => {
          delete draft._id
          delete draft.med
          delete draft.resident
          delete draft.default_quantity
          delete draft.latest_remaining_units
          delete draft.latest_administered_at
          draft['expected_remaining_units'] = expectedRemaining
        }),
      )
    }

    try {
      if (save) {
        await save(result)
      } else {
        await createMutation.mutateAsync(result)
      }

      close()
    } catch (error) {
      console.error(error)
      Notifications.send('Failed to create Medication Logs', 'negative')
    }
  }

  React.useEffect(() => {
    trackEvent({ name: 'Worksheet Opened', params: { worksheetType: 'Medication Log' } })
  }, [])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        model: 'resident',
        type: 'object_selector',
        config: {
          quickAdd: QUICK_ADD.all_clients,
          isPolymorphic: true,
          shouldHideSelected: false,
          showHideSelectedToggle: true,
          endpoint: '/residents',
          queryKey: 'residents',
          queryParams: { status: 'current' },
          selectTitle: (data: any) => data.name,
          validations: {
            presence: {
              message: 'Please select a client',
            },
          },
          onUpdate: ({ value, rowData, updateRow, rowId }: any) => {
            if (!value || !rowData) return

            const valueId = `${value.id}-${value.type}`
            const rowDataId = `${rowData.resident?.id}-${rowData.resident?.type}`

            if (valueId === rowDataId) return

            updateRow(rowId, {
              med: null,
              dosage: null,
              unit: null,
              latest_administered_at: null,
              latest_remaining_units: null,
            })
          },
        },
      },
      {
        title: 'Medication',
        model: 'med',
        type: 'object_selector',
        config: {
          isPolymorphic: true,
          shouldHideSelected: false,
          showHideSelectedToggle: true,
          endpoint: ({ rowData }: any) => rowData?.resident?.id && `/residents/${rowData.resident.id}/meds`,
          queryKey: ({ rowData }: any) => rowData?.resident?.id && ['client', rowData?.resident?.id, 'meds'],
          selectTitle: (data: any) => {
            const medTimes = formatMedTimes(data.medication_time)

            if (!medTimes) return data.name

            return `${data.name} (${medTimes})`
          },
          selectDescription: (data) => {
            const checkedResults = data?.medication_time && Object.entries(data.medication_time).filter(([_key, value]) => value)
            const finalValue: any = []

            checkedResults.map(([key]) => {
              finalValue.push(MEDICATION_TIMES[key])
            })

            return (
              <>
                <b>Status: </b> {titleCase(data.status)}
                {' | '}
                <b>Dosage: </b> {data.dosage}
                {' | '}
                <b>Unit Type: </b> {titleCase(data.unit)}
                {' | '}
                <b>Quantity: </b> {titleCase(data.quantity)}
                {' | '}
                <b>Medication Time: </b> {size(finalValue) > 0 ? finalValue.join(', ') : DEFAULT_EMPTY_VALUE}
              </>
            )
          },
          getIsApplicable: ({ rowData }: any) => !!rowData?.resident?.id,
          onUpdate: ({ value, updateRow, rowId }: any) => {
            if (!value || !updateRow) return

            updateRow(rowId, {
              dosage: value.dosage,
              unit: value.unit,
              latest_remaining_units: value.latest_remaining_units,
              latest_administered_at: value.latest_administered_at,
            })
          },
          validations: {
            presence: {
              message: 'Please select a client',
            },
          },
        },
      },
      {
        title: 'Dosage',
        model: 'dosage',
        type: 'number',
        config: {
          validations: {
            presence: {
              message: 'Please enter a dosage',
            },
          },
        },
      },
      {
        title: 'Unit Type',
        model: 'unit',
        config: {
          validations: {
            presence: {
              message: 'Please enter a unit',
            },
          },
        },
      },
      {
        title: 'Standard Dose Quantity',
        model: 'default_quantity',
        disableEdit: true,
        config: {
          getIsApplicable: ({ value }: any) => !!value,
        },
      },
      {
        title: 'Latest Log Created At',
        model: 'latest_administered_at',
        type: 'date_time',
        disableEdit: true,
        config: {
          getIsApplicable: ({ value }: any) => !!value,
        },
      },
      {
        title: 'Quantity Remaining from Latest Log',
        model: 'latest_remaining_units',
        width: 150,
        disableEdit: true,
        config: {
          getIsApplicable: ({ value }: any) => isFinite(parseFloat(value)),
        },
      },
      {
        title: 'Quantity Distributed',
        model: 'quantity',
        type: 'number',
        width: 150,
        config: {
          validations: {
            presence: {
              message: 'Please enter a quantity',
            },
          },
          onUpdate: ({ value, updateRow, rowData, rowId }: any) => {
            const parsedValue = parseFloat(value)

            if (!isFinite(parsedValue) || !updateRow) return

            updateRow(rowId, { quantity: parsedValue })

            const remainingQuantity = parseFloat(rowData.latest_remaining_units)

            if (!autoFillQuantityRemaining || !isFinite(remainingQuantity)) return

            updateRow(rowId, {
              remaining_units: remainingQuantity - parsedValue,
            })
          },
        },
      },
      {
        title: 'Quantity Remaining',
        model: 'remaining_units',
        type: 'number',
        width: 150,
        config: {
          onUpdate: ({ value, rowId, updateRow }: any) => {
            const parsedValue = parseFloat(value)

            if (!isFinite(parsedValue)) return

            updateRow(rowId, { remaining_units: parsedValue })
          },
          renderTooltip: ({ rowData }: any) => {
            const quantity = parseFloat(rowData?.quantity)
            const remainingQuantity = parseFloat(rowData?.remaining_units)
            const latestRemainingQuantity = parseFloat(rowData?.latest_remaining_units)

            if (!isFinite(quantity) || !isFinite(remainingQuantity) || !isFinite(latestRemainingQuantity)) return null

            const expectedResult = latestRemainingQuantity - quantity

            if (expectedResult === remainingQuantity) return null

            return {
              glyph: 'circle_error',
              color: COLORS.red,
              message: (
                <>
                  The "Quantity Remaining" value <b>should be {expectedResult}</b>, but is {remainingQuantity}.<br /> Please ensure you have
                  entered the correct quantity.
                </>
              ),
            }
          },
        },
      },
      {
        title: 'Medication Time',
        model: 'medication_time',
        type: 'multi_select',
        config: {
          options: [
            { label: 'AM', model: 'am' },
            { label: 'PM', model: 'pm' },
            { label: 'Morning', model: 'morning' },
            { label: 'Afternoon', model: 'afternoon' },
            { label: 'Evening', model: 'evening' },
            { label: 'Bedtime', model: 'bedtime' },
            { label: 'PRN', model: 'prn' },
          ],
          renderTooltip: ({ rowData }: any) => {
            if (!rowData?.medication_time || !rowData?.med?.medication_time) return null

            const timeDifferences: any = []

            for (const time in rowData.medication_time) {
              if (rowData.medication_time[time] === true && rowData?.med?.medication_time?.[time] === false) timeDifferences.push(time)
            }

            const differencesCount = size(timeDifferences)

            if (differencesCount === 0) return null

            return {
              glyph: 'circle_error',
              color: COLORS.red,
              message: (
                <>
                  <div>
                    <b>Please Note:</b> The medication time{differencesCount > 1 ? 's' : ''}{' '}
                    {timeDifferences.map((time) => (
                      <Status inline small key={time} label={MEDICATION_TIMES[time]} color="red" style={{ marginRight: '0.35rem' }} />
                    ))}{' '}
                    {differencesCount > 1 ? 'do' : 'does'} not match the medication time on the medication record. Please ensure you have
                    selected the correct medication.
                  </div>

                  <div style={{ marginTop: '0.5rem', fontStyle: 'italic' }}>
                    <b>"{rowData.med?.name}" Medication Times:</b>{' '}
                    {Object.keys(rowData.med.medication_time).map((time) => {
                      if (!rowData.med.medication_time?.[time]) return null

                      return (
                        <Status inline small key={time} label={MEDICATION_TIMES[time]} color="blue" style={{ marginRight: '0.35rem' }} />
                      )
                    })}
                  </div>
                </>
              ),
            }
          },
        },
      },
      {
        title: 'Status',
        model: 'did_administer',
        type: 'select',
        config: {
          defaultValue: true,
          options: [
            { label: 'Administered', value: true, glyph: 'check', color: 'green' },
            { label: 'Refused Administration', value: false, glyph: 'cross', color: 'red' },
          ],
        },
      },
      {
        title: 'Administered / Refusal At',
        model: 'administered_at',
        type: 'date_time',
        config: {
          defaultTo: 'now',
          validations: {
            presence: {
              message: 'Please enter the time of administration',
            },
          },
        },
      },
      {
        title: 'Notes',
        model: 'notes',
        type: 'textarea',
      },
      // {
      //   title: 'Client Signature',
      //   model: 'client_signature',
      //   type: 'signature', // TODO
      //   config: {
      //     validations: {},
      //   },
      // },
      // {
      //   title: 'Staff Signature',
      //   model: 'employee_signature',
      //   type: 'signature', // TODO
      //   config: {
      //     validations: {},
      //   },
      // },
      // {
      //   title: 'Co-Signer',
      //   model: 'require_cosigner',
      //   type: 'status',
      //   config: {
      //     options: [
      //       { label: 'Required', value: true, glyph: 'check', color: 'green' },
      //       { label: 'Not Required', value: false, glyph: 'cross', color: 'red' },
      //     ],
      //   },
      // },
    ] as const
  }, [autoFillQuantityRemaining])

  return (
    <Overlay showBackdrop onClose={onClose} position="center" maxWidth={140}>
      <Overlay.Header title="Create Medication Logs" icon="med_pass" />

      <Overlay.Content className="!p-4">
        <Grid gap="0.5rem" className="!pb-3">
          <div className="flex flex-nowrap items-center">
            <PlainSwitch
              horizontal
              isEditable
              isChecked={autoFillQuantityRemaining}
              onCheckedChange={setAutoFillQuantityRemaining}
              labelWidth="auto"
              withHover={false}
              size={100}
              label={null}
            />
            <Label
              label="Auto-Fill Quantity Remaining"
              className="ml-1.5 whitespace-nowrap cursor-pointer"
              onClick={() => {
                setAutoFillQuantityRemaining((c) => !c)
              }}
            />
            <Status small contrast label="New" color={COLORS.vividBlue} />
          </div>

          {autoFillQuantityRemaining && (
            <Alert contrast glyph="info">
              <b>Please note:</b> the "Quantity Remaining" column will be automatically filled in based on the "Quantity Remaining from
              Latest Log" value and the "Quantity" entered. However, you can still manually enter a value if needed and are responsible for
              ensuring it is correct.
            </Alert>
          )}

          <Worksheet
            asCard
            getForm={form}
            title="Medication Logs"
            allow={allow}
            columns={columns}
            onValidationUpdate={setIsValid}
            onRowsCountUpdate={setRowsCount}
            initialData={initialData}
            editDisabledColumns={editDisabledColumns}
          />
        </Grid>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Save ${countWord('Medication Logs', rowsCount)}`}
          glyph="check"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={createMutation.isLoading || isSaving}
          isDisabled={!isValid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
