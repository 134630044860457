import { useEffect, useState } from 'react'

const useActivePath = ({ location, to }) => {
  const [active, setActive] = useState(null)

  useEffect(() => {
    if (location && to) setActive(location.pathname === to.pathname)
  }, [setActive, location, to])

  return active
}

export default useActivePath
