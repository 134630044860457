import React from 'react'
import { connect } from 'react-redux'

import Alert from '../../components/Alert'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import DataFormStatus from '../../components/Statuses/DataFormStatus'
import DateInput from '../../components/Forms/DateInput'
import DateOfBirthInput from '../../components/Forms/DateOfBirthInput'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import TimeInputSelector from '../../components/Forms/TimeInputSelector/TimeInputSelector'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const RootASI5Overlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Addiction Severity Index, 5th Edition"
      category="clinical_measurement"
      subcategory="asi5"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Introduction"
        headingType="h2"
        scrollview={{
          id: 'introduction',
          name: 'Introduction',
        }}
      >
        <p>
          Seven potential problem areas: Medical, Employment/Support Status, Alcohol, Drug, Legal, Family/Social, and Psychological. All
          clients receive the same standard interview. All information gathered is <b>confidential</b>.
        </p>

        <p>We will discuss two time periods:</p>

        <ol>
          <li>The past 30 days</li>
          <li> Lifetime data</li>
        </ol>

        <p>
          <b>Patient Rating Scale:</b> Patient input is important. For each area, I will ask you to use this scale to let me know how
          bothered you have been by any problems in each section. I will also ask you how important treatment is for you in the area being
          discussed.
        </p>

        <p>The scale is:</p>

        <ul>
          <li>0–Not at all</li>
          <li>1–Slightly</li>
          <li>2–Moderately</li>
          <li>3–Considerably</li>
          <li>4–Extremely</li>
        </ul>

        <p>If you are uncomfortable giving an answer, then don’t answer</p>

        <p>
          <b>
            <i>Please do not give inaccurate information! Remember: This is an interview, not a test.</i>
          </b>
        </p>
      </Section>

      <Divider />

      <Section
        title="Interviewer Instructions"
        headingType="h2"
        scrollview={{
          id: 'interviewer_instructions',
          name: 'Interviewer Instructions',
        }}
      >
        <ol>
          <li>Leave no blanks.</li>
          <li>
            Make plenty of comments and include the question number before each comment. If another person reads this ASI, that person
            should have a relatively complete picture of the client’s perceptions of his or her problems.
          </li>
          <li>X = Question not answered. N = Question not applicable.</li>
          <li>Stop the interview if the client misrepresents two or more sections.</li>
          <li>Tutorial and coding notes are preceded by •.</li>
        </ol>

        <p>
          <b>INTERVIEWER SCALE:</b>
        </p>

        <ul>
          <li>0–1 = No problem</li>
          <li>2–3 = Slight problem</li>
          <li>4–5 = Moderate problem</li>
          <li>6–7 = Severe problem</li>
          <li>8–9 = Extreme problem</li>
        </ul>

        <p>
          <b>HALF TIME RULE:</b> If a question asks for the number of months, round up periods of 14 days or more to 1 month. Round up 6
          months or more to 1 year.
        </p>

        <p>
          <b>CONFIDENCE RATINGS:</b>
        </p>

        <ul>
          <li>Last two items in each section.</li>
          <li>Do not overinterpret.</li>
          <li>Denial does not warrant misrepresentation.</li>
          <li>Misrepresentation is overt contradiction in information.</li>
        </ul>

        <p>
          <b>
            <i>PROBE AND MAKE PLENTY OF COMMENTS!</i>
          </b>
        </p>
      </Section>

      <Divider />

      <Section
        title="List of Commonly Used Drugs"
        headingType="h2"
        scrollview={{
          id: 'list_of_commonly_used_drugs',
          name: 'List of Commonly Used Drugs',
        }}
      >
        <p>
          <b>Alcohol:</b> Beer, wine, liquor
        </p>

        <p>
          <b>Methadone:</b> Dolophine, LAAM
        </p>

        <p>
          <b>Opiates:</b> Painkillers = Morphine; Dilaudid; Demerol; Percocet; Darvon; Talwin; Codeine; Tylenol 2, 3, 4
        </p>

        <p>
          <b>Barbiturates:</b> Nembutal, Seconal, Tuinol, Amytal, Pentobarbital, Secobarbital, Phenobarbital, Fiorinol
        </p>

        <p>
          <b>Sedatives/ Hypnotics/ Tranquilizers:</b> Benzodiazepines, Valium, Librium, Ativan, Serax Tranxene, Dalmane, Halcion, Xanax,
          Miltown Chloral Hydrate (Noctex), Quaaludes
        </p>

        <p>
          <b>Cocaine:</b> Cocaine Crystal, Freebase Cocaine or “Crack,” and ”Rock Cocaine”
        </p>

        <p>
          <b>Amphetamines:</b> Monster, Crank, Benzedrine, Dexedrine, Ritalin, Preludin, Methamphetamine, Speed, Ice, Crystal
        </p>

        <p>
          <b>Cannabis:</b> Marijuana, Hashish
        </p>

        <p>
          <b>Hallucinogens:</b> LSD (Acid), Mescaline, Mushrooms (Psilocybin), Peyote, Green, PCP (Phencyclidine), Angel Dust, Ecstasy
        </p>

        <p>
          <b>Inhalants:</b> Nitrous Oxide, Amyl Nitrate (Whippets, Poppers), Glue, Solvents, Gasoline, Toluene, etc.
        </p>

        <p>
          <b>Just note if these are used:</b>
        </p>

        <ul>
          <li>Antidepressants</li>
          <li>Ulcer Medications—Zantac, Tagamet</li>
          <li>Asthma Medications—Ventoline Inhaler, Theo-Dur</li>
          <li>Other Medications—Antipsychotics, Lithium</li>
        </ul>
      </Section>

      <Divider />

      <Section
        title="Alcohol Drug Use Instructions"
        headingType="h2"
        scrollview={{
          id: 'alcohol_drug_use_instructions',
          name: 'Alcohol Drug Use Instructions',
        }}
      >
        <p>
          This section looks at two time periods: the past 30 days and years of regular use, or lifetime use. Lifetime use refers to the
          time prior to the past 30 days.
        </p>

        <ul>
          <li>30-day questions require only the number of days used.</li>
          <li>Lifetime use is asked to determine extended periods of regular use. It refers to the time prior to the past 30 days.</li>
          <li>Regular use = 3+ times per week, 2+ day binges, or problematic, irregular use in which normal activities are compromised.</li>
          <li>
            Alcohol to intoxication does not necessarily mean “drunk”; use the words “felt the effects,” “got a buzz,” “high,” etc. instead
            of “intoxication.” As a rule of thumb, 5+ drinks in one day, or 3+ drinks in a sitting defines intoxication.
          </li>
          <li>
            <div>How to ask these questions:</div>
            <ul>
              <li>How many days in the past 30 days have you used…?</li>
              <li>How many years in your life have you regularly used…?</li>
            </ul>
          </li>
        </ul>
      </Section>

      <Divider />

      <Section
        title="General Information"
        headingType="h2"
        scrollview={{
          id: 'general_information',
          name: 'General Information',
        }}
        commentsModel="data.general_information"
      >
        <FormSection>
          <Input label="G1. ID No." model="data.general_information.g1" />
          <Input label="G2. Soc. Sec. No." model="data.general_information.g2" />
          <DateInput label="G4. Date of Admission" model="data.general_information.g4" />
          <DateInput label="G5. Date of Interview" model="data.general_information.g5" />
          <TimeInputSelector label="G6. Time Begun" model="data.general_information.g6" />
          <TimeInputSelector label="G7. Time Ended" model="data.general_information.g7" />

          <RadioGroup label="G8. Class" layout="vertical-dense" model="data.general_information.g8">
            <Radio label="1 – Intake" value={1} />
            <Radio label="2 – Follow-up" value={2} />
          </RadioGroup>

          <RadioGroup
            label="G9. Contact Code"
            description="(Intake ASI must be in person)"
            layout="vertical-dense"
            model="data.general_information.g9"
          >
            <Radio label="1 – In person" value={1} />
            <Radio label="2 – Telephone" value={2} />
          </RadioGroup>

          <RadioGroup label="G10. Gender" layout="horizontal-dense" model="data.general_information.g10">
            <Radio label="1 – Male" value={1} />
            <Radio label="2 – Female" value={2} />
          </RadioGroup>

          <Input label="G11. Interviewer Code No./Initials" model="data.general_information.g11" />

          <RadioGroup label="G12. Special" layout="vertical-dense" model="data.general_information.g12">
            <Radio label="1. Patient terminated" value="1" />
            <Radio label="2. Patient refused" value="2" />
            <Radio label="3. Patient unable to respond" value="3" />
            <Radio label="N. Not applicable" value="N" />
          </RadioGroup>

          <Divider />

          <h3>Address</h3>

          <Input label="Name" model="data.general_information.address.name" />
          <Input label="Address 1" model="data.general_information.address.line_1" />
          <Input label="Address 2" model="data.general_information.address.line_2" />
          <Input label="City" model="data.general_information.address.city" />
          <Flex gap="1rem">
            <Input label="State" size={4} model="data.general_information.address.state" />
            <Input label="Zip Code" size={8} model="data.general_information.address.zip_code" />
          </Flex>

          <Label label="G14. How long have you lived at this address?" />

          <Flex gap="1rem">
            <Input suffix="Years" model="data.general_information.g14.years" size="4" />
            <Input suffix="Months" model="data.general_information.g14.months" size="4" />
          </Flex>

          <RadioGroup
            label="G15. Is this residence owned by you or your family?"
            layout="horizontal-dense"
            model="data.general_information.g15"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <DateOfBirthInput label="G16. Date of birth:" model="data.general_information.g16" />

          <RadioGroup label="G17. Of what race do you consider yourself?" layout="vertical-dense" model="data.general_information.g17">
            <Radio label="1. White (not Hispanic)" value={1} />
            <Radio label="2. Black (not Hispanic)" value={2} />
            <Radio label="3. American Indian" value={3} />
            <Radio label="4. Alaskan Native" value={4} />
            <Radio label="5. Asian/Pacific Islander" value={5} />
            <Radio label="6. Hispanic-Mexican" value={6} />
            <Radio label="7. Hispanic-Puerto Rican " value={7} />
            <Radio label="8. Hispanic-Cuban" value={8} />
            <Radio label="9. Other Hispanic" value={9} />
          </RadioGroup>

          <RadioGroup label="G18. Do you have a religious preference?" layout="vertical-dense" model="data.general_information.g18">
            <Radio label="1. Protestant " value={1} />
            <Radio label="2. Catholic " value={2} />
            <Radio label="3. Jewish " value={3} />
            <Radio label="4. Islamic " value={4} />
            <Radio label="5. Other" value={5} />
            <Radio label="6. None" value={6} />
          </RadioGroup>

          <RadioGroup
            label="G19. Have you been in a controlled environment in the past 30 days?"
            description="• A place, theoretically, without access to drugs/alcohol."
            layout="vertical-dense"
            model="data.general_information.g19"
          >
            <Radio label="1. No" value={1} />
            <Radio label="2. Jail" value={2} />
            <Radio label="3. Alcohol/Drug Treatment" value={3} />
            <Radio label="4. Medical Treatment" value={4} />
            <Radio label="5. Psychiatric Treatment" value={5} />
            <Radio label="6. Other" value={6} />
          </RadioGroup>

          <Input label="If other, specify:" model="data.general_information.g19_other" />

          <Input
            label="G20. How many days?"
            description="• “NN” if Question G19 is No. Refers to total number of days detained in the past 30 days."
            suffix="days"
            size={4}
            model="data.general_information.g20"
          />

          <Divider />

          <h3>Additional Test Results</h3>

          <Input label="G21." model="data.general_information.g21" />
          <Input label="G22." model="data.general_information.g22" />
          <Input label="G23." model="data.general_information.g23" />
          <Input label="G24." model="data.general_information.g24" />
          <Input label="G25." model="data.general_information.g25" />
          <Input label="G26." model="data.general_information.g26" />
          <Input label="G27." model="data.general_information.g27" />
          <Input label="G28." model="data.general_information.g28" />

          <Divider />

          <h3>Problems & Severity Profile</h3>

          <Select label="Medical" model="data.general_information.severity_profile.medical">
            <Option label="0" value={0} />
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
          </Select>

          <Select label="Employment Support" model="data.general_information.severity_profile.employment_support">
            <Option label="0" value={0} />
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
          </Select>

          <Select label="Alcohol" model="data.general_information.severity_profile.alcohol">
            <Option label="0" value={0} />
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
          </Select>

          <Select label="Drugs" model="data.general_information.severity_profile.drugs">
            <Option label="0" value={0} />
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
          </Select>

          <Select label="Legal" model="data.general_information.severity_profile.legal">
            <Option label="0" value={0} />
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
          </Select>

          <Select label="Family/Social" model="data.general_information.severity_profile.family_social">
            <Option label="0" value={0} />
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
          </Select>

          <Select label="Psychiatric" model="data.general_information.severity_profile.psychiatric">
            <Option label="0" value={0} />
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
          </Select>

          <SmartTextarea
            useDictation
            label="General Information Comments"
            description="(Include the question number with your notes)"
            model="data.general_information.notes"
          />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Medical Status"
        headingType="h2"
        scrollview={{
          id: 'medical_status',
          name: 'Medical Status',
        }}
        commentsModel="data.medical_status"
      >
        <FormSection>
          <Input
            label="M1. How many times in your life have you been hospitalized for medical problems?"
            description="• Include ODs and DTs. Exclude detox, alcohol/drug, psychiatric treatment, and childbirth (if no complications). Enter the number of overnight hospitalizations for medical problems."
            suffix="times"
            size={4}
            model="data.medical_status.m1"
          />

          <Label
            label="M2. How long ago was your last / hospitalization for a physical problem?"
            description="• If no hospitalizations in Question M1, then (Years/Months) this should be “NN.”"
          />

          <Flex gap="1rem">
            <Input suffix="Years" model="data.medical_status.m2.years" size="4" />
            <Input suffix="Months" model="data.medical_status.m2.months" size="4" />
          </Flex>

          <RadioGroup
            label="M3. Do you have any chronic medical problems that continue to interfere with your life?"
            description="• If Yes, specify in comments. • A chronic medical condition is a serious physical condition that requires regular care (i.e., medication, dietary restriction), preventing full advantage of the person’s abilities."
            layout="horizontal-dense"
            model="data.medical_status.m3"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <Input
            label="M15. (OPTIONAL) Number of months pregnant"
            description="• “N” for males, “0” for not pregnant."
            model="data.medical_status.m15"
            size={4}
          />

          <RadioGroup
            label="M4. Are you taking any prescribed medication on a regular basis for a physical problem?"
            description="• If Yes, specify in comments. • Medication prescribed by an M.D. for medical conditions; not psychiatric medicines. Include medicines prescribed whether or not the patient is currently taking them. The intent is to verify chronic medical problems."
            layout="horizontal-dense"
            model="data.medical_status.m4"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup
            label="M5. Do you receive a pension for a physical disability?"
            description="• If Yes, specify in comments. • Include worker’s compensation; exclude psychiatric disability"
            layout="horizontal-dense"
            model="data.medical_status.m5"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <Input
            label="M6. How many days have you experienced medical problems in the past 30 days?"
            description="• Include flu, colds, etc. Include serious ailments related to drugs/alcohol, which would continue even if the patient were abstinent (e.g., cirrhosis of liver, abscesses from needles)."
            suffix="days"
            size={4}
            model="data.medical_status.m6"
          />

          <Alert glyph="info" children="For Questions M7 & M8, ask the patient to use the Patient’s Rating Scale." />

          <Input
            label="M7. How troubled or bothered have you been by these medical problems in the past 30 days?"
            description="(Restrict response to problem days of Question M6.)"
            size={4}
            model="data.medical_status.m7"
          />

          <Input
            label="M8. How important to you now is treatment for these medical problems?"
            description="• If client is currently receiving medical treatment, refer to the need for additional medical treatment by the patient."
            size={4}
            model="data.medical_status.m8"
          />

          <Divider />

          <h3>Interviewer Severity Rating</h3>

          <Input
            label="M9. How would you rate the patient’s need for medical treatment?"
            description="• Refers to the patient’s need for additional medical treatment."
            size={4}
            model="data.medical_status.m9"
          />

          <Divider />

          <h3>Confidence Rating</h3>
          <h4>Is the above information significantly distorted by:</h4>

          <RadioGroup label="M10.Patient’s misrepresentation?" layout="horizontal-dense" model="data.medical_status.m10">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup label="M11. Patient’s inability to understand?" layout="horizontal-dense" model="data.medical_status.m11">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <SmartTextarea
            useDictation
            label="Medical Comments"
            description="(Include question number with your notes)"
            model="data.medical_status.notes"
          />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Employment/Support Status"
        headingType="h2"
        scrollview={{
          id: 'employment_support_status',
          name: 'Employment/Support Status',
        }}
        commentsModel="data.employment_support_status"
      >
        <FormSection>
          <Label label="E1. Education completed:" description="• GED = 12 years, note in comments. • Include formal education only." />

          <Flex gap="1rem">
            <Input suffix="Years" model="data.employment_support_status.e1.years" size="4" />
            <Input suffix="Months" model="data.employment_support_status.e1.months" size="4" />
          </Flex>

          <Input
            label="E2. Training or technical education completed:"
            description="• Formal/organized training only. For military training, include only training that can be used in civilian life (e.g., electronics, artillery)."
            suffix="months"
            size={4}
            model="data.employment_support_status.e2"
          />

          <RadioGroup
            label="E3. Do you have a profession, trade, or skill?"
            description="• Employable, transferable skill acquired through training."
            layout="horizontal-dense"
            model="data.employment_support_status.e3"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <SmartTextarea useDictation label="If Yes, specify:" model="data.employment_support_status.e3_notes" />

          <RadioGroup
            label="E4. Do you have a valid driver’s license?"
            description="• Valid license; not suspended/revoked."
            layout="horizontal-dense"
            model="data.employment_support_status.e4"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup
            label="E5. Do you have an automobile available for use?"
            description="• If answer to E4 is No, then E5 must be No. Does not require ownership, requires only availability on a
              regular basis."
            layout="horizontal-dense"
            model="data.employment_support_status.e5"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <Label
            label="E6. How long was your longest full-time job?"
            description="• Full time = 40+ hours weekly; does not necessarily mean most recent job."
          />

          <Flex gap="1rem">
            <Input suffix="Years" model="data.employment_support_status.e6.years" size="4" />
            <Input suffix="Months" model="data.employment_support_status.e6.months" size="4" />
          </Flex>

          <RadioGroup label="E7. Usual (or last) occupation?" layout="horizontal-dense" model="data.employment_support_status.e7">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <Input
            label="If yes, specify:"
            description="(Use Hollingshead Categories Reference Sheet)"
            model="data.employment_support_status.e7_notes"
          />

          <RadioGroup
            label="E8. Does someone contribute to your support in any way?"
            description="• Is patient receiving any regular support (i.e., cash, food, housing) from family/friend? Include spouse’s contribution; exclude support by an institution."
            layout="horizontal-dense"
            model="data.employment_support_status.e8"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup
            label="E9. Does this constitute the majority of your support?"
            description="• If E8 is No, then E9 is N."
            layout="horizontal-dense"
            model="data.employment_support_status.e9"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup
            label="E10. Usual employment pattern, past 3 years?"
            description="• Answer should represent the majority of the last 3 years, not just the most recent selection. If there are equal times for more than one category, select that which best represents the current situation."
            layout="vertical-dense"
            model="data.employment_support_status.e10"
          >
            <Radio label="1. Full time (40 hrs/week)" value={1} />
            <Radio label="2. Part time (regular hours)" value={2} />
            <Radio label="3. Part time (irregular hours)" value={3} />
            <Radio label="4. Student" value={4} />
            <Radio label="5. Service/Military" value={5} />
            <Radio label="6. Retired/Disability" value={6} />
            <Radio label="7. Unemployed" value={7} />
            <Radio label="8. In controlled environment" value={8} />
          </RadioGroup>

          <Input
            label="E11. How many days were you paid for working in the past 30 days?"
            description="• Include “under the table” work, paid sick days, and vacation"
            suffix="days"
            size={4}
            model="data.employment_support_status.e11"
          />

          <h4>For Questions E12–17: How much money did you receive from the following sources in the past 30 days?</h4>

          <Input
            label="E12. Employment"
            description="• Net or “take home” pay; include any “under the table” money"
            size={6}
            model="data.employment_support_status.e12"
          />

          <Input label="E13. Unemployment compensation" size={6} model="data.employment_support_status.e13" />

          <Input
            label="E14. Welfare"
            description="• Include food stamps, transportation money provided by an agency to go to and from treatment."
            size={6}
            model="data.employment_support_status.e14"
          />

          <Input
            label="E15. Pensions, benefits, or Social Security"
            description="• Include disability, pensions, retirement, veteran’s benefits, SSI, and worker’s compensation."
            size={6}
            model="data.employment_support_status.e15"
          />

          <Input
            label="E16. Mate, family, or friends"
            description="• Money for personal expenses (e.g., clothing); include unreliable sources of income. Record cash payments only; include windfalls (unexpected), money from loans, legal gambling, inheritance, tax returns, etc."
            size={6}
            model="data.employment_support_status.e16"
          />

          <Input
            label="E17. Illegal"
            description="• Cash obtained from drug dealing, stealing, fencing stolen goods, illegal gambling, prostitution, etc. Do not attempt to convert drugs exchanged to a dollar value."
            size={6}
            model="data.employment_support_status.e17"
          />

          <Input
            label="E18. How many people depend on you for the majority of their food, shelter, etc.?"
            description="• Must be regularly depending on patient; do include alimony/child support; do not include the patient or self-supporting spouse, etc."
            suffix="people"
            size={4}
            model="data.employment_support_status.e18"
          />

          <Input
            label="E19. How many days have you experienced employment problems in the past 30 days?"
            description="• Include inability to find work, if actively looking for work, or problems with present job in which that job is jeopardized."
            suffix="days"
            size={4}
            model="data.employment_support_status.e19"
          />

          <Alert glyph="info" children="For Questions E20 & E21, ask the patient to use the Patient’s Rating Scale" />

          <Input
            label="E20. How troubled or bothered have you been by these employment problems in the past 30 days?"
            description="• If the patient has been incarcerated or detained during the past 30 days, he or she cannot have employment problems. In that case, an N response is indicated."
            size={4}
            model="data.employment_support_status.e20"
          />

          <Input
            label="E21. How important to you now is counseling for these employment problems?"
            description="• Stress help in finding or preparing for a job, not giving the patient a job."
            size={4}
            model="data.employment_support_status.e21"
          />

          <Divider />

          <h3>Interviewer Severity Rating</h3>

          <Input
            label="E22. How would you rate the patient’s need for employment counseling?"
            size={4}
            model="data.employment_support_status.e22"
          />

          <Divider />

          <h3>Confidence Rating</h3>
          <h4>Is the above information significantly distorted by:</h4>

          <RadioGroup label="E23. Patient’s misrepresentation?" layout="horizontal-dense" model="data.employment_support_status.e23">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup label="E24. Patient’s inability to understand?" layout="horizontal-dense" model="data.employment_support_status.e24">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <SmartTextarea
            useDictation
            label="Employment/Support Comments"
            description="(Include question number with your notes)"
            model="data.employment_support_status.notes"
          />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Alcohol / Drugs"
        headingType="h2"
        scrollview={{
          id: 'alcohol_drugs',
          name: 'Alcohol / Drugs',
        }}
        commentsModel="data.alcohol_drugs"
      >
        <h3>Route of administration:</h3>

        <ol>
          <li>Oral</li>
          <li>Nasal</li>
          <li>Smoking</li>
          <li>Non-IV injection</li>
          <li>IV injection</li>
        </ol>

        <p>
          <i>
            • Note the usual or most recent route. For more than one route, choose the most severe. The routes are listed from least severe
            to most severe.
          </i>
        </p>

        <FormSection>
          <Grid gap="0.25rem">
            <Label label="D1. Alcohol (any use at all)" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d1_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d1_regular_use_years" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D2. Alcohol (to intoxication)" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d2_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d2_regular_use_years" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D3. Heroin" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d3_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d3_regular_use_years" />
            <Input suffix="Route of Admin." size={3} model="data.alcohol_drugs.d3_admin_route" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D4. Methadone" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d4_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d4_regular_use_years" />
            <Input suffix="Route of Admin." size={3} model="data.alcohol_drugs.d4_admin_route" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D5. Other Opiates/Analgesics" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d5_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d5_regular_use_years" />
            <Input suffix="Route of Admin." size={3} model="data.alcohol_drugs.d5_admin_route" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D6. Barbiturates" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d6_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d6_regular_use_years" />
            <Input suffix="Route of Admin." size={3} model="data.alcohol_drugs.d6_admin_route" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D7. Sedatives/Hypnotics/Tranquilizers" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d7_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d7_regular_use_years" />
            <Input suffix="Route of Admin." size={3} model="data.alcohol_drugs.d7_admin_route" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D8. Cocaine" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d8_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d8_regular_use_years" />
            <Input suffix="Route of Admin." size={3} model="data.alcohol_drugs.d8_admin_route" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D9. Amphetamines" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d9_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d9_regular_use_years" />
            <Input suffix="Route of Admin." size={3} model="data.alcohol_drugs.d9_admin_route" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D10. Cannabis" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d10_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d10_regular_use_years" />
            <Input suffix="Route of Admin." size={3} model="data.alcohol_drugs.d10_admin_route" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D11. Hallucinogens" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d11_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d11_regular_use_years" />
            <Input suffix="Route of Admin." size={3} model="data.alcohol_drugs.d11_admin_route" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D12. Inhalants" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d12_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d12_regular_use_years" />
            <Input suffix="Route of Admin." size={3} model="data.alcohol_drugs.d12_admin_route" />
          </Grid>

          <Grid gap="0.25rem">
            <Label label="D13. More than one substance per day (including alcohol)" />
            <Input suffix="Past 30 Days" size={3} model="data.alcohol_drugs.d13_past_30_days" />
            <Input suffix="Years of Regular Use" size={3} model="data.alcohol_drugs.d13_regular_use_years" />
          </Grid>

          <Input
            label="D14. According to the interviewer, which substance(s) is/are the major problem?"
            description="• Interviewer should determine the major drug of abuse. Code the number next to the drug in Questions D1–12, or “00” = no problem, “15” = alcohol and one or more drugs, “16” = more than one drug but no alcohol. Ask patient when not clear."
            size={4}
            model="data.alcohol_drugs.d14"
          />

          <Input
            label="D15. How long was your last period of voluntary abstinence from this major substance?"
            description="• Last attempt of at least 1 month, not necessarily (Months) the longest. Periods of hospitalization/incarceration do not count. Periods of Antabuse, methadone, or naltrexone use during abstinence do count. • “00” = never abstinent"
            suffix="months"
            size={4}
            model="data.alcohol_drugs.d15"
          />

          <Input
            label="D16. How many months ago did this abstinence end?"
            description="• If D15 = “00,” then D16 = “NN.” • “00” = still abstinent."
            size={4}
            model="data.alcohol_drugs.d16"
          />

          <Divider />

          <h3>How many times have you:</h3>

          <Input
            label="D17. Had alcohol DTs?"
            description="• Delirium Tremens (DTs): Occur 24-48 hours after last drink or significant decrease in alcohol intake; includes shaking, severe disorientation, fever, hallucinations. DTs usually require medical attention."
            size={4}
            model="data.alcohol_drugs.d17"
          />

          <Input
            label="D18. Overdosed on drugs?"
            description="• Overdoses (OD): Requires intervention by someone to recover, not simply sleeping it off; include suicide attempts by OD."
            size={4}
            model="data.alcohol_drugs.d18"
          />

          <Divider />

          <h3>How many times in your life have you been treated for:</h3>

          <Input label="D19. Alcohol abuse?" suffix="times" size={4} model="data.alcohol_drugs.d19" />

          <Input
            label="D20. Drug abuse?"
            description="• Include detoxification, halfway houses, in/outpatient counseling, and AA or NA (if 3+ meetings within 1-month period)."
            suffix="times"
            size={4}
            model="data.alcohol_drugs.d20"
          />

          <Divider />

          <h3>How many of these were detox only?</h3>

          <Input label="D21. Alcohol" suffix="times" size={4} model="data.alcohol_drugs.d21" />

          <Input
            label="D22. Drugs"
            description="• If D19 = “00,” then Question D21 is “NN.” If D20 = “00,” then Question D22 is “NN.”"
            suffix="times"
            size={4}
            model="data.alcohol_drugs.d22"
          />

          <Divider />

          <h3>How much money would you say you spent during the past 30 days on:</h3>

          <Input label="D23. Alcohol?" size={6} model="data.alcohol_drugs.d23" />

          <Input
            label="D24. Drugs?"
            description="• Count only actual money spent. What is the financial burden caused by drugs/alcohol?"
            size={6}
            model="data.alcohol_drugs.d24"
          />

          <Input
            label="D25. How many days have you been treated in an outpatient setting for alcohol or drugs in the past 30 days?"
            description="• Include AA/NA"
            suffix="times"
            size={4}
            model="data.alcohol_drugs.d25"
          />

          <Input
            label="D99. (OPTIONAL) How many days have you been treated in an inpatient setting for alcohol or drugs in the past 30 days?"
            suffix="days"
            size={4}
            model="data.alcohol_drugs.d99"
          />

          <Divider />

          <h3>How many days in the past 30 days have you experienced:</h3>

          <Input label="D26. Alcohol problems?" suffix="days" size={4} model="data.alcohol_drugs.d26" />

          <Input
            label="D27. Drug problems?"
            description="• Include: Craving, withdrawal symptoms, disturbing effects of use, or wanting to stop and being unable to."
            suffix="days"
            size={4}
            model="data.alcohol_drugs.d27"
          />

          <Divider />

          <Alert
            glyph="info"
            children="For Questions D28-D31, ask the patient to use the Patient’s Rating Scale. The patient is rating the need for additional substance abuse treatment."
          />

          <h3>How troubled or bothered have you been in the past 30 days by these:</h3>

          <Input label="D28. Alcohol problems?" model="data.alcohol_drugs.d28" />
          <Input label="D29. Drug problems?" model="data.alcohol_drugs.d29" />

          <Divider />

          <h3>How important to you now is treatment for:</h3>

          <Input label="D30. Alcohol problems?" model="data.alcohol_drugs.d30" />
          <Input label="D31. Drug problems?" model="data.alcohol_drugs.d31" />

          <Divider />

          <h3>Interviewer Severity Rating</h3>
          <h4>How would you rate the patient’s need for treatment for:</h4>

          <Input label="D32. Alcohol problems?" model="data.alcohol_drugs.d32" />
          <Input label="D33. Drug problems?" model="data.alcohol_drugs.d33" />

          <Divider />

          <h3>Confidence Rating</h3>
          <h4>Is the above information significantly distorted by:</h4>

          <RadioGroup label="D34. Patient’s misrepresentation?" layout="horizontal-dense" model="data.alcohol_drugs.d34">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup label="D35. Patient’s inability to understand?" layout="horizontal-dense" model="data.alcohol_drugs.d35">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <SmartTextarea
            useDictation
            label="Alcohol/Drugs Comments"
            description="(Include question number with your notes)"
            model="data.alcohol_drugs.notes"
          />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Legal Status"
        headingType="h2"
        scrollview={{
          id: 'legal_status',
          name: 'Legal Status',
        }}
        commentsModel="data.legal_status"
      >
        <FormSection>
          <RadioGroup
            label="L1. Was this admission prompted or suggested by the criminal justice system?"
            description="• Judge, probation/parole officer, etc."
            layout="horizontal-dense"
            model="data.legal_status.l1"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup
            label="L2. Are you on parole or probation?"
            description="• Note duration and level in comments."
            layout="horizontal-dense"
            model="data.legal_status.l2"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <Label
            label="How many times in your life have you been arrested and charged with the following:"
            description="• Include total number of counts, not just convictions. Do not include juvenile (pre age 18) crimes, unless client was charged as an adult. • Include formal charges only."
          />

          <Input label="L3. Shoplifting/Vandalism" suffix="times" size={4} model="data.legal_status.l3" />

          <Input label="L4. Parole/Probation Violations" suffix="times" size={4} model="data.legal_status.l4" />
          <Input label="L5. Drug Charges" suffix="times" size={4} model="data.legal_status.l5" />
          <Input label="L6. Forgery" suffix="times" size={4} model="data.legal_status.l6" />
          <Input label="L7. Weapons Offense" suffix="times" size={4} model="data.legal_status.l7" />
          <Input label="L8. Burglary/Larceny/ Breaking and Entering" suffix="times" size={4} model="data.legal_status.l8" />
          <Input label="L9. Robbery" suffix="times" size={4} model="data.legal_status.l9" />
          <Input label="L10. Assault" suffix="times" size={4} model="data.legal_status.l10" />
          <Input label="L11. Arson" suffix="times" size={4} model="data.legal_status.l11" />
          <Input label="L12. Rape" suffix="times" size={4} model="data.legal_status.l12" />
          <Input label="L13. Homicide/ Manslaughter" suffix="times" size={4} model="data.legal_status.l13" />
          <Input label="L14. Prostitution" suffix="times" size={4} model="data.legal_status.l14" />
          <Input label="L15. Contempt of Court" suffix="times" size={4} model="data.legal_status.l15" />
          <Input label="L16. Other:" suffix="times" size={4} model="data.legal_status.l16" />
          <Input label="If other, specify:" model="data.legal_status.l16_other" />

          <Input
            label="L17. How many of these charges resulted in convictions?"
            description="• If L3–16 = 00, then question L17 = “NN.” • Do not include misdemeanor offenses from questions L18–20 below. • Convictions include fines, probation, incarcerations, suspended sentences, guilty pleas, and plea bargaining."
            suffix="convictions"
            size={4}
            model="data.legal_status.l17"
          />

          <Input label="L18. Disorderly conduct, vagrancy, public intoxication?" suffix="times" size={4} model="data.legal_status.l18" />
          <Input label="L19. Driving while intoxicated?" suffix="times" size={4} model="data.legal_status.l19" />
          <Input
            label="L20. Major driving violations?"
            description="• Moving violations: speeding, reckless driving, no license, etc."
            suffix="times"
            size={4}
            model="data.legal_status.l20"
          />

          <Input
            label="L21. How many months have you been incarcerated in your life?"
            description="• If incarcerated 2 weeks or more, round this up to 1 month. List total number of months incarcerated."
            suffix="months"
            size={4}
            model="data.legal_status.l21"
          />

          <Input
            label="L22. How long was your last incarceration?"
            description="• Enter “NN” if never incarcerated."
            suffix="months"
            size={4}
            model="data.legal_status.l22"
          />

          <Input
            label="L23. What was it for?"
            description="• Use codes L3–16, L18–20. If multiple charges, choose the most severe. Enter “NN” if never incarcerated."
            size={4}
            model="data.legal_status.l23"
          />

          <RadioGroup
            label="L24. Are you presently awaiting charges, trial, or sentencing?"
            layout="horizontal-dense"
            model="data.legal_status.l24"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <Input
            label="L25. What for?"
            description="• Use the number of the type of crime committed: L3–16 and L18–20. • Refers to Question L24. If more than one charge, choose the most severe."
            size={4}
            model="data.legal_status.l25"
          />

          <Input
            label="L26. How many days in the past 30 days were you detained or incarcerated?"
            description="• Include being arrested and released on the same day."
            suffix="days"
            size={4}
            model="data.legal_status.l26"
          />

          <Input
            label="L27. How many days in the past 30 days have you engaged in illegal activities for profit?"
            description="• Exclude simple drug possession. Include drug dealing, prostitution, selling stolen goods, etc. May be cross-checked with Question E17 under Employment/Support Section."
            suffix="days"
            size={4}
            model="data.legal_status.l27"
          />

          <Alert glyph="info" children="For Questions L28-29, ask the patient to use the Patient’s Rating Scale." />

          <Input
            label="L28. How serious do you feel your present legal problems are?"
            description="• Exclude civil problems"
            model="data.legal_status.l28"
          />

          <Input
            label="L29. How important to you now is counseling or referral for these legal problems?"
            description="• Patient is rating a need for additional referral to legal counsel for defense against criminal charges."
            model="data.legal_status.l29"
          />

          <Divider />

          <h3>Interviewer Severity Rating</h3>

          <Input label="L30. How would you rate the patient’s need for legal services or counseling?" model="data.legal_status.l30" />

          <Divider />

          <h3>Confidence Rating</h3>
          <h4>Is the above information significantly distorted by:</h4>

          <RadioGroup label="L31. Patient’s misrepresentation?" layout="horizontal-dense" model="data.legal_status.l31">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup label="L32. Patient’s inability to understand?" layout="horizontal-dense" model="data.legal_status.l32">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <SmartTextarea
            useDictation
            label="Legal Comments"
            description="(Include question number with your notes)"
            model="data.legal_status.notes"
          />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Family History"
        headingType="h2"
        scrollview={{
          id: 'family_history',
          name: 'Family History',
        }}
        commentsModel="data.family_history"
      >
        <FormSection>
          <h3>
            Have any of your blood-related relatives had what you would call a significant drinking, drug use, or psychiatric problem?
            Specifically, was there a problem that did or should have led to treatment?
          </h3>

          <ul>
            <li>0 = Clearly No for any relatives in that category</li>
            <li>1 = Clearly Yes for any relatives in that category</li>
            <li>X = Uncertain or don’t know</li>
            <li>N = Never had a relative in that category</li>
            <li>
              In cases in which there is more than one person for a category, report the most severe. Accept the patient’s judgment on these
              questions.
            </li>
          </ul>

          <h3>Mother’s Side</h3>

          <Grid gap="0.25rem">
            <Label label="H1. Grandmother" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h1_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h1_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h1_psych" />
            </Flex>
          </Grid>

          <Grid gap="0.25rem">
            <Label label="H2. Grandfather" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h2_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h2_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h2_psych" />
            </Flex>
          </Grid>

          <Grid gap="0.25rem">
            <Label label="H3. Mother" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h3_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h3_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h3_psych" />
            </Flex>
          </Grid>

          <Grid gap="0.25rem">
            <Label label="H4. Aunt" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h4_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h4_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h4_psych" />
            </Flex>
          </Grid>

          <Grid gap="0.25rem">
            <Label label="H5. Uncle" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h5_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h5_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h5_psych" />
            </Flex>
          </Grid>

          <Divider />

          <h3>Father’s Side</h3>

          <Grid gap="0.25rem">
            <Label label="H6. Grandmother" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h6_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h6_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h6_psych" />
            </Flex>
          </Grid>

          <Grid gap="0.25rem">
            <Label label="H7. Grandfather" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h7_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h7_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h7_psych" />
            </Flex>
          </Grid>

          <Grid gap="0.25rem">
            <Label label="H8. Father" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h8_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h8_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h8_psych" />
            </Flex>
          </Grid>

          <Grid gap="0.25rem">
            <Label label="H9. Aunt" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h9_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h9_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h9_psych" />
            </Flex>
          </Grid>

          <Grid gap="0.25rem">
            <Label label="H10. Uncle" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h10_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h10_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h10_psych" />
            </Flex>
          </Grid>

          <Divider />

          <h3>Siblings</h3>

          <Grid gap="0.25rem">
            <Label label="H11. Brother" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h11_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h11_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h11_psych" />
            </Flex>
          </Grid>

          <Grid gap="0.25rem">
            <Label label="H12. Sister" />
            <Flex gap="0.5rem">
              <Input suffix="Alcohol" size={3} model="data.family_history.h12_alcohol" />
              <Input suffix="Drug" size={3} model="data.family_history.h12_drug" />
              <Input suffix="Psych." size={3} model="data.family_history.h12_psych" />
            </Flex>
          </Grid>

          <Divider />

          <SmartTextarea
            useDictation
            label="Family History Comments"
            description="(Include question number with your notes)"
            model="data.family_history.notes"
          />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Family Social Relationships"
        headingType="h2"
        scrollview={{
          id: 'family_social',
          name: 'Family Social Relationships',
        }}
        commentsModel="data.family_social"
      >
        <FormSection>
          <Input
            label="F1. Marital Status:"
            description="1–Married 3–Widowed 5–Divorced 2–Remarried 4–Separated 6–Never Married • Code common-law marriage as “1” and specify in comments."
            size={4}
            model="data.family_social.f1"
          />

          <Label
            label="F2. How long have you been in this marital status (Question F1)?"
            description="• If never married, then since age 18."
          />

          <Flex gap="1rem">
            <Input suffix="Years" model="data.family_social.f2.years" size={4} />
            <Input suffix="Months" model="data.family_social.f2.months" size={4} />
          </Flex>

          <RadioGroup
            label="F3. Are you satisfied with this situation?"
            description="• Satisfied = generally liking the situation. • Refers to Questions F1 and F2."
            layout="vertical-dense"
            model="data.family_social.f3"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Indifferent" value={1} />
            <Radio label="2 – Yes" value={2} />
          </RadioGroup>

          <RadioGroup
            label="F4. Usual living arrangements (past 3 years):"
            description="• Choose arrangements most representative of the past 3 years. If there is an even split in time between these arrangements, choose the most recent arrangement."
            layout="vertical-dense"
            model="data.family_social.f4"
          >
            <Radio label="1–With sexual partner and children" value={1} />
            <Radio label="2–With sexual partner alone" value={2} />
            <Radio label="3–With children alone" value={3} />
            <Radio label="4–With parents" value={4} />
            <Radio label="5–With family" value={5} />
            <Radio label="6–With friends" value={6} />
            <Radio label="7–Alone" value={7} />
            <Radio label="8–Controlled environment" value={8} />
            <Radio label="9–No stable arrangement" value={9} />
          </RadioGroup>

          <Label
            label="F5. How long have you lived in these arrangements?"
            description="• If with parents or family, since age 18. • Code years and months living in arrangements from Question F4."
          />

          <Flex gap="1rem">
            <Input suffix="Years" model="data.family_social.f5.years" size={4} />
            <Input suffix="Months" model="data.family_social.f5.months" size={4} />
          </Flex>

          <RadioGroup label="F6. Are you satisfied with these arrangements?" layout="vertical-dense" model="data.family_social.f6">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Indifferent" value={1} />
            <Radio label="2 – Yes" value={2} />
          </RadioGroup>

          <h3>Do you live with anyone who:</h3>

          <RadioGroup label="F7. Has a current alcohol problem?" layout="horizontal-dense" model="data.family_social.f7">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup
            label="F8. Uses nonprescribed drugs, or abuses prescribed drugs?"
            layout="horizontal-dense"
            model="data.family_social.f8"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup
            label="F9. With whom do you spend most of your free time?"
            description="• If a girlfriend/boyfriend is considered as family by patient, then the patient must refer to that person as “family” throughout this section, not as a friend."
            layout="vertical-dense"
            model="data.family_social.f9"
          >
            <Radio label="1–Family" value={1} />
            <Radio label="2–Friends" value={2} />
            <Radio label="3–Alone" value={3} />
          </RadioGroup>

          <RadioGroup
            label="F10. Are you satisfied with spending your free time this way?"
            description="• A satisfied response must indicate that the person generally likes the situation. Refers to Question F9."
            layout="vertical-dense"
            model="data.family_social.f10"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Indifferent" value={1} />
            <Radio label="2 – Yes" value={2} />
          </RadioGroup>

          <Input
            label="F11. How many close friends do you have?"
            description="• Stress that you mean close. Exclude family members. These are “reciprocal” relationships or mutually supportive relationships."
            suffix="friends"
            size={4}
            model="data.family_social.f11"
          />

          <h3>Would you say you have had a close, long-lasting, personal relationship with any of the following people in your life:</h3>

          <ul>
            <li>0 = Clearly No for all in class</li>
            <li>1 = Clearly Yes for any in class</li>
            <li>X = Uncertain or “I don’t know”</li>
            <li>N = Never had a relative in category</li>
          </ul>

          <Input label="F12. Mother" size={4} model="data.family_social.f12" />
          <Input label="F13. Father" size={4} model="data.family_social.f13" />
          <Input label="F14. Brothers/ sisters" size={4} model="data.family_social.f14" />
          <Input label="F15. Sexual partner/ spouse" size={4} model="data.family_social.f15" />
          <Input label="F16. Children" size={4} model="data.family_social.f16" />
          <Input label="F17. Friends" size={4} model="data.family_social.f17" />

          <Divider />

          <div>
            <h3>Have you had significant periods in which you have experienced serious problems getting along with:</h3>
            <p>
              • “Serious problems” mean those that endangered the relationship.
              <br />• A “problem” requires contact of some sort, either by telephone or in person.
            </p>
          </div>

          <Grid gap="2rem">
            <Grid gap="0.25rem">
              <Label label="F18. Mother" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f18_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f18_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="F19. Father" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f19_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f19_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="F20. Brother/sister" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f20_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f20_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="F21. Sexual partner/spouse" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f21_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f21_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="F22. Children" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f22_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f22_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="F23. Other significant family" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f23_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f23_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Input label="If other, specify:" model="data.family_social.f23_other" />

            <Grid gap="0.25rem">
              <Label label="F24. Close friends" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f24_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f24_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="F25. Neighbors" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f25_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f25_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="F26. Coworkers" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f26_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f26_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>
          </Grid>

          <Divider />

          <h3>Has anyone ever abused you?</h3>

          <Grid gap="2rem">
            <Grid gap="0.25rem">
              <Label label="F27. Emotionally" description="• Made you feel bad through harsh words." />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f27_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f27_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="F28. Physically" description="• Caused you physical harm." />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f28_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f28_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="F29. Sexually" description="• Forced sexual advances/acts." />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.family_social.f29_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.family_social.f29_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>
          </Grid>

          <Divider />

          <h3>How many days in the past 30 days have you had serious conflicts with:</h3>
          <Input label="F30. Your family?" suffix="days" size={4} model="data.family_social.f30" />
          <Input label="F31. Other people (excluding family)?" suffix="days" size={4} model="data.family_social.f31" />

          <Alert glyph="info" children="For Questions F32–35, ask the patient to use the Patient’s Rating Scale." />

          <h3>How troubled or bothered have you been in the past 30 days by:</h3>
          <Input label="F32. Family problems?" size={4} model="data.family_social.f32" />
          <Input label="F33. Social problems?" size={4} model="data.family_social.f33" />

          <h3>How important to you now is treatment or counseling for:</h3>
          <Input
            label="F34. Family problems"
            description="• Patient is rating his or her need for counseling for family problems, not whether the patient would be willing to attend."
            size={4}
            model="data.family_social.f34"
          />
          <Input
            label="F35. Social problems"
            description="• Include patient’s need to seek treatment for such social problems as loneliness, inability to socialize, and dissatisfaction with friends. Patient rating should refer to dissatisfaction, conflicts, or other serious problems."
            size={4}
            model="data.family_social.f35"
          />

          <Divider />

          <h3>Interviewer Severity Rating</h3>
          <Input
            label="F36. How would you rate the patient’s need for family and/or social counseling?"
            size={4}
            model="data.family_social.f36"
          />

          <Divider />

          <h3>Confidence Rating</h3>
          <h4>Is the above information significantly distorted by:</h4>

          <RadioGroup label="F37. Patient’s misrepresentation?" layout="horizontal-dense" model="data.family_social.f37">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup label="F38. Patient’s inability to understand?" layout="horizontal-dense" model="data.family_social.f38">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <SmartTextarea
            useDictation
            label="Family/Social Comments"
            description="(Include question number with your notes)"
            model="data.family_social.notes"
          />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Psychiatric Status"
        headingType="h2"
        scrollview={{
          id: 'psychiatric_status',
          name: 'Psychiatric Status',
        }}
        commentsModel="data.psychiatric_status"
      >
        <FormSection>
          <h3>How many times have you been treated for any psychological or emotional problems:</h3>

          <Input label="P1. In a hospital or inpatient setting?" suffix="times" size={4} model="data.psychiatric_status.p1" />

          <Input
            label="P2. Outpatient/private patient?"
            description="• Do not include substance abuse, employment, or family counseling. Treatment episode = a series of more or less continuous visits or treatment days, not the number of visits or treatment days. • Enter diagnosis in comments if known."
            suffix="times"
            size={4}
            model="data.psychiatric_status.p2"
          />

          <Divider />

          <RadioGroup
            label="P3. Do you receive a pension for a psychiatric disability?"
            layout="horizontal-dense"
            model="data.psychiatric_status.p3"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <Divider />

          <h3>Have you had a significant period of time (that was not a direct result of alcohol/drug use)</h3>

          <Grid gap="2rem">
            <Grid gap="0.25rem">
              <Label label="P4. Experienced serious depression, sadness, hopelessness, loss of interest, difficulty with daily functioning?" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.psychiatric_status.p4_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.psychiatric_status.p4_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="P5. Experienced serious anxiety/tension— were uptight, unreasonably worried, unable to feel relaxed?" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.psychiatric_status.p5_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.psychiatric_status.p5_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="P6. Experienced hallucinations—saw things or heard voices that others didn’t see/hear?" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.psychiatric_status.p6_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.psychiatric_status.p6_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label label="P7. Experienced trouble understanding, concentrating, or remembering?" />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.psychiatric_status.p7_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.psychiatric_status.p7_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label
                label="P8. Experienced trouble controlling violent behavior, including episodes of rage or violence?"
                description="• Patient can be under the influence of alcohol/drugs."
              />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.psychiatric_status.p8_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.psychiatric_status.p8_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label
                label="P9. Experienced serious thoughts of suicide?"
                description="• Patient seriously considered a plan for taking his or her life. Patient can be under the influence of alcohol/drugs."
              />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.psychiatric_status.p9_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.psychiatric_status.p9_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label
                label="P10. Attempted suicide?"
                description="• Include actual suicidal gestures or attempts. • Patient can be under the influence of alcohol/drugs."
              />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.psychiatric_status.p10_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.psychiatric_status.p10_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>

            <Grid gap="0.25rem">
              <Label
                label="P11. Been prescribed medication for any psychological or emotional problems?"
                description="• Prescribed for the patient by a physician. Record “Yes” if a medication was prescribed even if the patient is not taking it."
              />
              <Flex gap="1rem">
                <RadioGroup label="Past 30 days" layout="horizontal-dense" model="data.psychiatric_status.p11_past_30_days">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
                <RadioGroup label="In Your Life" layout="horizontal-dense" model="data.psychiatric_status.p11_lifetime">
                  <Radio label="0 – No" value={0} />
                  <Radio label="1 – Yes" value={1} />
                </RadioGroup>
              </Flex>
            </Grid>
          </Grid>

          <Divider />

          <Input
            label="P12. How many days in the past 30 days have you experienced these psychological or emotional problems?"
            description="• This refers to problems noted in Questions P4–P10."
            suffix="days"
            size={4}
            model="data.psychiatric_status.p12"
          />

          <Divider />

          <Alert glyph="info" children="For Questions P13–P14, ask the patient to use the Patient’s Rating Scale." />

          <Input
            label="P13. How much have you been troubled or bothered by these psychological or emotional problems in the past 30 days?"
            description="• Patient should be rating the problem days from Question P12."
            size={4}
            model="data.psychiatric_status.p13"
          />

          <Input
            label="P14. How important to you now is treatment for these psychological or emotional problems?"
            size={4}
            model="data.psychiatric_status.p14"
          />

          <Divider />

          <Alert glyph="info" children="The following items are to be completed by the interviewer:" />

          <h3>At the time of the interview, the patient was:</h3>

          <RadioGroup label="P15. Obviously depressed/withdrawn" layout="horizontal-dense" model="data.psychiatric_status.p15">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup label="P16. Obviously hostile" layout="horizontal-dense" model="data.psychiatric_status.p16">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup label="P17. Obviously anxious/nervous" layout="horizontal-dense" model="data.psychiatric_status.p17">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup
            label="P18. Having trouble with reality testing, thought disorders, paranoid thinking"
            layout="horizontal-dense"
            model="data.psychiatric_status.p18"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup
            label="P19. Having trouble comprehending, concentrating, remembering"
            layout="horizontal-dense"
            model="data.psychiatric_status.p19"
          >
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup label="P20. Having suicidal thoughts" layout="horizontal-dense" model="data.psychiatric_status.p20">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <Divider />

          <h3>Interviewer Severity Rating</h3>

          <Input
            label="P21. How would you rate the patient’s need for psychiatric/psychological treatment?"
            size={4}
            model="data.psychiatric_status.p21"
          />

          <Divider />

          <h3>Confidence Rating</h3>
          <h4>Is the above information significantly distorted by:</h4>

          <RadioGroup label="P22. Patient’s misrepresentation?" layout="horizontal-dense" model="data.psychiatric_status.p22">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <RadioGroup label="P23. Patient’s inability to understand?" layout="horizontal-dense" model="data.psychiatric_status.p23">
            <Radio label="0 – No" value={0} />
            <Radio label="1 – Yes" value={1} />
          </RadioGroup>

          <Divider />

          <SmartTextarea
            useDictation
            label="Psychiatric Status Comments"
            description="(Include question number with your notes)"
            model="data.psychiatric_status.notes"
          />
        </FormSection>
      </Section>
    </DataFormOverlay>
  )
}

export const ASI5Overlay = withOverlayError(RootASI5Overlay)
