import React from 'react'

import { useDataTable } from '../../../DataTable/useDataTable'
import { NotificationConfigsDataTable } from '../../../../constructs/Notifications/NotificationConfigsDataTable'

export const AdminNotificationConfigsTable = ({ onSelect }: any) => {
  const tableProps = useDataTable({
    name: ['notification_configs'],
    endpoint: '/notification_configs',
    localStorageKey: 'notification_configs_selector_v1',
  })

  return <NotificationConfigsDataTable {...tableProps} onClick={onSelect} />
}
