import React from 'react'

import Divider from '../../components/Divider'
import Section from '../../components/Section'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import CIWASymptoms from '../../components/Overlays/pages/DataForms/common/CIWASymptoms'
import VitalSignsForm from '../../components/Overlays/pages/DataForms/common/VitalSignsForm'

const RootCiwaOverlay = () => (
  <DataFormOverlay
    title="Clinical Institute Withdrawal Assessment"
    category="nursing"
    subcategory="ciwa"
    requestClientSignature={false}
    requestAuthorSignature={true}
    requireSupervisor={true}
    enableTreatmentPlanSelector
    icon="nursing"
  >
    <Section
      title="Vital Signs"
      scrollview={{
        id: 'vital_signs',
        name: 'Vital Signs',
      }}
      commentsModel="data.vital_signs"
    >
      <VitalSignsForm showDateTime={false} showPainScale={false} showComments={false} requireHeight />
    </Section>

    <Divider />

    <Section
      title="CIWA Symptoms"
      scrollview={{
        id: 'ciwa_symptoms',
        name: 'CIWA Symptoms',
      }}
      commentsModel="data.ciwa_symptoms"
    >
      <CIWASymptoms />
    </Section>
  </DataFormOverlay>
)

export const CiwaOverlay = withOverlayError(RootCiwaOverlay)
