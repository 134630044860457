import React from 'react'

import FormSection from '../../../../Forms/FormSection'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import ContextShow from '../../../../Forms/ContextShow'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'

const CurrentDiagnosis = () => {
  return (
    <FormSection layout="vertical">
      <CheckboxGroup label="Current Diagnosis:" layout="vertical-dense">
        <Checkbox label="F10.20 Alcohol use disorder, severe" model="data.current_diagnosis.f10_20" />
        <Checkbox label="F11.20 Opioid use disorder, severe" model="data.current_diagnosis.f11_20" />
        <Checkbox label="F12.20 Cannabis use disorder, severe" model="data.current_diagnosis.f12_20" />
        <Checkbox label="F13.20 Sedative, hypnotic or anxiolytic disorder, severe" model="data.current_diagnosis.f13_20" />
        <Checkbox label="F14.20 Cocaine use disorder, severe" model="data.current_diagnosis.f14_20" />
        <Checkbox label="F15.20 Other stimulant use disorder, severe" model="data.current_diagnosis.f15_20" />
        <Checkbox label="F16.20 Hallucinogen use disorder, severe" model="data.current_diagnosis.f16_20" />
        <Checkbox label="F17.20 Nicotine dependence" model="data.current_diagnosis.f17_20" />
        <Checkbox label="F18.20 Inhalant related disorder, severe" model="data.current_diagnosis.f18_20" />
        <Checkbox label="F19.20 Other psychoactive substance dependence, severe" model="data.current_diagnosis.f19_20" />
        <Checkbox label="Other" model="data.current_diagnosis.other" />
      </CheckboxGroup>

      <ContextShow when="data.current_diagnosis.other" is={true}>
        <SmartTextarea
          useDictation
          label="Please specify:"
          model="data.current_diagnosis.other_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>
    </FormSection>
  )
}

export default CurrentDiagnosis
