import React from 'react'
import { NavLink, Route, useNavigate } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'
import { invalidateQueries } from '@behavehealth/hooks/useNewAPI'
import { request } from '@behavehealth/modules/axios'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import State from '@behavehealth/components/State'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { RoundSignatureStatus } from '@behavehealth/constructs/Rounds/RoundSignatureStatus'
import { RoundsOverlay } from '@behavehealth/constructs/Rounds/RoundsOverlay'
import { RoundStatus, statuses } from '@behavehealth/constructs/Rounds/RoundStatus'

const RootRounds = () => {
  return (
    <>
      <RoundsIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<RoundsOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const RoundsIndex = () => {
  const navigate = useNavigate()

  const [isCreating, setIsCreating] = React.useState(false)

  const templateTableProps = useDataTable({
    name: ['round_templates'],
    endpoint: `/round_templates`,
    localStorageKey: 'round_templates',
  })

  const tableProps = useDataTable({
    name: ['rounds'],
    endpoint: `/rounds`,
    localStorageKey: 'rounds',
  })

  const { data: templates, isLoading: isLoadingTemplates } = templateTableProps

  const isTemplatesEmpty = size(templates) === 0

  const createRound = async (templateId: string) => {
    if (!templateId) return

    setIsCreating(true)

    try {
      const newRound = await request.post('/rounds', { round_template_id: templateId })

      navigate(newRound.data.data.id)

      invalidateQueries('rounds')
    } catch (error) {
      console.error(error)
    } finally {
      setIsCreating(false)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell as={NavLink} id={data.id} to={data.id} value={value} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        width: 150,
        disableHide: true,
        formatValue: ({ value }: any) => {
          return <RoundStatus status={value} />
        },
      },
      {
        title: 'Signature Status',
        model: 'signature_status',
        width: 150,
        disableHide: true,
        formatValue: ({ value }: any) => {
          return <RoundSignatureStatus status={value} />
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [],
  )

  return (
    <Page
      title="Rounds"
      icon="rounds"
      actions={
        <>
          <Button
            as={NavLink}
            label="Manage Templates"
            glyph="settings"
            type="minimal"
            link="/settings/rounds-templates"
            permission="rounds_templates.view"
          />

          <Dropdown label="Add Rounds" glyph="add" buttonType="primary" permission="rounds.create" isLoading={isCreating}>
            {isTemplatesEmpty || isLoadingTemplates ? (
              <State
                icon="rounds"
                title="Round Templates"
                isLoading={isLoadingTemplates}
                isEmpty={isTemplatesEmpty}
                emptyDescription="Add a round template first to create a round"
                className="!pb-6"
                minHeight={100}
                emptyActions={
                  <Button
                    label="Create Round Template"
                    glyph="settings"
                    type="primary"
                    link="/settings/rounds-templates/new"
                    permission="rounds_templates.view"
                    size={200}
                  />
                }
              />
            ) : (
              templates.map((template: any) => (
                <DropdownItem
                  icon="rounds"
                  key={template.id}
                  label={template.name}
                  onClick={() => createRound(template.id)}
                  permission="rounds.create"
                />
              ))
            )}
          </Dropdown>
        </>
      }
    >
      <Grid>
        <DataTable asCard title="Rounds" icon="rounds" columns={columns} filtersConfig={FILTERS_CONFIG} {...tableProps} />
      </Grid>
    </Page>
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  status: {
    label: 'Signature Status',
    type: 'multi_select',
    options: Object.entries(statuses).map(([value, option]) => ({ label: option.label, value })),
  },
  signature_status: {
    label: 'Signature Status',
    type: 'multi_select',
    options: [
      { label: 'Pending', value: 'pending' },
      { label: 'Signed', value: 'signed' },
    ],
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}

export const Rounds = withPageError(RootRounds)
