import React from 'react'

import { useCreate } from '../../hooks/useNewAPI'
import { useDataTable } from '../../components/DataTable/useDataTable'

import Alert from '../../components/Alert'
import Glyph from '../../components/Glyph'
import Loader from '../../components/Loader'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

import { AdminDiscountTemplatesDataTable } from '../InternalBilling/AdminDiscountTemplatesDataTable'

export const QuoteDiscountsImportOverlay = (props: any) => {
  const { onImport, onClose } = props

  const tableProps = useDataTable({
    name: ['discounts'],
    endpoint: '/internal_templates',
    params: { category: 'discount' },
    localStorageKey: 'discounts_import_v1',
  })

  const { mutateAsync: createDiscount, isLoading: isCreatingDiscount } = useCreate({
    name: ['create-discount'],
    url: '/discounts',
    invalidate: 'discounts',
  })

  const [isImporting, setIsImporting] = React.useState(false)

  const handleSingleImport = async (selectedRow: any) => {
    if (!selectedRow) return

    setIsImporting(true)

    try {
      const newDiscount = await createDiscount({
        name: selectedRow.name,
        status: selectedRow.status,
        category: 'discount',
        ...selectedRow.data,
      })

      if (!newDiscount?.data) return

      await onImport?.(newDiscount?.data)

      onClose?.()
    } catch (error) {
      console.error(error)
    } finally {
      setIsImporting(false)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={90} onClose={onClose}>
      <Overlay.Header icon="certificate" title="Import from Catalog Discount Templates" />

      <Overlay.Content>
        <Section>
          <Alert
            small
            contrast
            className="-mt-2 mb-4"
            graphic={isCreatingDiscount || isImporting ? <Loader size={16} className="mr-4" /> : <Glyph glyph="info" size={16} />}
          >
            {isCreatingDiscount || isImporting ? 'Importing Discount…' : 'Select a discount template to import it into the quote.'}
          </Alert>

          <AdminDiscountTemplatesDataTable {...tableProps} title="Discount Templates" onClick={handleSingleImport} />
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}
