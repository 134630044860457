import React from 'react'
import { useSelector } from 'react-redux'
import size from 'lodash/size'
import get from 'lodash/get'

import { isDefined } from '../utils/functions'

import MarketingCard from '../components/Cards/MarketingCard'
import Page from '../components/Page'

const withMarketing = (Component: React.ReactNode, config: any) => (componentProps: any) => {
  const { addon, feature, help, marketingID, plan, title } = config

  let showMarketing = false

  const tenant = useSelector((state) => state.me?.tenant)
  const featureFlagsV2 = tenant?.feature_flags
  const checkFeatureFlagV2 = isDefined(feature) && size(featureFlagsV2) > 0
  const isTrialing = tenant?.plan_status === 'trialing'

  if (isTrialing && checkFeatureFlagV2 && !get(featureFlagsV2, feature)) {
    showMarketing = true
  }

  if (showMarketing) {
    return (
      <Page>
        <MarketingCard plan={plan} addon={addon} feature={feature} help={help} title={title} marketingID={marketingID} />
      </Page>
    )
  }

  return <Component {...componentProps} />
}

export default withMarketing
