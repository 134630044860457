import React, { Component } from 'react'

import FormSection from '../../../../Forms/FormSection'
import Option from '../../../../Forms/Option'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Select from '../../../../Forms/Select'
import SmartTextarea from '../../../../Forms/SmartTextarea'

class WithdrawalSymptomsForm extends Component {
  render() {
    const withdrawalSymptoms = [
      { label: 'Depression', model: 'depression' },
      { label: 'Anxiety', model: 'anxiety' },
      { label: 'Irritability', model: 'irritability' },
      { label: 'Stress sensitivity', model: 'stress_sensitivity' },
      { label: 'Hopelessness', model: 'hopelessness' },
      { label: 'Difficulty concentrating', model: 'difficulty_concentrating' },
      { label: 'Fearfulness', model: 'fearfulness' },
      { label: 'Mood swings', model: 'mood_swings' },
      { label: 'Nausea', model: 'nausea' },
      { label: 'Vomiting', model: 'vomiting' },
      { label: 'Tremors', model: 'tremors' },
      { label: 'Runny nose', model: 'runny_nose' },
      { label: 'Body aches', model: 'body_aches' },
      { label: 'Auditory or visual disturbances', model: 'auditory_or_visual_disturbances' },
      { label: 'Tactile disturbances (skin crawling, etc)', model: 'tactile_disturbances' },
      { label: 'Dizziness', model: 'dizziness' },
      { label: 'Headache', model: 'headache' },
      { label: 'Disorientation or cloudiness', model: 'disorientation_or_cloudiness' },
      { label: 'Agitation or restlessness', model: 'agitation_or_restlessness' },
      { label: 'Upset stomach/Cramps', model: 'upset_stomach_cramps' },
    ]

    return (
      <FormSection layout="vertical">
        {withdrawalSymptoms.map((data, idx) => (
          <RadioGroup
            key={idx}
            testKey={`${data.model}_radio_group`}
            label={data.label}
            model={`data.withdrawal_symptoms.${data.model}`}
            layout="horizontal"
            defaultValue={null}
          >
            <Radio testKey={`${data.model}_low_radio_button`} label="Low" value="low" />
            <Radio testKey={`${data.model}_moderate_radio_button`} label="Moderate" value="moderate" />
            <Radio testKey={`${data.model}_severe_radio_button`} label="Severe" value="severe" />
          </RadioGroup>
        ))}

        <Select
          allowEmpty
          label="What is the client's current craving level? (1 = low, 10 = high)"
          model="data.withdrawal_symptoms.craving_level"
        >
          <Option label="1" value="1" />
          <Option label="2" value="2" />
          <Option label="3" value="3" />
          <Option label="4" value="4" />
          <Option label="5" value="5" />
          <Option label="6" value="6" />
          <Option label="7" value="7" />
          <Option label="8" value="8" />
          <Option label="9" value="9" />
          <Option label="10" value="10" />
        </Select>

        <SmartTextarea useDictation label="Notes" model="data.withdrawal_symptoms.notes" />
      </FormSection>
    )
  }
}

export default WithdrawalSymptomsForm
