import React from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { COLORS } from '@behavehealth/theme'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CheckboxGroup,
  ContextShow,
  Flex,
  Form,
  Glyph,
  Grid,
  Iframe,
  Input,
  Label,
  PageLayout,
  PageSection,
  PhoneInput,
  Radio,
  RadioGroup,
  Text,
  URLInput,
} from '@behavehealth/components'

import BedAvailabilityWidget from '@behavehealth/components/BedAvailabilityWidget'
import useCopyToClipboard from '@behavehealth/hooks/useCopyToClipboard'

const BedsAvailability = () => {
  const tenant = useSelector((state: any) => state.me?.tenant)

  const initialData = {
    showTotalBeds: true,
    showPercentage: true,
    showBreakdown: true,
    callToAction: 'application',
    websiteLabel: `Go to ${tenant?.name}`,
    phone_no: tenant?.phone_no,
  }

  const [data, setData] = React.useState(initialData)
  const [valid, setValid] = React.useState()
  const identifier = React.useRef(uuid())

  if (!tenant) return null

  const embed = `<div id="behave-bed-availability-${
    identifier.current
  }"></div><script type="text/javascript">!function(){function e(){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.onload=function(){BedAvailability.init({parentElement:"behave-bed-availability-${
    identifier.current
  }",tenant:"${tenant.subdomain}",tenantID:"${tenant.id}",externalID:"${tenant.external_id}",timezone:"${
    tenant.timezone
  }",showPercentage:${data?.showPercentage},showTotalBeds:${data?.showTotalBeds},showBreakdown:${data?.showBreakdown},callToAction:"${data?.callToAction}",${
    data?.phone_no ? `phone_no:"${data?.phone_no}",` : ''
  }${data?.website ? `website:"${data?.website}",` : ''}${data?.websiteLabel ? `websiteLabel:"${data?.websiteLabel}"` : ''}})},e.src="${
    process.env.BH_APP_WIDGETS_URL
  }/BedAvailability.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}window.attachEvent?window.attachEvent("onload",e):window.addEventListener("load",e,!1)}();</script>`

  const code = valid ? embed : 'Complete all the required files to generate an embed code'

  const { copy, didCopy: codeCopied } = useCopyToClipboard({
    text: code,
    notification: 'Code copied!',
  })

  return (
    <div>
      <PageLayout>
        <PageSection>
          <PageSection.Header>
            <PageSection.Title title="Widget Description" />
          </PageSection.Header>

          <PageSection.Content>
            <Card>
              <CardHeader className="!px-2 !py-[0.65rem]">
                <Grid gap="1rem">
                  <Text nowrap glyph="tick" description="Embed a code snippet on any website to show your beds availability" />
                  <Text nowrap glyph="tick" description="The widget will show the live availability data across all properties and beds" />
                  <Text
                    nowrap
                    glyph="tick"
                    description="Customize the call-to-action to link to the application form, call a phone number, or open any web page"
                  />
                </Grid>
              </CardHeader>
            </Card>
          </PageSection.Content>
        </PageSection>

        <PageSection>
          <PageSection.Header>
            <PageSection.Title title="Widget Example" />
            <PageSection.Subtitle subtitle="" />
          </PageSection.Header>

          <PageSection.Content>
            <BedAvailabilityWidget tenant={tenant.subdomain} tenantID={tenant.id} externalID={tenant.external_id} {...data} />
          </PageSection.Content>
        </PageSection>

        <PageSection>
          <PageSection.Header>
            <PageSection.Title title="Widget Configurator" />
            <PageSection.Subtitle subtitle="Change the visible elements of your widget and the call-to-action" />
          </PageSection.Header>

          <PageSection.Content>
            <Card>
              <CardHeader>
                <Form initialModel={initialData} onUpdate={setData} onValidationUpdate={setValid}>
                  <Grid gap=".5rem">
                    <CheckboxGroup label="Widget Settings" layout="vertical-dense" maxWidth="auto">
                      <Checkbox label="Show number of total beds" model="showTotalBeds" />
                      <Checkbox label="Show availability percentage" model="showPercentage" />
                      <Checkbox label="Show breakdown by accepted gender" model="showBreakdown" />
                    </CheckboxGroup>

                    <RadioGroup label="Call to Action" model="callToAction" layout="vertical-dense" maxWidth="auto">
                      <Radio label="Link to application form" value="application" />
                      <Radio label="Call a phone number" value="phone" />
                      <Radio label="Link to a website" value="website" />
                    </RadioGroup>

                    <ContextShow when="callToAction" is="phone">
                      <PhoneInput
                        label="Phone Number"
                        model="phone_no"
                        maxWidth="auto"
                        validations={{
                          presence: {
                            message: 'Please enter a number phone',
                          },
                        }}
                      />
                    </ContextShow>

                    <ContextShow when="callToAction" is="website">
                      <URLInput
                        label="Website"
                        model="website"
                        maxWidth="auto"
                        validations={{
                          presence: {
                            message: 'Please enter a website url',
                          },
                        }}
                      />

                      <Input
                        label="Button Text"
                        model="websiteLabel"
                        maxWidth="auto"
                        validations={{
                          presence: {
                            message: 'Please enter the label for the button',
                          },
                        }}
                      />
                    </ContextShow>
                  </Grid>
                </Form>
              </CardHeader>
            </Card>
          </PageSection.Content>
        </PageSection>

        <PageSection>
          <PageSection.Header>
            <PageSection.Title title="Usage Instructions" />
            <PageSection.Subtitle subtitle="Paste the code below on your website to embed the beds availability widget or send it to your website administrator" />
          </PageSection.Header>

          <PageSection.Content>
            <Card>
              <CardHeader>
                <pre
                  css={{
                    width: '100%',
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-wrap',
                    color: COLORS.textMuted,
                    background: '#EEF0F7',
                    border: `1px solid ${COLORS.divider}`,
                    borderRadius: 5,
                    padding: 10,
                    margin: 0,
                  }}
                >
                  {code}
                </pre>
              </CardHeader>

              <CardContent>
                <Flex gap="1rem">
                  <Button
                    glyph={codeCopied ? 'check' : 'copy'}
                    label={codeCopied ? 'Code Copied to Clipboard!' : 'Copy Code'}
                    onClick={copy}
                    display="inline-flex"
                    type={codeCopied ? 'default' : 'primary'}
                    isDisabled={!valid}
                  />

                  {valid && (
                    <a
                      href={`mailto:?subject=Please%20Embed%20Code%20To%20Contact%20Page&body=Hello,%0A%0ACan%20you%20please%20embed%20the%20code%20below%20to%20our%20contact%20page?%0A%0A${code}%0A%0AThank you!`}
                      target="_blank"
                      rel="noreferrer"
                      css={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        svg: { marginLeft: '0.25em' },
                        '&:hover': { textDecoration: 'underline' },
                      }}
                    >
                      Email to website administrator <Glyph glyph="external_link" size={14} color={COLORS.blue} />
                    </a>
                  )}
                </Flex>
              </CardContent>
            </Card>
          </PageSection.Content>
        </PageSection>
      </PageLayout>
    </div>
  )
}

export default BedsAvailability
