import React from 'react'

import { withOverlayError } from '../../../hocs/withOverlayError'

import Overlay from '../../Overlay'
import Section from '../../Section'

import ShareBedAvailabilityForm from '../../ApplicationForm/ShareBedAvailabilityForm'

const ShareBedAvailabilityFormOverlay = ({ onClose, subdomain, timezone }) => {
  return (
    <Overlay position="center" showBackdrop={true} onClose={onClose} maxWidth={42} closeOnBackdrop>
      <Overlay.Header icon="bed_management" title="Bed Availability Widget" />
      <Overlay.Content>
        <Section>
          <ShareBedAvailabilityForm subdomain={subdomain} timezone={timezone} />
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}

export default withOverlayError(ShareBedAvailabilityFormOverlay)
