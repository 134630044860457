import React from 'react'
import produce from 'immer'
import size from 'lodash/size'

import SummonOverlay from './SummonOverlay'

export const GlobalSummonOverlayContext: any = React.createContext({
  isOpen: false,
  overlay: null,
  setIsOpen: () => {},
  setOverlay: () => {},
})

export const GlobalSummonOverlayProvider = ({ children }: any) => {
  const [overlays, setOverlays]: any = React.useState([])

  const addOverlay = (overlay: React.ReactNode) => {
    setOverlays((o: any) => [...o, overlay])
  }

  const setOverlay = (overlay: React.ReactNode) => {
    setOverlays([overlay])
  }

  const removeOverlay = (index: number) => {
    setOverlays(
      produce(overlays, (draft: any) => {
        draft.splice(index, 1)
      }),
    )
  }

  return (
    <GlobalSummonOverlayContext.Provider
      value={{
        overlays,
        addOverlay,
        setOverlay,
        overlaysCount: size(overlays),
        removeOverlay,
      }}
    >
      {children}
    </GlobalSummonOverlayContext.Provider>
  )
}

export const GlobalSummonOverlay = () => {
  const { overlays, removeOverlay } = React.useContext(GlobalSummonOverlayContext)

  if (size(overlays) === 0) return null

  return overlays.map((overlay: any, index: number) => (
    <SummonOverlay isOpen overlay={overlay} cloneProps={{ stackIndex: index + 1 }} onClose={() => removeOverlay(index)} />
  ))
}
