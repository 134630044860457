import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useLocation } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Page, Tabs, HelpTagIframe } from '@behavehealth/components'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'

import AdminHRCustomNoteTemplates from './pages/admin_hr_custom_note_templates'
import ClientCustomNoteTemplates from './pages/client_custom_note_templates'
import DashboardCustomNoteTemplates from './pages/dashboard_custom_note_templates'
import GrievanceCustomNoteTemplates from './pages/grievance_custom_note_templates'
import OrganizationCustomNoteTemplates from './pages/organization_custom_note_templates'
import ProgramCustomNoteTemplates from './pages/program_custom_note_templates'
import PropertyCustomNoteTemplates from './pages/property_custom_note_templates'
import StaffCustomNoteTemplates from './pages/staff_custom_note_templates'

import { AdminHRCustomNoteTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/AdminHRCustomNoteTemplateOverlay'
import { ClientCustomNoteTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/ClientCustomNoteTemplateOverlay'
import { GrievanceCustomNoteTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/GrievanceCustomNoteTemplateOverlay'
import { OrganizationCustomNoteTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/OrganizationCustomNoteTemplateOverlay'
import { ProgramCustomNoteTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/ProgramCustomNoteTemplateOverlay'
import { PropertyCustomNoteTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/PropertyCustomNoteTemplateOverlay'
import { StaffCustomNoteTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/StaffCustomNoteTemplateOverlay'
import { TeamCustomNoteTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/TeamCustomNoteTemplateOverlay'

import ClientCustomNoteTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/ClientCustomNoteTemplatesImportOverlay'
import OrganizationCustomNoteTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/OrganizationCustomNoteTemplatesImportOverlay'
import ProgramCustomNoteTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/ProgramCustomNoteTemplatesImportOverlay'
import PropertyCustomNoteTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/PropertyCustomNoteTemplatesImportOverlay'
import StaffCustomNoteTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/StaffCustomNoteTemplatesImportOverlay'
import TeamCustomNoteTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/TeamCustomNoteTemplatesImportOverlay'

const TABS_CONFIG: any = {
  clients: {
    label: 'Add Client Template',
    permission: 'settings.client_custom_note_templates.create',
    feature_flag: 'client_custom_note_templates',
  },
  staff: {
    label: 'Add Staff Template',
    permission: 'settings.staff_custom_note_templates.create',
    feature_flag: 'staff_custom_note_templates',
  },
  properties: {
    label: 'Add Location Template',
    permission: 'settings.property_custom_note_templates.create',
    feature_flag: 'property_custom_note_templates',
  },
  organizations: {
    label: 'Add Organization Template',
    permission: 'settings.organization_custom_note_templates.create',
    feature_flag: 'organization_custom_note_templates',
  },
  programs: {
    label: 'Add Program Template',
    permission: 'settings.program_custom_note_templates.create',
    feature_flag: 'program_custom_note_templates',
  },
  admin_hr: {
    label: 'Add Admin HR Template',
    permission: false,
    feature_flag: 'admin_hr_custom_notes',
  },
  grievance: {
    label: 'Add Grievance Template',
    permission: 'settings.grievance_custom_note_templates.create',
    feature_flag: 'grievance_note_templates',
  },
}

const CustomNoteTemplates = () => {
  const { pathname } = useLocation()
  const { isBehave, isManagement } = useSettings()

  let selectedTab = ''

  if (pathname.includes('clients')) selectedTab = 'clients'
  else if (pathname.includes('staff')) selectedTab = 'staff'
  else if (pathname.includes('properties')) selectedTab = 'properties'
  else if (pathname.includes('offices')) selectedTab = 'offices'
  else if (pathname.includes('organizations')) selectedTab = 'organizations'
  else if (pathname.includes('programs')) selectedTab = 'programs'
  else if (pathname.includes('team')) selectedTab = 'team'
  else if (pathname.includes('admin-hr')) selectedTab = 'admin_hr'
  else if (pathname.includes('grievance')) selectedTab = 'grievance'

  const tabConfig = React.useMemo(() => {
    if (selectedTab === 'admin_hr') {
      return {
        ...TABS_CONFIG.admin_hr,
        permission: isBehave || isManagement,
      }
    }

    return TABS_CONFIG[selectedTab]
  }, [selectedTab, isBehave, isManagement])

  return (
    <>
      <Page
        feature="custom_note_templates"
        breakpoint={4}
        help={<HelpTagIframe id="settings_custom_note_templates" />}
        actions={
          tabConfig && (
            <>
              {isBehave && (
                <Button
                  as={Link}
                  label="Import Behave Templates"
                  type="default"
                  glyph="add"
                  link={`${pathname}/import`}
                  permission={tabConfig.permission}
                  featureFlagV2={tabConfig.feature_flag}
                />
              )}
              <Button
                as={Link}
                label={tabConfig.label}
                type="primary"
                glyph="add"
                link={`${pathname}/new`}
                permission={tabConfig.permission}
              />
            </>
          )
        }
      >
        <Tabs defaultTab="support_main_plan">
          <Tabs.List css={styles.tabsList}>
            <Tabs.Item as={NavLink} label="Dashboard" icon="dashboard" to={`dashboard`} />
            <Tabs.Item
              as={NavLink}
              label="Clients"
              icon="clients"
              to={`clients`}
              featureFlagV2="client_custom_note_templates"
              permission="settings.client_custom_note_templates.view"
            />
            <Tabs.Item
              as={NavLink}
              label="Staff"
              icon="employees"
              to={`staff`}
              featureFlagV2="staff_custom_note_templates"
              permission="settings.staff_custom_note_templates.view"
            />
            <Tabs.Item
              as={NavLink}
              label="Locations"
              icon="properties"
              to={`properties`}
              featureFlagV2="property_custom_note_templates"
              permission="settings.property_custom_note_templates.view"
            />
            <Tabs.Item
              as={NavLink}
              label="Organizations"
              icon="organizations"
              to={`organizations`}
              featureFlagV2="organization_custom_note_templates"
              permission="settings.organization_custom_note_templates.view"
            />
            <Tabs.Item
              as={NavLink}
              label="Programs"
              icon="programs"
              to={`programs`}
              featureFlagV2="program_custom_note_templates"
              permission="settings.program_custom_note_templates.view"
            />
            <Tabs.Item as={NavLink} label="Admin HR" icon="admin_hr_notes" to={`admin-hr`} permission={isBehave || isManagement} />
            <Tabs.Item
              as={NavLink}
              label="Grievance"
              icon="grievance_notes"
              to={`grievance`}
              permission="settings.grievance_custom_note_templates.view"
              featureFlagV2="grievance_note_templates"
            />
          </Tabs.List>
        </Tabs>

        <Routes>
          <Route index element={<Navigate to="dashboard" replace />} />

          <Route path={`dashboard/*`} element={<DashboardCustomNoteTemplates />} />
          <Route
            path={`clients/*`}
            element={
              <ProtectedRouteV6 featureFlagV2="client_custom_note_templates" permission="settings.client_custom_note_templates.view">
                <ClientCustomNoteTemplates />
              </ProtectedRouteV6>
            }
          />
          <Route
            path={`staff/*`}
            element={
              <ProtectedRouteV6 featureFlagV2="staff_custom_note_templates" permission="settings.staff_custom_note_templates.view">
                <StaffCustomNoteTemplates />
              </ProtectedRouteV6>
            }
          />
          <Route
            path={`properties/*`}
            element={
              <ProtectedRouteV6 featureFlagV2="property_custom_note_templates" permission="settings.property_custom_note_templates.view">
                <PropertyCustomNoteTemplates />
              </ProtectedRouteV6>
            }
          />
          <Route
            path={`organizations/*`}
            element={
              <ProtectedRouteV6
                featureFlagV2="organization_custom_note_templates"
                permission="settings.organization_custom_note_templates.view"
              >
                <OrganizationCustomNoteTemplates />
              </ProtectedRouteV6>
            }
          />
          <Route
            path={`programs/*`}
            element={
              <ProtectedRouteV6 featureFlagV2="program_custom_note_templates" permission="settings.program_custom_note_templates.view">
                <ProgramCustomNoteTemplates />
              </ProtectedRouteV6>
            }
          />

          <Route
            path="admin-hr/*"
            element={
              <ProtectedRouteV6 permission={isBehave || isManagement}>
                <AdminHRCustomNoteTemplates />
              </ProtectedRouteV6>
            }
          />

          <Route
            path="grievance/*"
            element={
              <ProtectedRouteV6 featureFlag="grievance_note_templates" permission="settings.grievance_custom_note_templates.view">
                <GrievanceCustomNoteTemplates />
              </ProtectedRouteV6>
            }
          />
        </Routes>
      </Page>

      <AnimatedRoutes>
        <Route path="clients/import" element={<ClientCustomNoteTemplatesImportOverlay useV6Router />} />
        <Route path="clients/:id" element={<ClientCustomNoteTemplateOverlayV6 useV6Router />} />

        <Route path="staff/import" element={<StaffCustomNoteTemplatesImportOverlay useV6Router />} />
        <Route path="staff/:id" element={<StaffCustomNoteTemplateOverlayV6 useV6Router />} />

        <Route path="properties/import" element={<PropertyCustomNoteTemplatesImportOverlay useV6Router />} />
        <Route path="properties/:id" element={<PropertyCustomNoteTemplateOverlayV6 useV6Router />} />

        <Route path="organizations/import" element={<OrganizationCustomNoteTemplatesImportOverlay useV6Router />} />

        <Route
          path="admin-hr/:id"
          element={
            <ProtectedRouteV6 permission={isBehave || isManagement}>
              <AdminHRCustomNoteTemplateOverlayV6 useV6Router />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="grievance/:id"
          element={
            <ProtectedRouteV6 permission="settings.grievance_custom_note_templates.create" featureFlag="grievance_notes">
              <GrievanceCustomNoteTemplateOverlayV6 useV6Router />
            </ProtectedRouteV6>
          }
        />

        <Route path="organizations/:id" element={<OrganizationCustomNoteTemplateOverlayV6 useV6Router />} />

        <Route path="programs/import" element={<ProgramCustomNoteTemplatesImportOverlay useV6Router />} />
        <Route path="programs/:id" element={<ProgramCustomNoteTemplateOverlayV6 useV6Router />} />

        <Route path="team/import" element={<TeamCustomNoteTemplatesImportOverlay useV6Router />} />
        <Route path="team/:id" element={<TeamCustomNoteTemplateOverlayV6 useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const styles = {
  tabsList: {
    marginTop: '-0.75rem',
    marginBottom: '1.25rem',
  },
}

export default withPageError(CustomNoteTemplates)
