import React from 'react'

import ContextShow from '../../../../Forms/ContextShow'
import FormSection from '../../../../Forms/FormSection'
import Input from '../../../../Forms/Input'
import Option from '../../../../Forms/Option'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Select from '../../../../Forms/Select'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import Type from '../../../../Typography/Type'
import YesNoRadioGroup from '../../../../Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupExtra from '../../../../Forms/elements/YesNoRadioGroupExtra'

import MentalStatusForm from './MentalStatusForm'

const MentalHealthCognitiveEmotionalBehavioral = () => {
  return (
    <>
      <FormSection layout="vertical">
        <RadioGroup
          model="data.bio_medical.has_ben_hospitalized_for_mental_illness"
          label="Has the client ever been hospitalized for mental illness?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.bio_medical.has_ben_hospitalized_for_mental_illness" is={true}>
          <SmartTextarea
            useDictation
            label="Please list the reason, duration and location of treatment:"
            model="data.bio_medical.has_ben_hospitalized_for_mental_illness_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_suffered_eating_disorder"
          label="Has the client ever suffered from an eating disorder?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_suffered_eating_disorder" is={true}>
          <SmartTextarea
            useDictation
            label="Please list dates and duration of symptoms:"
            model="data.cognitive_emotional_behavioral.has_suffered_eating_disorder_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_suffered_physical_abuse"
          label="Has the client ever been the victim of physical abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_suffered_physical_abuse" is={true}>
          <SmartTextarea
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.has_suffered_physical_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.is_victim_of_emotional_abuse"
          label="Has the client ever been the victim of emotional abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_emotional_abuse" is={true}>
          <SmartTextarea
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.is_victim_of_emotional_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.is_victim_of_verbal_abuse"
          label="Has the client ever been the victim of verbal abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_verbal_abuse" is={true}>
          <SmartTextarea
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.is_victim_of_verbal_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse"
          label="Has the client ever been the victim of sexual abuse?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse" is={true}>
          <SmartTextarea
            useDictation
            label="Please list age, duration and short description of abuse:"
            model="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <YesNoRadioGroup
          label="Has the client ever been a victim of exploitation?"
          model="data.cognitive_emotional_behavioral.is_victim_of_exploit"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_exploit" is={true}>
          <Select
            label="What type of abuse has the client been a victim of?"
            model="data.cognitive_emotional_behavioral.is_victim_of_exploit_type"
          >
            <Option label="Sexually" value="sexually" />
            <Option label="Drugs and alcohol" value="drugs_and_alcohol" />
            <Option label="Economic" value="economic" />
          </Select>

          <SmartTextarea useDictation label="Please explain:" model="data.cognitive_emotional_behavioral.is_victim_of_exploit_extra" />
        </ContextShow>

        <YesNoRadioGroupExtra
          label="Has the client ever been a part of an environmental trauma?"
          model="data.cognitive_emotional_behavioral.is_part_of_environmental_trauma"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever been a victim of domestic violence?"
          model="data.cognitive_emotional_behavioral.is_victim_of_domestic_violence"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever been a victim of neglect?"
          model="data.cognitive_emotional_behavioral.is_victim_of_neglect"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever experienced a traumatic death or loss?"
          model="data.cognitive_emotional_behavioral.has_experienced_traumatic_death_loss"
        />

        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_harmed_another_person"
          label="Has the client ever tried to harm another person?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_harmed_another_person" is={true}>
          <SmartTextarea
            useDictation
            label="Please explain:"
            model="data.cognitive_emotional_behavioral.has_harmed_another_person_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <YesNoRadioGroup
          label="Does the client have a history of causing harm to animals?"
          model="data.cognitive_emotional_behavioral.has_animals_harm_history"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.has_animals_harm_history" is={true}>
          <Input label="Last episode:" model="data.cognitive_emotional_behavioral.has_animals_harm_history_last_episode" />
          <SmartTextarea useDictation label="Please describe:" model="data.cognitive_emotional_behavioral.has_animals_harm_history_extra" />
        </ContextShow>

        <YesNoRadioGroup
          label="Does the client have a history of setting fires?"
          model="data.cognitive_emotional_behavioral.has_setting_fires_history"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.has_setting_fires_history" is={true}>
          <Input label="Last episode:" model="data.cognitive_emotional_behavioral.has_setting_fires_history_last_episode" />
          <SmartTextarea
            useDictation
            label="Please describe:"
            model="data.cognitive_emotional_behavioral.has_setting_fires_history_extra"
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_family_mental_illness_history"
          label="Is there a history of mental illness in the client's family?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_family_mental_illness_history" is={true}>
          <SmartTextarea
            useDictation
            label="Please list family member and explain:"
            model="data.cognitive_emotional_behavioral.has_family_mental_illness_history_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <YesNoRadioGroup
          label="Is there a history of substance abuse in the client's family?"
          model="data.cognitive_emotional_behavioral.has_family_substance_abuse_history"
        />

        <ContextShow when="data.cognitive_emotional_behavioral.has_family_substance_abuse_history" is={true}>
          <SmartTextarea
            useDictation
            label="Family Member(s)"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.family_members"
          />

          <YesNoRadioGroup
            label="Are they actively using now?"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.actively_using"
          />
          <SmartTextarea
            useDictation
            label="More Details"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.actively_using_extra"
          />

          <YesNoRadioGroup
            label="Are they in recovery?"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.in_recovery"
          />
          <SmartTextarea
            useDictation
            label="More Details"
            model="data.cognitive_emotional_behavioral.family_substance_abuse_history.in_recovery_extra"
          />
        </ContextShow>

        <RadioGroup
          model="data.cognitive_emotional_behavioral.has_family_suicide_history"
          label="Is there a history of suicide in the client's family?"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.cognitive_emotional_behavioral.has_family_suicide_history" is={true}>
          <SmartTextarea
            useDictation
            label="Please explain:"
            model="data.cognitive_emotional_behavioral.has_family_suicide_history_extra"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <YesNoRadioGroupExtra
          label="Has the client ever talked to a psychiatrist, psychologist, therapist, social worker, or counselor about an emotional problem?"
          model="data.cognitive_emotional_behavioral.discussed_emotional_problems_with_professional"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever felt they needed help with their emotional problems, or have people told them that they should get help for their emotional problems?"
          model="data.cognitive_emotional_behavioral.help_needed_or_suggested_emotional_problems"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever been advised to take medication for anxiety, depression, hearing voices, or for any other emotional problem?"
          model="data.cognitive_emotional_behavioral.advised_medication_for_emotional_issues"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever been seen in a psychiatric emergency room or been hospitalized for psychiatric reasons?"
          model="data.cognitive_emotional_behavioral.psychiatric_emergency_room_or_hospitalization"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever heard voices that no one else could hear or seen objects or things that others could not see?"
          model="data.cognitive_emotional_behavioral.heard_unexplained_voices_seen_objects"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever experienced being depressed for weeks at a time, lost interest or pleasure in most activities, had trouble concentrating and making decisions, or thought about killing themselves?"
          model="data.cognitive_emotional_behavioral.long_lasting_depression_suicidal_thoughts"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever attempted to kill themselves?"
          model="data.cognitive_emotional_behavioral.suicide_attempt_occurred"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever had nightmares or flashbacks as a result of being involved in a traumatic or terrible event? For example, warfare, gang fights, fire, domestic violence, rape, incest, car accident, being shot or stabbed?"
          model="data.cognitive_emotional_behavioral.experienced_nightmares_flashbacks_from_trauma"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever experienced any strong fears, such as fear of heights, insects, animals, dirt, attending social events, being in a crowd, being alone, or being in places where it may be hard to escape or get help?"
          model="data.cognitive_emotional_behavioral.strong_fears_specific_examples"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever given in to an aggressive urge or impulse, on more than one occasion, that resulted in serious harm to others or led to the destruction of property?"
          model="data.cognitive_emotional_behavioral.aggressive_urge_caused_harm_destruction"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever felt that people had something against them, without them necessarily saying so, or that someone or some group may be trying to influence their thoughts or behavior?"
          model="data.cognitive_emotional_behavioral.sensed_conspiracy_unspoken_grudge"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever experienced any emotional problems associated with their sexual interests, sexual activities, or choice of sexual partner?"
          model="data.cognitive_emotional_behavioral.emotional_problems_tied_to_sexuality"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Was there ever a period in the client's life when they spent a lot of time thinking and worrying about gaining weight, becoming fat, or controlling their eating? For example, by repeatedly dieting or fasting, engaging in excessive exercise to compensate for binge eating, taking enemas, or forcing themselves to throw up?"
          model="data.cognitive_emotional_behavioral.obsessing_over_weight_control_measures"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever had a period of time when they were so full of energy, their ideas came very rapidly, they talked nearly nonstop, moved quickly from one activity to another, needed little sleep, and believed they could do almost anything?"
          model="data.cognitive_emotional_behavioral.manic_high_energy_period_experiences"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever experienced spells or attacks when they suddenly felt anxious, frightened, or uneasy to the extent that they began sweating, their heart started beating rapidly, they were shaking or trembling, their stomach was upset, or they felt dizzy or unsteady, as if they would faint?"
          model="data.cognitive_emotional_behavioral.panic_attacks_anxiety_symptoms"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever had persistent, lasting thoughts or impulses to do something over and over that caused them considerable distress and interfered with normal routines, work, or social relations? Examples would include repeatedly counting things, checking and rechecking on things they had done, washing and rewashing their hands, praying, or maintaining a very rigid schedule of daily activities from which they could not deviate."
          model="data.cognitive_emotional_behavioral.compulsive_repetitive_actions_interference"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever lost considerable sums of money through gambling or had problems at work, in school, or with their family and friends as a result of their gambling?"
          model="data.cognitive_emotional_behavioral.gambling_issues_financial_social_impact"
          textareaLabel="More details"
        />

        <YesNoRadioGroupExtra
          label="Has the client ever been told by teachers, guidance counselors, or others that they have a special learning problem?"
          model="data.cognitive_emotional_behavioral.identified_special_learning_problem"
          textareaLabel="More details"
        />
      </FormSection>

      <Type as="h4" variant="H4" css={styles.heading}>
        Current Mental Status
      </Type>

      <FormSection>
        <MentalStatusForm />

        <SmartTextarea useDictation label="Notes" model="data.cognitive_emotional_behavioral.notes" />
      </FormSection>
    </>
  )
}

const styles = {
  heading: {
    margin: '2rem 0 1rem',
  },
}

export default MentalHealthCognitiveEmotionalBehavioral
