import React from 'react'
import size from 'lodash/size'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardTreeItem from '../../../components/CardTreeItem'
import Dropdown from '../../../components/Dropdown'
import DropdownItem from '../../../components/DropdownItem'
import Flex from '../../../components/Flex'
import Icon from '../../../components/Icon'
import State from '../../../components/State'

import { ReferralColumn } from '../components/ReferralColumn'
import ConsentFormTemplateSections from '../components/ConsentFormTemplateSections'

import { REFERRAL_CONSENT_FORMS } from '../mockData'

export const ConsentForms = (props: any) => {
  const { client, data, setData, timezone, setClient } = props

  const handleImport = (templateId: string) => {
    const newTemplate = REFERRAL_CONSENT_FORMS.find((o) => o.id === templateId)

    setData({ consent_forms: [...(data?.consent_forms || []), newTemplate] })
  }

  const handleDelete = (templateId: string) => {
    const newTemplates = data?.consent_forms?.filter((o: any) => o.id !== templateId)

    setData({ consent_forms: newTemplates })
  }

  const isEmpty = size(data?.consent_forms) === 0

  return (
    <ReferralColumn
      icon={props.icon}
      title={props.title}
      headerAfter={
        !isEmpty && (
          <Flex gap="0.5rem">
            <Button
              glyph="settings"
              size={200}
              type="minimal"
              color="text"
              label="Manage Consent Form Templates"
              link="/settings/referral-consent-form-templates"
              target="_blank"
            />

            <Dropdown glyph="add" label="Import Consent Form" buttonType="primary">
              {REFERRAL_CONSENT_FORMS.map((o) => (
                <DropdownItem icon="referrals" key={o.id} label={o.name} onClick={() => handleImport(o.id)} />
              ))}
            </Dropdown>
          </Flex>
        )
      }
    >
      {isEmpty ? (
        <Card>
          <State
            isEmpty
            title="Consent Forms"
            icon="checklist"
            emptyDescription="No consent forms have been added yet"
            emptyActions={
              <>
                <Dropdown glyph="add" label="Import Consent Form" buttonType="primary">
                  {REFERRAL_CONSENT_FORMS.map((o) => (
                    <DropdownItem icon="referrals" key={o.id} label={o.name} onClick={() => handleImport(o.id)} />
                  ))}
                </Dropdown>

                <Button
                  glyph="settings"
                  size={200}
                  type="minimal"
                  color="text"
                  label="Manage Consent Form Templates"
                  link="/settings/referral-consent-form-templates"
                  target="_blank"
                />
              </>
            }
          />
        </Card>
      ) : (
        <div className="grid gap-2">
          {data?.consent_forms?.map((o: any) => (
            <div key={o.id}>
              <CardTreeItem
                title={o.name}
                shouldIndent={false}
                titleBefore={<Icon icon="referrals" size={20} className="mr-1.5" />}
                after={
                  <Button
                    glyph="cross"
                    label="Delete"
                    type="minimal"
                    color="red"
                    size={100}
                    onClick={() => {
                      handleDelete(o.id)
                    }}
                  />
                }
              >
                <div>
                  <ConsentFormTemplateSections value={o.sections} />
                </div>
              </CardTreeItem>
            </div>
          ))}
        </div>
      )}
    </ReferralColumn>
  )
}
