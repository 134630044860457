import React from 'react'

import Divider from '../../components/Divider'
import Section from '../../components/Section'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import COWSSymptoms from '../../components/Overlays/pages/DataForms/common/COWSSymptoms'
import VitalSignsForm from '../../components/Overlays/pages/DataForms/common/VitalSignsForm'

const RootCowsOverlay = () => (
  <DataFormOverlay
    title="Clinical Opiate Withdrawal Scale"
    icon="nursing"
    category="nursing"
    subcategory="cows"
    requestClientSignature={false}
    requestAuthorSignature={true}
    requireSupervisor={true}
    enableTreatmentPlanSelector
  >
    <Section
      title="Vital Signs"
      scrollview={{
        id: 'vital_signs',
        name: 'Vital Signs',
      }}
      commentsModel="data.vital_signs"
    >
      <VitalSignsForm showDateTime={false} showPainScale={false} showComments={false} requireHeight />
    </Section>

    <Divider />

    <Section
      title="COWS Symptoms"
      scrollview={{
        id: 'cows_symptoms',
        name: 'COWS Symptoms',
      }}
      commentsModel="data.cows_symptoms"
    >
      <COWSSymptoms />
    </Section>
  </DataFormOverlay>
)

export const CowsOverlay = withOverlayError(RootCowsOverlay)
