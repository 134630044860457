import React from 'react'
import get from 'lodash/get'
import { withFormContext } from '../../../../Forms/context'

import Button from '../../../../Button'
import Flex from '../../../../Flex'
import Section from '../../../../Section'
import State from '../../../../State'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import FormSection from '../../../../Forms/FormSection'
import Input from '../../../../Forms/Input'
import Option from '../../../../Forms/Option'
import Select from '../../../../Forms/Select'
import SmartTextarea from '../../../../Forms/SmartTextarea'

import { COLORS } from '../../../../../theme'

class Diagnoses extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      diagnoses: get(props.form.getInitialModel(), `${props.model}.diagnoses`, []),
    }
  }

  /*
    CUSTOM
  */
  addDiagnosis = () => {
    let diagnoses = this.state.diagnoses

    diagnoses.push({
      status: 'inactive',
      name: '',
      created_at: Date.now(),
      created_by: '',
    })

    this.setState({ diagnoses: diagnoses })
  }

  removeDiagnosis = (idx) => {
    let diagnoses = this.state.diagnoses
    diagnoses.splice(idx, 1)
    this.setState({ diagnoses: diagnoses })
  }

  renderTrigger = () => {
    return (
      this.props.isEditable && <Button type="default" glyph="add" label="Add Diagnosis" onClick={this.addDiagnosis} display="inline-flex" />
    )
  }

  /*
    RENDER
  */
  render() {
    const { diagnoses } = this.state
    const { model, isEditable } = this.props

    const isEmpty = diagnoses.length === 0

    return (
      <State isEmpty={isEmpty} emptyDescription="No data found" emptyActions={this.renderTrigger()}>
        {diagnoses?.map((_problem, idx) => (
          <Section
            key={idx}
            title="Diagnosis"
            aside={
              isEditable && (
                <Button label="Remove" glyph="delete" type="minimal" color="red" size="small" onClick={() => this.removeDiagnosis(idx)} />
              )
            }
            css={styles}
          >
            <FormSection layout="vertical" maxWidth="100%">
              <Flex gap={8} stretchChildrenX alignItems="flex-end">
                <Select allowEmpty label="Approval Status" model={`${model}.diagnoses[${idx}].status`}>
                  <Option label="To Review" value="to_review" />
                  <Option label="Signed Off" value="signed_off" />
                </Select>

                <CheckboxGroup layout="horizontal">
                  <Checkbox label="Principal Diagnosis" model={`${model}.diagnoses[${idx}].principal`} />
                  <Checkbox label="Active Diagnosis" model={`${model}.diagnoses[${idx}].active`} />
                </CheckboxGroup>
              </Flex>

              <Flex gap={8} stretchChildrenX>
                <Input model={`${model}.diagnoses[${idx}].code`} label="Code" className="!max-w-[150px]" />
                <Input model={`${model}.diagnoses[${idx}].name`} label="Name" />
              </Flex>

              <SmartTextarea useDictation label="Notes" model={`${model}.diagnoses[${idx}].notes`} />
            </FormSection>
          </Section>
        ))}
        {this.renderTrigger()}
      </State>
    )
  }
}

Diagnoses.defaultProps = {
  isEditable: false,
  model: 'data',
}

const styles = {
  border: `2px solid ${COLORS.divider}`,
  borderRadius: 7,
  padding: '0.75rem',
}

export default withFormContext(Diagnoses)
