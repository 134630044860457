import React from 'react'

import PieChart from '../ChartTypes/PieChart'

export const ClientDrugsOfChoiceChart = (props: any) => {
  const data: any = [
    {
      name: 'Amphetamines',
      value: props.data?.amphetamines,
    },
    {
      name: 'Barbiturates',
      value: props.data?.barbiturates,
    },
    {
      name: 'Benzodiazepines',
      value: props.data?.benzodiazepines,
    },
    {
      name: 'Buprenorphine',
      value: props.data?.buprenorphine,
    },
    {
      name: 'Cocaine',
      value: props.data?.cocaine,
    },
    {
      name: 'Ecstasy (MDMA)',
      value: props.data?.ecstasy,
    },
    {
      name: 'Methadone',
      value: props.data?.methadone,
    },
    {
      name: 'Methamphetamine',
      value: props.data?.methamphetamine,
    },
    {
      name: 'Morphine',
      value: props.data?.morphine,
    },
    {
      name: 'Oxy',
      value: props.data?.oxy,
    },
    {
      name: 'PCP',
      value: props.data?.pcp,
    },
    {
      name: 'THC',
      value: props.data?.thc,
    },
    {
      name: 'Alcohol',
      value: props.data?.alcohol,
    },
    {
      name: 'Bath Salts',
      value: props.data?.bathsalts,
    },
    {
      name: 'K2',
      value: props.data?.k2,
    },
    {
      name: 'Kratom',
      value: props.data?.kratom,
    },
    {
      name: 'Heroin',
      value: props.data?.heroin,
    },
    {
      name: 'Opiates',
      value: props.data?.opiates,
    },
    {
      name: 'Ketamine',
      value: props.data?.ketamine,
    },
    {
      name: 'TCA',
      value: props.data?.tca,
    },
    {
      name: 'Fentanyl',
      value: props.data?.fentanyl,
    },
    {
      name: 'Nicotine',
      value: props.data?.nicotine,
    },
  ]

  return <PieChart {...props} data={data} />
}
