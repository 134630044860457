import React from 'react'
import { darken, tint, transparentize } from 'polished'
import { useMedia } from 'use-media'

import { BREAKPOINT, TOKENS, COLORS } from '../theme'

import Alert from './Alert'
import Button from './Button'
import Tooltip from './Tooltip'

type Props = {
  label: string
  onClick: Function
}

const AppNavItemSearch: React.FC<Props> = ({ label, onClick }) => {
  const isCompact = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavCompactBreakpoint] })
  const isExpanded = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavExpandBreakpoint] })

  const show = isCompact && !isExpanded

  return (
    <Tooltip position="right" className="!w-full !block" show={show} content={label}>
      <Button
        onClick={onClick}
        css={styles.search}
        label={label}
        glyph="search"
        glyphColor={COLORS.textMuted}
        glyphSize={20}
        hideLabel={show}
        after={
          <Alert css={styles.kbd} compact hide={!isExpanded}>
            /
          </Alert>
        }
      />
    </Tooltip>
  )
}

const styles: Record<string, any> = {
  search: {
    border: 0,

    justifyContent: 'flex-start',

    color: COLORS.text,
    fontWeight: 500,
    background: darken(0.02, COLORS.lightBackground),
    boxShadow: 'none',

    margin: '0 -0.5rem',
    marginBottom: '.8rem',

    '&:hover': {
      transform: 'none',
      color: COLORS.blue,
      background: tint(0.92, COLORS.blue),
      boxShadow: `
        inset 0 0 0 1px ${transparentize(0.75, COLORS.blue)}
      `,

      '& svg': {
        fill: `${COLORS.blue}`,
      },
    },

    '&.is-mobile': {
      display: 'none',
    },

    '& > *': {
      width: '100%',
    },
  },

  kbd: {
    padding: '0 0.5rem',
    margin: '0 !important',
    color: COLORS.gray,
    background: COLORS.divider,
    flex: '0 0 auto',
  },
}

export default AppNavItemSearch
