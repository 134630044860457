import React from 'react'
import clsx from 'clsx'

import Loader from './Loader'
import Flex from './Flex'

import { COLORS } from '../theme'

type Props = {
  loading?: boolean
  className?: string
  slideFrom: SLIDE_FROM.TOP | SLIDE_FROM.BOTTOM
}

const InfiniteScrollLoeader = ({ loading, className, slideFrom = SLIDE_FROM.BOTTOM, ...rest }: Props) => {
  const classNames = clsx({
    'is-loading': loading,
    [className]: className,
  })

  const slidesFromTop = slideFrom === SLIDE_FROM.TOP

  const loaderContainerDynamicStyles = {
    alignItems: slidesFromTop ? 'flex-start' : 'flex-end',
    '&.is-loading .loader-frame': {
      transform: `translateY(${slidesFromTop ? '50%' : '-50%'})`,
    },
  }

  const loaderFrameDynamicStyles = {
    transform: `translateY(${slidesFromTop ? '-125%' : '125%'})`,
  }

  return (
    <Flex css={[loaderContainer, loaderContainerDynamicStyles]} justifyContent="center" className={classNames}>
      <Flex css={[loaderFrameStyles, loaderFrameDynamicStyles]} className="loader-frame">
        <Loader color={COLORS.blue} size={20} speed={500} {...rest} />
      </Flex>
    </Flex>
  )
}

const loaderContainer = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 100,
  overflow: 'hidden',
  pointerEvents: 'none',
}

const loaderFrameStyles = {
  padding: '.75rem',
  background: COLORS.white,
  boxShadow: `
          0 0 0 1px ${COLORS.shadow},
          0 3px 0 1px ${COLORS.shadow}
        `,
  borderRadius: 100,
  transition: 'all .25s',
}

export const SLIDE_FROM = {
  TOP: 'top',
  BOTTOM: 'bottom',
}

export default InfiniteScrollLoeader
