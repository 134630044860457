import React from 'react'
import { transparentize } from 'polished'
import circle from '@turf/circle'
import MapGL, { Source, Layer, Image, Popup } from '@urbica/react-map-gl'
import size from 'lodash/size'

import { address as formatAddress, isDefined } from '../../utils/functions'
import { COLORS, SHADOW } from '../../theme'

import 'mapbox-gl/dist/mapbox-gl.css'

import { LOCATION_CATEGORIES } from './constants'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import CardSubtitle from '../../components/CardSubtitle'
import CardTitle from '../../components/CardTitle'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Status from '../../components/Status'

import markerLocation from '../../assets/img/map/location@2x.png'
import markerSelected from '../../assets/img/map/pin_selected@2x.png'
import { PROPERTY_CATEGORIES } from '../../utils/constants'

import { CommunityContext } from './context'

const THEMES = {
  default: 'mapbox://styles/mapbox/streets-v11',
  streets: 'mapbox://styles/mapbox/streets-v11',
  light: 'mapbox://styles/mapbox/light-v10',
}

export const dataToFeatures = (data: any) => {
  if (!data) return null

  return {
    type: 'FeatureCollection',
    features: data.map((record: any) => ({
      type: 'Feature',
      properties: {
        ...record,
        address: formatAddress(record?.address),
      },
      geometry: {
        type: 'Point',
        coordinates: [record.lon, record.lat],
      },
    })),
  }
}

export const objectToFeature = (obj: any) => {
  if (!isDefined(obj?.lat) || !isDefined(obj?.lon)) return null

  return {
    type: 'Feature',
    properties: {
      ...obj,
    },
    geometry: {
      type: 'Point',
      coordinates: [obj.lon, obj.lat],
    },
  }
}

export const CommunityMap = (props: any) => {
  const {
    circleCenter,
    className,
    data,
    getRef,
    locationCategories,
    onFeatureSelect,
    onPropertySelect,
    radius = 5,
    theme = 'streets',
  } = props

  const ref = React.useRef()

  const context = React.useContext(CommunityContext)

  const selectedFeature = context?.selectedFeature
  const setSelectedFeature = context?.setSelectedFeature

  const viewport = context?.viewport
  const setViewport = context?.setViewport

  const [radiusCircle, setRadiusCircle]: any = React.useState(null)
  const [radiusCenter, setRadiusCenter]: any = React.useState(null)
  const [selectedProperty, setSelectedProperty]: any = React.useState(null)
  const [hoverPopup, setHoverPopup]: any = React.useState(null)

  const onLayerClick = (event: any) => {
    event.originalEvent.preventDefault()

    const parsed = event.features[0]?.toJSON?.()

    if (parsed.source === 'properties') {
      setSelectedProperty(parsed)
    } else {
      setSelectedFeature(parsed)
    }
  }

  const onFeatureHover = (event: any) => {
    if (size(event.features) > 0) {
      const nextHoverId = event.features[0]?.properties?.entity_id

      if (!nextHoverId || nextHoverId === hoverPopup?.entity_id) return

      setHoverPopup(event.features[0]?.properties)
    }
  }

  const onFeatureLeave = (event: any) => {
    if (hoverPopup) setHoverPopup(null)
    onLayerLeave()
  }

  const onLayerEnter = (event: any) => {
    ref.current.getMap().getCanvas().style.cursor = 'pointer'
  }

  const onLayerLeave = () => {
    ref.current.getMap().getCanvas().style.cursor = ''
  }

  React.useEffect(() => {
    onFeatureSelect?.(selectedFeature)
  }, [selectedFeature])

  React.useEffect(() => {
    if (getRef) getRef(ref)
  }, [ref?.current])

  // update radius circle on map
  React.useEffect(() => {
    if (!circleCenter) {
      setRadiusCircle(null)
      setRadiusCenter(null)

      return
    }

    const turfCircle: any = circle([circleCenter.lon, circleCenter.lat], radius, {
      steps: 200,
      units: 'miles',
    })

    setRadiusCircle(turfCircle)

    setRadiusCenter({
      type: 'FeatureCollection',
      features: [
        {
          id: 'circle-center',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [circleCenter.lon, circleCenter.lat],
          },
        },
      ],
    })
  }, [circleCenter?.lat, circleCenter?.lon, radius])

  return (
    <>
      <MapGL
        antialias
        width="100%"
        height="100%"
        ref={ref}
        mapStyle={THEMES[theme] || THEMES.default}
        accessToken={process.env.BH_MAPBOX}
        onViewportChange={setViewport}
        viewportChangeMethod="flyTo"
        viewportChangeOptions={{ duration: 1000 }}
        attributionControl={false}
        className={className}
        css={STYLES.mapbox}
        {...viewport}
      >
        {/* radius circle */}
        <Source id="radius-circle" type="geojson" data={radiusCircle} />
        <Layer
          minzoom={0}
          type="fill"
          id="radius-circle-layer"
          source="radius-circle"
          paint={{
            'fill-color': transparentize(0.6, '#BEEAFF'),
            'fill-opacity': 1,
            'fill-outline-color': '#48C5FF',
          }}
          layout={{
            visibility: radiusCenter ? 'visible' : 'none',
          }}
        />

        {/* {selectedFeature && (
          <>
            <Source id="pin" type="geojson" data={selectedFeature} />
            <Layer
              minzoom={0}
              type="fill"
              id="pin"
              source="pin"
              paint={{
                'fill-color': transparentize(0.5, '#BEEAFF'),
                'fill-opacity': 1,
                'fill-outline-color': '#48C5FF',
              }}
              layout={{
                visibility: radiusCenter ? 'visible' : 'none',
              }}
            />
          </>
        )} */}

        {/* With Image */}
        {/* {locationCategories &&
          Object.keys(locationCategories).map((category) => {
            const categoryData = data?.[category]
            const categoryConfig = LOCATION_CATEGORIES[category]
            const isVisible = locationCategories?.[category]

            if (!categoryData || !categoryConfig) return null

            return (
              <>
                <Image id={category} image={markerLocation} pixelRatio={2} />

                <Source id={category} type="geojson" data={categoryData} />

                <Layer
                  minzoom={0}
                  type="symbol"
                  id={category}
                  source={category}
                  onClick={onLayerClick}
                  onEnter={onLayerEnter}
                  onLeave={onLayerLeave}
                  layout={{
                    'icon-image': category,
                    'icon-allow-overlap': true,
                    visibility: isVisible ? 'visible' : 'none',
                  }}
                />
              </>
            )
          })} */}

        {locationCategories &&
          Object.keys(locationCategories).map((category) => {
            const categoryData = data?.[category]
            const categoryConfig = LOCATION_CATEGORIES[category]
            const isVisible = locationCategories?.[category]

            if (!categoryData || !categoryConfig) return null

            return (
              <React.Fragment key={category}>
                <Source id={category} type="geojson" data={categoryData} />
                <Layer
                  minzoom={0}
                  type="circle"
                  id={`layer-${category}`}
                  source={category}
                  onClick={onLayerClick}
                  onEnter={onLayerEnter}
                  onLeave={onFeatureLeave}
                  onHover={onFeatureHover}
                  paint={{
                    'circle-radius': 5,
                    'circle-opacity': 1,
                    'circle-stroke-width': 1,
                    'circle-stroke-color': categoryConfig.border,
                    'circle-stroke-opacity': 0.5,
                    'circle-color': categoryConfig.background,
                  }}
                  layout={{
                    visibility: isVisible ? 'visible' : 'none',
                  }}
                />
              </React.Fragment>
            )
          })}

        {hoverPopup && (
          <Popup anchor="bottom" maxWidth="280px" latitude={hoverPopup.lat} longitude={hoverPopup.lon} closeButton={false}>
            <div css={STYLES.hoverPopupName}>{hoverPopup.entity_name}</div>
          </Popup>
        )}

        {data?.your_locations && (
          <>
            <Image id="properties" image={markerLocation} pixelRatio={2} />

            <Source id="properties" type="geojson" data={data.your_locations} />

            <Layer
              minzoom={0}
              type="symbol"
              id="properties"
              source="properties"
              onEnter={onLayerEnter}
              onLeave={onLayerLeave}
              onClick={onLayerClick}
              layout={{
                'icon-image': `properties`,
                'icon-allow-overlap': true,
              }}
            />
          </>
        )}

        {/* selected feature pin */}
        {selectedFeature && (
          <>
            <Image id={`pin`} image={markerSelected} pixelRatio={2} />

            <Source id={`pin`} type="geojson" data={{ type: 'FeatureCollection', features: [selectedFeature] }} />

            <Layer
              minzoom={0}
              type="symbol"
              id={`pin`}
              source={`pin`}
              onClick={onLayerClick}
              onEnter={onLayerEnter}
              onLeave={onLayerLeave}
              layout={{
                'icon-image': 'pin',
                'icon-allow-overlap': true,
                // visibility: selectedFeature ? 'visible' : 'none',
              }}
            />
          </>
        )}

        {isDefined(selectedProperty?.properties) && (
          <Popup
            anchor="top"
            maxWidth="280px"
            latitude={selectedProperty.properties.lat}
            longitude={selectedProperty.properties.lon}
            onClose={() => setSelectedProperty(null)}
          >
            <Grid gap="0.75rem">
              {selectedProperty.properties.category && (
                <Status
                  color="blue"
                  label={
                    selectedProperty.properties.category === 'housing'
                      ? `${selectedProperty.properties.gender} Property`
                      : PROPERTY_CATEGORIES[selectedProperty.properties.category]
                  }
                />
              )}

              <Flex nowrap gap="0.5rem">
                <Avatar src={selectedProperty.properties.avatar} initials={selectedProperty.properties.name} />
                <div>
                  <CardTitle title={selectedProperty.properties.name} css={STYLES.popupTitle} />
                  {selectedProperty.properties.address && (
                    <CardSubtitle subtitle={formatAddress(JSON.parse(selectedProperty.properties.address))} css={STYLES.popupDescription} />
                  )}
                </div>
              </Flex>

              <Button
                label="Search Around Location"
                color="blue"
                type="default"
                glyph="map"
                size={200}
                onClick={() => {
                  onPropertySelect?.(selectedProperty.properties)
                  setSelectedProperty(null)
                }}
              />
            </Grid>
          </Popup>
        )}

        {/* <div style={{ position: 'absolute', right: 40, top: 10 }}>
          <NavigationControl showCompass={false} />
        </div> */}
      </MapGL>
    </>
  )
}

const STYLES = {
  popupTitle: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },

  popupDescription: {
    fontSize: '0.95rem',
  },

  hoverPopupName: {
    fontSize: '1rem',
  },

  // mapbox styles
  mapbox: {
    '.mapboxgl-popup-content': {
      borderRadius: 4,
      boxShadow: SHADOW(5, transparentize(0.88, COLORS.black)),
      padding: '0.75rem 1rem',
    },

    '.mapboxgl-popup-close-button': {
      width: 28,
      height: 28,
      outline: 'none',
      fontSize: '1.2rem',

      '&:hover': {
        color: COLORS.red,
        background: 'transparent',
      },
    },
  },
}
