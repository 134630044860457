import React from 'react'

import { address, titleCase } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Chotomate from '../../components/Chotomate'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Input from '../../components/Forms/Input'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Status from '../../components/Status'
import Timeline from '../../components/Timeline/Timeline'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'

import ConsentFormTemplateSections from './components/ConsentFormTemplateSections'
import { REFERRAL_CONSENT_FORMS } from './mockData'

const RootReferralConsentFormTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    // data,
    deleteRecord,
    edit,
    form,
    id,
    // initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    // save,
  } = useOverlay({
    name: 'referral-consent-form-templates',
    endpoint: '/referral_consent_form_templates',
    invalidate: 'referral_consent_form_templates',
    options: props,
  })

  const data = REFERRAL_CONSENT_FORMS.find((item) => item.id === id)

  const { tenant, timezone } = useSettings()

  const save = () => {
    isNew ? close() : cancel()
  }

  // if (isOverlayLoading) {
  //   return <OverlayLoader position="right" />
  // }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} maxWidth={90}>
      <Overlay.Header icon="referrals" title="Referral Consent Form Template" />

      <Overlay.Content>
        <Form getForm={form} initialModel={data} isEditable={isEditable} timezone={timezone} onValidationUpdate={onValidationUpdate}>
          <Section title="Template Details">
            <FormSection maxWidth="100%">
              <Input
                autoFocus
                label="Template Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a template name',
                  },
                }}
              />

              {!isEditable && <ObjectSelector model="author" label="Created by" type="employees" isEditable={false} icon="employees" />}

              {!isEditable && <DateTimeInput model="created_at" label="Created At" />}

              {!isEditable && data?.updated_at !== data?.created_at && <DateTimeInput model="updated_at" label="Last Updated" />}
            </FormSection>
          </Section>

          <Divider />

          <ConsentFormTemplateSections model="sections" />

          <Section title="Signature Settings">
            <CheckboxGroup layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
              <Checkbox model="client_signature_required" label="Client Signature Required" />
              <Checkbox model="sender_signature_required" label="Sender Signature Required" />
              <Checkbox model="receiver_signature_required" label="Receiver Signature Required" />
            </CheckboxGroup>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Referral Consent Form Template"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
            />

            <DeleteDialog
              title="Delete Referral Consent Form Template?"
              message="Are you sure you want to delete this template? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ReferralConsentFormTemplateOverlay = withOverlayError(RootReferralConsentFormTemplateOverlay)
