import React from 'react'
import { opacify, transparentize, tint } from 'polished'
import clsx from 'clsx'
import intersection from 'lodash/intersection'
import size from 'lodash/size'

import { COLORS } from '../../theme'

import Card from '../Card'
import Flex from '../Flex'
import Grid from '../Grid'
import Icon from '../Icon'
import Status from '../Status'
import Tooltip from '../Tooltip'
import Type from '../Typography/Type'
import SubscriptionStatus from '../../constructs/Account/SubscriptionStatus'

import { beautifulAmount } from '../../utils/functions'
import { ALL_FEATURES, PRICING_UNIT_TYPES } from './constants'

import {
  GENERAL_FEATURES_KEYS,
  ERP_FEATURES_KEYS,
  CONTACTS_FEATURES_KEYS,
  CHAT_FEATURES_KEYS,
  CARE_COORDINATION_FEATURES_KEYS,
  RCM_FEATURES_KEYS,
  INSURANCE_FEATURES_KEYS,
  NOTES_FEATURES_KEYS,
  MEDICAL_FEATURES_KEYS,
  CLINICAL_FEATURES_KEYS,
  SECURITY_FEATURES_KEYS,
} from './constants'

type Props = {
  actions?: any
  featuresOverlay?: any
  featuresActions?: any
  isActive?: boolean
  isComingSoon?: boolean
  isTrialing?: boolean
  product: any
  showFeatures?: boolean
  title: string
  beforeActions?: React.ReactNode
}

const getFeatureKeys = (features: any) => {
  const res: any = []

  for (const key in features) {
    if (features[key]) res.push(key)
  }

  return res
}

const FeatureSection = ({ featureKeys, sectionKeys, title }: any) => {
  const intersectionKeys = intersection(sectionKeys, featureKeys)

  if (size(intersectionKeys) === 0) return null

  return (
    <>
      <Grid gap="0.75rem" columns="100%" css={styles.featuresGrid}>
        <Type as="div" variant="CAPS_TITLE_SMALL">
          {title}
        </Type>

        {intersectionKeys.map((featureKey: any) => (
          <Flex centerY nowrap key={featureKey}>
            <Icon icon={ALL_FEATURES[featureKey].icon} size={20} css={styles.featureIcon} />
            <Type as="div" variant="TEXT" css={styles.featureTitle}>
              {ALL_FEATURES[featureKey]?.title}
            </Type>
            <Tooltip content={ALL_FEATURES[featureKey]?.title} css={styles.infoTooltip} />
          </Flex>
        ))}
      </Grid>
    </>
  )
}

const ProductCard: React.FC<Props> = (props) => {
  const {
    actions,
    featuresOverlay,
    isActive,
    isComingSoon,
    isDiscounted,
    isRequired,
    isRecommended,
    isTrialing,
    product,
    showFeatures = true,
    featuresActions,
    beforeActions,
  } = props

  if (!product) return null

  const price = product.pricing_type !== 'free' ? parseFloat(product.price) : 'free'

  // discounts
  let discountedPrice = null
  let discountedLabel = 'Discounted'
  const useIsDiscounted = isDiscounted || product.discounted_price !== null
  if (useIsDiscounted) {
    discountedPrice = product.discounted_price
    if (product?.discounts?.length > 0) {
      discountedLabel = `Discounted: ${product.discounts.map((o: any) => o.name).join(', ')}`
    }
  }

  let priceSuffix = ''
  if (product.pricing_type === 'monthly') {
    priceSuffix = '/ Month'
  } else if (product.pricing_type === 'metered_monthly') {
    priceSuffix = PRICING_UNIT_TYPES[product.price_unit_type]
  }

  const featureKeys = getFeatureKeys(product.features)

  const classNames = clsx({
    'is-active': isActive,
    'is-trialing': isTrialing,
    'is-discounted': useIsDiscounted,
  })

  return (
    <Card className={classNames} css={styles.card}>
      <div css={styles.header}>
        {product.icon && (
          <div css={styles.circleIcon}>
            <Icon icon={product.icon} size={24} />
          </div>
        )}

        <Type as="h1" variant="CAPS_TITLE" css={styles.title} className={classNames}>
          {product.public_name}
        </Type>

        <Grid gap="1rem" css={styles.tags}>
          {isComingSoon && <div css={styles.comingSoon}>Coming Soon</div>}
          {isRequired && <div css={styles.required}>Required</div>}
          {isRecommended && <div css={styles.recommended}>Recommended</div>}
        </Grid>

        {useIsDiscounted && <Status useTint={false} color="green" label={discountedLabel} css={styles.discountedStatus} />}
      </div>

      <div css={styles.price}>
        <Type as="span" variant="H3" css={styles.amount} className={classNames}>
          {price === 'free' ? 'Free' : beautifulAmount(price)}
        </Type>

        {useIsDiscounted && (
          <Type as="span" variant="H3" css={styles.discountedAmount} className={classNames}>
            {discountedPrice === 'free' ? 'Free' : beautifulAmount(discountedPrice)}
          </Type>
        )}

        {priceSuffix && (
          <Type as="span" variant="TEXT_SMALL" color="textMuted">
            {' '}
            {priceSuffix}
          </Type>
        )}
      </div>

      {/* {product.description && (
        <Type as="h1" variant="TEXT_SMALL">
          <Markup value={product.description} />
        </Type>
      )} */}

      {(isActive || isTrialing) && (
        <Flex justifyContent="center">
          <SubscriptionStatus status={isActive ? 'active' : 'trialing'} css={styles.status} />
        </Flex>
      )}

      {showFeatures && (
        <>
          <FeatureSection title="General" sectionKeys={GENERAL_FEATURES_KEYS} featureKeys={featureKeys} />
          <FeatureSection title="ERP" sectionKeys={ERP_FEATURES_KEYS} featureKeys={featureKeys} />
          <FeatureSection title="Contacts" sectionKeys={CONTACTS_FEATURES_KEYS} featureKeys={featureKeys} />
          <FeatureSection title="Chat" sectionKeys={CHAT_FEATURES_KEYS} featureKeys={featureKeys} />
          <FeatureSection title="Care Coordination" sectionKeys={CARE_COORDINATION_FEATURES_KEYS} featureKeys={featureKeys} />
          <FeatureSection title="RCM" sectionKeys={RCM_FEATURES_KEYS} featureKeys={featureKeys} />
          <FeatureSection title="Insurance" sectionKeys={INSURANCE_FEATURES_KEYS} featureKeys={featureKeys} />
          <FeatureSection title="Notes" sectionKeys={NOTES_FEATURES_KEYS} featureKeys={featureKeys} />
          <FeatureSection title="Medical" sectionKeys={MEDICAL_FEATURES_KEYS} featureKeys={featureKeys} />
          <FeatureSection title="Clinical" sectionKeys={CLINICAL_FEATURES_KEYS} featureKeys={featureKeys} />
          <FeatureSection title="Security" sectionKeys={SECURITY_FEATURES_KEYS} featureKeys={featureKeys} />
        </>
      )}

      {beforeActions && <div>{beforeActions}</div>}

      <div css={styles.bottomActions}>
        {featuresActions && (
          <Flex stretchChildrenX gap="0.5rem" css={{ marginBottom: '0.4rem !important' }}>
            {featuresActions}
          </Flex>
        )}

        {actions && !isActive && (
          <Flex gap="0.5rem" css={styles.actions}>
            {actions}
          </Flex>
        )}
      </div>
    </Card>
  )
}

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1.5rem 1.5rem',
    marginTop: '1.75rem',
    overflow: 'visible',
    position: 'relative',

    '&.is-trialing': {
      border: '1px solid #E6CA66',
      boxShadow: '0 0 0 3px #F1EED5',
    },

    '&.is-active': {
      border: '1px solid #76C578',
      boxShadow: '0 0 0 3px #D4E9D9',
    },
  },

  circleIcon: {
    width: '3rem',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: COLORS.white,
    borderRadius: 500,

    marginTop: '-1.5rem',
    marginBottom: '0.5rem',

    boxShadow: `
      0 0 0 1px ${opacify(-0.05, COLORS.divider)},
      0 3px 0 1px ${opacify(-0.07, COLORS.divider)}
    `,
  },

  tags: {
    display: 'flex',

    position: 'absolute',
    left: '5.4rem',
    top: '-0.9rem',
  },

  comingSoon: {
    height: '1.8rem',
    background: tint(0.6, COLORS.gold),
    border: `1px solid ${COLORS.gold}`,
    padding: '0.1rem 0.75rem',
    borderRadius: 100,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    lineHeight: 'normal',
    fontWeight: 600,
    fontSize: '0.8rem',
    verticalAlign: 'middle',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },

  recommended: {
    height: '1.8rem',

    color: COLORS.green,
    background: tint(0.6, COLORS.white),
    border: `1px solid ${transparentize(0.6, COLORS.green)}`,
    padding: '0.1rem 0.75rem',
    borderRadius: 100,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    lineHeight: 'normal',
    fontWeight: 600,
    fontSize: '0.8rem',
    verticalAlign: 'middle',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },

  required: {
    height: '1.8rem',
    background: tint(0.6, COLORS.gold),
    border: `1px solid ${COLORS.gold}`,
    padding: '0.1rem 0.75rem',
    borderRadius: 100,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    lineHeight: 'normal',
    fontWeight: 600,
    fontSize: '0.8rem',
    verticalAlign: 'middle',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },

  header: {
    marginBottom: '0.5rem',
  },

  price: {
    marginBottom: '0.5rem',
  },

  discountedPrice: {
    marginBottom: '1rem',
  },

  amount: {
    textTransform: 'uppercase',

    '&.is-active': {
      color: COLORS.green,
    },

    '&.is-discounted': {
      textDecoration: 'line-through',
      fontSize: '1.5rem',
    },

    '&.is-active.is-discounted': {
      color: COLORS.text,
    },
  },

  discountedAmount: {
    textTransform: 'uppercase',
    display: 'inline-block',
    marginLeft: '0.6rem',
    color: COLORS.green,
  },

  discountedStatus: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: '0.25rem 0.35rem',
  },

  title: {
    marginTop: '1rem',
    fontSize: '1.1rem',
    whiteSpace: 'pre-wrap',
    paddingRight: 90,

    '&.is-active': {
      color: COLORS.green,
    },
  },

  description: {
    marginTop: '0.75rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },

  featuresGrid: {
    marginTop: '2rem',
  },

  featureTitle: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  featureIcon: {
    marginRight: '0.25rem',
  },

  infoTooltip: {
    marginLeft: 'auto',
  },

  actions: {
    marginTop: '1.5rem',
  },

  bottomActions: {
    paddingTop: '0.5rem',
    marginTop: 'auto',
  },

  status: {
    fontSize: '0.9rem',
    borderRadius: 100,
    padding: '0.2em 0.6em',
  },
}

export default ProductCard
