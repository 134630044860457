import React from 'react'
import autoAnimate from '@formkit/auto-animate'
import clsx from 'clsx'

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'

import Glyph from './Glyph'
import Portal from './Portal'

const defaultGetId = (item: any) => item.id
const defaultRenderItem = () => null

export const DragAndDrop = (props: any) => {
  const { items, onUpdate, getId = defaultGetId, renderItem = defaultRenderItem, renderOverlay, isDisabled } = props

  const [draggingId, setDraggingId] = React.useState<string | null>(null)

  const { draggingItem, draggingIndex } = React.useMemo(() => {
    const result = { draggingItem: null, draggingIndex: -1 }

    if (!items || !draggingId) return result

    result.draggingIndex = items.findIndex((item) => getId(item) === draggingId)
    result.draggingItem = items[result.draggingIndex]

    return result
  }, [items, draggingId])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const animationRef = React.useRef(null)

  React.useEffect(() => {
    animationRef.current && autoAnimate(animationRef.current)
  }, [animationRef])

  if (!items) return null

  function handleDragStart(event) {
    const { active } = event

    if (!active?.id) return

    setDraggingId(active.id)
  }

  function handleDragOver(event) {}

  function handleDragEnd(event) {
    const { active, over } = event

    if (!onUpdate || !active?.data?.current || !over?.data?.current) return

    const { index: activeIndex } = active.data.current
    const { index: overIndex } = over.data.current

    const nextIndex = overIndex === 0 ? 0 : activeIndex < overIndex ? overIndex - 1 : overIndex

    const newItems = arrayMove(items, activeIndex, nextIndex)

    onUpdate(newItems)
    setDraggingId(null)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <DroppableItem index={0} />

      <div ref={animationRef}>
        {items.map((item, index) => {
          const id = getId(item)

          return (
            <React.Fragment key={id}>
              <DraggableItem id={id} index={index} item={item} renderItem={renderItem} isDisabled={isDisabled} />
              <DroppableItem index={index + 1} />
            </React.Fragment>
          )
        })}
      </div>

      <Portal type="radix">
        <DragOverlay>
          {draggingItem && (
            <DraggableItem isDisabled id={draggingId} item={draggingItem} renderItem={renderOverlay || renderItem} index={draggingIndex} />
          )}
        </DragOverlay>
      </Portal>
    </DndContext>
  )
}

export function DraggableItem(props: any) {
  const { id, index, isDisabled, item, renderItem } = props

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, isDragging } = useDraggable({
    id,
    data: { type: 'DRAGGABLE', index },
    disabled: isDisabled,
  })

  const rootClasses = clsx('DRAGGABLE', isDisabled && '!cursor-auto', isDragging && 'opacity-60')

  return (
    <div ref={setNodeRef} className={rootClasses} {...attributes}>
      {renderItem?.({
        item,
        index,
        dragElement: (
          <div
            ref={setActivatorNodeRef}
            {...listeners}
            className={clsx(
              'flex-[0_0_auto] w-7 h-7 rounded-md flex self-stretch items-center justify-center',
              isDisabled ? '!cursor-auto' : '!cursor-move hover:bg-hover',
            )}
          >
            {!isDisabled && <Glyph glyph="drag_and_drop" size={14} />}
          </div>
        ),
        renderDragElement: (dragProps = {}) => {
          const { children, className } = dragProps

          return (
            <div
              ref={setActivatorNodeRef}
              {...listeners}
              className={clsx(
                'flex-[0_0_auto] min-h-7 rounded-md flex self-stretch items-center justify-center',
                isDisabled ? '!cursor-auto' : '!cursor-move hover:bg-hover',
                className,
              )}
            >
              {!isDisabled && <Glyph glyph="drag_and_drop" size={14} />}

              {children}
            </div>
          )
        },
      })}
    </div>
  )
}

export function DroppableItem(props: any) {
  const { index, isDisabled } = props

  const { setNodeRef, isOver } = useDroppable({
    id: `droppable-${index}`,
    data: { type: 'DROPPABLE', index },
    disabled: isDisabled,
  })

  const rootClasses = clsx(
    'h-0 w-full',
    !isOver && 'bg-divider',
    isOver && 'bg-blue-500 !opacity-100 border-t border-b border-vivid-blue-400 shadow-[0_0_0_1px] shadow-blue-300',
  )

  return <div ref={setNodeRef} className={rootClasses}></div>
}
