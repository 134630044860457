import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'

import { css, COLORS } from '../../theme'
import { DT } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import Link from '../Link'

import { RoadmapContext } from './context'

const TRIM_OFFSETS: any = {
  day: { minutes: 10 },
  week: { hours: 2.5 },
  month: { hours: 8 },
  trimester: { hours: 8 },
}

const RoadmapBar = React.forwardRef((props: any, ref: any) => {
  const {
    asGradient,
    background = tint(0.7, COLORS.blue),
    children,
    className,
    endDate,
    minWidth,
    onBlur,
    onClick,
    onFocus,
    onPointerEnter,
    onPointerLeave,
    onTouchStart,
    startDate,
    to,
    overflow,
  } = props

  const Tag = !!to ? Link : 'div'

  const { timezone } = useSettings()
  const context: any = React.useContext(RoadmapContext)
  const { period, minutesInView }: any = context

  const roadmapStartDate = context.startDate
  const roadmapEndDate = context.endDate.endOf('day')

  const rootClasses = clsx(STYLES.root().className, className)
  const contentClasses = clsx(STYLES.barContent().className, overflow === 'hidden' && 'overflow-hidden')

  let startDT = startDate && DT(startDate, timezone)
  let endDT = endDate && DT(endDate, timezone)

  // event starts after roadmap end date
  if (startDT && startDT.toSeconds() > roadmapEndDate.toSeconds()) return null

  // event ends before roadmap start date
  if (endDT && endDT.toSeconds() < roadmapStartDate.toSeconds()) return null

  // event has no end date and starts before roadmap start date
  if (!endDate && startDT && startDT.toSeconds() < roadmapStartDate.toSeconds()) return null

  const trimOffset = TRIM_OFFSETS[period]

  const shouldTrimStart = startDT && startDT.diff(roadmapStartDate, 'minutes').toObject().minutes < 0
  const shouldTrimEnd = endDT && endDT.diff(roadmapEndDate, 'minutes').toObject().minutes > 0

  if (shouldTrimStart && trimOffset) startDT = roadmapStartDate.minus(trimOffset)
  if (shouldTrimEnd && trimOffset) endDT = roadmapEndDate.plus(trimOffset)

  let duration = endDT ? endDT.diff(startDT, 'minutes').toObject().minutes : 0
  let durationFromStart = startDT ? startDT.diff(roadmapStartDate, 'minutes').toObject().minutes : 0

  const durationBasedWidth = (duration / minutesInView) * 100 + '%'
  const durationBasedOffset = (durationFromStart / minutesInView) * 100 + '%'

  const offset = props.offset || durationBasedOffset || 0
  const width = props.width || durationBasedWidth || 0

  const style = {
    left: offset,
    width: width,
    minWidth: minWidth,
    background: asGradient ? `linear-gradient(90deg, ${background}, transparent)` : background,
    border: asGradient ? 'none' : '1px solid white',
  }

  return (
    <Tag
      ref={ref}
      className={rootClasses}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onTouchStart={onTouchStart}
      style={style}
      to={to}
    >
      {children && <div className={contentClasses}>{children}</div>}
    </Tag>
  )
})

const STYLES = {
  root: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginLeft: 1,
    marginRight: 1,
    textAlign: 'center',
    borderRadius: 3,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '80%',
    maxHeight: 32,
  }),

  barContent: css({
    paddingLeft: '0.25rem',
    fontSize: '0.9rem',
    fontWeight: 600,

    '&.overflow-hidden': {
      overflow: 'hidden',
    },
  }),
}

export default RoadmapBar
