import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useSettings } from '@behavehealth/hooks/useSettings'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const PAYMENT_METHODS = {
  check: 'Check',
  ach: 'Bank Transfer (ACH)',
  other: 'Other',
}

const Payments = () => {
  const match = useRouteMatch()
  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Payment ID',
        model: 'identifier',
        formatValue: ({ data, value }: any) => <MainCell testKey="main_cell" id={data.id} value={value} to={`${match.url}/${data.id}`} />,
      },
      {
        title: 'Payment Reference #',
        model: 'reference',
      },
      {
        title: 'Payment Method',
        model: 'payment_method',
        formatValue: ({ value }: any) => PAYMENT_METHODS[value] || '–',
      },
      {
        title: 'Payment Date',
        model: 'payed_at',
        type: 'date',
      },
      {
        title: 'Posted Date',
        model: 'posted_at',
        type: 'date_time',
      },
      {
        title: 'Payment Amount',
        model: 'amount',
        type: 'amount',
        width: 180,
      },
      {
        title: 'Total Billed',
        model: 'billed_amount',
        type: 'amount',
        width: 180,
      },
      {
        title: 'Total Adjustment',
        model: 'adjustment_amount',
        type: 'amount',
        width: 180,
      },
      {
        title: 'Total Allowed Amount',
        model: 'allowed_amount',
        type: 'amount',
        width: 180,
      },
      {
        title: 'Total Balance Due Client',
        model: 'balance_client_due_amount',
        type: 'amount',
        width: 180,
      },
      {
        title: 'Total Client Respons.',
        model: 'client_responsibility_amount',
        type: 'amount',
        width: 180,
      },
      {
        title: 'Total Paid Amount',
        model: 'paid_amount',
        type: 'amount',
        width: 180,
      },
      {
        title: 'Created At',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Created By',
        model: 'author',
        type: 'profile',
      },
      {
        title: 'Last Edited By',
        model: 'last_edited_by',
        type: 'profile',
      },
    ],
    [match.url, timezone],
  )

  const tableProps = useDataTable({
    name: ['insurance_new_payments'],
    endpoint: '/insurance_new_payments',
    localStorageKey: 'insurance_new_payments_v1',
  })

  return <DataTable {...tableProps} asCard title="Payments" icon="accounting" columns={columns} filtersConfig={FILTERS_CONFIG} />
}

const FILTERS_CONFIG = {
  identifier: {
    label: 'Payment ID',
    type: 'string',
  },
  reference: {
    label: 'Payment Reference #',
    type: 'string',
  },
  payment_method: {
    label: 'Payment Method',
    type: 'multi_select',
    options: Object.entries(PAYMENT_METHODS).map(([value, label]) => ({ value, label })),
  },
  payed_at: {
    label: 'Payment Date',
    type: 'date_time',
  },
  posted_at: {
    label: 'Posted Date',
    type: 'date_time',
  },
  amount: {
    label: 'Payment Amount',
    type: 'number',
  },
  billed_amount: {
    label: 'Total Billed',
    type: 'number',
  },
  adjustment_amount: {
    label: 'Total Adjustment',
    type: 'number',
  },
  allowed_amount: {
    label: 'Total Allowed Amount',
    type: 'number',
  },
  balance_client_due_amount: {
    label: 'Total Balance Due Client',
    type: 'number',
  },
  client_responsibility_amount: {
    label: 'Total Client Respons.',
    type: 'number',
  },
  paid_amount: {
    label: 'Total Paid Amount',
    type: 'number',
  },
  created_at: {
    label: 'Created At',
    type: 'date_time',
  },
  author: {
    label: 'Created By',
    type: 'multi_object',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: true,
    selectTitle: (data: any) => data.name,
  },
  last_edited_by: {
    label: 'Last Edited By',
    type: 'multi_object',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: true,
    selectTitle: (data: any) => data.name,
  },
}

export default Payments
