import React from 'react'

import Alert from '../../../Alert'
import Button from '../../../Button'
import Form from '../../../Forms/Form'
import Grid from '../../../Grid'
import Overlay from '../../../Overlay'
import Section from '../../../Section'
import SigneeCard from '../../../Cards/SigneeCard'

import withSettings from '../../../../hocs/withSettings'
import { SendNotificationForm } from '../../../Forms/elements'
import { apiCreate, apiGet } from '../../../../modules/api'
import { withOverlayError } from '../../../../hocs/withOverlayError'

const RESOURCES = {
  admissions: 'residents',
  clients: 'residents',
  alumni: 'residents',
  employees: 'employees',
  organizations: 'organizations',
}

const SendAgreementsOverlay = ({ match, history, location, online }: any) => {
  const [loading, setLoading] = React.useState(false)
  const [signeesLoading, setSigneesLoading] = React.useState(true)
  const [signees, setSignees] = React.useState([])
  const [valid, setValid] = React.useState(false)

  const resourceID = match.params.resource_id
  const resourceType = RESOURCES?.[match.params.resource]

  const form = React.useRef(null)

  React.useEffect(() => {
    const getSignees = async () => {
      try {
        const request = await apiGet({
          name: 'signees',
          url: `/${resourceType}/${resourceID}/signees?only_with_contracts=true`,
        })

        setSignees(request.data.data)
        setSigneesLoading(false)
      } catch (errors) {
        console.debug('errors: ', errors)
      }
    }

    getSignees()
  }, [resourceID])

  const sendNotifications = async () => {
    setLoading(true)

    try {
      await apiCreate({
        name: 'agreements',
        url: `/${resourceType}/${resourceID}/agreements/notifications`,
        params: form.current.getFormValue(),
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }

    location.parent ? history.push(location.parent.url) : history.goBack()
  }

  return (
    <Overlay showBackdrop isLoading={signeesLoading}>
      <Overlay.Header icon="legal_agreement_alt" title="Notify Signees" />

      <Overlay.Content>
        <Form getForm={form} onValidationUpdate={setValid}>
          <Section>
            <Grid gap="1.75rem">
              <Alert glyph="info">Please note: only Signees with Agreements can be notified</Alert>

              {signees?.map((signee: any) => (
                <Grid key={signee?.id} gap="0.75rem">
                  <SigneeCard signee={signee} variant="variant-plain" />

                  <SendNotificationForm
                    model={`notifications.${signee?.id}`}
                    email={signee?.signer?.email}
                    phoneNo={signee?.signer?.phone_no}
                    firstName={signee?.signer?.first_name}
                  />
                </Grid>
              ))}
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer online={online}>
        <Button
          label="Send Agreements"
          glyph="check"
          type="primary"
          color="green"
          flex="100 1 auto"
          onClick={sendNotifications}
          isLoading={loading}
          isDisabled={!valid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default withSettings(withOverlayError(SendAgreementsOverlay))
