import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import DataFormStatus from '../../../components/Statuses/DataFormStatus'
import SummonOverlay from '../../../components/SummonOverlay'

import { useDataForms } from '../../../hooks/useDataForms'
import { useSettings } from '../../../hooks/useSettings'

import { FoRSEAdmitSurveyOverlay } from '../../OutcomeMeasures/FoRSEAdmitSurveyOverlay'
import { FoRSEDemographicInformationOverlay } from '../../OutcomeMeasures/FoRSEDemographicInformationOverlay'
import { FoRSEDischargeSurveyOverlay } from '../../OutcomeMeasures/FoRSEDischargeSurveyOverlay'
import { FoRSEOutcomesSurveyOverlay } from '../../OutcomeMeasures/FoRSEOutcomesSurveyOverlay'
import { FoRSEProgressMonitoringSurveyOverlay } from '../../OutcomeMeasures/FoRSEProgressMonitoringSurveyOverlay'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Dropdown from '../../../components/Dropdown'
import DropdownItem from '../../../components/DropdownItem'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import TableFilter from '../../../components/Table/TableFilter'

const OVERLAYS: any = {
  forse_admit_survey: FoRSEAdmitSurveyOverlay,
  forse_demographic_information: FoRSEDemographicInformationOverlay,
  forse_discharge_survey: FoRSEDischargeSurveyOverlay,
  forse_outcomes_survey: FoRSEOutcomesSurveyOverlay,
  forse_progress_monitoring_survey: FoRSEProgressMonitoringSurveyOverlay,
}

const handleSummonOpen = (event: any) => {
  event.stopPropagation()
}

const OutcomeMeasures = ({ client, subcategories, setStatus }: any) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()
  const { timezone }: any = useSettings()

  const clientId = client?.id || resource_id

  const subcategoriesCount = size(subcategories)
  const showAll = isUndefined(subcategories) || subcategoriesCount === 0

  const { data, isLoading, isRefetching } = useDataForms({
    clientID: clientId,
    category: 'outcome_measure',
    options: { enabled: !!clientId },
  })

  const isEmpty = size(data) === 0

  const [admitSurveyOpen, setAdmitSurvey] = React.useState(false)
  const [demographicInformationOpen, setDemographicInformation] = React.useState(false)
  const [dischargeSurveyOpen, setDischargeSurvey] = React.useState(false)
  const [outcomesSurveyOpen, setOutcomesSurvey] = React.useState(false)
  const [progressMonitoringSurveyOpen, setProgressMonitoringSurvey] = React.useState(false)

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <>
      <SummonOverlay isOpen={admitSurveyOpen} onClose={() => setAdmitSurvey(false)} overlay={<FoRSEAdmitSurveyOverlay dataID="new" />} />

      <SummonOverlay
        isOpen={demographicInformationOpen}
        onClose={() => setDemographicInformation(false)}
        overlay={<FoRSEDemographicInformationOverlay dataID="new" />}
      />

      <SummonOverlay
        isOpen={dischargeSurveyOpen}
        onClose={() => setDischargeSurvey(false)}
        overlay={<FoRSEDischargeSurveyOverlay dataID="new" />}
      />

      <SummonOverlay
        isOpen={outcomesSurveyOpen}
        onClose={() => setOutcomesSurvey(false)}
        overlay={<FoRSEOutcomesSurveyOverlay dataID="new" />}
      />

      <SummonOverlay
        isOpen={progressMonitoringSurveyOpen}
        onClose={() => setProgressMonitoringSurvey(false)}
        overlay={<FoRSEProgressMonitoringSurveyOverlay dataID="new" />}
      />

      <Card testKey="outcome_measures_table">
        <Table
          icon="outcome_measures"
          title="Outcome Measures"
          showSettings={false}
          data={data}
          isLoading={isLoading || isRefetching}
          columns={columns(timezone)}
          emptyDescription="No outcome measures have been added yet."
        />
        <CardContent>
          <Dropdown
            label="Add New…"
            buttonType="primary"
            glyph="add"
            position="bottom"
            buttonSize={200}
            display="inline-flex"
            permission="outcome_measures.create"
            css={STYLES.dropdown}
          >
            {(showAll || subcategories?.includes('forse_admit_survey')) && (
              <DropdownItem label="Admit Survey" icon="outcome_measures" onClick={() => setAdmitSurvey(true)} />
            )}

            {(showAll || subcategories?.includes('forse_demographic_information')) && (
              <DropdownItem label="Demographic Information Form" icon="outcome_measures" onClick={() => setDemographicInformation(true)} />
            )}

            {(showAll || subcategories?.includes('forse_progress_monitoring_survey')) && (
              <DropdownItem label="Progress Monitoring Survey" icon="outcome_measures" onClick={() => setProgressMonitoringSurvey(true)} />
            )}

            {(showAll || subcategories?.includes('forse_outcomes_survey')) && (
              <DropdownItem label="Outcomes Survey" icon="outcome_measures" onClick={() => setOutcomesSurvey(true)} />
            )}

            {(showAll || subcategories?.includes('forse_discharge_survey')) && (
              <DropdownItem label="Discharge Survey" icon="outcome_measures" onClick={() => setDischargeSurvey(true)} />
            )}
          </Dropdown>
        </CardContent>
      </Card>
    </>
  )
}

const columns = (timezone: string) => [
  {
    accessor: 'name',
    Header: 'Name',
    width: 240,
    Cell: ({ value, row }: any) => {
      const OverlayTag = OVERLAYS?.[row.original.subcategory]

      if (!OverlayTag) return null

      return (
        <SummonOverlay
          onOpen={handleSummonOpen}
          overlay={<OverlayTag showBackdrop dataID={row.original.id} initialData={row.original} type="summon" />}
        >
          <TableCell.MainLink label={value} />
        </SummonOverlay>
      )
    },
  },
  {
    width: 120,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <DataFormStatus status={value} />,
    Filter: TableFilter.DataFormStatus,
    filter: 'dataFormStatus',
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

const STYLES = {
  dropdown: {
    display: 'inline-flex',
    width: 'fit-content',
  },
}

export default OutcomeMeasures
