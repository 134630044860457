import React from 'react'

import { ICONS } from '../theme'

import { Icon as IconType } from '../declarations/types'

type Props = {
  icon: IconType
  size?: number | string
  width?: number | string
  height?: number | string
} & React.HTMLAttributes<HTMLImageElement>

const Icon: React.FC<Props> = ({ icon, size = 24, width, height, className }) => {
  let iconObj: any = {}

  if (typeof icon === 'string') iconObj = ICONS[icon]
  else if (typeof icon === 'object') iconObj = icon

  const styles: any = {
    width: width || size,
    height: height || size,
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    pointerEvents: 'none',
    flex: '0 0 auto',
  }

  return <img src={iconObj?.file} css={styles} alt={iconObj?.alt || 'Icon'} width={size} height={size} className={className} />
}

export default Icon
