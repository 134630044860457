import React from 'react'

import PieChart from '../ChartTypes/PieChart'

export const PaymentSourceChart = (props: any) => {
  const data: any = [
    {
      name: 'Card',
      value: props.data?.credit_debit_card,
    },
    {
      name: 'Cash',
      value: props.data?.cash,
    },
    {
      name: 'Check',
      value: props.data?.check,
    },
    {
      name: 'Money Order',
      value: props.data?.money_order,
    },
    {
      name: 'Bank Account (ACH)',
      value: props.data?.ach,
    },
    {
      name: 'Write-Off',
      value: props.data?.write_off,
    },
    {
      name: 'Venmo',
      value: props.data?.venmo,
    },
    {
      name: 'Zelle',
      value: props.data?.zelle,
    },
    {
      name: 'Other',
      value: props.data?.other,
    },
  ]

  return <PieChart {...props} data={data} />
}
