import React from 'react'

import SmartStatus from '../../components/SmartStatus'

const statuses = {
  pending: {
    label: 'pending',
    color: 'blue',
  },
  accepted: {
    label: 'accepted',
    color: 'green',
  },
  declined: {
    label: 'declined',
    color: 'red',
  },
}

export const PartnershipInviteToStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}
