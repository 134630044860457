import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import Label from '../../components/Label'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'

import { VariableKey } from '../FormBuilder/components/VariableKey'

const RootVariableOverlay = (props: any) => {
  const { data, close, form, initialModel, isOverlayLoading } = useOverlay({
    name: 'form_variables',
    endpoint: '/form_variables',
    invalidate: 'form_variables',
    options: props,
  })

  if (isOverlayLoading || !data) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} position="right">
      <Overlay.Header title="Variable" icon="variables" />

      <Overlay.Content>
        <Form isEditable={false} getForm={form} initialModel={initialModel}>
          <Section>
            <FormSection>
              <Input label="Name" model="name" />
              <div>
                <Label label="Variable Key" />
                <VariableKey canCopy variable={data?.variable_key} />
              </div>

              <Input label="Value" model="variable_value" />
              <Textarea label="Description" model="description" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    </Overlay>
  )
}

export const VariableOverlay = withOverlayError(RootVariableOverlay)
