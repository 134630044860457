import React from 'react'

import GlobalTags from '../../../Table/Cells/GlobalTags'
import SmartFlags from '../../../Table/Cells/SmartFlags'
import Table from '../../../Table/Table'
import TableCell from '../../../Table/TableCell'
import TableFilter from '../../../Table/TableFilter'

import withSettings from '../../../../hocs/withSettings'
import { formatURL } from '../../../../utils/functions'
import { PRICING_STRATEGIES, PRICING_UNIT_TYPES } from '../../../Billing/constants'

const STATUSES: any = {
  active: 'Active',
  archived: 'Archived',
}

const columns = (to, onClick, mainLinkAs) => [
  {
    width: 430,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Internal Name',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink
        as={mainLinkAs}
        icon={row.original.data?.icon}
        to={to?.(row.original.id)}
        onClick={() => onClick?.(row.original)}
        label={value}
      />
    ),
  },
  {
    width: 110,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <TableCell value={STATUSES[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'active', value: 'active' },
      { label: 'archived', value: 'archived' },
    ],
  },
  {
    width: 240,
    Header: 'Tags',
    accessor: 'global_tags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <GlobalTags
        // queryKey={queryKey} // TODO
        tags={row.original.global_tags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 240,
    Header: 'Smart Flags',
    accessor: 'smart_flags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <SmartFlags
        // queryKey={queryKey}
        value={row.original.smart_flags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 180,
    accessor: 'data.dynamic_filters.filter_1',
    Header: 'Filter 1',
  },
  {
    width: 180,
    accessor: 'data.dynamic_filters.filter_2',
    Header: 'Filter 2',
  },
  {
    width: 200,
    accessor: 'data.public_name',
    Header: 'Public Name',
    Cell: TableCell.Truncated,
  },
  {
    accessor: 'data.price',
    Header: 'Price',
    Cell: ({ value }: any) => (value ? <TableCell.Amount value={value} /> : '–'),
  },
  {
    width: 200,
    accessor: 'data.pricing_type',
    Header: 'Pricing Strategy',
    Cell: ({ value }: any) => <TableCell value={PRICING_STRATEGIES[value] || '–'} />,
  },
  {
    width: 240,
    accessor: 'data.price_unit_type',
    Header: 'Unit Type',
    Cell: ({ value }: any) => <TableCell value={PRICING_UNIT_TYPES[value] || '–'} />,
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  timezone: string
  title: string
  to?: Function
}

const ServiceBehaveTemplatesTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    localStorageKey,
    onClick,
    timezone,
    title,
    to,
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={columns(to, onClick, props.mainLinkAs)}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

ServiceBehaveTemplatesTable.defaultProps = {
  title: 'Services',
  icon: 'certificate',
  emptyDescription: 'No services to display',
  localStorageKey: 'service_internal_templates',
}

export default withSettings(ServiceBehaveTemplatesTable)
