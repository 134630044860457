import React from 'react'
import { connect } from 'react-redux'

import Button from '../../Button'
import DateInput from '../../Forms/DateInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Flex from '../../Flex'
import Input from '../../Forms/Input'
import Select from '../../Forms/Select'
import Option from '../../Forms/Option'
import Textarea from '../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'

import { withOverlayError } from '../../../hocs/withOverlayError'

class AllergyOverlay extends OverlayBase {
  renderHeader = () => <Overlay.Header title="Allergy" icon="treatment_data" />

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { record, timezone } = this.props

    const reference = this.isPortal ? this.props.user : this.props.reference

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          timezone={timezone}
          isEditable={$editable}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
          linked={$new && { reference_id: reference?.id, reference_type: reference?.type }}
        >
          <Section>
            <FormSection layout="vertical">
              <Flex gap="1rem" stretchChildrenX>
                <Select label="Status" model="status" defaultValue="active">
                  <Option label="Active" value="active" />
                  <Option label="Inactive" value="inactive" />
                  <Option label="Canceled" value="canceled" />
                  <Option label="Resolved" value="resolved" />
                </Select>

                <Select label="Category" model="category" defaultValue="drug">
                  <Option label="Drug" value="drug" />
                  <Option label="Food" value="food" />
                  <Option label="Environmental" value="environmental" />
                </Select>

                <Select label="Source" model="source" isEditable={false} defaultValue="internal">
                  <Option label="Custom" value="internal" />
                  <Option label="eRX" value="erx" />
                </Select>
              </Flex>

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please add a name',
                  },
                }}
              />

              <Input
                label="Risk"
                model="criticality"
                description="The potential seriousness of a future reaction. This represents a clinical judgment about the worst-case scenario for a future reaction. For example, a severe rash may have a low risk (or criticality) of a life-threatening reaction."
              />

              <Flex gap="1rem" stretchChildrenX>
                <Input label="Severity" model="severity" />

                <Select label="Reaction Type" model="reaction_type" defaultValue="allergy">
                  <Option label="Allergy" value="allergy" />
                  <Option label="Intolerance" value="intolerance" />
                </Select>
              </Flex>

              <Flex gap="1rem" stretchChildrenX>
                <DateInput
                  defaultToNow
                  model="started_on"
                  label="Onset On"
                  validations={{
                    presence: {
                      message: 'Please enter the date and time of contact',
                    },
                  }}
                />

                <DateInput model="ended_on" label="Resolved On" />
              </Flex>

              <Textarea label="Description" model="description" />

              <Textarea label="Reactions" model="reactions" />

              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button glyph="edit" label="Edit" type="default" isDisabled={this.props.loading} onClick={this.edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Allergy?"
              message="Are you sure you want to delete this allergy? This action cannot be undone."
              onYes={this.delete}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={this.props.loading} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'allergies')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'allergies')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(AllergyOverlay))
