import React from 'react'

import Card from '../../../../Card'
import CardContent from '../../../../CardContent'
import CardHeader from '../../../../CardHeader'
import CardTitle from '../../../../CardTitle'
import Grid from '../../../../Grid'
import Icon from '../../../..//Icon'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Table from '../../../../Table/Table'
import snakeCase from 'lodash/snakeCase'

const columns = [
  {
    width: 120,
    accessor: 'name',
    Cell: ({ value }: any) => <span className="!font-[600]">{value}</span>,
  },
  {
    width: 260,
    accessor: 'model',
    Cell: ({ value }: any) => (
      <RadioGroup model={`data.past_medications.${value}`} defaultValue="never_took" layout="horizontal-wrap">
        <Radio label="Helpful" value="helpful" />
        <Radio label="Not helpful" value="not_helpful" />
        <Radio label="Never took" value="never_took" />
      </RadioGroup>
    ),
  },
]

const ANTIDEPRESSANTS = [
  { name: 'Celexa', model: 'celexa' },
  { name: 'Cymbalta', model: 'cymbalta' },
  { name: 'Effexor', model: 'effexor' },
  { name: 'Lexapro', model: 'lexapro' },
  { name: 'Luvox', model: 'luvox' },
  { name: 'Paxil', model: 'paxil' },
  { name: 'Pristiq', model: 'pristiq' },
  { name: 'Prozac', model: 'prozac' },
  { name: 'Remeron', model: 'remeron' },
  { name: 'Serzone', model: 'serzone' },
  { name: 'Tricyclics', model: 'tricyclics' },
  { name: 'Wellbutrin', model: 'wellbutrin' },
  { name: 'Zoloft', model: 'zoloft' },
]

const MOOD_STABILIZERS = [
  { name: 'Depakote', model: 'depakote' },
  { name: 'Lamictal', model: 'lamictal' },
  { name: 'Lithium', model: 'lithium' },
  { name: 'Neurontin', model: 'neurontin' },
  { name: 'Tegretol', model: 'tegretol' },
  { name: 'Topamax', model: 'topamax' },
  { name: 'Trileptal', model: 'trileptal' },
]

const STIMULANTS = [
  { name: 'Adderall', model: 'adderall' },
  { name: 'Concerta', model: 'concerta' },
  { name: 'Intuniv', model: 'intuniv' },
  { name: 'Ritalin', model: 'ritalin' },
  { name: 'Strattera', model: 'strattera' },
  { name: 'Vyvanse', model: 'vyvanse' },
]

const ANTIPSYCHOTICS_MOOD_STABILIZERS = [
  { name: 'Abilify', model: 'abilify' },
  { name: 'Clozaril', model: 'clozaril' },
  { name: 'Geodon', model: 'geodon' },
  { name: 'Haldol', model: 'haldol' },
  { name: 'Invega', model: 'invega' },
  { name: 'Latuda', model: 'latuda' },
  { name: 'Prolixin', model: 'prolixin' },
  { name: 'Risperdal', model: 'risperdal' },
  { name: 'Saphris', model: 'saphris' },
  { name: 'Seroquel', model: 'seroquel' },
  { name: 'Zyprexa', model: 'zyprexa' },
]

const SEDATIVES_HYPNOTICS = [
  { name: 'Ambien', model: 'ambien' },
  { name: 'Lunesta', model: 'lunesta' },
  { name: 'Restoril', model: 'restoril' },
  { name: 'Rozerem', model: 'rozerem' },
  { name: 'Seroquel', model: 'seroquel' },
  { name: 'Sonata', model: 'sonata' },
  { name: 'Trazodone', model: 'trazodone' },
]

const ANXIOLYTICS = [
  { name: 'Ativan', model: 'ativan' },
  { name: 'Buspar', model: 'buspar' },
  { name: 'Klonopin', model: 'klonopin' },
  { name: 'Lyrica', model: 'lyrica' },
  { name: 'Neurontin', model: 'neurontin' },
  { name: 'Valium', model: 'valium' },
  { name: 'Xanax', model: 'xanax' },
]

type SectionProps = {
  title: string
  data: Object[]
}

const PastMedicationsSection = ({ title, data }: SectionProps) => (
  <Card testKey={`${snakeCase(title)}_table`}>
    <CardHeader graphic={<Icon icon="medications" size="1.2rem" />}>
      <CardTitle title={title} />
    </CardHeader>
    <CardContent className="!p-0">
      <Table columns={columns} data={data} showHeaders={false} showSettings={false} />
    </CardContent>
  </Card>
)

const PastMedications = () => {
  return (
    <Grid gap="1rem">
      <PastMedicationsSection title="Antidepressants" data={ANTIDEPRESSANTS} />
      <PastMedicationsSection title="Mood stabilizers" data={MOOD_STABILIZERS} />
      <PastMedicationsSection title="Stimulants" data={STIMULANTS} />
      <PastMedicationsSection title="Antipsychotics/Mood Stabilizers" data={ANTIPSYCHOTICS_MOOD_STABILIZERS} />
      <PastMedicationsSection title="Sedatives/ Hypnotics" data={SEDATIVES_HYPNOTICS} />
      <PastMedicationsSection title="Anxiolytics" data={ANXIOLYTICS} />
    </Grid>
  )
}

export default PastMedications
