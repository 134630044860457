import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../../theme'
import Chart from '../Chart'

export const GroupTherapyAttendanceChart = (props: any) => {
  const days: any = Array.from(new Set([...props.attending_clients_data.x, ...props.non_attending_clients_data.x]))

  const series: any = [
    {
      name: 'Attending Clients',
      type: 'bar',
      stack: 'total',
      color: COLORS.green,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: props.attending_clients_data.y,
    },
    {
      name: 'Non-Attending Clients',
      type: 'bar',
      stack: 'total',
      color: COLORS.red,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: props.non_attending_clients_data.y,
    },
  ]

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: ['Attending Clients', 'Non-Attending Clients'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: days,
    },
    yAxis: {
      type: 'value',
    },
    series,
  }

  return <Chart option={option} {...props} />
}
