import React from 'react'
import startCase from 'lodash/startCase'

import { fullname, age, nicePhoneNo } from '../../utils/functions'

import CardLinkPhoto from '../CardLinkPhoto'
import CardMeta from '../CardMeta'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'
import Text from '../Typography/Text'

type Props = {
  to: any
  client: any
  hideStatus: boolean
  hideIntakeStatus: boolean
  actions: React.ReactNode
}

const InvitedClient = ({ to, client, hideStatus, hideIntakeStatus, actions, ...rest }: Props) => (
  <CardLinkPhoto to={to} record={client} src={client?.avatar} testKey={`${fullname(client)}`} actions={actions} {...rest}>
    <CardTitle title={fullname(client)} />
    <CardSubtitle subtitle={`${startCase(client?.sex) || '–'}, ${age(client?.dob)} y/o, #${client?.behave_id}`} />

    <CardMeta>
      <Text glyph="email" description={client?.email} />
      <Text glyph="phone" description={nicePhoneNo(client?.phone_no)} />
    </CardMeta>
  </CardLinkPhoto>
)

export default InvitedClient
