import React from 'react'

import SmartStatus from '../SmartStatus'

export const statuses = {
  online: {
    label: 'Online',
    color: 'red',
  },
  manual: {
    label: 'Manual',
    color: 'red',
  },
  embed: {
    label: 'Contact Form',
    color: 'yellow',
  },
  app: {
    label: 'Full Application',
    color: 'blue',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
