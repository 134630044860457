import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import isEqual from 'react-fast-compare'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { getFormElementProps } from '../../utils/functions'
import { isDefined } from '../../../../utils/functions'

import { useFormField } from '../../../../components/Forms/hooks/useFormField'
import { withFormContext } from '../../../../components/Forms/context'
import { Worksheet as BHWorksheet } from '../../../../components/Worksheet/Worksheet'
import Label from '../../../../components/Label'
import Json from '../../../../components/Json'

export const Worksheet: React.FC<any> = (props) => {
  const { isEditable, children, element, className, hoverElement, useParsedConfig, environment, setElementConfigValue } = props

  const isBuilder = environment === 'builder'

  if (!element?.config) return null

  const rootClasses = clsx('WORKSHEET grid', className)

  const { worksheet_columns = [], initial_rows_count = 7, allow_create_delete_rows } = element.config

  const { title, model, label, tooltip, description }: any = getFormElementProps(element, { useParsedConfig, environment })

  if (isBuilder) {
    return (
      <div className={rootClasses}>
        {hoverElement}
        {children}

        <div className="w-full grid">
          {(label || tooltip) && <Label label={label} tooltip={tooltip} />}
          {description && <div className="text-[0.9rem] mb-2">{description}</div>}
          <WorksheetBuilderForm
            key={`updated-${element.config?.updated_at}`}
            setElementConfigValue={setElementConfigValue}
            element={element}
            isEditable={isEditable}
            title={title}
            worksheetColumns={worksheet_columns}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}

      <div className="w-full grid">
        {(label || tooltip) && <Label label={label} tooltip={tooltip} />}
        {description && <div className="text-[0.9rem] mb-2">{description}</div>}
        <WorksheetForm
          model={model}
          title={title}
          element={element}
          worksheetColumns={worksheet_columns}
          initialRowsCount={initial_rows_count}
          allowCreateDeleteRows={allow_create_delete_rows}
        />
      </div>
    </div>
  )
}

const WorksheetForm = withFormContext((props: any) => {
  const { element, model, worksheetColumns, initialRowsCount = 7, form, isEditable, title } = props

  const modelVal = form?.getField(model)
  const initialModelVal = form?.getInitialInputFieldValue(model)

  const [data, setData]: any = React.useState(
    isDefined(modelVal) ? modelVal : isDefined(initialModelVal) ? initialModelVal : element?.config?.initial_data || [],
  )

  const { formActions } = useFormField({ model, form })

  const allowCreateDeleteRows = isUndefined(props.allowCreateDeleteRows) ? true : props.allowCreateDeleteRows

  // Update form state
  React.useEffect(() => {
    if (!data) return

    formActions.setValue(data)
  }, [data])

  if (!worksheetColumns || size(worksheetColumns) === 0) return null

  return (
    <>
      <BHWorksheet
        asCard
        title={title || null}
        withFullScreenToggle
        withWrapToggle={false}
        withShowInvalidToggle={false}
        withForm={false}
        isEditable={isEditable}
        initialData={data}
        allow={isEditable && allowCreateDeleteRows ? 'create-update-delete' : ''}
        columns={worksheetColumns}
        initialRowsCount={initialRowsCount}
        titleClassName="!mr-auto"
        onDataUpdate={(dataMap: any, dataIds: any) => {
          const result: any = []

          if (!dataMap || !dataIds) return result

          for (const id of dataIds) {
            result.push(dataMap[id])
          }

          if (isEqual(data, result)) return

          setData(result)
        }}
      />
    </>
  )
})

const getBuilderInitialData = (element: any) => {
  const rowsCount = element?.config?.initial_rows_count || 7

  const result: any = []

  for (let i = 0; i < rowsCount; i++) {
    result.push({ _id: uuid() })
  }

  return result
}

const WorksheetBuilderForm = (props: any) => {
  const { setElementConfigValue, element, isEditable, title, worksheetColumns } = props

  const [initialData] = React.useState(element?.config?.initial_data || getBuilderInitialData(element))

  const handleDataChange = (dataMap, dataIds) => {
    if (!dataMap || !dataIds) return

    const result: any = []

    for (const id of dataIds) {
      result.push(dataMap[id])
    }

    setElementConfigValue({
      uuid: element.uuid,
      key: 'initial_data',
      value: result,
    })
  }

  if (!worksheetColumns || size(worksheetColumns) === 0) return null

  return (
    <BHWorksheet
      asCard
      title={title || null}
      withFullScreenToggle
      withWrapToggle={false}
      withShowInvalidToggle={false}
      withForm={true}
      isEditable={isEditable}
      initialData={initialData}
      allow={isEditable ? 'create-update-delete' : ''}
      columns={worksheetColumns}
      titleClassName="!mr-auto"
      onDataChange={handleDataChange}
    />
  )
}
