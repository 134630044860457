import React from 'react'

import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Grid from '../../Grid'
import Input from '../../Forms/Input'
import PhoneInput from '../../Forms/PhoneInput'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const ContactsFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <div>
        <Type as="h2" variant="H3">
          Contacts
        </Type>
        <Type as="p" variant="TEXT" css={{ margin: '0.2em 0 0' }}>
          This information will help us understand how your business operates, so we can better fit your needs.{' '}
          <em>
            Don't worry if you handle everything yourself, we work with lots of small business owners to help them stay on top of
            everything.
          </em>
        </Type>
      </div>

      <PhoneInput
        label="Main Business Phone Number"
        model="questionnaire.contacts.main_business_phone_no"
        description="We require at least one valid phone number"
      />

      <Grid gap="2rem">
        <FormSection
          heading="Accounts Payable Contact"
          description="Who do your vendors call regarding payment for your purchases?"
          css={{ gridGap: 0 }}
        >
          <Flex horizontal stretchChildrenX gap="1rem" css={{ marginTop: 0 }}>
            <Input label="Name" model="questionnaire.contacts.accounts_payable.name" />
            <PhoneInput label="Phone Number" model="questionnaire.contacts.accounts_payable.phone_no" />
          </Flex>
        </FormSection>

        <FormSection
          heading="IT Support Contact"
          description="Who purchases and maintains your information technology; computers, phones, and business software?"
          css={{ gridGap: 0 }}
        >
          <Flex horizontal stretchChildrenX gap="1rem" css={{ marginTop: 0 }}>
            <Input label="Name" model="questionnaire.contacts.it_support.name" />
            <PhoneInput label="Phone Number" model="questionnaire.contacts.it_support.phone_no" />
          </Flex>
        </FormSection>

        <FormSection heading="Website Admin Contact" description="Who handles updates to your website?" css={{ gridGap: 0 }}>
          <Flex horizontal stretchChildrenX gap="1rem" css={{ marginTop: 0 }}>
            <Input label="Name" model="questionnaire.contacts.website_admin.name" />
            <PhoneInput label="Phone Number" model="questionnaire.contacts.website_admin.phone_no" />
          </Flex>
        </FormSection>

        <FormSection
          heading="Business Development Contact"
          description="Who do your partners call regarding their relationship with your organization?"
          css={{ gridGap: 0 }}
        >
          <Flex horizontal stretchChildrenX gap="1rem" css={{ marginTop: 0 }}>
            <Input label="Name" model="questionnaire.contacts.business_development.name" />
            <PhoneInput label="Phone Number" model="questionnaire.contacts.business_development.phone_no" />
          </Flex>
        </FormSection>
      </Grid>
    </FormSection>
  )
}

export const Contacts: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <ContactsFields />
    </Form>
  )
}
