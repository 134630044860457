import React from 'react'

import Flex from '../../../../components/Flex'
import Input from '../../../../components/Forms/Input'
import Option from '../../../../components/Forms/Option'
import Radio from '../../../../components/Forms/Radio'
import RadioGroup from '../../../../components/Forms/RadioGroup'
import Select from '../../../../components/Forms/Select'
import TableArrayForm from '../../../../components/Forms/TableArrayForm'
import URLInput from '../../../../components/Forms/URLInput'

export const VideoEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate } = props

  const {
    video_max_width = 100,
    video_max_width_type = 'percentage',
    // video_provider = 'youtube',
    // video_src,
    video_markers,
    video_url,
    video_thumbnail,
  } = activeElement.config

  return (
    <>
      {/* <RadioGroup label="Provider" model="video_provider" value={video_provider} onUpdate={onUpdate} layout="horizontal-dense">
        <Radio label="YouTube" value="youtube" />
        <Radio label="Vimeo" value="vimeo" />
      </RadioGroup> */}

      {/* <Input label="Video ID" model="video_src" value={video_src} onUpdate={onUpdate} /> */}

      <URLInput label="Video URL" model="video_url" value={video_url} onUpdate={onUpdate} />

      <URLInput label="Thumbnail URL" model="video_thumbnail" value={video_thumbnail} onUpdate={onUpdate} />

      <Flex nowrap gap="0.75rem">
        <Input label="Max Width" type="number" model="video_max_width" size={4} value={video_max_width} onUpdate={onUpdate} />
        <Select label="Type" model="video_max_width_type" value={video_max_width_type} onUpdate={onUpdate} flex="1 1 auto">
          <Option label="Percent" value="percentage" />
          <Option label="Pixels" value="pixels" />
        </Select>
      </Flex>

      {/* <TableArrayForm name="Video Markers" model="video_markers" inputs={INPUTS} value={video_markers} onUpdate={onUpdate} /> */}
    </>
  )
}

const INPUTS = [
  {
    name: 'Time (sec)',
    width: '1fr',
    isRequired: true,
    element: (id: string, data: any) => (
      <Input
        type="number"
        model={`${id}.time`}
        value={data.time}
        defaultValue={10}
        validations={{
          presence: {
            message: 'Please enter a time in seconds',
          },
        }}
      />
    ),
  },
  {
    name: 'Label',
    width: '1fr',
    isRequired: true,
    element: (id: string, data: any) => (
      <Input
        model={`${id}.label`}
        value={data.label}
        defaultValue="Label"
        validations={{
          presence: {
            message: 'Please enter a label',
          },
        }}
      />
    ),
  },
]
