import React from 'react'

import { Button, Glyph, State } from '../../'

const Message = ({ glyph, title, description }: any) => (
  <div css={styles.root}>
    <Glyph glyph={glyph} />
    <h2 css={styles.title}>{title}</h2>
    <p css={styles.description}>{description}</p>
  </div>
)

const ConfirmationStep = ({ isLoading, onClose }: any) => {
  if (isLoading) return <State isLoading />

  return (
    <>
      <Message glyph="tick_circle" title="Thank you!" description="Your payment has been successful." />

      <Button label="Close" onClick={onClose} />
    </>
  )
}

const styles = {
  root: {
    textAlign: 'center',
    padding: '2rem 0',
  },

  title: {
    margin: '0.25rem 0 0.5rem',
  },

  description: {
    margin: 0,
  },
}

export default ConfirmationStep
