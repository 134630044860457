import React from 'react'

import { ComboBox } from '../../components/Forms/ComboBoxes/ComboBox'
import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Overlay from '../../components/Overlay'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Section from '../../components/Section'

export const IntakeApplicationSelectOverlay = (props: any) => {
  const { onClose, onSelect } = props

  const [resident, setResident] = React.useState(null)
  const [intakeApplication, setIntakeApplication] = React.useState(null)

  const handleSelect = () => {
    if (!intakeApplication) return

    onSelect?.(intakeApplication)
    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center">
      <Overlay.Header title="Select Intake Application" icon="admissions" />

      <Overlay.Content>
        <Section>
          <Form isEditable>
            <FormSection>
              <OverlaySelector label="Client" type="clients.all" icon="clients" onSelect={setResident} />
              <ComboBox
                label="Intake Application"
                type="client.intake_applications"
                icon="admissions"
                onUpdate={setIntakeApplication}
                dependentValue={resident?.id}
                isDisabled={!resident?.id}
              />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Select Intake Application"
          glyph="form"
          color="blue"
          type="primary"
          onClick={handleSelect}
          isDisabled={!intakeApplication}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
