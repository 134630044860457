import React from 'react'
import size from 'lodash/size'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Icon from '../../../components/Icon'
import Markup from '../../../components/Markup'
import Overlay from '../../../components/Overlay'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'

export const CustomTermsList = ({ data }: any) => {
  const enabledSections = React.useMemo(() => {
    const result: any = []

    if (!data) return result

    for (const key in SECTIONS) {
      const { enabledModel } = SECTIONS[key]
      const enabled = data?.[enabledModel]

      if (enabled) {
        result.push(SECTIONS[key])
      }
    }

    return result
  }, [data])

  if (size(enabledSections) === 0) return null

  return (
    <div>
      {enabledSections.map((section) => (
        <div className="flex items-center py-1.5 border-b border-0 border-solid border-divider last:border-none">
          <Icon icon="legal_agreement_alt" size={18} className="mr-1.5" />

          <div className="flex-[1_1_auto] font-[600]">{section.title}</div>

          <SummonOverlay
            overlay={<PreviewOverlay title={section.title} content={data[`parsed_${section.textModel}`] || data[section.textModel]} />}
          >
            <Button label="View" size={100} glyph="view" type="primary" />
          </SummonOverlay>
        </div>
      ))}
    </div>
  )
}

const PreviewOverlay = (props: any) => {
  const { content, title, onClose } = props

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} maxWidth={60} position="center">
      <Overlay.Header icon="legal_agreement_alt" title={title} />

      <Overlay.Content className="p-5">
        {content ? (
          <Markup value={content} />
        ) : (
          <Card>
            <State isEmpty icon="legal_agreement_alt" title={title} emptyDescription="No content has been added yet" minHeight={200} />
          </Card>
        )}
      </Overlay.Content>
    </Overlay>
  )
}

const SECTIONS = {
  service_terms: {
    title: 'Service Terms',
    enabledModel: 'use_service_terms',
    textModel: 'service_terms_text',
  },
  terms_and_conditions: {
    title: 'Terms & Conditions',
    enabledModel: 'use_terms_and_conditions',
    textModel: 'terms_and_conditions_text',
  },
  privacy_policy: {
    title: 'Privacy Policy',
    enabledModel: 'use_privacy_policy',
    textModel: 'privacy_policy_text',
  },
  software_access_agreement: {
    title: 'Software Access Agreement',
    enabledModel: 'use_software_access_agreement',
    textModel: 'software_access_agreement_text',
  },
  business_associate_agreement: {
    title: 'Business Associate Agreement',
    enabledModel: 'use_business_associate_agreement',
    textModel: 'business_associate_agreement_text',
  },
  software_terms_of_use_agreement: {
    title: 'Software Terms Of Use Agreement',
    enabledModel: 'use_software_terms_of_use_agreement',
    textModel: 'software_terms_of_use_agreement_text',
  },
}
