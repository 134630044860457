import React from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom-v5-compat'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import sortBy from 'lodash/sortBy'

import { COLORS, SHADOW } from '../../theme'
import { useCreate, useGet } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const [removed] = list.splice(startIndex, 1)
  list.splice(endIndex, 0, removed)

  return list
}

const RootProgramPhasesReorderOverlay = (props: any) => {
  const { phases, onClose } = props

  const location = useLocation()
  const navigate = useNavigate()
  const { resource_id }: any = useParams()

  const { data: program, isLoading: isLoadingProgram }: any = useGet({
    name: ['program', resource_id],
    url: `/programs/${resource_id}`,
    options: { enabled: !!resource_id },
  })

  const { mutateAsync, isLoading: isSaving }: any = useCreate({
    name: ['program', resource_id, 'reorder'],
    url: '/phases/reorder',
    invalidate: ['program', resource_id],
    invalidateKeys: [
      'programs-timeline',
      'program-timeline',
      ['program', resource_id, 'phases-with-residents'],
      ['program', resource_id, 'phases-with-seats'],
    ],
  })

  const [data, setData]: any = React.useState([])

  const close = () => {
    if (onClose) return onClose()

    if (location.parent) navigate(location.parent.url)
    else navigate(-1)
  }

  React.useEffect(() => {
    if (!program) return

    const orderedPhases = sortBy(program.phases, 'order')

    setData(orderedPhases)
  }, [program])

  const handleSave = async () => {
    await mutateAsync({
      reorder: data.map((phase: any, index: number) => ({ id: phase.id, order: index })),
    })

    close()
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return // dragged outside the list

    const reordered = reorder(data, result.source.index, result.destination.index)
    setData(reordered)
  }

  if (!program || isLoadingProgram) return <OverlayLoader position="right" />

  return (
    <Overlay position="right" onClose={close}>
      <Overlay.Header icon="program_lists" title="Reorder Program Lists" />

      <Overlay.Content css={styles.content}>
        <Grid gap="1rem">
          <Card>
            <div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="program_phases_reorder">
                  {(droppableProvided: any) => (
                    <div ref={droppableProvided.innerRef}>
                      {data.map((phase: any, index: number) => {
                        return (
                          <Draggable key={phase.id} draggableId={phase.id} index={index}>
                            {(draggableProvided: any, draggableSnapshot: any) => (
                              <div
                                key={phase.id}
                                ref={draggableProvided.innerRef}
                                css={styles.item}
                                style={draggableProvided.draggableProps.style}
                                className={draggableSnapshot.isDragging ? 'is-dragging' : ''}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                              >
                                <Glyph glyph="drag_and_drop" size={18} css={styles.moveGlyph} />
                                <span css={styles.label}>{phase.name}</span>
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Card>

          <Button label="Save" glyph="check" type="primary" color="green" onClick={handleSave} isLoading={isSaving} />
        </Grid>
      </Overlay.Content>
    </Overlay>
  )
}

const styles = {
  content: {
    padding: '1rem',
  },

  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.4em 0.75em',
    borderBottom: `1px solid ${COLORS.divider}`,
    cursor: 'pointer !important',

    '&:hover': {
      background: COLORS.hover,
    },

    '&:last-of-type': {
      borderBottom: 'none',
    },

    '&.is-dragging': {
      borderRadius: 4,
      background: COLORS.white,
      boxShadow: SHADOW(3, COLORS.divider),

      // quick fix: when used inside a portal container, the placeholder element's position is not calculated correctly
      left: 'auto !important',
      right: '0 !important',
    },
  },

  hiddenInput: {
    display: 'none',
    WebkitAppearance: 'none',
  },

  label: {
    display: 'inline-block',
    marginLeft: '0.4em',
    fontWeight: 500,
  },

  moveGlyph: {
    opacity: 0.7,
    marginLeft: '-0.1rem',
    marginRight: '0.5rem',
    cursor: 'move !important',

    '&:hover': {
      cursor: 'move !important',
    },
  },
}

export const ProgramPhasesReorderOverlay = withOverlayError(RootProgramPhasesReorderOverlay)
