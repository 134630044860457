import React from 'react'

import PieChart from '../ChartTypes/PieChart'

export const ClientEthnicitiesChart = (props: any) => {
  const data: any = [
    {
      name: 'American Indian or Alaska Native',
      value: props.data?.american_indian_or_alaska_native,
    },
    {
      name: 'Asian',
      value: props.data?.asian,
    },
    {
      name: 'Black or African American',
      value: props.data?.black_or_african_american,
    },
    {
      name: 'Hispanic or Latino',
      value: props.data?.hispanic_or_latino,
    },
    {
      name: 'Native Hawaiian or Other Pacific Islander',
      value: props.data?.native_hawaiian_or_other_pacific_islander,
    },
    {
      name: 'White',
      value: props.data?.white,
    },
  ]

  return <PieChart {...props} data={data} />
}
