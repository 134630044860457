import React from 'react'
import isUndefined from 'lodash/isUndefined'

import { COLORS } from '../../theme'
import { withFormContext } from '../../components/Forms/context'

import Avatar from '../../components/Avatar'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Loader from '../../components/Loader'
import Overlay from '../../components/Overlay'
import SummonOverlay from '../../components/SummonOverlay'

import { EmployeesTable } from '../../components/Forms/Selectors/tables/EmployeesTable'

const RootDataFormAssignmentSelector = (props: any) => {
  const { label = 'Assignee:', value, onUpdate } = props

  const [data, setData]: any = React.useState(value)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isUpdated, setIsUpdated] = React.useState(false)

  const onSelect = async (record: any) => {
    // update local state
    setData(record)

    // call updating function if exists
    if (onUpdate) {
      setIsLoading(true)

      try {
        await onUpdate(record)

        setIsLoading(false)
        setIsUpdated(true)
      } catch (error) {
        setIsLoading(false)
        setIsUpdated(false)
      }
    }
  }

  return (
    <div>
      <SummonOverlay overlay={<SelectorOverlay onSelect={onSelect} />}>
        <div css={STYLES.trigger}>
          <div css={STYLES.triggerLabel}>{label}</div>
          {data ? <Value label={data?.name} avatar={data?.avatar} /> : <div css={STYLES.triggerEmpty}>Select…</div>}

          <Glyph glyph="triangle_down" size={10} css={STYLES.triggerTriangle} />
          {isLoading && <Loader size={12} color="green" css={{ marginLeft: 5 }} />}
          {isUpdated && <Glyph size={12} glyph="check" />}
        </div>
      </SummonOverlay>
    </div>
  )
}

const Value = ({ label, icon, avatar }: any) => {
  const hasAvatar = !isUndefined(avatar)

  return (
    <div css={STYLES.value}>
      {(icon || hasAvatar) && (
        <div css={STYLES.valueGraphic}>
          {icon && <Icon icon={icon} size={16} />}
          {hasAvatar && <Avatar src={avatar} initials={label} size={20} />}
        </div>
      )}

      <div className="text-blue-500 font-[500]">{label}</div>
    </div>
  )
}

const SelectorOverlay = (props: any) => {
  const { onClose, onSelect } = props

  const handleSelect = (record: any) => {
    onSelect?.(record)
    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop position="right" maxWidth={80} onClose={onClose}>
      <Overlay.Header title="Assign Assessment To…" icon="web_form" />

      <Overlay.Content className="!bg-white flex flex-nowrap">
        <EmployeesTable minimal onSelect={handleSelect} />
      </Overlay.Content>
    </Overlay>
  )
}

const STYLES = {
  trigger: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.95rem',
    cursor: 'pointer',
  },

  triggerLabel: {
    fontWeight: 600,
    marginRight: '0.5rem',
    display: 'inline-flex',
  },

  triggerEmpty: {
    color: COLORS.blue,
  },

  triggerTriangle: {
    marginLeft: '0.25rem',
  },

  value: {
    display: 'flex',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    lineHeight: 1,
  },

  valueGraphic: {
    marginRight: '0.35rem',
  },
}

export const DataFormAssignmentSelector = withFormContext(RootDataFormAssignmentSelector)
