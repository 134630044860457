import React from 'react'
import { useMedia } from 'use-media'

import { COLORS, BREAKPOINT, TOKENS, ICONS } from '../theme'

import Dropdown from './Dropdown'
import Glyph from './Glyph'
import Icon from './Icon'
import Tooltip from './Tooltip'

import { Icon as IconType } from '../declarations/types'

type Props = {
  icon: IconType
  label: string
  description: string
} & React.HTMLAttributes<any>

const AppNavDropdown: React.FC<Props> = ({ icon, label, description, children }) => {
  const isCompact = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavCompactBreakpoint] })
  const isExpanded = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavExpandBreakpoint] })

  const show = isCompact && !isExpanded

  return (
    <Tooltip
      position="right"
      className="!w-full !block"
      color={ICONS[icon]?.color}
      show={show}
      content={<span className="!font-[500]">{label}</span>}
    >
      <Dropdown
        closeOnMenuClick={false}
        trigger={
          <div css={styles.root} className={show ? 'is-compact' : ''}>
            {icon && <Icon icon={icon} css={styles.icon} size={20} />}
            {!show && (
              <>
                <div css={styles.text}>
                  <div css={styles.label}>{label}</div>
                  <div css={styles.description}>{description}</div>
                </div>
                <Glyph glyph="triangle_down" size={12} css={styles.triangle} />
              </>
            )}
          </div>
        }
      >
        {children}
      </Dropdown>
    </Tooltip>
  )
}

const styles: { root: any; icon: any; text: any; label: any; description: any; triangle: any } = {
  root: {
    ...TOKENS.wrapperPadding,

    display: 'flex',
    alignItems: 'center',

    paddingTop: '0.5rem !important',
    paddingBottom: '0.5rem !important',
    borderBottom: `1px solid ${COLORS.divider}`,

    fontWeight: 600,

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-compact': {
      justifyContent: 'center',

      img: {
        marginRight: 0,
      },
    },
  },

  icon: {
    marginRight: '0.75em',
  },

  text: {
    paddingRight: '0.5rem',
  },

  label: {
    maxWidth: 140,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  description: {
    maxWidth: 140,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: COLORS.textMuted,
    fontSize: '0.8em',
    fontWeight: 400,
  },

  triangle: {
    marginLeft: 'auto',
  },
}

export default AppNavDropdown
