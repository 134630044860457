import React from 'react'
import { useMedia } from 'use-media'
import produce from 'immer'
import startCase from 'lodash/startCase'

import { COLORS } from '../../theme'
import { address } from '../../utils/functions'
import { getPrefix, useUpdate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import PropertyQuickView from '../Overlays/quick/PropertyQuickView'
import { usePermissions } from '../../hooks/usePermissions'

const CATEGORY = {
  housing: 'Housing',
  office: 'Office',
  mixed_category: 'Mixed (Housing & Office)',
}

const mainColumn = (to, mainLinkAs) => ({
  width: 240,
  isSticky: true,
  canToggleVisible: false,
  accessor: 'name',
  Header: 'Name',
  Cell: ({ value, row }: any) => {
    const hasParent = !!row.original.parent_id
    return <TableCell.MainLink as={mainLinkAs} isIndented={hasParent} to={to(row.original.id)} label={value} avatar={row.original.avatar} />
  },
})

const tagsColumn = (apiKey: any) => ({
  width: 200,
  Header: 'Tags',
  accessor: 'tags',
  disableFilters: true,
  Cell: ({ value, row }: any) => {
    const { mutateAsync, isLoading }: any = useUpdate({
      name: ['location', row.original.id],
      url: `/houses/${row.original.id}`,
      invalidateKeys: ['locations', 'tag-live-reports'],
      onSuccess: (_data: any, variables: any, queryClient: any) => {
        const prefix = getPrefix()

        queryClient.setQueryData([prefix, apiKey].flat(), (oldData: any) => {
          if (!oldData?.data) return

          const index = oldData.data.findIndex((o) => o.id === row.original.id)
          if (index === -1) return

          const newData = produce(oldData, (draft: any) => {
            draft.data[index].tags = variables.tags
          })

          return newData
        })
      },
    })

    return (
      <TableCell.Tags
        value={value}
        save={(tagIDs: any, tags: any) => mutateAsync({ tag_ids: tagIDs, tags })}
        isLoading={isLoading}
        smartCategories="locations"
      />
    )
  },
})

const columns = (to: Function = () => {}, isDesktop: boolean, isPortal: boolean, apiKey: any, showCommunity: boolean, mainLinkAs) => [
  ...(isDesktop || isPortal ? [mainColumn(to, mainLinkAs)] : []),
  {
    width: isDesktop ? 160 : 240,
    disableFilters: true,
    disableSortBy: true,
    id: 'quick_view',
    accessor: 'id',
    Header: 'Quick View',
    Cell: ({ row }: any) => (
      <TableCell.QuickViewOverlay overlay={<PropertyQuickView property={row.original} />}>
        {!isDesktop && <TableCell.MainLink label={row.original.name} avatar={row.original.avatar} glyphAfter="quick_view" />}
      </TableCell.QuickViewOverlay>
    ),
  },
  ...(apiKey ? [tagsColumn(apiKey)] : []),
  {
    width: 160,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ value }: any) => <TableCell value={CATEGORY[value]} />,
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 100,
    accessor: 'gender',
    Header: 'Accepted Gender',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'male',
        label: 'Male',
      },
      {
        value: 'female',
        label: 'Female',
      },
      {
        value: 'decline_to_respond',
        label: 'Decline To Respond',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  {
    width: 180,
    accessor: 'is_shared_with_portal',
    Header: 'Client Portal Sharing',
    Cell: ({ value }: any) => {
      if (!value) return 'Not shared'
      return <TableCell.GlyphValue glyph="portal" value="Shared" />
    },
    Filter: TableFilter.Select,
    filter: 'boolean',
    filterOptions: [
      {
        value: true,
        label: 'Shared',
      },
      {
        value: false,
        label: 'Not shared',
      },
    ],
  },
  {
    width: 240,
    accessor: 'address',
    Header: 'Address',
    Cell: ({ value }: any) => <TableCell.Truncated value={address(value)} />,
  },
]

const PropertiesTable = (props: any) => {
  const {
    apiKey,
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    isLoading,
    localStorageKey,
    title,
    titleAfter,
    to,
    testKey,
    ...rest
  } = props

  const isDesktop = useMedia({ minWidth: 600 })
  const { isPortal, isBehave } = useSettings()
  const { allowed: showCommunity } = usePermissions({ featureFlag: 'community' })

  return (
    <Table
      testKey={testKey}
      showExpandColumn
      subExpandKey="houses"
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, isDesktop, isPortal, apiKey, showCommunity && isBehave, props.mainLinkAs)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      {...rest}
      localStorageKey={localStorageKey}
    />
  )
}

PropertiesTable.defaultProps = {
  title: 'Locations',
  icon: 'properties',
  emptyDescription: 'No locations added yet',
  localStorageKey: 'properties',
}

export default PropertiesTable
