import React from 'react'

const MESSAGES: any = {
  create: (props: any) => (
    <>
      {props.author} added the {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
  add_clients: (props: any) => (
    <>
      {props.author} added new clients to the {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
  update: (props: any) => (
    <>
      {props.author} updated the {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
  destroy: (props: any) => (
    <>
      {props.author} deleted the {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
}

export default (props: any) => MESSAGES[props.event?.action_type]?.(props) || null
