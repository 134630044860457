import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  pending_review: {
    label: 'Pending Review',
    color: 'orange',
  },
  rejected: {
    label: 'Rejected',
    color: 'red',
  },
  signed_off: {
    label: 'Signed-Off',
    color: 'green',
  },
}

const Status = ({ setLabel, status, ...rest }) => {
  return (
    <SmartStatus
      statuses={statuses}
      status={status}
      label={setLabel ? setLabel(statuses[status]?.label) : statuses[status]?.label}
      {...rest}
    />
  )
}

export default Status
