import React from 'react'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

import { INSURANCE_REPORT_STATUSES } from '../constants'

export const InsuranceReportStatusSelect = ({ isEditable, label, model, validations, onUpdate, value, defaultValue = 'draft' }: any) => (
  <Select
    fullWidth
    allowEmpty
    isEditable={isEditable}
    label={label}
    model={model}
    validations={validations}
    onUpdate={onUpdate}
    value={value}
    defaultValue={defaultValue}
  >
    {Object.keys(INSURANCE_REPORT_STATUSES).map((key: any) => {
      if (key === 'draft') return null

      return <Option key={key} label={INSURANCE_REPORT_STATUSES[key].label} value={key} />
    })}
  </Select>
)

InsuranceReportStatusSelect.defaultProps = {
  label: 'Status',
  model: 'status',
}
