import React from 'react'

import withSettings from '../../hocs/withSettings'

import Glyph from '../Glyph'
import ClientFlowStatus from '../Statuses/ClientFlowStatus'
import CardLink from '../CardLink'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'
import { CARD_VARIANTS } from '../Card'

const statuses = {
  completed: {
    color: 'green',
    glyph: 'check',
  },
  in_progress: {
    color: 'paleBlue',
    glyph: 'in_progress',
  },
  todo: {
    color: 'divider',
    glyph: 'more_horizontal',
  },
  overdue: {
    color: 'red',
    glyph: 'circle_error',
  },
  dev: {
    color: 'gray',
    glyph: 'behave_health',
  },
}

type Props = {
  testKey: string
  step: any
  to: any
}

const ClientFlowStep = ({ testKey, step, to, icon }: Props) => {
  if (!step) return null

  return (
    <CardLink
      to={to}
      testKey={testKey}
      graphic={<Glyph glyph={statuses[step?.status]?.glyph} size={20} />}
      variant={CARD_VARIANTS.LIST}
      paddingY="0.75rem"
      showChevron
    >
      <CardTitle title={step.title} after={<ClientFlowStatus status={step?.status} />} />
      <CardSubtitle subtitle={step.description} />
    </CardLink>
  )
}

export default withSettings(ClientFlowStep)
