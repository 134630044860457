import React from 'react'

import withSettings from '../../../hocs/withSettings'

import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Workflow from '../../../components/Workflow/Workflow'

const AdmissionDetails = ({ data, setData, setStatus, timezone }: any) => {
  const form = React.useRef()
  const [valid, setValid] = React.useState()

  const { goNext }: any = React.useContext(Workflow.Context)

  React.useEffect(() => {
    valid ? setStatus('completed') : setStatus('todo')
  }, [valid])

  return (
    <Form
      useLocalModel
      getForm={form}
      initialModel={data}
      onUpdate={setData}
      onValidationUpdate={setValid}
      timezone={timezone}
      defaultDebounce={300}
    >
      <FormSection>
        <DateTimeInput
          defaultToNow
          label="Admit Date"
          model="admitted_at"
          validations={{
            presence: {
              message: 'Please select an Admit Date',
            },
          }}
        />

        <DateTimeInput
          defaultInOneMonth
          label="Est. Discharge Date"
          model="estimated_discharge"
          validations={{
            presence: {
              message: 'Please select an Est. Discharge Date',
            },
          }}
        />

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={goNext} isDisabled={!valid} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default withSettings(AdmissionDetails)
