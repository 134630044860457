import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { CLINICAL_MEASURES_SLUGS } from '@behavehealth/utils/constants'

import { ClinicalMeasuresReportDataTable } from '@behavehealth/constructs/LiveReports/ClinicalMeasuresReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ClinicalMeasures = () => {
  const tableProps = useDataTable({
    name: ['reports', 'clinical-measures'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_clinical_measures_v1',
    params: { category: 'clinical_measures' },
  })

  const to = React.useMemo(
    () => (rowData: any) =>
      `${getResourceLink(rowData.client)}/clinical-measurements/${CLINICAL_MEASURES_SLUGS?.[rowData?.subcategory]}/${rowData.id}`,
    [],
  )

  return (
    <Page feature="clinical_measurements" title="Live Clinical Measures Report">
      <Grid>
        <ClinicalMeasuresReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ClinicalMeasures)
