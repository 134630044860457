import React from 'react'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useAPI } from '@behavehealth/hooks'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Card, Chotomate, Dropdown, DropdownItem, Page, HelpTagIframe } from '@behavehealth/components'
import { IntakeFormsTable } from '@behavehealth/components/Tables'

const INTAKES = {
  intake_form: 'intake-form',
  sober_living_home_agreement: 'sober-living-home-agreement',
}

const IntakeForms = ({ match }) => {
  const data = useSelector((state) => state.data.data_forms?.data)
  const loading = useSelector((state) => state.data.data_forms?.loading)

  useAPI('data_forms', `/residents/${match.params?.resource_id}/data_forms?category=intake_form`)

  const isEmpty = size(data) === 0

  const formsDropdown = (
    <Dropdown label="Add New…" buttonType="primary" glyph="add" position="bottom">
      <DropdownItem
        label="Intake Form"
        icon="intake_forms"
        color="green"
        link={{
          pathname: `${match.url}/intake-form/new`,
          parent: match,
        }}
      />
      <DropdownItem
        label="Sober Living Home Agreement"
        icon="intake_forms"
        color="green"
        link={{
          pathname: `${match.url}/sober-living-home-agreement/new`,
          parent: match,
        }}
      />
    </Dropdown>
  )

  return (
    <Page feature="intake_forms" actions={!isEmpty && formsDropdown} help={<HelpTagIframe id="intake_forms" />}>
      <Chotomate name="intake_forms" ready={!loading} />
      <Card>
        <IntakeFormsTable
          data={data}
          isLoading={isEmpty && loading}
          emptyActions={formsDropdown}
          localStorageKey="clients_intake_forms"
          to={(id, subcategory) => ({
            pathname: `${match.url}/${INTAKES[subcategory]}/${id}`,
            parent: match,
          })}
        />
      </Card>
    </Page>
  )
}

export default withPageError(IntakeForms)
