import React from 'react'
import { connect } from 'react-redux'

import Button from '../../Button'
import Chotomate from '../../Chotomate'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Overlay from '../../Overlay'
import DateInput from '../../Forms/DateInput'
import Select from '../../Forms/Select'
import Option from '../../Forms/Option'
import Input from '../../Forms/Input'
import Section from '../../Section'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class PayerEVOBOverlay extends OverlayBase {
  onSaveSuccessful = () => {
    if (this.props.onSaveSuccessful) this.props.onSaveSuccessful()
  }

  renderHeader = () => {
    return <Overlay.Header icon="financials" title="EVOB" />
  }

  renderContent = () => {
    const { $editable, params } = this.state
    const { record, timezone } = this.props

    return (
      <Overlay.Content>
        <Chotomate ready name="rule_overlay" />

        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          timezone={timezone}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
        >
          <Section>
            <FormSection>
              <DateInput model="created_at" label="Date Requested" />

              <Select label="Status" model="status">
                <Option label="Proccessed" value="processed" />
                <Option label="Proccessing" value="processing" />
                <Option label="Issue" value="issue" />
              </Select>

              <Select label="Coverage Status" model="coverage_status">
                <Option label="Active" value="active" />
                <Option label="Inactive" value="inactive" />
              </Select>

              <DateInput model="date_of_service" label="Date of Service" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'evobs')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'evobs')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(PayerEVOBOverlay))
