import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { CareTeamTable } from '@behavehealth/components/Tables'
import { Chotomate, Button, Card, Page, Grid, HelpTagIframe } from '@behavehealth/components'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

const pageConfig = {
  feature: 'care_team',
  help: <HelpTagIframe id="client_care_team" />,
  marketingID: 'client_care_team',
}

type Props = {}

const CareTeam: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const [filters, setFilters] = React.useState({})

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'connections', { filters }],
    url: `/residents/${resource_id}/connections`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const { mutate: deleteConnections } = useDelete({
    name: ['client', resource_id, 'connections'],
    url: '/connections',
    invalidate: 'connections',
  })

  const isEmpty = size(data) === 0

  const actions = (
    <Button
      glyph="add"
      type="primary"
      label="Add Connection"
      link={{
        pathname: `${match.url}/new`,
        data: {
          residents: [client],
        },
      }}
      permission="connections.create"
    />
  )

  return (
    <Page emptyDescription="There are no connections created yet" actions={!isEmpty && canCreate && actions} {...pageConfig}>
      <Grid gap="1rem">
        <Chotomate name="care_team" ready={!isLoading} />

        <Filters config={FILTERS.client_connections} onUpdate={setFilters} localStorageKey="client_connections" />

        <Card>
          <CareTeamTable
            data={data}
            isLoading={isEmpty && isLoading}
            emptyActions={canCreate && actions}
            localStorageKey="clients_care_team"
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'connections.delete',
                action: async ({ ids }: any) => {
                  await deleteConnections(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CareTeam, pageConfig))
