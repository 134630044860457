import React from 'react'
import { Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Tenant } from '@behavehealth/declarations'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Page, Grid, Tab, TabList, Tabs, Uploader, HelpTagIframe } from '@behavehealth/components'

import Billing from './billing'
import ClientPortal from './client_portal'
import General from './general'

const pageConfig = {
  feature: 'files',
  help: <HelpTagIframe id="company_files" />,
  marketingID: 'company_files',
}

const Files: React.FC = () => {
  const tenant: Tenant = useSelector((state: any) => state.me?.tenant)
  const permissions = useSelector((state: any) => state.me.permissions)
  const isBilling = tenant?.category === 'billing'

  const { pathname } = useLocation()
  const { url } = useRouteMatch()

  let category = 'general'
  if (pathname.includes(`${url}/billing`)) {
    category = 'billing'
  } else if (pathname.includes(`${url}/client-portal`)) {
    category = 'client_portal'
  }

  return (
    <Page
      breakpoint="0"
      actions={
        <Uploader category={category} resource={tenant}>
          <Button type="primary" glyph="upload" label="Upload Files" permission="companies.files.create" />
        </Uploader>
      }
      {...pageConfig}
    >
      <Tabs>
        {permissions?.files?.view === 'all' && (
          <TabList>
            <Tab label="General" to={`general`} />
            {isBilling && <Tab label="Billing" to={`billing`} />}
            <Tab label="Client Portal" to={`client-portal`} />
          </TabList>
        )}
      </Tabs>

      <Grid gap="1rem">
        <Switch>
          <Route path={`${url}/general`} component={General} />
          {permissions?.files?.view === 'all' && isBilling && <Route path={`${url}/billing`} component={Billing} />}
          <Route path={`${url}/client-portal`} component={ClientPortal} />

          <Redirect exact from={url} to={`${url}/general`} />
        </Switch>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Files, pageConfig))
