import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

import { COLORS } from '@behavehealth/theme'
import { mapToArray } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useAPI, { useAPIwithInitialClear } from '@behavehealth/hooks/useAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import {
  Button,
  Card,
  Chotomate,
  Divider,
  Dropdown,
  DropdownItem,
  Glyph,
  HelpTagIframe,
  Page,
  PageLayout,
  PageSection,
} from '@behavehealth/components'

import { AgreementsTable, SigneesTable } from '@behavehealth/components/Tables'

const AddDropdown = ({ templates }: any) => {
  const match = useRouteMatch()

  return (
    <Dropdown label="Add Agreement…" glyph="add" buttonType="primary" buttonSize={300} permission="organization_agreements.create">
      <DropdownItem
        label="Empty Agreement"
        glyph="add"
        color="paleBlue"
        link={{
          pathname: `${match.url}/new`,
          parent: match,
          data: {
            name: 'New Agreement',
          },
        }}
      />

      <Divider />

      {sortBy(mapToArray(templates), 'order', 'created_at').map((template) => (
        <DropdownItem
          key={template.id}
          label={template.name}
          icon="legal_agreement_alt"
          color="paleBlue"
          link={{
            pathname: `${match.url}/new`,
            parent: match,
            data: template,
          }}
        />
      ))}
    </Dropdown>
  )
}

const pageConfig = {
  feature: 'organization_agreements',
  help: <HelpTagIframe id="agreements" />,
}

type Props = {
  canCreate: boolean
}

const Agreements: React.FC<Props> = ({ canCreate = true }) => {
  const templates = useSelector((state) => state.data.agreement_templates?.data)
  const templatesLoading = useSelector((state) => state.data.agreement_templates?.loading)
  const templatesEmpty = size(templates) === 0
  const match = useRouteMatch()

  const agreements = useSelector((state) => state.data.agreements?.data)
  const agreementsLoading = useSelector((state) => state.data.agreements?.loading)
  const agreementsEmpty = size(agreements) === 0

  const signees = useSelector((state) => state.data.signees?.data)

  useAPIwithInitialClear('agreements', `/organizations/${match.params?.resource_id}/agreements?variant=organization`)
  useAPI('agreement_templates', '/agreement_templates?variant=organization')
  useAPI('signees', `/organizations/${match.params?.resource_id}/signees`)

  if (!templatesLoading && templatesEmpty) {
    return (
      <>
        <Chotomate ready name="agreements" />
        <Page
          isEmpty={true}
          emptyDescription="No agreement templates have been added yet. Go to Settings to create the first template."
          actions={
            <Button
              size={300}
              label="Manage Agreement Templates"
              glyph="settings"
              type="primary"
              link="/settings/agreement-templates/organizations"
              permission="settings.agreement_templates.edit"
            />
          }
          {...pageConfig}
        />
      </>
    )
  }

  return (
    <Page
      feature="agreements"
      plan="basic"
      isLoading={agreementsEmpty && agreementsLoading}
      isEmpty={agreementsEmpty}
      emptyDescription="No agreements have been added yet"
      help={<HelpTagIframe id="agreements" />}
      actions={
        <>
          <Button
            size={300}
            css={agreementsEmpty && { order: 1 }}
            glyph="settings"
            type="default"
            label="Manage Templates"
            link="/settings/agreement-templates/organizations"
            permission="settings.agreement_templates.edit"
          />

          <AddDropdown templates={templates} />
        </>
      }
    >
      <PageLayout>
        <PageSection>
          <PageSection.Header
            graphic={<Glyph glyph="user_group" color={COLORS.blue} />}
            after={
              !agreementsEmpty &&
              canCreate && (
                <Button
                  label="Notify Signees"
                  glyph="signature"
                  type="default"
                  link={{
                    pathname: `${match.url}/send-agreements`,
                    parent: match,
                  }}
                  size={300}
                />
              )
            }
          >
            <PageSection.Title title="Signees" />
          </PageSection.Header>

          <PageSection.Content>
            <Card>
              <SigneesTable data={signees} localStorageKey="clients_signees" />
            </Card>
          </PageSection.Content>
        </PageSection>

        <PageSection>
          <PageSection.Header graphic={<Glyph glyph="legal_history" color={COLORS.orange} />}>
            <PageSection.Title title="Agreements" />
          </PageSection.Header>

          <PageSection.Content>
            <Card>
              <Chotomate ready name="organization_agreements" />

              <AgreementsTable
                data={agreements}
                testKey="organization_agreements_table"
                to={(id: string) => ({
                  pathname: `${match.url}/${id}`,
                  parent: match,
                })}
                localStorageKey="organization_agreements"
              />
            </Card>
          </PageSection.Content>
        </PageSection>
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(Agreements, pageConfig))
