import React from 'react'

import { usDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Button from '../Button'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ row, value }: any) => <TableCell.MainLink icon={row.original.icon} to={to(row.original.id)} label={value} />,
  },
  {
    width: 200,
    accessor: 'description',
    Header: 'Description',
  },
  {
    accessor: 'addons_count',
    Header: 'Addons Count',
  },
  {
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell value={usDate(value, timezone)} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    id: 'author',
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
  {
    id: 'actions',
    Header: 'Actions',
    Cell: () => <Button label="Archive" glyph="empty" color="gray" size={200} />,
  },
]

type Props = {
  data: Object
  hiddenColumns?: string[]
  isLoading: boolean
  isSelectable: boolean
  localStorageKey: string
  timezone: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const BillingTiersTable = (props: Props) => {
  const { to, hiddenColumns, data, isLoading, localStorageKey, batchActionsConfig, timezone, titleAfter } = props

  return (
    <Table
      title="Add-On Tiers"
      titleAfter={titleAfter}
      icon="financials"
      data={data}
      hiddenColumns={hiddenColumns}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyDescription="No add-on tiers added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

BillingTiersTable.defaultProps = {
  localStorageKey: 'billing_tiers',
}

export default withSettings(BillingTiersTable)
