import React from 'react'

import { UATests } from '../../../utils/constants'

import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import SmartTextarea from '../../../components/Forms/SmartTextarea'

import {
  CIWA_SYMPTOMS_FIELDS,
  COWS_SYMPTOMS_FIELDS,
  MEDICAL_EVALUATION_FIELDS,
  MENTAL_STATUS_EXAM_FIELDS,
  PAST_MEDICAL_HISTORY_FIELDS,
  TREATMENT_HISTORY_FIELDS,
  VITAL_SIGNS_FIELDS,
} from './common'

export const NURSING_ASSESSMENT_SECTIONS = [
  {
    title: 'Current Diagnosis',
    model: 'current_diagnosis',
    fields: [
      {
        label: 'Current Diagnosis',
        model: 'current_diagnosis',
        component: () => (
          <>
            <CheckboxGroup label="Current Diagnosis:" layout="vertical-dense">
              <Checkbox label="F10.20 Alcohol use disorder, severe" model="data.current_diagnosis.f10_20" />
              <Checkbox label="F11.20 Opioid use disorder, severe" model="data.current_diagnosis.f11_20" />
              <Checkbox label="F12.20 Cannabis use disorder, severe" model="data.current_diagnosis.f12_20" />
              <Checkbox label="F13.20 Sedative, hypnotic or anxiolytic disorder, severe" model="data.current_diagnosis.f13_20" />
              <Checkbox label="F14.20 Cocaine use disorder, severe" model="data.current_diagnosis.f14_20" />
              <Checkbox label="F15.20 Other stimulant use disorder, severe" model="data.current_diagnosis.f15_20" />
              <Checkbox label="F16.20 Hallucinogen use disorder, severe" model="data.current_diagnosis.f16_20" />
              <Checkbox label="F17.20 Nicotine dependence" model="data.current_diagnosis.f17_20" />
              <Checkbox label="F18.20 Inhalant related disorder, severe" model="data.current_diagnosis.f18_20" />
              <Checkbox label="F19.20 Other psychoactive substance dependence, severe" model="data.current_diagnosis.f19_20" />
              <Checkbox label="Other" model="data.current_diagnosis.other" />
            </CheckboxGroup>

            <ContextShow when="data.current_diagnosis.other" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please specify:"
                model="data.current_diagnosis.other_extra"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Advance Directive/Power of Attorney',
    model: 'advance_directive_poa',
    fields: [
      {
        label: 'Advance Directive',
        model: 'advance_directive',
        component: () => (
          <>
            <RadioGroup label="Advance Directive" model="data.advance_directive_poa.advance_directive" layout="vertical-dense">
              <Radio label="Has Living Will" value="has_living_will" />
              <Radio label="No Advance Directive" value="no_advance_directive" />
              <Radio label="Requests assistance with Advance Directive" value="requests_assistance" />
              <Radio label="Declines assistance with Advance Directive" value="declines_assistance" />
            </RadioGroup>

            <ContextShow when="data.advance_directive_poa.advance_directive" is="has_living_will">
              <RadioGroup
                label="Confirm copy of living will is in the chart"
                model="data.advance_directive_poa.have_copy_of_living_will"
                layout="vertical-dense"
              >
                <Radio label="Yes, copy of living will is in the chart" value={true} />
                <Radio label="No, copy of living will is NOT in the chart" value={false} />
              </RadioGroup>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Does the client have a Medical Power of Attorney?',
        model: 'medical_poa',
        component: () => (
          <RadioGroup
            label="Does the client have a Medical Power of Attorney?"
            model="data.advance_directive_poa.medical_poa"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>
        ),
      },
      {
        label: 'Does the client have a Mental Health Power of Attorney?',
        model: 'mental_poa',
        component: () => (
          <RadioGroup
            label="Does the client have a Mental Health Power of Attorney?"
            model="data.advance_directive_poa.mental_poa"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>
        ),
      },
    ],
  },
  {
    title: 'Vital Signs',
    model: 'vital_signs',
    fields: VITAL_SIGNS_FIELDS,
  },
  {
    title: 'Test Results',
    model: 'test_results',
    fields: [
      {
        label: 'Urine Drug Screen Result',
        model: 'ua_results',
        component: () => (
          <>
            <RadioGroup label="Urine Drug Screen Result" model="data.test_results.ua_results" layout="horizontal">
              <Radio label="Negative" value="negative" />
              <Radio label="Positive" value="positive" />
            </RadioGroup>

            <ContextShow when="data.test_results.ua_results" is="positive">
              <CheckboxGroup label="Drugs:" layout="vertical-dense">
                {UATests.map((test) => (
                  <Checkbox label={test.name} model={`data.test_results.${test.model}`} />
                ))}
              </CheckboxGroup>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Pregnancy Test',
        model: 'pregnancy_test',
        component: () => (
          <>
            <RadioGroup label="Pregnancy Test" model="data.test_results.pregnancy_test" layout="vertical-dense">
              <Radio label="Negative" value="negative" />
              <Radio label="Positive" value="positive" />
              <Radio label="N/A" value="n/a" />
            </RadioGroup>
          </>
        ),
      },
    ],
  },
  {
    title: 'Medical History',
    model: 'past_medical_history',
    fields: PAST_MEDICAL_HISTORY_FIELDS,
  },
  {
    title: 'Medical Evaluation',
    model: 'medical_evaluation',
    fields: MEDICAL_EVALUATION_FIELDS,
  },
  {
    title: 'Coordination of Care',
    model: 'coordination_of_care',
    fields: [
      {
        label: 'Please list any other providers you would like to be involved with your care',
        model: 'providers',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="Please list any other providers the client would like to be involved with their care:"
            model="data.coordination_of_care.providers"
          />
        ),
      },
      {
        label: 'Is the client in need of a special diet? (Religious, vegan, etc.)',
        model: 'special_diet',
        component: () => (
          <>
            <RadioGroup
              label="Is the client in need of a special diet? (Religious, vegan, etc.)"
              model="data.coordination_of_care.special_diet"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.coordination_of_care.special_diet" is={true}>
              <SmartTextarea useQuickText useDictation label="Please explain:" model="data.coordination_of_care.special_diet_extra" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Does the client have any other special needs?',
        model: 'special_needs',
        component: () => (
          <>
            <RadioGroup
              label="Does the client have any other special needs?"
              model="data.coordination_of_care.special_needs"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.coordination_of_care.special_needs" is={true}>
              <SmartTextarea useQuickText useDictation label="Please explain:" model="data.coordination_of_care.special_needs_extra" />
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Prosthesis / Appliance Inventory',
    model: 'prosthesis_appliance_inventory',
    fields: [
      {
        label: 'Contacts',
        model: 'contacts',
        component: () => (
          <>
            <RadioGroup label="Contacts" model="data.prosthesis_appliance_inventory.contacts" layout="horizontal-dense">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.prosthesis_appliance_inventory.contacts" is={true}>
              <RadioGroup
                label="Are contacts with patient?"
                model="data.prosthesis_appliance_inventory.contacts_extra"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Glasses',
        model: 'glasses',
        component: () => (
          <>
            <RadioGroup label="Glasses" model="data.prosthesis_appliance_inventory.glasses" layout="horizontal-dense">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.prosthesis_appliance_inventory.glasses" is={true}>
              <RadioGroup
                label="Are glasses with patient?"
                model="data.prosthesis_appliance_inventory.glasses_extra"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Hearing aid',
        model: 'hearing_aid',
        component: () => (
          <>
            <RadioGroup label="Hearing aid" model="data.prosthesis_appliance_inventory.hearing_aid" layout="horizontal-dense">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.prosthesis_appliance_inventory.hearing_aid" is={true}>
              <RadioGroup
                label="Are hearing aids with patient?"
                model="data.prosthesis_appliance_inventory.hearing_aid_extra"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Cane / walker / crutches',
        model: 'cane',
        component: () => (
          <>
            <RadioGroup label="Cane / walker / crutches" model="data.prosthesis_appliance_inventory.cane" layout="horizontal-dense">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.prosthesis_appliance_inventory.cane" is={true}>
              <RadioGroup
                label="Are cane / walker / crutches with patient?"
                model="data.prosthesis_appliance_inventory.cane_extra"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Dentures / bridge / partial',
        model: 'dentures',
        component: () => (
          <>
            <RadioGroup label="Dentures / bridge / partial" model="data.prosthesis_appliance_inventory.dentures" layout="horizontal-dense">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.prosthesis_appliance_inventory.dentures" is={true}>
              <RadioGroup
                label="Are dentures / bridge / partial with patient?"
                model="data.prosthesis_appliance_inventory.dentures_extra"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Treatment History',
    model: 'treatment_history',
    fields: TREATMENT_HISTORY_FIELDS,
  },
  {
    title: 'Mental Status Exam',
    model: 'mental_status_exam',
    fields: MENTAL_STATUS_EXAM_FIELDS,
  },
  {
    title: 'COWS Symptoms',
    model: 'cows_symptoms',
    fields: COWS_SYMPTOMS_FIELDS,
  },
  {
    title: 'CIWA Symptoms',
    model: 'ciwa_symptoms',
    fields: CIWA_SYMPTOMS_FIELDS,
  },
  {
    title: 'Summary',
    model: 'summary',
    fields: [
      {
        label: 'Interpretive Summary',
        model: 'interpretive_summary',
        component: () => <SmartTextarea useQuickText useDictation label="Interpretive Summary" model="data.summary.interpretive_summary" />,
      },
      {
        label: 'Additional Relevant Information',
        model: 'additional_relevant_info',
        component: () => (
          <SmartTextarea useQuickText useDictation label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
        ),
      },
      {
        label: 'Recommendations',
        model: 'recommendations',
        component: () => <SmartTextarea useQuickText useDictation label="Recommendations" model="data.summary.recommendations" />,
      },
    ],
  },
]
