import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'
import get from 'lodash/get'

import Attachments from '../../../Forms/Attachments'
import Button from '../../../Button'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import ConfirmDialog from '../../../Dialogs/ConfirmDialog'
import ContextShow from '../../../Forms/ContextShow'
import DataFormStatus from '../../../Statuses/DataFormStatus'
import DateTimeInput from '../../../Forms/DateTimeInput'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Dialog from '../../../Dialog'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import HelpTagIframe from '../../../Help/HelpTagIframe'
import Input from '../../../Forms/Input'
import MultiObjectSelector from '../../../Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import OverlaySelector from '../../../Forms/Selectors/OverlaySelector/OverlaySelector'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import Permission from '../../../Permission'
import Section from '../../../Section'
import SignatureDialog from '../../../Dialogs/SignatureDialog'
import SignaturePad from '../../../Forms/SignaturePad'
import StaffProfileHeader from '../../../StaffProfileHeader'
import Textarea from '../../../Forms/Textarea'
import TextareaDialog from '../../../Dialogs/TextareaDialog'
import Timeline from '../../../Timeline/Timeline'
import TooltipButton from '../../../TooltipButton'
import MultiOverlaySelector from '../../../Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import CustomNoteSections from '../../../Elements/CustomNoteSections'

import { ICONS } from '../../../../theme'
import { address } from '../../../../utils/functions'
import { apiUpdate } from '../../../../modules/api'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../OverlayBase'

import { ExportPDFButton } from '../../../Buttons/ExportPDFButton'

class CustomNoteOverlay extends OverlayBase {
  onPreviewOpen = () => {
    this.setState({ formData: this.form.current?.getFormValue() })
  }

  onDisabledClick = () => {
    this.form.current.validate()
  }

  updateStatusTo = async (status) => {
    await apiUpdate({
      name: 'custom_notes',
      url: `/custom_notes/${this.props.record.id}`,
      params: {
        status: status,
      },
    })

    this.setState({ $editable: false })
  }

  sendForSupervisorReview = async (signature) => {
    await apiUpdate({
      name: 'custom_notes',
      url: `/custom_notes/${this.props.record.id}`,
      params: {
        [signature.model]: signature.value,
      },
    })

    this.setState({ $editable: false })
  }

  sendForSupervisorReviewWithoutSignature = async () => {
    await apiUpdate({
      name: 'custom_notes',
      url: `/custom_notes/${this.props.record.id}`,
      params: {
        status: 'pending_review',
      },
    })

    this.setState({ $editable: false })
  }

  requestUpdates = async (updates) => {
    await apiUpdate({
      name: 'custom_notes',
      url: `/custom_notes/${this.props.record.id}`,
      params: {
        status: 'updates_required',
        request_updates: updates.value,
      },
    })

    this.setState({ $editable: false })
  }

  signOff = async (signature) => {
    await apiUpdate({
      name: 'custom_notes',
      url: `/custom_notes/${this.props.record.id}`,
      params: {
        [signature.model]: signature.value,
      },
    })

    this.setState({ $editable: false })
  }

  signOffWithoutSignature = async () => {
    await apiUpdate({
      name: 'custom_notes',
      url: `/custom_notes/${this.props.record.id}`,
      params: {
        status: 'signed_off',
      },
    })

    this.setState({ $editable: false })
  }

  render = () => {
    const { $new, $editable, isInvalid, params } = this.state
    const { user, record, timezone, online, loading, tenant } = this.props

    const data = $new ? params : record

    if (!$new && size(data) === 0) {
      return <OverlayLoader position={this.props.position} showBackdrop={this.props.showBackdrop} />
    }

    const isSupervisor = user?.id === record?.supervisor?.id
    const isTrialing = tenant?.plan_status === 'trialing'

    return (
      <Overlay
        position="center"
        isLoading={!record}
        showBackdrop={true}
        maxWidth={82}
        closeWrapper={(element) => (
          <Dialog
            glyph="delete"
            title="Close without saving?"
            message="All changes will be lost. This action cannot be undone."
            yesColor="red"
            yesLabel="Yes, Close Without Saving"
            onYes={this.close}
            skip={!$editable}
          >
            {element}
          </Dialog>
        )}
      >
        <Overlay.Header
          icon="management_department"
          title={record?.name || 'New Account Note'}
          titleAside={<DataFormStatus status={data?.status} />}
          help={<HelpTagIframe id="account_note" />}
        />

        {!this.state.$editable && !isTrialing && (
          <Permission permission="clients.actions.export">
            <Overlay.SubHeader>
              <ExportPDFButton url={`/custom_notes/${this.state.id}/pdf`} />
            </Overlay.SubHeader>
          </Permission>
        )}

        <Overlay.Content>
          <Form
            useFullModel
            getForm={this.form}
            timezone={timezone}
            initialModel={data}
            isEditable={$editable}
            onValidationUpdate={this.onValidationUpdate}
            linked={{
              reference_id: tenant?.id,
              reference_type: tenant?.type,
              variant: 'account',
            }}
          >
            <Section title="Custom Note Details" commentsModel="settings.comments" commentsName="custom_notes" commentsURL="/custom_notes">
              <FormSection maxWidth="100%">
                <Input
                  label="Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a name',
                    },
                  }}
                  className="!grow-[2]"
                />

                <Flex gap={8} alignItems="flex-end">
                  <OverlaySelector
                    isPolymorphic
                    className="!flex-auto"
                    label="Supervisor"
                    blankLabel="Select Supervisor…"
                    icon="employees"
                    type="employees.active"
                    model="supervisor"
                    selectTitle={(data) => data.name}
                    selectDescription={() => null}
                    disableLink={this.isPortal}
                    validations={{
                      presence: {
                        message: 'Please select a Supervisor',
                      },
                    }}
                  />

                  <CheckboxGroup layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                    <Checkbox label="Require Supervisor Signature" model="settings.require_supervisor_signature" />
                  </CheckboxGroup>
                </Flex>

                <Flex gap="1rem" stretchChildrenX>
                  <DateTimeInput
                    defaultToNow
                    model="started_at"
                    label="Start Date and Time"
                    validations={{
                      presence: {
                        message: 'Please enter a date and time',
                      },
                    }}
                  />

                  <DateTimeInput model="ended_at" label="End Date and Time" />
                </Flex>

                <Textarea useQuickText label="Description" model="notes" className="!grow !basis-[300px] !self-start" />
              </FormSection>
            </Section>

            <Divider />

            <CustomNoteSections
              model="sections"
              validations={{
                presence: {
                  message: 'Please add at least one section',
                },
              }}
            />

            <Section headingType="h2" title="Places">
              <FormSection maxWidth="100%" layout="vertical">
                <ObjectSelector
                  model="house"
                  label="Location"
                  type="properties"
                  icon={ICONS.properties}
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => address(data.address)}
                />

                <ObjectSelector
                  model="organization"
                  label="Organization"
                  type="organizations"
                  icon={ICONS.organizations}
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => address(data.address)}
                />
              </FormSection>
            </Section>

            <Divider />

            <Section
              headingType="h2"
              title="Signatures"
              aside={
                <CheckboxGroup layout="horizontal-dense" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox label="Staff Signature" model="settings.require_reference_signature" />
                  <Checkbox label="Co-Signer Signature" model="settings.require_staff_signature" />
                </CheckboxGroup>
              }
            >
              <FormSection layout="vertical" maxWidth={500} className="!mt-4">
                <ContextShow when="settings.require_reference_signature" is={true}>
                  <SignaturePad
                    className="!max-w-[650px]"
                    label="Staff Signature"
                    person={record.reference || this.props.current}
                    model="reference_signature"
                    signedAtModel="reference_signed_at"
                    allowPin={false}
                  />
                </ContextShow>

                <ContextShow when="settings.require_staff_signature" is={true}>
                  <SignaturePad
                    className="!max-w-[650px]"
                    label="Co-Signer Signature"
                    person={record.author}
                    allowPin={false}
                    model="author_signature"
                    signedAtModel="author_signed_at"
                  />
                </ContextShow>

                {record.supervisor_signature && (
                  <SignaturePad
                    className="!max-w-[650px]"
                    label="Supervisor Signature"
                    person={record.supervisor}
                    allowPin={false}
                    model="supervisor_signature"
                    signedAtModel="supervisor_signed_at"
                  />
                )}
              </FormSection>
            </Section>

            <Divider />

            <Section headingType="h2" title="Attachments" description="Upload the files related to this shift note">
              <FormSection maxWidth="100%" layout="vertical">
                <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
              </FormSection>
            </Section>

            {!$new && (
              <>
                <Divider />

                <Section headingType="h2" title="Timeline">
                  <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
                </Section>
              </>
            )}
          </Form>
        </Overlay.Content>

        {record.status !== 'signed_off' && !this.isPortal && (
          <Overlay.Footer online={online}>
            {$editable && (
              <>
                <Button
                  label={$new ? 'Save as Draft' : 'Save Changes'}
                  glyph="check"
                  type="primary"
                  color="green"
                  onClick={this.save}
                  isLoading={loading}
                  isDisabled={loading}
                  flex="100 1 auto"
                  permission="staff_custom_notes.create"
                />

                {isInvalid && (
                  <Button
                    label="Highlight Required Fields"
                    glyph="view"
                    type="default"
                    color="orange"
                    onClick={this.onDisabledClick}
                    isDisabled={loading}
                  />
                )}

                {!$new && <Button glyph="cross" label="Cancel" type="default" isDisabled={loading} onClick={this.cancel} />}
              </>
            )}

            {!$editable && online && (
              <>
                {record.status === 'draft' && (
                  <>
                    <Button
                      glyph="edit"
                      label="Edit Custom Note"
                      type="default"
                      isDisabled={loading}
                      onClick={this.edit}
                      flex="100 1 auto"
                      permission="staff_custom_notes.edit"
                    />

                    <DeleteDialog
                      title="Delete Custom Note?"
                      message={`Are you sure you want to delete this custom note? This action cannot be undone.`}
                      onYes={this.delete}
                    >
                      <Button
                        fullWidth
                        glyph="delete"
                        label="Delete Custom Note…"
                        type="default"
                        color="red"
                        isDisabled={loading}
                        permission="staff_custom_notes.delete"
                      />
                    </DeleteDialog>

                    {!isSupervisor && (
                      <>
                        {data.settings?.require_staff_signature ? (
                          <SignatureDialog
                            model="author"
                            title="Sign & Send for Review"
                            yesLabel="Apply Signature & Send for Review"
                            onYes={this.sendForSupervisorReview}
                            isDisabled={loading || isInvalid}
                            onDisabledClick={this.onDisabledClick}
                          >
                            <TooltipButton
                              fullWidth
                              label="Sign & Send for Supervisor Review…"
                              glyph="signature"
                              type="default"
                              isDisabled={loading || isInvalid}
                              permission="staff_custom_notes.edit"
                              show={isInvalid}
                              message="Click to highlight required fields"
                            />
                          </SignatureDialog>
                        ) : (
                          <ConfirmDialog
                            model="supervisor"
                            title="Send for Supervisor Review"
                            yesLabel="Send for Review"
                            onYes={this.sendForSupervisorReviewWithoutSignature}
                            isDisabled={loading || isInvalid}
                            onDisabledClick={this.onDisabledClick}
                          >
                            <TooltipButton
                              fullWidth
                              label="Send for Supervisor Review…"
                              glyph="check"
                              type="default"
                              isDisabled={loading || isInvalid}
                              permission="staff_custom_notes.edit"
                              show={isInvalid}
                              message="Click to highlight required fields"
                            />
                          </ConfirmDialog>
                        )}
                      </>
                    )}

                    {isSupervisor && (
                      <>
                        {data.settings?.require_supervisor_signature ? (
                          <SignatureDialog
                            model="supervisor"
                            title="Sign Off as Supervisor"
                            yesLabel="Apply Signature & Sign Off"
                            onYes={this.signOff}
                            isDisabled={loading || isInvalid}
                            onDisabledClick={this.onDisabledClick}
                          >
                            <TooltipButton
                              fullWidth
                              label="Sign Off as Supervisor…"
                              glyph="signature"
                              type="primary"
                              color="green"
                              isDisabled={loading || isInvalid}
                              permission="staff_custom_notes.edit"
                              show={isInvalid}
                              message="Click to highlight required fields"
                            />
                          </SignatureDialog>
                        ) : (
                          <ConfirmDialog
                            model="supervisor"
                            title="Sign Off as Supervisor"
                            yesLabel="Sign Off"
                            onYes={this.signOffWithoutSignature}
                            isDisabled={loading || isInvalid}
                            onDisabledClick={this.onDisabledClick}
                          >
                            <TooltipButton
                              fullWidth
                              label="Sign Off as Supervisor…"
                              glyph="check"
                              type="primary"
                              color="green"
                              isDisabled={loading || isInvalid}
                              permission="staff_custom_notes.edit"
                              show={isInvalid}
                              message="Click to highlight required fields"
                            />
                          </ConfirmDialog>
                        )}
                      </>
                    )}
                  </>
                )}

                {record.status === 'pending_review' && (
                  <>
                    <Button
                      glyph="edit"
                      label="Edit Custom Note"
                      type="default"
                      isDisabled={loading}
                      onClick={this.edit}
                      flex="100 1 auto"
                      permission="staff_custom_notes.edit"
                    />

                    <DeleteDialog
                      title={`Delete Custom Note?`}
                      message={`Are you sure you want to delete this custom note? This action cannot be undone.`}
                      onYes={this.delete}
                    >
                      <Button
                        glyph="delete"
                        label={`Delete Custom Note…`}
                        type="default"
                        color="red"
                        isDisabled={loading}
                        fullWidth
                        permission="staff_custom_notes.delete"
                      />
                    </DeleteDialog>

                    {isSupervisor && (
                      <TextareaDialog
                        model="request_updates"
                        title="What updates should be made?"
                        onYes={this.requestUpdates}
                        yesLabel="Ask For Updates"
                      >
                        <Button label="Request Updates…" type="default" isDisabled={loading} permission="staff_custom_notes.edit" />
                      </TextareaDialog>
                    )}

                    {isSupervisor && (
                      <>
                        {data.settings?.require_supervisor_signature ? (
                          <SignatureDialog
                            model="supervisor"
                            title="Sign Off as Supervisor"
                            yesLabel="Apply Signature & Sign Off"
                            onYes={this.signOff}
                            isDisabled={loading || isInvalid}
                            onDisabledClick={this.onDisabledClick}
                          >
                            <TooltipButton
                              fullWidth
                              label="Sign Off as Supervisor…"
                              glyph="signature"
                              type="primary"
                              color="green"
                              isDisabled={loading || isInvalid}
                              permission="staff_custom_notes.edit"
                              show={isInvalid}
                              message="Click to highlight required fields"
                            />
                          </SignatureDialog>
                        ) : (
                          <ConfirmDialog
                            model="supervisor"
                            title="Sign Off as Supervisor"
                            yesLabel="Sign Off"
                            onYes={this.signOffWithoutSignature}
                            isDisabled={loading || isInvalid}
                            onDisabledClick={this.onDisabledClick}
                          >
                            <TooltipButton
                              fullWidth
                              label="Sign Off as Supervisor…"
                              glyph="check"
                              type="primary"
                              color="green"
                              isDisabled={loading || isInvalid}
                              permission="staff_custom_notes.edit"
                              show={isInvalid}
                              message="Click to highlight required fields"
                            />
                          </ConfirmDialog>
                        )}
                      </>
                    )}
                  </>
                )}

                {record.status === 'updates_required' && (
                  <>
                    <Button
                      glyph="edit"
                      label={`Edit Custom Note`}
                      type="default"
                      isDisabled={loading}
                      onClick={this.edit}
                      flex="100 1 auto"
                      permission="staff_custom_notes.edit"
                    />

                    <DeleteDialog
                      title={`Delete Custom Note?`}
                      message={`Are you sure you want to delete this custom note? This action cannot be undone.`}
                      onYes={this.delete}
                    >
                      <Button
                        fullWidth
                        glyph="delete"
                        label={`Delete Custom Note…`}
                        type="default"
                        color="red"
                        isDisabled={loading}
                        permission="staff_custom_notes.delete"
                      />
                    </DeleteDialog>

                    {!isSupervisor && (
                      <>
                        {data.settings?.require_staff_signature ? (
                          <SignatureDialog
                            model="author"
                            title="Sign & Send for Review"
                            yesLabel="Apply Signature & Send for Review"
                            onYes={this.sendForSupervisorReview}
                            isDisabled={loading || isInvalid}
                            onDisabledClick={this.onDisabledClick}
                          >
                            <TooltipButton
                              fullWidth
                              label="Sign & Send for Supervisor Review…"
                              glyph="signature"
                              type="default"
                              isDisabled={loading || isInvalid}
                              permission="staff_custom_notes.edit"
                              show={isInvalid}
                              message="Click to highlight required fields"
                            />
                          </SignatureDialog>
                        ) : (
                          <ConfirmDialog
                            model="supervisor"
                            title="Send for Supervisor Review"
                            yesLabel="Send for Review"
                            onYes={this.sendForSupervisorReviewWithoutSignature}
                            isDisabled={loading || isInvalid}
                            onDisabledClick={this.onDisabledClick}
                          >
                            <TooltipButton
                              fullWidth
                              label="Send for Supervisor Review…"
                              glyph="check"
                              type="default"
                              isDisabled={loading || isInvalid}
                              permission="staff_custom_notes.edit"
                              show={isInvalid}
                              message="Click to highlight required fields"
                            />
                          </ConfirmDialog>
                        )}
                      </>
                    )}

                    {isSupervisor && (
                      <>
                        {data.settings?.require_supervisor_signature ? (
                          <SignatureDialog
                            model="supervisor"
                            title="Sign Off as Supervisor"
                            yesLabel="Apply Signature & Sign Off"
                            onYes={this.signOff}
                            isDisabled={loading || isInvalid}
                            onDisabledClick={this.onDisabledClick}
                          >
                            <TooltipButton
                              fullWidth
                              label="Sign Off as Supervisor…"
                              glyph="signature"
                              type="primary"
                              color="green"
                              isDisabled={loading || isInvalid}
                              permission="staff_custom_notes.edit"
                              show={isInvalid}
                              message="Click to highlight required fields"
                            />
                          </SignatureDialog>
                        ) : (
                          <ConfirmDialog
                            model="supervisor"
                            title="Sign Off as Supervisor"
                            yesLabel="Sign Off"
                            onYes={this.signOffWithoutSignature}
                            isDisabled={loading || isInvalid}
                            onDisabledClick={this.onDisabledClick}
                          >
                            <TooltipButton
                              fullWidth
                              label="Sign Off as Supervisor…"
                              glyph="check"
                              type="primary"
                              color="green"
                              isDisabled={loading || isInvalid}
                              permission="staff_custom_notes.edit"
                              show={isInvalid}
                              message="Click to highlight required fields"
                            />
                          </ConfirmDialog>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Overlay.Footer>
        )}
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => ({
  ...defaultMapStateToProps(state, props.match, 'custom_notes'),
})

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(CustomNoteOverlay))
