import React from 'react'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'

import { Page, Grid, Tab, TabList, Tabs, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import TodoPageActions from '../../../../components/TodoPageActions'

import Active from './active'
import Completed from './completed'
import { useGet } from '@behavehealth/hooks/useNewAPI'

const pageConfig = {
  feature: 'todos',
  help: <HelpTagIframe id="todos" />,
  marketingID: 'todos',
}

const Tasks = ({ canCreate = true }) => {
  const { params, url } = useRouteMatch()

  const { data: client }: any = useGet({
    name: ['client', params.resource_id],
    url: `/residents/${params.resource_id}`,
  })

  return (
    <Page breakpoint="0" actions={canCreate && <TodoPageActions data={{ resident: client }} />} {...pageConfig}>
      <Grid gap={16}>
        <Tabs>
          <TabList>
            <Tab label="Active" to={`${url}/active`} />
            <Tab label="Completed" to={`${url}/completed`} />
          </TabList>
        </Tabs>

        <Switch>
          <Route path="/:resource_type/:resource_id/todo/active" children={<Active canCreate={canCreate} />} />
          <Route path="/:resource_type/:resource_id/todo/completed" children={<Completed canCreate={canCreate} />} />

          <Redirect exact from={url} to={`${url}/active`} />
        </Switch>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Tasks, pageConfig))
