import React from 'react'

import Select from '../Select'
import Option from '../Option'
import { RELATIONSHIP_TO_CLIENT_CODE } from '../../RCM/constants'

const RelationshipToClientCodeSelector = ({
  label = 'Relationship to Client',
  model = 'policy_holder.relationship_to_customer',
  ...rest
}) => (
  <Select defaultValue="01" label={label} model="policy_holder.relationship_to_customer" {...rest}>
    {Object.keys(RELATIONSHIP_TO_CLIENT_CODE).map((key) => (
      <Option label={RELATIONSHIP_TO_CLIENT_CODE[key]} value={key} />
    ))}
  </Select>
)

export default RelationshipToClientCodeSelector
