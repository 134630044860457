import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { apiUpdate } from '@behavehealth/modules/api'

import {
  Accordion,
  AddressSelectorInput,
  Checkbox,
  CheckboxGroup,
  Chotomate,
  Form,
  FormSection,
  HelpTagIframe,
  Input,
  Option,
  Page,
  PhoneInput,
  Select,
  TimeInputSelector,
  TimezoneInput,
  URLInput,
} from '@behavehealth/components'

import InsurancePayerSelector from '@behavehealth/components/Forms/elements/InsurancePayerSelector'
import InsurancePayerSupport from '@behavehealth/components/RCM/components/InsurancePayerSupport'

import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'

const GeneralInfo: React.FC = () => {
  const match = useRouteMatch()
  const { isBehave } = useSettings()

  const { data: payer }: any = useGet({
    name: ['insurance_payers', match.params.resource_id],
    url: `/insurance_local_payers/${match.params.resource_id}`,
  })

  const { mutateAsync: updateLocalPayer }: any = useUpdate({
    name: ['insurance_payers', match.params?.resource_id],
    url: `/insurance_local_payers/${match.params?.resource_id}`,
    invalidate: ['insurance_payers'],
  })

  if (!payer) return null

  return (
    <Page feature="general_info" help={<HelpTagIframe id="payer_general_info" />}>
      <Chotomate ready name="general_info" />

      {/* Personal Details */}
      <Accordion
        isOpen
        title="Custom Details"
        initialModel={payer}
        glyph="personal_details"
        permission="insurance_local_payers.general_info.custom_details.view"
        editPermission="insurance_local_payers.general_info.custom_details.edit"
        activateEditMode
        onSubmit={updateLocalPayer}
      >
        <Form>
          <FormSection>
            <Input
              label="Name"
              model="name"
              validations={{
                presence: {
                  message: "Please enter the employee's first name",
                },
              }}
            />

            <Select allowEmpty label="Network Status" model="network_status">
              <Option label="Out Of Network" value="out_of_network" />
              <Option label="In Network" value="in_network" />
            </Select>

            <CheckboxGroup label="Enrollment Status" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
              <Checkbox
                label="We are enrolled with this payer"
                model="enrolled_status"
                true_value="is_enrolled"
                false_value="not_enrolled"
              />
            </CheckboxGroup>
          </FormSection>
        </Form>
      </Accordion>

      <Accordion
        isOpen
        title="Payer Details"
        initialModel={payer}
        glyph="personal_details"
        permission="insurance_local_payers.general_info.payer_details.view"
        activateEditMode={isBehave}
        onSubmit={updateLocalPayer}
      >
        <Form>
          <FormSection>
            <PhoneInput label="Main Phone Line" model="phone_no" />
            <PhoneInput label="Main Fax Line" model="fax_no" />

            <PhoneInput label="Other Phone 1" model="other_phone_no_1" />
            <PhoneInput label="Other Phone 2" model="other_phone_no_2" />

            <URLInput label="Website" model="website" />
            <TimezoneInput label="Timezone" model="timezone" />

            <FormSection horizontal>
              <TimeInputSelector label="Open Time" model="opening_at" />
              <TimeInputSelector label="Close Time" model="closing_at" />
            </FormSection>
          </FormSection>
        </Form>
      </Accordion>

      {/* Address */}
      <Accordion
        isOpen
        title="Claim Submission Address"
        glyph="address"
        permission="insurance_local_payers.general_info.claim_submission_address.view"
      >
        <FormSection labelWidth="150px">
          <AddressSelectorInput
            value={payer?.address}
            permission="insurance_local_payers.general_info.claim_submission_address.edit"
            actionLabel="Update Location Address"
            coords={{
              lat: payer?.lat,
              lon: payer?.lon,
            }}
            afterAddressSelect={(address: any) =>
              apiUpdate({
                name: 'insurance_local_payers',
                url: `/insurance_local_payers/${payer.id}`,
                params: { address: address, lat: address?.coords?.lat, lon: address?.coords?.lon },
              })
            }
          />
        </FormSection>
      </Accordion>

      <Accordion
        isOpen
        title="Connected Clearing House Details"
        initialModel={payer}
        glyph="additional_information"
        permission="insurance_local_payers.general_info.connected_clearing_house_details.view"
        activateEditMode={isBehave}
        onSubmit={updateLocalPayer}
      >
        <Form>
          <FormSection>
            <InsurancePayerSelector
              label="Connected Clearing House Payer"
              description="If you cannot find your Payer in this list please get in touch with us"
              icon="insurance"
              model="insurance_clearing_house_config"
              type="insurance_clearing_house_configs"
            />

            {payer && <InsurancePayerSupport payer={payer} />}
          </FormSection>
        </Form>
      </Accordion>
    </Page>
  )
}

export default GeneralInfo
