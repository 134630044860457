import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  queued: {
    label: 'Queued',
    color: 'grey',
  },
  completed: {
    label: 'Sent',
    color: 'green',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
