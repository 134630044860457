import React from 'react'

import { COLORS } from '../../../theme'
import { useSettings } from '../../../hooks'
import { withOverlayError } from '../../../hocs/withOverlayError'

import Alert from '../../Alert'
import Button from '../../Button'
import Card from '../../Card'
import CardContent from '../../CardContent'
import DataList from '../../DataList'
import FormSection from '../../Forms/FormSection'
import Overlay from '../../Overlay'
import Section from '../../Section'

import { useUpdate } from '../../../hooks/useNewAPI'

const ClientPortalReinstate = ({ client, onClose }: any) => {
  const { online } = useSettings()

  const [loading, setLoading] = React.useState(false)
  const { mutateAsync: togglePortalAccess }: any = useUpdate({
    name: ['client', client?.id],
    url: `/residents/${client?.id}/toggle_access_to_client_portal`,
    invalidate: ['clients'],
  })

  const reinstatePortalAccess = async () => {
    setLoading(true)

    try {
      await togglePortalAccess()

      setLoading(false)

      if (onClose) onClose()
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  if (!client) return null

  return (
    <Overlay showBackdrop position="center" onClose={onClose} isLoading={!client}>
      <Overlay.Header glyph="portal" title="Reinstate Client Portal Access" />

      <Overlay.Content>
        <Section>
          <FormSection>
            <Alert glyph="portal" type="positive">
              Allow {client.first_name} to access the Client Portal again. You can revoke access at any time.
            </Alert>

            <Card>
              <CardContent showDivider={false} css={{ background: COLORS.white }}>
                <DataList layout="vertical">
                  <DataList.Title
                    title={`Reinstating the Client Portal will give ${client.first_name} access to the
                  following data:`}
                  />

                  <DataList.Item
                    label="General Info"
                    value="Can see and update Personal Details, Contact Details, Medical Information, Sensitive Information, Insurance Information and Home Address"
                  />
                  <DataList.Item label="To-Do's" value="Can see and update only the To-Do's of Type Client" />
                  <DataList.Item label="Contacts" value="Can see and update, but cannot delete" />
                  <DataList.Item label="Ledger" value="Can see, but cannot update or delete" />
                  <DataList.Item label="Treatment Plans" value="Can see, but cannot update or delete" />
                  <DataList.Item label="Medications" value="Can see, but cannot update or delete" />
                  <DataList.Item label="Test Results" value="Can see, but cannot update or delete" />
                  <DataList.Item label="Staff" value="Can see limited information, and only if shared with the Portal" />
                  <DataList.Item label="Locations" value="Can see limited information, and only if shared with the Portal" />
                  <DataList.Item label="Organizations" value="Can see limited information, and only if shared with the Portal" />
                </DataList>
              </CardContent>
            </Card>
          </FormSection>
        </Section>
      </Overlay.Content>

      <Overlay.Footer online={online}>
        <Button
          label="Restore Client Portal Access"
          type="primary"
          color="green"
          glyph="portal"
          isLoading={loading}
          onClick={reinstatePortalAccess}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default withOverlayError(ClientPortalReinstate)
