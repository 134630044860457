import { v4 as uuid } from 'uuid'
import { REQUEST_STATUSES } from './PartnershipRequestStatus'

function pickRandom(arr) {
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

function getRandomDate() {
  const month = String(Math.floor(Math.random() * 12) + 1).padStart(2, '0')
  const day = String(Math.floor(Math.random() * 28) + 1).padStart(2, '0')
  return `2023-${month}-${day}T00:00:00.000Z`
}

const NAMES: any = [
  'Emily Johnson',
  'John Smith',
  'Sophia Williams',
  'Michael Brown',
  'Emma Davis',
  'William Wilson',
  'Olivia Thomas',
  'James Garcia',
  'Isabella Anderson',
  'Matthew Martinez',
  'Ava Taylor',
  'David Nelson',
  'Mia Carter',
  'Robert White',
  'Abigail Lewis',
  'Joseph Harris',
  'Harper Clark',
  'Thomas Scott',
  'Amelia Adams',
  'Andrew Baker',
]

const STATUS_KEYS = Object.keys(REQUEST_STATUSES)

const REQUEST_INFO: any = [
  {
    request_type: 'form',
    name: 'Form Request',
    notes: 'Need to complete a background check form.',
  },
  {
    request_type: 'file',
    name: 'File Exchange',
    notes: 'Need ECG report for further evaluation.',
  },
  {
    request_type: 'communication',
    name: 'Communication Request',
    notes: 'Review recent changes to treatment protocol.',
  },
  {
    request_type: 'todo',
    name: 'Todo Request',
    notes: 'Finalize the staff schedule for next month.',
  },
  {
    request_type: 'event',
    name: 'Event Attendance',
    notes: 'Invitation to quarterly performance review meeting.',
  },
  {
    request_type: 'form',
    name: 'Form Request',
    notes: 'Complete the patient discharge form.',
  },
  {
    request_type: 'file',
    name: 'File Exchange',
    notes: 'Exchange immunization records.',
  },
  {
    request_type: 'communication',
    name: 'Communication Request',
    notes: "Contact patient's family to discuss ongoing care.",
  },
  {
    request_type: 'todo',
    name: 'Todo Request',
    notes: 'Reorder antibiotics for pharmacy stock.',
  },
  {
    request_type: 'event',
    name: 'Event Attendance',
    notes: 'RSVP for the annual fundraising gala.',
  },
  {
    request_type: 'form',
    name: 'Form Request',
    notes: 'Fill out annual employee evaluation forms.',
  },
  {
    request_type: 'file',
    name: 'File Exchange',
    notes: 'Provide annual financial reports for review.',
  },
  {
    request_type: 'communication',
    name: 'Communication Request',
    notes: 'Schedule a team meeting to discuss upcoming projects.',
  },
  {
    request_type: 'todo',
    name: 'Todo Request',
    notes: 'Update software on all computers in the department.',
  },
  {
    request_type: 'event',
    name: 'Event Attendance',
    notes: 'Confirm attendance to a departmental training session.',
  },
  {
    request_type: 'form',
    name: 'Form Request',
    notes: 'Complete a self-assessment form for career development.',
  },
  {
    request_type: 'file',
    name: 'File Exchange',
    notes: 'Send monthly expense reports to accounting.',
  },
  {
    request_type: 'communication',
    name: 'Communication Request',
    notes: 'Plan a follow-up call with a key stakeholder.',
  },
  {
    request_type: 'todo',
    name: 'Todo Request',
    notes: 'Organize a community outreach event.',
  },
  {
    request_type: 'event',
    name: 'Event Attendance',
    notes: 'Invite team members to a product launch.',
  },
]

export const getMockRequests = ({ count = 10, status }: any) =>
  Array.from({ length: count }, (_, i) => {
    const requestInfo = pickRandom(REQUEST_INFO)

    return {
      id: uuid(),
      request_type: pickRandom,
      status: status || pickRandom(STATUS_KEYS.filter((o) => o !== 'archived')),
      name: 'Form Request',
      notes: 'Requesting patient consent form for surgery.',
      due_at: getRandomDate(),
      created_at: getRandomDate(),
      updated_at: getRandomDate(),
      sent_by: {
        id: uuid(),
        avatar: '',
        name: pickRandom(NAMES),
      },
      sent_to: {
        id: uuid(),
        avatar: '',
        name: pickRandom(NAMES),
      },
      ...requestInfo,
    }
  })

export const REQUESTS_DATA_BY_STATUS = {
  received: {
    all: getMockRequests({ count: Math.floor(Math.random() * 11) + 10 }),
    pending: getMockRequests({ count: Math.floor(Math.random() * 11) + 10, status: 'pending' }),
    completed: getMockRequests({ count: Math.floor(Math.random() * 11) + 10, status: 'completed' }),
    cancelled: getMockRequests({ count: Math.floor(Math.random() * 11) + 10, status: 'cancelled' }),
    archived: getMockRequests({ count: Math.floor(Math.random() * 11) + 10, status: 'archived' }),
  },
  sent: {
    all: getMockRequests({ count: Math.floor(Math.random() * 11) + 10 }),
    pending: getMockRequests({ count: Math.floor(Math.random() * 11) + 10, status: 'pending' }),
    completed: getMockRequests({ count: Math.floor(Math.random() * 11) + 10, status: 'completed' }),
    cancelled: getMockRequests({ count: Math.floor(Math.random() * 11) + 10, status: 'cancelled' }),
    archived: getMockRequests({ count: Math.floor(Math.random() * 11) + 10, status: 'archived' }),
  },
}
