import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../../theme'
import Chart from '../Chart'

export const IntakesChart = (props: any) => {
  const option = {
    legend: {
      data: ['Accepted', 'Declined'],
    },
    tooltip: { trigger: 'axis' },
    xAxis: { type: 'category', data: props.xData },
    yAxis: { type: 'value' },
    series: [
      {
        name: 'Accepted',
        data: props.yData1,
        type: 'bar',
        stack: 'total',
        color: COLORS.green,
        label: { show: true },
      },
      {
        name: 'Declined',
        data: props.yData2,
        type: 'bar',
        stack: 'total',
        color: COLORS.red,
        label: { show: true },
      },
    ],
  }

  return <Chart option={option} {...props} />
}
