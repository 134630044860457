import React from 'react'

import Checkbox from '../../../../Forms/Checkbox'
import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import YesNoRadioGroup from '../../../../Forms/elements/YesNoRadioGroup'

const SuicideRiskDocumentation = ({ showNutritionReferral }: any) => (
  <FormSection layout="vertical" maxWidth={570}>
    <RadioGroup model="data.suicide_risk_documentation.risk_level" label="Suicide Risk Level" layout="horizontal">
      <Radio label="High Risk" value="high" />
      <Radio label="Moderate Risk" value="moderate" />
      <Radio label="Low Risk" value="low" />
    </RadioGroup>

    <SmartTextarea useDictation label="Your Clinical Observation" model="data.suicide_risk_documentation.clinical_observation" />

    <SmartTextarea
      useDictation
      label="Relevant Mental Status Information"
      model="data.suicide_risk_documentation.mental_status_information"
    />

    <SmartTextarea
      useDictation
      label="Methods of Suicide Risk Evaluation"
      model="data.suicide_risk_documentation.methods_of_suicide_risk_evaluation"
    />

    {showNutritionReferral && (
      <YesNoRadioGroup label="Nutrition referral needed?" model="data.suicide_risk_documentation.does_require_nutrition_referral" />
    )}

    <SmartTextarea
      useDictation
      label="Brief Evaluation Summary"
      description="Please include Warning Signs, Risk Indicators, Protective Factors, Access to Lethal Means, Collateral Sources Used and Relevant Information Obtained, Specific Assessment Data to Support Risk Determination, Rationale for Actions Taken and Not Taken"
      model="data.suicide_risk_documentation.evaluation_summary"
    />

    <Checkbox
      trueIcon="tick"
      falseIcon="cross"
      falseStyle="faded"
      label="Provision of Crisis Line 1-800-273-TALK(8255)"
      model="data.suicide_risk_documentation.provision_of_crisis_line"
    />

    <SmartTextarea useDictation label="Implementation of Safety Plan (If Applicable)" model="data.suicide_risk_documentation.safety_plan" />
  </FormSection>
)

export default SuicideRiskDocumentation
