import React from 'react'
import { DateTime } from 'luxon'

import { useUpdate } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Section from '../../components/Section'

import { CommunityProfileLocationsDataTable } from './CommunityProfileLocationsDataTable'
import { useCommunityPermissions } from './useCommunityPermissions'
import { useCommunityEditor } from './useCommunityEditor'

const RootCommunityProfileLocationsTab: React.FC<any> = (props: any) => {
  const { entityId, data } = props

  const { canEditCommunityProfiles } = useCommunityPermissions()
  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  const [isEditable, setIsEditable] = React.useState(false)

  const { mutateAsync: updateEntity, isLoading: isSaving }: any = useUpdate({
    name: ['community-entity', entityId],
    url: `/community/entities/${entityId}`,
    invalidate: 'community-entities',
  })

  const [primaryLocation, setPrimaryLocation] = React.useState<any>(data?.primary_location || null)

  const save = async () => {
    await updateEntity({
      primary_location_id: primaryLocation?.id || null,
      // primary_location: primaryLocation,
    })

    setUpdatedAt(DateTime.now().toMillis())
    setIsEditable(false)
  }

  return (
    <>
      <Section>
        <CommunityProfileLocationsDataTable entityId={entityId} />

        <Card className="px-3 pt-3 pb-4 mt-5">
          <Form isCompact isEditable={isEditable}>
            <FormSection maxWidth="100%">
              <ObjectSelector
                label="Primary Location"
                value={primaryLocation}
                icon="locations"
                type="community_profile.locations"
                dependentValue={data?.id}
                selectTitle={(data: any) => data.name}
                onUpdate={({ object }) => {
                  setPrimaryLocation(object)
                }}
              />

              {canEditCommunityProfiles && (
                <Flex gap="0.75rem">
                  {isEditable && (
                    <>
                      <Button
                        label="Save Primary Location"
                        glyph="check"
                        type="primary"
                        color="green"
                        isLoading={isSaving}
                        onClick={save}
                        size={200}
                        display="inline-flex"
                      />
                      <Button
                        label="Cancel"
                        glyph="cross"
                        type="default"
                        isDisabled={isSaving}
                        onClick={() => {
                          setIsEditable(false)
                        }}
                        size={200}
                        display="inline-flex"
                      />
                    </>
                  )}

                  {!isEditable && (
                    <>
                      <Button
                        glyph="edit"
                        label="Edit Primary Location"
                        type="default"
                        onClick={() => {
                          setIsEditable(true)
                        }}
                        size={200}
                        display="inline-flex"
                      />
                    </>
                  )}
                </Flex>
              )}
            </FormSection>
          </Form>
        </Card>
      </Section>
    </>
  )
}

export const CommunityProfileLocationsTab = withOverlayError(RootCommunityProfileLocationsTab)
