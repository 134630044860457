import React from 'react'
import { DateTime } from 'luxon'
import { transparentize } from 'polished'

import { css, COLORS, HARD_SHADOW } from '../../theme'
import { useSettings } from '../../hooks/useSettings'
import { DT } from '../../utils/functions'

import Button from '../Button'
import DatePickerSelector from '../Forms/DatePickerSelector'
import Dropdown from '../Dropdown'
import DropdownItem from '../DropdownItem'
import Flex from '../Flex'
import Loader from '../Loader'

const PERIOD_LABELS: any = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  trimester: '3 Months',
}

const getHoursList = (startDate: any) => {
  const res = []

  for (let i = 0; i < 24; i++) {
    res.push(startDate.plus({ hours: i }))
  }

  return res
}

const DayView: React.FC<any> = ({ startDate }) => {
  const { timezone } = useSettings()
  const hoursList = getHoursList(startDate)

  return (
    <>
      {hoursList.map((date: any) => (
        <div css={[STYLES.dateCell, STYLES.timeCell]}>
          <div css={STYLES.cellText}>
            <span css={STYLES.time}>{DT(date, timezone).toFormat('h')}</span>
            <span css={STYLES.meridian}>{DT(date, timezone).toFormat('a')}</span>
          </div>
        </div>
      ))}
    </>
  )
}

const WeekView: React.FC<any> = ({ datesList, todayDate }) => {
  const { timezone } = useSettings()

  return (
    <>
      {datesList.map((date: any) => {
        const isToday = todayDate === date.toFormat('MM-dd-yyyy')

        return (
          <div css={STYLES.dateCell} className={isToday ? 'is-today' : ''}>
            <div css={STYLES.cellText}>
              <div css={STYLES.day}>{DT(date, timezone).toFormat('ccc')}</div>
              <div css={STYLES.date}>{DT(date, timezone).toFormat('MMM dd')}</div>
            </div>
          </div>
        )
      })}
    </>
  )
}

const MonthView: React.FC<any> = ({ datesList, todayDate }) => {
  const { timezone } = useSettings()

  return (
    <>
      {datesList.map((date: any) => {
        const isToday = todayDate === date.toFormat('MM-dd-yyyy')

        return (
          <div css={STYLES.dateCell} className={isToday ? 'is-today' : ''}>
            <div css={STYLES.cellText}>
              {/* <div css={STYLES.month}>{DT(date, timezone).toFormat('MMM')}</div> */}
              <div css={STYLES.day}>{DT(date, timezone).toFormat('dd')}</div>
            </div>
          </div>
        )
      })}
    </>
  )
}

const VIEWS: any = {
  day: DayView,
  week: WeekView,
  month: MonthView,
  trimester: MonthView,
}

const TimelineHeader: React.FC<any> = (props) => {
  const {
    datesList,
    period,
    startDate,
    currentDate,
    isLoading,
    isRefetching,
    onNext,
    onPeriodClick,
    onPrev,
    onToday,
    setCurrentDate,
    hideThreeMonthView,
    today,
  } = props

  const { timezone } = useSettings()

  const View = VIEWS[period]

  const firstDate = datesList[0]
  const lastDate = datesList[datesList.length - 1]

  const startDay = firstDate.day
  const startMonth = firstDate.toFormat('LLL')
  const startYear = firstDate.year

  const endDay = lastDate.day
  const endMonth = lastDate.toFormat('LLL')
  const endYear = lastDate.year

  const firstDateLabel = (
    <>
      {startMonth} {startDay}
      {startYear !== endYear && <span className={STYLES.year()}> {startYear}</span>}
    </>
  )
  const endDateLabel = <>{startMonth === endMonth ? endDay : `${endMonth} ${endDay}`}</>

  return (
    <>
      <div css={STYLES.root}>
        <div css={STYLES.headerCell}>
          <Flex stretchSelf centerY gap="0.25rem">
            <div className={STYLES.headingMobile()}>
              <span className={STYLES.dayMonth()}>
                {startMonth} {startDay}
              </span>
              <span className={STYLES.year()}> {startYear}</span>
            </div>

            <div className={STYLES.headingDesktop()}>
              {period === 'day' ? (
                <>
                  <span className={STYLES.dayMonth()}>
                    {startMonth} {startDay}
                  </span>
                  <span className={STYLES.year()}> {startYear}</span>
                </>
              ) : (
                <>
                  <span className={STYLES.dayMonth()}>
                    {firstDateLabel}–{endDateLabel}
                  </span>

                  <span className={STYLES.year()}> {endYear}</span>
                </>
              )}
            </div>

            <DatePickerSelector
              showMonthYearPicker={period === 'month' || period === 'trimester'}
              onChange={(date) => {
                if (!date) return

                setCurrentDate(DateTime.fromJSDate(date).setZone(timezone))
              }}
              trigger={
                <Button hideLabel color="blue" glyph="date" size={100} css={{ ...STYLES.navButton, width: 30 }} isDisabled={isLoading} />
              }
            />

            <Flex stretchSelf>{(isRefetching || isLoading) && <Loader css={STYLES.loader} color={COLORS.blue} size={16} />}</Flex>

            <Flex nowrap css={STYLES.buttonsGroup}>
              <Button
                hideLabel
                color="text"
                size={100}
                glyph="chevron_left"
                glyphSize="1em"
                onClick={onPrev}
                css={STYLES.navButton}
                isDisabled={isLoading}
              />
              <Button
                hideLabel
                color="text"
                size={100}
                glyph="chevron_right"
                glyphSize="1em"
                onClick={onNext}
                css={STYLES.navButton}
                isDisabled={isLoading}
              />
            </Flex>
          </Flex>

          <Dropdown
            label={`${PERIOD_LABELS[period]} View`}
            glyph="view"
            buttonColor="blue"
            buttonType="link"
            position="bottom"
            buttonSize={100}
            buttonClassName={STYLES.dropdownButton().className}
            isDisabled={isLoading}
            className="!w-fit"
          >
            <DropdownItem
              label="Day"
              color="text"
              size={200}
              onClick={() => onPeriodClick('day')}
              isActive={period === 'day'}
              isDisabled={isLoading}
            />
            <DropdownItem
              label="Week"
              color="text"
              size={200}
              onClick={() => onPeriodClick('week')}
              isActive={period === 'week'}
              isDisabled={isLoading}
            />
            <DropdownItem
              label="Month"
              color="text"
              size={200}
              onClick={() => onPeriodClick('month')}
              isActive={period === 'month'}
              isDisabled={isLoading}
            />
            {!hideThreeMonthView && (
              <DropdownItem
                label="3 Months"
                color="text"
                size={200}
                onClick={() => onPeriodClick('trimester')}
                isActive={period === 'trimester'}
                isDisabled={isLoading}
              />
            )}
          </Dropdown>
        </div>

        <div css={STYLES.datesHeader}>
          <View startDate={startDate} datesList={datesList} todayDate={today.toFormat('MM-dd-yyyy')} />
        </div>
      </div>
    </>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'var(--row-columns)',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    background: COLORS.white,
    boxShadow: HARD_SHADOW(3),
    borderRadius: '5px 0 0 0',
    borderBottom: `1px solid ${COLORS.divider}`,
    height: 'var(--timeline-header-height)',
  },

  headerCell: {
    display: 'grid',
    alignItems: 'center',
    padding: '0.4rem 0.5rem',
    position: 'sticky',
    left: 0,
    background: COLORS.white,
    borderRadius: '5px 0 0 0',
    zIndex: 1,
    boxShadow: `
      1px 0 0 ${COLORS.divider},
      4px 0 0 ${COLORS.shadow},
      inset 1px 1px 0 0 ${COLORS.divider}
    `,
  },

  datesHeader: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'var(--days-columns)',
    boxShadow: `inset 0 1px 0 0 ${COLORS.divider}`,
  },

  dateCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 auto',
    textAlign: 'center',
    position: 'relative',
    overflow: 'visible',
    boxShadow: `-1px 0 0 ${COLORS.divider}`,

    '&.is-today': {
      background: transparentize(0.85, COLORS.vividBlue),
    },
  },

  cellText: {
    padding: '0.5rem',
  },

  timeCell: {
    textAlign: 'left',
  },

  time: {
    fontWeight: 600,
    display: 'inline-block',
  },

  meridian: {
    fontSize: '0.9em',
    opacity: 0.75,
    textTransform: 'lowercase',
    display: 'inline-block',
    marginLeft: '0.2em',
  },

  day: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },

  date: {
    fontSize: '0.9em',
    opacity: 0.75,
  },

  month: {
    fontSize: '0.85em',
    opacity: 0.75,
    textTransform: 'uppercase',
    fontWeight: 600,
  },

  headingMobile: css({
    display: 'block',
    fontSize: '1.05rem',
    fontWeight: 400,

    '@media(min-width: 1000px)': {
      display: 'none',
    },
  }),

  headingDesktop: css({
    display: 'none',
    fontSize: '1.05rem',
    fontWeight: 400,

    '@media(min-width: 1000px)': {
      display: 'block',
    },
  }),

  dayMonth: css({
    fontWeight: 700,
  }),

  year: css({
    fontWeight: 400,
    fontSize: '0.92em',
    color: COLORS.textMuted,
  }),

  buttonsGroup: {
    display: 'none',

    '@media(min-width: 600px)': {
      display: 'flex',
    },

    '& > *': {
      marginLeft: -1,
      borderRadius: '0 !important',

      '&:first-child': {
        borderRadius: '5px 0 0 5px !important',
      },

      '&:last-child': {
        borderRadius: '0 5px 5px 0 !important',
      },
    },
  },

  loader: {
    marginRight: '0.5rem',
  },

  navButton: {
    minHeight: 24,
    padding: '0.2em 0.5em',
  },

  dropdownButton: css({
    padding: '0 !important',

    svg: {
      marginRight: '0.05rem !important',
    },
  }),
}

export default TimelineHeader
