import React from 'react'

import withSettings from '../../../hocs/withSettings'
import { usDateTime } from '../../../utils/functions'

import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import TableFilter from '../../../components/Table/TableFilter'

import ClaimInternalStatus from '../../../components/Statuses/ClaimInternalStatus'

const columns = (to: Function = () => {}, onClick: any, timezone: string) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'created_at',
    Header: 'Date',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink to={to(row.original.id)} onClick={() => onClick?.(row.original)} label={usDateTime(value, timezone)} />
    ),
  },
  {
    width: 400,
    accessor: 'notes',
    Header: 'Notes',
  },
  {
    width: 160,
    accessor: 'author',
    Header: 'Created By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
]

const ClaimNotesTable: React.FC<any> = (props) => {
  const {
    emptyDescription = 'No claim notes added yet',
    icon = 'claim_notes',
    localStorageKey = 'claim_notes',
    onClick,
    timezone,
    title = 'Claim Notes',
    to,
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      columns={columns(to, onClick, timezone)}
      emptyDescription={emptyDescription}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

export default withSettings(ClaimNotesTable)
