import React from 'react'

import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootFoRSEDischargeSurveyOverlay = (props: any) => {
  return (
    <DataFormOverlay
      disableSignOff
      minimizeEnabled
      enableTreatmentPlanSelector
      title="Discharge Survey"
      category="outcome_measure"
      subcategory="forse_discharge_survey"
      icon="outcome_measures"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      requestSupervisorSignature={false}
      signoffWithoutSupervisor={true}
      {...props}
    >
      <Section
        title="Questions"
        headingType="h2"
        scrollview={{
          id: 'questions',
          name: 'Questions',
        }}
      >
        <FormSection>
          <RadioGroup
            label="From what level of care is this client discharging?"
            model="data.discharge_level_of_care"
            layout="vertical-dense"
          >
            <Radio label="Inpatient Hospital or Medical Detox" value="inpatient_hospital_or_medical_detox" />
            <Radio label="Residential" value="residential" />
            <Radio label="Partial Hospitalization" value="partial_hospitalization" />
            <Radio label="Intensive Outpatient" value="intensive_outpatient" />
            <Radio label="Outpatient" value="outpatient" />
            <Radio
              label="Sober Living/Recovery Residence and Recovery Support (e.g., recovery coaching)"
              value="sober_living_recovery_residence"
            />
            <Radio label="Recovery Support (e.g., recovery coaching)" value="recovery_support" />
            <Radio label="Other type of program" value="other_type_of_program" />
          </RadioGroup>

          <RadioGroup label="Discharge type" model="data.discharge_type" layout="vertical-dense">
            <Radio label="Completed (discharged to home or transferred to a lower level of care at another facility)" value="completed" />
            <Radio label="AMA (left against medical advice or discontinued care)" value="ama" />
            <Radio
              label="Administrative (discharged/transferred due to difficulty conforming to community rules (e.g., fighting))"
              value="administrative"
            />
            <Radio
              label="Medical (discharged/transferred due to medical condition outside the scope of current care environment)"
              value="medical"
            />
            <Radio
              label="Psych (discharged/transferred due to psychiatric condition outside the scope of current care environment)"
              value="psych"
            />
            <Radio label="Other (any other type of discharge)" value="other" />
          </RadioGroup>

          <div>
            <h4>Q3: How many days did this patient receive the following levels of care?</h4>

            <FormSection className="!pl-4">
              <Input
                label="Inpatient Hospital or Medical Detox"
                size={4}
                suffix="days"
                model="data.level_of_care_days.inpatient_hospital_or_medical_detox"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 999,
                    message: 'Please enter an number between 0 and 999',
                  },
                }}
              />

              <Input
                label="Residential"
                size={4}
                suffix="days"
                model="data.level_of_care_days.residential"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 999,
                    message: 'Please enter an number between 0 and 999',
                  },
                }}
              />

              <Input
                label="Partial Hospitalization"
                size={4}
                suffix="days"
                model="data.level_of_care_days.partial_hospitalization"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 999,
                    message: 'Please enter an number between 0 and 999',
                  },
                }}
              />

              <Input
                label="Intensive Outpatient"
                size={4}
                suffix="days"
                model="data.level_of_care_days.intensive_outpatient"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 999,
                    message: 'Please enter an number between 0 and 999',
                  },
                }}
              />

              <Input
                label="Outpatient"
                size={4}
                suffix="days"
                model="data.level_of_care_days.outpatient"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 999,
                    message: 'Please enter an number between 0 and 999',
                  },
                }}
              />

              <Input
                label="Sober Living/Recovery Residence"
                size={4}
                suffix="days"
                model="data.level_of_care_days.sober_living_recovery_residence"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 999,
                    message: 'Please enter an number between 0 and 999',
                  },
                }}
              />

              <Input
                label="Recovery Support"
                size={4}
                suffix="days"
                model="data.level_of_care_days.recovery_support"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 999,
                    message: 'Please enter an number between 0 and 999',
                  },
                }}
              />

              <Input
                label="Other type of program"
                size={4}
                suffix="days"
                model="data.level_of_care_days.other"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 999,
                    message: 'Please enter an number between 0 and 999',
                  },
                }}
              />
            </FormSection>
          </div>

          <RadioGroup label="Payment type" model="data.payment_type" layout="vertical-dense">
            <Radio label="Self-Pay" value="self_pay" />
            <Radio label="Private Insurance" value="private_insurance" />
            <Radio label="Combination of Self-Pay and Insurance" value="combination_of_self_pay_and_insurance" />
            <Radio label="Medicare or Other public funding" value="medicare_or_other_public_funding" />
          </RadioGroup>

          <RadioGroup
            label="What is the primary diagnostic Substance Use Disorder category?"
            model="data.primary_diagnostic_substance_use_disorder_category"
            layout="vertical-dense"
          >
            <Radio label="Alcohol" value="alcohol" />
            <Radio label="Cannabis" value="cannabis" />
            <Radio label="Hallucinogen" value="hallucinogen" />
            <Radio label="Inhalant" value="inhalant" />
            <Radio label="Opioid" value="opioid" />
            <Radio label="Sedative/Hypnotic/Anxiolytic" value="sedative_hypnotic_anxiolytic" />
            <Radio label="Stimulant" value="stimulant" />
            <Radio label="Tobacco" value="tobacco" />
            <Radio label="Other" value="other" />
          </RadioGroup>

          <RadioGroup label="MAT medications received" model="data.mat_medications_received" layout="vertical-dense">
            <Radio label="None" value="none" />
            <Radio label="Acamprosate/Campral" value="acamprosate_campral" />
            <Radio label="Buprenorphine (Sublocade Injection)" value="buprenorphine_sublocade_injection" />
            <Radio label="Buprenorphine (Subutex/Suboxone film)" value="buprenorphine_subutex_suboxone_film" />
            <Radio label="Disulfiram/Antabuse" value="disulfiram_antabuse" />
            <Radio label="Naltrexone (ReVia Pill)" value="naltrexone_re_via_pill" />
            <Radio label="Naltrexone (Vivitrol Injection)" value="naltrexone_vivitrol_injection" />
            <Radio label="Methadone" value="methadone" />
            <Radio label="Medications for co-occurring disorder (e.g. anxiety or depression)" value="co_occurring_disorder_medications" />
            <Radio label="Medication not listed" value="medication_not_listed" />
            <Radio label="Other" value="other" />
          </RadioGroup>

          <CheckboxGroup label="Mental Health Diagnosis" layout="vertical-dense">
            <Checkbox label="None" model="data.mental_health_diagnosis.none" />
            <Checkbox label="Anxiety Disorder" model="data.mental_health_diagnosis.anxiety_disorder" />
            <Checkbox label="Bipolar Disorder" model="data.mental_health_diagnosis.bipolar_disorder" />
            <Checkbox label="Depressive Disorder" model="data.mental_health_diagnosis.depressive_disorder" />
            <Checkbox
              label="Disruptive/Impulse-Control/Conduct Disorder"
              model="data.mental_health_diagnosis.disruptive_impulse_control_conduct_disorder"
            />
            <Checkbox label="Dissociative Disorder" model="data.mental_health_diagnosis.dissociative_disorder" />
            <Checkbox label="Eating Disorder" model="data.mental_health_diagnosis.eating_disorder" />
            <Checkbox label="Neurocognitive Disorder" model="data.mental_health_diagnosis.neurocognitive_disorder" />
            <Checkbox
              label="Neurodevelopmental Disorder (e.g., ADHD, ASD, Intellectual Disability, Learning Disorder)"
              model="data.mental_health_diagnosis.neurodevelopmental_disorder"
            />
            <Checkbox
              label="Obsessive Compulsive and Related Disorder"
              model="data.mental_health_diagnosis.obsessive_compulsive_and_related_disorder"
            />
            <Checkbox
              label="Trauma and Stressor Related Disorder (e.g., PTSD)"
              model="data.mental_health_diagnosis.trauma_and_stressor_related_disorder"
            />
            <Checkbox
              label="Schizophrenia Spectrum and Other Psychotic Disorder"
              model="data.mental_health_diagnosis.schizophrenia_spectrum_and_other_psychotic_disorder"
            />
            <Checkbox label="Paraphilic Disorder" model="data.mental_health_diagnosis.paraphilic_disorder" />
            <Checkbox label="Personality Disorder" model="data.mental_health_diagnosis.personality_disorder" />
            <Checkbox label="Sexual Dysfunction" model="data.mental_health_diagnosis.sexual_dysfunction" />
            <Checkbox label="Sleep Disorder" model="data.mental_health_diagnosis.sleep_disorder" />
            <Checkbox
              label="Somatic Symptom and Related Disorder"
              model="data.mental_health_diagnosis.somatic_symptom_and_related_disorder"
            />
            <Checkbox label="Other Disorder" model="data.mental_health_diagnosis.other_disorder" />
          </CheckboxGroup>

          <RadioGroup
            label="Was any portion of the client's care delivered remotely/through telehealth?"
            model="data.did_use_telehealth"
            layout="horizontal-dense"
          >
            <Radio label="No" value={false} />
            <Radio label="Yes" value={true} />
          </RadioGroup>
        </FormSection>
      </Section>
    </DataFormOverlay>
  )
}

export const FoRSEDischargeSurveyOverlay = withOverlayError(RootFoRSEDischargeSurveyOverlay)
