import React from 'react'
import * as Toast from '@radix-ui/react-toast'
import { keyframes } from '@emotion/react'
import { SHADOW, COLORS } from '../theme'
import { css } from '@emotion/react'
import { lighten, darken } from 'polished'

import useAnalytics from '../hooks/useAnalytics'
import Button from './Button'
import Flex from './Flex'
import Grid from './Grid'
import ConfirmDialog from './Dialogs/ConfirmDialog'
import { useSettings } from '../hooks/useSettings'

import { apiCreate } from '../modules/api'

const INTERVAL = 60 * 60 * 1000 // every 60 minutes
const SHOW_TOAST = 60 * 60 * 1000 // 60 minutes

export const TrialTimerToast: React.FC<any> = () => {
  const [showToast, setShowToast] = React.useState(true)

  const { tenant } = useSettings()

  const hasSeedData = tenant?.has_seed_data

  const interval = React.useRef(null)

  const { trackEvent } = useAnalytics()

  const clearSampleData = async () => {
    try {
      await apiCreate({ url: '/apps/seeds/delete_defaults' })
      trackEvent({ name: 'Cleared Sample Data', params: { from: 'toast' } })

      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (interval.current) clearInterval(interval.current)

    if (!showToast) {
      interval.current = setInterval(() => {
        setShowToast(true)
      }, INTERVAL)
    }

    return () => {
      if (interval.current) clearInterval(interval.current)
    }
  }, [showToast])

  return (
    <>
      <Toast.Provider swipeDirection="right" duration={SHOW_TOAST}>
        <Toast.Root open={showToast} onOpenChange={setShowToast} css={[borderAngle, warning, {}]}>
          <Flex centerY gap="1rem">
            <Grid gap="0.5rem" flex="1 1 200px">
              <Toast.Title css={STYLES.title}>You are in Trial Mode</Toast.Title>

              <Toast.Description asChild css={STYLES.description}>
                <>
                  {hasSeedData && (
                    <p>
                      Your app contains <strong>Sample Data</strong>. Please make note that we will clear this when you start your
                      subscription.
                    </p>
                  )}

                  {!hasSeedData && <p>You successfully cleared your Sample Data. When ready, check your Subscription Plan.</p>}
                </>
              </Toast.Description>
            </Grid>

            <Toast.Action asChild altText="I Understand">
              <Grid center gap="1rem">
                {hasSeedData ? (
                  <ConfirmDialog
                    glyph="behave_health"
                    title={`Clear Sample Data?`}
                    message={`This will clear all sample data from your app. If you want the sample data back, please get in touch with us.`}
                    onYes={clearSampleData}
                  >
                    <Button type="primary" color="blue" label="Clear Sample Data…" />
                  </ConfirmDialog>
                ) : (
                  <Button glyph="star" type="primary" color="green" label="Ready to Subscribe" link="/settings/billing/products" />
                )}

                <Button
                  type="default"
                  color="red"
                  label="Dismiss for 1 hour"
                  onClick={() => {
                    setShowToast(false)
                  }}
                />
              </Grid>
            </Toast.Action>
          </Flex>
        </Toast.Root>

        <Toast.Viewport css={STYLES.viewport} />
      </Toast.Provider>
    </>
  )
}

const hide = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

const slideIn = keyframes`
  0% {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }

  100% {
    transform: translateX(0);
  }
`

const swipeOut = keyframes`
  0% {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }

  100% {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
`

const bgSpin = keyframes`
  0% {
    '--border-angle': '0turn'
  }

  50% {
    '--border-angle': '0.5turn'
  }

  100% {
    '--border-angle': '1turn'
  }
`

const STYLES = {
  viewport: {
    '--viewport-padding': '25px',

    position: 'fixed',

    bottom: 10,
    right: 10,

    display: 'flex',
    flexDirection: 'column',
    gap: '10px',

    width: 590,
    maxWidth: '100vw',
    margin: 0,
    listStyle: 'none',
    zIndex: 2147483647,

    outline: 'none',
  },

  notification: {
    background: '#fff',
    padding: '1rem',

    borderRadius: 10,
    fontWeight: 500,

    boxShadow: SHADOW(2),

    // grid
    display: 'grid',
    gridTemplateAreas: "'title action' 'description action'",
    gridTemplateColumns: 'auto max-content',
    columnGap: '15px',
    alignItems: 'center',

    '@media (max-width: 600px)': {
      gridTemplateColumns: '1fr',
    },

    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },

    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`,
    },

    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },

    '&[data-swipe="cancel"]': {
      transform: 'translateX(0)',
      transition: 'transform 200ms ease-out',
    },

    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`,
    },
  },

  warning: {
    '--border-size': 3,
    '--border-angle': '0turn',

    backgroundImage: `conic-gradient(from var(--border-angle), #213, #112 50%, #213) conicGradient(from var(--border-angle), transparent 20%, #08f, #f03)`,
    backgroundSize: `calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',

    animation: `${bgSpin} 3s linear infinite`,

    '@property --border-angle': {
      syntax: '<angle>',
      inherits: 'true',
      initialValue: '0turn',
    },
  },

  title: {
    marginBottom: 5,
    fontWeight: 600,
  },

  description: {
    margin: 0,

    color: COLORS.textLight,
    fontWeight: 400,
  },
}

const warning = css`
  border-radius: 10px;
  overflow: hidden;
  padding: 2rem;

  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;

    z-index: -2;
    left: -50%;
    top: -200%;
    width: 200%;
    height: 500%;

    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;

    background-image: linear-gradient(${lighten(0.2, COLORS.gold)}, ${lighten(0.2, COLORS.gold)}),
      linear-gradient(${lighten(0.1, COLORS.gold)}, ${lighten(0.1, COLORS.gold)}),
      linear-gradient(${darken(0, COLORS.gold)}, ${darken(0, COLORS.gold)}),
      linear-gradient(${darken(0.05, COLORS.gold)}, ${darken(0.05, COLORS.gold)});

    animation: rotate 5s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;

    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);

    background: white;
    border-radius: 5px;
  }
`

const borderAngle = css`
  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`
