import React from 'react'

import ContextShow from '../ContextShow'
import Input from '../Input'
import Option from '../Option'
import Select from '../Select'

const EthnicitySelect = ({ model, isRequired }) => (
  <>
    <Select
      allowEmpty
      label="Ethnicity"
      model={model}
      validations={
        isRequired && {
          presence: {
            message: 'Please select an ethnicity / race',
          },
        }
      }
    >
      <Option label="Decline to respond" value="decline_to_respond" />
      <Option label="American Indian or Alaska Native" value="american_indian_or_alaska_native" />
      <Option label="Asian" value="asian" />
      <Option label="Black or African American" value="black_or_african_american" />
      <Option label="Hispanic or Latino" value="hispanic_or_latino" />
      <Option label="Native Hawaiian or Other Pacific Islander" value="native_hawaiian_or_other_pacific_islander" />
      <Option label="White" value="white" />
      <Option label="Other" value="other" />
    </Select>

    <ContextShow when={model} is="other">
      <Input label="Please add your ethnicity" model={`${model}_other`} />
    </ContextShow>
  </>
)

EthnicitySelect.defaultProps = {
  model: 'ethnicity',
}

export default EthnicitySelect
