import React from 'react'
import size from 'lodash/size'
import useStore from '../modules/store'

export const useDebug = () => {
  const updateStore = useStore((state) => state.update)

  const localDebug: any = localStorage.getItem('bh.dbg')
  const parsedDebug = JSON.parse(localDebug)

  const [debug, setDebug] = React.useState(!!parsedDebug ? parsedDebug : {})

  React.useEffect(() => {
    const checkDebug = () => {
      const localDebug: any = localStorage.getItem('bh.dbg')
      const parsedDebug = JSON.parse(localDebug)

      if (size(parsedDebug)) setDebug(parsedDebug)
    }

    window.addEventListener('storage', checkDebug)

    return () => {
      window.removeEventListener('storage', checkDebug)
    }
  }, [])

  React.useEffect(() => {
    updateStore({ debug: debug })
  }, [debug])

  const toggleDebug = (key: string) => {
    let newValue = debug

    if (debug?.[key] === true) newValue = { ...debug, [key]: false }
    else newValue = { ...debug, [key]: true }

    // update local storage
    localStorage.setItem('bh.dbg', JSON.stringify(newValue))

    // update local debug key
    setDebug(newValue)
  }

  const isDebug = (key: string) => {
    return debug?.[key] === true
  }

  return {
    debug,
    isDebug,
    toggleDebug,
  }
}

export default useDebug
