import React from 'react'
import isUndefined from 'lodash/isUndefined'

import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Notifications from '../../modules/notifications'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import SummonOverlay from '../../components/SummonOverlay'

import { TreatmentBookImportOverlay } from '../TreatmentBook/TreatmentBookImportOverlay'
import { TreatmentPlanEvidenceBasedInput } from './form_elements/TreatmentPlanEvidenceBasedInput'
import { TreatmentPlanRecordDates } from './form_elements/TreatmentPlanRecordDates'
import { TreatmentPlanRecordStatus } from './form_elements/TreatmentPlanRecordStatus'
import { ReviewOverlay, TreatmentPlanReviews } from './components/TreatmentPlanReviews'

const RootTreatmentPlanGoalOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    queryKey,
    params,
  } = useOverlay({
    name: 'treatment-goal',
    endpoint: '/treatment_goals',
    invalidate: 'treatment-goals',
    invalidateKeys: [
      'treatment-problems',
      'treatment-plan-updates',
      'treatment-goals-objectives-interventions',
      'treatment-problem',
      'treatment-objective',
    ],
    options: props,
  })

  const { canEdit, planId, planCategory, permissionBase, treatmentBookPermissionBase } = props

  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGet({
    name: ['treatment_plans', planId],
    url: `/treatment_plans/${planId}`,
    options: { enabled: !!planId },
  })

  const { timezone } = useSettings()
  const { resource_id: clientId }: any = params

  const [isCompleting, setIsCompleting] = React.useState(false)

  const [name, setName] = React.useState<string>(initialModel?.name || '')
  const [description, setDescription] = React.useState<string>(initialModel?.description || '')

  const showStart =
    isUndefined(treatmentPlan?.settings?.sections?.goals?.start_date) || treatmentPlan?.settings?.sections?.goals?.start_date === true

  const showTarget =
    isUndefined(treatmentPlan?.settings?.sections?.goals?.target_date) || treatmentPlan?.settings?.sections?.goals?.target_date === true

  const showCompletion =
    isUndefined(treatmentPlan?.settings?.sections?.goals?.completion_date) ||
    treatmentPlan?.settings?.sections?.goals?.completion_date === true

  const useProblems = treatmentPlan?.settings?.enabled_sections?.problems === true
  const usePlanReviews = treatmentPlan?.settings?.use_plan_reviews === true

  const showDates = showStart || showTarget || showCompletion
  const showReviews = treatmentPlan?.use_plan_reviews === true

  const handleSave = () => {
    saveWithData({
      ...form.current.getFormValue(),
      name,
      description,
    })
  }

  const handleImport = (importData: any) => {
    if (importData.name) setName(importData.name)
    if (importData.description) setDescription(importData.description)
  }

  React.useEffect(() => {
    if (!data) return

    setName(data.name)
    setDescription(data.description)
  }, [data])

  const formKey = React.useMemo(() => {
    if (!data) return null

    const problemKeys = data.treatment_problems?.map((problem: any) => problem.updated_at).join('-')

    return `${data.updated_at}-${problemKeys}`
  }, [data])

  if (isOverlayLoading || isLoadingTreatmentPlan) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="treatment_plans" title="Goal" />

      <Overlay.Content>
        <Form
          key={formKey}
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              category: planCategory,
              treatment_plan_id: planId,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              <h2>Goal</h2>

              {treatmentPlan?.settings?.instructions?.goal && (
                <Alert contrast glyph="info">
                  {treatmentPlan.settings.instructions?.goal}
                </Alert>
              )}

              {useProblems && (
                <MultiObjectSelector
                  label="Linked Problems"
                  dependentValue={planId}
                  type="treatment_plan.treatment_problems"
                  selectTitle={(data) => data.name || <i>No title</i>}
                  selectDescription={(data) => data.behavioral_definitions}
                  model="treatment_problems"
                />
              )}

              <TreatmentPlanRecordStatus />

              <Input
                label="Name"
                value={name}
                onUpdate={({ value }) => {
                  setName(value)
                }}
              />

              <SmartTextarea
                useQuickText
                useDictation
                label="Description"
                value={description}
                onUpdate={({ value }) => {
                  setDescription(value)
                }}
              />

              {isEditable && (
                <Permission featureFlagV2="treatment_book" permission={`${treatmentBookPermissionBase}.view`}>
                  <SummonOverlay
                    overlay={<TreatmentBookImportOverlay category={planCategory} importType="goals" onSingleSelect={handleImport} />}
                  >
                    <Button label="Import & Replace from Treatment Book" size={100} glyph="add_file" type="minimal" />
                  </SummonOverlay>
                </Permission>
              )}

              <TreatmentPlanEvidenceBasedInput />

              {showDates && (
                <>
                  <Divider />
                  <TreatmentPlanRecordDates showStart={showStart} showTarget={showTarget} showCompletion={showCompletion} />
                </>
              )}

              {usePlanReviews && showReviews && (
                <>
                  <Divider />

                  <TreatmentPlanReviews
                    planId={planId}
                    isNew={isNew}
                    reference={data}
                    invalidate={queryKey}
                    data={data?.treatment_reviews}
                    canEdit={canEdit}
                    permissionBase={permissionBase}
                    isEditable={isEditable}
                  />
                </>
              )}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      {canEdit && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                glyph="check"
                label="Save"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={handleSave}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission={`${permissionBase}.edit`}
              />
              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <div className="grid gap-3">
              {data && data.status !== 'completed' && (
                <>
                  <Flex gap="0.75rem">
                    <SummonOverlay
                      portalType="z100"
                      overlay={
                        <ReviewOverlay
                          dataID="new"
                          initialData={{
                            parent_updates: { status: 'completed' },
                          }}
                          planId={planId}
                          reference={data}
                          invalidate={queryKey}
                          canEdit={canEdit}
                          permissionBase={permissionBase}
                        />
                      }
                    >
                      <Button
                        label="Mark as Completed"
                        glyph="tick_circle"
                        color="green"
                        type="default"
                        flex="1 1 auto"
                        isDisabled={isSaving || isDeleting}
                        isLoading={isCompleting}
                        permission={`${permissionBase}.edit`}
                      />
                    </SummonOverlay>
                  </Flex>

                  <Divider className="!my-2" />
                </>
              )}

              <Flex gap="0.75rem">
                <Button
                  label="Edit"
                  glyph="edit"
                  type="default"
                  isDisabled={isSaving || isDeleting}
                  onClick={edit}
                  flex="100 1 auto"
                  permission={`${permissionBase}.edit`}
                />

                <DeleteDialog
                  title="Delete Goal?"
                  message="Are you sure you want to delete this Goal? This action cannot be undone."
                  onYes={deleteRecord}
                  content={
                    <Alert small contrast type="negative" glyph="warning" className="mt-3">
                      <b>Please note:</b> all associated objectives and interventions will also be permanently removed.
                    </Alert>
                  }
                >
                  <Button
                    label="Delete"
                    type="default"
                    glyph="delete"
                    color="red"
                    isLoading={isDeleting}
                    isDisabled={isSaving || isDeleting}
                    fullWidth
                    permission={`${permissionBase}.edit`}
                  />
                </DeleteDialog>
              </Flex>
            </div>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const TreatmentPlanGoalOverlay = withOverlayError(RootTreatmentPlanGoalOverlay)
