import React from 'react'
import sum from 'lodash/sum'
import { connect } from 'react-redux'

import { arrayToMap, getFirstValueFromMap } from '../../../../utils/functions'
import { apiUpdate } from '../../../../modules/api'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import Button from '../../../Button'
import Card from '../../../Card'
import CardContent from '../../../CardContent'
import CardHeader from '../../../CardHeader'
import CardTitle from '../../../CardTitle'
import Checkbox from '../../../Forms/Checkbox'
import Chotomate from '../../../Chotomate'
import DataArray from '../../../Forms/DataArray'
import Flex from '../../../Flex'
import Form from '../../../Forms/Form'
import Grid from '../../../Grid'
import HundredPercentageAlert from '../../../Alerts/HundredPercentage'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../../Overlay'
import PercentageInput from '../../../Forms/PercentageInput'
import Section from '../../../Section'
import SigneeStatus from '../../../Statuses/SigneeStatus'

import { OverlayBase, defaultMapDispatchToProps } from '../OverlayBase'

class EditPayers extends OverlayBase {
  renderHeader = () => <Overlay.Header glyph="user_group" title="Edit Payers" />

  onFormUpdate = ({ model }) => {
    let blacklisted = []
    for (let i = 0; i < model.payers.length; i++) {
      if (model.payers[i].reference) {
        blacklisted.push({
          id: model.payers[i].reference.id,
          type: model.payers[i].reference.type,
        })
      }
    }

    const percentage = sum(model.payers.map((o) => parseFloat(o.percentage) || 0))

    this.setState({ percentage: percentage, blacklisted: blacklisted })
  }

  save = async () => {
    let model = this.form.current.getFormValue()

    await apiUpdate({
      name: 'financial_plan',
      url: `/financial_plans/${this.props.financial_plan.id}`,
      notify: false,
      params: {
        payers_attributes: model.payers,
      },
    })

    this.close()
  }

  renderContent = () => {
    const { payers, payersMap, match } = this.props
    const clientID = match.params.resource_id

    return (
      <Overlay.Content>
        <Chotomate ready name="edit_payers_overlay" />

        <Section>
          <HundredPercentageAlert percentage={this.state.percentage} className="!mb-4" />

          <Form
            getForm={this.form}
            onUpdate={this.onFormUpdate}
            onValidationUpdate={this.onValidationUpdate}
            initialModel={{ payers: payers }}
          >
            <DataArray model="payers">
              {({ ids, add, remove, elements }: any) => (
                <Grid gap="1rem">
                  {ids.map((id: string, idx: number) => {
                    return (
                      <Card key={id} paddingX="1rem">
                        <CardHeader
                          after={
                            !elements?.[id]?.is_required && (
                              <Button label="Remove" type="link" color="gray" glyph="delete" size={300} onClick={() => remove(id)} />
                            )
                          }
                          css={{ minHeight: 40 }}
                        >
                          <CardTitle
                            title={payersMap[id]?.reference.name || `Payer #${idx + 1}`}
                            after={<SigneeStatus signee={payersMap[id]?.reference} />}
                            className="!font-[700]"
                          />
                        </CardHeader>

                        <CardContent css={{ padding: '0.75rem 1rem' }}>
                          <Flex gap={12} horizontal>
                            <Checkbox
                              value={true}
                              isEditable={false}
                              falseStyle="hidden"
                              css={{ display: 'none' }}
                              model={`${id}.is_payer`}
                            />

                            <ObjectSelector
                              isPolymorphic
                              isEditable={!elements?.[id]?.is_required}
                              icon="contacts"
                              model={`${id}.reference`}
                              label="Payer"
                              type="client.possible_payers"
                              dependentValue={clientID}
                              selectTitle={(data) => data.name}
                              selectDescription={(data) => {
                                if (data.type === 'organization') {
                                  return data.subcategory.name
                                } else if (data.type === 'contact') {
                                  return data.relationship
                                }
                              }}
                              blacklisted={this.state.blacklisted}
                              validations={{
                                presence: {
                                  message: 'Please select a contact or remove this payer',
                                },
                              }}
                              css={{
                                ...(payersMap[id]?.is_required && { display: 'none' }),
                                flex: '1 1 260px',
                              }}
                              emptyActions={
                                <Button
                                  label="Add Contacts"
                                  link={`/clients/${clientID}/contacts`}
                                  glyph="add"
                                  permission="contacts.view"
                                />
                              }
                            />

                            <PercentageInput
                              label="Percentage"
                              model={`${id}.percentage`}
                              validations={{
                                presence: {
                                  message: 'Please add a percentage (can be zero)',
                                },
                              }}
                              className="!grow-0 !shrink-0"
                            />
                          </Flex>

                          {/* <div className="!mt-3">
                            <Checkbox
                              label="Request signature"
                              model={`${id}.request_signature`}
                              isEditable={!payersMap[id]?.is_required}
                              trueIcon="check"
                              falseIcon="cross"
                              falseStyle="faded"
                            />
                          </div> */}
                        </CardContent>
                      </Card>
                    )
                  })}

                  <Button label="Add New Payer" glyph="add" type="default" onClick={add} />
                </Grid>
              )}
            </DataArray>
          </Form>
        </Section>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    return (
      <Overlay.Footer>
        <Button
          label="Save Payers"
          glyph="check"
          type="primary"
          color="green"
          flex="100 1 auto"
          onClick={this.save}
          isLoading={this.props.loading}
          isDisabled={this.state.isInvalid || this.state.percentage !== 100}
        />
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => {
  const fp = getFirstValueFromMap(state.data.financial_plan?.data)
  return {
    financial_plan: fp,
    payers: fp?.payers,
    payersMap: arrayToMap(fp?.payers),
    loading: state.data.financial_plan?.loading,
    online: state.common.global?.online,
    idle: state.common.global.idle,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(EditPayers))
