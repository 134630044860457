import React from 'react'

import { usDate, usTime } from '../../../utils/functions'

import EventStatus from '../../Statuses/EventStatus'
import Table from '../../Table/Table'
import TableCell from '../../Table/TableCell'
import TableFilter from '../../Table/TableFilter'

import withSettings from '../../../hocs/withSettings'
import { NavLink } from 'react-router-dom-v5-compat'

const CATEGORIES: any = {
  general: 'General',
  client_admission: 'Client Admission',
  recovery_coaching: 'Recovery Coaching',
  housing_activity: 'Housing Activity',
  discharge: 'Discharge',
  psych_appointment: 'Psych Appointment',
  medical_appointment: 'Medical Appointment',
  clinical_appointment: 'Clinical Appointment',
  progress_appointment: 'Progress Appointment',
  individual_therapy: 'Individual Therapy',
  individual_peer_note: 'Individual Peer Note',
  group_peer_notes: 'Group Peer Note',
  individual_daily_note: 'Individual Daily Note',
  group_daily_notes: 'Group Daily Note',
  group_therapy: 'Group Therapy',
  meds_pass: 'Meds Pass',
  urinalysis: 'Urinalysis',
  breathalyzer: 'Breathalyzer',
  vitals: 'Vitals',
  communicable_tests: 'Communicable Tests',
  bed_check: 'Bed Check',
  housing_intake: 'Housing Intake',
  show_as_busy: 'Show as Busy',
}

const columns = (to: Function = () => {}, timezone: string, useV6Router) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'title',
    Header: 'Title',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink as={useV6Router ? NavLink : undefined} to={to(row.original)} label={value} avatar={row.original.avatar} />
    ),
  },
  {
    width: 150,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <EventStatus status={value} />,
  },
  {
    width: 160,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ value }: any) => {
      if (!(value in CATEGORIES)) return <TableCell.Empty />

      return CATEGORIES[value]
    },
  },
  {
    width: 90,
    accessor: 'color',
    Header: 'Color',
    Cell: TableCell.Swatch,
  },
  {
    width: 160,
    accessor: 'started_at',
    Header: 'Date',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 160,
    accessor: 'is_all_day',
    Header: 'Time',
    Cell: ({ value, row }: any) => {
      if (value === true) return 'All-day'

      return `${usTime(row.original?.start_time, timezone)} – ${usTime(row.original?.end_time, timezone)}`
    },
  },
  {
    width: 200,
    accessor: 'public_description',
    Header: 'Public Description',
    Cell: ({ value }: any) => <TableCell.Markup value={value} />,
  },
]

type Props = {
  batchActionsConfig?: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  events: Object
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  setTableColumns?: Function
  tableProps?: any
  timezone: string
  title: string
  to?: Function
  useV6Router?: boolean
}

const TableView = (props: Props) => {
  const {
    batchActionsConfig,
    emptyActions,
    emptyDescription,
    events,
    hiddenColumns,
    icon,
    isLoading,
    localStorageKey,
    setTableColumns,
    timezone,
    title,
    to,
    tableProps,
    useV6Router,
  } = props

  return (
    <Table
      testKey="calendar_table"
      title={title}
      icon={icon}
      data={events}
      columns={setTableColumns ? setTableColumns(to, timezone, useV6Router) : columns(to, timezone, useV6Router)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...tableProps}
    />
  )
}

TableView.defaultProps = {
  title: 'Events',
  icon: 'calendar',
  emptyDescription: 'No events to display',
  localStorageKey: 'calendar',
}

export default withSettings(TableView)
