import React from 'react'
import { connect } from 'react-redux'

import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import DateInput from '../../Forms/DateInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import Checkbox from '../../Forms/Checkbox'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import Permission from '../../Permission'
import Section from '../../Section'
import Select from '../../Forms/Select'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'
import MedicationDescriptionSelect from '../../Forms/elements/MedicationDescriptionSelect'

import { titleCase } from '../../../utils/functions'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { ExportPDFButton } from '../../Buttons/ExportPDFButton'
import { MEDICATION_TIMES } from '../../../utils/constants'

class Medication extends OverlayBase {
  renderHeader = () => {
    const { tenant } = this.props
    const isTrialing = tenant?.plan_status === 'trialing'

    return (
      <>
        <Overlay.Header icon="medications" title="Medication" />

        {!this.state.$editable && !isTrialing && (
          <Permission permission="clients.actions.export">
            <Overlay.SubHeader>
              <ExportPDFButton url={`/meds/${this.state.id}/pdf`} />
            </Overlay.SubHeader>
          </Permission>
        )}
      </>
    )
  }

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { record, tenant, reference, timezone, loading } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          timezone={timezone}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          linked={$new && { resident_id: this.props?.client?.id || reference?.id }}
        >
          <Section>
            <FormSection layout="vertical">
              <Flex gap={16} stretchChildrenX>
                <Select
                  fullWidth
                  label="Status"
                  model="status"
                  defaultValue="active"
                  validations={{
                    presence: {
                      message: 'Please select a status',
                    },
                  }}
                >
                  <Option label="Active" value="active" />
                  <Option label="Inactive" value="inactive" />
                </Select>

                <Select
                  fullWidth
                  label="Source"
                  model="source"
                  defaultValue="internal"
                  validations={{
                    presence: {
                      message: 'Please select a source',
                    },
                  }}
                >
                  <Option label={tenant.name} value="internal" />
                  <Option label="eRX (Dr.First)" value="erx" />
                  <Option label="External Provider (during treatment)" value="external_during" />
                  <Option label="External Provider (prior to treatment)" value="external_prior" />
                  <Option label="Private Providers" value="private_provider" />
                </Select>
              </Flex>

              <Input
                model="name"
                label="Medication Name"
                validations={{
                  presence: {
                    message: 'Please enter a medication name',
                  },
                }}
              />

              <Flex gap={16} stretchChildrenX>
                <MedicationDescriptionSelect
                  label="Medication Description"
                  model="description"
                  validations={{
                    presence: {
                      message: 'Please select a source',
                    },
                  }}
                />
              </Flex>

              <Flex gap={8} stretchChildrenX>
                <Input
                  model="dosage"
                  label="Dosage"
                  type="number"
                  step="0.001"
                  min="0"
                  validations={{
                    presence: {
                      message: 'Please enter a dosage',
                    },
                  }}
                />

                <Input
                  model="unit"
                  label="Unit Type"
                  labelDescription="(eg. mg, g, mL)"
                  validations={{
                    presence: {
                      message: 'Please enter a unit',
                    },
                  }}
                />

                <Input
                  model="quantity"
                  label="Quantity"
                  type="number"
                  size={5}
                  validations={{
                    presence: {
                      message: 'Please enter a quantity',
                    },
                  }}
                />
              </Flex>

              <Input
                label="Standard Dose Quantity"
                model="default_quantity"
                description={
                  $editable &&
                  'This is the default quantity that will be administered to the client. Used as reference when logging medications.'
                }
                placeholder="e.g. 1-2 pills by mouth every 4 hours"
              />

              <Flex gap={8} stretchChildrenX horizontal>
                <DateInput
                  model="started_at"
                  label="Start Date"
                  inputGroupMaxWidth={false}
                  validations={{
                    presence: {
                      message: 'Please enter a start date',
                    },
                  }}
                />

                <DateInput
                  model="ended_at"
                  label="End Date"
                  inputGroupMaxWidth={false}
                  validations={{
                    presence: {
                      message: 'Please enter an end date',
                    },
                  }}
                />
              </Flex>

              <CheckboxGroup label="Medication Time" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
                {Object.entries(MEDICATION_TIMES).map(([key, value]) => (
                  <Checkbox label={value} model={`medication_time.${key}`} />
                ))}
              </CheckboxGroup>

              <Flex gap={8}>
                <Input label="Prescription #" model="prescription_no" />
                <Input className="!flex-auto" label="Prescribing Physician" model="prescription_by" />

                {/* <ObjectSelector
                  className="!flex-auto"
                  label="Prescribing Physician"
                  model="prescribed_by"
                  type="employees"
                  icon="employees"
                  selectTitle={(data: any) => data.name}
                  selectDescription={(data: any) => titleCase(data.position)}
                /> */}
              </Flex>

              <Textarea useQuickText model="notes" label="Instructions / Notes" />
              <Textarea useQuickText model="refill" label="Refill Notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this medication">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} disableLinks={this.isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission={$new ? 'medications.create' : 'medications.edit'}
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="medications.edit"
            />

            <Permission permission="medications.delete">
              <DeleteDialog
                title="Delete Medication?"
                message="Are you sure you want to delete this medication? This action cannot be undone."
                onYes={this.delete}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={this.props.loading}
                  fullWidth
                  permission="medications.delete"
                />
              </DeleteDialog>
            </Permission>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'meds')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'meds')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(Medication))
