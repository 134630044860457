import React from 'react'
import get from 'lodash/get'
import { v4 as uuid } from 'uuid'
import isEqual from 'react-fast-compare'

import { abortDataFetching, clearData } from '../../actions/data'

import Button from '../Button'
import Grid from '../Grid'
import DataGrid from '../DataGrid'
import State from '../State'

// TODO (Andrei): rename to SmartView everywhere
export class SmartContentBase extends React.Component {
  constructor(props) {
    super(props)

    const initialModel = props.form.getInitialModel()
    const value = props.value || get(initialModel, props.model, '')
    const signedOff = initialModel.status === 'signed_off'

    this.state = {
      type: 'SMARTCONTENT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value,
      errors: [],
      $static: signedOff,
      $loading: !signedOff,
      isDirty: false,
      isPristine: true,
    }
  }

  componentDidMount = () => {
    // register element with the form
    if (this.props.form) this.props.form.register(this.state)

    // fetch data
    if (this.props.client.id) this.fetchData()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.client.id !== this.props.client.id) this.fetchData()

    if (!isEqual(prevProps.list, this.props.list) && this.props.form) {
      this.props.form.change({
        ...this.state,
        value: this.props.list,
      })
    }
  }

  componentWillUnmount = () => {
    this.props.abortDataFetching()
    this.props.clearData()

    // register element with the form
    if (this.props.form) this.props.form.deregister(this.state)
  }

  fetchData = async () => {
    if (this.state.$static) return

    this.setState({ $loading: true })
    await this.getData(this.props.client.id)
    this.setState({ $loading: false })
  }

  getSnapshot = () => {
    this.setState({ snapshot: this.props.list })
  }

  renderElement = () => null
  renderTrigger = () => null

  render() {
    const { $loading, value, $static, errors } = this.state
    const { icon, list, title, isEditable, emptyDescription, blockedDescription } = this.props

    let data = list
    if ($static) data = value

    const isEmpty = Object.keys(data).length === 0
    const hasErrors = errors.length > 0

    return (
      <State
        icon={icon}
        title={title}
        isLoading={$loading}
        isEmpty={isEmpty}
        hasError={hasErrors}
        blockedDescription={blockedDescription}
        errorActions={<Button label="Try again" type="minimal" icon="ic_refresh" onClick={this.fetchData} />}
        emptyActions={isEditable && this.renderTrigger()}
        emptyDescription={emptyDescription}
      >
        <Grid gap={8}>
          <DataGrid data={data} useSearch={false} render={(element) => this.renderElement(element, $static)} />
          {isEditable && this.renderTrigger()}
        </Grid>
      </State>
    )
  }
}

SmartContentBase.defaultProps = {
  isEditable: false,
  client: {},
}

export const defaultMapDispatchToProps = (dispatch, name) => {
  return {
    abortDataFetching: () => dispatch(abortDataFetching()),
    clearData: () => dispatch(clearData(name)),
  }
}

export const defaultMapStateToProps = (state, name) => {
  return {
    loading: get(state, `data.${name}.loading`),
    list: get(state, `data.${name}.data`, {}),
  }
}
