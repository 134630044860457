import React from 'react'
import size from 'lodash/size'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, PageLayout, PageSection, Card, Grid, Alert, Flex, Logo, PortalsContainer } from '@behavehealth/components'

import StripePaymentMethods from '@behavehealth/components/Stripe/behave/StripePaymentMethods'
import BillingInvoicesTable from '@behavehealth/components/Tables/BillingInvoicesTable'

import { initialize, setup } from '../modules/init'
import { apiGet } from '@behavehealth/modules/api'

const SelectPlan = () => {
  const history = useHistory()

  const user = useSelector((state) => state.me.user)

  const invoicesLoading = useSelector((state) => state.data.invoices?.loading)
  const invoices = useSelector((state) => state.data.invoices?.data)
  const isInvoicesEmpty = size(invoices) <= 0

  const isBehave = user.type === 'bh_employee'
  const isManagement = ['owner', 'administrator'].includes(user.position)

  const onSuccess = () => {
    history.push('/dashboard')
  }

  React.useEffect(() => {
    const init = async () => {
      await initialize()
      await setup()

      await apiGet({ name: 'invoices', url: `/invoices` })
    }

    init()
  }, [])

  return (
    <PageLayout className="max-w-[1200px] p-4 my-0 mx-auto">
      <Grid gap="5rem">
        <Flex justifyContent="center" className="!my-8 !mx-0">
          <Logo logo="ehr" />
        </Flex>

        <PageSection>
          <Grid gap="2rem">
            {isBehave || isManagement ? (
              <>
                <PageSection.Header>
                  <Alert type="positive" glyph="info">
                    Your account has unpaid invoices. To continue, please pay invoices below and log back in.
                  </Alert>
                </PageSection.Header>

                <StripePaymentMethods />

                <PageSection.Content>
                  <Card>
                    <BillingInvoicesTable
                      isLoading={invoicesLoading}
                      isEmpty={isInvoicesEmpty}
                      data={invoices}
                      to={(id: string) => `${url}/invoices/${id}`}
                    />
                  </Card>
                </PageSection.Content>
              </>
            ) : (
              <PageSection.Header>
                <Alert type="positive" glyph="info">
                  Your account access has been disabled. Account Owner or Administrator should contact Behave Health at{' '}
                  <a href="mailto:contact@behavehealth.com">contact@behavehealth.com</a> to reactivate account
                </Alert>
              </PageSection.Header>
            )}
          </Grid>
        </PageSection>

        <Grid gap="1rem" centerX>
          <Button glyph="chat" type="primary" label="Get in touch with us" color="green" onClick={() => window.Beacon('open')} />
          <Button type="link" label="Log Out" color="red" link="/logout" />
        </Grid>
      </Grid>

      <PortalsContainer />
    </PageLayout>
  )
}

const styles = {
  divider: {
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
  },
}

export default SelectPlan
