import React from 'react'

import { CLINICAL_ASSESSMENTS_SLUGS, MEDICAL_ASSESSMENTS_SLUGS, NURSING_SLUGS } from '../../utils/constants'
import { getVersionSlug, initials, getClientLink } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import LevelOfCareSelect from '../../components/Forms/LevelOfCareSelect'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Status from '../../components/Status'

import { SectionCard } from '../../components/SectionCard'

const ROSTER_CATEGORY = {
  clinical_note: 'Clinical Note',
  ua_test: 'UA Test',
  breathalyzer_test: 'Breathalyzer Test',
  custom_note: 'Custom Note',
  clinical_assessment: 'Clinical Assessment',
  medical_assessment: 'Medical Assessment',
  nursing_assessment: 'Nursing Assessment',
}

const DATA_FORM_ROUTES = {
  clinical_assessment: (record: any) => {
    const version = record?.version
    const subcategory = record?.subcategory
    const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

    return `/clinical-assessments/${CLINICAL_ASSESSMENTS_SLUGS[subcategory]}/${record.id}${formattedVersion}`
  },
  medical_assessment: (record: any) => `/medical-assessments/${MEDICAL_ASSESSMENTS_SLUGS[record?.subcategory]}/${record.id}`,
  nursing: (record: any) => `/nursing/${NURSING_SLUGS[record?.subcategory]}/${record.id}`,
}

const ROSTER_ROUTES = {
  clinical_note: (record: any) => `/clinical-notes/all/${initials(record.category)}/${record.id}`,
  ua_test: (record: any) => `/test-results/ua-tests/${record.id}`,
  breathalyzer_test: (record: any) => `/test-results/breathalyzer-tests/${record.id}`,
  custom_note: (record: any) => `/custom-notes/${record.id}`,
  data_form: (record: any) => DATA_FORM_ROUTES?.[record?.category]?.(record),
}

const RootRosterOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    data,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isSaving,
    isOverlayLoading,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'insurance_roster_item',
    endpoint: '/insurance_roster_items',
    invalidate: 'insurance_roster_items',
    options: props,
  })

  const { timezone } = useSettings()

  const rosterItemLink = React.useMemo(() => {
    if (!data?.client || !data?.record) return null

    const recordType = data.record.type
    const clientLink = getClientLink(data.client)
    const recordLink = ROSTER_ROUTES?.[recordType]?.(data.record)

    if (!clientLink || !recordLink) return null

    return `${clientLink}${recordLink}`
  }, [data])

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={58} />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} maxWidth={58}>
      <Overlay.Header icon="roster" title="Roster Item" />
      <Overlay.SubHeader>
        <Flex centerY gap={8}>
          <Button
            label={`Claim: ${data?.insurance_claim?.identifier || '–'}`}
            to={'/claims/id'}
            size={200}
            type="minimal"
            color="blue"
            isDisabled={!data?.insurance_claim?.identifier}
          />
          <Button
            label={`Service Line: ${data?.insurance_claim_service_line?.identifier || '–'}`}
            to={'/claims/id'}
            size={200}
            type="minimal"
            color="blue"
            isDisabled={!data?.insurance_claim_service_line?.identifier}
          />
        </Flex>
      </Overlay.SubHeader>

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <Grid gap="0.75rem">
              <SectionCard title="Roster">
                <Flex gap="1rem">
                  <Input isEditable={false} label="ID" model="identifier" />

                  <Select isEditable={false} label="Status" model="status">
                    <Option label="New" value="draft" />
                    <Option label="Flagged" value="flagged" />
                    <Option label="Processed" value="processed" />
                    <Option label="Archived" value="archived" />
                  </Select>

                  <DateInput label="Service Date" model="service_date" />
                  <MiniRichTextEditor label="Biller Notes" model="biller_notes" />
                </Flex>
              </SectionCard>

              <SectionCard
                title="Documentation"
                aside={rosterItemLink && <Button label="View Record →" link={rosterItemLink} size={200} type="minimal" color="blue" />}
              >
                <Flex gap="1rem">
                  <Input isEditable={false} label="Record Type" value={ROSTER_CATEGORY[data?.category] || '–'} />

                  <Select isEditable={false} label="Status" model="record_status">
                    <Option label="Draft" value="draft" />
                    <Option label="Updates Required" value="updates_required" />
                    <Option label="Pending Review" value="pending_review" />
                    <Option label="Draft" value="client_draft" />
                    <Option label="Client Updates Required" value="client_updates_required" />
                    <Option label="Draft" value="employee_draft" />
                    <Option label="Pending Staff Review" value="employee_pending_review" />
                    <Option label="Pending Supervisor Review" value="supervisor_pending_review" />
                    <Option label="Staff Updates Required" value="employee_updates_required" />
                    <Option label="Signed Off" value="signed_off" />
                    <Option label="Collected" value="collected" />
                    <Option label="Refused Collection" value="refused" />
                    <Option label="N/A" value="not_available" />
                  </Select>

                  <OverlaySelector
                    isEditable={false}
                    label="Supervisor"
                    model="record_supervisor"
                    type="employees.active"
                    icon="employees"
                    selectTitle={(data: any) => data.name}
                  />

                  <DateTimeInput isEditable={false} label="Added Date" model="record_created_at" />
                  <DateTimeInput isEditable={false} label="Start Date and Time" model="record_started_at" />
                  <DateTimeInput isEditable={false} label="End Date and Time" model="record_ended_at" />
                  <DateTimeInput isEditable={false} label="Sign-Off Date" model="record_signed_off_at" />

                  <Input type="number" step="1" min="0" suffix="minutes" isEditable={false} label="Session Duration" />

                  <CheckboxGroup label="Client Presence" trueIcon="check" falseIcon="cross" falseStyle="none">
                    <Checkbox
                      isEditable={false}
                      label={data?.record_client_presence ? 'Present' : 'Absent'}
                      model="record_client_presence"
                    />
                  </CheckboxGroup>
                </Flex>
              </SectionCard>

              <SectionCard title="Author">
                <Flex gap="1rem">
                  <OverlaySelector
                    isEditable={false}
                    label="Author"
                    model="record_author"
                    type="employees.active"
                    icon="employees"
                    selectTitle={(data: any) => data.name}
                  />

                  <DateTimeInput isEditable={false} label="Author Submit Date" model="record_author_submitted_at" />
                  <DateTimeInput isEditable={false} label="Author Sign-Off Date" model="record_author_signed_off_at" />
                </Flex>
              </SectionCard>

              <SectionCard title="Client">
                <Flex gap="1rem">
                  <OverlaySelector isEditable={false} label="Client" type="clients.current" icon="clients" model="client" />
                  <LevelOfCareSelect isEditable={false} label="Client Level of Care" model="client_level_of_care" />
                  <Input isEditable={false} label="Diagnoses Codes" model="client_diagnoses_codes" />

                  <ObjectSelector
                    isEditable={false}
                    label="Insurance"
                    model="client_insurance_policy"
                    selectTitle={(data: any) => data.insurance_name}
                    selectDescription={(data: any) => <Status label={data.category} color="gray" />}
                  />
                </Flex>
              </SectionCard>

              <SectionCard title="Authorization">
                <Flex gap="1rem">
                  <Input isEditable={false} label="Authorization #" value="1234" />
                  <LevelOfCareSelect
                    isEditable={false}
                    label="Authorization Level of Care"
                    model="client_insurance_authorization_level_of_care"
                  />

                  <CheckboxGroup label="Service within Authorization Period" trueIcon="check" falseIcon="cross" falseStyle="none">
                    <Checkbox
                      isEditable={false}
                      label={data?.client_insurance_authorization_service_in_period ? 'Yes' : 'No'}
                      model="client_insurance_authorization_service_in_period"
                    />
                  </CheckboxGroup>

                  <Input
                    isEditable={false}
                    type="number"
                    step="1"
                    min="0"
                    label="Sessions Authorized"
                    model="client_insurance_authorization_sessions_authorized"
                  />

                  <Input
                    isEditable={false}
                    type="number"
                    step="1"
                    min="0"
                    label="Sessions Billed"
                    model="client_insurance_authorization_sessions_billed"
                  />
                </Flex>
              </SectionCard>
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="insurance_roster.edit"
            />

            <DeleteDialog
              title="Delete Roster?"
              message="Are you sure you want to delete this Roster? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="insurance_roster.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const RosterOverlay = withOverlayError(RootRosterOverlay)
