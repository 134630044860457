import React from 'react'

import Button from '../../components/Button'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Flex from '../../components/Flex'

import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import RichTextEditor from '../../components/Forms/RichTextEditor'
import SignaturePad from '../../components/Forms/SignaturePad'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

import { apiCreate, apiUpdate } from '../../modules/api'

const AddendumOverlay = ({ addendum, reference, onClose, onUpdate }: any) => {
  const isNew = !addendum?.id
  const form = React.useRef()

  const [loading, setLoading] = React.useState(false)
  const [valid, setValid] = React.useState(false)

  const onSave = async () => {
    setLoading(true)

    try {
      await apiCreate({
        url: `/addendums`,
        params: {
          reference_id: reference?.id,
          reference_type: reference?.type,
          ...form.current.getFormValue(),
        },
        notify: false,
      })

      if (onUpdate) onUpdate()
      if (onClose) onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const updateAddendum = async () => {
    setLoading(true)

    try {
      await apiUpdate({
        url: `/addendums/${addendum.id}`,
        params: form.current.getFormValue(),
        notify: false,
      })

      if (onUpdate) onUpdate()
      if (onClose) onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={69} onClose={onClose}>
      <Overlay.Header
        title={isNew ? 'Add New Addendum' : 'Edit Addendum'}
        description={!isNew && <DataFormStatus status={addendum?.status} />}
      />

      <Overlay.Content>
        <Form getForm={form} initialModel={addendum} onValidationUpdate={setValid}>
          <Section>
            <FormSection maxWidth="100%">
              <Flex gap="1rem" stretchChildrenX>
                <Input
                  label="Title"
                  model="title"
                  validations={{
                    presence: {
                      message: 'Please add a title',
                    },
                  }}
                />

                <ObjectSelector
                  isPolymorphic
                  label="Supervisor"
                  blankLabel="Select Supervisor…"
                  icon="employees"
                  type="clinical_supervisors"
                  model="supervisor"
                  selectTitle={(data) => data.name}
                  validations={{
                    presence: {
                      message: 'Please select a Supervisor',
                    },
                  }}
                />
              </Flex>
              <RichTextEditor useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Section>
            {addendum?.author_signature && (
              <SignaturePad
                label="Author Signature"
                person={addendum.author}
                allowPin={false}
                model="author_signature"
                signedAtModel="author_signed_at"
              />
            )}

            {addendum?.supervisor_signature && (
              <SignaturePad
                label="Supervisor Signature"
                person={addendum.supervisor}
                allowPin={false}
                model="supervisor_signature"
                signedAtModel="supervisor_signed_at"
              />
            )}
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isNew ? (
          <Button label="Save as Draft" type="primary" color="green" isLoading={loading} onClick={onSave} isDisabled={!valid || loading} />
        ) : (
          <Button
            label="Save Changes"
            type="primary"
            color="green"
            isLoading={loading}
            onClick={updateAddendum}
            isDisabled={!valid || loading}
          />
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export default AddendumOverlay
