import React from 'react'
import clsx from 'clsx'

import { useEditor, EditorContent } from '@tiptap/react'

import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import Strike from '@tiptap/extension-strike'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'

import { RICH_TEXT_STYLES } from '../theme/defs/styles'

const Markup = ({ value, className }: any) => {
  const valueRef = React.createRef()

  const editor = useEditor({
    content: value || '–',
    editable: false,
    extensions: [
      Highlight.configure({ multicolor: true }),
      Image.configure({ inline: true }),
      Link.configure({
        protocols: ['tel', 'mailto'],
        linkOnPaste: true,
        openOnClick: false,
        HTMLAttributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      }),
      StarterKit,
      // Strike,
      Table.configure({ resizable: true }),
      TableCell,
      TableHeader,
      TableRow,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Color,
      TextStyle,
      Underline,
      TaskList,
      TaskItem,
    ],
  })

  React.useEffect(() => {
    if (valueRef.current !== value && editor) {
      editor.commands.setContent(value)
      valueRef.current = value
    }
  }, [value])

  return <EditorContent editor={editor} className={clsx('is-readonly', className)} css={RICH_TEXT_STYLES} />
}

export default Markup
