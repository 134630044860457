export const RELATIONSHIP_TO_CLIENT_CODE = {
  '01': 'Spouse',
  '19': 'Child',
  '20': 'Employee',
  '21': 'Unknown',
  '39': 'Organ Donor',
  '40': 'Cadaver Donor',
  '53': 'Life Partner',
  G8: 'Other Relationship',
}
