import React from 'react'
import size from 'lodash/size'

import { titleCase } from '../../utils/functions'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Chotomate from '../../components/Chotomate'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Link from '../../components/Link'

import AmountInput from '../../components/Forms/AmountInput'
import Attachments from '../../components/Forms/Attachments'
import DateInput from '../../components/Forms/DateInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Textarea from '../../components/Forms/Textarea'
import ContextShow from '../../components/Forms/ContextShow'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import OverlayLoader from '../../components/OverlayLoader'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import produce from 'immer'

const RootOrganizationCreditOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    record,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    anonymize,
    data,
  } = useOverlay({
    name: 'financial-transactions',
    endpoint: '/financial_transactions',
    invalidate: 'financial-transactions',
    options: props,
    parentType: 'organization',
    parentRequest: '/organizations',
  })

  const { timezone } = useSettings()
  const [formData, setFormData] = React.useState(initialModel)

  const save = () => {
    const saveData = produce(formData, (draft) => {
      if (draft.reference_category === 'contact' && draft.contact_reference_id) {
        draft.reference_id = draft.contact_reference_id
        draft.reference_type = draft.contact_reference_type
        return
      }

      if (draft.reference_category === 'organization' && draft.organization_reference_id) {
        draft.reference_id = draft.organization_reference_id
        draft.reference_type = draft.organization_reference_type
        return
      }

      draft.reference_id = null
      draft.reference_type = null
    })

    saveWithData(saveData)
  }

  if (isOverlayLoading || size(record) === 0) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="financials" title={isNew ? 'Add Credit' : 'Credit'} />

      <Overlay.Content>
        <Chotomate ready name="credit_overlay" />

        {record?.financial_plan ? (
          <Form
            key={`updated-${data?.updated_at}`}
            getForm={form}
            initialModel={produce(initialModel, (draft) => {
              if (draft.reference_category === 'contact' && draft.reference?.type === 'contact') {
                draft.contact_reference = draft.reference
                return
              }

              if (draft.reference_category === 'organization' && draft.reference?.type === 'organization') {
                draft.organization_reference = draft.reference
                return
              }
            })}
            onUpdate={setFormData}
            timezone={timezone}
            isEditable={isEditable}
            onValidationUpdate={onValidationUpdate}
            linked={
              isNew && {
                category: 'credit',
                financial_plan_id: record?.financial_plan?.id,
              }
            }
          >
            <Section>
              <FormSection layout="vertical">
                <Input
                  label="Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a charge name',
                    },
                  }}
                />

                <Flex gap={16}>
                  <AmountInput
                    label="Amount"
                    model="amount"
                    isEditable={isNew || (isEditable && initialModel.source === 'app')}
                    validations={{
                      presence: {
                        message: 'Please enter an amount',
                      },
                      numericality: {
                        greaterThan: 0,
                        message: 'Please enter an amount',
                      },
                    }}
                  />

                  <DateInput
                    defaultToNow
                    label="Credit Date"
                    model="transactioned_at"
                    fullWidth
                    validations={{
                      presence: {
                        message: 'Please set a credit date',
                      },
                    }}
                  />
                </Flex>

                <Divider className="!m-0" />

                <RadioGroup label="Payer Type" model="reference_category" layout="horizontal-dense" defaultValue="organization">
                  <Radio label="Organization" value="organization" />
                  <Radio label="Contact" value="contact" />
                </RadioGroup>

                {!isEditable ? (
                  <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="reference" />
                ) : (
                  <>
                    <ContextShow when="reference_category" is="organization">
                      <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="organization_reference" value={record} />
                    </ContextShow>

                    <ContextShow when="reference_category" is="contact">
                      <ObjectSelector
                        isPolymorphic
                        icon="contacts"
                        label="Payer"
                        model="contact_reference"
                        type="organization.contacts"
                        description={
                          <>
                            Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                          </>
                        }
                        dependentValue={record?.id}
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => titleCase(data?.relationship)}
                      />
                    </ContextShow>
                  </>
                )}

                <Divider className="!mt-2 !mx-0 !mb-0" />

                <Textarea useQuickText label="Notes" model="notes" />
              </FormSection>
            </Section>

            {!anonymize && (
              <>
                <Divider />

                <Section hide={anonymize} headingType="h2" title="Attachments" description="Upload the files related to this credit">
                  <FormSection layout="vertical">
                    <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
                  </FormSection>
                </Section>
              </>
            )}
          </Form>
        ) : (
          <Alert type="negative" className="!m-4">
            The data required to create <strong>Credits</strong> could not be loaded from our servers. To try again, please close this
            Overlay and refresh. If this problem persists, please get in touch with Support.
          </Alert>
        )}
      </Overlay.Content>

      {!anonymize && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save Credit"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission="ledger.create"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label="Edit Credit"
                type="default"
                isDisabled={isSaving}
                onClick={edit}
                flex="100 1 auto"
                permission="ledger.edit"
              />

              <DeleteDialog
                title="Delete Credit?"
                message="Are you sure you want to delete this credits record? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  fullWidth
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  permission="ledger.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const OrganizationCreditOverlay = withOverlayError(RootOrganizationCreditOverlay)
