import React from 'react'
import { keyframes } from '@emotion/react'
import clsx from 'clsx'

import { apiUpdate } from '../modules/api'
import { COLORS, MEDIA_QUERY, TOKENS } from '../theme'
import { Plan } from '../declarations/types/Plan'
import { Addon } from '../declarations/types/Addon'
import { Icon as IconType } from '../declarations/types/Icon'
import { Glyph as GlyphType } from '../declarations/types/Glyph'

import { Text } from './Typography'
import ChecklistItemPlans from './ChecklistItemPlans'
import Flex from './Flex'
import Glyph from './Glyph'
import HelpTagIframe from './Help/HelpTagIframe'
import Icon from './Icon'
import Link from './Link'

type Props = {
  render?: Function
  noDependent?: boolean
  noDependentMessage?: string
  icon?: IconType
  glyph?: GlyphType
  to?: string
  href?: string
  title?: string
  description?: string
  item?: string
  plan?: Plan
  addon?: Addon
  help?: React.ReactNode
  onClick?: Function
  updateChecklist?: Function
  list?: string
  data?: any
} & React.HTMLAttributes<any>

const ChecklistItem: React.FC<Props> = (props) => {
  const {
    render,
    className,
    noDependent,
    noDependentMessage,
    icon,
    glyph,
    to,
    href,
    title,
    description,
    item,
    plan,
    addon,
    help,
    onClick,
    updateChecklist,
    list,
    data,
  } = props

  const checklistItem = data?.[item]
  const isCompleted = checklistItem === 'completed'
  const isDisabled = noDependent

  const classNames = clsx({
    [className]: className,
    'is-completed': isCompleted,
    'has-required-dependent': isDisabled,
  })

  const check = () => {
    if (isCompleted || isDisabled) return

    apiUpdate({
      name: 'tenant',
      url: `/me/tenant`,
      params: {
        [list]: {
          ...data,
          [item]: 'completed',
        },
      },
      reducer: 'me',
      notify: false,
    })
  }

  if (updateChecklist) updateChecklist(item, isCompleted)

  return (
    <div css={styles.root} className={classNames} onClick={check}>
      <div className="py-[0.6rem] pr-0 pl-[0.8rem]">
        <>
          {isCompleted && (
            <div css={styles.completed}>
              <Glyph glyph="check" size="0.7rem" />
            </div>
          )}

          {!isCompleted && (
            <Flex className="w-[18px] h-[18px] items-center justify-center">
              {icon && <Icon icon={icon} className="checklist-item__icon" />}
              {glyph && <Glyph glyph={glyph} className="checklist-item__icon" />}

              <div className="checklist-item__check-circle">
                <Glyph glyph="check" size="0.7rem" />
              </div>
            </Flex>
          )}
        </>
      </div>

      {title && (
        <Flex vertical className="!flex-1">
          <Link
            type="minimal"
            after={<Glyph glyph="chevron_right" />}
            css={styles.title}
            to={to}
            href={href}
            target={to ? '_self' : '_blank'}
            className="checklist-item__header"
            onClick={onClick}
          >
            <div>{title} →</div>
            <div>
              {isDisabled && (
                <Text css={styles.description} glyph="warning">
                  {noDependentMessage}
                </Text>
              )}
              {description && <Text css={styles.description}>{description}</Text>}
            </div>
          </Link>
        </Flex>
      )}

      {(plan || addon) && <ChecklistItemPlans plan={plan} addon={addon} />}

      {render && <div className="py-[0.6rem] pr-0 pl-[0.8rem]">{render({ isCompleted })}</div>}

      <div css={styles.help}>
        {help || (
          <div css={styles.hiddenHelp}>
            <HelpTagIframe id="programs" label="Learn" css={styles.hiddenHelp} />
          </div>
        )}
      </div>
    </div>
  )
}

const checkAnimation = keyframes`
  0% {
    transform: scale(.5);
  }
  100% {
    transform: scale(1);
  }
`

const styles: Record<string, any> = {
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.lightGray}`,
    cursor: 'pointer',

    '& .checklist-item__check-circle': {
      width: 18,
      height: 18,
      borderRadius: '100%',
      border: `2px solid ${COLORS.paleGreen}`,
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',

      svg: {
        display: 'none',
        opacity: 0.6,
      },

      '&:hover': {
        background: COLORS.hover,
      },
    },

    '&.has-required-dependent': {
      '& .checklist-item__header': {
        pointerEvents: 'none',
        color: COLORS.textMuted,
      },

      '&:hover': {
        '& .checklist-item__icon': {
          display: 'block',
        },

        '& .checklist-item__check-circle': {
          display: 'none',
        },
      },
    },

    '&.is-completed': {
      cursor: 'auto',

      '& .checklist-item__header': {
        textDecoration: 'line-through',
      },
    },

    '&:hover': {
      background: COLORS.hover,

      '& .checklist-item__icon': {
        display: 'none',
      },

      '& .checklist-item__check-circle': {
        display: 'flex',
        animation: `${checkAnimation} 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)`,

        svg: {
          display: 'block',
        },
      },
    },

    '&:last-child': {
      borderBottom: 0,
    },
  },

  completed: {
    width: 18,
    height: 18,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: COLORS.green,

    svg: {
      fill: COLORS.white,
    },
  },

  check: {
    animation: `${checkAnimation} 200ms ${TOKENS.easeOutCubic}`,
  },

  title: {
    fontSize: '1rem',
    padding: '0.6rem 0.8rem',
    // color: `${COLORS.text}`,
  },

  description: {
    fontSize: '0.9rem',
    marginTop: '0.2rem',
  },

  help: {
    display: 'none',
    paddingRight: '0.8rem',

    [`${MEDIA_QUERY[1]}`]: {
      display: 'block',
    },
  },

  hiddenHelp: {
    opacity: 0,
    visibility: 'hidden',
    pointerEvents: 'none',
  },
}

ChecklistItem.defaultProps = {}

export default ChecklistItem
