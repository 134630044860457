import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { arrayToMapWithKey } from '../../../utils/functions'
import { COLORS } from '../../../theme'
import { useFormField } from '../../Forms/hooks/useFormField'
import { useStore } from '../utils/useStore'
import { withFormContext } from '../../Forms/context'

import { PopoverMenu, CustomPopoverMenuItem } from '../../../components/PopoverMenu'
import Glyph from '../../../components/Glyph'

export const RootSelectCell = (props: any) => {
  const { canEdit, column, form, isEditing, model, onBlur, setValue, validations, value, renderAfterCell, renderMenuBefore, onCellUpdate } =
    props

  const { formActions, formState } = useFormField({
    model: model,
    form: form,
    validations: validations,
    isValid: size(validations) === 0,
  })

  const { isInvalid } = formState

  const updateField: any = useStore((state: any) => state.updateField)
  const showInvalid: any = useStore((state: any) => state.showInvalid)

  const [isOpen, setIsOpen] = React.useState(isEditing)

  const options = column?.config?.options || []

  const optionsByValue = React.useMemo(() => {
    return arrayToMapWithKey(options, 'value')
  }, [options])

  const activeOption = React.useMemo(() => {
    return optionsByValue[`${value}`]
  }, [optionsByValue, value])

  // Sync props value with state value
  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  // Update form state
  React.useEffect(() => {
    if (isUndefined(value)) return

    let newValue = value

    if (value === 'true') newValue = true
    else if (value === 'false') newValue = false

    formActions.setValue(newValue)
  }, [value])

  const triggerClasses = clsx(size(activeOption) === 0 && 'is-empty', isEditing && 'is-editing', isInvalid && showInvalid && 'is-invalid')

  if (size(options) === 0) return null

  const trigger = (
    <div className={triggerClasses} css={STYLES.trigger}>
      {activeOption && (
        <>
          {activeOption.glyph && <Glyph glyph={activeOption.glyph} size={10} className="select-trigger-glyph" />}

          <div className="select-trigger-label">{activeOption.label}</div>
        </>
      )}

      {/* {!activeOption && 'Select…'} */}

      {canEdit && <Glyph glyph="triangle_down" size={9} className="select-trigger-triangle" />}

      {renderAfterCell && renderAfterCell()}
    </div>
  )

  if (!canEdit) return trigger

  return (
    <>
      <PopoverMenu
        isOpen={isOpen}
        trigger={trigger}
        // side={null}
        // align={null}
        onOpenUpdated={(currentIsOpen: boolean) => {
          setIsOpen(currentIsOpen)
          if (!currentIsOpen) onBlur()
        }}
      >
        {renderMenuBefore && renderMenuBefore()}

        {options.map((option: any) => (
          <SelectItem
            key={option.label}
            label={option.label}
            glyph={option.glyph}
            color={option.color}
            isActive={activeOption?.value === option.value}
            onClick={() => {
              setValue(option.value)
              updateField?.({ model, value: option.value })
              onCellUpdate?.({ model, value: option.value })
            }}
          />
        ))}
      </PopoverMenu>
    </>
  )
}

const SelectItem = (props: any) => {
  const { label, isActive, onClick, color: propsColor, glyph = 'check' } = props

  const color = propsColor ? COLORS[propsColor] || propsColor : COLORS.green

  const style: any = React.useMemo(() => {
    return { '--highlight-color': tint(0.92, color) }
  }, [color])

  return (
    <CustomPopoverMenuItem style={style} css={STYLES.selectItem} isActive={isActive} onClick={onClick}>
      <div className="select-item-graphic">
        <Glyph glyph={glyph} size={10} color={color} />
      </div>

      <div>{label}</div>
    </CustomPopoverMenuItem>
  )
}

const STYLES = {
  trigger: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
    padding: '0.2rem',
    paddingLeft: '0.4rem',
    paddingRight: '1.2rem',
    overflow: 'hidden',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    '.select-trigger-label': {
      fontWeight: 500,
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '.select-trigger-glyph': {
      marginRight: '0.5rem',
      pointerEvents: 'none',
    },

    '.select-trigger-triangle': {
      position: 'absolute',
      right: '0.25rem',
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },

    '&.is-empty': {
      fontStyle: 'italic',
      color: COLORS.textStronglyMuted,
    },

    '&.is-editing': {
      borderRadius: 3,
      background: COLORS.white,
      boxShadow: 'var(--input-focus-box-shadow)',
    },

    '&.is-invalid': {
      display: 'block',
      background: tint(0.85, COLORS.red),
    },
  },

  selectItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    fontSize: '0.88rem',
    width: '100%',
    lineHeight: 'normal',
    paddingTop: '0.4rem',
    paddingBottom: '0.4rem',
    paddingRight: '1rem',
    fontWeight: 600,

    '&:hover': {
      fontWeight: 600,
      background: COLORS.hover,
    },

    '.select-item-graphic': {
      opacity: 0,
      visibility: 'hidden',
      width: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '&.is-active': {
      backgroundColor: 'var(--highlight-color)',

      '&:hover': {
        backgroundColor: 'var(--highlight-color)',
      },

      '.select-item-graphic': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
}

export const SelectCell: any = withFormContext(RootSelectCell)
