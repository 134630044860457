import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const ReferralsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'resident',
        model: 'data.resident.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.data.id} value={data.data.name} avatar={data.data.resident.avatar} to={to?.(data.data.resident)} />
        ),
      },
      {
        width: 180,
        id: 'organization',
        model: 'data.organization.name',
        title: 'Referral Organization',
        type: 'profile',
      },
      {
        width: 160,
        id: 'referral_notes',
        model: 'data.referral_notes',
        title: 'Notes',
        type: 'rich_text',
      },
      {
        width: 160,
        id: 'admitted_at',
        model: 'data.admitted_at',
        title: 'Admitted At',
        type: 'date_time',
      },
      {
        width: 160,
        id: 'discharged_at',
        model: 'data.discharged_at',
        title: 'Discharged At',
        type: 'date_time',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Referrals"
      icon="clients"
      columns={columns}
      filtersConfig={FILTERS.live_reports.referral}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="referrals"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=referrals'}
        />
      }
      {...props}
    />
  )
}
