import React from 'react'

// TODO (Andrei): check auto-recurring charges timeline once API is updated
const MESSAGES: any = {
  charge_builder: (props: any) => (
    <>
      {props.author} added a new {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
  create: (props: any) => (
    <>
      {props.author} added a new {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
  update: (props: any) => (
    <>
      {props.author} updated the {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
  destroy: (props: any) => (
    <>
      {props.author} deleted the {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
}

export default (props: any) => MESSAGES[props.event?.action_type]?.(props) || null
