import React from 'react'

import SmartTextarea from '../../../components/Forms/SmartTextarea'
import ThoughtsPlansSuicidalIntent from '../../../components/Overlays/pages/DataForms/common/ThoughtsPlansSuicidalIntent'

import {
  LEVEL_OF_RISK_AND_INTERVENTIONS_FIELDS,
  PROTECTIVE_FACTORS_FIELDS,
  RISK_FACTORS_FIELDS,
  SNAP_FIELDS,
  SUICIDE_RISK_DOCUMENTATION_FIELDS,
} from './common'

export const SUICIDE_ASSESSMENT_SECTIONS = [
  {
    title: 'Risk Factors',
    model: 'risk_factors',
    fields: RISK_FACTORS_FIELDS,
  },
  {
    title: 'Protective Factors',
    model: 'protective_factors',
    fields: PROTECTIVE_FACTORS_FIELDS,
  },
  {
    title: 'Thoughts, Plans, and Suicidal Intent',
    model: 'thoughts_plans_and_suicidal_intent',
    fields: [
      {
        label: 'Thoughts, Plans, and Suicidal Intent Form',
        model: 'thoughts_plans_suicidal_intent_form',
        component: () => <ThoughtsPlansSuicidalIntent hideNotes />,
      },
      {
        label: 'Notes',
        model: 'notes',
        component: () => <SmartTextarea useQuickText useDictation label="Notes" model="data.thoughts_plans_and_suicidal_intent.notes" />,
      },
    ],
  },
  {
    title: 'Level of Risk and Interventions',
    model: 'level_of_risk_and_interventions',
    fields: LEVEL_OF_RISK_AND_INTERVENTIONS_FIELDS,
  },
  {
    title: 'Strengths, Needs, Abilities, Preferences',
    model: 'snap',
    fields: SNAP_FIELDS,
  },
  {
    title: 'Documentation',
    model: 'suicide_risk_documentation',
    fields: SUICIDE_RISK_DOCUMENTATION_FIELDS,
  },
]
