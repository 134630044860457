import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  done: {
    label: 'Completed',
    color: 'green',
  },
  due_today: {
    label: 'Due Today',
    color: 'orange',
  },
  to_do: {
    label: 'To-Do',
    color: 'blue',
  },
  overdue: {
    label: 'Overdue',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
