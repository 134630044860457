import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { useGet } from '@behavehealth/hooks/useNewAPI'

import { Button, Card, Chotomate, Grid, HelpTagIframe, Link, Page } from '@behavehealth/components'
import { OrganizationContactsTable } from '@behavehealth/components/Tables'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

const Contacts: React.FC = () => {
  const match = useRouteMatch()

  const { resource_id }: any = useParams()
  const [filters, setFilters] = React.useState({})

  const { data, isLoading }: any = useGet({
    name: ['organization', resource_id, 'contacts', { filters }],
    url: `/organizations/${resource_id}/contacts`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const isEmpty = size(data) === 0

  return (
    <Page
      feature="contacts"
      help={<HelpTagIframe id="organization_contacts_and_communications" />}
      actions={
        <>
          <Button label="Add Contact" glyph="add" type="primary" link={`${match.url}/new`} permission="contacts.create" />
        </>
      }
    >
      <Grid gap="1rem" columns="100%">
        <Filters config={FILTERS.organization_contacts} onUpdate={setFilters} localStorageKey="organization_contacts" />

        <Card>
          <Chotomate name="contacts_all" ready={!isLoading} />

          <OrganizationContactsTable
            data={data}
            isLoading={isEmpty && isLoading}
            localStorageKey="organizations_contacts"
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            titleAfter={<Link to="/reports/organization-contacts">View Full Report →</Link>}
            emptyActions={<Button label="Add Contact" glyph="add" type="primary" link={`${match.url}/new`} permission="contacts.create" />}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default Contacts
