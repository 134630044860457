import React from 'react'
import { useParams } from 'react-router-dom'

import { useOverlay } from '../../../hooks/useOverlay'
import { useSettings } from '../../../hooks/useSettings'
import { withOverlayError } from '../../../hocs/withOverlayError'

import Button from '../../../components/Button'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Overlay from '../../../components/Overlay'
import OverlayLoader from '../../../components/OverlayLoader'
import Section from '../../../components/Section'
import Textarea from '../../../components/Forms/Textarea'

const RootClaimNoteOverlay = (props: any) => {
  const { timezone } = useSettings()
  const { insurance_claim_id }: any = useParams()

  const claimID = props.claimID || insurance_claim_id

  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'insurance-claim-note',
    endpoint: '/insurance_claim_notes',
    invalidate: 'insurance-claim-notes',
    options: props,
    Overlay: ClaimNoteOverlay,
  })

  if (isOverlayLoading) return <OverlayLoader showBackdrop={props.showBackdrop} position="right" maxWidth={35} />

  return (
    <Overlay showBackdrop={isEditable || props.showBackdrop} closeOnBackdrop={!isEditable} onClose={close} maxWidth={35}>
      <Overlay.Header icon="claim_notes" title="Claim Note" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && { insurance_claim_id: claimID }}
        >
          <Section>
            <FormSection>
              <Textarea label="Claim Notes" model="notes" minRows={8} />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
            />
            <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete claim note?"
              message="Are you sure you want to delete this claim note? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClaimNoteOverlay = withOverlayError(RootClaimNoteOverlay)
