import React from 'react'
import clsx from 'clsx'
import EmbedContainer from 'react-oembed-container'

import { useGet } from '../../../../hooks/useNewAPI'
import Loader from '../../../../components/Loader'
import State from '../../../../components/State'

import { Box } from './Box'

export const CanvaOEmbed = React.forwardRef((props: any, ref) => {
  const { isEditable, className, element, hoverElement } = props

  const canvaUrl = element?.config?.canva_url

  const queryParams = { url: canvaUrl }

  const { data, isLoading }: any = useGet({
    name: ['canva_oembed', queryParams],
    url: `/apps/vendors/canva_oembed`,
    params: queryParams,
    options: { enabled: !!canvaUrl },
  })

  const rootClasses = clsx('CANVA_OEMBED', !canvaUrl && 'is-empty', isEditable && 'is-editable', className)

  if (!element?.config) return null

  const {
    aspect_ratio_high = 9,
    aspect_ratio_wide = 16,
    iframe_height = 800,
    iframe_height_type = 'pixels',
    iframe_sizing_strategy = 'aspect_ratio',
    iframe_width = 100,
    iframe_width_type = 'percentage',
  } = element.config

  let aspectRatioWide = parseFloat(aspect_ratio_wide) || 3
  let aspectRatioHigh = parseFloat(aspect_ratio_high) || 1
  let iframeWidth = getSizeValue(iframe_width, iframe_width_type)
  let iframeHeight = getSizeValue(iframe_height, iframe_height_type)

  const aspectRatio = (aspectRatioHigh / aspectRatioWide) * 100

  return (
    <Box
      ref={ref}
      element={element}
      className={rootClasses}
      hoverElement={hoverElement}
      css={STYLES.root}
      style={{
        width: iframeWidth,
      }}
    >
      {isLoading && <Loader css={STYLES.loader} />}

      {data?.html && (
        <div
          css={STYLES.iframeWrapper}
          style={{
            ...(iframe_sizing_strategy === 'custom' && {
              height: iframeHeight,
            }),

            ...(iframe_sizing_strategy === 'aspect_ratio' && {
              paddingBottom: `${aspectRatio}%`,
            }),
          }}
        >
          <EmbedContainer markup={data.html}>
            <div dangerouslySetInnerHTML={{ __html: data.html }} />
          </EmbedContainer>
        </div>
      )}

      {isEditable && !canvaUrl && <State isEmpty glyph="embed" title="Canva OEmbed" emptyDescription="Enter an Canva OEmbed URL" />}
    </Box>
  )
})

const SIZE_VALUE_TYPES = {
  percentage: '%',
  pixels: 'px',
  viewport_width: 'vw',
  viewport_height: 'vh',
}

const getSizeValue = (value: any, type: 'percentage' | 'pixels' | 'viewport_width' | 'viewport_height') => {
  const valueNumber = parseFloat(value) || 100
  const valueType = SIZE_VALUE_TYPES[type] || SIZE_VALUE_TYPES.pixels

  return `${valueNumber}${valueType}`
}

const STYLES = {
  root: {
    position: 'relative',
    margin: '0 auto',
  },

  iframeWrapper: {
    width: '100%',
    position: 'relative',
    margin: '0 auto',

    iframe: {
      position: 'absolute',
      width: '100% !important',
      height: '100% !important',
    },
  },

  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}
