import React from 'react'

import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const HealthcareFacilityDetailsFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="care_level_6" />
        <div>
          <Type as="h2" variant="H3">
            Healthcare Facility Details
          </Type>

          <Type as="p" variant="TEXT" css={{ margin: '0.2em 0 0' }}>
            Check all the ASAM Levels Of Care that apply
          </Type>
        </div>

        <Button
          nowrap
          type="link"
          label="Learn More"
          target="_blank"
          href="https://www.asamcontinuum.org/knowledgebase/what-are-the-asam-levels-of-care/"
          className="!ml-auto"
        />
      </Flex>

      <CheckboxGroup withToggle trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" label="Inpatient" layout="vertical-dense">
        <Checkbox
          label="4-D Medically Managed Intensive Inpatient Detoxification"
          model="questionnaire.healthcare_facility_details.levels_of_care.medically_managed_intensive_inpatient_detoxification"
        />
        <Checkbox
          label="4 Medically Managed Intensive Inpatient Services"
          model="questionnaire.healthcare_facility_details.levels_of_care.medically_managed_intensive_inpatient_services"
        />
        <Checkbox
          label="3.7-D Medically Monitored Inpatient Detoxification"
          model="questionnaire.healthcare_facility_details.levels_of_care.medically_monitored_inpatient_detoxification"
        />
        <Checkbox
          label="3.7 Medically Monitored High-Intensity Inpatient Services"
          model="questionnaire.healthcare_facility_details.levels_of_care.medically_monitored_high_intensity_inpatient_services"
        />
      </CheckboxGroup>

      <CheckboxGroup
        withToggle
        trueIcon="check"
        falseIcon="cross"
        falseStyle="faded-linethrough"
        label="Residential"
        layout="vertical-dense"
      >
        <Checkbox
          label="3.5 Clinically Managed Medium-Intensity Residential Services"
          model="questionnaire.healthcare_facility_details.levels_of_care.clinically_managed_medium_intensity_residential_services"
        />
        <Checkbox
          label="3.3 Clinically Managed Population-Specific High-Intensity Residential Services"
          model="questionnaire.healthcare_facility_details.levels_of_care.clinically_managed_population_specific_high_intensity_residential_services"
        />
        <Checkbox
          label="3.2-D Clinically Managed Residential Detoxification"
          model="questionnaire.healthcare_facility_details.levels_of_care.clinically_managed_residential_detoxification"
        />
        <Checkbox
          label="3.1 Clinically Managed Low-Intensity Residential Services"
          model="questionnaire.healthcare_facility_details.levels_of_care.clinically_managed_low_intensity_residential_services"
        />
        <Checkbox
          label="3-D Residential/Inpatient Detoxification"
          model="questionnaire.healthcare_facility_details.levels_of_care.residential_inpatient_detoxification"
        />
      </CheckboxGroup>

      <CheckboxGroup
        withToggle
        trueIcon="check"
        falseIcon="cross"
        falseStyle="faded-linethrough"
        label="Outpatient"
        layout="vertical-dense"
      >
        <Checkbox
          label="2.5 Partial Hospitalization Services"
          model="questionnaire.healthcare_facility_details.levels_of_care.partial_hospitalization_services"
        />
        <Checkbox
          label="2.1 Intensive Outpatient Services"
          model="questionnaire.healthcare_facility_details.levels_of_care.intensive_outpatient_services"
        />
        <Checkbox
          label="2-D Ambulatory Detoxification With Extended On-Site Monitoring"
          model="questionnaire.healthcare_facility_details.levels_of_care.ambulatory_detoxification_with_extended_on_site_monitoring"
        />
        <Checkbox
          label="1-D Ambulatory Detoxification Without Extended On-Site Monitoring"
          model="questionnaire.healthcare_facility_details.levels_of_care.ambulatory_detoxification_without_extended_on_site_monitoring"
        />
        <Checkbox label="1 Outpatient Services" model="questionnaire.healthcare_facility_details.levels_of_care.outpatient_services" />
        <Checkbox label="0.5 Early Intervention" model="questionnaire.healthcare_facility_details.levels_of_care.early_intervention" />
      </CheckboxGroup>
    </FormSection>
  )
}

export const HealthcareFacilityDetails: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <HealthcareFacilityDetailsFields />
    </Form>
  )
}
