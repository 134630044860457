import React from 'react'
import { useMedia } from 'use-media'
import * as HoverCard from '@radix-ui/react-hover-card'

import { css, COLORS, SHADOW } from '../../../theme'
import { usePortal } from '../../../hooks/usePortal'

export const CardTooltipContent: React.FC = ({ children }) => {
  const isDesktop = useMedia({ minWidth: 600 })

  const container = usePortal('default')
  const collisionBoundary = document?.getElementById?.('roadmap')

  return (
    <HoverCard.Portal container={container}>
      <HoverCard.Content className={STYLES.root()} collisionBoundary={collisionBoundary} collisionPadding={{ left: isDesktop ? 200 : 20 }}>
        <HoverCard.Arrow width={14} height={8} className={STYLES.arrow()} />
        {children}
      </HoverCard.Content>
    </HoverCard.Portal>
  )
}

const STYLES = {
  root: css({
    minWidth: 280,
    maxWidth: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    borderRadius: 7,
    background: COLORS.white,
    boxShadow: SHADOW(10, COLORS.divider),
    border: 'none',
    paddingBottom: '0.5rem',

    '@media(min-width: 600px)': {
      minWidth: 360,
    },
  }),

  arrow: css({
    color: COLORS.white,
    margin: '0 1rem',
  }),
}
