import React from 'react'

import Checkbox from '../../../../components/Forms/Checkbox'
import RadioGroup from '../../../../components/Forms/RadioGroup'
import Radio from '../../../../components/Forms/Radio'
import Input from '../../../../components/Forms/Input'
import Textarea from '../../../../components/Forms/Textarea'

import { VariablesDropdownMenu } from '../../components/VariablesDropdownMenu'

export const BasicInputEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput, activeElementId, appendVariableToElementModel } = props

  const { config } = activeElement
  const { description, is_required, label, tooltip } = config

  return (
    <>
      <Input
        label="Label"
        model="label"
        value={label}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'label' })
            }}
          />
        }
      />
      <Checkbox
        label="Required Field"
        model="is_required"
        onUpdate={editActiveElementFromInput}
        // value={is_required}
      />

      <Textarea
        label="Description"
        model="description"
        value={description}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'description' })
            }}
          />
        }
      />
      <Textarea
        label="Info Tooltip"
        model="tooltip"
        value={tooltip}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'tooltip' })
            }}
          />
        }
      />

      {/* <RadioGroup label="Edit Permission" layout="vertical-dense">
        <Radio label="Everyone" value="all" />
        <Radio label="Staff Only" value="staff" />
        <Radio label="Clients Only" value="clients" />
      </RadioGroup> */}
    </>
  )
}
