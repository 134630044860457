import React from 'react'

import ContextShow from '../ContextShow'
import Input from '../Input'
import Radio from '../Radio'
import RadioGroup from '../RadioGroup'

const PreferredPaymentMethodRadioGroup = ({ label, model }) => (
  <>
    <RadioGroup label={label} model={model} layout="vertical-dense">
      <Radio label="Credit/Debit Card" value="credit_debit_card" />
      <Radio label="Cash" value="cash" />
      <Radio label="Check" value="check" />
      <Radio label="Money Order" value="money_order" />
      <Radio label="Bank Account (ACH)" value="ach" />
      <Radio label="Write-off" value="write_off" />
      <Radio label="Venmo" value="venmo" />
      <Radio label="Zelle" value="zelle" />
      <Radio label="Other" value="other" />
    </RadioGroup>
    <ContextShow when={model} is="other">
      <Input label="Other Payment Method" model={`${model}_other`} />
    </ContextShow>
  </>
)

PreferredPaymentMethodRadioGroup.defaultProps = {
  model: 'preferred_payment_method',
}

export default PreferredPaymentMethodRadioGroup
