import React from 'react'
import { connect } from 'react-redux'

import Button from '../../Button'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Select from '../../Forms/Select'
import Option from '../../Forms/Option'
import Input from '../../Forms/Input'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Timeline from '../../Timeline/Timeline'
import MyIPAddress from '../../MyIPAddress'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'
import withRouterV6 from '../../../hocs/withRouterV6'

class AllowedIPOverlay extends OverlayBase {
  renderHeader = () => {
    const { $new } = this.state

    return <Overlay.Header icon="permissions" title={$new ? 'Add IP' : 'Update IP'} />
  }

  renderContent = () => {
    const { $editable, $new } = this.state
    const { record, match, loading } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={record}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          decorate={() => ({
            reference_id: match?.params?.resource_id,
            reference_type: 'Employee',
          })}
        >
          <Section>
            <FormSection layout="vertical">
              <Select label="Status" model="status" defaultValue="active">
                <Option label="Active" value="active" />
                <Option label="Inactive" value="inactive" />
              </Select>

              <MyIPAddress />

              <Input
                label="IP Address"
                model="ip"
                validations={{
                  presence: {
                    message: 'Please enter an IP address',
                  },
                }}
              />
            </FormSection>
          </Section>

          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="contacts.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit IP"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="contacts.edit"
            />

            <DeleteDialog
              title="Delete IP?"
              message="Are you sure you want to delete this IP address? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                permission="contacts.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'allowed_ips')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'allowed_ips')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(AllowedIPOverlay))

export const AllowedIPOverlayV6 = withRouterV6(connect(mapStateToProps, mapDispatchToProps)(withOverlayError(AllowedIPOverlay)))
