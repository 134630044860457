import React from 'react'

import { COLORS } from '../theme'

import Header from './Header'

const PageHeader = ({ children, isSticky, className, showFeedbackTool, ...rest }) => {
  return (
    <Header {...rest} isSticky={isSticky} className={className} css={styles} help={null}>
      {children}
    </Header>
  )
}

const styles = {
  top: 0,
  background: COLORS.white,

  boxShadow: `
    0 0.5px 0 ${COLORS.divider},
    0 2px 6px ${COLORS.shadow},
    0 4px 12px ${COLORS.shadow},
    0 8px 24px ${COLORS.shadow}
  `,
}

export default PageHeader
