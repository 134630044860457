import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ContextShow from '../../Forms/ContextShow'
import DateTimeInput from '../../Forms/DateTimeInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Grid from '../../Grid'
import Input from '../../Forms/Input'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import Permission from '../../Permission'
import Section from '../../Section'
import SignaturePad from '../../Forms/SignaturePad'
import Switch from '../../Forms/Switch'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'

import { age, titleCase } from '../../../utils/functions'
import { DEFAULT_EMPTY_VALUE, MEDICATION_TIMES } from '../../../utils/constants'
import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { ExportPDFButton } from '../../Buttons/ExportPDFButton'

class MedicationLog extends OverlayBase {
  renderHeader = () => {
    const { tenant } = this.props
    const isTrialing = tenant?.plan_status === 'trialing'

    return (
      <>
        <Overlay.Header icon="medications" title="Medication Log" />

        {!this.state.$editable && !isTrialing && (
          <Permission permission="clients.actions.export">
            <Overlay.SubHeader>
              <ExportPDFButton url={`/medication_logs/${this.state.id}/pdf`} />
            </Overlay.SubHeader>
          </Permission>
        )}
      </>
    )
  }

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { record, reference, timezone, tenant, loading } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={{
            ...record,
            ...params,
          }}
          isEditable={$editable}
          timezone={timezone}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
          linked={
            $new &&
            (reference?.id || this.props?.client?.id) && {
              resident_id: this.props?.client?.id || reference?.id,
              resident: this.props?.client || reference,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              <ObjectSelector
                initialModelRequired={$new}
                model="resident"
                label="Client"
                type="current_clients"
                icon="clients"
                showDescription={false}
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => `${startCase(data.sex) || '–'}, ${age(data.dob)} y/o, #${data.behave_id}`}
                disableLink={this.isPortal}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
                description="We load clients from Applicants, Accepted and Current Clients"
                isEditable={$editable && !this.isPortal}
              />

              <ObjectSelector
                model="med"
                label="Medication"
                type="client.medications"
                dependent="resident_id"
                disableUnless="resident_id"
                icon="medications"
                showDescription={false}
                selectTitle={(data) => data.name}
                selectDescription={(data) => {
                  const checkedResults = data?.medication_time && Object.entries(data.medication_time).filter(([key, value]) => value)
                  const finalValue: any = []

                  checkedResults.map(([key, value]) => {
                    finalValue.push(MEDICATION_TIMES[key])
                  })

                  return (
                    <>
                      <b>Status: </b> {titleCase(data.status)}
                      {' | '}
                      <b>Medication Time: </b> {size(finalValue) > 0 ? finalValue.join(', ') : DEFAULT_EMPTY_VALUE}
                    </>
                  )
                }}
                disableLink={this.isPortal}
                validations={{
                  presence: {
                    message: 'Please select a medication',
                  },
                }}
              />

              <Flex gap={16} nowrap>
                <Input
                  model="dosage"
                  label="Dosage"
                  type="number"
                  step="0.001"
                  min="0"
                  validations={{
                    presence: {
                      message: 'Please enter a dosage',
                    },
                  }}
                />
                <Input
                  model="unit"
                  label="Quantity"
                  validations={{
                    presence: {
                      message: 'Please enter a unit',
                    },
                  }}
                />
                <Input model="remaining_units" label="Quantity Remaining" />
              </Flex>

              <CheckboxGroup label="Medication Time" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
                <Checkbox label="AM" model="medication_time.am" />
                <Checkbox label="PM" model="medication_time.pm" />
                <Checkbox label="Morning" model="medication_time.morning" />
                <Checkbox label="Afternoon" model="medication_time.afternoon" />
                <Checkbox label="Evening" model="medication_time.evening" />
                <Checkbox label="Bedtime" model="medication_time.bedtime" />
                <Checkbox label="PRN" model="medication_time.prn" />
              </CheckboxGroup>

              <DateTimeInput
                defaultToNow
                model="administered_at"
                label="Administered At"
                validations={{
                  presence: {
                    message: 'Please enter the time of administration',
                  },
                }}
              />

              <Textarea useQuickText model="notes" label="Notes" />
            </FormSection>
          </Section>

          <Divider />

          {!this.isPortal && tenant.is_billed && (
            <>
              <Section
                headingType="h2"
                title="Billing"
                description="Use this section to record the billable service"
                aside={<Switch defaultValue={false} model="is_billable" />}
              >
                <ContextShow when="is_billable" is={true}>
                  <FormSection maxWidth="100%">
                    <ObjectSelector
                      dependent="resident_id"
                      model="insurance_service"
                      label="Rendered Insurance Service"
                      type="client.insurance_services"
                      icon="billing"
                      searchKeys={['code', 'short_description', 'long_description']}
                      selectTitle={(o: any) => `${o?.code} - ${o?.short_description}`}
                      selectDescription={(o: any) => o?.long_description}
                      groupBy={{
                        accessor: 'service_type',
                        sections: {
                          in_network: 'In Network',
                          out_of_network: 'Out of Network',
                          global_oon: 'Global Out of Network',
                        },
                      }}
                      validations={{
                        presence: {
                          message: 'Please select a service',
                        },
                      }}
                    />

                    <Textarea useQuickText model="billing_notes" label="Billing Notes" />
                  </FormSection>
                </ContextShow>
              </Section>

              <Divider />
            </>
          )}

          <Section headingType="h2" title="Attachments" description="Upload the files related to this log">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Signatures">
            <Grid gap={16}>
              <SignaturePad
                showSignedBy={false}
                label="Client Signature"
                model="client_signature"
                signedAtModel="client_signed_at"
                allowPin={false}
                showAccept={false}
              />

              {!this.isPortal && (
                <>
                  <SignaturePad
                    allowPin
                    person={record.signed_by}
                    label="Staff Signature"
                    model="employee_signature"
                    signedAtModel="author_signed_at"
                    showAccept={false}
                  />

                  <Checkbox
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="faded-linethrough"
                    label="Require Co-Signer"
                    defaultValue={false}
                    model="require_cosigner"
                  />

                  <ContextShow when="require_cosigner" is={true}>
                    <SignaturePad
                      allowPin
                      showSignedBy={false}
                      label="Co-Signer Signature"
                      model="cosigner_signature"
                      signedAtModel="cosigned_at"
                      showAccept={false}
                    />
                  </ContextShow>
                </>
              )}
            </Grid>
          </Section>

          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} disableLinks={this.isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="10 1 auto"
              permission={$new ? 'medication_logs.create' : 'medication_logs.edit'}
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="10 1 auto"
              permission="medication_logs.edit"
            />

            <Permission permission="medication_logs.delete">
              <DeleteDialog
                title="Delete Medication Log?"
                message="Are you sure you want to delete this medication log? This action cannot be undone."
                onYes={this.delete}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={this.props.loading}
                  fullWidth
                  permission="medication_logs.delete"
                />
              </DeleteDialog>
            </Permission>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'medication_logs')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'medication_logs')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(MedicationLog))
