import React from 'react'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../theme'

type Props = {
  layout: string
  isCompact?: boolean
  gap: number
  columnWidth: number
} & React.HTMLAttributes<any>

const CardsGrid: React.FC<any> = ({ layout = 'grid', columnWidth = 340, gap = 8, children, className, isCompact }) => {
  const classNames = clsx({
    'is-list': layout === 'list',
    'is-grid': layout === 'grid',
    'is-compact': isCompact,
    [className]: className,
  })

  return (
    <div css={styles({ gap, columnWidth })} className={classNames}>
      {children}
    </div>
  )
}

const styles = ({ gap, columnWidth }: any) => ({
  display: 'grid',
  gridGap: gap,

  '&.is-grid': {
    [`@media (min-width: ${columnWidth * 2}px)`]: {
      gridTemplateColumns: `repeat(auto-fit, minmax(${columnWidth}px, 1fr))`,
    },
  },

  '&.is-list': {
    gridTemplateColumns: '1fr',
  },

  '&.is-compact': {
    gridGap: 0,

    '& > *': {
      borderRadius: `0 !important`,
      borderBottom: `1px solid ${COLORS.divider}`,
      boxShadow: `none`,

      '&:hover': {
        boxShadow: SHADOW(5),
      },
    },
  },
})

export default CardsGrid
