import React from 'react'
import size from 'lodash/size'

import { niceAmount } from '../../../utils/functions'

import Card from '../../../components/Card'
import GridTable from '../../../components/GridTable'
import State from '../../../components/State'

import { SERVICE_CODES } from '../constants'

const columns = [
  {
    name: 'In Network',
    width: 'minmax(100px, 1fr)',
  },
  {
    name: 'Coverage Level',
    width: 'minmax(150px, 1fr)',
  },
  {
    name: 'Amount',
    width: 'minmax(140px, 1fr)',
  },
  {
    name: 'Time Period',
    width: 'minmax(140px, 1fr)',
  },
  // {
  //   name: 'Remaining',
  //   width: 'minmax(240px, 1fr)',
  // },
  {
    name: 'Message',
    width: 'minmax(700px, 1fr)',
  },
]

const gridColumns = columns.map((column) => column.width).join(' ')

export const DeductibleTable: React.FC<any> = ({ data }) => {
  if (size(data) === 0) {
    return (
      <Card>
        <State isEmpty icon="financials" title="Deductible" emptyDescription="No data available to display" />
      </Card>
    )
  }

  return (
    <Card>
      <GridTable templateColumns={gridColumns}>
        <GridTable.Header>
          {columns.map((column) => (
            <GridTable.Cell key={column.name}>{column.name}</GridTable.Cell>
          ))}
        </GridTable.Header>

        {Object.entries(data).map(([name, rows]: any) => (
          <GridTable.Group isOpen key={name} title={`${name} ${SERVICE_CODES[name]} (${size(rows)})`}>
            {rows.map((row, index) => (
              <GridTable.Row key={index}>
                <GridTable.Cell>{row.inPlanNetworkIndicator}</GridTable.Cell>
                <GridTable.Cell>{row.coverageLevel}</GridTable.Cell>
                <GridTable.Cell>{niceAmount(row.benefitAmount)}</GridTable.Cell>
                <GridTable.Cell>{row.timeQualifier}</GridTable.Cell>
                {/* <GridTable.Cell>???</GridTable.Cell> */}
                <GridTable.Cell>
                  {size(row.additionalInformation) > 0 && (
                    <ul>
                      {row.additionalInformation.map((info) => (
                        <li key={info.description}>{info.description}</li>
                      ))}
                    </ul>
                  )}
                </GridTable.Cell>
              </GridTable.Row>
            ))}
          </GridTable.Group>
        ))}
      </GridTable>
    </Card>
  )
}
