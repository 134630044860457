import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { countWord } from '../../../../utils/functions'
import useAPI from '../../../../hooks/useAPI'

import Button from '../../../Button'
import Card from '../../../Card'
import Overlay from '../../../Overlay'
import Section from '../../../Section'

import DiscountInternalTemplatesTable from '../../../Templates/tables/internal/DiscountInternalTemplatesTable'

import { apiCreate, apiGet } from '../../../../modules/api'
import { invalidateQueries } from '../../../../hooks/useNewAPI'

const DiscountsImportOverlay = () => {
  const navigate = useNavigate()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [loading, setLoading]: any = React.useState(false)

  const data = useSelector((state: any) => state.data?.internal_discounts_templates?.data)
  const dataLoading = useSelector((state: any) => state.data?.internal_discounts_templates?.loading)
  const isEmpty = size(data) === 0

  useAPI('internal_discounts_templates', '/internal_templates?category=discount&subcategory=discount&level=behave_template')

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    setLoading(true)

    try {
      const ids = selectedRows.map((o: any) => o.original.id)
      await apiCreate({
        url: '/internal_templates/import',
        params: { ids: ids, category: 'discount', subcategory: 'discount' },
      })
      invalidateQueries('discounts')
      // await apiGet({ name: 'discounts', url: '/discounts' })

      navigate(-1)
    } catch (errors) {
      console.debug(errors)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import Discounts from Catalog" />

      <Overlay.Content>
        <Section>
          <Card>
            <DiscountInternalTemplatesTable title="Discounts" data={data} isLoading={isEmpty && dataLoading} onSelect={setSelectedRows} />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Discounts', selectedRowsCount)}` : 'Select Discounts to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={loading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default DiscountsImportOverlay
