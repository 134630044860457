import React from 'react'
import { v4 as uuid } from 'uuid'
import isEqual from 'react-fast-compare'
import Timer from 'react-compound-timer'

import { withFormContext } from './context'

import Field from './Field'
import Flex from '../Flex'
import Button from '../Button'

import FieldBase from './FieldBase'

class TimerInput extends FieldBase {
  constructor(props) {
    super(props)

    this.state = {
      type: 'TIMERINPUT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: props.form?.getInitialInputFieldValue(props.model, 0),
      status: 'off',
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: props.form?.getInitialInputFieldValue(props.model, 0),
      status: 'off',
    }

    this.updateType = 'DATA'
    this.timer = React.createRef()
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.isEditable !== this.props.isEditable || nextProps.layout !== this.props.layout) {
      this.updateType = 'STATE'
      return true
    }

    if (!isEqual(this.state, nextState)) {
      this.updateType = 'DATA'
      return true
    }

    if (this.props.value !== nextProps.value) {
      this.updateType = 'PROPS'
      return true
    }

    return false
  }

  /*
    CUSTOM FUNCTIONS
  */
  onStart = () => this.setState({ status: 'on' })
  onStop = () => {
    const miliseconds = this.timer?.current?.getTime()
    if (miliseconds > 0) {
      this.changeValue(Math.round(miliseconds / 1000))
      this.setState({ status: 'off' })
    }
  }

  render = () => {
    const {
      autoFocus,
      layout,
      max,
      maxLength,
      min,
      model,
      placeholder,
      prefix,
      size,
      step,
      suffix,
      type,
      validations,
      inputGroupMaxWidth,
      ...others
    } = this.props

    const { id, isRequired, isValid, errors, smartValue, status } = this.state

    return (
      <Field {...others} id={id} errors={errors} isValid={isValid} isRequired={isRequired} layout={layout} smartValue={smartValue}>
        <Timer ref={this.timer} startImmediately={false} onStart={this.onStart} onStop={this.onStop} initialTime={this.state.value * 1000}>
          {({ start, stop }) => (
            <Flex horizontal gap={8}>
              {!this.props.isEditable && (
                <div css={styles}>
                  <Timer.Minutes formatValue={(value) => `${value < 10 ? `0${value}` : value}`} />:
                  <Timer.Seconds formatValue={(value) => `${value < 10 ? `0${value}` : value}`} />
                </div>
              )}
              {this.props.isEditable && (
                <div css={styles} className="is-editable">
                  <Timer.Minutes formatValue={(value) => `${value < 10 ? `0${value}` : value}`} />:
                  <Timer.Seconds formatValue={(value) => `${value < 10 ? `0${value}` : value}`} />
                </div>
              )}
              {this.props.isEditable && (
                <div>
                  {status === 'on' && <Button label="Stop" color="red" onClick={stop} />}
                  {status === 'off' && <Button label="Start" color="green" onClick={start} />}
                </div>
              )}
            </Flex>
          )}
        </Timer>
      </Field>
    )
  }
}

const styles = {
  minWidth: 85,
  paddingBottom: 8,

  '&.is-editable': {
    paddingTop: 11,
    fontSize: 24,
    fontWeight: 200,
  },
}

TimerInput.defaultProps = {
  isEditable: true,
}

export default withFormContext(TimerInput)
