import React from 'react'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

export const InsuranceERAsTable = ({ onSelect, dependentValue, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-insurance-eras'],
    endpoint: '/insurance_eras',
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_insurance_eras_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'ERA ID',
        model: 'identifier',
        width: 200,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell id={data.id} value={value} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'Insurance Payer',
        model: 'insurance_local_payer',
        type: 'profile',
      },
      {
        title: 'Facility Provider',
        model: 'provider.credential',
      },
      {
        title: 'Clearing House ID',
        model: 'clearing_house_id',
      },
      {
        title: 'Clearing House Payer ID',
        model: 'clearing_house_payer_id',
      },
      {
        title: 'Payment Method',
        model: 'payment_method',
      },
      {
        title: 'Payment Format',
        model: 'payment_format',
      },
      {
        title: 'Payment Date',
        model: 'paid_date',
        type: 'date',
      },
      {
        title: 'Check Number',
        model: 'check_number',
      },
      {
        width: 200,
        title: 'Paid Amount',
        model: 'paid_amount',
        type: 'amount',
      },
    ],
    [],
  )

  return <DataTable {...tableProps} {...rest} icon="insurance" title="Insurance ERAs" columns={columns} searchBarFilterKey="name" />
}
