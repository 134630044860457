import React from 'react'
import size from 'lodash/size'

import { useDataTable } from '../../../components/DataTable/useDataTable'
import { useRouteMatch } from 'react-router-dom'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import FormSection from '../../../components/Forms/FormSection'
import Permission from '../../../components/Permission'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { MessageBoardDataTable } from '../../MessageBoard/MessageBoardDataTable'
import Message from '../../../components/Overlays/pages/Message'

const AddButton = ({ client }) => {
  const match = useRouteMatch()

  return (
    <Permission permission="messages.create">
      <SummonOverlay
        overlay={
          <Message
            showBackdrop
            testKey="add_message_overlay"
            dataID="new"
            match={match}
            initialData={{ message_type: 'client_internal', reference: client }}
          />
        }
      >
        <Button
          testKey="add_message_button"
          label="Add Message"
          glyph="add"
          size={200}
          type="primary"
          display="inline-flex"
          permission="messages.create"
        />
      </SummonOverlay>
    </Permission>
  )
}

const Messages = ({ data, client, setStatus, setData }: any) => {
  const match = useRouteMatch()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = match.params

  const [openMessage, setOpenMessage] = React.useState(null)
  const [showExpiredMessages, setShowExpiredMessages] = React.useState(false)

  const tableProps: any = useDataTable({
    name: ['client', resource_id, 'messages'],
    endpoint: `/residents/${resource_id}/messages`,
    params: { show_expired: !!showExpiredMessages },
    updateDeleteEndpoint: '/messages',
    keepPreviousData: false,
    enabled: !!resource_id,
    localStorageKey: `client_profile_messages_v1`,
  })

  const isEmpty = size(tableProps.data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <MessageBoardDataTable
            emptyActions={<AddButton client={client} />}
            onClick={(record) => setOpenMessage(record)}
            {...tableProps}
          />

          <CardContent>
            <AddButton client={client} />
          </CardContent>
        </Card>

        <CheckboxGroup>
          <Checkbox
            label="Expire all Messages Upon Discharge"
            model="expire_messages"
            value={data?.expire_messages}
            onUpdate={(state: any) => {
              setData({ expire_messages: state.value })
            }}
          />
        </CheckboxGroup>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>

      <SummonOverlay
        key={`summon-${openMessage?.id}`}
        isOpen={!!openMessage}
        onClose={() => setOpenMessage(null)}
        overlay={<Message showBackdrop testKey="message_overlay" dataID={openMessage?.id} match={match} />}
      />
    </>
  )
}

export default Messages
