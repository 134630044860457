import React from 'react'

import FormSection from '../../../../Forms/FormSection'
import ContextShow from '../../../../Forms/ContextShow'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const CoordinationOfCare = () => {
  return (
    <FormSection layout="vertical">
      <SmartTextarea
        useDictation
        label="Please list any other providers the client would like to be involved with their care:"
        model="data.coordination_of_care.providers"
      />

      <RadioGroup
        label="Is the client in need of a special diet? (Religious, vegan, etc.)"
        model="data.coordination_of_care.special_diet"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.coordination_of_care.special_diet" is={true}>
        <SmartTextarea useDictation label="Please explain:" model="data.coordination_of_care.special_diet_extra" />
      </ContextShow>

      <RadioGroup label="Does the client have any other special needs?" model="data.coordination_of_care.special_needs" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.coordination_of_care.special_needs" is={true}>
        <SmartTextarea useDictation label="Please explain:" model="data.coordination_of_care.special_needs_extra" />
      </ContextShow>
    </FormSection>
  )
}

export default CoordinationOfCare
