import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import meReducer from '../reducers/me'
import dataReducer from '../reducers/data'
import commonReducer from '../reducers/common'

const createReducer = (history, injectedReducers = {}) =>
  combineReducers({
    common: commonReducer,
    data: dataReducer,
    me: meReducer,
    router: connectRouter(history),
    ...injectedReducers,
  })

export default createReducer
