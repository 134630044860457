import React from 'react'
import { DateTime } from 'luxon'

import { daysToWords } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MessageStatus from '../../components/Statuses/MessageStatus'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import URLInput from '../../components/Forms/URLInput'

import { useCommunityPermissions } from './useCommunityPermissions'
import { useCommunityEditor } from './useCommunityEditor'

const RootCommunityMessageOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'profile_messages',
    endpoint: '/profile_messages',
    invalidate: 'profile_messages',
    options: props,
    disableParentRequest: true,
    onSaveSuccessful: () => {
      setUpdatedAt(DateTime.now().toMillis())
    },
    onDeleteSuccessful: () => {
      setUpdatedAt(DateTime.now().toMillis())
    },
  })

  const { entityId } = props

  const { timezone } = useSettings()

  const { canEditCommunityProfiles } = useCommunityPermissions()
  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  if (isOverlayLoading) {
    return <OverlayLoader position="right" showBackdrop={isEditable} />
  }

  return (
    <Overlay onClose={close} showBackdrop={isEditable}>
      <Overlay.Header icon="message_board" title="Community Message" />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={{
            reference_id: entityId,
            reference_type: 'bridge/entity',
            message_type: 'community',
          }}
          decorate={(model: any) => ({
            expired_at:
              isNew && model.days_to_expiry
                ? DateTime.local()
                    .plus({ days: model.days_to_expiry + 1 })
                    .setZone(timezone)
                    .startOf('day')
                    .toISO()
                : model.expired_at,
          })}
        >
          <Section>
            <FormSection layout="vertical">
              <RadioGroup
                label="Message Category"
                model="category"
                layout="vertical-dense"
                defaultValue="info"
                validations={{
                  presence: {
                    message: 'Please select a message type',
                  },
                }}
              >
                <Radio label={<MessageStatus status="note" />} value="note" />
                <Radio label={<MessageStatus status="info" />} value="info" />
                <Radio label={<MessageStatus status="warning" />} value="warning" />
                <Radio label={<MessageStatus status="alert" />} value="alert" />
              </RadioGroup>

              <Flex gap="1rem">
                <Input
                  label="Title"
                  model="title"
                  validations={{
                    presence: {
                      message: 'Please enter a message title',
                    },
                  }}
                  className="!grow"
                />

                <Input type="number" label="Order" model="order" className="!grow-0 !shrink" />
              </Flex>

              <MiniRichTextEditor label="Description" model="description" />

              <URLInput label="External Link" model="link" />

              {isNew ? (
                <Input label="Expires In" type="number" min={1} size={6} suffix="days" model="days_to_expiry" />
              ) : (
                <DateInput label="Expires On" model="expired_at" smartDescription={(date) => daysToWords(date, timezone)} />
              )}

              {!isEditable && <ObjectSelector label="Written By" model="author" type="employees" isEditable={false} icon="employees" />}
              {!isEditable && <DateTimeInput label="Created At" model="created_at" />}

              {!isEditable && data?.updated_at !== data?.created_at && <DateTimeInput label="Last Updated" model="updated_at" />}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              glyph="check"
              label="Save"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission={canEditCommunityProfiles}
            />
            {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              label="Edit"
              glyph="edit"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission={canEditCommunityProfiles}
            />

            <DeleteDialog
              title="Delete Message?"
              message="Are you sure you want to delete this message? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                label="Delete"
                type="default"
                glyph="delete"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission={canEditCommunityProfiles}
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const CommunityMessageOverlay = withOverlayError(RootCommunityMessageOverlay)
