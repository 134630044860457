import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'blue',
  },
  pending_review: {
    label: 'Pending Review',
    color: 'orange',
  },
  approved: {
    label: 'Approved',
    color: 'green',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
