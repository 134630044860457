import React from 'react'

import Empty from './Empty'

const Swatch: React.FC = ({ value }: any) => {
  if (!value) return <Empty css={styles.empty} />

  return <div css={styles.swatch} style={{ '--swatch-color': value }} />
}

const styles = {
  swatch: {
    display: 'block',
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: 'none',
    boxShadow: `inset 0 0 0 1px rgba(12, 62, 106, 0.15)`,
    background: 'var(--swatch-color)',
    margin: '0 auto',
  },

  empty: {
    display: 'block',
    textAlign: 'center',
  },
}

export default Swatch
