import React from 'react'

import Chotomate from '../../components/Chotomate'
import ContextHide from '../../components/Forms/ContextHide'
import Divider from '../../components/Divider'
import Section from '../../components/Section'
import Switch from '../../components/Forms/Switch'

import LevelOfRiskAndInterventions from '../../components/Overlays/pages/DataForms/common/LevelOfRiskAndInterventions'
import ProtectiveFactors from '../../components/Overlays/pages/DataForms/common/ProtectiveFactors'
import RiskFactors from '../../components/Overlays/pages/DataForms/common/RiskFactors'
import SNAP from '../../components/Overlays/pages/DataForms/common/SNAP'
import SuicideRiskDocumentation from '../../components/Overlays/pages/DataForms/common/SuicideRiskDocumentation'
import ThoughtsPlansSuicidalIntent from '../../components/Overlays/pages/DataForms/common/ThoughtsPlansSuicidalIntent'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootSuicideAssessmentOverlay = () => (
  <DataFormOverlay
    title="Suicide Assessment"
    category="clinical_assessment"
    subcategory="suicide_assessment"
    icon="clinical_assessments"
    helpID="clinical_assessments_suicide_assessment"
    requestClientSignature={false}
    requestAuthorSignature={true}
    requireSupervisor={true}
    enableTreatmentPlanSelector
  >
    {({ data }) => (
      <>
        <Chotomate ready name="suicide_assessment_overlay" />
        {/* Risk Factors */}
        <Section
          title="Risk Factors"
          scrollview={{
            id: 'risk_factors',
            name: 'Risk Factors',
          }}
          commentsModel="data.risk_factors"
        >
          <RiskFactors />
        </Section>

        <Divider />

        {/* Protective Factors */}
        <Section
          title="Protective Factors"
          description="Protective factors may not counteract significant acute suicide risk factors"
          scrollview={{
            id: 'protective_factors',
            name: 'Protective Factors',
          }}
          commentsModel="data.protective_factors"
        >
          <ProtectiveFactors />
        </Section>

        <Divider />

        {/* Thoughts, Plans, and Suicidal Intent */}
        <Section
          title="Thoughts, Plans, and Suicidal Intent"
          scrollview={{
            id: 'thoughts_plans_and_suicidal_intent',
            name: 'Thoughts, Plans, and Suicidal Intent',
          }}
          commentsModel="data.thoughts_plans_and_suicidal_intent"
        >
          <ThoughtsPlansSuicidalIntent data={data?.data?.thoughts_plans_and_suicidal_intent} />
        </Section>

        <Divider />

        {/* Level of Risk and Interventions */}
        <Section
          title="Level of Risk and Interventions"
          scrollview={{
            id: 'level_of_risk_and_interventions',
            name: 'Level of Risk and Interventions',
          }}
          commentsModel="data.level_of_risk_and_interventions"
        >
          <LevelOfRiskAndInterventions />
        </Section>

        <Divider />

        <Section
          title="Strengths, Needs, Abilities, Preferences"
          aside={<Switch horizontal inverse model="data.snap.not_applicable" />}
          scrollview={{
            id: 'snap',
            name: 'S.N.A.P.',
          }}
          commentsModel="data.snap"
        >
          <ContextHide when="data.snap.not_applicable" is={true}>
            <SNAP />
          </ContextHide>
        </Section>

        <Divider />

        {/* Documentation */}
        <Section
          title="Documentation"
          scrollview={{
            id: 'suicide_risk_documentation',
            name: 'Documentation',
          }}
          commentsModel="data.suicide_risk_documentation"
        >
          <SuicideRiskDocumentation showNutritionReferral />
        </Section>
      </>
    )}
  </DataFormOverlay>
)

export const SuicideAssessmentOverlay = withOverlayError(RootSuicideAssessmentOverlay)
