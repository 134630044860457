import React from 'react'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'

import { Button, Divider, Dropdown, DropdownItem, Page, Tabs } from '@behavehealth/components'
import Permission from '@behavehealth/components/Permission'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useHistory } from 'react-router-dom'

import Billing from './pages/billing'
import ClaimStatus from './pages/claim_status'
import Dashboard from './pages/dashboard'
import Posting from './pages/posting'
import UtilizationReview from './pages/utilization_review'
import Verifications from './pages/verifications'

const RCM = () => {
  const match = useRouteMatch()
  const history = useHistory()

  const { pathname } = useLocation()
  const { isBehave, isSelfServiceBilling } = useSettings()

  if (!isBehave && !isSelfServiceBilling) {
    history.push('/dashboard')
    return
  }

  let selectedTab = ''
  let actions = null

  if (pathname.includes(`${match.url}/dashboard`)) {
    selectedTab = 'dashboard'
  } else if (pathname.includes(`${match.url}/verifications`)) {
    selectedTab = 'verifications'
  } else if (pathname.includes(`${match.url}/utilization-review`)) {
    selectedTab = 'utilization_review'
    // actions = (
    //   <Dropdown label="Add New…" buttonType="primary" permission={isBehave ? true : isSelfServiceBilling ? 'rcm.create' : false}>
    //     <DropdownItem label="Pre-certification" glyph="add" color="blue" link={`${match.url}/utilization-review/pre-certifications/new`} />
    //     <DropdownItem
    //       label="Initial Authorization"
    //       glyph="add"
    //       color="blue"
    //       link={`${match.url}/utilization-review/initial-authorizations/new`}
    //     />
    //     <DropdownItem label="Concurrent Review" glyph="add" color="blue" link={`${match.url}/utilization-review/concurrent-reviews/new`} />
    //     <DropdownItem
    //       label="Documentation Review"
    //       glyph="add"
    //       color="blue"
    //       link={`${match.url}/utilization-review/documentation-reviews/new`}
    //     />
    //     <DropdownItem label="Clinical Appeal" glyph="add" color="blue" link={`${match.url}/utilization-review/clinical-appeals/new`} />
    //     <DropdownItem
    //       label="Discharge Planning"
    //       glyph="add"
    //       color="blue"
    //       link={`${match.url}/utilization-review/discharge-plannings/new`}
    //     />
    //   </Dropdown>
    // )
  } else if (pathname.includes(`${match.url}/billing`)) {
    selectedTab = 'billing'
    actions = (
      <Button
        label="Add Claim"
        link={`${pathname}/new`}
        type="primary"
        glyph="add"
        permission={isBehave ? true : isSelfServiceBilling ? 'rcm.create' : false}
      />
    )
  } else if (pathname.includes(`${match.url}/claim-status`)) {
    selectedTab = 'claim-status'
  } else if (pathname.includes(`${match.url}/posting`)) {
    selectedTab = 'posting'
    actions = (
      <Dropdown label="Add Payment…" buttonType="primary" permission={isBehave ? true : isSelfServiceBilling ? 'rcm.create' : false}>
        <DropdownItem label="Batch Payments" glyph="circle_add" color="blue" link={`${match.url}/posting/payments-builder`} />
        <Divider />
        <DropdownItem
          label="Client Payment"
          glyph="add"
          color="blue"
          link={{
            pathname: `${match.url}/posting/new`,
            data: { category: 'client' },
          }}
        />
        <DropdownItem
          label="Insurance Payment"
          glyph="add"
          color="blue"
          link={{
            pathname: `${match.url}/posting/new`,
            data: { category: 'insurance' },
          }}
        />
      </Dropdown>
    )
  }

  return (
    <Permission
      permission={isBehave ? true : isSelfServiceBilling ? 'rcm.view' : false}
      featureFlagV2={isBehave ? true : isSelfServiceBilling ? 'rcm' : false}
    >
      <Page icon="financials" title="Revenue Cycle Management" breakpoint={4} actions={actions}>
        <Tabs defaultTab="support_main_plan">
          <Tabs.List css={styles.tabsList}>
            {/* <Tabs.Item isDisabled label="Dashboard" icon="dashboard" to={`${match.url}/dashboard`} /> */}
            {/* <Tabs.Item isDisabled label="Verifications" icon="rcm_verifications" to={`${match.url}/verifications`} /> */}
            {/* <Tabs.Item
              label="Utilization Review"
              icon="rcm_utilization_review"
              to={`${match.url}/utilization-review`}
            /> */}
            <Tabs.Item label="Billing" icon="rcm_billing" to={`${match.url}/billing`} />
            <Tabs.Item
              label="Claim Status"
              icon="rcm_claim_status"
              to={`${match.url}/claim-status`}
            />
            <Tabs.Item label="Posting" icon="rcm_posting" to={`${match.url}/posting`} />
          </Tabs.List>
        </Tabs>

        <Switch>
          <Route path={`/:resource_type/:resource_id/rcm/dashboard`} component={Dashboard} />
          <Route path={`/:resource_type/:resource_id/rcm/verifications`} component={Verifications} />
          {/* <Route path={`/:resource_type/:resource_id/rcm/utilization-review`} component={UtilizationReview} /> */}
          <Route path={`/:resource_type/:resource_id/rcm/billing`} component={Billing} />
          <Route path={`/:resource_type/:resource_id/rcm/claim-status`} component={ClaimStatus} />
          <Route path={`/:resource_type/:resource_id/rcm/posting`} component={Posting} />

          <Redirect exact from={match.url} to={`${match.url}/billing`} />
        </Switch>
      </Page>
    </Permission>
  )
}

const styles = {
  tabsList: {
    marginTop: '-0.75rem',
    marginBottom: '1.25rem',
  },
}

export default RCM
