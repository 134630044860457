import React from 'react'
import { darken, tint } from 'polished'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { COLORS } from '../../../theme'
import { withOverlayError } from '../../../hocs/withOverlayError'
import { isDefined } from '../../../utils/functions'

import Alert from '../../Alert'
import Button from '../../Button'
import ButtonGroup from '../../ButtonGroup'
import Card from '../../Card'
import CardContent from '../../CardContent'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import DataList from '../../DataList'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Label from '../../Label'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Select from '../../Forms/Select'
import Textarea from '../../Forms/Textarea'

import { useGet, useUpdate } from '../../../hooks/useNewAPI'
import useStore from '../../../modules/store'

const ClientPortalInvite = ({ location, history }: any) => {
  const { resource_id: clientID } = useParams()
  const online = useStore((state) => state.online)

  const { data: client }: any = useGet({ name: ['client', clientID], url: `/residents/${clientID}` })

  const { mutateAsync, isLoading }: any = useUpdate({
    name: ['client', clientID],
    url: `/residents/${clientID}/invite_to_client_portal`,
    invalidate: ['clients'],
  })

  const hasEmail = !!client?.email
  const hasPhone = !!client?.phone_no

  const form = React.useRef(null)
  const [hasConsent, setHasConsent] = React.useState(false)
  const [inviteType, setInviteType]: any = React.useState(hasEmail ? 'email' : 'phone_no')

  const canInvite = React.useMemo(() => {
    if (inviteType === 'phone_no') return hasPhone
    else if (inviteType === 'email') return hasEmail
  }, [inviteType, hasEmail, hasPhone])

  const close = () => {
    const path = location.pathname
    history.push(path.substr(0, path.lastIndexOf('/')))
  }

  const sendClientInvite = async () => {
    const formData = form.current.getFormValue()

    await mutateAsync({
      is_allowed_to_contact: hasConsent,
      invite: {
        ...formData,
        sent_to_details: {
          full_name: client.name,
          ...(inviteType === 'email' && { email: client.email }),
          ...(inviteType === 'phone_no' && { phone_no: client.phone_no }),
        },
      },
    })

    close()
  }

  React.useEffect(() => {
    if (isDefined(client?.is_allowed_to_contact)) setHasConsent(client.is_allowed_to_contact)
  }, [client?.is_allowed_to_contact])

  if (!client) return null

  return (
    <Overlay showBackdrop position="center" onClose={close} isLoading={!client}>
      <Overlay.Header glyph="portal" title={`Invite ${client.first_name} to Client Portal`} />

      <Overlay.Content>
        <Section>
          <FormSection>
            <ButtonGroup stretchSelf>
              <Button
                label="Invite via Email"
                color="gray"
                glyph="email"
                type={inviteType === 'email' ? 'primary' : 'default'}
                onClick={() => setInviteType('email')}
                size={200}
              />
              <Button
                label="Invite via Phone"
                color="gray"
                glyph="cell_phone"
                type={inviteType === 'phone_no' ? 'primary' : 'default'}
                onClick={() => setInviteType('phone_no')}
                size={200}
              />
            </ButtonGroup>

            {!canInvite && (
              <>
                <Alert contrast glyph="warning" type="warning">
                  <h4 css={{ margin: '0 0 0.25rem' }}>
                    No {inviteType === 'phone_no' ? 'Cell Number' : 'Email'} Added for {client.first_name}
                  </h4>
                  <p className="!m-0">
                    Please add {inviteType === 'phone_no' ? 'a cell number' : 'an email address'} from{' '}
                    <Link to={`/clients/${clientID}/general-info`}>General Info → Contact Details</Link> and return to send the Client
                    Portal invite.
                  </p>
                </Alert>
              </>
            )}

            {canInvite && (
              <Form getForm={form}>
                <FormSection>
                  <div>
                    <Label label={`Registration invite will be sent via ${inviteType === 'phone_no' ? 'text message' : 'email'} to:`} />

                    <div css={STYLES.monoType}>
                      {inviteType === 'phone_no' ? client.phone_no : inviteType === 'email' ? client.email : null}
                    </div>

                    <div css={STYLES.smallText}>
                      You can edit this {inviteType === 'phone_no' ? 'cell number' : 'email address'} from{' '}
                      <Link to={`/clients/${clientID}/general-info`}>General Info → Contact Details</Link> and return to send the Client
                      Portal invite
                    </div>
                  </div>

                  <Textarea
                    label="Invite Message"
                    model="message"
                    defaultValue={'Please accept this invite to get access to our Client Portal'}
                  />

                  <CheckboxGroup label="Consent to Contact" trueIcon="check" falseIcon="cross" falseStyle="faded">
                    <Checkbox
                      label="I acknowledge that the Client has given valid Consent to be contacted"
                      value={hasConsent}
                      onUpdate={(state: any) => setHasConsent(state.value)}
                    />
                  </CheckboxGroup>

                  <Alert contrast glyph="warning" type="warning">
                    Consent to contact is required to send {client.first_name} a Client Portal invitation. <b>Do not enable</b> without a
                    valid, signed authorization from the client.
                  </Alert>

                  <Card>
                    <CardContent showDivider={false} css={{ background: COLORS.white }}>
                      <DataList layout="vertical">
                        <DataList.Title
                          title={`Sending the Client Portal invite will give ${client.first_name} access to the
                      following data:`}
                        />

                        <DataList.Item
                          label="General Info"
                          value="Can see and update Personal Details, Contact Details, Medical Information, Sensitive Information, Insurance Information and Home Address"
                        />
                        <DataList.Item label="To-Do's" value="Can see and update only the To-Do's of Type Client" />
                        <DataList.Item label="Contacts" value="Can see and update, but cannot delete" />
                        <DataList.Item label="Ledger" value="Can see, but cannot update or delete" />
                        <DataList.Item label="Treatment Plans" value="Can see, but cannot update or delete" />
                        <DataList.Item label="Medications" value="Can see, but cannot update or delete" />
                        <DataList.Item label="Test Results" value="Can see, but cannot update or delete" />
                        <DataList.Item label="Staff" value="Can see limited information, and only if shared with the Portal" />
                        <DataList.Item label="Locations" value="Can see limited information, and only if shared with the Portal" />
                        <DataList.Item label="Organizations" value="Can see limited information, and only if shared with the Portal" />
                      </DataList>
                    </CardContent>
                  </Card>
                </FormSection>
              </Form>
            )}
          </FormSection>
        </Section>
      </Overlay.Content>

      <Overlay.Footer online={online}>
        {canInvite && (
          <Button
            label="Send Client Portal Invite"
            type="primary"
            color="green"
            isLoading={isLoading}
            onClick={sendClientInvite}
            isDisabled={!hasConsent}
          />
        )}

        {!canInvite && (
          <Button
            label={`Add Missing ${inviteType === 'phone_no' ? 'Cell Number' : 'Email Address'} →`}
            link={`/clients/${clientID}/general-info`}
          />
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const STYLES = {
  monoType: {
    display: 'inline-flex',
    fontSize: '1.05rem',
    fontFamily: 'monospace',
    fontWeight: 400,
    borderRadius: 5,
    background: COLORS.hover,
    border: `1px solid ${COLORS.divider}`,
    padding: '0.4rem 0.8rem',
    color: COLORS.text,
    letterSpacing: 1,

    '&.is-success': {
      color: darken(0.05, COLORS.gray),
      borderColor: tint(0.6, COLORS.gray),
      background: tint(0.95, COLORS.gray),

      svg: {
        fill: COLORS.gray,
      },
    },
  },

  smallText: {
    fontSize: '0.9rem',
    color: COLORS.gray,
    marginTop: '0.25rem',
  },
}

export default withOverlayError(ClientPortalInvite)
