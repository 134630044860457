import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import size from 'lodash/size'

import { useCreate } from '../../../../hooks/useNewAPI'
import { BehaveAIChat } from '../../../BehaveAI/BehaveAIChat'

import Alert from '../../../../components/Alert'
import Button from '../../../../components/Button'
import Dropdown from '../../../../components/Dropdown'
import DropdownItem from '../../../../components/DropdownItem'
import Flex from '../../../../components/Flex'
import Form from '../../../../components/Forms/Form'
import FormSection from '../../../../components/Forms/FormSection'
import Input from '../../../../components/Forms/Input'
import Label from '../../../../components/Label'
import Mermaid from '../../../../components/Mermaid'
import Option from '../../../../components/Forms/Option'
import Overlay from '../../../../components/Overlay'
import Select from '../../../../components/Forms/Select'
import State from '../../../../components/State'
import SummonOverlay from '../../../../components/SummonOverlay'
import Textarea from '../../../../components/Forms/Textarea'

export const MermaidEditor = (props: any) => {
  const { activeElement, editElementConfig, editActiveElementFromInput: onUpdate } = props

  const handleSave = (newValue: any) => {
    editElementConfig({
      uuid: activeElement.uuid,
      config: { mermaid_value: newValue },
    })
  }

  if (!activeElement?.config) return null

  const value = activeElement?.config?.mermaid_value

  const {
    aspect_ratio_high = 9,
    aspect_ratio_wide = 16,
    mermaid_height = 800,
    mermaid_height_type = 'pixels',
    mermaid_sizing_strategy = 'aspect_ratio',
    mermaid_width = 100,
    mermaid_width_type = 'percentage',
  } = activeElement.config

  return (
    <>
      <Select label="Sizing Strategy" model="mermaid_sizing_strategy" value={mermaid_sizing_strategy} onUpdate={onUpdate}>
        <Option label="Aspect Ratio" value="aspect_ratio" />
        <Option label="Custom Width/Height" value="custom" />
      </Select>

      <Flex nowrap gap="0.75rem">
        <Input label="Width" type="number" model="mermaid_width" size={4} value={mermaid_width || 100} onUpdate={onUpdate} />
        <Select label="Type" model="mermaid_width_type" value={mermaid_width_type} onUpdate={onUpdate} flex="1 1 auto">
          <Option label="Percent" value="percentage" />
          <Option label="Pixels" value="pixels" />
          <Option label="Screen Percent" value="viewport_width" />
        </Select>
      </Flex>

      {mermaid_sizing_strategy === 'custom' && (
        <Flex nowrap gap="0.75rem">
          <Input label="Height" type="number" model="mermaid_height" size={4} value={mermaid_height || 100} onUpdate={onUpdate} />
          <Select label="Type" model="mermaid_height_type" value={mermaid_height_type} onUpdate={onUpdate} flex="1 1 auto">
            <Option label="Percent" value="percentage" />
            <Option label="Pixels" value="pixels" />
            <Option label="Screen Percent" value="viewport_height" />
          </Select>
        </Flex>
      )}

      {mermaid_sizing_strategy === 'aspect_ratio' && (
        <div>
          <Label
            label="Aspect Ratio"
            after={
              <Dropdown
                triggerStyles={STYLES.dropdownTrigger}
                trigger={<Button hideLabel glyph="triangle_down" size={100} css={STYLES.dropdownButton} />}
              >
                <DropdownItem
                  label="Square (1:1)"
                  onClick={() => {
                    editElementConfig({
                      uuid: activeElement.uuid,
                      config: {
                        aspect_ratio_wide: 1,
                        aspect_ratio_high: 1,
                      },
                    })
                  }}
                />
                <DropdownItem
                  label="Video (16:9)"
                  onClick={() => {
                    editElementConfig({
                      uuid: activeElement.uuid,
                      config: {
                        aspect_ratio_wide: 16,
                        aspect_ratio_high: 9,
                      },
                    })
                  }}
                />
                <DropdownItem
                  label="Portrait (2:4)"
                  onClick={() => {
                    editElementConfig({
                      uuid: activeElement.uuid,
                      config: {
                        aspect_ratio_wide: 2,
                        aspect_ratio_high: 4,
                      },
                    })
                  }}
                />
                <DropdownItem
                  label="Landscape (4:2)"
                  onClick={() => {
                    editElementConfig({
                      uuid: activeElement.uuid,
                      config: {
                        aspect_ratio_wide: 4,
                        aspect_ratio_high: 2,
                      },
                    })
                  }}
                />
              </Dropdown>
            }
          />
          <Flex gap="0.75rem">
            <Input suffix="wide" type="number" model="aspect_ratio_wide" size={2} value={aspect_ratio_wide} onUpdate={onUpdate} />
            <Input suffix="high" type="number" model="aspect_ratio_high" size={2} value={aspect_ratio_high} onUpdate={onUpdate} />
          </Flex>
        </div>
      )}

      <div>
        <Label label="Mermaid Content" />

        <SummonOverlay overlay={<ValueEditOverlay initialValue={value} onSave={handleSave} />}>
          <Button label={`${value ? 'Update' : 'Set'} Mermaid Content`} color="pink" glyph="mermaid" size={200} />
        </SummonOverlay>

        {value && (
          <pre className="mt-3 text-text-muted px-3 py-2 border-solid border-divider bg-hover text-[0.88rem] rounded-md">{value}</pre>
        )}
      </div>
    </>
  )
}

const ValueEditOverlay = ({ initialValue, onSave, onClose }) => {
  const form = React.useRef()

  const [chatId, setChatId] = React.useState('')
  const [value, setValue] = React.useState(initialValue || '')

  const [mermaid, setMermaid]: any = React.useState(null)
  const [isMermaidValid, setIsMermaidValid] = React.useState(false)

  const handleSave = () => {
    onSave(value)
    onClose()
  }

  const { mutateAsync: createChat, isLoading: isCreatingChat }: any = useCreate({
    name: ['start-new-chat'],
    url: `/ai/chats`,
    invalidate: 'ai-chats',
  })

  const startNewChat = async () => {
    try {
      const { data: newChat }: any = await createChat({
        ai_bot_identifier: 'forms_mermaid',
        category: 'form_builder_mermaid_chat',
      })

      setChatId(newChat.id)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="right" maxWidth={chatId ? 90 : 70}>
      <Overlay.Header title="Update Mermaid Content" glyph="mermaid" />

      <div
        className={clsx(
          'grid h-[100%] grid-rows-[100%] overflow-hidden bg-[#F7F8FB]',
          chatId ? 'grid-cols-[0.8fr_1fr]' : 'grid-cols-[300px_1fr]',
        )}
      >
        <div className="overflow-y-auto border-r border-0 border-solid border-divider !bg-white !bg-opacity-80 !shadow-right-soft-1 relative !z-[3]">
          {!chatId && (
            <State
              isEmpty
              icon="behave_ai"
              title="Behave AI"
              emptyDescription={
                <>
                  Generate Mermaid content
                  <br />
                  using Behave AI
                </>
              }
              emptyActions={
                <Button label="Start Chat" glyph="chat" type="primary" size={200} onClick={startNewChat} isLoading={isCreatingChat} />
              }
            />
          )}

          {chatId && <BehaveAIChat chatId={chatId} className="min-h-full" footerClassName="px-4 pb-1" />}
        </div>

        <div className="grid grid-rows-[1fr_auto] overflow-hidden">
          <div className="overflow-y-auto p-4">
            <Form getForm={form}>
              <FormSection maxWidth="100%">
                <Alert small contrast glyph="info">
                  <ol className="pl-5">
                    <li>Enter your Mermaid content below</li>
                    <li>Click the "Test Mermaid Code" button; if the Mermaid diagram is valid, it will be displayed below</li>
                    <li>Click the "Save Changes" button to save the Mermaid content to the form element</li>
                  </ol>
                </Alert>

                <Textarea
                  label="Mermaid Content"
                  value={value}
                  minRows={20}
                  onUpdate={(state) => setValue(state.value)}
                  className="[&_textarea]:!font-mono"
                />

                {mermaid && (
                  <Mermaid key={`mermaid-${mermaid.id}`} id={mermaid.id} value={mermaid.value} onValidationUpdate={setIsMermaidValid} />
                )}
              </FormSection>
            </Form>
          </div>

          <div className="px-4 pb-3">
            {mermaid && value && mermaid.value === value && isMermaidValid ? (
              <Button label="Save Changes" type="primary" color="green" glyph="multi_select" onClick={handleSave} />
            ) : (
              <Button
                label="Test Mermaid Code"
                isDisabled={!value}
                color="blue"
                type="primary"
                glyph="mermaid"
                onClick={() => {
                  setMermaid({
                    id: uuid(),
                    value,
                  })
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Overlay>
  )
}

const STYLES = {
  dropdownTrigger: {
    marginLeft: 'auto',
  },

  dropdownButton: {
    width: 22,
    height: 22,
    minWidth: 22,
    minHeight: 22,
    svg: { width: 14, height: 14 },
  },
}
