import React from 'react'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

import { FILTERS } from '../../../../constructs/Filters/config'

const CATEGORY = {
  housing: 'Housing',
  office: 'Office',
  mixed_category: 'Mixed (Housing & Office)',
}

export const PropertiesTable = ({ onSelect, dependentValue, includeColumns, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-properties'],
    endpoint: '/houses',
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_properties_v1',
  })

  const defaultColumns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            onClick={onSelect ? () => onSelect(data) : undefined}
          />
        ),
      },
      {
        width: 160,
        model: 'category',
        title: 'Category',
        formatValue: ({ data, value }: any) => CATEGORY?.[value],
      },
      {
        width: 160,
        model: 'gender',
        title: 'Accepted Gender',
        type: 'title',
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'phone_no',
        title: 'Phone',
      },
      {
        width: 160,
        id: 'email',
        model: 'email',
        title: 'Email',
      },
      {
        title: 'Address',
        model: 'address',
        type: 'address',
        disableSort: true,
      },
    ],
    [],
  )

  const columns = React.useMemo(() => {
    if (!includeColumns) return defaultColumns

    return defaultColumns.filter((column) => {
      return includeColumns.includes(column.id) || includeColumns.includes(column.model)
    })
  }, [includeColumns])

  return (
    <DataTable
      {...tableProps}
      {...rest}
      icon="gps_check_in_out"
      title="Locations"
      columns={columns}
      filtersConfig={FILTERS.locations}
      searchBarFilterKey="name"
    />
  )
}
