import React from 'react'

import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import RichTextEditor from '../../components/Forms/RichTextEditor'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootPhysicianOrderOverlay = () => (
  <DataFormOverlay
    title="Physician Order"
    category="physician_order"
    subcategory="physician_order"
    icon="physician_orders"
    requestClientSignature={false}
    requestAuthorSignature={true}
    requireSupervisor={true}
  >
    <>
      <Section
        title="Order Types"
        scrollview={{
          id: 'order_types',
          name: 'Order Types',
        }}
        commentsModel="data.order_types"
      >
        <FormSection>
          <CheckboxGroup layout="vertical-dense">
            <Checkbox label="Adding Medication" model="data.order_types.adding_medication" />
            <Checkbox label="Discontinuing Medication" model="data.order_types.discontinuing_medication" />
            <Checkbox label="Changing Existing Medication" model="data.order_types.changing_existing_medication" />
            <Checkbox label="Urine Analysis Test" model="data.order_types.urine_analysis_test" />
            <Checkbox label="Breathalyzer Test" model="data.order_types.breathalyzer_test" />
            <Checkbox label="Blood Test" model="data.order_types.blood_test" />
            <Checkbox label="Other Order" model="data.order_types.other_order" />
          </CheckboxGroup>

          <ContextShow when="data.order_types.other_order" is={true}>
            <Textarea label="Other Order Details" model="data.order_types.other_order_extra" />
          </ContextShow>
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Notes"
        scrollview={{
          id: 'notes',
          name: 'Notes',
        }}
        commentsModel="data.physician_order_notes"
      >
        <RichTextEditor useQuickText maxRows={20} model="data.notes" />
      </Section>
    </>
  </DataFormOverlay>
)

export const PhysicianOrderOverlay = withOverlayError(RootPhysicianOrderOverlay)
