import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'
import Glyph from '../../../components/Glyph'

export const FilterDropdownTrigger = React.forwardRef((props: any, ref) => {
  const { label, onClick } = props

  return (
    <div ref={ref} css={STYLES.trigger} onClick={onClick}>
      <span>{label || 'Select…'}</span>
      <Glyph glyph="triangle_down" size={10} />
    </div>
  )
})

const STYLES = {
  trigger: {
    padding: '0 0.4rem',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    color: COLORS.blue,
    fontWeight: 500,
    cursor: 'pointer',
    minHeight: 'var(--filter-height)',

    '&:hover': {
      backgroundColor: tint(0.92, COLORS.blue),
    },

    svg: {
      marginLeft: '0.2rem',
    },
  },
}
