import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  level_of_care: {
    label: 'Level Of Care',
    color: 'yellow',
  },
  ancillary: {
    label: 'Ancillary',
    color: 'green',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
