import React from 'react'
import isEqual from 'react-fast-compare'

import Tooltip from '../../../../components/Tooltip'
import { Worksheet as BHWorksheet } from '../../../../components/Worksheet/Worksheet'

export const SingleSelectInputBooleanEditor = (props: any) => {
  const { activeElement, editElementConfig } = props

  const { single_select_input_values } = activeElement.config

  return (
    <BHWorksheet
      asCard
      withFullScreenToggle
      withWrapToggle={false}
      withShowInvalidToggle={false}
      title="Options"
      allow="update"
      titleClassName="!mr-auto"
      columns={COLUMNS}
      initialData={single_select_input_values}
      onDataUpdate={(dataMap: any, dataIds: any) => {
        const newOptions: any = []

        if (!dataMap || !dataIds) return newOptions

        for (let i = 0; i < dataIds.length; i++) {
          const _id = dataIds[i]
          const row = dataMap[_id]

          newOptions.push({
            _id,
            id: i + 1,
            label: row.label,
            value: row.value,
          })
        }

        if (isEqual(single_select_input_values, newOptions)) return

        editElementConfig({
          uuid: activeElement.uuid,
          config: {
            ...activeElement.config,
            single_select_input_values: newOptions,
          },
        })
      }}
    />
  )
}

const COLUMNS = [
  {
    title: 'Label',
    model: 'label',
    width: 150,
  },
  {
    title: (
      <>
        Value
        <Tooltip
          content="This is the internal form value that will be used to reference each option in the form submission data"
          className="ml-1"
        />
      </>
    ),
    model: 'value',
    width: 120,
    type: 'select',
    config: {
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
    },
  },
]
