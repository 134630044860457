import React from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { countWord } from '../../../../utils/functions'
import { getPrefix } from '../../../../hooks/useNewAPI'
import useAPI from '../../../../hooks/useAPI'

import Alert from '../../../Alert'
import Button from '../../../Button'
import Card from '../../../Card'
import Overlay from '../../../Overlay'
import Section from '../../../Section'

import InsuranceGlobalPayersTable from '../../../Tables/InsuranceGlobalPayersTable'
import { InsuranceGlobalPayersDataTable } from '../../../../constructs/RCM/InsuranceGlobalPayersDataTable'

import { apiCreate, apiGet } from '../../../../modules/api'
import { withOverlayError } from '../../../../hocs/withOverlayError'
import useStore from '../../../../modules/store'
import { useDataTable } from '../../../DataTable/useDataTable'
import { FEATURES } from '../../../../theme'

const LocalPayersImportOverlay = () => {
	const history = useHistory()
	const queryClient = useQueryClient()

	const [selectedRows, setSelectedRows]: any = React.useState([])
	const [loading, setLoading]: any = React.useState(false)

	const isBehave = useStore((state) => state.isBehave)
	const data = useSelector((state: any) => state.data?.insurance_clearing_house_configs?.data)
	const dataLoading = useSelector((state: any) => state.data?.insurance_clearing_house_configs?.loading)
	const isEmpty = size(data) === 0

	// const tableProps = useDataTable({
	//   name: ['insurance-global-payers'],
	//   endpoint: `/insurance_clearing_house_configs${isBehave ? '' : '?clearing_house=change_health'}`,
	//   globalStorageKey: 'insurance_local_payers_v1',
	// })

	useAPI(
		'insurance_clearing_house_configs',
		`/insurance_clearing_house_configs${isBehave ? '' : '?clearing_house=claim_md'}`
	)

	const selectedRowsCount = size(selectedRows)
	const hasSelectedRows = selectedRowsCount >= 1

	const onImport = async () => {
		setLoading(true)

		try {
			const ids = selectedRows.map((o: any) => o.original.id)
			await apiCreate({
				url: '/insurance_local_payers/import',
				params: { ids: ids }
			})
			await apiGet({ name: 'insurance_local_payers', url: '/insurance_local_payers' })

			queryClient.invalidateQueries([getPrefix(), 'insurance_payers'])

			history.goBack()
		} catch (errors) {
			console.debug(errors)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Overlay showBackdrop position="center" maxWidth={100}>
			<Overlay.Header title="Import Insurance Payers" icon={FEATURES.insurance_payers.icon} />

			<Overlay.Content>
				<Section margin="0 0 1.5rem 0">
					<Alert contrast glyph="info" className="mt-4 mb-3">
						Choose <span className="font-[700]">"Clearing House 1 (CH) (Beta)"</span> Payers for use with{' '}
						<span className="font-[700]">Claims & Posting</span>
					</Alert>

					<Card className="!mt-4">
						<InsuranceGlobalPayersTable
							title="Global Insurance Payers"
							data={data}
							isLoading={isEmpty && dataLoading}
							onSelect={setSelectedRows}
						/>
					</Card>

					{/* <div className="mt-4">
            <InsuranceGlobalPayersDataTable {...tableProps} />
          </div> */}
				</Section>
			</Overlay.Content>

			<Overlay.Footer>
				<Button
					label={
						hasSelectedRows
							? `Import ${countWord('Insurance Payers', selectedRowsCount)}`
							: 'Select Insurance Payers to Import'
					}
					isDisabled={!hasSelectedRows}
					type="primary"
					color="green"
					isLoading={loading}
					onClick={onImport}
				/>
			</Overlay.Footer>
		</Overlay>
	)
}

export default withOverlayError(LocalPayersImportOverlay)
