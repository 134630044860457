import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { EmployeesReportDataTable } from '@behavehealth/constructs/LiveReports/EmployeesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Employees = () => {
  const queryParams = { status: 'current' }

  const tableProps = useDataTable({
    name: ['reports', 'employees'],
    endpoint: `/live_reports?category=staff`,
    params: queryParams,
    localStorageKey: 'report_employees_v2',
  })

  const to = React.useMemo(() => (rowData: any) => `/employees/${rowData.id}`, [])

  return (
    <Page feature="employees" title="Live Staff Report">
      <Grid>
        <EmployeesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Employees)
