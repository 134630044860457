import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../theme'

type Props = {
  background?: string
  labelSize: number
  letterSpacing: number
  size: number
  strokeWidth: number
  value: number
} & React.HTMLAttributes<any>

const DonutChart: React.FC<Props> = (props) => {
  const { background, className, labelSize = 19, letterSpacing = 0, size = 50, strokeWidth = 10, value = 0 } = props

  const halfsize = size * 0.5
  const labelOffset = labelSize * 0.375
  const radius = halfsize - strokeWidth * 0.5
  const circumference = 2 * Math.PI * radius
  const strokeval = (value * circumference) / 100
  const dashval = strokeval + ' ' + circumference

  const trackStyle = { strokeWidth: strokeWidth }
  const indicatorStyle = { strokeWidth: strokeWidth, strokeDasharray: dashval }
  const rotateVal = 'rotate(-90 ' + halfsize + ',' + halfsize + ')'
  const textStyle = { fontSize: labelSize }

  const classNames = clsx(className, 'donutchart')

  return (
    <svg width={size} height={size} className={classNames}>
      <circle
        r={radius}
        cx={halfsize}
        cy={halfsize}
        transform={rotateVal}
        style={trackStyle}
        css={{ ...donutChartTrack, stroke: background }}
      />
      <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateVal} style={indicatorStyle} css={donutChartIndicatorStyle} />
      <text css={donutChartText} x={halfsize} y={halfsize + labelOffset} style={textStyle}>
        <tspan css={donutChartTextVal(letterSpacing)}>{value}%</tspan>
      </text>
    </svg>
  )
}

const donutChartIndicatorStyle = {
  fill: 'transparent',
  stroke: COLORS.green,
  strokeWidth: 26,
  strokeDasharray: '0 10000',
  transition: 'stroke-dasharray .3s ease',
}

const donutChartTrack = {
  fill: 'transparent',
  stroke: COLORS.lightGray,
  strokeWidth: 26,
}

const donutChartText = {
  fill: COLORS.green,
  textAnchor: 'middle',
}

const donutChartTextVal = (letterSpacing) => ({
  fontWeight: 600,
  letterSpacing,
})

export default DonutChart
