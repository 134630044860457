import React from 'react'
import { tint } from 'polished'
import { useMedia } from 'use-media'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'

import { COLORS, SHADOW } from '../../../theme'
import { useUpdate } from '../../../hooks/useNewAPI'

import Avatar from '../../Avatar'
import Button from '../../Button'
import Glyph from '../../Glyph'
import Icon from '../../Icon'
import Link from '../../Link'
import NavLink from '../../NavLink'
import SummonOverlay from '../../SummonOverlay'
import Tooltip from '../../Tooltip'

import { EmptyCell } from './EmptyCell'
import { useStore } from '../useStore'

import { VendorQuickLinks } from '../../../constructs/VendorQuickLinks/VendorQuickLinks'

const handleSummonOpen = (event: any) => {
  event.preventDefault()
  event.stopPropagation()
  event.nativeEvent.stopImmediatePropagation()
}

export const MainCell: React.FC = (props: any) => {
  const {
    id,
    value,
    to,
    href,
    glyph,
    glyphColor,
    icon,
    graphic,
    avatar,
    quickViewOverlay,
    queryKey,
    testKey,
    onClick,
    isActive,
    quickLinksVendors,
    className,
    after,
    target,
  } = props

  const isDesktop = useMedia({ minWidth: 600 })

  const queryURL = useStore((state: any) => state.queryURL)

  const { mutate: update, isLoading: isUpdating }: any = useUpdate({
    name: queryKey,
    url: `${queryURL}/${id}`,
  })

  const handleDrop = (folderId: any) => {
    if (!folderId) return

    update({ folder_id: folderId })
  }

  // const [{ isDragging }, dragRef] = useDrag(
  //   () => ({
  //     type: 'TABLE_MAIN_CELL',
  //     item: { id },
  //     end: (item, monitor) => {
  //       const dropResult: any = monitor.getDropResult()

  //       if (!dropResult || item.id === dropResult.id) return // cannot drop on itself

  //       handleDrop(dropResult?.id)
  //     },
  //     collect: (monitor) => ({
  //       isDragging: monitor.isDragging(),
  //       handlerId: monitor.getHandlerId(),
  //     }),
  //   }),
  //   [handleDrop], // required dependency array, otherwise handleDrop function doesn't work
  // )

  const linkClasses = clsx(isActive && 'is-active', className)

  // if (!value) return <EmptyCell testKey={testKey} />

  const hasAvatar = !isUndefined(avatar)
  const hasGraphic = hasAvatar || glyph || icon || graphic

  const vendorQuickLinks = quickLinksVendors && <VendorQuickLinks value={value} vendors={quickLinksVendors} />

  const graphicElement = hasGraphic && (
    <div className={clsx(hasAvatar && 'has-avatar')} css={STYLES.graphic}>
      {graphic}
      {hasAvatar && <Avatar magnify src={avatar} initials={value} size={22} css={STYLES.avatar} />}
      {glyph && <Glyph glyph={glyph} color={glyphColor} size={16} />}
      {icon && <Icon icon={icon} size={16} />}
    </div>
  )

  if (to) {
    const Tag = props.as || NavLink

    return (
      <div data-test={testKey} css={STYLES.root}>
        <Tag to={to} className={linkClasses} css={STYLES.navLink}>
          {graphicElement}
          <span css={STYLES.value}>{value || <EmptyCell />}</span>
          {vendorQuickLinks}
        </Tag>

        {quickViewOverlay && (
          <>
            <SummonOverlay onOpen={handleSummonOpen} overlay={quickViewOverlay}>
              <div css={STYLES.quickViewButton}>
                <Tooltip content="Quick View" show={isDesktop}>
                  <Button testKey="quick_view_button" hideLabel size={100} glyph="quick_view" />
                </Tooltip>
              </div>
            </SummonOverlay>
          </>
        )}

        {after}
      </div>
    )
  }

  if (href) {
    return (
      <div data-test={testKey} css={STYLES.root}>
        <a href={href} className={linkClasses} css={STYLES.navLink} target={target}>
          {graphicElement}
          <span css={STYLES.value}>{value || <EmptyCell />}</span>
          {vendorQuickLinks}
          {after}
          {target === '_blank' && <Glyph glyph="external_link" size={12} color={COLORS.blue} className="ml-1" />}
        </a>
      </div>
    )
  }

  if (onClick) {
    return (
      <div data-test={testKey} css={STYLES.root}>
        <div onClick={onClick} className={linkClasses} css={STYLES.navLink}>
          {graphicElement}
          <span css={STYLES.value}>{value || <EmptyCell />}</span>
          {vendorQuickLinks}
          {after}
        </div>
      </div>
    )
  }

  return (
    <div css={STYLES.root}>
      {graphicElement}
      <span css={STYLES.value}>{value || <EmptyCell />}</span>
      {vendorQuickLinks}
      {after}
    </div>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: '1 1 auto',
    fontWeight: 600,

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0 0.5rem',
    margin: '0 !important',

    '&:hover': {},

    '&.is-dragging': {
      opacity: 0.5,
      border: `1px dashed ${tint(0.8, COLORS.blue)}`,
    },
  },

  graphic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    minWidth: 'fit-content',
    maxWidth: '1rem',
    marginRight: '0.5rem',

    '&.has-avatar': {
      maxWidth: 24,
    },
  },

  navLink: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: '1 1 auto',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    color: COLORS.blue,
    cursor: 'pointer',

    '&.is-active::before, &.active::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: tint(0.8, COLORS.vividBlue),
      boxShadow: `inset 0 0 0 1px ${COLORS.divider}, ${SHADOW(3)} !important`,
      zIndex: -1,
    },
  },

  value: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flex: '1 1 auto',
  },

  quickViewButton: {
    svg: { margin: '0 !important' },

    '@media(max-width: 599px)': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      border: '2px solid',
      opacity: 0,
    },
  },

  avatar: {
    boxShadow: 'none !important',
  },
}
