import React from 'react'
import clsx from 'clsx'

import { css, COLORS } from '../../../theme'

import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import Icon from '../../../components/Icon'
import RoadmapHeaderRow from '../../../components/Roadmap/RoadmapHeaderRow'

export const GroupHeader = (props: any) => {
  const { icon, title, isDisabled, isAllOpen, onToggleOpen } = props

  const rootClasses = clsx(STYLES.groupHeaderRow().className, isDisabled && 'is-disabled')

  return (
    <RoadmapHeaderRow className={rootClasses}>
      <div className={STYLES.groupHeader()}>
        <Icon icon={icon} size={18} className={STYLES.groupHeaderIcon().className} />

        <Flex centerY stretchSelf justifyContent="space-between">
          <div className={STYLES.groupHeaderTitle()}>{title}</div>
        </Flex>

        <Button
          onClick={onToggleOpen}
          label={`${isAllOpen ? 'Close' : 'Open'} All`}
          size={100}
          glyph={isAllOpen ? 'close_all_rows' : 'open_all_rows'}
          display="inline-block"
          type="minimal"
          className="!ml-auto"
        />
      </div>
    </RoadmapHeaderRow>
  )
}

const STYLES = {
  groupHeaderRow: css({
    display: 'flex',
    alignItems: 'center',
    // borderTop: `1px solid ${COLORS.divider}`,
  }),

  groupHeader: css({
    display: 'flex',
    alignItems: 'center',
    padding: '0.15rem 0',
    flex: '1 1 auto',
  }),

  groupHeaderIcon: css({
    marginRight: '0.5rem',
  }),

  groupHeaderTitle: css({
    fontSize: '0.94rem',
    fontWeight: 700,
    color: COLORS.text,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginRight: 'auto',
  }),
}
