import React from 'react'
import { connect } from 'react-redux'

import Chotomate from '../../../Chotomate'
import ClientProfileHeader from '../../../ClientProfileHeader'
import ContextHide from '../../../Forms/ContextHide'
import DataFormStatus from '../../../Statuses/DataFormStatus'
import DateInput from '../../../Forms/DateInput'
import Diagnoses from './common/Diagnoses'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import FormSection from '../../../Forms/FormSection'
import GoalsAndObjectives from './common/GoalsAndObjectives'
import HelpTagIframe from '../../../Help/HelpTagIframe'
import Input from '../../../Forms/Input'
import Overlay from '../../../Overlay'
import Problems from './common/Problems'
import Section from '../../../Section'
import SNAP from './common/SNAP'
import Switch from '../../../Forms/Switch'
import Textarea from '../../../Forms/Textarea'

import { DataFormsBase, mapStateToProps, mapDispatchToProps } from './DataFormsBase'
import { withOverlayError } from '../../../../hocs/withOverlayError'

class TreatmentPlanOverlayV1 extends DataFormsBase {
  renderHeader = () => (
    <Overlay.Header
      icon="treatment_plans"
      title={this.props.data_form?.name || this.props.title}
      help={<HelpTagIframe id="treatment_plans" />}
      titleAside={<DataFormStatus status={this.props.data_form?.status} />}
      description={<ClientProfileHeader client={this.props.data_form?.client || this.props.client} />}
    />
  )

  renderContent = () => {
    const { $editable } = this.state

    return (
      <>
        <Chotomate ready name="treatment_plan_overlay" />

        <Section
          scrollview={{
            id: 'appointments',
            name: 'Appointments',
          }}
          headingType="h2"
          title="Appointments"
          commentsModel="data.appointments"
        >
          <FormSection layout="vertical">
            <DateInput label="Review Date" model="data.appointments.review_date" />
            <Flex gap={8} stretchChildrenX>
              <Input label="Frequency of Appointments" model="data.appointments.frequency" />
              <Input label="Estimated Length of Treatment" model="data.appointments.estimated_length" />
            </Flex>
          </FormSection>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'problems',
            name: 'Problems',
          }}
          headingType="h2"
          title="Problems"
          commentsModel="data.problems_section"
        >
          <Problems isEditable={$editable} />
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'goals_and_objectives',
            name: 'Goals & Objectives',
          }}
          headingType="h2"
          title="Goals & Objectives"
          commentsModel="data.goals_and_objectives"
        >
          <GoalsAndObjectives isEditable={$editable} />
        </Section>

        <Divider />

        <Section
          title="Strengths, Needs, Abilities, Preferences"
          aside={<Switch horizontal inverse model="data.snap.not_applicable" />}
          scrollview={{
            id: 'snap',
            name: 'S.N.A.P.',
          }}
          commentsModel="data.snap"
        >
          <ContextHide when="data.snap.not_applicable" is={true}>
            <SNAP />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'diagnoses',
            name: 'Diagnoses',
          }}
          headingType="h2"
          title="Diagnoses"
          commentsModel="data.diagnosis_section"
        >
          <Diagnoses isEditable={$editable} />
        </Section>

        <Divider />

        <Section
          title="Summary"
          aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
          scrollview={{
            id: 'summary',
            name: 'Summary',
          }}
          commentsModel="data.summary"
        >
          <ContextHide when="data.summary.not_applicable" is={true}>
            <FormSection layout="vertical" maxWidth="100%">
              <Textarea label="Interpretive Summary" model="data.summary.interpretive_summary" />
              <Textarea label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
              <Textarea label="Recommendations" model="data.summary.recommendations" />
            </FormSection>
          </ContextHide>
        </Section>
      </>
    )
  }
}

TreatmentPlanOverlayV1.defaultProps = {
  defaultDebounce: 0,
  title: 'Treatment Plan',
  category: 'treatment_plan',
  subcategory: 'treatment_plan',
  version: '1.0',
  requestClientSignature: true,
  requestAuthorSignature: true,
  requireSupervisor: true,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(TreatmentPlanOverlayV1))
