import React from 'react'

import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ContextShow from '../../Forms/ContextShow'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Status from '../../Status'
import Tooltip from '../../Tooltip'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

const ComingSoon = () => (
  <Tooltip content="Check this box if you want to get your spot on the waitlist. You will be notified via email.">
    <Status glyph="info" color="vividBlue" label="Coming Soon" css={{ borderRadius: '100px !important' }} />
  </Tooltip>
)

export const ElectronicHealthRecordsFeaturesFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="behave_health" />
        <div>
          <Type as="h2" variant="H3">
            Electronic Health Records Features
          </Type>

          <Type as="p" variant="TEXT" css={{ margin: '0.2em 0 0' }}>
            Check the boxes for the EHR features you would like to evaluate
          </Type>
        </div>
      </Flex>

      <CheckboxGroup
        trueIcon="check"
        falseIcon="cross"
        falseStyle="faded-linethrough"
        label="Treatment Services Offered"
        layout="vertical-dense"
      >
        <Checkbox defaultChecked label="We offer clinical services" model="questionnaire.ehr_features.does_offer_clinical_services" />
        <Checkbox defaultChecked label="We offer medical services" model="questionnaire.ehr_features.does_offer_medical_services" />
      </CheckboxGroup>

      <ContextShow when="questionnaire.ehr_features.does_offer_clinical_services" is={true}>
        <CheckboxGroup
          withToggle
          trueIcon="check"
          falseIcon="cross"
          falseStyle="faded-linethrough"
          label="Clinical EHR"
          layout="vertical-dense"
        >
          <Checkbox
            label="Clinical Assessments; Biopsychosocial, Pre-screen, Suicide Assessment, etc."
            model="questionnaire.ehr_features.clinical_ehr.clinical_assessments"
          />
          <Checkbox
            label="Easily manage your Treatment Plans with progress updates"
            model="questionnaire.ehr_features.clinical_ehr.manage_treatment_plans"
          />
          <Checkbox
            label="Wiley Treatment Plan Content will make planning faster and more effective"
            model="questionnaire.ehr_features.clinical_ehr.wiley_treatment_plan_content"
          />
          <Checkbox
            label="Progress reviews for insurance utilization review"
            model="questionnaire.ehr_features.clinical_ehr.progress_reviews"
          />
          <Checkbox
            label="Clinical Notes: Group, Individual, Family, Case Mgmt"
            model="questionnaire.ehr_features.clinical_ehr.clinical_notes"
          />
          <Checkbox
            label="Monitor patient progress trends with Behave Clinical Measures Library"
            tooltip="PHQ-9, GAD-7, ACE, ASI-5, and many more"
            model="questionnaire.ehr_features.clinical_ehr.clinical_measures"
          />
          <Checkbox
            label="Prove the quality of your program with Behave Outcome Measures Toolkit"
            model="questionnaire.ehr_features.clinical_ehr.outcome_measures_toolkit"
          />
        </CheckboxGroup>
      </ContextShow>

      <ContextShow when="questionnaire.ehr_features.does_offer_medical_services" is={true}>
        <CheckboxGroup
          withToggle
          trueIcon="check"
          falseIcon="cross"
          falseStyle="faded-linethrough"
          label="Medical EHR"
          layout="vertical-dense"
        >
          <Checkbox
            label="Medical Assessments: History & Physical & Psychiatric"
            model="questionnaire.ehr_features.medical_ehr.medical_assessments"
          />
          <Checkbox
            label="Nursing: Assessment & Re-assessment, COWS/CIWA, Progress Note, Fall Risk"
            model="questionnaire.ehr_features.medical_ehr.nursing"
          />
          <Checkbox label="Physicians Orders: Medications & Lab Tests" model="questionnaire.ehr_features.medical_ehr.physicians_orders" />
          <Checkbox
            label="Test Results: UA, Breathalyzer, Vital Signs, Communicable Disease"
            model="questionnaire.ehr_features.medical_ehr.test_results"
          />
          <Checkbox
            label="Medication Mgmt: Med List, Med Logs, Med Destruction Logs"
            model="questionnaire.ehr_features.medical_ehr.medication_management"
          />
          <Checkbox
            label="Easily prescribe medications electronically using Behave eRX"
            model="questionnaire.ehr_features.medical_ehr.erx"
          />
          <Checkbox
            label="Easily order lab tests electronically using Behave Lab Connect*"
            description={<ComingSoon />}
            model="questionnaire.ehr_features.medical_ehr.lab_connect"
          />
        </CheckboxGroup>
      </ContextShow>
    </FormSection>
  )
}

export const ElectronicHealthRecordsFeatures: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <ElectronicHealthRecordsFeaturesFields />
    </Form>
  )
}
