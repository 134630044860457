import React from 'react'
import compact from 'lodash/compact'
import startCase from 'lodash/startCase'

import { age } from '../../../utils/functions'
import { useUpdate } from '../../../hooks/useNewAPI'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { Text } from '../../Typography'
import AlumnusNavItems from '../../Navs/AlumnusNavItems'
import ClientStatus from '../../Statuses/ClientStatus'
import QuickViewOverlay from './QuickViewOverlay'
import TagsSelector from '../../TagsSelector'

const AlumniQuickView = ({ client, onClose }: any) => {
  const { mutateAsync: updateClient }: any = useUpdate({
    name: ['client', client?.id],
    url: `/residents/${client?.id}`,
    invalidate: ['clients'],
    invalidateKeys: ['client'],
  })

  return (
    <QuickViewOverlay
      title={client.name}
      avatar={client.avatar}
      onClose={onClose}
      maxWidth={48}
      data={client}
      titleAside={<ClientStatus status={client.status} />}
      subheader={
        <>
          <Text description={`${startCase(client.sex) || '–'}, ${age(client.dob)} y/o, #${client.behave_id}`} />
          {client.house && <Text description={compact([client.house.name, client.room?.name]).join(', ')} avatar={client.house.avatar} />}
        </>
      }
      headerAfter={
        <div className="!mt-3 w-full">
          <TagsSelector tags={client?.tags} onSave={(tagIDs: any) => updateClient({ tag_ids: tagIDs })} smartCategories="past_clients" />
        </div>
      }
    >
      <AlumnusNavItems id={client.id} />
    </QuickViewOverlay>
  )
}

export default withOverlayError(AlumniQuickView)
