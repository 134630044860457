import React from 'react'
import size from 'lodash/size'

import { DataTable } from '../../../DataTable/DataTable'
import { LinkCell } from '../../../DataTable/cells/LinkCell'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

import { DEFAULT_FILTERS } from '../../../../constructs/Filters/constants'
import { CommunityAccountsTable } from './CommunityAccountsTable'

import Grid from '../../../Grid'
import Link from '../../../Link'

const ACCOUNT_TYPES: any = {
  healthcare_facility: 'Healthcare Facility',
  healthcare_practice: 'Healthcare Practice',
  support_facility: 'Support Facility',
  support_practice: 'Support Practice',
  provider_affiliate: 'Provider Partner',
}

const SALES_STEP: any = {
  signed_up: 'Account just signed up',
  invited: 'Owner invited to create password',
  registered: 'Owner password created',
  profile_completed: 'Owner completed the Business Profile',
  activated: 'Trial/Account activated',
}

export const FacilitiesTable = ({ onSelect, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-facilities'],
    endpoint: '/facilities',
    localStorageKey: 'selector_facilities_v3',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        // id: 'facility_name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell id={data.id} value={value} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'Tenant / Subdomain',
        model: 'subdomain',
        id: 'tenant',
      },
      {
        title: 'Tags',
        model: 'global_tags',
        type: 'global_tags',
        editPermission: false,
      },
      {
        title: 'Community Profiles',
        model: 'connected_entities',
        disableSort: true,
        formatValue: ({ value }: any) => {
          if (size(value) === 0) return null

          return (
            <div className="absolute py-1.5 px-2 top-0 bottom-0 left-0 right-0 hover:relative hover:!py-0 hover:!px-0 w-full">
              <Grid gap="0.5rem">
                {value.map((o: any) => (
                  <Link to={`/community/${o.id}`}>{o.name}</Link>
                ))}
              </Grid>
            </div>
          )
        },
      },
      {
        title: 'State',
        model: 'state',
      },
      {
        title: 'Primary Profile',
        model: 'primary_profile',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Community Admin Email',
        model: 'contacts.community.email',
        type: 'email',
        width: 200,
        disableSort: true,
      },
      {
        title: 'Community Admin Phone No',
        model: 'contacts.community.phone_no',
        type: 'phone',
        width: 210,
        disableSort: true,
      },
      {
        title: 'Account Type',
        model: 'category',
        width: 210,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return ACCOUNT_TYPES[value] || value
        },
      },
      {
        title: 'Account Status',
        model: 'plan_status',
        type: 'title',
      },
      {
        title: 'First Name',
        model: 'first_name',
      },
      {
        title: 'Last Name',
        model: 'last_name',
      },
      {
        title: 'Account Email',
        model: 'email',
        type: 'email',
      },
      {
        title: 'Account Phone No',
        model: 'phone_no',
        type: 'phone',
      },
      {
        title: 'Has Community?',
        model: 'has_community',
        disableSort: true,
        formatValue: ({ data }: any) => {
          return size(data.connected_entities) === 0 ? 'No' : 'Yes'
        },
      },
      {
        title: 'Smart Flags',
        model: 'smart_flags',
        type: 'smart_flags',
        editPermission: false,
      },

      {
        title: 'Sales Status',
        model: 'sales_status',
        formatValue: ({ value }: any) => value || '–',
      },
      {
        title: 'Timezone',
        model: 'timezone',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return value.replaceAll('_', ' ')
        },
      },
      {
        title: 'Trial Started On',
        model: 'trial_started_at',
        type: 'date',
        width: 140,
      },
      {
        title: 'Trial Ends On',
        model: 'trial_ended_at',
        type: 'date',
        width: 140,
      },
      {
        title: 'Hubspot Deal',
        model: 'hubspot_deal_id',
        type: 'hubspot_deal',
      },
      {
        title: 'Hubspot Company',
        model: 'hubspot_company_id',
        type: 'hubspot_company',
      },
      {
        title: 'Hubspot EHR Account',
        model: 'hubspot_ehr_account_id',
        width: 200,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal value={value} href={`https://app.hubspot.com/contacts/2828210/record/2-20336856/${value}`} />
        },
      },
      {
        title: 'Stripe Customer',
        model: 'stripe_customer',
        width: 200,
        disableSort: true,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal value={value} href={`https://dashboard.stripe.com/customers/${value}`} />
        },
      },
      {
        title: 'Account Notes',
        model: 'metadata.account_notes',
        width: 200,
        hoverExpand: false,
        disableSort: true,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable {...tableProps} {...rest} title="EHR Accounts" columns={columns} filtersConfig={FILTERS_CONFIG} searchBarFilterKey="name" />
  )
}

const FILTERS_CONFIG = {
  name: DEFAULT_FILTERS.name,
  tenant: {
    label: 'Tenant / Subdomain',
    type: 'string',
  },
  first_name: DEFAULT_FILTERS.first_name,
  last_name: DEFAULT_FILTERS.last_name,
  global_tags: DEFAULT_FILTERS.global_tags,
  smart_flags: {
    label: 'Smart Flags',
    type: 'smart_flags',
    glyph: 'flag',
  },
  connected_entities: {
    label: 'Community Profiles',
    type: 'multi_overlay',
    polymorphic: false,
    selectTitle: (item: any) => item.name,
    icon: 'enterprise',
    table: <CommunityAccountsTable />,
  },
  primary_profile: {
    label: 'Primary Profile',
    type: 'multi_overlay',
    polymorphic: false,
    selectTitle: (item: any) => item.name,
    icon: 'enterprise',
    table: <CommunityAccountsTable />,
  },
  has_community: {
    label: 'Has Community?',
    type: 'boolean',
  },
  email: DEFAULT_FILTERS.email,
  state: {
    label: 'State',
    type: 'string',
  },
  phone_no: DEFAULT_FILTERS.phone_no,
  timezone: DEFAULT_FILTERS.timezone,
  category: {
    label: 'Account Type',
    type: 'multi_select',
    options: [
      { label: 'Healthcare Facility', value: 'healthcare_facility' },
      { label: 'Healthcare Practice', value: 'healthcare_practice' },
      { label: 'Support Facility', value: 'support_facility' },
      { label: 'Support Practice', value: 'support_practice' },
      { label: 'Provider Partner', value: 'provider_affiliate' },
    ],
  },
  plan_status: {
    label: 'Account Status',
    type: 'multi_select',
    options: [
      { label: 'Community', value: 'community' },
      { label: 'Trial Requested', value: 'trial_requested' },
      { label: 'Trialing', value: 'trialing' },
      { label: 'Unpaid', value: 'unpaid' },
      { label: 'Cancelled', value: 'canceled' },
      { label: 'Active', value: 'active' },
      { label: 'Archived', value: 'archived' },
    ],
  },
  hubspot_deal_id: {
    label: 'Hubspot Deal',
    type: 'string',
  },
  hubspot_company_id: {
    label: 'Hubspot Company',
    type: 'string',
  },
  hubspot_ehr_account_id: {
    label: 'Hubspot EHR Account',
    type: 'string',
  },
  stripe_customer_id: {
    label: 'Stripe Customer',
    type: 'string',
  },
  trial_started_at: {
    label: 'Trial Started On',
    type: 'date_time',
    glyph: 'date',
  },
  trial_ended_at: {
    label: 'Trial Ends On',
    type: 'date_time',
    glyph: 'date',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
