export const SERVICE_CODES: any = {
  'Health Benefit Plan Coverage': '[30]',
  Abortion: '[84]',
  Acupuncture: '[64]',
  'Adjunctive Dental Services': '[28]',
  AIDS: '[85]',
  'Air Transportation': '[57]',
  Alcoholism: '[AJ]',
  Allergy: '[GY]',
  'Allergy Testing': '[79]',
  'Alternate Method Dialysis': '[15]',
  'Ambulatory Service Center Facility': '[13]',
  Anesthesia: '[7]',
  Anesthesiologist: '[97]',
  'Audiology Exam': '[71]',
  'Blood Charges': '[10]',
  'Brand Name Prescription Drug': '[91]',
  'Brand Name Prescription Drug - Formulary': '[B2]',
  'Brand Name Prescription Drug - Non-Formulary': '[B3]',
  'Burn Care': '[B1]',
  Cabulance: '[58]',
  Cancer: '[87]',
  Cardiac: '[BL]',
  'Cardiac Rehabilitation': '[BG]',
  'Case Management': '[CQ]',
  Chemotherapy: '[78]',
  Chiropractic: '[33]',
  'Chiropractic Office Visits': '[34]',
  'Chronic Renal Disease (CRD) Equipment': '[16]',
  'Cognitive Therapy': '[BD]',
  Consultation: '[3]',
  'Coronary Care': '[C1]',
  'Day Care (Psychiatric)': '[BC]',
  'Dental Accident': '[37]',
  'Dental Care': '[35]',
  'Dental Crowns': '[36]',
  Dermatology: '[DG]',
  'Diabetic Supplies': '[DS]',
  'Diagnostic Dental': '[23]',
  'Diagnostic Lab': '[5]',
  'Diagnostic Medical': '[73]',
  'Diagnostic X-Ray': '[4]',
  Dialysis: '[76]',
  'Donor Procedures': '[63]',
  'Drug Addiction': '[AK]',
  'Durable Medical Equipment': '[DM]',
  'Durable Medical Equipment Purchase': '[12]',
  'Durable Medical Equipment Rental': '[18]',
  'Emergency Services': '[86]',
  Endocrine: '[BP]',
  Endodontics: '[26]',
  'Experimental Drug Therapy': '[AR]',
  Eye: '[BR]',
  'Eyewear and Eyewear Accessories': '[CP]',
  'Family Planning': '[82]',
  'Flu Vaccination': '[CO]',
  Frames: '[AM]',
  'Free Standing Prescription Drug': '[89]',
  Gastrointestinal: '[BN]',
  'General Benefits': '[60]',
  'Generic Prescription Drug': '[92]',
  'Generic Prescription Drug - Formulary': '[GF]',
  'Generic Prescription Drug - Non-Formulary': '[GN]',
  Gynecological: '[BT]',
  'Home Health Care': '[42]',
  'Home Health Prescriptions': '[43]',
  'Home Health Visits': '[44]',
  Hospice: '[45]',
  Hospital: '[47]',
  'Hospital - Ambulatory Surgical': '[53]',
  'Hospital - Emergency Accident': '[51]',
  'Hospital - Emergency Medical': '[52]',
  'Hospital - Inpatient': '[48]',
  'Hospital - Outpatient': '[50]',
  'Hospital - Room and Board': '[49]',
  Immunizations: '[80]',
  'In-vitro Fertilization': '[61]',
  'Independent Medical Evaluation': '[BA]',
  Infertility: '[83]',
  'Inhalation Therapy': '[72]',
  'Intensive Care': '[IC]',
  'Invasive Procedures': '[BS]',
  Lenses: '[AO]',
  'Licensed Ambulance': '[59]',
  'Long Term Care': '[54]',
  Lymphatic: '[BM]',
  'Mail Order Prescription Drug': '[90]',
  'Mail Order Prescription Drug: Brand Name': '[BW]',
  'Mail Order Prescription Drug: Generic': '[BX]',
  'Major Medical': '[55]',
  'Mammogram, High Risk Patient': '[CM]',
  'Mammogram, Low Risk Patient': '[CN]',
  'Massage Therapy': '[BE]',
  Maternity: '[69]',
  'Maxillofacial Prosthetics': '[27]',
  'Medical Care': '[1]',
  'Medically Related Transportation': '[56]',
  'Mental Health': '[MH]',
  'Mental Health Facility - Inpatient': '[CG]',
  'Mental Health Facility - Outpatient': '[CH]',
  'Mental Health Provider - Inpatient': '[CE]',
  'Mental Health Provider - Outpatient': '[CF]',
  'MRI/CAT Scan': '[62]',
  'Neonatal Intensive Care': '[NI]',
  Neurology: '[BQ]',
  'Newborn Care': '[65]',
  'Nonmedically Necessary Physical': '[AQ]',
  Nursery: '[BI]',
  Obstetrical: '[BU]',
  'Obstetrical/Gynecological': '[BV]',
  'Occupational Therapy': '[AD]',
  Oncology: '[ON]',
  'Oral Surgery': '[40]',
  Orthodontics: '[38]',
  Orthopedic: '[BK]',
  'Other Medical': '[9]',
  'Otological Exam': '[77]',
  'Partial Hospitalization (Psychiatric)': '[BB]',
  Pathology: '[66]',
  Pediatric: '[BH]',
  Periodontics: '[24]',
  Pharmacy: '[88]',
  'Physical Medicine': '[AE]',
  'Physical Therapy': '[PT]',
  'Physician Visit - Office: Sick': '[BY]',
  'Physician Visit - Office: Well': '[BZ]',
  'Plan Waiting Period': '[32]',
  'Pneumonia Vaccine': '[19]',
  Podiatry: '[93]',
  'Podiatry - Nursing Home Visits': '[95]',
  'Podiatry - Office Visits': '[94]',
  'Pre-Admission Testing': '[17]',
  'Private Duty Nursing': '[74]',
  'Private Duty Nursing - Home': '[CB]',
  'Private Duty Nursing - Inpatient': '[CA]',
  'Professional (Physician)': '[96]',
  'Professional (Physician) Visit - Home': '[A3]',
  'Professional (Physician) Visit - Inpatient': '[99]',
  'Professional (Physician) Visit - Nursing Home': '[A1]',
  'Professional (Physician) Visit - Office': '[98]',
  'Professional (Physician) Visit - Outpatient': '[A0]',
  'Professional (Physician) Visit - Skilled Nursing': '[A2]',
  'Prosthetic Device': '[75]',
  Prosthodontics: '[39]',
  Psychiatric: '[A4]',
  'Psychiatric - Inpatient': '[A7]',
  'Psychiatric - Outpatient': '[A8]',
  'Psychiatric - Room and Board': '[A5]',
  Psychotherapy: '[A6]',
  Pulmonary: '[PU]',
  'Pulmonary Rehabilitation': '[BF]',
  'Radiation Therapy': '[6]',
  Rehabilitation: '[A9]',
  'Rehabilitation - Inpatient': '[AB]',
  'Rehabilitation - Outpatient': '[AC]',
  'Rehabilitation - Room and Board': '[AA]',
  Renal: '[RN]',
  'Renal Supplies in the Home': '[14]',
  'Residential Psychiatric Treatment': '[RT]',
  'Respite Care': '[46]',
  Restorative: '[25]',
  'Routine (Preventive) Dental': '[41]',
  'Routine Exam. Use for Routine Vision Exam only.': '[AN]',
  'Routine Physical': '[81]',
  'Screening laboratory': '[CL]',
  'Screening X-ray': '[CK]',
  'Second Surgical Opinion': '[20]',
  'Skilled Nursing Care': '[AG]',
  'Skilled Nursing Care - Room and Board': '[AH]',
  Skin: '[BJ]',
  'Smoking Cessation': '[67]',
  'Social Work': '[22]',
  'Speech Therapy': '[AF]',
  'Substance Abuse': '[AI]',
  'Substance Abuse Facility - Inpatient': '[CI]',
  'Substance Abuse Facility - Outpatient': '[CJ]',
  Surgical: '[2]',
  'Surgical Assistance': '[8]',
  'Surgical Benefits - Facility': '[CD]',
  'Surgical Benefits - Professional (Physician)': '[CC]',
  'Third Surgical Opinion': '[21]',
  'Transitional Care': '[TC]',
  'Transitional Nursery Care': '[TN]',
  Transplants: '[70]',
  'Urgent Care': '[UC]',
  'Used Durable Medical Equipment': '[11]',
  'Vision (Optometry)': '[AL]',
  'Well Baby Care': '[68]',
}
