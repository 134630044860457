import React from 'react'

import { getPermission } from '../utils/functions'
import useStore from '../modules/store'

export const usePermissions = ({ permission, featureFlag, dev, marketing }: any) => {
  const [allowed, setAllowed] = React.useState(true)

  // store variables
  const user = useStore((state: any) => state.user)
  const tenant = useStore((state: any) => state.tenant)

  // get user permissions & flags
  const userPermissions = useStore((state: any) => state.permissions)
  const userFeatureFlags = tenant?.feature_flags

  React.useEffect(() => {
    const isAllowed = getPermission({
      dev,
      featureFlag,
      marketing,
      permission,
      tenant,
      user,
      userFeatureFlags,
      userPermissions,
    })

    setAllowed(isAllowed)
  }, [permission, featureFlag, dev, marketing])

  return { allowed }
}

export default usePermissions
