import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import { arrayToMapWithKey } from '../../../utils/functions'
import { COLORS } from '../../../theme'
import { useFormField } from '../../Forms/hooks/useFormField'
import { useStore } from '../utils/useStore'
import { withFormContext } from '../../Forms/context'

import { PopoverMenu, CustomPopoverMenuItem } from '../../../components/PopoverMenu'
import Glyph from '../../../components/Glyph'

export const RootMultiSelectCell = (props: any) => {
  const { canEdit, column, isEditing, model, onBlur, setValue, validations, value } = props

  const config = column?.config
  const options = config?.options || []

  const [isOpen, setIsOpen] = React.useState(isEditing)
  const [selected, setSelected]: any = React.useState([])

  const optionsByModel = React.useMemo(() => {
    return arrayToMapWithKey(options, 'model')
  }, [options])

  const handleClose = () => {
    if (!selected || !optionsByModel) return

    const newValue: any = {}

    for (const option of options) {
      newValue[option.model] = selected.includes(option.model)
    }

    setValue(newValue)

    if (onBlur) onBlur()
  }

  const triggerClasses = clsx(
    size(selected) === 0 && 'is-empty',
    isEditing && 'is-editing',
    // isInvalid && showInvalid && 'is-invalid',
  )

  const displayValueClasses = clsx(
    size(selected) === 0 && 'is-empty',
    isEditing && 'is-editing',
    // isInvalid && showInvalid && 'is-invalid',
  )

  if (size(options) === 0) return null

  if (!canEdit) return null

  return (
    <>
      <PopoverMenu
        isOpen={isOpen}
        side={null}
        align={null}
        closeOnItemClick={false}
        trigger={
          <div css={STYLES.trigger} className={triggerClasses}>
            {canEdit && <Glyph glyph="triangle_down" size={9} className="select-trigger-triangle" />}
          </div>
        }
        onOpenUpdated={(currentIsOpen: boolean) => {
          if (!currentIsOpen) handleClose()

          setIsOpen(currentIsOpen)
        }}
      >
        {options.map((option: any) => (
          <SelectItem
            key={option.model}
            color={option.color}
            glyph={option.glyph}
            label={option.label}
            model={model}
            option={option}
            selected={selected}
            setSelected={setSelected}
          />
        ))}
      </PopoverMenu>

      {size(value) > 0 && (
        <div css={STYLES.displayValue} className={displayValueClasses}>
          {Object.keys(value).map((model: any) => {
            const option = optionsByModel[model]

            if (!value[model] || !option) return null

            return (
              <div key={model} css={STYLES.displayValueInner}>
                {option.label}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

const SelectItem = withFormContext((props: any) => {
  const { color: propsColor, form, glyph = 'check', label, model, option, selected, setSelected } = props

  const color = propsColor ? COLORS[propsColor] || propsColor : COLORS.green
  const itemModel = `${model}.${option.model}`

  const { formActions, formState } = useFormField({
    model: itemModel,
    form: form,
    isValid: true,
    skipDeregister: true,
  })

  const updateField: any = useStore((state: any) => state.updateField)

  const handleClick = () => {
    const selectedIndex = selected.indexOf(option.model)
    const isSelected = selectedIndex !== -1

    const newValue = !isSelected

    formActions.setValue(newValue)
    updateField({ model: itemModel, value: newValue })

    setSelected(
      produce((draft: any) => {
        if (isSelected) draft.splice(selectedIndex, 1)
        else draft.push(option.model)
      }),
    )
  }

  const style: any = React.useMemo(() => {
    return { '--highlight-color': tint(0.92, color) }
  }, [color])

  return (
    <CustomPopoverMenuItem style={style} css={STYLES.selectItem} isActive={selected.includes(option.model)} onClick={handleClick}>
      <div className="select-item-graphic">
        <Glyph glyph={glyph} size={10} color={color} />
      </div>

      <div>{label}</div>
    </CustomPopoverMenuItem>
  )
})

const STYLES = {
  trigger: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
    padding: '0.2rem',
    paddingLeft: '0.4rem',
    paddingRight: '1.2rem',
    overflow: 'hidden',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    '.select-trigger-label': {
      fontWeight: 500,
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '.select-trigger-glyph': {
      marginRight: '0.5rem',
      pointerEvents: 'none',
    },

    '.select-trigger-triangle': {
      position: 'absolute',
      right: '0.25rem',
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },

    '&.is-empty': {
      fontStyle: 'italic',
      color: COLORS.textStronglyMuted,
    },

    '&.is-editing': {
      borderRadius: 3,
      background: COLORS.white,
      boxShadow: 'var(--input-focus-box-shadow)',
    },

    '&.is-invalid': {
      display: 'block',
      background: tint(0.85, COLORS.red),
    },
  },

  selectItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    fontSize: '0.88rem',
    width: '100%',
    lineHeight: 'normal',
    paddingTop: '0.4rem',
    paddingBottom: '0.4rem',
    paddingRight: '1rem',
    fontWeight: 600,

    '&:hover': {
      fontWeight: 600,
      background: COLORS.hover,
    },

    '.select-item-graphic': {
      opacity: 0,
      visibility: 'hidden',
      width: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '&.is-active': {
      backgroundColor: 'var(--highlight-color)',

      '&:hover': {
        backgroundColor: 'var(--highlight-color)',
      },

      '.select-item-graphic': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },

  displayValue: {
    position: 'relative',
    zIndex: 1,
    pointerEvents: 'none',

    '.wrapping-wrap &': {
      display: 'grid',
      gridGap: '0.25rem',
    },

    '.wrapping-clip &': {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginRight: '1rem',

      '& > *': {
        marginLeft: '0.55rem',
      },
    },
  },

  displayValueInner: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },

  displayValueGraphic: {
    marginRight: '0.4rem',
  },
}

export const MultiSelectCell: any = withFormContext(RootMultiSelectCell)
