import React from 'react'

import { AdmissionsIntakeFields } from './SteppedOnboardingForm/pages/AdmissionsIntake'
import { BusinessDescriptionFields } from './SteppedOnboardingForm/pages/BusinessDescription'
import { ClientsFields } from './SteppedOnboardingForm/pages/Clients'
import { CommunicationsOperationsFields } from './SteppedOnboardingForm/pages/CommunicationsOperations'
import { DigitalMarketingFields } from './SteppedOnboardingForm/pages/DigitalMarketing'
import { ElectronicHealthRecordsFeaturesFields } from './SteppedOnboardingForm/pages/ElectronicHealthRecordsFeatures'
import { FinancialDetailsFields } from './SteppedOnboardingForm/pages/FinancialDetails'
import { GeneralInformationFields } from './SteppedOnboardingForm/pages/GeneralInformation'
import { HealthcareFacilityDetailsFields } from './SteppedOnboardingForm/pages/HealthcareFacilityDetails'
import { HealthcarePracticeDetailsFields } from './SteppedOnboardingForm/pages/HealthcarePracticeDetails'
import { LocationsFields } from './SteppedOnboardingForm/pages/Locations'
import { NPIFields } from './SteppedOnboardingForm/pages/NPI'
import { RecoveryAccountabilityFields } from './SteppedOnboardingForm/pages/RecoveryAccountability'
import { RevenueCycleManagementFields } from './SteppedOnboardingForm/pages/RevenueCycleManagement'
import { ServiceCodesFields } from './SteppedOnboardingForm/pages/ServiceCodes'
import { StaffFields } from './SteppedOnboardingForm/pages/Staff'

import Divider from './Divider'

const HEALTHCARE_FACILITIES = ['healthcare_facility', 'healthcare_practice']
const SUPPORT_FACILITIES = ['support_facility', 'support_practice']

const OnboardingForm = ({ accountType }: any) => {
  const isHealthcare = HEALTHCARE_FACILITIES.includes(accountType)
  const isSupport = SUPPORT_FACILITIES.includes(accountType)

  const isHealthcareOrSupport = isHealthcare || isSupport
  const isHealthcareFacility = accountType === 'healthcare_facility'
  const isHealthcarePractice = accountType === 'healthcare_practice'

  return (
    <div>
      <BusinessDescriptionFields />

      <Divider />
      <GeneralInformationFields isHealthcareFacility={isHealthcareFacility} isHealthcare={isHealthcare} />

      <Divider />
      <LocationsFields />

      {isHealthcareOrSupport && (
        <>
          <Divider />
          <ClientsFields isHealthcare={isHealthcare} />

          <Divider />
          <StaffFields isHealthcare={isHealthcare} />
        </>
      )}

      {isHealthcareFacility && (
        <>
          <Divider />
          <HealthcareFacilityDetailsFields />
        </>
      )}

      {isHealthcarePractice && (
        <>
          <Divider />
          <HealthcarePracticeDetailsFields />
        </>
      )}

      {isHealthcare && (
        <>
          <Divider />
          <NPIFields />

          <Divider />
          <ServiceCodesFields />

          <Divider />
          <ElectronicHealthRecordsFeaturesFields />
        </>
      )}

      <Divider />
      <AdmissionsIntakeFields />

      <Divider />
      <RecoveryAccountabilityFields />

      <Divider />
      <CommunicationsOperationsFields />

      {isHealthcare && (
        <>
          <Divider />
          <RevenueCycleManagementFields />
        </>
      )}

      <Divider />
      <FinancialDetailsFields isHealthcare={isHealthcare} />

      <Divider />
      <DigitalMarketingFields />
    </div>
  )
}

export default OnboardingForm
