import React from 'react'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

import { FILTERS } from '../../../../constructs/Filters/config'

export const OrganizationsTable = ({ onSelect, dependentValue, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-organizations'],
    endpoint: '/organizations',
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_organizations_all_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            onClick={onSelect ? () => onSelect(data) : undefined}
          />
        ),
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        tagSmartCategories: 'organizations',
      },
      {
        title: 'Type',
        model: 'category',
        type: 'title',
        width: 140,
      },
      {
        title: 'Category',
        id: 'category_type',
        model: 'subcategory.name',
        type: 'title',
        width: 140,
      },
      {
        title: 'Phone',
        model: 'phone_no',
        type: 'phone',
      },
      {
        title: 'Email',
        model: 'email',
        type: 'email',
      },
      {
        title: 'Website',
        model: 'website',
        type: 'external_url',
        disableSelect: true,
      },
      {
        title: 'Address',
        model: 'address',
        type: 'address',
        disableSort: true,
      },
    ],
    [],
  )

  return (
    <DataTable
      {...tableProps}
      {...rest}
      icon="organizations"
      title="Organizations"
      columns={columns}
      filtersConfig={FILTERS.organizations}
      searchBarFilterKey="name"
    />
  )
}
