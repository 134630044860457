import React from 'react'
import get from 'lodash/get'

import { withFormContext } from '../../../../Forms/context'

import Button from '../../../../Button'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import DateInput from '../../../../Forms/DateInput'
import Flex from '../../../../Flex'
import FormSection from '../../../../Forms/FormSection'
import Option from '../../../../Forms/Option'
import Section from '../../../../Section'
import Select from '../../../../Forms/Select'
import State from '../../../../State'
import SmartTextarea from '../../../../Forms/SmartTextarea'

import { COLORS } from '../../../../../theme'

class GoalsAndObjectives extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      goals: get(props.form.getInitialModel(), `${props.model}.goals`, []),
    }
  }

  /*
    CUSTOM
  */
  addGoal = () => {
    let goals = this.state.goals

    goals.push({
      status: 'inactive',
      description: '',
      objectives: [],
    })

    this.setState({ goals: goals })
  }

  addObjective = (goal_id) => {
    let goals = this.state.goals

    goals[goal_id].objectives.push({
      status: 'inactive',
      description: '',
      interventions: [],
    })

    this.setState({ goals: goals })
  }

  addIntervention = (goal_id, objective_id) => {
    let goals = this.state.goals

    if (!goals[goal_id].objectives[objective_id].interventions) {
      goals[goal_id].objectives[objective_id].interventions = []
    }

    goals[goal_id].objectives[objective_id].interventions.push({
      description: '',
    })

    this.setState({ goals: goals })
  }

  removeGoal = (id) => {
    let goals = this.state.goals
    goals.splice(id, 1)
    this.setState({ goals: goals })
  }

  removeObjective = (goal_id, id) => {
    let goals = this.state.goals
    goals[goal_id].objectives.splice(id, 1)
    this.setState({ goals: goals })
  }

  removeIntervention = (goal_id, objective_id, id) => {
    let goals = this.state.goals
    goals[goal_id].objectives[objective_id].interventions.splice(id, 1)
    this.setState({ goals: goals })
  }

  renderTrigger = () => {
    return this.props.isEditable && <Button type="default" glyph="add" label="Add Goal" onClick={this.addGoal} display="inline-flex" />
  }

  /*
    RENDER
  */
  render() {
    const { goals } = this.state
    const { model, isEditable } = this.props

    const isEmpty = goals.length === 0

    return (
      <State isEmpty={isEmpty} emptyDescription="No data found" emptyActions={this.renderTrigger()}>
        {goals.map((_goal, idx) => (
          <Section
            key={idx}
            css={styles}
            title={`Goal #${idx + 1}`}
            aside={
              isEditable && (
                <Button label="Remove" glyph="delete" type="minimal" color="red" size="small" onClick={() => this.removeGoal(idx)} />
              )
            }
          >
            <FormSection labelWidth="120px" maxWidth="100%">
              <Select allowEmpty label="Status" model={`${model}.goals[${idx}].status`}>
                <Option label="Inactive" value="inactive" />
                <Option label="In Progress" value="in_progress" />
                <Option label="Completed" value="completed" />
                <Option label="Extended" value="extended" />
                <Option label="Cancelled" value="cancelled" />
                <Option label="Unmet" value="unmet" />
              </Select>

              <SmartTextarea useDictation model={`${model}.goals[${idx}].description`} label="Description" />

              {goals[idx]?.objectives?.map((_objective, jdx) => (
                <Section
                  key={jdx}
                  css={{ ...styles, margin: 0, marginLeft: 20, padding: '1rem' }}
                  title={`Objective #${idx + 1}.${jdx + 1}`}
                  aside={
                    isEditable && (
                      <Button
                        label="Remove"
                        glyph="delete"
                        type="minimal"
                        color="red"
                        size="small"
                        onClick={() => this.removeObjective(idx, jdx)}
                      />
                    )
                  }
                >
                  <FormSection labelWidth="120px">
                    <Flex gap={8} stretchChildrenX>
                      <Select allowEmpty label="Status" model={`${model}.goals[${idx}].objectives[${jdx}].status`}>
                        <Option label="In Progress" value="in_progress" />
                        <Option label="Extended" value="extended" />
                        <Option label="Cancelled" value="cancelled" />
                        <Option label="Completed" value="completed" />
                        <Option label="Unmet" value="unmet" />
                      </Select>

                      <CheckboxGroup label="EBT" layout="horizontal" trueIcon="check" falseStyle="faded" falseIcon="cross">
                        <Checkbox label="Evidence Based Treatment (EBT)" model={`${model}.goals[${idx}].objectives[${jdx}].ebt`} />
                      </CheckboxGroup>
                    </Flex>

                    <Flex gap={8}>
                      <DateInput label="Start Date" model={`${model}.goals[${idx}].objectives[${jdx}].started_at`} />
                      <DateInput label="Target Date" model={`${model}.goals[${idx}].objectives[${jdx}].target_at`} />
                    </Flex>

                    <SmartTextarea useDictation model={`${model}.goals[${idx}].objectives[${jdx}].description`} label="Description" />

                    {goals[idx]?.objectives[jdx]?.interventions?.map((_intervention, kdx) => (
                      <Section
                        key={kdx}
                        css={{ ...styles, margin: 0, marginLeft: 20, padding: '1rem' }}
                        title={`Intervention #${idx + 1}.${jdx + 1}.${kdx + 1}`}
                        aside={
                          isEditable && (
                            <Button
                              label="Remove"
                              glyph="delete"
                              type="minimal"
                              color="red"
                              size="small"
                              onClick={() => this.removeIntervention(idx, jdx)}
                            />
                          )
                        }
                      >
                        <FormSection layout="vertical">
                          <SmartTextarea
                            useDictation
                            model={`${model}.goals[${idx}].objectives[${jdx}].interventions[${kdx}].description`}
                            label="Description"
                          />
                        </FormSection>
                      </Section>
                    ))}

                    {isEditable && (
                      <Button
                        type="link"
                        glyph="add"
                        label="Add Intervention"
                        size="small"
                        onClick={() => this.addIntervention(idx, jdx)}
                      />
                    )}
                  </FormSection>
                </Section>
              ))}

              {isEditable && <Button type="link" glyph="add" label="Add Objective" size="small" onClick={() => this.addObjective(idx)} />}
            </FormSection>
          </Section>
        ))}
        {this.renderTrigger()}
      </State>
    )
  }
}

GoalsAndObjectives.defaultProps = {
  isEditable: false,
  model: 'data',
}

const styles = {
  border: `2px solid ${COLORS.divider}`,
  borderRadius: 7,
  padding: '0.75rem',
}

export default withFormContext(GoalsAndObjectives)
