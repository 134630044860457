import React from 'react'

import { COLORS } from '../theme'

type Props = {
  title: string
} & React.HTMLAttributes<any>

const DropdownGroup: React.FC<Props> = ({ title, className, children }) => {
  return (
    <div className={className}>
      <h4 css={styles}>{title}</h4>
      {children}
    </div>
  )
}

const styles = {
  textTransform: 'uppercase',
  letterSpacing: 1,
  fontSize: '0.95rem',
  color: COLORS.textMuted,
  padding: '0.75rem 1rem 0.25rem',
}

export default DropdownGroup
