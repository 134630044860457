import React from 'react'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { BedsBoard } from '@behavehealth/constructs/BedManagement/BedsBoard'

const RootBedsBoardPage = () => {
  const [date, setDate]: any = React.useState(null)

  const queryParams = { date: date }

  const { data, isLoading }: any = useGet({
    name: ['bed-management-board', queryParams],
    url: `/bed_management/board`,
    params: { ...queryParams },
    options: { enabled: !!date },
  })

  return (
    <div css={STYLES.root}>
      <BedsBoard setDate={setDate} isLoading={isLoading || !data?.houses} houses={data?.houses} />
    </div>
  )
}

const STYLES: any = {
  root: {
    display: 'grid',
    padding: '1rem',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    overflow: 'hidden',
  },
}

export const BedsBoardPage = withPageError(RootBedsBoardPage)
