import React from 'react'

import { titleCase, address } from '../../../utils/functions'

import Form from '../../../components/Forms/Form'
import MultiObjectSelector from '../../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Grid from '../../../components/Grid'
import Flex from '../../../components/Flex'
import Input from '../../../components/Forms/Input'
import Overlay from '../../../components/Overlay'
import Section from '../../../components/Section'

import { withOverlayError } from '../../../hocs/withOverlayError'

import FeeScheduleTable from './FeeScheduleTable'

const RootPreviewFeeScheduleOverlay = ({ feeSchedule, onClose }: any) => (
  <Overlay showBackdrop onClose={onClose} position="center" maxWidth={90}>
    <Overlay.Header icon="insurance" title="Fee Schedule" />

    <Overlay.Content>
      <Form isEditable={false} initialModel={feeSchedule}>
        <Section>
          <Grid gap="1rem" maxWidth="100%">
            <Input
              label="Name"
              model="name"
              validations={{
                presence: {
                  message: 'Please enter a fee schedule name',
                },
              }}
            />

            <Flex stretchChildrenX gap="1rem">
              <MultiObjectSelector
                label="Insurance Payers"
                model="insurance_local_payers"
                icon="insurance"
                type="insurance_local_payers"
                selectTitle={(data: any) => data?.name}
                selectDescription={(data: any) => data?.notes}
                validations={{
                  presence: {
                    message: 'Please select some Insurance Payers',
                  },
                }}
              />

              <MultiObjectSelector
                label="Healthcare Locations"
                model="houses"
                icon="properties"
                type="insurance.locations"
                selectTitle={(data: any) => data?.name}
                selectDescription={(data: any) => address(data?.address)}
              />

              <MultiObjectSelector
                label="Healthcare Providers"
                model="employees"
                icon="employees"
                type="insurance.providers"
                selectTitle={(data: any) => data?.name}
                selectDescription={(data) => titleCase(data.position)}
              />
            </Flex>

            <FeeScheduleTable model="insurance_fee_schedule_services" />
          </Grid>
        </Section>
      </Form>
    </Overlay.Content>
  </Overlay>
)

export const PreviewFeeScheduleOverlay = withOverlayError(RootPreviewFeeScheduleOverlay)
