import React from 'react'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { isBlank } from '../../utils/functions'

import { Input } from './Input'
import { withFormContext } from './context'
import LinkValue from '../LinkValue'

class URLInput extends Input {
  readOnlyRender = () => {
    if (isBlank(this.state.value)) return DEFAULT_EMPTY_VALUE

    return <LinkValue url={this.state.value} value={this.state.value} glyph={this.props.glyph} className={this.props.className} />
  }
}

URLInput.defaultProps = {
  glyph: 'link',
  validateOn: 'blur-change',
  quickLinksVendors: ['hubspot', 'google', 'gmail', 'google_calendar', 'helpscout'],
}

export default withFormContext(URLInput)
