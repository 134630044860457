import React from 'react'
import { v4 as uuid } from 'uuid'
import { useParams, useHistory } from 'react-router-dom'
import produce from 'immer'
import size from 'lodash/size'

import AccordionCard from '../../components/AccordionCard'
import Alert from '../../components/Alert'
import AmountInput from '../../components/Forms/AmountInput'
// import Attachments from '../../components/Forms/Attachments'
import State from '../../components/State'
import Button from '../../components/Button'
import Card from '../../components/Card'
import DateInput from '../../components/Forms/DateInput'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import GridTable from '../../components/GridTable'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Status from '../../components/Status'
import Select from '../../components/Forms/Select'
import ClaimInternalStatus from '../../components/Statuses/ClaimInternalStatus'
import DataArray from '../../components/Forms/DataArray'

import { withOverlayError } from '../../hocs/withOverlayError'
import { isDefined, mapToArray, usDate, titleCase, niceAmount } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'

import { COLORS } from '../../theme'

import { useFormField } from '../../components/Forms/hooks/useFormField'

const PaymentLine = ({ id, claim, remove, ...rest }: any) => {
  const [totalAmount, setTotalAmount] = React.useState(0)
  const [allowedAmount, setAllowedAmount] = React.useState(0)
  const [adjustmentAmount, setAdjustmentAmount] = React.useState(0)

  React.useEffect(() => {
    setAdjustmentAmount(totalAmount - allowedAmount)
  }, [allowedAmount])

  return (
    <GridTable.Row {...rest}>
      {/* Service */}
      <GridTable.Cell>
        <ObjectSelector
          label=""
          icon="financials"
          type="insurance.claim_service_lines"
          dependentValue={claim?.id}
          selectTitle={(data: any) =>
            `${data.insurance_code?.code} (${usDate(data.service_date_start)} to ${usDate(data.service_date_end)})`
          }
          selectDescription={(data: any) => (
            <Grid gap="0.25rem">
              <span>{data.insurance_code?.name}</span>
              <span>{niceAmount(data.total_amount)}</span>
            </Grid>
          )}
          validations={{
            presence: {
              message: 'Please select a service',
            },
          }}
          model={`${id}.service`}
          onUpdate={({ value }: any) => {
            if (!value) return
            setTotalAmount(value.total_amount || 0)
          }}
        />
      </GridTable.Cell>

      {/* Billed */}
      <GridTable.Cell css={{ lineHeight: '2.5rem' }}>{niceAmount(totalAmount)}</GridTable.Cell>

      {/* Allowed */}
      <GridTable.Cell>
        <AmountInput model={`${id}.allowed_amount`} onUpdate={({ value }: any) => setAllowedAmount(value)} />
      </GridTable.Cell>

      {/* Insurance Paid */}
      <GridTable.Cell>
        <AmountInput model={`${id}.paid_amount`} />
      </GridTable.Cell>

      {/* Deductible */}
      <GridTable.Cell>
        <AmountInput model={`${id}.deductible_amount`} />
      </GridTable.Cell>

      {/* Co-Pay */}
      <GridTable.Cell>
        <AmountInput model={`${id}.copay_amount`} />
      </GridTable.Cell>

      {/* Co-Insurance */}
      <GridTable.Cell>
        <AmountInput model={`${id}.coinsurance_amount`} />
      </GridTable.Cell>

      {/* Remark Codes */}
      <GridTable.Cell>
        <Input model={`${id}.remark_codes`} />
      </GridTable.Cell>

      {/* Adjustment */}
      <GridTable.Cell>
        <AmountInput model={`${id}.adjustment_amount`} value={adjustmentAmount} onUpdate={({ value }: any) => setAdjustmentAmount(value)} />
      </GridTable.Cell>

      {/* Remaining */}
      <GridTable.Cell>
        <AmountInput model={`${id}.remaining_amount`} />
      </GridTable.Cell>

      {/* Remove */}
      <GridTable.Cell>
        <Button hideLabel glyph="delete" type="minimal" color="red" onClick={() => remove(id)} />
      </GridTable.Cell>
    </GridTable.Row>
  )
}

const ClaimLine = ({ identifier, clientID }: any) => {
  const [payments, setPayments]: any = React.useState([])
  const [claim, setClaim]: any = React.useState(null)

  return (
    <FormSection maxWidth="100%">
      <Flex gap="1rem">
        <ObjectSelector
          className="!grow-0 !shrink !basis-[350px]"
          label="Claim"
          icon="insurance"
          type="insurance.claims"
          model={`${identifier}.insurance_claim`}
          dependentValue={clientID}
          selectTitle={(data: any) => `${data.control_number} (${niceAmount(data?.total_amount)})`}
          selectDescription={(data: any) => (
            <>
              <Flex gap="0.5rem">
                <ClaimInternalStatus status={data.status} />
                <Status color="gray" label={titleCase(data.category)} />
              </Flex>
              {`${usDate(data.covering_from)} to ${usDate(data.covering_until)}`}
            </>
          )}
          validations={{
            presence: {
              message: 'Please select a claim',
            },
          }}
          onUpdate={({ value }: any) => {
            if (value) {
              const newPayments = value?.insurance_claim_service_lines?.map((line: any) => ({
                service: line,
                service_id: line.id,
              }))

              setPayments(newPayments)
              setClaim(value)
            } else {
              setPayments([])
              setClaim(null)
            }
          }}
        />

        <DateInput defaultToNow label="Payment Date" model={`${identifier}.dated_at`} />

        <Select label="Payment Method" defaultValue="check" model={`${identifier}.payment_method`}>
          <Option label="Check" value="check" />
          <Option label="Bank Transfer (ACH)" value="ach" />
          <Option label="Other" value="other" />
        </Select>

        <Input label="Reference" model={`${identifier}.reference`} />
      </Flex>

      {isDefined(claim) ? (
        <>
          <Card>
            <GridTable templateColumns="400px 100px 170px 170px 170px 170px 170px 170px 170px 170px 1fr">
              <GridTable.Header css={{ gridTemplateColumns: '840px 510px 1fr', textTransform: 'uppercase' }}>
                <GridTable.Cell></GridTable.Cell>
                <GridTable.Cell>Patient Responsibility</GridTable.Cell>
                <GridTable.Cell></GridTable.Cell>
              </GridTable.Header>

              <GridTable.Header>
                <GridTable.Cell>Service *</GridTable.Cell>
                <GridTable.Cell>Billed</GridTable.Cell>
                <GridTable.Cell>Allowed Amount</GridTable.Cell>
                <GridTable.Cell>Insurance Paid</GridTable.Cell>

                <GridTable.Cell>Deductible</GridTable.Cell>
                <GridTable.Cell>Co-Pay</GridTable.Cell>
                <GridTable.Cell>Co-Insurance</GridTable.Cell>

                <GridTable.Cell>Remark Codes</GridTable.Cell>
                <GridTable.Cell>Adjustment</GridTable.Cell>

                <GridTable.Cell>Balance Due Client</GridTable.Cell>
                <GridTable.Cell></GridTable.Cell>
              </GridTable.Header>

              <DataArray
                value={payments}
                model={`${identifier}.payments`}
                onUpdate={(state: any) => {
                  setPayments(state.value)
                }}
              >
                {({ orderedIds, add, remove }: any) => {
                  return (
                    <>
                      {orderedIds?.map((id: string) => {
                        return <PaymentLine key={id} id={id} remove={remove} claim={claim} />
                      })}

                      <div>
                        <Button label="Add New Service" type="minimal" display="inline-flex" glyph="add" size={200} onClick={add} />
                      </div>
                    </>
                  )
                }}
              </DataArray>
            </GridTable>
          </Card>
        </>
      ) : (
        <Alert>Please select a Claim above before posting Insurance Payments</Alert>
      )}
    </FormSection>
  )
}

const ClaimLines = ({ form, clientID, model }: any) => {
  const [data, setData]: any = React.useState({})
  const [validData, setValidData]: any = React.useState({})
  const [isValid, setIsValid]: any = React.useState(false)

  const { formActions } = useFormField({
    model: model,
    form: form,
  })

  const add = () => {
    const newID = uuid()

    const lastLine = data[Object.keys(data).pop()]

    setData((o: any) => ({
      ...o,
      [newID]: {
        _id: newID,
        ...(lastLine && {
          dated_at: lastLine.dated_at,
          payment_method: lastLine.payment_method,
          reference: lastLine.reference,
        }),
      },
    }))

    setValidData((o: any) => ({ ...o, [newID]: false }))
  }

  React.useEffect(() => {
    if (!form) return
    if (!data) return

    formActions.setValue(mapToArray(data))
  }, [data])

  React.useEffect(() => {
    let valid = true
    const validValues = Object.values(validData)
    const validValuesLength = validValues.length
    if (validValuesLength === 0) {
      setIsValid(false)
      return
    }

    for (let i = 0; i < validValuesLength; i++) {
      if (validValues[i] === true) continue

      valid = false
      break
    }

    setIsValid(valid)
  }, [validData])

  React.useEffect(() => {
    if (!form) return

    formActions.setValidity(isValid)
  }, [isValid])

  return (
    <DataArray startWithEmptyRecord model={model}>
      {({ orderedIds, elements, add, remove }: any) => {
        const elementSize = size(orderedIds)
        const isEmpty = elementSize === 0

        const actions = <Button type="default" glyph="add" label="Add Claim" onClick={add} display="inline-flex" size={200} />

        if (isEmpty) {
          return (
            <Section>
              <State isEmpty emptyDescription="No Claims added yet" emptyActions={actions} />
            </Section>
          )
        }

        return (
          <>
            {orderedIds?.map((id: string, idx: number) => {
              const item = elements[id]
              const claim = item.insurance_claim
              let title = `Claim #${idx + 1}`

              if (claim) title += ` (${claim.control_number})`

              return (
                <Section key={id} className="!p-0">
                  <AccordionCard
                    isOpen={idx === 0}
                    title={title}
                    description={
                      claim && `${niceAmount(claim?.total_amount)}, ${usDate(claim?.covering_from)} to ${usDate(claim?.covering_until)}`
                    }
                    css={{ background: COLORS.lightBackground }}
                    aside={
                      <Button
                        type="minimal"
                        glyph="delete"
                        color="red"
                        label="Remove"
                        onClick={(event: any) => {
                          event.preventDefault()
                          remove(id)
                        }}
                        display="inline-flex"
                        size={200}
                      />
                    }
                  >
                    <ClaimLine identifier={id} clientID={clientID} />
                  </AccordionCard>
                </Section>
              )
            })}

            {actions}
          </>
        )
      }}
    </DataArray>
  )
}

const RootClaimPaymentsBuilderOverlay = () => {
  const history = useHistory()
  const { timezone } = useSettings()

  const { resource_id } = useParams()

  const form = React.useRef()
  const [valid, setValid] = React.useState()

  const { mutateAsync: createPayments, isLoading }: any = useCreate({
    name: ['client', resource_id, 'insurance-payments'],
    url: `/insurance_payments/builder`,
    invalidate: ['insurance-payments'],
  })

  const onSave = async () => {
    try {
      await createPayments(form.current.getFormValue())
      history.goBack()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={130}>
      <Overlay.Header icon="financials" title="Claim Payments Builder" />

      <Overlay.Content>
        <Form useLocalModel isEditable getForm={form} timezone={timezone} onValidationUpdate={setValid}>
          <Section>
            <ClaimLines clientID={resource_id} model="claim_payments" />
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Create Multi-Payments"
          glyph="check"
          type="primary"
          color="green"
          flex="100 1 auto"
          onClick={onSave}
          isLoading={isLoading}
          isDisabled={!valid}
          permission="todos.edit"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClaimPaymentsBuilderOverlay = withOverlayError(RootClaimPaymentsBuilderOverlay)
