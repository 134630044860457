import React from 'react'

import { niceAmount } from '../../../utils/functions'
import { Form, Input, Workflow, FormSection, DataList, RadioGroup, Radio } from '../../'

import { request } from '../../../modules/axios'
import { WorkflowContext } from '../../Workflow/context'

const DEFAULT_AMOUNT = 150

const AmountStep = (props: any) => {
  const { currentBalance, selectedAmount, passFees, onUpdate } = props
  const context = React.useContext(WorkflowContext)

  const form = React.useRef(null)

  const [valid, setValid] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [fees, setFees] = React.useState({})
  const [amount, setAmount] = React.useState(0)
  const [paymentMethod, setPaymentMethod] = React.useState('card')

  React.useEffect(() => {
    const getLatestFees = async () => {
      try {
        setLoading(true)
        const fees = await request.get(`/apps/ledger/fees/${amount * 100}?payment_method=${paymentMethod}`)
        setFees(fees.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (amount >= 5) getLatestFees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, paymentMethod])

  React.useEffect(() => {
    if (onUpdate) onUpdate(amount)
  }, [fees])

  const balance = parseFloat(currentBalance)
  const feeToPay = selectedAmount || (balance < 0 ? Math.abs(balance) : DEFAULT_AMOUNT)
  const finalBalance = balance + fees.charge
  const isFinalBalancePositive = finalBalance > 0

  return (
    <Form getForm={form} onValidationUpdate={setValid}>
      <FormSection>
        <Input
          autoFocus
          isEditable
          size={7}
          debounce={200}
          prefix="$"
          type="number"
          model="amount"
          placeholder="0.00"
          label="How much would you like to pay?"
          defaultValue={feeToPay}
          onUpdate={(state: any) => setAmount(state.value || 0)}
          validations={{
            presence: {
              message: 'Please enter an amount',
            },
            greaterThanOrEqualTo: {
              value: 5,
              message: 'The amount must be higher than $5',
            },
          }}
        />

        <RadioGroup
          layout="horizontal-dense"
          label="Paying With"
          model="payment_method"
          defaultValue="card"
          onUpdate={(state: any) => setPaymentMethod(state.value)}
        >
          <Radio label="Card Payment" value="card" />
          <Radio label="ACH" value="ach" />
        </RadioGroup>

        <DataList labelWidth={200}>
          <DataList.Item label="Charge Amount" value={niceAmount(amount)} />
          <DataList.Item label="Current Balance" value={niceAmount(currentBalance)} />
          {passFees && (
            <>
              {paymentMethod === 'card' && (
                <DataList.Item label="Card Processing Fees" value={niceAmount(fees?.stripe_fee) + ' (2.9% + ¢30)'} />
              )}
              {paymentMethod === 'ach' && (
                <DataList.Item label="ACH Processing Fees" value={niceAmount(fees?.stripe_fee) + ' (0.8%, max $5)'} />
              )}
            </>
          )}
          <DataList.Item label="Total Payment" value={niceAmount(fees?.total)} />
          <DataList.Item
            label="Final Balance"
            value={
              <>
                {isFinalBalancePositive && '+'}
                {niceAmount(finalBalance)}
              </>
            }
          />
        </DataList>

        <Workflow.ContinueButton
          label={`Continue (${niceAmount(fees.total)})`}
          isLoading={loading}
          isDisabled={!valid}
          onClick={() => {
            context.goNext()
          }}
        />
      </FormSection>
    </Form>
  )
}

export default AmountStep
