import React from 'react'

import { useUpdate } from '../../../hooks/useNewAPI'
import { withOverlayError } from '../../../hocs/withOverlayError'

import EmployeeNavItems from '../../Navs/EmployeeNavItems'
import QuickViewOverlay from './QuickViewOverlay'
import Status from '../../Status'
import TagsSelector from '../../TagsSelector'

const EmployeeQuickView = ({ employee, onClose }: any) => {
  const { mutateAsync: updateEmployee }: any = useUpdate({
    name: ['employee', employee?.id],
    url: `/employees/${employee?.id}`,
    invalidate: ['employees'],
    invalidateKeys: ['employee'],
  })

  return (
    <QuickViewOverlay
      title={employee.name}
      avatar={employee.avatar}
      onClose={onClose}
      data={employee}
      subheader={<Status label={employee?.position?.replace('_', ' ')} />}
      headerAfter={
        <div className="!mt-3 w-full">
          <TagsSelector tags={employee?.tags} onSave={(tagIDs: any) => updateEmployee({ tag_ids: tagIDs })} smartCategories="employees" />
        </div>
      }
    >
      <EmployeeNavItems employeeID={employee.id} />
    </QuickViewOverlay>
  )
}

export default withOverlayError(EmployeeQuickView)
