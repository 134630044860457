import React from 'react'

import Alert from '../../../components/Alert'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Link from '../../../components/Link'
import Workflow from '../../../components/Workflow/Workflow'

const RootAccounting = ({ data, setData, setStatus, timezone }: any) => {
  const form = React.useRef()
  const [valid, setValid] = React.useState()

  const { goNext }: any = React.useContext(Workflow.Context)

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  return (
    <Form useLocalModel getForm={form} initialModel={data} onUpdate={setData} timezone={timezone} defaultDebounce={300}>
      <FormSection>
        <Alert glyph="info" type="neutral">
          <div>
            Visit <Link to={'/settings/accounting'}>https://app.behavehealth.com/settings/accounting</Link> to decide what information is
            sent to accounting system.
          </div>
        </Alert>

        <Card>
          <CardContent className="!p-0">
            <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
              <Checkbox label="Sync with Accounting Platform" description="" model="sync_with_accounting" />
            </CheckboxGroup>
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export const Accounting = RootAccounting
