import React from 'react'

import Empty from './Empty'
import Truncated from './Truncated'

const MarkupCell: React.FC<any> = ({ value, className }) => {
  if (!value) return <Empty />

  const plainText = value.replace(/<[^>]*>/g, ' ')

  return <Truncated value={plainText} className={className} />
}

export default MarkupCell
