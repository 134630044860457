import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { invalidateQueries } from '../../hooks/useNewAPI'

import Alert from '../../components/Alert'
import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'

import SubscriptionStatus from './SubscriptionStatus'
import { ProductOverlay } from '../InternalBilling/ProductOverlay'
import { DiscountOverlay } from '../InternalBilling/DiscountOverlay'
import { niceAmount, nicePercentage } from '../../utils/functions'

const getDiscountText = (discount: any) => {
  if (!discount) return ''

  let info: any = discount.name

  const { discount_type, percent_off, amount_off } = discount

  if (discount_type === 'percentage') {
    info += ` (${nicePercentage(percent_off)}% off)`
  } else if (discount_type === 'fixed') {
    info += ` (${niceAmount(amount_off)} off)`
  }

  return info
}

export const SubscriptionOverlay = (props: any) => {
  const {
    data,
    cancel,
    close,
    edit,
    form,
    initialModel,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'subscriptions',
    endpoint: '/subscriptions',
    options: props,
    invalidateKeys: ['subscriptions'],
  })

  const { timezone } = useSettings()

  const [product, setProduct] = React.useState(null)
  const [discount, setDiscount] = React.useState(null)

  const save = async () => {
    const formData = form.current?.getFormValue()

    await saveWithData({
      ...formData,
      product_id: product?.id,
      discount_id: discount?.id || null,
    })
  }

  React.useEffect(() => {
    if (!data?.id) return

    setProduct(data.product)
    setDiscount(data.discount || null)
  }, [data?.updated_at])

  if (isOverlayLoading) return <OverlayLoader position="right" maxWidth={34} />

  return (
    <Overlay maxWidth={34} showBackdrop={isEditable} onClose={close}>
      <Overlay.Header
        icon="certificate"
        title="Subscription"
        subtitle={
          <Flex gap="0.5rem">
            <Status label={data.product.category.replace('_', ' ')} color="blue" />
            <SubscriptionStatus status={data.status} />
          </Flex>
        }
      />

      <Overlay.Content>
        <Section>
          <Form
            getForm={form}
            onValidationUpdate={onValidationUpdate}
            initialModel={initialModel}
            isEditable={isEditable}
            timezone={timezone}
            key={`updated-${data?.updated_at}`}
          >
            <FormSection>
              {!isNew && (
                <Alert small contrast glyph="info">
                  <b>Please note: </b> any updates made to the product or discount will only be reflected in the subscription in the next
                  billing cycle.
                </Alert>
              )}

              <Input isEditable={false} label="Product" value={product?.name} />

              {!isEditable && product?.id && (
                <SummonOverlay
                  className="flex-[0_0_auto]"
                  overlay={
                    <ProductOverlay
                      dataID={product.id}
                      canDelete={false}
                      onSaveSuccessful={(updatedProduct: any) => {
                        invalidateQueries(null, 'subscriptions')
                        setProduct(updatedProduct)
                      }}
                    />
                  }
                >
                  <Button
                    label="View Product"
                    size={100}
                    type="default"
                    glyph="view"
                    display="inline-flex"
                    className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                  />
                </SummonOverlay>
              )}

              <Input isEditable={false} label="Discount" value={getDiscountText(discount)} />

              {!isEditable && (
                <>
                  {discount?.id ? (
                    <SummonOverlay
                      className="flex-[0_0_auto]"
                      overlay={
                        <DiscountOverlay
                          dataID={discount.id}
                          onSaveSuccessful={(updatedDiscount) => {
                            invalidateQueries(null, 'subscriptions')
                            setDiscount(updatedDiscount)
                          }}
                          onDeleteSuccessful={() => {
                            if (isEditable) {
                              setDiscount(null)
                            } else {
                              saveWithData({
                                discount_id: null,
                              })
                            }
                          }}
                        />
                      }
                    >
                      <Button
                        label="View Discount"
                        size={100}
                        type="default"
                        glyph="view"
                        display="inline-flex"
                        className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                      />
                    </SummonOverlay>
                  ) : (
                    <SummonOverlay
                      className="flex-[0_0_auto]"
                      overlay={
                        <DiscountOverlay
                          dataID="new"
                          onSaveSuccessful={(newDiscount) => {
                            if (isEditable) {
                              setDiscount(newDiscount)
                            } else {
                              saveWithData({
                                discount_id: newDiscount.id,
                              })
                            }
                          }}
                        />
                      }
                    >
                      <Button
                        label="Add Discount"
                        size={100}
                        type="default"
                        glyph="add"
                        display="inline-flex"
                        className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                      />
                    </SummonOverlay>
                  )}
                </>
              )}

              <Select allowEmpty label="Status" model="status">
                <Option label="Active" value="active" />
                <Option label="Cancelled" value="canceled" />
                <Option label="Unpaid" value="unpaid" />
              </Select>

              <DateInput
                label="Current Billing Period Start Day"
                model="current_period_start"
                validations={{
                  presence: {
                    message: 'Please enter a Start Day',
                  },
                }}
              />

              <DateInput
                label="Current Billing Period End Day"
                model="current_period_end"
                validations={{
                  presence: {
                    message: 'Please enter an End Day',
                  },
                }}
              />

              <AmountInput isEditable={false} label="Current Billing Period" model="current_period_estimated_cost" />

              <DateTimeInput isEditable={false} label="Subscription Started On" model="started_at" />

              {/* {(data.status === 'trialing' || data.status === 'active' || data.status === 'past_due') && (
                <CheckboxGroup label="Cancellation Policy" trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox label="Cancel at Period End" model="cancel_at_period_end" />
                </CheckboxGroup>
              )} */}

              {data.status !== 'canceled' && (
                <>
                  <DateInput label="Cancel / End Subscription On" model="should_cancel_at" />

                  <Alert small contrast glyph="info">
                    <b>Note: </b> Subscriptions are checked for Cancellation every day at around 12pm PST / 3pm EST
                  </Alert>
                </>
              )}

              {data.status === 'canceled' && <DateTimeInput isEditable={false} label="Subscription Ended On" model="cancelled_at" />}
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
            />

            {!isNew && <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" />
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}
