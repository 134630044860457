import React from 'react'

import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

const columns = (to: Function, mainLinkAs) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'certification',
    Header: 'Certification',
    Cell: ({ row, value }: any) => <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} label={value} />,
  },
  {
    width: 200,
    accessor: 'credential',
    Header: 'Credential',
    Cell: ({ value }: any) => <Status color="blue" label={value} />,
  },
  {
    width: 200,
    accessor: 'level',
    Header: 'Level',
  },
  {
    width: 200,
    accessor: 'organization',
    Header: 'Organization',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  title: string
  to: Function
  batchActionsConfig?: Object
}

const CredentialsTable = (props: Props) => {
  const { data, emptyActions, emptyDescription, icon, isLoading, localStorageKey, batchActionsConfig, title, hiddenColumns, to } = props

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={columns(to, props.mainLinkAs)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      // showSettings={false}
    />
  )
}

CredentialsTable.defaultProps = {
  title: 'Credentials',
  icon: 'staff_credentials',
  emptyDescription: 'No credentials added yet',
  localStorageKey: 'staff_credentials',
}

export default CredentialsTable
