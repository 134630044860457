import React from 'react'

import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const AdmissionsIntakeFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="admissions" />
        <Type as="h2" variant="H3">
          Admissions & Intake
        </Type>
      </Flex>

      <CheckboxGroup withToggle label=" " trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" layout="vertical-dense">
        <Checkbox
          label="Easily capture leads on my website with an embedded contact form"
          model="questionnaire.admissions_intake.embedded_contact_form"
        />
        <Checkbox
          label="Customizable application form to determine client fit for program"
          model="questionnaire.admissions_intake.application_form"
        />
        <Checkbox
          label="Have new clients sign their intake agreements digitally at arrival or before"
          model="questionnaire.admissions_intake.intake_agreements"
        />
        <Checkbox
          label="Empower your team to deliver a consistent admissions process with our workflow"
          model="questionnaire.admissions_intake.admissions_process_workflow"
        />
        <Checkbox
          label="Track your census and capacity in realtime with our digital bed board and program manager"
          model="questionnaire.admissions_intake.digital_bed_board"
        />
      </CheckboxGroup>
    </FormSection>
  )
}

export const AdmissionsIntake: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <AdmissionsIntakeFields />
    </Form>
  )
}
