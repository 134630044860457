import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { Heading } from '../../../Typography'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import ClientProfileHeader from '../../../ClientProfileHeader'
import ContextHide from '../../../Forms/ContextHide'
import ContextShow from '../../../Forms/ContextShow'
import DataFormStatus from '../../../Statuses/DataFormStatus'
import DateInput from '../../../Forms/DateInput'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import FormSection from '../../../Forms/FormSection'
import Grid from '../../../Grid'
import LevelOfCareSelect from '../../../Forms/LevelOfCareSelect'
import Overlay from '../../../Overlay'
import Radio from '../../../Forms/Radio'
import RadioGroup from '../../../Forms/RadioGroup'
import Section from '../../../Section'
import Switch from '../../../Forms/Switch'
import Textarea from '../../../Forms/Textarea'

import Chotomate from '../../../Chotomate'
import Medications from '../../../SmartContent/Medications'

import { daysBetween, countWord } from '../../../../utils/functions'

import SNAP from './common/SNAP'
import { DataFormsBase, mapDispatchToProps, mapStateToProps } from './DataFormsBase'
import { withOverlayError } from '../../../../hocs/withOverlayError'

class DischargeSummary extends DataFormsBase {
  renderHeader = () => (
    <Overlay.Header
      icon="discharge_forms"
      title={this.props.data_form?.name || this.props.title}
      titleAside={<DataFormStatus status={this.props.data_form?.status} />}
      description={<ClientProfileHeader client={this.props.data_form?.client || this.props.client} />}
    />
  )

  renderContent = () => {
    const { data, $editable } = this.state

    return (
      <Fragment>
        <Chotomate ready name="discharge_summary_overlay" />

        <Section>
          <FormSection layout="vertical">
            <Flex horizontal gap={16}>
              <DateInput
                label="Admission Date"
                model="data.general.admitted_at"
                value={this.props.client?.current_admission?.admitted_at}
              />
              <DateInput
                label="Discharge Date"
                model="data.general.discharged_at"
                smartDescription={(date, model) => {
                  if (model?.data?.general?.admitted_at) {
                    const length = daysBetween(model.data.general.admitted_at, date, this.props.timezone)
                    return `Length of Stay: ${countWord('days', length)}`
                  } else {
                    return `Select an Admission and Discharge Date to calculate the stay`
                  }
                }}
              />
            </Flex>

            <RadioGroup label="Discharge Type" model="data.general.discharge_type" layout="vertical-dense">
              <Radio label="Successfully Completed" value="successfully_completed" />
              <Radio label="Therapeutic Discharge" value="therapeutic_discharge" />
              <Radio label="AMA" value="ama" />
              <Radio label="MIA" value="mia" />
              <Radio label="Administrative Discharge" value="administrative_discharge" />
              <Radio label="Other" value="other" />
            </RadioGroup>

            <ContextShow when="data.general.discharge_type" is="other">
              <Textarea label="Other:" model="data.general.discharge_type_other" />
            </ContextShow>

            <LevelOfCareSelect label="Level of Care at Time of Discharge" model="data.general.level_of_care" />
          </FormSection>
        </Section>

        <Divider />

        <Section
          title="Medications at Time of Discharge"
          aside={<Switch horizontal inverse model="data.medications.not_applicable" />}
          scrollview={{
            id: 'medications',
            name: 'Medications',
          }}
          commentsModel="data.medications"
        >
          <ContextHide when="data.medications.not_applicable" is={true} declineUndefined>
            <Medications
              model="data.medications.meds"
              isEditable={$editable}
              client={data.client}
              icon="medications"
              title="Medications"
              emptyDescription="No medications added yet"
            />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Strengths, Needs, Abilities, Preferences"
          aside={<Switch horizontal inverse model="data.snap.not_applicable" />}
          scrollview={{
            id: 'snap',
            name: 'S.N.A.P.',
          }}
          commentsModel="data.snap"
        >
          <ContextHide when="data.snap.not_applicable" is={true}>
            <SNAP />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Summary of Provided Services"
          aside={<Switch horizontal inverse model="data.provided_services.not_applicable" />}
          scrollview={{
            id: 'summary',
            name: 'Summary of Services',
          }}
          commentsModel="data.summary"
        >
          <ContextHide when="data.provided_services.not_applicable" is={true}>
            <FormSection layout="vertical">
              <CheckboxGroup label="Summary of provided services:" layout="vertical-dense">
                <Checkbox label="Assessment and evaluation" model="data.provided_services.assessment_and_evaluation" />
                <Checkbox label="Psychiatric assessment" model="data.provided_services.psychiatric_assessment" />
                <Checkbox label="Medication management" model="data.provided_services.medication_management" />
                <Checkbox label="Group psychotherapy" model="data.provided_services.group_psychotherapy" />
                <Checkbox label="Co-occurring disorder education" model="data.provided_services.co_occurring_disorder_education" />
                <Checkbox label="Life skills" model="data.provided_services.life_skills" />
                <Checkbox label="Nutrition education" model="data.provided_services.nutrition_education" />
                <Checkbox label="Family therapy" model="data.provided_services.family_therapy" />
                <Checkbox label="Individual counseling" model="data.provided_services.individual_counseling" />
                <Checkbox label="Trauma focused therapy" model="data.provided_services.trauma_focused_therapy" />
                <Checkbox label="12 step meetings" model="data.provided_services.12_step_meetings" />
                <Checkbox label="Other" model="data.provided_services.other" />
              </CheckboxGroup>

              <ContextShow when="data.provided_services.other" is={true}>
                <Textarea label="Other:" model="data.provided_services.other_extra" />
              </ContextShow>

              <Textarea
                label="Clinical Summary (include any special needs or concerns):"
                model="data.provided_services.clinical_summary"
                maxWidth="500px"
              />

              <RadioGroup
                label="Does client have any unresolved issues that could hinder continued recovery?"
                model="data.provided_services.has_unresolved_issues"
                layout="horizontal"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.provided_services.has_unresolved_issues" is={true}>
                <Textarea label="Please explain:" model="data.provided_services.has_unresolved_issues_extra" />
              </ContextShow>

              <RadioGroup
                label="Please rate the client's overall compliance:"
                model="data.provided_services.client_overall_compliance"
                layout="horizontal-wrap"
              >
                <Radio label="Poor" value="poor" />
                <Radio label="Fair" value="fair" />
                <Radio label="Good" value="good" />
                <Radio label="Excellent" value="excellent" />
              </RadioGroup>

              <RadioGroup
                label="Please rate the client's prognosis for motivation:"
                model="data.provided_services.client_motivation"
                layout="horizontal-wrap"
              >
                <Radio label="Poor" value="poor" />
                <Radio label="Fair" value="fair" />
                <Radio label="Good" value="good" />
                <Radio label="Excellent" value="excellent" />
              </RadioGroup>

              <RadioGroup
                label="Please rate the client's prognosis for medication compliance:"
                model="data.provided_services.client_medication_compliance"
                layout="horizontal-wrap"
              >
                <Radio label="Poor" value="poor" />
                <Radio label="Fair" value="fair" />
                <Radio label="Good" value="good" />
                <Radio label="Excellent" value="excellent" />
              </RadioGroup>

              <RadioGroup
                label="Please rate the client's prognosis for continued recovery:"
                model="data.provided_services.client_continued_recovery"
                layout="horizontal-wrap"
              >
                <Radio label="Poor" value="poor" />
                <Radio label="Fair" value="fair" />
                <Radio label="Good" value="good" />
                <Radio label="Excellent" value="excellent" />
              </RadioGroup>
            </FormSection>
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Aftercare Plan"
          aside={<Switch horizontal inverse model="data.aftercare_plan.not_applicable" />}
          scrollview={{
            id: 'aftercare_plan',
            name: 'Aftercare Plan',
          }}
          commentsModel="data.aftercare_plan"
        >
          <ContextHide when="data.aftercare_plan.not_applicable" is={true}>
            <FormSection layout="vertical">
              <CheckboxGroup label="Please select all that apply:" layout="vertical-dense">
                <Checkbox label="Transfer to another facility" model="data.aftercare_plan.transfer_to_another_facility" />
                <Checkbox label="Sober living" model="data.aftercare_plan.sober_living" />
                <Checkbox label="Independent residence" model="data.aftercare_plan.independent_residence" />
                <Checkbox label="Involvement in 12 step meetings" model="data.aftercare_plan.involvement_in_12_step_meetings" />
                <Checkbox label="Outpatient psychiatric" model="data.aftercare_plan.outpatient_psychiatric" />
                <Checkbox label="Outpatient medical provider" model="data.aftercare_plan.outpatient_medical_provider" />
                <Checkbox label="Outpatient therapist" model="data.aftercare_plan.outpatient_therapist" />
                <Checkbox label="Other" model="data.aftercare_plan.other" />
              </CheckboxGroup>

              <ContextShow when="data.aftercare_plan.other" is={true}>
                <Textarea label="Other:" model="data.aftercare_plan.other_extra" />
              </ContextShow>

              <Textarea label="Please list any other referrals that have been given:" model="data.aftercare_plan.referrals_given" />
            </FormSection>
          </ContextHide>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'client_review',
            name: 'Client Review',
          }}
          commentsModel="data.client_review"
        >
          <Grid gap={16}>
            <Heading is="h2">Client Confirmation of Reviewed Material</Heading>

            <Heading is="h3" size={600}>
              I understand and agree with the above recommendations. All personal items and valuables have been returned to me. Medication
              risks, benefits and potential side effects have been explained to me.
            </Heading>

            <Heading is="h3" size={600}>
              I have received the medications listed in this summary and understand that any controlled substances prescribed to me and not
              released will be destroyed within 72 hours of discharge.
            </Heading>
          </Grid>
        </Section>
      </Fragment>
    )
  }
}

DischargeSummary.defaultProps = {
  title: 'Discharge Summary',
  category: 'discharge',
  subcategory: 'discharge_summary',
  requestClientSignature: true,
  requireSupervisor: true,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(DischargeSummary))
