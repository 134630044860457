import React from 'react'
import startCase from 'lodash/startCase'

import { age, address } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import { ROUNDS_CATEGORIES } from './constants'
import { Permission } from '../../components'

const RootRoundsTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'round_templates',
    endpoint: '/round_templates',
    invalidate: 'round_templates',
    options: props,
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="rounds" title="Rounds Template" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection>
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a template name',
                  },
                }}
              />

              <ObjectSelector
                label="Default Supervisor"
                model="default_supervisor"
                icon="employees"
                type="employees"
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => startCase(data.position)}
              />

              <CheckboxGroup label="Documentation" layout="vertical-dense">
                <Checkbox value={true} isDisabled label="Round Notes" icon="rounds" />

                {Object.entries(ROUNDS_CATEGORIES).map(([model, category]) => (
                  <Permission featureFlagV2={category.featureFlag}>
                    <Checkbox key={model} label={category.label} icon={category.icon} model={`documentation.${model}`} />
                  </Permission>
                ))}
              </CheckboxGroup>

              <RadioGroup label="Clients Import" model="client_import_type" layout="vertical-dense" defaultValue="all">
                <Radio label="All Clients" value="all" />
                <Radio label="Manual from Clients List" value="manual" />
                <Radio label="Automatic from Locations / Programs" value="dynamic" />
              </RadioGroup>

              <ContextShow when="client_import_type" is="manual">
                <MultiObjectSelector
                  label="Clients"
                  model="imported_clients"
                  type="applicants_and_clients"
                  icon={ICONS.clients}
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => `${startCase(data?.sex) || '–'}, ${age(data?.dob)} y/o, #${data?.behave_id}`}
                />
              </ContextShow>

              <ContextShow when="client_import_type" is="dynamic">
                <MultiObjectSelector
                  label="Import from Locations"
                  model="imported_houses"
                  type="properties"
                  icon="locations"
                  selectTitle={(data: any) => data.name}
                  selectDescription={(data: any) => address(data.address)}
                />

                <RadioGroup label="Programs Import Type" model="programs_import_type" layout="horizontal-dense" defaultValue="programs">
                  <Radio label="Programs" value="programs" />
                  <Radio label="Program Lists" value="program_lists" />
                </RadioGroup>

                <ContextShow when="programs_import_type" is="programs">
                  <MultiObjectSelector
                    label="Import from Programs"
                    type="programs"
                    model="imported_programs"
                    icon="checklist"
                    selectTitle={(data: any) => data.name}
                  />
                </ContextShow>

                <ContextShow when="programs_import_type" is="program_lists">
                  <MultiObjectSelector
                    label="Import from Program Lists"
                    type="program_lists"
                    model="imported_phases"
                    icon="checklist"
                    selectTitle={(data: any) => data.name}
                    selectDescription={(data: any) => data?.program?.name || '–'}
                  />
                </ContextShow>
              </ContextShow>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission={isNew ? 'rounds_templates.create' : 'rounds_templates.edit'}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Rounds Template"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="rounds_templates.edit"
            />

            <DeleteDialog
              title="Delete Rounds Template?"
              message="Are you sure you want to delete this Rounds Template? This action cannot be undone."
              onYes={deleteRecord}
              permission="rounds_templates.delete"
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                permission="rounds_templates.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const RoundsTemplateOverlay = withOverlayError(RootRoundsTemplateOverlay)
