import React from 'react'

import { LOGOS } from '../theme'

const Logo = ({ logo, small, ...rest }) => {
  let logoObj = LOGOS[logo]
  if (small) logoObj = LOGOS[logo].small

  return <img css={styles} src={logoObj?.file} width={logoObj?.width} height={logoObj?.height} alt={logoObj?.alt || 'Logo'} {...rest} />
}

const styles = {
  display: 'inline-block',
  verticalAlign: 'middle',
  flex: '0 0 auto',
  userSelect: 'none',
}

Logo.defaultProps = {
  logo: 'behave_health',
}

export default Logo
