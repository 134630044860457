import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import SmartStatus from '@behavehealth/components/SmartStatus'

const STATUSES = {
  active: {
    label: 'Active',
    color: 'green',
  },
  archived: {
    label: 'Archived',
    color: 'red',
  },
  unknown: {
    label: 'Unknown',
    color: 'gray',
  },
}

const RootInventory = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting', 'inventory'],
    endpoint: `/accounting/inventory`,
    // params: {...},
    localStorageKey: 'accounting_inventory_v1',
  })

  const { mutateAsync: syncRailzToBh, isLoading: isSyncingRailz } = useCreate({
    name: ['sync-railz-to-bh'],
    url: '/accounting/inventory/sync_railz_to_bh',
    invalidate: tableProps.queryKey,
  })

  const { mutateAsync: syncQbToRailz, isLoading: isSyncingQb } = useCreate({
    name: ['sync-qb-to-railz'],
    url: '/accounting/inventory/sync_qb_to_railz',
  })

  const handleSyncRailzToBh = async () => {
    await syncRailzToBh()
    tableProps.refetch()
  }

  const handleSyncQbToRailz = () => {
    syncQbToRailz()
  }

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} />,
      },
      {
        title: 'Code',
        model: 'code',
        width: 200,
        disableHide: true,
      },
      {
        title: 'Quickbooks Status',
        model: 'item_status',
        width: 160,
        disableHide: true,
        formatValue: ({ value }) => <SmartStatus statuses={STATUSES} status={value} />,
      },
      {
        title: 'Is Invoice Item',
        model: 'is_invoice_item',
        width: 180,
        disableHide: true,
        type: 'boolean',
      },
      {
        title: 'Invoice Item Description',
        model: 'invoice_item_description',
        width: 300,
        disableHide: true,
      },
      {
        title: 'Invoice Item Unite Price',
        model: 'invoice_item_unit_price',
        width: 260,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Invoice Item Account Reference',
        model: 'invoice_item_account_ref_id',
        width: 300,
        disableHide: true,
      },
      {
        title: 'Invoice Item Tax Rate Reference',
        model: 'invoice_item_tax_rate_ref_id',
        width: 300,
        disableHide: true,
      },
      {
        title: 'Is Bill Item',
        model: 'is_bill_item',
        width: 180,
        disableHide: true,
        type: 'boolean',
      },
      {
        title: 'Bill Item Description',
        model: 'bill_item_description',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Bill Item Unit Price',
        model: 'bill_item_unit_price',
        width: 260,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Bill Item Account Reference',
        model: 'bill_item_account_ref_id',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Bill Item Tax Rate Reference',
        model: 'bill_item_tax_rate_ref_id',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Quantity On Hand',
        model: 'quantity_on_hand_quantity',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Quantity On Hand Location Reference',
        model: 'quantity_on_hand_location_ref_id',
        width: 300,
        disableHide: true,
      },
      {
        title: 'Quantity On Hand Account Reference',
        model: 'quantity_on_hand_account_ref_id',
        width: 300,
        disableHide: true,
      },
      {
        title: 'Quickbooks Last Updated',
        model: 'source_modified_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Inventory">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Inventory"
          icon="accounting"
          emptyDescription="No inventory have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          columns={columns}
          {...tableProps}
          headerAfter={
            isBehave && (
              <Flex gap="0.5rem">
                <AccountingSyncButton
                  forceShow={true}
                  category="inventory"
                  invalidate={tableProps.queryKey}
                  refetchUrl={'/accounting/sync?category=inventory'}
                />
              </Flex>
            )
          }
        />
      </Grid>
    </Page>
  )
}

export const Inventory = withPageError(RootInventory)

const FILTERS_CONFIG = {
  name: DEFAULT_FILTERS.name,
  code: {
    label: 'Code',
    type: 'string',
  },
  item_status: {
    label: 'Item Status',
    type: 'string',
  },
  is_bill_item: {
    label: 'Is Bill Item',
    type: 'string',
  },
  bill_item_description: {
    label: 'Bill Item Description',
    type: 'string',
  },
  bill_item_unit_price: {
    label: 'Bill Item Unit Price',
    type: 'string',
  },
  bill_item_account_ref_id: {
    label: 'Bill Item Account',
    type: 'string',
  },
  bill_item_tax_rate_ref_id: {
    label: 'Bill Item Tax Rate',
    type: 'string',
  },
  is_invoice_item: {
    label: 'Is Invoice Item',
    type: 'string',
  },
  invoice_item_description: {
    label: 'Invoice Item Description',
    type: 'string',
  },
  invoice_item_unit_price: {
    label: 'Invoice Item Unit Price',
    type: 'string',
  },
  invoice_item_account_ref_id: {
    label: 'Invoice Item Account',
    type: 'string',
  },
  invoice_item_tax_rate_ref_id: {
    label: 'Invoice Item Tax Rate',
    type: 'string',
  },
  quantity_on_hand_quantity: {
    label: 'Quantity On Hand Quantity',
    type: 'string',
  },
  quantity_on_hand_location_ref_id: {
    label: 'Quantity On Hand Location',
    type: 'string',
  },
  quantity_on_hand_account_ref_id: {
    label: 'Quantity On Hand Account',
    type: 'string',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'string',
  },
}
