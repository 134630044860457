import React from 'react'
import { connect } from 'react-redux'

import Card from '../../../../Card'
import Checkbox from '../../../../Forms/Checkbox'
import ContextShow from '../../../../Forms/ContextShow'
import DateOfBirthInput from '../../../../Forms/DateOfBirthInput'
import Divider from '../../../../Divider'
import FormSection from '../../../../Forms/FormSection'
import GridTable from '../../../../GridTable'
import HelpTagIframe from '../../../../Help/HelpTagIframe'
import Input from '../../../../Forms/Input'
import Overlay from '../../../../Overlay'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Section from '../../../../Section'

import ClientProfileHeader from '../../../../ClientProfileHeader'
import DataFormStatus from '../../../../Statuses/DataFormStatus'

import { DataFormsBase, mapDispatchToProps, mapStateToProps } from '../DataFormsBase'
import { withOverlayError } from '../../../../../hocs/withOverlayError'

const SUPPORT_GROUP_CONTINUITY = [
  { name: 'Never/Stopped', model: 'no' },
  { name: 'Once a month or less', model: 'once_a_month_or_less' },
  { name: 'Several times a month', model: 'several_times_a_month' },
  { name: 'At least once a week', model: 'at_least_once_a_week' },
]

const SUPPORT_GROUPS = [
  { name: '1a. Formal aftercare', model: 'formal_aftercare' },
  { name: '1b. AA', model: 'aa' },
  { name: '1c. NA', model: 'na' },
  { name: '1d. Other support group', model: 'other' },
]

const TROUBLING_LEVEL = [
  { name: 'Not Troubled At All', model: 'not_troubled_at_all' },
  { name: 'Somewhat Troubled', model: 'somewhat_troubled' },
  { name: 'Very troubled', model: 'very_troubled' },
]

const TROUBLING_REASONS = [
  { name: '2a. Being bored', model: 'being_bored' },
  { name: '2b. Being under stress', model: 'being_under_stress' },
  { name: '2c. Being anxious', model: 'being_anxious' },
  { name: '2d. Being nervous', model: 'being_nervous' },
  { name: '2e. Feeling uncomfortable', model: 'feeling_uncomfortable' },
  { name: '2f. Feeling restless', model: 'feeling_restless' },
  { name: '2g. Craving alcohol', model: 'craving_alcohol' },
  { name: '2h. Craving drugs', model: 'craving_drugs' },
]

const SUBSTANCES = [
  { name: '3a. Alcohol', model: 'alcohol' },
  { name: '3b. Marijuana', model: 'marijuana' },
  { name: '3c. Cocaine', model: 'cocaine' },
  { name: '3d. Heroin', model: 'heroin' },
  { name: '3e. Prescription Opioids', model: 'prescription_opioids' },
  { name: '3f. Other Opioids', model: 'other_opioids' },
  { name: '3g. Methamphetamine', model: 'methamphetamine' },
  { name: '3h. Benzodiazepines', model: 'benzodiazepines' },
  { name: '3i. Amphetamines', model: 'amphetamines' },
  { name: '3j. Designer drugs such as hallucinogens', model: 'designer_drugs' },
  { name: '3k. Other drugs', model: 'other_drugs' },
]

const SUBSTANCE_USE_QUESTIONS = [
  { name: 'No use', model: 'no_use' },
  { name: 'Used less than a week', model: 'used_less_than_a_week' },
  { name: 'Used more than a week', model: 'used_more_than_a_week' },
]

const SUBSTANCE_USE_EXPERIENCE_QUESTIONS = [
  { name: 'No', model: 'no' },
  { name: 'Yes', model: 'yes' },
  { name: 'N/A', model: 'not_available' },
]

const SUBSTANCE_USE_EXPERIENCES = [
  { name: '6a. Used longer than intended', model: 'used_longer_than_intended' },
  { name: '6b. Neglected some usual responsibilities', model: 'neglected_some_usual_responsibilites' },
  { name: '6c. Wanted to cut down or stop using', model: 'wanted_to_cut_down_or_stop_using' },
  { name: '6d. Others objected to your use', model: 'others_objected_to_your_use' },
  { name: '6e. Preoccupied about using (thinking about it a lot)', model: 'preoccupied_about_using' },
  { name: '6f. Used to relieve emotional distress', model: 'used_to_relieve_emotional_distress' },
  { name: '6g. Other negative experiences', model: 'other_negative_experiences' },
]

const TREATMENT_HELPFULNESS = [
  { name: 'Not helpful', model: 'not_helpful' },
  { name: 'A little helpful', model: 'a_little_helpful' },
  { name: 'Somewhat helpful', model: 'somewhat_helpful' },
  { name: 'Very helpful', model: 'very_helpful' },
  { name: 'Not applicable', model: 'not_applicable' },
]

const TREATMENT_GROUPS = [
  { name: '10a. Group therapy', model: 'group_therapy' },
  { name: '10b. Individual counseling', model: 'individual_counseling' },
  { name: '10c. Lectures and education', model: 'lectures_and_education' },
  { name: '10d. Working the AA/NA steps', model: 'working_the_aa_na_steps' },
  { name: '10e. Peer group meetings (e.g. AA)', model: 'peer_group_meetings' },
  { name: '10f. Family portion of program', model: 'family_portion_of_program' },
  { name: '10g. Talking with other patients', model: 'talking_with_other_patients' },
  { name: '10h. Overall rating of the program', model: 'overall_program_rating' },
]

class FollowUpFormOverlay extends DataFormsBase {
  renderHeader = () => (
    <Overlay.Header
      icon="outcome_measures"
      title={this.props.data_form?.name || this.props.title}
      titleAside={<DataFormStatus status={this.props.data_form?.status} />}
      description={<ClientProfileHeader client={this.props.data_form?.client || this.props.client} />}
      help={<HelpTagIframe id="outcome_measures_locator_form" />}
    />
  )

  renderContent = () => (
    <>
      <Section
        title="Research Coordinator"
        headingType="h2"
        scrollview={{
          id: 'research_coordination',
          name: 'Research Coordination',
        }}
        commentsModel="data.research_coordination"
      >
        <FormSection>
          <RadioGroup label="Follow Up" model="data.research_coordination.follow_up" layout="vertical-dense">
            <Radio label="1 Month" value="1" />
            <Radio label="3 Month" value="3" />
            <Radio label="6 Month" value="6" />
            <Radio label="9 Month" value="9" />
            <Radio label="12 Month" value="12" />
          </RadioGroup>

          <DateOfBirthInput isEditable={false} label="Patient DOB" value={this.props?.client?.dob} />

          <RadioGroup
            label="Please ask the participant their Date of Birth to confirm their identity. Only complete survey if DOB matches."
            model="data.research_coordination.identity_confirmation"
            layout="vertical-dense"
          >
            <Radio label="Yes, DOB matches" value="dob_matches" />
            <Radio label="No, DOB does not match" value="dob_does_not_match" />
            <Radio label="No, client refused to confirm DOB" value="dob_client_refused_to_confirm" />
            <Radio label="No, did not speak with client/ unable to complete survey" value="dob_unable_to_complete" />
          </RadioGroup>

          <ContextShow when="data.research_coordination.identity_confirmation" is="dob_unable_to_complete">
            <RadioGroup
              label="If unable to complete this survey with the patient, please indicate why:"
              model="data.research_coordination.dob_unable_to_complete_reason"
              layout="vertical-dense"
            >
              <Radio label="Deceased" value="deceased" />
              <Radio label="In treatment" value="in_treatment" />
              <Radio label="Incarcerated" value="incarcerated" />
              <Radio label="Refused" value="refused" />
              <Radio label="Unable to locate" value="unable_to_locate" />
            </RadioGroup>
          </ContextShow>
        </FormSection>
      </Section>

      <ContextShow when="data.research_coordination.identity_confirmation" is="dob_matches">
        <Divider />

        <Section
          title="Substance Use History"
          headingType="h2"
          scrollview={{
            id: 'substance_use_history',
            name: 'Substance Use History',
          }}
          commentsModel="data.substance_use_history"
        >
          <FormSection label="1. How often did you attend the following in the last month/in the last three months?" maxWidth="100%">
            <Card className="!mb-4 max-w-[960px]">
              <GridTable firstColumnSticky templateColumns="180px repeat(4, 180px)">
                <GridTable.Header>
                  <GridTable.Cell></GridTable.Cell>
                  <GridTable.Cell>Never/Stopped</GridTable.Cell>
                  <GridTable.Cell>Once a month or less</GridTable.Cell>
                  <GridTable.Cell>Several times a month</GridTable.Cell>
                  <GridTable.Cell>At least once a week</GridTable.Cell>
                </GridTable.Header>

                {SUPPORT_GROUPS.map((support_group) => (
                  <GridTable.Row key={support_group.model}>
                    <GridTable.Cell>{support_group.name}</GridTable.Cell>

                    {SUPPORT_GROUP_CONTINUITY.map((substance) => (
                      <GridTable.Cell key={substance.model} css={checkboxCellStyles}>
                        <Checkbox
                          trueIcon="check"
                          model={`data.substance_use_history.support_group_continuity.${substance.model}.${support_group.model}`}
                        />
                      </GridTable.Cell>
                    ))}
                  </GridTable.Row>
                ))}
              </GridTable>
            </Card>
          </FormSection>

          <FormSection label="2. In the last month/in the last three months how troubled were you by:" maxWidth="100%">
            <Card className="!mb-4 max-w-[960px]">
              <GridTable firstColumnSticky templateColumns="180px repeat(3, 240px)">
                <GridTable.Header>
                  <GridTable.Cell></GridTable.Cell>
                  <GridTable.Cell>Not troubled at all</GridTable.Cell>
                  <GridTable.Cell>Somewhat troubled</GridTable.Cell>
                  <GridTable.Cell>Very troubled</GridTable.Cell>
                </GridTable.Header>

                {TROUBLING_REASONS.map((troubling_reason) => (
                  <GridTable.Row key={troubling_reason.model}>
                    <GridTable.Cell>{troubling_reason.name}</GridTable.Cell>

                    {TROUBLING_LEVEL.map((troubling_level) => (
                      <GridTable.Cell key={troubling_level.model} css={checkboxCellStyles}>
                        <Checkbox
                          trueIcon="check"
                          model={`data.substance_use_history.troubling_reasons.${troubling_reason.model}.${troubling_level.model}`}
                        />
                      </GridTable.Cell>
                    ))}
                  </GridTable.Row>
                ))}
              </GridTable>
            </Card>
          </FormSection>

          <FormSection label="3. How long you have used any of the following in the last month/in the last three months?" maxWidth="100%">
            <Card className="!mb-4 max-w-[960px]">
              <GridTable firstColumnSticky templateColumns="180px repeat(3, 240px)">
                <GridTable.Header>
                  <GridTable.Cell></GridTable.Cell>
                  <GridTable.Cell>No use</GridTable.Cell>
                  <GridTable.Cell>Used less than a week</GridTable.Cell>
                  <GridTable.Cell>Used more than a week</GridTable.Cell>
                </GridTable.Header>

                {SUBSTANCES.map((question) => (
                  <GridTable.Row key={question.model}>
                    <GridTable.Cell>{question.name}</GridTable.Cell>

                    {SUBSTANCE_USE_QUESTIONS.map((substance) => (
                      <GridTable.Cell key={substance.model} css={checkboxCellStyles}>
                        <Checkbox
                          trueIcon="check"
                          model={`data.substance_use_history.substance_use.${substance.model}.${question.model}`}
                        />
                      </GridTable.Cell>
                    ))}
                  </GridTable.Row>
                ))}
              </GridTable>
            </Card>
          </FormSection>

          <FormSection maxWidth="100%">
            <Input
              label="4. In the past month/three months, what was your longest period of abstinence from non-prescribed substances in weeks?"
              type="number"
              suffix="weeks"
              size={4}
              model="data.substance_use_history.longest_abstinence_period"
            />

            <Input
              label="5. How many different substances have you used in the past week, including alcohol and prescription drugs not used as prescribed?"
              type="number"
              suffix="substances"
              size={4}
              model="data.substance_use_history.amt_of_substances_used_in_the_past_week"
            />

            <FormSection
              label="6. Which of the following have you experienced related to substance use in the last month/three months?"
              maxWidth="100%"
            >
              <Card className="!mb-4 max-w-[960px]">
                <GridTable firstColumnSticky templateColumns="180px repeat(3, 240px)">
                  <GridTable.Header>
                    <GridTable.Cell></GridTable.Cell>
                    <GridTable.Cell>No</GridTable.Cell>
                    <GridTable.Cell>Yes</GridTable.Cell>
                    <GridTable.Cell>N/A</GridTable.Cell>
                  </GridTable.Header>

                  {SUBSTANCE_USE_EXPERIENCES.map((substanceUseExperience) => (
                    <GridTable.Row key={substanceUseExperience.model}>
                      <GridTable.Cell>{substanceUseExperience.name}</GridTable.Cell>

                      {SUBSTANCE_USE_EXPERIENCE_QUESTIONS.map((substanceUseQuestion) => (
                        <GridTable.Cell key={substanceUseQuestion.model} css={checkboxCellStyles}>
                          <Checkbox
                            trueIcon="check"
                            model={`data.substance_use_history.substance_use_experience.${substanceUseExperience.model}.${substanceUseQuestion.model}`}
                          />
                        </GridTable.Cell>
                      ))}
                    </GridTable.Row>
                  ))}
                </GridTable>
              </Card>
            </FormSection>

            <FormSection maxWidth="100%">
              <RadioGroup
                label="7. Have you received services for a substance use disorder from another program or provider?"
                model="data.substance_use_history.received_services_from_another_program"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.received_services_from_another_program" is={true}>
                <div className="!pl-4">
                  <RadioGroup
                    label="7a. If yes, was this provider recommended by the previous program or provider?"
                    model="data.substance_use_history.recommended_by_previous_program"
                    layout="horizontal-dense"
                  >
                    <Radio label="Yes" value={true} />
                    <Radio label="No" value={false} />
                  </RadioGroup>
                </div>
              </ContextShow>

              <RadioGroup
                label="8. Are you taking any medication for your substance use disorder?"
                model="data.substance_use_history.taking_any_medication_for_substance_use_disorder"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.taking_any_medication_for_substance_use_disorder" is={true}>
                <div className="!pl-4">
                  <RadioGroup
                    label="8a. If yes, are you taking it only as prescribed?"
                    model="data.substance_use_history.taking_medication_as_prescribed"
                    layout="horizontal-dense"
                  >
                    <Radio label="Yes" value={true} />
                    <Radio label="No" value={false} />
                  </RadioGroup>

                  <RadioGroup
                    label="8b. If taking a medication for a substance use disorder, have you missed taking it more than once or twice in a week?"
                    model="data.substance_use_history.missed_medication"
                    layout="horizontal-dense"
                  >
                    <Radio label="Yes" value={true} />
                    <Radio label="No" value={false} />
                  </RadioGroup>
                </div>
              </ContextShow>

              <RadioGroup
                label="9. Are you taking any medication for a mental health condition?"
                model="data.substance_use_history.taking_medication_for_mental_health"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.substance_use_history.taking_medication_for_mental_health" is={true}>
                <div className="!pl-4">
                  <RadioGroup
                    label="9a. If taking a medication for mental health disorder, have you missed taking it more than once or twice in a week?"
                    model="data.substance_use_history.missed_medication_for_mental_health"
                    layout="horizontal-dense"
                  >
                    <Radio label="Yes" value={true} />
                    <Radio label="No" value={false} />
                  </RadioGroup>
                </div>
              </ContextShow>
            </FormSection>
          </FormSection>
        </Section>

        <Divider />

        <Section
          title="Treatment Ratings"
          headingType="h2"
          scrollview={{
            id: 'treatment_ratings',
            name: 'Treatment Ratings',
          }}
          commentsModel="data.treatment_ratings"
        >
          <FormSection label="10. How helpful have the following treatment components been to you?" maxWidth="100%">
            <Card className="!mb-4 max-w-[960px]">
              <GridTable firstColumnSticky templateColumns="180px repeat(5, 144px)">
                <GridTable.Header>
                  <GridTable.Cell></GridTable.Cell>
                  <GridTable.Cell>Not helpful</GridTable.Cell>
                  <GridTable.Cell>A little helpful</GridTable.Cell>
                  <GridTable.Cell>Somewhat helpful</GridTable.Cell>
                  <GridTable.Cell>Very helpful</GridTable.Cell>
                  <GridTable.Cell>Not applicable</GridTable.Cell>
                </GridTable.Header>

                {TREATMENT_GROUPS.map((treatmentGroup) => (
                  <GridTable.Row key={treatmentGroup.model}>
                    <GridTable.Cell>{treatmentGroup.name}</GridTable.Cell>

                    {TREATMENT_HELPFULNESS.map((treatmentHelpfulness) => (
                      <GridTable.Cell key={treatmentHelpfulness.model} css={checkboxCellStyles}>
                        <Checkbox
                          trueIcon="check"
                          model={`data.treatment_ratings.helpfulness.${treatmentGroup.model}.${treatmentHelpfulness.model}`}
                        />
                      </GridTable.Cell>
                    ))}
                  </GridTable.Row>
                ))}
              </GridTable>
            </Card>
          </FormSection>
        </Section>
      </ContextShow>

      <Divider />

      <Section>
        <p>
          <b>Copyright:</b> The NAATP Addiction Treatment Outcomes Measurement Program
        </p>
      </Section>
    </>
  )
}

const checkboxCellStyles = {
  display: 'flex',
  justifyContent: 'center',
}

FollowUpFormOverlay.defaultProps = {
  title: 'Follow-Up Form',
  category: 'outcome_measure',
  subcategory: 'follow_up_form',
  requestClientSignature: false,
  requestAuthorSignature: false,
  requireSupervisor: false,
  maxWidth: 85,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(FollowUpFormOverlay))
