import React from 'react'
import { NavLink, Navigate, Route, Routes, useMatch } from 'react-router-dom-v5-compat'
import kebabCase from 'lodash/kebabCase'
import snakeCase from 'lodash/snakeCase'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'

import PageHeader from '@behavehealth/components/PageHeader'
import Tabs from '@behavehealth/components/Tabs'
import Card from '@behavehealth/components/Card'
import State from '@behavehealth/components/State'

import { TreatmentBookCategoryPage } from './components/TreatmentBookCategoryPage'
import { useTreatmentPlanPermissions } from '@behavehealth/constructs/TreatmentPlans/useTreatmentPlanPermissions'
import { useNavigate } from 'react-router-dom-v5-compat'

const TreatmentPlanBook = () => {
  const { url } = useRouteURL()
  const navigate = useNavigate()

  const locationMatch: any = useMatch({ path: `${url}/:category` })
  const category = snakeCase(locationMatch?.params?.category)

  const {
    viewSomeTreatmentBooks,
    viewClinicalTreatmentBook,
    viewMedicalTreatmentBook,
    viewCaseManagementTreatmentBook,
    viewPeerTreatmentBook,
  } = useTreatmentPlanPermissions()

  const allowedTabs = React.useMemo(() => {
    const result = []

    if (viewClinicalTreatmentBook) result.push('clinical')
    if (viewMedicalTreatmentBook) result.push('medical')
    if (viewCaseManagementTreatmentBook) result.push('case_management')
    if (viewPeerTreatmentBook) result.push('peer')

    return result
  }, [viewClinicalTreatmentBook, viewMedicalTreatmentBook, viewCaseManagementTreatmentBook, viewPeerTreatmentBook])

  const firstAllowedTab = allowedTabs[0]

  React.useEffect(() => {
    if (!category || allowedTabs.includes(category)) return

    if (firstAllowedTab) navigate(kebabCase(firstAllowedTab), { replace: true })
  }, [category, allowedTabs, firstAllowedTab])

  return (
    <>
      <div>
        <div className="bg-white sticky top-0 z-10">
          <PageHeader title="Treatment Book" icon="treatment_plans" />

          {viewSomeTreatmentBooks && (
            <Tabs>
              <Tabs.List className="px-4">
                <Tabs.Item as={NavLink} label="Clinical" to={`clinical`} permission={`clinical_treatment_book.view`} />
                <Tabs.Item as={NavLink} label="Medical" to={`medical`} permission={`medical_treatment_book.view`} />
                <Tabs.Item as={NavLink} label="Case Management" to={`case-management`} permission={`case_management_treatment_book.view`} />
                <Tabs.Item as={NavLink} label="Peer" to={`peer`} permission={`peer_treatment_book.view`} />
              </Tabs.List>
            </Tabs>
          )}
        </div>

        {!viewSomeTreatmentBooks && (
          <div className="p-4">
            <Card>
              <State
                isEmpty
                icon="treatment_plans"
                title="Treatment Book"
                emptyDescription="You do not have permission to access Clinical, Medical nor Peer Treatment Books"
              />
            </Card>
          </div>
        )}

        {viewSomeTreatmentBooks && firstAllowedTab && (
          <Routes>
            <Route index element={<Navigate to={firstAllowedTab} replace />} />
            <Route path=":category/*" element={<TreatmentBookCategoryPage key={category} />} />
          </Routes>
        )}
      </div>
    </>
  )
}

export default withPageError(TreatmentPlanBook)
