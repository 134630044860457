import React from 'react'
import { connect } from 'react-redux'

import { UATests } from '../../../utils/constants'

import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import Chotomate from '../../Chotomate'
import ContextShow from '../../Forms/ContextShow'
import DateTimeInput from '../../Forms/DateTimeInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import HelpTagIframe from '../../Help/HelpTagIframe'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import Permission from '../../Permission'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Section from '../../Section'
import SignaturePad from '../../Forms/SignaturePad'
import Switch from '../../Forms/Switch'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { InsuranceBillingSection } from '../../../constructs/RCM/components/InsuranceBillingSection'
import { ExportPDFButton } from '../../Buttons/ExportPDFButton'

class UATest extends OverlayBase {
  onSaveSuccessful = () => {
    if (this.props.onSaveSuccessful) this.props.onSaveSuccessful()
  }

  renderHeader = () => {
    const { tenant } = this.props
    const isTrialing = tenant?.plan_status === 'trialing'

    return (
      <>
        <Overlay.Header icon="test_results" title="UA Test" help={<HelpTagIframe id="test_results_ua_tests" />} />

        {!this.state.$editable && !isTrialing && (
          <Permission permission="clients.actions.export">
            <Overlay.SubHeader>
              <ExportPDFButton url={`/ua_tests/${this.state.id}/pdf`} />
            </Overlay.SubHeader>
          </Permission>
        )}
      </>
    )
  }

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { record, user, timezone, tenant, loading } = this.props

    const isBehave = user?.type === 'bh_employee'

    return (
      <Overlay.Content>
        <Chotomate ready name="ua_test_overlay" />
        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          timezone={timezone}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
        >
          {isBehave && (
            <Section headingType="h2" title="Behave Details">
              <FormSection layout="vertical">
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox label="Billing Completed" model="is_billed" />
                </CheckboxGroup>

                <ContextShow when="is_billed" is={true}>
                  <DateTimeInput defaultToNow model="billed_at" label="Billing Completed At" />
                </ContextShow>
              </FormSection>
            </Section>
          )}

          <Section>
            <FormSection layout="vertical">
              <ObjectSelector
                isPolymorphic
                initialModelRequired={$new}
                model="reference"
                label="Client"
                type="current_clients"
                icon="clients"
                selectTitle={(data) => data.name}
                selectDescription={(data) => data.behave_id}
                disableLink={this.isPortal}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
              />

              <RadioGroup
                model="status"
                label="Status"
                layout="vertical-dense"
                validations={{
                  presence: {
                    message: 'Please select a status',
                  },
                }}
              >
                <Radio label="Collected" value="collected" />
                <Radio label="Not Collected" value="refused" />
              </RadioGroup>

              <DateTimeInput
                defaultToNow
                model="collected_at"
                label="Collection Date"
                validations={{
                  presence: {
                    message: 'Please enter a collection date',
                  },
                }}
              />

              <ContextShow when="status" is="collected">
                <RadioGroup
                  model="result"
                  label="Result"
                  layout="vertical-dense"
                  validations={{
                    presence: {
                      message: 'Please select a result',
                    },
                  }}
                >
                  <Radio label="Positive" value="positive" />
                  <Radio label="Negative" value="negative" />
                  <Radio label="Not Available" value="not_available" />
                </RadioGroup>

                <ContextShow when="result" is="positive">
                  <MultiObjectSelector
                    isRelations={false}
                    model="test_results"
                    label="Positive Results"
                    apiData={UATests}
                    showAvatars={false}
                    validations={{
                      presence: {
                        message: 'Please select positive test results',
                      },
                    }}
                  />
                </ContextShow>
              </ContextShow>

              <Textarea useQuickText model="additional_info" label="Notes" />
            </FormSection>
          </Section>

          <Divider />

          {tenant.is_billed && <InsuranceBillingSection isNew={$new} />}

          <Section headingType="h2" title="Attachments" description="Upload the files related to this UA Test">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          <Divider />

          <Section
            headingType="h2"
            title="Signatures"
            description="Use this section to record signatures"
            aside={<Switch defaultValue={false} model="is_signable" />}
          >
            <ContextShow when="is_signable" is={true}>
              <FormSection layout="vertical" className="!pt-4">
                <SignaturePad
                  showSignedBy={false}
                  label="Client Signature"
                  model="client_signature"
                  signedAtModel="client_signed_at"
                  allowPin={false}
                  showAccept={false}
                />

                <SignaturePad
                  allowPin
                  person={record.signed_by}
                  label="Staff Signature"
                  model="employee_signature"
                  signedAtModel="author_signed_at"
                  showAccept={false}
                />
              </FormSection>
            </ContextShow>
          </Section>

          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} disableLinks={this.isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    // skip rendering footer if Portal
    if (this.isPortal) return null

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save UA Test"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="test_results.ua_tests.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit UA Test"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="test_results.ua_tests.edit"
            />

            <DeleteDialog
              title="Delete UA Test?"
              message="Are you sure you want to delete this UA test? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                fullWidth
                permission="test_results.ua_tests.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'ua_tests')

export default connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(UATest))
