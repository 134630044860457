import React from 'react'
import { size } from 'lodash'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

import withSettings from '../../hocs/withSettings'
import { titleCase } from '../../utils/functions'

const columns = (to: Function = () => {}) => [
  {
    isSticky: true,
    canToggleVisible: false,
    id: 'name',
    accessor: 'signer.name',
    Header: 'Name',
    Cell: ({ value, row }) => <TableCell.MainLink to={to(row.original.id)} label={value} />,
  },
  {
    id: 'Signee Type',
    accessor: 'signer.type',
    Header: 'Type',
    Cell: ({ value }: any) => titleCase(value),
  },
  {
    id: 'contracts_count',
    accessor: 'contracts',
    Header: 'Assigned Agreements #',
    Cell: ({ value }: any) => {
      return `${size(value)} Agreements`
    },
  },
]

const SigneesTable = ({ data, to, isLoading, localStorageKey, batchActionsConfig, titleAfter }: any) => {
  return (
    <Table
      data={data}
      columns={columns(to)}
      title="Signees"
      titleAfter={titleAfter}
      icon="legal_agreement_alt"
      isLoading={isLoading}
      emptyDescription="No signees have been added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

SigneesTable.defaultProps = {
  localStorageKey: 'signees',
}

export default withSettings(SigneesTable)
