import React from 'react'

import Checkbox from '../../Forms/Checkbox'
import ContextShow from '../../Forms/ContextShow'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import InsurancePayersSelector from '../../Forms/elements/InsurancePayersSelector'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const RevenueCycleManagementFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="insurance" />
        <div>
          <Type as="h2" variant="H3">
            Revenue Cycle Management
          </Type>
        </div>
      </Flex>

      <ContextShow when="questionnaire.financial_details.accepted_payment_methods.cash_or_self_payment" is={true}>
        <div>
          <Type as="h3" variant="H4" className="mt-6">
            Consumer RCM
          </Type>
          <Type as="p" variant="TEXT" css={{ margin: '0.2em 0 0' }}>
            Direct to consumer revenue cycle management (RCM) automation includes the following benefits
          </Type>
        </div>

        <div>
          <ul>
            <li>Automate billing for your services and track how much clients owe</li>
            <li>Accept Credit & Debit cards digitally, with optional auto-payment capture</li>
            <li>Customize the payment methods you want to allow; cash, check, money order etc.</li>
            <li>Support multiple payers for a client, including outside organization payers</li>
          </ul>
        </div>

        <Checkbox
          trueIcon="check"
          falseIcon="cross"
          falseStyle="faded-linethrough"
          label="Simplify & automate my direct to consumer billing & payment collections"
          model="questionnaire.rcm.consumer_revenue_cycle_management"
        />
      </ContextShow>

      <Type as="h3" variant="H4" className="!mt-3">
        Behave Insurance RCM Software
      </Type>

      <div>
        <ul>
          <li>Manage all your payer relationships both out-of-network and in-network</li>
          <li>Organize & monitor all your facility & staff credentials to stay in compliance</li>
          <li>Verify Insurance Form for Marketing Website</li>
          <li>Automatic Electronic Verification of Benefits from Website Form</li>
          <li>Instant Benefits Verification Confirmation Calls</li>
        </ul>
      </div>

      <Checkbox
        trueIcon="check"
        falseIcon="cross"
        falseStyle="faded-linethrough"
        label="Simplify & automate my health insurance billing & collections"
        model="questionnaire.rcm.behave_insurance_rcm_software"
      />

      <Type as="h3" variant="H4" className="mt-6">
        Behave Insurance RCM Services
      </Type>

      <div>
        <ul>
          <li>Manage your Service Fee Schedule connected to your EHR</li>
          <li>Automatic coding of medical records for claim creation</li>
          <li>Seamless Utilization Review Services to Obtain Authorizations</li>
          <li>Automatic billing rosters for UB-04 & CMS-1500 claim creation</li>
          <li>Seamless and transparent claim creation and submission</li>
          <li>See your claim submission in real-time with your RCM analytics dashboard</li>
          <li>Monitor your aging in realtime to reduce surprises that delay your cashflow</li>
        </ul>
      </div>

      <Checkbox
        trueIcon="check"
        falseIcon="cross"
        falseStyle="faded-linethrough"
        label="Digitize & streamline my insured patient admissions process"
        model="questionnaire.rcm.behave_insurance_rcm_services"
      />

      <InsurancePayersSelector
        label="Health Insurance Payers"
        model="questionnaire.rcm.insurance_global_payers"
        placeholder="Search here for your Insurance Payers"
      />
    </FormSection>
  )
}

export const RevenueCycleManagement: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <RevenueCycleManagementFields />
    </Form>
  )
}
