import React from 'react'
import { Input } from './Input'
import { withFormContext } from './context'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { isBlank } from '../../utils/functions'

import LinkValue from '../LinkValue'

class SkypeInput extends Input {
  readOnlyRender = () => {
    if (isBlank(this.state.value)) return DEFAULT_EMPTY_VALUE

    return <LinkValue href={`skype:${this.state.value}?chat`} value={this.state.value} glyph={this.props.glyph} />
  }
}

SkypeInput.defaultProps = {
  glyph: 'skype',
}

export default withFormContext(SkypeInput)
