import React from 'react'
import WebViewer from '@pdftron/pdfjs-express-viewer'

export const PDFViewer = ({ name, blob, url }: any) => {
  const viewer = React.useRef(null)

  React.useEffect(() => {
    WebViewer(
      {
        licenseKey: process.env.BH_PDF_EXPRESS_KEY,
        path: '/webviewer/lib',
      },
      viewer.current,
    ).then((instance: any) => {
      if (blob) {
        instance.UI.loadDocument(blob, { filename: `${name}.pdf`, extension: 'pdf' })
        instance.setFitMode(instance.FitMode.FitWidth)
      } else if (url) {
        instance.loadDocument(url, {
          customHeaders: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Request-Method': '*',
            'Access-Control-Allow-Headers': '*',
          },
          extension: 'pdf',
        })
        instance.setFitMode(instance.FitMode.FitWidth)
      }
    })
  }, [name, blob, url])

  return <div key={url || blob} className="webviewer w-full h-full" ref={viewer}></div>
}
