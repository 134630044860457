import React from 'react'

import { address } from '../../../utils/functions'
import { useUpdate } from '../../../hooks/useNewAPI'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { Text } from '../../Typography'
import PropertyNavItems from '../../Navs/PropertyNavItems'
import QuickViewOverlay from './QuickViewOverlay'
import Status from '../../Status'
import TagsSelector from '../../TagsSelector'

const PropertyQuickView = ({ property, onClose }: any) => {
  const { mutateAsync: updateLocation }: any = useUpdate({
    name: ['location', property?.id],
    url: `/houses/${property?.id}`,
    invalidate: ['locations'],
    invalidateKeys: ['location'],
  })

  return (
    <QuickViewOverlay
      title={property.name}
      avatar={property.avatar}
      onClose={onClose}
      data={property}
      subheader={
        <>
          <Text>{address(property.address, '')}</Text>
          <Status color="blue" label={`${property.gender} property`} />
        </>
      }
      headerAfter={
        <div className="!mt-3 w-full">
          <TagsSelector tags={property?.tags} onSave={(tagIDs: any) => updateLocation({ tag_ids: tagIDs })} smartCategories="locations" />
        </div>
      }
    >
      <PropertyNavItems propertyID={property.id} />
    </QuickViewOverlay>
  )
}

export default withOverlayError(PropertyQuickView)
