import React from 'react'

import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const DigitalMarketingFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="web_form" />
        <div>
          <Type as="h2" variant="H3">
            Digital Marketing
          </Type>
        </div>
      </Flex>

      <div>
        <ul>
          <li>Connect your Google Analytics to Behave Marketing Data Hub*</li>
          <li>Monitor your website traffic referral sources</li>
          <li>Learn where your marketing is working best*</li>
          <li>Compare your digital marketing with other providers to optimize your strategy</li>
          <li>Measure the effectiveness of your digital marketing consultant driven campaigns</li>
          <li>Monitor your social media profiles</li>
        </ul>
      </div>

      <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough">
        <Checkbox
          defaultChecked
          label="I want to learn more about our digital marketing platform"
          model="questionnaire.digital_marketing.beta_waitlist"
        />
      </CheckboxGroup>
    </FormSection>
  )
}

export const DigitalMarketing: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <DigitalMarketingFields />
    </Form>
  )
}
