import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { LocationCheckinsReportDataTable } from '@behavehealth/constructs/LiveReports/LocationCheckinsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const LocationCheckins = () => {
  const tableProps = useDataTable({
    name: ['reports', 'location-checks'],
    endpoint: `/live_reports?category=location_checks`,
    localStorageKey: 'report_location_checks_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.resident)}/location-checkin/${rowData.id}`, [])

  return (
    <Page feature="location_checkin" title="Location Check-in Report">
      <Grid>
        <LocationCheckinsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(LocationCheckins)
