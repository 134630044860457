import React from 'react'

import { withFormContext } from '../../../../components/Forms/context'

import { ComboBox } from '../../../../components/Forms/ComboBoxes/ComboBox'
import Button from '../../../../components/Button'
import Flex from '../../../../components/Flex'
import SummonOverlay from '../../../../components/SummonOverlay'

import AdmissionSourceSelect from '../../../../components/Forms/AdmissionSourceSelect'
import AdmissionTypeSelect from '../../../../components/Forms/AdmissionTypeSelect'

import ClaimBillTypeSelector from '../../../../components/Forms/ClaimBillTypeSelector'
import ConditionCodesSelector from '../../../../components/Forms/elements/ConditionCodesSelector'

import ClaimFrequencySelect from '../ClaimFrequencySelect'
import ClaimFilingCodeSelect from '../ClaimFilingCodeSelect'
import ClaimPlaceOfServiceSelect from '../ClaimPlaceOfServiceSelect'
import ClaimPatientStatusSelect from '../ClaimPatientStatusSelect'

import { PreviewFeeScheduleOverlay } from '../PreviewFeeScheduleOverlay'

import { SectionCard } from '../../../../components/SectionCard'

const ClaimDetails = ({ data, feeSchedule, onUpdate }: any) => {
  if (!data) return null
  const { category } = data

  return (
    <SectionCard
      title="Claim Details"
      aside={
        <Flex gap="1rem">
          <Button label="Fee Schedule Settings" size={100} type="link" glyph="settings" link="/settings/fee-schedules" target="_blank" />

          {feeSchedule && (
            <SummonOverlay overlay={<PreviewFeeScheduleOverlay feeSchedule={feeSchedule} />}>
              <Button label="Fee Schedule Preview" glyph="view" type="link" size={100} display="inline-flex" />
            </SummonOverlay>
          )}
        </Flex>
      }
    >
      <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2 mq900:grid-cols-3 mq1200:grid-cols-4 mq1400:grid-cols-5">
        <ComboBox
          label="Fee Schedule"
          model="insurance_new_fee_schedule"
          type="insurance.new_fee_schedules.all"
          maxWidth="100%"
          selectTitle={(data: any) => data?.name}
          onUpdate={(value: any) => {
            onUpdate?.(value)
          }}
          onClear={() => {
            onUpdate?.(null)
          }}
        />

        <ClaimFilingCodeSelect model="claim_filing_code" maxWidth="100%" />
        <ClaimFrequencySelect model="claim_frequency_code" maxWidth="100%" />
        <ClaimPlaceOfServiceSelect model="place_of_service_code" maxWidth="100%" />

        {category === 'institutional' && <ClaimBillTypeSelector model="bill_type" maxWidth="100%" />}

        {category === 'institutional' && (
          <>
            <AdmissionTypeSelect model="admission_type_code" />
            <AdmissionSourceSelect model="admission_source_code" />
          </>
        )}

        <ConditionCodesSelector maxWidth="100%" model="condition_codes" />

        {category === 'institutional' && <ClaimPatientStatusSelect model="patient_status_code" />}
      </div>
    </SectionCard>
  )
}

export default withFormContext(ClaimDetails)
