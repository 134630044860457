import React from 'react'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

export const InsuranceEOBsTable = ({ onSelect, dependentValue, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-insurance-eobs'],
    endpoint: '/insurance_eobs?status=draft',
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_insurance_eobs_all_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'EOB',
        model: 'identifier',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell testKey="main_cell" id={data.id} value={value} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'File Name',
        model: 'upload_name',
        width: 200,
      },
      {
        title: 'Upload Date',
        model: 'uploaded_at',
        type: 'date_time',
      },
      {
        title: 'Uploaded By',
        model: 'uploaded_by',
        type: 'profile',
      },
    ],
    [],
  )

  return (
    <DataTable
      {...tableProps}
      {...rest}
      testKey="insurance_eobs_table"
      icon="insurance"
      title="Insurance EOBs"
      columns={columns}
      // filtersConfig={FILTERS.organizations}
      searchBarFilterKey="name"
    />
  )
}
