import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useLocation } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Page, Tabs, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { useSettings } from '@behavehealth/hooks/useSettings'

import ClientAgreementTemplates from './pages/client_agreement_templates'
import DashboardAgreementTemplates from './pages/dashboard_agreement_templates'
import EmployeeAgreementTemplates from './pages/employee_agreement_templates'
import OrganizationAgreementTemplates from './pages/organization_agreement_templates'
import snakeCase from 'lodash/snakeCase'

import ClientAgreementTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/ClientAgreementTemplatesImportOverlay'
import EmployeeAgreementTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/EmployeeAgreementTemplatesImportOverlay'
import OrganizationAgreementTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/OrganizationAgreementTemplatesImportOverlay'

import { ClientAgreementTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/ClientAgreementTemplateOverlay'
import { EmployeeAgreementTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/EmployeeAgreementTemplateOverlay'
import { OrganizationAgreementTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/OrganizationAgreementTemplateOverlay'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'

const TABS_CONFIG: any = {
  clients: {
    label: 'Add Client Template',
    permission: 'settings.agreement_templates.create',
    feature_flag: 'client_agreement_templates',
  },
  employees: {
    label: 'Add Staff Template',
    permission: 'settings.employee_agreement_templates.create',
    feature_flag: 'staff_agreement_templates',
  },
  organizations: {
    label: 'Add Organization Template',
    permission: 'settings.organization_agreement_templates.create',
    feature_flag: 'organization_agreement_templates',
  },
}

const AgreementTemplates = () => {
  const { pathname } = useLocation()
  const { isBehave, isManagement } = useSettings()

  let selectedTab = ''

  if (pathname.includes(`clients`)) selectedTab = 'clients'
  else if (pathname.includes(`employees`)) selectedTab = 'employees'
  else if (pathname.includes(`organizations`)) selectedTab = 'organizations'

  const tabConfig = TABS_CONFIG[selectedTab]

  return (
    <Page
      feature="agreement_templates"
      breakpoint={4}
      help={<HelpTagIframe id="settings_agreement_templates" />}
      actions={
        tabConfig && (
          <>
            {isBehave && (
              <Button
                as={Link}
                label="Import Behave Templates"
                type="default"
                glyph="add"
                link={`${pathname}/import`}
                permission={tabConfig.permission}
                featureFlagV2={tabConfig.feature_flag}
              />
            )}

            {(isBehave || isManagement) && (selectedTab === 'clients' || selectedTab === 'employees') && (
              <Button
                as={Link}
                testKey={`${snakeCase(tabConfig.label)}_button`}
                label="Add Admin Template"
                type="default"
                glyph="lock"
                link={`${pathname}/new`}
                state={{ data: { agreement_type: 'admin' } }}
                permission={tabConfig.permission}
                featureFlagV2={tabConfig.feature_flag}
              />
            )}

            <Button
              as={Link}
              testKey={`${snakeCase(tabConfig.label)}_button`}
              label={tabConfig.label}
              type="primary"
              glyph="add"
              link={`${pathname}/new`}
              permission={tabConfig.permission}
              featureFlagV2={tabConfig.feature_flag}
            />
          </>
        )
      }
    >
      <Tabs defaultTab="support_main_plan">
        <Tabs.List css={styles.tabsList}>
          <Tabs.Item as={NavLink} label="Dashboard" icon="dashboard" to={`dashboard`} />
          <Tabs.Item
            as={NavLink}
            label="Clients"
            icon="clients"
            to={`clients`}
            permission="settings.agreement_templates.view"
            featureFlagV2="client_agreement_templates"
          />
          <Tabs.Item
            as={NavLink}
            label="Staff"
            icon="employees"
            to={`employees`}
            permission="settings.employee_agreement_templates.view"
            featureFlagV2="staff_agreement_templates"
          />
          <Tabs.Item
            as={NavLink}
            label="Organizations"
            icon="organizations"
            to={`organizations`}
            permission="settings.organization_agreement_templates.view"
            featureFlagV2="organization_agreement_templates"
          />
        </Tabs.List>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to="dashboard" replace />} />

        <Route path="dashboard/*" element={<DashboardAgreementTemplates />} />
        <Route
          path="clients/*"
          element={
            <ProtectedRouteV6 permission="settings.agreement_templates.view" featureFlagV2="client_agreement_templates">
              <ClientAgreementTemplates />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="employees/*"
          element={
            <ProtectedRouteV6 permission="settings.employee_agreement_templates.view" featureFlagV2="staff_agreement_templates">
              <EmployeeAgreementTemplates />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="organizations/*"
          element={
            <ProtectedRouteV6 permission="settings.organization_agreement_templates.view" featureFlagV2="organization_agreement_templates">
              <OrganizationAgreementTemplates />
            </ProtectedRouteV6>
          }
        />
      </Routes>

      <AnimatedRoutes>
        <Route path="clients/agreements/import" element={<ClientAgreementTemplatesImportOverlay useV6Router />} />
        <Route path="clients/agreements/:id" element={<ClientAgreementTemplateOverlayV6 useV6Router />} />

        <Route
          path="clients/admin-agreements/import"
          element={isBehave || isManagement ? <ClientAgreementTemplatesImportOverlay useV6Router /> : null}
        />
        <Route
          path="clients/admin-agreements/:id"
          element={isBehave || isManagement ? <ClientAgreementTemplateOverlayV6 useV6Router /> : null}
        />

        <Route path="employees/agreements/import" element={<EmployeeAgreementTemplatesImportOverlay useV6Router />} />
        <Route path="employees/agreements/:id" element={<EmployeeAgreementTemplateOverlayV6 useV6Router />} />

        <Route
          path="employees/admin-agreements/import"
          element={isBehave || isManagement ? <EmployeeAgreementTemplatesImportOverlay useV6Router /> : null}
        />

        <Route
          path="employees/admin-agreements/:id"
          element={isBehave || isManagement ? <EmployeeAgreementTemplateOverlayV6 useV6Router /> : null}
        />

        <Route path="organizations/import" element={<OrganizationAgreementTemplatesImportOverlay useV6Router />} />
        <Route path="organizations/:id" element={<OrganizationAgreementTemplateOverlayV6 useV6Router />} />
      </AnimatedRoutes>
    </Page>
  )
}

const styles = {
  tabsList: {
    marginTop: '-0.75rem',
    marginBottom: '1.25rem',
  },
}

export default withPageError(AgreementTemplates)
