import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Switch, Route, Redirect } from 'react-router-dom'

import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'

import { RosterClaimBuilderOverlay } from '@behavehealth/constructs/RCM/RosterClaimBuilderOverlay'
import { RosterNotesOverlay } from '@behavehealth/constructs/RCM/RosterNotesOverlay'
import { RosterServiceStatusOverlay } from '@behavehealth/constructs/RCM/RosterServiceStatusOverlay'

import RosterAll from './roster_all'
import RosterNew from './roster_new'
import RosterFlagged from './roster_flagged'
import RosterProcessed from './roster_processed'
import RosterArchived from './roster_archived'

export const Roster = () => {
  const match = useRouteMatch()

  const [selectedRosterItems, setSelectedRosterItems]: any = React.useState([])

  const [isClaimOverlayOpen, setIsClaimOverlayOpen] = React.useState(false)
  const [isNotesOverlayOpen, setIsNotesOverlayOpen] = React.useState(false)
  const [isStatusOverlayOpen, setIsStatusOverlayOpen] = React.useState(false)

  return (
    <>
      <Tabs defaultTab="draft">
        <Tabs.List className="px-4">
          <Tabs.Item label="All Items" to={`${match.url}/all`} />
          <Tabs.Item label="New" to={`${match.url}/new`} />
          <Tabs.Item label="Flagged" to={`${match.url}/flagged`} />
          <Tabs.Item label="Processed" to={`${match.url}/processed`} />
          <Tabs.Item label="Archived" to={`${match.url}/archived`} />
        </Tabs.List>
      </Tabs>

      <div className="p-4 grid grid-cols-[100%]">
        <Switch>
          <Route
            path={`${match.url}/all`}
            children={
              <RosterAll
                onItemSelect={setSelectedRosterItems}
                onClaimOpen={setIsClaimOverlayOpen}
                onNotesOpen={setIsNotesOverlayOpen}
                onStatusOpen={setIsStatusOverlayOpen}
              />
            }
          />
          <Route
            path={`${match.url}/new`}
            children={
              <RosterNew
                onItemSelect={setSelectedRosterItems}
                onClaimOpen={setIsClaimOverlayOpen}
                onNotesOpen={setIsNotesOverlayOpen}
                onStatusOpen={setIsStatusOverlayOpen}
              />
            }
          />
          <Route
            path={`${match.url}/flagged`}
            children={
              <RosterFlagged
                onItemSelect={setSelectedRosterItems}
                onClaimOpen={setIsClaimOverlayOpen}
                onNotesOpen={setIsNotesOverlayOpen}
                onStatusOpen={setIsStatusOverlayOpen}
              />
            }
          />
          <Route
            path={`${match.url}/processed`}
            children={
              <RosterProcessed
                onItemSelect={setSelectedRosterItems}
                onClaimOpen={setIsClaimOverlayOpen}
                onNotesOpen={setIsNotesOverlayOpen}
                onStatusOpen={setIsStatusOverlayOpen}
              />
            }
          />
          <Route
            path={`${match.url}/archived`}
            children={
              <RosterArchived
                onItemSelect={setSelectedRosterItems}
                onClaimOpen={setIsClaimOverlayOpen}
                onNotesOpen={setIsNotesOverlayOpen}
                onStatusOpen={setIsStatusOverlayOpen}
              />
            }
          />

          <Redirect exact from={match.url} to={`${match.url}/new`} />
        </Switch>
      </div>

      <SummonOverlay
        isOpen={isStatusOverlayOpen}
        onClose={() => {
          setSelectedRosterItems([])
          setIsStatusOverlayOpen(false)
        }}
        overlay={<RosterServiceStatusOverlay selectedRosterItems={selectedRosterItems} />}
      />

      <SummonOverlay
        isOpen={isNotesOverlayOpen}
        onClose={() => {
          setSelectedRosterItems([])
          setIsNotesOverlayOpen(false)
        }}
        overlay={<RosterNotesOverlay selectedRosterItems={selectedRosterItems} />}
      />

      <SummonOverlay
        isOpen={isClaimOverlayOpen}
        onClose={() => {
          setSelectedRosterItems([])
          setIsClaimOverlayOpen(false)
        }}
        overlay={<RosterClaimBuilderOverlay selectedRosterItems={selectedRosterItems} />}
      />
    </>
  )
}
