import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS } from '../../../../theme'
import { FILE_TYPES } from '../../../../utils/constants'

import CardLink from '../../../../components/CardLink'
import CardSubtitle from '../../../../components/CardSubtitle'
import CardTitle from '../../../../components/CardTitle'
import Grid from '../../../../components/Grid'
import Icon from '../../../../components/Icon'
import Label from '../../../../components/Label'
import QuickView from '../../../../components/QuickView'
import State from '../../../../components/State'

import DropdownItem from '../../../../components/DropdownItem'

export const Documents = React.forwardRef((props: any, ref) => {
  const { isEditable, children, element, className, hoverElement } = props

  if (!element?.config) return null

  const { documents = [] } = element
  const { label, description, tooltip } = element.config

  const rootClasses = clsx('DOCUMENTS', className)

  const isEmpty = size(documents) === 0

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}
      {label && <Label label={label} tooltip={tooltip} />}
      {description && <div css={STYLES.description}>{description}</div>}

      {isEditable && isEmpty && (
        <div>
          <State
            isEmpty
            glyph="add_file"
            title="Documents"
            emptyDescription="Use the editor to upload documents and display them in this form"
            minHeight={150}
          />
        </div>
      )}

      {!isEmpty && (
        <Grid gap="0.35rem" css={STYLES.grid}>
          {documents.map((document: any) => {
            const fileType = FILE_TYPES?.[document.content_type]?.name || 'Other'

            return (
              <QuickView key={document.external_id} record={{ ...document, url: document.url }}>
                {({ open }) => (
                  <CardLink
                    baseline="2.5rem"
                    paddingY="0.1rem"
                    graphic={<Icon icon="files" size={18} />}
                    onClick={open}
                    actions={
                      <>
                        <DropdownItem label="View File" glyph="view" color="blue" target="_blank" href={document.url} />
                        <DropdownItem label="Download" glyph="download" color="blue" target="_blank" href={document.download} />
                      </>
                    }
                  >
                    <CardTitle title={document.title} css={STYLES.title} />
                    <CardSubtitle subtitle={fileType} css={STYLES.subtitle} />
                  </CardLink>
                )}
              </QuickView>
            )
          })}
        </Grid>
      )}
    </div>
  )
})

const STYLES = {
  description: {
    color: COLORS.textMuted,
    fontSize: '0.95rem',
  },

  grid: {
    marginTop: '0.5rem',
  },

  title: {
    fontSize: '0.95rem',
  },

  subtitle: {
    fontSize: '0.9rem',
  },

  after: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
