import React from 'react'

import ContextShow from '../../../../Forms/ContextShow'
import DateInput from '../../../../Forms/DateInput'
import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const RecurringRecoveryEnvironment = () => {
  return (
    <FormSection layout="vertical">
      <RadioGroup
        label="Has client had any family sessions?"
        model="data.recovery_environment.has_had_family_sessions"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.recovery_environment.has_had_family_sessions" is={true}>
        <SmartTextarea
          useDictation
          label="List date, family members attended and whether in person or by phone:"
          model="data.recovery_environment.family_members_attending_sessions"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <ContextShow when="data.recovery_environment.has_had_family_sessions" is={false}>
        <SmartTextarea
          useDictation
          label="Why not?"
          model="data.recovery_environment.family_members_not_attending_reason"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <SmartTextarea useDictation label="Where is client currently living?" model="data.recovery_environment.current_living_location" />
      <DateInput label="Anticipated Discharge Date:" model="data.recovery_environment.anticipated_discharge_date" />
      <SmartTextarea useDictation label="Notes" model="data.recovery_environment.notes" />
    </FormSection>
  )
}

export default RecurringRecoveryEnvironment
