import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { InsuranceClaimTasksReportDataTable } from '@behavehealth/constructs/LiveReports/InsuranceClaimTasksReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const InsuranceClaimTasks = () => {
  const tableProps = useDataTable({
    name: ['reports', 'insurance-claim-tasks'],
    endpoint: `/live_reports/insurance_claim_tasks`,
    localStorageKey: 'report_insurance_claim_tasks_v1',
  })

  const to = React.useMemo(() => (rowData: any) => getResourceLink(rowData.insurance_claim?.resident), [])

  return (
    <Page feature="insurance" title="Live Claim Tasks Report">
      <Grid>
        <InsuranceClaimTasksReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(InsuranceClaimTasks)
