import React from 'react'
import clsx from 'clsx'

import { Color } from '@tiptap/extension-color'
import { Node } from '@tiptap/core'
import { useEditor, EditorContent } from '@tiptap/react'
import Heading from '@tiptap/extension-heading'
import Highlight from '@tiptap/extension-highlight'
import Paragraph from '@tiptap/extension-paragraph'
import Strike from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'

import Bold from '@tiptap/extension-bold'
import History from '@tiptap/extension-history'
import Italic from '@tiptap/extension-italic'

import { COLORS as BH_COLORS } from '../../../../theme'
import { RichTextToolbar } from '../../components/RichTextToolbar'
import Glyph from '../../../../components/Glyph'

import Portal from '../../../../components/Portal'
import Markup from '../../../../components/Markup'

import { Box } from './Box'

const OneLiner = Node.create({
  name: 'oneLiner',
  topNode: true,
  content: 'block',
})

const TOOLBAR = ['headings', 'paragraph', 'inline', 'color', 'highlight']

export const Accordion = React.forwardRef((props: any, ref) => {
  const { children, className, element, hoverElement, editElementConfig, isEditable, isPreviewMode } = props

  const [isOpen, setIsOpen] = React.useState(!!element?.config?.is_default_open)

  const isActive = element?._isActive

  const toggleIsOpen = () => {
    setIsOpen((c) => !c)
  }

  const handleUpdate = ({ editor }) => {
    editElementConfig({
      uuid: element.uuid,
      config: { title: editor.getHTML() },
    })
  }

  const editor = useEditor(
    {
      content: element?.config?.title || 'Toggle Content',
      editable: isEditable,
      onUpdate: handleUpdate,
      extensions: [OneLiner, Text, TextStyle, Heading, Paragraph, Color, Highlight, Strike, Underline, Bold, Italic, History],
    },
    [isEditable],
  )

  React.useEffect(() => {
    setIsOpen(!!element?.config?.is_default_open)
  }, [element?.config?.is_default_open])

  if (!element || !element.config) return null

  const { is_default_open } = element.config

  const rootClasses = clsx('ACCORDION', isOpen && 'is-open', className)
  const headerClasses = clsx(isOpen && 'is-open')

  const contentClasses = clsx('accordion-content', isEditable ? 'gap-1' : 'gap-4')

  return (
    <>
      <Portal type="rich-text-toolbar">{isActive && <RichTextToolbar editor={editor} toolbar={TOOLBAR} css={STYLES.toolbar} />}</Portal>

      <Box ref={ref} element={element} className={rootClasses} css={STYLES.root} hoverElement={hoverElement}>
        <header className={headerClasses} css={STYLES.header}>
          <button type="button" data-test="toggle_accordion_button" css={STYLES.toggleButton} onClick={toggleIsOpen}>
            <Glyph glyph="triangle_down" size={14} className="accordion-toggle-glyph" />
          </button>

          {isPreviewMode || !isEditable ? (
            <Markup css={STYLES.headerTitle} value={element?.config?.parsed?.title || element?.config?.title} />
          ) : (
            <EditorContent editor={editor} css={STYLES.headerTitle} />
          )}
        </header>

        <div className={contentClasses}>{children}</div>
      </Box>
    </>
  )
})

const STYLES = {
  root: {
    position: 'relative',

    '--accordion-baseline': '1.4rem',
    '--accordion-baseline-padding': '0.3rem',

    marginLeft: 'calc(var(--accordion-baseline-padding) * -1)',

    '.accordion-content': {
      // using visibility hidden instead of display none, otherwise
      // SignaturePad doesn't render correctly when opening the toggle
      visibility: 'hidden',
      height: 0,
      paddingLeft: 'calc(var(--accordion-baseline) + var(--accordion-baseline-padding))',
      overflow: 'hidden',
    },

    '&.is-open': {
      display: 'grid',

      '& > .accordion-content': {
        display: 'grid',
        visibility: 'visible',
        height: 'auto',
        overflow: 'visible',
      },
    },
  },

  toolbar: {
    position: 'absolute',
    left: '50%',
    transform: 'translate3d(-50%, 0, 0)',
    zIndex: 10,
    top: '0.5rem',
  },

  toggleButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    borderRadius: 4,
    width: 'var(--accordion-baseline)',
    height: 'var(--accordion-baseline)',
    marginRight: 'var(--accordion-baseline-padding)',
    position: 'relative',

    '&:hover': {
      background: BH_COLORS.hover,
    },

    // increase hit area
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
      width: 30,
      height: 30,
    },
  },

  header: {
    display: 'flex',
    alignItems: 'center',

    '&.is-open': {
      '.accordion-toggle-glyph': {
        transform: 'rotate3d(1, 1, 1, 0)',
      },
    },

    '.accordion-toggle-glyph': {
      transform: 'rotate3d(0, 0, 1, -90deg)',
    },
  },

  headerTitle: {
    cursor: 'text',
    flex: '1 1 auto',

    '.ProseMirror': {
      outline: 'none',

      '& > *': {
        margin: '0 !important',
      },

      '& > p': {
        fontWeight: 600,
      },
    },
  },
}
