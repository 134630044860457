import React from 'react'
import { useMedia } from 'use-media'

import withSettings from '../../hocs/withSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

const mainColumn = (to: any) => ({
  width: 240,
  isSticky: true,
  canToggleVisible: false,
  accessor: 'name',
  Header: 'Name',
  Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} avatar={row.original.avatar} />,
})

const columns = (to: Function = () => {}, isDesktop: boolean) => [
  ...(isDesktop ? [mainColumn(to)] : []),

  {
    width: 160,
    accessor: 'title',
    Header: 'Title',
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 160,
    accessor: 'email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
]

const CommunityEmployeesTable = (props: any) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    batchActionsConfig,
    title,
    to,
    timezone,
    user,
    tenant,
    hiddenColumns,
    titleAfter,
    apiKey,
    testKey,
    showFilters,
    ...rest
  } = props

  const isDesktop = useMedia({ minWidth: 600 })

  return (
    <Table
      testKey={testKey}
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, isDesktop)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

CommunityEmployeesTable.defaultProps = {
  title: 'Staff',
  icon: 'employees',
  emptyDescription: 'No staff added yet',
  localStorageKey: 'employees',
}

export default withSettings(CommunityEmployeesTable)
