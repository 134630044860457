import React from 'react'
import { darken, transparentize } from 'polished'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../../theme'

import Button from '../Button'
import MarketingStar from './MarketingStar'

const VARIANTS: any = {
  white: {
    starVariant: 'gold',
    glyphColor: '#ff9606',
  },
  gold: {
    starVariant: 'white',
    glyphColor: '#ff9606',
  },
  violet: {
    starVariant: 'white',
    glyphColor: COLORS.violet,
  },
}

type Props = {
  className?: string
  description: string
  layout: string
  showButton: boolean
  title: string
  glyph?: string
  buttonLabel?: string
  buttonGlyph?: string
  variant: 'white' | 'gold' | 'violet'
  onClick?: Function
}

const MarketingCard = ({
  className,
  description,
  layout,
  showButton,
  title,
  variant,
  glyph,
  buttonLabel = 'Contact Us Now',
  onClick,
}: Props) => {
  const classNames = clsx(className, {
    [`layout-${layout}`]: layout,
    [`variant-${variant}`]: variant,
  })

  const isVertical = layout === 'vertical'

  return (
    <div css={styles.root} className={classNames} onClick={onClick}>
      <MarketingStar
        glyph={glyph}
        size={isVertical ? '3rem' : '2rem'}
        hasLink={false}
        css={styles.graphic}
        className="marketing-graphic"
        variant={VARIANTS[variant].starVariant}
      />

      <div>
        {title && <h3 className="marketing-title">{title}</h3>}

        {description && <p className="marketing-description">{description}</p>}

        {showButton && (
          <Button rounded type="primary" color="green" label={buttonLabel} css={styles.button} size={isVertical ? 300 : 200} />
        )}
      </div>
    </div>
  )
}

const styles: any = {
  root: {
    display: 'block',
    borderRadius: 7,

    '&.layout-vertical': {
      marginTop: '2.5rem',
      padding: '0 3rem 1.75rem',
      textAlign: 'center',

      '.marketing-graphic': {
        marginTop: '-1.5rem',
        marginBottom: '1rem',
      },

      '.marketing-title': {
        margin: 0,
        fontSize: '1.4rem',
      },

      '.marketing-description': {
        maxWidth: 380,
        margin: '0.5em auto',
        lineHeight: 1.5,
      },
    },

    '&.layout-horizontal': {
      display: 'flex',
      padding: '0.75rem 1rem',
      alignItems: 'center',

      '.marketing-graphic': {
        marginRight: '1rem',
      },

      '.marketing-title': {
        margin: 0,
        fontSize: '1.1rem',
      },

      '.marketing-description': {
        fontSize: '0.95rem',
        margin: '0.15em 0 0',
        lineHeight: 1.5,
      },
    },

    '&.variant-white': {
      color: COLORS.text,
      background: COLORS.white,
      boxShadow: SHADOW(2, transparentize(0.95, COLORS.yellow)),
    },

    '&.variant-gold': {
      color: darken(0.1, COLORS.text),
      background: 'linear-gradient(30deg, #ffd861, #ffc459)',
      boxShadow: SHADOW(2, transparentize(0.85, COLORS.yellow)),
      textShadow: `0 1px 10px ${transparentize(0.75, COLORS.white)}`,
    },

    '&.variant-violet': {
      color: COLORS.white,
      background: `linear-gradient(30deg, ${darken(0.1, COLORS.purple)}, ${COLORS.violet})`,
      boxShadow: SHADOW(2, transparentize(0.9, COLORS.purple)),
      textShadow: `0 1px 10px ${transparentize(0.5, COLORS.violet)}`,
    },
  },

  button: {
    display: 'inline-flex',
    textShadow: 'none',
    marginTop: '0.5em',
  },
}

MarketingCard.defaultProps = {
  layout: 'horizontal',
  showButton: true,
  variant: 'gold',
}

export default MarketingCard
