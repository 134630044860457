import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  pending: {
    label: 'Pending Review',
    color: 'yellow',
  },
  published: {
    label: 'Published',
    color: 'green',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
