import React from 'react'

import { DEFAULT_EMPTY_VALUE } from '../../../utils/constants'
import { DT } from '../../../utils/functions'

const OpeningTime: React.FC = ({ open_time, close_time, timezone }) => {
  if (!open_time || !close_time) return DEFAULT_EMPTY_VALUE

  return `${DT(open_time, timezone).toFormat('hh:mm a')} - ${DT(close_time, timezone).toFormat('hh:mm a')}`
}

export default OpeningTime
