import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { apiCreate } from '../../../modules/api'
import { ICONS } from '../../../theme'
import { withOverlayError } from '../../../hocs/withOverlayError'

import Button from '../../Button'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Select from '../../Forms/Select'
import Option from '../../Forms/Option'
import Textarea from '../../Forms/Textarea'
import Overlay from '../../Overlay'
import Section from '../../Section'

const NewRule = ({ history, location }: any) => {
  const match: any = useRouteMatch()
  const [valid, setValid] = React.useState(false)
  const loading = useSelector((state: any) => state.data?.insurance_global_payer_rules?.loading)
  const payer = useSelector((state) => state.data?.insurance_local_payers?.data?.[match?.params?.resource_id])
  const form = React.useRef()

  const onSave = async () => {
    await apiCreate({
      name: 'insurance_global_payer_rules',
      url: '/insurance_global_payer_rules',
      params: {
        ...form.current.getFormValue(),
        insurance_global_payer_id: payer?.insurance_global_payer?.id,
      },
    })

    history.goBack()
  }

  return (
    <Overlay>
      <Overlay.Header icon={ICONS.licence} title="Add New Rule" />

      <Overlay.Content>
        <Form getForm={form} initialModel={location?.data} onValidationUpdate={setValid}>
          <Section>
            <FormSection>
              <Input
                label="Rule Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a rule name',
                  },
                }}
              />

              <Select allowEmpty label="Rule Category" model="category">
                <Option label="Insurance" value="insurance" />
                <Option label="Pre Cert" value="pre_cert" />
                <Option label="Authorizations" value="authorizations" />
                <Option label="Claims" value="claims" />
              </Select>

              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" glyph="check" type="primary" color="green" isLoading={loading} onClick={onSave} isDisabled={!valid} />
      </Overlay.Footer>
    </Overlay>
  )
}

export default withOverlayError(NewRule)
