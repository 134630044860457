import React from 'react'
import size from 'lodash/size'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Flex from '../../../components/Flex'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Markup from '../../../components/Markup'
import MiniRichTextEditor from '../../../components/Forms/MiniRichTextEditor'
import Overlay from '../../../components/Overlay'
import Section from '../../../components/Section'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Text from '../../../components/Typography/Text'

import { ReferralColumn } from './ReferralColumn'

export const ReferralMessages = () => {
  const isEmpty = size(DATA) === 0

  const action = (
    <SummonOverlay overlay={<MessageOverlay />}>
      <Button label="Send Message" glyph="chat" type="primary" />
    </SummonOverlay>
  )

  return (
    <ReferralColumn
      icon="community_invites"
      title="Messages"
      className="!grow-0 shrink-0 basis-[400px] border-l border-solid border-0 border-divider bg-white bg-opacity-40 animate-slideInFromRight"
      headerAfter={!isEmpty && action}
    >
      {isEmpty ? (
        <State isEmpty icon="community_invites" title="Messages" emptyDescription="No messages sent yet" emptyActions={action} />
      ) : (
        <div className="grid gap-3">
          {DATA.map((o: any) => (
            <Card key={o.id} className="px-3 py-2">
              <Markup value={o.message} />

              <Flex gap="0.5rem">
                <Text glyph="date" description="11-01-2023" />
                <Text glyph="user_neutral" description="John Doe" />
              </Flex>
            </Card>
          ))}
        </div>
      )}
    </ReferralColumn>
  )
}

const MessageOverlay = (props: any) => {
  const { onClose } = props

  const form = React.useRef(null)

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={props.onClose}>
      <Overlay.Header title={`Send Message`} icon="community_invites" />

      <Overlay.Content>
        <Form isEditable getForm={form}>
          <Section>
            <FormSection>
              <MiniRichTextEditor label="Message" model="message" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Send Message"
          type="primary"
          color="green"
          glyph="check"
          onClick={() => {
            console.log(form.current.getFormValue())
            onClose?.()
          }}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const DATA = [
  {
    id: '1',
    message:
      '<p>Hello, please can you <strong><span style="color: #0F9913">confirm availability</span></strong> for this referral <strong><span style="color: #F23E2E">ASAP</span></strong>?</p>',
    author: {
      name: 'Oliver',
    },
  },
  {
    id: '2',
    message: '<p>Hi, I have already <strong><span style="color: #0F9913">confirmed availability</span></strong> for this referral<strong>.',
    author: {
      name: 'John',
    },
  },
]
