import React from 'react'

import { withFormContext } from '../../../../components/Forms/context'

import Button from '../../../../components/Button'
import { ComboBox } from '../../../../components/Forms/ComboBoxes/ComboBox'

import { SectionCard } from '../../../../components/SectionCard'

const getDescription = (provider: any) => {
	if (!provider) return null

	const { level, license_number, employee_identifier } = provider

	if (level === 'individual') return `Individual • NPI: ${license_number} • Employee ID: ${employee_identifier}`
	if (level === 'organization') return `Organization • NPI: ${license_number}`
}

const ClaimInstitutionalProviders = ({ className, tenant }: any) => {
	const [facilityProvider, setFacilityProvider] = React.useState(null)
	const [billingProvider, setBillingProvider] = React.useState(null)
	const [renderingProvider, setRenderingProvider] = React.useState(null)
	const [attendingProvider, setAttendingProvider] = React.useState(null)
	const [referringProvider, setReferringProvider] = React.useState(null)

	return (
		<>
			<SectionCard
				title="Providers"
				className={className}
				aside={
					<Button
						label="Providers Settings"
						size={100}
						type="link"
						glyph="settings"
						link="/settings/providers"
						target="_blank"
					/>
				}
			>
				<div className="grid gap-4 grid-cols-1 mq600:grid-cols-2 mq900:grid-cols-3 mq1200:grid-cols-4 mq1400:grid-cols-6">
					<div>
						<ComboBox
							label="Facility"
							model="facility_provider"
							icon="staff_credentials"
							type="company.organization_providers"
							dependentValue={tenant?.id}
							selectTitle={(data: any) => `${data?.credential} (${data?.license_number})`}
							selectDescription={(data: any) => data?.organization}
							maxWidth="100%"
							onUpdate={(value: any) => {
								setFacilityProvider(value)
							}}
							tooltip="This is the provider who is responsible for the facility where the patient was treated. Can be an Individual or an Organisation"
						/>
						<div className="text-sm text-text-muted mt-1">{getDescription(facilityProvider)}</div>
					</div>

					<div>
						<ComboBox
							label="Billing Provider"
							model="billing_provider"
							icon="staff_credentials"
							type="company.providers"
							dependentValue={tenant?.id}
							selectTitle={(data: any) => `${data?.credential} (${data?.license_number})`}
							selectDescription={(data: any) =>
								data?.level === 'organization' ? data?.organization : `${data?.first_name} ${data?.last_name}`
							}
							maxWidth="100%"
							value={billingProvider}
							onUpdate={(value: any) => {
								setBillingProvider(value)
							}}
							tooltip="This is the provider who is responsible for the billing of the claim. Can be an Individual or an Organisation"
						/>
						<div className="text-sm text-text-muted mt-1">{getDescription(billingProvider)}</div>
					</div>

					<div>
						<ComboBox
							label="Operating Physician"
							model="rendering_provider"
							icon="staff_credentials"
							type="company.providers"
							dependentValue={tenant?.id}
							selectTitle={(data: any) => `${data?.credential} (${data?.license_number})`}
							selectDescription={(data: any) =>
								data?.level === 'organization' ? data?.organization : `${data?.first_name} ${data?.last_name}`
							}
							maxWidth="100%"
							value={renderingProvider}
							onUpdate={(value: any) => {
								setRenderingProvider(value)
							}}
							tooltip="This is the provider who is responsible for the care of the patient during the visit. Can be an Individual or an Organisation"
						/>
						<div className="text-sm text-text-muted mt-1">{getDescription(renderingProvider)}</div>
					</div>

					<div>
						<ComboBox
							label="Attending Provider"
							model="attending_provider"
							icon="staff_credentials"
							type="company.individual_providers"
							dependentValue={tenant?.id}
							selectTitle={(data: any) => `${data?.credential} (${data?.license_number})`}
							selectDescription={(data: any) => `${data?.first_name} ${data?.last_name}`}
							maxWidth="100%"
							value={attendingProvider}
							onUpdate={(value: any) => {
								setAttendingProvider(value)
							}}
							tooltip="This is the provider who is responsible for the care of the patient during the visit. Must be an Individual"
						/>
						<div className="text-sm text-text-muted mt-1">{getDescription(attendingProvider)}</div>
					</div>

					<div>
						<ComboBox
							label="Referring Provider"
							model="referring_provider"
							icon="staff_credentials"
							type="company.individual_providers"
							dependentValue={tenant?.id}
							selectTitle={(data: any) => `${data?.credential} (${data?.license_number})`}
							selectDescription={(data: any) => `${data?.first_name} ${data?.last_name}`}
							maxWidth="100%"
							value={referringProvider}
							onUpdate={(value: any) => {
								setReferringProvider(value)
							}}
							tooltip="This is the provider who referred the patient to the facility. Must be an Individual"
						/>
						<div className="text-sm text-text-muted mt-1">{getDescription(referringProvider)}</div>
					</div>
				</div>
			</SectionCard>
		</>
	)
}

export default withFormContext(ClaimInstitutionalProviders)
