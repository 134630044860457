import React from 'react'

import ContextShow from '../../../../Forms/ContextShow'
import FormSection from '../../../../Forms/FormSection'
import Option from '../../../../Forms/Option'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Select from '../../../../Forms/Select'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const SuicideSafetyRisk = () => {
  return (
    <FormSection layout="vertical">
      <RadioGroup model="data.suicide_safety_risk.is_secretive" label="Does the client appear secretive?" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.suicide_safety_risk.is_secretive" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.suicide_safety_risk.is_secretive_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup model="data.suicide_safety_risk.is_grandiose" label="Does the client appear grandiose?" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.suicide_safety_risk.is_grandiose" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.suicide_safety_risk.is_grandiose_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.suicide_safety_risk.has_homicidal_ideation"
        label="Is the client experiencing any homicidal ideation?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.suicide_safety_risk.has_homicidal_ideation" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.suicide_safety_risk.has_homicidal_ideation_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.suicide_safety_risk.is_feeling_hopeless"
        label="Does the client feel hopeless and worthless at times?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.suicide_safety_risk.is_feeling_hopeless" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.suicide_safety_risk.is_feeling_hopeless_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        label="Is the client experiencing racing thoughts?"
        model="data.suicide_safety_risk.has_racing_thoughts"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.suicide_safety_risk.has_racing_thoughts" is={true}>
        <Select label="To what degree? (0 absent - 10 severe)" model="data.suicide_safety_risk.has_racing_thoughts_degree">
          <Option label="0" value={0} />
          <Option label="1" value={1} />
          <Option label="2" value={2} />
          <Option label="3" value={3} />
          <Option label="4" value={4} />
          <Option label="5" value={5} />
          <Option label="6" value={6} />
          <Option label="7" value={7} />
          <Option label="8" value={8} />
          <Option label="9" value={9} />
          <Option label="10" value={10} />
        </Select>
      </ContextShow>

      <RadioGroup
        label="Is the client experiencing physical pain?"
        model="data.suicide_safety_risk.has_physical_pain"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.suicide_safety_risk.has_physical_pain" is={true}>
        <Select label="To what degree? (0 absent - 10 severe)" model="data.suicide_safety_risk.has_physical_pain_degree">
          <Option label="0" value={0} />
          <Option label="1" value={1} />
          <Option label="2" value={2} />
          <Option label="3" value={3} />
          <Option label="4" value={4} />
          <Option label="5" value={5} />
          <Option label="6" value={6} />
          <Option label="7" value={7} />
          <Option label="8" value={8} />
          <Option label="9" value={9} />
          <Option label="10" value={10} />
        </Select>
      </ContextShow>

      <RadioGroup
        label="Is the client experiencing negative self-talk?"
        model="data.suicide_safety_risk.has_negative_self_talk"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.suicide_safety_risk.has_negative_self_talk" is={true}>
        <Select label="To what degree? (0 absent - 10 severe)" model="data.suicide_safety_risk.has_negative_self_talk_degree">
          <Option label="0" value={0} />
          <Option label="1" value={1} />
          <Option label="2" value={2} />
          <Option label="3" value={3} />
          <Option label="4" value={4} />
          <Option label="5" value={5} />
          <Option label="6" value={6} />
          <Option label="7" value={7} />
          <Option label="8" value={8} />
          <Option label="9" value={9} />
          <Option label="10" value={10} />
        </Select>
      </ContextShow>

      <RadioGroup
        label="Is the client experiencing suicidal thoughts?"
        model="data.suicide_safety_risk.has_suicidal_thoughts"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.suicide_safety_risk.has_suicidal_thoughts" is={true}>
        <Select label="To what degree? (0 absent - 10 severe)" model="data.suicide_safety_risk.has_suicidal_thoughts_degree">
          <Option label="0" value={0} />
          <Option label="1" value={1} />
          <Option label="2" value={2} />
          <Option label="3" value={3} />
          <Option label="4" value={4} />
          <Option label="5" value={5} />
          <Option label="6" value={6} />
          <Option label="7" value={7} />
          <Option label="8" value={8} />
          <Option label="9" value={9} />
          <Option label="10" value={10} />
        </Select>
      </ContextShow>

      <SmartTextarea useDictation label="Notes" model="data.suicide_safety_risk.notes" />
    </FormSection>
  )
}

export default SuicideSafetyRisk
