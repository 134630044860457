import React from 'react'

import {
  RailzVisualizations,
  RailzBankAccounts,
  RailzIncomeStatements,
  RailzStatementsChart,
  RailzFinancialRatios,
} from '@railzai/railz-visualizations-react'
import { RVReportTypes, RVFilterIncomeStatementsType, RVReportFrequency } from '@railzai/railz-visualizations'

import Grid from '../../components/Grid'
import Card from '../../components/Card'

const isDevelopment = process.env.NODE_ENV === 'development'

export const Hub = ({ token, subdomain, from, until }: any) => {
  const config = {
    token: token,
  }

  const filter = {
    startDate: from,
    endDate: until,
    businessName: subdomain,
    serviceName: isDevelopment ? 'sandbox' : 'quickbooks',
  }

  return (
    <>
      {/* Invoices */}
      <Grid columns="1fr 1fr 1fr" gap="1rem" columnMinWidth="280px">
        <Card>
          <RailzVisualizations
            configuration={config}
            filter={{
              ...filter,
              reportType: RVReportTypes.INVOICES,
            }}
          />
        </Card>

        {/* Bills */}
        <Card>
          <RailzVisualizations
            configuration={config}
            filter={{
              ...filter,
              reportType: RVReportTypes.BILLS,
            }}
          />
        </Card>

        {/* Bank Accounts */}
        <Card>
          <RailzBankAccounts
            configuration={config}
            filter={{
              ...filter,
              reportType: RVReportTypes.BANK_ACCOUNT,
            }}
          />
        </Card>
      </Grid>

      <Grid columns="1fr 1fr" gap="1rem" columnMinWidth="280px">
        {/* Expenses */}
        <Card>
          <RailzIncomeStatements
            configuration={config}
            filter={{
              ...(filter as RVFilterIncomeStatementsType),
              reportType: RVReportTypes.EXPENSES,
              reportFrequency: RVReportFrequency.MONTH,
            }}
          />
        </Card>

        {/* Revenue */}
        <Card>
          <RailzIncomeStatements
            configuration={config}
            filter={{
              ...(filter as RVFilterIncomeStatementsType),
              reportType: RVReportTypes.REVENUE,
              reportFrequency: RVReportFrequency.MONTH,
            }}
          />
        </Card>

        {/* Railz Score */}
        {/* <Card>
          <RailzGaugeChart
            configuration={config}
            filter={{
              ...filter,
              reportType: RVReportTypes.RAILZ_SCORE,
              reportFrequency: RVReportFrequency.MONTH,
            }}
            options={{
              title: {
                text: 'Behave Score',
              },
            }}
          />
        </Card> */}
      </Grid>

      <Grid columns="1fr 1fr" gap="1rem" columnMinWidth="280px">
        {/* Cashflow Statements */}
        <Card>
          <RailzStatementsChart
            configuration={config}
            filter={{
              ...filter,
              reportType: RVReportTypes.CASHFLOW_STATEMENTS,
              reportFrequency: RVReportFrequency.MONTH,
            }}
          />
        </Card>

        {/* Financial Ratios */}
        <Card>
          <RailzFinancialRatios
            configuration={config}
            filter={{
              ...filter,
              reportType: RVReportTypes.FINANCIAL_RATIO,
              reportFrequency: RVReportFrequency.MONTH,
            }}
          />
        </Card>
      </Grid>

      <Grid columns="1fr 1fr" gap="1rem" columnMinWidth="280px">
        {/* Balance Sheet */}
        <Card>
          <RailzStatementsChart
            configuration={config}
            filter={{
              ...filter,
              reportType: RVReportTypes.BALANCE_SHEET,
              reportFrequency: RVReportFrequency.MONTH,
            }}
          />
        </Card>

        {/* Income Statements */}
        <Card>
          <RailzStatementsChart
            configuration={config}
            filter={{
              ...filter,
              reportType: RVReportTypes.INCOME_STATEMENTS,
              reportFrequency: RVReportFrequency.MONTH,
            }}
          />
        </Card>
      </Grid>
    </>
  )
}
