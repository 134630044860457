import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import Button from '@behavehealth/components/Button'
import Tabs from '@behavehealth/components/Tabs'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

const CLAIM_TYPES = {
  professional: 'Professional',
  institutional: 'Institutional',
}

const CLAIM_TEMPLATE_FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  identifier: {
    label: 'Identifier',
    type: 'string',
  },
  category: {
    label: 'Category',
    type: 'string',
  },
  condition_codes: {
    label: 'Condition Codes',
    type: 'string',
  },
  bill_type: {
    label: 'Bill Type',
    type: 'string',
  },
  claim_filing_code: {
    label: 'Claim Filing Code',
    type: 'string',
  },
  claim_frequency_code: {
    label: 'Claim Frequency Code',
    type: 'string',
  },
  place_of_service_code: {
    label: 'Place Of Service Code',
    type: 'string',
  },
  patient_status_code: {
    label: 'Patient Status Code',
    type: 'string',
  },
  admission_type_code: {
    label: 'Admission Type Code',
    type: 'string',
  },
  admission_source_code: {
    label: 'Admission Source Code',
    type: 'string',
  },
  billing_provider: {
    label: 'Billing Provider',
    type: 'string',
  },
  attending_provider: {
    label: 'Attending Provider',
    type: 'string',
  },
  rendering_provider: {
    label: 'Attending Provider',
    type: 'string',
  },
  ordering_provider: {
    label: 'Ordering Provider',
    type: 'string',
  },
  referring_provider: {
    label: 'Referring Provider',
    type: 'string',
  },
  supervising_provider: {
    label: 'Supervising Provider',
    type: 'string',
  },
  author: {
    label: 'Author',
    type: 'multi_object',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_neutral',
    showAvatar: true,
    polymorphic: true,
    selectTitle: (data: any) => data.name,
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}

export const ClaimTemplates = () => {
  const match = useRouteMatch()

  const columns = React.useMemo(
    () => [
      {
        title: 'Template Name',
        model: 'name',
        width: 250,
        formatValue: ({ data, value }: any) => <MainCell testKey="main_cell" id={data.id} value={value} to={`${match.url}/${data.id}`} />,
      },
      {
        title: 'Template ID',
        model: 'identifier',
      },
      {
        title: 'Claim Type',
        model: 'category',
        formatValue: ({ value }: any) => CLAIM_TYPES[value],
      },
      {
        title: 'Condition Codes',
        model: 'condition_codes',
        formatValue: ({ value }: any) => value?.map((o: any) => o?.code).join(', ') || '–',
      },
      {
        title: 'Type Of Bill',
        model: 'bill_type',
      },
      {
        title: 'Claim Filing Code',
        model: 'claim_filing_code',
        width: 150,
      },
      {
        title: 'Claim Frequency',
        model: 'claim_frequency_code',
        width: 150,
      },
      {
        title: 'Place Of Service',
        model: 'place_of_service_code',
        width: 150,
      },
      {
        title: 'Patient Status',
        model: 'patient_status_code',
        width: 150,
      },
      {
        title: 'Admission Type',
        model: 'admission_type_code',
        width: 150,
      },
      {
        title: 'Admission Source',
        model: 'admission_source_code',
        width: 150,
      },
      {
        title: 'Billing Provider',
        id: 'billing_provider',
        model: 'billing_provider.credential',
      },
      {
        title: 'Attending Provider',
        id: 'attending_provider',
        model: 'attending_provider.credential',
      },
      {
        title: 'Rendering Provider',
        id: 'rendering_provider',
        model: 'rendering_provider.credential',
      },
      {
        title: 'Ordering Provider',
        id: 'ordering_provider',
        model: 'ordering_provider.credential',
      },
      {
        title: 'Referring Provider',
        id: 'referring_provider',
        model: 'referring_provider.credential',
      },
      {
        title: 'Supervising Provider',
        id: 'supervising_provider',
        model: 'supervising_provider.credential',
      },
      {
        title: 'Author',
        model: 'author',
        type: 'profile',
      },
      {
        title: 'Created At',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated At',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Actions',
        id: 'duplicate',
        width: 130,
        disableSort: true,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          return (
            <Button
              label="Duplicate"
              glyph="add"
              size={100}
              type="default"
              link={{
                pathname: `${match.url}/new`,
                parent: match,
                data,
              }}
            />
          )
        },
      },
    ],
    [match.url],
  )

  const [selectedTab, setSelectedTab] = React.useState('active')

  const tableProps = useDataTable({
    name: 'insurance_claim_templates',
    endpoint: '/insurance_claim_templates',
    params: { status: selectedTab },
    localStorageKey: 'insurance_claim_templates_v1',
  })

  return (
    <>
      <Tabs defaultTab="active" onChange={setSelectedTab}>
        <Tabs.List className="px-4">
          <Tabs.Item label="Active" name="active" />
          <Tabs.Item label="Archived" name="archived" />
        </Tabs.List>
      </Tabs>

      <div className="p-4 grid grid-cols-[100%]">
        <DataTable
          {...tableProps}
          asCard
          canBatchSelect
          title="Claim Templates"
          icon="outbox"
          columns={columns}
          filtersConfig={CLAIM_TEMPLATE_FILTERS_CONFIG}
          searchBarFilterKey="name"
        />
      </div>
    </>
  )
}
