import React from 'react'

import GlobalTags from '../../../Table/Cells/GlobalTags'
import SmartFlags from '../../../Table/Cells/SmartFlags'
import Table from '../../../Table/Table'
import TableCell from '../../../Table/TableCell'
import TableFilter from '../../../Table/TableFilter'

import withSettings from '../../../../hocs/withSettings'
import { amount, usDate, titleCase } from '../../../../utils/functions'

const STATUSES: any = {
  active: 'Active',
  archived: 'Archived',
}

const columns = (to, onClick, timezone, mainLinkAs) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink
        as={mainLinkAs}
        icon={row.original.data?.icon}
        to={to?.(row.original.id)}
        onClick={() => onClick?.(row.original)}
        label={value}
      />
    ),
  },
  {
    width: 110,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <TableCell value={STATUSES[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'active', value: 'active' },
      { label: 'archived', value: 'archived' },
    ],
  },
  {
    width: 240,
    Header: 'Tags',
    accessor: 'global_tags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <GlobalTags
        // queryKey={queryKey} // TODO
        tags={row.original.global_tags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 240,
    Header: 'Smart Flags',
    accessor: 'smart_flags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <SmartFlags
        // queryKey={queryKey}
        value={row.original.smart_flags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    accessor: 'data.discount_type',
    Header: 'Discount Type',
    Cell: ({ value }: any) => <TableCell value={titleCase(value)} />,
  },
  {
    accessor: 'data.amount_off',
    Header: 'Discount',
    Cell: ({ row }: any) => (
      <TableCell
        value={
          row.original.data?.discount_type === 'fixed' ? `$${amount(row.original.data?.amount_off)}` : `${row.original.data?.percent_off}%`
        }
      />
    ),
  },
  {
    accessor: 'data.duration',
    Header: 'Duration',
    Cell: ({ value }: any) => <TableCell value={titleCase(value)} />,
  },
  {
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell value={usDate(value, timezone)} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  timezone: string
  title: string
  to?: Function
}

const DiscountBehaveTemplatesTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    localStorageKey,
    onClick,
    timezone,
    title,
    to,
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={columns(to, onClick, timezone, props.mainLinkAs)}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

DiscountBehaveTemplatesTable.defaultProps = {
  title: 'Discounts',
  icon: 'certificate',
  emptyDescription: 'No discounts to display',
  localStorageKey: 'discount_internal_templates',
}

export default withSettings(DiscountBehaveTemplatesTable)
