import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'

export const MedicationDestructionLogsDataTable = (props: any) => {
  const { to } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Medication',
        id: 'med',
        model: 'med',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={value?.id} value={value?.name} to={to?.(data)} />,
      },
      {
        id: 'resident',
        model: 'resident',
        title: 'Client',
        type: 'profile',
      },
      {
        model: 'employee',
        title: 'Destroyed By',
        type: 'profile',
      },
      {
        model: 'quantity',
        title: 'Quantity',
        type: 'number',
      },
      {
        width: 180,
        model: 'prescription_no',
        title: 'Prescription #',
      },
      {
        width: 180,
        model: 'pharmacy_name',
        title: 'Pharmacy Name',
      },
      {
        id: 'author',
        model: 'author',
        title: 'Logged By',
        type: 'profile',
      },
      {
        title: 'Destruction Date',
        model: 'destroyed_at',
        type: 'date_time',
      },
      {
        title: 'Created At',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated At',
        model: 'updated_at',
        type: 'date_time',
      },
    ]
  }, [to])

  return (
    <DataTable
      title="Medication Destruction Logs"
      testKey="medication_destruction_logs_table"
      icon="medication_inventory"
      columns={columns}
      filtersConfig={FILTERS.medication_destruction_logs}
      {...props}
    />
  )
}
