import React from 'react'

import Checkbox from '../Checkbox'
import CheckboxGroup from '../CheckboxGroup'
import ContextHide from '../ContextHide'
import ContextShow from '../ContextShow'
import Textarea from '../Textarea'

const AddictionAreasCheckboxGroup = ({ label, model, isRequired }) => (
  <>
    <CheckboxGroup
      label={label}
      layout="vertical-dense"
      validations={
        isRequired && {
          presence: {
            message: 'Please select at least one option',
          },
        }
      }
    >
      <Checkbox label="None" model={`${model}.none`} />
      <ContextHide when={`${model}.none`} is={true}>
        <Checkbox label="Internet" model={`${model}.internet`} />
        <Checkbox label="Food" model={`${model}.food`} />
        <Checkbox label="Relationships" model={`${model}.relationships`} />
        <Checkbox label="Money" model={`${model}.money`} />
        <Checkbox label="Shopping" model={`${model}.shopping`} />
        <Checkbox label="Sex" model={`${model}.sex`} />
        <Checkbox label="Other" model={`${model}.other`} />
      </ContextHide>
    </CheckboxGroup>

    <ContextHide when={`${model}.none`} is={true}>
      <ContextShow when={`${model}.other`} is={true}>
        <Textarea
          label="Please list other areas"
          model={`${model}_other`}
          validations={
            isRequired && {
              presence: {
                message: 'Please list other areas',
              },
            }
          }
        />
      </ContextShow>
    </ContextHide>
  </>
)

AddictionAreasCheckboxGroup.defaultProps = {
  model: 'addictive_areas',
}

export default AddictionAreasCheckboxGroup
