import React from 'react'
import produce from 'immer'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { amount, isDefined } from '../../utils/functions'

import Value from '../Value'
import { withFormContext } from './context'
import { Input } from './Input'

class AmountInput extends Input {
  processUpdate = (_queueEvent: any) => {
    const vs = { ...this.props.defaultValidations, ...this.props.validations }
    const valueDefined = isDefined(this.props.value)
    let newValue = this.props.value

    if (valueDefined) {
      const parsedValue = parseFloat(newValue)
      if (!isNaN(parsedValue)) newValue = parsedValue
    }

    const newState = produce(this.state, (draft: any) => {
      draft.isRequired = vs?.hasOwnProperty('presence')
      draft.defaultValue = this.props.defaultValue

      if (valueDefined && newValue !== draft.value) draft.value = newValue
    })

    this.setState({
      isHighlighted: false,
      model: this.props.model,
      value: newState.value,
      defaultValue: newState.defaultValue,
    })

    return newState
  }

  readOnlyRender = () => {
    const { prefix, suffix } = this.props
    const { value } = this.state

    const valueDefined = isDefined(value)

    return <Value value={`${valueDefined ? prefix : ''}${valueDefined ? amount(value) : DEFAULT_EMPTY_VALUE} ${(value && suffix) || ''}`} />
  }
}

AmountInput.defaultProps = {
  type: 'number',
  prefix: '$',
  placeholder: '0.00',
  min: 0,
  size: 6,
  validateOn: 'blur-change',
}

export default withFormContext(AmountInput)
