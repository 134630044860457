import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'

import { getResourceLink } from '@behavehealth/utils/functions'

import { Page, Grid } from '@behavehealth/components'
import { ICONS } from '@behavehealth/theme'

import { InsuranceBillingReportDataTable } from '@behavehealth/constructs/LiveReports/InsuranceBillingReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const BillingReport = () => {
  const tableProps = useDataTable({
    name: ['reports', 'insurance-billing'],
    endpoint: `/live_reports/insurance_claim_service_lines`,
    localStorageKey: 'report_insurance_billing_v1',
  })

  const to = React.useMemo(() => (rowData: any) => getResourceLink(rowData.insurance_claim?.primary_insurance?.insurance_local_payer), [])

  return (
    <Page icon={ICONS.insurance} title="Insurance Billing Report">
      <Grid>
        <InsuranceBillingReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(BillingReport)
