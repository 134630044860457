import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { Button, Card, Dropdown, DropdownItem, Divider, Page, PageLayout, HelpTagIframe } from '@behavehealth/components'
import { CustomNotesTable } from '@behavehealth/components/Tables'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { mapToArray } from '@behavehealth/utils/functions'
import useAPI from '@behavehealth/hooks/useAPI'

const AddDropdown = ({ templates }: any) => {
  const match = useRouteMatch()

  return (
    <Dropdown label="Add Team Note…" glyph="add" buttonType="primary" buttonSize={300} permission="agreements.create">
      {mapToArray(templates).map((template) => (
        <DropdownItem
          key={template.id}
          label={template.name}
          glyph="documents"
          color="paleBlue"
          link={{
            pathname: `${match.url}/new`,
            parent: match,
            data: template,
          }}
        />
      ))}

      <Divider />

      <DropdownItem
        label="Empty Custom Note"
        glyph="add"
        color="paleBlue"
        link={{
          pathname: `${match.url}/new`,
          parent: match,
          data: {
            name: 'New Custom Note',
          },
        }}
      />
    </Dropdown>
  )
}

const TeamNotes: React.FC = () => {
  const match = useRouteMatch()

  const templates = useSelector((state) => state.data.custom_note_templates?.data)
  const templatesEmpty = size(templates) === 0

  const data = useSelector((state) => state.data.custom_notes?.data)
  const loading = useSelector((state) => state.data.custom_notes?.loading)

  useAPI('custom_notes', '/custom_notes?variant=team')
  useAPI('custom_note_templates', '/custom_note_templates?variant=team')

  const isEmpty = size(data) === 0
  const actions = <Button type="primary" glyph="add" label="Add Note" link={`${match.url}/new`} />

  return (
    <Page
      feature="team_notes"
      breakpoint="0"
      help={<HelpTagIframe id="team_notes" />}
      actions={
        !loading && (
          <>
            <Button
              size={300}
              css={isEmpty && { order: 1 }}
              glyph="settings"
              type="default"
              label="Manage Templates"
              link="/settings/custom-note-templates/team"
              // permission="settings.custom_note_templates.view"
            />

            {!templatesEmpty && <AddDropdown templates={templates} />}
          </>
        )
      }
    >
      <PageLayout>
        <Card>
          <CustomNotesTable
            title="Team Notes"
            emptyDescription="No team notes have been added yet"
            icon="community"
            data={data}
            isLoading={isEmpty && loading}
            emptyActions={actions}
            to={(id) => `team-notes/${id}`}
            localStorageKey="team_notes"
          />
        </Card>
      </PageLayout>
    </Page>
  )
}

export default withPageError(TeamNotes)
