import React from 'react'

import { COLORS, ICONS } from '../../../theme'

import Card from '../../Card'
import Grid from '../../Grid'
import Text from '../../Typography/Text'

import Table from '../../Table/Table'
import TableCell from '../../Table/TableCell'

const RECORD_META = {
  housing_note: {
    title: 'Housing Note',
    icon: ICONS.housing_notes,
    getUrl: (clientId, recordId): any => `/clients/${clientId}/shift-notes/${recordId}`,
  },
  clinical_note: {
    title: 'Clinical Note',
    icon: ICONS.clinical_notes,
    getUrl: (clientId): any => `/clients/${clientId}/clinical-notes/all`,
  },
}

const columns = [
  {
    width: 200,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'type',
    Header: 'Name',
    Cell: ({ value, row }: any) => {
      const clientId = row.original?.client?.id
      const recordId = row.original?.id

      return (
        <TableCell.MainLink
          label={RECORD_META[value]?.title}
          icon={RECORD_META[value]?.icon}
          to={RECORD_META[value]?.getUrl(clientId, recordId)}
        />
      )
    },
  },
  {
    width: 180,
    accessor: 'client.name',
    Header: 'Client',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={value} />,
  },
  {
    width: 180,
    accessor: 'created_at',
    Header: 'Created At',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} />,
  },
]

const RecordsMessage = ({ message }: any) => {
  if (!message) return null

  return (
    <Grid gap={10}>
      <Text color={COLORS.text}>{message.text}</Text>
      <Card className="!max-w-[580px]">
        <Table title="Client Records" icon="clients" data={message.records?.data} columns={columns} />
      </Card>
    </Grid>
  )
}

export default RecordsMessage
