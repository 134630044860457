import React from 'react'
import { connect } from 'react-redux'

import { apiUpdate } from '../../../modules/api'

import Alert from '../../Alert'
import Button from '../../Button'
import Divider from '../../Divider'
import Overlay from '../../Overlay'
import Section from '../../Section'

import AmountInput from '../../Forms/AmountInput'
import Attachments from '../../Forms/Attachments'
import ContextShow from '../../Forms/ContextShow'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Option from '../../Forms/Option'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Select from '../../Forms/Select'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

import withRouterV6 from '../../../hocs/withRouterV6'

class HealthcareService extends OverlayBase {
  toggleArchive = async () => {
    if (this.props.record.archived_at) {
      await apiUpdate({
        name: 'services',
        url: `/services/${this.props.match.params.id}/unarchive`,
      })
    } else {
      await apiUpdate({
        name: 'services',
        url: `/services/${this.props.match.params.id}/archive`,
      })
    }
  }

  renderHeader = () => {
    return <Overlay.Header icon="financials" title={this.state.$new ? 'Add Insurance Service' : 'Insurance Service'} />
  }

  renderContent = () => {
    const { $new, $editable, params } = this.state
    const { record, loading } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          linked={{ service_type: 'healthcare' }}
        >
          <Section>
            <FormSection>
              {record.archived_at && (
                <Alert glyph="info" type="neutral">
                  This service is archived. No new charges can be created based on it.
                </Alert>
              )}

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a service name',
                  },
                }}
              />

              <Textarea label="Description" model="description" />

              <RadioGroup
                label="Collection Type"
                model="collection_type"
                layout="horizontal-dense"
                validations={{
                  presence: {
                    message: 'Please select an answer',
                  },
                }}
                defaultValue="insurance"
              >
                <Radio label="Insurance" value="insurance" />
                <Radio label="Cash Payment" value="cash" />
              </RadioGroup>

              {/* Amount */}
              <FormSection horizontal>
                <Select label="Unit" model="unit" defaultValue="per_session">
                  <Option label="Per Diem" value="per_diem" />
                  <Option label="Per Session" value="per_session" />
                  <Option label="Per Item" value="per_item" />
                  <Option label="Per Hour" value="per_hour" />
                  <Option label="Per Minutes" value="per_minutes" />
                </Select>

                <AmountInput
                  label="Amount"
                  model="amount"
                  validations={{
                    presence: {
                      message: 'Please enter a service amount',
                    },
                  }}
                />

                <ContextShow when="unit" is="per_minutes">
                  <Input size={4} label="Minutes" model="minutes" />
                </ContextShow>
              </FormSection>
            </FormSection>
          </Section>

          <Divider />

          <Section title="Internal Cost & Notes">
            <FormSection>
              <Alert glyph="info">
                This information is <span css={{ fontStyle: 'italic' }}>not</span> displayed to any clients or payers.
              </Alert>

              <FormSection horizontal>
                <AmountInput label="Service Cost" model="internal_cost" />

                <ContextShow when="is_time_based_amount" is={true}>
                  <Select label="Per" model="internal_amount_time" defaultValue="hour">
                    <Option label="Hour" value="hour" />
                    <Option label="Min" value="min" />
                  </Select>
                </ContextShow>
              </FormSection>

              <Textarea label="Internal Notes" model="internal_notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section title="Attachments">
            <Attachments model="documents" />
          </Section>

          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid, category } = this.state
    const { record } = this.props

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={this.save}
              isDisabled={isInvalid}
              isLoading={this.props.loading}
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button label="Edit" glyph="edit" type="default" onClick={this.edit} isDisabled={this.props.loading} flex="100 1 auto" />

            {record.archived_at ? (
              <Button
                label="Un-archive"
                glyph="delete"
                type="default"
                color="gray"
                onClick={this.toggleArchive}
                isLoading={this.props.loading}
              />
            ) : (
              <Button
                label="Archive"
                glyph="delete"
                type="default"
                color="gray"
                onClick={this.toggleArchive}
                isLoading={this.props.loading}
              />
            )}
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state: any, props: any) => defaultMapStateToProps(state, props.match, 'services')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(HealthcareService))

export const HealthcareServiceV6 = withRouterV6(connect(mapStateToProps, mapDispatchToProps)(withOverlayError(HealthcareService)))
