import React from 'react'
import { v4 as uuid } from 'uuid'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../../../../theme'
import { niceAmount, arrayToMap, mapToArray } from '../../../../../utils/functions'
import { withFormContext } from '../../../../Forms/context'

import AmountInput from '../../../../Forms/AmountInput'
import Button from '../../../../Button'
import Card from '../../../../Card'
import DateInput from '../../../../Forms/DateInput'
import Flex from '../../../../Flex'
import GridTable from '../../../../GridTable'
import Input from '../../../../Forms/Input'
import Tooltip from '../../../../Tooltip'

import ModifierCodesSelector from '../../../../Forms/elements/ModifierCodesSelector'
import PerDiemServiceCodeSelector from '../../../../Forms/elements/PerDiemServiceCodeSelector'
import RevenueCodeSelector from '../../../../Forms/elements/RevenueCodeSelector'

import { useFormField } from '../../../../Forms/hooks/useFormField'

const serviceLineColumns = [
  {
    name: 'Service Date',
    width: '240px',
  },
  {
    name: 'Per Diem Service Code',
    width: '220px',
  },
  {
    name: 'Revenue Code',
    width: '220px',
  },
  {
    name: (
      <div>
        <Flex centerY gap="0.5rem">
          <span>Modifier Codes</span> <Tooltip glyph="info" content="Please enter maximum 4 modifier codes" />
        </Flex>
      </div>
    ),
    width: '220px',
  },
  {
    name: 'Units',
    width: '65px',
  },
  {
    name: 'Amount',
    width: '160px',
  },
  {
    name: 'Total Charge',
    width: '110px',
  },
  {
    name: 'Remove',
    width: '80px',
  },
]

const serviceLineGridColumns = serviceLineColumns.map((column) => `minmax(${column.width}, 1fr)`).join(' ')

const ServiceLines = ({ model, form, isEditable }: any) => {
  const [data, setData]: any = React.useState({})

  const isEmpty = size(data) === 0

  const { formActions } = useFormField({
    model: model,
    form: form,
  })

  // ON MOUNT
  React.useEffect(() => {
    let initialData = form?.getInitialInputFieldValue(model)

    setData(arrayToMap(initialData))
  }, [])

  // UPDATE FORM
  React.useEffect(() => {
    formActions.setValue(mapToArray(data))
  }, [data])

  // FUNCTIONS
  const add = () => {
    const newID = uuid()

    setData((o: any) => ({
      ...o,
      [newID]: {
        id: newID,
        service_date: null,
        service_code: null,
        revenue_code: null,
        modifier_codes: null,
        units: 1,
        amount: null,
      },
    }))
  }

  const remove = (id: string) => {
    const newData = produce(data, (draft: any) => {
      delete draft[id]
    })

    setData(newData)
  }

  const handleUpdate = (model: string, id: string, value: any) => {
    const newData = produce(data, (draft: any) => {
      draft[id][model] = value
    })
    setData(newData)
  }

  return (
    <>
      <Card>
        <GridTable templateColumns={serviceLineGridColumns}>
          <GridTable.Header>
            {serviceLineColumns.map((column) => (
              <GridTable.Cell key={column.name}>{column.name}</GridTable.Cell>
            ))}
          </GridTable.Header>

          {isEmpty && (
            <div css={styles.emptyState}>
              <div>No service lines added yet</div>
            </div>
          )}

          {!isEmpty &&
            mapToArray(data).map((item: any) => (
              <GridTable.Row key={item.id}>
                <GridTable.Cell>
                  <DateInput
                    value={data[item.id].service_date}
                    onUpdate={({ value }: any) => handleUpdate('service_date', item.id, value)}
                  />
                </GridTable.Cell>

                <GridTable.Cell>
                  <PerDiemServiceCodeSelector
                    label={false}
                    value={data[item.id].service_code}
                    onUpdate={({ value }: any) => handleUpdate('service_code', item.id, value)}
                  />
                </GridTable.Cell>

                <GridTable.Cell>
                  <RevenueCodeSelector
                    label={false}
                    value={data[item.id].revenue_code}
                    onUpdate={({ value }: any) => handleUpdate('revenue_code', item.id, value)}
                  />
                </GridTable.Cell>

                <GridTable.Cell>
                  <ModifierCodesSelector
                    label={false}
                    value={data[item.id].modifier_codes}
                    onUpdate={({ value }: any) => handleUpdate('modifier_codes', item.id, value)}
                  />
                </GridTable.Cell>

                <GridTable.Cell>
                  <Input
                    type="number"
                    defaultValue={1}
                    value={data[item.id].units}
                    onUpdate={({ value }: any) => handleUpdate('units', item.id, value)}
                  />
                </GridTable.Cell>

                <GridTable.Cell>
                  <AmountInput
                    defaultValue={0}
                    value={data[item.id].amount}
                    onUpdate={({ value }: any) => handleUpdate('amount', item.id, value)}
                  />
                </GridTable.Cell>

                <GridTable.Cell>{niceAmount(data[item.id]?.amount * data[item.id]?.units)}</GridTable.Cell>

                <GridTable.Cell>
                  {isEditable ? <Button hideLabel glyph="delete" type="link" color="red" onClick={() => remove(item.id)} /> : '–'}
                </GridTable.Cell>
              </GridTable.Row>
            ))}
        </GridTable>
      </Card>

      {isEditable && (
        <Button label="Add Service Line" glyph="add" type="link" display="inline-flex" size={200} onClick={add} css={styles.button} />
      )}
    </>
  )
}

const styles = {
  button: {
    marginTop: '1rem',
  },

  emptyState: {
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
    padding: '1rem',
    color: COLORS.textMuted,
    background: COLORS.hover,
    opacity: 0.8,
  },
}

export default withFormContext(ServiceLines)
