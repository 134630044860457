import React from 'react'

import CardsGrid from '../../CardsGrid'
import FinancialCard from '../../Cards/FinancialCard'
import Permission from '../../Permission'

import { useGet } from '../../../hooks/useNewAPI'

const TransactionStats = ({ name, url, showRepeatingCharges }: any) => {
  const { data: stats }: any = useGet({
    name: [name, 'stats'].flat(),
    url: `${url}/stats`,
    options: {
      staleTime: 0,
    },
  })

  return (
    <CardsGrid columnWidth={200}>
      <FinancialCard testKey="current_balance" title="Current Balance" value={parseFloat(stats?.balance)} />
      <FinancialCard testKey="total_charges" title="Total Charges" value={parseFloat(stats?.charges)} valueColor="red" prefix="-" />
      <FinancialCard
        testKey="total_successful_payments"
        title="Total Successful Payments"
        value={parseFloat(stats?.payments)}
        valueColor="green"
        prefix="+"
      />
      <FinancialCard
        testKey="payments_processing"
        title="Payments Processing"
        value={parseFloat(stats?.processing_payments)}
        valueColor="gray"
        prefix=" "
      />
      <FinancialCard
        testKey="failed_payments"
        title="Failed Payments"
        value={parseFloat(stats?.failed_payments)}
        valueColor="red"
        prefix=" "
      />
      <FinancialCard testKey="total_credits" title="Total Credits" value={parseFloat(stats?.credits)} valueColor="orange" prefix="+" />
      <FinancialCard testKey="total_refunds" title="Total Refunds" value={parseFloat(stats?.refunds)} valueColor="gray" prefix="-" />
      <Permission featureFlagV2="financials_pass_rcm_fees">
        <FinancialCard testKey="total_fees" title="Total Fees" value={parseFloat(stats?.fees)} valueColor="textStronglyMuted" prefix="-" />
      </Permission>
      <FinancialCard
        testKey="total_write_offs"
        title="Total Write-Offs"
        value={parseFloat(stats?.write_offs)}
        valueColor="paleGreen"
        prefix="+"
      />

      {showRepeatingCharges && (
        <FinancialCard
          testKey="total_future_planned_charges"
          title="Total Future Planned Charges"
          value={parseFloat(stats?.future_charges)}
          valueColor="yellow"
          sign=" "
        />
      )}
    </CardsGrid>
  )
}

export default TransactionStats
