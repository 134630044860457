import React from 'react'
import Fuse from 'fuse.js'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'

export const useFuse = (data: any[] = [], searchTerm: string, fuseOptions?: any) => {
  const fuse = React.useMemo(() => {
    return new Fuse(data, fuseOptions)
  }, [data, fuseOptions])

  const results = React.useMemo(() => {
    if (!searchTerm) return data

    let searchResults: any[] = []
    let fuseResults = fuse.search(searchTerm)

    const resultsCount = size(fuseResults)

    if (resultsCount) {
      for (let i = 0; i < resultsCount; i++) searchResults.push(fuseResults[i].item)
    }

    return searchResults
  }, [fuse, searchTerm])

  return results
}
