import React from 'react'

import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'

import { DataFormOverlay } from '../DataFormOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import Score from './common/Score'

const INITIAL_STATE = {
  little_interest: 0,
  hopeless: 0,
  sleep_issues: 0,
  tiredness: 0,
  eating_issues: 0,
  feeling_as_failure: 0,
  focus_issues: 0,
  movement_issues: 0,
  self_harm_thoughts: 0,
}

const RootTEAOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Treatment Effectiveness Assessment (TEA)"
      category="clinical_measurement"
      subcategory="tea"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Info"
        headingType="h2"
        scrollview={{
          id: 'info',
          name: 'Info',
        }}
      >
        <p>
          The TEA asks you, the client, to express the extent of changes for the better from your involvement in the program to this point
          (or how things are if it's your first TEA or baseline) in four areas: substance use, health, lifestyle, and community. For each
          area, think about how things have become better and circle the results on the scale below: the more you have improved, the higher
          the number – from 1 (not better at all) to 10 (very much better). In each area write down the one or two changes most important to
          you in the Remarks section.
        </p>
      </Section>

      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <YesNoRadioGroup label="First TEA?" model="data.questionnaire.is_first_tea" />

        <RadioGroup
          label="1. Substance use: How much better are you with drug and alcohol use? Consider the frequency and amount of use, money spent on drugs, amount of drug craving, time spent being loaded, being sick, in trouble and in other drug-using activities, etc"
          model="data.questionnaire.q1"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q1: state.value }))}
        >
          <Radio label="1 = None or not much" value={1} />
          <Radio label="2" value={2} />
          <Radio label="3" value={3} />
          <Radio label="4" value={4} />
          <Radio label="5 = Better" value={5} />
          <Radio label="6" value={6} />
          <Radio label="7" value={7} />
          <Radio label="8" value={8} />
          <Radio label="9" value={9} />
          <Radio label="10 = Much better" value={10} />
        </RadioGroup>

        <SmartTextarea useDictation label="Remarks" model="data.questionnaire.q1_notes" />

        <RadioGroup
          label="2. Health: Has your health improved? In what way and how much? Think about your physical and mental health: Are you eating and sleeping properly, exercising, taking care of health problems or dental problems, feeling better about yourself, etc?"
          model="data.questionnaire.q2"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q2: state.value }))}
        >
          <Radio label="1 = None or not much" value={1} />
          <Radio label="2" value={2} />
          <Radio label="3" value={3} />
          <Radio label="4" value={4} />
          <Radio label="5 = Better" value={5} />
          <Radio label="6" value={6} />
          <Radio label="7" value={7} />
          <Radio label="8" value={8} />
          <Radio label="9" value={9} />
          <Radio label="10 = Much better" value={10} />
        </RadioGroup>

        <SmartTextarea useDictation label="Remarks" model="data.questionnaire.q2_notes" />

        <RadioGroup
          label="3. Lifestyle: How much better are you in taking care of personal responsibilities? Think about your living conditions, family situation, employment, relationships: Are you paying your bills? Following through with your personal or professional commitments?"
          model="data.questionnaire.q3"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q3: state.value }))}
        >
          <Radio label="1 = None or not much" value={1} />
          <Radio label="2" value={2} />
          <Radio label="3" value={3} />
          <Radio label="4" value={4} />
          <Radio label="5 = Better" value={5} />
          <Radio label="6" value={6} />
          <Radio label="7" value={7} />
          <Radio label="8" value={8} />
          <Radio label="9" value={9} />
          <Radio label="10 = Much better" value={10} />
        </RadioGroup>

        <SmartTextarea useDictation label="Remarks" model="data.questionnaire.q3_notes" />

        <RadioGroup
          label="4. Community: Are you a better member of the community? Think about things like obeying laws and meeting your
          responsibilities to society: Do your actions have positive or negative impacts on other people?"
          model="data.questionnaire.q4"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q4: state.value }))}
        >
          <Radio label="1 = None or not much" value={1} />
          <Radio label="2" value={2} />
          <Radio label="3" value={3} />
          <Radio label="4" value={4} />
          <Radio label="5 = Better" value={5} />
          <Radio label="6" value={6} />
          <Radio label="7" value={7} />
          <Radio label="8" value={8} />
          <Radio label="9" value={9} />
          <Radio label="10 = Much better" value={10} />
        </RadioGroup>

        <SmartTextarea useDictation label="Remarks" model="data.questionnaire.q4_notes" />
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Score title="Score" finalScore={finalScore} />
        </>
      )}
    </>
  )
}

export const TEAOverlay = withOverlayError(RootTEAOverlay)
