import React from 'react'
import isNumber from 'lodash/isNumber'

import { COLORS } from '../../../theme'

import Button from '../../../components/Button'
import Glyph from '../../../components/Glyph'

import { Filter } from '../common/Filter'
import { FilterDropdown } from '../common/FilterDropdown'
import { FilterDropdownItem } from '../common/FilterDropdownItem'
import { FilterLabel } from '../common/FilterLabel'
import { FilterSegment } from '../common/FilterSegment'

const CONDITIONS = {
  between: 'between',
  less_than: 'less than',
  greater_than: 'greater than',
  eq: 'equal to',
}

const CONDITION_GLYPHS = {
  between: 'between',
  less_than: 'less_than',
  greater_than: 'greater_than',
  eq: 'equals',
}

export const NumberFilter = (props: any) => {
  const { config, onClear, filter, onUpdate } = props

  const [condition, setCondition] = React.useState(filter?.condition || 'between')
  const [didChange, setDidChange] = React.useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  const [firstNumber, setFirstNumber]: any = React.useState(filter?.value?.from || filter?.value || 0)
  const [secondNumber, setSecondNumber]: any = React.useState(filter?.value?.until || 1)

  const ref = React.useRef(null)

  const handleUpdate = () => {
    let updateValue: any = firstNumber

    if (condition === 'between') {
      updateValue = { from: firstNumber, until: secondNumber }
    }

    onUpdate?.({ value: updateValue, condition })
    setDidChange(false)
  }

  React.useEffect(() => {
    if (!isNumber(firstNumber)) return

    if (condition === 'between' && !isNumber(secondNumber)) return

    handleUpdate()
  }, [condition])

  React.useEffect(() => {
    if (!ref?.current) return

    ref.current.focus()
  }, [])

  if (!config) return null

  const { label, glyph } = config

  return (
    <Filter onClear={onClear}>
      <FilterLabel label={label} glyph={glyph} />

      <FilterSegment>
        <FilterDropdown label={CONDITIONS[condition]} open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
          {Object.keys(CONDITIONS).map((key) => (
            <FilterDropdownItem
              title={CONDITIONS[key]}
              isActive={condition === key}
              onClick={() => {
                setCondition(key)
                setIsDropdownOpen(false)
              }}
              graphic={<Glyph glyph={CONDITION_GLYPHS[key]} color={COLORS.textMuted} size={16} />}
            />
          ))}
        </FilterDropdown>
      </FilterSegment>

      <FilterSegment>
        <input
          ref={ref}
          type="number"
          css={STYLES.input}
          value={isNaN(firstNumber) ? '' : firstNumber}
          onChange={(event) => {
            const inputValue = parseFloat(event.target.value)

            if (isNaN(inputValue)) {
              setFirstNumber(null)
              return
            }

            setDidChange(true)
            setFirstNumber(inputValue)
          }}
        />
      </FilterSegment>

      {condition === 'between' && (
        <>
          <FilterSegment css={STYLES.arrowSegment}>→</FilterSegment>

          <FilterSegment>
            <input
              type="number"
              css={STYLES.input}
              value={isNaN(secondNumber) ? '' : secondNumber}
              onChange={(event) => {
                const inputValue = parseFloat(event.target.value)

                if (isNaN(inputValue)) {
                  setSecondNumber(null)
                  return
                }

                setDidChange(true)
                setSecondNumber(inputValue)
              }}
            />
          </FilterSegment>
        </>
      )}

      {didChange && (
        <FilterSegment css={STYLES.actionSegment}>
          <Button label="Apply" onClick={handleUpdate} color="green" size={100} glyph="check" type="primary" css={STYLES.saveButton} />
        </FilterSegment>
      )}
    </Filter>
  )
}

const STYLES = {
  actionSegment: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0 0.25rem',
  },

  input: {
    border: 'none',
    maxWidth: 80,
    outline: 'none',
    height: 'var(--filter-height)',
    padding: '0 0.5rem',
    borderRadius: 3,
    fontSize: '0.85rem',

    '&:hover': {
      background: COLORS.hover,
    },

    '&:focus': {
      background: COLORS.hover,
      boxShadow: `inset 0 0 0 1px ${COLORS.blue}`,
    },
  },

  saveButton: {
    height: '20px !important',
    minHeight: '20px !important',
    fontSize: '0.82rem',

    svg: {
      width: 12,
      height: 12,
      margin: '0 !important',
    },
  },

  arrowSegment: {
    padding: '0 0.5rem',
  },
}
