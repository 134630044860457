import React from 'react'
import { useSelector } from 'react-redux'

import { Accordion, Form, Page, Divider, HelpTagIframe } from '@behavehealth/components'
import { apiUpdate } from '@behavehealth/modules/api'
import { Tenant } from '@behavehealth/declarations'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { AdditionalContactInformationFields } from '@behavehealth/components/SteppedOnboardingForm/pages/AdditionalContactInformation'
import { ContactsFields } from '@behavehealth/components/SteppedOnboardingForm/pages/Contacts'

const CompanyContacts: React.FC = () => {
  const record: Tenant = useSelector((state) => state.me.tenant)

  return (
    <Page title="Company Contacts" icon="contacts" help={<HelpTagIframe id="settings_company_contacts" />}>
      <Accordion
        isOpen
        isNew
        activateEditMode
        initialModel={record}
        title="Company Contacts"
        icon="contacts"
        onSubmit={async (data: any) => {
          await apiUpdate({
            name: 'tenant',
            url: '/facilities/current/questionnaire',
            params: {
              questionnaire: data.questionnaire,
            },
            reducer: 'me',
          })
        }}
      >
        <Form>
          <ContactsFields />

          <Divider />

          <AdditionalContactInformationFields />
        </Form>
      </Accordion>
    </Page>
  )
}

export default withPageError(CompanyContacts)
