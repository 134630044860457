import React from 'react'
import { connect } from 'react-redux'

import { withOverlayError } from '../../../hocs/withOverlayError'

import Button from '../../Button'
import DateInput from '../../Forms/DateInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../../Overlays/pages/OverlayBase'

class DocumentationReviewOverlay extends OverlayBase {
  renderHeader = () => (
    <Overlay.Header icon="authorizations" title={this.state.$new ? 'Add Documentation Review' : 'Documentation Review'} />
  )

  renderContent = () => {
    const { $editable, $new } = this.state
    const { reference, record, timezone } = this.props

    const isBehave = this.props?.user?.type === 'bh_employee'

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={record}
          timezone={timezone}
          isEditable={$editable}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
          linked={
            $new && {
              admission_id: reference?.current_admission?.id,
              title: 'Documentation Review',
              category: 'documentation_review',
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              {!$new && <ObjectSelector isEditable={false} model="author" label="Author" type="employees" icon="employees" />}

              <Flex stretchChildrenX gap={16}>
                <DateInput label="From" model="data.started_at" />
                <DateInput label="Until" model="data.ended_at" />
              </Flex>
            </FormSection>

            <Divider />

            <FormSection heading="Notes">
              {isBehave && <Textarea label="Internal Notes" model="data.internal_notes" />}
              <Textarea label="Customer Notes" model="data.notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="authorizations.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Documentation Review"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="authorizations.edit"
            />

            <DeleteDialog
              title="Delete Documentation Review?"
              message="Are you sure you want to delete this Documentation Review? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                fullWidth
                permission="authorizations.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'insurance_authorizations')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_authorizations')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(DocumentationReviewOverlay))
