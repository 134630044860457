import React from 'react'

import Attachments from '../../components/Forms/Attachments'
import Chotomate from '../../components/Chotomate'
import ContextHide from '../../components/Forms/ContextHide'
import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import HelpTag from '../../components/HelpTag'
import Medications from '../../components/SmartContent/Medications'
import Option from '../../components/Forms/Option'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import Switch from '../../components/Forms/Switch'

import BioMedical from '../../components/Overlays/pages/DataForms/common/BioMedical'
import CognitiveEmotionalBehavioral from '../../components/Overlays/pages/DataForms/common/CognitiveEmotionalBehavioral'
import MotivationForChangeForm from '../../components/Overlays/pages/DataForms/common/MotivationForChangeForm'
import RecoveryEnvironment from '../../components/Overlays/pages/DataForms/common/RecoveryEnvironment'
import RelapsePotential from '../../components/Overlays/pages/DataForms/common/RelapsePotential'
import SNAP from '../../components/Overlays/pages/DataForms/common/SNAP'
import SubstanceUseHistory from '../../components/Overlays/pages/DataForms/common/SubstanceUseHistory'
import TreatmentHistoryForm from '../../components/Overlays/pages/DataForms/common/TreatmentHistoryForm'
import WithdrawalSymptomsForm from '../../components/Overlays/pages/DataForms/common/WithdrawalSymptomsForm'

import { DataFormOverlay } from '../DataFormOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootPreScreenOverlayV2 = (props: any) => {
  const { isBehave } = useSettings()

  return (
    <DataFormOverlay
      title="Pre-Screen"
      category="clinical_assessment"
      subcategory="prescreen"
      icon="clinical_assessments"
      helpID="clinical_assessments_prescreen"
      version="2.0"
      requestClientSignature={false}
      requestAuthorSignature={true}
      requireSupervisor={true}
      type={props.type}
      onClose={props.onClose}
      onSaveSuccessful={props.onSaveSuccessful}
      onDeleteSuccessful={props.onDeleteSuccessful}
      dataID={props.dataID}
      enableTreatmentPlanSelector
    >
      {({ client, isEditable, isNew }) => (
        <>
          <Chotomate ready name="pre_screen_overlay" />

          <Section
            title="Medications"
            aside={<Switch horizontal inverse model="data.medications.not_applicable" />}
            scrollview={{
              id: 'medications',
              name: 'Medications',
            }}
            commentsModel="data.medications"
          >
            <ContextHide when="data.medications.not_applicable" is={true} declineUndefined>
              <Medications
                model="data.medications.meds"
                isEditable={isEditable}
                client={client}
                icon="medications"
                title="Medications"
                emptyDescription="No medications added yet"
              />
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Data"
            aside={<Switch horizontal inverse model="data.data.not_applicable" />}
            scrollview={{
              id: 'data',
              name: 'Data',
            }}
            commentsModel="data.data"
          >
            <ContextHide when="data.data.not_applicable" is={true}>
              <FormSection>
                <SmartTextarea
                  useDictation
                  label="What made the client decide to seek treatment now? (i.e. family intervention, medical problem, legal issues, etc.)"
                  description="Please be specific"
                  model="data.data.reason_for_seeking_treatment"
                />

                <RadioGroup
                  label="Has the client ever had a seizure as a result of withdrawals?"
                  model="data.data.has_had_seizure_from_withdrawal"
                  layout="horizontal-dense"
                >
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>

                <ContextShow when="data.data.has_had_seizure_from_withdrawal" is={true}>
                  <SmartTextarea
                    useDictation
                    label="When?"
                    model="data.data.seizures_when"
                    validations={{
                      presence: {
                        message: 'Please provide this information',
                      },
                    }}
                  />
                </ContextShow>

                <RadioGroup
                  label="Has the client ever had any other types of withdrawal symptoms? (DT's, hallucinations, etc.)"
                  model="data.data.has_had_withdrawal_symptoms"
                  layout="horizontal-dense"
                >
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>

                <ContextShow when="data.data.has_had_withdrawal_symptoms" is={true}>
                  <SmartTextarea
                    useDictation
                    label="Please explain:"
                    model="data.data.withdrawal_symptoms_extra"
                    validations={{
                      presence: {
                        message: 'Please provide this information',
                      },
                    }}
                  />
                </ContextShow>

                <RadioGroup
                  label="Does the client have any ongoing medical conditions?"
                  model="data.data.has_ongoing_medical_conditions"
                  layout="horizontal-dense"
                >
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>

                <ContextShow when="data.data.has_ongoing_medical_conditions" is={true}>
                  <>
                    <RadioGroup
                      label="Has the client received medical treatment for these conditions?"
                      model="data.data.has_received_treatment_for_medical_conditions"
                      layout="horizontal-dense"
                    >
                      <Radio label="Yes" value={true} />
                      <Radio label="No" value={false} />
                    </RadioGroup>

                    <ContextShow when="data.data.has_received_treatment_for_medical_conditions" is={true}>
                      <SmartTextarea
                        useDictation
                        label="Please explain:"
                        model="data.data.treatment_extra"
                        validations={{
                          presence: {
                            message: 'Please provide this information',
                          },
                        }}
                      />
                    </ContextShow>
                  </>
                </ContextShow>

                <Select
                  label="How would the client rate their cravings on a scale of 1 to 10 (1 = lowest, 10 = highest)?"
                  model="data.data.craving_scale"
                >
                  <Option label="1" value={1} />
                  <Option label="2" value={2} />
                  <Option label="3" value={3} />
                  <Option label="4" value={4} />
                  <Option label="5" value={5} />
                  <Option label="6" value={6} />
                  <Option label="7" value={7} />
                  <Option label="8" value={8} />
                  <Option label="9" value={9} />
                  <Option label="10" value={10} />
                </Select>

                <RadioGroup label="Is the client currently experiencing cravings?" model="data.data.has_cravings" layout="horizontal-dense">
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>

                <ContextShow when="data.data.has_cravings" is={true}>
                  <Select label="To what degree? (1 = lowest, 10 = highest)?" model="data.data.current_craving_scale">
                    <Option label="1" value={1} />
                    <Option label="2" value={2} />
                    <Option label="3" value={3} />
                    <Option label="4" value={4} />
                    <Option label="5" value={5} />
                    <Option label="6" value={6} />
                    <Option label="7" value={7} />
                    <Option label="8" value={8} />
                    <Option label="9" value={9} />
                    <Option label="10" value={10} />
                  </Select>
                </ContextShow>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Substance Use History"
            aside={<Switch horizontal inverse model="data.substance_use_history.not_applicable" />}
            scrollview={{
              id: 'substance_use_history',
              name: 'Substance Use History',
            }}
            commentsModel="data.substance_use_history"
          >
            <ContextHide when="data.substance_use_history.not_applicable" is={true}>
              <SubstanceUseHistory />
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Treatment History for Mental Health and Addiction"
            aside={<Switch horizontal inverse model="data.treatment_history.not_applicable" />}
            scrollview={{
              id: 'treatment_history',
              name: 'Treatment History for Mental Health and Addiction',
            }}
            commentsModel="data.treatment_history"
          >
            <ContextHide when="data.treatment_history.not_applicable" is={true}>
              <TreatmentHistoryForm />
            </ContextHide>
          </Section>

          <Divider />

          {/* D1: Withdrawal Symptoms */}
          <Section
            title="Dimension 1: Withdrawal Symptoms"
            aside={<Switch horizontal inverse model="data.withdrawal_symptoms.not_applicable" />}
            scrollview={{
              id: 'withdrawal_symptoms',
              name: 'Dimension 1: Withdrawal Symptoms',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.withdrawal_symptoms"
          >
            <ContextHide when="data.withdrawal_symptoms.not_applicable" is={true}>
              <WithdrawalSymptomsForm />
            </ContextHide>
          </Section>

          <Divider />

          {/* D2: Bio-medical */}
          <Section
            title="Dimension 2: Bio-Medical"
            aside={<Switch horizontal inverse model="data.bio_medical.not_applicable" />}
            scrollview={{
              id: 'bio_medical',
              name: 'Dimension 2: Bio-Medical',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.bio_medical"
          >
            <ContextHide when="data.bio_medical.not_applicable" is={true}>
              <BioMedical />
            </ContextHide>
          </Section>

          <Divider />

          {/* D3: Emotional, Behavioral or Cognitive Conditions and Complications */}
          <Section
            title="Dimension 3: Emotional, Behavioral or Cognitive Conditions and Complications"
            aside={<Switch horizontal inverse model="data.cognitive_emotional_behavioral.not_applicable" />}
            scrollview={{
              id: 'cognitive_emotional_behavioral',
              name: 'Dimension 3: Emotional, Behavioral or Cognitive Conditions and Complications',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.cognitive_emotional_behavioral"
          >
            <ContextHide when="data.cognitive_emotional_behavioral.not_applicable" is={true}>
              <CognitiveEmotionalBehavioral />
            </ContextHide>
          </Section>

          <Divider />

          {/* D4: Readiness for Change */}
          <Section
            title="Dimension 4: Readiness for Change"
            aside={<Switch horizontal inverse model="data.motivation_for_change.not_applicable" />}
            scrollview={{
              id: 'motivation_for_change',
              name: 'Dimension 4: Readiness for Change',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.motivation_for_change"
          >
            <ContextHide when="data.motivation_for_change.not_applicable" is={true}>
              <MotivationForChangeForm />
            </ContextHide>
          </Section>

          <Divider />

          {/* D5: Relapse, Continued Use, or Continued Problem Potential */}
          <Section
            title="Dimension 5: Relapse, Continued Use, or Continued Problem Potential"
            aside={<Switch horizontal inverse model="data.relapse_potential.not_applicable" />}
            scrollview={{
              id: 'relapse_potential',
              name: 'Dimension 5: Relapse, Continued Use, or Continued Problem Potential',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.relapse_potential"
          >
            <ContextHide when="data.relapse_potential.not_applicable" is={true}>
              <RelapsePotential />
            </ContextHide>
          </Section>

          <Divider />

          {/* D6: Recovery / Living Environment */}
          <Section
            title="Dimension 6: Recovery / Living Environment"
            aside={<Switch horizontal inverse model="data.recovery_environment.not_applicable" />}
            scrollview={{
              id: 'recovery_environment',
              name: 'Dimension 6: Recovery / Living Environment',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.recovery_environment"
          >
            <ContextHide when="data.recovery_environment.not_applicable" is={true}>
              <RecoveryEnvironment />
            </ContextHide>
          </Section>

          <Section
            title="Strengths, Needs, Abilities, Preferences"
            aside={<Switch horizontal inverse model="data.snap.not_applicable" />}
            scrollview={{
              id: 'snap',
              name: 'S.N.A.P.',
            }}
            commentsModel="data.snap"
          >
            <ContextHide when="data.snap.not_applicable" is={true}>
              <SNAP />
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Summary"
            aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
            scrollview={{
              id: 'summary',
              name: 'Summary',
            }}
            commentsModel="data.summary"
          >
            <ContextHide when="data.summary.not_applicable" is={true}>
              <FormSection maxWidth="100%">
                <SmartTextarea useDictation model="data.summary.interpretive_summary" />
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Single Case Agreement"
            aside={<Switch horizontal inverse model="data.single_case_agreement.not_applicable" value={true} />}
            scrollview={{
              id: 'single_case_agreement',
              name: 'Single Case Agreement',
            }}
            commentsModel="data.single_case_agreement"
          >
            <ContextHide when="data.single_case_agreement.not_applicable" is={true}>
              <FormSection layout="vertical">
                <SmartTextarea useDictation label="Agreement Justification" model="data.single_case_agreement.justification" />

                {!isNew && (
                  <SmartTextarea
                    useDictation
                    isEditable={isBehave}
                    label="Behave Health Notes"
                    model="data.single_case_agreement.behave_notes"
                  />
                )}
              </FormSection>
            </ContextHide>
          </Section>

          {/* <Divider />

          <Section
            title="Attachments"
            scrollview={{
              id: 'attachments',
              name: 'Attachments',
            }}
          >
            <Attachments model="documents" />
          </Section> */}
        </>
      )}
    </DataFormOverlay>
  )
}

export const PreScreenOverlayV2 = withOverlayError(RootPreScreenOverlayV2)
