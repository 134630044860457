import React from 'react'
import { NotionRenderer as Renderer, Code, Collection, CollectionRow, Modal } from 'react-notion-x'

import 'react-notion-x/src/styles.css'
// import 'prismjs/themes/prism-tomorrow.css'

import './styles.css'

export const NotionRenderer = (props: any) => (
  <Renderer
    {...props}
    showTableOfContents={false}
    components={{
      ...props.components,
      code: Code,
      collection: Collection,
      collectionRow: CollectionRow,
      modal: Modal,
    }}
  />
)
