import React from 'react'

import EVOBTable from '../Tables/EVOBTable'

import DataView from './DataView'

type Props = {
  clientID: string
}

const EVOBDataView = ({ clientID }: Props) => {
  return (
    <DataView fetchName="evob" fetchURL={`/residents/${clientID}/insurance_evobs`} emptyDescription="No eVOBs available to display">
      {(data: any) => <EVOBTable data={data} isLoading={false} />}
    </DataView>
  )
}

export default EVOBDataView
