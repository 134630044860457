import React from 'react'
import { Route, useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { titleCase } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import Card from '@behavehealth/components/Card'
import Page from '@behavehealth/components/Page'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import Portal from '@behavehealth/components/Portal'
import State from '@behavehealth/components/State'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import { OrganizationFormSubmissionOverlay } from '@behavehealth/constructs/Organizations/OrganizationFormSubmissionOverlay'

const RootForms = () => {
  const match = useRouteMatch()
  const params: any = useParams()

  const { resource_id: id } = params

  const { data: partnership, isLoading, isError, error }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  const isShared = partnership?.other_partner_settings?.forms === true

  const tableProps: any = useDataTable({
    name: ['organization', id, 'shared-in-forms'],
    endpoint: `/organizations/${id}/other_partner_forms`,
    options: { enabled: isShared },
  })

  const isEmpty = size(tableProps.data) === 0

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value} to={`${match.url}/new-${data.id}`} />,
      },
    ],
    [match.url],
  )

  if (!partnership || isLoading) return <PageLoader />

  const partner = partnership.other_partner

  if (!isShared || isEmpty || tableProps.isLoading) {
    return (
      <Page icon="web_form" title="Forms">
        <Card>
          <State
            isEmpty={!isShared || isEmpty}
            isLoading={tableProps.isLoading}
            icon="web_form"
            title="Forms"
            emptyDescription={`${partner?.name || '–'} has not shared any Forms yet`}
          />
        </Card>
      </Page>
    )
  }

  return (
    <>
      <Page
        title="Forms"
        icon="web_form"
        actions={
          size(tableProps.data) > 0 && (
            <Dropdown label="Complete New Form" glyph="add" buttonType="primary">
              {tableProps.data.map((form: any) => (
                <DropdownItem icon="web_form" label={form.name} link={`${match.url}/new-${form.id}`} />
              ))}
            </Dropdown>
          )
        }
      >
        <DataTable {...tableProps} asCard icon="web_form" title="Shared Forms" columns={columns} />
      </Page>

      <Portal type="z90">
        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <Route exact path={`${match.path}/new-:form_id`}>
            <OrganizationFormSubmissionOverlay partner={partner} />
          </Route>

          <Route exact path={`${match.path}/:id`}>
            <OrganizationFormSubmissionOverlay partner={partner} />
          </Route>
        </AnimatedSwitch>
      </Portal>
    </>
  )
}

export const Forms = withPageError(RootForms)
