import React from 'react'
import { connect } from 'react-redux'

import Button from '../Button'
import Dialog from '../Dialog'
import Form from '../Forms/Form'
import FormSection from '../Forms/FormSection'
import Input from '../Forms/Input'
import ObjectSelector from '../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../Overlay'
import Section from '../Section'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../Overlays/pages/OverlayBase'

import withRouterV6 from '../../hocs/withRouterV6'

class TierOverlay extends OverlayBase {
  render() {
    const { $editable, isInvalid } = this.state
    const { record, online, loading } = this.props

    return (
      <Overlay onClose={this.close} showBackdrop={$editable}>
        <Overlay.Header title="Tier" icon="behave_health" />

        <Overlay.Content>
          <Section>
            <Form getForm={this.form} initialModel={record} isEditable={$editable} onValidationUpdate={this.onValidationUpdate}>
              <FormSection>
                <Input
                  label="Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a tier name',
                    },
                  }}
                />

                <Input label="Description" model="description" />

                <ObjectSelector
                  label="Add-On's"
                  model="description"
                  validations={{
                    presence: {
                      message: "Please select the add-on's that are part of this tier",
                    },
                  }}
                />
              </FormSection>
            </Form>
          </Section>
        </Overlay.Content>

        <Overlay.Footer online={online}>
          {$editable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                onClick={() => this.setState({ $editable: false })}
                isLoading={loading}
                isDisabled={isInvalid}
              />
              <Button label="Cancel" glyph="cross" onClick={this.cancel} isDisabled={loading} />
            </>
          )}

          {!$editable && (
            <>
              <Button label="Edit" glyph="edit" onClick={this.edit} flex="100 1 auto" />

              <Dialog title="Archive Tier?" message="Are you sure you want to archive this tier?" onYes={this.delete}>
                <Button label="Archive" glyph="empty" color="text" isLoading={loading} />
              </Dialog>
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'billing_products')

export default connect(mapStateToProps, mapDispatchToProps)(TierOverlay)

export const TierOverlayV6 = withRouterV6(connect(mapStateToProps, mapDispatchToProps)(TierOverlay))
