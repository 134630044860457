import React from 'react'
import { Route, Redirect, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { clearData, updateData } from '@behavehealth/actions/data'
import { store } from '@behavehealth/setup/store'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Notifications from '@behavehealth/modules/notifications'
import withBackListener from '@behavehealth/hocs/withBackListener'

import {
  Flex,
  Button,
  HelpTagIframe,
  Nav,
  PageGrid,
  PageLoader,
  PageProfile,
  PayerNavItems,
  Status,
  TagsSelector,
} from '@behavehealth/components'
import PayerNetworkStatus from '@behavehealth/components/Statuses/PayerNetworkStatus'

import AddressBook from './pages/address_book'
import Authorizations from './pages/authorizations'
import Benefits from './pages/benefits'
import Clients from './pages/clients'
import Contacts from './pages/contacts'
import FaxesAndVoicemails from './pages/faxes_and_voicemails'
import FeeSchedules from './pages/fee_schedules'
import GeneralInfo from './pages/general_info'
import InsuranceCodes from './pages/insurance_codes'
import PayerFiles from './pages/payer_files'
import PhoneBook from './pages/phone_book'
import Rules from './pages/rules'

import RCM from './pages/rcm/rcm'
import { FEATURES } from '@behavehealth/theme'
import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'

const RootLocalPayer = () => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const params: any = useParams()

  const { data, isLoading, isError, error }: any = useGet({
    name: ['insurance_payers', params.payer_id],
    url: `/insurance_local_payers/${params.payer_id}`,
  })

  const { mutateAsync, isLoading: isUpdating }: any = useUpdate({
    name: ['insurance_payers', params.payer_id],
    url: `/insurance_local_payers/${params.payer_id}`,
    invalidate: ['insurance_payers'],
  })

  React.useEffect(() => {
    if (!isError) return

    console.error(error)
    if (error?.error?.data?.errors?.[0]) {
      Notifications.send(error.error.data.errors[0], 'negative')
    }

    // go back to main page if unauthorized
    if (error?.error?.status === 401) history.push('/insurance-payers/active')
  }, [isError])

  React.useEffect(() => {
    if (!data) return

    store.dispatch(updateData(`insurance_local_payers.data.${data?.id}`, data))

    return () => {
      store.dispatch(clearData('insurance_local_payers'))
    }
  }, [data])

  if (isLoading || !data) return <PageLoader />

  return (
    <PageGrid breakpoint={3} columns="280px 1fr">
      <Nav
        showBack
        breakpoint={3}
        headingSize={400}
        record={data}
        title={data.name}
        avatar={data.avatar}
        description={
          <Flex gap="0.5rem">
            <Status label={data?.status === 'active' ? 'Active' : 'Archived'} color={data?.status === 'active' ? 'green' : 'grey'} />
            <PayerNetworkStatus status={data?.network_status} />
          </Flex>
        }
        desktopProps={{
          icon: FEATURES.insurance_payers.icon,
          title: 'Payer',
          headingSize: 300,
          avatar: undefined,
          description: undefined,
        }}
        help={<HelpTagIframe id="payer_profile" />}
        profile={
          <PageProfile
            record={data}
            title={data?.name}
            description={
              <Flex gap="0.5rem">
                <Status label={data?.status === 'active' ? 'Active' : 'Archived'} color={data?.status === 'active' ? 'green' : 'grey'} />
                <PayerNetworkStatus status={data?.network_status} />
              </Flex>
            }
            underActions={
              <Flex vertical gap="1rem" className="!mt-3 w-full">
                <TagsSelector
                  tags={data?.tags}
                  onSave={(tagIDs: any) => mutateAsync({ tag_ids: tagIDs })}
                  smartCategories="insurance_payers"
                />

                {data?.status === 'active' ? (
                  <Button
                    label="Archive"
                    glyph="delete"
                    type="default"
                    color="gray"
                    onClick={() => mutateAsync({ status: 'archived' })}
                    isLoading={isUpdating}
                  />
                ) : (
                  <Button
                    label="Un-Archive"
                    glyph="delete"
                    type="default"
                    color="gray"
                    onClick={() => mutateAsync({ status: 'active' })}
                    isLoading={isUpdating}
                  />
                )}
              </Flex>
            }
          />
        }
      >
        <PayerNavItems payerID={params?.payer_id} />
      </Nav>

      <Switch>
        <Route path={`/insurance-payers/:resource_id/general-info`} component={GeneralInfo} />
        <ProtectedRoute
          path={`/insurance-payers/:resource_id/files`}
          component={PayerFiles}
          permission="insurance_local_payers.files.view"
        />
        <ProtectedRoute
          path={`/insurance-payers/:resource_id/clients`}
          component={Clients}
          permission="insurance_local_payers.clients.view"
        />
        <Route path={`/insurance-payers/:resource_id/fee-schedules`} component={FeeSchedules} />

        <Route path={`/insurance-payers/:resource_id/address-book`} component={AddressBook} />
        <Route path={`/insurance-payers/:resource_id/phone-book`} component={PhoneBook} />
        <Route path={`/insurance-payers/:resource_id/contacts`} component={Contacts} />

        <Route path={`/insurance-payers/:resource_id/rules`} component={Rules} />
        <Route path={`/insurance-payers/:resource_id/authorizations`} component={Authorizations} />
        <Route path={`/insurance-payers/:resource_id/benefits`} component={Benefits} />
        <Route path={`/insurance-payers/:resource_id/faxes-and-voicemails`} component={FaxesAndVoicemails} />

        <Route path={`/insurance-payers/:resource_id/rcm`} component={RCM} />

        <Redirect exact from={url} to={`${url}/general-info`} />
      </Switch>
    </PageGrid>
  )
}

export default withBackListener(withPageError(RootLocalPayer))
