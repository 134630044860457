import React from 'react'

import { useDataTable } from '../../../DataTable/useDataTable'
import { AdminDiscountTemplatesDataTable } from '../../../../constructs/InternalBilling/AdminDiscountTemplatesDataTable'

export const AdminDiscountTemplatesTable = ({ onSelect }: any) => {
  const tableProps = useDataTable({
    name: ['discount_templates'],
    endpoint: '/internal_templates',
    params: { category: 'discount' },
    localStorageKey: 'discount_templates_v1',
  })

  return <AdminDiscountTemplatesDataTable {...tableProps} onClick={onSelect} />
}
