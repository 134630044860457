import React from 'react'
import get from 'lodash/get'
import isInteger from 'lodash/isInteger'
import isNaN from 'lodash/isNaN'
import isNumber from 'lodash/isNumber'
import size from 'lodash/size'

import { H3, H4 } from '../../components/Typography'
import Alert from '../../components/Alert'
import Card from '../../components/Card'
import CardTitle from '../../components/CardTitle'
import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import Field from '../../components/Forms/Field'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import CardTreeItem from '../../components/CardTreeItem'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'

import { DataFormOverlay } from '../DataFormOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

const INITIAL_STATE = {
  q1: null,
  q2: null,
  q3: null,
  q4: null,
  q5: null,
  q6: null,
  q7: null,
  q8: null,
  q9: null,
  q10: null,
  q11: null,
  q12: null,
  q13: null,
  q14: null,
  q15: null,
  q16: null,
  q17: null,
  q18: null,
  q19: null,
  q20: null,
  q21: null,
  q22: null,
  q23: null,
  q24: null,
  q25: null,
  q26: null,
  q27: null,
  q28: null,
}

const SUBSCALE_MODELS = {
  restraint: ['q1', 'q2', 'q3', 'q4', 'q5'],
  eating_concern: ['q7', 'q9', 'q19', 'q20', 'q21'],
  shape_concern: ['q6', 'q8', 'q10', 'q11', 'q23', 'q26', 'q27', 'q28'],
  weight_concern: ['q8', 'q12', 'q22', 'q24', 'q25'],
}

const QUESTION_SHORT_TITLES = {
  q1: 'Restraint over eating',
  q2: 'Avoidance of eating',
  q3: 'Food avoidance',
  q4: 'Dietary Rules',
  q5: 'Empty stomach',
  q6: 'Flat stomach',
  q7: 'Preoccupation with food, eating or calories',
  q8: 'Preoccupation with shape or weight',
  q9: 'Fear of losing control over eating',
  q10: 'Fear of weight gain',
  q11: 'Feelings of fatness',
  q12: 'Desire to lose weight',
  q19: 'Eating in secret',
  q20: 'Guilt about eating',
  q21: 'Social eating',
  q22: 'Importance of weight',
  q23: 'Importance of shape',
  q24: 'Reaction to prescribed weighing',
  q25: 'Dissatisfaction with weight',
  q26: 'Dissatisfaction with shape',
  q27: 'Discomfort seeing body',
  q28: 'Avoidance of exposure',
}

const RootEDEQ6Overlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Eating Disorder Examination Questionnaire (EDE-Q 6.0)"
      category="clinical_measurement"
      subcategory="edeq6"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <div>
          Instructions: The following questions are concerned with the past four weeks (28 days) only. Please read each question carefully.
          Please answer all the questions. Thank you.
        </div>
      </Section>

      <Divider />

      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [data, setData] = React.useState(INITIAL_STATE)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <H4>
          Questions 1 to 12: Please circle the appropriate number on the right. Remember that the questions only refer to the past four
          weeks (28 days) only.
        </H4>

        <RadioGroup
          label="1. Have you been deliberately trying to limit the amount of food you eat to influence your shape or weight (whether or not you have succeeded)?"
          model="data.questionnaire.q1"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q1: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="2. Have you gone for long periods of time (8 waking hours or more) without eating anything at all in order to influence your shape or weight?"
          model="data.questionnaire.q2"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q2: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="3. Have you tried to exclude from your diet any foods that you like in order to influence your shape or weight (whether or not you have succeeded)?"
          model="data.questionnaire.q3"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q3: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="4. Have you tried to follow definite rules regarding your eating (for example, a calorie limit) in order to influence your shape or weight (whether or not you have succeeded)?"
          model="data.questionnaire.q4"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q4: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="5. Have you had a definite desire to have an empty stomach with the aim of influencing your shape or weight?"
          model="data.questionnaire.q5"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q5: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="6. Have you had a definite desire to have a totally flat stomach?"
          model="data.questionnaire.q6"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q6: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="7. Has thinking about food, eating or calories made it very difficult to concentrate on things you are interested in (for example, working, following a conversation, or reading)?"
          model="data.questionnaire.q7"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q7: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="8. Has thinking about shape or weight made it very difficult to concentrate on things you are interested in (for example, working, following a conversation, or reading)?"
          model="data.questionnaire.q8"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q8: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="9. Have you had a definite fear of losing control over eating?"
          model="data.questionnaire.q9"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q9: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="10. Have you had a definite fear that you might gain weight?"
          model="data.questionnaire.q10"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q10: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="11. Have you felt fat?"
          model="data.questionnaire.q11"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q11: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="12. Have you had a strong desire to lose weight?"
          model="data.questionnaire.q12"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q12: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>
      </Grid>

      <Divider />

      <Grid gap="1rem">
        <H4>
          Questions 13-18: Please fill in the appropriate number in the boxes on the right. Remember that the questions only refer to the
          past four weeks (28 days).
        </H4>

        <Input
          label="13. Over the past 28 days, how many times have you eaten what other people would regards as an unusually large amount of food (given the circumstances)?"
          suffix="times"
          type="number"
          size={4}
          model="data.questionnaire.q13"
          onUpdate={(state: any) => setData((o) => ({ ...o, q13: state.value }))}
        />

        <Input
          label="14. ... On how many of these times did you have a sense of having lost control over your eating (at the time you were eating)?"
          suffix="times"
          type="number"
          size={4}
          model="data.questionnaire.q14"
          onUpdate={(state: any) => setData((o) => ({ ...o, q14: state.value }))}
        />

        <Input
          label="15. Over the past 28 days, on how many DAYS have such episodes of overeating occurred (i.e. you have eaten an unusually large amount of food and have had a sense of loss of control at the time)?"
          suffix="days"
          type="number"
          size={4}
          model="data.questionnaire.q15"
          onUpdate={(state: any) => setData((o) => ({ ...o, q15: state.value }))}
        />

        <Input
          label="16. Over the past 28 days, how many times have you made yourself sick (vomit) as a means of controlling your shape or weight?"
          suffix="times"
          type="number"
          size={4}
          model="data.questionnaire.q16"
          onUpdate={(state: any) => setData((o) => ({ ...o, q16: state.value }))}
        />

        <Input
          label="17. Over the past 28 days, how many times have you taken laxatives as a means of controlling your shape or weight?"
          suffix="times"
          type="number"
          size={4}
          model="data.questionnaire.q17"
          onUpdate={(state: any) => setData((o) => ({ ...o, q17: state.value }))}
        />

        <Input
          label="18. Over the past 28 days, how many times have you exercised in a “driven” or “compulsive” way as a means of controlling your weight, shape or amount of fat, or to burn off calories"
          suffix="times"
          type="number"
          size={4}
          model="data.questionnaire.q18"
          onUpdate={(state: any) => setData((o) => ({ ...o, q18: state.value }))}
        />
      </Grid>

      <Divider />

      <Grid gap="1rem">
        <H4>
          Questions 19 to 20: Please circle the appropriate number. Please note that for these questions the term “binge eating” means
          eating what others would regard as an unusually large amount of food for the circumstances, accompanied by a sense of having lost
          control over eating.
        </H4>

        <RadioGroup
          label="19. Over the past 28 days, on how many days have you eaten in secret (ie, furtively)?......Do not count episodes of binge eating"
          model="data.questionnaire.q19"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q19: state.value }))}
        >
          <Radio label="0 = No Days" value={0} />
          <Radio label="1 = 1-5 Days" value={1} />
          <Radio label="2 = 6-12 Days" value={2} />
          <Radio label="3 = 13-15 Days" value={3} />
          <Radio label="4 = 16-22 Days" value={4} />
          <Radio label="5 = 23-27 Days" value={5} />
          <Radio label="6 = Every Day" value={6} />
        </RadioGroup>

        <RadioGroup
          label="20. On what proportion of the times that you have eaten have you felt guilty (felt that you’ve done wrong) because of its effect on your shape or weight? ... Do not count episodes of binge eating."
          model="data.questionnaire.q20"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q20: state.value }))}
        >
          <Radio label="0 = None of the times" value={0} />
          <Radio label="1 = A few of the times" value={1} />
          <Radio label="2 = Less than half" value={2} />
          <Radio label="3 = Half of the times" value={3} />
          <Radio label="4 = More than half" value={4} />
          <Radio label="5 = Most of the time" value={5} />
          <Radio label="6 = Every time" value={6} />
        </RadioGroup>
      </Grid>

      <Divider />

      <Grid gap="1rem">
        <H4>In the past 28 days…</H4>

        <RadioGroup
          label="21. How concerned have you been about other people seeing you eat? (Do not count episodes of binge eating)"
          model="data.questionnaire.q21"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q21: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Not much" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Very much" value={5} />
          <Radio label="6 = Markedly" value={6} />
        </RadioGroup>

        <RadioGroup
          label="22. Has your weight influenced how you think about (judge) yourself as a person?"
          model="data.questionnaire.q22"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q22: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Not much" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Very much" value={5} />
          <Radio label="6 = Markedly" value={6} />
        </RadioGroup>

        <RadioGroup
          label="23. Has your shape influenced how you think about (judge) yourself as a person?"
          model="data.questionnaire.q23"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q23: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Not much" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Very much" value={5} />
          <Radio label="6 = Markedly" value={6} />
        </RadioGroup>

        <RadioGroup
          label="24. How much would it have upset you if you had been asked to weigh yourself once a week (no more, or less, often) for the next four weeks?"
          model="data.questionnaire.q24"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q24: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Not much" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Very much" value={5} />
          <Radio label="6 = Markedly" value={6} />
        </RadioGroup>

        <RadioGroup
          label="25. How dissatisfied have you been with your weight?"
          model="data.questionnaire.q25"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q25: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Not much" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Very much" value={5} />
          <Radio label="6 = Markedly" value={6} />
        </RadioGroup>

        <RadioGroup
          label="26. How dissatisfied have you been with your shape?"
          model="data.questionnaire.q26"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q26: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Not much" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Very much" value={5} />
          <Radio label="6 = Markedly" value={6} />
        </RadioGroup>

        <RadioGroup
          label="27. How uncomfortable have you felt seeing your body (for example, seeing your shape in the mirror, in a shop window reflection, while undressing or taking a bath or shower)?"
          model="data.questionnaire.q27"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q27: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Not much" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Very much" value={5} />
          <Radio label="6 = Markedly" value={6} />
        </RadioGroup>

        <RadioGroup
          label="28. How uncomfortable have you felt about others seeing your shape or figure (for example, in communal changing rooms, when swimming, or wearing tight clothes)?"
          model="data.questionnaire.q28"
          layout="vertical-dense"
          onUpdate={(state: any) => setData((o) => ({ ...o, q28: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Not much" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Very much" value={5} />
          <Radio label="6 = Markedly" value={6} />
        </RadioGroup>
      </Grid>

      <Divider />

      <Grid gap="1rem">
        <Input
          label="What is your weight at present? (Please give your best estimate.):"
          type="number"
          model="data.weight"
          suffix="lbs"
          size={4}
        />

        <Field label="What is your height? (Please give your best estimate.):" labelWidth="120px" labelAlign="right" className="!flex-auto">
          <Flex gap={8} alignItems="center">
            <Input type="number" model="data.height.ft" suffix="ft" size={4} />
            <Input type="number" model="data.height.in" suffix="in" size={4} />
          </Flex>
        </Field>

        <YesNoRadioGroup
          label="If female: Over the past three to four months have you missed any menstrual periods?"
          model="data.did_miss_menstrual_periods"
        />

        <ContextShow when="data.did_miss_menstrual_periods" is={true}>
          <Input label="If so, how many?:" type="number" size={4} model="data.times_missed_menstrual_periods" />
        </ContextShow>

        <YesNoRadioGroup label="Have you been taking the “pill”?:" model="data.has_been_taking_the_pill" />
      </Grid>

      {!isPortal && data && (
        <>
          <Divider />
          <Scoring data={data} />
        </>
      )}

      <div className="!mt-4">© 2008 Christopher G Fairburn and Sarah Beglin</div>
    </>
  )
}

const Scoring = ({ data }: any) => {
  const scores = React.useMemo(() => {
    const restraint = getSubscaleScore('restraint', data)
    const eatingConcern = getSubscaleScore('eating_concern', data)
    const shapeConcern = getSubscaleScore('shape_concern', data)
    const weightConcern = getSubscaleScore('weight_concern', data)

    let globalScore = null

    if (isNumber(restraint) && isNumber(eatingConcern) && isNumber(shapeConcern) && isNumber(weightConcern)) {
      globalScore = restraint + eatingConcern + shapeConcern + weightConcern
    }

    return {
      restraint,
      eatingConcern,
      shapeConcern,
      weightConcern,
      globalScore,
    }
  }, [data])

  const isGlobalScoreValid = isNumber(scores.globalScore) && !isNaN(scores.globalScore)

  return (
    <Grid gap="0.75rem">
      <H3>Scoring</H3>

      <CardTreeItem title="More Info">
        <Grid gap="0.5rem" css={{ padding: '1rem 0' }}>
          <div>
            The EDE, and its self-report version, the EDE-Q, generate two types of data. First, they provide frequency data on key
            behavioural features of eating disorders in terms of number of episodes of the Behaviour and in some instances number of days on
            which the behaviour has occurred. Second, they provide subscale scores reflecting the severity of aspects of the psychopathology
            of eating disorders.
          </div>

          <H4 className="!mt-2">Global Score</H4>
          <div>
            To obtain an overall or 'global' score, the four subscales scores are summed and the resulting total divided by the number of
            subscales (i.e. four).
          </div>

          <H4 className="!mt-2">Subscales</H4>
          <div>
            The subscales are Restraint, Eating Concern, Shape Concern and Weight Concern. To obtain a particular subscale score, the
            ratings for the relevant items (listed below) are added together and the sum divided by the total number of items forming the
            subscale. If ratings are only available on some items, a score may nevertheless be obtained by dividing the resulting total by
            the number of rated items so long as more than half the items have been rated.
          </div>

          <div>Subscale scores are reported as means and standard deviations.</div>
        </Grid>
      </CardTreeItem>

      <ScoreCard title="Restraint" subscale="restraint" data={data} score={scores.restraint} />
      <ScoreCard title="Eating Concern" subscale="eating_concern" data={data} score={scores.eatingConcern} />
      <ScoreCard title="Shape Concern" subscale="shape_concern" data={data} score={scores.shapeConcern} />
      <ScoreCard title="Weight Concern" subscale="weight_concern" data={data} score={scores.weightConcern} />

      <Card css={STYLES.card}>
        <Grid gap="0.75rem">
          <CardTitle title={isGlobalScoreValid ? `Global Score: ${scores.globalScore}` : 'Global Score'} css={STYLES.cardTitleLarge} />

          {!isGlobalScoreValid && (
            <Alert small type="warning" glyph="warning">
              Global score can be defined only if all subscales are scored.
            </Alert>
          )}
        </Grid>
      </Card>
    </Grid>
  )
}

const ScoreCard = ({ data, title, score, subscale }: any) => {
  const isScoreValid = isNumber(score) && !isNaN(score)
  const subscaleModels = SUBSCALE_MODELS?.[subscale]

  if (!subscaleModels) return null

  return (
    <Card css={STYLES.card}>
      <Grid gap="0.75rem">
        {!isScoreValid && (
          <Alert small type="warning" glyph="warning">
            Please answer at least half of the questions in this subscale to get a score.
          </Alert>
        )}

        <CardTitle title={isScoreValid ? `${title}: ${score}` : title} css={STYLES.cardTitle} />

        <Grid gap="0.15rem">
          {subscaleModels.map((model: string) => {
            const shortTitle = QUESTION_SHORT_TITLES[model]

            const questionAnswer = get(data, model)
            const isAnswerValid = isInteger(questionAnswer)

            return (
              <div key={model} css={STYLES.listItem} className={isAnswerValid ? 'is-valid' : 'is-invalid'}>
                <Glyph size={16} glyph={isAnswerValid ? 'tick_circle' : 'cross'} />
                <div>
                  <b>{model.replace('q', '')}.</b> {shortTitle}
                </div>
              </div>
            )
          })}
        </Grid>
      </Grid>
    </Card>
  )
}

type Subscale = 'restraint' | 'eating_concern' | 'shape_concern' | 'weight_concern'

const getSubscaleScore = (subscale: Subscale, data: any) => {
  const subscaleModels = SUBSCALE_MODELS?.[subscale]

  if (!subscaleModels || !data) return null

  // at least have of the questions must be answered to get a score
  const minAnswersRequired = Math.floor(size(subscaleModels) / 2)
  const answers: any = []

  for (const model of subscaleModels) {
    const answer = parseInt(get(data, model))

    if (isInteger(answer)) answers.push(answer)
  }

  const answersCount = size(answers)

  if (answersCount < minAnswersRequired) return null

  const result = answers.reduce((a, b) => a + b, 0) / answersCount

  return parseFloat(result.toFixed(2))
}

const STYLES = {
  card: {
    padding: '1rem',
  },

  cardTitle: {
    fontSize: '1.17rem',
    fontWeight: 700,
  },

  cardTitleLarge: {
    fontSize: '1.3rem',
    fontWeight: 700,
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',

    '& > svg': { marginRight: '0.35rem' },

    '&.is-invalid': { opacity: 0.4 },
  },
}

export const EDEQ6Overlay = withOverlayError(RootEDEQ6Overlay)
