import React from 'react'

import withSettings from '../../../hocs/withSettings'

import Alert from '../../../components/Alert'
import Card from '../../../components/Card'
import ContextShow from '../../../components/Forms/ContextShow'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Flex from '../../../components/Flex'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Grid from '../../../components/Grid'
import Icon from '../../../components/Icon'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Workflow from '../../../components/Workflow/Workflow'

import { ProgramAssignmentInfo } from '../../Programs/ProgramAssignmentInfo'

const ProgramRemove = ({ data, setData, setStatus, timezone, occupancy }: any) => {
  const form = React.useRef()
  const [valid, setValid] = React.useState()

  const { goNext }: any = React.useContext(Workflow.Context)

  const handleUpdate = () => {
    setData(form.current?.getFormValue())
    valid ? setStatus('completed') : setStatus('todo')
  }

  return (
    <Form
      useFullModel
      getForm={form}
      initialModel={data}
      onUpdate={handleUpdate}
      onValidationUpdate={setValid}
      timezone={timezone}
      defaultDebounce={300}
    >
      <FormSection>
        <Card>
          <Flex centerY gap="0.75rem" css={{ padding: '0.7rem 1rem 0.4rem' }}>
            <Icon icon="programs" size={20} />
            <div className="text-[1.1rem] font-[700]">Current Program Assignment</div>
          </Flex>

          <ProgramAssignmentInfo data={occupancy} />

          <Grid gap="1rem" css={{ padding: '0.5rem 1rem 0.75rem' }}>
            <RadioGroup
              label="When would you like to end the Current Program Assignment?"
              model="use_custom_move_date"
              layout="horizontal-dense"
              defaultValue={false}
            >
              <Radio label="End Now" value={false} />
              <Radio label="Another Time" value={true} />
            </RadioGroup>

            <ContextShow when="use_custom_move_date" is={true}>
              <DateTimeInput
                defaultToNow
                label="Current Program Assignment End Date"
                model="custom_moved_at"
                validations={{
                  presence: {
                    message: 'Please enter an end date',
                  },
                }}
              />

              <Alert type="warning">
                Please select the date when the above <strong>Program Assignment</strong> should end.
              </Alert>
            </ContextShow>
          </Grid>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton
            onClick={() => {
              handleUpdate()
              goNext()
            }}
            isDisabled={!valid}
          />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default withSettings(ProgramRemove)
