import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const statuses = {
  pending: {
    label: 'pending',
    color: 'orange',
  },
  signed: {
    label: 'Signed',
    color: 'green',
  },
}

export const RoundSignatureStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}
