import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import pluralize from 'pluralize'
import size from 'lodash/size'
import { Link as LinkV6, NavLink } from 'react-router-dom-v5-compat'

import { useRouteURL } from '@behavehealth/hooks/useRouteURL'
import { useAPI } from '@behavehealth/hooks'
import { amount, isWebGLAvailable, usDate, isDefined, nicePercentage, niceAmount, titleCase } from '@behavehealth/utils/functions'
import { clearData } from '@behavehealth/actions/data'

import {
  Alert,
  Accordion,
  DataList,
  Button,
  Card,
  CardHeader,
  Flex,
  Status,
  Glyph,
  Grid,
  Type,
  LineChart,
  Link,
  State,
  ConfirmDialog,
  SummonOverlay,
  Markup,
} from '@behavehealth/components'

import Divider from '@behavehealth/components/Divider'
import Icon from '@behavehealth/components/Icon'
import PageLayout from '@behavehealth/components/PageLayout'
import PageSection from '@behavehealth/components/PageSection/PageSection'
import SubscriptionStatus from '@behavehealth/constructs/Account/SubscriptionStatus'
import InternalNotesOverlay from '@behavehealth/components/Billing/InternalNotesOverlay'

import { buildPriceLabel } from '@behavehealth/components/Billing/constants'
import { apiGet, apiUpdate } from '@behavehealth/modules/api'

import { FacilityQuotes } from './components/FacilityQuotes'
import { useSettings } from '@behavehealth/hooks/useSettings'

const formatDiscount = (discount: any) => {
  if (!discount) return <span className="text-text-muted">–</span>

  let info: any = ''

  const { discount_type, percent_off, amount_off } = discount

  if (discount_type === 'percentage') {
    info = `${nicePercentage(percent_off)}% off`
  } else if (discount_type === 'fixed') {
    info = `${niceAmount(amount_off)} off`
  }

  return (
    <span className="flex items-center">
      {info}
      <Status small label={titleCase(discount.status)} color={discount.status === 'active' ? 'green' : 'gray'} className="ml-1.5" />
    </span>
  )
}

const SharedDataListItems = ({ data, onRefreshEstimate }: any) => {
  const { isBehave, timezone } = useSettings()

  if (!data) return null

  return (
    <>
      {data.discount?.id && (
        <>
          <DataList.Item label="Discount" value={formatDiscount(data.discount)} />

          <DataList.Item label="Discount Type" value={titleCase(data.discount.duration)} />

          {data.discount.duration === 'once' || data.discount.duration === 'number_of_months' ? (
            <DataList.Item
              label="Discount Redemptions"
              value={
                <div className="flex items-center flex-nowrap">
                  <span>{data.discount.redemptions} used</span>
                  <span className="mx-1 text-text-muted opacity-80">/</span>
                  <span>{data.discount.max_redemptions} max</span>
                </div>
              }
            />
          ) : (
            <>
              {data.discount.duration === 'forever' && (
                <DataList.Item
                  label="Discount Redemptions"
                  value={
                    <div className="flex items-center flex-nowrap">
                      <span>{data.discount.redemptions} used / ∞</span>
                    </div>
                  }
                />
              )}

              {data.discount.duration === 'until' && (
                <>
                  <DataList.Item label="Discount Redemptions" value={`${data.discount.redemptions} used`} />
                  <DataList.Item label="Discount Valid Until" value={usDate(data.discount.valid_until, timezone)} />
                </>
              )}
            </>
          )}
        </>
      )}

      <DataList.Item
        label="Estimated Cost:"
        value={
          <Flex gap="0.5rem" className="items-center">
            {data.discount?.id ? (
              <>
                <span className="text-text-muted opacity-80 line-through">{niceAmount(data?.current_period_estimated_cost)}</span>
                <span className="font-[600]">{niceAmount(data?.current_period_estimated_discounted_cost)}</span>
              </>
            ) : (
              <span>{niceAmount(data?.current_period_estimated_cost)}</span>
            )}

            <Button
              label="Refresh"
              type="link"
              display="inline-flex"
              size={100}
              onClick={async () => {
                await onRefreshEstimate?.(data)
              }}
            />
          </Flex>
        }
      />
    </>
  )
}

const ActiveSubscriptions = () => {
  const dispatch = useDispatch()

  const { timezone, tenant, isBehave } = useSettings()

  const statsLoading = useSelector((state) => state.data.stats?.loading)
  const stats = useSelector((state) => state.data.stats?.data)

  const subscriptionsLoading = useSelector((state) => state.data.stats?.loading)
  const subscriptions = useSelector((state) => state.data.subscriptions?.data)
  const hasSubscriptions = size(subscriptions) > 0

  const [platformPlans, setPlatformPlans] = React.useState([])
  const [plans, setPlans] = React.useState([])
  const [addons, setAddons] = React.useState([])

  const hasPlatformPlans = size(platformPlans) > 0
  const hasPlans = size(plans) > 0
  const hasAddons = size(addons) > 0

  useAPI('stats', `/subscriptions/stats`)
  useAPI('subscriptions', `/subscriptions?status=active`)

  const cancelPlan = async (plan: any) => {
    try {
      dispatch(clearData('subscriptions'))

      await apiUpdate({
        name: 'subscriptions',
        url: `/subscriptions/${plan.id}/cancel`,
        notify: false,
      })

      await apiGet({ url: '/me', reducer: 'me' })
    } catch (errors) {
      console.error(errors)
    }
  }

  const cancelAddon = async (addon: any) => {
    try {
      dispatch(clearData('subscriptions'))

      await apiUpdate({
        name: 'subscriptions',
        url: `/subscriptions/${addon.id}/cancel`,
        notify: false,
      })

      await apiGet({ url: '/me', reducer: 'me' })
    } catch (errors) {
      console.error(errors)
    }
  }

  const refreshEstimate = async (addon) => {
    try {
      await apiUpdate({
        name: 'subscriptions',
        url: `/subscriptions/${addon.id}/refresh_estimate`,
      })
    } catch (errors) {
      console.error(errors)
    }
  }

  React.useEffect(() => {
    if (!subscriptions) return

    const newPlatformPlans = Object.values(subscriptions).filter(
      (sub: any) => sub?.status === 'active' && sub?.product?.category === 'platform_plan',
    )
    const newPlans = Object.values(subscriptions).filter((sub: any) => sub?.status === 'active' && sub?.product?.category === 'plan')
    const newAddons = Object.values(subscriptions).filter((sub: any) => sub?.status === 'active' && sub?.product?.category === 'addon')

    setPlatformPlans(newPlatformPlans)
    setPlans(newPlans)
    setAddons(newAddons)
  }, [subscriptions])

  return (
    <PageLayout className="!grid-cols-[100%]">
      <Grid gap="1rem" columns="repeat(auto-fit, minmax(280px, 1fr))" className="mt-1">
        {statsLoading ? (
          <State isLoading={true} minHeight={10} />
        ) : (
          <>
            <Card baseline="3rem">
              <CardHeader graphic={<Glyph glyph="behave_health" size={20} />}>
                <Type as="h3" variant="H4" css={styles.cardTitle}>
                  {stats?.subscriptions}
                </Type>
                <Type as="h3" variant="CAPS_TITLE_SMALL" css={styles.cardSubtitle}>
                  Active {pluralize('Products', stats?.subscriptions)}
                </Type>
              </CardHeader>
            </Card>

            <Card baseline="3rem">
              <CardHeader graphic={<Glyph glyph="date" size={20} />}>
                <Type as="h3" variant="H4" css={styles.cardTitle}>
                  {usDate(stats?.next_invoice_date, timezone)}
                </Type>
                <Type as="h3" variant="CAPS_TITLE_SMALL" css={styles.cardSubtitle}>
                  Next Invoice Date
                </Type>
              </CardHeader>
            </Card>

            <Card baseline="3rem">
              <CardHeader graphic={<Glyph glyph="dollar" size={20} />}>
                <Type as="h3" variant="H4" color="green" css={styles.cardTitle}>
                  ${stats?.credits}
                </Type>
                <Type as="h3" variant="CAPS_TITLE_SMALL" css={styles.cardSubtitle}>
                  Active Credits
                </Type>
              </CardHeader>
            </Card>
          </>
        )}
      </Grid>

      <Divider className="!my-0" />

      {(isBehave || tenant?.metadata?.plans_notes) && (
        <PageSection>
          <PageSection.Header
            after={
              <Flex gap="0.5rem">
                <SummonOverlay
                  isPermissible={isBehave}
                  overlay={<InternalNotesOverlay title="Edit Notes" data={tenant} model="metadata.plans_notes" />}
                >
                  <Button label="Edit Notes" glyph="edit" type="default" size={200} permission={isBehave} />
                </SummonOverlay>
              </Flex>
            }
          >
            <PageSection.Title title="Notes" />
          </PageSection.Header>

          {tenant?.metadata?.plans_notes && (
            <PageSection.Content>
              <Card className="!p-4">
                <Markup value={tenant?.metadata?.plans_notes} />
              </Card>
            </PageSection.Content>
          )}
        </PageSection>
      )}

      <FacilityQuotes />

      {hasSubscriptions && (
        <>
          <Divider className="!my-0" />

          <PageSection>
            <PageSection.Header
              graphic={
                <div className="flex items-center justify-center w-6 h-6">
                  <Icon icon="certificate" size={20} />
                </div>
              }
            >
              <PageSection.Title title="Platform Plan" />
            </PageSection.Header>

            <Grid gap="0.3rem">
              <Alert contrast glyph="info">
                If you'd like to make any changes to the active products below, please{' '}
                <a href="mailto:invoices@behavehealth.com>">contact us</a>.
              </Alert>

              {hasPlatformPlans &&
                platformPlans?.map((plan: any) => (
                  <Accordion
                    isOpen
                    key={plan.id}
                    icon={plan?.product?.icon || '–'}
                    title={
                      <Flex centerY gap="0.5rem">
                        <div>{plan?.product?.name}</div>
                        <SubscriptionStatus status={plan?.status} />
                        {plan?.product?.discounts_count > 0 && <Status color="green" label="Discounted" />}
                      </Flex>
                    }
                    description={`${buildPriceLabel(
                      plan?.product?.pricing_type,
                      isDefined(plan?.product?.discounted_price) ? plan?.product?.discounted_price : plan?.product?.price,
                      plan?.product?.price_unit_type,
                    )}`}
                  >
                    <DataList labelWidth={200}>
                      {plan?.status === 'trialing' ? (
                        <>
                          <DataList.Item label="Trial Start Date:" value={usDate(plan?.trial_start, timezone)} />
                          <DataList.Item label="Trial End Date:" value={usDate(plan?.trial_end, timezone)} />
                        </>
                      ) : (
                        <>
                          <DataList.Item label="Start Date:" value={usDate(plan?.started_at, timezone)} />
                          <DataList.Item
                            label="Current Billing Period:"
                            value={`${usDate(plan?.current_period_start, timezone)} - ${usDate(plan?.current_period_end, timezone)}`}
                          />
                          <SharedDataListItems data={plan} onRefreshEstimate={refreshEstimate} />
                        </>
                      )}
                    </DataList>
                  </Accordion>
                ))}

              {!hasPlatformPlans && (
                <Card>
                  <State
                    isEmpty
                    emptyDescription={
                      <>
                        Not subscribed to any Platform Plans yet. Go to <Link to="../products">Plans & Addon's</Link> to start.
                      </>
                    }
                  />
                </Card>
              )}
            </Grid>
          </PageSection>

          <PageSection>
            <PageSection.Header
              graphic={
                <div className="flex items-center justify-center w-6 h-6">
                  <Icon icon="certificate" size={20} />
                </div>
              }
            >
              <PageSection.Title title="Plans" />
            </PageSection.Header>

            <Grid gap="0.3rem">
              {hasPlans &&
                plans.map((plan: any) => (
                  <Accordion
                    key={plan?.id}
                    icon={plan?.product?.icon}
                    title={
                      <Flex centerY gap="0.5rem">
                        <div>{plan?.product?.name}</div>
                        <SubscriptionStatus status={plan?.status} />
                        {plan?.product?.discounts_count > 0 && <Status color="green" label="Discounted" />}
                        {plan.cancel_at_period_end && <Status color="grey" label={`Canceling automatically on Period End`} />}
                      </Flex>
                    }
                    description={`${buildPriceLabel(
                      plan?.product?.pricing_type,
                      isDefined(plan?.product?.discounted_price) ? plan?.product?.discounted_price : plan?.product?.price,
                      plan?.product?.price_unit_type,
                    )}`}
                    actions={
                      !plan.cancel_at_period_end && (
                        <ConfirmDialog
                          glyph="behave_health"
                          title="Cancel this Plan?"
                          yesColor="red"
                          message="You will lose access to all the features included with the Plan. Please note that we will keep your data available in case you change your mind and want to re-activate later."
                          onYes={() => cancelPlan(plan)}
                        >
                          <Button label="Cancel Plan" type="default" color="red" size={200} display="inline-flex" />
                        </ConfirmDialog>
                      )
                    }
                  >
                    <DataList labelWidth={200}>
                      <DataList.Item
                        label="Current Billing Period:"
                        value={`${usDate(plan?.current_period_start, timezone)} - ${usDate(plan?.current_period_end, timezone)}`}
                      />
                      <SharedDataListItems data={plan} onRefreshEstimate={refreshEstimate} />
                    </DataList>
                  </Accordion>
                ))}

              {!hasPlans && (
                <Card>
                  <State isEmpty minHeight={200} icon="behave_health" emptyDescription="Not subscribed to any Plans yet" />
                </Card>
              )}
            </Grid>
          </PageSection>

          <PageSection>
            <PageSection.Header
              graphic={
                <div className="flex items-center justify-center w-6 h-6">
                  <Icon icon="certificate" size={20} />
                </div>
              }
            >
              <PageSection.Title title="Add-On's" />
            </PageSection.Header>

            <Grid gap="0.3rem">
              {hasAddons &&
                addons.map((addon) => (
                  <Accordion
                    key={addon.id}
                    icon={addon?.product?.icon}
                    title={
                      <Flex centerY gap="0.5rem">
                        <div>{addon?.product?.name}</div>
                        <SubscriptionStatus status={addon?.status} />
                        {addon?.product?.discounts_count > 0 && <Status color="green" label="Discounted" />}
                        {addon.cancel_at_period_end && <Status color="grey" label={`Canceling automatically on Period End`} />}
                      </Flex>
                    }
                    description={`${buildPriceLabel(
                      addon?.product?.pricing_type,
                      isDefined(addon?.product?.discounted_price) ? addon?.product?.discounted_price : addon?.product?.price,
                      addon?.product?.price_unit_type,
                    )}`}
                    actions={
                      !addon.cancel_at_period_end && (
                        <ConfirmDialog
                          glyph="behave_health"
                          title="Cancel this Add-On?"
                          yesColor="red"
                          message="You will lose access to all the features included with the Add-On. Please note that we will keep your data available in case you change your mind and want to re-activate later."
                          onYes={() => cancelAddon(addon)}
                        >
                          <Button label="Cancel Add-On" type="default" color="red" size={200} display="inline-flex" />
                        </ConfirmDialog>
                      )
                    }
                  >
                    <DataList labelWidth={200}>
                      <DataList.Item
                        label="Current Billing Period:"
                        value={`${usDate(addon?.current_period_start, timezone)} - ${usDate(addon?.current_period_end, timezone)}`}
                      />
                      <SharedDataListItems data={addon} onRefreshEstimate={refreshEstimate} />
                    </DataList>
                  </Accordion>
                ))}

              {!hasAddons && (
                <Card>
                  <State isEmpty minHeight={200} icon="behave_health" emptyDescription="Not subscribed to any Add-On's yet" />
                </Card>
              )}
            </Grid>
          </PageSection>
        </>
      )}
    </PageLayout>
  )
}

const styles = {
  cardTitle: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },

  cardSubtitle: {
    fontSize: '0.85rem',
  },

  heading: {
    margin: '1.5rem 0 0.75rem',
  },
}

export default ActiveSubscriptions
