import { transparentize, darken, lighten, tint, setHue, setSaturation } from 'polished'

class ColorString extends String {
  darken = (amount) => new ColorString(darken(amount, this.toString()))
  lighten = (amount) => new ColorString(lighten(amount, this.toString()))
  tint = (amount) => new ColorString(tint(amount, this.toString()))
  setHue = (amount) => new ColorString(setHue(amount, this.toString()))
  setSaturation = (amount) => new ColorString(setSaturation(amount, this.toString()))
  transparentize = (amount) => new ColorString(transparentize(amount, this.toString()))
}

const COLORS: any = {
  default: 'hotpink',

  // Behave Health Palette
  blue: '#336BFD',
  gray: '#48585F',
  lightGray: '#dcdfe9',
  green: '#0F9913',
  mintGreen: '#00AD6F',
  paleGreen: '#76BE71',
  orange: '#FF8A00',
  paleBlue: '#5482FF',
  purple: '#8D70FF',
  red: '#F23E2E',
  violet: '#5E44FC',
  vividBlue: '#33C2FF',
  yellow: '#F4A306',
  gold: '#FFD700',
  white: '#FFF',
  black: '#111',
  deepBlack: '#000',
  pink: '#E84393',

  // Text
  link: '#0084F8',
  text: '#383859',
  textMuted: transparentize(0.25, '#383859'),
  textLight: transparentize(0.15, '#383859'),
  textStronglyMuted: transparentize(0.5, '#383859'),

  // Rest
  backdrop: 'rgba(12, 62, 106, 0.2)', // dark transparent layer behind overlays / dialogs
  divider: 'rgba(52, 73, 129, 0.12)', // for all dividers / separators
  hover: 'rgba(52, 73, 129, 0.04)', // hover background for cards
  lightBackground: '#F5F6FA', // e.g. app background
  darkBackground: '#EBEDF5', // e.g. darker bg on top of app bg
  lightShadow: 'rgba(12, 62, 106, 0.015)', // default shadow color
  shadow: 'rgba(12, 62, 106, 0.03)', // default shadow color
  darkShadow: 'rgba(12, 62, 106, 0.1)', // default shadow color
  transparent: 'rgba(255, 255, 255, 0)', // recommended instead of the 'transparent' keyword in CSS
  banding: tint(0.25, '#F5F6FA'),

  // Brands
  cash_app: '#00D54B',
  github: '#333333',
  linkedin: '#0a66c2',
  facebook: '#0866ff',
  facebookMessenger: '#0084ff',
  googleMeet: '#00897B',
  paypal: '#009cde',
  skype: '#00aff0',
  stripe: '#7972ff',
  venmo: '#3d95ce',
  whatsapp: '#28d366',
  youtube: '#ff0000',
  zelle: '#6d1ed4',
  zoom: '#2196F3',
  lottie: '#00DDB3',
  hubspot: '#ff5c35',
  mermaid: '#ff3670',
}

export const getColor = (color) => {
  return COLORS[color] || color
}

export const getPolishedColor = (color) => new ColorString(color)

export default COLORS
