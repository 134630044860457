import React from 'react'

import { useSettings } from '../../../hooks/useSettings'
import { usDateTime } from '../../../utils/functions'

import Button from '../../../components/Button'
import Divider from '../../../components/Divider'
import SummonOverlay from '../../../components/SummonOverlay'
import DataList from '../../../components/DataList'

import { ChecklistItem } from '../components/ChecklistItem'
import { ProfileContactDetails } from '../components/ProfileContactDetails'
import { ProfileWithTooltip } from '../components/ProfileWithTooltip'
import { ReferralCard } from '../components/ReferralCard'
import { ReferralColumn } from '../components/ReferralColumn'

import { ReferralUpdatesDataTable } from '../ReferralUpdatesDataTable'

import { PartnerServicesOverlay } from './PartnerServicesOverlay'

export const ReferralDetails = (props: any) => {
  const {
    isNew,
    data,
    setData,
    timezone,
    initialData,
    isEditable,
    setFormValid,
    client,
    setClient,
    partners,
    setPartners,
    partner,
    setPartner,
  } = props

  const { tenant } = useSettings()

  const handleUpdate = (value: any) => {
    setData(value)
  }

  const [selectedPartner, setSelectedPartner] = React.useState<any>(null)

  return (
    <ReferralColumn icon="dashboard" title="Summary">
      <div className="grid grid-cols-1 gap-2">
        <div className="grid grid-cols-2 grid-rows-[100%] gap-4">
          <ReferralCard icon="organizations" title="Referring From">
            <ChecklistItem title="Organization">
              <ProfileWithTooltip
                name={data.sent_from.name}
                description="Healthcare Facility"
                tooltipContent={
                  <ProfileContactDetails
                    name={data.sent_from.name}
                    after={
                      <Button
                        label="View Services"
                        type="primary"
                        size={200}
                        onClick={() => {
                          setSelectedPartner(data.sent_from)
                        }}
                      />
                    }
                  />
                }
              />
            </ChecklistItem>

            <ChecklistItem title="Assigned Staff" className="!border-0 !shadow-none" css={{ boxShadow: 'none!important' }}>
              <ProfileWithTooltip
                name={data.author.name}
                description="Administrator"
                tooltipContent={<ProfileContactDetails name={data.author.name} />}
              />
            </ChecklistItem>

            <div className="px-2 mt-4">
              <div className="uppercase font-[700] tracking-[1px] text-[0.85rem] opacity-80">Checklist</div>
            </div>

            <ChecklistItem title="Send Client Face Sheet" status="completed" />
            <ChecklistItem title="Send Client Records" status="pending" />
            <ChecklistItem title="Confirm Client Departure" status="pending" />
          </ReferralCard>

          <ReferralCard icon="organizations" title="Referring To">
            <ChecklistItem title="Organization">
              <ProfileWithTooltip
                name={data.sent_to.name}
                description="Healthcare Facility"
                tooltipContent={
                  <ProfileContactDetails
                    name={data.sent_to.name}
                    after={
                      <Button
                        label="View Services"
                        type="primary"
                        size={200}
                        onClick={() => {
                          setSelectedPartner(data.sent_to)
                        }}
                      />
                    }
                  />
                }
              />
            </ChecklistItem>

            <ChecklistItem title="Assigned Staff" className="!border-0 !shadow-none" css={{ boxShadow: 'none!important' }}>
              <div className="opacity-50">–</div>
            </ChecklistItem>

            <div className="px-2 mt-4">
              <div className="uppercase font-[700] tracking-[1px] text-[0.85rem] opacity-80">Checklist</div>
            </div>

            <ChecklistItem title="Confirm Availability" status="completed" />
            <ChecklistItem title="Accept / Decline Referral" status="pending" />
            <ChecklistItem title="Confirm Client Arrival" status="pending" />
          </ReferralCard>
        </div>

        <ReferralCard icon="general_info" title="Referral Details">
          <DataList className="px-4 pb-2 text-[0.95rem]">
            <DataList.Item label="Requested First Appointment Date" value={usDateTime(data.referred_at)} />
            <DataList.Item label="Notes" value={<div className="whitespace-pre">{data.notes}</div>} />
          </DataList>
        </ReferralCard>

        <ReferralCard minimal icon="referrals" title="Referral Updates">
          <ReferralUpdatesDataTable data={data.updates} />
        </ReferralCard>
      </div>

      <SummonOverlay
        isOpen={!!selectedPartner}
        onClose={() => {
          setSelectedPartner(null)
        }}
        overlay={<PartnerServicesOverlay name={selectedPartner?.name} />}
      ></SummonOverlay>
    </ReferralColumn>
  )
}
