import React from 'react'
import { darken } from 'polished'
import clsx from 'clsx'

import { COLORS } from '../../theme'
import { Color } from '../../declarations/types'

type Props = {
  is: string
  color?: Color
  allCaps?: boolean
  bold?: boolean
  italic?: boolean
  lineHeight: number | string
} & React.HTMLAttributes<any>

const TypeBase: React.FC<Props> = (props) => {
  const { allCaps, bold, children, className, color = 'text', is = 'div', italic, lineHeight = '1.44em', onClick } = props

  const classNames = clsx({
    'is-uppercase': allCaps,
    [className]: className,
  })

  const Tag = is

  return (
    <Tag
      className={classNames}
      onClick={onClick}
      css={[
        styles,
        {
          lineHeight,
          color: darken(0.1, COLORS[color] || color),
          fontWeight: bold && '700',
          fontStyle: italic && 'italic',
        },
      ]}
    >
      {children}
    </Tag>
  )
}

const styles: any = {
  '&.is-uppercase': {
    fontWeight: 600,
    letterSpacing: '0.75px',
    textTransform: 'uppercase',
  },

  '&.is-muted': {
    color: COLORS.textMuted,
  },
}

export default TypeBase
