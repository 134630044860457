import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'

import { withOverlayError } from '../../hocs/withOverlayError'
import { usDate, titleCase, niceAmount } from '../../utils/functions'

import Alert from '../Alert'
import AmountInput from '../Forms/AmountInput'
import Button from '../Button'
import Divider from '../Divider'
import DateInput from '../Forms/DateInput'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Flex from '../Flex'
import Form from '../Forms/Form'
import FormSection from '../Forms/FormSection'
import Input from '../Forms/Input'
import ObjectSelector from '../Forms/Selectors/Object/ObjectSelector'
import Option from '../Forms/Option'
import Overlay from '../Overlay'
import OverlayLoader from '../OverlayLoader'
import Section from '../Section'
import Select from '../Forms/Select'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../Overlays/pages/OverlayBase'

import ClaimInternalStatus from '../Statuses/ClaimInternalStatus'

class ClaimPaymentOverlay extends OverlayBase {
  render = () => {
    const { $new, params, $editable, isInvalid }: any = this.state
    const { claim, claim_service } = this.state
    const { record, timezone, loading }: any = this.props

    const data = { ...params, ...record }

    if (!$new && size(data) === 0) {
      return <OverlayLoader position={this.props.position} showBackdrop={this.props.showBackdrop} />
    }

    const selectedClaim = this.state.claim || record.insurance_claim

    return (
      <Overlay onClose={this.close} maxWidth={45}>
        <Overlay.Header icon="financials" title={`${titleCase(data.category)} Payment`} />

        <Overlay.Content>
          <Form
            getForm={this.form}
            initialModel={data}
            isEditable={$editable}
            timezone={timezone}
            onValidationUpdate={this.onValidationUpdate}
            linked={$new && { category: data.category }}
          >
            <Section>
              <FormSection maxWidth="100%">
                <ObjectSelector
                  className="!grow-0 !shrink !basis-[350px]"
                  label="Claim"
                  model="insurance_claim"
                  dependentValue={params?.resource_id}
                  icon="insurance"
                  type="insurance.claims"
                  selectTitle={(data) => titleCase(data.category)}
                  selectDescription={(data) => (
                    <>
                      <ClaimInternalStatus status={data.status} />
                      {`${usDate(data.treatment_episode?.admitted_at)} to ${usDate(data.treatment_episode?.discharged_at)}`}
                    </>
                  )}
                  onUpdate={(state: any) => {
                    this.setState({ claim: state.object })
                  }}
                  validations={{
                    presence: {
                      message: 'Please select a Claim to post against',
                    },
                  }}
                />

                {selectedClaim && (
                  <>
                    <ObjectSelector
                      className="!flex-auto"
                      label="Claim Service Line"
                      icon="financials"
                      type="insurance.claim_service_lines"
                      dependent={claim}
                      model="insurance_claim_service_line"
                      description={'Select a Service Line to prefill some of the amounts'}
                      selectTitle={(data: any) => `${niceAmount(data.total_amount)}`}
                      selectDescription={(data: any) => `${usDate(data.service_date_start)} to ${usDate(data.service_date_end)}`}
                      validations={{
                        presence: {
                          message: 'Please select a service',
                        },
                      }}
                      onUpdate={(state: any) => {
                        this.setState({ claim_service: state.object })
                      }}
                    />

                    <Flex gap="1rem">
                      <DateInput defaultToNow label="Payment Date" model="dated_at" />

                      <Select label="Payment Method" model="payment_method" defaultValue="check">
                        <Option label="Check" value="check" />
                        <Option label="Bank Transfer (ACH)" value="ach" />
                        <Option label="Other" value="other" />
                      </Select>

                      <Input label="Reference" model="reference" className="!flex-auto" />
                    </Flex>
                  </>
                )}
              </FormSection>

              {!selectedClaim && (
                <>
                  <Divider />
                  <Alert glyph="info">To start, select a Claim to post against</Alert>
                </>
              )}
            </Section>

            {selectedClaim && (
              <>
                <Divider />

                <Section title="Insurance">
                  <FormSection maxWidth="100%">
                    <Flex gap="1rem">
                      <AmountInput isEditable={false} label="Billed Amount" value={claim_service?.total_amount} />

                      <AmountInput label="Allowed Amount" model="allowed_amount" value={claim_service?.total_amount} />
                      <AmountInput label="Insurance Paid" model="paid_amount" value={claim_service?.total_amount} />
                    </Flex>
                  </FormSection>
                </Section>

                <Divider />

                <Section title="Patient Responsibility">
                  <FormSection maxWidth="100%">
                    <Flex gap="0.5rem">
                      <AmountInput label="Deductible" model="deductible_amount" />
                      <AmountInput label="Co-Pay" model="copay_amount" />
                      <AmountInput label="Co-Insurance" model="coinsurance_amount" />
                      <AmountInput label="Not Payable" model="not_payable_amount" />
                    </Flex>
                  </FormSection>
                </Section>

                <Divider />

                <Section title="Adjudication">
                  <FormSection maxWidth="100%">
                    <Flex gap="1rem">
                      <AmountInput label="Adjustment" model="adjustment_amount" />
                      <AmountInput label="Remaining" model="remaining_amount" />
                    </Flex>
                  </FormSection>
                </Section>
              </>
            )}
          </Form>
        </Overlay.Content>

        <Overlay.Footer>
          {$editable ? (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                onClick={this.save}
                isLoading={loading}
                isDisabled={isInvalid}
                permission="todos.edit"
              />
              {!$new && <Button label="Cancel" glyph="cross" onClick={this.cancel} isDisabled={loading} />}
            </>
          ) : (
            <>
              <Button label="Edit" glyph="edit" onClick={this.edit} flex="100 1 auto" permission="todos.edit" />

              <DeleteDialog
                title="Delete payment?"
                message="Are you sure you want to delete this payment? This action cannot be undone."
                onYes={this.delete}
              >
                <Button label="Delete" glyph="delete" color="red" isLoading={this.props.loading} permission="todos.delete" />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_payments')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(ClaimPaymentOverlay))
