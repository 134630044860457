import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { OUTCOME_MEASURE_GENDER_TITLES, OUTCOME_MEASURE_ETHNICITY_TITLES } from './constants'

import { useSettings } from '../../hooks/useSettings'
import { countWord } from '@behavehealth/utils/functions'

import DataFormStatus from '@behavehealth/components/Statuses/DataFormStatus'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const DemographicInfoFormReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.name} to={to?.(data.data)} />,
      },
      {
        width: 180,
        id: 'client',
        model: 'data.client.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 180,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ value }) => {
          if (!value) return null

          return <DataFormStatus status={value} />
        },
      },
      {
        width: 200,
        id: 'dated_at',
        model: 'data.dated_at',
        title: 'Date and Time',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'ended_at',
        model: 'data.ended_at',
        title: 'Session End Time',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'age_at_admission',
        model: 'data.age_at_admission',
        title: 'Age At Admission',
      },
      {
        width: 200,
        id: 'gender',
        model: 'data.gender',
        title: 'Gender',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_GENDER_TITLES[value]
        },
      },
      {
        width: 200,
        id: 'race_ethnicity',
        model: 'data.race_ethnicity',
        title: 'Race / Ethnicity',
        formatValue: ({ value }) => {
          if (!value) return null

          return value
            .split(',')
            .map((o) => OUTCOME_MEASURE_ETHNICITY_TITLES[o])
            .join(', ')
        },
      },
      {
        width: 200,
        id: 'created_at',
        model: 'data.created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'updated_at',
        model: 'data.updated_at',
        title: 'Last Updated',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'data.author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Demographic Information Form"
      icon="outcome_measures"
      columns={columns}
      filtersConfig={FILTERS.live_reports.demographic_information_form}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="demographic_information_form"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=demographic_information_form'}
        />
      }
      {...props}
    />
  )
}
