import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'

import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const AgreementsReportDataTable = (props: any) => {
  const { to } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.name} avatar={data.avatar} to={to?.(data.data)} />,
        disableHide: true,
        disableSort: false,
      },
      {
        title: 'Client',
        width: 180,
        id: 'reference',
        model: 'data.reference.name',
        type: 'profile',
        disableSort: false,
      },
      {
        title: 'Staff Signee',
        width: 180,
        id: 'cosigner',
        model: 'data.cosigner.name',
        type: 'profile',
        disableSort: false,
      },
      {
        title: 'Status',
        width: 180,
        id: 'status',
        model: 'data.status',
        disableSort: false,
      },
      {
        title: '# Signees',
        width: 180,
        id: 'contract_count',
        model: 'data.contract_count',
        disableSort: false,
      },
      {
        title: 'Added By',
        width: 180,
        id: 'author',
        model: 'data.author.name',
        type: 'profile',
        disableSort: false,
      },
      {
        title: 'Review Cycle',
        width: 140,
        id: 'review_cycle',
        model: 'data.review_cycle',
        disableSort: false,
      },
      {
        title: 'Effective Date',
        width: 200,
        id: 'dated_at',
        model: 'data.dated_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Expired At',
        width: 200,
        id: 'expired_at',
        model: 'data.expired_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Last Review Date',
        width: 200,
        id: 'reviewed_at',
        model: 'data.reviewed_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Last Updated',
        width: 200,
        id: 'updated_at',
        model: 'data.updated_at',
        type: 'date_time',
        disableSort: false,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Agreements"
      icon="legal_agreement_alt"
      columns={columns}
      filtersConfig={FILTERS.live_reports.agreements}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="agreements"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=agreements'}
        />
      }
    />
  )
}
