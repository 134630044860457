import React from 'react'
import { scroller } from 'react-scroll'
import get from 'lodash/get'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { withFormContext } from '../../Forms/context'

import Flex from '../../Flex'
import Glyph from '../../Glyph'

const CommentsCount = ({ data, model, onClick }) => {
  if (!data && model) return null

  const comments = get(data, `${model}._comments`)
  const count = size(comments)

  const scroll = () =>
    scroller.scrollTo(model, {
      containerId: 'overlay-content',
      isDynamic: true,
      duration: 200,
      smooth: 'easeInOutQuart',
      offset: -20,
    })

  const handleClick = () => {
    scroll()
    if (onClick) onClick()
  }

  return (
    <Flex centerY onClick={handleClick} css={styles} className={count >= 1 ? 'has-comments' : ''}>
      <Glyph glyph="chat" size={16} />
      <div>{count}</div>
    </Flex>
  )
}

const styles = {
  fontSize: '0.95rem',
  cursor: 'pointer',
  color: COLORS.textMuted,

  '&:hover': {
    textDecoration: 'underline',
  },

  '&.has-comments': {
    color: COLORS.link,

    svg: {
      fill: COLORS.link,
    },
  },

  svg: {
    marginRight: '0.25rem',
    fill: COLORS.textMuted,
  },
}

export default withFormContext(CommentsCount)
