import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { countWord } from '../../../utils/functions'
import useAPI from '../../../hooks/useAPI'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../Button'
import Card from '../../Card'
import Overlay from '../../Overlay'
import Section from '../../Section'
import SummonOverlay from '../../SummonOverlay'
import Tabs from '../../Tabs'

import LegalAgreementInternalTemplatesTable from '../tables/internal/LegalAgreementInternalTemplatesTable'
import LegalAgreementTemplateOverlay from './LegalAgreementTemplateOverlay'

import { apiCreate, apiGet } from '../../../modules/api'

const HIDDEN_COLUMNS = ['global_tags', 'smart_flags']

const LegalAgreementTemplatesImportOverlay = () => {
  const navigate = useNavigate()
  const { tenant } = useSettings()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [preview, setPreview]: any = React.useState(null)
  const [loading, setLoading]: any = React.useState(false)

  const { isBehave } = useSettings()

  const data = useSelector((state: any) => state.data?.internal_legal_agreement_templates?.data)
  const dataLoading = useSelector((state: any) => state.data?.internal_legal_agreement_templates?.loading)
  const isEmpty = size(data) === 0

  useAPI(
    'internal_legal_agreement_templates',
    `/internal_templates?category=agreement&subcategory=legal&level=behave_template&status=active`,
  )

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    setLoading(true)

    try {
      const ids = selectedRows.map((o: any) => o.original.id)
      await apiCreate({
        url: `/internal_templates/import`,
        params: { ids: ids, category: 'legal', reference_type: 'Facility', reference_id: tenant.id },
      })
      await apiGet({ name: 'agreements', url: '/agreements?variant=legal' })

      navigate(-1)
    } catch (errors) {
      console.debug(errors)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import Agreement Templates" />

      <Overlay.Content>
        {preview && (
          <SummonOverlay
            isOpen={!!preview}
            onClose={() => setPreview(null)}
            overlay={<LegalAgreementTemplateOverlay allowEditing={false} dataID={preview.id} initialData={preview.data} />}
          />
        )}

        <Section margin="0 0 1.5rem 0">
          <Card className="!mt-4">
            <LegalAgreementInternalTemplatesTable
              title="Legal Agreements"
              data={data}
              isLoading={isEmpty && dataLoading}
              onSelect={setSelectedRows}
              onClick={setPreview}
              hiddenColumns={!isBehave && HIDDEN_COLUMNS}
            />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Templates', selectedRowsCount)}` : 'Select Templates to Import'}
          isDisabled={!hasSelectedRows}
          isLoading={loading}
          type="primary"
          color="green"
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default LegalAgreementTemplatesImportOverlay
