import React from 'react'

import { apiGet, apiUpdate } from '../../modules/api'
import { useSettings } from '../../hooks'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../Alert'
import Button from '../Button'
import Overlay from '../Overlay'
import Section from '../Section'
import Link from '../Link'
import FormSection from '../Forms/FormSection'
import ObjectSelector from '../Forms/Selectors/Object/ObjectSelector'
import Form from '../Forms/Form'

const ApplyPermissionTemplateOverlay = ({ record, onClose }: any) => {
  const { online } = useSettings()

  const form = React.useRef()
  const [valid, setValid] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const applyTemplate = async () => {
    setLoading(true)

    try {
      await apiUpdate({
        name: 'employees',
        url: `/employees/${record.id}`,
        params: form.current.getFormValue(),
      })

      await apiGet({ url: '/me', reducer: 'me' })

      window.location.reload()
    } catch (error) {
      console.error(error)
    }

    onClose()
  }

  if (!record) return null

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header icon="permissions" title="Apply new Staff Permission" />

      <Overlay.Content>
        <Form isEditable getForm={form} onValidationUpdate={setValid}>
          <Section>
            <FormSection>
              <Alert contrast type="warning">
                Permission Templates are editable at <Link to="/settings/permission-templates">Settings / Staff Permissions</Link>
              </Alert>

              <ObjectSelector
                label="Apply Permission Template"
                icon="permissions"
                type="permission_templates"
                model="permissions_template"
                selectTitle={(data: any) => data?.name}
                selectDescription={() => null}
                validations={{
                  presence: {
                    message: 'Please select a permission to apply',
                  },
                }}
              />

              <Alert glyph="warning" type="negative">
                The selected Permission template will be applied immediately on this record. The previous settings will be replaced.
              </Alert>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer online={online}>
        <Button
          label="Apply new Permissions"
          type="primary"
          color="green"
          glyph="warning"
          onClick={applyTemplate}
          isLoading={loading}
          isDisabled={!valid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default withOverlayError(ApplyPermissionTemplateOverlay)
