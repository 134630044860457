import React from 'react'
import startCase from 'lodash/startCase'

import Glyph from '../Glyph'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to?: Function, assignTo?: Function) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to && to(row.original.id)} label={value} avatar={row.original.avatar} />,
  },
  {
    width: 100,
    accessor: 'sex',
    Header: 'Sex',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'male',
        label: 'Male',
      },
      {
        value: 'female',
        label: 'Female',
      },
      {
        value: 'decline_to_respond',
        label: 'Decline To Respond',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 160,
    accessor: 'email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
  {
    id: 'actions',
    width: 160,
    accessor: 'id',
    disableFilters: true,
    canToggleVisible: false,
    Header: 'Actions',
    Cell: ({ row }: any) => (
      <TableCell.Link to={assignTo && assignTo(row.original.id)} label="Assign Bed…" glyph={<Glyph glyph="check_in" />} />
    ),
  },
]

type Props = {
  assignTo?: Function
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const UnassignedClientsTable = (props: Props) => {
  const { assignTo, data, emptyActions, emptyDescription, icon, isLoading, batchActionsConfig, localStorageKey, title, to, titleAfter } =
    props

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, assignTo)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

UnassignedClientsTable.defaultProps = {
  icon: 'clients',
  emptyDescription: 'No clients with unassigned rooms to display',
  localStorageKey: 'unassigned_clients',
}

export default UnassignedClientsTable
