import { createSelector } from 'reselect'
import size from 'lodash/size'
import get from 'lodash/get'

const admissionsSelector = (state) => state?.data?.admissions?.data
export const clientSelector = (state, match) => {
  return get(state, `data.clients.data.${match?.params?.resource_id}`)
}

export const referenceSelector = (state, match) => {
  if (['admissions', 'clients', 'alumni'].includes(match?.params?.resource)) {
    return get(state, `data.clients.data.${match?.params?.resource_id}`, {})
  }

  return get(state, `data.${match?.params?.resource}.data.${match?.params?.resource_id}`, {})
}

export const currentAdmission = createSelector([admissionsSelector], (admissions) => {
  if (size(admissions) === 0) return null
  return admissions[Object.keys(admissions)[0]]
})
