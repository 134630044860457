import React from 'react'
import clsx from 'clsx'

import { AIInput } from '../../components/AIInput'
import { getFormElementProps } from '../../utils/functions'
import { FormContext } from '../../../../components/Forms/context'

import BHRichTextEditor from '../../../../components/Forms/RichTextEditor'

const MINI_EDITOR_ACTIONS = ['inline', 'color', 'list']

export const RichTextInput: React.FC<any> = (props) => {
  const { children, element, className, hoverElement, useParsedConfig, environment, isEditable, editInputDefaultValue } = props

  const [editor, setEditor] = React.useState(null)

  const formContext = React.useContext(FormContext)

  if (!element) return null

  const rootClasses = clsx('RICH_TEXT_INPUT', className)

  const inputProps: any = getFormElementProps(element, { useParsedConfig, environment })

  const { ai_input_enabled } = element.config

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}
      <BHRichTextEditor
        {...inputProps}
        key={`rich-text-input-${element.config?.rich_text_input_type}`}
        actions={element.config?.rich_text_input_type === 'mini' ? MINI_EDITOR_ACTIONS : undefined}
        getEditor={setEditor}
        useAIActions={false}
        onUpdate={(input) => {
          if (isEditable) editInputDefaultValue?.(input)
        }}
      />

      {ai_input_enabled && formContext.isEditable && (
        <AIInput
          isRichText
          key={`ai-input-${element.config.local_ai_input_value}-${element.config.global_ai_input_value}`}
          elementConfig={element.config}
          getInput={() => editor?.getHTML?.()}
          onInsert={(aiResult) => {
            editor?.commands.focus('end')
            editor?.commands.insertContent(aiResult)
          }}
          onReplace={(aiResult) => {
            editor?.commands.clearContent()
            editor?.commands.insertContent(aiResult)
          }}
        />
      )}
    </div>
  )
}
