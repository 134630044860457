import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { ApplicantsDataTable } from '@behavehealth/constructs/Clients/ApplicantsDataTable'

const Applicants: React.FC = () => {
  return <ApplicantsDataTable testKey="applicant_data_table" />
}

export default withPageError(Applicants)
