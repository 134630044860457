import React from 'react'
import startCase from 'lodash/startCase'

import { useSettings } from '../../hooks'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

import PayerPhoneTypeStatus from '../Statuses/PayerPhoneTypeStatus'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} />,
  },

  // eslint-disable-next-line no-console
  // TODO: @jbraccinibh @roberteles: I deteceted 'equals' filters are not working properly in most places
  // The only reason this works is becasue theres always a relation 'snaked_value' > 'Snaked Value' between
  // value and label. Then we actually render the cell to startCase the value (a.k.a 'snaked_value') and it is
  // turned to 'Snake Value' just because of startCase, but it is no using the defined label anywhere
  {
    width: 80,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
    filter: 'equals',
    filterOptions: [
      {
        value: 'main_line',
        label: 'Main Line',
      },
      {
        value: 'pre_cert',
        label: 'Pre Cert',
      },
      {
        value: 'authorizations',
        label: 'Authorizations',
      },
      {
        value: 'denials',
        label: 'Denials',
      },
      {
        value: 'appeals',
        label: 'Appeals',
      },
    ],
  },
  {
    width: 80,
    accessor: 'phone_type',
    Header: 'Type',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
    filter: 'equals',
    filterOptions: [
      {
        value: 'phone',
        label: 'Phone',
      },
      {
        value: 'fax',
        label: 'Fax',
      },
    ],
  },
  {
    width: 80,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    accessor: 'notes',
    Header: 'Notes',
    Cell: TableCell,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  selectActions?: Function
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const PhoneBookTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, localStorageKey, selectActions, batchActionsConfig, hiddenColumns, titleAfter } = props
  const { timezone } = useSettings()

  return (
    <Table
      showFilters
      title="Phone Book"
      titleAfter={titleAfter}
      icon="housing_notes"
      data={data}
      columns={columns(to, timezone)}
      hiddenColumns={hiddenColumns}
      selectActions={selectActions}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No phones added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

PhoneBookTable.defaultProps = {
  localStorageKey: 'phones',
}

export default PhoneBookTable
