import React from 'react'

import FormSection from '../../../../Forms/FormSection'
import ContextShow from '../../../../Forms/ContextShow'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'

import { UATests } from '../../../../../utils/constants'

const TestResults = () => {
  return (
    <FormSection layout="vertical">
      <RadioGroup label="Urine Drug Screen Result" model="data.test_results.ua_results" layout="horizontal">
        <Radio label="Negative" value="negative" />
        <Radio label="Positive" value="positive" />
      </RadioGroup>

      <ContextShow when="data.test_results.ua_results" is="positive">
        <CheckboxGroup label="Drugs:" layout="vertical-dense">
          {UATests.map((test) => (
            <Checkbox label={test.name} model={`data.test_results.${test.model}`} />
          ))}
        </CheckboxGroup>
      </ContextShow>

      <RadioGroup label="Pregnancy Test" model="data.test_results.pregnancy_test" layout="vertical-dense">
        <Radio label="Negative" value="negative" />
        <Radio label="Positive" value="positive" />
        <Radio label="N/A" value="n/a" />
      </RadioGroup>
    </FormSection>
  )
}

export default TestResults
