import React from 'react'
import { useLocation } from 'react-router-dom'

import { usDate } from '../../utils/functions'
import useActivePath from '../../hooks/useActivePath'
import withSettings from '../../hocs/withSettings'

import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'
import CardSubtitle from '../CardSubtitle'

type Props = {
  location: any
  log: any
  timezone: any
  to: any
}

const MedicationLog = ({ log, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={log?.med?.name} />
      <CardMeta>
        <Text label="Dosage: " description={log?.dosage} />
        <Text label="Unit: " description={log?.unit} />
        <Text glyph="time" label="Administered At: " description={usDate(log?.administered_at, timezone)} />
        <Text avatar={log?.author?.avatar} label="Logged By: " description={log?.author?.name} />
      </CardMeta>
      <CardSubtitle subtitle={log?.notes} />
    </CardLink>
  )
}

export default withSettings(MedicationLog)
