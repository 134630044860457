import React from 'react'

import Button from './Button'
import Card from './Card'
import Glyph from './Glyph'
import Overlay from './Overlay'

import { DragAndDrop } from './DragAndDrop'

const DEFAULT_RENDER_OVERLAY = ({ item }: any) => {
  return (
    <Card className="w-fit px-3 py-2 flex items-center flex-nowrap !select-none">
      <div className="flex items-center justify-center mr-2">
        <Glyph glyph="drag_and_drop" size={14} />
      </div>
      <div className="font-[600]">{item.name}</div>
    </Card>
  )
}

const DEFAULT_RENDER_ITEM = ({ renderDragElement, item }: any) => {
  return (
    <div className="w-full px-3 py-2 flex items-center flex-nowrap border-b border-0 border-solid border-divider">
      <div className="flex items-center justify-center mr-2">{renderDragElement()}</div>
      <div className="font-[600]">{item.name}</div>
    </div>
  )
}

export const ReorderOverlay = (props: any) => {
  const { onClose, items, onSave, getId, renderItem = DEFAULT_RENDER_ITEM, renderOverlay = DEFAULT_RENDER_OVERLAY, isDisabled } = props

  const [localItems, setLocalItems] = React.useState(items || [])

  const handleSave = () => {
    onSave?.(localItems)
    onClose?.()
  }

  if (!items) return null

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose}>
      <Overlay.Header title="Reorder" glyph="move_up_down" onClose={onClose} />

      <Overlay.Content className="p-5">
        <Card>
          <DragAndDrop
            items={localItems}
            onUpdate={setLocalItems}
            getId={getId}
            renderItem={renderItem}
            renderOverlay={renderOverlay}
            isDisabled={isDisabled}
          />
        </Card>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" glyph="tick_circle" color="green" type="primary" onClick={handleSave} />
      </Overlay.Footer>
    </Overlay>
  )
}
