import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { InsurancePoliciesReportDataTable } from '@behavehealth/constructs/LiveReports/InsurancePoliciesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const InsurancePolicies = () => {
  const tableProps = useDataTable({
    name: ['reports', 'insurance-policies'],
    endpoint: `/live_reports?category=insurance_policies`,
    localStorageKey: 'report_insurance_policies_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getClientLink(rowData.resident)}/insurance/${rowData.id}`, [])

  return (
    <Page feature="insurance_policies" title="Live Insurance Policies Report">
      <Grid>
        <InsurancePoliciesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(InsurancePolicies)
