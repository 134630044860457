import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  due: {
    label: 'Due',
    color: 'orange',
  },
  overdue: {
    label: 'Overdue',
    color: 'red',
  },
  paid: {
    label: 'Paid',
    color: 'green',
  },
  write_off: {
    label: 'Write-Off',
    color: 'gray',
  },
  pending: {
    label: 'Pending',
    color: 'blue',
  },
  failed: {
    label: 'Payment Failed',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
