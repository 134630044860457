import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import SmartStatus from '@behavehealth/components/SmartStatus'

const RootAccounts = () => {
  const tableProps = useDataTable({
    name: ['accounting', 'accounts'],
    endpoint: `/accounting/accounts`,
    // params: {...},
    localStorageKey: 'accounting_accounts_v1',
  })

  const { isBehave } = useSettings()

  const { mutateAsync: syncRailzToBh, isLoading: isSyncingRailz } = useCreate({
    name: ['sync-railz-to-bh'],
    url: '/accounting/accounts/sync_railz_to_bh',
    invalidate: tableProps.queryKey,
  })

  const { mutateAsync: syncQbToRailz, isLoading: isSyncingQb } = useCreate({
    name: ['sync-qb-to-railz'],
    url: '/accounting/accounts/sync_qb_to_railz',
  })

  const handleSyncRailzToBh = async () => {
    await syncRailzToBh()
    tableProps.refetch()
  }

  const handleSyncQbToRailz = () => {
    syncQbToRailz()
  }

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Quickbooks Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} />,
      },
      {
        title: 'Quickbooks',
        model: 'external_url',
        width: 160,
        disableHide: true,
        disableSort: true,
        formatValue: ({ value, data }: any) => {
          if (!value) return null

          return <LinkCell isExternal value="View Link" href={value} />
        },
      },
      {
        title: 'Description',
        model: 'description',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Group',
        model: 'group',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Sub Group',
        model: 'sub_group',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Classification',
        model: 'classification',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Fully Qualified Name',
        model: 'fully_qualified_name',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Account Type',
        model: 'account_type',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Sub Type',
        model: 'sub_type',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Current Balance',
        model: 'current_balance',
        width: 160,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Nominal Code',
        model: 'nominal_code',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Is Bank Account',
        model: 'is_bank_account',
        width: 160,
        disableHide: true,
        type: 'boolean',
      },
      {
        title: 'Is Sub Account',
        model: 'is_sub_account',
        width: 160,
        disableHide: true,
        type: 'boolean',
      },
      {
        title: 'Is Active',
        model: 'is_active',
        width: 160,
        disableHide: true,
        type: 'boolean',
      },
      {
        title: 'Section',
        model: 'section',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Sub Section',
        model: 'sub_section',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Quickbooks Last Updated',
        model: 'source_modified_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Accounts">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Accounts"
          icon="accounting"
          emptyDescription="No accounts have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          columns={columns}
          {...tableProps}
          headerAfter={
            isBehave && (
              <Flex gap="0.5rem">
                <AccountingSyncButton
                  forceShow={true}
                  category="accounts"
                  invalidate={tableProps.queryKey}
                  refetchUrl={'/accounting/sync?category=accounts'}
                />
              </Flex>
            )
          }
        />
      </Grid>
    </Page>
  )
}

export const Accounts = withPageError(RootAccounts)

const FILTERS_CONFIG = {
  name: DEFAULT_FILTERS.name,
  description: {
    label: 'Description',
    type: 'string',
  },
  group: {
    label: 'Group',
    type: 'string',
  },
  sub_group: {
    label: 'Sub Group',
    type: 'string',
  },
  classification: {
    label: 'Classification',
    type: 'string',
  },
  fully_qualified_name: {
    label: 'Fully Qualified Name',
    type: 'string',
  },
  account_type: {
    label: 'Account Type',
    type: 'string',
  },
  sub_type: {
    label: 'Sub Type',
    type: 'string',
  },
  depth: {
    label: 'Depth',
    type: 'string',
  },
  current_balance: {
    label: 'Current Balance',
    type: 'number',
  },
  nominal_code: {
    label: 'Nominal Code',
    type: 'string',
  },
  is_bank_account: {
    label: 'Is Bank Account',
    type: 'boolean',
  },
  is_sub_account: {
    label: 'Is Sub Account',
    type: 'boolean',
  },
  is_active: {
    label: 'Is Active',
    type: 'boolean',
  },
  section: {
    label: 'Section',
    type: 'string',
  },
  sub_section: {
    label: 'Sub Section',
    type: 'string',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'datetime',
  },
}
