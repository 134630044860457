import React from 'react'

import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ContextHide from '../../Forms/ContextHide'
import ContextShow from '../../Forms/ContextShow'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Input from '../../Forms/Input'
import Textarea from '../../Forms/Textarea'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const NPIFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="behave_health" />
        <div>
          <Type as="h2" variant="H3">
            NPI
          </Type>
          <Type as="p" variant="TEXT" css={{ margin: '0.2em 0 0' }}>
            Add any associated NPI's related to your business, including both organization and individual NPI where applicable.
          </Type>
        </div>
      </Flex>

      <ContextHide when="questionnaire.npi.no_npi" is={true}>
        <Input
          label="NPI #1"
          model="questionnaire.npi.npi_1"
          size={10}
          validations={{
            presence: {
              message: 'Please enter at least one NPI',
            },
            length: {
              minimum: 10,
              message: 'Your NPI should have 10 characters',
            },
          }}
        />

        <Input label="NPI #2" model="questionnaire.npi.npi_2" size={10} />

        <Input label="NPI #3" model="questionnaire.npi.npi_3" size={10} />
      </ContextHide>

      <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough">
        <Checkbox label="I do not have an NPI" model="questionnaire.npi.no_npi" />
      </CheckboxGroup>

      <ContextShow when="questionnaire.npi.no_npi" is={true}>
        <Textarea
          label="If you do not currently have an NPI, please explain why below"
          model="questionnaire.npi.no_npi_why"
          validations={{
            presence: {
              message: 'Please enter the answer for why you do not have an NPI',
            },
            length: {
              minimum: 10,
              tooShort: 'Please enter %{count} or more characters',
            },
          }}
        />
      </ContextShow>
    </FormSection>
  )
}

export const NPI: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <NPIFields />
    </Form>
  )
}
