import React from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { apiCreate } from '../../../../modules/api'
import { mapToArray, countWord } from '../../../../utils/functions'
import { useAPIwithNoAbort } from '../../../../hooks/useAPI'
import { useSettings } from '../../../../hooks/useSettings'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import Alert from '../../../Alert'
import Button from '../../../Button'
import Card from '../../../Card'
import CardHeader from '../../../CardHeader'
import CardSubtitle from '../../../CardSubtitle'
import CardTitle from '../../../CardTitle'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import Chotomate from '../../../Chotomate'
import Divider from '../../../Divider'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Grid from '../../../Grid'
import MultiObjectSelector from '../../../Forms/Selectors/MultiObject/MultiObjectSelector'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import Radio from '../../../Forms/Radio'
import RadioGroup from '../../../Forms/RadioGroup'
import Section from '../../../Section'
import snakeCase from 'lodash/snakeCase'
import State from '../../../State'
import Status from '../../../Status'

const getSelectedTemplates = (templates: Object) => {
  const res: string[] = []

  if (!templates) return res

  for (const key in templates) {
    if (templates[key]) res.push(key)
  }

  return res
}

const filterAgreementType = (obj, agreement_type = 'general') => {
  const result = {}
  for (const key in obj) {
    if (obj[key].agreement_type === agreement_type) {
      result[key] = obj[key]
    }
  }
  return result
}

const RootImportEmployeeAgreementsOverlay = (props: any) => {
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()

  const { online } = useSettings()

  const employeeID = match.params.resource_id
  const employee = useSelector((state: any) => state.data?.employees?.data?.[employeeID])

  const templates = useSelector((state) => state.data?.agreement_templates?.data)
  const templatesLoading = useSelector((state) => state.data?.agreement_templates?.loading)
  const [loading, setLoading] = React.useState(false)
  const templatesEmpty = size(templates) === 0

  const { isBehave, isManagement } = useSettings()

  // if (isBehave || isManagement) {
  //   useAPIwithNoAbort('agreement_templates', '/agreement_templates?variant=employee&include_admin=true', false)
  // } else {
  //   useAPIwithNoAbort('agreement_templates', '/agreement_templates?variant=employee', false)
  // }

  useAPIwithNoAbort('agreement_templates', '/agreement_templates?variant=employee', false)

  const generalTemplates = filterAgreementType(templates, 'general')

  const form = React.useRef(null)
  const [formData, setFormData]: any = React.useState(null)

  const selectedTemplates = getSelectedTemplates(formData?.templates)
  const didSelectTemplates = selectedTemplates.length > 0

  const importAgreements = async () => {
    setLoading(true)

    // do async request
    const model = form.current.getFormValue()
    await apiCreate({
      name: 'agreements',
      url: `/employees/${employeeID}/agreements/import`,
      params: model,
    })

    setLoading(false)
    if (onClose) onClose()
  }

  const onClose = () => {
    if (props.onClose) {
      props.onClose()
    } else if (location.parent) {
      history.push(location.parent.url)
    } else {
      history.goBack()
    }
  }

  // if (!employee) return <OverlayLoader />

  return (
    <Overlay showBackdrop onClose={onClose} isLoading={templatesEmpty && templatesLoading}>
      <Chotomate ready name="import_agreements" />

      <Overlay.Header icon="legal_agreement_alt" title="Import Agreement Templates" />

      <Overlay.Content>
        {templatesEmpty && (
          <State
            isEmpty
            icon="legal_agreement_alt"
            title="Agreement Templates"
            emptyDescription="There are no agreement templates created yet. Go to Settings to add a template."
            emptyActions={
              <Button label="Manage Agreement Templates" glyph="settings" type="primary" link="/settings/agreement-templates/employees" />
            }
          />
        )}

        {!templatesEmpty && (
          <Form
            getForm={form}
            onUpdate={setFormData}
            linked={{
              variant: 'employee',
              reference_id: employeeID,
              reference_type: 'Employee',
              has_other_signees: false,
            }}
          >
            <Section>
              <FormSection>
                <CheckboxGroup
                  label="Select templates to import:"
                  layout="vertical-dense"
                  validations={{
                    presence: {
                      message: 'Please select at least one template',
                    },
                  }}
                >
                  {mapToArray(generalTemplates).map((template: any) => (
                    <Checkbox
                      testKey={`${snakeCase(template.name)}_checkbox`}
                      key={template.id}
                      label={template.name}
                      model={`templates.${template.id}`}
                    />
                  ))}
                </CheckboxGroup>
              </FormSection>
            </Section>

            {/* <Divider />

            <Section title="Signees">
              {!didSelectTemplates && <Alert glyph="info">Please select at least one agreement template first.</Alert>}

              {didSelectTemplates && (
                <FormSection>
                  <Card  >
                    <CardHeader>
                      <CardTitle title={employee.name} after={<Status label="Staff Member" color="blue" />} />
                      <CardSubtitle subtitle="The staff member is a mandatory signee for all agreements." />
                    </CardHeader>
                  </Card>

                  <Checkbox  label="Add other signees…" model="has_other_signees" />

                  {formData?.has_other_signees && (
                    <>
                      <RadioGroup model="use_same_signees" layout="vertical-dense" defaultValue={true}>
                        <Radio  label="Same signees for all agreements" value={true} />
                        <Radio  label="Different signees for each agreement" value={false} />
                      </RadioGroup>

                      {formData?.use_same_signees && (
                        <MultiObjectSelector
                          
                          icon="contacts"
                          model="signees"
                          label="All Agreements Signees"
                          type="client.signees"
                          dependentValue={employeeID}
                          selectTitle={(data) => data.reference.name}
                          selectDescription={(data) => data.reference.relationship}
                        />
                      )}

                      {!formData?.use_same_signees && (
                        <Grid gap="1.5rem" className="!mt-4">
                          {selectedTemplates.map((templateID: string) => (
                            <MultiObjectSelector
                              key={templateID}
                              icon="contacts"
                              label={`${templates[templateID].name} Signees`}
                              model={`templates.${templateID}.signees`}
                              type="client.signees"
                              dependentValue={employeeID}
                              selectTitle={(data) => data.reference.name}
                              selectDescription={(data) => data.reference.relationship}
                            />
                          ))}
                        </Grid>
                      )}
                    </>
                  )}
                </FormSection>
              )}
            </Section> */}
          </Form>
        )}
      </Overlay.Content>

      {!templatesEmpty && (
        <Overlay.Footer online={online}>
          <Button
            label={didSelectTemplates ? `Import ${countWord('Agreements', selectedTemplates.length)}` : 'Import Agreements'}
            color="green"
            type="primary"
            isDisabled={!didSelectTemplates}
            isLoading={loading}
            onClick={importAgreements}
            permission="employee_agreements.create"
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const ImportEmployeeAgreementsOverlay = withOverlayError(RootImportEmployeeAgreementsOverlay)
