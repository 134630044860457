import React from 'react'
import { darken, tint } from 'polished'
import clsx from 'clsx'
import get from 'lodash/get'
import size from 'lodash/size'
import produce from 'immer'

import {
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

import { COLORS } from '../../theme'
import { mapToArray, countWord } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Button from '../Button'
import Card from '../Card'
import ConfirmDialog from '../Dialogs/ConfirmDialog'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Flex from '../Flex'
import Glyph from '../Glyph'
import RadioCheckElement from '../Forms/RadioCheckElement'
import SignatureDialog from '../Dialogs/SignatureDialog'
import State from '../State'
import SummonOverlay from '../SummonOverlay'
import TextareaDialog from '../Dialogs/TextareaDialog'
import Tooltip from '../Tooltip'
import Dropdown from '../Dropdown'
import DropdownItem from '../DropdownItem'
import Loader from '../Loader'

import TableCell from './TableCell'
import TableOptionsOverlay from './TableOptionsOverlay'
import TablePagination from './TablePagination'
import TableRow from './TableRow'
import TextFilter from './Filters/TextFilter'

import { filterTypes } from './utils/filterTypes'
import { setTableSettings, getTableSettings, clearTableSortingSettings } from './utils/localStorage'
import { getConditionalSelectHeaderCheckboxProps } from './utils/conditionalSelectHeader'

import { DateRangeSelector } from '../Forms/DateRangeSelector'
import { TableContext } from './context'
import { TableEditOverlay } from './TableEditOverlay'
import snakeCase from 'lodash/snakeCase'

const BORDER_COLOR = darken(0.07, COLORS.lightBackground)
const BANDING_COLOR = tint(0.25, COLORS.lightBackground)
const PAGE_SIZES = [5, 10, 25, 50, 75, 100]

const defaultColumn = { Cell: TableCell, Filter: TextFilter, canToggleVisible: true }

const selectionColumn = {
  id: 'selection',
  width: 48,
  isFixedWidth: true,
  canToggleVisible: false,
  Header: (props: any) => {
    // Disable 'Select All' from selecting 'signed_off' records
    const checkboxProps = getConditionalSelectHeaderCheckboxProps({
      headerProps: props,
      shouldSelectPage: false,
      checkIfRowIsSelectable: (row) => row.original?.status !== 'signed_off',
    })

    return (
      <label css={styles.selectCheckbox}>
        <input type="checkbox" css={styles.selectCheckboxInput} {...checkboxProps} />
        <RadioCheckElement
          type="checkbox"
          isChecked={checkboxProps.checked}
          isIndeterminate={checkboxProps.indeterminate}
          css={styles.selectCheckboxElement}
        />
      </label>
    )
  },

  Cell: ({ row }: any) => {
    const props = row.getToggleRowSelectedProps()

    if (row.original?.status === 'signed_off') {
      return (
        <Tooltip position="right" className="!w-full !block" color="red" content="Signed Off records cannot be edited">
          <label css={styles.selectCheckbox} className={props.checked ? 'is-checked' : ''}>
            <input type="checkbox" disabled css={styles.selectCheckboxInput} {...props} />
            <RadioCheckElement type="checkbox" isChecked={props.checked} isDisabled={true} css={styles.selectCheckboxElement} />
          </label>
        </Tooltip>
      )
    } else {
      return (
        <label css={styles.selectCheckbox} className={props.checked ? 'is-checked' : ''}>
          <input type="checkbox" css={styles.selectCheckboxInput} {...props} />
          <RadioCheckElement type="checkbox" isChecked={props.checked} css={styles.selectCheckboxElement} />
        </label>
      )
    }
  },
}

const behaveSelectionColumn = {
  ...selectionColumn,
  Cell: ({ row }: any) => {
    const props = row.getToggleRowSelectedProps()

    return (
      <label css={styles.selectCheckbox} className={props.checked ? 'is-checked' : ''}>
        <input type="checkbox" css={styles.selectCheckboxInput} {...props} />
        <RadioCheckElement type="checkbox" isChecked={props.checked} css={styles.selectCheckboxElement} />
      </label>
    )
  },
}

const expanderColumn = {
  id: 'expander',
  width: 44,
  isFixedWidth: true,
  Cell: ({ row }) => {
    if (!row.canExpand) return null

    return (
      <span {...row.getToggleRowExpandedProps()}>
        <Glyph
          glyph={'triangle_down'}
          size={12}
          css={{ transform: !row.isExpanded && `rotate(-90deg)`, marginRight: '0.5rem', marginLeft: `${row.depth * 0.5}rem` }}
        />
      </span>
    )
  },
}

const getSubRows = (row: any, key: string = 'sub_rows') => get(row, key)

const getCellClassNames = (cell: any) =>
  clsx('table-cell', {
    'is-fixed-width': cell.column.isFixedWidth,
    'is-sticky': cell.column.isSticky,
    'is-selected': cell.row.isSelected,
  })

const getHeaderCellClassNames = (column: any) =>
  clsx('table-header-cell', {
    'is-fixed-width': column.isFixedWidth,
    'is-sticky': column.isSticky,
  })

const getExpandArrowClassNames = (isExpanded: any) =>
  clsx('expand-header-arrow', {
    'is-expanded': isExpanded,
  })

const DEFAULT_ACTION_TYPES: any = {
  delete: {
    glyph: 'delete',
    color: 'red',
    label: (length: number) => `Delete (${length})`,
  },
  sign_off: {
    glyph: 'signature',
    color: 'green',
    label: (length: number) => `Sign Off As Supervisor (${length})`,
  },
  send_for_review: {
    glyph: 'signature',
    color: 'green',
    label: (length: number) => `Send for Review (${length})`,
  },
  request_updates: {
    glyph: 'note',
    color: 'blue',
    label: (length: number) => `Request Updates (${length})`,
  },
}

const ActionButton = ({ type, label, glyph, color, action, count, permission }: any) => {
  const [isActionProcessing, setIsActionProcessing] = React.useState(false)
  switch (type) {
    case 'delete':
      return (
        <DeleteDialog
          title="Delete selected records?"
          message={`Are you sure you want to delete the ${count} selected records? This action cannot be undone.`}
          yesLabel={`Yes, Delete (${count})`}
          onYes={async () => {
            // start loader
            setIsActionProcessing(true)

            await action() // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        >
          <Button
            testKey="table_delete_selected_button"
            label={label}
            glyph={glyph}
            color={color}
            type="minimal"
            size={200}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
          />
        </DeleteDialog>
      )
    case 'sign_off':
      return (
        <SignatureDialog
          model="supervisor"
          title="Sign Off as Supervisor"
          yesLabel="Apply Signature & Sign Off"
          onYes={async (signature) => {
            // start loader
            setIsActionProcessing(true)

            await action(signature) // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        >
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type="minimal"
            size={200}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
          />
        </SignatureDialog>
      )
    case 'employee_sign_off':
      return (
        <SignatureDialog
          model="supervisor"
          title="Staff Signature"
          yesLabel="Apply Signature"
          onYes={async (signature: any) => {
            // start loader
            setIsActionProcessing(true)

            await action(signature) // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        >
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type="minimal"
            size={200}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
          />
        </SignatureDialog>
      )
    case 'send_for_review':
      return (
        <ConfirmDialog
          title="Send for Review"
          yesLabel="Send for Review"
          onYes={async () => {
            // start loader
            setIsActionProcessing(true)

            await action() // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        >
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type="minimal"
            size={200}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
          />
        </ConfirmDialog>
      )
    case 'request_updates':
      return (
        <TextareaDialog
          model="rejected_reason"
          title="What updates should be made?"
          onYes={async (updates) => {
            // start loader
            setIsActionProcessing(true)

            await action(updates) // process action

            // stop loader
            setIsActionProcessing(false)
          }}
          yesLabel="Ask For Updates"
        >
          <Button
            label={label}
            glyph={glyph}
            color={color}
            type="minimal"
            size={200}
            display="inline-flex"
            isLoading={isActionProcessing}
            permission={permission}
          />
        </TextareaDialog>
      )
    default:
      return (
        <Button
          label={label}
          glyph={glyph}
          color={color}
          type="minimal"
          size={200}
          display="inline-flex"
          isLoading={isActionProcessing}
          permission={permission}
          onClick={async () => {
            // start loader
            setIsActionProcessing(true)

            await action() // process action

            // stop loader
            setIsActionProcessing(false)
          }}
        />
      )
  }
}

const Table = (props: any) => {
  const {
    asCard,
    batchActions,
    batchActionsConfig,
    columns,
    data,
    defaultDateFilter,
    emptyActions,
    emptyDescription,
    emptyHeight,
    groupBy,
    help,
    hiddenColumns,
    icon,
    isBlocked,
    isLoading,
    isFetching,
    isBehave = false,
    localStorageKey,
    onDateUpdates,
    onSelect,
    pageSize,
    renderExpandHeader,
    showExpandColumn,
    showFilters,
    showHeaders,
    showPagination,
    showSettings,
    showSorting,
    sortBy,
    subExpandKey,
    timezone,
    title,
    titleAfter,
    useDateFilters,
    useServerSorting,
    useServerPagination,
    serverPagination,
    onPaginationUpdate,
    onSortingUpdate,
    customRowProps,
    customCellProps,
    testKey,
    className,
  } = props

  const isEmpty = !data || size(data) === 0
  const isProcessing = isLoading || isEmpty || isBlocked
  const userSettings = useServerSorting
    ? produce(getTableSettings(localStorageKey), (draft: any) => {
        if (draft?.sortBy) draft.sortBy = []
      })
    : getTableSettings(localStorageKey)

  const RootTag = asCard ? Card : 'div'
  const rootClasses = clsx(asCard && 'as-card', className)

  const [filtersVisible, setFiltersVisible] = React.useState(useServerSorting ? false : showFilters && userSettings?.showFilters)
  const [dates, setDates] = React.useState(null)

  const [editOverlayOpen, setEditOverlayOpen]: any = React.useState(false)
  const [editSettings, setEditSettings]: any = React.useState({})

  const openEditOverlay = (args: any) => {
    setEditOverlayOpen(true)
    setEditSettings(args)
  }

  const closeEditOverlay = () => {
    setEditOverlayOpen(false)
    setEditSettings({})
  }

  const tableInstance = useTable(
    {
      data: React.useMemo(() => mapToArray(data, []), [data]),
      columns: React.useMemo(() => columns, [columns]),
      filterTypes: React.useMemo(() => filterTypes(timezone), [timezone]),
      defaultColumn,
      disableFilters: !filtersVisible,
      disableSortBy: !showSorting,
      getSubRows: (row: any) => getSubRows(row, subExpandKey),
      initialState: {
        ...userSettings,
        ...(groupBy && { groupBy: [groupBy] }),
        ...(sortBy && { sortBy: [sortBy] }),
        hiddenColumns: [...(hiddenColumns || []), ...(userSettings?.hiddenColumns || [])] || [],
        pageIndex: 0,
        pageSize: showPagination ? userSettings?.pageSize || pageSize : 9999,
      },
      ...(useServerPagination && {
        manualPagination: true,
        autoResetPage: false,
        pageCount: serverPagination?.meta?.pages,
      }),
      ...(useServerSorting && {
        manualSortBy: true,
        autoResetSortBy: false,
      }),
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks: any) => {
      hooks.visibleColumns.push((columns: any) => [
        ...(onSelect || batchActions || batchActionsConfig ? [isBehave ? behaveSelectionColumn : selectionColumn] : []),
        ...(showExpandColumn ? [expanderColumn] : []),
        ...columns,
      ])
    },
  )

  const {
    allColumns,
    canNextPage,
    canPreviousPage,
    filteredRows,
    getTableProps,
    getToggleAllRowsExpandedProps,
    gotoPage,
    headerGroups,
    isAllRowsExpanded,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    selectedFlatRows,
    setAllFilters,
    setColumnOrder,
    state,
    toggleAllRowsSelected,
    totalColumnsWidth,
    setPageSize,
  } = tableInstance

  const clearFilters = () => setAllFilters([])
  const clearSelected = () => toggleAllRowsSelected(false)

  const hasFilters = state.filters.length > 0
  const emptyFilterResults = hasFilters && filteredRows.length === 0
  const noOfSelectedRows = size(state.selectedRowIds)
  const hasSelectedRows = noOfSelectedRows > 0

  const toggleFiltersVisible = () => {
    setFiltersVisible((c: boolean) => !c)
    clearFilters()
  }

  // Update local storage settings when table state changes
  React.useEffect(() => {
    if (!localStorageKey) return

    setTableSettings(localStorageKey, {
      ...state,
      selectedRowIds: [], // don't save the selected items to local storage
      showFilters: filtersVisible, // disableFilters key is outside the initial state of the tableInstance and needs to be saved separately
      pageSize: PAGE_SIZES.includes(state.pageSize) ? state.pageSize : 25,
    })
  }, [state, filtersVisible, localStorageKey])

  // selectedFlatRows
  React.useEffect(() => {
    if (!onSelect) return
    onSelect(selectedFlatRows)
  }, [onSelect, selectedFlatRows])

  React.useEffect(() => {
    if (onDateUpdates) onDateUpdates(dates)
  }, [dates])

  React.useEffect(() => {
    if (!useServerPagination) return

    if (serverPagination?.pagination?.page !== state.pageIndex + 1 || serverPagination?.pagination?.items !== state.pageSize) {
      onPaginationUpdate({ page: state.pageIndex + 1, items: state.pageSize })
    }
  }, [state.pageIndex, state.pageSize])

  React.useEffect(() => {
    if (!useServerSorting) return

    // if not sorted, empty
    if (size(state.sortBy) === 0) return onSortingUpdate({})

    const sorted = state.sortBy[0]
    onSortingUpdate({ name: sorted.id, sort: sorted.desc ? 'DESC' : 'ASC' })
  }, [state.sortBy])

  // Cleanup
  React.useEffect(() => {
    if (useServerSorting && localStorageKey) {
      clearSelected()
      clearTableSortingSettings(localStorageKey)
    }
  }, [])

  return (
    <TableContext.Provider value={{ closeEditOverlay, editSettings, openEditOverlay }}>
      <RootTag css={styles.root} className={rootClasses}>
        {/* SETTINGS */}
        {showSettings && (
          <div css={styles.settings}>
            <Flex gap={2} centerY>
              {useServerPagination
                ? title && <div css={styles.count}>{countWord(title, serverPagination?.meta?.count)}</div>
                : title && (
                    <div data-test={`${snakeCase(title)}_counter`} css={styles.count}>
                      {countWord(title, size(data))}
                    </div>
                  )}

              {titleAfter}

              {batchActions && hasSelectedRows && batchActions(selectedFlatRows, clearSelected)}
              {hasSelectedRows &&
                batchActionsConfig?.map((action: any) => (
                  <ActionButton
                    label={action.label?.(noOfSelectedRows) || DEFAULT_ACTION_TYPES?.[action?.type]?.label?.(noOfSelectedRows)}
                    count={noOfSelectedRows}
                    glyph={action.glyph || DEFAULT_ACTION_TYPES?.[action?.type]?.glyph}
                    color={action.color || DEFAULT_ACTION_TYPES?.[action?.type]?.color}
                    type={action.type}
                    permission={action.permission}
                    action={async (data: any) => {
                      await action?.action({
                        ids: selectedFlatRows.map((o: any) => o.original.id),
                        data: data,
                        selectedRows: selectedFlatRows,
                      })
                      clearSelected()
                    }}
                  />
                ))}

              {hasFilters && (
                <Button
                  label={`Clear ${countWord('Filters', state.filters.length)}`}
                  display="inline-flex"
                  type="minimal"
                  color="text"
                  glyph="cross"
                  glyphColor={COLORS.red}
                  size={200}
                  onClick={clearFilters}
                />
              )}

              {isFetching && <Loader color={COLORS.blue} size={16} />}

              <Flex gap={2} css={styles.rightHeaderButtons}>
                {(showExpandColumn || renderExpandHeader || state.groupBy?.length > 0) && (
                  <Button
                    label={isAllRowsExpanded ? 'Contract All Rows' : 'Expand All Rows'}
                    display="inline-flex"
                    type="minimal"
                    color="text"
                    glyph={isAllRowsExpanded ? 'table_delete_row' : 'table_insert_row'}
                    glyphColor={COLORS.blue}
                    size={200}
                    {...getToggleAllRowsExpandedProps()}
                  />
                )}

                {showFilters && !useServerPagination && (
                  <Button
                    testKey="hide_show_filters_button"
                    label={filtersVisible ? 'Hide Filters' : 'Show Filters'}
                    display="inline-flex"
                    type="minimal"
                    color="text"
                    glyph="view"
                    glyphColor={COLORS.blue}
                    size={200}
                    onClick={toggleFiltersVisible}
                  />
                )}

                <SummonOverlay
                  overlay={<TableOptionsOverlay columns={allColumns} hiddenColumns={hiddenColumns} onColumnReorder={setColumnOrder} />}
                >
                  <Button
                    testKey="column_options_button"
                    label="Column Options"
                    type="minimal"
                    color="text"
                    glyph="selector"
                    glyphColor={COLORS.blue}
                    display="inline-flex"
                    size={200}
                  />
                </SummonOverlay>

                {useDateFilters && (
                  <DateRangeSelector onChange={setDates} startDate={dates?.[0]} endDate={dates?.[1]} defaultPeriod={defaultDateFilter} />
                )}

                {help}
              </Flex>
            </Flex>
          </div>
        )}

        {/* TABLE */}
        <div css={styles.scrollWrapper}>
          <div data-test={testKey} {...getTableProps()} css={styles.table}>
            {showHeaders &&
              headerGroups.map((headerGroup: any) => (
                <div className="table-row" {...headerGroup.getHeaderGroupProps()} data-test="table_header">
                  {headerGroup.headers.map((column: any) => (
                    <div
                      data-test="header_cell"
                      className={getHeaderCellClassNames(column)}
                      {...column.getHeaderProps()}
                      width={column.width}
                    >
                      <div className={clsx('header-title', { 'is-sortable': column.canSort })} {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted && (
                          <Glyph glyph={column.isSortedDesc ? 'triangle_down' : 'triangle_up'} size={12} css={styles.sortTriangle} />
                        )}
                      </div>

                      {column.canFilter && <div css={styles.filter}>{column.render('Filter')}</div>}

                      {!column.isFixedWidth && (
                        <div className={`column-resizer ${column.isResizing ? 'is-resizing' : ''}`} {...column.getResizerProps()} />
                      )}
                    </div>
                  ))}
                </div>
              ))}

            {isProcessing ? (
              <>
                <State
                  icon={icon}
                  title={title}
                  isEmpty={isEmpty}
                  isBlocked={isBlocked}
                  isLoading={isLoading}
                  emptyActions={emptyActions}
                  emptyDescription={emptyDescription}
                  minHeight={emptyHeight}
                  help={help}
                />
              </>
            ) : (
              <>
                {/* ROWS */}
                {page.map((row: any) => {
                  prepareRow(row)

                  if ((row.canExpand && renderExpandHeader) || row.isGrouped) {
                    return (
                      <div {...row.getToggleRowExpandedProps()} className="expand-header" style={{ width: totalColumnsWidth }}>
                        <div className="expand-header-content">
                          <Glyph size={12} glyph="triangle_down" className={getExpandArrowClassNames(row.isExpanded)} />
                          {row.isGrouped ? row.cells[0].render('Cell') : renderExpandHeader(row)}
                        </div>
                      </div>
                    )
                  }

                  return (
                    <TableRow {...row.getRowProps(customRowProps?.(row))} data-test="table_row">
                      {row.cells.map((cell: any) => (
                        <div data-test="table_cell" className={getCellClassNames(cell)} {...cell.getCellProps(customCellProps?.(cell))}>
                          {cell.isPlaceholder ? null : cell.render('Cell')}
                        </div>
                      ))}
                    </TableRow>
                  )
                })}
              </>
            )}
          </div>
        </div>

        {/* EMPTY STATE */}
        {emptyFilterResults && (
          <div css={styles.emptyFilterResults}>
            <div>
              <Glyph glyph="search" color={COLORS.text} css={styles.emptyFilterGlyph} />

              <h4 css={styles.emptyFilterTitle}>No Filtered Results</h4>

              <Button
                label={`Clear ${countWord('Filters', state.filters.length)}`}
                display="inline-flex"
                color="text"
                glyph="close"
                glyphColor={COLORS.red}
                size={200}
                onClick={clearFilters}
              />
            </div>
          </div>
        )}

        {/* FOOTER */}
        <div css={styles.footer} className="table-footer">
          <Dropdown
            trigger={
              <div css={styles.pageSizeDropdownLabel}>
                <b>{state?.pageSize} records </b>
                <span> / page</span>
                <Glyph glyph="triangle_down" size={12} />
              </div>
            }
            css={styles.pageSizeDropdown}
          >
            {PAGE_SIZES.map((size: any) => (
              <DropdownItem
                key={size}
                onClick={() => {
                  setPageSize(size)
                }}
                label={
                  <>
                    <b>{size}</b> rows
                  </>
                }
              />
            ))}
          </Dropdown>

          {showPagination && !emptyFilterResults && (
            <TablePagination
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              gotoPage={gotoPage}
              nextPage={nextPage}
              pageIndex={useServerPagination ? serverPagination?.meta?.page : state?.pageIndex + 1}
              pageCount={pageCount}
              previousPage={previousPage}
              isLoading={isProcessing}
            />
          )}
        </div>

        <SummonOverlay isOpen={editOverlayOpen} onClose={closeEditOverlay} overlay={<TableEditOverlay />} />
      </RootTag>
    </TableContext.Provider>
  )
}

const styles = {
  root: {
    '&.as-card': {
      overflow: 'visible',

      '.table-footer': {
        borderRadius: '0 0 5px 5px',
      },
    },
  },

  scrollWrapper: {
    overflowX: 'auto',
    overflowY: 'hidden',
    WebkitOverflowScrolling: 'touch',
    position: 'relative',
    zIndex: 0,
  },

  settings: {
    padding: '0.2em 0.25em',
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },

  count: {
    fontWeight: 600,
    padding: '0 0.5rem',
  },

  sortTriangle: {
    marginLeft: '0.25em',
  },

  filter: {
    marginTop: '0.5em',
  },

  rightHeaderButtons: {
    marginLeft: 'auto !important',
  },

  emptyFilterResults: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
    minHeight: 280,
    textAlign: 'center',
  },

  emptyFilterGlyph: {
    marginBottom: '0.75rem',
    opacity: 0.6,
  },

  emptyFilterTitle: {
    margin: '0 0 0.75rem',
    fontWeight: 500,
    opacity: 0.6,
  },

  selectCheckbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '.table-cell &': {
      opacity: 0,
    },

    '.table-cell:hover &, &.is-checked': {
      opacity: 1,
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },

  selectCheckboxInput: {
    display: 'none',
    WebkitAppearance: 'none',
  },

  selectCheckboxElement: {
    '&.is-checked': {
      background: COLORS.blue,
      boxShadow: `0 0 0 0 ${COLORS.blue} !important`,
    },

    'label:hover &, .input:hover &': {
      borderColor: tint(0.1, COLORS.blue),
      boxShadow: `0 0 0 2px ${tint(0.5, COLORS.blue)}`,

      '&.is-checked': {
        borderColor: darken(0.04, COLORS.blue),
        background: darken(0.04, COLORS.blue),
      },
    },
  },

  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    borderTop: `1px solid ${COLORS.divider}`,
    background: COLORS.white,
    position: 'sticky',
    bottom: 0,
  },

  table: {
    cursor: 'auto',
    width: '100%',
    fontSize: '0.96rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    background: COLORS.white,

    '.table-header-cell': {
      borderBottom: `1px solid ${BORDER_COLOR}`,
      fontWeight: 600,
      textAlign: 'left',
      paddingTop: '0.6em',
      paddingBottom: '0.6em',
      position: 'relative',

      '.column-resizer': {
        opacity: 0,
        display: 'inline-block',
        width: 16,
        height: '100%',
        position: 'absolute',
        right: 0,
        top: 0,
        transform: `translateX(50%)`,
        zIndex: 3,
        touchAction: 'none',

        '&:hover': {
          opacity: 0.4,
        },

        '&.is-resizing': {
          opacity: 1,
        },

        '&::before': {
          content: '""',
          position: 'absolute',
          transform: `translateX(-50%)`,
          top: 0,
          bottom: 0,
          left: '50%',
          width: 3,
          background: COLORS.vividBlue,
        },
      },
    },

    '.header-title.is-sortable:hover': {
      color: COLORS.blue,
    },

    '.table-row': {
      '&:nth-child(odd)': {
        '.table-cell': {
          background: BANDING_COLOR,
        },
      },

      '&:hover .table-cell': {
        position: 'relative',
        zIndex: 1,

        color: '#000',
        background: tint(0.9, COLORS.blue),
        boxShadow: `
          inset 0 1px 0 ${tint(0.3, COLORS.blue)},
          inset 0 -1px 0 ${tint(0.3, COLORS.blue)}
        `,

        '&.is-sticky': {
          zIndex: 3,
        },
      },

      '&:first-child:hover .table-cell': {
        boxShadow: `inset 0 -1px 0 ${BORDER_COLOR}`,
      },
    },

    '.table-header-cell, .table-cell': {
      margin: 0,
      padding: '0.8em 0.75em',
      overflow: 'hidden',

      boxShadow: `inset -1px 0 0 ${BORDER_COLOR}`,
      background: COLORS.white,

      position: 'relative',
      zIndex: 0,

      '&:hover, &:focus-within': {
        zIndex: 2,
      },

      '&.is-selected': {
        background: `${tint(0.88, COLORS.blue)} !important`,
      },

      '&.is-fixed-width': {
        flex: '0 0 auto !important',
      },

      '&:last-child': {
        boxShadow: 'none',
      },

      '@media (min-width: 600px)': {
        padding: '0.4em 0.75em',
      },
    },

    '.expand-header': {
      cursor: 'pointer',
      padding: '0.4rem 0.75rem',
      borderBottom: `1px solid ${COLORS.divider}`,
      width: '100% !important',

      '&:hover': {
        background: COLORS.hover,
      },
    },

    '.expand-header-content': {
      display: 'flex',
      alignItems: 'center',
    },

    '.expand-header-arrow': {
      transform: 'rotate(-90deg)',
      marginRight: '0.5rem',

      '&.is-expanded': {
        transform: `rotate(0deg)`,
      },
    },

    // Sticky Column
    '@media (min-width: 600px)': {
      '.table-header-cell, .table-cell': {
        '&.is-sticky': {
          position: 'sticky !important',
          left: 0,
          zIndex: 2,
          boxShadow: `
            inset -1px 0 0 ${BORDER_COLOR},
            3px 0 0 ${COLORS.shadow}
          `,

          '&:hover': {
            zIndex: 1,
          },
        },
      },
    },
  },

  pageSizeDropdown: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.85rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    padding: '0.5rem',

    svg: {
      marginLeft: '0.25rem',
    },
  },

  pageSizeDropdownLabel: {
    display: 'flex',
    alignItems: 'center',

    span: {
      color: COLORS.textMuted,
      margin: '0 0.25rem',
    },
  },
}

Table.defaultProps = {
  emptyHeight: 200,
  pageSize: 25,
  showFilters: true,
  showHeaders: true,
  showPagination: true,
  showSettings: true,
  showSorting: true,
}

export default withSettings(Table)
