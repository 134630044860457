import React from 'react'
import { useSelector } from 'react-redux'
import { Global } from '@emotion/react'
import { useHistory } from 'react-router-dom'

import { initialize, setup } from '../modules/init'

import withSettings from '@behavehealth/hocs/withSettings'
import { apiUpdate } from '@behavehealth/modules/api'
import { titleCase } from '@behavehealth/utils/functions'

import { Emoji, Flex, FormSection, H1, Logo, P, PortalsContainer, Button } from '@behavehealth/components'
import SteppedOnboardingForm from '@behavehealth/components/SteppedOnboardingForm/SteppedOnboardingForm'

const Setup = () => {
  const history = useHistory()

  const [loading, setLoading] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const tenant = useSelector((state: any) => state.me.tenant)

  React.useEffect(() => {
    const init = async () => {
      await initialize()
      await setup()
    }

    init()
  }, [])

  const updateSetup = async (data: any) => {
    setLoading(true)

    try {
      await apiUpdate({
        url: '/facilities/current/activate_account',
        params: {
          questionnaire: data.questionnaire,
        },
        reducer: 'me',
      })
      setLoading(false)
      setSubmitted(true)
    } catch (error) {
      console.debug(error)
      setLoading(false)
    }
  }

  if (!tenant) return null

  return (
    <div>
      <Global styles={styles.main} />

      <Flex vertical stretchSelfMax nowrap justifyContent="space-between" className="!w-full !pb-8">
        <div>
          <Flex justifyContent="center" className="!my-8 !mx-0">
            <Logo logo="ehr" />
          </Flex>
        </div>

        <div css={{ width: '100%', maxWidth: 650, margin: '0 auto' }}>
          <FormSection maxWidth="650px" className="text-center !mb-8">
            <Emoji emoji="🎉" size={40} />
            <H1>{tenant.name} Account Setup</H1>
            {submitted ? (
              <>
                <P>
                  Your <b>Business Profile</b> was submitted successfully!
                </P>
                <Button label="Open App" onClick={() => history.push('/dashboard')} />
              </>
            ) : (
              <P>
                To configure the app to your personal requirements we would like to ask you a few questions. We found it takes around 5
                minutes!
              </P>
            )}
            <P>Account Type: {titleCase(tenant.category)}</P>
          </FormSection>

          {!submitted && (
            <SteppedOnboardingForm initialData={tenant} accountType={tenant.category} onSave={updateSetup} isLoading={loading} />
          )}
        </div>
      </Flex>

      <PortalsContainer />
    </div>
  )
}

const styles = {
  page: { padding: '1rem', maxWidth: 1000, width: '100%' },
  main: {
    'html, body, #app': {
      overflow: 'unset',
      width: 'auto',
      height: 'auto',
    },
  },
  success: {
    maxWidth: 600,
    margin: '0 auto',
    textAlign: 'center',
    padding: '100px 20px',
  },
}

export default withSettings(Setup)
