import React from 'react'
import size from 'lodash/size'

import { useGet } from '../../../hooks/useNewAPI'
import withSettings from '../../../hocs/withSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import FormSection from '../../../components/Forms/FormSection'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import Workflow from '../../../components/Workflow/Workflow'

import { ProgramAssignmentOverlay } from '../../Programs/ProgramAssignmentOverlay'

const columns = (timezone: string) => [
  {
    width: 200,
    accessor: 'place.program.name',
    Header: 'Program Name',
    Cell: ({ value, row }: any) => (
      <SummonOverlay overlay={<ProgramAssignmentOverlay showBackdrop initialData={row.original} position="right" />}>
        <TableCell.MainLink label={value} avatar={row.original.avatar} />
      </SummonOverlay>
    ),
  },
  {
    width: 160,
    accessor: 'place.phase.name',
    Header: 'List',
  },
  {
    width: 160,
    accessor: 'place.name',
    Header: 'Seat',
  },
  {
    width: 200,
    accessor: 'started_at',
    Header: 'Start Date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 200,
    accessor: 'ended_at',
    Header: 'End Date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
]

const AddPhaseButton = ({ match, client }) => (
  <SummonOverlay overlay={<ProgramAssignmentOverlay match={match} dataID="new" initialData={{ reference: client }} position="right" />}>
    <Button
      label="Add Program Assignment"
      type="primary"
      glyph="add"
      size={300}
      display="inline-flex"
      permission="programs.edit"
    />
  </SummonOverlay>
)

const Programs = ({ match, setStatus, timezone }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id } = match.params

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const queryParams = { category: 'program_management' }

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'occupancies', queryParams],
    url: `/residents/${resource_id}/occupancies`,
    params: queryParams,
  })

  const isEmpty = size(data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  if (isEmpty || isLoading) {
    return (
      <State
        isLoading={isLoading}
        isEmpty={isEmpty}
        title="Programs"
        icon="checklist"
        emptyDescription="No lists added yet"
        emptyActions={<AddPhaseButton match={match} client={client} />}
      />
    )
  }

  return (
    <>
      <FormSection maxWidth="100%">
        <Card>
          <Table
            icon="checklist"
            title="Programs"
            showSettings={false}
            data={data}
            columns={columns(timezone)}
            emptyDescription="No lists have been added yet."
            emptyActions={<AddPhaseButton match={match} client={client} />}
          />
          <CardContent>
            <AddPhaseButton match={match} client={client} />
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>
    </>
  )
}

export default withSettings(Programs)
