import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  completed: {
    label: 'Completed',
    color: 'green',
  },
  in_progress: {
    label: 'In Progress',
    color: 'paleBlue',
  },
  overdue: {
    label: 'Overdue',
    color: 'red',
  },
  dev: {
    label: 'Dev',
    color: 'gray',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
