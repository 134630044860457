import React from 'react'

import { DataFormOverlay } from '../DataFormOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'

const INITIAL_STATE = {
  q1: 0,
  q2: 0,
  q3: 0,
  q4: 0,
  q5: 0,
  q6: 0,
  q7: 0,
  q8: 0,
  q9: 0,
  q10: 0,
  q11: 0,
  q12: 0,
  q13: 0,
  q14: 0,
  q15: 0,
  q16: 0,
  q17: 0,
  q18: 0,
  q19: 0,
  q20: 0,
  q21: 0,
  q22: 0,
  q23: 0,
  q24: 0,
  q25: 0,
  q26: 0,
  q27: 0,
  q28: 0,
  q29: 0,
  q30: 0,
}

const RootPROQOLOverlay = (props: any) => {
  return (
    <DataFormOverlay
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Professional Quality Of Life Scale (PROQOL)"
      category="clinical_measurement"
      subcategory="proqol"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <p>
          When you [help] people you have direct contact with their lives. As you may have found, your compassion for those you [help] can
          affect you in positive and negative ways. Below are some questions about your experiences, both positive and negative, as a
          [helper]. Consider each of the following questions about you and your current work situation.
        </p>
      </Section>

      <Divider />

      <Questionnaire />

      <Divider />

      <Section title="Sources" headingType="h2">
        <p>B. Hudnall Stamm, 2009-2012. Professional Quality of Life: Compassion Satisfaction and Fatigue Version 5 (ProQOL).</p>
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const { isPortal } = useSettings()

  return (
    <>
      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Grid gap="1.5rem">
          <Alert contrast glyph="info">
            Select the number that honestly reflects how frequently you experienced these things in the last 30 days.
          </Alert>

          {QUESTIONS.map((question, index) => (
            <RadioGroup
              key={`q-${index}`}
              label={`${index + 1}. ${question}`}
              model={`data.questionnaire.q${index + 1}`}
              layout="vertical-dense"
              onUpdate={(state: any) => setScore((o) => ({ ...o, [`q${index + 1}`]: state.value }))}
            >
              <Radio label="1 – Never" value={1} />
              <Radio label="2 – Rarely" value={2} />
              <Radio label="3 – Sometimes" value={3} />
              <Radio label="4 – Often" value={4} />
              <Radio label="5 – Very Often" value={5} />
            </RadioGroup>
          ))}
        </Grid>
      </Section>

      {!isPortal && (
        <>
          <Divider />

          <Section
            title="Scoring"
            headingType="h2"
            scrollview={{
              id: 'scoring',
              name: 'Scoring',
            }}
          >
            <Grid gap="1.5rem" className="mt-2">
              {SECTIONS_SCORING.map((section) => {
                const sectionScore = Object.entries(score).reduce((a, [key, value]) => {
                  const questionNumber = parseInt(key.replace('q', ''))

                  if (!value) return NaN

                  if (value && section.questions.includes(questionNumber)) {
                    let questionValue = value

                    if (section.reversed && section.reversed.includes(questionNumber)) {
                      questionValue = 6 - value
                    }

                    return a + questionValue
                  }

                  return a
                }, 0)

                return <Score title={section.title} finalScore={sectionScore} scoring={section.scoring} />
              })}
            </Grid>
          </Section>
        </>
      )}
    </>
  )
}

const QUESTIONS = [
  `I am happy.`,
  `I am preoccupied with more than one person I [help].`,
  `I get satisfaction from being able to [help] people.`,
  `I feel connected to others.`,
  `I jump or am startled by unexpected sounds.`,
  `I feel invigorated after working with those I [help].`,
  `I find it difficult to separate my personal life from my life as a [helper].`,
  `I am not as productive at work because I am losing sleep over traumatic experiences of a person I [help].`,
  `I think that I might have been affected by the traumatic stress of those I [help].`,
  `I feel trapped by my job as a [helper].`,
  `Because of my [helping], I have felt "on edge" about various things.`,
  `I like my work as a [helper].`,
  `I feel depressed because of the traumatic experiences of the people I [help].`,
  `I feel as though I am experiencing the trauma of someone I have [helped].`,
  `I have beliefs that sustain me.`,
  `I am pleased with how I am able to keep up with [helping] techniques and protocols.`,
  `I am the person I always wanted to be.`,
  `My work makes me feel satisfied.`,
  `I feel worn out because of my work as a [helper].`,
  `I have happy thoughts and feelings about those I [help] and how I could help them.`,
  `I feel overwhelmed because my case [work] load seems endless.`,
  `I believe I can make a difference through my work.`,
  `I avoid certain activities or situations because they remind me of frightening experiences of the people I [help].`,
  `I am proud of what I can do to [help].`,
  `As a result of my [helping], I have intrusive, frightening thoughts.`,
  `I feel "bogged down" by the system.`,
  `I have thoughts that I am a "success" as a [helper].`,
  `I can't recall important parts of my work with trauma victims.`,
  `I am a very caring person.`,
  `I am happy that I chose to do this work.`,
]

const SECTIONS_SCORING = [
  {
    title: 'Compassion Satisfaction',
    questions: [3, 6, 12, 16, 18, 20, 22, 24, 27, 30],
    scoring: {
      22: 'Low',
      41: 'Moderate',
      150: 'High',
    },
  },
  {
    title: 'Burnout',
    questions: [1, 4, 8, 10, 15, 17, 19, 21, 26, 29],
    reversed: [1, 4, 15, 17, 29],
    scoring: {
      22: 'Low',
      41: 'Moderate',
      150: 'High',
    },
  },
  {
    title: 'Secondary Traumatic Stress',
    questions: [2, 5, 7, 9, 11, 13, 14, 23, 25, 28],
    scoring: {
      22: 'Low',
      41: 'Moderate',
      150: 'High',
    },
  },
]

export const PROQOLOverlay = withOverlayError(RootPROQOLOverlay)
