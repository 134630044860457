import { DateTime } from 'luxon'

import { request } from './axios'
import Analytics from './analytics'

export const SIGNED_IN = 'global/SIGNED_IN'
export const SIGN_OUT = 'global/SIGN_OUT'

export const supportedAuth = async ({ username }: any) => {
  Analytics.queue({
    type: 'track',
    name: 'Supported Login Checked',
  })

  try {
    const result = await request.post('/auth/sa', { username: username })
    return result.data
  } catch (errors) {
    throw errors
  }
}

export const login = async (auth: any) => {
  Analytics.queue({
    type: 'track',
    name: 'Login Attempted',
  })

  try {
    const results = await request.post(`/login${auth.include_access ? '?include_access=true' : ''}`, {
      username: auth.username,
      password: auth.password,
    })

    if (results.data.token_type === 'jwt') {
      // set the access token
      localStorage.setItem('bh.at', results.data.access_token)

      // set the refresh token
      localStorage.setItem('bh.rt', results.data.refresh_token)

      // set the expires at
      localStorage.setItem('bh.ea', DateTime.local().plus({ seconds: results.data.expires_in }).toISO())
    } else if (results.data.token_type === 'session') {
      // set the session token
      localStorage.setItem('bh.stk', results.data.session_token)

      // set the expires at
      localStorage.setItem('bh.ea', DateTime.fromISO(results.data.expires_at))
    }

    // set the login as value
    if (auth.as) localStorage.setItem('bh.as', auth.as)

    return results
  } catch (errors) {
    throw errors
  }
}

export const loginClient = async (auth: any) => {
  Analytics.queue({ type: 'track', name: 'Client Login Attempted' })

  try {
    const results = await request.post('/login?include_access=true', {
      username: auth.username || auth.email,
      password: auth.password,
    })

    if (results.data.token_type === 'jwt') {
      // set the access token
      localStorage.setItem('bh.at', results.data.access_token)

      // set the expires at
      localStorage.setItem('bh.ea', DateTime.local().plus({ seconds: results.data.expires_in }).toISO())
    } else if (results.data.token_type === 'session') {
      // set the session token
      localStorage.setItem('bh.stk', results.data.session_token)

      // set the expires at
      localStorage.setItem('bh.ea', DateTime.fromISO(results.data.expires_at))
    }

    // set tenants
    localStorage.setItem('bh.tn', results.data.tenants)

    // set the login as value
    if (auth.as) localStorage.setItem('bh.as', auth.as)

    Analytics.queue({ type: 'track', name: 'Client Login Successful' })

    return results.data.tenants
  } catch (errors) {
    throw errors
  }
}

export const devLogin = async (auth: any) => {
  Analytics.queue({ type: 'track', name: 'Login Attempted' })

  try {
    const results = await request.post('/dev-login', {
      username: auth.username,
      password: auth.password,
    })

    // set the access token
    localStorage.setItem('bh.at', results.data.access_token)

    // set the refresh token
    localStorage.setItem('bh.rt', results.data.refresh_token)

    // set the expires at
    localStorage.setItem('bh.ea', DateTime.local().plus({ seconds: results.data.expires_in }).toISO())

    // set the impersonate value
    if (auth.impersonate) localStorage.setItem('bh.imp', auth.impersonate)

    // set the login as value
    if (auth.as) localStorage.setItem('bh.as', auth.as)

    return results
  } catch (errors) {
    throw errors
  }
}

export const sendOTP = async (auth: any) => {
  Analytics.queue({
    type: 'track',
    name: 'Send OTP',
  })

  try {
    const results = await request.post('/passcode/send', { username: auth.username })

    return results
  } catch (errors) {
    throw errors
  }
}

export const loginOTP = async (auth: any) => {
  Analytics.queue({
    type: 'track',
    name: 'OTP Login Attempted',
  })

  try {
    const results = await request.post('/passcode/authenticate', {
      username: auth.username,
      code: auth.code,
    })

    // set the session token
    localStorage.setItem('bh.stk', results.data.data.session_token)

    // set the expires at
    localStorage.setItem('bh.ea', DateTime.fromISO(results.data.data.expires_at))

    // set the login as value
    if (auth.as) localStorage.setItem('bh.as', auth.as)

    return results
  } catch (errors) {
    throw errors
  }
}

export const sendMagic = async (auth: any) => {
  Analytics.queue({ type: 'track', name: 'Send Magic Link' })

  try {
    const results = await request.post('/magic/send', { username: auth.username })
    return results
  } catch (errors) {
    throw errors
  }
}

export const sendMagicReset = async (auth: any) => {
  Analytics.queue({ type: 'track', name: 'Send Reset Magic Link' })

  try {
    const results = await request.post('/magic/reset/send', { username: auth.username })
    return results
  } catch (errors) {
    throw errors
  }
}

export const loginMagic = async (auth: any) => {
  Analytics.queue({ type: 'track', name: 'Magic Login Attempted' })

  try {
    const results = await request.post('/magic/authenticate', {
      token: auth.token,
    })

    // set the session token
    localStorage.setItem('bh.stk', results.data.data.session_token)

    // set the expires at
    localStorage.setItem('bh.ea', DateTime.fromISO(results.data.data.expires_at))

    // set the login as value
    if (auth.as) localStorage.setItem('bh.as', auth.as)

    return results
  } catch (errors) {
    throw errors
  }
}

export const logout = async () => {
  // Add event dispatcher to comunicate with the mobile app
  // const onLogout = invoke.bind('onLogout')
  // if (onLogout) {
  //   onLogout()
  // }

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'logout' }))
  }

  // update defaults for requests
  delete request.defaults.headers.common['Authorization']
  delete request.defaults.headers.common['Session-Token']
  delete request.defaults.headers.common['As']
  delete request.defaults.headers.common['X-Tenant']
  delete request.defaults.headers.common['X-Community-Id']
  delete request.defaults.headers.common['X-Community-Slug']

  localStorage.removeItem('bh.at') // access token
  localStorage.removeItem('bh.stk') // session token
  localStorage.removeItem('bh.rt') // refresh token
  localStorage.removeItem('bh.ea') // expires at
  localStorage.removeItem('bh.stn') // selected tenant
  sessionStorage.removeItem('bh.stn') // selected tenant
  localStorage.removeItem('bh.ftn') // focused tenant
  sessionStorage.removeItem('bh.sct') // selected community tenant

  localStorage.removeItem('bh.tn') // tenants
  localStorage.removeItem('bh.st') // state
  localStorage.removeItem('bh.imp') // impersonate
  localStorage.removeItem('bh.filters') // filters

  Analytics.queue({ type: 'logout' })

  return true
}
