import React from 'react'
import SignaturePad from 'signature_pad'
import { DateTime } from 'luxon'

import { withFormContext } from './context'
import Grid from '../Grid'
import Button from '../Button'

import { useFormField } from './hooks/useFormField'

const SignatureCanvas = (props: any) => {
  const { width, height, timezone, onUpdate, onValidationUpdate } = props

  const { form, formActions } = useFormField({
    model: `${props.model}_data`,
    form: props.form,
    defaultEmptyValue: null,
    afterValidation: (state: any) => {
      if (onValidationUpdate) onValidationUpdate(state.isValid)
    },
    afterChange: (state: any) => {
      if (onUpdate) onUpdate(state)
    },
  })

  const [size, setSize] = React.useState([width, height])
  const [image, setImage] = React.useState(null)

  const canvasRef = React.useRef(null)
  const signaturePad = React.useRef(null)

  const onResize = () => {
    if (!canvasRef.current) return

    let ratio = Math.max(window.devicePixelRatio || 1, 1)
    let canvas = canvasRef.current

    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)

    setSize([canvas.offsetWidth, canvas.offsetHeight])
  }

  const clear = () => {
    setImage(null)

    // update signed at if there's a model
    if (props.model) form.setFieldValue(`${props.model}_signed_at`, null)

    return signaturePad.current.clear()
  }

  React.useEffect(() => {
    signaturePad.current = new SignaturePad(canvasRef.current, {
      ...props.options,
      onEnd: () => {
        setImage(signaturePad.current.toDataURL())
      },
    })

    onResize()
  }, [])

  // UPDATE FIELD
  React.useEffect(() => {
    formActions.setValue(image)

    // add signed_at if there's an image
    if (form && props.model && !!image) {
      form.setFieldValue(`${props.model}_signed_at`, DateTime.local().setZone(timezone).toISO())
    }
    // formActions.setValidity(!!image)
  }, [image])

  return (
    <>
      <Grid css={styles.manual}>
        <div css={styles} style={{ width: size[0], height: size[1] }}>
          <canvas ref={canvasRef} {...props.canvasProps} />
        </div>
      </Grid>

      <Grid css={styles.footer} autoFlow="column">
        <Button testKey="clear_canvas_button" type="minimal" color="red" glyph="cross" label="Clear" onClick={clear} fullWidth />
      </Grid>
    </>
  )
}

const styles = {
  display: 'inherit',
  position: 'relative',

  canvas: {
    width: '100%',
    height: '100%',
    touchAction: 'none',
    MsTouchAction: 'none',
  },

  manual: {
    display: 'flex',
    width: '100%',

    // Dotted Background (https://codepen.io/edmundojr/pen/xOYJGw)
    background: `linear-gradient(90deg, #fdfdfe 18px, transparent 1%) center, linear-gradient(#fdfdfe 18px, transparent 1%) center,#e8e8eb`,
    backgroundSize: '20px 20px',
    backgroundPosition: '10px 10px',
  },

  footer: {
    '.Button': {
      boxShadow: '1px 0 0 @separator_color',
      borderRadius: 0,
    },
  },
}

SignatureCanvas.defaultProps = {
  width: '100%',
  height: 200,
  clearOnResize: true,
  options: {
    throttle: 8,
    minDistance: 3,
    penColor: 'black',
    minWidth: 1,
    maxWidth: 3,
    velocityFilterWeight: 0.3,
  },
  validateOn: 'blur-change',
}

export default withFormContext(SignatureCanvas)
