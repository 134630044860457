import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { ProviderOverlay } from '@behavehealth/constructs/Providers/ProviderOverlay'

const pageConfig = {
  feature: 'company_credentials',
  title: 'Providers',
}

const Providers = () => {
  return (
    <>
      <ProvidersIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<ProviderOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const ProvidersIndex: React.FC = () => {
  const { tenant } = useSettings()

  const tableProps = useDataTable({
    name: ['credentials'],
    endpoint: `/facilities/${tenant.id}/credentials`,
    params: { category: 'npi' },
    updateDeleteEndpoint: '/credentials',
    localStorageKey: 'credentials_v1',
  })

  const isEmpty = size(tableProps.data) === 0

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'credential',
        width: 240,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} to={data.id} value={value} />,
      },
      {
        title: 'NPI',
        model: 'license_number',
        width: 200,
      },
      {
        title: 'Level',
        model: 'level',
        width: 150,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return startCase(value)
        },
      },
      {
        title: 'First Name',
        model: 'first_name',
        width: 150,
      },
      {
        title: 'Last Name',
        model: 'last_name',
        width: 150,
      },
      {
        title: 'Organization Name',
        model: 'organization',
        width: 150,
      },
    ],
    [],
  )

  const actions = (
    <Button
      as={Link}
      label="Add Provider"
      type="primary"
      glyph="add"
      link="new"
      permission="settings.insurance_providers.create"
    />
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Grid gap="1rem">
        <Card>
          <DataTable
            {...tableProps}
            title="Providers"
            icon="staff_credentials"
            columns={columns}
            emptyActions={actions}
            filtersConfig={FILTERS_CONFIG}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'settings.insurance_providers.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

const FILTERS_CONFIG = {
  credential: {
    title: 'Name',
    type: 'string',
  },
  license_number: {
    title: 'NPI',
    type: 'string',
  },
  level: {
    label: 'Level',
    type: 'multi_select',
    options: [
      {
        label: 'Individual',
        value: 'individual',
      },
      {
        label: 'Organization',
        value: 'organization',
      },
    ],
  },
  first_name: {
    title: 'First Name',
    type: 'string',
  },
  last_name: {
    title: 'Last Name',
    type: 'string',
  },
  organization: {
    title: 'Organization Name',
    type: 'string',
  },
}

export default withPageError(withMarketing(Providers, pageConfig))
