import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  cancelled: {
    label: 'Cancelled',
    color: 'red',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
  active: {
    label: 'Active',
    color: 'blue',
  },
}

const EventStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default EventStatus
