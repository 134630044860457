import React from 'react'
import clsx from 'clsx'

import { GLYPHS, COLORS } from '../theme'
import { Glyph as GlyphType } from '../declarations/types/Glyph'

type Props = {
  size?: number | string
  color?: string
  glyph?: GlyphType
} & React.HTMLAttributes<HTMLOrSVGElement>

const Glyph: React.FC<Props> = ({ size = 24, glyph = 'default', testKey, color, className, ...rest }) => {
  let glyphObj: any = {}

  if (typeof glyph === 'string') glyphObj = GLYPHS[glyph]
  else if (typeof glyph === 'object') glyphObj = glyph

  const fill = color || glyphObj?.color || COLORS[color]

  const styles = {
    width: size,
    height: size,
    display: 'inline-block',
    verticalAlign: 'middle',
    flex: '0 0 auto',
    fill: fill,
  }

  const classNames = clsx('glyph', {
    [className]: className,
  })

  return (
    <svg
      data-test={testKey}
      css={styles}
      width={size}
      height={size}
      viewBox={glyphObj?.viewBox || '0 0 30 30'}
      xmlns="http://www.w3.org/2000/svg"
      className={classNames}
      fill={fill}
      {...rest}
    >
      <path d={glyphObj?.path} />
    </svg>
  )
}

export default Glyph
