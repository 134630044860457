import React from 'react'
import { DateTime } from 'luxon'
import { transparentize } from 'polished'
import mergeWith from 'lodash/mergeWith'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../theme'

import MessageInput from './MessageInput'
import Messages from './Messages'
import State from '../State'

import { ChatChannelContext } from './context'

import { DT, sortObjectKeys } from '../../utils/functions'
import { apiGet, apiCreate, apiUpdate, apiDelete } from '../../modules/api'

const processCurrentMessage = (data: any, messageID: string, messages: any, timezone: string) => {
  const dateGroup = DT(data?.sent_at, timezone).toFormat('yyyy-MM-dd')
  return produce(messages, (draft: any) => {
    // update the message
    for (let i = 0; i < draft[dateGroup].length; i++) {
      if (draft[dateGroup][i].id === messageID) {
        draft[dateGroup][i] = data
        break
      }
    }
  })
}

const compareDates = (a: any, b: any) => {
  const dateA = DateTime.fromFormat(a, 'yyyy-MM-dd')
  const dateB = DateTime.fromFormat(b, 'yyyy-MM-dd')

  if (dateA < dateB) return -1
  else return 1
}

const ChannelContent = ({ channel, header, subheader, icon, timezone, permissions }: any) => {
  const [loading, setLoading] = React.useState(false)
  const [paginationLoading, setPaginationLoading] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [messages, setMessages] = React.useState({})
  const [pagination, setPagination] = React.useState(1)
  const [meta, setMeta] = React.useState({})
  const isEmpty = size(messages) <= 0

  // on mount, load the first messages
  React.useEffect(() => {
    const getMessages = async () => {
      try {
        setLoading(true)

        const result = await apiGet({ url: `/chat_messages?channel_slug=${channel?.slug}&page=1` })

        // update state
        setMessages(result.data?.data)
        setMeta(result.data?.meta)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (channel?.id && !loading) {
      getMessages()
      setPagination(1)
    }
  }, [channel])

  React.useEffect(() => {
    const getPaginatedMessages = async () => {
      try {
        setPaginationLoading(true)

        const result = await apiGet({ url: `/chat_messages?channel_slug=${channel?.slug}&page=${pagination}` })

        const newMessages = produce(messages, (draft: any) => {
          mergeWith(draft, result.data?.data, (a, b) => a?.concat(b))
        })

        const sortedMessages = sortObjectKeys(newMessages, compareDates)

        // update state
        setMessages(sortedMessages)
        setMeta(result.data?.meta)
      } catch (error) {
        console.error(error)
      } finally {
        setPaginationLoading(false)
      }
    }

    if (pagination > meta?.pages) return
    if (channel?.id && !loading) getPaginatedMessages()
  }, [pagination])

  const onLoadMoreMessages = () => {
    if (pagination >= meta?.pages) return
    setPagination((page) => page + 1)
  }

  const onSubmittedMessage = async ({ message, category }: any) => {
    if (!channel) return

    try {
      setSending(true)

      const result = await apiCreate({
        url: `/chat_messages`,
        params: {
          channel_slug: channel.slug,
          category: category,
          message: message,
        },
        notify: false,
      })

      // update messages with the new message
      const today = DT(result.data?.data?.sent_at, timezone).toFormat('yyyy-MM-dd')
      const updatedMessages = produce(messages, (draft: any) => {
        if (draft.hasOwnProperty(today)) {
          draft[today].push(result.data?.data)
        } else {
          draft[today] = [result.data?.data]
        }
      })

      setMessages(updatedMessages)
    } catch (error) {
      console.error(error)
    } finally {
      setSending(false)
    }
  }

  const toggleMessageStatus = async ({ id, params }: any) => {
    if (!id) return

    try {
      const result = await apiUpdate({
        url: `/chat_messages/${id}`,
        params: params,
        notify: false,
      })

      const updatedMessages = processCurrentMessage(result.data?.data, id, messages, timezone)
      setMessages(updatedMessages)
    } catch (error) {
      console.error(error)
    }
  }

  const addReaction = async ({ message, emoji }: any) => {
    if (!message) return

    try {
      await apiCreate({
        url: `/chat_reactions`,
        params: {
          chat_message_id: message.id,
          category: emoji,
        },
        notify: false,
      })

      const result = await apiGet({
        url: `/chat_messages/${message.id}`,
        notify: false,
      })

      // update messages with the new message
      const updatedMessages = processCurrentMessage(result.data?.data, message.id, messages, timezone)
      setMessages(updatedMessages)
    } catch (error) {
      console.error(error)
    }
  }

  const removeReaction = async ({ message, reactionID }: any) => {
    if (!message) return

    try {
      await apiDelete({
        url: `/chat_reactions/${reactionID}`,
        notify: false,
      })

      const result = await apiGet({
        url: `/chat_messages/${message.id}`,
        notify: false,
      })

      const updatedMessages = processCurrentMessage(result.data?.data, message.id, messages, timezone)
      setMessages(updatedMessages)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ChatChannelContext.Provider
      value={{
        toggleMessageStatus: toggleMessageStatus,
        addReaction: addReaction,
        removeReaction: removeReaction,
      }}
    >
      <div css={styles.root}>
        <div css={styles.main}>
          {header && <div css={styles.header}>{header}</div>}
          {subheader && <div css={styles.header}>{subheader}</div>}

          {loading || isEmpty ? (
            <State
              icon={icon}
              title={`${channel?.name} Channel`}
              isLoading={loading}
              isEmpty={isEmpty}
              emptyDescription="This channel doesn't have any conversations yet"
            />
          ) : (
            <Messages
              timezone={timezone}
              messages={messages}
              triggerMoreMessages={onLoadMoreMessages}
              page={pagination}
              meta={meta}
              loading={paginationLoading}
              permissions={permissions}
            />
          )}
        </div>

        <MessageInput isLoading={sending} onSubmit={onSubmittedMessage} />
      </div>
    </ChatChannelContext.Provider>
  )
}

const styles: any = {
  root: {
    '@media (min-width: 600px)': {
      display: 'grid',
      gridTemplateRows: '1fr auto',
      overflow: 'hidden',
    },
  },

  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    overflow: 'hidden',
    marginBottom: 140,
    borderRadius: 0,

    '@media (min-width: 600px)': {
      marginBottom: 0,
    },
  },

  header: {
    flex: '0 0 auto',
    padding: '0.5rem',
    background: transparentize(0.2, COLORS.white),
    borderBottom: `1px solid ${COLORS.divider}`,
  },
}

export default ChannelContent
