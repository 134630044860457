import { useEffect } from 'react'

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (Array.isArray(ref)) {
        // console.log('Array.isArray(ref)')

        for (const r of ref) {
          // console.log('r.current', r.current)

          if (!r.current || r.current.contains(event.target)) {
            // console.log('outside click')
            return
          }
        }
      }

      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
