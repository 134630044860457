import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import get from 'lodash/get'
import size from 'lodash/size'

import { css, COLORS, INPUT_STYLES } from '../../theme'
import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { useCreate } from '../../hooks/useNewAPI'
import { withFormContext } from '../../components/Forms/context'

import Field from '../../components/Forms/Field'
import Flex from '../../components/Flex'
import Button from '../../components/Button'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import SummonOverlay from '../../components/SummonOverlay'

import { BedSelectorOverlay } from './BedSelectorOverlay'
import { useFormField } from '../Forms/hooks/useFormField'

const BedSelector = (props: any) => {
  const {
    afterChange,
    clientId,
    description,
    form,
    fromDate,
    fromLabel,
    isEditable,
    label = 'Bed',
    labelAfter,
    model,
    quickFinderEnabled,
    setFromDate,
    setUntilDate,
    untilDate,
    untilLabel,
    validations,
    testKey,
  } = props

  const { formActions, formState } = useFormField({
    model: model,
    form: form,
    isRelation: true,
    isPolymorphic: true,
    validations: validations,
    afterChange: afterChange,
  })

  const [data, setData]: any = React.useState(form?.getInitialInputFieldValue(model))
  const isEmpty = size(data) === 0

  const { mutateAsync, isLoading: isLoadingBeds }: any = useCreate({
    name: ['beds-finder'],
    url: `/beds/find`,
  })

  const canUseQuickFinder = !!(quickFinderEnabled && clientId && fromDate)

  const findBeds = async (event) => {
    event.stopPropagation()

    if (!canUseQuickFinder) return

    try {
      const result = await mutateAsync({
        resident_id: clientId,
        started_at: fromDate,
        ended_at: untilDate,
        limit: 1,
        random: true,
      })

      if (size(result.data) > 0) {
        setData(result.data[0])
      }
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    formActions.setValue(data)
  }, [data?.id])

  const inputClasses = clsx(STYLES.input().className, { 'is-empty': isEmpty })

  return (
    <Field
      testKey={testKey}
      label={label}
      description={description}
      labelAfter={labelAfter}
      isEditable={isEditable}
      isRequired={formState.isRequired}
      isValid={formState.isValid}
    >
      {!isEditable && (
        <>
          {isEmpty && DEFAULT_EMPTY_VALUE}

          {data && (
            <Flex centerY gap="0.4rem">
              <Item label={data.house?.name} icon="locations" />
              <Item label={data.floor?.name} icon="floors" />
              <Item label={data.room?.name} icon="rooms" />
              <Item label={data?.name} icon="beds" />
            </Flex>
          )}
        </>
      )}

      {isEditable && (
        <SummonOverlay
          overlay={
            <BedSelectorOverlay
              data={data}
              setData={setData}
              fromDate={fromDate}
              setFromDate={setFromDate}
              untilDate={untilDate}
              setUntilDate={setUntilDate}
              fromLabel={fromLabel}
              untilLabel={untilLabel}
            />
          }
        >
          <div className={inputClasses}>
            <div className={STYLES.inputContent()}>
              {isEmpty && (
                <>
                  <div className={STYLES.emptyGraphic()}>
                    <Icon icon="beds" size={20} />
                  </div>

                  <div className={STYLES.emptyPlaceholder()}>Select Bed…</div>
                  <Glyph glyph="selector" size={20} className={STYLES.selectorGlyph()} />
                </>
              )}

              {data && (
                <Flex centerY gap="0.4rem">
                  <Item label={data.house?.name} icon="locations" />
                  <Item label={data.floor?.name} icon="floors" />
                  <Item label={data.room?.name} icon="rooms" />
                  <Item label={data?.name} icon="beds" />
                </Flex>
              )}
            </div>

            {canUseQuickFinder && (
              <div className={STYLES.inputAction()}>
                <Button
                  label="Quick Find"
                  glyph="quick_view"
                  onClick={findBeds}
                  isLoading={isLoadingBeds}
                  size={100}
                  type="link"
                  flex="1 1 auto"
                />
              </div>
            )}
          </div>
        </SummonOverlay>
      )}
    </Field>
  )
}

const Item = ({ label, icon, testKey }: any) => {
  return (
    <div className={STYLES.item()}>
      <div className={STYLES.itemGraphic()}>{icon && <Icon icon={icon} size={20} />}</div>
      <div>{label}</div>
      <Glyph glyph="chevron" size={12} className="item-chevron" color={COLORS.textMuted} />
    </div>
  )
}

const STYLES = {
  input: css({
    ...INPUT_STYLES,
    cursor: 'pointer',

    '@media (min-width: 600px)': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },

    '&.is-empty': {
      paddingLeft: 0,
      flexWrap: 'nowrap',
    },
  }),

  inputContent: css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexWrap: 'wrap',
    flex: '1 1 auto',
  }),

  inputAction: css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexWrap: 'nowrap',
    flex: '1 1 auto',
    marginTop: '0.5rem',
    paddingTop: '0.5rem',
    borderTop: `1px solid ${COLORS.divider}`,

    '@media (min-width: 600px)': {
      flex: '0 0 auto',
      borderLeft: `1px solid ${COLORS.divider}`,
      marginTop: '0',
      paddingTop: '0',
      marginLeft: '0.5rem',
      borderTop: 'none',
    },
  }),

  emptyGraphic: css({
    width: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),

  emptyPlaceholder: css({
    color: COLORS.textMuted,
    opacity: 0.6,
    fontStyle: 'italic',
  }),

  selectorGlyph: css({
    marginLeft: 'auto',
  }),

  item: css({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',

    '.item-chevron': {
      margin: '0 0.4rem',
      opacity: 0.6,
    },

    '&:last-child .item-chevron': {
      display: 'none',
    },
  }),

  itemGraphic: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    marginRight: '0.4rem',
  }),
}

export default withFormContext(BedSelector)
