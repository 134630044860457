import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { Tenant } from '@behavehealth/declarations'

import { PageHeader, Button, SummonOverlay, Tab, TabList, Tabs, HelpTagIframe, Permission } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { BedsBoardOverlay } from '@behavehealth/constructs/BedManagement/BedsBoardOverlay'
import { NewApplicantOverlay } from '@behavehealth/constructs/Clients/NewApplicantOverlay'

import { ClearSampleDataBanner } from '../../components/ClearSampleDataBanner'

import Applicants from './applicants'
import Intake from './intake'
import Declined from './declined'

const Admissions: React.FC = () => {
  const history = useHistory()
  const tenant: Tenant = useSelector((state) => state.me.tenant)

  return (
    <div css={STYLES.root}>
      <PageHeader
        showFeedbackTool
        title="Admissions"
        icon="admissions"
        help={<HelpTagIframe id="admissions" />}
        aside={
          <>
            <Permission showForCommunity permission="bed_management.view" featureFlagV2="bed_management">
              <SummonOverlay overlay={<BedsBoardOverlay />}>
                <Button type="minimal" label="Beds Board" icon="bed_management_board" />
              </SummonOverlay>
            </Permission>

            <SummonOverlay overlay={<NewApplicantOverlay type="summon" dataID="new" history={history} />} flex="1 1 auto">
              <Button
                type="primary"
                label="+ Add Applicant"
                permission="applications.create"
                flex="1 1 auto"
              />
            </SummonOverlay>
          </>
        }
      />

      <div>
        <Tabs>
          <TabList css={STYLES.tabList}>
            <Tab label="Applicants" to="applicants" />
            <Tab label="Accepted" to="intake" />
            <Tab label="Declined" to="declined" />
          </TabList>
        </Tabs>

        {tenant?.has_seed_data && <ClearSampleDataBanner isFullWidth />}
      </div>

      <Switch>
        <Route exact path={`/admissions/applicants`} component={Applicants} />
        <Route exact path={`/admissions/intake`} component={Intake} />
        <Route exact path={`/admissions/declined`} component={Declined} />

        <Redirect exact from="/admissions" to="/admissions/applicants" />
      </Switch>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: 'min-content min-content minmax(100px, 1fr)',
    overflow: 'hidden',
  },

  tabList: {
    paddingLeft: '1rem',
  },
}

export default withPageError(Admissions)
