import React from 'react'
import Fuse from 'fuse.js'
import produce from 'immer'
import size from 'lodash/size'

import Glyph from '../../../components/Glyph'

import { Filter } from '../common/Filter'
import { FilterCondition } from '../common/FilterCondition'
import { FilterDropdown } from '../common/FilterDropdown'
import { FilterDropdownItem } from '../common/FilterDropdownItem'
import { FilterDropdownSearch } from '../common/FilterDropdownSearch'
import { FilterDropdownValue } from '../common/FilterDropdownValue'
import { FilterLabel } from '../common/FilterLabel'
import { FilterSegment } from '../common/FilterSegment'

export const MultiSelectFilter = (props: any) => {
  const { config, onUpdate, filter, onClear } = props

  const [value, setValue]: any = React.useState(filter?.value || [])
  const [searchTerm, setSearchTerm]: any = React.useState('')

  const optionsMap = React.useMemo(() => {
    const result = {}

    if (size(config?.options) === 0) return result

    for (const option of config.options) {
      result[option.value] = option
    }

    return result
  }, [config?.options])

  const fuse = React.useMemo(() => {
    return new Fuse(config?.options, { keys: ['label'] })
  }, [config?.options, config?.searchKeys])

  const searchData = React.useMemo(() => {
    if (!searchTerm) return config?.options

    let searchResults: any[] = []
    let fuseResults = fuse.search(searchTerm)

    for (let i = 0; i < fuseResults.length; i++) {
      searchResults.push(fuseResults[i].item)
    }

    return searchResults
  }, [fuse, searchTerm])

  const clearSearch = () => {
    setSearchTerm('')
  }

  const handleUpdate = () => {
    onUpdate?.({ condition: 'in', value })
  }

  if (!config || size(config?.options) === 0) return null

  const { label, glyph, renderGraphic } = config

  const filtersCount = size(filter?.value)
  const hasActiveFilters = filtersCount >= 1
  const isSearchEmpty = size(searchData) === 0

  return (
    <Filter onClear={onClear}>
      <FilterLabel label={label} glyph={glyph} />

      <FilterCondition condition={filtersCount >= 2 ? 'is either of' : 'is'} />

      <FilterSegment>
        <FilterDropdown
          onClose={handleUpdate}
          isSearchEmpty={isSearchEmpty}
          activeValues={
            hasActiveFilters &&
            filter.value.map((filterValue: any) => (
              <FilterDropdownValue key={filterValue} graphic={<Glyph glyph="check" size={12} />} value={optionsMap[filterValue]?.label} />
            ))
          }
        >
          <FilterDropdownSearch value={searchTerm} onChange={setSearchTerm} onClear={clearSearch} />

          {searchData.map((option: any) => (
            <FilterDropdownItem
              key={option.value}
              title={option.label}
              isActive={value.includes(option.value)}
              graphic={renderGraphic?.(option)}
              onClick={() => {
                setValue((currentValue: any = {}) => {
                  return produce(currentValue, (draft: any) => {
                    if (draft.includes(option.value)) {
                      const index = draft.indexOf(option.value)
                      draft.splice(index, 1)
                      return
                    }

                    draft.push(option.value)
                  })
                })
              }}
            />
          ))}
        </FilterDropdown>
      </FilterSegment>
    </Filter>
  )
}
