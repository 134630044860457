import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'

import { COLORS, INPUT_STYLES, INPUT_FOCUS_STYLES, FONT_FAMILY } from '../../theme'
import { Alert, Label } from '../'

const StripeCardElement = ({ valid, setValid, error, setError }: any) => {
  const model = 'stripe_card_element'

  const onChange = (event) => {
    if (setValid) setValid(event.complete)
    if (!setError) return

    event.error ? setError(event.error.message) : setError('')
  }

  return (
    <div>
      <Label label="Card Details" htmlFor={model} isValid={valid} isRequired />
      <CardElement
        id={'card-element-' + model}
        onChange={onChange}
        css={styles}
        options={{
          classes: {
            focus: 'focus',
          },
          style: {
            base: {
              fontSize: '15px',
            },
          },
        }}
      />
      {error && (
        <Alert type="negative" glyph="circle_error" className="!mt-4">
          {error}
        </Alert>
      )}
    </div>
  )
}

const styles = {
  ...INPUT_STYLES,
  minHeight: 'none',
  padding: '0.75em',

  color: COLORS.text,
  fontFamily: FONT_FAMILY,
  fontVariant: 'tabular-nums',
  fontFeatureSettings: 'tnum',

  '::placeholder': {
    color: '#aab7c4',
  },

  '&.focus': {
    ...INPUT_FOCUS_STYLES,
  },
}

export default StripeCardElement
