import React from 'react'
import { useLocation } from 'react-router-dom'

import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'
import { apiUpdate } from '../../modules/api'

import TaskCardLink from '../TaskCardLink'

type TaskProps = {
  hideClients?: boolean
  location: any
  task: any
  timezone: any
  to: any
  toggleTaskStatus: any
}

const Task = ({ hideClients, task, timezone, to }: TaskProps) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })
  const [loading, setLoading] = React.useState(false)
  const [status, setStatus] = React.useState(task?.display_status)

  const toggleTask = async () => {
    if (loading) return

    setLoading(true)

    const status = task.status === 'completed' ? 'due' : 'completed'
    try {
      const response = await apiUpdate({
        name: 'tasks',
        url: `/events/${task.id}`,
        params: { status: status },
      })
      setStatus(response.data.data.display_status)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <TaskCardLink
      isActive={!!isActive}
      isCompleted={status === 'completed'}
      loading={loading}
      status={status}
      task={task}
      timezone={timezone}
      to={to}
      toggleTask={toggleTask}
      hideClients={hideClients}
    />
  )
}

export default withSettings(Task)
