import React from 'react'
import { useMedia } from 'use-media'
import produce from 'immer'
import size from 'lodash/size'

import { getPrefix, useUpdate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import withSettings from '../../hocs/withSettings'

import EmployeeQuickView from '../Overlays/quick/EmployeeQuickView'
import Glyph from '../Glyph'
import Flex from '../Flex'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import { ROLES, STAFF_ROLES } from '../../utils/constants'
import SummonOverlay from '../SummonOverlay'
import Permission from '../Permission'
import { StaffPermissionsOverlay } from '../../constructs/Staff/StaffPermissionsOverlay'

const mainColumn = (to: any) => ({
  width: 240,
  isSticky: true,
  canToggleVisible: false,
  accessor: 'name',
  Header: 'Name',
  Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} avatar={row.original.avatar} />,
})

const tagsColumn = (apiKey: any) => ({
  width: 200,
  Header: 'Tags',
  accessor: 'tags',
  disableFilters: true,
  Cell: ({ value, row }: any) => {
    const { mutateAsync, isLoading }: any = useUpdate({
      name: ['employee', row.original.id],
      url: `/employees/${row.original.id}`,
      invalidateKeys: ['employees', 'tag-live-reports'],
      onSuccess: (_data: any, variables: any, queryClient: any) => {
        const prefix = getPrefix()

        queryClient.setQueryData([prefix, apiKey].flat(), (oldData: any) => {
          if (!oldData?.data) return

          const index = oldData.data.findIndex((o) => o.id === row.original.id)
          if (index === -1) return

          const newData = produce(oldData, (draft: any) => {
            draft.data[index].tags = variables.tags
          })

          return newData
        })
      },
    })

    return (
      <TableCell.Tags
        value={value}
        save={(tagIDs: any, tags: any) => mutateAsync({ tag_ids: tagIDs, tags })}
        isLoading={isLoading}
        smartCategories="employees"
      />
    )
  },
})

const logRocketColumn = (subdomain: string) => ({
  width: 140,
  id: 'log_rocket',
  accessor: 'email',
  Header: 'Sessions',
  Cell: ({ value }: any) => (
    <TableCell.Link
      href={`https://logrocket.behavehealth.com/behavehealth/ehr-new/?filters=[{"type":"userTrait","operator":{"name":"is","type":"IS","hasStrings":true,"autocompleteEnabled":true},"strings":["tenant:${subdomain}"]},{"type":"email","operator":{"name":"is","type":"IS","hasStrings":true,"autocompleteEnabled":true},"strings":["${value}"]}]`}
      label={'Open'}
      glyph={<Glyph glyph="link" />}
      isExternal
    />
  ),
})

const columns = (
  to: Function = () => {},
  isPortal: boolean,
  isBehave: boolean,
  isDesktop: boolean,
  timezone: string,
  subdomain: string,
  apiKey: any,
  staffPermissionsId: string,
  setStaffPermissionsId: any,
) => [
  ...(isDesktop || isPortal ? [mainColumn(to)] : []),
  ...(isBehave ? [logRocketColumn(subdomain)] : []),

  {
    width: isDesktop ? 140 : 240,
    disableFilters: true,
    disableSortBy: true,
    id: 'quick_view',
    accessor: 'id',
    Header: 'Quick View',
    Cell: ({ row }: any) => (
      <TableCell.QuickViewOverlay overlay={<EmployeeQuickView employee={row.original} />}>
        {!isDesktop && <TableCell.MainLink label={row.original.name} avatar={row.original.avatar} glyphAfter="quick_view" />}
      </TableCell.QuickViewOverlay>
    ),
  },
  {
    disableFilters: true,
    disableSortBy: true,
    id: '_permissions',
    accessor: '_permissions',
    Header: 'Permissions',
    Cell: ({ row }: any) => (
      <Permission permission="employees.permissions.view">
        <TableCell.MainLink
          label="View Permissions"
          graphic={<Glyph glyph="lock" size={12} className="mr-1.5" />}
          onClick={() => setStaffPermissionsId(row.original.id)}
          className={staffPermissionsId === row.original.id ? 'is-active' : ''}
        />
      </Permission>
    ),
  },
  ...(apiKey ? [tagsColumn(apiKey)] : []),
  {
    width: 160,
    accessor: 'title',
    Header: 'Title',
  },
  {
    width: 160,
    disableFilters: true,
    disableSortBy: true,
    accessor: 'houses',
    Header: 'Assigned Locations',
    Cell: ({ row, value }: any) => {
      if (size(value) === 0) return <TableCell.Empty />

      return (
        <Flex centerY gap="0.5rem">
          {value.map((property: any) => (
            <TableCell.Profile key={property.id} name={property.name} avatar={property.avatar} />
          ))}
        </Flex>
      )
    },
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 160,
    accessor: 'email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
  {
    width: 180,
    accessor: 'is_shared_with_portal',
    Header: 'Client Portal Sharing',
    Cell: ({ value }: any) => {
      if (!value) return 'Not shared'
      return <TableCell.GlyphValue glyph="portal" value="Shared" />
    },
    Filter: TableFilter.Select,
    filter: 'boolean',
    filterOptions: [
      {
        value: true,
        label: 'Shared',
      },
      {
        value: false,
        label: 'Not shared',
      },
    ],
  },
  {
    width: 120,
    accessor: 'is_blocked',
    Header: 'Is Blocked',
    Cell: ({ value }: any) => (value ? 'Yes' : 'No'),
    Filter: TableFilter.Select,
    filter: 'boolean',
    filterOptions: [
      {
        value: true,
        label: 'Blocked',
      },
      {
        value: false,
        label: 'Not blocked',
      },
    ],
  },
  {
    width: 140,
    accessor: 'position',
    Header: 'Role',
    Cell: ({ value }: any) => ROLES[value] || '–',
  },
  {
    width: 140,
    accessor: 'behave_user_role',
    Header: 'User Type',
    Cell: ({ value }: any) => STAFF_ROLES[value] || '–',
  },
  {
    width: 180,
    accessor: 'is_clinical_supervisor',
    Header: 'Is Supervisor',
    Cell: ({ value }: any) => <TableCell.TrueFalse isTrue={value} />,
    Filter: TableFilter.Select,
    filter: 'boolean',
    filterOptions: [
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No',
      },
    ],
  },
  {
    width: 180,
    accessor: 'is_healthcare_provider',
    Header: 'Is Healthcare Provider',
    Cell: ({ value }: any) => <TableCell.TrueFalse isTrue={value} />,
    Filter: TableFilter.Select,
    filter: 'boolean',
    filterOptions: [
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No',
      },
    ],
  },
  {
    width: 190,
    accessor: 'last_logged_in_at',
    Header: 'Last Logged in At',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 140,
    accessor: 'last_name',
    Header: 'Last Name',
    Cell: ({ value }: any) => <TableCell value={value} />,
  },
]

const EmployeesTable = (props: any) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    batchActionsConfig,
    title,
    to,
    timezone,
    user,
    tenant,
    hiddenColumns,
    titleAfter,
    apiKey,
    testKey,
    ...rest
  } = props

  const { isPortal } = useSettings()
  const isDesktop = useMedia({ minWidth: 600 })
  const isBehave = user?.type === 'bh_employee'
  const subdomain = tenant?.subdomain

  const [staffPermissionsId, setStaffPermissionsId] = React.useState(null)

  return (
    <>
      <Table
        testKey={testKey}
        title={title}
        titleAfter={titleAfter}
        icon={icon}
        data={data}
        columns={columns(to, isPortal, isBehave, isDesktop, timezone, subdomain, apiKey, staffPermissionsId, setStaffPermissionsId)}
        isLoading={isLoading}
        emptyActions={emptyActions}
        emptyDescription={emptyDescription}
        batchActionsConfig={batchActionsConfig}
        hiddenColumns={hiddenColumns}
        localStorageKey={localStorageKey}
        {...rest}
      />

      <Permission permission="employees.permissions.view">
        <SummonOverlay
          key={`id-${staffPermissionsId}`}
          isOpen={!!staffPermissionsId}
          onClose={() => setStaffPermissionsId(null)}
          overlay={<StaffPermissionsOverlay key={`id-${staffPermissionsId}`} dataID={staffPermissionsId} />}
        />
      </Permission>
    </>
  )
}

EmployeesTable.defaultProps = {
  title: 'Staff',
  icon: 'employees',
  emptyDescription: 'No staff added yet',
  localStorageKey: 'employees',
}

export default withSettings(EmployeesTable)
