import React from 'react'
import { useParams } from 'react-router-dom'

import { titleCase } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Alert from '@behavehealth/components/Alert'
import Avatar from '@behavehealth/components/Avatar'
import Card from '@behavehealth/components/Card'
import Page from '@behavehealth/components/Page'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import State from '@behavehealth/components/State'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const RootStaff = () => {
  const params: any = useParams()
  const { resource_id: id } = params

  const { data: partnership, isLoading, isError, error }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  const isShared = partnership?.other_partner_settings?.employees === true

  const tableProps: any = useDataTable({
    name: ['organization', id, 'other-partner-employees'],
    endpoint: `/organizations/${id}/other_partner_employees`,
    options: { enabled: isShared },
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ value }: any) => (
          <div className="flex flex-nowrap items-center font-[600]">
            <Avatar initials={value} src="" size={22} className="mr-1.5" />
            {value}
          </div>
        ),
      },
      {
        width: 70,
        id: 'title',
        model: 'title',
        title: 'Title',
      },
      {
        width: 160,
        id: 'position',
        model: 'position',
        title: 'Role',
        formatValue: ({ data, value }: any) => titleCase(value),
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'phone_no',
        title: 'Phone',
        type: 'phone',
      },
      {
        width: 160,
        id: 'email',
        model: 'email',
        title: 'Email',
        type: 'email',
      },
    ],
    [],
  )

  if (!partnership || isLoading) return <PageLoader />

  const partner = partnership.other_partner

  const infoAlert = (
    <Alert small contrast glyph="info" className="mb-4">
      <span className="font-[600]">Please note: </span> staff members shared here are from the partner's EHR account, not their public
      Community Profile.
    </Alert>
  )

  if (!isShared) {
    return (
      <Page icon="employees" title="Shared Staff">
        {infoAlert}

        <Card>
          <State isEmpty icon="employees" title="Staff" emptyDescription={`${partner?.name || '–'} has not shared any staff members`} />
        </Card>
      </Page>
    )
  }

  return (
    <Page title="Shared Staff" icon="employees">
      {infoAlert}
      <DataTable {...tableProps} icon="employees" title="Staff Members" columns={columns} />
    </Page>
  )
}

export const Staff = withPageError(RootStaff)
