import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
}

const RosterStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default RosterStatus
