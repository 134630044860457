import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { MEDICAL_ASSESSMENTS_SLUGS } from '@behavehealth/utils/constants'

import { Page, Grid } from '@behavehealth/components'

import { MedicalAssessmentsReportDataTable } from '@behavehealth/constructs/LiveReports/MedicalAssessmentsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const MedicalAssessments = () => {
  const tableProps = useDataTable({
    name: ['reports', 'medical-assessments'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_medical_assessments_v1',
    params: { category: 'medical_assessments' },
  })

  const to = React.useMemo(
    () => (rowData: any) =>
      `${getResourceLink(rowData.client)}/medical-assessments/${MEDICAL_ASSESSMENTS_SLUGS?.[rowData?.subcategory]}/${rowData.id}`,
    [],
  )

  return (
    <Page feature="medical_assessments" title="Live Medical Assessments Report">
      <Grid>
        <MedicalAssessmentsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(MedicalAssessments)
