import React from 'react'

import { COLORS } from '../../theme'

import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import DataList from '../../components/DataList'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import TreeItem from '../../components/TreeItem'
import { VisualHide } from '../../components/VisualHide'

export const ProgramAssignmentInfo = ({ data, showCurrentDetails, toggleCurrentDetails }: any) => {
  const { timezone } = useSettings()

  if (!data?.place) return null

  const { place: seat = {} } = data
  const { program, phase } = seat

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item
        label="Program"
        value={
          <Flex centerY nowrap gap="0.4rem">
            <Icon icon="programs" size={18} />
            <div className="!font-[600]">{program?.name}</div>
          </Flex>
        }
      />

      <DataList.Item
        label="Program List"
        value={
          <Flex nowrap centerY gap="0.3rem">
            <Icon icon="program_lists" size={18} />
            <div>{phase?.name}</div>
          </Flex>
        }
      />

      <TreeItem title="More Options" isOpen={showCurrentDetails} onClick={toggleCurrentDetails} />

      <VisualHide when={!showCurrentDetails}>
        <DataList.Item
          label="List Seat"
          value={
            <Flex nowrap centerY gap="0.3rem">
              <Glyph glyph="chair" size={16} color={COLORS.text} />
              <div>{seat?.name}</div>
            </Flex>
          }
        />

        <DataList.Item label="Occupied From" value={usDateTime(data.started_at, timezone)} />

        <DataList.Item
          label="Occupied Until"
          value={data.period_type === 'indefinite' ? 'Discharged' : usDateTime(data.ended_at, timezone)}
        />
      </VisualHide>
    </DataList>
  )
}
