import React from 'react'
import { v4 as uuid } from 'uuid'
import { darken, transparentize, tint } from 'polished'

import { withFormContext } from './context'
import { isDefined } from '../../utils/functions'

import Flex from '../Flex'
import Glyph from '../Glyph'
import FieldBase from './FieldBase'
import { COLORS, SHADOW } from '../../theme'

export class PillBox extends FieldBase {
  constructor(props) {
    super(props)

    const valueDefined = isDefined(props.value)
    let value = valueDefined ? props.value : null
    if (!valueDefined && props.form) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)
      value = isDefined(modelVal) ? modelVal : isDefined(initialModelVal) ? initialModelVal : props.defaultValue
    }

    this.state = {
      id: `${props.model}-${uuid()}`,
      model: props.model,
      label: props.label,
      value: value || false,
      isNested: props.isNested || false,
      isDisabled: props.isDisabled || false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValid: true,
      isInvalid: false,
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value || false,
      isValid: true,
      isInvalid: false,
    }
    this.updateType = 'DATA'
  }

  /*
    RENDER
  */
  render() {
    const { description, label, glyph } = this.props
    const { value } = this.state

    return (
      <div css={styles} className={value ? 'is-active' : ''} onClick={() => this.changeValue(!this.state.value)}>
        <Flex vertical centerX centerY gap={4}>
          {glyph && <Glyph glyph={glyph} color={value ? COLORS.green : COLORS.paleBlue} size={24} />}
          <span>{label}</span>
          <span>{description}</span>
        </Flex>
      </div>
    )
  }
}

const styles = {
  flex: '1 1 auto',
  flexBasis: 0,
  borderRadius: 7,
  background: 'white',
  boxShadow: `inset 0 0 0 0.5px ${COLORS.divider}, ${SHADOW(1)}`,
  overflow: 'hidden',
  padding: '0.75em',
  justifyContent: 'center',

  '&:hover': {
    boxShadow: SHADOW(4),
    transform: 'translateY(-1px)',
    cursor: 'pointer',
  },

  '&.is-active': {
    background: tint(0.96, COLORS.green),
    borderColor: `${transparentize(0.5, COLORS.green)} !important`,
    boxShadow: `
      inset 0 0 0 1px ${darken(0.02, COLORS.green)},
      ${SHADOW(2, transparentize(0.9, COLORS.green))}
    `,

    '&:hover': {
      background: tint(0.92, COLORS.green),
    },
  },
}

export default withFormContext(PillBox)
