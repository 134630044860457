import React from 'react'
import 'regenerator-runtime/runtime'
import { motion } from 'framer-motion'
import { tint } from 'polished'

import { useDictation as useTranscription } from '../../hooks/useDictation'

import { COLORS, INPUT_STYLES } from '../../theme'

import Button from '../Button'
import Divider from '../Divider'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Markup from '../Markup'
import Permission from '../Permission'
import Tooltip from '../Tooltip'

import { AIActionsMenu } from '../../constructs/AIActions/AIActionsMenu'
import { QuickTextSelector } from '../../constructs/QuickText/QuickTextSelector'
import { useSettings } from '../../hooks/useSettings'

import { withFormContext } from './context'
import RichTextEditor from './RichTextEditor'

const animationProps = {
  type: 'spring',
  stiffness: 1200,
  damping: 100,
}

const SmartRichTextEditor = (props: any) => {
  const { isPortal } = useSettings()

  const { isEditable, useQuickText, useDictation, useAIActions = true } = props

  const [editor, setEditor]: any = React.useState(null)

  const { canStartDictation, didStartDictation, isButtonDisabled, isProcessing, toggleListening, transcript } = useTranscription({
    onComplete: (finalTranscript: any) => {
      if (!finalTranscript) return

      editor.commands.focus('end')
      editor.commands.insertContent(`<p>${finalTranscript}</p>`)
    },
  })

  // AI Actions
  const [aiAction, setAIAction]: any = React.useState(null)
  const [aiResult, setAIResult]: any = React.useState('')
  const [aiError, setAIError]: any = React.useState('')

  const showAIResult = !!aiAction || !!aiResult || !!aiError

  const clearAIResult = () => {
    setAIAction(null)
    setAIResult('')
    setAIError('')
  }

  const handleAIResultInsert = () => {
    if (!aiResult) return

    editor?.commands.focus('end')
    editor?.commands.insertContent(aiResult)
    clearAIResult()
  }

  const handleAIResultReplace = () => {
    if (!aiResult) return

    editor?.commands.clearContent()
    editor?.commands.insertContent(aiResult)

    clearAIResult()
  }

  return (
    <div css={STYLES.root} className={didStartDictation || showAIResult ? 'squared-bottom' : ''}>
      <RichTextEditor {...props} useQuickText={false} getEditor={setEditor} />

      {isEditable && !isPortal && (
        <>
          <motion.div
            className={!!transcript ? 'has-transcript' : ''}
            initial={false}
            animate={{ height: didStartDictation ? 'auto' : 0, opacity: didStartDictation ? 1 : 0 }}
            style={{ overflow: 'hidden' }}
            transition={{
              height: animationProps,
              opacity: animationProps,
            }}
          >
            <div css={STYLES.transcript}>
              <Glyph glyph="microphone" color={COLORS.blue} size={20} />
              <div>{transcript ? `"${transcript}"` : 'Listening…'}</div>
            </div>
          </motion.div>

          <motion.div
            initial={false}
            animate={{ height: showAIResult ? 'auto' : 0, opacity: showAIResult ? 1 : 0 }}
            style={{ overflow: 'hidden' }}
            transition={{
              height: animationProps,
              opacity: animationProps,
            }}
          >
            {aiAction && (
              <div
                css={STYLES.aiResult}
                style={{ '--ai-result-background': tint(0.9, aiError ? COLORS.red : aiAction.color || COLORS.blue) } as any}
              >
                <div className="flex-auto">
                  <Flex centerY nowrap>
                    <div className="w-6 mr-1">
                      <Glyph glyph={aiError ? 'circle_error' : aiAction.glyph} color={aiError ? COLORS.red : aiAction.color} size={20} />
                    </div>

                    <div className="font-bold">{aiAction.name}</div>
                  </Flex>

                  {(aiResult || aiError) && (
                    <div className="pl-6 ml-1">
                      {aiResult ? (
                        <div className="mt-4">
                          <Markup value={aiResult} />
                        </div>
                      ) : aiError ? (
                        <div className="italic">{aiError}</div>
                      ) : null}

                      {aiResult && (
                        <div className="mt-2">
                          <Flex gap="0.5rem">
                            <Button
                              label="Insert Below"
                              size={100}
                              color="text"
                              glyph="insert_below"
                              display="inline-flex"
                              onClick={handleAIResultInsert}
                            />
                            <Button
                              label="Replace Text"
                              size={100}
                              color="text"
                              glyph="replace"
                              display="inline-flex"
                              onClick={handleAIResultReplace}
                            />
                          </Flex>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <button type="button" onClick={clearAIResult} css={STYLES.clearButton}>
                  <Glyph glyph="circle_error" color={COLORS.text} size={14} />
                </button>
              </div>
            )}
          </motion.div>

          <div css={STYLES.actions}>
            <Flex centerY gap="0.5rem" justifyContent="flex-start">
              {(useQuickText || useDictation) && (
                <>
                  {useQuickText && !isPortal && (
                    <Permission featureFlagV2="quick_text">
                      <QuickTextSelector
                        onSelect={(text: any) => {
                          if (!editor || !text?.rich) return

                          editor.commands.insertContent(text.rich)
                        }}
                      />
                    </Permission>
                  )}

                  {useDictation && canStartDictation && (
                    <Permission featureFlagV2="dictation">
                      <Flex nowrap>
                        <Button
                          label={didStartDictation ? 'Stop Recording' : 'Speech to Text'}
                          color={didStartDictation ? 'red' : 'green'}
                          type={didStartDictation ? 'primary' : 'default'}
                          icon={didStartDictation ? 'microphone_recording' : 'microphone'}
                          display="inline-flex"
                          size={100}
                          onClick={toggleListening}
                          isDisabled={isButtonDisabled}
                          isLoading={isProcessing}
                        />

                        <Tooltip
                          color={COLORS.green}
                          className="ml-1"
                          content={
                            <div>
                              <div>
                                Private Beta Test – BEHAVE+ only,
                                <br />
                                <a href="https://behavehealth.com/vip" target="_blank">
                                  click to learn more
                                </a>
                              </div>

                              <Divider className="!my-2" />

                              <div className="italic font-small">
                                Behave Health Corp. uses Microsoft Speaker Recognition technology to process Behave Health Corp.'s users'
                                biometric data as its service provider (“Processor”). Microsoft may process and store audio and voice
                                signature templates for the purposes of providing speaker verification and/or identification services on
                                Behave Health Corp.'s behalf, and only as instructed by Behave Health Corp.. Microsoft will store this data
                                as long as Behave Health Corp. requests, which shall be no longer than a limited grace period after the date
                                when (i) Behave Health Corp. ceases to have a relationship with Microsoft or (ii) when Behave Health Corp.
                                requests deletion.
                              </div>
                            </div>
                          }
                        >
                          <Glyph glyph="info" size={12} color={COLORS.green} />
                        </Tooltip>
                      </Flex>
                    </Permission>
                  )}
                </>
              )}

              {useAIActions && (
                <Permission featureFlagV2="behave_ai">
                  <AIActionsMenu
                    getInput={() => editor.getHTML()}
                    onStartAction={({ action }: any) => {
                      setAIAction(action)
                      setAIResult('')
                      setAIError('')
                    }}
                    onCompleteAction={({ action, result }: any) => {
                      if (!result) {
                        setAIError('The AI system could not perform this action')
                        return
                      }

                      setAIAction(action)
                      setAIResult(result)
                    }}
                  />
                </Permission>
              )}
            </Flex>
          </div>
        </>
      )}
    </div>
  )
}

const STYLES = {
  root: {
    maxWidth: 'var(--field-max-width)',

    '&.squared-bottom': {
      '.RichTextEditor': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },

  transcript: {
    ...INPUT_STYLES,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    position: 'relative',
    background: tint(0.94, COLORS.blue),
    fontWeight: 500,
    top: -1,
    minHeight: 'auto',

    display: 'flex',
    alignItems: 'center',
    padding: '0.4rem',
    fontSize: '0.94rem',
    flexWrap: 'nowrap',
    overflow: 'hidden',

    '& > svg': {
      marginRight: '0.5rem',
    },

    '&.has-transcript': {
      fontWeight: 400,
      fontStyle: 'italic',
    },

    '&:hover': {},
  },

  aiResult: {
    ...INPUT_STYLES,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    position: 'relative',
    background: `var(--ai-result-background)`,
    fontWeight: 500,
    top: -1,
    minHeight: 'auto',
    whiteSpace: 'pre-wrap',

    display: 'flex',
    alignItems: 'center',
    padding: '0.4rem',
    fontSize: '0.94rem',
    flexWrap: 'nowrap',
    overflow: 'hidden',

    '& > svg': {
      marginRight: '0.5rem',
    },

    '&.has-transcript': {
      fontWeight: 400,
      fontStyle: 'italic',
    },

    '&:hover': {},
  },

  actions: {
    marginTop: '0.5rem',
    maxWidth: 'var(--field-max-width)',
  },

  clearButton: {
    background: 'none',
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    outline: 'none',
    width: 24,
    height: 24,
    opacity: 0.8,

    '&:hover': {
      opacity: 1,
      background: COLORS.hover,
      svg: { fill: COLORS.red },
    },
  },
}

export default withFormContext(SmartRichTextEditor)
