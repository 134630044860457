import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../../theme'
import LineChart from '../ChartTypes/LineChart'

export const AllPropertiesOccupancyRateChart = (props: any) => {
  return (
    <LineChart
      option={{
        yAxis: {
          max: 150,
          type: 'value',
          axisLabel: { formatter: '{value}%' },
        },
      }}
      color={COLORS.blue}
      {...props}
    />
  )
}
