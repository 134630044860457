import React from 'react'

import Button from '../Button'
import { StepperContext } from './context'

const StepperContinueButton: React.FC<any> = ({
  onClick,
  isLoading,
  isDisabled,
  label = 'Continue →',
  color = 'blue',
  glyph,
  className,
}) => {
  const { goNext, isLast, isValid }: any = React.useContext(StepperContext)

  if (isLast) return null

  return (
    <Button
      label={label}
      color={color}
      glyph={glyph}
      type="primary"
      onClick={onClick ? onClick : goNext}
      isDisabled={isDisabled || !isValid}
      isLoading={isLoading}
      size={300}
      css={styles}
      className={className}
    />
  )
}

const styles = {
  position: 'sticky',
  bottom: 0,
  zIndex: 1,
}

export default StepperContinueButton
