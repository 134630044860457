import React from 'react'
import startCase from 'lodash/startCase'

import { age } from '../../utils/functions'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import ProgramProfileHeader from '../../components/ProgramProfileHeader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import SeatSelector from '../../components/Forms/SeatSelector'
import Section from '../../components/Section'

const RootProgramAssignmentOverlay = (props: any) => {
  const { position = 'center' } = props

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialData,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'occupancy',
    endpoint: '/occupancies',
    invalidateKeys: ['programs-timeline', 'program-timeline'],
    options: props,
    isEditable: props.isEditable,
  })

  // get program data from API if not passed via router
  // const { data: apiProgram, isLoading: isProgramLoading }: any = useGet({
  //   name: ['program', resource_id],
  //   url: `/programs/${resource_id}`,
  //   options: { enabled: !!resource_id && !initialData?.program },
  // })

  // get program data from router or from API
  // const programData = initialData?.program || apiProgram

  const { timezone } = useSettings()

  const [fromDate, setFromDate] = React.useState(null)
  const [untilDate, setUntilDate] = React.useState(null)

  if (isOverlayLoading) {
    return <OverlayLoader position={position} />
  }

  return (
    <Overlay showBackdrop closeOnBackdrop={!isEditable} position={position} onClose={close}>
      <Overlay.Header
        icon="program_lists"
        title={isNew ? 'Add New Program Assignment' : 'Edit Program Assignment'}
        // description={programData && <ProgramProfileHeader program={programData} />}
      />

      <Overlay.Content>
        <Form
          useFullModel
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={{ status: 'occupied' }}
        >
          {({ data: formData }) => {
            const fromDateLabel = formData.status === 'reserved' ? 'Reserve From' : 'Occupy From'
            const untilDateLabel = formData.status === 'reserved' ? 'Reserve Until' : 'Occupy Until'
            const periodLabel = formData.status === 'reserved' ? 'Reserve Period' : 'Occupy Period'

            return (
              <Section>
                <Grid gap="1rem">
                  <ObjectSelector
                    isPolymorphic
                    isEditable={isNew}
                    label="Client"
                    type="applicants_and_clients"
                    model="reference"
                    icon="clients"
                    selectTitle={(data: any) => data.name}
                    selectDescription={(data: any) => `${startCase(data.sex) || '–'}, ${age(data.dob)} y/o, #${data.behave_id}`}
                    validations={{
                      presence: {
                        message: 'Please select a client',
                      },
                    }}
                  />

                  <DateTimeInput
                    defaultToNow
                    label={fromDateLabel}
                    model="started_at"
                    value={fromDate}
                    onUpdate={({ value }) => {
                      setFromDate(value)
                    }}
                    validations={{
                      presence: {
                        message: 'Please enter a start date',
                      },
                    }}
                  />

                  <RadioGroup label={periodLabel} model="period_type" layout="horizontal-dense" defaultValue="indefinite">
                    <Radio label="Until Discharged" value="indefinite" />
                    <Radio label="Until Date" value="set_datetime" />
                  </RadioGroup>

                  <ContextShow when="period_type" is="set_datetime">
                    <DateTimeInput
                      defaultToTomorrow
                      label={untilDateLabel}
                      model="ended_at"
                      value={untilDate}
                      onUpdate={({ value }: any) => {
                        setUntilDate(value)
                      }}
                      validations={{
                        presence: {
                          message: 'Please enter an end date',
                        },
                      }}
                    />
                  </ContextShow>

                  <SeatSelector
                    isPolymorphic
                    fromDate={fromDate}
                    fromLabel={fromDateLabel}
                    setFromDate={setFromDate}
                    setUntilDate={setUntilDate}
                    untilDate={untilDate}
                    untilLabel={untilDateLabel}
                    label="Seat"
                    model="place"
                    validations={{
                      presence: {
                        message: 'Please select a seat',
                      },
                    }}
                  />
                </Grid>
              </Section>
            )
          }}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete program assignment?"
              message="Are you sure you want to delete this program assignment? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button fullWidth glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ProgramAssignmentOverlay = withOverlayError(RootProgramAssignmentOverlay)
