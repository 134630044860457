import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { RefundsReportDataTable } from '@behavehealth/constructs/LiveReports/RefundsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Refunds = () => {
  const tableProps = useDataTable({
    name: ['reports', 'refunds'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_refunds_v1',
    params: { category: 'refunds' },
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.resident)}/financials/refunds/${rowData.id}`, [])

  return (
    <Page feature="financials" title="Live Refunds Report">
      <Grid>
        <RefundsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Refunds)
