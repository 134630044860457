import React from 'react'
import { DateTime } from 'luxon'
import clsx from 'clsx'

import { css, COLORS } from '../../theme'
import { DT, daysBetween } from '../../utils/functions'
import { useSettings } from '../../hooks'

import { RoadmapContext } from './context'
import State from '../State'
import TimelineHeader from './TimelineHeader'

type Period = 'day' | 'week' | 'month' | 'trimester'

type Props = {
  className?: any
  dates?: any
  defaultPeriod?: Period
  emptyDescription?: any
  icon?: any
  isEmpty?: boolean
  isLoading?: boolean
  isRefetching?: boolean
  onDatesChange?: any
  showTodayLine?: boolean
  title?: any
}

const getDatesList = (period: Period, startDate: any) => {
  let datesList = []

  if (period === 'day') {
    datesList.push(startDate.startOf('day'))
    return datesList
  }

  if (period === 'week') {
    for (let i = 0; i < 7; i++) {
      datesList.push(startDate.startOf('week').plus({ days: i }))
    }
    return datesList
  }

  if (period === 'month') {
    const monthStart = startDate.startOf('month')
    const monthEnd = startDate.endOf('month')
    const daysCount = daysBetween(monthStart, monthEnd)

    for (let i = 0; i < daysCount; i++) {
      datesList.push(monthStart.plus({ days: i }))
    }
  }

  if (period === 'trimester') {
    const monthStart = startDate.startOf('month')
    const monthEnd = startDate.plus({ months: 3 }).endOf('month')
    const daysCount = daysBetween(monthStart, monthEnd)

    for (let i = 0; i < daysCount; i++) {
      datesList.push(monthStart.plus({ days: i }))
    }
  }

  return datesList
}

const getNextDate = (period: Period, currentDate: any) => {
  let nextDate = currentDate

  if (period === 'day') {
    nextDate = currentDate.plus({ days: 1 })
  } else if (period === 'week') {
    nextDate = currentDate.plus({ weeks: 1 })
  } else if (period === 'month') {
    nextDate = currentDate.plus({ months: 1 })
  } else if (period === 'trimester') {
    nextDate = currentDate.plus({ months: 3 })
  }

  return nextDate.startOf('day')
}

const getPrevDate = (period: Period, currentDate: any) => {
  let prevDate = currentDate

  if (period === 'day') {
    prevDate = currentDate.minus({ days: 1 })
  } else if (period === 'week') {
    prevDate = currentDate.minus({ weeks: 1 })
  } else if (period === 'month') {
    prevDate = currentDate.minus({ months: 1 })
  } else if (period === 'trimester') {
    prevDate = currentDate.minus({ months: 3 })
  }

  return prevDate.startOf('day')
}

const Roadmap: React.FC<Props> = (props) => {
  const {
    children,
    className,
    dates,
    defaultPeriod = 'month',
    emptyDescription,
    icon,
    isEmpty,
    isLoading,
    isRefetching,
    onDatesChange,
    title,
    hideThreeMonthView,
  } = props

  const { timezone } = useSettings()
  const now = DateTime.local().setZone(timezone)
  const nowSeconds = now.toSeconds()
  const today = now.startOf('day')

  const todayLineRef: any = React.useRef()

  const initialDate = dates?.startDate ? DT(dates.startDate).startOf('day') : today

  const [currentDate, setCurrentDate] = React.useState(initialDate)
  const [period, setPeriod] = React.useState(defaultPeriod)

  const handleNext = () => setCurrentDate(getNextDate(period, currentDate))
  const handlePrev = () => setCurrentDate(getPrevDate(period, currentDate))
  const handleToday = () => setCurrentDate(today)

  const handlePeriodSwitch = (period: Period) => setPeriod(period)

  const datesList = getDatesList(period, currentDate)
  const startDate = datesList[0]
  const endDate = datesList[datesList.length - 1]

  const minutesInView = datesList.length * 24 * 60
  const minutesFromStart = now.diff(startDate, 'minutes').toObject().minutes

  const showTodayLine = props.showTodayLine && nowSeconds > startDate.toSeconds() && nowSeconds < endDate.toSeconds()

  const classNames = clsx({
    [`period-${period}`]: period,
    [className]: className,
  })

  React.useEffect(() => {
    if (!onDatesChange) return

    onDatesChange({
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
      currentDate: currentDate.toISODate(),
    })
  }, [onDatesChange, currentDate, period])

  React.useEffect(() => {
    if (!todayLineRef.current?.scrollIntoView) return

    todayLineRef.current.scrollIntoView({ inline: 'start' })
  }, [todayLineRef.current])

  return (
    <RoadmapContext.Provider value={{ datesList, minutesInView, startDate, endDate, period }}>
      <div id="roadmap" css={styles.root} className={classNames}>
        {!isLoading && isEmpty ? (
          <State isEmpty title={title} icon={icon} emptyDescription={emptyDescription} />
        ) : (
          <div css={styles.scroll}>
            <TimelineHeader
              startDate={startDate}
              datesList={datesList}
              period={period}
              currentDate={currentDate}
              isLoading={isLoading}
              isRefetching={isRefetching}
              onNext={handleNext}
              onPeriodClick={handlePeriodSwitch}
              onPrev={handlePrev}
              onToday={handleToday}
              setCurrentDate={setCurrentDate}
              hideThreeMonthView={hideThreeMonthView}
              today={today}
            />

            {!isLoading && (
              <div className={styles.content()}>
                {showTodayLine && (
                  <div className={styles.todayLineWrapper()}>
                    <div
                      ref={todayLineRef}
                      className={styles.todayLine()}
                      style={{ left: (minutesFromStart / minutesInView) * 100 + '%' }}
                    />
                  </div>
                )}

                {children}
              </div>
            )}
          </div>
        )}
      </div>
    </RoadmapContext.Provider>
  )
}

const styles = {
  root: {
    position: 'relative',
    // background: COLORS.white,
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: '100%',

    '--timeline-header-height': '60px',

    // First Column Width
    '--first-column-width': '180px',

    '@media (min-width: 600px)': {
      '--first-column-width': '200px',
    },

    '@media (min-width: 1000px)': {
      '--first-column-width': '340px',
    },

    // Timeline Column Width
    '--timeline-column-width': '10px',

    '&.period-day': {
      '--timeline-column-width': '100px',
    },

    '&.period-week': {
      '--timeline-column-width': '140px',
    },

    '&.period-month': {
      '--timeline-column-width': '50px',
    },

    '&.period-trimester': {
      '--timeline-column-width': '50px',
    },

    '--row-columns': 'var(--first-column-width) 1fr',
    '--days-columns': 'minmax(var(--timeline-column-width), 1fr)',
  },

  scroll: {
    overflow: 'auto',
    display: 'grid',
    gridTemplateRows: 'min-content',
    alignContent: 'flex-start',
  },

  content: css({
    position: 'relative',
  }),

  todayLineWrapper: css({
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 'var(--first-column-width)',
    overflow: 'hidden',
    zIndex: 1,
    pointerEvents: 'none',
  }),

  todayLine: css({
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 1,
    background: COLORS.gray,
    opacity: 0.5,

    '&::before': {
      content: '""',
      width: 7,
      height: 7,
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      borderRadius: '50%',
      background: COLORS.red,
    },
  }),
}

export default Roadmap
