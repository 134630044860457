import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { DateTime } from 'luxon'

import { FILTERS } from '../Filters/config'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Link from '../../components/Link'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

import { useCommunityEditor } from './useCommunityEditor'
import { POPULATIONS_SERVED_GROUPS } from './constants'

const CATEGORY = {
  housing: 'Housing',
  office: 'Office',
  mixed_category: 'Mixed (Housing & Office)',
}

const RootCommunityLocationEditorOverlay = (props: any) => {
  const { entityId } = props

  const match = useRouteMatch()

  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  const { isNew, close, saveWithData, form, onValidationUpdate, isOverlayLoading, initialModel, isSaving, data } = useOverlay({
    name: 'community-location',
    endpoint: `/community/locations`,
    invalidate: 'community-locations',
    invalidateKeys: ['community-entity'],
    isEditable: true,
    options: props,
    closeOnSave: true,
    onSaveSuccessful: (newData: any) => {
      setUpdatedAt(DateTime.now().toMillis())
    },
  })

  const { timezone, isEHRApp } = useSettings()

  const [location, setLocation] = React.useState<any>(null)

  // const [avatar, setAvatar] = React.useState(initialModel?.avatar || '')
  const [name, setName] = React.useState(initialModel?.name || '')
  const [address, setAddress] = React.useState(initialModel?.address || '')
  const [email, setEmail] = React.useState(initialModel?.email || '')
  const [phoneNo, setPhoneNo] = React.useState(initialModel?.phone_no || '')
  const [description, setDescription] = React.useState(initialModel?.description || '')

  const [lat, setLat] = React.useState(initialModel?.lat || '')
  const [lon, setLon] = React.useState(initialModel?.lon || '')

  const [avatarData, setAvatarData] = React.useState<any>(null)
  const [avatarName, setAvatarName] = React.useState<any>(null)

  const handleSave = () => {
    saveWithData({
      // avatar,
      ...form.current?.getFormValue?.(),
      name,
      address,
      email,
      phone_no: phoneNo,
      description,
      lat,
      lon,
      ...(isNew && { entity_id: entityId }),
      ...(avatarData &&
        avatarName && {
          avatar_data: avatarData,
          avatar_name: avatarName,
        }),
    })
  }

  if (isOverlayLoading) return <OverlayLoader position="center" maxWidth={40} />

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" maxWidth={40} onClose={close}>
      <Overlay.Header title="Community Location Profile" icon="locations" />

      <Overlay.Content>
        <Section>
          <Form
            // isCompact
            isEditable
            key={location?.id}
            getForm={form}
            initialModel={initialModel}
            timezone={timezone}
            onValidationUpdate={onValidationUpdate}
            className="grid gap-4"
          >
            <FormSection maxWidth="100%">
              {isEHRApp && (
                <SummonOverlay
                  overlay={
                    <LocationSelectorOverlay
                      onSelect={(data: any) => {
                        setLocation(data)
                        setName(data.name)
                        setEmail(data.email)
                        setPhoneNo(data.phone_no)
                        setAddress(data.address)
                        setLat(data?.lat || null)
                        setLon(data?.lon || null)
                        // setAvatarData(data.avatar)
                      }}
                    />
                  }
                >
                  <div>
                    <Button label="Copy from Location…" icon="locations" size={300} type="default" display="inline-flex" />
                  </div>
                </SummonOverlay>
              )}

              {isNew && (
                <Alert contrast small glyph="info">
                  <span className="font-[600]">Note: </span> you can upload a photo for this location after saving
                </Alert>
              )}

              {!isNew && (
                <div className="flex justify-center">
                  <Avatar
                    isEditable
                    src={avatarData || data?.avatar}
                    width={120}
                    height={80}
                    recordId={data?.id}
                    // updateURL={`/community/locations/${data?.id}`}
                    onUpload={(params) => {
                      setAvatarData(params.avatar_data)
                      setAvatarName(params.avatar_name)
                    }}
                  />
                </div>
              )}

              <Input
                label="Name"
                value={name}
                onUpdate={({ value }) => {
                  setName(value)
                }}
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <Input
                label="Email"
                value={email}
                onUpdate={({ value }) => {
                  setEmail(value)
                }}
              />

              <PhoneInput
                label="Phone Number"
                value={phoneNo}
                onUpdate={({ value }) => {
                  setPhoneNo(value)
                }}
              />

              <Textarea
                label="Description"
                value={description}
                onUpdate={({ value }) => {
                  setDescription(value)
                }}
              />

              <AddressSelectorInput
                value={address ? { ...address, coords: { lat, lon } } : null}
                onUpdate={({ value }) => {
                  setAddress(value)

                  setLat(value?.coords?.lat || null)
                  setLon(value?.coords?.lon || null)

                  // setLat(value?.lat || null)
                  // setLon(value?.lon || null)
                }}
              />

              <Alert small contrast glyph="info">
                Populations served are managed separately at the main{' '}
                <Link to={`${match.url.replace('/locations', '/populations-served')}`}>Organization Level</Link> and each individual
                location level.
              </Alert>

              {POPULATIONS_SERVED_GROUPS.map((group) => (
                <CheckboxGroup
                  key={group.model}
                  layout="vertical-dense"
                  trueIcon="check"
                  falseIcon="cross"
                  falseStyle="faded"
                  label={group.label}
                  withHover={false}
                >
                  {group.groupItems.map((o) => (
                    <Checkbox key={o.model} label={o.label} model={`populations_served.${group.model}.${o.model}`} />
                  ))}
                </CheckboxGroup>
              ))}
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save Location Profile"
          glyph="tick_circle"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={isSaving}
          isDisabled={!name}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const LocationSelectorOverlay = (props: any) => {
  const { onClose, onSelect } = props

  const tableProps = useDataTable({
    name: ['location-selector-overlay'],
    endpoint: '/houses',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            onClick={
              onSelect
                ? () => {
                    onSelect(data)
                    onClose?.()
                  }
                : undefined
            }
          />
        ),
      },
      {
        width: 160,
        model: 'category',
        title: 'Category',
        formatValue: ({ data, value }: any) => CATEGORY?.[value],
      },
      {
        width: 160,
        model: 'gender',
        title: 'Accepted Gender',
        type: 'title',
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'phone_no',
        title: 'Phone',
      },
      {
        width: 160,
        id: 'email',
        model: 'email',
        title: 'Email',
      },
      {
        title: 'Address',
        model: 'address',
        type: 'address',
        disableSort: true,
      },
    ],
    [],
  )

  return (
    <Overlay showBackdrop closeOnBackdrop maxWidth={80} position="center" onClose={onClose}>
      <Overlay.Header title="Select Location" icon="locations" />

      <Overlay.Content>
        <DataTable {...tableProps} title="Locations" icon="locations" columns={columns} filtersConfig={FILTERS.locations} />
      </Overlay.Content>
    </Overlay>
  )
}

export const CommunityLocationEditorOverlay = withOverlayError(RootCommunityLocationEditorOverlay)
