import React from 'react'
import ReactPinInput from 'react-pin-input'

import { COLORS, INPUT_STYLES, INPUT_FOCUS_STYLES } from '../theme'

export const PinInput = (props: any) => {
  const { length = 6, onComplete, onChange, className } = props

  return (
    <div css={STYLES}>
      <ReactPinInput
        focus
        autoSelect
        length={length}
        className={className}
        initialValue=""
        type="numeric"
        inputMode="number"
        style={{ padding: '10px' }}
        onComplete={onComplete}
        onChange={onChange}
      />
    </div>
  )
}

const STYLES = {
  '.pincode-input-container': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 !important',

    input: {
      ...INPUT_STYLES,

      flex: '1 1 auto',
      width: '40px !important',
      height: '70px !important',
      fontSize: '1.2rem !important',
      border: INPUT_STYLES.border + ' !important',
      background: COLORS.white + ' !important',

      '@media(min-width: 600px)': {
        height: '100px !important',
        fontSize: '1.5rem !important',
      },

      '&:first-of-type': {
        marginLeft: '0 !important',
      },

      '&:last-of-type': {
        marginRight: '0 !important',
      },

      '&:focus': {
        ...INPUT_FOCUS_STYLES,

        borderColor: INPUT_FOCUS_STYLES.borderColor + ' !important',
        boxShadow: INPUT_FOCUS_STYLES.boxShadow + ' !important',
      },
    },
  },
}
