import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { css, COLORS, INPUT_STYLES, INPUT_FOCUS_STYLES } from '../../../theme'
import { useFormField } from '../../Forms/hooks/useFormField'
import { useFuse } from '../../../hooks/useFuse'
import { useGet } from '../../../hooks/useNewAPI'

import { withFormContext } from '../../Forms/context'

import Avatar from '../../../components/Avatar'
import ClearButton from '../ClearButton'
import Field from '../../../components/Forms/Field'
import Glyph from '../../../components/Glyph'
import Icon from '../../../components/Icon'
import State from '../../../components/State'
import Tooltip from '../../../components/Tooltip'
import Value from '../../../components/Value'

import { PopoverMenu, CustomPopoverMenuItem } from '../../../components/PopoverMenu'

import { CONFIG } from './config'

const DEFAULT_SEARCH_KEYS = ['name', 'title']

const RootComboBox = (props: any) => {
  const {
    dependentValue,
    form,
    isCompact,
    isDisabled,
    isEditable,
    isPolymorphic,
    labelAfter,
    model,
    onUpdate,
    showAvatars,
    tooltip,
    tooltipTrigger,
    type,
    validations,
  } = props

  const config = React.useMemo(() => get(CONFIG, type), [type])

  const [value, setValue] = React.useState(props.value)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [inputRef, setInputRef] = React.useState(null)

  const [isOpen, setIsOpen] = React.useState(false)
  const [isFocused, setIsFocused] = React.useState(false)
  const [selectedIds, setSelectedIds]: any = React.useState([])

  const [highlightIndex, setHighlightIndex]: any = React.useState(0)

  const endpoint = React.useMemo(() => {
    const _endpoint = props.endpoint || config?.endpoint

    if (typeof _endpoint === 'function') return dependentValue ? _endpoint(dependentValue) : null

    return _endpoint
  }, [props.endpoint, config?.endpoint, dependentValue])

  const dependentValueRef = React.useRef(props.dependentValue)

  const params = props.params || config?.params
  const icon = props.icon || config?.icon
  const selectTitle = props.selectTitle || config?.selectTitle
  const selectDescription = props.selectDescription || config?.selectDescription
  const searchKeys = props.searchKeys || config?.searchKeys || DEFAULT_SEARCH_KEYS

  const prevEndpointRef = React.useRef(endpoint)

  const title = React.useMemo(() => {
    if (!value) return ''

    return selectTitle?.(value) || value?.name
  }, [value])

  const graphic = React.useMemo(() => {
    if (!isUndefined(value?.avatar)) return <Avatar src={value.avatar} size={24} initials={title} />
    else if (icon) return <Icon icon={icon} size={20} />
    else return null
  }, [value, icon, title])

  const { data, isLoading }: any = useGet({
    name: ['combo-box', endpoint, params].flat(),
    url: endpoint,
    params: params,
    options: {
      enabled: !!endpoint && isOpen,
      keepPreviousData: prevEndpointRef.current === endpoint,
    },
  })

  const { formActions, formState } = useFormField({
    model: model,
    form: form,
    initialValue: value,
    validations: validations,
    isRelation: true,
    isPolymorphic,
    isValid: size(validations) === 0,
    // afterChange: onUpdate,
  })

  const searchData = useFuse(data, searchTerm, { keys: searchKeys })
  const isEmpty = size(data) === 0

  // const [hideSelected, setHideSelected]: any = React.useState(!!config?.shouldHideSelected)

  // const showHideSelectedToggle = !!config?.shouldHideSelected || !!config?.showHideSelectedToggle

  const handleChange = (event: any) => {
    const newSearchTerm = event.target.value

    setSearchTerm(newSearchTerm)

    // remove value if searchTerm is empty
    if (!newSearchTerm && !!value) setValue(null)
  }

  const handleKeyDown = (event: any) => {
    if (!isOpen && !['Tab', 'Alt', 'Shift'].includes(event.key)) {
      setIsOpen(true)
    }

    // select first item on enter
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()

      if (isOpen) {
        const record = searchData[highlightIndex]
        handleSelect(record)
        close()
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      event.stopPropagation()

      if (!isOpen) return

      const nextIndex = highlightIndex > 0 ? highlightIndex - 1 : 0
      setHighlightIndex(nextIndex)
    } else if (event.key === 'ArrowDown') {
      event.preventDefault()
      event.stopPropagation()

      if (!isOpen) return

      const nextIndex = highlightIndex < size(searchData) - 1 ? highlightIndex + 1 : highlightIndex
      setHighlightIndex(nextIndex)
    }
  }

  const close = () => {
    setIsOpen(false)
    inputRef?.focus?.()
  }

  const handleClear = () => {
    formActions.setValue(null)
    setValue(null)
    setSelectedIds([])
  }

  const handleSelect = (record: any) => {
    if (!record) return

    const newTitle = selectTitle?.(record) || record.name

    setValue(record)
    setSearchTerm(newTitle)

    close()
  }

  // Sync input value on mount
  React.useEffect(() => {
    if (!model) return

    const initialData = form?.getInitialInputFieldValue(model)
    const newTitle = selectTitle?.(initialData) || initialData?.name

    setValue(initialData)
    setSearchTerm(newTitle)
  }, [])

  // Sync input value with selected title on close
  React.useEffect(() => {
    if (isOpen) return

    if (searchTerm !== title) setSearchTerm(title)
  }, [searchTerm, title, isOpen])

  // Reset highlight index on close and on search
  React.useEffect(() => {
    setHighlightIndex(0)
  }, [isOpen, searchTerm])

  // Sync props value with state value
  React.useEffect(() => {
    if (!props.value) return

    // value has not changed
    if (props.value?.id === value?.id) return

    const newTitle = selectTitle?.(props.value) || props.value?.name

    setValue(props.value)
    setSearchTerm(newTitle)
  }, [props.value?.id])

  // Update form state
  React.useEffect(() => {
    if (isUndefined(value)) return

    formActions.setValue(value)
    onUpdate?.(value)
  }, [model, value])

  // Clear value when dependant endpoint changes
  React.useEffect(() => {
    if (!dependentValueRef.current) return
    if (dependentValueRef.current === dependentValue) return

    if (!dependentValueRef.current && !!value) return

    setValue(null)
    setSearchTerm('')
  }, [dependentValueRef.current, dependentValue])

  const wrapperClasses = clsx(STYLES.wrapper().className, isFocused && 'is-focused')
  const inputClasses = clsx(STYLES.input().className, graphic && 'has-graphic')

  return (
    <Field
      testKey="combo_box_select"
      label={props.label}
      isRequired={formState.isRequired}
      isValid={formState.isValid}
      isCompact={isCompact}
      isEditable={isEditable}
      isDisabled={isDisabled}
      tooltip={tooltip}
      tooltipTrigger={tooltipTrigger}
      labelAfter={labelAfter}
    >
      {!isEditable && (
        <Value
          avatarMagnify
          value={value && (selectTitle?.(value) || value.name)}
          icon={icon}
          avatar={value?.avatar}
          avatarMagnifyScale={2.5}
          // description={showDescription && object?.description}
        />
      )}

      {isEditable && (
        <div className={wrapperClasses}>
          {graphic && <div className={STYLES.graphic()}>{graphic}</div>}

          <input
            ref={setInputRef}
            value={searchTerm}
            className={inputClasses}
            disabled={isDisabled}
            onChange={handleChange}
            type="text"
            placeholder="Select…"
            onFocus={(e) => {
              setIsOpen(true)
              setIsFocused(true)
            }}
            onBlur={(e) => {
              // setIsOpen(false)
              setIsFocused(false)
            }}
            onKeyDown={handleKeyDown}
          />

          {value && <ClearButton onClick={handleClear} />}

          <PopoverMenu
            useTriggerWidth
            isOpen={isOpen}
            onOpenUpdated={setIsOpen}
            onOpenAutoFocus={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            onInteractOutside={(e) => {
              // don't close menu when clicking on input
              if (inputRef?.contains(e.target)) {
                e.preventDefault()
                return
              }
            }}
            trigger={
              <div
                css={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  pointerEvents: 'none',
                }}
              />
            }
          >
            {!data || isLoading || isEmpty ? (
              <State isLoading={isLoading} isEmpty={isEmpty} title={config?.label} />
            ) : (
              <>
                {searchData.map((record: any, index) => {
                  const isActive = value?.id === record.id
                  const isSelected = index === highlightIndex

                  return (
                    <SelectItem
                      key={record.id}
                      data={record}
                      isActive={isActive}
                      isSelected={isSelected}
                      selectDescription={selectDescription}
                      selectTitle={selectTitle}
                      showAvatars={showAvatars}
                      onClick={() => {
                        handleSelect(record)
                      }}
                    />
                  )
                })}
              </>
            )}
          </PopoverMenu>
        </div>
      )}
    </Field>
  )
}

const SelectItem = (props: any) => {
  const { data, disabledMessage, isActive, isDisabled, isHighlighted, isSelected, onClick, selectDescription, selectTitle, showAvatars } =
    props

  if (!data) return null

  const title = selectTitle?.(data) || data?.name
  const description = selectDescription?.(data)

  const selectItemClasses = clsx(
    STYLES.selectItem().className,
    isSelected && 'is-selected',
    isHighlighted && 'is-highlighted',
    isDisabled && 'is-disabled',
  )

  return (
    <CustomPopoverMenuItem className={selectItemClasses} isActive={isActive} onClick={onClick}>
      <div className="select-item-graphic">
        {isActive ? (
          <Glyph glyph="check" size={10} color={COLORS.green} />
        ) : isSelected ? (
          <Glyph glyph="arrow_right" size={10} color={COLORS.blue} />
        ) : null}
      </div>

      {(!isUndefined(data.avatar) || showAvatars) && (
        <div className="select-item-avatar">
          <Avatar src={data.avatar} size={22} initials={title} />
        </div>
      )}

      {title && (
        <div>
          <div className="select-item-title">{title}</div>
          {description && <div className="select-item-description">{description}</div>}
        </div>
      )}

      {isDisabled && disabledMessage && (
        <Tooltip color={COLORS.red} content={disabledMessage} css={STYLES.selectItemTooltip} portal="radix" />
      )}
    </CustomPopoverMenuItem>
  )
}

const STYLES = {
  wrapper: css({
    ...INPUT_STYLES,
    position: 'relative',
    padding: 0,

    '&:focus-within': INPUT_FOCUS_STYLES,
  }),

  input: css({
    height: '100%',
    width: '100%',
    outline: 'none !important',
    border: 'none !important',
    minHeight: INPUT_STYLES.minHeight,

    '&.has-graphic': {
      paddingLeft: '2.5rem',
    },
  }),

  graphic: css({
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2.5rem',
    height: '100%',
  }),

  trigger: css({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
    padding: '0.2rem',
    paddingLeft: '0.4rem',
    paddingRight: '1.2rem',
    overflow: 'hidden',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    '.select-trigger-value': {
      display: 'flex',
      flexWrap: 'nowrap',
      flex: '1 1 auto',
      maxWidth: '100%',
    },

    '.select-trigger-avatar': {
      marginRight: '0.4rem',
    },

    '.select-trigger-label': {
      fontWeight: 500,
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '.select-trigger-glyph': {
      marginRight: '0.5rem',
      pointerEvents: 'none',
    },

    '.select-trigger-triangle': {
      position: 'absolute',
      right: '0.25rem',
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },

    '&.is-empty': {
      fontStyle: 'italic',
      color: COLORS.textStronglyMuted,
    },

    '&.is-editing': {
      borderRadius: 3,
      background: COLORS.white,
      boxShadow: 'var(--input-focus-box-shadow)',
    },

    '&.is-invalid': {
      display: 'block',
      background: tint(0.85, COLORS.red),
    },
  }),

  selectItem: css({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    fontSize: '0.88rem',
    width: '100%',
    lineHeight: 'normal',
    paddingTop: '0.4rem',
    paddingBottom: '0.4rem',
    paddingRight: '1rem',
    fontWeight: 600,

    '&:hover': {
      fontWeight: 600,
      background: COLORS.hover,
    },

    '.select-item-graphic': {
      opacity: 0,
      visibility: 'hidden',
      width: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '.select-item-avatar': {
      marginRight: '0.5rem',
    },

    '.select-item-title': {
      fontWeight: 600,
    },

    '.select-item-description': {
      fontWeight: 400,
      color: COLORS.textMuted,
    },

    '&.is-selected': {
      backgroundColor: `${tint(0.92, COLORS.blue)} !important`,
      // boxShadow: `inset 0 0 0 1px ${tint(0.2, COLORS.blue)}`,

      '&:hover': {
        backgroundColor: tint(0.92, COLORS.blue),
      },

      '.select-item-graphic': {
        opacity: 1,
        visibility: 'visible',
      },
    },

    '&.is-disabled': {
      opacity: 0.5,
      cursor: 'not-allowed !important',
      backgroundColor: tint(0.92, COLORS.gray),

      '&:hover': {
        backgroundColor: tint(0.92, COLORS.gray),
      },

      '.select-item-graphic': {
        opacity: 1,
        visibility: 'visible',
      },
    },

    '&.is-active': {
      backgroundColor: tint(0.92, COLORS.green),

      '&:hover': {
        backgroundColor: tint(0.92, COLORS.green),
      },

      '.select-item-graphic': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  }),

  selectItemTooltip: {
    marginLeft: 'auto',
    position: 'relative',
    zIndex: 3,
  },
}

export const ComboBox: any = withFormContext(RootComboBox)
