import React from 'react'

import { FormSubmissionsDataTable } from '@behavehealth/constructs/FormSubmissions/FormSubmissionsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import Grid from '@behavehealth/components/Grid'

export const FormInbox = () => {
  const tableProps = useDataTable({
    name: ['form_submissions'],
    endpoint: `/form_submissions`,
    params: { assigned: false },
    localStorageKey: 'form_submissions_v2',
  })

  return (
    <Grid>
      <FormSubmissionsDataTable
        asCard
        useV6Router
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'form_submissions.delete',
            action: async ({ ids }: any) => {
              await tableProps.deleteRecords(ids.join(','))
            },
          },
        ]}
        {...tableProps}
      />
    </Grid>
  )
}
