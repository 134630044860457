import React from 'react'

import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ContextShow from '../../Forms/ContextShow'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Label from '../../Label'
import Textarea from '../../Forms/Textarea'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

type Props = {
  isHealthcare: boolean
}

export const FinancialDetailsFields: React.FC<Props> = ({ isHealthcare }) => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="financials" />
        <Type as="h2" variant="H3">
          Financials
        </Type>
      </Flex>

      <Label label="Which of the following types of client payments, insurance, or funding are accepted by this facility for treatment services?" />

      <CheckboxGroup withToggle trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" label=" " layout="vertical-dense">
        <Checkbox
          defaultChecked
          label="Cash or self-payment"
          model="questionnaire.financial_details.accepted_payment_methods.cash_or_self_payment"
        />
        <Checkbox
          defaultChecked={isHealthcare}
          label="Private health insurance (out-of-network)"
          model="questionnaire.financial_details.accepted_payment_methods.private_health_insurance_out_of_network"
        />
        <Checkbox
          label="Private health insurance (in-network)"
          model="questionnaire.financial_details.accepted_payment_methods.private_health_insurance_in_network"
        />
        <Checkbox
          label="Private or community foundation"
          model="questionnaire.financial_details.accepted_payment_methods.private_or_community_foundation"
        />
      </CheckboxGroup>

      <h3>Government Funding Sources</h3>

      <CheckboxGroup withToggle trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" label="Insurance" layout="vertical-dense">
        <Checkbox label="Medicare" model="questionnaire.financial_details.accepted_payment_methods.medicare" />
        <Checkbox label="Medicaid" model="questionnaire.financial_details.accepted_payment_methods.medicaid" />
      </CheckboxGroup>

      <h4>Non-insurance Sources</h4>

      <CheckboxGroup withToggle trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" label="Federal" layout="vertical-dense">
        <Checkbox label="Federal grants" model="questionnaire.financial_details.accepted_payment_methods.federal_grants" />
        <Checkbox
          label="Federal military insurance (such as TRICARE)"
          model="questionnaire.financial_details.accepted_payment_methods.federal_military_insurance"
        />
        <Checkbox
          label="U.S. Department of Veterans Affairs funds"
          model="questionnaire.financial_details.accepted_payment_methods.us_department_of_veterans_affairs_funds"
        />
        <Checkbox
          label="IHS/Tribal/Urban (ITU) funds"
          model="questionnaire.financial_details.accepted_payment_methods.ihs_tribal_urban_itu_funds"
        />
      </CheckboxGroup>

      <CheckboxGroup withToggle trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" label="State" layout="vertical-dense">
        <Checkbox
          label="State-financed health insurance plan other than Medicaid"
          model="questionnaire.financial_details.accepted_payment_methods.state_financed_health_insurance_plan_other_than_medicaid"
        />
        <Checkbox
          label="State mental health agency (or equivalent) funds"
          model="questionnaire.financial_details.accepted_payment_methods.state_mental_health_agency_or_equivalent_funds"
        />
        <Checkbox
          label="State welfare or child and family services agency funds"
          model="questionnaire.financial_details.accepted_payment_methods.state_welfare_or_child_and_family_services_agency_funds"
        />
        <Checkbox
          label="State corrections or juvenile justice agency funds"
          model="questionnaire.financial_details.accepted_payment_methods.state_corrections_or_juvenile_justice_agency_funds"
        />
        <Checkbox
          label="State education agency funds"
          model="questionnaire.financial_details.accepted_payment_methods.state_education_agency_funds"
        />
        <Checkbox
          label="Other state government funds"
          model="questionnaire.financial_details.accepted_payment_methods.other_state_government_funds"
        />
      </CheckboxGroup>

      <CheckboxGroup
        withToggle
        trueIcon="check"
        falseIcon="cross"
        falseStyle="faded-linethrough"
        label="County/Local"
        layout="vertical-dense"
      >
        <Checkbox
          label="Community Service Block Grants"
          model="questionnaire.financial_details.accepted_payment_methods.community_service_block_grants"
        />
        <Checkbox
          label="Community Mental Health Block Grants"
          model="questionnaire.financial_details.accepted_payment_methods.community_mental_health_block_grants"
        />
        <Checkbox
          label="County or local government funds"
          model="questionnaire.financial_details.accepted_payment_methods.county_or_local_government_funds"
        />
      </CheckboxGroup>

      <ContextShow when="questionnaire.financial_details.accepted_payment_methods.federal_grants" is={true}>
        <Textarea label="Federal grants – specify:" model="questionnaire.financial_details.accepted_payment_methods.federal_grants_notes" />
      </ContextShow>

      <CheckboxGroup withToggle trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" label=" " layout="vertical-dense">
        <Checkbox
          label="No payment accepted (free treatment for ALL clients)"
          model="questionnaire.financial_details.accepted_payment_methods.free_treatment_for_all_clients"
        />
        <Checkbox label="Other" model="questionnaire.financial_details.accepted_payment_methods.other" />
      </CheckboxGroup>

      <ContextShow when="questionnaire.financial_details.accepted_payment_methods.other" is={true}>
        <Textarea label="Other – specify:" model="questionnaire.financial_details.accepted_payment_methods.other_notes" />
      </ContextShow>
    </FormSection>
  )
}

export const FinancialDetails: React.FC<Props> = ({ isHealthcare }) => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <FinancialDetailsFields isHealthcare={isHealthcare} />
    </Form>
  )
}
