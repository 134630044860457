import React from 'react'

import Select from '../Forms/Select'
import Option from '../Forms/Option'
import { withFormContext } from '../Forms/context'

import { EVENT_CATEGORIES, EVENTS_CATEGORIES } from '../../utils/constants'

const EventCategorySelect = ({ label, model = 'category', defaultValue, isEditable, isDisabled, tooltip }: any) => {
  let options = null

  if (isEditable && !isDisabled) {
    options = Object.keys(EVENTS_CATEGORIES).map((category: any) => {
      const local_category = EVENTS_CATEGORIES[category]

      if (typeof local_category === 'object') {
        const local_subcategories = local_category.subcategories

        return (
          <>
            <option disabled />

            <optgroup key={category} label={local_category.name}>
              {Object.keys(local_subcategories).map((subcategory: any) => (
                <Option key={`subcategory-${subcategory}`} label={local_subcategories?.[subcategory]} value={subcategory} />
              ))}
            </optgroup>
          </>
        )
      } else {
        return <Option key={category} label={local_category} value={category} />
      }
    })
  } else {
    options = Object.keys(EVENT_CATEGORIES).map((category: any) => {
      return <Option key={category} label={EVENT_CATEGORIES[category]} value={category} />
    })
  }

  return (
    <Select label={label} model={model} defaultValue={defaultValue} isEditable={isEditable && !isDisabled} tooltip={tooltip}>
      {options}
    </Select>
  )
}

export default withFormContext(EventCategorySelect)
