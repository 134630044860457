import React from 'react'

import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextHide from '../../components/Forms/ContextHide'
import ContextShow from '../../components/Forms/ContextShow'
import Diagnoses from '../../components/SmartContent/Diagnoses'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Medications from '../../components/SmartContent/Medications'
import Section from '../../components/Section'
import Switch from '../../components/Forms/Switch'
import Textarea from '../../components/Forms/Textarea'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../components/Forms/elements/YesNoRadioGroupWithTextarea'

import CIWASymptoms from '../../components/Overlays/pages/DataForms/common/CIWASymptoms'
import COWSSymptoms from '../../components/Overlays/pages/DataForms/common/COWSSymptoms'
import MedicalSystemsReview from '../../components/Overlays/pages/DataForms/common/MedicalSystemsReview'
import MentalStatusExam from '../../components/Overlays/pages/DataForms/common/MentalStatusExam'
import OtherDiagnosisCodes from '../../components/Overlays/pages/DataForms/common/OtherDiagnosisCodes'
import PhysicalExam from '../../components/Overlays/pages/DataForms/common/PhysicalExam'
import SubstanceUseDiagnosisCodes from '../../components/Overlays/pages/DataForms/common/SubstanceUseDiagnosisCodes'
import SubstanceUseHistoryV1 from '../../components/Overlays/pages/DataForms/common/SubstanceUseHistoryV1'
import SubstanceUseHistory from '../../components/Overlays/pages/DataForms/common/SubstanceUseHistory'
import VitalSignsForm from '../../components/Overlays/pages/DataForms/common/VitalSignsForm'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootHistoryAndPhysicalOverlay = () => (
  <DataFormOverlay
    title="History And Physical"
    category="medical_assessment"
    subcategory="history_and_physical"
    icon="medical_assessments"
    helpID="medical_assessments_history_and_physical"
    requestClientSignature={false}
    requireSupervisor={true}
    requestSupervisorSignature={true}
    enableTreatmentPlanSelector
  >
    {({ client, data, isEditable }: any) => (
      <>
        {/* Precipitating Event */}
        <Section
          title="Precipitating Event"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.precipitating_event.not_applicable" />}
          scrollview={{
            id: 'precipitating_event',
            name: 'Precipitating Event',
          }}
          commentsModel="data.precipitating_event"
        >
          <ContextHide when="data.precipitating_event.not_applicable" is={true}>
            <Textarea
              label="Why is the client seeking treatment at this time? What makes this time different, if anything? (Please be specific)"
              model="data.precipitating_event.description"
            />
          </ContextHide>
        </Section>

        <Divider />

        {/* Diagnostic Criteria */}
        {data?.diagnostic_criteria && (
          <>
            <Section
              title="Diagnostic Criteria"
              headingType="h2"
              aside={<Switch horizontal inverse model="data.diagnostic_criteria.not_applicable" />}
              scrollview={{
                id: 'diagnostic_criteria',
                name: 'Diagnostic Criteria',
              }}
              commentsModel="data.diagnostic_criteria"
            >
              <ContextHide when="data.diagnostic_criteria.not_applicable" is={true}>
                <FormSection>
                  <SubstanceUseDiagnosisCodes />
                  <OtherDiagnosisCodes />

                  <YesNoRadioGroupWithTextarea
                    label="Over the last 12 months, have substances been taken in larger amounts or over a longer period of time than intended?"
                    model="data.diagnostic_criteria.did_take_substances_in_past_year"
                  />

                  <YesNoRadioGroupWithTextarea
                    label="Over the last 12 months, has there been a persistent desire or unsuccessful efforts to cut down or control substance use?"
                    model="data.diagnostic_criteria.did_try_substance_use_control_in_past_year"
                  />

                  <Input
                    label="Number of times client has attempted to quit on their own:"
                    model="data.diagnostic_criteria.number_quit_attempts"
                    suffix="times"
                    type="number"
                    size={4}
                  />

                  <Input
                    label="Total number of substance abuse admissions:"
                    model="data.diagnostic_criteria.number_substance_abuse_admissions"
                    suffix="admissions"
                    type="number"
                    size={4}
                  />

                  <Input
                    label="Total number of psychiatric admissions:"
                    model="data.diagnostic_criteria.number_psychiatric_admissions"
                    suffix="admissions"
                    type="number"
                    size={4}
                  />

                  <Input
                    label="Total number of eating disorder admissions:"
                    model="data.diagnostic_criteria.number_eating_disorder_admissions"
                    suffix="admissions"
                    type="number"
                    size={4}
                  />

                  <Grid gap="0.5rem">
                    <Label label="Please check any that apply:" />

                    <Checkbox
                      label="A large amount of time is spent to obtain substance, use substance or recover from the effects"
                      model="data.diagnostic_criteria.applicable.does_spend_large_time_on_substance_use"
                    />

                    <ContextShow when="data.diagnostic_criteria.applicable.does_spend_large_time_on_substance_use" is={true}>
                      <Textarea
                        label="Please give example:"
                        model="data.diagnostic_criteria.applicable.does_spend_large_time_on_substance_use_description"
                      />
                    </ContextShow>

                    <Checkbox
                      label="Client has craving or strong desire to use substance"
                      model="data.diagnostic_criteria.applicable.has_substance_craving"
                    />

                    <Checkbox
                      label="Substance use has resulted in failure to fulfill major role obligations at work, home or school"
                      model="data.diagnostic_criteria.applicable.did_fail_obligations_due_to_substance_use"
                    />

                    <ContextShow when="data.diagnostic_criteria.applicable.did_fail_obligations_due_to_substance_use" is={true}>
                      <Textarea
                        label="Please give example:"
                        model="data.diagnostic_criteria.applicable.did_fail_obligations_due_to_substance_use_description"
                      />
                    </ContextShow>

                    <Checkbox
                      label="Substance use continues in spite of persistent or recurring social or interpersonal problems caused or exacerbated by the effects of the substance"
                      model="data.diagnostic_criteria.applicable.does_continue_substance_use_despite_negatives"
                    />

                    <ContextShow when="data.diagnostic_criteria.applicable.does_continue_substance_use_despite_negatives" is={true}>
                      <Textarea
                        label="Please give example:"
                        model="data.diagnostic_criteria.applicable.does_continue_substance_use_despite_negatives_description"
                      />
                    </ContextShow>

                    <Checkbox
                      label="Continued use in dangerous situations or despite having knowledge of the consequences physically or psychologically"
                      model="data.diagnostic_criteria.applicable.does_continue_substance_use_despite_dangers"
                    />

                    <ContextShow when="data.diagnostic_criteria.applicable.does_continue_substance_use_despite_dangers" is={true}>
                      <CheckboxGroup label="The above is evidenced by the following (check any that apply):" layout="vertical-dense">
                        <Checkbox label="Overdose" model="data.diagnostic_criteria.applicable.dangers.overdose" />
                        <Checkbox label="Intravenous drug use" model="data.diagnostic_criteria.applicable.dangers.intravenous_drug_use" />
                        <Checkbox
                          label="Shared needles or paraphernalia"
                          model="data.diagnostic_criteria.applicable.dangers.shared_needles_or_paraphernalia"
                        />
                        <Checkbox
                          label="Abscesses or other infections"
                          model="data.diagnostic_criteria.applicable.dangers.abscesses_or_other_infections"
                        />
                        <Checkbox label="Unsafe sex" model="data.diagnostic_criteria.applicable.dangers.unsafe_sex" />
                        <Checkbox label="Criminal activity" model="data.diagnostic_criteria.applicable.dangers.criminal_activity" />
                        <Checkbox label="Drive impaired" model="data.diagnostic_criteria.applicable.dangers.drive_impaired" />
                        <Checkbox label="HIV" model="data.diagnostic_criteria.applicable.dangers.hiv" />
                        <Checkbox label="Hepatitis C" model="data.diagnostic_criteria.applicable.dangers.hepatitis_c" />
                        <Checkbox label="Seizure" model="data.diagnostic_criteria.applicable.dangers.seizure" />
                        <Checkbox label="Blackouts" model="data.diagnostic_criteria.applicable.dangers.blackouts" />
                        <Checkbox label="Liver disease" model="data.diagnostic_criteria.applicable.dangers.liver_disease" />
                        <Checkbox label="Tremors" model="data.diagnostic_criteria.applicable.dangers.tremors" />
                        <Checkbox label="Anxiety" model="data.diagnostic_criteria.applicable.dangers.anxiety" />
                        <Checkbox label="Depression" model="data.diagnostic_criteria.applicable.dangers.depression" />
                        <Checkbox label="Other" model="data.diagnostic_criteria.applicable.dangers.other" />
                      </CheckboxGroup>

                      <ContextShow when="data.diagnostic_criteria.applicable.dangers.other" is={true}>
                        <Textarea label="Other:" model="data.diagnostic_criteria.applicable.dangers.other_extra" />
                      </ContextShow>
                    </ContextShow>
                  </Grid>
                </FormSection>
              </ContextHide>
            </Section>

            <Divider />
          </>
        )}

        {/* Medical Systems Review */}
        <Section
          title="Medical Systems Review"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.medical_systems_review.not_applicable" />}
          scrollview={{
            id: 'medical_systems_review',
            name: 'Medical Systems Review',
          }}
          commentsModel="data.medical_systems_review"
        >
          <ContextHide when="data.medical_systems_review.not_applicable" is={true}>
            <MedicalSystemsReview />
          </ContextHide>
        </Section>

        <Divider />

        {/* Vital Signs */}
        <Section
          title="Vital Signs"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.vital_signs.not_applicable" />}
          scrollview={{
            id: 'vital_signs',
            name: 'Vital Signs',
          }}
          commentsModel="data.vital_signs"
        >
          <ContextHide when="data.vital_signs.not_applicable" is={true}>
            <VitalSignsForm showComments={false} />
          </ContextHide>
        </Section>

        <Divider />

        {/* Physical Exam */}
        <Section
          title="Physical Exam"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.physical_exam.not_applicable" />}
          scrollview={{
            id: 'physical_exam',
            name: 'Physical Exam',
          }}
          commentsModel="data.physical_exam"
        >
          <ContextHide when="data.physical_exam.not_applicable" is={true}>
            <PhysicalExam />
          </ContextHide>
        </Section>

        <Divider />

        {/* Substance Use History */}
        <Section
          title="Substance Use History"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.substance_use_history.not_applicable" />}
          scrollview={{
            id: 'substance_use_history',
            name: 'Substance Use History',
          }}
          commentsModel="data.substance_use_history"
        >
          <ContextHide when="data.substance_use_history.not_applicable" is={true}>
            <SubstanceUseHistory />
          </ContextHide>
        </Section>

        <Divider />

        {/* Client History */}
        <Section
          title="Client History"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.client_history.not_applicable" />}
          scrollview={{
            id: 'client_history',
            name: 'Client History',
          }}
          commentsModel="data.client_history"
        >
          <ContextHide when="data.client_history.not_applicable" is={true}>
            <FormSection>
              <YesNoRadioGroup label="Has the client been abused or exploited?" model="data.client_history.has_been_abused_or_exploited" />

              <ContextShow when="data.client_history.has_been_abused_or_exploited" is={true}>
                <CheckboxGroup label="Type of abuse:" layout="vertical-dense">
                  <Checkbox label="Physical" model="data.client_history.abuse_type.physical" />
                  <Checkbox label="Emotional" model="data.client_history.abuse_type.emotional" />
                  <Checkbox label="Verbal" model="data.client_history.abuse_type.verbal" />
                  <Checkbox label="Sexual" model="data.client_history.abuse_type.sexual" />
                  <Checkbox label="Other" model="data.client_history.abuse_type.other" />
                </CheckboxGroup>

                <ContextShow when="data.client_history.abuse_type.other" is={true}>
                  <Input label="Other:" model="data.client_history.abuse_type.other_extra" />
                </ContextShow>

                <Textarea label="Please list age, duration and examples:" model="data.client_history.abuse_details" />
              </ContextShow>

              <YesNoRadioGroup label="Does the client have any current or past legal issues?" model="data.client_history.has_legal_issues" />

              <ContextShow when="data.client_history.has_legal_issues" is={true}>
                <Textarea label="Please list offenses, dates and status of charges:" model="data.client_history.legal_issues_details" />
              </ContextShow>

              <Textarea
                label="Please list any medical issues evident in the client's family history. (include specific conditions and family members):"
                model="data.client_history.family_medical_issues"
              />
              <Textarea label="Please list any medical issues currently or in the past:" model="data.client_history.medical_issues" />
              <Textarea label="Please list any past surgeries:" model="data.client_history.past_surgeries" />
              <Textarea label="Please list any family history of substance abuse" model="data.client_history.family_substance_abuse" />
              <Textarea
                label="Please describe the client's educational history, including any problems due to substance use:"
                model="data.client_history.educational_history"
              />
              <Textarea
                label="Please describe the client's occupational history including problems due to substance use:"
                model="data.client_history.occupational_history"
              />
              <Textarea
                label="Please describe the client's social environment (friends, hobbies, primary relationships)"
                model="data.client_history.social_environment"
              />
            </FormSection>
          </ContextHide>
        </Section>

        <Divider />

        {/* Mental Status Exam */}
        <Section
          title="Mental Status Exam"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.mental_status_exam.not_applicable" />}
          scrollview={{
            id: 'mental_status_exam',
            name: 'Mental Status Exam',
          }}
          commentsModel="data.mental_status_exam"
        >
          <ContextHide when="data.mental_status_exam.not_applicable" is={true}>
            <MentalStatusExam />
          </ContextHide>
        </Section>

        <Divider />

        {/* COWS Symptoms */}
        <Section
          title="COWS Symptoms"
          aside={<Switch horizontal inverse model="data.cows_symptoms.not_applicable" />}
          scrollview={{
            id: 'cows_symptoms',
            name: 'COWS Symptoms',
          }}
          commentsModel="data.cows_symptoms"
        >
          <ContextHide when="data.cows_symptoms.not_applicable" is={true}>
            <COWSSymptoms />
          </ContextHide>
        </Section>

        <Divider />

        {/* CIWA Symptoms */}
        <Section
          title="CIWA Symptoms"
          aside={<Switch horizontal inverse model="data.ciwa_symptoms.not_applicable" />}
          scrollview={{
            id: 'ciwa_symptoms',
            name: 'CIWA Symptoms',
          }}
          commentsModel="data.ciwa_symptoms"
        >
          <ContextHide when="data.ciwa_symptoms.not_applicable" is={true}>
            <CIWASymptoms />
          </ContextHide>
        </Section>

        <Divider />

        {/* Medication List */}
        <Section
          title="Medication List"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.medications.not_applicable" />}
          scrollview={{
            id: 'medications',
            name: 'Medication List',
          }}
          commentsModel="data.medications"
        >
          <ContextHide when="data.medications.not_applicable" is={true}>
            <Medications
              model="data.medications.meds"
              isEditable={isEditable}
              client={client}
              icon="medications"
              title="Medications"
              emptyDescription="No medications added yet"
            />
          </ContextHide>
        </Section>

        <Divider />

        {/* Diagnoses */}
        <Section
          title="Diagnoses"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.diagnoses.not_applicable" />}
          scrollview={{
            id: 'diagnoses',
            name: 'Diagnoses',
          }}
          commentsModel="data.diagnoses_section"
        >
          <ContextHide when="data.diagnoses.not_applicable" is={true}>
            <FormSection>
              <Diagnoses
                model="data.diagnoses.diagnoses"
                isEditable={isEditable}
                client={client}
                icon="diagnosis"
                title="Diagnoses"
                emptyDescription="No diagnoses added yet"
              />

              <YesNoRadioGroupWithTextarea
                label="Over the last 12 months, have substances been taken in larger amounts or over a longer period of time than intended?"
                model="data.diagnoses.did_take_substances_in_past_year"
              />

              <YesNoRadioGroupWithTextarea
                label="Over the last 12 months, has there been a persistent desire or unsuccessful efforts to cut down or control substance use?"
                model="data.diagnoses.did_try_substance_use_control_in_past_year"
              />

              <Input
                label="Number of times client has attempted to quit on their own:"
                model="data.diagnoses.number_quit_attempts"
                suffix="times"
                type="number"
                size={4}
              />

              <Input
                label="Total number of substance abuse admissions:"
                model="data.diagnoses.number_substance_abuse_admissions"
                suffix="admissions"
                type="number"
                size={4}
              />

              <Input
                label="Total number of psychiatric admissions:"
                model="data.diagnoses.number_psychiatric_admissions"
                suffix="admissions"
                type="number"
                size={4}
              />

              <Input
                label="Total number of eating disorder admissions:"
                model="data.diagnoses.number_eating_disorder_admissions"
                suffix="admissions"
                type="number"
                size={4}
              />

              <Grid gap="0.5rem">
                <Label label="Please check any that apply:" />

                <Checkbox
                  label="A large amount of time is spent to obtain substance, use substance or recover from the effects"
                  model="data.diagnoses.applicable.does_spend_large_time_on_substance_use"
                />

                <ContextShow when="data.diagnoses.applicable.does_spend_large_time_on_substance_use" is={true}>
                  <Textarea
                    label="Please give example:"
                    model="data.diagnoses.applicable.does_spend_large_time_on_substance_use_description"
                  />
                </ContextShow>

                <Checkbox
                  label="Client has craving or strong desire to use substance"
                  model="data.diagnoses.applicable.has_substance_craving"
                />

                <Checkbox
                  label="Substance use has resulted in failure to fulfill major role obligations at work, home or school"
                  model="data.diagnoses.applicable.did_fail_obligations_due_to_substance_use"
                />

                <ContextShow when="data.diagnoses.applicable.did_fail_obligations_due_to_substance_use" is={true}>
                  <Textarea
                    label="Please give example:"
                    model="data.diagnoses.applicable.did_fail_obligations_due_to_substance_use_description"
                  />
                </ContextShow>

                <Checkbox
                  label="Substance use continues in spite of persistent or recurring social or interpersonal problems caused or exacerbated by the effects of the substance"
                  model="data.diagnoses.applicable.does_continue_substance_use_despite_negatives"
                />

                <ContextShow when="data.diagnoses.applicable.does_continue_substance_use_despite_negatives" is={true}>
                  <Textarea
                    label="Please give example:"
                    model="data.diagnoses.applicable.does_continue_substance_use_despite_negatives_description"
                  />
                </ContextShow>

                <Checkbox
                  label="Continued use in dangerous situations or despite having knowledge of the consequences physically or psychologically"
                  model="data.diagnoses.applicable.does_continue_substance_use_despite_dangers"
                />

                <ContextShow when="data.diagnoses.applicable.does_continue_substance_use_despite_dangers" is={true}>
                  <CheckboxGroup label="The above is evidenced by the following (check any that apply):" layout="vertical-dense">
                    <Checkbox label="Overdose" model="data.diagnoses.applicable.dangers.overdose" />
                    <Checkbox label="Intravenous drug use" model="data.diagnoses.applicable.dangers.intravenous_drug_use" />
                    <Checkbox
                      label="Shared needles or paraphernalia"
                      model="data.diagnoses.applicable.dangers.shared_needles_or_paraphernalia"
                    />
                    <Checkbox
                      label="Abscesses or other infections"
                      model="data.diagnoses.applicable.dangers.abscesses_or_other_infections"
                    />
                    <Checkbox label="Unsafe sex" model="data.diagnoses.applicable.dangers.unsafe_sex" />
                    <Checkbox label="Criminal activity" model="data.diagnoses.applicable.dangers.criminal_activity" />
                    <Checkbox label="Drive impaired" model="data.diagnoses.applicable.dangers.drive_impaired" />
                    <Checkbox label="HIV" model="data.diagnoses.applicable.dangers.hiv" />
                    <Checkbox label="Hepatitis C" model="data.diagnoses.applicable.dangers.hepatitis_c" />
                    <Checkbox label="Seizure" model="data.diagnoses.applicable.dangers.seizure" />
                    <Checkbox label="Blackouts" model="data.diagnoses.applicable.dangers.blackouts" />
                    <Checkbox label="Liver disease" model="data.diagnoses.applicable.dangers.liver_disease" />
                    <Checkbox label="Tremors" model="data.diagnoses.applicable.dangers.tremors" />
                    <Checkbox label="Anxiety" model="data.diagnoses.applicable.dangers.anxiety" />
                    <Checkbox label="Depression" model="data.diagnoses.applicable.dangers.depression" />
                    <Checkbox label="Other" model="data.diagnoses.applicable.dangers.other" />
                  </CheckboxGroup>

                  <ContextShow when="data.diagnoses.applicable.dangers.other" is={true}>
                    <Textarea label="Other:" model="data.diagnoses.applicable.dangers.other_extra" />
                  </ContextShow>
                </ContextShow>
              </Grid>
            </FormSection>
          </ContextHide>
        </Section>

        <Divider />

        {/* Summary */}
        <Section
          title="Summary"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
          scrollview={{
            id: 'summary',
            name: 'Summary',
          }}
          commentsModel="data.summary"
        >
          <ContextHide when="data.summary.not_applicable" is={true}>
            <Textarea label="Assessment Summary" model="data.summary.description" />
          </ContextHide>
        </Section>
      </>
    )}
  </DataFormOverlay>
)

export const HistoryAndPhysicalOverlay = withOverlayError(RootHistoryAndPhysicalOverlay)
