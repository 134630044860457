import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { Text, Ol, Li } from '../../components/Typography'
import AccordionAlert from '../../components/AccordionAlert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Field from '../../components/Forms/Field'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SignaturePad from '../../components/Forms/SignaturePad'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import Switch from '../../components/Forms/Switch'
import Timeline from '../../components/Timeline/Timeline'

import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'

const RootVitalSignsOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'vital-signs',
    endpoint: '/vital_signs',
    invalidate: 'vital-signs',
    options: props,
  })

  const { isPortal, tenant, timezone } = useSettings()

  const isTrialing = tenant?.plan_status === 'trialing'

  if (isOverlayLoading) {
    return <OverlayLoader position="right" showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} />
  }

  return (
    <Overlay showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} onClose={close}>
      <Overlay.Header icon="test_results" title="Vital Signs" help={<HelpTagIframe id="test_results_vital_signs" />} />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/vital_signs/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          key={`updated-${data?.updated_at}`}
        >
          <Section>
            <FormSection>
              <OverlaySelector
                initialModelRequired={isNew}
                model="client"
                label="Client"
                type="clients.current"
                icon="clients"
                selectTitle={(data) => data.name}
                selectDescription={(data) => data.behave_id}
                disableLink={isPortal}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
              />

              <DateTimeInput label="Date & Time" model="recorded_at" defaultToNow />

              <FormFutureDateWarning dateLabel="Date & Time" model="recorded_at" />

              <RadioGroup
                model="status"
                label="Status"
                layout="vertical-dense"
                validations={{
                  presence: {
                    message: 'Please select a status',
                  },
                }}
              >
                <Radio label="Collected" value="collected" />
                <Radio label="Not Collected" value="refused" />
              </RadioGroup>

              <ContextShow when="status" is="collected">
                <RadioGroup model="assessment_type" label="Assessment Type" layout="horizontal-dense" defaultValue="normal">
                  <Radio label="Normal" value="normal" />
                  <Radio label="Orthostatic" value="orthostatic" />
                </RadioGroup>

                <ContextShow when="assessment_type" is="normal">
                  <Grid horizontal gap="1rem">
                    <Field label="Blood Pressure" labelWidth="120px" labelAlign="right">
                      <Flex gap={8} alignItems="center">
                        <Input type="number" model="blood_pressure_systolic" size={4} />
                        <span>/</span>
                        <Input type="number" model="blood_pressure_diastolic" suffix="mm Hg" size={4} />
                      </Flex>
                    </Field>

                    <Input label="Pulse" type="number" model="pulse" suffix="beats / min" size={4} />
                  </Grid>
                </ContextShow>

                <ContextShow when="assessment_type" is="orthostatic">
                  <FormSection>
                    <AccordionAlert glyph="info" header="How do I run an Orthostatic Assessment?" isOpen={false}>
                      <Ol>
                        <Li>Have the patient lie down for 5 minutes</Li>
                        <Li>Measure blood pressure and pulse</Li>
                        <Li>Have the patient stand</Li>
                        <Li>Repeat blood pressure and pulse rate measurements after standing 1 and 3 minutes</Li>
                      </Ol>

                      <div css={{ padding: '1rem 1rem 0.5rem 1.5rem', fontStyle: 'italic' }}>
                        A drop in BP of ≥20 mm Hg, or in diastolic BP of ≥10 mm Hg, or experiencing lightheadedness or dizziness is
                        considered abnormal
                      </div>
                    </AccordionAlert>

                    <Grid gap="0.15rem">
                      <strong>Measurements after Lying down for 5 minutes</strong>

                      <Grid horizontal gap="1rem">
                        <Field label="Blood Pressure" labelWidth="120px" labelAlign="right">
                          <Flex gap={8} alignItems="center">
                            <Input type="number" model="blood_pressure_systolic_lying_down" size={4} />
                            <span>/</span>
                            <Input type="number" model="blood_pressure_diastolic_lying_down" suffix="mm Hg" size={4} />
                          </Flex>
                        </Field>

                        <Input label="Pulse" type="number" model="pulse_lying_down" suffix="beats / min" size={4} />
                      </Grid>
                    </Grid>

                    <Grid gap="0.15rem">
                      <strong>Measurements after Standing for 1 minute</strong>

                      <Grid horizontal gap="1rem">
                        <Field label="Blood Pressure" labelWidth="120px" labelAlign="right">
                          <Flex gap={8} alignItems="center">
                            <Input type="number" model="blood_pressure_systolic_standing_1_minute" size={4} />
                            <span>/</span>
                            <Input type="number" model="blood_pressure_diastolic_standing_1_minute" suffix="mm Hg" size={4} />
                          </Flex>
                        </Field>

                        <Input label="Pulse" type="number" model="pulse_standing_1_minute" suffix="beats / min" size={4} />
                      </Grid>
                    </Grid>

                    <Grid gap="0.15rem">
                      <strong>Measurements after Standing for 3 minutes</strong>

                      <Grid horizontal gap="1rem">
                        <Field label="Blood Pressure" labelWidth="120px" labelAlign="right">
                          <Flex gap={8} alignItems="center">
                            <Input type="number" model="blood_pressure_systolic_standing_3_minute" size={4} />
                            <span>/</span>
                            <Input type="number" model="blood_pressure_diastolic_standing_3_minute" suffix="mm Hg" size={4} />
                          </Flex>
                        </Field>

                        <Input label="Pulse" type="number" model="pulse_standing_3_minute" suffix="beats / min" size={4} />
                      </Grid>
                    </Grid>
                  </FormSection>
                </ContextShow>

                <Input label="Temp." type="number" model="temperature" suffix="°F" size={4} />

                <Input label="O2 Sat" type="number" model="oxygen_saturation" suffix="%" size={4} />

                <Input label="Respiration" type="number" model="respiration" suffix="breaths / min" size={4} />

                <Input label="Pain Scale" type="number" model="pain_scale" suffix="/ 10" step={1} min={0} max={10} size={4} />

                <FormSection horizontal>
                  <Input label="Weight" model="weight" suffix="pounds" size={4} />

                  <Field label="Height" labelWidth="120px" labelAlign="right" className="!flex-auto">
                    <Flex gap={8} alignItems="center">
                      <Input type="number" model="height_ft" suffix="ft" size={4} />
                      <Input type="number" model="height_in" suffix="in" size={4} />
                    </Flex>
                  </Field>
                </FormSection>
              </ContextShow>

              <SmartTextarea useDictation useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          {tenant?.is_billed && (
            <>
              <Section
                headingType="h2"
                title="Billing"
                description="Use this section to record the billable service"
                aside={<Switch defaultValue={false} model="is_billable" />}
              >
                <ContextShow when="is_billable" is={true}>
                  <FormSection maxWidth="100%">
                    <ObjectSelector
                      dependent="client_id"
                      model="insurance_service"
                      label="Rendered Insurance Service"
                      type="client.insurance_services"
                      icon="billing"
                      searchKeys={['code', 'short_description', 'long_description']}
                      selectTitle={(o: any) => `${o?.code} - ${o?.short_description}`}
                      selectDescription={(o: any) => o?.long_description}
                      groupBy={{
                        accessor: 'service_type',
                        sections: {
                          in_network: 'In Network',
                          out_of_network: 'Out of Network',
                          global_oon: 'Global Out of Network',
                        },
                      }}
                      validations={{
                        presence: {
                          message: 'Please select a service',
                        },
                      }}
                    />

                    <SmartTextarea useDictation useQuickText model="billing_notes" label="Notes" />
                  </FormSection>
                </ContextShow>
              </Section>

              <Divider />
            </>
          )}

          <Section headingType="h2" title="Attachments" description="Upload any files related to this Vital Signs record">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          <Divider />

          <Section
            headingType="h2"
            title="Signatures"
            description="Use this section to record signatures"
            aside={<Switch defaultValue={false} model="is_signable" />}
          >
            <ContextShow when="is_signable" is={true}>
              <FormSection layout="vertical" className="!pt-4">
                <SignaturePad
                  showSignedBy={false}
                  label="Client Signature"
                  model="client_signature"
                  signedAtModel="client_signed_at"
                  allowPin={false}
                  showAccept={false}
                />

                <SignaturePad
                  allowPin
                  person={data?.signed_by}
                  label="Staff Signature"
                  model="employee_signature"
                  signedAtModel="author_signed_at"
                  showAccept={false}
                />
              </FormSection>
            </ContextShow>
          </Section>

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} disableLinks={isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {!isPortal && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save Vital Signs"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission="test_results.vital_signs.create"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label="Edit Vital Signs"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="test_results.vital_signs.edit"
              />

              <DeleteDialog
                title="Delete Vital Signs?"
                message="Are you sure you want to delete this Vital Signs record? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="test_results.vital_signs.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const VitalSignsOverlay = withOverlayError(RootVitalSignsOverlay)
