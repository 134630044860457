import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { Button, Card, PageHeader } from '@behavehealth/components'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { ClientJourneyTimeline } from '@behavehealth/constructs/ClientsJourney/ClientJourneyTimeline'

const RootClientJourneyPage = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const [dates, setDates]: any = React.useState({})
  const [filters, setFilters] = React.useState({})

  const hasDates = !!dates?.startDate && !!dates?.endDate

  const queryParams = {
    filters,
    ...(hasDates && {
      from: dates.startDate,
      until: dates.endDate,
    }),
  }

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const { data, isLoading, isRefetching }: any = useGet({
    name: ['client', resource_id, 'client-journey', queryParams],
    url: `/residents/${resource_id}/client_journey`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: hasDates },
  })

  return (
    <div css={styles.root}>
      <PageHeader showFeedbackTool title="Client Journey" icon="client_journey" />

      <main css={styles.main}>
        <ClientJourneyTimeline
          data={data}
          client={client}
          dates={dates}
          setDates={setDates}
          isLoading={isLoading}
          isRefetching={isRefetching}
        />
      </main>
    </div>
  )
}

const styles: any = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    // overflowY: 'hidden',
  },

  main: {
    display: 'grid',
    padding: '1rem',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    overflow: 'hidden',
  },
}

export const ClientJourneyPage = withPageError(RootClientJourneyPage)
