import React from 'react'
import size from 'lodash/size'

import { apiGet } from '../../../modules/api'

import Alert from '../../../components/Alert'
import Card from '../../../components/Card'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Loader from '../../../components/Loader'
import Workflow from '../../../components/Workflow/Workflow'

const StaffAccessLists = (props: any) => {
  const { client, data, setData, setStatus } = props

  const form = React.useRef()
  const { goNext }: any = React.useContext(Workflow.Context)

  const [loading, setLoading] = React.useState(false)
  const [accessControlStaff, setAccessControlStaff] = React.useState(null)
  const [propertyStaff, setPropertyStaff] = React.useState(null)

  const currentProperty = props.currentProperty || client?.current_bed_occupancy?.place?.house
  const newProperty = props.newProperty || (!data?.skip_new_occupancy && data?.place?.house)

  const showRemove = (newProperty && newProperty.id !== currentProperty?.id) || data.skip_new_occupancy === true
  const showAdd = !!newProperty

  const hasAccessStaffToRemove = size(accessControlStaff)
  const hasPropertyStaff = size(propertyStaff)

  const handleContinue = async () => {
    setStatus('completed')
    goNext()
  }

  const handleSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    const findAccessControlStaff = async () => {
      setLoading(true)

      try {
        const results = await apiGet({ url: `/residents/${client?.id}/access_employees` })
        setAccessControlStaff(results?.data?.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (showRemove) findAccessControlStaff()
  }, [showRemove])

  React.useEffect(() => {
    const findPropertyAssignedStaff = async () => {
      setLoading(true)

      try {
        const results = await apiGet({ url: `/houses/${newProperty?.id}` })
        setPropertyStaff(results?.data?.data?.employees)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (newProperty?.id) findPropertyAssignedStaff()
  }, [newProperty?.id])

  if (!client || !data) return null

  return (
    <Form useLocalModel getForm={form} initialModel={data} onUpdate={setData}>
      <FormSection maxWidth="100%">
        {!newProperty && (
          <Alert glyph="warning" type="warning">
            Select a property first to manage Staff Access Lists.
          </Alert>
        )}

        {loading ? (
          <Card className="!p-4">
            <Loader centerX centerY />
          </Card>
        ) : (
          <>
            {showRemove && (
              <>
                {hasAccessStaffToRemove ? (
                  <CheckboxGroup
                    withToggle
                    label={`Remove ${client?.first_name} from the Access Lists of the following Staff:`}
                    layout="vertical-dense"
                  >
                    {accessControlStaff?.map?.((staff: any) => (
                      <Checkbox key={staff?.id} label={staff?.name} model={`staff_access_list.remove.${staff?.id}`} />
                    ))}
                  </CheckboxGroup>
                ) : (
                  <Alert glyph="info">
                    There are no Staff Access Lists to update for <strong>{client?.first_name}</strong>
                  </Alert>
                )}
              </>
            )}

            {showAdd && (
              <>
                {hasPropertyStaff ? (
                  <CheckboxGroup
                    withToggle
                    label={`Add ${client.first_name} to the Access Lists of the following Staff:`}
                    layout="vertical-dense"
                  >
                    {propertyStaff?.map?.((staff: any) => (
                      <Checkbox key={staff?.id} label={staff?.name} model={`staff_access_list.add.${staff?.id}`} />
                    ))}
                  </CheckboxGroup>
                ) : (
                  <Alert glyph="info">
                    <strong>{newProperty?.name}</strong> has no Assigned Staff. To be able to manage Staff Access Lists, please assign staff
                    members to the property first.
                  </Alert>
                )}
              </>
            )}
          </>
        )}

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={handleContinue} />
          <Workflow.SkipButton onClick={handleSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default StaffAccessLists
