import React from 'react'
import startCase from 'lodash/startCase'

import { titleCase } from '../../utils/functions'

import Button from '../../components/Button'
import FormSection from '../../components/Forms/FormSection'
import Form from '../../components/Forms/Form'
import Overlay from '../../components/Overlay'
import Grid from '../../components/Grid'
import Section from '../../components/Section'

import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'

import RadioGroup from '../../components/Forms/RadioGroup'
import Radio from '../../components/Forms/Radio'

import Link from '../../components/Link'
import Icon from '../../components/Icon'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import Divider from '../../components/Divider'
import Label from '../../components/Label'
import ContextShow from '../../components/Forms/ContextShow'
import Input from '../../components/Forms/Input'
import { Text } from '../../components/Typography'

import { ICONS } from '../../theme'

import { useUpdate } from '../../hooks/useNewAPI'

const ORGANIZATION_TYPES = {
  vendor: 'Vendor',
  resource: 'Community Resource',
  provider: 'Provider',
}

const EditOrganizationPaymentMethodOverlay = ({ organization, paymentMethod, onClose }: any) => {
  const closeOverlay = async () => {
    if (onClose) onClose()
  }

  const [valid, setValid] = React.useState(false)
  const [data, setData] = React.useState(null)

  const { mutateAsync: updatePaymentMethod, isLoading } = useUpdate({
    name: ['organization', organization.id, 'payment_methods'],
    url: `/payment_methods/${paymentMethod.id}`,
    invalidate: 'payment_methods',
  })

  const cardIcon = ICONS[paymentMethod.service_details?.brand] || ICONS.billing

  const update = async () => {
    await updatePaymentMethod(data)
    await closeOverlay()
  }

  return (
    <Overlay showBackdrop position="center" onClose={closeOverlay} maxWidth={35}>
      <Overlay.Header title="Edit Payment Method" glyph="dollar" />
      <Overlay.Content>
        <Form
          initialModel={paymentMethod}
          onValidationUpdate={setValid}
          onUpdate={(data: any) => {
            setData(data)
          }}
        >
          <Section>
            <FormSection>
              <Input
                label="Payment Method Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please add a Payment Method name',
                  },
                }}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section>
            <FormSection>
              <RadioGroup
                label="Who is the holder of this Payment Method?"
                model="reference_category"
                layout="horizontal-dense"
                defaultValue="organization"
              >
                <Radio label="Organization" value="organization" />
                <Radio label="Contact" value="contact" />
              </RadioGroup>

              <ContextShow when="reference_category" is="organization">
                <ObjectSelector
                  isPolymorphic
                  isEditable={false}
                  icon="organizations"
                  label="Organization"
                  model="reference"
                  type="organizations"
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => ORGANIZATION_TYPES[data?.category]}
                />
              </ContextShow>

              <ContextShow when="reference_category" is="contact">
                <ObjectSelector
                  isPolymorphic
                  icon="contacts"
                  label="Contact"
                  model="reference"
                  type="organization.contacts"
                  description={
                    <>
                      Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                    </>
                  }
                  dependentValue={organization?.id}
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => titleCase(data?.relationship)}
                  validations={{
                    presence: {
                      message: 'Please select a Contact',
                    },
                  }}
                />
              </ContextShow>
            </FormSection>
          </Section>

          <Divider />

          <Section>
            <FormSection>
              <RadioGroup isEditable={false} label="Payment Method Type" model="category" layout="horizontal-dense" defaultValue="card">
                <Radio label="Card" value="card" />
                <Radio label="Bank Account (ACH)" value="ach" />
              </RadioGroup>

              {paymentMethod?.category === 'card' && (
                <>
                  <Grid gap="0.5rem">
                    <Label label="Card Details" />

                    <Card>
                      <CardHeader graphic={<Icon icon={cardIcon} />}>
                        {paymentMethod.status === 'processing' && <Text>We are currently processing this payment method</Text>}
                        {paymentMethod.status === 'active' && (
                          <Text>
                            {startCase?.(paymentMethod?.service_details?.brand)} **** {paymentMethod?.service_details?.last4}, Exp{' '}
                            {paymentMethod?.service_details?.exp_month}/{paymentMethod?.service_details?.exp_year}
                          </Text>
                        )}
                      </CardHeader>
                    </Card>
                  </Grid>
                </>
              )}

              <Button
                label="Update Payment Method"
                glyph="check"
                color="green"
                type="primary"
                onClick={update}
                isLoading={isLoading}
                isDisabled={!valid || isLoading}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    </Overlay>
  )
}

export default EditOrganizationPaymentMethodOverlay
