import React from 'react'
import startCase from 'lodash/startCase'

import Card from '../../Card'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Table from '../../Table/Table'
import TableCell from '../../Table/TableCell'

import EmployeeQuickView from '../../Overlays/quick/EmployeeQuickView'

const columns = [
  {
    width: 160,
    disableFilters: true,
    id: 'quick_view',
    accessor: 'id',
    Header: 'Quick View',
    Cell: ({ row }: any) => (
      <TableCell.QuickViewOverlay overlay={<EmployeeQuickView employee={row.original} />}>
        <TableCell.MainLink label={row.original.name} avatar={row.original.avatar} glyphAfter="quick_view" />
      </TableCell.QuickViewOverlay>
    ),
  },
  {
    width: 160,
    accessor: 'position',
    Header: 'Position',
    Cell: ({ value }: any) => startCase(value),
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 160,
    accessor: 'email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
]

const CompanyChannelOverlay = ({ channel, onClose }: any) => (
  <Overlay showBackdrop onClose={onClose}>
    <Overlay.Header icon="company_chat" title={channel?.name} />

    <Overlay.Content>
      <Section>
        <Card>
          <Table title="Channel Members" icon="employees" data={channel?.members} columns={columns} />
        </Card>
      </Section>
    </Overlay.Content>
  </Overlay>
)

export default CompanyChannelOverlay
