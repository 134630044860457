import React from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import Glyph from './Glyph'
import Button from './Button'
import Flex from './Flex'

import LogRocket from '../vendors/logrocket'
import withSettings from '../hocs/withSettings'

export const FallbackComponent = ({ user, tenant, onLeave, showHomeButton = true }: any) => (
  <Flex vertical centerX centerY css={styles}>
    <Glyph glyph="circle_error" className="!w-8 !h-8" />
    <h2 data-test="error_component">Oops, something went wrong…</h2>
    <p className="!max-w-[26rem]">You can get in touch and let us know how this happened, or click the link below to go back</p>
    <Flex vertical gap={8}>
      <Button
        color="red"
        label="Let us know what happened"
        glyph="email"
        href={`mailto:support@behavebilling.com?subject=I%20found%20a%20bug!&body=Hey%20Behave%20Health%2C%0A%0AIt%20seems%20that%20something%20broke%20while%20I%20was%20on%20${encodeURIComponent(
          window.location.href,
        )}%20%28tenant%3A%20${
          tenant?.subdomain
        }%29.%20%0A%0AI%20look%20forward%20to%20hearing%20back%20from%20you%20when%20the%20issue%20has%20been%20solved.%0A%0AAll%20the%20best%2C%0A${
          user?.first_name
        }`}
      />

      {showHomeButton && <Button label="Go back to the Dashboard" glyph="long_arrow_left" type="link" onClick={onLeave} />}
    </Flex>
  </Flex>
)

const ErrorBoundary = ({ user, tenant, children, fallback }: any) => {
  const history = useHistory()

  const defaultFallback = ({ resetError }: any) => (
    <FallbackComponent
      user={user}
      tenant={tenant}
      onLeave={() => {
        history.push('/dashboard')
        resetError()
      }}
    />
  )

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope: any) => {
        if (LogRocket.getRocket()) {
          scope.setContext('sessionURL', LogRocket.getRocket().sessionURL)
        }
      }}
      fallback={fallback || defaultFallback}
      onError={(error: any) => {
        console.error(error)
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

const styles = {
  textAlign: 'center',
  padding: '0 1.5rem',
  minHeight: '12rem',

  position: 'absolute',
  top: '0',
  right: '0',
  width: '100%',
  height: '100%',
}

export default withSettings(ErrorBoundary)
