import React from 'react'
import { Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '../../components/AnimatedRoutes'
import { FormSubmissionOverlay } from './FormSubmissionOverlay'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'

export const FormSubmissionOverlayRoutes = () => {
  const { url } = useRouteURL()

  return (
    <AnimatedRoutes>
      <Route path="new/:form_id" element={<FormSubmissionOverlay useV6Router back={url} />} />
      <Route path=":id" element={<FormSubmissionOverlay useV6Router back={url} />} />
    </AnimatedRoutes>
  )
}
