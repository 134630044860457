import React from 'react'

import { FEATURES } from '../../theme'
import { titleCase } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import ScheduledReportStatus from '../Statuses/ScheduledReportStatus'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'
import SummonOverlay from '../SummonOverlay'

import { ScheduledExcelReportOverlay } from '../../constructs/ExcelReports/ScheduledExcelReportOverlay'

const HOURS = {
  //generate enumeration of each 24 hour based list to am/pm
  0: '12:00 AM',
  1: '1:00 AM',
  2: '2:00 AM',
  3: '3:00 AM',
  4: '4:00 AM',
  5: '5:00 AM',
  6: '6:00 AM',
  7: '7:00 AM',
  8: '8:00 AM',
  9: '9:00 AM',
  10: '10:00 AM',
  11: '11:00 AM',
  12: '12:00 PM',
  13: '1:00 PM',
  14: '2:00 PM',
  15: '3:00 PM',
  16: '4:00 PM',
  17: '5:00 PM',
  18: '6:00 PM',
  19: '7:00 PM',
  20: '8:00 PM',
  21: '9:00 PM',
  22: '10:00 PM',
  23: '11:00 PM',
}

const columns = (to: Function = () => {}, mainLinkAs, timezone: string) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink as={mainLinkAs} label={value} to={to(row.original.id)} />,
  },
  {
    width: 140,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <ScheduledReportStatus status={value} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
    ],
  },
  {
    width: 200,
    accessor: 'frequency',
    Header: 'Frequency',
    Cell: ({ value }: any) => (value ? titleCase(value) : <TableCell.Empty />),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'day_of_week',
    Header: 'Day to Run',
    Cell: ({ value }: any) => (value ? titleCase(value) : <TableCell.Empty />),
  },
  {
    width: 200,
    accessor: 'hour',
    Header: 'Time to Run',
    Cell: ({ value }: any) => (value ? HOURS[value] : <TableCell.Empty />),
  },
  {
    width: 200,
    accessor: 'last_run_at',
    Header: 'Last Run Date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  // {
  //   width: 200,
  //   accessor: 'next_run_at',
  //   Header: 'Next Run Date',
  //   Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  //   Filter: TableFilter.Date,
  //   filter: 'date',
  //   type: 'date',
  // },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Requested At',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Requested By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  isLoading: boolean
  localStorageKey: string
  timezone: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const ScheduledReportsTable = ({
  to,
  data,
  isLoading,
  emptyActions,
  batchActionsConfig,
  localStorageKey,
  timezone,
  titleAfter,
  mainLinkAs,
}: Props) => {
  return (
    <Table
      title="Scheduled Reports"
      titleAfter={titleAfter}
      icon={FEATURES.live_reports.icon}
      data={data}
      columns={columns(to, mainLinkAs, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No scheduled reports created yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

ScheduledReportsTable.defaultProps = {
  localStorageKey: 'scheduled_reports',
}

export default withSettings(ScheduledReportsTable)
