import React from 'react'

import { withFormContext } from '../../../../components/Forms/context'

import Flex from '../../../../components/Flex'
import Input from '../../../../components/Forms/Input'
import Select from '../../../../components/Forms/Select'
import Option from '../../../../components/Forms/Option'
import Textarea from '../../../../components/Forms/Textarea'

import { SectionCard } from '../../../../components/SectionCard'

const ClaimTemplateDetails = () => {
  return (
    <SectionCard title="Template Details">
      <Flex gap="1rem">
        <Input
          label="Template Name"
          model="name"
          validations={{
            presence: {
              message: 'Please enter the Template Name',
            },
          }}
        />
        <Select label="Status" model="status">
          <Option label="Active" value="active" />
          <Option label="Archived" value="archived" />
        </Select>

        <Textarea label="Internal Notes" model="internal_notes" />
      </Flex>
    </SectionCard>
  )
}

export default withFormContext(ClaimTemplateDetails)
