import React from 'react'
import { DateTime } from 'luxon'
import { keyframes } from '@emotion/react'
import { transparentize, lighten } from 'polished'
import DatePicker, { registerLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-US'
import rangeRight from 'lodash/rangeRight'

import 'react-datepicker/dist/react-datepicker.min.css'

import { COLORS, SHADOW } from '../../theme'
import { MONTHS } from '../../utils/constants'

import Button from '../Button'
import Flex from '../Flex'
import Grid from '../Grid'
import Option from './Option'
import Select from './Select'

import withSettings from '../../hocs/withSettings'

const DatePickerSelectorButton = ({ value, onClick }: any) => (
  <Button hideLabel glyph="date" glyphSize="26" type="link" onClick={onClick} css={buttonStyles} />
)

const DatePickerHeader = ({
  changeMonth,
  changeYear,
  date,
  decreaseMonth,
  firstYear,
  futureYears,
  increaseMonth,
  pastYears,
  yearInterval,
}) => {
  const currentYear = new Date().getFullYear()

  let yearList = rangeRight(firstYear || currentYear - pastYears, currentYear + futureYears + yearInterval, yearInterval)

  return (
    <Grid gap={5} columns="2rem auto auto 2rem">
      <Button type="link" glyph="chevron_left" onClick={decreaseMonth} css={monthStepperStyles} />

      <Select value={MONTHS[date.getMonth()]} onUpdate={({ value }: any) => changeMonth(MONTHS.indexOf(value))} defaultValue={MONTHS[0]}>
        {MONTHS.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>

      <Select value={date.getFullYear()} onUpdate={({ value }: any) => changeYear(value)}>
        {yearList.map((option) => (
          <Option key={option} label={option} value={option} />
        ))}
      </Select>

      <Button type="link" glyph="chevron" onClick={increaseMonth} css={monthStepperStyles} />
    </Grid>
  )
}

const DatePickerContainer = withSettings(({ timezone, children }: any) => {
  return (
    <Grid css={styles.main} className="date-picker-container">
      {children}
      <Flex css={styles.footer} centerX centerY gap={5}>
        <b>Timezone: </b> <p>{timezone}</p>
      </Flex>
    </Grid>
  )
})

const DatePickerSelector = ({
  date,
  pastYears,
  futureYears,
  yearInterval,
  onChange,
  weekStartsOn,
  firstYear,
  containerStyles,
  trigger,
  timezone,
  ...rest
}: any) => {
  // Attention: JS Date works with 0 based months
  const jsDate = date && date.isValid ? date.toJSDate() : null

  const locale = {
    ...en,
    options: {
      weekStartsOn,
    },
  }

  registerLocale('en-US', locale)

  return (
    <div
      css={{
        // overflow: 'hidden',
        // width: 30,

        '.react-datepicker-wrapper': {
          width: 'auto',
        },
      }}
    >
      <DatePicker
        selected={jsDate}
        onChange={onChange}
        customInput={trigger || <DatePickerSelectorButton />}
        renderCustomHeader={(props: any) => (
          <DatePickerHeader firstYear={firstYear} futureYears={futureYears} pastYears={pastYears} yearInterval={yearInterval} {...props} />
        )}
        calendarContainer={DatePickerContainer}
        portalId="portal-selector"
        locale="en-US"
        popperPlacement="bottom-end"
        popperModifiers={[
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'viewport',
            },
          },
        ]}
        {...rest}
      />
    </div>
  )
}

const datePickerMountAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-3px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const buttonStyles = {
  width: 45,
  paddingLeft: 0,

  // increase button hit area
  '&::before': {
    content: '""',
    position: 'absolute',
    width: 44,
    height: 44,
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },

  '@media (min-width: 380px)': {
    paddingLeft: '.2em',
  },
}

const monthStepperStyles = {
  '&, & *': {
    padding: 0,
    margin: 0,
  },
}

const styles = {
  main: {
    userSelect: 'none',
    fontSize: 14,
    background: COLORS.white,
    borderRadius: 5,
    boxShadow: `
			inset 0 0 0 1px ${transparentize(0.85, COLORS.gray)},
      ${SHADOW(1, transparentize(0.95, COLORS.gray))}`,

    gridTemplateColumns: 'auto auto',
    gridTemplateAreas: '". ." "footer footer"',

    animation: `${datePickerMountAnimation} 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)`,

    '.react-datepicker': {
      '&__day, &__day-name': {
        width: '2.5rem',
        height: '2.5rem',
        lineHeight: '2.5rem',
        fontSize: '1.1rem',

        '&:hover': {
          fontWeight: 600,
        },
      },

      '@media(min-width: 600px)': {
        '&__day, &__day-name': {
          width: '2.2rem',
          height: '2.2rem',
          lineHeight: '2.2rem',
          fontSize: '1rem',
        },
      },

      '&__timeContainer': {
        borderLeft: `1px solid ${COLORS.lightGray}`,
      },

      '&__header': {
        background: 'transparent',
        border: 0,
        borderRadius: 4,
      },

      '&__time-list-item': {
        paddingLeft: '0!important',
        paddingRight: '0!important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '27px!important',

        '&--selected': {
          backgroundColor: `${COLORS.blue}!important`,
        },
      },

      '&__today-button': {},

      '&__month-text': {
        padding: '0.25rem 0.75rem',
      },

      '&__month--selected': {
        background: COLORS.blue,
      },

      '&__day-name': {
        fontWeight: 600,
      },

      '&__week': {
        display: 'flex',
      },

      '&__day': {
        color: COLORS.black,
        outline: 0,
        borderRadius: 5,
        transition: 'transform 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',

        '&:hover': {
          transform: 'scale3d(1.04, 1.04, 1.04)',
        },

        '&--today': {
          color: COLORS.blue,
          boxShadow: 'none !important',
        },

        '&--outside-month': {
          border: '1px solid red',
          pointerEvents: 'none',
          visibility: 'hidden',
        },

        '&--disabled': {
          opacity: 0.5,
        },

        '&--in-range, &--in-selecting-range, &--in-selecting-range': {
          color: `${COLORS.white}!important`,
          background: `${COLORS.blue}!important`,

          '& .&--keyboard-selected': {
            color: `${COLORS.white}!important`,
            background: `${lighten(0.2, COLORS.blue)}!important`,
          },
        },

        '&--range-start': {
          color: COLORS.white,
          background: lighten(0.2, COLORS.blue),
          border: `1px solid ${COLORS.blue}`,
        },

        '&--selected': {
          color: COLORS.white,
          background: COLORS.blue,
          fontWeight: 600,
          boxShadow: `
            inset 0 0 0 1px ${transparentize(0.85, COLORS.gray)},
            ${SHADOW(1, transparentize(0.95, COLORS.gray))}`,

          '&:focus': {
            boxShadow: `
            inset 0 0 0 1px ${transparentize(0.65, COLORS.blue)},
            ${SHADOW(1, transparentize(0.95, COLORS.gray))}`,
          },
        },

        '&--keyboard-selected': {
          background: COLORS.white,
          boxShadow: `
            inset 0 0 0 1px ${transparentize(0.85, COLORS.gray)},
            ${SHADOW(1, transparentize(0.95, COLORS.gray))}`,

          '&:focus': {
            boxShadow: `
            inset 0 0 0 1px ${transparentize(0.65, COLORS.blue)},
            ${SHADOW(1, transparentize(0.95, COLORS.gray))}`,
          },
        },
      },
    },
  },
  footer: {
    gridArea: 'footer',
    padding: '.5rem 0',
    borderTop: `1px solid ${COLORS.lightGray}`,
  },
  headerButton: {
    paddingLeft: 0,
    paddingRight: 0,

    svg: {
      margin: 0,
    },
  },
}

export const DAYS = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

DatePickerSelector.defaultProps = {
  isEditable: true,
  date: DateTime.local(),
  timeIntervals: 15,
  futureYears: 2,
  pastYears: 5,
  yearInterval: 1,
  weekStartsOn: DAYS.MONDAY, // Week starts on Monday
}

export default DatePickerSelector
