import React from 'react'

import { COLORS, SHADOW } from '../theme'

import Glyph from './Glyph'

export const Tag: React.FC<any> = ({ data }) => {
  if (!data) return null

  return (
    <div key={data.id} css={styles.tag} style={{ '--color': data.color }}>
      <Glyph glyph={data.glyph} size={14} color={data.color} css={styles.glyph} />
      <div css={styles.tagName}>{data.name}</div>
    </div>
  )
}

const styles = {
  tag: {
    position: 'relative',
    display: 'inline-flex',
    flexWrap: 'nowrap',
    borderRadius: 100,
    border: `1px solid ${COLORS.divider}`,
    background: COLORS.white,
    boxShadow: SHADOW(1),
    minHeight: '1.5rem',
    paddingLeft: '0.3rem',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '0.8rem',
    lineHeight: 'normal',

    '& .close-button': {
      display: 'none',
      pointerEvents: 'none',

      borderLeft: `1px solid ${COLORS.divider}`,
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      width: '1.75rem',
      alignSelf: 'stretch',

      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      background: COLORS.white,

      '&:hover': {
        svg: { fill: COLORS.red },
      },
    },

    '&:hover .close-button': {
      display: 'flex',
    },
  },

  tagName: {
    paddingRight: '0.4rem',
    whiteSpace: 'nowrap',
  },

  glyph: {
    marginRight: '0.25rem',
  },
}
