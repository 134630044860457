import React from 'react'

import { usDateTime, daysBetween, countWord } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { Text } from '../../components/Typography'
import Attachments from '../../components/Forms/Attachments'
import Checkbox from '../../components/Forms/Checkbox'
import ContextHide from '../../components/Forms/ContextHide'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Section from '../../components/Section'
import Switch from '../../components/Forms/Switch'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import { DataFormOverlay } from '../DataFormOverlay'

const RootCaseManagementChecklistOverlay = (props: any) => {
  const { timezone } = useSettings()

  return (
    <DataFormOverlay
      minimizeEnabled
      title="Case Management Checklist"
      category="recovery_coaching"
      subcategory="case_management_checklist"
      icon="recovery_coaching"
      signoffWithoutSupervisor={true}
      requireSupervisor={false}
      requestClientSignature={false}
      requestAuthorSignature={false}
      requestSupervisorSignature={false}
      enableTreatmentPlanSelector
      {...props}
    >
      {({ client }) => (
        <>
          <Section title="Current Treatment Episode Details">
            <FormSection layout="vertical" maxWidth="100%">
              <Text>Admit Date: {usDateTime(client?.current_admission?.admitted_at, timezone)}</Text>
              <Text>Estimated Discharge Date: {usDateTime(client?.current_admission?.estimated_discharge, timezone)}</Text>
              <Text>
                Estimated Length of Stay:{' '}
                {countWord(
                  'days',
                  daysBetween(client?.current_admission?.admitted_at, client?.current_admission?.estimated_discharge, timezone),
                )}
              </Text>
            </FormSection>
          </Section>

          <Divider />

          <Section
            title="12 Step Recovery Program"
            aside={<Switch horizontal inverse model="data.twelve_step_recovery_program.not_applicable" />}
            scrollview={{
              id: 'twelve_step_recovery_program',
              name: '12 Step Recovery Program',
            }}
            commentsModel="data.twelve_step_recovery_program"
          >
            <ContextHide when="data.twelve_step_recovery_program.not_applicable" is={true}>
              <FormSection layout="vertical" maxWidth="100%">
                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="What step is the client currently on"
                  model="data.twelve_step_recovery_program.step"
                />
                <ContextShow when="data.twelve_step_recovery_program.step" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.twelve_step_recovery_program.step_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Where is the client's home group"
                  model="data.twelve_step_recovery_program.home_group"
                />
                <ContextShow when="data.twelve_step_recovery_program.home_group" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.twelve_step_recovery_program.home_group_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Where is the client's service commitment"
                  model="data.twelve_step_recovery_program.service_commitment"
                />
                <ContextShow when="data.twelve_step_recovery_program.service_commitment" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.twelve_step_recovery_program.service_commitment_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Who is the client sponsor or sponsees"
                  model="data.twelve_step_recovery_program.sponsor_sponsees"
                />
                <ContextShow when="data.twelve_step_recovery_program.sponsor_sponsees" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.twelve_step_recovery_program.sponsor_sponsees_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="How is the client's meeting attendance"
                  model="data.twelve_step_recovery_program.meeting_attendance"
                />
                <ContextShow when="data.twelve_step_recovery_program.meeting_attendance" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.twelve_step_recovery_program.meeting_attendance_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Does the client have a sober support group"
                  model="data.twelve_step_recovery_program.sober_support_group"
                />
                <ContextShow when="data.twelve_step_recovery_program.sober_support_group" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.twelve_step_recovery_program.sober_support_group_extra" />
                </ContextShow>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Employment"
            aside={<Switch horizontal inverse model="data.employment.not_applicable" />}
            scrollview={{
              id: 'employment',
              name: 'Employment',
            }}
            commentsModel="data.employment"
          >
            <ContextHide when="data.employment.not_applicable" is={true}>
              <FormSection layout="vertical" maxWidth="100%">
                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Where is the client currently employed"
                  model="data.employment.employment"
                />
                <ContextShow when="data.employment.employment" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.employment.employment_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="What is the client's hourly wage"
                  model="data.employment.hourly_wage"
                />
                <ContextShow when="data.employment.hourly_wage" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.employment.hourly_wage_extra" />
                </ContextShow>

                <Checkbox falseIcon="cross" falseStyle="faded" label="When is the client's pay day" model="data.employment.pay_day" />
                <ContextShow when="data.employment.pay_day" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.employment.pay_day_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Does the client enjoy working"
                  model="data.employment.enjoy_working"
                />
                <ContextShow when="data.employment.enjoy_working" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.employment.enjoy_working_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="How is the client's work performance"
                  model="data.employment.work_performance"
                />
                <ContextShow when="data.employment.work_performance" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.employment.work_performance_extra" />
                </ContextShow>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Job Search"
            aside={<Switch horizontal inverse model="data.job_search.not_applicable" />}
            scrollview={{
              id: 'job_search',
              name: 'Job Search',
            }}
            commentsModel="data.job_search"
          >
            <ContextHide when="data.job_search.not_applicable" is={true}>
              <FormSection layout="vertical" maxWidth="100%">
                <Checkbox falseIcon="cross" falseStyle="faded" label="What is the client's job search plan" model="data.job_search.plan" />
                <ContextShow when="data.job_search.plan" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.job_search.plan_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Does the client require assistance creating a resume"
                  model="data.job_search.help_with_resume"
                />
                <ContextShow when="data.job_search.help_with_resume" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.job_search.help_with_resume_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="What is the client's motivation level for obtaining employment"
                  model="data.job_search.motivation"
                />
                <ContextShow when="data.job_search.motivation" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.job_search.motivation_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="What is the client's current progress on obtaining employment"
                  model="data.job_search.progress"
                />
                <ContextShow when="data.job_search.progress" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.job_search.progress_extra" />
                </ContextShow>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Relationships"
            aside={<Switch horizontal inverse model="data.relationships.not_applicable" />}
            scrollview={{
              id: 'relationships',
              name: 'Relationships',
            }}
            commentsModel="data.relationships"
          >
            <ContextHide when="data.relationships.not_applicable" is={true}>
              <FormSection layout="vertical" maxWidth="100%">
                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="How often does the client have family contact"
                  model="data.relationships.family_contact"
                />
                <ContextShow when="data.relationships.family_contact" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.relationships.family_contact_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Does the client have close friends outside of treatment"
                  model="data.relationships.close_friends"
                />
                <ContextShow when="data.relationships.close_friends" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.relationships.close_friends_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Does the client participate in a social life outside of treatment"
                  model="data.relationships.outside_social_life"
                />
                <ContextShow when="data.relationships.outside_social_life" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.relationships.outside_social_life_extra" />
                </ContextShow>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Recreation"
            aside={<Switch horizontal inverse model="data.recreation.not_applicable" />}
            scrollview={{
              id: 'recreation',
              name: 'Recreation',
            }}
            commentsModel="data.recreation"
          >
            <ContextHide when="data.recreation.not_applicable" is={true}>
              <FormSection layout="vertical" maxWidth="100%">
                <Checkbox falseIcon="cross" falseStyle="faded" label="What does the client do for fun" model="data.recreation.do_for_fun" />
                <ContextShow when="data.recreation.do_for_fun" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.recreation.do_for_fun_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="How does the client maintain work and life balance"
                  model="data.recreation.work_life_balance"
                />
                <ContextShow when="data.recreation.work_life_balance" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.recreation.work_life_balance_extra" />
                </ContextShow>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Budget"
            aside={<Switch horizontal inverse model="data.budget.not_applicable" />}
            scrollview={{
              id: 'budget',
              name: 'Budget',
            }}
            commentsModel="data.budget"
          >
            <ContextHide when="data.budget.not_applicable" is={true}>
              <FormSection layout="vertical" maxWidth="100%">
                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Is the client spending finances according to their budget"
                  model="data.budget.budgeting"
                />
                <ContextShow when="data.budget.budgeting" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.budget.budgeting_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Is the client responsibly saving finances for the future"
                  model="data.budget.saving"
                />
                <ContextShow when="data.budget.saving" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.budget.saving_extra" />
                </ContextShow>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Graduation Plan"
            aside={<Switch horizontal inverse model="data.graduation_plan.not_applicable" />}
            scrollview={{
              id: 'graduation_plan',
              name: 'Graduation Plan',
            }}
            commentsModel="data.graduation_plan"
          >
            <ContextHide when="data.graduation_plan.not_applicable" is={true}>
              <FormSection layout="vertical" maxWidth="100%">
                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="What is the client's plan for graduation"
                  model="data.graduation_plan.plan"
                />
                <ContextShow when="data.graduation_plan.plan" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.graduation_plan.plan_extra" />
                </ContextShow>

                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Does the client require any assistance in planning for graduation"
                  model="data.graduation_plan.help"
                />
                <ContextShow when="data.graduation_plan.help" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.graduation_plan.help_extra" />
                </ContextShow>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Weekly Schedules"
            aside={<Switch horizontal inverse model="data.weekly_schedules.not_applicable" />}
            scrollview={{
              id: 'weekly_schedules',
              name: 'Weekly Schedules',
            }}
            commentsModel="data.weekly_schedules"
          >
            <ContextHide when="data.weekly_schedules.not_applicable" is={true}>
              <FormSection layout="vertical" maxWidth="100%">
                <Checkbox
                  falseIcon="cross"
                  falseStyle="faded"
                  label="Check schedules to assure clients are scheduling their days to attain their goals"
                  model="data.weekly_schedules.scheduling"
                />
                <ContextShow when="data.weekly_schedules.scheduling" is={true}>
                  <SmartTextarea useDictation label="Explain:" model="data.weekly_schedules.scheduling_extra" />
                </ContextShow>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Next Steps"
            aside={<Switch horizontal inverse model="data.next_steps.not_applicable" />}
            scrollview={{
              id: 'next_steps',
              name: 'Next Steps',
            }}
            commentsModel="data.next_steps"
          >
            <ContextHide when="data.next_steps.not_applicable" is={true}>
              <FormSection layout="vertical" maxWidth="100%">
                <DateTimeInput defaultToNow model="data.next_steps.next_session_at" label="Next Session Date" />
                <SmartTextarea useDictation useQuickText label="Concerns" model="data.next_steps.concerns" />
                <DateTimeInput defaultToNow model="data.next_steps.concerns_followup_at" label="Concerns Follow-Up Date" />
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Summary"
            aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
            scrollview={{
              id: 'summary',
              name: 'Summary',
            }}
            commentsModel="data.summary"
          >
            <ContextHide when="data.summary.not_applicable" is={true}>
              <FormSection layout="vertical">
                <SmartTextarea useDictation label="Notes" model="data.summary.interpretive_summary" />
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Attachments"
            scrollview={{
              id: 'attachments',
              name: 'Attachments',
            }}
            commentsModel="data.attachments"
          >
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </>
      )}
    </DataFormOverlay>
  )
}

export const CaseManagementChecklistOverlay = withOverlayError(RootCaseManagementChecklistOverlay)
