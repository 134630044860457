import React from 'react'
import { keyframes } from '@emotion/react'
import { tint } from 'polished'
import clone from 'lodash/clone'
import Fuse from 'fuse.js'
import isEqual from 'react-fast-compare'
import startCase from 'lodash/startCase'

import { SHADOW, COLORS } from '../../../../theme'
import { mapToArray } from '../../../../utils/functions'

import Avatar from '../../../Avatar'
import Card, { CARD_CLASSES } from '../../../Card'
import CardHeader from '../../../CardHeader'
import CardSubtitle from '../../../CardSubtitle'
import CardTitle from '../../../CardTitle'
import Search from '../../../Search'
import State from '../../../State'

export default class SelectorMenu extends React.Component {
  constructor(props) {
    super(props)

    const data = mapToArray(props.data)

    let groupedData: any = {}
    if (props.groupBy) groupedData = this.groupData({ data: props.data, groupBy: props.groupBy })

    this.state = {
      origin: clone(props.data),
      search: '',
      data: data,
      groupedData: groupedData,
      searchResults: data,
      autoFocus: false,
    }

    this.fuseOptions = {
      shouldSort: true,
      keys: props.searchKeys,
      threshold: 0.4,
    }

    this.fuse = new Fuse(data, this.fuseOptions)
  }

  /*
    LIFECYCLE
  */
  componentDidUpdate = () => {
    const { data, groupBy } = this.props
    const { origin, search } = this.state

    if (!isEqual(data, origin)) {
      const arrayData = mapToArray(data)
      let results = arrayData

      this.fuse = new Fuse(arrayData, this.fuseOptions)

      if (search?.length > 0) {
        results = []
        const searchResults = this.fuse.search(search)
        for (let i = 0; i < searchResults.length; i++) results.push(searchResults[i].item)
      }

      let groupedData: any = {}
      if (groupBy) groupedData = this.groupData({ data, groupBy })

      this.setState({ origin: clone(data), data: arrayData, searchResults: results, groupedData: groupedData })
    }
  }

  handleKeyDown = (event) => {
    if (event.key === 'Escape' && this.props.close) {
      event.preventDefault()
      event.stopPropagation()

      this.props.close()
    }
  }

  componentDidMount(): void {
    document.addEventListener('keydown', this.handleKeyDown)

    setTimeout(() => {
      this.setState({ autoFocus: true })
    }, 0)
  }

  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  /*
    CUSTOM FUNCTIONS
  */
  stopPropagation = (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  onSearch = (search: string) => {
    const { data } = this.state

    if (data && data.length === 0) return
    let results = []

    if (search?.length > 0) {
      const searchResults = this.fuse.search(search)
      for (let i = 0; i < searchResults.length; i++) results.push(searchResults[i].item)
    } else {
      results = this.state.data
    }

    this.setState({ searchResults: results, search: search })
  }

  groupData = ({ data, groupBy }: any) => {
    const groups: any = {}

    const { accessor, sections } = groupBy
    const sectionKeys = Object.keys(sections)

    for (const record of data) {
      const value = record[accessor]

      if (sectionKeys.includes(value)) {
        if (!groups[value]) groups[value] = []
        groups[value].push(record)
      } else {
        if (!groups['_other']) groups['_other'] = []
        groups['_other'].push(record)
      }
    }

    return groups
  }

  render() {
    const {
      icon,
      type,
      onSelect,
      isLoading,
      selectTitle,
      selectDescription,
      emptyActions,
      groupBy,
      testKey,
      onSearchKeyDown,
      selectGraphic,
    } = this.props
    const { search, searchResults, groupedData } = this.state

    const isEmpty = this.state.data.length === 0

    const shouldShowGroups = !search && groupBy && groupedData

    return (
      <div data-test={testKey} css={rootStyles} onClick={this.stopPropagation}>
        <div css={headerStyles}>
          <Search autoFocus={this.state.autoFocus} onChange={this.onSearch} placeholder="Search…" onKeyDown={onSearchKeyDown} />
        </div>

        <div css={contentStyles}>
          {isLoading && <State isLoading />}

          {!isLoading && searchResults.length === 0 && (
            <State
              isEmpty
              icon={icon}
              title={type && startCase(type)}
              emptyActions={emptyActions}
              emptyDescription={type ? `No ${startCase(type)} found` : 'No data found'}
            />
          )}

          {!shouldShowGroups &&
            searchResults.map((data) => (
              <Card
                as="button"
                key={data.id}
                onMouseDown={() => onSelect(data)}
                onClick={() => onSelect(data)}
                onKeyDown={this.handleKeyDown}
                variant="variant-list"
                css={itemStyles}
                testKey="selector_item"
                baseline="3rem"
              >
                <CardHeader
                  graphic={
                    selectGraphic?.(data) ||
                    (data.hasOwnProperty('avatar') && (
                      <Avatar
                        magnify
                        isEditable={false}
                        initials={selectTitle?.(data)}
                        src={data.avatar}
                        magnifyPlacement="left"
                        size="2rem"
                      />
                    ))
                  }
                >
                  {selectTitle && <CardTitle title={selectTitle(data)} css={titleStyles} />}
                  {selectDescription && <CardSubtitle subtitle={selectDescription(data)} css={descriptionStyles} />}
                </CardHeader>
              </Card>
            ))}

          {shouldShowGroups &&
            Object.keys(groupedData).map((groupKey) => (
              <div key={groupKey}>
                <h3 css={groupHeaderStyles}>{groupBy.sections[groupKey]}</h3>

                {groupedData[groupKey].map((data: any) => (
                  <Card
                    as="button"
                    key={data.id}
                    onMouseDown={() => onSelect(data)}
                    onClick={() => onSelect(data)}
                    onKeyDown={this.handleKeyDown}
                    variant="variant-list"
                    css={itemStyles}
                    testKey="selector_item"
                  >
                    <CardHeader
                      graphic={
                        selectGraphic?.(data) ||
                        (data.hasOwnProperty('avatar') && (
                          <Avatar
                            magnify
                            isEditable={false}
                            initials={selectTitle?.(data)}
                            src={data.avatar}
                            magnifyPlacement="left"
                            size="2rem"
                          />
                        ))
                      }
                    >
                      {selectTitle && <CardTitle title={selectTitle(data)} css={titleStyles} />}
                      {selectDescription && <CardSubtitle subtitle={selectDescription(data)} css={descriptionStyles} />}
                    </CardHeader>
                  </Card>
                ))}
              </div>
            ))}
        </div>
      </div>
    )
  }
}

const mountAnimation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(-4px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const rootStyles = {
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  background: 'white',

  borderRadius: 7,
  boxShadow: `${SHADOW(10, COLORS.divider)}, 0 0 0 0.5px ${COLORS.divider}`,

  top: '100%',
  width: '100%',
  height: 380,
  overflow: 'hidden',
  outline: 0,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  transformOrigin: 'top center',
  animation: `${mountAnimation} 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,
}

const itemStyles = {
  display: 'block',
  border: 'none',
  textAlign: 'left',
  width: '100%',

  '&:hover, &:focus': {
    cursor: 'pointer',
    background: tint(0.85, COLORS.blue),
    outline: 'none',
    // boxShadow: `inset 0 0 0 1px ${tint(0.2, COLORS.blue)}, 0 1px 0 ${COLORS.divider} !important`,

    [`.${CARD_CLASSES.TITLE}`]: {
      color: COLORS.blue,
    },
  },

  // '': {
  //   background: COLORS.hover,
  // }
}

const titleStyles = {
  fontSize: '1rem',
}

const descriptionStyles = {
  fontSize: '0.9rem',
}

const headerStyles = {
  flex: '0 0 auto',
  boxShadow: `0 0.5px 0 ${COLORS.divider}`,
  position: 'relative',
  zIndex: 3,

  input: {
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  },
}

const contentStyles = {
  width: '100%',
  flex: '1 1 auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  position: 'relative',
  background: tint(0.5, COLORS.lightBackground),
}

const groupHeaderStyles = {
  position: 'sticky',
  top: 0,
  zIndex: 3,
  margin: 0,
  padding: '0.3rem 1rem',
  fontSize: '0.88rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  letterSpacing: 1,
  background: COLORS.lightGray,
}
