import React from 'react'
import size from 'lodash/size'

import Card from '../../../components/Card'
import DiagnosisStatus from '../../../components/Statuses/DiagnosisStatus'
import GridTable from '../../../components/GridTable'
import State from '../../../components/State'
import Status from '../../../components/Status'

import { DateCell, NavLinkCell } from './TreeComponents'
import { titleCase } from '../../../utils/functions'
import { TreatmentPlanSection } from './TreatmentPlanSection'
import { SECTIONS } from '../constants'

export const TreatmentPlanDiagnoses = (props: any) => {
  const { data, isLoading, setEditLink, diagnosesActions } = props

  const [openIds, setOpenIds] = React.useState<any>([])
  const [checkedIds, setCheckedIds] = React.useState<any>([])

  const isEmpty = size(data) === 0

  return (
    <>
      <TreatmentPlanSection {...SECTIONS.diagnoses}>
        <Card>
          {(isLoading || isEmpty) && (
            <State
              title="Linked Diagnoses"
              icon="diagnosis"
              isLoading={isLoading}
              isEmpty={isEmpty}
              emptyDescription="No diagnoses added yet to this treatment plan"
              minHeight={150}
              emptyActions={diagnosesActions}
            />
          )}

          {!isEmpty && (
            <GridTable
              testKey="diagnoses_table"
              useBanding={false}
              templateColumns="110px 250px 120px 100px repeat(2, minmax(95px, 1fr))"
              className="text-[0.92rem]"
            >
              <GridTable.Header>
                <GridTable.Cell className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[5] mq900:left-0">
                  Type
                </GridTable.Cell>
                <GridTable.Cell className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[5] mq900:left-[110px]">
                  Description
                </GridTable.Cell>
                <GridTable.Cell>Status</GridTable.Cell>
                <GridTable.Cell>Code</GridTable.Cell>
                <GridTable.Cell>From Date</GridTable.Cell>
                <GridTable.Cell>Until Date</GridTable.Cell>
              </GridTable.Header>

              {data?.map?.((o: any, goalIndex: number) => {
                const diagnosis = o?.diagnosis

                if (!diagnosis) return null

                const isOpen = openIds.includes(diagnosis.id)
                const isChecked = checkedIds.includes(diagnosis.id)
                const hasChildren = !!diagnosis.notes

                return (
                  <>
                    <GridTable.Row>
                      <GridTable.Cell className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[3] mq900:left-0">
                        {titleCase(diagnosis.category) || '–'}
                      </GridTable.Cell>

                      <NavLinkCell
                        to={setEditLink?.(diagnosis.id)}
                        label={diagnosis.description || diagnosis?.code}
                        className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[3] mq900:left-[110px]"
                      />

                      <GridTable.Cell>
                        <DiagnosisStatus small status={diagnosis.status} />
                      </GridTable.Cell>

                      <GridTable.Cell>
                        <Status small label={diagnosis?.code} color="vividBlue" />
                      </GridTable.Cell>

                      <DateCell value={diagnosis.from} />
                      <DateCell value={diagnosis.until} />
                    </GridTable.Row>

                    {isOpen && (
                      <div className="grid gap-1 pt-1 pt-2 pl-2 pb-5 text-[0.9rem] pl-[35px] border border-b border-solid border-0 border-divider">
                        <div className="font-[600]">Behavioral Definitions:</div>
                        <div>{diagnosis.behavioral_definitions || '–'}</div>
                      </div>
                    )}
                  </>
                )
              })}
            </GridTable>
          )}
        </Card>

        {!isEmpty && <div className="pt-4">{diagnosesActions}</div>}
      </TreatmentPlanSection>
    </>
  )
}
