import React from 'react'

import Chart from '@behavehealth/components/Chart'

const virtualData = [
  ['2017-01-01', 944],
  ['2017-01-02', 831],
  ['2017-01-03', 592],
  ['2017-01-04', 242],
  ['2017-01-05', 156],
  ['2017-01-06', 253],
  ['2017-01-07', 859],
  ['2017-01-08', 503],
  ['2017-01-09', 227],
  ['2017-01-10', 204],
  ['2017-01-11', 753],
  ['2017-01-12', 499],
  ['2017-01-13', 976],
  ['2017-01-14', 655],
  ['2017-01-15', 730],
  ['2017-01-16', 299],
  ['2017-01-17', 873],
  ['2017-01-18', 353],
  ['2017-01-19', 834],
  ['2017-01-20', 452],
  ['2017-01-21', 585],
  ['2017-01-22', 778],
  ['2017-01-23', 496],
  ['2017-01-24', 248],
  ['2017-01-25', 28],
  ['2017-01-26', 82],
  ['2017-01-27', 308],
  ['2017-01-28', 228],
  ['2017-01-29', 685],
  ['2017-01-30', 829],
  ['2017-01-31', 335],
  ['2017-02-01', 642],
  ['2017-02-02', 527],
  ['2017-02-03', 516],
  ['2017-02-04', 585],
  ['2017-02-05', 839],
  ['2017-02-06', 447],
  ['2017-02-07', 521],
  ['2017-02-08', 468],
  ['2017-02-09', 140],
  ['2017-02-10', 185],
  ['2017-02-11', 170],
  ['2017-02-12', 752],
  ['2017-02-13', 904],
  ['2017-02-14', 168],
  ['2017-02-15', 370],
  ['2017-02-16', 172],
  ['2017-02-17', 534],
  ['2017-02-18', 541],
  ['2017-02-19', 435],
  ['2017-02-20', 321],
  ['2017-02-21', 835],
  ['2017-02-22', 494],
  ['2017-02-23', 750],
  ['2017-02-24', 996],
  ['2017-02-25', 309],
  ['2017-02-26', 437],
  ['2017-02-27', 228],
  ['2017-02-28', 55],
  ['2017-03-01', 864],
  ['2017-03-02', 308],
  ['2017-03-03', 202],
  ['2017-03-04', 745],
  ['2017-03-05', 221],
  ['2017-03-06', 151],
  ['2017-03-07', 569],
  ['2017-03-08', 129],
  ['2017-03-09', 971],
  ['2017-03-10', 126],
  ['2017-03-11', 373],
  ['2017-03-12', 263],
  ['2017-03-13', 255],
  ['2017-03-14', 46],
  ['2017-03-15', 256],
  ['2017-03-16', 240],
  ['2017-03-17', 722],
  ['2017-03-18', 290],
  ['2017-03-19', 695],
  ['2017-03-20', 701],
  ['2017-03-21', 851],
  ['2017-03-22', 428],
  ['2017-03-23', 239],
  ['2017-03-24', 320],
  ['2017-03-25', 745],
  ['2017-03-26', 202],
  ['2017-03-27', 848],
  ['2017-03-28', 327],
  ['2017-03-29', 673],
  ['2017-03-30', 958],
  ['2017-03-31', 868],
  ['2017-04-01', 532],
  ['2017-04-02', 171],
  ['2017-04-03', 106],
  ['2017-04-04', 931],
  ['2017-04-05', 776],
  ['2017-04-06', 950],
  ['2017-04-07', 986],
  ['2017-04-08', 148],
  ['2017-04-09', 212],
  ['2017-04-10', 919],
  ['2017-04-11', 290],
  ['2017-04-12', 828],
  ['2017-04-13', 754],
  ['2017-04-14', 435],
  ['2017-04-15', 101],
  ['2017-04-16', 436],
  ['2017-04-17', 901],
  ['2017-04-18', 727],
  ['2017-04-19', 178],
  ['2017-04-20', 347],
  ['2017-04-21', 138],
  ['2017-04-22', 604],
  ['2017-04-23', 887],
  ['2017-04-24', 307],
  ['2017-04-25', 227],
  ['2017-04-26', 593],
  ['2017-04-27', 258],
  ['2017-04-28', 999],
  ['2017-04-29', 220],
  ['2017-04-30', 603],
  ['2017-05-01', 49],
  ['2017-05-02', 642],
  ['2017-05-03', 662],
  ['2017-05-04', 143],
  ['2017-05-05', 335],
  ['2017-05-06', 515],
  ['2017-05-07', 361],
  ['2017-05-08', 736],
  ['2017-05-09', 272],
  ['2017-05-10', 982],
  ['2017-05-11', 801],
  ['2017-05-12', 75],
  ['2017-05-13', 183],
  ['2017-05-14', 299],
  ['2017-05-15', 233],
  ['2017-05-16', 722],
  ['2017-05-17', 169],
  ['2017-05-18', 993],
  ['2017-05-19', 630],
  ['2017-05-20', 790],
  ['2017-05-21', 600],
  ['2017-05-22', 552],
  ['2017-05-23', 929],
  ['2017-05-24', 926],
  ['2017-05-25', 89],
  ['2017-05-26', 277],
  ['2017-05-27', 860],
  ['2017-05-28', 367],
  ['2017-05-29', 471],
  ['2017-05-30', 525],
  ['2017-05-31', 646],
  ['2017-06-01', 487],
  ['2017-06-02', 928],
  ['2017-06-03', 915],
  ['2017-06-04', 954],
  ['2017-06-05', 498],
  ['2017-06-06', 715],
  ['2017-06-07', 16],
  ['2017-06-08', 745],
  ['2017-06-09', 335],
  ['2017-06-10', 867],
  ['2017-06-11', 233],
  ['2017-06-12', 127],
  ['2017-06-13', 383],
  ['2017-06-14', 182],
  ['2017-06-15', 230],
  ['2017-06-16', 942],
  ['2017-06-17', 573],
  ['2017-06-18', 949],
  ['2017-06-19', 173],
  ['2017-06-20', 502],
  ['2017-06-21', 821],
  ['2017-06-22', 678],
  ['2017-06-23', 475],
  ['2017-06-24', 808],
  ['2017-06-25', 927],
  ['2017-06-26', 484],
  ['2017-06-27', 818],
  ['2017-06-28', 899],
  ['2017-06-29', 540],
  ['2017-06-30', 918],
  ['2017-07-01', 513],
  ['2017-07-02', 412],
  ['2017-07-03', 219],
  ['2017-07-04', 890],
  ['2017-07-05', 252],
  ['2017-07-06', 802],
  ['2017-07-07', 237],
  ['2017-07-08', 365],
  ['2017-07-09', 342],
  ['2017-07-10', 388],
  ['2017-07-11', 272],
  ['2017-07-12', 40],
  ['2017-07-13', 464],
  ['2017-07-14', 275],
  ['2017-07-15', 816],
  ['2017-07-16', 974],
  ['2017-07-17', 226],
  ['2017-07-18', 354],
  ['2017-07-19', 774],
  ['2017-07-20', 367],
  ['2017-07-21', 97],
  ['2017-07-22', 838],
  ['2017-07-23', 445],
  ['2017-07-24', 411],
  ['2017-07-25', 371],
  ['2017-07-26', 882],
  ['2017-07-27', 724],
  ['2017-07-28', 563],
  ['2017-07-29', 619],
  ['2017-07-30', 600],
  ['2017-07-31', 744],
  ['2017-08-01', 953],
  ['2017-08-02', 377],
  ['2017-08-03', 763],
  ['2017-08-04', 920],
  ['2017-08-05', 535],
  ['2017-08-06', 7],
  ['2017-08-07', 245],
  ['2017-08-08', 473],
  ['2017-08-09', 4],
  ['2017-08-10', 724],
  ['2017-08-11', 458],
  ['2017-08-12', 891],
  ['2017-08-13', 767],
  ['2017-08-14', 0],
  ['2017-08-15', 885],
  ['2017-08-16', 374],
  ['2017-08-17', 279],
  ['2017-08-18', 665],
  ['2017-08-19', 118],
  ['2017-08-20', 112],
  ['2017-08-21', 450],
  ['2017-08-22', 683],
  ['2017-08-23', 170],
  ['2017-08-24', 998],
  ['2017-08-25', 989],
  ['2017-08-26', 827],
  ['2017-08-27', 232],
  ['2017-08-28', 870],
  ['2017-08-29', 11],
  ['2017-08-30', 794],
  ['2017-08-31', 151],
  ['2017-09-01', 517],
  ['2017-09-02', 283],
  ['2017-09-03', 852],
  ['2017-09-04', 114],
  ['2017-09-05', 46],
  ['2017-09-06', 724],
  ['2017-09-07', 931],
  ['2017-09-08', 611],
  ['2017-09-09', 246],
  ['2017-09-10', 206],
  ['2017-09-11', 488],
  ['2017-09-12', 463],
  ['2017-09-13', 388],
  ['2017-09-14', 444],
  ['2017-09-15', 728],
  ['2017-09-16', 545],
  ['2017-09-17', 614],
  ['2017-09-18', 309],
  ['2017-09-19', 358],
  ['2017-09-20', 137],
  ['2017-09-21', 304],
  ['2017-09-22', 438],
  ['2017-09-23', 401],
  ['2017-09-24', 452],
  ['2017-09-25', 724],
  ['2017-09-26', 882],
  ['2017-09-27', 546],
  ['2017-09-28', 6],
  ['2017-09-29', 336],
  ['2017-09-30', 696],
  ['2017-10-01', 260],
  ['2017-10-02', 15],
  ['2017-10-03', 961],
  ['2017-10-04', 814],
  ['2017-10-05', 160],
  ['2017-10-06', 552],
  ['2017-10-07', 366],
  ['2017-10-08', 742],
  ['2017-10-09', 426],
  ['2017-10-10', 496],
  ['2017-10-11', 414],
  ['2017-10-12', 851],
  ['2017-10-13', 603],
  ['2017-10-14', 207],
  ['2017-10-15', 660],
  ['2017-10-16', 137],
  ['2017-10-17', 110],
  ['2017-10-18', 447],
  ['2017-10-19', 799],
  ['2017-10-20', 599],
  ['2017-10-21', 246],
  ['2017-10-22', 996],
  ['2017-10-23', 58],
  ['2017-10-24', 284],
  ['2017-10-25', 936],
  ['2017-10-26', 784],
  ['2017-10-27', 778],
  ['2017-10-28', 22],
  ['2017-10-29', 174],
  ['2017-10-30', 198],
  ['2017-10-31', 156],
  ['2017-11-01', 888],
  ['2017-11-02', 250],
  ['2017-11-03', 142],
  ['2017-11-04', 966],
  ['2017-11-05', 612],
  ['2017-11-06', 594],
  ['2017-11-07', 524],
  ['2017-11-08', 367],
  ['2017-11-09', 612],
  ['2017-11-10', 991],
  ['2017-11-11', 788],
  ['2017-11-12', 700],
  ['2017-11-13', 463],
  ['2017-11-14', 705],
  ['2017-11-15', 124],
  ['2017-11-16', 1],
  ['2017-11-17', 138],
  ['2017-11-18', 800],
  ['2017-11-19', 399],
  ['2017-11-20', 740],
  ['2017-11-21', 845],
  ['2017-11-22', 181],
  ['2017-11-23', 639],
  ['2017-11-24', 457],
  ['2017-11-25', 221],
  ['2017-11-26', 763],
  ['2017-11-27', 600],
  ['2017-11-28', 591],
  ['2017-11-29', 516],
  ['2017-11-30', 383],
  ['2017-12-01', 199],
  ['2017-12-02', 379],
  ['2017-12-03', 243],
  ['2017-12-04', 842],
  ['2017-12-05', 146],
  ['2017-12-06', 466],
  ['2017-12-07', 944],
  ['2017-12-08', 289],
  ['2017-12-09', 566],
  ['2017-12-10', 837],
  ['2017-12-11', 784],
  ['2017-12-12', 4],
  ['2017-12-13', 817],
  ['2017-12-14', 626],
  ['2017-12-15', 191],
  ['2017-12-16', 478],
  ['2017-12-17', 118],
  ['2017-12-18', 443],
  ['2017-12-19', 467],
  ['2017-12-20', 352],
  ['2017-12-21', 541],
  ['2017-12-22', 172],
  ['2017-12-23', 183],
  ['2017-12-24', 812],
  ['2017-12-25', 598],
  ['2017-12-26', 491],
  ['2017-12-27', 602],
  ['2017-12-28', 618],
  ['2017-12-29', 493],
  ['2017-12-30', 658],
  ['2017-12-31', 9],
]

var graphData = [
  ['2017-02-01', 260],
  ['2017-02-04', 200],
  ['2017-02-09', 279],
  ['2017-02-13', 847],
  ['2017-02-18', 241],
  ['2017-02-23', 411],
  ['2017-02-27', 985],
]

var links = graphData.map(function (item, idx) {
  return {
    source: idx,
    target: idx + 1,
  }
})

links.pop()

const option = {
  tooltip: {
    position: 'top',
  },

  visualMap: [
    {
      min: 0,
      max: 1000,
      calculable: true,
      seriesIndex: [2, 3, 4],
      orient: 'horizontal',
      left: '55%',
      bottom: 20,
    },
    {
      min: 0,
      max: 1000,
      inRange: {
        color: ['grey'],
        opacity: [0, 0.3],
      },
      controller: {
        inRange: {
          opacity: [0.3, 0.6],
        },
        outOfRange: {
          color: '#ccc',
        },
      },
      seriesIndex: [1],
      orient: 'horizontal',
      left: '10%',
      bottom: 20,
    },
  ],

  calendar: [
    {
      orient: 'vertical',
      yearLabel: {
        margin: 40,
      },
      monthLabel: {
        nameMap: 'en',
        margin: 20,
      },
      dayLabel: {
        firstDay: 0,
        nameMap: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
      },
      cellSize: 40,
      range: '2017-02',
    },
    {
      orient: 'vertical',
      yearLabel: {
        margin: 40,
      },
      monthLabel: {
        margin: 20,
      },
      cellSize: 40,
      left: 460,
      range: '2017-01',
      dayLabel: {
        firstDay: 0,
        nameMap: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
      },
    },
    {
      orient: 'vertical',
      yearLabel: {
        margin: 40,
      },
      monthLabel: {
        margin: 20,
      },
      cellSize: 40,
      top: 350,
      range: '2017-03',
    },
    {
      orient: 'vertical',
      yearLabel: {
        margin: 40,
      },
      monthLabel: {
        nameMap: 'en',
        margin: 20,
      },
      cellSize: 40,
      top: 350,
      left: 460,
      range: '2017-04',
    },
  ],

  series: [
    {
      type: 'graph',
      edgeSymbol: ['none', 'arrow'],
      coordinateSystem: 'calendar',
      links: links,
      symbolSize: 10,
      calendarIndex: 0,
      data: graphData,
    },
    {
      type: 'heatmap',
      coordinateSystem: 'calendar',
      data: virtualData,
    },
    {
      type: 'effectScatter',
      coordinateSystem: 'calendar',
      calendarIndex: 1,
      symbolSize: function (val) {
        return val[1] / 40
      },
      data: virtualData,
    },
    {
      type: 'scatter',
      coordinateSystem: 'calendar',
      calendarIndex: 2,
      symbolSize: function (val) {
        return val[1] / 60
      },
      data: virtualData,
    },
    {
      type: 'heatmap',
      coordinateSystem: 'calendar',
      calendarIndex: 3,
      data: virtualData,
    },
  ],
}

const ChartExample = (props) => {
  return <Chart option={option} {...props} />
}

export default ChartExample
