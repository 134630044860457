import React from 'react'

import { usDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string, mainLinkAs) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ row, value }: any) => <TableCell.MainLink as={mainLinkAs} to={to(row.original.id)} label={value} />,
  },
  {
    accessor: 'amount',
    Header: 'Credits Amount',
  },
  {
    width: 200,
    accessor: 'notes',
    Header: 'Notes',
  },
  {
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell value={usDate(value, timezone)} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

type Props = {
  data: Object
  hiddenColumns?: string[]
  isLoading: boolean
  isSelectable: boolean
  localStorageKey: string
  timezone: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const BillingCreditsTable = (props: Props) => {
  const { to, hiddenColumns, data, isLoading, localStorageKey, batchActionsConfig, timezone, titleAfter } = props

  return (
    <Table
      title="Credits"
      titleAfter={titleAfter}
      icon="financials"
      data={data}
      hiddenColumns={hiddenColumns}
      columns={columns(to, timezone, props.mainLinkAs)}
      isLoading={isLoading}
      emptyDescription="No credits added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

BillingCreditsTable.defaultProps = {
  localStorageKey: 'billing_credits',
}

export default withSettings(BillingCreditsTable)
