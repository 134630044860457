import React from 'react'
import dompurify from 'dompurify'

import MultiSearchPillSelector from '../../Forms/Selectors/MultiSearchObject/MultiSearchPillSelector'

const sanitize = dompurify.sanitize

const ServiceCodeGroupsSelector = ({ label = 'Service Code Groups', model = 'service_codes', ...rest }) => {
  return (
    <MultiSearchPillSelector
      isRelations={false}
      showAvatars={false}
      label={label}
      model={model}
      type="insurance_codes.service_code_group"
      selectTitle={(data: any, highlights: any) => {
        let description = data.description
        if (!highlights) return description

        for (let i = 0; i < highlights?.length; i++) {
          if (highlights[i].field === 'description') {
            description = highlights[i].snippet
            break
          }
        }

        return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
      }}
      selectDescription={(data: any) => {
        const elements = data.full_description.split(',')

        return elements.map((o: any) => <div>{o}</div>)
      }}
      {...rest}
    />
  )
}

export default ServiceCodeGroupsSelector
