import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import OccupancyStatus from '../../components/Statuses/OccupancyStatus'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { FILTERS } from '../Filters/config'

export const ProgramAssignmentsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        id: 'reference',
        model: 'data.reference.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.data.reference?.name} avatar={data.data.reference?.avatar} to={to?.(data.data)} />
        ),
      },
      {
        width: 200,
        id: 'started_at',
        model: 'data.started_at',
        title: 'Started At',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 200,
        id: 'ended_at',
        model: 'data.ended_at',
        title: 'Ended At',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 220,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ value }: any) => (value ? <OccupancyStatus status={value} /> : '–'),
      },
      {
        width: 140,
        id: 'program',
        model: 'data.program.name',
        title: 'Program',
        type: 'profile',
      },
      {
        width: 140,
        id: 'phase',
        model: 'data.phase.name',
        title: 'Phase',
      },
      {
        width: 140,
        id: 'seat',
        model: 'data.seat.name',
        title: 'Seat',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Program Assignments"
      icon="programs"
      columns={columns}
      filtersConfig={FILTERS.live_reports.program_occupancies}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="program_assignments"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=program_assignments'}
        />
      }
      {...props}
    />
  )
}
