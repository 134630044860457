import React from 'react'
import { useSelector } from 'react-redux'

import ErrorBoundary, { FallbackComponent } from '../components/ErrorBoundary'

export const withPageError = (Wrapped: any) => (props: any) => {
  const user = useSelector((state) => state.me?.user)

  return (
    <ErrorBoundary fallback={<FallbackComponent user={user} showHomeButton={false} />}>
      <Wrapped {...props} />
    </ErrorBoundary>
  )
}
