import React from 'react'
import { darken, tint } from 'polished'

import { COLORS } from '../theme'

import Flex from './Flex'
import Graphic from './Graphic'

export const TYPES = {
  default: 'blue',
  negative: 'red',
  neutral: 'gray',
  positive: 'green',
  warning: 'orange',
}

type Props = {
  actions?: React.ReactNode
  children?: React.ReactNode
  className?: any
  color?: string
  compact?: boolean
  contrast?: string
  testKey?: string
  glyph?: string
  hide?: boolean
  small?: boolean
  centerX?: boolean
  type?: keyof typeof TYPES
}

const Alert: React.FC<Props> = ({
  actions,
  children,
  className,
  color,
  compact,
  contrast,
  testKey,
  glyph,
  hide,
  small,
  type = 'default',
  centerX,
  graphic,
}) => {
  const glyphColor: string = color ? COLORS[color] || color : COLORS?.[TYPES?.[type]]

  if (hide) return null

  return (
    <div
      className={className}
      css={{
        borderRadius: compact ? 2 : 7,
        alignItems: 'center',
        background: tint(0.9, glyphColor),
        padding: '0.5em 0.75em',
        boxShadow: contrast ? `inset 0 0 1px ${darken(0.16, glyphColor)}` : undefined,
        fontSize: small ? '0.9rem' : '1rem',
      }}
    >
      <Flex nowrap gap={8} centerX={centerX}>
        {graphic}
        {glyph && <Graphic glyph={glyph} glyphColor={darken(0.05, glyphColor)} size={18} />}

        <div data-test={testKey} css={{ fontWeight: 500, width: centerX ? 'auto' : '100%', minWidth: 0 }}>
          {children}
        </div>

        {actions && <Flex>{actions}</Flex>}
      </Flex>
    </div>
  )
}

export default Alert
