import React from 'react'
import produce from 'immer'
import size from 'lodash/size'

import { useGet } from '../../../hooks/useNewAPI'
import Glyph from '../../../components/Glyph'

import { Filter } from '../common/Filter'
import { FilterCondition } from '../common/FilterCondition'
import { FilterDropdown } from '../common/FilterDropdown'
import { FilterDropdownGroup } from '../common/FilterDropdownGroup'
import { FilterDropdownItem } from '../common/FilterDropdownItem'
import { FilterDropdownValue } from '../common/FilterDropdownValue'
import { FilterLabel } from '../common/FilterLabel'
import { FilterSegment } from '../common/FilterSegment'

export const TagsFilter = (props: any) => {
  const { config, onUpdate, filter, onClear } = props

  const [tagIds, setTagsIds]: any = React.useState(filter?.value || [])

  const { data, isLoading }: any = useGet({
    name: ['tag-groups'],
    url: `/tag_groups`,
  })

  const isEmpty = size(data) === 0

  const handleUpdate = () => {
    onUpdate?.({ condition: 'in', value: tagIds })
  }

  const activeTags = React.useMemo(() => {
    const result: any = []
    const tagsMap: any = {}

    if (size(filter?.value) === 0 || size(data) === 0) return result

    for (const tagGroup of data) {
      if (!tagGroup.tags) continue

      for (const tag of tagGroup.tags) {
        tagsMap[tag.id] = tag
      }
    }

    for (const id of filter.value) {
      if (!tagsMap?.[id]) continue

      result.push(tagsMap[id])
    }

    return result
  }, [data, filter])

  if (!config) return null

  const { label, glyph } = config

  const hasActiveTags = size(activeTags) >= 1

  return (
    <Filter onClear={onClear}>
      <FilterLabel label={label} glyph={glyph} />

      <FilterCondition condition="include" />

      <FilterSegment>
        <FilterDropdown
          isEmpty={isEmpty}
          isLoading={isLoading}
          onClose={handleUpdate}
          activeValues={
            hasActiveTags &&
            activeTags?.map?.((tag) => (
              <FilterDropdownValue value={tag.name} graphic={<Glyph glyph={tag.glyph} size={14} color={tag.color} />} />
            ))
          }
        >
          {!isEmpty &&
            data.map((tagGroup: any) => {
              if (size(tagGroup?.tags) === 0) return null

              return (
                <FilterDropdownGroup key={tagGroup.id} label={tagGroup.name}>
                  {tagGroup.tags.map((tag: any) => (
                    <FilterDropdownItem
                      key={tag.id}
                      title={tag.name}
                      graphic={<Glyph glyph={tag.glyph} size={14} color={tag.color} />}
                      isActive={tagIds.includes(tag.id)}
                      onClick={() => {
                        setTagsIds((currentValue: any = {}) => {
                          return produce(currentValue, (draft: any) => {
                            if (draft.includes(tag.id)) {
                              const index = draft.indexOf(tag.id)
                              draft.splice(index, 1)
                              return
                            }

                            draft.push(tag.id)
                          })
                        })
                      }}
                    />
                  ))}
                </FilterDropdownGroup>
              )
            })}
        </FilterDropdown>
      </FilterSegment>
    </Filter>
  )
}
