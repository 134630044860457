import React from 'react'

import { getClientLink, daysBetween, countWord, titleCase } from '../../utils/functions'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import { LOC } from '../../utils/constants'

import { FILTERS } from '../Filters/config'

const RELAPSE_STATUSES = {
  relapsed: 'Relapsed',
  not_relapsed: 'Did not Relapse',
  unknown: 'Unknown',
}

const DISCHARGE_TYPES = {
  successfully_completed: 'Successful program completion',
  successful_discharge: 'Successful discharge',
  unsuccessful_discharge_with_option_to_return: 'Unsuccessful discharge with option to return',
  unsuccessful_discharge_without_option_to_return: 'Unsuccessful discharge without option to return',
  therapeutic_discharge: 'Therapeutic Discharge',
  ama: 'AMA',
  mia: 'MIA',
  administrative_discharge: 'Administrative Discharge',
  other: 'Other',
}

export const DischargesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell id={data.data.id} value={value || titleCase(data.data.subcategory)} to={to?.(data.data)} />
        ),
      },
      {
        title: 'Client',
        id: 'client',
        model: 'data.client',
        type: 'profile',
        config: {
          setLink: ({ rowData }: any) => getClientLink(rowData.client),
        },
      },
      {
        width: 220,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ value }: any) => <DataFormStatus status={value} />,
      },
      {
        width: 220,
        id: 'relapse_status',
        model: 'data.relapse_status',
        title: 'Relapse Status',
        formatValue: ({ value }: any) => (value ? RELAPSE_STATUSES[value] : '-'),
      },
      {
        width: 220,
        id: 'discharge_type',
        model: 'data.discharge_type',
        title: 'Discharge Type',
        formatValue: ({ value }: any) => (value ? DISCHARGE_TYPES[value] : '-'),
      },
      {
        width: 220,
        id: 'level_of_care',
        model: 'data.level_of_care',
        title: 'Level Of Care',
        formatValue: ({ value }: any) => (value ? LOC[value] : '-'),
      },
      {
        width: 220,
        id: 'placement_reason',
        model: 'data.placement_reason',
        title: 'Placement Reason',
      },
      {
        width: 220,
        id: 'discharge_reason',
        model: 'data.discharge_reason',
        title: 'Discharge Reason',
      },
      {
        width: 150,
        id: 'admitted_at',
        model: 'data.admitted_at',
        title: 'Admission Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 150,
        id: 'discharged_at',
        model: 'data.discharged_at',
        title: 'Discharge Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 150,
        id: 'length_of_stay',
        title: 'Length of Stay',
        model: 'data.length_of_stay',
      },
      {
        width: 200,
        id: 'updated_at',
        model: 'data.updated_at',
        title: 'Last Edited',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 180,
        id: 'author',
        model: 'data.author.name',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Discharges"
      icon="discharge_forms"
      columns={columns}
      filtersConfig={FILTERS.live_reports.discharges}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="discharges"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=discharges'}
        />
      }
    />
  )
}
