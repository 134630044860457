import React, { Fragment } from 'react'

import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import ContextShow from '../../../../Forms/ContextShow'

const Assignments = ({ checkFutureAppointments, checkPastAppointments }) => {
  return (
    <FormSection layout="vertical">
      <ContextShow when="data.assignments.is_on_behavioral_or_safety_contract" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.assignments.is_on_behavioral_or_safety_contract_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <SmartTextarea
        useDictation
        label="Please list specific assignments that are being worked on or will be assigned to client:"
        model="data.assignments.assignments_assigned_to_client_during_treatment"
      />

      <SmartTextarea
        useDictation
        label="What issues are being addressed in individual sessions and how? (List assignments given to address presenting issues)"
        model="data.assignments.issues_addressed_in_sessions"
      />

      {checkFutureAppointments && (
        <Fragment>
          <RadioGroup
            label="Will the client be seen by a psychologist, psychiatrist or other outside physician during treatment?"
            model="data.assignments.has_appointments"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>

          <ContextShow when="data.assignments.has_appointments" is={true}>
            <SmartTextarea
              useDictation
              label="Please list provider name and reason for appointment:"
              model="data.assignments.provider_name_and_reason"
              validations={{
                presence: {
                  message: 'Please provide this information',
                },
              }}
            />

            <RadioGroup
              label="Has an appointment already been scheduled?"
              model="data.assignments.is_physician_appointment_scheduled"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.assignments.is_physician_appointment_scheduled" is={true}>
              <SmartTextarea
                useDictation
                label="Please list provider name and date of appointment:"
                model="data.assignments.provider_name_and_appointment"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </ContextShow>
        </Fragment>
      )}

      {checkPastAppointments && (
        <Fragment>
          <RadioGroup
            label="Has the client been seen by a psychologist, psychiatrist or other outside physician since admission?"
            model="data.assignments.has_appointments"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>

          <ContextShow when="data.assignments.has_appointments" is={true}>
            <SmartTextarea
              useDictation
              label="Please list provider name, date seen, reason and follow up appointment:"
              model="data.assignments.provider_name_and_appointment"
              validations={{
                presence: {
                  message: 'Please provide this information',
                },
              }}
            />
          </ContextShow>

          <SmartTextarea
            useDictation
            label="How are mental health issues or medical concerns being addressed?"
            model="data.assignments.how_are_issues_addressed"
          />
        </Fragment>
      )}
    </FormSection>
  )
}

Assignments.defaultProps = {
  checkFutureAppointments: false,
  checkPastAppointments: true,
}

export default Assignments
