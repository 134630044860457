import React from 'react'
import { useDroppable } from '@dnd-kit/core'
import clsx from 'clsx'

import Glyph from '../../../components/Glyph'

import { ElementIcon } from '../components/ElementIcon'
import { ELEMENTS } from './elements'
import { useFormBuilder } from '../useFormBuilder'

export const PageElementDroppable: any = React.memo(
  ({ pageId, index, isEmpty }: any) => {
    const isEditable: any = useFormBuilder((state: any) => state.isEditable)

    const { setNodeRef, isOver, active } = useDroppable({
      id: `element-droppable-${index}-${pageId}`,
      data: {
        type: 'PAGE_ELEMENT_DROPPABLE',
        pageId,
        index,
      },
      disabled: !isEditable,
    })

    const isElementOver = isOver && active?.data?.current?.type === 'ELEMENT'

    if (isEmpty) {
      const emptyClasses = clsx(
        'h-24 bg-white bg-opacity-20 rounded-[5px] border-dashed border-divider text-center flex items-center justify-center p-4 text-text-strongly-muted italic text-[0.88rem]',
        isElementOver && 'bg-vivid-blue-100 border-vivid-blue-400 !opacity-100',
      )

      return (
        <div ref={setNodeRef} className={emptyClasses}>
          Drag and drop elements here
        </div>
      )
    }

    const rootClasses = clsx('h-[3px] w-full', isElementOver && 'bg-blue-500 !opacity-100 border-t border-b border-vivid-blue-400')

    return <div ref={setNodeRef} className={rootClasses} />
  },
  (prevProps, nextProps) => {
    return (
      prevProps.pageId === nextProps.pageId &&
      prevProps.index === nextProps.index &&
      prevProps.isEmpty === nextProps.isEmpty &&
      prevProps.type === nextProps.type
    )
  },
)

export const ChildElementDroppable: any = React.memo(({ elementId, index, isEmpty, isDisabled }: any) => {
  const isEditable: any = useFormBuilder((state: any) => state.isEditable)

  const { setNodeRef, isOver, active } = useDroppable({
    id: `child-element-droppable-${index}-${elementId}`,
    data: {
      type: 'CHILD_ELEMENT_DROPPABLE',
      elementId,
      index,
    },
    disabled: !isEditable || isDisabled,
  })

  const isElementOver = isOver && active?.data?.current?.type === 'ELEMENT'

  if (isEmpty) {
    const emptyClasses = clsx(
      'h-24 bg-white bg-opacity-20 rounded-[5px] border-dashed border-divider text-center flex items-center justify-center p-4 text-text-strongly-muted italic text-[0.88rem]',
      isElementOver && '!bg-vivid-blue-100 border-vivid-blue-400 !opacity-100 !border-dashed',
      '[.CONDITIONAL_&]:border-none',
    )

    return (
      <div ref={setNodeRef} className={emptyClasses}>
        Drag and drop elements here
      </div>
    )
  }

  const rootClasses = clsx('h-[3px] w-full', isElementOver && 'bg-blue-500 !opacity-100 border-t border-b border-vivid-blue-400')

  return <div ref={setNodeRef} className={rootClasses} />
})

export const ElementPreview: any = React.memo(
  ({ elementId }: any) => {
    const element: any = useFormBuilder((state: any) => state.allElements?.[elementId])
    const setActiveElementId: any = useFormBuilder((state: any) => state.setActiveElementId)

    const ELEMENT = React.useMemo(() => {
      return ELEMENTS?.[element?.category]
    }, [element])

    const renders = React.useRef(0)

    const handleClick = () => {
      setActiveElementId(elementId)
    }

    if (!element || !ELEMENT) return null

    let title = ELEMENT.renderTitle?.(element.config) || ELEMENT.name

    const rootClasses = clsx(
      'flex items-center flex-nowrap cursor-pointer bg-white rounded-[5px] truncate',
      '!bg-white bg-opacity-90 rounded-[5px] shadow-soft-3 shadow-divider pr-4 whitespace-nowrap',
    )

    return (
      <div className={rootClasses}>
        <div className="w-8 flex self-stretch items-center justify-center !cursor-move hover:bg-hover">
          <Glyph glyph="drag_and_drop" size={14} />
        </div>
        <ElementIcon elementCategory={element.category} className="mr-1.5 w-[20px] h-[20px]" />
        <div className="text-[0.88rem] font-[500] py-1.5 truncate">{title}</div>
        {/* <div className="text-[0.88rem] ml-auto">{renders.current++}</div> */}
      </div>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.elementId === nextProps.elementId
  },
)
