import React from 'react'

import Select from '../Select'
import Option from '../Option'

import { STATES } from '../../../utils/constants'

const StateSelect = ({ testKey, label, model, isRequired, className, value, ...rest }: any) => (
  <Select
    allowEmpty
    testKey={testKey}
    className={className}
    label={label}
    model={model}
    value={value}
    validations={
      isRequired && {
        presence: {
          message: 'Please select a state',
        },
      }
    }
    {...rest}
  >
    {STATES?.map((state) => (
      <Option key={state?.abbreviation} label={state?.name} value={state?.abbreviation} />
    ))}
  </Select>
)

StateSelect.defaultProps = {
  label: 'State',
  model: 'state',
}

export default StateSelect
