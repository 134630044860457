import React from 'react'

import FormSection from '../../../../Forms/FormSection'
import Input from '../../../../Forms/Input'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import ContextShow from '../../../../Forms/ContextShow'

const CognitiveEmotionalBehavioralV1 = () => {
  return (
    <FormSection layout="vertical">
      <RadioGroup
        model="data.cognitive_emotional_behavioral.has_suffered_eating_disorder"
        label="Has the client ever suffered from an eating disorder?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.has_suffered_eating_disorder" is={true}>
        <SmartTextarea
          useDictation
          label="Please list dates and duration of symptoms:"
          model="data.cognitive_emotional_behavioral.has_suffered_eating_disorder_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.cognitive_emotional_behavioral.has_suffered_physical_abuse"
        label="Has the client ever been the victim of physical abuse?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.has_suffered_physical_abuse" is={true}>
        <SmartTextarea
          useDictation
          label="Please list age, duration and short description of abuse:"
          model="data.cognitive_emotional_behavioral.has_suffered_physical_abuse_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.cognitive_emotional_behavioral.is_victim_of_emotional_verbal_abuse"
        label="Has the client ever been the victim of emotional or verbal abuse?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_emotional_verbal_abuse" is={true}>
        <SmartTextarea
          useDictation
          label="Please list age, duration and short description of abuse:"
          model="data.cognitive_emotional_behavioral.is_victim_of_emotional_verbal_abuse_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse"
        label="Has the client ever been the victim of sexual abuse?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse" is={true}>
        <SmartTextarea
          useDictation
          label="Please list age, duration and short description of abuse:"
          model="data.cognitive_emotional_behavioral.is_victim_of_sexual_abuse_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.cognitive_emotional_behavioral.has_harmed_another_person"
        label="Has the client ever tried to harm another person?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.has_harmed_another_person" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.cognitive_emotional_behavioral.has_harmed_another_person_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.cognitive_emotional_behavioral.has_family_mental_illness_history"
        label="Is there a history of mental illness in the client's family?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.has_family_mental_illness_history" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.cognitive_emotional_behavioral.has_family_mental_illness_history_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.cognitive_emotional_behavioral.has_family_suicide_history"
        label="Is there a history of suicide in the client's family?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.has_family_suicide_history" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.cognitive_emotional_behavioral.has_family_suicide_history_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.cognitive_emotional_behavioral.has_death_wishes"
        label="Has the client ever wished they were dead?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.has_death_wishes" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.cognitive_emotional_behavioral.has_death_wishes_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.cognitive_emotional_behavioral.has_suicidal_thoughts"
        label="Has the client had thoughts of killing themselves?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.has_suicidal_thoughts" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.cognitive_emotional_behavioral.has_suicidal_thoughts_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.cognitive_emotional_behavioral.has_intentions_to_follow_through"
        label="Has the client had intentions of following through with the thought or plan?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.has_intentions_to_follow_through" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.cognitive_emotional_behavioral.has_intentions_to_follow_through_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.cognitive_emotional_behavioral.has_worked_out_plan"
        label="Has the client ever worked out the details of how to carry out this plan?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.has_worked_out_plan" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.cognitive_emotional_behavioral.has_worked_out_plan_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
        <SmartTextarea
          useDictation
          label="How many times has the client have these thoughts?"
          model="data.cognitive_emotional_behavioral.has_worked_out_plan_how_many_times"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
        <SmartTextarea
          useDictation
          label="How long do these thoughts last?"
          model="data.cognitive_emotional_behavioral.has_worked_out_plan_how_long"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
        <SmartTextarea
          useDictation
          label="Can the client stop thinking these thoughts if they want to?"
          model="data.cognitive_emotional_behavioral.has_worked_out_plan_can_stop"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
        <SmartTextarea
          useDictation
          label="What things would stop them from acting on these thoughts?"
          model="data.cognitive_emotional_behavioral.has_worked_out_plan_what_stop"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        label="Has the client ever attempted suicide?"
        model="data.cognitive_emotional_behavioral.has_attempted_suicide"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.cognitive_emotional_behavioral.has_attempted_suicide" is={true}>
        <Input
          label="How many times?"
          model="data.cognitive_emotional_behavioral.has_attempted_suicide_how_many_times"
          type="number"
          size={4}
          suffix="times"
        />
        <SmartTextarea
          useDictation
          label="Please list date(s) and method(s):"
          model="data.cognitive_emotional_behavioral.has_attempted_suicide_dates_and_methods"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.cognitive_emotional_behavioral.has_firearms_access"
        label="Does the client have access to firearms at home?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>
    </FormSection>
  )
}

export default CognitiveEmotionalBehavioralV1
