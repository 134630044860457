import React from 'react'

type Props = {
  name: string
  ready: boolean
}

const Chotomate: React.FC<Props> = ({ name = 'chotomate', ready }) => {
  if (!ready) return null

  return <div css={styles} data-chotomate={name} />
}

const styles = {
  display: 'none',
}

export default Chotomate
