import React from 'react'
import { useRouteMatch, useLocation, useParams } from 'react-router-dom'

import { initials } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'

import Button from '@behavehealth/components/Button'
import Chotomate from '@behavehealth/components/Chotomate'
import Grid from '@behavehealth/components/Grid'
import HelpTagIframe from '@behavehealth/components/Help/HelpTagIframe'

import { PeerNotesDataTable } from '@behavehealth/constructs/PeerNotes/PeerNotesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const IndividualPeerNotes: React.FC = () => {
  const { resource_id }: any = useParams()
  const { user }: any = useSettings()
  const location = useLocation()
  const match = useRouteMatch()

  const { data: employee }: any = useGet({
    name: ['employee', resource_id],
    url: `/employees/${resource_id}`,
  })

  const isClinicalSupervisor = user?.is_clinical_supervisor
  const isCurrentEmployee = employee?.id === user?.id

  const tableProps = useDataTable({
    name: ['employee', resource_id, 'peer_notes'],
    endpoint: `/employees/${resource_id}/peer_notes`,
    updateDeleteEndpoint: `/peer_notes`,
    params: { category: 'individual' },
    localStorageKey: 'employee_peer_notes_individual_v1',
  })

  const { deleteRecords, batchUpdateRecords } = tableProps

  const to = React.useMemo(
    () => (id: string, category: string) => ({
      pathname: `${match.url}/${initials(category)}/${id}`,
      parent: match,
    }),
    [match.url],
  )

  const duplicateFormLink = React.useMemo(
    () => (record: any) => {
      let routePrefix = ''

      if (record.category === 'individual') routePrefix = 'i'
      else if (record.category === 'group_note') routePrefix = 'gn'
      else if (record.category === 'case_management') routePrefix = 'cm'
      else if (record.category === 'family_session') routePrefix = 'fs'

      return {
        pathname: `${match.url}/${routePrefix}/new`,
        parent: match,
        data: record,
      }
    },
    [],
  )

  return (
    <Grid gap="1rem" columns="100%">
      <PeerNotesDataTable
        {...tableProps}
        title="Individual Notes"
        to={to}
        hiddenColumnIds={['author']}
        duplicateFormLink={duplicateFormLink}
        emptyActions={
          <Button
            label="Add Individual Note…"
            type="primary"
            glyph="add"
            link={{
              pathname: `${location.pathname}/i/new`,
              parent: match,
            }}
            permission="peer_notes.create"
          />
        }
        help={<HelpTagIframe id="peer_notes_individual" />}
        getCanSelect={(data: any) =>
          data.status === 'signed_off'
            ? { canSelect: false, message: 'Signed Off records cannot be edited' }
            : data.status === 'closed'
            ? { canSelect: false, message: 'Closed records cannot be edited' }
            : { canSelect: true }
        }
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/peer-notes',
          },
        ]}
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'peer_notes.delete',
            action: async ({ ids }: any) => {
              await deleteRecords(ids.join(','))
            },
          },
          {
            type: 'employee_sign_off',
            permission: 'peer_notes.edit',
            action: async ({ ids, data: signature }: any) => {
              await batchUpdateRecords(ids, { [signature.model]: signature.value })
            },
          },
          {
            type: 'send_for_review',
            permission: !isClinicalSupervisor,
            action: async ({ ids }: any) => {
              await batchUpdateRecords(ids, { status: 'pending_review' })
            },
          },
          {
            type: 'request_updates',
            permission: isClinicalSupervisor && isCurrentEmployee,
            action: async ({ ids, data: updates }: any) => {
              await batchUpdateRecords(ids, { status: 'updates_required', rejected_reason: updates.value })
            },
          },
          {
            type: 'sign_off',
            permission: isClinicalSupervisor && isCurrentEmployee,
            action: async ({ ids, data: signature }: any) => {
              await batchUpdateRecords(ids, { [signature.model]: signature.value })
            },
          },
          {
            type: 'close',
            permission: true,
            action: async ({ ids }: any) => {
              await batchUpdateRecords(ids, { status: 'closed' })
            },
          },
        ]}
      />

      <Chotomate name="peer_notes_individual" ready={!tableProps?.isLoading} />
    </Grid>
  )
}

export default IndividualPeerNotes
