import React from 'react'

import { COLORS } from '../../../theme'

import Alert from '../../Alert'
import Button from '../../Button'
import Flex from '../../Flex'
import Grid from '../../Grid'
import SummonOverlay from '../../SummonOverlay'
import Text from '../../Typography/Text'

import MessagePollOverlay from '../overlays/MessagePollOverlay'

const PollMessage = ({ message }: any) => {
  const [userAnswer, setUserAnswer] = React.useState('yes')

  return (
    <Grid gap="0.5rem">
      <Text color={COLORS.text}>{message.text}</Text>

      <Alert contrast type={userAnswer === 'yes' ? 'positive' : 'negative'} glyph="question" css={styles.poll}>
        <div css={styles.pollTitle}>Poll Message</div>

        <Flex gap={6}>
          <Button
            color="text"
            glyph="check"
            glyphColor={COLORS.green}
            label={userAnswer === 'yes' ? 'You Answered Yes: 5' : 'Yes: 4'}
            css={userAnswer === 'yes' && { borderBottom: `3px solid ${COLORS.green}` }}
            onClick={() => setUserAnswer('yes')}
            size={200}
          />

          <Button
            color="text"
            glyph="cross"
            glyphColor={COLORS.red}
            label={userAnswer === 'no' ? 'You Answered No: 2' : 'No: 1'}
            css={userAnswer === 'no' && { borderBottom: `3px solid ${COLORS.red}` }}
            onClick={() => setUserAnswer('no')}
            size={200}
          />

          <SummonOverlay overlay={<MessagePollOverlay message={message} />}>
            <Button label="View Results" size={200} type="default" glyph="question" css={{ marginLeft: 'auto' }} />
          </SummonOverlay>
        </Flex>
      </Alert>
    </Grid>
  )
}

const styles = {
  poll: {
    marginTop: '0.5rem',
  },

  pollTitle: {
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontSize: '0.9rem',
    color: COLORS.text,
    marginBottom: '0.5rem',
    fontWeight: 600,
  },
}

export default PollMessage
