import React from 'react'

import ContextHide from '../../components/Forms/ContextHide'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import Switch from '../../components/Forms/Switch'

import AdvanceDirectivePOA from '../../components/Overlays/pages/DataForms/common/AdvanceDirectivePOA'
import CIWASymptoms from '../../components/Overlays/pages/DataForms/common/CIWASymptoms'
import CoordinationOfCare from '../../components/Overlays/pages/DataForms/common/CoordinationOfCare'
import COWSSymptoms from '../../components/Overlays/pages/DataForms/common/COWSSymptoms'
import CurrentDiagnosis from '../../components/Overlays/pages/DataForms/common/CurrentDiagnosis'
import MedicalEvaluation from '../../components/Overlays/pages/DataForms/common/MedicalEvaluation'
import MentalStatusExam from '../../components/Overlays/pages/DataForms/common/MentalStatusExam'
import PastMedicalHistory from '../../components/Overlays/pages/DataForms/common/PastMedicalHistory'
import ProsthesisApplianceInventory from '../../components/Overlays/pages/DataForms/common/ProsthesisApplianceInventory'
import TestResults from '../../components/Overlays/pages/DataForms/common/TestResults'
import TreatmentHistory from '../../components/Overlays/pages/DataForms/common/TreatmentHistory'
import VitalSignsForm from '../../components/Overlays/pages/DataForms/common/VitalSignsForm'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootNursingAssessmentOverlay = () => (
  <DataFormOverlay
    title="Nursing Assessment"
    category="nursing"
    subcategory="nursing_assessment"
    requestClientSignature={false}
    requestAuthorSignature={true}
    requireSupervisor={false}
    signoffWithoutSupervisor={true}
    enableTreatmentPlanSelector
    icon="nursing"
  >
    <Section
      title="Current Diagnosis"
      aside={<Switch horizontal inverse model="data.current_diagnosis.not_applicable" />}
      scrollview={{
        id: 'current_diagnosis',
        name: 'Current Diagnosis',
      }}
      commentsModel="data.current_diagnosis"
    >
      <ContextHide when="data.current_diagnosis.not_applicable" is={true}>
        <CurrentDiagnosis />
      </ContextHide>
    </Section>

    <Divider />

    {/* Advance Directive / POA */}
    <Section
      title="Advance Directive/Power of Attorney"
      aside={<Switch horizontal inverse model="data.advance_directive_poa.not_applicable" />}
      scrollview={{
        id: 'advance_directive_poa',
        name: 'Advance Directive',
      }}
      commentsModel="data.advance_directive_poa"
    >
      <ContextHide when="data.advance_directive_poa.not_applicable" is={true}>
        <AdvanceDirectivePOA />
      </ContextHide>
    </Section>

    <Divider />

    {/* Vital Signs */}
    <Section
      title="Vital Signs"
      aside={<Switch horizontal inverse model="data.bio_medical.not_applicable" />}
      scrollview={{
        id: 'vital_signs',
        name: 'Vital Signs',
      }}
      commentsModel="data.vital_signs"
    >
      <ContextHide when="data.bio_medical.not_applicable" is={true}>
        <VitalSignsForm showDateTime={false} showPainScale showComments requireHeight />
      </ContextHide>
    </Section>

    <Divider />

    {/* Test Results */}
    <Section
      title="Test Results"
      aside={<Switch horizontal inverse model="data.test_results.not_applicable" />}
      scrollview={{
        id: 'test_results',
        name: 'Test Results',
      }}
      commentsModel="data.test_results"
    >
      <ContextHide when="data.test_results.not_applicable" is={true}>
        <TestResults />
      </ContextHide>
    </Section>

    <Divider />

    {/* Past Medical History */}
    <Section
      title="Past Medical History"
      aside={<Switch horizontal inverse model="data.past_medical_history.not_applicable" />}
      scrollview={{
        id: 'past_medical_history',
        name: 'Past Medical History',
      }}
      commentsModel="data.past_medical_history"
    >
      <ContextHide when="data.past_medical_history.not_applicable" is={true}>
        <PastMedicalHistory showBPSHistory={false} showFamilyHistory={false} showSympthoms={false} showPhysicalSympthoms={false} />
      </ContextHide>
    </Section>

    <Divider />

    {/* Medical Evaluation */}
    <Section
      title="Medical Evaluation"
      aside={<Switch horizontal inverse model="data.medical_evaluation.not_applicable" />}
      scrollview={{
        id: 'medical_evaluation',
        name: 'Medical Evaluation',
      }}
      commentsModel="data.medical_evaluation"
    >
      <ContextHide when="data.medical_evaluation.not_applicable" is={true}>
        <MedicalEvaluation />
      </ContextHide>
    </Section>

    <Divider />

    {/* Coordination of Care */}
    <Section
      title="Coordination of Care"
      aside={<Switch horizontal inverse model="data.coordination_of_care.not_applicable" />}
      scrollview={{
        id: 'coordination_of_care',
        name: 'Coordination of Care',
      }}
      commentsModel="data.coordination_of_care"
    >
      <ContextHide when="data.coordination_of_care.not_applicable" is={true}>
        <CoordinationOfCare />
      </ContextHide>
    </Section>

    <Divider />

    {/* Prosthesis/Appliance Inventory */}
    <Section
      title="Prosthesis / Appliance Inventory"
      aside={<Switch horizontal inverse model="data.prosthesis_appliance_inventory.not_applicable" />}
      scrollview={{
        id: 'prosthesis_appliance_inventory',
        name: 'Prosthesis / Appliance Inventory',
      }}
      commentsModel="data.prosthesis_appliance_inventory"
    >
      <ContextHide when="data.prosthesis_appliance_inventory.not_applicable" is={true}>
        <ProsthesisApplianceInventory />
      </ContextHide>
    </Section>

    <Divider />

    {/* Treatment History */}
    <Section
      title="Treatment History"
      aside={<Switch horizontal inverse model="data.treatment_history.not_applicable" />}
      scrollview={{
        id: 'treatment_history',
        name: 'Treatment History',
      }}
      commentsModel="data.treatment_history"
    >
      <ContextHide when="data.treatment_history.not_applicable" is={true}>
        <TreatmentHistory />
      </ContextHide>
    </Section>

    <Divider />

    {/* Mental Status Exam */}
    <Section
      title="Mental Status Exam"
      aside={<Switch horizontal inverse model="data.mental_status_exam.not_applicable" />}
      scrollview={{
        id: 'mental_status_exam',
        name: 'Mental Status Exam',
      }}
      commentsModel="data.mental_status_exam"
    >
      <ContextHide when="data.mental_status_exam.not_applicable" is={true}>
        <MentalStatusExam />
      </ContextHide>
    </Section>

    <Divider />

    {/* COWS Symptoms */}
    <Section
      title="COWS Symptoms"
      aside={<Switch horizontal inverse model="data.cows_symptoms.not_applicable" />}
      scrollview={{
        id: 'cows_symptoms',
        name: 'COWS Symptoms',
      }}
      commentsModel="data.cows_symptoms"
    >
      <ContextHide when="data.cows_symptoms.not_applicable" is={true}>
        <COWSSymptoms />
      </ContextHide>
    </Section>

    <Divider />

    {/* CIWA Symptoms */}
    <Section
      title="CIWA Symptoms"
      aside={<Switch horizontal inverse model="data.ciwa_symptoms.not_applicable" />}
      scrollview={{
        id: 'ciwa_symptoms',
        name: 'CIWA Symptoms',
      }}
      commentsModel="data.ciwa_symptoms"
    >
      <ContextHide when="data.ciwa_symptoms.not_applicable" is={true}>
        <CIWASymptoms />
      </ContextHide>
    </Section>

    <Divider />

    {/* Summary */}
    <Section
      title="Summary"
      aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
      scrollview={{
        id: 'summary',
        name: 'Summary',
      }}
      commentsModel="data.summary"
    >
      <ContextHide when="data.summary.not_applicable" is={true}>
        <FormSection layout="vertical">
          <SmartTextarea useDictation label="Interpretive Summary" model="data.summary.interpretive_summary" />
          <SmartTextarea useDictation label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
          <SmartTextarea useDictation useQuickText label="Recommendations" model="data.summary.recommendations" />
        </FormSection>
      </ContextHide>
    </Section>
  </DataFormOverlay>
)

export const NursingAssessmentOverlay = withOverlayError(RootNursingAssessmentOverlay)
