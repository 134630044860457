import React from 'react'
import { keyframes } from '@emotion/react'
import { Manager, Reference } from 'react-popper'
import { tint, darken, transparentize } from 'polished'
import clsx from 'clsx'
import pluralize from 'pluralize'

import { request } from '../modules/axios'
import { readFileAsDataURL, initials as getInitials } from '../utils/functions'

import { COLORS, SHADOW } from '../theme'
import Notifications from '../modules/notifications'

import Glyph from './Glyph'
import Loader from './Loader'
import SmartPortal from './SmartPortal'

const colors = [
  tint(0.75, COLORS.blue),
  tint(0.75, COLORS.gold),
  tint(0.75, COLORS.green),
  tint(0.75, COLORS.mintGreen),
  tint(0.75, COLORS.orange),
  tint(0.75, COLORS.paleBlue),
  tint(0.75, COLORS.purple),
  tint(0.75, COLORS.red),
  tint(0.75, COLORS.violet),
  tint(0.75, COLORS.vividBlue),
  tint(0.75, COLORS.yellow),
]

const numberFromString = (text: string): number => {
  if (!text) return 0
  const charCodes = text
    .split('')
    .map((char) => char.charCodeAt(0))
    .join('')
  return parseInt(charCodes, 10)
}

const getColorForString = (initials: string): string => {
  if (!initials) return COLORS.white
  return colors[numberFromString(initials) % colors.length]
}

class Avatar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      $loading: false,
      src: props.src,
      showMagnifyPortal: false,
    }

    this.inputRef = React.createRef()
    this.unmounted = false
  }

  componentWillUnmount = () => (this.unmounted = true)

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.state.src) this.setState({ src: nextProps.src })
  }

  upload = async (file) => {
    const { recordId, recordType, updateURL, onUpload } = this.props

    this.setState({ $loading: true })

    let dataUrl = await readFileAsDataURL(file)
    const params = {
      avatar_name: file.name,
      avatar_data: dataUrl,
    }

    if (onUpload) {
      onUpload(params)
      this.setState({ $loading: false })
      return
    }

    try {
      let result = await request.put(updateURL || `${pluralize.plural(recordType)}/${recordId}`, params)
      if (this.unmounted) return

      this.setState({ src: result.data.data.avatar, $loading: false })
    } catch (errors) {
      if (this.unmounted) return

      Notifications.send('Avatar upload failed. Please try again later or contact us', 'negative')
      this.setState({ $loading: false })
    }
  }

  onChange = (event) => {
    if (!this.props.isUpdateable) return
    if (event.target.files[0]) this.upload(event.target.files[0])

    // clear out in order to be able to trigger onChange with the same file
    this.inputRef.current.value = null
  }

  stopPropagation = (event) => event.nativeEvent.stopImmediatePropagation()

  render = () => {
    const { borderRadius, className, glyph, glyphColor, height, initials, isEditable, magnify, magnifyPlacement, size, width } = this.props

    const { src, $loading } = this.state

    const firstTwoInitials = initials ? getInitials(initials).slice(0, 2) : ''

    const shouldPortalMagnify = src && magnify

    const enterMagnify = () => {
      if (!shouldPortalMagnify) return
      this.setState({ showMagnifyPortal: true })
    }
    const exitMagnify = () => {
      if (!shouldPortalMagnify) return
      this.setState({ showMagnifyPortal: false })
    }

    const classNames = clsx('avatar', {
      'no-src': !src,
      'is-editable': isEditable,
      'magnify-portal': shouldPortalMagnify,
      [className]: className,
    })

    const dynamicStyles = {
      borderRadius: borderRadius,
      width: width || size,
      minWidth: width || size,
      height: height || size,
      minHeight: height || size,
      background: `${getColorForString(firstTwoInitials)} !important`,
    }

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} className={classNames} css={[styles, dynamicStyles]} onMouseEnter={enterMagnify} onMouseLeave={exitMagnify}>
              {src && <img src={src} alt="avatar" css={imageStyles} />}

              {!src && firstTwoInitials && !isEditable && <span css={initialsStyles}>{firstTwoInitials}</span>}

              {!src && !initials && (
                <Glyph glyph={glyph} color={glyphColor || darken(0.1, COLORS.lightBackground)} className="!max-w-[50%] !max-h-[50%]" />
              )}

              {isEditable && (
                <div css={uploaderStyles}>
                  <input
                    ref={this.inputRef}
                    type="file"
                    onChange={this.onChange}
                    multiple={false}
                    accept="image/*"
                    disabled={$loading}
                    css={inputStyles}
                    onClick={this.stopPropagation}
                  />
                  {$loading && <Loader css={loaderStyles} />}

                  {!$loading && (
                    <>{initials ? <span css={initialsStyles}>{initials}</span> : <Glyph glyph={glyph} css={{ pointerEvents: 'none' }} />}</>
                  )}
                </div>
              )}
            </div>
          )}
        </Reference>

        {this.state.showMagnifyPortal && (
          <SmartPortal portal="avatar" position={magnifyPlacement}>
            <div css={portalWrapperStyles}>
              <img src={src} alt="avatar" css={[imageStyles, portalImageStyles]} />
            </div>
          </SmartPortal>
        )}
      </Manager>
    )
  }
}

const styles = {
  position: 'relative',
  boxShadow: SHADOW(3),
  overflow: 'hidden',
  background: 'white',
  zIndex: 0,
  flex: '0 0 auto !important',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  img: {
    width: 'auto',
    height: '100%',
    pointerEvents: 'none',
    position: 'relative',
    zIndex: 1,
    background: 'white',
  },

  '&.is-editable': {
    '&.no-src': { border: `1.5px dashed ${tint(0.4, COLORS.blue)}` },

    '&:hover': {
      borderColor: COLORS.blue,
      boxShadow: SHADOW(3, transparentize(0.95, COLORS.blue)),
    },
  },

  '&.magnify-portal': {
    transition: 'transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',

    '&:hover': {
      transform: 'scale3d(1.15, 1.15, 1.15)',
      boxShadow: `${SHADOW(2, transparentize(0.88, COLORS.text))}`,
    },
  },
}

const portalAnimation = keyframes({
  '0%': {
    transform: 'translateX(-0.5rem) scale3d(0.9, 0.9, 0.9)',
    opacity: 0,
  },
  '100%': {
    transform: 'translateX(0) scale3d(1, 1, 1)',
    opacity: 1,
  },
})

const imageStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'relative',
  zIndex: 1,

  '&::before': {
    content: '" "',
    display: 'block',

    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',

    backgroundSize: '40%',
    backgroundColor: '#f7f8fa',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23d0d7e1' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='12' cy='12' r='3.2'/%3e%3cpath d='M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e")`,
  },
}

const portalWrapperStyles = {
  width: 300,
  height: 300,
  borderRadius: 7,
  boxShadow: SHADOW(3),
  overflow: 'hidden',
  margin: '0 0.5rem',
}

const portalImageStyles = {
  animation: `${portalAnimation} 240ms cubic-bezier(0.175, 0.885, 0.32, 1.275)`,
}

const uploaderStyles = {
  cursor: 'pointer',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}

const loaderStyles = {
  zIndex: 1,
  background: transparentize(0.25, COLORS.white),
  boxShadow: `0 0 0 2px ${transparentize(0.25, COLORS.white)}`,
  borderRadius: '50%',
}

const inputStyles = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  opacity: 0,

  '&:hover': {
    cursor: 'pointer',
  },
}

const initialsStyles = {
  fontSize: '0.75em',
  fontWeight: 600,
  color: COLORS.text,
  opacity: 0.9,
  pointerEvents: 'none',
  textTransform: 'uppercase',
  textDecoration: 'none !important',
}

Avatar.defaultProps = {
  borderRadius: 4,
  glyph: 'photo',
  isUpdateable: true,
  magnifyOrigin: 'center',
  magnifyScale: 2,
  magnifyPlacement: 'left',
  size: 72,
}

export default Avatar
