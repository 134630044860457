import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'

import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import ProgramProfileHeader from '../../components/ProgramProfileHeader'
import Section from '../../components/Section'

const RootProgramSeatOverlay = (props: any) => {
  const { resource_id, phase_id }: any = useParams()

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialData,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'seat',
    endpoint: '/seats',
    // invalidate: 'seats',
    invalidateKeys: [
      'program-timeline',
      'programs-timeline',
      ['program', resource_id, 'phases-with-residents'],
      ['program', resource_id, 'phases-with-seats'],
    ],
    options: props,
    // isEditable: true,
  })

  // get program data from API if not passed via router
  const { data: apiProgram, isLoading: isProgramLoading }: any = useGet({
    name: ['program', resource_id],
    url: `/programs/${resource_id}`,
    options: { enabled: !!resource_id && !initialData?.program },
  })

  // get program data from router or from API
  const programData = initialData?.program || apiProgram

  const { timezone } = useSettings()

  if (isOverlayLoading || isProgramLoading) return <OverlayLoader position="center" />

  return (
    <Overlay showBackdrop position="center" onClose={close}>
      <Overlay.Header
        glyph="chair"
        title={isNew ? 'Add New Seat' : 'Edit Seat'}
        description={programData && <ProgramProfileHeader program={programData} />}
      />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && { place_id: phase_id, place_type: 'phase' }}
        >
          <Section>
            <FormSection>
              <Input
                autoFocus
                model="name"
                label="Seat Name"
                validations={{
                  presence: {
                    message: 'Please enter a seat name',
                  },
                }}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Seat?"
              message="Are you sure you want to delete this seat? This action cannot be undone."
              onYes={deleteRecord}
              isDisabled={!data?.is_deletable}
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                isDisabled={!data?.is_deletable}
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ProgramSeatOverlay = withOverlayError(RootProgramSeatOverlay)
