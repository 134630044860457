import React from 'react'
import { useParams } from 'react-router-dom'

import { FEATURES } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import QuickView from '../../components/QuickView'
import Section from '../../components/Section'

import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Textarea from '../../components/Forms/Textarea'

import { useTreatmentPlanPermissions } from '../../constructs/TreatmentPlans/useTreatmentPlanPermissions'
import Permission from '../../components/Permission'

const RootDataExportOverlay = (props: any) => {
  const { resource_id }: any = useParams()

  const { viewSomeTreatmentPlans } = useTreatmentPlanPermissions()

  const {
    cancel,
    close,
    data,
    edit,
    form,
    initialModel,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    isLoading,
    deleteRecord,
    isDeleting,
  } = useOverlay({
    name: 'data-export',
    endpoint: `/data_exports`,
    invalidate: 'data-exports',
    options: props,
  })

  const { timezone } = useSettings()

  const { data: client } = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  if (isOverlayLoading || !client) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="reports" title={isNew ? 'Request Data Export' : 'Data Export'} />

      <Overlay.Content>
        <Form
          getForm={form}
          isEditable={isEditable}
          initialModel={initialModel}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          decorate={() =>
            isNew && {
              customer_id: client?.id,
              customer_type: client?.type,
            }
          }
        >
          <Section>
            <FormSection>
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name for this export',
                  },
                }}
              />

              <Textarea
                useQuickText
                label="Export Reason"
                model="reason"
                validations={{
                  presence: {
                    message: 'Please enter a reason for this export',
                  },
                }}
              />

              <DateTimeInput
                defaultToOneMonthAgo
                label="Start Date"
                model="starting_at"
                isEditable={isNew}
                validations={{
                  presence: {
                    message: 'Please enter a start date',
                  },
                }}
              />

              <DateTimeInput
                defaultToNow
                label="End Date"
                model="ending_at"
                isEditable={isNew}
                validations={{
                  presence: {
                    message: 'Please enter an end date',
                  },
                }}
              />

              <Checkbox defaultChecked isEditable={isNew} label="Send Email/SMS notification once generated" model="send_notification" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Records" description={isNew && 'Select the data you would like to export'}>
            <FormSection>
              <CheckboxGroup
                withToggle={isNew}
                defaultToggle={true}
                label="Export"
                layout="vertical-dense"
                trueIcon="check"
                falseStyle="faded"
              >
                <Permission permission="agreements.view" featureFlagV2="client_agreements">
                  <Checkbox
                    defaultChecked
                    label="Agreements"
                    model="records.agreements"
                    isEditable={isNew}
                    icon={FEATURES.agreements.icon}
                  />
                </Permission>
                <Permission permission="applications.view" featureFlagV2="admissions">
                  <Checkbox
                    defaultChecked
                    label="Intake Applications"
                    model="records.intake_applications"
                    isEditable={isNew}
                    icon={FEATURES.applications.icon}
                  />
                </Permission>
                <Permission permission="test_results" featureFlagV2="test_results">
                  <Checkbox
                    defaultChecked
                    label="Breathalyzer Tests"
                    model="records.breathalyzer_tests"
                    isEditable={isNew}
                    icon={FEATURES.test_results.icon}
                  />
                </Permission>
                <Permission permission="clinical_assessments.view" featureFlagV2="clinical_assessments">
                  <Checkbox
                    defaultChecked
                    label="Clinical Assessments"
                    model="records.clinical_assessments"
                    isEditable={isNew}
                    icon={FEATURES.clinical_assessments.icon}
                  />
                </Permission>
                <Permission permission="clinical_measurements.view" featureFlagV2="clinical_measures">
                  <Checkbox
                    defaultChecked
                    label="Clinical Measures"
                    model="records.clinical_measures"
                    isEditable={isNew}
                    icon={FEATURES.clinical_measures.icon}
                  />
                </Permission>
                <Permission permission="clinical_notes.view" featureFlagV2="clinical_notes">
                  <Checkbox
                    defaultChecked
                    label="Clinical Notes"
                    model="records.clinical_notes"
                    isEditable={isNew}
                    icon={FEATURES.clinical_notes.icon}
                  />
                </Permission>
                <Permission permission="communications.view" featureFlagV2="communication_logs">
                  <Checkbox
                    defaultChecked
                    label="Communications"
                    model="records.communications"
                    isEditable={isNew}
                    icon={FEATURES.communications.icon}
                  />
                </Permission>
                <Permission permission="test_results" featureFlagV2="test_results">
                  <Checkbox
                    defaultChecked
                    label="Communicable Disease Tests"
                    model="records.communicable_disease_tests"
                    isEditable={isNew}
                    icon={FEATURES.test_results.icon}
                  />
                </Permission>
                <Permission permission="contacts.view" featureFlagV2="contacts">
                  <Checkbox defaultChecked label="Contacts" model="records.contacts" isEditable={isNew} icon={FEATURES.contacts.icon} />
                </Permission>
                <Permission permission="client_custom_notes.view" featureFlagV2="client_custom_notes">
                  <Checkbox
                    defaultChecked
                    label="Custom Notes"
                    model="records.custom_notes"
                    isEditable={isNew}
                    icon={FEATURES.custom_notes.icon}
                  />
                </Permission>
                <Permission permission="discharges.view" featureFlagV2="discharges">
                  <Checkbox
                    defaultChecked
                    label="Discharges"
                    model="records.discharges"
                    isEditable={isNew}
                    icon={FEATURES.discharges.icon}
                  />
                </Permission>
                <Permission permission="events.view" featureFlagV2="calendar">
                  <Checkbox defaultChecked label="Events" model="records.events" isEditable={isNew} icon={FEATURES.calendar.icon} />
                </Permission>
                <Permission permission="applications.view" featureFlagV2="admissions">
                  <Checkbox defaultChecked label="Intakes" model="records.intakes" isEditable={isNew} icon={FEATURES.intake_forms.icon} />
                </Permission>
                <Permission permission="insurance_policies.view" featureFlagV2="insurance_policies">
                  <Checkbox
                    defaultChecked
                    label="Insurance Policies"
                    model="records.insurance_policies"
                    isEditable={isNew}
                    icon={FEATURES.insurance_policies.icon}
                  />
                </Permission>
                <Permission permission="medication_logs.view" featureFlagV2="medication_logs">
                  <Checkbox
                    defaultChecked
                    label="Medication Logs"
                    model="records.medication_logs"
                    isEditable={isNew}
                    icon={FEATURES.medication_logs.icon}
                  />
                </Permission>
                <Permission permission="medication_destruction_logs.view" featureFlagV2="medication_destruction_logs">
                  <Checkbox
                    defaultChecked
                    label="Medication Destruction Logs"
                    model="records.medication_destruction_logs"
                    isEditable={isNew}
                    icon={FEATURES.medication_logs.icon}
                  />
                </Permission>
                <Permission permission="medications.view" featureFlagV2="medications">
                  <Checkbox
                    defaultChecked
                    label="Medications"
                    model="records.medications"
                    isEditable={isNew}
                    icon={FEATURES.medications.icon}
                  />
                </Permission>
                <Permission featureFlagV2="medical_assessments" permission="medical_assessments.view">
                  <Checkbox
                    defaultChecked
                    label="Medical Assessments"
                    model="records.medical_assessments"
                    isEditable={isNew}
                    icon={FEATURES.medical_assessments.icon}
                  />
                </Permission>
                <Permission featureFlagV2="nursing" permission="nursing.view">
                  <Checkbox
                    defaultChecked
                    label="Nursing"
                    model="records.nursing"
                    isEditable={isNew}
                    icon={FEATURES.medical_assessments.icon}
                  />
                </Permission>
                <Permission permission="outcome_measures.view" featureFlagV2="outcome_measures">
                  <Checkbox
                    defaultChecked
                    label="Outcome Measures"
                    model="records.outcome_measures"
                    isEditable={isNew}
                    icon={FEATURES.outcome_measures.icon}
                  />
                </Permission>
                <Permission permission="location_checkin.view" featureFlagV2="location_gps_check_in">
                  <Checkbox
                    label="Location Check-Ins"
                    model="records.location_checks"
                    isEditable={isNew}
                    defaultChecked
                    icon={FEATURES.properties.icon}
                  />
                </Permission>
                <Permission permission="progress_reviews.view" featureFlagV2="progress_reviews">
                  <Checkbox
                    defaultChecked
                    label="Progress Reviews"
                    model="records.progress_reviews"
                    isEditable={isNew}
                    icon={FEATURES.progress_reviews.icon}
                  />
                </Permission>
                <Permission permission="physician_orders.view" featureFlagV2="physician_orders">
                  <Checkbox
                    defaultChecked
                    label="Physician Orders"
                    model="records.physician_orders"
                    isEditable={isNew}
                    icon={FEATURES.physician_orders.icon}
                  />
                </Permission>
                <Permission permission="recovery_coaching.view" featureFlagV2="recovery_coaching">
                  <Checkbox
                    defaultChecked
                    label="Recovery Coaching"
                    model="records.recovery_coachings"
                    isEditable={isNew}
                    icon={FEATURES.recovery_coaching.icon}
                  />
                </Permission>
                <Permission permission="shift_notes.view" featureFlagV2="shift_notes">
                  <Checkbox
                    defaultChecked
                    label="Shift Notes"
                    model="records.housing_notes"
                    isEditable={isNew}
                    icon={FEATURES.shift_notes.icon}
                  />
                </Permission>
                <Permission permission="safety_checks.view" featureFlagV2="safety_checks">
                  <Checkbox
                    defaultChecked
                    label="Safety Checks"
                    model="records.safety_checks"
                    isEditable={isNew}
                    icon={FEATURES.safety_checks.icon}
                  />
                </Permission>
                <Permission permission="service_episodes.view" featureFlagV2="service_episodes">
                  <Checkbox
                    defaultChecked
                    label="Service Episodes"
                    model="records.service_episodes"
                    isEditable={isNew}
                    icon={FEATURES.service_episodes.icon}
                  />
                </Permission>
                <Permission permission="todos.view" featureFlagV2="todos">
                  <Checkbox defaultChecked label="To-Do's" model="records.tasks" isEditable={isNew} icon={FEATURES.todos.icon} />
                </Permission>

                {viewSomeTreatmentPlans && (
                  <>
                    <Checkbox
                      defaultChecked
                      label="New Clinical Treatment Plans"
                      model="records.new_clinical_treatment_plans"
                      isEditable={isNew}
                      icon={FEATURES.treatment_plans.icon}
                    />

                    <Checkbox
                      defaultChecked
                      label="New Medical Treatment Plans"
                      model="records.new_medical_treatment_plans"
                      isEditable={isNew}
                      icon={FEATURES.treatment_plans.icon}
                    />

                    <Checkbox
                      defaultChecked
                      label="New Case Management Treatment Plans"
                      model="records.new_case_management_treatment_plans"
                      isEditable={isNew}
                      icon={FEATURES.treatment_plans.icon}
                    />

                    <Checkbox
                      defaultChecked
                      label="New Peer Treatment Plans"
                      model="records.new_peer_treatment_plans"
                      isEditable={isNew}
                      icon={FEATURES.treatment_plans.icon}
                    />
                  </>
                )}

                <Permission permission="treatment_plans.view" featureFlagV2="treatment_plans">
                  <Checkbox
                    defaultChecked
                    label="Treatment Plans (Archived)"
                    model="records.treatment_plans"
                    isEditable={isNew}
                    icon={FEATURES.treatment_plans.icon}
                  />
                </Permission>
                <Permission permission="test_results" featureFlagV2="test_results">
                  <Checkbox defaultChecked label="UA Tests" model="records.ua_tests" isEditable={isNew} icon={FEATURES.test_results.icon} />
                </Permission>
                <Permission permission="test_results" featureFlagV2="test_results">
                  <Checkbox
                    defaultChecked
                    label="Vital Signs"
                    model="records.vital_signs"
                    isEditable={isNew}
                    icon={FEATURES.test_results.icon}
                  />
                </Permission>
                <Permission permission="clients.general_info.personal_details.view">
                  <Checkbox
                    label="Client Face Sheet"
                    model="records.client_face_sheet"
                    isEditable={isNew}
                    defaultChecked={false}
                    icon={FEATURES.clients.icon}
                  />
                </Permission>
                <Permission permission="ledger.view" featureFlagV2="financials">
                  <Checkbox
                    label="Client Charges Summary"
                    model="records.client_charges_summary"
                    isEditable={isNew}
                    defaultChecked={false}
                    icon={FEATURES.financials.icon}
                  />
                </Permission>
                <Permission permission="ledger.view" featureFlagV2="financials">
                  <Checkbox
                    label="Client Payments Summary"
                    model="records.client_payments_summary"
                    isEditable={isNew}
                    defaultChecked={false}
                    icon={FEATURES.financials.icon}
                  />
                </Permission>
                <Permission permission="ledger.view" featureFlagV2="financials">
                  <Checkbox
                    label="Client Ledger Summary"
                    model="records.client_ledger_summary"
                    isEditable={isNew}
                    defaultChecked={false}
                    icon={FEATURES.financials.icon}
                  />
                </Permission>
              </CheckboxGroup>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label={isNew ? 'Request Data Export' : 'Save'}
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="clients.actions.export"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <Grid gap={8}>
            {data?.attachment && (
              <>
                <Flex stretchChildrenX gap={8}>
                  <QuickView width={100} title={data?.name} url={data?.attachment} contentType="application/pdf">
                    <Button glyph="view" label="View Export" type="primary" flex="2 1 auto" />
                  </QuickView>

                  <Button onClick={() => window.open(data?.attachment)} glyph="download" label="Download" type="primary" color="green" />
                </Flex>
              </>
            )}

            <Flex stretchChildrenX gap={8}>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="clients.actions.export"
              />

              <DeleteDialog
                title="Delete Authorization?"
                message="Are you sure you want to delete this authorization? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="clients.actions.export"
                />
              </DeleteDialog>
            </Flex>
          </Grid>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const DataExportOverlay = withOverlayError(RootDataExportOverlay)
