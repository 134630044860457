import React from 'react'
import { useLocation } from 'react-router-dom'

import DataFormStatus from '../Statuses/DataFormStatus'
import { Text } from '../Typography'

import { usDateTime, usTime } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'

const INTAKES = {
  intake_form: 'Intake Form',
  sober_living_home_agreement: 'Sober Living Home Agreement',
}

type Props = {
  data: any
  location: any
  timezone: any
  to: any
}

const IntakeForm = ({ data, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={INTAKES[data.subcategory]} />
      <CardMeta>
        <DataFormStatus status={data?.status} />
        <Text avatar={data?.author?.avatar} label={data?.author?.name} />
        <Text glyph="date" label="Last Edited: " description={usDateTime(data.created_at, timezone)} />
        <Text glyph="time" label="Time: " description={usTime(data.created_at, timezone)} />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(IntakeForm)
