import React from 'react'
import isNumber from 'lodash/isNumber'

import { COLORS } from '../../../theme'

import Glyph from '../../../components/Glyph'
import Flex from '../../../components/Flex'

import { FilterDropdown } from './FilterDropdown'
import { PopoverItem } from './PopoverItem'

import { INPUT_STYLES } from '../styles'

const CONDITIONS: any = {
  between: 'between',
  less_than: 'less than',
  greater_than: 'greater than',
  eq: 'equal to',
}

const CONDITION_GLYPHS: any = {
  between: 'between',
  less_than: 'less_than',
  greater_than: 'greater_than',
  eq: 'equals',
}

export const NumberFilter = (props: any) => {
  const { filter, onUpdate } = props

  const ref = React.useRef(null)

  const defaultCondition = CONDITIONS.hasOwnProperty(filter?.condition) ? filter?.condition : 'between'

  const [condition, setCondition] = React.useState(defaultCondition)
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  const [firstNumber, setFirstNumber]: any = React.useState(filter?.condition === 'between' ? filter?.value?.from : filter?.value || 0)
  const [secondNumber, setSecondNumber]: any = React.useState(filter?.value?.until || 1)

  React.useEffect(() => {
    const parsedFirstNumber = parseFloat(firstNumber)
    const parsedSecondNumber = parseFloat(secondNumber)

    const firstNumberInvalid = !isFinite(parsedFirstNumber)
    const secondNumberInvalid = condition === 'between' && !isFinite(parsedSecondNumber)

    if (firstNumberInvalid || secondNumberInvalid) return

    let updateValue: any = parsedFirstNumber

    if (condition === 'between') {
      updateValue = { from: parsedFirstNumber, until: parsedSecondNumber }
    }

    onUpdate?.({ value: updateValue, condition })
  }, [condition, firstNumber, secondNumber])

  React.useEffect(() => {
    if (!ref?.current) return

    ref.current.focus()
  }, [])

  return (
    <>
      <FilterDropdown label={CONDITIONS[condition]} open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        {Object.keys(CONDITIONS).map((key) => (
          <PopoverItem
            key={key}
            title={CONDITIONS[key]}
            isActive={condition === key}
            onClick={() => {
              setCondition(key)
              setIsDropdownOpen(false)
            }}
            graphic={<Glyph glyph={CONDITION_GLYPHS[key]} color={COLORS.textMuted} size={16} />}
          />
        ))}
      </FilterDropdown>

      <Flex nowrap centerY gap="0.25rem">
        <input
          ref={ref}
          type="number"
          css={INPUT_STYLES}
          className="max-w-[80px] !min-h-[1.75rem]"
          value={isNaN(firstNumber) ? '' : firstNumber}
          onChange={(event) => {
            const inputValue = parseFloat(event.target.value)

            if (isNaN(inputValue)) {
              setFirstNumber(0)
              return
            }

            setFirstNumber(inputValue)
          }}
        />

        {condition === 'between' && (
          <>
            <div css={STYLES.arrowSegment}>→</div>

            <input
              type="number"
              css={INPUT_STYLES}
              value={isNaN(secondNumber) ? '' : secondNumber}
              onChange={(event) => {
                const inputValue = parseFloat(event.target.value)

                if (isNaN(inputValue)) {
                  setSecondNumber(null)
                  return
                }

                setSecondNumber(inputValue)
              }}
            />
          </>
        )}
      </Flex>
    </>
  )
}

const STYLES = {
  actionSegment: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0 0.25rem',
  },

  input: {
    border: 'none',
    maxWidth: 80,
    outline: 'none',
    height: 'var(--filter-height)',
    padding: '0 0.5rem',
    borderRadius: 3,
    fontSize: '0.85rem',

    '&:hover': {
      background: COLORS.hover,
    },

    '&:focus': {
      background: COLORS.hover,
      boxShadow: `inset 0 0 0 1px ${COLORS.blue}`,
    },
  },

  saveButton: {
    height: '20px !important',
    minHeight: '20px !important',
    fontSize: '0.82rem',

    svg: {
      width: 12,
      height: 12,
      margin: '0 !important',
    },
  },

  arrowSegment: {
    opacity: 0.6,
    color: COLORS.textMuted,
    padding: '0 0.25rem',
  },
}
