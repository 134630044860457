import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { setBackRoute, clearBackRoute } from '../actions/common'

const withBackListener = (Wrapped) => (props) => {
  const { setBackRoute, clearBackRoute } = props

  React.useEffect(() => {
    setBackRoute()
    return () => clearBackRoute()
  }, [setBackRoute, clearBackRoute])

  return <Wrapped {...props} />
}

const mapDispatchToProps = (dispatch) => ({
  setBackRoute: () => dispatch(setBackRoute()),
  clearBackRoute: () => dispatch(clearBackRoute()),
})

const BackListener = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withBackListener,
)

BackListener.displayName = 'withBackListener'
export default BackListener
