import React from 'react'

import Checkbox from '../../../../components/Forms/Checkbox'
import Input from '../../../../components/Forms/Input'
import Textarea from '../../../../components/Forms/Textarea'

import { VariablesDropdownMenu } from '../../components/VariablesDropdownMenu'

export const GenericInputEditor = (props: any) => {
  const { activeElementId, activeElement, editActiveElementFromInput, appendVariableToElementModel } = props

  const { config } = activeElement
  const { description, is_required, label, placeholder, tooltip } = config

  return (
    <>
      <Input
        label="Label"
        model="label"
        value={label}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'label' })
            }}
          />
        }
      />

      <Checkbox label="Required Field" model="is_required" onUpdate={editActiveElementFromInput} value={is_required} />

      <Input
        label="Placeholder"
        model="placeholder"
        value={placeholder}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'placeholder' })
            }}
          />
        }
      />

      <Textarea
        label="Description"
        model="description"
        value={description}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'description' })
            }}
          />
        }
      />

      <Textarea
        label="Info Tooltip"
        model="tooltip"
        value={tooltip}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'tooltip' })
            }}
          />
        }
      />
    </>
  )
}
