import React from 'react'

import { amount, usDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, isSelectable: boolean, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ row, value }: any) => <TableCell.MainLink to={isSelectable && to(row.original.id)} label={value} />,
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    Cell: ({ cell }: any) => <Status color="gray" label={`-$${amount(cell.value)}`} />,
  },
  {
    accessor: 'transactioned_at',
    Header: 'Refund Date',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 250,
    accessor: 'reference',
    Header: 'Payer',
    Cell: ({ value }: any) => <TableCell.Profile avatar={value?.avatar} name={value?.name} />,
  },
  {
    accessor: 'notes',
    Header: 'Notes',
    Cell: ({ value }: any) => <TableCell.Truncated value={value} />,
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 220,
    accessor: 'updated_at',
    Header: 'Date Updated',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 200,
    accessor: 'client_location',
    Header: 'Client Location',
    Cell: ({ row, value }: any) => (
      <TableCell.Profile avatar={row.original.client_location?.avatar} name={row.original.client_location?.name} />
    ),
  },
  {
    id: 'author',
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  hiddenColumns?: string[]
  isLoading: boolean
  isSelectable: boolean
  localStorageKey: string
  timezone: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const RefundsTable = ({
  to,
  hiddenColumns,
  data,
  isLoading,
  isSelectable,
  batchActionsConfig,
  localStorageKey,
  timezone,
  titleAfter,
  testKey,
}: Props) => (
  <Table
    testKey={testKey}
    title="Refunds"
    titleAfter={titleAfter}
    icon="financials"
    data={data}
    hiddenColumns={hiddenColumns}
    columns={columns(to, isSelectable, timezone)}
    isLoading={isLoading}
    emptyDescription="No refunds added yet"
    batchActionsConfig={batchActionsConfig}
    localStorageKey={localStorageKey}
  />
)

RefundsTable.defaultProps = {
  localStorageKey: 'refunds',
}

export default withSettings(RefundsTable)
