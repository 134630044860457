import React from 'react'

export const AppsHeaderButton = React.forwardRef((props: any, ref) => {
  const { isDesktop, children, onClick, testKey } = props

  return (
    <div data-test={testKey} ref={ref} css={STYLES.root} onClick={onClick} className={isDesktop ? 'is-desktop' : 'is-mobile'}>
      {children}
    </div>
  )
})

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0.5rem',
    cursor: 'pointer',

    '&.is-desktop': {
      minWidth: 40,
    },

    '&.is-mobile': {
      flex: '1 1 auto',
    },
  },
}
