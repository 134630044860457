import React from 'react'
import { darken } from 'polished'

import { COLORS } from '../../../theme'

import { INPUT_STYLES } from '../styles'

export const FilterCondition: React.FC<any> = ({ condition }) => {
  if (!condition) return null

  return <div css={STYLES.root}>{condition}</div>
}

const STYLES = {
  root: {
    ...INPUT_STYLES,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.textMuted,
    textAlign: 'center',
    minHeight: 'var(--filter-height)',
    height: 'var(--filter-height)',
    padding: '0 0.5rem',
    fontSize: '0.85rem',
    background: COLORS.hover,
    boxShadow: `inset 0 2px 3px ${COLORS.shadow} !important`,
    border: `1px solid ${darken(0.8, COLORS.hover)} !important`,
  },
}
