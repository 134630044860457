import React from 'react'
import { tint } from 'polished'
import { useParams } from 'react-router-dom'
import * as HoverCard from '@radix-ui/react-hover-card'

import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import Icon from '../../../components/Icon'
import RoadmapGraphic from '../../../components/Roadmap/RoadmapGraphic'

import { CardTooltip } from './CardTooltip'
import { CardTooltipContent } from './CardTooltipContent'
import { ClientHeader } from './ClientHeader'

const STATUSES = {
  draft: {
    label: 'Draft',
    color: 'blue',
    glyph: 'more_horizontal',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'red',
    glyph: 'cross',
  },
  pending_review: {
    label: 'Pending Review',
    color: 'yellow',
    glyph: 'time',
  },
  client_draft: {
    label: 'Draft',
    color: 'blue',
    glyph: 'more_horizontal',
  },
  client_updates_required: {
    label: 'Client Updates Required',
    color: 'red',
    glyph: 'cross',
  },
  employee_draft: {
    label: 'Draft',
    color: 'blue',
    glyph: 'more_horizontal',
  },
  employee_pending_review: {
    label: 'Pending Staff Review',
    color: 'yellow',
    glyph: 'time',
  },
  supervisor_pending_review: {
    label: 'Pending Supervisor Review',
    color: 'yellow',
    glyph: 'time',
  },
  employee_updates_required: {
    label: 'Staff Updates Required',
    color: 'red',
    glyph: 'cross',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
    glyph: 'check',
  },
}

export const DataFormTooltip = (props: any) => {
  const { color, client, startDate, children, icon, title, recordLink, status } = props

  const { resource_id }: any = useParams()

  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  const statusConfig = STATUSES[status]

  const graphic = (
    <RoadmapGraphic
      startDate={startDate}
      onClick={openTooltip}
      background={statusConfig?.color && tint(0.9, statusConfig.color)}
      graphic={statusConfig?.glyph && <Glyph glyph={statusConfig.glyph} size={12} />}
      size="20px"
    />
  )

  if (!children) return graphic

  return (
    <CardTooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
      <HoverCard.Trigger asChild>{graphic}</HoverCard.Trigger>

      <CardTooltipContent>
        {/* {client && <ClientHeader client={client} />} */}

        {title && (
          <Flex nowrap centerY css={{ padding: '0.5rem 1rem 0.25rem' }}>
            {icon && <Icon icon={icon} size={20} className="!mr-2" />}
            <div className="text-[1.1rem] font-[700]">{title}</div>

            {recordLink && (
              <Button label="Open" size={100} link={`/clients/${resource_id}${recordLink}`} css={{ marginLeft: 'auto' }} target="_blank" />
            )}
          </Flex>
        )}
        {children}
      </CardTooltipContent>
    </CardTooltip>
  )
}
