import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Card, Grid, Page } from '@behavehealth/components'
import { RoundLinesDataTable } from '@behavehealth/constructs/RoundLines/RoundLinesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable.js'

const RoundLines = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const tableProps: any = useDataTable({
    name: ['client', resource_id, 'round_lines'],
    endpoint: `/residents/${resource_id}/round_lines`,
  })

  const { mutate: deleteRoundLines } = useDelete({
    name: ['client', resource_id, 'round_lines'],
    url: '/round_lines',
    invalidate: 'round_lines',
    invalidateKeys: ['rounds'],
  })

  return (
    <Page feature="rounds">
      <Grid gap="1rem" columns="100%">
        <Card>
          <RoundLinesDataTable
            {...tableProps}
            localStorageKey="client_round_lines"
            to={(rowData: any) => ({
              pathname: `${match.url}/${rowData.id}`,
              parent: match,
            })}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'rounds.delete',
                action: async ({ ids }: any) => {
                  await deleteRoundLines(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(RoundLines)
