import React from 'react'
import pluralize from 'pluralize'
import size from 'lodash/size'

import { usDate } from '../../../utils/functions'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import FormSection from '../../../components/Forms/FormSection'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import Workflow from '../../../components/Workflow/Workflow'

import { ChargeBuilderOverlay } from '../../Financials/ChargeBuilderOverlay'
import { useGet } from '../../../hooks/useNewAPI'

const chargesColumns = [
  {
    id: 'name',
    accessor: 'name',
    width: 150,
    Header: 'Name',
  },
  {
    id: 'amount',
    accessor: 'amount',
    width: 100,
    Header: 'Amount',
    Cell: TableCell.Amount,
  },
  {
    id: 'transactioned_at',
    accessor: 'transactioned_at',
    width: 150,
    Header: 'Due Date',
    Cell: ({ value }: any) => usDate(value),
  },
  {
    id: 'author_name',
    accessor: 'author.name',
    width: 120,
    Header: 'Created by',
  },
]

const AddChargesButton = ({ match, location }: any) => (
  <SummonOverlay overlay={<ChargeBuilderOverlay match={match} location={location} showBackdrop />}>
    <Button label="Add Charges" type="primary" glyph="add" size={300} display="inline-flex" permission="ledger.create" />
  </SummonOverlay>
)

const Financials = ({ client, setStatus, match, location }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)

  const { data: transactions, isLoading: loadingCharges }: any = useGet({
    name: ['client', match.params.resource_id, 'financial-transactions', { category: 'charge' }],
    url: `/residents/${match.params.resource_id}/financial_transactions`,
    params: { category: 'charge' },
  })

  const count = size(transactions)
  const isEmpty = count === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  if (loadingCharges) return <State isLoading />

  return (
    <FormSection maxWidth="100%">
      <Card>
        {!isEmpty && (
          <CardHeader>
            <CardTitle title={`${count} Existing ${pluralize('Charges', count)}`} className="!text-[1rem]" />
          </CardHeader>
        )}

        <CardContent className="!p-0" showDivider={!isEmpty}>
          <Table
            icon="financials"
            title="Charges"
            data={transactions}
            columns={chargesColumns}
            isLoading={isEmpty && loadingCharges}
            isEmpty={isEmpty}
            emptyDescription="No charges have been created yet"
            emptyActions={<AddChargesButton match={match} location={location} />}
          />
        </CardContent>

        {!isEmpty && (
          <CardContent>
            <AddChargesButton match={match} location={location} />
          </CardContent>
        )}
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        <Workflow.SkipButton onClick={onSkip} />
      </Workflow.Buttons>
    </FormSection>
  )
}

export default Financials
