import React from 'react'

import MultiObjectSelector from '../Selectors/MultiObject/MultiObjectSelector'
import { UATests } from '../../../utils/constants'

const DrugsOfChoiceSelector = ({ model, isRequired }) => (
  <MultiObjectSelector
    isRelations={false}
    model={model}
    label="Drug(s) of Choice"
    blankLabel="Select Drugs…"
    apiData={UATests}
    showAvatars={false}
    validations={
      isRequired && {
        presence: {
          message: 'Please select at least one drug',
        },
      }
    }
  />
)

DrugsOfChoiceSelector.defaultProps = {
  model: 'drugs_of_choice',
}

export default DrugsOfChoiceSelector
