import React from 'react'
import { useParams } from 'react-router-dom'

import { withFormContext } from '../../../components/Forms/context'
import { COLORS } from '../../../theme'
import { usDateTime, titleCase, usDate } from '../../../utils/functions'
import { LOC } from '../../../utils/constants'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import Grid from '../../../components/Grid'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import DateTimeInput from '../../../components/Forms/DateTimeInput'

import AdmissionSourceSelect from '../../../components/Forms/AdmissionSourceSelect'
import AdmissionTypeSelect from '../../../components/Forms/AdmissionTypeSelect'

const ClaimTreatmentEpisodeDetails = ({ client, data }: any) => {
  const { resource_id }: any = useParams()
  const [episode, setEpisode] = React.useState({})

  if (!data) return null
  const { category } = data

  return (
    <Card>
      <CardHeader>
        <CardTitle title="Admission & Discharge" css={styles.cardTitle} />
      </CardHeader>

      <CardContent css={styles.cardContent}>
        <Grid gap="1rem">
          <ObjectSelector
            label="Authorization"
            icon="authorizations"
            type="client.insurance_authorizations"
            model="insurance_authorization"
            selectTitle={(data: any) => data.identifier}
            selectDescription={(data: any) => (
              <Grid gap="0.25rem">
                <div>Level of Care: {LOC[data.level]}</div>
                <div>
                  Time covered: {usDate(data.started_at)} to {usDate(data.ended_at)}
                </div>
                <div>
                  Sessions Authorized: {data.sessions || 0} Used: {data.used_sessions || 0}
                </div>
              </Grid>
            )}
            dependentValue={resource_id}
          />

          {category === 'institutional' && (
            <>
              <ObjectSelector
                label="Admitting Diagnosis"
                icon="diagnosis"
                type="client.diagnoses"
                model="admitting_diagnosis"
                dependentValue={client?.id}
                selectTitle={(data) => data.code}
                selectDescription={(data) => data.description}
              />

              <ObjectSelector
                label="Treatment Episode"
                icon="treatment_data"
                type="client.admissions"
                model="treatment_episode"
                dependentValue={client?.id}
                selectTitle={(data: any) => usDateTime(data.admitted_at)}
                selectDescription={(data: any) => titleCase(data.status)}
                onUpdate={(model: any) => {
                  if (!model.object?.id) return
                  setEpisode(model.object)
                }}
              />

              <DateTimeInput
                label="Admit Date"
                model="admitted_at"
                description={`Treatment Episode Admit Date: ${usDateTime(episode.admitted_at)}`}
              />
              <DateTimeInput
                label="Discharge Date"
                model="discharged_at"
                description={`Treatment Episode Discharge Date: ${usDateTime(episode.discharged_at)}`}
              />

              <AdmissionTypeSelect model="admission_type_code" />
              <AdmissionSourceSelect model="admission_source_code" />
            </>
          )}

          {category === 'professional' && (
            <>
              <ObjectSelector
                label="Treatment Episode"
                icon="treatment_data"
                type="client.admissions"
                model="treatment_episode"
                dependentValue={client?.id}
                selectTitle={(data: any) => usDateTime(data.admitted_at)}
                selectDescription={(data: any) => titleCase(data.status)}
              />
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

const styles = {
  dataList: {
    fontSize: '0.95rem',
    flex: '1 1 300px',
  },

  dataListTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    color: COLORS.textMuted,
  },

  cardTitle: {
    fontSize: '1rem',
  },

  cardContent: {
    height: '100%',
  },
}

export default withFormContext(ClaimTreatmentEpisodeDetails)
