import React from 'react'
import { darken, transparentize } from 'polished'
import { keyframes } from '@emotion/react'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../../theme'

import Glyph from '../Glyph'

import { HUBSPOT_ID, CONTACT_EMAIL } from './constants'

const VARIANTS: any = {
  white: {
    starColor: COLORS.gold,
    glyphColor: '#ff9606',
  },
  gold: {
    starColor: COLORS.white,
    glyphColor: '#ff9606',
  },
  violet: {
    starColor: COLORS.white,
    glyphColor: COLORS.violet,
  },
}

type Props = {
  className?: string
  hasLink: boolean
  hubspotCTAID?: string
  label: string
  title: string
  variant: 'white' | 'gold' | 'violet'
}

const MarketingBadge = ({ className, hasLink, hubspotCTAID, label, title, variant }: Props) => {
  const Tag = hasLink ? 'a' : 'span'

  const classNames = clsx(className, {
    [`variant-${variant}`]: variant,
  })

  let href = ''
  if (hubspotCTAID) {
    href = `https://cta-redirect.hubspot.com/cta/redirect/${HUBSPOT_ID}/${hubspotCTAID}`
  } else if (hasLink) {
    href = `mailto:${CONTACT_EMAIL}?subject=${title}`
  }

  return (
    <Tag css={styles.root} href={href} className={classNames} target={hubspotCTAID && '_blank'}>
      <Glyph glyph="star" color={VARIANTS[variant].starColor} size="1em" />
      <span css={styles.label}>{label}</span>
    </Tag>
  )
}

const pulseAnimation = (color: string) => {
  return keyframes({
    '0%': {
      transform: 'scale3d(0.9, 0.9, 0.9)',
      boxShadow: `0 0 0 0 ${transparentize(0.3, color)}`,
    },

    '70%': {
      transform: 'scale3d(1, 1, 1)',
      boxShadow: `0 0 0 7px ${transparentize(1, color)}`,
    },

    '100%': {
      transform: 'scale3d(0.9, 0.9, 0.9)',
      boxShadow: `0 0 0 0 ${transparentize(1, color)}`,
    },
  })
}

const styles = {
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',

    paddingTop: '0.25em',
    paddingBottom: '0.25em',
    paddingLeft: '0.5em',
    paddingRight: '0.8em',

    fontSize: '0.8rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 1,
    borderRadius: '100px',

    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      borderRadius: '100px',
      animation: `${pulseAnimation(COLORS.yellow)} 2s infinite`,
    },

    '&.variant-white': {
      color: COLORS.text,
      background: COLORS.white,
      boxShadow: SHADOW(1, transparentize(0.85, COLORS.yellow)),
    },

    '&.variant-gold': {
      color: COLORS.text,
      background: 'linear-gradient(30deg, #ffd861, #ffc459)',
      boxShadow: SHADOW(1, transparentize(0.85, COLORS.yellow)),
    },

    '&.variant-violet': {
      color: COLORS.white,
      background: `linear-gradient(30deg, ${darken(0.1, COLORS.purple)}, ${COLORS.violet})`,
      boxShadow: SHADOW(1, transparentize(0.8, COLORS.purple)),

      '&::after': {
        animation: `${pulseAnimation(COLORS.purple)} 2s infinite`,
      },
    },
  },

  label: {
    marginLeft: '0.25em',
  },
}

MarketingBadge.defaultProps = {
  hasLink: true,
  label: 'Premium',
  title: 'Behave Health Feature Request',
  variant: 'gold',
}

export default MarketingBadge
