import React from 'react'
import isUndefined from 'lodash/isUndefined'

import { COLORS } from '../../theme'
import { useSettings } from '../../hooks/useSettings'

import Avatar from '../Avatar'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Icon from '../Icon'
import Link from '../Link'

import { usePinsStore } from './pinsStore'
import { ResourceTag } from '../../constructs/Resources/ResourceTag'

export const Pin: React.FC<any> = ({ data = {}, graphic }) => {
  if (!data.type) return null

  let Tag = null
  const pin = PINS[data.type]

  if (typeof pin === 'function') Tag = pin
  else if (pin[data.status]) Tag = pin[data.status]

  if (!Tag) return null

  return <Tag data={data} graphic={graphic} />
}

const RootPin: React.FC<any> = ({ avatar, icon, to, title, description, data = {}, graphic }) => {
  const { tenant } = useSettings()
  const removePin = usePinsStore((state: any) => state.removePin)

  const id = data.document_id || data.id
  const type = data.type

  if (!type && !id) return null

  const handleRemove = () => {
    removePin(tenant.subdomain, `${type}-${id}`)
  }

  return (
    <div css={styles.root}>
      <Link to={to} css={styles.link}>
        <div css={styles.graphic}>
          <Glyph glyph="drag_and_drop" size={16} color={COLORS.textMuted} css={styles.dragAndDrop} />
          {icon && <Icon icon={icon} size={20} />}
          {!isUndefined(avatar) && <Avatar avatar={avatar} initials={title} size={'2rem'} />}
        </div>

        <div css={styles.content}>
          <h3 css={styles.title} className="title">
            {title}
          </h3>

          <Flex centerY gap={8} css={styles.description}>
            <ResourceTag isSmall data={data} />
            {description}
          </Flex>
        </div>
      </Link>

      <div css={styles.actions} onClick={handleRemove}>
        <Glyph glyph="cross" size={12} color={COLORS.textMuted} />
      </div>
    </div>
  )
}

const ClientPin: React.FC<any> = ({ data }) => {
  return (
    <RootPin data={data} avatar={data.avatar || ''} to={`/clients/${data.document_id}`} title={data.name} description={data.behave_id} />
  )
}

const AlumniPin: React.FC<any> = ({ data }) => {
  return (
    <RootPin data={data} avatar={data.avatar || ''} to={`/clients/${data.document_id}`} title={data.name} description={data.behave_id} />
  )
}

const LeadPin: React.FC<any> = ({ data }) => {
  return (
    <RootPin data={data} avatar={data.avatar || ''} to={`/admissions/${data.document_id}`} title={data.name} description={data.behave_id} />
  )
}

const IntakePin: React.FC<any> = ({ data }) => {
  return (
    <RootPin data={data} avatar={data.avatar || ''} to={`/admissions/${data.document_id}`} title={data.name} description={data.behave_id} />
  )
}

const EmployeePin: React.FC<any> = ({ data }) => {
  return (
    <RootPin data={data} avatar={data.avatar || ''} to={`/employees/${data.document_id}`} title={data.name} description={data.behave_id} />
  )
}

const PINS: any = {
  resident: {
    current: ClientPin,
    lead: LeadPin,
    intake: IntakePin,
    alumni: AlumniPin,
  },
}

const styles = {
  root: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 35px',
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  link: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '4rem 1fr',
    color: COLORS.text,
    padding: '0.25rem 0',

    '&:hover': {
      backgroundColor: COLORS.white,

      '.title': {
        color: COLORS.blue,
      },
    },
  },

  graphic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '0.2rem',
  },

  dragAndDrop: {
    marginRight: '0.2rem',
  },

  content: {
    display: 'grid',
  },

  title: {
    margin: 0,
    fontSize: '1rem',
  },

  description: {
    margin: 0,
    fontSize: '0.9rem',
    color: COLORS.textMuted,
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: `1px solid ${COLORS.divider}`,
    alignSelf: 'stretch',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.white,
      svg: { fill: COLORS.red },
    },
  },
}
