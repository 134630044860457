import React from 'react'

import { COLORS } from '../../../../theme'

import Button from '../../../Button'

export const EventOutcomesFooter = (props: any) => {
  const { addLabel = 'Add Documentation', onClick, permission, className } = props

  return (
    <footer css={STYLES.root} className={className}>
      <Button label={addLabel} glyph="add" type="primary" size={200} onClick={onClick} permission={permission} />
    </footer>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    padding: '0.5rem 0.6rem',
    borderTop: `1px solid ${COLORS.divider}`,
  },
}
