import React from 'react'

import { DataTable } from '../../../../DataTable/DataTable'
import { MainCell } from '../../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../../DataTable/useDataTable'

import ClientColorPicker from '../../../../ColorPickers/ClientColorPicker'
import { FILTERS } from '../../../../../constructs/Filters/config'
import { HighRiskAlertStatus } from '../../../../../constructs/HighRiskAlert/HighRiskAlertStatus'

export const BillableClientsTable = ({ onSelect, dependentValue, options = {}, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-billable-clients-current'],
    endpoint: '/residents/billable',
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_billable_clients_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        // disableSort: true,
        disableSelect: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            onClick={onSelect ? () => onSelect(data) : undefined}
          />
        ),
      },
      {
        title: 'High Risk Alert',
        model: 'high_risk_title',
        width: 300,
        formatValue: ({ data }: any) => {
          if (!data.high_risk_title) return null

          return <HighRiskAlertStatus title={data.high_risk_title} notes={data.high_risk_notes} />
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
      },
      {
        title: 'Sex',
        model: 'sex',
        type: 'title',
        width: 140,
      },
      {
        id: 'house',
        title: 'House',
        model: 'current_bed_occupancy.place.house',
        type: 'profile',
      },
      {
        id: 'room',
        title: 'Room',
        model: 'current_bed_occupancy.place.room',
        type: 'profile',
      },
      {
        title: 'Phone',
        type: 'phone',
        model: 'phone_no',
      },
      {
        title: 'Email',
        type: 'email',
        model: 'email',
      },
      {
        title: 'Color',
        model: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => <ClientColorPicker clientID={data.id} color={data.color_code} />,
      },
      {
        title: 'Client ID',
        model: 'behave_id',
        width: 180,
        formatValue: ({ data }: any) => `#${data.behave_id}`,
      },
      {
        title: 'Last Name',
        model: 'last_name',
        width: 180,
      },
      {
        title: 'Admit Date',
        model: 'admitted_at',
        id: 'admission_admitted_at',
        type: 'date',
      },
      {
        title: 'Using Portal',
        model: 'client_portal_status',
        width: 120,
        formatValue: ({ data }: any) => {
          const value = data.client_portal_status

          if (value === 'access') return 'Yes'
          else if (value === 'invited') return 'Invited'
          else return 'No'
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable
      {...tableProps}
      {...rest}
      title="Billable Clients"
      columns={columns}
      filtersConfig={FILTERS.clients}
      searchBarFilterKey="name"
    />
  )
}
