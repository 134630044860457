import React from 'react'

import { ICONS } from '../../theme'
import { titleCase } from '../../utils/functions'

import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Dialog from '../../components/Dialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import RichTextEditor from '../../components/Forms/RichTextEditor'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'
import URLInput from '../../components/Forms/URLInput'
import RadioGroup from '../../components/Forms/RadioGroup'
import Radio from '../../components/Forms/Radio'
import UserTypeSelect from '../../components/Billing/UserTypeSelect'

import { PRICING_UNIT_TYPES, YEARLY_PRICING_UNIT_TYPES } from '../../components/Billing/constants'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

const icons = Object.entries(ICONS).map(([key]) => ({
  id: key,
  name: titleCase(key),
}))

const RootProductOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    updateAsync,
  } = useOverlay({
    name: 'products',
    endpoint: '/products',
    invalidate: 'products',
    options: props,
  })

  const { canDelete = true } = props

  const { online } = useSettings()

  const [category, setCategory] = React.useState(initialModel?.category)

  const save = async () => {
    const formData = form.current.getFormValue()

    await saveWithData({
      ...formData,
      category,
      is_required: category === 'platform_plan' ? true : formData.is_required,
    })
  }

  // rebuild icon data
  for (let i = 0; i < icons.length; i++) {
    if (icons[i].id === initialModel.icon) {
      initialModel.icon = icons[i]
      break
    }
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const toggleArchive = async () => {
    await updateAsync({ status: initialModel.status === 'archived' ? 'active' : 'archived' })
    close()
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header title="Product" icon="certificate" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          decorate={(model: any) => ({
            icon: model?.icon?.id,
          })}
        >
          <Section>
            <FormSection>
              <RadioGroup
                label="Type"
                layout="vertical-2-columns"
                model="category"
                validations={{
                  presence: {
                    message: 'Please select a Type',
                  },
                }}
                onUpdate={({ value }) => setCategory(value)}
                defaultValue={isNew ? 'platform_plan' : initialModel?.category}
              >
                <Radio label="Platform Plan" value="platform_plan" />
                <Radio label="Plan" value="plan" />
                <Radio label="Add-On" value="addon" />
                <Radio label="Service" value="service" />
              </RadioGroup>

              <Input
                label="Internal Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <Input
                label="Public Name"
                model="public_name"
                validations={{
                  presence: {
                    message: 'Please enter a public name',
                  },
                }}
              />

              <Input label="SKU" model="sku" />

              <Select allowEmpty label="Status" model="status" defaultValue="active">
                <Option label="Active" value="active" />
                <Option label="In Use" value="in_use" />
                <Option label="Archived" value="archived" />
              </Select>

              <div className="grid grid-cols-2 gap-4">
                <div
                  className={category === 'platform_plan' ? '!cursor-not-allowed' : ''}
                  onClick={(e) => {
                    if (category === 'platform_plan') {
                      e.preventDefault()
                      e.stopPropagation()
                    }
                  }}
                >
                  <CheckboxGroup isDisabled={category === 'platform_plan'} trueIcon="check" falseIcon="empty_checkbox" falseStyle="faded">
                    <Checkbox
                      label="Required"
                      model="is_required"
                      {...(category === 'platform_plan' && {
                        isDisabled: true,
                        value: true,
                        defaultChecked: true,
                      })}
                    />
                  </CheckboxGroup>
                </div>

                <CheckboxGroup trueIcon="check" falseIcon="empty_checkbox" falseStyle="faded">
                  <Checkbox label="Recommended" model="is_recommended" />
                </CheckboxGroup>
              </div>

              <URLInput label="Notion Link" model="notion_url" />

              <Textarea label="Quote / Invoice Line Description" model="line_description" />

              <ObjectSelector
                isRelation={false}
                label="Icon"
                model="icon"
                apiData={icons}
                value={initialModel?.icon}
                selectTitle={(data: any) => (
                  <Flex nowrap centerY gap="0.5rem">
                    <Icon icon={data.id} size={20} />
                    <div>{data.name}</div>
                  </Flex>
                )}
              />

              <Select
                allowEmpty
                label="Pricing Strategy"
                model="pricing_type"
                layout="vertical-dense"
                validations={{
                  presence: {
                    message: 'Please select a pricing strategy',
                  },
                }}
              >
                <Option label="Monthly Subscription" value="monthly" />
                <Option label="Metered (Monthly)" value="metered_monthly" />
                <Option label="Metered (Annually)" value="metered_annually" />
                <Option label="Annual Subscription" value="annually" />
                <Option label="One-Time Payment" value="one_time" />
                {/* <Option label="Free" value="free" /> */}
              </Select>

              <Select
                allowEmpty
                label="Billed"
                model="bill_type"
                layout="vertical-dense"
                defaultValue="end_of_cycle"
                validations={{
                  presence: {
                    message: 'Please select a billing type',
                  },
                }}
              >
                <option />
                <Option label="At the End of Cycle" value="end_of_cycle" />
                <Option label="Upfront" value="upfront" />
              </Select>

              <Flex stretchChildrenX gap="1rem">
                <Input
                  prefix="$"
                  type="number"
                  min={0}
                  label="Price"
                  model="price"
                  validations={{
                    presence: {
                      message: 'Please enter a price',
                    },
                  }}
                />

                <ContextShow when="pricing_type" is="metered_monthly">
                  <Select allowEmpty label="Unit Type" model="price_unit_type" defaultValue="client_per_month">
                    <option />
                    {Object.entries(PRICING_UNIT_TYPES).map(([key, value]) => (
                      <Option label={value} value={key} />
                    ))}
                  </Select>
                </ContextShow>

                <ContextShow when="pricing_type" is="metered_annually">
                  <Select allowEmpty label="Unit Type" model="price_unit_type" defaultValue="client_per_year">
                    <option />
                    {Object.entries(YEARLY_PRICING_UNIT_TYPES).map(([key, value]) => (
                      <Option label={value} value={key} />
                    ))}
                  </Select>
                </ContextShow>

                <ContextShow when="price_unit_type" is="user_per_month">
                  <UserTypeSelect label="User Type" model="price_unit_subtype" />
                </ContextShow>
              </Flex>

              <RichTextEditor label="Full Description" model="description" />
              <RichTextEditor label="Internal Notes" model="internal_notes" />

              <Input label="Dynamic Filter 1" model="dynamic_filters.filter_1" />
              <Input label="Dynamic Filter 2" model="dynamic_filters.filter_2" />
              <Input label="Dynamic Filter 3" model="dynamic_filters.filter_3" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer online={online}>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
            />
            <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" />

            {/* <Dialog
              title={initialModel?.status === 'archived' ? 'Activate' : 'Archive'}
              message="Are you sure you want to update this product?"
              onYes={toggleArchive}
            >
              <Button
                label={initialModel?.status === 'archived' ? 'Activate' : 'Archive'}
                glyph="empty"
                color="text"
                isLoading={isSaving}
              />
            </Dialog> */}

            {canDelete && (
              <DeleteDialog title="Delete Product?" message="Are you sure you want to delete this product?" onYes={deleteRecord}>
                <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} />
              </DeleteDialog>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ProductOverlay = withOverlayError(RootProductOverlay)
