import React from 'react'
import { tint, transparentize, darken } from 'polished'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../../../theme'

import Avatar from '../../Avatar'
import Glyph from '../../Glyph'
import Icon from '../../Icon'
import NavLink from '../../NavLink'

import { HOVER_FOCUS_STYLES } from './Link'

type MainLinkProps = {
  avatar?: string
  className: string
  glyphAfter?: string
  graphic?: any
  icon?: string
  isTruncated?: boolean
  isIndented?: boolean
  label: string
  onClick?: any
  to?: any
}

const InnerContent: React.FC<MainLinkProps> = ({ avatar, label, glyphAfter, icon, graphic }) => {
  return (
    <>
      {graphic}
      {avatar !== undefined && <Avatar magnify src={avatar} size="1.5rem" initials={label} className="!mr-2" />}
      {icon && <Icon icon={icon} size="1.25rem" className="!mr-2" />}
      <span className="text-cell-label">{label}</span>
      {glyphAfter && <Glyph glyph={glyphAfter} color={COLORS.blue} size={14} className="link-glyph" />}
    </>
  )
}

const MainLink: React.FC<MainLinkProps> = (props) => {
  const { to, label, onClick, avatar, className, glyphAfter, icon, isTruncated, isIndented, state, graphic } = props

  const classNames = clsx({
    'is-truncated': isTruncated,
    'is-indented': isIndented,
    [className]: className,
  })

  if (to) {
    const Tag = props.as || NavLink

    return (
      <Tag to={to} css={styles} onClick={onClick} activeClassName="is-active" className={classNames} state={state}>
        <InnerContent avatar={avatar} label={label} icon={icon} to={to} glyphAfter="chevron" />
      </Tag>
    )
  }

  if (onClick) {
    return (
      <span onClick={onClick} css={styles} className={classNames}>
        <InnerContent avatar={avatar} label={label} icon={icon} to={to} glyphAfter={glyphAfter} graphic={graphic} />
      </span>
    )
  }

  return (
    <span css={{ display: 'inline-flex', alignItems: 'center' }} className={classNames}>
      <InnerContent avatar={avatar} label={label} icon={icon} />
    </span>
  )
}

const styles = {
  display: 'flex',
  alignItems: 'center',
  color: COLORS.blue,
  fontWeight: 500,
  cursor: 'pointer',
  outline: 'none',

  '&.is-indented': {
    paddingLeft: '1rem',
  },

  '&.is-truncated .text-cell-label': {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '.link-glyph': {
    flex: '0 0 auto',
    marginLeft: 'auto',
    transform: 'translateX(0)',
    transition: 'transform 60ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    borderRadius: 2,
  },

  '&:hover, &:focus': {
    ...HOVER_FOCUS_STYLES,

    '.link-glyph': {
      transform: 'translateX(0.16em)',
    },
  },

  '&.is-active, &.active': {
    color: darken(0.1, COLORS.blue),

    '&::before': {
      background: tint(0.9, COLORS.blue),
      boxShadow: `
        inset 4px 0 0 ${COLORS.blue},
        inset 0 0 0 1px ${tint(0.2, COLORS.blue)},
        ${SHADOW(2, transparentize(0.97, COLORS.blue))}
      `,
    },
  },
}

export default MainLink
