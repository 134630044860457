import React from 'react'
import { connect } from 'react-redux'

import { ICONS } from '../../../../theme'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import { plural, titleCase } from '../../../../utils/functions'

import Alert from '../../../Alert'
import Button from '../../../Button'
import Chotomate from '../../../Chotomate'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import Grid from '../../../Grid'
import Overlay from '../../../Overlay'
import Section from '../../../Section'
import Glyph from '../../../Glyph'
import Link from '../../../Link'

import AmountInput from '../../../Forms/AmountInput'
import Attachments from '../../../Forms/Attachments'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import ContextShow from '../../../Forms/ContextShow'
import DateTimeInput from '../../../Forms/DateTimeInput'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Input from '../../../Forms/Input'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Radio from '../../../Forms/Radio'
import RadioGroup from '../../../Forms/RadioGroup'
import SigneeStatus from '../../../Statuses/SigneeStatus'
import Textarea from '../../../Forms/Textarea'

import { apiGet } from '../../../../modules/api'

import { PAYMENT_METHODS_LIST } from '../../../../utils/constants'
import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../OverlayBase'

const ORGANIZATION_TYPES = {
  vendor: 'Vendor',
  resource: 'Community Resource',
  provider: 'Provider',
}

class PaymentOverlay extends OverlayBase {
  onSaveSuccessful = () => {
    const { current } = this.props

    apiGet({
      name: 'financial_transactions_stats',
      url: `/${plural(current.type)}/${current.id}/financial_transactions/stats`,
    })
  }

  renderHeader = () => {
    const { $new } = this.state
    return <Overlay.Header icon={ICONS.financials} title={$new ? 'Add Payment' : 'Payment'} />
  }

  renderContent = () => {
    const { $editable, $new, params, formData, selectedPaymentMethod } = this.state
    const { record, current, timezone } = this.props

    return (
      <Overlay.Content>
        <Chotomate ready name="payment_overlay" />

        <Form
          getForm={this.form}
          timezone={timezone}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          linked={{
            category: 'payment',
            financial_plan_id: current?.financial_plan?.id,
          }}
          onUpdate={(form: any) => {
            this.setState({ formData: form.model })
          }}
        >
          <Section>
            <FormSection layout="vertical">
              <Input
                autoFocus
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a charge name',
                  },
                }}
              />

              <Flex gap="1rem">
                <AmountInput
                  label="Amount"
                  model="amount"
                  isEditable={$new || ($editable && record.source === 'app')}
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                    numericality: {
                      greaterThan: 0,
                      message: 'Please enter an amount',
                    },
                  }}
                />

                <ObjectSelector
                  isRelation={false}
                  className="!flex-auto"
                  model="payed_with"
                  label="Payed With"
                  apiData={PAYMENT_METHODS_LIST}
                  showAvatars={false}
                  selectTitle={(data: any) => (
                    <>
                      <Glyph glyph={data.glyph} /> {data.name}
                    </>
                  )}
                  selectDescription={(data: any) => data.description}
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                  }}
                />
              </Flex>

              {formData?.payed_with && (
                <>
                  {formData?.payed_with === 'online' && (
                    <>
                      <ObjectSelector
                        icon="billing"
                        type="client.payment_methods"
                        label="Payment Method"
                        model="payment_method"
                        dependentValue={current?.id}
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => data?.reference?.name}
                        onUpdate={(model: any) => {
                          this.setState({ selectedPaymentMethod: model.object })
                        }}
                      />

                      {(selectedPaymentMethod || record?.payment_method) && (
                        <>
                          <Divider className="!m-0" />

                          <RadioGroup
                            isEditable={false}
                            label="Payer Type"
                            model="reference_category"
                            layout="horizontal-dense"
                            value={selectedPaymentMethod?.reference_category || record.payment_method?.reference_category}
                          >
                            <Radio label="Client" value="client" />
                            <Radio label="Contact" value="contact" />
                            <Radio label="Organization" value="organization" />
                          </RadioGroup>

                          <ObjectSelector
                            isEditable={false}
                            isPolymorphic
                            label="Payer"
                            model="reference"
                            value={selectedPaymentMethod?.reference || record.payment_method?.reference}
                          />

                          <Divider className="!mt-2 !mx-0 !mb-0" />
                        </>
                      )}
                    </>
                  )}

                  {formData?.payed_with === 'online_apps' && (
                    <>
                      <Divider className="!m-0" />

                      <RadioGroup label="Payer Type" model="reference_category" layout="horizontal-dense">
                        <Radio label="Client" value="client" />
                        <Radio label="Contact" value="contact" />
                        <Radio label="Organization" value="organization" />
                      </RadioGroup>

                      <ContextShow when="reference_category" is="client">
                        <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="reference" value={current} />
                      </ContextShow>

                      <ContextShow when="reference_category" is="contact">
                        <ObjectSelector
                          isPolymorphic
                          icon="contacts"
                          label="Payer"
                          model="reference"
                          type="client.contacts"
                          description={
                            <>
                              Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                            </>
                          }
                          dependentValue={current?.id}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => titleCase(data?.relationship)}
                        />
                      </ContextShow>

                      <ContextShow when="reference_category" is="organization">
                        <ObjectSelector
                          isPolymorphic
                          icon="organizations"
                          label="Payer"
                          model="reference"
                          type="organizations"
                          dependentValue={current?.id}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => ORGANIZATION_TYPES[data?.category]}
                          description={
                            <>
                              Can't find the Organization you're looking for? Go to <Link to="/community/organizations">Organizations</Link>{' '}
                              to add it.
                            </>
                          }
                        />
                      </ContextShow>

                      <Divider className="!mt-2 !mx-0 !mb-0" />
                    </>
                  )}

                  {formData?.payed_with !== 'online' && formData?.payed_with !== 'online_apps' && (
                    <>
                      <Divider className="!m-0" />

                      <RadioGroup label="Payer Type" model="reference_category" layout="horizontal-dense" defaultValue="client">
                        <Radio label="Client" value="client" />
                        <Radio label="Contact" value="contact" />
                        <Radio label="Organization" value="organization" />
                      </RadioGroup>

                      <ContextShow when="reference_category" is="client">
                        <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="reference" value={current} />
                      </ContextShow>

                      <ContextShow when="reference_category" is="contact">
                        <ObjectSelector
                          isPolymorphic
                          icon="contacts"
                          label="Payer"
                          model="reference"
                          type="client.contacts"
                          description={
                            <>
                              Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                            </>
                          }
                          dependentValue={current?.id}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => titleCase(data?.relationship)}
                        />
                      </ContextShow>

                      <ContextShow when="reference_category" is="organization">
                        <ObjectSelector
                          isPolymorphic
                          icon="organizations"
                          label="Payer"
                          model="reference"
                          type="organizations"
                          dependentValue={current?.id}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => ORGANIZATION_TYPES[data?.category]}
                          description={
                            <>
                              Can't find the Organization you're looking for? Go to <Link to="/community/organizations">Organizations</Link>{' '}
                              to add it.
                            </>
                          }
                        />
                      </ContextShow>

                      <Divider className="!mt-2 !mx-0 !mb-0" />
                    </>
                  )}
                </>
              )}

              <DateTimeInput
                isEditable={$new || ($editable && record.source === 'app')}
                defaultToNow
                label="Payment Date"
                model="transactioned_at"
                validations={{
                  presence: {
                    message: 'Please enter a payment date',
                  },
                }}
              />

              <Input label="Payment Reference" model="payment_reference" />
              <Textarea useQuickText label="Notes" model="notes" />

              <Input isEditable={false} label="Transaction ID" model="uuid" />
            </FormSection>
          </Section>

          <Divider />

          {record.payer && (
            <>
              <Section glyph="warning" title="Payer">
                <Grid gap="1rem">
                  <Alert glyph="unavailable" type="negative">
                    We replaced <strong>Payers</strong> with <strong>Payment Methods</strong>. Payers will not show up for new Transactions.
                  </Alert>

                  <ObjectSelector
                    isEditable={false}
                    apiData={current?.financial_plan?.payers}
                    icon="financials"
                    model="payer"
                    label="Payer"
                    type="client.payers"
                    selectTitle={(data: any) => data?.reference?.name}
                    selectDescription={(data: any) => <SigneeStatus signee={data?.reference} />}
                  />
                </Grid>
              </Section>

              <Divider />
            </>
          )}

          <Section headingType="h2" title="Attachments" description="Upload the files related to this payment">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {$new && (
            <>
              <Divider />

              <Section title="Send Notifications">
                <Grid gap={8}>
                  <Alert glyph="notification">Send a notification alert via SMS / Email to the Payer(s) above</Alert>

                  <CheckboxGroup layout="vertical-dense">
                    <Checkbox defaultChecked label="Send SMS Notification" model="should_sms" />
                    <Checkbox defaultChecked label="Send Email Notification" model="should_email" />
                  </CheckboxGroup>
                </Grid>
              </Section>
            </>
          )}

          {/* {!$new && (
              <>
                <Divider />

                <Section headingType="h2" title="Timeline">
                  <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
                </Section>
              </>
            )} */}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid, anonymize } = this.state

    if (anonymize) return null

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save Payment"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              flex="100 1 auto"
              isDisabled={isInvalid}
              permission="ledger.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Payment"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="ledger.edit"
            />

            <DeleteDialog
              title="Delete Payment?"
              message="Are you sure you want to delete this payment? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                permission="ledger.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'financial_transactions')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(PaymentOverlay))
