import React from 'react'
import { Switch, Route, Redirect, useRouteMatch, useLocation } from 'react-router-dom'

import { Button, Page, Tabs, TabList, Tab } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Active from './active'
import Completed from './completed'

const Rosters: React.FC = () => {
  const match = useRouteMatch()
  const location = useLocation()
  return (
    <Page
      title="Rosters"
      icon="roster"
      actions={<Button label="Add Roster" glyph="add" type="primary" link={`${location.pathname}/new`} />}
      breakpoint="0"
    >
      <Tabs className="!mb-4">
        <TabList>
          <Tab label="Active" to={`${match.url}/active`} />
          <Tab label="Completed" to={`${match.url}/completed`} />
        </TabList>
      </Tabs>

      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/active`} />
        <Route path={`${match.url}/active`} component={Active} />
        <Route path={`${match.url}/completed`} component={Completed} />
      </Switch>
    </Page>
  )
}

export default withPageError(Rosters)
