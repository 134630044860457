import React from 'react'
import { transparentize } from 'polished'
import { Link, NavLink, Navigate, Route, Routes, useNavigate, useParams, useLocation } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { COLORS, HARD_SHADOW } from '@behavehealth/theme'
import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import CardTreeItem from '@behavehealth/components/CardTreeItem'
import DeleteDialog from '@behavehealth/components/Dialogs/DeleteDialog'
import Flex from '@behavehealth/components/Flex'
import Grid from '@behavehealth/components/Grid'
import HelpTagIframe from '@behavehealth/components/Help/HelpTagIframe'
import Icon from '@behavehealth/components/Icon'
import Page from '@behavehealth/components/Page'
import State from '@behavehealth/components/State'

import { LocationBedOverlay } from '@behavehealth/constructs/Locations/LocationBedOverlay'
import { LocationFloorOverlay } from '@behavehealth/constructs/Locations/LocationFloorOverlay'
import { LocationRoomOverlay } from '@behavehealth/constructs/Locations/LocationRoomOverlay'

const FloorsRoomsBeds = () => {
  const { url } = useRouteURL()

  return (
    <>
      <FloorsRoomsBedsIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<LocationFloorOverlay back={url} useV6Router />} />
        <Route path=":floor_id/:id" element={<LocationRoomOverlay back={url} useV6Router />} />
        <Route path=":floor_id/:room_id/:id" element={<LocationBedOverlay back={url} useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const FloorsRoomsBedsIndex = () => {
  const { resource_id }: any = useParams()
  const { isBehave }: any = useSettings()

  const { data, isLoading }: any = useGet({
    name: ['location', resource_id, 'floors-rooms-beds'],
    url: `/houses/${resource_id}/floors_rooms_beds`,
    options: { enabled: !!resource_id, keepPreviousData: true },
  })

  const isFloorsEmpty = data && size(data.floors) === 0

  const { mutate: deleteFloor, isLoading: isDeletingFloors } = useDelete({
    name: ['location', resource_id, 'floors-rooms-beds'],
    url: `/floors`,
    invalidate: ['floors-rooms-beds'],
  })

  const { mutate: deleteRoom, isLoading: isDeletingRooms } = useDelete({
    name: ['location', resource_id, 'floors-rooms-beds'],
    url: `/rooms`,
    invalidate: ['floors-rooms-beds'],
  })

  const actions = <Button as={Link} label="Add Floor" type="primary" glyph="add" link="new" />

  return (
    <Page icon="floor_plan" title="Floor Plan" actions={!isFloorsEmpty && actions} help={<HelpTagIframe id="location_floors" />}>
      {isFloorsEmpty ? (
        <State
          isEmpty={isFloorsEmpty}
          isLoading={isLoading}
          emptyActions={actions}
          title="Floors"
          emptyDescription="This location has no floors yet"
        />
      ) : (
        <Grid gap="1rem">
          {/* FLOORS */}
          {data?.floors?.map((floor: any) => {
            const roomsCount = size(floor.rooms)
            const isRoomsEmpty = roomsCount === 0
            const isFloorDeletable = isBehave ? true : floor?.is_deletable

            return (
              <CardTreeItem
                isOpen
                key={floor.id}
                title={floor.name}
                titleBefore={<Icon icon="floors" size={18} css={STYLES.icon} />}
                shouldIndent={false}
                after={
                  <div css={{ marginLeft: 'auto' }}>
                    <DeleteDialog
                      title="Delete Floor?"
                      message="Are you sure you want to delete this floor? This action cannot be undone."
                      onYes={async () => {
                        await deleteFloor(floor.id)
                      }}
                      isDisabled={!isFloorDeletable}
                    >
                      <Button
                        label="Delete Floor"
                        testKey="delete_floor_button"
                        glyph="delete"
                        type="default"
                        size={200}
                        color="red"
                        display="inline-flex"
                        css={STYLES.headerAction}
                        isLoading={isDeletingFloors}
                        isDisabled={!isFloorDeletable}
                      />
                    </DeleteDialog>
                  </div>
                }
                titleAfter={
                  <Button
                    hideLabel
                    as={Link}
                    size={100}
                    type="minimal"
                    glyph="edit"
                    glyphSize={12}
                    css={STYLES.squareButton}
                    onClick={(event) => {
                      event.stopPropagation()
                      event.nativeEvent.stopImmediatePropagation()
                    }}
                    link={floor.id}
                  />
                }
              >
                {isRoomsEmpty ? (
                  <State
                    isEmpty
                    title="Rooms"
                    icon="rooms"
                    emptyDescription="This Floor doesn't have Rooms yet"
                    emptyActions={
                      <Button
                        as={Link}
                        label="Add Room"
                        glyph="add"
                        color="blue"
                        display="inline-flex"
                        size={200}
                        css={{ marginLeft: '1rem', marginTop: '0.25rem', marginBottom: '1.25rem' }}
                        link={`${floor.id}/new`}
                        state={{
                          data: { place: floor, noOfRooms: roomsCount },
                        }}
                      />
                    }
                  />
                ) : (
                  <>
                    <div css={{ padding: '1rem', display: 'grid', gridGap: '0.75rem' }}>
                      {/* ROOMS */}
                      {floor.rooms.map((room: any) => {
                        const bedsCount = size(room.beds)
                        const isBedsEmpty = bedsCount === 0
                        const isRoomDeletable = isBehave ? true : room?.is_deletable

                        return (
                          <CardTreeItem
                            isOpen
                            key={room.id}
                            css={STYLES.room}
                            title={<div css={STYLES.roomTitle}>{room.name}</div>}
                            titleBefore={<Icon icon="rooms" size={18} css={STYLES.icon} />}
                            titleAfter={
                              <Button
                                hideLabel
                                as={Link}
                                size={100}
                                type="minimal"
                                glyph="edit"
                                glyphSize={12}
                                css={STYLES.squareButton}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  event.nativeEvent.stopImmediatePropagation()
                                }}
                                link={`${floor.id}/${room.id}`}
                              />
                            }
                            after={
                              <div css={{ marginLeft: 'auto' }}>
                                <DeleteDialog
                                  title="Delete Room?"
                                  message="Are you sure you want to delete this room? This action cannot be undone."
                                  onYes={async () => {
                                    await deleteRoom(room.id)
                                  }}
                                  isDisabled={!isRoomDeletable}
                                >
                                  <Button
                                    label="Delete Room"
                                    testKey="delete_room_button"
                                    glyph="delete"
                                    type="default"
                                    size={200}
                                    color="red"
                                    display="inline-flex"
                                    css={STYLES.headerAction}
                                    isLoading={isDeletingRooms}
                                    isDisabled={!isRoomDeletable}
                                  />
                                </DeleteDialog>
                              </div>
                            }
                          >
                            <div>
                              {isBedsEmpty ? (
                                <State
                                  isEmpty
                                  title="Beds"
                                  icon="beds"
                                  emptyDescription="This Room has no Beds yet"
                                  emptyActions={
                                    <Button
                                      as={Link}
                                      label="Add Bed"
                                      glyph="add"
                                      size={100}
                                      color="blue"
                                      link={`${floor.id}/${room.id}/new`}
                                      state={{
                                        data: { place: room, noOfBeds: bedsCount },
                                      }}
                                    />
                                  }
                                />
                              ) : (
                                <>
                                  {room.beds.map((bed: any) => (
                                    <NavLink key={bed.id} css={STYLES.row} to={`${floor.id}/${room.id}/${bed.id}`}>
                                      <Flex centerY nowrap gap="0.5rem">
                                        <Icon icon="beds" size={18} />
                                        <div>{bed.name}</div>
                                      </Flex>
                                    </NavLink>
                                  ))}

                                  <Button
                                    as={Link}
                                    label="Add Bed"
                                    glyph="add"
                                    color="blue"
                                    display="inline-flex"
                                    size={200}
                                    css={{ marginTop: '0.25rem', marginBottom: '1.25rem' }}
                                    link={`${floor.id}/${room.id}/new`}
                                    state={{
                                      data: { place: room, noOfBeds: bedsCount },
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </CardTreeItem>
                        )
                      })}
                    </div>

                    <Button
                      as={Link}
                      label="Add Room"
                      glyph="add"
                      color="blue"
                      display="inline-flex"
                      size={200}
                      css={{ marginLeft: '1rem', marginTop: '0.25rem', marginBottom: '1.25rem' }}
                      link={`${floor.id}/new`}
                      state={{
                        data: { place: floor, noOfRooms: roomsCount },
                      }}
                    />
                  </>
                )}
              </CardTreeItem>
            )
          })}
        </Grid>
      )}
    </Page>
  )
}

const STYLES = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.35rem 0.75rem',
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: `${HARD_SHADOW(2)}, 1px 0 0 ${COLORS.divider}`,
    background: transparentize(0.5, COLORS.white),
  },

  room: {
    // borderTop: `1px solid ${COLORS.divider}`,
    // '&:first-of-type': {
    //   borderTop: 'none',
    // },
  },

  roomsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  },

  row: {
    display: 'flex',
    fontSize: '0.9rem',
    fontWeight: 400,
    padding: '0.3rem 0.75rem',
    margin: '0.5rem 0 0.5rem 0',
    borderTop: `1px solid ${COLORS.divider}`,
    color: COLORS.text,

    '&:first-of-type': {
      borderTop: 'none',
    },

    '&:hover': {
      fontWeight: 600,
      color: COLORS.blue,
      background: COLORS.white,
      // borderRadius: '4px 0 0 4px',
      // boxShadow: `0 0 0 1px ${COLORS.divider}`,
    },

    '&.is-active': {
      fontWeight: 600,
      color: COLORS.blue,
      background: COLORS.white,
      boxShadow: `inset 3px 0 0 ${COLORS.blue}`,
    },
  },

  addRow: {
    padding: '0.75rem 0.75rem 1rem',
    color: COLORS.blue,
    fontWeight: 600,
    fontSize: '0.9rem',
  },

  dropdownButton: {
    width: 20,
    minWidth: 20,
    height: 24,
    minHeight: 24,
  },

  icon: {
    marginRight: '0.4rem',
  },

  headerAction: {
    marginLeft: 'auto',
  },

  floorTitle: {
    fontSize: '0.96rem',
    fontWeight: 700,
  },

  roomTitle: {
    fontSize: '0.9rem',
    fontWeight: 600,
    color: COLORS.text,
  },

  squareButton: {
    width: 24,
    minWidth: 24,
    height: 24,
    minHeight: 24,
    marginLeft: '0.4rem',
  },
}

export default withPageError(FloorsRoomsBeds)
