import React from 'react'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'

import Accordion from '../Accordion'
import Alert from '../Alert'
import Form from '../Forms/Form'
import FormSection from '../Forms/FormSection'
import Grid from '../Grid'
import Markup from '../Markup'
import Textarea from '../Forms/Textarea'

import { data } from './data'

type Props = {
  companyName: string
  roiDisclaimerMessage?: string
  schema: object
  form: React.RefObject<any>
  initialModel?: any
  isEditable?: boolean
  onUpdate?: Function
  onValidationUpdate?: Function
  timezone?: string
  forceOpenAccordion?: boolean
  linked?: object
}

const checkShowGroup = (groupSchema: any) => {
  if (!groupSchema || !groupSchema.show) return false

  let res = true

  if (groupSchema.hasOwnProperty('fields')) {
    let isAnyFieldVisible = false

    for (const key in groupSchema.fields) {
      if (groupSchema.fields[key].show) {
        isAnyFieldVisible = true
        break
      }
    }

    res = isAnyFieldVisible
  }

  return res
}

const ApplicationForm: React.FC<Props> = (props: any) => {
  const {
    companyName,
    roiDisclaimerMessage,
    form,
    initialModel,
    isEditable,
    onUpdate,
    onValidationUpdate,
    schema,
    timezone,
    forceOpenAccordion,
    linked,
    canUpload = true,
  } = props

  if (!schema) return null

  return (
    <Form
      autoFocus={false}
      getForm={form}
      timezone={timezone}
      onUpdate={onUpdate}
      isEditable={isEditable}
      initialModel={initialModel}
      onValidationUpdate={onValidationUpdate}
      linked={linked}
    >
      <Grid gap="0.75rem">
        {data.map((group: any, groupIdx) => {
          const groupSchema = schema?.[`${group.model}`]
          const shouldShow = checkShowGroup(groupSchema)

          if (group.model === 'custom' && size(groupSchema?.questions) === 0) return null

          if (!shouldShow) return null

          return (
            <React.Fragment key={`${group.model}-${groupIdx}`}>
              <Accordion isOpen={true} toggleOpen={forceOpenAccordion} title={group.title} className="!m-0">
                <FormSection>
                  {group.description && schema[`${group.model}`].description && (
                    <Alert type="default" glyph="info" className="!mt-2">
                      <Markup value={schema[`${group.model}`].description} />
                    </Alert>
                  )}

                  {group.fields?.map((field, fieldIdx) => {
                    const fieldData = data[groupIdx].fields[fieldIdx]
                    const fieldSettings = schema[`${group.model}`]?.fields[`${field.model}`]

                    return (
                      <React.Fragment key={field.model}>
                        {fieldSettings?.show &&
                          fieldData.component({ companyName, isRequired: fieldSettings.required, roiDisclaimerMessage })}
                      </React.Fragment>
                    )
                  })}

                  {size(schema[`${group.model}`]?.fields?.custom_questions?.questions) > 0 && (
                    <>
                      {schema[`${group.model}`].fields.custom_questions.questions.map((o: any) => (
                        <Textarea
                          key={o.id}
                          label={o.question}
                          model={`${group.model}.custom_questions.${snakeCase(o.question)}`}
                          validations={
                            o.required && {
                              presence: {
                                message: 'Please enter a response',
                              },
                            }
                          }
                        />
                      ))}
                    </>
                  )}

                  {group.renderForm?.(schema[`${group.model}`], { canUpload })}
                </FormSection>
              </Accordion>
            </React.Fragment>
          )
        })}
      </Grid>
    </Form>
  )
}

export default ApplicationForm
