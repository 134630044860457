import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import isNaN from 'lodash/isNaN'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { useFormField } from '../../Forms/hooks/useFormField'
import { useStore } from '../utils/useStore'
import { withFormContext } from '../../Forms/context'

import { DisplayValue } from './DisplayValue'

export const RootInputCell = (props: any) => {
  const {
    form,
    formatDisplayValue,
    isEditing,
    model,
    onBlur,
    rowData,
    setValue,
    type = 'text',
    validations,
    value,
    enterEdit,
    onKeyDown,
    canEdit,
    cellTooltip,
  } = props

  const ref: any = React.useRef()

  const { formActions, formState } = useFormField({
    model: model,
    form: form,
    validations: validations,
    isValid: size(validations) === 0,
  })

  const { isInvalid } = formState

  const updateField: any = useStore((state: any) => state.updateField)
  const showInvalid: any = useStore((state: any) => state.showInvalid)

  const displayValue = React.useMemo(() => {
    if (isUndefined(formatDisplayValue)) return value

    return formatDisplayValue({ value, rowData })
  }, [value, formatDisplayValue])

  const handleChange = (event: any) => {
    setValue(event.target.value)
  }

  const handleFocus = () => {
    enterEdit()
  }

  const handleBlur = () => {
    updateField({ model, value })
    onBlur?.()
  }

  // Sync props value with state value
  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  // Update form state
  React.useEffect(() => {
    if (isUndefined(value)) return

    let newValue = value

    if (type === 'number') {
      const floatValue = parseFloat(value)
      newValue = isNaN(floatValue) ? 0 : floatValue
    }

    formActions.setValue(newValue)
  }, [value])

  // Focus input when editing
  React.useEffect(() => {
    if (!ref.current || !isEditing) return

    setTimeout(() => {
      ref.current.focus() // focus works only with setTimeout
    }, 0)
  }, [isEditing])

  const hasTooltip = !!cellTooltip?.message

  const rootClasses = clsx(isEditing && 'is-editing', isInvalid && showInvalid && 'is-invalid', hasTooltip && 'has-tooltip')

  return (
    <>
      <input
        ref={ref}
        type={type}
        css={STYLES.root}
        className={rootClasses}
        value={value || ''}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        disabled={!canEdit}
      />
      <DisplayValue isEditing={isEditing}>{displayValue}</DisplayValue>
    </>
  )
}

const STYLES = {
  root: {
    borderRadius: 3,
    border: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    right: 0,
    padding: '0 0.4rem',
    minHeight: 30,
    outline: 'none',

    fontSize: '0.92rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    background: 'var(--cell-bg, white) !important',

    opacity: 0,

    '&:focus': {
      boxShadow: 'var(--input-focus-box-shadow)',
    },

    '&.is-editing': {
      opacity: 1,
      display: 'block',
      background: COLORS.white,
    },

    '&.is-invalid': {
      display: 'block',
      background: tint(0.85, COLORS.red),
    },

    '&.has-tooltip': {
      paddingLeft: '1.75rem',
    },
  },
}

export const InputCell = withFormContext(RootInputCell)
