import React from 'react'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useSelector } from 'react-redux'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { ICONS } from '../../theme'
import Button from '../Button'
import Dialog from '../Dialog'
import Divider from '../Divider'
import Glyph from '../Glyph'
import Grid from '../Grid'
import Markup from '../Markup'
import Overlay from '../Overlay'
import Radio from '../Forms/Radio'
import RadioGroup from '../Forms/RadioGroup'
import Section from '../Section'
import State from '../State'
import Status from '../Status'

import Card from '../Card'
import CardTitle from '../CardTitle'
import CardSubtitle from '../CardSubtitle'
import CardHeader from '../CardHeader'

import { apiGet, apiCreate, apiUpdate } from '../../modules/api'
import { amount } from '../../utils/functions'

import StripeCardElement from '../Stripe/StripeCardElement'

const stripePromise = loadStripe(process.env.BH_STRIPE_BEHAVE_PUBLIC_KEY)

const Message = ({ glyph, title, description }: any) => (
  <div css={styles.message.root}>
    <Glyph glyph={glyph} />
    <h2 css={styles.message.title}>{title}</h2>
    <p css={styles.message.description}>{description}</p>
  </div>
)

const PurchasePaymentOverlay = (props: any) => {
  const { onClose, product } = props

  const stripe = useStripe()
  const elements = useElements()

  const tenant = useSelector((state) => state.me.tenant)

  const [loading, setLoading] = React.useState(false)
  const [valid, setValid] = React.useState(false)
  const [errors, setErrors] = React.useState(null)
  const [processing, setProcessing] = React.useState(false)

  const [succeeded, setSucceeded] = React.useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(tenant?.stripe_default_payment_id)

  const payment_methods = useSelector((state: any) => state.data?.payment_methods?.data)
  const hasPaymentMethods = size(payment_methods?.cards) > 0

  const purchaseProduct = async () => {
    setProcessing(true)

    let setupIntent = null

    if (!hasPaymentMethods && stripe) {
      try {
        const result = await apiCreate({
          url: `/apps/stripe/create_setup_intent`,
          notify: false,
        })

        setupIntent = result.data

        const response = await stripe.confirmCardSetup(setupIntent.client_secret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: `${tenant.first_name} ${tenant.last_name}`,
            },
          },
        })

        // support errors
        if (response.error) {
          setErrors(response.error.message)
          setProcessing(false)
          return
        }

        if (errors) setErrors(null)

        await apiUpdate({
          url: '/me/tenant',
          params: { stripe_default_payment_id: response.setupIntent?.payment_method },
          notify: false,
        })
      } catch (error) {
        console.debug(error)

        try {
          apiCreate({
            url: `/apps/stripe/cancel_setup_intent`,
            params: { intent: setupIntent.id },
            notify: false,
          })
        } catch (error) {
          console.debug(error)
        }

        setProcessing(false)
        return
      }
    }

    await apiCreate({
      url: `/products/${product.id}/purchase`,
      params: {
        payment_method: selectedPaymentMethod,
      },
      notify: false,
    })

    setLoading(false)
    setSucceeded(true)
  }

  const closeOverlay = async () => {
    if (onClose) onClose()
  }

  const getPaymentMethods = async () => {
    try {
      setLoading(true)

      await apiGet({
        notify: false,
        name: 'payment_methods',
        url: `/apps/stripe/payment_methods`,
      })

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // update validity if payment method selected
  React.useEffect(() => {
    if (selectedPaymentMethod && !valid) setValid(true)
  }, [selectedPaymentMethod])

  return (
    <Overlay
      showBackdrop
      position="center"
      closeWrapper={(closeElement: any) => {
        if (succeeded) {
          return <div onClick={onClose}>{closeElement}</div>
        } else {
          return (
            <Dialog
              glyph="circle_error"
              title="Changed your mind?"
              message="This purchase will not be processed."
              yesColor="red"
              yesLabel="Yes, Cancel"
              noLabel="Go Back"
              onYes={closeOverlay}
            >
              {closeElement}
            </Dialog>
          )
        }
      }}
    >
      <Overlay.Header title={`Purchase ${product.name}`} glyph="dollar" />

      <Overlay.Content>
        {loading ? (
          <State isLoading={true} />
        ) : (
          <>
            {succeeded ? (
              <Section>
                <Message
                  glyph="tick_circle"
                  title="Thank you!"
                  description="Your purchase has been processed successfully. Someone from our team will reach out shortly to set up a meeting."
                />
                <Button label="Close" onClick={onClose} />
              </Section>
            ) : (
              <>
                <Section headingType="h3" description="You are purchasing this service">
                  <Card baseline="3rem">
                    <CardHeader graphic={<Glyph glyph={product.icon} size={20} />}>
                      <CardTitle title={product.name} />
                      <CardSubtitle subtitle={<Markup value={product.description} />} />
                    </CardHeader>
                  </Card>
                </Section>

                <Divider />

                {hasPaymentMethods ? (
                  <Section headingType="h3" title="Payment Methods" description="Please select the payment method you would like to use">
                    <RadioGroup
                      layout="vertical-dense"
                      model="payment_method"
                      defaultValue={tenant?.stripe_default_payment_id}
                      onChange={(state: any) => setSelectedPaymentMethod(state.value)}
                    >
                      {payment_methods?.cards.map((payment_method: any) => {
                        if (payment_method.type === 'us_bank_account') {
                          return (
                            <Radio
                              key={payment_method.id}
                              value={payment_method.id}
                              label={`${payment_method?.us_bank_account?.bank_name} **** ${payment_method?.us_bank_account?.last4}`}
                              icon={ICONS.billing}
                            />
                          )
                        } else {
                          return (
                            <Radio
                              key={payment_method.id}
                              value={payment_method.id}
                              label={`${startCase?.(payment_method.card?.brand)} **** ${payment_method.card?.last4}`}
                              description={
                                <Grid gap="0.25rem">
                                  <div>
                                    Expires {payment_method.card?.exp_month}/{payment_method.card?.exp_year}
                                  </div>
                                  {tenant?.stripe_default_payment_id === payment_method.id && <Status label="Default" color="blue" />}
                                </Grid>
                              }
                              icon={ICONS[payment_method.card?.brand] || ICONS.billing}
                            />
                          )
                        }
                      })}
                    </RadioGroup>
                  </Section>
                ) : (
                  <Section headingType="h3" title="Payment Method" description="Please add your card to set up a payment method">
                    <StripeCardElement valid={valid} error={errors} setValid={setValid} setError={setErrors} />
                  </Section>
                )}
              </>
            )}
          </>
        )}
      </Overlay.Content>

      {!succeeded && (
        <Overlay.Footer>
          <Button
            label={`Pay $${amount(product.discounted_price || product.price)}`}
            glyph="check"
            color="green"
            type="primary"
            onClick={purchaseProduct}
            isDisabled={!valid}
            isLoading={loading || processing}
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const styles = {
  dataList: {
    marginBottom: '1rem',
  },
  message: {
    root: {
      textAlign: 'center',
      padding: '2rem 0',
    },

    title: {
      margin: '0.25rem 0 0.5rem',
    },

    description: {
      margin: 0,
    },
  },
}

const PurchasePaymentOverlayWithElements = (props) => (
  <Elements stripe={stripePromise}>
    <PurchasePaymentOverlay {...props} />
  </Elements>
)

export default PurchasePaymentOverlayWithElements
