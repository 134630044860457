import { ASAM_CRITERIA_SECTIONS } from './configs/asam_criteria'
import { BIOPSYCHOSOCIAL_SECTIONS } from './configs/biopsychosocial'
import { CIWA_SECTIONS } from './configs/ciwa'
import { CLIENT_HEALTH_QUESTIONNAIRE_INITIAL_SCREENING_SECTIONS } from './configs/client_health_questionnaire_initial_screening'
import { CLIENT_HEALTH_QUESTIONNAIRE_SECTIONS } from './configs/client_health_questionnaire'
import { CLINICAL_DISCHARGE_SUMMARY_SECTIONS } from './configs/clinical_discharge_summary'
import { COWS_SECTIONS } from './configs/cows'
import { DISCHARGE_SUMMARY_SECTIONS } from './configs/discharge_summary'
import { FALL_RISK_ASSESSMENT_SECTIONS } from './configs/fall_risk_assessment'
import { HISTORY_AND_PHYSICAL_FOLLOW_UP_SECTIONS } from './configs/history_and_physical_follow_up'
import { HISTORY_AND_PHYSICAL_SECTIONS } from './configs/history_and_physical'
import { MENTAL_STATUS_EXAM_SECTIONS } from './configs/mental_status_exam'
import { NON_CLINICAL_DISCHARGE_SUMMARY_SECTIONS } from './configs/non_clinical_discharge_summary'
import { NURSING_ASSESSMENT_SECTIONS } from './configs/nursing_assessment'
import { NURSING_REASSESSMENT_SECTIONS } from './configs/nursing_reassessment'
import { PRE_SCREEN_SECTIONS } from './configs/pre_screen'
import { PROGRESS_NOTE_SECTIONS } from './configs/progress_note'
import { PROGRESS_REVIEW_SECTIONS } from './configs/progress_review'
import { PSYCHIATRIC_ASSESSMENT_SECTIONS } from './configs/psychiatric_assessment'
import { SUICIDE_ASSESSMENT_SECTIONS } from './configs/suicide_assessment'

export const CATEGORIES = {
  clinical: {
    id: 'clinical_assessment',
    name: 'Clinical',
    nameFull: 'Clinical Assessments',
    icon: 'clinical_assessments',
    slug: 'clinical',
    permissionName: 'clinical_assessments',
    showLinkedGoalsObjectivesInterventions: true,
  },
  medical: {
    id: 'medical_assessment',
    name: 'Medical',
    nameFull: 'Medical Assessments',
    icon: 'medical_assessments',
    slug: 'medical',
    permissionName: 'medical_assessments',
    showLinkedGoalsObjectivesInterventions: true,
  },
  nursing: {
    id: 'nursing',
    name: 'Nursing',
    nameFull: 'Nursing Assessments',
    icon: 'nursing',
    slug: 'nursing',
    permissionName: 'nursing',
    showLinkedGoalsObjectivesInterventions: true,
  },
  progress_review: {
    id: 'progress_review',
    name: 'Progress Review',
    nameFull: 'Progress Review',
    icon: 'progress_reviews',
    slug: 'progress-review',
    permissionName: 'progress_reviews',
    showLinkedGoalsObjectivesInterventions: true,
  },
  discharge: {
    id: 'discharge',
    name: 'Discharge',
    nameFull: 'Discharge Forms',
    icon: 'discharge_forms',
    slug: 'discharge',
    permissionName: 'discharges',
    showLinkedGoalsObjectivesInterventions: false,
  },
}

export const DATA_FORM_SETTINGS = {
  progress_review: {
    progress_review: {
      name: 'Progress Review',
      slug: 'progress-review',
      sections: PROGRESS_REVIEW_SECTIONS,
    },
  },
  clinical_assessment: {
    asam_criteria: {
      name: 'ASAM Criteria',
      slug: 'asam-criteria',
      sections: ASAM_CRITERIA_SECTIONS,
    },
    biopsychosocial: {
      name: 'Biopsychosocial',
      slug: 'biopsychosocial',
      sections: BIOPSYCHOSOCIAL_SECTIONS,
    },
    biopsychosocial_substance_abuse: {
      name: 'Biopsychosocial (Substance Abuse)',
      slug: 'biopsychosocial-substance-abuse',
      sections: BIOPSYCHOSOCIAL_SECTIONS,
    },
    biopsychosocial_mental_health: {
      name: 'Biopsychosocial (Mental Health)',
      slug: 'biopsychosocial-mental-health',
      sections: BIOPSYCHOSOCIAL_SECTIONS,
    },
    biopsychosocial_eating_disorder: {
      name: 'Biopsychosocial (Eating Disorder)',
      slug: 'biopsychosocial-eating-disorder',
      sections: BIOPSYCHOSOCIAL_SECTIONS,
    },
    biopsychosocial_peer: {
      name: 'Biopsychosocial (Peer)',
      slug: 'biopsychosocial-peer',
      sections: BIOPSYCHOSOCIAL_SECTIONS,
    },
    biopsychosocial_developmental_disabilities: {
      name: 'Biopsychosocial (Developmental Disabilities)',
      slug: 'biopsychosocial-developmental-disabilities',
      sections: BIOPSYCHOSOCIAL_SECTIONS,
    },
    biopsychosocial_child_and_adolescent: {
      name: 'Biopsychosocial (Child and Adolescent)',
      slug: 'biopsychosocial-child-and-adolescent',
      sections: BIOPSYCHOSOCIAL_SECTIONS,
    },
    mental_status_exam: {
      name: 'Mental Status Exam',
      slug: 'mental-status-exam',
      sections: MENTAL_STATUS_EXAM_SECTIONS,
    },
    prescreen: {
      name: 'Pre-Screen',
      slug: 'pre-screen',
      sections: PRE_SCREEN_SECTIONS,
    },
    prescreen_substance_abuse: {
      name: 'Pre-Screen (Substance Abuse)',
      slug: 'pre-screen-substance-abuse',
      sections: PRE_SCREEN_SECTIONS,
    },
    prescreen_mental_health: {
      name: 'Pre-Screen (Mental Health)',
      slug: 'pre-screen-mental-health',
      sections: PRE_SCREEN_SECTIONS,
    },
    prescreen_eating_disorder: {
      name: 'Pre-Screen (Eating Disorder)',
      slug: 'pre-screen-eating-disorder',
      sections: PRE_SCREEN_SECTIONS,
    },
    prescreen_peer: {
      name: 'Pre-Screen (Peer)',
      slug: 'pre-screen-peer',
      sections: PRE_SCREEN_SECTIONS,
    },
    prescreen_developmental_disabilities: {
      name: 'Pre-Screen (Developmental Disabilities)',
      slug: 'pre-screen-developmental-disabilities',
      sections: PRE_SCREEN_SECTIONS,
    },
    prescreen_child_and_adolescent: {
      name: 'Pre-Screen (Child and Adolescent)',
      slug: 'pre-screen-child-and-adolescent',
      sections: PRE_SCREEN_SECTIONS,
    },
    suicide_assessment: {
      name: 'Suicide Assessment',
      slug: 'suicide-assessment',
      sections: SUICIDE_ASSESSMENT_SECTIONS,
    },
  },
  medical_assessment: {
    client_health_questionnaire: {
      name: 'Client Health Questionnaire',
      slug: 'client-health-questionnaire',
      sections: CLIENT_HEALTH_QUESTIONNAIRE_SECTIONS,
    },
    client_health_questionnaire_initial_screening: {
      name: 'Client Health Questionnaire & Initial Screening',
      slug: 'client-health-questionnaire-initial-screening',
      sections: CLIENT_HEALTH_QUESTIONNAIRE_INITIAL_SCREENING_SECTIONS,
    },
    history_and_physical: {
      name: 'History And Physical',
      slug: 'history-and-physical',
      sections: HISTORY_AND_PHYSICAL_SECTIONS,
    },
    history_and_physical_eating_disorder: {
      name: 'History And Physical (Eating Disorder)',
      slug: 'history-and-physical-eating-disorder',
      sections: HISTORY_AND_PHYSICAL_SECTIONS,
    },
    history_and_physical_child_and_adolescent: {
      name: 'History And Physical (Child and Adolescent)',
      slug: 'history-and-physical-child-and-adolescent',
      sections: HISTORY_AND_PHYSICAL_SECTIONS,
    },
    history_and_physical_follow_up: {
      name: 'History And Physical Follow-Up',
      slug: 'history-and-physical-follow-up',
      sections: HISTORY_AND_PHYSICAL_FOLLOW_UP_SECTIONS,
    },
    history_and_physical_follow_up_eating_disorder: {
      name: 'History And Physical Follow-Up (Eating Disorder)',
      slug: 'history-and-physical-follow-up',
      sections: HISTORY_AND_PHYSICAL_FOLLOW_UP_SECTIONS,
    },
    history_and_physical_follow_up_child_and_adolescent: {
      name: 'History And Physical Follow-Up (Child and Adolescent)',
      slug: 'history-and-physical-follow-up',
      sections: HISTORY_AND_PHYSICAL_FOLLOW_UP_SECTIONS,
    },
    psychiatric_assessment: {
      name: 'Psychiatric Assessment',
      slug: 'psychiatric-assessment',
      sections: PSYCHIATRIC_ASSESSMENT_SECTIONS,
    },
    psychiatric_assessment_substance_abuse: {
      name: 'Psychiatric Assessment (Substance Abuse)',
      slug: 'psychiatric-assessment-substance-abuse',
      sections: PSYCHIATRIC_ASSESSMENT_SECTIONS,
    },
    psychiatric_assessment_child_and_adolescent: {
      name: 'Psychiatric Assessment (Child and Adolescent)',
      slug: 'psychiatric-assessment-child-and-adolescent',
      sections: PSYCHIATRIC_ASSESSMENT_SECTIONS,
    },
  },
  nursing: {
    ciwa: {
      name: 'CIWA',
      slug: 'ciwa',
      sections: CIWA_SECTIONS,
    },
    cows: {
      name: 'COWS',
      slug: 'cows',
      sections: COWS_SECTIONS,
    },
    fall_risk_assessment: {
      name: 'Fall Risk Assessment',
      slug: 'fall-risk-assessment',
      sections: FALL_RISK_ASSESSMENT_SECTIONS,
    },
    nursing_assessment: {
      name: 'Nursing Assessment',
      slug: 'nursing-assessment',
      sections: NURSING_ASSESSMENT_SECTIONS,
    },
    nursing_assessment_eating_disorder: {
      name: 'Nursing Assessment (Eating Disorder)',
      slug: 'nursing-assessment-eating-disorder',
      sections: NURSING_ASSESSMENT_SECTIONS,
    },
    nursing_assessment_child_and_adolescent: {
      name: 'Nursing Assessment (Child and Adolescent)',
      slug: 'nursing-assessment-child-and-adolescent',
      sections: NURSING_ASSESSMENT_SECTIONS,
    },
    nursing_reassessment: {
      name: 'Nursing Reassessment',
      slug: 'nursing-reassessment',
      sections: NURSING_REASSESSMENT_SECTIONS,
    },
    nursing_reassessment_eating_disorder: {
      name: 'Nursing Reassessment (Eating Disorder)',
      slug: 'nursing-reassessment-eating-disorder',
      sections: NURSING_REASSESSMENT_SECTIONS,
    },
    nursing_reassessment_child_and_adolescent: {
      name: 'Nursing Reassessment (Child and Adolescent)',
      slug: 'nursing-reassessment-child-and-adolescent',
      sections: NURSING_REASSESSMENT_SECTIONS,
    },
    progress_note: {
      name: 'Progress Note',
      slug: 'progress-note',
      sections: PROGRESS_NOTE_SECTIONS,
    },
  },
  discharge: {
    discharge_summary: {
      name: 'Discharge Summary',
      slug: 'discharge-summary',
      sections: DISCHARGE_SUMMARY_SECTIONS,
    },
    clinical_discharge_summary: {
      name: 'Clinical Discharge Summary',
      slug: 'clinical-discharge-summary',
      sections: CLINICAL_DISCHARGE_SUMMARY_SECTIONS,
    },
    non_clinical_discharge_summary: {
      name: 'Non Clinical Discharge Summary',
      slug: 'non-clinical-discharge-summary',
      sections: NON_CLINICAL_DISCHARGE_SUMMARY_SECTIONS,
    },
  },
}
