import React from 'react'
import { NavLink, useParams } from 'react-router-dom-v5-compat'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, HelpTagIframe, Page } from '@behavehealth/components'
import { ProgramManagingStaffTable } from '@behavehealth/constructs/Programs/ProgramManagingStaffTable'

const pageConfig = {
  icon: 'employees',
  title: 'Managing Staff',
  help: <HelpTagIframe id="program_assigned_staff" />,
}

const AssignedStaff: React.FC = () => {
  const { resource_id }: any = useParams()

  const { data, isLoading }: any = useGet({
    name: ['program', resource_id, 'managing-staff'],
    url: `/programs/${resource_id}/managing_staff`,
  })

  return (
    <Page
      feature="employees"
      breakpoint="0"
      // actions={
      //   <Button
      //     type="primary"
      //     glyph="add"
      //     label="Assign Staff"
      //     link={`${match.url}/import`}
      //     // permission="assigned_staff.create"
      //   />
      // }
      {...pageConfig}
    >
      <Card>
        <ProgramManagingStaffTable mainLinkAs={NavLink} data={data} isLoading={isLoading} />
      </Card>
    </Page>
  )
}

export default withPageError(withMarketing(AssignedStaff, pageConfig))
