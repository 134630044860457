import React from 'react'

import Card from '../../../components/Card'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import SmartStatus from '../../../components/SmartStatus'

const STATUSES = {
  completed: {
    label: 'Completed',
    color: 'green',
  },
  pending: {
    label: 'Pending',
    color: 'orange',
  },
}

export const ChecklistItem = (props: any) => {
  const { title, status, description, children, className } = props

  return (
    <Card baseline="2.75rem" variant="variant-list" className={className}>
      <CardHeader className="!border-none !shadow-none">
        <CardTitle title={title} className="!text-[1rem] !font-[600]" />
        {description}
        {children}
        {status && <SmartStatus small status={status} statuses={STATUSES} />}
      </CardHeader>
    </Card>
  )
}
