import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import DataExportsTable from '@behavehealth/components/Tables/DataExportsTable'
import Page from '@behavehealth/components/Page'

const pageConfig = {
  feature: 'data_exports',
}

const DataExports = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const actions = (
    <Button label="Add Data Export" glyph="add" type="primary" link={`${match.url}/new`} permission="clients.actions.export" />
  )

  const [shouldPoll, setShouldPoll] = React.useState(false)

  const { data, isLoading, refetch, isRefetching }: any = useGet({
    name: ['client', resource_id, 'data-exports'],
    url: `/residents/${resource_id}/data_exports`,
    options: shouldPoll && { refetchInterval: 5 * 1000, refetchOnMount: true, staleTime: 0 },
  })

  const isEmpty = size(data) == 0

  React.useEffect(() => {
    if (isEmpty) return null

    for (const item of data) {
      if (item.status === 'processing') {
        setShouldPoll(true)
        break
      }

      setShouldPoll(false)
    }
  }, [data, isEmpty])

  return (
    <Page breakpoint="0" actions={!isEmpty && actions} {...pageConfig}>
      <div className="grid grid-cols-[100%]">
        <Card>
          <DataExportsTable
            data={data}
            isLoading={isLoading}
            emptyActions={actions}
            localStorageKey="clients_data_exports"
            titleAfter={
              <>
                <div>
                  <Button label="Reload" size={100} glyph="reset" isLoading={isLoading || isRefetching || shouldPoll} onClick={refetch} />
                </div>
              </>
            }
            to={(id: string) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
          />
        </Card>
      </div>
    </Page>
  )
}

export default withPageError(withMarketing(DataExports, pageConfig))
