import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'
import size from 'lodash/size'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'

import { useGet } from '@behavehealth/hooks/useNewAPI'

import { Card, Button, Page } from '@behavehealth/components'

import { QuickTextTable } from '@behavehealth/constructs/QuickText/QuickTextTable'
import { QuickTextOverlay } from '@behavehealth/constructs/QuickText/QuickTextOverlay'

export const QuickText = () => {
  return (
    <div>
      <QuickTextIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<QuickTextOverlay useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const QuickTextIndex = () => {
  const { data, isLoading }: any = useGet({
    name: 'quick-paragraphs',
    url: `/quick_paragraphs`,
  })

  const isEmpty = size(data) === 0

  const actions = <Button as={Link} label="Add Quick Text" type="primary" glyph="add" link="new" permission="settings.quick_text.create" />

  return (
    <Page title="Quick Text" feature="quick_text" actions={!isEmpty && actions}>
      <Card>
        <QuickTextTable mainLinkAs={NavLink} data={data} isLoading={isLoading} emptyActions={actions} to={(id: string) => id} />
      </Card>
    </Page>
  )
}
