import React from 'react'
import { connect } from 'react-redux'

import { getFirstValueFromMap } from '../../../../utils/functions'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import Button from '../../../Button'
import Form from '../../../Forms/Form'
import Grid from '../../../Grid'
import Overlay from '../../../Overlay'
import PayerCard from '../../../Cards/PayerCard'
import Section from '../../../Section'
import SendNotificationForm from '../../../Forms/elements/SendNotificationForm'

import { OverlayBase, defaultMapDispatchToProps } from '../OverlayBase'

class SendFinancialPlanOverlay extends OverlayBase {
  renderHeader = () => <Overlay.Header icon="financials" title="Send Financial Plan" />

  renderContent = () => {
    const { signees } = this.props
    const hasSignees = signees && signees.length > 0

    return (
      <Overlay.Content>
        <Form isEditable getForm={this.form} initialModel={this.props.data} onValidationUpdate={this.onValidationUpdate}>
          <Section>
            <Grid gap="1.75rem">
              {hasSignees &&
                signees.map((signee) => (
                  <Grid key={signee.id} gap="0.75rem">
                    <PayerCard payer={signee} variant="variant-plain" />
                    <SendNotificationForm
                      key={signee.id}
                      model={signee.id}
                      email={signee.reference.email}
                      phone={signee.reference.phone_no}
                    />
                  </Grid>
                ))}
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    return (
      <Overlay.Footer>
        <Button
          label="Send Financial Plan"
          glyph="check"
          type="primary"
          color="green"
          flex="100 1 auto"
          onClick={this.save}
          isDisabled={this.state.isInvalid}
          isLoading={this.props.loading}
        />
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => {
  const fp = getFirstValueFromMap(state.data.financial_plan?.data)
  return {
    financial_plan: fp,
    signees: fp?.signees,
    loading: state.data.financial_plan?.loading,
    online: state.common.global?.online,
    idle: state.common.global.idle,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(SendFinancialPlanOverlay))
