import React from 'react'
import clsx from 'clsx'

import GridTableCell from './GridTableCell'
import GridTableGroup from './GridTableGroup'
import GridTableHeader from './GridTableHeader'
import GridTableRow from './GridTableRow'

interface Composition {
  Cell: React.FC
  Group: React.FC
  Header: React.FC
  Row: React.FC
}

type Props = {
  columnsWidth?: string
  firstColumnSticky?: boolean
  templateColumns?: string
  className?: string
  useBanding?: boolean
  useRowHover?: boolean
  onClick?: any
}

const GridTable: React.FC<Props> & Composition = (props) => {
  const {
    children,
    columnsWidth,
    firstColumnSticky,
    templateColumns,
    className,
    useBanding = true,
    useRowHover = true,
    onClick,
    testKey,
  } = props

  const classNames = clsx({
    'first-column-sticky': firstColumnSticky,
    'use-equal-columns': columnsWidth,
    'use-banding': useBanding,
    'use-row-hover': useRowHover,
    [className]: className,
  })

  const cssVariables: any = {
    '--columns-width': columnsWidth,
    '--template-columns': templateColumns,
  }

  return (
    <div data-test={testKey} css={styles} style={cssVariables} className={classNames} onClick={onClick}>
      <div>{children}</div>
    </div>
  )
}

const styles: any = {
  display: 'grid',
  overflowX: 'auto',
  overflowY: 'hidden',
}

GridTable.Cell = GridTableCell
GridTable.Cell.displayName = 'GridTableCell'

GridTable.Header = GridTableHeader
GridTable.Header.displayName = 'GridTableHeader'

GridTable.Row = GridTableRow
GridTable.Row.displayName = 'GridTableRow'

GridTable.Group = GridTableGroup
GridTable.Group.displayName = 'GridTableGroup'

export default GridTable
