import React from 'react'
import { useParams } from 'react-router-dom'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'
import kebabCase from 'lodash/kebabCase'

import { titleCase } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import Alert from '@behavehealth/components/Alert'
import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import Button from '@behavehealth/components/Button'
import PageHeader from '@behavehealth/components/PageHeader'
import Portal from '@behavehealth/components/Portal'
import ReferralStatus from '@behavehealth/constructs/Referrals/ReferralStatus'
import Tabs from '@behavehealth/components/Tabs'
import Json from '@behavehealth/components/Json'

import { ReferralBuilderOverlay } from '@behavehealth/constructs/Referrals/ReferralBuilderOverlay'
import { ReferralReceivedOverlay } from '@behavehealth/constructs/Referrals/ReferralReceivedOverlay'
import { ReferralSentOverlay } from '@behavehealth/constructs/Referrals/ReferralSentOverlay'
import { SummonOverlay } from '@behavehealth/components'

import { useMockReferralsStore } from '@behavehealth/constructs/Referrals/useMockReferralsStore'
import { useGet } from '@behavehealth/hooks/useNewAPI'

export const REFERRAL_STATUSES = {
  all: {
    label: 'All',
    color: 'orange',
  },
  pending: {
    label: 'Pending',
    color: 'orange',
  },
  in_progress: {
    label: 'In Progress',
    color: 'blue',
  },
  accepted: {
    label: 'Accepted',
    color: 'green',
  },
  declined: {
    label: 'Declined',
    color: 'green',
  },
}

export const RootReferrals: React.FC = () => {
  const match = useRouteMatch()

  const { resource_id: id }: any = useParams()

  const { data: partnership }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  if (!partnership) return null

  return (
    <>
      <div css={STYLES.root}>
        <div className="bg-white sticky top-0 z-10 flex-0">
          <PageHeader
            title="Referrals"
            icon="referrals"
            aside={
              <>
                <SummonOverlay
                  overlay={
                    <ReferralBuilderOverlay
                      dataID="new"
                      initialData={{
                        partners: [partnership],
                        partner_ids: [partnership.id],
                      }}
                    />
                  }
                >
                  <Button label="Send New Referral" glyph="check_out" type="primary" />
                </SummonOverlay>
              </>
            }
          />

          <Alert type="warning" className="!rounded-none">
            <div className="flex flex-nowrap items-center">
              <div className="text-[1.2rem] mr-2">🚧</div>
              <div>
                <h4>Feature Under Development</h4>
                <div>
                  The Referrals feature you're currently viewing is still in active development. While you can explore this page, please
                  note that it's not yet functional.
                </div>
              </div>
            </div>
          </Alert>

          <Tabs>
            <Tabs.List className="px-4">
              <Tabs.Item label="Referrals Sent" to={`${match.url}/sent`} />
              <Tabs.Item label="Referrals Received" to={`${match.url}/received`} />
            </Tabs.List>
          </Tabs>
        </div>

        <div css={STYLES.content}>
          <Switch>
            <Route path={`${match.path}/sent`} component={ReferralsSentPage} />
            <Route path={`${match.path}/received`} component={ReferralsReceivedPage} />

            <Redirect exact from={match.url} to={`${match.url}/sent`} />
          </Switch>
        </div>
      </div>

      <Portal type="z90">
        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <Route exact path={`${match.path}/sent/:status/:id`}>
            {(routeProps) => {
              return <ReferralSentOverlay dataID={routeProps.match?.params?.id} />
            }}
          </Route>

          <Route exact path={`${match.path}/received/:status/:id`}>
            {(routeProps) => {
              return <ReferralReceivedOverlay dataID={routeProps.match?.params?.id} />
            }}
          </Route>
        </AnimatedSwitch>
      </Portal>
    </>
  )
}

const ReferralsSentPage = () => {
  const match = useRouteMatch()

  return (
    <div>
      <Tabs>
        <Tabs.List className="mb-4">
          {Object.keys(REFERRAL_STATUSES).map((statusKey) => (
            <Tabs.Item label={REFERRAL_STATUSES[statusKey].label} to={`${match.url}/${kebabCase(statusKey)}`} />
          ))}
        </Tabs.List>
      </Tabs>

      <Switch>
        <Route path={`${match.path}/:status`} component={ReferralsSentDataTable} />

        <Redirect exact from={match.url} to={`${match.url}/all`} />
      </Switch>
    </div>
  )
}

const ReferralsReceivedPage = () => {
  const match = useRouteMatch()

  return (
    <div>
      <Tabs>
        <Tabs.List className="mb-4">
          {Object.keys(REFERRAL_STATUSES).map((statusKey) => (
            <Tabs.Item label={REFERRAL_STATUSES[statusKey].label} to={`${match.url}/${kebabCase(statusKey)}`} />
          ))}
        </Tabs.List>
      </Tabs>

      <Switch>
        <Route path={`${match.path}/:status`} component={ReferralsReceivedDataTable} />

        <Redirect exact from={match.url} to={`${match.url}/all`} />
      </Switch>
    </div>
  )
}

const ReferralsSentDataTable = () => {
  const match = useRouteMatch()
  const { status }: any = useParams()

  const { resource_id: partnershipId }: any = useParams()

  const { data: organization }: any = useGet({
    name: ['organization', partnershipId],
    url: `/organizations/${partnershipId}`,
  })

  const storeData = useMockReferralsStore((state) => state.data)

  const data = React.useMemo(() => {
    if (!organization) return null

    return storeData?.filter((o) => o.sent_to?.id === organization?.other_partner.id)
  }, [organization, storeData, status])

  const columns = React.useMemo(
    () => [
      {
        title: 'Client Name',
        model: 'client',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} value={value?.name} avatar="" to={`${match.url}/${data.id}`} />
        },
      },
      {
        title: 'Sent to Partner',
        model: 'sent_to',
        type: 'profile',
      },
      {
        title: 'Status From',
        model: 'sent_from_status',
        width: 200,
        formatValue: ({ value }: any) => <ReferralStatus status={value} />,
      },
      {
        title: 'Status To',
        model: 'sent_to_status',
        width: 200,
        formatValue: ({ value }: any) => <ReferralStatus status={value} />,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
    ],
    [match.url],
  )

  return (
    <>
      <div>
        <DataTable
          key={status}
          asCard
          title={`${titleCase(status)} Referrals`}
          icon="referrals"
          columns={columns}
          data={data}
          meta={{ count: size(data), page: 1, prev: null, next: null, pages: 1 }}
          batchActionsConfig={[
            {
              label: (count) => (count ? `Cancel Referral (${count})` : 'Cancel Referral'),
              glyph: 'decline',
              color: 'red',
              action: async ({ ids }: any) => {},
            },
            {
              type: 'delete',
              action: async ({ ids }: any) => {},
            },
          ]}
        />
      </div>

      {/* <Json data={data} /> */}
    </>
  )
}

const ReferralsReceivedDataTable = () => {
  const match = useRouteMatch()
  const { status }: any = useParams()
  const { resource_id: partnershipId }: any = useParams()

  const { data: organization }: any = useGet({
    name: ['organization', partnershipId],
    url: `/organizations/${partnershipId}`,
  })

  const storeData = useMockReferralsStore((state) => state.data)

  const data = React.useMemo(() => {
    if (!organization) return null

    return storeData?.filter((o) => o.sent_from?.id === organization?.other_partner.id)
  }, [organization, storeData, status])

  const columns = React.useMemo(
    () => [
      {
        title: 'Client Name',
        model: 'client',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} value={value?.name} avatar="" to={`${match.url}/${data.id}`} />
        },
      },
      {
        title: 'Sent from Partner',
        model: 'sent_from',
        type: 'profile',
      },
      // {
      //   title: 'Status From',
      //   model: 'sent_from_status',
      //   width: 200,
      //   formatValue: ({ value }: any) => <ReferralStatus status={value} />,
      // },
      {
        title: 'Status',
        model: 'sent_to_status',
        width: 200,
        formatValue: ({ value }: any) => <ReferralStatus status={value} />,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
    ],
    [match.url],
  )

  return (
    <>
      <div>
        <DataTable
          key={status}
          asCard
          title={`${titleCase(status)} Referrals`}
          icon="referrals"
          columns={columns}
          data={data}
          meta={{ count: size(data), page: 1, prev: null, next: null, pages: 1 }}
        />
      </div>

      {/* <Json data={data} /> */}
    </>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    overflow: 'hidden',
  },

  content: {
    padding: '1rem',
    paddingTop: 0,
    overflowY: 'auto',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
  },
}

export const Referrals = withPageError(RootReferrals)
