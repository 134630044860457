import React from 'react'
import { v4 as uuid } from 'uuid'
import isEqual from 'react-fast-compare'
import produce from 'immer'

import Field from './Field'
import BaseInput from './BaseInput'
import { withFormContext } from './context'
import Grid from '../Grid'

import FieldBase from './FieldBase'

class DigitsPin extends FieldBase {
  constructor(props) {
    super(props)

    let pins = []
    for (let i = 0; i < props.digits; i++) pins[i] = ''

    this.state = {
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: '',
      pins: pins,
      isValid: props.checkValidity ? false : true,
      isInvalid: props.checkValidity ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isBlur: false,
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: '',
      pins: pins,
      isValid: props.checkValidity ? false : true,
      isInvalid: props.checkValidity ? true : false,
    }

    this.updateType = 'DATA'
  }

  processChangeValue = (args: any) => {
    const { digits, digitsOnly } = this.props

    const newState = produce(this.state, (draft: any) => {
      let pins = draft.pins
      if (digitsOnly) {
        // only digits allowed
        if (!isNaN(args.value)) {
          pins[args.index] = args.value
        } else {
          return // no need to update anything
        }
      } else {
        pins[args.index] = args.value
      }

      // focus on next element
      if (args.index < digits - 1) args.nextSibling.focus()

      draft.pins = pins
      draft.value = pins.join('')
    })

    this.setState({
      pins: newState.pins,
      value: newState.value,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })

    return newState
  }

  onFocus = (event: any) => {
    if (event.target) event.target.select()
    this.queue({ type: 'FOCUS', event })
  }

  /*
    RENDER
  */
  render() {
    const { id, isRequired, isValid, errors, pins } = this.state
    const { digits, isEditable, allowPin, showHeader, inputStyles, ...others } = this.props

    let inputs = []
    for (let i = 0; i < digits; i++) {
      inputs.push(
        <BaseInput
          value={'•'.repeat(pins[i].length)}
          key={i}
          id={`${id}-${i}`}
          name={`${id}-${i}`}
          type="text"
          onChange={(event: any) => this.changeValue({ index: i, value: event.target.value, nextSibling: event.target.nextSibling })}
          onFocus={this.onFocus}
          min={1}
          max={1}
          maxLength={1}
          autocomplete="new-password"
          css={[styles, inputStyles]}
        />,
      )
    }

    return (
      <Field id={id} errors={errors} isValid={isValid} isRequired={isRequired} layout={false} {...others}>
        <Grid columns={`repeat(${digits}, min-content)`} justifyContent="center" gap={8}>
          {inputs}
        </Grid>
      </Field>
    )
  }
}

const styles = {
  padding: '0 !important',
  textAlign: 'center !important',
  fontSize: '2rem !important',
  width: '4rem',
  height: '6rem',
}

DigitsPin.defaultProps = {
  isEditable: true,
  digits: 4,
  digitsOnly: true,
  checkValidity: true,
  validateOn: 'blur-change',
}

export default withFormContext(DigitsPin)
