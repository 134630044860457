import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'

import { RosterServiceStatus } from '@behavehealth/constructs/RCM/components/RosterServiceStatus'

import InsuranceRosterItemRecordStatus from '@behavehealth/components/Statuses/InsuranceRosterItemRecordStatus'
import LevelOfCareStatus from '@behavehealth/components/Statuses/LevelOfCareStatus'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { getClientLink } from '@behavehealth/utils/functions'
import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'
import { AllClientsTable } from '@behavehealth/components/Forms/Selectors/tables/AllClientsTable'

const ROSTER_CATEGORY = {
  peer_note: 'Peer Note',
  daily_note: 'Daily Note',
  clinical_note: 'Clinical Note',
  ua_test: 'UA Test',
  breathalyzer_test: 'Breathalyzer Test',
  custom_note: 'Custom Note',
  clinical_assessment: 'Clinical Assessment',
  medical_assessment: 'Medical Assessment',
  nursing_assessment: 'Nursing Assessment',
}

const CLINICAL_NOTES_CATEGORIES = {
  individual: 'Individual',
  group_note: 'Group',
  case_management: 'Case Management',
  family_session: 'Family Session',
}

const PEER_NOTES_CATEGORIES = {
  individual: 'Individual',
  group_note: 'Group',
}

const DAILY_NOTES_CATEGORIES = {
  individual: 'Individual',
  group_note: 'Group',
}

export const RosterNew = ({ onItemSelect, onClaimOpen, onStatusOpen, onNotesOpen }: any) => {
  const match = useRouteMatch()

  const columns = React.useMemo(
    () => [
      {
        title: 'Roster Item',
        model: 'identifier',
        width: 200,
        disableHide: true,
        disableSelect: true,
        formatValue: ({ data, value }: any) => <MainCell testKey="main_cell" id={data?.id} value={value} to={`${match.url}/${data?.id}`} />,
      },
      {
        title: 'Roster Item Status',
        model: 'status',
        formatValue: ({ value }: any) => <RosterServiceStatus status={value} />,
      },
      {
        title: 'Client',
        id: 'client',
        model: 'client',
        type: 'profile',
        config: {
          setLink: ({ rowData }: any) => `${getClientLink(rowData.client)}/general-info`,
        },
      },
      {
        title: 'Authorizations Quick View',
        model: 'authorization',
        disableSort: true,
        formatValue: ({ data }: any) => {
          return <LinkCell isExternal value="View Authorizations" href={`${getClientLink(data.client)}/authorizations`} />
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'insurance_roster.edit',
      },
      {
        title: 'Insurance Policy',
        id: 'client_insurance_policy_name',
        model: 'client_insurance_policy.insurance_name',
      },
      {
        title: 'Service Date',
        model: 'service_date',
        type: 'date',
      },
      {
        title: 'Record Type',
        model: 'category',
        formatValue: ({ data, value }) => {
          if (data?.category === 'clinical_note')
            return `${ROSTER_CATEGORY[value]} (${CLINICAL_NOTES_CATEGORIES[data?.record?.category] || '–'})`
          else if (data?.category === 'peer_note')
            return `${ROSTER_CATEGORY[value]} (${PEER_NOTES_CATEGORIES[data?.record?.category] || '–'})`
          else if (data?.category === 'daily_note')
            return `${ROSTER_CATEGORY[value]} (${DAILY_NOTES_CATEGORIES[data?.record?.category] || '–'})`
          else if (data?.category === 'clinical_assessment') return `${ROSTER_CATEGORY[value]} (${data.record?.name || '–'})`
          else if (data?.category === 'medical_assessment') return `${ROSTER_CATEGORY[value]} (${data.record?.name || '–'})`
          else if (data?.category === 'nursing_assessment') return `${ROSTER_CATEGORY[value]} (${data.record?.name || '–'})`
          else return ROSTER_CATEGORY[value]
        },
      },
      {
        width: 100,
        title: 'Is Billable',
        model: 'record.is_billable',
        type: 'boolean',
      },
      {
        width: 180,
        title: 'Fee Schedule',
        model: 'record.insurance_new_fee_schedule.name',
      },
      {
        width: 180,
        title: 'Insurance Code',
        model: 'record.insurance_new_fee_schedule_service.insurance_new_code.service_name',
      },
      {
        title: 'Start Date and Time',
        model: 'record_started_at',
        type: 'date',
      },
      {
        title: 'End Date and Date',
        model: 'record_ended_at',
        type: 'date',
      },
      {
        title: 'Session Duration',
        model: 'record_minutes_between_start_and_end',
        formatValue: ({ value }) => {
          if (!value) return '–'
          else return `${value} minutes`
        },
      },
      {
        title: 'Client Presence',
        model: 'record_client_presence',
        width: 130,
        type: 'boolean',
      },
      {
        title: 'Biller Notes',
        model: 'biller_notes',
        type: 'rich_text',
      },
      {
        title: 'Record Status',
        model: 'record_status',
        formatValue: ({ value }: any) => <InsuranceRosterItemRecordStatus status={value} />,
      },
      {
        title: 'Author',
        id: 'record_author_name',
        model: 'record_author',
        type: 'profile',
      },
      {
        title: 'Author Submit Date',
        model: 'record_author_submitted_at',
        type: 'date_time',
      },
      {
        title: 'Author Sign-Off Date',
        model: 'record_author_signed_off_at',
        type: 'date_time',
      },
      {
        title: 'Supervisor',
        id: 'record_supervisor_name',
        model: 'record_supervisor',
        type: 'profile',
      },
      {
        title: 'Added Date',
        model: 'record_created_at',
        type: 'date_time',
      },
      {
        title: 'Sign-Off Date',
        model: 'record_signed_off_at',
        type: 'date_time',
      },
      {
        title: 'Diagnosis Codes',
        model: 'client_diagnoses_codes',
      },
      {
        title: 'Authorization #',
        model: 'client_insurance_authorization_identifier',
      },
      {
        title: 'Client Level of Care',
        model: 'client_level_of_care',
      },
      {
        title: 'Sessions Authorized',
        model: 'client_insurance_authorization_sessions_authorized',
      },
      {
        title: 'Claim ID',
        id: 'claim_identifier',
        model: 'insurance_claim.identifier',
      },
      {
        title: 'Service Line ID',
        model: 'insurance_claim_service_line',
        type: 'profile',
        disableSort: true,
      },
    ],
    [match.url],
  )

  const tableProps = useDataTable({
    name: ['insurance_roster_items', 'new'],
    endpoint: '/insurance_roster_items',
    params: { status: 'draft' },
    localStorageKey: 'insurance_roster_items_new_v1',
  })

  const { deleteRecords, batchUpdateRecords } = tableProps

  return (
    <DataTable
      {...tableProps}
      asCard
      canBatchSelect
      title="New Roster Items"
      icon="roster"
      columns={columns}
      filtersConfig={ROSTER_ITEM_FILTERS_CONFIG}
      batchActionsConfig={[
        {
          label: () => 'Create Claims',
          action: async ({ selectedRows }: any) => {
            onItemSelect(selectedRows)
            onClaimOpen(true)
          },
          glyph: 'add',
          buttonType: 'primary',
          // keepSelected: true,
        },
        {
          label: () => 'Update Status',
          action: async ({ selectedRows }: any) => {
            onItemSelect(selectedRows)
            onStatusOpen(true)
          },
          glyph: 'edit',
          buttonType: 'default',
          // keepSelected: true,
        },
        {
          label: () => 'Add Notes',
          action: async ({ selectedRows }: any) => {
            onItemSelect(selectedRows)
            onNotesOpen(true)
          },
          glyph: 'note',
          buttonType: 'default',
          // keepSelected: true,
        },
        {
          type: 'delete',
          action: async ({ ids }: any) => {
            await deleteRecords(ids.join(','))
          },
        },
      ]}
    />
  )
}

const ROSTER_ITEM_FILTERS_CONFIG = {
  client_insurance_policy_name: {
    label: 'Insurance Policy',
    type: 'string',
  },
  client: {
    label: 'Client',
    type: 'multi_overlay',
    polymorphic: false,
    selectTitle: (item: any) => item.name,
    icon: 'clients',
    table: <AllClientsTable defaultTab="current_clients" />,
  },
  tags: {
    label: 'Tags',
    type: 'tags',
  },
  identifier: {
    label: 'Roster Item',
    type: 'string',
  },
  status: {
    label: 'Roster Item Status',
    type: 'multi_select',
    options: [
      { label: 'New', value: 'draft' },
      { label: 'Processed', value: 'processed' },
      { label: 'Flagged', value: 'flagged' },
      { label: 'Archived', value: 'archived' },
    ],
  },
  category: {
    label: 'Record Type',
    type: 'multi_select',
    options: [
      {
        label: 'UA Test',
        value: 'ua_test',
      },
      {
        label: 'Breathalyzer Test',
        value: 'breathalyzer_test',
      },
      {
        label: 'Custom Note',
        value: 'custom_note',
      },
      {
        label: 'Clinical Assessment',
        value: 'clinical_assessment',
      },
      {
        label: 'Clinical Note (Individual)',
        value: 'clinical_note_individual',
      },
      {
        label: 'Clinical Note (Group)',
        value: 'clinical_note_group_note',
      },
      {
        label: 'Clinical Note (Case Management)',
        value: 'clinical_note_case_management',
      },
      {
        label: 'Clinical Note (Family Session)',
        value: 'clinical_note_family_session',
      },
      {
        label: 'Peer Note (Individual)',
        value: 'peer_note_individual',
      },
      {
        label: 'Peer Note (Group)',
        value: 'peer_note_group_note',
      },
      {
        label: 'Daily Note (Individual)',
        value: 'daily_note_individual',
      },
      {
        label: 'Daily Note (Group)',
        value: 'daily_note_group_note',
      },
      {
        label: 'Medical Assessment',
        value: 'medical_assessment',
      },
      {
        label: 'Nursing Assessment',
        value: 'nursing_assessment',
      },
    ],
  },
  record_client_presence: {
    label: 'Client Presence',
    type: 'boolean',
  },
  biller_notes: {
    label: 'Biller Notes',
    type: 'string',
  },
  record_status: {
    label: 'Record Status',
    type: 'multi_select',
    options: [
      {
        label: 'Draft',
        value: 'draft',
      },
      {
        label: 'Client Draft',
        value: 'client_draft',
      },
      {
        label: 'Employee Draft',
        value: 'employee_draft',
      },
      {
        label: 'Updates Required',
        value: 'updates_required',
      },
      {
        label: 'Pending Review',
        value: 'pending_review',
      },
      {
        label: 'Client Updates Required',
        value: 'client_updates_required',
      },
      {
        label: 'Pending Staff Review',
        value: 'employee_pending_review',
      },
      {
        label: 'Pending Supervisor Review',
        value: 'supervisor_pending_review',
      },
      {
        label: 'Staff Updates Required',
        value: 'employee_updates_required',
      },
      {
        label: 'Signed Off',
        value: 'signed_off',
      },
      {
        label: 'Closed',
        value: 'closed',
      },
      {
        label: 'Collected',
        value: 'collected',
      },
      {
        label: 'Refused Collection',
        value: 'refused',
      },
      {
        label: 'N/A',
        value: 'not_available',
      },
    ],
  },
  record_author_name: {
    label: 'Author',
    type: 'string',
  },
  record_supervisor_name: {
    label: 'Supervisor',
    type: 'string',
  },
  client_diagnoses_codes: {
    label: 'Diagnosis Codes',
    type: 'string',
  },
  client_insurance_authorization_identifier: {
    label: 'Authorization #',
    type: 'string',
  },
  client_level_of_care: {
    label: 'Client Level of Care',
    type: 'string',
  },
  record_minutes_between_start_and_end: {
    label: 'Session Duration',
    type: 'number',
  },
  claim_identifier: {
    label: 'Claim ID',
    type: 'string',
  },
  client_insurance_authorization_sessions_authorized: {
    label: 'Sessions Authorized',
    type: 'number',
  },
  record_author_submitted_at: {
    label: 'Author Submit Date',
    type: 'date_time',
    glyph: 'date',
  },
  record_author_signed_off_at: {
    label: 'Author Sign-Off Date',
    type: 'date_time',
    glyph: 'date',
  },
  service_date: {
    label: 'Service Date',
    type: 'date_time',
    glyph: 'date',
  },
  record_started_at: {
    label: 'Start Date and Time',
    type: 'date_time',
    glyph: 'date',
  },
  record_ended_at: {
    label: 'End Date and Time',
    type: 'date_time',
    glyph: 'date',
  },
  record_created_at: {
    label: 'Added Date',
    type: 'date_time',
    glyph: 'date',
  },
  record_signed_off_at: {
    label: 'Sign-Off Date',
    type: 'date_time',
    glyph: 'date',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}

export default RosterNew
