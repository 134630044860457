import React from 'react'

import { DropdownMenu, DropdownMenuItem } from '../../../components/DropdownMenu'
import Button from '../../../components/Button'
import Glyph from '../../../components/Glyph'

import { ELEMENT_EDITOR_STATES } from '../utils/constants'
import { useFormBuilder } from '../useFormBuilder'
import { useFormOptionsStore } from '../useFormOptionsStore'

import { ElementIcon } from './ElementIcon'

export const ElementEditorStateDropdown = () => {
  const elementEditorState: any = useFormOptionsStore((state: any) => state.elementEditorState)
  const setElementEditorState: any = useFormOptionsStore((state: any) => state.setElementEditorState)

  const activeElementId: any = useFormBuilder((state: any) => state.activeElementId)
  const activeElementCategory: any = useFormBuilder((state: any) => state.allElements?.[activeElementId]?.category)

  const isClosed = elementEditorState === 'closed'

  if (!elementEditorState || !ELEMENT_EDITOR_STATES[elementEditorState]) return null

  return (
    <DropdownMenu
      trigger={
        <div>
          <Button
            before={
              isClosed && activeElementCategory ? (
                <ElementIcon elementCategory={activeElementCategory} className="!w-[16px] !h-[16px] !mr-0.5" />
              ) : (
                <Glyph glyph="close_pane" />
              )
            }
            label={
              <span className="whitespace-nowrap">
                <span className="font-[600]">Editor: </span>
                <span className="font-[400]">{ELEMENT_EDITOR_STATES[elementEditorState]}</span>
              </span>
            }
            type="minimal"
            size={100}
            color="text"
            after={<Glyph glyph="triangle_down" size={9} className="!-ml-0" />}
          />
        </div>
      }
    >
      {Object.entries(ELEMENT_EDITOR_STATES).map(([value, label]) => (
        <DropdownMenuItem
          key={value}
          isActive={elementEditorState === value}
          label={label}
          className="[&.is-active]:!bg-vivid-blue-100"
          onClick={() => {
            setElementEditorState(value)
          }}
        />
      ))}
    </DropdownMenu>
  )
}
