import React from 'react'

import SmartStatus from '../SmartStatus'

export const statuses = {
  draft: {
    label: 'Draft',
    color: 'blue',
  },
  in_progress: {
    label: 'In Progress',
    color: 'yellow',
  },
  updates_required: {
    label: 'Updates Requested',
    color: 'red',
  },
  pending_review: {
    label: 'Pending Review',
    color: 'yellow',
  },
  client_draft: {
    label: 'Draft',
    color: 'blue',
  },
  client_updates_required: {
    label: 'Client Updates Required',
    color: 'red',
  },
  employee_draft: {
    label: 'Draft',
    color: 'blue',
  },
  employee_pending_review: {
    label: 'Pending Staff Review',
    color: 'yellow',
  },
  supervisor_pending_review: {
    label: 'Pending Supervisor Review',
    color: 'yellow',
  },
  employee_updates_required: {
    label: 'Staff Updates Required',
    color: 'red',
  },
  approved: {
    label: 'Approved',
    color: 'paleGreen',
  },
  rejected: {
    label: 'Rejected',
    color: 'red',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
  closed: {
    label: 'Closed',
    color: 'yellow',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
