import React from 'react'

import Flex from '../../../../components/Flex'
import Input from '../../../../components/Forms/Input'

import { VariablesDropdownMenu } from '../../components/VariablesDropdownMenu'

export const TextInputPrefixSuffix = (props: any) => {
  const { activeElement, editActiveElementFromInput, activeElementId, appendVariableToElementModel } = props

  const { prefix, suffix } = activeElement.config

  return (
    <Flex stretchChildrenX gap="0.75rem">
      <Input
        label="Prefix"
        model="prefix"
        value={prefix}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'prefix' })
            }}
          />
        }
      />

      <Input
        label="Suffix"
        model="suffix"
        value={suffix}
        onUpdate={editActiveElementFromInput}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'suffix' })
            }}
          />
        }
      />
    </Flex>
  )
}
