import React from 'react'
import { keyframes } from '@emotion/react'
import { tint } from 'polished'
import * as Popover from '@radix-ui/react-popover'
import compact from 'lodash/compact'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS, SHADOW } from '../../../theme'
import { mapToArray } from '../../../utils/functions'
import { useGet } from '../../../hooks/useNewAPI'
import { usePortal } from '../../../hooks/usePortal'

import Button from '../../Button'
import Card from '../../Card'
import Empty from './Empty'
import Flex from '../../Flex'
import Glyph from '../../Glyph'
import Grid from '../../Grid'
import Loader from '../../Loader'
import State from '../../State'
import Tooltip from '../../Tooltip'

import { Tag } from '../../Tag'

const Tags = ({ value, save, isLoading, smartCategories }: any) => {
  const [tags, setTags]: any = React.useState(value || [])
  const [didChange, setDidChange] = React.useState(false)

  const portalContainer = usePortal('default')

  const { data, isLoading: isLoadingGroups }: any = useGet({
    name: compact(['tag-groups', smartCategories]),
    url: `/tag_groups`,
    params: {
      smart_categories: smartCategories,
    },
  })

  const isEmpty = size(data) === 0

  const hasTags = size(tags) > 0
  const tagIDs = tags?.map?.((o) => o.id)

  React.useEffect(() => {
    if (!value) return
    setTags(value)
  }, [value])

  const addTag = (tag: any) => {
    setDidChange(true)

    const newTags = produce(tags, (draft: any) => {
      draft.push(tag)
    })
    setTags(newTags)
  }

  const removeMenuTag = (id: number) => {
    setDidChange(true)

    const newTags: any = produce(tags, (draft: any) => {
      const index = draft.findIndex((o) => o.id === id)
      draft.splice(index, 1)
    })
    setTags(newTags)
  }

  const handleSave = () => {
    if (didChange && save) {
      save(
        tags.map((o: any) => o.id),
        tags,
      )
    }
  }

  return (
    <>
      <Tooltip
        // position="right"
        content={
          hasTags && (
            <Flex gap="0.4rem">
              {value?.map((tag) => (
                <Tag key={tag.id} data={tag} />
              ))}
            </Flex>
          )
        }
      >
        {isLoading && <Loader size={20} color="blue" />}

        {hasTags ? (
          <Flex nowrap gap="0.4rem" css={{ paddingRight: 1 }}>
            {value?.map((tag) => (
              <Tag key={tag.id} data={tag} />
            ))}
          </Flex>
        ) : (
          <Empty />
        )}
      </Tooltip>

      <Popover.Root>
        <Popover.Trigger css={styles.trigger}>
          <Glyph glyph="edit" className="cell-glyph" size={15} />
        </Popover.Trigger>

        <Popover.Portal container={portalContainer}>
          <Popover.Content asChild onInteractOutside={handleSave}>
            <div css={styles.menu}>
              <Card css={styles.card}>
                {isEmpty || isLoadingGroups ? (
                  <State
                    title="Tags Manager"
                    minHeight={150}
                    emptyDescription="No tags have been created yet"
                    emptyActions={
                      <Button label="Manage Global Tags →" size={200} type="link" glyph="settings" link="/settings/tags-manager" />
                    }
                    glyph="tag"
                    isEmpty={isEmpty}
                    isLoading={isLoadingGroups}
                  />
                ) : (
                  <>
                    <div css={styles.content}>
                      {mapToArray(data).map((group: any) => (
                        <React.Fragment key={group.id}>
                          <div css={styles.groupHeader}>
                            <div>{group.name}</div>

                            <Tooltip content={group.description} position="right" />
                          </div>

                          <Grid>
                            {group.tags.map((tag: any) => {
                              const isAdded = tagIDs.includes(tag.id)

                              return (
                                <Tooltip content={tag.description} position="left">
                                  <div
                                    key={tag.id}
                                    css={styles.groupRow}
                                    style={{ '--color': tag.color }}
                                    className={isAdded ? 'is-added' : ''}
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      event.nativeEvent.stopImmediatePropagation()

                                      if (isAdded) removeMenuTag(tag.id)
                                      else addTag(tag)
                                    }}
                                  >
                                    <Glyph glyph={tag.glyph} size={14} color={tag.color} css={styles.glyph} />

                                    <div>{tag.name}</div>

                                    {isAdded && (
                                      <div className="close-button">
                                        <Glyph glyph="cross" size={10} color={COLORS.textMuted} />
                                      </div>
                                    )}
                                  </div>
                                </Tooltip>
                              )
                            })}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </div>
                  </>
                )}
              </Card>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </>
  )
}

const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translateY(-12px);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translateY(0);
  }
`

const styles = {
  root: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  popover: {
    background: 'white',
    width: '100%',
    maxWidth: 300,
    outline: 'none',
    borderRadius: 5,
    boxShadow: SHADOW(3),
    border: `1px solid ${COLORS.divider}`,

    fontSize: '0.96rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    padding: '0.4em 0.75em',
    zIndex: 0,
  },

  trigger: {
    position: 'absolute',
    top: 1,
    bottom: 1,
    right: 0,
    zIndex: 1,
    border: 'none',
    background: 'none',

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      width: 50,
      display: 'block',
      background: `linear-gradient(90deg, ${COLORS.transparent}, ${COLORS.white} 50%)`,
      borderRight: `1px solid #dde1ee`,
    },

    '.table-row:nth-child(odd) &::after': {
      background: `linear-gradient(90deg, ${COLORS.transparent}, ${tint(0.25, COLORS.lightBackground)} 50%)`,
    },

    '.cell-glyph': {
      position: 'absolute',
      top: '50%',
      right: '0.5rem',
      transform: 'translateY(-50%)',
      zIndex: 1,

      '@media(min-width: 600px)': {
        opacity: 0,
        transition: 'all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
      },
    },

    '&:hover': {
      '&::after': {
        background: `linear-gradient(90deg, ${COLORS.transparent}, ${tint(0.75, COLORS.vividBlue)}) !important`,
      },

      '@media(min-width: 600px)': {
        '.cell-glyph': {
          opacity: 1,
          transform: 'translateX(0) translateY(-50%)',
        },
      },
    },
  },

  menu: {
    padding: '0.5rem',
    animation: `${animation} 100ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards`,
    outline: 'none',
  },

  card: {
    minWidth: '15rem',
    maxWidth: '20rem',
    maxHeight: 400,
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    overflow: 'auto',
  },

  content: {
    overflowY: 'auto',
  },

  groupHeader: {
    padding: '0.3rem 0.75rem',
    fontWeight: 600,
    fontSize: '0.92rem',
    lineHeight: 'normal',
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    borderTop: `1px solid ${COLORS.divider}`,

    '&:first-of-type': {
      borderTop: 'none',
      marginTop: '0.25rem',
    },
  },

  groupRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0.75rem',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '0.95rem',
    width: '100%',

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-added': {
      fontWeight: 500,

      '.row-swatch': {
        boxShadow: `
          0 0 0 2px ${COLORS.white},
          0 0 0 4px var(--color)
        `,
      },

      '.close-button': {
        marginLeft: 'auto',
      },

      '&:hover .close-button svg': {
        transform: 'scale3d(1.25, 1.25, 1.25)',
        fill: COLORS.red,
      },
    },
  },

  glyph: {
    marginRight: '0.25rem',
  },
}

export default Tags
