import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  is_enrolled: {
    label: 'Enrolled',
    color: 'green',
  },
  not_enrolled: {
    label: 'Not Enrolled',
    color: 'red',
  },
}

const PayerEnrollmentStatus = (props) => {
  return <SmartStatus statuses={statuses} {...props} />
}

export default PayerEnrollmentStatus
