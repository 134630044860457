import React from 'react'
import { Input } from './Input'
import { withFormContext } from './context'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { isBlank } from '../../utils/functions'

import LinkValue from '../LinkValue'

class FaxInput extends Input {
  readOnlyRender = () => {
    if (isBlank(this.state.value)) return DEFAULT_EMPTY_VALUE

    return <LinkValue href={`tel:${this.state.value}`} value={this.state.value} glyph={this.props.glyph} />
  }
}

FaxInput.defaultProps = {
  type: 'tel',
  glyph: 'fax',
  defaultValidations: {
    length: {
      maximum: 14,
      message: 'Please use a valid phone number format (e.g. 123-456-7890)',
    },
  },
}

export default withFormContext(FaxInput)
