import React from 'react'

import { COLORS } from '@behavehealth/theme'
import { countWord, usDate } from '@behavehealth/utils/functions'
import { LOC } from '@behavehealth/utils/constants'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withSettings from '@behavehealth/hocs/withSettings'

import { Flex, Card, Link, CardTitle, Calendar, Page, TableCell, TableFilter } from '@behavehealth/components'

import URBillingStatus from '@behavehealth/components/Statuses/URBillingStatus'
import BillingRosterStatus from '@behavehealth/components/Statuses/BillingRosterStatus'

const BillingRosters: React.FC = ({ timezone }: any) => {
  return (
    <Page feature="billing_rosters">
      <Calendar
        events={DATA}
        isLoading={false}
        timezone={timezone}
        startDateKey="started_at"
        defaultView="table"
        defaultMode="weeks"
        setTableColumns={setTableColumns}
        to={(data: string) => `/clients/${data.client.id}/billing-roster/${data.id}`}
        tableProps={{ groupBy: 'started_at' }}
        localStorageKey="reports_billing_roster"
        listStyle={styles.listStyle}
        renderEvent={(data: any) => (
          <Link to={`/clients/${data.client.id}/billing-roster/${data.id}`} css={styles.link}>
            <Card css={styles.card}>
              <Flex gap={6}>
                <CardTitle title={data.client.name} css={styles.title} />
                <BillingRosterStatus status={data.status} css={styles.status} />
                {/* TODO: show statuses for bh employees only */}
                {/* <URBillingStatus setLabel={(status: string) => `UR ${status}`} status={data.ur_status} css={styles.status} />
                <URBillingStatus setLabel={(status: string) => `Billing ${status}`} status={data.billing_status} css={styles.status} /> */}
              </Flex>

              <div css={styles.description}>
                <b>
                  {data.documented_hours} / {data.insurance_loc_expectation}
                </b>{' '}
                documented hrs
              </div>

              <div css={styles.description}>{LOC[data.authorized_level_of_care]}</div>
            </Card>
          </Link>
        )}
      />
    </Page>
  )
}

const setTableColumns = (to: Function = () => {}, timezone: string) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'client.name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original)} label={value} avatar={row.original.client?.avatar} />,
  },
  {
    accessor: 'started_at',
    Header: 'Date',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <BillingRosterStatus status={value} />,
  },
  {
    width: 300,
    accessor: 'authorized_level_of_care',
    Header: 'Authorized LoC',
    Cell: ({ value }: any) => <TableCell.Truncated value={LOC[value]} />,
  },
  {
    width: 180,
    accessor: 'ur_status',
    Header: 'UR Sign-Off',
    Cell: ({ value }: any) => <URBillingStatus status={value} />,
  },
  {
    width: 180,
    accessor: 'billing_status',
    Header: 'Billing Sign-Off',
    Cell: ({ value }: any) => <URBillingStatus status={value} />,
  },
  {
    width: 120,
    accessor: 'sessions_used',
    Header: 'Sessions Used',
    Cell: ({ value }: any) => `${value} / 7 sessions`,
  },
  {
    width: 140,
    accessor: 'insurance_loc_expectation',
    Header: 'Insurance LOC Expectation',
    Cell: ({ value }: any) => countWord('hours', value),
  },
  {
    width: 140,
    accessor: 'program_calendar_hours',
    Header: 'Program Calendar Hours',
    Cell: ({ value }: any) => countWord('hours', value),
  },
  {
    width: 140,
    accessor: 'documented_hours',
    Header: 'Documented Hours',
    Cell: ({ value }: any) => countWord('hours', value),
  },
  {
    width: 140,
    accessor: 'undocumented_hours',
    Header: 'Undocumented Hours',
    Cell: ({ row }: any) => {
      const value = row.original.program_calendar_hours - row.original.documented_hours

      return countWord('hours', value)
    },
  },
  {
    width: 300,
    accessor: 'customer_comments',
    Header: 'Customer Comments',
    Cell: ({ value }: any) => value,
  },
  {
    width: 300,
    accessor: 'ur_comments',
    Header: 'Behave UR Comments',
    Cell: ({ value }: any) => value,
  },
  {
    width: 300,
    accessor: 'billing_comments',
    Header: 'Behave Billing Comments',
    Cell: ({ value }: any) => value,
  },
  {
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    accessor: 'updated_at',
    Header: 'Date Updated',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

const styles = {
  link: {
    display: 'block',
  },

  card: {
    padding: '0.25rem 0.4rem',
  },

  title: {
    fontSize: '0.9rem',
    color: COLORS.link,
  },

  description: {
    fontSize: '0.9rem',
    color: COLORS.textMuted,
    marginTop: '0.2rem',
  },

  status: {
    fontSize: '0.78rem',
  },

  listStyle: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
    gridGap: '0.25rem',
  },
}

const DATA = [
  {
    id: 1,
    status: 'draft',
    authorized_level_of_care: 'medically_monitored_inpatient_detoxification',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'pending_review',
    billing_status: 'pending_review',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-10T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Abbigail Villarreal',
      behave_id: 'ABVI000001',
      sex: 'female',
      avatar: null,
      dob: '1960-12-05T19:00:00.000-05:00',
    },
  },
  {
    id: 2,
    status: 'draft',
    authorized_level_of_care: 'clinically_managed_residential_detoxification',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'pending_review',
    billing_status: 'signed_off',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-11T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Adrien Adkinss',
      behave_id: 'ADAD000001',
      sex: 'male',
      avatar: null,
      dob: '1990-03-22T19:00:00.000-05:00',
    },
  },
  {
    id: 3,
    status: 'signed_off',
    authorized_level_of_care: 'partial_hospitalization_services',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'rejected',
    billing_status: 'rejected',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-12T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Alexia Cannon',
      behave_id: 'ALCA000001',
      sex: 'female',
      avatar: null,
      dob: '1998-05-11T20:00:00.000-04:00',
    },
  },
  {
    id: 4,
    status: 'signed_off',
    authorized_level_of_care: 'ambulatory_detoxification_with_extended_on_site_monitoring',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'signed_off',
    billing_status: 'signed_off',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-13T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Alyse Gottlieb Nikolaus',
      behave_id: 'ALNI000006',
      sex: 'female',
      avatar: null,
      dob: '1979-07-26T20:00:00.000-04:00',
    },
  },
  {
    id: 5,
    status: 'draft',
    authorized_level_of_care: 'medically_monitored_inpatient_detoxification',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'pending_review',
    billing_status: 'pending_review',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-10T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Annice Batz Hamill',
      behave_id: 'ANHA000036',
      sex: 'female',
      avatar: null,
      dob: '1992-03-06T19:00:00.000-05:00',
    },
  },
  {
    id: 6,
    status: 'draft',
    authorized_level_of_care: 'clinically_managed_residential_detoxification',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'pending_review',
    billing_status: 'signed_off',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-11T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Bobbie Hopper',
      behave_id: 'BOHO000001',
      sex: 'female',
      avatar: null,
      dob: '1954-11-01T19:00:00.000-05:00',
    },
  },
  {
    id: 7,
    status: 'signed_off',
    authorized_level_of_care: 'partial_hospitalization_services',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'rejected',
    billing_status: 'rejected',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-12T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Brendan Marks Walter',
      behave_id: 'BRWA000043',
      sex: 'female',
      avatar: null,
      dob: '1998-05-08T20:00:00.000-04:00',
    },
  },
  {
    id: 8,
    status: 'signed_off',
    authorized_level_of_care: 'ambulatory_detoxification_with_extended_on_site_monitoring',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'signed_off',
    billing_status: 'signed_off',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-13T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Casandra Mccray',
      behave_id: 'CAMC000001',
      sex: 'female',
      avatar: null,
      dob: '1986-10-03T20:00:00.000-04:00',
    },
  },
  {
    id: 9,
    status: 'draft',
    authorized_level_of_care: 'medically_monitored_inpatient_detoxification',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'pending_review',
    billing_status: 'pending_review',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-10T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Cassandra Griffith',
      behave_id: 'CAGR000001',
      sex: 'female',
      avatar: null,
      dob: '1962-09-12T20:00:00.000-04:00',
    },
  },
  {
    id: 10,
    status: 'draft',
    authorized_level_of_care: 'clinically_managed_residential_detoxification',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'pending_review',
    billing_status: 'signed_off',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-11T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Catarina Dicki Spencer',
      behave_id: 'CASP000010',
      sex: 'female',
      avatar: null,
      dob: '1981-09-14T20:00:00.000-04:00',
    },
  },
  {
    id: 11,
    status: 'signed_off',
    authorized_level_of_care: 'partial_hospitalization_services',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'rejected',
    billing_status: 'rejected',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-12T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Garett Bradleys',
      behave_id: 'GABR000001',
      sex: 'male',
      avatar: null,
      dob: '1974-05-26T20:00:00.000-04:00',
    },
  },
  {
    id: 12,
    status: 'signed_off',
    authorized_level_of_care: 'ambulatory_detoxification_with_extended_on_site_monitoring',
    sessions_used: 4,
    insurance_loc_expectation: 5,
    program_calendar_hours: 5,
    documented_hours: 4,
    ur_status: 'signed_off',
    billing_status: 'signed_off',
    customer_comments: 'Customer Comments',
    ur_comments: 'UR Comments',
    billing_comments: 'Billing Comments',
    started_at: '2021-08-13T06:30:49.976-05:00',
    created_at: '2021-07-06T06:30:49.976-04:00',
    updated_at: '2021-07-06T06:30:49.976-05:00',
    client: {
      id: 'vNwNjp2',
      name: 'Ingrid Booth',
      behave_id: 'INBO000001',
      sex: 'female',
      avatar: null,
      dob: '1959-03-23T19:00:00.000-05:00',
    },
  },
]

export default withPageError(withSettings(BillingRosters))
