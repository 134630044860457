import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { useOverlay } from '../../hooks/useOverlay'
import { useUpdate } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

const EDITOR_ACTIONS = ['inline']

export const ClientHighRiskAlertOverlay = (props: any) => {
  const history: any = useHistory()
  const location: any = useLocation()

  const { resource_id }: any = useParams()
  const clientID = props.dataID || resource_id

  const close = () => {
    if (location?.parent) {
      if (location.parent.url) history.push(location.parent.url)
      else history.push(location.parent)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  const { cancel, edit, form, initialModel, isEditable, isInvalid, isOverlayLoading, isSaving, onValidationUpdate, save } = useOverlay({
    name: 'clients',
    endpoint: '/residents',
    invalidateKeys: ['clients', ['client', clientID]],
    closeOnSave: true,
    options: {
      ...props,
      type: 'summon',
      onClose: close,
      dataID: clientID,
    },
  })

  const _isEditable = !initialModel?.high_risk_title || isEditable

  const { mutateAsync, isLoading: isDeleting }: any = useUpdate({
    name: ['client', clientID],
    url: `/residents/${clientID}`,
    invalidateKeys: ['clients', ['client', clientID]],
  })

  const deleteAlert = async () => {
    try {
      await mutateAsync({
        high_risk_title: null,
        high_risk_notes: null,
      })
      close()
    } catch (error) {
      console.error(error)
    }
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="center" onClose={close} maxWidth={35} />
  }

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={close} maxWidth={35}>
      <Overlay.Header title="High-Risk Alert" />

      <Overlay.Content>
        <Form getForm={form} isEditable={_isEditable} initialModel={initialModel} onValidationUpdate={onValidationUpdate}>
          <Section>
            <FormSection>
              <Input
                label="Title"
                model="high_risk_title"
                validations={{
                  presence: {
                    message: 'Please enter a title',
                  },
                }}
              />
              <MiniRichTextEditor useQuickText label="Details" model="high_risk_notes" actions={EDITOR_ACTIONS} />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {_isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
            />
            <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
          </>
        )}

        {!_isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete High-Risk Alert?"
              message="Are you sure you want to delete this alert? This action cannot be undone."
              onYes={deleteAlert}
            >
              <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}
