import React from 'react'
import clsx from 'clsx'
import * as HoverCard from '@radix-ui/react-hover-card'
import { useMedia } from 'use-media'

import { css, COLORS, SHADOW } from '../../../theme'

import Avatar from '../../../components/Avatar'
import Glyph from '../../../components/Glyph'

export const ProfileWithTooltip = (props: any) => {
  const { name, avatar = '', tooltipContent } = props

  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <HoverCardRoot open={isOpen} onOpenChange={setIsOpen}>
      <HoverCard.Trigger asChild>
        <div
          className="flex flex-nowrap items-center text-blue-500 font-[500]"
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <Avatar src={avatar} size={20} initials={name} className="mr-1.5" /> {name}
          <Glyph className="ml-1" glyph="info" size={12} />
        </div>
      </HoverCard.Trigger>

      <HoverCardContent>{tooltipContent}</HoverCardContent>
    </HoverCardRoot>
  )
}

export const HoverCardRoot: React.FC = ({ children, ...rest }) => {
  return (
    <HoverCard.Root openDelay={100} closeDelay={100} {...rest}>
      {children}
    </HoverCard.Root>
  )
}

export const HoverCardContent: React.FC = ({ children }) => {
  const isDesktop = useMedia({ minWidth: 600 })

  const container = document?.getElementById?.('portal-radix')
  const collisionBoundary = document?.getElementById?.('roadmap')

  return (
    <HoverCard.Portal container={container}>
      <HoverCard.Content
        className={clsx(STYLES.hoverCard().className, 'animate-slideInFromTop')}
        collisionBoundary={collisionBoundary}
        collisionPadding={{ left: isDesktop ? 200 : 20 }}
      >
        <HoverCard.Arrow width={14} height={8} className={STYLES.hoverCardArrow()} />
        {children}
      </HoverCard.Content>
    </HoverCard.Portal>
  )
}

const STYLES = {
  hoverCard: css({
    minWidth: 280,
    maxWidth: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    borderRadius: 7,
    background: COLORS.white,
    boxShadow: SHADOW(10, COLORS.divider),
    border: 'none',
    paddingBottom: '0.5rem',

    '@media(min-width: 600px)': {
      minWidth: 360,
    },
  }),

  hoverCardArrow: css({
    color: COLORS.white,
    margin: '0 1rem',
  }),
}
