import React from 'react'
import { useSelector } from 'react-redux'

import { apiUpdate } from '@behavehealth/modules/api'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Accordion, Form, FormSection, Grid, Input, Page, HelpTagIframe } from '@behavehealth/components'

const pageConfig = {
  title: 'Company Providers',
}

const CollaborateMD: React.FC = () => {
  const record = useSelector((state) => state.me.tenant)

  return (
    <Page title="CollaborateMD Settings" help={<HelpTagIframe id="settings_collaborate_md" />}>
      <Grid gap="1rem">
        <Accordion
          isOpen
          activateEditMode
          initialModel={record}
          title="Details"
          onSubmit={async (data) => {
            await apiUpdate({ name: 'tenant', url: '/me/tenant', params: data, reducer: 'me' })
          }}
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input
                label="CollaborateMD Customer Number"
                model="collaboratemd_customer_number"
                validations={{
                  presence: {
                    message: 'Please enter a customer number',
                  },
                }}
              />
            </FormSection>
          </Form>
        </Accordion>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CollaborateMD, pageConfig))
