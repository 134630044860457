import React from 'react'

import { useDataTable } from '../../components/DataTable/useDataTable'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import Overlay from '../../components/Overlay'

import { QuoteTemplatesDataTable } from './QuoteTemplatesDataTable'
import { FormSection } from '@behavehealth/components'

export const EHRImportQuoteTemplateOverlay = (props: any) => {
  const { onClose, onImport } = props

  const [isMain, setIsMain] = React.useState(false)

  const tableProps = useDataTable({
    name: ['quote_templates'],
    endpoint: '/quote_templates',
    localStorageKey: 'quote_templates_v1',
  })

  const [isImporting, setIsImporting] = React.useState(false)
  const [activeTemplate, setActiveTemplate] = React.useState<any>(null)

  const handleImport = async () => {
    if (!activeTemplate) return

    setIsImporting(true)

    try {
      await onImport?.({
        quote_template_id: activeTemplate.id,
        is_main: isMain,
      })
      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsImporting(false)
    }
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} maxWidth={80}>
      <Overlay.Header title="Quote Templates" icon="quotes" />

      <Overlay.Content className="grid gap-4 p-5 items-start content-start">
        <Alert small contrast glyph="info">
          Select a Quote Template to import, then click the "Import" button below
        </Alert>

        <QuoteTemplatesDataTable {...tableProps} activeId={activeTemplate?.id} onSelect={setActiveTemplate} />
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={activeTemplate ? `Import "${activeTemplate.name}" Template` : 'Select Quote Template to Import'}
          glyph="tick_circle"
          color="green"
          type="primary"
          onClick={handleImport}
          isLoading={isImporting}
          isDisabled={!activeTemplate}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
