import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import withPermissions from '@behavehealth/hocs/withPermissions'

import { Button, Card, Chotomate, Grid, Link } from '@behavehealth/components'
import { CommunicableDiseaseTestsTable } from '@behavehealth/components/Tables'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

const CommunicableDiseaseTests = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const [filters, setFilters] = React.useState({})

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'communicable-disease-tests', { filters }],
    url: `/residents/${resource_id}/communicable_disease_tests`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const { mutate: deleteRecords } = useDelete({
    name: ['client', resource_id, 'communicable-disease-tests'],
    url: '/communicable_disease_tests',
    invalidate: 'communicable-disease-tests',
  })

  return (
    <Grid gap="1rem" columns="100%">
      <Chotomate name="test_results_communicable_disease_tests" ready={!isLoading} />

      <Filters config={FILTERS.communicable_disease_tests} onUpdate={setFilters} localStorageKey="client_communicable_disease_tests" />

      <Card>
        <CommunicableDiseaseTestsTable
          data={data}
          isLoading={isLoading}
          localStorageKey="clients_communicable_disease_tests"
          to={(id: string) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
          titleAfter={<Link to="/reports/communicable-disease-tests">View Full Report →</Link>}
          hiddenColumns={['client']}
          emptyActions={
            <Button
              label="Add Communicable Disease Test"
              type="primary"
              glyph="add"
              link={{
                pathname: `${match.url}/new`,
                data: {
                  client: client,
                },
              }}
              permission="test_results.communicable_disease_tests.create"
            />
          }
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'test_results.communicable_disease_tests.delete',
              action: async ({ ids }: any) => {
                await deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Card>
    </Grid>
  )
}

export default withPermissions(CommunicableDiseaseTests)
