import React from 'react'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'

import { COLORS } from '../../theme'

import { Markup, Button, Flex, Glyph } from '../../components'

export const HighRiskAlert = ({ title, notes, isRounded, className }: any) => {
  const location = useLocation()
  const rootClasses = clsx(isRounded && 'is-rounded', className)

  if (!title) return null

  return (
    <div css={styles.root} className={rootClasses}>
      <Glyph glyph="warning" color={COLORS.white} css={styles.glyph} />
      <Flex centerY gap="0.5rem" justifyContent="space-between" flex="1 1 auto">
        <div>
          <div css={styles.title}>{title}</div>

          {notes && (
            <div css={styles.notes}>
              <Markup value={notes} />
            </div>
          )}
        </div>

        <Button
          type="default"
          color="red"
          label="Edit"
          glyph="edit"
          size={100}
          link={{
            pathname: `${location.pathname}/high-risk-alert`,
            parent: location.pathname,
          }}
        />
      </Flex>
    </div>
  )
}

const styles = {
  root: {
    background: COLORS.red,
    color: COLORS.white,
    padding: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginBottom: '0.75rem !important',
    flex: '1 1 auto',
    width: '100%',

    '&.is-rounded': {
      borderRadius: 5,
    },

    p: {
      margin: 0,
    },
  },

  title: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },

  notes: {
    fontSize: '0.95rem',
    marginTop: '0.4rem',
  },

  glyph: {
    marginRight: '0.75rem',
  },
}
