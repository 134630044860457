import React from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { COLORS } from '@behavehealth/theme'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Flex,
  Glyph,
  Grid,
  Iframe,
  PageLayout,
  PageSection,
  Permission,
  Text,
} from '@behavehealth/components'

import QuickContactFormExample from '@behavehealth/components/Widgets/QuickContactForm'
import useCopyToClipboard from '@behavehealth/hooks/useCopyToClipboard'

const QuickContactForm = () => {
  const tenant = useSelector((state: any) => state.me?.tenant)

  const identifier = React.useRef(uuid())

  if (!tenant) return null

  const code = `<div id="behave-application-form-${identifier.current}"></div><script type="text/javascript">!function(){function e(){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.onload=function(){ApplicationForm.init({parentElement:"behave-application-form-${identifier.current}",tenant:"${tenant.subdomain}",timezone:"${tenant.timezone}"})},e.src="${process.env.BH_APP_WIDGETS_URL}/ApplicationForm.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}window.attachEvent?window.attachEvent("onload",e):window.addEventListener("load",e,!1)}();</script>`

  const { copy, didCopy: codeCopied } = useCopyToClipboard({
    text: code,
    notification: 'Code copied!',
  })

  return (
    <Permission newFeatureFlag="contact_us_form">
      <PageLayout>
        <PageSection>
          <PageSection.Header>
            <PageSection.Title title="Widget Description" />
          </PageSection.Header>

          <PageSection.Content>
            <Card>
              <CardHeader className="!px-2 !py-[0.65rem]">
                <Grid gap="1rem">
                  <Text
                    nowrap
                    glyph="tick"
                    description="Embed the Quick Contact Form widget on your website for your clients to receive a link via text or email to the full
                application form."
                  />
                  <Text
                    nowrap
                    glyph="tick"
                    description="We try to automatically contact applicants to send them a link to their application form"
                  />
                  <Text
                    nowrap
                    glyph="tick"
                    description="All the information gets saved directly to your app, and even if they don't finish up submissions you can always continue."
                  />
                </Grid>
              </CardHeader>

              <CardContent>
                <Iframe title="Embed Contact Form Example" url="https://behavehealth.com/widgets/contact-form">
                  <Button label="View contact form example" display="inline-flex" glyph="view" />
                </Iframe>
              </CardContent>
            </Card>
          </PageSection.Content>
        </PageSection>

        <PageSection>
          <PageSection.Header>
            <PageSection.Title title="Widget Example" />
          </PageSection.Header>

          <PageSection.Content>
            <Card>
              <CardHeader className="!px-2 !py-[0.65rem]">
                <Flex centerX>
                  <QuickContactFormExample tenant={tenant} timezone={tenant.timezone} />
                </Flex>
              </CardHeader>
            </Card>
          </PageSection.Content>
        </PageSection>

        <PageSection>
          <PageSection.Header>
            <PageSection.Title title="Usage Instructions" />
            <PageSection.Subtitle subtitle="Paste the code below on your website to embed the quick contact form, or send it to your website administrator." />
          </PageSection.Header>

          <PageSection.Content>
            <Card>
              <CardHeader className="!py-1 !px-0">
                <pre
                  css={{
                    width: '100%',
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-wrap',
                    color: COLORS.textMuted,
                    background: '#EEF0F7',
                    border: `1px solid ${COLORS.divider}`,
                    borderRadius: 5,
                    padding: 10,
                    margin: 0,
                  }}
                >
                  {code}
                </pre>
              </CardHeader>

              <CardContent>
                <Flex gap="1rem">
                  <Button
                    glyph={codeCopied ? 'check' : 'copy'}
                    label={codeCopied ? 'Code Copied to Clipboard!' : 'Copy Code'}
                    onClick={copy}
                    display="inline-flex"
                    type={codeCopied ? 'default' : 'primary'}
                  />

                  <a
                    href={`mailto:?subject=Please%20Embed%20Code%20To%20Contact%20Page&body=Hello,%0A%0ACan%20you%20please%20embed%20the%20code%20below%20to%20our%20contact%20page?%0A%0A${code}%0A%0AThank you!`}
                    target="_blank"
                    rel="noreferrer"
                    css={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      svg: { marginLeft: '0.25em' },
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    Email to website administrator <Glyph glyph="external_link" size={14} color={COLORS.blue} />
                  </a>
                </Flex>
              </CardContent>
            </Card>
          </PageSection.Content>
        </PageSection>
      </PageLayout>
    </Permission>
  )
}

export default QuickContactForm
