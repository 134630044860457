import React from 'react'
import { useMedia } from 'use-media'
import produce from 'immer'
import startCase from 'lodash/startCase'

import { FINANCIAL_STATUS } from '../../utils/constants'
import { useUpdate, getPrefix } from '../../hooks/useNewAPI'
import withSettings from '../../hocs/withSettings'

import ClientColorPicker from '../ColorPickers/ClientColorPicker'
import Flex from '../Flex'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import AlumniQuickView from '../Overlays/quick/AlumniQuickView'

const billingColumn = {
  width: 140,
  accessor: 'is_billed_by_behave',
  Header: 'Billed',
  Cell: () => <TableCell.TrueFalse />,
  Filter: TableFilter.TrueFalse,
}

const mainColumn = (to) => ({
  width: 240,
  isSticky: true,
  canToggleVisible: false,
  // disableSortBy: true,
  accessor: 'name',
  Header: 'Name',
  Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} avatar={row.original.avatar} />,
})

const columns = (to: Function = () => {}, isBehave: boolean, isDesktop: boolean, showERXFeatures: boolean, apiKey: any, timezone: string) => [
  ...(isDesktop ? [mainColumn(to)] : []),
  ...(isBehave ? [billingColumn] : []),
  {
    width: isDesktop ? 160 : 240,
    disableFilters: true,
    disableSortBy: true,
    id: 'quick_view',
    accessor: 'id',
    Header: 'Quick View',
    Cell: ({ row }: any) => (
      <TableCell.QuickViewOverlay overlay={<AlumniQuickView client={row.original} />}>
        {!isDesktop && <TableCell.MainLink label={row.original.name} avatar={row.original.avatar} glyphAfter="quick_view" />}
      </TableCell.QuickViewOverlay>
    ),
  },
  {
    width: 200,
    Header: 'Tags',
    accessor: 'tags',
    // disableSortBy: true,
    Cell: ({ value, row }: any) => {
      const { mutateAsync, isLoading }: any = useUpdate({
        name: ['client', row.original.id],
        url: `/residents/${row.original.id}`,
        invalidate: ['clients', { status: 'alumni' }],
        invalidateKeys: ['tag-live-reports'],
        onSuccess: (_data: any, variables: any, queryClient: any) => {
          const prefix = getPrefix()

          // update clients list
          queryClient.setQueryData([prefix, apiKey].flat(), (oldData: any) => {
            if (!oldData?.data) return

            const index = oldData.data.findIndex((o) => o.id === row.original.id)
            if (index === -1) return

            const newData = produce(oldData, (draft: any) => {
              draft.data[index].tags = variables.tags
            })

            return newData
          })
        },
      })

      return (
        <TableCell.Tags
          value={value}
          save={(tag_ids: any, tags: any) => mutateAsync({ tag_ids, tags })}
          isLoading={isLoading}
          smartCategories="past_clients"
        />
      )
    },
  },
  {
    width: 140,
    accessor: 'color_code',
    Header: 'Color',
    Cell: ({ row, value }: any) => (
      <Flex centerX>
        <ClientColorPicker clientID={row.original?.id} color={value} css={{ margin: '0 auto' }} />
      </Flex>
    ),
  },
  {
    width: 150,
    accessor: 'financial_status',
    Header: 'Financial Status',
    Cell: ({ value }: any) => <TableCell value={FINANCIAL_STATUS[value]} />,
  },
  {
    width: 140,
    accessor: 'behave_id',
    Header: 'Client ID',
    Cell: ({ value }: any) => <TableCell value={value && `#${value}`} />,
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 240,
    accessor: 'email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
  {
    width: 100,
    accessor: 'sex',
    Header: 'Sex',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'male',
        label: 'Male',
      },
      {
        value: 'female',
        label: 'Female',
      },
      {
        value: 'decline_to_respond',
        label: 'Decline To Respond',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  {
    width: 200,
    accessor: 'level_of_care',
    Header: 'Level of Care',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
  },
  ...(showERXFeatures
    ? [
        {
          width: 140,
          accessor: 'erx_status',
          Header: 'Synced with eRX',
          Cell: ({ value }: any) => {
            if (value === 'synced') return 'Yes'
            else if (value === 'not_synced') return 'No'

            return '–'
          },
          Filter: TableFilter.Select,
          filter: 'boolean',
          filterOptions: [
            {
              value: true,
              label: 'Synced',
            },
            {
              value: false,
              label: 'Not Synced',
            },
          ],
        },
      ]
    : []),
  {
    width: 140,
    accessor: 'client_portal_status',
    Header: 'Using Portal',
    Cell: ({ value }: any) => {
      if (value === 'access') return 'Yes'
      else if (value === 'invited') return 'Invited'
      else return 'No'
    },
    Filter: TableFilter.Select,
    filter: 'boolean',
    filterOptions: [
      {
        value: true,
        label: 'With Access',
      },
      {
        value: false,
        label: 'No Access',
      },
    ],
  },
  {
    width: 190,
    accessor: 'current_admission.discharged_at',
    Header: 'Discharge Date',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  // {
  //   width: 190,
  //   accessor: 'last_logged_in_at',
  //   Header: 'Last Portal Login At',
  //   Filter: TableFilter.Date,
  //   filter: 'date',
  //   Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  // },
  {
    width: 140,
    accessor: 'last_name',
    Header: 'Last Name',
    Cell: ({ value }: any) => <TableCell value={value} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  title: string
  to?: Function
  tenant?: Object
  timezone: string
  user: any
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const AlumniTable = (props: Props) => {
  const {
    apiKey,
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    batchActionsConfig,
    localStorageKey,
    title,
    to,
    tenant,
    timezone,
    user,
    titleAfter,
    ...rest
  } = props

  const isBehave = user?.type === 'bh_employee'

  const isDesktop = useMedia({ minWidth: 600 })
  const showERXFeatures = tenant?.feature_flags?.erx === true

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, isBehave, isDesktop, showERXFeatures, apiKey, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

AlumniTable.defaultProps = {
  title: 'Past Clients',
  icon: 'alumni_relations',
  emptyDescription: 'No past clients added yet',
  localStorageKey: 'alumni',
}

export default withSettings(AlumniTable)
