import React from 'react'

import { useCreate } from '../../../hooks/useNewAPI'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Markup from '../../../components/Markup'
import Status from '../../../components/Status'

import { ReviewSection } from './ReviewSection'
import QuotePaymentMethod from '../../PaymentMethods/QuotePaymentMethod'
import { PaymentMethod } from '../../BillingElements/PaymentMethodElements'

export const CollectPaymentMethod = (props: any) => {
  const { data, enabled, isPublic, refetchQuote, isOpen } = props

  const isPaid = data?.status === 'paid' || data?.status === 'completed'

  const { mutateAsync: subscribe, isLoading: isSubscribing } = useCreate({
    name: ['subscribe'],
    url: isPublic ? `/apps/quotes/${data?.external_id}/subscribe` : `/quotes/${data?.id}/subscribe`,
    invalidate: 'quotes',
  })

  const isEmpty = !data.global_payment_method
  const hasPaymentMethod = !isEmpty

  const handleSubscribe = async () => {
    try {
      await subscribe({})
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <ReviewSection
        isOpen={isOpen}
        data={data}
        title="Payment Methods"
        icon="billing"
        sectionModel="payment_methods"
        className="!border-none"
      >
        {isPaid ? (
          <>
            {data?.global_payment_method && (
              <div className="flex items-center mb-4">
                <div className="whitespace-nowrap mr-1.5 font-[600]">Payment Method:</div>
                <PaymentMethod paymentMethod={data?.global_payment_method} />
              </div>
            )}

            <Alert glyph="tick_circle" type="positive">
              Thank you, your payment methods have been set up and we will be in touch shortly. If you have any questions, please{' '}
              <a href="mailto:contact@behavehealth.com>">contact us</a>.
            </Alert>
          </>
        ) : !enabled ? (
          <Alert glyph="info">Please complete the previous sections to view the payment method options</Alert>
        ) : (
          <div className="grid gap-5 w-full max-w-[500px]">
            {data?.payment_text && <Markup value={data.parsed_payment_text || data.payment_text} />}

            {hasPaymentMethod ? (
              <Status label="Completed" glyph="tick_circle" color="green" className="mr-2" />
            ) : (
              <>
                <Status label="Action Required" glyph="warning" color="orange" />
                <Alert glyph="info">Please add a payment method to proceed with the quote</Alert>
              </>
            )}

            <QuotePaymentMethod category="quote" record={data} paymentMethod={data.global_payment_method} onSuccess={refetchQuote} />

            {hasPaymentMethod && (
              <>
                <Alert glyph="tick_circle" type="positive">
                  {data?.facility?.id ? (
                    'Successfully added a payment method. Click the subscribe button below to complete the process.'
                  ) : (
                    <>
                      Successfully added a payment method. We are currently working setting up your EHR account. If you have any questions
                      for now, please <a href="mailto:contact@behavehealth.com>">contact us</a>.
                    </>
                  )}
                </Alert>

                {data?.facility?.id && (
                  <Button
                    label="Complete"
                    color="green"
                    type="primary"
                    glyph="tick_circle"
                    onClick={handleSubscribe}
                    isLoading={isSubscribing}
                  />
                )}
              </>
            )}
          </div>
        )}
      </ReviewSection>
    </>
  )
}
