import create from 'zustand'
import produce from 'immer'
import { persist } from 'zustand/middleware'

export const usePinsStore = create(
  persist(
    (set: any, get: any) => ({
      pins: {},

      setPins: (tenant: string, data: any) =>
        set({
          pins: produce(get().pins, (draft: any) => {
            draft[tenant] = data
          }),
        }),

      addPin: (tenant: string, id: string, data: any) =>
        set({
          pins: produce(get().pins, (draft: any) => {
            if (!draft[tenant]) draft[tenant] = {}

            draft[tenant][id] = data
          }),
        }),

      removePin: (tenant: string, id: string) =>
        set({
          pins: produce(get().pins, (draft: any) => {
            if (!draft[tenant]) return

            delete draft[tenant][id]
          }),
        }),

      togglePin: (tenant: string, id: string, data: any) =>
        set({
          pins: produce(get().pins, (draft: any) => {
            // remove existing item
            if (draft[tenant]?.[id]) {
              delete draft[tenant][id]
              return
            }

            // add item
            if (!draft[tenant]) {
              draft[tenant] = {
                [id]: data,
              }
            } else {
              draft[tenant][id] = data
            }
          }),
        }),
    }),
    { name: 'bh.app-sidebar-pins' },
  ),
)
