import React from 'react'

import { COLORS } from '../../../../theme'

import Flex from '../../../Flex'
import Icon from '../../../Icon'

export const EventOutcomeItem = (props: any) => {
  const { icon, title, titleAfter, description, meta, onClick } = props

  return (
    <div css={STYLES.root} onClick={onClick} className={!!onClick ? 'as-link' : ''}>
      {icon && <Icon icon={icon} size={18} css={STYLES.icon} />}

      <div>
        {title && (
          <div css={STYLES.title}>
            {title}
            {titleAfter && <div css={STYLES.titleAfter}>{titleAfter}</div>}
          </div>
        )}

        {description && <div>{description}</div>}
        {meta && (
          <Flex gap="0.25rem" className="text-[0.85rem]">
            {meta}
          </Flex>
        )}
      </div>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0.5rem 1rem',

    '&.as-link': {
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: COLORS.hover,
      },
    },

    '& + &': {
      borderTop: `1px solid ${COLORS.divider}`,
    },
  },

  icon: {
    marginRight: '0.75rem',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,

    '.as-link &': {
      color: COLORS.blue,
    },
  },

  titleAfter: {
    marginLeft: '0.5rem',
  },
}
