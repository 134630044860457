import React from 'react'
import { useMedia } from 'use-media'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import Button from '../../components/Button'
import Card from '../../components/Card'
import DateInput from '../../components/Forms/DateInput'
import Flex from '../../components/Flex'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import State from '../../components/State'
import Status from '../../components/Status'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'

import { FormReadonlyReviewCard } from './components/FormReadonlyReviewCard'
import { TreatmentPlanRecordStatus } from './form_elements/TreatmentPlanRecordStatus'
import { useTreatmentPlanStore } from './store'

export const TreatmentPlanProblemsForm = (props: any) => {
  const { data = [], onUpdate, showDates, usePlanReviews, problemsShowSettings, useDiagnoses, useGoals, planId } = props

  const [formData, setFormData]: any = React.useState()

  const isDesktop = useMedia({ minWidth: 1500 })
  const reviewsVisible = useTreatmentPlanStore((state: any) => state.problemReviewsVisible)

  const isEmpty = size(data) === 0

  React.useEffect(() => {
    const treatment_problems_attributes: any = []

    if (!data) return { treatment_problems_attributes }

    for (const problem of data) {
      treatment_problems_attributes.push({
        id: problem.id,
        name: problem.name,
        behavioral_definitions: problem.behavioral_definitions,
        status: problem.status,
        started_at: problem.started_at,
        target_at: problem.target_at,
        completed_at: problem.completed_at,
        last_treatment_review: problem.last_treatment_review,
        next_treatment_review: problem.next_treatment_review,
        treatment_diagnoses: problem.treatment_diagnoses,
      })
    }

    setFormData({ treatment_problems_attributes })
  }, [data])

  React.useEffect(() => {
    if (!formData) return

    onUpdate(formData)
  }, [formData])

  if (!formData) return null

  const addProblemAction = (
    <Button
      label="Add Problem"
      glyph="add"
      size={100}
      type="primary"
      display="inline-flex"
      className="mt-3"
      onClick={() => {
        setFormData((prev: any) => {
          const updated = produce(prev, (draft) => {
            draft.treatment_problems_attributes.push({
              _id: uuid(),
              name: `Problem ${size(draft.treatment_problems_attributes) + 1}`,
              behavioral_definitions: '',
              status: 'in_progress',
              started_at: '',
              target_at: '',
              completed_at: '',
            })
          })

          return updated
        })
      }}
    />
  )

  return (
    <>
      {size(formData.treatment_problems_attributes) === 0 ? (
        <Card>
          <State
            isEmpty
            icon="treatment_plans"
            title="Problems"
            emptyDescription="Start by adding the problem"
            emptyActions={!isEmpty && addProblemAction}
          />
        </Card>
      ) : (
        <>
          {reviewsVisible && isDesktop && usePlanReviews && (
            <Card className="px-3 py-1.5 sticky top-2 z-[3] font-[600] mb-2">
              <div className="grid grid-cols-[1fr_220px_220px] mq1600:grid-cols-[1fr_280px_280px] mq1800:grid-cols-[1fr_340px_340px] gap-3">
                <div>Problems</div>
                <div className="pl-3 border-l border-0 border-solid border-divider text-center">Last Reviews</div>
                <div className="pl-3 border-l border-0 border-solid border-divider text-center">Next Reviews</div>
              </div>
            </Card>
          )}

          {formData.treatment_problems_attributes?.map?.((problem: any, problemIndex: number) => {
            return (
              <div key={problem.id || problem._id} className={problem?._destroy ? 'hidden' : ''}>
                <CardItem
                  data={problem}
                  showDates={showDates}
                  identifier={`Problem ${problemIndex + 1}`}
                  useDiagnoses={useDiagnoses}
                  useGoals={useGoals}
                  planId={planId}
                  onUpdate={(key, value) => {
                    setFormData((prev: any) => {
                      return produce(prev, (draft) => {
                        draft.treatment_problems_attributes[problemIndex][key] = value
                      })
                    })
                  }}
                  onDelete={() => {
                    setFormData((prev: any) => {
                      const isAPIRecord = !!problem.id

                      if (isAPIRecord) {
                        return produce(prev, (draft) => {
                          draft.treatment_problems_attributes[problemIndex]._destroy = true
                        })
                      }

                      return produce(prev, (draft) => {
                        draft.treatment_problems_attributes.splice(problemIndex, 1)
                      })
                    })
                  }}
                  problemsShowSettings={problemsShowSettings}
                />
              </div>
            )
          })}

          <div className="pl-2">{addProblemAction}</div>
        </>
      )}
    </>
  )
}

const CardItem = (props: any) => {
  const { data, identifier, onDelete, onUpdate, showDates, usePlanReviews, problemsShowSettings, useDiagnoses, planId } = props

  const isDesktop = useMedia({ minWidth: 1500 })
  const reviewsVisible = useTreatmentPlanStore((state: any) => state.problemReviewsVisible)

  const lastReview = data?.last_treatment_review
  const nextReview = data?.next_treatment_review

  return (
    <div
      className={clsx(
        'pt-4',
        reviewsVisible &&
          isDesktop &&
          'grid grid-cols-[1fr_220px_220px] mq1600:grid-cols-[1fr_280px_280px] mq1800:grid-cols-[1fr_340px_340px] gap-3',
      )}
    >
      <Card className="block px-3 py-3 border-solid border-transparent [&.active]:!border-blue-300 [&.active]:ring-2 ring-blue-100 text-text">
        <FormSection className="!gap-2" maxWidth="100%">
          <Flex centerY stretchSelfX gap="0.75rem" className="!flex-[1_1_auto]">
            <Status small label={identifier} color="textMuted" className="!text-text" />

            <TreatmentPlanRecordStatus
              label={null}
              value={data.status}
              onUpdate={(updated) => onUpdate('status', updated.value)}
              className="[&_select]:!min-h-[1.8rem] [&_select]:!py-0"
            />

            <Button label="Delete" glyph="delete" onClick={onDelete} type="minimal" color="red" size={100} className="!ml-auto" />
          </Flex>

          <Flex gap="1rem">
            <div className="!flex-[1_1_auto]">
              <Input label="Name" value={data.name} onUpdate={(updated) => onUpdate('name', updated.value)} />
            </div>

            {showDates && (
              <Flex gap="1rem">
                {problemsShowSettings?.start_date && (
                  <DateInput
                    defaultToNow
                    label="Start"
                    value={data.started_at}
                    onUpdate={(updated) => onUpdate('started_at', updated.value)}
                  />
                )}

                {problemsShowSettings?.target_date && (
                  <DateInput label="Target" value={data.target_at} onUpdate={(updated) => onUpdate('target_at', updated.value)} />
                )}

                {problemsShowSettings?.completion_date && (
                  <DateInput label="Completion" value={data.completed_at} onUpdate={(updated) => onUpdate('completed_at', updated.value)} />
                )}
              </Flex>
            )}
          </Flex>

          {useDiagnoses && (
            <MultiObjectSelector
              label="Linked Diagnoses"
              type="treatment_plan.treatment_diagnoses"
              dependentValue={planId}
              selectTitle={(data) => data?.diagnosis?.code}
              selectDescription={(data) => data?.diagnosis?.description}
              value={data.treatment_diagnoses}
              onUpdate={(update) => {
                const newIds = update?.value?.map?.((o) => o.id) || []
                onUpdate('treatment_diagnosis_ids', newIds)
              }}
            />
          )}

          <SmartTextarea
            useQuickText
            useDictation
            label="Description"
            value={data.behavioral_definitions}
            onUpdate={(updated) => onUpdate('behavioral_definitions', updated.value)}
          />
        </FormSection>
      </Card>

      {reviewsVisible && isDesktop && usePlanReviews && (
        <>
          <FormReadonlyReviewCard data={lastReview} type="last" />
          <FormReadonlyReviewCard data={nextReview} type="next" hideCreate={!lastReview} />
        </>
      )}
    </div>
  )
}
