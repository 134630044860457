import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { Button, Card, Chotomate, Grid, Page, HelpTagIframe, Link } from '@behavehealth/components'
import { CommunicationsTable } from '@behavehealth/components/Tables'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

const Communications: React.FC = () => {
  const match = useRouteMatch()
  const { user } = useSettings()
  const { resource_id }: any = useParams()

  const [filters, setFilters] = React.useState({})

  const { data, isLoading }: any = useGet({
    name: ['organization', resource_id, 'communications', { filters }],
    url: `/organizations/${resource_id}/communications`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const isEmpty = size(data) === 0

  return (
    <Page
      title="Communications"
      feature="communications"
      help={<HelpTagIframe id="organization_contacts_and_communications" />}
      actions={
        <>
          <Button
            label="Add Communication Log"
            type="primary"
            glyph="add"
            link={{
              pathname: `${match.url}/new`,
              data: {
                employee: user,
              },
            }}
            permission="communications.create"
          />
        </>
      }
    >
      <Grid gap="1rem" columns="100%">
        <Filters config={FILTERS.organization_communications} onUpdate={setFilters} localStorageKey="organization_communications" />

        <Card>
          <Chotomate name="contacts_communications" ready={!isLoading} />

          <CommunicationsTable
            data={data}
            isLoading={isEmpty && isLoading}
            localStorageKey="organizations_communications"
            titleAfter={<Link to="/reports/organizations-communications">View Full Report →</Link>}
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            emptyActions={
              <Button
                label="Add Communication Log"
                type="primary"
                glyph="add"
                link={{
                  pathname: `${match.url}/new`,
                  data: {
                    employee: user,
                  },
                }}
                permission="communications.create"
              />
            }
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default Communications
