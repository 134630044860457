import React from 'react'

import { useDataTable } from '../../../DataTable/useDataTable'

import { AdminProductTemplatesDataTable } from '../../../../constructs/InternalBilling/AdminProductTemplatesDataTable'

export const AdminPlanAddonServiceTemplatesTable = ({ onSelect }: any) => {
  const tableProps = useDataTable({
    name: ['product_templates'],
    endpoint: '/internal_templates',
    params: { category: 'product' },
    localStorageKey: 'product_templates_v1',
  })

  return <AdminProductTemplatesDataTable {...tableProps} asCard onClick={onSelect} />
}
