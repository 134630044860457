import React from 'react'

import { beautifulAmount } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Dialog from '../../components/Dialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PercentageInput from '../../components/Forms/PercentageInput'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'

import { PRICING_STRATEGIES, PRICING_UNIT_TYPES } from '../../components/Billing/constants'

const RootDiscountOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    updateAsync,
  } = useOverlay({
    name: 'discounts',
    endpoint: '/discounts',
    invalidate: 'discounts',
    options: props,
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const toggleArchive = async () => {
    await updateAsync({ status: initialModel.status === 'archived' ? 'active' : 'archived' })
    close()
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable}>
      <Overlay.Header title="Discount" icon="certificate" />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection>
              {/* <Alert type="positive">Discount has been used at least once. It cannot be updated anymore.</Alert> */}

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <Input label="SKU" model="sku" />

              <Select allowEmpty label="Status" model="status" defaultValue="active">
                <Option label="Inactive" value="inactive" />
                <Option label="Active" value="active" />
                {/* <Option label="In Use" value="in_use" /> */}
                <Option label="Used Up" value="used" />
                <Option label="Archived" value="archived" />
              </Select>

              <RadioGroup label="Type" layout="vertical-dense" model="discount_type" defaultValue="fixed">
                <Radio label="Fixed Amount" value="fixed" />
                <Radio label="Percentage" value="percentage" />
              </RadioGroup>

              <ContextShow when="discount_type" is="fixed">
                <AmountInput
                  label="Amount"
                  prefix="$"
                  size={6}
                  model="amount_off"
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                  }}
                />
              </ContextShow>

              <ContextShow when="discount_type" is="percentage">
                <PercentageInput label="Percentage" model="percent_off" />
              </ContextShow>

              <RadioGroup label="Number of Redemptions" layout="vertical-dense" model="duration" defaultValue="once">
                <Radio label="Once" value="once" />
                <Radio label="Max Redemptions" value="number_of_months" />
                <Radio label="Until" value="until" />
                <Radio label="Forever" value="forever" />
              </RadioGroup>

              <ContextShow when="duration" is="number_of_months">
                <Input
                  label="Max Redemptions"
                  suffix="months"
                  size={6}
                  model="max_redemptions"
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                  }}
                />
              </ContextShow>

              <ContextShow when="duration" is="until">
                <DateInput
                  label="Valid Until"
                  model="valid_until"
                  validations={{
                    presence: {
                      message: 'Please select a date until the discount is valid',
                    },
                  }}
                />
              </ContextShow>

              <ContextShow when="usage" is="promo_code">
                <Input label="Promo Code" model="promo_code" />
              </ContextShow>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
            />
            <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" />

            {/* <Dialog
              title={initialModel?.status === 'archived' ? 'Activate' : 'Archive'}
              message="Are you sure you want to update this discount?"
              onYes={toggleArchive}
            >
              <Button
                label={initialModel?.status === 'archived' ? 'Activate' : 'Archive'}
                glyph="empty"
                color="text"
                isLoading={isSaving}
              />
            </Dialog> */}

            <DeleteDialog title="Delete discount?" message="Are you sure you want to delete this discount?" onYes={deleteRecord}>
              <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const DiscountOverlay = withOverlayError(RootDiscountOverlay)
