import React from 'react'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import { css, COLORS, INPUT_STYLES } from '../../theme'
import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { withFormContext } from '../../components/Forms/context'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Field from '../../components/Forms/Field'
import Glyph from '../../components/Glyph'
import GridTable from '../../components/GridTable'
import Icon from '../../components/Icon'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'

import { TreatmentPlanSelectorOverlay } from './TreatmentPlanSelectorOverlay'
import { useFormField } from '../Forms/hooks/useFormField'

import { useTreatmentPlanPermissions } from '../../constructs/TreatmentPlans/useTreatmentPlanPermissions'

import { ToggleCell } from '../../constructs/TreatmentPlans/components/TreeComponents'

const RootTreatmentPlanSelector = (props: any) => {
  const {
    afterChange,
    testKey,
    description,
    form,
    isEditable,
    label = 'Linked Goals, Objectives, Interventions',
    labelAfter,
    model,
    validations,
    clientId,
    baseModel,
    allowed,
  } = props

  const goalsModel = `${baseModel ? `${baseModel}.` : ''}${props.goalsModel || 'treatment_goals'}`
  const objectivesModel = `${baseModel ? `${baseModel}.` : ''}${props.objectivesModel || 'treatment_objectives'}`
  const interventionsModel = `${baseModel ? `${baseModel}.` : ''}${props.interventionsModel || 'treatment_interventions'}`

  const { viewSomeTreatmentPlans } = useTreatmentPlanPermissions()

  const prevClientId = React.useRef(clientId)

  const { formActions: goalsFormActions, formState: goalsFormState } = useFormField({
    model: goalsModel,
    form: form,
    isRelations: true,
    isPolymorphic: false,
    validations: validations,
    afterChange: afterChange,
  })

  const { formActions: objectivesFormActions, formState: objectivesFormState } = useFormField({
    model: objectivesModel,
    form: form,
    isRelations: true,
    isPolymorphic: false,
    validations: validations,
    afterChange: afterChange,
  })

  const { formActions: interventionsFormActions, formState: interventionsFormState } = useFormField({
    model: interventionsModel,
    form: form,
    isRelations: true,
    isPolymorphic: false,
    validations: validations,
    afterChange: afterChange,
  })

  const initialGoals = form?.getInitialInputFieldValue(goalsModel) || []
  const initialObjectives = form?.getInitialInputFieldValue(objectivesModel) || []
  const initialInterventions = form?.getInitialInputFieldValue(interventionsModel) || []

  const [goals, setGoals]: any = React.useState(initialGoals)
  const [objectives, setObjectives]: any = React.useState(initialObjectives)
  const [interventions, setInterventions]: any = React.useState(initialInterventions)

  const [openGoalIds, setOpenGoalIds] = React.useState<any>([])
  const [openObjectiveIds, setOpenObjectiveIds] = React.useState<any>([])
  const [openInterventionIds, setOpenInterventionIds] = React.useState<any>([])

  const goalsCount = size(goals)
  const objectivesCount = size(objectives)
  const interventionsCount = size(interventions)

  const isEmpty = goalsCount === 0 && objectivesCount === 0 && interventionsCount === 0

  const inputClasses = clsx(STYLES.input().className, { 'is-empty': isEmpty })

  const templateColumns = React.useMemo(() => {
    let result = '120px 1fr 2fr'

    if (isEditable) result += ' 40px'

    return result
  }, [isEditable])

  const toggleGoalOpen = (goalId: any) => {
    setOpenGoalIds((c: any) => {
      if (c.includes(goalId)) {
        return c.filter((id: any) => id !== goalId)
      } else {
        return [...c, goalId]
      }
    })
  }

  const toggleObjectiveOpen = (objectiveId: any) => {
    setOpenObjectiveIds((c: any) => {
      if (c.includes(objectiveId)) {
        return c.filter((id: any) => id !== objectiveId)
      } else {
        return [...c, objectiveId]
      }
    })
  }

  const toggleInterventionOpen = (interventionId: any) => {
    setOpenInterventionIds((c: any) => {
      if (c.includes(interventionId)) {
        return c.filter((id: any) => id !== interventionId)
      } else {
        return [...c, interventionId]
      }
    })
  }

  const handleSelect = (newData: any) => {
    if (!newData) return

    if (newData.type === 'treatment_goal') {
      setGoals((c: any) => [...c, newData.data])
    } else if (newData.type === 'treatment_objective') {
      setObjectives((c: any) => [...c, newData.data])
    } else if (newData.type === 'treatment_intervention') {
      setInterventions((c: any) => [...c, newData.data])
    }
  }

  React.useEffect(() => {
    goalsFormActions.setValue(goals)
  }, [goals])

  React.useEffect(() => {
    objectivesFormActions.setValue(objectives)
  }, [objectives])

  React.useEffect(() => {
    interventionsFormActions.setValue(interventions)
  }, [interventions])

  React.useEffect(() => {
    if (!prevClientId.current) return

    if (prevClientId.current !== clientId) {
      setGoals([])
      setObjectives([])
      setInterventions([])
    }
  }, [clientId, prevClientId.current])

  React.useEffect(() => {
    prevClientId.current = clientId
  }, [clientId])

  if (!viewSomeTreatmentPlans) return null

  return (
    <Field
      testKey={testKey}
      label={label}
      description={description}
      labelAfter={labelAfter}
      isEditable={isEditable}
      withHover={false}
      // isRequired={formState.isRequired}
      // isValid={formState.isValid}
    >
      {isEditable && (
        <SummonOverlay overlay={<TreatmentPlanSelectorOverlay allowed={allowed} onSelect={handleSelect} clientId={clientId} />}>
          <div className={inputClasses}>
            <div className={STYLES.inputContent()}>
              <div className={STYLES.emptyGraphic()}>
                <Icon icon="treatment_plans" size={20} />
              </div>

              <div className={STYLES.emptyPlaceholder()}>Select…</div>
              <Glyph glyph="selector" size={20} className={STYLES.selectorGlyph()} />
            </div>
          </div>
        </SummonOverlay>
      )}

      {isEmpty && !isEditable && DEFAULT_EMPTY_VALUE}

      {!isEmpty && (
        <Card>
          <GridTable templateColumns={templateColumns} useBanding={false} className="text-[0.94rem]">
            <GridTable.Header>
              <GridTable.Cell>Type</GridTable.Cell>
              <GridTable.Cell>Name</GridTable.Cell>
              <GridTable.Cell>Description</GridTable.Cell>
              {isEditable && <GridTable.Cell></GridTable.Cell>}
            </GridTable.Header>

            {goals?.map?.((item: any, index: number) => {
              const isOpen = openGoalIds.includes(item.id)
              const hasProblems = size(item.treatment_problems) > 0

              return (
                <>
                  <GridTable.Row key={item.id}>
                    <ToggleCell
                      isOpen={isOpen}
                      onClick={() => toggleGoalOpen(item.id)}
                      hasChildren
                      label={<Status inline small label="Goal" color="blue" />}
                    />

                    <GridTable.Cell className="leading-normal w-full truncate">{item.name || '–'}</GridTable.Cell>
                    <GridTable.Cell className="leading-normal w-full truncate">{item.description || '–'}</GridTable.Cell>

                    {isEditable && (
                      <GridTable.Cell>
                        <ClearButton
                          onClick={() => {
                            setGoals(
                              produce(goals, (draft: any) => {
                                const foundIndex = draft.findIndex((o: any) => o.id === item.id)
                                draft.splice(foundIndex, 1)
                              }),
                            )
                          }}
                        />
                      </GridTable.Cell>
                    )}
                  </GridTable.Row>

                  {isOpen && (
                    <GridTable.Row className="!grid-cols-[100%]">
                      <div className="px-3 py-2 leading-normal text-[0.92rem]">
                        {hasProblems ? (
                          <>
                            <div className="font-[600]">Linked Problems:</div>
                            <ul className="list-disc pl-6">
                              {item.treatment_problems.map((problem: any) => (
                                <li key={problem.id}>{problem.name}</li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <div className="text-center text-text-muted italic">No problems associated with this goal</div>
                        )}
                      </div>
                    </GridTable.Row>
                  )}
                </>
              )
            })}

            {objectives?.map?.((item: any, index: number) => {
              const isOpen = openObjectiveIds.includes(item.id)
              const goal = item?.treatment_goal

              return (
                <>
                  <GridTable.Row key={item.id}>
                    <ToggleCell
                      isOpen={isOpen}
                      onClick={() => toggleObjectiveOpen(item.id)}
                      hasChildren
                      label={<Status inline small label="Objective" color="purple" className="mr-2" />}
                    />

                    <GridTable.Cell className="leading-normal w-full truncate">{item.name || '–'}</GridTable.Cell>
                    <GridTable.Cell className="leading-normal w-full truncate">{item.description || '–'}</GridTable.Cell>

                    {isEditable && (
                      <GridTable.Cell>
                        <ClearButton
                          onClick={() => {
                            setObjectives(
                              produce(objectives, (draft: any) => {
                                const foundIndex = draft.findIndex((o: any) => o.id === item.id)
                                draft.splice(foundIndex, 1)
                              }),
                            )
                          }}
                        />
                      </GridTable.Cell>
                    )}
                  </GridTable.Row>

                  {isOpen && (
                    <GridTable.Row className="!grid-cols-[100%]">
                      <div className="px-3 py-2 leading-normal text-[0.92rem]">
                        <div className="font-[600]">Linked Goal:</div>
                        {goal?.name && <div className="font-[600]">{goal?.name}</div>}
                        {goal?.description && <div className="font-[400] text-text-muted">{goal?.description}</div>}
                      </div>
                    </GridTable.Row>
                  )}
                </>
              )
            })}

            {interventions?.map?.((item: any, index: number) => {
              const isOpen = openInterventionIds.includes(item.id)
              const goal = item?.treatment_goal
              const objective = item?.treatment_objective

              return (
                <>
                  <GridTable.Row key={item.id}>
                    <ToggleCell
                      isOpen={isOpen}
                      onClick={() => toggleInterventionOpen(item.id)}
                      hasChildren
                      label={<Status small label="Intervention" color="orange" className="mr-2" />}
                    />

                    <GridTable.Cell className="leading-normal w-full truncate">{item.name || '–'}</GridTable.Cell>
                    <GridTable.Cell className="leading-normal w-full truncate">{item.description || '–'}</GridTable.Cell>

                    {isEditable && (
                      <GridTable.Cell>
                        <ClearButton
                          onClick={() => {
                            setInterventions(
                              produce(interventions, (draft: any) => {
                                const foundIndex = draft.findIndex((o: any) => o.id === item.id)
                                draft.splice(foundIndex, 1)
                              }),
                            )
                          }}
                        />
                      </GridTable.Cell>
                    )}
                  </GridTable.Row>

                  {isOpen && (
                    <GridTable.Row className="!grid-cols-[100%]">
                      <div className="px-3 py-2 leading-normal text-[0.92rem]">
                        <div className="font-[600]">Linked Goal:</div>
                        {goal?.name && <div className="font-[600]">{goal?.name}</div>}
                        {goal?.description && <div className="font-[400] text-text-muted">{goal?.description}</div>}

                        <div className="font-[600] mt-2">Linked Objective:</div>
                        {objective?.name && <div className="font-[600]">{objective?.name}</div>}
                        {objective?.description && <div className="font-[400] text-text-muted">{objective?.description}</div>}
                      </div>
                    </GridTable.Row>
                  )}
                </>
              )
            })}
          </GridTable>
        </Card>
      )}
    </Field>
  )
}

const ClearButton = ({ onClick }: any) => {
  return (
    <Button hideLabel size={100} glyph="cross" color="text" type="minimal" onClick={onClick} className={STYLES.clearButton().className} />
  )
}

const STYLES = {
  input: css({
    ...INPUT_STYLES,
    cursor: 'pointer',
    paddingLeft: 0,
    flexWrap: 'nowrap',

    '@media (min-width: 600px)': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
  }),

  inputContent: css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexWrap: 'wrap',
    flex: '1 1 auto',
  }),

  inputAction: css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexWrap: 'nowrap',
    flex: '1 1 auto',
    marginTop: '0.5rem',
    paddingTop: '0.5rem',
    borderTop: `1px solid ${COLORS.divider}`,

    '@media (min-width: 600px)': {
      flex: '0 0 auto',
      borderLeft: `1px solid ${COLORS.divider}`,
      marginTop: '0',
      paddingTop: '0',
      marginLeft: '0.5rem',
      borderTop: 'none',
    },
  }),

  emptyGraphic: css({
    width: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),

  emptyPlaceholder: css({
    color: COLORS.textMuted,
    opacity: 0.6,
    fontStyle: 'italic',
  }),

  selectorGlyph: css({
    marginLeft: 'auto',
  }),

  item: css({
    // padding: '0.5rem 0',

    '& + &': {
      marginTop: '0.5rem',
    },
  }),

  cardTreeTitle: css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    alignItems: 'center',
    fontSize: '0.95rem',
    flex: '1 1 auto',
  }),

  itemGraphic: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    marginRight: '0.4rem',
  }),

  clearButton: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    marginLeft: 'auto',

    svg: {
      margin: '0 !important',
    },

    '&:hover': {
      svg: {
        fill: COLORS.red,
      },
    },
  }),
}

export const TreatmentPlanSelector = withFormContext(RootTreatmentPlanSelector)
