import React from 'react'

import * as HoverCard from '@radix-ui/react-hover-card'

import Card from '../../Card'

import { usePortal } from '../../../hooks/usePortal'

import { DataTableCell } from '../DataTableCell'

export const HoverCell: React.FC = (props: any) => {
  const portalContainer = usePortal('default')
  const [isOpen, setIsOpen] = React.useState(false)

  const hoverContent = props.hoverContent ? props.hoverContent({ value: props.value, data: props.rowData }) : null

  return (
    <HoverCard.Root open={isOpen} onOpenChange={setIsOpen} openDelay={0} closeDelay={0}>
      <HoverCard.Trigger asChild>
        <DataTableCell {...props} />
      </HoverCard.Trigger>

      <HoverCard.Portal container={portalContainer}>
        <HoverCard.Content asChild align="center" side="right" sideOffset={5}>
          <Card className="px-1 py-2">{hoverContent}</Card>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  )
}
