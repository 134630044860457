import React from 'react'
import { connect } from 'react-redux'
import compact from 'lodash/compact'

import { useLocation } from 'react-router-dom'

import { fullname, usDateTime, usTime, titleDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import Status from '../Status'
import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'
import CardSubtitle from '../CardSubtitle'

type Props = {
  location: any
  test: any
  timezone: any
  to: any
  user: any
}

const UATest = ({ test, timezone, to, user }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  let checkedTests: any = []
  if (test?.test_results?.length > 0) {
    checkedTests = compact(
      test.test_results.map((value) => {
        if (value.checked) return value.name
        else return null
      }),
    )
  }

  const isBehave = user?.type === 'bh_employee'
  const isBilled = test.is_billed ? `Yes (${usDateTime(test.billed_at, timezone)})` : 'No'

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle
        title={titleDate(test?.created_at)}
        after={
          <>
            {test?.status === 'refused' ? (
              <Status color="red" label="Refused Collection" />
            ) : (
              <Status
                color={test?.result === 'negative' ? 'green' : 'red'}
                label={test?.result + (test?.result === 'positive' ? ` (${checkedTests.length})` : '')}
              />
            )}
          </>
        }
      />

      <CardMeta>
        <Text avatar={test?.author?.avatar} label={fullname(test?.author)} />
        <Text glyph="time" label="Time: " description={usTime(test?.created_at)} />
        {isBehave && <Text icon="behave_health" label="Billed: " description={isBilled} />}
      </CardMeta>

      <CardSubtitle subtitle={test?.additional_info} />
      {checkedTests.length > 0 && <CardSubtitle subtitle={checkedTests.join(', ')} />}
    </CardLink>
  )
}

const mapStateToProps = (state) => ({
  user: state.me.user,
})

export default withSettings(connect(mapStateToProps)(UATest))
