import React from 'react'
import size from 'lodash/size'

import { apiUpdate, apiGet } from '../../../modules/api'

import Alert from '../../../components/Alert'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import State from '../../../components/State'
import Workflow from '../../../components/Workflow/Workflow'

const AccessLists = ({ client, match, setStatus }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)

  const form = React.useRef()
  const [valid, setValid] = React.useState(false)
  const [requestLoading, setRequestLoading] = React.useState(false)
  const { resource_id } = match.params

  const [allEmployees, setAllEmployees]: any = React.useState([])
  const [currentEmployeeIDs, setCurrentEmployeeIDs]: any = React.useState([])

  const [loading, setLoading] = React.useState(true)
  const isEmpty = size(allEmployees) === 0

  React.useEffect(() => {
    const request = async () => {
      try {
        const _allEmployees = await apiGet({
          url: `/employees?status=active&access_level=restricted`,
        })

        const _currentEmployees = await apiGet({
          url: `/residents/${resource_id}/access_employees`,
        })

        const _currentEmployeeIDs: any = []

        if (size(_currentEmployees.data.data) >= 1) {
          for (const employee of _currentEmployees.data.data) {
            _currentEmployeeIDs.push(employee.id)
          }
        }

        setAllEmployees(_allEmployees.data.data)
        setCurrentEmployeeIDs(_currentEmployeeIDs)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    request()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onContinue = async () => {
    setRequestLoading(true)

    const model = form.current.getFormValue()
    const employeeIDs = model.access_employee_ids

    if (size(employeeIDs) === 0) {
      setRequestLoading(false)
      goNext()
    }

    const result: any = []

    for (const id in employeeIDs) {
      if (!employeeIDs[id]) continue

      result.push(id)
    }

    await apiUpdate({
      name: 'access_employees',
      url: `/residents/${resource_id}`,
      params: { access_employee_ids: result },
    })

    setRequestLoading(false)
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  if (loading) return <State isLoading />

  if (isEmpty) {
    return (
      <FormSection maxWidth="100%">
        <Alert glyph="warning" type="warning">
          No staff members have restricted client access, this step can be skipped.
        </Alert>

        <Workflow.SkipButton onClick={onSkip} />
      </FormSection>
    )
  }

  return (
    <Form useLocalModel getForm={form} onValidationUpdate={setValid}>
      <FormSection maxWidth="100%">
        <CheckboxGroup gap="1.75rem" label={`Add ${client.name} to the Access Lists of the following Staff:`} layout="vertical-dense">
          {allEmployees.map((employee: any) => (
            <Checkbox
              label={employee.name}
              model={`access_employee_ids.${employee.id}`}
              defaultChecked={currentEmployeeIDs.includes(employee.id)}
            />
          ))}
        </CheckboxGroup>

        <Workflow.Buttons>
          <Workflow.ContinueButton label="Save & Continue →" onClick={onContinue} isDisabled={!valid} isLoading={requestLoading} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default AccessLists
