import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { usDate } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import { ComboBox } from '../../components/Forms/ComboBoxes/ComboBox'
import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Status from '../../components/Status'

const RootNewVOBOverlay = ({ onClose }: any) => {
	const form = React.useRef()
	const match: any = useRouteMatch()
	const history = useHistory()

	const clientID = match?.params?.resource_id
	const client = useSelector((state: any) => state.data?.clients?.data?.[clientID])

	const user = useSelector((state: any) => state.me?.user)

	const [valid, setValid] = React.useState(false)

	const { mutateAsync: createVOB, isLoading: isCreating } = useCreate({
		name: ['insurance_vobs'],
		url: `/insurance_vobs`,
		invalidate: ['insurance_vobs']
	})

	const startNewVOB = async () => {
		try {
			const res = await createVOB(form.current?.getFormValue())
			const vobID = res.id

			if (vobID) {
				onClose()
				history.push(`${match.url}/${vobID}`)
			}
		} catch (error) {
			console.debug(error)
		}
	}

	return (
		<Overlay showBackdrop position="center" onClose={onClose}>
			<Overlay.Header icon="financials" title="Add New VOB" />

			<Overlay.Content>
				<Section>
					<Form
						getForm={form}
						onValidationUpdate={setValid}
						initialModel={{
							employee: user
						}}
						linked={{ client_id: client?.id, version: 'v2' }}
					>
						<FormSection>
							{client && (
								<>
									<ObjectSelector
										label="Insurance Policy"
										icon="insurance"
										model="insurance_policy"
										type="client.insurance_policies"
										dependentValue={client.id}
										selectTitle={(data: any) => data.insurance_name}
										selectDescription={(data: any) => <Status label={data.category} color="gray" />}
										validations={{
											presence: {
												message: 'Please select an insurance policy to link'
											}
										}}
									/>

									<ComboBox
										icon="insurance"
										label="Fee Schedule"
										model="insurance_new_fee_schedule"
										type="insurance.new_fee_schedules.all"
										maxWidth="100%"
										validations={{
											presence: {
												message: 'Please select a Fee Schedule'
											}
										}}
									/>

									<ObjectSelector
										label="eVOB"
										icon="vob"
										model="insurance_evob"
										type="client.insurance_evobs"
										dependentValue={client.id}
										selectTitle={(data: any) => usDate(data.created_at)}
										selectDescription={(data: any) => data.insurance_global_payer?.name}
									/>
								</>
							)}
						</FormSection>
					</Form>
				</Section>
			</Overlay.Content>

			<Overlay.Footer>
				<Button
					label="Create New VOB"
					color="green"
					type="primary"
					onClick={startNewVOB}
					isLoading={isCreating}
					isDisabled={!valid}
				/>
			</Overlay.Footer>
		</Overlay>
	)
}

export const NewVOBOverlay = withOverlayError(RootNewVOBOverlay)
