import React from 'react'
import { Popper } from 'react-popper'

import Portal from './Portal'

const preventOverflow = {
  name: 'preventOverflow',
  enabled: true,
  options: {
    altAxis: true,
    mainAxis: true,
    padding: '20px',
  },
}

const SmartPortal = ({ children, position, fallbackPositions, portal, useReferenceWidth, testKey }) => {
  const sameWidth = React.useMemo(
    () => ({
      name: 'sameWidth',
      enabled: useReferenceWidth,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`
      },
      effect: ({ state }) => {
        state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
      },
    }),
    [useReferenceWidth],
  )

  const flip = React.useMemo(
    () => ({
      name: 'flip',
      options: {
        fallbackPlacements: fallbackPositions || ['auto'],
      },
    }),
    [],
  )

  if (!children) return null

  return (
    <Portal type={portal}>
      <Popper placement={position} modifiers={[preventOverflow, sameWidth, flip]}>
        {({ ref, style, placement }) => (
          <div css={{ outline: 0 }} ref={ref} style={style} data-placement={placement} data-test={testKey}>
            {children}
          </div>
        )}
      </Popper>
    </Portal>
  )
}

SmartPortal.defaultProps = {
  position: 'bottom',
  portal: 'default',
  useReferenceWidth: false,
}

export default SmartPortal
