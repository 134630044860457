import React from 'react'

import PageSectionContent from './PageSectionContent'
import PageSectionHeader from './PageSectionHeader'
import PageSectionSubtitle from './PageSectionSubtitle'
import PageSectionTitle from './PageSectionTitle'

const PageSection = ({ children, className }: any) => {
  return (
    <section css={styles} className={className}>
      {children}
    </section>
  )
}

const styles = {
  display: 'grid',
  gridTemplateColumns: '1fr',
}

PageSection.Content = PageSectionContent
PageSection.Header = PageSectionHeader
PageSection.Subtitle = PageSectionSubtitle
PageSection.Title = PageSectionTitle

export default PageSection
