import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'
import { usDate } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import DataList from '../../../components/DataList'
import DiagnosisCategoryStatus from '../../../components/Statuses/DiagnosisCategoryStatus'
import DiagnosisStatus from '../../../components/Statuses/DiagnosisStatus'

import { RecordTooltip } from '../components/RecordTooltip'

export const DiagnosisInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item label="Code" value={data.code} />
      {data.client && <DataList.Item label="Client" value={data.client.name} avatar={data.client.avatar} />}
      <DataList.Item label="Description" value={data.description} />
      <DataList.Item label="Status" value={<DiagnosisStatus status={data.status} />} />
      <DataList.Item label="Category" value={<DiagnosisCategoryStatus status={data.category} />} />
      <DataList.Item label="From Date" value={usDate(data.from, timezone)} />
      <DataList.Item label="Until Date" value={usDate(data.until, timezone)} />
    </DataList>
  )
}
