import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import withSettings from '../../../hocs/withSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Grid from '../../../components/Grid'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { MainCell } from '../../../components/DataTable/cells/MainCell'
import { ServiceEpisodeOverlay } from '../../ServiceEpisodes/ServiceEpisodeOverlay'
import { ServiceEpisodesDataTable } from '../../ServiceEpisodes/ServiceEpisodesDataTable'
import { useDataTable } from '../../../components/DataTable/useDataTable'

const AddButton = ({ client }) => {
  return (
    <SummonOverlay overlay={<ServiceEpisodeOverlay showBackdrop dataID="new" clientId={client?.id} />}>
      <Button label="Add Service Episode" glyph="add" type="primary" display="inline-flex" permission="service_episodes.create" />
    </SummonOverlay>
  )
}

const ServiceEpisodes = ({ client, setStatus }: any) => {
  const match = useRouteMatch()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { isRequired }: any = React.useContext(Workflow.PanelContext)

  const tableProps = useDataTable({
    name: ['client', client?.id, 'service-episodes'],
    endpoint: `/residents/${client?.id}/service_episodes`,
    localStorageKey: 'clients_service_episodes_v1',
    options: { enabled: !!client?.id },
  })

  const isEmpty = size(tableProps.data) === 0

  const onContinue = async () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  const mainCell = React.useCallback(({ data: rowData }: any) => {
    return (
      <SummonOverlay overlay={<ServiceEpisodeOverlay showBackdrop dataID={rowData.id} clientId={client?.id} />}>
        <MainCell id={rowData.id} value={rowData.name} />
      </SummonOverlay>
    )
  }, [])

  return (
    <Grid gap="1rem">
      <Card testKey="applications_table">
        <ServiceEpisodesDataTable asCard={false} {...tableProps} mainCell={mainCell} />

        <CardContent>
          <AddButton client={client} match={match} />
        </CardContent>
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        {!isRequired && <Workflow.SkipButton onClick={onSkip} />}
      </Workflow.Buttons>
    </Grid>
  )
}

export default withSettings(ServiceEpisodes)
