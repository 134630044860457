export const NEW_VERSION_AVAILABLE = 'ui/global/NEW_VERSION_AVAILABLE'
export const NEW_VERSION_RESET = 'ui/global/NEW_VERSION_RESET'

export const SET_ONLINE = 'ui/global/SET_ONLINE'
export const SET_OFFLINE = 'ui/global/SET_OFFLINE'

export const SET_IDLE = 'ui/global/SET_IDLE'
export const SET_IDLE_SAVING = 'ui/global/SET_IDLE_SAVING'

export const SET_APP_NAV = 'ui/appNav/SET_APP_NAV'
export const TOGGLE_DEVBAR = 'ui/global/TOGGLE_DEVBAR'

export const SET_SEARCH_RESULTS = 'ui/global/SET_SEARCH_RESULTS'

export const RESET_QUEUE = 'ui/uploader/RESET_QUEUE'
export const REMOVE_FROM_QUEUE = 'ui/uploader/REMOVE_FROM_QUEUE'
export const BUILD_QUEUE = 'ui/uploader/BUILD_QUEUE'
export const UPDATE_QUEUE = 'ui/uploader/UPDATE_QUEUE'
export const UPDATE_STATS = 'ui/uploader/UPDATE_STATS'
export const SET_CURRENT_UPLOAD = 'ui/uploader/SET_CURRENT_UPLOAD'
export const PROCESSING_STARTED = 'ui/uploader/PROCESSING_STARTED'
export const PROCESSING_FINISHED = 'ui/uploader/PROCESSING_FINISHED'

export const ADD_NOTIFICATION = 'ui/notifications/ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'ui/notifications/REMOVE_NOTIFICATION'

export const ADD_RECENT_SEARCH = 'ui/search/ADD_RECENT_SEARCH'

export const SET_BACK_ROUTE = 'ui/routes/SET_BACK_ROUTE'
export const CLEAR_BACK_ROUTE = 'ui/routes/CLEAR_BACK_ROUTE'

// SET IDLE
export const setIdle = (idle) => ({ type: SET_IDLE, idle })
export const setIdleSaving = (saving) => ({ type: SET_IDLE_SAVING, saving })

// SET ONLINE
export const setOnline = () => ({ type: SET_ONLINE })
export const setOffline = () => ({ type: SET_OFFLINE })

// QUICK SEARCH
export const addRecentSearch = (client) => ({ type: ADD_RECENT_SEARCH, client })
export const setSearchResults = (data) => ({ type: SET_SEARCH_RESULTS, data })

// NEW VERSION
export const newVersionAvailable = (version) => ({ type: NEW_VERSION_AVAILABLE, version })
export const newVersionReset = () => ({ type: NEW_VERSION_RESET })

// DEV BAR
export const toggleDevBar = () => ({ type: TOGGLE_DEVBAR })

// UPLOADER
export const queueUpload = (data: any) => ({ type: BUILD_QUEUE, data })
export const removeFromQueue = (data: any) => ({ type: REMOVE_FROM_QUEUE, data })
export const resetQueue = (data: any) => ({ type: RESET_QUEUE, data })
export const processingStarted = (data: any) => ({ type: PROCESSING_STARTED, data })
export const processingFinished = (data: any) => ({ type: PROCESSING_FINISHED, data })
export const updateQueue = (data: any) => ({ type: UPDATE_QUEUE, data })
export const updateStats = (percentage, step) => ({ type: UPDATE_STATS, percentage, step })
export const setCurrentUpload = (files, current) => ({ type: SET_CURRENT_UPLOAD, files, current })

// NOTIFICATIONS
export const addNotification = (data: any) => ({ type: ADD_NOTIFICATION, data })
export const removeNotification = (data: any) => ({ type: REMOVE_NOTIFICATION, data })

export const setBackRoute = () => ({ type: SET_BACK_ROUTE })
export const clearBackRoute = () => ({ type: CLEAR_BACK_ROUTE })
