import React from 'react'

import { useDataTable } from '../../../DataTable/useDataTable'

import { DiscountsDataTable } from '../../../../constructs/InternalBilling/DiscountsDataTable'

export const DiscountsTable = (props: any) => {
  const { onSelect } = props

  const tableProps = useDataTable({
    name: ['discounts'],
    endpoint: props?.config?.endpoint || '/discounts',
    localStorageKey: 'discounts_v1',
  })

  return <DiscountsDataTable {...tableProps} onClick={onSelect} />
}
