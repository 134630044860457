import React from 'react'

import { useCreate } from '../../hooks/useNewAPI'
import { titleCase } from '../../utils/functions'

import { Text } from '../../components/Typography'
import CardMeta from '../../components/CardMeta'
import CardTitle from '../../components/CardTitle'
import CardLink from '../../components/CardLink'

import logoCircleImg from '../../assets/accounts/ic_bh_logo.svg'

export const NewInviteCard = (props: any) => {
  const { data, onClick, variant } = props

  const { mutateAsync: acceptAsync, isLoading: isAccepting }: any = useCreate({
    name: ['invites', data?.id, 'accept'],
    url: `/invites/${data?.id}/accept`,
  })

  const { mutateAsync: declineAsync, isLoading: isDeclining }: any = useCreate({
    name: ['invites', data?.id, 'decline'],
    url: `/invites/${data?.id}/decline`,
  })

  const handleAccept = async () => {
    try {
      await acceptAsync({})
    } catch (error) {
      console.error('NewInviteCard: error accepting invite', error)
    }
  }

  const handleDecline = async () => {
    try {
      await declineAsync({})
    } catch (error) {
      console.error('NewInviteCard: error accepting invite', error)
    }
  }

  if (!data) return null

  return (
    <CardLink
      isLink
      onClick={onClick}
      showChevron
      target="_blank"
      variant={variant}
      graphic={
        data.tenant?.logo ? (
          <img src={data.tenant?.logo} alt="Logo" css={STYLES.tenantLogoImg} />
        ) : (
          <img src={logoCircleImg} alt="Behave Health Logo" />
        )
      }
    >
      <CardTitle title={data.tenant?.name} />

      <CardMeta>
        <Text glyph="offices" description={titleCase(data.tenant?.category)} />
      </CardMeta>
    </CardLink>
  )
}

const STYLES = {
  tenantLogoImg: {
    maxWidth: '3rem',
    maxHeight: '2.5rem',
  },
}
