import React from 'react'

import withSettings from '../../../hocs/withSettings'

import Avatar from '../../Avatar'
import Button from '../../Button'
import Flex from '../../Flex'
import Option from '../../Forms/Option'
import Select from '../../Forms/Select'
import SmartTextarea from '../../Forms/SmartTextarea'

const AddComment = ({ onAdd, user }: any) => {
  const [content, setContent] = React.useState('')
  const [status, setStatus] = React.useState('none')

  const addComment = () => {
    if (onAdd) onAdd({ content, status })
    setContent('')
    setStatus('none')
  }

  if (!user) return null

  return (
    <div>
      <Flex nowrap>
        <Avatar initials={user.name} src={user.avatar} size="1.5rem" />
        <span css={styles.name}>{user.name}</span>
      </Flex>

      <div css={styles.formWrapper}>
        <SmartTextarea useDictation isEditable placeholder="Add comment…" value={content} onUpdate={({ value }) => setContent(value)} />

        <Select allowEmpty isEditable label="Status" value={status} onUpdate={({ value }) => setStatus(value)}>
          <Option label="None" value="none" />
          <Option label="Todo" value="todo" />
          <Option label="Done" value="done" />
        </Select>

        <div>
          <Button
            type="primary"
            label="Add Comment"
            glyph="add"
            onClick={addComment}
            isDisabled={!content}
            display="inline-flex"
            size={300}
            css={styles.button}
          />
        </div>
      </div>
    </div>
  )
}

const styles = {
  name: {
    fontWeight: 600,
    fontSize: '0.95rem',
    marginLeft: '0.5rem',
  },

  formWrapper: {
    display: 'grid',
    gridGap: '0.5rem',
    paddingLeft: '2rem',
    marginTop: '0.5rem',
  },

  button: {
    marginTop: '0.5rem',
  },
}

export default withSettings(AddComment)
