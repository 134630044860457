import React from 'react'

import Select from '../common/Select'

type Props = { column: any }

const SelectFilter = (props: Props) => {
  const {
    column: { filterValue, setFilter, filterOptions },
  } = props

  if (!filterOptions || filterOptions.length === 0) return null

  return <Select setFilter={setFilter} filterValue={filterValue} filterOptions={filterOptions} />
}

export default SelectFilter
