import React from 'react'

import { isBlank } from '../../../utils/functions'

import Checkbox from '../Checkbox'
import CheckboxGroup from '../CheckboxGroup'
import ContextShow from '../ContextShow'
import EmailInput from '../EmailInput'
import PhoneInput from '../PhoneInput'

const SendNotificationForm = ({ email, phoneNo, model, firstName }: any) => (
  <>
    <CheckboxGroup
      layout="vertical-dense"
      maxWidth="100%"
      description={(!email || !phoneNo) && `We'll automatically save these details under ${firstName}'s contact details`}
    >
      <Checkbox
        defaultChecked={!!email}
        label={email ? `Send via Email (${email})` : `Send via Email`}
        description={!email && <em>No email has been added yet</em>}
        model={`${model}.send_email`}
      />
      <Checkbox
        defaultChecked={!!phoneNo}
        label={phoneNo ? `Send via SMS (${phoneNo})` : `Send via SMS`}
        description={!phoneNo && <em>No cell phone number has been added yet</em>}
        model={`${model}.send_sms`}
      />
    </CheckboxGroup>

    {isBlank(email) && (
      <ContextShow when={`${model}.send_email`} is={true}>
        <EmailInput
          label="Email"
          model={`${model}.email`}
          maxWidth="100%"
          validations={{
            presence: {
              message: 'Please enter an email address',
            },
          }}
        />
      </ContextShow>
    )}

    {isBlank(phoneNo) && (
      <ContextShow when={`${model}.send_sms`} is={true}>
        <PhoneInput
          label="Cell Phone Number"
          model={`${model}.phone_no`}
          maxWidth="100%"
          validations={{
            presence: {
              message: 'Please enter a cell phone number',
            },
          }}
        />
      </ContextShow>
    )}
  </>
)

export default SendNotificationForm
