// import posthog from 'posthog-js'

import ThirdParty from './third_party'

const DEBUG = false
const BLACKLISTED_PROPERTIES = [
  'password',
  'encrypted_data',
  'encrypted_data_attributes',
  'ssn',
  'address',
  'dob',
  'signature',
  'signature_data',
]

class Analytics {
  constructor() {
    this.isProduction = process.env.NODE_ENV === 'production'
    this.useAnalytics = process.env.BH_ANALYTICS === 'true'
    this.isBehave = false

    this.requestsQueue = []

    this.initialized = false
    this.identified = false
  }

  initialize = async (opts = {}) => {
    if (!this.isProduction) return
    if (!this.useAnalytics) return

    if (DEBUG) console.debug('Analytics initializing…')
    this.initialized = true

    // if (opts.key)
    //   posthog.init(opts.key, {
    //     api_host: opts.host,
    //     loaded: () => {
    //       this.initialized = true
    //     },
    //     property_blacklist: BLACKLISTED_PROPERTIES,
    //   })
  }

  identify = ({ user, tenant }, callback) => {
    if (this.identified) return

    // track everything under HQ
    if (process.env.BH_APP !== 'hq' && process.env.BH_APP !== 'community-admin') {
      // track only if user is not behave
      if (user?.email.includes('@behavehealth.com') || user?.email.includes('@behavebilling.com')) {
        this.isBehave = true
        this.logout()
      }
    }

    // if (!this.isBehave) {
    //   posthog.identify(user.email, {
    //     tracking_id: user?.tracking_id,
    //     name: user.name,
    //     email: user.email,
    //     phone: user.phone_no,
    //     company: tenant.name,
    //     tenant: tenant.subdomain,
    //     job_title: titleCase(user.position),
    //     plan: tenant.plan?.name,
    //     plan_status: titleCase(tenant.plan_status),
    //   })
    // }

    // also identify third parties
    ThirdParty.identify({ user: user, tenant: tenant })

    this.identified = true

    if (DEBUG) console.debug('Analytics: Identified')
    if (callback) callback()
  }

  track = (name, params, callback) => {
    // posthog.capture(name, {
    //   ...params,
    //   source: 'web',
    // })

    if (DEBUG) console.debug(`Analytics: Tracked - ${name}`)
    if (callback) callback()
  }

  logout = () => {
    // posthog.reset()
  }

  process = () => {
    if (!this.initialized) return

    if (this.requestsQueue.length > 0) {
      let processingRequest = this.requestsQueue[0]

      // delete request from queue
      const idx = this.requestsQueue.indexOf(processingRequest)
      this.requestsQueue.splice(idx, 1)

      // process
      if (processingRequest.type === 'identify') {
        this.identify(processingRequest.params, this.process)
      } else if (processingRequest.type === 'track') {
        this.track(processingRequest.name, processingRequest.params, this.process)
      } else if (processingRequest.type === 'logout') {
        this.logout()
      }
    }
  }

  queue = async (request) => {
    if (!this.isProduction) return
    if (!this.useAnalytics) return
    if (this.isBehave) return

    this.requestsQueue.push(request)
    this.process()
  }
}

export default new Analytics()
