import React from 'react'

import SmartStatus from '../../components/SmartStatus'

import { REFERRAL_STATUSES } from './constants'

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={REFERRAL_STATUSES} {...rest} />
}

export default Status
