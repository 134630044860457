import React from 'react'
import clsx from 'clsx'

type Props = {
  emoji: string
  size: string | number
  aria: any
} & React.HTMLAttributes<HTMLImageElement>

const Emoji: React.FC<Props> = ({ emoji, size, aria = 'tada', className }) => {
  const classNames = clsx({
    emoji: true,
    [className]: className,
  })

  return (
    <span style={{ display: 'inline-block', fontSize: size }} role="img" aria-label={aria} className={classNames}>
      {emoji}
    </span>
  )
}

export default Emoji
