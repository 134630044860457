import React from 'react'
import size from 'lodash/size'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Chotomate from '../../components/Chotomate'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Glyph from '../../components/Glyph'
import Link from '../../components/Link'

import AmountInput from '../../components/Forms/AmountInput'
import Attachments from '../../components/Forms/Attachments'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Textarea from '../../components/Forms/Textarea'
import OverlayLoader from '../../components/OverlayLoader'

import { ICONS } from '../../theme'
import { titleCase } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import { PAYMENT_METHODS_LIST } from '../../utils/constants'
import produce from 'immer'

const RootOrganizationPaymentOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    record,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    anonymize,
    saveWithData,
    data,
  } = useOverlay({
    name: 'financial-transactions',
    endpoint: '/financial_transactions',
    invalidate: 'financial-transactions',
    options: props,
    parentType: 'organization',
    parentRequest: '/organizations',
  })

  const [formData, setFormData] = React.useState(initialModel)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(null)
  const { timezone } = useSettings()

  const save = () => {
    const saveData = produce(formData, (draft) => {
      if (draft.payed_with?.model === 'online') {
        const method = selectedPaymentMethod || initialModel?.payment_method
        const methodReference = method?.reference

        draft.reference_id = methodReference?.id
        draft.reference_type = methodReference?.type

        return
      }

      if (draft.reference_category === 'contact' && draft.contact_reference_id) {
        draft.reference_id = draft.contact_reference_id
        draft.reference_type = draft.contact_reference_type
        return
      }

      if (draft.reference_category === 'organization' && draft.organization_reference_id) {
        draft.reference_id = draft.organization_reference_id
        draft.reference_type = draft.organization_reference_type
        return
      }

      draft.reference_id = null
      draft.reference_type = null
    })

    saveWithData({ ...saveData, payed_with: formData.payed_with?.model })
  }

  if (isOverlayLoading || size(record) === 0) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon={ICONS.financials} title={isNew ? 'Add Payment' : 'Payment'} />

      <Overlay.Content>
        <Chotomate ready name="payment_overlay" />

        {record?.financial_plan ? (
          <Form
            getForm={form}
            timezone={timezone}
            isEditable={isEditable}
            initialModel={produce(initialModel, (draft) => {
              if (draft.reference_category === 'contact' && draft.reference?.type === 'contact') {
                draft.contact_reference = draft.reference
                return
              }

              if (draft.reference_category === 'organization' && draft.reference?.type === 'organization') {
                draft.organization_reference = draft.reference
                return
              }
            })}
            onValidationUpdate={onValidationUpdate}
            key={`updated-${data?.updated_at}`}
            linked={
              isNew && {
                category: 'payment',
                financial_plan_id: record?.financial_plan?.id,
              }
            }
            onUpdate={setFormData}
          >
            <Section>
              <FormSection layout="vertical">
                <Input
                  autoFocus
                  label="Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a charge name',
                    },
                  }}
                />

                <Flex gap="1rem">
                  <AmountInput
                    label="Amount"
                    model="amount"
                    isEditable={isNew || (isEditable && initialModel.source === 'app')}
                    validations={{
                      presence: {
                        message: 'Please enter an amount',
                      },
                      numericality: {
                        greaterThan: 0,
                        message: 'Please enter an amount',
                      },
                    }}
                  />

                  <ObjectSelector
                    isRelation={false}
                    className="!flex-auto"
                    model="payed_with"
                    label="Payed With"
                    apiData={PAYMENT_METHODS_LIST}
                    showAvatars={false}
                    selectTitle={(data: any) => (
                      <>
                        <Glyph glyph={data.glyph} /> {data.name}
                      </>
                    )}
                    selectDescription={(data: any) => data.description}
                    validations={{
                      presence: {
                        message: 'Please enter an amount',
                      },
                    }}
                  />
                </Flex>

                {formData?.payed_with?.model && (
                  <>
                    {formData?.payed_with?.model === 'online' && (
                      <>
                        <ObjectSelector
                          icon="billing"
                          type="organization.payment_methods"
                          label="Payment Method"
                          model="payment_method"
                          dependentValue={record?.id}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => data?.reference?.name}
                          onUpdate={(model: any) => {
                            setSelectedPaymentMethod(model.object)
                          }}
                        />

                        {(selectedPaymentMethod || initialModel?.payment_method) && (
                          <>
                            <Divider className="!m-0" />

                            <RadioGroup
                              isEditable={false}
                              label="Payer Type"
                              model="reference_category"
                              layout="horizontal-dense"
                              value={selectedPaymentMethod?.reference_category || initialModel.payment_method?.reference_category}
                            >
                              <Radio label="Organization" value="organization" />
                              <Radio label="Contact" value="contact" />
                            </RadioGroup>

                            <ObjectSelector
                              isEditable={false}
                              isPolymorphic
                              label="Payer"
                              model="reference"
                              value={selectedPaymentMethod?.reference || initialModel.payment_method?.reference}
                            />

                            <Divider className="!mt-2 !mx-0 !mb-0" />
                          </>
                        )}
                      </>
                    )}

                    {formData?.payed_with?.model !== 'online' && (
                      <>
                        <Divider className="!m-0" />

                        <RadioGroup label="Payer Type" model="reference_category" layout="horizontal-dense" defaultValue="organization">
                          <Radio label="Organization" value="organization" />
                          <Radio label="Contact" value="contact" />
                        </RadioGroup>

                        {!isEditable ? (
                          <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="reference" />
                        ) : (
                          <>
                            <ContextShow when="reference_category" is="organization">
                              <ObjectSelector
                                isEditable={false}
                                isPolymorphic
                                label="Payer"
                                model="organization_reference"
                                value={record}
                              />
                            </ContextShow>

                            <ContextShow when="reference_category" is="contact">
                              <ObjectSelector
                                isPolymorphic
                                icon="contacts"
                                label="Payer"
                                model="contact_reference"
                                type="organization.contacts"
                                description={
                                  <>
                                    Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                                  </>
                                }
                                dependentValue={record?.id}
                                selectTitle={(data: any) => data?.name}
                                selectDescription={(data: any) => titleCase(data?.relationship)}
                              />
                            </ContextShow>
                          </>
                        )}

                        <Divider className="!mt-2 !mx-0 !mb-0" />
                      </>
                    )}
                  </>
                )}

                <DateTimeInput
                  isEditable={isNew || (isEditable && initialModel.source === 'app')}
                  defaultToNow
                  label="Payment Date"
                  model="transactioned_at"
                  validations={{
                    presence: {
                      message: 'Please enter a payment date',
                    },
                  }}
                />

                <Input label="Payment Reference" model="payment_reference" />
                <Textarea useQuickText label="Notes" model="notes" />

                {!isNew && <Input isEditable={false} label="Transaction ID" model="uuid" />}
              </FormSection>
            </Section>

            <Divider />

            <Section headingType="h2" title="Attachments" description="Upload the files related to this payment">
              <FormSection layout="vertical">
                <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
              </FormSection>
            </Section>
          </Form>
        ) : (
          <Alert type="negative" className="!m-4">
            The data required to create <strong>Payments</strong> could not be loaded from our servers. To try again, please close this
            Overlay and refresh. If this problem persists, please get in touch with Support.
          </Alert>
        )}
      </Overlay.Content>

      {!anonymize && (
        <Overlay.Footer>
          {isEditable ? (
            <>
              <Button
                label="Save Payment"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                flex="100 1 auto"
                isDisabled={isInvalid}
                permission="ledger.create"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" onClick={cancel} isDisabled={isSaving} />}
            </>
          ) : (
            <>
              <Button
                glyph="edit"
                label="Edit Payment"
                type="default"
                isDisabled={isSaving}
                onClick={edit}
                flex="100 1 auto"
                permission="ledger.edit"
              />

              <DeleteDialog
                title="Delete Payment?"
                message="Are you sure you want to delete this payment? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  fullWidth
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  permission="ledger.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const OrganizationPaymentOverlay = withOverlayError(RootOrganizationPaymentOverlay)
