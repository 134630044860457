import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { EventDocumentationsReportDataTable } from '@behavehealth/constructs/LiveReports/EventDocumentationsReportDataTable'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Events = () => {
  const tableProps = useDataTable({
    name: ['reports', 'event_documentations'],
    endpoint: `/live_reports?category=event_documentations`,
    localStorageKey: 'report_event_documentations_v1',
    params: { event_type: 'event' },
  })

  const to = React.useMemo(() => (rowData: any) => `/calendar/table-view/${rowData.id}`, [])

  return (
    <Page feature="calendar" title="Live Event Documentations Report">
      <Grid>
        <EventDocumentationsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Events)
