import React from 'react'

import withSettings from '../../../hocs/withSettings'
import { COLORS } from '../../../theme'

import { usDateTime } from '../../../utils/functions'

import Alert from '../../../components/Alert'
import Card from '../../../components/Card'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import Icon from '../../../components/Icon'
import DataList from '../../../components/DataList'
import Avatar from '../../../components/Avatar'
import Glyph from '../../../components/Glyph'
import BedOccupancyStatus from '../../../components/Statuses/BedOccupancyStatus'

import ContextShow from '../../../components/Forms/ContextShow'
import BedSelector from '../../../components/Forms/BedSelector'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Workflow from '../../../components/Workflow/Workflow'

const BedDischarge = (props: any) => {
  const { data, hasCurrentOccupancy, setData, setStatus, timezone } = props

  const form = React.useRef()
  const [valid, setValid] = React.useState()

  const { goNext }: any = React.useContext(Workflow.Context)

  const handleUpdate = (value: any) => {
    setData({
      ...value,
      occupancy: {
        ...value?.occupancy,
        id: data?.occupancy?.id,
      },
    })
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    valid ? setStatus('completed') : setStatus('todo')
  }, [valid])

  return (
    <Form
      getForm={form}
      initialModel={data}
      onUpdate={handleUpdate}
      onValidationUpdate={setValid}
      timezone={timezone}
      defaultDebounce={300}
    >
      <FormSection>
        {hasCurrentOccupancy ? (
          <Card css={{ padding: '0.2rem 0' }}>
            <Flex centerY gap="0.75rem" css={{ padding: '0.2rem 1rem' }}>
              <Icon icon="beds" />
              <div css={{ fontWeight: 700, fontSize: '1.2rem' }}>Current Assignment</div>
            </Flex>

            <DataList isCompact withPadding>
              <DataList.Item label="Status" value={<BedOccupancyStatus status={data.occupancy?.status} />} />

              <DataList.Item
                label="Location"
                value={
                  <Flex centerY gap="0.4rem">
                    <Avatar src={data.occupancy?.place?.house.avatar} initials={data.occupancy?.place?.house?.name} size={24} />
                    <div className="!font-[600]">{data.occupancy?.place?.house?.name}</div>
                  </Flex>
                }
              />

              <DataList.Item
                label="Current Bed"
                value={
                  <Flex centerY gap="0.5rem">
                    <div>
                      <Flex nowrap centerY gap="0.3rem">
                        <Icon icon="floors" size={20} />
                        <div>{data.occupancy?.place?.floor?.name}</div>
                      </Flex>
                    </div>

                    <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                    <div>
                      <Flex nowrap centerY gap="0.3rem">
                        <Icon icon="rooms" size={20} />
                        <div>{data.occupancy?.place?.room?.name}</div>
                      </Flex>
                    </div>

                    <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                    <div>
                      <Flex nowrap centerY gap="0.3rem">
                        <Icon icon="beds" size={20} />
                        <div>{data.occupancy?.place?.name}</div>
                      </Flex>
                    </div>
                  </Flex>
                }
              />

              <DataList.Item
                label={data.occupancy?.status === 'occupied' ? 'Occupied From' : 'Reserved From'}
                value={usDateTime(data.occupancy?.started_at, timezone)}
              />
              <DataList.Item
                label={data.occupancy?.status === 'occupied' ? 'Occupied Until' : 'Reserved Until'}
                value={data.occupancy?.period_type === 'indefinite' ? 'Discharged' : usDateTime(data.occupancy?.ended_at, timezone)}
              />
            </DataList>

            <Grid gap="0.75rem" css={{ padding: '1rem 1rem 0.5rem' }}>
              <RadioGroup
                label="When would you like to discharge the Bed?"
                model="should_end_current_bed_occupancy"
                layout="horizontal-dense"
                defaultValue="on_discharge_date"
              >
                <Radio label="On Discharge Date" value="on_discharge_date" />
                <Radio label="Another Time" value="another_time" />
              </RadioGroup>

              <ContextShow when="should_end_current_bed_occupancy" is="on_discharge_date">
                <DataList>
                  <DataList.Item label="Discharged On" value={usDateTime(data?.discharged_at, timezone)} />
                </DataList>
              </ContextShow>

              <ContextShow when="should_end_current_bed_occupancy" is="another_time">
                <DateTimeInput
                  defaultToNow
                  label="Bed Discharge Date"
                  model="current_bed_occupancy_ended_at"
                  validations={{
                    presence: {
                      message: 'Please enter an end date',
                    },
                  }}
                />
              </ContextShow>
            </Grid>
          </Card>
        ) : (
          <Alert type="warning">This Client has no Bed Assignments</Alert>
        )}

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={goNext} isDisabled={!valid} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default withSettings(BedDischarge)
