import React from 'react'
import { Input } from './Input'
import { withFormContext } from './context'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { isBlank } from '../../utils/functions'

import LinkValue from '../LinkValue'

class WhatsAppInput extends Input {
  readOnlyRender = () => {
    if (isBlank(this.state.value)) return DEFAULT_EMPTY_VALUE

    return <LinkValue href={`https://wa.me/+1${this.state.value}`} value={this.state.value} glyph={this.props.glyph} />
  }
}

WhatsAppInput.defaultProps = {
  type: 'tel',
  glyph: 'whatsapp',
  // defaultValidations: {
  //   format: {
  //     // eslint-disable-next-line no-useless-escape
  //     pattern: '^([s]+)?$|^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$',
  //     message: 'Please use a valid phone number format (e.g. 123-456-7890)',
  //   },
  // },
}

export default withFormContext(WhatsAppInput)
