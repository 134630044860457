import React from 'react'
import { tint } from 'polished'
import { v4 as uuid } from 'uuid'
import set from 'lodash/set'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { isDefined } from '../../../utils/functions'
import { withFormContext } from '../../../components/Forms/context'

import AmountInput from '../../../components/Forms/AmountInput'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Flex from '../../../components/Flex'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Grid from '../../../components/Grid'
import GridTable from '../../../components/GridTable'
import Input from '../../../components/Forms/Input'
import RadioGroup from '../../../components/Forms/RadioGroup'
import PhoneInput from '../../../components/Forms/PhoneInput'
import PercentageInput from '../../../components/Forms/PercentageInput'
import Textarea from '../../../components/Forms/Textarea'
import Radio from '../../../components/Forms/Radio'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import Section from '../../../components/Section'
import Tabs from '../../../components/Tabs'
import Status from '../../../components/Status'

import { useFormField } from '../../../components/Forms/hooks/useFormField'

const AmountCalculator = ({ value, label, onUpdate }: any) => {
  const [data, setData] = React.useState(value || { total: 0, met: 0, remaining: 0 })

  React.useEffect(() => {
    if (!value) return

    const newData = produce(data, (draft: any) => {
      if ((value.total || value.total === 0) && draft.total !== value.total) {
        draft.total = value.total
      }
      if ((value.met || value.met === 0) && draft.met !== value.met) {
        draft.met = value.met
      }
      if ((value.remaining || value.remaining === 0) && draft.remaining !== value.remaining) {
        draft.remaining = value.remaining
      }
    })

    setData(newData)
  }, [value])

  // update form if value changes
  React.useEffect(() => {
    if (onUpdate) onUpdate(data)
  }, [data])

  return (
    <>
      <AmountInput
        label={label}
        value={data?.total || 0}
        onUpdate={({ value }: any) => {
          if (data.total === value) return
          setData({ total: value, met: data.met, remaining: value - data?.met })
        }}
      />
      <AmountInput
        label="Met So Far"
        value={data?.met || 0}
        onUpdate={({ value }: any) => {
          if (data.met === value) return
          setData({ total: data.total, met: value, remaining: data?.total - value })
        }}
      />
      <AmountInput
        label="Remaining"
        value={data?.remaining || 0}
        onUpdate={({ value }: any) => {
          if (data.remaining === value) return
          setData({ total: data.total, remaining: data.remaining, met: data.total - value })
        }}
      />
    </>
  )
}

const VOBServices = ({ model, form, isEditable, value, scrollview }: any) => {
  const [data, setData]: any = React.useState(value)
  const [copy, setCopy]: any = React.useState(null)

  const isEmpty = size(data) === 0

  const { formActions } = useFormField({
    model: model,
    form: form,
  })

  // UPDATE FORM
  React.useEffect(() => {
    if (!form) return

    formActions.setValue(data)
  }, [form, model, data])

  // FUNCTIONS
  const addRow = () => {
    const newID = uuid()

    setData((o: any) => ({
      ...o,
      [newID]: {
        _id: newID,
        insurance_new_code: null,
        price: 0,
        is_covered: false,
        requires_precert: false,
      },
    }))
  }

  const removeRow = (id: string) => {
    const newData = produce(data, (draft: any) => {
      delete draft[id]
    })

    if (isDefined(copy) && copy._id === id) setCopy(null)
    setData(newData)
  }

  return (
    <Section
      title="Services"
      scrollview={scrollview}
      aside={isEditable && <Button label="Add Service" glyph="add" type="minimal" display="inline-flex" size={200} onClick={addRow} />}
    >
      <Form isEditable={isEditable}>
        <div className="!mt-3">
          {isEmpty && (
            <Card css={styles.emptyState}>
              <div>No service lines added yet</div>
            </Card>
          )}

          {!isEmpty && (
            <Card>
              <GridTable templateColumns="40px repeat(2, minmax(200px, 1fr)) 170px 170px 60px 50px">
                <GridTable.Header css={{ borderWidth: 1 }}>
                  <GridTable.Cell></GridTable.Cell>
                  <GridTable.Cell>Insurance Code</GridTable.Cell>
                  <GridTable.Cell>Price</GridTable.Cell>
                  <GridTable.Cell>Covered</GridTable.Cell>
                  <GridTable.Cell>Pre-Cert</GridTable.Cell>
                  <GridTable.Cell></GridTable.Cell>
                  <GridTable.Cell></GridTable.Cell>
                </GridTable.Header>

                {Object.keys(data)?.map((id: any) => {
                  const item = data[id]
                  if (!item) return null

                  return (
                    <ServiceRow
                      key={id}
                      row={item}
                      isEditable={isEditable}
                      copy={copy}
                      updateCopy={setCopy}
                      removeRow={removeRow}
                      onCancel={() => setCopy(null)}
                      onUpdate={(newItem: any) => {
                        const newData = produce(data, (draft: any) => {
                          draft[item._id] = newItem
                        })

                        setData(newData)
                      }}
                    />
                  )
                })}
              </GridTable>
            </Card>
          )}
        </div>
      </Form>
    </Section>
  )
}

const ServiceRow: React.FC<any> = ({ row, copy, updateCopy, onUpdate, removeRow, isEditable }) => {
  const [item, setItem] = React.useState(row)
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleOpen = () => {
    setIsOpen((c) => !c)
  }

  const onPaste = () => {
    setItem((prevItem: any) => ({
      ...prevItem,
      is_covered: copy.is_covered,
      requires_precert: copy.requires_precert,
      cost_sharing: copy.cost_sharing,
      pre_cert: copy.pre_cert,
      insurance_visits: copy.insurance_visits,
      max_reimbursements: copy.max_reimbursements,
    }))
  }

  const onCopy = () => {
    updateCopy({
      _id: item._id,
      is_covered: item.is_covered,
      requires_precert: item.requires_precert,
      cost_sharing: item.cost_sharing,
      pre_cert: item.pre_cert,
      insurance_visits: item.insurance_visits,
      max_reimbursements: item.max_reimbursements,
    })
  }

  const onDelete = () => {
    removeRow(item._id)
  }

  const onCancel = () => {
    updateCopy(null)
  }

  React.useEffect(() => {
    if (item && onUpdate) onUpdate(item)
  }, [item])

  if (!item) return null

  const canCopy = !isDefined(copy)
  const canCancel = isDefined(copy) && copy._id === item._id
  const canPaste = isDefined(copy) && copy._id !== item._id

  return (
    <>
      <GridTable.Row css={styles.mainRow}>
        <GridTable.Cell centerX centerY>
          <Button
            hideLabel
            glyph="triangle_down"
            glyphSize={14}
            type="minimal"
            color="blue"
            onClick={toggleOpen}
            className={isOpen ? 'is-open' : ''}
            css={styles.toggleButton}
            size={200}
          />
        </GridTable.Cell>

        <GridTable.Cell>
          <ObjectSelector
            label=""
            icon="insurance"
            type="insurance_new_codes"
            value={item.insurance_new_code}
            selectTitle={(data: any) => `${data.service_name}`}
            selectDescription={(data: any) => (
              <Grid gap="0.2rem">
                <span>{data?.procedure_code}</span>
                <Status label={`Rev: ${data.revenue_code || '–'}`} />
              </Grid>
            )}
            onUpdate={(state: any) => {
              if (!state.object) return
              setItem({ ...item, insurance_new_code: state.object, insurance_new_code_id: state.object.id })
            }}
          />
        </GridTable.Cell>

        <GridTable.Cell>
          <AmountInput
            value={item.price}
            onUpdate={(state: any) => {
              if (isDefined(state.value) && state.value !== '') {
                setItem((prevItem: any) => ({ ...prevItem, price: state.value }))
              }
            }}
          />
        </GridTable.Cell>

        <GridTable.Cell>
          <RadioGroup
            layout="horizontal-dense"
            value={item.is_covered}
            onUpdate={(state: any) => {
              if (!isDefined(state.value)) return
              setItem({ ...item, is_covered: state.value })
            }}
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>
        </GridTable.Cell>

        <GridTable.Cell>
          <RadioGroup
            layout="horizontal-dense"
            value={item.requires_precert}
            onUpdate={(state: any) => {
              if (!isDefined(state.value)) return
              setItem({ ...item, requires_precert: state.value })
            }}
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>
        </GridTable.Cell>

        {isEditable && (
          <>
            <GridTable.Cell centerX centerY>
              {canCopy && <Button hideLabel glyph="copy" glyphSize={20} type="minimal" color="green" onClick={onCopy} size={200} />}
              {canPaste && <Button hideLabel glyph="paste" glyphSize={20} type="minimal" color="blue" onClick={onPaste} size={200} />}
              {canCancel && <Button hideLabel glyph="cross" glyphSize={16} type="minimal" color="red" onClick={onCancel} size={200} />}
            </GridTable.Cell>

            <GridTable.Cell centerX centerY>
              <Button hideLabel glyph="delete" type="minimal" color="red" onClick={onDelete} size={200} />
            </GridTable.Cell>
          </>
        )}
      </GridTable.Row>

      <div className={isOpen ? '' : 'is-hidden'} css={styles.subRow}>
        <div css={{ gridColumn: '2 / -2' }}>
          {item.is_covered && (
            <Tabs defaultTab="cost_sharing">
              <Tabs.List css={styles.tabsList}>
                <Tabs.Item label="Cost Sharing" icon="financials" name="cost_sharing" />
                {item.requires_precert && <Tabs.Item label="Pre-Cert" icon="checklist" name="pre_cert" />}
                <Tabs.Item label="Insurance Visits" icon="financials" name="insurance_visits" />
                <Tabs.Item label="Max Reimbursements" icon="financials" name="max_reimbursements" />
              </Tabs.List>

              <Tabs.Panels>
                <Tabs.Panel name="cost_sharing">
                  <Grid gap="0.5rem">
                    <Flex gap="1rem">
                      <FormSection maxWidth="100%" css={{ borderRight: `1px solid ${COLORS.divider}`, paddingRight: '1rem' }}>
                        <Grid gap={12} columns="1fr 1fr 1fr">
                          <AmountCalculator
                            label="Individual Deductible"
                            value={item?.cost_sharing?.individual_deductible}
                            onUpdate={(value: any) => {
                              const newItem = produce(item, (draft: any) => {
                                set(draft, 'cost_sharing.individual_deductible', value)
                              })

                              setItem(newItem)
                            }}
                          />
                          <AmountCalculator
                            label="Family Deductible"
                            value={item?.cost_sharing?.family_deductible}
                            onUpdate={(value: any) => {
                              const newItem = produce(item, (draft: any) => {
                                set(draft, 'cost_sharing.family_deductible', value)
                              })

                              setItem(newItem)
                            }}
                          />
                          <AmountCalculator
                            label="Individual Out-of-Pocket"
                            value={item?.cost_sharing?.individual_out_of_pocket}
                            onUpdate={(value: any) => {
                              const newItem = produce(item, (draft: any) => {
                                set(draft, 'cost_sharing.individual_out_of_pocket', value)
                              })

                              setItem(newItem)
                            }}
                          />
                          <AmountCalculator
                            label="Family Out-of-Pocket"
                            value={item?.cost_sharing?.family_out_of_pocket}
                            onUpdate={(value: any) => {
                              const newItem = produce(item, (draft: any) => {
                                set(draft, 'cost_sharing.family_out_of_pocket', value)
                              })

                              setItem(newItem)
                            }}
                          />
                        </Grid>
                      </FormSection>

                      <Grid fixChildrenY gap="1rem">
                        <RadioGroup
                          label="Does the Deductible apply to the Out of Pocket maximum?"
                          layout="horizontal-dense"
                          value={item.cost_sharing?.deductible_applies_to_oop_max}
                          onUpdate={(state: any) => {
                            const newItem = produce(item, (draft: any) => {
                              set(draft, 'cost_sharing.deductible_applies_to_oop_max', state.value)
                            })

                            setItem(newItem)
                          }}
                        >
                          <Radio label="Yes" value={true} />
                          <Radio label="No" value={false} />
                        </RadioGroup>

                        <PercentageInput
                          label="Co-Insurance"
                          value={item?.cost_sharing?.co_insurance_percentage}
                          onUpdate={(state: any) => {
                            const newItem = produce(item, (draft: any) => {
                              set(draft, 'cost_sharing.co_insurance_percentage', state.value)
                            })

                            setItem(newItem)
                          }}
                        />

                        <AmountInput
                          label="Co-Pay Amount"
                          value={item?.cost_sharing?.copay_amount}
                          onUpdate={(state: any) => {
                            const newItem = produce(item, (draft: any) => {
                              set(draft, 'cost_sharing.copay_amount', state.value)
                            })

                            setItem(newItem)
                          }}
                        />
                      </Grid>
                    </Flex>
                  </Grid>
                </Tabs.Panel>

                <Tabs.Panel name="pre_cert">
                  <Grid gap="0.5rem">
                    <FormSection horizontal maxWidth="100%">
                      <Input
                        label="Pre-Certification Company Name"
                        value={item?.pre_cert?.company_name}
                        onUpdate={(state: any) => {
                          const newItem = produce(item, (draft: any) => {
                            set(draft, 'pre_cert.company_name', state.value)
                          })

                          setItem(newItem)
                        }}
                      />
                      <PhoneInput
                        label="Pre-Cert Company Phone Number"
                        value={item?.pre_cert?.phone_no}
                        onUpdate={(state: any) => {
                          const newItem = produce(item, (draft: any) => {
                            set(draft, 'pre_cert.phone_no', state.value)
                          })

                          setItem(newItem)
                        }}
                      />
                    </FormSection>

                    <FormSection>
                      <RadioGroup
                        label="Is there a Pre-Cert Penalty?"
                        layout="horizontal-dense"
                        value={item.pre_cert?.has_penalty}
                        onUpdate={(state: any) => {
                          const newItem = produce(item, (draft: any) => {
                            set(draft, 'pre_cert.has_penalty', state.value)
                          })

                          setItem(newItem)
                        }}
                      >
                        <Radio label="Yes" value={true} />
                        <Radio label="No" value={false} />
                      </RadioGroup>

                      {item.pre_cert?.has_penalty && (
                        <Textarea
                          label="Pre-Cert Penalty Terms"
                          value={item?.pre_cert?.penalty_terms}
                          onUpdate={(state: any) => {
                            const newItem = produce(item, (draft: any) => {
                              set(draft, 'pre_cert.penalty_terms', state.value)
                            })

                            setItem(newItem)
                          }}
                        />
                      )}
                    </FormSection>
                  </Grid>
                </Tabs.Panel>

                <Tabs.Panel name="insurance_visits">
                  <FormSection horizontal maxWidth="100%">
                    <Input
                      size={6}
                      suffix="days"
                      label="Days / Year"
                      value={item?.insurance_visits?.days_per_year}
                      onUpdate={(state: any) => {
                        const newItem = produce(item, (draft: any) => {
                          set(draft, 'insurance_visits.days_per_year', state.value)
                        })

                        setItem(newItem)
                      }}
                    />
                    <Input
                      size={6}
                      suffix="days"
                      label="Days Used"
                      value={item?.insurance_visits?.days_used}
                      onUpdate={(state: any) => {
                        const newItem = produce(item, (draft: any) => {
                          set(draft, 'insurance_visits.days_used', state.value)
                        })

                        setItem(newItem)
                      }}
                    />
                    <Input
                      size={6}
                      suffix="days"
                      label="Lifetime Max"
                      value={item?.insurance_visits?.lifetime_max}
                      onUpdate={(state: any) => {
                        const newItem = produce(item, (draft: any) => {
                          set(draft, 'insurance_visits.lifetime_max', state.value)
                        })

                        setItem(newItem)
                      }}
                    />
                    <Input
                      size={6}
                      suffix="days"
                      label="Lifetime Limits Used"
                      value={item?.insurance_visits?.lifetime_limits_used}
                      onUpdate={(state: any) => {
                        const newItem = produce(item, (draft: any) => {
                          set(draft, 'insurance_visits.lifetime_limits_used', state.value)
                        })

                        setItem(newItem)
                      }}
                    />
                  </FormSection>
                </Tabs.Panel>

                <Tabs.Panel name="max_reimbursements">
                  <FormSection horizontal maxWidth="100%">
                    <AmountInput
                      size={7}
                      label="Max Amount / Day"
                      value={item?.max_reimbursements?.max_amount_per_day}
                      onUpdate={(state: any) => {
                        const newItem = produce(item, (draft: any) => {
                          set(draft, 'max_reimbursements.max_amount_per_day', state.value)
                        })

                        setItem(newItem)
                      }}
                    />
                    <AmountInput
                      size={7}
                      label="Annual Max"
                      value={item?.max_reimbursements?.annual_max}
                      onUpdate={(state: any) => {
                        const newItem = produce(item, (draft: any) => {
                          set(draft, 'max_reimbursements.annual_max', state.value)
                        })

                        setItem(newItem)
                      }}
                    />
                    <AmountInput
                      size={7}
                      label="Annual Used"
                      value={item?.max_reimbursements?.annual_used}
                      onUpdate={(state: any) => {
                        const newItem = produce(item, (draft: any) => {
                          set(draft, 'max_reimbursements.annual_used', state.value)
                        })

                        setItem(newItem)
                      }}
                    />
                    <AmountInput
                      size={7}
                      label="Lifetime Max"
                      value={item?.max_reimbursements?.lifetime_max}
                      onUpdate={(state: any) => {
                        const newItem = produce(item, (draft: any) => {
                          set(draft, 'max_reimbursements.lifetime_max', state.value)
                        })

                        setItem(newItem)
                      }}
                    />
                    <AmountInput
                      size={7}
                      label="Lifetime Used"
                      value={item?.max_reimbursements?.lifetime_used}
                      onUpdate={(state: any) => {
                        const newItem = produce(item, (draft: any) => {
                          set(draft, 'max_reimbursements.lifetime_used', state.value)
                        })

                        setItem(newItem)
                      }}
                    />
                  </FormSection>
                </Tabs.Panel>
              </Tabs.Panels>
            </Tabs>
          )}
        </div>
      </div>
    </>
  )
}

const styles = {
  mainRow: {
    '& > *, &:hover > *': {
      background: `${COLORS.white} !important`,
    },
  },

  subRow: {
    display: 'grid',
    gridTemplateColumns: 'var(--template-columns)',
    background: `${tint(0.25, COLORS.lightBackground)}`,
    borderBottom: `1px solid ${COLORS.divider}`,
    paddingBottom: '1rem',
  },

  toggleButton: {
    svg: {
      transform: 'rotate(-90deg) !important',
    },

    '&.is-open svg': {
      transform: 'rotate(0) !important',
    },
  },

  tabsList: {
    marginBottom: '1rem',
  },

  emptyState: {
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
    padding: '1rem',
    color: COLORS.textMuted,
    opacity: 0.8,
  },
}

export default withFormContext(VOBServices)
