import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { useGet } from '../hooks/useNewAPI'

import Button from './Button'
import Card from './Card'
import Grid from './Grid'
import Header from './Header'
import HelpTagIframe from './Help/HelpTagIframe'
import Switch from './Forms/Switch'
import Tab from './Tab'
import TabList from './TabList'
import Tabs from './Tabs'

import MessagesTable from './Tables/MessagesTable'
import Permission from './Permission'

function filterMessagesByExpired(messages = []) {
  const expiredMessages = []
  const activeMessages = []
  const messagesKeys = Object.keys(messages)

  let message
  for (const messageId of messagesKeys) {
    message = messages[messageId]
    message.is_expired ? expiredMessages.push(message) : activeMessages.push(message)
  }

  return { expiredMessages, activeMessages }
}

const MessageBoard = (props) => {
  const {
    enableExpiredMessages = true,
    isSelectable = true,
    onlyPortal,
    name = 'messages',
    url = '/messages',
    showTableSettings = false,
    showTabs,
  } = props

  const match = useRouteMatch()

  const [messageType, setMessageType] = React.useState(onlyPortal ? 'portal' : 'ehr')
  const [showExpiredMessages, setShowExpiredMessages] = React.useState(false)

  const filters = { message_type: messageType }

  const { data: messages, isLoading }: any = useGet({
    name: [name, filters].flat(),
    url: url,
    params: filters,
  })

  const { activeMessages, expiredMessages } = filterMessagesByExpired(messages)
  const hasExpiredMessages = size(expiredMessages) > 0

  const isEmpty = size(messages) === 0

  return (
    <Permission permission="messages.view">
      <Grid>
        <Header
          icon="care_level_4"
          title="Message Board"
          className="!px-0 !py-3"
          help={
            <>
              {messageType === 'ehr' && <HelpTagIframe id="message_board_internal" />}
              {messageType === 'portal' && <HelpTagIframe id="message_board_client_portal" />}
            </>
          }
          aside={
            <>
              {enableExpiredMessages && hasExpiredMessages && (
                <Switch
                  onUpdate={({ value }) => setShowExpiredMessages(value)}
                  label="Show Expired Messages"
                  horizontal
                  labelWidth="auto"
                />
              )}

              {!onlyPortal && (
                <Button
                  label="Add Message"
                  type="primary"
                  glyph="add"
                  link={{
                    pathname: `${match.url}/messages/new`,
                    parent: match,
                    data: {
                      message_type: messageType,
                    },
                  }}
                  permission="messages.create"
                />
              )}
            </>
          }
        />

        <Card testKey="message_board">
          {!onlyPortal && showTabs && (
            <Tabs onChange={setMessageType} defaultTab="ehr">
              <TabList className="!pl-4">
                <Tab label="Internal" name="ehr" />
                <Tab label="Client Portal" name="portal" />
              </TabList>
            </Tabs>
          )}

          <MessagesTable
            to={props.to}
            {...(isSelectable && {
              to: (id: string) => ({
                pathname: `${match.url}/messages/${id}`,
                parent: match,
              }),
            })}
            data={hasExpiredMessages && showExpiredMessages ? messages : activeMessages}
            isLoading={isEmpty && isLoading}
            showSettings={showTableSettings}
          />
        </Card>
      </Grid>
    </Permission>
  )
}

export default MessageBoard
