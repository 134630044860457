import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import capitalize from 'lodash/capitalize'
import size from 'lodash/size'

import { countWord } from '@behavehealth/utils/functions'
import { css, COLORS } from '@behavehealth/theme'
import { RoomTable } from '@behavehealth/components/Tables'
import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'

import Avatar from '@behavehealth/components/Avatar'
import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import CardContent from '@behavehealth/components/CardContent'
import CardHeader from '@behavehealth/components/CardHeader'
import CardLink from '@behavehealth/components/CardLink'
import CardTitle from '@behavehealth/components/CardTitle'
import DeleteDialog from '@behavehealth/components/Dialogs/DeleteDialog'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Flex from '@behavehealth/components/Flex'
import Grid from '@behavehealth/components/Grid'
import Icon from '@behavehealth/components/Icon'
import Link from '@behavehealth/components/Link'
import ProgressBar from '@behavehealth/components/ProgressBar'
import State from '@behavehealth/components/State'
import Status from '@behavehealth/components/Status'
import Wrapper from '@behavehealth/components/Wrapper'
import Switch from '@behavehealth/components/Forms/Switch'

const RoomManagement = () => {
  const match = useRouteMatch()
  const { url, params }: any = match

  const propertyID = params?.property_id
  const [showAllAssignments, setShowAllAssignments] = React.useState(false)

  const { data: property, isLoading: isPropertyLoading }: any = useGet({
    name: ['location', propertyID],
    url: `/houses/${propertyID}`,
    options: { enabled: !!propertyID },
  })

  const { data: rooms, isLoading: isRoomsLoading }: any = useGet({
    name: ['locations', propertyID, 'rooms-with-residents'],
    url: `/houses/${propertyID}/rooms_with_residents`,
    options: { enabled: !!propertyID },
  })

  const isRoomsEmpty = size(rooms) === 0

  if (isRoomsLoading || isPropertyLoading || isRoomsEmpty) {
    return (
      <State
        isEmpty={isRoomsEmpty}
        isLoading={isRoomsLoading || isPropertyLoading}
        icon="rooms"
        title="Room Management"
        emptyDescription="Create a room for this property to manage beds and clients"
        emptyActions={
          property && <Button label="Manage Floor Plan →" icon="floor_plan" size={300} link={`/locations/${property?.id}/floor-plan`} />
        }
      />
    )
  }

  if (!property) return null

  const occupancyPercentage = (property.current_residents_count / property.beds_count) * 100

  return (
    <Wrapper>
      <Grid gap="1rem">
        <Card className="py-2 px-4">
          <Flex justifyContent="flex-end" alignItems="center" className="!w-full">
            <Flex horizontal gap="0.5rem" alignItems="center">
              <span css={!showAllAssignments ? STYLES.label.active : STYLES.label.inactive}>Show Current Assignments</span>
              <Switch label="" size={200} value={showAllAssignments} onUpdate={(state: any) => setShowAllAssignments(state.value)} />
              <span css={showAllAssignments ? STYLES.label.active : STYLES.label.inactive}>Show Today's Changes</span>
            </Flex>
          </Flex>
        </Card>

        <CardLink
          baseline="3.8rem"
          src={property.avatar}
          graphic={<Avatar src={property.avatar} initials={property.name} size={40} />}
          to={`/locations/${propertyID}`}
          actions={<DropdownItem label="View Location" glyph="property_details" link={`/locations/${propertyID}`} />}
        >
          <CardTitle title={property.name} />
          <Flex centerY gap="0.5rem">
            <Status color="blue" label={`${capitalize(property.gender)} Location`} />

            <div className={STYLES.description()}>
              {property.beds_count - property.current_residents_count} Available / {countWord('Beds', property.beds_count)}
            </div>

            <ProgressBar showPercentage width={50} color="green" percent={occupancyPercentage || 0} labelAfter="Occupancy" />
          </Flex>
        </CardLink>

        {rooms.map((room: any) => (
          <Room key={room.id} room={room} showAllAssignments={showAllAssignments} />
        ))}
      </Grid>
    </Wrapper>
  )
}

const STATUSES = {
  lead: 'admissions',
  intake: 'admissions',
  declined: 'admissions',
  current: 'clients',
  alumni: 'alumni',
}

const Room = ({ room, showAllAssignments }: any) => {
  const { isBehave } = useSettings()

  const match = useRouteMatch()
  const { url, params }: any = match

  const propertyID = params?.property_id

  const { mutateAsync: deleteRoom, isLoading: isDeleting } = useDelete({
    name: ['room', room.id],
    url: `/rooms/${room.id}`,
    invalidate: ['locations', propertyID, 'rooms-with-residents'],
    invalidateKeys: ['rooms', 'bed-management-timeline', 'bed-management-board'],
  })

  if (!room) return null

  let occupancies = []
  if (showAllAssignments) {
    occupancies = room.todays_bed_occupancies
  } else {
    occupancies = room.todays_bed_occupancies?.filter((occupancy: any) => !occupancy.in_the_past)
  }

  const occupancyPercentage = (room.todays_bed_occupancies_count / room.beds_count) * 100

  return (
    <Card baseline="3rem">
      <CardHeader
        graphic={<Icon icon="rooms" size={20} />}
        after={
          <Flex gap={8}>
            <Button
              testKey="edit_room_button"
              label="Edit Room"
              glyph="edit"
              size={200}
              isDisabled={isDeleting}
              link={{
                pathname: `${url}/rooms/${room.id}`,
                parent: match,
              }}
              permission="bed_management.edit"
            />
            <DeleteDialog
              isDisabled={isBehave ? false : !room.is_deletable}
              title="Delete Room?"
              message="Are you sure you want to delete this room? This action cannot be undone."
              onYes={deleteRoom}
            >
              <Button
                testKey="delete_room_button"
                label="Delete Room"
                glyph="delete"
                color="red"
                size={200}
                isLoading={isDeleting}
                isDisabled={isBehave ? false : !room.is_deletable}
                permission="bed_management.delete"
              />
            </DeleteDialog>
          </Flex>
        }
      >
        <Flex centerY gap="0.5rem">
          <CardTitle
            title={
              <>
                <span>{room.name}</span>

                <span className={STYLES.description()}>{room?.place?.name || '–'}</span>
                <span className={STYLES.description()}>
                  {room.beds_count - room.todays_bed_occupancies_count} Available / {countWord('Beds', room.beds_count)}
                </span>
              </>
            }
          />

          <ProgressBar showPercentage width={50} color="green" percent={occupancyPercentage || 0} labelAfter="Occupancy" />
        </Flex>
      </CardHeader>

      <CardContent className="!p-0">
        <RoomTable
          data={occupancies}
          localStorageKey="bed_management_room"
          to={(row: any) => `/${STATUSES[row.reference?.status]}/${row.reference?.id}`}
          moveBedsTo={(id) => ({
            pathname: `${url}/move-beds/${id}`,
            parent: match,
          })}
          emptyTitle={`No clients in "${room.name}"`}
          emptyDescription={
            <>
              Admit clients from <Link to={`/admissions/intake`}>Admissions</Link> to view them here.
            </>
          }
        />
      </CardContent>
    </Card>
  )
}

const STYLES = {
  description: css({
    display: 'inline-block',
    marginLeft: '0.75rem',
    fontSize: '0.92rem',
    fontWeight: 400,
    color: COLORS.textMuted,
  }),
  label: {
    active: {
      fontWeight: 600,
      color: COLORS.text,
    },
    inactive: {
      fontWeight: 600,
      color: COLORS.textStronglyMuted,
    },
  },
}

export default RoomManagement
