import React from 'react'
import { Link, NavLink, useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Chotomate, Card, Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { ShiftNotesTable } from '@behavehealth/components/Tables'

import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

const pageConfig = {
  feature: 'shift_notes',
  help: <HelpTagIframe id="shift_notes" />,
  marketingID: 'shift_notes',
}

const ShiftNotes: React.FC = () => {
  const { resource_id }: any = useParams()

  const [filters, setFilters] = React.useState({})

  const { data, isLoading }: any = useGet({
    name: ['location', resource_id, 'shift-notes', { filters }],
    url: `/houses/${resource_id}/housing_notes`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const { mutate: deleteShiftNotes } = useDelete({
    name: ['location', resource_id, 'shift-notes'],
    url: '/housing_notes',
    invalidate: 'shift-notes',
  })

  const isEmpty = size(data) === 0

  const actions = (
    <>
      <Dropdown label="Add Shift Notes…" glyph="add" buttonType="primary" permission="shift_notes.create">
        <DropdownItem as={Link} label="Add Shift Note" icon="housing_notes" color="blue" link={`new`} />
        <DropdownItem as={Link} label="Add Many Shift Notes…" glyph="stack" color="blue" link={`new-batch`} featureFlagV2="bulk_add" />
      </Dropdown>
    </>
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Grid gap="1rem" columns="100%">
        <Chotomate name="shift_notes" ready={!isLoading} />

        <Filters config={FILTERS.shift_notes} onUpdate={setFilters} localStorageKey="location_shift_notes" />

        <Card>
          <ShiftNotesTable
            mainLinkAs={NavLink}
            data={data}
            isLoading={isEmpty && isLoading}
            emptyActions={actions}
            localStorageKey="properties_shift_notes"
            to={(id) => id}
            titleAfter={<Link to="/reports/shift-notes">View Full Report →</Link>}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'shift_notes.delete',
                action: async ({ ids }: any) => {
                  await deleteShiftNotes(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ShiftNotes, pageConfig))
