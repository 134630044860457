import React from 'react'

import { initializeStore } from './store'

export const DataTableContext: any = React.createContext(null)

export const DataTableProvider = ({ children, ...props }: any) => {
  const storeRef = React.useRef<any>()

  if (!storeRef.current) {
    storeRef.current = initializeStore(props)
  }

  return <DataTableContext.Provider value={storeRef.current}>{children}</DataTableContext.Provider>
}
