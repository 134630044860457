import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { InsuranceReportStatus } from '@behavehealth/constructs/RCM/components/InsuranceReportStatus'

const REPORT_CATEGORIES = {
  batch_level_rejections: 'Batch Level Rejection',
  claim_level_rejections: 'Claim Level Rejection',
  claim_notifications: 'Claim Notification',
  claim_status: 'Claim Status',
  claim_validation_rejections: 'Claim Validation Rejection',
  human_readable_notifications: 'Notification',
  recreated_claims: 'Recreated Claim',
  remittance_advice: 'Remittance Advice',
  remittance_error: 'Remittance Error',
  remittance_reconciliation: 'Remittance Reconciliation',
  cancelled: 'Cancelled',
  error_correction: 'Error Correction',
  final_report: 'Final Report',
  reissue_resubmit: 'Re-Issue / Re-Submit',
  self_explanatory: 'Self Explanatory',
  statistical_report: 'Statistical Report',
  unable_to_assign: 'Unable to Assign',
  unique_event: 'Unique Event',
  test_sample: 'Test Sample',
}

export const InsuranceReportsArchived = ({ onItemSelect, onStatusOpen, onNotesOpen }: any) => {
  const match = useRouteMatch()

  const columns = React.useMemo(
    () => [
      {
        title: 'Report',
        model: 'identifier',
        width: 200,
        disableHide: true,
        disableSelect: true,
        formatValue: ({ data, value }: any) => <MainCell testKey="main_cell" id={data?.id} value={value} to={`${match.url}/${data?.id}`} />,
      },
      {
        title: 'Name',
        model: 'name',
      },
      {
        title: 'Clearing House ID',
        model: 'internal_name',
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <InsuranceReportStatus status={value} />,
      },
      {
        title: 'Type',
        model: 'category',
        formatValue: ({ value }) => REPORT_CATEGORIES[value] || '–',
      },
      {
        title: 'Notes',
        model: 'notes',
        type: 'rich_text',
      },
    ],
    [match.url],
  )

  const tableProps = useDataTable({
    name: ['insurance_clearing_house_reports', 'archived'],
    endpoint: '/insurance_clearing_house_reports',
    params: { status: 'archived' },
    localStorageKey: 'insurance_clearing_house_reports_archived_v1',
  })

  return (
    <DataTable
      {...tableProps}
      asCard
      canBatchSelect
      title="Reports"
      icon="accounting"
      columns={columns}
      batchActionsConfig={[
        {
          label: () => 'Update Status',
          action: async ({ selectedRows }: any) => {
            onItemSelect(selectedRows)
            onStatusOpen(true)
          },
          glyph: 'edit',
          buttonType: 'default',
          // keepSelected: true,
        },
        {
          label: () => 'Add Notes',
          action: async ({ selectedRows }: any) => {
            onItemSelect(selectedRows)
            onNotesOpen(true)
          },
          glyph: 'note',
          buttonType: 'default',
          // keepSelected: true,
        },
      ]}
    />
  )
}
