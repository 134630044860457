import React from 'react'
import size from 'lodash/size'

import { DataTable } from '../../components/DataTable/DataTable'
import SmartStatus from '@behavehealth/components/SmartStatus'

const UPDATE_ACTIONS = {
  referral_create: {
    label: 'Referral Created',
    color: 'blue',
  },
  referral_update: {
    label: 'Referral Updated',
    color: 'yellow',
  },
  sent_to_accepted_data: {
    label: 'Availability Confirmed',
    color: 'green',
  },
  sent_to_declined_data: {
    label: 'Referral Declined',
    color: 'red',
  },
  sent_to_accepted_referral: {
    label: 'Accepted Referral',
    color: 'green',
  },
  sent_to_declined_referral: {
    label: 'Declined Referral',
    color: 'red',
  },
  sent_from_accepted: {
    label: 'Sender Accepted',
    color: 'green',
  },
  sent_from_declined: {
    label: 'Sender Declined',
    color: 'red',
  },
  sent_from_cancelled: {
    label: 'Sender Cancelled',
    color: 'red',
  },
  sent_from_uncancelled: {
    label: 'Sender Un-cancelled',
    color: 'blue',
  },
}

export const ReferralUpdatesDataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Event Name',
        model: 'name',
        width: 400,
      },
      {
        title: 'Type',
        model: 'action',
        width: 200,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <SmartStatus small statuses={UPDATE_ACTIONS} status={value} />
        },
      },
      {
        title: 'Triggered By',
        model: 'author',
        type: 'profile',
        width: 200,
      },
      {
        title: 'Triggered At',
        model: 'triggered_at',
        type: 'date_time',
        width: 250,
      },
    ],
    [],
  )

  return (
    <DataTable
      mainHeaderHidden
      title="Referral Updates"
      icon="referrals"
      columns={columns}
      {...props}
      data={props.data || []}
      meta={{ count: size(props.data), page: 1, prev: null, next: null, pages: 1 }}
    />
  )
}
