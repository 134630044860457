import React from 'react'
import { useLocation } from 'react-router-dom'

import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const SCORING = {
  0: 'No problems reported (suggested action: none at this time)',
  2: 'Low level (suggested action: monitor, re‐assess at a later date)',
  5: 'Moderate level (suggested action: further investigation)',
  8: 'Substantial level (suggested action: intensive assessment)',
  10: 'Severe level (suggested action: intensive assessment)',
}

const INITIAL_STATE = {
  did_use_drugs_for_non_medical_reasons: 0,
  does_abuse_multiple_drugs: 0,
  is_able_to_stop_using_drugs: 0,
  did_have_blackouts: 0,
  does_feel_bad_about_drug_use: 0,
  do_spouse_or_parents_complain_drug_use: 0,
  did_neglect_family_because_drug_use: 0,
  did_engage_in_illegal_activities_for_drugs: 0,
  did_experience_withdrawal_symptoms: 0,
  did_have_medical_problems_due_to_drug_use: 0,
}

const RootDAST10Overlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="DAST-10 Somatic / Physical Symptoms"
      category="clinical_measurement"
      subcategory="dast10"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Introduction"
        headingType="h2"
        scrollview={{
          id: 'introduction',
          name: 'Introduction',
        }}
      >
        <p>
          The Drug Abuse Screening Test (DAST-10) is a 10-item brief screening tool that can be administered by a clinician or
          self-administered. Each question requires a yes or no response, and the tool can be completed in less than 8 minutes. This tool
          assesses drug use, not including alcohol or tobacco use, in the past 12 months.
        </p>
      </Section>

      <Divider />

      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <p>
          I’m going to read you a list of questions concerning information about your potential involvement with drugs, excluding alcohol
          and tobacco, during the past 12 months.
        </p>

        <p>
          When the words “drug abuse” are used, they mean the use of prescribed or over‐the‐counter medications/drugs in excess of the
          directions and any non‐medical use of drugs. The various classes of drugs may include: cannabis (e.g., marijuana, hash), solvents,
          tranquilizers (e.g., Valium), barbiturates, cocaine, stimulants (e.g., speed), hallucinogens (e.g., LSD) or narcotics (e.g.,
          heroin). Remember that the questions{' '}
          <b>
            <u>do not include alcohol or tobacco</u>
          </b>
          .
        </p>

        <p>
          If you have difficulty with a statement, then choose the response that is mostly right. You may choose to answer or not answer any
          of the questions in this section.
        </p>

        <Questionnaire />
      </Section>

      <Divider />

      <Section>
        <p>
          <b>Copyright:</b> Skinner, H. A. (1982). The Drug Abuse Screening Test. Addictive Behavior, 7(4),363–371
        </p>
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <RadioGroup
          label="Have you used drugs other than those required for medical reasons?"
          model="data.questionnaire.did_use_drugs_for_non_medical_reasons"
          layout="horizontal-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, did_use_drugs_for_non_medical_reasons: state.value }))}
        >
          <Radio label="Yes" value={1} />
          <Radio label="No" value={0} />
        </RadioGroup>

        <RadioGroup
          label="Do you abuse more than one drug at a time?"
          model="data.questionnaire.does_abuse_multiple_drugs"
          layout="horizontal-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, does_abuse_multiple_drugs: state.value }))}
        >
          <Radio label="Yes" value={1} />
          <Radio label="No" value={0} />
        </RadioGroup>

        <RadioGroup
          label="Are you always able to stop using drugs when you want to? (If never use drugs, answer “Yes.”"
          model="data.questionnaire.is_able_to_stop_using_drugs"
          layout="horizontal-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, is_able_to_stop_using_drugs: state.value }))}
        >
          <Radio label="Yes" value={1} />
          <Radio label="No" value={0} />
        </RadioGroup>

        <RadioGroup
          label="Have you had 'blackouts' or 'flashbacks' as a result of drug use?"
          model="data.questionnaire.did_have_blackouts"
          layout="horizontal-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, did_have_blackouts: state.value }))}
        >
          <Radio label="Yes" value={1} />
          <Radio label="No" value={0} />
        </RadioGroup>

        <RadioGroup
          label="Do you ever feel bad or guilty about your drug use? If never use drugs, choose 'No.'"
          model="data.questionnaire.does_feel_bad_about_drug_use"
          layout="horizontal-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, does_feel_bad_about_drug_use: state.value }))}
        >
          <Radio label="Yes" value={1} />
          <Radio label="No" value={0} />
        </RadioGroup>

        <RadioGroup
          label="Does your spouse (or parents) ever complain about your involvement with drugs?"
          model="data.questionnaire.do_spouse_or_parents_complain_drug_use"
          layout="horizontal-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, do_spouse_or_parents_complain_drug_use: state.value }))}
        >
          <Radio label="Yes" value={1} />
          <Radio label="No" value={0} />
        </RadioGroup>

        <RadioGroup
          label="Have you neglected your family because of your use of drugs?"
          model="data.questionnaire.did_neglect_family_because_drug_use"
          layout="horizontal-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, did_neglect_family_because_drug_use: state.value }))}
        >
          <Radio label="Yes" value={1} />
          <Radio label="No" value={0} />
        </RadioGroup>

        <RadioGroup
          label="Have you engaged in illegal activities in order to obtain drugs?"
          model="data.questionnaire.did_engage_in_illegal_activities_for_drugs"
          layout="horizontal-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, did_engage_in_illegal_activities_for_drugs: state.value }))}
        >
          <Radio label="Yes" value={1} />
          <Radio label="No" value={0} />
        </RadioGroup>

        <RadioGroup
          label="Have you ever experienced withdrawal symptoms (felt sick) when you stopped taking drugs?"
          model="data.questionnaire.did_experience_withdrawal_symptoms"
          layout="horizontal-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, did_experience_withdrawal_symptoms: state.value }))}
        >
          <Radio label="Yes" value={1} />
          <Radio label="No" value={0} />
        </RadioGroup>

        <RadioGroup
          label="Have you had medical problems as a result of your drug use (e.g. memory loss, hepatitis, convulsions, bleeding, etc.)?"
          model="data.questionnaire.did_have_medical_problems_due_to_drug_use"
          layout="horizontal-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, did_have_medical_problems_due_to_drug_use: state.value }))}
        >
          <Radio label="Yes" value={1} />
          <Radio label="No" value={0} />
        </RadioGroup>
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Score
            title="Final Score"
            description={`In these statements, the term "drug abuse" refers to the use of medications at a level that exceeds the instructions, and/or any non‐medical use of drugs. Patients receive 1 point for every "yes" answer with the exception of question #3, for which a "no" answer receives 1 point. DAST‐10 Score Degree of Problems Related to Drug Abuse Suggested Action.`}
            finalScore={finalScore}
            scoring={SCORING}
          />
        </>
      )}
    </>
  )
}

export const DAST10Overlay = withOverlayError(RootDAST10Overlay)
