import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../theme'
import { useCreate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import Notifications from '../../modules/notifications'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Overlay from '../../components/Overlay'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'

type Props = {
  category: string
  className?: string
  pageID: string
}

export const NotionFeedback = ({ pageID, className, category }: Props) => {
  const { isBehave } = useSettings()

  const [userRating, setUserRating] = React.useState('')
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false)

  const { mutateAsync, isLoading } = useCreate({
    name: 'notion-feedback',
    url: '/feedback',
  })

  const thumbsUp = async () => {
    setUserRating('good')

    await mutateAsync({
      rating: 'good',
      category: category,
      pathname: `/notion/${pageID}`,
    })
  }

  const thumbsDown = () => {
    setUserRating('bad')
    setIsOverlayOpen(true)
  }

  const closeOverlay = () => {
    setIsOverlayOpen(false)
  }

  React.useEffect(() => {
    setUserRating('')
  }, [pageID])

  if (!pageID || !category || !isBehave) return null

  return (
    <div css={styles.root} className={className}>
      <div css={styles.main}>
        <div css={styles.content}>
          <h3 css={styles.title}>Was this article helpful?</h3>
        </div>

        <div css={styles.actions}>
          <Button
            rounded
            hideLabel
            emoji="👍"
            display="inline-flex"
            type="default"
            size={200}
            onClick={thumbsUp}
            isLoading={isLoading}
            isDisabled={isLoading}
            css={styles.button}
            className={userRating === 'good' ? 'is-active rating-good' : ''}
          />
          <Button
            rounded
            hideLabel
            emoji="👎"
            display="inline-flex"
            type="default"
            size={200}
            onClick={thumbsDown}
            isDisabled={isLoading}
            css={styles.button}
            className={userRating === 'bad' ? 'is-active rating-bad' : ''}
          />
        </div>
      </div>

      <SummonOverlay isOpen={isOverlayOpen} onClose={closeOverlay} overlay={<FeedbackOverlay pageID={pageID} category={category} />} />
    </div>
  )
}

const FeedbackOverlay = ({ onClose, pageID, category }: any) => {
  const form: any = React.useRef()
  const [isValid, setIsValid] = React.useState(false)

  const { mutateAsync, isLoading } = useCreate({
    name: 'notion-feedback',
    url: '/feedback',
  })

  const sendFeedback = async () => {
    const model = form.current.getFormValue()

    await mutateAsync({
      category: category,
      pathname: `/notion/${pageID}`,
      notes: model.notes,
    })

    Notifications.send('Your feedback has been sent', 'positive')

    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center">
      <Overlay.Header title="Send Your Feedback" glyph="star" />

      <Overlay.Content>
        <Section>
          <Form getForm={form} onValidationUpdate={setIsValid}>
            <FormSection>
              <RadioGroup label="Rating" model="rating" layout="horizontal-dense" css={styles.ratingRadios} defaultValue="bad">
                <Radio showCheck={false} label="👍" value="good" />
                <Radio showCheck={false} label="👎" value="bad" />
              </RadioGroup>

              <Textarea
                label="Your feedback"
                model="notes"
                validations={{
                  presence: {
                    message: 'Please type your feedback',
                  },
                }}
              />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Send Feedback" color="green" type="primary" isDisabled={!isValid} isLoading={isLoading} onClick={sendFeedback} />
      </Overlay.Footer>
    </Overlay>
  )
}

const styles = {
  root: {
    display: 'grid',
    gridGap: '1rem',
  },

  main: {
    display: 'grid',
    gridGap: '0.5rem',
    textAlign: 'center',
    justifyItems: 'center',

    '@media(min-width: 600px)': {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      justifyItems: 'start',
      textAlign: 'left',
    },
  },

  content: {
    display: 'grid',
    gridGap: '0.1rem',

    '& > *': { margin: 0 },
  },

  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    margin: '-0.25rem',
  },

  title: {
    fontSize: '1rem',
  },

  button: {
    width: 40,
    height: 40,
    margin: '0.25rem',

    '&.is-active.rating-good': {
      boxShadow: `inset 0 0 0 1px ${COLORS.green}, 0 0 0 3px ${tint(0.5, COLORS.green)}`,
    },

    '&.is-active.rating-bad': {
      boxShadow: `inset 0 0 0 1px ${COLORS.red}, 0 0 0 3px ${tint(0.5, COLORS.red)}`,
    },
  },

  ratingRadios: {
    '.item-label': {
      fontSize: '1.35rem',
    },
  },
}
