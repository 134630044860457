import React from 'react'

import { COLORS } from '../theme'
import { SERVICE_CATEGORIES } from '../utils/constants'

import Card from './Card'
import CardContent from './CardContent'
import CardLink from './CardLink'
import CardSubtitle from './CardSubtitle'
import CardTitle from './CardTitle'
import Glyph from './Glyph'

const ServiceSelector = (props: any) => {
  const [isOpen, setIsOpen] = React.useState(props.isOpen)
  const [category, setCategory] = React.useState(props.category)
  const [subcategory, setSubcategory] = React.useState(props.subcategory)

  const selectedCategory = SERVICE_CATEGORIES[category]
  const selectedSubcategory = selectedCategory?.subcategories[subcategory]

  return (
    <div>
      <Card>
        <CardLink graphic={<Glyph glyph="selector" />} onClick={() => setIsOpen((c) => !c)}>
          <CardTitle
            title={
              selectedSubcategory ? (
                selectedSubcategory.label
              ) : (
                <>
                  Select Service Template
                  <Glyph glyph="label_required" size={12} />
                </>
              )
            }
            css={{
              fontSize: '1.05rem',
              color: !selectedSubcategory && `${COLORS.text} !important`,
            }}
          />
          {selectedCategory && <CardSubtitle subtitle={selectedCategory.label} className="text-[0.95rem]" />}
        </CardLink>

        {isOpen && (
          <CardContent showDivider={false} css={menuStyles}>
            {Object.values(SERVICE_CATEGORIES).map((category: any) => (
              <div key={category.id}>
                <h4
                  css={{
                    margin: '1rem 0 0',
                    padding: '0.4rem 1.3rem 0',

                    color: COLORS.textMuted,
                    fontSize: '0.85rem',
                    fontWeight: 700,
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                  }}
                >
                  {category.label}
                </h4>

                <div className="pl-[1.3rem]">
                  {Object.values(category.subcategories).map((subcategory: any) => (
                    <CardLink
                      key={subcategory.id}
                      onClick={() => {
                        if (props.onUpdate) {
                          props.onUpdate({
                            category: category.id,
                            subcategory: subcategory.id,
                          })
                        }
                        setCategory(category.id)
                        setSubcategory(subcategory.id)
                        setIsOpen(false)
                      }}
                      variant="variant-list"
                      paddingX="0"
                      css={{ overflow: 'visible' }}
                    >
                      <CardTitle title={subcategory.label} className="text-[0.95rem] font-[600]" />
                    </CardLink>
                  ))}
                </div>
              </div>
            ))}
          </CardContent>
        )}
      </Card>
    </div>
  )
}

const menuStyles = {
  padding: 0,
  maxHeight: 500,
  background: COLORS.white,

  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
}

export default ServiceSelector
