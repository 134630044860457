import React from 'react'

import Select from '../common/Select'

type Props = { column: any }

const ContentTypeFilter = (props: Props) => {
  const {
    column: { filterValue, setFilter },
  } = props

  const filterOptions = [
    {
      value: 'audio',
      label: 'Audio',
    },
    {
      value: 'excel',
      label: 'Excel',
    },
    {
      value: 'image',
      label: 'Image',
    },
    {
      value: 'pdf',
      label: 'PDF',
    },
    {
      value: 'word',
      label: 'Word',
    },
    {
      value: 'other',
      label: 'File',
    },
  ]

  return <Select setFilter={setFilter} filterValue={filterValue} filterOptions={filterOptions} />
}

export default ContentTypeFilter
