import React from 'react'
import groupBy from 'lodash/groupBy'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

import { COLORS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'

import { PopoverMenu, CustomPopoverMenuItem } from '../../components/PopoverMenu'
import Button from '../../components/Button'
import Grid from '../../components/Grid'
import Markup from '../../components/Markup'
import State from '../../components/State'
import Tooltip from '../../components/Tooltip'

export const QuickTextSelector = ({ onSelect, className }: any) => {
  const { isPortal } = useSettings()

  if (isPortal) return null

  return (
    <PopoverMenu
      // maxWidth="80vw"
      trigger={
        <div>
          <Button
            testKey="quick_text_button"
            label="Quick Text…"
            icon="quick_text"
            color="orange"
            size={100}
            className={className}
            css={STYLES.button}
          />
        </div>
      }
    >
      <QuickTextList onSelect={onSelect} />
    </PopoverMenu>
  )
}

const groupData = (data: any) => {
  const groups: any = {}
  const other: any = []

  if (!data) return { groups, other }

  for (const item of data) {
    if (item.subcategory) {
      if (!groups[item.subcategory]) groups[item.subcategory] = []

      groups[item.subcategory].push(item)
    } else {
      other.push(item)
    }
  }

  return { groups, other }
}

const QuickTextList = ({ onSelect, testKey }: any) => {
  const { data: quickTextData, isLoading }: any = useGet({ name: 'quick-paragraphs', url: `/quick_paragraphs` })

  const isEmpty = size(quickTextData) === 0

  const personalQuickText = sortBy(
    quickTextData?.filter((item: any) => item.category === 'personal'),
    'order',
  )
  const companyQuickText = sortBy(
    quickTextData?.filter((item: any) => item.category === 'company'),
    'order',
  )

  const { groups: groupedPersonal, other: otherPersonal } = groupData(personalQuickText)
  const { groups: groupedCompany, other: otherCompany } = groupData(companyQuickText)

  const hasPersonalQuickText = size(personalQuickText) > 0
  const hasCompanyQuickText = size(companyQuickText) > 0

  if (isLoading || isEmpty || !quickTextData) {
    return (
      <State
        testKey={testKey}
        isLoading={isLoading}
        isEmpty={isEmpty}
        title="Quick Text"
        emptyDescription="No quick texts created yet. Configure from settings or record a voice memo."
        emptyActions={<Button testKey="manage_quick_text_button" label="Manage Quick Text" glyph="settings" link="/settings/quick-text" />}
      />
    )
  }

  return (
    <Grid gap="0.5rem" testKey={testKey}>
      {hasPersonalQuickText && (
        <div>
          <div css={STYLES.heading}>Personal Quick Text</div>

          {Object.keys(groupedPersonal).map((groupName: any) => {
            const items = groupedPersonal[groupName]

            return (
              <div key={groupName}>
                <div css={STYLES.subheading}>{groupName}</div>

                {items.map((item: any) => (
                  <QuickTextItem key={item.id} data={item} onSelect={onSelect} />
                ))}
              </div>
            )
          })}

          {size(otherPersonal) > 0 && (
            <>
              {size(groupedPersonal) > 0 && <div css={STYLES.subheading}>Other</div>}

              {otherPersonal.map((item: any) => (
                <QuickTextItem key={item.id} data={item} onSelect={onSelect} />
              ))}
            </>
          )}
        </div>
      )}

      {hasCompanyQuickText && (
        <div>
          <div css={STYLES.heading}>Company Quick Text</div>

          {Object.keys(groupedCompany).map((groupName: any) => {
            const items = groupedCompany[groupName]

            return (
              <div key={groupName}>
                <div css={STYLES.subheading}>{groupName}</div>

                {items.map((item: any) => (
                  <QuickTextItem key={item.id} data={item} onSelect={onSelect} />
                ))}
              </div>
            )
          })}

          {size(otherCompany) > 0 && (
            <>
              {size(groupedCompany) > 0 && <div css={STYLES.subheading}>Other</div>}

              {otherCompany.map((item: any) => (
                <QuickTextItem key={item.id} data={item} onSelect={onSelect} />
              ))}
            </>
          )}
        </div>
      )}
    </Grid>
  )
}

const QuickTextItem = ({ data, onSelect }: any) => {
  const handleSelect = () => {
    onSelect?.({ rich: data.notes, plain: data.plain_notes })
  }

  if (!data) return null

  return (
    <CustomPopoverMenuItem onClick={handleSelect} css={STYLES.item}>
      <div className="grid truncate min-w-0 pr-8">
        {data.name && <div css={STYLES.name}>{data.name}</div>}
        {data.plain_notes && <div css={STYLES.content}>{data.plain_notes}</div>}
      </div>

      <div className="absolute z-[10] right-3" onClick={stopPropagation}>
        <Tooltip content={<Markup value={data.notes} />} portal="radix" />
      </div>
    </CustomPopoverMenuItem>
  )
}

const stopPropagation = (e: any) => e.stopPropagation()

const STYLES = {
  button: {
    img: {
      width: 16,
      height: 16,
    },
  },

  heading: {
    padding: '0.5rem 0.7rem',
    fontSize: '1rem',
    fontWeight: 700,
    borderBottom: `1px solid ${COLORS.divider}`,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
  },

  subheading: {
    padding: '0.25rem 0.7rem',
    fontSize: '0.95rem',
    fontWeight: 600,
    color: COLORS.textMuted,
    backgroundColor: COLORS.hover,
    borderBottom: `1px solid ${COLORS.divider}`,
    // textTransform: 'uppercase',
    // letterSpacing: 1,
  },

  item: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    minWidth: 0,
    borderBottom: `1px solid ${COLORS.divider}`,
    lineHeight: 'normal',
    padding: '0.25rem 0.7rem',
    cursor: 'pointer',
    position: 'relative',

    '&:hover': {
      background: COLORS.hover,
    },
  },

  name: {
    fontWeight: 600,
    fontSize: '0.95rem',
    color: COLORS.blue,
  },

  content: {
    color: COLORS.textMuted,
    fontSize: '0.9rem',
    lineHeight: '1.44em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}
