import React from 'react'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import Glyph from '../Glyph'
import Flex from '../Flex'
import State from '../State'
import NoInternet from '../Alerts/NoInternet'

import OverlayContent from './OverlayContent'
import OverlayFooter from './OverlayFooter'
import OverlayHeader from './OverlayHeader'
import OverlaySidebar from './OverlaySidebar'
import OverlaySubHeader from './OverlaySubHeader'

import { COLORS, TOKENS } from '../../theme'

import useHotkeys from '../../hooks/useHotkeys'

import { OverlayContext } from './context'
import { GlobalSummonOverlayContext } from '../GlobalSummonOverlay'

type BackdropProps = {
  isTransparent: boolean
}

type OverlayProps = {
  children?: React.ReactNode
  className?: string
  closeOnBackdrop?: boolean
  closeWrapper?: any
  content?: any
  footer?: any
  fullheight?: boolean
  header?: any
  history?: any
  innerRef?: any
  isFullyMinimized?: boolean
  isLoading?: boolean
  isMinimized?: boolean
  location?: any
  maxWidth?: number | string
  minHeight?: number | string
  minimizeEnabled?: boolean
  onClose?: any
  onFullyMinimize?: any
  onMaximize?: any
  onMinimize?: any
  onShow?: any
  position?: string
  showBackdrop?: boolean
  stackIndex?: number
  stopPropagation?: boolean
  transparentBackdrop?: boolean
  zIndex?: number
}

const closeElement = (
  <Flex testKey="close_overlay" className="close">
    <Glyph glyph="close" size={14} color={COLORS.text} className="overlay-close-glyph" />
  </Flex>
)

const BackdropElement = ({ isTransparent }: BackdropProps) => {
  const classNames = clsx('backdrop', { 'is-transparent': isTransparent })

  return <div className={classNames} />
}

const Overlay = (props: OverlayProps) => {
  const {
    children,
    className,
    closeOnBackdrop = false,
    closeWrapper,
    content,
    footer,
    fullheight,
    header,
    innerRef,
    isLoading = false,
    maxWidth = 37,
    minHeight,
    minimizeEnabled = false,
    onClose,
    onFullyMinimize,
    onMaximize,
    onMinimize,
    onShow,
    position = 'right',
    showBackdrop,
    stackIndex = 0,
    stopPropagation = false,
    transparentBackdrop,
    testKey,
    zIndex,
    closeOnEscape = true,
    transformOrigin,
    hideClose,
    animateOnEnter,
    ...rest
  } = props

  const history = useHistory()
  const location = useLocation()

  const { overlaysCount } = React.useContext(GlobalSummonOverlayContext)

  const [isMinimized, setIsMinimized] = React.useState(!!props.isMinimized)
  const [isFullyMinimized, setIsFullyMinimized] = React.useState(!!props.isFullyMinimized)

  const online = useSelector((state) => state?.common?.global?.online)

  const onStopPropagation = (event) => {
    if (!stopPropagation) return

    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const closeOverlay = () => {
    clearAllBodyScrollLocks()

    if (onClose) return onClose()

    if (location.parent) history?.push?.(location.parent.url)
    else history?.goBack?.()
    // const path = location.pathname
    // history.push(path.substr(0, path.lastIndexOf('/')))
  }

  useHotkeys(
    'overlay',
    [
      {
        key: 'esc',
        description: 'Close',
        action: () => closeOnEscape && closeOverlay(),
        restrict: true,
      },
    ],
    closeWrapper,
  )

  if (innerRef) innerRef(this.ref && this.ref.current)

  const rootClasses = clsx({
    [`pos-${position}`]: position,
    [`from-transform-origin`]: !!transformOrigin,
    'minimize-enabled': minimizeEnabled,
    'is-minimized': isMinimized,
    'is-fully-minimized': isFullyMinimized,
    'animate-overlayEnterRight': animateOnEnter && position === 'right',
    [className]: className,
  })

  const dialogClasses = clsx('dialog', `pos-${position}`, {
    fullheight: fullheight,
    'is-not-minimized': !isFullyMinimized && !isMinimized,
  })

  const fullyMinimize = () => {
    setIsMinimized(true)
    setIsFullyMinimized(true)
    if (onFullyMinimize) onFullyMinimize()
  }

  const show = () => {
    setIsFullyMinimized(false)
    if (onShow) onShow()
  }

  const maximize = () => {
    setIsMinimized(false)
    if (onMaximize) onMaximize()
  }

  const minimize = () => {
    setIsMinimized(true)
    if (onMinimize) onMinimize()
  }

  const dynamicStyles = {
    ...(isMinimized && {
      bottom: (overlaysCount - stackIndex) * 50,
    }),

    ...(isFullyMinimized && {
      transform: `scale(${1 + stackIndex / 100})`,
      transformOrigin: 'bottom right',
    }),
  }

  return (
    <React.StrictMode>
      <OverlayContext.Provider value={{ position }}>
        <div
          data-test={testKey}
          css={[styles.root, animations, { zIndex }]}
          onClick={onStopPropagation}
          className={rootClasses}
          style={transformOrigin && { '--transform-origin': transformOrigin }}
          {...rest}
        >
          <div
            className={dialogClasses}
            css={{
              ...(minHeight && {
                height: '100% !important',
                maxHeight: `${minHeight}rem !important`,
              }),
              maxWidth: maxWidth ? `${maxWidth}rem` : '100%',
              ...dynamicStyles,
            }}
          >
            {isLoading && <State isLoading />}
            {!isLoading && (
              <>
                {header}
                {content || children}
                {online && footer}
                {!online && <NoInternet />}
              </>
            )}

            <div css={styles.dialogActions}>
              {/* Close & Minimize */}
              {/* {minimizeEnabled && (
                <>
                  <div onClick={isFullyMinimized ? show : fullyMinimize}>
                    <Glyph glyph="horizontal_line" size={16} color={COLORS.text} />
                  </div>

                  <div onClick={isFullyMinimized ? show : isMinimized ? maximize : minimize}>
                    <Glyph glyph={isMinimized ? 'enlarge' : 'compress'} size={16} color={COLORS.text} />
                  </div>
                </>
              )} */}

              {!hideClose && (
                <>
                  {closeWrapper && closeWrapper(closeElement, closeOverlay)}
                  {!closeWrapper && <div onClick={closeOverlay}>{closeElement}</div>}
                </>
              )}
            </div>
          </div>

          {/* BACKDROP */}
          {showBackdrop && !isMinimized && !isFullyMinimized && (
            <>
              {closeWrapper && closeWrapper(<BackdropElement isTransparent={transparentBackdrop} />, closeOnBackdrop ? closeOverlay : null)}
              {!closeWrapper && (
                <div onClick={closeOnBackdrop ? closeOverlay : null}>
                  <BackdropElement isTransparent={transparentBackdrop} />
                </div>
              )}
            </>
          )}
        </div>
      </OverlayContext.Provider>
    </React.StrictMode>
  )
}

const HEADER_BUTTON_SIZE = 38

const styles: any = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 100,
    pointerEvents: 'none',

    '&.pos-right': {
      justifyContent: 'flex-end',
    },

    '&.pos-center': {
      padding: '0.5rem',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '&.pos-top': {
      padding: '0.5rem',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },

    '.overlay-header': {
      paddingRight: HEADER_BUTTON_SIZE + 20,
      zIndex: 3,
      flex: '0 0 auto',
    },

    '&.minimize-enabled .overlay-header': {
      paddingRight: HEADER_BUTTON_SIZE * 3 + 20,
    },

    '&.is-minimized, &.is-fully-minimized': {
      padding: 0,
      paddingRight: '1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',

      '.dialog': {
        borderBottomLeftRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
        height: '100% !important',
        maxHeight: '600px !important',
        maxWidth: '800px !important',
      },
    },

    '&.is-fully-minimized': {
      '.dialog': {
        height: 'auto !important',
      },

      '.overlay-subheader, .overlay-content, .overlay-footer, .overlay-page-grid': {
        display: 'none',
      },
    },

    '.backdrop': {
      background: COLORS.backdrop,
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 0,
      pointerEvents: 'all',

      willChange: 'opacity',

      '&.is-transparent': {
        cursor: 'default',
        background: COLORS.transparent,
      },
    },

    '.dialog': {
      width: '100%',
      height: '100%',

      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      zIndex: 1,
      pointerEvents: 'all',
      background: COLORS.white,
      overflow: 'hidden',

      transition: `max-width 120ms ${TOKENS.easeOutCubic}, top 120ms ${TOKENS.easeOutCubic}`,
      willChange: 'transform, top, opacity, max-width',

      '&.pos-left': {
        boxShadow: '6px 0 8px 4px rgba(12,38,106,0.04), 16px 0 24px 6px rgba(12,38,106,0.03), 24px 0 32px 8px rgba(12,38,106,0.02)',
      },

      '&.pos-right': {
        boxShadow: '-6px 0 8px 4px rgba(12,38,106,0.04), -16px 0 24px 6px rgba(12,38,106,0.03), -24px 0 32px 8px rgba(12,38,106,0.02)',
      },

      '&.pos-center': {
        height: 'min-content',
        maxHeight: '100%',
        borderRadius: 6,

        boxShadow: '0 6px 8px 4px rgba(12,38,106,0.08), 0 16px 24px 6px rgba(12,38,106,0.06), 0 24px 32px 8px rgba(12,38,106,0.04)',
      },

      '&.pos-top': {
        height: 'min-content',
        maxHeight: '100%',
        borderRadius: 6,

        boxShadow: '0 6px 8px 4px rgba(12,38,106,0.08), 0 16px 24px 6px rgba(12,38,106,0.06), 0 24px 32px 8px rgba(12,38,106,0.04)',
      },

      '&.fullheight': { height: '100% !important' },

      '&.is-not-minimized': {
        top: '0 !important',
      },
    },
  },

  dialogActions: {
    display: 'flex',
    alignItems: 'stretch',
    height: TOKENS.headerHeight,
    marginRight: 10,

    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 3,

    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'stretch',
      width: HEADER_BUTTON_SIZE,
      transition: 'all 80ms cubic-bezier(0.39, 0.575, 0.565, 1)',
      cursor: 'pointer',

      '&:hover': {
        svg: {
          fill: COLORS.blue,
          transform: 'scale3d(1.15, 1.15, 1.15)',
        },

        '.close svg': {
          fill: COLORS.red,
        },
      },
    },
  },
}

const animations = {
  '&.pos-right': {
    // ENTER
    '&.animation-enter': {
      '.dialog': { transform: 'translate3d(75%, 0, 0)' },
      '.backdrop': { opacity: 0 },
    },

    '&.animation-enter.animation-enter-active': {
      '.dialog': {
        transform: 'translate3d(0, 0, 0)',
        transition: `all 150ms ${TOKENS.easeOutCubic}`,
      },
      '.backdrop': {
        opacity: 1,
        transition: `opacity 150ms ${TOKENS.easeOutCubic}`,
      },
    },

    // EXIT
    '&.animation-exit': {
      '.dialog': { transform: 'translate3d(0, 0, 0)' },
      '.backdrop': { opacity: 1 },
    },

    '&.animation-exit.animation-exit-active': {
      '.dialog': {
        transform: 'translate3d(200%, 0, 0)',
        transition: `all 150ms ${TOKENS.easeInSine}`,
        boxShadow: 'none',
      },
      '.backdrop': {
        opacity: 0,
        transition: `opacity 150ms ${TOKENS.easeInSine}`,
      },
    },

    '&.animation-exit-done': {
      '.dialog': { opacity: 0, boxShadow: 'none', transform: 'translate3d(200%, 0, 0)' },
      '.backdrop': { opacity: 0 },
    },
  },

  '&.pos-left': {
    // ENTER
    '&.animation-enter': {
      '.dialog': { transform: 'translateX(-25%)' },
      '.backdrop': { opacity: 0 },
    },

    '&.animation-enter.animation-enter-active': {
      '.dialog': {
        transform: 'translateX(0px)',
        transition: `all 150ms ${TOKENS.easeOutCubic}`,
      },
      '.backdrop': {
        opacity: 1,
        transition: `opacity 150ms ${TOKENS.easeOutCubic}`,
      },
    },

    '&.animation-enter-done': {
      '.dialog': { opacity: 1, transform: 'translateX(0px)' },
      '.backdrop': { opacity: 1 },
    },

    // EXIT
    '&.animation-exit': {
      '.dialog': { transform: 'translateX(0px)' },
      '.backdrop': { opacity: 1 },
    },

    '&.animation-exit.animation-exit-active': {
      '.dialog': {
        transform: 'translateX(-100%)',
        transition: `all 150ms ${TOKENS.easeInSine}`,
        boxShadow: 'none',
      },
      '.backdrop': {
        opacity: 0,
        transition: `opacity 150ms ${TOKENS.easeInSine}`,
      },
    },

    '&.animation-exit-done': {
      '.dialog': { opacity: 0, boxShadow: 'none', transform: 'translateX(-100%)' },
      '.backdrop': { opacity: 0 },
    },
  },

  '&.pos-center': {
    // ENTER
    '&.animation-enter': {
      '.dialog': { opacity: 0, transform: 'translate3d(0, 1rem, 0)' },
      '.backdrop': { opacity: 0 },
    },

    '&.animation-enter.animation-enter-active': {
      '.dialog': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
        transition: `all 150ms ${TOKENS.easeOutCubic}`,
      },
      '.backdrop': {
        opacity: 1,
        transition: `opacity 150ms ${TOKENS.easeOutCubic}`,
      },
    },

    // EXIT
    '&.animation-exit': {
      '.dialog': { opacity: 1, transform: 'translate3d(0, 0, 0)' },
      '.backdrop': { opacity: 1 },
    },

    '&.animation-exit.animation-exit-active': {
      '.dialog': {
        opacity: 0,
        transform: 'translate3d(0, 1rem, 0)',
        transition: `all 150ms ${TOKENS.easeInSine}`,
      },
      '.backdrop': {
        opacity: 0,
        transition: `opacity 150ms ${TOKENS.easeInSine}`,
      },
    },

    '&.animation-exit-done': {
      '.dialog': { opacity: 0, transform: 'translate3d(0, 1rem, 0)' },
      '.backdrop': { opacity: 0 },
    },
  },

  '&.pos-center.from-transform-origin': {
    // ENTER
    '&.animation-enter': {
      '.dialog': {
        opacity: 0,
        transform: 'scale3d(0.5, 0.5, 0.5)',
        transformOrigin: 'var(--transform-origin)',
      },
      '.backdrop': { opacity: 0 },
    },

    '&.animation-enter.animation-enter-active': {
      '.dialog': {
        opacity: 1,
        transform: 'scale3d(1, 1, 1)',
        transition: `all 150ms ${TOKENS.easeOutCubic}`,
        transformOrigin: 'var(--transform-origin)',
      },
      '.backdrop': {
        opacity: 1,
        transition: `opacity 150ms ${TOKENS.easeOutCubic}`,
      },
    },

    // EXIT
    '&.animation-exit': {
      '.dialog': {
        opacity: 1,
        transform: 'scale3d(1, 1, 1)',
        transformOrigin: 'var(--transform-origin)',
      },
      '.backdrop': { opacity: 1 },
    },

    '&.animation-exit.animation-exit-active': {
      '.dialog': {
        opacity: 0,
        transform: 'scale3d(0.5, 0.5, 0.5)',
        transition: `all 150ms ${TOKENS.easeInSine}`,
        transformOrigin: 'var(--transform-origin)',
      },
      '.backdrop': {
        opacity: 0,
        transition: `opacity 150ms ${TOKENS.easeInSine}`,
      },
    },

    '&.animation-exit-done': {
      '.dialog': {
        opacity: 0,
        transform: 'scale3d(0.5, 0.5, 0.5)',
      },
      '.backdrop': { opacity: 0 },
    },
  },

  '&.pos-top': {
    // ENTER
    '&.animation-enter': {
      '.dialog': { opacity: 0, transform: 'translateY(1rem) scale(.95)' },
      '.backdrop': { opacity: 0 },
    },

    '&.animation-enter.animation-enter-active': {
      '.dialog': {
        opacity: 1,
        transform: 'translateY(0) scale(1)',
        transition: `all 150ms cubic-bezier(0.34, 1.56, 0.64, 1)`,
      },
      '.backdrop': {
        opacity: 1,
        transition: `opacity 150ms ${TOKENS.easeInSine}`,
      },
    },

    // EXIT
    '&.animation-exit': {
      '.dialog': { opacity: 1, transform: 'scale(1)' },
      '.backdrop': { opacity: 1 },
    },

    '&.animation-exit.animation-exit-active': {
      '.dialog': {
        opacity: 0,
        transform: 'scale(.95)',
        transition: `all 150ms ${TOKENS.easeOutCubic}`,
      },
      '.backdrop': {
        opacity: 0,
        transition: `opacity 150ms ${TOKENS.easeOutCubic}`,
      },
    },

    '&.animation-exit-done': {
      '.dialog': { opacity: 0, transform: 'scale(.95)' },
      '.backdrop': { opacity: 0 },
    },
  },
}

Overlay.Content = OverlayContent
Overlay.Content.displayName = 'OverlayContent'

Overlay.Footer = OverlayFooter
Overlay.Footer.displayName = 'OverlayFooter'

Overlay.Header = OverlayHeader
Overlay.Header.displayName = 'OverlayHeader'

Overlay.Sidebar = OverlaySidebar
Overlay.Sidebar.displayName = 'OverlaySidebar'

Overlay.SubHeader = OverlaySubHeader
Overlay.SubHeader.displayName = 'OverlaySubHeader'

export default Overlay
