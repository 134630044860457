import React from 'react'

import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'

const DocumentationMultiSelector: React.FC<any> = ({ model = 'documentation_required' }) => {
  return (
    <MultiObjectSelector
      apiData={DATA}
      icon="custom_notes"
      label="Documentation Required"
      model={model}
      selectTitle={(data) => data.name}
      selectDescription={() => null}
    />
  )
}

const DATA = [
  {
    id: 'clinical_assessment_asam_criteria',
    name: 'Clinical Assessment – ASAM Criteria',
  },
  {
    id: 'clinical_assessment_biopsychosocial_assessment',
    name: 'Clinical Assessment – Biopsychosocial Assessment',
  },
  {
    id: 'clinical_assessment_mental_status_exam',
    name: 'Clinical Assessment – Mental Status Exam',
  },
  {
    id: 'clinical_assessment_pre_screen',
    name: 'Clinical Assessment – Pre-Screen',
  },
  {
    id: 'clinical_assessment_suicide_assessment',
    name: 'Clinical Assessment – Suicide Assessment',
  },
  {
    id: 'clinical_measures_ace_score',
    name: 'Clinical Measures – ACE Score',
  },
  {
    id: 'clinical_measures_asi_the_addiction_severity_index_lite',
    name: 'Clinical Measures – ASI The Addiction Severity Index (Lite)',
  },
  {
    id: 'clinical_measures_asi_5_addiction_severity_index',
    name: 'Clinical Measures – ASI-5 Addiction Severity Index',
  },
  {
    id: 'clinical_measures_asrm_altman_self_rating_mania_scale',
    name: 'Clinical Measures – ASRM Altman Self-Rating Mania Scale',
  },
  {
    id: 'clinical_measures_audit_c',
    name: 'Clinical Measures – AUDIT-C',
  },
  {
    id: 'clinical_measures_bam_brief_addiction_monitor',
    name: 'Clinical Measures – BAM Brief Addiction Monitor',
  },
  {
    id: 'clinical_measures_barc_10_brief_assessment_of_recovery_capital',
    name: 'Clinical Measures – BARC-10 Brief Assessment of Recovery Capital',
  },
  {
    id: 'clinical_measures_dast_10_drug_abuse_screening_test',
    name: 'Clinical Measures – DAST-10 Drug Abuse Screening Test',
  },
  {
    id: 'clinical_measures_gad_7_anxiety',
    name: 'Clinical Measures – GAD-7 Anxiety',
  },
  {
    id: 'clinical_measures_mdi_major_depression_inventory',
    name: 'Clinical Measures – MDI Major Depression Inventory',
  },
  {
    id: 'clinical_measures_pcl_c_ptsd_check_list_civilian_version',
    name: 'Clinical Measures – PCL-C PTSD CheckList Civilian Version',
  },
  {
    id: 'clinical_measures_pdss_panic_disorder_severity_scale_self_report',
    name: 'Clinical Measures – PDSS Panic Disorder Severity Scale (Self Report)',
  },
  {
    id: 'clinical_measures_phq_15_somatic_physical_symptoms',
    name: 'Clinical Measures – PHQ-15 Somatic / Physical Symptoms',
  },
  {
    id: 'clinical_measures_phq_9_depression',
    name: 'Clinical Measures – PHQ-9 Depression',
  },
  {
    id: 'clinical_measures_the_world_health_organization_quality_of_life_whoqol_bref',
    name: 'Clinical Measures – The World Health Organization Quality of Life (WHOQOL)-BREF',
  },
  {
    id: 'clinical_notes_case_management',
    name: 'Clinical Notes – Case Management',
  },
  {
    id: 'clinical_notes_family_session',
    name: 'Clinical Notes – Family Session',
  },
  {
    id: 'clinical_notes_group',
    name: 'Clinical Notes – Group',
  },
  {
    id: 'clinical_notes_individual',
    name: 'Clinical Notes – Individual',
  },
  {
    id: 'discharge_summary',
    name: 'Discharge Summary  ',
  },
  {
    id: 'medical_assessments_client_health_questionnaire',
    name: 'Medical Assessments – Client Health Questionnaire',
  },
  {
    id: 'medical_assessments_history_physical',
    name: 'Medical Assessments – History & Physical',
  },
  {
    id: 'medical_assessments_history_physical_follow_up',
    name: 'Medical Assessments – History & Physical Follow Up',
  },
  {
    id: 'medical_assessments_psychiatric_assessment',
    name: 'Medical Assessments – Psychiatric Assessment',
  },
  {
    id: 'nursing_ciwa',
    name: 'Nursing – CIWA',
  },
  {
    id: 'nursing_cows',
    name: 'Nursing – COWS',
  },
  {
    id: 'nursing_fall_risk_assessment',
    name: 'Nursing – Fall Risk Assessment',
  },
  {
    id: 'nursing_nursing_assessment',
    name: 'Nursing – Nursing Assessment',
  },
  {
    id: 'nursing_nursing_re_assessment',
    name: 'Nursing – Nursing Re-Assessment',
  },
  {
    id: 'nursing_progress_note',
    name: 'Nursing – Progress Note',
  },
  {
    id: 'outcome_measures_discharge_reminder_form',
    name: 'Outcome Measures – Discharge Reminder Form',
  },
  {
    id: 'outcome_measures_follow_up_form',
    name: 'Outcome Measures – Follow-up Form',
  },
  {
    id: 'outcome_measures_intake_form',
    name: 'Outcome Measures – Intake Form',
  },
  {
    id: 'outcome_measures_locator_form',
    name: 'Outcome Measures – Locator Form',
  },
  {
    id: 'outcome_measures_service_summary_form',
    name: 'Outcome Measures – Service Summary Form',
  },
  {
    id: 'physician_order',
    name: 'Physician Order',
  },
  {
    id: 'progress_review',
    name: 'Progress Review',
  },
  {
    id: 'test_results_breathalyzer_test',
    name: 'Test Results – Breathalyzer Test',
  },
  {
    id: 'test_results_communicable_disease_test',
    name: 'Test Results – Communicable Disease Test',
  },
  {
    id: 'test_results_ua_test',
    name: 'Test Results – UA Test',
  },
  {
    id: 'test_results_vital_signs',
    name: 'Test Results – Vital Signs',
  },
  {
    id: 'treatment_plan',
    name: 'Treatment Plan',
  },
  {
    id: 'updated_diagnosis',
    name: 'Updated Diagnosis',
  },
  {
    id: 'updated_medications',
    name: 'Updated Medications',
  },
]

export default DocumentationMultiSelector
