import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Overlay from '../../../components/Overlay'
import SummonOverlay from '../../../components/SummonOverlay'

import { ClaimTaskOverlay } from '../components/ClaimTaskOverlay'
import { ClaimTasks } from '../components/ClaimTasks'

export const ClaimOverlayTasks = () => {
  const match = useRouteMatch()
  const params = useParams()

  return (
    <Overlay.Content css={{ zIndex: 0 }}>
      <div className="!p-4">
        <Card>
          <ClaimTasks
            claimID={params?.id}
            to={(id: any) => `${match.url}/${id}`}
            titleAfter={
              <SummonOverlay overlay={<ClaimTaskOverlay showBackdrop dataID="new" claimID={params?.id} />}>
                <Button label="Add Claim Task" glyph="add" type="primary" size={100} />
              </SummonOverlay>
            }
          />
        </Card>
      </div>
    </Overlay.Content>
  )
}
