import React from 'react'
import dompurify from 'dompurify'

import MultiSearchPillSelector from '../../Forms/Selectors/MultiSearchObject/MultiSearchPillSelector'

const sanitize = dompurify.sanitize

const ServiceCodesSelector = ({ label = 'Service Codes', model = 'service_codes', ...rest }) => {
  return (
    <MultiSearchPillSelector
      isRelations={false}
      showAvatars={false}
      label={label}
      model={model}
      type="insurance_codes.service_code"
      selectTitle={(data, highlights) => {
        let description = data.description
        if (!highlights) return description

        for (let i = 0; i < highlights?.length; i++) {
          if (highlights[i].field === 'description') {
            description = highlights[i].snippet
            break
          }
        }

        return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
      }}
      selectDescription={(data, highlights) => {
        let description = data.code
        if (!highlights) return description

        for (let i = 0; i < highlights?.length; i++) {
          if (highlights[i].field === 'code') {
            description = highlights[i].snippet
            break
          }
        }

        return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
      }}
      {...rest}
    />
  )
}

export default ServiceCodesSelector
