import React from 'react'

import withSettings from '../../hocs/withSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

const CODE_TYPES = {
  cpt: 'CPT',
  hcpc_mod: 'HCPC/MOD',
  hcpcs: 'HCPCS',
}

const columns = (to: Function = () => {}, mainLinkAs: any) => [
  {
    width: 600,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink as={mainLinkAs} to={to(row.original.id)} label={value} />,
  },
  {
    width: 300,
    canToggleVisible: false,
    accessor: 'description',
    Header: 'Description',
    Cell: ({ value }: any) => <TableCell.Truncated value={value} />,
  },
  {
    width: 100,
    accessor: 'code',
    Header: 'Code',
  },
  {
    width: 150,
    accessor: 'price',
    Header: 'Suggested Price',
    Cell: ({ value }: any) => <TableCell.Amount value={value} />,
  },
  {
    width: 160,
    accessor: 'unit',
    Header: 'Unit',
    Cell: ({ value }: any) => <TableCell value={value?.replace('_', ' ')} />,
  },
  {
    width: 160,
    accessor: 'modifier_codes',
    Header: 'Modifier Codes',
    Cell: ({ value }: any) => <TableCell value={value?.join(', ')} />,
  },
  {
    width: 180,
    accessor: 'notes',
    Header: 'Notes',
  },
  {
    width: 140,
    accessor: 'code_type',
    Header: 'Code Type',
    Cell: ({ value }: any) => <TableCell value={CODE_TYPES[value]} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  isLoading: boolean
  localStorageKey: string
  to?: Function
  user?: any
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const InsuranceCodesTable = ({
  data,
  emptyActions,
  isLoading,
  batchActionsConfig,
  localStorageKey,
  to,
  user,
  titleAfter,
  mainLinkAs,
  ...rest
}: Props) => (
  <Table
    title="Insurance Codes"
    titleAfter={titleAfter}
    icon="insurance"
    data={data}
    columns={columns(to, mainLinkAs)}
    isLoading={isLoading}
    emptyActions={emptyActions}
    emptyDescription="No insurance codes added yet"
    batchActionsConfig={batchActionsConfig}
    localStorageKey={localStorageKey}
    {...rest}
  />
)

InsuranceCodesTable.defaultProps = {
  localStorageKey: 'global_insurance_codes',
}

export default withSettings(InsuranceCodesTable)
