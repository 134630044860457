import React from 'react'

import { DateTime } from 'luxon'
import { keyframes } from '@emotion/react'
import { tint } from 'polished'
import padStart from 'lodash/padStart'
import clsx from 'clsx'

import { SHADOW, COLORS } from '../../../theme'

import Card, { CARD_CLASSES } from '../../Card'
import CardHeader from '../../CardHeader'
import CardTitle from '../../CardTitle'

const buildAMHours = () => {
  let hours = [
    {
      id: '00_00_AM',
      hour: 12,
      minute: 0,
      meridiem: 'AM',
    },
    {
      id: '00_15_AM',
      hour: 12,
      minute: 15,
      meridiem: 'AM',
    },
    {
      id: '00_30_AM',
      hour: 12,
      minute: 30,
      meridiem: 'AM',
    },
    {
      id: '00_45_AM',
      hour: 12,
      minute: 45,
      meridiem: 'AM',
    },
  ]

  for (let i = 1; i <= 11; i++) {
    // runs 10 times
    for (let j = 0; j <= 3; j++) {
      hours.push({ id: `${padStart(i, 2, 0)}_${padStart(j * 15, 2, 0)}_AM`, hour: i, minute: j * 15, meridiem: 'AM' })
    }
  }

  return hours
}

const buildPMHours = () => {
  let hours = [
    {
      id: '12_00_PM',
      hour: 12,
      minute: 0,
      meridiem: 'PM',
    },
    {
      id: '12_15_PM',
      hour: 12,
      minute: 15,
      meridiem: 'PM',
    },
    {
      id: '12_30_PM',
      hour: 12,
      minute: 30,
      meridiem: 'PM',
    },
    {
      id: '12_45_PM',
      hour: 12,
      minute: 45,
      meridiem: 'PM',
    },
  ]

  for (let i = 1; i <= 11; i++) {
    // runs 10 times
    for (let j = 0; j <= 3; j++) {
      hours.push({ id: `${padStart(i, 2, 0)}_${padStart(j * 15, 2, 0)}_PM`, hour: i, minute: j * 15, meridiem: 'PM' })
    }
  }

  return hours
}

const objectFromTime = (time: any, year: any, month: any, day: any, timezone: string) => {
  if (time.meridiem === 'AM' && time.hour === 12) {
    return DateTime.fromObject({
      year: year,
      month: month,
      day: day,
      hour: 0,
      minute: time.minute,
      zone: timezone,
    })
  } else if (time.meridiem === 'AM' && time.hour < 12) {
    return DateTime.fromObject({
      year: year,
      month: month,
      day: day,
      hour: time.hour,
      minute: time.minute,
      zone: timezone,
    })
  } else if (time.meridiem === 'PM' && time.hour === 12) {
    return DateTime.fromObject({
      year: year,
      month: month,
      day: day,
      hour: time.hour,
      minute: time.minute,
      zone: timezone,
    })
  } else if (time.meridiem === 'PM' && time.hour < 12) {
    return DateTime.fromObject({
      year: year,
      month: month,
      day: day,
      hour: time.hour + 12,
      minute: time.minute,
      zone: timezone,
    })
  }
}

const HOUR_BRACKETS = [...buildAMHours(), ...buildPMHours()]

export const TimeInputSelectorMenu = ({ onSelect, className, year, month, day, hour, minute, meridiem, timezone }: any) => {
  const bracketRefs = React.useRef({})
  const dataID = `${hour}_${minute}_${meridiem}`

  React.useEffect(() => {
    bracketRefs.current[dataID]?.scrollIntoView({ block: 'center', inline: 'center' })
  }, [])

  return (
    <div
      tabIndex="0"
      className={className}
      css={rootStyles}
      onClick={(event: any) => {
        event.stopPropagation()
      }}
    >
      <div css={contentStyles}>
        {HOUR_BRACKETS.map((time: any) => {
          const date = objectFromTime(time, year, month, day, timezone)

          return (
            <div
              ref={(ref: any) => {
                bracketRefs.current[time?.id] = ref
              }}
            >
              <Card
                variant="variant-list"
                id={time.id}
                key={time.id}
                css={itemStyles}
                onMouseDown={() => onSelect(date)}
                className={clsx({
                  'is-selected': time.id === dataID,
                })}
              >
                <CardHeader>
                  <CardTitle title={date?.toFormat('hh:mm a')} className="card-title" />
                </CardHeader>
              </Card>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const mountAnimation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(-4px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const rootStyles = {
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  background: 'white',

  borderRadius: 7,
  boxShadow: `${SHADOW(10, COLORS.divider)}, 0 0 0 0.5px ${COLORS.divider}`,

  top: '100%',
  width: '100%',
  height: 380,
  overflow: 'hidden',
  outline: 0,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  transformOrigin: 'top center',
  animation: `${mountAnimation} 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,
}

const itemStyles = {
  '&:hover': {
    cursor: 'pointer',
    background: COLORS.hover,

    [`.${CARD_CLASSES.TITLE}`]: {
      color: COLORS.blue,
    },
  },

  '.card-title': {
    fontSize: '1rem',
    fontWeight: 500,
  },

  '&.is-selected': {
    background: COLORS.hover,

    '.card-title': {
      fontWeight: 500,
      color: COLORS.blue,
    },
  },
}

const descriptionStyles = {
  fontSize: '0.9rem',
}

const contentStyles = {
  width: '100%',
  flex: '1 1 auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  position: 'relative',
  background: tint(0.5, COLORS.lightBackground),
}
