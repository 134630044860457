import React from 'react'
import clsx from 'clsx'
import isFinite from 'lodash/isFinite'

import BHSliderInput from '../../../../components/Forms/Slider'

import { getFormElementProps } from '../../utils/functions'

export const SliderInput: React.FC<any> = (props) => {
  const { children, element, className, hoverElement, useParsedConfig, environment } = props

  if (!element || !element.config) return null

  const rootClasses = clsx('SLIDER_INPUT', className)

  const { min = '0', max = '100', step = '1' } = element.config

  const minFloat = parseFloat(min)
  const maxFloat = parseFloat(max)
  const stepFloat = parseFloat(step)

  const minVal = isFinite(minFloat) ? minFloat : 0
  const maxVal = isFinite(maxFloat) ? maxFloat : 100
  const stepVal = isFinite(stepFloat) ? stepFloat : 1
  const inputProps = getFormElementProps(element, { useParsedConfig, environment })

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}
      <BHSliderInput {...inputProps} min={minVal} max={maxVal} step={stepVal} withInputs={true} />
    </div>
  )
}
