import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'

import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Page, Accordion, Form, FormSection, URLInput } from '@behavehealth/components'

const pageConfig = {
  feature: 'settings',
  title: 'Settings',
}

const Settings: React.FC = () => {
  const { resource_id }: any = useParams()

  const { data: program }: any = useGet({
    name: ['program', resource_id],
    url: `/programs/${resource_id}`,
  })

  const { mutateAsync: updateProgram }: any = useUpdate({
    name: ['program', resource_id],
    url: `/programs/${resource_id}`,
    invalidate: ['programs'],
  })

  return (
    <Page {...pageConfig}>
      <Accordion isOpen activateEditMode initialModel={program} title="Forms URLs" onSubmit={updateProgram}>
        <Form>
          <FormSection>
            <URLInput label="Profile URL" model="settings_prefs.profile_url" />
            <URLInput label="Website URL" model="settings_prefs.website_url" />
            <URLInput label="Send Referral URL" model="settings_prefs.send_referral_url" />
            <URLInput label="Send Message URL" model="settings_prefs.send_message_url" />
          </FormSection>
        </Form>
      </Accordion>
    </Page>
  )
}

export default withPageError(withMarketing(Settings, pageConfig))
