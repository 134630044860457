import React from 'react'
import startCase from 'lodash/startCase'

import { fullname } from '../../utils/functions'
import { RELATIONSHIP_TO_CLIENT_CODE } from '../RCM/constants'

import SummonOverlay from '../SummonOverlay'
import InsurancePolicy from '../Overlays/pages/InsurancePolicy'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = [
  {
    width: 220,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'insurance_name',
    Header: 'Company Name',
    Cell: ({ value, row }: any) => (
      <SummonOverlay overlay={<InsurancePolicy initialData={row.original} dataID={row.original.id} type="summon" />}>
        <TableCell.MainLink label={value} />
      </SummonOverlay>
    ),
  },
  {
    width: 110,
    accessor: 'category',
    Header: 'Type',
    Cell: ({ value }: any) => startCase(value),
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'primary',
        label: 'Primary',
      },
      {
        value: 'secondary',
        label: 'Secondary',
      },
      {
        value: 'tertiary',
        label: 'Tertiary',
      },
      {
        value: 'unknown',
        label: 'Unknown',
      },
    ],
  },
  {
    width: 160,
    accessor: 'plan',
    Header: 'Plan Name',
  },
  {
    width: 160,
    accessor: 'member_id',
    Header: 'Member ID',
  },
  {
    width: 160,
    accessor: 'group_id',
    Header: 'Group ID',
  },
  {
    width: 140,
    accessor: 'is_client_policy_holder',
    Header: 'Policy Holder',
    Cell: ({ value, row }: any) => {
      if (value === true) return 'Client'
      if (!row.original.policy_holder) return <TableCell.Empty />

      return `${fullname(row.original.policy_holder)} (${
        RELATIONSHIP_TO_CLIENT_CODE[row?.original?.policy_holder?.relationship_to_customer] || '–'
      })`
    },
    Filter: TableFilter.Select,
    filter: 'boolean',
    filterOptions: [
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No',
      },
    ],
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription?: string
  isLoading: boolean
  localStorageKey: string
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const InsurancePoliciesSummonTable = ({
  data,
  isLoading,
  emptyActions,
  emptyDescription,
  batchActionsConfig,
  localStorageKey,
  titleAfter,
}: Props) => {
  return (
    <Table
      title="Insurance Policies"
      titleAfter={titleAfter}
      icon="insurance"
      data={data}
      columns={columns}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

InsurancePoliciesSummonTable.defaultProps = {
  localStorageKey: 'insurance_policies',
  emptyDescription: 'No insurance policies added yet',
}

export default InsurancePoliciesSummonTable
