import React from 'react'

import { SearchWidget } from '@behavehealth/constructs/Search/SearchWidget'

const SearchPage = () => {
  return (
    <div css={styles.root}>
      <SearchWidget />
    </div>
  )
}

const styles = {
  root: {
    // border: '2px solid dodgerblue',
    display: 'grid',
    gridTemplateRows: '100%',
  },
}

export default SearchPage
