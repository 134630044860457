import React from 'react'

import Chart from '../Chart'

type Props = {
  data: any[]
}

const PieChart: React.FC<Props> = ({ data, ...rest }) => {
  return (
    <Chart
      option={{
        tooltip: {
          trigger: 'item',
        },
        // legend: {
        //   top: '5%',
        //   left: 'center',
        //   orient: 'horizontal',
        //   type: 'scroll',
        // },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            itemStyle: {
              borderRadius: 4,
              borderColor: '#fff',
              borderWidth: 2,
            },
            data,
          },
        ],
      }}
      {...rest}
    />
  )
}

export default PieChart
