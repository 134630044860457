import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { initials } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import HelpTagIframe from '@behavehealth/components/Help/HelpTagIframe'

import { PeerNotesDataTable } from '@behavehealth/constructs/PeerNotes/PeerNotesDataTable'
import { PeerNotesDropdown } from '@behavehealth/constructs/PeerNotes/PeerNotesDropdown'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

type Props = {
  canCreate: boolean
}

const AllPeerNotes: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const tableProps = useDataTable({
    name: ['client', resource_id, 'peer_notes'],
    endpoint: `/residents/${resource_id}/peer_notes`,
    updateDeleteEndpoint: `/peer_notes`,
    // localStorageKey: 'client_peer_notes_v1',
  })

  const { deleteRecords } = tableProps

  const to = React.useMemo(
    () => (id: string, category: string) => ({
      pathname: `${match.url}/${initials(category)}/${id}`,
      parent: match,
    }),
    [match.url],
  )

  const duplicateFormLink = React.useMemo(
    () => (record: any) => {
      let routePrefix = ''

      if (record.category === 'individual') routePrefix = 'i'
      else if (record.category === 'group_note') routePrefix = 'gn'

      return {
        pathname: `${match.url}/${routePrefix}/new`,
        parent: match,
        data: record,
      }
    },
    [],
  )

  return (
    <Grid gap="1rem" columns="100%">
      <PeerNotesDataTable
        {...tableProps}
        to={to}
        testKey="all_peer_notes_table"
        hiddenColumnIds={['client']}
        duplicateFormLink={duplicateFormLink}
        help={<HelpTagIframe id="peer_notes" />}
        getCanSelect={(data: any) =>
          data.status === 'signed_off'
            ? { canSelect: false, message: 'Signed Off records cannot be edited' }
            : data.status === 'closed'
            ? { canSelect: false, message: 'Closed records cannot be edited' }
            : { canSelect: true }
        }
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/peer-notes',
          },
        ]}
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'peer_notes.delete',
            action: async ({ ids }: any) => {
              await deleteRecords(ids.join(','))
            },
          },
        ]}
        emptyActions={canCreate && <PeerNotesDropdown client={client} />}
      />
    </Grid>
  )
}

export default AllPeerNotes
