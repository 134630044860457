import React from 'react'

import { COLORS } from '../../theme'
import { usDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import BillingInvoiceStatus from '../Statuses/BillingInvoiceStatus'
import Glyph from '../Glyph'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'invoice_number',
    Header: 'Invoice',
    Cell: ({ value }: any) => <TableCell value={value} />,
  },
  {
    accessor: 'status',
    Header: 'Status',
    width: 100,
    Cell: ({ value }: any) => <BillingInvoiceStatus status={value} />,
  },
  {
    accessor: 'product',
    Header: 'Service',
    Cell: ({ value }: any) => <TableCell value={value?.name || '–'} />,
  },
  {
    accessor: 'due_date',
    Header: 'Due Date',
    width: 100,
    Cell: ({ value }: any) => <TableCell value={usDate(value, timezone)} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    accessor: 'paid_at',
    Header: 'Paid On',
    width: 100,
    Cell: ({ value }: any) => <TableCell value={usDate(value, timezone)} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    accessor: 'amount',
    Header: 'Cost',
    width: 100,
    Cell: ({ value }: any) => <TableCell.Amount value={value} />,
  },
  {
    accessor: 'invoice_url',
    Header: 'Invoice',
    width: 100,
    Cell: ({ value }: any) => (
      <TableCell.Link isExternal label="View Invoice" glyph={<Glyph glyph="file_pdf" size={16} color={COLORS.blue} />} href={value} />
    ),
  },
  {
    accessor: 'invoice_pdf_url',
    Header: 'Download',
    width: 100,
    Cell: ({ value }: any) => (
      <TableCell.Link isExternal label="Download PDF" glyph={<Glyph glyph="file_pdf" size={16} color={COLORS.blue} />} href={value} />
    ),
  },
]

type Props = {
  data: Object
  hiddenColumns?: string[]
  isLoading: boolean
  isSelectable: boolean
  localStorageKey: string
  timezone: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const BillingInvoicesTable = (props: Props) => {
  const { to, hiddenColumns, data, isLoading, localStorageKey, batchActionsConfig, timezone, titleAfter } = props

  return (
    <Table
      title="Invoices"
      titleAfter={titleAfter}
      icon="financials"
      data={data}
      hiddenColumns={hiddenColumns}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyDescription="No invoices added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

BillingInvoicesTable.defaultProps = {
  localStorageKey: 'billing_invoices',
}

export default withSettings(BillingInvoicesTable)
