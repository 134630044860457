// This component will implement QuickView overlays or links to the EHR records
// once the issue with loading data in an overlay after refreshing is fixed.
// For now the component renders an icon and plain text only.

import React from 'react'

import Icon from '../Icon'
import Glyph from '../Glyph'

const Record = ({ icon, glyph, label }: any) => {
  return (
    <span css={styles.root}>
      {icon && <Icon icon={icon} size={18} css={styles.graphic} />}
      {glyph && <Glyph glyph={glyph} size={18} css={styles.graphic} />}

      <span>{label}</span>
    </span>
  )
}

const RecordQuickLink = ({ record }: any) => {
  if (!record) return null

  const { type, category, subcategory } = record

  if (type === 'task') {
    return <Record icon="tasks" label="Todo" />
  }

  if (type === 'event') {
    return <Record icon="calendar" label="Calendar Event" />
  }

  if (type === 'service') {
    return <Record icon="financials" label="Service" />
  }

  if (type === 'agreement_template') {
    return <Record icon="legal_agreement_alt" label="Agreement Template" />
  }

  if (type === 'employee') {
    return <Record icon="employees" label="Staff Member" />
  }

  if (type === 'organization') {
    return <Record icon="organizations" label="Organization" />
  }

  if (type === 'ua_test') {
    return <Record icon="test_results" label="UA Test" />
  }

  if (type === 'vital_sign') {
    return <Record icon="test_results" label="Vital Signs Record" />
  }

  if (type === 'breathalyzer_test') {
    return <Record icon="test_results" label="Breathalyzer Test" />
  }

  if (type === 'housing_note') {
    return <Record icon="housing_notes" label="Shift Note" />
  }

  if (type === 'communicable_disease_test') {
    return <Record icon="test_results" label="Communicable Disease Test" />
  }

  if (type === 'document') {
    return <Record icon="files" label="File" />
  }

  if (type === 'report') {
    return <Record icon="patient_progress_measure" label="Report" />
  }

  if (type === 'phase') {
    return <Record icon="housing_shifts" label="Program List" />
  }

  if (type === 'intake_application') {
    return <Record icon="applications" label="Application" />
  }

  if (type === 'phases_resident') {
    return <Record icon="checklist" label="Program" />
  }

  if (type === 'agreement') {
    return <Record icon="legal_agreement_alt" label="Agreement" />
  }

  if (type === 'property') {
    return <Record icon="properties" label="Location" />
  }

  if (type === 'client') {
    return <Record icon="clients" label="Client" />
  }

  if (type === 'financial_transaction') {
    if (category === 'credit') return <Record icon="financials" label="Credit" />
    if (category === 'charge') return <Record icon="financials" label="Charge" />
    if (category === 'payment') return <Record icon="financials" label="Payment" />
    if (category === 'recurring') return <Record icon="financials" label="Auto-Repeating Charge" />
  }

  if (type === 'clinical_note') {
    if (category === 'case_management') return <Record icon="clinical_notes" label="Case Management Note" />
    if (category === 'family_session') return <Record icon="clinical_notes" label="Family Session Note" />
    if (category === 'group_note') return <Record icon="clinical_notes" label="Clinical Group Note" />
    if (category === 'individual') return <Record icon="clinical_notes" label="Clinical Individual Note" />

    return <Record icon="clinical_notes" label="Clinical Note" />
  }

  if (type === 'insurance_policy') {
    return <Record icon="insurance" label="Insurance Policy" />
  }

  if (type === 'insurance_evob') {
    return <Record icon="insurance" label="EVOB" />
  }

  if (type === 'insurance_vob') {
    return <Record icon="behave_health" label="VOB" />
  }

  if (type === 'connection') {
    return <Record icon="connections" label="Connection" />
  }

  if (type === 'contact') {
    return <Record icon="contacts" label="Contact" />
  }

  if (type === 'communication') {
    return <Record icon="communications" label="Communication Log" />
  }

  if (type === 'medication_log') {
    return <Record icon="medications" label="Medication Log" />
  }

  if (type === 'med') {
    return <Record icon="medications" label="Medication" />
  }

  if (type === 'data_form') {
    if (category === 'progress_review') {
      return <Record icon="progress_reviews" label="Progress Review" />
    }

    if (category === 'treatment_plan') {
      return <Record icon="treatment_plans" label="Treatment Plan" />
    }

    if (category === 'recovery_coaching') {
      return <Record icon="recovery_coaching" label="Recovery Coaching Form" />
    }

    if (category === 'discharge') {
      return <Record icon="discharge_forms" label="Discharge Summary" />
    }

    if (category === 'treatment_plan') {
      return <Record icon="treatment_plans" label="Treatment Plan" />
    }

    if (category === 'physician_order') {
      return <Record icon="physician_orders" label="Physician Order" />
    }

    if (category === 'intake_form') {
      if (subcategory === 'sober_living_home_agreement') return <Record icon="intake_forms" label="Sober Living Home Agreement" />

      return <Record icon="clinical_assessments" label="Intake Form" />
    }

    if (category === 'clinical_assessment') {
      if (subcategory === 'suicide_assessment') return <Record icon="clinical_assessments" label="Suicide Assessment" />
      if (subcategory === 'biopsychosocial') return <Record icon="clinical_assessments" label="Biopsychosocial Assessment" />
      if (subcategory === 'prescreen') return <Record icon="clinical_assessments" label="Pre-Screen" />
      if (subcategory === 'asam_criteria') return <Record icon="clinical_assessments" label="ASAM Criteria" />
    }

    if (category === 'medical_assessment') {
      if (subcategory === 'history_and_physical') return <Record icon="medical_assessments" label="History & Physical Assessment" />
      if (subcategory === 'history_and_physical_follow_up')
        return <Record icon="medical_assessments" label="History & Physical Follow Up Assessment" />
      if (subcategory === 'psychiatric_assessment') return <Record icon="medical_assessments" label="Psychiatric Assessment" />
    }

    if (category === 'nursing') {
      if (subcategory === 'nursing_assessment') return <Record icon="nursing" label="Nursing Assessment" />
      if (subcategory === 'nursing_reassessment') return <Record icon="nursing" label="Nursing Re-Assessment" />
      if (subcategory === 'cows') return <Record icon="nursing" label="COWS" />
      if (subcategory === 'ciwa') return <Record icon="nursing" label="CIWA" />
      if (subcategory === 'progress_note') return <Record icon="nursing" label="Progress Note" />
      if (subcategory === 'fall_risk_assessment') return <Record icon="nursing" label="Fall Risk Assessment" />
    }
  }

  return null
}

const styles = {
  root: {
    display: 'inline-flex',
    alignItems: 'baseline',
    fontWeight: 600,
    flexWrap: 'nowrap',
    margin: '0.2em 0',
  },

  graphic: {
    marginRight: '0.25em',
    alignSelf: 'center',
  },
}

export default RecordQuickLink
