import React from 'react'
import isUndefined from 'lodash/isUndefined'

import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Notifications from '../../modules/notifications'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import { TreatmentBookImportOverlay } from '../TreatmentBook/TreatmentBookImportOverlay'
import { TreatmentPlanRecordDates } from './form_elements/TreatmentPlanRecordDates'
import { TreatmentPlanRecordStatus } from './form_elements/TreatmentPlanRecordStatus'
import { ReviewOverlay, TreatmentPlanReviews } from './components/TreatmentPlanReviews'

const RootTreatmentPlanProblemOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    queryKey,
  } = useOverlay({
    name: 'treatment-problem',
    endpoint: '/treatment_problems',
    invalidate: 'treatment-problems',
    invalidateKeys: ['treatment-plan-updates', 'treatment-goal'],
    options: props,
  })

  const { canEdit, planId, planCategory, permissionBase, treatmentBookPermissionBase } = props

  const { data: treatmentPlan, isFetching: isLoadingTreatmentPlan } = useGet({
    name: ['treatment_plans', planId],
    url: `/treatment_plans/${planId}`,
    options: { enabled: !!planId },
  })

  const { timezone } = useSettings()

  const [isCompleting, setIsCompleting] = React.useState(false)

  const [name, setName] = React.useState<string>(initialModel?.name || '')
  const [description, setDescription] = React.useState<string>(initialModel?.behavioral_definitions || '')

  const useDiagnoses = treatmentPlan?.settings?.enabled_sections?.diagnoses === true
  const useGoals = treatmentPlan?.settings?.enabled_sections?.goals === true
  const problemsShowSettings = treatmentPlan?.settings?.sections?.problems
  const usePlanReviews = treatmentPlan?.settings?.use_plan_reviews === true

  const showStart = isUndefined(problemsShowSettings?.start_date) || problemsShowSettings?.start_date === true
  const showTarget = isUndefined(problemsShowSettings?.target_date) || problemsShowSettings?.target_date === true
  const showCompletion = isUndefined(problemsShowSettings?.completion_date) || problemsShowSettings?.completion_date === true

  const showDates = showStart || showTarget || showCompletion

  const handleSave = () => {
    saveWithData({
      ...form.current.getFormValue(),
      name,
      behavioral_definitions: description,
    })
  }

  const handleImport = (importData: any) => {
    if (importData.name) setName(importData.name)
    if (importData.behavioral_definitions) setDescription(importData.behavioral_definitions)
  }

  React.useEffect(() => {
    if (!data) return

    setName(data.name)
    setDescription(data.behavioral_definitions)
  }, [data])

  const formKey = React.useMemo(() => {
    if (!data) return null

    const goalKeys = data.treatment_goals?.map((goal: any) => goal.updated_at).join('-')
    const diagnosisKeys = data.treatment_diagnoses?.map((diagnosis: any) => diagnosis.updated_at).join('-')

    return `${data.updated_at}-${goalKeys}-${diagnosisKeys}`
  }, [data])

  if (isOverlayLoading || isLoadingTreatmentPlan) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="treatment_plans" title="Problem" />

      <Overlay.Content>
        <Form
          key={formKey}
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              category: planCategory,
              treatment_plan_id: planId,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              <h2>Problem</h2>

              {treatmentPlan?.settings?.instructions?.problem && (
                <Alert contrast glyph="info">
                  {treatmentPlan.settings.instructions?.problem}
                </Alert>
              )}

              {useDiagnoses && (
                <MultiObjectSelector
                  label="Linked Diagnoses"
                  type="treatment_plan.treatment_diagnoses"
                  dependentValue={planId}
                  selectTitle={(data) => data?.diagnosis?.code}
                  selectDescription={(data) => data?.diagnosis?.description}
                  model="treatment_diagnoses"
                />
              )}

              {useGoals && (
                <MultiObjectSelector
                  label="Linked Goals"
                  type="treatment_plan.treatment_goals"
                  dependentValue={planId}
                  selectTitle={(data) => data.name || data.description}
                  model="treatment_goals"
                />
              )}

              <TreatmentPlanRecordStatus />

              <Input
                label="Name"
                value={name}
                onUpdate={({ value }) => {
                  setName(value)
                }}
              />

              <SmartTextarea
                useQuickText
                useDictation
                label="Behavioral Definitions"
                value={description}
                onUpdate={({ value }) => {
                  setDescription(value)
                }}
              />

              {isEditable && (
                <Permission featureFlagV2="treatment_book" permission={`${treatmentBookPermissionBase}.view`}>
                  <SummonOverlay
                    overlay={<TreatmentBookImportOverlay category={planCategory} importType="problems" onSingleSelect={handleImport} />}
                  >
                    <Button label="Import & Replace from Treatment Book" size={100} glyph="add_file" type="minimal" />
                  </SummonOverlay>
                </Permission>
              )}

              {showDates && (
                <>
                  <Divider />
                  <TreatmentPlanRecordDates showStart={showStart} showTarget={showTarget} showCompletion={showCompletion} />
                </>
              )}

              <Divider />

              {usePlanReviews && (
                <TreatmentPlanReviews
                  planId={planId}
                  isNew={isNew}
                  reference={data}
                  invalidate={queryKey}
                  data={data?.treatment_reviews}
                  canEdit={canEdit}
                  permissionBase={permissionBase}
                  isEditable={isEditable}
                />
              )}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      {canEdit && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                glyph="check"
                label="Save"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={handleSave}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission={`${permissionBase}.edit`}
              />
              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <div className="grid gap-3">
              {data && data.status !== 'completed' && (
                <>
                  <Flex gap="0.75rem">
                    <SummonOverlay
                      portalType="z100"
                      overlay={
                        <ReviewOverlay
                          dataID="new"
                          initialData={{
                            parent_updates: { status: 'completed' },
                          }}
                          planId={planId}
                          reference={data}
                          invalidate={queryKey}
                          canEdit={canEdit}
                          permissionBase={permissionBase}
                        />
                      }
                    >
                      <Button
                        label="Mark as Completed"
                        glyph="tick_circle"
                        color="green"
                        type="default"
                        flex="1 1 auto"
                        isDisabled={isSaving || isDeleting}
                        isLoading={isCompleting}
                        permission={`${permissionBase}.edit`}
                      />
                    </SummonOverlay>
                  </Flex>

                  <Divider className="!my-2" />
                </>
              )}

              <Flex gap="0.75rem">
                <Button
                  label="Edit"
                  glyph="edit"
                  type="default"
                  isDisabled={isSaving || isDeleting}
                  onClick={edit}
                  flex="100 1 auto"
                  permission={`${permissionBase}.edit`}
                />

                <DeleteDialog
                  title="Delete Problem?"
                  message="Are you sure you want to delete this Problem? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button
                    fullWidth
                    label="Delete"
                    type="default"
                    glyph="delete"
                    color="red"
                    isLoading={isDeleting}
                    isDisabled={isSaving || isDeleting}
                    permission={`${permissionBase}.edit`}
                  />
                </DeleteDialog>
              </Flex>
            </div>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const TreatmentPlanProblemOverlay = withOverlayError(RootTreatmentPlanProblemOverlay)
