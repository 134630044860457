import React from 'react'
import { tint } from 'polished'
import { useParams } from 'react-router-dom'
import * as HoverCard from '@radix-ui/react-hover-card'

import { COLORS } from '../../../theme'
import { usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import DataList from '../../../components/DataList'
import Icon from '../../../components/Icon'
import Flex from '../../../components/Flex'
import Button from '../../../components/Button'
import IntakeStatus from '../../../components/Statuses/IntakeStatus'
import RoadmapBar from '../../../components/Roadmap/RoadmapBar'

import { CardTooltip } from '../components/CardTooltip'
import { CardTooltipContent } from '../components/CardTooltipContent'
import { ClientHeader } from '../components/ClientHeader'

export const TreatmentEpisode = ({ data, client }: any) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const { timezone } = useSettings()
  const { resource_id }: any = useParams()

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  if (!data) return null

  const startDate = data.admitted_at || data.estimated_arrival
  const endDate = data.discharged_at || data.estimated_discharge

  return (
    <CardTooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
      <HoverCard.Trigger asChild>
        <RoadmapBar
          minWidth={7}
          background={tint(0.7, COLORS.vividBlue)}
          startDate={startDate}
          endDate={endDate}
          onClick={openTooltip}
          asGradient={!endDate}
          width={!endDate && 50}
          overflow="hidden"
        >
          <Flex nowrap centerY gap="0.5rem" css={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
            <Icon icon="treatment_episodes" size={15} />
            <div css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.title || 'Treatment Episode'}</div>
          </Flex>
        </RoadmapBar>
      </HoverCard.Trigger>

      <CardTooltipContent>
        {/* <ClientHeader client={client} /> */}

        <Flex centerY css={{ padding: '0.5rem 1rem 0.25rem' }}>
          <Icon icon="treatment_episodes" size={20} className="!mr-2" />
          <div className="text-[1.1rem] font-[700]">{data.title || 'Treatment Episode'}</div>
          <Button
            label="Open"
            size={100}
            link={`/clients/${resource_id}/treatment-episodes/${data.id}`}
            css={{ marginLeft: 'auto' }}
            target="_blank"
          />
        </Flex>

        <DataList isCompact withPadding labelWidth={120}>
          <DataList.Item label="Status" value={<IntakeStatus status={data.status} />} />

          {data.admitted_at ? (
            <DataList.Item label="Admit Date" value={usDateTime(data.admitted_at, timezone)} />
          ) : (
            <DataList.Item label="Est. Arrival Date" value={usDateTime(data.estimated_arrival, timezone)} />
          )}

          {data.discharged_at ? (
            <DataList.Item label="Discharge Date" value={usDateTime(data.discharged_at, timezone)} />
          ) : (
            <DataList.Item label="Est. Discharge Date" value={usDateTime(data.estimated_discharge, timezone)} />
          )}
        </DataList>
      </CardTooltipContent>
    </CardTooltip>
  )
}
