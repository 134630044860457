import React from 'react'
import { Input } from './Input'
import { withFormContext } from './context'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { isBlank } from '../../utils/functions'

import LinkValue from '../LinkValue'

import { EmailDropdownMenu } from '../../constructs/Common/EmailDropdownMenu'

class EmailInput extends Input {
  readOnlyRender = () => {
    if (isBlank(this.state.value)) return DEFAULT_EMPTY_VALUE

    return (
      <div className="flex items-center">
        <LinkValue href={`mailto:${this.state.value}`} value={this.state.value} glyph={this.props.glyph} />
        <div className="ml-2">
          <EmailDropdownMenu email={this.state.value} />
        </div>
      </div>
    )
  }
}

EmailInput.defaultProps = {
  type: 'email',
  glyph: 'email',
  autoCapitalize: 'off',
  defaultValidations: {
    format: {
      // eslint-disable-next-line no-useless-escape
      pattern:
        /^([\s]+)?$|^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      message: 'Please enter a valid email (e.g. name@email.com)',
    },
  },
  validateOn: 'blur',
  quickLinksVendors: ['hubspot', 'google', 'gmail', 'google_calendar', 'helpscout', 'postmark'],
}

export default withFormContext(EmailInput)
