import React from 'react'

import NavGroup from '../NavGroup'
import NavItem from '../NavItem'
import useStore from '../../modules/store'

type Props = {
  organizationID: string
  id?: string
}

const OrganizationNavItems = (props: Props) => {
  const { organizationID, id } = props

  const basePath = props.basePath || `/community/organizations/${organizationID || id}`
  const isBehave = useStore((state) => state?.isBehave)

  return (
    <>
      <NavItem feature="bridge" label="Profile" to={`${basePath}/profile`} featureFlagV2="orgs_forms_profile" />
      <NavItem feature="bridge" label="Website" to={`${basePath}/website`} featureFlagV2="orgs_forms_profile" />

      <NavGroup label="General">
        <NavItem feature="general_info" label="General Info" to={`${basePath}/general-info`} />
        <NavItem showMarketing feature="todos" label="To-Do" to={`${basePath}/todo`} permission="todos.view" featureFlagV2="todos" />
        <NavItem
          showMarketing
          label="Calendar"
          to={`${basePath}/calendar`}
          feature="calendar"
          permission="events.view"
          featureFlagV2="calendar"
        />
        <NavItem
          showMarketing
          feature="files"
          label="Files"
          to={`${basePath}/files`}
          permission="clients.files.view"
          featureFlagV2="files"
        />
        <NavItem isDev feature="properties" label="Locations" to={`${basePath}/locations`} />
        <NavItem
          feature="activity_timeline"
          label="Activity Timeline"
          to={`${basePath}/activity-timeline`}
          permission="activity_timeline.view"
        />
        <NavItem feature="settings" label="Settings" to={`${basePath}/settings`} featureFlagV2={isBehave} />
      </NavGroup>

      <NavGroup label="Forms">
        <NavItem
          feature="form_submissions"
          label="Form Inbox"
          to={`${basePath}/form-submissions`}
          featureFlagV2="form_submissions"
          permission="form_submissions.view"
        />
        <NavItem
          feature="form_submissions"
          label="Send Referral"
          to={`${basePath}/send-referral`}
          featureFlagV2="orgs_forms_send_referral"
        />
        <NavItem feature="form_submissions" label="Send Message" to={`${basePath}/send-message`} featureFlagV2="orgs_forms_send_message" />
      </NavGroup>

      <NavGroup label="Org Coordination">
        <NavItem
          showMarketing
          feature="organization_agreements"
          label="Agreements"
          to={`${basePath}/agreements`}
          permission="organization_agreements.view"
          featureFlagV2="organization_agreements"
        />
        <NavItem
          showMarketing
          feature="contacts"
          label="Contacts"
          to={`${basePath}/contacts`}
          permission="contacts.view"
          featureFlagV2="contacts"
        />
        <NavItem
          showMarketing
          feature="communications"
          label="Communications"
          to={`${basePath}/communications`}
          permission="communications.view"
          featureFlagV2="communication_logs"
        />
      </NavGroup>

      <NavGroup label="Notes">
        <NavItem
          showMarketing
          feature="shift_notes"
          label="Shift Notes"
          to={`${basePath}/shift-notes`}
          permission="shift_notes.view"
          featureFlagV2="shift_notes"
        />
        <NavItem
          showMarketing
          feature="custom_notes"
          label="Custom Notes"
          to={`${basePath}/custom-notes`}
          permission="organization_custom_notes.view"
          featureFlagV2="organization_custom_notes"
        />
      </NavGroup>

      <NavGroup label="Financials">
        <NavItem
          feature="financials"
          label="Financials"
          to={`${basePath}/financials`}
          permission="ledger.view"
          featureFlagV2="organization_financials"
        />
      </NavGroup>
    </>
  )
}

export default OrganizationNavItems
