import React from 'react'
import startCase from 'lodash/startCase'

import { address, isFuture } from '../../../utils/functions'
import { ICONS } from '../../../theme'
import { useOverlay } from '../../../hooks/useOverlay'
import { useSettings } from '../../../hooks/useSettings'
import { withOverlayError } from '../../../hocs/withOverlayError'
import { RECURRING_DAYS } from '../../../utils/recurrence'

import Accordions from '../../Accordions'
import Alert from '../../Alert'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ColorSelector from '../../Forms/ColorSelector'
import ContextShow from '../../Forms/ContextShow'
import DateInput from '../../Forms/DateInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import EventCategorySelect from '../../Elements/EventCategorySelect'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Label from '../../Label'
import Links from '../../Forms/Links'
import MiniRichTextEditor from '../../Forms/MiniRichTextEditor'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import OverlayLoader from '../../OverlayLoader'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Section from '../../Section'
import Select from '../../Forms/Select'
import Textarea from '../../Forms/Textarea'
import TimeInputSelector from '../../Forms/TimeInputSelector/TimeInputSelector'
import URLInput from '../../Forms/URLInput'

const EventTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialData,
    isDeleting,
    isEditable,
    isEmpty,
    isInvalid,
    isLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'event-template',
    endpoint: '/event_templates',
    options: props,
  })

  const { allowEditing = true } = props
  const { timezone } = useSettings()

  const [recurringFrequency, setRecurringFrequency]: any = React.useState('never')
  const [startedAtDate, setStartedAtDate]: any = React.useState(null)
  const [repeatFromDate, setRepeatFromDate]: any = React.useState(null)

  const startDate = recurringFrequency === 'never' ? startedAtDate : repeatFromDate

  if (isLoading || isEmpty) {
    return <OverlayLoader position="right" maxWidth={37} />
  }

  return (
    <Overlay showBackdrop={isEditable} maxWidth={37} onClose={close}>
      <Overlay.Header icon="calendar" title="Calendar Template " />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            ...data,
            ...initialData,
            recurringFrequency: 'never',
          }}
          onValidationUpdate={onValidationUpdate}
          timezone={timezone}
          isEditable={isEditable}
          linked={{
            event_type: 'event',
          }}
        >
          <Section>
            <FormSection layout="vertical" maxWidth="100%">
              <Input
                autoFocus
                label="Title"
                model="title"
                validations={{
                  presence: {
                    message: 'Please enter an event name',
                  },
                }}
              />

              <Flex gap="1rem">
                <div className="!flex-auto">
                  <EventCategorySelect label="Event Category" model="category" defaultValue="general" />
                </div>

                <ColorSelector label="Color" model="color" />
              </Flex>

              <MiniRichTextEditor label="Public Description" model="public_description" />
            </FormSection>
          </Section>

          <Section>
            <Accordions>
              <Accordions.Item isOpen title="Date & Time">
                <FormSection>
                  <Select
                    // className="!max-w-[150px]"
                    label="Repeat"
                    model="recurring_frequency"
                    defaultValue="never"
                    onUpdate={(model: any) => {
                      setRecurringFrequency(model.value)
                    }}
                  >
                    <Option label="Never" value="never" />
                    <Option label="Daily" value="daily" />
                    <Option label="Weekly" value="weekly" />
                    <Option label="Bi-Weekly" value="biweekly" />
                    <Option label="Monthly" value="monthly" />
                    <Option label="Quarterly" value="quarterly" />
                    <Option label="Yearly" value="yearly" />
                  </Select>

                  {/* <ContextShow when="recurring_frequency" is="never">
                    <DateInput
                      defaultToNow
                      label="Date"
                      model="started_at"
                      onUpdate={({ date }: any) => setStartedAtDate(date)}
                      validations={{
                        presence: {
                          message: 'Please select an event date',
                        },
                      }}
                    />
                  </ContextShow> */}

                  {/* {recurringFrequency !== 'never' && (
                    <Flex gap="1rem">
                      <DateInput
                        defaultToTomorrow
                        label="Repeat From"
                        model="repeat_from"
                        onUpdate={({ date }: any) => {
                          setRepeatFromDate(date)
                        }}
                        validations={{
                          presence: {
                            message: 'Please select when the repeating event should start',
                          },
                        }}
                      />

                      <DateInput
                        label="Repeat Until"
                        model="repeat_until"
                        validations={{
                          presence: {
                            message: 'Please select when the repeating event should end',
                          },
                          datetime: {
                            earliest: repeatFromDate,
                            message: 'Please enter a future date',
                          },
                        }}
                      />
                    </Flex>
                  )} */}

                  {/* {parseInt(repeatFromDate?.day) >= 29 && (
                    <ContextShow when="recurring_frequency" within={['monthly', 'quarterly']}>
                      <Alert glyph="info">This event will be repeat only on months with a {repeatFromDate.day} day.</Alert>
                    </ContextShow>
                  )} */}

                  <ContextShow when="recurring_frequency" within={['weekly', 'biweekly']}>
                    <div>
                      <Label label="Repeat On" />
                      <Flex nowrap>
                        {RECURRING_DAYS.weekly.map((day: string) => (
                          <Checkbox
                            key={day}
                            label={day.charAt(0)}
                            model={`weekly_repeat_days.${day?.toLowerCase()}`}
                            css={styles.dayCheckbox}
                          />
                        ))}
                      </Flex>
                    </div>
                  </ContextShow>

                  {/* <ContextShow when="is_all_day" is={false}>
                    <FormSection horizontal>
                      <TimeInputSelector
                        grow
                        defaultToNow
                        label="Start Time"
                        model="start_time"
                        validations={{
                          presence: {
                            message: 'Please select when the event starts',
                          },
                        }}
                      />

                      <TimeInputSelector
                        grow
                        defaultToInOneHour
                        label="End Time"
                        model="end_time"
                        validations={{
                          presence: {
                            message: 'Please select when the event ends',
                          },
                        }}
                      />
                    </FormSection>
                  </ContextShow> */}

                  <Checkbox label="All Day Event" model="is_all_day" defaultValue={false} />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Staff">
                <FormSection>
                  <MultiObjectSelector
                    model="employees"
                    type="employees"
                    icon={ICONS.employees}
                    selectTitle={(data: any) => data.name}
                    selectDescription={(data: any) => startCase(data.position)}
                  />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Clients">
                <FormSection>
                  <Select label="Event Type" model="meeting_type" defaultValue="one_to_one" className="!flex-1">
                    <Option label="One-to-One" value="one_to_one" />
                    <Option label="Group Event" value="group_meeting" />
                  </Select>

                  <ContextShow when="meeting_type" is="group_meeting">
                    <RadioGroup label="Add Client Attendees" model="clients_import_type" layout="vertical-dense" defaultValue="dynamic">
                      <Radio label="Import dynamically from Programs / Properties" value="dynamic" />
                      <Radio label="Select manually from list" value="manual" />
                    </RadioGroup>

                    <ContextShow when="clients_import_type" is="dynamic">
                      <Alert glyph="info">
                        Clients from the selected programs and properties will be imported as attendees at the time of the event.
                      </Alert>

                      <MultiObjectSelector
                        label="Programs"
                        type="programs"
                        model="imported_programs"
                        icon={ICONS.checklist}
                        selectTitle={(data: any) => data.name}
                      />

                      <MultiObjectSelector
                        label="Locations"
                        model="imported_houses"
                        type="properties"
                        icon={ICONS.properties}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => address(data.address)}
                      />
                    </ContextShow>
                  </ContextShow>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Notifications">
                <FormSection>
                  <Alert glyph="notification">Send a notification alert via SMS / Email to the people above</Alert>

                  <CheckboxGroup layout="vertical-dense">
                    <Checkbox defaultChecked label="Send SMS Notification" model="should_sms" />
                    <Checkbox defaultChecked label="Send Email Notification" model="should_email" />
                  </CheckboxGroup>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Reminders">
                <FormSection>
                  <Checkbox label="Send SMS and Email Reminders" model="should_send_reminders" />

                  <ContextShow when="should_send_reminders" is={true}>
                    <div className="!pl-6">
                      <CheckboxGroup
                        label="Send Reminders Options"
                        layout="vertical-dense"
                        validations={{
                          presence: {
                            message: 'Please select at least one option',
                          },
                        }}
                      >
                        <Checkbox label="Three days before the event" model="send_reminders.three_days" />
                        <Checkbox label="Two days before the event" model="send_reminders.two_days" />
                        <Checkbox label="One day before the event" model="send_reminders.one_day" />
                        <Checkbox label="Three hours before the event" model="send_reminders.three_hours" />
                        <Checkbox label="Two hours before the event" model="send_reminders.two_hours" />
                        <Checkbox label="One hour before the event" model="send_reminders.one_hour" />
                        <Checkbox label="15 min before event" model="send_reminders.15_minutes" />
                      </CheckboxGroup>
                    </div>
                  </ContextShow>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Place">
                <FormSection>
                  <Select label="Meeting Place" model="meeting_place" defaultValue="none" className="!flex-1">
                    <Option label="None" value="none" />
                    <Option label="Online" value="online" />
                    <Option label="Housing" value="property" />
                    <Option label="Office" value="office" />
                    <Option label="Organization" value="organization" />
                    <Option label="Other" value="other" />
                  </Select>

                  <ContextShow when="meeting_place" is="online">
                    <URLInput
                      label="Meeting Link"
                      model="meeting_url"
                      validations={{
                        presence: {
                          message: 'Please enter a meeting link',
                        },
                      }}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" is="property">
                    <ObjectSelector
                      icon={ICONS.properties}
                      label="Housing"
                      type="properties"
                      model="house"
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                      validations={{
                        presence: {
                          message: 'Please select a location',
                        },
                      }}
                    />

                    <ObjectSelector
                      icon={ICONS.rooms}
                      label="Room (optional)"
                      type="property.rooms"
                      model="room"
                      dependent="house_id"
                      disableUnless="house_id"
                      selectTitle={(data: any) => data.name}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" is="office">
                    <ObjectSelector
                      icon={ICONS.properties}
                      label="Office"
                      type="offices"
                      model="house"
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                      validations={{
                        presence: {
                          message: 'Please select a location',
                        },
                      }}
                    />

                    <ObjectSelector
                      icon={ICONS.rooms}
                      label="Room (optional)"
                      type="property.rooms"
                      model="room"
                      dependent="house_id"
                      disableUnless="house_id"
                      selectTitle={(data: any) => data.name}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" is="organization">
                    <ObjectSelector
                      label="Organization"
                      model="organization"
                      type="organizations"
                      icon={ICONS.organizations}
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                      validations={{
                        presence: {
                          message: 'Please select an organization',
                        },
                      }}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" not="none">
                    <MiniRichTextEditor label="More Details" model="place_details" />
                  </ContextShow>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Links">
                <Links model="urls" />
              </Accordions.Item>

              <Accordions.Item title="Notes">
                <MiniRichTextEditor label="Internal Notes" model="internal_notes" />
              </Accordions.Item>
            </Accordions>
          </Section>
        </Form>
      </Overlay.Content>

      {allowEditing && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                onClick={save}
                isLoading={isSaving}
                isDisabled={isInvalid}
                permission="calendar_templates.create"
              />
              <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
            </>
          )}

          {!isEditable && (
            <>
              <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" permission="calendar_templates.edit" />

              <DeleteDialog
                title="Delete To-Do Template?"
                message="Are you sure you want to delete this to-do template? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} permission="calendar_templates.delete" />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const styles = {
  dayCheckbox: {
    width: 'auto !important',
    flex: '1 1 auto !important',
    marginLeft: 4,
    marginRight: 4,

    '@media (max-width: 550px)': {
      marginLeft: 2,
      marginRight: 2,
    },

    '&:first-of-type': { marginLeft: 0 },
    '&:last-of-type': { marginRight: 0 },

    label: {
      fontSize: '0.9rem',

      '@media (max-width: 550px)': {
        padding: '0.5em 0.4em',
        flexDirection: 'column',
      },
    },

    '.check-element': {
      width: 18,
      height: 18,

      '@media (max-width: 550px)': {
        margin: '0 0 0.4rem 0',
      },
    },
  },
}

export default withOverlayError(EventTemplateOverlay)
