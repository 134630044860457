import React from 'react'
import { useSelector } from 'react-redux'

import { useAPIwithNoAbort } from '../../../hooks/useAPI'

import Card from '../../Card'
import Tabs from '../../Tabs'

import ChargesTable from '../../Tables/ChargesTable'
import CreditsTable from '../../Tables/CreditsTable'
import RefundsTable from '../../Tables/RefundsTable'
import WriteOffsTable from '../../Tables/WriteOffsTable'
import PaymentsTable from '../../Tables/PaymentsTable'
import TransactionsTable from '../../Tables/TransactionsTable'

import PayerTransactionStats from './PayerTransactionStats'

const PayerTransactions = ({ url, useStats, className }: any) => {
  const transactions = useSelector((state: any) => state.data['payer_transactions']?.data)
  const transactionsLoading = useSelector((state: any) => state.data['payer_transactions']?.loading)
  const [transactionsCategory, setTransactionsCategory] = React.useState('subcharge,payment,credit,refund,write_off')

  useAPIwithNoAbort('payer_transactions', `${url}?category=${transactionsCategory}`)

  return (
    <div className={className}>
      {useStats && <PayerTransactionStats name="payer_transactions" url={url} />}

      <Card className="!mt-4">
        <Tabs defaultTab="all_transactions">
          <Tabs.List caps className="!pl-4">
            <Tabs.Item
              label="All Transactions"
              name="all_transactions"
              onSelected={() => setTransactionsCategory('subcharge,payment,credit,refund,write_off')}
            />
            <Tabs.Divider />
            <Tabs.Item label="Charges" name="charges" onSelected={() => setTransactionsCategory('subcharge')} />
            <Tabs.Item label="Payments" name="payments" onSelected={() => setTransactionsCategory('payment')} />
            <Tabs.Item label="Credits" name="credits" onSelected={() => setTransactionsCategory('credit')} />
            <Tabs.Item label="Refunds" name="refunds" onSelected={() => setTransactionsCategory('refund')} />
            <Tabs.Item label="Write-Off's" name="write-offs" onSelected={() => setTransactionsCategory('write_off')} />
          </Tabs.List>

          <Tabs.Panels>
            <Tabs.Panel name="all_transactions">
              <TransactionsTable isLoading={transactionsLoading} data={transactions} />
            </Tabs.Panel>

            <Tabs.Panel name="charges">
              <ChargesTable isLoading={transactionsLoading} data={transactions} />
            </Tabs.Panel>

            <Tabs.Panel name="payments">
              <PaymentsTable isLoading={transactionsLoading} data={transactions} />
            </Tabs.Panel>

            <Tabs.Panel name="credits">
              <CreditsTable isLoading={transactionsLoading} data={transactions} />
            </Tabs.Panel>

            <Tabs.Panel name="refunds">
              <RefundsTable isLoading={transactionsLoading} data={transactions} />
            </Tabs.Panel>

            <Tabs.Panel name="write-offs">
              <WriteOffsTable isLoading={transactionsLoading} data={transactions} />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </Card>
    </div>
  )
}

PayerTransactions.defaultProps = {
  useStats: true,
}

export default PayerTransactions
