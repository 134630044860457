import React, { Component } from 'react'
import { withFormContext } from '../../../../Forms/context'

import DateTimeInput from '../../../../Forms/DateTimeInput'
import Field from '../../../../Forms/Field'
import FormSection from '../../../../Forms/FormSection'
import Grid from '../../../../Grid'
import Input from '../../../../Forms/Input'
import SmartTextarea from '../../../../Forms/SmartTextarea'

class VitalSignsForm extends Component {
  render() {
    const { requireHeight, showBMI, showComments, showDateTime, showHeight, showPainScale, showWeight } = this.props

    return (
      <Grid gap={16}>
        <FormSection
          labelWidth="120px"
          labelAlign="right"
          layouts={[
            { width: 480, layout: 'horizontal' },
            { width: 0, layout: 'vertical' },
          ]}
        >
          {showDateTime && <DateTimeInput label="Date & Time" model="data.bio_medical.recorded_at" defaultToNow />}

          <Field label="Blood Pressure" labelWidth="120px" labelAlign="right">
            <Grid columns="repeat(3, max-content)" css={{ width: 'fit-content', alignItems: 'center' }}>
              <Input type="number" model="data.bio_medical.blood_pressure_systolic" size={4} />
              <div css={{ paddingLeft: '0.75rem', paddingRight: '0.75rem' }}>/</div>
              <Input type="number" model="data.bio_medical.blood_pressure_diastolic" suffix="mm Hg" size={4} />
            </Grid>
          </Field>

          <Input label="Temp." type="number" model="data.bio_medical.temperature" suffix="°F" size={4} />
          <Input label="O2 Sat" type="number" model="data.bio_medical.oxygen_saturation" suffix="%" size={4} />
          <Input label="Pulse" type="number" model="data.bio_medical.pulse" suffix="beats / min" size={4} />
          <Input label="Respiration" type="number" model="data.bio_medical.respiration" suffix="breaths / min" size={4} />

          {showPainScale && (
            <Input label="Pain Scale" type="number" model="data.bio_medical.pain_scale" suffix="/ 10" step={1} min={0} max={10} size={4} />
          )}

          {showWeight && <Input label="Weight" model="data.bio_medical.weight" suffix="pounds" size={4} />}

          {showHeight && (
            <Field
              label="Height"
              labelWidth="120px"
              labelAlign="right"
              isRequired={requireHeight} //TODO(Robert): Can we show the required attribute in this case?
            >
              <Grid columns="repeat(2, max-content)" gapX={0.75} css={{ width: 'fit-content', alignItems: 'center' }}>
                <Input type="number" model="data.bio_medical.height_ft" suffix="ft" size={4} />
                <Input type="number" model="data.bio_medical.height_in" suffix="in" size={4} />
              </Grid>
            </Field>
          )}
        </FormSection>

        {showBMI && <Input label="BMI" model="data.bio_medical.bmi" size={10} />}

        {showComments && (
          <FormSection layout="vertical">
            <SmartTextarea useDictation useQuickText label="Comments" model="data.bio_medical.comments" />
          </FormSection>
        )}
      </Grid>
    )
  }
}

VitalSignsForm.defaultProps = {
  showBMI: true,
  showComments: true,
  showDateTime: true,
  showHeight: true,
  showPainScale: true,
  showWeight: true,
}

export default withFormContext(VitalSignsForm)
