import { createStore } from 'zustand'
import { v4 as uuid } from 'uuid'
import isUndefined from 'lodash/isUndefined'
import lodashSet from 'lodash/set'
import merge from 'lodash/merge'
import produce from 'immer'
import size from 'lodash/size'

import { arrayToMap, isDefined } from '../../../utils/functions'
import { COLUMNS_CONFIG, DEBUG } from './constants'
import { formatCellCoords } from './functions'

export const initializeStore = (initProps: any) => {
  const DEFAULT_PROPS: any = {
    activeCellCoords: null,
    didInitializeData: false,
    formData: {},
    isEditingActiveCell: false,
    wrapping: 'wrap',
    showInvalid: false,
    editingCellCoords: null,
    allow: '',
  }

  const { allow = '', initialRowsCount, defaultNewRow } = initProps

  const allowCreate = allow && allow.includes('create')
  const allowUpdate = allow && allow.includes('update')
  const allowDelete = allow && allow.includes('delete')

  return createStore<any>()((set, get) => ({
    ...DEFAULT_PROPS,
    ...initProps,

    allowCreate,
    allowUpdate,
    allowDelete,

    editDisabledColumns: initProps.editDisabledColumns || [],

    initializeData: (initialData: any) => {
      const didInitializeData = get().didInitializeData

      if (DEBUG) console.debug('✅ [Worksheet] initializeData() INPUT\n', { initialData })

      if (didInitializeData) {
        if (DEBUG) console.debug('🚨 [Worksheet] initializeData() SKIPPED as data was already initialized')
        return
      }

      const result: any = {
        initialData,
        dataIds: [],
        dataMap: {},
        didInitializeData: true,
      }

      if (isUndefined(initialData)) {
        for (let i = 0; i < initialRowsCount; i++) {
          const _id = uuid()

          result.dataIds.push(_id)
          result.dataMap[_id] = { _id }
        }
      } else {
        result.initialData = initialData

        // construct dataMap from initialData
        if (Array.isArray(initialData) && size(initialData) > 0) {
          for (const row of initialData) {
            const _id = row?._id || uuid()

            result.dataIds.push(_id)
            result.dataMap[_id] = { ...row, _id }
          }
        }
      }

      if (DEBUG) console.debug('✅ [Worksheet] initializeData() OUTPUT\n', result)

      set(result)
    },

    processColumns: (columns: any) => {
      if (DEBUG) console.debug('🧵 [Worksheet] processColumns() INPUT\n', { columns })

      const columnsCount = size(columns)

      if (columnsCount === 0) {
        throw new Error(`Cannot initialize Worksheet without a "columns" array`)
      }

      const columnsMap: any = {}
      const columnIds: any = []

      // Process columns
      for (let i = 0; i < columnsCount; i++) {
        const column = columns[i]
        const config = COLUMNS_CONFIG[column.type] || COLUMNS_CONFIG.default

        const id = column?._id || column?.id || column?.model

        if (!id) {
          throw new Error(`Column #${i + 1} does not have an ID or a model defined \n ${JSON.stringify({ column }, null, 2)}`)
        } else if (columnIds.includes(id)) {
          throw new Error(`Found duplicate column ID ${id}`)
        }

        columnIds.push(id)

        columnsMap[id] = {
          ...config,
          ...column,
          id,
          width: column?.width || config?.width || 200,
        }
      }

      if (DEBUG) console.debug('🧵 [Worksheet] processColumns() OUTPUT\n', { columnsMap, columnIds })

      set({ columnsMap, columnIds })
    },

    // Rows
    addRow: () => {
      const dataMap = get().dataMap
      const dataIds = get().dataIds

      const allowCreate = get().allowCreate

      if (!allowCreate) {
        if (DEBUG) console.debug('🚨 [Worksheet] addRow() BLOCKED. Update `allow` prop to include `create` if you wish to create rows.')
        return
      }

      const newId = uuid()

      const newDataIds = produce(dataIds, (draft: any) => {
        draft.push(newId)
      })

      const newDataMap = produce(dataMap, (draft: any) => {
        const newRow = typeof defaultNewRow === 'function' ? defaultNewRow(dataMap, dataIds) : defaultNewRow

        draft[newId] = { ...newRow, _id: newId }
      })

      set({ dataMap: newDataMap, dataIds: newDataIds })
    },

    deleteRowByIndex: (index: number) => {
      const dataMap = get().dataMap
      const dataIds = get().dataIds

      const allowDelete = get().allowDelete

      if (!allowDelete) {
        if (DEBUG)
          console.debug('🚨 [Worksheet] deleteRowByIndex() BLOCKED. Update `allow` prop to include `delete` if you wish to delete rows.')
        return
      }

      const id = dataIds[index]

      const newDataIds = produce(dataIds, (draft: any) => {
        draft.splice(index, 1)
      })

      const newDataMap = produce(dataMap, (draft: any) => {
        delete draft[id]
      })

      set({ dataMap: newDataMap, dataIds: newDataIds })
    },

    // Cells
    selectCellByCoords: (coords: string) => {
      const cellsMap = get().cellsMap
      const activeCellCoords = get().activeCellCoords
      const cell = cellsMap[coords]

      if (!cell) {
        console.error(`Cell ${coords} could not be found in cellsMap`)
        return
      }

      if (activeCellCoords === coords) {
        if (DEBUG) console.debug(`🚨 [Worksheet] Cell ${coords} is already selected\n`, { coords, cell })
        return
      }

      if (cell?.column?.disableEdit) {
        if (DEBUG) console.debug(`🚨 [Worksheet] Cell ${coords} cannot be selected/edited\n`, { coords, cell })
        return
      }

      if (DEBUG) console.debug(`✅ [Worksheet] Selected Cell ${coords}\n`, { coords, cell })

      set({ activeCellCoords: coords })
    },

    editCellByCoords: (coords: string) => {
      set({ editingCellCoords: coords })
    },

    editActiveCell: () => {
      const cellsMap = get().cellsMap
      const activeCellCoords = get().activeCellCoords

      if (!cellsMap?.[activeCellCoords]) {
        console.error(`Cell with coords ${activeCellCoords} could not be found in cellsMap`)
        return
      }

      if (DEBUG) console.debug('✅ [Worksheet] Enter Cell Edit\n', { activeCellCoords, cell: cellsMap[activeCellCoords] })

      set({ isEditingActiveCell: true })
    },

    clearAllSelections: () => {
      if (DEBUG) console.debug('✅ [Worksheet] Cleared all selections')

      set({ isEditingActiveCell: false, activeCellCoords: null })
    },

    copyPasteCellAcrossColumn: ({ cell, value }: any) => {
      if (!cell?.column?.model || isUndefined(value)) {
        if (DEBUG) console.debug(`🚨 [Worksheet] Could not paste cell value ${value} across column`)
        return
      }

      const dataIds = get().dataIds
      const dataMap = get().dataMap

      if (size(dataIds) === 0 || size(dataMap) === 0) return

      const newDataMap = produce(dataMap, (draft: any) => {
        for (const id of dataIds) {
          lodashSet(draft, `${id}.${cell.column.model}`, value)
        }
      })

      set({ dataMap: newDataMap })

      if (DEBUG) console.debug(`✅ [Worksheet] Copy-pasted cell "${cell?.coords}" across column`, { value })
    },

    // Form
    toggleShowInvalid: () => {
      set({ showInvalid: !get().showInvalid })
    },

    updateField: ({ model, value }: any) => {
      const dataMap = get().dataMap

      if (!dataMap || !model || isUndefined(value)) return

      const newData = produce(dataMap, (draft: any) => {
        lodashSet(draft, model, value)
      })

      set({ dataMap: newData })

      if (DEBUG) console.debug(`✅ [Worksheet] updateField()`, { model, value })
    },

    updateRow: (id: string, data: any) => {
      if (DEBUG) console.debug(`✅ [Worksheet] updateRow() INPUT`, { id, data })

      const dataMap = get().dataMap

      if (!dataMap?.[id] || size(data) === 0) return

      const newData = produce(dataMap, (draft: any) => {
        merge(draft[id], data)
      })

      set({ dataMap: newData })

      if (DEBUG) console.debug(`✅ [Worksheet] updateRow() OUTPUT`, { newData })
    },

    quickAddData: ({ model, data }: any) => {
      const dataMap = get().dataMap
      const dataIds = get().dataIds

      if (!dataMap || !model || size(data) === 0) return

      const dataMapIds = Object.keys(dataMap)

      // get list of ids where new data can be added
      const availableIds: any = []
      const newDataIds: any = [...dataIds]

      for (const id of dataMapIds) {
        if (isDefined(dataMap[id]?.[model])) continue
        availableIds.push(id)
      }

      const newData = produce(dataMap, (draft: any) => {
        for (let i = 0; i < data.length; i++) {
          const id = availableIds[0]

          if (id) {
            lodashSet(draft, `${id}.${model}`, data[i])
            availableIds.splice(0, 1)
          } else {
            const newId = uuid()

            lodashSet(draft, newId, {
              _id: newId,
              [model]: data[i],
            })

            newDataIds.push(newId)
          }
        }
      })

      set({ dataMap: newData, dataIds: newDataIds })

      if (DEBUG) console.debug(`✅ [Worksheet] updateField()`, { model })
    },

    // Settings
    setWrapping: (wrapType: 'wrap' | 'clip') => set({ wrapping: wrapType }),
    setAllow: (allow: string) => {
      const allowCreate = allow && allow.includes('create')
      const allowUpdate = allow && allow.includes('update')
      const allowDelete = allow && allow.includes('delete')

      set({ allow, allowCreate, allowUpdate, allowDelete })
    },
  }))
}
