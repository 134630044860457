import React from 'react'

import withSettings from '../../hocs/withSettings'

import Table from '../../components/Table/Table'
import TableCell from '../../components/Table/TableCell'
import TableFilter from '../../components/Table/TableFilter'
import Status from '../../components/Status'
import { getResourceLink } from '../../utils/functions'

const columns = (timezone?: string, mainLinkAs) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink as={mainLinkAs} label={value} avatar={row.original.avatar} to={getResourceLink(row.original)} />
    ),
  },
  {
    width: 160,
    accessor: 'type',
    Header: 'Type',
    Cell: ({ value }: any) => {
      if (value === 'employee') return <Status label="Employee" color="blue" />
      if (value === 'contact') return <Status label="Contact" color="orange" />
      return <TableCell.Empty />
    },
  },
  {
    width: 160,
    accessor: 'title',
    Header: 'Title',
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 160,
    accessor: 'email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
  {
    width: 180,
    accessor: 'is_shared_with_portal',
    Header: 'Client Portal Sharing',
    Cell: ({ value }: any) => {
      if (!value) return 'Not shared'
      return <TableCell.GlyphValue glyph="portal" value="Shared" />
    },
    Filter: TableFilter.Select,
    filter: 'boolean',
    filterOptions: [
      {
        value: true,
        label: 'Shared',
      },
      {
        value: false,
        label: 'Not shared',
      },
    ],
  },
  {
    width: 150,
    accessor: 'is_blocked',
    Header: 'Is Blocked',
    Cell: ({ value }: any) => (value ? 'Yes' : 'No'),
    Filter: TableFilter.Select,
    filter: 'boolean',
    filterOptions: [
      {
        value: true,
        label: 'Blocked',
      },
      {
        value: false,
        label: 'Not blocked',
      },
    ],
  },
  {
    width: 190,
    accessor: 'last_logged_in_at',
    Header: 'Last Logged in At',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 140,
    accessor: 'last_name',
    Header: 'Last Name',
    Cell: ({ value }: any) => <TableCell value={value} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription?: string
  hiddenColumns?: string[]
  icon?: string
  isLoading: boolean
  localStorageKey?: string
  remove: Function
  removeMessage: string
  timezone?: string
  title?: string
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const RootProgramManagingStaffTable: React.FC<Props> = (props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    isLoading,
    localStorageKey,
    timezone,
    title,
    titleAfter,
  } = props

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(timezone, props.mainLinkAs)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
    />
  )
}

RootProgramManagingStaffTable.defaultProps = {
  title: 'Program Managing Staff',
  icon: 'employees',
  emptyDescription: 'No managing staff assigned yet',
  localStorageKey: 'program_managing_staff',
}

export const ProgramManagingStaffTable = withSettings(RootProgramManagingStaffTable)
