import React from 'react'
import { useLocation } from 'react-router-dom'

import { usDateTime, titleDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import DataFormStatus from '../Statuses/DataFormStatus'
import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'

const NURSING = {
  nursing_assessment: 'Nursing Assessment',
  nursing_reassessment: 'Nursing Re-Assessment',
  cows: 'COWS',
  ciwa: 'CIWA',
}

type Props = {
  data: any
  location: any
  timezone: any
  to: any
}

const Nursing = ({ data, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={`${NURSING[data?.subcategory]} - ${titleDate(data?.created_at)}`} />
      <CardMeta>
        <DataFormStatus status={data?.status} />
        <Text avatar={data?.author?.avatar} label={data?.author?.name} />
        <Text glyph="date" label="Last Edited: " description={usDateTime(data?.updated_at, timezone)} />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(Nursing)
