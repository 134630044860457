import React from 'react'
import { tint } from 'polished'
import pluralize from 'pluralize'
import kebabCase from 'lodash/kebabCase'

import { COLORS } from '../../../theme'
import { amount, usDateTime, titleCase } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import DataList from '../../../components/DataList'
import Status from '../../../components/Status'

import { RecordTooltip } from '../components/RecordTooltip'

export const FinancialTransaction = ({ data, client }: any) => {
  const { timezone } = useSettings()

  let valueColor = 'red'
  let sign = '+'

  if (!data) return null

  if (data.category === 'payment') {
    valueColor = 'green'
  } else if (data.category === 'refund') {
    valueColor = 'gray'
    sign = '-'
  } else if (data.category === 'write_off') {
    valueColor = 'green'
  } else if (data.category === 'credit') {
    valueColor = 'orange'
  } else sign = '-'

  return (
    <RecordTooltip
      title={titleCase(data.category)}
      icon="financials"
      color={tint(0.7, COLORS.green)}
      startDate={data.transactioned_at}
      recordLink={`/financials/${kebabCase(pluralize(data.category))}/${data.id}`}
      client={client}
    >
      <DataList isCompact withPadding labelWidth={120}>
        <DataList.Item label="Amount" value={<Status color={valueColor} label={`${sign}$${amount(data.amount)}`} />} />
        <DataList.Item label="Payment Method" value={titleCase(data.payment_method)} />
        <DataList.Item label="Notes" value={titleCase(data.notes)} />
        <DataList.Item label="Date Added" value={usDateTime(data.created_at, timezone)} />
        <DataList.Item label="Last Edited" value={usDateTime(data.updated_at, timezone)} />
      </DataList>
    </RecordTooltip>
  )
}
