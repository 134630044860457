import React from 'react'

import Alert from '../../../components/Alert'

import Markup from '../../../components/Markup'
import Form from '../../../components/Forms/Form'

import { ReviewSection } from './ReviewSection'
import { QuoteIframeButton } from '../../QuoteElements/QuoteIframeButton'
import { FileAttachmentsInput } from '../../../components/Forms/FileAttachmentsInput'

import { useUpdate } from '@behavehealth/hooks/useNewAPI'

export const PriceMatching = (props: any) => {
  const { isPublic, data, isFinalStatus, canViewQuoteDetails, isOpen } = props
  const form = React.useRef()

  const [priceMatchingAttachments, setPriceMatchingAttachments] = React.useState(data?.price_matching_attachments || [])

  const { mutateAsync: updatePriceMatchingForQuote }: any = useUpdate({
    name: ['quote-price-matching'],
    url: isPublic ? `/apps/quotes/${data?.external_id}/price_matching` : `/quotes/${data?.id}`,
    invalidate: ['quote'],
  })

  if (!data || !data.use_price_matching_section) return null

  return (
    <ReviewSection
      isOpen={isOpen}
      data={data}
      title="Price Matching"
      icon="exchange"
      titleAfter={<QuoteIframeButton data={data} model="price_matching" />}
      sectionModel="price_matching"
    >
      {!canViewQuoteDetails ? (
        <Alert glyph="info">Please complete the previous sections to enable price matching</Alert>
      ) : (
        <div className="grid gap-4">
          <Markup
            value={
              data.parsed_price_matching_text ||
              data.price_matching_text ||
              'Upload a copy of a paid invoice or a recent proposal for one of our competitors products. As soon as your information is verified and approved, we will send you an updated proposal'
            }
          />

          <Form
            isEditable={!isFinalStatus}
            getForm={form}
            initialModel={data}
            onUpdate={(value: any) => {
              const stringifiedNewAttachments = JSON.stringify(value.price_matching_attachments)
              const stringifiedCurrentAttachments = JSON.stringify(priceMatchingAttachments)

              // if the attachments are different, update the state
              if (stringifiedNewAttachments !== stringifiedCurrentAttachments) {
                setPriceMatchingAttachments(value.price_matching_attachments)

                updatePriceMatchingForQuote({ price_matching_attachments: value.price_matching_attachments })
              }
            }}
          >
            <FileAttachmentsInput canUpload={!isFinalStatus} model="price_matching_attachments" label="Shared Files" />
          </Form>
        </div>
      )}
    </ReviewSection>
  )
}
