import React from 'react'
import { connect } from 'react-redux'

import AddressSelectorInput from '../../Forms/AddressSelectorInput'
import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import ContextShow from '../../Forms/ContextShow'
import DateInput from '../../Forms/DateInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import PhoneInput from '../../Forms/PhoneInput'
import Section from '../../Section'
import Select from '../../Forms/Select'
import Textarea from '../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class ProviderOverlay extends OverlayBase {
  renderHeader = () => <Overlay.Header icon="staff_credentials" title="Provider" />

  renderContent = () => {
    const { $editable, $new } = this.state
    const { reference, record, tenant } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={record}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          linked={
            $new && {
              reference_id: reference?.id || tenant?.id,
              reference_type: reference?.type || tenant?.type,
              category: 'npi',
            }
          }
        >
          <Section>
            <FormSection maxWidth="100%">
              <Flex gap="1rem">
                <Input
                  className="!flex-auto"
                  label="Name"
                  model="credential"
                  validations={{
                    presence: {
                      message: 'Please enter a name',
                    },
                  }}
                />

                <Select label="Type" model="level" defaultValue="individual">
                  <Option label="Individual" value="individual" />
                  <Option label="Organization" value="organization" />
                </Select>
              </Flex>

              <ContextShow when="level" is="individual">
                <Flex gap="1rem" stretchChildrenX>
                  <Input
                    label="First Name"
                    model="first_name"
                    validations={{
                      presence: {
                        message: 'Please enter a first name',
                      },
                    }}
                  />
                  <Input
                    label="Last Name"
                    model="last_name"
                    validations={{
                      presence: {
                        message: 'Please enter a last name',
                      },
                    }}
                  />
                </Flex>
              </ContextShow>

              <ContextShow when="level" is="organization">
                <Input
                  label="Organization Name"
                  model="organization"
                  validations={{
                    presence: {
                      message: 'Please enter an organization name',
                    },
                  }}
                />
              </ContextShow>

              <Flex stretchChildrenX gap="1rem">
                <ContextShow when="level" is="individual">
                  <Input label="Employee ID" model="employee_identifier" />
                </ContextShow>

                <ContextShow when="level" is="individual">
                  <Input label="Tax ID" model="tax_identifier" />
                </ContextShow>

                <ContextShow when="level" is="organization">
                  <Input
                    label="Tax ID"
                    model="tax_identifier"
                    validations={{
                      presence: {
                        message: 'Please enter a valid Tax ID',
                      },
                    }}
                  />
                </ContextShow>

                <Input
                  label="NPI Number"
                  model="license_number"
                  validations={{
                    presence: {
                      message: 'Please enter a valid NPI',
                    },
                  }}
                />
              </Flex>

              <DateInput label="Issue Date" model="issued_at" />

              <Textarea label="Notes" model="notes" />

              <PhoneInput label="Phone No" model="phone_no" />

              <AddressSelectorInput model="address" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload files related to this Credential">
            <FormSection maxWidth="100%" layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save Credential"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="credentials.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Credential"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="credentials.edit"
            />

            <DeleteDialog
              title="Delete Credential?"
              message="Are you sure you want to delete this credential? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                fullWidth
                permission="credentials.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'credentials')

export default connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(ProviderOverlay))
