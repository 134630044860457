import React from 'react'

import SmartTextarea from '../../../../Forms/SmartTextarea'
import FormSection from '../../../../Forms/FormSection'

const PhysicalHealthHistory = () => {
  return (
    <FormSection layout="vertical">
      <SmartTextarea
        useDictation
        label="Notes"
        model="data.physical_health_history.notes"
      />
    </FormSection>
  )
}

export default PhysicalHealthHistory
