import React from 'react'
import size from 'lodash/size'
import { Link, NavLink, Route, useParams } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Button, Page, Card, HelpTagIframe, Grid } from '@behavehealth/components'
import LocationsTable from '@behavehealth/components/Tables/LocationsTable'

import { SubLocationsImportOverlay } from '@behavehealth/constructs/Locations/SubLocationsImportOverlay'
import { NewPropertyLocationOverlay } from '@behavehealth/constructs/Locations/NewPropertyLocationOverlay'

const pageConfig = {
  feature: 'properties',
  title: 'Locations',
  help: <HelpTagIframe id="location_sublocations" />,
}

const Locations = () => {
  const { resource_id }: any = useParams()

  return (
    <>
      <LocationsIndex />

      <AnimatedRoutes>
        <Route path="import" element={<SubLocationsImportOverlay useV6Router />} />
        <Route path="new" element={<NewPropertyLocationOverlay useV6Router parent={{ id: resource_id, type: 'location' }} />} />
      </AnimatedRoutes>
    </>
  )
}

const LocationsIndex = () => {
  const { resource_id }: any = useParams()

  const { data, isLoading }: any = useGet({
    name: ['location', resource_id, 'locations'],
    url: `/houses/${resource_id}/houses`,
  })

  const { mutateAsync: deleteLocations }: any = useDelete({
    name: ['location', resource_id, 'locations'],
    url: `/houses`,
    invalidate: ['locations'],
  })

  const isEmpty = size(data) === 0

  const actions = (
    <>
      <Button as={Link} label="Import Locations" glyph="add" link={`import`} />
      <Button as={Link} label="Add New Location" type="primary" glyph="add" link={`new`} />
    </>
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Grid>
        <Card>
          <LocationsTable
            useAutoPageSize
            mainLinkAs={NavLink}
            data={data}
            isLoading={isEmpty && isLoading}
            emptyActions={actions}
            localStorageKey="properties_sublocations"
            to={(id) => `/locations/${id}`}
            batchActionsConfig={[
              {
                glyph: 'delete',
                color: 'red',
                label: (length: number) => `Remove (${length})`,
                permission: 'properties.actions.delete',
                action: async ({ ids }: any) => {
                  await deleteLocations(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(Locations)
