import React from 'react'

import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const CommunicationsOperationsFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="contacts" />
        <Type as="h2" variant="H3">
          Communications & Operations
        </Type>
      </Flex>

      <CheckboxGroup withToggle label=" " trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" layout="vertical-dense">
        <Checkbox
          label="Keep your clients and programs organized with our program lists"
          model="questionnaire.communications_operations.program_lists"
        />
        <Checkbox
          label="Get your team in sync with our events calendars"
          model="questionnaire.communications_operations.events_calendars"
        />
        <Checkbox
          label="Secure one to one and mass messaging with staff and clients"
          model="questionnaire.communications_operations.staff_and_clients_chat"
        />
        <Checkbox
          label="Organize all your HR documentation using staff agreements & records"
          model="questionnaire.communications_operations.agreements"
        />
      </CheckboxGroup>
    </FormSection>
  )
}

export const CommunicationsOperations: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <CommunicationsOperationsFields />
    </Form>
  )
}
