import React from 'react'
import { useSelector } from 'react-redux'

import {
  Glyph,
  Flex,
  Link,
  Iframe,
  HelpTagIframe,
  Page,
  Checklist,
  ChecklistItem,
  ConfettiFireworks,
  Grid,
  Emoji,
  H1,
  P,
  Button,
} from '@behavehealth/components'
import { Text } from '@behavehealth/components/Typography'

import { COLORS } from '@behavehealth/theme'
import { isWebGLAvailable } from '@behavehealth/utils/functions'

const OnBoardingChecklist: React.FC = () => {
  const data = useSelector((state: any) => state.me?.tenant.onboarding_prefs)
  const canDoConfetti = isWebGLAvailable()

  const hadFirstLogin = localStorage.getItem('bh.had_first_login')
  const [celebrations, setCelebrations] = React.useState(hadFirstLogin ? 0 : 1)

  React.useEffect(() => {
    localStorage.setItem('bh.had_first_login', true)
  }, [])

  return (
    <Page feature="onboarding_checklist" breakpoint="0" childrenGap="1rem" help={<HelpTagIframe id="onboarding_checklist" />}>
      <Grid centerX gap={12} css={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
        <Emoji emoji="🎉" size={40} />
        <H1 className="text-blue-500">A warm welcome from everyone at Behave Health!</H1>
        <P>
          We are excited to have you with us! You can start with the checklist below, or look around the app yourself. We have added sample
          data for you, to show you how the software works.
        </P>

        {canDoConfetti && (
          <Button
            color="orange"
            label="Celebrate!"
            onClick={() => {
              setCelebrations((celebrations) => celebrations + 1)
            }}
          />
        )}
      </Grid>

      <Checklist isOpen title="Your Behave Health Checklist" list="onboarding_prefs" data={data}>
        <ChecklistItem
          item="register"
          title="Register for your BEHAVE HEALTH Account"
          description="Get access to the web and mobile Apps"
          icon="dashboard"
        />
        <ChecklistItem
          item="view_dashboard"
          title="View your dashboard"
          to="/dashboard"
          icon="dashboard"
          // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
        />
        <ChecklistItem
          item="try_search"
          icon="search"
          title="Try the search"
          // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
        />

        <ChecklistItem
          item="view_whats_new"
          icon="startup"
          // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          render={({ isCompleted }: any) => (
            <Flex vertical className="!flex-1">
              <Iframe
                title="What's New"
                icon="startup"
                url="https://connect.behavehealth.com/c/product-updates"
                overlayWidth={70}
                stopPropagation={false}
                children={
                  <Link type="minimal" after={<Glyph glyph="chevron_right" />} css={isCompleted && styles.completed}>
                    View what's new
                  </Link>
                }
              />
              <Text>Catch up on Behave Health News</Text>
            </Flex>
          )}
        />

        <ChecklistItem
          item="visit_blog"
          title="Visit Behave Health's Blog"
          description="Catch up on Behave Health News"
          icon="default"
          href="https://behavehealth.com/blog"
          // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
        />

        <ChecklistItem
          item="view_help_center"
          title="View the help center"
          description="See how you can learn and send us a support email"
          icon="treatment_data"
          onClick={() => window.Beacon('open')}
          // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
        />

        <ChecklistItem
          item="view_mobile_apps"
          // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          icon="mobile"
          render={({ isCompleted }: any) => (
            <Flex vertical className="!flex-1">
              <Flex css={isCompleted && styles.completed}>
                <Text color={COLORS.text}>Download the mobile app (</Text>
                <Link
                  type="minimal"
                  href="https://apps.apple.com/us/developer/behave/id1531441728?utm_campaign=Apple%20App%20Store&utm_source=app.behavehealth.com&utm_medium=onboarding_checklist"
                  target="_blank"
                >
                  iPhone
                </Link>
                <Text color={COLORS.text}>/</Text>
                <Link
                  type="minimal"
                  href="https://play.google.com/store/apps/developer?id=Behave%20Health&utm_campaign=Google%20Play%20Store&utm_source=app.behavehealth.com&utm_medium=product_tour"
                  target="_blank"
                >
                  Android
                </Link>
                <Text color={COLORS.text}>)</Text>
              </Flex>
              <Text className="text-[14px]">Get the BH Staff app for iOS or Android</Text>
            </Flex>
          )}
        />
      </Checklist>

      {canDoConfetti && celebrations > 0 && <ConfettiFireworks runs={celebrations} />}
    </Page>
  )
}

const styles = {
  completed: {
    textDecoration: 'line-through',
    color: `${COLORS.text} !important`,
  },
}

export default OnBoardingChecklist
