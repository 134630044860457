import React from 'react'
import { keyframes } from '@emotion/react'
import { transparentize, tint } from 'polished'
import classNames from 'classnames'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { COLORS, SHADOW } from '../theme'
import { Icon, Glyph as GlyphType } from '../declarations/types'
import Notifications from '../modules/notifications'
import withPermissions from '../hocs/withPermissions'
import withSettings from '../hocs/withSettings'

import Button from './Button'
import DeleteDialog from './Dialogs/DeleteDialog'
import Flex from './Flex'
import Form from './Forms/Form'
import Glyph from './Glyph'
import Graphic from './Graphic'

import { AccordionsContext } from './AccordionsContext'

type Props = {
  actions?: string
  activateEditMode?: boolean
  aside?: React.ReactNode
  baseline?: string | number
  children?: React.ReactNode
  className?: any
  testKey?: any
  description: string
  editPermission?: boolean
  glyph?: GlyphType
  graphic?: string
  help?: string
  icon?: Icon
  initialModel?: any
  isNew?: boolean
  isOpen?: boolean
  isSticky?: boolean
  minimal?: boolean
  onCancel?: Function
  onDelete?: Function
  onEdit?: Function
  onReset?: Function
  onSubmit?: Function
  onToggle?: Function
  showDelete?: boolean
  showReset?: boolean
  timezone?: string
  title: string
  toggleOpen?: boolean
  useValidation?: boolean
  render?: Function
  size?: number
  skipValidCheck?: boolean
}

const Accordion: React.FC<Props> = (props) => {
  const {
    actions,
    activateEditMode,
    aside,
    baseline,
    children,
    className,
    testKey,
    description,
    editPermission,
    glyph,
    graphic,
    help,
    icon,
    initialModel,
    isNew,
    isOpen,
    isSticky,
    minimal,
    onCancel,
    onDelete,
    onEdit,
    onReset,
    onSubmit,
    showDelete,
    showReset,
    timezone,
    title,
    titleAfter,
    toggleOpen,
    useValidation,
    render,
    skipValidCheck,
    size: uiSize = 300,
    withBorder,
    rootClassNames,
    footerAfter,
  } = props

  const [loading, setLoading] = React.useState(false)
  const [editable, setEditable] = React.useState(isNew)
  const [open, setOpen] = React.useState(isOpen)
  const [valid, setValid] = React.useState(true)
  const form = React.useRef()

  const context: any = React.useContext(AccordionsContext)

  React.useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  React.useEffect(() => {
    if (!context) return
    context.register(title)
  }, [])

  const onToggle = () => {
    if (props.onToggle) {
      props.onToggle()
      return
    }

    setOpen((c) => !c)
  }

  const handleEdit = () => {
    setEditable(true)
    if (onEdit) onEdit(true)
  }

  const onCancelHandler = () => {
    if (form.current) form.current.resetForm()
    setEditable(false)
    if (onCancel) onCancel(false)
  }

  const onSaveHandler = async () => {
    setLoading(true)

    try {
      if (form.current) {
        const response = await onSubmit(form.current.getFormValue())
        // form.current.resetForm()

        if (size(response?.errors) >= 1) {
          Notifications.send(response?.validations?.[0]?.errors?.[0], 'negative')
          setLoading(false)
          return
        }
      } else {
        const response = await onSubmit()

        if (size(response?.errors) >= 1) {
          Notifications.send(response?.validations?.[0]?.errors?.[0], 'negative')
          setLoading(false)
          return
        }
      }

      setLoading(false)
      setEditable(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const onResetHandler = async () => {
    setLoading(true)

    try {
      await onReset()
      if (form.current) form.current.resetForm()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    } finally {
      setEditable(false)
    }
  }

  React.useEffect(() => {
    if (isUndefined(toggleOpen)) return

    if (!open) setOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleOpen])

  const classes = classNames('accordion', `accordion-size-${uiSize}`, {
    'is-open': open,
    'is-not-open': !open,
    'is-sticky': isSticky,
    'with-border': withBorder,
    minimal: minimal,
    [className]: className,
  })

  const childrenWithProps = React.Children.map(children, (child) => {
    if (child?.type === Form && useValidation) {
      return React.cloneElement(child, {
        getForm: child.props.getForm || form,
        initialModel: initialModel,
        onValidationUpdate: child.props.onValidationUpdate || setValid,
        isEditable: editable,
        timezone: timezone,
      })
    } else return child
  })

  return (
    <div data-test={testKey} css={styles.wrapper} className={clsx(classes, rootClassNames)}>
      <div className={classes} css={styles.header} onClick={onToggle} onDoubleClick={onToggle}>
        <Graphic icon={icon} glyph={glyph} graphic={graphic} size={20} css={{ width: baseline }} />

        <div css={{ paddingLeft: !(icon || glyph || graphic) && '1rem', flex: '999 1 auto' }}>
          <Flex centerY horizontal>
            <h3 css={styles.title}>{title}</h3>
            {titleAfter}
            {help}
          </Flex>

          <span css={{ opacity: 0.75, fontSize: minimal && '0.9rem' }}>{description}</span>
        </div>

        <Flex gap={8} className="!ml-auto">
          {aside}
          <AccordionIcon isOpen={open} />
        </Flex>
      </div>

      <div className={classes} css={styles.content}>
        {childrenWithProps}
        {render && render({ isEditable: editable })}
      </div>

      {open && activateEditMode && (
        <div className={classes} css={styles.footer}>
          <Flex gap={8}>
            {!editable && (
              <>
                <Button
                  testKey="edit_button"
                  type="primary"
                  glyph="edit"
                  label="Edit"
                  onClick={handleEdit}
                  flex="0 0 100px"
                  display="inline-flex"
                  permission={editPermission}
                  size={uiSize}
                />

                {showDelete && (
                  <DeleteDialog
                    title="Delete Confirmation"
                    message="Are you sure you want to delete this record? This action cannot be undone."
                    onYes={onDelete}
                  >
                    <Button
                      color="red"
                      isLoading={loading}
                      type="default"
                      glyph="delete"
                      label="Delete"
                      flex="0 0 auto"
                      display="inline-flex"
                      size={uiSize}
                    />
                  </DeleteDialog>
                )}

                {showReset && (
                  <Button
                    color="green"
                    isLoading={loading}
                    type="default"
                    glyph="reset"
                    label="Reset to Default"
                    flex="0 0 auto"
                    display="inline-flex"
                    onClick={onResetHandler}
                    size={uiSize}
                  />
                )}
              </>
            )}

            {editable && (
              <>
                <Button
                  glyph="check"
                  type="primary"
                  color="green"
                  testKey="save_button"
                  label="Save"
                  onClick={onSaveHandler}
                  isDisabled={skipValidCheck ? false : !valid}
                  isLoading={loading}
                  flex="0 0 100px"
                  display="inline-flex"
                  size={uiSize}
                />

                {!isNew && (
                  <Button
                    glyph="cross"
                    type="default"
                    color="blue"
                    testKey="cancel_button"
                    label="Cancel"
                    onClick={onCancelHandler}
                    isDisabled={loading}
                    flex="0 0 auto"
                    display="inline-flex"
                    size={uiSize}
                  />
                )}
              </>
            )}

            {footerAfter}
          </Flex>
        </div>
      )}

      {open && actions && (
        <div className={classes} css={styles.footer}>
          {actions}
        </div>
      )}
    </div>
  )
}

const accordionIconStyles = {
  width: 20,
  height: 20,
  display: 'flex',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 160ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  borderRadius: '50%',

  '.icon': {
    transform: 'scale3d(1,1,1)',
    fill: COLORS.blue,
    transition: 'all 160ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  '&.is-open': {
    opacity: 0.6,
    background: COLORS.text,
    transform: 'scale3d(0.85,0.85,0.85) rotateZ(135deg)',

    '.icon': {
      fill: 'white',
    },
  },
}

const AccordionIcon = ({ isOpen }) => (
  <div className={isOpen ? 'is-open' : ''} css={accordionIconStyles}>
    <Glyph className="icon" glyph="add" color="red" size={16} />
  </div>
)

const contentAnimation = keyframes`
  0% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
`

const styles = {
  wrapper: {
    transition: 'transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275), box-shadow 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',

    background: 'white',
    boxShadow: SHADOW(2),
    borderRadius: 7,
    marginTop: '0.4rem',
    '&:first-child': {
      marginTop: 0,
    },

    '&.is-open': {
      transform: 'translateY(-1px)',
      background: 'white',
      boxShadow: SHADOW(2),
    },

    '&.is-not-open:hover': {
      transform: 'translateY(-1px)',
      boxShadow: SHADOW(2),
    },

    '&.with-border': {
      border: `1px solid ${COLORS.divider}`,
    },
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0.35rem 1rem 0.35rem 0rem',
    minHeight: 40,
    cursor: 'pointer',
    boxShadow: `0 1px 0 ${COLORS.divider}`,
    transition: 'box-shadow 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
    willChange: 'box-shadow',
    position: 'relative',
    zIndex: 3,
    borderRadius: 7,

    'svg, img': {
      transition: 'transform 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
      willChange: 'transform',
    },

    '&:hover': {
      background: 'white',
      boxShadow: SHADOW(2),

      'svg, img': {
        transform: 'scale3d(1.2, 1.2, 1.2)',
      },

      'h1, h2, h3, h4, h5, h6': {
        color: COLORS.link,
      },
    },

    '&.is-open': {
      background: 'white',
      borderRadius: '7px 7px 0 0',
    },

    '&.is-sticky': {
      top: 0,
      position: 'sticky',
      background: 'white !important',
      zIndex: 3,
      boxShadow: SHADOW(1),
    },

    // '.accordion-size-300 &': {
    //   padding: '0.35rem 1rem 0.35rem 0rem',
    // },

    '.accordion-size-200 &': {
      minHeight: 34,
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
    },

    '.accordion-size-100 &': {
      minHeight: 26,
      paddingTop: '0.15rem',
      paddingBottom: '0.15rem',
    },
  },

  title: {
    '.minimal &': { fontSize: '1.1rem' },
    '.accordion-size-200 &': { fontSize: '1.05rem' },
    '.accordion-size-100 &': { fontSize: '0.95rem', fontWeight: 600 },
  },

  content: {
    height: '0',
    overflow: 'hidden',
    display: 'none',
    padding: '1rem',
    position: 'relative',
    zIndex: 0,

    '&.is-open': {
      height: 'auto',
      display: 'block',
      animation: `${contentAnimation} 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)`,
    },

    '&.minimal': {
      padding: 0,
    },
  },

  footer: {
    padding: '0.5rem',
    boxShadow: `inset 0 1px 0 ${COLORS.divider}`,
    borderRadius: '0 0 7px 7px',
    background: transparentize(0.04, tint(0.5, COLORS.lightBackground)),

    '&.is-open': {
      zIndex: 1,
      bottom: -1,
      position: 'sticky',
    },
  },
}

Accordion.defaultProps = {
  baseline: 42,
  useValidation: true,
  isNew: false,
  isOpen: false,
  editPermission: true,
}

export default withSettings(withPermissions(Accordion))
