import React from 'react'

import { APP_URLS } from '../../constructs/MyAccount/utils'
import { mapToArray } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { AppSidebarContent } from './AppSidebarContent'
import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

import { usePinsStore } from './pinsStore'

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const [removed] = list.splice(startIndex, 1)
  list.splice(endIndex, 0, removed)

  return list
}

const AIAssistantView: React.FC<any> = () => {
  const { tenant } = useSettings()
  const pins = usePinsStore((state: any) => state.pins)
  const setPins = usePinsStore((state: any) => state.setPins)

  const tenantPins = mapToArray(pins?.[tenant.subdomain])

  const onDragEnd = (result: any) => {
    if (!result.destination) return // dragged outside the list

    const entries = Object.entries(pins?.[tenant.subdomain])

    const reordered = reorder(entries, result.source.index, result.destination.index)
    const newPins: any = {}

    for (const item of reordered) {
      newPins[item[0]] = item[1]
    }

    setPins(tenant.subdomain, newPins)
  }

  return (
    <AppSidebarView css={styles.root}>
      <AppSidebarHeader title="AI Assistant" icon="ai_assistant" />

      <AppSidebarContent css={styles.content}>
        <iframe
          frameBorder="0"
          border="0"
          cellSpacing="0"
          src={`${APP_URLS.my_account}/ai-assistant`}
          css={styles.iframe}
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
        />
      </AppSidebarContent>
    </AppSidebarView>
  )
}

const styles = {
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },

  content: {
    padding: 0,
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
    flex: '1 1 auto',
  },

  iframe: {
    width: '100%',
    height: '100%',
  },
}

export default AIAssistantView
