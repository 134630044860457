import React from 'react'
import { v4 as uuid } from 'uuid'
import size from 'lodash/size'
import isFinite from 'lodash/isFinite'

import { usDateTime, countWord, titleCase } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { useDataTable } from '../../components/DataTable/useDataTable'
import Tooltip from '../../components/Tooltip'
import GridTable from '../../components/GridTable'
import Card from '../../components/Card'
import TreatmentPlanEventAction from './components/TreatmentPlanEventAction'
import Glyph from '../../components/Glyph'
import Form from '../../components/Forms/Form'

import { TreatmentPlanEvidenceBasedInput } from './form_elements/TreatmentPlanEvidenceBasedInput'

const CONFIG = {
  started_at: {
    name: 'Start Date',
    render: ({ value, timezone }) => usDateTime(value, timezone),
  },
  target_at: {
    name: 'Target Date',
    render: ({ value, timezone }) => usDateTime(value, timezone),
  },
  last_reviewed_at: {
    name: 'Last Review Date',
    render: ({ value, timezone }) => usDateTime(value, timezone),
  },
  completed_at: {
    name: 'Completion Date',
    render: ({ value, timezone }) => usDateTime(value, timezone),
  },
  dated_at: {
    name: 'Date',
    render: ({ value, timezone }) => usDateTime(value, timezone),
  },
  evidence_based_treatment: {
    name: 'Evidence Based Treatment',
    render: ({ value }) => (
      <Form isEditable={false} initialModel={{ evidence_based_treatment: value }}>
        <TreatmentPlanEvidenceBasedInput />
      </Form>
    ),
  },
  status: {
    name: 'Status',
    render: ({ value }) => titleCase(value),
  },
  name: {
    name: 'Name',
    render: ({ value }) => value,
  },
  description: {
    name: 'Description',
    render: ({ value }) => value,
  },
  behavioral_definitions: {
    name: 'Behavioral Definitions',
    render: ({ value }) => value,
  },
  interpretative_summary: {
    name: 'Interpretive Summary',
    render: ({ value }) => value,
  },
  recommendations: {
    name: 'Recommendations',
    render: ({ value }) => value,
  },
  additional_notes: {
    name: 'Additional Relevant Information',
    render: ({ value }) => value,
  },
}

export const TreatmentPlanUpdatesDataTable = (props: any) => {
  const { planId, pageSize = 5 } = props

  const { timezone } = useSettings()

  const tableProps = useDataTable({
    name: ['treatment_plans', planId, 'treatment-plan-updates'],
    endpoint: `/treatment_plans/${planId}/treatment_plan_events`,
    options: { enabled: !!planId },
    pageSize,
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Event Name',
        model: 'name',
        width: 600,
      },
      {
        title: 'Updates',
        model: 'data',
        width: 150,
        formatValue: ({ data, value }: any) => {
          const updates = size(value)

          let tableData: any = []

          for (let key in value) {
            const config = CONFIG[key]

            const from = value[key].from
            const to = value[key].to

            let fromDisplay = config?.render ? config.render({ value: from, timezone }) : ''
            let toDisplay = config?.render ? config.render({ value: to, timezone }) : ''

            if (!config) {
              console.error(`🚨🚨🚨 No config found for key: ${key}`)

              if (typeof from === 'string') {
                fromDisplay = from?.includes?.('_') ? titleCase(from) : from
              } else if (typeof from === 'number') {
                fromDisplay = isFinite(from) ? from : ''
              } else if (!from) {
                fromDisplay = null
              } else if (typeof from === 'object') {
                fromDisplay = JSON.stringify(from, null, 2)
              }

              if (typeof to === 'string') {
                toDisplay = to?.includes?.('_') ? titleCase(to) : to
              } else if (typeof to === 'number') {
                toDisplay = isFinite(to) ? to : ''
              } else if (!to) {
                toDisplay = null
              } else if (typeof to === 'object') {
                toDisplay = JSON.stringify(to, null, 2)
              }
            }

            tableData.push({
              id: uuid(),
              name: CONFIG[key]?.name || titleCase(key),
              fromDisplay,
              toDisplay,
            })
          }

          return (
            <Tooltip
              position="right"
              show={size(tableData) > 0}
              content={
                <Card className="text-[0.9rem]">
                  <GridTable templateColumns="1fr 1fr 1fr">
                    <GridTable.Header>
                      <GridTable.Cell>Label</GridTable.Cell>
                      <GridTable.Cell>Changed From</GridTable.Cell>
                      <GridTable.Cell>Changed To</GridTable.Cell>
                    </GridTable.Header>

                    {tableData.map((item) => (
                      <GridTable.Row key={item.id}>
                        <GridTable.Cell className="font-[600]">{item.name}</GridTable.Cell>
                        <GridTable.Cell>{item.fromDisplay || '–'}</GridTable.Cell>
                        <GridTable.Cell>{item.toDisplay || '–'}</GridTable.Cell>
                      </GridTable.Row>
                    ))}
                  </GridTable>
                </Card>
              }
            >
              <div className="flex flex-nowrap items-center">
                {size(tableData) > 0 ? (
                  <>
                    {countWord('Updates', updates)}
                    <Glyph glyph="info" className="ml-1" size={12} />
                  </>
                ) : (
                  <span className="text-text-muted">–</span>
                )}
              </div>
            </Tooltip>
          )
        },
      },
      {
        title: 'Type',
        model: 'action',
        width: 150,
        formatValue: ({ value }: any) => <TreatmentPlanEventAction small status={value} />,
      },
      {
        title: 'Triggered By',
        model: 'author',
        type: 'profile',
        width: 200,
      },
      {
        title: 'Triggered At',
        model: 'triggered_at',
        type: 'date_time',
        width: 250,
      },
    ],
    [],
  )

  return <DataTable title="Treatment Plan Updates" icon="clinical_assessments" columns={columns} {...props} {...tableProps} />
}
