import React from 'react'
import { Element } from 'react-scroll'
import { v4 as uuid } from 'uuid'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { useFormField } from '../../../components/Forms/hooks/useFormField'
import { withFormContext } from '../../../components/Forms/context'

import Button from '../../../components/Button'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import Divider from '../../../components/Divider'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Input from '../../../components/Forms/Input'
import Overlay from '../../../components/Overlay'
import Section from '../../../components/Section'
import SmartRichTextEditor from '../../../components/Forms/SmartRichTextEditor'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import Tooltip from '../../../components/Tooltip'

export const RootTreatmentPlanCustomSections = (props: any) => {
  const { model, form, isEditable, value, buttonType = 'primary' } = props

  const [data, setData]: any = React.useState(value || form?.getInitialInputFieldValue(model) || [])

  const isEmpty = size(data) === 0

  const { formActions } = useFormField({ model, form })

  // UPDATE FORM
  React.useEffect(() => {
    if (!form) return

    formActions.setValue(data)
  }, [form, model, data])

  // FUNCTIONS
  const addSection = (section: any) => {
    const newData = produce(data, (draft: any) => {
      draft.push({
        _id: uuid(),
        name: section.name || `Section ${size(draft) + 1}`,
        description: section.description || '',
        content: section.content || '',
      })
    })

    setData(newData)
  }

  const removeSection = (idx: number) => {
    if (size(data) === 0) return

    const newData = produce(data, (draft: any) => {
      draft.splice(idx, 1)
    })

    setData(newData)
  }

  const updateSection = (idx: number, value: any) => {
    const newData = produce(data, (draft: any) => {
      draft[idx] = { ...draft[idx], ...value }
    })

    setData(newData)
  }

  const moveSectionUp = (idx: number) => {
    if (idx === 0) return

    const newData = produce(data, (draft: any) => {
      const toMove = draft[idx]

      draft.splice(idx, 1)
      draft.splice(idx - 1, 0, toMove)
    })

    setData(newData)
  }

  const moveSectionDown = (idx: number) => {
    if (idx >= size(data)) return

    const newData = produce(data, (draft: any) => {
      const toMove = draft[idx]

      draft.splice(idx, 1)
      draft.splice(idx + 1, 0, toMove)
    })

    setData(newData)
  }

  const action = isEditable && (
    <div>
      <SummonOverlay overlay={<SectionOverlay onSave={addSection} />}>
        <Button label="Add Section" glyph="add" type={buttonType} display="inline-flex" size={200} onClick={addSection} />
      </SummonOverlay>
    </div>
  )

  return (
    <>
      {isEmpty ? (
        <State
          isEmpty
          icon="treatment_plans"
          title="Custom Sections"
          emptyDescription="No custom sections added yet"
          emptyActions={action}
          minHeight={160}
        />
      ) : (
        <div className="grid gap-4">
          {data?.map?.((section: any, idx: number) => {
            return (
              <React.Fragment key={section._id || `section-${idx}`}>
                <SingleSection
                  idx={idx}
                  data={section}
                  isEditable={isEditable}
                  isFirst={idx === 0}
                  isLast={idx === size(value) - 1}
                  remove={removeSection}
                  moveUp={moveSectionUp}
                  moveDown={moveSectionDown}
                  onUpdate={(value) => updateSection(idx, value)}
                />

                {idx < size(data) - 1 && <Divider className="!m-0" />}
              </React.Fragment>
            )
          })}
        </div>
      )}

      {!isEmpty && <div className="mt-4">{action}</div>}
    </>
  )
}

const SingleSection = ({ isEditable, data, idx, isFirst, isLast, remove, moveUp, moveDown, onUpdate, testKey }: any) => {
  const [section, setSection] = React.useState(data)

  React.useEffect(() => {
    if (onUpdate) onUpdate(section)
  }, [section])

  if (!section) return null

  if (!isEditable) {
    return (
      <Section
        testKey={testKey}
        title={section.name || <span className="opacity-60">Section #{idx + 1}</span>}
        subtitle={<div className="whitespace-pre text-text-muted">{section.description}</div>}
        className="!p-0 !m-0"
        headingType="h4"
        headingSize={400}
      >
        <SmartRichTextEditor maxRows={20} value={section.content} isEditable={false} />
      </Section>
    )
  }

  return (
    <Element key={section.id} name={section.id}>
      <Section
        testKey={testKey}
        title={section.name || <span className="opacity-60">Section #{idx + 1}</span>}
        subtitle={<div className="whitespace-pre text-text-muted">{section.description}</div>}
        className="!p-0 !m-0"
        headingType="h4"
        headingSize={400}
        aside={
          <>
            <SummonOverlay overlay={<SectionOverlay data={section} onSave={setSection} />}>
              <Button label="Edit" glyph="edit" type="minimal" size={200} />
            </SummonOverlay>

            <DeleteDialog
              glyph="delete"
              title="Delete Section?"
              message="This action cannot be undone."
              noLabel="No, Cancel"
              yesLabel="Delete Section"
              onYes={() => remove(idx)}
            >
              <Button type="minimal" label="Remove" glyph="delete" color="red" size={200} />
            </DeleteDialog>

            <Tooltip content="Move section up">
              <Button glyph="triangle_up" className="!p-0" onClick={() => moveUp(idx)} isDisabled={isFirst} hideLabel size={200} />
            </Tooltip>

            <Tooltip content="Move section down">
              <Button hideLabel size={200} glyph="triangle_down" className="!p-0" onClick={() => moveDown(idx)} isDisabled={isLast} />
            </Tooltip>
          </>
        }
      >
        <FormSection maxWidth="100%" className="!mt-4">
          <SmartRichTextEditor
            maxRows={20}
            label="Section Content"
            value={section.content}
            onUpdate={(state: any) => {
              setSection({ ...section, content: state.value })
            }}
          />
        </FormSection>
      </Section>
    </Element>
  )
}

const SectionOverlay = (props: any) => {
  const { onSave } = props

  const isNew = !props.data
  const form = React.useRef()

  const [formData, setFormData] = React.useState({
    name: props.data?.name,
    description: props.data?.description,
  })

  return (
    <Overlay showBackdrop position="center" onClose={props?.onClose}>
      <Overlay.Header title="Section" />

      <Overlay.Content>
        <Form getForm={form} onUpdate={setFormData} initialModel={props.data}>
          <Section>
            <FormSection maxWidth="100%" className="!mt-4">
              <Input
                className="!grow-[2]"
                label="Section Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a section name',
                  },
                }}
              />

              <SmartTextarea useQuickText useDictation label="Section Description" model="description" />

              {/* <SmartRichTextEditor  maxRows={20} label="Section Content" model="content" /> */}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={isNew ? 'Add Section' : 'Save Section'}
          type="primary"
          color="green"
          glyph="tick_circle"
          onClick={() => {
            onSave?.(formData)
            props?.onClose?.()
          }}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const TreatmentPlanCustomSections = withFormContext(RootTreatmentPlanCustomSections)
