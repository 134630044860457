import React from 'react'

import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Workflow from '../../../components/Workflow/Workflow'

const Financials = ({ client, data, setData, setStatus }: any) => {
  const form = React.useRef()
  const { goNext }: any = React.useContext(Workflow.Context)

  const handleContinue = async () => {
    setStatus('completed')
    goNext()
  }

  const handleSkip = () => {
    setStatus('skipped')
    goNext()
  }

  if (!client) return null

  return (
    <Form useLocalModel getForm={form} initialModel={data} onUpdate={setData}>
      <FormSection maxWidth="100%">
        <CheckboxGroup label={`Cancel all Planned Charges that ${client.first_name} has set up`}>
          <Checkbox label="Cancel all Planned Charges" model="cancel_future_charges" />
        </CheckboxGroup>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={handleContinue} />
          <Workflow.SkipButton onClick={handleSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default Financials
