import React from 'react'
import { tint, transparentize } from 'polished'
import isFunction from 'lodash/isFunction'
import size from 'lodash/size'

import { COLORS, HARD_SHADOW } from '../../theme'
import { countWord } from '../../utils/functions'
import { useFuse } from '../../hooks/useFuse'

import { PopoverMenu, PopoverMenuItem } from '../PopoverMenu'
import Button from '../Button'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Grid from '../Grid'
import State from '../State'

import { FilterDropdownSearch } from './filters/FilterDropdownSearch'
import { INPUT_STYLES } from './styles'
import { PopoverItem } from './filters/PopoverItem'
import { useStore } from './useStore'

export const DataTableSorting = (props: any) => {
  const { isSortingOpen, setIsSortingOpen } = props

  const applySorting = () => {}

  const closeMenu = () => {
    setIsSortingOpen(false)
  }

  const handleClearAll = () => {
    resetSorting()
    closeMenu()
  }

  const addSorting: any = useStore((state: any) => state.addSorting)
  const columnsMap: any = useStore((state: any) => state.columnsMap)
  const filtersConfig: any = useStore((state: any) => state.filtersConfig)
  const removeSorting: any = useStore((state: any) => state.removeSorting)
  const resetSorting: any = useStore((state: any) => state.resetSorting)
  const setSorting: any = useStore((state: any) => state.setSorting)
  const sorting: any = useStore((state: any) => state.sorting)

  const sortingCount = size(sorting)
  const isEmpty = sortingCount === 0

  if (size(filtersConfig) === 0) return null

  return (
    <>
      <PopoverMenu
        testKey="sorting_menu"
        isOpen={isSortingOpen}
        onOpenUpdated={setIsSortingOpen}
        menuCss={STYLES.menuWrapper}
        trigger={
          <div>
            <Button
              testKey="data_table_sort_button"
              label={sortingCount ? countWord('Sort', sortingCount) : 'Sort'}
              glyph="move_up_down"
              type="minimal"
              display="inline-flex"
              size={100}
              className={!isEmpty ? 'is-active' : ''}
              css={STYLES.trigger}
            />
          </div>
        }
      >
        <>
          <header css={STYLES.menuHeader}>
            <div css={STYLES.menuHeaderTitle}>Sort By</div>

            <SortingSelect
              onSelect={(sortKey: any) => {
                addSorting(sortKey, 'ASC')
              }}
              trigger={
                <div>
                  <Button testKey="add_sort_button" label="Add Sort Column" glyph="add" type="minimal" display="inline-flex" size={100} />
                </div>
              }
            />

            {!isEmpty && (
              <Button
                testKey="clear_all_sorting_button"
                label="Clear All"
                glyph="close"
                type="minimal"
                color="red"
                display="inline-flex"
                size={100}
                onClick={handleClearAll}
                css={{ marginLeft: 'auto' }}
              />
            )}
          </header>

          <main css={STYLES.menuContent}>
            {isEmpty && (
              <Grid centerX centerY gap="0.75rem" css={{ padding: '1rem', minHeight: 200 }}>
                <Glyph glyph="move_up_down" size={20} color={COLORS.blue} />
                <Grid gap="0.25rem" css={{ textAlign: 'center' }}>
                  <h4 css={{ textTransform: 'uppercase', letterSpacing: 1, fontSize: '0.85rem' }}>No sorting applied</h4>
                  <div css={{ color: COLORS.textMuted, fontSize: '0.9rem', opacity: 0.9 }}>Click "Add Sort Column" to get started.</div>
                </Grid>
              </Grid>
            )}

            {!isEmpty && (
              <>
                {sorting.map((o) => {
                  const column = columnsMap?.[o?.name]
                  const config = filtersConfig?.[o?.name]

                  if (!column || column.disableSort) return null

                  return (
                    <div css={STYLES.sortingRow}>
                      <div css={STYLES.sortingRowMain}>
                        <SortingSelect
                          value={o.name}
                          onSelect={(newSortingKey: any) => {
                            setSorting(o.name, { name: newSortingKey })
                          }}
                          trigger={({ isOpen }: any) => (
                            <div css={STYLES.sortingSelect} className={isOpen ? 'is-focused' : ''}>
                              <Glyph
                                glyph={config?.glyph || 'move_up_down'}
                                size={12}
                                css={STYLES.sortingSelectGlyph}
                                color={COLORS.blue}
                              />
                              <div css={STYLES.sortingSelectLabel}>{column.title}</div>
                              <Glyph glyph="triangle_down" size={10} css={STYLES.sortingSelectTriangle} />
                            </div>
                          )}
                        />

                        <DirectionSelect
                          value={o.sort}
                          onSelect={(direction: any) => {
                            setSorting(o.name, { sort: direction })
                          }}
                        />
                      </div>

                      <button type="button" onClick={() => removeSorting(o.name)} css={STYLES.clearButton}>
                        <Glyph glyph="close" color={COLORS.text} size={10} />
                      </button>
                    </div>
                  )
                })}
              </>
            )}
          </main>

          <footer css={STYLES.menuFooter}>
            <Flex gap="0.75rem" justifyContent="space-between">
              <Button
                testKey="apply_sorting_button"
                label="Apply Sorting"
                glyph="tick_circle"
                type="primary"
                color="green"
                size={100}
                onClick={() => {
                  applySorting()
                  closeMenu()
                }}
                flex="100 1 auto"
                isDisabled={isEmpty}
              />
            </Flex>
          </footer>
        </>
      </PopoverMenu>
    </>
  )
}

export const SortingSelect = (props: any) => {
  const { trigger, onSelect, isOpen, onOpenUpdated } = props

  const sorting: any = useStore((state: any) => state.sorting)
  const columnsMap: any = useStore((state: any) => state.columnsMap)
  const filtersConfig: any = useStore((state: any) => state.filtersConfig)

  const activeSortKeys = sorting.map?.((o: any) => o.name) || []

  const [searchTerm, setSearchTerm]: any = React.useState('')

  const searchData = useFuse(columnsMapToArray(columnsMap), searchTerm, { keys: ['title'] })
  const isEmpty = size(searchTerm) > 0 && size(searchData) === 0

  const clearSearch = () => {
    setSearchTerm('')
  }

  if (!columnsMap || !trigger) return null

  return (
    <PopoverMenu
      stopPropagation
      isOpen={isOpen}
      onOpenUpdated={onOpenUpdated}
      trigger={isFunction(trigger) ? trigger({ isOpen }) : trigger}
    >
      <FilterDropdownSearch value={searchTerm} onChange={setSearchTerm} onClear={clearSearch} />

      {isEmpty ? (
        <State isEmpty key="empty-state" glyph="search" emptyDescription="No filters found" />
      ) : (
        searchData?.map?.((column: any) => {
          const sortKey = column?.key
          const isAdded = activeSortKeys.includes(sortKey)
          const config = filtersConfig?.[sortKey]

          if (isAdded) return null

          return (
            <PopoverMenuItem
              key={sortKey}
              label={column.title}
              glyph={config?.glyph || 'move_up_down'}
              glyphColor={COLORS.blue}
              onClick={() => onSelect?.(sortKey)}
            />
          )
        })
      )}

      {Object.keys(columnsMap).map((sortKey) => {})}
    </PopoverMenu>
  )
}

export const DirectionSelect = (props: any) => {
  const { onSelect, value } = props

  const [isOpen, setIsOpen] = React.useState(false)

  if (!value) return null

  return (
    <PopoverMenu
      // stopPropagation
      isOpen={isOpen}
      onOpenUpdated={setIsOpen}
      trigger={
        <div data-test="sorting_selector" css={STYLES.sortingSelect} className={isOpen ? 'is-focused' : ''}>
          <Glyph glyph={SORTING[value]?.glyph || 'move_up_down'} size={12} css={STYLES.sortingSelectGlyph} color={COLORS.blue} />
          <div css={STYLES.sortingSelectLabel}>{SORTING[value]?.label}</div>
          <Glyph glyph="triangle_down" size={10} css={STYLES.sortingSelectTriangle} />
        </div>
      }
    >
      <PopoverItem
        testKey="ascending_selector"
        title="Ascending"
        description="A → Z"
        isActive={value === 'ASC'}
        onClick={() => {
          onSelect('ASC')
          setIsOpen(false)
        }}
        graphic={<Glyph glyph="sort_ascending" size={16} />}
      />
      <PopoverItem
        testKey="descending_selector"
        title="Descending"
        description="Z → A"
        isActive={value === 'DESC'}
        onClick={() => {
          onSelect('DESC')
          setIsOpen(false)
        }}
        graphic={<Glyph glyph="sort_descending" size={16} />}
      />
    </PopoverMenu>
  )
}

const columnsMapToArray = (columnsMap: any) => {
  const result: any = []

  if (!columnsMap) return result

  for (const key in columnsMap) {
    const column = columnsMap[key]

    if (column.disableSort) continue

    result.push({ key, ...column })
  }

  return result
}

const SORTING: any = {
  ASC: {
    label: 'Ascending',
    glyph: 'sort_ascending',
  },
  DESC: {
    label: 'Descending',
    glyph: 'sort_descending',
  },
}

const STYLES = {
  menuWrapper: {
    '--filter-height': '26px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'hidden !important',
    minHeight: 280,

    '@media(min-width: 600px)': {
      minWidth: 400,
    },
  },

  menuHeader: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    overflow: 'hidden',
    padding: '0.4rem 0.75rem',
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(3),
    fontWeight: 600,
  },

  menuHeaderGlyph: {
    marginRight: '0.25rem',
  },

  menuHeaderTitle: {
    marginRight: '0.25rem',
  },

  menuContent: {
    '--filter-height': '26px',
    flex: '1 1 auto',
    overflowY: 'auto',
    padding: '0.25rem 0.75rem',
  },

  menuFooter: {
    flex: '0 0 auto',
    overflow: 'hidden',
    padding: '0.4rem 0.75rem',
    borderTop: `1px solid ${COLORS.divider}`,
  },

  sortingSelect: {
    ...INPUT_STYLES,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },

  sortingSelectLabel: {
    marginRight: '0.25rem',
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  sortingSelectGlyph: {
    marginRight: '0.25rem',
  },

  sortingSelectTriangle: {
    marginLeft: 'auto',
  },

  trigger: {
    '&.is-active': {
      background: tint(0.8, COLORS.vividBlue),
      boxShadow: `inset 0 0 0 1px ${transparentize(0.86, COLORS.blue)}`,
    },
  },

  sortingRow: {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '0.75rem 0',

    '& + &': {
      borderTop: `1px solid ${COLORS.divider}`,
    },

    '@media(min-width: 600px)': {
      padding: '0.5rem 0',
    },
  },

  sortingRowMain: {
    flex: '1 1 auto',
    paddingRight: '0.25rem',
    display: 'grid',
    gridGap: '0.25rem',

    '@media(min-width: 600px)': {
      gridTemplateColumns: '2fr 1fr',
    },
  },

  clearButton: {
    background: 'none',
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'var(--filter-height)',
    height: 'var(--filter-height)',
    cursor: 'pointer',
    borderRadius: 4,
    outline: 'none',

    '&:hover': {
      background: COLORS.hover,
      svg: { fill: COLORS.red },
    },
  },
}
