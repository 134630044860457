import React from 'react'

import { COLORS } from '../../../theme'

import Button from '../../../components/Button'

import { Filter } from '../common/Filter'
import { FilterCondition } from '../common/FilterCondition'
import { FilterLabel } from '../common/FilterLabel'
import { FilterSegment } from '../common/FilterSegment'

export const StringFilter = (props: any) => {
  const { filter, config, onClear, onUpdate } = props

  const [value, setValue] = React.useState(filter?.value || '')
  const [didChange, setDidChange] = React.useState(false)

  const ref = React.useRef(null)

  const handleUpdate = () => {
    onUpdate?.({ value, condition: 'contains' })
    setDidChange(false)
  }

  React.useEffect(() => {
    if (!ref?.current) return

    ref.current.focus()
  }, [])

  if (!config) return null

  const { label, glyph } = config

  return (
    <Filter onClear={onClear}>
      <FilterLabel label={label} glyph={glyph} />

      <FilterCondition condition="contains" />

      <FilterSegment>
        <div css={STYLES.root}>
          <input
            ref={ref}
            type="text"
            css={STYLES.input}
            value={value}
            onChange={(event) => {
              setDidChange(true)
              setValue(event.target.value)
            }}
          />

          {didChange && (
            <div css={STYLES.segment}>
              <Button label="Apply" onClick={handleUpdate} color="green" size={100} glyph="check" type="primary" css={STYLES.saveButton} />
            </div>
          )}
        </div>
      </FilterSegment>
    </Filter>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },

  input: {
    border: 'none',
    maxWidth: 140,
    outline: 'none',
    height: 'var(--filter-height)',
    padding: '0 0.5rem',
    borderRadius: 3,
    fontSize: '0.85rem',

    '&:hover': {
      background: COLORS.hover,
    },

    '&:focus': {
      background: COLORS.hover,
      boxShadow: `inset 0 0 0 1px ${COLORS.blue}`,
    },
  },

  segment: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.25rem',
  },

  saveButton: {
    height: '20px !important',
    minHeight: '20px !important',
    fontSize: '0.82rem',

    svg: {
      width: 12,
      height: 12,
      margin: '0 !important',
    },
  },
}
