import React from 'react'
import startCase from 'lodash/startCase'
import size from 'lodash/size'

import { ICONS } from '../../theme'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import Dialog from '../../components/Dialog'
import DropdownItem from '../../components/DropdownItem'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Glyph from '../../components/Glyph'
import PageSection from '../../components/PageSection/PageSection'
import State from '../../components/State'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import PaymentMethodStatus from '../../components/Statuses/PaymentMethodStatus'
import { H5, Text } from '../../components/Typography'

import OrganizationPaymentMethodOverlay from './OrganizationPaymentMethodOverlay'
import EditOrganizationPaymentMethodOverlay from './EditOrganizationPaymentMethodOverlay'
import Notifications from '../../modules/notifications'
import StripePaymentOverlay from '../../components/Stripe/StripePaymentOverlay'

import { useGet, useUpdate, useDelete, invalidateQueries } from '../../hooks/useNewAPI'
import { create } from '../../modules/api/requests'
import useStore from '../../modules/store'
import { sleep } from '../../utils/functions'

const PaymentMethod = ({ paymentMethod, organization, updateDefaultPaymentMethod, deletePaymentMethod }: any) => {
  const [isEditing, setIsEditing] = React.useState(false)

  const isDefault = paymentMethod?.id === organization?.default_payment_method?.id
  const isProcessing = paymentMethod?.status === 'processing'

  const actions = (
    <>
      {!isDefault && !isProcessing && (
        <Dialog
          glyph="card"
          title="Change Default Payment Method?"
          message="Are you sure you want to change your Default Payment Method?"
          yesColor="green"
          yesLabel="Set as Default"
          onYes={async () => {
            await updateDefaultPaymentMethod({ default_payment_method_id: paymentMethod.id })
          }}
        >
          <DropdownItem label="Make Default" glyph="check" color="green" />
        </Dialog>
      )}

      <DropdownItem label="Edit" glyph="edit" color="blue" onClick={() => setIsEditing(true)} />

      <Dialog
        glyph="delete"
        title="Delete Payment Method?"
        message="Are you sure you want to delete this payment method? This action cannot be undone."
        yesColor="red"
        yesLabel="Yes, Delete Payment Method"
        onYes={async () => await deletePaymentMethod(paymentMethod?.id)}
      >
        <DropdownItem label="Delete" glyph="delete" color="red" />
      </Dialog>
    </>
  )

  if (paymentMethod.category === 'card') {
    const cardIcon = ICONS[paymentMethod.service_details?.brand] || ICONS.billing
    const card = paymentMethod?.service_details

    return (
      <>
        <Card>
          <CardHeader graphic={<Icon icon={cardIcon} />} actions={actions}>
            <Grid gap="0.5rem">
              <Flex gap="0.5rem">
                <Flex centerY gap="0.5rem">
                  <H5>{paymentMethod.name}</H5>
                  {isDefault && <Status label="Default" color="blue" />}
                </Flex>
                {paymentMethod.status !== 'active' && <PaymentMethodStatus status={paymentMethod.status} />}
              </Flex>

              {paymentMethod.status === 'processing' && <Text>We are currently processing your payment method</Text>}
              {paymentMethod.status === 'active' && (
                <Text>
                  {startCase?.(card.brand)} **** {card.last4}, Exp {card?.exp_month}/{card?.exp_year}
                </Text>
              )}

              <Text description={paymentMethod?.reference?.name} avatar={paymentMethod?.reference?.avatar} />
            </Grid>
          </CardHeader>
        </Card>

        <SummonOverlay
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          overlay={<EditOrganizationPaymentMethodOverlay organization={organization} paymentMethod={paymentMethod} />}
        />
      </>
    )
  } else if (paymentMethod.category === 'ach') {
    const bank = paymentMethod?.service_details

    return (
      <>
        <Card>
          <CardHeader graphic={<Glyph glyph="bank" />} actions={actions}>
            <Grid gap="0.5rem">
              <Flex gap="0.5rem">
                <Flex centerY gap="0.5rem">
                  <H5>{paymentMethod.name}</H5>
                  {isDefault && <Status label="Default" color="blue" />}
                </Flex>
                {paymentMethod.status !== 'active' && <PaymentMethodStatus status={paymentMethod.status} />}
              </Flex>

              {paymentMethod.status === 'processing' && <Text>We are currently processing your payment method</Text>}
              {paymentMethod.status === 'active' && (
                <Text>
                  {bank?.bank_name} **** {bank?.last4}
                </Text>
              )}

              <Text description={paymentMethod?.reference?.name} avatar={paymentMethod?.reference?.avatar} />
            </Grid>
          </CardHeader>
        </Card>

        <SummonOverlay
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          overlay={<EditOrganizationPaymentMethodOverlay organization={organization} paymentMethod={paymentMethod} />}
        />
      </>
    )
  }
}

const OrganizationPaymentMethodsSection = ({ makeDefaultURL, title = 'Payment Methods', organization, tenant }: any) => {
  const [syncing, setSyncing] = React.useState(false)
  const isBehave = useStore((state) => state.isBehave)

  const { data, isLoading }: any = useGet({
    name: ['organization', organization.id, 'payment_methods'],
    url: `/organizations/${organization?.id}/payment_methods`,
  })

  const { mutateAsync: updateOrganization } = useUpdate({
    name: ['organization', organization.id],
    url: `/organizations/${organization?.id}`,
    invalidate: ['organization'],
  })

  const { mutateAsync: deletePaymentMethod } = useDelete({
    name: ['organization', organization.id, 'payment_methods'],
    url: '/payment_methods',
  })

  const isEmpty = size(data) === 0

  const syncPaymentMethods = async () => {
    setSyncing(true)

    try {
      await create(`/organizations/${organization.id}/payment_methods/sync_with_stripe`)
      invalidateQueries(['organization', organization.id, 'payment_methods'])

      Notifications.send('Syncronised successfully', 'positive')
    } catch (errors) {
      console.error(errors)
      Notifications.send("Something didn't work as expected. Please try again later", 'negative')
    } finally {
      setSyncing(false)
    }
  }

  const onSuccess = async () => {
    Notifications.send('Refreshing…', 'positive')
    await sleep(5000)

    invalidateQueries(['organization', organization.id, 'financial_transactions'])
  }

  const actions = (
    <Flex gap={8}>
      {isBehave && (
        <Button
          size={200}
          color="green"
          label="Sync Payment Methods"
          glyph="reset"
          type="minimal"
          onClick={syncPaymentMethods}
          isLoading={syncing}
        />
      )}

      <SummonOverlay
        overlay={
          <OrganizationPaymentMethodOverlay
            stripeConnectID={tenant?.stripe_account_id}
            organization={organization}
            tenant={tenant}
            makeDefaultURL={makeDefaultURL}
          />
        }
      >
        <Button label="Add Payment Method" glyph="add" />
      </SummonOverlay>

      <SummonOverlay
        overlay={
          <StripePaymentOverlay
            stripeConnectID={tenant?.stripe_account_id}
            passFees={tenant?.financial_prefs?.pass_fees_to_payers}
            customer={organization}
            source="EHR"
            onSuccess={onSuccess}
          />
        }
      >
        <Button label="Collect a Payment" type="primary" glyph="dollar" color="green" isDisabled={false} isLoading={false} />
      </SummonOverlay>
    </Flex>
  )

  return (
    <PageSection>
      <PageSection.Header graphic={<Icon icon="billing" />} after={actions}>
        <PageSection.Title title={title} />
      </PageSection.Header>

      <PageSection.Content>
        {isLoading || isEmpty ? (
          <Card>
            <State
              title="Payment Methods"
              emptyDescription="No payment method added yet"
              isLoading={isLoading}
              isEmpty={isEmpty}
              minHeight={100}
            />
          </Card>
        ) : (
          <div className="grid gap-4 grid-cols-1 mq800:grid-cols-2 mq1024:grid-cols-1 mq1200:grid-cols-2 mq1500:grid-cols-3 mq1800:grid-cols-4">
            {Object.keys(data).map((key: any) => (
              <PaymentMethod
                key={key}
                paymentMethod={data[key]}
                organization={organization}
                updateDefaultPaymentMethod={updateOrganization}
                deletePaymentMethod={deletePaymentMethod}
              />
            ))}
          </div>
        )}
      </PageSection.Content>
    </PageSection>
  )
}

export default OrganizationPaymentMethodsSection
