import React from 'react'

import { MainCell } from '../../components/DataTable/cells/MainCell'
import { DataTable } from '../../components/DataTable/DataTable'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import ClaimStatus from '@behavehealth/constructs/RCM/ClaimStatus'
import ClaimFlagStatus from '@behavehealth/constructs/RCM/ClaimFlagStatus'

const CLAIM_SERVICE_LINE_STATUSES = {
  draft: 'Draft',
  connected: 'Connected',
  archived: 'Archived',
}

export const NewInsurancePaymentsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Insurance Payer',
        id: 'insurance_payer',
        model: 'data.insurance_payer',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.insurance_payer?.name} to={to?.(data.data)} />,
      },
      {
        title: 'Plan Name',
        id: 'plan_name',
        model: 'data.plan_name',
        width: 160,
      },
      {
        title: 'Client Name',
        id: 'client',
        model: 'data.client',
        type: 'profile',
        width: 160,
      },
      {
        title: 'Service Start Date',
        id: 'service_start_date',
        model: 'data.service_start_date',
        type: 'date_time',
        width: 160,
      },
      {
        title: 'Service End Date',
        id: 'service_end_date',
        model: 'data.service_end_date',
        type: 'date_time',
        width: 160,
      },
      {
        title: 'Service Name',
        id: 'service_name',
        model: 'data.service_name',
        width: 160,
      },
      {
        title: 'Procedure Code',
        id: 'procedure_code',
        model: 'data.procedure_code',
        width: 160,
      },
      {
        title: 'Service Line Status',
        id: 'service_line_status',
        model: 'data.service_line_status',
        width: 160,
        formatValue: ({ value }: any) => (!!value ? CLAIM_SERVICE_LINE_STATUSES[value] : '–'),
      },
      {
        title: 'Billed Amount',
        id: 'billed_amount',
        model: 'data.billed_amount',
        type: 'currency',
        width: 160,
      },
      {
        title: 'Allowed Amount',
        id: 'allowed_amount',
        model: 'data.allowed_amount',
        type: 'currency',
        width: 160,
      },
      {
        title: 'Insurance Paid',
        id: 'insurance_paid',
        model: 'data.insurance_paid',
        type: 'currency',
        width: 160,
      },
      {
        title: 'Deductible',
        id: 'deductible',
        model: 'data.deductible',
        type: 'currency',
        width: 160,
      },
      {
        title: 'Co-Pay',
        id: 'co_pay',
        model: 'data.co_pay',
        type: 'currency',
        width: 160,
      },
      {
        title: 'Coinsurance',
        id: 'coinsurance',
        model: 'data.coinsurance',
        type: 'currency',
        width: 160,
      },
      {
        title: 'Claim Adjustment Group Code',
        id: 'claim_adjustment_group_code',
        model: 'data.claim_adjustment_group_code',
        width: 160,
      },
      {
        title: 'Adjustment Reason Code',
        id: 'adjustment_reason_code',
        model: 'data.adjustment_reason_code',
        width: 160,
      },
      {
        title: 'Adjustment Amount',
        id: 'adjustment_amount',
        model: 'data.adjustment_amount',
        type: 'currency',
        width: 160,
      },
      {
        title: 'Balance Due Client',
        id: 'balance_due_client',
        model: 'data.balance_due_client',
        type: 'currency',
        width: 160,
      },
      {
        title: 'Invoiced Amount',
        id: 'balance_billed_amount',
        model: 'data.balance_billed_amount',
        type: 'currency',
        width: 160,
      },
      {
        title: 'Payment Reference',
        id: 'payment_reference',
        model: 'data.payment_reference',
        width: 160,
      },
      {
        title: 'Total Payment Amount',
        id: 'payment_amount',
        model: 'data.payment_amount',
        width: 160,
      },
      {
        title: 'Payment Date',
        id: 'payment_date',
        model: 'data.payment_date',
        type: 'date',
        width: 160,
      },
      {
        title: 'Posted Amount',
        id: 'posted_date',
        model: 'data.posted_date',
        type: 'date',
        width: 160,
      },
      {
        title: 'Initial Bill Date',
        id: 'initial_bill_date',
        model: 'data.initial_bill_date',
        type: 'date',
        width: 160,
      },
      {
        title: 'Claim ID',
        id: 'claim_id',
        model: 'data.claim_id',
        width: 160,
      },
      {
        title: 'Service Line ID',
        id: 'service_line_id',
        model: 'data.service_line_id',
        width: 160,
      },
      {
        title: 'Payment Service Line ID',
        id: 'payment_service_line_id',
        model: 'data.payment_service_line_id',
        width: 160,
      },
      {
        title: 'Connected EOBs',
        id: 'connected_eobs',
        model: 'data.connected_eobs',
        width: 160,
      },
      {
        title: 'Connected ERAs',
        id: 'connected_eras',
        model: 'data.connected_eras',
        width: 160,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Insurance Payments"
      icon="insurance"
      columns={columns}
      filtersConfig={FILTER_CONFIG}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="new_insurance_payments"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=new_insurance_payments'}
        />
      }
      {...props}
    />
  )
}

const FILTER_CONFIG = {
  insurance_payer: {
    label: 'Insurance Payer',
    type: 'multi_object',
    endpoint: '/insurance_local_payers',
    apiKey: 'insurance_local_payers',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (item: any) => item.name,
  },
  plan_name: {
    label: 'Plan Name',
    type: 'string',
  },
  client: {
    label: 'Client',
    type: 'multi_object',
    endpoint: '/residents',
    apiKey: 'residents',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (item: any) => item.name,
  },
  service_start_date: {
    label: 'Service Start Date',
    type: 'date_time',
  },
  service_end_date: {
    label: 'Service End Date',
    type: 'date_time',
  },
  service_name: {
    label: 'Service Name',
    type: 'string',
  },
  procedure_code: {
    label: 'Procedure Code',
    type: 'string',
  },
  revenue_code: {
    label: 'Revenue Code',
    type: 'string',
  },
  service_line_status: {
    label: 'Service Line Status',
    type: 'multi_select',
    options: [
      { label: 'Draft', value: 'draft' },
      { label: 'Connected', value: 'connected' },
      { label: 'Archived', value: 'archived' },
    ],
  },
  billed_amount: {
    label: 'Billed Amount',
    type: 'number',
  },
  allowed_amount: {
    label: 'Allowed Amount',
    type: 'number',
  },
  insurance_paid: {
    label: 'Insurance Amount',
    type: 'number',
  },
  deductible: {
    label: 'Deductible',
    type: 'number',
  },
  co_pay: {
    label: 'Co-Pay',
    type: 'number',
  },
  coinsurance: {
    label: 'Coinsurance',
    type: 'number',
  },
  claim_adjustment_group_code: {
    label: 'Claim Adjustment Group Code',
    type: 'string',
  },
  adjustment_reason_code: {
    label: 'Adjustment Reason Code',
    type: 'string',
  },
  adjustment_amount: {
    label: 'Adjustment Amount',
    type: 'number',
  },
  balance_due_client: {
    label: 'Balance Due Client',
    type: 'number',
  },
  balance_billed_amount: {
    label: 'Invoiced Amount',
    type: 'number',
  },
  payment_reference: {
    label: 'Invoiced Amount',
    type: 'string',
  },
  payment_amount: {
    label: 'Total Payment Amount',
    type: 'number',
  },
  payment_date: {
    label: 'Payment Date',
    type: 'date',
  },
  posted_date: {
    label: 'Posted Date',
    type: 'date',
  },
  initial_bill_date: {
    label: 'Initial Bill Date',
    type: 'date',
  },
  claim_id: {
    label: 'Claim ID',
    type: 'string',
  },
  service_line_id: {
    label: 'Service Line ID',
    type: 'string',
  },
  payment_service_line_id: {
    label: 'Payment Service Line ID',
    type: 'string',
  },
  connected_eobs: {
    label: 'Connected EOBs',
    type: 'string',
  },
  connected_eras: {
    label: 'Connected ERAs',
    type: 'string',
  },
}
