import React from 'react'
import { connect } from 'react-redux'
import startCase from 'lodash/startCase'

import { age, titleCase } from '../../../utils/functions'

import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import DateTimeInput from '../../Forms/DateTimeInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Grid from '../../Grid'
import Input from '../../Forms/Input'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import Permission from '../../Permission'
import Section from '../../Section'
import SignaturePad from '../../Forms/SignaturePad'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { ExportPDFButton } from '../../Buttons/ExportPDFButton'

class MedicationDestructionLogOverlay extends OverlayBase {
  renderHeader = () => {
    const { tenant } = this.props
    const isTrialing = tenant?.plan_status === 'trialing'

    return (
      <>
        <Overlay.Header icon="medication_inventory" title="Medication Destruction Log" />

        {!this.state.$editable && !isTrialing && (
          <Permission permission="clients.actions.export">
            <Overlay.SubHeader>
              <ExportPDFButton url={`/medication_destruction_logs/${this.state.id}/pdf`} />
            </Overlay.SubHeader>
          </Permission>
        )}
      </>
    )
  }

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { record, reference, timezone, loading } = this.props

    const isPortal = this.props.location?.source === 'portal'

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={{
            ...record,
            ...params,
          }}
          isEditable={$editable}
          timezone={timezone}
          onValidationUpdate={this.onValidationUpdate}
          linked={$new && { client: reference, client_id: reference?.id }}
        >
          <Section>
            <FormSection layout="vertical">
              <ObjectSelector
                model="client"
                label="Client"
                type="clients"
                icon="clients"
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => `${startCase(data.sex) || '–'}, ${age(data.dob)} y/o, #${data.behave_id}`}
                disableLink={isPortal}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
              />

              <Flex gap={16} stretchChildrenX>
                <ObjectSelector
                  model="med"
                  label="Medication"
                  type="client.medications"
                  dependent="client_id"
                  icon="medications"
                  showDescription={false}
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => titleCase(data.status)}
                  disableLink={isPortal}
                  validations={{
                    presence: {
                      message: 'Please select a medication',
                    },
                  }}
                />

                <Input
                  model="quantity"
                  label="Quantity"
                  validations={{
                    presence: {
                      message: 'Please enter a quantity',
                    },
                  }}
                />
              </Flex>

              <ObjectSelector
                model="employee"
                label="Destroyed By"
                type="employees"
                icon="employees"
                selectTitle={(data) => data.name}
                selectDescription={(data) => titleCase(data.position)}
                disableLink={isPortal}
                validations={{
                  presence: {
                    message: 'Please select an Employee',
                  },
                }}
              />

              <DateTimeInput
                defaultToNow
                model="destroyed_at"
                label="Destroyed At"
                validations={{
                  presence: {
                    message: 'Please enter the date of destruction',
                  },
                }}
              />

              <Input model="prescription_no" label="Prescription Number" />
              <Input model="pharmacy_name" label="Pharmacy Name" />

              <Textarea useQuickText model="notes" label="Notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this log">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Signatures">
            <Grid gap={16}>
              <SignaturePad
                allowPin
                person={record.signed_by}
                label="Staff Signature"
                model="employee_signature"
                signedAtModel="signed_at"
                showAccept={false}
              />
            </Grid>
          </Section>

          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} disableLinks={isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="10 1 auto"
              permission={$new ? 'medication_destruction_logs.create' : 'medication_destruction_logs.edit'}
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="10 1 auto"
              permission="medication_destruction_logs.edit"
            />

            <DeleteDialog
              title="Delete Medication Destruction Log?"
              message="Are you sure you want to delete this medication destruction log? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                fullWidth
                permission="medication_destruction_logs.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'medication_destruction_logs')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(MedicationDestructionLogOverlay))
