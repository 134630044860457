import React from 'react'
import { connect } from 'react-redux'

import Button from '../../Button'
import Chotomate from '../../Chotomate'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Overlay from '../../Overlay'
import PhoneInput from '../../Forms/PhoneInput'
import Select from '../../Forms/Select'
import Option from '../../Forms/Option'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Input from '../../Forms/Input'
import EmailInput from '../../Forms/EmailInput'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'
import SexSelect from '../../Forms/elements/SexSelect'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class PayerPublicContactOverlay extends OverlayBase {
  onSaveSuccessful = () => {
    if (this.props.onSaveSuccessful) this.props.onSaveSuccessful()
  }

  renderHeader = () => {
    return <Overlay.Header icon="contacts" title="Public Contact" />
  }

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { record, timezone } = this.props

    return (
      <Overlay.Content>
        <Chotomate ready name="contact_overlay" />

        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          timezone={timezone}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
        >
          <Section>
            <FormSection>
              <Input
                label="First Name"
                model="first_name"
                validations={{
                  presence: {
                    message: "Please enter the contact's first name",
                  },
                }}
              />

              <Input label="Middle Name" model="middle_name" />

              <Input
                label="Last Name"
                model="last_name"
                validations={{
                  presence: {
                    message: "Please enter the contact's last name",
                  },
                }}
              />

              <SexSelect label="Sex" model="sex" />

              <Input model="role" label="Role" />

              <PhoneInput model="phone_no" label="Phone Number" />
              <Input glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />
              <EmailInput model="email" label="Email" />

              <Textarea model="notes" label="Notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Contact"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
            />

            <DeleteDialog
              title="Delete Contact?"
              message="Are you sure you want to delete this contact? This action cannot be undone."
              onYes={this.delete}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={this.props.loading} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'insurance_global_payer_contacts')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_global_payer_contacts')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(PayerPublicContactOverlay))
