import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

export const TreatmentPlanBookGoalsDataTable = ({ category, to, onClick, ...rest }: any) => {
  const match = useRouteMatch()

  const tableProps = useDataTable({
    name: ['treatment-book-goals'],
    endpoint: `/treatment_book_goals`,
    params: { category },
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 120,
        formatValue: ({ data, value }: any) => (
          <MainCell id={data.id} value={value} to={to ? to(data) : undefined} onClick={onClick ? () => onClick(data) : undefined} />
        ),
      },
      // {
      //   title: 'Category',
      //   model: 'category',
      //   width: 120,
      // },
      {
        title: 'Description',
        model: 'description',
        width: 180,
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [to, onClick],
  )

  return <DataTable asCard title="Treatment Book Goals" icon="treatment_plans" columns={columns} {...rest} {...tableProps} />
}
