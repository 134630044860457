import React from 'react'

import AmountInput from '../../../../components/Forms/AmountInput'

export const AmountInputDefaultValue = (props: any) => {
  const { activeElement, editActiveElementFromInput } = props

  const { default_value } = activeElement.config

  return <AmountInput label="Default Value" model="default_value" value={default_value} onUpdate={editActiveElementFromInput} />
}
