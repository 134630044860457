import React from 'react'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import useAPI from '@behavehealth/hooks/useAPI'

import { Card, Page, PageLayout, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { VOBsTable } from '@behavehealth/components/Tables'

const pageConfig = {
  feature: 'full_vobs',
  title: 'VOBs',
}

const VOB = ({ match }: any) => {
  const data = useSelector((state) => state.data?.insurance_vobs?.data)
  const loading = useSelector((state) => state.data?.insurance_vobs?.loading)
  const empty = size(data) === 0

  useAPI('insurance_vobs', `/residents/${match.params?.resource_id}/insurance_vobs?version=v1`)

  return (
    <Page icon="insurance" title="VOBs" help={<HelpTagIframe id="client_behave_vobs" />}>
      <PageLayout>
        <Card>
          <VOBsTable
            title="VOBs"
            icon="insurance"
            data={data}
            isLoading={empty && loading}
            emptyDescription="Create the first VOB for this client"
            localStorageKey="insurance_vobs"
            to={(id: string) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
          />
        </Card>
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(VOB, pageConfig))
