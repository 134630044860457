import React from 'react'
import startCase from 'lodash/startCase'

import ClientStatus from '../Statuses/ClientStatus'
import ClientPortalStatus from '../Statuses/ClientPortalStatus'

import { fullname, age, nicePhoneNo } from '../../utils/functions'

import CardLinkPhoto from '../CardLinkPhoto'
import CardMeta from '../CardMeta'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'
import Text from '../Typography/Text'

type Props = {
  to: any
  client: any
  hideStatus: boolean
  hideIntakeStatus: boolean
  actions: React.ReactNode
}

const Client = ({ to, client, hideStatus, hideIntakeStatus, actions, ...rest }: Props) => (
  <CardLinkPhoto to={to} record={client} src={client.avatar} testKey={`${fullname(client)}`} actions={actions} {...rest}>
    <CardMeta>
      <CardTitle title={fullname(client)} />
      {!hideStatus && <ClientStatus status={client.status} />}
      {client?.client_portal_status && <ClientPortalStatus status={client?.client_portal_status} />}
    </CardMeta>

    <CardMeta>
      <CardSubtitle subtitle={`${startCase(client?.sex) || '–'}, ${age(client?.dob)} y/o, #${client?.behave_id}`} />
      <Text glyph="email" description={client.email} />
      <Text glyph="phone" description={client.phone_no} />
    </CardMeta>
  </CardLinkPhoto>
)

export default Client
