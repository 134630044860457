import React from 'react'
import { connect } from 'react-redux'

import { queueUpload } from '../actions/common'
import Upload from '../modules/upload'

class Uploader extends React.Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  onChange = (event) => {
    const options = {
      category: this.props.category,
      subcategory: this.props.subcategory,
      state: this.props.state,
      queryKey: this.props.queryKey,
    }

    const files = Upload.queue({ files: event.target.files, resource: this.props.resource, options })
    this.props.queueUpload(files)

    Upload.start()

    // clear out in order to be able to trigger onChange with the same file
    this.ref.current.value = null
  }

  onClick = () => {
    this.ref.current.click()
  }

  render() {
    return (
      <div onClick={this.onClick} css={styles}>
        {this.props.children}
        <input css={{ display: 'none' }} ref={this.ref} type="file" multiple={this.props.acceptMultiple} onChange={this.onChange} />
      </div>
    )
  }
}

const styles = {
  '& > a': {
    width: '100%',
  },
}

Uploader.defaultProps = {
  acceptMultiple: true,
  isEditable: true,
  isBlocked: false,
}

const mapDispatchToProps = (dispatch) => ({
  queueUpload: (data) => dispatch(queueUpload(data)),
})

export default connect(null, mapDispatchToProps)(Uploader)
