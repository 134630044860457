import React from 'react'

import { SectionCard } from '../../../components/SectionCard'

import DateInput from '../../../components/Forms/DateInput'
import Flex from '../../../components/Flex'
import Input from '../../../components/Forms/Input'
import Option from '../../../components/Forms/Option'
import Select from '../../../components/Forms/Select'
import Textarea from '../../../components/Forms/Textarea'

export const InvoiceDetails = () => {
  return (
    <SectionCard title="Details">
      <div className="grid gap-3">
        <Flex gap="0.75rem">
          <DateInput label="Due Date" model="due_at" />

          <Select label="Status" model="status">
            <Option label="Draft" value="draft" />
            <Option label="Open" value="open" />
            <Option label="Paid" value="paid" />
            <Option label="Failed" value="failed" />
            <Option label="Refunded" value="refunded" />
            <Option label="Cancelled" value="cancelled" />
            <Option label="Written Off" value="written_off" />
          </Select>

          <Input label="Invoice Number" model="identifier" />
        </Flex>

        <Flex gap="0.75rem">
          <DateInput label="Invoice Period Start" model="started_at" />
          <DateInput label="Invoice Period End" model="ended_at" />
        </Flex>

        <Textarea label="Description" minRows={2} model="description" />
        <Textarea label="Instructions" minRows={2} model="instructions" />
        <Textarea label="Notes" minRows={2} model="notes" />
      </div>
    </SectionCard>
  )
}
