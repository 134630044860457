import React from 'react'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useSelector } from 'react-redux'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { ICONS } from '../../theme'
import Button from '../Button'
import Dialog from '../Dialog'
import Overlay from '../Overlay'
import Grid from '../Grid'
import RadioGroup from '../Forms/RadioGroup'
import Radio from '../Forms/Radio'
import Status from '../Status'
import Glyph from '../Glyph'
import Icon from '../Icon'
import Section from '../Section'
import Divider from '../Divider'
import State from '../State'
import Alert from '../Alert'
import FormSection from '../Forms/FormSection'
import SummonOverlay from '../SummonOverlay'

import Card from '../Card'
import CardTitle from '../CardTitle'
import CardSubtitle from '../CardSubtitle'
import CardHeader from '../CardHeader'

import { apiGet, apiCreate } from '../../modules/api'
import useStore from '../../modules/store'

import StripeAddCardOverlay from '../Stripe/behave/StripeAddCardOverlay'

import { buildPriceLabel } from './constants'

const stripePromise = loadStripe(process.env.BH_STRIPE_BEHAVE_PUBLIC_KEY)

const Message = ({ glyph, title, description }: any) => (
  <div css={styles.message.root}>
    <Glyph glyph={glyph} />
    <h2 css={styles.message.title}>{title}</h2>
    <p css={styles.message.description}>{description}</p>
  </div>
)

const SubscriptionPaymentOverlay = (props: any) => {
  const { onClose, product, onSuccess } = props

  const updateStore = useStore((state) => state.update)

  const productType = product.category
  const activeSubscriptions = useSelector((state) => state.me?.subscriptions)
  const activePlan = activeSubscriptions.find((sub) => sub.status === 'active' && sub.product?.category === 'plan')

  const [loading, setLoading] = React.useState(false)
  const [processing, setProcessing] = React.useState(false)

  const [succeeded, setSucceeded] = React.useState(false)

  const payment_methods = useSelector((state: any) => state.data?.payment_methods?.data)
  const hasPaymentMethods = size(payment_methods?.cards) > 0

  const subscribeToPlan = async () => {
    setProcessing(true)

    await apiCreate({
      url: '/subscriptions/subscribe',
      params: {
        product_id: product.id,
      },
      notify: false,
    })

    const me = await apiGet({ url: '/me', reducer: 'me' })
    updateStore({ tenant: me.data.tenant, subscriptions: me.data.subscriptions })

    setLoading(false)
    setSucceeded(true)

    if (onSuccess) onSuccess()
  }

  const closeOverlay = async () => {
    if (onClose) onClose()
  }

  const getPaymentMethods = async () => {
    try {
      setLoading(true)

      await apiGet({
        notify: false,
        name: 'payment_methods',
        url: `/apps/stripe/payment_methods`,
      })

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Overlay
      showBackdrop
      position="center"
      closeWrapper={(closeElement: any) => {
        if (succeeded) {
          return <div onClick={onClose}>{closeElement}</div>
        } else {
          return (
            <Dialog
              glyph="circle_error"
              title="Changed your mind?"
              message="This subscription will not be processed."
              yesColor="red"
              yesLabel="Yes, Cancel"
              noLabel="Go Back"
              onYes={closeOverlay}
            >
              {closeElement}
            </Dialog>
          )
        }
      }}
    >
      <Overlay.Header title={`Subscribe to ${product.name}`} glyph="dollar" />

      <Overlay.Content>
        {loading ? (
          <State isLoading={true} />
        ) : (
          <>
            {succeeded ? (
              <Section>
                <Message glyph="tick_circle" title="Thank you!" description="Your subscription has been processed successfully" />
                <Button label="Close" onClick={onClose} />
              </Section>
            ) : (
              <>
                {activePlan && productType === 'plan' && (
                  <>
                    <Section headingType="h3" title="Current Plan" description="This is your current plan">
                      <FormSection>
                        <Card baseline="3rem">
                          <CardHeader graphic={<Icon icon={activePlan?.product?.icon} size={20} />}>
                            <CardTitle title={activePlan?.product?.name} />
                            <CardSubtitle
                              subtitle={buildPriceLabel(
                                activePlan?.product?.pricing_type,
                                activePlan?.product?.discounted_price || activePlan?.product?.price,
                                activePlan?.product?.price_unit_type,
                              )}
                            />
                          </CardHeader>
                        </Card>

                        <Alert>This plan will get automatically canceled when you subscribe to your new plan below</Alert>
                      </FormSection>
                    </Section>

                    <Divider />
                  </>
                )}

                {productType === 'plan' && (
                  <Section headingType="h3" title="New Plan" description="You are subscribing to this plan">
                    <Card baseline="3rem">
                      <CardHeader graphic={<Icon icon={product.icon} size={20} />}>
                        <CardTitle title={product.name} />
                        <CardSubtitle
                          subtitle={buildPriceLabel(
                            product.pricing_type,
                            product.discounted_price || product.price,
                            product.price_unit_type,
                          )}
                        />
                      </CardHeader>
                    </Card>
                  </Section>
                )}

                {productType === 'addon' && (
                  <Section headingType="h3" title="Subscribing to:">
                    <Card baseline="3rem">
                      <CardHeader graphic={<Icon icon={product.icon} size={20} />}>
                        <CardTitle title={product.name} />
                        <CardSubtitle
                          subtitle={buildPriceLabel(
                            product.pricing_type,
                            product.discounted_price || product.price,
                            product.price_unit_type,
                          )}
                        />
                      </CardHeader>
                    </Card>
                  </Section>
                )}

                <Divider />

                {hasPaymentMethods && (
                  <Section>
                    <Alert glyph="info">
                      Your <strong>Default Payment Method</strong> will be used for Invoices
                    </Alert>
                  </Section>
                )}

                {!hasPaymentMethods && (
                  <Section headingType="h3" title="New Payment Method" description="Please add a new payment method">
                    <SummonOverlay overlay={<StripeAddCardOverlay onSuccess={getPaymentMethods} />}>
                      <Button label="Add New Payment Method" glyph="add" size={300} display="inline-flex" className="w-[250px]" />
                    </SummonOverlay>
                  </Section>
                )}
              </>
            )}
          </>
        )}
      </Overlay.Content>

      {!succeeded && (
        <Overlay.Footer>
          <Button
            label="Subscribe"
            glyph="check"
            color="green"
            type="primary"
            onClick={subscribeToPlan}
            isLoading={loading || processing}
            isDisabled={!hasPaymentMethods}
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const styles = {
  dataList: {
    marginBottom: '1rem',
  },
  message: {
    root: {
      textAlign: 'center',
      padding: '2rem 0',
    },

    title: {
      margin: '0.25rem 0 0.5rem',
    },

    description: {
      margin: 0,
    },
  },
}

const SubscriptionPaymentOverlayWithElements = (props) => (
  <Elements stripe={stripePromise}>
    <SubscriptionPaymentOverlay {...props} />
  </Elements>
)

export default SubscriptionPaymentOverlayWithElements
