import React from 'react'
import { transparentize } from 'polished'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../../theme'
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard'
import Icon from '../Icon'

import { EmptyCell } from './cells/EmptyCell'
import { useStore } from './useStore'

import { stringifyCellCoords } from './utils'

export const DataTableCell = React.forwardRef((props: any, ref: any) => {
  const {
    align,
    canEdit,
    centerX,
    children,
    className,
    flexChildren,
    formatValue,
    hoverExpand = true,
    icon,
    isSticky,
    level,
    onClick,
    record,
    style,
    value,
    x,
    y,
    testKey,
  } = props

  const timezone: any = useStore((state: any) => state.timezone)
  const selectedCells: any = useStore((state: any) => state.selectedCells)
  const canBatchSelect: any = useStore((state: any) => state.canBatchSelect)

  const formattedValue = formatValue ? formatValue({ value, data: record, timezone }) : value

  const { copy, didCopy } = useCopyToClipboard({
    text: formattedValue,
    notification: 'Copied!',
  })

  const coords = stringifyCellCoords({ x, y })
  // const canSelect = !disableSelect
  const isSelected = selectedCells?.includes(coords)

  const rootClasses = clsx(
    'DataTableCell',
    isSticky && 'is-sticky',
    isSelected && 'is-selected',
    hoverExpand && 'hover-expand',
    canEdit && 'can-edit',
    // canSelect && 'can-select',
    canBatchSelect && 'can-batch-select',
    centerX && 'center-x',
    flexChildren && 'flex-children',
    level && `level-${level}`,
    align && `align-${align}`,
    className,
  )

  return (
    <div
      ref={ref}
      css={STYLES.root}
      data-test={testKey || 'data_table_cell'}
      className={rootClasses}
      data-type="cell"
      data-coords={coords}
      data-x={x}
      data-y={y}
      onClick={onClick}
      style={style}
      data-state={props['data-state']}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onPointerEnter={props.onPointerEnter}
      onPointerLeave={props.onPointerLeave}
      onTouchStart={props.onTouchStart}
      // {...(canSelect && { 'data-can-select': true })}
    >
      {icon && (children || formattedValue) && <Icon icon={icon} size={18} css={STYLES.graphic} />}

      <div className="DataTableCell-value" css={STYLES.value}>
        {children || formattedValue || <EmptyCell />}
      </div>
    </div>
  )
})

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flexWrap: 'nowrap',
    padding: '0 0.4rem',
    position: 'relative',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    zIndex: 0,
    cursor: 'default',
    boxShadow: `inset 1px 0 0 ${COLORS.divider}`,
    whiteSpace: 'nowrap',
    minWidth: 0,

    '&.can-select > *': {
      pointerEvents: 'none',
    },

    '&.flex-children .DataTableCell-value': {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'stretch',
      flexWrap: 'nowrap',

      '& > *': {
        marginRight: '0.5rem',
      },
    },

    '&.is-selected': {
      '&::before': {
        content: '""',
        display: 'block',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        position: 'absolute',
        pointerEvents: 'none',
        background: transparentize(0.75, COLORS.vividBlue),
        border: `1px dashed ${transparentize(0.25, COLORS.vividBlue)}`,
      },
    },

    '&.center-x': {
      justifyContent: 'center',
      textAlign: 'center',
    },

    '&.align-right': {
      justifyContent: 'flex-end',
      textAlign: 'right',
    },

    '&.hover-expand:hover': {
      overflow: 'visible',
      zIndex: 10,

      '.DataTableCell-value': {
        position: 'absolute',
        padding: '0.2rem 0.4rem',
        top: 0,
        left: 0,
        overflow: 'visible',
        minWidth: '100%',
        width: 'max-content',
        maxWidth: '200%',
        whiteSpace: 'normal',
        minHeight: 30,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap !important',
        background: `${COLORS.white} !important`,
        boxShadow: `inset 0 0 0 1px ${COLORS.divider}, ${SHADOW(3)} !important`,
        borderRadius: 4,
      },

      '&.center-x .DataTableCell-value': {
        justifyContent: 'center',
        textAlign: 'center',
      },

      '&.align-right .DataTableCell-value': {
        justifyContent: 'flex-end',
        textAlign: 'right',
      },

      '&.flex-children .DataTableCell-value': {
        flexWrap: 'wrap',

        '& > *': {
          marginTop: '0.2rem',
          marginBottom: '0.2rem',
        },
      },
    },

    '&.is-active': {
      background: COLORS.white,
      boxShadow: `inset 0 0 0 1px ${COLORS.blue} !important`,

      '& > *': { pointerEvents: 'all' },
    },

    '&::after': {
      display: 'block',
      left: 0,
      right: 0,
      height: 5000,
      position: 'absolute',
      pointerEvents: 'none',
      background: transparentize(0.92, COLORS.gray),
      zIndex: -1,
    },

    '&:hover': {
      // background: `${COLORS.white} !important`,
      // boxShadow: `inset 0 0 0 1px ${COLORS.divider}`,
    },

    '&:last-child': {
      borderRight: 'none',
    },

    '&.is-sticky': {
      '@media(min-width: 600px)': {
        position: 'sticky',
        left: 0,
        background: `${COLORS.white} !important`,
        boxShadow: `3px 0 0 ${COLORS.shadow}`,
        zIndex: '10 !important',

        '&.can-batch-select': {
          left: 'var(--checkbox-width)',
        },
      },
    },

    '&.level-1': {
      paddingLeft: 'var(--triangle-size)',
    },

    '&.level-2': {
      position: 'relative',
      paddingLeft: 'calc(1 * var(--indent-size) + var(--triangle-size))',
    },

    '&.level-3': {
      paddingLeft: 'calc(2 * var(--indent-size) + var(--triangle-size))',
    },

    '&.level-4': {
      paddingLeft: 'calc(3 * var(--indent-size) + var(--triangle-size))',
    },
  },

  value: {
    textOverflow: 'ellipsis',
    flex: '1 1 auto',
    overflow: 'hidden',
  },

  graphic: {
    marginRight: '0.4rem',
  },
}
