import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import ClaimTypeStatus from '../../components/Statuses/ClaimTypeStatus'
import ContextShow from '../../components/Forms/ContextShow'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Tabs from '../../components/Tabs'
import PillTab from '../../components/PillTab'
import PillTabList from '../../components/PillTabList'
import TabPanels from '../../components/TabPanels'
import TabPanel from '../../components/TabPanel'

import ClaimDetails from './components/Template/ClaimDetails'
import ClaimPayerDetails from './components/Template/ClaimPayerDetails'
import ClaimInstitutionalProviders from './components/Template/ClaimInstitutionalProviders'
import ClaimInstitutionalValueCodes from './components/ClaimInstitutionalValueCodes'
import ClaimProfessionalProviders from './components/Template/ClaimProfessionalProviders'
import ClaimTemplateDetails from './components/Template/ClaimTemplateDetails'
import InstitutionalServiceLines from './components/Template/InstitutionalServiceLines'
import ProfessionalServiceLines from './components/Template/ProfessionalServiceLines'

import OverlayLoader from '../../components/OverlayLoader'

const RootClaimTemplateOverlay = (props: any) => {
	const {
		data,
		cancel,
		close,
		deleteRecord,
		edit,
		form,
		initialModel,
		isDeleting,
		isEditable,
		isInvalid,
		isLoading,
		isNew,
		isSaving,
		isOverlayLoading,
		onValidationUpdate,
		save
	} = useOverlay({
		name: 'insurance_claim_template',
		endpoint: '/insurance_claim_templates',
		invalidate: 'insurance_claim_templates',
		options: props
	})

	const { timezone, tenant } = useSettings()

	const [claimCategory, setClaimCategory] = React.useState(null)
	const [insuranceProvider, setInsuranceProvider] = React.useState(null)
	const [feeSchedule, setFeeSchedule] = React.useState(null)

	if (isOverlayLoading) return <OverlayLoader position="right" maxWidth={120} />

	return (
		<Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} maxWidth={120}>
			<Overlay.Header
				icon="accounting"
				title="Claim Template"
				titleAside={!isNew && <ClaimTypeStatus status={initialModel.category} />}
			/>

			<Overlay.Content>
				<Form
					isCompact
					getForm={form}
					timezone={timezone}
					isEditable={isEditable}
					initialModel={initialModel}
					onValidationUpdate={onValidationUpdate}
					maxWidth="100%"
				>
					<Section>
						<Grid gap="0.6rem">
							<RadioGroup
								model="category"
								layout="horizontal"
								defaultValue="professional"
								maxWidth="100%"
								withHover={false}
								isEditable={isNew}
								className={`${isNew ? '' : 'is-hidden'} w-[500px]`}
								onUpdate={(state: any) => {
									setClaimCategory(state.value)
								}}
							>
								<Radio label="Professional Claim (CMS-1500)" value="professional" />
								<Radio label="Institutional Claim (UB-04)" value="institutional" />
							</RadioGroup>

							<div>
								<Grid gap="0.4rem">
									<Flex gap="1rem" alignItems="normal">
										<ClaimTemplateDetails />

										<ClaimPayerDetails
											insuranceProvider={insuranceProvider}
											onInsuranceProviderUpdate={setInsuranceProvider}
										/>
									</Flex>

									<ClaimDetails feeSchedule={feeSchedule} onUpdate={setFeeSchedule} />

									<ContextShow when="category" is="professional">
										<ClaimProfessionalProviders tenant={tenant} />
									</ContextShow>

									<ContextShow when="category" is="institutional">
										<Tabs defaultTab="providers">
											<PillTabList css={{ marginTop: '0.25rem', marginBottom: '0.5rem' }}>
												<PillTab label="Providers" name="providers" />
												<PillTab label="Value Codes" name="value_codes" />
											</PillTabList>

											<TabPanels>
												<TabPanel name="providers">
													<ClaimInstitutionalProviders tenant={tenant} />
												</TabPanel>

												<TabPanel name="value_codes">
													<ClaimInstitutionalValueCodes tenant={tenant} />
												</TabPanel>
											</TabPanels>
										</Tabs>
									</ContextShow>
								</Grid>
							</div>

							{claimCategory === 'professional' && (
								<ProfessionalServiceLines
									isNested
									key={initialModel?.updated_at}
									value={data?.insurance_claim_template_service_lines}
									model="insurance_claim_template_service_lines"
									isNew={isNew}
									tenant={tenant}
									feeSchedule={feeSchedule}
								/>
							)}

							{claimCategory === 'institutional' && (
								<InstitutionalServiceLines
									isNested
									key={initialModel?.updated_at}
									value={data?.insurance_claim_template_service_lines}
									model="insurance_claim_template_service_lines"
									isNew={isNew}
									tenant={tenant}
									feeSchedule={feeSchedule}
								/>
							)}
						</Grid>
					</Section>
				</Form>
			</Overlay.Content>

			<Overlay.Footer>
				{isEditable && (
					<>
						<Button
							label="Save"
							glyph="check"
							type="primary"
							color="green"
							isLoading={isSaving}
							onClick={save}
							isDisabled={isInvalid}
							flex="100 1 auto"
							permission="insurance_claim_templates.create"
						/>
						{!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
					</>
				)}

				{!isEditable && (
					<>
						<Button
							glyph="edit"
							label="Edit"
							type="default"
							isDisabled={isLoading}
							onClick={edit}
							flex="100 1 auto"
							permission="insurance_claim_templates.edit"
						/>

						<DeleteDialog
							title="Delete Claim Template?"
							message="Are you sure you want to delete this Claim Template? This action cannot be undone."
							onYes={deleteRecord}
							permission="insurance_claim_templates.delete"
						>
							<Button
								glyph="delete"
								label="Delete"
								type="default"
								color="red"
								isLoading={isDeleting}
								fullWidth
								permission="insurance_claim_templates.delete"
							/>
						</DeleteDialog>
					</>
				)}
			</Overlay.Footer>
		</Overlay>
	)
}

export const ClaimTemplateOverlay = withOverlayError(RootClaimTemplateOverlay)
