import React from 'react'

import FormSection from '../../../../Forms/FormSection'
import ContextShow from '../../../../Forms/ContextShow'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Input from '../../../../Forms/Input'

const TreatmentHistory = () => {
  return (
    <FormSection layout="vertical">
      <RadioGroup label="Has the client been treated for mental illness?" model="data.treatment_history.mental_illness" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.treatment_history.mental_illness" is={true}>
        <Input label="How many times?" model="data.treatment_history.mental_illness_times" />
      </ContextShow>

      <RadioGroup
        label="Has the client been treated for substance abuse issues?"
        model="data.treatment_history.substance_abuse_issues"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.treatment_history.substance_abuse_issues" is={true}>
        <Input label="How many times?" model="data.treatment_history.substance_abuse_issues_times" />
      </ContextShow>

      <RadioGroup
        label="Has the client been treated for an eating disorder?"
        model="data.treatment_history.eating_disorder"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.treatment_history.eating_disorder" is={true}>
        <Input label="How many times?" model="data.treatment_history.eating_disorder_times" />
      </ContextShow>
    </FormSection>
  )
}

export default TreatmentHistory
