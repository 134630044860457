import React from 'react'
import { useParams } from 'react-router-dom'

import { QuickContactWidget } from '@behavehealth/components/QuickContactWidget'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Card from '@behavehealth/components/Card'
import Page from '@behavehealth/components/Page'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import State from '@behavehealth/components/State'

export const ContactUs = () => {
  const params: any = useParams()
  const { resource_id: id } = params

  const { data: partnership, isLoading, isError, error }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  if (!partnership || isLoading) return <PageLoader />

  const partner = partnership.other_partner
  const isShared = partnership.other_partner_settings?.contact_us === true

  if (!isShared) {
    return (
      <Page icon="communications" title="Contact Us">
        <Card>
          <State
            isEmpty
            icon="communications"
            title="Contact Us"
            emptyDescription={`${partner?.name || '–'} has not shared their contact form.`}
          />
        </Card>
      </Page>
    )
  }

  return (
    <Page icon="communications" title="Contact Us">
      <div className="flex justify-center">
        <QuickContactWidget tenant={partner.subdomain} timezone={partner.timezone} />
      </div>
    </Page>
  )
}
