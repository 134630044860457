import React from 'react'
import size from 'lodash/size'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  AppLayout,
  Button,
  Page,
  PageLayout,
  PageSection,
  State,
  SummonOverlay,
  Card,
  Grid,
  Alert,
  Flex,
  Logo,
  PortalsContainer,
  Markup,
} from '@behavehealth/components'

import { ProductCard, ProductsGrid } from '@behavehealth/components/Billing'

import SubscriptionPlanPaymentOverlay from '@behavehealth/components/Billing/SubscriptionPlanPaymentOverlay'
import MarketingCard from '@behavehealth/components/Marketing/MarketingCard'

import { initialize, setup } from '../modules/init'

import useStore from '@behavehealth/modules/store'
import { useGet } from '@behavehealth/hooks/useNewAPI'

const SelectPlan = () => {
  const tenant = useSelector((state) => state.me.tenant)
  const history = useHistory()

  const activeSubscriptions = useStore((state: any) => state.subscriptions)

  const [initialized, setInitialized] = React.useState(false)
  const [requiredAddons, setRequiredAddons] = React.useState([])
  const [remainingAddons, setRemainingAddons] = React.useState([])

  const [requiredProducts, setRequiredProducts] = React.useState([])
  const [oneTimeRequiredProducts, setOneTimeRequiredProducts] = React.useState([])

  const { data: plans, isLoading: plansLoading }: any = useGet({
    name: ['products', { category: 'plan', status: 'in_use,active' }],
    url: `/products`,
    params: {
      category: 'plan',
      status: 'in_use,active',
    },
    options: { enabled: initialized },
  })

  const { data: addons, isLoading: addonsLoading }: any = useGet({
    name: ['products', { category: 'addon', status: 'in_use,active' }],
    url: `/products`,
    params: {
      category: 'addon',
      status: 'in_use,active',
    },
    options: { enabled: initialized },
  })

  const { data: services, isLoading: servicesLoading }: any = useGet({
    name: ['products', { category: 'service', status: 'in_use,active' }],
    url: `/products`,
    params: {
      category: 'service',
      status: 'in_use,active',
    },
    options: { enabled: initialized },
  })

  const isPlansEmpty = size(plans) <= 0
  const isAddonsEmpty = size(addons) <= 0
  const isRequiredAddonsEmpty = size(requiredAddons) <= 0
  const isRemainingAddonsEmpty = size(remainingAddons) <= 0
  const isServicesEmpty = size(services) <= 0

  const onSuccess = () => {
    history.push('/dashboard')
  }

  const PLANS = () => {
    if (plansLoading) return <State isLoading={true} />
    if (isPlansEmpty) return <State isEmpty={true} />
    if (!plans) return null

    return plans.map((product: any) => {
      // find out if there is an active or trialing plan
      let state = 'none'
      for (let i = 0; i < activeSubscriptions?.length; i++) {
        if (activeSubscriptions[i]?.product?.id === product?.id) {
          if (activeSubscriptions[i].status === 'trialing') state = 'trialing'
          else if (activeSubscriptions[i].status === 'active') state = 'active'

          break
        }
      }

      return (
        <ProductCard isRequired isActive={state === 'active'} isTrialing={state === 'trialing'} product={product} showFeatures={false} />
      )
    })
  }

  const REQUIRED_ADDONS = () => {
    if (addonsLoading) return <State isLoading={true} />
    if (!requiredAddons) return null

    return requiredAddons.map((addon) => {
      // find out if there is an active or trialing plan
      let state = 'none'
      for (let i = 0; i < activeSubscriptions?.length; i++) {
        if (activeSubscriptions[i]?.product?.id === addon?.id) {
          if (activeSubscriptions[i]?.status === 'trialing') state = 'trialing'
          else if (activeSubscriptions[i]?.status === 'active') state = 'active'

          break
        }
      }

      return (
        <ProductCard
          isRequired
          isRecommended={addon.is_recommended}
          key={addon.id}
          product={addon}
          showFeatures={false}
          isActive={state === 'active'}
          isTrialing={state === 'trialing'}
        />
      )
    })
  }

  const REMAINING_ADDONS = () => {
    if (addonsLoading) return <State isLoading={true} />
    if (!remainingAddons) return null

    return remainingAddons.map((addon) => {
      // find out if there is an active or trialing plan
      let state = 'none'
      for (let i = 0; i < activeSubscriptions?.length; i++) {
        if (activeSubscriptions[i]?.product?.id === addon?.id) {
          if (activeSubscriptions[i]?.status === 'trialing') state = 'trialing'
          else if (activeSubscriptions[i]?.status === 'active') state = 'active'

          break
        }
      }

      return (
        <ProductCard
          isRecommended={addon.is_recommended}
          key={addon.id}
          product={addon}
          showFeatures={false}
          isActive={state === 'active'}
          isTrialing={state === 'trialing'}
        />
      )
    })
  }

  const SERVICES = () => {
    if (servicesLoading) return <State isLoading={true} />
    if (!services) return null

    return services.map((service) => (
      <ProductCard
        isRequired={service.is_required}
        isRecommended={service.is_recommended}
        key={service.id}
        product={service}
        showFeatures={false}
      />
    ))
  }

  React.useEffect(() => {
    if (!addons) return

    let required = []
    let remaining = []

    for (let i = 0; i < addons.length; i++) {
      if (addons[i].is_required) {
        required.push(addons[i])
      } else {
        remaining.push(addons[i])
      }
    }

    setRequiredAddons(required)
    setRemainingAddons(remaining)
  }, [addons])

  React.useEffect(() => {
    let required = []
    let oneTime = []

    for (let i = 0; i < plans?.length; i++) {
      if (plans[i].pricing_type === 'one_time') oneTime.push(plans[i])
      else required.push(plans[i])
    }

    for (let i = 0; i < addons?.length; i++) {
      if (addons[i].is_required && addons[i].pricing_type === 'one_time') oneTime.push(addons[i])
      else if (addons[i].is_required) required.push(addons[i])
    }

    for (let i = 0; i < services?.length; i++) {
      if (services[i].is_required && services[i].pricing_type === 'one_time') oneTime.push(services[i])
      else if (services[i].is_required) required.push(services[i])
    }

    setRequiredProducts(required)
    setOneTimeRequiredProducts(oneTime)
  }, [plans, addons, services])

  React.useEffect(() => {
    const init = async () => {
      await initialize()
      await setup()

      setInitialized(true)
    }

    init()
  }, [])

  return (
    <AppLayout columns="1fr">
      <Page>
        <PageLayout className="max-w-[1200px] p-4 my-0 mx-auto">
          <Flex justifyContent="center" className="!my-8 !mx-0">
            <Logo logo="ehr" />
          </Flex>

          <PageSection>
            <PageSection.Header>
              <Alert type="positive">
                <p>Your Behave Health free trial has ended, and we are excited for you to join our community of customers!</p>
                <p>
                  To continue using our app and all its features, click the subscribe button below. If you have any questions please{' '}
                  <a
                    href="#"
                    onClick={() => {
                      window.Beacon('open')
                    }}
                  >
                    contact us
                  </a>
                  .
                </p>
                <p>We are looking forward to partnering with you!</p>
              </Alert>
            </PageSection.Header>
          </PageSection>

          {tenant?.metadata?.plans_notes && (
            <PageSection>
              <PageSection.Content>
                <Card className="!py-0 !px-4">
                  <Markup value={tenant?.metadata?.plans_notes} />
                </Card>
              </PageSection.Content>
            </PageSection>
          )}

          <Grid centerX>
            <SummonOverlay
              overlay={
                <SubscriptionPlanPaymentOverlay
                  requiredProducts={requiredProducts}
                  oneTimeRequiredProducts={oneTimeRequiredProducts}
                  onSuccess={onSuccess}
                />
              }
            >
              <MarketingCard
                showButton
                glyph="star"
                variant="white"
                layout="vertical"
                title="Start Your Subscription"
                description="Click the button to activate your account"
                buttonLabel="Subscribe"
                buttonGlyph="star"
              />
            </SummonOverlay>
          </Grid>

          <PageSection>
            <PageSection.Header>
              <PageSection.Title title="Platform Base" />
            </PageSection.Header>

            <PageSection.Content>
              {isPlansEmpty ? (
                <Card>
                  <State
                    isEmpty
                    icon="certificate"
                    title="Platform Base"
                    emptyDescription="There is no Platform Base to choose. Please contact us to set it up."
                    emptyActions={<Button glyph="chat" label="Contact Us" onClick={() => window.Beacon('open')} />}
                  />
                </Card>
              ) : (
                <ProductsGrid>{PLANS()}</ProductsGrid>
              )}
            </PageSection.Content>
          </PageSection>

          {!isAddonsEmpty && !isRequiredAddonsEmpty && (
            <PageSection>
              <PageSection.Header>
                <PageSection.Title title="Plans" />
              </PageSection.Header>

              <PageSection.Content>
                <ProductsGrid>{REQUIRED_ADDONS()}</ProductsGrid>
              </PageSection.Content>
            </PageSection>
          )}

          {!isAddonsEmpty && !isRemainingAddonsEmpty && (
            <PageSection>
              <PageSection.Header>
                <PageSection.Title title="Add-On's" />
              </PageSection.Header>

              <PageSection.Content>
                <ProductsGrid>{REMAINING_ADDONS()}</ProductsGrid>
              </PageSection.Content>
            </PageSection>
          )}

          {!isServicesEmpty && (
            <PageSection>
              <PageSection.Header>
                <PageSection.Title title="Services" />
              </PageSection.Header>

              <PageSection.Content>
                <ProductsGrid>{SERVICES()}</ProductsGrid>
              </PageSection.Content>
            </PageSection>
          )}

          <Grid gap="1rem" centerX>
            <h3>Have questions?</h3>
            <Grid gap="1rem">
              <a href="tel:(650) 338-4113">Call us at (650) 338-4113</a>
              <Button glyph="chat" label="Message us" color="green" onClick={() => window.Beacon('open')} />
            </Grid>
            <Button type="link" label="Log Out" color="red" link="/logout" className="mt-[100px]" />
          </Grid>

          <PortalsContainer />
        </PageLayout>
      </Page>
    </AppLayout>
  )
}

const styles = {
  divider: {
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
  },
}

export default SelectPlan
