import React from 'react'

import Chart from '../Chart'

type Props = {
  data: any[]
  xData: any[]
  yData: any[]
}

const HeatmapChart: React.FC<Props> = ({ data, xData, yData, ...rest }) => {
  return (
    <Chart
      option={{
        tooltip: {
          position: 'top',
        },
        animation: false,
        grid: {
          height: '50%',
          top: '10%',
        },
        xAxis: {
          type: 'category',
          data: xData,
          splitArea: {
            show: true,
          },
        },
        yAxis: {
          type: 'category',
          data: yData,
          splitArea: {
            show: true,
          },
        },
        visualMap: {
          min: 0,
          max: 10,
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '15%',
        },
        series: [
          {
            type: 'heatmap',
            data,
          },
        ],
      }}
      {...rest}
    />
  )
}

export default HeatmapChart
