import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'blue',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'red',
  },
  pending_review: {
    label: 'Pending Review',
    color: 'yellow',
  },
  client_draft: {
    label: 'Draft',
    color: 'blue',
  },
  client_updates_required: {
    label: 'Client Updates Required',
    color: 'red',
  },
  employee_draft: {
    label: 'Draft',
    color: 'blue',
  },
  employee_pending_review: {
    label: 'Pending Staff Review',
    color: 'yellow',
  },
  supervisor_pending_review: {
    label: 'Pending Supervisor Review',
    color: 'yellow',
  },
  employee_updates_required: {
    label: 'Staff Updates Required',
    color: 'red',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
  collected: {
    label: 'Collected',
    color: 'blue',
  },
  refused: {
    label: 'Refused Collection',
    color: 'red',
  },
  not_available: {
    label: 'N/A',
    color: 'gray',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
