import React from 'react'
import { useParams } from 'react-router-dom'
import produce from 'immer'

import withSettings from '../../../hocs/withSettings'
import { COLORS } from '../../../theme'

import { usDateTime } from '../../../utils/functions'

import Card from '../../../components/Card'
import Flex from '../../../components/Flex'
import Icon from '../../../components/Icon'
import DataList from '../../../components/DataList'
import Avatar from '../../../components/Avatar'
import Glyph from '../../../components/Glyph'
import BedOccupancyStatus from '../../../components/Statuses/BedOccupancyStatus'

import ContextShow from '../../../components/Forms/ContextShow'
import BedSelector from '../../../components/Forms/BedSelector'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Workflow from '../../../components/Workflow/Workflow'

const BedAssignment = (props: any) => {
  const { data, defaultFromDate, defaultStatus = 'reserved', hasCurrentOccupancy, defaultUntilDate, setData, setStatus, timezone } = props

  const { resource_id }: any = useParams()

  const form = React.useRef()
  const [valid, setValid] = React.useState()

  const { goNext, requiredSteps }: any = React.useContext(Workflow.Context)
  const { isRequired }: any = React.useContext(Workflow.PanelContext)

  const [place, setPlace] = React.useState(data?.occupancy?.place)
  const [fromDate, setFromDate] = React.useState(data?.occupancy?.started_at || defaultFromDate)
  const [untilDate, setUntilDate] = React.useState(data?.occupancy?.ended_at || defaultUntilDate)

  const handleUpdate = (value: any) => {
    const newValue = produce(value, (draft: any) => {
      draft['occupancy']['place'] = place
    })

    setData(newValue)
  }

  React.useEffect(() => {
    valid ? setStatus('completed') : setStatus('todo')
  }, [valid])

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  return (
    <Form useLocalModel getForm={form} initialModel={data} onValidationUpdate={setValid} onUpdate={handleUpdate} timezone={timezone}>
      {({ data: formData }: any) => {
        const fromLabel = formData?.occupancy?.status === 'reserved' ? 'Reserve Bed From' : 'Occupy Bed From'
        const untilLabel = formData?.occupancy?.status === 'reserved' ? 'Bed Reserved Until' : 'Bed Occupied Until'
        const periodLabel = formData?.occupancy?.status === 'reserved' ? 'Bed Reserved Until…' : 'Bed Occupied Until…'

        return (
          <FormSection maxWidth="100%">
            {hasCurrentOccupancy && (
              <Card css={{ padding: '0.2rem 0' }}>
                <Flex centerY gap="0.75rem" css={{ padding: '0.2rem 1rem' }}>
                  <Icon icon="beds" />
                  <div css={{ fontWeight: 700, fontSize: '1.2rem' }}>Current Assignment</div>
                </Flex>

                <DataList isCompact withPadding>
                  <DataList.Item label="Bed Assignment Type" value={<BedOccupancyStatus status={data.occupancy?.status} />} />

                  <DataList.Item
                    label="Location"
                    value={
                      <Flex centerY gap="0.4rem">
                        <Avatar src={data.occupancy?.place?.house?.avatar} initials={data.occupancy?.place?.house?.name} size={24} />
                        <div className="!font-[600]">{data.occupancy?.place?.house?.name}</div>
                      </Flex>
                    }
                  />

                  <DataList.Item
                    label="Current Bed"
                    value={
                      <Flex centerY gap="0.5rem">
                        <div>
                          <Flex nowrap centerY gap="0.3rem">
                            <Icon icon="floors" size={20} />
                            <div>{data.occupancy?.place?.floor?.name}</div>
                          </Flex>
                        </div>

                        <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                        <div>
                          <Flex nowrap centerY gap="0.3rem">
                            <Icon icon="rooms" size={20} />
                            <div>{data.occupancy?.place?.room?.name}</div>
                          </Flex>
                        </div>

                        <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                        <div>
                          <Flex nowrap centerY gap="0.3rem">
                            <Icon icon="beds" size={20} />
                            <div>{data.occupancy?.place?.name}</div>
                          </Flex>
                        </div>
                      </Flex>
                    }
                  />

                  <DataList.Item
                    label={data.occupancy?.status === 'occupied' ? 'Occupied From' : 'Reserved From'}
                    value={usDateTime(data.occupancy?.started_at, timezone)}
                  />
                  <DataList.Item
                    label={data.occupancy?.status === 'occupied' ? 'Occupied Until' : 'Reserved Until'}
                    value={data.occupancy?.period_type === 'indefinite' ? 'Discharged' : usDateTime(data.occupancy?.ended_at, timezone)}
                  />
                </DataList>
              </Card>
            )}

            <RadioGroup
              label="Bed Assignment Type"
              model="occupancy.status"
              validations={{
                presence: {
                  message: 'Please select a Bed Status',
                },
              }}
              layout="horizontal-dense"
              defaultValue={defaultStatus}
            >
              <Radio label="Bed Reserved" value="reserved" />
              <Radio label="Bed Occupied" value="occupied" />
            </RadioGroup>

            <DateTimeInput
              defaultToNow
              label={fromLabel}
              model="occupancy.started_at"
              value={fromDate}
              onUpdate={({ value }) => {
                setFromDate(value)
              }}
              validations={{
                presence: {
                  message: 'Please enter a start date',
                },
              }}
            />

            <RadioGroup label={periodLabel} model="occupancy.period_type" layout="horizontal-dense" defaultValue="indefinite">
              <Radio label="Client Discharged" value="indefinite" />
              <Radio label="Date" value="set_datetime" />
            </RadioGroup>

            <ContextShow when="occupancy.period_type" is="set_datetime">
              <DateTimeInput
                defaultToTomorrow
                label={untilLabel}
                model="occupancy.ended_at"
                value={untilDate}
                onUpdate={({ value }) => {
                  setUntilDate(value)
                }}
                validations={{
                  presence: {
                    message: 'Please enter an end date',
                  },
                  datetime: {
                    earliest: fromDate,
                    message: `Please enter a date after "${fromLabel}"`,
                  },
                }}
              />
            </ContextShow>

            <BedSelector
              quickFinderEnabled
              label="Assigned Bed"
              model="occupancy.place"
              clientId={resource_id}
              fromLabel={fromLabel}
              untilLabel={untilLabel}
              fromDate={fromDate}
              setFromDate={setFromDate}
              setUntilDate={setUntilDate}
              untilDate={untilDate}
              afterChange={({ value }: any) => {
                setPlace(value)
              }}
              validations={
                isRequired && {
                  presence: {
                    message: 'Please select a bed',
                  },
                }
              }
            />

            <Workflow.Buttons>
              <Workflow.ContinueButton onClick={goNext} isDisabled={!valid} />
              {!isRequired && <Workflow.SkipButton onClick={onSkip} />}
            </Workflow.Buttons>
          </FormSection>
        )
      }}
    </Form>
  )
}

export default withSettings(BedAssignment)
