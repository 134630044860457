import React from 'react'
import { useSelector } from 'react-redux'

import { Button, Page, PageLayout } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useAPI from '@behavehealth/hooks/useAPI'

const ChargeNotes = ({ match }) => {
  const { financial_plan_id } = match.params
  const transactions = useSelector((state) => state.data?.transactions?.data)
  useAPI('transactions', `/financialplans/${financial_plan_id}/transactions`)

  return (
    <Page
      title="Charge Notes"
      icon="financials"
      actions={
        <Button
          label="Add Charge Note"
          link={{
            pathname: `${match.url}/new`,
            state: { parentURL: match.url },
          }}
          glyph="add"
          type="primary"
          display="inline-flex"
        />
      }
    >
      <PageLayout>
        <pre>{JSON.stringify({ transactions }, null, 2)}</pre>
      </PageLayout>
    </Page>
  )
}

export default withPageError(ChargeNotes)
