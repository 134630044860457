import React from 'react'
import { useParams } from 'react-router-dom'

import { useCreate, useUpdate } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import DataFormStatus from '../../components/Statuses/DataFormStatus'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import RichTextEditor from '../../components/Forms/RichTextEditor'
import Section from '../../components/Section'
import SignaturePad from '../../components/Forms/SignaturePad'

export const DataFormAddendumOverlay = (props: any) => {
  const { data, reference, onClose } = props
  const { id }: any = useParams()

  const form: any = React.useRef()
  const [valid, setValid] = React.useState(false)

  const { mutateAsync: createAddendum, isLoading: isCreating }: any = useCreate({
    name: ['create-addendum'],
    url: `/addendums`,
    invalidate: 'data_forms',
  })

  const { mutateAsync: updateAsync, isLoading: isUpdating } = useUpdate({
    name: ['update_addendum'],
    url: `/addendums/${data?.id}`,
    invalidate: 'data_forms',
  })

  const isLoading = isCreating || isUpdating

  const handleSave = async () => {
    try {
      const formData = form.current.getFormValue()

      await createAddendum({
        reference_id: reference?.id,
        reference_type: reference?.type,
        ...formData,
      })

      onClose?.()
    } catch (error) {
      console.error(error)
    }
  }

  const handleUpdate = async () => {
    try {
      const formData = form.current.getFormValue()

      await updateAsync(formData)

      onClose?.()
    } catch (error) {
      console.error(error)
    }
  }

  const isNew = !data?.id

  return (
    <Overlay showBackdrop position="center" maxWidth={70} onClose={onClose}>
      <Overlay.Header title="Addendum" glyph="note" description={!isNew && <DataFormStatus status={data?.status} />} />

      <Overlay.Content>
        <Form getForm={form} initialModel={data} onValidationUpdate={setValid}>
          <Section>
            <FormSection maxWidth="100%">
              <Flex gap="1rem" stretchChildrenX>
                <Input
                  label="Title"
                  model="title"
                  validations={{
                    presence: {
                      message: 'Please add a title',
                    },
                  }}
                />

                <ObjectSelector
                  isPolymorphic
                  label="Supervisor"
                  blankLabel="Select Supervisor…"
                  icon="employees"
                  type="employees"
                  model="supervisor"
                  selectTitle={(data) => data.name}
                  validations={{
                    presence: {
                      message: 'Please select a Supervisor',
                    },
                  }}
                />
              </Flex>

              {/* <Flex gap="1rem" stretchChildrenX>
                <Select allowEmpty label="Reference Field" model="connected_input">
                  <optgroup label="Custom">
                    <Option label="Other" value="other" />
                  </optgroup>
                </Select>

                <ContextShow when="connected_input" is="other">
                  <Input label="Other Reference Field" model="connected_input_other" />
                </ContextShow>
              </Flex> */}

              <RichTextEditor useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Section>
            {data?.author_signature && (
              <SignaturePad
                label="Author Signature"
                person={data.author}
                allowPin={false}
                model="author_signature"
                signedAtModel="author_signed_at"
              />
            )}

            {data?.supervisor_signature && (
              <SignaturePad
                label="Supervisor Signature"
                person={data.supervisor}
                allowPin={false}
                model="supervisor_signature"
                signedAtModel="supervisor_signed_at"
              />
            )}
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isNew ? (
          <Button
            label="Save as Draft"
            type="primary"
            color="green"
            isLoading={isLoading}
            onClick={handleSave}
            isDisabled={!valid || isLoading}
          />
        ) : (
          <Button
            label="Save Changes"
            type="primary"
            color="green"
            isLoading={isLoading}
            onClick={handleUpdate}
            isDisabled={!valid || isLoading}
          />
        )}
      </Overlay.Footer>
    </Overlay>
  )
}
