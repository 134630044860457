import React from 'react'

import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Input from '../../Forms/Input'
import Textarea from '../../Forms/Textarea'
import Tooltip from '../../Tooltip'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

type Props = {
  isHealthcare: boolean
}

export const ClientsFields: React.FC<Props> = ({ isHealthcare }) => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="clients" />
        <Type as="h2" variant="H3">
          Clients
        </Type>
      </Flex>

      <FormSection horizontal>
        <Input
          type="number"
          size={6}
          label="Current Client Census"
          defaultValue={1}
          suffix="clients"
          model="questionnaire.clients_information.current_census"
        />
        <Input
          type="number"
          size={6}
          label="Current Client Max Capacity"
          defaultValue={1}
          suffix="clients"
          model="questionnaire.clients_information.current_capacity"
        />
      </FormSection>

      {isHealthcare && (
        <>
          <Textarea
            label="Session Frequency – How often do you provide services to your clients?"
            labelAfter={
              <Tooltip content="e.g. our client session frequency ranges from once a month to once a week for clients who need a higher level of intensity." />
            }
            model="questionnaire.clients_information.session_frequency"
            validations={{
              length: {
                minimum: 10,
                tooShort: 'Please enter %{count} or more characters',
              },
            }}
          />

          <Textarea
            label="Duration of Care – How long do your clients typically utilize your services?"
            labelAfter={
              <Tooltip content="e.g. our typical client completes our program in 6 months.  We also offer a shorter 3 month program." />
            }
            model="questionnaire.clients_information.duration_of_care"
            validations={{
              length: {
                minimum: 10,
                tooShort: 'Please enter %{count} or more characters',
              },
            }}
          />

          <Textarea
            label="Client Population – Who is a typical client and do you serve any special  populations?"
            labelAfter={
              <Tooltip content="e.g. We offer a pregnancy program for women, a veterans program, and an anger management track" />
            }
            model="questionnaire.clients_information.client_population"
            validations={{
              length: {
                minimum: 10,
                tooShort: 'Please enter %{count} or more characters',
              },
            }}
          />
        </>
      )}
    </FormSection>
  )
}

export const Clients: React.FC<Props> = ({ isHealthcare }) => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <ClientsFields isHealthcare={isHealthcare} />
    </Form>
  )
}
