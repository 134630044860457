import React from 'react'

import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextHide from '../../components/Forms/ContextHide'
import ContextShow from '../../components/Forms/ContextShow'
import Diagnoses from '../../components/SmartContent/Diagnoses'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Medications from '../../components/SmartContent/Medications'
import PhoneInput from '../../components/Forms/PhoneInput'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Switch from '../../components/Forms/Switch'
import Textarea from '../../components/Forms/Textarea'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'

import CareCoordination from '../../components/Overlays/pages/DataForms/common/CareCoordination'
import MentalStatusExam from '../../components/Overlays/pages/DataForms/common/MentalStatusExam'
import OtherDiagnosisCodes from '../../components/Overlays/pages/DataForms/common/OtherDiagnosisCodes'
import PastMedicalHistory from '../../components/Overlays/pages/DataForms/common/PastMedicalHistory'
import PastMedications from '../../components/Overlays/pages/DataForms/common/PastMedications'
import SubstanceUseDiagnosisCodes from '../../components/Overlays/pages/DataForms/common/SubstanceUseDiagnosisCodes'

import SubstanceUseHistory from '../../components/Overlays/pages/DataForms/common/SubstanceUseHistory'
import SuicideSafetyRisk from '../../components/Overlays/pages/DataForms/common/SuicideSafetyRisk'
import TreatmentHistory from '../../components/Overlays/pages/DataForms/common/TreatmentHistory'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootPsychiatricAssessmentOverlay = () => (
  <DataFormOverlay
    title="Psychiatric Evaluation"
    category="medical_assessment"
    subcategory="psychiatric_assessment"
    icon="medical_assessments"
    helpID="medical_assessments_psychiatric_assessment"
    requestClientSignature={false}
    requireSupervisor={true}
    requestSupervisorSignature={true}
    enableTreatmentPlanSelector
  >
    {({ client, data, isEditable }) => (
      <>
        {/* Suicide/Safety Risk */}
        <Section
          title="Suicide/Safety Risk"
          aside={<Switch horizontal inverse model="data.suicide_safety_risk.not_applicable" />}
          scrollview={{
            id: 'suicide_safety_risk',
            name: 'Suicide/Safety Risk',
          }}
          commentsModel="data.suicide_safety_risk"
        >
          <ContextHide when="data.suicide_safety_risk.not_applicable" is={true}>
            <SuicideSafetyRisk />
          </ContextHide>
        </Section>

        <Divider />

        {/* Current Psychiatric Medications */}
        <Section
          title="Current Psychiatric Medications"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.medications.not_applicable" />}
          scrollview={{
            id: 'medications',
            name: 'Current Psychiatric Medications',
          }}
          commentsModel="data.medications"
        >
          <ContextHide when="data.medications.not_applicable" is={true}>
            <FormSection>
              <Medications
                model="data.medications.meds"
                isEditable={isEditable}
                client={client}
                icon="medications"
                title="Medications"
                emptyDescription="No medications added yet"
              />
              <YesNoRadioGroup
                label="Does the client feel that their current medications are effective?"
                model="data.medications.are_current_meds_effective"
              />
            </FormSection>
          </ContextHide>
        </Section>

        <Divider />

        {/* Past Medications */}
        <Section
          title="Past Medications"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.past_medications.not_applicable" />}
          scrollview={{
            id: 'past_medications',
            name: 'Past Medications',
          }}
          commentsModel="data.past_medications"
        >
          <ContextHide when="data.past_medications.not_applicable" is={true}>
            <PastMedications />
          </ContextHide>
        </Section>

        {/* Medical History */}
        <Divider />

        <Section
          title="Medical History"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.past_medical_history.not_applicable" />}
          scrollview={{
            id: 'past_medical_history',
            name: 'Medical History',
          }}
          commentsModel="data.past_medical_history"
        >
          <ContextHide when="data.past_medical_history.not_applicable" is={true}>
            <PastMedicalHistory />
          </ContextHide>
        </Section>

        <Divider />

        {/* ​Psychiatric History */}
        <Section
          title="​Psychiatric History"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.psychiatric_history.not_applicable" />}
          scrollview={{
            id: 'psychiatric_history',
            name: '​Psychiatric History',
          }}
          commentsModel="data.psychiatric_history"
        >
          <ContextHide when="data.psychiatric_history.not_applicable" is={true}>
            <FormSection layout="vertical">
              <RadioGroup
                label="Has client been hospitalized for psychiatric reasons?"
                model="data.psychiatric_history.has_been_hospitalized"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.psychiatric_history.has_been_hospitalized" is={true}>
                <Textarea
                  label="Please explain (include dates, location and length of stay)"
                  model="data.psychiatric_history.has_been_hospitalized_extra"
                />
              </ContextShow>

              <RadioGroup
                label="Does client have a current therapist or counselor?"
                model="data.psychiatric_history.has_therapist_or_counselor"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.psychiatric_history.has_therapist_or_counselor" is={true}>
                <Input label="Therapist or Counselor Name" model="data.psychiatric_history.therapist_counselor.name" />
                <PhoneInput label="Therapist or Counselor Phone" model="data.psychiatric_history.therapist_counselor.phone_no" />

                <RadioGroup
                  label="Will care be coordinated with this provider?"
                  model="data.psychiatric_history.therapist_counselor.will_care_coordinate"
                  layout="horizontal-dense"
                >
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>
              </ContextShow>
            </FormSection>
          </ContextHide>
        </Section>

        <Divider />

        {/* Substance Use History */}
        <Section
          title="Substance Use History"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.substance_use_history.not_applicable" />}
          scrollview={{
            id: 'substance_use_history',
            name: 'Substance Use History',
          }}
          commentsModel="data.substance_use_history"
        >
          <ContextHide when="data.substance_use_history.not_applicable" is={true}>
            <SubstanceUseHistory />
          </ContextHide>
        </Section>

        <Divider />

        {/* Treatment History */}
        <Section
          title="Treatment History"
          aside={<Switch horizontal inverse model="data.treatment_history.not_applicable" />}
          scrollview={{
            id: 'treatment_history',
            name: 'Treatment History',
          }}
          commentsModel="data.treatment_history"
        >
          <ContextHide when="data.treatment_history.not_applicable" is={true}>
            <TreatmentHistory />
          </ContextHide>
        </Section>

        <Divider />

        {/* Mental Status Exam */}
        <Section
          title="Mental Status Exam"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.mental_status_exam.not_applicable" />}
          scrollview={{
            id: 'mental_status_exam',
            name: 'Mental Status Exam',
          }}
          commentsModel="data.mental_status_exam"
        >
          <ContextHide when="data.mental_status_exam.not_applicable" is={true}>
            <MentalStatusExam />
          </ContextHide>
        </Section>

        <Divider />

        {/* Care Coordination */}
        <Section
          title="Care Coordination"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.care_coordination.not_applicable" />}
          scrollview={{
            id: 'care_coordination',
            name: 'Care Coordination',
          }}
          commentsModel="data.care_coordination"
        >
          <ContextHide when="data.care_coordination.not_applicable" is={true}>
            <CareCoordination />
          </ContextHide>
        </Section>

        <Divider />

        {/* Diagnostic Criteria */}
        <Section
          title="Diagnoses"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.diagnoses.not_applicable" />}
          scrollview={{
            id: 'diagnoses',
            name: 'Diagnoses',
          }}
          commentsModel="data.diagnoses_section"
        >
          <ContextHide when="data.diagnoses.not_applicable" is={true}>
            <Diagnoses
              model="data.diagnoses.diagnoses"
              isEditable={isEditable}
              client={client}
              icon="diagnosis"
              title="Diagnoses"
              emptyDescription="No diagnoses added yet"
            />
          </ContextHide>
        </Section>

        <Divider />

        {/* Diagnostic Criteria */}
        {data?.diagnoses?.substance && (
          <>
            <Section
              title="Previous Diagnoses Section"
              headingType="h2"
              scrollview={{
                id: 'diagnostic_criteria',
                name: 'Previous Diagnoses Section',
              }}
              commentsModel="data.previous_diagnoses_section"
            >
              <ContextHide when="data.diagnoses.not_applicable" is={true}>
                <FormSection>
                  <SubstanceUseDiagnosisCodes />
                  <OtherDiagnosisCodes />

                  <Textarea label="Chief complaint:" model="data.diagnoses.chief_complaint" />
                  <Textarea label="History of present illness:" model="data.diagnoses.history_of_present_illness" />
                </FormSection>
              </ContextHide>
            </Section>

            <Divider />
          </>
        )}

        <Section
          title="Summary"
          headingType="h2"
          aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
          scrollview={{
            id: 'summary',
            name: 'Summary',
          }}
          commentsModel="data.summary"
        >
          <ContextHide when="data.summary.not_applicable" is={true}>
            <FormSection>
              <CheckboxGroup label="Strengths & Assets" layout="vertical-dense">
                <Checkbox label="Physical Health" model="data.summary.strengths_assets.physical_health" />
                <Checkbox label="Family Support" model="data.summary.strengths_assets.family_support" />
                <Checkbox label="Cooperative" model="data.summary.strengths_assets.cooperative" />
                <Checkbox label="Communication Skils" model="data.summary.strengths_assets.communication_skils" />
                <Checkbox label="Capacity to perform ADL's" model="data.summary.strengths_assets.capacity_to_perform_adl_s" />
                <Checkbox label="Other" model="data.summary.strengths_assets.other" />
              </CheckboxGroup>

              <ContextShow when="data.summary.strengths_assets.other" is={true}>
                <Textarea label="Other:" model="data.summary.strengths_assets.other_extra" />
              </ContextShow>

              <Textarea label="Liabilities & Barriers to Recovery" model="data.summary.liabilities_barriers_to_recovery" />
              <Textarea label="Interpretation" model="data.summary.interpretation" />

              <CheckboxGroup label="Justification for Treatment" layout="vertical-dense">
                <Checkbox
                  label="Acute history of psychoactive substance abuse"
                  model="data.summary.justification_for_treatment.acute_psychoactive_substance_abuse"
                />
                <Checkbox
                  label="Acute history of inability to maintain long-term abstinence from the use of psychoactive substances"
                  model="data.summary.justification_for_treatment.acute_inability_for_long_term_abstinence_from_psychoactive_substances"
                />
                <Checkbox
                  label="Danger to self or others with need for controlled environment"
                  model="data.summary.justification_for_treatment.danger_to_self_or_others"
                />
                <Checkbox label="Other:" model="data.summary.justification_for_treatment.other" />
              </CheckboxGroup>

              <ContextShow when="data.summary.justification_for_treatment.other" is={true}>
                <Textarea label="Other:" model="data.summary.justification_for_treatment.other_extra" />
              </ContextShow>

              <Textarea label="Additional Relevant Information" model="data.summary.additional_information" />

              <CheckboxGroup label="Recommendation - Appropriate Levels of Care" layout="vertical-dense">
                <Checkbox label="Detoxification (DTX)" model="data.summary.recommended_level_of_care.dtx" />
                <Checkbox label="24 hour monitoring in Residential (RTC) Enviroment" model="data.summary.recommended_level_of_care.rtc" />
                <Checkbox
                  label="Partial Hospitalization (PHP) with Supportive Housing"
                  model="data.summary.recommended_level_of_care.php"
                />
                <Checkbox label="Intensive Outpatient (IOP)" model="data.summary.recommended_level_of_care.iop" />
                <Checkbox label="Outpatient (OP)" model="data.summary.recommended_level_of_care.op" />
              </CheckboxGroup>

              <CheckboxGroup label="Recommendation - Appropriate Treatment Services" layout="vertical-dense">
                <Checkbox label="Medication Management" model="data.summary.recommended_treatment_services.medication_management" />
                <Checkbox
                  label="Health and Physical Evaluation"
                  model="data.summary.recommended_treatment_services.health_and_physical_evaluation"
                />
                <Checkbox label="Individual Psychotherapy" model="data.summary.recommended_treatment_services.individual_psychotherapy" />
                <Checkbox label="Group Psychotherapy" model="data.summary.recommended_treatment_services.group_psychotherapy" />
                <Checkbox label="Family Psychotherapy" model="data.summary.recommended_treatment_services.family_psychotherapy" />
                <Checkbox label="Psychoeducational Groups" model="data.summary.recommended_treatment_services.psychoeducational_groups" />
                <Checkbox label="Case Management" model="data.summary.recommended_treatment_services.case_management" />
                <Checkbox label="Aftercare Plan" model="data.summary.recommended_treatment_services.aftercare_plan" />
                <Checkbox label="Other:" model="data.summary.recommended_treatment_services.other" />
              </CheckboxGroup>

              <ContextShow when="data.summary.recommended_treatment_services.other" is={true}>
                <Textarea label="Other:" model="data.summary.recommended_treatment_services.other_extra" />
              </ContextShow>

              <Textarea label="Additional Recommendations" model="data.summary.additional_recommendations" />

              <YesNoRadioGroup
                label="Client has been fully informed by the provider regarding the risks and benefits of the treatment offered by the facility. The client has expressed to the provider an understanding of the explanations of the provider."
                model="data.summary.has_client_been_informed_of_risks_and_benefits"
              />

              <YesNoRadioGroup
                label="Was greater than 50% of appointment spent on counseling and coordination of care?"
                model="data.summary.did_spend_half_or_more_on_counseling_and_coordination_of_care"
              />
            </FormSection>
          </ContextHide>
        </Section>
      </>
    )}
  </DataFormOverlay>
)

export const PsychiatricAssessmentOverlay = withOverlayError(RootPsychiatricAssessmentOverlay)
