import { MENTAL_STATUS_EXAM_FIELDS, SNAP_FIELDS } from './common'

export const MENTAL_STATUS_EXAM_SECTIONS = [
  {
    title: 'Mental Status Exam',
    model: 'mental_status_exam',
    fields: MENTAL_STATUS_EXAM_FIELDS,
  },
  {
    title: 'Strengths, Needs, Abilities, Preferences',
    model: 'snap',
    fields: SNAP_FIELDS,
  },
]
