import React from 'react'
import { beautifulAmount } from '../../utils/functions'

import Tooltip from '../../components/Tooltip'

export const InvoiceFeesInfo = ({ invoice = {} }: any) => {
  if (!invoice) return null

  // no info to display if payer is not paying the fee
  if (!invoice?.pass_fee_to_payer) return null

  // hide info bar is invoice is paid
  if (invoice?.status === 'paid' || invoice?.status === 'processing') return null

  return (
    <Tooltip
      content={
        <>
          <div>
            Please note, a payment processing fee will apply <br /> to this invoice based on your selected payment method:
          </div>

          <ul className="pl-6 mt-2">
            <li>
              <span className="font-[600]">Card Payment Total Fees:</span>
              {beautifulAmount(invoice?.calculated_amounts?.card_fees)}
            </li>

            <li>
              <span className="font-[600]">ACH Payment Total Fees:</span>
              {beautifulAmount(invoice?.calculated_amounts?.ach_fees)}
            </li>
          </ul>
        </>
      }
    ></Tooltip>
  )
}
