import React from 'react'

import { COLORS } from '../theme'

import { H3, Text } from './Typography'
import Avatar from './Avatar'
import Card from './Card'
import EmailButton from './Buttons/EmailButton'
import Flex from './Flex'
import Grid from './Grid'
import Link from './Link'
import PhoneButton from './Buttons/PhoneButton'
import Status from './Status'
import ZoomButton from './Buttons/ZoomButton'

const PopupStatus = ({ type, gender }: any) => {
  if (type === 'house') {
    if (gender) return <Status color="blue" label={`${gender} property`} />
    return null
  } else if (type === 'organization') {
    return <Status color="blue" label="Organization" />
  }

  return null
}

const MapPopup = ({ location, to }: any) => {
  if (!location) return null

  return (
    <Card css={styles.card}>
      <Grid gap={10}>
        <PopupStatus type={location.type} gender={location.gender} />

        <Flex gap={10}>
          <Avatar initials={location.name} src={location.avatar} />
          <div>
            {/* <Link to={`${REDIRECT_BASE_URL[location.type]}/${location.id}`}> */}
            <Link to={to}>
              <H3 color={COLORS.blue}>{location.name} →</H3>
            </Link>
            <Text css={{ marginTop: 5 }}>{location.address}</Text>
          </div>
        </Flex>

        <Flex gap={5}>
          {location?.phone_no && <PhoneButton label="Call" phone_no={location.phone_no} css={styles.button} />}
          {location?.email && <EmailButton label="Email" email={location.email} css={styles.button} />}
          {location?.zoom && <ZoomButton label="Zoom" zoom={location.zoom} css={styles.button} />}
        </Flex>
      </Grid>
    </Card>
  )
}

const styles = {
  card: { padding: '1rem' },
  button: { flex: 1 },
}

export default MapPopup
