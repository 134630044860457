import React from 'react'

import Alert from '../../../components/Alert'
import CardTreeItem from '../../../components/CardTreeItem'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import FormSection from '../../../components/Forms/FormSection'
import Input from '../../../components/Forms/Input'
import NumberInput from '../../../components/Forms/NumberInput'
import Textarea from '../../../components/Forms/Textarea'
import TableArrayForm from '../../../components/Forms/TableArrayForm'

export const TreatmentPlanPlanReviewsSettings = ({ prefix }: any) => {
  const model = `${prefix ? `${prefix}.` : ''}settings`

  return (
    <>
      <CardTreeItem isOpen title="Review Periods">
        <FormSection className="pt-3 pb-5 pr-4">
          <Alert small contrast glyph="info">
            Set up review periods for the treatment plan to generate review records automatically when creating or importing problems,
            goals, objectives and interventions.
          </Alert>

          <CheckboxGroup label={null} layout="vertical-dense" trueIcon="check" falseIcon="empty_checkbox" falseStyle="faded">
            <Checkbox label="Enable Review Periods" model={`${model}.reviews_config_enabled`} />
          </CheckboxGroup>

          <ContextShow when={`${model}.reviews_config_enabled`} is={true}>
            <TableArrayForm name="Review Periods" model={`${model}.reviews_config`} inputs={INPUTS} />
          </ContextShow>
        </FormSection>
      </CardTreeItem>

      <CardTreeItem isOpen title="Plan Review Notifications">
        <FormSection className="pt-3 pb-5 pr-4">
          <Alert small contrast glyph="info" type="positive">
            Notifications are being sent to the Supervisors and Staff Members added to the Treatment Plan.
          </Alert>

          <Checkbox
            label="Send SMS and Email Reminders"
            model={`${model}.should_send_plan_review_reminders`}
            trueIcon="check"
            falseIcon="empty_checkbox"
            falseStyle="faded-linethrough"
          />

          <ContextShow when={`${model}.should_send_plan_review_reminders`} is={true}>
            <div className="!pl-6">
              <CheckboxGroup
                label="Plan Review Reminders Options"
                layout="vertical-dense"
                validations={{
                  presence: {
                    message: 'Please select at least one option',
                  },
                }}
              >
                <Checkbox label="Seven days before Plan Review date" model={`${model}.send_plan_review_reminders.seven_days`} />
                <Checkbox label="Five days before Plan Review date" model={`${model}.send_plan_review_reminders.five_days`} />
                <Checkbox label="Three days before Plan Review date" model={`${model}.send_plan_review_reminders.three_days`} />
                <Checkbox label="One day before Plan Review date" model={`${model}.send_plan_review_reminders.one_day`} />
                <Checkbox label="Day of Plan Review date" model={`${model}.send_plan_review_reminders.day_of`} />
              </CheckboxGroup>
            </div>
          </ContextShow>
        </FormSection>
      </CardTreeItem>
    </>
  )
}

const INPUTS = [
  {
    name: 'Name',
    width: '2fr',
    element: (id: string) => <Input isCompact withHover={false} model={`${id}.name`} />,
  },
  {
    name: 'Days from Start',
    width: '1fr',
    element: (id: string) => <NumberInput isCompact withHover={false} model={`${id}.days_from_start`} defaultValue={30} />,
  },
  {
    name: 'Description',
    width: '2fr',
    element: (id: string) => <Textarea isCompact withHover={false} model={`${id}.description`} minRows={1} />,
  },
]
