import React from 'react'

import { titleCase } from '../../../utils/functions'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

export const TitleCell: React.FC = (props: any) => {
  return <DataTableCell {...props}>{props.value ? titleCase(props.value) : <EmptyCell />}</DataTableCell>
}
