import React from 'react'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { LocalPayers } from '@behavehealth/declarations'

import { Page, Card } from '@behavehealth/components'
import { InsuranceERAReportsTable } from '@behavehealth/components/Tables'

const pageConfig = {
  feature: 'insurance_era_reports',
}

const ERAs: React.FC = () => {
  const { data, isLoading }: any = useGet({
    name: 'insurance-era-reports',
    url: `/insurance_era_reports`,
  })

  const isEmpty = size(data) <= 0

  return (
    <Page breakpoint="0" actions={!isEmpty} {...pageConfig}>
      <Card>
        <InsuranceERAReportsTable
          useAutoPageSize
          data={data}
          isLoading={isLoading}
          localStorageKey="rcm_insurance_era_reports"
          to={(id) => `insurance-era-reports/${id}`}
        />
      </Card>
    </Page>
  )
}

export default withPageError(withMarketing(ERAs, pageConfig))
