import React from 'react'

import Chart from '@behavehealth/components/Chart'

const option = {
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}%',
  },
  legend: {
    data: ['A', 'B', 'C', 'D', 'E'],
  },
  series: [
    {
      name: 'Data',
      type: 'funnel',
      left: '10%',
      width: '80%',
      label: {
        formatter: '{b}',
      },
      labelLine: {
        show: false,
      },
      itemStyle: {
        opacity: 0.7,
      },
      emphasis: {
        label: {
          position: 'inside',
          formatter: '{b}: {c}%',
        },
      },
      data: [
        { value: 60, name: 'C' },
        { value: 40, name: 'D' },
        { value: 20, name: 'E' },
        { value: 80, name: 'B' },
        { value: 100, name: 'A' },
      ],
    },
    {
      name: 'Data',
      type: 'funnel',
      left: '10%',
      width: '80%',
      maxSize: '80%',
      label: {
        position: 'inside',
        formatter: '{c}%',
        color: '#fff',
      },
      itemStyle: {
        opacity: 0.5,
        borderColor: '#fff',
        borderWidth: 2,
      },
      emphasis: {
        label: {
          position: 'inside',
          formatter: '{b}: {c}%',
        },
      },
      data: [
        { value: 30, name: 'C' },
        { value: 10, name: 'D' },
        { value: 5, name: 'E' },
        { value: 50, name: 'B' },
        { value: 80, name: 'A' },
      ],
      // Ensure outer shape will not be over inner shape when hover.
      z: 100,
    },
  ],
}

const ChartExample = (props) => {
  return <Chart option={option} {...props} />
}

export default ChartExample
