import React from 'react'
import { tint } from 'polished'

import { AIActionsMenu } from '../../../AIActions/AIActionsMenu'
import { COLORS, INPUT_STYLES } from '../../../../theme'
import { useSettings } from '../../../../hooks/useSettings'

import Button from '../../../../components/Button'
import Flex from '../../../../components/Flex'
import Glyph from '../../../../components/Glyph'
import Markup from '../../../../components/Markup'
import Permission from '../../../../components/Permission'

export const RichTextAIActionsEditor = (props: any) => {
  const { activeElement, richTextEditor } = props

  const { isBehave } = useSettings()

  const [aiAction, setAIAction]: any = React.useState(null)
  const [aiResult, setAIResult]: any = React.useState('')
  const [aiError, setAIError]: any = React.useState('')

  const clearAIResult = () => {
    setAIAction(null)
    setAIResult('')
    setAIError('')
  }

  const handleAIResultInsert = () => {
    if (!aiResult || !richTextEditor?.commands) return

    richTextEditor.commands.focus('end')
    richTextEditor.commands.insertContent(aiResult)
  }

  const handleAIResultReplace = () => {
    if (!aiResult || !richTextEditor?.commands) return

    richTextEditor.commands.clearContent()
    richTextEditor.commands.insertContent(aiResult)
  }

  if (!isBehave) return null

  return (
    <div>
      <Permission featureFlagV2="behave_ai">
        <AIActionsMenu
          showChevron
          buttonSize={200}
          className="w-full"
          buttonClassName="w-full"
          buttonLabel="Behave AI Actions"
          getInput={() => activeElement?.config?.content}
          onStartAction={({ action }: any) => {
            setAIAction(action)
            setAIResult('')
            setAIError('')
          }}
          onCompleteAction={({ action, result }: any) => {
            if (!result) {
              setAIError('The AI system could not perform this action')
              return
            }

            setAIAction(action)
            setAIResult(result)
          }}
        />
      </Permission>

      {aiAction && (
        <div
          css={STYLES.aiResult}
          style={{ '--ai-result-background': tint(0.9, aiError ? COLORS.red : aiAction.color || COLORS.blue) } as any}
        >
          <div className="flex-auto">
            <Flex centerY nowrap>
              <div className="w-6 mr-1">
                <Glyph glyph={aiError ? 'circle_error' : aiAction.glyph} color={aiError ? COLORS.red : aiAction.color} size={20} />
              </div>

              <div className="font-bold">{aiAction.name}</div>
            </Flex>

            {(aiResult || aiError) && (
              <div className="pl-6 ml-1">
                {aiResult ? (
                  <div className="mt-4">
                    <Markup value={aiResult} />
                  </div>
                ) : aiError ? (
                  <div className="italic">{aiError}</div>
                ) : null}

                {aiResult && (
                  <div className="mt-2">
                    <Flex gap="0.5rem">
                      <Button
                        label="Insert Below"
                        size={100}
                        color="text"
                        glyph="insert_below"
                        display="inline-flex"
                        onClick={handleAIResultInsert}
                      />
                      <Button
                        label="Replace Text"
                        size={100}
                        color="text"
                        glyph="replace"
                        display="inline-flex"
                        onClick={handleAIResultReplace}
                      />
                    </Flex>
                  </div>
                )}
              </div>
            )}
          </div>

          <button type="button" onClick={clearAIResult} css={STYLES.clearButton}>
            <Glyph glyph="circle_error" color={COLORS.text} size={14} />
          </button>
        </div>
      )}
    </div>
  )
}

const STYLES = {
  aiResult: {
    ...INPUT_STYLES,
    marginTop: '0.75rem',
    background: `var(--ai-result-background)`,
    fontWeight: 500,
    minHeight: 'auto',
    whiteSpace: 'pre-wrap',

    display: 'flex',
    alignItems: 'center',
    padding: '0.4rem',
    fontSize: '0.94rem',
    flexWrap: 'nowrap',
    overflow: 'hidden',

    '& > svg': {
      marginRight: '0.5rem',
    },

    '&.has-transcript': {
      fontWeight: 400,
      fontStyle: 'italic',
    },

    '&:hover': {},
  },

  clearButton: {
    background: 'none',
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    outline: 'none',
    width: 24,
    height: 24,
    opacity: 0.8,

    '&:hover': {
      opacity: 1,
      background: COLORS.hover,
      svg: { fill: COLORS.red },
    },
  },
}
