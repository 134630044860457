import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { withOverlayError } from '../../hocs/withOverlayError'
import { useDataTable } from '../../components/DataTable/useDataTable'

import Button from '../../components/Button'
import Icon from '../../components/Icon'
import PageSection from '../../components/PageSection/PageSection'
import SummonOverlay from '../../components/SummonOverlay'

import { MessageBoardDataTable } from '../MessageBoard/MessageBoardDataTable'

import { CommunityMessageOverlay } from './CommunityMessageOverlay'
import { useCommunityPermissions } from './useCommunityPermissions'

const RootCommunityProfileMessagesTab: React.FC<any> = (props: any) => {
  const { entityId } = props

  const match = useRouteMatch()

  const { canEditCommunityProfiles } = useCommunityPermissions()

  const tableProps: any = useDataTable({
    name: ['community', entityId, 'profile_messages'],
    endpoint: `/community/entities/${entityId}/profile_messages`,
  })

  const to = React.useMemo(
    () => (data: any) => ({
      pathname: `${match.url}/${data.id}`,
      parent: match,
    }),
    [match.url],
  )

  return (
    <div className="grid grid-cols-[100%]">
      <PageSection>
        <PageSection.Header
          graphic={<Icon icon="message_board" />}
          after={
            canEditCommunityProfiles && (
              <SummonOverlay overlay={<CommunityMessageOverlay entityId={entityId} dataID="new" />}>
                <Button label="Add New Message" glyph="add" size={200} type="primary" display="inline-flex" />
              </SummonOverlay>
            )
          }
        >
          <PageSection.Title title="Message Board" />
        </PageSection.Header>
      </PageSection>

      <MessageBoardDataTable {...tableProps} to={to} />
    </div>
  )
}

export const CommunityProfileMessagesTab = withOverlayError(RootCommunityProfileMessagesTab)
