import React from 'react'

import { useSettings } from '../../hooks'

import DataFormStatus from '../Statuses/DataFormStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'
import Flex from '../Flex'
import Button from '../Button'

const columns = (to: Function = () => {}, timezone: string, duplicatePlanLink: Function = () => {}) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'id',
    Header: 'Name',
    Cell: ({ row }: any) => <TableCell.MainLink to={to(row.original)} label={row.original.name} />,
  },
  {
    width: 200,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <DataFormStatus status={value} />,
    Filter: TableFilter.DataFormStatus,
    filter: 'dataFormStatus',
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Last Edited',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
  {
    width: 180,
    id: 'duplicate',
    accessor: 'id',
    Header: 'Create New From',
    Cell: ({ row }) => {
      return (
        <Flex gap="0.5rem">
          <Button
            label="Duplicate Plan"
            glyph="add"
            size={200}
            link={duplicatePlanLink(row.original)}
            permission="treatment_plans.create"
          />
        </Flex>
      )
    },
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  isLoading: boolean
  localStorageKey: string
  to?: Function
  duplicatePlanLink?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
  isBehave?: boolean
}

const TreatmentPlansTable = ({
  to,
  data,
  isLoading,
  emptyActions,
  localStorageKey,
  batchActionsConfig,
  duplicatePlanLink,
  titleAfter,
  isBehave,
}: Props) => {
  const { timezone } = useSettings()

  return (
    <Table
      testKey="treatment_plans_table"
      title="Treatment Plans"
      titleAfter={titleAfter}
      icon="treatment_plans"
      data={data}
      columns={columns(to, timezone, duplicatePlanLink)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No treatment plans added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      isBehave={isBehave}
    />
  )
}

TreatmentPlansTable.defaultProps = {
  localStorageKey: 'treatment_plans',
}

export default TreatmentPlansTable
