import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'

export const TreatmentPlanAppointmentsDataTable = (props: any) => {
  const { appointmentLinkTo, eventLinkTo } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 200,
        formatValue: ({ data, value }: any) => (
          <MainCell as={NavLink} id={data.id} value={value} to={appointmentLinkTo ? appointmentLinkTo(data) : undefined} />
        ),
      },
      {
        title: 'Description',
        model: 'description',
        width: 280,
      },
      {
        title: 'Event',
        model: 'event',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell as={Link} value={value.title} to={eventLinkTo ? eventLinkTo(value) : undefined} />
        },
      },
      {
        title: 'Appointment Time',
        model: 'dated_at',
        type: 'date_time',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
    ],
    [appointmentLinkTo, eventLinkTo],
  )

  return (
    <DataTable
      testKey="treatment_plan_appointments_table"
      title="Treatment Plan Appointments"
      icon="calendar"
      columns={columns}
      {...props}
    />
  )
}
