import React from 'react'
import intersection from 'lodash/intersection'
import size from 'lodash/size'
import uniq from 'lodash/uniq'

import { beautifulAmount, mapToArray, notionIDFormURL } from '../../utils/functions'

import Card from '../Card'
import Glyph from '../Glyph'
import GridTable from '../GridTable'
import Icon from '../Icon'
import Overlay from '../Overlay'
import Type from '../Typography/Type'
import State from '../State'
import Tabs from '../Tabs'
import TabList from '../TabList'
import Tab from '../Tab'
import TabPanels from '../TabPanels'
import TabPanel from '../TabPanel'
import { NotionRenderer } from '../Notion/NotionRenderer'

import { ALL_FEATURES, PRICING_UNIT_TYPES } from './constants'
import { request } from '../../modules/axios'

import {
  GENERAL_FEATURES_KEYS,
  ERP_FEATURES_KEYS,
  CONTACTS_FEATURES_KEYS,
  CHAT_FEATURES_KEYS,
  CARE_COORDINATION_FEATURES_KEYS,
  RCM_FEATURES_KEYS,
  INSURANCE_FEATURES_KEYS,
  NOTES_FEATURES_KEYS,
  MEDICAL_FEATURES_KEYS,
  CLINICAL_FEATURES_KEYS,
  SECURITY_FEATURES_KEYS,
} from './constants'

const getFeatureKeys = (plans: any) => {
  const res: any = []

  Object.values(plans).map((o: any) => {
    res.push(...Object.keys(o.features))
  })

  return uniq(res)
}

const FeatureSection = ({ title, sectionKeys, featureKeys, plans }: any) => {
  const intersectionKeys = intersection(sectionKeys, featureKeys)

  if (size(intersectionKeys) === 0) return null

  return (
    <>
      <GridTable.Row>
        <GridTable.Cell css={styles.featureGroupCell}>
          <Type as="div" variant="CAPS_TITLE_SMALL">
            {title}
          </Type>
        </GridTable.Cell>
      </GridTable.Row>

      {intersectionKeys.map((featureKey: any) => (
        <GridTable.Row key={featureKey}>
          <GridTable.Cell css={styles.featureCell}>
            <Icon icon={ALL_FEATURES[featureKey].icon} size={20} />
            <div>{ALL_FEATURES[featureKey].title}</div>
          </GridTable.Cell>

          {plans.map((plan: any) => (
            <GridTable.Cell key={plan.id} css={styles.glyphCell}>
              <Glyph glyph={plan.features[featureKey] ? 'check' : 'cross'} size={16} />
            </GridTable.Cell>
          ))}
        </GridTable.Row>
      ))}
    </>
  )
}

const FeaturesComparisonOverlay = ({ plans, onClose, notionURL, name }: any) => {
  if (!plans) return null

  const plansArray = mapToArray(plans)
  const plansCount = size(plansArray)

  const featureKeys = getFeatureKeys(plans)

  const [notionData, setNotionData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const [notionID, setNotionID] = React.useState(null)

  const hasNotionPage = !!notionURL

  React.useEffect(() => {
    const getNotionData = async (notionID: string) => {
      setIsLoading(true)

      try {
        const response = await request.get(`${process.env.BH_NOTION_API_BASE}/api/page/${notionID}`, {
          headers: null,
        })
        setNotionData(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (hasNotionPage) getNotionData(notionID)
  }, [notionID])

  React.useEffect(() => {
    if (!hasNotionPage) return

    const id = notionIDFormURL(notionURL)
    if (id) setNotionID(id)
  }, [notionURL])

  return (
    <Overlay showBackdrop closeOnBackdrop maxWidth={75} position="center" onClose={onClose}>
      <Overlay.Header title={name} />

      <Overlay.Content css={styles.overlayContent}>
        <Tabs defaultTab={hasNotionPage ? 'description' : 'comparison'}>
          {hasNotionPage && (
            <TabList className="!mb-4">
              <Tab label="Description" name="description" />
              <Tab label="Plans Comparison" name="comparison" />
            </TabList>
          )}

          <TabPanels>
            <TabPanel name="description">
              <div css={{ minHeight: 35 }}>
                {isLoading ? (
                  <State isLoading css={{ minHeight: 35 }} />
                ) : (
                  <NotionRenderer disableHeader fullWidth recordMap={notionData} darkMode={false} />
                )}
              </div>
            </TabPanel>

            <TabPanel name="comparison" className="!my-4 !mx-0">
              <Card>
                <GridTable templateColumns={`280px repeat(${plansCount}, minmax(180px, 1fr))`}>
                  <GridTable.Header>
                    <GridTable.Cell css={styles.firstCell}>Features Comparison</GridTable.Cell>

                    {plansArray.map((plan) => {
                      const price = plan.pricing_type !== 'free' ? parseFloat(plan.discounted_price || plan.price) : 'free'
                      let priceSuffix = ''
                      if (plan.pricing_type === 'monthly') {
                        priceSuffix = '/ Month'
                      } else if (plan.pricing_type === 'metered_monthly') {
                        priceSuffix = PRICING_UNIT_TYPES[plan.price_unit_type]
                      }

                      return (
                        <GridTable.Cell key={plan.id} css={styles.centerText}>
                          <div>{plan.public_name}</div>

                          <div css={styles.price}>
                            <Type as="span" variant="H3" css={{ textTransform: 'capitalize' }}>
                              {price === 'free' ? 'Free' : beautifulAmount(price)}
                            </Type>

                            {priceSuffix && (
                              <Type as="span" variant="TEXT_SMALL" color="textMuted">
                                {' '}
                                {priceSuffix}
                              </Type>
                            )}
                          </div>
                        </GridTable.Cell>
                      )
                    })}
                  </GridTable.Header>

                  <FeatureSection title="General" sectionKeys={GENERAL_FEATURES_KEYS} featureKeys={featureKeys} plans={plansArray} />
                  <FeatureSection title="ERP" sectionKeys={ERP_FEATURES_KEYS} featureKeys={featureKeys} plans={plansArray} />
                  <FeatureSection title="Contacts" sectionKeys={CONTACTS_FEATURES_KEYS} featureKeys={featureKeys} plans={plansArray} />
                  <FeatureSection title="Chat" sectionKeys={CHAT_FEATURES_KEYS} featureKeys={featureKeys} plans={plansArray} />
                  <FeatureSection
                    title="Care Coordination"
                    sectionKeys={CARE_COORDINATION_FEATURES_KEYS}
                    featureKeys={featureKeys}
                    plans={plansArray}
                  />
                  <FeatureSection title="RCM" sectionKeys={RCM_FEATURES_KEYS} featureKeys={featureKeys} plans={plansArray} />
                  <FeatureSection title="Insurance" sectionKeys={INSURANCE_FEATURES_KEYS} featureKeys={featureKeys} plans={plansArray} />
                  <FeatureSection title="Notes" sectionKeys={NOTES_FEATURES_KEYS} featureKeys={featureKeys} plans={plansArray} />
                  <FeatureSection title="Medical" sectionKeys={MEDICAL_FEATURES_KEYS} featureKeys={featureKeys} plans={plansArray} />
                  <FeatureSection title="Clinical" sectionKeys={CLINICAL_FEATURES_KEYS} featureKeys={featureKeys} plans={plansArray} />
                  <FeatureSection title="Security" sectionKeys={SECURITY_FEATURES_KEYS} featureKeys={featureKeys} plans={plansArray} />
                </GridTable>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Overlay.Content>
    </Overlay>
  )
}

const styles = {
  overlayContent: {
    padding: '0 1.5rem',
  },

  featureCell: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingLeft: '1rem',

    img: {
      marginRight: '0.5rem',
    },
  },

  featureGroupCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    gridColumn: '1 / -1',
  },

  glyphCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  price: {
    textAlign: 'center',
    marginTop: '0.25rem',
  },

  centerText: {
    textAlign: 'center',
  },

  firstCell: {
    display: 'flex',
    alignItems: 'center',
  },
}

export default FeaturesComparisonOverlay
