import React from 'react'
import { lighten } from 'polished'

import { COLORS } from '../../../theme'

import Button from '../../Button'
import Flex from '../../Flex'
import Glyph from '../../Glyph'
import Grid from '../../Grid'
import QuickView from '../../QuickView'
import Text from '../../Typography/Text'

const FileMessage = ({ message }) => {
  return (
    <Grid gap={10}>
      <Text color={COLORS.text}>{message.text}</Text>

      <QuickView record={message.file}>
        <Grid gridTemplateColumns="40px 1fr 40px" gap={10} css={styles}>
          <Flex vertical justifyContent="center" alignItems="center">
            <Glyph glyph="file_pdf" size={30} color={COLORS.text} />
          </Flex>
          <Flex vertical justifyContent="center">
            {message.file?.title}
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <Button type="transparent" glyph="download" glyphSize={20} glyphColor={COLORS.text} />
          </Flex>
        </Grid>
      </QuickView>
    </Grid>
  )
}

const styles = {
  padding: '.3rem 0',
  backgroundColor: lighten(0.65, COLORS.gray),
  boxShadow: `inset 0 0 0 1px ${lighten(0.4, COLORS.gray)}`,
  borderRadius: 5,
  cursor: 'pointer',
}

export default FileMessage
