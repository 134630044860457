import React from 'react'

import { address } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import Timeline from '../../components/Timeline/Timeline'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'

const RootSafetyCheckOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'safety_checks',
    endpoint: '/safety_checks',
    invalidate: 'safety_checks',
    options: props,
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} />
  }

  return (
    <Overlay onClose={close} showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} isDirty={isEditable}>
      <Overlay.Header icon="safety_checks" title="Safety Check" />

      {/* {!isEditable && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/safety_checks/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )} */}

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          key={`updated-${data?.updated_at}`}
        >
          <Section>
            <FormSection maxWidth="100%">
              {!isEditable && <ObjectSelector model="author" label="Written by" type="employees" isEditable={false} icon="employees" />}

              {!isEditable && <DateTimeInput model="created_at" label="Created At" />}

              {!isEditable && data?.updated_at !== data?.created_at && <DateTimeInput model="updated_at" label="Last Updated" />}

              <DateTimeInput
                defaultToNow
                label="Date & Time"
                model="dated_at"
                validations={{
                  presence: {
                    message: 'Please add a date & time',
                  },
                }}
              />

              <MiniRichTextEditor
                useQuickText
                useDictation
                label="Note"
                model="notes"
                validations={{
                  presence: {
                    message: 'Please add a note',
                  },
                }}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="People">
            <FormSection maxWidth="100%" layout="vertical">
              <MultiOverlaySelector
                initialModelRequired={isNew}
                model="employees"
                label="Staff"
                type="employees.active"
                icon={ICONS.employees}
              />

              <OverlaySelector
                initialModelRequired={isNew}
                model="resident"
                label="Client"
                type="clients.current"
                icon={ICONS.clients}
                validations={{
                  presence: {
                    message: 'Please select at least one client',
                  },
                }}
              />
            </FormSection>
          </Section>

          <Section headingType="h2" title="Notifications">
            <FormSection>
              <Alert glyph="notification">Send a notification alert via SMS / Email to the employees selected above</Alert>

              <CheckboxGroup layout="vertical-dense">
                <Checkbox defaultChecked label="Send SMS Notification" model="should_sms" />
                <Checkbox defaultChecked label="Send Email Notification" model="should_email" />
              </CheckboxGroup>
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Places">
            <FormSection maxWidth="100%" layout="vertical">
              <ObjectSelector
                initialModelRequired={isNew}
                model="house"
                label="Location"
                type="properties"
                icon={ICONS.properties}
                selectTitle={(data) => data.name}
                selectDescription={(data) => address(data.address)}
              />

              <ObjectSelector
                initialModelRequired={isNew}
                model="organization"
                label="Organization"
                type="organizations"
                icon={ICONS.organizations}
                selectTitle={(data) => data.name}
                selectDescription={(data) => address(data.address)}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this Safety Check">
            <FormSection maxWidth="100%" layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Safety Check"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="safety_checks.edit"
            />

            <DeleteDialog
              title="Delete Safety Check?"
              message="Are you sure you want to delete this Safety Check? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="safety_checks.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const SafetyCheckOverlay = withOverlayError(RootSafetyCheckOverlay)
