import React from 'react'

import Chart from '../Chart'

import { COLORS } from '../../theme'

type Props = {
  color?: string
  option?: any
  showSymbol?: boolean
  xData: any[]
  yData: any[]
}

const LineChart: React.FC<Props> = ({ xData, yData, showSymbol = true, color = COLORS.blue, option, ...rest }) => {
  return (
    <Chart
      option={{
        tooltip: {
          trigger: 'axis',
        },
        xAxis: [{ type: 'category', data: xData }],
        yAxis: [{ type: 'value' }],
        series: [
          {
            type: 'line',
            showSymbol,
            data: yData,
            color,
          },
        ],
        ...option,
      }}
      {...rest}
    />
  )
}

export default LineChart
