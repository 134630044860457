import React from 'react'

import { apiGet } from '@behavehealth/modules/api'
import { Card, ChartCard, State } from '@behavehealth/components'

import { AlumniGraduationCohortsChart, GenericTreeChart } from '@behavehealth/components/Charts'

import { AnalyticsContext } from '../context'

const OtherAnalytics = () => {
  const { timeframe, renderer } = React.useContext(AnalyticsContext)

  const [loading, setLoading] = React.useState(false)
  const [stats, setStats]: any = React.useState({})

  React.useEffect(() => {
    const getStats = async (timeframe: any) => {
      try {
        setLoading(true)

        const result = await apiGet({
          url: '/metrics_stats/other',
          params: {
            timeframe: timeframe,
          },
          notify: false,
        })

        // update state
        setStats(result.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (!loading) getStats(timeframe)
  }, [timeframe])

  if (loading) {
    return (
      <Card>
        <State isLoading />
      </Card>
    )
  }

  return (
    <>
      <ChartCard title="Location Clients Breakdown" subtitle="Who is assigned to my properties and rooms?" css={styles.fullWidth}>
        <GenericTreeChart timeframe={timeframe} renderer={renderer} data={stats.property_clients_breakdown} height={600} />
      </ChartCard>

      <div css={styles.grid}>
        {/*      
      <ChartCard title="Alumni Graduation Cohorts" subtitle="How many alumni do we have by graduation cohort?">
        <AlumniGraduationCohortsChart timeframe={timeframe} renderer={renderer} />
      </ChartCard> 
      */}

        <ChartCard title="Location Staff Breakdown" subtitle="Which Staff are assigned to my properties?">
          <GenericTreeChart timeframe={timeframe} renderer={renderer} data={stats.property_staff_breakdown} />
        </ChartCard>
        <ChartCard title="Organization Staff Breakdown" subtitle="Which Staff are assigned to my departments ?">
          <GenericTreeChart timeframe={timeframe} renderer={renderer} data={stats.organization_staff_breakdown} />
        </ChartCard>
      </div>
    </>
  )
}

const styles = {
  grid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '100%',

    '@media (min-width: 768px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
    },
  },

  fullWidth: {
    marginBottom: '1rem',
  },
}

export default OtherAnalytics
