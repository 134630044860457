import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { FilesDataTable } from '@behavehealth/constructs/Files/FilesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, CardContent, DnDUploader, Page, Grid, Uploader, HelpTagIframe } from '@behavehealth/components'

const pageConfig = {
  feature: 'files',
  help: <HelpTagIframe id="files" />,
  marketingID: 'files',
}

const Files = ({ canCreate = true }) => {
  const { resource_id }: any = useParams()

  const { data: program }: any = useGet({
    name: ['program', resource_id],
    url: `/programs/${resource_id}`,
  })

  const tableProps: any = useDataTable({
    name: ['program', resource_id, 'files'],
    endpoint: `/programs/${resource_id}/documents`,
    updateDeleteEndpoint: '/documents',
    enabled: !!resource_id,
    localStorageKey: 'program_files_v1',
  })

  const isEmpty = size(tableProps.data) === 0

  const actions = (
    <Uploader category="general" resource={program} queryKey={tableProps.queryKey}>
      <Button label="Upload Files" glyph="add_file" type="primary" size={300} />
    </Uploader>
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Grid gap="1rem">
        <Card>
          <FilesDataTable
            {...tableProps}
            title="Program Files"
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'programs.edit',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />

          {canCreate && (
            <CardContent>
              <DnDUploader category="general" queryKey={tableProps.queryKey} resource={program} isEditable={true} isDisabled={false} />
            </CardContent>
          )}
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Files, pageConfig))
