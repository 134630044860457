import React from 'react'
import { DateTime } from 'luxon'
import clsx from 'clsx'

import { css, COLORS, HARD_SHADOW } from '../theme'
import { usDate, usTime } from '../utils/functions'
import { useSettings } from '../hooks/useSettings'

import Glyph from './Glyph'
import Divider from './Divider'
import { PopoverMenu, PopoverMenuItem } from './PopoverMenu'

const MIN_YEAR = 1950
const MAX_YEAR = 2200

const MENU_ITEM_HEIGHT = 24
const HEADER_HEIGHT = 32

const WEEK_DAYS = ['M', 'T', 'W', 'T', 'F', 'S', 'S']
const MONTHS = ['01 Jan', '02 Feb', '03 Mar', '04 Apr', '05 May', '06 Jun', '07 Jul', '08 Aug', '09 Sep', '10 Oct', '11 Nov', '12 Dec']

const DAYS_TO_DISPLAY = 42
const MINUTES_IN_DAY = 60 * 24
const MINUTES_INTERVAL = 15
const HOURS_TO_DISPLAY = MINUTES_IN_DAY / MINUTES_INTERVAL

const getTimes = (startDT: any) => {
  const times: any[] = []

  if (!startDT) return times

  const dayStart = startDT.startOf('day')

  for (let i = 0; i < HOURS_TO_DISPLAY; i++) times.push(dayStart.plus({ minutes: i * MINUTES_INTERVAL }))

  return times
}

const getDays = (startDT: any) => {
  const days: any[] = []

  if (!startDT) return days

  const firstDay = startDT.startOf('month').startOf('week')

  for (let i = 0; i < DAYS_TO_DISPLAY; i++) days.push(firstDay.plus({ days: i }))

  return days
}

const getYears = (minYear: number, maxYear: number) => {
  const years = []

  for (let i = maxYear; i >= minYear; i--) {
    years.push(i)
  }

  return years
}

export const DatePicker = (props: any) => {
  const {
    className,
    date,
    isInline,
    isOpen,
    maxYear = MAX_YEAR,
    minYear = MIN_YEAR,
    onOpenUpdated,
    onSelect,
    trigger,
    updateOn = 'day-time',
    withQuickActions = true,
    withTime = false,
    stopPropagation,
  } = props

  const { timezone: settingsTimezone } = useSettings()
  const timezone = props.timezone || settingsTimezone

  const today = DateTime.local().setZone(timezone)

  const [previewDT, setPreviewDT] = React.useState(date?.isValid ? date : today)
  const [selectedDate, setSelectedDate] = React.useState(date)

  const formattedDate = React.useMemo(() => usDate(selectedDate, timezone), [selectedDate, timezone])
  const isToday = formattedDate === usDate(today, timezone)

  const quickDates = React.useMemo(() => {
    const yesterday = today.minus({ days: 1 })
    const isYesterday = formattedDate === usDate(yesterday, timezone)

    const lastWeek = today.minus({ weeks: 1 })
    const isLastWeek = formattedDate === usDate(lastWeek, timezone)

    const lastMonth = today.minus({ months: 1 })
    const isLastMonth = formattedDate === usDate(lastMonth, timezone)

    const tomorrow = today.plus({ days: 1 })
    const isTomorrow = formattedDate === usDate(tomorrow, timezone)

    const nextWeek = today.plus({ weeks: 1 })
    const isNextWeek = formattedDate === usDate(nextWeek, timezone)

    const nextMonth = today.plus({ months: 1 })
    const isNextMonth = formattedDate === usDate(nextMonth, timezone)

    return {
      yesterday,
      isYesterday,

      lastWeek,
      isLastWeek,

      lastMonth,
      isLastMonth,

      tomorrow,
      isTomorrow,

      nextWeek,
      isNextWeek,

      nextMonth,
      isNextMonth,
    }
  }, [today, timezone, formattedDate])

  const selectedUsTime = React.useMemo(() => {
    return usTime(selectedDate)
  }, [selectedDate])

  const times = React.useMemo(() => {
    return getTimes(previewDT)
  }, [previewDT?.toISO()])

  const days = React.useMemo(() => {
    return getDays(previewDT)
  }, [previewDT?.toISO()])

  const years = React.useMemo(() => {
    return getYears(minYear, maxYear).reverse()
  }, [minYear, maxYear])

  const setPreviewDay = (day: number) => {
    const newDate = previewDT.set({ day })
    setPreviewDT(newDate)
    setSelectedDate(newDate)
  }

  const rootClasses = clsx(withTime && 'with-time', isInline && 'is-inline', className)
  const menuClasses = clsx(withTime && 'with-time')

  const setPreviewMonth = (month: number) => {
    const newDate = previewDT.set({ month })
    setPreviewDT(newDate)

    if (updateOn?.includes('month')) setSelectedDate(newDate)
  }

  const setPreviewYear = (year: number) => {
    const newDate = previewDT.set({ year })
    setPreviewDT(newDate)

    if (updateOn?.includes('year')) setSelectedDate(newDate)
  }

  const handleDaySelect = (day: any) => {
    setSelectedDate(day)
    if (onOpenUpdated) onOpenUpdated(false)
  }

  const handleTimeSelect = (timeDT: any) => {
    const newDate = previewDT.set({ hour: timeDT.hour, minute: timeDT.minute })
    setSelectedDate(newDate)
    if (withTime && onOpenUpdated) onOpenUpdated(false)
  }

  const handleOpenAutoFocus = (event: any) => {
    event.preventDefault()
  }

  React.useEffect(() => {
    if (date?.isValid) {
      setPreviewDT(date)
      setSelectedDate(date)
    }
  }, [date])

  React.useEffect(() => {
    if (!selectedDate?.isValid) return

    if (onSelect) onSelect(selectedDate)
  }, [selectedDate])

  // update Date Picker Date if picker changed outside
  React.useEffect(() => {
    if (!isOpen) return

    if (date?.isValid) setPreviewDT(date)
  }, [isOpen])

  const content = (
    <div className={rootClasses} css={STYLES.root}>
      {withQuickActions && (
        <div css={STYLES.column}>
          <header css={STYLES.header}>Quick</header>

          <PopoverMenuItem label="Today" onClick={() => handleDaySelect(today)} isActive={isToday} css={STYLES.quickMenuItem} />

          <Divider className="!m-0" />

          <PopoverMenuItem
            label="Yesterday"
            onClick={() => handleDaySelect(quickDates.yesterday)}
            isActive={quickDates.isYesterday}
            css={STYLES.quickMenuItem}
          />
          <PopoverMenuItem
            label="Last Week"
            onClick={() => handleDaySelect(quickDates.lastWeek)}
            isActive={quickDates.isLastWeek}
            css={STYLES.quickMenuItem}
          />
          <PopoverMenuItem
            label="Last Month"
            onClick={() => handleDaySelect(quickDates.lastMonth)}
            isActive={quickDates.isLastMonth}
            css={STYLES.quickMenuItem}
          />

          <Divider className="!m-0" />

          <PopoverMenuItem
            label="Tomorrow"
            onClick={() => handleDaySelect(quickDates.tomorrow)}
            isActive={quickDates.isTomorrow}
            css={STYLES.quickMenuItem}
          />
          <PopoverMenuItem
            label="Next Week"
            onClick={() => handleDaySelect(quickDates.nextWeek)}
            isActive={quickDates.isNextWeek}
            css={STYLES.quickMenuItem}
          />
          <PopoverMenuItem
            label="Next Month"
            onClick={() => handleDaySelect(quickDates.nextMonth)}
            isActive={quickDates.isNextMonth}
            css={STYLES.quickMenuItem}
          />
        </div>
      )}

      <div css={STYLES.months}>
        <header css={STYLES.header}>Month</header>

        {MONTHS.map((month, index) => {
          const monthNumber = index + 1

          return (
            <PopoverMenuItem
              key={month}
              label={month}
              isActive={previewDT.month === monthNumber}
              css={STYLES.menuItem}
              onClick={() => {
                setPreviewMonth(monthNumber)
              }}
            />
          )
        })}
      </div>

      <div css={STYLES.calendar}>
        <header css={STYLES.header} className="!p-0">
          {WEEK_DAYS.map((weekDay, index) => (
            <div key={index} css={STYLES.weekDay}>
              <span>{weekDay}</span>
            </div>
          ))}
        </header>

        <div css={STYLES.calendarGrid}>
          {days.map((date) => {
            const dateString = usDate(date, timezone)
            const isSelected = dateString === formattedDate
            const isToday = dateString === usDate(today, timezone)
            const isOutsideMonth = date.month !== previewDT.month
            // const isAfterCurrentMonth = date.month > previewDT.month || date.year > previewDT.year

            const dayClasses = clsx({
              'is-today': isToday,
              'is-selected': isSelected,
              'is-outside-month': isOutsideMonth,
              // 'is-after-current-month': isAfterCurrentMonth,
            })

            return (
              <div
                data-test={`day_${date.day}`}
                key={dateString}
                css={STYLES.day}
                className={dayClasses}
                onClick={() => {
                  withTime ? setPreviewDay(date.day) : handleDaySelect(date)
                }}
              >
                <span>{date.toFormat('dd')}</span>
              </div>
            )
          })}
        </div>

        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            textAlign: 'center',

            borderTop: `1px solid ${COLORS.divider}`,
            fontSize: '0.88rem',
          }}
        >
          <span className="font-[600] inline-block mr-1">Timezone: </span> <span className="text-text-muted">{timezone}</span>
        </div>
      </div>

      <div css={STYLES.years}>
        <header css={STYLES.header}>Year</header>

        {years.map((year: number) => (
          <PopoverMenuItem
            key={year}
            shouldScrollIntoView
            isActive={previewDT.year === year}
            label={year}
            css={STYLES.menuItem}
            onClick={() => {
              setPreviewYear(year)
            }}
          />
        ))}
      </div>

      {withTime && (
        <div css={STYLES.time}>
          <header css={STYLES.header}>Time</header>

          {times.map((timeDT: number) => {
            const formattedTime = usTime(timeDT)

            return (
              <PopoverMenuItem
                // shouldScrollIntoView
                key={formattedTime}
                isActive={formattedTime === selectedUsTime}
                label={formattedTime}
                css={STYLES.menuItem}
                onClick={() => {
                  handleTimeSelect(timeDT)
                }}
              />
            )
          })}
        </div>
      )}
    </div>
  )

  if (isInline) return content

  return (
    <>
      <PopoverMenu
        sideOffset={12}
        isOpen={isOpen}
        onOpenUpdated={onOpenUpdated}
        onOpenAutoFocus={handleOpenAutoFocus}
        menuClassName={menuClasses}
        menuCss={STYLES.menu}
        stopPropagation={stopPropagation}
        closeOnItemClick={false}
        trigger={
          trigger || (
            <div css={STYLES.trigger}>
              <Glyph glyph="date" size={24} color={COLORS.blue} />
            </div>
          )
        }
      >
        {content}
      </PopoverMenu>
    </>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gridTemplateRows: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    background: 'rgb(247, 248, 250)',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    fontSize: '0.92rem',

    '&.is-inline': {
      maxHeight: `${MENU_ITEM_HEIGHT * 12 + HEADER_HEIGHT}px !important`, // show all 12 months without scrolling
    },

    '& > *': {
      flex: '1 0 auto',
    },
  },

  menu: {
    '--menu-max-width': '90vw !important',

    // '&.with-time': {
    //   '--menu-max-width': '100% !important',
    // },

    display: 'grid',
    overflow: 'hidden',
    gridTemplateRows: '100%',
    gridTemplateColumns: 'fit-content',
    maxHeight: `${MENU_ITEM_HEIGHT * 12 + HEADER_HEIGHT}px !important`, // show all 12 months without scrolling
  },

  menuItem: {
    padding: '0 0.5rem',
    minHeight: `${MENU_ITEM_HEIGHT}px !important`,
    fontWeight: '500 !important',

    '&.is-active': {
      color: `${COLORS.white} !important`,
      background: `${COLORS.link} !important`,
    },
  },

  quickMenuItem: {
    padding: '0 0.5rem',
    minHeight: `${MENU_ITEM_HEIGHT}px !important`,
    fontWeight: '500 !important',
    margin: '0.25rem 0',

    'header + &': {
      marginTop: 0,
    },

    '&.is-active': {
      color: `${COLORS.white} !important`,
      background: `${COLORS.link} !important`,
    },
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: HEADER_HEIGHT,
    background: COLORS.white,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(3),
    fontWeight: 600,
    padding: '0 0.6rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontSize: '0.85rem',
    position: 'sticky',
    top: 0,

    '& > *': {
      flex: '1 1 auto',
    },
  },

  calendar: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    flex: '3 0 auto',
  },

  time: {
    overflowY: 'auto',
    overflowX: 'hidden',
    borderLeft: `1px solid ${COLORS.divider}`,
  },

  years: {
    overflowY: 'auto',
    overflowX: 'hidden',
    borderLeft: `1px solid ${COLORS.divider}`,
  },

  months: {
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRight: `1px solid ${COLORS.divider}`,
  },

  column: {
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRight: `1px solid ${COLORS.divider}`,
  },

  calendarGrid: {
    flex: '1 1 auto',

    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridAutoRows: 'min-content',
    alignItems: 'flex-start',
    fontSize: '0.96rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    width: '100%',
    maxWidth: 480,
    margin: '0 auto',
  },

  trigger: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    marginLeft: '0.5rem !important',
  },

  weekDay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0.5rem',
    zIndex: 0,
    lineHeight: 1,
  },

  day: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0.86rem 0.45rem',
    zIndex: 0,
    lineHeight: 1,
    cursor: 'pointer',

    // highlight circle
    span: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      position: 'relative',

      '&::before': {
        content: '""',
        borderRadius: '100%',
        position: 'absolute',
        display: 'block',
        width: 28,
        height: 28,
        zIndex: -1,
        opacity: 0,
      },
    },

    '&:hover, &.is-selected': {
      span: {
        color: COLORS.white,

        '&::before': {
          opacity: 1,
          background: COLORS.link,
        },
      },
    },

    '&.is-today': {
      position: 'relative',

      '&::after': {
        content: '""',
        width: 6,
        height: 6,
        borderRadius: '50%',
        background: COLORS.red,
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'block',
      },
    },

    '&.is-outside-month': {
      opacity: 0.25,
      pointerEvents: 'none',
    },

    '&.is-after-current-month': {
      display: 'none',
      pointerEvents: 'none',
    },
  },
}
