import React from 'react'

import MonthView from './MonthView'
import WeekView from './WeekView'

const CalendarView = (props: any) => {
  if (props.mode === 'months') return <MonthView {...props} />

  return <WeekView {...props} />
}

export default CalendarView
