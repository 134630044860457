import React from 'react'
import size from 'lodash/size'
import { NavLink } from 'react-router-dom-v5-compat'

import { titleCase, usDate, usTime } from '../../../utils/functions'
import { useUpdate } from '../../../hooks/useNewAPI'

import CheckButton from '../../CheckButton'
import DonutChart from '../../DonutChart'
import Flex from '../../Flex'
import Status from '../../Status'
import Table from '../../Table/Table'
import TableCell from '../../Table/TableCell'
import TableFilter from '../../Table/TableFilter'
import TaskStatus from '../../Statuses/TaskStatus'

import withSettings from '../../../hocs/withSettings'

const STATUS_COLORS: any = {
  done: 'green',
  due_today: 'orange',
  to_do: 'blue',
  overdue: 'red',
}

const TASK_TYPES: any = {
  one_to_one: 'One-to-One',
  group_meeting: 'Group Task',
}

const TaskCheckbox = ({ task }: any) => {
  const status = task?.display_status

  const { mutate, isLoading } = useUpdate({
    name: ['todo', task.id],
    url: `/events/${task.id}`,
    invalidate: 'todos',
  })

  const toggleTask = () => {
    mutate({ action_status: task.action_status === 'done' ? 'due' : 'done' })
  }

  return (
    <Flex centerY nowrap>
      <CheckButton isCompleted={task.action_status === 'done'} isLoading={isLoading} onClick={toggleTask} css={styles.checkButton} />
      <Status color={STATUS_COLORS[status]} label={status?.replace('_', ' ')} />
    </Flex>
  )
}

const columns = (to: Function = () => {}, timezone: string, useV6Router) => [
  {
    width: 140,
    canToggleVisible: false,
    accessor: 'display_status',
    Header: 'Status',
    Cell: ({ row }: any) => <TaskCheckbox task={row.original} />,
  },
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'title',
    Header: 'Title',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink as={useV6Router ? NavLink : undefined} to={to(row.original.id)} label={value} avatar={row.original.avatar} />
    ),
  },
  {
    width: 170,
    accessor: 'subtasks_no',
    Header: 'Tasks',
    Cell: ({ value, row }: any) => {
      if (value === 0) return <TableCell.Empty />

      return (
        <Flex centerY nowrap>
          <DonutChart
            value={(row.original?.subtasks_completed_no / row.original?.subtasks_no) * 100}
            size={16}
            strokeWidth={3}
            labelSize="0px"
            css={styles.donut}
          />
          <div>
            {row.original?.subtasks_completed_no}/{row.original?.subtasks_no} Completed
          </div>
        </Flex>
      )
    },
    Filter: false,
  },
  {
    width: 90,
    accessor: 'color',
    Header: 'Color',
    Cell: TableCell.Swatch,
  },
  {
    width: 170,
    accessor: 'residents',
    Header: 'Clients',
    Cell: ({ value, row }: any) => {
      // Dynamic Import
      if (size(value) === 0) {
        if (row.original.clients_import_type === 'dynamic') {
          const programs = row.original.imported_programs.map((o: any) => o.name) || []
          const properties = row.original.imported_houses.map((o: any) => o.name)
          const imported = programs.concat(properties).join(', ')

          return <TableCell value={`Dynamically imported from ${imported}, on the Due Date`} />
        }

        if (row.original.meeting_type === 'one_to_one') {
          return <TableCell value={row.original.resident?.name} />
        } else {
          return <TableCell.Empty />
        }
      }

      // Clients
      const clients = row.original.residents.map((o: any) => o.name).join(', ')
      return <TableCell value={clients} />
    },
    Filter: false,
  },
  {
    width: 170,
    accessor: 'employees',
    Header: 'Staff',
    Cell: ({ value, row }: any) => {
      const employees = row.original.employees.map((o: any) => o.name).join(', ')
      if (employees) {
        return <TableCell value={employees} />
      } else {
        return <TableCell.Empty />
      }
    },
    Filter: false,
  },
  {
    width: 160,
    accessor: 'started_at',
    Header: 'Due Date',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 120,
    accessor: 'is_all_day',
    Header: 'Due By',
    Cell: ({ value, row }: any) => {
      if (value === true) return 'All-day task'

      return usTime(row.original?.start_time, timezone)
    },
    Filter: false,
  },
  {
    width: 110,
    accessor: 'meeting_type',
    Header: 'Type',
    Cell: ({ value }: any) => <TableCell value={TASK_TYPES[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'One-to-One', value: 'one_to_one' },
      { label: 'Group Task', value: 'group_meeting' },
    ],
  },
  {
    width: 140,
    accessor: 'meeting_place',
    Header: 'Meeting Place',
    Cell: ({ value }: any) => <TableCell value={titleCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'None', value: 'none' },
      { label: 'Online', value: 'online' },
      { label: 'Location', value: 'property' },
      { label: 'Organization', value: 'organization' },
      { label: 'Other', value: 'other' },
    ],
  },
  {
    width: 200,
    accessor: 'share_with_client_portal',
    Header: 'Shared With Client Portal',
    Cell: ({ value }: any) => <TableCell.TrueFalse isTrue={value} />,
  },
  {
    width: 200,
    accessor: 'public_description',
    Header: 'Public Description',
    Cell: ({ value }: any) => <TableCell.Markup value={value} />,
  },
]

type Props = {
  emptyActions?: React.ReactNode
  emptyDescription: string
  events: Object
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  timezone: string
  title: string
  to?: Function
  batchActionsConfig?: Object
  useV6Router?: boolean
}

const TodoTableView = (props: Props) => {
  const {
    events,
    emptyActions,
    emptyDescription,
    icon,
    localStorageKey,
    batchActionsConfig,
    title,
    to,
    timezone,
    hiddenColumns,
    useV6Router,
  } = props

  return (
    <Table
      testKey="todo_table"
      title={title}
      icon={icon}
      data={events}
      columns={columns(to, timezone, useV6Router)}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
    />
  )
}

const styles = {
  checkButton: {
    marginRight: 10,
  },
  donut: {
    marginRight: 8,
  },
}

TodoTableView.defaultProps = {
  title: 'To-do',
  icon: 'tasks',
  emptyDescription: "No to-do's to display",
  localStorageKey: 'todo_calendar',
}

export default withSettings(TodoTableView)
