import React from 'react'
import { useParams } from 'react-router-dom'

import { COLORS } from '@behavehealth/theme'
import { request } from '@behavehealth/modules/axios'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import { ApplicationForm } from '@behavehealth/components'
import { Card, State, Page, Flex, Grid, P, Loader, Button, Alert } from '@behavehealth/components'
import { IntakeApplicationSelectOverlay } from '@behavehealth/constructs/Community/IntakeApplicationSelectOverlay'

import Notifications from '@behavehealth/modules/notifications'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import SummonOverlay from '@behavehealth/components/SummonOverlay'

const logoUrl = (tenantId: string) => {
  return `${process.env.BH_API_BASE_PROTOCOL}://${process.env.BH_API_BASE_URL}/v1/logo/${tenantId}`
}

export const FullApplication: React.FC = () => {
  const [data, setData] = React.useState(null)
  const [valid, setValid] = React.useState(false)

  const [isLogoLoading, setIsLogoLoading] = React.useState(false)
  const [updating, setUpdating] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const [hasLogo, setHasLogo] = React.useState(true)
  const [toggleAccordions, setToggleAccordions] = React.useState(false)

  const params: any = useParams()
  const { resource_id: id } = params

  const { data: organization, isLoading }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  const form = React.useRef()
  const [formData, setFormData] = React.useState(null)

  const otherPartner = organization?.other_partner
  const otherPartnerId = otherPartner?.id
  const otherPartnerSettings = otherPartner?.default_application_form || {}
  const isShared = organization.other_partner_settings?.full_application_form === true

  // CHECK TO SEE IF THE LOGO CAN BE LOADED
  React.useEffect(() => {
    if (!otherPartnerId) return

    const dataFetch = async () => {
      setIsLogoLoading(true)

      try {
        await request.get(logoUrl(otherPartnerId))
      } catch (error) {
        setHasLogo(false)
      }

      setIsLogoLoading(false)
    }

    dataFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherPartnerId])

  const submitForm = async () => {
    setUpdating(true)

    try {
      await request.post(
        '/intake_applications/submit',
        {
          status: 'submitted',
          source: 'form',
          data: data,
        },
        {
          headers: {
            'X-Tenant': otherPartner?.subdomain,
          },
        },
      )

      Notifications.send('Your data has been submitted successfully', 'positive')
      setSubmitted(true)
    } catch (errors) {
      console.error(errors)

      Notifications.send('Submit Failed', 'negative')
    }

    setUpdating(false)
  }

  const highlightRequired = () => {
    form.current?.highlightInvalid()
    setToggleAccordions((o) => !o)
  }

  const handleReset = () => {
    setData(null)
    setValid(false)
    setSubmitted(false)
    setFormData(null)
  }

  if (!organization || isLoading) return <PageLoader />

  if (!isShared) {
    return (
      <Page icon="admissions" title="Application Form">
        <Card>
          <State
            isEmpty
            title="Application Form"
            icon="admissions"
            emptyDescription={`${otherPartner?.name || '–'} has not shared their Application Form`}
          />
        </Card>
      </Page>
    )
  }

  return (
    <Page
      icon="admissions"
      title="Application Form"
      actions={
        <SummonOverlay
          overlay={<IntakeApplicationSelectOverlay onSelect={(intakeApplication: any) => setFormData(intakeApplication?.data)} />}
        >
          <Button glyph="form" label="Pre-Fill Client Application" type="primary" />
        </SummonOverlay>
      }
    >
      <div css={styles.root} className="application-form-root">
        <div css={styles.wrapper}>
          {isLogoLoading && (
            <Flex centerX centerY>
              <Loader color={COLORS.blue} size={24} speed={500} />
            </Flex>
          )}

          {!isLogoLoading && (
            <>
              {hasLogo && (
                <div css={styles.header}>
                  <img src={logoUrl(otherPartnerId)} alt="logo" css={styles.logo} />
                </div>
              )}

              {submitted && (
                <div>
                  <Alert type="positive" glyph="tick_circle" className="!my-5 !mx-0">
                    <P className="text-[1.05rem]">{otherPartnerSettings?.thank_you_message || 'Your application has been submitted.'}</P>
                  </Alert>

                  <div className="flex justify-center mb-8">
                    <Button
                      label="Complete another form"
                      onClick={handleReset}
                      size={300}
                      display="inline-flex"
                      type="default"
                      glyph="reset"
                    />
                  </div>
                </div>
              )}

              {!submitted && (
                <>
                  {otherPartnerSettings?.welcome_message && (
                    <>
                      <p className="text-[1.2rem] font-[700] mt-0 mx-0 mb-2">Hello,</p>
                      <p className="text-[1.1rem] mt-0 mx-0 mb-4">{otherPartnerSettings?.welcome_message}</p>
                    </>
                  )}

                  <ApplicationForm
                    form={form}
                    onUpdate={setData}
                    timezone={otherPartner.timezone}
                    schema={otherPartnerSettings?.schema}
                    companyName={otherPartner?.name}
                    onValidationUpdate={setValid}
                    forceOpenAccordion={toggleAccordions}
                    initialModel={formData}
                    key={JSON.stringify(formData || {})}
                  />

                  <div css={styles.footer}>
                    <Grid gap={10}>
                      {!valid && (
                        <Button
                          fullWidth
                          color="orange"
                          type="default"
                          glyph="view"
                          label="Highlight Required Fields"
                          isLoading={updating}
                          className="application-form-button"
                          onClick={highlightRequired}
                        />
                      )}
                      <Button
                        fullWidth
                        color="green"
                        type="primary"
                        label="Submit Application"
                        isLoading={updating}
                        isDisabled={!valid}
                        onClick={submitForm}
                        className="application-form-button"
                      />
                    </Grid>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </Page>
  )
}

const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    background: COLORS.lightBackground,
  },

  wrapper: {
    padding: '1em',
    width: '100%',
    margin: 'auto',
    maxWidth: 600,
  },

  header: {
    margin: '1rem 0 2rem',
  },

  logo: {
    display: 'block',
    width: '100%',
    height: 'auto',
    maxWidth: 180,
    maxHeight: 120,
    objectFit: 'contain',
    margin: '0 auto',
  },

  footer: {
    position: 'sticky',
    bottom: 0,
    marginTop: '1rem',
    paddingBottom: '1rem',
    background: COLORS.lightBackground,
  },
}

export default FullApplication
