import React from 'react'
import LogRocket from '../vendors/logrocket'

const useAnalytics = () => {
  const trackEvent = ({ name, params }: any) => {
    if (!name) return
    if (!LogRocket) return
    if (!LogRocket.getRocket()) return

    LogRocket.getRocket().track(name, params)
  }

  return { trackEvent }
}

export default useAnalytics
