export const VENDOR_QUICK_LINKS: any = {
  google: {
    getLink: (value: any) => `https://www.google.com/search?q=${encodeURIComponent(value)}`,
    icon: 'google',
  },
  hubspot: {
    getLink: (value: any) =>
      `https://app.hubspot.com/prospecting/2828210/activity-feed?isNew=true&globalSearchQuery=${encodeURIComponent(value)}`,
    icon: 'hubspot',
  },
  helpscout: {
    getLink: (value: any) => `https://secure.helpscout.net/search/?query=${encodeURIComponent(value)}`,
    icon: 'helpscout',
  },
  gmail: {
    getLink: (value: any) => `https://mail.google.com/mail/u/0/#search/${encodeURIComponent(value)}`,
    icon: 'gmail',
  },
  google_calendar: {
    getLink: (value: any) => `https://calendar.google.com/calendar/u/0/r/search?q=${encodeURIComponent(value)}`,
    icon: 'google_calendar',
  },
  postmark: {
    getLink: (value: any) =>
      `https://account.postmarkapp.com/servers/2552935/streams/outbound/events?f[range]=all&f[query]=${encodeURIComponent(value)}`,
    icon: 'postmark',
  },
}
