import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  trialing: {
    label: 'Trialing',
    color: 'gray',
  },
  trial_expired: {
    label: 'Trial Expired',
    color: 'yellow',
  },
  active: {
    label: 'Active',
    color: 'blue',
  },
  past_due: {
    label: 'Past Due',
    color: 'orange',
  },
  canceled: {
    label: 'Cancelled',
    color: 'red',
  },
  cancelled: {
    label: 'Cancelled',
    color: 'red',
  },
  unpaid: {
    label: 'Unpaid',
    color: 'red',
  },
  community: {
    label: 'Community',
    color: 'gray',
  },
  archived: {
    label: 'Archived',
    color: 'gray',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
