import React from 'react'
import startCase from 'lodash/startCase'

import { ADMISSION_TYPE, ADMISSION_SOURCE } from '../../utils/constants'
import { usDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import ClaimInternalStatus from '../Statuses/ClaimInternalStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'control_number',
    Header: 'Claim ID',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} />,
  },
  {
    width: 130,
    accessor: 'category',
    Header: 'Claim Type',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
  },
  {
    width: 180,
    accessor: 'status',
    Header: 'Internal Status',
    Cell: ({ value }: any) => <ClaimInternalStatus status={value} />,
  },
  {
    width: 180,
    accessor: 'identifier',
    Header: 'Identifier',
  },
  {
    width: 220,
    Header: 'Service Dates',
    Cell: ({ row }: any) => <TableCell value={`${usDate(row.original.covering_from)} - ${usDate(row.original.covering_until)}`} />,
  },
  {
    width: 240,
    accessor: 'primary_insurance.insurance_name',
    Header: 'Primary Insurance',
  },
  {
    width: 220,
    accessor: 'insurance_fee_schedule.name',
    Header: 'Fee Schedule',
  },
  {
    width: 150,
    accessor: 'total_amount',
    Header: 'Billed Amount',
    Cell: TableCell.Amount,
  },
  {
    width: 160,
    accessor: 'assignee.name',
    Header: 'Assignee',
    Cell: ({ value }: any) => <TableCell.Profile avatar={''} name={value} />,
  },
  {
    width: 160,
    accessor: 'initial_submission_at',
    Header: 'Initial Billed Date',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
  },
  {
    width: 130,
    accessor: 'last_submission_at',
    Header: 'Billed Date',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
  },
  {
    width: 190,
    accessor: 'created_at',
    Header: 'Date Created',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 190,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
]

const ClaimsTable = (props: any) => {
  const {
    emptyDescription = 'No claims added yet',
    icon = 'claims',
    localStorageKey = 'claims',
    timezone,
    title = 'Staff',
    to,
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      columns={columns(to, timezone)}
      emptyDescription={emptyDescription}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

export default withSettings(ClaimsTable)
