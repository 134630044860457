import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { APP_URLS } from '../MyAccount/utils'
import { countWord } from '../../utils/functions'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Icon from '../../components/Icon'
import Link from '../../components/Link'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import Overlay from '../../components/Overlay'
import PageSection from '../../components/PageSection/PageSection'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'

import { DataTable } from '../../components/DataTable/DataTable'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { useCommunityPermissions } from './useCommunityPermissions'
import { useDataTable } from '../../components/DataTable/useDataTable'

const RootCommunityProfilePartnersTab: React.FC<any> = (props: any) => {
  const match = useRouteMatch()

  const { entityId, data, isSaving, updateEntity } = props

  const { canEditCommunityProfiles } = useCommunityPermissions()

  const tableProps: any = useDataTable({
    name: ['community-entity', entityId, 'public_partnerships'],
    endpoint: `/community/entities/${entityId}/public_partnerships`,
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Partner Business Name',
        model: 'other_partner.name',
        width: 280,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data?.other_partner?.name} to={`/community/organizations/${data.id}`} />
        ),
      },
      {
        title: 'Community Profile',
        model: 'other_partner.primary_profile.name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => {
          if (!data.other_partner?.primary_profile) return null

          const communityURL = `${APP_URLS.community}/profiles/${data.other_partner.primary_profile.slug}`

          return (
            <LinkCell
              isExternal
              before={<Icon icon="community" size={16} className="mr-1.5" />}
              value={data.other_partner.primary_profile.name}
              href={communityURL}
            />
          )
        },
      },
      {
        title: 'Phone',
        model: 'other_partner.phone_no',
        type: 'phone',
      },
      {
        title: 'Email',
        model: 'other_partner.email',
        type: 'email',
      },
      {
        title: 'Website',
        model: 'other_partner.url',
        type: 'external_url',
        disableSelect: true,
      },
      {
        title: 'Address',
        model: 'other_partner.address',
        type: 'address',
        width: 280,
        disableSort: true,
      },
    ],
    [],
  )

  return (
    <>
      <Section>
        <PageSection>
          <PageSection.Header
            graphic={<Icon icon="community_partners" />}
            after={
              canEditCommunityProfiles && (
                <SummonOverlay
                  overlay={
                    <EditOverlay entityId={entityId} isSaving={isSaving} updateEntity={updateEntity} partnerships={tableProps.data || []} />
                  }
                >
                  <Button label="Edit Shared Partners" glyph="edit" size={200} type="primary" display="inline-flex" />
                </SummonOverlay>
              )
            }
          >
            <PageSection.Title title={size(tableProps.data) ? countWord('Shared Partners', size(tableProps.data)) : 'Shared Partners'} />
          </PageSection.Header>
        </PageSection>

        {/* <Alert contrast small glyph="info">
          Select which Active Partner Profiles you'd like to share on your Public Community Profile. You can allow your partners to share
          your Community Profile on their Public Partner Profile from{' '}
          <Link to={match.url.replace('/partners', '/details')}>Profile Details → Partnership Sharing</Link>.
        </Alert> */}

        <DataTable asCard {...tableProps} icon="community_partners" title="Shared Partners" columns={columns} className="mt-4" />
      </Section>
    </>
  )
}

const EditOverlay = (props: any) => {
  const { onClose, updateEntity, partnerships, isSaving } = props

  const form = React.useRef(null)

  const save = async () => {
    await updateEntity(form.current.getFormValue())

    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center">
      <Overlay.Header title="Shared Partners" icon="community_partners" />

      <Overlay.Content>
        <Section>
          <Form isEditable isCompact getForm={form} initialModel={{ partnerships, partnership_ids: partnerships?.map((o) => o.id) }}>
            <FormSection maxWidth="100%">
              <MultiOverlaySelector
                label="Shared Partners"
                model="partnerships"
                type="partnerships.shareable"
                icon="community_partners"
                selectTitle={(data: any) => data.other_partner?.name}
              />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save Shared Partners"
          glyph="check"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={save}
          size={200}
          display="inline-flex"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const CommunityProfilePartnersTab = withOverlayError(RootCommunityProfilePartnersTab)
