import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import withMarketing from '@behavehealth/hocs/withMarketing'
import useAPI from '@behavehealth/hooks/useAPI'

import { Card, Page, Grid } from '@behavehealth/components'

import CustomNoteTemplatesTable from '@behavehealth/components/Templates/tables/CustomNoteTemplatesTable'

const pageConfig = {
  feature: 'staff_custom_note_templates',
  title: 'Staff Custom Note Templates',
}

const StaffCustomNoteTemplates = () => {
  const data = useSelector((state: any) => state.data?.custom_note_templates?.data)
  const loading = useSelector((state: any) => state.data?.custom_note_templates?.loading)
  const isEmpty = size(data) === 0

  useAPI('custom_note_templates', '/custom_note_templates?variant=staff')

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Grid>
        <Card>
          <CustomNoteTemplatesTable
            mainLinkAs={NavLink}
            title="Custom Note Templates"
            data={data}
            isLoading={isEmpty && loading}
            to={(id: string) => id}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withMarketing(StaffCustomNoteTemplates, pageConfig)
