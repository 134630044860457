import React from 'react'
import { connect } from 'react-redux'

import { apiCreate } from '../../../modules/api'
import { ICONS } from '../../../theme'

import Alert from '../../Alert'
import Button from '../../Button'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import Overlay from '../../Overlay'
import Label from '../../Label'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'
import Text from '../../Typography/Text'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class CompanyMessageBlastOverlay extends OverlayBase {
  save = async () => {
    const { departments, roles, properties, programs }: any = this.state

    const department_slugs = departments ? departments.map((o) => o.slug) : []
    const role_slugs = roles ? roles.map((o) => o.slug) : []
    const property_slugs = properties ? properties.map((o) => o.slug) : []
    const program_slugs = programs ? programs.map((o) => o.slug) : []

    const slugs = [...department_slugs, ...role_slugs, ...property_slugs, ...program_slugs].filter((n) => n)

    const linked = {
      chat_type: 'company',
      slugs: slugs,
      channels: { departments: departments, roles, properties, programs },
    }

    let model = this.form.current.getFormValue()

    await apiCreate({
      name: 'chat_message_blasts',
      url: `/chat_message_blasts`,
      params: {
        ...model,
        ...linked,
      },
    })

    this.close()
  }

  renderHeader = () => <Overlay.Header icon="company_chat" title="Message Blast" />

  renderContent = () => {
    const { $new, $editable } = this.state
    const { record, timezone } = this.props

    return (
      <Overlay.Content>
        <Form
          useFullModel
          getForm={this.form}
          timezone={timezone}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          initialModel={record}
        >
          <Section>
            <FormSection layout="vertical">
              {/* <Select label="Message Type" model="content" defaultValue="text">
                    <Option label="Text" value={CHAT_MESSAGE_CONTENT.TEXT} />
                    <Option label="Poll" value={CHAT_MESSAGE_CONTENT.POLL} />
                  </Select> */}

              {/* <ContextShow when="content" is="poll">
                    {$new && (
                      <Alert glyph="info" type="neutral">
                        Poll message – get quick <Status inline label="Yes" color="green" /> / <Status inline label="No" color="red" /> answers
                        to your question. All results will be displayed in this same overlay.
                      </Alert>
                    )}
                  </ContextShow> */}

              <Textarea
                label="Message"
                model="message"
                validations={{
                  presence: {
                    message: 'Please enter a message',
                  },
                }}
              />

              {$new && (
                <>
                  <Alert glyph="info">Send a message to all the Channels selected below</Alert>
                  <MultiObjectSelector
                    model="channels.departments"
                    label="Departments"
                    type="chat.company.departments"
                    icon={ICONS.offices}
                    onUpdate={({ object }) => {
                      this.setState({ departments: object })
                    }}
                  />
                  <MultiObjectSelector
                    model="channels.roles"
                    label="Roles"
                    type="chat.company.roles"
                    icon={ICONS.employees}
                    onUpdate={({ object }) => {
                      this.setState({ roles: object })
                    }}
                  />
                  <MultiObjectSelector
                    model="channels.properties"
                    label="Locations"
                    type="chat.company.properties"
                    icon={ICONS.properties}
                    onUpdate={({ object }) => {
                      this.setState({ properties: object })
                    }}
                  />
                  <MultiObjectSelector
                    model="channels.programs"
                    label="Programs"
                    type="chat.company.programs"
                    icon={ICONS.checklist}
                    onUpdate={({ object }) => {
                      this.setState({ programs: object })
                    }}
                  />
                </>
              )}

              {!$new && (
                <div>
                  <Label label="Sent to" />
                  {record?.channels?.map((channel: any) => (
                    <div>
                      <Text description={channel.name} />
                    </div>
                  ))}
                </div>
              )}
            </FormSection>
          </Section>

          {/* {!$new && (
            <Section
              headingType="h2"
              title="Poll Results"
              description={
                <>
                  <b>YES:</b> 80% / <b>No:</b> 20%
                </>
              }
            >
              <ContextShow when="content" is="poll">
                {[...tempData.employees].splice(0, 4).map((employee) => (
                  <Flex gap={8}>
                    <Status color="green" label="Yes" />
                    <span>{employee.name}</span>
                  </Flex>
                ))}

                {[...tempData.employees].splice(3, 1).map((employee) => (
                  <Flex gap={8}>
                    <Status color="red" label="No" />
                    <span>{employee.name}</span>
                  </Flex>
                ))}
              </ContextShow>
            </Section>
          )} */}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    if (!this.state.$new) return null

    return (
      <Overlay.Footer>
        {this.state.$new ? (
          <Button
            glyph="check"
            label="Send"
            type="primary"
            color="green"
            isLoading={this.props.loading}
            onClick={this.save}
            isDisabled={this.state.isInvalid}
          />
        ) : (
          <Button label="Hide Message for Everyone" glyph="eye" color="gray" />
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'chat_message_blasts')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(CompanyMessageBlastOverlay))
