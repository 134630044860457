import React from 'react'
import { opacify, transparentize } from 'polished'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { COLORS, SHADOW } from '../../theme'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { usePortal } from '../../hooks/usePortal'
import { useSettings } from '../../hooks/useSettings'

import Form from '../Forms/Form'
import Json from '../Json'
import State from '../State'
import Portal from '../Portal'
import Overlay from '../Overlay'

import { COUNT_COLUMN_WIDTH, DEBUG } from './utils/constants'
import { useGridColumns } from './utils/useGridColumns'
import { useStore } from './utils/useStore'
import { WorksheetProvider } from './utils/context'

import { WorksheetHeader } from './WorksheetHeader'
import { WorksheetMain } from './WorksheetMain'

const RootWorksheet = (props: any) => {
  const {
    asCard,
    columns,
    getForm,
    icon,
    initialData,
    isEditable = true,
    isLoading,
    isRefetching,
    onDataUpdate,
    onRowsCountUpdate,
    onValidationUpdate,
    title,
    titleClassName,
    withForm = true,
    withFullScreenToggle = false,
    withShowInvalidToggle = true,
    withWrapToggle = true,
    onDataChange,
  } = props

  const form = getForm || React.useRef()
  const rootNodeRef = React.useRef()
  const portalContainer = usePortal('radix') // TODO: update after changing portal for radix components

  const allow: any = useStore((state: any) => state.allow)
  const setAllow: any = useStore((state: any) => state.setAllow)
  const allowDelete: any = useStore((state: any) => state.allowDelete)
  const clearAllSelections: any = useStore((state: any) => state.clearAllSelections)
  const columnsMap: any = useStore((state: any) => state.columnsMap)
  const dataIds: any = useStore((state: any) => state.dataIds)
  const dataMap: any = useStore((state: any) => state.dataMap)
  const initializeData: any = useStore((state: any) => state.initializeData)
  const didInitializeData: any = useStore((state: any) => state.didInitializeData)
  const processColumns: any = useStore((state: any) => state.processColumns)

  const isEmpty = size(initialData) === 0
  const rootClasses = clsx('relative z-0', asCard && 'as-card')

  const isProcessing = !isUndefined(initialData) && (!initialData || !dataMap || isLoading || isEmpty)

  const [isFullScreen, setIsFullScreen] = React.useState(false)

  const notifyOnChange = React.useRef(false)

  const toggleFullScreen = () => {
    setIsFullScreen((c) => !c)
  }

  // **************
  // Effects
  // **************

  // Initialize Data
  React.useEffect(() => {
    initializeData(initialData)
  }, [initialData, initializeData])

  // Process Columns
  React.useEffect(() => {
    if (columns) processColumns(columns)
  }, [columns, processColumns])

  React.useEffect(() => {
    if (onRowsCountUpdate) onRowsCountUpdate(size(dataIds))
  }, [onRowsCountUpdate, dataIds])

  React.useEffect(() => {
    if (onDataUpdate && dataMap) onDataUpdate(dataMap, dataIds)
  }, [onDataUpdate, dataMap, dataIds])

  // run onDataChange only after the first render when data has actually changed
  React.useEffect(() => {
    if (!onDataChange || !didInitializeData) return

    if (!notifyOnChange.current) {
      notifyOnChange.current = true
      return
    }

    onDataChange(dataMap, dataIds)
  }, [dataMap, dataIds, didInitializeData])

  React.useEffect(() => {
    if (allow === props.allow) return

    setAllow(props.allow)
  }, [allow, props.allow])

  // Process CSS Grid Columns
  const gridColumns = useGridColumns(columnsMap)

  // Define root style
  const style: any = React.useMemo(() => {
    return { '--grid-template-columns': `${COUNT_COLUMN_WIDTH}px ${gridColumns} ${allowDelete ? '50px' : ''}` }
  }, [allow, gridColumns])

  const RootTag: any = isFullScreen ? PortalWrapper : React.Fragment

  // Handle outside click
  useOnClickOutside([rootNodeRef, portalContainer], () => {
    clearAllSelections()
  })

  const content = (
    <>
      <WorksheetHeader
        title={title}
        icon={icon}
        isLoading={isLoading}
        isRefetching={isRefetching}
        withWrapToggle={withWrapToggle}
        withShowInvalidToggle={withShowInvalidToggle}
        titleClassName={titleClassName}
        isEditable={isEditable}
        withFullScreenToggle={withFullScreenToggle}
        isFullScreen={isFullScreen}
        toggleFullScreen={toggleFullScreen}
      />
      <WorksheetMain isEditable={isEditable} />
    </>
  )

  return (
    <RootTag {...(isFullScreen ? { toggleFullScreen } : undefined)}>
      <div ref={rootNodeRef} style={style} className={rootClasses} css={STYLES.root}>
        {isProcessing ? (
          <State isLoading={isLoading} isEmpty={isEmpty} />
        ) : (
          <>
            {withForm ? (
              <Form as="div" getForm={form} initialModel={dataMap} onValidationUpdate={onValidationUpdate} css={STYLES.scrollContent}>
                {content}
              </Form>
            ) : (
              <div css={STYLES.scrollContent}>{content}</div>
            )}
          </>
        )}
      </div>

      {DEBUG && <DataDebug />}
    </RootTag>
  )
}

const PortalWrapper = ({ children, toggleFullScreen }: any) => {
  return (
    <Portal type="z90">
      <Overlay showBackdrop closeOnBackdrop hideClose position="top" maxWidth={90} onClose={toggleFullScreen}>
        {/* <Overlay.Content>
          </Overlay.Content> */}
        {children}
      </Overlay>
    </Portal>
  )
}

export const Worksheet = (props: any) => {
  const {
    allow = '',
    columns,
    initialData,
    isLoading,
    isRefetching,
    processCanEdit,
    initialRowsCount = 1,
    defaultNewRow,
    wrapping,
    editDisabledColumns = [],
  } = props

  const { timezone: settingsTimezone } = useSettings()
  const timezone = props.timezone || settingsTimezone

  return (
    <WorksheetProvider
      allow={allow}
      columns={columns}
      initialData={initialData}
      isLoading={isLoading}
      isRefetching={isRefetching}
      timezone={timezone}
      processCanEdit={processCanEdit}
      initialRowsCount={initialRowsCount}
      defaultNewRow={defaultNewRow}
      wrapping={wrapping}
      editDisabledColumns={editDisabledColumns}
    >
      <RootWorksheet {...props} timezone={timezone} />
    </WorksheetProvider>
  )
}

export const DataDebug = () => {
  const store: any = useStore((state: any) => state)

  if (!DEBUG || !store) return null

  const storeData: any = {}

  for (const key in store) {
    if (typeof store[key] !== 'function') storeData[key] = store[key]
  }

  return <Json data={storeData} />
}

const STYLES = {
  root: {
    '--input-focus-box-shadow': `
      0 0 0 1px ${COLORS.blue},
      ${SHADOW(5, transparentize(0.95, COLORS.blue))},
      0 0 0 2px ${transparentize(0.75, COLORS.blue)}
    `,

    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    overflow: 'hidden !important',
    flex: '1 1 auto',

    fontSize: '0.92rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',

    '&.as-card': {
      background: COLORS.white,
      borderRadius: 5,
      boxShadow: `
        0 0 0 1px ${opacify(-0.05, COLORS.divider)},
        0 3px 0 1px ${opacify(-0.07, COLORS.divider)}
      `,
    },
  },

  scrollContent: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '100%',
    overflow: 'hidden',
  },
}
