import React from 'react'

import { nicePercentage } from '../../utils/functions'
import { withOverlayError } from '../../hocs/withOverlayError'

import Card from '../../components/Card'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import GridTable from '../../components/GridTable'
import ProgressBar from '../../components/ProgressBar'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import { DataFormOverlay } from '../DataFormOverlay'
import { useSettings } from '../../hooks/useSettings'

const INITIAL_STATE = {
  q1: 0,
  q2: 0,
  q3: 0,
  q4: 0,
  q5: 0,
  q6: 0,
  q7: 0,
  q8: 0,
  q9: 0,
  q10: 0,
}

const RootDimensionsOfWellnessOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Dimensions of Wellness"
      category="clinical_measurement"
      subcategory="dow"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Directions"
        headingType="h2"
        scrollview={{
          id: 'directions',
          name: 'Directions',
        }}
      >
        <p>
          <b>Directions:</b> Select the number that applies to you for each statement. Your score for each dimension (out of 40) will be
          displayed at the end of this form.
        </p>
      </Section>

      <Divider />

      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <Questionnaire />
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <p>*This assessment has been adapted from the New York State Bar Association (NYSBA)</p>
      </Section>
    </DataFormOverlay>
  )
}

const RadioQuestion = ({ category, label, model, setScore }: any) => (
  <RadioGroup
    label={label}
    model={`data.questionnaire.${category}.${model}`}
    layout="vertical-dense"
    onUpdate={({ value }: any) => setScore((o) => ({ ...o, [model]: value || 0 }))}
  >
    <Radio label="1 = Rarely, if ever" value={1} />
    <Radio label="2 = Sometimes" value={2} />
    <Radio label="3 = Most of the time" value={3} />
    <Radio label="4 = Always" value={4} />
  </RadioGroup>
)

const Questionnaire = (props: any) => {
  const [emotionalScore, setEmotionalScore] = React.useState(INITIAL_STATE)
  const [spiritualScore, setSpiritualScore] = React.useState(INITIAL_STATE)
  const [physicalScore, setPhysicalScore] = React.useState(INITIAL_STATE)
  const [socialScore, setSocialScore] = React.useState(INITIAL_STATE)
  const [financialScore, setFinancialScore] = React.useState(INITIAL_STATE)
  const [occupationalScore, setOccupationalScore] = React.useState(INITIAL_STATE)
  const [intellectualScore, setIntellectualScore] = React.useState(INITIAL_STATE)
  const [environmentalScore, setEnvironmentalScore] = React.useState(INITIAL_STATE)

  const finalEmotionalScore = Object.values(emotionalScore).reduce((a, b) => a + b, 0)
  const finalSpiritualScore = Object.values(spiritualScore).reduce((a, b) => a + b, 0)
  const finalPhysicalScore = Object.values(physicalScore).reduce((a, b) => a + b, 0)
  const finalSocialScore = Object.values(socialScore).reduce((a, b) => a + b, 0)
  const finalFinancialScore = Object.values(financialScore).reduce((a, b) => a + b, 0)
  const finalOccupationalScore = Object.values(occupationalScore).reduce((a, b) => a + b, 0)
  const finalIntellectualScore = Object.values(intellectualScore).reduce((a, b) => a + b, 0)
  const finalEnvironmentalScore = Object.values(environmentalScore).reduce((a, b) => a + b, 0)

  return (
    <>
      <Grid gap="1.5rem">
        <FormSection heading="Emotional">
          <RadioQuestion
            label="I find healthy ways to cope with stress (e.g. exercise, meditation, social support, self-care activities, etc.)"
            category="emotional"
            model="q1"
            setScore={setEmotionalScore}
          />
          <RadioQuestion
            label="I am able to ask for assistance when I need it, either from friends and family, or professionals."
            category="emotional"
            model="q2"
            setScore={setEmotionalScore}
          />
          <RadioQuestion label="I accept responsibility for my own actions." category="emotional" model="q3" setScore={setEmotionalScore} />
          <RadioQuestion label="I am able to set priorities." category="emotional" model="q4" setScore={setEmotionalScore} />
          <RadioQuestion
            label="I feel good about myself and believe others like me for who I am."
            category="emotional"
            model="q5"
            setScore={setEmotionalScore}
          />
          <RadioQuestion
            label="I am flexible and able to adapt/adjust to life’s changes in a positive way."
            category="emotional"
            model="q6"
            setScore={setEmotionalScore}
          />
          <RadioQuestion
            label="I can express all ranges of feelings (i.e. hurt, sadness, fear, anger, joy, etc.) and manage emotion-related behaviors in a healthy way."
            category="emotional"
            model="q7"
            setScore={setEmotionalScore}
          />
          <RadioQuestion
            label="I maintain a balance of work, friends, family, school and other obligations."
            category="emotional"
            model="q8"
            setScore={setEmotionalScore}
          />
          <RadioQuestion
            label="I do not let my emotions get the better of me. I think before I act."
            category="emotional"
            model="q9"
            setScore={setEmotionalScore}
          />
          <RadioQuestion
            label="I have a healthy relationship with social media."
            category="emotional"
            model="q10"
            setScore={setEmotionalScore}
          />
        </FormSection>

        <FormSection heading="Spiritual">
          <RadioQuestion
            label="I take time to think about what is important in life – who I am, what I value, where I fit in, where I’m going."
            category="spiritual"
            model="q1"
            setScore={setSpiritualScore}
          />
          <RadioQuestion label="I make time for relaxation during the day." category="spiritual" model="q2" setScore={setSpiritualScore} />
          <RadioQuestion
            label="I have a belief system in place (religious, agnostic, atheist, spiritual, etc.)."
            category="spiritual"
            model="q3"
            setScore={setSpiritualScore}
          />
          <RadioQuestion label="My values guide my decisions and actions." category="spiritual" model="q4" setScore={setSpiritualScore} />
          <RadioQuestion label="I have a sense of purpose in my life." category="spiritual" model="q5" setScore={setSpiritualScore} />
          <RadioQuestion
            label="I am tolerant and accepting of the view of others."
            category="spiritual"
            model="q6"
            setScore={setSpiritualScore}
          />
          <RadioQuestion
            label="I utilize resources to improve my well-being."
            category="spiritual"
            model="q7"
            setScore={setSpiritualScore}
          />
          <RadioQuestion
            label="I am active in communities or causes I care about."
            category="spiritual"
            model="q8"
            setScore={setSpiritualScore}
          />
          <RadioQuestion
            label="I am able to set, communicate and enforce boundaries."
            category="spiritual"
            model="q9"
            setScore={setSpiritualScore}
          />
          <RadioQuestion
            label="I work to create balance and peace within my interpersonal relationships, community and the world."
            category="spiritual"
            model="q10"
            setScore={setSpiritualScore}
          />
        </FormSection>

        <FormSection heading="Physical">
          <RadioQuestion label="I manage my weight in healthy ways." category="physical" model="q1" setScore={setPhysicalScore} />
          <RadioQuestion label="I exercise regularly." category="physical" model="q2" setScore={setPhysicalScore} />
          <RadioQuestion
            label="I get 7-9 hours of sleep each night and feel rested in the morning."
            category="physical"
            model="q3"
            setScore={setPhysicalScore}
          />
          <RadioQuestion
            label="I seek advice from health care professionals if I have a health concern I cannot solve on my own."
            category="physical"
            model="q4"
            setScore={setPhysicalScore}
          />
          <RadioQuestion
            label="I do not use or avoid harmful use of drugs (over-the-counter, prescription and illicit)."
            category="physical"
            model="q5"
            setScore={setPhysicalScore}
          />
          <RadioQuestion
            label="I drink alcohol responsibly (i.e. designated sober driver, avoid binge drinking, etc.)"
            category="physical"
            model="q6"
            setScore={setPhysicalScore}
          />
          <RadioQuestion
            label="I protect my skin from sun damage by using sunscreen with SPF 30+, wearing hats and/or avoiding tanning booths and sun lamps."
            category="physical"
            model="q7"
            setScore={setPhysicalScore}
          />
          <RadioQuestion
            label="I maintain healthy eating patterns that include fruits and vegetables."
            category="physical"
            model="q8"
            setScore={setPhysicalScore}
          />
          <RadioQuestion
            label="I stay hydrated and drink water throughout the day."
            category="physical"
            model="q9"
            setScore={setPhysicalScore}
          />
          <RadioQuestion
            label="I protect myself from STIs and unwanted pregnancy by either abstaining from sexual behaviors or using proper protection, such as condoms."
            category="physical"
            model="q10"
            setScore={setPhysicalScore}
          />
        </FormSection>

        <FormSection heading="Social">
          <RadioQuestion label="I am satisfied with my social life." category="social" model="q1" setScore={setSocialScore} />
          <RadioQuestion
            label="I am involved in at least one university community or group."
            category="social"
            model="q2"
            setScore={setSocialScore}
          />
          <RadioQuestion
            label="I maintain a network of supportive friends, family and social contacts."
            category="social"
            model="q3"
            setScore={setSocialScore}
          />
          <RadioQuestion
            label="I have at least one meaningful relationship in my life."
            category="social"
            model="q4"
            setScore={setSocialScore}
          />
          <RadioQuestion
            label="I am accepting of the diversity of others (race, ethnicity, religion, gender, ability, sexual orientation, etc.)"
            category="social"
            model="q5"
            setScore={setSocialScore}
          />
          <RadioQuestion
            label="I am able to prioritize my own needs by saying “no” to others’ requests for my time."
            category="social"
            model="q6"
            setScore={setSocialScore}
          />
          <RadioQuestion
            label="I have someone I can talk to about my feelings and struggles."
            category="social"
            model="q7"
            setScore={setSocialScore}
          />
          <RadioQuestion
            label="I participate in social activities and enjoy being with people who are different from me."
            category="social"
            model="q8"
            setScore={setSocialScore}
          />
          <RadioQuestion label="I give and take equally in my relationships." category="social" model="q9" setScore={setSocialScore} />
          <RadioQuestion label="I plan time with my family and friends." category="social" model="q10" setScore={setSocialScore} />
        </FormSection>

        <FormSection heading="Financial">
          <RadioQuestion
            label="I am able to set and stick to a budget each month so I don’t run out of money."
            category="financial"
            model="q1"
            setScore={setFinancialScore}
          />
          <RadioQuestion
            label="I know my total amount of debt and interest rates."
            category="financial"
            model="q2"
            setScore={setFinancialScore}
          />
          <RadioQuestion
            label="I pay my credit cards, tuition/fees and other bills on time."
            category="financial"
            model="q3"
            setScore={setFinancialScore}
          />
          <RadioQuestion
            label="I know about the different sources of financial aid that I am eligible for and apply when I am able."
            category="financial"
            model="q4"
            setScore={setFinancialScore}
          />
          <RadioQuestion
            label="I have a savings account and save money regularly."
            category="financial"
            model="q5"
            setScore={setFinancialScore}
          />
          <RadioQuestion label="I know my credit score." category="financial" model="q6" setScore={setFinancialScore} />
          <RadioQuestion
            label="I keep my financial information safe by using secure passwords, PINs and dual authentication."
            category="financial"
            model="q7"
            setScore={setFinancialScore}
          />
          <RadioQuestion
            label="I feel good about my current and future financial situation."
            category="financial"
            model="q8"
            setScore={setFinancialScore}
          />
          <RadioQuestion
            label="I check my bank statements/accounts each month."
            category="financial"
            model="q9"
            setScore={setFinancialScore}
          />
          <RadioQuestion
            label="I understand how to build credit and use credit cards wisely."
            category="financial"
            model="q10"
            setScore={setFinancialScore}
          />
        </FormSection>

        <FormSection heading="Occupational">
          <RadioQuestion
            label="I am able to balance work, play, school and other aspects of my life."
            category="occupational"
            model="q1"
            setScore={setOccupationalScore}
          />
          <RadioQuestion
            label="I take advantage of opportunities to learn new skills that can enhance my future employment opportunities."
            category="occupational"
            model="q2"
            setScore={setOccupationalScore}
          />
          <RadioQuestion
            label="I know what skills are necessary for the occupations I am interested in."
            category="occupational"
            model="q3"
            setScore={setOccupationalScore}
          />
          <RadioQuestion
            label="I strive to develop good work habits (dependability, initiative, etc.)."
            category="occupational"
            model="q4"
            setScore={setOccupationalScore}
          />
          <RadioQuestion label="I work effectively with others." category="occupational" model="q5" setScore={setOccupationalScore} />
          <RadioQuestion
            label="I have confidence in my job search skill (resume writing, interviewing, cover letters, networking, etc.)."
            category="occupational"
            model="q6"
            setScore={setOccupationalScore}
          />
          <RadioQuestion
            label="I have explored different career options."
            category="occupational"
            model="q7"
            setScore={setOccupationalScore}
          />
          <RadioQuestion
            label="I know where to find employment opportunities (job service, online, etc.)"
            category="occupational"
            model="q8"
            setScore={setOccupationalScore}
          />
          <RadioQuestion label="I manage my time effectively." category="occupational" model="q9" setScore={setOccupationalScore} />
          <RadioQuestion
            label="I have participated in internships or volunteer work."
            category="occupational"
            model="q10"
            setScore={setOccupationalScore}
          />
        </FormSection>

        <FormSection heading="Intellectual">
          <RadioQuestion
            label="I know about available campus resources to help me study."
            category="intellectual"
            model="q1"
            setScore={setIntellectualScore}
          />
          <RadioQuestion
            label="I feel fulfilled in my academic program."
            category="intellectual"
            model="q2"
            setScore={setIntellectualScore}
          />
          <RadioQuestion
            label="I seek personal growth by learning new skills."
            category="intellectual"
            model="q3"
            setScore={setIntellectualScore}
          />
          <RadioQuestion
            label="I look for ways to use my creative and critical thinking skills."
            category="intellectual"
            model="q4"
            setScore={setIntellectualScore}
          />
          <RadioQuestion label="I am open to new ideas." category="intellectual" model="q5" setScore={setIntellectualScore} />
          <RadioQuestion
            label="I learn about different topics that interest me from books, magazines, newspapers and the internet."
            category="intellectual"
            model="q6"
            setScore={setIntellectualScore}
          />
          <RadioQuestion
            label="I know how to access academic resources when necessary."
            category="intellectual"
            model="q7"
            setScore={setIntellectualScore}
          />
          <RadioQuestion
            label="I am comfortable reaching out to my professor/TA for help or attending office hours."
            category="intellectual"
            model="q8"
            setScore={setIntellectualScore}
          />
          <RadioQuestion label="I am eager to learn." category="intellectual" model="q9" setScore={setIntellectualScore} />
          <RadioQuestion
            label="I stay informed about social, political and other current issues."
            category="intellectual"
            model="q10"
            setScore={setIntellectualScore}
          />
        </FormSection>

        <FormSection heading="Environmental">
          <RadioQuestion
            label="I spend time outdoors enjoying nature."
            category="environmental"
            model="q1"
            setScore={setEnvironmentalScore}
          />
          <RadioQuestion
            label="I reduce, reuse and recycle products."
            category="environmental"
            model="q2"
            setScore={setEnvironmentalScore}
          />
          <RadioQuestion
            label="I try to lessen my environmental impact."
            category="environmental"
            model="q3"
            setScore={setEnvironmentalScore}
          />
          <RadioQuestion
            label="I walk, bike, use public transportation or carpool when possible."
            category="environmental"
            model="q4"
            setScore={setEnvironmentalScore}
          />
          <RadioQuestion
            label="I am concerned about impacts on my local, national and world climate."
            category="environmental"
            model="q5"
            setScore={setEnvironmentalScore}
          />
          <RadioQuestion label="I have a space to call my own." category="environmental" model="q6" setScore={setEnvironmentalScore} />
          <RadioQuestion
            label="I feel comfortable in the space I occupy."
            category="environmental"
            model="q7"
            setScore={setEnvironmentalScore}
          />
          <RadioQuestion
            label="I feel content in my environments (class, home, work, etc.)."
            category="environmental"
            model="q8"
            setScore={setEnvironmentalScore}
          />
          <RadioQuestion label="I shop locally." category="environmental" model="q9" setScore={setEnvironmentalScore} />
          <RadioQuestion
            label="I participate in campus events that help my community (food drives, fundraisers, etc.)."
            category="environmental"
            model="q10"
            setScore={setEnvironmentalScore}
          />
        </FormSection>
      </Grid>

      <Divider />

      <Grid gap="1rem">
        <h2>Personal Wellness Checklist</h2>

        <Card>
          <GridTable templateColumns="repeat(4, minmax(180px, 1fr))">
            <GridTable.Header>
              <GridTable.Cell>Dimension of Wellness</GridTable.Cell>
              <GridTable.Cell>My Score</GridTable.Cell>
              <GridTable.Cell>Maximum Score</GridTable.Cell>
              <GridTable.Cell>Percentage</GridTable.Cell>
            </GridTable.Header>

            <GridTable.Row>
              <GridTable.Cell>Emotional</GridTable.Cell>
              <GridTable.Cell>{finalEmotionalScore}</GridTable.Cell>
              <GridTable.Cell>40</GridTable.Cell>
              <GridTable.Cell>
                <ProgressBar showPercentage color="green" width={80} percent={nicePercentage((finalEmotionalScore / 40) * 100)} />
              </GridTable.Cell>
            </GridTable.Row>

            <GridTable.Row>
              <GridTable.Cell>Spiritual</GridTable.Cell>
              <GridTable.Cell>{finalSpiritualScore}</GridTable.Cell>
              <GridTable.Cell>40</GridTable.Cell>
              <GridTable.Cell>
                <ProgressBar showPercentage color="green" width={80} percent={nicePercentage((finalSpiritualScore / 40) * 100)} />
              </GridTable.Cell>
            </GridTable.Row>

            <GridTable.Row>
              <GridTable.Cell>Physical</GridTable.Cell>
              <GridTable.Cell>{finalPhysicalScore}</GridTable.Cell>
              <GridTable.Cell>40</GridTable.Cell>
              <GridTable.Cell>
                <ProgressBar showPercentage color="green" width={80} percent={nicePercentage((finalPhysicalScore / 40) * 100)} />
              </GridTable.Cell>
            </GridTable.Row>

            <GridTable.Row>
              <GridTable.Cell>Social</GridTable.Cell>
              <GridTable.Cell>{finalSocialScore}</GridTable.Cell>
              <GridTable.Cell>40</GridTable.Cell>
              <GridTable.Cell>
                <ProgressBar showPercentage color="green" width={80} percent={nicePercentage((finalSocialScore / 40) * 100)} />
              </GridTable.Cell>
            </GridTable.Row>

            <GridTable.Row>
              <GridTable.Cell>Financial</GridTable.Cell>
              <GridTable.Cell>{finalFinancialScore}</GridTable.Cell>
              <GridTable.Cell>40</GridTable.Cell>
              <GridTable.Cell>
                <ProgressBar showPercentage color="green" width={80} percent={nicePercentage((finalFinancialScore / 40) * 100)} />
              </GridTable.Cell>
            </GridTable.Row>

            <GridTable.Row>
              <GridTable.Cell>Occupational</GridTable.Cell>
              <GridTable.Cell>{finalOccupationalScore}</GridTable.Cell>
              <GridTable.Cell>40</GridTable.Cell>
              <GridTable.Cell>
                <ProgressBar showPercentage color="green" width={80} percent={nicePercentage((finalOccupationalScore / 40) * 100)} />
              </GridTable.Cell>
            </GridTable.Row>

            <GridTable.Row>
              <GridTable.Cell>Intellectual</GridTable.Cell>
              <GridTable.Cell>{finalIntellectualScore}</GridTable.Cell>
              <GridTable.Cell>40</GridTable.Cell>
              <GridTable.Cell>
                <ProgressBar showPercentage color="green" width={80} percent={nicePercentage((finalIntellectualScore / 40) * 100)} />
              </GridTable.Cell>
            </GridTable.Row>

            <GridTable.Row>
              <GridTable.Cell>Environmental</GridTable.Cell>
              <GridTable.Cell>{finalEnvironmentalScore}</GridTable.Cell>
              <GridTable.Cell>40</GridTable.Cell>
              <GridTable.Cell>
                <ProgressBar showPercentage color="green" width={80} percent={nicePercentage((finalEnvironmentalScore / 40) * 100)} />
              </GridTable.Cell>
            </GridTable.Row>
          </GridTable>
        </Card>

        <SmartTextarea useDictation label="Which dimensions could you improve on?" model="data.questionnaire.dimensions_to_improve" />
        <SmartTextarea useDictation label="Which dimensions are going “okay”?" model="data.questionnaire.okay_dimensions" />
        <SmartTextarea useDictation label="Which dimensions are you having success in?" model="data.questionnaire.successful_dimensions" />
      </Grid>
    </>
  )
}

export const DimensionsOfWellnessOverlay = withOverlayError(RootDimensionsOfWellnessOverlay)
