import React from 'react'
import axios from 'axios'
import size from 'lodash/size'
import compact from 'lodash/compact'
import produce from 'immer'
import { FlyToInterpolator, NavigationControl } from 'react-map-gl'
import { useMedia } from 'use-media'

import Button from '../../Button'
import MapboxMap from '../../MapboxMap'
import Grid from '../../Grid'
import Flex from '../../Flex'
import { Text } from '../../Typography'
import Input from '../../Forms/Input'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Overlay from '../../Overlay'

import withSettings from '../../../hocs/withSettings'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { STATE_BOUNDARIES } from '../../../utils/constants'
import { BREAKPOINT, MEDIA_QUERY } from '../../../theme'

const TOKEN = process.env.BH_MAPBOX

const parseContext = (contexts) => {
  if (!contexts) return {}

  let map = {}
  for (let i = 0; i < contexts.length; i++) map[contexts[i].id.split('.')[0]] = contexts[i]
  return map
}

const buildTitle = (feature, addressLine2 = '') => {
  if (!feature) return ''

  const type = feature.place_type[0]

  if (type === 'poi') return feature.text
  if (type === 'address' && feature.address) {
    const address = feature.place_name.split(',')

    return compact([address[0], addressLine2]).join(', ')
  } else return feature.text
}

const getStateBoundaries = (state: string) => STATE_BOUNDARIES[state]?.bbox.join(',')

const AddressSelectorOverlay = ({ centerOnState, onClose, onUpdate, afterSelect, lat, lon }: any) => {
  const [search, setSearch] = React.useState(null)
  const [results, setResults] = React.useState([])
  const [selectedAddress, setSelectedAddress] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [addressLine2, setAddressLine2] = React.useState('')
  const mq2 = useMedia({ minWidth: BREAKPOINT[2] })

  const currentLat = selectedAddress?.center[1] || lat
  const currentLon = selectedAddress?.center[0] || lon

  const onSearchUpdate = ({ value }) => setSearch(encodeURIComponent(value))
  const onResultSelected = ({ value }) => {
    const foundResult = results.find((result) => result.id === value)
    if (!foundResult) return

    setSelectedAddress(
      produce(foundResult, (draft) => {
        draft.context = parseContext(draft.context)
      }),
    )
  }

  const onAddressSelect = () => {
    if (onUpdate) onUpdate({ ...selectedAddress, addressLine2: addressLine2 })
    if (afterSelect) afterSelect({ ...selectedAddress, addressLine2: addressLine2 })
    onClose()
  }

  React.useEffect(() => {
    if (!search) return

    const fetchResults = async () => {
      setLoading(true)

      try {
        const result = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json`, {
          params: {
            access_token: TOKEN,
            limit: 9,
            country: 'US',
            types: 'address',
          },
        })

        const results = result.data?.features?.filter((feature) => feature?.address)

        // set up results
        setResults(results)

        if (!results[0]) return

        // select first result
        setSelectedAddress(
          produce(results[0], (draft: any) => {
            draft.context = parseContext(draft.context)
          }),
        )
      } catch (errors) {
        console.error(errors)
      } finally {
        setLoading(false)
      }
    }

    fetchResults()
  }, [search, centerOnState])

  const hasResults = size(results) > 0

  return (
    <Overlay position="center" maxWidth={70} minHeight={50} showBackdrop onClose={onClose}>
      <Overlay.Header glyph="map_pin" title="Search Address" />

      <Overlay.Content className="!p-4">
        <Grid rows="min-content 1fr" className="!h-full" gap="1rem">
          <Flex stretchChildrenX gap="1rem">
            <Input
              isEditable
              label="Search Address"
              placeholder="Search Address"
              maxWidth="100%"
              debounce={150}
              onUpdate={onSearchUpdate}
              forceEmptyValue
            />
            <Input isEditable className="!max-w-[200px]" label="Address Line 2" onUpdate={({ value }: any) => setAddressLine2(value)} />
          </Flex>

          <Grid css={gridColumnStyles} gap="1rem">
            <div css={{ overflowY: 'auto', height: '37rem' }}>
              <Grid gap="1rem" minHeight="100%">
                {hasResults ? (
                  <RadioGroup
                    isEditable
                    shouldAlwaysUpdate
                    css={{ '&:hover': { background: 'transparent' } }}
                    layout="vertical-dense"
                    maxWidth="100%"
                    onChange={onResultSelected}
                    value={selectedAddress?.id}
                  >
                    {results.map((feature) => {
                      const { id, context } = feature
                      const { place, postcode, region }: any = parseContext(context)
                      return (
                        <Radio
                          key={id}
                          label={buildTitle(feature, addressLine2)}
                          description={`${place?.text}, ${region?.text}, ${postcode?.text} `}
                          value={id}
                        />
                      )
                    })}
                  </RadioGroup>
                ) : (
                  <Text justifyContent="center">{search ? 'No addresses found' : 'Start writing an address above to see results'}</Text>
                )}
              </Grid>
            </div>

            {mq2 && (
              <div css={styles}>
                <MapboxMap
                  css={styles.map}
                  zoom={15}
                  lat={currentLat}
                  lon={currentLon}
                  data={[{ lat: currentLat, lon: currentLon }]}
                  settings={{
                    dragRotate: false,
                  }}
                  viewport={{
                    transitionInterpolator: new FlyToInterpolator({ speed: 3 }),
                    transitionDuration: 'auto',
                  }}
                >
                  <div style={{ position: 'absolute', left: 10, top: 10 }}>
                    <NavigationControl showCompass={false} />
                  </div>
                </MapboxMap>
              </div>
            )}
          </Grid>
        </Grid>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Select Address"
          glyph="check"
          type="primary"
          color="green"
          isLoading={loading}
          onClick={onAddressSelect}
          isDisabled={!selectedAddress}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const gridColumnStyles = {
  height: '100%',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '100%',

  [MEDIA_QUERY[2]]: {
    gridTemplateColumns: '20rem 1fr',
  },

  [MEDIA_QUERY[3]]: {
    gridTemplateColumns: '30rem 1fr',
  },
}

const styles = {
  display: 'none',

  [MEDIA_QUERY[2]]: {
    display: 'grid',
  },

  map: {
    minHeight: '34rem',
    height: '100%',
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
  },
}

export default withOverlayError(withSettings(AddressSelectorOverlay))
