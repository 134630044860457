import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { COLORS } from '../../../../theme'
import { plural, sleep } from '../../../../utils/functions'
import { apiGet } from '../../../../modules/api'
import Notifications from '../../../../modules/notifications'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import useAPI from '../../../../hooks/useAPI'
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard'

import Button from '../../../Button'
import Card from '../../../Card'
import CardHeader from '../../../CardHeader'
import CardSubtitle from '../../../CardSubtitle'
import CardTitle from '../../../CardTitle'
import Chotomate from '../../../Chotomate'
import Divider from '../../../Divider'
import Glyph from '../../../Glyph'
import Icon from '../../../Icon'
import Overlay from '../../../Overlay'
import PayerTransactions from '../../../Elements/transactions/PayerTransactions'
import Section from '../../../Section'
import SigneeStatus from '../../../Statuses/SigneeStatus'
import StripePaymentMethodsSection from '../../../Stripe/StripePaymentMethodsSection'
import StripePaymentOverlay from '../../../Stripe/StripePaymentOverlay'
import SummonOverlay from '../../../SummonOverlay'

const PayerOverlay = () => {
  const { id, resource_id } = useParams()

  const loading = useSelector((state: any) => state.data?.payers?.loading)
  const payer = useSelector((state: any) => state.data?.payers?.data?.[id])
  const tenant = useSelector((state: any) => state.me?.tenant)
  const client = useSelector((state: any) => state.data.clients?.data?.[resource_id])

  const { copy, didCopy } = useCopyToClipboard({ text: payer?.ledger_link })

  useAPI('payers', `/payers/${id}`)

  const onPaymentSuccess = async () => {
    Notifications.send('Refreshing…', 'positive')
    await sleep(5000)

    apiGet({
      name: 'payer_transactions',
      url: `/payers/${id}/financial_transactions?category=subcharge,payment,credit,refund,write_off`,
    })

    apiGet({
      name: 'payer_transactions_stats',
      url: `/payers/${id}/financial_transactions/payer_stats`,
    })

    apiGet({
      name: 'financial_transactions',
      url: `/residents/${client?.id}/financial_transactions?category=charge,payment,credit,refund,write_off`,
    })

    apiGet({
      name: 'financial_transactions_stats',
      url: `/residents/${client?.id}/financial_transactions/stats`,
    })
  }

  return (
    <Overlay maxWidth={80} minHeight={88} position="center" showBackdrop isLoading={loading}>
      <Chotomate ready name="financials_payer_overlay" />

      <Overlay.Header
        icon={plural(payer?.reference?.type, null)}
        title={payer?.reference?.name}
        subtitle={<SigneeStatus payer={payer?.reference} />}
      />

      <Overlay.Content>
        <Section>
          <Card>
            <CardHeader
              graphic={<Icon icon="financials" />}
              after={
                <Button
                  type="primary"
                  onClick={copy}
                  color={didCopy ? 'green' : 'blue'}
                  glyph={didCopy ? 'check' : 'copy'}
                  label={didCopy ? 'Link Copied!' : 'Copy Link'}
                  size={300}
                />
              }
            >
              <CardTitle title="Share Ledger Link" />
              <CardSubtitle
                subtitle={
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    href={payer?.ledger_link}
                    target="_blank"
                    rel="noreferrer"
                    css={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      svg: { marginLeft: '0.25em' },
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    {payer?.ledger_link} <Glyph glyph="external_link" size={14} color={COLORS.blue} />
                  </a>
                }
              />
            </CardHeader>
          </Card>

          {payer && tenant?.is_stripe_connected && (
            <>
              <Divider />

              <StripePaymentMethodsSection
                makeDefaultURL={`/${plural(payer?.reference.type)}/${payer?.reference.id}`}
                customer={payer?.reference}
                tenant={tenant}
                extraActions={({ isEmpty }: any) => (
                  <SummonOverlay
                    overlay={
                      <StripePaymentOverlay
                        stripeConnectID={tenant?.stripe_account_id}
                        passFees={tenant?.financial_prefs?.pass_fees_to_payers}
                        currentBalance={payer?.current_balance}
                        customer={payer?.reference}
                        onSuccess={onPaymentSuccess}
                        source="EHR"
                      />
                    }
                  >
                    <Button
                      label="Make a Payment"
                      type="primary"
                      glyph="dollar"
                      color="green"
                      isDisabled={isEmpty}
                      isLoading={false}
                      permission="ledger.actions.make_payments"
                    />
                  </SummonOverlay>
                )}
              />
            </>
          )}

          <Divider />

          <PayerTransactions className="!mt-4" url={`/payers/${id}/financial_transactions`} />
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}

export default withOverlayError(PayerOverlay)
