import React from 'react'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'

import { apiUpdate } from '../modules/api'
import { COLORS, FEATURES } from '../theme'
import { formatURL } from '../utils/functions'
import { request } from '../modules/axios'

import Button from '../components/Button'
import Flex from '../components/Flex'
import Glyph from '../components/Glyph'
import Icon from '../components/Icon'
import Link from '../components/Link'
import State from '../components/State'
import Status from '../components/Status'
import SummonOverlay from '../components/SummonOverlay'

import { TenantNotionPageSetupOverlay } from '../components/Notion/TenantNotionPageSetupOverlay'
import { NotionRenderer } from '../components/Notion/NotionRenderer'

type Props = {
  tag: string
  className?: string
}

const getFeatureBySlug = (slug: string) => {
  var arr = slug.split('/')

  for (var i = arr.length - 1; i >= 0; i--) {
    const item = arr[i].replaceAll('-', '_')
    const itemPlural = pluralize(item)

    if (item in FEATURES) return FEATURES[item]
    if (itemPlural in FEATURES) return FEATURES[itemPlural]
  }
}

const NOTION_URL = process.env.BH_NOTION_URL

export const TenantNotionPage: React.FC<Props> = ({ tag, className }) => {
  const user = useSelector((state: any) => state.me?.user)
  const isBehave = user?.type === 'bh_employee'

  const notionMetadata = useSelector((state: any) => state.me?.tenant?.metadata?.notion)
  const page = notionMetadata?.[tag]

  const [isLoading, setIsLoading] = React.useState(false)
  const [notionData, setNotionData] = React.useState(null)

  const handleSave = async (data: any) => {
    await apiUpdate({
      name: 'tenant',
      url: '/me/tenant',
      params: {
        metadata: {
          notion: {
            [tag]: data,
          },
        },
      },
      reducer: 'me',
    })
  }

  const components = React.useMemo(
    () => ({
      link: ({ className, children, href = '' }: any) => {
        if (href.includes('app.behavehealth.com')) {
          const to = href.split('app.behavehealth.com')[1]
          const feature = getFeatureBySlug(to) || FEATURES['default']

          return (
            <span css={styles.linkWrapper}>
              <Icon icon={feature.icon} size={16} css={styles.linkIcon} />
              <Link className={className} children={children} to={to} />
            </span>
          )
        }

        return <a className={className} children={children} href={href} target="_blank" />
      },
      pageLink: ({ className, children, href = '' }: any) => {
        const pageId = href.replace('/', '')

        return (
          <a
            href={`${NOTION_URL}/${pageId}`}
            className={className}
            css={styles.notionLink}
            children={
              <>
                {children}
                <Glyph glyph="external_link" size={18} />
              </>
            }
            target="_blank"
          />
        )
      },
    }),
    [],
  )

  React.useEffect(() => {
    if (!page?.notion_page) return

    const getNotionData = async () => {
      setIsLoading(true)

      try {
        const response = await request.get(`${process.env.BH_NOTION_API_BASE}/api/page/${page.notion_page}`, {
          headers: null,
        })
        setNotionData(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    getNotionData()
  }, [page])

  return (
    <div className={className}>
      {isBehave && !isLoading && (
        <div css={styles.editHeader}>
          <Flex centerY gap={8} justifyContent="space-between">
            <SummonOverlay overlay={<TenantNotionPageSetupOverlay title={page?.name} url={page?.url} onSave={handleSave} />}>
              <Button label="Edit Page Link" glyph="edit" type="minimal" size={200} />
            </SummonOverlay>

            <Status label="Tenant Page" glyph="notion" color="white" css={styles.status} />

            {page && (
              <Button
                useGlyphForTarget
                label="Open in Notion"
                color="text"
                glyph="notion"
                href={`https://www.notion.so/${page.notion_page}`}
                size={200}
                target="_blank"
                type="minimal"
              />
            )}
          </Flex>
        </div>
      )}

      {(!page?.type || page?.type === 'notion') && (
        <div css={styles.mainContent}>
          {(isLoading || !notionData) && (
            <State
              isLoading={isLoading}
              isEmpty={!isLoading && !notionData}
              glyph="notion"
              emptyDescription="Page not yet set up"
              emptyActions={
                <SummonOverlay overlay={<TenantNotionPageSetupOverlay title={page?.name} url={page?.url} onSave={handleSave} />}>
                  <Button label="Set Up Link" glyph="add" type="primary" size={200} />
                </SummonOverlay>
              }
            />
          )}

          {!isLoading && notionData && (
            <NotionRenderer fullPage disableHeader showTableOfContents darkMode={false} recordMap={notionData} components={components} />
          )}
        </div>
      )}

      {page?.type === 'iframe' && (
        <iframe
          src={formatURL(page.url)}
          frameBorder="0"
          className="w-full h-[80vh]"
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
        ></iframe>
      )}
    </div>
  )
}

const styles = {
  editHeader: {
    borderBottom: `1px solid ${COLORS.divider}`,
    marginBottom: '1rem',
    padding: '0.25rem 0',
  },

  status: {
    background: 'none !important',
  },

  mainContent: {
    '.notion': {
      background: 'none !important',
    },

    'main.notion-page': {
      marginTop: '1.25rem !important',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },

    'main.notion-page > h1.notion-title': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: '2rem',
    },

    '.notion-page-cover': {
      borderRadius: 7,
      overflow: 'hidden',
    },

    'a.notion-page-link': {
      background: 'none',
    },

    '.notion-page-icon-wrapper': {
      justifyContent: 'flex-start',
      paddingTop: '1rem',
      paddingLeft: '1rem',

      img: {
        maxWidth: '48px !important',
        maxHeight: '48px !important',
        width: '48px !important',
        height: '48px !important',
      },
    },

    '.notion-page-no-cover.notion-page-has-image-icon': {
      paddingTop: '85px !important',
    },
  },

  linkWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  linkIcon: {
    marginRight: '0.25rem',
  },

  notionLink: {
    display: 'inline-flex',
    alignItems: 'center',
    color: COLORS.blue,
    fontWeight: 600,

    '&:hover': {
      border: 'none !important',
      textDecoration: 'none !important',
    },

    '.glyph': {
      marginLeft: '0.5em',
    },

    span: {
      border: 'none',
    },
  },
}
