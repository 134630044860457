import React from 'react'
import { useParams } from 'react-router-dom'

import Card from '../../../components/Card'
import Overlay from '../../../components/Overlay'

import { ChangeHealthRequests } from '../components/ChangeHealthRequests'

export const ClaimOverlayLogs = () => {
  const params = useParams()

  return (
    <Overlay.Content css={{ zIndex: 0 }}>
      <div className="!p-4">
        <Card>
          <ChangeHealthRequests claimID={params?.id} />
        </Card>
      </div>
    </Overlay.Content>
  )
}
