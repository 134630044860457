import React from 'react'

import { COLORS } from '../../theme'
import { useCreate, useGet, invalidateQueries } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'

import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Alert from '../../components/Alert'
import Loader from '../../components/Loader'

import Notifications from '../../modules/notifications'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'

import { useAccountingStore } from './store'

export const AccountingSyncButton = ({ category, invalidate, refetchUrl, statusUrl, forceShow = false }: any) => {
  const { isBehave } = useSettings()

  const categoriesStatusCheck = useAccountingStore((state: any) => state.categoriesStatusCheck)
  const removeCategoryStatusCheck = useAccountingStore((state: any) => state.removeCategoryStatusCheck)

  const storeShouldCheckStatus = categoriesStatusCheck?.includes(category)

  const [shouldCheckStatus, setShouldCheckStatus] = React.useState(true)
  const [wasProcessing, setWasProcessing] = React.useState(false)

  const { mutateAsync, isLoading } = useCreate({
    name: ['sync-accounting'],
    url: refetchUrl,
    invalidate,
    invalidateKeys: [invalidate],
    onSuccess: () => {
      setShouldCheckStatus(true)
    },
    onError: () => {
      setShouldCheckStatus(true)
    },
  })

  const { data, refetch }: any = useGet({
    name: ['accounting', 'status', { category }],
    url: statusUrl || `/accounting/sync_status`,
    params: { category },
    options: {
      refetchInterval: 15 * 1000,
      refetchOnMount: true,
      staleTime: 0,
      enabled: !!category && (shouldCheckStatus || storeShouldCheckStatus),
    },
    onSuccess: (success: any) => {
      if (wasProcessing && success?.data?.status === null) {
        invalidateQueries(invalidate)
        setWasProcessing(false)
      }
    },
  })

  React.useEffect(() => {
    if (data?.status === null) {
      setShouldCheckStatus(false)
      removeCategoryStatusCheck(category)
    } else {
      setWasProcessing(true)
      setShouldCheckStatus(true)
    }
  }, [data])

  if (!forceShow && !isBehave) return null

  if (data?.status === 'processing')
    return (
      <Alert small type="warning">
        <Flex nowrap centerY gap="0.5rem">
          <Loader size={16} color={COLORS.orange} />
          <div>Data syncing is currently in progress, it might take a few minutes.</div>
        </Flex>
      </Alert>
    )

  return (
    <ConfirmDialog
      title="Confirm Sync?"
      message="This will sync the latest data from Quickbooks. It may take a few minutes."
      yesLabel="Yes, Start Syncing"
      onYes={async () => {
        await mutateAsync({})
        await refetch()

        Notifications.send(
          'Your QuickBooks date is currently being synced. Please refresh using the button on the right to load the latest data. It might take a few minutes.',
          'positive',
        )
      }}
    >
      <Button size={100} label="Sync Data" glyph="reset" isLoading={isLoading} />
    </ConfirmDialog>
  )
}
