import React from 'react'

type Props = {
  as: string
  area?: string
  row?: string
  column?: string
} & React.HTMLAttributes<any>

const GridItem: React.FC<Props> = (props) => {
  const { area, as = 'div', children, className, row, column } = props

  const Tag = as

  const dynamicStyles = {
    ...(row && { gridRow: row }),
    ...(area && { gridArea: area }),
    ...(column && { gridColumn: column }),
  }

  return <Tag className={className} children={children} css={dynamicStyles} />
}

export default GridItem
