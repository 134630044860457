import React from 'react'
import { useMedia } from 'use-media'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { countWord } from '../../utils/functions'
import { css } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { ComboBox } from '../../components/Forms/ComboBoxes/ComboBox'
import { PDFViewer } from './components/PDFViewer'
import { PlainSwitch } from '../../components/Forms/Switch'
import { Spreadsheet } from '../../components/Spreadsheet/Spreadsheet'
import { useSpreadsheet } from '../../components/Spreadsheet/useSpreadsheet'
import Alert from '../../components/Alert'
import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import ButtonGroup from '../../components/ButtonGroup'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardTitle from '../../components/CardTitle'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Select from '../../components/Forms/Select'
import State from '../../components/State'
import SummonOverlay from '../../components/SummonOverlay'
import TreeItem from '../../components/TreeItem'

import { ClaimsImportOverlay } from './ClaimsImportOverlay'
import { InvoiceOverlay } from './InvoiceOverlay'
import { SectionCard } from '../../components/SectionCard'
import { ERAViewer } from './components/ERAViewer'
import { ERAClaims } from './components/ERAClaims'

const SERVICE_LINE_STATUSES = {
	draft: 'Draft',
	validated: 'Validated',
	validation_error: 'Validation Error',
	submitted: 'Submitted',
	submission_error: 'Submission Error',
	denied: 'Denied',
	balance_due_client: 'Balance Due Client',
	paid: 'Paid',
	written_off: 'Written Off'
}

const SPREADSHEET_COLUMNS: any = [
	{
		title: 'Client',
		model: 'insurance_claim.resident.name',
		type: 'text',
		readonly: true
	},
	{
		title: 'Service Start Date',
		model: 'insurance_claim_service_line.service_date_start',
		type: 'date',
		readonly: true
	},
	{
		title: 'Service End Date',
		model: 'insurance_claim_service_line.service_date_end',
		type: 'date',
		readonly: true
	},
	{
		title: 'Payment Service Line ID',
		model: 'insurance_claim_service_line.identifier',
		type: 'text',
		readonly: true,
		width: 240
	},
	{
		title: 'Claim ID',
		model: 'insurance_claim.identifier',
		type: 'text',
		readonly: true
	},
	{
		title: 'Procedure Code',
		model: 'insurance_claim_service_line.insurance_new_code.procedure_code',
		type: 'text',
		readonly: true
	},
	{
		title: 'Service Line Status',
		model: 'insurance_claim_service_line.status',
		type: 'text',
		readonly: true,
		formatDisplayValue: ({ cell }) => SERVICE_LINE_STATUSES[cell.data] || '–',
		config: {
			options: [
				{ label: 'Draft', value: 'draft' },
				{ label: 'Validated', value: 'validated' },
				{ label: 'Validation Error', value: 'validation_error' },

				{ label: 'Submitted', value: 'submitted' },
				{ label: 'Submission Error', value: 'submission_error' },
				{ label: 'Denied', value: 'denied' },

				{ label: 'Balance Due Patient', value: 'balance_due_client' },
				{ label: 'Paid', value: 'paid' },
				{ label: 'Written Off', value: 'written_off' }
			]
		}
	},
	{
		title: 'Billed Amount',
		model: 'insurance_claim_service_line.total_amount',
		type: 'amount',
		width: 120,
		readonly: true,
		config: {
			max: 999_999.99,
			validations: {
				lessThan: {
					value: 1_000_000,
					message: 'Amount must be less than $1,000,000'
				}
			}
		}
	},
	{
		title: 'Allowed Amount',
		model: 'allowed_amount',
		type: 'amount',
		width: 120,
		onUpdate: ({ row, cell, set }: any) => {
			const totalAmountCell = row.get('insurance_claim_service_line.total_amount')
			const adjustmentAmount = totalAmountCell.data - cell.data

			set('adjustment_amount', adjustmentAmount || 0)
		},
		config: {
			max: 999_999.99,
			validations: {
				lessThan: {
					value: 1_000_000,
					message: 'Amount must be less than $1,000,000'
				}
			}
		}
	},
	{
		title: 'Insurance Paid',
		model: 'insurance_paid_amount',
		type: 'amount',
		width: 120,
		config: {
			max: 999_999.99,
			validations: {
				lessThan: {
					value: 1_000_000,
					message: 'Amount must be less than $1,000,000'
				}
			}
		}
	},
	{
		title: 'Deductible',
		model: 'deductible_amount',
		type: 'amount',
		width: 120,
		onUpdate: ({ row, cell, set }: any) => {
			const deductibleAmountCell = cell
			const copayAmountCell = row.get('copay_amount')
			const coInsuranceAmountCell = row.get('coinsurance_amount')

			const balanceClientDueAmount = deductibleAmountCell.data + copayAmountCell.data + coInsuranceAmountCell.data

			set('balance_client_due_amount', balanceClientDueAmount || 0)
		},
		config: {
			max: 999_999.99,
			validations: {
				lessThan: {
					value: 1_000_000,
					message: 'Amount must be less than $1,000,000'
				}
			}
		}
	},
	{
		title: 'Co-Pay',
		model: 'copay_amount',
		type: 'amount',
		width: 120,
		onUpdate: ({ row, cell, set }: any) => {
			const deductibleAmountCell = row.get('deductible_amount')
			const copayAmountCell = cell
			const coInsuranceAmountCell = row.get('coinsurance_amount')

			const balanceClientDueAmount = deductibleAmountCell.data + copayAmountCell.data + coInsuranceAmountCell.data

			set('balance_client_due_amount', balanceClientDueAmount || 0)
		},
		config: {
			max: 999_999.99,
			validations: {
				lessThan: {
					value: 1_000_000,
					message: 'Amount must be less than $1,000,000'
				}
			}
		}
	},
	{
		title: 'Coinsurance',
		model: 'coinsurance_amount',
		type: 'amount',
		width: 120,
		onUpdate: ({ row, cell, set }: any) => {
			const deductibleAmountCell = row.get('deductible_amount')
			const copayAmountCell = row.get('copay_amount')
			const coInsuranceAmountCell = cell

			const balanceClientDueAmount = deductibleAmountCell.data + copayAmountCell.data + coInsuranceAmountCell.data

			set('balance_client_due_amount', balanceClientDueAmount || 0)
		},
		config: {
			max: 999_999.99,
			validations: {
				lessThan: {
					value: 1_000_000,
					message: 'Amount must be less than $1,000,000'
				}
			}
		}
	},
	{
		title: 'Claim Adjustment Group Code',
		model: 'adjustment_group_code',
		type: 'text'
	},
	{
		title: 'Adj Reason Code',
		model: 'adjustment_reason_code',
		type: 'text'
	},
	{
		title: 'Adjustment Amount',
		model: 'adjustment_amount',
		type: 'amount',
		width: 150,
		config: {
			max: 999_999.99,
			validations: {
				lessThan: {
					value: 1_000_000,
					message: 'Amount must be less than $1,000,000'
				}
			}
		}
	},
	{
		title: 'Balance Due Client',
		model: 'balance_client_due_amount',
		type: 'amount',
		width: 150,
		config: {
			max: 999_999.99,
			validations: {
				lessThan: {
					value: 1_000_000,
					message: 'Amount must be less than $1,000,000'
				}
			}
		}
	},
	{
		title: 'Invoiced Amount',
		model: 'balance_billed_amount',
		type: 'amount',
		width: 150,
		config: {
			max: 999_999.99,
			validations: {
				lessThan: {
					value: 1_000_000,
					message: 'Amount must be less than $1,000,000'
				}
			}
		}
	}
]

const DESKTOP_MIN_WIDTH = 1200
const DESKTOP_MIN_HEIGHT = 1000

const DESKTOP_MQ = `@media (min-width: ${DESKTOP_MIN_WIDTH}px) and (min-height: ${DESKTOP_MIN_HEIGHT}px)`

const RootPaymentOverlay = (props: any) => {
	const {
		cancel,
		close,
		deleteRecord,
		edit,
		form,
		data,
		initialModel,
		isDeleting,
		isEditable,
		isInvalid,
		isLoading,
		isNew,
		onValidationUpdate,
		isOverlayLoading,
		saveWithData
	} = useOverlay({
		name: 'insurance_new_payments',
		endpoint: '/insurance_new_payments',
		invalidate: 'insurance_new_payments',
		options: props,
		onSaveSuccessful: (data: any) => {
			resetData(data.insurance_new_payment_service_lines)
		}
	})

	const { timezone } = useSettings()
	const isDesktop = useMedia({ minWidth: DESKTOP_MIN_WIDTH, minHeight: DESKTOP_MIN_HEIGHT })
	const shouldPinColumns = useMedia({ minWidth: 800 })

	const [isDetailsOpen, setIsDetailsOpen] = React.useState(true)
	const [isSaving, setIsSaving] = React.useState(false)
	const [invoiceData, setInvoiceData] = React.useState(null)
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)

	const [serviceLinesSnapshot, setServiceLinesSnapshot] = React.useState<any>([])
	const [serviceLines, setServiceLines] = React.useState<any>([])

	const [availableEOBs, setAvailableEOBs] = React.useState(null)
	const [selectedEOB, setSelectedEOB] = React.useState(null)

	const [availableERAs, setAvailableERAs] = React.useState(null)
	const [selectedERA, setSelectedERA] = React.useState(null)

	const [isEOBVisible, setIsEOBVisible] = React.useState(false)
	const [isERAVisible, setIsERAVisible] = React.useState(false)
	const [columnsLayout, setColumnsLayout] = React.useState('vertical')

	const columnsCount = React.useMemo(() => {
		let result = 1

		if (isEOBVisible) result++
		if (isERAVisible) result++

		return result
	}, [isEOBVisible, isERAVisible])

	const serviceLinesCount = size(serviceLines)
	const isServiceLinesEmpty = serviceLinesCount === 0

	const { getData, deleteSelectedRows, selectedIndexes, resetData, ...spreadsheetProps } = useSpreadsheet({
		originalData: serviceLines,
		columns: SPREADSHEET_COLUMNS,
		isEditable,
		freezeColumns: shouldPinColumns ? 3 : 0,
		timezone,
		localStorageKey: 'payment_overlay_service_lines_v1'
	})

	const selectedCount = size(selectedIndexes)

	const toggleDetailsOpen = () => {
		setIsDetailsOpen((c) => !c)
	}

	React.useEffect(() => {
		if (!availableEOBs || size(availableEOBs) === 0) {
			setSelectedEOB(null)
			setIsEOBVisible(false)
		} else {
			if (!!selectedEOB) return
			setSelectedEOB(availableEOBs[0])
		}
	}, [availableEOBs])

	React.useEffect(() => {
		if (!availableERAs || size(availableERAs) === 0) {
			setSelectedERA(null)
			setIsERAVisible(false)
		} else {
			if (!!selectedERA) return
			setSelectedERA(availableERAs[0])
		}
	}, [availableERAs])

	React.useEffect(() => {
		if (!data?.insurance_new_payment_service_lines) return

		// if there is something in the service lines, pre-populate
		if (size(data.insurance_new_payment_service_lines) > 0) {
			setServiceLines(data.insurance_new_payment_service_lines)
			setServiceLinesSnapshot(data.insurance_new_payment_service_lines)
		}
	}, [data])

	const hasEOBs = size(availableEOBs)
	const hasERAs = size(availableERAs)

	const handleCancel = () => {
		setServiceLines(serviceLinesSnapshot)
		resetData(serviceLinesSnapshot)
		cancel()
	}

	const onSave = async () => {
		setIsSaving(true)

		const formValue = form.current.getFormValue()

		const saveData = getData()

		const finalServiceLinesArray = produce(saveData, (draft: any) => {
			for (const row of draft) {
				delete row['insurance_claim']
				delete row['insurance_claim_service_line']
			}
		})

		try {
			await saveWithData({ ...formValue, insurance_new_payment_service_lines_attributes: finalServiceLinesArray })
		} catch (error) {
			console.error(error)
		} finally {
			setIsSaving(false)
		}
	}

	if (isOverlayLoading) return <OverlayLoader position="right" maxWidth={150} />

	const importClaimsButton = (
		<SummonOverlay
			overlay={
				<ClaimsImportOverlay
					onSelect={(selectedClaims: any) => {
						let finalServiceLines: any[] = []

						for (let i = 0; i < selectedClaims.length; i++) {
							const claim = selectedClaims[i]

							for (let j = 0; j < claim.insurance_claim_service_lines.length; j++) {
								const serviceLine = claim.insurance_claim_service_lines[j]

								finalServiceLines.push({
									_id: uuid(),
									insurance_claim: claim,
									insurance_claim_service_line: serviceLine,
									insurance_claim_service_line_id: serviceLine.id
								})
							}
						}

						setServiceLines((prevServiceLines: any) => {
							return [...prevServiceLines, ...finalServiceLines]
						})
					}}
					getImportedData={getData}
				/>
			}
		>
			<Button label="Import Claims" glyph="add_file" type="primary" size={200} />
		</SummonOverlay>
	)

	return (
		<Overlay
			onClose={close}
			showBackdrop={isNew || isEditable}
			isDirty={isEditable}
			position="right"
			maxWidth={150}
			closeOnEscape={!isEditable}
		>
			<Overlay.Header icon="accounting" title="Payment" />

			<Overlay.Content>
				<Form
					isCompact
					getForm={form}
					timezone={timezone}
					isEditable={isEditable}
					initialModel={initialModel}
					onValidationUpdate={onValidationUpdate}
					maxWidth="100%"
					className={STYLES.form().className}
				>
					<div className="px-4 pt-4 border-b border-solid border-0 border-divider">
						{/* <div>
              <TreeItem
                withHover={false}
                title={`${isDetailsOpen ? 'Hide' : 'Show'} Details`}
                className=""
                headerClassName="!text-[0.95rem]"
                onClick={toggleDetailsOpen}
                isOpen={isDetailsOpen}
              />
            </div> */}

						<div className={clsx(isDetailsOpen ? 'block' : 'hidden', 'pb-4')}>
							<Flex gap="0.5rem">
								<SectionCard title="Payment" className="!flex-grow-4 !basis-[500px]">
									<Flex stretchChildrenX gap="1rem">
										<ComboBox
											label="Insurance Provider"
											model="insurance_local_payer"
											icon="insurance"
											type="insurance_local_payers"
											selectTitle={(data: any) => data?.name}
											selectDescription={(data: any) => data?.notes}
											validations={{
												presence: {
													message: 'Please select an Insurance Provider'
												}
											}}
										/>

										<Select label="Payment Method" defaultValue="check" model="payment_method">
											<Option label="Check" value="check" />
											<Option label="Bank Transfer (ACH)" value="ach" />
											<Option label="Other" value="other" />
										</Select>

										<DateInput
											className="!max-w-[200px]"
											label="Payment Date"
											model="payed_at"
											validations={{
												presence: {
													message: 'Please enter a Payment Date'
												}
											}}
										/>

										<Input
											label="Payment Reference #"
											model="reference"
											validations={{
												presence: {
													message: 'Please enter a Payment Reference #'
												}
											}}
										/>
										<AmountInput label="Payment Amount" model="amount" />

										{!isNew && <DateInput isEditable={false} label="Posted Date" model="posted_at" />}

										<MultiOverlaySelector
											label="Connected EOBs"
											type="insurance.insurance_eobs"
											model="insurance_eobs"
											icon="insurance"
											selectTitle={(data: any) => data?.identifier}
											onUpdate={(state: any) => {
												setAvailableEOBs(state.value)
												setIsEOBVisible(size(state.value) > 0)
											}}
										/>

										<MultiOverlaySelector
											label="Connected ERAs"
											type="insurance.insurance_eras"
											model="insurance_eras"
											icon="insurance"
											selectTitle={(data: any) => data?.identifier}
											onUpdate={(state: any) => {
												setAvailableERAs(state.value)
												setIsERAVisible(size(state.value) > 0)
											}}
										/>
									</Flex>
								</SectionCard>

								{!isNew && (
									<>
										<SectionCard title="Service Lines Amounts" className="!flex-grow-1">
											<div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
												<AmountInput isEditable={false} label="Total Billed" value={data.billed_amount} />
												<AmountInput isEditable={false} label="Total Adjustment" value={data.adjustment_amount} />
												<AmountInput isEditable={false} label="Total Allowed Amount" value={data.allowed_amount} />
												<AmountInput
													isEditable={false}
													label="Total Balance Due Client"
													value={data.balance_client_due_amount}
												/>
												<AmountInput
													isEditable={false}
													label="Total Client Respons."
													value={data.client_responsibility_amount}
												/>
												<AmountInput isEditable={false} label="Total Paid Amount" value={data.paid_amount} />
											</div>
										</SectionCard>

										<SectionCard title="Details" className="!flex-grow-1">
											<div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
												<OverlaySelector
													isEditable={false}
													label="Created By"
													model="author"
													type="employees.active"
													icon="employees"
													selectTitle={(data: any) => data.name}
													selectDescription={(data: any) => startCase(data.position)}
												/>

												<OverlaySelector
													isEditable={false}
													label="Last Edited By"
													model="last_edited_by"
													type="employees.active"
													icon="employees"
													selectTitle={(data: any) => data.name}
													selectDescription={(data: any) => startCase(data.position)}
												/>

												<DateTimeInput isEditable={false} label="Created At" model="created_at" />
												<DateTimeInput isEditable={false} label="Last Edited At" model="updated_at" />
											</div>
										</SectionCard>
									</>
								)}
							</Flex>
						</div>
					</div>

					<div className="flex items-center justify-start px-4 py-2">
						<div className="flex items-center mr-4">
							<div className={clsx('flex flex-nowrap items-center', !hasEOBs && 'pointer-events-none opacity-50')}>
								<PlainSwitch
									horizontal
									isEditable
									isChecked={isEOBVisible}
									onCheckedChange={setIsEOBVisible}
									labelWidth="auto"
									withHover={false}
									size={100}
									label={null}
								/>
								<Label label="Show EOB" className="ml-1.5 whitespace-nowrap cursor-pointer" />
							</div>

							<div className={clsx('flex flex-nowrap items-center', !hasERAs && 'pointer-events-none opacity-50')}>
								<PlainSwitch
									horizontal
									isEditable
									isChecked={isERAVisible}
									onCheckedChange={setIsERAVisible}
									labelWidth="auto"
									withHover={false}
									size={100}
									label={null}
								/>
								<Label label="Show ERA" className="ml-1.5 whitespace-nowrap cursor-pointer" />
							</div>

							{isDesktop && (
								<div className={clsx('ml-2', !hasEOBs && !hasERAs && 'pointer-events-none opacity-50')}>
									<ButtonGroup>
										<Button
											size={100}
											label="Vertical"
											color="gray"
											type={columnsLayout === 'vertical' ? 'primary' : 'default'}
											onClick={() => setColumnsLayout('vertical')}
										/>
										<Button
											size={100}
											label="Horizontal"
											color="gray"
											type={columnsLayout === 'horizontal' ? 'primary' : 'default'}
											onClick={() => setColumnsLayout('horizontal')}
										/>
									</ButtonGroup>
								</div>
							)}
						</div>

						{!hasEOBs && !hasERAs && (
							<Alert contrast glyph="info" className="!px-2 !py-1 !text-sm !rounded-md">
								Connect EOBs and/or ERAs to preview them here
							</Alert>
						)}
					</div>

					<div className={clsx(STYLES.gridLayout().className, `columns-${columnsCount}`, `layout-${columnsLayout}`)}>
						<Card isEditable={isEditable} className={STYLES.leftColumn().className}>
							{!isServiceLinesEmpty && (
								<CardHeader
									className={`${
										isEditable ? `bg-[#fff6c5]` : ''
									} shrink-0 border-b border-solid border-0 border-divider`}
								>
									<div className="flex items-center">
										<div className="flex items-center mr-auto">
											<CardTitle
												title={isEditable ? 'Edit Service Lines' : countWord('Service Lines', serviceLinesCount)}
											/>
											{isEditable && selectedCount > 0 && (
												<Button
													label={`Delete ${countWord('Rows', selectedCount)}`}
													size={100}
													color="red"
													glyph="delete"
													className="ml-3"
													onClick={deleteSelectedRows}
												/>
											)}
										</div>

										{isEditable && importClaimsButton}
									</div>
								</CardHeader>
							)}

							<div className="grid grid-cols-[100%] grid-rows-[100%] overflow-hidden">
								{isServiceLinesEmpty ? (
									<State
										isEmpty
										key="empty-state"
										title="Service Lines"
										icon="accounting"
										emptyDescription="No service lines created yet"
										emptyActions={isEditable && importClaimsButton}
									/>
								) : (
									<Spreadsheet {...spreadsheetProps} />
								)}
							</div>
						</Card>

						{isEOBVisible && (
							<Card className={STYLES.rightColumn().className}>
								<CardHeader>
									<div className="flex items-center">
										<div className="mr-4">
											<CardTitle title="EOBs" />
										</div>

										<Flex gap="0.5rem">
											{availableEOBs?.map((eob: any) => (
												<Button
													size={200}
													label={eob.identifier}
													type={selectedEOB?.id === eob.id ? 'primary' : 'default'}
													onClick={() => {
														setSelectedEOB(eob)
													}}
												/>
											))}
										</Flex>
									</div>
								</CardHeader>

								<div className="grid grid-cols-[100%] grid-rows-[100%] overflow-hidden">
									<PDFViewer url={selectedEOB?.upload} />
								</div>
							</Card>
						)}

						{isERAVisible && (
							<Card className={STYLES.rightColumn().className}>
								<CardHeader>
									<div className="flex items-center">
										<div className="mr-4">
											<CardTitle title="ERAs" />
										</div>

										<Flex gap="0.5rem">
											{availableERAs?.map((era: any) => (
												<Button
													size={200}
													label={era.identifier}
													type={selectedERA?.id === era.id ? 'primary' : 'default'}
													onClick={() => {
														setSelectedERA(era)
													}}
												/>
											))}
										</Flex>
									</div>
								</CardHeader>

								<div>
									<ERAClaims data={selectedERA?.insurance_era_claims} />
								</div>

								{/* <ERAViewer content={selectedERA?.content?.report_content} /> */}
							</Card>
						)}
					</div>
				</Form>

				<SummonOverlay isOpen={!!invoiceData} overlay={<InvoiceOverlay />} onClose={() => setInvoiceData(null)} />
				<DeleteDialog
					setOpen={isDeleteDialogOpen}
					onClose={() => setIsDeleteDialogOpen(false)}
					message="Are you sure you want to delete the selected service lines?"
				/>
			</Overlay.Content>

			<Overlay.Footer>
				{isEditable && (
					<>
						<Button
							label="Save"
							glyph="check"
							type="primary"
							color="green"
							isLoading={isSaving}
							onClick={onSave}
							isDisabled={isInvalid}
							flex="100 1 auto"
							permission="insurance_posting.create"
						/>
						{!isNew && (
							<Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={handleCancel} />
						)}
					</>
				)}

				{!isEditable && (
					<>
						<Button
							glyph="edit"
							label="Edit"
							type="default"
							isDisabled={isLoading}
							onClick={edit}
							flex="100 1 auto"
							permission="insurance_posting.edit"
						/>

						<DeleteDialog
							title="Delete Payment?"
							message="Are you sure you want to delete this Payment? This action cannot be undone."
							onYes={deleteRecord}
							permission="insurance_posting.delete"
						>
							<Button
								glyph="delete"
								label="Delete"
								type="default"
								color="red"
								isLoading={isDeleting}
								fullWidth
								permission="insurance_posting.delete"
							/>
						</DeleteDialog>
					</>
				)}
			</Overlay.Footer>
		</Overlay>
	)
}

const STYLES = {
	form: css({
		[DESKTOP_MQ]: {
			height: '100%',
			width: '100%',
			overflow: 'hidden',
			display: 'grid',
			gridTemplateRows: 'auto auto 1fr'
		}
	}),

	gridLayout: css({
		padding: '0 1rem 2rem',
		display: 'grid',
		gridGap: '1rem',

		[DESKTOP_MQ]: {
			gridAutoFlow: 'column',
			gridTemplateColumns: '1fr',
			gridTemplateRows: '100%',
			overflow: 'hidden',

			'&.columns-2': { gridTemplateColumns: '1.5fr 1fr' },
			'&.columns-3': { gridTemplateColumns: '1.5fr 1fr 1fr' },

			'& > *': {
				flex: '1 1 auto'
			},

			'&.layout-vertical': {
				display: 'flex',
				flexWrap: 'nowrap',
				flexDirection: 'column',
				gridGap: 0,

				'& > * + *': {
					marginTop: '1rem'
				}
			}
		}
	}),

	leftColumn: css({
		display: 'grid',
		gridTemplateRows: 'auto 1fr',
		gridTemplateColumns: '100%',
		overflow: 'hidden !important',

		[DESKTOP_MQ]: {
			'.layout-vertical.columns-1 &': {
				flex: '0 1 auto !important'
			},

			'.layout-vertical.columns-2 &': {
				flex: '0 1 auto !important',
				maxHeight: '50% !important'
			},

			'.layout-vertical.columns-3 &': {
				flex: '0 1 auto !important',
				maxHeight: '35% !important'
			}
		}
	}),

	rightColumn: css({
		display: 'grid',
		gridTemplateRows: 'auto 1fr',
		gridTemplateColumns: '100%',
		overflow: 'hidden !important',
		height: 800,

		[DESKTOP_MQ]: {
			height: 'auto'
		}
	})
}

export const PaymentOverlay = withOverlayError(RootPaymentOverlay)
