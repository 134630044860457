import React from 'react'
import { DateTime } from 'luxon'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { transparentize } from 'polished'
import { useEditor } from '@tiptap/react'
import produce from 'immer'
import size from 'lodash/size'

import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'

import { COLORS, SHADOW } from '../../theme'
import { Lottie } from '../../components/Lottie'
import { request } from '../../modules/axios'
import { THREE_DOTS_ANIMATION } from '../../theme/defs/lottie'
import { getPrefix, useCreate, useGet } from '../../hooks/useNewAPI'
import useStore from '../../modules/store'

import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Glyph from '../../components/Glyph'
import State from '../../components/State'
import SubmitButton from '../../components/SubmitButton'

import { AIBotIcon } from '../AI/AIBotIcon'
import { ChatMessage } from '../AI/AIChatsPage/components/ChatMessage'
import { TiptapPromptInput } from '../AI/AIChatsPage/components/TiptapPromptInput'

const API_BASE_URL = `${process.env.BH_API_BASE_PROTOCOL}://${process.env.BH_API_BASE_URL}/v1`

export const BehaveAIChat = (props: any) => {
  const {
    allChatsQueryKey,
    afterMessages,
    chatId,
    className,
    onMessagesUpdate,
    messageClassName,
    footerClassName,
    mainClassName,
    onChatUpdate,
  } = props

  const newUser = useStore((state: any) => state.newUser)

  const [prompt, setPrompt]: any = React.useState('')
  const [scrollRef, setScrollRef]: any = React.useState(null)
  const [messages, setMessages]: any = React.useState([])
  const [isProcessing, setIsProcessing] = React.useState(false)

  const [streamedMessage, setStreamedMessage] = React.useState('')
  const [shouldSummarize, setShouldSummarize] = React.useState(false)

  const {
    data: chat,
    isLoading: isChatLoading,
    refetch,
  }: any = useGet({
    name: ['ai_chat', chatId],
    url: `/ai/chats/${chatId}`,
    options: { enabled: !!chatId },
  })

  const { mutateAsync: summarizeTitle, isLoading: isSummarizingTitle }: any = useCreate({
    name: ['summarize_title'],
    url: `/ai/chats/${chatId}/summarize_title`,
    onSuccess: ({ data: newData }: any, _variables: any, queryClient: any) => {
      const prefix = getPrefix()

      // update chat name
      queryClient.setQueryData([prefix, 'ai_chat', chatId], (oldData: any) => {
        if (!oldData?.data) return

        return produce(oldData, (draft: any) => {
          draft.data = newData
        })
      })

      if (allChatsQueryKey) {
        queryClient.setQueryData([prefix, ...allChatsQueryKey], (oldData: any) => {
          if (!oldData?.data) return

          const index = oldData.data.findIndex((o) => o.id === chatId)
          if (index === -1) return

          return produce(oldData, (draft: any) => {
            draft.data[index] = newData
          })
        })
      }
    },
  })

  const isMessagesEmpty = size(messages) === 0
  const isPromptEmpty = size(prompt) === 0

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Placeholder.configure({
        placeholder: 'Type something…',
      }),
    ],
    onUpdate: ({ editor }) => {
      const text = editor.getText()
      setPrompt(text)
    },
  })

  const appendContent = (content: string) => {
    if (!editor) return

    editor.commands.insertContent(content)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    sendMessage()
  }

  const handleEnterKeyPress = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      sendMessage()
    }
  }

  const processMessages = async () => {
    await fetchEventSource(`${API_BASE_URL}/ai/chats/${chatId}/process`, {
      method: 'POST',
      openWhenHidden: true,
      headers: request.defaults.headers.common,
      body: JSON.stringify({ stream: 'true' }),
      async onopen() {
        setIsProcessing(true)
      },
      onmessage(event) {
        setIsProcessing(true)
        setStreamedMessage((c) => c + event.data)
      },
      onclose() {
        setIsProcessing(false)
      },
      onerror(err) {
        setIsProcessing(false)
        console.error(err)
      },
    })
  }

  const sendMessage = async () => {
    if (size(prompt) === 0 || isProcessing) return

    if (!chat?.name) {
      setShouldSummarize(true)
    }

    editor?.commands.clearContent()

    const savedPrompt = prompt
    setPrompt('')

    try {
      // add temporary message until API responds
      const tempMessage = {
        role: 'user',
        author: newUser,
        content: prompt,
        created_at: DateTime.local().toISO(),
      }

      setMessages((current: any) => [...current, tempMessage])

      // start processing state
      setIsProcessing(true)

      // send message to API
      const messageResult = await request.post('/ai/chat_messages', {
        ai_chat_id: chatId,
        role: 'user',
        content: prompt,
      })

      // replace temporary message with the actual message
      setMessages((current: any) =>
        produce(current, (draft: any) => {
          const tempMessageIndex = size(draft) - 1
          draft.splice(tempMessageIndex, 1)

          if (messageResult.data.data) draft.push(messageResult.data.data)
        }),
      )

      await processMessages()
    } catch (error) {
      console.error(error)

      // revert back to the prompt
      setPrompt(savedPrompt)
    } finally {
      setIsProcessing(false)
      refetch()
    }
  }

  React.useEffect(() => {
    if (!scrollRef) return

    scrollRef.scroll({
      top: scrollRef.scrollHeight,
      behavior: 'smooth',
    })
  }, [messages, streamedMessage])

  // Summarize chat title
  React.useEffect(() => {
    if (isProcessing || !chat || !shouldSummarize) return

    if (!chat?.name && size(messages) === 2) {
      summarizeTitle()
    }
  }, [shouldSummarize, messages, chat, isProcessing])

  React.useEffect(() => {
    if (!onMessagesUpdate) return

    onMessagesUpdate(messages)
  }, [messages])

  React.useEffect(() => {
    if (!chat) return

    setMessages(chat.messages)
  }, [chat])

  React.useEffect(() => {
    if (!chat || !onChatUpdate) return

    onChatUpdate(chat)
  }, [onChatUpdate, chat])

  React.useEffect(() => {
    if (isProcessing) return

    if (streamedMessage) {
      setMessages([...messages, { role: 'assistant', content: streamedMessage }])
      setStreamedMessage('')
    }
  }, [isProcessing, streamedMessage])

  return (
    <main css={STYLES.main} className={className}>
      <div ref={setScrollRef} css={STYLES.mainContent} className={mainClassName}>
        {!messages || isChatLoading || isMessagesEmpty ? (
          <State
            title="Behave AI Chat"
            icon="behave_ai"
            isEmpty={isMessagesEmpty}
            isLoading={isChatLoading}
            emptyDescription="Type a message below to start chatting"
          />
        ) : (
          messages?.map((message: any, index: number) => (
            <ChatMessage
              key={index}
              message={message}
              assistantGraphic={chat?.ai_bot && <AIBotIcon icon={chat.ai_bot.icon} color={chat.ai_bot.color} />}
              className={messageClassName}
            />
          ))
        )}

        {(streamedMessage || isProcessing) && (
          <ChatMessage
            isLoading={isProcessing}
            assistantGraphic={
              isProcessing && !streamedMessage ? (
                <Lottie autoplay animationData={THREE_DOTS_ANIMATION} loop={true} />
              ) : (
                chat?.ai_bot && <AIBotIcon icon={chat.ai_bot.icon} color={chat.ai_bot.color} />
              )
            }
            message={{
              role: 'assistant',
              content: streamedMessage || 'Thinking…',
            }}
            className={messageClassName}
          />
        )}

        {afterMessages}
      </div>

      <div className={footerClassName}>
        <Form onSubmit={handleSubmit} css={STYLES.promptForm}>
          <TiptapPromptInput editor={editor} appendContent={appendContent} onKeyPress={handleEnterKeyPress} />

          <div css={STYLES.promptFormFooter}>
            <Flex centerY gap="1rem" justifyContent="space-between">
              <SubmitButton
                label="Send"
                glyph="circle_arrow_right"
                size={200}
                type="primary"
                isDisabled={isChatLoading || isPromptEmpty || isProcessing}
                css={STYLES.sendButton}
              />
            </Flex>
          </div>
        </Form>
      </div>
    </main>
  )
}

const STYLES = {
  nav: {
    flex: '0 0 300px',
    background: COLORS.white,
    zIndex: 2,
    position: 'relative',
  },

  subheader: {
    fontSize: '0.92rem',
    padding: '0.5rem 0.75rem',
    background: transparentize(0.5, COLORS.white),
    b: { fontWeight: 600 },
    svg: { marginRight: '0.5rem' },
    lineHeight: 1,

    boxShadow: `
      0 0.5px 0 ${COLORS.divider},
      0 2px 6px ${COLORS.shadow},
      0 4px 12px ${COLORS.shadow},
      0 8px 24px ${COLORS.shadow}
    `,
  },

  addButton: {
    margin: '0 -0.4rem 1rem',
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flex: '1 1 auto',
  },

  mainContent: {
    flex: '1 1 auto',
    overflowY: 'auto',
  },

  promptForm: {
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    // maxWidth: 520,
    margin: '1rem auto',
    border: `1px solid ${COLORS.divider}`,
    background: COLORS.white,
    boxShadow: SHADOW(4),
    marginBottom: '1rem',
    borderRadius: 5,
    overflow: 'hidden',
    position: 'sticky',
    bottom: 10,
  },

  promptFormFooter: {
    width: '100%',
    padding: '0.5rem',
  },

  sendButton: {
    minWidth: 100,
  },
}
