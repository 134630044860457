import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, Grid, Chotomate, Page, HelpTagIframe, Link } from '@behavehealth/components'
import { ShiftNotesTable } from '@behavehealth/components/Tables'

import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

const pageConfig = {
  feature: 'shift_notes',
  help: <HelpTagIframe id="shift_notes" />,
  marketingID: 'shift_notes',
}

const ShiftNotes = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const [filters, setFilters] = React.useState({})

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'shift-notes', { filters }],
    url: `/residents/${resource_id}/housing_notes`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const { mutate: deleteShiftNotes } = useDelete({
    name: ['client', resource_id, 'shift-notes'],
    url: '/housing_notes',
    invalidate: 'shift-notes',
  })

  const isEmpty = size(data) === 0

  const actions = (
    <>
      <Dropdown label="Add Shift Notes…" glyph="add" buttonType="primary" permission="shift_notes.create">
        <DropdownItem
          label="Add Shift Note"
          icon="housing_notes"
          color="blue"
          link={{
            pathname: `${match.url}/new`,
            data: {
              residents: [client],
            },
          }}
        />
        <DropdownItem label="Add Many Shift Notes…" glyph="stack" color="blue" link={`${match.url}/new-batch`} featureFlagV2="bulk_add" />
      </Dropdown>
    </>
  )

  return (
    <Page actions={!isEmpty && canCreate && actions} {...pageConfig}>
      <Grid gap="1rem" columns="100%">
        <Chotomate name="shift_notes" ready={!isLoading} />

        <Filters config={FILTERS.shift_notes} onUpdate={setFilters} localStorageKey="client_shift_notes" />

        <Card>
          <ShiftNotesTable
            data={data}
            isLoading={isEmpty && isLoading}
            emptyActions={actions}
            localStorageKey="clients_shift_notes"
            to={(id: any) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            titleAfter={<Link to="/reports/shift-notes">View Full Report →</Link>}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'shift_notes.delete',
                action: async ({ ids }: any) => {
                  await deleteShiftNotes(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ShiftNotes, pageConfig))
