import { BreathalyzerTestOverlay } from '../TestResults/BreathalyzerTestOverlay'
import { MedicationDestructionLogOverlay } from '../Medications/MedicationDestructionLogOverlay'
import { MedicationIncidentLogOverlay } from '../Medications/MedicationIncidentLogOverlay'
import { MedicationLogOverlay } from '../Medications/MedicationLogOverlay'
import { UATestOverlay } from '../TestResults/UATestOverlay'
import { VitalSignsOverlay } from '../TestResults/VitalSignsOverlay'

import { BreathalyzerTestWorksheetOverlay } from '../TestResults/BreathalyzerTestWorksheetOverlay'
import { MedicationLogWorksheetOverlay } from '../Medications/MedicationLogWorksheetOverlay'
import { SafetyCheckOverlay } from '../SafetyChecks/SafetyCheckOverlay'
import { SafetyCheckWorksheetOverlay } from '../SafetyChecks/SafetyCheckWorksheetOverlay'
import { UATestWorksheetOverlay } from '../TestResults/UATestWorksheetOverlay'

export const ROUNDS_CATEGORIES = {
  safety_check: {
    label: 'Safety Checks',
    singularLabel: 'Safety Check',
    icon: 'safety_checks',
    featureFlag: 'safety_checks',
    permission: 'safety_checks',
    invalidate: 'safety_checks',
    overlay: SafetyCheckOverlay,
    worksheetOverlay: SafetyCheckWorksheetOverlay,
    getOverlayInitialData: (roundLine: any) => ({ resident: roundLine.reference }),
    getWorksheetInitialData: (roundLines: any[]) => {
      if (!roundLines) return []

      return roundLines.map((roundLine) => ({
        resident: roundLine.reference,
        ...roundLine.safety_check,
      }))
    },
    worksheetEditDisabledColumns: ['resident'],
  },
  ua_test: {
    label: 'UA Tests',
    singularLabel: 'UA Test',
    icon: 'test_results',
    featureFlag: 'ua_tests',
    permission: 'test_results.ua_tests',
    invalidate: 'ua-tests',
    overlay: UATestOverlay,
    worksheetOverlay: UATestWorksheetOverlay,
    getOverlayInitialData: (roundLine: any) => ({ reference: roundLine.reference }),
    getWorksheetInitialData: (roundLines: any[]) => {
      if (!roundLines) return []

      return roundLines.map((roundLine) => ({
        reference: roundLine.reference,
        ...roundLine.ua_test,
      }))
    },
    worksheetEditDisabledColumns: ['reference'],
  },
  breathalyzer_test: {
    label: 'Breathalyzer Tests',
    singularLabel: 'Breathalyzer Test',
    icon: 'test_results',
    featureFlag: 'breathalyzer_tests',
    permission: 'test_results.breathalyzer_tests',
    invalidate: 'breathalyzer-tests',
    overlay: BreathalyzerTestOverlay,
    worksheetOverlay: BreathalyzerTestWorksheetOverlay,
    getOverlayInitialData: (roundLine: any) => ({ resident: roundLine.reference }),
    getWorksheetInitialData: (roundLines: any[]) => {
      if (!roundLines) return []

      return roundLines.map((roundLine) => ({
        resident: roundLine.reference,
        ...roundLine.breathalyzer_test,
      }))
    },
    worksheetEditDisabledColumns: ['resident'],
  },
  vital_sign: {
    label: 'Vital Signs',
    singularLabel: 'Vital Signs',
    icon: 'test_results',
    featureFlag: 'vital_signs',
    permission: 'test_results.vital_signs',
    invalidate: 'vital-signs',
    overlay: VitalSignsOverlay,
    getOverlayInitialData: (roundLine: any) => ({ client: roundLine.reference }),
  },
  medication_log: {
    label: 'Medication Logs',
    singularLabel: 'Medication Log',
    icon: 'med_pass',
    featureFlag: 'medication_logs',
    permission: 'medication_logs',
    invalidate: 'medication-log',
    overlay: MedicationLogOverlay,
    worksheetOverlay: MedicationLogWorksheetOverlay,
    getOverlayInitialData: (roundLine: any) => ({ resident: roundLine.reference }),
    getWorksheetInitialData: (roundLines: any[]) => {
      if (!roundLines) return []

      return roundLines.map((roundLine) => ({
        resident: roundLine.reference,
        ...roundLine.medication_log,
      }))
    },
    worksheetEditDisabledColumns: ['resident'],
  },
  medication_incident_log: {
    label: 'Medication Incident Logs',
    singularLabel: 'Medication Incident Log',
    icon: 'medication_incidents',
    featureFlag: 'medication_incident_logs',
    permission: 'medication_incident_logs',
    invalidate: 'medication_incident_logs',
    overlay: MedicationIncidentLogOverlay,
    getOverlayInitialData: (roundLine: any) => ({ client: roundLine.reference }),
  },
  medication_destruction_log: {
    label: 'Medication Destruction Logs',
    singularLabel: 'Medication Destruction Log',
    icon: 'medication_inventory',
    featureFlag: 'medication_destruction_logs',
    permission: 'medication_destruction_logs',
    invalidate: 'medication-destruction-log',
    overlay: MedicationDestructionLogOverlay,
    getOverlayInitialData: (roundLine: any) => ({ client: roundLine.reference }),
  },
}
