import React from 'react'

import Table from '../../Table/Table'
import TableCell from '../../Table/TableCell'

import withSettings from '../../../hocs/withSettings'

const CATEGORIES: any = {
  general: 'General',
  client_admission: 'Client Admission',
  recovery_coaching: 'Recovery Coaching',
  housing_activity: 'Housing Activity',
  discharge: 'Discharge',
  psych_appointment: 'Psych Appointment',
  medical_appointment: 'Medical Appointment',
  clinical_appointment: 'Clinical Appointment',
  progress_appointment: 'Progress Appointment',
  individual_therapy: 'Individual Therapy',
  group_therapy: 'Group Therapy',
  individual_peer_note: 'Individual Peer Note',
  group_peer_notes: 'Group Peer Note',
  individual_daily_note: 'Individual Daily Note',
  group_daily_notes: 'Group Daily Note',
  meds_pass: 'Meds Pass',
  urinalysis: 'Urinalysis',
  breathalyzer: 'Breathalyzer',
  vitals: 'Vitals',
  communicable_tests: 'Communicable Tests',
  bed_check: 'Bed Check',
  housing_intake: 'Housing Intake',
  show_as_busy: 'Show as Busy',
}

const columns = (to, onClick, mainLinkAs) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'title',
    Header: 'Title',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink
        as={mainLinkAs}
        to={to?.(row.original.id)}
        onClick={() => onClick?.(row.original)}
        label={value}
        avatar={row.original.avatar}
      />
    ),
  },
  {
    width: 160,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ value }: any) => {
      if (!(value in CATEGORIES)) return <TableCell.Empty />

      return CATEGORIES[value]
    },
  },
  {
    width: 200,
    accessor: 'public_description',
    Header: 'Public Description',
    Cell: ({ value }: any) => <TableCell.Markup value={value} />,
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  setTableColumns?: Function
  timezone: string
  title: string
  to?: Function
}

const EventTemplatesTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    isLoading,
    localStorageKey,
    onClick,
    setTableColumns,
    timezone,
    title,
    to,
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={setTableColumns ? setTableColumns(to, timezone) : columns(to, onClick, props.mainLinkAs)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

EventTemplatesTable.defaultProps = {
  title: 'Event Templates',
  icon: 'calendar',
  emptyDescription: 'No event templates to display',
  localStorageKey: 'event_templates',
}

export default withSettings(EventTemplatesTable)
