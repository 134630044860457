import React from 'react'
import { useMedia } from 'use-media'

import { COLORS, BREAKPOINT, TOKENS } from '../theme'

type Props = {
  columns: string
  background: any
}

const AppLayout: React.FC<Props> = ({ children, columns, background, className }) => {
  // When AppNav is shown, use Grid multi-column layout
  const useGridLayout = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavCompactBreakpoint] })

  const dynamicStyles: any = {
    background,

    ...(useGridLayout && {
      display: 'grid',
      gridAutoFlow: 'column',
      gridAutoColumns: '1fr',
      gridTemplateColumns: columns,
      gridTemplateRows: '100%',

      '& > *': {
        overflowY: 'auto',
        overflowX: 'hidden',
        WebkitOverflowScrolling: 'touch',
      },
    }),

    ...(!useGridLayout && {
      overflowY: 'auto',
      overflowX: 'hidden',
      WebkitOverflowScrolling: 'touch',
      background: background || COLORS.lightBackground,
    }),
  }

  return (
    <div data-testid="APP" css={[styles, dynamicStyles]} className={className}>
      {children}
    </div>
  )
}

const styles = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
}

AppLayout.defaultProps = {
  columns: 'min-content 1fr',
}

export default AppLayout
