import React from 'react'

import SmartTextarea from '../../../components/Forms/SmartTextarea'

export const PROGRESS_NOTE_SECTIONS = [
  {
    title: 'Progress Note',
    model: 'progress_note',
    fields: [
      {
        label: 'Note Description',
        model: 'note_description',
        component: () => <SmartTextarea useQuickText useDictation label="Note Description" model="data.notes" />,
      },
    ],
  },
]
