import React from 'react'

import { usDateTime, isPast } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 220,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'started_at',
    Header: 'From',
    Cell: ({ value, row }) => {
      return <TableCell.MainLink to={to(row.original.id)} label={usDateTime(value, timezone)} />
    },
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 220,
    accessor: 'ended_at',
    Header: 'Until',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value, row }: any) => {
      if (row.original.period_type === 'indefinite') return 'Discharged'

      return <TableCell.UsDateTime value={value} timezone={timezone} />
    },
  },
  {
    width: 200,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ row }: any) => {
      if (row.original.period_type === 'indefinite') return <Status color="green" label="Active" />
      if (isPast(row.original.ended_at)) return <Status color="gray" label="Ended" />

      return <Status color="green" label="Active" />
    },
  },
  {
    width: 200,
    accessor: 'place.house',
    Header: 'Location',
    Cell: ({ value }) => {
      return <TableCell.Profile name={value?.name} avatar={value?.avatar} />
    },
  },
  {
    width: 200,
    accessor: 'place.floor.name',
    Header: 'Floor',
  },
  {
    width: 200,
    accessor: 'place.room.name',
    Header: 'Room',
  },
  {
    width: 200,
    accessor: 'place.name',
    Header: 'Bed',
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 220,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
]

export const BedOccupanciesTable = (props: any) => {
  const {
    data,
    emptyDescription = 'No bed assignments have been created yet',
    icon = 'occupancies',
    localStorageKey = 'bed_occupancies',
    title = 'Bed Assignments',
    to,
    ...rest
  } = props

  const { timezone } = useSettings()

  return (
    <Table
      testKey="bed_assignments_table"
      columns={columns(to, timezone)}
      data={data}
      emptyDescription={emptyDescription}
      icon={icon}
      localStorageKey={localStorageKey}
      title={title}
      {...rest}
    />
  )
}
