import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import ReportStatus from '../../components/Statuses/ReportStatus'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const DataExportsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        width: 260,
        isSticky: true,
        canToggleVisible: false,
        id: 'customer',
        model: 'data.customer.name',
        title: 'Owner',
        formatValue: ({ data, value }: any) => <MainCell id={data.data.id} value={value} avatar={data.data.avatar} />,
      },
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.data.id} value={value} avatar={data.data.avatar} to={to?.(data.data)} />,
      },
      {
        width: 140,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ value }: any) => <ReportStatus status={value} />,
      },
      {
        width: 200,
        id: 'created_at',
        model: 'data.created_at',
        title: 'Requested At',
        type: 'date_time',
      },
      {
        width: 160,
        id: 'starting_at',
        model: 'data.starting_at',
        title: 'Start Date',
        type: 'date_time',
      },
      {
        width: 160,
        id: 'ending_at',
        model: 'data.ending_at',
        title: 'End Date',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'data.author.name',
        title: 'Requested By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Data Exports"
      icon="data_export"
      columns={columns}
      filtersConfig={FILTERS.live_reports.data_exports}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="data_exports"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=data_exports'}
        />
      }
      {...props}
    />
  )
}
