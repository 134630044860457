import React from 'react'

import { CLAIM_STATUSES } from '../../../utils/constants'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

const ClaimInternalStatusSelect: React.FC<any> = ({ label = 'Internal Status', model = 'status' }) => (
  <Select label={label} model={model} defaultValue="draft">
    {Object.entries(CLAIM_STATUSES).map(([key, label]) => (
      <Option key={key} label={label} value={key} />
    ))}
  </Select>
)

export default ClaimInternalStatusSelect
