import React from 'react'
import { connect } from 'react-redux'

import useIsMounted from '../hooks/useIsMounted'
import useHotkeys from '../hooks/useHotkeys'
import { Glyph as GlyphType } from '../declarations/types/Glyph'
import { Color } from '../declarations/types/Color'

import Animated from './Animated'
import Button from './Button'
import Overlay from './Overlay'
import Portal from './Portal'
import Section from './Section'
import Glyph from './Glyph'
import { Text } from './Typography'
import NoInternet from './Alerts/NoInternet'

type Props = {
  isDisabled?: boolean
  setOpen?: boolean
  skip?: boolean
  skipCloseHotkey?: boolean
  online?: boolean
  width?: number
  title?: string
  glyph?: GlyphType
  message?: string
  messageGlyph?: string
  messageGlyphColor?: string
  content?: React.ReactNode
  onYes?: Function
  onNo?: Function
  noLabel?: boolean
  noColor?: Color
  showNoButton?: boolean
  showYesButton?: boolean
  yesLabel?: string
  yesColor?: Color
  isValid?: boolean
  isLoading?: boolean
  onDisabledClick?: Function
  render?: Function | React.ReactNode
  className?: string
  onClose?: any
  testKey?: string
  portal?: string
}

const Dialog: React.FC<Props> = ({
  isDisabled,
  setOpen = false,
  skip,
  skipCloseHotkey,
  online,
  children,
  className,
  width = 32,
  title = 'Title',
  glyph = 'behave_health',
  message,
  messageGlyph,
  messageGlyphColor,
  content,
  onYes,
  onNo,
  noLabel = 'No, Cancel',
  noColor,
  showNoButton = true,
  showYesButton = true,
  yesLabel = 'Yes, I am sure',
  yesColor = 'blue',
  isValid = true,
  isLoading,
  onDisabledClick,
  render,
  onClose,
  testKey,
  portal = 'dialog',
  onOpenChange,
  closeOnBackdrop = true,
}) => {
  const [isOpen, setIsOpen] = React.useState(setOpen)
  const [loading, setLoading] = React.useState(false)
  const isMounted = useIsMounted()

  const open = (event) => {
    if (isDisabled) {
      if (onDisabledClick) onDisabledClick()
      return
    }

    if (event) {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
    }

    if (!skip) setIsOpen(true)
    else if (onYes) onYes()
  }

  const close = () => {
    if (!isMounted) return
    if (onClose) onClose()
    setIsOpen(false)
  }

  const onNegative = () => {
    if (onNo) onNo()
    close()
  }

  const onPositive = async () => {
    if (onYes) {
      try {
        setLoading(true)
        await onYes()
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    close()
  }

  useHotkeys(
    'dialog',
    [
      {
        key: 'esc',
        description: 'Dialog Close',
        action: () => open(),
        restrict: true,
      },
    ],
    skipCloseHotkey,
  )

  React.useEffect(() => {
    setIsOpen(setOpen)
  }, [setOpen])

  React.useEffect(() => {
    if (onOpenChange) onOpenChange(isOpen)
  }, [isOpen])

  // if (!render) return null

  return (
    <>
      {children && (
        <div onClick={open} className={className}>
          {children}
        </div>
      )}
      {render && render(open)}

      <Portal type={portal}>
        <Animated show={isOpen} animation="animation">
          <Overlay
            showBackdrop
            stopPropagation
            closeOnBackdrop={closeOnBackdrop}
            position="center"
            onClose={close}
            maxWidth={width}
            testKey={testKey}
          >
            <Overlay.Header title={title} glyph={glyph} />

            <Overlay.Content>
              <Section>
                {messageGlyph && (
                  <div css={{ textAlign: 'center', width: '100%' }}>
                    <Glyph glyph={messageGlyph} size={38} color={messageGlyphColor} css={{ display: 'inline' }} />
                  </div>
                )}
                {message && <Text description={message} />}
                {content}
              </Section>
            </Overlay.Content>

            <Overlay.Footer>
              {showNoButton && (
                <Button
                  testKey="confirm_negative"
                  label={noLabel}
                  type="default"
                  color={noColor}
                  onClick={onNegative}
                  isDisabled={loading}
                />
              )}
              {online && showYesButton && (
                <Button
                  testKey="confirm_positive"
                  type="primary"
                  color={yesColor}
                  label={yesLabel}
                  onClick={onPositive}
                  isDisabled={isLoading || loading || !isValid}
                  isLoading={isLoading || loading}
                />
              )}
              {!online && <NoInternet />}
            </Overlay.Footer>
          </Overlay>
        </Animated>
      </Portal>
    </>
  )
}

const mapStateToProps = (state) => ({
  online: state?.common?.global?.online,
})

export default connect(mapStateToProps)(Dialog)
