import React from 'react'
import size from 'lodash/size'
import startCase from 'lodash/startCase'
import { useSelector } from 'react-redux'

import { ICONS } from '../../../theme'

import Button from '../../Button'
import Card from '../../Card'
import CardHeader from '../../CardHeader'
import CardsGrid from '../../CardsGrid'
import CardSubtitle from '../../CardSubtitle'
import CardTitle from '../../CardTitle'
import Dialog from '../../Dialog'
import DropdownItem from '../../DropdownItem'
import Flex from '../../Flex'
import Icon from '../../Icon'
import Glyph from '../../Glyph'
import PageSection from '../../PageSection/PageSection'
import State from '../../State'
import Status from '../../Status'
import SummonOverlay from '../../SummonOverlay'

import StripeAddCardOverlay from './StripeAddCardOverlay'
import useStore from '../../../modules/store'

import { apiGet, apiUpdate, apiDelete } from '../../../modules/api'

export const PaymentMethodCard = ({
  card,
  paymentMethodID,
  defaultPaymentMethodID,
  refreshPaymentMethods,
  isLastPaymentMethod,
  hasNoActiveSubscriptions,
}: any) => {
  if (!card) return null

  const cardIcon = ICONS[card.brand] || ICONS.billing
  const isDefault = paymentMethodID === defaultPaymentMethodID
  const canDelete = !isDefault || (isLastPaymentMethod && hasNoActiveSubscriptions)
  const updateStore = useStore((state) => state.update)

  const makeDefault = async () => {
    const tenant = await apiUpdate({
      url: '/me/tenant',
      params: { stripe_default_payment_id: paymentMethodID },
      notify: false,
    })

    updateStore({ tenant: tenant?.data?.data })
  }

  const deletePaymentMethod = async () => {
    await apiDelete({
      url: `/apps/stripe/payment_methods/${paymentMethodID}?is_default=${isDefault}`,
      notify: false,
    })

    if (refreshPaymentMethods) await refreshPaymentMethods()
  }

  return (
    <Card>
      <CardHeader
        graphic={<Icon icon={cardIcon} />}
        actions={
          (!isDefault || canDelete) && (
            <>
              {!isDefault && (
                <Dialog
                  glyph="card"
                  title="Change Default Payment Method?"
                  message={
                    <div>
                      <div>Your new default payment method will be set to:</div>
                      <Flex nowrap gap={8} alignItems="center">
                        <Icon icon={cardIcon} size={20} />
                        <div>
                          <b>
                            {startCase?.(card.brand)} **** {card.last4}
                          </b>
                        </div>
                      </Flex>
                    </div>
                  }
                  yesColor="green"
                  yesLabel="Set as Default"
                  onYes={makeDefault}
                >
                  <DropdownItem label="Make Default" glyph="check" color="green" />
                </Dialog>
              )}

              {canDelete && (
                <Dialog
                  glyph="delete"
                  title="Delete Card?"
                  message="Are you sure you want to delete this card? This action cannot be undone."
                  yesColor="red"
                  yesLabel="Yes, Delete Card"
                  onYes={deletePaymentMethod}
                >
                  <DropdownItem label="Delete Card" glyph="delete" color="red" />
                </Dialog>
              )}
            </>
          )
        }
      >
        <CardTitle title={`${startCase?.(card.brand)} **** ${card.last4}`} after={isDefault && <Status label="Default" color="blue" />} />
        <CardSubtitle subtitle={`Expires ${card?.exp_month}/${card?.exp_year}`} />
      </CardHeader>
    </Card>
  )
}

export const PaymentMethodBankAccount = ({
  bankAccount,
  paymentMethodID,
  defaultPaymentMethodID,
  refreshPaymentMethods,
  isLastPaymentMethod,
  hasNoActiveSubscriptions,
}: any) => {
  if (!bankAccount) return null

  const isDefault = paymentMethodID === defaultPaymentMethodID
  const canDelete = !isDefault || (isLastPaymentMethod && hasNoActiveSubscriptions)
  const updateStore = useStore((state) => state.update)

  const makeDefault = async () => {
    const tenant = await apiUpdate({
      url: '/me/tenant',
      params: { stripe_default_payment_id: paymentMethodID },
      notify: false,
    })

    updateStore({ tenant: tenant?.data?.data })
  }

  const deletePaymentMethod = async () => {
    await apiDelete({
      url: `/apps/stripe/payment_methods/${paymentMethodID}?is_default=${isDefault}`,
      notify: false,
    })

    if (refreshPaymentMethods) await refreshPaymentMethods()
  }

  return (
    <Card>
      <CardHeader
        graphic={<Glyph glyph="bank" />}
        actions={
          (!isDefault || canDelete) && (
            <>
              {!isDefault && (
                <Dialog
                  glyph="card"
                  title="Change Default Payment Method?"
                  message={
                    <div>
                      <div>Your new default payment method will be set to:</div>
                      <Flex nowrap gap={8} alignItems="center">
                        <div>
                          <b>
                            {bankAccount.bank_name} **** {bankAccount.last4}
                          </b>
                        </div>
                      </Flex>
                    </div>
                  }
                  yesColor="green"
                  yesLabel="Set as Default"
                  onYes={makeDefault}
                >
                  <DropdownItem label="Make Default" glyph="check" color="green" />
                </Dialog>
              )}

              {canDelete && (
                <Dialog
                  glyph="delete"
                  title="Delete Bank Account?"
                  message="Are you sure you want to delete this bank account? This action cannot be undone."
                  yesColor="red"
                  yesLabel="Yes, Delete Bank Account"
                  onYes={deletePaymentMethod}
                >
                  <DropdownItem label="Delete Bank Account" glyph="delete" color="red" />
                </Dialog>
              )}
            </>
          )
        }
      >
        <CardTitle
          title={`${bankAccount.bank_name} **** ${bankAccount.last4}`}
          after={isDefault && <Status label="Default" color="blue" />}
        />
        <CardSubtitle subtitle="USD" />
      </CardHeader>
    </Card>
  )
}

const StripePaymentMethods = ({ extraActions }: any) => {
  const [loading, setLoading] = React.useState(false)
  const payment_methods = useSelector((state: any) => state.data?.payment_methods?.data)

  const tenant = useStore((state: any) => state?.tenant)
  const defaultPaymentMethod = tenant?.stripe_default_payment_id
  const subscriptions = useStore((state) => state?.subscriptions)

  const isEmpty = size(payment_methods?.cards) === 0

  const isProcessing = loading || isEmpty
  const hasNoActiveSubscriptions = size(subscriptions.filter((sub: any) => sub?.status === 'active')) === 0
  const isLastPaymentMethod = size(payment_methods?.cards) === 1

  const getPaymentMethods = async () => {
    try {
      setLoading(true)

      await apiGet({
        notify: false,
        name: 'payment_methods',
        url: `/apps/stripe/payment_methods`,
      })

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actions = (
    <Flex gap={8}>
      <SummonOverlay overlay={<StripeAddCardOverlay onSuccess={getPaymentMethods} />}>
        <Button label="Add Payment Method" glyph="add" size={200} />
      </SummonOverlay>

      {extraActions?.({ isEmpty })}
    </Flex>
  )

  return (
    <PageSection>
      <PageSection.Header
        graphic={
          <div className="flex items-center justify-center w-6 h-6">
            <Icon icon="billing" size={20} />
          </div>
        }
        after={actions}
      >
        <PageSection.Title title="Payment Methods" />
      </PageSection.Header>

      <PageSection.Content>
        {isProcessing ? (
          <Card>
            <State
              title="Payment Methods"
              emptyDescription="No payment method added yet"
              isLoading={loading}
              isEmpty={isEmpty}
              minHeight={100}
            />
          </Card>
        ) : (
          <CardsGrid>
            {payment_methods?.cards.map((paymentMethod: any) => {
              if (paymentMethod.type === 'card') {
                return (
                  <PaymentMethodCard
                    card={paymentMethod.card}
                    paymentMethodID={paymentMethod.id}
                    defaultPaymentMethodID={defaultPaymentMethod}
                    refreshPaymentMethods={getPaymentMethods}
                    isLastPaymentMethod={isLastPaymentMethod}
                    hasNoActiveSubscriptions={hasNoActiveSubscriptions}
                  />
                )
              } else if (paymentMethod.type === 'us_bank_account') {
                return (
                  <PaymentMethodBankAccount
                    bankAccount={paymentMethod.us_bank_account}
                    paymentMethodID={paymentMethod.id}
                    defaultPaymentMethodID={defaultPaymentMethod}
                    refreshPaymentMethods={getPaymentMethods}
                    isLastPaymentMethod={isLastPaymentMethod}
                    hasNoActiveSubscriptions={hasNoActiveSubscriptions}
                  />
                )
              }
            })}
          </CardsGrid>
        )}
      </PageSection.Content>
    </PageSection>
  )
}

export default StripePaymentMethods
