import React from 'react'
import { DateTime } from 'luxon'
import clsx from 'clsx'

import { COLORS, HARD_SHADOW } from '../../../theme'

import CalendarEventsList from '../CalendarEventsList'

const MonthView = ({ week, days, currentDate, eventsByDate, renderEvent }: any) => {
  return (
    <div css={styles.root}>
      {week.map((day, index) => (
        <div key={`day-${index}`} css={styles.weekDay}>
          {day.toFormat('cccc')}
        </div>
      ))}

      {days.map((day, index) => (
        <MonthCell
          disableOutsideMonth
          key={`cell-${index}`}
          index={index}
          day={day}
          currentDate={currentDate}
          events={eventsByDate[day.toFormat('yyyy-MM-dd')]}
          renderEvent={renderEvent}
          css={styles.cell}
        />
      ))}
    </div>
  )
}

const MonthCell = ({ className, currentDate, day, disableOutsideMonth, events, renderEvent }: any) => {
  const today = DateTime.local()
  const isOutsideMonth = disableOutsideMonth && day.month !== currentDate.month
  const isToday = day.hasSame(today, 'day') && day.hasSame(today, 'month') && day.hasSame(today, 'year')

  const classNames = clsx({
    'is-outside-month': isOutsideMonth,
    [className]: className,
  })

  return (
    <div css={cellStyles.root} className={classNames}>
      <div css={cellStyles.header}>
        <div css={cellStyles.date} className={isToday ? 'is-today' : ''}>
          {day.toFormat('dd')}
        </div>
        <div css={cellStyles.weekDay}>{day.weekdayShort}</div>
      </div>

      <CalendarEventsList events={events} renderEvent={renderEvent} css={cellStyles.list} />
    </div>
  )
}

const styles = {
  root: {
    overflowY: 'visible',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',

    '@media (min-width: 1024px)': {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
    },
  },

  weekDay: {
    fontWeight: 600,
    padding: '0.5rem',
    textAlign: 'center',
    boxShadow: `1px 0 0 ${COLORS.divider}, ${HARD_SHADOW(2)}`,
    borderBottom: `1px solid ${COLORS.divider}`,

    // hide week days on mobile
    '@media (max-width: 1023px)': {
      display: 'none',
    },
  },

  cell: {
    '@media (min-width: 1024px)': {
      minHeight: 160,
    },

    // hide outside-month cells in responsive vertical-list view
    '&.is-outside-month': {
      '@media (max-width: 1023px)': {
        display: 'none',
      },
    },
  },
}

const cellStyles = {
  root: {
    padding: '.5rem',
    boxShadow: `1px 1px 0 0 ${COLORS.divider}`,
    // display: 'grid',
    // gridTemplateColumns: '3rem 1fr',
    minWidth: 160,

    '&.is-outside-month': {
      // lower opacity on children so the parent border keeps correct color
      // '& > *': {
      //   opacity: 0.3,
      // },
    },

    '&.is-cursor': {
      background: COLORS.lightGray,
    },

    '@media (min-width: 1024px)': {
      // gridTemplateColumns: '2rem 1fr',
    },
  },

  header: {
    // textAlign: 'center',
    // paddingRight: '0.5rem',
  },

  date: {
    display: 'inline-block',
    fontSize: '1.1rem',
    fontWeight: 600,

    '@media (min-width: 1024px)': {
      fontSize: '0.9rem',
    },

    '&.is-today': {
      fontSize: '1rem',
      color: COLORS.white,
      position: 'relative',
      zIndex: 0,
      textAlign: 'center',
      marginBottom: '0.25rem',

      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
        background: COLORS.red,
        width: '1.75rem',
        height: '1.75rem',
        zIndex: -1,
        borderRadius: '50%',
      },
    },
  },

  weekDay: {
    fontSize: '0.88rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: 500,
    color: COLORS.textMuted,

    '@media (min-width: 1024px)': {
      display: 'none',
    },
  },

  list: {
    padding: 0,

    '@media (min-width: 1024px)': {
      paddingTop: '0.5rem',
    },
  },
}

export default MonthView
