import React from 'react'

import Checkbox from '../../../../components/Forms/Checkbox'

export const AccordionEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate } = props

  const { is_default_open } = activeElement.config

  return (
    <>
      <Checkbox
        label="Default Open"
        model="is_default_open"
        defaultValue={true}
        onUpdate={onUpdate}
        // value={is_default_open}
      />
    </>
  )
}
