import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'

import { css, COLORS, SHADOW } from '../../theme'
import { DT } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import Link from '../Link'

import { RoadmapContext } from './context'

const RoadmapGraphic = React.forwardRef((props: any, ref: any) => {
  const {
    asGradient,
    background = tint(0.7, COLORS.blue),
    children,
    className,
    graphic,
    onBlur,
    onClick,
    onFocus,
    onPointerEnter,
    onPointerLeave,
    onTouchStart,
    shape = 'circle',
    size = '14px',
    startDate,
    to,
  } = props

  const Tag = !!to ? Link : 'div'

  const { timezone } = useSettings()
  const context: any = React.useContext(RoadmapContext)
  const { period, minutesInView }: any = context

  const roadmapStartDate = context.startDate
  const roadmapEndDate = context.endDate.endOf('day')

  const rootClasses = clsx(STYLES.root().className, `shape-${shape}`, className)

  let startDT = startDate && DT(startDate, timezone)

  // event starts after roadmap end date
  if (startDT && startDT.toSeconds() > roadmapEndDate.toSeconds()) return null

  let durationFromStart = startDT ? startDT.diff(roadmapStartDate, 'minutes').toObject().minutes : 0

  const durationBasedOffset = (durationFromStart / minutesInView) * 100 + '%'

  const offset = props.offset || durationBasedOffset || 0

  const style = {
    left: offset,
    width: size,
    height: size,
    background: asGradient ? `linear-gradient(90deg, ${background}, transparent)` : background,
    border: asGradient ? 'none' : '1px solid white',
  }

  return (
    <Tag
      ref={ref}
      className={rootClasses}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onTouchStart={onTouchStart}
      style={style}
      to={to}
    >
      {graphic}
      {children}
    </Tag>
  )
})

const STYLES = {
  root: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 1,
    marginRight: 1,
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    transformOrigin: 'left',
    transform: 'scale(1) translateX(-50%) translateY(-50%)',
    transition: 'transform 140ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    height: '80%',
    maxHeight: 32,
    boxShadow: `inset 0 0 0 1px ${COLORS.divider}, ${SHADOW(1)}`,
    cursor: 'pointer',

    '&:hover': {
      transform: 'scale(1.2) translateX(-50%) translateY(-50%)',
    },

    '&.shape-circle': {
      borderRadius: '50%',
    },

    '&.shape-square': {
      borderRadius: '3px',
    },
  }),
}

export default RoadmapGraphic
