import React from 'react'
import { Navigate, NavLink, Route, Routes } from 'react-router-dom-v5-compat'

import { withPageError } from '@behavehealth/hocs/withPageError'

import { PageHeader, Tabs, Tab, TabList } from '@behavehealth/components'

import { Admissions } from './admissions'
import { CurrentClients } from './current_clients'
import { PastClients } from './past_clients'

const ClientsJourney = () => {
  const [dates, setDates]: any = React.useState({})

  return (
    <div css={styles.root}>
      <PageHeader showFeedbackTool icon="clients_journey" title="Clients Journey" css={styles.header} />

      <Tabs css={styles.tabs}>
        <TabList css={styles.tabList}>
          <Tab as={NavLink} label="Admissions" icon="admissions" to="admissions" />
          <Tab as={NavLink} label="Current Clients" icon="clients" to="current-clients" />
          <Tab as={NavLink} label="Past Clients" icon="alumni_relations" to="past-clients" />
        </TabList>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to="admissions" replace />} />

        <Route path="admissions" element={<Admissions dates={dates} setDates={setDates} />} />
        <Route path="current-clients" element={<CurrentClients dates={dates} setDates={setDates} />} />
        <Route path="past-clients" element={<PastClients dates={dates} setDates={setDates} />} />
      </Routes>
    </div>
  )
}

const styles = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
  },

  header: {
    zIndex: 1,
    position: 'relative',
  },

  tabs: {
    zIndex: 2,
  },

  tabList: {
    paddingLeft: '1rem',
  },
}

export default withPageError(ClientsJourney)
