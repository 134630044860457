import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import Button from '../../components/Button'
import Grid from '../../components/Grid'
import { Text } from '../../components/Typography'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import Textarea from '../../components/Forms/Textarea'
import FormSection from '../../components/Forms/FormSection'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import IntakeStatus from '../../components/Statuses/IntakeStatus'
import NoInternet from '../../components/Alerts/NoInternet'

import withSettings from '../../hocs/withSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { currentAdmission } from '../../reducers/selectors'
import { apiGet, apiUpdate } from '../../modules/api'

const RootIntakeClose = ({ location, history, online }) => {
  const data = useSelector(currentAdmission)
  const loading = useSelector((state) => state.data.admissions?.loading)
  const [valid, setValid] = React.useState(false)
  const form = React.useRef()

  if (!data) return null

  const close = () => {
    if (location.parent) {
      if (location.parent.url) history.push(location.parent.url)
      else history.push(location.parent)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  const closeEpisode = async () => {
    await apiUpdate({
      name: 'admissions',
      url: `/admissions/${data.id}/close`,
      params: form.current.getFormValue(),
    })
    apiGet({ name: 'clients', url: `/residents/${data.customer.id}` })
    close()
  }

  return (
    <Overlay onClose={close} position="center" maxWidth={32} showBackdrop>
      <Overlay.Header glyph="decline" title="Close Episode" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={data}
          onValidationUpdate={setValid}
          linked={{
            status: 'closed',
          }}
        >
          <Section>
            <FormSection layout="vertical">
              <Textarea useQuickText model="notes" label="Additional Notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Upon Close…">
            <Grid gap={16} style={{ marginBottom: '2rem' }}>
              <Text
                glyph="check"
                layout="vertical"
                description={
                  <Fragment>
                    <span style={{ marginRight: '0.5rem' }}>The client's status won't change</span>
                  </Fragment>
                }
                setIconColor={false}
              />
              <Text
                glyph="check"
                layout="vertical"
                description={
                  <Fragment>
                    <span style={{ marginRight: '0.5rem' }}>
                      This intake will be set to <IntakeStatus status="closed" small inline /> which means that no further action can be
                      taken on it
                    </span>
                  </Fragment>
                }
                setIconColor={false}
              />
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {online && (
          <Button
            label="Close Intake"
            glyph="discharge"
            type="primary"
            color="red"
            isLoading={loading}
            onClick={closeEpisode}
            isDisabled={!valid}
          />
        )}
        {!online && <NoInternet />}
      </Overlay.Footer>
    </Overlay>
  )
}

export const IntakeClose = withOverlayError(withSettings(RootIntakeClose))
