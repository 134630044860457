import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Button from '../../components/Button'
import Divider from '../../components/Divider'
import EmailInput from '../../components/Forms/EmailInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import StateSelect from '../../components/Forms/elements/StateSelect'
import Textarea from '../../components/Forms/Textarea'
import TimezoneInput from '../../components/Forms/TimezoneInput'
import URLInput from '../../components/Forms/URLInput'

const RootCommunityMemberSuggestionOverlay = (props: any) => {
  const { close, data, form, isEditable, isInvalid, initialModel, isOverlayLoading, isSaving, onValidationUpdate, refetch, save } =
    useOverlay({
      name: 'community_accounts',
      endpoint: '/community/entities',
      invalidate: 'community_accounts',
      options: props,
    })

  if (isOverlayLoading) return <OverlayLoader position="right" maxWidth={36} />

  return (
    <Overlay onClose={close} position="right" maxWidth={36}>
      <Overlay.Header title="Suggest Community Member" icon="community" />

      <Overlay.Content>
        <Form
          isEditable={isEditable}
          getForm={form}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
          linked={{
            entity_type: 'organization',
            business_status: 'inactive',
            status: 'unclaimed',
            show_status: 'hide',
          }}
        >
          <Section title="Organization Details">
            <FormSection maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter an organization name',
                  },
                }}
              />

              <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
                <EmailInput label="Email" model="email" />
                <PhoneInput label="Phone Number" model="phone_no" />
              </div>

              {/* <AddressSelectorInput label="Address" model="primary_location" /> */}

              <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
                <StateSelect label="Primary State" model="primary_state" />
                <TimezoneInput allowEmpty label="Timezone" model="timezone" />
              </div>

              <Textarea label="Notes" model="internal_notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section title="Web Presence">
            <FormSection maxWidth="100%">
              <URLInput label="Website URL" model="url" />

              <Grid vertical gap="1rem" columns="1fr 1fr">
                <URLInput glyph="facebook" label="Facebook" model="facebook_url" />
                <URLInput glyph="instagram" label="Instagram" model="instagram_url" />
              </Grid>

              <Grid vertical gap="1rem" columns="1fr 1fr">
                <URLInput glyph="linkedin" label="LinkedIn" model="linkedin_url" />
                <URLInput glyph="twitter" label="Twitter" model="twitter_url" />
              </Grid>

              <Grid vertical gap="1rem" columns="1fr 1fr">
                <URLInput glyph="googleplus" label="Google" model="google_url" />
                <URLInput glyph="youtube" label="YouTube" model="youtube_url" />
              </Grid>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Send Suggestion"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const CommunityMemberSuggestionOverlay = withOverlayError(RootCommunityMemberSuggestionOverlay)
