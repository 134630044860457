import React from 'react'
import clsx from 'clsx'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import { COLORS } from '../../theme'

import { AppSidebarContext } from './context'

export const AppSidebarView: React.FC<any> = ({ children, className }) => {
  const scrollRef = React.useRef(null)
  const { isFullScreen, toggleFullScreen }: any = React.useContext(AppSidebarContext)

  const classNames = clsx(isFullScreen && 'is-full-screen', className)

  React.useEffect(() => {
    if (!scrollRef?.current) return

    disableBodyScroll(scrollRef.current)

    return clearAllBodyScrollLocks
  }, [])

  return (
    <>
      <div ref={scrollRef} css={styles.root} className={classNames}>
        {children}
      </div>

      {isFullScreen && <div css={styles.backdrop} onClick={toggleFullScreen} />}
    </>
  )
}

const SIDE_SHADOW = `
  -.5px 0 0 ${COLORS.divider},
  -2px 0 6px ${COLORS.shadow},
  -4px 0 12px ${COLORS.shadow},
  -8px 0 24px ${COLORS.shadow}
`

const HEADER_SHADOW = `
  0 0.5px 0 ${COLORS.divider},
  0 2px 6px ${COLORS.shadow},
  0 4px 12px ${COLORS.shadow},
  0 8px 24px ${COLORS.shadow}
`

const styles = {
  root: {
    boxShadow: SIDE_SHADOW,
    background: '#FCFCFD',
    overflowY: 'auto',
    position: 'relative',
    zIndex: 2,

    '@media (min-width: 800px)': {
      '&.is-full-screen': {
        borderRadius: 7,
        position: 'fixed',
        top: 20,
        bottom: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        maxWidth: 900,
        zIndex: 101,

        'main.notion-page': {
          width: '100%',
        },
      },
    },
  },

  backdrop: {
    display: 'none',

    '@media (min-width: 800px)': {
      display: 'block',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: COLORS.backdrop,
      zIndex: 100,
    },
  },
}
