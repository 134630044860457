import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'blue',
  },
  pending_review: {
    label: 'Pending Review',
    color: 'orange',
  },
  submitted: {
    label: 'Submitted',
    color: 'orange',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'red',
  },
  issue: {
    label: 'Issue',
    color: 'red',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
