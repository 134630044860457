import React from 'react'

import withSettings from '../../hocs/withSettings'
import { usDate } from '../../utils/functions'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

import InsurancePaymentStatus from '../Statuses/InsurancePaymentStatus'

const PAYMENT_METHODS = {
  check: 'Check',
  ach: 'Bank Transfer (ACH)',
  other: 'Other',
}

const columns = (to: Function = () => {}, onClick: any, timezone: string) => [
  {
    width: 140,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'dated_at',
    Header: 'Payment Date',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink to={to(row.original.id)} onClick={() => onClick?.(row.original)} label={usDate(value, timezone)} />
    ),
  },
  {
    width: 120,
    accessor: 'category',
    Header: 'Payment Type',
    Cell: ({ value }: any) => <InsurancePaymentStatus status={value} />,
  },
  {
    width: 120,
    accessor: 'insurance_claim.control_number',
    Header: 'Claim ID',
    Cell: ({ value }: any) => `#${value}`,
  },
  {
    width: 160,
    accessor: 'payment_method',
    Header: 'Payment Method',
    Cell: ({ value }: any) => PAYMENT_METHODS[value] || '–',
  },
  {
    width: 100,
    accessor: 'reference',
    Header: 'Reference',
  },
  {
    width: 100,
    accessor: 'allowed_amount',
    Header: 'Allowed',
    Cell: TableCell.Amount,
  },
  {
    width: 100,
    accessor: 'paid_amount',
    Header: 'Paid',
    Cell: TableCell.Amount,
  },
  {
    width: 130,
    id: 'client_responsibility',
    Header: 'Client Respons.',
    Cell: ({ row }: any) => {
      const deductible_amount = parseFloat(row.original.deductible_amount)
      const copay_amount = parseFloat(row.original.copay_amount)
      const coinsurance_amount = parseFloat(row.original.coinsurance_amount)
      const not_payable_amount = parseFloat(row.original.not_payable_amount)
      const total = deductible_amount + copay_amount + coinsurance_amount + not_payable_amount

      return <TableCell.Amount value={total} />
    },
  },
  {
    width: 130,
    accessor: 'adjustment_amount',
    Header: 'Adjustment',
    Cell: TableCell.Amount,
  },
  {
    width: 160,
    accessor: 'remaining_amount',
    Header: 'Balance Due Client',
    Cell: TableCell.Amount,
  },
  {
    width: 160,
    accessor: 'created_by',
    Header: 'Created By',
  },
  {
    width: 160,
    accessor: 'last_edited_by',
    Header: 'Last Edited By',
  },
]

const ClaimPaymentsTable: React.FC<any> = (props) => {
  const {
    emptyDescription = 'No claim payments added yet',
    icon = 'financials',
    localStorageKey = 'claim_payments',
    onClick,
    timezone,
    title = 'Claim Payments',
    to,
    data,
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      columns={columns(to, onClick, timezone)}
      emptyDescription={emptyDescription}
      localStorageKey={localStorageKey}
      data={data}
      {...rest}
    />
  )
}

export default withSettings(ClaimPaymentsTable)
