import React from 'react'
import { useStore as useZustandStore } from 'zustand'

import { DataTableContext } from './context'

export const useStore = (selector: any, equalityFn?: any) => {
  const store: any = React.useContext(DataTableContext)

  if (!store) throw new Error('Missing DataTableContext.Provider in the tree')

  return useZustandStore(store, selector, equalityFn)
}
