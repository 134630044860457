import React from 'react'
import { useAsyncDebounce } from 'react-table'
import snakeCase from 'lodash/snakeCase'

import ClearButton from '../../Forms/ClearButton'
import Input from '../common/Input'

type Props = { column: any }

const HumanizedTextFilter = (props: Props) => {
  const {
    column: { filterValue, setFilter },
  } = props

  const [value, setValue] = React.useState(filterValue)

  const onChange = useAsyncDebounce((value) => {
    setFilter(snakeCase(value) || undefined)
  }, 200)

  const clear = () => {
    setFilter(undefined)
    setValue(undefined)
  }

  // clear input value when the filter is reset
  React.useEffect(() => {
    if (filterValue === undefined) {
      setValue(undefined)
    }
  }, [filterValue])

  return (
    <div className="!relative">
      <Input
        value={value || ''}
        onChange={(event: any) => {
          setValue(event.target.value)
          onChange(event.target.value)
        }}
      />
      <ClearButton isVisible={!!filterValue} onClick={clear} />
    </div>
  )
}

export default HumanizedTextFilter
