import React from 'react'

import withSettings from '../../hocs/withSettings'

import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Table from '../../components/Table/Table'
import TableCell from '../../components/Table/TableCell'

const columns = (to: any, mainLinkAs: any) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink
        as={mainLinkAs}
        to={to && to(row.original.id)}
        label={
          <Flex centerY gap="0.5rem">
            <Glyph glyph={row.original.glyph} color={row.original.color} size={20} />
            <div>{value}</div>
          </Flex>
        }
        avatar={row.original.avatar}
      />
    ),
  },
  {
    width: 200,
    accessor: 'description',
    Header: 'Description',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const RootTagsTable = (props: Props) => {
  const { data, emptyActions, emptyDescription, icon, isLoading, localStorageKey, batchActionsConfig, title, to, titleAfter, testKey } =
    props

  return (
    <Table
      testKey={testKey}
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, props.mainLinkAs)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

RootTagsTable.defaultProps = {
  title: 'Tags',
  icon: 'tags',
  emptyDescription: 'No tags added to this group yet',
  localStorageKey: 'tags',
}

export const TagsTable = withSettings(RootTagsTable)
