import React from 'react'
import { v4 as uuid } from 'uuid'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { COLORS } from '../../theme'
import { INPUT_STYLES } from '../../theme'
import { isDefined } from '../../utils/functions'

import { validate } from './validators'
import { withFormContext } from './context'
import Glyph from '../Glyph'
import Dropdown from '../Dropdown'

import FieldBase from './FieldBase'

export class ColorSelector extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    const vs = { ...props.defaultValidations, ...props.validations }
    let value = props.value
    if (!value) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

      if (props.forceEmptyValue) value = ''
      else if (isDefined(modelVal)) value = modelVal
      else if (isDefined(initialModelVal)) value = initialModelVal
      else if (isDefined(props.defaultValue)) value = props.defaultValue
      else value = ''
    }

    if (vs) errors = validate(value, vs)

    this.state = {
      type: 'COLOR_SELECTOR',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value,
      isNested: props.isNested || false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isBlur: false,
      isValidations: vs,
      isRequired: vs?.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }
    this.updateType = 'DATA'
  }

  editRender = () => {
    const { value }: any = this.state
    const { isSimplified, colors = PICKER_COLORS }: any = this.props

    return (
      <Dropdown
        trigger={
          <div css={styles.input} style={{ '--selected-color': value }} className={isSimplified ? 'is-simplified' : ''}>
            <div css={styles.inputSwatch} className="input-swatch" />
            <Glyph glyph="triangle_down" size={12} css={styles.triangle} />
          </div>
        }
      >
        <div css={styles.menuGrid}>
          {colors.map((color) => (
            <div data-test={`${color}_color_menu_item`} key={color} css={styles.menuItem} onClick={() => this.changeValue(color)}>
              <div css={styles.menuSwatch} style={{ '--swatch-color': color }} className={color === value ? 'is-active' : ''} />
            </div>
          ))}
        </div>
      </Dropdown>
    )
  }

  readOnlyRender = () => {
    const { value }: any = this.state

    if (!value) return DEFAULT_EMPTY_VALUE

    return <div css={styles.inputSwatch} style={{ '--selected-color': value }} />
  }
}

export const PICKER_COLORS = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#336BFD',
  '#03a9f4',
  '#00ddf9',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#274879',
  '#222222',
  '#7f8c8d',
  '#bdc3c7',
  '#ecf0f1',
]

const styles = {
  input: {
    ...INPUT_STYLES,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&.is-simplified': {
      width: 'auto',
      padding: 0,
      border: 'none',
      boxShadow: 'none',
      background: 'none',
      minHeight: 'auto',

      svg: { display: 'none' },
      '.input-swatch': { margin: 0 },
    },
  },

  triangle: {
    marginLeft: 'auto',
    pointerEvents: 'none',
  },

  menuGrid: {
    display: 'grid',
    gridAutoRows: '50px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(65px, 1fr))',
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `1px 1px 0 ${COLORS.divider}`,
    padding: '1rem',
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.hover,
    },
  },

  inputSwatch: {
    display: 'inline-block',
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: 'none',
    boxShadow: `inset 0 0 0 1px rgba(12, 62, 106, 0.15)`,
    background: 'var(--selected-color)',
    marginRight: '0.75rem',
  },

  menuSwatch: {
    display: 'inline-block',
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: 'none',
    boxShadow: `inset 0 0 0 1px rgba(12, 62, 106, 0.15)`,
    background: 'var(--swatch-color)',

    '&.is-active': {
      boxShadow: `
        0 0 0 2px ${COLORS.white},
        0 0 0 4px var(--swatch-color)
      `,
    },
  },
}

ColorSelector.defaultProps = {
  isEditable: true,
  isDisabled: false,
  defaultValidations: null,
}

export default withFormContext(ColorSelector)
