import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'

const RootCommunityInviteTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'partnership-invite-template',
    endpoint: `/partnership_invite_templates`,
    invalidate: 'partnership-invite-templates',
    options: props,
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) return <OverlayLoader position="right" />

  return (
    <Overlay showBackdrop={isEditable} closeOnBackdrop position="right" onClose={close}>
      <Overlay.Header title="Community Invite Template" icon="community_partners" />

      <Overlay.Content>
        <Form
          isEditable={isEditable}
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <Grid gap="0.75rem">
              <Input
                label="Template Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name for this template',
                  },
                }}
              />

              <Input
                label="Subject"
                model="title"
                validations={{
                  presence: {
                    message: 'Please enter a subject line for this template',
                  },
                }}
              />

              <Textarea
                label="Message"
                model="message"
                validations={{
                  presence: {
                    message: 'Please enter a message for this template',
                  },
                }}
              />
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Invite Template"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="settings.community_invite_templates.edit"
            />

            <DeleteDialog
              title="Delete Invite Template?"
              message="Are you sure you want to delete this Invite Template? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="settings.community_invite_templates.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const CommunityInviteTemplateOverlay = withOverlayError(RootCommunityInviteTemplateOverlay)
