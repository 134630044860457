import React from 'react'
import clsx from 'clsx'

import BHZoomInput from '../../../../components/Forms/ZoomInput'

import { getFormElementProps } from '../../utils/functions'

export const ZoomInput: React.FC<any> = (props) => {
  const { children, element, className, hoverElement, useParsedConfig, environment } = props

  if (!element) return null

  const rootClasses = clsx('ZOOM_INPUT', className)
  const inputProps = getFormElementProps(element, { useParsedConfig, environment })

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}
      <BHZoomInput {...inputProps} />
    </div>
  )
}
