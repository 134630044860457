import React from 'react'
import { NavLink } from 'react-router-dom'
import { transparentize } from 'polished'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../theme'
import Icon from './Icon'
import Glyph from './Glyph'

import withTabsContext from '../hocs/withTabsContext'
import withPermissions from '../hocs/withPermissions'

const PillTab = withTabsContext((props: any) => {
	const {
		after,
		className,
		currentTab,
		disableForwardNav,
		glyph,
		icon,
		isDisabled,
		label,
		name,
		onSelect,
		onSelected,
		registerTab,
		tabsList,
		to,
		testKey,
		onClick
	} = props

	const [disabled, setDisabled] = React.useState(isDisabled)

	const classNames = clsx(className, {
		'is-active': currentTab && name && currentTab === name,
		'is-disabled': disabled
	})

	React.useEffect(() => {
		registerTab?.(name)
	}, [])

	React.useEffect(() => {
		if (disableForwardNav) {
			const tabIndex = tabsList.indexOf(name)
			const activeTabIndex = tabsList.indexOf(currentTab)

			setDisabled(isDisabled || tabIndex > activeTabIndex)
		} else {
			setDisabled(isDisabled)
		}
	}, [isDisabled, name, currentTab, tabsList])

	if (to) {
		const isLinkActive = (_, { search, pathname }) => {
			let isActive = false

			if (typeof to === 'string' && !search) {
				isActive = pathname.includes(to) || pathname.includes(to.pathname)
			} else if (to.hasOwnProperty('search')) {
				if (to.search === null) isActive = search === ''
				else isActive = search === to.search
			}

			return isActive
		}

		const Tag = props.as || NavLink

		return (
			<Tag
				data-test={testKey}
				css={styles}
				activeClassName="is-active"
				isActive={isLinkActive}
				to={!disabled && to}
				className={classNames}
				onClick={onClick}
			>
				{icon && <Icon icon={icon} size={18} css={styles.graphic} />}
				{glyph && <Glyph glyph={glyph} size={18} css={styles.graphic} />}
				{label}
				{after}
			</Tag>
		)
	}

	const handleClick = () => {
		if (disabled) return

		if (onSelected) onSelected()
		if (onSelect) onSelect(name)
	}

	return (
		<button data-test={testKey} type="button" css={styles} className={classNames} onClick={handleClick}>
			{icon && <Icon icon={icon} size={18} css={styles.graphic} />}
			{glyph && <Glyph glyph={glyph} size={18} css={styles.graphic} />}
			{label}
		</button>
	)
})

const styles = {
	position: 'relative',
	marginRight: '0.5rem',
	padding: '0.35rem 0.5rem',

	fontSize: '0.95rem',
	fontWeight: 500,
	color: COLORS.blue,

	border: '1px solid transparent',
	background: 'none',
	outline: 'none',
	cursor: 'pointer',

	svg: {
		fill: COLORS.blue
	},

	'.caps &': {
		fontWeight: 600,
		fontSize: '0.9rem',
		textTransform: 'uppercase',
		letterSpacing: 0.5
	},

	'&:hover': {
		textDecoration: 'none !important'
	},

	'&.is-active, &.active': {
		color: COLORS.text,

		svg: {
			fill: COLORS.text
		}
	},

	'&.is-disabled': {
		// cursor: 'default',
		color: COLORS.textMuted,
		opacity: 0.5,
		cursor: 'not-allowed',

		svg: {
			fill: COLORS.textMuted
		}
	},

	'&.is-active': {
		background: transparentize(0.95, COLORS.text),
		borderRadius: 5
	},

	graphic: {
		marginRight: '0.5rem'
	}
}

export default withPermissions(PillTab)
