import React from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { mapToArray } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Button from '@behavehealth/components/Button'
import Divider from '@behavehealth/components/Divider'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

const CalendarPageActions: React.FC<any> = ({ data }) => {
  const match = useRouteMatch()
  const location = useLocation()

  const { data: templates }: any = useGet({
    name: 'event-templates',
    url: `/event_templates`,
    params: { event_type: 'event' },
  })

  return (
    <>
      <Button label="Event Note Audit" glyph="calendar" type="default" link="/reports/event-documentations" permission="events.view" />

      <Button
        label="Manage Event Templates"
        glyph="settings"
        type="default"
        link="/settings/calendar-templates"
        permission="calendar_templates.view"
      />

      <Dropdown label="Add Event…" glyph="add" buttonType="primary" buttonSize={300} permission="events.create">
        <DropdownItem
          label="New Event"
          glyph="add"
          color="paleBlue"
          link={{
            pathname: `${location.pathname}/event-builder`,
            data: data,
          }}
          permission="events.create"
        />

        <Divider />

        {mapToArray(templates).map((template) => {
          const { id, ...templateKeys } = template

          return (
            <DropdownItem
              key={template.id}
              label={template.title}
              icon="calendar"
              color="paleBlue"
              link={{
                pathname: `${location.pathname}/event-builder`,
                data: {
                  ...templateKeys,
                  ...data,
                  template_id: template.id,
                },
              }}
              permission="events.create"
            />
          )
        })}
      </Dropdown>
    </>
  )
}

export default CalendarPageActions
