import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../theme'

import { useStore } from './useStore'

export const DataTableHeader = (props: any) => {
  const { children, className } = props

  const canBatchSelect: any = useStore((state: any) => state.canBatchSelect)

  const rootClasses = clsx('DataTableHeader', canBatchSelect && 'can-batch-select', className)

  return (
    <div className={rootClasses} css={STYLES.root}>
      {canBatchSelect && <div css={STYLES.emptyCell}></div>}
      <div css={STYLES.content}>{children}</div>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    minHeight: 'var(--row-min-height)',
    minWidth: 'fit-content',
    position: 'sticky',
    top: 0,
    zIndex: 5,
    whiteSpace: 'nowrap',

    '.DataTableCell': {
      fontWeight: 600,
    },
  },

  content: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(120px, 1fr)',
    gridTemplateColumns: 'var(--grid-template-columns)',
    flex: '1 1 auto',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  emptyCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'var(--checkbox-width)',
    alignSelf: 'stretch',
    paddingLeft: 'var(--padding-x)',
    paddingRight: 'var(--padding-x)',
    flex: '0 0 auto',
    position: 'sticky',
    left: 0,
    background: COLORS.white,
    zIndex: 1,
  },
}
