import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS } from '../../theme'

import { useStore } from './utils/useStore'
import { formatCellCoords } from './utils/functions'
import { ROW_MIN_HEIGHT } from './utils/constants'
import Glyph from '../Glyph'

import { WorksheetCell } from './WorksheetCell'
import { WorksheetHeaderCell } from './WorksheetHeaderCell'
import { WorksheetHeaderRow } from './WorksheetHeaderRow'
import { WorksheetRow } from './WorksheetRow'

export const WorksheetMain = ({ isEditable }) => {
  const allowCreate: any = useStore((state: any) => state.allowCreate)
  const allowDelete: any = useStore((state: any) => state.allowDelete)
  const columnsMap: any = useStore((state: any) => state.columnsMap)
  const dataMap: any = useStore((state: any) => state.dataMap)
  const columnIds: any = useStore((state: any) => state.columnIds)
  const wrapping: any = useStore((state: any) => state.wrapping)
  const dataIds: any = useStore((state: any) => state.dataIds)
  const deleteRowByIndex: any = useStore((state: any) => state.deleteRowByIndex)

  if (!dataMap || !dataIds || !columnsMap || !columnIds) return null

  const rootClasses = clsx(wrapping && `wrapping-${wrapping}`)

  return (
    <main className={rootClasses} css={STYLES.root}>
      <WorksheetHeaderRow>
        {/* row number empty cell */}
        <WorksheetHeaderCell css={STYLES.countCell} />

        {columnIds.map((columnId: any, columnIndex: number) => {
          const column = columnsMap[columnId]

          return (
            <WorksheetHeaderCell key={column.id} columnIndex={columnIndex} column={columnsMap[columnId]}>
              {column.title}
            </WorksheetHeaderCell>
          )
        })}

        {isEditable && allowCreate && <WorksheetHeaderCell css={STYLES.deleteEmptyCell} />}
      </WorksheetHeaderRow>

      {dataIds.map((rowId: any, rowIndex: number) => {
        return (
          <WorksheetRow key={rowId} rowId={rowId}>
            <WorksheetHeaderCell css={STYLES.countCell}>{rowIndex + 1}</WorksheetHeaderCell>

            {columnIds.map((columnId: any, columnIndex: number) => {
              const cellCoords = formatCellCoords(columnIndex, rowIndex)

              return (
                <WorksheetCell
                  key={cellCoords}
                  isEditable={isEditable}
                  column={columnsMap[columnId]}
                  rowData={dataMap[rowId]}
                  coords={cellCoords}
                />
              )
            })}

            {isEditable && allowDelete && size(dataIds) > 1 && (
              <div
                data-test="delete_row_button"
                css={STYLES.deleteAction}
                onClick={() => {
                  deleteRowByIndex(rowIndex)
                }}
              >
                <Glyph glyph="delete" size={12} />
              </div>
            )}
          </WorksheetRow>
        )
      })}
    </main>
  )
}

const STYLES = {
  root: {
    overflow: 'auto',
    // minHeight: 100,

    '&.wrapping-wrap': {},

    '&.wrapping-clip': {
      display: 'grid',
      gridAutoRows: ROW_MIN_HEIGHT,
    },
  },

  countCell: {
    fontWeight: 600,
    textAlign: 'right',
    justifyContent: 'flex-end',
    paddingRight: '0.4rem',
    background: '#f7f8fa',
    position: 'sticky',
    left: 0,
    zIndex: 1,
  },

  deleteAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'sticky',
    right: 0,
    background: COLORS.white,
    boxShadow: `-1px 0 0 0 #e0e5f1`,

    svg: { transition: 'transform 220ms cubic-bezier(0.175, 0.885, 0.32, 1.275)' },

    '&:hover': {
      background: tint(0.92, COLORS.red),

      svg: { transform: 'scale3d(1.2, 1.2, 1.2)' },
    },

    // position: 'absolute',
    // top: 0,
    // bottom: 0,
    // left: 0,
    // right: 0,
  },

  deleteEmptyCell: {
    position: 'sticky',
    right: 0,
    background: COLORS.white,
    boxShadow: `-1px 0 0 0 #e0e5f1`,
  },
}
