import React from 'react'
import get from 'lodash/get'
import { withFormContext } from '@behavehealth/components/Forms/context'

import Attachments from '@behavehealth/components/Forms/Attachments'
import Button from '@behavehealth/components/Button'
import Checkbox from '@behavehealth/components/Forms/Checkbox'
import CheckboxGroup from '@behavehealth/components/Forms/CheckboxGroup'
import Flex from '@behavehealth/components/Flex'
import FormSection from '@behavehealth/components/Forms/FormSection'
import Grid from '@behavehealth/components/Grid'
import Input from '@behavehealth/components/Forms/Input'
import State from '@behavehealth/components/State'
import Textarea from '@behavehealth/components/Forms/Textarea'
import { Heading } from '@behavehealth/components/Typography'

const H2 = ({ children }) => {
  return (
    <Heading is="h2" className="!m-0">
      {children}
    </Heading>
  )
}

class Properties extends React.Component {
  constructor(props) {
    super(props)

    const modelVal = props.form?.getField(props.model)
    const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

    this.state = {
      properties: modelVal || initialModelVal || [],
    }
  }

  /*
    CUSTOM
  */
  addProperty = () => {
    this.setState({
      properties: [
        ...this.state.properties,
        {
          isOffice: null,
          isResidential: null,
          name: '',
          description: '',
          address: {
            line1: '',
            line2: '',
            city: '',
            county: '',
            state: '',
            zipCode: '',
          },
          providerNpi: '',
          shouldCheckBackground: null,
        },
      ],
    })
  }

  removeProperty = (idx) => {
    let properties = this.state.properties
    properties.splice(idx, 1)
    this.setState({ properties: properties })
  }

  renderTrigger = () => {
    return (
      this.props.isEditable && (
        <div className="!mt-4">
          <Button glyph="add" label="Add Property" onClick={this.addProperty} />
        </div>
      )
    )
  }

  /*
    RENDER
  */
  render() {
    const { properties } = this.state
    const isEmpty = properties.length === 0

    return (
      <State isEmpty={isEmpty} emptyDescription="No properties" emptyActions={this.renderTrigger()}>
        <FormSection layout="vertical" maxWidth={600}>
          {properties.map((_property, idx) => (
            <Grid gap={16} key={idx}>
              <Flex wrap justifyContent="space-between" alignItems="center">
                <H2>Location #{idx + 1}</H2>
                {this.props.isEditable && (
                  <Button
                    //label="Delete"
                    glyph="delete"
                    type="minimal"
                    color="red"
                    size={300}
                    onClick={() => this.removeProperty(idx)}
                  />
                )}
              </Flex>

              <CheckboxGroup label="Location Type" layout="horizontal">
                <Checkbox label="Treatment Office" model={`properties[${idx}].isOffice`} />
                <Checkbox label="Housing" model={`properties[${idx}].isResidential`} />
              </CheckboxGroup>

              <Input label="Location Public Profile Name" model={`properties[${idx}].name`} />
              <Textarea label="Location Public Profile Description" model={`properties[${idx}].description`} />

              <Input label="Address Line 1" model={`properties[${idx}].address.line1`} />
              <Input label="Address Line 2" model={`properties[${idx}].address.line2`} />
              <Input label="City" model={`properties[${idx}].address.city`} />
              <Input label="County" model={`properties[${idx}].address.county`} />
              <Input label="State" model={`properties[${idx}].address.state`} />
              <Input label="Zip Code" model={`properties[${idx}].address.zipCode`} size={8} />

              <Attachments isInline label="Upload Location Photos" model={`properties[${idx}].photoGallery`} />
            </Grid>
          ))}
        </FormSection>

        {this.renderTrigger()}
      </State>
    )
  }
}

export default withFormContext(Properties)
