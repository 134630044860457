import React from 'react'
import size from 'lodash/size'

import { DropdownMenu, DropdownMenuItem } from '../../components/DropdownMenu'
import { useCreate, useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import Notifications from '../../modules/notifications'
import useStore from '../../modules/store'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import State from '../../components/State'
import Textarea from '../../components/Forms/Textarea'

const RootOrganizationDirectInviteOverlay = (props: any) => {
  const { orgId, onSave } = props

  const { close, data, form, isSaving, isOverlayLoading } = useOverlay({
    name: 'organizations-invite',
    endpoint: `/organizations`,
    invalidate: 'organizations',
    disableParentRequest: true,
    options: {
      dataID: props.dataID || orgId,
      ...props,
    },
  })

  const { mutateAsync: sendInvite, isLoading: isSendingInvite } = useCreate({
    name: ['send_community_profile_request'],
    url: `/organizations/${orgId}/send_direct_invite`,
    invalidateKeys: ['organizations', 'organization'],
  })

  const { data: inviteTemplates, isLoading: isLoadingInviteTemplates } = useGet({
    name: ['partnership-invite-templates'],
    url: `/partnership_invite_templates`,
  })

  const isInviteTemplatesEmpty = size(inviteTemplates) === 0

  const { timezone } = useSettings()
  const tenant = useStore((state: any) => state.tenant)
  const user = useStore((state: any) => state.newUser)

  const handleInvite = async () => {
    try {
      await sendInvite(form.current.getFormValue())
      Notifications.send('Your Invite was sent successfully', 'positive')
    } catch (error) {
      console.error(error)
      Notifications.send('Your Invite was unsuccessful', 'negative')
    }

    close()
  }

  const [subject, setSubject] = React.useState(`Connect with ${tenant?.name || ''}`)
  const [message, setMessage] = React.useState(getDefaultMessage({ user, tenant }))

  if (!data || isOverlayLoading) return <OverlayLoader position="center" />

  const inviteTemplatesAction = (
    <Button
      label="Manage Invite Templates →"
      type="minimal"
      color="text"
      glyph="settings"
      size={100}
      link="/settings/community/invite-templates"
    />
  )

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={close} maxWidth={44}>
      <Overlay.Header title="Partner Up Now" icon="community_partners" />

      <Overlay.Content>
        <Form isEditable getForm={form} timezone={timezone}>
          <Section>
            <FormSection maxWidth="100%">
              <Grid gap="0.75rem">
                <Card className="pr-3 py-2 flex items-center">
                  <div className="w-12 flex items-center justify-center shrink-0">
                    <Icon icon="community" size={22} />
                  </div>

                  <div>
                    <h3 className="font-[700] text-[1.15rem]">{data.name}</h3>
                    <div>This is Behave Community organization. You can send them a direct invite to partner up with you.</div>
                  </div>
                </Card>

                <div className="mt-2">
                  <Flex gap="1rem" justifyContent="space-between">
                    <DropdownMenu
                      trigger={
                        <div>
                          <Button
                            size={200}
                            label="Select from Template…"
                            icon="community_partners"
                            after={<Glyph glyph="triangle_down" size={10} />}
                            display="inline-flex"
                          />
                        </div>
                      }
                    >
                      {isLoadingInviteTemplates || isInviteTemplatesEmpty ? (
                        <State
                          isLoading={isLoadingInviteTemplates}
                          isEmpty={isInviteTemplatesEmpty}
                          title="Invite Templates"
                          icon="community_partners"
                          emptyDescription="No invite templates created yet"
                          emptyActions={inviteTemplatesAction}
                          minHeight={180}
                        />
                      ) : (
                        inviteTemplates?.map?.((template: any) => (
                          <DropdownMenuItem
                            key={template.id}
                            label={template.title}
                            onClick={() => {
                              setSubject(template.title)
                              setMessage(template.message)
                            }}
                          />
                        ))
                      )}
                    </DropdownMenu>

                    {inviteTemplatesAction}
                  </Flex>
                </div>

                <Input
                  label="Subject"
                  value={subject}
                  onChange={({ value }: any) => {
                    setSubject(value)
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter a subject',
                    },
                  }}
                />

                <Textarea
                  label="Message"
                  value={message}
                  onChange={({ value }: any) => {
                    setMessage(value)
                  }}
                  validations={{
                    presence: {
                      message: 'Please write a message',
                    },
                  }}
                />
              </Grid>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Invite "${data.name}" to partner up!`}
          glyph="tick_circle"
          type="primary"
          color="green"
          onClick={handleInvite}
          isLoading={isSaving || isSendingInvite}
          isDisabled={!subject || !message}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const getDefaultMessage = ({ user, tenant }: any) => {
  return `Hello,

We would like to partner up with your organization via Behave Health so we can share information and referrals.

Please accept our invitation.
  
${user.name}
${tenant.name}`
}

export const OrganizationDirectInviteOverlay = withOverlayError(RootOrganizationDirectInviteOverlay)
