import React from 'react'

import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

import withSettings from '../../hocs/withSettings'

const STATUSES: any = {
  active: 'Active',
  archived: 'Archived',
}

const columns = (to, mainLinkAs, onClick) => [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Name',
    width: 240,
    Cell: ({ cell, row }: any) => {
      return <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} onClick={() => onClick?.(row.original)} label={cell?.value} />
    },
  },
  {
    accessor: 'request_cosigner_signature',
    Header: 'Company Signature',
    width: 180,
    Cell: ({ value, row }: any) => {
      if (!value) return 'Not required'
      return <Status label={`${row.original.contracts_count} Signees`} />
    },
  },
  {
    accessor: 'should_sign',
    Header: 'Behave Health Signature',
    width: 180,
    Cell: ({ value, row }: any) => {
      if (!value) return 'Not required'

      if (row.original.cosigner_signed_at) return <Status label="Signed" color="green" />
      return <Status label="Not Signed" />
    },
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  title?: string
  titleAfter?: React.ReactNode
  to?: Function
}

const LegalAgreementsTable = (props: Props) => {
  const { batchActionsConfig, data, isLoading, localStorageKey, onClick, title, titleAfter, to, mainLinkAs, ...rest } = props

  return (
    <Table
      data={data}
      columns={columns(to, mainLinkAs, onClick)}
      title={title}
      titleAfter={titleAfter}
      icon="legal_agreement"
      isLoading={isLoading}
      emptyDescription="No legal agreements added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

LegalAgreementsTable.defaultProps = {
  title: 'Legal Agreements',
  localStorageKey: 'legal_agreements',
}

export default withSettings(LegalAgreementsTable)
