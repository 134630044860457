import React from 'react'

import { withOverlayError } from '../../hocs/withOverlayError'
import { useGet } from '../../hooks/useNewAPI'

import Overlay from '../../components/Overlay'

import { BedsBoard } from './BedsBoard'

const RootBedsBoardOverlay = ({ onClose }: any) => {
  const [date, setDate]: any = React.useState(null)

  const queryParams = { date: date }

  const { data, isLoading }: any = useGet({
    name: ['bed-management-board', queryParams],
    url: `/bed_management/board`,
    params: { ...queryParams },
    options: { enabled: !!date },
  })

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center" maxWidth={120}>
      <Overlay.Header title="Beds Board" icon="bed_management_board" />

      <Overlay.Content css={STYLES.content}>
        <BedsBoard setDate={setDate} isLoading={isLoading || !data?.houses} houses={data?.houses} />
      </Overlay.Content>
    </Overlay>
  )
}

const STYLES = {
  content: {
    padding: '1rem',
    minHeight: 200,
  },
}

export const BedsBoardOverlay = withOverlayError(RootBedsBoardOverlay)
