import React from 'react'
import { useParams } from 'react-router-dom'
import * as HoverCard from '@radix-ui/react-hover-card'

import { useSettings } from '../../../hooks/useSettings'
import { usDateTime } from '../../../utils/functions'

import { BED_ASSIGNMENT_COLORS } from '../../BedManagement/BedsTimeline'
import BedOccupancyStatus from '../../../components/Statuses/BedOccupancyStatus'
import Button from '../../../components/Button'
import DataList from '../../../components/DataList'
import Flex from '../../../components/Flex'
import Icon from '../../../components/Icon'
import RoadmapBar from '../../../components/Roadmap/RoadmapBar'

import { CardTooltip } from '../components/CardTooltip'
import { CardTooltipContent } from '../components/CardTooltipContent'
import { ClientHeader } from '../components/ClientHeader'
import { BedAssignmentInfo } from '../../BedManagement/BedAssignmentInfo'

export const BedAssignment = ({ data, client }: any) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const { timezone } = useSettings()
  const { resource_id }: any = useParams()

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  if (!data) return null

  return (
    <CardTooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
      <HoverCard.Trigger asChild>
        <RoadmapBar
          minWidth={7}
          background={BED_ASSIGNMENT_COLORS[data.status]}
          endDate={data.ended_at}
          startDate={data.started_at}
          onClick={openTooltip}
        >
          <Icon icon="beds" size={15} />
        </RoadmapBar>
      </HoverCard.Trigger>

      <CardTooltipContent>
        {/* <ClientHeader client={client} /> */}

        <Flex centerY css={{ padding: '0.5rem 1rem 0.25rem' }}>
          <Icon icon="beds" size={20} className="!mr-2" />
          <div className="text-[1.1rem] font-[700]">Bed Assignment</div>
          <Button
            label="Open"
            size={100}
            link={`/clients/${resource_id}/occupancies/${data.id}`}
            css={{ marginLeft: 'auto' }}
            target="_blank"
          />
        </Flex>

        <BedAssignmentInfo data={data} />

        {/* <DataList isCompact withPadding labelWidth={140}>
          <DataList.Item label="Status" value={<BedOccupancyStatus status={data.status} />} />
          <DataList.Item label="From" value={usDateTime(data.started_at, timezone)} />
          <DataList.Item label="Until" value={data.period_type === 'indefinite' ? 'Discharged' : usDateTime(data.ended_at, timezone)} />
        </DataList> */}
      </CardTooltipContent>
    </CardTooltip>
  )
}
