import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import withSettings from '../../hocs/withSettings'

import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Table from '../../components/Table/Table'
import TableCell from '../../components/Table/TableCell'

import { CATEGORIES } from './TagSmartCategorySelector'

const columns = (onClick: any = () => {}) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'data.name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink onClick={() => onClick?.(row.original)} label={value} />,
  },
  {
    width: 200,
    accessor: 'data.tags',
    Header: 'Tags',
    Cell: ({ value, row }: any) => {
      if (size(value) === 0) return <TableCell.Empty />

      return (
        <>
          {value.map((tag, index) => (
            <Flex key={index} centerY gap="0.5rem">
              <Glyph glyph={tag.glyph} color={tag.color} size={20} />
              <div>{tag.name}</div>
            </Flex>
          ))}
        </>
      )
    },
  },
  {
    width: 200,
    accessor: 'data.smart_categories',
    Header: 'Smart Categories',
    Cell: ({ value, row }: any) => {
      if (size(value) === 0) return <TableCell.Empty />

      return (
        <>
          {value.map((tag, index) => {
            const config = CATEGORIES[tag]

            if (!config) return <TableCell.Empty />

            return (
              <Flex key={index} centerY gap="0.5rem">
                {config.graphicType === 'glyph' && <Glyph glyph={config.graphic} size={20} />}
                {config.graphicType === 'icon' && <Icon icon={config.graphic} size={20} />}
                <div>{config.label}</div>
              </Flex>
            )
          })}
        </>
      )
    },
  },
  {
    width: 200,
    accessor: 'data.description',
    Header: 'Description',
  },
]

const RootTagGroupTemplatesImportTable = (props: any) => {
  const { url } = useRouteMatch()

  const {
    data,
    emptyActions,
    isLoading,
    batchActionsConfig,
    onClick,
    titleAfter,
    title = 'Tags Group Template',
    icon = 'tags',
    emptyDescription = 'No tag templates added yet',
    localStorageKey = 'tags',

    ...rest
  } = props

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(onClick)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

export const TagGroupTemplatesImportTable = withSettings(RootTagGroupTemplatesImportTable)
