import React from 'react'
import { useLocation } from 'react-router-dom'

import { usDate, usTime, titleCase, titleDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import Status from '../Status'
import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'

const colors = {
  completed: 'blue',
  rejected: 'red',
  signed_off: 'green',
  to_complete: 'gray',
}

type Props = {
  data: any
  location: any
  timezone: any
  to: any
}

const TreatmentPlan = ({ data, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={titleDate(data?.created_at, timezone)} />
      <CardMeta>
        <Status label={titleCase(data?.status)} color={colors[data?.status]} />
        <Text avatar={data?.author?.avatar} label={data?.author?.name} />
        <Text glyph="date" label="Date: " description={usDate(data?.created_at, timezone)} />
        <Text glyph="time" label="Time: " description={usTime(data?.created_at, timezone)} />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(TreatmentPlan)
