import React from 'react'

import { isDefined, nicePercentage } from '../../../utils/functions'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

const formatValue = ({ value }: any) => {
  if (!isDefined(value)) return <EmptyCell />

  return value.replace(/<[^>]*>/g, ' ')
}

export const RichTextCell: React.FC = (props: any) => {
  return <DataTableCell {...props} hoverExpand formatValue={formatValue} />
}
