import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Flex from '@behavehealth/components/Flex'
import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import SmartStatus from '@behavehealth/components/SmartStatus'

const STATUSES = {
  paid: {
    label: 'Paid',
    color: 'green',
  },
  open: {
    label: 'Open',
    color: 'yellow',
  },
  partiallyPaid: {
    label: 'Partially Paid',
    color: 'yellow',
  },
  void: {
    label: 'Void',
    color: 'yellow',
  },
  draft: {
    label: 'Archived',
    color: 'gray',
  },
  unknown: {
    label: 'Unknown',
    color: 'gray',
  },
}

const RootBillCreditNotes = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting', 'bill_credit_notes'],
    endpoint: `/accounting/bill_credit_notes`,
    // params: {...},
    localStorageKey: 'accounting_bill_credit_notes_v1',
  })

  const { mutateAsync: syncRailzToBh, isLoading: isSyncingRailz } = useCreate({
    name: ['sync-railz-to-bh'],
    url: '/accounting/bill_credit_notes/sync_railz_to_bh',
    invalidate: tableProps.queryKey,
  })

  const { mutateAsync: syncQbToRailz, isLoading: isSyncingQb } = useCreate({
    name: ['sync-qb-to-railz'],
    url: '/accounting/bill_credit_notes/sync_qb_to_railz',
  })

  const handleSyncRailzToBh = async () => {
    await syncRailzToBh()
    tableProps.refetch()
  }

  const handleSyncQbToRailz = () => {
    syncQbToRailz()
  }

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Vendor',
        model: 'vendor_ref_name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.vendor_ref_name} />,
      },
      {
        title: 'Memo',
        model: 'memo',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Quickbooks Status',
        model: 'status',
        width: 260,
        disableHide: true,
        formatValue: ({ value }) => <SmartStatus statuses={STATUSES} status={value} />,
      },
      {
        title: 'Remaining Credit',
        model: 'remaining_credit',
        width: 200,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Total Amount',
        model: 'total_amount',
        width: 200,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Posted Date',
        model: 'posted_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
      {
        title: 'Quickbooks Last Updated',
        model: 'source_modified_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Bill Credit Notes">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Bill Credit Notes"
          emptyDescription="No bill credit notes have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          icon="financials"
          columns={columns}
          {...tableProps}
          headerAfter={
            isBehave && (
              <Flex gap="0.5rem">
                <AccountingSyncButton
                  forceShow={true}
                  category="bill_credit_notes"
                  invalidate={tableProps.queryKey}
                  refetchUrl={'/accounting/sync?category=bill_credit_notes'}
                />
              </Flex>
            )
          }
        />
      </Grid>
    </Page>
  )
}

export const BillCreditNotes = withPageError(RootBillCreditNotes)

const FILTERS_CONFIG = {
  vendor_ref_name: {
    label: 'Vendor',
    type: 'string',
  },
  memo: {
    label: 'memo',
    type: 'string',
  },
  status: {
    label: 'status',
    type: 'string',
  },
  total_amount: {
    label: 'Total Amount',
    type: 'string',
  },
  remaining_credit: {
    label: 'Remaining Credit',
    type: 'string',
  },
  currency_ref_id: {
    label: 'Currency Ref Id',
    type: 'string',
  },
  posted_date: {
    label: 'Posted Date',
    type: 'string',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'datetime',
  },
}
