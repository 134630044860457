import React from 'react'

import { APP_URLS } from '../MyAccount/utils'
import { COLORS } from '../../theme'
import { usePermissions } from '../../hooks/usePermissions'
import { useSettings } from '../../hooks/useSettings'

import Button from '../../components/Button'
import Glyph from '../../components/Glyph'
import Tooltip from '../../components/Tooltip'

export const InviteStaffMemberAction = ({ link }: any) => {
  const { allowed } = usePermissions({ permission: 'employees.actions.invite' })

  return (
    <Tooltip show={!allowed} color="red" content={<MissingStaffInvitePermissionMessage />}>
      <Button label="Invite Staff Member" type="primary" glyph="add" link={link} isDisabled={!allowed} />
    </Tooltip>
  )
}

export const MissingStaffInvitePermissionMessage = () => {
  const { user, tenant } = useSettings()

  const permissionContactEmail = tenant?.contacts?.roles_permissions?.email || tenant?.contacts?.owner?.email

  return (
    <div className="flex flex-nowrap items-center">
      <Glyph glyph="warning" color={COLORS.red} size={18} className="mr-1.5" />

      <div>
        <div className="font-[600]">You do not have permission to invite staff members</div>

        {permissionContactEmail ? (
          <div>
            Please contact your administrator at{' '}
            <a
              className="font-[500]"
              href={`mailto:${permissionContactEmail}?subject=${encodeURIComponent(noPermissionSubject)}&body=${encodeURIComponent(
                getNoPermissionEmailBody({ user, tenant }),
              )}`}
            >
              {permissionContactEmail}
            </a>{' '}
            to enable this for you.
          </div>
        ) : (
          <div>Please contact your administrator to enable this for you.</div>
        )}
      </div>
    </div>
  )
}

const noPermissionSubject = `Permission request to invite staff members in Behave Health EHR`

const getNoPermissionEmailBody = ({ user, tenant }: any) => `Hello,

I would like to invite staff members to the "${tenant.name}" Behave Health EHR app, but I do not have permission to do so.

Can you please enable the "Invite New Staff" permission for me from:
${APP_URLS.ehr}/employees/${user?.id}/staff-permissions

Thank you,
${user?.name || ''}
`
