import { useState } from 'react'

import { useOverlay } from './useOverlay'

type Args = {
  onDeleteSuccessful?: any
  onSaveSuccessful?: any
  options?: any
  Overlay?: any

  // data form custom
  category: string
  subcategory: string
  title: string
  version?: string
}

export const useDataFormOverlay = (args: Args) => {
  const {
    onDeleteSuccessful,
    onSaveSuccessful,
    options,
    Overlay,

    // data form custom
    category,
    subcategory,
    title,
    version,
  } = args

  const {
    cancel,
    client: apiClient,
    close,
    createAsync,
    data,
    deleteRecord,
    edit,
    form,
    handleFullyMinimize,
    handleMinimize,
    highlightRequired,
    id,
    initialData,
    initialModel,
    isDeleting,
    isEditable,
    isEmpty,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    isValid,
    onIdle,
    onValidationUpdate,
    parent,
    queryKey,
    invalidateKeys,
    setIsEditable,
    updateAsync,
  } = useOverlay({
    name: 'data_form',
    endpoint: '/data_forms',
    invalidate: 'data_forms',
    filters: { category: category },
    options: options,
    onSaveSuccessful: onSaveSuccessful,
    onDeleteSuccessful: onDeleteSuccessful,
    Overlay: Overlay,
  })

  const client: any = options?.client || apiClient
  const client_id = client?.id || parent.id

  const [datedAt, setDatedAt] = useState(null)

  const save = async () => {
    const formData = form.current.getFormValue()

    const saveData = {
      id: data?.id,
      name: title,
      ...formData,
      client_id: client_id,
      category: category,
      subcategory: subcategory,
      version: version,
      status: formData?.client_should_complete ? 'client_draft' : 'employee_draft',
    }

    let request = isNew ? await createAsync(saveData) : await updateAsync(saveData)

    if (options?.onSaveSuccessful) options?.onSaveSuccessful?.(request.data?.data)

    if (onSaveSuccessful) onSaveSuccessful(request.data?.data)

    close()
  }

  return {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    highlightRequired,
    id,
    initialData,
    initialModel,
    isDeleting,
    isEditable,
    isEmpty,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    isValid,
    onIdle,
    onValidationUpdate,
    queryKey,
    invalidateKeys,

    // custom data forms logic
    client_id,
    client,
    dated_at: datedAt,
    handleFullyMinimize,
    handleMinimize,
    save,

    onDateUpdate: (date: any) => setDatedAt(date.value),

    updateStatusTo: async (status: string) => {
      await updateAsync({ status: status })
      setIsEditable(false)
    },

    sendForSupervisorReview: async ({ model, value }: any) => {
      await updateAsync({ [model]: value })
      setIsEditable(false)
    },

    requestEmployeeUpdates: async (updates: any) => {
      await updateAsync({
        status: 'employee_updates_required',
        internal_updates_requested: updates.value,
      })
      setIsEditable(false)
    },

    requestClientUpdates: async (updates: any) => {
      await updateAsync({
        status: 'client_updates_required',
        internal_updates_requested: updates.value,
      })
      setIsEditable(false)
    },

    signOff: async ({ model, value }: any) => {
      await updateAsync({ [model]: value })
      setIsEditable(false)
    },

    signOffWithoutSupervisor: async ({ model, value }: any) => {
      await updateAsync({ [model]: value, status: 'signed_off' })
      setIsEditable(false)
    },
  }
}
