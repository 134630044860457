import React from 'react'
import { darken } from 'polished'
import { DateTime } from 'luxon'
import { useRouteMatch } from 'react-router-dom'

import Card from '../../Card'
import Link from '../../Link'
import Tabs from '../../Tabs'
import Tooltip from '../../Tooltip'

import AutoRepeatingChargesTable from '../../Tables/AutoRepeatingChargesTable'
import ChargesTable from '../../Tables/ChargesTable'
import CreditsTable from '../../Tables/CreditsTable'
import RefundsTable from '../../Tables/RefundsTable'
import WriteOffsTable from '../../Tables/WriteOffsTable'
import PaymentsTable from '../../Tables/PaymentsTable'
import TransactionsTable from '../../Tables/TransactionsTable'

import TransactionStats from './TransactionStats'
import { COLORS } from '../../../theme'
import { useGet } from '../../../hooks/useNewAPI'

const Transactions = (props: any) => {
  const { hiddenColumns, isSelectable, name, showRepeatingCharges, showReportLinks, url, useStats } = props

  const match = useRouteMatch()

  const [transactionsCategory, setTransactionsCategory] = React.useState('charge,payment,credit,refund,write_off,fee')
  const { data: transactions, isLoading }: any = useGet({
    name: [name, transactionsCategory].flat(),
    url: url,
    params: { category: transactionsCategory },
    options: {
      staleTime: 0,
    },
  })

  const estTime = DateTime.local().setZone('America/New_York')
  const leftTime = estTime.set({ hour: 8, minute: 0 }).toLocal().toFormat('ha')
  const rightTime = estTime.set({ hour: 10, minute: 0 }).toLocal().toFormat('ha')
  const offsetName = DateTime.local().toFormat('ZZZZZ')

  return (
    <>
      {useStats && <TransactionStats showRepeatingCharges={showRepeatingCharges} name={name} url={url} />}

      <Card testKey="transaction_table" className="!mt-4">
        <Tabs defaultTab="all_transactions">
          <Tabs.List caps className="!pl-4">
            <Tabs.Item
              label="All Transactions"
              name="all_transactions"
              onSelected={() => setTransactionsCategory('charge,payment,credit,refund,write_off')}
            />

            <Tabs.Divider />

            <Tabs.Item label="Charges" name="charges" onSelected={() => setTransactionsCategory('charge')} />
            <Tabs.Item label="Payments" name="payments" onSelected={() => setTransactionsCategory('payment')} />
            <Tabs.Item label="Credits" name="credits" onSelected={() => setTransactionsCategory('credit')} />
            <Tabs.Item label="Refunds" name="refunds" onSelected={() => setTransactionsCategory('refund')} />
            <Tabs.Item label="Write Off's" name="write-offs" onSelected={() => setTransactionsCategory('write_off')} />

            {showRepeatingCharges && (
              <>
                <Tabs.Divider />
                <Tabs.Item
                  label="Auto-Repeating Charges"
                  name="auto_repeating_charges"
                  onSelected={() => setTransactionsCategory('recurring')}
                />
              </>
            )}

            <Tooltip
              color={darken(0.05, COLORS.orange)}
              glyph="question"
              position="top"
              content={
                <span>
                  <i>Future Planned Charges</i> and <i>Auto-Repeating Charges</i> get processed daily between <b>{leftTime}</b> and{' '}
                  <b>{rightTime}</b>, {offsetName}
                </span>
              }
              css={{ marginLeft: 'auto', marginRight: '0.85rem' }}
            />
          </Tabs.List>

          <Tabs.Panels>
            <Tabs.Panel name="all_transactions">
              <TransactionsTable
                testKey="all_transactions_table"
                isSelectable={isSelectable}
                isLoading={isLoading}
                hiddenColumns={hiddenColumns}
                data={transactions}
                to={({ id, category }: any) => ({
                  pathname: `${match?.url}/${category}/${id}`,
                  parent: match,
                })}
              />
            </Tabs.Panel>

            <Tabs.Panel name="charges">
              <ChargesTable
                testKey="charges_table"
                isSelectable={isSelectable}
                isLoading={isLoading}
                hiddenColumns={hiddenColumns}
                data={transactions}
                to={(id: any) => ({
                  pathname: `${match?.url}/charges/${id}`,
                  parent: match,
                })}
                titleAfter={showReportLinks && <Link to="/reports/charges">View Full Report →</Link>}
              />
            </Tabs.Panel>

            <Tabs.Panel name="payments">
              <PaymentsTable
                testKey="payments_table"
                isSelectable={isSelectable}
                isLoading={isLoading}
                hiddenColumns={hiddenColumns}
                data={transactions}
                to={(id: any) => ({
                  pathname: `${match?.url}/payments/${id}`,
                  parent: match,
                })}
                titleAfter={showReportLinks && <Link to="/reports/payments">View Full Report →</Link>}
              />
            </Tabs.Panel>

            <Tabs.Panel name="credits">
              <CreditsTable
                testKey="credits_table"
                isSelectable={isSelectable}
                isLoading={isLoading}
                hiddenColumns={hiddenColumns}
                data={transactions}
                to={(id: any) => ({
                  pathname: `${match?.url}/credits/${id}`,
                  parent: match,
                })}
                titleAfter={showReportLinks && <Link to="/reports/credits">View Full Report →</Link>}
              />
            </Tabs.Panel>

            <Tabs.Panel name="refunds">
              <RefundsTable
                testKey="refunds_table"
                isSelectable={isSelectable}
                isLoading={isLoading}
                hiddenColumns={hiddenColumns}
                data={transactions}
                to={(id: any) => ({
                  pathname: `${match?.url}/refunds/${id}`,
                  parent: match,
                })}
                titleAfter={showReportLinks && <Link to="/reports/refunds">View Full Report →</Link>}
              />
            </Tabs.Panel>

            <Tabs.Panel name="write-offs">
              <WriteOffsTable
                testKey="write_offs_table"
                isSelectable={isSelectable}
                isLoading={isLoading}
                hiddenColumns={hiddenColumns}
                data={transactions}
                to={(id: any) => ({
                  pathname: `${match?.url}/write-offs/${id}`,
                  parent: match,
                })}
                titleAfter={showReportLinks && <Link to="/reports/write-offs">View Full Report →</Link>}
              />
            </Tabs.Panel>

            <Tabs.Panel name="auto_repeating_charges">
              <AutoRepeatingChargesTable
                testKey="auto_repeating_charges_table"
                isSelectable={isSelectable}
                isLoading={isLoading}
                hiddenColumns={hiddenColumns}
                data={transactions}
                to={(id: any) => ({
                  pathname: `${match?.url}/auto-repeating-charges/${id}`,
                  parent: match,
                })}
              />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </Card>
    </>
  )
}

Transactions.defaultProps = {
  name: 'financial_transactions',
  useStats: true,
  isSelectable: true,
  showRepeatingCharges: true,
}

export default Transactions
