import React from 'react'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'

import { COLORS } from '../theme'
import { DEFAULT_EMPTY_VALUE } from '../utils/constants'

import Avatar from './Avatar'

type DataListProps = {
  isCompact?: boolean
  centerLabelsY?: boolean
  labelWidth?: string | number
  layout?: string
  paddingY?: string
  withPadding?: boolean
  withDividers?: boolean
} & React.HTMLAttributes<any>

const DataList: React.FC<DataListProps> = (props) => {
  const {
    children,
    className,
    isCompact,
    labelWidth = 160,
    layout = 'horizontal',
    paddingY = '0.5rem',
    withPadding,
    withDividers = true,
    centerLabelsY = false,
  } = props

  const classNames = clsx({
    'is-compact': isCompact,
    'with-padding': withPadding,
    'without-dividers': !withDividers,
    'center-labels-y': centerLabelsY,
    [`layout-${layout}`]: layout,
    [className]: className,
  })

  return (
    <dl css={styles.root({ labelWidth, paddingY })} className={classNames}>
      {children}
    </dl>
  )
}

type DataListItemProps = {
  label: string
  value: string
}

const DataListItem: React.FC<DataListItemProps> = (props: any) => {
  const { label, value, avatar, avatarInitials, testKey, className, centerLabelY } = props

  return (
    <div data-test={testKey} css={styles.item} className={className}>
      <dt className={centerLabelY ? 'self-center' : ''}>{label}</dt>
      <dd>
        {!isUndefined(avatar) && <Avatar size={20} src={avatar} initials={avatarInitials || value} css={styles.avatar} />}
        {value || DEFAULT_EMPTY_VALUE}
      </dd>
    </div>
  )
}

const DataListTitle = ({ title, className }: any) => (
  <p css={styles.title} className={className}>
    {title}
  </p>
)

const setWidth = (width: string | number) => (typeof width === 'number' ? `${width}px` : width)

const styles: Record<string, any> = {
  root: ({ labelWidth, paddingY }: any) => ({
    margin: 0,
    '--label-width': setWidth(labelWidth),
    '--padding-y': paddingY,
    '--padding-x': '1rem',

    '&.is-compact': {
      '--padding-y': '0.4rem',
      fontSize: '0.92rem',
    },

    '&.center-labels-y': {
      dt: {
        alignSelf: 'center',
      },
    },
  }),

  title: {
    fontSize: '1.05rem',
    fontWeight: 700,
    margin: '0 0 0.5em',
  },

  item: {
    padding: 'var(--padding-y) 0',
    borderBottom: `1px solid ${COLORS.divider}`,

    '.layout-vertical > &': {
      display: 'block',

      dt: {
        paddingBottom: '0.25em',
      },
    },

    '.with-padding &': {
      paddingLeft: 'var(--padding-x)',
      paddingRight: 'var(--padding-x)',
    },

    '.without-dividers &': {
      borderBottom: 'none',
    },

    '@media (min-width: 600px)': {
      '.layout-horizontal > &': {
        display: 'flex',
        flexWrap: 'nowrap',

        dt: {
          paddingRight: '0.5em',
          flex: '0 0 var(--label-width)',
        },
      },
    },

    '&:last-child, &:only-child': {
      borderBottom: 'none',
    },

    dt: {
      fontWeight: 600,
    },

    dd: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 1 auto',
      margin: 0,
      whiteSpace: 'pre-line',
    },
  },

  avatar: {
    marginRight: '0.5rem',
  },
}

DataListItem.defaultProps = {
  value: DEFAULT_EMPTY_VALUE,
}

DataList.Item = DataListItem
DataList.Title = DataListTitle

export default DataList
