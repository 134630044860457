import React from 'react'
import { keyframes } from '@emotion/react'
import { tint } from 'polished'
import * as Popover from '@radix-ui/react-popover'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS, SHADOW } from '../../../theme'
import { useGet, useUpdate, getPrefix } from '../../../hooks/useNewAPI'
import { usePortal } from '../../../hooks/usePortal'

import Card from '../../Card'
import Empty from './Empty'
import Flex from '../../Flex'
import Glyph from '../../Glyph'
import Grid from '../../Grid'
import Loader from '../../Loader'
import State from '../../State'
import Tooltip from '../../Tooltip'

import { Tag } from '../../Tag'

const SmartFlags = (props: any) => {
  const { invalidate, invalidateKeys, queryKey, updateEndpoint, updateId, updateKey, value } = props

  const portalContainer = usePortal('default')

  const isEditable = !!(updateEndpoint && updateId && updateKey)

  const [menuFlags, setMenuFlags]: any = React.useState(value || [])
  const menuFlagIds = menuFlags?.map?.((o) => o.id)

  const [didChange, setDidChange] = React.useState(false)

  const { mutateAsync, isLoading: isUpdating }: any = useUpdate({
    name: [updateKey, updateId].flat(),
    url: `${updateEndpoint}/${updateId}`,
    invalidate: invalidate,
    invalidateKeys: [queryKey, invalidateKeys],
    onSuccess: (_data: any, variables: any, queryClient: any) => {
      if (!queryKey || !updateId || !variables.smart_flags) return

      const prefix = getPrefix()

      // update flags in queryKey cache
      queryClient.setQueryData([prefix, queryKey].flat(), (oldData: any) => {
        if (!oldData?.data) return

        const index = oldData.data.findIndex((o) => o.id === updateId)
        if (index === -1) return

        const newData = produce(oldData, (draft: any) => {
          draft.data[index].smart_flags = variables.smart_flags
        })

        return newData
      })

      setDidChange(false)
    },
  })

  const { data, isLoading }: any = useGet({
    name: ['smart-flags'],
    url: `/smart_flags`,
  })

  const isDataEmpty = size(data) === 0
  const isValueEmpty = size(value) === 0

  React.useEffect(() => {
    if (!value) return
    setMenuFlags(value)
  }, [value])

  const addFlag = (flag: any) => {
    const newFlags = produce(menuFlags, (draft: any) => {
      draft.push(flag)
    })

    setMenuFlags(newFlags)
    setDidChange(true)
  }

  const removeMenuFlag = (id: any) => {
    const newFlags: any = produce(menuFlags, (draft: any) => {
      const index = draft.findIndex((o) => o.id === id)
      draft.splice(index, 1)
    })

    setMenuFlags(newFlags)
    setDidChange(true)
  }

  const handleSave = () => {
    if (didChange && mutateAsync) {
      mutateAsync({
        smart_flag_ids: menuFlags.map((o: any) => o.id),
        smart_flags: menuFlags, // used in the optimistic update
      })
    }
  }

  const cellFlags = !isValueEmpty && (
    <Flex gap="0.4rem">
      {value?.map?.((flag: any) => (
        <Tag key={flag.id} data={flag} />
      ))}
    </Flex>
  )

  return (
    <>
      <Tooltip content={cellFlags}>
        {isUpdating && <Loader size={20} color="blue" />}
        {isValueEmpty && !isUpdating && <Empty />}
        {cellFlags}
      </Tooltip>

      {isEditable && (
        <Popover.Root>
          <Popover.Trigger css={styles.trigger}>
            <Glyph glyph="edit" className="cell-glyph" size={15} />
          </Popover.Trigger>

          <Popover.Portal container={portalContainer}>
            <Popover.Content asChild onInteractOutside={handleSave}>
              <div css={styles.menu}>
                <Card css={styles.card}>
                  {isDataEmpty || isLoading ? (
                    <State
                      title="Smart Flags"
                      minHeight={150}
                      emptyDescription="No Smart Flags have been created yet"
                      glyph="flag"
                      isEmpty={isDataEmpty}
                      isLoading={isLoading}
                    />
                  ) : (
                    <>
                      <div css={styles.content}>
                        <Grid>
                          {data.map((flag: any) => {
                            const isAdded = menuFlagIds.includes(flag.id)

                            return (
                              <Tooltip content={flag.description} position="left">
                                <div
                                  key={flag.id}
                                  css={styles.groupRow}
                                  style={{ '--color': flag.color }}
                                  className={isAdded ? 'is-added' : ''}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    event.nativeEvent.stopImmediatePropagation()

                                    if (isAdded) removeMenuFlag(flag.id)
                                    else addFlag(flag)
                                  }}
                                >
                                  <Glyph glyph={flag.glyph} size={14} color={flag.color} css={styles.glyph} />

                                  <div>{flag.name}</div>

                                  {isAdded && (
                                    <div className="close-button">
                                      <Glyph glyph="cross" size={10} color={COLORS.textMuted} />
                                    </div>
                                  )}
                                </div>
                              </Tooltip>
                            )
                          })}
                        </Grid>
                      </div>
                    </>
                  )}
                </Card>
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      )}
    </>
  )
}

const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translateY(-12px);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translateY(0);
  }
`

const styles = {
  root: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  popover: {
    background: 'white',
    width: '100%',
    maxWidth: 300,
    outline: 'none',
    borderRadius: 5,
    boxShadow: SHADOW(3),
    border: `1px solid ${COLORS.divider}`,

    fontSize: '0.96rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    padding: '0.4em 0.75em',
    zIndex: 0,
  },

  trigger: {
    position: 'absolute',
    top: 1,
    bottom: 1,
    right: 0,
    zIndex: 1,
    border: 'none',
    background: 'none',

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      width: 50,
      display: 'block',
      background: `linear-gradient(90deg, ${COLORS.transparent}, ${COLORS.white} 50%)`,
      borderRight: `1px solid #dde1ee`,
    },

    '.table-row:nth-child(odd) &::after': {
      background: `linear-gradient(90deg, ${COLORS.transparent}, ${tint(0.25, COLORS.lightBackground)} 50%)`,
    },

    '.cell-glyph': {
      position: 'absolute',
      top: '50%',
      right: '0.5rem',
      transform: 'translateY(-50%)',
      zIndex: 1,

      '@media(min-width: 600px)': {
        opacity: 0,
        transition: 'all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
      },
    },

    '&:hover': {
      '&::after': {
        background: `linear-gradient(90deg, ${COLORS.transparent}, ${tint(0.75, COLORS.vividBlue)}) !important`,
      },

      '@media(min-width: 600px)': {
        '.cell-glyph': {
          opacity: 1,
          transform: 'translateX(0) translateY(-50%)',
        },
      },
    },
  },

  menu: {
    padding: '0.5rem',
    animation: `${animation} 100ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards`,
  },

  card: {
    minWidth: '15rem',
    maxWidth: '20rem',
    maxHeight: 400,
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    overflow: 'auto',
  },

  content: {
    overflowY: 'auto',
  },

  groupHeader: {
    padding: '0.3rem 0.75rem',
    fontWeight: 600,
    fontSize: '0.92rem',
    lineHeight: 'normal',
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    borderTop: `1px solid ${COLORS.divider}`,

    '&:first-of-type': {
      borderTop: 'none',
      marginTop: '0.25rem',
    },
  },

  groupRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0.75rem',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '0.95rem',
    width: '100%',

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-added': {
      fontWeight: 500,

      '.row-swatch': {
        boxShadow: `
          0 0 0 2px ${COLORS.white},
          0 0 0 4px var(--color)
        `,
      },

      '.close-button': {
        marginLeft: 'auto',
      },

      '&:hover .close-button svg': {
        transform: 'scale3d(1.25, 1.25, 1.25)',
        fill: COLORS.red,
      },
    },
  },

  glyph: {
    marginRight: '0.25rem',
  },
}

export default SmartFlags
