import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  accepted: {
    label: 'Accepted',
    color: 'green',
  },
  pending: {
    label: 'Pending',
    color: 'orange',
  },
  declined: {
    label: 'Declined',
    color: 'red',
  },
  expired: {
    label: 'Expired',
    color: 'gray',
  },
  cancelled: {
    label: 'Revoked',
    color: 'gray',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
