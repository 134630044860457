import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Page, Timeline, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

const ActivityTimeline = () => {
  const match = useRouteMatch()

  return (
    <Page feature="activity_timeline" plan="lite" help={<HelpTagIframe id="activity_timeline" />}>
      <Timeline asRecipient recordType="resident" recordID={match?.params?.resource_id} />
    </Page>
  )
}

export default withPageError(ActivityTimeline)
