import React from 'react'
import { NavLink } from 'react-router-dom'
import { useMedia } from 'use-media'
import { keyframes } from '@emotion/react'
import { darken, transparentize, tint } from 'polished'
import { Global } from '@emotion/react'
import clsx from 'clsx'

import { COLORS, MEDIA_QUERY, BREAKPOINT, TOKENS } from '../theme'
import { titleCase } from '../utils/functions'
import { useAppSidebarStore } from '../hooks/useAppSidebarStore'
import { User } from '../declarations'
import { Logo as LogoType } from '../declarations/types'

import { Heading } from './Typography'
import AppNavGroup from './AppNavGroup'
import AppNavItem from './AppNavItem'
import Button from './Button'
import Divider from './Divider'
import Dropdown from './Dropdown'
import DropdownItem from './DropdownItem'
import Flex from './Flex'
import Glyph from './Glyph'
import Grid from './Grid'
import Header from './Header'
import Icon from './Icon'
import Logo from './Logo'
import QuickAdd from './QuickAdd'
import Scroll from './Scroll'
import SettingsMenuLink from './SettingsMenuLink'
import Status from './Status'
import Wrapper from './Wrapper'
import HelpTrigger from './HelpTrigger'

type Props = {
  before: React.ReactNode
  logo: LogoType
  logoLinkTo?: string
  openSearch: Function
  settingsLinkTo?: string
  settingsNavItems?: any
  showBookmarks?: boolean
  showHelpInfo?: boolean
  showQuickAdd?: boolean
  user: User
}

const AppNav: React.FC<Props> = (props) => {
  const { before, children, logo, logoLinkTo, openSearch, settingsLinkTo, settingsNavItems, showQuickAdd, user, linksAs } = props

  const { open } = useAppSidebarStore()
  const openAppSidebar = () => open('todo')

  const isCompact = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavCompactBreakpoint] })
  const isExpanded = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavExpandBreakpoint] })

  const isMobile = !isCompact
  const newHeader = false

  const classNames = clsx({
    'is-expanded': isExpanded,
    'is-compact': isCompact,
  })

  return (
    <>
      {isMobile && showQuickAdd && <QuickAdd hideLabel isFloating />}
      {!isMobile && <HelpTrigger />}

      <div css={wrapperStyles} className="AppNav">
        <Global styles={mobileStyles} />
        <Scroll isActive={isCompact} css={styles.appNav} className={classNames} innerCSS={{ minHeight: 'auto', width: '100%' }}>
          {/* Mobile View */}
          {isMobile && (
            <Flex fullWidth justifyContent="space-between" alignItems="center">
              <div>
                <Flex horizontal gap={8} alignItems="center">
                  <Dropdown
                    trigger={
                      <Button
                        label="Menu"
                        type="default"
                        color="blue"
                        glyphColor={COLORS.blue}
                        before={<Logo logo={logo} small css={mobileLogoStyles} />}
                      />
                    }
                  >
                    {before}
                    <Wrapper>{children}</Wrapper>
                    <Wrapper>
                      <AppNavGroup title="Settings">
                        <AppNavItem as={linksAs} icon="settings" label="Settings" to={settingsLinkTo} />
                        <AppNavItem as={linksAs} icon="log_out" label="Log Out" to="/logout" />
                      </AppNavGroup>
                    </Wrapper>
                  </Dropdown>
                </Flex>
              </div>

              <div>
                <Flex horizontal gap={12}>
                  {openSearch && (
                    <Button
                      label="Search…"
                      glyph="search"
                      glyphColor={COLORS.blue}
                      glyphSize={20}
                      color="text"
                      onClick={openSearch}
                      css={searchStyles}
                    />
                  )}

                  <Button hideLabel glyph="app_sidebar" glyphColor={COLORS.blue} glyphSize={20} color="blue" onClick={openAppSidebar} />
                </Flex>
              </div>
            </Flex>
          )}

          {/* Desktop View */}
          {isCompact && (
            <>
              <Header isSticky css={{ justifyContent: 'center', top: 0 }}>
                {newHeader && (
                  <NavLink to={logoLinkTo}>
                    <Icon icon="behave_health" size={26} css={{ marginLeft: isExpanded && -6 }} />
                  </NavLink>
                )}

                {isExpanded && newHeader && (
                  <div className="!ml-[0.75em]">
                    <div
                      css={{
                        fontWeight: 600,
                        fontSize: '1.02rem',
                        marginBottom: '0.25em',
                        whiteSpace: 'nowrap',
                        maxWidth: 200,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        color: COLORS.text,
                      }}
                    >
                      Company Name
                    </div>

                    <Heading is="h3" size={100} className="whitespace-nowrap opacity-90">
                      <strong className="text-text">BEHAVE</strong>
                      <span className="!font-[400]">HEALTH</span>
                      <strong className="text-text"> EHR</strong>
                    </Heading>
                  </div>
                )}

                {!newHeader && (
                  <NavLink to={logoLinkTo}>
                    <Logo logo={logo} small={!isExpanded} />
                  </NavLink>
                )}
              </Header>

              {before}

              {showQuickAdd && <QuickAdd hideLabel={isCompact && !isExpanded} className="!p-3 !pb-0" />}

              <Wrapper>{children}</Wrapper>
            </>
          )}
        </Scroll>

        {isCompact && user && (
          <Grid gap={0} css={pinnedFooterStyles}>
            {(settingsLinkTo || settingsNavItems) && <SettingsMenuLink to={settingsLinkTo} navItems={settingsNavItems} />}

            <Dropdown
              css={triggerStyles}
              trigger={
                <AppNavItem
                  useV6Router
                  skipActive
                  showEmptyAvatar
                  avatarMagnify={false}
                  avatar={user.avatar}
                  label={user.name}
                  description={<Status label={titleCase(user.position)} feature="employees" />}
                  aside={<Glyph glyph="triangle_down" size={14} color={COLORS.blue} />}
                />
              }
            >
              <>
                {/* <DropdownItem
                  icon="settings"
                  label="Settings"
                  link={settingsLinkTo}
                  featureFlag={true}
                  permission={true}
                /> */}
                <DropdownItem as={linksAs} icon="log_out" label="Log Out" link="/logout" />
              </>
            </Dropdown>
          </Grid>
        )}
      </div>
    </>
  )
}

const mountAnimation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const growAnimation = keyframes`
  0% {
    transform: translateX(-5px) scale3d(0.99, 0.99, 0.99);
  }
  100% {
    transform: translateX(0) scale3d(1, 1, 1);
  }
`

const shrinkAnimation = keyframes`
  0% {
    transform: translateX(5px) scale3d(1.01, 1.01, 1.01);
  }
  100% {
    transform: translateX(0) scale3d(1, 1, 1);
  }
`

const mobileStyles: any = {
  '#app': {
    paddingTop: TOKENS.appHeaderHeight,

    [`${MEDIA_QUERY[1]}`]: {
      paddingTop: 0,
    },
  },
}

const wrapperStyles: any = {
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 10,

  boxShadow: `
    0.5px 0 0 ${COLORS.divider},
    2px 0 6px ${COLORS.shadow},
    4px 0 12px ${COLORS.shadow},
    8px 0 24px ${COLORS.shadow}
  `,

  [`${MEDIA_QUERY[1]}`]: {
    position: 'static',
  },
}

const triggerStyles: any = {
  margin: '0',
  padding: '0.5rem 0.75rem',
  background: 'white',
  position: 'relative',
}

const searchStyles: any = {
  color: COLORS.text,
  fontWeight: 500,
  background: darken(0.02, COLORS.lightBackground),
  boxShadow: 'none',

  paddingLeft: '0.75em',
  paddingRight: '0.75em',

  '.button-label': {
    display: 'none',
  },

  '&:hover': {
    transform: 'none',
    color: COLORS.blue,
    background: tint(0.92, COLORS.blue),
    boxShadow: `
      inset 0 0 0 1px ${transparentize(0.75, COLORS.blue)}
    `,

    '& svg': {
      fill: `${COLORS.blue}`,
    },
  },

  '@media (min-width: 400px)': {
    '.button-label': {
      display: 'inline',
    },
  },
}

const mobileLogoStyles: any = {
  width: 24,
  height: 24,
  padding: 4,

  borderRadius: '50%',
  boxShadow: `
    0 0 2px ${transparentize(0.88, COLORS.violet)},
    0 2px 6px ${transparentize(0.76, COLORS.violet)},
    0 2px 10px ${transparentize(0.94, COLORS.violet)}
  `,
}

const styles: any = {
  appNav: {
    position: 'sticky',
    zIndex: 10,
    top: 0,

    height: 48,
    padding: '0 0.75rem 0',

    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    flex: '1 1 auto',

    background: 'white',
    boxShadow: `inset 0 -1px 0 ${transparentize(0.1, COLORS.divider)}`,

    animation: `${mountAnimation} 480ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,

    [`${MEDIA_QUERY[1]}`]: {
      height: 'auto',
      minHeight: 'auto',
      padding: 0,
      display: 'block',
      position: 'relative',
    },
  },

  '&.is-expanded': {
    animation: `${growAnimation} 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,
    transformOrigin: 'left center',
  },

  '&.is-compact': {
    animation: `${shrinkAnimation} 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,
    transformOrigin: 'left center',
  },
}

const pinnedFooterStyles = {
  zIndex: 10,
  background: COLORS.white,
  boxShadow: `
    0 -2px 6px rgb(12 62 106 / 3%),
    0 -4px 12px rgb(12 62 106 / 3%),
    0 -8px 24px rgb(12 62 106 / 3%)`,
}

AppNav.defaultProps = {
  logoLinkTo: '/',
}

export default AppNav
