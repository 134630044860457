import React from 'react'
import { Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom'
import size from 'lodash/size'

import { APP_URLS } from '@behavehealth/constructs/MyAccount/utils'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { ORGANIZATIONS_TYPE_VENDORS, ORGANIZATIONS_TYPE_RESOURCES, ORGANIZATIONS_TYPE_PROVIDERS } from '@behavehealth/utils/constants'

import { CommunityProfileShare } from '@behavehealth/constructs/Community/CommunityProfileShare'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { PARTNERSHIP_STATUSES } from '@behavehealth/constructs/Partnerships/PartnershipStatus'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { EHROrganizationStatus } from '@behavehealth/constructs/Organizations/EHROrganizationStatus'

import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import Icon from '@behavehealth/components/Icon'
import PageHeader from '@behavehealth/components/PageHeader'
import Status from '@behavehealth/components/Status'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'
import Tooltip from '@behavehealth/components/Tooltip'

import { CommunityMessageBoard } from './community_message_board'
import { PartnersLocations } from './community_shared_locations'

import { OrganizationCommunityRequestOverlay } from '@behavehealth/constructs/Community/OrganizationCommunityRequestOverlay'
import { OrganizationInviteRequestOverlay } from '@behavehealth/constructs/Community/OrganizationInviteRequestOverlay'
import { OrganizationDirectInviteOverlay } from '@behavehealth/constructs/Community/OrganizationDirectInviteOverlay'

import image from '@behavehealth/assets/images/organizations_statuses_legend.jpg'

export const CommunityOrganizations: React.FC = () => {
  const match = useRouteMatch()
  const location = useLocation()

  const { connectedEntities } = useSettings()
  const hasConnectedEntities = size(connectedEntities) > 0

  return (
    <div css={STYLES.root}>
      <div className="bg-white sticky top-0 z-10 flex-0">
        <PageHeader
          title="Organizations"
          icon="organizations"
          titleAside={
            hasConnectedEntities && (
              <div className="!ml-3">
                <CommunityProfileShare profiles={connectedEntities} label="Share Your Profile…" />
              </div>
            )
          }
          aside={
            <Flex gap="0.5rem">
              <Button
                label="Explore Community →"
                icon="community"
                type="minimal"
                link="/community/explore"
                permission="community_explore.view"
              />

              <Button
                label="Import New Organization"
                type="primary"
                glyph="add"
                link={`${location.pathname}/import-new`}
                permission="organizations.actions.create"
              />
            </Flex>
          }
        />

        <Tabs>
          <Tabs.List className="px-4">
            <Tabs.Item label="All" to={`/community/organizations/all`} />
            <Tabs.Item label="Organizations" to={`/community/organizations/orgs`} />
            <Tabs.Item label="Partners" to={`/community/organizations/partners`} />
            <Tabs.Divider />
            <Tabs.Item label="Partners Shared Locations" to={`/community/organizations/shared-locations`} />
            <Tabs.Item label="Partners Message Board" to={`/community/organizations/message-board`} />
            <Tabs.Divider />
            <Tabs.Item label="Archived" to={`/community/organizations/archived`} />
          </Tabs.List>
        </Tabs>
      </div>

      <div css={STYLES.content}>
        <Switch>
          <Route path={`/community/organizations/shared-locations`} component={PartnersLocations} />
          <Route path={`/community/organizations/message-board`} component={CommunityMessageBoard} />

          <Route path={`/community/organizations/all`}>
            <OrganizationsDataTable title="Organizations & Partners" testKey="organizations_data_table" />
          </Route>

          <Route path={`/community/organizations/orgs`}>
            <OrganizationsDataTable title="Organizations" queryParams={{ status: 'organization' }} />
          </Route>

          <Route path={`/community/organizations/partners`}>
            <OrganizationsDataTable title="Partners" queryParams={{ status: 'partner' }} />
          </Route>

          <Route path={`/community/organizations/archived`}>
            <OrganizationsDataTable title="Archived Organizations" queryParams={{ status: 'archived' }} />
          </Route>

          <Redirect exact from={match.url} to={`/community/organizations/all`} />
        </Switch>
      </div>
    </div>
  )
}

const OrganizationsDataTable = ({ title, queryParams }: any) => {
  const match = useRouteMatch()
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['organizations'],
    endpoint: '/organizations',
    params: queryParams,
    localStorageKey: `organizations_v3`,
  })

  const [inviteCommunity, setInviteCommunity] = React.useState(null)
  const [inviteOrg, setInviteOrg] = React.useState(null)
  const [directInviteOrg, setDirectInviteOrg] = React.useState(null)

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => {
          return <MainCell id={data.id} value={data.name} to={`/community/organizations/${data?.id}`} />
        },
      },
      {
        title: 'Community Profile',
        model: 'community_profile.name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => {
          if (!data.community_profile) return null

          const communityURL = `${APP_URLS.community}/profiles/${data.community_profile.slug}`

          return (
            <LinkCell
              isExternal
              before={<Icon icon="community" size={16} className="mr-1.5" />}
              value={data.community_profile.name}
              href={communityURL}
            />
          )
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
      },
      {
        title: 'Status',
        model: 'internal_status',
        width: 200,
        formatValue: ({ value }: any) => {
          return <EHROrganizationStatus status={value} />
        },
      },
      {
        title: 'Actions',
        id: 'actions',
        model: 'partnership_status',
        width: 250,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          const internalStatus = data?.internal_status

          if (internalStatus === 'internal')
            return (
              <Button
                label="Submit Partnership Request"
                icon="community_invites"
                color="blue"
                size={100}
                display="inline-flex"
                onClick={() => setInviteCommunity(data)}
              />
            )

          if (internalStatus === 'community_profile_request') return <Status label="Reviewing your Request" color="green" />
          if (internalStatus === 'partner_invite_sent') return <Status label="Waiting for a Reply" color="green" />

          if (internalStatus === 'ready_to_partner' && !data.is_ready_for_direct_invite) {
            return (
              <Button
                label="Submit Partnership Request"
                icon="community_partners"
                color="blue"
                size={100}
                display="inline-flex"
                onClick={() => setInviteOrg(data)}
              />
            )
          }

          if (internalStatus === 'ready_to_partner' && data.is_ready_for_direct_invite) {
            return (
              <Button
                label="Invite to Partner Up Now"
                icon="community_partners"
                color="blue"
                size={100}
                display="inline-flex"
                onClick={() => setDirectInviteOrg(data)}
              />
            )
          }

          if (internalStatus === 'partner_invite_request') return <Status label="Reviewing your Request" color="green" />

          return null
        },
      },
      {
        title: 'Type',
        model: 'category',
        type: 'title',
      },
      {
        title: 'Category',
        id: 'subcategory',
        model: 'subcategory',
        type: 'title',
      },
      {
        title: 'Phone',
        model: 'phone_no',
        type: 'phone',
      },
      {
        title: 'Fax',
        model: 'fax',
        type: 'fax',
      },
      {
        title: 'Email',
        model: 'email',
        type: 'email',
      },
      {
        title: 'Website',
        model: 'website',
        type: 'external_url',
      },
      {
        title: 'City',
        id: 'city',
        model: 'address.city',
      },
      {
        title: 'State',
        id: 'state',
        model: 'address.state',
      },
      {
        title: 'Address',
        id: 'address',
        model: 'address',
        type: 'address',
        width: 280,
        disableSort: false,
      },
      {
        title: 'Client Portal Sharing',
        model: 'is_shared_with_portal',
        type: 'boolean',
        formatValue: ({ value }: any) => (value ? 'Shared' : 'Not shared'),
      },
    ],
    [match.url],
  )

  return (
    <>
      <DataTable
        asCard
        testKey="organizations_data_table"
        title={title}
        headerAfter={
          isBehave && (
            <Tooltip glyph="question" position="bottom" content={<img src={image} className="w-full max-h-[2000px]" />}>
              <Status glyph="behave_health" label="Legend" color="blue" />
            </Tooltip>
          )
        }
        icon="organizations"
        columns={columns}
        filtersConfig={FILTERS_CONFIG}
        {...tableProps}
      />

      <SummonOverlay
        isOpen={!!inviteCommunity}
        onClose={() => {
          setInviteCommunity(null)
        }}
        overlay={<OrganizationCommunityRequestOverlay orgId={inviteCommunity?.id} orgName={inviteCommunity?.name} />}
      />

      <SummonOverlay
        isOpen={!!inviteOrg}
        onClose={() => {
          setInviteOrg(null)
        }}
        overlay={<OrganizationInviteRequestOverlay orgId={inviteOrg?.id} orgName={inviteOrg?.name} />}
      />

      <SummonOverlay
        isOpen={!!directInviteOrg}
        onClose={() => {
          setDirectInviteOrg(null)
        }}
        overlay={<OrganizationDirectInviteOverlay orgId={directInviteOrg?.id} orgName={directInviteOrg?.name} />}
      />
    </>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    overflow: 'hidden',
  },

  content: {
    padding: '1rem',
    overflowY: 'auto',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
  },
}

const VENDOR_SELECT_OPTIONS = Object.keys(ORGANIZATIONS_TYPE_VENDORS).map((vendor) => ({
  label: ORGANIZATIONS_TYPE_VENDORS[vendor],
  value: vendor,
}))

const RESOURCE_SELECT_OPTIONS = Object.keys(ORGANIZATIONS_TYPE_RESOURCES).map((resource) => ({
  label: ORGANIZATIONS_TYPE_RESOURCES[resource],
  value: resource,
}))

const PROVIDER_SELECT_OPTIONS = Object.keys(ORGANIZATIONS_TYPE_PROVIDERS).map((provider) => ({
  label: ORGANIZATIONS_TYPE_PROVIDERS[provider],
  value: provider,
}))

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  tags: {
    label: 'Tags',
    type: 'tags',
  },
  // internal_status: {
  //   label: 'Status',
  //   type: 'multi_select',
  //   options: Object.keys(TABS).map((key) => ({
  //     value: key,
  //     label: TABS[key].label,
  //   })),
  // },
  partnership_status: {
    label: 'Partnership Status',
    type: 'multi_select',
    options: Object.keys(PARTNERSHIP_STATUSES).map((key) => ({
      value: key,
      label: PARTNERSHIP_STATUSES[key].label,
    })),
  },
  category: {
    label: 'Type',
    type: 'multi_select',
    options: [
      { label: 'Vendor', value: 'vendor' },
      { label: 'Community Resource', value: 'resource' },
      { label: 'Provider', value: 'provider' },
    ],
  },
  subcategory: {
    label: 'Category',
    type: 'multi_select',
    options: [
      { groupTitle: 'Vendor' },
      ...VENDOR_SELECT_OPTIONS,

      { groupTitle: 'Community Resource' },
      ...RESOURCE_SELECT_OPTIONS,

      { groupTitle: 'Provider' },
      ...PROVIDER_SELECT_OPTIONS,
    ],
  },
  phone_no: {
    label: 'Phone No',
    type: 'string',
  },
  fax: {
    label: 'Fax',
    type: 'string',
  },
  email: {
    label: 'Email',
    type: 'string',
  },
  city: {
    label: 'City',
    type: 'string',
  },
  state: {
    label: 'State',
    type: 'string',
  },
  website: {
    label: 'Website',
    type: 'string',
  },
  address: {
    label: 'Address',
    type: 'string',
  },
  is_shared_with_portal: {
    label: 'Client Portal Sharing',
    type: 'boolean',
  },
}
