import React from 'react'
import size from 'lodash/size'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import { withPageError } from '@behavehealth/hocs/withPageError'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button } from '@behavehealth/components'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { REFERRAL_CONSENT_FORMS } from '@behavehealth/constructs/Referrals/mockData'
import { ReferralConsentFormTemplateOverlay } from '@behavehealth/constructs/Referrals/ReferralConsentFormTemplateOverlay'

import Page from '@behavehealth/components/Page'

const ReferralConsentFormTemplates = () => {
  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} id={data.id} value={value} to={data.id} />,
      },
      {
        width: 200,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'updated_at',
        title: 'Last Edited',
        type: 'date_time',
      },
      {
        width: 180,
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [])

  return (
    <>
      <Page
        icon="referrals"
        title="Referral Consent Form Templates"
        actions={<Button as={Link} label={`Add Consent Form Template`} glyph="add" type="primary" size={300} link="new" />}
      >
        <DataTable
          asCard
          title="Referral Consent Forms"
          icon="referrals"
          columns={columns}
          data={REFERRAL_CONSENT_FORMS}
          meta={{ count: size(REFERRAL_CONSENT_FORMS) }}
        />
      </Page>

      <AnimatedRoutes>
        <Route exact path=":id" element={<ReferralConsentFormTemplateOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

export default withPageError(ReferralConsentFormTemplates)
