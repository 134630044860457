import React from 'react'
import { NavLink, Navigate, Route, Routes, useParams } from 'react-router-dom-v5-compat'
import sortBy from 'lodash/sortBy'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { COLORS } from '@behavehealth/theme'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Glyph from '@behavehealth/components/Glyph'
import Page from '@behavehealth/components/Page'
import Tabs from '@behavehealth/components/Tabs'

import { CATEGORIES } from '@behavehealth/constructs/DataFormsBuilder/constants'
import { DataFormBuilderOverlay } from '@behavehealth/constructs/DataFormsBuilder/DataFormBuilderOverlay'
import { DataFormSettingsStatus } from '@behavehealth/constructs/DataFormsBuilder/DataFormSettingsStatus'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const RootAssessmentsBuilder = () => {
  return (
    <>
      <Page title="Assessments Builder" icon="assessments_builder">
        <Tabs>
          <Tabs.List className="-mt-4 mb-4">
            {Object.keys(CATEGORIES).map((key) => {
              // discharges are managed on a separate page
              if (key === 'discharge') return null

              const config = CATEGORIES[key]

              return <Tabs.Item as={NavLink} key={key} label={config.name} icon={config.icon} to={key} />
            })}
          </Tabs.List>
        </Tabs>

        <Routes>
          <Route index element={<Navigate to="clinical" replace />} />
          <Route path=":slug/*" element={<InnerPage />} />
        </Routes>
      </Page>

      <AnimatedRoutes>
        <Route path=":slug/:id" element={<DataFormBuilderOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const InnerPage = () => {
  const { slug }: any = useParams()

  const category = CATEGORIES?.[slug]?.id

  const tableProps = useDataTable({
    name: ['data_form_settings'],
    endpoint: `/data_form_settings`,
    params: { category },
    keepPreviousData: false,
    enabled: !!category,
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 280,
        disableSort: true,
        disableHide: true,
        disableSelect: true,
        formatValue: ({ data, value }: any) => {
          return (
            <MainCell
              as={NavLink}
              id={data.id}
              value={value}
              icon={!data.variant && CATEGORIES?.[slug].icon}
              graphic={data.variant && <Glyph glyph="curved_arrow_down_right" size={12} color={COLORS.blue} className="ml-1.5" />}
              to={data.id}
            />
          )
        },
      },
      {
        title: 'Status',
        model: 'status',
        disableSort: true,
        formatValue: ({ value }: any) => <DataFormSettingsStatus status={value} />,
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        disableSort: true,
        type: 'date_time',
      },
    ]
  }, [slug, category])

  const data = React.useMemo(() => {
    if (!tableProps.data) return []

    return sortBy(tableProps.data, 'name')
  }, [tableProps.data])

  return (
    <>
      <div className="grid grid-cols-1">
        <DataTable
          key={`category-${category}`}
          asCard
          title={`${CATEGORIES?.[slug]?.nameFull}`}
          columns={columns}
          {...tableProps}
          data={data}
        />
      </div>
    </>
  )
}

export const AssessmentsBuilder = withPageError(RootAssessmentsBuilder)
