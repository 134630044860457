import React from 'react'

import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks'

import HelpTagIframe from '../Help/HelpTagIframe'
import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 220,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'recorded_at',
    Header: 'Date & Time',
    Cell: ({ row, value }: any) => <TableCell.MainLink to={to(row.original.id)} label={usDateTime(value, timezone)} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    id: 'client',
    accessor: 'client',
    Header: 'Client',
    Cell: ({ value }: any) => <TableCell.Profile avatar={value?.avatar} name={value?.name} />,
  },
  {
    width: 180,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }) => {
      if (value === 'refused') return <Status color="red" label="Refused Collection" />
      if (value === 'collected') return <Status color="blue" label="Collected" />
      return <TableCell.NotApplicable />
    },
  },
  {
    id: 'assessment_type',
    accessor: 'assessment_type',
    width: 150,
    Header: 'Assessment Type',
    Cell: ({ value }: any) => {
      if (value == 'normal') return 'Normal'
      else if (value == 'orthostatic') return 'Orthostatic'

      return <TableCell.Empty />
    },
  },
  {
    id: 'blood_pressure',
    accessor: 'blood_pressure_diastolic',
    width: 270,
    Header: 'Blood Pressure',
    Cell: ({ row }: any) => {
      const {
        assessment_type,
        blood_pressure_diastolic,
        blood_pressure_systolic,
        blood_pressure_systolic_lying_down,
        blood_pressure_diastolic_lying_down,
        blood_pressure_systolic_standing_1_minute,
        blood_pressure_diastolic_standing_1_minute,
        blood_pressure_systolic_standing_3_minute,
        blood_pressure_diastolic_standing_3_minute,
      } = row.original

      if (assessment_type === 'normal') {
        if (!blood_pressure_diastolic && !blood_pressure_systolic) return <TableCell.Empty />
        return `${blood_pressure_systolic} / ${blood_pressure_diastolic} mm Hg`
      } else if (assessment_type === 'orthostatic') {
        return `
          Down: ${blood_pressure_systolic_lying_down || '–'} / ${blood_pressure_diastolic_lying_down || '–'} mm Hg, 
          St (1m): ${blood_pressure_systolic_standing_1_minute || '–'} / ${blood_pressure_diastolic_standing_1_minute || '–'}, 
          St (3m): ${blood_pressure_systolic_standing_3_minute || '–'} / ${blood_pressure_diastolic_standing_3_minute || '–'}
        `
      }

      return <TableCell.Empty />
    },
  },
  {
    accessor: 'temperature',
    width: 80,
    Header: 'Temp.',
    Cell: ({ value }) => {
      if (!value) return <TableCell.Empty />
      return `${value} °F`
    },
  },
  {
    accessor: 'oxygen_saturation',
    width: 80,
    Header: 'O2 Sat',
    Cell: ({ value }) => {
      if (!value) return <TableCell.Empty />
      return `${value}%`
    },
  },
  {
    accessor: 'pulse',
    width: 270,
    Header: 'Pulse',
    Cell: ({ row }: any) => {
      const { assessment_type, pulse, pulse_lying_down, pulse_standing_1_minute, pulse_standing_3_minute } = row.original

      if (assessment_type === 'normal') {
        if (!pulse) return <TableCell.Empty />
        return `${pulse} beats / min`
      } else if (assessment_type === 'orthostatic') {
        return `
          Down: ${pulse_lying_down || '–'} beats / min, 
          St (1m): ${pulse_standing_1_minute || '–'} beats / min, 
          St (3m): ${pulse_standing_3_minute || '–'} beats / min
        `
      }

      return <TableCell.Empty />
    },
  },
  {
    accessor: 'respiration',
    width: 150,
    Header: 'Respiration',
    Cell: ({ value }) => {
      if (!value) return <TableCell.Empty />
      return `${value} breaths / min`
    },
  },
  {
    accessor: 'pain_scale',
    width: 110,
    Header: 'Pain Scale',
    Cell: ({ value }) => {
      if (!value) return <TableCell.Empty />
      return `${value} / 10`
    },
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns: string[]
  isLoading: boolean
  localStorageKey: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const VitalSignsTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, localStorageKey, batchActionsConfig, hiddenColumns, titleAfter } = props
  const { timezone } = useSettings()

  return (
    <Table
      title="Vital Signs"
      titleAfter={titleAfter}
      icon="test_results"
      data={data}
      columns={columns(to, timezone)}
      hiddenColumns={hiddenColumns}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No vital signs records added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      help={<HelpTagIframe id="test_results_vital_signs" />}
    />
  )
}

VitalSignsTable.defaultProps = {
  localStorageKey: 'vital_signs',
}

export default VitalSignsTable
