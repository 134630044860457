import React from 'react'

import { useSettings } from '../../../hooks/useSettings'
import { usDate, usDateTime } from '../../../utils/functions'
import DataList from '../../../components/DataList'
import DataFormStatus from '../../../components/Statuses/DataFormStatus'

export const ProgressReviewInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item label="Name" value={data.name} />
      <DataList.Item label="Status" value={<DataFormStatus small status={data.status} />} />
      {data.client && <DataList.Item label="Client" value={data.client.name} avatar={data.client.avatar} />}
      <DataList.Item label="Date and Time" value={usDateTime(data.dated_at, timezone)} />
      <DataList.Item label="Date Added" value={usDateTime(data.created_at, timezone)} />
      <DataList.Item label="Last Edited" value={usDateTime(data.updated_at, timezone)} />
    </DataList>
  )
}
