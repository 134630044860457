import React from 'react'

import Status from '../Status'
import { titleCase } from '../../utils/functions'

const statusColor = {
  vendor: 'violet',
  resource: 'purple',
  provider: 'blue',
}

const OrganizationStatus = ({ organization }: any) => {
  return (
    <Status
      color={statusColor[organization?.category]}
      label={organization?.category && organization?.subcategory && `${organization.category} – ${titleCase(organization.subcategory)}`}
    />
  )
}

export default OrganizationStatus
