import React from 'react'

import { COLORS } from '../../theme'
import { useCreate, useGet, invalidateQueries } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'

import Button from '../Button'
import Flex from '../Flex'
import Alert from '../Alert'
import Loader from '../Loader'

import Notifications from '../../modules/notifications'
import ConfirmDialog from '../Dialogs/ConfirmDialog'

const ReportRefetchButton = ({ category, invalidate, refetchUrl, statusUrl, forceShow = false }: any) => {
  const { isBehave } = useSettings()

  const [shouldCheckStatus, setShouldCheckStatus] = React.useState(true)
  const [wasProcessing, setWasProcessing] = React.useState(false)

  const { mutateAsync, isLoading } = useCreate({
    name: ['refetch-report'],
    url: refetchUrl,
    invalidate,
    invalidateKeys: [invalidate],
    onSuccess: () => {
      setShouldCheckStatus(true)
    },
    onError: () => {
      setShouldCheckStatus(true)
    },
  })

  const { data, refetch }: any = useGet({
    name: ['reports', 'status', { category }],
    url: statusUrl || `/live_reports/status`,
    params: { category },
    options: { refetchInterval: 30 * 1000, refetchOnMount: true, staleTime: 0, enabled: !!category && shouldCheckStatus },
    onSuccess: (success: any) => {
      if (wasProcessing && success?.data?.status === null) {
        invalidateQueries(invalidate)
        setWasProcessing(false)
      }
    },
  })

  React.useEffect(() => {
    if (data?.status === null) {
      setShouldCheckStatus(false)
    } else {
      setWasProcessing(true)
      setShouldCheckStatus(true)
    }
  }, [data])

  if (!forceShow && !isBehave) return null

  if (data?.status === 'processing')
    return (
      <Alert small type="warning">
        <Flex nowrap centerY gap="0.5rem">
          <Loader size={16} color={COLORS.orange} />
          <div>Report is being processed, it might take a few minutes. We'll notify you once it is processed via Email/SMS.</div>
        </Flex>
      </Alert>
    )

  return (
    <ConfirmDialog
      title="Confirm Rebuilding Report?"
      message="This will clear and rebuild the report with the latest data. It may take a few minutes. We will notify you once it is processed via Email/SMS."
      yesLabel="Yes, Rebuild & Notify Me"
      onYes={async () => {
        await mutateAsync({})

        Notifications.send(
          'Your new Reports are currently being processed. Please refresh using the button on the right to load the latest data. It might take a few minutes. We will notify you once it is processed via Email/SMS.',
          'positive',
        )
      }}
    >
      <Button size={100} label="Rebuild Report" glyph="reset" isLoading={isLoading} />
    </ConfirmDialog>
  )
}

export default ReportRefetchButton
