import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom-v5-compat'

import { mapToArray } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Button from '@behavehealth/components/Button'
import Divider from '@behavehealth/components/Divider'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

export const CalendarPageActionsV6: React.FC<any> = ({ data }) => {
  const location = useLocation()

  const { data: templates }: any = useGet({
    name: 'event-templates',
    url: `/event_templates`,
    params: { event_type: 'event' },
  })

  return (
    <>
      <Button
        as={Link}
        label="Event Note Audit"
        glyph="calendar"
        type="default"
        link="/reports/event-documentations"
        permission="events.view"
        featureFlag="live_reports"
      />

      <Button
        as={Link}
        label="Manage Event Templates"
        glyph="settings"
        type="default"
        link="/settings/calendar-templates"
        permission="calendar_templates.view"
      />

      <Dropdown label="Add Event…" glyph="add" buttonType="primary" buttonSize={300} permission="events.create">
        <DropdownItem
          as={NavLink}
          label="New Event"
          glyph="add"
          color="paleBlue"
          link={`${location.pathname}/event-builder`}
          state={{ data }}
          permission="events.create"
        />

        <Divider />

        {mapToArray(templates).map((template) => {
          const { id, ...templateKeys } = template

          return (
            <DropdownItem
              as={NavLink}
              key={template.id}
              label={template.title}
              icon="calendar"
              color="paleBlue"
              link={`${location.pathname}/event-builder`}
              state={{
                data: {
                  ...templateKeys,
                  ...data,
                  template_id: template.id,
                },
              }}
              permission="events.create"
            />
          )
        })}
      </Dropdown>
    </>
  )
}
