import React from 'react'

import { useUpdate } from '../../hooks/useNewAPI'
import Notifications from '../../modules/notifications'

import ColorPicker, { ColorPickerProps } from '../ColorPicker'

type Props = {
  clientID: string
  color: ColorPickerProps['color']
  isDisabled?: boolean
  name?: 'clients' | 'leads'
}

const ClientColorPicker: React.FC<Props> = ({ name = 'clients', clientID, color, isDisabled }) => {
  const [loading, setLoading] = React.useState(false)

  const { mutateAsync: updateClient }: any = useUpdate({
    name: ['client', clientID],
    url: `/residents/${clientID}`,
    invalidate: ['clients'],
  })

  const handleSelect = async (colorName: string) => {
    setLoading(true)
    Notifications.send('Updating…', 'positive', 1000)

    try {
      await updateClient({ color_code: colorName })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return <ColorPicker color={color} onSelect={(colorName: string) => handleSelect(colorName)} isLoading={loading} isDisabled={isDisabled} />
}

export default ClientColorPicker
