import React from 'react'

import { APP_URLS } from '../MyAccount/utils'
import { niceAmount, usDate } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import DataList from '../../components/DataList'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Icon from '../../components/Icon'
import Label from '../../components/Label'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import PageSection from '../../components/PageSection/PageSection'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Select from '../../components/Forms/Select'
import SummonOverlay from '../../components/SummonOverlay'
import Tabs from '../../components/Tabs'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { InvoiceBilledBySection } from '../InvoiceElements/InvoiceBilledBySection'
import { InvoiceBilledToSection } from '../InvoiceElements/InvoiceBilledToSection'
import { InvoiceDetailsInputs } from '../InvoiceElements/InvoiceDetailsInputs'
import { InvoiceLinesAmounts } from '../InvoiceElements/InvoiceLinesAmounts'
import { InvoiceLinesEditTable } from '../InvoiceElements/InvoiceLinesEditTable'
import { InvoiceLinesTable } from '../InvoiceElements/InvoiceLinesTable'
import { InvoiceSettingsOverlay } from '../InvoiceElements/InvoiceSettingsOverlay'
import { SectionCard } from '../../components/SectionCard'

import { InvoicePayOverlay } from './InvoicePayOverlay'
import { InvoiceStatus } from './InvoiceStatus'
import { NotificationLogsDataTable } from '../Notifications/NotificationLogsDataTable'
import { PaymentMethod, PaymentMethodTitle, PaymentMethodIcon, PaymentMethodDescription } from '../BillingElements/PaymentMethodElements'

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'

const INITIAL_DATA = {
  status: 'draft',
  billed_by_company_name: 'Behave Health Corp',
  billed_by_email: 'contact@behavehealth.com',
  billed_by_phone_no: '+1 650-338-4113',
  billed_by_address: {
    address_line_1: '135 Main Street',
    address_line_2: 'Suite #1140',
    city: 'San Francisco',
    state: 'CA',
    zip_code: '94105',
  },
}

const RootInvoiceOverlay = (props: any) => {
  const overlayProps = useOverlay({
    name: 'invoices',
    endpoint: props.isPublic ? '/apps/invoices' : '/invoices',
    options: props,
  })

  const isLegacy = overlayProps?.data?.category === 'legacy'

  if (!isLegacy && overlayProps.isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={120} />
  }

  if (isLegacy) {
    return <LegacyInvoiceOverlay {...overlayProps} />
  }

  return <NewInvoiceOverlay {...overlayProps} />
}

const NewInvoiceOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = props

  const [tab, setTab] = React.useState('invoice_details')
  const [invoiceLines, setInvoiceLines]: any = React.useState(data?.invoice_lines || [])
  const [deletedInvoiceLines, setDeletedInvoiceLines]: any = React.useState([])

  const { tenant, isBehave, timezone, isEHRApp, isHQApp } = useSettings()

  const [notificationConfig, setNotificationConfig] = React.useState(data?.notification_config || null)
  const [paymentMethod, setPaymentMethod] = React.useState(data?.auto_collection_payment_method || null)

  const facilityId = tenant?.id

  const [isPaymentOverlayOpen, setIsPaymentOverlayOpen] = React.useState(false)

  const canEditInvoiceLines = isBehave && data?.status !== 'paid' && data?.status !== 'processing'

  const save = async () => {
    const formData = form.current.getFormValue()

    const invoice_lines_attributes: any = []

    for (let i = 0; i < invoiceLines.length; i++) {
      const line = invoiceLines[i]

      invoice_lines_attributes.push({ ...line, order: i })
    }

    for (const line of deletedInvoiceLines) {
      invoice_lines_attributes.push(line)
    }

    try {
      const result = await saveWithData({
        ...formData,
        facility_id: tenant?.id,
        ...(canEditInvoiceLines && { invoice_lines_attributes }),
        ...(!data?.charged_payment_method && { auto_collection_payment_method_id: paymentMethod?.id || null }),
      })

      if (result.invoice_lines) {
        setInvoiceLines(result.invoice_lines)
      }

      setDeletedInvoiceLines([])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay onClose={close} position="right" maxWidth={120} showBackdrop={isEditable} closeOnEscape={false}>
      <Overlay.Header
        title="Invoice"
        icon="invoices"
        titleAside={
          <>
            {data?.external_id && (
              <Button
                useGlyphForTarget
                label="Open Public Invoice"
                glyph="link"
                target="_blank"
                className="!ml-3"
                size={100}
                href={`${APP_URLS.mini_apps}/invoices/${data.external_id}`}
              />
            )}

            {data?.hosted_receipt_url && (
              <Button
                useGlyphForTarget
                label="Open Public Receipt"
                glyph="link"
                target="_blank"
                className="!ml-3"
                size={100}
                href={data.hosted_receipt_url}
              />
            )}

            {!isEditable && <ExportPDFButton url={`/invoices/${id}/pdf`} tenant="public" />}
          </>
        }
      />

      {isBehave && IS_DEVELOPMENT && (
        <Overlay.SubHeader className="!py-0 relative z-[3]">
          <Tabs activeTab={tab} onChange={setTab}>
            <Tabs.List>
              <Tabs.Item label="Invoice Details" name="invoice_details" icon="invoices" />
              <Tabs.Item label="Notification Logs" name="notification_logs" icon="notification_logs" />
            </Tabs.List>
          </Tabs>
        </Overlay.SubHeader>
      )}

      {tab === 'invoice_details' && (
        <Overlay.Content>
          <Form
            isCompact
            getForm={form}
            initialModel={{
              ...initialModel,
              ...(isNew && INITIAL_DATA),
              ...(isNew && { pass_fee_to_payer: !!tenant?.pass_behave_internal_billing_fee_to_payer }),
            }}
            isEditable={isEditable}
            timezone={timezone}
            onValidationUpdate={onValidationUpdate}
            key={`updated-${data?.updated_at}`}
          >
            <FormSection maxWidth="100%" className="px-5 pt-4 pb-12">
              {data?.id && (
                <Card className="flex items-center justify-between px-3 py-1">
                  <div className="flex items-center">
                    {data.status && <InvoiceStatus status={data.status} className="mr-2" />}
                    {data.identifier && <div className="font-[600] mr-2">{data.identifier}</div>}
                    {data.invoice_number && <div className="text-text-muted mr-2">{data.invoice_number}</div>}
                  </div>

                  <Flex gap="0.75rem">
                    {isBehave && data?.id && !isEditable && (
                      <SummonOverlay overlay={<InvoiceSettingsOverlay dataID={data.id} />}>
                        <Button label="Invoice Settings" glyph="settings" type="minimal" size={200} />
                      </SummonOverlay>
                    )}

                    <SummonOverlay
                      isOpen={isPaymentOverlayOpen}
                      onClose={() => {
                        setIsPaymentOverlayOpen(false)
                      }}
                      overlay={<InvoicePayOverlay dataID={data.id} paymentMethodId={paymentMethod?.id} />}
                    />

                    {!isEditable &&
                      isEHRApp &&
                      data?.status !== 'paid' &&
                      data?.status !== 'processing' &&
                      data?.calculated_amounts?.total > 0 && (
                        <>
                          <Button
                            label="Pay Invoice"
                            glyph="tick_circle"
                            type="primary"
                            color="green"
                            size={200}
                            onClick={() => setIsPaymentOverlayOpen(true)}
                          />
                        </>
                      )}
                  </Flex>
                </Card>
              )}

              <PageSection>
                <div className="mt-2 grid gap-5 grid-cols-1 mq1024:grid-cols-[1.5fr_1fr_1fr]">
                  <SectionCard title="Details" className="!p-3">
                    <div className="grid gap-4">
                      <InvoiceDetailsInputs />

                      {isBehave && (
                        <>
                          <div className="flex flex-nowrap items-end">
                            <div className="flex-[1_1_auto]">
                              <OverlaySelector
                                label="Notifications Config"
                                tooltip="Select a configuration to use for sending notifications for this invoice"
                                icon="notification_configs"
                                type="admin.notification_configs"
                                model="notification_config"
                                selectTitle={(config: any) => config.name}
                                selectGraphic={() => <Icon icon="notification_configs" size={20} />}
                                onUpdate={({ object }) => {
                                  setNotificationConfig(object)
                                }}
                                labelAfter={
                                  isBehave &&
                                  notificationConfig?.id && (
                                    <div className="pl-2">
                                      <Button
                                        label="Open in HQ"
                                        size={100}
                                        href={`${APP_URLS.hq}/notification-configs/${notificationConfig.id}`}
                                        target="_blank"
                                      />
                                    </div>
                                  )
                                }
                              />
                            </div>
                          </div>

                          {isEditable && facilityId && !data?.charged_payment_method ? (
                            <ObjectSelector
                              label="Auto-Collection Payment Method"
                              tooltip="When a payment method is added, we will attempt to automatically charge it on the Due Date selected above"
                              value={paymentMethod}
                              onUpdate={({ value }) => setPaymentMethod(value)}
                              icon="online_card_payment"
                              type="admin.facility.global_payment_methods"
                              dependentValue={facilityId}
                              key={`facility-${facilityId}`}
                              selectTitle={(paymentMethod: any) => <PaymentMethodTitle paymentMethod={paymentMethod} />}
                              selectGraphic={(paymentMethod: any) => <PaymentMethodIcon paymentMethod={paymentMethod} />}
                              selectDescription={(paymentMethod: any) => <PaymentMethodDescription paymentMethod={paymentMethod} />}
                            />
                          ) : (
                            <div>
                              <Label isCompact label="Auto-Collection Payment Method" />

                              {data?.auto_collection_payment_method ? (
                                <PaymentMethod paymentMethod={data?.auto_collection_payment_method} />
                              ) : (
                                <div>–</div>
                              )}
                            </div>
                          )}

                          {!isNew && (
                            <div>
                              <Label isCompact label="Charged Payment Method" />

                              {data?.charged_payment_method ? <PaymentMethod paymentMethod={data?.charged_payment_method} /> : <div>–</div>}
                            </div>
                          )}

                          {isBehave && (
                            <RadioGroup
                              label="Payment Processing Fee"
                              model="pass_fee_to_payer"
                              layout="vertical-dense"
                              isEditable={isEditable && data?.status !== 'paid' && data?.status !== 'processing'}
                            >
                              <Radio label="Behave Health pays the fee" value={false} />
                              <Radio label="Invoice Payer pays the fee" value={true} />
                            </RadioGroup>
                          )}
                        </>
                      )}
                    </div>
                  </SectionCard>

                  <InvoiceBilledToSection />
                  <InvoiceBilledBySection />
                </div>
              </PageSection>

              <PageSection className="mt-4">
                <PageSection.Header>
                  <PageSection.Title title="Invoice Lines" className="!text-[1.5rem]" />
                </PageSection.Header>

                <PageSection.Content className="grid gap-4">
                  {/* {data && <InvoiceLinesFinancialCards amounts={data?.balance_amounts} />} */}

                  <Card className="px-4 py-3">
                    {isEditable && canEditInvoiceLines ? (
                      <InvoiceLinesEditTable
                        invoiceLines={invoiceLines}
                        setInvoiceLines={setInvoiceLines}
                        deletedInvoiceLines={deletedInvoiceLines}
                        setDeletedInvoiceLines={setDeletedInvoiceLines}
                      />
                    ) : (
                      <InvoiceLinesTable invoice={data} />
                    )}

                    {data && !isEditable && (
                      <div>
                        <div className="flex justify-end mt-4">
                          <InvoiceLinesAmounts invoice={data} />
                        </div>
                      </div>
                    )}
                  </Card>
                </PageSection.Content>
              </PageSection>
            </FormSection>
          </Form>
        </Overlay.Content>
      )}

      {tab === 'notification_logs' && (
        <Overlay.Content className="p-4">
          <PageSection>
            <PageSection.Header
              graphic={
                <div className="flex items-center justify-center w-6 h-6">
                  <Icon icon="notification_logs" size={18} />
                </div>
              }
            >
              <PageSection.Title title="Notification Logs" />
            </PageSection.Header>

            <NotificationLogsDataTable data={[]} />
          </PageSection>
        </Overlay.Content>
      )}

      {isBehave && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 auto"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button glyph="edit" label="Edit Invoice" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

              <DeleteDialog
                title="Delete Invoice?"
                message="Are you sure you want to delete this Invoice? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const LegacyInvoiceOverlay = (props: any) => {
  const { data, close, isEditable, form, isNew, isSaving, save, cancel, isLoading, edit } = props

  const { isEHRApp, isBehave, tenant, timezone } = useSettings()

  if (!data) return null

  return (
    <Overlay onClose={close}>
      <Overlay.Header title="Invoice" icon="invoices_blue" />

      <Overlay.Content className="p-5">
        <Form
          isCompact
          getForm={form}
          initialModel={{ status: data?.status }}
          isEditable={isEditable}
          timezone={timezone}
          key={`updated-${data?.updated_at}`}
        >
          <div className="grid gap-5">
            <Alert contrast glyph="info">
              This invoice was generated using a third-party provider. To view or download the invoice, please click the links below.
            </Alert>

            {isBehave && (
              <Select allowEmpty label="Status" model="status">
                <Option label="Draft" value="draft" />
                <Option label="Open" value="open" />
                <Option label="Paid" value="paid" />
                <Option label="Failed" value="failed" />
                <Option label="Refunded" value="refunded" />
                <Option label="Cancelled" value="cancelled" />
                <Option label="Written Off" value="written_off" />
              </Select>
            )}

            <Card>
              <div className="px-5 pt-2">
                <header className="flex items-center justify-between mt-1 mb-3">
                  <div>
                    <h1 className="">{niceAmount(data.amount)}</h1>

                    <div className="flex items-center">
                      <InvoiceStatus status={data.status} className="mr-2" />
                      {data.due_date && <div className="text-text-muted">Due {usDate(data.due_date, timezone)}</div>}
                    </div>
                  </div>

                  <div className="bg-blue-100 w-12 h-12 rounded-full flex items-center justify-center">
                    <Icon icon="invoices_blue" />
                  </div>
                </header>

                <DataList className="!mt-2" withDividers={true} paddingY="0.5rem" labelWidth={80}>
                  {data.invoice_number && <DataList.Item label="Invoice" value={data.invoice_number} />}
                  {isEHRApp && tenant && <DataList.Item label="To" value={tenant?.name} />}
                  <DataList.Item label="From" value="Behave Health Corp." />
                </DataList>
              </div>

              <div className="grid grid-cols-2 mt-4 border-t border-0 border-solid border-divider">
                <Button
                  useGlyphForTarget
                  label="Invoice Details"
                  type="minimal"
                  size={200}
                  glyph="note"
                  className="border-r border-0 border-solid border-divider !shadow-none !rounded-0 !py-3"
                  css={{ boxShadow: 'none !important', borderRadius: '0 !important' }}
                  href={data.invoice_url}
                  target="_blank"
                />

                <Button
                  useGlyphForTarget
                  label="Download PDF"
                  type="minimal"
                  size={200}
                  glyph="download"
                  className="!border-none !shadow-none !rounded-0 !py-3"
                  css={{ boxShadow: 'none !important', borderRadius: '0 !important' }}
                  href={data.invoice_pdf_url}
                  target="_blank"
                />
              </div>
            </Card>
          </div>
        </Form>
      </Overlay.Content>

      {isBehave && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button label="Save" glyph="check" type="primary" color="green" isLoading={isSaving} onClick={save} flex="100 1 auto" />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button glyph="edit" label="Edit Invoice" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const EHRInvoiceOverlay = withOverlayError(RootInvoiceOverlay)
