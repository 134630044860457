import React from 'react'
import size from 'lodash/size'

import { apiGet } from '../../../modules/api'

import Alert from '../../../components/Alert'
import Card from '../../../components/Card'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Loader from '../../../components/Loader'
import Workflow from '../../../components/Workflow/Workflow'

const ProgramStaffAccessLists = (props: any) => {
  const { client, data, setData, setStatus, currentPhase, nextPhase } = props

  const form = React.useRef()
  const { goNext }: any = React.useContext(Workflow.Context)

  const [loading, setLoading] = React.useState(false)
  const [currentPhaseEmployees, setCurrentPhaseEmployees] = React.useState(null)
  const [nextPhaseEmployees, setNextPhaseEmployees] = React.useState(null)

  const showRemove = nextPhase && nextPhase.id !== currentPhase?.id
  const showAdd = !!nextPhase

  const hasAccessStaffToRemove = size(currentPhaseEmployees)
  const hasPropertyStaff = size(nextPhaseEmployees)

  const handleContinue = async () => {
    setStatus('completed')
    goNext()
  }

  const handleSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    const findCurrentPhaseEmployees = async () => {
      setLoading(true)

      try {
        const results = await apiGet({ url: `/phases/${currentPhase?.id}` })
        setCurrentPhaseEmployees(results?.data?.data?.employees)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (showRemove) findCurrentPhaseEmployees()
  }, [showRemove, currentPhase?.id])

  React.useEffect(() => {
    const findNextPhaseEmployees = async () => {
      setLoading(true)

      try {
        const results = await apiGet({ url: `/phases/${nextPhase?.id}` })
        setNextPhaseEmployees(results?.data?.data?.employees)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (nextPhase?.id) findNextPhaseEmployees()
  }, [nextPhase?.id])

  if (!client || !data) return null

  return (
    <Form useLocalModel getForm={form} initialModel={data} onUpdate={setData}>
      <FormSection maxWidth="100%">
        {!nextPhase && (
          <Alert glyph="warning" type="warning">
            Select a new program assignment first to manage Staff Access Lists.
          </Alert>
        )}

        {loading ? (
          <Card className="!p-4">
            <Loader centerX centerY />
          </Card>
        ) : (
          <>
            {showRemove && (
              <>
                {hasAccessStaffToRemove ? (
                  <CheckboxGroup
                    withToggle
                    label={`Remove ${client?.first_name} from the Access Lists of the following Staff:`}
                    layout="vertical-dense"
                  >
                    {currentPhaseEmployees?.map?.((staff: any) => (
                      <Checkbox key={staff?.id} label={staff?.name} model={`staff_access_list.remove.${staff?.id}`} />
                    ))}
                  </CheckboxGroup>
                ) : (
                  <Alert glyph="info">
                    There are no Staff Access Lists to update for <strong>{client?.first_name}</strong>
                  </Alert>
                )}
              </>
            )}

            {showAdd && (
              <>
                {hasPropertyStaff ? (
                  <CheckboxGroup
                    withToggle
                    label={`Add ${client.first_name} to the Access Lists of the following Staff:`}
                    layout="vertical-dense"
                  >
                    {nextPhaseEmployees?.map?.((staff: any) => (
                      <Checkbox key={staff?.id} label={staff?.name} model={`staff_access_list.add.${staff?.id}`} />
                    ))}
                  </CheckboxGroup>
                ) : (
                  <Alert glyph="info">
                    <strong>{nextPhase?.name}</strong> has no Assigned Staff. To be able to manage Staff Access Lists, please assign staff
                    members to the property first.
                  </Alert>
                )}
              </>
            )}
          </>
        )}

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={handleContinue} />
          <Workflow.SkipButton onClick={handleSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default ProgramStaffAccessLists
