import React from 'react'

import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootFoRSEAdmitSurveyOverlay = (props: any) => {
  return (
    <DataFormOverlay
      disableSignOff
      minimizeEnabled
      enableTreatmentPlanSelector
      title="Admit Survey"
      category="outcome_measure"
      subcategory="forse_admit_survey"
      icon="outcome_measures"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      requestSupervisorSignature={false}
      signoffWithoutSupervisor={true}
      {...props}
    >
      <Section
        title="Questions"
        headingType="h2"
        scrollview={{
          id: 'questions',
          name: 'Questions',
        }}
      >
        <FormSection>
          <RadioGroup label="To what level of care is this client admitting?" model="data.admission_level_of_care" layout="vertical-dense">
            <Radio label="Inpatient Hospital or Medical Detox" value="inpatient_hospital_or_medical_detox" />
            <Radio label="Residential" value="residential" />
            <Radio label="Partial Hospitalization" value="partial_hospitalization" />
            <Radio label="Intensive Outpatient" value="intensive_outpatient" />
            <Radio label="Outpatient" value="outpatient" />
            <Radio label="Sober Living/Recovery Residence" value="sober_living_recovery_residence" />
            <Radio label="Recovery Support (e.g., recovery coaching)" value="recovery_support" />
            <Radio label="Other type of program" value="other_type_of_program" />
          </RadioGroup>

          <RadioGroup
            label="What was the client's employment status at the time of admission?"
            model="data.employment_status"
            layout="vertical-dense"
          >
            <Radio label="Full Time" value="full_time" />
            <Radio label="Part Time" value="part_time" />
            <Radio label="Student" value="student" />
            <Radio label="Unemployed - seeking employment" value="unemployed_seeking_employment" />
            <Radio
              label="Unemployed - not seeking employment (retired, on disability, other source of income, in controlled environment)"
              value="unemployed_not_seeking_employment"
            />
          </RadioGroup>

          <RadioGroup label="Housing status at the time of admission?" model="data.admission_housing_status" layout="vertical-dense">
            <Radio
              label="Homeless/unstable housing - (shelter, street, outdoors, temporarily staying with friends)"
              value="homeless_unstable_housing"
            />
            <Radio
              label="Institution/controlled environment - (hospital, nursing home, jail/prison, residential treatment)"
              value="institution_controlled_environment"
            />
            <Radio
              label="Stable housing - (Own or rent apartment, room or house, stable housing with friends/family, dormitory or college residence, sober living home, other)"
              value="stable_housing"
            />
          </RadioGroup>

          <div>
            <h4>Q4: Days of substance use in the 30 days prior to admission?</h4>

            <FormSection className="!pl-4">
              <Input
                label="Alcohol"
                size={4}
                suffix="days"
                model="data.prior_month_substance_use.alcohol"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 30,
                    message: 'Please enter an number between 0 and 30',
                  },
                }}
              />
              <Input
                label="Cannabis"
                size={4}
                suffix="days"
                model="data.prior_month_substance_use.cannabis"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 30,
                    message: 'Please enter an number between 0 and 30',
                  },
                }}
              />
              <Input
                label="Hallucinogens"
                size={4}
                suffix="days"
                model="data.prior_month_substance_use.hallucinogens"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 30,
                    message: 'Please enter an number between 0 and 30',
                  },
                }}
              />
              <Input
                label="Inhalants"
                size={4}
                suffix="days"
                model="data.prior_month_substance_use.inhalants"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 30,
                    message: 'Please enter an number between 0 and 30',
                  },
                }}
              />
              <Input
                label="Opioids"
                size={4}
                suffix="days"
                model="data.prior_month_substance_use.opioids"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 30,
                    message: 'Please enter an number between 0 and 30',
                  },
                }}
              />
              <Input
                label="Sedatives, hypnotics, or anxiolytics"
                size={4}
                suffix="days"
                model="data.prior_month_substance_use.sedatives_hypnotics_or_anxiolytics"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 30,
                    message: 'Please enter an number between 0 and 30',
                  },
                }}
              />
              <Input
                label="Stimulants"
                size={4}
                suffix="days"
                model="data.prior_month_substance_use.stimulants"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 30,
                    message: 'Please enter an number between 0 and 30',
                  },
                }}
              />
              <Input
                label="Tobacco"
                size={4}
                suffix="days"
                model="data.prior_month_substance_use.tobacco"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 30,
                    message: 'Please enter an number between 0 and 30',
                  },
                }}
              />
              <Input
                label="Other (or unknown) substance"
                size={4}
                suffix="days"
                model="data.prior_month_substance_use.other_or_unknown_substance"
                validations={{
                  numericality: {
                    greaterThanOrEqualTo: 0,
                    lessThanOrEqualTo: 30,
                    message: 'Please enter an number between 0 and 30',
                  },
                }}
              />
            </FormSection>
          </div>

          <RadioGroup label="History of IV drug use?" model="data.history_of_iv_drug_use" layout="horizontal-dense">
            <Radio label="No" value={false} />
            <Radio label="Yes" value={true} />
          </RadioGroup>

          <Input label="Number of the past alcohol/drug overdoses?" type="number" size={4} model="data.past_overdoses_number" />

          <Input
            label="Number of previous SUD treatments (Including outpatient, residential, and detox episodes)"
            type="number"
            size={4}
            model="data.previous_sud_treatments_number"
          />
        </FormSection>
      </Section>
    </DataFormOverlay>
  )
}

export const FoRSEAdmitSurveyOverlay = withOverlayError(RootFoRSEAdmitSurveyOverlay)
