import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
      staleTime: 60 * 1000, // 1 minute
      retry: false,
    },
  },
})
