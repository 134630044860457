import React from 'react'
import { keyframes } from '@emotion/react'

import Emoji from '../Emoji'

const Errors = ({ emoji, errors }) => {
  if (errors.length === 0) return null

  return (
    <div css={styles}>
      {emoji && <Emoji emoji={emoji} />}
      <span>{errors[0]?.message}</span>
    </div>
  )
}

const animation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(75%);
  }
  100% {
    opacity: 1;
    transform: translateY(100%);
  }
`

const styles = {
  position: 'absolute',
  bottom: 0,
  left: -4,
  background: 'var(--field-background-error)',
  padding: '0.2em 0.4em',
  borderRadius: '0 0 5px 5px',
  zIndex: 0,
  animation: `${animation} 160ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards`,
}

Errors.defaultProps = {
  errors: [],
}

export default Errors
