import React from 'react'
import { Gif } from '@giphy/react-components'
import { GiphyFetch } from '@giphy/js-fetch-api'

import { COLORS } from '../../../theme'

import Grid from '../../Grid'
import Text from '../../Typography/Text'

const GifMessage = ({ message }) => {
  const [gifData, setGifData] = React.useState()
  const gf = new GiphyFetch('0rOxHtz4M7rwDosMI2GJ3YfikSmyTetp')

  const getGif = async () => {
    const { data } = await gf.gif(message.gif)
    setGifData(data)
  }

  React.useEffect(() => {
    if (!gifData) getGif()
  })

  if (!gifData) return null

  return (
    <Grid maxWidth={300} gap={10}>
      <Gif gif={gifData} style={messageContentGifStyles} width="100%" />
      <Text color={COLORS.text}>{message.text}</Text>
    </Grid>
  )
}

const messageContentGifStyles = {
  borderRadius: 3,
  overflow: 'hidden',
}

export default GifMessage
