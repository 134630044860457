import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'
import { usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import ApplicationStatus from '../../../components/Statuses/ApplicationStatus'
import ApplicationReviewStatus from '../../../components/Statuses/ApplicationReviewStatus'
import DataList from '../../../components/DataList'

import { RecordTooltip } from '../components/RecordTooltip'

export const Application = ({ data, client }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <RecordTooltip
      title={`Application`}
      icon="checklist"
      color={tint(0.7, COLORS.green)}
      startDate={data.created_at}
      recordLink={`/applications/${data.id}`}
      client={client}
    >
      <DataList isCompact withPadding labelWidth={120}>
        <DataList.Item label="Status" value={<ApplicationStatus status={data.status} />} />
        <DataList.Item label="Review Status" value={<ApplicationReviewStatus status={data.review_status} />} />
        <DataList.Item label="Last Reminded" value={usDateTime(data.reminded_at, timezone)} />
        <DataList.Item label="Date Added" value={usDateTime(data.created_at, timezone)} />
        <DataList.Item label="Last Edited" value={usDateTime(data.updated_at, timezone)} />
      </DataList>
    </RecordTooltip>
  )
}
