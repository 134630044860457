import React from 'react'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import withSettings from '../../hocs/withSettings'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    id: 'name',
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }) => <TableCell.MainLink to={to(row.original.id)} label={value} />,
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
  {
    width: 220,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
]

const InsuranceClaimsTable = ({ data, to, isLoading, timezone, localStorageKey, batchActionsConfig, titleAfter }: any) => {
  return (
    <Table
      data={data}
      columns={columns(to, timezone)}
      title="Insurance Claims"
      titleAfter={titleAfter}
      icon="insurance"
      isLoading={isLoading}
      emptyDescription="No insurance claims have been added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

InsuranceClaimsTable.defaultProps = {
  localStorageKey: 'insurance_claims',
}

export default withSettings(InsuranceClaimsTable)
