import React from 'react'

import { useCreate } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import Input from '../../components/Forms/Input'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

const DUPLICATE_RECORD_TYPES = {
  appointments: 'Appointments',
  updates: 'Updates',
  treatment_reviews: 'Plan Reviews',
  diagnosis: 'Diagnoses',
  treatment_problems: 'Problems',
  treatment_goals_objectives_interventions: 'Goals, Objectives & Interventions',
  custom_sections: 'Custom Sections',
  documents: 'Files',
  summary: 'Summary',
  signatures: 'Signatures',
}

const RootTreatmentPlanCloseDuplicateOverlay = (props: any) => {
  const { planId, currentPlanName, onSuccess, permissionBase } = props

  const form = React.useRef()

  const { mutateAsync: duplicatePlan, isLoading: isDuplicatingPlan } = useCreate({
    name: ['treatment_plans', planId, 'duplicate'],
    url: `/treatment_plans/${planId}/duplicate`,
    invalidate: ['treatment_plans'],
    invalidateKeys: ['client'],
  })

  const handleDuplicateTreatmentPlan = async () => {
    try {
      const formData = form.current.getFormValue()
      const newPlan = await duplicatePlan(formData)

      if (onSuccess && newPlan?.data) {
        onSuccess(newPlan.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay onClose={props.onClose}>
      <Overlay.Header glyph="copy" title="Duplicate Plan" />

      <Overlay.Content>
        <Form getForm={form}>
          <Section>
            <FormSection layout="vertical">
              <Alert small contrast glyph="info">
                This will close the current plan and create a new plan with the same details. You can select below which items you would
                like to copy over to the new plan.
              </Alert>

              <Input
                vertical
                label="Name"
                model="name"
                defaultValue={currentPlanName}
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <DateTimeInput
                defaultToNow
                model="started_at"
                cypress="start_date_and_time_input"
                label="Start Date and Time"
                validations={{
                  presence: {
                    message: 'Please enter a date and time',
                  },
                }}
              />

              <CheckboxGroup label="Records to duplicate:" layout="vertical-dense">
                {Object.keys(DUPLICATE_RECORD_TYPES).map((key) => {
                  const label = DUPLICATE_RECORD_TYPES[key]

                  return <Checkbox defaultChecked key={key} label={label} model={`records.${key}`} />
                })}
              </CheckboxGroup>

              <ContextShow when="records.signatures" is={true}>
                <CheckboxGroup label="Signature options:" layout="vertical-dense">
                  <Checkbox
                    label="Apply Existing Signatures"
                    description="This will copy over any existing initial and last updates signatures from the current plan to the new plan"
                    model="should_copy_signatures"
                  />
                </CheckboxGroup>
              </ContextShow>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          glyph="copy"
          label="Duplicate Plan"
          type="primary"
          color="green"
          isLoading={isDuplicatingPlan}
          onClick={handleDuplicateTreatmentPlan}
          flex="100 1 auto"
          permission={`${permissionBase}.edit`}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const TreatmentPlanCloseDuplicateOverlay = withOverlayError(RootTreatmentPlanCloseDuplicateOverlay)
