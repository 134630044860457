import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import Notifications from '../../modules/notifications'

import AddressInputs from '../../components/Forms/elements/AddressInputs'
import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Chotomate from '../../components/Chotomate'
import ContextHide from '../../components/Forms/ContextHide'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import DateOfBirthInput from '../../components/Forms/DateOfBirthInput'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Flex from '../../components/Flex'
import Input from '../../components/Forms/Input'
import InsurancePlanTypeSelect from '../../components/Forms/elements/InsurancePlanTypeSelect'
import Link from '../../components/Link'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import PhoneInput from '../../components/Forms/PhoneInput'
import RelationshipToClientCodeSelector from '../../components/Forms/elements/RelationshipToClientCodeSelector'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SexSelect from '../../components/Forms/elements/SexSelect'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'

import RequestEvobOverlay from '../../components/Overlays/actions/RequestEvobOverlay'
import RequestVOBOverlay from '../../components/Overlays/actions/RequestVOBOverlay'

const InsurancePayerSupport = ({ payer }: any) => (
  <Alert type="warning">
    <span>Features supported by {payer.name}:</span>
    <Grid className="!mt-1.5">
      <div>
        <Glyph glyph={payer?.supports?.eligibility ? 'check' : 'cross'} size="1.1rem" /> Electronic Verification of Benefits (eVOB)
      </div>
      <div>
        <Glyph glyph={payer?.supports?.claims ? 'check' : 'cross'} size="1.1rem" /> Claims Submission
      </div>
      <div>
        <Glyph glyph={payer?.supports?.secondary_claims ? 'check' : 'cross'} size="1.1rem" /> Secondary Claims
      </div>
      <div>
        <Glyph glyph={payer?.supports?.claim_status || payer?.supports?.claims_status ? 'check' : 'cross'} size="1.1rem" /> Claim Status
      </div>
    </Grid>
  </Alert>
)

const RootInsurancePolicyOverlay = (props: any) => {
  const match = useRouteMatch()

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    initialData,
    updateAsync,
  } = useOverlay({
    name: 'insurance_policies',
    endpoint: '/insurance_policies',
    invalidate: 'insurance_policies',
    options: props,
    disableParentRequest: true,
  })

  const { data: client }: any = useGet({
    name: ['client', match.params?.resource_id],
    url: `/residents/${match.params?.resource_id}`,
  })

  const { tenant, timezone } = useSettings()
  const [isArchiving, setIsArchiving] = React.useState(false)

  const [insuranceLocalPayer, setInsuranceLocalPayer] = React.useState(null)

  const archivePolicy = async () => {
    setIsArchiving(true)

    try {
      await updateAsync({ status: 'archived' })

      Notifications.send('Insurance Policy Archived', 'positive')
    } catch (error) {
      Notifications.send('Insurance Policy Archive unsuccessful. If the issue persists, please contact us.', 'negative')
    } finally {
      setIsArchiving(false)
    }
  }

  const unArchivePolicy = async () => {
    setIsArchiving(true)

    try {
      await updateAsync({ status: 'active' })

      Notifications.send('Insurance Policy Un-Archived', 'positive')
    } catch (error) {
      Notifications.send('Insurance Policy Un-Archived unsuccessful. If the issue persists, please contact us.', 'negative')
    } finally {
      setIsArchiving(false)
    }
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const isTrialing = tenant?.plan_status === 'trialing'
  const status = data?.status || initialData?.status

  const hasInsurancePayer = data?.insurance_local_payer?.id || initialModel?.insurance_local_payer?.id || insuranceLocalPayer

  const canRunEVOB = data?.insurance_local_payer?.supports?.eligibility
  const isTenantBilledByBehave = tenant?.is_billed

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable || props.showBackdrop} closeOnBackdrop={!isEditable}>
      <Overlay.Header
        testKey="insurance-form"
        icon="insurance"
        title={isNew ? 'Add Insurance Policy' : 'Insurance Policy'}
        subtitle={status && <Status label={status === 'active' ? 'Active' : 'Archived'} color={status === 'active' ? 'green' : 'grey'} />}
      />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/insurance_policies/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Chotomate ready name="insurance_policy_overlay" />

        <Form
          getForm={form}
          timezone={timezone}
          initialModel={{
            ...initialModel,
            ...(isNew && {
              policy_holder: client?.encrypted_data?.address || {},
            }),
          }}
          isEditable={isEditable}
          onValidationUpdate={(valid) => onValidationUpdate(valid)}
          decorate={() => ({
            ...(isNew && { client_id: client?.id }),
          })}
        >
          <Section>
            <Flex stretchChildrenX gap="0.5rem">
              <Select label="Type" model="category" defaultValue="primary">
                <Option label="Primary" value="primary" />
                <Option label="Secondary" value="secondary" />
                <Option label="Tertiary" value="tertiary" />
                <Option label="Unknown" value="unknown" />
              </Select>

              <Select label="Status" model="status" defaultValue="active">
                <Option label="Active" value="active" />
                <Option label="Archived" value="archived" />
              </Select>
            </Flex>
          </Section>

          <Divider />

          <Section title="Insurance Details">
            <FormSection>
              <ObjectSelector
                label="Insurance Payer"
                icon="insurance"
                type="insurance_local_payers"
                model="insurance_local_payer"
                description={
                  <>
                    Add / Import new <Link to="/payers">Insurance Payers</Link> if you cannot find it above
                  </>
                }
                selectTitle={(data) => data.name}
                selectDescription={() => null}
                validations={{
                  presence: {
                    message: 'Please select an insurance payer',
                  },
                }}
                onUpdate={(model: any) => {
                  setInsuranceLocalPayer(model.object)
                }}
              />

              {insuranceLocalPayer && <InsurancePayerSupport payer={insuranceLocalPayer} />}

              <Input label="Plan Name" model="plan" />

              <Input label="Insurance Group ID" model="group_id" />

              <Input
                label="Insurance Member ID (Policy Number)"
                model="member_id"
                validations={{
                  presence: {
                    message: 'Please enter a member ID',
                  },
                }}
              />

              <PhoneInput label="Insurance Phone Number" model="claims_phone_no" />

              <InsurancePlanTypeSelect model="plan_type" />
              <DateInput label="Policy Effective Date" model="effective_at" />
              <DateInput label="Policy Reset Date" model="resets_at" />
              <DateInput label="Policy Termination Date" model="terminates_at" />

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section title="Policy Holder Details">
            <FormSection>
              <Flex gap="1rem" stretchChildrenX>
                <Checkbox
                  defaultChecked
                  label="Client is the Policy Holder"
                  model="is_client_policy_holder"
                  trueIcon="check"
                  falseIcon="cross"
                  falseStyle="none"
                />

                <ContextShow when="is_client_policy_holder" is={true}>
                  <Checkbox label="Client is Homeless" trueIcon="check" falseIcon="cross" falseStyle="none" model="is_homeless" />
                </ContextShow>
              </Flex>

              <ContextShow when="is_client_policy_holder" is={false}>
                <Input
                  label="First Name"
                  model="policy_holder.first_name"
                  validations={{
                    presence: {
                      message: 'Please enter a first name',
                    },
                  }}
                />

                <Input label="Middle Name" model="policy_holder.middle_name" />

                <Input
                  label="Last Name"
                  model="policy_holder.last_name"
                  validations={{
                    presence: {
                      message: 'Please enter a last name',
                    },
                  }}
                />

                <DateOfBirthInput
                  label="Date of Birth"
                  model="policy_holder.dob"
                  validations={{
                    presence: {
                      message: 'Please enter a Date of Birth',
                    },
                  }}
                />
                <SexSelect isRequired label="Sex" model="policy_holder.sex" />

                <RelationshipToClientCodeSelector label="Relationship to Client" model="policy_holder.relationship_to_customer" />

                <Input type="email" label="Email" model="policy_holder.email" />

                <Input type="tel" label="Phone Number" model="policy_holder.phone_no" />
              </ContextShow>

              <ContextShow when="is_client_policy_holder" is={true}>
                <Alert contrast type="warning" className="!mt-4">
                  Please confirm that the Client Address is correct
                </Alert>
              </ContextShow>

              <ContextShow when="is_homeless" is={false}>
                <AddressInputs isRequired model="policy_holder" />
              </ContextShow>

              <ContextShow when="is_client_policy_holder" is={false}>
                <AddressInputs isRequired model="policy_holder" />
              </ContextShow>
            </FormSection>
          </Section>

          <Divider />

          <Section title="Attachments">
            <Attachments label="Attachments" model="documents" labelAlign="top" labelJustify="top" />
          </Section>

          {!isNew && data && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="insurance_policies.create"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            {!canRunEVOB && (
              <Alert contrast type="warning" glyph="info">
                <p css={{ margin: 0, fontWeight: 600 }}>Set Up NPI Details to Run eVOBs</p>
                <p css={{ margin: '0.2em 0 0' }}>
                  Go to <Link to="/settings/providers">Settings → Providers</Link> to set up the NPI details.
                </p>
              </Alert>
            )}

            {hasInsurancePayer && canRunEVOB && (
              <div css={{ flex: '1 1 400px !important' }}>
                <SummonOverlay overlay={<RequestEvobOverlay insurancePolicy={data} />}>
                  <Button label="Request New eVOB" color="green" glyph="circle_tick" permission="insurance_evobs.create" />
                </SummonOverlay>
              </div>
            )}

            {isTenantBilledByBehave && (
              <div css={{ flex: '1 1 400px !important' }}>
                <SummonOverlay overlay={<RequestVOBOverlay client={client} />}>
                  <Button label="Request Full VOB" glyph="behave_health" color="green" />
                </SummonOverlay>
              </div>
            )}

            <Button
              glyph="edit"
              label="Edit Insurance Policy"
              type="default"
              isDisabled={props.loading}
              onClick={edit}
              flex="100 1 auto"
              permission="insurance_policies.edit"
            />

            {data?.status === 'archived' ? (
              <Button label="Un-Archive" glyph="delete" type="default" color="gray" onClick={unArchivePolicy} isLoading={isArchiving} />
            ) : (
              <Button label="Archive" glyph="delete" type="default" color="gray" onClick={archivePolicy} isLoading={isArchiving} />
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const InsurancePolicyOverlay = withOverlayError(RootInsurancePolicyOverlay)
