import React from 'react'
import { Route } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { BehaveFileOverlay } from '@behavehealth/constructs/BehaveFiles/BehaveFileOverlay'
import { Button, Page, Grid, Uploader, DnDUploader, Card, CardContent, TipAlert } from '@behavehealth/components'
import { FilesDataTable } from '@behavehealth/constructs/Files/FilesDataTable'
import { Tenant } from '@behavehealth/declarations'

const pageConfig = {
  feature: 'files',
  title: 'Behave Files',
}

const BehaveFiles = () => {
  return (
    <>
      <BehaveFilesIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<BehaveFileOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const BehaveFilesIndex: React.FC = () => {
  const tenant: Tenant = useSelector((state: any) => state.me.tenant)

  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['facilities', tenant.id, 'files'],
    endpoint: `/facilities/${tenant.id}/documents`,
    updateDeleteEndpoint: '/documents',
    enabled: !!tenant.id,
    params: {
      category: 'behave',
    },
    localStorageKey: 'files_v1',
  })

  return (
    <Page
      breakpoint="0"
      actions={
        <Uploader category="behave" resource={tenant} queryKey={tableProps.queryKey}>
          <Button type="primary" glyph="upload" label="Upload Files" />
        </Uploader>
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        <TipAlert contrast type="warning" localStorageKey="settings_behave_files">
          Files uploaded here are only viewable by Behave only
        </TipAlert>

        <Card>
          <FilesDataTable
            {...tableProps}
            title="Behave Files"
            batchActionsConfig={[
              {
                type: 'delete',
                permission: isBehave,
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />

          <CardContent>
            <DnDUploader isEditable category="behave" resource={tenant} isDisabled={false} queryKey={tableProps.queryKey} />
          </CardContent>
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(BehaveFiles, pageConfig))
