import React from 'react'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { countWord, usDate, usTime } from '../../utils/functions'
import { useUpdate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks'

import CheckButton from '../../components/CheckButton'
import DonutChart from '../../components/DonutChart'
import EventStatus from '../../components/Statuses/EventStatus'
import Flex from '../../components/Flex'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import Status from '../../components/Status'
import TaskStatus from '../../components/Statuses/TaskStatus'

import { FILTERS } from '../Filters/config'
import { ProfileCellInner } from '../../components/DataTable/cells/ProfileCell'

const STATUS_COLORS: any = {
  done: 'green',
  due_today: 'orange',
  to_do: 'blue',
  overdue: 'red',
}

const DISPLAY_STATUSES: any = {
  done: 'Completed',
  due_today: 'Due Today',
  to_do: 'To Do',
  overdue: 'Overdue',
}

const TASK_TYPES: any = {
  one_to_one: 'One-to-One',
  group_meeting: 'Group Task',
}

const TaskCheckbox = ({ task }: any) => {
  const status = task?.display_status

  const { mutate, isLoading } = useUpdate({
    name: ['todo', task.id],
    url: `/events/${task.id}`,
    invalidate: 'todos',
  })

  const toggleTask = () => {
    mutate({ action_status: task.action_status === 'done' ? 'due' : 'done' })
  }

  return (
    <Flex centerY nowrap className="!min-h-[28px] pl-1">
      <CheckButton isCompleted={task.action_status === 'done'} isLoading={isLoading} onClick={toggleTask} className="mr-3" />
      <Status color={STATUS_COLORS[status]} label={DISPLAY_STATUSES[status]} />
    </Flex>
  )
}

export const TodosDataTable = (props: any) => {
  const { to } = props

  const { timezone, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        id: 'title',
        model: 'title',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell as={props.mainLinkAs} id={data.id} value={data.title} to={to?.(data)} />,
      },
      {
        width: 140,
        id: '__display_status',
        model: 'display_status',
        title: 'Status',
        hoverExpand: false,
        formatValue: ({ data }: any) => <TaskCheckbox task={data} />,
      },
      {
        width: 170,
        id: 'subtasks_no',
        model: 'subtasks_no',
        title: 'Tasks',
        formatValue: ({ data, value }: any) => {
          if (value === 0) return null

          return (
            <div className="flex items-center justify-start">
              <DonutChart
                value={(data.subtasks_completed_no / data.subtasks_no) * 100}
                size={16}
                strokeWidth={3}
                labelSize="0px"
                background={COLORS.divider}
              />
              <div className="ml-1.5">
                {data.subtasks_completed_no} / {data?.subtasks_no} Completed
              </div>
            </div>
          )
        },
      },
      {
        width: 90,
        id: '__color',
        model: 'color',
        title: 'Color',
        type: 'color',
      },
      {
        width: 180,
        model: 'residents',
        title: 'Clients',
        hoverExpand: true,
        formatValue: ({ data, value }: any) => {
          // Dynamic Import
          if (size(value) === 0) {
            if (data?.clients_import_type === 'dynamic') {
              const programs = data?.imported_programs?.map?.((o: any) => o.name) || []
              const properties = data?.imported_houses?.map?.((o: any) => o.name)
              const imported = programs?.concat?.(properties)?.join?.(', ')

              return `Dynamically imported from ${imported}, on the Due Date`
            }

            if (data?.meeting_type === 'one_to_one') {
              return <ProfileCellInner value={data?.resident} />
            } else {
              return null
            }
          }

          // Clients
          return (
            <div css={STYLES.clientsCell}>
              <div className="hide-hover">{countWord('Client', size(value))}</div>

              <Flex gap="0.5rem" className="show-hover">
                {data?.residents?.map?.((o: any) => (
                  <ProfileCellInner value={o} />
                ))}
              </Flex>
            </div>
          )
        },
      },
      {
        width: 160,
        id: 'started_at',
        model: 'started_at',
        title: 'Due Date',
        formatValue: ({ value }: any) => usDate(value, timezone),
      },
      {
        width: 120,
        id: '_due_by',
        model: 'is_all_day',
        title: 'Due By',
        formatValue: ({ value, data }: any) => {
          if (value === true) return 'All-day task'

          return usTime(data?.start_time, timezone)
        },
      },
      {
        width: 110,
        id: 'meeting_type',
        model: 'meeting_type',
        title: 'Type',
        formatValue: ({ value }: any) => TASK_TYPES[value],
      },
      {
        width: 110,
        id: 'meeting_place',
        model: 'meeting_place',
        title: 'Meeting Place',
        type: 'title',
      },
      {
        width: 160,
        id: 'sharing_type',
        model: 'sharing_type',
        title: 'Permissions',
        formatValue: ({ value }: any) => {
          return <TaskStatus status={value} />
        },
        // filterOptions: [
        //   { label: 'Staff Only', value: 'internal' },
        //   { label: 'Client Portal', value: 'client' },
        // ],
      },
      {
        width: 200,
        id: 'public_description',
        model: 'public_description',
        title: 'Public Description',
        type: 'rich_text',
        // NOTE @Andrei: use `public_description` to show html format
      },
      {
        id: 'created_at',
        model: 'created_at',
        title: 'Date Created',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="To-Do's"
      testKey="to_dos_data_table"
      icon="tasks"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      {...props}
    />
  )
}

const STYLES = {
  clientsCell: {
    '&:hover.hide-hover': {
      display: 'none',
    },

    '.show-hover': {
      display: 'none',
    },

    '&:hover .show-hover': {
      display: 'flex',
    },
  },
}

const FILTERS_CONFIG = {
  ...FILTERS.todos,

  display_status: {
    label: 'Status',
    type: 'multi_select',
    options: Object.keys(DISPLAY_STATUSES).map((key: string) => ({
      label: DISPLAY_STATUSES[key],
      value: key,
    })),
  },

  started_at: {
    label: 'Due Date',
    type: 'date_time',
    glyph: 'date',
  },

  public_description: {
    label: 'Public Description',
    type: 'string',
  },
}
