import React from 'react'
import { isDefined } from '../../../utils/functions'
import get from 'lodash/get'
import size from 'lodash/size'

import NoInternet from '../../Alerts/NoInternet'
import Overlay from '../../Overlay'
import OverlayLoader from '../../OverlayLoader'

import { setIdleSaving } from '../../../actions/common'
import { TIMEZONE } from '../../../utils/constants'

import { apiCreate, apiUpdate, apiDelete } from '../../../modules/api'
import { clientSelector, referenceSelector } from '../../../reducers/selectors'

export class OverlayBase extends React.Component {
  constructor(props) {
    super(props)

    let dataID = props.dataID
    let isNew = isDefined(dataID) ? dataID === 'new' : true
    let isRequired = props.isRequired || false

    if (props.type === 'route') {
      dataID = props.match?.params?.id
      isNew = isDefined(dataID) ? dataID === 'new' : true
      isRequired = props.location?.isRequired || false
    }

    const anonymize = props.location && isDefined(props.location.anonymize) ? props.location.anonymize : props.anonymize

    this.state = {
      id: dataID,
      params: props.initialData || (props.location && props.location.data) || (props.location?.state && props.location?.state?.data),
      anonymize: anonymize,
      isValid: false,
      isInvalid: true,
      isRequired: isRequired,
      $editable: isNew,
      $new: isNew,
    }

    this.form = React.createRef()
    this.isPortal = process.env.BH_APP === 'portal'
    this.skipRecordChecks = false
  }

  /*
    LIFECYCLE
  */
  shouldComponentUpdate = (nextProps) => {
    if (this.props.idle && nextProps.idle) return false
    return true
  }

  componentDidUpdate = (prevProps) => {
    // if (!prevProps.idle && this.props.idle) this.onIdle()

    this.props.updateHotkeys()
  }

  /*
    CUSTOM FUNCTIONS
  */
  onValidationUpdate = (valid: any) => this.setState({ isValid: valid, isInvalid: !valid })

  onIdle = async () => {
    const { $new, isValid } = this.state
    const { match, endpoint, idleSaving, skipReduxBucket } = this.props

    if (isValid) {
      idleSaving()
      let model = this.form.current.getFormValue()

      if ($new)
        await apiCreate({
          name: skipReduxBucket ? null : endpoint,
          url: `/${endpoint}`,
          params: model,
        })
      else
        await apiUpdate({
          name: skipReduxBucket ? null : endpoint,
          url: `/${endpoint}/${match?.params?.id}`,
          params: model,
        })
    }
  }

  close = () => {
    if (this.props.type === 'summon') {
      if (this.props.onClose) this.props.onClose()
    } else if (this.props.type === 'route') {
      if (this.props.location.parent) {
        this.props.history.push(this.props.location.parent.url)
      } else {
        const path = this.props.location.pathname
        this.props.history.push(path.substr(0, path.lastIndexOf('/')))
      }
    }
  }

  edit = () => this.setState({ $editable: true })

  cancel = () => {
    this.form.current.resetForm()
    this.setState({ $editable: false })
  }

  save = async () => {
    const { $new } = this.state
    const { match, endpoint, type, history, location, skipReduxBucket } = this.props

    let request = null
    let model = this.form.current.getFormValue()

    if ($new)
      request = await apiCreate({
        name: skipReduxBucket ? null : endpoint,
        url: `/${endpoint}`,
        params: model,
      })
    else
      request = await apiUpdate({
        name: skipReduxBucket ? null : endpoint,
        url: `/${endpoint}/${this.state.id || match?.params?.id}`,
        params: model,
      })

    await this.onSaveSuccessful()

    if (this.props.onSaveSuccessful) this.props.onSaveSuccessful(request.data.data)

    // if (type === 'summon' && $new) this.close()
    // else if (type === 'route' && $new) {
    //   history.push({
    //     pathname: `${request.data?.data?.id}`,
    //     parent: location.parent,
    //   })
    // }

    this.setState({ $editable: false })
    this.close()
  }

  delete = async () => {
    const { match, endpoint, skipReduxBucket } = this.props
    await apiDelete({
      name: skipReduxBucket ? null : endpoint,
      url: `/${endpoint}/${this.state.id || match?.params?.id}`,
    })

    await this.onDeleteSuccessful()

    this.close()
  }

  highlightRequired = () => {
    this.form.current.validate()
  }

  onSaveSuccessful = async () => null
  onDeleteSuccessful = async () => null

  /*
    RENDER
  */
  renderHeader: any = () => null
  renderContent: any = () => null
  renderFooter: any = () => null

  render() {
    const { $editable, $new, params } = this.state
    const { online, showBackdrop, record } = this.props

    const data = { ...params, ...record }
    const useBackdrop = isDefined(showBackdrop) ? showBackdrop : $editable || $new

    if (!this.skipRecordChecks && !$new && size(data) === 0) {
      return <OverlayLoader position={this.props.position} showBackdrop={useBackdrop} />
    }

    return (
      <Overlay
        testKey={this.props.testKey}
        position={this.props.position}
        onClose={this.close}
        showBackdrop={useBackdrop}
        isDirty={$editable}
      >
        {this.renderHeader()}
        {this.renderContent()}
        {online && this.renderFooter()}
        {!online && (
          <Overlay.Footer>
            <NoInternet />
          </Overlay.Footer>
        )}
      </Overlay>
    )
  }
}

OverlayBase.defaultProps = {
  type: 'route',
  position: 'right',
  anonymize: false,
  updateHotkeys: () => {},
}

export const defaultMapDispatchToProps = (dispatch) => ({
  idleSaving: () => dispatch(setIdleSaving(true)),
})

export const defaultMapStateToProps = (state, match, endpoint) => {
  return {
    tenant: state.me?.tenant,
    current: clientSelector(state, match),
    user: state.me?.user,
    loading: state.data[endpoint]?.loading,
    reference: referenceSelector(state, match),
    record: get(state, `data.${endpoint}.data.${match?.params?.id || state?.id}`, {}),
    online: state.common?.global?.online,
    idle: state.common?.global.idle,
    timezone: state.me?.tenant?.timezone || TIMEZONE,
    endpoint: endpoint,
  }
}

export const defaultHotkeys = [
  {
    key: 'esc',
    description: 'Close Overlay',
    action: (overlay) => overlay.close(),
  },
]
