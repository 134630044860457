import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { titleCase } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { withFormContext } from '../../components/Forms/context'

import Icon from '../../components/Icon'
import Tooltip from '../../components/Tooltip'

import { VENDOR_QUICK_LINKS } from './config'

const handleClick = (event: any) => {
  event.stopPropagation()
}

const RootVendorQuickLinks = (props: any) => {
  const { value, vendors, isEditable } = props

  const { isBehave, isHQApp } = useSettings()

  if (!isBehave || !isHQApp) return null

  if (size(vendors) === 0 || (!isEditable && !value)) return null

  return (
    <div className="flex flex-nowrap">
      {vendors.map((vendor: any) => {
        const config = VENDOR_QUICK_LINKS?.[vendor]

        if (!config) return null

        const link = config.getLink(value)

        return (
          <Tooltip show={!!value} content={`Search in ${titleCase(vendor)}`}>
            <a
              key={vendor}
              href={value ? link : undefined}
              target="_blank"
              className={clsx('ml-2', !value && 'pointer-events-none')}
              onClick={handleClick}
            >
              <Icon icon={config.icon} size={14} className={clsx(!value && 'grayscale opacity-0')} />
            </a>
          </Tooltip>
        )
      })}
    </div>
  )
}

export const VendorQuickLinks = withFormContext(RootVendorQuickLinks)
