import React from 'react'
import { useLocation } from 'react-router-dom'

import { H4 } from '../../components/Typography'
import Alert from '../../components/Alert'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'
import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const SCORING = {
  4: 'minimal symptom severity',
  8: 'mild symptom severity',
  12: 'medium symptom severity',
  26: 'high symptom severity',
}

const INITIAL_STATE = {
  stomach_pain: 0,
  back_pain: 0,
  pain_in_arms_legs_or_joints: 0,
  headaches: 0,
  chest_pain: 0,
  dizziness: 0,
  fainting_spells: 0,
  feeling_heart_pound_or_race: 0,
  shortness_of_breath: 0,
  constipation_loose_bowels_or_diarrhea: 0,
  nausea_gas_or_indigestion: 0,
  feeling_tired_or_having_low_energy: 0,
  trouble_sleeping: 0,
}

const RootPHQ15Overlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="PHQ-15 Somatic / Physical Symptoms"
      category="clinical_measurement"
      subcategory="phq15"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Summary"
        headingType="h2"
        scrollview={{
          id: 'summary',
          name: 'Summary',
        }}
        commentsModel="data.summary"
      >
        <Alert glyph="info">Recommended frequency: Every 2 weeks</Alert>
        <p>
          This measure has been adapted from the Patient Health Questionnaire Physical Symptoms (PHQ-15) by the American Psychiatric
          Association ("APA") for use by children and adolescents.The PHQ-15 is a well-validated scale that can be used to screen for
          somatization concerns and monitor symptom severity.
        </p>

        <p>
          The APA version is identical to the 15-item version, although two items have been removed. Each item evaluates the degree to which
          an individual has been bothered by a specic somatic concern during the past week on a 3-point Likert scale. Higher scores on the
          PHQ-15 correspond to increased functional impairment. The self-report scale can be used by individuals between the ages of 11 to
          17. A parent/caregiver version is also available and can be completed by individuals whose child is between the ages of 6 to 17.
        </p>
      </Section>

      <Divider />

      <Section
        title="Psychometric Properties"
        headingType="h2"
        scrollview={{
          id: 'psychometric_properties',
          name: 'Psychometric Properties',
        }}
        commentsModel="data.psychometric_properties"
      >
        <p>
          The PHQ-15 demonstrates good internal consistency (α = .80) when used by adults. It also has good test-retest reliability and
          convergent validity with the Beck Depression Inventory (BDI) and General Health Questionnaire12 (GHQ-12).
        </p>
      </Section>

      <Divider />

      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <PHQ15Questionnaire />
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <ol>
          <li>
            <a target="_blank" href="https://www.psychiatry.org/psychiatrists/practice/dsm/educational-resources/assessment-measures">
              https://www.psychiatry.org/psychiatrists/practice/dsm/educational-resources/assessment-measures
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.ncbi.nlm.nih.gov/pubmed/11914441">
              https://www.ncbi.nlm.nih.gov/pubmed/11914441
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3278967/">
              https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3278967/
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.ncbi.nlm.nih.gov/pubmed/19996228">
              https://www.ncbi.nlm.nih.gov/pubmed/19996228
            </a>
          </li>
        </ol>

        <p>
          <b>Copyright:</b> Developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke and colleagues, with an educational grant
          from Pzer Inc.
        </p>
      </Section>
    </DataFormOverlay>
  )
}

const PHQ15Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <H4>Over the last two weeks, how often have you been bothered by any of the following problems?</H4>

        <RadioGroup
          label="Stomach pain"
          model="data.questionnaire.stomach_pain"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, stomach_pain: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Back pain"
          model="data.questionnaire.back_pain"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, back_pain: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Pain in your arms, legs or joints (knees, hips, etc.)"
          model="data.questionnaire.pain_in_arms_legs_or_joints"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, pain_in_arms_legs_or_joints: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Headaches"
          model="data.questionnaire.headaches"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, headaches: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Chest pain"
          model="data.questionnaire.chest_pain"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, chest_pain: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Dizziness"
          model="data.questionnaire.dizziness"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, dizziness: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Fainting spells"
          model="data.questionnaire.fainting_spells"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, fainting_spells: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Feeling heart pound or race"
          model="data.questionnaire.feeling_heart_pound_or_race"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, feeling_heart_pound_or_race: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Shortness of breath"
          model="data.questionnaire.shortness_of_breath"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, shortness_of_breath: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Constipation, loose bowels or diarrhea"
          model="data.questionnaire.constipation_loose_bowels_or_diarrhea"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, constipation_loose_bowels_or_diarrhea: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Nausea, gas or indigestion"
          model="data.questionnaire.nausea_gas_or_indigestion"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, nausea_gas_or_indigestion: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Feeling tired or having low energy"
          model="data.questionnaire.feeling_tired_or_having_low_energy"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, feeling_tired_or_having_low_energy: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>

        <RadioGroup
          label="Trouble sleeping"
          model="data.questionnaire.trouble_sleeping"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, trouble_sleeping: state.value }))}
        >
          <Radio label="0 = Not bothered at all" value={0} />
          <Radio label="1 = Bothered a little" value={1} />
          <Radio label="2 = Bothered a lot" value={2} />
        </RadioGroup>
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Score
            title="Final Score"
            description={`Severity of somatic symptoms is determined by assigning 0-2 to the response categories of "not bothered at all", "bothered a little", and "bothered a lot", respectively. The sum of all 13 items represents the severity of symptoms as determined by the youth/adolescent or parent/caretaker. Total scores range from 0-26, higher scores indicating a greater severity of somatic symptoms. The table below indicates the severity ranges for an individual's scores.`}
            finalScore={finalScore}
            scoring={SCORING}
          />
        </>
      )}
    </>
  )
}

export const PHQ15Overlay = withOverlayError(RootPHQ15Overlay)
