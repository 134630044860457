import React from 'react'
import { useRouteMatch, useHistory, useParams } from 'react-router-dom'
import { geolocated } from 'react-geolocated'

import { address, isDefined, isWebGLAvailable } from '../../utils/functions'
import { useGet, invalidateQueries } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import Notifications from '../../modules/notifications'

import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Map from '../../components/Map'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'
import RadioGroup from '../../components/Forms/RadioGroup'
import Radio from '../../components/Forms/Radio'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'

import {
  SERVICE_EPISODE_CATEGORIES,
  SERVICE_EPISODE_LOC_CATEGORIES,
  SERVICE_EPISODE_LOC_SUBCATEGORIES,
} from '@behavehealth/utils/constants'

export const RootServiceEpisodeOverlay = (props: any) => {
  const match = useRouteMatch()
  const history = useHistory()

  const { resource_id }: any = useParams()
  const clientId = props.clientId || resource_id

  const {
    cancel,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isEditable,
    isInvalid,
    isLoading,
    isDeleting,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    close,
  } = useOverlay({
    name: 'service-episode',
    endpoint: '/service_episodes',
    invalidate: 'service-episodes',
    invalidateKeys: [['client', clientId, 'service-episodes']],
    options: props,
    skipClose: true,
    closeOnSave: false,
    onSaveSuccessful: (apiData: any) => {
      if (!apiData) return

      if (isNew && match.url.includes(`/new`)) {
        const newURL = match.url.replace(`/new`, `/${apiData.id}`)
        history.replace(newURL)
      }
    },
  })

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
  })

  const [episodeType, setEpisodeType] = React.useState('level_of_care')
  const { timezone, tenant, isPortal } = useSettings()
  const isTrialing = tenant?.plan_status === 'trialing'

  if (isOverlayLoading) return <OverlayLoader position="right" showBackdrop={props.showBackdrop || isEditable} />

  return (
    <Overlay onClose={close} showBackdrop={props.showBackdrop || isEditable} closeOnBackdrop={!isEditable}>
      <Overlay.Header title={`${isNew ? 'Add Service Episode' : 'Service Episode'}`} icon="treatment_data" />

      {!isNew && !isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/service_episodes/${data?.id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          useLocalModel
          isEditable={isEditable}
          getForm={form}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          initialModel={initialModel}
          linked={{
            client_id: client?.id || clientId,
          }}
        >
          <Section>
            <FormSection maxWidth="100%">
              <RadioGroup
                label="Episode Type"
                model="category"
                layout="horizontal-dense"
                defaultValue="level_of_care"
                onUpdate={(state: any) => setEpisodeType(state.value)}
              >
                {Object.entries(SERVICE_EPISODE_CATEGORIES).map(([key, value]) => (
                  <Radio label={value} value={key} key={key} />
                ))}
              </RadioGroup>

              <Flex gap={16} horizontal stretchChildrenX>
                <DateTimeInput
                  defaultToNow
                  model="started_at"
                  label="Episode From"
                  validations={{
                    presence: {
                      message: 'Please enter a date and time',
                    },
                  }}
                />

                <DateTimeInput
                  model="ended_at"
                  label="Episode Until"
                  validations={{
                    presence: {
                      message: 'Please enter a date and time',
                    },
                  }}
                />
              </Flex>

              <Select label="Status" model="status">
                <Option label="Active" value="active" />
                <Option label="Inactive" value="inactive" />
              </Select>

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter an episode name',
                  },
                }}
              />

              {episodeType === 'level_of_care' && (
                <>
                  <Select label="Level of Care Category" model="loc_category" defaultValue="asam_substance_use_disorder_treatment">
                    {Object.entries(SERVICE_EPISODE_LOC_CATEGORIES).map(([key, value]) => (
                      <Option label={value} value={key} key={key} />
                    ))}
                  </Select>

                  <ContextShow when="loc_category" is="asam_substance_use_disorder_treatment">
                    <Select label="Level of Care" model="loc_subcategory" defaultValue="1_0_outpatient_services">
                      {Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['asam_substance_use_disorder_treatment']).map(([key, value]) => (
                        <Option label={value} value={key} key={key} />
                      ))}
                    </Select>
                  </ContextShow>

                  <ContextShow when="loc_category" is="asam_substance_use_disorder_treatment_old">
                    <Select label="Level of Care" model="loc_subcategory" defaultValue="level_0_5">
                      {Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['asam_substance_use_disorder_treatment_old']).map(
                        ([key, value]) => (
                          <Option label={value} value={key} key={key} />
                        ),
                      )}
                    </Select>
                  </ContextShow>

                  <ContextShow when="loc_category" is="mental_health">
                    <Select label="Level of Care" model="loc_subcategory" defaultValue="outpatient_treatment">
                      {Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['mental_health']).map(([key, value]) => (
                        <Option label={value} value={key} key={key} />
                      ))}
                    </Select>
                  </ContextShow>

                  <ContextShow when="loc_category" is="narr_levels_of_support">
                    <Select label="Level of Care" model="loc_subcategory" defaultValue="level_1">
                      {Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['narr_levels_of_support']).map(([key, value]) => (
                        <Option label={value} value={key} key={key} />
                      ))}
                    </Select>
                  </ContextShow>
                </>
              )}

              <Textarea model="notes" label="Notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      {!isPortal && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                glyph="check"
                label="Save Episode"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 240px"
                permission="agreements.create"
              />

              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                label="Edit"
                glyph="edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="agreements.edit"
              />

              <DeleteDialog
                title="Delete Episode?"
                message="Are you sure you want to delete this service episode? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="agreements.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const ServiceEpisodeOverlay = withOverlayError(RootServiceEpisodeOverlay)
