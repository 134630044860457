import React from 'react'

import Section from '../../../../Section'
import Card from '../../../../Card'
import Status from '../../../../Status'
import Table from '../../../../Table/Table'
import Flex from '../../../../Flex'
import Grid from '../../../../Grid'
import Alert from '../../../../Alert'

import { isPast, usDateTime, niceAmount, encodeObjectToURL } from '../../../../../utils/functions'
import { apiGet } from '../../../../../modules/api'

const columns = (timezone: string) => [
  {
    id: 'status',
    Header: 'Status',
    accessor: 'transactioned_at',
    Cell: ({ value }: any) => {
      const status = isPast(value, timezone) ? 'charged' : 'future'
      return <Status label={status === 'charged' ? 'Charged' : 'Planned'} color={status === 'charged' ? 'green' : 'yellow'} />
    },
  },
  {
    id: 'date',
    Header: 'Due Date',
    accessor: 'transactioned_at',
    width: 220,
    Cell: ({ value }: any) => usDateTime(value, timezone),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    width: 120,
    Cell: ({ value }: any) => niceAmount(value),
  },
]

const PreviewRecurringChargesSection = (props: any) => {
  const { from, endIn, serviceID, amount, title, before, timezone, showDescription, onUpdate, onClear, usingCustomFrequency } = props

  const [loading, setLoading] = React.useState(false)
  const [dates, setDates] = React.useState([])

  React.useEffect(() => {
    const getRecurringCharges = async (serviceID, from, endIn) => {
      try {
        setLoading(true)

        const encoded = encodeObjectToURL({
          service_id: serviceID,
          from: from,
          end_in: endIn,
        })

        const result = await apiGet({
          url: `/apps/ledger/dates_for_recurring_service?${encoded}`,
        })

        // update state
        setDates(result.data.dates)

        if (onUpdate) onUpdate(result.data.dates)

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (from) getRecurringCharges(serviceID, from, endIn)

    return () => {
      if (onClear) onClear()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceID, from, endIn])

  const description = (
    <Grid gap={8}>
      <div>
        <Status label="Charged" color="green" css={{ display: 'inline-block', lineHeight: '1.3rem' }} /> Charges will be processed and added
        to the Current Balance immediately
      </div>
      <div>
        <Status label="Planned" color="yellow" css={{ display: 'inline-block', lineHeight: '1.3rem' }} /> Charges will be added with a
        Future Status and will be processed on the Due Date
      </div>
    </Grid>
  )

  return (
    <Section title={title} description={!usingCustomFrequency && showDescription && description} isLoading={loading}>
      {before}
      <Card>
        {usingCustomFrequency ? (
          <Alert type="warning">
            The preview is <u>not yet</u> available when Custom Frequency is ON. Recurring Charges do get created on Save.
          </Alert>
        ) : (
          <Table
            testKey="preview_recurring_charges_table"
            columns={columns(timezone)}
            showSettings={false}
            pageSize={10}
            data={dates?.map((date: string, idx: number) => ({
              id: idx,
              transactioned_at: date,
              amount: amount,
            }))}
          />
        )}
      </Card>
    </Section>
  )
}

PreviewRecurringChargesSection.defaultProps = {
  title: 'Preview Charges',
  showDescription: true,
}

export default PreviewRecurringChargesSection
