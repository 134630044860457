import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import startCase from 'lodash/startCase'

import { age } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import BedSelector from '../../components/Forms/BedSelector'
import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

const RootBedOccupancyOverlay = (props: any) => {
  const { url } = useRouteMatch()
  const { property_id }: any = useParams()
  const { showMoveBeds } = props

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialData,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'occupancy',
    endpoint: '/occupancies',
    invalidateKeys: [
      'bed-management-timeline',
      'bed-management-board',
      'clients-unassigned-stats',
      'location',
      'locations',
      'bed-management-timeline',
      'bed-management-board',
      'rooms-with-residents',
      'occupancy',
      'occupancies',
      'client-to-move',
      ['clients', { unassigned: true }],
    ],
    options: props,
    isEditable: props.isEditable,
  })

  const { timezone } = useSettings()

  const [fromDate, setFromDate] = React.useState(null)
  const [untilDate, setUntilDate] = React.useState(null)

  if (isOverlayLoading) {
    return <OverlayLoader position="center" />
  }

  return (
    <Overlay showBackdrop position="center" onClose={close}>
      <Overlay.Header icon={ICONS.bed_management} title="Bed Assignment" />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
        >
          {({ data: formData }) => {
            const fromDateLabel = formData.status === 'reserved' ? 'Reserve Bed From' : 'Occupy Bed From'
            const untilDateLabel = formData.status === 'reserved' ? 'Bed Reserved Until' : 'Bed Occupied Until'
            const periodLabel = formData.status === 'reserved' ? 'Bed Reserved Until…' : 'Bed Occupied Until…'

            return (
              <Section>
                <Grid gap="1rem">
                  <ObjectSelector
                    isPolymorphic
                    isEditable={isNew}
                    label="Client"
                    type="applicants_and_clients"
                    model="reference"
                    icon="clients"
                    selectTitle={(data: any) => data.name}
                    selectDescription={(data: any) => `${startCase(data.sex) || '–'}, ${age(data.dob)} y/o, #${data.behave_id}`}
                    validations={{
                      presence: {
                        message: 'Please select a client',
                      },
                    }}
                  />

                  <RadioGroup
                    label="Bed Assignment Type"
                    model="status"
                    validations={{
                      presence: {
                        message: 'Please select a status',
                      },
                    }}
                    layout="horizontal-dense"
                  >
                    <Radio label="Bed Reserved" value="reserved" />
                    <Radio label="Bed Occupied" value="occupied" />
                  </RadioGroup>

                  <DateTimeInput
                    defaultToNow
                    label={fromDateLabel}
                    model="started_at"
                    value={fromDate}
                    onUpdate={({ value }) => {
                      setFromDate(value)
                    }}
                    validations={{
                      presence: {
                        message: 'Please enter a start date',
                      },
                    }}
                  />

                  <RadioGroup label={periodLabel} model="period_type" layout="horizontal-dense" defaultValue="indefinite">
                    <Radio label="Client Discharge" value="indefinite" />
                    <Radio label="Date" value="set_datetime" />
                  </RadioGroup>

                  <ContextShow when="period_type" is="set_datetime">
                    <DateTimeInput
                      defaultToTomorrow
                      label={untilDateLabel}
                      model="ended_at"
                      value={untilDate}
                      onUpdate={({ value }: any) => {
                        setUntilDate(value)
                      }}
                      validations={{
                        presence: {
                          message: 'Please enter an end date',
                        },
                      }}
                    />
                  </ContextShow>

                  <BedSelector
                    quickFinderEnabled
                    clientId={formData?.reference_id}
                    fromDate={fromDate}
                    fromLabel={fromDateLabel}
                    setFromDate={setFromDate}
                    setUntilDate={setUntilDate}
                    untilDate={untilDate}
                    untilLabel={untilDateLabel}
                    label="Bed"
                    model="place"
                    validations={{
                      presence: {
                        message: 'Please select a bed',
                      },
                    }}
                  />

                  {showMoveBeds && (
                    <Alert contrast small glyph="info">
                      <div>
                        <b>Move Beds</b> is used to move a client from their current bed to another. This will create a new bed assignment.
                      </div>

                      <div className="!mt-2">
                        <b>Edit Bed Assignments</b> is used to edit the dates of this particular bed assignment.
                      </div>
                    </Alert>
                  )}
                </Grid>
              </Section>
            )
          }}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            {showMoveBeds && (
              <Button
                glyph="swap"
                label="Move Beds"
                type="primary"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 500px"
                link={url && `${url.substr(0, url.lastIndexOf('/'))}/move-beds`}
                permission="bed_management.actions.move_client"
              />
            )}

            <Button
              glyph="edit"
              label="Edit Bed Assignment"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="10 1 auto"
              permission="bed_management.edit"
            />

            <DeleteDialog
              title="Delete bed assignment?"
              message="Are you sure you want to delete this bed assignment? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                permission="bed_management.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const BedOccupancyOverlay = withOverlayError(RootBedOccupancyOverlay)
