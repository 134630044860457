import React from 'react'
import { DataEditor, DataEditorProps } from '@glideapps/glide-data-grid'

import '@glideapps/glide-data-grid/dist/index.css'

const ROW_HEIGHT = 28
const HEADER_HEIGHT = 28

export const DEFAULT_PROPS: Partial<DataEditorProps> = {
  smoothScrollX: true,
  smoothScrollY: true,
  getCellsForSelection: true,
  width: '100%',
}

export const Spreadsheet: React.VFC = (props: any) => {
  const {
    columns,
    freezeColumns,
    getCellContent,
    gridSelection,
    isEditable,
    onColumnMoved,
    onColumnResize,
    onGridSelectionChange,
    rows,
    setCellValue,
    editorRef,
  } = props

  return (
    <DataEditor
      {...DEFAULT_PROPS}
      ref={editorRef}
      columns={columns}
      freezeColumns={freezeColumns}
      getCellContent={getCellContent}
      getCellsForSelection={true}
      gridSelection={gridSelection}
      headerHeight={HEADER_HEIGHT}
      onCellEdited={setCellValue}
      onColumnMoved={onColumnMoved}
      onColumnResize={onColumnResize}
      onGridSelectionChange={onGridSelectionChange}
      onPaste={true}
      rowHeight={ROW_HEIGHT}
      rowMarkers={isEditable ? 'checkbox-visible' : 'number'}
      rows={rows}
      rowSelect={isEditable ? 'multi' : 'none'}
      rowSelectionMode="multi"
    />
  )
}
