import React from 'react'
import { Link } from 'react-router-dom-v5-compat'

import withPermissions from '@behavehealth/hocs/withPermissions'

import { Grid, CardLink, CardTitle, CardSubtitle, Icon } from '@behavehealth/components'

const DashboardCard = withPermissions((props: any) => {
  const { icon, to, title } = props

  return (
    <CardLink as={Link} to={to} showChevron graphic={<Icon icon={icon} />}>
      <CardTitle title={title} />
      <CardSubtitle subtitle="View agreements" />
    </CardLink>
  )
})

const DashboardAgreementTemplates = () => {
  return (
    <Grid columns="repeat(auto-fit, minmax(280px, 1fr))" alignItems="normal" gap={8}>
      <DashboardCard
        title="Client Agreement Templates"
        icon="clients"
        to="/settings/agreement-templates/clients"
        permission="settings.agreement_templates.view"
        featureFlagV2="client_agreement_templates"
      />
      <DashboardCard
        title="Staff Agreement Templates"
        icon="employees"
        to="/settings/agreement-templates/employees"
        permission="settings.employee_agreement_templates.view"
        featureFlagV2="staff_agreement_templates"
      />
      <DashboardCard
        title="Organization Agreement Templates"
        icon="organizations"
        to="/settings/agreement-templates/organizations"
        permission="settings.organization_agreement_templates.view"
        featureFlagV2="organization_agreement_templates"
      />
    </Grid>
  )
}

export default DashboardAgreementTemplates
