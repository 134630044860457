import React from 'react'

import withSettings from '../../../hocs/withSettings'

import Textarea from '../../../components/Forms/Textarea'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Workflow from '../../../components/Workflow/Workflow'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Checkbox from '../../../components/Forms/Checkbox'
import ContextShow from '../../../components/Forms/ContextShow'

const DeclineDetails = ({ data, setData, setStatus, timezone }: any) => {
  const [valid, setValid] = React.useState()

  const { goNext }: any = React.useContext(Workflow.Context)

  React.useEffect(() => {
    valid ? setStatus('completed') : setStatus('todo')
  }, [valid])

  return (
    <Form useLocalModel initialModel={data} onUpdate={setData} onValidationUpdate={setValid} timezone={timezone}>
      <FormSection>
        <DateTimeInput defaultToNow label="Decline Date and Time" model="declined_at" />
        <Textarea useQuickText label="Internal Decline Notes" model="decline_reason" />

        {/* <CheckboxGroup>
          <Checkbox
            label="Send Decline Message to Client"
            model="send_decline_message"
            description="If we've got contact details for the client, we can send them a message to let them know that you've declined their referral"
          />
        </CheckboxGroup>

        <ContextShow when="send_decline_message" is={true}>
          <Textarea useQuickText label="Decline Message" model="decline_message" />
        </ContextShow> */}

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={goNext} isDisabled={!valid} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default withSettings(DeclineDetails)
