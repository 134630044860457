import React from 'react'
import size from 'lodash/size'

import { DataTable } from '../../../components/DataTable/DataTable'
import { singular } from '../../../utils/functions'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import NavGroup from '../../../components/NavGroup'
import NavItem from '../../../components/NavItem'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'

import { DATA_TRANSFER_CATEGORIES, DATA_TRANSFER_GROUPS } from '../constants'
import { ReferralColumn } from '../components/ReferralColumn'

import { DataCategoriesOverlay } from './DataCategoriesOverlay'

export const DataCategories = (props: any) => {
  const { data, setData } = props

  const categories = React.useMemo(() => {
    const result: any = []

    if (!data.data_transfer_categories) return result

    for (const categoryKey in data.data_transfer_categories) {
      if (!data.data_transfer_categories[categoryKey]) continue

      result.push(categoryKey)
    }

    return result
  }, [data])

  const [tab, setTab] = React.useState(categories?.[0] || '')

  const isEmpty = size(categories) === 0

  const tabConfig = React.useMemo(() => {
    return DATA_TRANSFER_CATEGORIES.find((o) => o.id === tab)
  }, [tab])

  React.useEffect(() => {
    if (!tab && categories?.[0]) setTab(categories[0])
  }, [categories, tab])

  const action = (
    <SummonOverlay overlay={<DataCategoriesOverlay initialModel={data.data_transfer_categories} onSave={setData} />}>
      <Button label="Edit Client Record Categories" size={300} glyph="edit" type="primary" />
    </SummonOverlay>
  )

  if (isEmpty) {
    return (
      <ReferralColumn asColumn title="Client Record Categories" icon="web_form">
        <Card>
          <State
            isEmpty
            title="Client Record Categories"
            icon="web_form"
            emptyDescription="No Client Record Categories have been selected yet"
            emptyActions={action}
          />
        </Card>
      </ReferralColumn>
    )
  }

  return (
    <>
      <ReferralColumn asColumn title="Client Record Categories" icon="web_form" className="!grow-0 shrink-0 basis-[300px]">
        <Grid gap="0.75rem">
          {action}

          {DATA_TRANSFER_GROUPS.map((group) => {
            return (
              <NavGroup key={group.id} label={group.label}>
                {group.items?.map?.((category: any) => {
                  if (!categories.includes(category.id)) return null

                  return (
                    <NavItem
                      key={category.id}
                      label={category.label}
                      icon={category.icon}
                      isActive={tab === category.id}
                      onClick={() => {
                        setTab(category.id)
                      }}
                    />
                  )
                })}
              </NavGroup>
            )
          })}
        </Grid>
      </ReferralColumn>

      {tabConfig && (
        <ReferralColumn
          title={tabConfig.label}
          icon={tabConfig.icon}
          headerAfter={
            <Flex gap="0.5rem">
              <Button size={200} label={`Add New ${singular(tabConfig.label)}`} type="default" glyph="add" />
              <Button size={200} label={`Import ${tabConfig.label}`} type="primary" glyph="add_file" />
            </Flex>
          }
        >
          <DataTable asCard title={tabConfig.label} icon={tabConfig.icon} data={[]} columns={[]} />
        </ReferralColumn>
      )}
    </>
  )
}
