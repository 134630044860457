import React from 'react'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

import Button from '../../../components/Button'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import GridTable from '../../../components/GridTable'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'

import { FormVariableOverlay } from './FormVariableOverlay'
import { useFormBuilder } from '../useFormBuilder'
import { VariableKey } from './VariableKey'
import { NameEditInput } from './NameEditInput'

export const FormVariablesTable = ({ isEditable }: any) => {
  const variables: any = useFormBuilder((state: any) => state.variables) || []
  const deleteFormVariable: any = useFormBuilder((state: any) => state.deleteFormVariable)
  const editFormVariable: any = useFormBuilder((state: any) => state.editFormVariable)

  const formVariables = React.useMemo(() => {
    const result: any = []

    for (const variable of variables) {
      if (variable?.category !== 'form' || variable._destroy) continue

      result.push(variable)
    }

    return result
  }, [variables])

  const isEmpty = size(formVariables) === 0

  const sortedVariables = sortBy(formVariables, 'name')

  if (isEmpty) {
    return (
      <State
        isEmpty
        minHeight={120}
        emptyDescription={
          <div>
            <div className="text-text text-[1.04rem] font-[600]">Form Variables</div>
            <div>No variables have been added yet</div>
          </div>
        }
        glyph="variables"
      />
    )
  }

  return (
    <GridTable
      testKey="signees_table"
      useBanding={false}
      templateColumns={`minmax(200px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr) minmax(280px, 1.5fr) ${isEditable ? '35px' : ''}`}
      className="text-[0.86rem]"
    >
      <GridTable.Header>
        <GridTable.Cell>Name</GridTable.Cell>
        <GridTable.Cell>Value</GridTable.Cell>
        <GridTable.Cell>Description</GridTable.Cell>
        <GridTable.Cell>Key</GridTable.Cell>
        {isEditable && <GridTable.Cell></GridTable.Cell>}
      </GridTable.Header>

      {sortedVariables?.map?.((variable: any) => {
        const { variable_key, variable_value, description } = variable

        if (variable?._destroy) return null

        return (
          <GridTable.Row key={variable_key}>
            <GridTable.Cell className="relative flex flex-nowrap items-center !py-1">
              <NameEditInput
                truncate
                isEditable={isEditable}
                className={`self-stretch block top-1 bottom-1 left-1 right-1 w-auto truncate flex-[1_1_auto] ${
                  isEditable ? 'hover:!bg-white hover:shadow-soft-2' : ''
                }`}
                inputClassName="top-1 bottom-1 left-1 right-1 !w-auto flex-[1_1_auto]"
                value={variable.name}
                onSave={(newValue) => {
                  if (!newValue) return

                  editFormVariable({ ...variable, name: newValue })
                }}
              />

              <div className="flex-[0_0_auto] ml-2">
                {isEditable && (
                  <SummonOverlay overlay={<FormVariableOverlay data={variable} />}>
                    <Button label="Edit" glyph="edit" size={100} />
                  </SummonOverlay>
                )}
              </div>
            </GridTable.Cell>

            <GridTable.Cell className="relative truncate">
              <NameEditInput
                truncate
                isEditable={isEditable}
                className={`block absolute top-1 bottom-1 left-1 right-1 w-auto truncate ${
                  isEditable ? 'hover:!bg-white hover:shadow-soft-2' : ''
                }`}
                inputClassName="absolute top-1 bottom-1 left-1 right-1 !w-auto"
                value={variable_value}
                onSave={(newValue) => {
                  if (!newValue) return

                  editFormVariable({ ...variable, variable_value: newValue })
                }}
              />
            </GridTable.Cell>

            <GridTable.Cell className="relative truncate">
              <NameEditInput
                truncate
                isEditable={isEditable}
                className={`block absolute top-1 bottom-1 left-1 right-1 w-auto truncate ${
                  isEditable ? 'hover:!bg-white hover:shadow-soft-2' : ''
                }`}
                inputClassName="absolute top-1 bottom-1 left-1 right-1 !w-auto"
                value={description}
                onSave={(newValue) => {
                  editFormVariable({ ...variable, description: newValue || '' })
                }}
              />
            </GridTable.Cell>

            <GridTable.Cell centerY className="truncate">
              <VariableKey variable={variable_key} />
            </GridTable.Cell>

            {isEditable && (
              <GridTable.Cell>
                <DeleteDialog
                  title={`Delete Variable?`}
                  message="Are you sure you want to delete this Applicant? This action cannot be undone."
                  onYes={() => {
                    deleteFormVariable(variable.external_id)
                  }}
                  permission="client_journey.actions.delete"
                >
                  <Button hideLabel color="red" glyph="delete" size={100} />
                </DeleteDialog>
              </GridTable.Cell>
            )}
          </GridTable.Row>
        )
      })}
    </GridTable>
  )
}
