import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { FilesDataTable } from '@behavehealth/constructs/Files/FilesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, CardContent, DnDUploader, Page, Uploader, HelpTagIframe, Permission } from '@behavehealth/components'

const pageConfig = {
  feature: 'files',
  help: <HelpTagIframe id="files" />,
  marketingID: 'files',
}

const Files: React.FC = () => {
  const { resource_id }: any = useParams()

  const { data: property }: any = useGet({
    name: ['locations', resource_id],
    url: `/houses/${resource_id}`,
  })

  const tableProps: any = useDataTable({
    name: ['locations', resource_id, 'files'],
    endpoint: `/houses/${resource_id}/documents`,
    updateDeleteEndpoint: '/documents',
    enabled: !!resource_id,
    localStorageKey: 'location_files_v1',
  })

  const isEmpty = size(tableProps.data) === 0

  const actions = (
    <Uploader category="general" resource={property} queryKey={tableProps.queryKey}>
      <Button type="primary" glyph="upload" label="Upload Files" permission="properties.files.create" />
    </Uploader>
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Card>
        <FilesDataTable
          {...tableProps}
          title="Location Files"
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'properties.files.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />

        <CardContent>
          <Permission permission="properties.files.create">
            <DnDUploader category="general" resource={property} queryKey={tableProps.queryKey} isEditable={true} isDisabled={false} />
          </Permission>
        </CardContent>
      </Card>
    </Page>
  )
}

export default withPageError(withMarketing(Files, pageConfig))
