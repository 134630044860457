import React from 'react'

import Chart from '../Chart'

type Props = {
  data: any[]
  links: any[]
  series?: any
}

const SankeyChart: React.FC<Props> = ({ data, links, series, ...rest }) => {
  return (
    <Chart
      option={{
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
        },

        series: {
          data,
          links,
          type: 'sankey',
          layout: 'none',
          emphasis: {
            focus: 'adjacency',
          },
          // levels: [
          //   {
          //     depth: 0,
          //     itemStyle: {
          //       color: '#fbb4ae',
          //     },
          //     lineStyle: {
          //       color: 'source',
          //       opacity: 0.6,
          //     },
          //   },
          //   {
          //     depth: 1,
          //     itemStyle: {
          //       color: '#b3cde3',
          //     },
          //     lineStyle: {
          //       color: 'source',
          //       opacity: 0.6,
          //     },
          //   },
          //   {
          //     depth: 2,
          //     itemStyle: {
          //       color: '#ccebc5',
          //     },
          //     lineStyle: {
          //       color: 'source',
          //       opacity: 0.6,
          //     },
          //   },
          //   {
          //     depth: 3,
          //     itemStyle: {
          //       color: '#decbe4',
          //     },
          //     lineStyle: {
          //       color: 'source',
          //       opacity: 0.6,
          //     },
          //   },
          // ],
          ...series,
        },
      }}
      {...rest}
    />
  )
}

export default SankeyChart
