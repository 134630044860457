import React from 'react'
import { opacify } from 'polished'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { arrayToMap, arrayToMapWithKey, countWord } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Button from '../Button'
import State from '../State'
import TreeItem from '../TreeItem'

import { DataTableActions } from './DataTableActions'
import { DataTableFooter } from './DataTableFooter'
import { DataTableMain } from './DataTableMain'
import { DataTableMainHeader } from './DataTableMainHeader'

import { CELLS } from './constants'
import { DataTableProvider } from './context'
import { useStore } from './useStore'

import { EmptyCell } from './cells/EmptyCell'
import { NotApplicableCell } from './cells/NotApplicableCell'
import { HEADER_FILTERS } from './headerFilters'
import isEqual from 'react-fast-compare'

const RootDataTable = (props: any) => {
  const {
    asAccordions,
    asCard,
    autoFocusFirstFilter,
    className,
    columns,
    testKey,
    data,
    emptyActions,
    emptyDescription = 'No data available to display',
    filtersConfig,
    folderCategory,
    folders,
    footer,
    footerHidden,
    glyph,
    graphic,
    headerAfter,
    headerAside,
    icon,
    invalidate,
    invalidateKeys,
    isError,
    isLoading,
    isRefetching,
    mainHeaderHidden,
    meta,
    minimal,
    onFiltersUpdate,
    onPageSizeChange,
    onRowSelectionUpdate,
    onSortingUpdate,
    pageSize,
    queryKey,
    refetch,
    searchBarFilterKey,
    showBanding,
    title,
    hiddenRowIds,
    cannotSelectIds,
    emptyStateMinHeight,
  } = props

  const columnIds: any = useStore((state: any) => state.columnIds)
  const columnsMap: any = useStore((state: any) => state.columnsMap)
  const dataMap: any = useStore((state: any) => state.dataMap)
  const filters: any = useStore((state: any) => state.filters)
  const hiddenColumnIds: any = useStore((state: any) => state.hiddenColumnIds)
  const initializeData: any = useStore((state: any) => state.initializeData)
  const isResizing: any = useStore((state: any) => state.isResizing)
  const resetFilters: any = useStore((state: any) => state.resetFilters)
  const resetHoverCellCoords: any = useStore((state: any) => state.resetHoverCellCoords)
  const resetSorting: any = useStore((state: any) => state.resetSorting)
  const selectCell: any = useStore((state: any) => state.selectCell)
  const selectedRows: any = useStore((state: any) => state.selectedRows)
  const setColumnWidth: any = useStore((state: any) => state.setColumnWidth)
  const setHoverCellCoords: any = useStore((state: any) => state.setHoverCellCoords)
  const setIsResizing: any = useStore((state: any) => state.setIsResizing)
  const sorting: any = useStore((state: any) => state.sorting)
  const addFilter: any = useStore((state: any) => state.addFilter)
  const setHiddenRowIds: any = useStore((state: any) => state.setHiddenRowIds)
  const setCannotSelectIds: any = useStore((state: any) => state.setCannotSelectIds)
  const unselectIds: any = useStore((state: any) => state.unselectIds)

  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false)
  const [isSortingOpen, setIsSortingOpen] = React.useState(false)

  const rootClasses = clsx(
    'DataTable',
    minimal && 'minimal',
    asCard && 'as-card',
    asAccordions && 'as-accordions',
    showBanding && 'show-banding',
    isResizing && 'is-resizing',
    className,
  )

  const isEmpty = !isLoading && size(dataMap) === 0
  const filtersCount = size(filters)
  const hasFilters = filtersCount > 0

  const showFiltersReset = isEmpty && !isLoading && hasFilters
  const showErrorReset = isError && (hasFilters || size(sorting) > 0)

  // **************
  // Functions
  // **************

  const openFilters = () => {
    setIsFiltersOpen(true)
  }

  const resizeRef: any = React.useRef({ x: 0, id: '', currentWidth: 0 })

  const handleErrorReset = React.useCallback(() => {
    resetFilters()
    resetSorting()
  }, [resetFilters, resetSorting])

  const handlePointerDown = React.useCallback(
    (event: any) => {
      if (event.target.dataset) {
        const { type, id } = event.target.dataset

        if (type === 'resizer') {
          const column = columnsMap[id]
          if (column) {
            setIsResizing(true)

            resizeRef.current.id = id
            resizeRef.current.x = event.screenX

            if (column.width) resizeRef.current.currentWidth = parseInt(column.width)
          }
        }
      }
    },
    [columnsMap],
  )

  const handlePointerMove = React.useCallback(
    (event: any) => {
      if (event.target.dataset) {
        const { id, x }: any = resizeRef.current

        if (id && x) {
          const currentWidth = resizeRef.current.currentWidth
          const diff = event.screenX - parseInt(x)
          const width = currentWidth + diff

          setColumnWidth({ id: id, width: width < 60 ? 60 : width })
        }
      }
    },
    [setColumnWidth],
  )

  const handlePointerUp = React.useCallback((event) => {
    const isCell = event.target?.dataset?.type === 'cell'
    const canSelectCell = event.target.hasAttribute('data-can-select')

    if (isCell && canSelectCell) {
      const { coords } = event.target.dataset

      selectCell({ coords, ctrlKey: event.metaKey || event.ctrlKey })
    }

    if (resizeRef.current) {
      resizeRef.current.x = 0
      resizeRef.current.id = ''
      resizeRef.current.currentWidth = 0

      setIsResizing(false)
    }
  }, [])

  const handleMouseOver = React.useCallback(
    (event: any) => {
      if (event.target.dataset) {
        const { x, y, type } = event.target.dataset

        if (type === 'cell') setHoverCellCoords({ x, y })
      }
    },
    [setHoverCellCoords],
  )

  const handleMouseOut = React.useCallback(
    (event: any) => {
      resetHoverCellCoords()
    },
    [resetHoverCellCoords],
  )

  // **************
  // Effects
  // **************

  React.useEffect(() => {
    if (!data || !columns) return

    initializeData({ data, columns, folders, meta })
  }, [data, columns, folders, meta, initializeData])

  React.useEffect(() => {
    // don't apply filters while modal is open
    if (!filters || isFiltersOpen) return

    const filtersMap = arrayToMapWithKey(filters, 'key')

    if (onFiltersUpdate) onFiltersUpdate(filtersMap)
  }, [filters, isFiltersOpen])

  React.useEffect(() => {
    if (!sorting || isSortingOpen) return

    if (onSortingUpdate) onSortingUpdate(sorting)
  }, [sorting, isSortingOpen])

  React.useEffect(() => {
    if (!onRowSelectionUpdate || !selectedRows) return

    onRowSelectionUpdate(selectedRows)
  }, [selectedRows, dataMap])

  // hide rows when hiddenRowIds updates
  React.useEffect(() => {
    if (!hiddenRowIds) return

    setHiddenRowIds(hiddenRowIds)
    initializeData({ data, columns, folders, meta })
  }, [hiddenRowIds])

  // sync cannotSelectIds
  React.useEffect(() => {
    if (!cannotSelectIds) return

    setCannotSelectIds(cannotSelectIds)
    unselectIds(cannotSelectIds)
  }, [cannotSelectIds])

  // add first filter if filters are empty
  React.useEffect(() => {
    if (!isFiltersOpen || size(filtersConfig) === 0 || size(filters) > 0) return

    const firstFilterKey = Object.keys(filtersConfig)[0]

    if (!firstFilterKey) return

    addFilter({ key: firstFilterKey })
  }, [isFiltersOpen, filtersConfig])

  const gridTemplateColumns = React.useMemo(() => {
    if (size(columnIds) === 0) return ''

    const columnWidths = []

    for (const columnId of columnIds) {
      const column = columnsMap[columnId]
      const isHidden = hiddenColumnIds.includes(columnId)

      if (!column || isHidden) continue

      const CONFIG = CELLS[column.type]
      const columnWidth = column.width || CONFIG?.width || 200

      columnWidths.push(`minmax(${columnWidth}px, ${column.maxWidth || '1fr'})`)
    }

    return columnWidths.join(' ')
  }, [columnsMap, columnIds, hiddenColumnIds])

  const style: any = {
    '--grid-template-columns': gridTemplateColumns,
  }

  // TEMP: remove once all filters are supported
  const columnFiltersEnabled = React.useMemo(() => {
    if (!filtersConfig || !columnIds) return

    const supportedHeaderFilters = Object.keys(HEADER_FILTERS)

    for (const key in filtersConfig) {
      if (!filtersConfig?.[key]?.type) continue

      const filterType = filtersConfig[key].type

      if (supportedHeaderFilters.includes(filterType) && columnIds?.includes(key)) return true
    }
  }, [filtersConfig, columnIds])

  return (
    <>
      <div
        style={style}
        className={rootClasses}
        css={STYLES.root}
        onPointerDown={handlePointerDown}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onPointerUp={handlePointerUp}
        onPointerMove={handlePointerMove}
        data-test={testKey}
      >
        {!mainHeaderHidden && (
          <DataTableMainHeader
            after={headerAfter}
            aside={headerAside}
            data={data}
            folderCategory={folderCategory}
            glyph={glyph}
            graphic={graphic}
            icon={icon}
            isEmpty={isEmpty}
            isFiltersOpen={isFiltersOpen}
            isLoading={isLoading}
            isRefetching={isRefetching}
            isSortingOpen={isSortingOpen}
            onFiltersUpdate={onFiltersUpdate}
            refetch={refetch}
            setIsFiltersOpen={setIsFiltersOpen}
            setIsSortingOpen={setIsSortingOpen}
            title={title}
            columnFiltersEnabled={columnFiltersEnabled}

            // searchBarFilterKey={searchBarFilterKey} // TODO: remove props from everywhere
          />
        )}

        <React.Fragment key="data-table-main">
          <DataTableActions />

          <DataTableMain
            showBanding={showBanding}
            isRefetching={isRefetching}
            invalidate={invalidate}
            invalidateKeys={invalidateKeys}
            queryKey={queryKey}
            openFilters={openFilters}
            searchBarFilterKey={searchBarFilterKey}
            columnFiltersEnabled={columnFiltersEnabled}
            autoFocusFirstFilter={autoFocusFirstFilter}
            after={
              showFiltersReset ? (
                <State
                  isEmpty
                  // title={title}
                  glyph="filter"
                  emptyActions={
                    <Button
                      testKey="clear_filters_button"
                      label={`Clear ${countWord('Filters', filtersCount)}`}
                      glyph="close"
                      glyphColor="red"
                      size={200}
                      type="default"
                      color="text"
                      onClick={resetFilters}
                    />
                  }
                  emptyDescription="No data matches the selected filters"
                />
              ) : showErrorReset ? (
                <State
                  key="error-state"
                  isEmpty
                  title={title}
                  glyph="circle_error"
                  emptyActions={
                    <>
                      <Button
                        testKey="clear_filters_and_sorting_button"
                        label="Clear Filters & Sorting"
                        glyph="close"
                        glyphColor="red"
                        size={200}
                        type="default"
                        color="text"
                        onClick={handleErrorReset}
                      />

                      <TreeItem title="More Info" headerCSS={STYLES.treeItemHeader}>
                        {size(sorting) > 0 && (
                          <div>
                            <b>Sort Column: </b> {arrayToMap(columns)?.[sorting?.name]?.title || sorting?.name}
                          </div>
                        )}

                        {hasFilters && (
                          <div>
                            <b>Active Filters: </b>

                            {Object.keys(filters)
                              .map((key) => filtersConfig?.[key]?.label || key)
                              .join(', ')}
                          </div>
                        )}
                      </TreeItem>
                    </>
                  }
                  emptyDescription="There was an error loading this data. Try clearing any sorted columns and filters."
                  css={STYLES.emptyState}
                />
              ) : !data || isLoading || isEmpty ? (
                <State
                  key="empty-state"
                  isLoading={isLoading}
                  isEmpty={!isLoading && !isRefetching && isEmpty}
                  title={title}
                  icon={icon}
                  glyph={glyph}
                  emptyActions={emptyActions}
                  emptyDescription={emptyDescription}
                  className={STYLES.emptyState}
                  minHeight={emptyStateMinHeight}
                />
              ) : null
            }
          />
        </React.Fragment>

        {!footerHidden && <DataTableFooter pageSize={pageSize} onPageSizeChange={onPageSizeChange} />}

        {footer}
      </div>
    </>
  )
}

export const DataTable = (props: any) => {
  const {
    batchActionsConfig,
    canBatchSelect,
    columns,
    currentPage,
    data,
    filters,
    filtersConfig,
    folders,
    footerHidden,
    getCanSelect,
    getRowId,
    headerLinksConfig,
    help,
    hiddenColumnIds,
    invalidate,
    invalidateKeys,
    isLoading,
    isRefetching,
    localStorageKey,
    meta,
    onCurrentPageChange,
    onFiltersUpdate,
    onPageSizeChange,
    onRowSelect,
    onSortingUpdate,
    pageSize,
    processRow,
    queryKey,
    renderBatchActions,
    sorting,
    updateDeleteEndpoint,
    updateKey,
    hiddenRowIds,
    cannotSelectIds,
  } = props

  const { timezone: settingsTimezone } = useSettings()
  const timezone = props.timezone || settingsTimezone

  return (
    <DataTableProvider
      batchActionsConfig={batchActionsConfig}
      canBatchSelect={canBatchSelect}
      columns={columns}
      currentPage={currentPage}
      data={data}
      filters={filters}
      filtersConfig={filtersConfig}
      folders={folders}
      getCanSelect={getCanSelect}
      getRowId={getRowId}
      headerLinksConfig={headerLinksConfig}
      help={help}
      hiddenColumnIds={hiddenColumnIds}
      invalidate={invalidate}
      invalidateKeys={invalidateKeys}
      isLoading={isLoading}
      isRefetching={isRefetching}
      localStorageKey={localStorageKey}
      meta={meta}
      onCurrentPageChange={onCurrentPageChange}
      onFiltersUpdate={onFiltersUpdate}
      onPageSizeChange={onPageSizeChange}
      onRowSelect={onRowSelect}
      onSortingUpdate={onSortingUpdate}
      pageSize={pageSize}
      processRow={processRow}
      queryKey={queryKey}
      renderBatchActions={renderBatchActions}
      sorting={sorting}
      timezone={timezone}
      updateDeleteEndpoint={updateDeleteEndpoint}
      updateKey={updateKey}
      hiddenRowIds={hiddenRowIds}
    >
      <RootDataTable {...props} />
    </DataTableProvider>
  )
}

DataTable.EmptyCell = EmptyCell
DataTable.EmptyCell.displayName = 'EmptyCell'

DataTable.NotApplicableCell = NotApplicableCell
DataTable.NotApplicableCell.displayName = 'NotApplicableCell'

const STYLES = {
  root: {
    '--checkbox-width': '2.5rem',
    '--graphic-width': '2.75rem',
    '--indent-size': '1rem',
    '--padding-x': '0.5rem',
    '--padding-y': '0.4rem',
    '--row-min-height': '2rem',
    '--triangle-size': '1rem',
    '--row-hover-color': '#f7f8fa',
    '--filter-height': '1.5rem',

    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'hidden !important',
    flex: '1 1 auto',

    fontSize: '0.92rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',

    boxShadow: `
      0 0 0 1px ${opacify(-0.05, COLORS.divider)},
      0 3px 0 1px ${opacify(-0.07, COLORS.divider)}
    `,

    '&.is-resizing, &.is-resizing *': {
      userSelect: 'none  !important',
      cursor: 'ew-resize !important',
    },

    '&.as-card': {
      borderRadius: 5,
    },

    '&.minimal': {
      boxShadow: 'none',
    },
  },

  emptyState: {
    minHeight: 'auto !important',
    flex: '1 1 auto',
  },

  treeItemHeader: {
    justifyContent: 'center !important',
    background: 'none !important',
    '& > *': { fontSize: '0.9rem' },
  },
}
