import React from 'react'

import Checkbox from '../Forms/Checkbox'

const PermissionCheckbox = ({ ...rest }: any) => {
  if (!rest.model) return <span css={{ opacity: 0.55 }}>–</span>
  return <Checkbox trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" {...rest} />
}

export default PermissionCheckbox
