import React from 'react'
import produce from 'immer'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Button from '../../components/Button'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import { RoundSignatureStatus } from '@behavehealth/constructs/Rounds/RoundSignatureStatus'
import { RoundStatus, statuses } from '@behavehealth/constructs/Rounds/RoundStatus'

export const RoundReportDataTable = (props: any) => {
  const { to } = props

  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.data.id} to={to(data.data)} value={data.data.name} />
        },
      },
      {
        title: 'Status',
        id: 'status',
        model: 'data.status',
        width: 150,
        disableHide: true,
        formatValue: ({ value }: any) => {
          return <RoundStatus status={value} />
        },
      },
      {
        title: 'Signature Status',
        id: 'signature_status',
        model: 'data.signature_status',
        width: 150,
        disableHide: true,
        formatValue: ({ value }: any) => {
          return <RoundSignatureStatus status={value} />
        },
      },
      {
        title: 'Date Added',
        id: 'created_at',
        model: 'data.created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        id: 'updated_at',
        model: 'data.updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        id: 'author',
        model: 'data.author.name',
        type: 'profile',
        disableSort: true,
      },
    ]
  }, [to])

  return (
    <>
      <DataTable
        asCard
        title="Rounds"
        icon="rounds"
        columns={columns}
        filtersConfig={FILTERS_CONFIG}
        {...props}
        headerAfter={
          <ReportRefetchButton
            forceShow={true}
            category="rounds"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=rounds'}
          />
        }
      />
    </>
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  status: {
    label: 'Signature Status',
    type: 'multi_select',
    options: Object.entries(statuses).map(([value, option]) => ({ label: option.label, value })),
  },
  signature_status: {
    label: 'Signature Status',
    type: 'multi_select',
    options: [
      { label: 'Pending', value: 'pending' },
      { label: 'Signed', value: 'signed' },
    ],
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
