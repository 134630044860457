import React from 'react'

import ContextShow from '../ContextShow'
import Input from '../Input'
import Option from '../Option'
import Select from '../Select'

const InsurancePlanTypeSelect = ({ isRequired, isNested, model, ...rest }: any) => (
  <>
    <Select
      allowEmpty
      isNested={isNested}
      label="Plan Type"
      model={model}
      validations={
        isRequired && {
          presence: {
            message: 'Please select a plan type',
          },
        }
      }
      {...rest}
    >
      <Option label="PPO" value="ppo" />
      <Option label="EPO" value="epo" />
      <Option label="HMO" value="hmo" />
      <Option label="POS" value="pos" />
      <Option label="Choice Plus" value="choice_plus" />
      <Option label="COBRA" value="cobra" />
      <Option label="Medicaid" value="medicaid" />
      <Option label="Medicare" value="medicare" />
      <Option label="Other" value="other" />
    </Select>

    <ContextShow when={rest.model} is="other">
      <Input
        isNested={isNested}
        label="Other Plan Type"
        model={`${rest.model}_other`}
        validations={{
          presence: {
            message: 'Please enter a plan type',
          },
        }}
      />
    </ContextShow>
  </>
)

InsurancePlanTypeSelect.defaultProps = {
  model: 'plan_type',
}

export default InsurancePlanTypeSelect
