import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { countWord, minutesBetween } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { ProfileCell } from '../../components/DataTable/cells/ProfileCell'
import LocationCheckStatus from '../../components/Statuses/LocationCheckStatus'

export const LocationCheckInsDataTable = (props: any) => {
  const match = useRouteMatch()
  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          const category = data?.category
          const location = data?.location

          let name = value

          if (category === 'organization' && location?.name) name = location.name
          else if (category === 'property' && location?.name) name = location.name

          return <MainCell id={data.id} to={props.to ? props.to(data) : `${match.url}/${data.id}`} value={name} />
        },
      },
      {
        title: 'Client',
        model: 'resident',
        type: 'profile',
      },
      {
        title: 'Bed Assignment at Check-In',
        model: 'bed_assignment.place',
        width: 300,
        formatValue: ({ data, value }: any) => {
          if (!value) return null

          const avatar = value?.house?.avatar
          const houseName = value?.house?.name
          const floorName = value?.floor?.name
          const roomName = value?.room?.name

          return <ProfileCell value={`${houseName}, ${floorName}, ${roomName}`} avatar={avatar} />
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        editPermission: true,
        tagSmartCategories: 'location_checkins',
      },
      {
        title: 'Category',
        model: 'category',
        type: 'title',
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <LocationCheckStatus status={value} />,
      },
      {
        title: 'Device Distance from Location',
        model: 'direct_miles_between_coords',
        width: 225,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return countWord('miles', value)
        },
      },
      {
        title: 'Check-in Time',
        model: 'checkin_at',
        type: 'date_time',
      },
      {
        title: 'Check-out Time',
        model: 'checkout_at',
        type: 'date_time',
      },
      {
        title: 'Duration',
        id: 'duration',
        formatValue: ({ data }: any) => {
          return `${minutesBetween(data.checkin_at, data.checkout_at, timezone)} minutes`
        },
      },
      {
        title: 'Notes',
        model: 'notes',
      },
      // {
      //   title: 'Added By',
      //   model: 'author',
      //   type: 'profile',
      // },
      // {
      //   title: 'Date Added',
      //   model: 'created_at',
      //   type: 'date_time',
      // },
      // {
      //   title: 'Date Updated',
      //   model: 'updated_at',
      //   type: 'date_time',
      // },
    ],
    [match, timezone, props.to],
  )

  return (
    <DataTable
      asCard
      title="Location Check-ins"
      icon="recovery_navigation"
      columns={columns}
      {...props}
      updateKey={props.queryKey}
      updateDeleteEndpoint="/location_checks"
      // filtersConfig={__FILTERS_CONFIG__}
    />
  )
}
