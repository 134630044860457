import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import size from 'lodash/size'

import { countWord, singular, titleCase } from '../../utils/functions'

import { TreatmentPlanBookGoalsDataTable } from './TreatmentPlanBookGoalsDataTable'
import { TreatmentPlanBookInterventionsDataTable } from './TreatmentPlanBookInterventionsDataTable'
import { TreatmentPlanBookObjectivesDataTable } from './TreatmentPlanBookObjectivesDataTable'
import { TreatmentPlanBookProblemsDataTable } from './TreatmentPlanBookProblemsDataTable'
import { TreatmentPlanBookCascadeImporter } from './TreatmentPlanBookCascadeImporter'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Tabs from '../../components/Tabs'

import { AutoGenerateReviewsCheckbox } from '../TreatmentPlans/components/TreatmentPlanReviews'
import Form from '../../components/Forms/Form'

const CONFIG: any = {
  goals: {
    title: 'Goals',
    table: TreatmentPlanBookGoalsDataTable,
  },
  objectives: {
    title: 'Objectives',
    table: TreatmentPlanBookObjectivesDataTable,
  },
  interventions: {
    title: 'Interventions',
    table: TreatmentPlanBookInterventionsDataTable,
  },
  problems: {
    title: 'Problems',
    table: TreatmentPlanBookProblemsDataTable,
  },
}

const CASCADE_IMPORT_TYPES: any = {
  goals: {
    title: 'Goals, Objectives, and Interventions',
  },
  objectives: {
    title: 'Objectives and Interventions',
  },
}

export const TreatmentBookImportOverlay = (props: any) => {
  const { planId, importType, category, onSingleSelect, onMultiSelect, onClose, permissionBase } = props

  const form = React.useRef()

  const location: any = useLocation()
  const navigate: any = useNavigate()

  const config = CONFIG?.[importType]

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [selectedRows, setSelectedRows] = React.useState<any>([])

  const selectedRowsCount = size(selectedRows)
  const isSelectedRowsEmpty = selectedRowsCount === 0

  const showCascadeImport = false // Object.keys(CASCADE_IMPORT_TYPES).includes(importType)

  const [tab, setTab] = React.useState('default')

  const close = () => {
    if (props.type === 'summon' && onClose) return onClose()

    const url = location.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    navigate(url)
  }

  const handleClick = React.useCallback(async (rowData: any) => {
    const formValue = form.current?.getFormValue?.() || {}
    await onSingleSelect?.(rowData, formValue)
    close?.()
  }, [])

  if (!config) return null

  const Table = config.table

  const pluralTitle = config.title
  const singularTitle = singular(pluralTitle)

  return (
    <Overlay onClose={close} position="right" maxWidth={80}>
      <Overlay.Header icon="treatment_plans" title={`Import ${pluralTitle}`} />

      <Overlay.SubHeader className="relative z-10">
        <div className="flex flex-wrap items-center justify-between">
          {showCascadeImport && (
            <>
              <Tabs defaultTab="default" onChange={setTab}>
                <Tabs.List className="!border-none">
                  <Tabs.Item label={`Import ${pluralTitle} Only`} name="default" glyph="multi_select" />
                  <Tabs.Item label={`Import ${CASCADE_IMPORT_TYPES?.[importType]?.title}`} name="cascade" glyph="stack" />
                </Tabs.List>
              </Tabs>
            </>
          )}

          <Button
            useGlyphForTarget
            label={`Manage ${titleCase(category)} Treatment Book ${config.title}`}
            target="_blank"
            size={200}
            glyph="settings"
            link={`/settings/treatment-plan-book/${kebabCase(category)}/${importType}`}
            display="inline-flex"
            type="minimal"
          />
        </div>
      </Overlay.SubHeader>

      <Overlay.Content>
        <Section>
          {tab === 'default' && (
            <>
              <Alert contrast glyph="info" className="mb-4">
                Click on a {singularTitle.toLowerCase()} to import, or select multiple {pluralTitle.toLowerCase()} and click the Import
                button.
              </Alert>

              <Table
                category={category}
                canBatchSelect={!isUndefined(onMultiSelect)}
                onRowSelectionUpdate={isUndefined(onMultiSelect) ? undefined : setSelectedRows}
                onClick={handleClick}
              />

              <Form getForm={form} className="mt-4">
                {planId && <AutoGenerateReviewsCheckbox planId={planId} />}
              </Form>
            </>
          )}

          {tab === 'cascade' && <TreatmentPlanBookCascadeImporter category={category} />}
        </Section>
      </Overlay.Content>

      {tab === 'default' && onMultiSelect && (
        <Overlay.Footer>
          <Button
            label={isSelectedRowsEmpty ? 'Select Rows to Import' : `Import ${countWord(pluralTitle, selectedRowsCount)}`}
            glyph={isSelectedRowsEmpty ? 'info' : 'tick_circle'}
            color={isSelectedRowsEmpty ? 'textMuted' : 'green'}
            type="primary"
            isLoading={isLoading}
            isDisabled={isSelectedRowsEmpty}
            onClick={async () => {
              const formValue = form.current?.getFormValue?.() || {}

              setIsLoading(true)
              await onMultiSelect(selectedRows, formValue)
              setIsLoading(false)
              close?.()
            }}
            permission={`${permissionBase}.edit`}
          />
        </Overlay.Footer>
      )}

      {/* {tab === 'cascade' && onMultiSelect && (
        <Overlay.Footer>
          <Button label="Import" />
        </Overlay.Footer>
      )} */}
    </Overlay>
  )
}
