import URLInput from './URLInput'
import { withFormContext } from './context'

class ZoomInput extends URLInput {}

ZoomInput.defaultProps = {
  glyph: 'zoom',
}

export default withFormContext(ZoomInput)
