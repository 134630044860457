import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import size from 'lodash/size'

import { countWord } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'
import useAPI from '../../../hooks/useAPI'

import Button from '../../Button'
import Card from '../../Card'
import Overlay from '../../Overlay'
import Section from '../../Section'
import InsuranceServicesTemplatesTable from '../tables/internal/InsuranceServicesTemplatesTable'

import TemplateLegalTooltip from '../TemplateLegalTooltip'

import { apiCreate, apiGet } from '../../../modules/api'

const HIDDEN_COLUMNS = ['global_tags', 'smart_flags']

const InsuranceServiceTemplatesImportOverlay = () => {
  const history = useHistory()
  const { isBehave } = useSettings()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [loading, setLoading]: any = React.useState(false)

  const data = useSelector((state: any) => state.data?.insurance_services_templates?.data)
  const dataLoading = useSelector((state: any) => state.data?.insurance_services_templates?.loading)
  const isEmpty = size(data) === 0

  useAPI('insurance_services_templates', '/internal_templates?category=insurance&subcategory=insurance_service')

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    setLoading(true)

    try {
      const ids = selectedRows.map((o: any) => o.original.id)
      await apiCreate({
        url: '/internal_templates/import',
        params: { ids: ids, category: 'insurance', subcategory: 'insurance_service' },
      })
      await apiGet({ name: 'services', url: '/services?service_type=healthcare' })

      history.goBack()
    } catch (errors) {
      console.debug(errors)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={97}>
      <Overlay.Header title="Import Insurance Service" titleAside={<TemplateLegalTooltip />} />

      <Overlay.Content>
        <Section>
          <Card className="!mt-4">
            <InsuranceServicesTemplatesTable
              title="Insurance Services"
              data={data}
              isLoading={isEmpty && dataLoading}
              onSelect={setSelectedRows}
              hiddenColumns={!isBehave && HIDDEN_COLUMNS}
            />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Services', selectedRowsCount)}` : 'Select Services to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={loading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default InsuranceServiceTemplatesImportOverlay
