import React from 'react'

import { CLAIM_PATIENT_STATUSES } from '../../../utils/constants'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

const ClaimPatientStatusSelect: React.FC<any> = ({ label = 'Patient Status', model = 'patient_status_code' }) => (
  <Select label={label} model={model} defaultValue="30">
    {Object.keys(CLAIM_PATIENT_STATUSES)
      .sort()
      .map((key: string) => (
        <Option key={key} label={`${key} – ${CLAIM_PATIENT_STATUSES[key]}`} value={key} />
      ))}
  </Select>
)

export default ClaimPatientStatusSelect
