import React from 'react'
import { transparentize } from 'polished'

import { apiUpdate } from '../../modules/api'
import { COLORS } from '../../theme'
import { usDate, usDateTime, countWord, daysBetween } from '../../utils/functions'
import Notifications from '../../modules/notifications'

import { H3, H4 } from '../../components/Typography'
import Button from '../../components/Button'
import CardTreeItem from '../../components/CardTreeItem'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import ContextShow from '../../components/Forms/ContextShow'
import DataFormStatus from '../../components/Statuses/DataFormStatus'
import DataList from '../../components/DataList'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Nav from '../../components/Nav'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PageGrid from '../../components/PageGrid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import RatingInput from '../../components/Forms/RatingInput'
import ScrollMenu from '../../components/ScrollMenu'
import ScrollView from '../../components/ScrollView'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'
import StateSelect from '../../components/Forms/elements/StateSelect'

import EVOBDataView from '../../components/DataView/EVOBDataView'
import InsurancePoliciesDataView from '../../components/DataView/InsurancePoliciesDataView'

import { withOverlayError } from '../../hocs/withOverlayError'

import VOBServices from './components/VOBServices'
import { useSettings } from '../../hooks/useSettings'
import { useOverlay } from '../../hooks/useOverlay'
import { useUpdate } from '../../hooks/useNewAPI'

const VOBOverlay = (props: any) => {
  const {
    cancel,
    close,
    client,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'insurance_vobs',
    endpoint: '/insurance_vobs',
    invalidate: 'insurance_vobs',
    options: props,
  })

  const { isBehave, timezone } = useSettings()

  const { mutateAsync: signOffVOB, isLoading: isSigningOff } = useUpdate({
    name: ['insurance_vobs', id],
    url: `/insurance_vobs/${id}`,
    invalidate: ['insurance_vobs'],
  })

  const signOff = async () => {
    try {
      await signOffVOB({ status: 'signed_off' })
      Notifications.send('VOB submitted successfully', 'positive')
    } catch (error) {
      Notifications.send('Something went wrong, the VOB was not submitted', 'negative')
    }
  }

  if (isOverlayLoading) return <OverlayLoader position={props.position} showBackdrop={props.showBackdrop} />

  return (
    <Overlay showBackdrop position="center" onClose={close} maxWidth={120}>
      <Overlay.Header
        title="VOB v2"
        icon="insurance"
        titleAside={<DataFormStatus status={data?.status} />}
        description={<ClientProfileHeader client={client} />}
      />

      <ScrollView>
        <PageGrid scroll breakpoint={3} columns="min-content 1fr">
          <Nav title="Table of Contents" breakpoint={3} top="0" background={transparentize(0.4, 'white')}>
            <ScrollMenu />
          </Nav>

          <Overlay.Content style={{ padding: 1 }}>
            <Form
              getForm={form}
              initialModel={initialModel}
              isEditable={isEditable}
              timezone={timezone}
              onValidationUpdate={onValidationUpdate}
            >
              <Section
                title="General"
                scrollview={{
                  id: 'general',
                  name: 'General',
                }}
              >
                <FormSection>
                  <DateTimeInput
                    defaultToNow
                    model="dated_at"
                    label="Date and Time"
                    validations={{
                      presence: {
                        message: 'Please enter a date and time',
                      },
                    }}
                  />

                  {/* <ObjectSelector
                    label="Assigned Behave Team Member"
                    icon="employees"
                    type="bh_employees.all"
                    model="employee"
                    selectTitle={(data: any) => data.name}
                  /> */}
                </FormSection>

                <Grid gap="0.5rem" className="!mt-4">
                  <CardTreeItem isOpen title="Client Details">
                    <DataList labelWidth="180px">
                      <DataList.Item label="Full Name" value={client?.name} />
                      {/* TODO (Andrei): should DOB have a timezone */}
                      <DataList.Item label="Date of Birth" value={usDate(client?.dob, timezone)} />
                      <DataList.Item label="Est. Admit Date:" value={usDateTime(client?.current_admission?.admitted_at, timezone)} />
                      <DataList.Item
                        label="Est. Discharge Date:"
                        value={usDateTime(client?.current_admission?.estimated_discharge, timezone)}
                      />
                      <DataList.Item
                        label="Est. Length of Stay:"
                        value={countWord(
                          'days',
                          daysBetween(client?.current_admission?.admitted_at, client?.current_admission?.estimated_discharge, timezone),
                        )}
                      />
                    </DataList>
                  </CardTreeItem>

                  <CardTreeItem shouldIndent={false} title="All Insurance Policies">
                    <InsurancePoliciesDataView clientID={client?.id} />
                  </CardTreeItem>

                  <CardTreeItem shouldIndent={false} title="All eVOBs">
                    <EVOBDataView clientID={client?.id} />
                  </CardTreeItem>
                </Grid>
              </Section>

              <Divider />

              <VOBServices
                scrollview={{
                  id: 'services',
                  name: 'Services',
                }}
                model="services"
                value={data.services}
              />

              <Divider />

              <Section
                title="Accreditations and Exclusions"
                scrollview={{
                  id: 'accreditations_and_exclusions',
                  name: 'Accreditations and Exclusions',
                }}
              >
                <FormSection>
                  <RadioGroup
                    label="Are any accreditations required for services to be covered?"
                    model="data.accreditations.has_required_accreditations"
                    layout="horizontal-dense"
                  >
                    <Radio label="Yes" value={true} />
                    <Radio label="No" value={false} />
                  </RadioGroup>

                  <ContextShow when="data.accreditations.has_required_accreditations" is={true}>
                    <CheckboxGroup layout="vertical-dense" label="Required Accreditations" trueIcon="check">
                      <Checkbox label="The Joint Commission (JCAHO)" model="data.accreditations.jcaho" />
                      <Checkbox label="Commission on Accreditation of Rehabilitation Facilities (CARF)" model="data.accreditations.carf" />
                      <Checkbox label="Council on Accreditation (COA)" model="data.accreditations.coa" />
                      <Checkbox label="National Committee for Quality Assurance (NCQA)" model="data.accreditations.ncqa" />
                      <Checkbox label="Healthcare Facilities Accreditation Program (HFAP)" model="data.accreditations.hfap" />
                    </CheckboxGroup>
                  </ContextShow>

                  <RadioGroup
                    label="Are free standing facilities covered?"
                    model="data.accreditations.are_free_standing_facilities_covered"
                    layout="horizontal-dense"
                    description="Facilities that are not part of a hospital"
                  >
                    <Radio label="Yes" value={true} />
                    <Radio label="No" value={false} />
                  </RadioGroup>

                  <Textarea label="Are there any exclusions on the plan?" model="data.accreditations.exclusions" />
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Claims Submission Info"
                scrollview={{
                  id: 'claims_submission_info',
                  name: 'Claims Submission Info',
                }}
              >
                <FormSection>
                  <Input label="Payer ID" model="data.claims.payer_id" />

                  <CheckboxGroup label="Type of Claims" layout="vertical-dense">
                    <Checkbox label="Professional" model="data.claims.is_professional" />
                    <Checkbox label="Institutional" model="data.claims.is_institutional" />
                  </CheckboxGroup>

                  <RadioGroup
                    label="Do lab and/or medical claims go to the same address?"
                    model="data.claims.use_same_address"
                    layout="horizontal-dense"
                  >
                    <Radio label="Yes" value={true} />
                    <Radio label="No" value={false} />
                  </RadioGroup>

                  <H4>Claims Mailing Address</H4>
                  <Input label="Address Line 1" model="data.claims.mailing_address.line1" />
                  <Input label="Address Line 2" model="data.claims.mailing_address.line2" />
                  <Input label="City" model="data.claims.mailing_address.city" />
                  <Input label="County" model="data.claims.mailing_address.county" />
                  <Input label="State" model="data.claims.mailing_address.state" />
                  <StateSelect model="data.claims.mailing_address.state" />
                  <Input label="Zip Code" model="data.claims.mailing_address.zipCode" size={8} />

                  <ContextShow when="data.claims.use_same_address" is={false}>
                    <H4>Lab/Medical Claims Mailing Address</H4>
                    <Input label="Address Line 1" model="data.claims.lab_mailing_address.line1" />
                    <Input label="Address Line 2" model="data.claims.lab_mailing_address.line2" />
                    <Input label="City" model="data.claims.lab_mailing_address.city" />
                    <Input label="County" model="data.claims.lab_mailing_address.county" />
                    <StateSelect model="data.claims.lab_mailing_address.state" />
                    <Input label="Zip Code" model="data.claims.lab_mailing_address.zipCode" size={8} />
                  </ContextShow>
                </FormSection>
              </Section>

              <Divider />

              <Section
                title="Notes"
                scrollview={{
                  id: 'internal_notes',
                  name: 'Notes',
                }}
              >
                <FormSection>
                  <Textarea useQuickText model="notes" />
                  <RatingInput glyph="dollar" fullColor={COLORS.mintGreen} label="Financial Quality Score" model="rating" />
                </FormSection>
              </Section>

              <Divider />

              <Section
                scrollview={{
                  id: 'summary',
                  name: 'Summary',
                }}
              >
                <FormSection maxWidth="100%">
                  <H3>Summary</H3>
                  <Textarea useQuickText model="data.summary.notes" />

                  {/* <CheckboxGroup label="Next Steps" layout="vertical-dense">
                      <Checkbox defaultChecked label="Admit client" model="summary.checklist.__TODO__2" />
                      <Checkbox label="BPS" model="summary.checklist.__TODO__3" />
                      <Checkbox
                        label="Financial Plan"
                        description="Confirm Insurance Benefits Usage"
                        model="summary.checklist.__TODO__4"
                      />
                      <Checkbox
                        label="Medical"
                        description="Should be done within 24h from admission"
                        model="summary.checklist.__TODO__5"
                      />
                      <Checkbox
                        label="Nursing Assessment"
                        description="Should be done within 72h from admission"
                        model="summary.checklist.__TODO__6"
                      />
                    </CheckboxGroup> */}

                  {/* <DataArray local model="summary.custom_checklist">
                      {({ ids, add, remove, isEditable }: any) => (
                        <div>
                          <Label label="Next Steps" />

                          <Grid gap="0.5rem">
                            {ids.map((id: string, index: number) => (
                              <Flex nowrap key={id} gap={8} alignItems="center">
                                <div className="!font-[600]">{index + 1}.</div>

                                <Input model={`${id}.task`} className="!flex-auto" />

                                {isEditable && (
                                  <DeleteDialog
                                    title="Delete Checklist Item?"
                                    message="Are you sure you want to delete this checklist item? This action cannot be undone."
                                    onYes={() => remove(id)}
                                  >
                                    <Button hideLabel type="minimal" color="gray" glyph="delete" size={200} />
                                  </DeleteDialog>
                                )}
                              </Flex>
                            ))}

                            {isEditable && <Button label="Add Step" glyph="add"  onClick={add} />}
                          </Grid>
                        </div>
                      )}
                    </DataArray> */}
                </FormSection>
              </Section>

              {!isNew && (
                <>
                  <Divider />

                  <Section headingType="h2" title="Timeline">
                    <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} />
                  </Section>
                </>
              )}
            </Form>
          </Overlay.Content>
        </PageGrid>
      </ScrollView>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save Changes"
              type="primary"
              glyph="check"
              color="green"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isSaving}
              flex="100 1 auto"
              permission={isBehave ? true : isNew ? 'full_vobs.create' : 'full_vobs.edit'}
            />
            <Button label="Cancel" glyph="cross" isDisabled={isSaving} onClick={cancel} />
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit VOB"
              isDisabled={isLoading}
              onClick={edit}
              flex="50 1 auto"
              permission={isBehave || 'full_vobs.edit'}
            />

            {data.status !== 'signed_off' && (
              <ConfirmDialog
                title="Sign Off & Send VOB to Facility?"
                message="No further edits are allowed after the VOB is signed off and sent. This action cannot be undone."
                yesLabel="Sign Off & Send VOB →"
                onYes={signOff}
                render={(openDialog: any) => (
                  <Button
                    glyph="check"
                    label="Sign Off"
                    color="green"
                    onClick={openDialog}
                    isDisabled={isInvalid || isLoading || isSigningOff}
                    flex="50 1 auto"
                    permission={isBehave || 'full_vobs.edit'}
                  />
                )}
              />
            )}

            <DeleteDialog
              title="Delete VOB?"
              message="Are you sure you want to delete this VOB? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete VOB…"
                color="red"
                permission={isBehave || 'full_vobs.delete'}
                isDisabled={isDeleting}
                fullWidth
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export default withOverlayError(VOBOverlay)
