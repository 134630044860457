import React from 'react'
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import FormSection from '../../components/Forms/FormSection'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

import { useCreate } from '../../hooks/useNewAPI'
import Notifications from '../../modules/notifications'

const stripePromise = loadStripe(process.env.BH_STRIPE_BEHAVE_PUBLIC_KEY)

export const StripeForm = ({ data, category, tenant, name, facilityId, recordId, recordType, serviceCustomerId, onSuccess }: any) => {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = React.useState(null)
  const [isPaymentElementComplete, setIsPaymentElementComplete] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const { mutateAsync: registerPaymentMethod, isLoading: isRegisteringPaymentMethod } = useCreate({
    name: ['global_payment_methods'],
    url: `/global_payment_methods/register`,
  })

  const handlePaymentElementChange = (event: any) => {
    setIsPaymentElementComplete(event.complete && !event.error)
  }

  const handleServerResponse = async (response: any) => {
    if (response.error) {
      // Show error from server on payment form
    } else if (response.status === 'requires_action') {
      // Use Stripe.js to handle the required next action
      const { error, paymentIntent } = await stripe.handleNextAction({
        clientSecret: response.clientSecret,
      })

      if (error) {
        // Show error from Stripe.js in payment form
      } else {
        // Actions handled, show success message
      }
    } else {
      // No actions needed, show success message
      Notifications.send('Payment Method Added Successfully', 'positive')

      if (onSuccess) onSuccess()
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (elements == null) return

    setIsLoading(true)

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message)
      return
    }

    const { error: confirmationTokenError, confirmationToken } = await stripe.createConfirmationToken({ elements })

    if (confirmationTokenError) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // data incomplete)
      setErrorMessage(confirmationTokenError.message)
      setIsLoading(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      // SEND REQUEST TO API TO CONFIRM PAYMENT
      try {
        // run
        const payQuoteResult = await registerPaymentMethod({
          confirmation_token: confirmationToken.id,
          tenant: tenant,
          facility_id: facilityId,
          record_category: category,
          name: name,
          service_customer_id: serviceCustomerId,
          record_id: recordId,
          record_type: recordType,
        })

        handleServerResponse(payQuoteResult)
      } catch (error) {
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <FormSection css={{ marginTop: 0 }}>
      <PaymentElement
        options={{
          layout: 'tabs',
          business: { name: data?.company_name },
          fields: {
            billingDetails: {
              name: 'auto',
            },
          },
          defaultValues: {
            billingDetails: {
              name: data?.name,
              email: data?.email,
              phone: data?.phone_no,
            },
          },
        }}
        onChange={handlePaymentElementChange}
      />

      {errorMessage && <Alert glyph="error">{errorMessage}</Alert>}

      <Button
        label="Add Payment Method"
        color="green"
        type="primary"
        glyph="tick_circle"
        className="mt-4"
        onClick={handleSubmit}
        isDisabled={!isPaymentElementComplete || isRegisteringPaymentMethod}
        isLoading={isLoading || isRegisteringPaymentMethod}
      />
    </FormSection>
  )
}

const AddPaymentMethodOverlay = ({
  category,
  tenant,
  facilityId,
  name,
  recordId,
  recordType,
  serviceCustomerId,
  data,
  onClose,
  onSuccess,
}: any) => {
  const closeOverlay = async () => {
    if (onClose) onClose()
  }

  const options = React.useMemo(() => {
    return {
      mode: 'setup',
      currency: 'usd',
      setupFutureUsage: 'off_session',
      paymentMethodTypes: ['card', 'us_bank_account'],
      captureMethod: 'automatic',
      appearance: {},
    }
  }, [])

  return (
    <Overlay showBackdrop position="center" onClose={closeOverlay} maxWidth={35}>
      <Overlay.Header title="Add Payment Method" glyph="dollar" />

      <Overlay.Content>
        <Section>
          <Elements stripe={stripePromise} options={options}>
            <StripeForm
              category={category}
              tenant={tenant}
              facilityId={facilityId}
              name={name}
              serviceCustomerId={serviceCustomerId}
              recordId={recordId}
              recordType={recordType}
              data={data}
              onClose={onClose}
              onSuccess={() => {
                if (onSuccess) onSuccess()

                closeOverlay()
              }}
            />
          </Elements>
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}

export default AddPaymentMethodOverlay
