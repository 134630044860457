import React from 'react'

import { COLORS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import ColorSelector from '../../components/Forms/ColorSelector'
import ContextShow from '../../components/Forms/ContextShow'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import GlyphSelector from '../../components/Forms/GlyphSelector'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Select from '../../components/Forms/Select'
import TableArrayForm from '../../components/Forms/TableArrayForm'
import Textarea from '../../components/Forms/Textarea'

import TagSmartCategorySelector from './TagSmartCategorySelector'

import { COLOR_PALETTE } from './TagOverlay'

const RootTagGroupInternalTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'internal-tag-group-template',
    endpoint: '/internal_templates',
    invalidate: 'internal-templates',
    options: props,
  })

  if (isOverlayLoading) return <OverlayLoader showBackdrop={isEditable} position="right" maxWidth={82} />

  return (
    <Overlay showBackdrop={isEditable} position="right" maxWidth={100} closeOnBackdrop={!isEditable} onClose={close}>
      <Overlay.Header icon="tags" title="Tag Group Template" />

      <Overlay.Content css={STYLES.overlayContent}>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={{ category: 'tag_group' }}
          css={STYLES.form}
        >
          <aside css={STYLES.aside}>
            <FormSection>
              <FormSection>
                <Flex stretchChildrenX gap="1rem">
                  <Select model="status" label="Status" fullWidth defaultValue="active">
                    <Option label="Active" value="active" />
                    <Option label="Archived" value="archived" />
                  </Select>

                  <Select model="level" label="Level" fullWidth defaultValue="behave_template">
                    <Option label="Behave Template" value="behave_template" />
                    <Option label="Public Template" value="public_template" />
                  </Select>
                </Flex>

                <Input
                  label="Group Name"
                  model="data.name"
                  validations={{
                    presence: {
                      message: 'Please enter a group name',
                    },
                  }}
                />

                <Textarea useQuickText label="Description" model="data.description" />

                <RadioGroup
                  model="data.category"
                  label="Category"
                  layout="horizontal-dense"
                  description="Use the Smart Categories to limit Tags only to certain records"
                  defaultValue="general"
                >
                  <Radio label="General" value="general" />
                  <Radio label="Smart" value="smart" />
                </RadioGroup>
              </FormSection>
            </FormSection>

            <ContextShow when="data.category" is="smart">
              <TagSmartCategorySelector model="data.smart_categories" value={initialModel?.data?.smart_categories} />
            </ContextShow>
          </aside>

          <main css={STYLES.main}>
            <TableArrayForm name="Tags" model="data.tags" inputs={ARRAY_INPUTS} />
          </main>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save Group"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              // permission={true}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit Group" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Group?"
              message="Are you sure you want to delete this group? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                // permission={true}
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const ARRAY_INPUTS = [
  {
    name: 'Icon',
    width: '100px',
    isRequired: true,
    element: (id: string, data: any) => <GlyphSelector model={`${id}.glyph`} defaultValue="circle" color={data.color} />,
  },
  {
    name: 'Color',
    width: '100px',
    isRequired: true,
    element: (id: string, data: any) => <ColorSelector model={`${id}.color`} defaultValue={COLOR_PALETTE[0]} colors={COLOR_PALETTE} />,
  },
  {
    name: 'Tag Name',
    width: '1fr',
    isRequired: true,
    element: (id: string, data: any) => (
      <Input
        model={`${id}.name`}
        validations={{
          presence: {
            message: 'Please enter a tag name',
          },
        }}
      />
    ),
  },
  {
    name: 'Tag Description',
    width: '1fr',
    element: (id: string, data: any) => <Textarea model={`${id}.description`} minRows={1} />,
  },
]

const STYLES = {
  overlayContent: {
    '@media(min-width: 900px)': {
      display: 'grid',
      gridTemplateRows: '100%',
      gridTemplateColumns: '100%',
      overflow: 'hidden',
    },
  },

  form: {
    '@media(min-width: 900px)': {
      display: 'grid',
      gridTemplateRows: '100%',
      gridTemplateColumns: '400px 1fr',
      overflow: 'hidden',
    },
  },

  aside: {
    padding: '1rem 1.5rem',

    '@media(min-width: 900px)': {
      overflowY: 'auto',
      paddingBottom: '1.5rem',
      borderRight: `1px solid ${COLORS.divider}`,
    },
  },

  main: {
    padding: '0 1.5rem 1.5rem',

    '.is-dragging': {
      top: 'auto !important',
    },

    '@media(min-width: 900px)': {
      padding: '1rem 1.5rem',
      overflowY: 'auto',
      paddingBottom: '1.5rem',
    },
  },
}

export const TagGroupInternalTemplateOverlay = withOverlayError(RootTagGroupInternalTemplateOverlay)
