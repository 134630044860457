import React from 'react'
import clsx from 'clsx'

import Flex from '../../../components/Flex'
import Type from '../../../components/Typography/Type'

export const SidebarGroup = ({ aside, title, titleVariant = 'CAPS_TITLE_SMALL', children, className, fullWidthContent }: any) => {
  const classNames = clsx(fullWidthContent && 'full-width-content', className)

  return (
    <div css={styles.root} className={classNames}>
      <Flex nowrap centerY gap="0.5rem" justifyContent="space-between">
        {title && (
          <Type as="h3" variant={titleVariant} css={styles.title}>
            {title}
          </Type>
        )}
        {aside && <div>{aside}</div>}
      </Flex>

      {children && <div css={styles.content}>{children}</div>}
    </div>
  )
}

const styles = {
  root: {
    '--padding-x': '0.75rem',
    '--padding-y': '0.5rem',

    padding: 'var(--padding-y) var(--padding-x)',

    '&:last-child': {
      borderBottom: 'none',
    },
  },

  title: {
    margin: 0,
    paddingTop: '0.35rem',
    marginBottom: '0.5rem',
    fontSize: '0.95rem',
    fontWeight: 700,
  },

  content: {
    marginTop: '0.75rem',

    '.full-width-content > &': {
      marginLeft: 'calc(var(--padding-x) * -1)',
      marginRight: 'calc(var(--padding-x) * -1)',
    },
  },
}
