import React from 'react'

import withSettings from '../../../hocs/withSettings'

import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'

const columns = (timezone: string) => [
  {
    width: 130,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'created_at',
    Header: 'Sent At',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 70,
    accessor: 'status',
    Header: 'Status',
  },
  {
    width: 70,
    accessor: 'category',
    Header: 'Category',
  },
  {
    width: 200,
    accessor: 'record',
    Header: 'Record',
    Cell: ({ value }: any) => <TableCell.JSON value={value} />,
  },
  {
    width: 200,
    accessor: 'request',
    Header: 'Request',
    Cell: ({ value }: any) => <TableCell.JSON value={value} />,
  },
  {
    width: 200,
    accessor: 'response',
    Header: 'Response',
    Cell: ({ value }: any) => <TableCell.JSON value={value} />,
  },
  {
    width: 160,
    accessor: 'author',
    Header: 'Sent By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

const ChangeHealthRequestsTable: React.FC<any> = (props) => {
  const {
    emptyDescription = 'No claim requests yet',
    icon = 'claim_notes',
    localStorageKey = 'insurance_change_health_requests',
    timezone,
    title = 'Clearing House Requests',
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      columns={columns(timezone)}
      emptyDescription={emptyDescription}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

export default withSettings(ChangeHealthRequestsTable)
