import React from 'react'
import { v4 as uuid } from 'uuid'
import produce from 'immer'
import size from 'lodash/size'

import { withFormContext } from './context'
import { useFormField } from './hooks/useFormField'

import Form from './Form'

export const arrayToUUIDMap = (value: any) => {
	if (!value) return { map: {}, ids: [] }
	if (!Array.isArray(value)) return { map: {}, ids: [] }

	let map = {}
	let order = []
	for (let i = 0; i < value?.length; i++) {
		const id = value[i]?._id || uuid()

		map[id] = value[i]
		order.push(id)
	}

	return { map, order }
}

const DataArray = ({
	children,
	isNested = false,
	form,
	model,
	timezone,
	isEditable,
	onUpdate,
	onValidationUpdate,
	startWithEmptyRecord,
	value,
	newRecord = {},
	isCompact
}: any) => {
	const { formActions } = useFormField({
		debug: false,
		model: model,
		form: form,
		defaultEmptyValue: [],
		isNested: isNested,
		validateOn: null, // don't validate using the normal validators
		afterChange: onUpdate
	})

	const initialValue = React.useRef(value || form?.getInitialInputFieldValue(model) || [])
	const initialDataStructure = React.useRef(
		arrayToUUIDMap(size(initialValue.current) === 0 && startWithEmptyRecord ? [{}] : initialValue.current)
	)

	const [data, setData]: any = React.useState(initialDataStructure.current.map)
	const [deletedData, setDeletedData]: any = React.useState([])
	const [orderIds, setOrderIds]: any = React.useState(initialDataStructure.current.order)
	const [isValid, setIsValid]: any = React.useState(true)

	const add = () => {
		const newID = uuid()

		// add to data
		setData((currentData: any) => ({
			...currentData,
			[newID]: newRecord
		}))

		// add to order
		if (!orderIds) {
			setOrderIds(() => [newID])
		} else {
			setOrderIds((currentOrderIds: any) => [...currentOrderIds, newID])
		}
	}

	const remove = (id: string) => {
		// update the Deleted Data with toDelete if it exists
		if (data[id]?.id) {
			setDeletedData((currentDeletedData: any) => [...currentDeletedData, { ...data[id], _destroy: 1 }])
		}

		// remove from map data
		const newData = produce(data, (draft: any) => {
			delete draft[id]
		})

		setData(newData)

		// deal with the order of the data
		const newOrderIds = produce(orderIds, (draft: any) => {
			const index = orderIds.indexOf(id)
			if (index === -1) return

			draft.splice(index, 1)
		})

		setOrderIds(newOrderIds)
	}

	const onDragAndDrop = (result: any) => {
		if (!result.destination) return // dragged outside the list

		const fromIndex = result.source.index
		const toIndex = result.destination.index

		const newOrder = produce(orderIds, (draft: any) => {
			const [removed] = draft.splice(fromIndex, 1)
			draft.splice(toIndex, 0, removed)
		})

		setOrderIds(newOrder)
	}

	React.useEffect(() => {
		if (!data) return

		let array = []
		for (let i = 0; i < orderIds?.length; i++) array.push(data[orderIds[i]])

		const orderSize = size(orderIds)

		formActions.setValue([...array, ...deletedData])

		// if there are no elements, set the validation to true
		if (orderSize > 0)
			formActions.setValidity(isValid) // update validity
		else formActions.setValidity(true)
	}, [data, orderIds])

	React.useEffect(() => {
		if (onValidationUpdate) onValidationUpdate(isValid)

		const orderSize = size(orderIds)

		// if there are no elements, set the validation to true
		if (orderSize > 0)
			formActions.setValidity(isValid) // update validity
		else formActions.setValidity(true)
	}, [isValid])

	return (
		<Form
			as="div"
			css={{ display: 'contents' }}
			initialModel={data}
			isEditable={isEditable}
			timezone={timezone}
			onUpdate={setData}
			onValidationUpdate={setIsValid}
			isCompact={isCompact}
		>
			{children({
				orderedIds: orderIds,
				elements: data,
				add: add,
				remove: remove,
				isEditable: isEditable,
				onDragAndDrop: onDragAndDrop
			})}
		</Form>
	)
}

export default withFormContext(DataArray)
