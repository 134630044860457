import React from 'react'

import { amount } from '../../utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Status from '../../components/Status'
import ClientColorPicker from '../../components/ColorPickers/ClientColorPicker'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const RefundsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.name} to={to?.(data.data)} />,
      },
      {
        width: 180,
        id: 'resident',
        model: 'data.resident.name',
        title: 'Client',
        type: 'profile',
      },
      {
        title: 'Color',
        id: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => (
          <ClientColorPicker isDisabled clientID={data.data?.resident?.id} color={data.data?.resident?.color_code} />
        ),
      },
      {
        width: 180,
        id: 'amount',
        model: 'data.amount',
        title: 'Amount',
        formatValue: ({ data, value }: any) => <Status color="gray" label={`+$${amount(value)}`} />,
      },
      {
        id: 'transactioned_at',
        model: 'data.transactioned_at',
        title: 'Refund Date',
        type: 'date',
      },
      {
        id: 'notes',
        model: 'data.notes',
        title: 'Notes',
      },
      {
        id: 'client_location',
        model: 'data.client_location.name',
        title: 'Client Location',
        type: 'profile',
      },
      {
        width: 180,
        id: 'author',
        model: 'data.author.name',
        title: 'Added By',
        type: 'profile',
      },
      {
        width: 140,
        id: 'created_at',
        model: 'data.created_at',
        title: 'Create Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 140,
        id: 'updated_at',
        model: 'data.updated_at',
        title: 'Last Update Date',
        type: 'date_time',
        disableSort: false,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Refunds"
      icon="financials"
      columns={columns}
      filtersConfig={FILTERS.live_reports.financial_transactions}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="refunds"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=refunds'}
        />
      }
      {...props}
    />
  )
}
