import React from 'react'

import { usDate, usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'
import DataList from '../../../components/DataList'
import EventStatus from '../../../components/Statuses/EventStatus'

export const EventInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item label="Title" value={data.title} />
      <DataList.Item label="Status" value={<EventStatus status={data.status} />} />
      <DataList.Item label="Date Added" value={usDateTime(data.created_at, timezone)} />
      <DataList.Item label="Last Edited" value={usDateTime(data.updated_at, timezone)} />
    </DataList>
  )
}
