import React from 'react'
import get from 'lodash/get'
import { withFormContext } from '../../../../Forms/context'

import Button from '../../../../Button'
import State from '../../../../State'
import Section from '../../../../Section'
import { P } from '../../../../Typography'
import { COLORS } from '../../../../../theme'

import DateInput from '../../../../Forms/DateInput'
import FormSection from '../../../../Forms/FormSection'
import Input from '../../../../Forms/Input'
import Select from '../../../../Forms/Select'
import Option from '../../../../Forms/Option'
import SmartTextarea from '../../../../Forms/SmartTextarea'

class Problems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      problems: get(props.form.getInitialModel(), `${props.model}.problems`, []),
    }
  }

  /*
    CUSTOM
  */
  addProblem = () => {
    let problems = this.state.problems

    problems.push({
      status: 'inactive',
      name: '',
      created_at: Date.now(),
      created_by: '',
    })

    this.setState({ problems: problems })
  }

  removeProblem = (idx) => {
    let problems = this.state.problems
    problems.splice(idx, 1)
    this.setState({ problems: problems })
  }

  renderTrigger = () => {
    return (
      this.props.isEditable && <Button type="default" glyph="add" label="Add Problem" onClick={this.addProblem} display="inline-flex" />
    )
  }

  /*
    RENDER
  */
  render() {
    const { problems } = this.state
    const { model, isEditable } = this.props

    const isEmpty = problems.length === 0

    return (
      <State isEmpty={isEmpty} emptyDescription="No data found" emptyActions={this.renderTrigger()}>
        <P>It has been determined that the client is in need of and/or has requested assistance for the following problems:</P>

        {problems.map((_problem, idx) => (
          <Section
            key={idx}
            title="Problem"
            aside={
              isEditable && (
                <Button label="Remove" glyph="delete" type="minimal" color="red" size="small" onClick={() => this.removeProblem(idx)} />
              )
            }
            css={styles}
          >
            <FormSection layout="vertical" maxWidth="100%">
              <FormSection horizontal>
                <Select allowEmpty label="Status" model={`${model}.problems[${idx}].status`}>
                  <Option label="Inactive" value="inactive" />
                  <Option label="In Progress" value="in_progress" />
                  <Option label="Completed" value="completed" />
                  <Option label="Extended" value="extended" />
                  <Option label="Cancelled" value="cancelled" />
                  <Option label="Unmet" value="unmet" />
                </Select>
                <DateInput label="Start Date" model={`${model}.problems[${idx}].created_at`} />
              </FormSection>
              <Input model={`${model}.problems[${idx}].name`} label="Name" />
              <SmartTextarea useDictation label="Behavioral Definitions" model={`${model}.problems[${idx}].behavioral_definitions`} />
            </FormSection>
          </Section>
        ))}
        {this.renderTrigger()}
      </State>
    )
  }
}

Problems.defaultProps = {
  isEditable: false,
  model: 'data',
}

const styles = {
  border: `2px solid ${COLORS.divider}`,
  borderRadius: 7,
  padding: '0.75rem',
}

export default withFormContext(Problems)
