import React from 'react'

import Flex from '../../../../components/Flex'
import Input from '../../../../components/Forms/Input'
import NativeColorInput from '../../../../components/Forms/NativeColorInput'
import Option from '../../../../components/Forms/Option'
import Select from '../../../../components/Forms/Select'

export const LottieAnimationEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate } = props

  const { lottie_background_color, lottie_max_width = 100, lottie_max_width_type = 'percentage', lottie_url } = activeElement.config

  return (
    <>
      <Input label="Lottie URL" model="lottie_url" value={lottie_url} onUpdate={onUpdate} />

      <Flex nowrap gap="0.75rem">
        <Input label="Max Width" type="number" model="lottie_max_width" size={4} value={lottie_max_width} onUpdate={onUpdate} />
        <Select label="Type" model="lottie_max_width_type" value={lottie_max_width_type} onUpdate={onUpdate} flex="1 1 auto">
          <Option label="Percent" value="percentage" />
          <Option label="Pixels" value="pixels" />
        </Select>
      </Flex>

      <NativeColorInput label="Background Color" model="lottie_background_color" value={lottie_background_color} onUpdate={onUpdate} />
    </>
  )
}
