import React from 'react'

import { COLORS } from '../../../theme'
import Glyph from '../../../components/Glyph'

import { FilterSegment } from './FilterSegment'

export const FilterLabel: React.FC<any> = (props) => {
  const { label, glyph } = props

  return (
    <FilterSegment css={STYLES.root}>
      <Glyph glyph={glyph || 'filter'} color={COLORS.blue} size={14} css={STYLES.labelGlyph} />
      <span css={STYLES.label}>{label}</span>
    </FilterSegment>
  )
}

const STYLES = {
  root: {
    padding: '0 0.4rem',
  },

  labelGlyph: {
    marginRight: '0.25rem',
  },

  label: {
    display: 'inline-block',
    fontWeight: 600,
  },
}
