import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import SmartStatus from '@behavehealth/components/SmartStatus'

const STATUSES = {
  paid: {
    label: 'Paid',
    color: 'green',
  },
  open: {
    label: 'Open',
    color: 'yellow',
  },
  closed: {
    label: 'Closed',
    color: 'red',
  },
  void: {
    label: 'Void',
    color: 'yellow',
  },
  draft: {
    label: 'Archived',
    color: 'gray',
  },
  unknown: {
    label: 'Unknown',
    color: 'gray',
  },
}

const RootEstimates = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting', 'estimates'],
    endpoint: `/accounting/estimates`,
    // params: {...},
    localStorageKey: 'accounting_estimates_v1',
  })

  const { mutateAsync: syncRailzToBh, isLoading: isSyncingRailz } = useCreate({
    name: ['sync-railz-to-bh'],
    url: '/accounting/estimates/sync_railz_to_bh',
    invalidate: tableProps.queryKey,
  })

  const { mutateAsync: syncQbToRailz, isLoading: isSyncingQb } = useCreate({
    name: ['sync-qb-to-railz'],
    url: '/accounting/estimates/sync_qb_to_railz',
  })

  const handleSyncRailzToBh = async () => {
    await syncRailzToBh()
    tableProps.refetch()
  }

  const handleSyncQbToRailz = () => {
    syncQbToRailz()
  }

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Memo',
        model: 'memo',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.memo} />,
      },
      {
        title: 'Quickbooks Status',
        model: 'status',
        width: 160,
        disableHide: true,
        formatValue: ({ value }) => <SmartStatus statuses={STATUSES} status={value} />,
      },
      {
        title: 'Discount Percentage',
        model: 'discount_percentage',
        width: 200,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Total Discount',
        model: 'total_discount',
        width: 200,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Currency Rate',
        model: 'currency_rate',
        width: 200,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Tax Amount',
        model: 'tax_amount',
        width: 200,
        disableHide: true,
      },
      {
        title: 'Sub Total',
        model: 'sub_total',
        width: 200,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Total Amount',
        model: 'total_amount',
        width: 200,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Due Date',
        model: 'due_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
      {
        title: 'Posted Date',
        model: 'posted_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
      {
        title: 'Accepted Date',
        model: 'accepted_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
      {
        title: 'Expiry Date',
        model: 'expiry_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
      {
        title: 'Quickbooks Last Updated',
        model: 'source_modified_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Estimates">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Estimates"
          icon="accounting"
          emptyDescription="No estimates have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          columns={columns}
          {...tableProps}
          headerAfter={
            isBehave && (
              <Flex gap="0.5rem">
                <AccountingSyncButton
                  forceShow={true}
                  category="estimates"
                  invalidate={tableProps.queryKey}
                  refetchUrl={'/accounting/sync?category=estimates'}
                />
              </Flex>
            )
          }
        />
      </Grid>
    </Page>
  )
}

export const Estimates = withPageError(RootEstimates)

const FILTERS_CONFIG = {
  memo: {
    label: 'Memo',
    type: 'string',
  },
  default_currency_ref_id: {
    label: 'Default Currency Ref Id',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'string',
  },
  discount_percentage: {
    label: 'Discount Percentage',
    type: 'number',
  },
  total_discount: {
    label: 'Total Discount',
    type: 'number',
  },
  currency_rate: {
    label: 'Currency Rate',
    type: 'number',
  },
  sub_total: {
    label: 'Sub Total',
    type: 'number',
  },
  tax_amount: {
    label: 'Tax Amount',
    type: 'number',
  },
  total_amount: {
    label: 'Total Amount',
    type: 'number',
  },
  posted_date: {
    label: 'Posted Date',
    type: 'datetime',
  },
  due_date: {
    label: 'Due Date',
    type: 'datetime',
  },
  accepted_date: {
    label: 'Accepted Date',
    type: 'datetime',
  },
  expiry_date: {
    label: 'Expiry Date',
    type: 'datetime',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'datetime',
  },
}
