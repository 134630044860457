import React from 'react'

import RadioGroup from '../../../../components/Forms/RadioGroup'
import Radio from '../../../../components/Forms/Radio'

// TODO: add back after API update

export const InputEditPermissions = (props: any) => {
  return null

  //

  //

  // const { activeElement, editActiveElementFromInput } = props

  //

  // const { config } = activeElement
  // const { edit_permission } = config

  // return (
  //   <>
  //     <RadioGroup
  //       label="Edit Permission"
  //       layout="vertical-dense"
  //       model="edit_permission"
  //       defaultValue="all"
  //       value={edit_permission}
  //       onUpdate={editActiveElementFromInput}
  //     >
  //       <Radio label="Everyone" value="all" />
  //       <Radio label="Staff Only" value="staff" />
  //       <Radio label="Clients Only" value="clients" />
  //     </RadioGroup>
  //   </>
  // )
}
