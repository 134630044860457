import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useAPI } from '@behavehealth/hooks'
import { apiDelete } from '@behavehealth/modules/api'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, Page, HelpTagIframe } from '@behavehealth/components'
import CredentialsTable from '@behavehealth/components/Tables/CredentialsTable'

const pageConfig = {
  feature: 'staff_credentials',
  help: <HelpTagIframe id="staff_credentials" />,
  title: 'Credentials',
}

const Credentials: React.FC = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const data = useSelector((state: any) => state.data?.credentials?.data)
  const loading = useSelector((state: any) => state.data?.credentials?.loading)

  useAPI('credentials', `/employees/${resource_id}/credentials`)

  const isEmpty = size(data) === 0

  const actions = (
    <Button
      label="Add Credential"
      type="primary"
      glyph="add"
      link={{
        pathname: `${match.url}/new`,
      }}
      permission="credentials.create"
    />
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <div className="grid grid-cols-[100%]">
        <Card>
          <CredentialsTable
            title="Credentials"
            icon="staff_credentials"
            data={data}
            isLoading={isEmpty && loading}
            emptyActions={actions}
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'credentials.delete',
                action: async ({ ids }: any) => {
                  await apiDelete({ name: 'credentials', url: `/credentials/${ids.join(',')}` })
                },
              },
            ]}
          />
        </Card>
      </div>
    </Page>
  )
}

export default withPageError(withMarketing(Credentials, pageConfig))
