import React from 'react'

import { COLORS } from '../../theme'

import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Avatar from '../../components/Avatar'
import BedOccupancyStatus from '../../components/Statuses/BedOccupancyStatus'
import DataList from '../../components/DataList'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'

export const BedAssignmentInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data?.place) return null

  const { status, place: bed } = data
  const { house, floor, room } = bed

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item label="Status" value={<BedOccupancyStatus status={status} />} />

      {house && (
        <DataList.Item
          label="Location"
          value={
            <Flex centerY nowrap gap="0.4rem">
              <Avatar src={house.avatar} initials={house.name} size={24} />
              <div className="!font-[600]">{house.name}</div>
            </Flex>
          }
        />
      )}

      <DataList.Item
        label="Bed"
        value={
          <Flex centerY gap="0.5rem">
            {floor && (
              <div>
                <Flex nowrap centerY gap="0.3rem">
                  <Icon icon="floors" size={20} />
                  <div>{floor.name}</div>
                </Flex>
              </div>
            )}

            {room && (
              <>
                <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                <div>
                  <Flex nowrap centerY gap="0.3rem">
                    <Icon icon="rooms" size={20} />
                    <div>{room.name}</div>
                  </Flex>
                </div>
              </>
            )}

            {bed && (
              <>
                <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                <div>
                  <Flex nowrap centerY gap="0.3rem">
                    <Icon icon="beds" size={20} />
                    <div>{bed.name}</div>
                  </Flex>
                </div>
              </>
            )}
          </Flex>
        }
      />

      <DataList.Item label={status === 'occupied' ? 'Occupied From' : 'Reserved From'} value={usDateTime(data.started_at, timezone)} />

      <DataList.Item
        label={status === 'occupied' ? 'Occupied Until' : 'Reserved Until'}
        value={data.period_type === 'indefinite' ? 'Discharged' : usDateTime(data.ended_at, timezone)}
      />
    </DataList>
  )
}
