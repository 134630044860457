import React from 'react'

import { COLORS } from '../../../theme'

import { FilterSegment } from './FilterSegment'

export const FilterCondition: React.FC<any> = ({ condition }) => {
  if (!condition) return null

  return (
    <FilterSegment css={STYLES.root}>
      <div css={STYLES.condition}>{condition}</div>
    </FilterSegment>
  )
}

const STYLES = {
  root: {
    padding: '0 0.4rem',
  },

  condition: {
    display: 'inline-block',
    color: COLORS.textMuted,
  },
}
