import React from 'react'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'

import { Page, Grid, Tab, TabList, Tabs, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import Active from './active'
import Completed from './completed'
import TodoPageActions from '../../../../../components/TodoPageActions'

const pageConfig = {
  feature: 'todos',
  help: <HelpTagIframe id="todos" />,
  marketingID: 'todos',
}

const Tasks = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { params, url } = match

  const { data: organization }: any = useGet({
    name: ['organization', params?.resource_id],
    url: `/organizations/${params?.resource_id}`,
  })

  return (
    <Page
      breakpoint="0"
      actions={canCreate && <TodoPageActions data={{ meeting_place: 'organization', organization: organization }} />}
      {...pageConfig}
    >
      <Grid gap={16}>
        <Tabs>
          <TabList css={styles.tabList}>
            <Tab label="Active" to={`${url}/active`} />
            <Tab label="Completed" to={`${url}/completed`} />
          </TabList>
        </Tabs>

        <Switch>
          <Route path={`${match.path}/active`} children={<Active canCreate={canCreate} />} />
          <Route path={`${match.path}/completed`} children={<Completed canCreate={canCreate} />} />

          <Redirect exact from={url} to={`${url}/active`} />
        </Switch>
      </Grid>
    </Page>
  )
}

const styles = {
  tabList: {
    marginTop: '-1rem',
  },
}

export default withPageError(withMarketing(Tasks, pageConfig))
