import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5-compat'
import { tint } from 'polished'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'

import { COLORS } from '../../../theme'
import { usDateShort, usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import Avatar from '../../../components/Avatar'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import Glyph from '../../../components/Glyph'
import GridTable from '../../../components/GridTable'
import Status from '../../../components/Status'
import SummonOverlay from '../../../components/SummonOverlay'
import Tooltip from '../../../components/Tooltip'

import { ReviewOverlay, STATUSES } from './TreatmentPlanReviews'

export const LastReviewCell = ({ reference, canEdit, planId, permissionBase }: any) => {
	const name = reference?.last_treatment_review?.name
	const notes = reference?.last_treatment_review?.notes
	const classes = clsx('flex items-center !p-0', !notes && 'text-text-strongly-muted')

	return (
		<GridTable.Cell className={classes}>
			<div className="flex-[1_1_auto] min-w-0 truncate mr-1.5 self-stretch flex items-center">
				{reference?.last_treatment_review?.id ? (
					<Tooltip
						content={
							<>
								<div>
									<b>Open Plan Review</b>
								</div>

								<div>{name}</div>
								<div>{notes}</div>
							</>
						}
						className="flex-[1_1_auto] min-w-0 truncate self-stretch flex items-center"
					>
						<NavLink
							className="flex-[1_1_auto] min-w-0 truncate self-stretch flex items-center px-2 py-1.5 [&.active]:!bg-vivid-blue-100 font-[500]"
							to={`reviews/${reference?.last_treatment_review?.id}`}
						>
							<span className="truncate">{name || notes || '–'}</span>
						</NavLink>
					</Tooltip>
				) : (
					<div className="px-2 py-1.5">–</div>
				)}
			</div>

			{canEdit && (
				<div className="flex items-center pr-2">
					<SummonOverlay
						overlay={
							<ReviewOverlay
								dataID="new"
								initialData={{ status: 'completed' }}
								reference={reference}
								canEdit={canEdit}
								planId={planId}
								permissionBase={permissionBase}
							/>
						}
					>
						<Button label="Add Review" size={100} glyph="add" className="!whitespace-nowrap" />
					</SummonOverlay>
				</div>
			)}
		</GridTable.Cell>
	)
}

export const LastReviewDateScoreCell = ({ reference }: any) => {
	const { timezone } = useSettings()

	const score = STATUSES?.[reference?.last_treatment_review?.score]

	const reviewDate = reference?.last_treatment_review?.dated_at
		? usDateTime(reference?.last_treatment_review?.dated_at, timezone)
		: '–'

	return (
		<GridTable.Cell className={'flex items-center truncate'}>
			<div className={clsx('flex-[1_1_auto] pr-2 truncate', !reviewDate ? 'text-text-strongly-muted' : '')}>
				{reviewDate}
			</div>

			{score && (
				<div className="min-w-0">
					<Status small label={score?.label?.replace?.('Score: ', '')} color={score.color} />
				</div>
			)}
		</GridTable.Cell>
	)
}

export const TextCell = ({ value }: any) => {
	return <GridTable.Cell className={!value ? 'text-text-strongly-muted' : ''}>{value || '–'}</GridTable.Cell>
}

export const DateCell = ({ value, isApplicable }: any) => {
	const { timezone } = useSettings()

	if (!isUndefined(isApplicable) && !isApplicable) {
		return <GridTable.Cell className="text-text-strongly-muted italic">N/A</GridTable.Cell>
	}

	return (
		<GridTable.Cell className={!value ? 'text-text-strongly-muted' : ''}>
			{value ? usDateShort(value, timezone) : '–'}
		</GridTable.Cell>
	)
}

export const DateTimeCell = ({ value }: any) => {
	const { timezone } = useSettings()

	return (
		<GridTable.Cell className={!value ? 'text-text-strongly-muted' : ''}>
			{value ? usDateTime(value, timezone) : '–'}
		</GridTable.Cell>
	)
}

export const ToggleHeaderCell = ({ isAllOpen, onClick, className }: any) => {
	const classes = clsx('flex flex-nowrap justify-center items-center text-blue-500 cursor-pointer', className)

	return (
		<GridTable.Cell onClick={onClick} className={classes}>
			<Button
				glyph={isAllOpen ? 'close_all_rows' : 'open_all_rows'}
				testKey="tree_toggle_all_button"
				label={isAllOpen ? 'Close All' : 'Open All'}
				size={100}
				type="default"
			/>
		</GridTable.Cell>
	)
}

export const ToggleCell = ({ isOpen, onClick, hasChildren, label, className }: any) => {
	const classNames = clsx('!flex !items-center !flex-nowrap', hasChildren && 'cursor-pointer hover:bg-hover', className)
	const glyphClassNames = clsx(!isOpen && '-rotate-90', !hasChildren && '!fill-text-strongly-muted opacity-0')

	return (
		<GridTable.Cell className={classNames} onClick={hasChildren ? onClick : undefined}>
			<Glyph glyph="triangle_down" className={glyphClassNames} size={9} color={COLORS.blue} />

			<Status small label={label} className="!bg-transparent !text-text !m-0 !p-0" />
		</GridTable.Cell>
	)
}

export const ProfileCell = ({ avatar, name, className }: any) => {
	const classNames = clsx('!flex !items-center !flex-nowrap', className)

	return (
		<GridTable.Cell className={classNames}>
			{name ? (
				<>
					<Avatar src={avatar} initials={name} size={20} />
					<div className="font-[500] ml-1">{name}</div>
				</>
			) : (
				<div className="italic opacity-50">N/A</div>
			)}
		</GridTable.Cell>
	)
}

export const LinkCell = ({ to, label, glyph, onClick, children, state }: any) => {
	const classNames = clsx('relative hover:bg-hover truncate', !label ? 'italic' : '')

	return (
		<GridTable.Cell className={classNames} onClick={onClick}>
			{!to ? (
				<div className="text-text-muted opacity-70">–</div>
			) : (
				<Link
					to={to}
					state={state}
					className="flex items-center absolute pl-2 top-0 bottom-0 left-0 right-0 hover:bg-vivid-blue-100 font-[500]"
				>
					{glyph && <Glyph glyph={glyph} size={12} className="mr-1.5" color={COLORS.blue} />}
					{label && <div className="w-full truncate text-[0.85rem]">{label}</div>}
					{children}
				</Link>
			)}
		</GridTable.Cell>
	)
}

export const NavLinkCell = ({ to, label, className }: any) => {
	const classNames = clsx('relative hover:bg-hover truncate', !label ? 'italic' : '', className)

	const Tag = to ? NavLink : 'div'

	return (
		<GridTable.Cell className={classNames}>
			<Tag
				to={to}
				className="flex items-center absolute pl-2 top-0 bottom-0 left-0 right-0 hover:bg-vivid-blue-50 font-[500] [&.active]:!bg-vivid-blue-100"
				css={navLinkStyles}
			>
				<div className="w-full truncate">{label || 'No title'}</div>
			</Tag>
		</GridTable.Cell>
	)
}

export const ActionCells = ({ label, actions }: any) => {
	// const classNames = clsx('relative hover:bg-hover truncate', !label ? 'italic' : '')

	return (
		<GridTable.Cell className="relative">
			{actions && (
				<div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-stretch pl-2 pr-2">
					<Dropdown label={label} buttonSize={100} buttonType="minimal" className="w-full">
						{actions}
					</Dropdown>
				</div>
			)}
		</GridTable.Cell>
	)
}

export const SignatureCell = ({ signatureImage, onClick }: any) => {
	return signatureImage ? (
		<GridTable.Cell
			className="relative flex items-center hover:!bg-vivid-blue-100 font-[500] cursor-pointer"
			onClick={onClick}
		>
			<Button hideLabel glyph="edit" type="link" size={100} className="ml-auto mr-2 !not-italic" />
		</GridTable.Cell>
	) : (
		<LinkCell label="Add Signature" glyph="signature" onClick={onClick} />
	)
}

const navLinkStyles = {
	'&.is-active': {
		background: `${tint(0.85, COLORS.vividBlue)} !important`
	}
}
