import React from 'react'
import { useParams } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'
import OrganizationTransactions from '@behavehealth/components/Elements/transactions/organizations/OrganizationTransactions'

const pageConfig = {
  feature: 'financials',
  title: 'Client Financials',
}

const ClientFinancials = () => {
  const { resource_id }: any = useParams()
  return <OrganizationTransactions subcharges url={`/organizations/${resource_id}/client_financial_transactions`} />
}

export default withPageError(withMarketing(ClientFinancials, pageConfig))
