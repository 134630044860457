import React from 'react'
import clsx from 'clsx'
import { opacify, lighten } from 'polished'

import withPermissions from '../hocs/withPermissions'
import { COLORS } from '../theme'

export const CARD_VARIANTS = {
  WELL: 'variant-well',
  LIST: 'variant-list',
  PLAIN: 'variant-plain',
}

export const CARD_CLASSES = {
  TITLE: 'card-title',
  HEADER_CONTENT: 'card-header-content',
  CHEVRON: 'card-chevron',
  GRAPHIC: 'card-graphic',
}

export const CARD_CSS_VARS = {
  BASELINE: '--card-baseline',
  PADDING_X: '--card-padding-x',
  PADDING_Y: '--card-padding-y',
}

type Props = {
  baseline?: string | number
  paddingX?: string | number
  paddingY?: string | number
  variant?: keyof typeof CARD_VARIANTS
  onClick?: any
  onMouseDown?: any
  featureFlag: string | boolean
  permission: string | boolean
  style?: any
  testKey?: any
  as?: any
} & React.HTMLAttributes<HTMLDivElement>

export const Card: React.FC<Props> = (props) => {
  const { id, baseline, children, className, testKey, onClick, onMouseDown, paddingX, paddingY, style, variant, onKeyDown, to } = props

  const classNames = clsx({
    [className]: className,
    [variant]: variant,
  })

  const Tag = React.useMemo(() => props.as || 'div', [props.as])

  return (
    <Tag
      id={id}
      style={style}
      css={[variableStyles({ baseline, paddingX, paddingY }), cardStyles]}
      data-test={`${testKey}_card`}
      className={classNames}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onKeyDown={onKeyDown}
      to={to}
      {...(props.as === 'button' && { type: 'button' })}
    >
      {children}
    </Tag>
  )
}

export const cardStyles = {
  borderRadius: 7,
  background: 'white',
  boxShadow: `
    0 0 0 1px ${opacify(-0.05, COLORS.divider)},
    0 3px 0 1px ${opacify(-0.07, COLORS.divider)}
  `,
  overflow: 'hidden',

  [`&.${CARD_VARIANTS.WELL}`]: {
    // background: transparentize(0.5, COLORS.white),
    background: lighten(0.01, COLORS.lightBackground),
    boxShadow: `0 0 0 1px ${COLORS.divider}`,
  },

  [`&.${CARD_VARIANTS.LIST}`]: {
    borderRadius: 0,
    background: COLORS.transparent,
    boxShadow: `0 1px 0 ${COLORS.divider}`,

    // TODO (Andrei): add back and fix the missing borders on Client Dashboard > Client Journey list cards
    // boxShadow: 'none',
    // '& + &': {
    //   boxShadow: `inset 0 1px 0 ${COLORS.divider} !important`,
    // },
  },

  [`&.${CARD_VARIANTS.PLAIN}`]: {
    borderRadius: 0,
    boxShadow: `none !important`,
    background: COLORS.transparent,

    [CARD_CSS_VARS.PADDING_X]: 0,
    [CARD_CSS_VARS.PADDING_Y]: 0,
  },
}

const variableStyles = ({ baseline, paddingX, paddingY }: any) => ({
  [CARD_CSS_VARS.BASELINE]: baseline,
  [CARD_CSS_VARS.PADDING_X]: paddingX,
  [CARD_CSS_VARS.PADDING_Y]: paddingY,
})

Card.defaultProps = {
  baseline: '4rem',
  paddingX: '0.65rem',
  paddingY: '0.4rem',
}

export default withPermissions(Card)
