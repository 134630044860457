import React from 'react'

import Input from '../../../../Forms/Input'
import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import ContextShow from '../../../../Forms/ContextShow'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'

import VitalSignsForm from './VitalSignsForm'

const RecurringBioMedical = () => {
  return (
    <FormSection layout="vertical">
      <VitalSignsForm showDateTime={false} showPainScale={false} showComments={false} requireHeight />

      <RadioGroup model="data.bio_medical.sleep_quality" label="How is the client's sleep currently?" layout="horizontal">
        <Radio label="Poor" value="poor" />
        <Radio label="Average" value="average" />
        <Radio label="Good" value="good" />
      </RadioGroup>

      <Input model="data.bio_medical.sleep_hours" label="How many hours does the client sleep in a night?" size={2} suffix="hours" />

      <CheckboxGroup label="Does the client have trouble falling asleep or staying asleep?" layout="vertical-dense">
        <Checkbox label="Falling asleep" model="data.bio_medical.sleep.trouble_falling_asleep" />
        <Checkbox label="Staying asleep" model="data.bio_medical.sleep.trouble_staying_asleep" />
        <Checkbox label="Waking up frequently" model="data.bio_medical.sleep.trouble_waking_up_frequently" />
        <Checkbox label="Nightmares" model="data.bio_medical.sleep.trouble_nightmares" />
        <Checkbox label="Using dreams" model="data.bio_medical.sleep.trouble_using_dreams" />
      </CheckboxGroup>

      <Input model="data.bio_medical.meals_per_day" label="How many meals per day is the client eating?" suffix="meals" size={2} />

      <Input
        model="data.bio_medical.percentage_meals_eating"
        label="What percentage of meals is the client currently eating?"
        suffix="%"
        size={2}
      />

      <RadioGroup
        label="Does the client have a diagnosed medical condition?"
        model="data.bio_medical.has_diagnosed_medical_condition"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.has_diagnosed_medical_condition" is={true}>
        <SmartTextarea
          useDictation
          label="Is condition interfering with treatment?"
          model="data.bio_medical.condition_interfering_with_treatment"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        label="Have there been any significant changes to the client's medical condition since last review?"
        model="data.bio_medical.had_significant_changes"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.had_significant_changes" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.bio_medical.had_significant_changes_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        label="Have there been any medical interventions required since last review?"
        model="data.bio_medical.has_required_medical_interventions"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.has_required_medical_interventions" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.bio_medical.has_required_medical_interventions_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <SmartTextarea
        useDictation
        label="Withdrawal Narrative"
        model="data.bio_medical.withdrawal_narrative"
        // validations={{
        //   presence: {
        //
        //     message: 'Please provide this information',
        //   },
        // }}
      />
    </FormSection>
  )
}

export default RecurringBioMedical
