import React from 'react'

import { Input } from './Input'
import { withFormContext } from './context'

import BaseInput from './BaseInput'
import Button from '../Button'
import Grid from '../Grid'

import Notifications from '../../modules/notifications'
import { copyToClipboard } from '../../utils/functions'

class CopyInput extends Input {
  copyToClipboard = () => {
    copyToClipboard(this.state.value)
    Notifications.send(this.props.successMessage || 'Value copied!', 'positive')
  }

  selectText = (event) => event.target.select()

  editRender = () => {
    const { id, value } = this.state
    const { buttonLabel } = this.props

    return (
      <Grid className="CopyInput" gap={12}>
        <BaseInput id={id} name={id} value={value} onFocus={this.selectText} onClick={this.selectText} onBlur={null} autoFocus readOnly />

        <Button fullWidth type="primary" glyph="copy" label={buttonLabel} onClick={this.copyToClipboard} css={{ margin: '0.5rem' }} />
      </Grid>
    )
  }
}

export default withFormContext(CopyInput)
