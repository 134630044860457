import React from 'react'
import { v4 as uuid } from 'uuid'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { isDefined } from '../../utils/functions'

import Button from '../Button'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Overlay from '../Overlay'
import Section from '../Section'
import SummonOverlay from '../SummonOverlay'
import Tooltip from '../Tooltip'
import Value from '../Value'

import BaseInput from './BaseInput'
import FieldBase from './FieldBase'
import Form from './Form'
import FormSection from './FormSection'
import Option from './Option'
import Select from './Select'

import { validate } from './validators'
import { withFormContext } from './context'

const FIRST_CODES: any = {
  '1': 'Hospital',
  '2': 'Skilled Nursing',
  '3': 'Home Health',
  '4': 'Religious Nonmedical Health Care Facility (Hospital)',
  '6': 'Intermediate Care',
  '7': 'Clinic',
  '8': 'Specialty Facility, Hospital ASC Surgery',
}

const SECOND_CODES: any = {
  '1': {
    '1': 'Inpatient (Including Medicare Part A)',
    '2': 'Inpatient (Medicare Part B Only)',
    '3': 'Outpatient',
    '4': 'Laboratory Services Provided to Non-Patients',
    '8': 'Swing Bed',
  },
  '2': {
    '1': 'Inpatient (Including Medicare Part A)',
    '2': 'Inpatient (Medicare Part B Only)',
    '3': 'Outpatient',
    '8': 'Swing Bed',
  },
  '3': {
    '2': 'Under A Plan Of Treatment',
    '4': 'Not Under A Plan Of Treatment',
  },
  '4': {
    '1': 'Inpatient',
    '3': 'Outpatient Services',
  },
  '6': {
    '5': 'Level I',
    '6': 'Level II',
  },
  '7': {
    '1': 'Rural Health',
    '2': 'Hospital Based or Independent Renal Dialysis Center',
    '3': 'Free Standing',
    '4': 'Outpatient Rehabilitation Facility (ORF)',
    '5': 'Comprehensive Outpatient Rehabilitation Facility (CORF)',
    '6': 'Community Mental Health Center',
    '7': 'Federally Qualified Health Center (FQHC)',
    '8': 'Licensed Freestanding Emergency Medical Facility',
    '9': 'OTHER',
  },
  '8': {
    '1': 'Hospice (Nonhospital based)',
    '2': 'Hospice (Hospital Based)',
    '3': 'Ambulatory Surgery Center',
    '4': 'Free Standing Birthing Center',
    '5': 'Critical Access Hospital',
    '6': 'Residential Facility',
    '7': 'Freestanding Non-Residential Opioid Treatment Program',
    '9': 'OTHER',
  },
}

const THIRD_CODES: any = {
  '0': 'Non-Payment Zero',
  '1': 'Admit Through Discharge Claim',
  '2': 'Interim-First Claim',
  '3': 'Interim-Continuing Claims',
  '4': 'Interim-Last Claim',
  '5': 'Late Charge(s) Only',
  '7': 'Replacement of Prior Claim',
  '8': 'Void/Cancel of Prior Claim',
  '9': 'Final Claim for a Home Health PPS Episode',
  A: 'Hospice Admission Notice',
  B: 'Cancellation of Election Notice',
  C: 'Hospice Change of Provider Notice',
  D: 'Termination/Revocation Notice',
  E: 'Hospice Change of Ownership',
  F: 'Beneficiary Initiated Adjustment Claim',
  G: 'CWF Initiated Adjustment Claim',
  H: 'CMS Initiated Adjustment Claim',
  I: 'Intermediary Adjustment Claim (Other than QIO or Provider)',
  J: 'Initiated Adjustment Claim-Other',
  K: 'OIG Initiated Adjustment Claim',
  M: 'MSP Initiated Adjustment Claim',
  O: 'Nonpayment/Zero Claim',
  P: 'QIO Adjustment Claim',
  Q: 'Claim Submitted for Reconsideration/Reopening Outside of Timely Limits',
  X: 'Void/Cancel a Prior Abbreviated Encounter Submission',
  Y: 'Replacement of Prior Abbreviated Encounter Submission',
  Z: 'New Abbreviated Encounter Submission',
}

const getBillTypeStrings = (code: string) => {
  if (!code || code.length !== 3) return null

  const firstDigit = code[0]
  const secondDigit = code[1]
  const thirdDigit = code[2]

  const firstString = FIRST_CODES[firstDigit]
  const secondString = SECOND_CODES[firstDigit][secondDigit]
  const thirdString = THIRD_CODES[thirdDigit]

  return {
    firstString: `${firstDigit} – ${firstString}`,
    secondString: `${secondDigit} – ${secondString}`,
    thirdString: `${thirdDigit} – ${thirdString}`,
  }
}

const SelectorOverlay = ({ onSave, onClose, code = '111' }: any) => {
  const form = React.useRef()

  const [firstDigit, setFirstDigit] = React.useState(FIRST_CODES[code[0]] ? code[0] : '1')
  const [secondDigit, setSecondDigit] = React.useState(code[1])
  const [thirdDigit, setThirdDigit] = React.useState(code[2])

  React.useEffect(() => {
    if (!firstDigit) return
    if (!!SECOND_CODES[secondDigit]) return

    setSecondDigit(Object.keys(SECOND_CODES[firstDigit])[0])
  }, [firstDigit])

  const handleSave = () => {
    onSave(firstDigit + secondDigit + thirdDigit)
    onClose()
  }

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header title="Type of Bill" />

      <Overlay.Content>
        <Form getForm={form}>
          <Section>
            <FormSection>
              {/* FIRST DIGIT */}
              <Select
                label="Type of Facility"
                defaultValue="1"
                value={firstDigit}
                onUpdate={({ value }) => {
                  setFirstDigit(FIRST_CODES[value] ? value : '1')
                }}
              >
                {Object.entries(FIRST_CODES).map(([digit, label]) => (
                  <Option value={digit} label={`${digit} – ${label}`} />
                ))}
              </Select>

              {firstDigit && (
                <Select label="Type of Care" defaultValue="1" value={secondDigit} onUpdate={({ value }) => setSecondDigit(value)}>
                  {Object.entries(SECOND_CODES[firstDigit]).map(([digit, label]) => (
                    <Option value={digit} label={`${digit} – ${label}`} />
                  ))}
                </Select>
              )}

              {/* THIRD DIGIT */}
              <Select
                label="Frequency"
                defaultValue="1"
                value={thirdDigit}
                onUpdate={({ value }) => {
                  setThirdDigit(THIRD_CODES[value] ? value : '1')
                }}
              >
                {Object.entries(THIRD_CODES).map(([digit, label]) => (
                  <Option value={digit} label={`${digit} – ${label}`} />
                ))}
              </Select>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" color="green" type="primary" onClick={handleSave} />
      </Overlay.Footer>
    </Overlay>
  )
}

export class ClaimBillTypeSelector extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    const vs = { ...props.defaultValidations, ...props.validations }
    let value = props.value
    if (!value) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

      if (props.forceEmptyValue) value = ''
      else if (isDefined(modelVal)) value = modelVal
      else if (isDefined(initialModelVal)) value = initialModelVal
      else if (isDefined(props.defaultValue)) value = props.defaultValue
      else value = ''
    }

    if (vs) errors = validate(value, vs)

    this.state = {
      type: 'CLAIM_BILL_TYPE',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value,
      isNested: props.isNested || false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isBlur: false,
      isValidations: vs,
      isRequired: vs?.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }
    this.updateType = 'DATA'
  }

  editRender = () => (
    <BaseInput
      id={this.state.id}
      name={this.state.id}
      value={this.state.value}
      placeholder={this.props.placeholder}
      size={this.props.size}
      type={this.props.type}
      onChange={this.onChange}
      onFocus={this.onFocus}
      onBlur={this.onBlur}
      onKeyDown={this.props.onKeyDown}
      prefix={this.props.prefix}
      suffix={
        <SummonOverlay overlay={<SelectorOverlay onSave={this.changeValue} code={this.state.value} />}>
          <Button hideLabel testKey="search_button" size={200} glyph="search" type="link" glyphSize={20} />
        </SummonOverlay>
      }
      step={this.props.step}
      autoFocus={this.props.autoFocus}
      autocomplete={this.props.autocomplete}
      className={this.props.className}
    />
  )

  readOnlyRender = () => {
    const { value }: any = this.state
    const billTypeStrings = getBillTypeStrings(value)

    if (!value) return DEFAULT_EMPTY_VALUE

    return (
      <Tooltip
        content={
          billTypeStrings && (
            <>
              <div>{billTypeStrings.firstString}</div>
              <div>{billTypeStrings.secondString}</div>
              <div>{billTypeStrings.thirdString}</div>
            </>
          )
        }
      >
        <Flex centerY gap="0.5rem">
          <Value value={value} />
          {billTypeStrings && <Glyph glyph="info" size={16} />}
        </Flex>
      </Tooltip>
    )
  }
}

ClaimBillTypeSelector.defaultProps = {
  type: 'text',
  model: 'bill_type',
  label: 'Type of Bill',
  isEditable: true,
  isDisabled: false,
  defaultValidations: null,
}

export default withFormContext(ClaimBillTypeSelector)
