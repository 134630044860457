import { VITAL_SIGNS_FIELDS, COWS_SYMPTOMS_FIELDS } from './common'

export const COWS_SECTIONS = [
  {
    title: 'Vital Signs',
    model: 'vital_signs',
    fields: VITAL_SIGNS_FIELDS,
  },
  {
    title: 'COWS Symptoms',
    model: 'cows_symptoms',
    fields: COWS_SYMPTOMS_FIELDS,
  },
]
