import React from 'react'
import axios from 'axios'
import ReactAudioPlayer from 'react-audio-player'
import WebViewer from '@pdftron/pdfjs-express-viewer'

import { getToken } from '../../modules/axios'
import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Flex from '../../components/Flex'
import Overlay from '../../components/Overlay'

const findType = (contentType) => {
  if (!contentType) return null
  else if (contentType.includes('image/')) return 'image'
  else if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) return 'excel'
  else if (contentType.includes('application/vnd.ms-excel')) return 'excel'
  else if (contentType.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) return 'word'
  else if (contentType.includes('application/msword')) return 'word'
  else if (contentType.includes('application/pdf')) return 'pdf'
  else if (contentType.includes('application/')) return 'application'
  else if (contentType.includes('audio/x-m4a')) return 'audio'
  else if (contentType.includes('audio/mpeg')) return 'audio'
  else if (contentType.includes('audio/wav')) return 'audio'
}

const buildURL = (type, url) => {
  const encodedURL = encodeURIComponent(url)
  const urls = {
    word: `https://view.officeapps.live.com/op/view.aspx?src=${encodedURL}`,
    excel: `https://view.officeapps.live.com/op/view.aspx?src=${encodedURL}`,
    application: `https://docs.google.com/viewer?url=${encodedURL}&embedded=true`,
    pdf: url,
    image: encodedURL,
    audio: encodedURL,
  }

  return urls[type]
}

const ImageRender = ({ uri }) => (
  <Overlay.Content style={{ position: 'relative' }}>
    <Flex centerX centerY className="!h-full">
      <img src={uri} css={{ height: 'auto', width: '100%' }} alt="Menu" />
    </Flex>
  </Overlay.Content>
)

const AudioRender = ({ uri, givenTitle, record }) => {
  return (
    <>
      <Overlay.Header title={givenTitle} glyph="behave_health" />
      <Overlay.Content css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ReactAudioPlayer src={record.url} autoPlay controls />
      </Overlay.Content>
    </>
  )
}

const PDFRender = ({ givenTitle, uri }: any) => {
  const viewer = React.useRef(null)

  React.useEffect(() => {
    if (!uri) return

    WebViewer(
      {
        licenseKey: process.env.BH_PDF_EXPRESS_KEY,
        path: '/webviewer/lib',
      },
      viewer.current,
    ).then((instance: any) => {
      instance.loadDocument(uri, {
        customHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Request-Method': '*',
          'Access-Control-Allow-Headers': '*',
        },
        extension: 'pdf',
      })
    })
  }, [uri])

  return (
    <>
      <Overlay.Header testKey={`${givenTitle}_view_overlay`} title={givenTitle || 'PDF Viewer'} glyph="behave_health" />
      <Overlay.Content style={{ position: 'relative' }}>
        <Flex centerX centerY className="!h-full">
          <div className="webviewer w-full h-full" ref={viewer}></div>
        </Flex>
      </Overlay.Content>
    </>
  )
}

const DefaultRender = ({ uri, givenTitle }) => (
  <>
    <Overlay.Header testKey={`${givenTitle}_view_overlay`} title={givenTitle} glyph="behave_health" />
    <Overlay.Content style={{ position: 'relative', padding: '1rem', overflow: 'hidden' }}>
      <iframe
        title="iframe"
        src={uri}
        frameborder="0"
        border="0"
        cellspacing="0"
        css={{
          borderStyle: 'none',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
      />
    </Overlay.Content>
  </>
)

const renderTypes = {
  image: ImageRender,
  audio: AudioRender,
  pdf: PDFRender,
  default: DefaultRender,
}

const RootFilePreviewOverlay = (props: any) => {
  const { url, title, contentType } = props

  const { data, close } = useOverlay({
    name: 'file',
    endpoint: '/documents',
    invalidate: 'files',
    options: props,
  })

  const [uri, setURI] = React.useState(null)
  const token = React.useRef(null)
  const type = findType(data?.content_type || contentType)
  const givenTitle = title || data?.title

  React.useEffect(() => {
    const checkToken = async () => {
      const tk = await getToken()
      if (tk) token.current = tk?.token
    }

    checkToken()
  }, [])

  React.useEffect(() => {
    if (!data) return

    const handlePreview = async () => {
      if (url) {
        setURI(buildURL(type, url))
        return
      }

      if (type === 'image') {
        setURI(data.url)
      } else if (type === 'pdf') {
        if (data?.get_signed) {
          const req = await axios.get(data?.get_signed + `&token=${token.current}`)
          setURI(req.data)
        } else {
          window.open(data?.url, '_blank')
          close()
        }
      } else {
        if (data?.get_signed) {
          const req = await axios.get(data?.get_signed + `&token=${token.current}`)
          setURI(buildURL(type, req.data))
        } else {
          window.open(data?.url, '_blank')
          close()
        }
      }
    }

    handlePreview()
  }, [type, data])

  const render = (props) => {
    let QuickViewContent = renderTypes.default
    if (renderTypes[type]) QuickViewContent = renderTypes[type]

    return <QuickViewContent {...props} />
  }

  return (
    <Overlay onClose={close} maxWidth={68}>
      {render({ uri, givenTitle, record: data })}
    </Overlay>
  )
}

export const FilePreviewOverlay = withOverlayError(RootFilePreviewOverlay)
