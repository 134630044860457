import React from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import isBoolean from 'lodash/isBoolean'
import isString from 'lodash/isString'
import includes from 'lodash/includes'
import size from 'lodash/size'

import { isDefined } from '../utils/functions'
import { PLANS } from '../utils/constants'

const processFeatureFlag = (key, flags) => {
  return isBoolean(key) ? key : get(flags, key)
}

const processPermission = (key, permissions) => {
  let ps = true

  // process permission
  if (isBoolean(key)) {
    ps = key
  } else if (key) {
    if (key === 'test_results') {
      let uat_view = get(permissions, 'test_results.ua_tests.view')
      let bt_view = get(permissions, 'test_results.breathalyzer_tests.view')

      if (includes(['all', 'assigned'], uat_view)) uat_view = true
      if (includes(['all', 'assigned'], bt_view)) bt_view = true

      ps = uat_view || bt_view
    } else {
      // check permission
      ps = get(permissions, key, false)
      if (isString(ps)) {
        if (includes(['all', 'assigned'], ps)) ps = true
        else if (ps === 'none') ps = false
      }
    }
  }

  return ps
}

const processPlan = (featurePlan, activePlan) => {
  if (!activePlan || !featurePlan) return false

  const activePlanValue = PLANS[activePlan].value
  const featurePlanValue = PLANS[featurePlan].value

  return activePlanValue >= featurePlanValue
}

const withPermissionsProps = (Wrapped) => (props: any) => {
  const permissions = useSelector((state) => state.me?.permissions)
  const featureFlags = useSelector((state) => state.me?.feature_flags)
  const newFeatureFlags = useSelector((state) => state.me?.new_feature_flags)
  const currentPlan = useSelector((state) => state.me?.plan)

  const checkPermission = isDefined(props.permission)
  const checkNewFlag = isDefined(props.newFeatureFlag) && size(newFeatureFlags) > 0
  const checkFeatureFlag = isDefined(props.featureFlag) && size(featureFlags) > 0
  const checkPlan = isDefined(props.plan)

  let flag = true
  let permission = true
  let plan = true

  if (checkNewFlag) {
    flag = processFeatureFlag(props.newFeatureFlag, newFeatureFlags)
  } else if (checkFeatureFlag) {
    flag = processFeatureFlag(props.featureFlag, featureFlags)
  }

  if (checkPermission) {
    permission = processPermission(props.permission, permissions)
  }

  if (checkPlan) {
    plan = processPlan(props.plan, currentPlan?.identifier?.split('_')[1])
  }

  return <Wrapped {...props} isPermissible={flag && permission && plan} />
}

export default withPermissionsProps
