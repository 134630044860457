import React from 'react'
import size from 'lodash/size'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import useAPI from '@behavehealth/hooks/useAPI'
import { Contacts } from '@behavehealth/declarations'

import { Button, Card, Chotomate } from '@behavehealth/components'
import { ContactsTable } from '@behavehealth/components/Tables'

const FacilityContacts: React.FC = () => {
  const match = useRouteMatch()
  const data: Contacts = useSelector((state) => state.data.contacts?.data)
  const loading = useSelector((state) => state.data.contacts?.loading)

  useAPI('contacts', `/insurance_local_payers/${match.params?.resource_id}/contacts`)

  const isEmpty = size(data) === 0

  const actions = (
    <Button
      label="Add Facility Contact"
      type="primary"
      glyph="add"
      link={{
        pathname: `${match.url}/new`,
        data: { reference_id: match.params?.resource_id },
      }}
    />
  )

  return (
    <>
      <Chotomate name="payers_facility_contact_overlay" ready={!loading} />

      <Card>
        <ContactsTable
          data={data}
          isLoading={isEmpty && loading}
          emptyActions={actions}
          localStorageKey="contacts"
          to={(id) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
        />
      </Card>
    </>
  )
}

export default FacilityContacts
