import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'

import { RecordTooltip } from '../components/RecordTooltip'
import { CustomNoteInfo } from '../../StaffJourney/records/CustomNoteInfo'

export const CustomNote = ({ data, client }: any) => {
  if (!data) return null

  return (
    <RecordTooltip
      title={data.name}
      icon="custom_notes"
      color={tint(0.7, COLORS.blue)}
      startDate={data.started_at}
      recordLink={`/custom-notes/${data.id}`}
      client={client}
    >
      <CustomNoteInfo data={data} />
    </RecordTooltip>
  )
}
