import React from 'react'
import clsx from 'clsx'

import Tabs from '../../../Tabs'

import { AdmissionsClientsTable } from './clients/AdmissionsClientsTable'
import { CurrentClientsTable } from './clients/CurrentClientsTable'
import { AlumniClientsTable } from './clients/AlumniClientsTable'

export const AllClientsTable = (props: any) => (
  <Tabs defaultTab={props.defaultTab || 'admissions'}>
    <Tabs.List className={clsx('ml-2', props.tabsListClassName)}>
      <Tabs.Item icon="admissions" label="Admissions" name="admissions" />
      <Tabs.Item icon="clients" label="Current Clients" name="current_clients" />
      <Tabs.Item icon="alumni_relations" label="Past Clients" name="past_clients" />
    </Tabs.List>

    <Tabs.Panels>
      <Tabs.Panel name="admissions" type="mount">
        <AdmissionsClientsTable {...props} />
      </Tabs.Panel>

      <Tabs.Panel name="current_clients" type="mount">
        <CurrentClientsTable {...props} />
      </Tabs.Panel>

      <Tabs.Panel name="past_clients" type="mount">
        <AlumniClientsTable {...props} />
      </Tabs.Panel>
    </Tabs.Panels>
  </Tabs>
)
