import React from 'react'
import startCase from 'lodash/startCase'

import Status from '../Status'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'to.name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} />,
  },
  {
    width: 160,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ value }: any) => {
      if (value === 'care_team') return <Status label="Care Team" color="orange" />
      if (value === 'organization') return <Status label="Organization" color="purple" />
      return <TableCell.Empty />
    },
    Filter: TableFilter.Select,
    filterOptions: [
      {
        value: 'care_team',
        label: 'Care Team',
      },
      {
        value: 'organization',
        label: 'Organization',
      },
    ],
  },
  {
    width: 200,
    accessor: 'subcategory',
    Header: 'Relation Type',
    Cell: ({ value }: any) => startCase(value),
    Filter: TableFilter.Select,
    filterOptions: [
      {
        value: 'care_physician',
        label: 'Care Physician',
      },
      {
        value: 'psychiatrist',
        label: 'Psychiatrist',
      },
      {
        value: 'therapist',
        label: 'Therapist',
      },
      {
        value: 'recovery_coach',
        label: 'Recovery Coach',
      },
      {
        value: 'treatment_center',
        label: 'Treatment Center',
      },
      {
        value: 'sober_living_home',
        label: 'Sober Living Home',
      },
      {
        value: 'employer',
        label: 'Employer',
      },
      {
        value: 'school',
        label: 'School',
      },
    ],
  },
  {
    width: 200,
    accessor: 'notes',
    Header: 'Notes',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  isLoading: boolean
  localStorageKey: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const CareTeamTable = ({ to, data, isLoading, emptyActions, batchActionsConfig, localStorageKey, titleAfter }: Props) => {
  return (
    <Table
      title="Connections"
      testKey="connections_table"
      titleAfter={titleAfter}
      icon="connections"
      data={data}
      columns={columns(to)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No care team members added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

CareTeamTable.defaultProps = {
  localStorageKey: 'care_team',
}

export default CareTeamTable
