import React from 'react'
import size from 'lodash/size'

import Button from '../../../components/Button'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Flex from '../../../components/Flex'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Glyph from '../../../components/Glyph'
import Permission from '../../../components/Permission'
import State from '../../../components/State'
import Textarea from '../../../components/Forms/Textarea'

import { DropdownMenu, DropdownMenuItem } from '../../../components/DropdownMenu'
import { useGet } from '../../../hooks/useNewAPI'

const WORKFLOW_CONFIGS: any = {
  accept: {
    label: 'Accept Message',
    description: `If we've got contact details for the client, we will send them a secure message to let them know that you've accepted their application`,
  },
  decline: {
    label: 'Decline Message',
    description: `If we've got contact details for the client, we will send them a secure message to let them know that you've declined their application`,
  },
  admit: {
    label: 'Admit Message',
    description: `If we've got contact details for the client, we will send them a secure message to let them know that you've admitted them`,
  },
  discharge: {
    label: 'Discharge Message',
    description: `If we've got contact details for the client, we will send them a secure message to let them know that you've discharged them`,
  },
  readmit: {
    label: 'Readmit Message',
    description: `If we've got contact details for the client, we will send them a secure message to let them know that you've readmitted them`,
  },
}

export const SecureMessage = (props: any) => {
  const { data, setData, category } = props

  const form = React.useRef()

  const workflowConfig = WORKFLOW_CONFIGS?.[category]

  const { data: templates, isLoading: isLoadingTemplates } = useGet({
    name: ['secure_message_templates', { category }],
    url: `/secure_message_templates`,
    params: { category },
  })

  const isTemplatesEmpty = size(templates) === 0

  const templatesAction = (
    <Button
      label="Manage Message Templates"
      type="minimal"
      color="text"
      glyph="settings"
      size={100}
      link="/settings/client-workflows-message-templates"
      target="_blank"
      useGlyphForTarget
    />
  )

  const [message, setMessage] = React.useState(data?.secure_message || '')

  React.useEffect(() => {
    setData({ secure_message: message })
  }, [message])

  if (!data || !setData || !workflowConfig) return

  return (
    <Form
      getForm={form}
      initialModel={{
        send_secure_message: data?.send_secure_message || false,
      }}
      onUpdate={setData}
    >
      <FormSection maxWidth="100%">
        <CheckboxGroup>
          <Checkbox
            label={`Send Secure ${workflowConfig.label} to Client`}
            model="send_secure_message"
            description={workflowConfig.description}
          />
        </CheckboxGroup>

        {data?.send_secure_message && (
          <>
            <Permission featureFlag="client_workflows_message_templates" permission="settings.client_workflows_message_templates.view">
              <div className="mt-2">
                <Flex gap="1rem" justifyContent="space-between">
                  <DropdownMenu
                    trigger={
                      <div>
                        <Button
                          size={200}
                          label="Select from Template…"
                          icon="client_workflows_message_templates"
                          after={<Glyph glyph="triangle_down" size={10} />}
                          display="inline-flex"
                        />
                      </div>
                    }
                  >
                    {isLoadingTemplates || isTemplatesEmpty ? (
                      <State
                        isLoading={isLoadingTemplates}
                        isEmpty={isTemplatesEmpty}
                        title="Message Templates"
                        icon="client_workflows_message_templates"
                        emptyDescription="No message templates created yet"
                        emptyActions={templatesAction}
                        minHeight={180}
                      />
                    ) : (
                      templates?.map?.((template: any) => (
                        <DropdownMenuItem
                          key={template.id}
                          label={template.name}
                          description={template.message}
                          onClick={() => {
                            setMessage(template.message)
                          }}
                        />
                      ))
                    )}
                  </DropdownMenu>

                  {templatesAction}
                </Flex>
              </div>
            </Permission>

            <Textarea
              useQuickText
              label={workflowConfig.label}
              value={message}
              onUpdate={({ value }: any) => {
                setMessage(value)
              }}
              validations={{
                presence: {
                  message: 'Please enter a message',
                },
              }}
            />
          </>
        )}
      </FormSection>
    </Form>
  )
}
