import React from 'react'

import { COLORS } from '../../theme'

export const WorksheetRow = (props: any) => {
  const { rowId, children } = props

  return (
    <div css={STYLES.root} data-id={rowId} data-type="worksheet-row" data-test="worksheet_row">
      {children}
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'var(--grid-template-columns)',
    borderBottom: `1px solid ${COLORS.divider}`,
    minWidth: 'fit-content',

    '&:hover': {
      background: COLORS.hover,
    },
  },
}
