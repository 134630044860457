import React from 'react'
import clsx from 'clsx'

import Overlay from './Overlay'

const OverlayLoader: React.FC<any> = ({ position = 'center', maxWidth, showBackdrop, onClose }) => {
  const className = clsx(`position-${position}`)

  return (
    <Overlay position={position} showBackdrop={showBackdrop} minHeight={position === 'center' && 8} maxWidth={maxWidth} onClose={onClose}>
      <Overlay.Content>
        <div css={styles.loaderWrapper} className={className}>
          Loading…
        </div>
      </Overlay.Content>
    </Overlay>
  )
}

const styles = {
  loaderWrapper: {
    padding: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    '&.position-right': {
      background: '#F7F8FB',
    },
  },
}

export default OverlayLoader
