import React from 'react'

import AddressInput from '../../components/Forms/AddressInput'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'

import { SectionCard } from '../../components/SectionCard'

export const InvoiceBilledBySection = () => {
  return (
    <SectionCard title="Billed By" className="!p-3">
      <FormSection maxWidth="100%" className="!gap-1">
        <div className="grid gap-4">
          <Input label="Company" model="billed_by_company_name" />
          <Input label="Name" model="billed_by_name" />
          <Input label="Email" model="billed_by_email" />
          <Input label="Phone" model="billed_by_phone_no" />
          <AddressInput actionLabel="Find Address" model="billed_by_address" />
        </div>
      </FormSection>
    </SectionCard>
  )
}
