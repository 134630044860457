import React from 'react'

export const FilterDropdownGroup: React.FC<any> = (props) => {
  const { label, children } = props

  return (
    <div css={STYLES.menuGroup}>
      {label && <div css={STYLES.menuGroupLabel}>{label}</div>}
      {children}
    </div>
  )
}

const STYLES = {
  menuGroup: {
    '& + &': {
      marginTop: '0.75rem',
    },
  },

  menuGroupLabel: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '0.85rem',
    letterSpacing: 0.75,
    padding: '0.25rem 0.5rem 0.15rem',
  },
}
