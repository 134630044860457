import React from 'react'
import { transparentize } from 'polished'

import { COLORS, SHADOW } from '../theme'

import Flex from './Flex'
import Glyph from './Glyph'
import SummonOverlay from './SummonOverlay'
import { FeedbackOverlay } from './FeedbackTool'

const HelpTrigger: React.FC<any> = ({ className }) => (
  <SummonOverlay overlay={<FeedbackOverlay />}>
    <Flex css={styles.root} alignItems="center" className={className}>
      <Glyph glyph="chat" size={24} color={COLORS.white} css={styles.glyph} />
    </Flex>
  </SummonOverlay>
)

const styles = {
  root: {
    position: 'absolute',
    zIndex: 11,

    bottom: 7,
    right: 7,

    width: 45,
    height: 45,
    borderRadius: '50%',
    background: COLORS.paleBlue,
    boxShadow: `${SHADOW(4, transparentize(0.7, COLORS.lightGray))}`,

    alignItems: 'center',
    padding: '0.4em 1em',
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.blue,
    },
  },

  glyph: {
    position: 'absolute',
    left: 11,
    top: 10,
  },

  glyph2: {
    position: 'absolute',
    left: 4,
    top: 4,
  },
}

export default HelpTrigger
