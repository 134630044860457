import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import usePermissionsStatus from '../hooks/usePermissionsStatus'
import Notifications from '../modules/notifications'
import { isBlank } from '../utils/functions'

const DEBUG = false

export const ProtectedRoute = ({ permission, featureFlag, ...rest }: any) => {
  const { allowed } = usePermissionsStatus({ permission: permission, featureFlag: featureFlag })

  if (DEBUG) {
    console.log('Route Debug', {
      ...rest,
      permission,
      featureFlag,
      allowed,
    })
  }

  // if there is no permission && feature flag set up, allow access
  if (isBlank(permission) && isBlank(featureFlag)) return <Route {...rest} />

  if (allowed === 'not_allowed') {
    Notifications.send('You do not have Permission to this page. Contact your Account Administrator for more details.', 'negative')

    return <Redirect to="/dashboard" />
  }

  if (allowed === 'unknown') return null
  if (allowed === 'allowed') return <Route {...rest} />
}
