import React from 'react'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

export const TreatmentPlanRecordCategorySelect = (props: any) => {
  return (
    <Select allowEmpty label="Category" model="category" {...props}>
      <Option label="Clinical" value="clinical" />
      <Option label="Medical" value="medical" />
      <Option label="Case Management" value="case_management" />
      <Option label="Peer" value="peer" />
    </Select>
  )
}
