import React from 'react'

import Input from '../../../../components/Forms/Input'
import Textarea from '../../../../components/Forms/Textarea'

import { VariablesDropdownMenu } from '../../components/VariablesDropdownMenu'

export const AttachmentInputEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate, activeElementId, appendVariableToElementModel } = props

  const { label, description, tooltip } = activeElement.config

  return (
    <>
      <Input
        label="Label"
        model="label"
        value={label}
        onUpdate={onUpdate}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'label' })
            }}
          />
        }
      />

      <Textarea
        label="Description"
        model="description"
        value={description}
        onUpdate={onUpdate}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'description' })
            }}
          />
        }
      />

      <Textarea
        label="Info Tooltip"
        model="tooltip"
        value={tooltip}
        onUpdate={onUpdate}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'tooltip' })
            }}
          />
        }
      />
    </>
  )
}
