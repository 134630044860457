import React from 'react'
import { useLocation } from 'react-router-dom'

import { H4 } from '../../components/Typography'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'
import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const SCORING = {
  0: 'no depression',
  4: 'minimal depression',
  9: 'mild depression',
  14: 'moderate depression',
  19: 'moderately severe depression',
  27: 'severe depression',
}

const INITIAL_STATE = {
  little_interest: 0,
  hopeless: 0,
  sleep_issues: 0,
  tiredness: 0,
  eating_issues: 0,
  feeling_as_failure: 0,
  focus_issues: 0,
  movement_issues: 0,
  self_harm_thoughts: 0,
}

const RootPHQ9Overlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="PHQ-9 Depression"
      category="clinical_measurement"
      subcategory="phq9"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <H4>Over the last two weeks, how often have you been bothered by any of the following problems?</H4>

        <RadioGroup
          label="Little interest or pleasure in doing things"
          model="data.questionnaire.little_interest"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, little_interest: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Feeling down, depressed, or hopeless"
          model="data.questionnaire.hopeless"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, hopeless: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Trouble falling or staying asleep, or sleeping too much"
          model="data.questionnaire.sleep_issues"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, sleep_issues: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Feeling tired or having little energy"
          model="data.questionnaire.tiredness"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, tiredness: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Poor appetite or overeating"
          model="data.questionnaire.eating_issues"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, eating_issues: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Feeling bad about yourself - or that you are a failure or have let yourself or your family down"
          model="data.questionnaire.feeling_as_failure"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, feeling_as_failure: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Trouble concentrating on things, such as reading the newspaper or watching television"
          model="data.questionnaire.focus_issues"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, focus_issues: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual"
          model="data.questionnaire.movement_issues"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, movement_issues: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Thoughts that you would be better off dead, or of hurting yourself in some way"
          model="data.questionnaire.self_harm_thoughts"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, self_harm_thoughts: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Score title="Depression Severity Score" finalScore={finalScore} scoring={SCORING} />
        </>
      )}
    </>
  )
}

export const PHQ9Overlay = withOverlayError(RootPHQ9Overlay)
