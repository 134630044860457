import React from 'react'
import { NavLink, Route } from 'react-router-dom-v5-compat'
import sortBy from 'lodash/sortBy'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { COLORS } from '@behavehealth/theme'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Glyph from '@behavehealth/components/Glyph'
import Page from '@behavehealth/components/Page'

import { DataFormBuilderOverlay } from '@behavehealth/constructs/DataFormsBuilder/DataFormBuilderOverlay'
import { DataFormSettingsStatus } from '@behavehealth/constructs/DataFormsBuilder/DataFormSettingsStatus'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'

const RootDischargesBuilder = () => {
  return (
    <>
      <Page title="Discharges Builder" feature="discharges_builder">
        <InnerPage />
      </Page>

      <AnimatedRoutes>
        <Route path=":id" element={<DataFormBuilderOverlay useV6Router permissionName="discharges_builder" />} />
      </AnimatedRoutes>
    </>
  )
}

const InnerPage = () => {
  const tableProps = useDataTable({
    name: ['data_form_settings'],
    endpoint: `/data_form_settings`,
    params: { category: 'discharge' },
    keepPreviousData: false,
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 280,
        disableSort: true,
        disableHide: true,
        disableSelect: true,
        formatValue: ({ data, value }: any) => {
          return (
            <MainCell
              as={NavLink}
              id={data.id}
              value={value}
              icon="discharge_forms"
              graphic={data.variant && <Glyph glyph="curved_arrow_down_right" size={12} color={COLORS.blue} className="ml-1.5" />}
              to={data.id}
            />
          )
        },
      },
      {
        title: 'Status',
        model: 'status',
        disableSort: true,
        formatValue: ({ value }: any) => <DataFormSettingsStatus status={value} />,
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        disableSort: true,
        type: 'date_time',
      },
    ]
  }, [])

  const data = React.useMemo(() => {
    if (!tableProps.data) return []

    return sortBy(tableProps.data, 'name')
  }, [tableProps.data])

  return (
    <>
      <div className="grid grid-cols-1">
        <DataTable asCard title="Discharge Forms" columns={columns} {...tableProps} data={data} />
      </div>
    </>
  )
}

export const DischargesBuilder = withPageError(RootDischargesBuilder)
