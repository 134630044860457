import React from 'react'

import Chotomate from '../../components/Chotomate'
import ContextHide from '../../components/Forms/ContextHide'
import HelpTag from '../../components/HelpTag'
import Section from '../../components/Section'
import Switch from '../../components/Forms/Switch'

import MentalStatusForm from '../../components/Overlays/pages/DataForms/common/MentalStatusForm'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootMentalStatusExamOverlay = () => (
  <DataFormOverlay
    title="Mental Status Exam"
    category="clinical_assessment"
    subcategory="mental_status_exam"
    icon="clinical_assessments"
    helpID="clinical_assessments_mental_status_exam"
    requestClientSignature={false}
    requestAuthorSignature={true}
    requireSupervisor={true}
    enableTreatmentPlanSelector
  >
    <Chotomate ready name="mental_status_exam_overlay" />

    <Section
      title="Mental Status Exam"
      aside={<Switch horizontal inverse model="data.mental_status.not_applicable" />}
      scrollview={{
        id: 'mental_status',
        name: 'Mental Status Exam',
      }}
      commentsModel="data.mental_status"
    >
      <ContextHide when="data.mental_status.not_applicable" is={true}>
        <MentalStatusForm />
      </ContextHide>
    </Section>
  </DataFormOverlay>
)

export const MentalStatusExamOverlay = withOverlayError(RootMentalStatusExamOverlay)
