import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { apiGet } from '../../../../modules/api'

import Glyph from '../../../Glyph'
import Overlay from '../../../Overlay'
import State from '../../../State'
import Tab from '../../../Tab'
import TabList from '../../../TabList'
import TabPanel from '../../../TabPanel'
import TabPanels from '../../../TabPanels'
import Tabs from '../../../Tabs'
import Tooltip from '../../../Tooltip'

import Behavior from './common/Behavior'
import Diagnoses from './common/Diagnoses'
import Goals from './common/Goals'
import Interventions from './common/Interventions'
import Objectives from './common/Objectives'
import Review from './common/Review'

import { checkValid } from './common/utils'

const initialData = {}

const formDataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const WileyPracticePlannerOverlay = (props: any) => {
  const { clientID, onClose, onSave, position = 'center', maxWidth = 70, showBackdrop = true, reviewStepAfter } = props

  const history = useHistory()
  const location = useLocation()

  const [formData, setFormData] = React.useReducer(formDataReducer, initialData)
  const [wileyData, setWileyData] = React.useState({})

  const [loading, setLoading] = React.useState(false)
  const [diagnosisCodes, setDiagnosesCodes]: any = React.useState(props.diagnosisCodes || [])

  const valid = checkValid(formData)

  const addDiagnosesCodes = (newDiagnoses: any) => {
    if (!newDiagnoses) return

    const filteredDiagnoses = newDiagnoses.filter((o: any) => !diagnosisCodes.includes(o?.code))
    const filteredDiagnosisCodes = filteredDiagnoses.map((o: any) => o?.code)

    setDiagnosesCodes((current: any) => [...current, ...filteredDiagnosisCodes])
  }

  const close = () => {
    if (props.type === 'summon' && props.onClose) return props.onClose()

    const url = location?.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    history.push(url)
  }

  // Get client diagnoses
  React.useEffect(() => {
    if (!clientID) return

    const getClientDiagnoses = async () => {
      try {
        setLoading(true)

        const result = await apiGet({ url: `/residents/${clientID}/diagnoses` })
        const clientDiagnosesCodes = result?.data?.data?.map?.((o) => o?.code)
        const newDiagnosisCodes = clientDiagnosesCodes.filter((o: any) => !diagnosisCodes.includes(o.id))

        setDiagnosesCodes((current: any) => [...current, ...newDiagnosisCodes])
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getClientDiagnoses()
  }, [clientID])

  return (
    <Overlay fullheight showBackdrop={showBackdrop} position={position} maxWidth={maxWidth} onClose={close}>
      <Overlay.Header
        title="Wiley Practice Planner"
        icon="treatment_plans"
        titleAside={
          <Tooltip
            content={`The Wiley Practice Planners Datasets are designed to provide accurate and authoritative information in regard to the subject matter covered. They are licensed with the understanding that the publisher is not engaged in rendering professional services. If legal, accounting, medical, psychological, or other expert assistance is required, the services of a competent professional person should be sought. THE PRACTICE PLANNER DATASETS AND ALL INFORMATION THEREIN ARE PROVIDED "AS IS" AND EXPRESS AND IMPLIED WARRANTIES AND REPRESENTATIONS OF ANY KIND, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, OR QUALITY, ACCURACY, COMPLETENESS AND/OR SUITABILITY ARE DISCLAIMED.`}
          >
            <Glyph glyph="info" size={18} />
          </Tooltip>
        }
      />

      <Overlay.Content>
        {loading && <State isLoading />}

        <Tabs defaultTab="diagnoses">
          <TabList css={styles.tabsList}>
            <Tab disableForwardNav label="1. Diagnoses" name="diagnoses" />
            <Tab disableForwardNav label="2. Behavior" name="behavior" isDisabled={!valid} />
            <Tab disableForwardNav label="3. Goals" name="goals" isDisabled={!valid} />
            <Tab disableForwardNav label="4. Objectives" name="objectives" isDisabled={!valid} />
            <Tab disableForwardNav label="5. Interventions" name="interventions" isDisabled={!valid} />
            <Tab disableForwardNav label="6. Review" name="review" isDisabled={!valid} />
          </TabList>

          <TabPanels>
            <TabPanel name="diagnoses" type="mount">
              <Diagnoses
                addDiagnosesCodes={addDiagnosesCodes}
                diagnosisCodes={diagnosisCodes}
                initialDiagnosisCodes={props.diagnosisCodes}
                formData={formData}
                setFormData={setFormData}
                setWileyData={setWileyData}
                wileyData={wileyData}
              />
            </TabPanel>

            <TabPanel name="behavior" type="mount">
              <Behavior formData={formData} setFormData={setFormData} wileyData={wileyData} setWileyData={setWileyData} />
            </TabPanel>

            <TabPanel name="goals" type="mount">
              <Goals formData={formData} setFormData={setFormData} wileyData={wileyData} setWileyData={setWileyData} />
            </TabPanel>

            <TabPanel name="objectives" type="mount">
              <Objectives formData={formData} setFormData={setFormData} wileyData={wileyData} setWileyData={setWileyData} />
            </TabPanel>

            <TabPanel name="interventions" type="mount">
              <Interventions formData={formData} setFormData={setFormData} wileyData={wileyData} setWileyData={setWileyData} />
            </TabPanel>

            <TabPanel name="review" type="mount">
              <Review wileyData={wileyData} onClose={close} onSave={onSave} after={reviewStepAfter} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Overlay.Content>
    </Overlay>
  )
}

const styles = {
  tabsList: {
    padding: '0 1rem',
  },
}

export default WileyPracticePlannerOverlay
