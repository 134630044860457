import React from 'react'
import clsx from 'clsx'

import Empty from './Empty'

const Truncated: React.FC<any> = ({ value, className }) => {
  if (!value) return <Empty />

  return (
    <span title={value} className={clsx('block overflow-hidden truncate', className)}>
      {value}
    </span>
  )
}

export default Truncated
