import React from 'react'

import { IntakesDataTable } from '@behavehealth/constructs/Clients/IntakesDataTable'

const Intake: React.FC = () => {
  return <IntakesDataTable />
}

const STYLES = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
}

export default Intake
