import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useParams, useNavigate, useLocation } from 'react-router-dom-v5-compat'

import { clearData, updateData } from '@behavehealth/actions/data'
import { store } from '@behavehealth/setup/store'
import { useGet, useUpdate, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Notifications from '@behavehealth/modules/notifications'
import useStore from '@behavehealth/modules/store'
import withBackListener from '@behavehealth/hocs/withBackListener'

import {
  Alert,
  DropdownItem,
  HelpTagIframe,
  Nav,
  NavGroup,
  NavItem,
  PageGrid,
  PageLoader,
  PageProfile,
  TagsSelector,
} from '@behavehealth/components'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { JoinMeetingButton } from '@behavehealth/components/Buttons/JoinMeetingButton'
import DeleteDialog from '@behavehealth/components/Dialogs/DeleteDialog'

import ActivityTimeline from './pages/activity_timeline'
import AssignedEmployees from './pages/assigned_employees'
import Calendar from './pages/calendar'
import CustomNotes from './pages/custom_notes'
import Files from './pages/files'
import FormSubmissions from './pages/form_submissions'
import GeneralInfo from './pages/general_info'
import Phases from './pages/phases'
import ProgramPlan from './pages/program_plan'
import Settings from './pages/settings'
import Todos from './pages/todos/todos'

import { ProgramAssignmentOverlay } from '@behavehealth/constructs/Programs/ProgramAssignmentOverlay'
import { ProgramAssignSeatsOverlay } from '@behavehealth/constructs/Programs/ProgramAssignSeatsOverlay'
import { ProgramListOverlay } from '@behavehealth/constructs/Programs/ProgramListOverlay'
import { ProgramMoveOverlay } from '@behavehealth/constructs/ClientWorkflows/ProgramMoveOverlay'
import { ProgramPhasesReorderOverlay } from '@behavehealth/constructs/Programs/ProgramPhasesReorderOverlay'
import { ProgramRemoveOverlay } from '@behavehealth/constructs/ClientWorkflows/ProgramRemoveOverlay'
import { ProgramSeatOverlay } from '@behavehealth/constructs/Programs/ProgramSeatOverlay'

const Program = () => {
  return (
    <>
      <ProgramIndex />

      <AnimatedRoutes>
        <Route path={`lists/:view/reorder`} element={<ProgramPhasesReorderOverlay useV6Router />} />
        <Route path={`lists/:view/:id`} element={<ProgramListOverlay useV6Router />} />
        <Route path={`lists/:view/:phase_id/seats/:id`} element={<ProgramSeatOverlay useV6Router />} />
        <Route path={`lists/:view/:phase_id/assign-seats`} element={<ProgramAssignSeatsOverlay useV6Router />} />
        <Route path={`lists/:view/:phase_id/seats/:seat_id/:id`} element={<ProgramAssignmentOverlay useV6Router />} />
        <Route path={`lists/:view/:occupancy_id/move`} element={<ProgramMoveOverlay useV6Router />} />
        <Route path={`lists/:view/:occupancy_id/remove`} element={<ProgramRemoveOverlay useV6Router />} />
        <Route path={`lists/:view/assignments/:id`} element={<ProgramAssignmentOverlay useV6Router />} />
        <Route path={`plan/reorder`} element={<ProgramPhasesReorderOverlay useV6Router />} />
        <Route path={`plan/:id`} element={<ProgramListOverlay useV6Router />} />
        <Route path={`plan/:phase_id/seats/:id`} element={<ProgramSeatOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const ProgramIndex = () => {
  const params: any = useParams()
  const navigate = useNavigate()

  const isBehave = useStore((state) => state?.isBehave)

  const {
    data: program,
    isLoading,
    isError,
    error,
  }: any = useGet({
    name: ['program', params.resource_id],
    url: `/programs/${params.resource_id}`,
  })

  const { mutateAsync: updateProgram }: any = useUpdate({
    name: ['program', params.resource_id],
    url: `/programs/${params.resource_id}`,
    invalidate: ['programs'],
  })

  const { mutateAsync: deleteProgram }: any = useDelete({
    name: ['program', params.resource_id],
    url: `/programs/${params.resource_id}`,
    invalidate: ['programs'],
    onSuccess: () => {
      navigate('/programs')
    },
  })

  React.useEffect(() => {
    if (!isError) return

    console.error(error)
    Notifications.send(error?.error?.data?.errors?.[0], 'negative')

    // go back to the Programs list if unauthorized
    if (error?.error?.status === 401) navigate('/programs')
  }, [isError])

  React.useEffect(() => {
    store.dispatch(updateData(`programs.data.${program?.id}`, program))

    return () => {
      store.dispatch(clearData('programs'))
    }
  }, [program])

  if (!program || isLoading) return <PageLoader />

  const stillHasProgramClients = program.residents_count > 0

  const actions = (
    <>
      {
        <DeleteDialog
          title="Delete Program?"
          message="Are you sure you want to delete this Program? This action cannot be undone."
          onYes={deleteProgram}
          isDisabled={stillHasProgramClients}
          permission="programs.delete"
        >
          <DropdownItem
            label="Delete Program…"
            glyph="delete"
            color="red"
            isDisabled={stillHasProgramClients}
            permission="programs.delete"
          />
          {stillHasProgramClients && (
            <Alert type="negative" css={{ margin: 10, maxWidth: 250 }}>
              Please remove all Clients from Program Lists to Delete
            </Alert>
          )}
        </DeleteDialog>
      }
    </>
  )

  return (
    <PageGrid breakpoint={3} columns="280px 1fr">
      <Nav
        showBack
        breakpoint={3}
        headingSize={400}
        record={program}
        title={program.name}
        avatar={program.avatar}
        desktopProps={{
          icon: 'checklist',
          title: 'Program',
          headingSize: 300,
          avatar: undefined,
          description: undefined,
        }}
        help={<HelpTagIframe id="program_profile" />}
        profile={
          <PageProfile
            more
            record={program}
            actions={actions}
            title={program.name}
            underActions={
              <>
                <JoinMeetingButton
                  title={program.name}
                  meetingID={program.personal_meeting_room?.id}
                  meetingName={`${program.name} Meeting Room`}
                />

                <div className="!mt-3 w-full">
                  <TagsSelector
                    tags={program?.tags}
                    onSave={(tagIDs: any) => updateProgram({ tag_ids: tagIDs })}
                    smartCategories="programs"
                  />
                </div>
              </>
            }
          />
        }
      >
        <NavGroup label="General">
          <NavItem as={NavLink} label="General Info" feature="general_info" to={`general-info`} />
          <NavItem as={NavLink} label="Program Lists" icon="program_lists" to={`lists`} />
          <NavItem as={NavLink} label="Program Capacity" icon="program_plan" color="green" to={`plan`} />
          <NavItem as={NavLink} feature="employees" label="Managing Staff" to={`managing-staff`} />

          <NavItem as={NavLink} isDev feature="todos" label="To-Do" to={`todo`} featureFlagV2="todos" />
          <NavItem as={NavLink} isDev feature="calendar" label="Calendar" to={`calendar`} featureFlagV2="calendar" />
          <NavItem as={NavLink} feature="files" label="Files" to={`files`} featureFlagV2="files" />

          <NavItem
            as={NavLink}
            isDev
            feature="activity_timeline"
            label="Activity Timeline"
            to={`activity-timeline`}
            featureFlagV2="activity_timeline"
            permission="activity_timeline.view"
          />

          <NavItem
            as={NavLink}
            isDev
            feature="form_submissions"
            label="Form Inbox"
            to={`form-submissions`}
            featureFlagV2="form_submissions"
          />
          <NavItem as={NavLink} isDev feature="settings" label="Settings" to={`settings`} featureFlagV2={isBehave} />
        </NavGroup>

        <NavGroup label="Notes">
          <NavItem
            as={NavLink}
            showMarketing
            feature="custom_notes"
            label="Custom Notes"
            to={`custom-notes`}
            permission="program_custom_notes.view"
            featureFlagV2="program_custom_notes"
          />
        </NavGroup>
      </Nav>

      <Routes>
        <Route index element={<Navigate to="general-info" replace />} />

        <Route path={`activity-timeline/*`} element={<ActivityTimeline />} />
        <Route path={`calendar/*`} element={<Calendar />} />
        <Route path={`custom-notes/*`} element={<CustomNotes />} />
        <Route path={`files/*`} element={<Files />} />
        <Route path={`form-submissions/*`} element={<FormSubmissions />} />
        <Route path={`general-info/*`} element={<GeneralInfo />} />
        <Route path={`lists/*`} element={<Phases />} />
        <Route path={`managing-staff/*`} element={<AssignedEmployees />} />
        <Route path={`plan/*`} element={<ProgramPlan />} />
        <Route path={`settings/*`} element={<Settings />} />
        <Route path={`todo/*`} element={<Todos />} />
      </Routes>
    </PageGrid>
  )
}

export default withBackListener(withPageError(Program))
