import React from 'react'

import { titleDate } from '../../../utils/functions'

import Flex from '../../../components/Flex'
import CardTreeItem from '../../../components/CardTreeItem'
import FormSection from '../../../components/Forms/FormSection'
import InsuranceClaimStatus from '../../../components/Statuses/InsuranceClaimStatus'

import { useSettings } from '../../../hooks/useSettings'

export const ClaimStatuses = ({ statuses, allOpen = false }: any) => {
	const { timezone } = useSettings()

	if (!statuses) return null

	return statuses?.map((claimStatus: any) => (
		<CardTreeItem
			isOpen={allOpen}
			key={claimStatus?.id}
			title={
				<Flex gap="1rem">
					<div>{titleDate(claimStatus?.dated_at, timezone)}</div>
					<InsuranceClaimStatus status={claimStatus?.status} />
				</Flex>
			}
		>
			<FormSection className="pt-3 pb-5 pr-4">
				<ul className="pl-4 list-disc">
					{claimStatus?.clearing_house_messages?.map((message: any) => (
						<li className="mt-1">{message?.message || '–'}</li>
					))}
				</ul>
			</FormSection>
		</CardTreeItem>
	))
}
