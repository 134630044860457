import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { DataTable } from '../../components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '../../constructs/Filters/constants'

import { MainCell } from '../../components/DataTable/cells/MainCell'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'

export const HubspotCommunityProfilesDataTable = (props: any) => {
  const match = useRouteMatch()

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        formatValue: ({ data, value }: any) => (
          <MainCell
            as={props.mainLinkAs}
            id={data.id}
            value={value}
            avatar={data.avatar || ''}
            to={props.to ? props.to(data) : undefined}
            onClick={props.onSelect ? () => props.onSelect(data) : props.onClick ? () => props.onClick(data) : undefined}
          />
        ),
      },
      {
        title: 'Hubspot Community Profile ID',
        model: 'id',
        type: 'hubspot_community_profile',
      },
      {
        title: 'Business Status',
        model: 'business_status',
      },
      {
        title: 'Status',
        model: 'status',
        type: 'title',
      },
      {
        title: 'Phone Number',
        model: 'phone_number',
        type: 'phone',
      },
      {
        title: 'Email',
        model: 'email',
        type: 'email',
      },
      {
        title: 'Primary State',
        model: 'primary_state',
      },
      {
        title: 'Timezone',
        model: 'timezone',
        width: 160,
      },
      {
        title: 'Smart Flags',
        model: 'smart_flags',
      },
      {
        title: 'HQ Profile URL',
        model: 'hq_profile_url',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={value} value={value} />
        },
      },
      {
        title: 'Public Profile URL',
        model: 'public_profile_url',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={value} value={'Public Profile'} />
        },
      },
      {
        title: 'Community Profile',
        model: 'community_public_profile_url',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={value} value={'Community Profile'} />
        },
      },
      {
        title: 'Website',
        model: 'website',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={value} value={value} />
        },
      },
      {
        title: 'Twitter / X',
        model: 'x_twitter',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={`https://twitter.com/${value}`} value={value} />
        },
      },
      {
        title: 'YouTube',
        model: 'youtube',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={value} value={'YouTube'} />
        },
      },
      {
        title: 'Facebook',
        model: 'facebook',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={value} value={'Facebook'} />
        },
      },
      {
        title: 'Google',
        model: 'google',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={value} value={'Google'} />
        },
      },
      {
        title: 'Instagram',
        model: 'instagram',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={value} value={'Instagram'} />
        },
      },
      {
        title: 'LinkedIn',
        model: 'linkedin',
        formatValue: ({ value }: any) => {
          if (!value) return null
          return <LinkCell isExternal href={value} value={'LinkedIn'} />
        },
      },
      {
        title: 'Internal Notes',
        model: 'internal_notes',
      },
      {
        title: 'Map Visibility',
        model: 'map_visibility',
      },
      {
        title: 'Marketing Contact Status',
        model: 'marketing_contact_status',
      },
      {
        title: 'Date Added',
        model: 'date_added',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: '_updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return <DataTable asCard {...props} columns={columns} title="HubSpot Community Profiles" icon="hubspot" filtersConfig={FILTERS_CONFIG} />
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  id: {
    label: 'Hubspot Community Profile ID',
    type: 'string',
  },
  business_status: {
    label: 'Business Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ],
  },
  status: {
    label: 'Status',
    type: 'string',
  },
  phone_number: {
    label: 'Phone Number',
    type: 'string',
  },
  email: {
    label: 'Email',
    type: 'string',
  },
  primary_state: {
    label: 'Primary State',
    type: 'string',
  },
  timezone: {
    label: 'Timezone',
    type: 'string',
  },
  smart_flags: {
    label: 'Smart Flags',
    type: 'string',
  },
  hq_profile_url: {
    label: 'HQ Profile URL',
    type: 'string',
  },
  public_profile_url: {
    label: 'Public Profile URL',
    type: 'string',
  },
  community_public_profile_url: {
    label: 'Community Profile',
    type: 'string',
  },
  website: {
    label: 'Website',
    type: 'string',
  },
  x_twitter: {
    label: 'Twitter / X',
    type: 'string',
  },
  youtube: {
    label: 'YouTube',
    type: 'string',
  },
  facebook: {
    label: 'Facebook',
    type: 'string',
  },
  google: {
    label: 'Google',
    type: 'string',
  },
  instagram: {
    label: 'Instagram',
    type: 'string',
  },
  linkedin: {
    label: 'LinkedIn',
    type: 'string',
  },
  internal_notes: {
    label: 'Internal Notes',
    type: 'string',
  },
  map_visibility: {
    label: 'Map Visibility',
    type: 'string',
  },
  marketing_contact_status: {
    label: 'Marketing Contact Status',
    type: 'string',
  },
  date_added: {
    label: 'Date Added',
    type: 'date_time',
  },
  _updated_at: {
    label: 'Date Updated',
    type: 'date_time',
  },
}
