import React from 'react'
import { Routes, useLocation } from 'react-router-dom-v5-compat'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Portal from './Portal'

export const AnimatedRoutes: React.FC<any> = (props: any) => {
  const { animation = 'animation', className = 'overlays', children, timeout = { enter: 400, exit: 400 }, portalType = 'z90' } = props

  const location = useLocation()

  return (
    <Portal type={portalType}>
      <TransitionGroup className={className}>
        <CSSTransition in={true} key={location.key} classNames={animation} timeout={timeout}>
          <Routes location={location}>{children}</Routes>
        </CSSTransition>
      </TransitionGroup>
    </Portal>
  )
}
