import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const STATUSES = {
  draft: {
    label: 'Draft',
    color: 'blue',
  },
  active: {
    label: 'Active',
    color: 'vividBlue',
  },
  selected: {
    label: 'Selected',
    color: 'vividBlue',
    isDisabled: true,
  },
  signed: {
    label: 'Signed',
    color: 'green',
    isDisabled: true,
  },
  paid: {
    label: 'Paid',
    color: 'green',
    isDisabled: true,
  },
  payment_method_on_file: {
    label: 'Payment Method Added',
    color: 'green',
    isDisabled: true,
  },
  payment_processing: {
    label: 'Processing Payment',
    color: 'orange',
    isDisabled: true,
  },
  payment_failed: {
    label: 'Payment Failed',
    color: 'red',
    isDisabled: true,
  },
  completed: {
    label: 'Completed',
    color: 'green',
    isDisabled: true,
  },
  expired: {
    label: 'Expired',
    color: 'red',
  },
  archived: {
    label: 'Archived',
    color: 'gray',
  },
} as const

export const QuoteStatus = ({ ...rest }) => {
  return <SmartStatus statuses={STATUSES} {...rest} />
}
