import React from 'react'

import { Card, ChartCard, State } from '@behavehealth/components'

import { MarketingFunnelChart } from '@behavehealth/components/Charts'

import { AnalyticsContext } from '../context'

const MarketingAnalytics = () => {
  const { timeframe, renderer } = React.useContext(AnalyticsContext)

  const [loading, setLoading] = React.useState(false)

  if (loading) {
    return (
      <Card>
        <State isLoading />
      </Card>
    )
  }

  return (
    <div css={styles.grid}>
      <ChartCard title="Marketing Funnel" subtitle="Marketing funnel until qualification">
        <MarketingFunnelChart timeframe={timeframe} renderer={renderer} />
      </ChartCard>
    </div>
  )
}

const styles = {
  grid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '100%',

    '@media (min-width: 768px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
    },
  },
}

export default MarketingAnalytics
