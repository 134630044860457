import React from 'react'
import { persist } from 'zustand/middleware'
import create from 'zustand'
import groupBy from 'lodash/groupBy'
import size from 'lodash/size'

import { useGet } from '../../hooks/useNewAPI'
import { BannerMessage } from '../../components/BannerMessage'
import { TopBarMessage } from '../../components/TopBarMessage'

export const LoginMessages = ({ app }: any) => {
  const dismissForever = useLoginMessagesStore((state: any) => state.dismissForever)
  const foreverDismissedIds = useLoginMessagesStore((state: any) => state.foreverDismissedIds)

  const [dismissedIds, setDismissedIds]: any = React.useState(foreverDismissedIds)

  const queryParams = { apps: app }

  const { data, isLoading }: any = useGet({
    name: ['login-messages', queryParams],
    url: `/public/login_messages`,
    params: queryParams,
    options: { enabled: !!app },
  })

  const grouped: any = React.useMemo(() => {
    if (!data) return {}

    const filtered = data.filter((o: any) => !dismissedIds.includes(o.id))

    return groupBy(filtered, 'appearance')
  }, [data, dismissedIds, foreverDismissedIds])

  const handleDismiss: any = (message: any) => {
    if (!message?.id) return

    setDismissedIds((c) => [...c, message.id])

    if (message.is_dismissible) dismissForever(message?.id)
  }

  if (isLoading || !data) return null

  return (
    <>
      {grouped?.top_bar && <TopBarMessages messages={grouped.top_bar} onDismiss={handleDismiss} />}
      {grouped?.banner && <BannerMessages messages={grouped.banner} onDismiss={handleDismiss} />}
    </>
  )
}

const BannerMessages = (props: any) => {
  const { messages, onDismiss } = props

  const [activeIndex, setActiveIndex] = React.useState(0)

  const count = size(messages)

  const goNext = () => {
    setActiveIndex((c) => {
      if (c < count - 1) return c + 1
      return 0
    })
  }

  const goPrev = () => {
    setActiveIndex((c) => {
      if (c > 0) return c - 1
      return count - 1
    })
  }

  const activeMessage = messages?.[activeIndex]

  if (!activeMessage) return null

  return (
    <BannerMessage
      color={activeMessage.color}
      currentCount={activeIndex + 1}
      glyph={activeMessage.glyph}
      title={activeMessage.internal_title}
      iframeURL={activeMessage.iframe_url}
      onDismissClick={() => {
        onDismiss(activeMessage)

        setActiveIndex((c) => {
          const nextCount = count - 1

          if (c < nextCount - 1) return c + 1
          else if (c > 0) return c - 1
          else return 0
        })
      }}
      onNextClick={goNext}
      onPrevClick={goPrev}
      richText={activeMessage.content}
      totalCount={count}
    />
  )
}

const TopBarMessages = (props: any) => {
  const { messages, onDismiss } = props

  const [activeIndex, setActiveIndex] = React.useState(0)

  const count = size(messages)

  const goNext = () => {
    setActiveIndex((c) => {
      if (c < count - 1) return c + 1
      return 0
    })
  }

  const goPrev = () => {
    setActiveIndex((c) => {
      if (c > 0) return c - 1
      return count - 1
    })
  }

  const activeMessage = messages?.[activeIndex]

  if (!activeMessage) return null

  return (
    <TopBarMessage
      color={activeMessage.color}
      currentCount={activeIndex + 1}
      glyph={activeMessage.glyph}
      onDismissClick={() => {
        onDismiss(activeMessage)

        setActiveIndex((c) => {
          const nextCount = count - 1

          if (c < nextCount - 1) return c + 1
          else if (c > 0) return c - 1
          else return 0
        })
      }}
      onNextClick={goNext}
      onPrevClick={goPrev}
      richText={activeMessage.content}
      totalCount={count}
    />
  )
}

const store = (set: any, get: any) => ({
  foreverDismissedIds: [],
  dismissForever: (id: string) => {
    set((state: any) => ({ foreverDismissedIds: [...state.foreverDismissedIds, id] }))
  },
})

const useLoginMessagesStore = create(persist(store, { name: 'bh.dismissed_login_messages' }))
