import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { DocumentationStatusReportDataTable } from '@behavehealth/constructs/LiveReports/DocumentationStatusReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ClientsJourney = () => {
  const tableProps = useDataTable({
    name: ['reports', 'documentation-status'],
    endpoint: `/live_reports?category=clients_journey`,
    localStorageKey: 'report_documentation_status_v1',
  })

  const to = React.useMemo(() => (rowData: any) => getClientLink(rowData), [])

  return (
    <Page icon="patient_progress_measure" title="Documentation Status">
      <Grid>
        <DocumentationStatusReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ClientsJourney)
