import React, { Component } from 'react'
import get from 'lodash/get'

import { UATests } from '../../../../../utils/constants'
import { withFormContext } from '../../../../Forms/context'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import DateInput from '../../../../Forms/DateInput'
import Divider from '../../../../Divider'
import FormSection from '../../../../Forms/FormSection'
import Grid from '../../../../Grid'
import Input from '../../../../Forms/Input'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import YesNoRadioGroup from '../../../../Forms/elements/YesNoRadioGroup'

class SubstanceUseHistoryV1 extends Component {
  render() {
    const { isEditable } = this.props

    const substances = this.props.form.getFieldValue('data.substance_use_history') || {}
    const substancesKeys = Object.keys(substances)
    let used = []

    for (let i = 0; i < substancesKeys.length; i++) {
      if (substances[substancesKeys[i]]?.used === true) {
        used.push(substances[substancesKeys[i]])
      }
    }

    if (isEditable)
      return (
        <Grid gap={16}>
          {UATests.map((substance) => (
            <FormSection key={substance.model} heading={substance.name} layout="vertical">
              <RadioGroup
                layout="horizontal-dense"
                label={`Has the client ever used ${substance.name?.toLowerCase()}?`}
                model={`data.substance_use_history.${substance.model}.used`}
                defaultValue={null}
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when={`data.substance_use_history.${substance.model}.used`} is={true}>
                {['amphetamines', 'barbituates', 'benzodiazepines'].includes(substance.model) && (
                  <SmartTextarea
                    useDictation
                    label="What types of substances has the client used?"
                    model={`data.substance_use_history.${substance.model}.substance_type`}
                  />
                )}

                <SmartTextarea
                  useDictation
                  label="How did the client acquire this substance?"
                  model={`data.substance_use_history.${substance.model}.acquire_method`}
                />

                <YesNoRadioGroup
                  label="Is this medication prescribed?"
                  model={`data.substance_use_history.${substance.model}.is_prescribed`}
                />

                <ContextShow when={`data.substance_use_history.${substance.model}.is_prescribed`} is={true}>
                  <YesNoRadioGroup
                    label="Is the client taking the substance as directed?"
                    model={`data.substance_use_history.${substance.model}.is_following_prescription_directions`}
                  />

                  <YesNoRadioGroup
                    label="Do you give consent for the prescribing doctor?"
                    model={`data.substance_use_history.${substance.model}.does_give_prescribing_doctor_consent`}
                  />

                  <ContextShow when={`data.substance_use_history.${substance.model}.does_give_prescribing_doctor_consent`} is={true}>
                    <SmartTextarea
                      useDictation
                      label="Doctor Information"
                      model={`data.substance_use_history.${substance.model}.doctor_information`}
                    />
                  </ContextShow>
                </ContextShow>

                <Input
                  type="number"
                  label="At what age did the client use this substance for the first time?"
                  suffix="y/o"
                  size={4}
                  model={`data.substance_use_history.${substance.model}.first_use_age`}
                />
                <Input
                  type="number"
                  label="At what age did the client begin daily use of this substance?"
                  suffix="y/o"
                  size={4}
                  model={`data.substance_use_history.${substance.model}.daily_use_age`}
                />

                <RadioGroup
                  label="During the client's heaviest use, how often did they use?"
                  model={`data.substance_use_history.${substance.model}.use_frequency`}
                  layout="vertical-dense"
                >
                  <Radio label="Every day" value="daily" />
                  <Radio label="4-6 times per week" value="4_6_times_per_week" />
                  <Radio label="1-3 times per week" value="1_3_times_per_week" />
                </RadioGroup>

                <SmartTextarea
                  useDictation
                  label="During the client's heaviest use, how much did they use per day/week?"
                  model={`data.substance_use_history.${substance.model}.use_quantity`}
                />

                <SmartTextarea
                  useDictation
                  label="What is the client's current frequency of use?"
                  model={`data.substance_use_history.${substance.model}.current_use_frequency`}
                />

                <SmartTextarea
                  useDictation
                  label="For how long at this rate?"
                  model={`data.substance_use_history.${substance.model}.current_use_frequency_length`}
                />

                <CheckboxGroup label="What was the method of use?" layout="vertical-dense">
                  <Checkbox label="Oral" model={`data.substance_use_history.${substance.model}.use_method.oral`} />
                  <Checkbox label="Smoke" model={`data.substance_use_history.${substance.model}.use_method.smoke`} />
                  <Checkbox label="Snort" model={`data.substance_use_history.${substance.model}.use_method.snort`} />
                  <Checkbox label="Inhalant" model={`data.substance_use_history.${substance.model}.use_method.inhalant`} />
                  <Checkbox label="IV (intravenous)" model={`data.substance_use_history.${substance.model}.use_method.intravenous`} />
                  <Checkbox label="IM (intramuscular)" model={`data.substance_use_history.${substance.model}.use_method.intramuscular`} />
                </CheckboxGroup>

                <RadioGroup
                  layout="horizontal-dense"
                  label="Does the client know the exact date they last used this substance?"
                  model={`data.substance_use_history.${substance.model}.last_use_date_known`}
                >
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>

                <ContextShow when={`data.substance_use_history.${substance.model}.last_use_date_known`} is={true}>
                  <DateInput label="Date of last use:" model={`data.substance_use_history.${substance.model}.last_use_date`} />
                </ContextShow>

                <ContextShow when={`data.substance_use_history.${substance.model}.last_use_date_known`} is={false}>
                  <Input label="Approximately how long ago?" model={`data.substance_use_history.${substance.model}.last_use_date_approx`} />
                </ContextShow>

                <Divider className="!m-0" />
              </ContextShow>
            </FormSection>
          ))}
        </Grid>
      )

    if (used.length === 0) {
      return (
        <Grid gap={16}>
          <p>No Substances Use History</p>
        </Grid>
      )
    }

    return (
      <Grid gap={16}>
        {UATests.map((substance, idx) => (
          <ContextShow key={substance.model} when={`data.substance_use_history.${substance.model}.used`} is={true}>
            <FormSection heading={substance.name} layout="vertical">
              <Input
                type="number"
                label="At what age did the client use this substance for the first time?"
                suffix="y/o"
                size={4}
                model={`data.substance_use_history.${substance.model}.first_use_age`}
              />
              <Input
                type="number"
                label="At what age did the client begin daily use of this substance?"
                suffix="y/o"
                size={4}
                model={`data.substance_use_history.${substance.model}.daily_use_age`}
              />

              <RadioGroup
                label="During the client's heaviest use, how often did they use?"
                model={`data.substance_use_history.${substance.model}.use_frequency`}
                layout="vertical-dense"
              >
                <Radio label="Every day" value="daily" />
                <Radio label="4-6 times per week" value="4_6_times_per_week" />
                <Radio label="1-3 times per week" value="1_3_times_per_week" />
              </RadioGroup>

              <SmartTextarea
                useDictation
                label="During the client's heaviest use, how much did they use per day/week?"
                model={`data.substance_use_history.${substance.model}.use_quantity`}
              />

              <CheckboxGroup label="What was the method of use?" layout="vertical-dense">
                <Checkbox label="Oral" model={`data.substance_use_history.${substance.model}.use_method.oral`} />
                <Checkbox label="Smoke" model={`data.substance_use_history.${substance.model}.use_method.smoke`} />
                <Checkbox label="Snort" model={`data.substance_use_history.${substance.model}.use_method.snort`} />
                <Checkbox label="Inhalant" model={`data.substance_use_history.${substance.model}.use_method.inhalant`} />
                <Checkbox label="IV (intravenous)" model={`data.substance_use_history.${substance.model}.use_method.intravenous`} />
                <Checkbox label="IM (intramuscular)" model={`data.substance_use_history.${substance.model}.use_method.intramuscular`} />
              </CheckboxGroup>

              <RadioGroup
                layout="horizontal-dense"
                label="Does the client know the exact date they last used this substance?"
                model={`data.substance_use_history.${substance.model}.last_use_date_known`}
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when={`data.substance_use_history.${substance.model}.last_use_date_known`} is={true}>
                <DateInput label="Date of last use:" model={`data.substance_use_history.${substance.model}.last_use_date`} />
              </ContextShow>

              <ContextShow when={`data.substance_use_history.${substance.model}.last_use_date_known`} is={false}>
                <Input label="Approximately how long ago?" model={`data.substance_use_history.${substance.model}.last_use_date_approx`} />
              </ContextShow>

              {idx > 0 && <Divider className="!m-0" />}
            </FormSection>
          </ContextShow>
        ))}
      </Grid>
    )
  }
}

export default withFormContext(SubstanceUseHistoryV1)
