import React from 'react'
import size from 'lodash/size'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import RichTextEditor from '../../components/Forms/RichTextEditor'

export const QuoteOfferOverlay = (props: any) => {
  const { data, onClose, onSave } = props

  const form = React.useRef(null)

  const [featureFlagConfig, setFeatureFlagConfig] = React.useState(data?.feature_flag_config)

  const handleSave = () => {
    onSave({
      ...data,
      ...form.current.getFormValue(),
      feature_flag_config: featureFlagConfig || null,
      feature_flag_config_id: featureFlagConfig?.id || null,
    })

    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center">
      <Overlay.Header title="Edit Quote Offer" glyph="edit" onClose={onClose} />

      <Overlay.Content className="p-5">
        <Form getForm={form} initialModel={data}>
          <FormSection>
            <Input label="Name" model="name" />
            <ObjectSelector
              label="Feature Flag Config"
              type="admin.feature_flag_configs"
              value={featureFlagConfig}
              model="feature_flag_config"
              icon="feature_flags"
              selectTitle={(data: any) => data?.name}
              selectDescription={(data: any) => {
                if (!data?.config) return null

                const allCount = size(data.config)
                const enabledCount = size(Object.values(data.config).filter((c: any) => c === true))

                return `${enabledCount} enabled / ${allCount}`
              }}
              onUpdate={({ object }: any) => {
                if (!object) {
                  setFeatureFlagConfig(null)
                  return
                }

                setFeatureFlagConfig(object)
              }}
            />
            <RichTextEditor label="Description" model="description" />
          </FormSection>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" glyph="tick_circle" color="green" type="primary" flex="100 1 auto" size={200} onClick={handleSave} />
      </Overlay.Footer>
    </Overlay>
  )
}
