import React from 'react'

import { Timeline, Page, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useParams } from 'react-router-dom-v5-compat'

const ActivityTimeline: React.FC = () => {
  const { resource_id } = useParams()

  return (
    <Page feature="activity_timeline" help={<HelpTagIframe id="activity_timeline" />}>
      <Timeline asRecipient recordType="property" recordID={resource_id} />
    </Page>
  )
}

export default withPageError(ActivityTimeline)
