import React from 'react'

import { Page, PageLayout } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Hub } from '@behavehealth/constructs/FinancialHub/Hub'
import { get } from '@behavehealth/modules/api/requests'

import { DateRangeSelector } from '@behavehealth/components/Forms/DateRangeSelector'
import Alert from '@behavehealth/components/Alert'
import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'

import useStore from '@behavehealth/modules/store'

const FinancialHub: React.FC = () => {
  const tenant = useStore((state: any) => state.tenant)
  const isAccountingActive = tenant.is_accounting_connected

  const [loading, setLoading] = React.useState(false)
  const [token, setToken] = React.useState(null)
  const [dates, setDates] = React.useState(null)

  React.useEffect(() => {
    const fetchToken = async () => {
      setLoading(true)

      try {
        const result = await get('/apps/financials/token')
        setToken(result.token)

        setLoading(false)
      } catch (errors) {
        console.error(errors)
        setLoading(false)
      }
    }

    if (isAccountingActive) fetchToken()
  }, [])

  return (
    <Page
      feature="financial_hub"
      breakpoint="0"
      isLoading={loading}
      actions={
        isAccountingActive && (
          <DateRangeSelector onChange={setDates} startDate={dates?.[0]} endDate={dates?.[1]} defaultPeriod="last_three_months" />
        )
      }
    >
      <PageLayout>
        {isAccountingActive ? (
          <Hub token={token} subdomain={tenant?.subdomain} from={dates?.[0]} until={dates?.[1]} />
        ) : (
          <Grid gap="1rem">
            <Alert type="warning">
              Please connect your <strong>Accounting Software</strong> account to view your Finance Hub Stats here.
            </Alert>
            <Button type="primary" label="Connect Accounting Software" link="/settings/accounting" className="!w-fit" />
          </Grid>
        )}
      </PageLayout>
    </Page>
  )
}

export default withPageError(FinancialHub)
