import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DEFAULT_FILTERS } from '../Filters/constants'
import { niceAmount } from '../../utils/functions'
import { PRICING_STRATEGIES, PRICING_UNIT_TYPES } from '../../components/Billing/constants'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { FacilitiesTable } from '../../components/Forms/Selectors/tables/FacilitiesTable'

const TYPES: any = {
  plan: 'Plan',
  addon: 'Add-On',
  service: 'Service',
  platform_plan: 'Platform Plan',
}

export const ProductsDataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            as={NavLink}
            id={data.id}
            icon={data.icon}
            value={data.name}
            to={props.to ? props.to(data) : undefined}
            onClick={props.onClick ? () => props.onClick(data) : undefined}
          />
        ),
      },
      {
        title: 'Connected EHR',
        model: 'facility',
        width: 300,
        type: 'profile',
      },
      {
        title: 'Status',
        model: 'status',
        type: 'title',
      },
      {
        title: 'SKU',
        model: 'sku',
      },
      {
        title: 'Type',
        model: 'category',
        formatValue: ({ value }: any) => TYPES?.[value] || null,
      },
      {
        title: 'Description',
        model: 'description',
        type: 'rich_text',
        width: 300,
      },
      {
        title: 'Public Name',
        model: 'public_name',
      },
      {
        title: 'Price',
        model: 'price',
        width: 120,
        formatValue: ({ value }: any) => (value ? niceAmount(value) : null),
      },
      {
        title: 'Pricing Strategy',
        model: 'pricing_type',
        formatValue: ({ value }: any) => PRICING_STRATEGIES?.[value] || null,
      },
      {
        title: 'Unit Type',
        model: 'price_unit_type',
        formatValue: ({ value }: any) => PRICING_UNIT_TYPES?.[value] || null,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return <DataTable asCard title="Products" icon="certificate" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  category: {
    label: 'Type',
    type: 'multi_select',
    options: [
      { label: 'Plan', value: 'plan' },
      { label: 'Add-On', value: 'addon' },
      { label: 'Service', value: 'service' },
      { label: 'Platform Plan', value: 'platform_plan' },
    ],
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'In Use', value: 'in_use' },
      { label: 'Archived', value: 'archived' },
    ],
  },
  sku: {
    label: 'SKU',
    type: 'string',
  },
  facility: {
    label: 'Connected EHR',
    type: 'multi_overlay',
    polymorphic: false,
    selectTitle: (item: any) => item.name,
    icon: 'enterprise',
    table: <FacilitiesTable />,
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  public_name: {
    label: 'Public Name',
    type: 'string',
  },
  price: {
    label: 'Price',
    type: 'number',
  },
  pricing_type: {
    label: 'Pricing Strategy',
    type: 'multi_select',
    options: Object.entries(PRICING_STRATEGIES).map(([value, label]) => ({ value, label })),
  },
  price_unit_type: {
    label: 'Unit Type',
    type: 'multi_select',
    options: Object.entries(PRICING_UNIT_TYPES).map(([value, label]) => ({ value, label })),
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
