import React from 'react'
import { connect } from 'react-redux'

import { COLORS } from '../../theme'
import { toggleDevBar } from '../../actions/common'
import FramesPerSecond from './FramesPerSecond'
import Nodes from './DOMNodes'

const DevStats = ({ show }) => (
  <div css={{ top: 0, left: 0, width: '100%', zIndex: 10000 }}>
    {show && (
      <div css={styles}>
        <FramesPerSecond />
        <Nodes />
        {/* this comment fixes build? strange bug */}
      </div>
    )}
  </div>
)

const styles = {
  width: '100%',
  height: '30px',
  borderBottom: `1px solid ${COLORS.divider}`,

  fontSize: '11px',
  fontWeight: 'bold',
  background: 'rgb(25, 29, 53)',
  color: 'rgb(150, 245, 250)',

  padding: '3px',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '> *': {
    marginRight: '10px',
  },
}

const mapStateToProps = (state, props) => ({
  show: state?.common?.global?.devBar || props.show,
})

const mapDispatchToProps = (dispatch) => ({
  toggleDevBar: () => dispatch(toggleDevBar()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DevStats)
