import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { NursingReportDataTable } from '@behavehealth/constructs/LiveReports/NursingReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const NURSING = {
  ciwa: 'ciwa',
  cows: 'cows',
  fall_risk_assessment: 'fall-risk-assessment',
  nursing_assessment: 'nursing-assessment',
  nursing_reassessment: 'nursing-reassessment',
  progress_note: 'progress-note',
}

const Nursing = () => {
  const tableProps = useDataTable({
    name: ['reports', 'nursing'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_nursing_v1',
    params: { category: 'nursing' },
  })

  const to = React.useMemo(
    () => (rowData: any) => `${getResourceLink(rowData.client)}/nursing/${NURSING?.[rowData?.subcategory]}/${rowData.id}`,
    [],
  )

  return (
    <Page feature="nursing" title="Live Nursing Report">
      <Grid>
        <NursingReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Nursing)
