import React from 'react'
import clsx from 'clsx'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { useVerifyPermission } from '../../hooks/useVerifyPermission'
import { withOverlayError } from '../../hocs/withOverlayError'

import Accordion from '../../components/Accordion'
import Button from '../../components/Button'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Icon from '../../components/Icon'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Status from '../../components/Status'
import Textarea from '../../components/Forms/Textarea'

import { ROUNDS_CATEGORIES } from '../Rounds/constants'
import { RoundLineRecord } from '../Rounds/RoundsOverlay'

const RootRoundLineOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    queryKey,
  } = useOverlay({
    name: 'round_lines',
    endpoint: '/round_lines',
    invalidate: 'round_lines',
    options: props,
    disableParentRequest: true,
  })

  const { timezone } = useSettings()
  const { verifyPermission } = useVerifyPermission()

  const save = () => {
    const formData = form.current?.getFormValue()

    saveWithData({
      round_note_attributes: formData?.round_note,
    })
  }

  const template = React.useMemo(() => {
    return data?.round?.saved_settings || null
  }, [data])

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="rounds" title="Round" />

      {/* {!isEditable && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/rounds/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )} */}

      <Overlay.Content>
        <Section>
          <Form
            isCompact
            getForm={form}
            initialModel={initialModel}
            isEditable={isEditable}
            timezone={timezone}
            onValidationUpdate={onValidationUpdate}
          >
            <Accordion isOpen title="Note" icon="rounds" size={200}>
              <FormSection maxWidth="100%">
                <DateTimeInput
                  defaultToNow
                  label="Date & Time"
                  model="round_note.dated_at"
                  validations={{
                    presence: {
                      message: 'Please add a date & time',
                    },
                  }}
                />

                <RadioGroup
                  label="Presence"
                  withHover={false}
                  layout="horizontal-dense"
                  model="round_note.presence"
                  validations={{
                    presence: {
                      message: 'Please select a presence status',
                    },
                  }}
                >
                  <Radio label="Present" value="present" />
                  <Radio label="Absent" value="absent" />
                </RadioGroup>

                <Textarea
                  label="Note"
                  model="round_note.notes"
                  validations={{
                    presence: {
                      message: 'Please add a note',
                    },
                  }}
                />

                {!isEditable && (
                  <ObjectSelector
                    label="Written by"
                    type="employees"
                    isEditable={false}
                    icon="employees"
                    value={data?.round_note?.author}
                  />
                )}

                {!isEditable && <DateTimeInput label="Date Created" value={data?.round_note?.created_at} />}

                {!isEditable && data?.round_note?.updated_at !== data?.round_note?.created_at && (
                  <DateTimeInput label="Last Updated" value={data?.round_note?.updated_at} />
                )}
              </FormSection>
            </Accordion>
          </Form>

          <Accordion isOpen minimal title="Documentation" icon="rounds" size={200}>
            {Object.keys(ROUNDS_CATEGORIES).map((recordType) => {
              const config = ROUNDS_CATEGORIES[recordType]

              const hasFeatureFlag = verifyPermission({ featureFlag: config.featureFlag })
              const hasCreatePermission = verifyPermission({ permission: `${config.permission}.create` })
              const hasEditPermission = verifyPermission({ permission: `${config.permission}.edit` })

              const didAdd = !!data?.[recordType]

              if (!hasFeatureFlag) return null

              if (!config) {
                console.error(`No config found for recordType "${recordType}"`)
                return null
              }

              if (!template?.documentation?.[recordType]) return null

              return (
                <div
                  className={clsx(
                    'flex items-center px-3 py-2 [&+&]:border-t border-0 border-solid border-divider',
                    didAdd && '!bg-[#f2fdf2]',
                  )}
                >
                  <RoundLineRecord
                    roundLine={data}
                    roundName={data?.round?.name}
                    record={data?.[recordType]}
                    recordType={recordType}
                    roundLineId={data?.id}
                    invalidateQueryKey={queryKey}
                    hasCreatePermission={hasCreatePermission}
                    hasEditPermission={hasEditPermission}
                    before={
                      <div className="flex flex-nowrap items-center flex-[100_1_auto]">
                        <Icon icon={config.icon} className="mr-2" size={18} />
                        <div className="flex-1 text-[0.9rem] font-[600]">{config.singularLabel}</div>
                      </div>
                    }
                  />
                </div>
              )
            })}

            <div className="px-3 py-2 border-t border-0 border-solid border-divider">
              <Button
                useGlyphForTarget
                label="Open Full Round"
                target="_blank"
                icon="rounds"
                type="minimal"
                link={`/rounds/${data?.round?.id}`}
                size={200}
                permission="rounds.view"
              />
            </div>
          </Accordion>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission={isNew ? 'rounds.create' : 'rounds.edit'}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Round"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="rounds.edit"
            />

            <DeleteDialog
              title="Delete Round?"
              message="Are you sure you want to delete this Round? This action cannot be undone."
              onYes={deleteRecord}
              permission="rounds.delete"
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="rounds.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const RoundLineOverlay = withOverlayError(RootRoundLineOverlay)
