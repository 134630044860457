import React from 'react'
import { useParams } from 'react-router-dom'
import { Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'
import kebabCase from 'lodash/kebabCase'

import PageHeader from '@behavehealth/components/PageHeader'
import Tabs from '@behavehealth/components/Tabs'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import { isDefined, titleCase } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Alert from '@behavehealth/components/Alert'
import Icon from '@behavehealth/components/Icon'
import Button from '@behavehealth/components/Button'
import SummonOverlay from '@behavehealth/components/SummonOverlay'

import { REQUEST_TYPES } from '@behavehealth/constructs/Partnerships/PartnershipRequestOverlay'
import { REQUESTS_DATA_BY_STATUS } from '@behavehealth/constructs/Partnerships/mockData'
import PartnershipRequestStatus, { REQUEST_STATUSES } from '@behavehealth/constructs/Partnerships/PartnershipRequestStatus'

export const RootRequests: React.FC = () => {
  const match = useRouteMatch()
  const location = useLocation()

  return (
    <div css={STYLES.root}>
      <div className="bg-white sticky top-0 z-10 flex-0">
        <PageHeader
          title="Requests"
          icon="web_form"
          aside={
            <Button
              label="Send New Request"
              glyph="check_out"
              type="primary"
              link={{
                pathname: `${location.pathname}/new`,
                parent: match,
              }}
            />
          }
        />

        <Alert type="warning" className="!rounded-none">
          <div className="flex flex-nowrap items-center">
            <div className="text-[1.2rem] mr-2">🚧</div>
            <div>
              <h4>Feature Under Development</h4>
              <div>
                The Requests feature you're currently viewing is still in active development. While you can explore this page, please note
                that it's not yet functional.
              </div>
            </div>
          </div>
        </Alert>

        <Tabs>
          <Tabs.List className="px-4">
            <Tabs.Item label="Requests Sent" to={`${match.url}/sent`} />
            <Tabs.Item label="Requests Received" to={`${match.url}/received`} />
          </Tabs.List>
        </Tabs>
      </div>

      <div css={STYLES.content}>
        <Switch>
          <Route path={`${match.path}/sent`} component={RequestsPage} />
          <Route path={`${match.path}/received`} component={RequestsPage} />

          <Redirect exact from={match.url} to={`${match.url}/sent`} />
        </Switch>
      </div>
    </div>
  )
}

const RequestsPage = () => {
  const match = useRouteMatch()

  return (
    <div>
      <Tabs>
        <Tabs.List className="mb-4">
          <Tabs.Item label="All" to={`${match.url}/all`} />

          {Object.keys(REQUEST_STATUSES).map((statusKey) => (
            <Tabs.Item label={REQUEST_STATUSES[statusKey].label} to={`${match.url}/${kebabCase(statusKey)}`} />
          ))}
        </Tabs.List>
      </Tabs>

      <Switch>
        <Route path={`${match.path}/:status`} component={ReferralsSubpage} />

        <Redirect exact from={match.url} to={`${match.url}/${Object.keys(REQUEST_STATUSES)[0]}`} />
      </Switch>
    </div>
  )
}

const ReferralsSubpage = () => {
  const match = useRouteMatch()
  const location = useLocation()
  const { status }: any = useParams()

  const requestType = location.pathname?.includes?.('received') ? 'received' : 'sent'

  const columns = React.useMemo(
    () => [
      {
        title: 'Title',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          const config = REQUEST_TYPES?.[data.request_type] || null

          return <MainCell id={data.id} value={value} icon={config.icon} to={`${match.url}/${data.id}`} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        width: 120,
        formatValue: ({ value }: any) => {
          return <PartnershipRequestStatus status={value} />
        },
      },
      {
        title: 'Message',
        model: 'notes',
        width: 260,
      },
      {
        title: 'Due Date',
        model: 'due_at',
        type: 'date_time',
      },
      {
        title: 'Date Requested',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Requested By',
        model: 'author',
        type: 'profile',
      },
    ],
    [match.url],
  )

  const { data, meta } = React.useMemo(() => {
    const mockData = REQUESTS_DATA_BY_STATUS?.[requestType]?.[status]

    return {
      data: mockData,
      meta: {
        count: size(mockData),
        page: 1,
        prev: null,
        next: null,
        pages: 1,
      },
    }
  }, [status, requestType])

  return (
    <>
      <div>
        <DataTable key={status} asCard title={`${titleCase(status)} Requests`} icon="web_form" columns={columns} meta={meta} />
      </div>
    </>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    overflow: 'hidden',
  },

  content: {
    padding: '1rem',
    paddingTop: 0,
    overflowY: 'auto',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
  },
}

export const Requests = withPageError(RootRequests)
