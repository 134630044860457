import React from 'react'

import CardActions from './CardActions'
import Flex from './Flex'
import Glyph from './Glyph'

import { COLORS } from '../theme'
import { CARD_CLASSES, CARD_CSS_VARS } from './Card'

type Props = {
  actions?: React.ReactNode
  after?: React.ReactNode
  contentGap?: string
  contentStyles?: any
  graphic?: React.ReactNode
  minHeight?: any
  showChevron?: boolean
} & React.HTMLAttributes<any>

const CardHeader: React.FC<Props> = ({
  actions,
  after,
  children,
  className,
  contentGap,
  contentStyles,
  graphic,
  minHeight = '2.5rem',
  onClick,
  onDoubleClick,
  showChevron,
}) => {
  return (
    <div
      css={styles.root(!!after)}
      className={className}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      style={{ '--card-header-min-height': minHeight }}
    >
      <Flex nowrap stretchSelf centerY flex="100 1 auto">
        {graphic && (
          <div className={CARD_CLASSES.GRAPHIC} css={styles.graphic}>
            {graphic}
          </div>
        )}

        <div css={[styles.content(!!graphic, contentGap), contentStyles]} className={CARD_CLASSES.HEADER_CONTENT}>
          {children}
        </div>
      </Flex>

      {showChevron && !actions && <Glyph size={16} glyph="chevron_right" css={styles.chevron} className={CARD_CLASSES.CHEVRON} />}

      {actions && <CardActions actions={actions} />}

      {after && <div css={styles.after}>{after}</div>}
    </div>
  )
}

const styles = {
  root: (hasAfter: boolean): any => ({
    display: 'flex',
    flexWrap: hasAfter ? 'wrap' : 'nowrap',
    alignItems: 'center',
    flex: '1 1 auto',
    //height: '100%',
    // minHeight: 40,
    // boxShadow: HARD_SHADOW(3),
    position: 'relative',
    zIndex: 1,
  }),

  graphic: {
    position: 'relative',
    width: `var(${CARD_CSS_VARS.BASELINE})`,
    minHeight: 'var(--card-header-min-height)',
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
  },

  content: (graphic: boolean, gap?: string | number) => ({
    display: 'grid',
    gridGap: gap || '0.2rem',
    padding: `var(${CARD_CSS_VARS.PADDING_Y}) var(${CARD_CSS_VARS.PADDING_X})`,
    paddingLeft: graphic && 0,
    flex: '999 1 auto',
    transition: 'transform 1060ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  }),

  chevron: {
    flex: '0 0 auto',
    alignSelf: 'center',
    marginRight: `var(${CARD_CSS_VARS.PADDING_X})`,
    fill: COLORS.link,
  },

  after: {
    flex: '1 1 auto',
    padding: `var(${CARD_CSS_VARS.PADDING_Y}) var(${CARD_CSS_VARS.PADDING_X})`,
  },
}

export default CardHeader
