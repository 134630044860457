import React from 'react'

import Glyph from '../../Glyph'
import SummonOverlay from '../../SummonOverlay'

import Link from './Link'

const QuickViewOverlay: React.FC = ({ overlay, children }) => (
  <SummonOverlay overlay={overlay}>{children || <Link label="Quick View" glyph={<Glyph glyph="quick_view" />} />}</SummonOverlay>
)

export default QuickViewOverlay
