import React from 'react'
import size from 'lodash/size'

import { useGet, useUpdate } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardsGrid from '../../components/CardsGrid'
import Flex from '../../components/Flex'
import Icon from '../../components/Icon'
import Loader from '../../components/Loader'
import PageSection from '../../components/PageSection/PageSection'
import SummonOverlay from '../../components/SummonOverlay'

import AddPaymentMethodOverlay from './AddPaymentMethodOverlay'
import { PaymentMethodCard } from './PaymentMethodCard'
import { invalidateQueries, useDelete } from '../../hooks/useNewAPI'

const InternalBillingPaymentMethods = ({ record, buttonType }: any) => {
  const { data, isLoading }: any = useGet({
    name: ['internal_billing', 'payment_methods', record?.id],
    url: `/facilities/${record?.id}/global_payment_methods?category=internal_billing`,
  })

  const details = React.useMemo(() => {
    return {
      company_name: record.name,
      name: `${record.first_name} ${record.last_name}`,
      email: record.email,
      phone_no: record.phone_no,
    }
  }, [record])

  const tenant = React.useMemo(() => {
    return record.subdomain
  }, [record])

  const facilityId = React.useMemo(() => {
    return record.id
  }, [record])

  const stripeId = React.useMemo(() => {
    return record.stripe_customer_id
  }, [record])

  const name = React.useMemo(() => {
    return record.company_name
  }, [record])

  const recordId = React.useMemo(() => {
    return record.id
  }, [record])

  const recordType = React.useMemo(() => {
    return record.type
  }, [record])

  const actions = (
    <Flex gap={8}>
      <SummonOverlay
        overlay={
          <AddPaymentMethodOverlay
            category="internal_billing"
            tenant={tenant}
            name={name}
            facilityId={facilityId}
            serviceCustomerId={stripeId}
            recordId={recordId}
            recordType={recordType}
            data={details}
            onSuccess={() => {
              invalidateQueries(['internal_billing', 'payment_methods'])
            }}
          />
        }
      >
        <Button label="Add Payment Method" glyph="add" size={200} type={buttonType} />
      </SummonOverlay>
    </Flex>
  )

  const isEmpty = size(data) === 0
  const hasConfigurationError = !stripeId

  const { mutate: deletePaymentMethod, isLoading: isDeleting } = useDelete({
    name: ['internal_billing', 'payment_methods', record?.id, 'remove'],
    url: `/global_payment_methods/remove`,
    invalidate: ['internal_billing', 'payment_methods'],
  })

  if (hasConfigurationError) {
    return (
      <PageSection>
        <PageSection.Header
          graphic={
            <div className="flex items-center justify-center w-6 h-6">
              <Icon icon="billing" size={20} />
            </div>
          }
        >
          <PageSection.Title title="Payment Methods" />
        </PageSection.Header>

        <PageSection.Content>
          <Card className="px-8 py-6 flex flex-col justify-center items-center">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Icon icon="billing" size={22} />
                <div className="text-center uppercase text-[1.15em] tracking-[1px] font-[700] mt-2">Payment Methods</div>
                <div className="text-center text-text-muted mt-2 opacity-80">
                  There seems to be a misconfiguration with your Account. <br />
                  Please get in touch with us to resolve it before adding Payment Methods
                </div>
              </>
            )}
          </Card>
        </PageSection.Content>
      </PageSection>
    )
  }

  return (
    <PageSection>
      <PageSection.Header
        graphic={
          <div className="flex items-center justify-center w-6 h-6">
            <Icon icon="billing" size={20} />
          </div>
        }
        after={actions}
      >
        <PageSection.Title title="Payment Methods" />
      </PageSection.Header>

      <PageSection.Content>
        {isLoading || isEmpty ? (
          <Card className="px-8 py-6 flex flex-col justify-center items-center">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Icon icon="billing" size={22} />
                <div className="text-center uppercase text-[1.15em] tracking-[1px] font-[700] mt-2">Payment Methods</div>
                <div className="text-center text-text-muted mt-2 opacity-80">No payment method added yet</div>
              </>
            )}
          </Card>
        ) : (
          <CardsGrid key="cards-grid">
            {data?.map((paymentMethod: any) => (
              <PaymentMethodCard paymentMethod={paymentMethod} deletePaymentMethod={deletePaymentMethod} isDeleting={isDeleting} />
            ))}
          </CardsGrid>
        )}
      </PageSection.Content>
    </PageSection>
  )
}

export default InternalBillingPaymentMethods
