import React from 'react'
import { connect } from 'react-redux'

import Button from '../../Button'
import Chotomate from '../../Chotomate'
import ContextShow from '../../Forms/ContextShow'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'

import { address, titleCase } from '../../../utils/functions'
import { withOverlayError } from '../../../hocs/withOverlayError'

class ClientConnection extends OverlayBase {
  renderHeader = () => {
    const { $new } = this.state

    return <Overlay.Header icon="connections" title={$new ? 'Add Connection' : 'Connection'} />
  }

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { reference, record, loading } = this.props

    return (
      <Overlay.Content>
        <Chotomate ready name="care_team_overlay" />

        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
          linked={
            $new && {
              reference_id: reference?.id,
              reference_type: reference?.type,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              <RadioGroup
                model="category"
                label="Category"
                layout="vertical-dense"
                defaultValue="care_team"
                validations={{
                  presence: {
                    message: 'Please select a connection category',
                  },
                }}
              >
                <Radio label="Care Team" value="care_team" />
                <Radio label="Organization" value="organization" />
              </RadioGroup>

              <ContextShow when="category" is={'care_team'}>
                <RadioGroup
                  model="subcategory"
                  label="Relation Type"
                  layout="vertical-dense"
                  defaultValue="therapist"
                  validations={{
                    presence: {
                      message: 'Please select a connection category',
                    },
                  }}
                >
                  <Radio label="Primary Therapist" value="therapist" />
                  <Radio label="Psychiatrist" value="psychiatrist" />
                  <Radio label="Recovery Coach" value="recovery_coach" />
                  <Radio label="Primary Care Physician" value="care_physician" />
                  <Radio label="Nurse" value="nurse" />
                  <Radio label="Case Manager" value="case_manager" />
                  <Radio label="Interventionist" value="interventionist" />
                  <Radio label="Probation Officer" value="probation_officer" />
                  <Radio label="Peer Provider" value="peer_provider" />
                  <Radio label="Sponsor" value="sponsor" />
                </RadioGroup>

                <ObjectSelector
                  isPolymorphic
                  model="to"
                  label="Connection"
                  type="employees"
                  icon="employees"
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => titleCase(data.position)}
                  validations={{
                    presence: {
                      message: 'Please select a staff member',
                    },
                  }}
                />
              </ContextShow>

              <ContextShow when="category" is={'organization'}>
                <RadioGroup
                  model="subcategory"
                  label="Connection Type"
                  layout="vertical-dense"
                  defaultValue="treatment_center"
                  validations={{
                    presence: {
                      message: 'Please select a connection subcategory',
                    },
                  }}
                >
                  <Radio label="Treatment Center" value="treatment_center" />
                  <Radio label="Sober Living Home" value="sober_living_home" />
                  <Radio label="Employer" value="employer" />
                  <Radio label="School" value="school" />
                </RadioGroup>

                <ObjectSelector
                  isPolymorphic
                  model="to"
                  label="Connection"
                  type="organizations"
                  icon="organizations"
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => address(data.address)}
                  validations={{
                    presence: {
                      message: 'Please select an organization',
                    },
                  }}
                />
              </ContextShow>

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>
          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              glyph="check"
              label="Save"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="connections.edit"
            />
            {!$new && <Button glyph="cross" label="Cancel" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              label="Edit"
              glyph="edit"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="connections.create"
            />

            <DeleteDialog
              title="Delete Connection?"
              message="Are you sure you want to delete this connection? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                label="Delete"
                type="default"
                glyph="delete"
                color="red"
                isLoading={this.props.loading}
                fullWidth
                permission="connections.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'connections')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'connections')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(ClientConnection))
