export const DEFAULT_EMPTY_VALUE = '–'

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount'
export const DAEMON = '@@saga-injector/daemon'
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount'

export const PAGE_NAV_OPEN_THRESHOLD = 1024 // in px

export const DEMO_ACCOUNTS = ['test', 'outpatient', 'detox', 'startup', 'community', 'davidsrecovery', 'demo']

export const InsuranceCompanies = [
  {
    id: 1,
    name: 'UnitedHealth Group',
    model: 'cju2en4xnl3ro0a88dfwpllz9',
    avatar: 'https://media.graphcms.com/9A0NufQJRfaPkVnT1Low',
    category: 'insurance_payer',
  },
  {
    id: 2,
    name: 'Wellpoint Inc. Group',
    model: 'cju2eoddkl3uz0a88w7lqqevm',
    avatar: 'https://media.graphcms.com/gfDUTOhQ9yjU6pqe3xs8',
    category: 'insurance_payer',
  },
  {
    id: 3,
    name: 'Kaiser Foundation Group',
    model: 'cju2eq5tkl3z40a88katgcyr4',
    avatar: 'https://media.graphcms.com/9hPNWSSXKHHPU11F8yDg',
    category: 'insurance_payer',
  },
  {
    id: 4,
    name: 'Humana Group',
    model: 'cju2eqksul40p0a881pu9w7ll',
    avatar: 'https://media.graphcms.com/RLLYUhSSdG03sbPV3b99',
    category: 'insurance_payer',
  },
  {
    id: 5,
    name: 'Aetna Group',
    model: 'cju2es49hl44d0a88z7zt86k7',
    avatar: 'https://media.graphcms.com/pWIR069gQKm3uAIOELZq',
    category: 'insurance_payer',
  },
]
