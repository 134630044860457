import React from 'react'
import axios from 'axios'
import isFunction from 'lodash/isFunction'
import ReactAudioPlayer from 'react-audio-player'
import WebViewer from '@pdftron/pdfjs-express-viewer'

import Animated from './Animated'
import Overlay from './Overlay'
import Portal from './Portal'
import Flex from './Flex'
import { getToken } from '../modules/axios'
import useIsMounted from '../hooks/useIsMounted'

const findType = (contentType) => {
  if (!contentType) return null
  else if (contentType.includes('image/')) return 'image'
  else if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) return 'excel'
  else if (contentType.includes('application/vnd.ms-excel')) return 'excel'
  else if (contentType.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) return 'word'
  else if (contentType.includes('application/msword')) return 'word'
  else if (contentType.includes('application/pdf')) return 'pdf'
  else if (contentType.includes('application/')) return 'application'
  else if (contentType.includes('audio/x-m4a')) return 'audio'
  else if (contentType.includes('audio/mpeg')) return 'audio'
  else if (contentType.includes('audio/wav')) return 'audio'
}

const buildURL = (type, url) => {
  const encodedURL = encodeURIComponent(url)
  const urls = {
    word: `https://view.officeapps.live.com/op/view.aspx?src=${encodedURL}`,
    excel: `https://view.officeapps.live.com/op/view.aspx?src=${encodedURL}`,
    application: `https://docs.google.com/viewer?url=${encodedURL}&embedded=true`,
    pdf: url,
    image: encodedURL,
    audio: encodedURL,
  }

  return urls[type]
}

const ImageRender = ({ uri }) => (
  <Overlay.Content style={{ position: 'relative' }}>
    <Flex centerX centerY className="!h-full">
      <img src={uri} css={{ height: 'auto', width: '100%' }} alt="Menu" />
    </Flex>
  </Overlay.Content>
)

const AudioRender = ({ uri, givenTitle, record }) => {
  return (
    <>
      <Overlay.Header title={givenTitle} glyph="behave_health" />
      <Overlay.Content css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ReactAudioPlayer src={record.url} autoPlay controls />
      </Overlay.Content>
    </>
  )
}

const PDFRender = ({ uri, givenTitle }: any) => {
  const viewer = React.useRef(null)

  React.useEffect(() => {
    WebViewer(
      {
        licenseKey: process.env.BH_PDF_EXPRESS_KEY,
        path: '/webviewer/lib',
      },
      viewer.current,
    ).then((instance: any) => {
      instance.loadDocument(uri, {
        customHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Request-Method': '*',
          'Access-Control-Allow-Headers': '*',
        },
        extension: 'pdf',
      })
    })
  }, [])

  return (
    <>
      <Overlay.Header testKey={`${givenTitle}_view_overlay`} title={givenTitle || 'PDF Viewer'} glyph="behave_health" />
      <Overlay.Content style={{ position: 'relative' }}>
        <Flex centerX centerY className="!h-full">
          <div className="webviewer" ref={viewer} css={{ width: '100%', height: '100%' }}></div>
        </Flex>
      </Overlay.Content>
    </>
  )
}

const DefaultRender = ({ uri, givenTitle }) => (
  <>
    <Overlay.Header testKey={`${givenTitle}_view_overlay`} title={givenTitle} glyph="behave_health" />
    <Overlay.Content style={{ position: 'relative', padding: '1rem', overflow: 'hidden' }}>
      <iframe
        title="iframe"
        src={uri}
        frameborder="0"
        border="0"
        cellspacing="0"
        css={{
          borderStyle: 'none',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
      />
    </Overlay.Content>
  </>
)

const renderTypes = {
  image: ImageRender,
  audio: AudioRender,
  pdf: PDFRender,
  default: DefaultRender,
}

const QuickView = ({ record, url, title, contentType, children, width }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [uri, setURI] = React.useState(null)
  const token = React.useRef(null)
  const isMounted = useIsMounted()
  const type = findType(record?.content_type || contentType)
  const givenTitle = title || record?.title

  React.useEffect(() => {
    const checkToken = async () => {
      const tk = await getToken()
      if (tk) token.current = tk?.token
    }

    checkToken()
  }, [])

  const open = async () => {
    if (url) {
      setURI(buildURL(type, url))
      setIsOpen(true)
      return
    }

    if (uri) {
      setIsOpen(true)
      return
    }

    if (type === 'image') {
      setURI(record.url)
      setIsOpen(true)
    } else if (type === 'pdf') {
      if (record.get_signed) {
        const req = await axios.get(record.get_signed + `&token=${token.current}`)
        setURI(req.data)
        setIsOpen(true)
      } else {
        window.open(record.url, '_blank')
      }
    } else {
      if (record.get_signed) {
        const req = await axios.get(record.get_signed + `&token=${token.current}`)
        setURI(buildURL(type, req.data))
        setIsOpen(true)
      } else {
        window.open(record.url, '_blank')
      }
    }
  }

  const close = () => {
    if (!isMounted) return
    setIsOpen(false)
  }

  const render = (props) => {
    let QuickViewContent = renderTypes.default
    if (renderTypes[type]) QuickViewContent = renderTypes[type]

    return <QuickViewContent {...props} />
  }

  return (
    <>
      {isFunction(children) ? children({ open }) : <div onClick={open}>{children}</div>}

      <Portal type="iframe">
        <Animated show={isOpen} animation="animation">
          <Overlay fullheight showBackdrop stopPropagation closeOnBackdrop position="center" onClose={close} maxWidth={width}>
            {render({ uri, givenTitle, record })}
            {/* {type === 'image' ? (
              <Overlay.Content style={{ position: 'relative' }}>
                <Flex centerX centerY className="!h-full">
                  <img src={uri} css={{ height: 'auto', width: '100%' }} alt="Menu" />
                </Flex>
              </Overlay.Content>
            ) : (
              <>
                <Overlay.Header title={givenTitle} glyph="behave_health" />
                <Overlay.Content style={{ position: 'relative', padding: '1rem', overflow: 'hidden' }}>
                  <iframe
                    title="iframe"
                    src={uri}
                    frameborder="0"
                    border="0"
                    cellspacing="0"
                    css={{
                      borderStyle: 'none',
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                  />
                </Overlay.Content>
              </>
            )} */}
          </Overlay>
        </Animated>
      </Portal>
    </>
  )
}

QuickView.defaultProps = {
  width: 100,
}

export default QuickView
