import React from 'react'
import { tint } from 'polished'
import { keyframes } from '@emotion/react'
import size from 'lodash/size'

import { apiGet } from '../../modules/api'
import { COLORS } from '../../theme'
import { useAppSidebarStore } from '../../hooks/useAppSidebarStore'
import { useSettings } from '../../hooks/useSettings'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Loader from '../../components/Loader'
import NavGroup from '../../components/NavGroup'
import NavItem from '../../components/NavItem'

import { SearchResults } from './SearchResults'
import { useSearchStore } from './store'

import { getResource } from '../Resources/resources'
import { ResourceTag } from '../Resources/ResourceTag'

import { usePinsStore } from '../../components/AppSidebar/pinsStore'

type Props = {
  onClose: Function
}

export const SearchWidget: React.FC<Props> = ({ onClose }) => {
  const searchInput = useSearchStore((state: any) => state.searchInput)
  const setSearchInput = useSearchStore((state: any) => state.setSearchInput)

  const activeResult = useSearchStore((state: any) => state.activeResult)
  const setActiveResult = useSearchStore((state: any) => state.setActiveResult)

  const [results, setResults] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const getSearchResults = async (input) => {
      try {
        setLoading(true)

        const result = await apiGet({
          url: `/search_v2?q=${encodeURIComponent(input)}`,
        })

        // update results
        setResults(result.data.data)
        setLoading(false)

        if (size(result.data.data) >= 1) {
          setActiveResult(result.data.data[0].document)
        } else {
          setActiveResult({})
        }
      } catch (error) {
        setLoading(false)
      }
    }

    if (searchInput.length >= 2) {
      getSearchResults(searchInput)
    } else {
      setResults([])
      setActiveResult({})
    }
  }, [searchInput])

  return (
    <div css={styles.root}>
      <div css={styles.searchColumn}>
        <div css={styles.inputWrapper}>
          <div css={styles.inputGraphic}>{loading ? <Loader color={COLORS.blue} size={20} /> : <Icon icon="search" size={20} />}</div>
          <Input
            autoFocus
            debounce={0}
            placeholder="Search…"
            onUpdate={({ value }) => setSearchInput(value)}
            defaultValue={searchInput}
            css={styles.input}
          />
        </div>

        <Grid className="!p-4">
          <NavItem isActive glyph="menu" label="All Results" />

          <NavGroup label="People">
            <NavItem skipActive icon="clients" label="Clients" />
            <NavItem skipActive icon="admissions" label="Applicants" />
            <NavItem skipActive icon="employees" label="Staff" />
            <NavItem skipActive icon="contacts" label="Contacts" />
          </NavGroup>

          <NavGroup label="Places">
            <NavItem skipActive icon="locations" label="Locations" />
            <NavItem skipActive icon="organizations" label="Organizations" />
          </NavGroup>
        </Grid>
      </div>

      <SearchResults results={results} isLoading={loading} />

      {activeResult && (
        <div css={styles.activeResultColumn}>
          <ResultMain data={activeResult} />
        </div>
      )}
    </div>
  )
}

const ResultMain = ({ data }) => {
  const { tenant } = useSettings()
  const resource = getResource(data.type, data.status)

  const pins = usePinsStore((state: any) => state.pins)
  const togglePin = usePinsStore((state: any) => state.togglePin)
  const openAppSidebar = useAppSidebarStore((state: any) => state.open)

  if (!resource || size(data) === 0) return null

  const ResourceNav = resource.nav

  const tenantPins = pins[tenant.subdomain] || {}
  const pinID = `${data.type}-${data.document_id}`
  const isPinned = !!tenantPins[pinID]

  const handlePinClick = (event: any) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    openAppSidebar('pins')
    togglePin(tenant.subdomain, pinID, data)
  }

  return (
    <>
      <Grid
        gap="0.5rem"
        css={{
          background: COLORS.white,
          position: 'sticky',
          top: 0,
          borderBottom: `1px solid ${COLORS.divider}`,
          padding: '1rem',
          zIndex: 1,
        }}
      >
        <Flex centerY justifyContent="space-between">
          <ResourceTag data={data} />

          <Button
            rounded
            label={isPinned ? 'Unpin' : 'Pin'}
            icon="pins"
            size={200}
            onClick={handlePinClick}
            css={styles.pinButton}
            className={isPinned ? 'is-pinned' : ''}
          />
        </Flex>

        <Grid fixChildrenX gap="0.5rem" columns="4rem 1fr">
          <Avatar src="" initials={data.name} size="4rem" />

          <div>
            <h3 className="!m-0">{data.name}</h3>
            <p css={{ margin: 0, color: COLORS.textMuted }}>#{data.behave_id}</p>
          </div>
        </Grid>
      </Grid>

      <div className="!p-4">{ResourceNav && <ResourceNav id={data.document_id} />}</div>
    </>
  )
}

const notFoundMountAnimation = keyframes`
  0% {
    opacity: 0.5;
    transform: scale(.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const styles = {
  root: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '300px 1fr 400px',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',

    '--header-height': '42px',
  },

  searchColumn: {
    backgroundColor: COLORS.white,
    borderRight: `1px solid ${COLORS.divider}`,
  },

  activeResultColumn: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  inputWrapper: {
    position: 'relative',
    borderBottom: `1px solid ${COLORS.divider}`,
    paddingLeft: '1rem',
  },

  inputGraphic: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 3,
  },

  input: {
    width: '100%',
    fontSize: '1.1rem',

    outline: 'none',
    border: 'none !important',
    boxShadow: 'none !important',
    background: 'white !important',

    input: {
      padding: '0',
      paddingLeft: '2.5rem',
    },

    '&::placeholder': {
      color: COLORS.textMuted,
      fontStyle: 'normal',
    },
  },

  subtitle: {
    fontSize: '.9rem',
    textTransform: 'uppercase',
    color: COLORS.textMuted,
    marginBottom: '1rem',
  },

  stats: {
    fontSize: '0.85rem',

    span: {
      textTransform: 'uppercase',
      fontWeight: 600,
      color: COLORS.gray,
    },
  },

  pinButton: {
    color: `${COLORS.text} !important`,

    img: {
      width: '1rem',
      height: '1rem',
    },

    '&.is-pinned': {
      backgroundColor: `${tint(0.85, COLORS.violet)} !important`,
      boxShadow: `0 0 0 1px ${tint(0.2, COLORS.violet)}, 0 0 0 4px ${tint(0.6, COLORS.violet)}`,
    },
  },
}
