import { INPUT_STYLES as DEFAULT_INPUT_STYLES, INPUT_FOCUS_STYLES } from '../../theme'

export const INPUT_STYLES = {
  ...DEFAULT_INPUT_STYLES,
  outline: 'none',
  minHeight: 'var(--filter-height, 1.5rem)',
  height: 'var(--filter-height, 1.5rem)',
  padding: '0 0.5rem',
  fontSize: '0.85rem',

  '&.is-focused, &:focus': INPUT_FOCUS_STYLES,
}
