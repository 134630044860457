import React from 'react'

import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import Diagnoses from '../../../components/SmartContent/Diagnoses'
import Grid from '../../../components/Grid'
import Input from '../../../components/Forms/Input'
import Label from '../../../components/Label'
import Medications from '../../../components/SmartContent/Medications'
import Textarea from '../../../components/Forms/Textarea'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../../components/Forms/elements/YesNoRadioGroupWithTextarea'

import {
  CIWA_SYMPTOMS_FIELDS,
  COWS_SYMPTOMS_FIELDS,
  MEDICAL_SYSTEMS_REVIEW_FIELDS,
  MENTAL_STATUS_EXAM_FIELDS,
  PHYSICAL_EXAM_FIELDS,
  SUBSTANCE_USE_HISTORY,
  VITAL_SIGNS_FIELDS,
} from './common'

export const HISTORY_AND_PHYSICAL_FOLLOW_UP_SECTIONS = [
  {
    title: 'Current Medications',
    model: 'medications',
    fields: [
      {
        label: 'Medications List',
        model: 'medications_list',
        component: ({ isEditable, client }: any) => (
          <Medications
            model="data.medications.meds"
            isEditable={isEditable}
            client={client}
            icon="medications"
            title="Medications"
            emptyDescription="No medications added yet"
          />
        ),
      },
    ],
  },
  {
    title: 'Medical Systems Review',
    model: 'medical_systems_review',
    fields: MEDICAL_SYSTEMS_REVIEW_FIELDS,
  },
  {
    title: 'Mental Status Exam',
    model: 'mental_status_exam',
    fields: MENTAL_STATUS_EXAM_FIELDS,
  },
  {
    title: 'Vital Signs',
    model: 'vital_signs',
    fields: VITAL_SIGNS_FIELDS,
  },
  {
    title: 'Follow-Up Plan',
    model: 'follow_up_plan',
    fields: [
      {
        label: 'Follow-Up Plan',
        model: 'follow_up_plan',
        component: () => <Textarea useQuickText model="data.follow_up_plan.description" />,
      },
    ],
  },
  {
    title: 'Diagnoses',
    model: 'diagnoses',
    fields: [
      {
        label: 'Diagnoses',
        model: 'diagnoses',
        component: ({ isEditable, client }: any) => (
          <Diagnoses
            model="data.diagnoses.diagnoses"
            isEditable={isEditable}
            client={client}
            icon="diagnosis"
            title="Diagnoses"
            emptyDescription="No diagnoses added yet"
          />
        ),
      },
    ],
  },
]
