import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { COLORS } from '@behavehealth/theme'
import { mapToArray, titleCase, beautifulAmount, usDate } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import { Button, Card, Flex, Grid, Tabs, TreeItem, PageLoader, State } from '@behavehealth/components'

import ClaimInternalStatus from '@behavehealth/components/Statuses/ClaimInternalStatus'

import { ClaimNotes } from '@behavehealth/constructs/Insurance/components/ClaimNotes'
import { ClaimTasks } from '@behavehealth/constructs/Insurance/components/ClaimTasks'
import { ElectronicStatuses } from '@behavehealth/constructs/Insurance/components/ElectronicStatuses'

import ClaimInfo from '@behavehealth/constructs/Insurance/components/ClaimInfo'

const ClaimStatus = () => {
  const match: any = useRouteMatch()
  const clientID = match.params?.resource_id

  const { data, isLoading }: any = useGet({
    name: ['client', clientID, 'insurance-claims'],
    url: `/residents/${clientID}/insurance_claims`,
  })

  const isEmpty = size(data) === 0

  if (isLoading) {
    return (
      <Card>
        <PageLoader />
      </Card>
    )
  }

  return (
    <div className="grid grid-cols-[100%]">
      <Card>
        <State icon="claims" isEmpty={isEmpty} title="Claims" emptyDescription="No claims added yet">
          {mapToArray(data).map((claim: any) => (
            <TreeItem
              css={styles.treeItem}
              title={
                <Grid gap="0.25rem">
                  <Flex centerY gap="0.75rem">
                    <div css={styles.title}>{claim.control_number}</div>
                    <ClaimInternalStatus status={claim.status} />
                  </Flex>

                  <div css={styles.subtitle}>
                    <span>
                      <b>Dates of Service:</b>{' '}
                      <span>
                        {usDate(claim.covering_from)} - {usDate(claim.covering_until)},{' '}
                      </span>
                    </span>
                    <span>
                      <b>Balance:</b> <span>{beautifulAmount(claim.total_amount)}, </span>
                    </span>
                    <span>
                      <b>Claim Type:</b> <span>{titleCase(claim.category)}, </span>
                    </span>
                    <span>
                      <b>Services:</b> <span>{size(claim.insurance_claim_service_lines)}, </span>
                    </span>
                    <span>
                      <b>Total Amount Billed:</b> <span>{beautifulAmount(claim.total_amount)}, </span>
                    </span>
                    <span>
                      <b>Last Billed:</b> <span>{usDate(claim.last_submission_at)}, </span>
                    </span>
                  </div>
                </Grid>
              }
            >
              <div css={styles.treeItemContent}>
                <Tabs defaultTab="claim_info">
                  <Tabs.List css={styles.tabsList}>
                    <Tabs.Item label="Claim Info" icon="general_info" name="claim_info" />
                    <Tabs.Item label="Tasks & Notes" icon="tasks" name="tasks_notes" />
                    <Tabs.Item label="Electronic Statuses" icon="intake_forms" name="electronic_status" />
                  </Tabs.List>

                  <Tabs.Panels>
                    <Tabs.Panel name="claim_info">
                      <Grid gap="0.5rem">
                        <ClaimInfo clientID={clientID} claim={claim} />

                        <div>
                          <Button
                            label="View Full Claim →"
                            type="default"
                            size={200}
                            display="inline-flex"
                            link={`/clients/${clientID}/rcm/billing/${claim.id}`}
                          />
                        </div>
                      </Grid>
                    </Tabs.Panel>

                    <Tabs.Panel name="tasks_notes" type="mount">
                      <Grid gap="0.75rem">
                        <ClaimNotes
                          claimID={claim.id}
                          to={(id: string) => ({
                            pathname: `${match.url}/${claim.id}/notes/${id}`,
                            parent: match,
                          })}
                          titleAfter={
                            <Button
                              label="Add Claim Note"
                              glyph="add"
                              type="primary"
                              size={100}
                              link={{
                                pathname: `${match.url}/${claim.id}/notes/new`,
                                parent: match,
                              }}
                            />
                          }
                        />

                        <ClaimTasks
                          claimID={claim.id}
                          to={(id: string) => ({
                            pathname: `${match.url}/${claim.id}/tasks/${id}`,
                            parent: match,
                          })}
                          titleAfter={
                            <Button
                              label="Add Claim Task"
                              glyph="add"
                              type="primary"
                              size={100}
                              link={{
                                pathname: `${match.url}/${claim.id}/tasks/new`,
                                parent: match,
                              }}
                            />
                          }
                        />
                      </Grid>
                    </Tabs.Panel>

                    <Tabs.Panel name="electronic_status" type="mount">
                      <Card>
                        <ElectronicStatuses claim={claim} />
                      </Card>
                    </Tabs.Panel>
                  </Tabs.Panels>
                </Tabs>
              </div>
            </TreeItem>
          ))}
        </State>
      </Card>
    </div>
  )
}

const styles = {
  treeItem: {
    borderBottom: `1px solid ${COLORS.divider}`,

    '.children': {
      padding: '0 !important',
    },

    '&.is-open > button': {
      borderBottom: `1px solid ${COLORS.divider}`,
    },
  },

  treeItemContent: {
    padding: '1rem',
    paddingTop: 0,
    background: '#F7F8FB',
  },

  tabsList: {
    marginBottom: '1rem',
  },

  tableIcon: {
    marginLeft: '0.5rem !important',
    marginRight: '-0.25rem !important',
  },

  title: {
    fontSize: '1rem',
  },

  subtitle: {
    fontWeight: 400,
    fontSize: '0.95rem',
    color: COLORS.textMuted,
    b: { fontWeight: 600 },
  },
}

export default ClaimStatus
