import React from 'react'

import { amount, usDate } from '../../../../../utils/functions'
import withSettings from '../../../../../hocs/withSettings'

import Status from '../../../../Status'
import Table from '../../../../Table/Table'
import TableCell from '../../../../Table/TableCell'
import TableFilter from '../../../../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 200,
    isSticky: true,
    canToggleVisible: false,
    id: 'client',
    Header: 'Client',
    accessor: 'reference',
    Cell: ({ value }: any) => <TableCell.MainLink to={to(value)} label={value.name} avatar={value.avatar} />,
  },
  {
    width: 250,
    id: 'name',
    Header: 'Name',
    accessor: 'name',
  },
  {
    width: 100,
    accessor: 'amount',
    Header: 'Amount',
    Cell: ({ cell }: any) => <Status color="green" label={`+$${amount(cell.value)}`} />,
  },
  {
    accessor: 'transactioned_at',
    Header: 'Refund Date',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    accessor: 'notes',
    Header: 'Notes',
  },
  {
    id: 'author',
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  timezone: string
  to?: Function
}

const WriteOffsTable = ({ to, hiddenColumns, data, isLoading, localStorageKey, timezone }: Props) => (
  <Table
    title="Write Off's"
    icon="financials"
    data={data}
    hiddenColumns={hiddenColumns}
    columns={columns(to, timezone)}
    isLoading={isLoading}
    emptyDescription="No write-off's added yet"
    localStorageKey={localStorageKey}
  />
)

WriteOffsTable.defaultProps = {
  localStorageKey: 'write-offs',
}

export default withSettings(WriteOffsTable)
