import React from 'react'
import { DateTime } from 'luxon'
import size from 'lodash/size'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useGet, useDelete } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import SummonOverlay from '../../components/SummonOverlay'
import Icon from '../../components/Icon'
import PageSection from '../../components/PageSection/PageSection'

import { CommunityStaffEditorOverlay } from './CommunityStaffEditorOverlay'
import { countWord } from '../../utils/functions'
import { useCommunityEditor } from './useCommunityEditor'
import { useCommunityPermissions } from './useCommunityPermissions'

const RootCommunityProfileStaffDataTable: React.FC<any> = (props: any) => {
  const { entityId } = props

  const { canEditCommunityProfiles } = useCommunityPermissions()

  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  const { data, isLoading }: any = useGet({
    name: ['community', entityId, 'community-employees'],
    url: `/community/entities/${entityId}/entity_staff`,
  })

  const { mutateAsync: deleteTeamMember } = useDelete({
    name: ['delete-community-employee'],
    url: `/community/entity_staff`,
    invalidate: ['community', entityId, 'community-employees'],
    onSuccess: () => {
      setUpdatedAt(DateTime.now().toMillis())
    },
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 200,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <SummonOverlay overlay={<CommunityStaffEditorOverlay dataID={data.id} initialData={data} entityId={entityId} />}>
            <MainCell id={data.id} value={data.name} />
          </SummonOverlay>
        ),
      },
      {
        title: 'Position',
        model: 'position',
      },
      {
        title: 'Phone No',
        model: 'phone_no',
      },
      {
        title: 'Email',
        model: 'email',
      },
      {
        title: 'Bio',
        model: 'description',
      },
      {
        title: 'Actions',
        id: 'actions',
        flexChildren: true,
        disableSort: true,
        width: 100,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          if (!canEditCommunityProfiles) return null

          return (
            <DeleteDialog
              title="Delete Team Member?"
              message="Are you sure you want to delete this Team Member?"
              onYes={async () => await deleteTeamMember(data.id)}
            >
              <Button glyph="delete" label="Delete" type="minimal" color="red" size={100} />
            </DeleteDialog>
          )
        },
      },
    ]
  }, [canEditCommunityProfiles])

  return (
    <div className="grid grid-cols-[100%]">
      <PageSection>
        <PageSection.Header
          graphic={<Icon icon="employees" />}
          after={
            canEditCommunityProfiles && (
              <SummonOverlay overlay={<CommunityStaffEditorOverlay entityId={entityId} dataID="new" />}>
                <Button label="Add New Team Member" glyph="add" size={200} type="primary" display="inline-flex" />
              </SummonOverlay>
            )
          }
        >
          <PageSection.Title title={size(data) ? countWord('Team Members', size(data)) : 'Team Members'} />
        </PageSection.Header>
      </PageSection>

      <DataTable
        asCard
        data={data}
        isLoading={isLoading}
        title="Team Members"
        icon="employees"
        columns={columns}
        mainHeaderHidden
        className="mt-4"
      />
    </div>
  )
}

export const CommunityProfileStaffDataTable = withOverlayError(RootCommunityProfileStaffDataTable)
