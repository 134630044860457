export const FACILITY_INFORMATION = {
  operatedBy: {
    name: 'Operated By',
    items: {
      Private_for_profit_organization: 'A private for-profit organization',
      Private_non_profit_organization: 'A private non-profit organization',
      State_government: 'State government',
      Local_county_or_community_government: 'Local, county, or community government',
      Tribal_government: 'Tribal government',
      Federal_government: 'Federal Government',
    },
  },
  federalGovernmentAgency: {
    name: 'Federal Government Agency',
    items: {
      Department_of_veterans_affairs: 'Department of Veterans Affairs',
      Department_of_defense: 'Department of Defense',
      Indian_health_service: 'Indian Health Service',
    },
  },
  serviceSettings: {
    name: 'Service Settings',
    items: {
      Hospital_inpatient: 'Hospital Inpatient',
      Residential: 'Residential (Non-hospital)',
      Outpatient: 'Outpatient (PHP, IOP, OP)',
      Housing: 'Housing (Sober Living, Halfway House, Transitional Housing etc.)',
    },
  },
  hospitalInpatientLevelsOfCare: {
    name: 'Hospital Inpatient Levels of Care',
    items: {
      Hospital_inpatient_detoxification: 'Hospital Inpatient Detoxification',
      Hospital_inpatient_treatment: 'Hospital Inpatient Treatment',
    },
  },
  residentialLevelsOfCare: {
    name: 'Residential Levels of Care',
    items: {
      Residential_detoxification: 'Residential Detoxification',
      Residential_short_term_treatment: 'Residential Short-Term Treatment',
      Residential_long_term_treatment: 'Residential Long-Term Treatment',
    },
  },
  outpatientLevelsOfCare: {
    name: 'Outpatient Levels of Care',
    items: {
      Outpatient_detoxification: 'Outpatient Detoxification',
      Outpatient_day_treatment: 'Outpatient Day Treatment or Partial Hospitalization',
      Intensive_outpatient_treatment: 'Intensive Outpatient Treatment (IOP)',
      Regular_outpatient_treatment: 'Regular Outpatient Treatment (OP)',
      Medication_assisted_treatment: 'Medication-Assisted Treatment (MAT)',
    },
  },
  housingLevelsOfSupport: {
    name: 'Housing Levels Of Support',
    items: {
      Level_4: 'Level 4',
      Level_3: 'Level 3',
      Level_2: 'Level 2',
      Level_1: 'Level 1',
    },
  },
  licensureStatus: {
    name: 'Licensure Status',
    items: {
      Active: 'Licensed and actively operating',
      Inactive: 'Licensed but not actively operating',
      Pending: 'Licensing in process',
      None: 'Not licensed yet, but we would like help',
    },
  },
  licensureAuthority: {
    name: 'Licensure Authority',
    items: {
      State_substance_abuse_agency: 'State substance abuse agency',
      State_mental_health_department: 'State mental health department',
      State_department_of_health: 'State department of health',
      Hospital_licensing_authority: 'Hospital licensing authority',
    },
  },
  accreditationStatus: {
    name: 'Accreditation Status',
    items: {
      Active: 'Currently accredited',
      Pending: 'Accreditation in progress',
      Seeking_help: 'No accreditation yet, but we would like help',
      None: 'No accreditation and not currently interested in pursuing accreditation',
    },
  },
  accreditationOrganizations: {
    name: 'Accreditation Organizations',
    items: {
      JCAHO: 'The Joint Commission (JCAHO)',
      CARF: 'Commission on Accreditation of Rehabilitation Facilities (CARF)',
      NCQA: 'National Committee for Quality Assurance (NCQA)',
      COA: 'Council on Accreditation (COA)',
      HFAP: 'Healthcare Facilities Accreditation Program (HFAP)',
    },
  },

  //-----------------------------
  // Clinical & Medical Services
  //-----------------------------

  primaryFocus: {
    name: 'Primary Focus',
    items: {
      Substance_abuse_treatment_services: 'Substance abuse treatment services',
      Mental_health_services: 'Mental health services',
      Mental_health_and_substance_abuse_treatment_services:
        'Mix of mental health and substance abuse treatment services (neither is primary)',
      General_health_care: 'General health care',
    },
  },
  substanceAbuseServices: {
    name: 'Substance Abuse Services',
    items: {
      Intake_assessment_or_referral: 'Intake, assessment, or referral',
      Detoxification: 'Detoxification',
      Substance_abuse_treatment:
        'Substance abuse treatment (services that focus on initiating and maintaining an individual’s recovery from substance abuse and on averting relapse)',
      Serious_mental_illness_emotional_disturbance_substance_use:
        'Treatment for co-occurring serious mental illness (SMI) / serious emotional disturbance (SED) and substance use disorders',
      Any_other_substance_abuse_services: 'Any other substance abuse services',
    },
  },
  mentalHealthServicesClientType: {
    name: 'Mental Health Services Client Type',
    items: {
      Substance_abuse_clients: 'Substance abuse clients',
      All_except_substance_abuse_clients: 'Clients other than substance abuse clients',
      None: 'No clients are offered mental health treatment services',
    },
  },
  detoxifiedSubstances: {
    name: 'Substances Detoxification',
    items: {
      Alcohol: 'Alcohol',
      Benzodiazepines: 'Benzodiazepines',
      Cocaine: 'Cocaine',
      Methamphetamines: 'Methamphetamines',
      Opioids: 'Opioids',
    },
  },
  assessmentPreTreatmentServices: {
    name: 'Assessment and Pre-Treatment Services',
    items: {
      Screening_for_substance_abuse: 'Screening for substance abuse',
      Screening_for_mental_disorders: 'Screening for mental disorders',
      Comprehensive_substance_abuse_assessment_or_diagnosis:
        'Comprehensive substance abuse assessment or diagnosis',
      Comprehensive_mental_health_assessment_or_diagnosis:
        'Comprehensive mental health assessment or diagnosis (for example, psychological or psychiatric evaluation and testing)',
      Screening_for_tobacco_use: 'Screening for tobacco use',
      Outreach_to_persons_in_the_community_who_may_need_treatment:
        'Outreach to persons in the community who may need treatment',
      Interim_services_for_clients_when_immediate_admission_is_not_possible:
        'Interim services for clients when immediate admission is not possible',
      Professional_interventionist_educational_consultant:
        'Professional interventionist/educational consultant',
      None: 'We do not offer any of these assessment and pre-treatment services',
    },
  },
  testing: {
    name: 'Testing',
    items: {
      Drug_and_alcohol_oral_fluid_testing: 'Drug and alcohol oral fluid testing',
      Breathalyzer_or_other_blood_alcohol_testing: 'Breathalyzer or other blood alcohol testing',
      Drug_or_alcohol_urine_screening: 'Drug or alcohol urine screening',
      Testing_for_hepatitis_b_hbv: 'Testing for Hepatitis B (HBV)',
      Testing_for_hepatitis_c_hcv: 'Testing for Hepatitis C (HCV)',
      Hiv_testing: 'HIV testing',
      Std_testing: 'STD testing',
      Tb_screening: 'TB screening',
      Testing_for_metabolic_syndrome: 'Testing for metabolic syndrome',
      None: 'We do not offer any of these testing services',
    },
  },
  medicalServices: {
    name: 'Medical Services',
    items: {
      Hepatitis_a_hav_vaccination: 'Hepatitis A (HAV) vaccination',
      Hepatitis_b_hbv_vaccination: 'Hepatitis B (HBV) vaccination',
      None: 'We do not offer any of these medical services',
    },
  },
  transitionalServices: {
    name: 'Transitional Services',
    items: {
      Discharge_planning: 'Discharge planning',
      Aftercare_continuing_care: 'Aftercare/continuing care',
      Naloxone_and_overdose_education: 'Naloxone and overdose education',
      Outcome_follow_up_after_discharge: 'Outcome follow-up after discharge',
      None: 'We do not offer any of these transitional services',
    },
  },
  recoverySupportServices: {
    name: 'Recovery Support Services',
    items: {
      Mentoring_peer_support: 'Mentoring/peer support',
      Self_help_groups: 'Self-help groups (for example, AA, NA, SMART Recovery)',
      Assistance_in_locating_housing_for_clients: 'Assistance in locating housing for clients',
      Employment_counseling_or_training_for_clients:
        'Employment counseling or training for clients',
      Assistance_with_obtaining_social_services:
        'Assistance with obtaining social services (for example, Medicaid, WIC, SSI, SSDI)',
      Recovery_coach: 'Recovery coach',
      None: 'We do not offer any of these recovery support services',
    },
  },
  educationCounselingServices: {
    name: 'Education and Counseling Services',
    items: {
      Hiv_or_aids_education_counseling_or_support: 'HIV or AIDS education, counseling, or support',
      Hepatitis_education_counseling_or_support: 'Hepatitis education, counseling, or support',
      Health_education_other_than_hiv_aids_or_hepatitis:
        'Health education other than HIV/AIDS or Hepatitis',
      Substance_abuse_education: 'Substance abuse education',
      Smoking_tobacco_cessation_counseling: 'Smoking/tobacco cessation counseling',
      Individual_counseling: 'Individual counseling',
      Group_counseling: 'Group counseling',
      Family_counseling: 'Family counseling',
      Marital_couples_counseling: 'Marital/couples counseling',
      Vocational_training_or_educational_support:
        'Vocational training or educational support (for example, high school coursework, GED preparation, etc.)',
      None: 'We do not offer any of these education and counseling services',
    },
  },
  clinicalTherapeuticApproaches: {
    name: 'Clinical / Therapeutic Approaches',
    items: {
      Substance_abuse_counseling: 'Substance abuse counseling',
      Twelve_step_facilitation: '12-step facilitation',
      Brief_intervention: 'Brief intervention',
      Cognitive_behavioral_therapy: 'Cognitive behavioral therapy',
      Dialectical_behavior_therapy: 'Dialectical behavior therapy',
      Contingency_management_motivational_incentives:
        'Contingency management/motivational incentives',
      Motivational_interviewing: 'Motivational interviewing',
      Trauma_related_counseling: 'Trauma-related counseling',
      Anger_management: 'Anger management',
      Matrix_model: 'Matrix model',
      Community_reinforcement_plus_vouchers: 'Community reinforcement plus vouchers',
      Rational_emotive_behavioral_therapy: 'Rational emotive behavioral therapy (REBT)',
      Relapse_prevention: 'Relapse prevention',
      Telemedicine_telehealth:
        'Telemedicine/telehealth (including Internet, Web, mobile, and desktop programs)',
      None: 'We do not use any of these clinical/therapeutic approaches',
    },
  },
  ancillaryServices: {
    name: 'Ancillary Services',
    items: {
      Case_management_services: 'Case management services',
      Social_skills_development: 'Social skills development',
      Child_care_for_clients_children: 'Child care for clients’ children',
      Domestic_violence_family_or_partner_violence_services:
        'Domestic violence — family or partner violence services (physical, sexual, and emotional abuse)',
      Early_intervention_for_hiv: 'Early intervention for HIV',
      Transportation_assistance_to_treatment: 'Transportation assistance to treatment',
      Mental_health_services: 'Mental health services',
      Acupuncture: 'Acupuncture',
      Residential_beds_for_clients_children: 'Residential beds for clients’ children',
      None: 'We do not offer any of these ancillary services',
    },
  },
  otherServices: {
    name: 'Other Services',
    items: {
      Treatment_for_gambling_disorder: 'Treatment for gambling disorder',
      Treatment_for_internet_use_disorder: 'Treatment for Internet use disorder',
      Treatment_for_other_addiction_disorder:
        'Treatment for other addiction disorder (non-substance abuse)',
      None: 'We do not offer any of these other services',
    },
  },
  pharmacotherapies: {
    name: 'Pharmacotherapies',
    items: {
      Disulfiram: 'Disulfiram (Antabuse®)',
      Naltrexone_oral: 'Naltrexone (oral)',
      Naltrexone_injectable: 'Naltrexone (extended-release, injectable, for example, Vivitrol®)',
      Acamprosate: 'Acamprosate (Campral®)',
      Nicotine_replacement: 'Nicotine replacement',
      Non_nicotine_smoking_tobacco_cessation_medications:
        'Non-nicotine smoking/tobacco cessation medications (for example, bupropion, varenicline)',
      Medications_for_psychiatric_disorders: 'Medications for psychiatric disorders',
      Methadone: 'Methadone',
      Buprenorphine_with_naloxone:
        'Buprenorphine with naloxone (for example, Suboxone®, Bunavail®, Zubsolv®, Cassipa®)',
      Buprenorphine_without_naloxone: 'Buprenorphine without naloxone',
      Buprenorphine_sub_dermal_implant: 'Buprenorphine sub-dermal implant (Probuphine®)',
      Buprenorphine: 'Buprenorphine (extended-release, injectable, for example, Sublocade®)',
      Medications_for_HIV_treatment:
        'Medications for HIV treatment (for example, antiretroviral medications such as tenofovir, efavirenz, emtricitabine, atazanavir, and lamivudine)',
      Medications_for_Hepatitis_C_HCV_treatment:
        'Medications for Hepatitis C (HCV) treatment (for example, sofosbuvir, ledipasvir, interferon, peginterferon, ribavirin)',
      Lofexidine: 'Lofexidine',
      Clonidine: 'Clonidine',
      None: 'We do not offer any of these pharmacotherapy services',
    },
  },
  additionalTherapeuticPathways: {
    name: 'Additional Therapeutic Pathways',
    items: {
      Chiropractic_care: 'Chiropractic Care',
      Physical_therapy: 'Physical Therapy',
      Aquatic_therapy: 'Aquatic Therapy',
      Art_therapy: 'Art Therapy',
      Garden_therapy: 'Garden Therapy',
      Meditation: 'Meditation',
      Music_therapy: 'Music Therapy',
      Surf_therapy: 'Surf Therapy',
      Occupational_therapy: 'Occupational Therapy',
      Massage_therapy: 'Massage Therapy',
      Equine_therapy: 'Equine Therapy',
      Canine_therapy: 'Canine Therapy',
      Other_animal_therapy: 'Other Animal Therapy',
    },
  },
  programLengthsAvailable: {
    name: 'Program Lengths Available',
    items: {
      Two_week: '2-Week Program',
      Thirty_day: '30-Day Program',
      Sixty_day: '60-Day Program',
      Ninety_day: '90-Day Program',
      Six_month: '6-Month Program',
      Nine_month: '9-Month Program',
      One_year: '1-Year Program',
      None: 'No defined program length',
    },
  },
  faithBasedProgramming: {
    name: 'Faith Based and Other Programs',
    items: {
      Not_applicable: 'Does not apply',
      Agnostic_atheist: 'Agnostic / Atheist',
      Assemblies_of_god: 'Assemblies of God',
      Baptist: 'Baptist',
      Buddhist: 'Buddhist',
      Catholic: 'Catholic',
      Christian: 'Christian',
      Church_of_god: 'Church of God',
      Churches_of_christ: 'Churches of Christ',
      Episcopal_anglican: 'Episcopal / Anglican',
      Evangelical: 'Evangelical',
      Hinduism: 'Hinduism',
      Islam: 'Islam',
      Jehovahs_witness: "Jehovah's Witness",
      Jewish: 'Jewish',
      Lutheran: 'Lutheran',
      Methodist: 'Methodist',
      Mormon_latter_day_saints: 'Mormon / Latter Day Saints',
      Muslim: 'Muslim',
      Pentecostal: 'Pentecostal',
      Presbyterian: 'Presbyterian',
      Protestant: 'Protestant',
      Seventh_day_adventist: 'Seventh-Day Adventist',
      United_church_of_christ: 'United Church of Christ',
    },
  },
  localSupportGroupMeetings: {
    name: 'Support Group Meetings Available Locally',
    items: {
      Alcoholics_anonymous: 'Alcoholics Anonymous',
      Narcotics_anonymous: 'Narcotics Anonymous',
      Smart_recovery: 'SMART Recovery',
    },
  },

  //---------------------
  // Opioid Use Disorder
  //---------------------

  opioidTreatmentMethods: {
    name: 'Opioid Use Disorder Treatment Methods',
    items: {
      Using_methadone_or_buprenorphine:
        'This facility uses methadone or buprenorphine for pain management, emergency cases, or research purposes. It is NOT a federally-certified Opioid Treatment Program (OTP).',
      Treats_without_medication:
        'This facility treats opioid use disorder, but it does not use medication assisted treatment (MAT), nor does it accept clients using MAT to treat opioid use disorder.',
      Using_prescribed_mat:
        'This facility accepts clients using MAT, but the medications originate from or are prescribed by another entity. (The medications may or may not be stored/delivered/monitored onsite.)',
      Administers_naltrexone:
        'This facility administers naltrexone to treat opioid use disorder. Naltrexone use is authorized through any medical staff who have prescribing privileges.',
      Prescribes_buprenorphine:
        'This facility prescribes buprenorphine to treat opioid use disorder. Buprenorphine use is authorized through a DATA 2000 waivered physician, physician assistant, or nurse practitioner.',
      Federally_certified_otp:
        'This facility is a federally-certified Opioid Treatment Program (OTP). (Most OTPs administer/dispense methadone; some only use buprenorphine.)',
    },
  },
  matClientsMedicationSource: {
    name: 'MAT Clients Medication Source',
    items: {
      In_network: 'The prescribing entity is in our network.',
      Referral:
        'There is a business, contractual, or formal referral relationship with the prescribing entity.',
      Personal_physician:
        'The client obtains their prescription/medication from their personal physician/health care provider.',
    },
  },
  medicationServices: {
    name: 'Medication Services',
    items: {
      Methadone_buprenorphine_maintenance: 'Maintenance services with methadone or buprenorphine',
      Medically_supervised_withdrawal_maintenance:
        'Maintenance services with medically-supervised withdrawal (or taper) after a period of stabilization',
      Methadone_buprenorphine_detoxification:
        'Detoxification from opioids of abuse with methadone or buprenorphine',
      Lofexidine_clonidine_detoxification:
        'Detoxification from opioids of abuse with lofexidine or clonidine',
      Naltrexone_relapse_prevention: 'Relapse prevention with naltrexone',
      None: 'We do not offer any of these medication services',
    },
  },

  //----------------------
  // Alcohol Use Disorder
  //----------------------

  alcoholTreatmentMethods: {
    name: 'Alcohol Treatment Methods',
    items: {
      Without_medication:
        'This facility treats alcohol use disorder, but it does not use medication assisted treatment (MAT)for alcohol use disorder, nor does it accept clients using MAT to treat alcohol use disorder.',
      Using_prescribed_mat:
        'This facility accepts clients using MAT for alcohol use disorder, but the medications originate from or are prescribed by another entity.',
      Prescribes_disulfiram_naltrexone_acamprosate:
        'This facility administers/prescribes at least one of disulfiram (Antabuse®), naltrexone, and/or acamprosate (Campral®)for alcohol use disorder.',
    },
  },

  //--------------------------
  // Client Population Served
  //--------------------------

  genderAccepted: {
    name: 'Gender Accepted',
    items: {
      Female: 'Female Only',
      Male: 'Male Only',
      Female_male: 'Female & Male',
    },
  },
  clientCategoriesAccepted: {
    name: 'Client Categories Accepted',
    items: {
      Adolescents: 'Adolescents',
      Young_adults: 'Young adults',
      Adult_men: 'Adult men',
      Adult_women: 'Adult women',
      Pregnant_postpartum_women: 'Pregnant/postpartum women',
      Mothers_with_young_children: 'Mothers with young children',
      Seniors_or_older_adults: 'Seniors or older adults',
      Working_professionals: 'Working professionals',
      Lesbian_gay_bisexual_transgender_clients:
        'Lesbian, gay, bisexual, transgender (LGBT) clients',
      Veterans: 'Veterans',
      Active_duty_military: 'Active duty military',
      Members_of_military_families: 'Members of military families',
      Criminal_justice_clients: 'Criminal justice clients (other than DUI/DWI)',
      Clients_with_dui_dwi_or_other_drunk_driver_offenses:
        'Clients with DUI/DWI or other drunk driver offenses',
      Clients_with_co_occurring_mental_and_substance_abuse_disorders:
        'Clients with co-occurring mental and substance abuse disorders',
      Clients_with_co_occurring_pain_and_substance_use:
        'Clients with co-occurring pain and substance use',
      Clients_with_hiv_or_aids: 'Clients with HIV or AIDS',
      Clients_requiring_wheelchair_accessibility: 'Clients requiring wheelchair accessibility',
      Clients_who_have_experienced_sexual_abuse: 'Clients who have experienced sexual abuse',
      Clients_who_have_experienced_domestic_violence:
        'Clients who have experienced intimate partner violence, domestic violence',
      Clients_who_have_experienced_trauma: 'Clients who have experienced trauma',
      No_tailored_programs_or_groups_offered:
        'No specifically tailored programs or groups are offered',
    },
  },

  //--------------------
  // Language Details
  //--------------------

  staffCounselorLanguages: {
    name: 'Staff Counselor Languages',
    items: {
      Arabic: 'Arabic',
      Any_chinese_language: 'Any Chinese language',
      Creole: 'Creole',
      Farsi: 'Farsi',
      French: 'French',
      German: 'German',
      Greek: 'Greek',
      Hebrew: 'Hebrew',
      Hindi: 'Hindi',
      Hmong: 'Hmong',
      Italian: 'Italian',
      Japanese: 'Japanese',
      Korean: 'Korean',
      Polish: 'Polish',
      Portuguese: 'Portuguese',
      Russian: 'Russian',
      Spanish: 'Spanish',
      Tagalog: 'Tagalog',
      Vietnamese: 'Vietnamese',
    },
  },
  staffCounselorNativeLanguages: {
    name: 'Staff Counselor Native Languages',
    items: {
      Hopi: 'Hopi',
      Lakota: 'Lakota',
      Navajo: 'Navajo',
      Ojibwa: 'Ojibwa',
      Yupik: 'Yupik',
    },
  },

  //--------------------
  // Financial Details
  //--------------------

  clientPaymentMethods: {
    name: 'Client Payment Methods',
    items: {
      Cash_or_self_payment: 'Cash or self-payment',
      Out_of_network_private_insurance: 'Private health insurance (out-of-network)',
      In_network_private_insurance: 'Private health insurance (in-network)',
      None: 'No payment accepted (free treatment for ALL clients)',
      Medicare: 'Medicare',
      Medicaid: 'Medicaid',
      State_financed_plan: 'State-financed health insurance plan other than Medicaid',
      Federal_military_insurance: 'Federal military insurance (e.g. TRICARE)',
      Itu_funds: 'IHS/Tribal/Urban (ITU) funds',
    },
  },
  cashPayInterval: {
    name: 'Cash Pay Interval',
    items: {
      Daily: 'Daily',
      Weekly: 'Weekly',
      Monthly: 'Monthly',
    },
  },

  //----------------------------
  // Amenities & Infrastructure
  //----------------------------

  fitness: {
    name: 'Fitness',
    items: {
      Gym_membership: 'Gym Membership',
      Pilates: 'Pilates',
      Yoga: 'Yoga',
      Basketball: 'Basketball',
      Tennis: 'Tennis',
      Volleyball: 'Volleyball',
      Soccer: 'Soccer',
      Martial_arts: 'Martial Arts',
      Swimming: 'Swimming',
      Hiking: 'Hiking',
      Outdoor_program: 'Outdoor Program',
      Overnight_outdoor_program: 'Overnight Outdoor Program',
      Wilderness_program: 'Wilderness Program',
    },
  },
  recreation: {
    name: 'Recreation',
    items: {
      Beach: 'Beach',
      Parks: 'Parks',
      Pool: 'Pool',
      Public_library_access: 'Public Library Access',
      Cooking_class: 'Cooking Class',
      Dance: 'Dance',
      Art: 'Art',
      Music: 'Music',
      Chess: 'Chess',
      Other_board_games: 'Other Board Games',
    },
  },
  premiumAmenities: {
    name: 'Premium Amenities',
    items: {
      Smoking_allowed: 'Smoking Allowed',
      Pet_friendly: 'Pet Friendly',
      Chef_prepared_meals: 'Chef-Prepared Meals',
      Cleaning_services: 'Cleaning Services',
      Laundry_services: 'Laundry Services',
      Dry_cleaning: 'Dry Cleaning',
      Nutritionist: 'Nutritionist',
      Personal_trainer: 'Personal Trainer',
      Massage_therapist: 'Massage Therapist',
      Spa: 'Spa',
    },
  },
  infrastructure: {
    name: 'Infrastructure',
    items: {
      Internet_access: 'Internet Access',
      Home_theater: 'Home Theater',
      Recording_studio: 'Recording Studio',
      Library: 'Library',
      Wheelchair_accessible: 'Wheelchair Accessible',
    },
  },
  transportation: {
    name: 'Transportation',
    items: {
      Facility_transportation: 'Facility Transportation',
      Public_transportation: 'Public Transportation',
      Personal_car_allowed: 'Personal Car Allowed',
      Uber_lyft_taxi_allowed: 'Uber / Lyft / Taxi Allowed',
    },
  },
  accommodations: {
    name: 'Accommodations',
    items: {
      Private_bathroom_available: 'Private Bathroom Available',
      Private_bedroom_available: 'Private Bedroom Available',
      Shared_bathroom: 'Shared Bathroom',
      Shared_bedroom: 'Shared Bedroom',
      Technology_privileges: 'Technology Privileges',
      Personal_cell_phone_allowed: 'Personal Cell Phone Allowed',
      Personal_laptop_allowed: 'Personal Laptop Allowed',
      Personal_ipod_allowed: 'Personal iPod Allowed ',
      Facility_phone: 'Facility Phone',
      Facility_computer: 'Facility Computer',
    },
  },
  smokingPolicy: {
    name: 'Smoking Policy',
    items: {
      Not_permitted: 'Not permitted to smoke anywhere outside or within any building',
      Designated_outdoor_areas: 'Permitted in designated outdoor area(s)',
      Anywhere_outside: 'Permitted anywhere outside',
      Designated_indoor_areas: 'Permitted in designated indoor area(s)',
      Anywhere_inside: 'Permitted anywhere inside',
      Anywhere_without_restriction: 'Permitted anywhere without restriction',
    },
  },
  locationType: {
    name: 'Location Type',
    items: {
      City: 'City Center / Inner City',
      Urban: 'Urban',
      Suburban: 'Suburban',
      Rural: 'Rural',
    },
  },
  naturalEnvironment: {
    name: 'Natural Environment',
    items: {
      Forest: 'Forest',
      Mountains: 'Mountains',
      Coastal_beach: 'Coastal / Beach',
      Parklands: 'Parklands',
      Farmlands: 'Farmlands',
    },
  },
}
