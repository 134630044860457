import React from 'react'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Markup from '../../../components/Markup'
import Overlay from '../../../components/Overlay'

export const NDAOverlay = ({ onClose, data, onAgree, didAgree, isLoading }: any) => {
  if (!data) return null

  const handleAgree = async () => {
    try {
      await onAgree?.()
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} maxWidth={60} position="center">
      <Overlay.Header title="NDA" icon="legal_agreement_alt" />

      <Overlay.Content className="p-5">
        <Markup value={data.parsed_nda_text || data.nda_text} />
      </Overlay.Content>

      {!didAgree && (
        <Overlay.Footer>
          <div className="grid gap-3">
            {data.nda_acknowledgement_legal_text && (
              <Alert small contrast type="warning" glyph="info">
                {data.parsed_nda_acknowledgement_legal_text || data.nda_acknowledgement_legal_text}
              </Alert>
            )}

            <Button label="Agree to NDA" color="green" glyph="tick_circle" type="primary" onClick={handleAgree} isLoading={isLoading} />
          </div>
        </Overlay.Footer>
      )}
    </Overlay>
  )
}
