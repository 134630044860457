import React from 'react'

import { APP_URLS } from '../MyAccount/utils'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Text from '../../components/Typography/Text'
import Textarea from '../../components/Forms/Textarea'

import Notifications from '../../modules/notifications'
import { useCreate } from '../../hooks/useNewAPI'

const RootCommunityOwnershipRequestOverlay = (props: any) => {
  const { entityId, entitySlug, entityName } = props

  const { close, data, form, onValidationUpdate, isInvalid, isOverlayLoading } = useOverlay({
    name: 'entities-search-invite',
    endpoint: `/community/entities`,
    invalidate: 'invites',
    options: {
      dataID: entityId,
      ...props,
    },
  })

  const { timezone } = useSettings()

  const { mutateAsync: sendRequestProfile, isLoading: isSendingRequest } = useCreate({
    name: ['entities_request_profile'],
    url: '/community/entities/request_profile',
  })

  const handleSend = async () => {
    const formData = form.current.getFormValue()

    try {
      await sendRequestProfile({ ...formData })

      Notifications.send('Your Request has been sent', 'positive')

      close()
    } catch (error) {
      console.error(error)
      Notifications.send('Something went wrong. Please try again in a few minutes', 'negative')
    }

    close()
  }

  if (!data || isOverlayLoading) return <OverlayLoader position="center" />

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={close}>
      <Overlay.Header title="Request Profile Ownership" subtitle={<Text label={entityName} avatar="" />} icon="star" />

      <Overlay.Content>
        <Form isCompact isEditable getForm={form} timezone={timezone} onValidationUpdate={onValidationUpdate}>
          <Section>
            <Grid gap="0.75rem">
              <Input
                isEditable={false}
                label="Profile URL"
                model="profile"
                defaultValue={`${APP_URLS.community}/profiles/${entitySlug}`}
                validations={{
                  presence: {
                    message: 'Please enter a subject',
                  },
                }}
              />

              <Input
                label="Subject"
                model="subject"
                defaultValue={`Request "${data?.name}" Profile Ownership`}
                validations={{
                  presence: {
                    message: 'Please enter a subject',
                  },
                }}
              />

              <Textarea
                label="Message"
                model="message"
                defaultValue={getDefaultMessage({ entity: data, slug: entitySlug })}
                validations={{
                  presence: {
                    message: 'Please write a message',
                  },
                }}
              />
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Email Us to Request Profile Ownership"
          glyph="email"
          type="primary"
          color="blue"
          isLoading={isSendingRequest}
          isDisabled={isInvalid}
          onClick={handleSend}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const getDefaultMessage = ({ entity, slug }: any) => {
  const communityURL = slug && `${APP_URLS.community}/profiles/${slug}`

  return `Hello,

I found my profile on Behave Community and would like to request ownership of it:
- Name: "${entity.name}"
- URL: ${communityURL}`
}

export const CommunityOwnershipRequestOverlay = withOverlayError(RootCommunityOwnershipRequestOverlay)
