import create from 'zustand'

type ResultsView = 'details' | 'compact'

export const useSearchStore = create((set: any) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false, searchInput: '' }),

  searchInput: '',
  setSearchInput: (input: string) => set({ searchInput: input }),

  resultsView: 'details',
  setResultsView: (resultsView: ResultsView) => set({ resultsView }),

  activeResult: {},
  setActiveResult: (data: any) => set({ activeResult: data }),
}))
