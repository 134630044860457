import React from 'react'
import { useLocation } from 'react-router-dom'

import { Text } from '../Typography'
import DataFormStatus from '../Statuses/DataFormStatus'

import { usDate } from '../../utils/functions'
import useActivePath from '../../hooks/useActivePath'
import withSettings from '../../hocs/withSettings'

import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'

type Props = {
  data: any
  location: any
  timezone: any
  to: any
}

const Discharge = ({ data, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title="Discharge Summary" />
      <CardMeta>
        <DataFormStatus status={data?.status} />
        <Text avatar={data?.author?.avatar} label={data?.author?.name} />
        <Text glyph="date" label="Admission Date: " description={usDate(data?.data?.general?.admitted_at, timezone)} />
        <Text glyph="date" label="Discharge Date: " description={usDate(data?.data?.general?.discharged_at, timezone)} />
        <Text glyph="date" label="Last Edited: " description={usDate(data.created_at, timezone)} />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(Discharge)
