import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../theme'

import Glyph from './Glyph'

type Props = {
  after?: any
  children?: React.ReactNode
  className?: any
  headerClassName?: any
  indentPadding?: any
  isOpen?: boolean
  onClick?: any
  shouldIndent?: boolean
  shouldUnmount?: boolean
  showDivider?: boolean
  subtitle?: React.ReactNode
  title: any
  titleAfter?: any
  titleBefore?: any
  withHover?: boolean
  headerContent?: React.ReactNode
}

const TreeItem = ({
  after,
  children,
  className,
  headerClassName,
  indentPadding = '1.5rem',
  isOpen,
  onClick,
  shouldIndent = true,
  shouldUnmount,
  showDivider,
  subtitle,
  title,
  titleAfter,
  titleBefore,
  withHover = true,
  headerCSS,
  headerContent,
  renderAfter,
}: Props) => {
  const [open, setOpen] = React.useState(isOpen)

  const canToggle = !!children

  const toggle = () => {
    setOpen((c) => !c)

    if (onClick) onClick()
  }

  const classNames = clsx('tree-item', {
    'is-open': open,
    'can-toggle': canToggle,
    'should-indent': shouldIndent,
    [className]: className,
  })

  React.useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const HeaderTag = canToggle ? 'button' : 'div'

  return (
    <ul css={styles.root} className={classNames} style={{ '--indent-padding': indentPadding }}>
      <HeaderTag
        type="button"
        css={{ ...styles.header, ...headerCSS }}
        onClick={canToggle ? toggle : undefined}
        className={clsx(showDivider && 'show-divider', withHover && 'with-hover', canToggle && 'can-toggle', headerClassName)}
      >
        <div css={styles.graphic} className={!canToggle && 'invisible'}>
          <Glyph glyph="triangle_down" size={12} color={COLORS.blue} className={clsx('triangle', open && 'is-open')} />
        </div>

        {(titleBefore || title || titleAfter || subtitle) && (
          <div className="flex-grow overflow-hidden">
            <div css={styles.title}>
              {titleBefore}
              {title}
              {titleAfter}
            </div>
            {subtitle && <div css={styles.subtitle}>{subtitle}</div>}
          </div>
        )}

        {headerContent}

        {after}

        {renderAfter && renderAfter({ isOpen: open })}
      </HeaderTag>

      <li className="children">{shouldUnmount ? open && <div>{children}</div> : <div>{children}</div>}</li>
    </ul>
  )
}

const styles = {
  root: {
    margin: 0,
    padding: 0,
    listStyle: 'none',

    '& > .children': {
      display: 'none',
      paddingTop: 1,
      paddingBottom: 1,
    },

    '&.is-open': {
      '& > .children': {
        display: 'block',
      },
    },

    '&.should-indent': {
      '& > .children': {
        paddingLeft: 'var(--indent-padding)',
      },

      'li ul.tree-item': {
        position: 'relative',
        paddingLeft: 'calc(var(--indent-padding) / 2)',

        '&::before': {
          content: '""',
          position: 'absolute',
          width: 1,
          height: '100%',
          top: 0,
          left: 'calc(var(--indent-padding) / 2)',
          background: COLORS.divider,
        },
      },
    },
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0.5rem 1rem',
    paddingLeft: 0,
    flex: '1 1 auto',

    fontSize: '1rem',
    fontWeight: 600,
    textAlign: 'left',

    border: 'none',
    outline: 'none',
    background: 'none',
    cursor: 'default',

    '&.show-divider': {
      borderBottom: `1px solid ${COLORS.divider}`,
    },

    '&.can-toggle': {
      cursor: 'pointer',

      '&.with-hover:hover': {
        color: COLORS.blue,
        background: COLORS.hover,
      },
    },

    '.triangle': {
      transform: 'rotate3d(0, 0, 1, -90deg)',
      transition: 'transform 120ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',

      '&.is-open': {
        transform: 'rotate3d(0, 0, 1, 0)',
      },
    },
  },

  graphic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    width: '1.5rem',
    position: 'relative',
  },

  title: {
    color: COLORS.black,
    display: 'flex',
    alignItems: 'center',
  },

  subtitle: {
    fontWeight: 400,
    marginTop: '0.2em',
    color: COLORS.textLight,
  },
}

export default TreeItem
