import React from 'react'
import axios from 'axios'
import clsx from 'clsx'
import pluralize from 'pluralize'

import { Button } from './Button'
import { Card } from './Card'
import { H2, Text } from './Typography'
import DonutChart from './DonutChart'
import Flex from './Flex'
import Grid from './Grid'
import State from './State'

import poweredByImg from '../assets/img/powered_by_behave_health@2x.png'

const buildURL = (external_id: string) => {
  return `${process.env.BH_API_BASE_PROTOCOL}://${process.env.BH_API_BASE_URL}/${process.env.BH_API_VERSION}/apps/availability/beds/${external_id}`
}

const applicationForm = (tenantID: string) => {
  return `${process.env.BH_API_BASE_PROTOCOL}://${process.env.BH_APP_BASE_URL}/${tenantID}/forms/full-application`
}

type BedAvailabilityResponse = {
  beds_available: number
  beds_total: number
}

type Props = {
  showPercentage: boolean
  showTotalBeds: boolean
  showBreakdown: boolean
  callToAction: string
  phone_no: string
  website: string
  websiteLabel: string
  tenant: string
  tenantID: string
  externalID: string
}

const BedAvailabilityWidget: React.FC<Props> = ({
  showPercentage,
  showTotalBeds,
  showBreakdown,
  callToAction,
  phone_no,
  website,
  websiteLabel,
  tenant,
  tenantID,
  externalID,
}) => {
  const informationClassNames = clsx({
    'with-percentage': showPercentage,
  })

  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState<BedAvailabilityResponse>({ beds_available: 2, beds_total: 0 })
  const [error, setError] = React.useState(false)

  const isProcessing = loading || error

  React.useEffect(() => {
    setLoading(true)

    const fetchAvailability = async () => {
      try {
        let result = await axios.get<BedAvailabilityResponse>(buildURL(externalID), {
          headers: {
            'X-Tenant': tenant,
          },
        })

        setData(result.data)
      } catch (errors) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchAvailability()
  }, [])

  const percentage = data?.beds_available && data?.beds_total ? Math.round((data?.beds_available * 100) / data?.beds_total) : 0

  return (
    <>
      <Card css={{ padding: '1rem .75rem .6rem .75rem' }}>
        {isProcessing && (
          <State
            isLoading={loading}
            hasError={error}
            title="Bed Availability"
            errorDescription="Sorry we couldn't fetch the data right now"
            minHeight={160}
          />
        )}

        {!isProcessing && (
          <Grid gap="1rem" className="!w-full">
            <Flex css={informationStyles} className={informationClassNames}>
              {showPercentage && (
                <Flex alignItems="center" justifyContent="center" className="!mr-4">
                  <DonutChart value={percentage} size={75} />
                </Flex>
              )}

              <Flex vertical>
                <H2 css={{ marginBottom: 7 }}>{data?.name}</H2>

                <Text css={[textStyles, { marginBottom: 7 }]} color="text">
                  <strong>{data?.beds_available}</strong> Beds Available{' '}
                  {showTotalBeds && (
                    <Text css={textStyles} color="text">
                      <span>
                        / <strong>{data?.beds_total}</strong> Total Beds
                      </span>
                    </Text>
                  )}
                </Text>

                {showBreakdown && (
                  <Flex gap={7}>
                    <Text glyph="gender_male" css={textStyles}>
                      {`${data?.beds_men} men ${pluralize('bed', data?.beds_men)}`}
                    </Text>
                    <Text glyph="gender_female" css={textStyles}>
                      {`${data?.beds_women} women ${pluralize('bed', data?.beds_women)}`}
                    </Text>
                    <Text glyph="gender_all" css={textStyles}>
                      {`${data?.beds_mixed} mixed ${pluralize('bed', data?.beds_mixed)}`}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>

            {callToAction === 'application' && (
              <Button
                type="primary"
                target="_blank"
                label="Start Application Process"
                href={applicationForm(tenantID)}
                css={{ color: 'white !important' }}
              />
            )}

            {callToAction === 'phone' && <Button type="primary" glyph="phone" label={`Call ${phone_no}`} href={`tel:${phone_no}`} />}

            {callToAction === 'website' && (
              <Button type="primary" glyph="external_link" label={websiteLabel} href={website} target="_blank" />
            )}
          </Grid>
        )}
      </Card>

      <a
        href="https://behavehealth.com"
        style={{ display: 'flex', minHeight: 45, alignItems: 'flex-start', justifyContent: 'flex-end', marginTop: '0.75rem' }}
      >
        <img src={poweredByImg} alt="Powered by Behave Health" className="w-[210px]" />
      </a>
    </>
  )
}

const textStyles = {
  fontSize: '1.1rem',
}

const informationStyles = {
  marginBottom: '.6rem',
  flexWrap: 'nowrap',
}

export default BedAvailabilityWidget
