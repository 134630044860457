import React from 'react'
import { DateTime } from 'luxon'
import { useParams } from 'react-router-dom'

import { daysToWords } from '../../../utils/functions'
import { useOverlay } from '../../../hooks/useOverlay'
import { useSettings } from '../../../hooks/useSettings'

import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import DateInput from '../../Forms/DateInput'
import DateTimeInput from '../../Forms/DateTimeInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import MessageStatus from '../../Statuses/MessageStatus'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import OverlayLoader from '../../OverlayLoader'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'
import URLInput from '../../Forms/URLInput'

import { withOverlayError } from '../../../hocs/withOverlayError'

const DEFAULT_EXPIRY_DAYS = 7

const REFERENCE_TYPES: any = {
  organizations: 'organization',
  properties: 'house',
  locations: 'house',
}

const RecordMessage = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'message',
    endpoint: '/messages',
    invalidate: 'messages',
    options: props,
  })

  const { timezone } = useSettings()
  const { resource, resource_id }: any = useParams()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="care_level_4" title="Message" />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              reference_id: resource_id,
              reference_type: REFERENCE_TYPES[resource],
            }
          }
          decorate={(model) => ({
            expires_at:
              isNew && model.days_to_expiry
                ? DateTime.local()
                    .plus({ days: model.days_to_expiry + 1 })
                    .setZone(timezone)
                    .startOf('day')
                    .toISO()
                : model.expires_at,
          })}
        >
          <Section>
            <FormSection layout="vertical">
              <RadioGroup
                label="Message Category"
                model="category"
                layout="vertical-dense"
                defaultValue="info"
                validations={{
                  presence: {
                    message: 'Please select a message type',
                  },
                }}
              >
                <Radio label={<MessageStatus status="note" />} value="note" />
                <Radio label={<MessageStatus status="info" />} value="info" />
                <Radio label={<MessageStatus status="warning" />} value="warning" />
                <Radio label={<MessageStatus status="alert" />} value="alert" />
              </RadioGroup>

              <Input
                vertical
                label="Title"
                model="title"
                validations={{
                  presence: {
                    message: 'Please enter a message title',
                  },
                }}
              />

              <Textarea label="Description" model="description" />

              <URLInput label="External Link" model="link" />

              {isNew ? (
                <Input
                  label="Expires In"
                  type="number"
                  min={1}
                  size={6}
                  suffix="days"
                  model="days_to_expiry"
                  defaultValue={DEFAULT_EXPIRY_DAYS}
                  validations={{
                    presence: {
                      message: 'Please select a due date',
                    },
                    numericality: {
                      greaterThan: 0,
                      message: 'Please enter a number of days greater than zero',
                    },
                  }}
                />
              ) : (
                <DateInput label="Expires On" model="expires_at" smartDescription={(date) => daysToWords(date, timezone)} />
              )}

              {!isEditable && <ObjectSelector label="Written By" model="author" type="employees" isEditable={false} icon="employees" />}

              {!isEditable && <DateTimeInput label="Created At" model="created_at" />}

              {!isEditable && data?.updated_at !== data?.created_at && <DateTimeInput label="Last Updated" model="updated_at" />}
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Message file attachments">
            <FormSection layout="vertical">
              <Attachments label="Attachments" model="documents" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              glyph="check"
              label="Save"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="messages.edit"
            />
            {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              label="Edit"
              glyph="edit"
              type="default"
              isDisabled={isDeleting}
              onClick={edit}
              flex="100 1 auto"
              permission="messages.create"
            />

            <DeleteDialog
              title="Delete Message?"
              message="Are you sure you want to delete this message? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                label="Delete"
                type="default"
                glyph="delete"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="messages.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export default withOverlayError(RecordMessage)
