import React from 'react'
import { DateTime } from 'luxon'

import { RECURRING_FREQUENCIES } from '../../utils/recurrence'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

import { useCommunityEditor } from './useCommunityEditor'

const RootCommunityProfileNonHealthcareServiceOverlay = (props: any) => {
  const { entityId } = props

  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  const { isNew, close, saveWithData, form, data, onValidationUpdate, isOverlayLoading, initialModel, isInvalid, isSaving } = useOverlay({
    name: 'entity_non_healthcare_service',
    endpoint: `/community/entity_non_healthcare_services`,
    invalidate: 'entity_non_healthcare_services',
    invalidateKeys: ['community-entity', 'entity_non_healthcare_services'],
    isEditable: true,
    options: props,
    closeOnSave: true,
    onSaveSuccessful: (newData: any) => {
      setUpdatedAt(DateTime.now().toMillis())
    },
  })

  const { timezone, isEHRApp } = useSettings()

  const [imported, setImported] = React.useState<any>(null)

  const [name, setName] = React.useState(initialModel?.name || '')
  const [description, setDescription] = React.useState(initialModel?.description || '')
  const [amount, setAmount] = React.useState(initialModel?.amount || '')
  const [isRecurring, setIsRecurring] = React.useState(!!initialModel?.is_recurring)
  const [isAmountPublic, setIsAmountPublic] = React.useState(isNew ? true : !!initialModel?.is_amount_public)
  const [recurringFrequency, setRecurringFrequency] = React.useState(initialModel?.recurring_frequency || 'monthly')

  const handleSave = () => {
    saveWithData({
      ...form.current.getFormValue(),

      name,
      description: description,
      amount: amount,
      is_recurring: isRecurring,
      is_amount_public: isAmountPublic,
      recurring_frequency: recurringFrequency,

      ...(isNew && { entity_id: entityId }),
      ...(isNew && imported && { reference_type: imported.type, reference_id: imported.id }),
    })
  }

  if (isOverlayLoading) return <OverlayLoader position="center" maxWidth={40} />

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" maxWidth={40} onClose={close}>
      <Overlay.Header title="Service" icon="non_healthcare_services" />

      <Overlay.Content>
        <Section>
          <Form
            isEditable
            key={imported?.id}
            getForm={form}
            initialModel={initialModel}
            timezone={timezone}
            onValidationUpdate={onValidationUpdate}
            className="grid gap-4"
          >
            <FormSection maxWidth="100%">
              {isEHRApp && (
                <SummonOverlay
                  overlay={
                    <ClientServiceSelectorOverlay
                      onSelect={(data: any) => {
                        setImported(data)

                        setName(data.name)
                        setDescription(data.description)
                        setAmount(data.amount)
                        setIsRecurring(data.is_recurring)
                      }}
                    />
                  }
                >
                  <div>
                    <Button label="Copy from Client Services…" icon="financials" size={300} type="default" display="inline-flex" />
                  </div>
                </SummonOverlay>
              )}

              <Input
                label="Name"
                value={name}
                onUpdate={({ value }) => {
                  setName(value)
                }}
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <MultiObjectSelector
                label="Locations"
                model="locations"
                icon="locations"
                type="community_profile.locations"
                dependentValue={entityId}
                selectTitle={(data: any) => data.name}
              />

              <RadioGroup
                label="Is this a one-time or repeating service?"
                value={isRecurring}
                onUpdate={({ value }) => {
                  setIsRecurring(value)
                }}
                layout="horizontal-dense"
                validations={{
                  presence: {
                    message: 'Please select an answer',
                  },
                }}
              >
                <Radio label="One-time" value={false} />
                <Radio label="Repeating" value={true} />
              </RadioGroup>

              <Checkbox
                label="Show Amount Publicly"
                value={isAmountPublic}
                defaultChecked={isAmountPublic}
                onUpdate={({ value }) => {
                  setIsAmountPublic(value)
                }}
              />

              {isAmountPublic && (
                <AmountInput
                  label="Amount"
                  value={amount}
                  onUpdate={({ value }) => {
                    setAmount(value)
                  }}
                />
              )}

              {isRecurring && (
                <RadioGroup
                  label="Repeating Frequency"
                  model="recurring_frequency"
                  layout="vertical-dense"
                  defaultValue="monthly"
                  value={recurringFrequency}
                  onUpdate={({ value }) => {
                    setRecurringFrequency(value)
                  }}
                >
                  {Object.keys(RECURRING_FREQUENCIES).map((key) => (
                    <Radio key={key} label={RECURRING_FREQUENCIES[key].label} value={key} />
                  ))}
                </RadioGroup>
              )}

              <Textarea
                label="Description"
                value={description}
                onUpdate={({ value }) => {
                  setDescription(value)
                }}
              />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save Service"
          glyph="tick_circle"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={isSaving}
          isDisabled={!name}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const ClientServiceSelectorOverlay = (props: any) => {
  const { onClose, onSelect } = props

  const tableProps = useDataTable({
    name: ['client-service-selector-overlay'],
    endpoint: '/services',
    params: {
      service_type: 'non_healthcare',
      status: 'active',
    },
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            onClick={
              onSelect
                ? () => {
                    onSelect(data)
                    onClose?.()
                  }
                : undefined
            }
          />
        ),
      },
      {
        title: 'Description',
        model: 'description',
        width: 300,
      },
      {
        title: 'Amount',
        model: 'amount',
        type: 'amount',
      },
      {
        title: 'Recurring',
        model: 'is_recurring',
        type: 'boolean',
      },
    ],
    [],
  )

  return (
    <Overlay showBackdrop closeOnBackdrop maxWidth={80} position="center" onClose={onClose}>
      <Overlay.Header title="Select Client Service" icon="financials" />

      <Overlay.Content>
        <DataTable {...tableProps} title="Client Services" icon="financials" columns={columns} />
      </Overlay.Content>
    </Overlay>
  )
}

export const CommunityProfileNonHealthcareServiceOverlay = withOverlayError(RootCommunityProfileNonHealthcareServiceOverlay)
