import { persist } from 'zustand/middleware'
import create from 'zustand'

export const useCommunityEditor = create(
  persist(
    (set: any, get: any) => ({
      updatedAt: null,
      setUpdatedAt: (val: any) => set({ updatedAt: val }),
    }),
    { name: 'bh.community-editor' },
  ),
)
