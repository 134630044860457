import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const ProgramListsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.data.id} value={value} to={to?.(data.data)} />,
      },
      {
        title: 'Program',
        id: 'program',
        model: 'data.program.name',
        width: 260,
        type: 'profile',
      },
      {
        width: 140,
        id: 'residents_count',
        model: 'data.residents_count',
        title: '# Clients',
      },
      {
        width: 200,
        id: 'description',
        model: 'data.description',
        title: 'Description',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Program Lists"
      icon="checklist"
      columns={columns}
      filtersConfig={FILTERS.live_reports.program_lists}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="program_lists"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=program_lists'}
        />
      }
    />
  )
}
