import React from 'react'

import { formatURL } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import MessageStatus from '../Statuses/MessageStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to?: Function, mainLinkAs, timezone?: string) => [
  {
    width: 220,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'title',
    Header: 'Title',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink
        to={to && to(row.original.id)}
        label={row.original.is_expired ? `${value} (Expired)` : value}
        avatar={row.original.avatar}
        css={row.original.is_expired && { fontStyle: 'italic', textDecoration: 'line-through', opacity: 0.5 }}
        as={mainLinkAs}
      />
    ),
  },
  {
    width: 140,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ value, row }: any) => <MessageStatus status={value} css={row.original.is_expired && { opacity: 0.5 }} />,
  },
  {
    width: 400,
    accessor: 'description',
    Header: 'Description',
    Cell: ({ value, row }: any) => {
      if (!value) return <TableCell.Empty />

      return (
        <TableCell.Markup
          value={value}
          css={row.original.is_expired && { fontStyle: 'italic', textDecoration: 'line-through', opacity: 0.5 }}
        />
      )
    },
  },
  {
    width: 200,
    accessor: 'link',
    Header: 'Link',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell.Empty />
      return <TableCell.Link isExternal href={formatURL(value)} label={value} />
    },
  },
  {
    width: 140,
    accessor: 'expires_at',
    Header: 'Expires At',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  showSettings?: boolean
  timezone: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const MessagesTable = (props: Props) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    batchActionsConfig,
    showSettings,
    title,
    to,
    timezone,
    titleAfter,
    mainLinkAs,
  } = props

  return (
    <Table
      title={title}
      testKey="messages_table"
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, mainLinkAs, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      showSettings={showSettings}
      emptyHeight={80}
    />
  )
}

MessagesTable.defaultProps = {
  // title: 'Messages',
  icon: 'care_level_4',
  emptyDescription: 'No messages added yet',
  localStorageKey: 'messages',
}

export default withSettings(MessagesTable)
