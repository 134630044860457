import React from 'react'
import clsx from 'clsx'
import * as HoverCard from '@radix-ui/react-hover-card'

import { INPUT_STYLES, COLORS } from '../../../theme'

import ClearButton from '../../Forms/ClearButton'
import Glyph from '../../Glyph'
import Graphic from '../../Graphic'

type Props = {
  avatar?: string
  icon?: string
  isDisabled: boolean
  label: string
  onClear: any
  onOpen: any
  placeholder: string
  className?: any
  testKey?: any
  getRef?: any
  showFocusMessage?: boolean
}

const SelectorInput = (props: Props) => {
  const {
    avatar,
    icon,
    isDisabled,
    label,
    onClear,
    onOpen,
    placeholder,
    className,
    getRef,
    testKey,
    showFocusMessage,
    object,
    selectGraphic,
  } = props

  const [ref, setRef] = React.useState(null)
  const [isFocused, setIsFocused] = React.useState(false)

  const classNames = clsx({
    'is-disabled': isDisabled,
    'text-only': avatar === undefined && !icon,
    [className]: className,
  })

  React.useEffect(() => {
    if (!getRef || !ref) return

    getRef(ref)
  }, [ref])

  const graphic = (
    <>
      {object && selectGraphic?.(object) ? (
        <Graphic graphic={selectGraphic(object)} css={styles.graphic} />
      ) : (
        <Graphic avatar={avatar} avatarInitials={label} avatarSize="1.75rem" icon={icon} iconSize={20} css={styles.graphic} />
      )}
    </>
  )

  return (
    <button
      ref={setRef}
      type="button"
      data-test={testKey || 'selector_input'}
      css={styles.root}
      onClick={onOpen}
      className={classNames}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      {graphic}

      {label && (
        <>
          <HoverCard.Root openDelay={500} closeDelay={50}>
            <HoverCard.Trigger asChild>
              <span className="truncate">{label}</span>
            </HoverCard.Trigger>

            <HoverCard.Portal container={document?.getElementById?.('portal-radix')}>
              <HoverCard.Content asChild align="start" side="bottom">
                <span className="w-full flex items-center px-3 py-2 max-w-[400px] bg-white shadow-soft-5 rounded-md">
                  {graphic}
                  <span className="font-[400] ml-2">{label}</span>
                </span>
              </HoverCard.Content>
            </HoverCard.Portal>
          </HoverCard.Root>

          {!isDisabled && <ClearButton onClick={onClear} css={styles.clearButton} />}
        </>
      )}

      {!label && (
        <>
          {placeholder && <span css={styles.placeholder}>{placeholder}</span>}
          <Glyph glyph="triangle_down" size={10} css={styles.triangle} />
        </>
      )}

      {showFocusMessage && isFocused && (
        <span css={{ ...styles.placeholder, position: 'absolute', right: '2rem' }}>Press Enter to open</span>
      )}
    </button>
  )
}

const styles = {
  root: {
    ...INPUT_STYLES,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0 !important',
    cursor: 'pointer',
    textAlign: 'left',

    '&.text-only': {
      paddingLeft: '0.75rem !important',
    },

    '&.is-disabled': {
      opacity: `0.4 !important`,
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },
  },

  clearButton: {
    width: '2.5rem',
  },

  graphic: {
    width: '2.5rem',
  },

  triangle: {
    marginLeft: 'auto',
    marginRight: '1rem',
    pointerEvents: 'none',
  },

  placeholder: {
    color: COLORS.textMuted,
    opacity: 0.6,
    fontStyle: 'italic',
  },

  isDisabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
}

SelectorInput.defaultProps = {
  // label: 'Select an item',
  placeholder: 'Select…',
}

export default SelectorInput
