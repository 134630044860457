import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { tint } from 'polished'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

import { COLORS, SHADOW } from '../../theme'
import { formatURL } from '../../utils/functions'

import Alert from '../Alert'
import Button from '../Button'
import Card from '../Card'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Form from '../Forms/Form'
import FormSection from '../Forms/FormSection'
import Glyph from '../Glyph'
import Input from '../Forms/Input'
import Loader from '../Loader'
import Overlay from '../Overlay'
import RadioCheckElement from '../Forms/RadioCheckElement'
import Section from '../Section'
import State from '../State'
import OverlayLoader from '../OverlayLoader'
import SummonOverlay from '../SummonOverlay'
import URLInput from '../Forms/URLInput'

import { useGet, useUpdate, useCreate, useDelete } from '../../hooks/useNewAPI'

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = produce(list, (draft: any) => {
    const [removed] = draft.splice(startIndex, 1)
    draft.splice(endIndex, 0, removed)

    for (let i = 0; i < draft.length; i++) {
      draft[i].order = i + 1
    }
  })

  return result
}

const handleSummonOpen = (event: any) => {
  event.stopPropagation()
  event.nativeEvent.stopImmediatePropagation()
}

const BookmarksOverlay = (props: any) => {
  const [ref, setRef] = React.useState(null)

  const { onClose, category } = props

  const { data, isLoading }: any = useGet({
    name: ['bookmarks'],
    url: `/bookmarks`,
  })

  const [bookmarks, setBookmarks] = React.useState(data)
  const isEmpty = size(bookmarks) === 0

  const { mutate: reorderBookmarks, isLoading: isReordering } = useUpdate({
    name: ['bookmarks-reorder'],
    url: `/bookmarks/reorder`,
    invalidate: 'bookmarks',
  })

  React.useEffect(() => {
    if (!data) return

    const ordered = sortBy(
      data.filter((o) => o.category === category),
      'order',
    )

    setBookmarks(ordered)
  }, [data])

  React.useEffect(() => {
    if (!ref) return

    const handleOutsideClick = (event: any) => {
      if (ref?.contains?.(event.target)) return
      close?.()
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [ref])

  const onDragEnd = (result: any) => {
    if (!result.destination) return

    const newOrder = reorder(bookmarks, result.source.index, result.destination.index)

    setBookmarks(newOrder)

    reorderBookmarks({ reorder: newOrder })
  }

  const addBookmark = (
    <SummonOverlay overlay={<NewBookmarkOverlay category={category} />}>
      <Button label="Add Custom Bookmark" glyph="add" css={styles.addButton} permission="settings.bookmarks_manager.create" />
    </SummonOverlay>
  )

  if (isLoading) return <OverlayLoader position="right" />

  return (
    <Overlay showBackdrop onClose={onClose}>
      <Overlay.Header title="Manage Bookmarks" icon="bookmarks" />

      <Overlay.Content css={styles.content}>
        {isEmpty ? (
          <State isEmpty emptyDescription="No bookmarks added yet" emptyActions={addBookmark} />
        ) : (
          <>
            <Alert contrast glyph="info" className="!mb-4">
              Click on service names to show/hide or drag to re-order them.
            </Alert>

            <Card css={styles.card} className={isReordering ? 'is-loading' : ''}>
              <div ref={setRef}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="bookmarks">
                    {(droppableProvided: any) => (
                      <div ref={droppableProvided.innerRef}>
                        {bookmarks.map((bookmark: any, index: number) => (
                          <Draggable key={bookmark.id} draggableId={bookmark.id} index={index}>
                            {(draggableProvided: any, draggableSnapshot: any) => (
                              <BookmarkItem
                                data={bookmark}
                                key={bookmark.id}
                                ref={draggableProvided.innerRef}
                                draggableProvided={draggableProvided}
                                draggableSnapshot={draggableSnapshot}
                              />
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </Card>
            {addBookmark}
          </>
        )}
      </Overlay.Content>
    </Overlay>
  )
}

export const BookmarkItem = React.forwardRef((props: any, ref: any) => {
  const { data, draggableProvided, draggableSnapshot } = props

  const { id } = data

  const { mutate: deleteBookmark, isLoading: isDeleting } = useDelete({
    name: ['bookmark', id],
    url: `/bookmarks/${id}`,
    invalidate: 'bookmarks',
  })

  const { mutate: updateBookmark, isLoading: isUpdating } = useUpdate({
    name: ['bookmark', id],
    url: `/bookmarks/${id}`,
    invalidate: 'bookmarks',
  })

  const toggleActive = () => {
    updateBookmark({ is_active: !data.is_active })
  }

  const isDisabled = isUpdating || isDeleting

  return (
    <div
      data-test="bookmark_item"
      ref={ref}
      css={styles.item}
      style={draggableProvided.draggableProps.style}
      className={clsx({
        'is-dragging': draggableSnapshot.isDragging,
        'is-active': data.is_active,
      })}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
    >
      <div css={styles.dragWrapper}>
        <Glyph glyph="drag_and_drop" size="1rem" css={styles.dragGlyph} />
      </div>

      <div css={styles.checkboxWrapper}>
        {isUpdating ? (
          <Loader size={20} color="green" />
        ) : (
          <RadioCheckElement type="checkbox" isChecked={data.is_active} css={styles.checkbox} onClick={toggleActive} />
        )}
      </div>

      <img src={data.icon} css={styles.logo} />
      <span css={styles.label}>{data.name}</span>

      <SummonOverlay onOpen={handleSummonOpen} overlay={<NewBookmarkOverlay bookmark={data} />}>
        <Button
          hideLabel
          testKey="edit_bookmark_button"
          glyph="edit"
          type="minimal"
          css={styles.customRowButton}
          size={200}
          isDisabled={isDisabled}
          permission="settings.bookmarks_manager.edit"
        />
      </SummonOverlay>

      <DeleteDialog message="Are you sure you want to delete this bookmark?" onYes={deleteBookmark}>
        <Button
          hideLabel
          testKey="delete_bookmark_button"
          glyph="delete"
          type="minimal"
          color="red"
          css={styles.customRowButton}
          size={200}
          isLoading={isDeleting}
          isDisabled={isDisabled}
          permission="settings.bookmarks_manager.delete"
        />
      </DeleteDialog>
    </div>
  )
})

export const NewBookmarkOverlay = ({ onClose: close, bookmark, category, reference }: any) => {
  const form = React.useRef()
  const [valid, setValid] = React.useState(false)

  const id = bookmark?.id
  const isNew = !bookmark

  const { mutateAsync: createBookmark, isLoading: isCreating } = useCreate({
    name: ['bookmark'],
    url: `/bookmarks`,
    invalidate: 'bookmarks',
  })

  const { mutateAsync: updateBookmark, isLoading: isUpdating } = useUpdate({
    name: ['bookmark', id],
    url: `/bookmarks/${id}`,
    invalidate: 'bookmarks',
  })

  const isLoading = isCreating || isUpdating

  const save = async () => {
    const data = form.current?.getFormValue?.()
    const formattedURL = formatURL(data.url)
    const url = formattedURL?.startsWith('//') ? `https:${formattedURL}` : formattedURL

    if (isNew) {
      await createBookmark({
        ...data,
        url,
        category,
        reference_id: reference?.id,
        reference_type: reference?.type,
      })
    } else {
      await updateBookmark({
        ...data,
        url,
      })
    }

    close()
  }

  return (
    <Overlay showBackdrop position="center" onClose={close}>
      <Overlay.Header title={isNew ? 'Add New Bookmark' : 'Edit Bookmark'} />

      <Overlay.Content>
        <Section>
          <Form getForm={form} onValidationUpdate={setValid} initialModel={bookmark}>
            <FormSection>
              <Input
                label="Service Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter the service name',
                  },
                }}
              />

              <URLInput
                label="URL"
                model="url"
                validations={{
                  presence: {
                    message: 'Please enter the service URL',
                  },
                }}
              />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={isNew ? 'Add Bookmark' : 'Save Changes'}
          isDisabled={!valid}
          onClick={save}
          type="primary"
          color="green"
          isLoading={isLoading}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const styles = {
  content: {
    padding: '1rem',
  },

  card: {
    '&.is-loading': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },

  item: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 44,
    borderBottom: `1px solid ${COLORS.divider}`,
    cursor: 'pointer !important',

    img: {
      opacity: 0.6,
    },

    '&.is-active img': {
      opacity: 1,
    },

    '&:hover': {
      background: COLORS.hover,
    },

    '&:last-of-type': {
      borderBottom: 'none',
    },

    '&.is-dragging': {
      borderRadius: 4,
      background: COLORS.white,
      boxShadow: SHADOW(3, COLORS.divider),

      // quick fix: when used inside a portal container, the placeholder element's position is not calculated correctly
      left: 'auto !important',
      right: '0 !important',
    },
  },

  hiddenInput: {
    display: 'none',
    WebkitAppearance: 'none',
  },

  label: {
    display: 'inline-block',
    marginLeft: '0.4em',
    fontWeight: 500,
    marginRight: 'auto',
  },

  checkboxWrapper: {
    alignSelf: 'stretch',
    width: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  checkbox: {
    '&:hover': {
      borderColor: `${tint(0.1, COLORS.green)} !important`,
      boxShadow: `0 0 0 2px ${tint(0.5, COLORS.green)} !important`,
    },
  },

  dragWrapper: {
    alignSelf: 'stretch',
    width: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: `1px solid ${COLORS.divider}`,

    '&:hover': {
      cursor: 'move',
      svg: { fill: COLORS.blue, opacity: 1 },
    },
  },

  dragGlyph: {
    opacity: 0.7,
    cursor: 'drag',
  },

  logo: {
    width: '2rem',
  },

  addButton: {
    marginTop: '1rem',
  },

  customRowButton: {
    marginRight: '0.5rem',
  },
}

export default BookmarksOverlay
