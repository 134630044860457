import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5-compat'
import { useMedia } from 'use-media'
import clsx from 'clsx'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'

import { daysTo, usDateShort } from '../../../utils/functions'
import { PlainSwitch } from '../../../components/Forms/Switch'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import ButtonGroup from '../../../components/ButtonGroup'
import Card from '../../../components/Card'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import GoalObjectiveInterventionStatus from '../../../components/Statuses/GoalObjectiveInterventionStatus'
import GridTable from '../../../components/GridTable'
import Label from '../../../components/Label'
import State from '../../../components/State'
import Status from '../../../components/Status'

import { DateCell, LastReviewCell, LastReviewDateScoreCell, LinkCell, NavLinkCell, ToggleCell, ToggleHeaderCell } from './TreeComponents'

import { TREATMENT_PLAN_VIEWS } from '../constants'

import { useTreatmentPlanStore } from '../store'
import SmartStatus from '../../../components/SmartStatus'

import { STATUSES, REVIEW_STATUSES } from './TreatmentPlanReviews'

export const TreatmentPlanDetails = (props: any) => {
  const currentView = useTreatmentPlanStore((state: any) => state.goalsObjectivesInterventionsView)

  const reviewsVisible = useTreatmentPlanStore((state: any) => state.reviewsVisible)
  const setReviewsVisible = useTreatmentPlanStore((state: any) => state.setReviewsVisible)
  const toggleReviewsVisible = useTreatmentPlanStore((state: any) => state.toggleReviewsVisible)

  const Tag: any = VIEWS?.[currentView] || TableView

  const isEmpty = size(props.data) === 0

  let titleLabelArray = []
  if (props.useGoals) titleLabelArray.push('Goals')
  if (props.useObjectives) titleLabelArray.push('Objectives')
  if (props.useInterventions) titleLabelArray.push('Interventions')

  const titleLabel = titleLabelArray.join(', ')

  return (
    <div className="grid gap-2">
      {!isEmpty && props.usePlanReviews && (
        <Card className="px-2 py-1.5 mt-1.5">
          <Flex gap="1rem" justifyContent="space-between">
            {currentView === TREATMENT_PLAN_VIEWS.cards && (
              <div className="flex items-center flex-nowrap">
                <Label isCompact label="Show Reviews" onClick={toggleReviewsVisible} className="!flex-[0_0_auto] cursor-pointer" />
                <PlainSwitch size={100} isChecked={reviewsVisible} onCheckedChange={setReviewsVisible} />
              </div>
            )}
          </Flex>
        </Card>
      )}

      {props.isLoading || isEmpty ? (
        <Card>
          <State
            title={titleLabel}
            icon="treatment_plans"
            emptyDescription={`No ${titleLabel} added to this treatment plan yet`}
            isLoading={props.isLoading}
            isEmpty={isEmpty}
            minHeight={200}
            emptyActions={props.goalActions}
          />
        </Card>
      ) : (
        <Tag {...props} />
      )}
    </div>
  )
}

const CardsView = (props: any) => {
  const {
    canEdit,
    data,
    setGoalAddLink,
    setGoalLink,
    setInterventionAddLink,
    setInterventionImportLink,
    setInterventionLink,
    setObjectiveAddLink,
    setObjectiveImportLink,
    setObjectiveLink,
    showGoalDates,
    showInterventionDates,
    showObjectiveDates,
    useGoals,
    useObjectives,
    useInterventions,
    usePlanReviews,
    goalsShowSettings,
    objectivesShowSettings,
    interventionsShowSettings,
  } = props

  const reviewsVisible = useTreatmentPlanStore((state: any) => state.reviewsVisible)

  const isDesktop = useMedia({ minWidth: 1500 })

  let titleLabelArray = []
  if (props.useGoals) titleLabelArray.push('Goals')
  if (props.useObjectives) titleLabelArray.push('Objectives')
  if (props.useInterventions) titleLabelArray.push('Interventions')

  const titleLabel = titleLabelArray.join(', ')

  return (
    <div className={clsx(reviewsVisible && isDesktop ? 'mt-2' : '-mt-4')}>
      {reviewsVisible && isDesktop && (
        <Card className="px-3 py-1.5 sticky top-2 z-[3] font-[600]">
          <div className="grid grid-cols-[1fr_220px_220px] mq1600:grid-cols-[1fr_280px_280px] mq1800:grid-cols-[1fr_340px_340px] gap-3">
            <div>{titleLabel}</div>
            {usePlanReviews && (
              <>
                <div className="pl-3 border-l border-0 border-solid border-divider text-center">Last Reviews</div>
                <div className="pl-3 border-l border-0 border-solid border-divider text-center">Next Reviews</div>
              </>
            )}
          </div>
        </Card>
      )}

      {data?.map?.((goal: any, goalIndex: number) => {
        const objectiveAddLinkProps = setObjectiveAddLink
          ? setObjectiveAddLink(goal, `Objective ${goalIndex + 1}.${size(goal?.treatment_objectives) + 1}`)
          : undefined

        return (
          <React.Fragment key={goal.id}>
            <CardItem
              canEdit={canEdit}
              isDesktop={isDesktop}
              data={goal}
              showDates={showGoalDates}
              identifier={`Goal ${goalIndex + 1}`}
              to={setGoalLink?.(goal.id)}
              showSettings={goalsShowSettings}
            />

            <div className="ml-3 pl-4 border-l border-0 border-solid border-divider">
              {useObjectives &&
                goal.treatment_objectives?.map((objective: any, objectiveIndex: number) => {
                  const interventionAddLinkProps = setInterventionAddLink
                    ? setInterventionAddLink(
                        objective,
                        `Intervention ${goalIndex + 1}.${objectiveIndex + 1}.${size(objective?.treatment_interventions) + 1}`,
                      )
                    : undefined

                  return (
                    <React.Fragment key={objective.id}>
                      <CardItem
                        canEdit={canEdit}
                        isDesktop={isDesktop}
                        data={objective}
                        showDates={showObjectiveDates}
                        identifier={`Obj ${goalIndex + 1}.${objectiveIndex + 1}`}
                        to={setObjectiveLink?.(objective.id)}
                        showSettings={objectivesShowSettings}
                      />

                      <div className="ml-3 pl-4 border-l border-0 border-solid border-divider">
                        {useInterventions &&
                          objective?.treatment_interventions?.map((intervention: any, interventionIndex: number) => {
                            return (
                              <React.Fragment key={intervention.id}>
                                <CardItem
                                  canEdit={canEdit}
                                  isDesktop={isDesktop}
                                  data={intervention}
                                  showDates={showInterventionDates}
                                  identifier={`Int ${goalIndex + 1}.${objectiveIndex + 1}.${interventionIndex + 1}`}
                                  to={setInterventionLink?.(intervention.id)}
                                  showSettings={interventionsShowSettings}
                                />
                              </React.Fragment>
                            )
                          })}

                        {useInterventions && canEdit && (
                          <div>
                            <Button
                              as={Link}
                              label="Add Intervention"
                              glyph="add"
                              size={100}
                              type="minimal"
                              display="inline-flex"
                              className="mt-3"
                              {...interventionAddLinkProps}
                            />

                            <Button
                              as={Link}
                              label="Import Intervention from Treatment Book under this Objective…"
                              glyph="add_file"
                              size={100}
                              type="minimal"
                              display="inline-flex"
                              className="ml-1"
                              link={setInterventionImportLink ? setInterventionImportLink(goal, objective) : undefined}
                            />
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )
                })}

              {useObjectives && canEdit && (
                <div>
                  <Button
                    as={Link}
                    label="Add Objective"
                    glyph="add"
                    size={100}
                    type="default"
                    display="inline-flex"
                    className="mt-3"
                    {...objectiveAddLinkProps}
                  />

                  <Button
                    as={Link}
                    label="Import Objectives from Treatment Book under this Goal…"
                    glyph="add_file"
                    size={100}
                    type="default"
                    display="inline-flex"
                    className="ml-2"
                    link={setObjectiveImportLink ? setObjectiveImportLink(goal) : undefined}
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        )
      })}

      {useGoals && canEdit && <div className="pt-2">{props.goalActions}</div>}
    </div>
  )
}

const CardItem = (props: any) => {
  const { data, identifier, to, isDesktop, canEdit, showDates, usePlanReviews, showSettings } = props

  const lastReview = data?.last_treatment_review
  const nextReview = data?.next_treatment_review

  const reviewsVisible = useTreatmentPlanStore((state: any) => state.reviewsVisible)

  const mainContent = (
    <>
      <Flex centerY gap="0.25rem">
        {identifier && <Status small label={identifier} color="textMuted" className="!text-text" />}
        <GoalObjectiveInterventionStatus small status={data.status} />

        {showDates && (
          <div className="!ml-auto">
            <Flex centerY gap="0.75rem">
              {showSettings?.completion_date && data.status === 'completed' && data.completed_at && (
                <DateItem label="Completion" value={data.completed_at} />
              )}
              {showSettings?.start_date && <DateItem label="Start" value={data.started_at} />}
              {showSettings?.target_date && <DateItem label="Target" value={data.target_at} />}
            </Flex>
          </div>
        )}
      </Flex>

      <h3 className="text-[1.04rem] font-[700] mt-1 text-blue-500 cursor-pointer">{data.name}</h3>
      {data.description && <div className="text-text-muted whitespace-pre-wrap">{data.description}</div>}
    </>
  )

  if (isDesktop) {
    return (
      <div
        className={clsx(
          'pt-4',
          reviewsVisible && 'grid grid-cols-[1fr_220px_220px] mq1600:grid-cols-[1fr_280px_280px] mq1800:grid-cols-[1fr_340px_340px] gap-3',
        )}
      >
        <Card
          to={to}
          as={to ? NavLink : 'div'}
          className="block px-2 py-2 border-solid border-transparent [&.active]:!border-blue-300 [&.active]:ring-2 ring-blue-100 text-text"
        >
          {mainContent}
        </Card>

        {usePlanReviews && reviewsVisible && (
          <>
            <ReviewCard canEdit={canEdit} reference={data} data={lastReview} type="last" />
            <ReviewCard canEdit={canEdit} reference={data} data={nextReview} type="next" hideCreate={!lastReview} />
          </>
        )}
      </div>
    )
  }

  const Tag: any = to ? NavLink : 'div'

  return (
    <div className="pt-4">
      <Card testKey={`${snakeCase(data.name)}`}>
        <Tag
          to={to}
          className={clsx(
            'block border-solid border-transparent [&.active]:!border-blue-300 [&.active]:ring-2 ring-blue-100 text-text',
            reviewsVisible ? 'rounded-[6px_6px_0_0]' : 'rounded-[6px]',
          )}
        >
          <div className="p-3">{mainContent}</div>
        </Tag>

        {reviewsVisible && (
          <div className="grid grid-cols-1 mq900:grid-cols-2 border-t border-0 border-solid border-divider bg-[#F7F8FB]">
            <ReviewCard
              canEdit={canEdit}
              plain
              reference={data}
              data={lastReview}
              type="last"
              className="shadow-[0_1px_0] mq900:shadow-[1px_0_0] !shadow-divider"
            />
            <ReviewCard canEdit={canEdit} plain reference={data} data={nextReview} type="next" hideCreate={!lastReview} />
          </div>
        )}
      </Card>
    </div>
  )
}

const ReviewCard = (props: any) => {
  const { data, reference, type, plain, className, hideCreate, canEdit } = props

  const { timezone } = useSettings()

  const to = data ? `reviews/${data.id}` : undefined

  const classes = clsx(
    'block px-2 py-2 border-solid border-transparent [&.active]:!border-blue-300 [&.active]:ring-2 ring-blue-100 text-text',
    !to && '!bg-white !bg-opacity-40',
    className,
  )

  const days = daysTo(data?.dated_at, timezone)

  let daysText =
    typeof days === 'number' &&
    (days === -1
      ? `yesterday`
      : days < 0
      ? `${Math.abs(days)} days ago`
      : days === 0
      ? 'today'
      : days === 1
      ? 'tomorrow'
      : `in ${days} days`)

  const mainContent: any = (
    <>
      {data ? (
        <div>
          <Flex centerY gap="0.25rem">
            <Status small label={`${type} review`} color="textMuted" className="!text-text" />
            <SmartStatus small statuses={REVIEW_STATUSES} status={data?.status || 'draft'} />
            {data?.score && <SmartStatus small statuses={STATUSES} status={data.score} />}

            {type === 'next' && typeof days === 'number' && data?.status !== 'completed' && days < 2 && (
              <>
                <Status small glyph={days > 0 ? 'warning' : 'circle_error'} color={days > 0 ? 'orange' : 'red'} label={`Due ${daysText}`} />
              </>
            )}

            {data?.dated_at && <DateItem value={data.dated_at} />}
          </Flex>

          <h3 className="text-[1.04rem] font-[700] mt-1 text-blue-500 cursor-pointer">{data.name}</h3>
          {data.notes && <div className="text-text-muted whitespace-pre-wrap">{data.notes}</div>}
        </div>
      ) : (
        <div className="grid gap-2">
          <Status small label={`${type} review`} color="textMuted" className="!text-text" />

          <div className="text-text-muted italic text-[0.92rem]">
            {type === 'last' ? 'Not yet added' : type === 'next' ? 'Not yet planned' : 'Not yet added'}
          </div>

          {!hideCreate && canEdit && (
            <Button
              as={Link}
              label={type === 'last' ? 'Add Review' : type === 'next' ? 'Plan Next Review' : 'Add Review'}
              type="primary"
              glyph="add"
              size={100}
              link="reviews/new"
              state={{
                data: {
                  _dated_in_one_month: type === 'next',
                  status: type === 'last' ? 'completed' : type === 'next' ? 'draft' : undefined,
                  reference,
                  treatment_goal: reference?.type === 'treatment_goal' ? reference : undefined,
                  treatment_objective: reference?.type === 'treatment_objective' ? reference : undefined,
                  treatment_intervention: reference?.type === 'treatment_intervention' ? reference : undefined,
                },
              }}
            />
          )}
        </div>
      )}
    </>
  )

  if (plain) {
    const Tag = to ? NavLink : 'div'

    return (
      <Tag
        to={to}
        className={clsx(
          'block border-solid border-transparent [&.active]:!border-blue-300 [&.active]:ring-2 ring-blue-100 text-text p-3',
          type === 'last' ? 'mq900:rounded-[0_0_0_6px]' : 'rounded-[0_0_6px_6px] mq900:rounded-[0_0_6px_0]',
          className,
        )}
      >
        {mainContent}
      </Tag>
    )
  }

  return (
    <Card to={to} as={to ? NavLink : 'div'} className={classes}>
      {mainContent}
    </Card>
  )
}

const DateItem = ({ label, value, after }: any) => {
  const { timezone } = useSettings()

  if (!label && !value) return null

  return (
    <div className="flex items-center flex-nowrap text-[0.9rem] tabular-nums min-w-[120px] text-text">
      <Glyph glyph="date" size="1em" className="mr-1" />
      {label && <div className="font-[500] mr-0.5">{label}:</div>}
      <div className={!value && 'text-text-strongly-muted'}>
        {value ? usDateShort(value, timezone) : '–'}
        {after}
      </div>
    </div>
  )
}

const TableView = (props: any) => {
  const {
    canEdit,
    data,
    permissionBase,
    planId,
    setGoalLink,
    setInterventionAddLink,
    setInterventionImportLink,
    setInterventionLink,
    setObjectiveAddLink,
    setObjectiveImportLink,
    setObjectiveLink,
    showGoalDates,
    showObjectiveDates,
    showInterventionDates,
  } = props

  const [openGoalIds, setOpenGoalIds] = React.useState<any>([])
  const [checkedGoalIds, setCheckedGoalIds] = React.useState<any>([])

  const [openObjectiveIds, setOpenObjectiveIds] = React.useState<any>([])
  const [checkedObjectiveIds, setCheckedObjectiveIds] = React.useState<any>([])
  const [checkedInterventionIds, setCheckedInterventionIds] = React.useState<any>([])

  const { goalIds, objectiveIds, interventionIds } = React.useMemo(() => {
    const result: any = { goalIds: [], objectiveIds: [], interventionIds: [] }

    if (!data) return result

    for (const goal of data) {
      result.goalIds.push(goal.id)

      for (const objective of goal.treatment_objectives) {
        result.objectiveIds.push(objective.id)

        for (const intervention of objective?.treatment_interventions) {
          result.interventionIds.push(intervention.id)
        }
      }
    }

    return result
  }, [data])

  const isAllGoalsOpen = size(openGoalIds) === size(goalIds)

  const toggleAllOpen = () => {
    setOpenGoalIds(goalIds)
    setOpenObjectiveIds(objectiveIds)
  }

  const toggleAllClosed = () => {
    setOpenGoalIds([])
    setOpenObjectiveIds([])
  }

  const openGoal = (goalId: any) => {
    setOpenGoalIds((c: any) => {
      if (!c.includes(goalId)) {
        return [...c, goalId]
      } else {
        return c
      }
    })
  }

  const toggleGoalOpen = (goalId: any) => {
    setOpenGoalIds((c: any) => {
      if (c.includes(goalId)) {
        return c.filter((id: any) => id !== goalId)
      } else {
        return [...c, goalId]
      }
    })
  }

  const openObjective = (objectiveId: any) => {
    setOpenObjectiveIds((c: any) => {
      if (!c.includes(objectiveId)) {
        return [...c, objectiveId]
      } else {
        return c
      }
    })
  }

  const toggleObjectiveOpen = (objectiveId: any) => {
    setOpenObjectiveIds((c: any) => {
      if (c.includes(objectiveId)) {
        return c.filter((id: any) => id !== objectiveId)
      } else {
        return [...c, objectiveId]
      }
    })
  }

  const showDates = showGoalDates || showObjectiveDates || showInterventionDates

  const templateColumns = React.useMemo(() => {
    // TODO @Andrei: refactor this to use a more elegant solution
    const columns: string[] = []

    // add checkbox column
    // if (canEdit) columns.push('35px')

    // add toggle, status, and name columns
    columns.push('110px 250px 110px minmax(260px, 3fr)')

    // add edit columns
    if (canEdit) columns.push('170px 170px')

    // add last review columns
    columns.push('320px 240px')

    // add date columns
    if (showDates) {
      columns.push('repeat(3, minmax(95px, 1fr))')
    }

    return columns.join(' ')
  }, [canEdit, showDates])

  return (
    <Card>
      <GridTable
        testKey="goals_objectives_interventions_table"
        useBanding={false}
        templateColumns={templateColumns}
        className="text-[0.92rem]"
      >
        <GridTable.Header className="relative z-[10]">
          <ToggleHeaderCell
            className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[5] mq900:left-0"
            isAllOpen={isAllGoalsOpen}
            onClick={() => {
              if (isAllGoalsOpen) {
                toggleAllClosed()
              } else {
                toggleAllOpen()
              }
            }}
          />

          <GridTable.Cell className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[5] mq900:left-[110px]">
            Name
          </GridTable.Cell>
          <GridTable.Cell>Status</GridTable.Cell>
          <GridTable.Cell>Description</GridTable.Cell>
          {canEdit && <GridTable.Cell>Import</GridTable.Cell>}
          {canEdit && <GridTable.Cell>Add</GridTable.Cell>}
          <GridTable.Cell>Last Review</GridTable.Cell>
          <GridTable.Cell>Last Review Date & Score</GridTable.Cell>
          {showDates && (
            <>
              <GridTable.Cell>Start</GridTable.Cell>
              <GridTable.Cell>Target</GridTable.Cell>
              <GridTable.Cell>Completion</GridTable.Cell>
            </>
          )}
        </GridTable.Header>

        {data?.map?.((goal: any, goalIndex: number) => {
          const isOpen = openGoalIds.includes(goal.id)
          const hasChildren = size(goal.treatment_objectives) > 0

          const objectiveAddLinkProps = setObjectiveAddLink
            ? setObjectiveAddLink(goal, `Objective ${goalIndex + 1}.${size(goal?.treatment_objectives) + 1}`)
            : undefined

          return (
            <>
              <GridTable.Row>
                {/* <GridTable.Cell className="cursor-pointer" onClick={() => toggleGoalChecked(goal.id)}>
                      <RadioCheckElement type="checkbox" isChecked={isChecked} />
                    </GridTable.Cell> */}

                <ToggleCell
                  className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[3] mq900:left-0"
                  isOpen={isOpen}
                  onClick={() => toggleGoalOpen(goal.id)}
                  label={`Goal ${goalIndex + 1}`}
                  hasChildren={hasChildren}
                />

                <NavLinkCell
                  className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[3] mq900:left-[110px]"
                  to={setGoalLink?.(goal.id)}
                  label={goal.name || '–'}
                />

                <GridTable.Cell>
                  <GoalObjectiveInterventionStatus small status={goal.status} />
                </GridTable.Cell>

                <GridTable.Cell className={clsx(!goal.description && 'text-text-strongly-muted', 'w-full truncate')}>
                  {goal.description || '–'}
                </GridTable.Cell>

                {canEdit && (
                  <>
                    <LinkCell
                      label="Import Objectives…"
                      glyph="add_file"
                      to={setObjectiveImportLink ? setObjectiveImportLink(goal) : undefined}
                      onClick={() => !hasChildren && openGoal(goal.id)}
                    />

                    <LinkCell
                      label="Add Objective"
                      glyph="add"
                      to={objectiveAddLinkProps?.link}
                      state={objectiveAddLinkProps?.state}
                      onClick={() => !hasChildren && openGoal(goal.id)}
                    />
                  </>
                )}

                <LastReviewCell reference={goal} canEdit={canEdit} planId={planId} permissionBase={permissionBase} />
                <LastReviewDateScoreCell reference={goal} />

                {showDates && (
                  <>
                    <DateCell isApplicable={showGoalDates} value={goal.started_at} />
                    <DateCell isApplicable={showGoalDates} value={goal.target_at} />
                    <DateCell isApplicable={showGoalDates} value={goal.completed_at} />
                  </>
                )}
              </GridTable.Row>

              {isOpen &&
                goal.treatment_objectives?.map((objective: any, objectiveIndex: number) => {
                  const isOpen = openObjectiveIds.includes(objective.id)
                  const hasChildren = size(objective?.treatment_interventions) > 0

                  const interventionAddLinkProps = setInterventionAddLink
                    ? setInterventionAddLink(
                        objective,
                        `Intervention ${goalIndex + 1}.${objectiveIndex + 1}.${size(objective?.treatment_interventions) + 1}`,
                      )
                    : undefined

                  return (
                    <>
                      <GridTable.Row>
                        {/* {canEdit && (
                              <GridTable.Cell className="cursor-pointer" onClick={() => toggleObjectiveChecked(objective.id)}>
                                <RadioCheckElement type="checkbox" isChecked={isChecked} />
                              </GridTable.Cell>
                            )} */}

                        <ToggleCell
                          className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[3] mq900:left-0 !pl-6"
                          isOpen={isOpen}
                          onClick={() => toggleObjectiveOpen(objective.id)}
                          label={`Obj ${goalIndex + 1}.${objectiveIndex + 1}`}
                          hasChildren={hasChildren}
                        />

                        <NavLinkCell
                          className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[3] mq900:left-[110px]"
                          to={setObjectiveLink?.(objective.id)}
                          label={objective.name || '–'}
                        />

                        <GridTable.Cell>
                          <GoalObjectiveInterventionStatus small status={objective.status} />
                        </GridTable.Cell>

                        <GridTable.Cell className={clsx(!objective.description && 'text-text-strongly-muted', 'w-full truncate')}>
                          {objective.description || '–'}
                        </GridTable.Cell>

                        {canEdit && (
                          <>
                            <LinkCell
                              label="Import Interventions"
                              glyph="add_file"
                              to={setInterventionImportLink ? setInterventionImportLink(goal, objective) : undefined}
                              onClick={() => !hasChildren && openObjective(objective.id)}
                            />
                            <LinkCell
                              label="Add Intervention"
                              glyph="add"
                              to={interventionAddLinkProps?.link}
                              state={interventionAddLinkProps?.state}
                              onClick={() => !hasChildren && openObjective(objective.id)}
                            />
                          </>
                        )}

                        <LastReviewCell reference={objective} canEdit={canEdit} planId={planId} permissionBase={permissionBase} />
                        <LastReviewDateScoreCell reference={objective} />

                        {showDates && (
                          <>
                            <DateCell isApplicable={showObjectiveDates} value={objective.started_at} />
                            <DateCell isApplicable={showObjectiveDates} value={objective.target_at} />
                            <DateCell isApplicable={showObjectiveDates} value={objective.completed_at} />
                          </>
                        )}
                      </GridTable.Row>

                      {isOpen &&
                        objective?.treatment_interventions?.map((intervention: any, interventionIndex: number) => {
                          return (
                            <>
                              <GridTable.Row>
                                {/* {canEdit && (
                                      <GridTable.Cell className="cursor-pointer" onClick={() => toggleInterventionChecked(intervention.id)}>
                                        <RadioCheckElement type="checkbox" isChecked={isChecked} />
                                      </GridTable.Cell>
                                    )} */}

                                <ToggleCell
                                  className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[3] mq900:left-0 !pl-10"
                                  isOpen={isOpen}
                                  label={`Int ${goalIndex + 1}.${objectiveIndex + 1}.${interventionIndex + 1}`}
                                  hasChildren={false}
                                />

                                <NavLinkCell
                                  className="mq900:sticky mq900:shadow-x-hard-2 mq900:!bg-white mq900:z-[3] mq900:left-[110px]"
                                  to={setInterventionLink?.(intervention.id)}
                                  label={intervention.name || '–'}
                                />

                                <GridTable.Cell>
                                  <GoalObjectiveInterventionStatus small status={intervention.status} />
                                </GridTable.Cell>

                                <GridTable.Cell
                                  className={clsx(!intervention.description && 'text-text-strongly-muted', 'w-full truncate')}
                                >
                                  {intervention.description || '–'}
                                </GridTable.Cell>

                                {canEdit && (
                                  <>
                                    <GridTable.Cell />
                                    <GridTable.Cell />
                                  </>
                                )}

                                <LastReviewCell
                                  reference={intervention}
                                  canEdit={canEdit}
                                  planId={planId}
                                  permissionBase={permissionBase}
                                />
                                <LastReviewDateScoreCell reference={intervention} />

                                {showDates && (
                                  <>
                                    <DateCell isApplicable={showInterventionDates} value={intervention.started_at} />
                                    <DateCell isApplicable={showInterventionDates} value={intervention.target_at} />
                                    <DateCell isApplicable={showInterventionDates} value={intervention.completed_at} />
                                  </>
                                )}
                              </GridTable.Row>
                            </>
                          )
                        })}
                    </>
                  )
                })}
            </>
          )
        })}
      </GridTable>
    </Card>
  )
}

const VIEWS = {
  [TREATMENT_PLAN_VIEWS.table]: TableView,
  [TREATMENT_PLAN_VIEWS.cards]: CardsView,
}
