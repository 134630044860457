import React from 'react'
import size from 'lodash/size'
import { useRouteMatch } from 'react-router-dom'

import { mapToArray } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Divider, Grid, Dropdown, DropdownItem, Page, HelpTagIframe } from '@behavehealth/components'
import { CustomNotesDataTable } from '@behavehealth/constructs/CustomNotes/CustomNotesDataTable'

const AddDropdown = ({ templates }: any) => {
  const match = useRouteMatch()

  const { isBehave, isManagement } = useSettings()

  return (
    <Dropdown
      label="Add Grievance Note…"
      glyph="add"
      buttonType="primary"
      buttonSize={300}
      permission={isBehave || isManagement}
      featureFlagV2="grievance_notes"
    >
      {mapToArray(templates).map((template) => (
        <DropdownItem
          key={template.id}
          label={template.name}
          glyph="documents"
          color="paleBlue"
          link={{
            pathname: `${match.url}/new`,
            parent: match,
            data: template,
          }}
        />
      ))}

      <Divider />

      <DropdownItem
        label="Empty Grievance Note"
        glyph="add"
        color="paleBlue"
        link={{
          pathname: `${match.url}/new`,
          parent: match,
          data: {
            name: 'New Grievance Note',
          },
        }}
      />
    </Dropdown>
  )
}

const pageConfig = {
  feature: 'grievance_notes',
  help: <HelpTagIframe id="custom_notes" />,
}

type Props = {
  canCreate: boolean
}

const RootGrievanceNotes: React.FC<Props> = () => {
  const match = useRouteMatch()
  const id = match.params?.resource_id

  const { isBehave, isManagement } = useSettings()

  const tableProps = useDataTable({
    name: ['client', id, 'custom_notes'],
    endpoint: `/residents/${id}/custom_notes`,
    params: { variant: 'grievance' },
    updateDeleteEndpoint: '/custom_notes',
    localStorageKey: 'grievance_notes',
  })

  const { data: templates, isLoading: isLoadingTemplates } = useGet({
    name: ['custom_note_templates', { variant: 'grievance' }],
    url: `/custom_note_templates`,
    params: { variant: 'grievance' },
  })

  // const isTemplatesEmpty = size(templates) === 0

  // if (!isLoadingTemplates && isTemplatesEmpty) {
  //   return (
  //     <>
  //       <Page
  //         isEmpty={true}
  //         emptyDescription="No custom note templates have been added yet. Go to Settings to create the first template."
  //         actions={
  //           <Button
  //             size={300}
  //             label="Manage Templates"
  //             glyph="settings"
  //             type="primary"
  //             link="/settings/custom-note-templates/grievance"
  //             permission="settings.grievance_custom_note_templates.view"
  //             featureFlagV2="grievance_note_templates"
  //           />
  //         }
  //         {...pageConfig}
  //       />
  //     </>
  //   )
  // }

  return (
    <Page
      plan="basic"
      actions={
        <>
          <Button
            size={300}
            glyph="settings"
            type="default"
            label="Manage Templates"
            link="/settings/custom-note-templates/grievance"
            featureFlagV2="grievance_note_templates"
            permission="settings.grievance_custom_note_templates.view"
          />

          <AddDropdown templates={templates} />
        </>
      }
      {...pageConfig}
    >
      <Grid>
        <CustomNotesDataTable
          {...tableProps}
          title="Grievance Notes"
          to={(data: any) => ({
            pathname: `${match.url}/${data.id}`,
            parent: match,
          })}
          duplicatePermission={isBehave || isManagement}
          duplicateLink={(record: any) => {
            const {
              client,
              employees,
              ended_at,
              name,
              notes,
              office,
              organization,
              portal_settings,
              house,
              reference,
              sections,
              settings,
              started_at,
              supervisor,
              tags,
              variant,
            } = record

            return {
              pathname: `${match.url}/new`,
              parent: match,
              data: {
                status: 'draft',
                client,
                employees,
                ended_at,
                name,
                notes,
                office,
                organization,
                organization_id: organization?.id,
                portal_settings,
                house,
                house_id: house?.id,
                reference,
                sections,
                settings,
                started_at,
                supervisor,
                tags,
                variant,
              },
            }
          }}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: isBehave || isManagement,
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export const GrievanceNotes = withPageError(withMarketing(RootGrievanceNotes, pageConfig))
