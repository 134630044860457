import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Notifications from '../../modules/notifications'

import { useSimpleOverlay } from '../../hooks/useSimpleOverlay'
import { useUpdate } from '../../hooks/useNewAPI'

import { InsuranceReportStatusSelect } from './components/InsuranceReportStatusSelect'

const RootInsuranceReportsStatusOverlay = (props: any) => {
  const { form, isInvalid, onValidationUpdate } = useSimpleOverlay({})

  const [isSaving, setIsSaving] = React.useState(false)
  const [error, setError] = React.useState(null)

  const { selectedInsuranceReports } = props

  const { timezone } = useSettings()

  const { mutateAsync: updateClaims }: any = useUpdate({
    name: ['insurance_clearing_house_reports'],
    url: `/insurance_clearing_house_reports/${selectedInsuranceReports?.map((o: any) => o.id).join(',')}`,
    invalidateKeys: ['insurance_clearing_house_reports'],
  })

  if (size(selectedInsuranceReports) === 0) {
    throw new Error('Select one or more reports to update the Status')
  }

  const save = async () => {
    setIsSaving(true)
    const formData = form.current.getFormValue()

    try {
      await updateClaims(formData)

      Notifications.send('Successfully updated Reports', 'positive')

      props.onClose()
    } catch (error) {
      console.error(error)
      setError(error?.error?.data?.errors?.[0])
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={props.onClose} position="center">
      <Overlay.Header icon="accounting" title="Batch Update Clearing House Reports" />

      <Overlay.Content>
        <Form isEditable getForm={form} timezone={timezone} onValidationUpdate={onValidationUpdate}>
          <Section>
            <FormSection maxWidth="100%">
              <Alert contrast type="default" glyph="info">
                Update the Status for the selected {countWord('Reports', size(selectedInsuranceReports))}.
              </Alert>

              <InsuranceReportStatusSelect
                label="Update Status To:"
                model="status"
                validations={{
                  presence: {
                    message: 'Please select a Status',
                  },
                }}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {error && (
          <Alert contrast type="negative" glyph="warning">
            {error}
          </Alert>
        )}

        <Button
          label={`Update ${countWord('Reports', size(selectedInsuranceReports))}`}
          glyph="check"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={save}
          isDisabled={isInvalid}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const InsuranceReportsStatusOverlay = withOverlayError(RootInsuranceReportsStatusOverlay)
