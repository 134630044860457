import React from 'react'
import clsx from 'clsx'
import { lighten } from 'polished'

import { COLORS, HARD_SHADOW } from '../../theme'

import WorkflowStepBadge from './WorkflowStepBadge'
import WorkflowTitle from './WorkflowTitle'
import Status from '../Status'

import { CSS_VALUES } from './css'
import { WorkflowContext, WorkflowPanelContext } from './context'

const WorkflowHeader = (props: any) => {
  const { after, children, graphic, isOpen, onClick, step, title, isActive } = props

  const { steps, showStepNumber, manualNavigationDisabled, requiredSteps, stepStatuses }: any = React.useContext(WorkflowContext)

  const { hasPermission }: any = React.useContext(WorkflowPanelContext)

  const stepNumber = steps.indexOf(step) + 1

  const isRequired = requiredSteps?.includes?.(step)
  const isCompleted = stepStatuses?.[step] === 'completed'

  const classNames = clsx({
    'is-open': isOpen,
    'is-active': !isOpen && isActive && onClick,
    'has-graphic': graphic || showStepNumber,
    'manual-navigation-disabled': manualNavigationDisabled,
  })

  return (
    <header css={styles.root} onClick={isActive && !manualNavigationDisabled ? onClick : undefined} className={classNames}>
      {showStepNumber && (
        <div css={styles.graphic}>
          <WorkflowStepBadge stepNumber={stepNumber} isOpen={isOpen} />
        </div>
      )}

      {graphic && <div css={styles.graphic}>{graphic}</div>}

      <div css={styles.content} className={classNames}>
        {title && <WorkflowTitle title={title} />}
        {children}
      </div>

      {(after || isRequired) && (
        <div css={styles.after}>
          {isRequired ? (
            <>
              {!hasPermission && <Status label="No Permission" color="orange" glyph="warning" />}
              {isCompleted ? <Status label="Completed" color="green" /> : <Status label="Required" color="red" />}
            </>
          ) : (
            after
          )}
        </div>
      )}
    </header>
  )
}

WorkflowHeader.defaultProps = {
  isActive: true,
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    minHeight: '2.5rem',
    background: COLORS.white,

    '&.is-active': {
      cursor: 'pointer',

      '&:hover': {
        background: lighten(0.4, COLORS.hover),
      },

      '.workflow-title': {
        color: COLORS.blue,
      },
    },

    '&.is-open': {
      cursor: 'default',
      boxShadow: `
        0 1px 0 ${COLORS.divider},
        ${HARD_SHADOW(2)}
      `,

      '.workflow-title': {
        color: COLORS.text,
      },
    },

    '&.manual-navigation-disabled': {
      cursor: 'default',

      '.workflow-title': {
        color: COLORS.text,
      },

      '&:hover': {
        background: COLORS.white,
      },
    },
  },

  content: {
    display: 'grid',
    gridGap: '0.2rem',
    flex: '999 1 auto',
    padding: `${CSS_VALUES.paddingY} ${CSS_VALUES.paddingX}`,

    '&.has-graphic': {
      paddingLeft: 0,
    },
  },

  graphic: {
    width: CSS_VALUES.baseline,
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
  },

  after: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    padding: `${CSS_VALUES.paddingY} ${CSS_VALUES.paddingX}`,

    '& > *': {
      marginLeft: '0.5rem',
    },
  },
}

export default WorkflowHeader
