import React from 'react'

import { countWord } from '../../../../utils/functions'
import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

export const EventsTable = ({ onSelect, dependentValue, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-events'],
    endpoint: '/events',
    params: { event_type: 'event' },
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_events_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Title',
        id: 'title',
        model: 'title',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell testKey="main_cell" id={data.id} value={value} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        width: 280,
        model: 'public_description',
        title: 'Public Description',
        type: 'rich_text',
      },
      {
        title: 'Date & Time',
        model: 'started_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable
      {...tableProps}
      {...rest}
      icon="calendar"
      title="Event"
      columns={columns}
      // filtersConfig={FILTERS.locations}
      // searchBarFilterKey="name"
    />
  )
}
