import React from 'react'

import { withFormContext } from '../../../components/Forms/context'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import Grid from '../../../components/Grid'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'

const ClaimInstitutionalProviders = ({ className, tenant }: any) => (
  <>
    <Card className={className}>
      <CardHeader>
        <CardTitle title="Providers" css={styles.cardTitle} />
      </CardHeader>

      <CardContent css={styles.cardContent}>
        <Grid gap="1rem">
          <ObjectSelector
            label="Billing Provider"
            model="billing_provider"
            icon="staff_credentials"
            type="company.organization_providers"
            dependentValue={tenant?.id}
            selectTitle={(data) => `${data?.credential} (${data?.license_number})`}
            selectDescription={(data) => data?.organization}
            validations={{
              presence: {
                message: 'Please select a Billing Provider',
              },
            }}
          />

          <ObjectSelector
            label="Rendering Provider"
            model="rendering_provider"
            icon="staff_credentials"
            type="company.organization_providers"
            dependentValue={tenant?.id}
            selectTitle={(data) => `${data.credential} (${data.license_number})`}
            selectDescription={(data) => (data.level === 'organization' ? data.organization : `${data.first_name} ${data.last_name}`)}
          />

          <ObjectSelector
            label="Attending Provider"
            model="attending_provider"
            icon="staff_credentials"
            type="company.organization_providers"
            dependentValue={tenant?.id}
            selectTitle={(data) => `${data.credential} (${data.license_number})`}
            selectDescription={(data) => (data.level === 'organization' ? data.organization : `${data.first_name} ${data.last_name}`)}
          />

          <ObjectSelector
            label="Referring Provider"
            model="referring_provider"
            icon="staff_credentials"
            type="company.organization_providers"
            dependentValue={tenant?.id}
            selectTitle={(data) => `${data.credential} (${data.license_number})`}
            selectDescription={(data) => (data.level === 'organization' ? data.organization : `${data.first_name} ${data.last_name}`)}
          />
        </Grid>
      </CardContent>
    </Card>
  </>
)

const styles = {
  cardTitle: {
    fontSize: '1rem',
  },

  cardContent: {
    height: '100%',
  },
}

export default withFormContext(ClaimInstitutionalProviders)
