import React from 'react'
import size from 'lodash/size'

import useCopyToClipboard from '../../hooks/useCopyToClipboard'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Label from '../../components/Label'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SummonOverlay from '../../components/SummonOverlay'

import { APP_URLS } from '../MyAccount/utils'
import { COLORS } from '../../theme'

const NEW_LINE = `%0A%0A`

const SOCIAL_LINKS: any = {
  email: {
    label: 'Email',
    glyph: 'email',
    color: COLORS.gray,
    getWebUrl: ({ url, title, message }: any) => `mailto:?cc=community@behavehealth.com&subject=${title}&body=${url + NEW_LINE + message}`,
  },
  gmail: {
    label: 'Gmail',
    icon: 'gmail',
    color: COLORS.gray,
    getWebUrl: ({ url, title, message }: any) =>
      `https://mail.google.com/mail/u/0/?ui=2&tf=cm&fs=1&su=${title}&body=${url + NEW_LINE + message}`,
  },
  outlook: {
    label: 'Outlook',
    icon: 'outlook',
    color: COLORS.blue,
    getWebUrl: ({ url, title, message }: any) =>
      `https://outlook.live.com/mail/0/deeplink/compose?popoutv2=1&subject=${title}&body=${url + NEW_LINE + message}`,
  },
  sms: {
    label: 'Text Message',
    glyph: 'sms',
    color: COLORS.paleBlue,
    getWebUrl: ({ url, title, message }: any) => `sms:&body=${title + NEW_LINE + url + NEW_LINE + message}`,
  },
  linkedin: {
    label: 'Linkedin',
    glyph: 'linkedin',
    color: COLORS.linkedin,
    getWebUrl: ({ url, title, message }: any) =>
      `https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}&summary=${message}`,
    getAppUrl: ({ url, title, message }: any) => `linkedin://sharing/share-offsite/?url=${url}&title=${title}&summary=${message}`,
  },
  facebook: {
    label: 'Facebook',
    glyph: 'facebook',
    color: COLORS.facebook,
    getWebUrl: ({ url, title, message }: any) => `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title + NEW_LINE + message}`,
    getAppUrl: ({ url, title, message }: any) =>
      `fb://facewebmodal/f?href=https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title + NEW_LINE + message}`,
  },
  whatsapp: {
    label: 'Whatsapp',
    glyph: 'whatsapp',
    color: COLORS.whatsapp,
    getWebUrl: ({ url, title, message }: any) => `https://api.whatsapp.com/send?text=${title + NEW_LINE + url + NEW_LINE + message}`,
  },
  twitter: {
    label: 'Twitter X',
    glyph: 'twitterx',
    color: COLORS.black,
    getWebUrl: ({ url, title, message }: any) => `https://twitter.com/intent/tweet?text=${title + NEW_LINE + url + NEW_LINE + message}`,
    getAppUrl: ({ url, title, message }: any) => `twitter://post?message=${title + NEW_LINE + url + NEW_LINE + message}`,
  },
}

export const CommunityProfileShare = (props: any) => {
  const { profiles, activeProfileId, label = 'Share…' } = props

  if (size(profiles) === 0) return null

  return (
    <SummonOverlay overlay={<ShareOverlay profiles={profiles} activeProfileId={activeProfileId} />}>
      <Button label={label} size={200} glyph="share" type="primary" color="green" />
    </SummonOverlay>
  )
}

const ShareOverlay = (props: any) => {
  const { profiles, activeProfileId, onClose } = props

  const [activeProfile, setActiveProfile] = React.useState(
    profiles?.find((profile: any) => profile.id === activeProfileId) || profiles?.[0],
  )

  const url = `${APP_URLS.community}/profiles/${activeProfile?.slug}`
  const title = `Visit ${activeProfile?.name} on the Behave Community!`
  const message = `If you are not a Behave Community Member yet, you can request to join when you visit my profile!`

  const { copy } = useCopyToClipboard({
    text: `${title}\n\n${url}\n\n${message}}`,
    notification: 'Email address copied',
  })

  if (!activeProfile) return null

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={onClose} maxWidth={32}>
      <Overlay.Header title="Share Community Profile" icon="community_profile" />

      <Overlay.Content>
        <Section>
          {size(profiles) > 1 && (
            <Select
              label="Share Profile"
              value={activeProfile?.id}
              onUpdate={({ value }) => {
                setActiveProfile(profiles?.find?.((profile: any) => profile.id === value))
              }}
            >
              {profiles.map((o) => (
                <Option key={o.id} label={o.name} value={o.id} />
              ))}
            </Select>
          )}

          <Card variant="variant-well" className="!bg-blue-300 !bg-opacity-10 px-3 py-2">
            <div className="font-[600]">{title}</div>
            <div className="mt-2">
              <a href={url} target="_blank">
                {url}
              </a>
            </div>
            <div className="mt-2">{message}</div>

            <Button label="Copy Message" onClick={copy} glyph="copy" size={100} className="mt-3" display="inline-flex" />
          </Card>

          <div className="mt-2">
            <Label label="Share via" />
          </div>

          <Card>
            {Object.keys(SOCIAL_LINKS).map((key) => {
              const config = SOCIAL_LINKS[key]

              const webUrl = config.getWebUrl?.({
                title: encodeURIComponent(title),
                message: encodeURIComponent(message),
                url: encodeURIComponent(url),
              })

              const appUrl = config.getAppUrl?.({
                title: encodeURIComponent(title),
                message: encodeURIComponent(message),
                url: encodeURIComponent(url),
              })

              return <SocialLink key={key} glyph={config.glyph} icon={config.icon} label={config.label} webUrl={webUrl} appUrl={appUrl} />
            })}
          </Card>
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}

const getIsMobileDevice = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

const SocialLink: React.FC<any> = ({ appUrl, webUrl, glyph, icon, label }) => {
  // const isMobileDevice = getIsMobileDevice()

  // const handleMobileClick = () => {
  //   if (!isMobileDevice || !appUrl) return

  //   const newWindow = window.open(appUrl, '_blank')

  //   // Check if the window could not be opened (popup blocked or app scheme not recognized)
  //   if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
  //     window.open(webUrl, '_blank')
  //   }
  // }

  return (
    <a
      // onClick={handleMobileClick}
      href={webUrl}
      target="_blank"
      className="flex items-center !outline-none w-full !px-3 !py-2 !border-b !border-0 !border-solid !border-divider hover:!bg-hover"
    >
      {glyph && <Glyph glyph={glyph} className="mr-1.5" />}
      {icon && <Icon icon={icon} className="mr-1.5" />}
      <div className="text-blue-500 font-[600]">{label}</div>
      <Glyph glyph="chevron" size={12} className="ml-auto" color={COLORS.blue} />
    </a>
  )
}

export default SocialLink
