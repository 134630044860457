import React from 'react'
import { connect } from 'react-redux'

import { Heading, Text, P, Ul, Li } from '../../../Typography'
import AmountInput from '../../../Forms/AmountInput'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import ClientProfileHeader from '../../../ClientProfileHeader'
import DataFormStatus from '../../../Statuses/DataFormStatus'
import DateInput from '../../../Forms/DateInput'
import Divider from '../../../Divider'
import FormSection from '../../../Forms/FormSection'
import Grid from '../../../Grid'
import Overlay from '../../../Overlay'
import Section from '../../../Section'

import Chotomate from '../../../Chotomate'

import { DataFormsBase, mapDispatchToProps, mapStateToProps } from './DataFormsBase'
import { withOverlayError } from '../../../../hocs/withOverlayError'

class SoberLivingHomeAgreement extends DataFormsBase {
  renderHeader = () => (
    <Overlay.Header
      icon="intake_forms"
      title={this.props.data_form?.name || this.props.title}
      titleAside={<DataFormStatus status={this.props.data_form?.status} />}
      description={<ClientProfileHeader client={this.props.data_form?.client || this.props.client} />}
    />
  )

  renderContent = () => (
    <>
      <Chotomate ready name="sober_living_home_agreement_overlay" />

      <Section
        scrollview={{
          id: 'waiver',
          name: 'Waiver',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">WAIVER OF LIABILITY AND HOLD HARMLESS AGREEMENT</Heading>

          <P>
            In consideration of receiving housing and transportation services from Facility stated above, hereinafter referred to as
            "Company", I hereby RELEASE, WAIVE, DISCHARGE AND COVENANT NOT TO SUE, Company, and any of their officers, agents, servants, or
            employees (hereinafter referred to as RELEASEES) from any and all liability, claims, demands, disputes and causes of action
            whatsoever arising out of or related to any loss, damage, or injury, including without limitation, death, bodily harm and/ or
            injury that may be sustained by me and/or other family members that are utilizing the transportation service, and/ or damage to
            any property belonging to me and/or other family members that are utilizing the transportation service, as the case maybe,
            WHETHER CAUSED BY THE NEGLIGENCE OF THE RELEASEES, or otherwise by any reason whatsoever, while utilizing such services, or
            while in, on or upon the premises where such services may be provided.
          </P>
          <P>
            I am fully aware of the unusual risks involved and hazards connected with this activity, including but not limited to travel
            risks and/or road hazards. I hereby elect to voluntarily participate in said activity with full knowledge that said activity may
            be hazardous to me and my property. I VOLUNTARILY ASSUME FULL RESPONSIBILITY FOR ANY RISKS OF LOSS, PROPERTY DAMAGE OR PERSONAL
            INJURY, INCLUDING WITHOUT LIMITATION DEATH, that may be sustained by me, or any loss or damage of property owned by me, as a
            result of being engaged in such activity, WHETHER CAUSED BY THE NEGLIGENCE OF RELEASEES OR OTHERWISE BY ANY REASON WHATSOEVER.
          </P>
          <P>
            I further hereby AGREE TO INDEMNIFY AND HOLD HARMLESS the RELEASEES from any loss, liability, damage or costs, including court
            costs and attorney fees, that RELEASEES may incur as a result of my participation in said activity, WHETHER CAUSED BY NEGLIGENCE
            OF RELEASEES or otherwise by any reason whatsoever.
          </P>
          <P>
            It is my express intent that this Waiver of Liability and Hold Harmless Agreement shall bind the members of my family and
            spouse, during the length of my life, and my heirs, assigns and personal representative, should I be deceased, and shall be
            deemed as a RELEASE, WAIVER, DISCHARGE AND COVENANT NOT TO SUE the above-named RELEASEES. I hereby further agree that this
            Waiver of Liability and Hold Harmless Agreement shall be construed in accordance with the laws of the State of Operation.
          </P>
          <P>
            IN SIGNING THIS RELEASE, I, ACKNOWLEDGE AND REPRESENT THAT I have read the foregoing Waiver of Liability and Hold Harmless
            Agreement, understand it and sign it voluntarily as my own free act and deed; no oral representations, statements, or
            inducements, apart from the foregoing written agreement, have been made; I am at least eighteen (18) years of age and fully
            competent; and I execute this Release for full, adequate and complete consideration fully intending to be bound by same.
          </P>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'consent_for_drug_testing',
          name: 'Consent For Drug Testing',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">CONSENT FOR DRUG TESTING</Heading>
          <P>
            It is the policy of Facility stated above to provide each client with an alcohol- and illicit drug-free environment. Urine drug
            screens and breathalyzer tests will help us in achieving this goal.
          </P>
          <P>
            As a client of company, I understand I will be expected to submit a urine sample for drug and/or alcohol screening under the
            supervision of staff.
          </P>
          <P>
            I understand I may be requested to provide a urine sample upon admission, randomly up to three times per week and/or upon
            suspicion of drug or alcohol use.
          </P>
          <P>
            My signature below indicates I have acknowledged the company’s protocols, and I hereby consent to the administration of urine
            drug screening and/or breathalyzer tests by the staff of the company
          </P>
          <P>
            I understand that my refusal to provide a urine specimen upon request could result in my being asked to leave recovery housing
            and to forfeit all my rights and privileges as a client.
          </P>
          <P>I hereby acknowledge and understand the above and give consent for drug screening.</P>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'shared_housing_agreement',
          name: 'Shared Housing Agreement',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">ALCOHOL AND DRUG FREE SHARED HOUSING AGREEMENT</Heading>
          <P>
            Facility stated above (the Company) has developed a set of rules and guidelines for the structure, safety, growth, and healing
            of all who reside at the company housing. We will help each individual with their customized approach to recovery for their
            specific needs.
          </P>
          <P>
            We believe that a large part of recovery is learning to be personally responsible and accountable for ourselves (thoughts,
            feelings, and behaviors). The below rules and expectations are part of taking personal responsibility and becoming more
            accountable.
          </P>
          <P>
            The undersigned alcohol and drug-free individual, acknowledges and understands that s/he is living in an alcohol and other drug
            free shared housing environment; residents are living together as a family of persons with various challenges. The undersigned
            shall be one member of the resident community. The undersigned understands that s/he resides in a congregate housing situation
            and not in an exclusive unit or space. The undersigned understands that s/he does not reside as a tenant with exclusivity rights
            or possession rights in any housing space, exclusively.ˀ
          </P>

          <P>
            <Text bold>The undersigned agrees to pay a monthly shared housing fee (for a shared room) of:</Text>
          </P>

          <FormSection layout="vertical">
            <AmountInput label="Amount" model="data.amount" size={6} />
            <DateInput label="Beginning on" model="data.beginning_on" />
          </FormSection>

          <P>
            Shared housing fee payments for any single month (Thirty day period) are non-refundable, in part or in whole if resident leaves
            or is asked to leave for violations of rules. Thirty days’ notice of vacating the shared housing is required of the resident.
            Residents who leave with less than Thirty days’ notice will be charged for a full thirty days. Prepaid fees for future months
            (30 day periods) are refunded in full. Resident shared housing fees are used to pay for rent, utilities, supplies, maintenance
            services, and house management. Resident shared housing fees are due seven days prior to the end of the current thirty day
            period.
          </P>
          <P>The undersigned agrees to pay housing fees either by credit/debit card/echeck through the billing system.</P>
          <P>
            Shared housing fees are based on each individual’s need and ability to pay. Shared housing fees are never to be discussed among
            fellow residents. Discussing your financial arrangements with other residents is grounds for immediate vacation of the
            accommodations.
          </P>
          <P>
            The undersigned agrees to vacate the shared housing accommodations if the company, in its sole discretion, determines the
            undersigned has used alcohol or illicit drugs, regardless of the location of use, or has otherwise violated any of the House
            Rules.
          </P>
          <P>
            The undersigned agrees to adhere to each of the following sets of Rules outlined below. The undersigned agrees that adherence to
            each of the following Rules, Regulations and Guidelines is, at a minimum, a condition precedent for continued residency.
          </P>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'general_regulation_rules',
          name: 'General Rules',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">GENERAL REGULATION RULES</Heading>
          <Ul>
            <Li>All residents shall remain alcohol and drug free at all times. This includes selling, possession, or consumption.</Li>
            <Li>
              Approved physical and mental health medication drugs are allowed, but must be included in the resident prescribed medication
              information sheet.
            </Li>
            <Li>All residents must either be in treatment, school, working, actively attempting to find work, or volunteering.</Li>
            <Li>
              Resident will sign and maintain consent for company staff to speak with resident’s family, treatment team, and other
              collateral sources that make up their treatment team.
            </Li>
            <Li>
              Residents may not at any time exhibit violence or threaten violence, coerce or intimidate members of the household or staff.
            </Li>
            <Li>Racial, LGBTQ-phobic comments, sexual slurs and sexual harassment will not be tolerated.</Li>
            <Li>Residents must sign in and out upon entering and leaving the house.</Li>
            <Li>There will be a weekly house meeting to discuss any Community or Household issues. Attendance is mandatory.</Li>
            <Li>
              No visitors are allowed on the premises without the full consent of the house management. Visitors can visit between 10a and
              10p.
            </Li>
            <Li>
              Residents may not accept visitors who are under the influence of alcohol/drugs. All visitors must be willing to take drug and
              alcohol tests when asked.
            </Li>
            <Li>Visitors are not allowed in the bedrooms and may only visit in the community areas of the house and premises.</Li>
            <Li>
              Residents must maintain any and all space s/he occupies at the residence in a healthy, clean, orderly, and safe condition.
            </Li>
            <Li>Resident fees shall be paid seven days prior to the last day of each month for the next 30 day period.</Li>
            <Li>Departing residents are required to give 30 days’ notice or will be charged for the full month.</Li>
            <Li>
              Resident will respect the privacy and confidentiality of all house residents and will not disclose to anyone outside the
              household the names or any other information about who resides at any sober living environment owned by the Company.
            </Li>
            <Li>
              No fraternization, dating, intimate and/or sexual contact or relationships are allowed between residents or residents and
              staff.
            </Li>
            <Li>Pets or animals are allowed based on company approval and a pet addendum must be signed along with a pet deposit.</Li>
            <Li>All residents’ vehicles must be insured and registered and a copy of both must be given to management.</Li>
            <Li>Residents will be held financially responsible and can be terminated for vandalism to company property.</Li>
            <Li>All residents are required to report any damage or theft to management.</Li>
            <Li>No property of the company shall be removed from the premises.</Li>
            <Li>
              Residents are not allowed to conduct ANY repairs to the property inside or outside the residences without written permission.
            </Li>
            <Li>
              The company is not responsible for lost damaged or stolen property. If a resident leaves without taking his/her personal
              belongings and makes no arrangements with management to retrieve these belongings within 30 days, they will be stored for up
              to 30 days and donated thereafter.
            </Li>
            <Li>Resident agrees to abide by all other unwritten rules as implemented.</Li>
          </Ul>

          <P>
            The company oversees all utility use, furnishings, and maintenance of all shared housing space. Each resident shall provide all
            items required to maintain his sobriety and personal care needs. Those living in the sober living will be held responsible and
            accountable for their living environment. In other words, keep the premises in clean condition – bed made, clothes stored in
            dresser and/or closet, dishes clean and put away, furniture placed where it belongs, cigarettes placed only in the ashtray in
            approved outdoor locations, floors are swept and/or vacuumed regularly, counters are cleaned, bedrooms and bathrooms are picked
            up, laundry room is straightened up after each use, as well as the community areas and outside of the premises. In an effort to
            maintain consistency staff will conduct random inspections and provide feedback.
          </P>
          <P>
            Those living at the sober livings are expected to add to a positive experience for others residing at the houses as well as to
            those visiting. Those that are disruptive to others healing and growth experience may be asked to leave. We expect high
            standards of conduct from all those residing at the sober living. The company encourages and supports an environment of
            willingness to accept feedback and suggestions from other members of the community and staff.
          </P>
          <P>In addition to the General Regulation Rules, stated above, resident agrees to the following rules:</P>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'urinalysis_testing',
          name: 'Urinalysis Testing',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">RANDOM URINALYSIS TESTING</Heading>
          <P>
            Drug testing is mandatory and will be performed randomly. All residents agree to take thrice weekly and/or on demand random drug
            tests. Any refusal or attempts to bypass, deceive or fail to provide a urinalysis sample for testing or a positive test
            indicating you have used substances is grounds for immediate expulsion from the sober living.
          </P>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'fire_health_and_safety',
          name: 'Fire, Health & Safety',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">FIRE, HEALTH AND SAFETY</Heading>
          <P>To best assure a safe and healthy environment:</P>
          <Ul>
            <Li>
              There is no smoking (including vapor devices) inside the house. Outdoor smoking is only allowed in the designated smoking
              area.
            </Li>
            <Li>All smoking material must be disposed in safe containers – NO BUTTS on ground.</Li>
            <Li>No burning candles, incense or smudge sticks inside the residence.</Li>
            <Li>No packing boxes, suitcases or other clutter material in rooms.</Li>
            <Li>Check all appliances and utilities when leaving premises to make sure they are off.</Li>
            <Li>
              Residents will give staff a daily agenda as well as signing in and out so that staff know if residents are present in an
              emergency.
            </Li>
            <Li>Residents will give staff a cell phone number to be used to reach in case of an emergency.</Li>
            <Li>
              Company staff has the right to enter and search all parts of the house and outside premises, including residents’ assigned
              room and their vehicles.
            </Li>
            <Li>
              Having a vehicle at the house is a privilege and residents must provide a copy of a valid and current driver’s license,
              vehicle registration, and valid insurance prior to being able to driving said vehicle.
            </Li>
          </Ul>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'cleanliness_chores_cooperation_courtesy',
          name: 'Cleanliness, Chores & Cooperation',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">CLEANLINESS, CHORES, COOPERATION & COURTESY</Heading>
          <P>To keep the environment clean and promote consideration among residents and to enhance family harmony:</P>
          <Ul>
            <Li>All residents will share cleaning and upkeep tasks of the community areas.</Li>
            <Li>Residents are not allowed to eat or store food in their rooms.</Li>
            <Li>All residents will clean dishes and utensils and clean kitchen after eating.</Li>
            <Li>All residents will leave shower, bathtub and basin clean after use.</Li>
            <Li>All residents will make bed early and leave personal area clean and tidy.</Li>
            <Li>All residents will clean up after themselves in the laundry room.</Li>
            <Li>Clients will have assigned chores that rotate every week.</Li>
            <Li>Televisions and radios are not to interfere with other residents and are to be turned off when not in use.</Li>
            <Li>Curfew is listed in the rules and expectations.</Li>
            <Li>Park in assigned and approved areas only.</Li>
            <Li>
              No loitering outside the front house yard area. No smoking around home within 100 feet except in the designated smoking area
              in the back yard.
            </Li>
            <Li>No loud outside socializing after 10:00 P.M. (Quiet conversation only).</Li>
            <Li>Drive at speed limits and through approved routes in the neighborhood.</Li>
            <Li>Be polite, courteous and respectful to neighbors at all times, and mindful of their privacy.</Li>
          </Ul>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'meetings_recovery_responsibilities',
          name: 'Recovery & Responsibilities',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">MEETINGS, RECOVERY AND RESPONSIBILITIES</Heading>
          <Ul>
            <Li>Weekly house meetings to be attended by all residents on nights they are scheduled.</Li>
            <Li>Residents remain active in their own program of recovery.</Li>
            <Li>
              Residents are expected to follow the recommendations of their treatment team and if there is disagreement or confusion we will
              have a phone conference to clarify.
            </Li>
            <Li>
              Request overnight leaves with pass request from House Management at least 24 hours in advance. For emergency situations you
              must notify house management as soon as possible for request for overnight leave.
            </Li>
            <Li>
              Must attend 5 recovery meetings weekly (AA, NA, SMART Recovery, Refuge in Recovery etc). Your attendance sheet must be filled
              out and up to date at all times and handed into your house manager.
            </Li>
            <Li>
              Residents are required to attend daily morning meeting/meditation unless resident is excused for work or program by the house
              manager.
            </Li>
            <Li>Residents are encouraged to participate in house fitness goals/classes and community activities.</Li>
            <Li>
              Your house manager must know what your IOP/OP/Work/School schedule is at all times. They must be notified of changes as they
              occur.
            </Li>
            <Li>
              You must look for work/school/volunteer jobs if you do not have daytime IOP or OP status. This means not being in residence
              during the hours of 9:00am to 3pm while looking for work.
            </Li>
            <Li>If you have a job please provide a weekly work schedule that can be verified.</Li>
          </Ul>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'house_relationships',
          name: 'House Relationships',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">HOUSE RELATIONSHIPS</Heading>
          <Ul>
            <Li>
              If you are having conflict with one of your housemates or house manager – If your feelings are high and you know you’ll say
              something destructive, take the situation to your therapist( if being treated), sponsor, mentor, trusted recovery buddy first,
              and then find a way to talk to that person directly.
            </Li>
            <Li>
              If a problem with a housemate persists then take it to your house manager for further guidance and/or mediation. If the
              problem is with your house manager and it cannot be resolved, take it to the executive director.
            </Li>
          </Ul>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'kitchen_and_food_protocol',
          name: 'Kitchen & Food Protocol',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">KITCHEN AND FOOD PROTOCOL</Heading>
          <Ul>
            <Li>
              All open containers, bags, packages of food MUST be sealed or properly closed after each use to prevent infestation of any
              kind.
            </Li>
            <Li>All food will be kept in the kitchen/refrigerator when not consuming.</Li>
            <Li>Food and beverages are not allowed in bedrooms.</Li>
            <Li>
              When you purchase your own food, please write your name on the container so others do not mistake it for common property.
            </Li>
          </Ul>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'room_and_hygiene_rules',
          name: 'Personal Room & Hygiene Rules',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">PERSONAL ROOM AND HYGIENE RULES</Heading>
          <Ul>
            <Li>
              Residents are to sleep in their ASSIGNED beds. Day sleeping is not allowed unless legitimately sick with a note from a doctor
              and/or urgent care. Residents are not allowed to sleep in common areas.
            </Li>
            <Li>Residents and visitors are NOT ALLOWED in other resident’s room for any reason.</Li>
            <Li>
              Resident must keep his/her living space clean, organized, free of clutter and disarray. A chore list will be posted and
              updated at all times.
            </Li>
            <Li>Beds must be made at all times when not in room.</Li>
            <Li>Any hair, soap, foam, toothpaste, makeup, etc. should be rinsed out of the sink or tub when you are finished using it.</Li>
            <Li>
              Trash goes out the night before the next day pickup. Trash containers shall be retrieved from the curb the evening following
              pickup and placed in their respective areas. This duty will be a portion of the chore list and should be rotated every week.
            </Li>
            <Li>All medication should be kept in your assigned medicine cabinet and the door should remain locked at all times.</Li>
            <Li>Music should be listened to with ear buds or headphones.</Li>
            <Li>Resident must comply with bed or house reassignments due to management’s discretion or individually based needs.</Li>
          </Ul>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'legal',
          name: 'Legal',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">LEGAL</Heading>
          <Ul>
            <Li>All residents are expected to comply with ALL LAWS and conditions of parole/probation.</Li>
            <Li>
              Residents may not engage in theft or any other criminal act, this includes gambling and borrowing without permission,
              including food.
            </Li>
            <Li>
              Residents are expected to respect the privacy and possessions of others. In the event stealing or missing items are reported,
              resident’s must comply with a search of all rooms and possessions. If reported or missing items are found in the resident’s
              possession this may result in immediate termination from the residence.
            </Li>
          </Ul>
          <Heading is="h3">I HAVE READ AND FULLY UNDERSTAND AND AGREE TO ALL PROVISIONS OF THIS SHARED HOUSING AGREEMENT.</Heading>
        </Grid>
      </Section>

      <Divider />

      <Section
        scrollview={{
          id: 'client_confirmation',
          name: 'Client Confirmation',
        }}
      >
        <Grid gap={16}>
          <Heading is="h2">Client Confirmation of Reviewed Material</Heading>
          <P>Client will check each box that has been reviewed and agreed to:</P>
          <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded" layout="vertical-dense" largeLabel>
            <Checkbox
              label="Waiver of Liability & Hold Harmless Agreement"
              model="data.client_confirmation.waiver_of_liability_hold_harmless_agreement"
            />
            <Checkbox label="Consent For Drug Testing" model="data.client_confirmation.consent_for_drug_testing" />
            <Checkbox
              label="Alcohol & Drug Free Shared Housing Agreement"
              model="data.client_confirmation.alcohol_drug_free_shared_housing_agreement"
            />
            <Checkbox label="General Regulation Rules" model="data.client_confirmation.general_regulation_rules" />
            <Checkbox label="Random Urinalysis Testing" model="data.client_confirmation.random_urinalysis_testing" />
            <Checkbox label="Fire, Health & Safety" model="data.client_confirmation.fire_health_safety" />
            <Checkbox
              label="Cleanliness, Chores, Cooperation & Courtesy"
              model="data.client_confirmation.cleanliness_chores_cooperation_courtesy"
            />
            <Checkbox label="Recovery & Responsibilities" model="data.client_confirmation.recovery_responsibilities" />
            <Checkbox label="House Relationships" model="data.client_confirmation.house_relationships" />
            <Checkbox label="Kitchen & Food Protocol" model="data.client_confirmation.kitchen_food_protocol" />
            <Checkbox label="Personal Room & Hygiene Rules" model="data.client_confirmation.personal_room_hygiene_rules" />
            <Checkbox label="Legal" model="data.client_confirmation.legal" />
          </CheckboxGroup>
        </Grid>
      </Section>
    </>
  )
}

SoberLivingHomeAgreement.defaultProps = {
  title: 'Sober Living Home Agreement',
  category: 'intake_form',
  subcategory: 'sober_living_home_agreement',
  signoffWithoutSupervisor: true,
  requireSupervisor: false,
  requestClientSignature: true,
  requestAuthorSignature: false,
  requestSupervisorSignature: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(SoberLivingHomeAgreement))
