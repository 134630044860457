import React from 'react'
import { darken, tint } from 'polished'
import clsx from 'clsx'

import { COLORS } from '../../theme'

const DAYS_TO_DISPLAY = 42
const WEEK_DAYS = ['M', 'T', 'W', 'T', 'F', 'S', 'S']

const getDays = (currentDate: any) => {
  const days: any[] = []

  if (!currentDate) return days

  const monthStart = currentDate.startOf('month')
  const firstDay = monthStart.startOf('week')

  for (let i = 0; i < DAYS_TO_DISPLAY; i++) days.push(firstDay.plus({ days: i }))

  return days
}

const MiniCalendar = ({ className, currentDate, mode, setCurrentDate, stats = [], today }: any) => {
  const days = getDays(currentDate)

  return (
    <div css={styles.root} className={clsx(`mode-${mode}`, className)}>
      {WEEK_DAYS.map((weekDay, index) => (
        <div key={index} css={styles.weekDay}>
          <span>{weekDay}</span>
        </div>
      ))}

      {days.map((day) => {
        const date = day.toFormat('MM-dd-yyyy')
        const isActive = mode === 'days' && date === currentDate.toFormat('MM-dd-yyyy')
        const isToday = date === today.toFormat('MM-dd-yyyy')
        const isOutsideMonth = day.month !== currentDate.month
        const isFirstDay = day.day === currentDate.startOf('month').day
        const isLastDay = day.day === currentDate.endOf('month').day

        let isHighlighted =
          (mode === 'weeks' && day.weekNumber === currentDate.weekNumber) || (mode === 'months' && day.month === currentDate.month)

        return (
          <div
            key={date}
            css={styles.day}
            onClick={() => {
              if (isHighlighted) return
              setCurrentDate(day)
            }}
            className={clsx({
              'is-today': isToday,
              'is-active': isActive,
              'is-outside-month': isOutsideMonth,
              'is-highlighted': isHighlighted,
              'is-not-highlighted': !isHighlighted,
              'is-first-day': isFirstDay,
              'is-last-day': isLastDay,
              'show-dot': !isActive && stats.includes(date),
            })}
          >
            <span>{day.toFormat('dd')}</span>
          </div>
        )
      })}
    </div>
  )
}

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    fontSize: '0.96rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',

    width: '100%',
    maxWidth: 480,
    margin: '0 auto',
  },

  weekDay: {
    textAlign: 'center',
    padding: '0.5rem',
    zIndex: 0,
    lineHeight: 1,
    fontWeight: 600,
  },

  day: {
    textAlign: 'center',
    padding: '0.6rem 0.5rem',
    zIndex: 0,
    lineHeight: 1,

    '&.show-dot': {
      position: 'relative',

      '&::after': {
        content: '""',
        width: 4,
        height: 4,
        borderRadius: '50%',
        background: COLORS.blue,
        position: 'absolute',
        bottom: 1,
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'block',
      },
    },

    '&.is-today': {
      color: `${COLORS.white} !important`,
      fontWeight: 500,
      fontSize: '0.9rem',

      span: {
        position: 'relative',

        '&::before': {
          content: '""',
          background: COLORS.red,
          borderRadius: '100%',
          position: 'absolute',
          display: 'block',
          top: -5,
          bottom: -5,
          left: -5,
          right: -5,
          zIndex: -1,
        },
      },
    },

    '&.is-active, &.is-not-highlighted:hover': {
      cursor: 'pointer',
      fontWeight: 500,
      opacity: '1 !important',
      color: `${COLORS.white} !important`,

      span: {
        position: 'relative',

        '&::before': {
          content: '""',
          background: COLORS.link,
          borderRadius: '100%',
          position: 'absolute',
          display: 'block',
          top: -5,
          bottom: -5,
          left: -5,
          right: -5,
          zIndex: -1,
        },
      },
    },

    '&.is-highlighted': {
      background: tint(0.8, COLORS.link),

      '.mode-weeks &': {
        '&:nth-child(7n)': {
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
        },

        '&:nth-child(7n + 1)': {
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
        },
      },

      '.mode-months &': {
        '&.is-first-day': {
          borderTopLeftRadius: 5,
        },

        '&.is-last-day': {
          borderBottomRightRadius: 5,
        },
      },
    },

    '&.is-outside-month': {
      opacity: 0.5,
      color: COLORS.textMuted,
    },
  },
}

export default MiniCalendar
