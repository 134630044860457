import React from 'react'

import Select from '../common/Select'

type Props = { column: any }

const filterOptions = [
  {
    value: 'last_week',
    label: 'Last Week',
  },
  {
    value: 'last_two_weeks',
    label: 'Last Two Weeks',
  },
  {
    value: 'last_month',
    label: 'Last Month',
  },
  {
    value: 'last_3_months',
    label: 'Last 3 Months',
  },
  {
    value: 'last_6_months',
    label: 'Last 6 Months',
  },
  {
    value: 'last_12_months',
    label: 'Last 12 Months',
  },
]

const DateFilter = (props: Props) => {
  const {
    column: { filterValue, setFilter },
  } = props

  return <Select defaultLabel="Any Time" setFilter={setFilter} filterValue={filterValue} filterOptions={filterOptions} />
}

export default DateFilter
