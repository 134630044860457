import React from 'react'
import { tint } from 'polished'
import { useParams } from 'react-router-dom'
import * as HoverCard from '@radix-ui/react-hover-card'

import { COLORS } from '../../../theme'
import { LOC } from '../../../utils/constants'
import { usDate } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import DataList from '../../../components/DataList'
import Flex from '../../../components/Flex'
import Icon from '../../../components/Icon'
import LevelOfCareStatus from '../../../components/Statuses/LevelOfCareStatus'
import RoadmapBar from '../../../components/Roadmap/RoadmapBar'

import { CardTooltip } from '../components/CardTooltip'
import { CardTooltipContent } from '../components/CardTooltipContent'
import { ClientHeader } from '../components/ClientHeader'

export const Authorization = ({ data, client }: any) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const { timezone } = useSettings()
  const { resource_id }: any = useParams()

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  if (!data) return null

  return (
    <CardTooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
      <HoverCard.Trigger asChild>
        <RoadmapBar
          minWidth={7}
          background={tint(0.8, COLORS.mintGreen)}
          startDate={data.started_at}
          endDate={data.ended_at}
          onClick={openTooltip}
          asGradient={!data.ended_at}
          width={!data.ended_at && 50}
          overflow="hidden"
        >
          <Flex nowrap centerY gap="0.5rem" css={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
            <Icon icon="authorizations" size={15} />
            <div css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{LOC[data.level]}</div>
          </Flex>
        </RoadmapBar>
      </HoverCard.Trigger>

      <CardTooltipContent>
        {/* <ClientHeader client={client} /> */}

        <Flex centerY css={{ padding: '0.5rem 1rem 0.25rem' }}>
          <Icon icon="authorizations" size={20} className="!mr-2" />
          <div className="text-[1.1rem] font-[700]">Authorization</div>
          <Button
            label="Open"
            size={100}
            link={`/clients/${resource_id}/authorizations/${data.id}`}
            css={{ marginLeft: 'auto' }}
            target="_blank"
          />
        </Flex>

        <DataList isCompact withPadding labelWidth={130}>
          <DataList.Item label="Level of Care" value={<LevelOfCareStatus status={data.level} />} />
          <DataList.Item label="Authorization #" value={data.identifier} />
          <DataList.Item label="Sessions Auth." value={data.sessions} />
          <DataList.Item label="Sessions Used" value={data.used_sessions} />
          <DataList.Item label="Started On" value={usDate(data.started_at, timezone)} />
          <DataList.Item label="Ending On" value={usDate(data.ended_at, timezone)} />
        </DataList>
      </CardTooltipContent>
    </CardTooltip>
  )
}
