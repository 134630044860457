import React from 'react'
import Observer from '@researchgate/react-intersection-observer'
import clsx from 'clsx'

import { COLORS, MEDIA_QUERY } from '../theme'
import { isDefined } from '../utils/functions'

import { Heading, Text } from './Typography'
import Comments from './Elements/Comments/Comments'
import CommentsCount from './Elements/Comments/CommentsCount'
import Flex from './Flex'
import Glyph from './Glyph'
import Graphic from './Graphic'
import Loader from './Loader'

import withScrollContext from '../hocs/withScrollContext'

const Section = (props: any) => {
  const {
    aside,
    baseline,
    children,
    className,
    commentsModel,
    commentsName,
    commentsURL,
    data,
    description,
    form,
    glyph,
    headerNowrap,
    headingSize,
    headingType,
    help,
    hide,
    icon,
    id,
    isCollapsible,
    isEditable,
    isLoading,
    margin,
    maxWidth,
    padding,
    paddingLeft,
    paddingRight,
    registerSection,
    scrollview,
    subtitle,
    title,
    updateActive,
    testKey,
  } = props

  let sectionRef = React.useRef(null)
  const openCommentsRef = React.useRef()

  const [visible, setVisible] = React.useState(false)

  const inView = (event) => {
    if (!scrollview) return

    if (scrollview.parent) {
      if (visible === event.isIntersecting) return
      setVisible(event.isIntersecting)

      updateActive(`${scrollview.parent.id}.sections.${scrollview.id}`, event.isIntersecting)
    } else {
      if (visible === event.isIntersecting) return
      setVisible(event.isIntersecting)

      updateActive(scrollview.id, event.isIntersecting)
    }
  }

  React.useEffect(() => {
    if (!scrollview || !registerSection) return

    if (scrollview.parent) {
      registerSection({
        [scrollview.parent.id]: {
          name: scrollview.parent.name,
          sections: {
            [scrollview.id]: {
              id: scrollview.id,
              active: false,
              name: scrollview.name,
              ref: sectionRef,
            },
          },
        },
      })
    } else {
      registerSection({
        ...scrollview,
        ref: sectionRef,
      })
    }
  }, [registerSection, scrollview, sectionRef])

  if (hide) return null

  const hasGraphic = icon || glyph
  const childrenWithProps = React.Children.map(children, (child) => {
    if (!child) return
    if (['div', 'ol'].includes(child.type)) return child
    return React.cloneElement(child, {
      form: isDefined(child.props.form) ? child.props.form : form,
      data: child.props.data || data,
      isEditable: isDefined(child.props.isEditable) ? child.props.isEditable : isEditable,
    })
  })

  const dynamicStyles = {
    display: 'block',
    paddingLeft: hasGraphic && '1.5rem',

    [`${MEDIA_QUERY[1]}`]: {
      paddingLeft: hasGraphic && 48,
    },
  }

  const [isOpen, setIsOpen] = React.useState(props.isOpen)

  const toggleOpen = () => {
    setIsOpen((c) => !c)
  }

  const classNames = clsx({
    'is-open': isOpen,
    'is-collapsible': isCollapsible,
    [className]: className,
  })

  return (
    <Observer onChange={inView}>
      <section
        data-test={testKey}
        id={id}
        ref={sectionRef}
        css={{
          margin,
          marginBottom: !margin && '1.5rem',
          marginTop: !margin && '1.5rem',
          maxWidth,
          padding,
          paddingLeft,
          paddingRight,
          ...styles.root,
        }}
        className={classNames}
      >
        <div>
          {(title || description) && (
            <div css={{ marginBottom: '0.75rem !important' }}>
              <Flex alignItems="center" gap={(aside || glyph || icon) && 12} nowrap={headerNowrap}>
                <Graphic glyph={glyph} icon={icon} width={baseline} size={20} />

                {isCollapsible && <Glyph glyph="triangle_down" size={14} className="collapse-triangle" />}

                <div css={{ flex: '999 1 auto' }}>
                  {title && (
                    <Flex horizontal alignItems="center">
                      <Heading
                        size={headingSize}
                        is={headingType}
                        css={{ marginRight: help ? '0.5rem' : 0 }}
                        className="section-heading"
                        onClick={isCollapsible && toggleOpen}
                      >
                        {title}
                      </Heading>
                      {commentsModel && (
                        <div css={styles.commentsCount}>
                          <CommentsCount model={commentsModel} onClick={openCommentsRef.current?.open} />
                        </div>
                      )}
                      {help}
                    </Flex>
                  )}

                  {subtitle}

                  {description && <Text css={{ marginTop: '0.25em' }}>{description}</Text>}
                </div>

                {aside && (
                  <div>
                    <Flex alignItems="center" gap={8}>
                      {aside}
                    </Flex>
                  </div>
                )}

                {isLoading && (
                  <div>
                    <Flex alignItems="center" gap={8}>
                      <Loader />
                    </Flex>
                  </div>
                )}
              </Flex>
            </div>
          )}

          <div className="section-content">{childrenWithProps}</div>
        </div>

        {commentsModel && (
          <div className="section-content">
            <Comments openComments={(ref) => (openCommentsRef.current = ref)} model={commentsModel} name={commentsName} url={commentsURL} />
          </div>
        )}
      </section>
    </Observer>
  )
}

const styles = {
  root: {
    '&.is-collapsible': {
      marginTop: '0.75rem',
      marginBottom: '0.75rem',

      '.collapse-triangle': {
        transform: 'rotate(-90deg)',
        marginRight: '0.25rem',
      },

      '.section-heading': {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
      },

      '.section-content': {
        display: 'none',
      },

      '&.is-open': {
        opacity: 1,

        '.collapse-triangle': {
          transform: 'rotate(0deg)',
        },

        '.section-content': {
          display: 'block',
        },
      },
    },
  },

  commentsCount: {
    fontWeight: 600,
    marginLeft: '1rem',
  },
}

Section.defaultProps = {
  headingType: 'h3',
  hide: false,
  marginTop: true,
  paddingLeft: '1.25rem',
  paddingRight: '1.25rem',
}

export default withScrollContext(Section)
