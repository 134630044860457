import { success, error, abort } from '@redux-requests/core'

import produce from 'immer'

import { UPDATE_ME } from '../actions/me'
import { setHelpCenterTags } from '../components/Help/utils'

export const INITIAL_STATE = {
  user: null,
  tenant: null,
  plan: null,
  newUser: null,
  access: null,
  client: null,
  permissions: null,
  new_feature_flags: null,
  feature_flags: null,
  subscriptions: null,
  services: null,
  bookmarks: null,
  addons: null,
  signup: null,
  settings: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  if (!action || !action.meta || action.meta.reducer !== 'me') return state

  const CONSTANT = action.meta.origin
  const name = action.meta.name

  switch (action.type) {
    case UPDATE_ME:
      return {
        ...state,
        ...action.response.data,
      }
    case CONSTANT:
      return state
    case success(CONSTANT):
      return produce(state, (draft) => {
        if (name) {
          draft[name] = action.response.data.data
        } else {
          if (action.response.data?.user) draft.user = action.response.data.user
          if (action.response.data?.client) draft.client = action.response.data.client
          if (action.response.data?.tenant) {
            draft.tenant = action.response.data.tenant
            draft.plan = action.response.data.tenant.plan
          }
          if (action.response.data?.plan) draft.plan = action.response.data.plan
          if (action.response.data?.new_user) draft.newUser = action.response.data.new_user
          if (action.response.data?.access) draft.access = action.response.data.access
          if (action.response.data?.subscriptions) draft.subscriptions = action.response.data.subscriptions
          if (action.response.data?.permissions) draft.permissions = action.response.data.permissions
          if (action.response.data?.bookmarks) draft.bookmarks = action.response.data.bookmarks
          if (action.response.data?.feature_flags) draft.feature_flags = action.response.data?.feature_flags
          if (action.response.data?.new_feature_flags) draft.new_feature_flags = action.response.data.new_feature_flags
          if (action.response.data?.services) draft.services = action.response.data.active_plans
          if (action.response.data?.addons) draft.addons = action.response.data.addons
          if (action.response.data?.settings) draft.settings = action.response.data.settings
          if (action.response.data?.settings) draft.settings.help_center_tags = setHelpCenterTags(action.response.data.settings.help_center)
          if (action.response.data?.settings)
            draft.settings.internal_center_tags = setHelpCenterTags(action.response.data.settings.internal_center)
        }
      })
    case error(CONSTANT):
      return state
    case abort(CONSTANT):
      return state
    default:
      return state
  }
}

export default reducer
