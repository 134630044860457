import React from 'react'
import { connect } from 'react-redux'

import Button from '../../Button'
import Chotomate from '../../Chotomate'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Overlay from '../../Overlay'
import PhoneInput from '../../Forms/PhoneInput'
import Select from '../../Forms/Select'
import Option from '../../Forms/Option'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Input from '../../Forms/Input'
import EmailInput from '../../Forms/EmailInput'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class PayerRuleOverlay extends OverlayBase {
  onSaveSuccessful = () => {
    if (this.props.onSaveSuccessful) this.props.onSaveSuccessful()
  }

  renderHeader = () => {
    return <Overlay.Header icon="licence" title="Rule" />
  }

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { record, timezone } = this.props

    return (
      <Overlay.Content>
        <Chotomate ready name="rule_overlay" />

        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          timezone={timezone}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
        >
          <Section>
            <FormSection>
              <Input
                label="Rule Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter the rule reference name',
                  },
                }}
              />

              <Select label="Rule Category" model="category">
                <Option label="Insurance" value="insurance" />
                <Option label="Pre Cert" value="pre_cert" />
                <Option label="Authorizations" value="authorizations" />
                <Option label="Claims" value="claims" />
              </Select>

              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button glyph="edit" label="Edit Rule" type="default" isDisabled={this.props.loading} onClick={this.edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Rule?"
              message="Are you sure you want to delete this rule? This action cannot be undone."
              onYes={this.delete}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={this.props.loading} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'insurance_global_payer_rules')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_global_payer_rules')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(PayerRuleOverlay))
