import React from 'react'
import { keyframes } from '@emotion/react'
import { parse } from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import { COLORS, HARD_SHADOW } from '../../theme'
import { useAppSidebarStore } from '../../hooks/useAppSidebarStore'

import Glyph from '../Glyph'

import AIAssistantView from './AIAssistantView'
import BedDashboardView from './BedDashboardView'
import BookmarksView from './BookmarksView'
import CalendarView from './CalendarView'
import HelpCenterView from './HelpCenterView'
import InternalCenterView from './InternalCenterView'
import AdminHelpCenterView from './AdminHelpCenterView'
import MeetingView from './MeetingView'
import OnboardingView from './OnboardingView'
import PinsView from './PinsView'
import QuickTextView from './QuickTextView'
import QuickTranscriptionsView from './QuickTranscriptionsView'
import RCMHelpCenterView from './RCMHelpCenterView'
import StickyNotesView from './StickyNotesView'

import TodoView from './TodoView'

import { AppSidebarContext } from './context'
import { AppSidebarNavItems } from './AppSidebarNavItems'

const LARGE_VIEWS = [
  'help_center',
  'rcm_help_center',
  'internal_center',
  'admin_help_center',
  'bed_dashboard',
  'bookmarks',
  'sticky_notes',
  'onboarding',
  'ai_assistant',
]

const AppSidebar = () => {
  const [visible, setVisible] = React.useState(false)

  const { activeTab, isOpen, open, close } = useAppSidebarStore()

  const checkVisibility = (isVisible: boolean) => {
    if (isVisible) setVisible(true)
  }

  const [maxWidth, setMaxWidth]: any = React.useState('380px')
  const [isFullScreen, setIsFullScreen] = React.useState(false)
  const isLargeWidth = LARGE_VIEWS.includes(activeTab)

  const classNames = clsx('AppSidebar', {
    'is-open': isOpen,
    'is-hidden': !visible,
    'is-narrow-width': !isLargeWidth,
    'is-large-width': isLargeWidth,
    'is-full-screen': isFullScreen,
  })

  const handleClose = () => {
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('guide')) {
      queryParams.delete('guide')
      history.replace({ search: queryParams.toString() })
    }

    if (queryParams.has('internal')) {
      queryParams.delete('internal')
      history.replace({ search: queryParams.toString() })
    }

    if (queryParams.has('sidebar')) {
      queryParams.delete('sidebar')
      history.replace({ search: queryParams.toString() })
    }

    close()
  }

  const toggleOpen = () => {
    isOpen ? handleClose() : open()
  }

  const toggleFullScreen = () => {
    setIsFullScreen((c) => !c)
  }

  React.useEffect(() => {
    setMaxWidth(activeTab === 'help_center' ? '720px' : isLargeWidth ? '620px' : '440px')
  }, [activeTab])

  // Help Center
  const location = useLocation()
  const history = useHistory()

  let params = parse(location.search)
  let showHelpCenter = !!params.guide
  let showOnboardingCenter = !!params.onboarding
  let showInternalCenter = !!params.internal
  let showAdminHelpCenter = !!params.admin_help

  React.useEffect(() => {
    if (!showOnboardingCenter) return
    open('onboarding')
  }, [showOnboardingCenter])

  React.useEffect(() => {
    if (!showHelpCenter) return
    open('help_center')
  }, [showHelpCenter])

  React.useEffect(() => {
    if (!showInternalCenter) return
    open('internal_center')
  }, [showInternalCenter])

  React.useEffect(() => {
    if (!showAdminHelpCenter) return
    open('admin_help_center')
  }, [showAdminHelpCenter])

  React.useEffect(() => {
    if (!params.sidebar) return

    open(params.sidebar)
  }, [params.sidebar])

  return (
    <AppSidebarContext.Provider value={{ open, toggleOpen, isLargeWidth, isFullScreen, toggleFullScreen, closeSidebar: handleClose }}>
      <div css={styles.root} className={classNames} style={{ '--sidebar-max-width': maxWidth }}>
        <div css={styles.wrapper} className={classNames}>
          <nav css={styles.nav}>
            <header css={styles.navHeader} onClick={toggleOpen}>
              {isOpen ? <Glyph glyph="close" size={12} /> : <Glyph glyph="app_sidebar" size={22} color={COLORS.blue} />}
            </header>

            <div css={styles.navContent}>
              <AppSidebarNavItems checkVisibility={checkVisibility} />
            </div>
          </nav>

          {activeTab === 'todo' && <TodoView />}
          {activeTab === 'calendar' && <CalendarView />}
          {/* {activeTab === 'meeting' && <MeetingView />} */}
          {activeTab === 'onboarding' && <OnboardingView />}
          {activeTab === 'help_center' && <HelpCenterView />}
          {activeTab === 'internal_center' && <InternalCenterView />}
          {activeTab === 'admin_help_center' && <AdminHelpCenterView />}
          {activeTab === 'rcm_help_center' && <RCMHelpCenterView />}
          {activeTab === 'bed_dashboard' && <BedDashboardView />}
          {activeTab === 'bookmarks' && <BookmarksView />}
          {activeTab === 'sticky_notes' && <StickyNotesView />}
          {activeTab === 'pins' && <PinsView />}
          {activeTab === 'quick_text' && <QuickTextView />}
          {activeTab === 'ai_assistant' && <AIAssistantView />}
          {activeTab === 'voice_memos' && <QuickTranscriptionsView />}
        </div>
      </div>
      <div css={styles.placeholder} className={classNames} />
    </AppSidebarContext.Provider>
  )
}

// -.5px 0 0 ${COLORS.divider},
const SIDE_SHADOW = `
  -1px 0 6px ${COLORS.shadow},
  -3px 0 12px ${COLORS.shadow},
  -6px 0 24px ${COLORS.shadow}
`

const HEADER_SHADOW = `
  0 0.5px 0 ${COLORS.divider},
  0 2px 6px ${COLORS.shadow},
  0 4px 12px ${COLORS.shadow},
  0 8px 24px ${COLORS.shadow}
`

const animation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const styles: any = {
  placeholder: {
    display: 'none',

    '&.is-hidden': {
      display: 'none !important',
    },

    '@media (min-width: 600px)': {
      width: '60px',
      display: 'block',
    },

    '&.is-narrow-width': {
      '@media (min-width: 1400px)': {
        display: 'none',
      },
    },

    '&.is-large-width': {
      '@media (min-width: 1600px)': {
        display: 'none',
      },
    },
  },

  root: {
    display: 'none',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '100%',
    background: COLORS.transparent,
    overflow: 'visible !important',
    zIndex: 10,

    width: '100%',
    maxWidth: 'var(--sidebar-max-width)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,

    '&.is-open': {
      display: 'grid',
    },

    '&.is-hidden': {
      display: 'none !important',
    },

    '@media (min-width: 600px)': {
      width: '60px',
      display: 'grid',

      '&.is-open': {
        width: 'var(--sidebar-max-width)',
        maxWidth: '100%',
      },
    },

    '@media (min-width: 800px)': {
      '&.is-full-screen': {
        '--sidebar-max-width': '60px !important',
      },
    },

    '&.is-narrow-width': {
      '@media (min-width: 1400px)': {
        position: 'relative',
      },
    },

    '&.is-large-width': {
      '@media (min-width: 1600px)': {
        position: 'relative',
      },
    },
  },

  wrapper: {
    display: 'grid',
    width: '60px',
    gridTemplateColumns: '1fr',
    background: COLORS.white,
    boxShadow: SIDE_SHADOW,
    borderLeft: `1px solid ${COLORS.divider}`,
    zIndex: 1,

    '&.is-open': {
      animation: `${animation} 300ms cubic-bezier(0.78, 0.14, 0.15, 0.86)`,
      width: '100%',

      '@media(min-width: 600px)': {
        gridTemplateColumns: '60px 1fr',
      },
    },
  },

  nav: {
    display: 'none',
    position: 'relative',
    zIndex: 1,
    overflowY: 'auto',

    '@media(min-width: 600px)': {
      display: 'block',
    },
  },

  navHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    marginBottom: '0.5rem',
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    cursor: 'pointer',
    position: 'sticky',
    top: 0,
    background: COLORS.white,
    zIndex: 1,

    svg: {
      transition: 'transform 50ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    },

    '&:hover': {
      svg: {
        transform: 'scale3d(1.2, 1.2, 1.2)',
      },
    },
  },

  navContent: {
    display: 'grid',
    justifyContent: 'center',
    gridGap: '0.5rem',
  },
}

export default AppSidebar
