import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  to_complete: {
    label: 'To Complete',
    color: 'blue',
  },
  rejected: {
    label: 'Rejected',
    color: 'red',
  },
  pending_review: {
    label: 'Pending Review',
    color: 'yellow',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'red',
  },
  pre_approved: {
    label: 'Pre-Approved',
    color: 'green',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
  closed: {
    label: 'Closed',
    color: 'green',
  },
  empty: {
    label: 'Empty',
    color: 'gray',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
