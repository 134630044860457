import React from 'react'

import { isDefined } from '../utils/functions'

import Avatar from './Avatar'
import Flex from './Flex'
import Glyph from './Glyph'
import Icon from './Icon'

import { Glyph as GlyphType } from '../declarations/types/Glyph'
import { Icon as IconType } from '../declarations/types/Icon'
import { Color } from '../declarations/types/Color'

type Props = {
  avatar?: string
  avatarGlyph?: GlyphType
  avatarMagnify?: boolean
  avatarSize?: number | string
  graphic?: React.ReactElement
  glyph?: GlyphType
  glyphColor?: Color
  glyphSize?: number | string
  icon?: IconType
  iconSize?: number | string
  showEmptyAvatar?: boolean
  size?: number | string
  avatarInitials?: string
} & React.HTMLAttributes<any>

const Graphic: React.FC<Props> = ({
  avatar,
  avatarGlyph,
  avatarMagnify = true,
  avatarSize = 24,
  className,
  graphic,
  glyph,
  glyphColor,
  glyphSize,
  icon,
  iconSize = 20,
  showEmptyAvatar = false,
  size = 18,
  avatarInitials,
}) => {
  let content = null

  if (avatar !== undefined || showEmptyAvatar) {
    content = (
      <Avatar
        magnify={avatarMagnify}
        src={avatar}
        initials={avatarInitials}
        size={avatarSize}
        magnifyPlacement="left"
        glyph={avatarGlyph}
      />
    )
  } else if (isDefined(icon)) {
    content = <Icon icon={icon} size={iconSize} />
  } else if (isDefined(glyph)) {
    content = <Glyph testKey={glyph} glyph={glyph} color={glyphColor} size={glyphSize || size} />
  } else if (isDefined(graphic)) {
    content = graphic
  } else {
    return null
  }

  return (
    <Flex fixSelf centerX centerY className={className}>
      {content}
    </Flex>
  )
}

export default Graphic
