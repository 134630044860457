import React from 'react'

import Radio from '../Radio'
import RadioGroup from '../RadioGroup'

const MaritalStatusRadioGroup = ({ label, model, isRequired }) => (
  <RadioGroup
    label={label}
    model={model}
    layout="vertical-dense"
    validations={
      isRequired && {
        presence: {
          message: 'Please select a marital status',
        },
      }
    }
  >
    <Radio label="Single" value="single" />
    <Radio label="Married" value="married" />
    <Radio label="Engaged" value="engaged" />
    <Radio label="Divorced" value="divorced" />
    <Radio label="Separated" value="separated" />
    <Radio label="Domestic Partnered" value="domestic_partnered" />
    <Radio label="Widowed" value="widowed" />
  </RadioGroup>
)

MaritalStatusRadioGroup.defaultProps = {
  label: 'Marital Status',
  model: 'marital_status',
}

export default MaritalStatusRadioGroup
