import React from 'react'
import { Link, Route, useNavigate } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { FormsDataTable } from '@behavehealth/constructs/FormBuilder/FormsDataTable'
import { useCreate } from '@behavehealth/hooks/useNewAPI'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { FormTemplatesImportOverlay } from '@behavehealth/constructs/FormBuilder/FormTemplatesImportOverlay'
import { FormBuilderOverlay } from '@behavehealth/constructs/FormBuilder/FormBuilderOverlay'

const RootFormsBuilder = () => {
  return (
    <>
      <FormsBuilderIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<FormBuilderOverlay useV6Router />} />
        <Route path="import" element={<FormTemplatesImportOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const FormsBuilderIndex = () => {
  const tableProps = useDataTable({
    name: ['forms'],
    endpoint: `/forms`,
    localStorageKey: 'forms_v1',
  })

  const { isBehave, isCommunity } = useSettings()

  const to = React.useCallback((data) => data.id, [])

  const actions = (
    <>
      {isBehave && <Button as={Link} label="Import Behave Templates" type="default" glyph="add" link="import" />}

      {!isCommunity && <Button as={Link} label="Add New Form" glyph="add" type="primary" permission="forms_builder.create" link="new" />}
    </>
  )

  return (
    <Page title="Forms Builder" icon="web_form" actions={actions}>
      <Grid>
        <FormsDataTable
          asCard
          to={to}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'forms_builder.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          renderDuplicateCell={(record: any) => <DuplicateButton data={record} />}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

const DuplicateButton = ({ data }: any) => {
  const navigate = useNavigate()

  const { mutateAsync, isLoading } = useCreate({
    name: ['duplicate-form'],
    url: `/forms/${data?.id}/duplicate`,
    invalidate: 'forms',
  })

  const handleDuplicateClick = async () => {
    if (!data?.id) return

    try {
      const newForm = await mutateAsync()

      if (newForm?.id) navigate(newForm.id)
    } catch (error) {
      console.error(error)
    }
  }

  return <Button label="Duplicate" glyph="add" size={100} type="default" onClick={handleDuplicateClick} isLoading={isLoading} />
}

export const FormsBuilder = withPageError(RootFormsBuilder)
