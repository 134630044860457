import React from 'react'

import { COLORS } from '../../../theme'
import { withFormContext } from '../../../components/Forms/context'

import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'

import Field from '../../../components/Forms/Field'
import Value from '../../../components/Value'
import { ComboBox } from '../../../components/Forms/ComboBoxes/ComboBox'

import PayerNetworkStatus from '../../../components/Statuses/PayerNetworkStatus'
import InsurancePayerSupport from './InsurancePayerSupport'

import { SectionCard } from '../../../components/SectionCard'

import Alert from '../../../components/Alert'
import Glyph from '../../../components/Glyph'
import Tooltip from '../../../components/Tooltip'
import Status from '../../../components/Status'

import DataList from '../../../components/DataList'

const ClaimPayerDetails = ({ insuranceProvider, onInsuranceProviderUpdate, isCompact }: any) => (
  <SectionCard title="Insurance Provider">
    <Grid gap="1rem">
      <ComboBox
        label="Insurance Provider"
        model="insurance_local_payer"
        icon="insurance"
        type="insurance_local_payers"
        selectTitle={(data: any) => data?.name}
        selectDescription={(data: any) => data?.notes}
        validations={{
          presence: {
            message: 'Please select an Insurance Provider',
          },
        }}
        onUpdate={(value: any) => {
          onInsuranceProviderUpdate?.(value)
        }}
        labelAfter={
          insuranceProvider && (
            <Tooltip
              color="orange"
              content={
                <DataList isCompact layout="horizontal" labelWidth={150} className="grid min-w-[190px]">
                  <DataList.Item
                    label="eVOBs"
                    value={<Glyph glyph={insuranceProvider?.supports?.eligibility ? 'check' : 'cross'} size="1.1rem" />}
                  />
                  <DataList.Item
                    label="Claims"
                    value={<Glyph glyph={insuranceProvider?.supports?.claims ? 'check' : 'cross'} size="1.1rem" />}
                  />
                  <DataList.Item
                    label="Secondary Claims"
                    value={<Glyph glyph={insuranceProvider?.supports?.secondary_claims ? 'check' : 'cross'} size="1.1rem" />}
                  />
                  <DataList.Item
                    label="Claim Status"
                    value={
                      <Glyph
                        glyph={insuranceProvider?.supports?.claim_status || insuranceProvider?.supports?.claims_status ? 'check' : 'cross'}
                        size="1.1rem"
                      />
                    }
                  />
                </DataList>
              }
            >
              <Status small glyph="view" color={COLORS.blue} label="Quickview" className="ml-auto" />
            </Tooltip>
          )
        }
      />

      <Flex gap="1rem">
        <Field label="Network Status" isCompact={isCompact}>
          <Value value={insuranceProvider ? <PayerNetworkStatus status={insuranceProvider.network_status} /> : '–'} />
        </Field>

        <Field label="Payer ID" isCompact={isCompact}>
          <Value value={insuranceProvider?.payer_id || '–'} />
        </Field>
      </Flex>
    </Grid>
  </SectionCard>
)

const styles = {
  dataList: {
    fontSize: '0.95rem',
    flex: '1 1 300px',
  },

  dataListTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    color: COLORS.textMuted,
  },

  cardTitle: {
    fontSize: '1rem',
  },

  cardContent: {
    height: '100%',
  },
}

export default withFormContext(ClaimPayerDetails)
