import React from 'react'

const MESSAGES: any = {
  batch_create: (props: any) => (
    <>
      {props.author} uploaded the {props.record} <i>"{props.event.trackable?.title}"</i>.
    </>
  ),
  create: (props: any) => (
    <>
      {props.author} uploaded the {props.record} <i>"{props.event.trackable?.title}"</i>.
    </>
  ),
  update: (props: any) => (
    <>
      {props.author} updated the {props.record} <i>"{props.event.trackable?.title}"</i>.
    </>
  ),
  destroy: (props: any) => (
    <>
      {props.author} deleted the {props.record} <i>"{props.event.trackable?.title}"</i>.
    </>
  ),
}

export default (props: any) => MESSAGES[props.event?.action_type]?.(props) || null
