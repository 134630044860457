import React, { Component } from 'react'
import { keyframes } from '@emotion/react'
import { tint } from 'polished'
import clone from 'lodash/clone'
import Fuse from 'fuse.js'
import isEqual from 'react-fast-compare'
import startCase from 'lodash/startCase'

import { mapToArray } from '../../../../utils/functions'
import { SHADOW, COLORS } from '../../../../theme'

import Avatar from '../../../Avatar'
import Card, { CARD_CLASSES } from '../../../Card'
import CardHeader from '../../../CardHeader'
import CardSubtitle from '../../../CardSubtitle'
import CardTitle from '../../../CardTitle'
import Search from '../../../Search'
import State from '../../../State'

export default class MultiSingleSelectorMenu extends Component {
  constructor(props) {
    super(props)

    const data = mapToArray(props.data)
    this.state = {
      origin: clone(props.data),
      search: '',
      data: data,
      searchResults: data,
      autoFocus: false,
    }

    this.fuseOptions = {
      shouldSort: true,
      keys: props.searchKeys,
      threshold: 0.4,
    }

    this.fuse = new Fuse(data, this.fuseOptions)
  }

  /*
    LIFECYCLE
  */
  componentDidMount(): void {
    setTimeout(() => {
      this.setState({ autoFocus: true })
    }, 0)
  }

  componentDidUpdate = () => {
    const { data } = this.props
    const { origin, search } = this.state

    if (!isEqual(data, origin)) {
      const arrayData = mapToArray(data)
      let results = arrayData
      if (search.length > 0) {
        this.fuse = new Fuse(arrayData, this.fuseOptions)
        results = this.fuse.search(search)
      }

      this.setState({ origin: clone(data), data: arrayData, searchResults: results })
    }
  }

  /*
    CUSTOM FUNCTIONS
  */
  stopPropagation = (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  onSearch = (search: string) => {
    const { data } = this.state

    if (data && data.length === 0) return
    let results = []

    if (search?.length > 0) {
      const searchResults = this.fuse.search(search)
      for (let i = 0; i < searchResults.length; i++) results.push(searchResults[i].item)
    } else {
      results = this.state.data
    }

    this.setState({ searchResults: results, search: search })
  }

  onSelect = (item) => this.props.onSelect([item])

  /*
    RENDER
  */
  render() {
    const { icon, type, isLoading, label, emptyActions } = this.props
    const { searchResults } = this.state

    const isEmpty = this.state.data.length === 0

    return (
      <div tabIndex="0" css={rootStyles} onClick={this.stopPropagation}>
        {!isEmpty && (
          <div css={headerStyles}>
            <Search autoFocus={this.state.autoFocus} onChange={this.onSearch} placeholder={`Search ${label}…`} />
          </div>
        )}

        <div css={contentStyles}>
          {isLoading && <State isLoading />}
          {!isLoading && searchResults.length === 0 && (
            <State
              isEmpty
              icon={icon}
              title={type && startCase(type)}
              emptyActions={emptyActions}
              emptyDescription={type ? `No ${startCase(type)} found` : 'No data found'}
            />
          )}

          {searchResults.map((data) => (
            <Card key={data.id} tabIndex="0" onMouseDown={() => this.onSelect(data)} variant="variant-list" css={itemStyles}>
              <CardHeader
                graphic={data.hasOwnProperty('avatar') && <Avatar magnify src={data.avatar} magnifyPlacement="left" size="2rem" />}
              >
                <CardTitle title={data.name} css={titleStyles} />
                <CardSubtitle subtitle={data.description} css={descriptionStyles} />
              </CardHeader>
            </Card>
          ))}
        </div>
      </div>
    )
  }
}

const mountAnimation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(-4px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const rootStyles = {
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  background: 'white',

  borderRadius: 7,
  boxShadow: `${SHADOW(10, COLORS.divider)}, 0 0 0 0.5px ${COLORS.divider}`,

  top: '100%',
  width: '100%',
  height: 380,
  overflow: 'hidden',
  outline: 0,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',

  transformOrigin: 'top center',
  animation: `${mountAnimation} 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards`,
}

const itemStyles = {
  '&:hover': {
    cursor: 'pointer',
    background: COLORS.hover,

    [`.${CARD_CLASSES.TITLE}`]: {
      color: COLORS.blue,
    },
  },
}

const titleStyles = {
  fontSize: '1rem',
}

const descriptionStyles = {
  fontSize: '0.9rem',
}

const headerStyles = {
  flex: '0 0 auto',
  boxShadow: `0 0.5px 0 ${COLORS.divider}`,
  position: 'relative',
  zIndex: 3,

  input: {
    borderRadius: 0,
    boxShadow: 'none',
    border: 'none',
  },
}

const contentStyles = {
  width: '100%',
  flex: '1 1 auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  position: 'relative',
  background: tint(0.5, COLORS.lightBackground),
}
