import React from 'react'
import { persist } from 'zustand/middleware'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import create from 'zustand'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Page, Grid, Tab, TabList, Tabs, SummonOverlay, Button, Flex, HelpTagIframe } from '@behavehealth/components'
import { TodosDataTable } from '@behavehealth/constructs/Todos/TodosDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import Active from './active'
import Completed from './completed'

import { TodoPageActionsV6 } from '../../components/TodoPageActionsV6'
import { TodosBatchEditOverlay } from '@behavehealth/constructs/Calendar/TodosBatchEditOverlay'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'

import TodoBuilderOverlay from '@behavehealth/components/Overlays/pages/Calendar/TodoBuilderOverlay'
import TodoOverlay from '@behavehealth/components/Overlays/pages/Calendar/TodoOverlay'

const pageConfig = {
  feature: 'todos',
  help: <HelpTagIframe id="company_todos" />,
}

const TABS = {
  table: 'table/all',
  calendar: 'calendar',
}

const Todos = () => {
  const defaultTab = useTodosTabSettings((store) => store.defaultTab)
  const setDefaultTab = useTodosTabSettings((store) => store.setDefaultTab)

  const defaultTabRoute = TABS?.[defaultTab] || TABS.table

  return (
    <>
      <Page breakpoint="0" actions={<TodoPageActionsV6 featureFlag="staff_todo" />} {...pageConfig}>
        <Grid gap={16}>
          <Tabs>
            <TabList css={styles.tabList}>
              <Tab
                as={NavLink}
                label="Calendar View"
                to={`calendar`}
                onClick={() => {
                  setDefaultTab('calendar')
                }}
              />
              <Tab
                as={NavLink}
                label="Batch Edit View"
                to={`table/all`}
                onClick={() => {
                  setDefaultTab('table')
                }}
                permission="todos.admin_view.view"
              />
            </TabList>
          </Tabs>

          <Routes>
            <Route index element={<Navigate to={defaultTabRoute} replace />} />

            <Route path={`table/all/*`} element={<TodosTablePage />} />
            <Route path={`calendar/*`} element={<TodosCalendarPage />} />
          </Routes>
        </Grid>
      </Page>

      <AnimatedRoutes>
        <Route
          path={`:view/:resource_type/todo-builder`}
          element={
            <ProtectedRouteV6 featureFlag="todos" permission="todos.view">
              <TodoBuilderOverlay useV6Router />
            </ProtectedRouteV6>
          }
        />
        <Route
          path={`:view/:resource_type/:id`}
          element={
            <ProtectedRouteV6 featureFlag="todos" permission="todos.view">
              <TodoOverlay useV6Router />
            </ProtectedRouteV6>
          }
        />
      </AnimatedRoutes>
    </>
  )
}

const TodosCalendarPage = () => {
  return (
    <>
      <Grid gap={16}>
        <Tabs>
          <TabList css={styles.tabList}>
            <Tab as={NavLink} label="Active" to={`active`} />
            <Tab as={NavLink} label="Completed" to={`completed`} />
          </TabList>
        </Tabs>

        <Routes>
          <Route index element={<Navigate to="active" replace />} />

          <Route path={`active/*`} element={<Active />} />
          <Route path={`completed/*`} element={<Completed />} />
        </Routes>
      </Grid>
    </>
  )
}

const TodosTablePage = () => {
  const tableProps = useDataTable({
    name: ['todos'],
    endpoint: `/events`,
    params: { event_type: 'task' },
  })

  const to = React.useMemo(() => (rowData: any) => rowData.id, [])

  return (
    <TodosDataTable
      {...tableProps}
      to={to}
      mainLinkAs={NavLink}
      renderBatchActions={({ selectedRows, selectNone }: any) => (
        <Flex gap="1rem">
          <SummonOverlay overlay={<TodosBatchEditOverlay data={selectedRows} afterSave={selectNone} />}>
            <Button label="Batch Edit" glyph="edit" type="minimal" size={100} onClick={selectNone} isDisabled={selectedRows.length === 0} />
          </SummonOverlay>
        </Flex>
      )}
      batchActionsConfig={[
        {
          type: 'delete',
          permission: 'todos.delete',
          action: async ({ ids }: any) => {
            await tableProps.deleteRecords(ids.join(','))
          },
        },
      ]}
    />
  )
}

const useTodosTabSettings = create(
  persist(
    (set: any, get: any) => ({
      defaultTab: 'table',
      setDefaultTab: (tab: any) => {
        if (!TABS.hasOwnProperty(tab)) return

        set({ defaultTab: tab })
      },
    }),
    { name: 'bh.todos-tabs' },
  ),
)

const styles = {
  tabList: {
    marginTop: '-1rem',
  },
}

export default withPageError(withMarketing(Todos, pageConfig))
