import React from 'react'
import size from 'lodash/size'

import { DataTableCell } from '../DataTableCell'
import { EmptyCell } from './EmptyCell'

import { ProfileCellInner } from './ProfileCell'

export const ProfilesCell: React.FC = (props: any) => {
  const { value, icon, column, rowData } = props

  const link = column?.config?.setLink?.({ value, rowData })

  return (
    <DataTableCell hoverExpand css={STYLES.root} {...props}>
      {size(value) === 0 ? (
        <EmptyCell />
      ) : (
        <>
          {Array.isArray(value) ? (
            value.map((o) => <ProfileCellInner value={o} icon={icon} link={column?.config?.setLink?.({ value: o, rowData })} />)
          ) : (
            <ProfileCellInner value={value} icon={icon} link={link} />
          )}
        </>
      )}
    </DataTableCell>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',

    '.DataTableCell-value': {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      alignSelf: 'stretch',
      flex: '1 1 auto',
      overflow: 'hidden',

      '& > *': {
        overflow: 'hidden',
        marginRight: '1rem',
        minWidth: 60,
      },
    },

    '&:hover': {
      overflow: 'visible',

      '.DataTableCell-value': {
        overflow: 'visible',
        display: 'grid !important',
        gridGap: '0.5rem',

        '& > *': {
          overflow: 'visible',
          marginRight: '0',
          minWidth: 'auto',
        },
      },
    },
  },
}
