import React from 'react'
import { useSelector } from 'react-redux'

import ErrorBoundary, { FallbackComponent } from '../components/ErrorBoundary'
import Overlay from '../components/Overlay'

export const withOverlayError = (Wrapped: any) => (props: any) => {
  const user = useSelector((state) => state.me.user)

  return (
    <ErrorBoundary
      fallback={
        <Overlay position="center" minHeight={20} {...props}>
          <Overlay.Content>
            <FallbackComponent user={user} showHomeButton={false} />
          </Overlay.Content>
        </Overlay>
      }
    >
      <Wrapped {...props} />
    </ErrorBoundary>
  )
}
