import React from 'react'
import clsx from 'clsx'

import BHAlert from '../../../../components/Alert'

import { getFormElementProps } from '../../utils/functions'
import { ALERT_CATEGORIES } from '../../utils/constants'
import { Box } from './Box'

export const Alert: React.FC<any> = React.forwardRef((props: any, ref) => {
  const { children, element, className, hoverElement } = props

  if (!element) return null

  const rootClasses = clsx('ALERT', className)

  const categoryConfig = ALERT_CATEGORIES[element?.config?.category || 'info']

  return (
    <Box ref={ref} element={element} className={rootClasses} hoverElement={hoverElement}>
      <BHAlert
        contrast
        {...categoryConfig}
        css={STYLES.alert}
        {...(element?.config?.category === 'custom' && {
          glyph: element?.config?.glyph,
          color: element?.config?.color,
        })}
      >
        {children}
      </BHAlert>
    </Box>
  )
})

const STYLES = {
  alert: {
    '.ProseMirror > *': {
      marginBottom: '0 !important',
    },

    '.ProseMirror > *+*': {
      marginTop: '0.4rem !important',
      marginBottom: '0 !important',
    },
  },
}
