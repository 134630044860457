import React from 'react'

import Input from '../Input'

const WeightInput = ({ model, isRequired }) => (
  <Input
    type="number"
    label="Weight"
    model={model}
    suffix="pounds"
    size={3}
    validations={
      isRequired && {
        presence: {
          message: 'Please enter a weight',
        },
      }
    }
  />
)

WeightInput.defaultProps = {
  model: 'weight',
}

export default WeightInput
