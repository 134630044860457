import React from 'react'

import SmartTextarea from '../../../../Forms/SmartTextarea'
import FormSection from '../../../../Forms/FormSection'

const MentalHealthHistory = () => {
  return (
    <FormSection layout="vertical">
      <SmartTextarea useDictation label="Notes" model="data.mental_health_history.notes" />
    </FormSection>
  )
}

export default MentalHealthHistory
