import React from 'react'
import { DateTime } from 'luxon'
import { Link, NavLink, useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { COLORS } from '@behavehealth/theme'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { getResourceLink } from '@behavehealth/utils/functions'
import PhaseTable from '@behavehealth/components/Tables/PhaseTable'
import Avatar from '@behavehealth/components/Avatar'
import Switch from '@behavehealth/components/Forms/Switch'

import { Button, Card, CardContent, CardHeader, CardSubtitle, CardTitle, Flex, PageLayout, State } from '@behavehealth/components'

const compare = (a: any, b: any) => {
  if (a.order < b.order) return -1
  else return 1
}

const getCurrentOccupancies = (data: any) => {}

const RootListsTable: React.FC = ({ setPhasesCount }: any) => {
  const { resource_id }: any = useParams()

  const { timezone } = useSettings()
  const today = DateTime.local().setZone(timezone)

  const [showAllAssignments, setShowAllAssignments] = React.useState(false)

  const { data, isLoading }: any = useGet({
    name: ['program', resource_id, 'phases-with-residents'],
    url: `/programs/${resource_id}/phases_with_residents`,
    options: { enabled: !!resource_id },
  })

  const isEmpty = size(data) === 0

  const phasesArray = data?.sort(compare)

  React.useEffect(() => {
    if (!data) return

    setPhasesCount(size(data))
  }, [data])

  return (
    <div css={STYLES.main}>
      {isLoading || isEmpty || !phasesArray ? (
        <Card>
          <State
            isLoading={isLoading}
            isEmpty={isEmpty}
            title="Program Lists"
            icon="housing_shifts"
            emptyDescription="No program lists created yet"
          />
        </Card>
      ) : (
        <PageLayout>
          <Card className="!px-4 !py-2">
            <Flex justifyContent="space-between" alignItems="center" className="!w-full">
              <div css={STYLES.dateHeading}>
                <span css={STYLES.dateHeadingBold}>{today.toFormat('LLL dd')}</span>
                <span css={STYLES.dateHeadingRegular}> {today.toFormat('kkkk')}</span>
              </div>

              <Flex horizontal gap="0.5rem" alignItems="center">
                <span css={!showAllAssignments ? STYLES.label.active : STYLES.label.inactive}>Show Current Assignments</span>
                <Switch label="" size={200} value={showAllAssignments} onUpdate={(state: any) => setShowAllAssignments(state.value)} />
                <span css={showAllAssignments ? STYLES.label.active : STYLES.label.inactive}>Show Today's Changes</span>
              </Flex>
            </Flex>
          </Card>

          {phasesArray?.map((phase: any, idx: number) => {
            let phaseOccupancies = []

            if (showAllAssignments) {
              phaseOccupancies = phase.todays_seat_occupancies
            } else {
              phaseOccupancies = phase.todays_seat_occupancies?.filter((occupancy: any) => !occupancy.in_the_past)
            }

            return (
              <Card key={`${phase.id}_${phase.order}_${idx}`}>
                <CardHeader
                  after={
                    <Flex gap={8}>
                      <Button
                        as={Link}
                        label="Add Assignment"
                        glyph="chair"
                        type="minimal"
                        size={200}
                        link={`${phase.id}/assign-seats`}
                        permission="programs.edit"
                      />

                      <Button
                        as={Link}
                        label="Add Seat"
                        glyph="add"
                        type="minimal"
                        size={200}
                        link={`${phase.id}/seats/new`}
                        permission="programs.edit"
                      />

                      <Button
                        as={Link}
                        label="Edit List"
                        glyph="edit"
                        type="minimal"
                        size={200}
                        link={`${phase.id}`}
                        permission="programs.edit"
                      />
                    </Flex>
                  }
                >
                  <CardTitle
                    title={phase.name}
                    after={
                      <div css={{ color: COLORS.textMuted, fontSize: '0.95rem', fontWeight: 400, marginLeft: '1rem' }}>
                        {phase.seats_count - phase.todays_seat_occupancies_count} Available / {phase.seats_count} Seats
                      </div>
                    }
                  />

                  {phase.description && <CardSubtitle subtitle={phase.description} />}

                  {size(phase.employees) > 0 && (
                    <Flex centerY gap="0.5rem">
                      <div css={{ fontWeight: 500, fontSize: '0.94rem' }}>Managing Staff: </div>

                      {phase.employees.map((record: any) => (
                        <div key={record.id} className="text-[0.9rem]">
                          <Flex centerY gap="0.4rem">
                            <Avatar src={record.avatar} initials={record.name} size={20} />
                            <div>{record.name}</div>
                          </Flex>
                        </div>
                      ))}
                    </Flex>
                  )}
                </CardHeader>

                <CardContent padding="0">
                  <PhaseTable
                    mainLinkAs={NavLink}
                    showSettings={false}
                    title={phase.name}
                    data={phaseOccupancies}
                    localStorageKey="programs_phase"
                    to={(reference: any) => getResourceLink(reference)}
                    moveLink={(id: any) => `${id}/move`}
                    removeLink={(id: any) => `${id}/remove`}
                    emptyActions={
                      <Button
                        as={Link}
                        label="Add Assignment"
                        glyph="chair"
                        type="minimal"
                        size={200}
                        link={`${phase.id}/assign-seats`}
                        permission="programs.edit"
                      />
                    }
                  />
                </CardContent>
              </Card>
            )
          })}
        </PageLayout>
      )}
    </div>
  )
}

const STYLES: any = {
  main: {
    padding: '1rem',
    overflow: 'auto',
    // display: 'grid',
    // gridTemplateRows: '100%',
    // gridTemplateColumns: '100%',
    // overflow: 'hidden !important',
  },

  label: {
    active: {
      fontWeight: 600,
      color: COLORS.text,
    },
    inactive: {
      fontWeight: 600,
      color: COLORS.textStronglyMuted,
    },
  },

  dateHeading: {
    fontSize: '1.25rem',
    fontWeight: 400,
    marginRight: '0.75rem',
  },

  dateHeadingBold: {
    fontWeight: 700,
  },

  dateHeadingRegular: {
    fontSize: '0.92em',
    color: COLORS.textMuted,
  },
}

export const ListsTable = withPageError(RootListsTable)
