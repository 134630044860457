import React from 'react'

import Avatar from '../../Avatar'
import Empty from './Empty'
import QuickViewOverlay from './QuickViewOverlay'

const ProfileQuickViewOverlay: React.FC = ({ avatar, name, overlay }) => {
  if (!name) return <Empty />

  return (
    <QuickViewOverlay overlay={overlay}>
      <div css={{ display: 'flex', alignItem: 'center' }}>
        <Avatar src={avatar} size="1.5rem" initials={name} css={{ marginRight: '0.4rem' }} magnify />
        {name}
      </div>
    </QuickViewOverlay>
  )
}

export default ProfileQuickViewOverlay
