import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'

import { Glyph, Alert, Button, Icon, Link, Page, PageLayout, PageSection, SummonOverlay, HelpTagIframe } from '@behavehealth/components'

import BookmarksGrid from '@behavehealth/components/Bookmarks/BookmarksGrid'
import BookmarksOverlay from '@behavehealth/components/Bookmarks/BookmarksOverlay'

const BookmarksManager = () => {
  return (
    <Page
      title="Bookmarks Manager"
      feature="bookmarks_manager"
      emptyDescription="Create quick links to all your daily apps below"
      help={<HelpTagIframe id="settings_bookmarks" />}
    >
      <PageLayout>
        <Alert contrast glyph="info">
          The following quick links will show up on your <Link to="/dashboard">Dashboard Page</Link>.
        </Alert>

        <PageSection>
          <PageSection.Header
            graphic={<Icon icon="patient" />}
            after={
              <SummonOverlay overlay={<BookmarksOverlay category="personal" />}>
                <Button label="Manage Personal Bookmarks" type="primary" />
              </SummonOverlay>
            }
          >
            <PageSection.Title title="Personal Bookmarks" />
            <PageSection.Subtitle subtitle="These bookmarks will show up only for you" />
          </PageSection.Header>

          <PageSection.Content>
            <BookmarksGrid category="personal" />
          </PageSection.Content>
        </PageSection>

        <PageSection>
          <PageSection.Header
            graphic={<Icon icon="enterprise" />}
            after={
              <SummonOverlay overlay={<BookmarksOverlay category="company" />}>
                <Button label="Manage Company Bookmarks" type="primary" />
              </SummonOverlay>
            }
          >
            <PageSection.Title title="Company Bookmarks" />
            <PageSection.Subtitle subtitle="These bookmarks are visible to all staff" />
          </PageSection.Header>

          <PageSection.Content>
            <BookmarksGrid category="company" />
          </PageSection.Content>
        </PageSection>

        <PageSection>
          <PageSection.Header
            graphic={<Glyph glyph="portal" />}
            after={
              <SummonOverlay overlay={<BookmarksOverlay category="client_portal" />}>
                <Button label="Manage Client Portal Bookmarks" type="primary" />
              </SummonOverlay>
            }
          >
            <PageSection.Title title="Client Portal Bookmarks" />
            <PageSection.Subtitle subtitle="These bookmarks are visible to all Client Portal users" />
          </PageSection.Header>

          <PageSection.Content>
            <BookmarksGrid category="client_portal" />
          </PageSection.Content>
        </PageSection>
      </PageLayout>
    </Page>
  )
}

export default withPageError(BookmarksManager)
