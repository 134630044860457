import React from 'react'

import { COLORS } from '../../theme'
import Card from '../Card'

const FlowChartItem = ({ children, lineTop, lineBottom, lineLeft, lineRight, row, column }: any) => {
  return (
    <Card css={{ ...styles, gridRow: row, gridColumn: column }}>
      {children}
      {lineTop && <div className="line top" />}
      {lineBottom && <div className="line bottom" />}
      {lineLeft && <div className="line left" />}
      {lineRight && <div className="line right" />}
    </Card>
  )
}

const styles = {
  fontSize: '1.2rem',
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  overflow: 'visible',
  position: 'relative',

  '.line': {
    background: COLORS.divider,
    position: 'absolute',
  },

  '.line.top, .line.bottom': {
    width: '1px',
    height: 'var(--gap-y)',
  },

  '.line.left, .line.right': {
    width: 'var(--gap-x)',
    height: '1px',
  },

  '.line.top': {
    top: 'calc(var(--gap-y) * -1)',
  },

  '.line.bottom': {
    bottom: 'calc(var(--gap-y) * -1)',
  },

  '.line.left': {
    left: 'calc(var(--gap-x) * -1)',
  },

  '.line.right': {
    right: 'calc(var(--gap-x) * -1)',
  },
}

export default FlowChartItem
