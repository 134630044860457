import React from 'react'

import ContextShow from '../../../components/Forms/ContextShow'
import Flex from '../../../components/Flex'
import Input from '../../../components/Forms/Input'
import Label from '../../../components/Label'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import TableArrayForm from '../../../components/Forms/TableArrayForm'
import Textarea from '../../../components/Forms/Textarea'
import YesNoRadioGroupWithTextarea from '../../../components/Forms/elements/YesNoRadioGroupWithTextarea'

export const CLIENT_HEALTH_QUESTIONNAIRE_INITIAL_SCREENING_SECTIONS = [
  {
    title: 'Physical',
    model: 'physical',
    fields: [
      {
        label: 'Has the client ever had a heart attack or any problem associated with the heart?',
        model: 'q1',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever had a heart attack or any problem associated with the heart?"
            textareaLabel="Please list when, what was the diagnosis and if you are currently taking medication:"
            model="data.q1"
          />
        ),
      },
      {
        label: 'Is the client currently experiencing chest pain(s)?',
        model: 'q2',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Is the client currently experiencing chest pain(s)?"
            textareaLabel="Please give details:"
            model="data.q2"
          />
        ),
      },
      {
        label:
          'Does the client have any serious health problems or illnesses (such as tuberculosis or active pneumonia) that may be contagious to others around them?',
        model: 'q3',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client have any serious health problems or illnesses (such as tuberculosis or active pneumonia) that may be contagious to others around them?"
            textareaLabel="Please give details:"
            model="data.q3"
          />
        ),
      },
      {
        label: 'Has the client ever tested positive for tuberculosis?',
        model: 'q4',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever tested positive for tuberculosis?"
            textareaLabel="When? Please give details:"
            model="data.q4"
          />
        ),
      },
      {
        label: 'Has the client ever been treated for HIV or AIDS?',
        model: 'q5',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever been treated for HIV or AIDS?"
            textareaLabel="When? Please give details:"
            model="data.q5"
          />
        ),
      },
      {
        label: 'Has the client ever been tested for sexually transmitted diseases?',
        model: 'q6',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever been tested for sexually transmitted diseases?"
            textareaLabel="Please give details and list any medications you are taking:"
            model="data.q6"
          />
        ),
      },
      {
        label:
          'Has the client had a head injury in the last six (6) months? Has the client ever had a head injury that resulted in a period of loss of consciousness?',
        model: 'q7',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client had a head injury in the last six (6) months? Has the client ever had a head injury that resulted in a period of loss of consciousness?"
            textareaLabel="Please give details:"
            model="data.q7"
          />
        ),
      },
      {
        label: 'Has the client ever been diagnosed with diabetes?',
        model: 'q8',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever been diagnosed with diabetes?"
            textareaLabel="Please give details, including insulin, oral medications, or special diet:"
            model="data.q8"
          />
        ),
      },
      {
        label: 'Does the client have any open lesions or wounds?',
        model: 'q9',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client have any open lesions or wounds?"
            textareaLabel="Please explain and list any medications you are taking:"
            model="data.q9"
          />
        ),
      },
      {
        label: 'Has the client ever had any form of seizures, delirium tremens or convulsions?',
        model: 'q10',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever had any form of seizures, delirium tremens or convulsions?"
            textareaLabel="Date of last seizure episode(s) and list any medications you are taking:"
            model="data.q10"
          />
        ),
      },
      {
        label: 'Does the client use a C-PAP machine or depend on oxygen?',
        model: 'q11',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client use a C-PAP machine or depend on oxygen?"
            textareaLabel="Please explain:"
            model="data.q11"
          />
        ),
      },
      {
        label: 'Has the client ever had a stroke?',
        model: 'q12',
        component: () => (
          <YesNoRadioGroupWithTextarea label="Has the client ever had a stroke?" textareaLabel="Please give details:" model="data.q12" />
        ),
      },
      {
        label: 'Is the client pregnant?',
        model: 'q13',
        component: () => (
          <>
            <RadioGroup label="Is the client pregnant?" layout="horizontal-dense" model="data.q13">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.q13" is={true}>
              <RadioGroup label="Which Trimester:" model="data.q13_trimester" layout="horizontal-dense">
                <Radio label="1st" value={1} />
                <Radio label="2nd" value={2} />
                <Radio label="3rd" value={3} />
              </RadioGroup>

              <RadioGroup label="Is the client receiving prenatal care?" model="data.q13_pre_natal_care" layout="horizontal-dense">
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <RadioGroup label="Any complications?" model="data.q13_has_complications" layout="horizontal-dense">
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.q13_has_complications" is={true}>
                <Textarea useQuickText label="Please explain:" model="data.q13_has_complications_description" />
              </ContextShow>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Does the client have a history of any other illness that may require frequent medical attention?',
        model: 'q14',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client have a history of any other illness that may require frequent medical attention?"
            textareaLabel="Please give details and list any medications you are taking:"
            model="data.q14"
          />
        ),
      },
      {
        label: 'Has the client ever had blood clots in the legs or elsewhere that required medical attention?',
        model: 'q15',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever had blood clots in the legs or elsewhere that required medical attention?"
            textareaLabel="Please give details:"
            model="data.q15"
          />
        ),
      },
      {
        label: 'Has the client ever had high-blood pressure or hypertension?',
        model: 'q16',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever had high-blood pressure or hypertension?"
            textareaLabel="Please give details:"
            model="data.q16"
          />
        ),
      },
      {
        label: 'Does the client have a history of cancer?',
        model: 'q17',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client have a history of cancer?"
            textareaLabel="Please give details and list any medications you are taking:"
            model="data.q17"
          />
        ),
      },
      {
        label: 'Does the client have any allergies to medications, foods, animals, chemicals, or any other substance?',
        model: 'q18',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client have any allergies to medications, foods, animals, chemicals, or any other substance?"
            textareaLabel="Please give details and list any medications you are taking:"
            model="data.q18"
          />
        ),
      },
      {
        label: 'Has the client ever had an ulcer, gallstones, internal bleeding, or any type of bowel or colon inflammation?',
        model: 'q19',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever had an ulcer, gallstones, internal bleeding, or any type of bowel or colon inflammation?"
            textareaLabel="Please give details:"
            model="data.q19"
          />
        ),
      },
      {
        label: 'Has the client ever been diagnosed with any type of hepatitis or other liver illness?',
        model: 'q20',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever been diagnosed with any type of hepatitis or other liver illness?"
            textareaLabel="Please give details and list any medications you are taking:"
            model="data.q20"
          />
        ),
      },
      {
        label:
          'Has the client ever reported problems with their thyroid gland, been treated for it, or told they need to be treated for it, or any other type of glandular disease?',
        model: 'q21',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever reported problems with their thyroid gland, been treated for it, or told they need to be treated for it, or any other type of glandular disease?"
            textareaLabel="Please give details:"
            model="data.q21"
          />
        ),
      },
      {
        label: 'Does the client currently have any lung diseases such as asthma, emphysema, or chronic bronchitis?',
        model: 'q22',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client currently have any lung diseases such as asthma, emphysema, or chronic bronchitis?"
            textareaLabel="Please give details:"
            model="data.q22"
          />
        ),
      },
      {
        label:
          'Has the client ever had kidney stones or kidney infections, or had problems, or been told they have problems with their kidneys or bladder?',
        model: 'q23',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever had kidney stones or kidney infections, or had problems, or been told they have problems with their kidneys or bladder?"
            textareaLabel="Please give details:"
            model="data.q23"
          />
        ),
      },
      {
        label: 'Does the client have any of the following: arthritis, back problems, bone injuries, muscle injuries, or joint injuries?',
        model: 'q24',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client have any of the following: arthritis, back problems, bone injuries, muscle injuries, or joint injuries?"
            textareaLabel="Please give details, including any ongoing pain or disabilities:"
            model="data.q24"
          />
        ),
      },
      {
        label: 'Does the client take over-the-counter pain medications such as aspirin, Tylenol, or Ibuprofen?',
        model: 'q25',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client take over-the-counter pain medications such as aspirin, Tylenol, or Ibuprofen?"
            textareaLabel="List the medication(s) and how often you take it:"
            model="data.q25"
          />
        ),
      },
      {
        label: 'Does the client take over-the-counter digestive medications such as Tums or Maalox?',
        model: 'q26',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client take over-the-counter digestive medications such as Tums or Maalox?"
            textareaLabel="List the medication(s) and how often you take it:"
            model="data.q26"
          />
        ),
      },
      {
        label: 'Does the client wear or need to wear glasses, contact lenses, or hearing aids?',
        model: 'q27',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client wear or need to wear glasses, contact lenses, or hearing aids?"
            textareaLabel="Please give details:"
            model="data.q27"
          />
        ),
      },
      {
        label: "When was the client's last dental exam?",
        model: 'q28',
        component: () => (
          <YesNoRadioGroupWithTextarea label="When was the client's last dental exam?" textareaLabel="Date:" model="data.q28" />
        ),
      },
      {
        label: 'Is the client in need of dental care?',
        model: 'q29',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Is the client in need of dental care?"
            textareaLabel="Please give details:"
            model="data.q29"
          />
        ),
      },
      {
        label: 'Does the client wear or need to wear dentures or other dental appliances that may require dental care?',
        model: 'q30',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Does the client wear or need to wear dentures or other dental appliances that may require dental care?"
            textareaLabel="Please give details:"
            model="data.q30"
          />
        ),
      },
      {
        label: 'Please describe any surgeries or hospitalizations due to illness or injury that the client has had in the past.',
        model: 'q31',
        component: () => (
          <Textarea
            useQuickText
            label="Please describe any surgeries or hospitalizations due to illness or injury that the client has had in the past."
            model="data.q31"
          />
        ),
      },
      {
        label:
          'When was the last time the client saw a physician and/or psychiatrist? What was the purpose of the visit? Please give details',
        model: 'q32',
        component: () => (
          <Textarea
            useQuickText
            label="When was the last time the client saw a physician and/or psychiatrist? What was the purpose of the visit? Please give details:"
            model="data.q32"
          />
        ),
      },
      {
        label: 'In the past seven days, what types of drugs, including alcohol, has the client used?',
        model: 'q33',
        component: () => (
          <div>
            <Label label="In the past seven days, what types of drugs, including alcohol, has the client used?" />
            <TableArrayForm name="Type of Drug" model="data.q33" inputs={DRUGS_TABLE_INPUTS} buttonLabel="Add" />
          </div>
        ),
      },
      {
        label: 'In the past year, what types of drugs, including alcohol, has the client used?',
        model: 'q34',
        component: () => (
          <div>
            <Label label="In the past year, what types of drugs, including alcohol, has the client used?" />
            <TableArrayForm name="Type of Drug" model="data.q34" inputs={DRUGS_TABLE_INPUTS} buttonLabel="Add" />
          </div>
        ),
      },
      {
        label: 'Does the client take any prescription medications including psychiatric medications?',
        model: 'q35',
        component: () => (
          <div>
            <Label label="Does the client take any prescription medications including psychiatric medications?" />
            <TableArrayForm name="Type of Drug" model="data.q35" inputs={DRUGS_TABLE_INPUTS} buttonLabel="Add" />
          </div>
        ),
      },
    ],
  },
  {
    title: 'Mental/Emotional',
    model: 'mental_emotional',
    fields: [
      {
        label: 'Is the client currently feeling down, depressed, anxious, or hopeless?',
        model: 'q36',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Is the client currently feeling down, depressed, anxious, or hopeless?"
            textareaLabel="Please describe:"
            model="data.q36"
          />
        ),
      },
      {
        label: 'Is the client currently receiving treatment services for an emotional/psychiatric diagnosis?',
        model: 'q37',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Is the client currently receiving treatment services for an emotional/psychiatric diagnosis?"
            textareaLabel="For what are you being treated?"
            model="data.q37"
          />
        ),
      },
      {
        label:
          'Over the last 2 weeks, has the client felt nervous, anxious, or on edge? Did they feel like they were unable to stop or control their worrying?',
        model: 'q38',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Over the last 2 weeks, has the client felt nervous, anxious, or on edge? Did they feel like they were unable to stop or control their worrying?"
            textareaLabel="Please describe:"
            model="data.q38"
          />
        ),
      },
      {
        label: 'Over the last 2 weeks, has the client had thoughts of suicide or thought that they would be better off dead?',
        model: 'q39',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Over the last 2 weeks, has the client had thoughts of suicide or thought that they would be better off dead?"
            textareaLabel="Please describe:"
            model="data.q39"
          />
        ),
      },
      {
        label: 'Has the client attempted suicide in the past two (2) years?',
        model: 'q40',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client attempted suicide in the past two (2) years?"
            textareaLabel="Give dates:"
            model="data.q40"
          />
        ),
      },
      {
        label: 'Has the client ever harmed themselves or others, or thought about harming themselves or others?',
        model: 'q41',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever harmed themselves or others, or thought about harming themselves or others?"
            textareaLabel="Please describe:"
            model="data.q41"
          />
        ),
      },
      {
        label: 'Is the client currently feeling that they’re hearing voices or seeing things?',
        model: 'q42',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Is the client currently feeling that they’re hearing voices or seeing things?"
            textareaLabel="Please describe:"
            model="data.q42"
          />
        ),
      },
      {
        label: 'Has the client ever been in a relationship where their partner has pushed or slapped them?',
        model: 'q43',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever been in a relationship where their partner has pushed or slapped them?"
            textareaLabel="Please describe:"
            model="data.q43"
          />
        ),
      },
    ],
  },
  {
    title: 'Previous Drug and/or Alcohol Treatment Services',
    model: 'previous_treatment_services',
    fields: [
      {
        label: 'Has the client received alcoholism or drug abuse recovery treatment services in the past?',
        model: 'q44',
        component: () => (
          <>
            <RadioGroup
              label="Has the client received alcoholism or drug abuse recovery treatment services in the past?"
              model="data.q44"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.q44" is={true}>
              <TableArrayForm
                name="Previous Recovery Treatment Services"
                model="data.q44_data"
                inputs={TREATMENT_SERVICES_TABLE_INPUTS}
                buttonLabel="Add"
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Has the client ever been treated for withdrawal symptoms?',
        model: 'q45',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Has the client ever been treated for withdrawal symptoms?"
            textareaLabel="Please state the dates you were treated and list any medications that were prescribed:"
            model="data.q45"
          />
        ),
      },
      {
        label:
          'The client has been informed of the risks and benefits of Medications for Addiction Treatment (MAT) also known as Medication Assisted Treatment. Additionally, the provider described the availability of MAT at the program, if applicable, or the referral process for MAT.',
        model: 'q46',
        component: () => (
          <div>
            <Label label="The client has been informed of the risks and benefits of Medications for Addiction Treatment (MAT) also known as Medication Assisted Treatment. Additionally, the provider described the availability of MAT at the program, if applicable, or the referral process for MAT." />

            <Flex gap="0.75rem">
              <Input label="Client Initial" model="data.q46_client_initial" />
              <Input label="Staff Initial" model="data.q46_staff_initial" />
            </Flex>
          </div>
        ),
      },
      {
        label:
          'The client has been screened for use of all tobacco products utilizing questions recommended in the most recent version of the Diagnostic and Statistical Manual of Mental Disorders under tobacco use disorder, or similar evidence-based guidance, for determining that an individual has a tobacco use disorder.',
        model: 'q47',
        component: () => (
          <div>
            <Label label="The client has been screened for use of all tobacco products utilizing questions recommended in the most recent version of the Diagnostic and Statistical Manual of Mental Disorders under tobacco use disorder, or similar evidence-based guidance, for determining that an individual has a tobacco use disorder." />

            <Flex gap="0.75rem">
              <Input label="Client Initial" model="data.q47_client_initial" />
              <Input label="Staff Initial" model="data.q47_staff_initial" />
            </Flex>
          </div>
        ),
      },
      {
        label: 'Additional Comments',
        model: 'additional_comments',
        component: () => <Textarea useQuickText label="Additional Comments:" model="data.additional_comments" />,
      },
    ],
  },
]

const DRUGS_TABLE_INPUTS = [
  {
    name: 'Type of Drug',
    width: '1fr',
    isRequired: true,
    element: (id: string, data: any) => <Input model={`${id}.drug_type`} value={data.drug_type} />,
  },
  {
    name: 'Route of Administration',
    width: '1fr',
    isRequired: true,
    element: (id: string, data: any) => <Input model={`${id}.administration_route`} value={data.administration_route} />,
  },
]

const TREATMENT_SERVICES_TABLE_INPUTS = [
  {
    name: 'Type of Previous Recovery Treatment (Outpatient, Residential, Detoxification)',
    width: '1fr',
    isRequired: true,
    element: (id: string, data: any) => <Input model={`${id}.recovery_treatment_type`} value={data.recovery_treatment_type} />,
  },
  {
    name: 'Name of Previous Treatment Facility',
    width: '1fr',
    isRequired: true,
    element: (id: string, data: any) => <Input model={`${id}.previous_facility_name`} value={data.previous_facility_name} />,
  },
  {
    name: 'Dates of Previous Treatment',
    width: '1fr',
    isRequired: true,
    element: (id: string, data: any) => <Input model={`${id}.previous_treatment_dates`} value={data.previous_treatment_dates} />,
  },
  {
    name: 'Treatment Completed?',
    width: '1fr',
    isRequired: true,
    element: (id: string, data: any) => (
      <RadioGroup
        label={null}
        withHover={false}
        layout="horizontal-dense"
        model={`${id}.did_complete_treatment`}
        value={data.did_complete_treatment}
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>
    ),
  },
]
