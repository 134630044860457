import React from 'react'

import CardLink from '../CardLink'
import CardHeader from '../CardHeader'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'
import Iframe from '../Iframe'

import { apiGet, apiCreate } from '../../modules/api'
import { URL } from '../../utils/globals'
import { COLORS } from '../../theme'

type Props = {
  title: any
  value?: any
  rcopiaExternalID?: string
  startupScreen?: string
  limpMode?: boolean
}

const ERXCard = ({ value, title, rcopiaExternalID, startupScreen = 'patient', limpMode = false }: Props) => {
  const [loading, setLoading] = React.useState(false)

  const getSSOURL = async () => {
    let params = {
      rcopia_user_external_id: 'ext_doc_123',
      startup_screen: startupScreen,
      rcopia_patient_external_id: rcopiaExternalID,
      limp_mode: limpMode ? 'y' : 'n',
    }

    try {
      setLoading(true)
      const result = await apiGet({ url: `${URL.api}/apps/rcopia/sso`, params: params })
      setLoading(false)
      return result.data.data
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const syncPatient = () => {
    try {
      apiCreate({
        url: `${URL.api}/apps/rcopia/async_patient`,
        params: { external_id: rcopiaExternalID },
        notify: false,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Iframe overlayWidth={180} title="e-Prescribing Portal" processURL={getSSOURL}>
      <CardLink isLink showChevron isLoading={loading}>
        <CardTitle title={value} css={styles.title} />
        <CardSubtitle subtitle={title} css={styles.subtitle} />
      </CardLink>
    </Iframe>
  )
}

const styles = {
  title: { fontSize: '1.2rem', fontWeight: 700, color: `${COLORS.red} !important` },
  subtitle: { fontSize: '1rem', fontWeight: 600 },
}

export default ERXCard
