import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { COLORS } from '@behavehealth/theme'
import { mapToArray } from '@behavehealth/utils/functions'

import { Button, Card, Flex, Grid, Tabs, TreeItem } from '@behavehealth/components'

import ClaimInfo from '@behavehealth/constructs/Insurance/components/ClaimInfo'
import ClaimInternalStatus from '@behavehealth/components/Statuses/ClaimInternalStatus'

import { CLAIMS, CLAIM_TASKS, CLAIM_NOTES } from '@behavehealth/components/RCM/data'

const Collections = () => {
  const match = useRouteMatch()

  return (
    <Card>
      {mapToArray(CLAIMS).map((claim: any) => (
        <TreeItem
          css={styles.treeItem}
          title={
            <Flex centerY gap="0.75rem">
              <div>{claim.dates}</div>
              <ClaimInternalStatus status={claim.internal_status} />
            </Flex>
          }
        >
          <div css={styles.treeItemContent}>
            <Tabs defaultTab="claim_info">
              <Tabs.List css={styles.tabsList}>
                <Tabs.Item label="Claim Info" icon="general_info" name="claim_info" />
                <Tabs.Item label="Tasks & Notes" icon="tasks" name="tasks_notes" />
                <Tabs.Item label="Outcome" icon="intake_forms" name="outcome" />
              </Tabs.List>

              <Tabs.Panels>
                <Tabs.Panel name="claim_info">
                  <Grid gap="0.5rem">
                    <ClaimInfo claim={claim} />

                    <div>
                      <Button
                        label="View Full Claim"
                        type="primary"
                        size={200}
                        display="inline-flex"
                        link={{
                          pathname: `${match.url}/claims/${claim.id}`,
                          parent: match,
                        }}
                      />
                    </div>
                  </Grid>
                </Tabs.Panel>

                <Tabs.Panel name="tasks_notes">
                  <Grid gap="0.75rem">
                    <Card>
                      <ClaimNotesTable
                        data={CLAIM_NOTES}
                        to={(id: string) => ({
                          pathname: `${match.url}/notes/${id}`,
                          parent: match,
                        })}
                        titleAfter={
                          <Button
                            label="Add Claim Note"
                            glyph="add"
                            type="primary"
                            size={200}
                            link={{
                              pathname: `${match.url}/notes/new`,
                              parent: match,
                            }}
                          />
                        }
                      />
                    </Card>

                    <Card>
                      <ClaimTasksTable
                        data={CLAIM_TASKS}
                        to={(id: string) => ({
                          pathname: `${match.url}/tasks/${id}`,
                          parent: match,
                        })}
                        titleAfter={
                          <Button
                            label="Add Claim Task"
                            glyph="add"
                            type="primary"
                            size={200}
                            link={{
                              pathname: `${match.url}/tasks/new`,
                              parent: match,
                            }}
                          />
                        }
                      />
                    </Card>
                  </Grid>
                </Tabs.Panel>

                <Tabs.Panel name="outcome">
                  <h2>Outcome</h2>
                </Tabs.Panel>
              </Tabs.Panels>
            </Tabs>
          </div>
        </TreeItem>
      ))}
    </Card>
  )
}

const styles = {
  treeItem: {
    borderBottom: `1px solid ${COLORS.divider}`,

    '.children': {
      padding: '0 !important',
    },

    '&.is-open > button': {
      borderBottom: `1px solid ${COLORS.divider}`,
    },
  },

  treeItemContent: {
    padding: '1rem',
    paddingTop: 0,
    background: '#F7F8FB',
  },

  tabsList: {
    marginBottom: '1rem',
  },

  tableIcon: {
    marginLeft: '0.5rem !important',
    marginRight: '-0.25rem !important',
  },
}

export default Collections
