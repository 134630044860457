import React from 'react'
import produce from 'immer'

import { countWord, minutesBetween, titleCase } from '../../utils/functions'
import { getPrefix, useUpdate } from '../../hooks/useNewAPI'
import withSettings from '../../hocs/withSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import LocationCheckStatus from '../Statuses/LocationCheckStatus'

const tagsColumn = (apiKey: any) => ({
  width: 200,
  Header: 'Tags',
  accessor: 'tags',
  disableFilters: true,
  Cell: ({ value, row }: any) => {
    const { mutateAsync, isLoading }: any = useUpdate({
      name: ['location-check', row.original.id],
      url: `/location_checks/${row.original.id}`,
      invalidateKeys: ['location-checks', 'tag-live-reports'],
      onSuccess: (_data: any, variables: any, queryClient: any) => {
        const prefix = getPrefix()

        queryClient.setQueryData([prefix, apiKey].flat(), (oldData: any) => {
          if (!oldData?.data) return

          const index = oldData.data.findIndex((o) => o.id === row.original.id)
          if (index === -1) return

          const newData = produce(oldData, (draft: any) => {
            draft.data[index].tags = variables.tags
          })

          return newData
        })
      },
    })

    return (
      <TableCell.Tags
        value={value}
        save={(tagIDs: any, tags: any) => mutateAsync({ tag_ids: tagIDs, tags })}
        isLoading={isLoading}
        smartCategories="location_checkins"
      />
    )
  },
})

const columns = (to: Function = () => {}, timezone: string, apiKey: any, mainLinkAs, mainLinkState) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => {
      const category = row.original?.category
      const location = row.original?.location

      let name = value

      if (category === 'organization' && location?.name) name = location.name
      else if (category === 'property' && location?.name) name = location.name

      return <TableCell.MainLink as={mainLinkAs} state={mainLinkState} to={to(row.original.id)} label={name} />
    },
  },
  {
    width: 180,
    accessor: 'resident.name',
    Header: 'Client',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original?.resident?.avatar} name={value} />,
  },
  {
    width: 300,
    accessor: 'bed_assignment.place',
    Header: 'Bed Assignment at Check-In',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell value="–" />

      const avatar = value?.house?.avatar

      const houseName = value?.house?.name
      const floorName = value?.floor?.name
      const roomName = value?.room?.name

      return <TableCell.Profile name={`${houseName}, ${floorName}, ${roomName}`} avatar={avatar} />
    },
  },

  ...(apiKey ? [tagsColumn(apiKey)] : []),
  {
    width: 140,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ value }: any) => <TableCell value={titleCase(value)} />,
  },
  {
    width: 160,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <LocationCheckStatus status={value} />,
  },
  {
    width: 200,
    accessor: 'direct_miles_between_coords',
    Header: 'Device Distance from Location',
    Cell: ({ value }: any) => (value ? countWord('miles', value) : <TableCell.Empty />),
  },
  {
    width: 200,
    accessor: 'checkin_at',
    Header: 'Check-in Time',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 200,
    accessor: 'checkout_at',
    Header: 'Check-out Time',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 120,
    accessor: 'id',
    id: 'duration',
    Header: 'Duration',
    Cell: ({ row }: any) => `${minutesBetween(row.original.checkin_at, row.original.checkout_at, timezone)} minutes`,
  },
  {
    width: 200,
    accessor: 'notes',
    Header: 'Notes',
  },
]

const LocationCheckinTable = (props: any) => {
  const {
    apiKey,
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    isLoading,
    localStorageKey,
    timezone,
    titleAfter,
    to,
    ...rest
  } = props

  return (
    <Table
      title="Location Check-in"
      titleAfter={titleAfter}
      icon="recovery_navigation"
      data={data}
      columns={columns(to, timezone, apiKey, props.mainLinkAs, props.mainLinkState)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

LocationCheckinTable.defaultProps = {
  localStorageKey: 'location_checkin',
  emptyDescription: 'No location check-ins added yet',
}

export default withSettings(LocationCheckinTable)
