import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { countWord, daysBetween, titleCase } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'
import { useDataForms } from '../../../hooks/useDataForms'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import DataFormStatus from '../../../components/Statuses/DataFormStatus'
import FormSection from '../../../components/Forms/FormSection'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import TableFilter from '../../../components/Table/TableFilter'
import Workflow from '../../../components/Workflow/Workflow'

import { DataFormAddDropdown } from '../../DataFormsBuilder/DataFormAddDropdown'
import { DataFormSubmissionOverlay } from '../../DataFormsBuilder/DataFormSubmissionOverlay'
import { DischargeSummaryOverlay } from '../../DischargeSummary/DischargeSummaryOverlay'
import { HealthcareDischargeSummaryOverlay } from '../../DischargeSummary/HealthcareDischargeSummaryOverlay'
import { NonClinicalDischargeSummaryOverlay } from '../../DischargeSummary/NonClinicalDischargeSummaryOverlay'

const columns = ({ isEHR, timezone }: any) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => {
      const isV3 = row.original?.version === '3.0'

      if (isV3) {
        return (
          <SummonOverlay overlay={<DataFormSubmissionOverlay position="right" dataID={row.original.id} initialData={row.original} />}>
            <TableCell.MainLink label={value || titleCase(row.original?.subcategory)} />
          </SummonOverlay>
        )
      }

      if (!isEHR) {
        return (
          <SummonOverlay overlay={<DischargeSummaryOverlay position="right" dataID={row.original.id} initialData={row.original} />}>
            <TableCell.MainLink label={value || titleCase(row.original?.subcategory)} />
          </SummonOverlay>
        )
      }

      if (row.original.subcategory === 'non_clinical_discharge_summary') {
        return (
          <SummonOverlay
            overlay={<NonClinicalDischargeSummaryOverlay position="right" dataID={row.original.id} initialData={row.original} />}
          >
            <TableCell.MainLink label={value || titleCase(row.original?.subcategory)} />
          </SummonOverlay>
        )
      }

      return (
        <SummonOverlay overlay={<HealthcareDischargeSummaryOverlay position="right" dataID={row.original.id} initialData={row.original} />}>
          <TableCell.MainLink label={value || titleCase(row.original?.subcategory)} />
        </SummonOverlay>
      )
    },
  },
  {
    width: 220,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <DataFormStatus status={value} />,
    Filter: TableFilter.DataFormStatus,
    filter: 'dataFormStatus',
  },
  {
    width: 150,
    accessor: 'data.general.admitted_at',
    Header: 'Admission Date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 150,
    accessor: 'data.general.discharged_at',
    Header: 'Discharge Date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 150,
    id: 'length_of_stay',
    Header: 'Length of Stay',
    accessor: 'data.general',
    Cell: ({ value }: any) => {
      if (!value || !value.admitted_at || !value.discharged_at) return <TableCell.Empty />

      return <TableCell value={countWord('days', daysBetween(value.admitted_at, value.discharged_at, timezone))} />
    },
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Last Edited',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

const DischargeSummaries = ({ client, setStatus }: any) => {
  const match = useRouteMatch()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = match.params

  const { tenant, timezone } = useSettings()

  const clientID = client?.id || resource_id
  const isEHR = tenant?.category === 'healthcare_facility' || tenant?.category === 'healthcare_practice'

  const { data, isEmpty, isLoading } = useDataForms({ clientID, category: 'discharge' })

  const [isDischargeOverlayOpen, setIsDischargeOverlayOpen] = React.useState(false)
  const [isNonClinicalOverlayOpen, setIsNonClinicalOverlayOpen] = React.useState(false)
  const [isHealthcareDischargeOverlayOpen, setIsHealthcareDischargeOverlayOpen] = React.useState(false)

  const [newDischargeSettingsId, setNewDischargeSettingsId] = React.useState(null)

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  const actions = (
    <DataFormAddDropdown
      category="discharge"
      permission="discharges_builder.view"
      featureFlagV2="discharges_builder"
      manageLink="/settings/discharges-builder"
      setLink={() => null}
      onClick={(o) => {
        setNewDischargeSettingsId(o?.id)
      }}
    />
  )

  return (
    <FormSection maxWidth="100%">
      <Card>
        <Table
          icon="discharge_forms"
          title="Discharge Summaries"
          showSettings={false}
          data={data}
          isLoading={isLoading}
          columns={columns({ isEHR, timezone })}
          emptyDescription="No Discharge Summaries have been added yet."
          emptyActions={actions}
        />
        {!isEmpty && <CardContent>{actions}</CardContent>}
      </Card>

      <SummonOverlay
        key={newDischargeSettingsId}
        isOpen={!!newDischargeSettingsId}
        onClose={() => {
          setNewDischargeSettingsId(null)
        }}
        overlay={<DataFormSubmissionOverlay position="right" dataID="new" clientId={client?.id} settingsId={newDischargeSettingsId} />}
      />

      <SummonOverlay
        isOpen={isNonClinicalOverlayOpen}
        onClose={() => {
          setIsNonClinicalOverlayOpen(false)
        }}
        overlay={<NonClinicalDischargeSummaryOverlay position="right" dataID="new" />}
      />

      <SummonOverlay
        isOpen={isHealthcareDischargeOverlayOpen}
        onClose={() => {
          setIsHealthcareDischargeOverlayOpen(false)
        }}
        overlay={<HealthcareDischargeSummaryOverlay position="right" dataID="new" />}
      />

      <SummonOverlay
        isOpen={isDischargeOverlayOpen}
        onClose={() => {
          setIsDischargeOverlayOpen(false)
        }}
        overlay={<DischargeSummaryOverlay position="right" dataID="new" />}
      />

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        <Workflow.SkipButton onClick={onSkip} />
      </Workflow.Buttons>
    </FormSection>
  )
}

export default DischargeSummaries
