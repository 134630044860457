import { v4 as uuid } from 'uuid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import set from 'lodash/set'
import size from 'lodash/size'

import { DataEditorRef, CompactSelection, GridCell, GridCellKind, GridColumn, GridSelection, Item } from '@glideapps/glide-data-grid'

import { arrayToMapWithKey, beautifulAmount, usDate } from '../../utils/functions'
import { setSettings, getSettings } from './localStorage'

const CELL_DEFAULTS: any = {
  amount: {
    kind: GridCellKind.Number,
    data: 0,
    copyData: 0,
    displayData: beautifulAmount(0),
    thousandSeparator: false,
  },
  number: {
    kind: GridCellKind.Number,
    data: 0,
    copyData: 0,
    displayData: '0',
    thousandSeparator: false,
  },
  text: {
    kind: GridCellKind.Text,
    data: '',
    copyData: '',
    displayData: '–',
  },
  date: {
    kind: GridCellKind.Text,
    data: '',
    copyData: '',
    displayData: '',
  },
}

export type SpreadsheetColumn = Partial<GridColumn> & {
  id?: string
  model: string
  readonly?: boolean
  type?: 'number' | 'text' | 'amount'
  width?: number
  onUpdate?: (args: any) => void
  formatDisplayValue?: (args: any) => string
}

const initializeColumns = (columns: any, localStorageKey?: string) => {
  if (!columns) return []

  const columnIds: string[] = columns.map((col: any) => col.id || col.model)

  const settings = getSettings(localStorageKey)
  const savedColumnIds = settings?.columnIds || []
  const savedColumnWidths = settings?.columnWidths || {}
  const filteredSavedColumnIds = savedColumnIds.filter((id: string) => columnIds.includes(id))

  columnIds.forEach((id, index) => {
    if (!filteredSavedColumnIds.includes(id)) {
      filteredSavedColumnIds.splice(index, 0, id)
    }
  })

  const finalColumnIds = compact(filteredSavedColumnIds)

  const result: SpreadsheetColumn[] = []

  finalColumnIds.forEach((columnId: string) => {
    const col = columns.find((c: any) => c.id === columnId || c.model === columnId)
    if (col) {
      const savedWidth = savedColumnWidths[columnId]

      if (result.some((resCol) => resCol.id === columnId)) {
        throw new Error(`Duplicate Spreadsheet Column ID: ${columnId}`)
      }

      result.push({
        id: columnId,
        model: col.model,
        type: col.type,
        title: col.title,
        readonly: col.readonly,
        onUpdate: col.onUpdate,
        formatDisplayValue: col.formatDisplayValue,
        width: savedWidth || col.width,
        hasMenu: false,
      })
    }
  })

  return result
}

class ContentCache {
  // id -> model -> value
  private cachedContent: Map<string, Map<string, GridCell>> = new Map()

  private originalDataMap: any = {}
  private dataToDestroy: any[] = []
  private columnsMap: any = {}
  private columnModels: string[] = []
  private timezone?: string = ''

  public rowIds: string[] = []
  private processedRowIds: string[] = []

  public setTimezone(timezone?: string) {
    if (timezone) this.timezone = timezone
  }

  public processColumns(columns: any[]) {
    if (!columns) return

    this.columnsMap = arrayToMapWithKey(columns, 'model')

    for (const col of columns) {
      const { model } = col

      if (!model) throw new Error(`Column model is required for column: ${JSON.stringify(col)}`)

      // skip if column already exists
      if (this.columnModels.includes(model)) continue

      // add column model to cache so it doesn't get processed again
      this.columnModels.push(model)
    }
  }

  private getRowId(row: any) {
    return row?.id || row?._id
  }

  public resetData(newData: any[]) {
    this.cachedContent = new Map()
    this.originalDataMap = {}
    this.dataToDestroy = []
    this.rowIds = []
    this.processedRowIds = []

    this.processData(newData)
  }

  public processData(data: any[]) {
    if (!data) return

    if (size(this.columnModels) === 0) throw new Error('Columns must be processed before data')

    for (const row of data) {
      const rowId = this.getRowId(row)

      if (!rowId) throw new Error(`Each row must have an id or _id property. Missing on row: ${JSON.stringify(row)}`)

      // skip if row already exists
      if (this.processedRowIds.includes(rowId)) continue

      // add row id to cache so it doesn't get processed again
      this.processedRowIds.push(rowId)

      // add row id to maintain row order
      this.rowIds.push(rowId)

      // add row to original data map
      this.originalDataMap[rowId] = row

      // add row to cache
      for (const model of this.columnModels) {
        const columnConfig: any = this.columnsMap[model]

        const cellData = get(row, model)
        const cellType = columnConfig?.type || 'text'
        const cellDefaults = CELL_DEFAULTS[cellType]
        let displayData = cellData

        if (cellType === 'amount') displayData = beautifulAmount(cellData)
        else if (cellType === 'number') displayData = cellData.toString()
        else if (cellType === 'date') displayData = cellData ? usDate(cellData, this.timezone) : ''

        let cellProps = { ...cellDefaults }

        if (cellData) {
          cellProps = {
            data: cellData,
            displayData,
            copyData: cellType === 'date' ? displayData : cellData,
          }
        }

        this.set(rowId, model, {
          ...cellDefaults,
          ...cellProps,

          lastUpdated: performance.now(),
        })
      }
    }
  }

  get(id: string, model: string): GridCell | undefined {
    const rowCache = this.cachedContent.get(id)

    if (rowCache === undefined) {
      return undefined
    }

    return rowCache.get(model)
  }

  getRow(id: string): any {
    return this.cachedContent.get(id)
  }

  set(id: string, model: string, value: Partial<GridCell>) {
    if (!this.cachedContent.has(id)) {
      this.cachedContent.set(id, new Map())
    }

    const newData = value.data
    const cellType = this.columnsMap[model]?.type
    const cellDefaults = CELL_DEFAULTS[cellType]

    const rowCache = this.cachedContent.get(id) as any
    const current = rowCache.get(model)

    let displayData = newData

    if (cellType === 'amount') displayData = beautifulAmount(newData)
    else if (cellType === 'number') displayData = newData.toString()
    else if (cellType === 'date') displayData = newData ? usDate(newData, this.timezone) : ''

    let cellProps = { ...cellDefaults }

    if (newData) {
      cellProps = {
        data: newData,
        displayData,
        copyData: cellType === 'date' ? displayData : newData,
      }
    }

    const newCell = {
      ...current,
      ...value,
      ...cellDefaults,
      ...cellProps,
      lastUpdated: performance.now(),
    }

    rowCache.set(model, newCell)
  }

  deleteRowsByIndex(indexes: number[]) {
    const idsToDelete = indexes.map((index) => this.rowIds[index])

    for (const rowId of idsToDelete) {
      this.cachedContent.delete(rowId)

      const shouldDestroy = !!this.originalDataMap[rowId]?.id
      if (shouldDestroy) this.dataToDestroy.push({ ...this.originalDataMap[rowId], _destroy: 1 })
    }

    this.rowIds = this.rowIds.filter((id) => !idsToDelete.includes(id))
  }

  getDataToDestroy() {
    return this.dataToDestroy
  }

  getData(): any[] {
    const result: any = []

    for (const id of this.rowIds) {
      const newRow = cloneDeep(this.originalDataMap[id])

      for (const [model, cell] of this.cachedContent.get(id) || []) {
        set(newRow, model, cell.data)
      }

      result.push(newRow)
    }

    if (size(this.dataToDestroy) > 0) result.push(...this.dataToDestroy)

    return result
  }
}

type SpreadsheetOptions = {
  columns: SpreadsheetColumn[]
  freezeColumns?: number
  originalData?: any[]
  isEditable: boolean
  localStorageKey?: string
  timezone?: string
}

const DEFAULT_SELECTION = {
  columns: CompactSelection.empty(),
  rows: CompactSelection.empty(),
}

export const useSpreadsheet = (options: SpreadsheetOptions) => {
  const { freezeColumns, isEditable, originalData = [], localStorageKey, timezone } = options

  const ref = useRef<DataEditorRef>(null)
  const cache = useRef<ContentCache>(new ContentCache())

  const [rowsCount, setRowsCount] = useState(size(originalData) || 0)
  const [columns, setColumns]: [SpreadsheetColumn[], any] = useState(initializeColumns(options.columns, localStorageKey))
  const [selected, setSelected] = useState<GridSelection | undefined>(DEFAULT_SELECTION)

  const { selectedIndexes, didSelectRows } = useMemo(() => {
    const indexes = selected?.rows?.toArray?.() || []
    return { selectedIndexes: indexes, didSelectRows: size(indexes) > 0 }
  }, [selected])

  useEffect(() => {
    cache.current.processColumns(columns)
  }, [columns])

  useEffect(() => {
    cache.current.processData(originalData)
    setRowsCount(size(cache.current.rowIds))
  }, [originalData])

  useEffect(() => {
    cache.current.setTimezone(timezone)
  }, [timezone])

  const getCellContent = useCallback(
    ([col, row]: Item): GridCell | undefined => {
      const columnConfig = columns?.[col]

      const model = columnConfig?.model
      const rowId = cache.current.rowIds[row]
      const val: any = cache.current.get(rowId, model)

      const isCellEditable = !columnConfig?.readonly

      return {
        ...val,
        allowOverlay: isEditable && isCellEditable,
        style: !isEditable || isCellEditable ? 'normal' : 'faded',
        themeOverride: { bgCell: !isEditable || isCellEditable ? '#fff' : '#eee' },
        displayData: columnConfig?.formatDisplayValue ? columnConfig?.formatDisplayValue({ cell: val }) : val?.displayData,
      }
    },
    [isEditable, columns],
  )

  const setCellValue = useCallback(
    ([col, row]: Item, val: GridCell): void => {
      const columnConfig = columns?.[col]
      const model = columnConfig?.model
      const isCellEditable = isEditable && !columnConfig?.readonly
      const rowId = cache.current.rowIds[row]

      if (!isCellEditable) return

      cache.current.set(rowId, model, val)

      if (columnConfig?.onUpdate) {
        columnConfig?.onUpdate({
          row: cache.current.getRow(rowId),
          cell: cache.current.get(rowId, model),
          set: (model: any, value: any) => {
            cache.current.set(rowId, model, { data: value, lastUpdated: performance.now() })

            // TODO update to get the affected cells from the side effect
            const colIndex = columns.findIndex((col) => col.model === model)
            ref.current?.updateCells([{ cell: [colIndex, row] }, { cell: [row, colIndex] }])
          },
        })
      }
    },
    [isEditable, columns],
  )

  const onColumnResize = useCallback(
    (col: any, newSize: number) => {
      const index = columns.indexOf(col)
      const newCols = [...columns]
      newCols[index] = {
        ...newCols[index],
        width: newSize,
      }
      setColumns(newCols)
    },
    [columns],
  )

  const onColumnMoved = useCallback(
    (startIndex: number, endIndex: number): void => {
      const minIndex = freezeColumns || 0

      if (startIndex < minIndex) return

      const finalIndex = endIndex < minIndex ? minIndex : endIndex

      setColumns((old) => {
        const newCols = [...old]
        const [toMove] = newCols.splice(startIndex, 1)
        newCols.splice(finalIndex, 0, toMove)
        return newCols
      })
    },
    [freezeColumns],
  )

  const onGridSelectionChange = useCallback((newSel?: GridSelection) => {
    setSelected(newSel)
  }, [])

  const deleteSelectedRows = () => {
    if (!didSelectRows) return

    cache.current.deleteRowsByIndex(selectedIndexes)
    setRowsCount(size(cache.current.rowIds))
    clearSelection()
  }

  const clearSelection = () => {
    setSelected(DEFAULT_SELECTION)
  }

  const resetData = (newData: any) => {
    cache.current.resetData(newData)
    setRowsCount(size(cache.current.rowIds))
  }

  // update localStorage settings
  useEffect(() => {
    if (!localStorageKey) return

    const newSettings: any = {
      columnIds: [],
      columnWidths: {},
    }

    for (const col of columns) {
      newSettings.columnIds.push(col.id)
      newSettings.columnWidths[col.id] = col.width
    }

    setSettings(localStorageKey, newSettings)
  }, [columns, localStorageKey])

  return {
    deleteSelectedRows,
    getData: () => cache.current.getData(),
    getDataToDestroy: () => cache.current.getDataToDestroy(),
    isEditable,
    resetData,
    selectedIndexes,

    // DataEditor props
    columns,
    freezeColumns,
    getCellContent,
    gridSelection: selected,
    onColumnMoved,
    onColumnResize,
    onGridSelectionChange,
    rows: rowsCount,
    setCellValue,
    editorRef: ref,
  }
}
