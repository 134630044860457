import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  internal: {
    label: 'Staff Only',
    color: 'blue',
  },
  client: {
    label: 'Client Portal',
    color: 'blue',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
