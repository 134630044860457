import React from 'react'
import clsx from 'clsx'

import { ICONS } from '../../theme'
import { usDate } from '../../utils/functions'
import useSettings from '../../hooks/useSettings'

import Icon from '../../components/Icon'
import Glyph from '../../components/Glyph'
import Status from '../../components/Status'

export const PaymentMethod = ({ paymentMethod }: any) => {
  if (!paymentMethod) return null

  return (
    <div className="flex items-center">
      <PaymentMethodIcon paymentMethod={paymentMethod} className="mr-1.5" />

      <div className="grid gap-1">
        <PaymentMethodTitle paymentMethod={paymentMethod} />
        {/* <PaymentMethodDescription paymentMethod={paymentMethod} /> */}
      </div>
    </div>
  )
}

export const PaymentMethodTitle = ({ paymentMethod, className }: any) => {
  if (!paymentMethod) return null

  let title = ''

  if (paymentMethod?.payment_method_type === 'card') {
    title = `•••• ${paymentMethod?.service_metadata?.last4 || ''}`
  } else if (paymentMethod.payment_method_type === 'us_bank_account') {
    title = `${paymentMethod?.service_metadata?.bank_name || ''} •••• ${paymentMethod?.service_metadata?.last4 || ''}`
  }

  if (!title) return null

  return (
    <div className={clsx('inline-flex items-center font-[600]', className)}>
      {title}
      {paymentMethod?.is_default && <Status small label="Default" color="blue" className="ml-1.5" />}
    </div>
  )
}

export const PaymentMethodDescription = ({ paymentMethod }: any) => {
  const { timezone } = useSettings()

  if (!paymentMethod?.created_at) return null

  return (
    <div className="text-text-muted text-[0.94em]">
      <b className="font-[600]">Date Added: </b> {usDate(paymentMethod.created_at, timezone)}
    </div>
  )
}

export const PaymentMethodIcon = ({ paymentMethod, ...rest }: any) => {
  if (!paymentMethod) return null

  const isBank = paymentMethod?.payment_method_type === 'us_bank_account'

  if (isBank) return <Glyph glyph="bank" {...rest} />

  return <Icon icon={isBank ? ICONS.bank_building : ICONS[paymentMethod?.service_metadata?.brand] || ICONS.billing} {...rest} />
}
