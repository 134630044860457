import size from 'lodash/size'
import useStore from '../modules/store'

import { TIMEZONE } from '../utils/constants'

export const useSettings = () => {
  const devBar = useStore((state: any) => state.devBar)
  const idle = useStore((state: any) => state.idle)
  const online = useStore((state: any) => state.online)
  const tenant = useStore((state: any) => state.tenant)
  const timezone = useStore((state) => state.tenant?.timezone || TIMEZONE)
  const user = useStore((state: any) => state.user)
  const newUser = useStore((state: any) => state.newUser)
  const record = useStore((state: any) => state?.newRecord)
  const connectedEntities = useStore((state: any) => state.connectedEntities)
  const notifications = useStore((state: any) => state.notifications)
  const formVariables = useStore((state: any) => state.formVariables)
  const defaultSupervisor = useStore((state: any) => state.defaultSupervisor)

  const isPortal = process.env.BH_APP === 'portal'
  const isEHRApp = process.env.BH_APP === 'ehr'
  const isHQApp = process.env.BH_APP === 'hq'
  const isBehave = user?.type === 'bh_employee'
  const isCommunity = tenant?.plan_status === 'community'
  const isOwner = user?.position === 'owner'
  const isManagement = user.position && ['owner', 'administrator'].includes(user.position)

  const isBilledByBehave = tenant?.is_billed === true
  const isSelfServiceBilling = tenant?.is_self_service_billing === true
  const hasAccountingAccess = user?.has_accounting_access === true
  const hasConnectedEntities = size(connectedEntities) > 0

  return {
    devBar,
    hasAccountingAccess,
    idle,
    isBehave,
    isCommunity,
    isBilledByBehave,
    isEHRApp,
    isHQApp,
    isManagement,
    isOwner,
    isPortal,
    isSelfServiceBilling,
    notifications,
    online,
    tenant,
    timezone,
    user,
    newUser,
    record,
    connectedEntities,
    hasConnectedEntities,
    systemPrefs: tenant?.system_prefs || {},
    formSettings: tenant?.forms_settings || {},
    formVariables,
    defaultSupervisor,
  }
}

export default useSettings
