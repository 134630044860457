import React from 'react'

import { Link, NavLink } from 'react-router-dom-v5-compat'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import { nicePhoneNo } from '@behavehealth/utils/functions'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import LinkValue from '@behavehealth/components/LinkValue'
import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import SmartStatus from '@behavehealth/components/SmartStatus'

const referenceUrl = (data: object) => {
  let url = ''

  let residentStatusUrlPaths = {
    alumni: 'alumni',
    current: 'clients',
    intake: 'admissions',
  }

  let resident = data.reference.client
  url = `/${residentStatusUrlPaths[resident.status]}/${resident.id}/financials/payments/${data.reference.id}`

  return url
}

const RootInvoicePayments = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting', 'invoice_payments'],
    endpoint: `/accounting/invoice_payments`,
    // params: {...},
    localStorageKey: 'accounting_invoice_payments_v1',
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Customer',
        model: 'customer_ref_name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell as={NavLink} id={data.id} value={data.customer_ref_name} to={`${data.id}`} />,
      },
      {
        title: 'Behave Record',
        model: 'reference.name',
        width: 260,
        disableHide: true,
        // type: 'profile',
        formatValue: ({ value, data }: any) => {
          if (!data.reference) return null

          let url = referenceUrl(data)

          return <LinkCell isExternal value={value} href={url} avatar={data.reference.avatar || ''} />
        },
      },
      {
        title: 'Memo',
        model: 'memo',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Total Amount',
        model: 'total_amount',
        width: 160,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Date',
        model: 'date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
      {
        title: 'Quickbooks Last Updated',
        model: 'source_modified_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Invoice Payments">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Invoice Payments"
          icon="accounting"
          emptyDescription="No invoice payments have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          columns={columns}
          {...tableProps}
          headerAfter={
            isBehave && (
              <Flex gap="0.5rem">
                <AccountingSyncButton
                  forceShow={true}
                  category="invoice_payments"
                  invalidate={tableProps.queryKey}
                  refetchUrl={'/accounting/sync?category=invoice_payments'}
                />
              </Flex>
            )
          }
        />
      </Grid>
    </Page>
  )
}

export const InvoicePayments = withPageError(RootInvoicePayments)

const FILTERS_CONFIG = {
  customer_ref_name: {
    label: 'Customer',
    type: 'string',
  },
  memo: {
    label: 'Memo',
    type: 'string',
  },
  currency_ref_id: {
    label: 'Currency Ref Id',
    type: 'string',
  },
  total_amount: {
    label: 'Total Amount',
    type: 'string',
  },
  date: {
    label: 'Phone',
    type: 'date_time',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'date_time',
  },
}
