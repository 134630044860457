import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useParams } from 'react-router-dom-v5-compat'

import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Tabs from '@behavehealth/components/Tabs'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { VariablesDataTable } from '@behavehealth/constructs/Variables/VariablesDataTable'

import { CustomAccountVariableOverlay } from '@behavehealth/constructs/Variables/CustomAccountVariableOverlay'
import { VariableOverlay } from '@behavehealth/constructs/Variables/VariableOverlay'

const TABS = {
  custom_account: { label: 'Custom Account Variables', category: 'custom_account', slug: 'custom-account' },
  account: { label: 'Account Variables', category: 'account', slug: 'account' },
  // time: { label: 'Time Variables', category: 'time', slug: 'time' },
  // system: { label: 'System Variables', category: 'system', slug: 'system' },
}

const RootVariables = () => {
  return (
    <>
      <Page
        title="Form Variables"
        icon="variables"
        actions={
          <Button
            as={Link}
            label="Add Custom Account Variable"
            glyph="add"
            type="primary"
            // permission="custom_account_variables.create" // ⏰ TODO: add back before release
            link="custom-account/new"
          />
        }
      >
        <Tabs>
          <Tabs.List className="-mt-4 mb-4">
            {Object.values(TABS).map((tab) => {
              return (
                <React.Fragment key={tab.category}>
                  <Tabs.Item as={NavLink} label={tab.label} to={tab.slug} />
                </React.Fragment>
              )
            })}
          </Tabs.List>
        </Tabs>

        <Routes>
          <Route index element={<Navigate to="custom-account" replace />} />
          <Route path=":category/*" element={<InnerPage />} />
        </Routes>
      </Page>

      <AnimatedRoutes>
        <Route path="custom-account/:id" element={<CustomAccountVariableOverlay useV6Router />} />
        <Route path=":category/:id" element={<VariableOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const InnerPage = () => {
  const { category: slug }: any = useParams()

  const tabConfig = Object.values(TABS).find((tab) => tab.slug === slug)

  const tableProps = useDataTable({
    name: ['form_variables'],
    endpoint: `/form_variables`,
    localStorageKey: 'form_variables_v1',
    params: { category: tabConfig?.category },
    options: { enabled: !!tabConfig?.category },
  })

  const to = React.useCallback((data: any) => data.id, [])

  return (
    <Grid>
      <VariablesDataTable asCard mainLinkAs={NavLink} to={to} title={tabConfig?.label} {...tableProps} />
    </Grid>
  )
}

export const Variables = withPageError(RootVariables)
