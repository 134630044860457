import React from 'react'
import produce from 'immer'
import size from 'lodash/size'
import { v4 as uuid } from 'uuid'

import { countWord } from '../../utils/functions'
import Notifications from '../../modules/notifications'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Flex from '../../components/Flex'
import TreeItem from '../../components/TreeItem'
import FormSection from '../../components/Forms/FormSection'

import { withOverlayError } from '../../hocs/withOverlayError'

import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { useDataTable } from '../../components/DataTable/useDataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

const STEPS = {
  select: 'select',
  warning: 'warning',
}

const RootPaymentServiceLinesImportOverlay = (props: any) => {
  const { getImportedData }: any = props
  const { online }: any = useSettings()

  const [step, setStep] = React.useState(STEPS.select)
  const [importedIds, setImportedIds] = React.useState<any[]>([])
  const [duplicateIds, setDuplicateIds] = React.useState<any[]>([])

  const [selectedRows, setSelectedRows] = React.useState<any[]>([])

  const selectedCount = size(selectedRows)

  const _tableProps = useDataTable({
    name: 'insurance_new_payments',
    endpoint: '/insurance_new_payments',
    localStorageKey: 'insurance_new_payments_v1',
  })

  const tableProps = React.useMemo(() => {
    return {
      data: DATA,
      meta: {
        count: size(DATA),
      },
    }
  }, [])

  const handleImport = () => {
    // find duplicate service line identifiers
    // if (size(importedIds) > 0) {
    //   const result: string[] = []

    //   for (const row of selectedRows) {
    //     if (size(row.insurance_claim_service_lines) > 0) {
    //       for (const line of row.insurance_claim_service_lines) {
    //         const id = line.identifier

    //         if (importedIds.includes(id)) result.push(id)
    //       }
    //     }
    //   }

    //   if (size(result) > 0) {
    //     setDuplicateIds(result)
    //     setStep(STEPS.warning)
    //     return
    //   }
    // }

    importSelectedRows()
  }

  const importSelectedRows = () => {
    // let newLinesCount = 0

    // for (const row of selectedRows) {
    //   if (size(row.insurance_claim_service_lines) === 0) continue

    //   for (const _line of row.insurance_claim_service_lines) {
    //     newLinesCount++
    //   }
    // }

    props.onSelect(selectedRows?.map?.((row: any) => ({ ...row, id: uuid() })) || [])
    props.onClose()

    // Notifications.send(`Imported ${countWord('service line', newLinesCount)}`, 'positive')
  }

  const importNonDuplicateRows = () => {
    const result: any[] = []
    let newLinesCount = 0

    for (const row of selectedRows) {
      if (size(row.insurance_claim_service_lines) > 0) {
        const nonDuplicateLines: any[] = []

        // filter out duplicate service lines
        for (const line of row.insurance_claim_service_lines) {
          const id = line.identifier

          if (importedIds.includes(id)) continue

          nonDuplicateLines.push(line)
          newLinesCount++
        }

        // add row if there are non-duplicate service lines
        if (size(nonDuplicateLines) > 0) {
          const newRow = produce(row, (draft: any) => {
            draft.insurance_claim_service_lines = nonDuplicateLines
          })

          result.push(newRow)
        }
      }
    }

    props.onSelect(result)
    props.onClose()

    if (newLinesCount > 0) {
      Notifications.send(`Imported ${countWord('service line', newLinesCount)}`, 'positive')
    } else {
      Notifications.send(`No new service lines were imported`, 'warning')
    }
  }

  React.useEffect(() => {
    if (getImportedData) {
      const importedData = getImportedData()
      const result: string[] = []

      for (const item of importedData) {
        const id = item.insurance_claim_service_line?.identifier

        if (id) result.push(id)
      }

      setImportedIds(result)
    }
  }, [])

  const columns = React.useMemo(
    () => [
      {
        title: 'Payment Service Line ID',
        model: 'identifier',
        formatValue: ({ data, value }: any) => <MainCell testKey="main_cell" id={data?.id} value={value} />,
      },
      {
        title: 'Service Start Date',
        model: 'insurance_claim_service_line.service_date_start',
        type: 'date',
      },
      {
        title: 'Service End Date',
        model: 'insurance_claim_service_line.service_date_end',
        type: 'date',
      },
      {
        title: 'Claim ID',
        model: 'insurance_claim.identifier',
      },
      {
        title: 'Procedure Code',
        model: 'insurance_claim_service_line.insurance_new_code.procedure_code',
      },
      {
        title: 'Service Name',
        model: 'insurance_claim_service_line.insurance_new_code.service_name',
      },
      {
        title: 'Service Description',
        model: 'insurance_claim_service_line.insurance_new_code.description',
      },
      {
        title: 'Billed Amount',
        model: 'balance_billed_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Allowed Amount',
        model: 'allowed_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Insurance Paid',
        model: 'insurance_paid_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Deductible',
        model: 'deductible_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Co-Pay',
        model: 'copay_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Coinsurance',
        model: 'coinsurance_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Adjustment Amount',
        model: 'adjustment_amount',
        type: 'amount',
        width: 150,
      },
      {
        title: 'Balance Due Client',
        model: 'balance_client_due_amount',
        type: 'amount',
        width: 150,
      },
    ],
    [],
  )

  return (
    <Overlay showBackdrop position="top" maxWidth={step === STEPS.select ? 90 : 50} onClose={props.onClose}>
      <Overlay.Header icon="deposit" title="Import Payment Service Lines" />

      <Overlay.Content>
        <Section>
          {step === STEPS.warning && (
            <div className="-ml-2 !-mt-4 mb-2">
              <Button
                label="← Back"
                type="minimal"
                color="blue"
                onClick={() => {
                  setStep(STEPS.select)
                }}
                display="inline-flex"
                size={200}
              />
            </div>
          )}

          <div className={step === STEPS.select ? 'block' : 'hidden'}>
            <FormSection maxWidth="100%">
              <Alert contrast glyph="info" children="Select Payment Service Lines to import" />
              <DataTable
                {...tableProps}
                asCard
                canBatchSelect
                title="Payment Service Lines"
                icon="deposit"
                columns={columns}
                onRowSelectionUpdate={setSelectedRows}
              />
            </FormSection>
          </div>

          {step === STEPS.warning && size(duplicateIds) > 0 && (
            <div>
              <Alert contrast type="warning" glyph="warning">
                The selected claims contain service lines that have already been imported. You can choose to import them and create
                duplicates, or you can import non-duplicate service lines only.
              </Alert>

              <div className="mt-2">
                <TreeItem title={`Show duplicate service lines (${size(duplicateIds)})`}>
                  <div className="border border-solid border-divider bg-hover rounded-md px-2 py-1 text-sm">
                    {duplicateIds.map((id: string) => (
                      <div key={id} className="!border-0">
                        {id}
                      </div>
                    ))}
                  </div>
                </TreeItem>
              </div>
            </div>
          )}
        </Section>
      </Overlay.Content>

      <Overlay.Footer online={online}>
        {step === STEPS.select && (
          <Button
            label={`Import ${countWord('Payment Service Lines', selectedCount)}`}
            glyph="check"
            type="primary"
            color="green"
            flex="100 1 auto"
            onClick={handleImport}
            isDisabled={selectedCount <= 0}
          />
        )}

        {step === STEPS.warning && (
          <Flex gap="0.75rem">
            <Button
              label={`Import Duplicated Service Lines`}
              glyph="warning"
              glyphColor="orange"
              type="default"
              color="text"
              flex="1 1 auto"
              onClick={importSelectedRows}
              isDisabled={selectedCount <= 0}
            />

            <Button
              label={`Import New Service Lines Only`}
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={importNonDuplicateRows}
              isDisabled={selectedCount <= 0}
            />
          </Flex>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const PaymentServiceLinesImportOverlay = withOverlayError(RootPaymentServiceLinesImportOverlay)

const DATA = [
  {
    id: 'dgyN9M',
    adjustment_amount: '0.0',
    adjustment_group_code: '',
    adjustment_reason_code: '',
    allowed_amount: '0.0',
    author: {
      id: 'wgWOgG',
      avatar: null,
      created_at: '2023-11-15T03:32:00-05:00',
      email: 'robert.eles@behavehealth.com',
      first_name: 'Robert',
      has_accounting_access: false,
      is_pin_set: false,
      is_shared_with_portal: true,
      last_name: 'Eles',
      middle_name: null,
      name: 'Robert Eles',
      phone_no: null,
      phone_no_ext: null,
      position: 'dev',
      signature:
        'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
      type: 'bh_employee',
      updated_at: '2023-11-15T11:16:23-05:00',
    },
    balance_billed_amount: '0.0',
    balance_client_due_amount: '100.0',
    balance_due_status: null,
    coinsurance_amount: '0.0',
    copay_amount: '0.0',
    created_at: '2023-11-24T08:07:41-05:00',
    deductible_amount: '0.0',
    identifier: 'BH_PAY_LN_000007',
    insurance_claim: {
      id: 'Oglw6y',
      admission_source_code: null,
      admission_type_code: null,
      admitted_at: null,
      admitting_diagnosis: {
        id: 'WoJokOq',
        category: 'primary',
        code: 'F10.23',
        created_at: '2023-11-15T03:58:01-05:00',
        description: 'Alcohol dependence with withdrawal',
        from: null,
        notes:
          'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
        source: 'internal',
        status: 'active',
        type: 'diagnosis',
        until: null,
        updated_at: '2023-11-17T13:00:26-05:00',
      },
      assignee: null,
      attending_provider: null,
      author: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      bill_type: null,
      billing_note: null,
      billing_provider: {
        id: 'X6Xqga',
        address: {
          city: 'West Harwich',
          state: 'MA',
          country: 'USA',
          zip_code: '02671',
          address_line_1: '32 Main Street',
          address_line_2: '',
        },
        category: 'npi',
        certification: null,
        continued_education_required: false,
        created_at: '2023-11-20T04:30:56-05:00',
        credential: 'Test',
        current_credits: null,
        employee_identifier: null,
        expires_at: null,
        first_name: null,
        graduation_year: null,
        issued_at: '2023-11-23T00:00:00-05:00',
        last_name: null,
        level: 'organization',
        license_number: '123',
        notes: '',
        organization: 'Org Test',
        phone_no: '1231231231',
        provider_type: null,
        required_annual_credits: null,
        status: null,
        tax_identifier: '123',
        type: 'credential',
        updated_at: '2023-11-20T04:31:03-05:00',
      },
      category: 'professional',
      claim_filing_code: 'CI',
      claim_frequency_code: '1',
      condition_codes: [],
      control_number: '9U5C9YE64',
      covering_from: '2023-11-19T09:13:22-05:00',
      covering_until: '2023-11-19T10:08:41-05:00',
      created_at: '2023-11-24T08:03:57-05:00',
      discharged_at: null,
      flagged_status: null,
      from_date: '2023-11-19T09:13:22-05:00',
      identifier: 'BH_CLM_000004',
      initial_submission_at: null,
      insurance_authorization: null,
      insurance_claim_service_lines: [
        {
          id: 'dgyN9M',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000007',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '100.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:35:22-05:00',
          service_date_start: '2023-11-19T09:13:22-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: 'NP2pgR',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000008',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '200.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:46:28-05:00',
          service_date_start: '2023-11-19T09:13:28-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: '1goEPw',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000009',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '300.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:57:35-05:00',
          service_date_start: '2023-11-19T09:13:35-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: 'bPxBg0',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000010',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '400.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T10:08:41-05:00',
          service_date_start: '2023-11-19T09:13:41-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
      ],
      insurance_fee_schedule: null,
      insurance_local_payer: {
        id: 'RPL0PD',
        address: {},
        archived_at: null,
        avatar: null,
        closing_at: null,
        created_at: '2023-11-19T09:15:21-05:00',
        enrolled_status: 'not_enrolled',
        fax_no: null,
        insurance_clearing_house_config: null,
        name: 'Test',
        network_status: 'out_of_network',
        opening_at: null,
        opening_times: null,
        other_phone_no_1: null,
        other_phone_no_2: null,
        payer_id: null,
        payer_source: 'clearing_house',
        phone_no: null,
        status: 'active',
        supports: null,
        tags: [],
        timezone: null,
        type: 'insurance_local_payer',
        updated_at: '2023-11-19T09:15:21-05:00',
        website: null,
      },
      insurance_new_fee_schedule: {
        id: 'RPL0PD',
        archived_at: null,
        created_at: '2023-11-19T09:14:10-05:00',
        employees: [],
        houses: [],
        insurance_local_payers: [],
        insurance_new_fee_schedule_services: [
          {
            id: 'RPL0PD',
            created_at: '2023-11-20T11:13:35-05:00',
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            price: '123.0',
            type: 'insurance_new_fee_schedule_service',
            updated_at: '2023-11-20T11:13:35-05:00',
          },
        ],
        name: 'aaa',
        status: 'active',
        type: 'insurance_new_fee_schedule',
        updated_at: '2023-11-20T11:13:35-05:00',
      },
      last_claim_note: null,
      last_insurance_claim_status: null,
      last_submission_at: null,
      ordering_provider: null,
      original_claim_no: '',
      patient_status_code: null,
      place_of_service_code: '57',
      primary_insurance: {
        id: 'RPL0PD',
        archived_at: null,
        card_issued_at: null,
        category: 'primary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:25:08-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: '12312312321',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AZ',
          zip_code: '123',
          address_line_1: 'asd',
          address_line_2: 'dsa',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:25:08-05:00',
      },
      principal_diagnosis: {
        id: 'WoJokOq',
        category: 'primary',
        code: 'F10.23',
        created_at: '2023-11-15T03:58:01-05:00',
        description: 'Alcohol dependence with withdrawal',
        from: null,
        notes:
          'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
        source: 'internal',
        status: 'active',
        type: 'diagnosis',
        until: null,
        updated_at: '2023-11-17T13:00:26-05:00',
      },
      referring_provider: null,
      rendering_provider: null,
      resident: {
        id: 'vNwNjp2',
        admitted_at: '2023-10-31T04:34:42-04:00',
        avatar: null,
        behave_id: 'ABVI000001',
        behave_identifier: '4P1531SEOM1D',
        care_level: 'sober_living',
        client_portal_status: null,
        color_code: null,
        created_at: '2023-11-15T03:34:42-05:00',
        dob: '2003-11-18T19:00:00-05:00',
        email: 'abbigail.villarreal@getnada.com',
        encrypted_data: {
          id: 'VoJo107',
          address: {
            address_line_1: '32 Main Street',
            address_line_2: '',
            city: 'East Hampton',
            state: 'NY',
            zip_code: '11930',
            country: 'USA',
          },
          created_at: '2023-11-15T03:34:42-05:00',
          notes: null,
          secure_pin: null,
          ssn: null,
          treatment_address: null,
          type: 'encrypted_data',
          updated_at: '2023-11-22T11:27:15-05:00',
        },
        external_id: '4444f162-8390-46e7-8e63-d6bfa9b63b65',
        financial_notes: null,
        first_name: 'Abbigail',
        gender_identity: null,
        gender_identity_other: null,
        has_portal_access: true,
        high_risk_notes: null,
        high_risk_title: null,
        invited_to_portal_at: '2023-11-15T03:34:42-05:00',
        is_billed_by_behave: true,
        is_insurance_auth_required: false,
        is_insurance_billable: true,
        is_insured: null,
        is_the_policy_holder: null,
        last_logged_in_at: null,
        last_name: 'Villarreal',
        level_of_care: null,
        middle_name: '',
        name: 'Abbigail Villarreal',
        notes: null,
        phone_no: '461-306-0872',
        preferred_pronouns: null,
        preferred_pronouns_other: null,
        priority: null,
        referral_notes: null,
        referral_out_notes: null,
        sex: 'female',
        sex_other: null,
        signature: null,
        status: 'current',
        sync_with_accounting: null,
        tags: [
          {
            id: 'RPL0PD',
            color: '#f44336',
            created_at: '2023-11-15T03:34:26-05:00',
            description:
              'This tag indicates that the referral came from a close family member or friend of the potential resident, who is seeking help on their behalf.',
            glyph: 'cross',
            name: 'Family or Friend',
            type: 'tag',
            updated_at: '2023-11-15T03:34:26-05:00',
          },
          {
            id: 'Oglw6y',
            color: '#274879',
            created_at: '2023-11-15T03:34:26-05:00',
            description:
              'This tag is for residents experiencing mental health issues, such as depression, anxiety, or other psychological conditions, and who require therapeutic interventions and support.',
            glyph: 'current_risk',
            name: 'Mental Health',
            type: 'tag',
            updated_at: '2023-11-15T03:34:26-05:00',
          },
        ],
        type: 'resident',
        updated_at: '2023-11-24T08:07:41-05:00',
      },
      response: {},
      secondary_insurance: {
        id: 'X6Xqga',
        archived_at: null,
        card_issued_at: null,
        category: 'secondary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:26:14-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: 'Member ID',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AK',
          zip_code: 'asd',
          address_line_1: 'asd',
          address_line_2: 'asd',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:26:20-05:00',
      },
      service_lines: [],
      state: 'state_draft',
      status: 'draft',
      supervising_provider: null,
      tertiary_insurance: {
        id: 'X6Xqga',
        archived_at: null,
        card_issued_at: null,
        category: 'secondary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:26:14-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: 'Member ID',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AK',
          zip_code: 'asd',
          address_line_1: 'asd',
          address_line_2: 'asd',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:26:20-05:00',
      },
      to_date: '2023-11-19T10:08:41-05:00',
      total_amount: '492.0',
      tracker_status: null,
      treatment_episode: {
        id: 'vNwNjp2',
        accepted_at: null,
        admitted_at: '2023-10-31T04:34:42-04:00',
        created_at: '2023-11-15T03:34:42-05:00',
        decline_reason: null,
        declined_at: null,
        discharge_notes: null,
        discharge_type: null,
        discharged_at: '2023-12-13T03:34:42-05:00',
        estimated_arrival: null,
        estimated_discharge: null,
        incoming_notes: null,
        levels_of_care: [],
        no_show_notes: null,
        notes: null,
        other_discharge_type: null,
        reserved_at: null,
        return_eligibility: null,
        status: 'admitted',
        tags: [],
        title: null,
        type: 'admission',
        updated_at: '2023-11-21T09:59:12-05:00',
        waitlist_notes: null,
      },
      type: 'insurance_claim',
      type_of_service_code: null,
      updated_at: '2023-11-24T08:07:41-05:00',
      validation: {},
      value_codes: [],
      version: 'v2',
      warnings: {},
    },
    insurance_claim_service_line: {
      id: 'dgyN9M',
      created_at: '2023-11-24T08:03:57-05:00',
      diagnoses: [],
      diagnoses_ids: [],
      identifier: 'BH_CLM_SVC_000007',
      insurance_claim: {
        status: 'draft',
      },
      insurance_new_code: {
        id: 'RPL0PD',
        code_type: 'cpt',
        created_at: '2023-11-20T11:12:50-05:00',
        description: 'test 213',
        modifier_codes: [],
        notes: '',
        procedure_code: 'aa',
        revenue_code: '',
        service_name: 'A',
        type: 'insurance_new_code',
        unit: 'per_unit',
        updated_at: '2023-11-20T11:12:50-05:00',
      },
      insurance_new_payment_service_line: {
        identifier: 'BH_PAY_000004',
        allowed_amount: '0.0',
        insurance_paid_amount: '0.0',
        deductible_amount: '0.0',
        copay_amount: '0.0',
        coinsurance_amount: '0.0',
        adjustment_group_code: '',
        adjustment_reason_code: '',
        adjustment_amount: '0.0',
        balance_client_due_amount: '100.0',
        balance_billed_amount: '0.0',
      },
      modifier_codes: [],
      revenue_code: null,
      service_date_end: '2023-11-19T09:35:22-05:00',
      service_date_start: '2023-11-19T09:13:22-05:00',
      status: 'balance_due_patient',
      total_amount: '123.0',
      type: 'insurance_claim_service_line',
      unit_price: '123.0',
      unit_type: 'UN',
      units: 1,
      updated_at: '2023-11-24T08:07:41-05:00',
    },
    insurance_paid_amount: '0.0',
    last_edited_by: {
      id: 'wgWOgG',
      avatar: null,
      created_at: '2023-11-15T03:32:00-05:00',
      email: 'robert.eles@behavehealth.com',
      first_name: 'Robert',
      has_accounting_access: false,
      is_pin_set: false,
      is_shared_with_portal: true,
      last_name: 'Eles',
      middle_name: null,
      name: 'Robert Eles',
      phone_no: null,
      phone_no_ext: null,
      position: 'dev',
      signature:
        'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
      type: 'bh_employee',
      updated_at: '2023-11-15T11:16:23-05:00',
    },
    type: 'insurance_new_payment_service_line',
    updated_at: '2023-11-24T08:07:41-05:00',
  },
  {
    id: 'NP2pgR',
    adjustment_amount: '0.0',
    adjustment_group_code: '',
    adjustment_reason_code: '',
    allowed_amount: '0.0',
    author: {
      id: 'wgWOgG',
      avatar: null,
      created_at: '2023-11-15T03:32:00-05:00',
      email: 'robert.eles@behavehealth.com',
      first_name: 'Robert',
      has_accounting_access: false,
      is_pin_set: false,
      is_shared_with_portal: true,
      last_name: 'Eles',
      middle_name: null,
      name: 'Robert Eles',
      phone_no: null,
      phone_no_ext: null,
      position: 'dev',
      signature:
        'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
      type: 'bh_employee',
      updated_at: '2023-11-15T11:16:23-05:00',
    },
    balance_billed_amount: '0.0',
    balance_client_due_amount: '200.0',
    balance_due_status: null,
    coinsurance_amount: '0.0',
    copay_amount: '0.0',
    created_at: '2023-11-24T08:07:41-05:00',
    deductible_amount: '0.0',
    identifier: 'BH_PAY_LN_000008',
    insurance_claim: {
      id: 'Oglw6y',
      admission_source_code: null,
      admission_type_code: null,
      admitted_at: null,
      admitting_diagnosis: {
        id: 'WoJokOq',
        category: 'primary',
        code: 'F10.23',
        created_at: '2023-11-15T03:58:01-05:00',
        description: 'Alcohol dependence with withdrawal',
        from: null,
        notes:
          'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
        source: 'internal',
        status: 'active',
        type: 'diagnosis',
        until: null,
        updated_at: '2023-11-17T13:00:26-05:00',
      },
      assignee: null,
      attending_provider: null,
      author: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      bill_type: null,
      billing_note: null,
      billing_provider: {
        id: 'X6Xqga',
        address: {
          city: 'West Harwich',
          state: 'MA',
          country: 'USA',
          zip_code: '02671',
          address_line_1: '32 Main Street',
          address_line_2: '',
        },
        category: 'npi',
        certification: null,
        continued_education_required: false,
        created_at: '2023-11-20T04:30:56-05:00',
        credential: 'Test',
        current_credits: null,
        employee_identifier: null,
        expires_at: null,
        first_name: null,
        graduation_year: null,
        issued_at: '2023-11-23T00:00:00-05:00',
        last_name: null,
        level: 'organization',
        license_number: '123',
        notes: '',
        organization: 'Org Test',
        phone_no: '1231231231',
        provider_type: null,
        required_annual_credits: null,
        status: null,
        tax_identifier: '123',
        type: 'credential',
        updated_at: '2023-11-20T04:31:03-05:00',
      },
      category: 'professional',
      claim_filing_code: 'CI',
      claim_frequency_code: '1',
      condition_codes: [],
      control_number: '9U5C9YE64',
      covering_from: '2023-11-19T09:13:22-05:00',
      covering_until: '2023-11-19T10:08:41-05:00',
      created_at: '2023-11-24T08:03:57-05:00',
      discharged_at: null,
      flagged_status: null,
      from_date: '2023-11-19T09:13:22-05:00',
      identifier: 'BH_CLM_000004',
      initial_submission_at: null,
      insurance_authorization: null,
      insurance_claim_service_lines: [
        {
          id: 'dgyN9M',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000007',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '100.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:35:22-05:00',
          service_date_start: '2023-11-19T09:13:22-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: 'NP2pgR',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000008',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '200.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:46:28-05:00',
          service_date_start: '2023-11-19T09:13:28-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: '1goEPw',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000009',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '300.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:57:35-05:00',
          service_date_start: '2023-11-19T09:13:35-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: 'bPxBg0',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000010',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '400.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T10:08:41-05:00',
          service_date_start: '2023-11-19T09:13:41-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
      ],
      insurance_fee_schedule: null,
      insurance_local_payer: {
        id: 'RPL0PD',
        address: {},
        archived_at: null,
        avatar: null,
        closing_at: null,
        created_at: '2023-11-19T09:15:21-05:00',
        enrolled_status: 'not_enrolled',
        fax_no: null,
        insurance_clearing_house_config: null,
        name: 'Test',
        network_status: 'out_of_network',
        opening_at: null,
        opening_times: null,
        other_phone_no_1: null,
        other_phone_no_2: null,
        payer_id: null,
        payer_source: 'clearing_house',
        phone_no: null,
        status: 'active',
        supports: null,
        tags: [],
        timezone: null,
        type: 'insurance_local_payer',
        updated_at: '2023-11-19T09:15:21-05:00',
        website: null,
      },
      insurance_new_fee_schedule: {
        id: 'RPL0PD',
        archived_at: null,
        created_at: '2023-11-19T09:14:10-05:00',
        employees: [],
        houses: [],
        insurance_local_payers: [],
        insurance_new_fee_schedule_services: [
          {
            id: 'RPL0PD',
            created_at: '2023-11-20T11:13:35-05:00',
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            price: '123.0',
            type: 'insurance_new_fee_schedule_service',
            updated_at: '2023-11-20T11:13:35-05:00',
          },
        ],
        name: 'aaa',
        status: 'active',
        type: 'insurance_new_fee_schedule',
        updated_at: '2023-11-20T11:13:35-05:00',
      },
      last_claim_note: null,
      last_insurance_claim_status: null,
      last_submission_at: null,
      ordering_provider: null,
      original_claim_no: '',
      patient_status_code: null,
      place_of_service_code: '57',
      primary_insurance: {
        id: 'RPL0PD',
        archived_at: null,
        card_issued_at: null,
        category: 'primary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:25:08-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: '12312312321',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AZ',
          zip_code: '123',
          address_line_1: 'asd',
          address_line_2: 'dsa',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:25:08-05:00',
      },
      principal_diagnosis: {
        id: 'WoJokOq',
        category: 'primary',
        code: 'F10.23',
        created_at: '2023-11-15T03:58:01-05:00',
        description: 'Alcohol dependence with withdrawal',
        from: null,
        notes:
          'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
        source: 'internal',
        status: 'active',
        type: 'diagnosis',
        until: null,
        updated_at: '2023-11-17T13:00:26-05:00',
      },
      referring_provider: null,
      rendering_provider: null,
      resident: {
        id: 'vNwNjp2',
        admitted_at: '2023-10-31T04:34:42-04:00',
        avatar: null,
        behave_id: 'ABVI000001',
        behave_identifier: '4P1531SEOM1D',
        care_level: 'sober_living',
        client_portal_status: null,
        color_code: null,
        created_at: '2023-11-15T03:34:42-05:00',
        dob: '2003-11-18T19:00:00-05:00',
        email: 'abbigail.villarreal@getnada.com',
        encrypted_data: {
          id: 'VoJo107',
          address: {
            address_line_1: '32 Main Street',
            address_line_2: '',
            city: 'East Hampton',
            state: 'NY',
            zip_code: '11930',
            country: 'USA',
          },
          created_at: '2023-11-15T03:34:42-05:00',
          notes: null,
          secure_pin: null,
          ssn: null,
          treatment_address: null,
          type: 'encrypted_data',
          updated_at: '2023-11-22T11:27:15-05:00',
        },
        external_id: '4444f162-8390-46e7-8e63-d6bfa9b63b65',
        financial_notes: null,
        first_name: 'Abbigail',
        gender_identity: null,
        gender_identity_other: null,
        has_portal_access: true,
        high_risk_notes: null,
        high_risk_title: null,
        invited_to_portal_at: '2023-11-15T03:34:42-05:00',
        is_billed_by_behave: true,
        is_insurance_auth_required: false,
        is_insurance_billable: true,
        is_insured: null,
        is_the_policy_holder: null,
        last_logged_in_at: null,
        last_name: 'Villarreal',
        level_of_care: null,
        middle_name: '',
        name: 'Abbigail Villarreal',
        notes: null,
        phone_no: '461-306-0872',
        preferred_pronouns: null,
        preferred_pronouns_other: null,
        priority: null,
        referral_notes: null,
        referral_out_notes: null,
        sex: 'female',
        sex_other: null,
        signature: null,
        status: 'current',
        sync_with_accounting: null,
        tags: [
          {
            id: 'RPL0PD',
            color: '#f44336',
            created_at: '2023-11-15T03:34:26-05:00',
            description:
              'This tag indicates that the referral came from a close family member or friend of the potential resident, who is seeking help on their behalf.',
            glyph: 'cross',
            name: 'Family or Friend',
            type: 'tag',
            updated_at: '2023-11-15T03:34:26-05:00',
          },
          {
            id: 'Oglw6y',
            color: '#274879',
            created_at: '2023-11-15T03:34:26-05:00',
            description:
              'This tag is for residents experiencing mental health issues, such as depression, anxiety, or other psychological conditions, and who require therapeutic interventions and support.',
            glyph: 'current_risk',
            name: 'Mental Health',
            type: 'tag',
            updated_at: '2023-11-15T03:34:26-05:00',
          },
        ],
        type: 'resident',
        updated_at: '2023-11-24T08:07:41-05:00',
      },
      response: {},
      secondary_insurance: {
        id: 'X6Xqga',
        archived_at: null,
        card_issued_at: null,
        category: 'secondary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:26:14-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: 'Member ID',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AK',
          zip_code: 'asd',
          address_line_1: 'asd',
          address_line_2: 'asd',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:26:20-05:00',
      },
      service_lines: [],
      state: 'state_draft',
      status: 'draft',
      supervising_provider: null,
      tertiary_insurance: {
        id: 'X6Xqga',
        archived_at: null,
        card_issued_at: null,
        category: 'secondary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:26:14-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: 'Member ID',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AK',
          zip_code: 'asd',
          address_line_1: 'asd',
          address_line_2: 'asd',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:26:20-05:00',
      },
      to_date: '2023-11-19T10:08:41-05:00',
      total_amount: '492.0',
      tracker_status: null,
      treatment_episode: {
        id: 'vNwNjp2',
        accepted_at: null,
        admitted_at: '2023-10-31T04:34:42-04:00',
        created_at: '2023-11-15T03:34:42-05:00',
        decline_reason: null,
        declined_at: null,
        discharge_notes: null,
        discharge_type: null,
        discharged_at: '2023-12-13T03:34:42-05:00',
        estimated_arrival: null,
        estimated_discharge: null,
        incoming_notes: null,
        levels_of_care: [],
        no_show_notes: null,
        notes: null,
        other_discharge_type: null,
        reserved_at: null,
        return_eligibility: null,
        status: 'admitted',
        tags: [],
        title: null,
        type: 'admission',
        updated_at: '2023-11-21T09:59:12-05:00',
        waitlist_notes: null,
      },
      type: 'insurance_claim',
      type_of_service_code: null,
      updated_at: '2023-11-24T08:07:41-05:00',
      validation: {},
      value_codes: [],
      version: 'v2',
      warnings: {},
    },
    insurance_claim_service_line: {
      id: 'NP2pgR',
      created_at: '2023-11-24T08:03:57-05:00',
      diagnoses: [],
      diagnoses_ids: [],
      identifier: 'BH_CLM_SVC_000008',
      insurance_claim: {
        status: 'draft',
      },
      insurance_new_code: {
        id: 'RPL0PD',
        code_type: 'cpt',
        created_at: '2023-11-20T11:12:50-05:00',
        description: 'test 213',
        modifier_codes: [],
        notes: '',
        procedure_code: 'aa',
        revenue_code: '',
        service_name: 'A',
        type: 'insurance_new_code',
        unit: 'per_unit',
        updated_at: '2023-11-20T11:12:50-05:00',
      },
      insurance_new_payment_service_line: {
        identifier: 'BH_PAY_000004',
        allowed_amount: '0.0',
        insurance_paid_amount: '0.0',
        deductible_amount: '0.0',
        copay_amount: '0.0',
        coinsurance_amount: '0.0',
        adjustment_group_code: '',
        adjustment_reason_code: '',
        adjustment_amount: '0.0',
        balance_client_due_amount: '200.0',
        balance_billed_amount: '0.0',
      },
      modifier_codes: [],
      revenue_code: null,
      service_date_end: '2023-11-19T09:46:28-05:00',
      service_date_start: '2023-11-19T09:13:28-05:00',
      status: 'balance_due_patient',
      total_amount: '123.0',
      type: 'insurance_claim_service_line',
      unit_price: '123.0',
      unit_type: 'UN',
      units: 1,
      updated_at: '2023-11-24T08:07:41-05:00',
    },
    insurance_paid_amount: '0.0',
    last_edited_by: {
      id: 'wgWOgG',
      avatar: null,
      created_at: '2023-11-15T03:32:00-05:00',
      email: 'robert.eles@behavehealth.com',
      first_name: 'Robert',
      has_accounting_access: false,
      is_pin_set: false,
      is_shared_with_portal: true,
      last_name: 'Eles',
      middle_name: null,
      name: 'Robert Eles',
      phone_no: null,
      phone_no_ext: null,
      position: 'dev',
      signature:
        'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
      type: 'bh_employee',
      updated_at: '2023-11-15T11:16:23-05:00',
    },
    type: 'insurance_new_payment_service_line',
    updated_at: '2023-11-24T08:07:41-05:00',
  },
  {
    id: '1goEPw',
    adjustment_amount: '0.0',
    adjustment_group_code: '',
    adjustment_reason_code: '',
    allowed_amount: '0.0',
    author: {
      id: 'wgWOgG',
      avatar: null,
      created_at: '2023-11-15T03:32:00-05:00',
      email: 'robert.eles@behavehealth.com',
      first_name: 'Robert',
      has_accounting_access: false,
      is_pin_set: false,
      is_shared_with_portal: true,
      last_name: 'Eles',
      middle_name: null,
      name: 'Robert Eles',
      phone_no: null,
      phone_no_ext: null,
      position: 'dev',
      signature:
        'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
      type: 'bh_employee',
      updated_at: '2023-11-15T11:16:23-05:00',
    },
    balance_billed_amount: '0.0',
    balance_client_due_amount: '300.0',
    balance_due_status: null,
    coinsurance_amount: '0.0',
    copay_amount: '0.0',
    created_at: '2023-11-24T08:07:41-05:00',
    deductible_amount: '0.0',
    identifier: 'BH_PAY_LN_000009',
    insurance_claim: {
      id: 'Oglw6y',
      admission_source_code: null,
      admission_type_code: null,
      admitted_at: null,
      admitting_diagnosis: {
        id: 'WoJokOq',
        category: 'primary',
        code: 'F10.23',
        created_at: '2023-11-15T03:58:01-05:00',
        description: 'Alcohol dependence with withdrawal',
        from: null,
        notes:
          'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
        source: 'internal',
        status: 'active',
        type: 'diagnosis',
        until: null,
        updated_at: '2023-11-17T13:00:26-05:00',
      },
      assignee: null,
      attending_provider: null,
      author: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      bill_type: null,
      billing_note: null,
      billing_provider: {
        id: 'X6Xqga',
        address: {
          city: 'West Harwich',
          state: 'MA',
          country: 'USA',
          zip_code: '02671',
          address_line_1: '32 Main Street',
          address_line_2: '',
        },
        category: 'npi',
        certification: null,
        continued_education_required: false,
        created_at: '2023-11-20T04:30:56-05:00',
        credential: 'Test',
        current_credits: null,
        employee_identifier: null,
        expires_at: null,
        first_name: null,
        graduation_year: null,
        issued_at: '2023-11-23T00:00:00-05:00',
        last_name: null,
        level: 'organization',
        license_number: '123',
        notes: '',
        organization: 'Org Test',
        phone_no: '1231231231',
        provider_type: null,
        required_annual_credits: null,
        status: null,
        tax_identifier: '123',
        type: 'credential',
        updated_at: '2023-11-20T04:31:03-05:00',
      },
      category: 'professional',
      claim_filing_code: 'CI',
      claim_frequency_code: '1',
      condition_codes: [],
      control_number: '9U5C9YE64',
      covering_from: '2023-11-19T09:13:22-05:00',
      covering_until: '2023-11-19T10:08:41-05:00',
      created_at: '2023-11-24T08:03:57-05:00',
      discharged_at: null,
      flagged_status: null,
      from_date: '2023-11-19T09:13:22-05:00',
      identifier: 'BH_CLM_000004',
      initial_submission_at: null,
      insurance_authorization: null,
      insurance_claim_service_lines: [
        {
          id: 'dgyN9M',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000007',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '100.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:35:22-05:00',
          service_date_start: '2023-11-19T09:13:22-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: 'NP2pgR',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000008',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '200.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:46:28-05:00',
          service_date_start: '2023-11-19T09:13:28-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: '1goEPw',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000009',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '300.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:57:35-05:00',
          service_date_start: '2023-11-19T09:13:35-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: 'bPxBg0',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000010',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '400.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T10:08:41-05:00',
          service_date_start: '2023-11-19T09:13:41-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
      ],
      insurance_fee_schedule: null,
      insurance_local_payer: {
        id: 'RPL0PD',
        address: {},
        archived_at: null,
        avatar: null,
        closing_at: null,
        created_at: '2023-11-19T09:15:21-05:00',
        enrolled_status: 'not_enrolled',
        fax_no: null,
        insurance_clearing_house_config: null,
        name: 'Test',
        network_status: 'out_of_network',
        opening_at: null,
        opening_times: null,
        other_phone_no_1: null,
        other_phone_no_2: null,
        payer_id: null,
        payer_source: 'clearing_house',
        phone_no: null,
        status: 'active',
        supports: null,
        tags: [],
        timezone: null,
        type: 'insurance_local_payer',
        updated_at: '2023-11-19T09:15:21-05:00',
        website: null,
      },
      insurance_new_fee_schedule: {
        id: 'RPL0PD',
        archived_at: null,
        created_at: '2023-11-19T09:14:10-05:00',
        employees: [],
        houses: [],
        insurance_local_payers: [],
        insurance_new_fee_schedule_services: [
          {
            id: 'RPL0PD',
            created_at: '2023-11-20T11:13:35-05:00',
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            price: '123.0',
            type: 'insurance_new_fee_schedule_service',
            updated_at: '2023-11-20T11:13:35-05:00',
          },
        ],
        name: 'aaa',
        status: 'active',
        type: 'insurance_new_fee_schedule',
        updated_at: '2023-11-20T11:13:35-05:00',
      },
      last_claim_note: null,
      last_insurance_claim_status: null,
      last_submission_at: null,
      ordering_provider: null,
      original_claim_no: '',
      patient_status_code: null,
      place_of_service_code: '57',
      primary_insurance: {
        id: 'RPL0PD',
        archived_at: null,
        card_issued_at: null,
        category: 'primary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:25:08-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: '12312312321',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AZ',
          zip_code: '123',
          address_line_1: 'asd',
          address_line_2: 'dsa',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:25:08-05:00',
      },
      principal_diagnosis: {
        id: 'WoJokOq',
        category: 'primary',
        code: 'F10.23',
        created_at: '2023-11-15T03:58:01-05:00',
        description: 'Alcohol dependence with withdrawal',
        from: null,
        notes:
          'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
        source: 'internal',
        status: 'active',
        type: 'diagnosis',
        until: null,
        updated_at: '2023-11-17T13:00:26-05:00',
      },
      referring_provider: null,
      rendering_provider: null,
      resident: {
        id: 'vNwNjp2',
        admitted_at: '2023-10-31T04:34:42-04:00',
        avatar: null,
        behave_id: 'ABVI000001',
        behave_identifier: '4P1531SEOM1D',
        care_level: 'sober_living',
        client_portal_status: null,
        color_code: null,
        created_at: '2023-11-15T03:34:42-05:00',
        dob: '2003-11-18T19:00:00-05:00',
        email: 'abbigail.villarreal@getnada.com',
        encrypted_data: {
          id: 'VoJo107',
          address: {
            address_line_1: '32 Main Street',
            address_line_2: '',
            city: 'East Hampton',
            state: 'NY',
            zip_code: '11930',
            country: 'USA',
          },
          created_at: '2023-11-15T03:34:42-05:00',
          notes: null,
          secure_pin: null,
          ssn: null,
          treatment_address: null,
          type: 'encrypted_data',
          updated_at: '2023-11-22T11:27:15-05:00',
        },
        external_id: '4444f162-8390-46e7-8e63-d6bfa9b63b65',
        financial_notes: null,
        first_name: 'Abbigail',
        gender_identity: null,
        gender_identity_other: null,
        has_portal_access: true,
        high_risk_notes: null,
        high_risk_title: null,
        invited_to_portal_at: '2023-11-15T03:34:42-05:00',
        is_billed_by_behave: true,
        is_insurance_auth_required: false,
        is_insurance_billable: true,
        is_insured: null,
        is_the_policy_holder: null,
        last_logged_in_at: null,
        last_name: 'Villarreal',
        level_of_care: null,
        middle_name: '',
        name: 'Abbigail Villarreal',
        notes: null,
        phone_no: '461-306-0872',
        preferred_pronouns: null,
        preferred_pronouns_other: null,
        priority: null,
        referral_notes: null,
        referral_out_notes: null,
        sex: 'female',
        sex_other: null,
        signature: null,
        status: 'current',
        sync_with_accounting: null,
        tags: [
          {
            id: 'RPL0PD',
            color: '#f44336',
            created_at: '2023-11-15T03:34:26-05:00',
            description:
              'This tag indicates that the referral came from a close family member or friend of the potential resident, who is seeking help on their behalf.',
            glyph: 'cross',
            name: 'Family or Friend',
            type: 'tag',
            updated_at: '2023-11-15T03:34:26-05:00',
          },
          {
            id: 'Oglw6y',
            color: '#274879',
            created_at: '2023-11-15T03:34:26-05:00',
            description:
              'This tag is for residents experiencing mental health issues, such as depression, anxiety, or other psychological conditions, and who require therapeutic interventions and support.',
            glyph: 'current_risk',
            name: 'Mental Health',
            type: 'tag',
            updated_at: '2023-11-15T03:34:26-05:00',
          },
        ],
        type: 'resident',
        updated_at: '2023-11-24T08:07:41-05:00',
      },
      response: {},
      secondary_insurance: {
        id: 'X6Xqga',
        archived_at: null,
        card_issued_at: null,
        category: 'secondary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:26:14-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: 'Member ID',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AK',
          zip_code: 'asd',
          address_line_1: 'asd',
          address_line_2: 'asd',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:26:20-05:00',
      },
      service_lines: [],
      state: 'state_draft',
      status: 'draft',
      supervising_provider: null,
      tertiary_insurance: {
        id: 'X6Xqga',
        archived_at: null,
        card_issued_at: null,
        category: 'secondary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:26:14-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: 'Member ID',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AK',
          zip_code: 'asd',
          address_line_1: 'asd',
          address_line_2: 'asd',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:26:20-05:00',
      },
      to_date: '2023-11-19T10:08:41-05:00',
      total_amount: '492.0',
      tracker_status: null,
      treatment_episode: {
        id: 'vNwNjp2',
        accepted_at: null,
        admitted_at: '2023-10-31T04:34:42-04:00',
        created_at: '2023-11-15T03:34:42-05:00',
        decline_reason: null,
        declined_at: null,
        discharge_notes: null,
        discharge_type: null,
        discharged_at: '2023-12-13T03:34:42-05:00',
        estimated_arrival: null,
        estimated_discharge: null,
        incoming_notes: null,
        levels_of_care: [],
        no_show_notes: null,
        notes: null,
        other_discharge_type: null,
        reserved_at: null,
        return_eligibility: null,
        status: 'admitted',
        tags: [],
        title: null,
        type: 'admission',
        updated_at: '2023-11-21T09:59:12-05:00',
        waitlist_notes: null,
      },
      type: 'insurance_claim',
      type_of_service_code: null,
      updated_at: '2023-11-24T08:07:41-05:00',
      validation: {},
      value_codes: [],
      version: 'v2',
      warnings: {},
    },
    insurance_claim_service_line: {
      id: '1goEPw',
      created_at: '2023-11-24T08:03:57-05:00',
      diagnoses: [],
      diagnoses_ids: [],
      identifier: 'BH_CLM_SVC_000009',
      insurance_claim: {
        status: 'draft',
      },
      insurance_new_code: {
        id: 'RPL0PD',
        code_type: 'cpt',
        created_at: '2023-11-20T11:12:50-05:00',
        description: 'test 213',
        modifier_codes: [],
        notes: '',
        procedure_code: 'aa',
        revenue_code: '',
        service_name: 'A',
        type: 'insurance_new_code',
        unit: 'per_unit',
        updated_at: '2023-11-20T11:12:50-05:00',
      },
      insurance_new_payment_service_line: {
        identifier: 'BH_PAY_000004',
        allowed_amount: '0.0',
        insurance_paid_amount: '0.0',
        deductible_amount: '0.0',
        copay_amount: '0.0',
        coinsurance_amount: '0.0',
        adjustment_group_code: '',
        adjustment_reason_code: '',
        adjustment_amount: '0.0',
        balance_client_due_amount: '300.0',
        balance_billed_amount: '0.0',
      },
      modifier_codes: [],
      revenue_code: null,
      service_date_end: '2023-11-19T09:57:35-05:00',
      service_date_start: '2023-11-19T09:13:35-05:00',
      status: 'balance_due_patient',
      total_amount: '123.0',
      type: 'insurance_claim_service_line',
      unit_price: '123.0',
      unit_type: 'UN',
      units: 1,
      updated_at: '2023-11-24T08:07:41-05:00',
    },
    insurance_paid_amount: '0.0',
    last_edited_by: {
      id: 'wgWOgG',
      avatar: null,
      created_at: '2023-11-15T03:32:00-05:00',
      email: 'robert.eles@behavehealth.com',
      first_name: 'Robert',
      has_accounting_access: false,
      is_pin_set: false,
      is_shared_with_portal: true,
      last_name: 'Eles',
      middle_name: null,
      name: 'Robert Eles',
      phone_no: null,
      phone_no_ext: null,
      position: 'dev',
      signature:
        'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
      type: 'bh_employee',
      updated_at: '2023-11-15T11:16:23-05:00',
    },
    type: 'insurance_new_payment_service_line',
    updated_at: '2023-11-24T08:07:41-05:00',
  },
  {
    id: 'bPxBg0',
    adjustment_amount: '0.0',
    adjustment_group_code: '',
    adjustment_reason_code: '',
    allowed_amount: '0.0',
    author: {
      id: 'wgWOgG',
      avatar: null,
      created_at: '2023-11-15T03:32:00-05:00',
      email: 'robert.eles@behavehealth.com',
      first_name: 'Robert',
      has_accounting_access: false,
      is_pin_set: false,
      is_shared_with_portal: true,
      last_name: 'Eles',
      middle_name: null,
      name: 'Robert Eles',
      phone_no: null,
      phone_no_ext: null,
      position: 'dev',
      signature:
        'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
      type: 'bh_employee',
      updated_at: '2023-11-15T11:16:23-05:00',
    },
    balance_billed_amount: '0.0',
    balance_client_due_amount: '400.0',
    balance_due_status: null,
    coinsurance_amount: '0.0',
    copay_amount: '0.0',
    created_at: '2023-11-24T08:07:41-05:00',
    deductible_amount: '0.0',
    identifier: 'BH_PAY_LN_000010',
    insurance_claim: {
      id: 'Oglw6y',
      admission_source_code: null,
      admission_type_code: null,
      admitted_at: null,
      admitting_diagnosis: {
        id: 'WoJokOq',
        category: 'primary',
        code: 'F10.23',
        created_at: '2023-11-15T03:58:01-05:00',
        description: 'Alcohol dependence with withdrawal',
        from: null,
        notes:
          'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
        source: 'internal',
        status: 'active',
        type: 'diagnosis',
        until: null,
        updated_at: '2023-11-17T13:00:26-05:00',
      },
      assignee: null,
      attending_provider: null,
      author: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      bill_type: null,
      billing_note: null,
      billing_provider: {
        id: 'X6Xqga',
        address: {
          city: 'West Harwich',
          state: 'MA',
          country: 'USA',
          zip_code: '02671',
          address_line_1: '32 Main Street',
          address_line_2: '',
        },
        category: 'npi',
        certification: null,
        continued_education_required: false,
        created_at: '2023-11-20T04:30:56-05:00',
        credential: 'Test',
        current_credits: null,
        employee_identifier: null,
        expires_at: null,
        first_name: null,
        graduation_year: null,
        issued_at: '2023-11-23T00:00:00-05:00',
        last_name: null,
        level: 'organization',
        license_number: '123',
        notes: '',
        organization: 'Org Test',
        phone_no: '1231231231',
        provider_type: null,
        required_annual_credits: null,
        status: null,
        tax_identifier: '123',
        type: 'credential',
        updated_at: '2023-11-20T04:31:03-05:00',
      },
      category: 'professional',
      claim_filing_code: 'CI',
      claim_frequency_code: '1',
      condition_codes: [],
      control_number: '9U5C9YE64',
      covering_from: '2023-11-19T09:13:22-05:00',
      covering_until: '2023-11-19T10:08:41-05:00',
      created_at: '2023-11-24T08:03:57-05:00',
      discharged_at: null,
      flagged_status: null,
      from_date: '2023-11-19T09:13:22-05:00',
      identifier: 'BH_CLM_000004',
      initial_submission_at: null,
      insurance_authorization: null,
      insurance_claim_service_lines: [
        {
          id: 'dgyN9M',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000007',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '100.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:35:22-05:00',
          service_date_start: '2023-11-19T09:13:22-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: 'NP2pgR',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000008',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '200.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:46:28-05:00',
          service_date_start: '2023-11-19T09:13:28-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: '1goEPw',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000009',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '300.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T09:57:35-05:00',
          service_date_start: '2023-11-19T09:13:35-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        {
          id: 'bPxBg0',
          created_at: '2023-11-24T08:03:57-05:00',
          diagnoses: [],
          diagnoses_ids: [],
          identifier: 'BH_CLM_SVC_000010',
          insurance_claim: {
            status: 'draft',
          },
          insurance_new_code: {
            id: 'RPL0PD',
            code_type: 'cpt',
            created_at: '2023-11-20T11:12:50-05:00',
            description: 'test 213',
            modifier_codes: [],
            notes: '',
            procedure_code: 'aa',
            revenue_code: '',
            service_name: 'A',
            type: 'insurance_new_code',
            unit: 'per_unit',
            updated_at: '2023-11-20T11:12:50-05:00',
          },
          insurance_new_payment_service_line: {
            identifier: 'BH_PAY_000004',
            allowed_amount: '0.0',
            insurance_paid_amount: '0.0',
            deductible_amount: '0.0',
            copay_amount: '0.0',
            coinsurance_amount: '0.0',
            adjustment_group_code: '',
            adjustment_reason_code: '',
            adjustment_amount: '0.0',
            balance_client_due_amount: '400.0',
            balance_billed_amount: '0.0',
          },
          modifier_codes: [],
          revenue_code: null,
          service_date_end: '2023-11-19T10:08:41-05:00',
          service_date_start: '2023-11-19T09:13:41-05:00',
          status: 'balance_due_patient',
          total_amount: '123.0',
          type: 'insurance_claim_service_line',
          unit_price: '123.0',
          unit_type: 'UN',
          units: 1,
          updated_at: '2023-11-24T08:07:41-05:00',
        },
      ],
      insurance_fee_schedule: null,
      insurance_local_payer: {
        id: 'RPL0PD',
        address: {},
        archived_at: null,
        avatar: null,
        closing_at: null,
        created_at: '2023-11-19T09:15:21-05:00',
        enrolled_status: 'not_enrolled',
        fax_no: null,
        insurance_clearing_house_config: null,
        name: 'Test',
        network_status: 'out_of_network',
        opening_at: null,
        opening_times: null,
        other_phone_no_1: null,
        other_phone_no_2: null,
        payer_id: null,
        payer_source: 'clearing_house',
        phone_no: null,
        status: 'active',
        supports: null,
        tags: [],
        timezone: null,
        type: 'insurance_local_payer',
        updated_at: '2023-11-19T09:15:21-05:00',
        website: null,
      },
      insurance_new_fee_schedule: {
        id: 'RPL0PD',
        archived_at: null,
        created_at: '2023-11-19T09:14:10-05:00',
        employees: [],
        houses: [],
        insurance_local_payers: [],
        insurance_new_fee_schedule_services: [
          {
            id: 'RPL0PD',
            created_at: '2023-11-20T11:13:35-05:00',
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            price: '123.0',
            type: 'insurance_new_fee_schedule_service',
            updated_at: '2023-11-20T11:13:35-05:00',
          },
        ],
        name: 'aaa',
        status: 'active',
        type: 'insurance_new_fee_schedule',
        updated_at: '2023-11-20T11:13:35-05:00',
      },
      last_claim_note: null,
      last_insurance_claim_status: null,
      last_submission_at: null,
      ordering_provider: null,
      original_claim_no: '',
      patient_status_code: null,
      place_of_service_code: '57',
      primary_insurance: {
        id: 'RPL0PD',
        archived_at: null,
        card_issued_at: null,
        category: 'primary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:25:08-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: '12312312321',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AZ',
          zip_code: '123',
          address_line_1: 'asd',
          address_line_2: 'dsa',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:25:08-05:00',
      },
      principal_diagnosis: {
        id: 'WoJokOq',
        category: 'primary',
        code: 'F10.23',
        created_at: '2023-11-15T03:58:01-05:00',
        description: 'Alcohol dependence with withdrawal',
        from: null,
        notes:
          'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
        source: 'internal',
        status: 'active',
        type: 'diagnosis',
        until: null,
        updated_at: '2023-11-17T13:00:26-05:00',
      },
      referring_provider: null,
      rendering_provider: null,
      resident: {
        id: 'vNwNjp2',
        admitted_at: '2023-10-31T04:34:42-04:00',
        avatar: null,
        behave_id: 'ABVI000001',
        behave_identifier: '4P1531SEOM1D',
        care_level: 'sober_living',
        client_portal_status: null,
        color_code: null,
        created_at: '2023-11-15T03:34:42-05:00',
        dob: '2003-11-18T19:00:00-05:00',
        email: 'abbigail.villarreal@getnada.com',
        encrypted_data: {
          id: 'VoJo107',
          address: {
            address_line_1: '32 Main Street',
            address_line_2: '',
            city: 'East Hampton',
            state: 'NY',
            zip_code: '11930',
            country: 'USA',
          },
          created_at: '2023-11-15T03:34:42-05:00',
          notes: null,
          secure_pin: null,
          ssn: null,
          treatment_address: null,
          type: 'encrypted_data',
          updated_at: '2023-11-22T11:27:15-05:00',
        },
        external_id: '4444f162-8390-46e7-8e63-d6bfa9b63b65',
        financial_notes: null,
        first_name: 'Abbigail',
        gender_identity: null,
        gender_identity_other: null,
        has_portal_access: true,
        high_risk_notes: null,
        high_risk_title: null,
        invited_to_portal_at: '2023-11-15T03:34:42-05:00',
        is_billed_by_behave: true,
        is_insurance_auth_required: false,
        is_insurance_billable: true,
        is_insured: null,
        is_the_policy_holder: null,
        last_logged_in_at: null,
        last_name: 'Villarreal',
        level_of_care: null,
        middle_name: '',
        name: 'Abbigail Villarreal',
        notes: null,
        phone_no: '461-306-0872',
        preferred_pronouns: null,
        preferred_pronouns_other: null,
        priority: null,
        referral_notes: null,
        referral_out_notes: null,
        sex: 'female',
        sex_other: null,
        signature: null,
        status: 'current',
        sync_with_accounting: null,
        tags: [
          {
            id: 'RPL0PD',
            color: '#f44336',
            created_at: '2023-11-15T03:34:26-05:00',
            description:
              'This tag indicates that the referral came from a close family member or friend of the potential resident, who is seeking help on their behalf.',
            glyph: 'cross',
            name: 'Family or Friend',
            type: 'tag',
            updated_at: '2023-11-15T03:34:26-05:00',
          },
          {
            id: 'Oglw6y',
            color: '#274879',
            created_at: '2023-11-15T03:34:26-05:00',
            description:
              'This tag is for residents experiencing mental health issues, such as depression, anxiety, or other psychological conditions, and who require therapeutic interventions and support.',
            glyph: 'current_risk',
            name: 'Mental Health',
            type: 'tag',
            updated_at: '2023-11-15T03:34:26-05:00',
          },
        ],
        type: 'resident',
        updated_at: '2023-11-24T08:07:41-05:00',
      },
      response: {},
      secondary_insurance: {
        id: 'X6Xqga',
        archived_at: null,
        card_issued_at: null,
        category: 'secondary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:26:14-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: 'Member ID',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AK',
          zip_code: 'asd',
          address_line_1: 'asd',
          address_line_2: 'asd',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:26:20-05:00',
      },
      service_lines: [],
      state: 'state_draft',
      status: 'draft',
      supervising_provider: null,
      tertiary_insurance: {
        id: 'X6Xqga',
        archived_at: null,
        card_issued_at: null,
        category: 'secondary',
        check_summary: null,
        claims_phone_no: '',
        clearing_house: null,
        created_at: '2023-11-22T11:26:14-05:00',
        effective_at: null,
        group_id: '',
        group_name: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_name: 'Test',
        is_client_policy_holder: true,
        member_id: 'Member ID',
        notes: '',
        payer_source: 'clearing_house',
        plan: '',
        plan_type: null,
        policy_holder: {
          city: 'dsa',
          state: 'AK',
          zip_code: 'asd',
          address_line_1: 'asd',
          address_line_2: 'asd',
        },
        resets_at: null,
        status: 'active',
        tags: [],
        terminates_at: null,
        type: 'insurance_policy',
        updated_at: '2023-11-22T11:26:20-05:00',
      },
      to_date: '2023-11-19T10:08:41-05:00',
      total_amount: '492.0',
      tracker_status: null,
      treatment_episode: {
        id: 'vNwNjp2',
        accepted_at: null,
        admitted_at: '2023-10-31T04:34:42-04:00',
        created_at: '2023-11-15T03:34:42-05:00',
        decline_reason: null,
        declined_at: null,
        discharge_notes: null,
        discharge_type: null,
        discharged_at: '2023-12-13T03:34:42-05:00',
        estimated_arrival: null,
        estimated_discharge: null,
        incoming_notes: null,
        levels_of_care: [],
        no_show_notes: null,
        notes: null,
        other_discharge_type: null,
        reserved_at: null,
        return_eligibility: null,
        status: 'admitted',
        tags: [],
        title: null,
        type: 'admission',
        updated_at: '2023-11-21T09:59:12-05:00',
        waitlist_notes: null,
      },
      type: 'insurance_claim',
      type_of_service_code: null,
      updated_at: '2023-11-24T08:07:41-05:00',
      validation: {},
      value_codes: [],
      version: 'v2',
      warnings: {},
    },
    insurance_claim_service_line: {
      id: 'bPxBg0',
      created_at: '2023-11-24T08:03:57-05:00',
      diagnoses: [],
      diagnoses_ids: [],
      identifier: 'BH_CLM_SVC_000010',
      insurance_claim: {
        status: 'draft',
      },
      insurance_new_code: {
        id: 'RPL0PD',
        code_type: 'cpt',
        created_at: '2023-11-20T11:12:50-05:00',
        description: 'test 213',
        modifier_codes: [],
        notes: '',
        procedure_code: 'aa',
        revenue_code: '',
        service_name: 'A',
        type: 'insurance_new_code',
        unit: 'per_unit',
        updated_at: '2023-11-20T11:12:50-05:00',
      },
      insurance_new_payment_service_line: {
        identifier: 'BH_PAY_000004',
        allowed_amount: '0.0',
        insurance_paid_amount: '0.0',
        deductible_amount: '0.0',
        copay_amount: '0.0',
        coinsurance_amount: '0.0',
        adjustment_group_code: '',
        adjustment_reason_code: '',
        adjustment_amount: '0.0',
        balance_client_due_amount: '400.0',
        balance_billed_amount: '0.0',
      },
      modifier_codes: [],
      revenue_code: null,
      service_date_end: '2023-11-19T10:08:41-05:00',
      service_date_start: '2023-11-19T09:13:41-05:00',
      status: 'balance_due_patient',
      total_amount: '123.0',
      type: 'insurance_claim_service_line',
      unit_price: '123.0',
      unit_type: 'UN',
      units: 1,
      updated_at: '2023-11-24T08:07:41-05:00',
    },
    insurance_paid_amount: '0.0',
    last_edited_by: {
      id: 'wgWOgG',
      avatar: null,
      created_at: '2023-11-15T03:32:00-05:00',
      email: 'robert.eles@behavehealth.com',
      first_name: 'Robert',
      has_accounting_access: false,
      is_pin_set: false,
      is_shared_with_portal: true,
      last_name: 'Eles',
      middle_name: null,
      name: 'Robert Eles',
      phone_no: null,
      phone_no_ext: null,
      position: 'dev',
      signature:
        'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
      type: 'bh_employee',
      updated_at: '2023-11-15T11:16:23-05:00',
    },
    type: 'insurance_new_payment_service_line',
    updated_at: '2023-11-24T08:07:41-05:00',
  },
]
