import React from 'react'
import { connect } from 'react-redux'

import Button from '../Button'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Form from '../Forms/Form'
import FormSection from '../Forms/FormSection'
import Input from '../Forms/Input'
import Option from '../Forms/Option'
import Textarea from '../Forms/Textarea'
import Overlay from '../Overlay'
import Section from '../Section'
import Select from '../Forms/Select'
import DateInput from '../Forms/DateInput'
import Radio from '../Forms/Radio'
import RadioGroup from '../Forms/RadioGroup'
import AmountInput from '../Forms/AmountInput'
import ContextShow from '../Forms/ContextShow'
import MultiObjectSelector from '../Forms/Selectors/MultiObject/MultiObjectSelector'

import { beautifulAmount } from '../../utils/functions'
import { PRICING_STRATEGIES, PRICING_UNIT_TYPES } from './constants'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../Overlays/pages/OverlayBase'
import { withOverlayError } from '../../hocs/withOverlayError'

import withRouterV6 from '../../hocs/withRouterV6'

class BillingCreditOverlay extends OverlayBase {
  renderHeader = () => {
    return <Overlay.Header title="Credit" glyph="dollar" />
  }

  renderContent = () => {
    const { $editable, params } = this.state
    const { record, timezone } = this.props

    return (
      <Overlay.Content>
        <Section>
          <Form
            getForm={this.form}
            initialModel={{ ...record, ...params }}
            isEditable={$editable}
            timezone={timezone}
            onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
          >
            <FormSection>
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <RadioGroup label="Type" layout="vertical-dense" model="credit_type" defaultValue="fixed">
                <Radio label="Fixed Amount" value="fixed" />
                <Radio label="Unit Based" value="unit" />
              </RadioGroup>

              <RadioGroup label="Usage Type" layout="vertical-dense" model="usage_type" defaultValue="monthly">
                <Radio label="Monthly" value="monthly" />
                <Radio label="One-Time" value="one_time" />
              </RadioGroup>

              <Select label="Status" model="status" defaultValue="inactive">
                <Option label="Active" value="active" />
                <Option label="Inactive" value="inactive" />
              </Select>

              <ContextShow when="credit_type" is="fixed">
                <AmountInput label="Amount" model="amount" />
              </ContextShow>

              <ContextShow when="credit_type" is="unit">
                <Input type="number" min="0" prefix="Credits" label="Amount" model="amount" defaultValue={0} />

                <Select label="Category" model="category" defaultValue="client_per_month">
                  {Object.entries(PRICING_UNIT_TYPES).map(([key, value]) => (
                    <Option label={value} value={key} />
                  ))}
                </Select>
              </ContextShow>

              <DateInput defaultToNow label="Valid From" model="valid_from" />
              <DateInput label="Valid Until" model="valid_until" />

              <Textarea useQuickText label="Notes" model="notes" />

              <MultiObjectSelector
                label="Apply to products"
                model="products"
                type="products"
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => {
                  return `${PRICING_STRATEGIES[data.pricing_type]} – ${beautifulAmount(data.price)} ${
                    PRICING_UNIT_TYPES[data.price_unit_type]
                  }`
                }}
                searchKeys={['name', 'category', 'account_type']}
              />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button glyph="edit" label="Edit Credit" type="default" isDisabled={this.props.loading} onClick={this.edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Credit?"
              message="Are you sure you want to delete this credit? This action cannot be undone."
              onYes={this.delete}
            >
              <Button fullWidth glyph="delete" label="Delete" type="default" color="red" isLoading={this.props.loading} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'credits')

export default connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(BillingCreditOverlay))

export const BillingCreditOverlayV6 = withRouterV6(
  connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(BillingCreditOverlay)),
)
