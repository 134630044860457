import React from 'react'
import size from 'lodash/size'

import { COLORS } from '../../../theme'

import ClearButton from '../../Forms/ClearButton'
import Input from '../common/Input'

type Props = {
  column: any
}

const NumberRangeFilter = (props: Props) => {
  const {
    column: { filterValue = [], setFilter },
  } = props

  const clear = () => {
    setFilter([])
  }

  const hasFilter = size(filterValue) >= 1

  // const [min, max] = React.useMemo(() => {
  //   let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
  //   let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0

  //   preFilteredRows.forEach((row: any) => {
  //     min = Math.min(row.values[id], min)
  //     max = Math.max(row.values[id], max)
  //   })

  //   return [min, max]
  // }, [id, preFilteredRows])

  return (
    <div style={styles.root}>
      <Input
        type="text"
        value={(!filterValue[0] && filterValue[0] !== 0) ? '' : filterValue[0]}
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [(!val && val !== 0) ? undefined : parseInt(val), old[1]])
        }}
        placeholder="Min"
        css={styles.input}
        setFilter={setFilter}
      />

      <span css={styles.dash}>–</span>

      <Input
        type="text"
        value={(!filterValue[1] && filterValue[1] !== 0) ? '' : filterValue[1]}
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [old[0], (!val && val !== 0) ? undefined : parseInt(val)])
        }}
        placeholder="Max"
        css={styles.input}
        setFilter={setFilter}
      />
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },

  dash: {
    color: COLORS.textMuted,
    opacity: 0.6,
    display: 'inline-flex',
    margin: '0 0.2rem',
  },

  input: {
    paddingRight: '0.2rem',
  },

  clearButton: {
    alignItems: 'flex-start',
    paddingTop: '0.3rem',
  },
}

export default NumberRangeFilter
