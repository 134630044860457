import React from 'react'
import { useParams } from 'react-router-dom'

import { APP_URLS } from '@behavehealth/constructs/MyAccount/utils'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Card from '@behavehealth/components/Card'
import PageHeader from '@behavehealth/components/PageHeader'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import State from '@behavehealth/components/State'

export const RootCommunityProfile = () => {
  const params: any = useParams()
  const { resource_id: id } = params

  const {
    data: organization,
    isLoading,
    isError,
    error,
  }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  if (!organization || isLoading) return <PageLoader />

  const slug = organization?.other_profile?.slug

  const communityURL = slug && `${APP_URLS.community}/profiles/${slug}`

  return (
    <div css={STYLES.root}>
      <PageHeader title="Community Profile" icon="community_profile" />

      <div css={STYLES.content}>
        <Card>
          {communityURL ? (
            <iframe
              title="iframe"
              src={`${communityURL}?embed=true`}
              frameBorder="0"
              border="0"
              cellSpacing="0"
              className="border-none w-full h-full min-h-[80vh]"
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
            />
          ) : (
            <State
              isEmpty
              icon="community_profile"
              title="Community Profile"
              emptyDescription="No community profile found for this partner"
            />
          )}
        </Card>
      </div>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    overflow: 'hidden',
    height: '100%',
  },

  content: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
    padding: '1rem',
  },
}

export const CommunityProfile = withPageError(RootCommunityProfile)
