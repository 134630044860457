import React from 'react'
import { useLocation } from 'react-router-dom'
import startCase from 'lodash/startCase'

import Status from '../Status'
import { Text } from '../Typography'

import ClientContactActions from '../QuickActions/ClientContact'
import useActivePath from '../../hooks/useActivePath'

import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'
import snakeCase from 'lodash/snakeCase'

type Props = {
  contact: any
  location: any
  to: any
}

const ClientContact = ({ contact, to, onClick }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink
      testKey={`${snakeCase(contact.name)}`}
      to={to}
      isActive={isActive}
      onClick={onClick}
      actions={<ClientContactActions data={contact} />}
    >
      <CardTitle title={contact.name} />
      <CardMeta>
        {contact.relationship && <Status color="orange" label={contact.relationship} />}
        <Status color={contact.roi ? 'green' : 'red'} label={contact.roi ? 'R.O.I. Allowed' : 'R.O.I. NOT Allowed'} />
        {contact.is_emergency && <Status color="yellow" label="Emergency Contact" />}
        <Text>{`Preferred Contact Method: ${startCase(contact.preferred_contact_method) || '–'}`}</Text>
      </CardMeta>
    </CardLink>
  )
}

export default ClientContact
