import React from 'react'

import { COLORS } from '../../theme'
import { usDateTime } from '../../utils/functions'

import { apiDelete } from '../../modules/api'
import withSettings from '../../hocs/withSettings'

import ClientStatus from '../Statuses/ClientStatus'
import ConfirmDialog from '../Dialogs/ConfirmDialog'
import Glyph from '../Glyph'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const removeLinkStyles = {
  margin: '0 auto',
  color: COLORS.red,

  '&:hover': {
    color: COLORS.text,
  },
}

const columns = (to: Function = () => {}, mainLinkAs, timezone: any) => [
  {
    width: 160,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'resource',
    Header: 'Name',
    Cell: ({ value }: any) => <TableCell.MainLink as={mainLinkAs} to={to(value?.id)} label={value?.name} avatar={value?.avatar} />,
  },
  {
    width: 100,
    accessor: 'resource.behave_id',
    Header: 'Client ID',
    Cell: ({ value }: any) => <TableCell value={value && `#${value}`} />,
  },
  {
    width: 100,
    accessor: 'resource.status',
    Header: 'Status',
    Cell: ({ value }: any) => <ClientStatus status={value} />,
  },
  {
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => usDateTime(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 60,
    id: 'actions',
    Header: 'Actions',
    Cell: ({ row }: any) => (
      <ConfirmDialog
        title="Remove client?"
        message={`Remove ${row.original.resource?.name} from the access list? This staff member will not be able to access their profile anymore.`}
        yesLabel="Remove"
        noLabel="Cancel"
        yesColor="red"
        glyph="delete"
        onYes={() => apiDelete({ name: 'access_controls', url: `/access_controls/${row.original?.id}` })}
      >
        <TableCell.LinkWithPermissions
          label="Remove"
          type="link"
          glyph={<Glyph glyph="delete" size={20} />}
          css={removeLinkStyles}
          permission="access_controls.delete"
        />
      </ConfirmDialog>
    ),
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  title: string
  to?: Function
  timezone: string
  user: Object
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const AccessListTable = (props: Props) => {
  const { data, emptyActions, emptyDescription, icon, isLoading, localStorageKey, batchActionsConfig, title, to, timezone, titleAfter } =
    props

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, props.mainLinkAs, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

AccessListTable.defaultProps = {
  title: 'Clients',
  icon: 'access_lists',
  emptyDescription: "This staff member doesn't have access to any clients yet.",
  localStorageKey: 'access_list',
}

export default withSettings(AccessListTable)
