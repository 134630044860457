import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { Button, Card, Chotomate, Page, Grid, HelpTagIframe } from '@behavehealth/components'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { TreatmentEpisodesTable } from '@behavehealth/constructs/TreatmentEpisodes/TreatmentEpisodesTable'

const TreatmentEpisodes = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'treatment-episodes'],
    url: `/residents/${resource_id}/admissions`,
  })

  const isEmpty = size(data) === 0

  return (
    <Page
      feature="treatment_episodes"
      plan="lite"
      isLoading={isLoading}
      isEmpty={isEmpty}
      emptyDescription="There are no Treatment Episodes yet"
      help={<HelpTagIframe id="treatment_episodes" />}
      actions={
        <Button label="Add Treatment Episode" link={`${match.url}/new`} glyph="add" type="primary" permission="treatment_episodes.create" />
      }
    >
      <Chotomate name="treatment_episodes" ready={!isLoading} />

      <Grid>
        <Card>
          <TreatmentEpisodesTable data={data} to={(id: string) => `${match.url}/${id}`} />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(TreatmentEpisodes)
