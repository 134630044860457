import React from 'react'

import Radio from '../../../../components/Forms/Radio'
import RadioGroup from '../../../../components/Forms/RadioGroup'
import SmartStatus from '../../../../components/SmartStatus'

import { COLORS } from '../../../../theme'
import { ALERT_CATEGORIES } from '../../utils/constants'

import GlyphSelector from '../../../../components/Forms/GlyphSelector'
import ColorSelector from '../../../../components/Forms/ColorSelector'
import Flex from '../../../../components/Flex'

export const AlertEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate } = props

  if (!activeElement.config) return null

  const { category } = activeElement.config

  return (
    <>
      <RadioGroup label="Category" model="category" layout="vertical-dense" defaultValue="info" value={category} onUpdate={onUpdate}>
        {Object.keys(ALERT_CATEGORIES).map((alertCategory) => {
          const categoryConfig = ALERT_CATEGORIES?.[alertCategory]

          if (!categoryConfig) return null

          return (
            <Radio key={alertCategory} label={<SmartStatus status={alertCategory} statuses={ALERT_CATEGORIES} />} value={alertCategory} />
          )
        })}
      </RadioGroup>

      {category === 'custom' && (
        <Flex gap="0.75rem">
          <ColorSelector label="Color" model="color" defaultValue={COLORS.text} onUpdate={onUpdate} />
          <GlyphSelector label="Glyph" model="glyph" defaultValue="question" onUpdate={onUpdate} color={activeElement.config?.color} />
        </Flex>
      )}
    </>
  )
}
