import React from 'react'

import Select from '../common/Select'

type Props = { column: any }

const DataFormStatusFilter = (props: Props) => {
	const {
		column: { filterValue, setFilter }
	} = props

	const filterOptions = [
		{
			value: 'draft',
			label: 'Draft'
		},
		{
			value: 'pending_review',
			label: 'Pending Review'
		},
		{
			value: 'updates_required',
			label: 'Updates Required'
		},
		{
			value: 'signed_off',
			label: 'Signed Off'
		},
		{
			label: 'Closed',
			value: 'closed'
		}
	]

	return <Select setFilter={setFilter} filterValue={filterValue} filterOptions={filterOptions} />
}

export default DataFormStatusFilter
