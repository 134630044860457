import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import size from 'lodash/size'
import compact from 'lodash/compact'

import { FEATURES } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'

import Alert from '../../components/Alert'
import AnimatedSwitch from '../../components/AnimatedSwitch'
import Portal from '../../components/Portal'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Checkbox from '../../components/Forms/Checkbox'
import ContextShow from '../../components/Forms/ContextShow'
import DataList from '../../components/DataList'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import State from '../../components/State'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'

import { DataTable } from '../../components/DataTable/DataTable'

import { PaymentServiceLinesImportOverlay } from './PaymentServiceLinesImportOverlay'
import { SectionCard } from '../../components/SectionCard'
import FinancialCard from '../../components/Cards/FinancialCard'
import PageSection from '../../components/PageSection/PageSection'
import { niceAmount, titleCase } from '../../utils/functions'

import { InvoicePaymentOverlay } from './InvoicePaymentOverlay'
import { InvoicePaymentsDataTable } from './InvoicePaymentsDataTable'
import { InvoiceWriteOffOverlay } from './InvoiceWriteOffOverlay'
import { InvoiceWriteOffsDataTable } from './InvoiceWriteOffsDataTable'
import { APP_URLS } from '../MyAccount/utils'

import StripePaymentOverlay from '../../components/Stripe/StripePaymentOverlay'

const SERVICE_LINE_STATUSES = {
  draft: 'Draft',
  validated: 'Validated',
  validation_error: 'Validation Error',
  submitted: 'Submitted',
  submission_error: 'Submission Error',
  denied: 'Denied',
  balance_due_client: 'Balance Due Client',
  paid: 'Paid',
  written_off: 'Written Off',
}

export const RootInvoiceOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'TODO',
    endpoint: '/TODO',
    invalidate: 'TODO',
    options: props,
  })

  const match = useRouteMatch()
  const { tenant, timezone } = useSettings()

  const [client, setClient] = React.useState<any>(isNew ? initialModel?.client || null : DATA.client)
  const [contact, setContact] = React.useState<any>(null)
  const [provider, setProvider] = React.useState<any>(isNew ? null : DATA.provider)
  const [payer, setPayer] = React.useState<any>(isNew ? null : DATA.insurance_policies[0].insurance_local_payer)

  const [billToReference, setBillToReference] = React.useState('client')

  const [isValid, setIsValid] = React.useState(false)
  const [rowsCount, setRowsCount] = React.useState(0)

  const [serviceLinesSnapshot, setServiceLinesSnapshot] = React.useState<any>([])

  const initialServiceLines = !isNew || initialModel?._with_service_lines ? DATA.invoice_lines : []
  const [serviceLines, setServiceLines] = React.useState<any>(initialServiceLines)

  const serviceLinesCount = size(serviceLines)
  const isServiceLinesEmpty = serviceLinesCount === 0

  const addServiceLine = () => {
    setServiceLines((c) => [...c, { _id: uuid() }])
  }

  const paymentLink = `${APP_URLS.mini_apps}/rcm-invoices/${tenant.subdomain}/pay/${uuid()}`
  const invoiceLink = `${APP_URLS.mini_apps}/rcm-invoices/${tenant.subdomain}/invoice/${uuid()}`

  const { copy: copyPaymentLink, didCopy: paymentLinkCopied } = useCopyToClipboard({
    text: paymentLink,
    notification: 'Payment Link copied!',
  })

  const { copy: copyInvoiceLink, didCopy: invoiceLinkCopied } = useCopyToClipboard({
    text: invoiceLink,
    notification: 'Invoice Link copied!',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Invoice Line ID',
        model: 'identifier',
      },
      {
        title: 'Service Name',
        model: 'insurance_claim_service_line.insurance_new_code.service_name',
      },
      {
        title: 'Service Description',
        model: 'insurance_claim_service_line.insurance_new_code.description',
      },
      {
        title: 'Service Start Date',
        model: 'insurance_claim_service_line.service_date_start',
        type: 'date',
        width: 140,
      },
      {
        title: 'Service End Date',
        model: 'insurance_claim_service_line.service_date_end',
        type: 'date',
        width: 140,
      },
      {
        title: 'Procedure Code',
        model: 'insurance_claim_service_line.insurance_new_code.procedure_code',
        width: 120,
      },
      {
        title: 'Billed Amount',
        model: 'balance_billed_amount',
        type: 'amount',
      },
      {
        title: 'Allowed Amount',
        model: 'allowed_amount',
        type: 'amount',
      },
      {
        title: 'Insurance Paid',
        model: 'insurance_paid_amount',
        type: 'amount',
      },
      {
        title: 'Deductible',
        model: 'deductible_amount',
        type: 'amount',
      },
      {
        title: 'Co-Pay',
        model: 'copay_amount',
        type: 'amount',
      },
      {
        title: 'Coinsurance',
        model: 'coinsurance_amount',
        type: 'amount',
      },
      {
        title: 'Adjustment Amount',
        model: 'adjustment_amount',
        type: 'amount',
      },
      {
        title: 'Balance Due Client',
        model: 'balance_client_due_amount',
        type: 'amount',
      },
      {
        title: 'Write-off',
        model: 'write_off',
        type: 'amount',
      },
    ],
    [],
  )

  const importAction = isEditable && (
    <>
      {!!client?.id ? (
        <SummonOverlay
          overlay={
            <PaymentServiceLinesImportOverlay
              onSelect={(selectedClaims: any) => {
                setServiceLines((prevServiceLines: any) => {
                  return [...prevServiceLines, ...selectedClaims]
                })
              }}
              // getImportedData={getData}
            />
          }
        >
          <Button label="Import Claim Service Lines" glyph="add_file" type="primary" size={200} />
        </SummonOverlay>
      ) : (
        <Alert small glyph="info">
          Select a client to import service lines
        </Alert>
      )}
    </>
  )

  return (
    <>
      <Overlay showBackdrop={isEditable} onClose={close} position="right" maxWidth={140}>
        <Overlay.Header
          title="Insurance Invoice"
          icon={FEATURES.invoices.icon}
          subtitle={
            <div className="text-text-muted text-[0.9rem] -mt-1.5">
              <span className="font-[600]">ID: </span> INV00001
            </div>
          }
        />

        <Overlay.Content className="!p-4">
          <Form isCompact getForm={form} isEditable={isEditable} maxWidth="100%" initialModel={!isNew && DATA}>
            <Grid gap="1rem" className="!pb-3">
              <Card className="flex items-center justify-between px-3 py-1">
                <div className="flex items-center">
                  <div className="font-[600] mr-2">Current Status</div>
                  <Status label="Due" color="orange" />
                </div>
                {/* <Button label="Make a Payment" type="primary" glyph="dollar" color="green" size={200} /> */}

                <SummonOverlay
                  overlay={
                    <StripePaymentOverlay
                      stripeConnectID={tenant?.stripe_account_id}
                      passFees={tenant?.financial_prefs?.pass_fees_to_payers}
                      currentBalance={payer?.current_balance}
                      customer={payer?.reference}
                      // onSuccess={onPaymentSuccess}
                      source="EHR"
                    />
                  }
                >
                  <Button
                    label="Make a Payment"
                    type="primary"
                    glyph="dollar"
                    color="green"
                    isLoading={false}
                    size={200}
                    // isDisabled={isEmpty}
                  />
                </SummonOverlay>
              </Card>

              {!isEditable && (
                <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
                  <Card css={{ padding: '1rem', height: '100%' }}>
                    <Grid gap="1rem">
                      <Flex gap="1rem" justifyContent="space-between">
                        <h3>
                          <Flex gap="0.5rem" alignItems="center">
                            <Glyph glyph="cash_hand" />
                            <span>Online Payments</span>
                          </Flex>
                        </h3>
                      </Flex>

                      <span>
                        Use the <strong>Online Payments</strong> to securely collect Payments <strong>without sharing</strong> any Invoice
                        details.
                      </span>

                      <Flex gap="0.5rem">
                        <Button
                          glyph={paymentLinkCopied ? 'check' : 'copy'}
                          label={paymentLinkCopied ? 'Link Copied!' : 'Copy Payments Link'}
                          onClick={copyPaymentLink}
                          size={200}
                        />

                        <Button useGlyphForTarget type="link" label="Open in New Tab" target="_blank" size={200} href={paymentLink} />
                      </Flex>
                    </Grid>
                  </Card>

                  <Card css={{ padding: '1rem', height: '100%' }}>
                    <Grid gap="1rem">
                      <Flex gap="1rem" justifyContent="space-between">
                        <h3>
                          <Flex gap="0.5rem" alignItems="center">
                            <Glyph glyph="ledger" />
                            <span>Full Online Invoice</span>
                          </Flex>
                        </h3>

                        {/* <AccessListTooltip client={client} accessList={accessList} /> */}
                      </Flex>

                      <span>
                        Use the <strong>Full Online Invoice</strong> to securely <strong>share</strong> some of the invoice details and
                        collect Payments.
                      </span>

                      <Flex gap="0.5rem">
                        <Button
                          glyph={invoiceLinkCopied ? 'check' : 'copy'}
                          label={invoiceLinkCopied ? 'Link Copied!' : 'Copy Payments Link'}
                          onClick={copyInvoiceLink}
                          size={200}
                        />

                        <Button useGlyphForTarget type="link" label="Open in New Tab" target="_blank" size={200} href={invoiceLink} />
                      </Flex>
                    </Grid>
                  </Card>
                </div>
              )}

              <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2 mq1024:grid-cols-3">
                <SectionCard title="Invoice Details">
                  <FormSection maxWidth="100%">
                    <Flex gap="1rem">
                      <Input label="Invoice Number" model="invoice_number" />
                      <DateInput label="Invoice Date" model="invoiced_at" />
                      <DateInput label="Invoice Due Date" model="due_at" />
                    </Flex>

                    <Textarea label="Instructions" model="instructions" />
                    <Textarea label="Notes" model="notes" />
                    <Input label="Sales Tax" model="tax_percentage" suffix="%" size={4} />
                    <Checkbox defaultChecked label="Sync with QuickBooks" model="sync" />
                  </FormSection>
                </SectionCard>

                <SectionCard title="Bill From (Provider)">
                  <FormSection maxWidth="100%">
                    <OverlaySelector
                      label="Provider"
                      icon="staff_credentials"
                      type="providers"
                      model="provider"
                      tenant={tenant}
                      selectTitle={(data: any) => data?.credential}
                      onUpdate={({ value }) => {
                        setProvider(value)
                      }}
                    />

                    <DataList isCompact layout="horizontal" labelWidth={120}>
                      <DataList.Item label="Name" value={provider?.credential} />
                      <DataList.Item label="Email" value={provider?.email} />
                      <DataList.Item label="Phone" value={provider?.phone_no} />
                      <DataList.Item
                        label="Address"
                        value={
                          size(provider?.address) > 0 && (
                            <div>
                              <div>{provider.address.address_line_1}</div>
                              <div>
                                {compact([
                                  provider.address.address_line_2,
                                  provider.address.city,
                                  provider.address.state,
                                  provider.address.zip_code,
                                ]).join(', ')}
                              </div>
                            </div>
                          )
                        }
                      />
                    </DataList>
                  </FormSection>
                </SectionCard>

                <SectionCard title="Bill To (Client/Contact)">
                  <FormSection maxWidth="100%">
                    <RadioGroup
                      label="Bill To"
                      value={billToReference}
                      defaultValue="client"
                      layout="horizontal-dense"
                      onUpdate={({ value }: any) => setBillToReference(value)}
                    >
                      <Radio defaultChecked label="Client" icon="clients" value="client" />
                      <Radio label="Contact" icon="contacts" value="contact" />
                    </RadioGroup>

                    {isEditable && (
                      <OverlaySelector
                        label="Client"
                        icon="clients"
                        type="clients.all"
                        value={client}
                        onUpdate={({ value }) => {
                          setClient(value)
                        }}
                      />
                    )}

                    <DataList isCompact layout="horizontal" labelWidth={140}>
                      <DataList.Item label="Full Name" value={client?.name} />
                      <DataList.Item label="Email" value={client?.email} />
                      <DataList.Item label="Phone" value={client?.phone_no} />
                      <DataList.Item
                        label="Address"
                        value={
                          size(client?.address) > 0 && (
                            <div>
                              <div>{client.address.address_line_1}</div>
                              <div>
                                {compact([
                                  client.address.address_line_2,
                                  client.address.city,
                                  client.address.state,
                                  client.address.zip_code,
                                ]).join(', ')}
                              </div>
                            </div>
                          )
                        }
                      />
                      {/* <DataList.Item
                      label="Insurance Provider"
                      value={
                        !!client?.id && (
                          <div className="grid gap-2">
                            {DATA.insurance_policies.map((policy: any, index) => {
                              if (index > 0) return null

                              return (
                                <div key={policy.id} className="flex items-center flex-nowrap">
                                  <Icon icon="sell" size={16} className="mr-1.5" />

                                  <div className="mr-1.5">
                                    <span className="font-[600]">{policy.insurance_local_payer?.name}</span>
                                  </div>

                                  

                                  <Glyph glyph="info" size={12} className="ml-1.5" />
                                </div>
                              )
                            })}
                          </div>
                        )
                      }
                    /> */}
                    </DataList>

                    {billToReference === 'contact' && (
                      <>
                        {isEditable && (
                          <ObjectSelector
                            key={client?.id}
                            label="Contact"
                            icon="contacts"
                            type="client.contacts"
                            model="contact"
                            dependentValue={client?.id}
                            isDisabled={!client?.id}
                            selectTitle={(data: any) => data.name}
                            onUpdate={({ value }) => {
                              setContact(value)
                            }}
                          />
                        )}

                        <DataList isCompact layout="horizontal" labelWidth={120}>
                          <DataList.Item label="Full Name" value={contact?.name} />
                          <DataList.Item label="Relationship" value={contact?.relationship} />
                          <DataList.Item label="Email" value={contact?.email} />
                          <DataList.Item label="Phone" value={contact?.phone_no} />
                          <DataList.Item
                            label="Address"
                            value={
                              size(contact?.address) > 0 && (
                                <div>
                                  <div>{contact.address.address_line_1}</div>
                                  <div>
                                    {compact([
                                      contact.address.address_line_2,
                                      contact.address.city,
                                      contact.address.state,
                                      contact.address.zip_code,
                                    ]).join(', ')}
                                  </div>
                                </div>
                              )
                            }
                          />
                        </DataList>
                      </>
                    )}

                    <ObjectSelector
                      label="Insurance Provider"
                      icon="bank_building"
                      type="insurance_local_payers"
                      selectTitle={(o) => o.name}
                      onUpdate={({ value }) => {
                        setPayer(value)
                      }}
                    />

                    <DataList isCompact>
                      <DataList.Item label="Payer Name" value={payer?.name} />
                      <DataList.Item label="Network Status" value={titleCase(payer?.network_status)} />
                    </DataList>

                    {client?.id && (
                      <>
                        <Checkbox label="Use Automatic Payment Collection" model="should_auto_charge" />

                        <ContextShow when="should_auto_charge" is={true}>
                          <ObjectSelector
                            icon="billing"
                            type="client.payment_methods"
                            label="Payment Method to Charge"
                            model="payment_method"
                            dependentValue={client.id}
                            selectTitle={(data: any) => data?.name}
                            selectDescription={(data: any) => data?.reference?.name}
                            validations={{
                              presence: {
                                message: 'Please select a Payment Method to use',
                              },
                            }}
                          />
                        </ContextShow>
                      </>
                    )}
                  </FormSection>
                </SectionCard>
              </div>

              {!isEditable && !isServiceLinesEmpty && (
                <PageSection className="mt-4">
                  <PageSection.Header>
                    <PageSection.Title title="Invoice Summary" />
                  </PageSection.Header>

                  <PageSection.Content>
                    {!isEditable && (
                      <div>
                        <Flex gap="1rem" stretchChildrenX>
                          <FinancialCard title="Current Balance" value={500} valueColor="red" />
                          <FinancialCard title="Total Charges" value={1500} valueColor="red" prefix="-" />
                          <FinancialCard title="Total Successful Payments" value={1000} valueColor="green" prefix="+" />
                          <FinancialCard title="Total Write-Off's" value={50} valueColor="paleGreen" prefix="+" />
                        </Flex>
                      </div>
                    )}

                    <Card className="px-3 py-2 mt-4">
                      <div className="grid grid-cols-[320px_1fr_200px] py-2 border-b !last:border-none border-solid border-0 border-divider text-[0.84rem] tracking-[1px] uppercase text-text-muted font-[700] opacity-[0.85]">
                        <div>Service Name</div>
                        <div>Service Description</div>
                        <div>Balance Due Client</div>
                      </div>

                      {serviceLines.map((o) => (
                        <div className="grid grid-cols-[320px_1fr_200px] py-1.5 border-b !last:border-none border-solid border-0 border-divider">
                          <div className="font-[600]">{o.insurance_claim_service_line?.insurance_new_code?.service_name}</div>
                          <div className="font-[400]">{o.insurance_claim_service_line?.insurance_new_code?.description}</div>
                          <div>{niceAmount(o.balance_client_due_amount)}</div>
                        </div>
                      ))}

                      <div className="grid grid-cols-[1fr_200px] py-2 font-[600]">
                        <div className="flex items-center justify-end pr-4">Subtotal:</div>
                        <div>{niceAmount(1500)}</div>
                      </div>

                      <div className="grid grid-cols-[1fr_200px] py-2 font-[600]">
                        <div className="flex items-center justify-end pr-4">Sales Tax:</div>
                        <div>10%</div>
                      </div>

                      <div className="grid grid-cols-[1fr_200px] py-2 font-[600]">
                        <div className="flex items-center justify-end pr-4">Total Tax:</div>
                        <div>{niceAmount(150)}</div>
                      </div>

                      <div className="grid grid-cols-[1fr_200px] py-2 font-[600]">
                        <div className="flex items-center justify-end pr-4 font-[700]">TOTAL:</div>
                        <div>{niceAmount(1650)}</div>
                      </div>
                    </Card>
                  </PageSection.Content>
                </PageSection>
              )}

              <PageSection className="mt-4">
                <PageSection.Header>
                  <PageSection.Title title="Invoice Lines" />
                </PageSection.Header>

                <PageSection.Content>
                  <div className="grid grid-cols-[100%] grid-rows-[100%]">
                    {isServiceLinesEmpty ? (
                      <Card>
                        <State
                          isEmpty
                          key="empty-state"
                          title="Invoice Lines"
                          icon="online_card_payment"
                          emptyDescription="No invoice lines created yet"
                          emptyActions={importAction}
                        />
                      </Card>
                    ) : (
                      <DataTable
                        asCard
                        canBatchSelect
                        key={Object.keys(serviceLines).join('-')}
                        headerAfter={<div className="ml-auto">{importAction}</div>}
                        data={serviceLines}
                        columns={columns}
                        meta={{ count: size(serviceLines) }}
                        title="Invoice Lines"
                        icon="online_card_payment"
                        renderBatchActions={({ selectedRows, selectNone }: any) => {
                          return (
                            <div>
                              <Flex gap="1rem">
                                <Button
                                  label="Add Payment"
                                  glyph="add"
                                  type="primary"
                                  size={100}
                                  link={`${match.url}/payments/new`}
                                  // onClick={selectNone}
                                  isDisabled={size(selectedRows) === 0}
                                />
                                <Button
                                  label="Add Write-Off"
                                  glyph="add"
                                  type="minimal"
                                  size={100}
                                  // onClick={selectNone}
                                  link={`${match.url}/write-offs/new`}
                                  isDisabled={size(selectedRows) === 0}
                                />
                              </Flex>
                            </div>
                          )
                        }}
                      />
                    )}
                  </div>
                </PageSection.Content>
              </PageSection>

              <PageSection className="mt-4">
                <PageSection.Header
                  after={<Button label="Add Payment" glyph="add" type="primary" size={200} link={`${match.url}/payments/new`} />}
                >
                  <PageSection.Title title="Payments" />
                </PageSection.Header>

                <PageSection.Content>
                  <InvoicePaymentsDataTable data={PAYMENTS} to={(rowData: any) => `${match.url}/payments/${rowData.id}`} />
                </PageSection.Content>
              </PageSection>

              <PageSection className="mt-4">
                <PageSection.Header
                  after={<Button label="Add Write-Off" glyph="add" type="primary" size={200} link={`${match.url}/write-offs/new`} />}
                >
                  <PageSection.Title title="Write-Off's" />
                </PageSection.Header>

                <PageSection.Content>
                  <InvoiceWriteOffsDataTable data={WRITE_OFFS} to={(rowData: any) => `${match.url}/write-offs/${rowData.id}`} />
                </PageSection.Content>
              </PageSection>

              <PageSection className="mt-4">
                <PageSection.Header>
                  <PageSection.Title title="Updates" />
                </PageSection.Header>

                <PageSection.Content>
                  <Card>
                    <State isEmpty title="Updates" icon="online_card_payment" emptyDescription="No updates to display" />
                  </Card>
                </PageSection.Content>
              </PageSection>
            </Grid>
          </Form>
        </Overlay.Content>

        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save Invoice"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={() => {
                  console.log(form.current.getFormValue())
                  cancel()
                }}
                // isDisabled={isInvalid}
                flex="100 1 auto"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="insurance_invoicing.edit"
              />

              <DeleteDialog
                title="Delete Invoice?"
                message="Are you sure you want to delete this Invoice? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="insurance_invoicing.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      </Overlay>

      <Portal type="z90">
        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <Route path={`${match.url}/payments/:payment_id`}>
            {({ match: routeMatch }) => (
              <InvoicePaymentOverlay
                dataID={routeMatch?.params.payment_id}
                initialData={PAYMENTS.find((o) => o.id === routeMatch?.params.payment_id)}
                client={DATA.client}
              />
            )}
          </Route>

          <Route path={`${match.url}/write-offs/:write_off_id`}>
            {({ match: routeMatch }) => (
              <InvoiceWriteOffOverlay
                dataID={routeMatch?.params.write_off_id}
                initialData={WRITE_OFFS.find((o) => o.id === routeMatch?.params.write_off_id)}
                client={DATA.client}
              />
            )}
          </Route>
        </AnimatedSwitch>
      </Portal>
    </>
  )
}

export const InvoiceOverlay = withOverlayError(RootInvoiceOverlay)

const DATA = {
  client: {
    id: 'vNwNjp2',
    name: 'Abbigail Villarreal',
    avatar: '',
    address: {
      address_line_1: '32 Main Street',
      address_line_2: '',
      city: 'East Hampton',
      state: 'NY',
      zip_code: '11930',
      country: 'USA',
    },
  },
  provider: {
    id: 'X6Xqga',
    address: {
      city: 'West Harwich',
      state: 'MA',
      country: 'USA',
      zip_code: '02671',
      address_line_1: '32 Main Street',
      address_line_2: '',
    },
    category: 'npi',
    certification: null,
    continued_education_required: false,
    created_at: '2023-11-20T04:30:56-05:00',
    credential: 'Behave Health',
    current_credits: null,
    employee_identifier: null,
    expires_at: null,
    first_name: null,
    graduation_year: null,
    issued_at: '2023-11-23T00:00:00-05:00',
    last_name: null,
    level: 'organization',
    license_number: '123',
    notes: '',
    organization: 'Org Test',
    phone_no: '1231231231',
    provider_type: null,
    required_annual_credits: null,
    status: null,
    tax_identifier: '123',
    type: 'credential',
    updated_at: '2023-11-20T04:31:03-05:00',
  },
  insurance_policies: [
    {
      id: 'RPL0PD',
      type: 'insurance_policy',
      updated_at: '2023-11-22T11:25:08.527-05:00',
      created_at: '2023-11-22T11:25:08.527-05:00',
      status: 'active',
      category: 'primary',
      is_client_policy_holder: true,
      policy_holder: {
        city: 'dsa',
        state: 'AZ',
        zip_code: '123',
        address_line_1: 'asd',
        address_line_2: 'dsa',
      },
      payer_source: 'clearing_house',
      member_id: '12312312321',
      group_id: '',
      group_name: null,
      plan: '',
      plan_type: null,
      card_issued_at: null,
      check_summary: null,
      claims_phone_no: '',
      notes: '',
      resets_at: null,
      effective_at: null,
      terminates_at: null,
      clearing_house: null,
      insurance_name: 'Test',
      insurance_local_payer: {
        id: 'RPL0PD',
        type: 'insurance_local_payer',
        updated_at: '2023-11-19T09:15:21.860-05:00',
        created_at: '2023-11-19T09:15:21.860-05:00',
        name: 'Test',
        payer_source: 'clearing_house',
        opening_times: null,
        website: null,
        network_status: 'out_of_network',
        enrolled_status: 'not_enrolled',
        supports: null,
        address: {},
        tags: [],
        insurance_clearing_house_config: null,
      },
      tags: [],
    },
    {
      id: 'X6Xqga',
      type: 'insurance_policy',
      updated_at: '2023-11-22T11:26:20.169-05:00',
      created_at: '2023-11-22T11:26:14.769-05:00',
      status: 'active',
      category: 'secondary',
      is_client_policy_holder: true,
      policy_holder: {
        city: 'dsa',
        state: 'AK',
        zip_code: 'asd',
        address_line_1: 'asd',
        address_line_2: 'asd',
      },
      payer_source: 'clearing_house',
      member_id: 'Member ID',
      group_id: '',
      group_name: null,
      plan: '',
      plan_type: null,
      card_issued_at: null,
      check_summary: null,
      claims_phone_no: '',
      notes: '',
      resets_at: null,
      effective_at: null,
      terminates_at: null,
      clearing_house: null,
      insurance_name: 'Test',
      insurance_local_payer: {
        id: 'RPL0PD',
        type: 'insurance_local_payer',
        updated_at: '2023-11-19T09:15:21.860-05:00',
        created_at: '2023-11-19T09:15:21.860-05:00',
        name: 'Test',
        payer_source: 'clearing_house',
        opening_times: null,
        website: null,
        network_status: 'out_of_network',
        enrolled_status: 'not_enrolled',
        supports: null,
        address: {},
        tags: [],
        insurance_clearing_house_config: null,
      },
      tags: [],
    },
  ],
  invoice_lines: [
    {
      id: 'dgyN9M',
      adjustment_amount: '0.0',
      adjustment_group_code: '',
      adjustment_reason_code: '',
      allowed_amount: '0.0',
      author: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      balance_billed_amount: '0.0',
      balance_client_due_amount: '100.0',
      balance_due_status: null,
      coinsurance_amount: '0.0',
      copay_amount: '0.0',
      created_at: '2023-11-24T08:07:41-05:00',
      deductible_amount: '0.0',
      identifier: 'BH_INV_LN_000001',
      insurance_claim: {
        id: 'Oglw6y',
        admission_source_code: null,
        admission_type_code: null,
        admitted_at: null,
        admitting_diagnosis: {
          id: 'WoJokOq',
          category: 'primary',
          code: 'F10.23',
          created_at: '2023-11-15T03:58:01-05:00',
          description: 'Alcohol dependence with withdrawal',
          from: null,
          notes:
            'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
          source: 'internal',
          status: 'active',
          type: 'diagnosis',
          until: null,
          updated_at: '2023-11-17T13:00:26-05:00',
        },
        assignee: null,
        attending_provider: null,
        author: {
          id: 'wgWOgG',
          avatar: null,
          created_at: '2023-11-15T03:32:00-05:00',
          email: 'robert.eles@behavehealth.com',
          first_name: 'Robert',
          has_accounting_access: false,
          is_pin_set: false,
          is_shared_with_portal: true,
          last_name: 'Eles',
          middle_name: null,
          name: 'Robert Eles',
          phone_no: null,
          phone_no_ext: null,
          position: 'dev',
          signature:
            'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
          type: 'bh_employee',
          updated_at: '2023-11-15T11:16:23-05:00',
        },
        bill_type: null,
        billing_note: null,
        billing_provider: {
          id: 'X6Xqga',
          address: {
            city: 'West Harwich',
            state: 'MA',
            country: 'USA',
            zip_code: '02671',
            address_line_1: '32 Main Street',
            address_line_2: '',
          },
          category: 'npi',
          certification: null,
          continued_education_required: false,
          created_at: '2023-11-20T04:30:56-05:00',
          credential: 'Test',
          current_credits: null,
          employee_identifier: null,
          expires_at: null,
          first_name: null,
          graduation_year: null,
          issued_at: '2023-11-23T00:00:00-05:00',
          last_name: null,
          level: 'organization',
          license_number: '123',
          notes: '',
          organization: 'Org Test',
          phone_no: '1231231231',
          provider_type: null,
          required_annual_credits: null,
          status: null,
          tax_identifier: '123',
          type: 'credential',
          updated_at: '2023-11-20T04:31:03-05:00',
        },
        category: 'professional',
        claim_filing_code: 'CI',
        claim_frequency_code: '1',
        condition_codes: [],
        control_number: '9U5C9YE64',
        covering_from: '2023-11-19T09:13:22-05:00',
        covering_until: '2023-11-19T10:08:41-05:00',
        created_at: '2023-11-24T08:03:57-05:00',
        discharged_at: null,
        flagged_status: null,
        from_date: '2023-11-19T09:13:22-05:00',
        identifier: 'BH_CLM_000004',
        initial_submission_at: null,
        insurance_authorization: null,
        insurance_claim_service_lines: [
          {
            id: 'dgyN9M',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000007',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'Routine blood testing for general health screening',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'Basic Bloodwork',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '100.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:35:22-05:00',
            service_date_start: '2023-11-19T09:13:22-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: 'NP2pgR',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000008',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'Standard physical therapy treatment',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'Physical Therapy Session',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '200.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:46:28-05:00',
            service_date_start: '2023-11-19T09:13:28-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: '1goEPw',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000009',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'Internal imaging using ultrasound technology',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'Ultrasound Diagnostic',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '300.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:57:35-05:00',
            service_date_start: '2023-11-19T09:13:35-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: 'bPxBg0',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000010',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'Administration of a specified vaccine',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'Immunization Shot',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '400.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T10:08:41-05:00',
            service_date_start: '2023-11-19T09:13:41-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
        ],
        insurance_fee_schedule: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_new_fee_schedule: {
          id: 'RPL0PD',
          archived_at: null,
          created_at: '2023-11-19T09:14:10-05:00',
          employees: [],
          houses: [],
          insurance_local_payers: [],
          insurance_new_fee_schedule_services: [
            {
              id: 'RPL0PD',
              created_at: '2023-11-20T11:13:35-05:00',
              insurance_new_code: {
                id: 'RPL0PD',
                code_type: 'cpt',
                created_at: '2023-11-20T11:12:50-05:00',
                description: 'test 213',
                modifier_codes: [],
                notes: '',
                procedure_code: 'aa',
                revenue_code: '',
                service_name: 'A',
                type: 'insurance_new_code',
                unit: 'per_unit',
                updated_at: '2023-11-20T11:12:50-05:00',
              },
              price: '123.0',
              type: 'insurance_new_fee_schedule_service',
              updated_at: '2023-11-20T11:13:35-05:00',
            },
          ],
          name: 'aaa',
          status: 'active',
          type: 'insurance_new_fee_schedule',
          updated_at: '2023-11-20T11:13:35-05:00',
        },
        last_claim_note: null,
        last_insurance_claim_status: null,
        last_submission_at: null,
        ordering_provider: null,
        original_claim_no: '',
        patient_status_code: null,
        place_of_service_code: '57',
        primary_insurance: {
          id: 'RPL0PD',
          archived_at: null,
          card_issued_at: null,
          category: 'primary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:25:08-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: '12312312321',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AZ',
            zip_code: '123',
            address_line_1: 'asd',
            address_line_2: 'dsa',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:25:08-05:00',
        },
        principal_diagnosis: {
          id: 'WoJokOq',
          category: 'primary',
          code: 'F10.23',
          created_at: '2023-11-15T03:58:01-05:00',
          description: 'Alcohol dependence with withdrawal',
          from: null,
          notes:
            'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
          source: 'internal',
          status: 'active',
          type: 'diagnosis',
          until: null,
          updated_at: '2023-11-17T13:00:26-05:00',
        },
        referring_provider: null,
        rendering_provider: null,
        resident: {
          id: 'vNwNjp2',
          admitted_at: '2023-10-31T04:34:42-04:00',
          avatar: null,
          behave_id: 'ABVI000001',
          behave_identifier: '4P1531SEOM1D',
          care_level: 'sober_living',
          client_portal_status: null,
          color_code: null,
          created_at: '2023-11-15T03:34:42-05:00',
          dob: '2003-11-18T19:00:00-05:00',
          email: 'abbigail.villarreal@getnada.com',
          encrypted_data: {
            id: 'VoJo107',
            address: {
              address_line_1: '32 Main Street',
              address_line_2: '',
              city: 'East Hampton',
              state: 'NY',
              zip_code: '11930',
              country: 'USA',
            },
            created_at: '2023-11-15T03:34:42-05:00',
            notes: null,
            secure_pin: null,
            ssn: null,
            treatment_address: null,
            type: 'encrypted_data',
            updated_at: '2023-11-22T11:27:15-05:00',
          },
          external_id: '4444f162-8390-46e7-8e63-d6bfa9b63b65',
          financial_notes: null,
          first_name: 'Abbigail',
          gender_identity: null,
          gender_identity_other: null,
          has_portal_access: true,
          high_risk_notes: null,
          high_risk_title: null,
          invited_to_portal_at: '2023-11-15T03:34:42-05:00',
          is_billed_by_behave: true,
          is_insurance_auth_required: false,
          is_insurance_billable: true,
          is_insured: null,
          is_the_policy_holder: null,
          last_logged_in_at: null,
          last_name: 'Villarreal',
          level_of_care: null,
          middle_name: '',
          name: 'Abbigail Villarreal',
          notes: null,
          phone_no: '461-306-0872',
          preferred_pronouns: null,
          preferred_pronouns_other: null,
          priority: null,
          referral_notes: null,
          referral_out_notes: null,
          sex: 'female',
          sex_other: null,
          signature: null,
          status: 'current',
          sync_with_accounting: null,
          tags: [
            {
              id: 'RPL0PD',
              color: '#f44336',
              created_at: '2023-11-15T03:34:26-05:00',
              description:
                'This tag indicates that the referral came from a close family member or friend of the potential resident, who is seeking help on their behalf.',
              glyph: 'cross',
              name: 'Family or Friend',
              type: 'tag',
              updated_at: '2023-11-15T03:34:26-05:00',
            },
            {
              id: 'Oglw6y',
              color: '#274879',
              created_at: '2023-11-15T03:34:26-05:00',
              description:
                'This tag is for residents experiencing mental health issues, such as depression, anxiety, or other psychological conditions, and who require therapeutic interventions and support.',
              glyph: 'current_risk',
              name: 'Mental Health',
              type: 'tag',
              updated_at: '2023-11-15T03:34:26-05:00',
            },
          ],
          type: 'resident',
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        response: {},
        secondary_insurance: {
          id: 'X6Xqga',
          archived_at: null,
          card_issued_at: null,
          category: 'secondary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:26:14-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: 'Member ID',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AK',
            zip_code: 'asd',
            address_line_1: 'asd',
            address_line_2: 'asd',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:26:20-05:00',
        },
        service_lines: [],
        state: 'state_draft',
        status: 'draft',
        supervising_provider: null,
        tertiary_insurance: {
          id: 'X6Xqga',
          archived_at: null,
          card_issued_at: null,
          category: 'secondary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:26:14-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: 'Member ID',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AK',
            zip_code: 'asd',
            address_line_1: 'asd',
            address_line_2: 'asd',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:26:20-05:00',
        },
        to_date: '2023-11-19T10:08:41-05:00',
        total_amount: '492.0',
        tracker_status: null,
        treatment_episode: {
          id: 'vNwNjp2',
          accepted_at: null,
          admitted_at: '2023-10-31T04:34:42-04:00',
          created_at: '2023-11-15T03:34:42-05:00',
          decline_reason: null,
          declined_at: null,
          discharge_notes: null,
          discharge_type: null,
          discharged_at: '2023-12-13T03:34:42-05:00',
          estimated_arrival: null,
          estimated_discharge: null,
          incoming_notes: null,
          levels_of_care: [],
          no_show_notes: null,
          notes: null,
          other_discharge_type: null,
          reserved_at: null,
          return_eligibility: null,
          status: 'admitted',
          tags: [],
          title: null,
          type: 'admission',
          updated_at: '2023-11-21T09:59:12-05:00',
          waitlist_notes: null,
        },
        type: 'insurance_claim',
        type_of_service_code: null,
        updated_at: '2023-11-24T08:07:41-05:00',
        validation: {},
        value_codes: [],
        version: 'v2',
        warnings: {},
      },
      insurance_claim_service_line: {
        id: 'dgyN9M',
        created_at: '2023-11-24T08:03:57-05:00',
        diagnoses: [],
        diagnoses_ids: [],
        identifier: 'BH_CLM_SVC_000007',
        insurance_claim: {
          status: 'draft',
        },
        insurance_new_code: {
          id: 'RPL0PD',
          code_type: 'cpt',
          created_at: '2023-11-20T11:12:50-05:00',
          description: 'Routine blood testing for general health screening',
          modifier_codes: [],
          notes: '',
          procedure_code: 'BB101',
          revenue_code: '',
          service_name: 'Basic Bloodwork',
          type: 'insurance_new_code',
          unit: 'per_unit',
          updated_at: '2023-11-20T11:12:50-05:00',
        },
        insurance_new_payment_service_line: {
          identifier: 'BH_PAY_000004',
          allowed_amount: '0.0',
          insurance_paid_amount: '0.0',
          deductible_amount: '0.0',
          copay_amount: '0.0',
          coinsurance_amount: '0.0',
          adjustment_group_code: '',
          adjustment_reason_code: '',
          adjustment_amount: '0.0',
          balance_client_due_amount: '100.0',
          balance_billed_amount: '0.0',
        },
        modifier_codes: [],
        revenue_code: null,
        service_date_end: '2023-11-19T09:35:22-05:00',
        service_date_start: '2023-11-19T09:13:22-05:00',
        status: 'balance_due_patient',
        total_amount: '123.0',
        type: 'insurance_claim_service_line',
        unit_price: '123.0',
        unit_type: 'UN',
        units: 1,
        updated_at: '2023-11-24T08:07:41-05:00',
      },
      insurance_paid_amount: '0.0',
      last_edited_by: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      type: 'insurance_new_payment_service_line',
      updated_at: '2023-11-24T08:07:41-05:00',
    },
    {
      id: 'NP2pgR',
      adjustment_amount: '0.0',
      adjustment_group_code: '',
      adjustment_reason_code: '',
      allowed_amount: '0.0',
      author: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      balance_billed_amount: '0.0',
      balance_client_due_amount: '200.0',
      balance_due_status: null,
      coinsurance_amount: '0.0',
      copay_amount: '0.0',
      created_at: '2023-11-24T08:07:41-05:00',
      deductible_amount: '0.0',
      identifier: 'BH_INV_LN_000002',
      insurance_claim: {
        id: 'Oglw6y',
        admission_source_code: null,
        admission_type_code: null,
        admitted_at: null,
        admitting_diagnosis: {
          id: 'WoJokOq',
          category: 'primary',
          code: 'F10.23',
          created_at: '2023-11-15T03:58:01-05:00',
          description: 'Alcohol dependence with withdrawal',
          from: null,
          notes:
            'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
          source: 'internal',
          status: 'active',
          type: 'diagnosis',
          until: null,
          updated_at: '2023-11-17T13:00:26-05:00',
        },
        assignee: null,
        attending_provider: null,
        author: {
          id: 'wgWOgG',
          avatar: null,
          created_at: '2023-11-15T03:32:00-05:00',
          email: 'robert.eles@behavehealth.com',
          first_name: 'Robert',
          has_accounting_access: false,
          is_pin_set: false,
          is_shared_with_portal: true,
          last_name: 'Eles',
          middle_name: null,
          name: 'Robert Eles',
          phone_no: null,
          phone_no_ext: null,
          position: 'dev',
          signature:
            'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
          type: 'bh_employee',
          updated_at: '2023-11-15T11:16:23-05:00',
        },
        bill_type: null,
        billing_note: null,
        billing_provider: {
          id: 'X6Xqga',
          address: {
            city: 'West Harwich',
            state: 'MA',
            country: 'USA',
            zip_code: '02671',
            address_line_1: '32 Main Street',
            address_line_2: '',
          },
          category: 'npi',
          certification: null,
          continued_education_required: false,
          created_at: '2023-11-20T04:30:56-05:00',
          credential: 'Test',
          current_credits: null,
          employee_identifier: null,
          expires_at: null,
          first_name: null,
          graduation_year: null,
          issued_at: '2023-11-23T00:00:00-05:00',
          last_name: null,
          level: 'organization',
          license_number: '123',
          notes: '',
          organization: 'Org Test',
          phone_no: '1231231231',
          provider_type: null,
          required_annual_credits: null,
          status: null,
          tax_identifier: '123',
          type: 'credential',
          updated_at: '2023-11-20T04:31:03-05:00',
        },
        category: 'professional',
        claim_filing_code: 'CI',
        claim_frequency_code: '1',
        condition_codes: [],
        control_number: '9U5C9YE64',
        covering_from: '2023-11-19T09:13:22-05:00',
        covering_until: '2023-11-19T10:08:41-05:00',
        created_at: '2023-11-24T08:03:57-05:00',
        discharged_at: null,
        flagged_status: null,
        from_date: '2023-11-19T09:13:22-05:00',
        identifier: 'BH_CLM_000004',
        initial_submission_at: null,
        insurance_authorization: null,
        insurance_claim_service_lines: [
          {
            id: 'dgyN9M',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000007',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '100.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:35:22-05:00',
            service_date_start: '2023-11-19T09:13:22-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: 'NP2pgR',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000008',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '200.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:46:28-05:00',
            service_date_start: '2023-11-19T09:13:28-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: '1goEPw',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000009',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '300.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:57:35-05:00',
            service_date_start: '2023-11-19T09:13:35-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: 'bPxBg0',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000010',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '400.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T10:08:41-05:00',
            service_date_start: '2023-11-19T09:13:41-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
        ],
        insurance_fee_schedule: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_new_fee_schedule: {
          id: 'RPL0PD',
          archived_at: null,
          created_at: '2023-11-19T09:14:10-05:00',
          employees: [],
          houses: [],
          insurance_local_payers: [],
          insurance_new_fee_schedule_services: [
            {
              id: 'RPL0PD',
              created_at: '2023-11-20T11:13:35-05:00',
              insurance_new_code: {
                id: 'RPL0PD',
                code_type: 'cpt',
                created_at: '2023-11-20T11:12:50-05:00',
                description: 'test 213',
                modifier_codes: [],
                notes: '',
                procedure_code: 'aa',
                revenue_code: '',
                service_name: 'A',
                type: 'insurance_new_code',
                unit: 'per_unit',
                updated_at: '2023-11-20T11:12:50-05:00',
              },
              price: '123.0',
              type: 'insurance_new_fee_schedule_service',
              updated_at: '2023-11-20T11:13:35-05:00',
            },
          ],
          name: 'aaa',
          status: 'active',
          type: 'insurance_new_fee_schedule',
          updated_at: '2023-11-20T11:13:35-05:00',
        },
        last_claim_note: null,
        last_insurance_claim_status: null,
        last_submission_at: null,
        ordering_provider: null,
        original_claim_no: '',
        patient_status_code: null,
        place_of_service_code: '57',
        primary_insurance: {
          id: 'RPL0PD',
          archived_at: null,
          card_issued_at: null,
          category: 'primary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:25:08-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: '12312312321',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AZ',
            zip_code: '123',
            address_line_1: 'asd',
            address_line_2: 'dsa',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:25:08-05:00',
        },
        principal_diagnosis: {
          id: 'WoJokOq',
          category: 'primary',
          code: 'F10.23',
          created_at: '2023-11-15T03:58:01-05:00',
          description: 'Alcohol dependence with withdrawal',
          from: null,
          notes:
            'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
          source: 'internal',
          status: 'active',
          type: 'diagnosis',
          until: null,
          updated_at: '2023-11-17T13:00:26-05:00',
        },
        referring_provider: null,
        rendering_provider: null,
        resident: {
          id: 'vNwNjp2',
          admitted_at: '2023-10-31T04:34:42-04:00',
          avatar: null,
          behave_id: 'ABVI000001',
          behave_identifier: '4P1531SEOM1D',
          care_level: 'sober_living',
          client_portal_status: null,
          color_code: null,
          created_at: '2023-11-15T03:34:42-05:00',
          dob: '2003-11-18T19:00:00-05:00',
          email: 'abbigail.villarreal@getnada.com',
          encrypted_data: {
            id: 'VoJo107',
            address: {
              address_line_1: '32 Main Street',
              address_line_2: '',
              city: 'East Hampton',
              state: 'NY',
              zip_code: '11930',
              country: 'USA',
            },
            created_at: '2023-11-15T03:34:42-05:00',
            notes: null,
            secure_pin: null,
            ssn: null,
            treatment_address: null,
            type: 'encrypted_data',
            updated_at: '2023-11-22T11:27:15-05:00',
          },
          external_id: '4444f162-8390-46e7-8e63-d6bfa9b63b65',
          financial_notes: null,
          first_name: 'Abbigail',
          gender_identity: null,
          gender_identity_other: null,
          has_portal_access: true,
          high_risk_notes: null,
          high_risk_title: null,
          invited_to_portal_at: '2023-11-15T03:34:42-05:00',
          is_billed_by_behave: true,
          is_insurance_auth_required: false,
          is_insurance_billable: true,
          is_insured: null,
          is_the_policy_holder: null,
          last_logged_in_at: null,
          last_name: 'Villarreal',
          level_of_care: null,
          middle_name: '',
          name: 'Abbigail Villarreal',
          notes: null,
          phone_no: '461-306-0872',
          preferred_pronouns: null,
          preferred_pronouns_other: null,
          priority: null,
          referral_notes: null,
          referral_out_notes: null,
          sex: 'female',
          sex_other: null,
          signature: null,
          status: 'current',
          sync_with_accounting: null,
          tags: [
            {
              id: 'RPL0PD',
              color: '#f44336',
              created_at: '2023-11-15T03:34:26-05:00',
              description:
                'This tag indicates that the referral came from a close family member or friend of the potential resident, who is seeking help on their behalf.',
              glyph: 'cross',
              name: 'Family or Friend',
              type: 'tag',
              updated_at: '2023-11-15T03:34:26-05:00',
            },
            {
              id: 'Oglw6y',
              color: '#274879',
              created_at: '2023-11-15T03:34:26-05:00',
              description:
                'This tag is for residents experiencing mental health issues, such as depression, anxiety, or other psychological conditions, and who require therapeutic interventions and support.',
              glyph: 'current_risk',
              name: 'Mental Health',
              type: 'tag',
              updated_at: '2023-11-15T03:34:26-05:00',
            },
          ],
          type: 'resident',
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        response: {},
        secondary_insurance: {
          id: 'X6Xqga',
          archived_at: null,
          card_issued_at: null,
          category: 'secondary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:26:14-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: 'Member ID',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AK',
            zip_code: 'asd',
            address_line_1: 'asd',
            address_line_2: 'asd',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:26:20-05:00',
        },
        service_lines: [],
        state: 'state_draft',
        status: 'draft',
        supervising_provider: null,
        tertiary_insurance: {
          id: 'X6Xqga',
          archived_at: null,
          card_issued_at: null,
          category: 'secondary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:26:14-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: 'Member ID',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AK',
            zip_code: 'asd',
            address_line_1: 'asd',
            address_line_2: 'asd',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:26:20-05:00',
        },
        to_date: '2023-11-19T10:08:41-05:00',
        total_amount: '492.0',
        tracker_status: null,
        treatment_episode: {
          id: 'vNwNjp2',
          accepted_at: null,
          admitted_at: '2023-10-31T04:34:42-04:00',
          created_at: '2023-11-15T03:34:42-05:00',
          decline_reason: null,
          declined_at: null,
          discharge_notes: null,
          discharge_type: null,
          discharged_at: '2023-12-13T03:34:42-05:00',
          estimated_arrival: null,
          estimated_discharge: null,
          incoming_notes: null,
          levels_of_care: [],
          no_show_notes: null,
          notes: null,
          other_discharge_type: null,
          reserved_at: null,
          return_eligibility: null,
          status: 'admitted',
          tags: [],
          title: null,
          type: 'admission',
          updated_at: '2023-11-21T09:59:12-05:00',
          waitlist_notes: null,
        },
        type: 'insurance_claim',
        type_of_service_code: null,
        updated_at: '2023-11-24T08:07:41-05:00',
        validation: {},
        value_codes: [],
        version: 'v2',
        warnings: {},
      },
      insurance_claim_service_line: {
        id: 'NP2pgR',
        created_at: '2023-11-24T08:03:57-05:00',
        diagnoses: [],
        diagnoses_ids: [],
        identifier: 'BH_CLM_SVC_000008',
        insurance_claim: {
          status: 'draft',
        },
        insurance_new_code: {
          id: 'RPL0PD',
          code_type: 'cpt',
          created_at: '2023-11-20T11:12:50-05:00',
          description: 'Standard physical therapy treatment',
          modifier_codes: [],
          notes: '',
          procedure_code: 'PT202',
          revenue_code: '',
          service_name: 'Physical Therapy Session',
          type: 'insurance_new_code',
          unit: 'per_unit',
          updated_at: '2023-11-20T11:12:50-05:00',
        },
        insurance_new_payment_service_line: {
          identifier: 'BH_PAY_000004',
          allowed_amount: '0.0',
          insurance_paid_amount: '0.0',
          deductible_amount: '0.0',
          copay_amount: '0.0',
          coinsurance_amount: '0.0',
          adjustment_group_code: '',
          adjustment_reason_code: '',
          adjustment_amount: '0.0',
          balance_client_due_amount: '200.0',
          balance_billed_amount: '0.0',
        },
        modifier_codes: [],
        revenue_code: null,
        service_date_end: '2023-11-19T09:46:28-05:00',
        service_date_start: '2023-11-19T09:13:28-05:00',
        status: 'balance_due_patient',
        total_amount: '123.0',
        type: 'insurance_claim_service_line',
        unit_price: '123.0',
        unit_type: 'UN',
        units: 1,
        updated_at: '2023-11-24T08:07:41-05:00',
      },
      insurance_paid_amount: '0.0',
      last_edited_by: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      type: 'insurance_new_payment_service_line',
      updated_at: '2023-11-24T08:07:41-05:00',
    },
    {
      id: '1goEPw',
      adjustment_amount: '0.0',
      adjustment_group_code: '',
      adjustment_reason_code: '',
      allowed_amount: '0.0',
      author: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      balance_billed_amount: '0.0',
      balance_client_due_amount: '300.0',
      balance_due_status: null,
      coinsurance_amount: '0.0',
      copay_amount: '0.0',
      created_at: '2023-11-24T08:07:41-05:00',
      deductible_amount: '0.0',
      identifier: 'BH_INV_LN_000003',
      insurance_claim: {
        id: 'Oglw6y',
        admission_source_code: null,
        admission_type_code: null,
        admitted_at: null,
        admitting_diagnosis: {
          id: 'WoJokOq',
          category: 'primary',
          code: 'F10.23',
          created_at: '2023-11-15T03:58:01-05:00',
          description: 'Alcohol dependence with withdrawal',
          from: null,
          notes:
            'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
          source: 'internal',
          status: 'active',
          type: 'diagnosis',
          until: null,
          updated_at: '2023-11-17T13:00:26-05:00',
        },
        assignee: null,
        attending_provider: null,
        author: {
          id: 'wgWOgG',
          avatar: null,
          created_at: '2023-11-15T03:32:00-05:00',
          email: 'robert.eles@behavehealth.com',
          first_name: 'Robert',
          has_accounting_access: false,
          is_pin_set: false,
          is_shared_with_portal: true,
          last_name: 'Eles',
          middle_name: null,
          name: 'Robert Eles',
          phone_no: null,
          phone_no_ext: null,
          position: 'dev',
          signature:
            'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
          type: 'bh_employee',
          updated_at: '2023-11-15T11:16:23-05:00',
        },
        bill_type: null,
        billing_note: null,
        billing_provider: {
          id: 'X6Xqga',
          address: {
            city: 'West Harwich',
            state: 'MA',
            country: 'USA',
            zip_code: '02671',
            address_line_1: '32 Main Street',
            address_line_2: '',
          },
          category: 'npi',
          certification: null,
          continued_education_required: false,
          created_at: '2023-11-20T04:30:56-05:00',
          credential: 'Test',
          current_credits: null,
          employee_identifier: null,
          expires_at: null,
          first_name: null,
          graduation_year: null,
          issued_at: '2023-11-23T00:00:00-05:00',
          last_name: null,
          level: 'organization',
          license_number: '123',
          notes: '',
          organization: 'Org Test',
          phone_no: '1231231231',
          provider_type: null,
          required_annual_credits: null,
          status: null,
          tax_identifier: '123',
          type: 'credential',
          updated_at: '2023-11-20T04:31:03-05:00',
        },
        category: 'professional',
        claim_filing_code: 'CI',
        claim_frequency_code: '1',
        condition_codes: [],
        control_number: '9U5C9YE64',
        covering_from: '2023-11-19T09:13:22-05:00',
        covering_until: '2023-11-19T10:08:41-05:00',
        created_at: '2023-11-24T08:03:57-05:00',
        discharged_at: null,
        flagged_status: null,
        from_date: '2023-11-19T09:13:22-05:00',
        identifier: 'BH_CLM_000004',
        initial_submission_at: null,
        insurance_authorization: null,
        insurance_claim_service_lines: [
          {
            id: 'dgyN9M',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000007',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '100.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:35:22-05:00',
            service_date_start: '2023-11-19T09:13:22-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: 'NP2pgR',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000008',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '200.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:46:28-05:00',
            service_date_start: '2023-11-19T09:13:28-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: '1goEPw',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000009',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '300.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:57:35-05:00',
            service_date_start: '2023-11-19T09:13:35-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: 'bPxBg0',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000010',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '400.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T10:08:41-05:00',
            service_date_start: '2023-11-19T09:13:41-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
        ],
        insurance_fee_schedule: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_new_fee_schedule: {
          id: 'RPL0PD',
          archived_at: null,
          created_at: '2023-11-19T09:14:10-05:00',
          employees: [],
          houses: [],
          insurance_local_payers: [],
          insurance_new_fee_schedule_services: [
            {
              id: 'RPL0PD',
              created_at: '2023-11-20T11:13:35-05:00',
              insurance_new_code: {
                id: 'RPL0PD',
                code_type: 'cpt',
                created_at: '2023-11-20T11:12:50-05:00',
                description: 'test 213',
                modifier_codes: [],
                notes: '',
                procedure_code: 'aa',
                revenue_code: '',
                service_name: 'A',
                type: 'insurance_new_code',
                unit: 'per_unit',
                updated_at: '2023-11-20T11:12:50-05:00',
              },
              price: '123.0',
              type: 'insurance_new_fee_schedule_service',
              updated_at: '2023-11-20T11:13:35-05:00',
            },
          ],
          name: 'aaa',
          status: 'active',
          type: 'insurance_new_fee_schedule',
          updated_at: '2023-11-20T11:13:35-05:00',
        },
        last_claim_note: null,
        last_insurance_claim_status: null,
        last_submission_at: null,
        ordering_provider: null,
        original_claim_no: '',
        patient_status_code: null,
        place_of_service_code: '57',
        primary_insurance: {
          id: 'RPL0PD',
          archived_at: null,
          card_issued_at: null,
          category: 'primary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:25:08-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: '12312312321',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AZ',
            zip_code: '123',
            address_line_1: 'asd',
            address_line_2: 'dsa',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:25:08-05:00',
        },
        principal_diagnosis: {
          id: 'WoJokOq',
          category: 'primary',
          code: 'F10.23',
          created_at: '2023-11-15T03:58:01-05:00',
          description: 'Alcohol dependence with withdrawal',
          from: null,
          notes:
            'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
          source: 'internal',
          status: 'active',
          type: 'diagnosis',
          until: null,
          updated_at: '2023-11-17T13:00:26-05:00',
        },
        referring_provider: null,
        rendering_provider: null,
        resident: {
          id: 'vNwNjp2',
          admitted_at: '2023-10-31T04:34:42-04:00',
          avatar: null,
          behave_id: 'ABVI000001',
          behave_identifier: '4P1531SEOM1D',
          care_level: 'sober_living',
          client_portal_status: null,
          color_code: null,
          created_at: '2023-11-15T03:34:42-05:00',
          dob: '2003-11-18T19:00:00-05:00',
          email: 'abbigail.villarreal@getnada.com',
          encrypted_data: {
            id: 'VoJo107',
            address: {
              address_line_1: '32 Main Street',
              address_line_2: '',
              city: 'East Hampton',
              state: 'NY',
              zip_code: '11930',
              country: 'USA',
            },
            created_at: '2023-11-15T03:34:42-05:00',
            notes: null,
            secure_pin: null,
            ssn: null,
            treatment_address: null,
            type: 'encrypted_data',
            updated_at: '2023-11-22T11:27:15-05:00',
          },
          external_id: '4444f162-8390-46e7-8e63-d6bfa9b63b65',
          financial_notes: null,
          first_name: 'Abbigail',
          gender_identity: null,
          gender_identity_other: null,
          has_portal_access: true,
          high_risk_notes: null,
          high_risk_title: null,
          invited_to_portal_at: '2023-11-15T03:34:42-05:00',
          is_billed_by_behave: true,
          is_insurance_auth_required: false,
          is_insurance_billable: true,
          is_insured: null,
          is_the_policy_holder: null,
          last_logged_in_at: null,
          last_name: 'Villarreal',
          level_of_care: null,
          middle_name: '',
          name: 'Abbigail Villarreal',
          notes: null,
          phone_no: '461-306-0872',
          preferred_pronouns: null,
          preferred_pronouns_other: null,
          priority: null,
          referral_notes: null,
          referral_out_notes: null,
          sex: 'female',
          sex_other: null,
          signature: null,
          status: 'current',
          sync_with_accounting: null,
          tags: [
            {
              id: 'RPL0PD',
              color: '#f44336',
              created_at: '2023-11-15T03:34:26-05:00',
              description:
                'This tag indicates that the referral came from a close family member or friend of the potential resident, who is seeking help on their behalf.',
              glyph: 'cross',
              name: 'Family or Friend',
              type: 'tag',
              updated_at: '2023-11-15T03:34:26-05:00',
            },
            {
              id: 'Oglw6y',
              color: '#274879',
              created_at: '2023-11-15T03:34:26-05:00',
              description:
                'This tag is for residents experiencing mental health issues, such as depression, anxiety, or other psychological conditions, and who require therapeutic interventions and support.',
              glyph: 'current_risk',
              name: 'Mental Health',
              type: 'tag',
              updated_at: '2023-11-15T03:34:26-05:00',
            },
          ],
          type: 'resident',
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        response: {},
        secondary_insurance: {
          id: 'X6Xqga',
          archived_at: null,
          card_issued_at: null,
          category: 'secondary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:26:14-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: 'Member ID',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AK',
            zip_code: 'asd',
            address_line_1: 'asd',
            address_line_2: 'asd',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:26:20-05:00',
        },
        service_lines: [],
        state: 'state_draft',
        status: 'draft',
        supervising_provider: null,
        tertiary_insurance: {
          id: 'X6Xqga',
          archived_at: null,
          card_issued_at: null,
          category: 'secondary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:26:14-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: 'Member ID',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AK',
            zip_code: 'asd',
            address_line_1: 'asd',
            address_line_2: 'asd',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:26:20-05:00',
        },
        to_date: '2023-11-19T10:08:41-05:00',
        total_amount: '492.0',
        tracker_status: null,
        treatment_episode: {
          id: 'vNwNjp2',
          accepted_at: null,
          admitted_at: '2023-10-31T04:34:42-04:00',
          created_at: '2023-11-15T03:34:42-05:00',
          decline_reason: null,
          declined_at: null,
          discharge_notes: null,
          discharge_type: null,
          discharged_at: '2023-12-13T03:34:42-05:00',
          estimated_arrival: null,
          estimated_discharge: null,
          incoming_notes: null,
          levels_of_care: [],
          no_show_notes: null,
          notes: null,
          other_discharge_type: null,
          reserved_at: null,
          return_eligibility: null,
          status: 'admitted',
          tags: [],
          title: null,
          type: 'admission',
          updated_at: '2023-11-21T09:59:12-05:00',
          waitlist_notes: null,
        },
        type: 'insurance_claim',
        type_of_service_code: null,
        updated_at: '2023-11-24T08:07:41-05:00',
        validation: {},
        value_codes: [],
        version: 'v2',
        warnings: {},
      },
      insurance_claim_service_line: {
        id: '1goEPw',
        created_at: '2023-11-24T08:03:57-05:00',
        diagnoses: [],
        diagnoses_ids: [],
        identifier: 'BH_CLM_SVC_000009',
        insurance_claim: {
          status: 'draft',
        },
        insurance_new_code: {
          id: 'RPL0PD',
          code_type: 'cpt',
          created_at: '2023-11-20T11:12:50-05:00',
          description: 'Internal imaging using ultrasound technology',
          modifier_codes: [],
          notes: '',
          procedure_code: 'UD303',
          revenue_code: '',
          service_name: 'Ultrasound Diagnostic',
          type: 'insurance_new_code',
          unit: 'per_unit',
          updated_at: '2023-11-20T11:12:50-05:00',
        },
        insurance_new_payment_service_line: {
          identifier: 'BH_PAY_000004',
          allowed_amount: '0.0',
          insurance_paid_amount: '0.0',
          deductible_amount: '0.0',
          copay_amount: '0.0',
          coinsurance_amount: '0.0',
          adjustment_group_code: '',
          adjustment_reason_code: '',
          adjustment_amount: '0.0',
          balance_client_due_amount: '300.0',
          balance_billed_amount: '0.0',
        },
        modifier_codes: [],
        revenue_code: null,
        service_date_end: '2023-11-19T09:57:35-05:00',
        service_date_start: '2023-11-19T09:13:35-05:00',
        status: 'balance_due_patient',
        total_amount: '123.0',
        type: 'insurance_claim_service_line',
        unit_price: '123.0',
        unit_type: 'UN',
        units: 1,
        updated_at: '2023-11-24T08:07:41-05:00',
      },
      insurance_paid_amount: '0.0',
      last_edited_by: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      type: 'insurance_new_payment_service_line',
      updated_at: '2023-11-24T08:07:41-05:00',
    },
    {
      id: 'bPxBg0',
      adjustment_amount: '0.0',
      adjustment_group_code: '',
      adjustment_reason_code: '',
      allowed_amount: '0.0',
      author: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      balance_billed_amount: '0.0',
      balance_client_due_amount: '400.0',
      balance_due_status: null,
      coinsurance_amount: '0.0',
      copay_amount: '0.0',
      created_at: '2023-11-24T08:07:41-05:00',
      deductible_amount: '0.0',
      identifier: 'BH_INV_LN_000004',
      insurance_claim: {
        id: 'Oglw6y',
        admission_source_code: null,
        admission_type_code: null,
        admitted_at: null,
        admitting_diagnosis: {
          id: 'WoJokOq',
          category: 'primary',
          code: 'F10.23',
          created_at: '2023-11-15T03:58:01-05:00',
          description: 'Alcohol dependence with withdrawal',
          from: null,
          notes:
            'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
          source: 'internal',
          status: 'active',
          type: 'diagnosis',
          until: null,
          updated_at: '2023-11-17T13:00:26-05:00',
        },
        assignee: null,
        attending_provider: null,
        author: {
          id: 'wgWOgG',
          avatar: null,
          created_at: '2023-11-15T03:32:00-05:00',
          email: 'robert.eles@behavehealth.com',
          first_name: 'Robert',
          has_accounting_access: false,
          is_pin_set: false,
          is_shared_with_portal: true,
          last_name: 'Eles',
          middle_name: null,
          name: 'Robert Eles',
          phone_no: null,
          phone_no_ext: null,
          position: 'dev',
          signature:
            'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
          type: 'bh_employee',
          updated_at: '2023-11-15T11:16:23-05:00',
        },
        bill_type: null,
        billing_note: null,
        billing_provider: {
          id: 'X6Xqga',
          address: {
            city: 'West Harwich',
            state: 'MA',
            country: 'USA',
            zip_code: '02671',
            address_line_1: '32 Main Street',
            address_line_2: '',
          },
          category: 'npi',
          certification: null,
          continued_education_required: false,
          created_at: '2023-11-20T04:30:56-05:00',
          credential: 'Test',
          current_credits: null,
          employee_identifier: null,
          expires_at: null,
          first_name: null,
          graduation_year: null,
          issued_at: '2023-11-23T00:00:00-05:00',
          last_name: null,
          level: 'organization',
          license_number: '123',
          notes: '',
          organization: 'Org Test',
          phone_no: '1231231231',
          provider_type: null,
          required_annual_credits: null,
          status: null,
          tax_identifier: '123',
          type: 'credential',
          updated_at: '2023-11-20T04:31:03-05:00',
        },
        category: 'professional',
        claim_filing_code: 'CI',
        claim_frequency_code: '1',
        condition_codes: [],
        control_number: '9U5C9YE64',
        covering_from: '2023-11-19T09:13:22-05:00',
        covering_until: '2023-11-19T10:08:41-05:00',
        created_at: '2023-11-24T08:03:57-05:00',
        discharged_at: null,
        flagged_status: null,
        from_date: '2023-11-19T09:13:22-05:00',
        identifier: 'BH_CLM_000004',
        initial_submission_at: null,
        insurance_authorization: null,
        insurance_claim_service_lines: [
          {
            id: 'dgyN9M',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000007',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '100.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:35:22-05:00',
            service_date_start: '2023-11-19T09:13:22-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: 'NP2pgR',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000008',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '200.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:46:28-05:00',
            service_date_start: '2023-11-19T09:13:28-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: '1goEPw',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000009',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '300.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T09:57:35-05:00',
            service_date_start: '2023-11-19T09:13:35-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
          {
            id: 'bPxBg0',
            created_at: '2023-11-24T08:03:57-05:00',
            diagnoses: [],
            diagnoses_ids: [],
            identifier: 'BH_CLM_SVC_000010',
            insurance_claim: {
              status: 'draft',
            },
            insurance_new_code: {
              id: 'RPL0PD',
              code_type: 'cpt',
              created_at: '2023-11-20T11:12:50-05:00',
              description: 'test 213',
              modifier_codes: [],
              notes: '',
              procedure_code: 'aa',
              revenue_code: '',
              service_name: 'A',
              type: 'insurance_new_code',
              unit: 'per_unit',
              updated_at: '2023-11-20T11:12:50-05:00',
            },
            insurance_new_payment_service_line: {
              identifier: 'BH_PAY_000004',
              allowed_amount: '0.0',
              insurance_paid_amount: '0.0',
              deductible_amount: '0.0',
              copay_amount: '0.0',
              coinsurance_amount: '0.0',
              adjustment_group_code: '',
              adjustment_reason_code: '',
              adjustment_amount: '0.0',
              balance_client_due_amount: '400.0',
              balance_billed_amount: '0.0',
            },
            modifier_codes: [],
            revenue_code: null,
            service_date_end: '2023-11-19T10:08:41-05:00',
            service_date_start: '2023-11-19T09:13:41-05:00',
            status: 'balance_due_patient',
            total_amount: '123.0',
            type: 'insurance_claim_service_line',
            unit_price: '123.0',
            unit_type: 'UN',
            units: 1,
            updated_at: '2023-11-24T08:07:41-05:00',
          },
        ],
        insurance_fee_schedule: null,
        insurance_local_payer: {
          id: 'RPL0PD',
          address: {},
          archived_at: null,
          avatar: null,
          closing_at: null,
          created_at: '2023-11-19T09:15:21-05:00',
          enrolled_status: 'not_enrolled',
          fax_no: null,
          insurance_clearing_house_config: null,
          name: 'Test',
          network_status: 'out_of_network',
          opening_at: null,
          opening_times: null,
          other_phone_no_1: null,
          other_phone_no_2: null,
          payer_id: null,
          payer_source: 'clearing_house',
          phone_no: null,
          status: 'active',
          supports: null,
          tags: [],
          timezone: null,
          type: 'insurance_local_payer',
          updated_at: '2023-11-19T09:15:21-05:00',
          website: null,
        },
        insurance_new_fee_schedule: {
          id: 'RPL0PD',
          archived_at: null,
          created_at: '2023-11-19T09:14:10-05:00',
          employees: [],
          houses: [],
          insurance_local_payers: [],
          insurance_new_fee_schedule_services: [
            {
              id: 'RPL0PD',
              created_at: '2023-11-20T11:13:35-05:00',
              insurance_new_code: {
                id: 'RPL0PD',
                code_type: 'cpt',
                created_at: '2023-11-20T11:12:50-05:00',
                description: 'test 213',
                modifier_codes: [],
                notes: '',
                procedure_code: 'aa',
                revenue_code: '',
                service_name: 'A',
                type: 'insurance_new_code',
                unit: 'per_unit',
                updated_at: '2023-11-20T11:12:50-05:00',
              },
              price: '123.0',
              type: 'insurance_new_fee_schedule_service',
              updated_at: '2023-11-20T11:13:35-05:00',
            },
          ],
          name: 'aaa',
          status: 'active',
          type: 'insurance_new_fee_schedule',
          updated_at: '2023-11-20T11:13:35-05:00',
        },
        last_claim_note: null,
        last_insurance_claim_status: null,
        last_submission_at: null,
        ordering_provider: null,
        original_claim_no: '',
        patient_status_code: null,
        place_of_service_code: '57',
        primary_insurance: {
          id: 'RPL0PD',
          archived_at: null,
          card_issued_at: null,
          category: 'primary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:25:08-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: '12312312321',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AZ',
            zip_code: '123',
            address_line_1: 'asd',
            address_line_2: 'dsa',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:25:08-05:00',
        },
        principal_diagnosis: {
          id: 'WoJokOq',
          category: 'primary',
          code: 'F10.23',
          created_at: '2023-11-15T03:58:01-05:00',
          description: 'Alcohol dependence with withdrawal',
          from: null,
          notes:
            'The client presented with a chief complaint of severe alcohol withdrawal symptoms. She reported experiencing tremors, increased anxiety, insomnia, and profuse sweating after attempting to quit drinking independently. Her desire to quit drinking stems from concerns about the negative impact alcohol has had on his relationships, work, and overall health.',
          source: 'internal',
          status: 'active',
          type: 'diagnosis',
          until: null,
          updated_at: '2023-11-17T13:00:26-05:00',
        },
        referring_provider: null,
        rendering_provider: null,
        resident: {
          id: 'vNwNjp2',
          admitted_at: '2023-10-31T04:34:42-04:00',
          avatar: null,
          behave_id: 'ABVI000001',
          behave_identifier: '4P1531SEOM1D',
          care_level: 'sober_living',
          client_portal_status: null,
          color_code: null,
          created_at: '2023-11-15T03:34:42-05:00',
          dob: '2003-11-18T19:00:00-05:00',
          email: 'abbigail.villarreal@getnada.com',
          encrypted_data: {
            id: 'VoJo107',
            address: {
              address_line_1: '32 Main Street',
              address_line_2: '',
              city: 'East Hampton',
              state: 'NY',
              zip_code: '11930',
              country: 'USA',
            },
            created_at: '2023-11-15T03:34:42-05:00',
            notes: null,
            secure_pin: null,
            ssn: null,
            treatment_address: null,
            type: 'encrypted_data',
            updated_at: '2023-11-22T11:27:15-05:00',
          },
          external_id: '4444f162-8390-46e7-8e63-d6bfa9b63b65',
          financial_notes: null,
          first_name: 'Abbigail',
          gender_identity: null,
          gender_identity_other: null,
          has_portal_access: true,
          high_risk_notes: null,
          high_risk_title: null,
          invited_to_portal_at: '2023-11-15T03:34:42-05:00',
          is_billed_by_behave: true,
          is_insurance_auth_required: false,
          is_insurance_billable: true,
          is_insured: null,
          is_the_policy_holder: null,
          last_logged_in_at: null,
          last_name: 'Villarreal',
          level_of_care: null,
          middle_name: '',
          name: 'Abbigail Villarreal',
          notes: null,
          phone_no: '461-306-0872',
          preferred_pronouns: null,
          preferred_pronouns_other: null,
          priority: null,
          referral_notes: null,
          referral_out_notes: null,
          sex: 'female',
          sex_other: null,
          signature: null,
          status: 'current',
          sync_with_accounting: null,
          tags: [
            {
              id: 'RPL0PD',
              color: '#f44336',
              created_at: '2023-11-15T03:34:26-05:00',
              description:
                'This tag indicates that the referral came from a close family member or friend of the potential resident, who is seeking help on their behalf.',
              glyph: 'cross',
              name: 'Family or Friend',
              type: 'tag',
              updated_at: '2023-11-15T03:34:26-05:00',
            },
            {
              id: 'Oglw6y',
              color: '#274879',
              created_at: '2023-11-15T03:34:26-05:00',
              description:
                'This tag is for residents experiencing mental health issues, such as depression, anxiety, or other psychological conditions, and who require therapeutic interventions and support.',
              glyph: 'current_risk',
              name: 'Mental Health',
              type: 'tag',
              updated_at: '2023-11-15T03:34:26-05:00',
            },
          ],
          type: 'resident',
          updated_at: '2023-11-24T08:07:41-05:00',
        },
        response: {},
        secondary_insurance: {
          id: 'X6Xqga',
          archived_at: null,
          card_issued_at: null,
          category: 'secondary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:26:14-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: 'Member ID',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AK',
            zip_code: 'asd',
            address_line_1: 'asd',
            address_line_2: 'asd',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:26:20-05:00',
        },
        service_lines: [],
        state: 'state_draft',
        status: 'draft',
        supervising_provider: null,
        tertiary_insurance: {
          id: 'X6Xqga',
          archived_at: null,
          card_issued_at: null,
          category: 'secondary',
          check_summary: null,
          claims_phone_no: '',
          clearing_house: null,
          created_at: '2023-11-22T11:26:14-05:00',
          effective_at: null,
          group_id: '',
          group_name: null,
          insurance_local_payer: {
            id: 'RPL0PD',
            address: {},
            archived_at: null,
            avatar: null,
            closing_at: null,
            created_at: '2023-11-19T09:15:21-05:00',
            enrolled_status: 'not_enrolled',
            fax_no: null,
            insurance_clearing_house_config: null,
            name: 'Test',
            network_status: 'out_of_network',
            opening_at: null,
            opening_times: null,
            other_phone_no_1: null,
            other_phone_no_2: null,
            payer_id: null,
            payer_source: 'clearing_house',
            phone_no: null,
            status: 'active',
            supports: null,
            tags: [],
            timezone: null,
            type: 'insurance_local_payer',
            updated_at: '2023-11-19T09:15:21-05:00',
            website: null,
          },
          insurance_name: 'Test',
          is_client_policy_holder: true,
          member_id: 'Member ID',
          notes: '',
          payer_source: 'clearing_house',
          plan: '',
          plan_type: null,
          policy_holder: {
            city: 'dsa',
            state: 'AK',
            zip_code: 'asd',
            address_line_1: 'asd',
            address_line_2: 'asd',
          },
          resets_at: null,
          status: 'active',
          tags: [],
          terminates_at: null,
          type: 'insurance_policy',
          updated_at: '2023-11-22T11:26:20-05:00',
        },
        to_date: '2023-11-19T10:08:41-05:00',
        total_amount: '492.0',
        tracker_status: null,
        treatment_episode: {
          id: 'vNwNjp2',
          accepted_at: null,
          admitted_at: '2023-10-31T04:34:42-04:00',
          created_at: '2023-11-15T03:34:42-05:00',
          decline_reason: null,
          declined_at: null,
          discharge_notes: null,
          discharge_type: null,
          discharged_at: '2023-12-13T03:34:42-05:00',
          estimated_arrival: null,
          estimated_discharge: null,
          incoming_notes: null,
          levels_of_care: [],
          no_show_notes: null,
          notes: null,
          other_discharge_type: null,
          reserved_at: null,
          return_eligibility: null,
          status: 'admitted',
          tags: [],
          title: null,
          type: 'admission',
          updated_at: '2023-11-21T09:59:12-05:00',
          waitlist_notes: null,
        },
        type: 'insurance_claim',
        type_of_service_code: null,
        updated_at: '2023-11-24T08:07:41-05:00',
        validation: {},
        value_codes: [],
        version: 'v2',
        warnings: {},
      },
      insurance_claim_service_line: {
        id: 'bPxBg0',
        created_at: '2023-11-24T08:03:57-05:00',
        diagnoses: [],
        diagnoses_ids: [],
        identifier: 'BH_CLM_SVC_000010',
        insurance_claim: {
          status: 'draft',
        },
        insurance_new_code: {
          id: 'RPL0PD',
          code_type: 'cpt',
          created_at: '2023-11-20T11:12:50-05:00',
          description: 'Tests for identifying specific allergies',
          modifier_codes: [],
          notes: '',
          procedure_code: 'AT606',
          revenue_code: '',
          service_name: 'Allergy Testing',
          type: 'insurance_new_code',
          unit: 'per_unit',
          updated_at: '2023-11-20T11:12:50-05:00',
        },
        insurance_new_payment_service_line: {
          identifier: 'BH_PAY_000004',
          allowed_amount: '0.0',
          insurance_paid_amount: '0.0',
          deductible_amount: '0.0',
          copay_amount: '0.0',
          coinsurance_amount: '0.0',
          adjustment_group_code: '',
          adjustment_reason_code: '',
          adjustment_amount: '0.0',
          balance_client_due_amount: '400.0',
          balance_billed_amount: '0.0',
        },
        modifier_codes: [],
        revenue_code: null,
        service_date_end: '2023-11-19T10:08:41-05:00',
        service_date_start: '2023-11-19T09:13:41-05:00',
        status: 'balance_due_patient',
        total_amount: '123.0',
        type: 'insurance_claim_service_line',
        unit_price: '123.0',
        unit_type: 'UN',
        units: 1,
        updated_at: '2023-11-24T08:07:41-05:00',
      },
      insurance_paid_amount: '0.0',
      last_edited_by: {
        id: 'wgWOgG',
        avatar: null,
        created_at: '2023-11-15T03:32:00-05:00',
        email: 'robert.eles@behavehealth.com',
        first_name: 'Robert',
        has_accounting_access: false,
        is_pin_set: false,
        is_shared_with_portal: true,
        last_name: 'Eles',
        middle_name: null,
        name: 'Robert Eles',
        phone_no: null,
        phone_no_ext: null,
        position: 'dev',
        signature:
          'http://api.lvh.me:3010/files/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35c6605ac6ace9df74c2040e4ce0f1d5ca8ce931/387186f7-12aa-44a9-8fad-1ebb8382bff2.png?tenant=demo',
        type: 'bh_employee',
        updated_at: '2023-11-15T11:16:23-05:00',
      },
      type: 'insurance_new_payment_service_line',
      updated_at: '2023-11-24T08:07:41-05:00',
    },
  ],
}

const PAYMENTS = [
  {
    id: 'Oglw6y',
    amount: '123.0',
    author: {
      id: 'wgWOgG',
      avatar: '',
      middle_name: null,
      name: 'Robert Eles',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'vNwNjp2',
      avatar: '',
      name: 'Abbigail Villarreal',
    },
    name: 'Card Payment',
    notes: 'Payment Notes',
    payed_with: 'online',
    payment_reference: '12345678',
    transactioned_at: '2023-11-15T03:34:42-05:00',
    created_at: '2023-11-15T03:34:45-05:00',
    updated_at: '2023-11-15T03:34:45-05:00',
  },
  {
    id: 'B7sJ2x',
    amount: '250.75',
    author: {
      id: 'kL3dZ8',
      avatar: '',
      middle_name: 'James',
      name: 'Emily Clarke',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'u6Hv9z1',
      avatar: '',
      name: 'Marcus Trenton',
    },
    name: 'Online Transfer',
    notes: 'Monthly premium payment',
    payed_with: 'online_apps',
    payment_reference: '87456321',
    transactioned_at: '2023-11-15T03:34:41-05:00',
    created_at: '2023-11-15T03:34:35-05:00',
    updated_at: '2023-11-15T03:34:35-05:00',
  },
  {
    id: 'QzT5vR',
    amount: '500.00',
    author: {
      id: 's9GvD2',
      avatar: '',
      middle_name: null,
      name: 'Alex Johnson',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'p3Rk8s3',
      avatar: '',
      name: 'Linda Gates',
    },
    name: 'Cheque Payment',
    notes: 'Annual insurance payment',
    payed_with: 'card',
    payment_reference: '99887766',
    transactioned_at: '2023-11-15T03:34:55-05:00',
    created_at: '2023-11-15T03:34:46-05:00',
    updated_at: '2023-11-15T03:34:46-05:00',
  },
  {
    id: 'W3pH7n',
    amount: '75.20',
    author: {
      id: 'oL2wH1',
      avatar: '',
      middle_name: 'Anne',
      name: 'Kevin Bright',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'x6Ty2m4',
      avatar: '',
      name: 'Sarah Connor',
    },
    name: 'Direct Debit',
    notes: 'Quarterly health insurance',
    payed_with: 'cash',
    payment_reference: '11223344',
    transactioned_at: '2023-11-15T03:34:41-05:00',
    created_at: '2023-11-15T03:34:47-05:00',
    updated_at: '2023-11-15T03:34:47-05:00',
  },
  {
    id: 'J8kL5d',
    amount: '300.50',
    author: {
      id: 'bD9zC5',
      avatar: '',
      middle_name: null,
      name: 'Daniel Ruiz',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'r5Uj9n5',
      avatar: '',
      name: 'Rebecca Norman',
    },
    name: 'PayPal Payment',
    notes: 'Bi-annual car insurance',
    payed_with: 'check',
    payment_reference: '55667788',
    transactioned_at: '2023-11-15T03:34:37-05:00',
    created_at: '2023-11-15T03:34:44-05:00',
    updated_at: '2023-11-15T03:34:44-05:00',
  },
  {
    id: 'G6vK2b',
    amount: '150.30',
    author: {
      id: 'pH7wM3',
      avatar: '',
      middle_name: 'Marie',
      name: 'Oscar Wilde',
    },
    category: 'payment',
    charge_category: null,
    client: {
      id: 'm8Zk6n6',
      avatar: '',
      name: 'George Orwell',
    },
    name: 'E-Wallet Payment',
    notes: 'Home insurance installment',
    payed_with: 'ach',
    payment_reference: '22334455',
    transactioned_at: '2023-11-15T03:34:43-05:00',
    created_at: '2023-11-15T03:34:36-05:00',
    updated_at: '2023-11-15T03:34:36-05:00',
  },
]

const WRITE_OFFS = [
  {
    id: 'Oglw6y',
    author: {
      id: 'wgWOgG',
      avatar: '',
      middle_name: null,
      name: 'Robert Eles',
    },
    client: {
      id: 'vNwNjp2',
      avatar: '',
      name: 'Abbigail Villarreal',
    },
    name: 'Unrecoverable Debt Write-off',
    amount: 150,
    notes: 'Client insolvency leading to unrecoverable debt write-off.',
    transactioned_at: '2023-11-15T03:34:42-05:00',
    created_at: '2023-11-15T03:34:45-05:00',
    updated_at: '2023-11-15T03:34:45-05:00',
  },
  {
    id: 'B7sJ2x',
    author: {
      id: 'kL3dZ8',
      avatar: '',
      middle_name: 'James',
      name: 'Emily Clarke',
    },
    client: {
      id: 'u6Hv9z1',
      avatar: '',
      name: 'Marcus Trenton',
    },
    name: 'Damage Claim Write-off',
    amount: 200,
    notes: 'Write-off for damaged goods claim, unable to reclaim costs.',
    transactioned_at: '2023-11-15T03:34:41-05:00',
    created_at: '2023-11-15T03:34:35-05:00',
    updated_at: '2023-11-15T03:34:35-05:00',
  },
  {
    id: 'QzT5vR',
    author: {
      id: 's9GvD2',
      avatar: '',
      middle_name: null,
      name: 'Alex Johnson',
    },
    client: {
      id: 'p3Rk8s3',
      avatar: '',
      name: 'Linda Gates',
    },
    name: 'Expired Service Contract Write-off',
    amount: 75,
    notes: 'Service contract expired without renewal, writing off remaining balance.',
    transactioned_at: '2023-11-15T03:34:55-05:00',
    created_at: '2023-11-15T03:34:46-05:00',
    updated_at: '2023-11-15T03:34:46-05:00',
  },
  {
    id: 'W3pH7n',
    author: {
      id: 'oL2wH1',
      avatar: '',
      middle_name: 'Anne',
      name: 'Kevin Bright',
    },
    client: {
      id: 'x6Ty2m4',
      avatar: '',
      name: 'Sarah Connor',
    },
    name: 'Bad Debt Relief Write-off',
    amount: 300,
    notes: 'Bad debt relief for long overdue invoices beyond recovery period.',
    transactioned_at: '2023-11-15T03:34:41-05:00',
    created_at: '2023-11-15T03:34:47-05:00',
    updated_at: '2023-11-15T03:34:47-05:00',
  },
  {
    id: 'J8kL5d',
    author: {
      id: 'bD9zC5',
      avatar: '',
      middle_name: null,
      name: 'Daniel Ruiz',
    },
    client: {
      id: 'r5Uj9n5',
      avatar: '',
      name: 'Rebecca Norman',
    },
    name: 'Charity Donation Write-off',
    amount: 500,
    notes: 'Charitable donation of services, recorded as write-off for tax purposes.',
    transactioned_at: '2023-11-15T03:34:37-05:00',
    created_at: '2023-11-15T03:34:44-05:00',
    updated_at: '2023-11-15T03:34:44-05:00',
  },
]
