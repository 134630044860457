import URLInput from './URLInput'
import { withFormContext } from './context'

class GoogleMeetInput extends URLInput {}

GoogleMeetInput.defaultProps = {
  glyph: 'google_meet',
}

export default withFormContext(GoogleMeetInput)
