import React from 'react'
import startCase from 'lodash/startCase'

import { COLORS } from '../theme'
import Analytics from '../modules/analytics'

import Tooltip from './Tooltip'
import Iframe from './Iframe'

const TYPES = {
  info: {
    glyph: 'info',
    color: COLORS.green,
    message: 'Click to find out more',
  },
  guide: {
    glyph: 'question',
    color: COLORS.blue,
    message: 'Click for instructions',
  },
}

const VIDEOS = {
  test: 'https://embed.fleeq.io/l/yln3hp2g46-yemu34m4wo',
  password_reset: 'https://embed.fleeq.io/l/mrwcndudun-a3folyta3d',
}

type Props = {
  articleId: string
  id: string
  isSeen: boolean
  openAs: string
  type: string
  videoId: string
}

const saveSettings = (props: Props) => {
  const { id, articleId, openAs, type } = props
  let firstTime = false

  try {
    const currentTags = JSON.parse(localStorage.getItem('bh.helptags'))
    if (!currentTags[id]) {
      localStorage.setItem('bh.helptags', JSON.stringify({ ...currentTags, [id]: true }))
      firstTime = true
    }
  } catch {
    localStorage.setItem('bh.helptags', JSON.stringify({ [id]: true }))
    firstTime = true
  }

  Analytics.queue({
    type: 'track',
    name: 'Tag Selected',
    params: {
      tagId: id,
      articleId: articleId,
      openAs: openAs,
      firstTime: firstTime,
      type: type,
    },
  })
}

const HelpTag = (props: Props) => {
  const { id, articleId, videoId, openAs, type, isSeen } = props
  const [seen, setSeen] = React.useState(isSeen)

  React.useEffect(() => {
    try {
      const currentTags = JSON.parse(localStorage.getItem('bh.helptags'))
      setSeen(currentTags[id])
    } catch {}
  }, [id, setSeen])

  if (!id) return null

  const onSelect = (event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()

    if (articleId) {
      window.Beacon('article', articleId, { type: openAs })
    }

    saveSettings(props)
    setSeen(true)
  }

  if (articleId) {
    return (
      <Tooltip
        onClick={onSelect}
        animate={!seen}
        glyph={TYPES[type].glyph}
        color={TYPES[type].color}
        css={{ marginLeft: '0.5em', display: 'inline-flex' }}
        content={TYPES[type].message}
      />
    )
  }

  if (videoId) {
    return (
      <Iframe
        title={startCase(videoId)}
        url={VIDEOS[videoId]}
        triggerCSS={{ marginLeft: '0.5em', display: 'inline-flex', alignItems: 'center' }}
        afterOpen={onSelect}
      >
        <Tooltip animate={!seen} glyph="video_circle" color={COLORS.blue} content="View Instructional Video" />
      </Iframe>
    )
  }

  return null
}

HelpTag.defaultProps = {
  isSeen: false,
  openAs: 'sidebar',
  type: 'info', // guide
}

export default HelpTag
