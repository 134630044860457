import React from 'react'

import { withFormContext } from '../../../components/Forms/context'

import Button from '../../../components/Button'
import Checkbox from '../../../components/Forms/Checkbox'
import ContextShow from '../../../components/Forms/ContextShow'

import { ComboBox } from '../../../components/Forms/ComboBoxes/ComboBox'

import { SectionCard } from '../../../components/SectionCard'

const getDescription = (provider: any) => {
	if (!provider) return null

	const { level, license_number, employee_identifier } = provider

	if (level === 'individual') return `Individual • NPI: ${license_number} • Employee ID: ${employee_identifier}`
	if (level === 'organization') return `Organization • NPI: ${license_number}`
}

const ClaimProfessionalProviders = ({ className, tenant }: any) => {
	const [facilityProvider, setFacilityProvider] = React.useState(null)
	const [billingProvider, setBillingProvider] = React.useState(null)
	const [renderingProvider, setRenderingProvider] = React.useState(null)
	const [supervisingProvider, setSupervisingProvider] = React.useState(null)
	const [orderingProvider, setOrderingProvider] = React.useState(null)
	const [referringProvider, setReferringProvider] = React.useState(null)

	return (
		<>
			<SectionCard
				title="Providers"
				className={className}
				aside={
					<Button
						label="Providers Settings"
						size={100}
						type="link"
						glyph="settings"
						link="/settings/providers"
						target="_blank"
					/>
				}
			>
				<div className="grid gap-4 grid-cols-1 mq600:grid-cols-2 mq900:grid-cols-3 mq1200:grid-cols-4 mq1400:grid-cols-6">
					<div>
						<ComboBox
							label="Facility"
							model="facility_provider"
							icon="staff_credentials"
							type="company.organization_providers"
							dependentValue={tenant?.id}
							selectTitle={(data: any) => `${data?.credential} (${data?.license_number})`}
							selectDescription={(data: any) =>
								data?.level === 'organization' ? data?.organization : `${data?.first_name} ${data?.last_name}`
							}
							validations={{
								presence: {
									message: 'Please select a Facility Provider'
								}
							}}
							maxWidth="100%"
							onUpdate={(value: any) => {
								setFacilityProvider(value)
							}}
							tooltip="This is the provider who is responsible for the facility where the patient was treated. Must be an Organization"
						/>
						<div className="text-sm text-text-muted mt-1">{getDescription(facilityProvider)}</div>
					</div>

					<div>
						<ComboBox
							label="Billing Provider"
							model="billing_provider"
							icon="staff_credentials"
							type="company.providers"
							dependentValue={tenant?.id}
							selectTitle={(data: any) => `${data?.credential} (${data?.license_number})`}
							selectDescription={(data: any) =>
								data?.level === 'organization' ? data?.organization : `${data?.first_name} ${data?.last_name}`
							}
							validations={{
								presence: {
									message: 'Please select a Billing Provider'
								}
							}}
							maxWidth="100%"
							onUpdate={(value: any) => {
								setBillingProvider(value)
							}}
							tooltip="This is the provider who is responsible for the billing of the claim. Can be an Individual or an Organization"
						/>
						<div className="text-sm text-text-muted mt-1">{getDescription(billingProvider)}</div>
					</div>

					<div>
						<ComboBox
							maxWidth="100%"
							label="Rendering Provider"
							model="rendering_provider"
							icon="staff_credentials"
							type="company.providers"
							dependentValue={tenant?.id}
							selectTitle={(data: any) => `${data?.credential} (${data?.license_number})`}
							selectDescription={(data: any) =>
								data?.level === 'organization' ? data?.organization : `${data?.first_name} ${data?.last_name}`
							}
							value={renderingProvider}
							onUpdate={(value: any) => {
								setRenderingProvider(value)
							}}
							tooltip="This is the provider who is rendering the care of the patient during the visit. Can be an Individual or an Organization"
						/>
						<div className="text-sm text-text-muted mt-1">{getDescription(renderingProvider)}</div>
					</div>
					{/* <ContextShow when="rendering_provider_same_with_billing_provider" is={true}>
					</ContextShow> */}

					<div>
						<ComboBox
							label="Supervising Provider"
							model="supervising_provider"
							icon="staff_credentials"
							type="company.individual_providers"
							dependentValue={tenant?.id}
							selectTitle={(data: any) => `${data?.credential} (${data?.license_number})`}
							selectDescription={(data: any) => `${data?.first_name} ${data?.last_name}`}
							maxWidth="100%"
							value={supervisingProvider}
							onUpdate={(value: any) => {
								setSupervisingProvider(value)
							}}
							tooltip="This is the provider who is responsible for the care of the patient during the visit. Must be an Individual"
						/>
						<div className="text-sm text-text-muted mt-1">{getDescription(supervisingProvider)}</div>
					</div>

					<div>
						<ComboBox
							label="Ordering Provider"
							model="ordering_provider"
							icon="staff_credentials"
							type="company.individual_providers"
							dependentValue={tenant?.id}
							selectTitle={(data: any) => `${data?.credential} (${data?.license_number})`}
							selectDescription={(data: any) => `${data?.first_name} ${data?.last_name}`}
							maxWidth="100%"
							value={orderingProvider}
							onUpdate={(value: any) => {
								setOrderingProvider(value)
							}}
							tooltip="This is the provider who is responsible for ordering the service. Must be an Individual"
						/>
						<div className="text-sm text-text-muted mt-1">{getDescription(orderingProvider)}</div>
					</div>

					<div>
						<ComboBox
							label="Referring Provider"
							model="referring_provider"
							icon="staff_credentials"
							type="company.individual_providers"
							dependentValue={tenant?.id}
							selectTitle={(data: any) => `${data?.credential} (${data?.license_number})`}
							selectDescription={(data: any) => `${data?.first_name} ${data?.last_name}`}
							maxWidth="100%"
							value={referringProvider}
							onUpdate={(value: any) => {
								setReferringProvider(value)
							}}
							tooltip="This is the provider who referred the patient to the facility. Must be an Individual"
						/>
						<div className="text-sm text-text-muted mt-1">{getDescription(referringProvider)}</div>
					</div>
				</div>
			</SectionCard>
		</>
	)
}

const styles = {
	cardTitle: {
		fontSize: '1rem'
	},

	cardContent: {
		height: '100%'
	}
}

export default withFormContext(ClaimProfessionalProviders)
