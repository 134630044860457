import React from 'react'

const PageSectionContent = ({ children, className }: any) => {
  return (
    <div css={styles} className={className}>
      {children}
    </div>
  )
}

const styles = {
  display: 'grid',
  gridTemplateColumns: '1fr',
}

export default PageSectionContent
