import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'
import { RECOVERY_NOTES_SLUGS } from '../../../utils/constants'

import { DataFormTooltip } from '../components/DataFormTooltip'
import { RecoveryNoteInfo } from '../../StaffJourney/records/RecoveryNoteInfo'

export const RecoveryNote = ({ data, client }: any) => {
  if (!data) return null

  return (
    <DataFormTooltip
      title={data.name}
      icon="recovery_coaching"
      color={tint(0.7, COLORS.orange)}
      startDate={data.dated_at}
      recordLink={`/recovery-coaching/${RECOVERY_NOTES_SLUGS[data.subcategory]}/${data.id}`}
      status={data.status}
      client={client}
    >
      <RecoveryNoteInfo data={data} />
    </DataFormTooltip>
  )
}
