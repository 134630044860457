import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import MedicationStatus from '../../components/Statuses/MedicationStatus'

import { FILTERS } from '../Filters/config'
import { MEDICATION_TIMES } from '../../utils/constants'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

const SOURCE: any = {
  external_during: 'External Provider (during treatment)',
  external_prior: 'External Provider (prior to treatment)',
  internal: 'Current Facility',
  private_provider: 'Private Providers',
}

export const MedicationsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.name} to={to?.(data.data)} />,
      },
      {
        title: 'Client',
        id: 'resident',
        model: 'data.resident.name',
        type: 'profile',
      },
      {
        title: 'Status',
        id: 'status',
        model: 'data.status',
        formatValue: ({ value }: any) => <MedicationStatus status={value} />,
      },
      {
        title: 'Start Date',
        id: 'started_at',
        model: 'data.started_at',
        type: 'date',
      },
      {
        title: 'End Date',
        id: 'ended_at',
        model: 'data.ended_at',
        type: 'date',
      },
      {
        title: 'Medication Time',
        id: 'medication_time',
        model: 'data.medication_time',
      },
      {
        title: 'Dosage',
        id: 'dosage',
        model: 'data.dosage',
      },
      {
        title: 'Unit',
        id: 'unit',
        model: 'data.unit',
      },
      {
        title: 'Source',
        id: 'source',
        model: 'data.source',
        formatValue: ({ value }: any) => SOURCE?.[value],
      },
      {
        title: 'Refill Notes',
        id: 'refill',
        model: 'data.refill',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Medications"
      icon="medications"
      columns={columns}
      filtersConfig={FILTERS.live_reports.meds}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="meds"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=meds'}
        />
      }
      {...props}
    />
  )
}
