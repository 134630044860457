import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const ClinicalAssessmentsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.name} to={to?.(data.data)} />,
      },
      {
        width: 180,
        id: 'client',
        model: 'data.client.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 200,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ value }: any) => <DataFormStatus status={value} />,
      },
      {
        width: 200,
        id: 'created_at',
        model: 'data.created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'updated_at',
        model: 'data.updated_at',
        title: 'Last Edited',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'data.author',
        title: 'Added By',
        type: 'profile',
      },
      {
        width: 180,
        id: 'author_signed_at',
        model: 'data.author_signed_at',
        title: 'Author Signed At',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'supervisor_signed_at',
        model: 'data.supervisor_signed_at',
        title: 'Supervisor Signed At',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'client_admitted_at',
        model: 'data.client_admitted_at',
        title: 'Client Admitted At',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'admit_to_submit',
        model: 'data.admit_to_submit',
        title: 'Admit To Submit',
      },
      {
        width: 180,
        id: 'submit_to_signed',
        model: 'data.submit_to_signed',
        title: 'Submit To Signed',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Clinical Assessments"
      icon="clinical_assessments"
      columns={columns}
      filtersConfig={FILTERS.live_reports.data_forms}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="clinical_assessments"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=clinical_assessments'}
        />
      }
      {...props}
    />
  )
}
