import React from 'react'
import Notifications from '../modules/notifications'
import { copyToClipboard } from '../utils/functions'

export const useCopyToClipboard = ({ text, notification = 'Link copied!', dismissAfter = 0 }: any) => {
  const [didCopy, setDidCopy] = React.useState(false)

  const copy = () => {
    try {
      copyToClipboard(text)

      if (notification) Notifications.send(notification, 'positive')

      setDidCopy(true)

      if (dismissAfter) {
        setTimeout(() => {
          setDidCopy(false)
        }, dismissAfter)
      }
    } catch (error) {
      console.warn('Copy failed', error)
    }
  }

  return { copy, didCopy }
}

export default useCopyToClipboard
