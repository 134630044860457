export const CSS_VARS = {
  baseline: '--workflow-baseline',
  paddingX: '--workflow-padding-x',
  paddingY: '--workflow-padding-y',
}

export const CSS_VALUES = {
  baseline: `var(${CSS_VARS.baseline})`,
  paddingX: `var(${CSS_VARS.paddingX})`,
  paddingY: `var(${CSS_VARS.paddingY})`,
}
