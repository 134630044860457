import React from 'react'

import { COLORS } from '../../../theme'
import Glyph from '../../Glyph'

import { INPUT_STYLES } from '../styles'

export const PopoverTrigger = React.forwardRef((props: any, ref) => {
  const { label, isFocused, glyph } = props

  return (
    <div css={STYLES.root} className={isFocused ? 'is-focused' : ''}>
      {glyph && <Glyph glyph={glyph} size={12} css={STYLES.glyph} color={COLORS.blue} />}
      <div css={STYLES.label}>{label || 'Select…'}</div>
      <Glyph glyph="triangle_down" size={10} css={STYLES.triangle} />
    </div>
  )
})

const STYLES = {
  root: {
    ...INPUT_STYLES,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flex: '1 1 auto',
  },

  label: {
    marginRight: '0.25rem',
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  glyph: {
    marginRight: '0.25rem',
  },

  triangle: {
    marginLeft: 'auto',
  },
}
