import React from 'react'
import { tint, transparentize } from 'polished'

import { COLORS, SHADOW } from '../../../theme'

import Avatar from '../../Avatar'
import Empty from './Empty'
import Glyph from '../../Glyph'
import RouterLink from '../../Link'

type LinkProps = {
  avatar?: string
  className?: string
  glyph?: React.ReactNode
  href?: any
  isExternal?: boolean
  label: string | React.ReactNode
  onClick?: any
  title?: string
  to?: any
  testKey?: string
}

const InnerContent: React.FC<LinkProps> = ({ avatar, label, glyph, isExternal }) => {
  return (
    <>
      {avatar !== undefined && <Avatar magnify src={avatar} size="1.5rem" initials={label} />}
      {glyph}
      {label}
      {isExternal && <Glyph glyph="external_link" size={14} color={COLORS.blue} css={externalGlyphStyles} />}
    </>
  )
}

const Link: React.FC<LinkProps> = (props) => {
  const { avatar, className, glyph, href, label, onClick, title, to, isExternal, testKey } = props

  if (to) {
    const Tag = props.as || RouterLink

    return (
      <Tag to={to} title={title} css={styles} className={className} onClick={onClick} testKey={testKey}>
        <InnerContent avatar={avatar} glyph={glyph} label={label} />
      </Tag>
    )
  }

  if (href) {
    return (
      <a
        href={href}
        title={title}
        css={styles}
        className={className}
        rel={isExternal ? 'noreferrer noopener' : undefined}
        target={isExternal ? '_blank' : undefined}
        data-test={testKey}
      >
        <InnerContent avatar={avatar} glyph={glyph} label={label} isExternal={isExternal} />
      </a>
    )
  }

  if (!href && !onClick && !label) return <Empty />

  return (
    <button
      type="button"
      onClick={onClick}
      title={title}
      css={styles}
      className={className}
      tabIndex={onClick ? 0 : -1}
      data-test={testKey}
    >
      <InnerContent avatar={avatar} glyph={glyph} label={label} />
    </button>
  )
}

export const HOVER_FOCUS_STYLES = {
  '&::before': {
    background: tint(0.98, COLORS.blue),
    boxShadow: `
      inset 0 0 0 1px ${tint(0.2, COLORS.blue)},
      0 0 0 1px ${tint(0.8, COLORS.blue)},
      ${SHADOW(2, transparentize(0.97, COLORS.blue))}
    `,
  },
}

const externalGlyphStyles = {
  marginLeft: '0.3rem',
}

const styles: any = {
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  padding: 0,

  color: COLORS.blue,
  fontSize: '1em',
  fontWeight: 500,

  border: 'none',
  background: COLORS.transparent,
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  outline: 'none',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    borderRadius: 2,
  },

  '&:hover, &:focus': {
    color: COLORS.blue,
    ...HOVER_FOCUS_STYLES,

    svg: {
      transform: 'scale3d(1.2, 1.2, 1.2)',
      transformOrigin: '60% 50%',
    },
  },

  svg: {
    width: '1.05em',
    height: '1.05em',
    marginRight: '0.25em',
    transition: 'transform 60ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  '.avatar': {
    marginRight: '0.5em',
  },
}

export default Link
