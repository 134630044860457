import React from 'react'

import { COLORS } from '../../theme'

import { DatePicker } from '../DatePicker'

const CalendarSidebar = (props: any) => {
  const { setCurrentDate, currentDate, after } = props

  return (
    <div css={STYLES.root}>
      <DatePicker
        isInline
        withQuickActions={false}
        minYear={2000}
        maxYear={2025}
        date={currentDate}
        onSelect={setCurrentDate}
        updateOn="year-month-day"
        css={STYLES.datePicker}
      />

      {after}
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridGap: '0.75rem',
  },

  datePicker: {
    background: COLORS.white,
    boxShadow: `0 1px 0 0 ${COLORS.divider}`,
  },
}

export default CalendarSidebar
