import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import FileStatus from '../../components/Statuses/FileStatus'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const OrganizationFilesReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'title',
        model: 'data.title',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value} to={to?.(data.data)} />,
      },
      {
        width: 180,
        id: 'resource',
        model: 'data.resource.name',
        title: 'Organization',
        type: 'profile',
      },
      {
        width: 140,
        id: 'file_type',
        model: 'data.content_type',
        title: 'Type',
        formatValue: ({ data, value }: any) => <FileStatus status={value} />,
      },
      {
        width: 140,
        disableFilters: true,
        id: 'file_tag_names',
        model: 'data.file_tag_names',
        title: 'Tags',
      },
      {
        width: 200,
        id: 'created_at',
        model: 'data.created_at',
        title: 'Date Added',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 180,
        id: 'author',
        model: 'data.author.name',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Organization Files"
      icon="files"
      columns={columns}
      filtersConfig={FILTERS.organization_files}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="organization_documents"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=organization_documents'}
        />
      }
    />
  )
}
