import React from 'react'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

export const ClaimFlagSelect = ({ isEditable, label, model, validations, onUpdate, value }: any) => (
  <Select
    fullWidth
    allowEmpty
    isEditable={isEditable}
    label={label}
    model={model}
    validations={validations}
    onUpdate={onUpdate}
    value={value}
  >
    <Option label="To Review" value="flagged_to_review" />
    <Option label="Pending" value="flagged_pending" />
    <Option label="Finalized" value="flagged_finalized" />
  </Select>
)

ClaimFlagSelect.defaultProps = {
  label: 'Flag Claims',
  model: 'flagged_status',
}
