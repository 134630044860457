import React from 'react'
import size from 'lodash/size'
import startCase from 'lodash/startCase'
import { useSelector } from 'react-redux'

import { ICONS } from '../../theme'

import Button from '../Button'
import Card from '../Card'
import CardHeader from '../CardHeader'
import CardsGrid from '../CardsGrid'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'
import Dialog from '../Dialog'
import DropdownItem from '../DropdownItem'
import Flex from '../Flex'
import Icon from '../Icon'
import Glyph from '../Glyph'
import PageSection from '../PageSection/PageSection'
import State from '../State'
import Status from '../Status'
import SummonOverlay from '../SummonOverlay'

import StripeAddCardOverlay from './StripeAddCardOverlay'

import { apiCreate, apiUpdate, apiDelete } from '../../modules/api'

const PaymentMethodCard = ({
  card,
  makeDefaultURL,
  paymentMethodID,
  defaultPaymentMethodID,
  refreshCards,
  hasOnlyOneCard,
  afterMakeCardDefault,
}: any) => {
  if (!card) return null

  const cardIcon = ICONS[card.brand] || ICONS.billing
  const isDefault = paymentMethodID === defaultPaymentMethodID

  const makeDefault = async () => {
    await apiUpdate({
      url: makeDefaultURL,
      params: { stripe_default_pm_id: paymentMethodID },
      notify: false,
    })

    if (afterMakeCardDefault) afterMakeCardDefault(paymentMethodID)
  }

  const deletePaymentMethod = async () => {
    await apiDelete({
      url: `/apps/stripe/connect/payment_methods/${paymentMethodID}?is_default=${isDefault}`,
      notify: false,
    })

    if (refreshCards) await refreshCards()
  }

  return (
    <Card>
      <CardHeader
        graphic={<Icon icon={cardIcon} />}
        actions={
          (!isDefault || hasOnlyOneCard) && (
            <>
              {!hasOnlyOneCard && (
                <Dialog
                  glyph="card"
                  title="Change Default Payment Method?"
                  message={
                    <div>
                      <div>Your new default payment method will be set to:</div>
                      <Flex nowrap gap={8} alignItems="center">
                        <Icon icon={cardIcon} size={20} />
                        <div>
                          <b>
                            {startCase?.(card.brand)} **** {card.last4}
                          </b>
                        </div>
                      </Flex>
                    </div>
                  }
                  yesColor="green"
                  yesLabel="Set as Default"
                  onYes={makeDefault}
                >
                  <DropdownItem label="Make Default" glyph="check" color="green" />
                </Dialog>
              )}

              <Dialog
                glyph="delete"
                title="Delete Card?"
                message="Are you sure you want to delete this card? This action cannot be undone."
                yesColor="red"
                yesLabel="Yes, Delete Card"
                onYes={deletePaymentMethod}
              >
                <DropdownItem label="Delete Card" glyph="delete" color="red" />
              </Dialog>
            </>
          )
        }
      >
        <CardTitle title={`${startCase?.(card.brand)} **** ${card.last4}`} after={isDefault && <Status label="Default" color="blue" />} />
        <CardSubtitle subtitle={`Expires ${card?.exp_month}/${card?.exp_year}`} />
      </CardHeader>
    </Card>
  )
}

const PaymentMethodBankAccount = ({
  bankAccount,
  makeDefaultURL,
  paymentMethodID,
  defaultPaymentMethodID,
  refreshBankAccounts,
  hasOnlyOneBankAccount,
  afterMakeBankAccountDefault,
}: any) => {
  if (!bankAccount) return null

  const isDefault = paymentMethodID === defaultPaymentMethodID

  const makeDefault = async () => {
    await apiUpdate({
      url: makeDefaultURL,
      params: { stripe_default_pm_id: paymentMethodID },
      notify: false,
    })

    if (afterMakeBankAccountDefault) afterMakeBankAccountDefault(paymentMethodID)
  }

  const deletePaymentMethod = async () => {
    await apiDelete({
      url: `/apps/stripe/connect/payment_methods/${paymentMethodID}?is_default=${isDefault}`,
      notify: false,
    })

    if (refreshBankAccounts) await refreshBankAccounts()
  }

  return (
    <Card>
      <CardHeader
        graphic={<Glyph glyph="bank" />}
        actions={
          (!isDefault || hasOnlyOneBankAccount) && (
            <>
              {!hasOnlyOneBankAccount && (
                <Dialog
                  glyph="card"
                  title="Change Default Payment Method?"
                  message={
                    <div>
                      <div>Your new default payment method will be set to:</div>
                      <Flex nowrap gap={8} alignItems="center">
                        <div>
                          <b>
                            {bankAccount.bank_name} **** {bankAccount.last4}
                          </b>
                        </div>
                      </Flex>
                    </div>
                  }
                  yesColor="green"
                  yesLabel="Set as Default"
                  onYes={makeDefault}
                >
                  <DropdownItem label="Make Default" glyph="check" color="green" />
                </Dialog>
              )}

              <Dialog
                glyph="delete"
                title="Delete Bank Account?"
                message="Are you sure you want to delete this bank account? This action cannot be undone."
                yesColor="red"
                yesLabel="Yes, Delete Bank Account"
                onYes={deletePaymentMethod}
              >
                <DropdownItem label="Delete Bank Account" glyph="delete" color="red" />
              </Dialog>
            </>
          )
        }
      >
        <CardTitle
          title={`${bankAccount.bank_name} **** ${bankAccount.last4}`}
          after={isDefault && <Status label="Default" color="blue" />}
        />
        <CardSubtitle subtitle="USD" />
      </CardHeader>
    </Card>
  )
}

const StripePaymentMethodsSection = ({ makeDefaultURL, title = 'Payment Methods', customer, tenant, extraActions }: any) => {
  const [loading, setLoading] = React.useState(false)
  const payment_methods = useSelector((state: any) => state.data?.payment_methods?.data)

  const [defaultPaymentMethod, setDefaultPaymentMethod] = React.useState(customer?.stripe_default_pm_id)
  const isEmpty = size(payment_methods?.cards) === 0
  const isProcessing = loading || isEmpty
  const hasOnlyOneCard = size(payment_methods?.cards) === 1

  const getPaymentMethods = async () => {
    try {
      setLoading(true)

      // POST is being used to be able to pass the params in the body
      await apiCreate({
        notify: false,
        name: 'payment_methods',
        url: `/apps/stripe/connect/payment_methods`,
        params: {
          reference_id: customer.id,
          reference_type: customer.type,
        },
      })

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actions = (
    <Flex gap={8}>
      <SummonOverlay
        overlay={
          <StripeAddCardOverlay
            stripeConnectID={tenant?.stripe_account_id}
            customer={customer}
            tenant={tenant}
            onSuccess={getPaymentMethods}
            makeDefaultURL={makeDefaultURL}
          />
        }
      >
        <Button label="Add Payment Method" glyph="add" />
      </SummonOverlay>

      {extraActions?.({ isEmpty })}
    </Flex>
  )

  return (
    <PageSection>
      <PageSection.Header graphic={<Icon icon="billing" />} after={actions}>
        <PageSection.Title title={title} />
      </PageSection.Header>

      <PageSection.Content>
        {isProcessing ? (
          <Card>
            <State
              title="Payment Methods"
              emptyDescription="No payment method added yet"
              isLoading={loading}
              isEmpty={isEmpty}
              minHeight={100}
            />
          </Card>
        ) : (
          <CardsGrid>
            {payment_methods?.cards.map((paymentMethod: any) => {
              if (paymentMethod.type === 'card') {
                return (
                  <PaymentMethodCard
                    makeDefaultURL={makeDefaultURL}
                    card={paymentMethod.card}
                    paymentMethodID={paymentMethod.id}
                    defaultPaymentMethodID={defaultPaymentMethod}
                    refreshCards={getPaymentMethods}
                    hasOnlyOneCard={hasOnlyOneCard}
                    afterMakeCardDefault={setDefaultPaymentMethod}
                  />
                )
              } else if (paymentMethod.type === 'us_bank_account') {
                return (
                  <PaymentMethodBankAccount
                    makeDefaultURL={makeDefaultURL}
                    bankAccount={paymentMethod.us_bank_account}
                    paymentMethodID={paymentMethod.id}
                    defaultPaymentMethodID={defaultPaymentMethod}
                    refreshBankAccounts={getPaymentMethods}
                    hasOnlyOneBankAccount={hasOnlyOneCard}
                    afterMakeBankAccountDefault={setDefaultPaymentMethod}
                  />
                )
              }
            })}
          </CardsGrid>
        )}
      </PageSection.Content>
    </PageSection>
  )
}

export default StripePaymentMethodsSection
