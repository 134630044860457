import React from 'react'

import { useUpdate } from '../../hooks/useNewAPI'

import Button from '../Button'
import Form from '../Forms/Form'
import Overlay from '../Overlay'
import Section from '../Section'

import { TableContext } from './context'

export const TableEditOverlay = ({ onClose }: any) => {
  const form: any = React.useRef()

  const { editSettings = {} }: any = React.useContext(TableContext)
  const { options = {}, input, initialModel } = editSettings

  const { mutateAsync, isLoading } = useUpdate(options)

  const handleSave = async () => {
    await mutateAsync(form.current.getFormValue())

    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center">
      <Overlay.Header title="Edit" glyph="edit" />

      <Overlay.Content>
        <Form getForm={form} initialModel={initialModel}>
          <Section>{input}</Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" glyph="check" color="green" type="primary" onClick={handleSave} isLoading={isLoading} />
      </Overlay.Footer>
    </Overlay>
  )
}
