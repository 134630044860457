import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../components/DataTable/DataTable'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { InvoiceStatus, STATUSES } from './InvoiceStatus'
import { APP_URLS } from '../MyAccount/utils'
import { DEFAULT_FILTERS } from '../Filters/constants'
import { beautifulAmount } from '../../utils/functions'

export const InvoicesDataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Invoice #',
        model: 'invoice_number',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell as={NavLink} id={data.id} to={props.to ? props.to(data) : undefined} value={value || data.identifier} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <InvoiceStatus status={value} />,
      },
      {
        title: 'Public Link',
        model: 'invoice_url',
        formatValue: ({ data }: any) => {
          if (!data.invoice_url && !data.external_id) return null

          return (
            <LinkCell isExternal value="Open Invoice" href={data.invoice_url || `${APP_URLS.mini_apps}/invoices/${data.external_id}`} />
          )
        },
      },
      {
        title: 'Total Amount',
        model: 'total_amount',
        width: 140,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          if (!data) return null

          return (
            <span className="flex justify-end font-[600]">
              {beautifulAmount(data?.category === 'legacy' ? data.amount : data?.calculated_amounts?.total)}
            </span>
          )
        },
      },
      {
        title: 'Billed To Name',
        model: 'billed_to_name',
      },
      {
        title: 'Billed To Company',
        model: 'billed_to_company_name',
      },
      {
        title: 'Billed To Email',
        model: 'billed_to_email',
        type: 'email',
      },
      {
        title: 'Billed To Phone',
        model: 'billed_to_phone_no',
        type: 'phone',
      },
      {
        title: 'Billed By Name',
        model: 'billed_by_name',
      },
      {
        title: 'Billed By Company',
        model: 'billed_by_company_name',
      },
      {
        title: 'Billed By Email',
        model: 'billed_by_email',
        type: 'email',
      },
      {
        title: 'Billed By Phone',
        model: 'billed_by_phone_no',
        type: 'phone',
      },
      {
        title: 'Invoice Period Start',
        model: 'period_started_at',
        type: 'date',
        width: 160,
      },
      {
        title: 'Invoice Period End',
        model: 'period_ended_at',
        type: 'date',
        width: 160,
      },
      {
        title: 'Due Date',
        model: 'due_date',
        type: 'date',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [],
  )

  return <DataTable asCard title="Invoices" icon="invoices" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
}

const FILTERS_CONFIG = {
  invoice_number: {
    label: 'Invoice #',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: Object.entries(STATUSES).map(([key, value]) => ({ label: value.label, value: key })),
  },
  total_amount: {
    label: 'Total Amount',
    type: 'number',
  },
  billed_to_name: {
    label: 'Billed To Name',
    type: 'string',
  },
  billed_to_company_name: {
    label: 'Billed To Company',
    type: 'string',
  },
  billed_to_email: {
    label: 'Billed To Email',
    type: 'string',
  },
  billed_to_phone_no: {
    label: 'Billed To Phone',
    type: 'string',
  },
  billed_by_name: {
    label: 'Billed By Name',
    type: 'string',
  },
  billed_by_company_name: {
    label: 'Billed By Company',
    type: 'string',
  },
  billed_by_email: {
    label: 'Billed By Email',
    type: 'string',
  },
  billed_by_phone_no: {
    label: 'Billed By Phone',
    type: 'string',
  },
  period_started_at: {
    label: 'Invoice Period Start',
    type: 'date',
  },
  period_ended_at: {
    label: 'Invoice Period End',
    type: 'date',
  },
  due_date: {
    label: 'Due Date',
    type: 'date',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
