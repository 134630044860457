import React from 'react'

import { Text } from '../../../../Typography'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import Flex from '../../../../Flex'
import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const circle = (color) => <span css={{ background: color, width: 18, height: 18, marginRight: 8, borderRadius: '100%' }} />

const LevelOfRiskAndInterventions = () => (
  <>
    <Text>
      <p>
        <i>
          "The estimation of suicide risk, at the culmination of the suicide assessment, is the quintessential{' '}
          <strong>clinical judgment</strong>, since no study has identified one specific risk factor or set of risk factors as specifically
          predictive of suicide or other suicidal behavior."
        </i>
      </p>
      <p>
        From The American Psychiatric Association Practice Guidelines for the Assessment and Treatment of Patients with Suicidal Behaviors,
        page 24.
      </p>
    </Text>

    <Flex vertical gap={35}>
      <FormSection heading={<Flex alignItems="center">{circle('red')} High Suicide Risk</Flex>} layout="vertical" maxWidth={570}>
        <RadioGroup model="data.level_of_risk_and_interventions.high_risk" layout="vertical-dense">
          <Radio
            label={
              <>
                Suicidal ideation with intent or intent with plan{' '}
                <strong>
                  <u>in past month</u>
                </strong>{' '}
                (C-SSRS Suicidal Ideation #4 or #5)
              </>
            }
            value="suicidal_ideation_with_intent_in_past_month"
          />
          <Radio
            label={
              <>
                Suicidal behavior{' '}
                <strong>
                  <u>within past 3 months</u>
                </strong>{' '}
                (C-SSRS Suicidal Behavior)
              </>
            }
            value="suicidal_behavior_within_past_three_month"
          />
        </RadioGroup>

        <CheckboxGroup label="Triage" layout="vertical" trueIcon="tick" falseIcon="cross" falseStyle="faded">
          <Checkbox
            label="Initiate local psychiatric admission process"
            model="data.level_of_risk_and_interventions.high_risk_triage.initiate_local_psychiatric_admission_process"
          />
          <Checkbox
            label="Stay with patient until transfer to higher level of care is complete"
            model="data.level_of_risk_and_interventions.high_risk_triage.stay_with_patient_until_transfer"
          />
          <Checkbox
            label="Follow-up and document outcome of emergency psychiatric evaluation"
            model="data.level_of_risk_and_interventions.high_risk_triage.follow_up_and_document_outcome"
          />
        </CheckboxGroup>
      </FormSection>

      <FormSection heading={<Flex alignItems="center">{circle('orange')} Moderate Suicide Risk</Flex>} layout="vertical" maxWidth={570}>
        <RadioGroup model="data.level_of_risk_and_interventions.moderate_risk" layout="vertical-dense">
          <Radio
            label={
              <>
                Suicidal ideation with method,{' '}
                <strong>
                  <u>WITHOUT plan, intent or behavior in past month</u>
                </strong>{' '}
                (C-SSRS Suicidal Ideation #3)
              </>
            }
            value="suicidal_ideation_without_plan_in_past_month"
          />
          <Radio
            label="Suicidal behavior more than 3 months ago (C-SSRS Suicidal Behavior Lifetime)"
            value="suicidal_behavior_more_than_three_months_ago"
          />
          <Radio label="Multiple risk factors and few protective factors" value="multiple_risk_factors_and_few_protective_factors" />
        </RadioGroup>

        <CheckboxGroup label="Triage" layout="vertical" trueIcon="tick" falseIcon="cross" falseStyle="faded">
          <Checkbox
            label="Directly address suicide risk, implementing suicide prevention strategies"
            model="data.level_of_risk_and_interventions.moderate_risk_triage.directly_address_suicide_risk"
          />
          <Checkbox label="Develop Safety Plan" model="data.level_of_risk_and_interventions.moderate_risk_triage.develop_safety_plan" />
        </CheckboxGroup>
      </FormSection>

      <FormSection heading={<Flex alignItems="center">{circle('yellow')} Low Suicide Risk</Flex>} layout="vertical" maxWidth={570}>
        <RadioGroup model="data.level_of_risk_and_interventions.low_risk" layout="vertical-dense">
          <Radio
            label={
              <>
                Wish to die or Suicidal Ideation{' '}
                <strong>
                  <u>WITHOUT method, intent, plan or behavior</u>
                </strong>{' '}
                (C-SSRS Suicidal Ideation #1 or #2)
              </>
            }
            value="suicidal_ideation_without_method"
          />
          <Radio label="Modifiable risk factors and strong protective factors" value="modifiable_risk_factors" />
          <Radio label="No reported history of Suicidal Ideation or Behavior" value="no_reported_history" />
        </RadioGroup>

        <CheckboxGroup label="Triage" layout="vertical" trueIcon="tick" falseIcon="cross" falseStyle="faded">
          <Checkbox
            label="Discretionary Outpatient Referral"
            model="data.level_of_risk_and_interventions.low_risk_triage.discretionary_outpatient_referral"
          />
        </CheckboxGroup>

        <SmartTextarea useDictation label="Notes" model="data.level_of_risk_and_interventions.notes" />
      </FormSection>
    </Flex>
  </>
)

export default LevelOfRiskAndInterventions
