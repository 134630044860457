import React from 'react'
import { Route, useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'

import Alert from '@behavehealth/components/Alert'
import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import Button from '@behavehealth/components/Button'
import Page from '@behavehealth/components/Page'
import Portal from '@behavehealth/components/Portal'
import ReferralStatus from '@behavehealth/constructs/Referrals/ReferralStatus'
import SummonOverlay from '@behavehealth/components/SummonOverlay'

import { ReferralSentOverlay } from '@behavehealth/constructs/Referrals/ReferralSentOverlay'
import { useMockReferralsStore } from '@behavehealth/constructs/Referrals/useMockReferralsStore'

export const RootReferrals: React.FC = () => {
  const match = useRouteMatch()
  const { resource_id: clientId }: any = useParams()

  const [selectedId, setSelectedId] = React.useState<string | null>(null)

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
  })

  const data = useMockReferralsStore((state) => state.data)

  const columns = React.useMemo(
    () => [
      {
        title: 'Sent to Partner',
        model: 'sent_to.other_partner',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} value={value?.name} avatar="" to={`${match.url}/${data.id}`} />
        },
      },
      // {
      //   title: 'Status From',
      //   model: 'sent_from_status',
      //   width: 200,
      //   formatValue: ({ value }: any) => <ReferralStatus status={value} />,
      // },
      {
        title: 'Status',
        model: 'sent_to_status',
        width: 200,
        formatValue: ({ value }: any) => <ReferralStatus status={value} />,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
    ],
    [match.url],
  )

  if (!client) return null

  return (
    <>
      <Page
        title="Referrals"
        icon="referrals"
        actions={
          <>
            <SummonOverlay overlay={<ReferralSentOverlay dataID="new" initialData={{ client, client_id: client.id }} />}>
              <Button label="Send New Referral" glyph="check_out" type="primary" />
            </SummonOverlay>
          </>
        }
      >
        <Alert contrast type="warning" className="mb-4">
          <div className="flex flex-nowrap items-center">
            <div className="text-[1.2rem] mr-2">🚧</div>
            <div>
              <h4>Feature Under Development</h4>
              <div>
                The Referrals feature you're currently viewing is still in active development. While you can explore this page, please note
                that it's not yet functional.
              </div>
            </div>
          </div>
        </Alert>

        <DataTable
          asCard
          title={`Referrals`}
          icon="referrals"
          columns={columns}
          data={data}
          meta={{ count: size(data), page: 1, prev: null, next: null, pages: 1 }}
        />
      </Page>

      {/* <SummonOverlay
        isOpen={!!selectedId}
        onClose={() => {
          setSelectedId(null)
        }}
        overlay={<ReferralSentOverlay dataID={selectedId} />}
      /> */}

      <Portal type="z90">
        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <Route exact path={`${match.path}/:id`}>
            {(routeProps) => {
              return <ReferralSentOverlay dataID={routeProps.match?.params?.id} />
            }}
          </Route>
        </AnimatedSwitch>
      </Portal>
    </>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    overflow: 'hidden',
  },

  content: {
    padding: '1rem',
    // overflowY: 'auto',
    // display: 'grid',
    // gridTemplateRows: '100%',
    // gridTemplateColumns: '100%',
  },
}

export const Referrals = withPageError(RootReferrals)
