import React from 'react'
// import Observer from '@researchgate/react-intersection-observer'

import Avatar from './Avatar'
import Grid from './Grid'
import Flex from './Flex'
import Wrapper from './Wrapper'
import { H2, Text } from './Typography'
import Dropdown from './Dropdown'
import Divider from './Divider'

const PageProfile = ({
  avatar,
  actions,
  before,
  content,
  description,
  isEditable,
  more,
  record,
  status,
  subtitle,
  title,
  beforeActions,
  underActions,
  titleBefore,
}: any) => {
  // const [isProfileVisible, setIsProfileVisible] = React.useState(false)

  return (
    <div>
      <Wrapper>
        {status && (
          <div css={{ marginBottom: '0.35rem' }}>
            <Flex gap={4}>{status}</Flex>
          </div>
        )}

        {before && <div css={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>{before}</div>}

        <div css={{ marginBottom: '0.5rem' }}>
          <Flex gap={12} nowrap alignItems="center">
            <Avatar
              magnify
              initials={title}
              isEditable={isEditable}
              size={72}
              src={avatar || record?.avatar}
              recordId={record?.id}
              recordType={record?.type}
            />

            <Grid gap={4}>
              {titleBefore}
              <H2>{title}</H2>
              {description && <Text>{description}</Text>}
              {subtitle}
            </Grid>
          </Flex>
        </div>

        <Flex stretchChildrenX gap={8}>
          {beforeActions}

          {actions && (
            <Dropdown label={more ? 'More Actions' : 'Actions'} buttonType="primary">
              {actions}
            </Dropdown>
          )}

          {content}

          {underActions}
        </Flex>
      </Wrapper>

      <Divider css={{ marginTop: '0', marginBottom: '0' }} />
    </div>
  )
}

// <Observer
//   threshold={0.3}
//   onChange={({ isIntersecting }) => setIsProfileVisible(isIntersecting)}
// >
// </Observer>

PageProfile.defaultProps = {
  isEditable: true,
}

export default PageProfile
