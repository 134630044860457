import React from 'react'
import { useParams } from 'react-router-dom'
import produce from 'immer'

import { useUpdate, getPrefix } from '../../hooks/useNewAPI'

import Dropdown from '../Dropdown'
import DropdownItem from '../DropdownItem'
import Glyph from '../Glyph'
import Loader from '../Loader'
import SmartStatus from '../SmartStatus'

const statuses = {
  todo: {
    label: 'To-Do',
    color: 'blue',
  },
  done: {
    label: 'Done',
    color: 'green',
  },
  in_progress: {
    label: 'In Progress',
    color: 'orange',
  },
  on_hold: {
    label: 'On Hold',
    color: 'gray',
  },
  urgent: {
    label: 'Urgent',
    color: 'red',
  },
}

const Status = (props: any) => {
  const { taskID, claimID, status } = props
  const { resource, resource_type, resource_id }: any = useParams()

  const resourceType = resource || resource_type

  const { mutate, isLoading } = useUpdate({
    name: 'insurance-claim-task',
    url: `/insurance_claim_tasks/${taskID}`,
    onSuccess: ({ data }: any, _variables: any, queryClient: any) => {
      const prefix = getPrefix()

      // invalidate single insurance claim task
      if (resourceType === 'clients' && resource_id) {
        queryClient.invalidateQueries([prefix, 'client', resource_id, 'insurance-claim-task', taskID])
      }

      // set new status in all insurance claim tasks data
      queryClient.setQueryData([prefix, 'insurance-claims', claimID, 'insurance-claim-tasks'], (oldData: any) => {
        if (!oldData?.data) return

        const index = oldData.data.findIndex((o) => o.id === data.id)
        if (index === -1) return

        const newData = produce(oldData, (draft: any) => {
          draft.data[index].status = data.status
        })

        return newData
      })
    },
  })

  if (!status) return null

  return (
    <Dropdown
      trigger={
        <SmartStatus
          before={isLoading && <Loader color="text" size={12} css={styles.loader} />}
          statuses={statuses}
          status={status}
          after={<Glyph glyph="triangle_down" color="text" size={10} css={styles.glyph} />}
        />
      }
    >
      {Object.keys(statuses).map((statusKey) => {
        const statusConfig: any = statuses[statusKey]

        return (
          <DropdownItem
            key={statusKey}
            label={statusConfig.label}
            isActive={status === statusKey}
            css={styles.dropdownItem}
            onClick={() => {
              mutate({ status: statusKey })
            }}
          />
        )
      })}
    </Dropdown>
  )
}

const styles = {
  glyph: {
    marginRight: '0.5rem',
  },

  dropdownItem: {
    minHeight: 32,
  },

  loader: {
    marginLeft: '0.3rem',
  },
}

export default Status
