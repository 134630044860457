import React from 'react'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'

import { Page, Grid, Tab, TabList, Tabs, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import Organization from './organization'
import Client from './client'

const pageConfig = {
  feature: 'financials',
  help: <HelpTagIframe id="financials" />,
  marketingID: 'financials',
}

const Financials = () => {
  const { url, path } = useRouteMatch()

  return (
    <Page breakpoint="0" {...pageConfig}>
      <Grid gap={16}>
        <Tabs>
          <TabList css={styles.tabList}>
            <Tab label="Organization" to={`${url}/organization`} />
            <Tab label="Client" to={`${url}/client`} />
          </TabList>
        </Tabs>

        <Switch>
          <Route path={`${path}/organization`} component={Organization} />
          <Route path={`${path}/client`} component={Client} />

          <Redirect exact from={url} to={`${url}/organization`} />
        </Switch>
      </Grid>
    </Page>
  )
}

const styles = {
  tabList: {
    marginTop: '-1rem',
  },
}

export default withPageError(withMarketing(Financials, pageConfig))
