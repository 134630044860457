import React from 'react'
import { v4 as uuid } from 'uuid'
import produce from 'immer'

import map from 'lodash/map'
import remove from 'lodash/remove'
import size from 'lodash/size'

import { DEFAULT_EMPTY_VALUE } from '../../../../utils/constants'

import { withFormContext } from '../../context'
import { validate } from '../../validators'

import Grid from '../../../Grid'
import SelectorBase from '../SelectorBase'
import SelectorInput from '../SelectorInput'
import SmartPortal from '../../../SmartPortal'
import Value from '../../../Value'

import MultiSelectorMenu from './MultiSelectorMenu'
import MultiObjectItem from './MultiObjectItem'

import { LINKS, getDefaultGroupBy } from '../SelectorBase'

class MultiObjectSelector extends SelectorBase {
  constructor(props) {
    super(props)

    let errors = []
    let vs = props.validations

    let object = props.value || props.form?.getInitialInputFieldValue(props.model)
    if (JSON.stringify(object) === '{}') object = [] // if the object is empty, force it to array

    // if the initial model is required, flag the objects as such
    if (props.initialModelRequired && object) {
      object = produce(object, (draft: any) => {
        for (let i = 0; i < draft.length; i++) if (draft[i]) draft[i].isRequired = true
      })
    }

    if (vs) errors = validate(object, vs)

    this.state = {
      type: 'OBJECT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      object: object,
      value: object,
      apiData: props.apiData ? props.apiData : [],
      isLoading: false,
      isRelations: props.isRelations,
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValidations: props.validations,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      object: object,
      value: object,
      apiData: props.apiData ? props.apiData : [],
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.updateType = 'DATA'
    this.referenceRef = null
    this.fieldRef = null

    this.eventsQueue = []
    this.processedEventsQueue = []
  }

  /*
    CUSTOM FUNCTIONS
  */
  processChangeValue = (items: any) => {
    if (this.props.isDisabled) return

    const newState = produce(this.state, (draft: any) => {
      draft.object = items
      draft.value = items
      draft.isOpen = false

      draft.isDirty = true
      draft.isPristine = false
    })

    this.setState({
      object: newState.object,
      value: newState.value,
      isOpen: newState.isOpen,
      isDirty: newState.isDirty,
      isPristine: newState.isPristine,
    })

    return newState
  }

  /*
    RENDER
  */
  renderEdit = () => {
    const { icon, isDisabled } = this.props

    return (
      <SelectorInput
        icon={icon}
        onOpen={this.open}
        onClear={() => {
          this.changeValue(null)
          if (this.props.onClear) this.props.onClear(this.state)
        }}
        isDisabled={isDisabled}
        placeholder={this.props.placeholder}
      />
    )
  }

  renderSelected = () => {
    const { showAvatars, selectTitle, selectDescription, selectGraphic, selectedDescriptionsVisible } = this.props
    const { object } = this.state

    return (
      size(object) > 0 && (
        <div className="!mt-1">
          {object.map((item: any) => (
            <MultiObjectItem
              key={item?.id}
              label={selectTitle?.(item) || item?.name}
              description={selectedDescriptionsVisible && selectDescription?.(item)}
              graphic={selectGraphic?.(item)}
              avatar={showAvatars ? item?.avatar || '' : item?.avatar}
              onClear={() => {
                const newState = produce(this.state, (draft: any) => {
                  remove(draft.object, { id: item?.id })
                  if (draft.apiData && draft.apiData[item?.id]) draft.apiData[item?.id].checked = false
                })

                this.changeValue(newState.object)
              }}
              isDisabled={item?.isRequired}
            />
          ))}
        </div>
      )
    )
  }

  renderReadOnly = () => {
    const { object } = this.state
    const { showAvatars, selectTitle, selectDescription, selectGraphic, disableLink, selectedDescriptionsVisible } = this.props

    if (object && object.length > 0) {
      return (
        <Grid gap={10}>
          {object.map((item: any) => {
            const graphic = selectGraphic?.(item)

            return (
              <Value
                avatarMagnify
                key={item.id}
                value={
                  <>
                    {selectTitle?.(item) || item?.name}
                    {selectedDescriptionsVisible && selectDescription?.(item)}
                  </>
                }
                avatarInitials={selectTitle?.(item) || item?.name}
                graphic={selectGraphic?.(item)}
                // description={selectDescription?.(item) || item?.description}
                icon={!graphic && this.props.icon}
                avatar={showAvatars ? item.avatar || '' : item.avatar}
                avatarMagnifyScale={2.5}
                link={!disableLink && LINKS[this.props.type]?.(item)}
              />
            )
          })}
        </Grid>
      )
    } else return DEFAULT_EMPTY_VALUE
  }

  renderSelector = () => {
    const { icon, searchKeys, type, selectTitle, selectDescription, selectGraphic, showAvatars, emptyActions, groupBy } =
      this.props
    const { isLoading, apiData, object } = this.state

    const groupByConfig = groupBy || getDefaultGroupBy(type)

    return (
      <SmartPortal useReferenceWidth portal="selector" position="bottom-start">
        <MultiSelectorMenu
          icon={icon}
          type={type}
          isLoading={isLoading}
          searchKeys={searchKeys}
          apiData={apiData}
          selectedData={object}
          showAvatars={showAvatars}
          selectTitle={selectTitle}
          selectGraphic={selectGraphic}
          selectDescription={selectDescription}
          emptyActions={emptyActions}
          onSelect={this.changeValue}
          onClose={this.close}
          groupBy={groupByConfig}
        />
      </SmartPortal>
    )
  }
}

MultiObjectSelector.defaultProps = {
  showAs: 'inline',
  showAvatars: true,
  prefetch: false,
  searchKeys: ['name', 'description'],
  isEditable: true,
  isDisabled: false,
  initialModelRequired: false,
  layout: 'vertical-dense',
  isOpen: false,
  isRelations: true,
  isPolymorphic: false,
  isNested: false,
  validateOn: 'blur-change',
}

export default withFormContext(MultiObjectSelector)
