import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'
import startCase from 'lodash/startCase'
import pluralize from 'pluralize'
import { DateTime } from 'luxon'

import { ICONS } from '../../../../theme'
import { getFirstValueFromMap, usDate, DT, daysToWords } from '../../../../utils/functions'
import { RECURRING_DAYS, RECURRING_FREQUENCIES } from '../../../../utils/recurrence'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import { niceOccurrenceText } from './utils'

import Alert from '../../../Alert'
import Button from '../../../Button'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import Grid from '../../../Grid'
import Overlay from '../../../Overlay'
import Section from '../../../Section'

import AmountInput from '../../../Forms/AmountInput'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import DateInput from '../../../Forms/DateInput'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Option from '../../../Forms/Option'
import Input from '../../../Forms/Input'
import PreviewRecurringChargesSection from './components/PreviewRecurringChargesSection'
import Select from '../../../Forms/Select'
import Textarea from '../../../Forms/Textarea'
import Radio from '../../../Forms/Radio'
import RadioGroup from '../../../Forms/RadioGroup'
import ContextShow from '../../../Forms/ContextShow'

import { OverlayBase, defaultMapDispatchToProps, defaultMapStateToProps } from '../OverlayBase'

import ChargePayersSection from './components/ChargePayersSection'

const FREQUENCIES = {
  weekly: 'recurring_weekly_due_day',
  biweekly: 'recurring_biweekly_due_day',
  monthly: 'recurring_monthly_due_day',
}

class AutoRepeatingChargeOverlay extends OverlayBase {
  renderHeader = () => <Overlay.Header icon="financials" title="Auto-Repeating Charge" />

  renderContent = () => {
    const { $editable, formData } = this.state
    const { record, current, timezone } = this.props

    const service = record?.service
    const employees = service?.employees
    const organizations = service?.organizations

    const hasEmployees = size(employees) > 0
    const hasOrganizations = size(organizations) > 0

    const dayOfNextRecurrence = formData && formData?.transactioned_at && formData?.end_in_months && DT(formData?.transactioned_at)
    const hasPayers = size(record.subcharges) > 0

    return (
      <Overlay.Content>
        <Form
          useFullModel
          getForm={this.form}
          timezone={timezone}
          isEditable={$editable}
          initialModel={record}
          onValidationUpdate={this.onValidationUpdate}
          onUpdate={(value: any) => {
            this.setState({ formData: value })
          }}
        >
          {/* General */}
          <Section>
            <FormSection>
              <ObjectSelector
                isEditable={false}
                model="service"
                label="Service"
                type="client_services"
                icon={ICONS.financials}
                selectTitle={(data) => data.name}
              />

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a charge name',
                  },
                }}
              />

              {!formData?.is_custom_amount && (
                <AmountInput
                  isEditable={false}
                  label="Amount"
                  suffix={niceOccurrenceText(
                    service?.is_recurring,
                    formData?.use_custom_frequency ? formData?.recurring_frequency : service?.recurring_frequency,
                    formData?.use_custom_frequency
                      ? formData?.[FREQUENCIES[formData?.recurring_frequency]]
                      : service?.[FREQUENCIES[service?.recurring_frequency]],
                  )}
                  value={service?.amount}
                />
              )}

              {formData?.is_custom_amount && (
                <AmountInput
                  debounce={300}
                  label="Custom Amount"
                  model="amount"
                  suffix={niceOccurrenceText(
                    service?.is_recurring,
                    formData?.use_custom_frequency ? formData?.recurring_frequency : service?.recurring_frequency,
                    formData?.use_custom_frequency
                      ? formData?.[FREQUENCIES[formData?.recurring_frequency]]
                      : service?.[FREQUENCIES[service?.recurring_frequency]],
                  )}
                  defaultValue={service?.amount}
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                    numericality: {
                      greaterThan: 0,
                      message: 'Please enter an amount',
                    },
                  }}
                />
              )}

              {$editable && (
                <Flex gap="1rem" stretchChildrenX>
                  <Checkbox label="Set Custom Amount" model="is_custom_amount" />
                  <Checkbox label="Set Custom Frequency" model="use_custom_frequency" />
                </Flex>
              )}

              {formData?.use_custom_frequency && (
                <>
                  <RadioGroup
                    label="Custom Repeating Frequency"
                    model="recurring_frequency"
                    layout="vertical-dense"
                    defaultValue={record?.service?.recurring_frequency || 'monthly'}
                    validations={{
                      presence: {
                        message: 'Please select a billing frequency',
                      },
                    }}
                  >
                    {Object.keys(RECURRING_FREQUENCIES).map((key) => (
                      <Radio key={key} label={RECURRING_FREQUENCIES[key].label} value={key} />
                    ))}
                  </RadioGroup>

                  <ContextShow when="recurring_frequency" is="weekly">
                    <Select
                      asNumber
                      label="Weekly Due Day"
                      model="recurring_weekly_due_day"
                      defaultValue={record?.service?.recurring_weekly_due_day || 0}
                      validations={{
                        presence: {
                          message: 'Please select a due day',
                        },
                      }}
                    >
                      {RECURRING_DAYS.weekly.map((key, idx) => (
                        <Option key={idx} label={`Every ${key}`} value={idx} />
                      ))}
                    </Select>
                  </ContextShow>

                  <ContextShow when="recurring_frequency" is="biweekly">
                    <Select
                      asNumber
                      label="Biweekly Due Day"
                      model="recurring_biweekly_due_day"
                      defaultValue={record?.service?.recurring_biweekly_due_day || 0}
                      validations={{
                        presence: {
                          message: 'Please select a due day',
                        },
                      }}
                    >
                      {RECURRING_DAYS.biweekly.map((key, idx) => (
                        <Option key={idx} label={`Every other ${key}`} value={idx} />
                      ))}
                    </Select>
                  </ContextShow>

                  <ContextShow when="recurring_frequency" is="monthly">
                    <Select
                      asNumber
                      label="Monthly Due Day"
                      model="recurring_monthly_due_day"
                      defaultValue={record?.service?.recurring_monthly_due_day || 1}
                      validations={{
                        presence: {
                          message: 'Please select a due day',
                        },
                      }}
                    >
                      {RECURRING_DAYS.monthly.map((key, idx) => (
                        <Option key={idx} label={key} value={idx + 1} />
                      ))}
                    </Select>
                  </ContextShow>
                </>
              )}

              <FormSection>
                <Flex gap={16}>
                  <DateInput
                    defaultToNow
                    label="Next Renewal Date"
                    model="transactioned_at"
                    validations={{
                      datetime: {
                        earliest: DateTime.local().minus({ days: 1 }),
                        message: 'Please enter a future date',
                      },
                      presence: {
                        message: 'Please enter a due date',
                      },
                    }}
                  />
                  <Select
                    defaultValue={1}
                    label="Covered Time Period"
                    model="end_in_months"
                    layout="vertical-dense"
                    validations={{
                      presence: {
                        message: 'Please select an end time',
                      },
                    }}
                  >
                    <Option label="1 Month" value={1} />
                    <Option label="2 Months" value={2} />
                    <Option label="3 Months" value={3} />
                    <Option label="6 Months" value={6} />
                    <Option label="9 Months" value={9} />
                    <Option label="12 Months" value={12} />
                  </Select>
                </Flex>
              </FormSection>

              {hasEmployees && (
                <ObjectSelector
                  model="employees"
                  label="Staff Member"
                  type="employees"
                  apiData={employees}
                  icon={ICONS.employees}
                  selectTitle={(data: any) => data.name}
                  selectDescription={(data: any) => startCase(data.position)}
                />
              )}

              {hasOrganizations && (
                <ObjectSelector
                  model="organizations"
                  label="Organization"
                  type="organizations"
                  apiData={organizations}
                  icon={ICONS.organizations}
                  selectTitle={(data: any) => data.name}
                  selectDescription={() => null}
                />
              )}

              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section title="Automatic Collection">
            <FormSection maxWidth="100%">
              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded">
                <Checkbox
                  label="Use Automatic Payment Collection"
                  model="should_auto_charge"
                  description="Attempt to collect from the Payment Method selected below"
                />
              </CheckboxGroup>

              {formData?.should_auto_charge && (
                <ObjectSelector
                  icon="billing"
                  type="client.payment_methods"
                  label="Payment Method to Charge"
                  model="payment_method"
                  dependentValue={current?.id}
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => data?.reference?.name}
                  validations={{
                    presence: {
                      message: 'Please select a Payment Method to use',
                    },
                  }}
                />
              )}
            </FormSection>
          </Section>

          <Divider />

          {hasPayers && (
            <>
              <ChargePayersSection subcharges={record.subcharges} />
              <Divider />
            </>
          )}

          <Section title="Renewal & Cancellation">
            <Grid gap="0.75rem">
              <Alert glyph="date" type="positive">
                This service will renew on <b>{usDate(dayOfNextRecurrence)}</b> <i>({daysToWords(dayOfNextRecurrence)})</i> and generate the
                next batch of charges (see below) for {formData?.end_in_months} {pluralize('month', parseInt(formData?.end_in_months))}.
              </Alert>

              <Alert glyph="circle_error" type="negative">
                Cancel the recurring service before <b>{DT(dayOfNextRecurrence)?.toFormat('ccc, MMM dd')}</b> to stop generating the next
                batch of charges.
              </Alert>
            </Grid>
          </Section>

          <PreviewRecurringChargesSection
            usingCustomFrequency={formData?.use_custom_frequency}
            from={formData?.transactioned_at}
            endIn={formData?.end_in_months}
            serviceID={service?.id}
            timezone={timezone}
            amount={formData?.is_custom_amount ? formData?.amount : service?.amount}
            onUpdate={(dates) => this.setState({ recurring_dates: dates })}
            onClear={() => this.setState({ recurring_dates: [] })}
          />

          {/* {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline recordID={record.id} recordType={record.type} />
              </Section>
            </>
          )} */}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save Recurring Service"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={this.save}
              isLoading={this.props.loading}
              isDisabled={this.state.isInvalid}
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Recurring Service"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="ledger.edit"
            />

            <DeleteDialog
              title="Cancel Recurring Service?"
              message="Are you sure you want to cancel this recurring service? This action cannot be undone."
              yesLabel="Yes, Cancel Service"
              onYes={this.delete}
            >
              <Button
                fullWidth
                glyph="circle_error"
                label="Cancel Service"
                color="red"
                isLoading={this.props.loading}
                permission="ledger.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state: any, props: any) => ({
  ...defaultMapStateToProps(state, props.match, 'financial_transactions'),
  financial_plan: getFirstValueFromMap(state.data.financial_plan?.data),
})

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(AutoRepeatingChargeOverlay))
