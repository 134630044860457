import React from 'react'
import size from 'lodash/size'

import { H3 } from '../../../../Typography'
import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import Value from '../../../../Value'

const defaultData = {
  frequency: 0,
  duration: 0,
  controllability: 0,
  deterrents: 0,
  reasons_for_ideation: 0,
}

const parseNumber = (n: any) => (isNaN(n) ? 0 : Number.isInteger(n) ? parseInt(n) : 0)

const ThoughtsPlansSuicidalIntent = ({ data, hideNotes }) => {
  const [score, setScore] = React.useState(data || defaultData)
  const finalScore = size(score) && Object.values(score).reduce((a, b) => parseNumber(a) + parseNumber(b))

  return (
    <FormSection layout="vertical" maxWidth={570}>
      <RadioGroup
        label="Frequency"
        description="How many times has the client had these thoughts?"
        model="data.thoughts_plans_and_suicidal_intent.frequency"
        layout="vertical-dense"
        onUpdate={(state) => setScore((o: any) => ({ ...o, frequency: parseInt(state.value) }))}
      >
        <Radio label="1 = Less than once a week" value={1} />
        <Radio label="2 = Once a week" value={2} />
        <Radio label="3 = 2-5 times in week" value={3} />
        <Radio label="4 = Daily or almost daily" value={4} />
        <Radio label="5 = Many times each day" value={5} />
      </RadioGroup>

      <RadioGroup
        label="Duration"
        description="When the client has these thoughts, how long do they typically last?"
        model="data.thoughts_plans_and_suicidal_intent.duration"
        layout="vertical-dense"
        onUpdate={(state) => setScore((o) => ({ ...o, duration: parseInt(state.value) }))}
      >
        <Radio label="1 = Fleeting - few seconds or minutes" value={1} />
        <Radio label="2 = Less than 1 hour/some of the time" value={2} />
        <Radio label="3 = 1-4 hours/a lot of time" value={3} />
        <Radio label="4 = 4-8 hours/most of day" value={4} />
        <Radio label="5 = More than 8 hours/persistent or continuous" value={5} />
      </RadioGroup>

      <RadioGroup
        label="Controllability"
        description="Can the client stop thinking about killing themselves or wanting to die if they want to?"
        model="data.thoughts_plans_and_suicidal_intent.controllability"
        layout="vertical-dense"
        onUpdate={(state) => setScore((o) => ({ ...o, controllability: parseInt(state.value) }))}
      >
        <Radio label="1 = Easily able to control thoughts" value={1} />
        <Radio label="2 = Can control thoughts with little difficulty" value={2} />
        <Radio label="3 = Can control thoughts with some difficulty" value={3} />
        <Radio label="4 = Can control thoughts with a lot of difficulty" value={4} />
        <Radio label="5 = Unable to control thoughts" value={5} />
        <Radio label="0 = Does not attempt to control thoughts" value={0} />
      </RadioGroup>

      <RadioGroup
        label="Deterrents"
        description="Are there things - anyone or anything [e.g., family, religion, pain of death] - that stopped the client from wanting to die or acting on thoughts of suicide?"
        model="data.thoughts_plans_and_suicidal_intent.deterrents"
        layout="vertical-dense"
        onUpdate={(state) => setScore((o) => ({ ...o, deterrents: parseInt(state.value) }))}
      >
        <Radio label="1 = Deterrents definitely stopped you from attempting suicide" value={1} />
        <Radio label="2 = Deterrents probably stopped you" value={2} />
        <Radio label="3 = Uncertain that deterrents stopped you" value={3} />
        <Radio label="4 = Deterrents most likely did not stop you" value={4} />
        <Radio label="5 = Deterrents definitely did not stop you" value={5} />
        <Radio label="0 = Does not apply" value={0} />
      </RadioGroup>

      <RadioGroup
        label="Reasons for Ideation"
        description="What sort of reasons did the client have for thinking about wanting to die or killing themselves? Was it to end the pain or stop the way they were feeling (in other words, they couldn't go on living with this pain or how they were feeling), or was it to get attention, revenge, or a reaction from others? Or both?"
        model="data.thoughts_plans_and_suicidal_intent.reasons_for_ideation"
        layout="vertical-dense"
        onUpdate={(state) => setScore((o) => ({ ...o, reasons_for_ideation: parseInt(state.value) }))}
      >
        <Radio label="1 = Completely to get attention, revenge or a reaction from other" value={1} />
        <Radio label="2 = Mostly to get attention, revenge or a reaction from others" value={2} />
        <Radio label="3 = Equally to get attention, revenge or a reaction from others and to end/stop the pain" value={3} />
        <Radio label="4 = Mostly to end or stop the pain (you couldn’t go on living with the pain or how you were feeling)" value={4} />
        <Radio label="5 = Completely to end or stop the pain (you couldn’t go on living with the pain or how you were feeling)" value={5} />
        <Radio label="0 = Does not apply" value={0} />
      </RadioGroup>

      <H3>Total Score</H3>
      <Value value={`Score: ${finalScore || '–'}`} />

      {!hideNotes && <SmartTextarea useDictation label="Notes" model="data.thoughts_plans_and_suicidal_intent.notes" />}
    </FormSection>
  )
}

export default ThoughtsPlansSuicidalIntent
