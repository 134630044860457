import React from 'react'
import pluralize from 'pluralize'

import { COLORS, SHADOW } from '../../theme'
import { usDate } from '../../utils/functions'

import Button from '../Button'
import ButtonGroup from '../ButtonGroup'
import Flex from '../Flex'
import Tooltip from '../Tooltip'
import Glyph from '../Glyph'
import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu'

import { MODES } from './CalendarBase'

const CalendarHeader = (props: any) => {
  const { currentDate, setCurrentDate, isLoading, mode, onNext, onPrev, onToday, setMode, setView, today, view, desktopBreakpoint } = props

  const currentDateString = React.useMemo(() => usDate(currentDate), [currentDate])
  const isToday = currentDateString === usDate(today)

  const quickDates = React.useMemo(() => {
    const yesterday = today.minus({ days: 1 })
    const isYesterday = currentDateString === usDate(yesterday)

    const lastWeek = today.minus({ weeks: 1 })
    const isLastWeek = currentDateString === usDate(lastWeek)

    const lastMonth = today.minus({ months: 1 })
    const isLastMonth = currentDateString === usDate(lastMonth)

    const tomorrow = today.plus({ days: 1 })
    const isTomorrow = currentDateString === usDate(tomorrow)

    const nextWeek = today.plus({ weeks: 1 })
    const isNextWeek = currentDateString === usDate(nextWeek)

    const nextMonth = today.plus({ months: 1 })
    const isNextMonth = currentDateString === usDate(nextMonth)

    return {
      yesterday,
      isYesterday,

      lastWeek,
      isLastWeek,

      lastMonth,
      isLastMonth,

      tomorrow,
      isTomorrow,

      nextWeek,
      isNextWeek,

      nextMonth,
      isNextMonth,
    }
  }, [today, currentDateString])

  return (
    <header css={styles.header}>
      <div css={styles.headerLeft({ desktopBreakpoint })}>
        <Flex centerY nowrap stretchSelf gap={8}>
          <div css={styles.heading}>
            <span css={styles.month}>{currentDate.toFormat('LLLL')}</span>
            <span css={styles.year}> {currentDate.toFormat('kkkk')}</span>
          </div>

          <div className="flex flex-nowrap !ml-3">
            <DropdownMenu
              trigger={
                <div>
                  <Button
                    label="Jump to"
                    color="text"
                    size={100}
                    glyph="quick_view"
                    glyphSize="1em"
                    glyphColor={COLORS.blue}
                    css={styles.navButton}
                    className="mr-1"
                    after={<Glyph glyph="triangle_down" size={9} />}
                  />
                </div>
              }
            >
              {mode === 'months' && (
                <DropdownMenuItem
                  label="Last Month"
                  isActive={quickDates.isLastMonth}
                  onClick={() => setCurrentDate(quickDates.lastMonth)}
                />
              )}

              {mode === 'weeks' && (
                <DropdownMenuItem label="Last Week" isActive={quickDates.isLastWeek} onClick={() => setCurrentDate(quickDates.lastWeek)} />
              )}

              <DropdownMenuItem label="Yesterday" isActive={quickDates.isYesterday} onClick={() => setCurrentDate(quickDates.yesterday)} />

              <DropdownMenuItem label="Today" isActive={isToday} onClick={() => setCurrentDate(today)} />

              <DropdownMenuItem label="Tomorrow" isActive={quickDates.isTomorrow} onClick={() => setCurrentDate(quickDates.tomorrow)} />

              {mode === 'weeks' && (
                <DropdownMenuItem label="Next Week" isActive={quickDates.isNextWeek} onClick={() => setCurrentDate(quickDates.nextWeek)} />
              )}

              {mode === 'months' && (
                <DropdownMenuItem
                  label="Next Month"
                  isActive={quickDates.isNextMonth}
                  onClick={() => setCurrentDate(quickDates.nextMonth)}
                />
              )}
            </DropdownMenu>

            <Tooltip content={`Previous ${pluralize.singular(mode)}`}>
              <Button
                hideLabel
                color="text"
                size={100}
                glyph="chevron_left"
                glyphSize="1em"
                onClick={onPrev}
                css={styles.navButton}
                className="!rounded-[5px_0_0_5px]"
                isDisabled={isLoading}
              />
            </Tooltip>

            <Tooltip content={`Next ${pluralize.singular(mode)}`}>
              <Button
                hideLabel
                color="text"
                size={100}
                glyph="chevron_right"
                glyphSize="1em"
                onClick={onNext}
                css={styles.navButton}
                className="!rounded-[0_5px_5px_0] -ml-[1px]"
                isDisabled={isLoading}
              />
            </Tooltip>
          </div>
        </Flex>
      </div>

      <Flex centerY justifyContent="space-between" gap="1rem" stretchSelf css={styles.headerRight}>
        <ButtonGroup>
          <Button
            label="Month"
            color="text"
            size={200}
            onClick={() => setMode(MODES.months)}
            type={mode === MODES.months ? 'primary' : 'default'}
            isDisabled={isLoading}
          />
          <Button
            label="Week"
            color="text"
            size={200}
            onClick={() => setMode(MODES.weeks)}
            type={mode === MODES.weeks ? 'primary' : 'default'}
            isDisabled={isLoading}
          />
        </ButtonGroup>

        <ButtonGroup>
          <Button
            label="Calendar"
            color="text"
            size={200}
            onClick={() => setView('calendar')}
            type={view === 'calendar' ? 'primary' : 'default'}
            isDisabled={isLoading}
          />
          <Button
            label="Agenda"
            color="text"
            size={200}
            onClick={() => setView('agenda')}
            type={view === 'agenda' ? 'primary' : 'default'}
            isDisabled={isLoading}
          />
          <Button
            label="Table"
            color="text"
            size={200}
            onClick={() => setView('table')}
            type={view === 'table' ? 'primary' : 'default'}
            isDisabled={isLoading}
          />
        </ButtonGroup>
      </Flex>
    </header>
  )
}

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: SHADOW(2),
    paddingRight: '0.5rem',
    flexWrap: 'wrap',
  },

  headerLeft: ({ desktopBreakpoint }: any) => ({
    padding: '0.5rem',

    [`@media(min-width: ${desktopBreakpoint})`]: {
      width: 380,
      borderRight: `1px solid ${COLORS.divider}`,
    },
  }),

  headerRight: {
    padding: '0.5rem',
  },

  navButton: {
    minWidth: 26,
    minHeight: 26,

    svg: { margin: '0 !important' },
  },

  heading: {
    fontSize: '1.25rem',
    fontWeight: 400,
    marginRight: 'auto !important',
  },

  month: {
    fontWeight: 700,
  },

  year: {
    fontSize: '0.95em',
    color: COLORS.textMuted,
  },
}

export default CalendarHeader
