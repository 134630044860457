import React from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

import PlanInternalTemplatesTable from '../../components/Templates/tables/internal/PlanInternalTemplatesTable'
import { useGet, useCreate } from '../../hooks/useNewAPI'

export const PlansImportOverlay = () => {
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows]: any = React.useState([])

  const { data, isLoading: dataLoading }: any = useGet({
    name: ['internal_plans_templates'],
    url: `/internal_templates`,
    params: { category: 'product', subcategory: 'platform_plan' },
  })

  const { mutateAsync: importTemplates, isLoading: importLoading }: any = useCreate({
    name: ['products', { category: 'platform_plan' }],
    url: '/internal_templates/import',
    invalidate: 'products',
  })

  const isEmpty = size(data) === 0
  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    try {
      const ids = selectedRows.map((o: any) => o.original.id)
      await importTemplates({
        ids: ids,
        category: 'product',
        subcategory: 'platform_plan',
      })

      navigate(-1)
    } catch (errors) {
      console.debug(errors)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={90}>
      <Overlay.Header icon="certificate" title="Import Platform Plans from Catalog" />

      <Overlay.Content>
        <Section>
          <Card>
            <PlanInternalTemplatesTable title="Platform Plans" data={data} isLoading={isEmpty && dataLoading} onSelect={setSelectedRows} />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Platform Plans', selectedRowsCount)}` : 'Select Platform Plans to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={importLoading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
