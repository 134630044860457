import React from 'react'

import Alert from '../../../../components/Alert'

export const GroupEditor = () => {
  return (
    <>
      <Alert contrast small glyph="info">
        Add elements within this group to easily duplicate or move them around the form.
      </Alert>
    </>
  )
}
