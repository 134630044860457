import React from 'react'

import ContextShow from '../../../../Forms/ContextShow'
import DateInput from '../../../../Forms/DateInput'
import DateOfBirthInput from '../../../../Forms/DateOfBirthInput'
import FormSection from '../../../../Forms/FormSection'
import LevelOfCareSelect from '../../../../Forms/LevelOfCareSelect'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const GeneralInfo = ({ client, showAdmitDate, showAllergies, showSymptomology }) => (
  <FormSection layout="vertical">
    <DateOfBirthInput isEditable={false} label="Date of Birth" model="data.general_info.date_of_birth" value={client && client.dob} />

    <LevelOfCareSelect
      isEditable={false}
      label="Current Level of Care"
      model="data.general_info.level_of_care"
      value={client && client.level_of_care}
    />

    {showAdmitDate && (
      <DateInput
        label="Clinical Admit Date"
        model="data.general_info.admitted_at"
        default={client && client.current_admission && client.current_admission.admitted_at}
      />
    )}

    {showAllergies && (
      <>
        <RadioGroup layout="horizontal-dense" label="Does the client have any allergies?" model="data.general_info.has_allergies">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.general_info.has_allergies" is={true}>
          <SmartTextarea
            useDictation
            label="Please list what the client is allergic to and the corresponding reaction."
            model="data.general_info.allergies"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </>
    )}

    {showSymptomology && (
      <SmartTextarea
        useDictation
        label="Presenting Problem and Behavioral Health Issues (Symptomology)"
        model="data.general_info.problems_and_symptomology"
      />
    )}
  </FormSection>
)

GeneralInfo.defaultProps = {
  showAdmitDate: true,
  showAllergies: false,
  showSymptomology: false,
}

export default GeneralInfo
