import React from 'react'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'

import { useFormBuilder } from '../FormBuilder/useFormBuilder'

export const SubmissionFormVariableOverlay = ({ data, onClose, onSave }: any) => {
  const isNew = !data
  const form = React.useRef(null)

  const editFormVariable: any = useFormBuilder((state: any) => state.editFormVariable)
  const parseAllElementConfigVariables: any = useFormBuilder((state: any) => state.parseAllElementConfigVariables)

  const [variableValue, setVariableValue] = React.useState(data?.variable_value || '')

  const handleSave = async () => {
    const saveData = {
      ...data,
      variable_value: variableValue,
    }

    editFormVariable(saveData)

    await parseAllElementConfigVariables()

    onSave?.(saveData)
    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center">
      <Overlay.Header title={isNew ? 'Add Variable' : 'Edit Variable'} glyph="form_builder_smart_sections" />

      <Overlay.Content>
        <Form isCompact getForm={form} initialModel={data}>
          <Section>
            <FormSection>
              <Input isEditable={false} label="Name" model="name" />

              <Input isEditable={false} label="Variable Key" model="variable_key" />

              <Input
                label="Value"
                value={variableValue}
                onUpdate={({ value }) => {
                  setVariableValue(value)
                }}
                validations={{
                  presence: {
                    message: 'Please enter a variable value',
                  },
                }}
              />

              <Textarea isEditable={false} label="Description" model="description" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={isNew ? 'Add Variable' : 'Save Changes'}
          type="primary"
          color="green"
          size={300}
          onClick={handleSave}
          isDisabled={!variableValue}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
