import React from 'react'

import Alert from './Alert'
import { apiGet } from '../modules/api'
import { buildBaseUrl } from '../modules/axios'

const ServicesStatus = () => {
  const [servicesStatus, setServicesStatus] = React.useState(null)

  React.useEffect(() => {
    const checkStatus = async () => {
      // build urls for requests
      buildBaseUrl()

      // check services status
      const result = await apiGet({ url: `/apps/status/services` })
      setServicesStatus(result?.data?.status)
    }

    checkStatus()
  }, [])

  if (servicesStatus !== 'down') return null

  return (
    <Alert type="negative" glyph="info" css={topAlertStyles}>
      <a href="https://status.behavehealth.com" target="_blank">
        Service Outage. View updates here →
      </a>
    </Alert>
  )
}

const topAlertStyles = {
  display: 'flex',
  justifyContent: 'center',
  borderRadius: 0,
}

export default ServicesStatus
