import React from 'react'

import { COLORS } from '../theme'

import Link from './Link'
import NavLink from './NavLink'

class Box extends React.PureComponent {
  ref = React.createRef()

  render = () => {
    const {
      // Size
      fullWidth,
      height,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      width,

      // Margin
      margin,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,

      // Padding
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,

      // Flex
      alignContent,
      alignItems,
      flex,
      flexBasis,
      flexDirection,
      flexGrow,
      flexShrink,
      flexWrap,
      justifyContent,
      justifyItems,

      // Typography
      color,
      fontSize,
      textAlign,
      fontWeight,
      textTransform,

      // Other
      id,
      type,
      display,
      position,
      top,
      bottom,
      left,
      right,
      zIndex,
      overflow,
      opacity,
      background,
      border,
      borderTop,
      borderBottom,
      borderRadius,
      boxShadow,
      hoverCss,
      hover,
      getReference,

      // Grid
      gridTemplateColumns,
      gridTemplateRows,
      gridGap,
      gridAutoFlow,
      gridAutoRows,
      gridAutoColumns,
      rowGap,
      columnGap,

      is,
      to,
      css,
      href,
      onClick,
      onDoubleClick,
      onMouseOver,
      onMouseLeave,
      children,
      className,
      test, // used to see the bounds of the Box instance quickly
      testKey,
      ...rest
    } = this.props

    const styles = {
      // Size
      height,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      width: (fullWidth && '100%') || width,

      // Margin
      margin,
      marginTop,
      marginBottom,
      marginLeft,
      marginRight,

      // Padding
      padding,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,

      // Flex
      alignContent,
      alignItems,
      flex,
      flexBasis,
      flexDirection,
      flexGrow,
      flexShrink,
      flexWrap,
      justifyContent,
      justifyItems,

      // Typography
      color,
      fontSize,
      textAlign,
      fontWeight,
      textTransform,

      // Other
      display,
      position,
      top,
      bottom,
      left,
      right,
      zIndex,
      overflow,
      opacity,
      background,
      border: (test && '1px solid') || border,
      borderTop,
      borderBottom,
      borderRadius,
      boxShadow,

      // Grid
      gridTemplateColumns,
      gridTemplateRows,
      gridGap,
      gridAutoFlow,
      gridAutoRows,
      gridAutoColumns,
      rowGap,
      columnGap,

      ...(hoverCss && {
        '&:hover': {
          cursor: 'pointer',
          ...hoverCss,
        },
      }),

      ...(hover && { '&:hover': { background: COLORS.hover } }),
      ...css,
    }

    let Tag = is
    if (to) Tag = NavLink
    else if (href) Tag = Link

    if (getReference) getReference(this.ref)

    return (
      <Tag
        ref={this.ref}
        css={styles}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        className={className}
        id={id}
        type={type}
        data-test={testKey}
        to={to}
        href={href}
        {...rest}
      >
        {children}
      </Tag>
    )
  }
}

Box.defaultProps = {
  is: 'div',
}

export default Box
