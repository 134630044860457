import React from 'react'
import { lighten, transparentize } from 'polished'
import { useDraggable } from '@dnd-kit/core'
import clsx from 'clsx'
import isEqual from 'react-fast-compare'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import Glyph from '../../../components/Glyph'

import { ChildElementDroppable } from './ElementDragAndDrop'
import { ELEMENTS } from './elements'
import { useFormBuilder } from '../useFormBuilder'

export const BuilderElement = (props: any) => {
  const { children, elementId, draggingElementId, isDropDisabled, getRichTextEditor } = props

  const deleteElement: any = useFormBuilder((state: any) => state.deleteElement)
  const duplicateElement: any = useFormBuilder((state: any) => state.duplicateElement)
  const editElementConfig: any = useFormBuilder((state: any) => state.editElementConfig)
  const element: any = useFormBuilder((state: any) => state.allElements[elementId])
  const environment: any = useFormBuilder((state: any) => state.environment)
  const isBuilderEditable: any = useFormBuilder((state: any) => state.isEditable)
  const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)
  const setActiveElementId: any = useFormBuilder((state: any) => state.setActiveElementId)
  const setElementConfigValue: any = useFormBuilder((state: any) => state.setElementConfigValue)
  const variableValues: any = useFormBuilder((state: any) => state.variableValues)

  const ELEMENT = React.useMemo(() => ELEMENTS?.[element?.category], [element?.category])
  const Tag = React.useMemo(() => ELEMENT?.component, [ELEMENT])

  const isHidden = element?.config?.is_hidden
  const isActive = element?._isActive
  const isEditable = isBuilderEditable && !isPreviewMode

  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false)

  const renders = React.useRef(0)

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, isDragging } = useDraggable({
    id: elementId,
    data: {
      type: 'ELEMENT',
      elementId,
    },
    disabled: !isEditable,
  })

  if (!ELEMENT || !element || !Tag || isHidden) return null

  const isConditionalTrigger = element._isConditionalTrigger

  const rootClasses = clsx('relative', isActive && 'is-active')

  const elementClasses = clsx(
    'ELEMENT relative p-2',
    isEditable && !isActive && '[&>*]:pointer-events-none',
    isActive && '[&>*]:pointer-events-auto',
    isConditionalTrigger && 'is-conditional-trigger',
    isEditable && 'is-editable',
    isEditable && element?.config?.is_readonly_hidden && 'opacity-40',
    !isEditable && element?.config?.is_readonly_hidden && '!hidden',
    isDragging && 'opacity-50',
  )

  const hoverClasses = clsx(
    'HOVER_ELEMENT absolute -inset-0 opacity-0 hover:opacity-100 !pointer-events-auto rounded-[5px]',
    (isActive || isDragging) && 'is-active !pointer-events-none opacity-100',
  )

  const hideHover = !!props.hideHover || (draggingElementId && !isDragging)
  const hasChildren = size(element?.elements_order) > 0

  const editInputDefaultValue = ({ value: newDefaultValue }: any) => {
    // skip if values are the same
    if (isEqual(element?.config?.['default_value'], newDefaultValue)) return

    // update element's config
    editElementConfig({
      uuid: element.uuid,
      config: { default_value: newDefaultValue },
    })
  }

  const handleDuplicate = () => {
    duplicateElement(elementId)
  }

  const handleDeleteClick = (e) => {
    // delete immediately if shift key is pressed
    const isShiftPressed = e.shiftKey

    if (isShiftPressed) {
      handleDelete()
      return
    }

    // show delete confirmation dialog
    setDeleteConfirmation(true)
  }

  const handleDelete = () => {
    deleteElement(elementId)
  }

  return (
    <div ref={setNodeRef} {...attributes} className={rootClasses}>
      <Tag
        isEditable={isEditable}
        editElementConfig={editElementConfig}
        editInputDefaultValue={editInputDefaultValue}
        className={elementClasses}
        element={element}
        elementId={elementId}
        environment={environment}
        useParsedConfig={isPreviewMode || !isEditable}
        variableValues={variableValues}
        setElementConfigValue={setElementConfigValue}
        css={STYLES.element}
        getEditor={getRichTextEditor}
        hoverElement={
          <>
            {isEditable && !hideHover && (
              <div
                className={hoverClasses}
                onClick={(e) => {
                  setActiveElementId(elementId)
                }}
                css={STYLES.hoverElement}
              >
                <div
                  ref={setActivatorNodeRef}
                  className="DRAG_HANDLE absolute z-[100] top-0 -left-1 flex-[0_0_auto] w-5 h-7 flex self-stretch items-center justify-center !cursor-move rounded-[4px] !pointer-events-auto"
                  {...listeners}
                >
                  <Glyph glyph="drag_and_drop" size={14} className="!fill-white" />
                </div>

                {isActive && (
                  <div className="absolute z-[100] -top-0.5 -right-8 pl-2 flex-[0_0_auto] grid gap-2">
                    <Button hideLabel glyph="delete" color="red" size={100} onClick={handleDeleteClick} />
                    <Button hideLabel glyph="copy" color="blue" size={100} onClick={handleDuplicate} />
                  </div>
                )}
              </div>
            )}
          </>
        }
      >
        {ELEMENT.allowChildren && (
          <ChildElementDroppable elementId={elementId} index={0} isEmpty={!hasChildren} isDisabled={isDragging || isDropDisabled} />
        )}

        {element.elements_order?.map((childId: any, index: number) => (
          <React.Fragment key={childId}>
            <BuilderElement
              key={childId}
              elementId={childId}
              isDropDisabled={isDragging || isDropDisabled}
              hideHover={isDragging || hideHover}
            />
            <ChildElementDroppable elementId={elementId} index={index + 1} isDisabled={isDragging || isDropDisabled} />
          </React.Fragment>
        ))}

        {children}

        {/* <Json data={element} /> */}
        {/* <div>Renders: {renders.current++}</div> */}
      </Tag>

      <DeleteDialog
        setOpen={deleteConfirmation}
        onOpenChange={setDeleteConfirmation}
        title={`Delete ${ELEMENT.name}?`}
        message="Are you sure you want to delete this element? This action cannot be undone."
        content={
          <Alert small contrast glyph="info" className="mt-3">
            <b>Tip: </b> to delete elements immediately without confirmation, hold the "Shift" key while clicking the delete button.
          </Alert>
        }
        yesLabel={`Delete ${ELEMENT.name}`}
        onYes={handleDelete}
      />
    </div>
  )
}

const STYLES = {
  hoverElement: {
    background: transparentize(0.8, COLORS.vividBlue),
    border: `1px solid ${transparentize(0.8, COLORS.blue)}`,

    '& > .DRAG_HANDLE': {
      opacity: 0,
      transform: 'translateX(-100%)',
      transition: 'all 0.1s cubic-bezier(0.39, 0.575, 0.565, 1)',
      background: lighten(0.3, COLORS.text),

      '&:hover': {
        background: COLORS.blue,
      },

      '&::before': {
        content: '""',
        position: 'absolute',
        top: -6,
        bottom: -6,
        left: -6,
        right: -6,
      },
    },

    '& > .DRAG_HANDLE:hover': {
      opacity: 1,
      svg: { fill: COLORS.blue },
    },

    '&.is-active > .DRAG_HANDLE': {
      opacity: 1,
    },

    '&:hover > .DRAG_HANDLE': {
      opacity: 1,
    },
  },

  element: {
    '&.is-editable.is-conditional-trigger': {
      '&::after': {
        content: '"CONDITION TRIGGER"',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: -3,
        left: 0,
        transform: 'translateY(-50%)',
        fontSize: '0.75rem',
        borderRadius: 4,
        fontWeight: 600,
        padding: '0.05rem 0.35rem',
        pointerEvents: 'none !important',
        color: COLORS.white,
        background: COLORS.green,
        letterSpacing: 0.2,
      },

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        border: `1px dashed ${COLORS.green}`,
        borderRadius: 5,
        opacity: 0.4,
        pointerEvents: 'none !important',
        background: transparentize(0.75, COLORS.green),
      },
    },
  },
}
