import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'

import { Tenant } from '@behavehealth/declarations'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, HelpTagIframe, TabList, Tab, Tabs, TipAlert, Link } from '@behavehealth/components'
import { InviteStaffMemberAction } from '@behavehealth/constructs/Staff/InviteStaffMemberAction'
import { ClearSampleDataBanner } from '../../components/ClearSampleDataBanner'

import Active from './active'
import Archived from './archived'

const Employees: React.FC = () => {
  const match = useRouteMatch()
  const tenant: Tenant = useSelector((state) => state.me.tenant)

  const actions = <InviteStaffMemberAction link={`${match.url}/new`} />

  return (
    <Page feature="employees" breakpoint="0" help={<HelpTagIframe id="staff" />} actions={actions}>
      {tenant?.has_seed_data && (
        <div className="mb-4">
          <ClearSampleDataBanner />
        </div>
      )}

      <TipAlert isFullWidth contrast type="warning" localStorageKey="staff_invites_moved" className="!mb-4">
        <b>Please note:</b> we moved Staff Invites to a new dedicated section in <Link to="/user-invites/staff">User Invites</Link>.
      </TipAlert>

      <Tabs>
        <TabList css={STYLES.tabsList}>
          <Tab label="Active" to="/employees/active" />
          <Tab label="Archived" to="/employees/archived" />
        </TabList>
      </Tabs>

      <div className="!mt-[1em]">
        <Switch>
          <Route path="/employees/active" component={Active} />
          <Route path="/employees/archived" component={Archived} />

          <Redirect exact from="/employees" to="/employees/active" />
        </Switch>
      </div>
    </Page>
  )
}

const STYLES = {
  tabsList: {
    marginTop: '-1rem',
  },
}

export default withPageError(Employees)
