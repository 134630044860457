import React from 'react'
import compact from 'lodash/compact'

import { StepperContext } from './context'
import { useStepper } from './useStepper'

import StepperBackButton from './StepperBackButton'
import StepperContinueButton from './StepperContinueButton'
import StepperPage from './StepperPage'
import StepperProgressBar from './StepperProgressBar'

const getSteps = (children: React.ReactNode) => {
  const steps = React.Children.toArray(children).map((child: any) => child.props.step)
  return compact(steps)
}

const Stepper = (props: any) => {
  const { children, className, defaultStep, initialData } = props

  const [permissionSteps, setPermissionSteps]: any = React.useState([])

  const context = useStepper({
    steps: getSteps(children),
    defaultStep,
    initialData,
  })

  const childrenWithProps: any = React.Children.map(children, (child) => {
    if (!child) return

    if (child.props?.onChange) child.props.onChange(context)

    const checkVisibility = (isVisible: boolean) => {
      if (permissionSteps.includes(child.props.step) || !isVisible) return
      setPermissionSteps((c) => [...c, child.props.step])
    }

    return React.cloneElement(child, { isVisible: checkVisibility })
  })

  React.useEffect(() => {
    context.setAllSteps(permissionSteps)
  }, [permissionSteps])

  return (
    <StepperContext.Provider value={context}>
      <div className={className}>{childrenWithProps}</div>
    </StepperContext.Provider>
  )
}

Stepper.Context = StepperContext
Stepper.Context.displayName = 'StepperContext'

Stepper.Page = StepperPage
Stepper.Page.displayName = 'StepperPage'

Stepper.ContinueButton = StepperContinueButton
Stepper.ContinueButton.displayName = 'StepperContinueButton'

Stepper.BackButton = StepperBackButton
Stepper.BackButton.displayName = 'StepperBackButton'

Stepper.ProgressBar = StepperProgressBar
Stepper.ProgressBar.displayName = 'StepperProgressBar'

export default Stepper
