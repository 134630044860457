import { v4 as uuid } from 'uuid'

import { REFERRAL_STATUSES } from './constants'

// Helper function to randomize array elements and select a subset
function getRandomSubset(arr, n) {
  const shuffled = [...arr]
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
  }
  return shuffled.slice(0, n)
}

function pickRandom(arr) {
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

function getRandomDate() {
  const month = String(Math.floor(Math.random() * 12) + 1).padStart(2, '0')
  const day = String(Math.floor(Math.random() * 28) + 1).padStart(2, '0')
  return `2023-${month}-${day}T00:00:00.000Z`
}

export const REFERRAL_STATUS_KEYS = Object.keys(REFERRAL_STATUSES)

const categories = [
  'intake_applications',
  'breathalyzer_tests',
  'clinical_assessments',
  'clinical_measures',
  'clinical_notes',
  'communications',
  'communicable_disease_tests',
  'contacts',
  'custom_notes',
  'discharges',
  'events',
  'intakes',
  'insurance_policies',
  'medication_logs',
  'medication_destruction_logs',
  'medications',
  'medical_assessments',
  'nursing',
  'outcome_measures',
  'location_checks',
  'progress_reviews',
  'physician_orders',
  'recovery_coachings',
]

const clientNames = [
  'Anna Johnson',
  'Bob Smith',
  'Charlie Brown',
  'Diana Ross',
  'Edward Norton',
  'Fiona Apple',
  'Helen Mirren',
  'Isaac Newton',
  'Julia Roberts',
]

const staffNames = [
  'Alice Johnson',
  'Bob Smith',
  'Charlie Brown',
  'Diana Ross',
  'Edward Norton',
  'Fiona Apple',
  'Helen Mirren',
  'Isaac Newton',
  'Julia Roberts',
]

export const getMockReferrals = ({ count = 10, status }: any) =>
  Array.from({ length: count }, (_, i) => {
    const clientName = pickRandom(clientNames)
    const staffName = pickRandom(staffNames)
    const selectedCategories = getRandomSubset(categories, Math.floor(Math.random() * 4) + 1)

    return {
      id: uuid(),
      client: {
        id: uuid(),
        name: clientName,
        avatar: '',
      },
      consent_form: {
        id: `consent${i + 1}`,
        content: `Consent content for ${clientName}`,
        date_issued: getRandomDate(),
      },
      signature: {
        signature_data: `Base64EncodedSignature${i + 1}`,
        signed_at: getRandomDate(),
      },
      data_categories_to_be_transferred: selectedCategories,
      individual_records_to_be_transferred: selectedCategories.map((category, idx) => ({
        id: `record${i + 1}-${idx}`,
        category,
        content: `Sample content for ${category}`,
      })),
      author: {
        id: uuid(),
        name: staffName,
        avatar: '',
      },
      status: status || pickRandom(REFERRAL_STATUS_KEYS),
      additional_notes: Math.random() > 0.7 ? `Additional note for ${clientName}` : undefined,
      updated_at: getRandomDate(),
      created_at: getRandomDate(),
    }
  })

export const REFERRALS_DATA = [
  {
    id: 'vNwNjp2',
    client: {
      name: 'Abbigail Villarreal',
      id: 'vNwNjp2',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-11-05T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-01-04T00:00:00.000Z',
    author: {
      id: '1046e6a3-1e0c-4192-9318-4ce511d830fd',
      name: 'Isaac Newton',
      avatar: '',
    },
  },
  {
    id: 'nq0qoVV',
    client: {
      name: 'Adrien Adkinss',
      id: 'nq0qoVV',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-07-07T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-03-06T00:00:00.000Z',
    author: {
      id: '742ddb58-fa4d-4b0e-b3da-9d9766969c9d',
      name: 'George Clooney',
      avatar: '',
    },
  },
  {
    id: 'rM0MmNX',
    client: {
      name: 'Alexia Cannon',
      id: 'rM0MmNX',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-06-02T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-05-28T00:00:00.000Z',
    author: {
      id: '3153267b-0372-4536-b077-0f3800e444e3',
      name: 'Alice Johnson',
      avatar: '',
    },
  },
  {
    id: 'R4G4kZV',
    client: {
      name: 'Bobbie Hopper',
      id: 'R4G4kZV',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-07-21T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-11-26T00:00:00.000Z',
    author: {
      id: '77d1f367-b30d-460c-9ecc-e184cd1f518f',
      name: 'Isaac Newton',
      avatar: '',
    },
  },
  {
    id: 'QJvJnlw',
    client: {
      name: 'Casandra Mccray',
      id: 'QJvJnlw',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-04-16T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-06-18T00:00:00.000Z',
    author: {
      id: '9819637e-1b0f-4b39-ac97-5099d4601050',
      name: 'Julia Roberts',
      avatar: '',
    },
  },
  {
    id: 'zX3Xwmx',
    client: {
      name: 'Cassandra Griffith',
      id: 'zX3Xwmx',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-04-28T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-06-03T00:00:00.000Z',
    author: {
      id: 'b8d71720-fb42-4ac4-9491-979642d0004a',
      name: 'George Clooney',
      avatar: '',
    },
  },
  {
    id: 'BL7LR1W',
    client: {
      name: 'Garett Bradleys',
      id: 'BL7LR1W',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-06-12T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-10-03T00:00:00.000Z',
    author: {
      id: 'a72b4c9d-df92-4188-9e26-2808fe7c64d2',
      name: 'Charlie Brown',
      avatar: '',
    },
  },
  {
    id: 'XQJQkx1',
    client: {
      name: 'Ingrid Booth',
      id: 'XQJQkx1',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-05-09T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-03-03T00:00:00.000Z',
    author: {
      id: '853bee82-1c52-4805-880e-40cac75afc3e',
      name: 'Edward Norton',
      avatar: '',
    },
  },
  {
    id: 'VoJo107',
    client: {
      name: 'Jakob Bullockds',
      id: 'VoJo107',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-03-13T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-06-28T00:00:00.000Z',
    author: {
      id: '1f9af7c4-ab30-4d0b-af52-173973f49459',
      name: 'Fiona Apple',
      avatar: '',
    },
  },
  {
    id: 'kw0wQJo',
    client: {
      name: 'Jovan Hardingds',
      id: 'kw0wQJo',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-01-01T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-03-08T00:00:00.000Z',
    author: {
      id: '42925e7e-4c28-4b6d-ae5a-87f812d26207',
      name: 'Helen Mirren',
      avatar: '',
    },
  },
  {
    id: 'pv0vD3d',
    client: {
      name: 'Katlin Tyler',
      id: 'pv0vD3d',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-12-08T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-02-14T00:00:00.000Z',
    author: {
      id: '1e5e9cfa-fe04-4199-9552-3ec702265042',
      name: 'Isaac Newton',
      avatar: '',
    },
  },
  {
    id: 'lj0jyxM',
    client: {
      name: 'Kelsea Martin',
      id: 'lj0jyxM',
      type: 'resident',
      status: 'current',
    },
    created_at: '2023-01-24T00:00:00.000Z',
    status: 'pending',
    updated_at: '2023-01-12T00:00:00.000Z',
    author: {
      id: '0535cbd1-2a43-4239-be4a-28711463f226',
      name: 'Charlie Brown',
      avatar: '',
    },
  },
]

export const REFERRAL_CONSENT_FORMS = [
  {
    id: '6bd44c01',
    name: 'General Medical Consent',
    updated_at: getRandomDate(),
    created_at: getRandomDate(),
    client_signature_required: true,
    sender_signature_required: true,
    receiver_signature_required: true,
    author: {
      id: uuid(),
      name: pickRandom(staffNames),
      avatar: '',
    },
    sections: [
      {
        id: '173f9251-72cd-49a0-a0ba-ccbd08acc959',
        name: 'Introduction',
        content:
          'This consent form is to authorize general medical care which may include routine diagnostic procedures and medical treatment as prescribed by the attending physician.',
      },
      {
        id: 'd24d9677-6d56-42f2-9c46-3288004a09cf',
        name: 'Consent to Treatment',
        content:
          'I hereby give my consent to the healthcare team to provide medical care including necessary tests, treatments, and other procedures.',
      },
      {
        id: '73ae368d-ae71-4782-9b3f-d2c145f45aa1',
        name: 'Disclosure of Health Information',
        content:
          'I understand that my health information may be shared with other healthcare professionals for the purpose of my treatment.',
      },
    ],
  },
  {
    id: '36b55c60',
    name: 'Surgical Procedure Consent',
    updated_at: getRandomDate(),
    created_at: getRandomDate(),
    client_signature_required: true,
    sender_signature_required: true,
    receiver_signature_required: false,
    author: {
      id: uuid(),
      name: pickRandom(staffNames),
      avatar: '',
    },
    sections: [
      {
        id: 'a84b0c00-df6c-4d5a-8e74-b692b1cd6700',
        name: 'Purpose of Surgery',
        content:
          'The purpose of this consent form is to provide permission for a surgical procedure that has been recommended by your surgeon.',
      },
      {
        id: '910e5afc-3428-426b-b7fa-a67a695db801',
        name: 'Risks and Benefits',
        content:
          'The potential risks and benefits of the surgery have been explained to me, and I understand that there is no guarantee of the outcome.',
      },
      {
        id: '15a58424-33ab-4d90-be24-68d6bcd7a891',
        name: 'Anesthesia Consent',
        content: 'I consent to the administration of anesthesia as deemed necessary for the procedure.',
      },
    ],
  },
  {
    id: 'a3b07826',
    name: 'Telemedicine Services Consent',
    updated_at: getRandomDate(),
    created_at: getRandomDate(),
    client_signature_required: true,
    sender_signature_required: false,
    receiver_signature_required: false,
    author: {
      id: uuid(),
      name: pickRandom(staffNames),
      avatar: '',
    },
    sections: [
      {
        id: '6745a759-9c9e-4755-b809-219fbc1e2b82',
        name: 'Nature of Telemedicine',
        content: 'This consent form is for the provision of healthcare services via telecommunication technologies.',
      },
      {
        id: '3c5e32b2-b176-4305-8db6-f443c56558bc',
        name: 'Confidentiality and Access',
        content: 'All telemedicine interactions will be conducted with the same degree of confidentiality as traditional medical care.',
      },
      {
        id: 'a47cf55b-f2a6-40d5-a688-7f5e507c3f2c',
        name: 'Patient Rights',
        content:
          'I understand that I have the right to withhold or withdraw my consent to the use of telemedicine during the course of my care at any time.',
      },
    ],
  },
]
