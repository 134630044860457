import React from 'react'

import { COLORS } from '../theme'
import { DEFAULT_EMPTY_VALUE } from '../utils/constants'

import { Text } from './Typography'
import Link from './Link'

const Value = (props) => {
  const {
    avatar,
    avatarInitials,
    avatarMagnify,
    avatarMagnifyScale,
    center,
    className,
    css,
    glyph,
    glyphColor,
    icon,
    muted,
    value,
    link,
    graphic,
    testKey,
    description,
  } = props

  const hasLink = link && value

  const content = (
    <Text
      testKey={testKey}
      className={className}
      muted={muted}
      css={css}
      center={center}
      children={value || DEFAULT_EMPTY_VALUE}
      glyph={glyph}
      glyphColor={glyphColor}
      graphic={graphic}
      icon={value && icon ? icon : null}
      avatar={avatar}
      avatarInitials={avatarInitials || value}
      avatarMagnify={avatarMagnify}
      avatarMagnifyScale={avatarMagnifyScale}
      color={hasLink ? COLORS.blue : COLORS.text}
      description={description}
    />
  )

  if (hasLink) {
    return (
      <Link to={link} css={styles}>
        {content}
      </Link>
    )
  }

  return content
}

const styles = {
  '&:hover': { textDecoration: 'underline' },
}

Value.defaultProps = {
  muted: false,
}

export default Value
