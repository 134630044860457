import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { Dialog, Overlay, Workflow } from '../'
import { niceAmount } from '../../utils/functions'

import AmountStep from './workflow/AmountStep'
import PaymentStep from './workflow/PaymentStep'
import ConfirmationStep from './workflow/ConfirmationStep'

let stripePromise
let usedStripeID

const buildStripe = (stripeConnectID) => {
  if (!stripeConnectID) return null
  if (stripePromise && usedStripeID === stripeConnectID) return stripePromise

  usedStripeID = stripeConnectID
  stripePromise = loadStripe(process.env.BH_STRIPE_PUBLIC_KEY, { stripeAccount: stripeConnectID })

  return stripePromise
}

const StripePaymentOverlay = (props: any) => {
  const {
    customer,
    currentBalance,
    passFees,
    stripeConnectID,
    onClose,
    onSuccess,
    source = 'Client Portal',
    paymentMethodReferenceCategory,
  } = props

  const [total, setTotal] = React.useState(0)
  const [succeeded, setSucceeded] = React.useState(false)

  const paymentSuccessful = () => {
    setSucceeded(true)

    if (onSuccess) onSuccess()
  }

  return (
    <Overlay
      position="center"
      minHeight={45}
      showBackdrop
      closeWrapper={(closeElement: any) => {
        if (succeeded) {
          return <div onClick={onClose}>{closeElement}</div>
        } else {
          return (
            <Dialog
              glyph="circle_error"
              title="Cancel Payment?"
              message="This payment will not be processed."
              yesColor="red"
              yesLabel="Cancel Payment"
              noLabel="Go Back"
              onYes={onClose}
            >
              {closeElement}
            </Dialog>
          )
        }
      }}
    >
      <Overlay.Header title="Make a Payment" glyph="dollar" />
      <Overlay.Content>
        <Elements stripe={buildStripe(stripeConnectID)}>
          <Workflow>
            <Workflow.Panel step="amount">
              <Workflow.Header title={`Payment Amount: ${total ? niceAmount(total) : '–'}`} isActive={!succeeded} />
              <Workflow.Content>
                <AmountStep
                  currentBalance={currentBalance}
                  passFees={passFees}
                  selectedAmount={total}
                  onUpdate={(value) => setTotal(value)}
                />
              </Workflow.Content>
            </Workflow.Panel>

            <Workflow.Panel step="payment">
              <Workflow.Header title="Payment Details" isActive={false} />
              <Workflow.Content>
                <PaymentStep
                  finalAmount={total}
                  customer={customer}
                  source={source}
                  paymentMethodReferenceCategory={paymentMethodReferenceCategory}
                />
              </Workflow.Content>
            </Workflow.Panel>

            <Workflow.Panel step="confirmation" onOpen={paymentSuccessful}>
              <Workflow.Header title="Confirmation" isActive={false} />
              <Workflow.Content>
                <ConfirmationStep onClose={onClose} />
              </Workflow.Content>
            </Workflow.Panel>
          </Workflow>
        </Elements>
      </Overlay.Content>
    </Overlay>
  )
}

export default StripePaymentOverlay
