import React from 'react'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import withSettings from '../../hocs/withSettings'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    id: 'charge_id',
    accessor: 'charge_id',
    Header: 'Charge ID',
    Cell: ({ value, row }) => <TableCell.MainLink to={to(row.original.id)} label={value} />,
  },
  {
    width: 210,
    accessor: 'status',
    Header: 'Status',
  },
  {
    width: 180,
    accessor: 'adjustment_id',
    Header: 'Adjustment ID',
  },
  {
    width: 180,
    accessor: 'claim_id',
    Header: 'Claim ID',
    Cell: ({ value, row }) => `${value} - ${row.subRows[0].original.primary_payer_name}`,
  },
  {
    width: 180,
    accessor: 'cpt_code',
    Header: 'CPT Code',
  },
  {
    width: 180,
    accessor: 'cpt_description',
    Header: 'CPT Description',
  },
  {
    width: 220,
    accessor: 'created_at',
    Header: 'Created At',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 220,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
]

const InsuranceChargesTable = ({ data, to, isLoading, timezone, localStorageKey, batchActionsConfig, titleAfter }: any) => {
  return (
    <Table
      data={data}
      columns={columns(to, timezone)}
      title="Insurance Charges"
      titleAfter={titleAfter}
      icon="insurance"
      groupBy="claim_id"
      isLoading={isLoading}
      emptyDescription="No insurance charges have been added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

InsuranceChargesTable.defaultProps = {
  localStorageKey: 'insurance_charges',
}

export default withSettings(InsuranceChargesTable)
