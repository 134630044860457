import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { usDate, usTime } from '../../utils/functions'
import { useUpdate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks'

import EventStatus from '../../components/Statuses/EventStatus'
import DonutChart from '../../components/DonutChart'
import Flex from '../../components/Flex'
import Status from '../../components/Status'
import TaskStatus from '../../components/Statuses/TaskStatus'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import { FILTERS } from '../Filters/config'

const STATUS_COLORS: any = {
  done: 'green',
  due_today: 'orange',
  to_do: 'blue',
  overdue: 'red',
}

const TASK_TYPES: any = {
  one_to_one: 'One-to-One',
  group_meeting: 'Group Task',
}

export const TodosReportDataTable = (props: any) => {
  const { to } = props

  const { timezone, isBehave } = useSettings()

  const TaskCheckbox = ({ task }: any) => {
    const status = task?.display_status

    const { mutate, isLoading } = useUpdate({
      name: ['todo', task.id],
      url: `/events/${task.id}`,
      invalidate: 'todos',
    })

    const toggleTask = () => {
      mutate({ action_status: task.action_status === 'done' ? 'due' : 'done' })
    }

    return (
      <Flex centerY nowrap>
        <Status color={STATUS_COLORS[status]} label={status?.replace('_', ' ')} />
      </Flex>
    )
  }

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        id: 'title',
        model: 'data.title',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.title} to={to?.(data.data)} />,
      },
      {
        width: 140,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ value }: any) => <EventStatus status={value} />,
      },
      {
        width: 170,
        id: 'subtasks_no',
        model: 'data.subtasks_no',
        title: 'Tasks',
        formatValue: ({ data, value }: any) => {
          if (value === 0) return '-'

          return (
            <Flex centerY nowrap>
              <DonutChart
                value={(data.data.subtasks_completed_no / data.data.subtasks_no) * 100}
                size={16}
                strokeWidth={3}
                labelSize="0px"
                css={styles.donut}
              />
              <div>
                {data.data.subtasks_completed_no} / {data.data?.subtasks_no} Completed
              </div>
            </Flex>
          )
        },
        Filter: false,
      },
      {
        width: 90,
        id: 'color',
        model: 'data.color',
        title: 'Color',
        type: 'color',
      },
      {
        width: 160,
        id: 'started_at',
        model: 'data.started_at',
        title: 'Due Date',
        formatValue: ({ value }: any) => usDate(value, timezone),
      },
      {
        width: 120,
        id: 'is_all_day',
        model: 'data.is_all_day',
        title: 'Due By',
        type: 'date-time',
      },
      {
        width: 110,
        id: 'meeting_type',
        model: 'data.meeting_type',
        title: 'Type',
        formatValue: ({ value }: any) => TASK_TYPES[value],
      },
      {
        width: 160,
        id: 'sharing_type',
        model: 'data.sharing_type',
        title: 'Permissions',
        formatValue: ({ value }: any) => {
          return <TaskStatus status={value} />
        },
        // filterOptions: [
        //   { label: 'Staff Only', value: 'internal' },
        //   { label: 'Client Portal', value: 'client' },
        // ],
      },
      {
        width: 200,
        id: 'plain_public_description',
        model: 'data.plain_public_description',
        title: 'Public Description',
        // NOTE @Andrei: use `public_description` to show html format
      },
    ]
  }, [to])

  const styles = {
    checkButton: {
      marginRight: 10,
    },
    donut: {
      marginRight: 8,
    },
  }

  return (
    <DataTable
      asCard
      title="To-Do's"
      icon="tasks"
      columns={columns}
      filtersConfig={FILTERS.todos}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="todos"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=todos'}
        />
      }
    />
  )
}
