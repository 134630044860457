import React from 'react'
import TextareaAutosize from 'react-autosize-textarea'

import { withFormContext } from './context'
import { INPUT_STYLES } from '../../theme'

import Textarea from './Textarea'
import Button from '../Button'
import Grid from '../Grid'

import Notifications from '../../modules/notifications'
import { copyToClipboard } from '../../utils/functions'

class CopyTextarea extends Textarea {
  copyToClipboard = () => {
    copyToClipboard(this.state.value)
    Notifications.send(this.props.successMessage || 'Value copied!', 'positive')
  }

  selectText = (event) => event.target.select()

  editRender = () => {
    const { buttonLabel } = this.props

    return (
      <Grid gap={12}>
        <TextareaAutosize
          id={this.state.id}
          name={this.props.model}
          value={this.state.value || ''}
          placeholder={this.props.placeholder}
          rows={this.props.minRows || this.props.rows}
          maxRows={this.props.maxRows || this.props.rows}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          css={INPUT_STYLES}
        />

        <Button fullWidth type="primary" glyph="copy" label={buttonLabel} onClick={this.copyToClipboard} css={{ margin: '0.5rem' }} />
      </Grid>
    )
  }
}

export default withFormContext(CopyTextarea)
