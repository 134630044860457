import React from 'react'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import { useMedia } from 'use-media'

import { COLORS } from '../../theme'
import { DT, usDate } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Button from '../Button'
import Dropdown from '../Dropdown'
import DropdownItem from '../DropdownItem'
import Flex from '../Flex'
import Glyph from '../Glyph'

export const PERIODS: any = {
  today: 'Today',
  last_week: 'Last Week',
  last_two_weeks: 'Last 2 Weeks',
  last_month: 'Last Month',
  last_three_months: 'Last 3 Months',
  all_time: 'All Time',
}

const OFFSET: any = {
  today: { days: 0 },
  last_week: { days: 7 },
  last_two_weeks: { weeks: 2 },
  last_month: { months: 1 },
  last_three_months: { months: 3 },
}

const DAYS_TO_DISPLAY = 42

const getDays = (startDT: any) => {
  const days: any[] = []

  if (!startDT) return days

  const firstDay = startDT.startOf('month').startOf('week')

  for (let i = 0; i < DAYS_TO_DISPLAY; i++) days.push(firstDay.plus({ days: i }))

  return days
}

export const DateRangeSelector: React.FC<any> = (props) => {
  const { onChange, defaultPeriod = 'all_time', renderTrigger, triggerStyles, dropdownCss, isInline } = props

  const { timezone: settingsTimezone } = useSettings()
  const timezone = props.timezone || settingsTimezone

  const today = DateTime.local().setZone(timezone)

  const [startDate, setStartDate]: any = React.useState(props.startDate ? DT(props.startDate, timezone) : null)
  const [endDate, setEndDate]: any = React.useState(props.startDate ? DT(props.endDate, timezone) : null)
  const [endPreviewDate, setEndPreviewDate]: any = React.useState(null)

  const [firstMonth, setFirstMonth] = React.useState(today.startOf('month'))
  const secondMonth = React.useMemo(() => firstMonth.plus({ months: 1 }), [firstMonth])

  const firstMonthDays = getDays(firstMonth)
  const secondMonthDays = getDays(firstMonth.plus({ months: 1 }))

  const [isOpen, setIsOpen]: any = React.useState(false)
  const [period, setPeriod] = React.useState(defaultPeriod)

  const isDesktop = useMedia({ minWidth: 800 })

  let label = 'Date Filter'

  if (period) {
    if (period === 'custom') {
      let start = startDate ? usDate(startDate, timezone) : '…'
      let end = endDate ? usDate(endDate, timezone) : '…'
      label = `${start} – ${end}`
    } else {
      label = PERIODS[period]
    }
  }

  const decreaseMonth = () => {
    setFirstMonth(firstMonth.minus({ months: 1 }))
  }
  const increaseMonth = () => {
    setFirstMonth(firstMonth.plus({ months: 1 }))
  }

  const handleDateSelect = (date: any) => {
    setPeriod('custom')

    if (startDate && endDate) {
      setStartDate(date)
      setEndDate(null)
    } else if (startDate) {
      date <= startDate ? setStartDate(date) : setEndDate(date)
    } else {
      setStartDate(date)
    }
  }

  const handleHover = (event: any) => {
    if (event.target.dataset?.date) {
      const hoverDate = DT(event.target.dataset?.date, timezone)
      setEndPreviewDate(hoverDate)
    }
  }

  const close = () => {
    // fill in end date on close if only start date was selected
    if (period === 'custom' && !!startDate && !endDate) {
      setEndDate(startDate)
    }

    setIsOpen(false)
  }

  React.useEffect(() => {
    if (!period || period === 'custom') return

    if (period === 'all_time') {
      setStartDate(null)
      setEndDate(null)
      setIsOpen(false)
    } else {
      const offset: any = OFFSET[period]

      setStartDate(today.minus(offset))
      setEndDate(today)
    }
  }, [period])

  React.useEffect(() => {
    if (period === 'all_time') {
      onChange?.([]) // empty array is required for react query enabled option
      return
    }

    if (!(startDate && endDate)) {
      onChange?.(null)
      return
    }

    const start = startDate.toISODate()
    const end = endDate.toISODate()

    onChange?.([start, end])
    setIsOpen(false)
  }, [startDate, endDate])

  const innerContent = (
    <div css={STYLES.columnsWrapper}>
      <div css={STYLES.columnLeft}>
        <DropdownItem label="All Time" isActive={period === 'all_time'} onClick={() => setPeriod('all_time')} />
        <DropdownItem label="Today" isActive={period === 'today'} onClick={() => setPeriod('today')} />
        <DropdownItem label="Last Week" isActive={period === 'last_week'} onClick={() => setPeriod('last_week')} />
        <DropdownItem label="Last 2 Weeks" isActive={period === 'last_two_weeks'} onClick={() => setPeriod('last_two_weeks')} />
        <DropdownItem label="Last Month" isActive={period === 'last_month'} onClick={() => setPeriod('last_month')} />
        <DropdownItem label="Last 3 Months" isActive={period === 'last_three_months'} onClick={() => setPeriod('last_three_months')} />
        {period === 'custom' && <DropdownItem label="Custom" isActive={period === 'custom'} />}
      </div>

      <div css={STYLES.main} onMouseOver={handleHover}>
        <div css={STYLES.calendars}>
          <div css={STYLES.calendar}>
            <header css={STYLES.calendarHeader}>
              {isDesktop ? (
                <>
                  <Button
                    hideLabel
                    glyph="chevron_left"
                    onClick={decreaseMonth}
                    size={200}
                    color="text"
                    display="inline-flex"
                    css={STYLES.navButton}
                  />
                  <div>{firstMonth.toFormat('LLL yyyy')}</div>
                </>
              ) : (
                <>
                  <div>{firstMonth.toFormat('LLL yyyy')}</div>
                  <Flex gap="0.25rem">
                    <Button
                      hideLabel
                      glyph="chevron_left"
                      onClick={decreaseMonth}
                      size={200}
                      color="text"
                      display="inline-flex"
                      css={STYLES.navButton}
                    />
                    <Button
                      hideLabel
                      glyph="chevron_right"
                      onClick={increaseMonth}
                      size={200}
                      color="text"
                      display="inline-flex"
                      css={STYLES.navButton}
                    />
                  </Flex>
                </>
              )}
            </header>

            <div css={STYLES.calendarGrid}>
              {firstMonthDays.map((date) => {
                const formattedDate = usDate(date, timezone)
                const isSelected = formattedDate === usDate(startDate, timezone) || formattedDate === usDate(endDate, timezone)
                const isInSelectionRange = date > startDate && date <= endDate
                const isInPreviewRange = date > startDate && date <= endPreviewDate
                const isOutsideMonth = date.month !== firstMonth.month

                const dayClasses = clsx({
                  'is-selected': isSelected,
                  'is-in-selection-range': startDate && endDate && isInSelectionRange,
                  'is-in-preview-range': startDate && !endDate && isInPreviewRange,
                  'is-outside-month': isOutsideMonth,
                })

                return (
                  <div
                    key={formattedDate}
                    css={STYLES.calendarDay}
                    className={dayClasses}
                    onClick={() => handleDateSelect(date)}
                    data-date={date.toISODate()}
                  >
                    <span>{date.toFormat('dd')}</span>
                  </div>
                )
              })}
            </div>
          </div>

          {isDesktop && (
            <div css={STYLES.calendar}>
              <header css={STYLES.calendarHeader}>
                <div>{secondMonth.toFormat('LLL yyyy')}</div>
                <Button
                  hideLabel
                  glyph="chevron_right"
                  onClick={increaseMonth}
                  size={200}
                  color="text"
                  display="inline-flex"
                  css={STYLES.navButton}
                />
              </header>

              <div css={STYLES.calendarGrid}>
                {secondMonthDays.map((date) => {
                  const formattedDate = usDate(date, timezone)
                  const isSelected = formattedDate === usDate(startDate, timezone) || formattedDate === usDate(endDate, timezone)
                  const isInSelectionRange = date > startDate && date <= endDate
                  const isInPreviewRange = date > startDate && date <= endPreviewDate
                  const isOutsideMonth = date.month !== secondMonth.month

                  const dayClasses = clsx({
                    'is-selected': isSelected,
                    'is-in-selection-range': startDate && endDate && isInSelectionRange,
                    'is-in-preview-range': startDate && !endDate && isInPreviewRange,
                    'is-outside-month': isOutsideMonth,
                  })

                  return (
                    <div
                      key={formattedDate}
                      css={STYLES.calendarDay}
                      className={dayClasses}
                      onClick={() => handleDateSelect(date)}
                      data-date={date.toISODate()}
                    >
                      <span>{date.toFormat('dd')}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>

        <div css={STYLES.footer}>
          <span css={STYLES.footerTitle}>Timezone: </span> <span css={STYLES.footerDescription}>{timezone}</span>
        </div>
      </div>
    </div>
  )

  if (isInline) return innerContent

  return (
    <div>
      <Dropdown
        isOpen={isOpen}
        onClose={close}
        maxWidth={800}
        portal="radix"
        css={{ ...STYLES.dropdown, ...dropdownCss }}
        menuWrapperCSS={STYLES.menuWrapper}
        position="bottom-start"
        closeOnMenuClick={false}
        trigger={
          renderTrigger ? (
            <div onClick={() => setIsOpen(true)} css={triggerStyles}>
              {renderTrigger({ period, startDate, endDate })}
            </div>
          ) : (
            <Button
              label={label}
              display="inline-flex"
              type="minimal"
              color="text"
              glyph="date"
              glyphColor={COLORS.blue}
              size={200}
              after={<Glyph glyph="triangle_down" color="blue" size={10} />}
              onClick={() => setIsOpen(true)}
            />
          )
        }
      >
        {innerContent}
      </Dropdown>
    </div>
  )
}

const STYLES = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },

  dropdown: {
    width: 'fit-content',
  },

  menuWrapper: {
    margin: '0.25rem 0 0 !important',
  },

  columnsWrapper: {
    display: 'grid',

    '@media (min-width: 800px)': {
      gridTemplateColumns: '140px 1fr',
    },
  },

  columnLeft: {
    borderRight: `1px solid ${COLORS.divider}`,
  },

  calendars: {
    display: 'flex',
    flex: '1 1 auto',
  },

  calendar: {
    flex: '1 1 auto',

    '&:first-of-type': {
      borderRight: `1px solid ${COLORS.divider}`,
    },
  },

  calendarHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 600,
    padding: '0.25rem 0.5rem',
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  calendarGrid: {
    flex: '1 1 300px',
    display: 'grid',
    gridGap: '0.25rem',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridAutoRows: 'min-content',
    alignItems: 'flex-start',
    fontSize: '0.96rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    width: '100%',
    maxWidth: 480,
    margin: '0 auto',
    padding: '0.25rem 0.5rem',
  },

  calendarDay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: 30,
    height: 30,
    zIndex: 0,
    lineHeight: 1,
    cursor: 'pointer',
    fontSize: '0.94rem',
    borderRadius: 4,

    '&.is-selected': {
      color: COLORS.white,
      background: COLORS.blue,
    },

    '&.is-in-selection-range': {
      color: COLORS.white,
      background: COLORS.blue,
    },

    '&.is-in-preview-range': {
      color: COLORS.white,
      background: COLORS.blue,
    },

    '&.is-outside-month': {
      opacity: 0,
      visibility: 'hidden',
    },

    '&:hover': {
      color: COLORS.white,
      background: COLORS.blue,
    },
  },

  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    textAlign: 'center',
    borderTop: `1px solid ${COLORS.divider}`,
    fontSize: '0.92rem',
  },

  footerTitle: {
    fontWeight: 600,
    display: 'inline-block',
    marginRight: '0.25rem',
  },

  footerDescription: {
    color: COLORS.textMuted,
  },

  navButton: {
    padding: 0,
  },
}
