import React from 'react'
import get from 'lodash/get'

import { address } from '../../../utils/functions'

import { Text } from '../../Typography'
import Status from '../../Status'

import OrganizationNavItems from '../../Navs/OrganizationNavItems'
import QuickViewOverlay from './QuickViewOverlay'
import { withOverlayError } from '../../../hocs/withOverlayError'

const OrganizationQuickView = ({ organization, onClose }: any) => (
  <QuickViewOverlay
    title={organization.name}
    avatar={organization.avatar}
    onClose={onClose}
    data={organization}
    subheader={
      <>
        <Text>{address(organization.address, '')}</Text>
        <Status color="purple" label={`${organization?.category} – ${get(organization, 'subcategory.name')}`} />
      </>
    }
  >
    <OrganizationNavItems organizationID={organization.id} />
  </QuickViewOverlay>
)

export default withOverlayError(OrganizationQuickView)
