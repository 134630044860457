import React from 'react'
import { DateTime } from 'luxon'
import size from 'lodash/size'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useGet, useDelete } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Icon from '../../components/Icon'
import PageSection from '../../components/PageSection/PageSection'
import PayerNetworkStatus from '../../components/Statuses/PayerNetworkStatus'
import SummonOverlay from '../../components/SummonOverlay'

import { CommunityProfileInsurancePayerOverlay } from './CommunityProfileInsurancePayerOverlay'
import { countWord } from '../../utils/functions'
import { useCommunityEditor } from './useCommunityEditor'
import { useCommunityPermissions } from './useCommunityPermissions'

const RootCommunityProfileInsurancePayersTab: React.FC<any> = (props: any) => {
  const { entityId } = props

  const { canEditCommunityProfiles } = useCommunityPermissions()

  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  const { data, isLoading }: any = useGet({
    name: ['community', entityId, 'entity_insurance_payers'],
    url: `/community/entities/${entityId}/entity_insurance_payers`,
  })

  const { mutateAsync: deleteRecord } = useDelete({
    name: ['delete_entity_insurance_payers'],
    url: `/community/entity_insurance_payers`,
    invalidate: ['community', entityId, 'entity_insurance_payers'],
    onSuccess: () => {
      setUpdatedAt(DateTime.now().toMillis())
    },
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 200,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <SummonOverlay overlay={<CommunityProfileInsurancePayerOverlay entityId={entityId} dataID={data.id} initialData={data} />}>
            <MainCell id={data.id} value={data.name} />
          </SummonOverlay>
        ),
      },
      {
        title: 'Network Status',
        model: 'network_status',
        formatValue: ({ value }: any) => <PayerNetworkStatus status={value} />,
      },
      {
        title: 'Description',
        model: 'description',
        width: 200,
      },
      {
        title: 'Actions',
        id: 'actions',
        flexChildren: true,
        disableSort: true,
        width: 100,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          if (!canEditCommunityProfiles) return null

          return (
            <DeleteDialog
              title="Delete Insurance Payer?"
              message="Are you sure you want to delete this Insurance Payer?"
              onYes={async () => await deleteRecord(data.id)}
            >
              <Button glyph="delete" label="Delete" type="minimal" color="red" size={100} />
            </DeleteDialog>
          )
        },
      },
    ]
  }, [canEditCommunityProfiles])

  return (
    <div className="grid grid-cols-[100%]">
      <PageSection>
        <PageSection.Header
          graphic={<Icon icon="bank_building" />}
          after={
            canEditCommunityProfiles && (
              <SummonOverlay overlay={<CommunityProfileInsurancePayerOverlay entityId={entityId} dataID="new" />}>
                <Button label="Add New Insurance Payer" glyph="add" size={200} type="primary" display="inline-flex" />
              </SummonOverlay>
            )
          }
        >
          <PageSection.Title title={size(data) ? countWord('Insurance Payers', size(data)) : 'Insurance Payers'} />
        </PageSection.Header>
      </PageSection>

      <DataTable
        asCard
        data={data}
        isLoading={isLoading}
        title="Insurance Payers"
        icon="bank_building"
        columns={columns}
        mainHeaderHidden
        className="mt-4"
      />
    </div>
  )
}

export const CommunityProfileInsurancePayersTab = withOverlayError(RootCommunityProfileInsurancePayersTab)
