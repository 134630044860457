import React from 'react'

import { Card, State } from '@behavehealth/components'

import { AnalyticsContext } from '../context'

const ProductivityAnalytics = () => {
  const { timeframe, renderer } = React.useContext(AnalyticsContext)

  const [loading, setLoading] = React.useState(false)

  if (loading) {
    return (
      <Card>
        <State isLoading />
      </Card>
    )
  }

  return <div css={styles.grid}></div>
}

const styles = {
  grid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '100%',

    '@media (min-width: 768px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
    },
  },
}

export default ProductivityAnalytics
