import React from 'react'
import size from 'lodash/size'

import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
import padStart from 'lodash/padStart'
import isEqual from 'react-fast-compare'
import produce from 'immer'

import { withFormContext } from './context'
import { usDate } from '../../utils/functions'
import { validate } from './validators'

import BaseInput from './BaseInput'
import Flex from '../Flex'
import Value from '../Value'

import FieldBase from './FieldBase'

import { DatePicker } from '../DatePicker'

const MIN_YEAR = 1950
const MAX_YEAR = 2030

const processDay = (day: any, month: any, year: any) => {
  if (isNaN(day)) return null

  const parsedMonth = month || DateTime.local().month
  const parsedYear = year || DateTime.local().year
  const daysInMonth = DateTime.local(parsedYear, parsedMonth).daysInMonth

  if (day < 1) return 1
  else if (day > daysInMonth) return daysInMonth
  else return day
}

const processMonth = (month: any) => {
  if (isNaN(month)) return null
  else if (month < 1) return 1
  else if (month > 12) return 12
  else return month
}

const processYear = (year: any) => {
  if (isNaN(year)) return null
  else if (year < 0) return MIN_YEAR
  else if (year >= 0 && year <= 30) return 2000 + year
  else if (year > 30 && year < 100) return 1900 + year
  else if (year >= 100 && year <= MIN_YEAR) return MIN_YEAR
  else if (year >= MAX_YEAR) return MAX_YEAR
  else return year
}

const allVisualFieldsCompleted = (visual: any) => {
  if (!visual) return false
  return visual?.year.length === 4 && visual?.month.length === 2 && visual?.day.length === 2
}

class DateInput extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    let timezone = props.timezone

    const vs = props.validations
    let date = DateTime.fromISO(props.value, { zone: timezone })

    if (!date.isValid) {
      const model = props.form?.getField(props.model)
      const initialModel = props.form?.getInitialInputFieldValue(props.model)
      const val = model || initialModel || props.default

      if (val) {
        date = DateTime.fromISO(val, { zone: timezone })
      } else {
        let localTime = DateTime.local()

        if (props.defaultToNow) {
          date = DateTime.fromObject({
            year: localTime.year,
            month: localTime.month,
            day: localTime.day,
            zone: timezone,
          })
        } else if (props.defaultToTomorrow) {
          localTime = localTime.plus({ days: 1 })
          date = DateTime.fromObject({
            year: localTime.year,
            month: localTime.month,
            day: localTime.day,
            zone: timezone,
          })
        } else if (props.defaultInOneMonth) {
          localTime = localTime.plus({ months: 1 })
          date = DateTime.fromObject({
            year: localTime.year,
            month: localTime.month,
            day: localTime.day,
            zone: timezone,
          })
        } else if (props.defaultToOneMonthAgo) {
          localTime = localTime.minus({ months: 1 })
          date = DateTime.fromObject({
            year: localTime.year,
            month: localTime.month,
            day: localTime.day,
            zone: timezone,
          })
        }
      }
    }

    const value = date.toISO()
    if (vs) errors = validate(value, vs)

    const day = date.day ? padStart(date.day?.toString(), 2, '0') : ''
    const month = date.month ? padStart(date.month?.toString(), 2, '0') : ''
    const year = date.year ? date.year?.toString() : ''

    let smartValue = ''
    if (props.smartDescription && props.form) {
      smartValue = props.smartDescription(date, props.form.getFormValue())
    }

    const isRequired = props.validations?.hasOwnProperty('presence')

    this.state = {
      type: 'DATEINPUT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      date: date,
      value: value,
      day: date.isValid ? date.day : null,
      month: date.isValid ? date.month : null,
      year: date.isValid ? date.year : null,
      visual: {
        day: day,
        month: month,
        year: year,
      },
      smartValue: smartValue,
      allFieldsCompleted: value ? true : false,
      isNested: props.isNested || false,
      inFocus: null,
      focused: {
        day: false,
        month: false,
        year: false,
      },
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isRequired: isRequired,
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
      isPickerOpen: false,
    }

    this.initialData = {
      value: value,
      date: date,
      day: day,
      month: month,
      year: year,
      visual: {
        day: day,
        month: month,
        year: year,
      },
      smartValue: smartValue,
      allFieldsCompleted: value ? true : false,
      focused: {
        day: false,
        month: false,
        year: false,
      },
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.updateType = 'DATA'
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.updateType === 'RESET') {
      this.updateType = 'DATA'
      return true
    }

    if (nextProps.layout !== this.props.layout || nextProps.maxWidth !== this.props.maxWidth) {
      this.updateType = 'STATE'
      return true
    }

    if (nextProps.isEditable !== this.props.isEditable || nextProps.isDisabled !== this.props.isDisabled) {
      this.updateType = 'STATE'
      return true
    }

    if (!isEqual(this.state, nextState)) {
      this.updateType = 'DATA'
      return true
    }

    if (this.props.value !== nextProps.value || this.props.model !== nextProps.model) {
      this.updateType = 'PROPS'
      return true
    }

    if (nextProps.minDate !== this.props.minDate || nextProps.maxDate !== this.props.maxDate) {
      this.updateType = 'STATE'
      return true
    }

    if (nextProps.startDate !== this.props.startDate || nextProps.minDate !== this.props.minDate) {
      this.updateType = 'STATE'
      return true
    }

    if (
      this.props.value !== nextProps.value ||
      this.props.defaultValue !== nextProps.defaultValue ||
      this.props.model !== nextProps.model ||
      this.props.isLoading !== nextProps.isLoading ||
      !isEqual(nextProps.validations, this.props.validations) ||
      !isEqual(nextProps.tooltip, this.props.tooltip)
    ) {
      this.updateType = 'PROPS'
      return true
    }

    if (
      this.props.placeholder !== nextProps.placeholder ||
      this.props.description !== nextProps.description ||
      this.props.labelAfter !== nextProps.labelAfter ||
      this.props.isDisabled !== nextProps.isDisabled ||
      this.props.color !== nextProps.color ||
      this.props.label !== nextProps.label ||
      this.props.count !== nextProps.count ||
      this.props.suffix !== nextProps.suffix ||
      this.props.children !== nextProps.children
    ) {
      this.updateType = 'EXTERNAL'
      return true
    }

    return this.props.shouldAlwaysUpdate || false
  }

  /*
    CUSTOM FUNCTIONS
  */
  onChange = (event: any) => {
    const target = event.target
    const value = target.value

    // if the target is not a number return
    if (value !== '' && isNaN(parseInt(value))) return

    // parse the event for the UI
    const newState = produce(this.state, (draft: any) => {
      if (target.id === 'day') draft.visual.day = value
      else if (target.id === 'month') draft.visual.month = value
      else if (target.id === 'year') draft.visual.year = value
    })

    // update visual state
    this.setState({ visual: newState.visual })
  }

  processValidation = (queueEvent: any) => {
    const fieldsCompleted = allVisualFieldsCompleted(queueEvent?.state?.visual)

    const date = DateTime.fromISO(queueEvent.value, { zone: this.props.timezone })
    let errors = []

    if (fieldsCompleted || this.state.isRequired) {
      errors = date.isValid
        ? validate(date, { ...this.props.defaultValidations, ...this.props.validations })
        : [{ message: 'Please enter a valid date time' }]
    }

    const newState = produce(this.state, (draft: any) => {
      draft.isValid = errors.length ? false : true
      draft.isInvalid = errors.length ? true : false
      draft.errors = errors
    })

    // update local state
    this.setState({
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      errors: errors,
    })

    return newState
  }

  getVisualStrings = (state: any) => {
    const monthString = state.month ? padStart(state.month?.toString(), 2, '0') : ''
    const dayString = state.day ? padStart(state.day?.toString(), 2, '0') : ''
    const yearString = state.year ? state.year.toString() : ''

    return {
      year: yearString,
      month: monthString,
      day: dayString,
    }
  }

  processUpdate = (_queueEvent: any) => {
    const time = DateTime.fromISO(this.props.value || this.props.defaultValue, { zone: this.props.timezone })

    const newState = produce(this.state, (draft: any) => {
      if (time) {
        draft.year = time.year
        draft.month = time.month
        draft.day = time.day

        draft.date = time
        draft.value = draft.date.toISO()

        draft.allFieldsCompleted = true

        if (this.props.smartDescription) {
          draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
        }

        draft.visual = this.getVisualStrings(draft)

        draft.isPristine = false
        draft.isDirty = true
      }
    })

    this.setState({
      year: newState.year,
      month: newState.month,
      day: newState.day,
      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      visual: newState.visual,
      smartValue: newState.smartValue,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })

    return newState
  }

  processDate = ({ day, month, year }: any) => {
    // try to parse the strings into numbers
    const parsedDay = parseInt(day)
    const parsedMonth = parseInt(month)
    const parsedYear = parseInt(year)

    // check if all the numbers are all ok
    const dayDefined = Number.isInteger(parsedDay)
    const monthDefined = Number.isInteger(parsedMonth)
    const yearDefined = Number.isInteger(parsedYear)

    // if yes, create a date object
    let date = null
    if (yearDefined && monthDefined && dayDefined) {
      date = DateTime.fromObject({
        year: parsedYear,
        month: parsedMonth,
        day: parsedDay,
        hour: 0,
        minute: 0,
        zone: this.props.timezone,
      })
    }

    return date
  }

  processFocus = (queueEvent: any) => {
    if (queueEvent.event.target) queueEvent.event.target.select()

    const newState = produce(this.state, (draft: any) => {
      if (queueEvent.event.target.id === 'day') {
        draft.focused.day = true
        draft.inFocus = 'day'
      } else if (queueEvent.event.target.id === 'month') {
        draft.focused.month = true
        draft.inFocus = 'month'
      } else if (queueEvent.event.target.id === 'year') {
        draft.focused.year = true
        draft.inFocus = 'year'
      }

      draft.isTouched = true
      draft.isUntouched = true
    })

    this.setState({
      focused: newState.focused,
      inFocus: newState.inFocus,
      isTouched: true,
      isUntouched: true,
      // isPickerOpen: true,
    })

    return newState
  }

  processBlur = (_queueEvent: any) => {
    const newState = produce(this.state, (draft: any) => {
      draft.isBlur = true
      draft.isHighlighted = false

      // beautify day
      if (draft.inFocus === 'day') {
        if (draft.visual.day === '0' || draft.visual.day === '00') draft.visual.day = '01'
        else {
          const dayDefined = Number.isInteger(parseInt(draft.visual.day))
          if (dayDefined) draft.visual.day = padStart(draft.visual.day, 2, '0')
        }
      }

      // beautify month
      if (draft.inFocus === 'month') {
        if (draft.visual.month === '0' || draft.visual.month === '00') draft.visual.month = '01'
        else {
          const monthDefined = Number.isInteger(parseInt(draft.visual.month))
          if (monthDefined) draft.visual.month = padStart(draft.visual.month, 2, '0')
        }
      }

      // beautify year
      if (draft.inFocus === 'year') {
        if (draft.visual.year.length === 1) {
          draft.visual.year = `200${draft.visual.year}`
        } else if (draft.visual.year.length === 2) {
          const parsed = parseInt(draft.visual.year)

          if (parsed >= 10 && parsed <= 30) {
            draft.visual.year = `20${draft.visual.year}`
          } else if (parsed > 30 && parsed <= 99) {
            draft.visual.year = `19${draft.visual.year}`
          }
        } else if (draft.visual.year.length === 3) {
          draft.visual.year = '2000'
        }
      }

      const date = this.processDate({
        day: draft.visual.day,
        month: draft.visual.month,
        year: draft.visual.year,
      })

      draft.date = date
      draft.value = date?.toISO() || null

      if (this.props.smartDescription) {
        draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
      }

      if (draft.date?.isValid) {
        draft.year = date.year
        draft.month = date.month
        draft.day = date.day
      }
    })

    this.setState({
      visual: newState.visual,
      isBlur: true,
      isHighlighted: false,

      year: newState.year,
      month: newState.month,
      day: newState.day,

      date: newState.date,
      value: newState.value,

      allFieldsCompleted: newState.allFieldsCompleted,
      smartValue: newState.smartValue,

      // isPickerOpen: false,
    })

    return newState
  }

  onKeyDown = (event: any) => {
    const upOrDownPressed = event.keyCode === 38 || event.keyCode === 40
    if (!upOrDownPressed) return

    const newState = produce(this.state, (draft: any) => {
      if (event.keyCode === 40) {
        // DOWN
        if (event.target.id === 'day') {
          const parsedDay = parseInt(draft.visual.day)
          const dayDefined = Number.isInteger(parsedDay)

          if (dayDefined) draft.day = processDay(parsedDay - 1, draft.month, draft.year)
          else draft.day = 1

          draft.visual.day = padStart(draft.day.toString(), 2, '0')
        } else if (event.target.id === 'month') {
          const parsedMonth = parseInt(draft.visual.month)
          const monthDefined = Number.isInteger(parsedMonth)

          if (monthDefined) draft.month = processMonth(parsedMonth - 1)
          else draft.month = 1

          draft.visual.month = padStart(draft.month.toString(), 2, '0')
        } else if (event.target.id === 'year') {
          const parsedYear = parseInt(draft.visual.year)
          const yearDefined = Number.isInteger(parsedYear)

          if (yearDefined) draft.year = processYear(parsedYear - 1)
          else draft.year = 1

          draft.visual.year = draft.year.toString()
        }
      } else if (event.keyCode === 38) {
        // UP
        if (event.target.id === 'day') {
          const parsedDay = parseInt(draft.visual.day)
          const dayDefined = Number.isInteger(parsedDay)

          if (dayDefined) draft.day = processDay(parsedDay + 1, draft.month, draft.year)
          else draft.day = 1

          draft.visual.day = padStart(draft.day.toString(), 2, '0')
        } else if (event.target.id === 'month') {
          const parsedMonth = parseInt(draft.visual.month)
          const monthDefined = Number.isInteger(parsedMonth)

          if (monthDefined) draft.month = processMonth(parsedMonth + 1)
          else draft.month = 1

          draft.visual.month = padStart(draft.month.toString(), 2, '0')
        } else if (event.target.id === 'year') {
          const parsedYear = parseInt(draft.visual.year)
          const yearDefined = Number.isInteger(parsedYear)

          if (yearDefined) draft.year = processYear(parsedYear + 1)
          else draft.year = 1

          draft.visual.year = draft.year.toString()
        }
      }

      const date = this.processDate({
        day: draft.visual.day,
        month: draft.visual.month,
        year: draft.visual.year,
      })

      draft.date = date
      draft.value = date?.toISO()

      if (draft.date?.isValid) {
        draft.year = date.year
        draft.month = date.month
        draft.day = date.day
      }

      if (this.props.smartDescription) {
        draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
      }

      draft.isPristine = false
      draft.isDirty = true
    })

    this.setState({
      day: newState.day,
      month: newState.month,
      year: newState.year,
      visual: newState.visual,
      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      smartValue: newState.smartValue,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })

    // update Form Engine
    if (this.props.form && this.props.model) this.props.form.change(newState)
  }

  onPickerOpenUpdated = (isOpen: boolean) => {
    this.setState({ isPickerOpen: isOpen })
  }

  onPickerSelected = (date: any) => {
    if (!date) return

    const newState = produce(this.state, (draft: any) => {
      draft.date = date
      draft.value = draft.date.toISO()
      draft.allFieldsCompleted = true

      draft.year = date.year
      draft.month = date.month
      draft.day = date.day

      if (this.props.smartDescription) {
        draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
      }

      draft.visual = this.getVisualStrings(draft)

      draft.isPristine = false
      draft.isDirty = true
    })

    this.setState({
      year: newState.year,
      month: newState.month,
      day: newState.day,
      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      visual: newState.visual,
      smartValue: newState.smartValue,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })

    if (this.props.validateOn?.includes('blur')) this.queue({ type: 'VALIDATE', value: newState.value })

    // update Form Engine
    if (this.props.form && this.props.model) this.props.form.change(newState)
  }

  editRender = () => {
    return (
      <Flex alignItems="center" gap={2} nowrap>
        {!this.props.allowInput && <span>{usDate(this.state.value, this.props.timezone)}</span>}

        {this.props.allowInput && (
          <>
            <BaseInput
              id="month"
              type="text"
              placeholder="MM"
              value={this.state.visual?.month}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onKeyDown={this.onKeyDown}
              size={2}
              maxLength={2}
            />

            <BaseInput
              id="day"
              type="text"
              placeholder="DD"
              value={this.state.visual?.day}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onKeyDown={this.onKeyDown}
              size={2}
              maxLength={2}
            />

            <BaseInput
              id="year"
              type="text"
              placeholder="YYYY"
              value={this.state.visual?.year}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onKeyDown={this.onKeyDown}
              size={4}
              maxLength={4}
            />
          </>
        )}

        {this.props.withDatePicker && (
          <DatePicker
            date={this.state.date}
            timezone={this.props.timezone}
            isOpen={this.state.isPickerOpen}
            onOpenUpdated={this.onPickerOpenUpdated}
            minYear={this.props.minYear || MIN_YEAR}
            maxYear={this.props.maxYear || MAX_YEAR}
            onSelect={this.onPickerSelected}
          />
        )}
      </Flex>
    )
  }

  readOnlyRender = () => <Value testKey={this.props.testKey} value={usDate(this.state.value, this.props.timezone)} />
}

DateInput.defaultProps = {
  allowInput: true,
  isEditable: true,
  defaultToNow: false,
  defaultToOneMonthAgo: false,
  inputGroupMaxWidth: '200px',
  withDatePicker: true,
  validateOn: 'blur-change',
}

export default withFormContext(DateInput)
