import React from 'react'

import FormSection from '../../../../Forms/FormSection'
import Checkbox from '../../../../Forms/Checkbox'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import ContextShow from '../../../../Forms/ContextShow'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import Flex from '../../../../Flex'

const MentalStatusForm = () => (
  <Flex vertical gap={16}>
    <FormSection layout="vertical">
      <CheckboxGroup label="Appearance" layout="vertical-dense">
        <Checkbox label="Well groomed" model="data.mental_status.appearance.well_groomed" />
        <Checkbox label="Dresses in work attire" model="data.mental_status.appearance.dresses_in_work_attire" />
        <Checkbox label="Disheveled" model="data.mental_status.appearance.disheveled" />
        <Checkbox label="Inappropriate attire" model="data.mental_status.appearance.inappropriate" />
        <Checkbox label="Casual attire" model="data.mental_status.appearance.casual" />
        <Checkbox label="Unkempt" model="data.mental_status.appearance.unkempt" />
        <Checkbox label="Bizzarre" model="data.mental_status.appearance.bizzarre" />
        <Checkbox label="Other" model="data.mental_status.appearance.other" />
      </CheckboxGroup>
      <ContextShow when="data.mental_status.appearance.other" is={true}>
        <SmartTextarea useDictation placeholder="Other" rows={2} model="data.mental_status.appearance.other_extra" />
      </ContextShow>

      <SmartTextarea useDictation label="Notes on Appearance" model="data.mental_status.appearance.notes" />
    </FormSection>

    <FormSection layout="vertical">
      <CheckboxGroup label="Affect" layout="vertical-dense">
        <Checkbox label="Normal" model="data.mental_status.affect.normal" />
        <Checkbox label="Congruent to mood" model="data.mental_status.affect.congruent_to_mood" />
        <Checkbox label="Constricted" model="data.mental_status.affect.constricted" />
        <Checkbox label="Blunted" model="data.mental_status.affect.blunted" />
        <Checkbox label="Flat" model="data.mental_status.affect.flat" />
        <Checkbox label="Inappropriate to content" model="data.mental_status.affect.inappropriate_to_content" />
        <Checkbox label="Labile" model="data.mental_status.affect.labile" />
        <Checkbox label="Stable" model="data.mental_status.affect.stable" />
        <Checkbox label="Other" model="data.mental_status.affect.other" />
      </CheckboxGroup>
      <ContextShow when="data.mental_status.affect.other" is={true}>
        <SmartTextarea useDictation placeholder="Other" rows={2} model="data.mental_status.affect.other_extra" />
      </ContextShow>

      <SmartTextarea useDictation label="Notes on Affect" model="data.mental_status.affect.notes" />
    </FormSection>

    <FormSection layout="vertical">
      <CheckboxGroup label="Thought Process" layout="vertical-dense">
        <Checkbox label="Logical and sequential" model="data.mental_status.thought_process.logical_and_sequential" />
        <Checkbox label="Flight of ideas" model="data.mental_status.thought_process.flight_of_ideas" />
        <Checkbox label="Goal directed" model="data.mental_status.thought_process.goal_directed" />
        <Checkbox label="Circumstantial" model="data.mental_status.thought_process.circumstantial" />
        <Checkbox label="Loose associations" model="data.mental_status.thought_process.loose_associations" />
        <Checkbox label="Thought blocking" model="data.mental_status.thought_process.thought_blocking" />
        <Checkbox label="Word salad" model="data.mental_status.thought_process.word_salad" />
        <Checkbox label="Fragmented" model="data.mental_status.thought_process.fragmented" />
        <Checkbox label="Other" model="data.mental_status.thought_process.other" />
      </CheckboxGroup>
      <ContextShow when="data.mental_status.thought_process.other" is={true}>
        <SmartTextarea useDictation placeholder="Other" rows={2} model="data.mental_status.thought_process.other_extra" />
      </ContextShow>

      <SmartTextarea useDictation label="Notes on Thought Process" model="data.mental_status.thought_process.notes" />
    </FormSection>

    <FormSection layout="vertical">
      <CheckboxGroup label="Judgment and Insight" layout="vertical-dense">
        <Checkbox label="Good" model="data.mental_status.judgement_and_insight.good" />
        <Checkbox label="Fair" model="data.mental_status.judgement_and_insight.fair" />
        <Checkbox label="Impaired by illness" model="data.mental_status.judgement_and_insight.impaired_by_illness" />
        <Checkbox label="Poor" model="data.mental_status.judgement_and_insight.poor" />
        <Checkbox label="Denies illness" model="data.mental_status.judgement_and_insight.denies_illness" />
        <Checkbox label="Good insight" model="data.mental_status.judgement_and_insight.good_insight" />
        <Checkbox label="Adequate insight" model="data.mental_status.judgement_and_insight.adequate_insight" />
        <Checkbox label="Limited insight" model="data.mental_status.judgement_and_insight.limited_insight" />
        <Checkbox label="Other" model="data.mental_status.judgement_and_insight.other" />
      </CheckboxGroup>
      <ContextShow when="data.mental_status.judgement_and_insight.other" is={true}>
        <SmartTextarea useDictation placeholder="Other" rows={2} model="data.mental_status.judgement_and_insight.other_extra" />
      </ContextShow>

      <SmartTextarea useDictation label="Notes on Judgment and Insight" model="data.mental_status.judgement_and_insight.notes" />
    </FormSection>

    <FormSection layout="vertical">
      <CheckboxGroup label="Behavior" layout="vertical-dense">
        <Checkbox label="Calm" model="data.mental_status.behavior.calm" />
        <Checkbox label="Relaxed" model="data.mental_status.behavior.relaxed" />
        <Checkbox label="Tics" model="data.mental_status.behavior.tics" />
        <Checkbox label="Agitated" model="data.mental_status.behavior.agitated" />
        <Checkbox label="Poor eye contact" model="data.mental_status.behavior.poor_eye_contact" />
        <Checkbox label="Rigidity" model="data.mental_status.behavior.rigidity" />
        <Checkbox label="Restless" model="data.mental_status.behavior.restless" />
        <Checkbox label="Tremors" model="data.mental_status.behavior.tremors" />
        <Checkbox label="Unsteady gait" model="data.mental_status.behavior.unsteady_gait" />
        <Checkbox label="Hyperactive" model="data.mental_status.behavior.hyperactive" />
        <Checkbox label="Tearful" model="data.mental_status.behavior.tearful" />
        <Checkbox label="Other" model="data.mental_status.behavior.other" />
      </CheckboxGroup>
      <ContextShow when="data.mental_status.behavior.other" is={true}>
        <SmartTextarea useDictation placeholder="Other" rows={2} model="data.mental_status.behavior.other_extra" />
      </ContextShow>

      <SmartTextarea useDictation label="Notes on Behavior" model="data.mental_status.behavior.notes" />
    </FormSection>

    <FormSection layout="vertical">
      <CheckboxGroup label="Mood" layout="vertical-dense">
        <Checkbox label="Euthymic" model="data.mental_status.mood.euthymic" />
        <Checkbox label="Depressed" model="data.mental_status.mood.depressed" />
        <Checkbox label="Fearful" model="data.mental_status.mood.fearful" />
        <Checkbox label="Anxious" model="data.mental_status.mood.anxious" />
        <Checkbox label="Euphoric" model="data.mental_status.mood.euphoric" />
        <Checkbox label="Dysphoric" model="data.mental_status.mood.dysphoric" />
        <Checkbox label="Stable" model="data.mental_status.mood.stable" />
        <Checkbox label="Angry/Irritable" model="data.mental_status.mood.angry_irritable" />
        <Checkbox label="Ashamed" model="data.mental_status.mood.ashamed" />
        <Checkbox label="Hostile" model="data.mental_status.mood.hostile" />
        <Checkbox label="Hopeless" model="data.mental_status.mood.hopeless" />
        <Checkbox label="Other" model="data.mental_status.mood.other" />
      </CheckboxGroup>
      <ContextShow when="data.mental_status.mood.other" is={true}>
        <SmartTextarea useDictation placeholder="Other" rows={2} model="data.mental_status.mood.other_extra" />
      </ContextShow>

      <SmartTextarea useDictation label="Notes on Mood" model="data.mental_status.mood.notes" />
    </FormSection>

    <FormSection layout="vertical">
      <CheckboxGroup label="Speech" layout="vertical-dense">
        <Checkbox label="Clear" model="data.mental_status.speech.clear" />
        <Checkbox label="Coherent" model="data.mental_status.speech.coherent" />
        <Checkbox label="Dysarthric" model="data.mental_status.speech.dysarthric" />
        <Checkbox label="Emotional" model="data.mental_status.speech.emotional" />
        <Checkbox label="Hesitant" model="data.mental_status.speech.hesitant" />
        <Checkbox label="Loud" model="data.mental_status.speech.loud" />
        <Checkbox label="Pressured" model="data.mental_status.speech.pressured" />
        <Checkbox label="Monotonous" model="data.mental_status.speech.monotonous" />
        <Checkbox label="Rapid" model="data.mental_status.speech.rapid" />
        <Checkbox label="Slurred" model="data.mental_status.speech.slurred" />
        <Checkbox label="Soft" model="data.mental_status.speech.soft" />
        <Checkbox label="Spontaneous" model="data.mental_status.speech.spontaneous" />
        <Checkbox label="Difficult to understand" model="data.mental_status.speech.difficult_to_understand" />
        <Checkbox label="Other" model="data.mental_status.speech.other" />
      </CheckboxGroup>
      <ContextShow when="data.mental_status.speech.other" is={true}>
        <SmartTextarea useDictation placeholder="Other" rows={2} model="data.mental_status.speech.other_extra" />
      </ContextShow>

      <SmartTextarea useDictation label="Notes on Speech" model="data.mental_status.speech.notes" />
    </FormSection>

    <FormSection layout="vertical">
      <CheckboxGroup label="Thought Content" layout="vertical-dense">
        <Checkbox label="Paranoia" model="data.mental_status.thought_content.paranoia" />
        <Checkbox label="Delusions" model="data.mental_status.thought_content.delusions" />
        <Checkbox label="Compulsions" model="data.mental_status.thought_content.compulsions" />
        <Checkbox label="Suicidal ideations" model="data.mental_status.thought_content.suicidal_ideations" />
        <Checkbox label="Homicidal ideations" model="data.mental_status.thought_content.homicidal_ideations" />
        <Checkbox label="Thoughts of dying" model="data.mental_status.thought_content.thoughts_of_dying" />
        <Checkbox label="Hallucinations" model="data.mental_status.thought_content.hallucinations" />
        <Checkbox label="Preoccupied" model="data.mental_status.thought_content.preoccupied" />
        <Checkbox label="Thought derailment" model="data.mental_status.thought_content.thought_derailment" />
        <Checkbox label="Impulsivity" model="data.mental_status.thought_content.impulsivity" />
        <Checkbox label="Indifference" model="data.mental_status.thought_content.indifference" />
        <Checkbox label="Other" model="data.mental_status.thought_content.other" />
      </CheckboxGroup>
      <ContextShow when="data.mental_status.thought_content.other" is={true}>
        <SmartTextarea useDictation placeholder="Other" rows={2} model="data.mental_status.thought_content.other_extra" />
      </ContextShow>

      <SmartTextarea useDictation label="Notes on Thought Content" model="data.mental_status.thought_content.notes" />
    </FormSection>

    <FormSection layout="vertical">
      <CheckboxGroup label="Cognition and Orientation" layout="vertical-dense">
        <Checkbox label="Alert" model="data.mental_status.cognition_and_orientation.alert" />
        <Checkbox label="Able to abstract" model="data.mental_status.cognition_and_orientation.able_to_abstract" />
        <Checkbox
          label="Able to maintain concentration"
          model="data.mental_status.cognition_and_orientation.able_to_maintain_concentration"
        />
        <Checkbox label="Distracted" model="data.mental_status.cognition_and_orientation.distracted" />
        <Checkbox label="Immediate recall intact" model="data.mental_status.cognition_and_orientation.immediate_recall_intact" />
        <Checkbox label="Recent recall intact" model="data.mental_status.cognition_and_orientation.recent_recall_intact" />
        <Checkbox label="Above average intelligence" model="data.mental_status.cognition_and_orientation.above_average_intelligence" />
        <Checkbox label="Below average intelligence" model="data.mental_status.cognition_and_orientation.below_average_intelligence" />
        <Checkbox label="Oriented to person" model="data.mental_status.cognition_and_orientation.oriented_to_person" />
        <Checkbox label="Oriented to time" model="data.mental_status.cognition_and_orientation.oriented_to_time" />
        <Checkbox label="Oriented to place" model="data.mental_status.cognition_and_orientation.oriented_to_place" />
        <Checkbox label="Oriented to situation" model="data.mental_status.cognition_and_orientation.oriented_to_situation" />
        <Checkbox label="Other" model="data.mental_status.cognition_and_orientation.other" />
      </CheckboxGroup>
      <ContextShow when="data.mental_status.cognition_and_orientation.other" is={true}>
        <SmartTextarea useDictation placeholder="Other" rows={2} model="data.mental_status.cognition_and_orientation.other_extra" />
      </ContextShow>

      <SmartTextarea useDictation label="Notes on Cognition and Orientation" model="data.mental_status.cognition_and_orientation.notes" />
    </FormSection>
  </Flex>
)

export default MentalStatusForm
