import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { parse } from 'query-string'
import size from 'lodash/size'

import { SummonOverlay, Button, Markup, PageLayout, PageSection } from '@behavehealth/components'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet, useCreate } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import Notifications from '@behavehealth/modules/notifications'

import Card from '@behavehealth/components/Card'
import DeleteDialog from '@behavehealth/components/Dialogs/DeleteDialog'
import Divider from '@behavehealth/components/Divider'
import Flex from '@behavehealth/components/Flex'
import Glyph from '@behavehealth/components/Glyph'
import Icon from '@behavehealth/components/Icon'
import InternalBillingPaymentMethods from '@behavehealth/constructs/PaymentMethods/InternalBillingPaymentMethods'
import InternalNotesOverlay from '@behavehealth/components/Billing/InternalNotesOverlay'
import PillTab from '@behavehealth/components/PillTab'
import PillTabList from '@behavehealth/components/PillTabList'
import State from '@behavehealth/components/State'
import Status from '@behavehealth/components/Status'
import StripePaymentMethods from '@behavehealth/components/Stripe/behave/StripePaymentMethods'
import Tabs from '@behavehealth/components/Tabs'

import { EHRImportQuoteTemplateOverlay } from '@behavehealth/constructs/QuoteTemplates/EHRImportQuoteTemplateOverlay'
import { QuoteOverlay } from '@behavehealth/constructs/Quotes/QuoteOverlay'
import { QuoteAdditionalProducts } from '@behavehealth/constructs/QuoteReview/QuoteAdditionalProducts'
import { QuoteReview } from '@behavehealth/constructs/QuoteReview/QuoteReview'
import { ReviewSection } from '@behavehealth/constructs/QuoteReview/components/ReviewSection'
import { InvoicesDataTable } from '@behavehealth/constructs/InternalBilling/InvoicesDataTable'

export const PlansBilling = () => {
  const { tenant, isBehave } = useSettings()
  const location = useLocation()
  const navigate = useNavigate()

  const { data: quotes, isLoading: isLoadingQuotes } = useGet({
    name: ['quotes'],
    url: '/quotes',
  })

  const { mutateAsync: importQuoteTemplate } = useCreate({
    name: ['import-quote'],
    url: '/quotes/import',
    invalidate: 'quotes',
  })

  const [activeQuoteId, setActiveQuoteId] = React.useState(null)

  const activeQuote = React.useMemo(() => {
    return quotes?.find?.((quote: any) => quote.id === activeQuoteId) || null
  }, [activeQuoteId, quotes])

  const handleImportQuoteTemplate = async (template: any) => {
    if (!template) return

    try {
      await importQuoteTemplate(template)

      Notifications.send('Quote Template Imported Successfully', 'positive')
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (size(quotes) === 0 || !!activeQuote) return

    const { quote: quoteId }: any = parse(window.location.search)

    if (!!quoteId && quoteId !== activeQuoteId) {
      setActiveQuoteId(quoteId)
    } else if (!activeQuoteId) {
      const firstQuoteId = quotes?.[0]?.id

      setActiveQuoteId(firstQuoteId)

      navigate({
        pathname: location.pathname,
        search: `?quote=${firstQuoteId}`,
      })
    }
  }, [activeQuote, activeQuoteId, quotes])

  return (
    <PageLayout>
      {(isBehave || tenant?.metadata?.invoices_notes) && (
        <PageSection>
          <PageSection.Header
            graphic={
              <div className="flex items-center justify-center w-6 h-6">
                <Glyph glyph="note" size={18} />
              </div>
            }
            after={
              <Flex gap="0.5rem">
                <SummonOverlay
                  isPermissible={isBehave}
                  overlay={<InternalNotesOverlay title="Edit Notes" data={tenant} model="metadata.invoices_notes" />}
                >
                  <Button label="Edit Notes" glyph="edit" type="default" size={200} permission={isBehave} />
                </SummonOverlay>
              </Flex>
            }
          >
            <PageSection.Title title="Notes" />
          </PageSection.Header>

          {tenant?.metadata?.invoices_notes && (
            <PageSection.Content>
              <Card className="!py-2 !px-4">
                <Markup value={tenant?.metadata?.invoices_notes} />
              </Card>
            </PageSection.Content>
          )}
        </PageSection>
      )}

      <PageSection>
        <InternalBillingPaymentMethods record={tenant} />
      </PageSection>

      <PageSection>
        <PageSection.Header
          graphic={
            <div className="flex items-center justify-center w-6 h-6">
              <Icon icon="quotes" size={20} />
            </div>
          }
          after={
            isBehave && (
              <>
                <Flex gap="0.75rem">
                  <SummonOverlay overlay={<EHRImportQuoteTemplateOverlay onImport={handleImportQuoteTemplate} />}>
                    <Button label="Import from Quote Templates…" glyph="add" type="default" size={200} />
                  </SummonOverlay>

                  <SummonOverlay overlay={<QuoteOverlay dataID="new" initialData={{ facility: tenant }} />}>
                    <Button label="Add New Quote" glyph="add" type="primary" size={200} />
                  </SummonOverlay>
                </Flex>
              </>
            )
          }
        >
          <Flex centerY gap="0.75rem">
            <PageSection.Title title={size(quotes) === 1 ? 'Quote' : 'Quotes'} />
          </Flex>
        </PageSection.Header>

        {size(quotes) > 0 && (
          <Tabs
            activeTab={activeQuoteId}
            onChange={(tabId) => {
              navigate({
                pathname: location.pathname,
                search: `?quote=${tabId}`,
              })
              setActiveQuoteId(tabId)
            }}
          >
            <PillTabList className="px-0 pb-4">
              {quotes?.map?.((quote: any) => {
                const isPaid = quote?.status === 'paid' || quote?.status === 'completed'

                return (
                  <PillTab
                    key={quote.id}
                    name={quote.id}
                    label={
                      <span className="flex items-center flex-nowrap">
                        {quote?.name}

                        {isPaid && <Status className="ml-1.5" small label="Active" color="green" />}
                      </span>
                    }
                  />
                )
              })}
            </PillTabList>
          </Tabs>
        )}

        {isLoadingQuotes || !activeQuote ? (
          <Card>
            <State
              isLoading={isLoadingQuotes}
              isEmpty={!isLoadingQuotes && !activeQuote}
              title="Quote"
              icon="quotes"
              emptyDescription="No quote added yet"
              minHeight={200}
            />
          </Card>
        ) : (
          <>
            <Card>
              <QuoteReview
                key={activeQuote?.id}
                isLoading={isLoadingQuotes}
                dataID={activeQuote?.id}
                before={
                  isBehave && (
                    <ReviewSection
                      title={activeQuote?.name}
                      icon="quotes"
                      className="!py-0"
                      titleAfter={
                        <div className="ml-auto pr-3">
                          <Flex gap="0.75rem">
                            <Button
                              useGlyphForTarget
                              label="Open Public Quote"
                              glyph="link"
                              type="minimal"
                              target="_blank"
                              size={200}
                              href={activeQuote?.public_url}
                            />

                            {activeQuote?.status !== 'paid' && activeQuote?.status !== 'completed' && (
                              <QuoteReset quoteId={activeQuote?.id} />
                            )}

                            <SummonOverlay overlay={<QuoteOverlay dataID={activeQuote?.id} />}>
                              <Button label="Edit Quote" glyph="edit" type="default" size={200} />
                            </SummonOverlay>
                          </Flex>
                        </div>
                      }
                    />
                  )
                }
                after={
                  false && (
                    <>
                      <div className="flex justify-center relative">
                        <Status
                          label="Additional Products"
                          className="!bg-text !text-white !rounded-full !text-[0.85rem] !px-2 !py-0.5 relative z-[3]"
                        />
                        <div className="absolute left-0 right-0 h-[1px] w-full bg-divider top-[50%]" />
                      </div>

                      <div className="px-5 pb-5">
                        <QuoteAdditionalProducts quote={activeQuote} className="mt-6" />
                      </div>
                    </>
                  )
                }
              />
            </Card>
          </>
        )}
      </PageSection>

      <Divider />

      <Invoices />
    </PageLayout>
  )
}

const QuoteReset = ({ quoteId }) => {
  const { mutateAsync: resetQuote } = useCreate({
    name: ['reset-quote'],
    url: `/quotes/${quoteId}/reset`,
    invalidate: 'quotes',
  })

  if (!quoteId) return null

  return (
    <DeleteDialog
      title="Reset Quote?"
      glyph="reset"
      message="Are you sure you want to reset this Quote?"
      yesLabel="Reset Quote"
      onYes={async () => await resetQuote({})}
    >
      <Button label="Reset Quote…" glyph="reset" type="default" color="text" glyphColor="red" size={200} />
    </DeleteDialog>
  )
}

const Invoices = () => {
  const { isBehave, tenant } = useSettings()

  const tableProps = useDataTable({
    name: ['invoices'],
    endpoint: `/facilities/${tenant?.id}/invoices`,
  })

  return (
    <PageSection>
      <PageSection.Header
        graphic={
          <div className="flex items-center justify-center w-6 h-6">
            <Icon icon="invoices" size={20} />
          </div>
        }
        // after={
        //   <Flex gap="0.5rem">
        //     <SummonOverlay overlay={<InvoiceOverlay dataID="new" />}>
        //       <Button label="Add Invoice" glyph="add" type="primary" size={200} permission={isBehave} />
        //     </SummonOverlay>
        //   </Flex>
        // }
      >
        <PageSection.Title title="Invoices" />
      </PageSection.Header>

      <InvoicesDataTable {...tableProps} to={(data: any) => `invoices/${data.id}`} hiddenColumnIds={['facility']} />
    </PageSection>
  )
}
