import React from 'react'
import sortBy from 'lodash/sortBy'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Card, Chotomate, Grid, Page, Table, TableCell, Tabs } from '@behavehealth/components'

import { RECURRING_DAYS, RECURRING_FREQUENCIES } from '@behavehealth/utils/recurrence'
import { SERVICE_CATEGORIES } from '@behavehealth/utils/constants'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { OrganizationServiceOverlay } from '@behavehealth/constructs/Financials/OrganizationServiceOverlay'

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ cell }: any) => <TableCell.MainLink as={NavLink} to={cell.row.original.id} cell={cell} label={cell.value} />,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }: any) => (row.original.archived_at ? 'Archived' : 'Active'),
  },
  {
    Header: 'Category',
    accessor: 'category',
    Cell: ({ value }: any) => SERVICE_CATEGORIES[value]?.label || '–',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: TableCell.Amount,
  },
  {
    Header: 'Order',
    accessor: 'order',
    width: 100,
  },
  {
    Header: 'Repeating',
    accessor: 'is_recurring',
    Cell: (props) => {
      const isRecurring = props.value
      const frequency = props.row.original.recurring_frequency
      let dueDay = props.row.original[`recurring_${frequency}_due_day`]

      // if not recurring, return n/a
      if (!isRecurring) return <TableCell.NotApplicable />

      // find label
      const label = RECURRING_FREQUENCIES[frequency].label

      // return only label if daily
      if (frequency === 'daily') return label

      // monthly's array index is +1, so we need to accomodate
      if (frequency === 'monthly') dueDay -= 1

      // find label for due day integer
      const dueDayLabel = RECURRING_DAYS[frequency][dueDay]

      return `${label} (${dueDayLabel})`
    },
  },
]

const pageConfig = {
  title: 'Organization Services',
  feature: 'services_book',
  marketingID: 'settings_services_book',
}

const OrganizationServices: React.FC = () => {
  return (
    <>
      <Page
        actions={
          <Button
            as={Link}
            label="Add Service"
            link="new"
            type="primary"
            glyph="add"
            size={300}
            display="inline-flex"
            permission="settings.services_book.create"
          />
        }
        {...pageConfig}
      >
        <Grid gap="1rem" columns="100%">
          <Tabs defaultTab="active">
            <Tabs.List className="-mt-5 mb-4">
              <Tabs.Item label="Active" name="active" glyph="tick_circle" />
              <Tabs.Item label="Archived" name="archived" glyph="empty" />
            </Tabs.List>

            <Tabs.Panels>
              <Tabs.Panel type="mount" name="active">
                <OrganizationServicesActive />
              </Tabs.Panel>

              <Tabs.Panel type="mount" name="archived">
                <OrganizationServicesArchived />
              </Tabs.Panel>
            </Tabs.Panels>
          </Tabs>
        </Grid>

        <Chotomate ready name="services_book" />
      </Page>

      <AnimatedRoutes>
        <Route path=":id" element={<OrganizationServiceOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const OrganizationServicesActive = () => {
  const queryParams = {
    service_type: 'organization',
    status: 'active',
  }

  const queryKey = ['services', queryParams]

  const { data, isLoading }: any = useGet({
    name: queryKey,
    url: `/services`,
    params: queryParams,
  })

  const sortedData = React.useMemo(() => {
    if (!data) return []
    return sortBy(data, 'order', 'created_at')
  }, [data])

  return (
    <Card>
      <Table data={sortedData} columns={columns} isLoading={isLoading} localStorageKey="settings_services_book_active" />
    </Card>
  )
}

const OrganizationServicesArchived = () => {
  const queryParams = {
    service_type: 'organization',
    status: 'archived',
  }

  const queryKey = ['services', queryParams]

  const { data, isLoading }: any = useGet({
    name: queryKey,
    url: `/services`,
    params: queryParams,
  })

  const sortedData = React.useMemo(() => {
    if (!data) return []
    return sortBy(data, 'order', 'created_at')
  }, [data])

  return (
    <Card>
      <Table data={sortedData} columns={columns} isLoading={isLoading} localStorageKey="settings_services_book_archived" />
    </Card>
  )
}

export default withPageError(withMarketing(OrganizationServices, pageConfig))
