import React from 'react'
import { useMedia } from 'use-media'
import { Route, Switch, Redirect, useLocation, useRouteMatch } from 'react-router-dom'

import { BREAKPOINT } from '@behavehealth/theme'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import All from './daily_notes/all'
import Individual from './daily_notes/individual'
import Group from './daily_notes/group'

import { Dropdown, DropdownItem, Grid, HelpTagIframe, Page, Tab, TabList, Tabs } from '@behavehealth/components'
import { DailyNotesDropdown } from '@behavehealth/constructs/DailyNotes/DailyNotesDropdown'

const pageConfig = {
  feature: 'daily_notes',
  help: <HelpTagIframe id="daily_notes" />,
  marketingID: 'daily_notes',
}

const DailyNotes: React.FC = () => {
  const { url } = useRouteMatch()

  const mq4 = useMedia({ minWidth: BREAKPOINT[4] })

  return (
    <Page {...pageConfig} actions={<DailyNotesDropdown />}>
      <Grid gap={16}>
        {mq4 ? (
          <Tabs className="-mt-4">
            <TabList>
              <Tab label="All Peer Notes" to={`${url}/all`} />
              <Tab label="Individual" to={`${url}/individual`} />
              <Tab label="Group" to={`${url}/group`} />
            </TabList>
          </Tabs>
        ) : (
          <Dropdown label="Select Note Type" icon="daily_notes" position="bottom">
            <DropdownItem label="All Daily Notes" icon="daily_notes" link={`${url}/all`} />
            <DropdownItem label="Individual" icon="daily_notes" link={`${url}/individual`} />
            <DropdownItem label="Group" icon="daily_notes" link={`${url}/group`} />
          </Dropdown>
        )}

        <Switch>
          <Route path="/(employees|me)/:resource_id/daily-notes/all" component={All} />
          <Route path="/(employees|me)/:resource_id/daily-notes/individual" component={Individual} />
          <Route path="/(employees|me)/:resource_id/daily-notes/group" component={Group} />

          <Redirect exact from={url} to={`${url}/all`} />
        </Switch>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(DailyNotes, pageConfig))
