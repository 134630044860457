import React from 'react'
import { connect } from 'react-redux'

import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ContextShow from '../../Forms/ContextShow'
import DateInput from '../../Forms/DateInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Select from '../../Forms/Select'
import StateSelect from '../../Forms/elements/StateSelect'
import Textarea from '../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class StaffCredentialOverlay extends OverlayBase {
  renderHeader = () => <Overlay.Header icon="staff_credentials" title="Credential" />

  renderContent = () => {
    const { $editable, $new } = this.state
    const { reference, record, tenant } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={record}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          linked={
            $new && {
              reference_id: reference?.id,
              reference_type: reference?.type,
            }
          }
        >
          <Section>
            <FormSection maxWidth="100%">
              <Input label="Credential" description="e.g. A-GNP" model="credential" />
              <Input label="Certification" description="e.g. Adult-Gerontology Primary Care Nurse Practitioner" model="certification" />
              <Input label="Organization" description="e.g. American Academy of Nurse Practitioners" model="organization" />

              <Flex gap="1rem" stretchChildrenX>
                <Select label="Status" model="status">
                  <Option label="Current" model="current" value="current" />
                  <Option label="Renewed & Current" model="renewed_and_current" value="renewed_and_current" />
                  <Option label="Expired" model="expired" value="expired" />
                </Select>

                <Select label="Level" model="level">
                  <Option label="National" value="national" />
                  <Option label="Regional" value="regional" />
                  <Option label="State" value="state" />
                  <Option label="Association" value="association" />
                </Select>
              </Flex>

              <ContextShow when="level" is={'state'}>
                <Flex gap="1rem" stretchChildrenX>
                  <StateSelect
                    model="state"
                    validations={{
                      presence: {
                        message: 'Please select a state',
                      },
                    }}
                  />
                </Flex>
              </ContextShow>

              <Flex gap="1rem" stretchChildrenX>
                <DateInput label="Issue Date" model="issued_at" />
                <DateInput label="Expiration Date" model="expires_at" />
              </Flex>

              <Flex gap="1rem">
                <Input label="License Number" model="license_number" className="!flex-1" />
                <Input label="Graduation Year" size={6} model="graduation_year" />
              </Flex>

              <CheckboxGroup layout="vertical-dense">
                <Checkbox label="Requires Continued Education" model="continued_education_required" />
              </CheckboxGroup>

              <ContextShow when="continued_education_required" is={true}>
                <Flex gap="1rem" stretchChildrenX>
                  <Input type="number" min="0" label="Current Credits" model="current_credits" defaultValue={0} />
                  <Input type="number" min="0" label="Required Annual Credits" model="required_credits" defaultValue={12} />
                </Flex>
              </ContextShow>

              <Textarea label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Email Reminders" description="Notify credential expiry">
            <FormSection maxWidth="100%" layout="vertical">
              <Checkbox label="Send Email Reminders" model="should_send_reminders" />
              <ContextShow when="should_send_reminders" is={true}>
                <div className="!pl-6">
                  <CheckboxGroup
                    label="Send Reminders Options"
                    layout="vertical-dense"
                    validations={{
                      presence: {
                        message: 'Please select at least one option',
                      },
                    }}
                  >
                    <Checkbox label="6 months before expiry" model="send_reminders.six_months" />
                    <Checkbox label="3 months before expiry" model="send_reminders.three_months" />
                    <Checkbox label="1 month before expiry" model="send_reminders.one_month" />
                    <Checkbox label="2 weeks before expiry" model="send_reminders.two_weeks" />
                    <Checkbox label="1 week before expiry" model="send_reminders.one_week" />
                    <Checkbox label="1 day before expiry" model="send_reminders.one_day" />
                  </CheckboxGroup>
                </div>
              </ContextShow>
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload files related to this Credential">
            <FormSection maxWidth="100%" layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save Credential"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="credentials.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Credential"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="credentials.edit"
            />

            <DeleteDialog
              title="Delete Credential?"
              message="Are you sure you want to delete this credential? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                fullWidth
                permission="credentials.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'credentials')

export default connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(StaffCredentialOverlay))
