import { BooleanFilter } from './headerFilters/BooleanFilter'
import { DateTimeFilter } from './headerFilters/DateTimeFilter'
import { GlobalTagsFilter } from './headerFilters/GlobalTagsFilter'
import { MultiObjectFilter } from './headerFilters/MultiObjectFilter'
import { MultiOverlayFilter } from './headerFilters/MultiOverlayFilter'
import { MultiSelectFilter } from './headerFilters/MultiSelectFilter'
import { NumberFilter } from './headerFilters/NumberFilter'
import { SmartFlags } from './headerFilters/SmartFlags'
import { StringFilter } from './headerFilters/StringFilter'
import { TagsFilter } from './headerFilters/TagsFilter'

export const HEADER_FILTERS: any = {
  boolean: BooleanFilter,
  date: DateTimeFilter,
  date_time: DateTimeFilter,
  global_tags: GlobalTagsFilter,
  multi_object: MultiObjectFilter,
  multi_overlay: MultiOverlayFilter,
  multi_select: MultiSelectFilter,
  number: NumberFilter,
  smart_flags: SmartFlags,
  string: StringFilter,
  tags: TagsFilter,
}
