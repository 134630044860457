import React from 'react'
import { tint } from 'polished'

import { COLORS, ICONS } from '../../theme'

import Icon from '../../components/Icon'

export const ResourceTag: React.FC<any> = ({ data, isSmall }) => {
  if (!data.type) return null

  const { icon, label, color } = getInfo(data)

  return (
    <div css={styles.root} style={{ backgroundColor: tint(0.75, color || icon.color) }} className={isSmall ? 'is-small' : ''}>
      {icon && <Icon icon={icon} size={isSmall ? 14 : 16} className="icon" />}
      <span className="label">{label}</span>
    </div>
  )
}

const getInfo = (data: any) => {
  let icon = null
  let label = null
  let color = null

  if (CONFIG[data.type]?.[data.status]) {
    const config = CONFIG[data.type][data.status]
    icon = config.icon
    label = config.label
    color = config.color
  } else if (CONFIG[data.type]?.icon) {
    const config = CONFIG[data.type]
    icon = config.icon
    label = config.label
    color = config.color
  }

  return { icon, label, color }
}

const CONFIG: any = {
  resident: {
    current: {
      icon: ICONS.clients,
      label: 'Client',
    },
    lead: {
      icon: ICONS.admissions,
      label: 'Applicant',
    },
    intake: {
      icon: ICONS.admissions,
      label: 'Accepted Applicant',
    },
    alumni: {
      icon: ICONS.alumni_relations,
      label: 'Alumni',
    },
    declined: {
      icon: ICONS.admissions,
      label: 'Declined Applicant',
      color: 'red',
    },
  },
  employee: {
    icon: ICONS.employees,
    label: '',
  },
}

const styles = {
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0.12rem',
    paddingLeft: '0.55rem',
    paddingRight: '0.75rem',
    borderRadius: 4,
    color: COLORS.text,

    '.icon': {
      marginRight: '0.35rem',
    },

    '.label': {
      fontWeight: 600,
      fontSize: '0.9rem',
    },

    '&.is-small': {
      fontWeight: 500,
      fontSize: '0.82rem',
      padding: '0.06rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.66rem',
    },
  },
}
