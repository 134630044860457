import React from 'react'
import { Route } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { NotionNotice } from '@behavehealth/components/NotionNotice'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { ProgramAssignmentOverlay } from '@behavehealth/constructs/Programs/ProgramAssignmentOverlay'
import { ProgramAssignSeatsOverlay } from '@behavehealth/constructs/Programs/ProgramAssignSeatsOverlay'
import { ProgramListOverlay } from '@behavehealth/constructs/Programs/ProgramListOverlay'
import { ProgramMoveOverlay } from '@behavehealth/constructs/ClientWorkflows/ProgramMoveOverlay'
import { ProgramPhasesReorderOverlay } from '@behavehealth/constructs/Programs/ProgramPhasesReorderOverlay'
import { ProgramRemoveOverlay } from '@behavehealth/constructs/ClientWorkflows/ProgramRemoveOverlay'
import { ProgramSeatOverlay } from '@behavehealth/constructs/Programs/ProgramSeatOverlay'
import { ProgramsTimeline } from '@behavehealth/constructs/Programs/ProgramsTimeline'

const RootProgramsTimelinePage = () => {
  const { url } = useRouteURL()

  return (
    <>
      <ProgramsTimelinePageIndex />

      <AnimatedRoutes>
        <Route path=":resource_id/reorder" element={<ProgramPhasesReorderOverlay useV6Router back={url} />} />
        <Route path=":resource_id/lists/:id" element={<ProgramListOverlay useV6Router back={url} />} />
        <Route path=":resource_id/lists/:phase_id/seats/:id" element={<ProgramSeatOverlay useV6Router back={url} />} />
        <Route path=":resource_id/lists/:phase_id/assign-seats" element={<ProgramAssignSeatsOverlay useV6Router back={url} />} />
        <Route
          path=":resource_id/lists/:phase_id/seats/:seat_id/:id"
          element={<ProgramAssignmentOverlay useV6Router back={url} isEditable />}
        />
        <Route path=":occupancy_id/move" element={<ProgramMoveOverlay useV6Router back={url} />} />
        <Route path=":occupancy_id/remove" element={<ProgramRemoveOverlay useV6Router back={url} />} />
        <Route path="assignments/:id" element={<ProgramAssignmentOverlay useV6Router back={url} isEditable />} />
      </AnimatedRoutes>
    </>
  )
}

const ProgramsTimelinePageIndex = () => {
  const [dates, setDates]: any = React.useState({})
  const [filters, setFilters] = React.useState({})

  const hasDates = !!dates?.startDate && !!dates?.endDate

  const queryParams = {
    filters,
    ...(hasDates && {
      from: dates.startDate,
      until: dates.endDate,
    }),
  }

  const apiKey = ['programs-timeline', queryParams]

  const { data, isLoading, isRefetching }: any = useGet({
    name: apiKey,
    url: `/program_management/timeline`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: hasDates },
  })

  const occupanciesByPhaseIds = React.useMemo(() => {
    const result = {}

    if (size(data?.occupancies) === 0) return result

    for (const occupancy of data.occupancies) {
      if (!occupancy.place?.phase?.id) continue
      if (!result[occupancy.place.phase.id]) result[occupancy.place.phase.id] = []

      result[occupancy.place.phase.id].push(occupancy)
    }

    return result
  }, [data])

  return (
    <div className="grid grid-rows-[min-content_auto]">
      <div className="p-4">
        <NotionNotice isOpen={false} type="neutral" header="Program Management Notice" notionID="4da58276ad5943e8965773ef60ab7d7c" />
      </div>

      <div css={styles.root}>
        <ProgramsTimeline
          programs={data?.programs}
          applicants={data?.applicants}
          unassigned={data?.unassigned}
          occupanciesByPhaseIds={occupanciesByPhaseIds}
          isLoading={isLoading}
          isRefetching={isRefetching}
          dates={dates}
          setDates={setDates}
        />
      </div>
    </div>
  )
}

const styles: any = {
  root: {
    // padding: '1rem',
    // paddingTop: '1rem',
    paddingLeft: '1rem',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    overflow: 'hidden',
  },
}

export const ProgramsTimelinePage = withPageError(RootProgramsTimelinePage)
