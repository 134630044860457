import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'

import { SummonOverlay, Button, Card, PageLayout, PageSection, Flex, Markup } from '@behavehealth/components'
import { BillingCreditsTable, BillingDiscountsTable } from '@behavehealth/components/Tables'
import InternalNotesOverlay from '@behavehealth/components/Billing/InternalNotesOverlay'
import { DiscountsDataTable } from '@behavehealth/constructs/InternalBilling/DiscountsDataTable'

import { useAPI } from '@behavehealth/hooks'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Discounts = () => {
  const tenant = useSelector((state) => state.me.tenant)

  // const discounts = useSelector((state) => state.data.discounts?.data)
  // const credits = useSelector((state) => state.data.credits?.data)

  // useAPI('discounts', '/discounts')
  // useAPI('credits', '/credits')

  const tableProps = useDataTable({
    name: ['discounts'],
    endpoint: '/discounts',
    localStorageKey: 'discounts_v1',
  })

  return (
    <PageLayout>
      <PageSection>
        <PageSection.Header
          after={
            <Flex gap="0.5rem">
              <SummonOverlay overlay={<InternalNotesOverlay title="Edit Notes" data={tenant} model="metadata.discounts_notes" />}>
                <Button label="Edit Notes" glyph="edit" type="default" size={200} />
              </SummonOverlay>
            </Flex>
          }
        >
          <PageSection.Title title="Internal Notes" />
        </PageSection.Header>

        <PageSection.Content>
          <Card className="!py-0 !px-4">
            <Markup value={tenant?.metadata?.discounts_notes || '–'} />
          </Card>
        </PageSection.Content>
      </PageSection>

      <PageSection>
        <PageSection.Header
          after={
            <Flex gap="0.5rem">
              <Button as={Link} label="Import Discounts" glyph="circle_add" size={200} link={`import-discounts`} />
              <Button as={Link} label="Add Discount" glyph="add" type="primary" size={200} link={`discounts/new`} />
            </Flex>
          }
        >
          <PageSection.Title title="Discounts" />
        </PageSection.Header>

        <PageSection.Content>
          <DiscountsDataTable {...tableProps} title="Discounts" to={(data: any) => data.id} hiddenColumnIds={['facility']} />
          {/* <Card>
						<BillingDiscountsTable mainLinkAs={NavLink} data={discounts} to={(id: string) => `discounts/${id}`} />
					</Card> */}
        </PageSection.Content>
      </PageSection>

      {/* <PageSection>
				<PageSection.Header
					after={<Button as={Link} label="Add Credit" glyph="add" type="primary" size={200} link={`credits/new`} />}
				>
					<PageSection.Title title="Credits" />
				</PageSection.Header>

				<PageSection.Content>
					<Card>
						<BillingCreditsTable mainLinkAs={NavLink} data={credits} to={(id: string) => `credits/${id}`} />
					</Card>
				</PageSection.Content>
			</PageSection> */}
    </PageLayout>
  )
}

export default Discounts
