import React from 'react'
import SignaturePad from 'signature_pad'

class SignatureCanvas extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isEmpty: true,
      width: props.width,
      height: props.height,
    }

    this.signaturePad = null
    this.hooking = null
    this.canvasRef = React.createRef()
  }

  /*
    LIFECYCLE
  */
  componentDidMount = () => {
    this.hookSignaturePad()
  }

  componentDidUpdate = () => {
    if (this.props.onUpdate) this.props.onUpdate(this.state)
  }

  componentWillUnmount = () => {
    if (this.hooking) clearTimeout(this.hooking)
    if (this.signaturePad) this.signaturePad.off()
  }

  /*
    CUSTOM FUNCTIONS
  */
  hookSignaturePad = () => {
    this.hooking = setTimeout(() => {
      if (this.signaturePad) return

      if (this.canvasRef.current) {
        this.signaturePad = new SignaturePad(this.canvasRef.current, {
          ...this.props.options,
          onEnd: this.onUpdate,
        })
        this.onResize()
      } else {
        this.hookSignaturePad()
      }
    }, 200)
  }

  checkClearOnResize = () => {
    if (!this.props.clearOnResize) {
      return
    }
    this.onResize()
  }

  onUpdate = () => {
    this.setState({ isEmpty: false })
  }

  onResize = () => {
    if (!this.canvasRef.current) return

    let ratio = Math.max(window.devicePixelRatio || 1, 1)
    let canvas = this.canvasRef.current

    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)

    this.setState({ width: canvas.offsetWidth, height: canvas.offsetHeight })
  }

  clear = () => {
    this.setState({ isEmpty: true })
    return this.signaturePad.clear()
  }

  isEmpty = () => {
    if (!this.signaturePad) return true
    return this.signaturePad.isEmpty()
  }

  getSignature = () => {
    return this.toDataURL()
  }

  fromDataURL = (dataURL, options) => {
    return this.signaturePad.fromDataURL(dataURL, options)
  }

  toDataURL = (type, encoderOptions) => {
    return this.signaturePad.toDataURL(type, encoderOptions)
  }

  fromData = (pointGroups) => {
    return this.signaturePad.fromData(pointGroups)
  }

  toData = () => {
    return this.signaturePad.toData()
  }

  /*
    RENDER
  */
  render() {
    const { width, height } = this.state

    return (
      <div css={styles} style={{ width, height }}>
        <canvas ref={this.canvasRef} {...this.props.canvasProps} />
      </div>
    )
  }
}

const styles = {
  display: 'inherit',
  position: 'relative',

  canvas: {
    width: '100%',
    height: '100%',
    touchAction: 'none',
    MsTouchAction: 'none',
  },
}

SignatureCanvas.defaultProps = {
  width: '100%',
  height: 200,
  clearOnResize: true,
  options: {
    throttle: 8,
    minDistance: 3,
    penColor: 'black',
    minWidth: 1,
    maxWidth: 3,
    velocityFilterWeight: 0.3,
  },
}

export default SignatureCanvas
