import COLORS from './colors'

import logoBehaveHealth from '../../assets/logos/logo_behave_health.svg'
import logoBehaveHealthSmall from '../../assets/logos/logo_behave_health_small.svg?url'
import logoEhr from '../../assets/logos/logo_ehr.svg?url'
import logoBridge from '../../assets/logos/logo_bridge.svg?url'
import logoLedger from '../../assets/logos/logo_ledger.svg?url'
import logoPortal from '../../assets/logos/logo_portal.svg?url'
import logoBehaveAdmin from '../../assets/logos/logo_behave_admin.svg?url'
import logoWebsiteBuilder from '../../assets/logos/logo_website_builder.svg?url'
import logoBehaveSites from '../../assets/logos/logo_behave_sites.svg?url'
import logoCommunity from '../../assets/logos/logo_community.svg?url'
import logoBehaveCommunityAdmin from '../../assets/logos/behave_community_admin_logo.svg?url'
import logoMembers from '../../assets/logos/logo_members.svg?url'
import logoBehaveSales from '../../assets/logos/logo_sales.svg?url'

const LOGOS = {
  behave_health: {
    alt: 'BEHAVE HEALTH Logo',
    color: COLORS.violet,
    file: logoBehaveHealth,
    width: 181,
    height: 24,
    small: {
      alt: 'BEHAVE HEALTH Logo',
      color: COLORS.violet,
      file: logoBehaveHealthSmall,
      height: 24,
      width: 24,
    },
  },
  bridge: {
    alt: 'Bridge Logo',
    color: COLORS.gray,
    file: logoBridge,
    width: 189,
    height: 24,
    small: {
      alt: 'Bridge Logo',
      color: COLORS.gray,
      file: logoBehaveHealthSmall,
      height: 24,
      width: 24,
    },
  },
  community: {
    alt: 'Community Logo',
    color: COLORS.gray,
    file: logoCommunity,
    width: 230,
    height: 24,
    // small: {
    //   alt: 'Community Logo',
    //   color: COLORS.gray,
    //   file: logoBehaveHealthSmall,
    //   height: 24,
    //   width: 24,
    // },
  },
  members: {
    alt: 'Members Logo',
    color: COLORS.gray,
    file: logoMembers,
    width: 206,
    height: 24,
    // small: {
    //   alt: 'Community Logo',
    //   color: COLORS.gray,
    //   file: logoBehaveHealthSmall,
    //   height: 24,
    //   width: 24,
    // },
  },
  default: {
    alt: 'BEHAVE HEALTH Logo',
    color: COLORS.violet,
    file: logoBehaveHealth,
    width: 181,
    height: 24,
    small: {
      alt: 'BEHAVE HEALTH Logo',
      color: COLORS.violet,
      file: logoBehaveHealthSmall,
      height: 24,
      width: 24,
    },
  },
  ehr: {
    alt: 'EHR Logo',
    color: COLORS.gray,
    file: logoEhr,
    width: 160,
    height: 24,
    small: {
      alt: 'EHR Logo',
      color: COLORS.gray,
      file: logoBehaveHealthSmall,
      height: 24,
      width: 24,
    },
  },
  ledger: {
    alt: 'Ledger Logo',
    color: COLORS.gray,
    file: logoLedger,
    width: 193,
    height: 24,
    small: {
      alt: 'Ledger Logo',
      color: COLORS.gray,
      file: logoBehaveHealthSmall,
      height: 24,
      width: 24,
    },
  },
  portal: {
    alt: 'Portal Logo',
    color: COLORS.gray,
    file: logoPortal,
    width: 193,
    height: 24,
    small: {
      alt: 'Portal Logo',
      color: COLORS.gray,
      file: logoBehaveHealthSmall,
      height: 24,
      width: 24,
    },
  },
  admin: {
    alt: 'BH Admin Logo',
    color: COLORS.gray,
    file: logoBehaveAdmin,
    width: 193,
    height: 24,
    small: {
      alt: 'Admin Logo',
      color: COLORS.gray,
      file: logoBehaveHealthSmall,
      height: 24,
      width: 24,
    },
  },
  sales: {
    alt: 'BH Sales Logo',
    color: COLORS.gray,
    file: logoBehaveSales,
    width: 193,
    height: 24,
    small: {
      alt: 'Sales Logo',
      color: COLORS.gray,
      file: logoBehaveHealthSmall,
      height: 24,
      width: 24,
    },
  },
  community_admin: {
    alt: 'BH Community Admin Logo',
    color: COLORS.gray,
    file: logoBehaveCommunityAdmin,
    width: 162,
    height: 24,
  },
  website_builder: {
    alt: 'BH Website Builder',
    color: COLORS.gray,
    file: logoWebsiteBuilder,
    width: 288,
    height: 24,
    small: {
      alt: 'Admin Logo',
      color: COLORS.gray,
      file: logoBehaveHealthSmall,
      height: 24,
      width: 24,
    },
  },
  behave_sites: {
    alt: 'Behave Sites',
    color: COLORS.gray,
    file: logoBehaveSites,
    width: 172,
    height: 24,
    small: {
      alt: 'Admin Logo',
      color: COLORS.gray,
      file: logoBehaveHealthSmall,
      height: 24,
      width: 24,
    },
  },
}

export default LOGOS
