import React from 'react'
import startCase from 'lodash/startCase'

import { address } from '../../utils/functions'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import withSettings from '../../hocs/withSettings'

const columns = (to: Function = () => {}) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to?.(row.original.id)} label={value} avatar={row.original.avatar} />,
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 100,
    accessor: 'sex',
    Header: 'Sex',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'male',
        label: 'Male',
      },
      {
        value: 'female',
        label: 'Female',
      },
      {
        value: 'decline_to_respond',
        label: 'Decline To Respond',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  {
    width: 240,
    accessor: 'address',
    Header: 'Address',
    Cell: ({ value }: any) => <TableCell.Truncated value={address(value)} />,
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  timezone: string
  title: string
  to?: Function
}

const LocationsImportTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    localStorageKey,
    onClick,
    timezone,
    title,
    to,
    ...rest
  } = props

  return (
    <Table
      title="Locations"
      icon="properties"
      data={data}
      columns={columns(to)}
      emptyActions={emptyActions}
      emptyDescription="No locations are available to import"
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

LocationsImportTable.defaultProps = {
  localStorageKey: 'import_locations',
}

export default withSettings(LocationsImportTable)
