import React from 'react'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

import { APP_URLS } from '../../../../constructs/MyAccount/utils'

import { LinkCell } from '../../../DataTable/cells/LinkCell'
import Alert from '../../../Alert'
import Icon from '../../../Icon'

export const ShareablePartnersTable = ({ onSelect, dependentValue, excludeRowIds, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-partners'],
    endpoint: '/organizations',
    params: { status: 'partner', partnership_status: 'partnership_active' },
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_shareable_partners_v1',
  })

  const { data, meta } = React.useMemo(() => {
    if (!tableProps.data) return { data: [], meta: tableProps.meta || {} }

    const data: any = []

    for (const partnership of tableProps.data) {
      if (partnership.status !== 'active' || !partnership?.other_partner_settings?.partnership_share_allowed) continue

      if (excludeRowIds && excludeRowIds.includes(partnership.id)) continue

      data.push(partnership)
    }

    return {
      data,
      meta: {
        ...tableProps.meta,
        count: data.length,
      },
    }
  }, [tableProps.data])

  const columns = React.useMemo(
    () => [
      {
        title: 'Partner Business Name',
        model: 'other_partner.name',
        width: 280,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data?.other_partner?.name} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'Community Profile',
        model: 'other_partner.primary_profile.name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => {
          if (!data.other_partner?.primary_profile) return null

          const communityURL = `${APP_URLS.community}/profiles/${data.other_partner.primary_profile.slug}`

          return (
            <LinkCell
              isExternal
              before={<Icon icon="community" size={16} className="mr-1.5" />}
              value={data.other_partner.primary_profile.name}
              href={communityURL}
            />
          )
        },
      },
      {
        title: 'Phone',
        model: 'other_partner.phone_no',
        type: 'phone',
      },
      {
        title: 'Email',
        model: 'other_partner.email',
        type: 'email',
      },
      {
        title: 'Website',
        model: 'other_partner.url',
        type: 'external_url',
        disableSelect: true,
      },
      {
        title: 'Address',
        model: 'other_partner.address',
        type: 'address',
        width: 280,
        disableSort: true,
      },
    ],
    [],
  )

  return (
    <>
      <Alert small contrast glyph="info" className="mb-4">
        This table shows all partners who allowed you to share their community profile with other Partners or in their Community Profile
      </Alert>

      <DataTable {...tableProps} {...rest} data={data} meta={meta} icon="community_partners" title="Partners" columns={columns} />
    </>
  )
}
