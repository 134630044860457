import React from 'react'

import { ICONS } from '../../theme'
import { titleCase } from '../../utils/functions'
import { apiCreate, apiUpdate, apiDelete } from '../../modules/api'

import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Dialog from '../../components/Dialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Icon from '../../components/Icon'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import RichTextEditor from '../../components/Forms/RichTextEditor'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Input from '../../components/Forms/Input'
import URLInput from '../../components/Forms/URLInput'
import UserTypeSelect from '../../components/Billing/UserTypeSelect'
import OverlayLoader from '../../components/OverlayLoader'

import {
  GENERAL_FEATURES,
  ERP_FEATURES,
  CONTACTS_FEATURES,
  CHAT_FEATURES,
  CARE_COORDINATION_FEATURES,
  RCM_FEATURES,
  INSURANCE_FEATURES,
  NOTES_FEATURES,
  MEDICAL_FEATURES,
  CLINICAL_FEATURES,
  SECURITY_FEATURES,
  PRICING_UNIT_TYPES,
  YEARLY_PRICING_UNIT_TYPES,
} from '../../components/Billing/constants'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

const icons = Object.entries(ICONS).map(([key]) => ({
  id: key,
  name: titleCase(key),
}))

const FeaturesCheckboxGroup = ({ label, data }: any) => {
  if (!data) return null

  return (
    <CheckboxGroup withToggle label={label} layout="vertical-dense" trueIcon="check" falseStyle="hidden" falseIcon="cross">
      {data.map((feature: any) => (
        <Checkbox key={feature.model} icon={feature.icon?.id || 'default'} label={feature.title} model={`features.${feature.model}`} />
      ))}
    </CheckboxGroup>
  )
}

const RootServiceOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    updateAsync,
  } = useOverlay({
    name: 'products',
    endpoint: '/products',
    invalidate: 'products',
    options: props,
  })

  const { online } = useSettings()

  // rebuild icon data
  for (let i = 0; i < icons.length; i++) {
    if (icons[i].id === initialModel.icon) {
      initialModel.icon = icons[i]
      break
    }
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const toggleArchive = async () => {
    await updateAsync({ status: initialModel.status === 'archived' ? 'active' : 'archived' })
    close()
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header title={titleCase(initialModel?.category) || '–'} />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={{ category: 'service' }}
          decorate={(model: any) => ({
            icon: model?.icon?.id,
          })}
        >
          <Section>
            <FormSection>
              <Input
                label="Internal Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <Input label="Public Name" model="public_name" />

              <Input label="SKU" model="sku" />
              <URLInput label="Notion Link" model="notion_url" />

              <RichTextEditor label="Description" model="description" />

              <ObjectSelector
                isRelation={false}
                label="Icon"
                model="icon"
                apiData={icons}
                value={initialModel?.icon}
                selectTitle={(data: any) => (
                  <Flex nowrap centerY gap="0.5rem">
                    <Icon icon={data.id} size={20} />
                    <div>{data.name}</div>
                  </Flex>
                )}
              />

              <Select
                label="Pricing Strategy"
                model="pricing_type"
                layout="vertical-dense"
                validations={{
                  presence: {
                    message: 'Please select a pricing strategy',
                  },
                }}
              >
                <Option label="Monthly Payment Plan" value="payment_plan" />
                <Option label="Monthly Subscription" value="monthly" />
                <Option label="Annual Subscription" value="annually" />
                <Option label="Metered (Monthly)" value="metered_monthly" />
                <Option label="Metered (Annually)" value="metered_annually" />
                <Option label="One-Time Payment" value="one_time" />
                <Option label="Free" value="free" />
              </Select>

              <Select
                label="Billed"
                model="bill_type"
                layout="vertical-dense"
                defaultValue="end_of_cycle"
                validations={{
                  presence: {
                    message: 'Please select a billing type',
                  },
                }}
              >
                <Option label="At the End of Cycle" value="end_of_cycle" />
                <Option label="Upfront" value="upfront" />
              </Select>

              <Flex gap="1rem">
                <AmountInput
                  label="Price"
                  model="price"
                  validations={{
                    presence: {
                      message: 'Please enter a price',
                    },
                  }}
                />

                <ContextShow when="pricing_type" is="metered_monthly">
                  <Select label="Unit Type" model="price_unit_type" defaultValue="client_per_month">
                    {Object.entries(PRICING_UNIT_TYPES).map(([key, value]) => (
                      <Option label={value} value={key} />
                    ))}
                  </Select>
                </ContextShow>

                <ContextShow when="pricing_type" is="metered_annually">
                  <Select label="Unit Type" model="price_unit_type" defaultValue="client_per_year">
                    {Object.entries(YEARLY_PRICING_UNIT_TYPES).map(([key, value]) => (
                      <Option label={value} value={key} />
                    ))}
                  </Select>
                </ContextShow>

                <ContextShow when="price_unit_type" is="user_per_month">
                  <UserTypeSelect label="User Type" model="price_unit_subtype" />
                </ContextShow>
              </Flex>

              <RichTextEditor label="Internal Notes" model="internal_notes" />

              <CheckboxGroup label="Required Product">
                <Checkbox label="Show as required" model="is_required" />
              </CheckboxGroup>

              <CheckboxGroup label="Recommend Service">
                <Checkbox label="Show as recommended" model="is_recommended" />
              </CheckboxGroup>

              <Input label="Dynamic Filter 1" model="dynamic_filters.filter_1" />
              <Input label="Dynamic Filter 2" model="dynamic_filters.filter_2" />
              <Input label="Dynamic Filter 3" model="dynamic_filters.filter_3" />
            </FormSection>
          </Section>

          <Section title="Features">
            <FormSection>
              <FeaturesCheckboxGroup label="General" data={GENERAL_FEATURES} />
              <FeaturesCheckboxGroup label="ERP" data={ERP_FEATURES} />
              <FeaturesCheckboxGroup label="Contacts" data={CONTACTS_FEATURES} />
              <FeaturesCheckboxGroup label="Chat" data={CHAT_FEATURES} />
              <FeaturesCheckboxGroup label="Care Coordination" data={CARE_COORDINATION_FEATURES} />
              <FeaturesCheckboxGroup label="RCM" data={RCM_FEATURES} />
              <FeaturesCheckboxGroup label="Insurance" data={INSURANCE_FEATURES} />
              <FeaturesCheckboxGroup label="Notes" data={NOTES_FEATURES} />
              <FeaturesCheckboxGroup label="Medical" data={MEDICAL_FEATURES} />
              <FeaturesCheckboxGroup label="Clinical" data={CLINICAL_FEATURES} />
              <FeaturesCheckboxGroup label="Security" data={SECURITY_FEATURES} />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer online={online}>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
            />
            <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" />

            <Dialog
              title={initialModel?.status === 'archived' ? 'Activate Service?' : 'Archive Service?'}
              message="Are you sure you want to update this service?"
              onYes={toggleArchive}
            >
              <Button
                label={initialModel?.status === 'archived' ? 'Activate' : 'Archive'}
                glyph="empty"
                color="text"
                isLoading={isSaving}
              />
            </Dialog>

            {initialModel?.status === 'archived' && (
              <DeleteDialog title="Delete Service?" message="Are you sure you want to delete this service?" onYes={deleteRecord}>
                <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} />
              </DeleteDialog>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ServiceOverlay = withOverlayError(RootServiceOverlay)
