import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

import { INTEGRATIONS } from './constants'

const RootIntegrationOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    client,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    data,
    updateAsync,
  } = useOverlay({
    name: 'integrations',
    endpoint: '/integrations',
    invalidate: 'integrations',
    invalidateKeys: ['company_integrations', 'personal_integrations'],
    options: props,
  })

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const integrationConfig: any = data?.provider && INTEGRATIONS?.[data.provider]

  console.log(integrationConfig, 'integrationConfig')

  const IntegrationForm: any = integrationConfig?.component

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="company_integrations" title="Integration" />

      <Overlay.Content>
        <Section>
          {IntegrationForm && (
            <IntegrationForm form={form} initialModel={initialModel} isEditable={isEditable} onValidationUpdate={onValidationUpdate} />
          )}
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable ? (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
              // permission="company_integrations.edit"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" onClick={cancel} isDisabled={isSaving} />}
          </>
        ) : (
          <>
            <Button label="Edit" glyph="edit" type="default" onClick={edit} flex="100 1 auto" permission="ledger.edit" />

            <DeleteDialog
              title="Delete Company Integration?"
              message="Are you sure you want to delete this Company Integration? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                // permission="company_integrations.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const IntegrationOverlay = withOverlayError(RootIntegrationOverlay)
