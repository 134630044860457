import React from 'react'
import { connect } from 'react-redux'
import { transparentize } from 'polished'
import get from 'lodash/get'
import size from 'lodash/size'

import Button from '../../../Button'
import ContextHide from '../../../Forms/ContextHide'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Dialog from '../../../Dialog'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import HelpTagIframe from '../../../Help/HelpTagIframe'
import Input from '../../../Forms/Input'
import Nav from '../../../Nav'
import NoInternet from '../../../Alerts/NoInternet'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Option from '../../../Forms/Option'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import PageGrid from '../../../PageGrid'
import ScrollView from '../../../ScrollView'
import Section from '../../../Section'
import Select from '../../../Forms/Select'
import Switch from '../../../Forms/Switch'
import Textarea from '../../../Forms/Textarea'

import { apiCreate, apiUpdate, apiDelete } from '../../../../modules/api'

import Diagnoses from '../../../Overlays/pages/DataForms/common/Diagnoses'
import GoalsAndObjectives from '../../../Overlays/pages/DataForms/common/GoalsAndObjectives'
import Problems from '../../../Overlays/pages/DataForms/common/Problems'
import SNAP from '../../../Overlays/pages/DataForms/common/SNAP'

import { DataFormTemplateBase, mapDispatchToProps } from '../../../Overlays/pages/DataFormTemplates/base'

import withRouterV6 from '../../../../hocs/withRouterV6'
import { TIMEZONE } from '../../../../utils/constants'

class TreatmentPlanTemplateOverlay extends DataFormTemplateBase {
  save = async () => {
    const { $new } = this.state
    const { match } = this.props

    let model = this.form.current.getFormValue()

    if ($new)
      await apiCreate({
        name: 'internal_treatment_plan_templates',
        url: `/internal_templates`,
        params: {
          ...model,
          category: 'treatment_plan',
        },
      })
    else
      await apiUpdate({
        name: 'internal_treatment_plan_templates',
        url: `/internal_templates/${match?.params?.id}`,
        params: model,
      })

    this.setState({ $editable: false })
    this.close()
  }

  delete = async () => {
    const { match } = this.props
    await apiDelete({
      name: 'internal_treatment_plan_templates',
      url: `/internal_templates/${this.state.id || match?.params?.id}`,
    })

    this.close()
  }

  render() {
    const { $new, $editable, isInvalid, closing } = this.state
    const { record, dataID, defaultDebounce, initialData, loading, location, online, timezone, title } = this.props

    const hasInitialData = initialData && dataID

    if (!$new && !hasInitialData && size(record) === 0) {
      return <OverlayLoader position="right" showBackdrop={this.state.$editable} />
    }

    return (
      <Overlay
        position="right"
        onClose={this.close}
        showBackdrop={$editable}
        maxWidth={82}
        isDirty={$editable}
        closeWrapper={(element, onClose) => (
          <Dialog
            glyph="check"
            title="Close without saving?"
            message="All changes will be lost. This action cannot be undone."
            yesColor="red"
            yesLabel="Yes, Close Without Saving"
            skip={!$editable || !onClose}
            onYes={onClose}
          >
            {element}
          </Dialog>
        )}
      >
        <Overlay.Header icon="treatment_plans" title="Treatment Plan Template" help={<HelpTagIframe id="treatment_plans" />} />

        <ScrollView>
          <PageGrid scroll breakpoint={3}>
            <Nav top="0" breakpoint={3} headingSize={300} title="Table of Contents" background={transparentize(0.4, 'white')}>
              {this.renderSidebar()}
            </Nav>

            <Overlay.Content preventScroll={closing} style={{ padding: 1 }}>
              <Form
                useFullModel
                getForm={this.form}
                initialModel={record}
                timezone={timezone}
                defaultDebounce={defaultDebounce}
                isEditable={$editable}
                onValidationUpdate={(valid) => this.setState({ isValid: valid, isInvalid: !valid })}
                decorate={(model: any) => ({
                  data: { ...model.data, category: 'treatment_plan', subcategory: 'treatment_plan' },
                })}
              >
                <Section headingType="h2" title="Name">
                  <FormSection maxWidth="100%">
                    <Input
                      label="Template Name"
                      model="data.name"
                      validations={{
                        presence: {
                          message: 'Please enter a template name',
                        },
                      }}
                    />

                    <Flex stretchChildrenX gap="1rem">
                      <Select model="status" label="Status" fullWidth defaultValue="active">
                        <Option label="Active" value="active" />
                        <Option label="Archived" value="archived" />
                      </Select>

                      <Select model="level" label="Level" fullWidth defaultValue="behave_template">
                        <Option label="Behave Template" value="behave_template" />
                        <Option label="Public Template" value="public_template" />
                      </Select>
                    </Flex>
                  </FormSection>
                </Section>

                <Divider />

                <Section
                  scrollview={{
                    id: 'appointments',
                    name: 'Appointments',
                  }}
                  headingType="h2"
                  title="Appointments"
                >
                  <FormSection layout="vertical" maxWidth="100%">
                    <Flex gap={8} stretchChildrenX>
                      <Input label="Frequency of Appointments" model="data.data.appointments.frequency" />
                      <Input label="Estimated Length of Treatment" model="data.data.appointments.estimated_length" />
                    </Flex>
                  </FormSection>
                </Section>

                <Divider />

                <Section
                  scrollview={{
                    id: 'problems',
                    name: 'Problems',
                  }}
                  headingType="h2"
                  title="Problems"
                >
                  <Problems model="data.data" isEditable={$editable} />
                </Section>

                <Divider />

                <Section
                  scrollview={{
                    id: 'goals_and_objectives',
                    name: 'Goals & Objectives',
                  }}
                  headingType="h2"
                  title="Goals & Objectives"
                >
                  <GoalsAndObjectives model="data.data" isEditable={$editable} />
                </Section>

                <Divider />

                <Section
                  title="Strengths, Needs, Abilities, Preferences"
                  aside={<Switch horizontal inverse model="data.data.snap.not_applicable" />}
                  scrollview={{
                    id: 'snap',
                    name: 'S.N.A.P.',
                  }}
                >
                  <ContextHide when="data.data.snap.not_applicable" is={true}>
                    <SNAP model="data.data" />
                  </ContextHide>
                </Section>

                <Divider />

                <Section
                  scrollview={{
                    id: 'diagnoses',
                    name: 'Diagnoses',
                  }}
                  headingType="h2"
                  title="Diagnoses"
                >
                  <Diagnoses model="data.data" isEditable={$editable} />
                </Section>

                <Divider />

                <Section
                  title="Summary"
                  aside={<Switch horizontal inverse model="data.data.summary.not_applicable" />}
                  scrollview={{
                    id: 'summary',
                    name: 'Summary',
                  }}
                >
                  <ContextHide when="data.data.summary.not_applicable" is={true}>
                    <FormSection layout="vertical" maxWidth="100%">
                      <Textarea label="Interpretive Summary" model="data.data.summary.interpretive_summary" />
                      <Textarea label="Additional Relevant Information" model="data.data.summary.additional_relevant_info" />
                      <Textarea useQuickText label="Recommendations" model="data.data.summary.recommendations" />
                    </FormSection>
                  </ContextHide>
                </Section>
              </Form>
            </Overlay.Content>
          </PageGrid>
        </ScrollView>

        <Overlay.Footer>
          {$editable && online && (
            <>
              <Button
                label="Save Template"
                glyph="check"
                type="primary"
                color="green"
                onClick={this.save}
                isLoading={loading}
                isDisabled={loading || isInvalid}
                flex="100 1 auto"
              />
              {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
            </>
          )}

          {!$editable && online && (
            <>
              <Button glyph="edit" label={`Edit ${title}`} type="default" isDisabled={loading} onClick={this.edit} flex="100 1 auto" />
              <DeleteDialog
                title={`Delete ${title}?`}
                message={`Are you sure you want to delete this ${title}? This action cannot be undone.`}
                onYes={this.delete}
              >
                <Button glyph="delete" label={`Delete ${title}…`} type="default" color="red" isDisabled={loading} fullWidth />
              </DeleteDialog>
            </>
          )}

          {!online && <NoInternet />}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

TreatmentPlanTemplateOverlay.defaultProps = {
  defaultDebounce: 0,
  title: 'Treatment Plan',
  category: 'treatment_plan',
}

export const mapStateToProps = (state, props) => ({
  user: state.me.user,
  loading: get(state, `data.internal_treatment_plan_templates.loading`),
  data: get(state, `data.internal_treatment_plan_templates.data`),
  record: get(state, `data.internal_treatment_plan_templates.data.${props.match?.params?.id}`, {}),
  online: state.common.global.online,
  idle: state.common.global.idle,
  timezone: state.me.tenant.timezone || TIMEZONE,
})

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanTemplateOverlay)

export const TreatmentPlanInternalTemplateOverlayV6 = withRouterV6(
  connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanTemplateOverlay),
)
