import React from 'react'

import { usDateTime } from '../../../utils/functions'
import { withFormContext } from '../../../components/Forms/context'

import Button from '../../../components/Button'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import { ComboBox } from '../../../components/Forms/ComboBoxes/ComboBox'
import SummonOverlay from '../../../components/SummonOverlay'
import Input from '../../../components/Forms/Input'
import AmountInput from '../../../components/Forms/AmountInput'
import DateTimeInput from '../../../components/Forms/DateTimeInput'

import AdmissionSourceSelect from '../../../components/Forms/AdmissionSourceSelect'
import AdmissionTypeSelect from '../../../components/Forms/AdmissionTypeSelect'

import ClaimBillTypeSelector from '../../../components/Forms/ClaimBillTypeSelector'
import ConditionCodesSelector from '../../../components/Forms/elements/ConditionCodesSelector'

import ClaimFrequencySelect from './ClaimFrequencySelect'
import ClaimFilingCodeSelect from './ClaimFilingCodeSelect'
import ClaimPlaceOfServiceSelect from './ClaimPlaceOfServiceSelect'
import ClaimPatientStatusSelect from './ClaimPatientStatusSelect'

import { PreviewFeeScheduleOverlay } from './PreviewFeeScheduleOverlay'
import { SectionCard } from '../../../components/SectionCard'

const ClaimDetails = ({
	data,
	client,
	feeSchedule,
	onUpdate,
	insuranceAuthorization,
	onInsuranceAuthorizationUpdate,
	treatmentEpisode,
	onTreatmentEpisodeUpdate,
	admittingDiagnosis,
	onAdmittingDiagnosisUpdate,
	principalDiagnosis,
	onPrincipalDiagnosisUpdate
}: any) => {
	if (!data) return null
	const { category } = data

	return (
		<SectionCard
			title="Claim Details"
			aside={
				feeSchedule && (
					<SummonOverlay overlay={<PreviewFeeScheduleOverlay feeSchedule={feeSchedule} />}>
						<Button label="Fee Schedule Preview" glyph="view" type="link" size={100} display="inline-flex" />
					</SummonOverlay>
				)
			}
		>
			<div className="grid gap-4 grid-cols-1 mq600:grid-cols-2 mq900:grid-cols-3 mq1200:grid-cols-4 mq1400:grid-cols-4">
				<ComboBox
					label="Fee Schedule"
					model="insurance_new_fee_schedule"
					type="insurance.new_fee_schedules.all"
					maxWidth="100%"
					validations={{
						presence: {
							message: 'Please select a Fee Schedule'
						}
					}}
					onUpdate={(value: any) => {
						onUpdate?.(value)
					}}
					onClear={() => {
						onUpdate?.(null)
					}}
				/>

				<ClaimFilingCodeSelect model="claim_filing_code" maxWidth="100%" />
				<ClaimFrequencySelect model="claim_frequency_code" maxWidth="100%" />
				<ClaimPlaceOfServiceSelect model="place_of_service_code" maxWidth="100%" />

				{category === 'institutional' && <ClaimBillTypeSelector model="bill_type" maxWidth="100%" />}

				<ComboBox
					label="Authorization"
					icon="authorizations"
					type="client.insurance_authorizations"
					value={insuranceAuthorization}
					maxWidth="100%"
					dependentValue={client?.id}
					isDisabled={!client?.id}
					onUpdate={(value: any) => {
						onInsuranceAuthorizationUpdate?.(value)
					}}
				/>

				{category === 'institutional' && (
					<>
						<ComboBox
							label="Admitting Diagnosis"
							type="client.diagnoses"
							maxWidth="100%"
							value={admittingDiagnosis}
							dependentValue={client?.id}
							isDisabled={!client?.id}
							onUpdate={(value: any) => {
								onAdmittingDiagnosisUpdate?.(value)
							}}
						/>

						<ComboBox
							label="Treatment Episode"
							type="client.admissions"
							maxWidth="100%"
							value={treatmentEpisode}
							dependentValue={client?.id}
							isDisabled={!client?.id}
							onUpdate={(value: any) => {
								onTreatmentEpisodeUpdate?.(value)
							}}
						/>

						<DateTimeInput
							maxWidth="100%"
							label="Admit Date"
							model="admitted_at"
							description={`Treatment Episode Admit Date: ${usDateTime(treatmentEpisode?.admitted_at)}`}
							defaultValue={treatmentEpisode?.admitted_at}
						/>

						<DateTimeInput
							maxWidth="100%"
							label="Discharge Date"
							model="discharged_at"
							description={`Treatment Episode Discharge Date: ${usDateTime(treatmentEpisode?.discharged_at)}`}
							defaultValue={treatmentEpisode?.discharged_at}
						/>

						<AdmissionTypeSelect model="admission_type_code" />
						<AdmissionSourceSelect model="admission_source_code" />
					</>
				)}

				{category === 'professional' && (
					<>
						<ComboBox
							label="Treatment Episode"
							type="client.admissions"
							maxWidth="100%"
							value={treatmentEpisode}
							dependentValue={client?.id}
							isDisabled={!client?.id}
							onUpdate={(value: any) => {
								onTreatmentEpisodeUpdate?.(value)
							}}
						/>
					</>
				)}

				<ConditionCodesSelector isCompact model="condition_codes" maxWidth="100%" />

				{category === 'institutional' && (
					<>
						<ComboBox
							label="Principal Diagnosis"
							type="client.diagnoses"
							maxWidth="100%"
							value={principalDiagnosis}
							dependentValue={client?.id}
							isDisabled={!client?.id}
							validations={{
								presence: {
									message: 'Please select a principal diagnosis'
								}
							}}
							onUpdate={(value: any) => {
								onPrincipalDiagnosisUpdate?.(value)
							}}
						/>

						<ClaimPatientStatusSelect model="patient_status_code" />
					</>
				)}

				<Input label="Insurance Claim Number" model="original_claim_no" />
				<Input isEditable={false} label="Claim #" model="identifier" />

				<AmountInput isEditable={false} label="Total Billed Amount" model="total_amount" />
			</div>
		</SectionCard>
	)
}

const styles = {
	cardTitle: {
		fontSize: '1rem'
	},

	cardContent: {
		height: '100%'
	}
}

export default withFormContext(ClaimDetails)
