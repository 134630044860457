import React from 'react'

import { useCreate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'
import Card from '../../components/Card'
import Icon from '../../components/Icon'
import Link from '../../components/Link'
import DataList from '../../components/DataList'
import ConfirmDialog from '@behavehealth/components/Dialogs/ConfirmDialog'

import Notifications from '../../modules/notifications'

import { APP_URLS } from '../MyAccount/utils'

import { PartnershipInviteFromStatus } from './PartnershipInviteFromStatus'
import { PartnershipInviteToStatus } from './PartnershipInviteToStatus'

import { useCommunityPermissions } from '../Community/useCommunityPermissions'

const RootPartnershipInviteReceivedOverlay = (props: any) => {
  const { close, form, onValidationUpdate, isOverlayLoading, initialModel, data } = useOverlay({
    name: 'partnership-invite',
    endpoint: `/partnership_invites`,
    invalidate: 'partnership-invites',
    options: props,
  })

  const { mutateAsync: asyncAccept, isLoading: isAccepting }: any = useCreate({
    name: ['accept-partnership-invite', data?.id],
    url: `/partnership_invites/${data?.id}/accept`,
    invalidate: ['partnership-invite', data?.id],
    invalidateKeys: ['partnership-invites'],
  })

  const { mutateAsync: asyncDecline, isLoading: isDeclining }: any = useCreate({
    name: ['decline-partnership-invite', data?.id],
    url: `/partnership_invites/${data?.id}/decline`,
    invalidate: ['partnership-invite', data?.id],
    invalidateKeys: ['partnership-invites'],
  })

  const { timezone } = useSettings()
  const { canAcceptDeclineCancelCommunityInvites } = useCommunityPermissions()

  const handleAccept = async () => {
    try {
      await asyncAccept({})

      Notifications.send('Successfully Accepted Invite', 'positive')

      close()
    } catch (error) {
      console.error(error)
      Notifications.send(error?.data?.errors?.[0], 'negative')
    }
  }

  const handleDecline = async () => {
    try {
      await asyncDecline({})

      Notifications.send('Successfully Declined Invite', 'positive')

      close()
    } catch (error) {
      console.error(error)
      Notifications.send(error?.data?.errors?.[0], 'negative')
    }
  }

  if (isOverlayLoading) return <OverlayLoader position="right" />

  const sentFrom = data.sent_from
  const sentTo = data.sent_to

  const selectedCommunityProfile = data.organization?.selected_profile_2
  const communityURL = selectedCommunityProfile?.slug && `${APP_URLS.community}/profiles/${selectedCommunityProfile?.slug}`

  return (
    <Overlay onClose={close}>
      <Overlay.Header
        title="Partnership Invite Received"
        icon="community_invites"
        subtitle={
          <Flex gap="0.5rem">
            {data?.status_from === 'cancelled' ? (
              <PartnershipInviteFromStatus status="cancelled" />
            ) : (
              <PartnershipInviteToStatus status={data?.status_to} />
            )}
          </Flex>
        }
      />

      <Overlay.Content>
        <Form
          isCompact
          isEditable={false}
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <Grid gap="1rem">
              <div className="grid grid-cols-2 gap-5">
                {/* SENT FROM */}
                <Link href={communityURL} target="_blank" className="flex flex-nowrap items-center">
                  <Card className="pr-3 py-2 flex items-center">
                    <div className="w-[3.25rem] flex items-center justify-center shrink-0">
                      <Icon icon="enterprise" size={20} />
                    </div>

                    <div>
                      <div className="uppercase font-[600] text-[0.82rem] text-text-muted opacity-80 tracking-[1px]">Sent From</div>
                      <h3 className="font-[700] text-[1.15rem]">{sentFrom.name}</h3>
                      <div>The invite was sent by this EHR</div>
                    </div>
                  </Card>
                </Link>

                {/* SENT TO */}
                <Card className="pr-3 py-2 flex items-center">
                  <div className="w-[3.25rem] flex items-center justify-center shrink-0">
                    <Icon icon="enterprise" size={20} />
                  </div>

                  <div>
                    <div className="uppercase font-[600] text-[0.82rem] text-text-muted opacity-80 tracking-[1px]">Sent To (You)</div>
                    <h3 className="font-[700] text-[1.15rem]">{sentTo.name}</h3>
                    <div>The invite was sent to your EHR</div>
                  </div>
                </Card>
              </div>

              <Card className="pr-3 py-2 flex items-center">
                <div className="w-[3.25rem] flex items-center justify-center shrink-0">
                  <Icon icon="communications" size={20} />
                </div>

                <div>
                  <div className="uppercase font-[600] text-[0.82rem] text-text-muted opacity-80 tracking-[1px]">Notifications</div>
                  <h3 className="font-[700] text-[1.15rem]">{}</h3>

                  <div className="mt-1">You received Email Notifications to the following addresses (set up in your EHR):</div>

                  <DataList isCompact labelWidth={60} className="mt-1">
                    <DataList.Item label="Emails: " value={data.sent_to_emails.join(', ') || '–'} />
                  </DataList>
                </div>
              </Card>

              <Grid className="mt-2" gap="1rem">
                <Input label="Subject" model="subject" />
                <Textarea label="Message" model="message" />
              </Grid>
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      {canAcceptDeclineCancelCommunityInvites && (
        <Overlay.Footer>
          {data?.status_from === 'sent' && (
            <>
              <ConfirmDialog
                glyph="tick_circle"
                title="Accept Invite?"
                message="Are you sure you want to accept this Invite?"
                yesColor="green"
                onYes={handleAccept}
              >
                <Button label="Accept Invite" glyph="tick_circle" type="primary" color="green" isLoading={isAccepting} />
              </ConfirmDialog>

              {data?.status_to !== 'declined' && (
                <ConfirmDialog
                  glyph="cross"
                  title="Decline Invite?"
                  message="Are you sure you want to decline this Invite? You will be able to still accept it later."
                  yesColor="green"
                  onYes={handleDecline}
                >
                  <Button label="Decline Invite" glyph="cross" type="default" color="text" glyphColor="red" isLoading={isDeclining} />
                </ConfirmDialog>
              )}
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const PartnershipInviteReceivedOverlay = withOverlayError(RootPartnershipInviteReceivedOverlay)
