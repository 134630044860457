import React from 'react'
import clsx from 'clsx'
import { lighten, transparentize } from 'polished'
import { PanelResizeHandle } from 'react-resizable-panels'

import { COLORS } from '../../../theme'
import Glyph from '../../../components/Glyph'

const hitAreaMargins = { fine: 10 } as const

export const PanelResize = ({ direction, className }: any) => {
  const rootClasses = clsx('relative z-[7]', className)

  const handleClasses = clsx(
    'resize-handle border-solid border-divider rounded-[4px] inline-flex items-center justify-center w-4 h-8 absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] z-[3]',
    direction === 'horizontal' && 'rotate-90',
  )

  return (
    <div className={rootClasses}>
      <PanelResizeHandle hitAreaMargins={hitAreaMargins} css={STYLES.bar} className={`direction-${direction}`}>
        <div className={handleClasses}>
          <Glyph glyph="drag_and_drop" size={14} className="!fill-white opacity-100" />
        </div>
      </PanelResizeHandle>
    </div>
  )
}

const STYLES = {
  bar: {
    '.resize-handle': {
      background: lighten(0.3, COLORS.text),
    },

    '&.direction-horizontal': {
      height: 2,
      width: '100%',
      zIndex: 1,
      position: 'relative',
      background: COLORS.divider,

      '&::before': {
        content: '""',
        position: 'absolute',
        top: -3,
        bottom: -3,
        opacity: 0,
        width: '100%',
        background: transparentize(0.85, COLORS.vividBlue),
        transition: 'opacity 60ms cubic-bezier(0.39, 0.575, 0.565, 1)',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        top: -1,
        bottom: 0,
        opacity: 0,
        width: '100%',
        background: transparentize(0.25, COLORS.blue),
        transition: 'opacity 60ms cubic-bezier(0.39, 0.575, 0.565, 1)',
      },
    },

    '&.direction-vertical': {
      width: 1,
      height: '100%',
      zIndex: 1,
      position: 'relative',
      background: COLORS.divider,

      '&::before': {
        content: '""',
        position: 'absolute',
        left: -4,
        right: -4,
        opacity: 0,
        height: '100%',
        background: transparentize(0.85, COLORS.vividBlue),
        transition: 'opacity 60ms cubic-bezier(0.39, 0.575, 0.565, 1)',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        left: -1,
        right: -1,
        opacity: 0,
        height: '100%',
        background: transparentize(0.25, COLORS.blue),
        transition: 'opacity 60ms cubic-bezier(0.39, 0.575, 0.565, 1)',
      },
    },

    '&[data-resize-handle-state="hover"], &[data-resize-handle-state="drag"]': {
      '&::before': { opacity: 1 },
      '&::after': { opacity: 1 },

      '.resize-handle': {
        background: COLORS.blue,
        // background: lighten(0.35, COLORS.vividBlue),
        // borderColor: lighten(0.1, COLORS.blue),

        // svg: {
        //   fill: `${COLORS.blue} !important`,
        // },
      },
    },
  },
}
