import React from 'react'

import NavGroup from '../NavGroup'
import NavItem from '../NavItem'

type Props = {
  propertyID: string
  id?: string
}

const PropertyNavItems = ({ propertyID, id }: Props) => {
  const basePath = `/locations/${propertyID || id}`

  return (
    <>
      <NavGroup label="General">
        <NavItem feature="general_info" label="General Info" to={`${basePath}/general-info`} />
        <NavItem
          showForCommunity
          showMarketing
          feature="files"
          label="Files"
          to={`${basePath}/files`}
          permission="properties.files.view"
          featureFlagV2="files"
        />
        <NavItem
          feature="activity_timeline"
          label="Activity Timeline"
          to={`${basePath}/activity-timeline`}
          permission="activity_timeline.view"
        />
        <NavItem
          showForCommunity
          feature="form_submissions"
          label="Form Inbox"
          to={`${basePath}/form-submissions`}
          featureFlagV2="form_submissions"
          permission="form_submissions.view"
        />
      </NavGroup>

      <NavGroup label="Organization">
        <NavItem showForCommunity showMarketing icon="floor_plan" label="Floor Plan" to={`${basePath}/floor-plan`} />
        <NavItem
          showForCommunity
          showMarketing
          feature="properties"
          label="Sublocations"
          to={`${basePath}/locations`}
          permission="properties.view"
          featureFlagV2="properties"
        />
      </NavGroup>

      <NavGroup label="Planning">
        <NavItem
          showForCommunity
          showMarketing
          feature="todos"
          label="To-Do"
          to={`${basePath}/todo`}
          permission="todos.view"
          featureFlagV2="todos"
        />
        <NavItem
          showForCommunity
          showMarketing
          label="Calendar"
          to={`${basePath}/calendar`}
          feature="calendar"
          permission="events.view"
          featureFlagV2="calendar"
        />

        <NavItem
          showMarketing
          feature="location_checkin"
          label="Location Tracking"
          to={`${basePath}/location-checkin`}
          permission="location_checkin.view"
          featureFlagV2="location_gps_check_in"
        />
      </NavGroup>

      <NavGroup label="Notes">
        <NavItem
          showMarketing
          feature="shift_notes"
          label="Shift Notes"
          to={`${basePath}/shift-notes`}
          permission="shift_notes.view"
          featureFlagV2="shift_notes"
        />
        <NavItem
          showMarketing
          feature="custom_notes"
          label="Custom Notes"
          to={`${basePath}/custom-notes`}
          permission="property_custom_notes.view"
          featureFlagV2="property_custom_notes"
        />
      </NavGroup>
    </>
  )
}

export default PropertyNavItems
