import React from 'react'
import { connect } from 'react-redux'

import { apiCreate, apiUpdate, apiDelete } from '../../../modules/api'

import Button from '../../Button'
import Card from '../../Card'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Overlay from '../../Overlay'
import Section from '../../Section'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../../Overlays/pages/OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

import StaffPermissionsList from '../../Permissions/StaffPermissionsList'
import withRouterV6 from '../../../hocs/withRouterV6'

class PermissionsTemplateOverlay extends OverlayBase {
  save = async () => {
    const { $new }: any = this.state
    const { match }: any = this.props

    const model = this.form.current.getFormValue()

    if ($new)
      await apiCreate({
        name: 'permission_templates',
        url: `/permission_templates`,
        params: model,
      })
    else
      await apiUpdate({
        name: 'permission_templates',
        url: `/permission_templates/${match?.params?.id}`,
        params: model,
      })

    await this.onSaveSuccessful()

    this.setState({ $editable: false })
  }

  delete = async () => {
    const { match, endpoint, skipReduxBucket }: any = this.props

    await apiDelete({
      name: skipReduxBucket ? null : endpoint,
      url: `/permission_templates/${this.state.id || match?.params?.id}`,
    })

    await this.onDeleteSuccessful()

    this.close()
  }

  render = () => {
    const { $editable, $new, isInvalid, params }: any = this.state
    const { record, timezone, loading }: any = this.props

    const data = { ...record, ...params }

    return (
      <Overlay showBackdrop={this.state.$editable} closeOnBackdrop position="right" maxWidth={55} onClose={this.close}>
        <Overlay.Header icon="permissions" title="Permissions Template" />

        <Overlay.Content>
          <Form
            getForm={this.form}
            initialModel={data}
            isEditable={$editable}
            timezone={timezone}
            onValidationUpdate={this.onValidationUpdate}
          >
            <Section>
              <FormSection maxWidth="100%">
                <Input
                  autoFocus
                  label="Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a permission template name',
                    },
                  }}
                />

                <Card>
                  <StaffPermissionsList model="data" />
                </Card>
              </FormSection>
            </Section>
          </Form>
        </Overlay.Content>

        <Overlay.Footer>
          {$editable && (
            <>
              <Button
                label="Save Template"
                glyph="check"
                type="primary"
                color="green"
                isLoading={loading}
                onClick={this.save}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission={true}
              />
              {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={loading} onClick={this.cancel} />}
            </>
          )}

          {!$editable && (
            <>
              <Button
                glyph="edit"
                testKey="edit_permissions_template_button"
                label="Edit List"
                type="default"
                isDisabled={loading}
                onClick={this.edit}
                flex="100 1 auto"
                permission="settings.permissions.edit"
              />

              <DeleteDialog
                title="Delete Permissions Template?"
                message="Are you sure you want to delete this template? This action cannot be undone."
                onYes={this.delete}
              >
                <Button
                  glyph="delete"
                  testKey="delete_permissions_template_button"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={loading}
                  fullWidth
                  permission="settings.permissions.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'permission_templates')

export default connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(PermissionsTemplateOverlay))

export const PermissionsTemplateOverlayV6 = withRouterV6(
  connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(PermissionsTemplateOverlay)),
)
