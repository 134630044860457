import React from 'react'

import AddressSelectorArray from '../../Forms/AddressSelectorArray'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const LocationsFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="gps_check_in_out" />
        <div>
          <Type as="h2" variant="H3">
            Locations
          </Type>
          <Type as="p" variant="TEXT" css={{ margin: '0.2em 0 0' }}>
            Add all your locations to identify the markets you serve. This will allow us to make helpful suggestions for nearby resources.
          </Type>
        </div>
      </Flex>

      <AddressSelectorArray model="questionnaire.addresses" />
    </FormSection>
  )
}

export const Locations = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <LocationsFields />
    </Form>
  )
}
