import React from 'react'
import * as Popover from '@radix-ui/react-popover'

import { COLORS, SHADOW } from '../../../theme'
import { usePortal } from '../../../hooks/usePortal'

import Glyph from '../../../components/Glyph'
import Grid from '../../../components/Grid'
import State from '../../../components/State'

import { INPUT_STYLES } from '../styles'

export const FilterDropdown: React.FC<any> = (props) => {
  const {
    activeValues,
    children,
    isEmpty,
    isLoading,
    isSearchEmpty,
    label = 'Select…',
    onClose,
    onOpenChange,
    open,
    testKey,
    onClick,
  } = props

  const portalContainer = usePortal('radix')

  return (
    <div onClick={onClick}>
      <Popover.Root open={open} onOpenChange={onOpenChange}>
        <Popover.Trigger asChild>
          <div data-test={testKey} css={STYLES.trigger}>
            {label}
            <Glyph glyph="triangle_down" size={10} css={STYLES.triangle} />
          </div>
        </Popover.Trigger>

        <Popover.Portal container={portalContainer}>
          <Popover.Content asChild side="bottom" align="start" onInteractOutside={onClose}>
            <div data-test="filter_dropdown_menu" css={STYLES.menu}>
              {isLoading || isEmpty ? (
                <State isLoading={isLoading} isEmpty={isEmpty} emptyDescription="No data available to display" glyph="filter" />
              ) : (
                <>
                  {!isLoading && !isEmpty && children}

                  {isSearchEmpty && <State isEmpty emptyDescription="No search results founds" glyph="search" />}
                </>
              )}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>

      {activeValues && (
        <Grid gap="0.4rem" alignItems="flex-start" css={STYLES.activeValuesGrid}>
          {activeValues}
        </Grid>
      )}
    </div>
  )
}

export const FilterDropdownTrigger = ({ testKey, label, hasActiveFilters, onClick }: any) => {
  return (
    <div data-test={testKey} css={STYLES.trigger} className={!hasActiveFilters ? 'is-empty' : ''} onClick={onClick}>
      {label}
      <Glyph glyph="triangle_down" size={10} css={STYLES.triangle} />
    </div>
  )
}

const STYLES = {
  trigger: {
    ...INPUT_STYLES,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  menu: {
    border: `1px solid ${COLORS.divider}`,
    boxShadow: SHADOW(5),
    background: COLORS.white,
    width: '100%',
    minWidth: 200,
    maxWidth: 280,
    borderRadius: 5,
    outline: 'none',
    overflow: 'auto',
    maxHeight: '75vh',
  },

  value: {
    display: 'flex',
    alignItems: 'center',
  },

  valueGraphic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '0.2rem',
  },

  triangle: {
    marginLeft: 'auto',
    alignSelf: 'center',
  },

  activeValuesGrid: {
    padding: '0.35rem 0',
  },
}
