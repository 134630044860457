import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  validated: {
    label: 'Validated',
    color: 'gray',
  },
  pending: {
    label: 'Pending',
    color: 'blue',
  },
  processed: {
    label: 'Processed',
    color: 'blue',
  },
  submitted_to_clearing_house: {
    label: 'Submitted To Clearinghouse',
    color: 'orange',
  },
  documentation_required: {
    label: 'Documentation Required',
    color: 'orange',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'orange',
  },
  to_review: {
    label: 'To Review',
    color: 'orange',
  },
  fully_paid: {
    label: 'Paid',
    color: 'green',
  },
  finalized: {
    label: 'Finalized',
    color: 'green',
  },
  denied: {
    label: 'Denied',
    color: 'red',
  },
  error_validation: {
    label: 'Validation Error',
    color: 'red',
  },
  error_submission: {
    label: 'Submission Error',
    color: 'red',
  },
  error_status: {
    label: 'Status Error',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
