import React from 'react'
import size from 'lodash/size'

import {
  Button,
  Accordion,
  Form,
  FormSection,
  DateInput,
  Divider,
  PageLayout,
  PageSection,
  State,
  SummonOverlay,
  DeleteDialog,
  Flex,
  Card,
  Grid,
  Textarea,
  Markup,
} from '@behavehealth/components'

import InternalNotesOverlay from '@behavehealth/components/Billing/InternalNotesOverlay'
import SubscriptionPlanPaymentOverlay from '@behavehealth/components/Billing/SubscriptionPlanPaymentOverlay'
import MarketingCard from '@behavehealth/components/Marketing/MarketingCard'

import {
  FeaturesComparisonOverlay,
  FeaturesOverlay,
  ProductCard,
  ProductsGrid,
  SubscriptionPaymentOverlay,
  PurchasePaymentOverlay,
} from '@behavehealth/components/Billing'

import { apiUpdate } from '@behavehealth/modules/api'

import useStore from '@behavehealth/modules/store'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'

const PlansAddons = () => {
  const { tenant, user } = useSettings()

  const activeSubscriptions = useStore((state: any) => state.subscriptions)
  const hasActivePlatformPlan =
    activeSubscriptions.filter((sub: any) => sub?.status === 'active' && sub?.product?.category === 'platform_plan').length > 0

  const isBehave = user?.type === 'bh_employee'

  const [requiredProducts, setRequiredProducts] = React.useState([])
  const [upfrontRequiredProducts, setUpfrontRequiredProducts] = React.useState([])

  const { data: platformPlan, isLoading: platformPlanLoading }: any = useGet({
    name: ['products', { category: 'platform_plan', status: 'active,in_use' }],
    url: `/products`,
    params: {
      category: 'platform_plan',
      status: 'active,in_use',
    },
  })

  const { data: plans, isLoading: plansLoading }: any = useGet({
    name: ['products', { category: 'plans', status: 'active' }],
    url: `/products`,
    params: {
      category: 'plan',
      status: 'active,in_use',
    },
  })

  const { data: addons, isLoading: addonsLoading }: any = useGet({
    name: ['products', { category: 'addon', status: 'active' }],
    url: `/products`,
    params: {
      category: 'addon',
      status: 'active,in_use',
    },
  })

  const { data: services, isLoading: servicesLoading }: any = useGet({
    name: ['products', { category: 'service', status: 'active' }],
    url: `/products`,
    params: {
      category: 'service',
      status: 'active,in_use',
    },
  })

  const isPlatformPlanEmpty = size(platformPlan) <= 0
  const isPlansEmpty = size(plans) <= 0
  const isAddonsEmpty = size(addons) <= 0
  const isServicesEmpty = size(services) <= 0

  const PLATFORM_PLANS = () => {
    if (platformPlanLoading) return <State isLoading={true} />
    if (isPlatformPlanEmpty) return <State isEmpty={true} />
    if (!platformPlan) return null

    return platformPlan.map((product: any) => {
      // find out if there is an active or trialing plan
      let state = 'none'
      for (let i = 0; i < activeSubscriptions?.length; i++) {
        if (activeSubscriptions[i]?.product?.id === product?.id) {
          if (activeSubscriptions[i].status === 'trialing') state = 'trialing'
          else if (activeSubscriptions[i].status === 'active') state = 'active'

          break
        }
      }

      return (
        <ProductCard
          isRequired
          isActive={state === 'active'}
          isTrialing={state === 'trialing'}
          product={product}
          showFeatures={false}
          actions={
            <>
              {state === 'none' && hasActivePlatformPlan && (
                <SummonOverlay overlay={<SubscriptionPaymentOverlay product={product} />}>
                  <Button rounded label={`Change Plan →`} color="green" />
                </SummonOverlay>
              )}

              <SummonOverlay
                overlay={<FeaturesComparisonOverlay plans={platformPlan} notionURL={product.notion_url} name={product.public_name} />}
              >
                {/* <Button type="default" color="green" glyph="info" label="Learn More" /> */}
              </SummonOverlay>
            </>
          }
        />
      )
    })
  }

  const PLANS = () => {
    if (plansLoading) return <State isLoading={true} />
    if (!plans) return null

    return plans.map((plan: any) => {
      // find out if there is an active or trialing plan
      let state = 'none'
      for (let i = 0; i < activeSubscriptions?.length; i++) {
        if (activeSubscriptions[i]?.product?.id === plan?.id) {
          if (activeSubscriptions[i]?.status === 'trialing') state = 'trialing'
          else if (activeSubscriptions[i]?.status === 'active') state = 'active'

          break
        }
      }

      return (
        <ProductCard
          isRequired={plan?.is_required}
          isRecommended={plan?.is_recommended}
          key={plan?.id}
          product={plan}
          showFeatures={false}
          isActive={state === 'active'}
          isTrialing={state === 'trialing'}
          actions={
            <>
              {state === 'active' && (
                <DeleteDialog
                  title="Cancel Plan?"
                  message="Are you sure you want to cancel this plan?"
                  yesLabel="Cancel Plan"
                  onYes={() => {}}
                >
                  <Button rounded label="Cancel" type="primary" color="red" />
                </DeleteDialog>
              )}

              <SummonOverlay overlay={<FeaturesOverlay product={plan} />}>
                <Button type="default" color="green" glyph="info" label="Learn More" />
              </SummonOverlay>
            </>
          }
        />
      )
    })
  }

  const ADDONS = () => {
    if (addonsLoading) return <State isLoading={true} />
    if (!addons) return null

    return addons.map((addon: any) => {
      // find out if there is an active or trialing plan
      let state = 'none'
      for (let i = 0; i < activeSubscriptions?.length; i++) {
        if (activeSubscriptions[i]?.product?.id === addon?.id) {
          if (activeSubscriptions[i]?.status === 'trialing') state = 'trialing'
          else if (activeSubscriptions[i]?.status === 'active') state = 'active'

          break
        }
      }

      return (
        <ProductCard
          isRequired={addon.is_required}
          isRecommended={addon.is_recommended}
          key={addon.id}
          product={addon}
          showFeatures={false}
          isActive={state === 'active'}
          isTrialing={state === 'trialing'}
          actions={
            <>
              {hasActivePlatformPlan && state === 'none' && (
                <SummonOverlay overlay={<SubscriptionPaymentOverlay product={addon} />}>
                  <Button type="primary" label="Subscribe" color="green" />
                </SummonOverlay>
              )}

              {state === 'trialing' && <Button rounded label="Activate" type="primary" color="blue" />}

              {state === 'active' && (
                <DeleteDialog
                  title="Cancel Add-On?"
                  message="Are you sure you want to cancel this add-on?"
                  yesLabel="Cancel Add-On"
                  onYes={() => {}}
                >
                  <Button rounded label="Cancel" type="primary" color="red" />
                </DeleteDialog>
              )}

              <SummonOverlay overlay={<FeaturesOverlay product={addon} />}>
                <Button type="default" color="green" glyph="info" label="Learn More" />
              </SummonOverlay>
            </>
          }
        />
      )
    })
  }

  const SERVICES = () => {
    if (servicesLoading) return <State isLoading={true} />
    if (!services) return null

    return services.map((service: any) => (
      <ProductCard
        isRequired={service.is_required}
        isRecommended={service.is_recommended}
        key={service.id}
        product={service}
        showFeatures={false}
        actions={
          <>
            <SummonOverlay overlay={<PurchasePaymentOverlay product={service} />}>
              <Button rounded label="Purchase" color="green" />
            </SummonOverlay>

            <SummonOverlay overlay={<FeaturesOverlay product={service} />}>
              <Button type="default" color="green" glyph="info" label="Learn More" />
            </SummonOverlay>
          </>
        }
      />
    ))
  }

  React.useEffect(() => {
    let required: any = []
    let upfront: any = []

    for (let i = 0; i < plans?.length; i++) {
      if (plans[i].pricing_type === 'one_time') upfront.push(plans[i])
      else required.push(plans[i])
    }

    for (let i = 0; i < addons?.length; i++) {
      if (addons[i].is_required && addons[i].pricing_type === 'one_time') upfront.push(addons[i])
      else if (addons[i].is_required) required.push(addons[i])
    }

    for (let i = 0; i < services?.length; i++) {
      if (services[i].is_required && services[i].pricing_type === 'one_time') upfront.push(services[i])
      else if (services[i].is_required) required.push(services[i])
    }

    setRequiredProducts(required)
    setUpfrontRequiredProducts(upfront)
  }, [plans, addons, services])

  return (
    <PageLayout>
      {isBehave && !hasActivePlatformPlan && (
        <Accordion
          activateEditMode
          initialModel={tenant}
          title="Proposal Details (Internal)"
          glyph="legal_history"
          onSubmit={async (data) => {
            await apiUpdate({ name: 'tenant', url: '/me/tenant', params: data, reducer: 'me' })
          }}
        >
          <Form>
            <FormSection maxWidth={600}>
              <DateInput label="Proposal Expires On" model="system_prefs.proposal.expires_at" />
              <Textarea useQuickText label="Proposal Internal Notes" model="system_prefs.proposal.notes" />
            </FormSection>
          </Form>
        </Accordion>
      )}

      {(isBehave || tenant?.metadata?.plans_notes) && (
        <PageSection>
          <PageSection.Header
            after={
              <Flex gap="0.5rem">
                <SummonOverlay
                  isPermissible={isBehave}
                  overlay={<InternalNotesOverlay title="Edit Notes" data={tenant} model="metadata.plans_notes" />}
                >
                  <Button label="Edit Notes" glyph="edit" type="default" size={200} permission={isBehave} />
                </SummonOverlay>
              </Flex>
            }
          >
            <PageSection.Title title="Notes" />
          </PageSection.Header>

          {tenant?.metadata?.plans_notes && (
            <PageSection.Content>
              <Card className="!p-4">
                <Markup value={tenant?.metadata?.plans_notes} />
              </Card>
            </PageSection.Content>
          )}
        </PageSection>
      )}

      {!hasActivePlatformPlan && (
        <Grid centerX>
          <SummonOverlay
            overlay={
              <SubscriptionPlanPaymentOverlay
                requiredProducts={requiredProducts}
                oneTimeRequiredProducts={upfrontRequiredProducts}
                onSuccess={() => {
                  // window.location.reload()
                }}
              />
            }
          >
            <MarketingCard
              showButton
              glyph="star"
              variant="white"
              layout="vertical"
              title="Start Your Subscription"
              description="Click the button to activate your account"
              buttonLabel="Subscribe"
              buttonGlyph="star"
            />
          </SummonOverlay>
        </Grid>
      )}

      {/* PLATFORM PLANS */}
      <PageSection>
        <PageSection.Header>
          <PageSection.Title title="Platform Plan" />
        </PageSection.Header>

        <PageSection.Content>
          {isPlatformPlanEmpty ? (
            <Card>
              <State
                isEmpty
                icon="certificate"
                title="Platform Plans"
                emptyDescription="There is no Platform Base to choose. Please contact us to set it up."
                emptyActions={<Button glyph="chat" label="Contact Us" onClick={() => window.Beacon('open')} />}
              />
            </Card>
          ) : (
            <ProductsGrid>{PLATFORM_PLANS()}</ProductsGrid>
          )}
        </PageSection.Content>
      </PageSection>

      <Divider css={styles.divider} />

      {/* PLANS */}
      <PageSection>
        <PageSection.Header>
          <PageSection.Title title="Plans" />
        </PageSection.Header>

        <PageSection.Content>
          {isPlansEmpty ? (
            <Card>
              <State
                isEmpty
                icon="certificate"
                title="Plans"
                emptyDescription="There are no Plans to choose from. Please contact us to set them up."
                emptyActions={<Button glyph="chat" label="Contact Us" onClick={() => window.Beacon('open')} />}
              />
            </Card>
          ) : (
            <ProductsGrid>{PLANS()}</ProductsGrid>
          )}
        </PageSection.Content>
      </PageSection>

      <Divider css={styles.divider} />

      {/* ADD-ON'S */}
      <PageSection>
        <PageSection.Header>
          <PageSection.Title title="Add-On's" />
        </PageSection.Header>

        <PageSection.Content>
          {isAddonsEmpty ? (
            <Card>
              <State
                isEmpty
                icon="certificate"
                title="Add-On's"
                emptyDescription="There are no Add-On's to choose from. Please contact us to set up your Add-On's."
                emptyActions={<Button glyph="chat" label="Contact Us" onClick={() => window.Beacon('open')} />}
              />
            </Card>
          ) : (
            <ProductsGrid>{ADDONS()}</ProductsGrid>
          )}
        </PageSection.Content>
      </PageSection>

      <Divider css={styles.divider} />

      {/* SERVICES */}
      <PageSection>
        <PageSection.Header>
          <PageSection.Title title="Services" />
        </PageSection.Header>

        <PageSection.Content>
          {isServicesEmpty ? (
            <Card>
              <State
                isEmpty
                icon="certificate"
                title="Services"
                emptyDescription="There are no Services to choose from. Please contact us to set your services up."
                emptyActions={<Button glyph="chat" label="Contact Us" onClick={() => window.Beacon('open')} />}
              />
            </Card>
          ) : (
            <ProductsGrid>{SERVICES()}</ProductsGrid>
          )}
        </PageSection.Content>
      </PageSection>
    </PageLayout>
  )
}

const styles = {
  divider: {
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
  },
}

export default PlansAddons
