import React from 'react'
import { connect } from 'react-redux'

import { COLORS, SHADOW } from '../theme'
import Flex from './Flex'
import Loader from './Loader'
import { Heading, P } from './Typography'

const IdleSaver = ({ saving, debug }) => {
  if (!debug && !saving) return null

  return (
    <div css={styles.main}>
      <div css={styles.pane}>
        <Flex css={styles.pane.heading} centerX centerY>
          <Heading is="h1" size={300}>
            Saving data
          </Heading>
          <Loader css={{ marginLeft: '10px' }} color={COLORS.mintGreen} size={16} speed={500} />
        </Flex>

        <P css={{ marginTop: '10px' }}>You will be redirected to the Login screen in a moment…</P>
      </div>
      <div css={styles.background}></div>
    </div>
  )
}

const styles = {
  main: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
  },

  pane: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxWidth: '300px',
    transform: 'translate(-50%, -50%)',
    background: COLORS.white,
    zIndex: 1,
    border: `1px solid ${COLORS.divider}`,
    borderRadius: '7px',
    boxShadow: SHADOW(10),
    padding: '20px',

    heading: {
      textAlign: 'center',
    },
  },

  background: {
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
  },
}

const mapStateToProps = (state) => {
  return {
    saving: state?.common?.global?.idleSaving,
  }
}

IdleSaver.defaultProps = {
  debug: false,
}

export default connect(mapStateToProps)(IdleSaver)
