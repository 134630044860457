import { useHotkeys } from 'react-hotkeys-hook'

import { useSettings } from '../../hooks/useSettings'
import { useFormBuilder } from './useFormBuilder'

export const useFormBuilderHotkeys = () => {
  const { isBehave } = useSettings()

  const activeElementId: any = useFormBuilder((state) => state.activeElementId)
  const deleteElement: any = useFormBuilder((state) => state.deleteElement)
  const isEditable: any = useFormBuilder((state) => state.isEditable)
  const moveElement: any = useFormBuilder((state) => state.moveElement)
  const environment: any = useFormBuilder((state) => state.environment)

  const hotkeysDisabled = !isEditable || !activeElementId || environment !== 'builder'

  useHotkeys(
    'up',
    () => {
      if (hotkeysDisabled) return

      moveElement(activeElementId, 'up')
    },
    {},
    [moveElement, activeElementId],
  )

  useHotkeys(
    'down',
    () => {
      if (hotkeysDisabled) return
      moveElement(activeElementId, 'down')
    },
    {},
    [moveElement, activeElementId],
  )

  useHotkeys(
    'del',
    () => {
      if (!isBehave || hotkeysDisabled) return

      deleteElement(activeElementId)
    },
    {},
    [activeElementId, isBehave],
  )
}
