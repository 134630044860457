import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import Button from '../Button'
import Dialog from '../Dialog'
import Overlay from '../Overlay'
import Grid from '../Grid'
import Glyph from '../Glyph'
import Icon from '../Icon'
import Section from '../Section'
import Divider from '../Divider'
import State from '../State'
import Alert from '../Alert'
import SummonOverlay from '../SummonOverlay'

import Card from '../Card'
import CardTitle from '../CardTitle'
import CardSubtitle from '../CardSubtitle'
import CardHeader from '../CardHeader'

import { apiGet, apiCreate } from '../../modules/api'
import useStore from '../../modules/store'

import StripeAddCardOverlay from '../Stripe/behave/StripeAddCardOverlay'

import { buildPriceLabel } from './constants'
import { beautifulAmount } from '../../utils/functions'

const stripePromise = loadStripe(process.env.BH_STRIPE_BEHAVE_PUBLIC_KEY)

const Message = ({ glyph, title, description }: any) => (
  <div css={styles.message.root}>
    <Glyph glyph={glyph} />
    <h2 css={styles.message.title}>{title}</h2>
    <p css={styles.message.description}>{description}</p>
  </div>
)

const SubscriptionPlanPaymentOverlay = (props: any) => {
  const { onClose, requiredProducts, oneTimeRequiredProducts, onSuccess } = props

  const updateStore = useStore((state) => state.update)

  const [loading, setLoading] = React.useState(false)
  const [processing, setProcessing] = React.useState(false)

  const [succeeded, setSucceeded] = React.useState(false)

  const payment_methods = useSelector((state: any) => state.data?.payment_methods?.data)
  const hasPaymentMethods = size(payment_methods?.cards) > 0

  let toPayNow = oneTimeRequiredProducts
    .map((product: any) => parseFloat(product.discounted_price || product.price))
    .reduce((a, b) => a + b, 0)
  const hasOneTimeFees = size(oneTimeRequiredProducts) > 0

  const subscribeToPlan = async () => {
    setProcessing(true)

    await apiCreate({
      url: '/subscriptions/subscribe_to_required',
      notify: false,
    })

    const me = await apiGet({ url: '/me', reducer: 'me' })
    updateStore({ tenant: me.data.tenant, subscriptions: me.data.subscriptions })

    setLoading(false)
    setSucceeded(true)

    if (onSuccess) onSuccess()
  }

  const closeOverlay = async () => {
    if (onClose) onClose()
  }

  const getPaymentMethods = async () => {
    try {
      setLoading(true)

      await apiGet({
        notify: false,
        name: 'payment_methods',
        url: `/apps/stripe/payment_methods`,
      })

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    getPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Overlay
      showBackdrop
      position="center"
      closeWrapper={(closeElement: any) => {
        if (succeeded) {
          return <div onClick={onClose}>{closeElement}</div>
        } else {
          return (
            <Dialog
              glyph="circle_error"
              title="Changed your mind?"
              message="This subscription will not be processed."
              yesColor="red"
              yesLabel="Yes, Cancel"
              noLabel="Go Back"
              onYes={closeOverlay}
            >
              {closeElement}
            </Dialog>
          )
        }
      }}
    >
      <Overlay.Header title={`Subscribe to ${size(requiredProducts)} products`} glyph="dollar" />

      <Overlay.Content>
        {loading ? (
          <State isLoading={true} />
        ) : (
          <>
            {succeeded ? (
              <Section>
                <Message glyph="tick_circle" title="Thank you!" description="Your subscription has been processed successfully" />
                <Button label="Close" onClick={onClose} />
              </Section>
            ) : (
              <>
                <Section headingType="h3" title="Subscribing To">
                  <Grid gap="1rem">
                    {requiredProducts.map((product: any) => {
                      let priceLabel = buildPriceLabel(
                        product.pricing_type,
                        product.discounted_price || product.price,
                        product.price_unit_type,
                      )

                      if (product.pricing_type === 'one_time') {
                        priceLabel = `One-Time fee of ${priceLabel}`
                      }

                      return (
                        <>
                          <Card baseline="3rem">
                            <CardHeader graphic={<Icon icon={product.icon} size={20} />}>
                              <CardTitle title={product.name} />
                              <CardSubtitle subtitle={priceLabel} />
                            </CardHeader>
                          </Card>
                        </>
                      )
                    })}
                  </Grid>
                </Section>

                {hasOneTimeFees && (
                  <>
                    <Divider />

                    <Section headingType="h3" title="One Time Fees">
                      <Grid gap="1rem">
                        {oneTimeRequiredProducts.map((product: any) => {
                          let priceLabel = buildPriceLabel(
                            product.pricing_type,
                            product.discounted_price || product.price,
                            product.price_unit_type,
                          )

                          return (
                            <>
                              <Card baseline="3rem">
                                <CardHeader graphic={<Icon icon={product.icon} size={20} />}>
                                  <CardTitle title={product.name} />
                                  <CardSubtitle subtitle={priceLabel} />
                                </CardHeader>
                              </Card>
                            </>
                          )
                        })}
                      </Grid>
                    </Section>

                    <Divider />
                  </>
                )}

                {hasPaymentMethods && (
                  <Section>
                    <Alert glyph="info">
                      Your <strong>Default Payment Method</strong> will be used for Invoicing
                    </Alert>
                  </Section>
                )}

                {!hasPaymentMethods && (
                  <Section headingType="h3" title="New Payment Method" description="Please add a new payment method">
                    <SummonOverlay overlay={<StripeAddCardOverlay onSuccess={getPaymentMethods} />}>
                      <Button label="Add New Payment Method" glyph="add" size={300} display="inline-flex" className="w-[250px]" />
                    </SummonOverlay>
                  </Section>
                )}
              </>
            )}
          </>
        )}
      </Overlay.Content>

      {!succeeded && (
        <Overlay.Footer>
          <Button
            label={`Pay ${beautifulAmount(toPayNow)} Today & Subscribe`}
            glyph="check"
            color="green"
            type="primary"
            onClick={subscribeToPlan}
            isLoading={loading || processing}
            isDisabled={!hasPaymentMethods}
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const styles = {
  dataList: {
    marginBottom: '1rem',
  },
  message: {
    root: {
      textAlign: 'center',
      padding: '2rem 0',
    },

    title: {
      margin: '0.25rem 0 0.5rem',
    },

    description: {
      margin: 0,
    },
  },
}

const SubscriptionPlanPaymentOverlayWithElements = (props) => (
  <Elements stripe={stripePromise}>
    <SubscriptionPlanPaymentOverlay {...props} />
  </Elements>
)

export default SubscriptionPlanPaymentOverlayWithElements
