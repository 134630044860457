import React from 'react'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import produce from 'immer'

import Button from '../../../Button'
import Chotomate from '../../../Chotomate'
import ClientProfileHeader from '../../../ClientProfileHeader'
import ContextHide from '../../../Forms/ContextHide'
import DataFormStatus from '../../../Statuses/DataFormStatus'
import DateInput from '../../../Forms/DateInput'
import Diagnoses from '../../../SmartContent/Diagnoses'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import FormSection from '../../../Forms/FormSection'
import GoalsAndObjectivesV2 from './common/GoalsAndObjectivesV2'
import HelpTagIframe from '../../../Help/HelpTagIframe'
import Input from '../../../Forms/Input'
import Overlay from '../../../Overlay'
import ProblemsV2 from './common/ProblemsV2'
import Section from '../../../Section'
import SNAP from './common/SNAP'
import Status from '../../../Status'
import SummonOverlay from '../../../SummonOverlay'
import Switch from '../../../Forms/Switch'
import Textarea from '../../../Forms/Textarea'
import Permission from '../../../Permission'

import { DataFormsBase, mapStateToProps, mapDispatchToProps } from './DataFormsBase'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import WileyPracticePlannerOverlay from '../../actions/Wiley/WileyPracticePlannerOverlay'

class TreatmentPlanOverlayV2 extends DataFormsBase {
  shouldComponentUpdate = (nextProps, nextState) => {
    if (!isEqual(nextProps.data_form, this.props.data_form)) return true
    if (!isEqual(nextState.wileyData, this.state.wileyData)) return true
    if (nextProps.loading !== this.props.loading) return true
    if (nextProps.idle !== this.props.idle) return true
    if (nextState.$editable !== this.state.$editable) return true
    if (nextState.isValid !== this.state.isValid) return true
    if (nextState.dated_at !== this.state.dated_at) return true

    return false
  }

  renderHeader = () => (
    <Overlay.Header
      icon="treatment_plans"
      title={this.props.data_form?.name || this.props.title}
      help={<HelpTagIframe id="treatment_plans" />}
      titleAside={<DataFormStatus status={this.props.data_form?.status} />}
      description={<ClientProfileHeader client={this.props.data_form?.client || this.props.client} />}
    />
  )

  setWileyData = (wileyData: any) => {
    this.setState({ wileyData: wileyData })
  }

  renderContent = () => {
    const { data, wileyData, $editable }: any = this.state

    return (
      <>
        <Chotomate ready name="treatment_plan_overlay" />

        <Permission featureFlagV2="wiley_treatment_plans">
          {$editable && (
            <>
              <Section headingType="h2" title="Wiley Practice Planner" help={<Status contrast label="Beta" color="orange" />}>
                <SummonOverlay overlay={<WileyPracticePlannerOverlay clientID={data?.client?.id} onSave={this.setWileyData} />}>
                  <Button type="primary" label="Open Wiley Planner" glyph="add_file" />
                </SummonOverlay>
              </Section>

              <Divider />
            </>
          )}
        </Permission>

        <Section
          scrollview={{
            id: 'appointments',
            name: 'Appointments',
          }}
          headingType="h2"
          title="Appointments"
          commentsModel="data.appointments"
        >
          <FormSection layout="vertical">
            <DateInput label="Review Date" model="data.appointments.review_date" />
            <Flex gap={8} stretchChildrenX>
              <Input label="Frequency of Appointments" model="data.appointments.frequency" />
              <Input label="Estimated Length of Treatment" model="data.appointments.estimated_length" />
            </Flex>
          </FormSection>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'diagnoses',
            name: 'Diagnoses',
          }}
          headingType="h2"
          title="Diagnoses"
          commentsModel="data.smart_diagnoses_comments"
        >
          <Diagnoses
            model="data.smart_diagnoses"
            isEditable={$editable}
            client={data.client}
            icon="diagnosis"
            title="Diagnoses"
            emptyDescription="No diagnoses added yet"
          />
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'problems',
            name: 'Problems',
          }}
          headingType="h2"
          title="Problems"
          commentsModel="data.problems_section"
        >
          <ProblemsV2 isEditable={$editable} wileyData={wileyData} model="data.problems" />
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'goals_and_objectives',
            name: 'Goals & Objectives',
          }}
          headingType="h2"
          title="Goals & Objectives"
          commentsModel="data.goals_and_objectives"
        >
          <GoalsAndObjectivesV2 isEditable={$editable} wileyData={wileyData} model="data.goals" />
        </Section>

        <Divider />

        <Section
          title="Strengths, Needs, Abilities, Preferences"
          aside={<Switch horizontal inverse model="data.snap.not_applicable" />}
          scrollview={{
            id: 'snap',
            name: 'S.N.A.P.',
          }}
          commentsModel="data.snap"
        >
          <ContextHide when="data.snap.not_applicable" is={true}>
            <SNAP />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Summary"
          aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
          scrollview={{
            id: 'summary',
            name: 'Summary',
          }}
          commentsModel="data.summary"
        >
          <ContextHide when="data.summary.not_applicable" is={true}>
            <FormSection layout="vertical" maxWidth="100%">
              <Textarea label="Interpretive Summary" model="data.summary.interpretive_summary" />
              <Textarea label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
              <Textarea useQuickText label="Recommendations" model="data.summary.recommendations" />
            </FormSection>
          </ContextHide>
        </Section>
      </>
    )
  }
}

TreatmentPlanOverlayV2.defaultProps = {
  defaultDebounce: 0,
  title: 'Treatment Plan',
  category: 'treatment_plan',
  subcategory: 'treatment_plan',
  version: '2.0',
  requestClientSignature: true,
  requestAuthorSignature: true,
  requireSupervisor: true,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(TreatmentPlanOverlayV2))
