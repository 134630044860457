import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Page, Accordion, Form, FormSection, Input, EmailInput } from '@behavehealth/components'

const pageConfig = {
  feature: 'settings',
  title: 'Settings',
}

const Settings: React.FC = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data: employee }: any = useGet({
    name: ['employee', match.params?.resource_id],
    url: `/employees/${match.params?.resource_id}`,
  })

  const { mutateAsync: updateEmployee }: any = useUpdate({
    name: ['employee', match.params?.resource_id],
    url: `/employees/${match.params?.resource_id}`,
    invalidate: ['employees'],
  })

  return (
    <Page {...pageConfig}>
      <Accordion isOpen title="Behave Health" initialModel={employee} glyph="behave_health" permission={true}>
        <Form>
          <FormSection>
            <Input label="User External ID" model="user_external_id" />
            <EmailInput label="Behave Email Address" type="email" value={`${employee?.user_external_id}@behavehealth.com`} />
          </FormSection>
        </Form>
      </Accordion>

      <Accordion isOpen activateEditMode initialModel={employee} title="Rcopia - Dr First (eRX)" onSubmit={updateEmployee}>
        <Form>
          <FormSection>
            <Input label="User ID" model="rcopia.rcopia_id" />
          </FormSection>
        </Form>
      </Accordion>
    </Page>
  )
}

export default withPageError(withMarketing(Settings, pageConfig))
