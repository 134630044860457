import React from 'react'
import size from 'lodash/size'

import { address, usDate } from '../../utils/functions'

import { parseQuoteVariables } from '../Quotes/functions'

export const QuoteContext: any = React.createContext({})

export const QuoteProvider = (props: any) => {
  const { timezone, children } = props

  const [data, setData]: any = React.useState(props.data || {})
  const [previewData, setPreviewData]: any = React.useState({})
  const [variables, setVariables]: any = React.useState([])

  const variableValues = React.useMemo(() => {
    const result = {}

    for (const variable of variables) {
      result[variable.variable_key] = variable.variable_value || ''
    }

    return result
  }, [variables])

  const parsePreviewData = React.useCallback(async () => {
    if (size(data) === 0) return

    const parsedData = await parseQuoteVariables(data, variableValues)

    setPreviewData(parsedData)
  }, [data, variableValues])

  React.useEffect(() => {
    // if (size(data) === 0) return

    setVariables([
      {
        name: 'Date Sent',
        variable_value: data?.sent_at ? usDate(data.sent_at, timezone) : '',
        variable_key: 'QV_SENT_AT',
      },
      {
        name: 'Expiry Date',
        variable_value: data?.expires_at ? usDate(data.expires_at, timezone) : '',
        variable_key: 'QV_EXPIRES_AT',
      },
      {
        name: 'Billed By Company',
        variable_value: data?.billed_by_company_name || '',
        variable_key: 'QV_BILLED_BY_COMPANY_NAME',
      },
      {
        name: 'Billed By Name',
        variable_value: data?.billed_by_name || '',
        variable_key: 'QV_BILLED_BY_NAME',
      },
      {
        name: 'Billed By Email',
        variable_value: data?.billed_by_email || '',
        variable_key: 'QV_BILLED_BY_EMAIL',
      },
      {
        name: 'Billed By Phone No',
        variable_value: data?.billed_by_phone_no || '',
        variable_key: 'QV_BILLED_BY_PHONE_NO',
      },
      {
        name: 'Billed By Address',
        variable_value: data?.billed_by_address ? address(data.billed_by_address) : '',
        variable_key: 'QV_BILLED_BY_ADDRESS',
      },
      {
        name: 'Billed By City',
        variable_value: data?.billed_by_address?.city || '',
        variable_key: 'QV_BILLED_BY_CITY',
      },
      {
        name: 'Billed By State',
        variable_value: data?.billed_by_address?.state || '',
        variable_key: 'QV_BILLED_BY_STATE',
      },
      {
        name: 'Billed To Company',
        variable_value: data?.billed_to_company_name || '',
        variable_key: 'QV_BILLED_TO_COMPANY_NAME',
      },
      {
        name: 'Billed To Name',
        variable_value: data?.billed_to_name || '',
        variable_key: 'QV_BILLED_TO_NAME',
      },
      {
        name: 'Billed To Email',
        variable_value: data?.billed_to_email || '',
        variable_key: 'QV_BILLED_TO_EMAIL',
      },
      {
        name: 'Billed To Phone No',
        variable_value: data?.billed_to_phone_no || '',
        variable_key: 'QV_BILLED_TO_PHONE_NO',
      },
      {
        name: 'Billed To City',
        variable_value: data?.billed_to_address?.city || '',
        variable_key: 'QV_BILLED_TO_CITY',
      },
      {
        name: 'Billed To State',
        variable_value: data?.billed_to_address?.state || '',
        variable_key: 'QV_BILLED_TO_STATE',
      },
      {
        name: 'Billed To Address',
        variable_value: data?.billed_to_address ? address(data.billed_to_address) : '',
        variable_key: 'QV_BILLED_TO_ADDRESS',
      },
    ])
  }, [data, timezone])

  return (
    <QuoteContext.Provider
      value={{
        data,
        variables,
        setData,
        variableValues,
        timezone,
        previewData,
        parsePreviewData,
      }}
    >
      {children}
    </QuoteContext.Provider>
  )
}

export const useQuoteContext = () => {
  const context = React.useContext(QuoteContext)

  if (!context) {
    throw new Error('useQuoteContext must be used within a QuoteProvider')
  }

  return context
}
