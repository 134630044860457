import React from 'react'
import { connect } from 'react-redux'
import withRouter from '../../../hocs/withRouter'

import AmountInput from '../../Forms/AmountInput'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Section from '../../Section'
import Select from '../../Forms/Select'
import State from '../../State'
import Textarea from '../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class InsuranceServiceOverlay extends OverlayBase {
  renderHeader = () => <Overlay.Header icon="insurance" title={this.state.$new ? 'Add Insurance Service' : 'Insurance Service'} />

  renderContent = () => {
    const { $editable, params } = this.state
    const { record, user } = this.props

    const isBehave = user?.type === 'bh_employee'
    const isGlobalService = record?.service_type === 'global_oon'

    return (
      <Overlay.Content>
        {isGlobalService && (
          <State
            isEmpty
            icon="insurance"
            title="Global Out-of-Network Service"
            emptyDescription="This is a global service managed under Settings for all payers."
            emptyActions={
              <Button
                size={200}
                label="Manage in Settings →"
                link="/settings/out-of-network-services"
                glyph="settings"
                permission="settings.out_of_network_services.view"
              />
            }
          />
        )}

        {!isGlobalService && (
          <Form
            useFullModel
            getForm={this.form}
            initialModel={{ ...record, ...params }}
            isEditable={$editable}
            onValidationUpdate={this.onValidationUpdate}
            decorate={(data: any) => ({
              linked_service_id: data.should_replace_service ? data.linked_service?.id : null,
            })}
          >
            {({ data }) => (
              <Section>
                <FormSection>
                  <RadioGroup label="Service Type" model="service_type" layout="vertical-dense">
                    <Radio label="In Network" value="in_network" />
                    <Radio label="Out of Network" value="out_of_network" />
                  </RadioGroup>

                  <Checkbox
                    label="Replace a global out-of-network service for this payer"
                    model="should_replace_service"
                    value={!!data.linked_service}
                  />

                  {data.should_replace_service && (
                    <ObjectSelector
                      label="Service to Replace"
                      model="linked_service"
                      icon="behave_health"
                      type="insurance_global_oon_services"
                      selectTitle={(data: any) => data.short_description}
                      selectDescription={(data: any) => `${data?.code} – ${data.long_description}`}
                      searchKeys={['short_description', 'long_description', 'code']}
                      validations={{
                        presence: {
                          message: 'Please select an insurance service to replace',
                        },
                      }}
                    />
                  )}

                  <Flex gap="0.5rem">
                    <Input
                      label="Code"
                      model="code"
                      value={data.insurance_service?.code}
                      validations={{
                        presence: {
                          message: 'Please enter a code',
                        },
                      }}
                    />

                    <AmountInput
                      label="Rate"
                      model="rate"
                      validations={{
                        presence: {
                          message: 'Please enter a rate',
                        },
                      }}
                    />

                    <Select
                      label="Unit"
                      model="unit"
                      value={data.insurance_service?.unit}
                      validations={{
                        presence: {
                          message: 'Please select a unit',
                        },
                      }}
                    >
                      <Option label="per occurrence" value="per_occurrence" />
                      <Option label="per day" value="per_day" />
                      <Option label="per hour" value="per_hour" />
                      <Option label="per test" value="per_test" />
                    </Select>
                  </Flex>

                  <Input
                    label="Short Description"
                    model="short_description"
                    value={data.insurance_service?.short_description}
                    validations={{
                      presence: {
                        message: 'Please enter a description',
                      },
                    }}
                  />

                  <Textarea label="Long Description" model="long_description" value={data.insurance_service?.long_description} />

                  <Textarea label="Notes" model="notes" value={data.insurance_service?.notes} />

                  <FormSection css={{ display: isBehave ? 'grid' : 'none' }}>
                    <Select label="Code Type" model="code_type" value={data.insurance_service?.code_type}>
                      <Option label="CPT" value="cpt" />
                      <Option label="HCPC/MOD" value="hcpc_mod" />
                    </Select>

                    <Input label="Provider" model="provider" value={data.insurance_service?.provider} />

                    <Textarea
                      label="Billing Best Practices"
                      model="billing_best_practices"
                      value={data.insurance_service?.billing_best_practices}
                    />
                  </FormSection>
                </FormSection>
              </Section>
            )}
          </Form>
        )}
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state
    const { record } = this.props

    if (record?.service_type === 'global_oon') return null

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save Insurance Service"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Service"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
            />

            <DeleteDialog
              title="Delete Insurance Service?"
              message="Are you sure you want to delete this insurance service? This action cannot be undone."
              onYes={this.delete}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={this.props.loading} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_services')

export default withRouter(connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(InsuranceServiceOverlay)))
