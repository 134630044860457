import React from 'react'
import size from 'lodash/size'

import { COLORS } from '../../../theme'

import RoadmapRow from '../../../components/Roadmap/RoadmapRow'
import RoadmapHeaderRow from '../../../components/Roadmap/RoadmapHeaderRow'

import { RecordTooltip } from './RecordTooltip'

const getAllData = (authored: any, assigned: any) => {
  const result = []

  if (size(authored) >= 1) {
    for (let i = 0; i < authored.length; i++) {
      result.push({ ...authored[i], _record_type: 'authored' })
    }
  }

  if (size(assigned) >= 1) {
    for (let i = 0; i < assigned.length; i++) {
      result.push({ ...assigned[i], _record_type: 'assigned' })
    }
  }

  return result
}

export const RoadmapRecordRow = (props: any) => {
  const {
    color = 'hotpink',
    data,
    icon,
    isOpen,
    recordLink,
    renderTitle = (record: any) => record.name,
    renderTooltip,
    startDateKey = 'started_at',
    title,
  } = props

  if (!data) return null

  const allData = getAllData(data.authored, data.assigned)
  const isEmpty = size(allData) === 0

  return (
    <RoadmapRow
      hideGridLines
      isDisabled={isEmpty}
      isOpen={isOpen}
      level={1}
      icon={icon}
      title={title}
      renderBars={({ isOpen }: any) => {
        if (isOpen || size(allData) === 0) return null

        return allData.map((record: any) => (
          <RecordTooltip
            startDate={record[startDateKey]}
            color={color}
            icon={icon}
            title={renderTitle(record)}
            recordLink={recordLink?.(record)}
            graphicShape={record._record_type === 'authored' ? 'circle' : 'square'}
            recordType={record._record_type}
          >
            {renderTooltip && renderTooltip(record)}
          </RecordTooltip>
        ))
      }}
    >
      {!isEmpty && (
        <>
          {allData.map((record: any) => (
            <RoadmapRow
              key={record.id}
              isOpen={isOpen}
              level={2}
              title={renderTitle(record)}
              renderBars={() => (
                <RecordTooltip
                  startDate={record[startDateKey]}
                  color={color}
                  icon={icon}
                  title={renderTitle(record)}
                  recordLink={recordLink?.(record)}
                  graphicShape={record._record_type === 'authored' ? 'circle' : 'square'}
                  recordType={record._record_type}
                >
                  {renderTooltip && renderTooltip(record)}
                </RecordTooltip>
              )}
            />
          ))}
        </>
      )}

      {/* {size(data.authored) >= 1 && (
        <>
          <RoadmapHeaderRow isSticky={false}>
            <div css={STYLES.headerTitle}>Authored</div>
          </RoadmapHeaderRow>

          {data.authored.map((record: any) => (
            <RoadmapRow
              key={record.id}
              isOpen={isOpen}
              level={2}
              title={renderTitle(record)}
              renderBars={() => (
                <RecordTooltip
                  startDate={record[startDateKey]}
                  color={color}
                  icon={icon}
                  title={renderTitle(record)}
                  recordLink={recordLink?.(record)}
                >
                  {renderTooltip && renderTooltip(record)}
                </RecordTooltip>
              )}
            />
          ))}
        </>
      )}

      {size(data.assigned) >= 1 && (
        <>
          <RoadmapHeaderRow isSticky={false}>
            <div css={STYLES.headerTitle}>Assigned</div>
          </RoadmapHeaderRow>

          {data.assigned.map((record: any) => (
            <RoadmapRow
              key={record.id}
              isOpen={isOpen}
              level={2}
              title={renderTitle(record)}
              renderBars={() => (
                <RecordTooltip
                  startDate={record[startDateKey]}
                  color={color}
                  icon={icon}
                  title={renderTitle(record)}
                  recordLink={recordLink?.(record)}
                >
                  {renderTooltip && renderTooltip(record)}
                </RecordTooltip>
              )}
            />
          ))}
        </>
      )} */}
    </RoadmapRow>
  )
}

const STYLES = {
  headerTitle: {
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: 600,
    fontSize: '0.78rem',
    marginLeft: 22,
    color: COLORS.textMuted,
  },
}
