import React from 'react'
import { useParams } from 'react-router-dom'

import { titleCase } from '../../../utils/functions'
import { apiUpdate } from '../../../modules/api'
import withSettings from '../../../hocs/withSettings'

import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import Textarea from '../../../components/Forms/Textarea'
import Workflow from '../../../components/Workflow/Workflow'
import MiniRichTextEditor from '../../../components/Forms/MiniRichTextEditor'

const ReferralDetails = ({ client, setStatus, timezone }: any) => {
  const { resource_id }: any = useParams()
  const { goNext }: any = React.useContext(Workflow.Context)

  const form = React.useRef()
  const [requestLoading, setRequestLoading] = React.useState(false)

  const onContinue = async () => {
    setRequestLoading(true)

    await apiUpdate({ name: 'clients', url: `/residents/${resource_id}`, params: form.current?.getFormValue() })

    setRequestLoading(false)

    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  return (
    <Form useLocalModel getForm={form} initialModel={client} timezone={timezone}>
      <FormSection maxWidth="100%">
        <ObjectSelector
          label="Referral In"
          model="referral_source"
          type="organizations"
          icon="organizations"
          selectTitle={(data: any) => data.name}
          selectDescription={(data: any) => `${titleCase(data.category)} - ${titleCase(data.subcategory?.name)}`}
        />

        <ObjectSelector
          model="referral_in_contact"
          label="Referral In Contact"
          type="organization.contacts"
          dependent="referral_source_id"
          selectTitle={(data) => data.name}
          selectDescription={(data) => data.role}
        />

        <MiniRichTextEditor useQuickText label="Referral In Notes" model="referral_notes" />

        <ObjectSelector
          label="Referral Out"
          model="referral_out"
          type="organizations"
          icon="organizations"
          selectTitle={(data: any) => data.name}
          selectDescription={(data: any) => `${titleCase(data.category)} - ${titleCase(data.subcategory?.name)}`}
        />

        <ObjectSelector
          model="referral_out_contact"
          label="Referral Out Contact"
          type="organization.contacts"
          dependent="referral_out_id"
          selectTitle={(data) => data.name}
          selectDescription={(data) => data.role}
        />

        <MiniRichTextEditor useQuickText label="Referral Out Notes" model="referral_out_notes" />

        <Workflow.Buttons>
          <Workflow.ContinueButton label="Save & Continue →" onClick={onContinue} isLoading={requestLoading} />
          <Workflow.SkipButton onClick={onSkip} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default withSettings(ReferralDetails)

// import React from 'react'

// import { titleCase } from '../../../utils/functions'
// import withSettings from '../../../hocs/withSettings'

// import Form from '../../../components/Forms/Form'
// import FormSection from '../../../components/Forms/FormSection'
// import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
// import Textarea from '../../../components/Forms/Textarea'
// import Workflow from '../../../components/Workflow/Workflow'

// const ReferralDetails = ({ client, data, setData, setStatus, timezone }: any) => {
//   const { goNext }: any = React.useContext(Workflow.Context)

//   const onSkip = () => {
//     setStatus('skipped')
//     goNext()
//   }

//   return (
//     <Form useLocalModel initialModel={data} timezone={timezone}>
//       <FormSection maxWidth="100%">
//         <ObjectSelector
//           label="Referral In"
//           model="referral_in"
//           type="local_organizations"
//           icon="organizations"
//           onUpdate={(state: any) => setData({ referral_in: state?.value, referral_in_id: state?.value?.id })}
//           selectTitle={(data: any) => data.name}
//           selectDescription={(data: any) => `${titleCase(data.category)} - ${titleCase(data.subcategory?.name)}`}
//         />

//         <ObjectSelector
//           model="referral_in_contact"
//           label="Referral In Contact"
//           type="organization.contacts"
//           dependentValue={data?.referral_in_id || client?.referral_in_id}
//           onUpdate={(state: any) => setData({ referral_in_contact: state.value, referral_in_contact_id: state.value?.id })}
//           selectTitle={(data: any) => data.name}
//           selectDescription={(data: any) => data.role}
//         />

//         <Textarea
//           useQuickText
//
//           label="Referral In Notes"
//           model="referral_in_notes"
//           onUpdate={(state: any) => setData({ referral_in_notes: state.value })}
//         />

//         <ObjectSelector
//           label="Referral Out"
//           model="referral_out"
//           type="local_organizations"
//           icon="organizations"
//           onUpdate={({ object }: any) => setData({ referral_out: object, referral_out_id: object?.id })}
//           selectTitle={(data: any) => data.name}
//           selectDescription={(data: any) => `${titleCase(data.category)} - ${titleCase(data.subcategory?.name)}`}
//         />

//         <ObjectSelector
//           model="referral_out_contact"
//           label="Referral Out Contact"
//           type="organization.contacts"
//           dependentValue={data?.referral_out_id || client?.referral_out_id}
//           onUpdate={({ object }: any) => setData({ referral_out_contact: object, referral_out_contact_id: object?.id })}
//           selectTitle={(data: any) => data.name}
//           selectDescription={(data: any) => data.role}
//         />

//         <Textarea
//           useQuickText
//
//           label="Referral Out Notes"
//           model="referral_out_notes"
//           onUpdate={(state: any) => setData({ referral_out_notes: state.value })}
//         />

//         <Workflow.Buttons>
//           {/* <Workflow.ContinueButton  label="Save & Continue →" onClick={onContinue} isLoading={requestLoading} /> */}
//           <Workflow.ContinueButton  label="Continue →" />
//           <Workflow.SkipButton onClick={onSkip} />
//         </Workflow.Buttons>
//       </FormSection>
//     </Form>
//   )
// }

// export default withSettings(ReferralDetails)
