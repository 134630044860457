import React from 'react'

import Button from '../../Button'
import Form from '../../Forms/Form'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import Overlay from '../../Overlay'
import Section from '../../Section'

import { apiUpdate } from '../../../modules/api'
import { mapToArray } from '../../../utils/functions'

const ClientAccessListOverlay = ({ channel, clientID, onClose }: any) => {
  const [loading, setLoading] = React.useState(false)
  const [valid, setValid] = React.useState(false)
  const form = React.useRef()

  const updateAccessList = async () => {
    const model = form.current.getFormValue()

    setLoading(true)

    try {
      await apiUpdate({ name: 'client_channels', url: `/chat/channels/clients/${clientID}/access_list`, params: model })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
      onClose()
    }
  }

  if (!channel) return null
  const channelMembers = mapToArray(channel?.members)

  return (
    <Overlay showBackdrop onClose={onClose}>
      <Overlay.Header icon="clients_chat" title={channel.client_name} description={channel.name} />

      <Overlay.Content>
        <Form getForm={form} onValidationUpdate={setValid} initialModel={{ members: channelMembers }}>
          <Section>
            <MultiObjectSelector
              model="members"
              label="Access List"
              blankLabel="Select Employees…"
              type="employees"
              icon="employees"
              validations={{
                presence: {
                  message: 'Please select at least one employee',
                },
              }}
            />
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          glyph="check"
          label="Save Access List"
          type="primary"
          color="green"
          isLoading={loading}
          onClick={updateAccessList}
          isDisabled={!valid}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default ClientAccessListOverlay
