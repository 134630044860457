import React from 'react'
import debounce from 'lodash/debounce'

import { request } from '../../modules/axios'
import { notionIDFormURL, formatURL } from '../../utils/functions'

import Alert from '../Alert'
import Button from '../Button'
import Card from '../Card'
import Form from '../Forms/Form'
import Input from '../Forms/Input'
import Overlay from '../Overlay'
import Radio from '../Forms/Radio'
import RadioGroup from '../Forms/RadioGroup'
import State from '../State'
import URLInput from '../Forms/URLInput'

import { NotionRenderer } from './NotionRenderer'

export const TenantNotionPageSetupOverlay = ({ url = '', title = '', onSave, onClose }: any) => {
  const form = React.useRef()

  const [type, setType] = React.useState('notion')
  const [pageId, setPageId] = React.useState('')
  const [iframeURL, setIframeURL] = React.useState('')
  const [notionData, setNotionData] = React.useState(null)

  const [isLoading, setIsLoading] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [valid, setValid] = React.useState(false)

  const debouncedUpdate = debounce((value) => {
    setIframeURL(value)
  }, 100)

  React.useEffect(() => {
    if (!pageId || type !== 'notion') return

    const getNotionData = async () => {
      setIsLoading(true)

      try {
        const response = await request.get(`${process.env.BH_NOTION_API_BASE}/api/page/${pageId}`, {
          headers: null,
        })

        setHasError(false)
        setNotionData(response.data)
      } catch (error) {
        setHasError(true)
        setNotionData(null)
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    getNotionData()
  }, [pageId])

  React.useEffect(() => {
    if (type === 'iframe') setHasError(false)
  }, [type])

  const save = async () => {
    setIsSaving(true)
    const model = form.current.getFormValue()

    if (onSave) {
      try {
        await onSave({
          name: model.name,
          url: model.url,
          notion_page: pageId,
          type: model.type,
        })
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    }

    if (onClose) onClose()
  }

  return (
    <Overlay showBackdrop fullheight position="center" maxWidth={54} onClose={onClose}>
      <Overlay.Header title="Page Link" glyph="edit" />

      <Overlay.Content>
        <section css={styles.section}>
          <Form getForm={form} onValidationUpdate={setValid}>
            <RadioGroup
              model="type"
              layout="horizontal-dense"
              defaultValue="notion"
              maxWidth="100%"
              onUpdate={({ value }: any) => {
                setType(value)
              }}
            >
              <Radio label="Notion" value="notion" />
              <Radio label="iFrame" value="iframe" />
            </RadioGroup>

            <Input
              label="Title"
              model="name"
              defaultValue={title}
              maxWidth="100%"
              validations={{
                presence: {
                  message: 'Please enter a page title',
                },
              }}
            />

            {type === 'iframe' && (
              <>
                <URLInput
                  label="iFrame URL"
                  model="url"
                  glyph="website"
                  maxWidth="100%"
                  defaultValue={url}
                  isDisabled={isLoading}
                  onUpdate={({ value }: any) => {
                    if (!value) return

                    debouncedUpdate(value)
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter a valid URL',
                    },
                  }}
                />

                {!iframeURL && (
                  <Card css={styles.emptyWrapper}>
                    <State
                      isEmpty
                      isLoading={isLoading}
                      glyph="website"
                      title="iFrame Preview"
                      css={styles.state}
                      emptyDescription="Please enter a URL"
                    />
                  </Card>
                )}

                {iframeURL && (
                  <Card css={styles.iframeWrapper}>
                    <iframe
                      frameBorder="0"
                      border="0"
                      cellSpacing="0"
                      src={formatURL(iframeURL)}
                      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                    />
                  </Card>
                )}
              </>
            )}

            {type === 'notion' && (
              <>
                <URLInput
                  label="Notion URL"
                  model="url"
                  glyph="notion"
                  maxWidth="100%"
                  defaultValue={url}
                  isDisabled={isLoading}
                  onUpdate={({ value }: any) => {
                    if (!value) return

                    setPageId(notionIDFormURL(value) || '')
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter a valid public Notion URL',
                    },
                  }}
                />

                {!notionData && (
                  <Card css={styles.emptyWrapper}>
                    <State
                      isEmpty
                      isLoading={isLoading}
                      glyph="notion"
                      title="Notion Page Preview"
                      css={styles.state}
                      emptyDescription={
                        hasError ? (
                          <Alert type="negative" glyph="circle_error">
                            This URL doesn't link to a valid or public Notion page.
                          </Alert>
                        ) : (
                          'Please enter a valid public Notion URL to preview'
                        )
                      }
                    />
                  </Card>
                )}

                {notionData && !hasError && (
                  <Card css={styles.notionWrapper}>
                    <NotionRenderer fullPage disableHeader showTableOfContents recordMap={notionData} darkMode={false} />
                  </Card>
                )}
              </>
            )}
          </Form>
        </section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save"
          glyph="check"
          color="green"
          type="primary"
          onClick={save}
          isLoading={isSaving}
          isDisabled={hasError || !valid || (type === 'notion' && !notionData)}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const styles = {
  section: {
    height: '100%',
    padding: '1rem',

    form: {
      height: '100%',
      display: 'grid',
      gridGap: '1rem',
      gridTemplateRows: 'min-content min-content min-content 1fr',
    },
  },

  emptyWrapper: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
  },

  notionWrapper: {
    overflow: 'auto',

    '.notion-app': {
      pointerEvents: 'none !important',
    },

    'main.notion-page': {
      marginTop: '32px !important',
    },

    '.notion-page-icon-wrapper': {
      justifyContent: 'flex-start',
      paddingLeft: '0.75rem',

      img: {
        maxWidth: '48px !important',
        maxHeight: '48px !important',
        width: '48px !important',
        height: '48px !important',
      },
    },

    '.notion-page-no-cover.notion-page-has-image-icon': {
      paddingTop: '85px !important',
    },
  },

  iframeWrapper: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',

    iframe: {
      width: '100%',
      height: '100%',
    },
  },

  state: {
    '& > div': {
      maxWidth: '450px !important',
    },
  },
}
