import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useDataForms } from '@behavehealth/hooks/useDataForms'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Chotomate, Button, Card, Grid, Page, HelpTagIframe, Link } from '@behavehealth/components'
import { ProgressNotesTable } from '@behavehealth/components/Tables'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { Filters } from '@behavehealth/constructs/Filters/Filters'
import { FILTERS } from '@behavehealth/constructs/Filters/config'

const pageConfig = {
  feature: 'progress_notes',
  help: <HelpTagIframe id="progress_notes" />,
  marketingID: 'progress_notes',
}

type Props = {
  canCreate: boolean
}

const ProgressNotes: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { isBehave } = useSettings()

  const { data, isEmpty, isLoading, batchDelete, setFilters } = useDataForms({ category: 'progress_note' })

  const actions = (
    <Button
      label="Add Progress Note"
      type="primary"
      glyph="add"
      link={{
        pathname: `${match.url}/new`,
        parent: match,
      }}
      permission="progress_notes.create"
    />
  )

  return (
    <Page actions={!isEmpty && canCreate && actions} {...pageConfig}>
      <Grid gap="1rem">
        <Chotomate name="progress_notes" ready={!isLoading} />

        <Filters config={FILTERS.progress_notes} onUpdate={setFilters} localStorageKey="progress_notes" />

        <Card>
          <ProgressNotesTable
            data={data}
            isLoading={isEmpty && isLoading}
            isBehave={isBehave}
            emptyActions={canCreate && actions}
            localStorageKey="clients_progress_notes"
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            // titleAfter={<Link to="/reports/progress-notes">View Full Report →</Link>}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'progress_notes.delete',
                action: batchDelete,
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ProgressNotes, pageConfig))
