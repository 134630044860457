import React from 'react'
import { useParams } from 'react-router-dom'
import * as HoverCard from '@radix-ui/react-hover-card'

import { COLORS } from '../../../theme'

import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import Icon from '../../../components/Icon'
import RoadmapGraphic from '../../../components/Roadmap/RoadmapGraphic'

import { CardTooltip } from './CardTooltip'
import { CardTooltipContent } from './CardTooltipContent'
import { ClientHeader } from './ClientHeader'

export const RecordTooltip = (props: any) => {
  const { color, client, startDate, children, icon, title, recordLink, renderGraphic } = props

  const { resource_id }: any = useParams()

  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  const graphic = renderGraphic ? (
    renderGraphic({ openTooltip })
  ) : (
    <RoadmapGraphic startDate={startDate} onClick={openTooltip} background={color || COLORS.blue} />
  )

  if (!children) return graphic

  return (
    <CardTooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
      <HoverCard.Trigger asChild>{graphic}</HoverCard.Trigger>

      <CardTooltipContent>
        {/* {client && <ClientHeader client={client} />} */}

        {title && (
          <Flex nowrap centerY css={{ padding: '0.5rem 1rem 0.25rem' }}>
            {icon && <Icon icon={icon} size={20} className="!mr-2" />}
            <div className="text-[1.1rem] font-[700]">{title}</div>

            {recordLink && (
              <Button label="Open" size={100} link={`/clients/${resource_id}${recordLink}`} css={{ marginLeft: 'auto' }} target="_blank" />
            )}
          </Flex>
        )}
        {children}
      </CardTooltipContent>
    </CardTooltip>
  )
}
