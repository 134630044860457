import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  institutional: {
    label: 'Institutional Claim (UB-04)',
    color: 'orange',
  },
  professional: {
    label: 'Professional Claim (CMS-1500)',
    color: 'blue',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
