import React from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom-v5-compat'

import { useRouteURL } from '../hooks/useRouteURL'

const withRouterV6 = (Wrapped) => (props) => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const { url } = useRouteURL()

  return (
    <Wrapped
      history={{
        push: navigate,
        goBack: () => navigate(-1),
      }}
      location={location}
      match={{
        url,
        params,
        pathname: location.pathname,
      }}
      {...props}
    />
  )
}

withRouterV6.displayName = 'withRouterV6'
export default withRouterV6
