import React from 'react'
import clsx from 'clsx'

const FlexItem = (props) => {
  const {
    as,
    children,
    className,
    onClick,

    flex,
    fixSelf,
    stretchSelf,
    stretchSelfMax,
  } = props

  const Tag = as

  const classNames = clsx({
    'fix-self': fixSelf,
    'stretch-self': stretchSelf,
    'stretch-self-max': stretchSelfMax,
    [className]: className,
  })

  return <Tag className={classNames} children={children} onClick={onClick} css={styles} style={{ flex }} />
}

const styles = {
  '&.fix-self': {
    flex: '0 0 auto',
  },

  '&.stretch-self': {
    flex: '1 1 auto',
  },

  '&.stretch-self-max': {
    flex: '999 1 auto',
  },
}

FlexItem.defaultProps = {
  as: 'div',
}

export default FlexItem
