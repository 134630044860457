import React from 'react'

const CardMeta: React.FC<React.HTMLAttributes<any>> = ({ children, className }) => {
  return (
    <div css={styles} className={className}>
      {children}
    </div>
  )
}

const styles: any = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  margin: '-0.25rem',

  '& > *': {
    margin: '0.25rem',
  },
}

export default CardMeta
