import React from 'react'
import { connect } from 'react-redux'
import { transparentize } from 'polished'
import size from 'lodash/size'

import { apiUpdate } from '../../../modules/api'
import { COLORS } from '../../../theme'
import { usDate, usDateTime, countWord, daysBetween } from '../../../utils/functions'
import Notifications from '../../../modules/notifications'

import { H3, H4 } from '../../Typography'
import Button from '../../Button'
import CardTreeItem from '../../CardTreeItem'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ClientProfileHeader from '../../ClientProfileHeader'
import ConfirmDialog from '../../Dialogs/ConfirmDialog'
import ContextShow from '../../Forms/ContextShow'
import DataFormStatus from '../../Statuses/DataFormStatus'
import DataList from '../../DataList'
import DateTimeInput from '../../Forms/DateTimeInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Grid from '../../Grid'
import Input from '../../Forms/Input'
import Nav from '../../Nav'
import Overlay from '../../Overlay'
import OverlayLoader from '../../OverlayLoader'
import PageGrid from '../../PageGrid'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import RatingInput from '../../Forms/RatingInput'
import ScrollMenu from '../../ScrollMenu'
import ScrollView from '../../ScrollView'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'
import StateSelect from '../../Forms/elements/StateSelect'

import EVOBDataView from '../../DataView/EVOBDataView'
import InsurancePoliciesDataView from '../../DataView/InsurancePoliciesDataView'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

// import VOBCalls from './VOBCalls'
// import VOBServiceLines from '../../RCM/components/VOBServiceLines'
import VOBServices from '../../RCM/components/VOBServices'

class VOBOverlay extends OverlayBase {
  signOff = async () => {
    this.setState({ $loading: true })

    const { id } = this.state

    try {
      await apiUpdate({ name: 'insurance_vobs', url: `/insurance_vobs/${id}`, params: { status: 'signed_off' }, notify: false })
      Notifications.send('VOB submitted successfully', 'positive')
    } catch (error) {
      Notifications.send('Something went wrong, the VOB was not submitted', 'negative')
    }

    this.setState({ $loading: false })
  }

  render() {
    const { $new, $editable, isInvalid } = this.state
    const { record, current, online, timezone, loading } = this.props

    const isBehave = this.props?.user?.type === 'bh_employee'
    const emptyInsurancePayer = !record?.insurance_policy

    if (!$new && size(record) === 0) {
      return <OverlayLoader position={this.props.position} showBackdrop={this.props.showBackdrop} />
    }

    return (
      <Overlay showBackdrop position="center" onClose={this.close} maxWidth={120}>
        <Overlay.Header
          title="VOB"
          icon="insurance"
          titleAside={<DataFormStatus status={record?.status} />}
          description={<ClientProfileHeader client={current} />}
        />

        <ScrollView>
          <PageGrid scroll breakpoint={3} columns="min-content 1fr">
            <Nav title="Table of Contents" breakpoint={3} top="0" background={transparentize(0.4, 'white')}>
              <ScrollMenu />
            </Nav>

            <Overlay.Content style={{ padding: 1 }}>
              <Form
                getForm={this.form}
                initialModel={record}
                isEditable={$editable}
                timezone={timezone}
                onValidationUpdate={this.onValidationUpdate}
              >
                <Section
                  title="General"
                  scrollview={{
                    id: 'general',
                    name: 'General',
                  }}
                >
                  <FormSection>
                    <DateTimeInput
                      defaultToNow
                      model="dated_at"
                      label="Date and Time"
                      validations={{
                        presence: {
                          message: 'Please enter a date and time',
                        },
                      }}
                    />

                    {/* <ObjectSelector
                      label="Assigned Behave Team Member"
                      icon="employees"
                      type="bh_employees.all"
                      model="employee"
                      selectTitle={(data: any) => data.name}
                    /> */}
                  </FormSection>

                  <Grid gap="0.5rem" className="!mt-4">
                    <CardTreeItem isOpen title="Client Details">
                      <DataList labelWidth="180px">
                        <DataList.Item label="Full Name" value={current?.name} />
                        {/* TODO (Andrei): should DOB have a timezone */}
                        <DataList.Item label="Date of Birth" value={usDate(current?.dob, this.props.timezone)} />
                        <DataList.Item
                          label="Est. Admit Date:"
                          value={usDateTime(current?.current_admission?.admitted_at, this.props.timezone)}
                        />
                        <DataList.Item
                          label="Est. Discharge Date:"
                          value={usDateTime(current?.current_admission?.estimated_discharge, this.props.timezone)}
                        />
                        <DataList.Item
                          label="Est. Length of Stay:"
                          value={countWord(
                            'days',
                            daysBetween(
                              current?.current_admission?.admitted_at,
                              current?.current_admission?.estimated_discharge,
                              this.props.timezone,
                            ),
                          )}
                        />
                      </DataList>
                    </CardTreeItem>

                    <CardTreeItem shouldIndent={false} title="All Insurance Policies">
                      <InsurancePoliciesDataView clientID={current?.id} />
                    </CardTreeItem>

                    <CardTreeItem shouldIndent={false} title="All eVOBs">
                      <EVOBDataView clientID={current?.id} />
                    </CardTreeItem>
                  </Grid>
                </Section>

                <Divider />

                <VOBServices
                  scrollview={{
                    id: 'services',
                    name: 'Services',
                  }}
                  model="services"
                  value={record.services}
                />

                <Divider />

                <Section
                  title="Accreditations and Exclusions"
                  scrollview={{
                    id: 'accreditations_and_exclusions',
                    name: 'Accreditations and Exclusions',
                  }}
                >
                  <FormSection>
                    <RadioGroup
                      label="Are any accreditations required for services to be covered?"
                      model="data.accreditations.has_required_accreditations"
                      layout="horizontal-dense"
                    >
                      <Radio label="Yes" value={true} />
                      <Radio label="No" value={false} />
                    </RadioGroup>

                    <ContextShow when="data.accreditations.has_required_accreditations" is={true}>
                      <CheckboxGroup layout="vertical-dense" label="Required Accreditations" trueIcon="check">
                        <Checkbox label="The Joint Commission (JCAHO)" model="data.accreditations.jcaho" />
                        <Checkbox
                          label="Commission on Accreditation of Rehabilitation Facilities (CARF)"
                          model="data.accreditations.carf"
                        />
                        <Checkbox label="Council on Accreditation (COA)" model="data.accreditations.coa" />
                        <Checkbox label="National Committee for Quality Assurance (NCQA)" model="data.accreditations.ncqa" />
                        <Checkbox label="Healthcare Facilities Accreditation Program (HFAP)" model="data.accreditations.hfap" />
                      </CheckboxGroup>
                    </ContextShow>

                    <RadioGroup
                      label="Are free standing facilities covered?"
                      model="data.accreditations.are_free_standing_facilities_covered"
                      layout="horizontal-dense"
                      description="Facilities that are not part of a hospital"
                    >
                      <Radio label="Yes" value={true} />
                      <Radio label="No" value={false} />
                    </RadioGroup>

                    <Textarea label="Are there any exclusions on the plan?" model="data.accreditations.exclusions" />
                  </FormSection>
                </Section>

                <Divider />

                <Section
                  title="Claims Submission Info"
                  scrollview={{
                    id: 'claims_submission_info',
                    name: 'Claims Submission Info',
                  }}
                >
                  <FormSection>
                    <Input label="Payer ID" model="data.claims.payer_id" />

                    <CheckboxGroup label="Type of Claims" layout="vertical-dense">
                      <Checkbox label="Professional" model="data.claims.is_professional" />
                      <Checkbox label="Institutional" model="data.claims.is_institutional" />
                    </CheckboxGroup>

                    <RadioGroup
                      label="Do lab and/or medical claims go to the same address?"
                      model="data.claims.use_same_address"
                      layout="horizontal-dense"
                    >
                      <Radio label="Yes" value={true} />
                      <Radio label="No" value={false} />
                    </RadioGroup>

                    <H4>Claims Mailing Address</H4>
                    <Input label="Address Line 1" model="data.claims.mailing_address.line1" />
                    <Input label="Address Line 2" model="data.claims.mailing_address.line2" />
                    <Input label="City" model="data.claims.mailing_address.city" />
                    <Input label="County" model="data.claims.mailing_address.county" />
                    <Input label="State" model="data.claims.mailing_address.state" />
                    <StateSelect model="data.claims.mailing_address.state" />
                    <Input label="Zip Code" model="data.claims.mailing_address.zipCode" size={8} />

                    <ContextShow when="data.claims.use_same_address" is={false}>
                      <H4>Lab/Medical Claims Mailing Address</H4>
                      <Input label="Address Line 1" model="data.claims.lab_mailing_address.line1" />
                      <Input label="Address Line 2" model="data.claims.lab_mailing_address.line2" />
                      <Input label="City" model="data.claims.lab_mailing_address.city" />
                      <Input label="County" model="data.claims.lab_mailing_address.county" />
                      <StateSelect model="data.claims.lab_mailing_address.state" />
                      <Input label="Zip Code" model="data.claims.lab_mailing_address.zipCode" size={8} />
                    </ContextShow>
                  </FormSection>
                </Section>

                <Divider />

                <Section
                  title="Notes"
                  scrollview={{
                    id: 'internal_notes',
                    name: 'Notes',
                  }}
                >
                  <FormSection>
                    <Textarea useQuickText model="notes" />
                    <RatingInput glyph="dollar" fullColor={COLORS.mintGreen} label="Financial Quality Score" model="rating" />
                  </FormSection>
                </Section>

                <Divider />

                <Section
                  scrollview={{
                    id: 'summary',
                    name: 'Summary',
                  }}
                >
                  <FormSection maxWidth="100%">
                    <H3>Summary</H3>
                    <Textarea useQuickText model="data.summary.notes" />

                    {/* <CheckboxGroup label="Next Steps" layout="vertical-dense">
                        <Checkbox defaultChecked label="Admit client" model="summary.checklist.__TODO__2" />
                        <Checkbox label="BPS" model="summary.checklist.__TODO__3" />
                        <Checkbox
                          label="Financial Plan"
                          description="Confirm Insurance Benefits Usage"
                          model="summary.checklist.__TODO__4"
                        />
                        <Checkbox
                          label="Medical"
                          description="Should be done within 24h from admission"
                          model="summary.checklist.__TODO__5"
                        />
                        <Checkbox
                          label="Nursing Assessment"
                          description="Should be done within 72h from admission"
                          model="summary.checklist.__TODO__6"
                        />
                      </CheckboxGroup> */}

                    {/* <DataArray local model="summary.custom_checklist">
                        {({ ids, add, remove, isEditable }: any) => (
                          <div>
                            <Label label="Next Steps" />

                            <Grid gap="0.5rem">
                              {ids.map((id: string, index: number) => (
                                <Flex nowrap key={id} gap={8} alignItems="center">
                                  <div className="!font-[600]">{index + 1}.</div>

                                  <Input model={`${id}.task`} className="!flex-auto" />

                                  {isEditable && (
                                    <DeleteDialog
                                      title="Delete Checklist Item?"
                                      message="Are you sure you want to delete this checklist item? This action cannot be undone."
                                      onYes={() => remove(id)}
                                    >
                                      <Button hideLabel type="minimal" color="gray" glyph="delete" size={200} />
                                    </DeleteDialog>
                                  )}
                                </Flex>
                              ))}

                              {isEditable && <Button label="Add Step" glyph="add"  onClick={add} />}
                            </Grid>
                          </div>
                        )}
                      </DataArray> */}
                  </FormSection>
                </Section>

                {!$new && (
                  <>
                    <Divider />

                    <Section headingType="h2" title="Timeline">
                      <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
                    </Section>
                  </>
                )}
              </Form>
            </Overlay.Content>

            {/* <Overlay.Content>
              <VOBCalls vobID={this.state.id} client={client} />
            </Overlay.Content> */}
          </PageGrid>
        </ScrollView>

        <Overlay.Footer online={online}>
          {$editable && (
            <>
              <Button
                label="Save Changes"
                type="primary"
                glyph="check"
                color="green"
                onClick={this.save}
                isLoading={loading}
                isDisabled={loading}
                flex="100 1 auto"
                permission={isBehave ? true : $new ? 'full_vobs.create' : 'full_vobs.edit'}
              />
              <Button label="Cancel" glyph="cross" isDisabled={loading} onClick={this.cancel} />
            </>
          )}

          {!$editable && (
            <>
              <Button
                glyph="edit"
                label="Edit VOB"
                isDisabled={loading}
                onClick={this.edit}
                flex="50 1 auto"
                permission={isBehave || 'full_vobs.edit'}
              />

              {record.status !== 'signed_off' && (
                <ConfirmDialog
                  title="Sign Off & Send VOB to Facility?"
                  message="No further edits are allowed after the VOB is signed off and sent. This action cannot be undone."
                  yesLabel="Sign Off & Send VOB →"
                  onYes={this.signOff}
                  render={(openDialog) => (
                    <Button
                      glyph="check"
                      label="Sign Off"
                      color="green"
                      onClick={openDialog}
                      isDisabled={isInvalid || loading}
                      flex="50 1 auto"
                      permission={isBehave || 'full_vobs.edit'}
                    />
                  )}
                />
              )}

              <DeleteDialog
                title="Delete VOB?"
                message="Are you sure you want to delete this VOB? This action cannot be undone."
                onYes={this.delete}
              >
                <Button
                  glyph="delete"
                  label="Delete VOB…"
                  color="red"
                  permission={isBehave || 'full_vobs.delete'}
                  isDisabled={loading}
                  fullWidth
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_vobs')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(VOBOverlay))
