import React from 'react'

import { useSettings } from '../../../hooks/useSettings'
import { usDate } from '../../../utils/functions'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

export const DateCell: React.FC = (props: any) => {
  const { timezone } = useSettings()

  return <DataTableCell {...props}>{props.value ? usDate(props.value, timezone) : <EmptyCell />}</DataTableCell>
}
