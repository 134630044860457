import React from 'react'

import { titleCase } from '../../../../utils/functions'

import GlobalTags from '../../../Table/Cells/GlobalTags'
import SmartFlags from '../../../Table/Cells/SmartFlags'
import Table from '../../../Table/Table'
import TableCell from '../../../Table/TableCell'
import TableFilter from '../../../Table/TableFilter'
import TaskStatus from '../../../Statuses/TaskStatus'

import withSettings from '../../../../hocs/withSettings'

const TASK_TYPES: any = {
  one_to_one: 'One-to-One',
  group_meeting: 'Group Task',
}

const STATUSES: any = {
  active: 'Active',
  archived: 'Archived',
}

const LEVELS: any = {
  public_template: 'Public',
  behave_template: 'Behave',
}

const columns = (to, onClick, mainLinkAs) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'data.title',
    Header: 'Title',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink
        as={mainLinkAs}
        to={to?.(row.original.id)}
        onClick={() => onClick?.(row.original)}
        label={value}
        avatar={row.original.avatar}
      />
    ),
  },
  {
    width: 110,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <TableCell value={STATUSES[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'active', value: 'active' },
      { label: 'archived', value: 'archived' },
    ],
  },
  {
    width: 240,
    Header: 'Tags',
    accessor: 'global_tags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <GlobalTags
        // queryKey={queryKey} // TODO
        tags={row.original.global_tags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 240,
    Header: 'Smart Flags',
    accessor: 'smart_flags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <SmartFlags
        // queryKey={queryKey}
        value={row.original.smart_flags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 140,
    accessor: 'level',
    Header: 'Access',
    Cell: ({ value }: any) => <TableCell value={LEVELS[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'Public', value: 'public_template' },
      { label: 'Behave', value: 'behave_template' },
    ],
  },
  {
    width: 110,
    accessor: 'data.meeting_type',
    Header: 'Type',
    Cell: ({ value }: any) => <TableCell value={TASK_TYPES[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'One-to-One', value: 'one_to_one' },
      { label: 'Group Task', value: 'group_meeting' },
    ],
  },
  {
    width: 140,
    accessor: 'data.meeting_place',
    Header: 'Meeting Place',
    Cell: ({ value }: any) => <TableCell value={titleCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'None', value: 'none' },
      { label: 'Online', value: 'online' },
      { label: 'Location', value: 'property' },
      { label: 'Organization', value: 'organization' },
      { label: 'Other', value: 'other' },
    ],
  },
  {
    width: 160,
    accessor: 'data.sharing_type',
    Header: 'Permissions',
    Cell: ({ value }: any) => {
      return <TaskStatus status={value} />
    },
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'Staff Only', value: 'internal' },
      { label: 'Client Portal', value: 'client' },
    ],
  },
  {
    width: 200,
    accessor: 'data.public_description',
    Header: 'Public Description',
    Cell: ({ value }: any) => <TableCell.Truncated value={value} />,
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  timezone: string
  title: string
  to?: Function
}

const TodoBehaveTemplatesTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    localStorageKey,
    onClick,
    timezone,
    title,
    to,
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={columns(to, onClick, props.mainLinkAs)}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

TodoBehaveTemplatesTable.defaultProps = {
  title: 'To-Do Templates',
  icon: 'tasks',
  emptyDescription: 'No to-do templates to display',
  localStorageKey: 'todo_internal_templates',
}

export default withSettings(TodoBehaveTemplatesTable)
