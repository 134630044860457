import React from 'react'
import startCase from 'lodash/startCase'

import { COLORS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import AmountInput from '../../components/Forms/AmountInput'
import Button from '../../components/Button'
import Card from '../../components/Card'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import SingleAttachment from '../../components/Forms/SingleAttachment'

import OverlayLoader from '../../components/OverlayLoader'
import { PDFViewer } from './components/PDFViewer'

import { SectionCard } from '../../components/SectionCard'

const RootEOBOverlay = (props: any) => {
  const {
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'insurance_eobs',
    endpoint: '/insurance_eobs',
    invalidate: 'insurance_eobs',
    options: props,
  })

  const { timezone } = useSettings()

  const [pdfName, setPdfName] = React.useState(null)
  const [pdfBlob, setPdfBlob] = React.useState(null)
  const [pdfUrl, setPdfUrl] = React.useState(null)

  const [pdfVisible, setPdfVisible] = React.useState(false)

  React.useEffect(() => {
    if (!!pdfBlob || !!pdfUrl) setPdfVisible(true)
  }, [pdfBlob, pdfUrl])

  if (isOverlayLoading) return <OverlayLoader position="right" maxWidth={100} />

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} maxWidth={100}>
      <Overlay.Header icon="accounting" title="EOB" />

      {!isNew && initialModel?.insurance_new_payment && (
        <Overlay.SubHeader>
          <Flex centerY gap={8} justifyContent="space-between">
            <Button
              label={`Payment: ${initialModel?.insurance_new_payment?.identifier}`}
              link={`/posting/payments/${initialModel?.insurance_new_payment?.id}`}
              size={200}
              type="minimal"
              color="blue"
            />
          </Flex>
        </Overlay.SubHeader>
      )}

      <Overlay.Content
        css={{
          display: 'grid',
          height: '100%',
          gridTemplateColumns: pdfVisible ? '500px 1fr' : '1fr',
          gridTemplateRows: '100%',
          overflow: 'hidden',
        }}
      >
        <div className="p-4 border-r border-0 border-solid border-divider">
          <Form
            isCompact
            getForm={form}
            timezone={timezone}
            isEditable={isEditable}
            initialModel={initialModel}
            onValidationUpdate={onValidationUpdate}
            className="overflow-y-auto"
          >
            <Grid gap="0.75rem">
              {isNew && (
                <Alert glyph="warning" type="default">
                  Upload your EOB here to be able to connect it to an Insurance Payment
                </Alert>
              )}

              <SectionCard isOpen title={isNew ? 'Upload EOB' : 'Uploaded EOB'}>
                <FormSection maxWidth="100%">
                  <SingleAttachment
                    model="upload"
                    label={null}
                    labelAlign="top"
                    labelJustify="top"
                    onUpload={(state: any) => {
                      setPdfBlob(state.value)
                      setPdfName(state.name)
                    }}
                  />
                </FormSection>
              </SectionCard>

              {!isNew && (
                <>
                  <SectionCard isOpen title="EOB">
                    <FormSection maxWidth="100%" layout="vertical">
                      <Input isEditable={false} label="ID" model="identifier" />

                      <ObjectSelector
                        isEditable={false}
                        label="Payer"
                        model="insurance_new_payment.insurance_local_payer"
                        blankLabel="Select Payer…"
                        type="insurance_local_payers"
                        icon="insurance"
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => data?.notes}
                      />

                      <Flex horizontal gap="1rem">
                        <DateInput isEditable={false} label="Payment Date" model="insurance_new_payment.payed_at" />
                        <DateInput isEditable={false} label="Posted Date" model="insurance_new_payment.posted_at" />
                      </Flex>

                      <Input isEditable={false} label="Payment Reference" model="insurance_new_payment.reference" />

                      {/* <MultiOverlaySelector isEditable={false} label="Clients" type="clients.current" icon="clients" model="residents" /> */}
                    </FormSection>
                  </SectionCard>

                  <SectionCard isOpen title="Amounts">
                    <FormSection maxWidth="100%" layout="vertical">
                      <Grid columns="1fr 1fr" gap="1rem">
                        <AmountInput isEditable={false} label="Total Adjustment" model="insurance_new_payment.adjustment_amount" />
                        <AmountInput
                          isEditable={false}
                          label="Total Balance Due Client"
                          model="insurance_new_payment.balance_due_patient_amount"
                        />
                        <AmountInput
                          isEditable={false}
                          label="Total Client Responsibility"
                          model="insurance_new_payment.patient_responsibility_amount"
                        />
                        <AmountInput isEditable={false} label="Total Paid Amount" model="insurance_new_payment.paid_amount" />
                      </Grid>
                    </FormSection>
                  </SectionCard>

                  <SectionCard isOpen title="Info">
                    <FormSection maxWidth="100%">
                      <DateTimeInput isEditable={false} label="Uploaded Date" model="uploaded_at" />
                      <OverlaySelector
                        isEditable={false}
                        label="Uploaded By"
                        model="uploaded_by"
                        type="employees.active"
                        icon="employees"
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => startCase(data.position)}
                      />
                    </FormSection>
                  </SectionCard>
                </>
              )}
            </Grid>
          </Form>
        </div>

        {pdfVisible && (
          <div className={'grid grid-rows-[100%] p-4'}>
            <Card>
              <PDFViewer name={pdfName} blob={pdfBlob} />
            </Card>
          </div>
        )}
      </Overlay.Content>

      <Overlay.Footer>
        {isNew && isEditable && (
          <>
            <Button
              label="Upload File"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={!pdfBlob}
              flex="100 1 auto"
              permission="insurance_posting.create"
            />

            <Button label="Cancel" glyph="cross" type="default" onClick={close} />
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="insurance_posting.edit"
            />

            <DeleteDialog
              title="Delete EOB?"
              message="Are you sure you want to delete this EOB? This action cannot be undone."
              onYes={deleteRecord}
              permission="insurance_posting.delete"
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="insurance_posting.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const EOBOverlay = withOverlayError(RootEOBOverlay)
