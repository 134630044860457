import React from 'react'

import { COLORS, SHADOW } from '@behavehealth/theme'
import { Page, HelpTagIframe, Tabs } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { TenantNotionPage } from '@behavehealth/constructs/TenantNotionPage'
import useStore from '@behavehealth/modules/store'

const Bridge = () => {
  const isBehave = useStore((state) => state?.isBehave)

  return (
    <Page breakpoint="0" feature="bridge" help={<HelpTagIframe id="bridge" />}>
      <Tabs defaultTab="bridge">
        <Tabs.List css={styles.tabsList}>
          <Tabs.Item label="Behave Bridge" icon="bridge" name="bridge" />
          {isBehave && <Tabs.Item label="Treatment Center" icon="care_level_5" name="treatment_center" />}
          {isBehave && <Tabs.Item label="Healthcare Practice" icon="care_level_6" name="healthcare_practice" />}
          {isBehave && <Tabs.Item label="Sober Living Homes" icon="community" name="sober_living_homes" />}
          {isBehave && <Tabs.Item label="Partner" icon="employees" name="partner" />}
          {isBehave && <Tabs.Item label="Behave Health Software" icon="default" name="software" />}
        </Tabs.List>

        <Tabs.Panels>
          <Tabs.Panel name="treatment_center" type="mount">
            <TenantNotionPage tag="bridge_treatment_center" css={styles.notionPage} />
          </Tabs.Panel>
          <Tabs.Panel name="healthcare_practice" type="mount">
            <TenantNotionPage tag="bridge_healthcare_practice" css={styles.notionPage} />
          </Tabs.Panel>
          <Tabs.Panel name="sober_living_homes" type="mount">
            <TenantNotionPage tag="bridge_sober_living_home" css={styles.notionPage} />
          </Tabs.Panel>
          <Tabs.Panel name="partner" type="mount">
            <TenantNotionPage tag="bridge_partner" css={styles.notionPage} />
          </Tabs.Panel>
          <Tabs.Panel name="bridge" type="mount">
            <TenantNotionPage tag="bridge_profile" css={styles.notionPage} />
          </Tabs.Panel>
          <Tabs.Panel name="software" type="mount">
            <TenantNotionPage tag="bridge_software" css={styles.notionPage} />
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </Page>
  )
}

const styles = {
  tabsList: {
    marginTop: '-1rem',
  },

  notionPage: {
    marginTop: '1rem',

    '.notion-page': {
      marginTop: '1rem',
      borderRadius: 7,
      boxShadow: SHADOW(2),
      background: COLORS.white,
    },
  },
}

export default withPageError(Bridge)
