import React from 'react'
import { connect } from 'react-redux'
import dompurify from 'dompurify'

import Alert from '../../Alert'
import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import DateInput from '../../Forms/DateInput'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Select from '../../Forms/Select'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'
import SingleSearchSelector from '../../Forms/Selectors/SingleSearchObject/SingleSearchSelector'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import withRouter from '../../../hocs/withRouter'
import { withOverlayError } from '../../../hocs/withOverlayError'

const sanitizer = dompurify.sanitize

class DiagnosisOverlay extends OverlayBase {
	renderHeader = () => {
		const { $new } = this.state

		return <Overlay.Header icon="diagnosis" title={$new ? 'Add Diagnosis' : 'Diagnosis'} />
	}

	renderContent = () => {
		const { $editable, $new } = this.state
		const { reference, initialData, timezone, loading } = this.props

		let record = Object.keys(this.props.record).length !== 0 ? this.props.record : initialData

		const isPortal = this.props.location?.source === 'portal'

		return (
			<Overlay.Content>
				<Form
					getForm={this.form}
					timezone={timezone}
					initialModel={record}
					isEditable={$editable}
					onValidationUpdate={this.onValidationUpdate}
					linked={$new && { client_id: reference?.id }}
				>
					<Section>
						<FormSection layout="vertical">
							<>
								{$new && (
									<>
										<Alert glyph="info">
											Try searching for the code or for a keyword in the code description. For example "Opioid
											Dependence".
										</Alert>

										<SingleSearchSelector
											isRelation={false}
											showAvatars={false}
											label="Search ICD-10 Codes"
											model="icd10"
											type="medical_codes.icd10_cm"
											selectTitle={(data, highlights) => {
												let title = data?.code
												if (!highlights) return title

												for (let i = 0; i < highlights?.length; i++) {
													if (highlights[i].field === 'code') {
														title = highlights[i].snippet
														break
													}
												}

												return <span dangerouslySetInnerHTML={{ __html: sanitizer(title) }} />
											}}
											selectDescription={(data, highlights) => {
												let description = data?.description
												if (!highlights) return description

												for (let i = 0; i < highlights?.length; i++) {
													if (highlights[i].field === 'description') {
														description = highlights[i].snippet
														break
													}
												}

												return <span dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
											}}
											onUpdate={(model) => this.setState({ selectedCode: model.value })}
										/>
									</>
								)}

								<Input
									label="Code"
									model="code"
									validateOn="blur-change-update"
									value={this.state.selectedCode?.code}
									validations={{
										presence: {
											message: 'Please add a code'
										}
									}}
								/>

								<Textarea label="Description" model="description" value={this.state.selectedCode?.description} />

								<Divider />
							</>

							<Select model="status" label="Status" defaultValue="active">
								<Option label="Active" value="active" />
								<Option label="To Review" value="to_review" />
								<Option label="Inactive" value="inactive" />
								<Option label="Resolved" value="resolved" />
							</Select>

							<Select model="category" label="Type" defaultValue="primary">
								<Option label="Admitting" value="admitting" />
								<Option label="Primary" value="primary" />
								<Option label="Principal" value="principal" />
								<Option label="Secondary" value="secondary" />
							</Select>

							<Flex stretchChildrenX gap="1rem">
								<DateInput
									defaultToNow
									label="From Date"
									model="from"
									validations={{
										presence: {
											message: 'Please enter a date'
										}
									}}
								/>
								<DateInput label="Until Date" model="until" />
							</Flex>

							<Textarea useQuickText label="Notes" model="notes" />
						</FormSection>
					</Section>

					<Divider />

					<Section headingType="h2" title="Attachments" description="Upload the files related to this diagnosis">
						<FormSection layout="vertical">
							<Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
						</FormSection>
					</Section>

					{!$new && (
						<>
							<Divider />

							<Section headingType="h2" title="Timeline">
								<Timeline
									isLoadingRecord={loading}
									recordID={record.id}
									recordType={record.type}
									disableLinks={isPortal}
								/>
							</Section>
						</>
					)}
				</Form>
			</Overlay.Content>
		)
	}

	renderFooter = () => {
		const { $new, $editable, isInvalid } = this.state

		return (
			<Overlay.Footer>
				{$editable && (
					<>
						<Button
							label="Save"
							glyph="check"
							type="primary"
							color="green"
							isLoading={this.props.loading}
							onClick={this.save}
							isDisabled={isInvalid}
							flex="100 1 auto"
							permission="diagnoses.create"
						/>
						{!$new && (
							<Button
								label="Cancel"
								glyph="cross"
								type="default"
								isDisabled={this.props.loading}
								onClick={this.cancel}
							/>
						)}
					</>
				)}

				{!$editable && (
					<>
						<Button
							glyph="edit"
							label="Edit Diagnosis"
							type="default"
							isDisabled={this.props.loading}
							onClick={this.edit}
							flex="100 1 auto"
							permission="diagnoses.edit"
						/>

						<DeleteDialog
							title="Delete Diagnosis?"
							message="Are you sure you want to delete this diagnosis? This action cannot be undone."
							onYes={this.delete}
						>
							<Button
								fullWidth
								glyph="delete"
								label="Delete"
								type="default"
								color="red"
								isLoading={this.props.loading}
								permission="diagnoses.delete"
							/>
						</DeleteDialog>
					</>
				)}
			</Overlay.Footer>
		)
	}
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'diagnoses')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'diagnoses')

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withOverlayError(DiagnosisOverlay)))
