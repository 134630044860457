import React from 'react'

import { titleCase } from '../../../../utils/functions'

import GlobalTags from '../../../Table/Cells/GlobalTags'
import Table from '../../../Table/Table'
import TableCell from '../../../Table/TableCell'

import withSettings from '../../../../hocs/withSettings'

const CODE_TYPES: any = {
  cpt: 'CPT',
  hcpc_mod: 'HCPC/MOD',
  hcpcs: 'HCPCS',
}

const columns = (to: any, mainLinkAs) => [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Name',
    width: 240,
    Cell: ({ value, row }: any) => <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} label={value} />,
  },
  {
    width: 240,
    Header: 'Tags',
    accessor: 'global_tags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <GlobalTags
        // queryKey={queryKey} // TODO
        tags={row.original.global_tags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 160,
    accessor: 'data.code',
    Header: 'Procedure Code',
  },
  {
    width: 160,
    accessor: 'data.code_type',
    Header: 'Code Type',
    Cell: ({ value }: any) => <TableCell value={CODE_TYPES[value] || '–'} />,
  },
  {
    width: 160,
    accessor: 'data.revenue_code',
    Header: 'Revenue Code',
  },
  {
    width: 160,
    accessor: 'data.price',
    Header: 'Suggested Price',
    Cell: ({ value }: any) => <TableCell.Amount value={value} />,
  },
  {
    width: 160,
    accessor: 'data.unit',
    Header: 'Unit',
    Cell: ({ value }: any) => <TableCell value={titleCase(value)} />,
  },
  {
    width: 240,
    accessor: 'data.description',
    Header: 'Description',
  },
  {
    width: 240,
    accessor: 'data.notes',
    Header: 'Notes',
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  isLoading: boolean
  localStorageKey: string
  title?: string
  titleAfter?: React.ReactNode
  to?: Function
}

const InsuranceCodesTemplatesTable = (props: Props) => {
  const { batchActionsConfig, data, isLoading, localStorageKey, title, titleAfter, to, ...rest } = props

  return (
    <Table
      data={data}
      columns={columns(to, props.mainLinkAs)}
      title={title}
      titleAfter={titleAfter}
      icon="insurance"
      isLoading={isLoading}
      emptyDescription="No insurance codes added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

InsuranceCodesTemplatesTable.defaultProps = {
  title: 'Insurance Codes',
  localStorageKey: 'insurance_codes_templates',
}

export default withSettings(InsuranceCodesTemplatesTable)
