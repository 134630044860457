import React from 'react'
import clsx from 'clsx'

import GridItem from './GridItem'

const Grid = (props) => {
  const {
    as,
    children,
    className,
    horizontal,

    centerX,
    centerY,

    columnCount,
    columnMinWidth,
    columnMaxWidth,

    fixChildrenX,
    fixChildrenY,

    stretchChildrenX,
    stretchChildrenY,

    gap,
    alignItems,
    justifyContent,
    justifyItems,

    flex,
    areas,
    autoColumns,
    autoFlow,
    autoRows,
    columns,
    rows,
    style,

    testKey,
  } = props

  const Tag = as

  const classNames = clsx({
    'is-horizontal': horizontal,
    'is-vertical': !horizontal,
    // 'no-areas': !areas,

    'center-x': centerX,
    'center-y': centerY,

    'fix-children-x': fixChildrenX,
    'fix-children-y': fixChildrenY,

    'stretch-children-x': stretchChildrenX,
    'stretch-children-y': stretchChildrenY,

    [className]: className,
  })

  const dynamicStyles = {
    gridGap: gap,
    flex,

    gridAutoFlow: autoFlow,
    gridAutoRows: autoRows,
    gridAutoColumns: autoColumns,

    alignItems: alignItems,
    justifyContent: justifyContent,
    justifyItems: justifyItems,

    ...(areas && {
      gridTemplateAreas: areas,
    }),

    ...(rows && {
      gridTemplateRows: rows,
    }),

    ...(columns && {
      gridTemplateColumns: columns,
    }),

    ...(columnMinWidth && {
      gridTemplateColumns: `repeat(auto-fit, minmax(${columnMinWidth}, ${columnMaxWidth || '1fr'}))`,
    }),

    ...(columnCount && {
      gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
    }),
  }

  return <Tag data-test={testKey} className={classNames} children={children} style={style} css={[styles, dynamicStyles]} />
}

const styles = {
  display: 'grid',
  alignItems: 'start',

  '&.center-x': { justifyContent: 'center', justifyItems: 'center' },
  '&.center-y': { alignContent: 'center' },

  // VERTICAL (default)
  '&.is-vertical': {
    '&.fix-children-x': { justifyContent: 'flex-start' },
    '&.fix-children-y': { gridAutoRows: 'min-content' },

    '&.stretch-children-x': { justifyItems: 'stretch' },
    '&.stretch-children-y': { gridAutoRows: '1fr' },
  },

  // HORIZONTAL
  '&.is-horizontal': {
    gridAutoFlow: 'column',

    '&.fix-children-x': { justifyContent: 'flex-start' },
    '&.fix-children-y': { alignContent: 'flex-start' },

    '&.stretch-children-x': { gridAutoColumns: '1fr' },
    '&.stretch-children-y': { alignItems: 'stretch' },
  },
}

Grid.Item = GridItem
Grid.Item.displayName = 'GridItem'

Grid.defaultProps = {
  as: 'div',
}

export default Grid
