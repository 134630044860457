import React from 'react'
import { DateTime } from 'luxon'

import { FILTERS } from '../Filters/config'
import { titleCase } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import EmployeeStatus from '../../components/Statuses/EmployeeStatus'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

import { useCommunityEditor } from './useCommunityEditor'

const RootCommunityStaffEditorOverlay = (props: any) => {
  const { entityId } = props

  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  const { isNew, close, saveWithData, form, data, onValidationUpdate, isOverlayLoading, initialModel, isInvalid, isSaving } = useOverlay({
    name: 'community-employee',
    endpoint: `/community/entity_staff`,
    invalidate: 'community-employees',
    invalidateKeys: ['community-entity', 'community-employees'],
    isEditable: true,
    options: props,
    closeOnSave: true,
    onSaveSuccessful: (newData: any) => {
      setUpdatedAt(DateTime.now().toMillis())
    },
  })

  const { timezone, isEHRApp } = useSettings()

  const [staff, setStaff] = React.useState<any>(null)

  const [avatar, setAvatar] = React.useState(initialModel?.avatar || '')
  const [name, setName] = React.useState(initialModel?.name || '')
  const [position, setPosition] = React.useState(initialModel?.position || '')
  const [email, setEmail] = React.useState(initialModel?.email || '')
  const [phoneNo, setPhoneNo] = React.useState(initialModel?.phone_no || '')
  const [bio, setBio] = React.useState(initialModel?.description || '')

  const [avatarData, setAvatarData] = React.useState<any>(null)
  const [avatarName, setAvatarName] = React.useState<any>(null)

  const handleSave = () => {
    saveWithData({
      // avatar,
      ...form.current.getFormValue(),
      name,
      position,
      email,
      phone_no: phoneNo,
      description: bio,
      // entity_id: entityId,
      ...(isNew && { entity_id: entityId }),
      ...(avatarData &&
        avatarName && {
          avatar_data: avatarData,
          avatar_name: avatarName,
        }),
    })
  }

  if (isOverlayLoading) return <OverlayLoader position="center" maxWidth={40} />

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" maxWidth={40} onClose={close}>
      <Overlay.Header title="Community Staff Profile" icon="employees" />

      <Overlay.Content>
        <Section>
          <Form
            // isCompact
            isEditable
            key={staff?.id}
            getForm={form}
            initialModel={initialModel}
            timezone={timezone}
            onValidationUpdate={onValidationUpdate}
            className="grid gap-4"
          >
            <FormSection maxWidth="100%">
              {isEHRApp && (
                <SummonOverlay
                  overlay={
                    <StaffSelectorOverlay
                      onSelect={(data: any) => {
                        setStaff(data)
                        setAvatar(data.avatar)
                        setName(data.name)
                        setPosition(titleCase(data.position))
                        setEmail(data.email)
                        setPhoneNo(data.phone_no)
                      }}
                    />
                  }
                >
                  <div>
                    <Button label="Copy from Staff…" icon="employees" size={300} type="default" display="inline-flex" />
                  </div>
                </SummonOverlay>
              )}

              {isNew && (
                <Alert contrast small glyph="info">
                  <span className="font-[600]">Note: </span> you can upload an avatar for this staff member after saving
                </Alert>
              )}

              {!isNew && (
                <div className="flex justify-center">
                  <Avatar
                    isEditable
                    src={avatarData || avatar}
                    width={80}
                    height={100}
                    recordId={data?.id}
                    // updateURL={`/community/entity_staff/${data?.id}`}
                    onUpload={(params) => {
                      setAvatarData(params.avatar_data)
                      setAvatarName(params.avatar_name)
                    }}
                  />
                </div>
              )}

              <Input
                label="Name"
                value={name}
                onUpdate={({ value }) => {
                  setName(value)
                }}
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <MultiObjectSelector
                label="Locations"
                model="locations"
                icon="locations"
                type="community_profile.locations"
                dependentValue={entityId}
                selectTitle={(data: any) => data.name}
              />

              <Input
                label="Position"
                value={position}
                onUpdate={({ value }) => {
                  setPosition(value)
                }}
              />

              <Input
                label="Email"
                value={email}
                onUpdate={({ value }) => {
                  setEmail(value)
                }}
              />

              <PhoneInput
                label="Phone Number"
                value={phoneNo}
                onUpdate={({ value }) => {
                  setPhoneNo(value)
                }}
              />

              <Textarea
                label="Bio"
                value={bio}
                onUpdate={({ value }) => {
                  setBio(value)
                }}
              />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save Staff Profile"
          glyph="tick_circle"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={isSaving}
          isDisabled={!name}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const StaffSelectorOverlay = (props: any) => {
  const { onClose, onSelect } = props

  const tableProps = useDataTable({
    name: ['staff-selector-overlay'],
    endpoint: '/employees',
    params: {
      status: 'active',
    },
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            onClick={
              onSelect
                ? () => {
                    onSelect(data)
                    onClose?.()
                  }
                : undefined
            }
          />
        ),
      },
      {
        width: 160,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ data, value }: any) => <EmployeeStatus status={value} />,
      },
      {
        width: 160,
        id: 'position',
        model: 'position',
        title: 'Position',
        formatValue: ({ data, value }: any) => titleCase(value),
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'phone_no',
        title: 'Phone',
      },
      {
        width: 160,
        id: 'email',
        model: 'email',
        title: 'Email',
      },
    ],
    [],
  )

  return (
    <Overlay showBackdrop closeOnBackdrop maxWidth={80} position="center" onClose={onClose}>
      <Overlay.Header title="Select Staff" icon="employees" />

      <Overlay.Content>
        <DataTable {...tableProps} title="Staff Members" icon="employees" columns={columns} filtersConfig={FILTERS.employees} />
      </Overlay.Content>
    </Overlay>
  )
}

export const CommunityStaffEditorOverlay = withOverlayError(RootCommunityStaffEditorOverlay)
