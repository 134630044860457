import React from 'react'

import { titleCase } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row, ...rest }) => {
      return <TableCell.MainLink label={value} />
    },
  },
  {
    accessor: 'type',
    Header: 'Category',
    Cell: ({ value }) => <TableCell.MainLink icon={value} label={titleCase(value)} />,
  },
  {
    width: 220,
    accessor: 'created_at',
    Header: 'Created At',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 220,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
]

const RosterDocumentationTable = ({ data, to, isLoading, timezone, localStorageKey, batchActionsConfig, titleAfter }: any) => {
  return (
    <Table
      data={data}
      columns={columns(to, timezone)}
      title="Roster Documentation"
      titleAfter={titleAfter}
      icon="rosters"
      groupBy="type"
      isLoading={isLoading}
      emptyDescription="No documentation has been added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      showSettings={false}
    />
  )
}

RosterDocumentationTable.defaultProps = {
  localStorageKey: 'billing_roster_documentation',
}

export default withSettings(RosterDocumentationTable)
