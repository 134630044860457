import React from 'react'
import pluralize from 'pluralize'
import size from 'lodash/size'

import { apiGet } from '../../modules/api'
import { encodeObjectToURL } from '../../utils/functions'

import Button from '../Button'
import State from '../State'
import SummonOverlay from '../SummonOverlay'
import TimelineItem from './TimelineItem'

import TimelineOverlay from './TimelineOverlay'

type Props = {
  asAuthor?: boolean
  asRecipient?: boolean
  disableLinks?: boolean
  isLoadingRecord?: boolean
  recordID: string
  recordType: string
  url?: string
}

const Timeline = (props: Props) => {
  const { asAuthor, asRecipient, disableLinks, isLoadingRecord, recordType, recordID, url } = props

  const [events, setEvents] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const eventsCount = size(events)
  const isEmpty = eventsCount === 0
  const showMore = eventsCount >= 10

  React.useEffect(() => {
    if (isLoadingRecord) return

    const getData = async () => {
      try {
        setLoading(true)

        const params = {
          category: 'recent',
          as_author: asAuthor,
          as_recipient: asRecipient,
          desc: true,
          items: 10,
          page: 1,
        }

        const finalURL = url ? url : `${pluralize(recordType)}/${recordID}/timeline`
        const result = await apiGet({ url: `/${finalURL}?${encodeObjectToURL(params)}` })

        setEvents(result?.data?.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [isLoadingRecord, recordType, recordID])

  if (loading || isEmpty) {
    return <State isLoading={loading} isEmpty={isEmpty} icon="lists" emptyDescription="No timeline events to display yet" minHeight={180} />
  }

  return (
    <>
      {events.map((event: any) => (
        <TimelineItem key={event.id} event={event} disableLinks={disableLinks} />
      ))}

      {showMore && (
        <SummonOverlay gap="0.75rem" overlay={<TimelineOverlay {...props} />}>
          <Button label="Show All" size={200} css={{ display: 'inline-flex' }} />
        </SummonOverlay>
      )}
    </>
  )
}

export default Timeline
