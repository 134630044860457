import React from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'

import { age, usDate } from '../../../utils/functions'
import { COLORS } from '../../../theme'
import { DataTable } from '../../../components/DataTable/DataTable'
import { usDateTime } from '../../../utils/functions'
import { useGet } from '../../../hooks/useNewAPI'
import { useSettings } from '../../../hooks/useSettings'
import { withOverlayError } from '../../../hocs/withOverlayError'

import Accordions from '../../../components/Accordions'
import Section from '../../../components/Section'
import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog'
import DataList from '../../../components/DataList'
import Divider from '../../../components/Divider'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import NavGroup from '../../../components/NavGroup'
import NavItem from '../../../components/NavItem'
import Overlay from '../../../components/Overlay'

export const PartnerServicesOverlay = (props: any) => {
  const { name, onClose } = props

  return (
    <Overlay position="right" onClose={onClose}>
      <Overlay.Header icon="organizations" title={`${name} Services`} />

      <Overlay.Content>
        <Section>
          <Accordions>
            <Accordions.Item minimal isOpen size={200} title="Insurance Payers" icon="bank_building">
              <DataTable data={[]} columns={[]} title="Insurance Payers" icon="bank_building" />
            </Accordions.Item>

            <Accordions.Item minimal isOpen size={200} title="Healthcare Services" icon="healthcare_services">
              <DataTable data={[]} columns={[]} title="Healthcare Services" icon="healthcare_services" />
            </Accordions.Item>

            <Accordions.Item minimal isOpen size={200} title="Non-Healthcare Services" icon="non_healthcare_services">
              <DataTable data={[]} columns={[]} title="Non-Healthcare Services" icon="non_healthcare_services" />
            </Accordions.Item>
          </Accordions>
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}
