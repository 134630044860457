import React from 'react'
import { v4 as uuid } from 'uuid'
import size from 'lodash/size'
import produce from 'immer'

import { withFormContext } from './context'

import Grid from '../Grid'
import Button from '../Button'

import withSettings from '../../hocs/withSettings'
import FieldBase from './FieldBase'
import AddressSelectorInput from './AddressSelectorInput'
import { COLORS } from '../../theme'

class AddressSelectorArray extends FieldBase {
  constructor(props) {
    super(props)

    let value = props.value
    if (!value) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

      if (initialModelVal) value = initialModelVal
      if (size(modelVal) > 0) value = modelVal
    }

    this.state = {
      type: 'ADDRESS_SELECTOR_ARRAY',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value || [],
      isValid: true,
      isInvalid: false,
      errors: [],
      validate: this.onValidate,
      reset: this.onReset,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value,
      isValid: true,
      isInvalid: false,
    }
    this.updateType = 'DATA'
  }

  addAddress = () => {
    const newValue = produce(this.state.value, (draft) => {
      draft.push({
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip_code: '',
        country: 'USA',
        coords: {
          lat: null,
          lon: null,
        },
      })
    })

    this.changeValue(newValue)
  }

  updateAddress = (idx: number, address: any) => {
    const newValue = produce(this.state.value, (draft: any) => {
      draft[idx] = Object.assign(draft[idx], address)
    })

    this.changeValue(newValue)
  }

  removeAddress = (idx: number) => {
    const newValue = produce(this.state.value, (draft: any) => {
      draft.splice(idx, 1)
    })

    this.changeValue(newValue)
  }

  render = () => {
    const { isEditable } = this.props

    return (
      <Grid gap="1rem">
        {this.state.value.map((address: string, idx: number) => (
          <div key={address.id} paddingX="2rem" css={styles}>
            <AddressSelectorInput
              value={address}
              css={{ padding: '0.75rem 1rem 1rem 1rem' }}
              afterAddressSelect={(address: any) => this.updateAddress(idx, address)}
            />
            {isEditable && (
              <Button
                label="Remove Address"
                type="link"
                color="red"
                glyph="delete"
                size={300}
                onClick={() => this.removeAddress(address.id)}
              />
            )}
          </div>
        ))}

        {isEditable && <Button label="Add New Address" glyph="add" type="link" size="small" onClick={this.addAddress} />}
      </Grid>
    )
  }
}

const styles = {
  border: `2px solid ${COLORS.divider}`,
  borderRadius: 7,
  padding: '0.75rem',
}

AddressSelectorArray.defaultProps = {
  isEditable: true,
}

export default withFormContext(withSettings(AddressSelectorArray))
