import React from 'react'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import size from 'lodash/size'

import { INPUT_STYLES } from '../../../theme'
import Glyph from '../../Glyph'

import { useStore } from '../useStore'

export const StringFilter = (props: any) => {
  const { filter, onUpdate, filterKey, autoFocus } = props

  const ref = React.useRef(null)
  const [value, setValue] = React.useState(filter?.value || '')

  const removeFilter: any = useStore((state: any) => state.removeFilter)

  React.useEffect(() => {
    setValue(filter?.value || '')
  }, [filter?.value])

  const debouncedOnUpdate = React.useMemo(() => {
    return debounce((value) => {
      onUpdate?.(value)
    }, 300)
  }, [])

  const handleUpdate = (event: any) => {
    const newValue = event.target.value

    setValue(newValue)

    if (size(newValue) === 0) {
      removeFilter(filterKey)
    } else {
      debouncedOnUpdate({ value: newValue, condition: 'contains' })
    }
  }

  const handleClear = () => {
    setValue('')
    removeFilter(filterKey)
  }

  React.useEffect(() => {
    if (ref?.current && autoFocus) ref.current.focus()
  }, [autoFocus, ref?.current])

  return (
    <div css={STYLES.root}>
      <input
        data-test="string_filter_input"
        ref={ref}
        type="text"
        css={STYLES.input}
        value={value}
        onChange={handleUpdate}
        autoFocus={autoFocus}
      />
      {value && (
        <button type="button" css={STYLES.clearButton} onClick={handleClear}>
          <Glyph glyph="close" size={12} />
        </button>
      )}
    </div>
  )
}

const STYLES = {
  root: {
    position: 'relative',
  },

  input: {
    ...INPUT_STYLES,
    outline: 'none',
    minHeight: '1.65rem',
    height: '1.75rem',
    padding: '0 0.5rem',
    fontSize: '0.95rem',
  },

  segment: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.25rem',
  },

  saveButton: {
    height: '20px !important',
    minHeight: '20px !important',
    fontSize: '0.82rem',

    svg: {
      width: 12,
      height: 12,
      margin: '0 !important',
    },
  },

  clearButton: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '2rem',

    background: 'none',
    border: 'none',
    borderRadius: 4,
    outline: 'none',
    height: 24,
    opacity: 0.8,

    svg: {
      transition: 'transform 80ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    },

    '&:hover': {
      svg: {
        // fill: COLORS.text,
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
    },
  },
}
