import React from 'react'

export default class DOMNodes extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      nodes: 0,
    }

    this.interval = null
  }

  shouldComponentUpdate = (_nextProps, nextState) => this.state.nodes !== nextState.nodes

  componentDidMount = () => {
    this.updateNodes()

    this.interval = setInterval(() => {
      this.updateNodes()
    }, 1000)
  }

  componentWillUnmount = () => {
    if (this.interval) clearInterval(this.interval)
  }

  calcNodes = () => {
    let nodes = 0

    let appElement = document.getElementsByClassName('APP')[0]
    let notificationsElement = document.getElementsByClassName('NotificationsContainer')[0]
    let portalsElement = document.getElementsByClassName('portals')[0]
    let overlaysElement = document.getElementsByClassName('overlays')[0]

    if (appElement) nodes += appElement.getElementsByTagName('*').length
    if (notificationsElement) nodes += notificationsElement.getElementsByTagName('*').length
    if (portalsElement) nodes += portalsElement.getElementsByTagName('*').length
    if (overlaysElement) nodes += overlaysElement.getElementsByTagName('*').length

    return nodes
  }

  updateNodes = () => this.setState({ nodes: this.calcNodes() })

  render = () => {
    return (
      <div className="DOMNodes">
        <div className="Meter">{this.state.nodes} nodes</div>
      </div>
    )
  }
}
