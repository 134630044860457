import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import useStore from '@behavehealth/modules/store'
import withSettings from '@behavehealth/hocs/withSettings'

import { get } from '@behavehealth/modules/api/requests'
import { Card, PageHeader, Tabs, Tab, TabList, TabPanels, TabPanel, Flex, Loader } from '@behavehealth/components'

const ConsolePanel = ({ activeTab }: any) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [iframe, setIframe] = React.useState(null)

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      try {
        const results = await get(`/apps/vendors/dashboard?dash=${activeTab}`)
        setIframe(results.data?.url)

        setIsLoading(false)
      } catch (error) {
        console.error(error)

        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  if (isLoading)
    return (
      <Flex centerX>
        <Loader />
      </Flex>
    )

  return (
    <Card className="min-h-[70vh] mq600:min-h-auto">
      <iframe
        title="iframe"
        src={iframe}
        frameBorder="0"
        border="0"
        cellSpacing="0"
        className="border-none w-full h-full"
        allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
      />
    </Card>
  )
}

const Console = () => {
  const user = useStore((state) => state.user)
  const tenant = useStore((state) => state.tenant)

  return (
    <div className="grid grid-rows-[auto_1fr] overflow-hidden">
      <PageHeader icon="optimization" title="Console" />

      <Tabs defaultTab="9cAiz3PX" className="pt-1 px-4 pb-4 grid grid-rows-[auto_1fr] overflow-hidden">
        <TabList className="mb-4">
          <Tab label="Dashboard 1" name="9cAiz3PX" />
          <Tab label="Dashboard 2" name="FnTREcb4" />
          <Tab label="Dashboard 3" name="7rLh2fJ7" />
        </TabList>

        <>
          <TabPanel name="9cAiz3PX" type="mount" className="[&.is-active]:!grid grid-rows-[100%] grid-cols-[100%]">
            <ConsolePanel activeTab="9cAiz3PX" />
          </TabPanel>

          <TabPanel name="FnTREcb4" type="mount" className="[&.is-active]:!grid grid-rows-[100%] grid-cols-[100%]">
            <ConsolePanel activeTab="FnTREcb4" />
          </TabPanel>

          <TabPanel name="7rLh2fJ7" type="mount" className="[&.is-active]:!grid grid-rows-[100%] grid-cols-[100%]">
            <ConsolePanel activeTab="7rLh2fJ7" />
          </TabPanel>
        </>
      </Tabs>
    </div>
  )
}

export default withPageError(withSettings(Console))
