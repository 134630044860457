import React from 'react'
import clsx from 'clsx'
import { keyframes } from '@emotion/react'

import { COLORS } from '../../theme'
import { Text } from '../Typography'
import Errors from './Errors'
import GroupLayout from '../GroupLayout'
import Label from '../Label'

import { VendorQuickLinks } from '../../constructs/VendorQuickLinks/VendorQuickLinks'

export default class Field extends React.Component {
  formGroupRef = React.createRef()

  componentDidMount = () => {
    if (this.props.getRef) this.props.getRef(this.formGroupRef)
  }

  render() {
    const {
      children,
      className,
      description,
      errors,
      flex,
      grow,
      horizontal,
      icon,
      id,
      isDisabled,
      isLocked,
      isEditable,
      isRequired,
      isValid,
      isHighlighted,
      label,
      labelAfter,
      labelAlign,
      labelDescription,
      labelJustify,
      labelWidth,
      largeLabel,
      layout,
      maxWidth,
      showLabel,
      smartDescription,
      smartValue,
      tooltip,
      withHover,
      testKey,
      hideAsterisk,
      isCompact,
      tooltipTrigger,
      alwaysShowDescription,
      labelClassName,
      labelTruncate,
      quickLinksVendors,
      quickLinksValue,
    } = this.props

    const classNames = clsx({
      'is-disabled': isDisabled,
      'is-compact': isCompact,
      'is-locked': isLocked,
      'is-editable': isEditable,
      'is-horizontal': horizontal,
      'is-invalid': errors && errors.length,
      'is-highlighted': isHighlighted,
      'with-hover': withHover && !isDisabled,
      grow: grow,
      [className]: className,
    })

    return (
      <div
        css={styles}
        className={classNames}
        style={{
          flex,
          ...(maxWidth && { '--field-max-width': maxWidth }),
          ...(labelWidth && { '--field-label-width': labelWidth }),
        }}
        ref={this.formGroupRef}
        data-test={testKey}
      >
        {((label && showLabel) || (isEditable && isRequired && showLabel)) && (
          <Label
            largeLabel={largeLabel}
            htmlFor={id}
            icon={icon}
            label={label}
            description={labelDescription}
            isRequired={isEditable && isRequired}
            isValid={isValid}
            layout={layout}
            align={labelAlign}
            justify={labelJustify}
            tooltip={tooltip}
            tooltipTrigger={tooltipTrigger}
            after={
              (labelAfter || quickLinksVendors) && (
                <>
                  {labelAfter}
                  {quickLinksVendors && <VendorQuickLinks vendors={quickLinksVendors} value={quickLinksValue} />}
                </>
              )
            }
            hideAsterisk={hideAsterisk}
            isCompact={isCompact}
            className={labelClassName}
            truncate={labelTruncate}
          />
        )}

        <div className="field-content">
          <GroupLayout layout={layout} isReadonly={!isEditable}>
            {children}
          </GroupLayout>

          {(isEditable || alwaysShowDescription) && description && <Text className="field-description">{description}</Text>}
          {(isEditable || alwaysShowDescription) && smartDescription && smartValue && (
            <Text className="field-description">{smartValue}</Text>
          )}
        </div>

        <>{errors && <Errors errors={errors} />}</>
      </div>
    )
  }
}

const highlightAnimation = keyframes`
  0% {
		background-position: 0 0;
	}
	50% {
		background-position: 300% 0;
	}
	100% {
		background-position: 0 0;
	}
`

const styles = {
  maxWidth: 'var(--field-max-width)',
  transition: 'box-shadow 160ms ease-out, transform 160ms ease-out',
  position: 'relative',
  '--gradient-shadow': `linear-gradient(45deg, ${COLORS.transparent}, ${COLORS.orange}, ${COLORS.red}, ${COLORS.transparent})`,

  '&.is-editable': {
    '&.with-hover:hover': {
      borderRadius: 0.5,
      background: 'var(--field-background-hover)',
      boxShadow: '0 0 0 4px var(--field-background-hover)',
      position: 'relative',
      zIndex: 1,
    },
  },

  '&.is-disabled': {
    // background: 'var(--field-background-disabled) !important',
    transform: 'none !important',
    cursor: 'not-allowed',
    transition: 'none',
    opacity: 0.5,
    pointerEvents: 'none',
  },

  '&.is-locked': {
    transform: 'none !important',
    cursor: 'not-allowed',
    transition: 'none',

    '&.is-editable': {
      opacity: 0.5,
    },

    '&.with-hover:hover': {
      borderRadius: 0,
      background: 'none',
      boxShadow: 'none',
    },
  },

  '&.is-horizontal': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',

    '.field-content': {
      flex: '1 1 auto',
    },
  },

  '&.is-invalid': {
    marginBottom: '2em',
    borderRadius: 0.5,
    background: 'var(--field-background-error) !important',
    boxShadow: '0 0 0 4px var(--field-background-error) !important',
    transform: 'translateY(-2px)',
    transition: 'background 160ms ease-out, box-shadow 160ms ease-out, transform 160ms ease-out',
    zIndex: 3,
  },

  '&.is-highlighted': {
    borderRadius: 0.5,
    position: 'relative',
    zIndex: 1,

    '&::after': {
      content: '""',
      position: 'absolute',
      top: -2,
      left: -2,
      background: 'var(--gradient-shadow)',
      backgroundSize: '400%',
      width: 'calc(100% + 4px)',
      height: 'calc(100% + 4px)',
      zIndex: -1,
      animation: `${highlightAnimation} 15s linear infinite`,
      opacity: 0.2,

      filter: 'blur(10px)',
    },
  },

  '&.is-compact': {
    '.field-description': {
      fontSize: '0.9rem !important',
    },
  },

  '&.grow': {
    flexGrow: 1,
  },

  '.field-content': {
    flex: '1 1 200px',
  },

  '.field-description': {
    marginTop: '0.25em',
  },
}

Field.defaultProps = {
  showLabel: true,
  withHover: true,
}
