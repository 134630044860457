import React from 'react'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

const ClaimFrequencySelect: React.FC<any> = ({ label = 'Claim Frequency', model = 'claim_frequency_code', ...rest }) => (
  <Select label={label} model={model} defaultValue="1" {...rest}>
    <Option label="1 - Original Claim" value="1" />
    <Option label="7 - Replacement of Prior Claim" value="7" />
    <Option label="8 - Void/Cancel Prior Claim" value="8" />
  </Select>
)

export default ClaimFrequencySelect
