import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Global } from '@emotion/react'
import { parse } from 'query-string'

import { COLORS } from '@behavehealth/theme'
import { buildBaseUrl } from '@behavehealth/modules/axios'
import { setIdle, setIdleSaving } from '@behavehealth/actions/common'
import { store } from '@behavehealth/setup/store'

import { AppLayout, Flex, Logo } from '@behavehealth/components'
import { apiGet } from '@behavehealth/modules/api'

const Redirect: React.FC = () => {
  let history = useHistory()
  let location = useLocation()

  React.useEffect(() => {
    store.dispatch(setIdle(false))
    store.dispatch(setIdleSaving(false))

    // build urls for requests
    buildBaseUrl()

    const asyncRedirect = async () => {
      try {
        let params = parse(location.search)
        if (params.page === 'full-application') {
          const result = await apiGet({ url: `/apps/account/id/${params.tenant}` })
          if (result.data?.id) {
            history.push(`/${result.data.id}/forms/full-application`)
          } else {
            history.push('/')
          }
        } else {
          history.push('/')
        }
      } catch (error) {
        console.error(error)
        history.push('/')
      }
    }

    asyncRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div css={{ height: '100%', display: 'contents' }}>
      <Global styles={globalStyles} />

      <AppLayout columns="1fr" background={COLORS.lightBackground}>
        <Flex vertical stretchSelfMax nowrap justifyContent="space-between" className="!w-full">
          <div>
            <Flex justifyContent="center" className="!my-8 !mx-0">
              <Logo logo="ehr" />
            </Flex>
          </div>
        </Flex>
      </AppLayout>
    </div>
  )
}

const globalStyles = {
  'html, body': {
    background: COLORS.white,
  },
}

export default Redirect
