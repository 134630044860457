import React from 'react'
import { useLocation } from 'react-router-dom'

import { H4 } from '../../components/Typography'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const SCORING = {
  20: 'No or doubtful depression',
  25: 'Mild depression',
  30: 'Moderate depression',
  50: 'Severe depression',
}

const INITIAL_STATE = {
  low_in_spirits_or_sad: 0,
  lost_interest_in_daily_activities: 0,
  lacking_in_energy_and_strength: 0,
  less_self_confident: 0,
  feel_guilt: 0,
  feel_life_not_worth_living: 0,
  difficulty_concentrating: 0,
  very_restless: 0,
  subdued_or_slowed_down: 0,
  trouble_sleeping: 0,
  reduced_appetite: 0,
  increased_appetite: 0,
}

const RootMDIOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="MDI Major Depression Inventory"
      category="clinical_measurement"
      subcategory="mdi"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <MDIQuestionnaire />
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <ol>
          <li>
            P Bech, et al. The sensitivity and specificity of the Major Depression Inventory, using the Present State Examination as the
            index of diagnostic validity. 66 J Affect Disord 159-164. 2001.
          </li>

          <li>
            LR Olsen, et al. The internal and external validity of the Major Depression Inventory in measuring severity of depressive
            states. 33 Psychological Medicine 351-356. 2003.
          </li>
        </ol>
      </Section>
    </DataFormOverlay>
  )
}

const MDIQuestionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <H4>The following questions ask how you have been feeling over the last 2 weeks</H4>

        <RadioGroup
          label="Have you felt low in spirits or sad?"
          model="data.questionnaire.low_in_spirits_or_sad"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, low_in_spirits_or_sad: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>

        <RadioGroup
          label="Have you lost interest in your daily activities?"
          model="data.questionnaire.lost_interest_in_daily_activities"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, lost_interest_in_daily_activities: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>

        <RadioGroup
          label="Have you felt lacking in energy and strength?"
          model="data.questionnaire.lacking_in_energy_and_strength"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, lacking_in_energy_and_strength: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>

        <RadioGroup
          label="Have you felt less self-confident?"
          model="data.questionnaire.less_self_confident"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, less_self_confident: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>

        <RadioGroup
          label="Have you had a bad conscience or feelings of guilt?"
          model="data.questionnaire.feel_guilt"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, feel_guilt: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>

        <RadioGroup
          label="Have you felt that life wasn’t worth living?"
          model="data.questionnaire.feel_life_not_worth_living"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, feel_life_not_worth_living: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>

        <RadioGroup
          label="Have you had difficulty in concentrating?"
          model="data.questionnaire.difficulty_concentrating"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, difficulty_concentrating: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>

        <RadioGroup
          label="Have you felt very restless?"
          model="data.questionnaire.very_restless"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, very_restless: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>

        <RadioGroup
          label="Have you felt subdued or slowed down?"
          model="data.questionnaire.subdued_or_slowed_down"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, subdued_or_slowed_down: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>

        <RadioGroup
          label="Have you had trouble sleeping at night?"
          model="data.questionnaire.trouble_sleeping"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, trouble_sleeping: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>

        <RadioGroup
          label="Have you suffered from reduced appetite?"
          model="data.questionnaire.reduced_appetite"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, reduced_appetite: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>

        <RadioGroup
          label="Have you suffered from increased appetite?"
          model="data.questionnaire.increased_appetite"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, increased_appetite: state.value }))}
        >
          <Radio label="0 = At no time" value={0} />
          <Radio label="1 = Some of the time" value={1} />
          <Radio label="2 = Slightly less than half the time" value={2} />
          <Radio label="3 = Slightly more than half the time" value={3} />
          <Radio label="4 = Most of the time" value={4} />
          <Radio label="5 = All the time" value={5} />
        </RadioGroup>
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Score title="Final Score" finalScore={finalScore} scoring={SCORING} />
        </>
      )}
    </>
  )
}

export const MDIOverlay = withOverlayError(RootMDIOverlay)
