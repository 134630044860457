import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import get from 'lodash/get'
import pluralize from 'pluralize'

import { COLORS, SHADOW } from '../theme'
import { hasOrHave } from '../utils/functions'
import { resetQueue } from '../actions/common'
import Upload from '../modules/upload'

import Button from './Button'
import CardLink from './CardLink'
import CardTitle from './CardTitle'
import DataGrid from './DataGrid'
import Icon from './Icon'
import Loader from './Loader'
import Overlay from './Overlay'
import Portal from './Portal'
import UploadFile from './UploadFile'

class UploadTracker extends React.Component {
  state = {
    $show: false,
    $open: false,
    files: this.props.files,
  }

  open = (event: any) => {
    event.stopPropagation()

    this.setState({ $open: true, $show: false })
  }
  close = () => {
    this.setState({ $open: false, $show: true })
    if (this.props.status === 'completed' || this.props.status === 'pending') this.hide()
  }
  hide = () => {
    this.setState({ $show: false })
    setTimeout(() => {
      this.props.resetQueue()
      Upload.clearQueue()
    }, 1000)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.files !== this.props.files) {
      this.setState({ files: this.props.files, $show: true })
    }
  }

  onRemove = (id) => {
    Upload.removeFromQueue(id)

    let files = this.state.files
    delete files[id]

    this.setState({ files: files })
  }

  render() {
    const { $open, $show } = this.state
    const { files, status, step } = this.props

    const isLoading = status === 'uploading'

    const filesLength = files ? Object.keys(files).length : 0

    let title = 'Upload Tracker'
    let subtitle = null

    if (status === 'completed') {
      title = `${filesLength} ${pluralize('File', filesLength)} Uploaded`
      subtitle = `${filesLength} ${pluralize('file', filesLength)} ${hasOrHave(filesLength)} been uploaded`
    } else if (status === 'uploading') {
      title = `Uploading ${step} / ${filesLength} Files…`
      subtitle = `Uploading ${step} / ${filesLength} Files`
    } else if (status === 'error') {
      title = 'Oops, something went wrong…'
      subtitle = 'Sorry, some files could not be uploaded'
    }

    const classes = classNames({ 'is-visible': $show })

    return (
      <>
        <div className={classes} css={styles.root}>
          <CardLink
            css={styles.card}
            onClick={this.open}
            graphic={<Icon icon="files" size={20} />}
            after={
              isLoading ? (
                <Loader />
              ) : (
                <Button hideLabel onClick={this.hide} glyph="chevron" type="link" color="textMuted" css={{ transform: 'rotateZ(90deg)' }} />
              )
            }
          >
            <CardTitle title={title} className="!text-[1rem]" />
          </CardLink>
        </div>

        {$open && (
          <Portal type="dialog">
            <Overlay closeOnBackdrop position="center" showBackdrop={true} minHeight={25} onClose={this.close}>
              <Overlay.Header icon="files" title="Upload Details" subtitle={subtitle} />

              <Overlay.Content>
                <DataGrid
                  gap="0"
                  data={files}
                  useSearch={false}
                  render={(file) => <UploadFile key={file.id} file={file} onRemove={this.onRemove} />}
                />
              </Overlay.Content>
            </Overlay>
          </Portal>
        )}
      </>
    )
  }
}

const styles = {
  root: {
    display: 'flex',
    paddingBottom: 3,
    maxWidth: '~"calc(100% - 1.5rem)"',
    width: 'fit-content',
    cursor: 'pointer',

    position: 'fixed',
    bottom: '0.75rem',
    left: '50%',
    transform: 'translate3d(-50%, 150% ,0)',
    transition: 'transform 320ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    zIndex: 0,
    opacity: 0,

    '&.is-visible': {
      zIndex: 1,
      opacity: 1,
      transform: 'translate3d(-50%, 0 ,0)',
    },
  },

  card: {
    boxShadow: SHADOW(3, COLORS.divider),

    '&:hover': {
      boxShadow: SHADOW(12, COLORS.divider),
    },
  },
}

const mapDispatchToProps = (dispatch) => ({
  resetQueue: () => dispatch(resetQueue()),
})

const mapStateToProps = (state) => ({
  files: get(state, 'common.uploader.files'),
  status: get(state, 'common.uploader.status'),
  current: get(state, 'common.uploader.current'),
  percentage: get(state, 'common.uploader.percentage'),
  step: get(state, 'common.uploader.step'),
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadTracker)
