import create from 'zustand'
import produce from 'immer'

const store = (set: any, get: any) => ({
  categoriesStatusCheck: [],
  addCategoryStatusCheck: (category: string) => {
    const current = get().categoriesStatusCheck

    if (current.includes(category)) return

    set({ categoriesStatusCheck: [...current, category] })
  },
  removeCategoryStatusCheck: (category: string) => {
    const current = get().categoriesStatusCheck

    const index = current.indexOf(category)

    if (index === -1) return

    const updated = produce(current, (draft: any) => {
      draft.splice(index)
    })

    set({ categoriesStatusCheck: updated })
  },
})

export const useAccountingStore = create(store)
