import React from 'react'

import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Status from '../../components/Status'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const VitalSignsReportDataTable = (props: any) => {
  const { to } = props

  const { timezone, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        width: 220,
        isSticky: true,
        canToggleVisible: false,
        id: 'recorded_at',
        model: 'data.recorded_at',
        title: 'Date & Time',
        type: 'datetime',
        formatValue: ({ data, value }: any) => <MainCell id={data.data.id} value={usDateTime(value, timezone)} to={to?.(data.data)} />,
      },
      {
        width: 180,
        id: 'client',
        model: 'data.client.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 180,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ data, value }) => {
          if (value === 'refused') return <Status color="red" label="Refused Collection" />
          if (value === 'collected') return <Status color="blue" label="Collected" />

          // NOTE @Andrei: Implement NotApplicable component
          return 'N/A'
        },
      },
      {
        id: 'blood_pressure_systolic',
        model: 'data.blood_pressure_systolic',
        width: 200,
        title: 'Blood Pressure Systolic',
        formatValue: ({ data, value }) => {
          const { blood_pressure_diastolic, blood_pressure_systolic } = data.data

          if (!blood_pressure_diastolic && !blood_pressure_systolic) return '-'
          return `${blood_pressure_systolic} mm Hg /`
        },
      },
      {
        id: 'blood_pressure_diastolic',
        model: 'data.blood_pressure_diastolic',
        width: 200,
        title: 'Blood Pressure Diastolic',
        formatValue: ({ data, value }) => {
          const { blood_pressure_diastolic, blood_pressure_systolic } = data.data

          if (!blood_pressure_diastolic && !blood_pressure_systolic) return '-'
          return `/ ${blood_pressure_diastolic} mm Hg`
        },
      },
      {
        id: 'temperature',
        model: 'data.temperature',
        width: 180,
        title: 'Temp.',
        formatValue: ({ data, value }) => {
          if (!value) return '-'
          return `${value} °F`
        },
      },
      {
        id: 'oxygen_saturation',
        model: 'data.oxygen_saturation',
        width: 180,
        title: 'O2 Sat',
        formatValue: ({ data, value }) => {
          if (!value) return '-'
          return `${value}%`
        },
      },
      {
        id: 'pulse',
        model: 'data.pulse',
        width: 180,
        title: 'Pulse',
        formatValue: ({ data, value }) => {
          if (!value) return '-'
          return `${value} beats / min`
        },
      },
      {
        id: 'respiration',
        model: 'data.respiration',
        width: 180,
        title: 'Respiration',
        formatValue: ({ data, value }) => {
          if (!value) return '-'
          return `${value} breaths / min`
        },
      },
      {
        id: 'pain_scale',
        model: 'data.pain_scale',
        width: 180,
        title: 'Pain Scale',
        formatValue: ({ data, value }) => {
          if (!value) return '-'
          return `${value} / 10`
        },
      },
      {
        width: 180,
        id: 'author',
        model: 'data.author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="Vital Signs"
      icon="test_results"
      columns={columns}
      filtersConfig={FILTERS.vital_signs}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="vital_signs"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=vital_signs'}
        />
      }
    />
  )
}
