import React from 'react'
import { transparentize, opacify } from 'polished'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../../../theme'
import ClearButton from '../../Forms/ClearButton'

const Input = (props: any) => {
  const { type, value, onChange, placeholder, className, setFilter } = props

  const clearFilter = () => setFilter(undefined)

  const classNames = clsx({
    'has-value': !!value,
    [className]: className,
  })

  return (
    <div className="!relative">
      <input
        type={type}
        value={!value && value !== 0 ? '' : value}
        onChange={onChange}
        placeholder={placeholder}
        css={inputStyles}
        className={classNames}
      />
      <ClearButton onClick={clearFilter} isVisible={!!value} />
    </div>
  )
}

export const inputStyles = {
  width: '100%',
  padding: '0.5em 0.75em',
  paddingRight: '1.8rem',

  color: COLORS.text,
  fontWeight: 500,
  fontSize: '0.95rem',

  outline: 'none',
  borderRadius: 4,
  background: COLORS.white,
  border: `1px solid ${opacify(0.08, COLORS.divider)}`,

  transition: `
    box-shadow 100ms cubic-bezier(0.39, 0.575, 0.565, 1),
    background 200ms cubic-bezier(0.39, 0.575, 0.565, 1)
  `,

  // '&.has-value': {
  // background: tint(0.96, COLORS.orange),
  // },

  '&::placeholder': {
    opacity: 0.6,
    fontStyle: 'italic',
  },

  '&:hover': {
    boxShadow: SHADOW(5),
    borderColor: opacify(0.1, COLORS.divider),
  },

  '&:focus': {
    background: COLORS.white,
    borderColor: COLORS.blue,
    boxShadow: `
      ${SHADOW(5, transparentize(0.95, COLORS.blue))},
      0 0 0 2px ${transparentize(0.75, COLORS.blue)}
    `,
  },
}

Input.defaultProps = {
  type: 'text',
  placeholder: 'Filter…',
}

export default Input
