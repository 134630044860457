import React from 'react'
import { DateTime } from 'luxon'
import Timer from 'react-compound-timer'

import { secondsBetweenRelative } from '../../utils/functions'
import { useGet, useUpdate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import State from '../../components/State'
import Status from '../../components/Status'

import { CollectPaymentMethod } from './components/CollectPaymentMethod'
import { Comments } from './components/Comments'
import { ConfidentialityNDA } from './components/ConfidentialityNDA'
import { Parties } from './components/Parties'
import { PriceMatching } from './components/PriceMatching'
import { Pricing } from './components/Pricing'
import { QuotePayment } from './components/QuotePayment'
import { Signing } from './components/Signing'
import { Summary } from './components/Summary'

export const QuoteReview = (props: any) => {
  const { before, dataID, endpoint = '/quotes', updateComments, updateAcceptedOffer, isPublic, after, isSectionsOpen } = props

  const { data, isLoading, isError, refetch } = useGet({
    name: ['quotes', dataID],
    url: `${endpoint}/${dataID}`,
    options: { enabled: !!dataID && !!endpoint },
  })

  const [didSign, setDidSign] = React.useState(!!data?.quote_signature && !!data?.signing_date)
  const [didAgreeConfidentiality, setDidAgreeConfidentiality] = React.useState(!!data?.agreed_to_confidentiality_notice_at)
  const [didAgreeNDA, setDidAgreeNDA] = React.useState(!!data?.agreed_to_nda_at)

  const [isAccepting, setIsAccepting] = React.useState(false)
  const [acceptedOfferId, setAcceptedOfferId] = React.useState(data?.selected_quote_offer?.id || null)

  const { isBehave, isEHRApp, isHQApp } = useSettings()
  const timezone = data?.timezone || data?.facility?.timezone

  const { mutateAsync: updateQuote } = useUpdate({
    name: ['save-comments'],
    url: `${endpoint}/${dataID}`,
    invalidate: 'quotes',
  })

  const canViewQuoteDetails = React.useMemo(() => {
    if (!data) return false

    const { use_confidentiality_section, use_nda_section } = data

    if (use_confidentiality_section && !didAgreeConfidentiality) return false
    if (use_nda_section && !didAgreeNDA) return false

    return true
  }, [data, didAgreeConfidentiality, didAgreeNDA])

  const didAcceptOffer = !!acceptedOfferId

  const isPaid = data?.status === 'completed' || data?.status === 'paid'
  const isFinalStatus =
    data?.status === 'completed' || data?.status === 'paid' || data?.status === 'expired' || data?.status === 'payment_method_on_file'
  const isProcessingPayment = data?.status === 'payment_processing'

  const acceptOffer = async (id: any) => {
    if (!id || !data?.id) return

    setIsAccepting(true)

    const payload = { selected_quote_offer_id: id }

    try {
      const response = updateAcceptedOffer ? await updateAcceptedOffer(payload) : await updateQuote(payload)

      setAcceptedOfferId(response?.data?.selected_quote_offer?.id || null)
    } catch (error) {
      console.error(error)
    } finally {
      setIsAccepting(false)
    }
  }

  React.useEffect(() => {
    if (!data) return

    setDidSign(!!data?.quote_signature && !!data?.signing_date)
    setDidAgreeNDA(!!data.agreed_to_nda_at)
    setDidAgreeConfidentiality(!!data.agreed_to_confidentiality_notice_at)

    if (!!data?.selected_quote_offer?.id) {
      setAcceptedOfferId(data?.selected_quote_offer?.id)
    }
  }, [data?.agreed_to_nda_at, data?.status, data?.agreed_to_confidentiality_notice_at, data?.selected_quote_offer?.id])

  if (isLoading || props.isLoading) {
    return (
      <State isLoading={isLoading} isEmpty={!isLoading} title="Quote" icon="quotes" emptyDescription="No quote added yet" minHeight={200} />
    )
  }

  if (isError) {
    return (
      <State
        isEmpty
        title="Quote Error"
        glyph="circle_error"
        emptyDescription="There was a problem loading this Quote. Please get in touch with us to help with this issue."
        emptyActions={<Button href="mailto:contact@behavehealth.com" label="Contact Us" glyph="email" type="primary" />}
        minHeight={200}
      />
    )
  }

  const { status } = data

  if (status === 'expired') {
    return (
      <div>
        {before}
        <State
          isEmpty
          title="Quote Expired"
          glyph="circle_error"
          emptyDescription="This quote has expired and is no longer valid. Please contact us if you need a new quote."
          emptyActions={<Button href="mailto:contact@behavehealth.com" label="Contact Us" glyph="email" type="primary" />}
          minHeight={240}
        />
      </div>
    )
  }

  if (status === 'archived') {
    return (
      <div>
        {before}
        <State
          isEmpty
          title="Quote Inactive"
          glyph="circle_error"
          emptyDescription="This quote is no longer active. Please contact us if you need a new quote."
          emptyActions={<Button href="mailto:contact@behavehealth.com" label="Contact Us" glyph="email" type="primary" />}
          minHeight={240}
        />
      </div>
    )
  }

  const secondsToExpiry = secondsBetweenRelative(DateTime.now().setZone(timezone), data.expires_at, timezone, null)
  const upfrontAmount = data?.selected_offer_calculated_amounts?.upfront?.total || 0

  return (
    <>
      {before}

      {isProcessingPayment && (
        <Alert centerX small glyph="info" type="warning" className="!rounded-none">
          The payment for this quote is currently being processed. The quote products will be activated once the payment is completed.
        </Alert>
      )}

      <Parties isOpen={isSectionsOpen} isPublic={isPublic} data={data} />

      <div className="grid mq1600:grid-cols-2">
        <Comments
          isOpen={isSectionsOpen}
          isPublic={isPublic}
          data={data}
          timezone={timezone}
          updateQuote={updateComments || updateQuote}
          title="Your Comments"
          model="customer_comments"
          className="mq1600:border-r mq1600:border-divider"
          canEdit={!isFinalStatus && !isBehave}
          before={
            !isFinalStatus &&
            !isBehave && (
              <Alert small glyph="info">
                If you have any comments or questions about this quote, please add them here. You can also reach out to us at{' '}
                <a href="mailto:contact@behavehealth.com">contact@behavehealth.com</a>.
              </Alert>
            )
          }
        />

        <Comments
          isOpen={isSectionsOpen}
          isPublic={isPublic}
          data={data}
          timezone={timezone}
          updateQuote={updateComments || updateQuote}
          title="Behave Health Comments"
          model="behave_comments"
          canEdit={!isFinalStatus && (isEHRApp || isHQApp) && isBehave}
        />
      </div>

      <Summary isOpen={isSectionsOpen} isPublic={isPublic} data={data} />

      <ConfidentialityNDA
        isOpen={isSectionsOpen}
        isPublic={isPublic}
        data={data}
        didAgreeConfidentiality={didAgreeConfidentiality}
        didAgreeNDA={didAgreeNDA}
        setDidAgreeConfidentiality={setDidAgreeConfidentiality}
        setDidAgreeNDA={setDidAgreeNDA}
      />

      <Pricing
        isOpen={isSectionsOpen}
        isPublic={isPublic}
        data={data}
        canViewQuoteDetails={canViewQuoteDetails}
        acceptedOfferId={acceptedOfferId}
        acceptOffer={acceptOffer}
        isAccepting={isAccepting}
      />

      <PriceMatching
        isOpen={isSectionsOpen}
        data={data}
        isPublic={isPublic}
        isFinalStatus={isFinalStatus}
        canViewQuoteDetails={canViewQuoteDetails && didAcceptOffer}
      />

      <Signing
        isOpen={isSectionsOpen}
        data={data}
        isPublic={isPublic}
        setDidSign={setDidSign}
        didSign={didSign || isFinalStatus}
        canViewQuoteDetails={canViewQuoteDetails && didAcceptOffer}
      />

      {!!upfrontAmount ? (
        <QuotePayment isOpen={isSectionsOpen} data={data} isPublic={isPublic} enabled={didSign && canViewQuoteDetails && didAcceptOffer} />
      ) : (
        <CollectPaymentMethod
          isOpen={isSectionsOpen}
          data={data}
          isPublic={isPublic}
          enabled={didSign && canViewQuoteDetails && didAcceptOffer}
          refetchQuote={refetch}
        />
      )}

      {after}
    </>
  )
}
