import React from 'react'
import size from 'lodash/size'

import { titleCase, address } from '../../utils/functions'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Grid from '../../components/Grid'
import Flex from '../../components/Flex'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

import { withOverlayError } from '../../hocs/withOverlayError'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

import FeeScheduleTable from './components/FeeScheduleTable'

const RootArchivedFeeScheduleOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isOverlayLoading,
    isSaving,
    isValid,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'insurance-fee-schedules',
    endpoint: '/insurance_fee_schedules',
    invalidate: 'insurance-fee-schedules',
    options: props,
  })

  const { online, timezone }: any = useSettings()
  const [formData, setFormData] = React.useState(null)

  if (isOverlayLoading) {
    return <OverlayLoader showBackdrop position="center" />
  }

  const canAddLines = !!formData?.name && size(formData?.insurance_local_payer_ids) > 0

  return (
    <Overlay showBackdrop position="center" maxWidth={90} onClose={close} isDirty={isEditable}>
      <Overlay.Header icon="insurance" title="Fee Schedule" />

      <Overlay.Content>
        <Form
          useLocalModel
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          timezone={timezone}
          onUpdate={setFormData}
          // decorate={(model: any) => ({
          //   insurance_fee_schedule_services_attributes: mapToArray(model?.insurance_fee_schedule_services),
          // })}
        >
          <Section>
            <Grid gap="1rem" maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a fee schedule name',
                  },
                }}
              />

              <Flex stretchChildrenX gap="1rem">
                <MultiObjectSelector
                  label="Insurance Payers"
                  model="insurance_local_payers"
                  icon="insurance"
                  type="insurance_local_payers"
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => data?.notes}
                  validations={{
                    presence: {
                      message: 'Please select some Insurance Payers',
                    },
                  }}
                />

                <MultiObjectSelector
                  label="Healthcare Locations"
                  model="houses"
                  icon="properties"
                  type="insurance.locations"
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => address(data?.address)}
                />

                <MultiObjectSelector
                  label="Healthcare Providers"
                  model="employees"
                  icon="employees"
                  type="insurance.providers"
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data) => titleCase(data.position)}
                />
              </Flex>

              {!isValid && (
                <Alert glyph="warning" type="warning">
                  You must add a <strong>Name</strong> and select <strong>Insurance Payers</strong> above before <strong>Services</strong>{' '}
                  become editable
                </Alert>
              )}

              <FeeScheduleTable
                isValid={canAddLines}
                model="insurance_fee_schedule_services"
                // value={initialModel.insurance_fee_schedule_services}
              />
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer online={online}>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={!isValid}
            />
            <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Fee Schedule?"
              message="Are you sure you want to delete this fee schedule? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ArchivedFeeScheduleOverlay = withOverlayError(RootArchivedFeeScheduleOverlay)
