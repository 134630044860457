import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'

import Divider from '../../Divider'
import Overlay from '../../Overlay'
import OverlayLoader from '../../OverlayLoader'
import Section from '../../Section'
import Timeline from '../../Timeline/Timeline'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

import ChangeHealthcare from './EVOB/ChangeHealthcare'
import PVerify from './EVOB/PVerify'
import { ClaimMD } from './EVOB/ClaimMD'

class EVOBOverlay extends OverlayBase {
  render = () => {
    const { $new, params } = this.state
    const { record, timezone, loading, onClose } = this.props

    const data = { ...params, ...record }

    // const summary = data?.insurance_eligibility_summary

    // if (!$new && size(summary) === 0) {
    //   return <OverlayLoader position={this.props.position} showBackdrop={this.props.showBackdrop} />
    // }

    if (data.clearing_house === 'claim_md') {
      return (
        <Overlay maxWidth={80} closeOnBackdrop={this.props.closeOnBackdrop} showBackdrop={this.props.showBackdrop} onClose={onClose}>
          <Overlay.Header icon="financials" title="eVOB" />

          <ClaimMD
            data={data}
            contentAfter={
              !$new && (
                <>
                  <Divider />

                  <Section headingType="h2" title="Timeline">
                    <Timeline isLoadingRecord={loading} recordID={data.id} recordType={data.type} />
                  </Section>
                </>
              )
            }
          />
        </Overlay>
      )
    }

    return (
      <Overlay
        maxWidth={data.clearing_house === 'change_health' ? 64 : 48}
        closeOnBackdrop={this.props.closeOnBackdrop}
        showBackdrop={this.props.showBackdrop}
        onClose={onClose}
      >
        <Overlay.Header icon="financials" title="eVOB" />

        <Overlay.Content>
          {data.clearing_house === 'change_health' && <ChangeHealthcare data={data} />}
          {data.clearing_house === 'pverify' && <PVerify data={data} />}

          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={data.id} recordType={data.type} />
              </Section>
            </>
          )}
        </Overlay.Content>
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_evobs')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(EVOBOverlay))
