import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { ROSTER_CATEGORIES } from '../../utils/constants'
import withSettings from '../../hocs/withSettings'

import ProgressBar from '../ProgressBar'
import RosterStatus from '../Statuses/RosterStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const calculatePercentage = (artefacts: any) => {
  if (!artefacts || size(artefacts) === 0) return 0

  let done = 0
  let total = size(artefacts)

  for (const artefact of artefacts) {
    if (artefact.status === 'done') done++
  }

  return Math.round((done / total) * 100)
}

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Roster Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id, row.original.category)} label={value} avatar={null} />,
  },
  {
    width: 120,
    accessor: 'status',
    Header: 'Roster Status',
    Cell: ({ value }: any) => {
      return <TableCell value={value && <RosterStatus status={value} />} />
    },
  },
  {
    width: 160,
    accessor: 'category',
    Header: 'Type',
    Cell: ({ value }: any) => {
      if (!(value in ROSTER_CATEGORIES)) return <TableCell.Empty />
      return <TableCell value={ROSTER_CATEGORIES[value]} />
    },
  },
  {
    width: 110,
    id: 'progress',
    accessor: 'artefacts',
    Header: 'Progress',
    Cell: ({ value }: any) => {
      const percent = calculatePercentage(value)
      return <ProgressBar showPercentage percent={percent} color="green" width={56} />
    },
  },
  {
    width: 160,
    id: 'clients_count',
    accessor: 'artefacts',
    Header: 'Clients',
    Cell: ({ value }) => {
      if (!value) return <TableCell.Empty />

      return countWord('Clients', size(value))
    },
  },
  {
    width: 160,
    accessor: 'assigned_at',
    Header: 'Assigned Date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 160,
    accessor: 'due_at',
    Header: 'Due Date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 220,
    accessor: 'employee.name',
    Header: 'Assigned Staff',
    Cell: ({ value, row }) => {
      if (!value) return <TableCell.Empty />
      return <TableCell.Profile name={value} avatar={row.original?.employee?.avatar} />
    },
  },
  {
    width: 220,
    accessor: 'program.name',
    Header: 'Assigned Program',
    Cell: ({ value, row }) => {
      if (!value) return <TableCell.Empty />
      return <TableCell.Profile name={value} avatar={row.original?.program?.avatar} />
    },
  },
  {
    width: 220,
    accessor: 'property.name',
    Header: 'Assigned Location',
    Cell: ({ value, row }) => {
      if (!value) return <TableCell.Empty />
      return <TableCell.Profile name={value} avatar={row.original?.property?.avatar} />
    },
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Created By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  timezone: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const RostersTable = (props: Props) => {
  const { data, emptyActions, emptyDescription, icon, isLoading, batchActionsConfig, localStorageKey, title, to, timezone, titleAfter } =
    props

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

RostersTable.defaultProps = {
  title: 'Rosters',
  icon: 'roster',
  emptyDescription: 'No rosters added yet',
  localStorageKey: 'rosters',
}

export default withSettings(RostersTable)
