import React from 'react'
import size from 'lodash/size'
import pluralize from 'pluralize'

import { COLORS } from '../../../../../theme'
import { mapToArray } from '../../../../../utils/functions'
import { TabsContext } from '../../../../../hocs/withTabsContext'

import Button from '../../../../Button'
import FormSection from '../../../../Forms/FormSection'
import State from '../../../../State'
import Status from '../../../../Status'
import TreeItem from '../../../../TreeItem'

import DiagnosisAccordion from '../components/DiagnosisAccordion'
import Footer from '../components/Footer'
import Main from '../components/Main'

const getPlannersList = (wileyData: any) => {
  const groupIDs: any = []
  const res = []

  for (const icd10 in wileyData) {
    // skip duplicates
    if (groupIDs.includes(wileyData[icd10].problem_group_id)) continue

    groupIDs.push(wileyData[icd10].problem_group_id)
    res.push(wileyData[icd10].problem_group_description)
  }

  return res
}

const Review = ({ after, wileyData, onSave, onClose }: any) => {
  const { onSelect: selectTab }: any = React.useContext(TabsContext)

  const [isLoading, setIsLoading] = React.useState(false)

  const save = async () => {
    setIsLoading(true)

    if (onSave) await onSave(wileyData)

    setIsLoading(false)

    if (onClose) onClose()
  }

  const planners = getPlannersList(wileyData)

  return (
    <>
      <Main>
        <FormSection maxWidth="100%">
          {mapToArray(wileyData).map((diagnosis) => (
            <DiagnosisAccordion
              icd10={diagnosis.icd10}
              icd10Description={diagnosis.description}
              problemGroupDescription={diagnosis.problem_group_description}
              problemDescription={diagnosis.problem_description}
            >
              {mapToArray(diagnosis.goals).map((goal: any) => (
                <TreeItem
                  isOpen
                  showDivider
                  key={goal.goal_id}
                  title={
                    <>
                      <Status inline small label="Goal" color="blue" css={styles.inlineStatus} />
                      {goal.description}
                    </>
                  }
                >
                  {size(goal.objectives) === 0 && (
                    <State
                      isEmpty
                      icon="treatment_plans"
                      title="Objectives"
                      emptyDescription="No objectives added for this goal"
                      emptyActions={
                        <Button label="← Select Objectives" type="minimal" size={200} onClick={() => selectTab('objectives')} />
                      }
                      minHeight={200}
                    />
                  )}

                  {mapToArray(goal.objectives).map((objective) => (
                    <TreeItem
                      isOpen
                      showDivider
                      key={objective.objective_id}
                      title={
                        <>
                          <Status inline small label="Objective" color="purple" css={styles.inlineStatus} />
                          <span css={styles.objectiveTitle}>{objective.description}</span>
                        </>
                      }
                    >
                      <Status small label="Interventions:" color="orange" css={styles.blockStatus} />

                      {size(objective.interventions) === 0 && (
                        <State
                          isEmpty
                          icon="treatment_plans"
                          title="Interventions"
                          emptyDescription="No interventions added for this objective"
                          emptyActions={
                            <Button label="← Select Interventions" type="minimal" size={200} onClick={() => selectTab('interventions')} />
                          }
                          minHeight={200}
                        />
                      )}

                      <ul css={styles.interventionsList}>
                        {mapToArray(objective.interventions).map((intervention) => (
                          <li key={intervention.intervention_id}>{intervention.description.description}</li>
                        ))}
                      </ul>
                    </TreeItem>
                  ))}
                </TreeItem>
              ))}
            </DiagnosisAccordion>
          ))}

          {after}
        </FormSection>

        <div css={styles.copyright}>
          <p>
            <b>Copyright: </b>
            From the Wiley Practice {pluralize('Planners', planners.length)} <i>"{planners.join('", "')}"</i>, Used by permission of John
            Wiley & Sons, Inc.
          </p>

          <p>
            <b>Disclaimer: </b>
            The Wiley Practice Planners Datasets are designed to provide accurate and authoritative information in regard to the subject
            matter covered. They are licensed with the understanding that the publisher is not engaged in rendering professional services.
            If legal, accounting, medical, psychological, or other expert assistance is required, the services of a competent professional
            person should be sought. THE PRACTICE PLANNER DATASETS AND ALL INFORMATION THEREIN ARE PROVIDED "AS IS" AND EXPRESS AND IMPLIED
            WARRANTIES AND REPRESENTATIONS OF ANY KIND, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR
            PURPOSE, OR QUALITY, ACCURACY, COMPLETENESS AND/OR SUITABILITY ARE DISCLAIMED.
          </p>
        </div>
      </Main>

      <Footer>
        <Button label="Import Content" glyph="check" type="primary" color="green" onClick={save} isLoading={isLoading} />
      </Footer>
    </>
  )
}

const styles = {
  copyright: {
    color: COLORS.textMuted,
    fontSize: '0.9rem',
  },

  inlineStatus: {
    marginRight: '0.5rem',
  },

  blockStatus: {
    margin: '0.5rem 0',
  },

  checkboxGroup: {
    paddingBottom: '1rem',
  },

  objectiveTitle: {
    fontWeight: 400,
  },

  interventionsList: {
    paddingLeft: '3rem !important',
    paddingBottom: '0.75rem',

    li: {
      marginTop: '0.25rem',
    },
  },
}

export default Review
