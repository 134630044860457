import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../utils/constants'
import State from '../../../../components/State'

import { Box } from './Box'

export const Image = React.forwardRef((props: any, ref) => {
  const { isEditable, children, className, element, hoverElement } = props

  if (!element || !element.config) return null

  const { image_url, image_description, image_max_width = 100, image_max_width_type = 'percentage' } = element.config

  const isEmpty = !image_url

  const rootClasses = clsx('IMAGE', isEmpty && 'is-empty', isEditable && 'is-editable', className)

  return (
    <Box ref={ref} element={element} className={rootClasses} hoverElement={hoverElement} css={STYLES.root}>
      {image_url && (
        <img
          src={image_url}
          alt={image_description || 'Image'}
          css={STYLES.image}
          style={{
            maxWidth: `${image_max_width}${WIDTH_VALUE_TYPES[image_max_width_type]}`,
          }}
        />
      )}

      {children}

      {isEditable && !image_url && <State isEmpty glyph="photo" title="Image" emptyDescription="Enter an image URL" />}
    </Box>
  )
})

const WIDTH_VALUE_TYPES = {
  percentage: '%',
  pixels: 'px',
}

const STYLES = {
  root: {
    '&.is-empty.is-editable': {
      minHeight: 100,
      borderRadius: 4,
      position: 'relative',
    },
  },

  image: {
    display: 'block',
    width: '100%',
    margin: '0 auto',

    '&::before': {
      content: '"🏞 Broken Image URL"',
      width: '100%',
      height: '100%',
      backgroundColor: '#fff',
      borderRadius: '5px',
      position: 'absolute',
      top: '50%',
      left: '-2px',
      transform: 'translateY(-50%)',

      fontSize: '0.88rem',
      color: COLORS.textMuted,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}
