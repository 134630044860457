import React from 'react'
import clsx from 'clsx'

import { usDate, usDateTime } from '../utils/functions'

import CardLink from './CardLink'
import CardMeta from './CardMeta'
import CardTitle from './CardTitle'
import Flex from './Flex'
import Status from './Status'
import TaskCheckButton from './TaskCheckButton'
import TaskStatus from './Statuses/TaskStatus'
import Text from './Typography/Text'

export type Props = {
  testKey: string
  isActive: boolean
  isCompleted: boolean

  hideClients?: boolean
  loading: boolean
  onClick?: any
  status: string
  task: any
  timezone: any
  to?: any
  toggleTask: Function
}

const STATUS_COLORS: any = {
  completed: 'green',
  due_today: 'orange',
  to_do: 'blue',
  overdue: 'red',
}

const TaskCardLink = (props: Props) => {
  const { testKey, isActive, isCompleted, hideClients, loading, status, task, timezone, to, toggleTask, onClick } = props

  const classNames = clsx({
    'is-active': isActive,
  })

  return (
    <CardLink
      className={classNames}
      activeColor={STATUS_COLORS[status]}
      baseline="3.75rem"
      testKey={testKey}
      isActive={isActive}
      to={to}
      onClick={onClick}
      before={<TaskCheckButton color={STATUS_COLORS[status]} isCompleted={isCompleted} isLoading={loading} onClick={toggleTask} />}
    >
      <CardTitle
        title={task?.title}
        after={
          <>
            <Status color={STATUS_COLORS[status]} label={status?.replace('_', ' ')} />
            <TaskStatus status={task?.category} />
          </>
        }
      />

      <CardMeta>
        {isCompleted ? (
          <>
            <Text glyph="user_neutral" label="Completed By: " muted={false} description={<Text description={task?.completed_by?.name} />} />
            <Text
              glyph="time"
              label="Completed At: "
              muted={false}
              description={<Text description={usDateTime(task?.completed_at, timezone)} />}
            />
          </>
        ) : (
          <>
            <Text
              glyph="date"
              label="Last Edited: "
              muted={false}
              description={<Text description={usDate(task?.updated_at, timezone)} />}
            />
            <Text glyph="date" label="Due: " muted={false} description={<Text description={usDate(task?.due_at, timezone)} />} />
          </>
        )}

        {!hideClients && task?.residents?.length > 0 && (
          <Flex gap={8}>
            <Text label="Clients: " glyph="user_neutral" muted={false} />
            {task?.residents?.map((item) => (
              <Text key={item?.id} avatar={item?.avatar} description={item?.name} />
            ))}
          </Flex>
        )}

        {task?.employees?.length > 0 && (
          <Flex gap={8}>
            <Text label="Staff: " glyph="user_group" muted={false} />
            {task?.employees?.map((item) => (
              <Text key={item?.id} avatar={item?.avatar} description={item?.name} />
            ))}
          </Flex>
        )}

        {task?.house && (
          <Text
            label="Location: "
            glyph="properties"
            muted={false}
            description={<Text avatar={task?.house?.avatar} description={task?.house?.name} />}
          />
        )}

        {task?.organization && (
          <Text
            label="Organization: "
            glyph="organizations"
            muted={false}
            description={<Text avatar={task?.organization?.avatar} description={task?.organization?.name} />}
          />
        )}

        {task?.subtasks.length > 0 && (
          <Text
            label="Tasks: "
            glyph="tick"
            glyphColor="textMuted"
            muted={false}
            description={`${task.subtasks_completed_no} / ${task.subtasks_no} completed`}
          />
        )}
      </CardMeta>
    </CardLink>
  )
}

export default TaskCardLink
