import React from 'react'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

import { FILTERS } from '../../../../constructs/Filters/config'

import ServiceEpisodeStatus from '../../../Statuses/ServiceEpisodeStatus'
import ServiceEpisodeTypeStatus from '../../../Statuses/ServiceEpisodeTypeStatus'

import { SERVICE_EPISODE_LOC_CATEGORIES, SERVICE_EPISODE_LOC_SUBCATEGORIES } from '../../../../utils/constants'

export const ActiveServiceEpisodesTable = ({ onSelect, dependentValue, includeColumns, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-levels-of-care-service-episodes', dependentValue],
    endpoint: `/residents/${dependentValue}/service_episodes`,
    params: {
      status: 'active',
    },
    localStorageKey: 'selector_levels_of_care_service_episodes_v1',
  })

  const defaultColumns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.name} avatar={data.avatar} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'Status',
        id: 'status',
        model: 'status',
        formatValue: ({ value }: any) => <ServiceEpisodeStatus status={value} />,
      },
      {
        title: 'Episode Type',
        id: 'category',
        model: 'category',
        formatValue: ({ value }: any) => <ServiceEpisodeTypeStatus status={value} />,
      },
      {
        title: 'Level of Care Category',
        id: 'loc_category',
        model: 'loc_category',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return SERVICE_EPISODE_LOC_CATEGORIES[value]
        },
      },
      {
        title: 'Level of Care',
        id: 'loc_subcategory',
        model: 'loc_subcategory',
        formatValue: ({ data, value }: any) => {
          if (!value) return null

          return SERVICE_EPISODE_LOC_SUBCATEGORIES[data.loc_category][value]
        },
      },
      {
        title: 'Episode From',
        id: 'started_at',
        model: 'started_at',
        type: 'date_time',
      },
      {
        title: 'Episode Until',
        id: 'ended_at',
        model: 'ended_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated At',
        id: 'updated_at',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Created At',
        id: 'created_at',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        id: 'author',
        model: 'author',
        type: 'profile',
      },
    ],
    [],
  )

  const columns = React.useMemo(() => {
    if (!includeColumns) return defaultColumns

    return defaultColumns.filter((column) => {
      return includeColumns.includes(column.id) || includeColumns.includes(column.model)
    })
  }, [includeColumns])

  return (
    <DataTable
      {...tableProps}
      {...rest}
      icon="service_episodes"
      title="Active Service Episodes"
      columns={columns}
      filtersConfig={FILTERS.organization_contacts}
    />
  )
}
