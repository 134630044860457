import React from 'react'

import { COLORS } from '../../theme'
import { opacify } from 'polished'

import Flex from '../Flex'
import Button from '../Button'
import Card from '../Card'
import CardContent from '../CardContent'
import CardHeader from '../CardHeader'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'
import Markup from '../Markup'
import DataFormStatus from '../Statuses/DataFormStatus'
import SignaturePad from '../Forms/SignaturePad'
import SignatureDialog from '../Dialogs/SignatureDialog'
import SignatureView from '../SignatureView'
import SummonOverlay from '../SummonOverlay'
import AddendumOverlay from '../../constructs/Overlays/AddendumOverlay'
import DeleteDialog from '../Dialogs/DeleteDialog'

import Text from '../Typography/Text'

import { usDate, usTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { apiUpdate, apiDelete } from '../../modules/api'

const AddendumCard = (props: any) => {
  const { data, onUpdate, variant } = props
  const { isPortal, timezone, user } = useSettings()

  const isDraft = data.status?.includes?.('draft')
  const isPendingReview = data?.status?.includes('pending_review')
  const isSignedOff = data.status === 'signed_off'

  const isAuthor = user?.id === data?.author?.id
  const isSupervisor = props.isSupervisor || user?.id === data?.supervisor?.id

  const deleteAddendum = async () => {
    try {
      await apiDelete({
        url: `/addendums/${data.id}`,
        notify: false,
      })

      if (onUpdate) onUpdate()
    } catch (error) {
      console.error(error)
    }
  }

  const signOff = async (signature: any) => {
    try {
      await apiUpdate({
        name: 'addendums',
        url: `/addendums/${data.id}`,
        params: {
          [signature.model]: signature.value,
        },
      })

      if (onUpdate) onUpdate()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Card variant={variant}>
        <CardHeader
          paddingY="0.65rem"
          css={{
            borderRadius: 0,
            background: COLORS.white,
            boxShadow: `0 0 0 1px ${opacify(-0.05, COLORS.divider)}`,
          }}
          after={
            !isPortal &&
            !isSignedOff && (
              <Flex>
                <SummonOverlay overlay={<AddendumOverlay addendum={data} onUpdate={onUpdate} />}>
                  <Button label="Edit" glyph="edit" type="link" />
                </SummonOverlay>

                <DeleteDialog
                  title={`Delete Addendum?`}
                  message={`Are you sure you want to delete this Addendum? This action cannot be undone.`}
                  onYes={deleteAddendum}
                >
                  <Button label="Delete" glyph="delete" color="red" type="link" />
                </DeleteDialog>
              </Flex>
            )
          }
        >
          <CardTitle title={data.title} />

          <CardMeta>
            <DataFormStatus status={data?.status} />
            <Text description={data?.author?.name} />
            <Text glyph="date" description={usDate(data?.created_at, timezone)} />
            <Text glyph="time" description={usTime(data?.created_at, timezone)} />
          </CardMeta>
        </CardHeader>

        <CardHeader
          after={
            !isPortal && (
              <>
                {isDraft && (
                  <>
                    {isSupervisor && (
                      <SignatureDialog
                        model="supervisor"
                        title="Sign Off as Supervisor"
                        yesLabel="Apply Signature & Sign Off"
                        onYes={signOff}
                      >
                        <Button size={200} label="Sign Off as Supervisor…" glyph="signature" type="primary" color="green" />
                      </SignatureDialog>
                    )}

                    {!isSupervisor && isAuthor && (
                      <SignatureDialog
                        model="author"
                        title="Sign & Send for Review"
                        yesLabel="Apply Signature & Send for Review"
                        onYes={signOff}
                      >
                        <Button size={200} label="Sign & Send for Review…" glyph="signature" type="primary" />
                      </SignatureDialog>
                    )}
                  </>
                )}

                {isPendingReview && isSupervisor && (
                  <SignatureDialog model="supervisor" title="Sign Off as Supervisor" yesLabel="Apply Signature & Sign Off" onYes={signOff}>
                    <Button size={200} label="Sign Off as Supervisor…" glyph="signature" type="primary" color="green" />
                  </SignatureDialog>
                )}
              </>
            )
          }
        >
          <CardMeta>
            <Text label="Supervisor: " description={data?.supervisor?.name} />
          </CardMeta>
        </CardHeader>

        <CardContent>
          {data?.referencing_field && <Text label="Field / Section: " description={data?.referencing_field} className="mb-2" />}

          <Markup value={data?.notes} />
        </CardContent>

        {(data?.author_signature || data?.supervisor_signature) && (
          <CardContent>
            {data?.author_signature && (
              <SignatureView
                label="Author Signature"
                value={data.author_signature}
                signedBy={data.author}
                signedAt={data.author_signed_at}
              />
            )}

            {data?.supervisor_signature && (
              <SignatureView
                label="Supervisor Signature"
                value={data.supervisor_signature}
                signedBy={data.supervisor}
                signedAt={data.supervisor_signed_at}
              />
            )}
          </CardContent>
        )}
      </Card>
    </>
  )
}

const styles = {
  border: `2px solid ${COLORS.divider}`,
  borderRadius: 7,
  // padding: '0.75rem',
}

export default AddendumCard
