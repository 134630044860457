import React from 'react'
import size from 'lodash/size'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { COLORS } from '../theme'
import { ROLES } from '../utils/constants'

import AppNavDropdown from './AppNavDropdown'
import DropdownItem from './DropdownItem'
import Flex from './Flex'
import Icon from './Icon'
import Search from './Search'
import State from './State'

import { useTenantSwitcherOverlay } from './TenantSwitcherOverlay'

type Props = {
  currentTenant: any
  tenants: any[]
}

const TenantSwitcher = ({ currentTenant, tenants }: Props) => {
  const { open } = useTenantSwitcherOverlay()

  const history = useHistory()
  const nonPortalTenants = tenants.filter?.((tenant) => tenant && tenant.type !== 'resident')
  const [search, setSearch] = React.useState('')
  const [searchResults, setSearchResults] = React.useState(nonPortalTenants)
  const user = useSelector((state: any) => state.me?.user)

  const isMobile = !!window.ReactNativeWebView

  // don't render if there is only one tenant
  if (size(tenants) <= 1) return null

  const onSelect = (tenant: string) => {
    // reload app
    if (!!window.ReactNativeWebView) localStorage.setItem('bh.stn', tenant)
    else sessionStorage.setItem('bh.stn', tenant)

    history.push('/dashboard')

    // reload app
    window.location.reload()
  }

  const showSearch = size(tenants) >= 5
  const hasResults = size(searchResults) >= 1
  const isBehave = user?.type === 'bh_employee'

  React.useEffect(() => {
    if (!search) return setSearchResults(tenants)

    const results = tenants?.filter((o) => o.name?.toLowerCase().includes(search.toLowerCase()))
    setSearchResults(results)
  }, [search])

  if (isMobile) {
    return (
      <div
        css={styles.bhSwitcher}
        onClick={() => {
          window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'show_tenants', tenants: tenants }))
        }}
      >
        <Flex centerY centerX gap="0.5rem">
          <Icon icon="enterprise" size={16} />
          <div css={styles.tenantName}>{currentTenant?.name}</div>
        </Flex>
      </div>
    )
  }

  if (isBehave) {
    return (
      <div css={styles.bhSwitcher} onClick={open}>
        <Flex centerY centerX gap="0.5rem">
          <Icon icon="enterprise" size={16} />
          <div css={styles.tenantName}>{currentTenant?.name}</div>
        </Flex>
      </div>
    )
  }

  return (
    <AppNavDropdown icon="enterprise" label={currentTenant?.name}>
      {showSearch && (
        <div css={styles.search}>
          <Search onChange={setSearch} />
        </div>
      )}

      {hasResults ? (
        searchResults.map(({ name, position, tenant, plan, plan_status }) => (
          <DropdownItem
            key={tenant}
            isActive={tenant === currentTenant?.subdomain}
            onClick={() => onSelect(tenant)}
            label={
              <>
                <span css={styles.name}>
                  {name} {isBehave && `(${tenant})`}
                </span>
                {isBehave ? (
                  <span css={styles.subdomain}>
                    {plan_status} Plan: <b>{plan}</b>
                  </span>
                ) : (
                  <span css={styles.subdomain}>
                    Login as <b>{ROLES[position]}</b>
                  </span>
                )}
              </>
            }
          />
        ))
      ) : (
        <State isEmpty icon="enterprise" emptyDescription="No facilities found" css={{ minHeight: 150 }} />
      )}
    </AppNavDropdown>
  )
}

const styles = {
  bhSwitcher: {
    padding: '0.5rem 0.2rem',
    color: COLORS.link,
    background: COLORS.hover,
    borderBottom: `1px solid ${COLORS.divider}`,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,

    '&:hover': {
      background: COLORS.white,
    },
  },

  search: {
    position: 'sticky',
    top: 0,
    padding: '0.5rem 0.5rem 0',
    background: 'white',
    zIndex: 1,
  },

  name: {
    display: 'block',
    maxWidth: 220,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  subdomain: {
    display: 'block',
    maxWidth: 220,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: COLORS.textMuted,
    fontSize: '0.9em',
    fontWeight: 400,
    textTransform: 'capitalize',
  },

  tenantName: {
    '@media (min-width: 600px) and (max-width: 1023px)': {
      display: 'none',
    },
  },
}

export default TenantSwitcher
