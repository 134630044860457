import React from 'react'

import { Card, Page, Grid, TipAlert } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import BedsVacancyAnalyticsTable from '@behavehealth/components/Tables/Reports/BedsVacancyAnalyticsTable'

const BedsVacancyAnalytics = () => {
  return (
    <Page feature="bed_management" title="Beds Vacancy Analytics">
      <Grid>
        <Card>
          <BedsVacancyAnalyticsTable useAutoPageSize />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(BedsVacancyAnalytics)
