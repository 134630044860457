import React from 'react'

import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import EmailInput from '../../components/Forms/EmailInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import InviteStatus from '../../components/Statuses/InviteStatus'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'

// from the APP side
import { CONFIG } from '../../utils/globals'
import { useCreate, useUpdate } from '../../hooks/useNewAPI'

import EmployeeNotificationLogsTable from './EmployeeNotificationLogsTable'

const RootEmployeeInviteOverlay = (props: any) => {
  const { id, close, data, form, initialModel, isOverlayLoading, onValidationUpdate, isDeleting, deleteRecord } = useOverlay({
    name: 'invite',
    endpoint: '/invites',
    invalidate: 'invites',
    options: props,
    invalidateKeys: props.invalidateKeys,
  })

  const { mutateAsync: asyncResend, isLoading: isResending }: any = useCreate({
    name: ['invite', id],
    url: `/invites/${id}/reprocess`,
    invalidate: 'invites',
    invalidateKeys: props.invalidateKeys,
  })

  const { mutateAsync: revokeRecord, isLoading: isRevoking }: any = useUpdate({
    name: ['invite', id],
    url: `/invites/${id}`,
    invalidate: 'invites',
    invalidateKeys: props.invalidateKeys,
  })

  const { timezone, isOwner, isBehave } = useSettings()

  const resendInvite = async () => {
    try {
      await asyncResend()
    } catch (error) {
      console.debug(error)
    }
  }

  const revokeInvite = async () => {
    try {
      await revokeRecord({ status: 'cancelled' })
    } catch (error) {
      console.debug(error)
    }
  }

  if (isOverlayLoading) return <OverlayLoader position="right" />

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon={ICONS.employees} title="Staff Invite" subtitle={<InviteStatus status={data?.status} />} />

      <Overlay.Content>
        <Form isEditable={false} getForm={form} timezone={timezone} initialModel={initialModel} onValidationUpdate={onValidationUpdate}>
          <Section>
            <FormSection>
              <Input
                label="Full Name"
                model="sent_to_details.full_name"
                quickLinksVendors={['hubspot', 'google', 'gmail', 'google_calendar', 'helpscout']}
              />

              <ObjectSelector
                icon="permissions"
                label="Staff Permission Template"
                type="permission_templates"
                model="settings.permissions_template"
                description="Inherit the permissions from the selected template. You can customize this under the Staff profile anytime"
                selectTitle={(data: any) => data?.name}
                selectDescription={() => null}
                validations={{
                  presence: {
                    message: 'Please select a Permission Template',
                  },
                }}
              />

              <Select
                fullWidth
                label="Staff Role"
                model="settings.position"
                defaultValue="house_manager"
                validations={{
                  presence: {
                    message: 'Please select a staff role',
                  },
                }}
              >
                {CONFIG.active_employee_roles.map(({ text, value }) => {
                  if (value === 'owner' && !isBehave && !isOwner) return null

                  return <Option key={value} label={text} value={value} />
                })}
              </Select>

              <EmailInput label="Email" model="sent_to_details.email" />
              <PhoneInput label="Cell Number" model="sent_to_details.phone_no" />

              <DateTimeInput label="Date Invited" model="created_at" />
            </FormSection>
          </Section>

          <Section>
            <FormSection heading="Notifications">
              <EmployeeNotificationLogsTable data={data?.notification_logs} />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Grid gap="1rem">
          {isBehave && (
            <Alert type="negative">
              If User cannot find email invitation, go to the Company EHR Page, on the User Invites sub-page, select the user, click re-send
              invite.
            </Alert>
          )}

          {data?.status !== 'active' && (
            <ConfirmDialog
              title="Re-send Invite?"
              message="Are you sure you want to re-send the Invite?"
              yesColor="green"
              onYes={resendInvite}
            >
              <Button
                label="Re-send Invite"
                glyph="reset"
                color="blue"
                isLoading={isResending}
                permission="user_invites.actions.resend_invite"
              />
            </ConfirmDialog>
          )}

          {(data?.status === 'pending' || data?.status === 'declined') && (
            <ConfirmDialog
              title="Revoke Invite?"
              message="Are you sure you want to revoke this invite?"
              yesColor="gray"
              onYes={revokeInvite}
            >
              <Button
                label="Revoke"
                glyph="decline"
                color="gray"
                fullWidth
                isLoading={isRevoking}
                permission="user_invites.actions.revoke_invite"
              />
            </ConfirmDialog>
          )}

          <DeleteDialog
            title="Delete Invite?"
            message="Are you sure you want to delete this invite? This action cannot be undone."
            onYes={deleteRecord}
          >
            <Button
              label="Delete"
              type="default"
              glyph="delete"
              color="red"
              fullWidth
              isLoading={isDeleting}
              permission="user_invites.actions.delete_invite"
            />
          </DeleteDialog>
        </Grid>
      </Overlay.Footer>
    </Overlay>
  )
}

export const EmployeeInviteOverlay = withOverlayError(RootEmployeeInviteOverlay)
