import React from 'react'

import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const SCORING = {
  5: 'less likely to be associated with significant symptoms of mania',
  20: 'high probability of a manic or hypomanic condition',
}

const RootASRMOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="ASRM Altman Self-Rating Mania Scale"
      category="clinical_measurement"
      subcategory="asrm"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Introduction"
        headingType="h2"
        scrollview={{
          id: 'introduction',
          name: 'Introduction',
        }}
      >
        <p>The ASRM is a 5-item self rating mania scale, designed to assess the presence and/or severity of manic symptoms.</p>
        <p>
          The ASRM may be used in an inpatient or outpatient setting to screen for the presence of and/or severity of manic symptoms for
          clinical or research purposes.
        </p>
        <p>
          Because it is compatible with DSM-IV criteria, and correlates significantly with Clinician- Administered Rating Scale for Mania
          (CARS-M), Young Mania Rating Scale (YMRS), it can be used effectively as a screening instrument to facilitate diagnostic
          assessment in patients with hypomanic symptoms.
        </p>

        <p>
          <b>Clinical Utility</b>
        </p>
        <p>
          In outpatient settings the ASRM may be used as a psycho-educational tool to help patients recognize and monitor their own
          symptoms.
        </p>
        <p>It may be used reliably as a self-report measure of efficacy for patients receiving clinical treatment.</p>
        <p>It may be used in combination with self-rating depression scales to assess mixed states of mania and depression.</p>
      </Section>

      <Divider />

      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <ol>
          <li>There are 5 statements groups on this questionnaire: read each group of statements carefully.</li>
          <li>Choose the one statement in each group that best describes the way you have been feeling for the past week.</li>
          <li>Check the box next to the number/statement selected.</li>
          <li>
            Please note: The word "occasionally" when used here means once or twice; "often" means several times or more and "frequently"
            means most of the time.
          </li>
        </ol>
      </Section>

      <Divider />

      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <Questionnaire />
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <p>
          (1) Altman EG, Hedeker D, Peterson JL, Davis JM. The Altman self-rating mania scale. Society of Biological Psychiatry 1997;
          42:948-955.
        </p>
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState({
    question_1: 0,
    question_2: 0,
    question_3: 0,
    question_4: 0,
    question_5: 0,
  })

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <RadioGroup
          label="Question 1"
          model="data.questionnaire.question_1"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, question_1: state.value }))}
        >
          <Radio label="0 = I do not feel happier or more cheerful than usual." value={0} />
          <Radio label="1 = I occasionally feel happier or more cheerful than usual." value={1} />
          <Radio label="2 = I often feel happier or more cheerful than usual." value={2} />
          <Radio label="3 = I feel happier or more cheerful than usual most of the time." value={3} />
          <Radio label="4 = I feel happier or more cheerful than usual all of the time." value={4} />
        </RadioGroup>

        <RadioGroup
          label="Question 2"
          model="data.questionnaire.question_2"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, question_2: state.value }))}
        >
          <Radio label="0 = I do not feel more self-confident than usual." value={0} />
          <Radio label="1 = I occasionally feel more self-confident than usual." value={1} />
          <Radio label="2 = I often feel more self-confident than usual." value={2} />
          <Radio label="3 = I feel more self-confident than usual." value={3} />
          <Radio label="4 = I feel extremely self-confident all of the time." value={4} />
        </RadioGroup>

        <RadioGroup
          label="Question 3"
          model="data.questionnaire.question_3"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, question_3: state.value }))}
        >
          <Radio label="0 = I do not need less sleep than usual." value={0} />
          <Radio label="1 = I occasionally need less sleep than usual." value={1} />
          <Radio label="2 = I often need less sleep than usual." value={2} />
          <Radio label="3 = I frequently need less sleep than usual." value={3} />
          <Radio label="4 = I can go all day and night without any sleep and still not feel tired." value={4} />
        </RadioGroup>

        <RadioGroup
          label="Question 4"
          model="data.questionnaire.question_4"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, question_4: state.value }))}
        >
          <Radio label="0 = I do not talk more than usual" value={0} />
          <Radio label="1 = I occasionally talk more than usual." value={1} />
          <Radio label="2 = I often talk more than usual." value={2} />
          <Radio label="3 = I frequently talk more than usual." value={3} />
          <Radio label="4 = I talk constantly and cannot be interrupted" value={4} />
        </RadioGroup>

        <RadioGroup
          label="Question 5"
          model="data.questionnaire.question_5"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, question_5: state.value }))}
        >
          <Radio label="0 = I have not been more active (either socially, sexually, at work, home or school) than usual." value={0} />
          <Radio label="1 = I have occasionally been more active than usual." value={1} />
          <Radio label="2 = I have often been more active than usual" value={2} />
          <Radio label="3 = I have frequently been more active than usual." value={3} />
          <Radio label="4 = I am constantly active or on the go all the time." value={4} />
        </RadioGroup>
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Score title="Final Score" finalScore={finalScore} scoring={SCORING}>
            <p>
              <b>Scoring</b>
            </p>

            <ol>
              <li>
                <p>Sum items 1-5</p>

                <ul>
                  <li>
                    A cutoff score of 6 or higher indicates a high probability of a manic or hypomanic condition (based on a sensitivity
                    rating of 85.5% and a specificity rating of 87.3%).
                  </li>
                  <li>
                    A score of 6 or higher may indicate a need for treatment and/or further diagnostic workup to confirm a diagnosis of
                    mania or hypomania.
                  </li>
                  <li>A score of 5 or lower is less likely to be associated with significant symptoms of mania.</li>
                </ul>
              </li>

              <li>
                As a self-report measure of clinical efficacy, items 1-5 should be summed to give a total score, which then may be compared
                to subsequent total scores during and after treatment.
              </li>
            </ol>

            <p>
              <b>Psychometric Properties</b>
            </p>
            <ul>
              <li>Specificity of 85.5</li>
              <li>Sensitivity of 87.3 (1)</li>
            </ul>

            <Divider />
          </Score>
        </>
      )}
    </>
  )
}

export const ASRMOverlay = withOverlayError(RootASRMOverlay)
