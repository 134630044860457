import React from 'react'

import { COLORS } from '../../../theme'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import Flex from '../../../components/Flex'

import Input from '../../../components/Forms/Input'
import URLInput from '../../../components/Forms/URLInput'
import Field from '../../../components/Forms/Field'
import Value from '../../../components/Value'

import PayerNetworkStatus from '../../../components/Statuses/PayerNetworkStatus'
import InsurancePayerSupport from './InsurancePayerSupport'

const ClaimPayerDetails = ({ payer }: any) => (
  <Card>
    <CardHeader>
      <CardTitle title="Payer Details" css={styles.cardTitle} />
    </CardHeader>

    <CardContent css={styles.cardContent}>
      <Flex gap="1rem">
        <Input isEditable={false} label="Name" value={payer?.name} />
        <Field label="Network Status">
          <Value value={payer ? <PayerNetworkStatus status={payer.network_status} /> : '–'} />
        </Field>
        <Input isEditable={false} label="PAYER ID" value={payer?.name} />
        {payer && <InsurancePayerSupport className="!mt-4" payer={payer} />}
      </Flex>
    </CardContent>
  </Card>
)

const styles = {
  dataList: {
    fontSize: '0.95rem',
    flex: '1 1 300px',
  },

  dataListTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    color: COLORS.textMuted,
  },

  cardTitle: {
    fontSize: '1rem',
  },

  cardContent: {
    height: '100%',
  },
}

export default ClaimPayerDetails
