import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { countWord } from '../../../utils/functions'
import { useCreate, useGet } from '../../../hooks/useNewAPI'

import Button from '../../Button'
import Card from '../../Card'
import Overlay from '../../Overlay'
import Section from '../../Section'
import SummonOverlay from '../../SummonOverlay'
import Tabs from '../../Tabs'

import TemplateLegalTooltip from '../TemplateLegalTooltip'
import TodoInternalTemplatesTable from '../tables/internal/TodoInternalTemplatesTable'
import TodoTemplateOverlay from './TodoTemplateOverlay'

const HIDDEN_COLUMNS = ['global_tags', 'smart_flags']

const TodoTemplatesImportOverlay = () => {
  const history = useHistory()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [preview, setPreview]: any = React.useState(null)
  const [selectedType, setSelectedType]: any = React.useState('public_template')

  const user = useSelector((state: any) => state.me?.user)
  const isBehave = user?.type === 'bh_employee'

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const filters = {
    category: 'todo',
    status: 'active',
    level: selectedType,
  }

  const { data: templates, isLoading: isLoadingTemplates }: any = useGet({
    name: ['internal-todo-templates', filters],
    url: `/internal_templates`,
    params: filters,
    options: { enabled: !!selectedType },
  })

  const { mutate, isLoading: isImporting } = useCreate({
    name: 'import-templates',
    url: '/internal_templates/import',
    invalidate: 'todo-templates',
    onSuccess: () => {
      history.goBack()
    },
  })

  const importTemplates = () => {
    const ids = selectedRows.map((o: any) => o.original.id)
    mutate({ ids: ids, category: 'todo' })
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import To-Do Templates" titleAside={<TemplateLegalTooltip />} />

      <Overlay.Content>
        {preview && (
          <SummonOverlay
            isOpen={!!preview}
            onClose={() => setPreview(null)}
            overlay={<TodoTemplateOverlay allowEditing={false} dataID={preview.id} initialData={preview.data} />}
          />
        )}

        <Section margin="0 0 1.5rem 0">
          {isBehave && (
            <Tabs onChange={setSelectedType} defaultTab="public_template">
              <Tabs.List>
                <Tabs.Item label="Public Templates" name="public_template" />
                <Tabs.Item glyph="behave_health" label="Behave Templates" name="behave_template" />
              </Tabs.List>
            </Tabs>
          )}

          <Card className="!mt-4">
            <TodoInternalTemplatesTable
              title="To-Do Templates"
              data={templates}
              isLoading={isLoadingTemplates}
              onSelect={setSelectedRows}
              onClick={setPreview}
              hiddenColumns={!isBehave && HIDDEN_COLUMNS}
            />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Templates', selectedRowsCount)}` : 'Select Templates to Import'}
          isDisabled={!hasSelectedRows}
          isLoading={isImporting}
          type="primary"
          color="green"
          onClick={importTemplates}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default TodoTemplatesImportOverlay
