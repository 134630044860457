import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { invalidateQueries } from '../../hooks/useNewAPI'
import useAPI from '../../hooks/useAPI'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import LocationsImportTable from '../../components/Tables/LocationsImportTable'

import { apiCreate, apiGet } from '../../modules/api'

export const SubLocationsImportOverlay = () => {
  const navigate = useNavigate()
  const params = useParams()

  const resourceID = params?.resource_id

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [loading, setLoading]: any = React.useState(false)

  const data = useSelector((state: any) => state.data?.import_locations?.data)
  const dataLoading = useSelector((state: any) => state.data?.import_locations?.loading)
  const isEmpty = size(data) === 0

  useAPI('import_locations', `/houses?exclude_parent_id=${resourceID}`)

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    setLoading(true)

    try {
      const ids = selectedRows.map((o: any) => o.original.id)
      await apiCreate({
        url: '/houses/import',
        params: {
          ids: ids,
          parent_id: resourceID,
        },
      })
      await apiGet({ name: 'sub_locations', url: `/houses/${resourceID}/houses` })

      invalidateQueries(null, 'locations')

      navigate(-1)
    } catch (errors) {
      console.debug(errors)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import Locations" />

      <Overlay.Content>
        <Section>
          <Card className="!mt-4">
            <LocationsImportTable title="Locations" data={data} isLoading={isEmpty && dataLoading} onSelect={setSelectedRows} />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Locations', selectedRowsCount)}` : 'Select Locations to Import'}
          isDisabled={!hasSelectedRows}
          isLoading={loading}
          type="primary"
          color="green"
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
