import React from 'react'

import withSettings from '../../../hocs/withSettings'

import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Workflow from '../../../components/Workflow/Workflow'

import { Text } from '../../../components/Typography'

const StaffAccess = ({ data, setData, setStatus, firstName, timezone }: any) => {
  const form = React.useRef()
  const [valid, setValid] = React.useState()

  const { goNext }: any = React.useContext(Workflow.Context)

  const handleUpdate = () => {
    setData(form.current?.getFormValue())
    valid ? setStatus('completed') : setStatus('todo')
  }

  return (
    <Form
      getForm={form}
      initialModel={data}
      onUpdate={handleUpdate}
      onValidationUpdate={setValid}
      timezone={timezone}
      defaultDebounce={300}
    >
      <FormSection>
        <Text>{`Remove ${firstName} from all Staff Access Lists they are part of`}</Text>

        <CheckboxGroup>
          <Checkbox
            testKey="remove_staff_access_checkbox"
            label="Remove from all Staff Access Lists"
            model="remove_access_controls"
            value={true}
          />
        </CheckboxGroup>

        <Workflow.Buttons>
          <Workflow.ContinueButton
            onClick={() => {
              handleUpdate()
              goNext()
            }}
            isDisabled={!valid}
          />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default withSettings(StaffAccess)
