import React from 'react'
import { useSelector } from 'react-redux'

import { COLORS } from '@behavehealth/theme'
import { Tenant } from '@behavehealth/declarations'
import useStore from '@behavehealth/modules/store'

import { Glyph, Accordion, Form, FormSection, Grid, Page, CheckboxGroup, Checkbox } from '@behavehealth/components'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { CLINICAL_MEASURES_TITLES } from '@behavehealth/utils/constants'
import { apiUpdate } from '@behavehealth/modules/api'

const MeasureSettings = () => {
  const record: Tenant = useSelector((state: any) => state.me?.tenant)

  const updateStore = useStore((state) => state.update)

  return (
    <Page breakpoint={3} title="Measure Settings" feature="measure_settings">
      <Grid gap="1rem">
        <div>
          <Accordion
            isOpen
            activateEditMode
            initialModel={record}
            graphic={<Glyph glyph="settings" color={COLORS.gray} size={20} />}
            title="Measure Settings"
            description="Set the available forms for your organization"
            editPermission="settings.form_settings.edit"
            onSubmit={async (data) => {
              const response = await apiUpdate({
                name: 'tenant',
                url: '/me/tenant',
                params: { forms_settings: data.forms_settings },
                reducer: 'me',
              })

              if (response?.data?.data) {
                updateStore({ tenant: response?.data?.data })
              }
            }}
          >
            <Form>
              <FormSection layout="vertical" labelWidth={150}>
                <CheckboxGroup label="Outcome Measures" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
                  <Checkbox
                    label="Recovery Capital Scale"
                    icon="outcome_measures"
                    model="forms_settings.outcome_measures_recovery_capital_scale"
                  />
                  <Checkbox
                    label="Multidimensional Inventory of Recovery Capital"
                    icon="outcome_measures"
                    model="forms_settings.outcome_measures_mirc"
                  />
                </CheckboxGroup>

                <CheckboxGroup label="Recovery Notes" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
                  <Checkbox
                    label="Recovery Capital Scale"
                    icon="recovery_coaching"
                    model="forms_settings.recovery_notes_recovery_capital_scale"
                  />
                  <Checkbox
                    label="Recovery Capital Plan"
                    icon="recovery_coaching"
                    model="forms_settings.recovery_notes_recovery_capital_plan"
                  />
                  <Checkbox
                    label="Recovery Capital Scale & Plan"
                    icon="recovery_coaching"
                    model="forms_settings.recovery_notes_recovery_capital_scale_and_plan"
                  />
                  <Checkbox
                    label="Multidimensional Inventory of Recovery Capital"
                    icon="recovery_coaching"
                    model="forms_settings.recovery_notes_mirc"
                  />
                </CheckboxGroup>

                <CheckboxGroup label="Clinical Measures" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
                  {Object.entries(CLINICAL_MEASURES_TITLES).map(([key, label]) => (
                    <Checkbox key={key} label={label} icon="clinical_measurements" model={`forms_settings.clinical_measures_${key}`} />
                  ))}
                </CheckboxGroup>
              </FormSection>
            </Form>
          </Accordion>
        </div>
      </Grid>
    </Page>
  )
}

export default withPageError(MeasureSettings)
