import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { copyToClipboard } from '../../utils/functions'
import { useDictation } from '../../hooks/useDictation'
import { useGet, useCreate, useUpdate, useDelete, getPrefix } from '../../hooks/useNewAPI'
import Notifications from '../../modules/notifications'

import { AppSidebarContent } from './AppSidebarContent'
import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

import Button from '../Button'
import Card from '../Card'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Flex from '../Flex'
import Form from '../Forms/Form'
import Grid from '../Grid'
import Icon from '../Icon'
import State from '../State'
import Loader from '../Loader'
import Textarea from '../Forms/Textarea'

const QuickTranscriptionsView: React.FC<any> = () => {
  const { data, isLoading }: any = useGet({
    name: ['quick-transcriptions'],
    url: `/quick_transcriptions`,
  })

  const { mutateAsync: createAsync, isLoading: isSaving }: any = useCreate({
    name: ['create-quick-transcription'],
    url: `/quick_transcriptions`,
    invalidate: ['quick-transcriptions'],
    onSuccess: (data: any, _variables: any, queryClient: any) => {
      if (!data?.data) return

      const prefix = getPrefix()

      queryClient.setQueryData([prefix, 'quick-transcriptions'], (oldData: any) => {
        if (!oldData?.data) return

        const newData = produce(oldData, (draft: any) => {
          draft.data.unshift(data?.data)
        })

        return newData
      })
    },
  })

  const {
    transcript,
    toggleListening,
    resetTranscript,
    didStartDictation,
    canStartDictation,
    isMicrophoneAvailable,
    isProcessing,
    isButtonDisabled,
  } = useDictation({
    onComplete: async (finalTranscript: any) => {
      if (finalTranscript) await createAsync({ content: finalTranscript })
    },
  })

  const isEmpty = size(data) === 0
  const isPendingPermission = !isMicrophoneAvailable

  return (
    <AppSidebarView>
      <AppSidebarHeader title="Voice Memos" icon="microphone" glyphColor={COLORS.red} />

      <AppSidebarContent>
        <div className="pt-1">
          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center cursor-pointer" onClick={toggleListening}>
              <button
                type="button"
                className={clsx('relative flex h-14 w-14 p-0 border-none bg-transparent cursor-pointer', isButtonDisabled && 'opacity-50')}
                disabled={isButtonDisabled || isProcessing || isSaving || isPendingPermission}
              >
                {didStartDictation && (
                  <span
                    className="absolute animate-ping inline-flex flex-grow-0 flex-shrink-0 h-full w-full rounded-full bg-red-200 opacity-75"
                    style={{ backgroundColor: tint(0.9, COLORS.red) }}
                  />
                )}

                <span
                  className={'relative inline-flex rounded-full h-14 w-14'}
                  style={{
                    backgroundColor: isPendingPermission
                      ? tint(0.8, COLORS.orange)
                      : isSaving
                      ? tint(0.8, COLORS.green)
                      : didStartDictation
                      ? tint(0.8, COLORS.red)
                      : tint(0.85, COLORS.green),
                  }}
                />

                <div className="absolute flex h-full w-full justify-center items-center">
                  <Icon icon={didStartDictation && !isSaving ? 'microphone_recording' : 'microphone'} size={32} className='"absolute' />
                </div>

                {(isProcessing || isSaving || isPendingPermission) && (
                  <Loader
                    className="absolute flex h-full w-full justify-center items-center"
                    speed={1000}
                    size="100%"
                    color={isPendingPermission ? COLORS.orange : isSaving ? COLORS.green : COLORS.green}
                  />
                )}
              </button>

              <div className="ml-2">
                <h3 style={{ color: isSaving ? COLORS.green : didStartDictation ? COLORS.red : COLORS.green }}>
                  {isPendingPermission
                    ? 'Allow Microphone Access'
                    : isSaving
                    ? 'Saving…'
                    : didStartDictation
                    ? 'Listening…'
                    : 'Start Recording'}
                </h3>
                <div style={{ color: COLORS.textMuted }}>{didStartDictation ? 'Click to stop dictation' : 'Click to start dictation'}</div>
              </div>
            </div>
          </div>

          {didStartDictation && (
            <div className="px-4 mt-4">
              <Transcript transcript={transcript} />
            </div>
          )}

          {isLoading || isEmpty ? (
            <State
              key="initial-state"
              title="Voice Memos"
              isLoading={isLoading}
              isEmpty={isEmpty}
              emptyDescription="No voice memos added yet. Click the microphone to start recording."
              glyph="microphone"
              glyphColor={COLORS.textStronglyMuted}
            />
          ) : (
            <div key="data-state" className="mt-6">
              <Grid gap="0.5rem">
                {data.map((memo: any) => (
                  <VoiceMemo key={memo.id} data={memo} />
                ))}
              </Grid>
            </div>
          )}
        </div>
      </AppSidebarContent>
    </AppSidebarView>
  )
}

const VoiceMemo = ({ data }: any) => {
  const [isEditing, setIsEditing] = React.useState(false)

  const form = React.useRef(null)

  const { mutateAsync: updateAsync, isLoading: isUpdating }: any = useUpdate({
    name: ['update-quick-transcription'],
    url: `/quick_transcriptions/${data.id}`,
    invalidate: ['quick-transcriptions'],
    onSuccess: (data: any, _variables: any, queryClient: any) => {
      if (!data?.data?.id) return

      const prefix = getPrefix()

      queryClient.setQueryData([prefix, 'quick-transcriptions'], (oldData: any) => {
        if (!oldData?.data) return

        const index = oldData.data.findIndex((o) => o.id === data.data.id)
        if (index === -1) return

        const newData = produce(oldData, (draft: any) => {
          draft.data[index] = data.data
        })

        return newData
      })
    },
  })

  const { mutateAsync: deleteAsync, isLoading: isDeleting }: any = useDelete({
    name: ['delete-quick-transcription'],
    url: `/quick_transcriptions/${data.id}`,
    invalidate: ['quick-transcriptions'],
    onSuccess: (_data: any, _variables: any, queryClient: any) => {
      const prefix = getPrefix()

      queryClient.setQueryData([prefix, 'quick-transcriptions'], (oldData: any) => {
        if (!oldData?.data) return

        const index = oldData.data.findIndex((o) => o.id === data.id)
        if (index === -1) return

        const newData = produce(oldData, (draft: any) => {
          draft.data.splice(index, 1)
        })

        return newData
      })
    },
  })

  const copy = () => {
    copyToClipboard(data.content)
    Notifications.send('Voice memo copied', 'positive')
  }

  const edit = () => {
    setIsEditing(true)
  }

  const cancel = () => {
    setIsEditing(false)
  }

  const handleUpdate = async () => {
    const formValue = form.current.getFormValue()
    await updateAsync(formValue)
    setIsEditing(false)
  }

  const handleDelete = async () => {
    await deleteAsync()
  }

  if (!data) return null

  return (
    <Card className="px-2 py-1">
      {isEditing ? (
        <Form key="edit-form" getForm={form} initialModel={{ content: data.content }}>
          <Textarea withHover={false} model="content" />
        </Form>
      ) : (
        <div>{data.content}</div>
      )}

      {isEditing ? (
        <div className="mt-2">
          <Flex gap="0.5rem" stretchChildrenX>
            <Button
              label="Save"
              glyph="tick_circle"
              size={100}
              onClick={handleUpdate}
              type="primary"
              color="green"
              isLoading={isUpdating}
            />

            <Button
              label="Cancel"
              glyph="tick_circle"
              size={100}
              onClick={cancel}
              display="inline-flex"
              className="!flex-grow-0"
              isDisabled={isUpdating}
            />
          </Flex>
        </div>
      ) : (
        <div className="flex justify-between mt-2">
          <Button label="Copy" glyph="copy" size={100} onClick={copy} type="minimal" display="inline-flex" />

          <div className="flex justify-between">
            <Button label="Edit" glyph="edit" size={100} onClick={edit} type="minimal" display="inline-flex" />

            <DeleteDialog
              title="Delete Voice Memo?"
              message="Are you sure you want to delete this Voice Memo? This action cannot be undone."
              onYes={handleDelete}
            >
              <Button label="Delete" glyph="delete" size={100} type="minimal" color="red" display="inline-flex" />
            </DeleteDialog>
          </div>
        </div>
      )}
    </Card>
  )
}

const Transcript = ({ transcript }: any) => {
  return (
    <Card className="px-2 py-1" style={{ backgroundColor: tint(0.95, COLORS.orange) }}>
      {transcript ? (
        <div className="font-semibold">{transcript}</div>
      ) : (
        <div className="italic opacity-80 text-center">Start speaking to record a new voice memo</div>
      )}
    </Card>
  )
}

export default QuickTranscriptionsView
