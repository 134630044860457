import React from 'react'
import clsx from 'clsx'

import BHDateTimeInput from '../../../../components/Forms/DateTimeInput'
import BHDateInput from '../../../../components/Forms/DateInput'
import BHTimeInput from '../../../../components/Forms/TimeInputSelector/TimeInputSelector'

import { getFormElementProps } from '../../utils/functions'

const INPUTS = {
  date_time_input: BHDateTimeInput,
  date_input: BHDateInput,
  time_input: BHTimeInput,
}

export const DateTimeInput: React.FC<any> = (props) => {
  const { children, element, className, hoverElement, useParsedConfig, environment } = props

  if (!element) return null

  const rootClasses = clsx('DATE_TIME_INPUT', className)

  const Tag = INPUTS?.[element.config?.date_time_input_type]

  const inputProps = getFormElementProps(element, { useParsedConfig, environment })

  if (!Tag) return null

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}
      <Tag
        key={`input-type-${element.config?.date_time_input_type}`}
        {...inputProps}
        defaultToNow={element.config?.date_time_input_type === 'time_input'}
      />
    </div>
  )
}
