import React from 'react'
import autoAnimate from '@formkit/auto-animate'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import State from '../../../components/State'

import { Element } from '../elements/Element'
import { FORM_CONTENT_WIDTHS } from '../utils/constants'
import { useFormBuilder } from '../useFormBuilder'

export const FormPreviewPage = React.forwardRef((props: any, ref: any) => {
  const { after, before, className, pageId } = props

  const page: any = useFormBuilder((state: any) => state.pages?.[pageId])
  const elementsOrder: any = useFormBuilder((state: any) => state.pages?.[pageId]?.elements_order)

  const animationRef = React.useRef(null)

  const style = React.useMemo(() => {
    return {
      width: '100%',
      maxWidth: FORM_CONTENT_WIDTHS?.[page?.config?.content_width] || '500px',
      '--field-max-width': '100% !important',
    }
  }, [page?.config?.content_width])

  React.useEffect(() => {
    animationRef.current && autoAnimate(animationRef.current)
  }, [animationRef])

  if (!page) return null

  const isEmpty = size(elementsOrder) === 0
  const rootClasses = clsx('w-full max-w-full mx-auto [&_label]:!pt-0', className)

  return (
    <div ref={ref} className={rootClasses} style={style}>
      {before}

      {isEmpty ? (
        <State isEmpty glyph="documents" glyphColor={COLORS.text} title="Empty Page" emptyDescription="This page has no content" />
      ) : (
        <div className="grid gap-4 grid-cols-[100%]">
          {elementsOrder?.map?.((elementId: string) => (
            <Element key={elementId} elementId={elementId} />
          ))}
        </div>
      )}

      {after}
    </div>
  )
})
