import React from 'react'
import { tint, darken } from 'polished'
import clsx from 'clsx'

import { COLORS, getColor } from '../theme'

import Graphic from './Graphic'
import Tooltip from './Tooltip'

const Status = (props) => {
  const {
    after,
    before,
    className,
    color,
    testKey,
    glyph,
    glyphColor,
    icon,
    inline,
    label,
    large,
    onClick,
    small,
    style,
    useTint = true,
    infoTooltip,
  } = props

  const classNames = clsx({
    'is-inline': inline,
    'is-small': small,
    'is-large': large,
    [className]: className,
  })

  return (
    <div
      css={[styles, { background: useTint ? tint(0.8, getColor(color)) : getColor(color), ...(!useTint && { color: COLORS.white }) }]}
      className={classNames}
      onClick={onClick}
      style={style}
    >
      {before}
      <Graphic
        size={small ? 12 : 16}
        icon={icon}
        glyph={glyph}
        glyphColor={glyphColor}
        css={{ marginLeft: '0.25em', marginRight: '-0.1em' }}
      />
      <span data-test={testKey} css={{ padding: '0.3em 0.5em', lineHeight: '1', whiteSpace: 'nowrap' }}>
        {label}
      </span>
      {after}
      {infoTooltip && <Tooltip glyphSize={12} color={darken(0.1, color)} content={infoTooltip} />}
    </div>
  )
}

const styles = {
  display: 'flex',
  width: 'fit-content',
  height: 'fit-content',

  borderRadius: 4,
  color: COLORS.text,
  alignItems: 'center',

  lineHeight: '1',
  fontWeight: 600,
  fontSize: '0.8rem',
  verticalAlign: 'middle',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',

  '&.is-inline': {
    display: 'inline-flex',
  },

  '&.is-small': {
    fontSize: '0.7rem',
  },

  '&.is-large': {
    fontSize: '0.94rem',
  },
}

Status.defaultProps = {
  color: 'gray',
}

export default Status
