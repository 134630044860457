import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import ApplyPermissionTemplateOverlay from '../../components/Permissions/ApplyPermissionTemplateOverlay'
import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import StaffPermissionsList from '../../components/Permissions/StaffPermissionsList'
import StaffProfileHeader from '../../components/StaffProfileHeader'
import SummonOverlay from '../../components/SummonOverlay'

const RootStaffPermissionsOverlay = (props: any) => {
  const { cancel, close, data, edit, form, isEditable, isInvalid, isLoading, isNew, isOverlayLoading, isSaving, onValidationUpdate, save } =
    useOverlay({
      name: 'employee',
      endpoint: '/employees',
      invalidate: 'employees',
      invalidateKeys: ['employee', 'employees'],
      options: props,
    })

  if (isOverlayLoading || !data) {
    return <OverlayLoader position="right" maxWidth={60} />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} maxWidth={60}>
      <Overlay.Header title="Staff Permissions" icon="permissions" subtitle={<StaffProfileHeader employee={data} />} />

      <Overlay.Content>
        <Form
          getForm={form}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          initialModel={{ permissions: data.permissions || {} }}
          key={`updated-${data?.updated_at}`}
        >
          <div className="!mb-4 border-0 !border-b border-solid border-divider">
            <StaffPermissionsList model="permissions" />
          </div>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Staff Permissions"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="employees.permissions.edit"
            />

            {data && (
              <SummonOverlay overlay={<ApplyPermissionTemplateOverlay record={data} />}>
                <Button label="Apply Staff Permissions…" permission="employees.permissions.edit" type="primary" />
              </SummonOverlay>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const StaffPermissionsOverlay = withOverlayError(RootStaffPermissionsOverlay)
