import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { Button, Card, CardContent, CardHeader, CardTitle, Icon, Link } from '@behavehealth/components'

import { MedicationIncidentLogsDataTable } from '@behavehealth/constructs/Medications/MedicationIncidentLogsDataTable'

const MedicationIncidentLogs = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['client', resource_id, 'medication_incident_logs'],
    endpoint: `/residents/${resource_id}/medication_incident_logs`,
    updateDeleteEndpoint: '/medication_incident_logs',
    localStorageKey: 'client_medication_incident_logs_v1',
  })

  const to = React.useCallback(
    (rowData: any) => ({
      pathname: `${match.url}/incident-logs/${rowData.id}`,
      parent: match,
    }),
    [match],
  )

  const isEmpty = size(tableProps.data) === 0

  return (
    <Card>
      <CardHeader
        graphic={<Icon icon="medication_incidents" size={20} />}
        after={
          canCreate && (
            <Button
              label="Add Medication Incident Log"
              glyph="add"
              type="primary"
              link={{ pathname: `${match.url}/incident-logs/new`, parent: match }}
              size={200}
              permission="medication_incident_logs.create"
            />
          )
        }
      >
        <CardTitle title="Medication Incident Logs" />
      </CardHeader>

      <CardContent className="!p-0" showDivider={!isEmpty}>
        <MedicationIncidentLogsDataTable
          {...tableProps}
          to={to}
          asCard={false}
          hiddenColumnIds={['client']}
          // headerLinksConfig={[
          //   {
          //     type: 'report',
          //     to: '/reports/medication-incident-logs',
          //   },
          // ]}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'medication_incident_logs.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </CardContent>
    </Card>
  )
}

export default MedicationIncidentLogs
