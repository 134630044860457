import React from 'react'

import TreeItem from '../../../../components/TreeItem'
import URLInput from '../../../../components/Forms/URLInput'

export const PDFThumbnailEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate, editElementConfig } = props

  const { pdf_thumbnail_url = '' } = activeElement.config

  return (
    <TreeItem title="PDF Thumbnail">
      <URLInput
        label="PDF Thumbnail URL"
        tooltip="Add an image URL to display in PDF exports"
        model="pdf_thumbnail_url"
        value={pdf_thumbnail_url}
        onUpdate={onUpdate}
      />
    </TreeItem>
  )
}

const STYLES = {
  dropdownTrigger: {
    marginLeft: 'auto',
  },

  dropdownButton: {
    width: 22,
    height: 22,
    minWidth: 22,
    minHeight: 22,
    svg: { width: 14, height: 14 },
  },
}
