import React from 'react'
import size from 'lodash/size'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Divider from '../../../components/Divider'
import Flex from '../../../components/Flex'
import Markup from '../../../components/Markup'
import PillTab from '../../../components/PillTab'
import PillTabList from '../../../components/PillTabList'
import State from '../../../components/State'
import Status from '../../../components/Status'
import Tabs from '../../../components/Tabs'

import { QUOTE_CELLS } from '../../QuoteElements/constants'
import { QuoteEstimatorSummonOverlay } from '../../QuoteElements/QuoteEstimatorSummonOverlay'
import { QuoteIframeButton } from '../../QuoteElements/QuoteIframeButton'
import { QuoteLineActions } from '../../QuoteElements/QuoteLineActions'

import { ReviewSection } from './ReviewSection'

export const Pricing = (props: any) => {
  const { data, canViewQuoteDetails, acceptedOfferId, acceptOffer, isAccepting, isPublic, isOpen } = props

  const [activeOfferId, setActiveOfferId] = React.useState(
    acceptedOfferId || data?.selected_quote_offer?.id || data?.quote_offers?.[0]?.id || null,
  )

  const isPaid = data?.status === 'paid' || data?.status === 'completed'

  React.useEffect(() => {
    if (!acceptedOfferId) return

    setActiveOfferId(acceptedOfferId)
  }, [acceptedOfferId])

  React.useEffect(() => {
    if (!isPaid || !data?.selected_quote_offer?.id) return

    setActiveOfferId(data?.selected_quote_offer?.id)
  }, [isPaid, data?.selected_quote_offer?.id])

  if (!data?.quote_offers) return null

  const offersCount = size(data.quote_offers)
  const isQuoteOffersEmpty = offersCount === 0

  return (
    <ReviewSection
      isOpen={isOpen}
      data={data}
      title="Pricing"
      icon="financials"
      titleAfter={<QuoteIframeButton data={data} model="quote_offers" />}
      sectionModel="quote_offers"
    >
      {!canViewQuoteDetails ? (
        <Alert glyph="info">Please complete the previous sections to view the pricing details</Alert>
      ) : (
        <>
          {isQuoteOffersEmpty ? (
            <State isEmpty title="Quote Offers" icon="quotes" emptyDescription="No quote offers added yet" minHeight={200} />
          ) : (
            <>
              {!acceptedOfferId && (
                <Alert glyph="info" className="mb-4">
                  {offersCount > 1
                    ? 'Please review the offers below and select the one you would like to select'
                    : 'Please review the offer below and select it to continue'}
                </Alert>
              )}

              <Tabs activeTab={activeOfferId} onChange={setActiveOfferId}>
                {!isPaid && (
                  <PillTabList className="px-0 pb-4">
                    {data.quote_offers.map((offer) => {
                      return (
                        <React.Fragment key={offer.id}>
                          <PillTab
                            label={
                              <span className="flex items-center flex-nowrap">
                                {offer?.name}

                                {!!acceptedOfferId && acceptedOfferId === offer.id && (
                                  <Status className="ml-1.5" small label="Selected" color="green" />
                                )}
                              </span>
                            }
                            name={offer.id}
                          />
                        </React.Fragment>
                      )
                    })}
                  </PillTabList>
                )}

                {isPaid && (
                  <div className="flex items-center mb-3">
                    <Status label="Completed" glyph="tick_circle" color="green" className="mr-2" />
                  </div>
                )}

                {!!acceptedOfferId && !isPaid && (
                  <>
                    {acceptedOfferId === activeOfferId ? (
                      <Alert type="positive" glyph="tick_circle" className="mb-4">
                        Offer selected. You can continue with the next steps.
                      </Alert>
                    ) : (
                      <Alert glyph="info" className="mb-4">
                        You already selected an offer. If you wish to change it, please select another offer below.
                      </Alert>
                    )}
                  </>
                )}

                <Tabs.Panels>
                  {data.quote_offers.map((offer: any) => (
                    <Tabs.Panel key={offer.id} name={offer.id}>
                      <QuoteOffer
                        quote={data}
                        data={offer}
                        acceptedOfferId={acceptedOfferId}
                        acceptOffer={acceptOffer}
                        isAccepting={isAccepting}
                        isPublic={isPublic}
                        canEdit={!isPaid}
                        isPaid={isPaid}
                      />
                    </Tabs.Panel>
                  ))}
                </Tabs.Panels>
              </Tabs>

              <Divider className="!my-8" />

              {(data?.iframes_settings?.use_price_summary_iframe_1_iframe ||
                data?.iframes_settings?.use_price_summary_iframe_2_iframe ||
                data?.iframes_settings?.use_price_summary_iframe_3_iframe) && (
                <div>
                  <Flex gap="1rem">
                    <QuoteIframeButton data={data} model="price_summary_iframe_1" />
                    <QuoteIframeButton data={data} model="price_summary_iframe_2" />
                    <QuoteIframeButton data={data} model="price_summary_iframe_3" />
                  </Flex>
                </div>
              )}

              {data.price_summary_text && (
                <div className="mt-6">
                  <Markup value={data.parsed_price_summary_text || data.price_summary_text} />
                </div>
              )}
            </>
          )}
        </>
      )}
    </ReviewSection>
  )
}

const QuoteOffer = (props: any) => {
  const { data, quote, isAccepting, acceptedOfferId, acceptOffer, isPublic, canEdit, isPaid } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Product',
        width: 'minmax(250px, 1fr)',
        render: (line: any) => {
          return QUOTE_CELLS.product({
            name: line?.product?.public_name,
            icon: line?.product?.icon,
          })
        },
      },
      {
        title: 'Status',
        width: '100px',
        render: (line: any) => {
          return (
            <div className="flex items-center">
              {line.product?.is_required ? <Status label="Required" color="orange" /> : <Status label="Optional" color="blue" />}
            </div>
          )
        },
      },
      {
        title: 'Actions',
        width: '280px',
        render: (line: any) => {
          return (
            <div className="flex items-center">
              <QuoteLineActions isPublic={isPublic} quote={quote} quoteLine={line} canEdit={canEdit} />
            </div>
          )
        },
      },
      {
        title: 'Amount',
        width: '300px',
        render: (line: any) => {
          return QUOTE_CELLS.amount(line?.product)
        },
      },
      {
        title: 'Billed',
        width: '150px',
        render: (line: any) => {
          return QUOTE_CELLS.billingType(line?.product)
        },
      },
      {
        title: 'Discount',
        width: '150px',
        render: (line: any) => {
          return QUOTE_CELLS.discount(line?.discount)
        },
      },
    ],
    [isPublic, canEdit],
  )

  const gridTemplateColumns = React.useMemo(() => columns.map((column) => column.width).join(' '), [columns])

  if (!data?.quote_lines) return null

  const isEmpty = size(data.quote_lines) === 0

  return (
    <div>
      <div className="mb-4 w-full">{data.description && <Markup value={data.description} />}</div>

      {isEmpty ? (
        <State isEmpty icon="quotes" title="Quote Offer" emptyDescription="No products added to this Quote Offer yet" minHeight={200} />
      ) : (
        <div className="grid pb-3 -mb-3 overflow-x-auto -mr-4 pr-4">
          <div
            className="grid text-[0.84rem] tracking-[1px] uppercase text-text-muted font-[700] opacity-[0.85]"
            style={{ gridTemplateColumns }}
          >
            {columns.map((column) => (
              <div key={column.title}>{column.title}</div>
            ))}
          </div>

          {data.quote_lines.map((line: any, index: number) => {
            const { product, discount } = line

            if (!product) return null

            return (
              <div
                key={line.id}
                className="grid py-2 border-b !last:border-none border-solid border-0 border-divider last:border-none"
                style={{ gridTemplateColumns }}
              >
                {columns.map((column) => (
                  <div key={column.title}>{column?.render?.(line) || null}</div>
                ))}
              </div>
            )
          })}
        </div>
      )}

      {!isPaid && !isEmpty && (
        <div className="mt-4">
          <Flex gap="1rem">
            {!acceptedOfferId && (
              <Button
                label={`Select Offer & Continue`}
                type="primary"
                color="green"
                glyph="tick_circle"
                size={300}
                onClick={() => {
                  acceptOffer?.(data.id)
                }}
                isLoading={isAccepting}
              />
            )}

            {!!acceptedOfferId && acceptedOfferId !== data.id && (
              <Button
                label={`Select This Offer Instead`}
                type="primary"
                color="green"
                glyph="tick_circle"
                size={300}
                onClick={() => {
                  acceptOffer?.(data.id)
                }}
                isLoading={isAccepting}
              />
            )}

            {quote?.use_estimator && <QuoteEstimatorSummonOverlay quote={quote} quoteOfferId={data.id} isPublic={isPublic} />}
          </Flex>
        </div>
      )}
    </div>
  )
}
