import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { BillingAddonsTable, BillingPlansTable, BillingServicesTable } from '@behavehealth/components/Tables'
import { ReorderOverlay } from '@behavehealth/components/Billing'
import InternalNotesOverlay from '@behavehealth/components/Billing/InternalNotesOverlay'

import { SummonOverlay, Button, Card, Flex, PageLayout, PageSection, Markup } from '@behavehealth/components'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { ProductsDataTable } from '@behavehealth/constructs/InternalBilling/ProductsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ProductsManager = () => {
  const { tenant } = useSettings()

  const platformPlansTableProps = useDataTable({
    name: ['products'],
    endpoint: '/products',
    params: { category: 'platform_plan' },
    localStorageKey: 'platform_plans_v1',
  })

  const productsTableProps = useDataTable({
    name: ['products'],
    endpoint: '/products',
    params: { category: 'plan,addon,service' },
    localStorageKey: 'products_v1',
  })

  // const { data: basePlans, isLoading: basePlansLoading }: any = useGet({
  //   name: ['products', { category: 'platform_plan' }],
  //   url: `/products`,
  //   params: { category: 'platform_plan' },
  // })

  // const { data: plansAddonServices, isLoading: plansAddonServicesLoading }: any = useGet({
  //   name: ['products', { category: 'plan,addon,service' }],
  //   url: `/products`,
  //   params: { category: 'plan,addon,service' },
  // })

  const { mutateAsync: reorderBasePlans }: any = useUpdate({
    name: ['products', { category: 'platform_plan' }],
    url: '/products/reorder?category=platform_plan',
  })

  const { mutateAsync: reorderPlansAddonsServices }: any = useUpdate({
    name: ['products', { category: 'plan,addon,service' }],
    url: '/products/reorder?category=plan,addon,service',
  })

  // const isBasePlansEmpty = size(basePlans) === 0
  // const isPlansAddonServicesEmpty = size(plansAddonServices) === 0

  const onBasePlansReorder = async (items: any) => {
    try {
      await reorderBasePlans({ reorder: items })
    } catch (errors) {
      console.log(errors)
    }
  }

  const onPlansAddonsServicesReorder = async (items: any) => {
    try {
      await reorderPlansAddonsServices({ reorder: items })
    } catch (errors) {
      console.log(errors)
    }
  }

  return (
    <PageLayout>
      <PageSection>
        <PageSection.Header
          after={
            <Flex gap="0.5rem">
              <SummonOverlay overlay={<InternalNotesOverlay title="Edit Notes" data={tenant} model="metadata.products_manager_notes" />}>
                <Button label="Edit Notes" glyph="edit" type="default" size={200} />
              </SummonOverlay>
            </Flex>
          }
        >
          <PageSection.Title title="Internal Notes" />
        </PageSection.Header>

        <PageSection.Content>
          <Card className="!py-0 !px-4">
            <Markup value={tenant?.metadata?.products_manager_notes} />
          </Card>
        </PageSection.Content>
      </PageSection>

      <PageSection>
        <PageSection.Header
          after={
            <Flex gap="0.5rem">
              <SummonOverlay
                overlay={<ReorderOverlay title="Reorder Plans" data={platformPlansTableProps.data} onSave={onBasePlansReorder} />}
              >
                <Button label="Reorder" glyph="drag_and_drop" type="default" size={200} />
              </SummonOverlay>

              <Button as={Link} label="Import" glyph="circle_add" size={200} link={`import-base-plans`} />

              <Button
                as={Link}
                label="Add Platform Plan"
                glyph="add"
                type="primary"
                size={200}
                link="base-plans/new"
                state={{ data: { category: 'platform_plan' } }}
              />
            </Flex>
          }
        >
          <PageSection.Title title="Platform Plans" />
        </PageSection.Header>

        <PageSection.Content>
          <ProductsDataTable
            {...platformPlansTableProps}
            title="Platform Plans"
            to={(data: any) => `base-plans/${data.id}`}
            hiddenColumnIds={['facility']}
          />

          {/* <Card>
            <BillingPlansTable
              mainLinkAs={NavLink}
              data={basePlans}
              isLoading={isBasePlansEmpty && basePlansLoading}
              to={(id: string) => `base-plans/${id}`}
            />
          </Card> */}
        </PageSection.Content>
      </PageSection>

      <PageSection>
        <PageSection.Header
          after={
            <Flex gap="0.5rem">
              <SummonOverlay
                overlay={<ReorderOverlay title="Reorder" data={productsTableProps.data} onSave={onPlansAddonsServicesReorder} />}
              >
                <Button label="Reorder" glyph="drag_and_drop" type="default" size={200} />
              </SummonOverlay>

              <Button as={Link} label="Import" glyph="circle_add" size={200} link={`import-plans-addons-services`} />

              <Dropdown label="Add New…" buttonType="primary" glyph="add" buttonSize={200}>
                <DropdownItem
                  as={Link}
                  label="Plan"
                  icon="certificate"
                  link="plans-addons-services/new"
                  state={{ data: { category: 'plan' } }}
                />
                <DropdownItem
                  as={Link}
                  label="Add-On"
                  icon="certificate"
                  link="plans-addons-services/new"
                  state={{ data: { category: 'addon' } }}
                />
                <DropdownItem
                  as={Link}
                  label="Service"
                  icon="certificate"
                  link="plans-addons-services/new"
                  state={{ data: { category: 'service' } }}
                />
              </Dropdown>
            </Flex>
          }
        >
          <PageSection.Title title="Products" />
        </PageSection.Header>

        <PageSection.Content>
          <ProductsDataTable
            {...productsTableProps}
            title="Platform Plans"
            to={(data: any) => `plans-addons-services/${data.id}`}
            hiddenColumnIds={['facility']}
          />

          {/* <Card>
            <BillingAddonsTable
              mainLinkAs={NavLink}
              data={plansAddonServices}
              isLoading={isPlansAddonServicesEmpty && plansAddonServicesLoading}
              to={(id: string) => `plans-addons-services/${id}`}
            />
          </Card> */}
        </PageSection.Content>
      </PageSection>
    </PageLayout>
  )
}

export default ProductsManager
