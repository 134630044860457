import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import produce from 'immer'
import size from 'lodash/size'

import { apiGet } from '../../../../../modules/api'
import { arrayToMap, mapToArray } from '../../../../../utils/functions'
import { TabsContext } from '../../../../../hocs/withTabsContext'

import Button from '../../../../Button'
import Card from '../../../../Card'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import Form from '../../../../Forms/Form'
import FormSection from '../../../../Forms/FormSection'
import State from '../../../../State'
import Status from '../../../../Status'
import TreeItem from '../../../../TreeItem'

import DiagnosisAccordion from '../components/DiagnosisAccordion'
import Footer from '../components/Footer'
import Main from '../components/Main'

import { icd10ToKey, keyToID } from './utils'

const setInitialPageData = (wileyData: any) => {
  const res: any = {}

  for (const icd10 of Object.keys(wileyData)) {
    res[icd10ToKey(icd10)] = {}
  }

  return res
}

const Interventions = ({ formData, setFormData, wileyData, setWileyData }: any) => {
  const { onSelect: selectTab }: any = React.useContext(TabsContext)

  const form: any = React.useRef()

  const initialPageData = setInitialPageData(wileyData)
  const [pageData, setPageData] = React.useState(initialPageData)

  const updatePageData = (icd10: any, data: any) => {
    setPageData(
      produce((draft: any) => {
        draft[icd10] = data
      }),
    )
  }

  const updateWileyData = () => {
    const icd10Keys = Object.keys(wileyData)
    const { selected_diagnoses } = form.current?.getFormValue()

    let newWileyData = cloneDeep(wileyData)

    for (const icd10 of icd10Keys) {
      const { problem_group_id, problem_id, selected_goals, selected_objectives, selected_interventions } = selected_diagnoses[icd10]

      for (const goal_id in selected_goals) {
        // skip if goal is not selected
        if (!selected_goals[goal_id]) continue
        const goalID = keyToID(goal_id)

        for (const objective_id in selected_objectives[goal_id]) {
          // skip if objective is not selected
          if (!selected_objectives[goal_id][objective_id]) continue

          const objectiveID = keyToID(objective_id)
          const newInterventions: any = {}

          for (const intervention_id in selected_interventions[goal_id][objective_id]) {
            // skip if intervention is not selected
            if (!selected_interventions[goal_id][objective_id][intervention_id]) continue

            const id = keyToID(intervention_id)
            const key = `${problem_group_id}_${problem_id}_${goalID}_${objectiveID}`
            const interventions = pageData[icd10][key].interventions

            const description = interventions.find((o) => o.intervention_id === id)

            newInterventions[intervention_id] = {
              intervention_id: id,
              description: description,
            }
          }

          newWileyData = produce(newWileyData, (draft: any) => {
            draft[icd10].goals[goal_id].objectives[objective_id].interventions = newInterventions
          })
        }
      }
    }

    setWileyData(newWileyData)
  }

  const goNext = () => {
    updateWileyData()
    selectTab('review')
  }

  if (!formData) return null

  return (
    <>
      <Main>
        <Form useFullModel getForm={form} initialModel={formData} onUpdate={setFormData}>
          <FormSection maxWidth="100%">
            {mapToArray(wileyData).map((diagnosis: any) => (
              <InterventionsSection
                key={diagnosis.icd10}
                icd10={diagnosis.icd10}
                icd10Description={diagnosis.description}
                problemGroupID={diagnosis.problem_group_id}
                problemID={diagnosis.problem_id}
                problemGroupDescription={diagnosis.problem_group_description}
                problemDescription={diagnosis.problem_description}
                updatePageData={updatePageData}
                goals={diagnosis.goals}
              />
            ))}
          </FormSection>
        </Form>
      </Main>

      <Footer>
        <Button label="Continue →" type="primary" onClick={goNext} />
      </Footer>
    </>
  )
}

const InterventionsSection: React.FC<any> = (props) => {
  const { icd10, icd10Description, problemDescription, problemGroupDescription, problemGroupID, problemID, updatePageData, goals } = props

  const { onSelect: selectTab }: any = React.useContext(TabsContext)

  const [data, setData]: any = React.useState({})
  const [loading, setLoading] = React.useState(false)

  const emptyGoals = size(goals) === 0

  const goBack = () => {
    selectTab('objectives')
  }

  // Get Wiley Data
  React.useEffect(() => {
    if (!problemGroupID && !problemID) return

    const getAPIData = async () => {
      try {
        setLoading(true)

        const filters = []

        for (const goal_id in goals) {
          for (const objective_id in goals[goal_id].objectives) {
            filters.push(`${problemGroupID}:${problemID}:${keyToID(goal_id)}:${keyToID(objective_id)}`)
          }
        }

        const result = await apiGet({ url: `/wiley/interventions_with_filters?filters=${filters.join(',')}` })
        const data = arrayToMap(result?.data?.slice(0, 10))

        setData(data)
        updatePageData(icd10ToKey(icd10), data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getAPIData()
  }, [problemGroupID, problemID])

  if (!problemGroupID && !problemID) return null

  if (loading || emptyGoals) {
    return (
      <Card>
        <State
          isLoading={loading}
          isEmpty={emptyGoals}
          icon="treatment_plans"
          title="No Goals Selected"
          emptyDescription="Please add at least one goal to view objectives"
          emptyActions={<Button label="← Select Goals" type="minimal" size={200} onClick={goBack} />}
          minHeight={200}
        />
      </Card>
    )
  }

  const baseModel = `selected_diagnoses.${icd10ToKey(icd10)}.selected_interventions`

  return (
    <DiagnosisAccordion
      icd10={icd10}
      icd10Description={icd10Description}
      problemGroupDescription={problemGroupDescription}
      problemDescription={problemDescription}
    >
      {mapToArray(goals).map((goal: any) => (
        <TreeItem
          isOpen
          showDivider
          key={goal.goal_id}
          title={
            <>
              <Status inline small label="Goal" color="blue" css={styles.inlineStatus} />
              {goal.description}
            </>
          }
        >
          {size(goal.objectives) === 0 && (
            <State
              isEmpty
              icon="treatment_plans"
              title="No Objectives Selected"
              emptyDescription="Please add at least one objective to view interventions"
              emptyActions={<Button label="← Select Objectives" type="minimal" size={200} onClick={goBack} />}
              minHeight={200}
            />
          )}

          {mapToArray(goal.objectives).map((objective, index: number) => {
            const key = `${problemGroupID}_${problemID}_${goal.goal_id}_${objective.objective_id}`

            if (!data[key]) return null

            return (
              <TreeItem
                showDivider
                isOpen
                key={objective.objective_id}
                title={
                  <>
                    <Status inline small label="Objective" color="purple" css={styles.inlineStatus} />
                    <span css={styles.objectiveTitle}>{objective.description}</span>
                  </>
                }
              >
                <Status label="Interventions:" color="orange" css={styles.blockStatus} />
                <CheckboxGroup layout="vertical-dense" withHover={false} css={styles.checkboxGroup}>
                  {data[key].interventions?.map?.((intervention: any) => (
                    <Checkbox
                      key={`${intervention.id}_${intervention.intervention_id}`}
                      label={intervention.description}
                      model={`${baseModel}.id_${goal.goal_id}.id_${objective.objective_id}.id_${intervention.intervention_id}`}
                    />
                  ))}
                </CheckboxGroup>
              </TreeItem>
            )
          })}
        </TreeItem>
      ))}
    </DiagnosisAccordion>
  )
}

const styles = {
  inlineStatus: {
    marginRight: '0.5rem',
  },

  blockStatus: {
    margin: '0.5rem 0',
  },

  checkboxGroup: {
    paddingBottom: '1rem',
  },

  objectiveTitle: {
    fontWeight: 400,
  },
}

export default Interventions
