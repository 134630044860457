import React from 'react'

import DateInput from '../../components/Forms/DateInput'
import Flex from '../../components/Flex'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'

export const InvoiceDetailsInputs = () => {
  return (
    <>
      <Flex gap="1rem">
        <Input label="Invoice Number" model="identifier" />

        <Select allowEmpty label="Status" model="status" defaultValue="draft">
          <Option label="Draft" value="draft" />
          <Option label="Open" value="open" />
          <Option label="Paid" value="paid" />
          <Option label="Failed" value="failed" />
          <Option label="Refunded" value="refunded" />
          <Option label="Cancelled" value="cancelled" />
          <Option label="Written Off" value="written_off" />
        </Select>

        <DateInput label="Due Date" model="due_date" />
      </Flex>

      <Flex gap="1rem">
        <DateInput label="Invoice Period Start" model="period_started_at" />
        <DateInput label="Invoice Period End" model="period_ended_at" />
      </Flex>

      <Textarea label="Description" minRows={2} model="description" />
      <Textarea label="Instructions" minRows={2} model="instructions" />
      <Textarea label="Notes" minRows={2} model="notes" />
    </>
  )
}
