import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import DropdownItem from '../DropdownItem'
import Tooltip from '../Tooltip'
import Iframe from '../Iframe'
import Link from '../Link'

import { apiGet, apiCreate } from '../../modules/api'
import { URL } from '../../utils/globals'

const ERXDropdownItem = ({ rcopiaID, rcopiaExternalID, isDisabled = false }: any) => {
  const match = useRouteMatch()
  const [loading, setLoading] = React.useState(false)

  const getSSOURL = async () => {
    let params = {
      rcopia_user_external_id: 'ext_doc_123',
      startup_screen: 'patient',
      rcopia_patient_id: rcopiaID,
    }

    try {
      setLoading(true)
      const result = await apiGet({ url: `${URL.api}/apps/rcopia/sso`, params: params })
      setLoading(false)
      return result.data.data
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const syncPatient = () => {
    try {
      apiCreate({
        url: `${URL.api}/apps/rcopia/async_patient`,
        params: { external_id: rcopiaExternalID },
        notify: false,
      })
    } catch (error) {
      console.error(error)
    }
  }

  if (isDisabled) {
    return (
      <>
        <Tooltip
          content={
            <>
              You must Sync from <Link to={`${match.url}/medications`}>Medications</Link> before accessing the e-Prescribing Portal
            </>
          }
        >
          <DropdownItem isDisabled label="Open e-Prescribing Portal" glyph="pill_bottle" color="red" featureFlagV2="erx" />
        </Tooltip>
      </>
    )
  }

  return (
    <Iframe overlayWidth={180} title="e-Prescribing" processURL={getSSOURL} beforeClose={syncPatient}>
      <DropdownItem isLoading={loading} label="Open e-Prescribing Portal" glyph="pill_bottle" color="red" featureFlagV2="erx" />
    </Iframe>
  )
}

export default ERXDropdownItem
