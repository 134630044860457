import React from 'react'

import { DEFAULT_FILTERS } from '../../../../constructs/Filters/constants'
import { FormBuilderShareStatus } from '../../../../constructs/FormBuilder/FormBuilderShareStatus'
import { useSettings } from '../../../../hooks/useSettings'

import { DataTable } from '../../../DataTable/DataTable'
import { LinkCell } from '../../../DataTable/cells/LinkCell'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

export const PublicFormsTable = ({ onSelect, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['forms'],
    endpoint: `/forms`,
    params: { share_status: 'public_status' },
    localStorageKey: 'selector_forms_v1',
  })

  const { tenant } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        width: 260,
        isSticky: true,
        canToggleVisible: false,
        model: 'name',
        title: 'Name',
        formatValue: ({ data, value }: any) => (
          <MainCell id={data.id} onClick={onSelect ? () => onSelect(data) : undefined} value={value} />
        ),
      },
      {
        width: 160,
        model: 'share_status',
        title: 'Share Status',
        formatValue: ({ value }: any) => {
          return <FormBuilderShareStatus status={value} />
        },
      },
      {
        width: 180,
        model: 'external_id',
        title: 'Shareable Link',
        disableSort: true,
        formatValue: ({ data, value: externalID }: any) => {
          const isPublic = data.share_status === 'public_status'
          const formURL = externalID && tenant && `${process.env.BH_APP_FORM_URL}/${tenant.subdomain}/${externalID}`

          if (!isPublic || !formURL) return null

          return <LinkCell isExternal href={formURL} value="Open Public Link" />
        },
      },
      {
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        model: 'updated_at',
        title: 'Date Updated',
        type: 'date_time',
      },
    ],
    [],
  )

  return <DataTable {...tableProps} {...rest} title="Forms" columns={columns} filtersConfig={FILTERS_CONFIG} />
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
