import React from 'react'

const MESSAGES: any = {
  batch_create: (props: any) => (
    <>
      {props.author} added a new {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
  create: (props: any) => (
    <>
      {props.author} added a new {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
  update: (props: any) => (
    <>
      {props.author} updated the {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
  destroy: (props: any) => (
    <>
      {props.author} deleted the {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
  sign: (props: any) => (
    <>
      {props.author} signed the {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
  notifications: (props: any) => <>{props.author} notified the signees of an agreement.</>,
  import: (props: any) => (
    <>
      {props.author} imported a new {props.record} <i>"{props.event.trackable?.name}"</i>.
    </>
  ),
}

export default (props: any) => MESSAGES[props.event?.action_type]?.(props) || null
