import React from 'react'
import { useHistory } from 'react-router-dom'

import Icon from '../../components/Icon'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import SmartPasswordInput from '../../components/Forms/SmartPasswordInput'

import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Label from '../../components/Label'
import TreeItem from '../../components/TreeItem'

import { COLORS } from '../../theme'
import { request } from '../../modules/axios'
import Notifications from '../../modules/notifications'

export const PasswordResetPage = ({ user, updateStore, onSuccessGoToRoute = '/dashboard' }: any) => {
  const history = useHistory()
  const [formData, setFormData]: any = React.useState({})

  const [error, setError] = React.useState(null)
  const [isValid, setIsValid] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const [passwordValid, setPasswordValid] = React.useState(false)

  const isFormValid = React.useMemo(() => {
    return isValid && passwordValid
  }, [isValid, passwordValid])

  const savePassword = async () => {
    setIsLoading(true)
    setError(null)

    try {
      const result = await request.put('me/password/set', {
        new_password: formData.password,
      })

      if (result.data.data.session_token) localStorage.setItem('bh.stk', result.data.data.session_token)

      Notifications.send('Password updated successfully', 'positive')

      history.push(onSuccessGoToRoute)
    } catch (error) {
      console.error(error)
      setError(error?.data?.error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div css={STYLES.root}>
      <Icon icon="permissions" size={32} />
      <h2 css={STYLES.title}>Securing Your Account</h2>

      <Alert contrast type="positive" glyph="tick_circle">
        To enhance security on the Behave Health platform, we kindly request you to confirm / update your password after verifying your
        email or phone for the first time.
      </Alert>

      <TreeItem title="Read More" css={STYLES.content}>
        <Grid gap="0.75rem">
          <div>
            We are constantly working to improve the <b>security and reliability</b> of the Behave Health platform to provide you with the
            best possible service.
          </div>

          <div>
            As a part of a recent security update, we have implemented <b>additional verification measures</b> to ensure the safety and
            privacy of your account.
          </div>

          <div>
            To comply with these new security measures, we are requesting all our valued users to <b>confirm / update their passwords</b>{' '}
            after verifying their email or phone for the first time.
          </div>

          <div>
            If you have any questions, please don't hesitate to contact us at{' '}
            <a href="mailto:contact@behavehealth.com">contact@behavehealth.com</a>.
          </div>

          <div>– Behave Health Team</div>
        </Grid>
      </TreeItem>

      <Form onValidationUpdate={setIsValid} onUpdate={setFormData}>
        <FormSection>
          <div>
            <Label label="Your Username" />
            <div css={STYLES.monoTypeBlock}>{user.email}</div>
          </div>

          <SmartPasswordInput
            isEditable
            showRules
            showFeedback
            username={user.email}
            onPasswordValidation={setPasswordValid}
            label="Your Password"
            model="password"
            validations={{
              presence: {
                message: 'Please enter your new password',
              },
              length: {
                minimum: 10,
                message: 'Your password should have at least 10 characters',
              },
              format: {
                pattern: /^(?=.*[a-z])(?=.*[A-Z])((?=.*\d)|(?=.*\W))[a-zA-Z0-9_@.#$=!%^)(\]:*;?/,}{'|<>[&+-]{10,}$/,
                message: 'Password must include both lower and upper case characters, and at least one digit or symbol',
              },
            }}
          />

          {error && <Alert type="negative">{error}</Alert>}

          <Button
            label="Check & Continue"
            color="green"
            glyph="lock"
            type="primary"
            onClick={savePassword}
            isDisabled={!isFormValid}
            isLoading={isLoading}
          />

          <Button
            label="I want to skip this until later"
            color="red"
            type="link"
            onClick={() => {
              updateStore({ showSecurity: false })

              history.push(onSuccessGoToRoute)
            }}
            css={{ marginTop: 10 }}
          />
        </FormSection>
      </Form>
    </div>
  )
}

const STYLES = {
  root: {
    padding: '1.5rem',
    fontSize: '1.05rem',
    width: '100%',
    maxWidth: 500,
    margin: '1rem auto 0',
  },

  title: {
    marginTop: '0.75rem',
    marginBottom: '1rem',
  },

  content: {
    marginTop: '0.5rem',
    marginBottom: '1.5rem',
  },

  subtitle: {
    fontSize: '1.15rem',
    lineHeight: '1.5em',
    color: COLORS.textMuted,
    marginTop: '-0.25rem',
    marginBottom: '0.5rem',
  },

  monoTypeBlock: {
    display: 'inline-block',
    fontSize: '1.05rem',
    fontFamily: 'monospace',
    fontWeight: 400,
    borderRadius: 5,
    background: COLORS.hover,
    border: `1px solid ${COLORS.divider}`,
    padding: '0.5rem 1rem',
    color: COLORS.text,
    letterSpacing: 2,
    width: '100%',
    textAlign: 'center',

    '@media(min-width: 600px)': {
      fontSize: '1.2rem',
    },
  },
}
