import React from 'react'
import { lighten, tint } from 'polished'
import clsx from 'clsx'

import { COLORS } from '../../theme'
import RadioCheckElement from '../Forms/RadioCheckElement'
import Tooltip from '../Tooltip'

import { useStore } from './useStore'

export const DataTableRow = (props: any) => {
  const { id, children, className, style } = props

  const canBatchSelect: any = useStore((state: any) => state.canBatchSelect)
  const getCanSelect: any = useStore((state: any) => state.getCanSelect)
  const selectedIds: any = useStore((state: any) => state.selectedIds)
  const selectId: any = useStore((state: any) => state.selectId)
  const dataMap: any = useStore((state: any) => state.dataMap)
  const cannotSelectIds: any = useStore((state: any) => state.cannotSelectIds)

  const [hasChanged, setHasChanged] = React.useState(false)

  const record = dataMap[id]

  const isSelected = selectedIds?.includes(id)

  const rootClasses = clsx('DataTableRow', isSelected && 'is-selected', className)

  let isSelectDisabled = cannotSelectIds.includes(id) || false
  let disabledSelectMessage = ''

  if (getCanSelect && record) {
    const { canSelect, message } = getCanSelect(record)

    if (!canSelect) {
      isSelectDisabled = true
      disabledSelectMessage = message
    }
  }

  const toggleSelected: any = () => {
    if (isSelectDisabled) return

    selectId(id)
  }

  const onEnter = (event: any) => {
    if (hasChanged) return

    // if left click is pressed
    if (event.buttons === 1) {
      toggleSelected()
      setHasChanged(true)
    }
  }

  const onLeave = () => {
    if (hasChanged) setHasChanged(false)
  }

  return (
    <>
      <div data-test="data_table_row" className={rootClasses} css={STYLES.root} style={style}>
        {canBatchSelect && (
          <div
            css={STYLES.checkbox}
            onContextMenu={toggleSelected}
            onMouseDown={toggleSelected}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
          >
            {isSelectDisabled ? (
              <Tooltip position="right" color={COLORS.red} content={disabledSelectMessage}>
                <RadioCheckElement type="checkbox" size={15} isChecked={isSelected} isDisabled={isSelectDisabled} />
              </Tooltip>
            ) : (
              <RadioCheckElement type="checkbox" size={15} isChecked={isSelected} />
            )}
          </div>
        )}

        <div className="DataTableRow-content" css={STYLES.content}>
          {children}
        </div>
      </div>
    </>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 'var(--row-min-height)',
    minWidth: 'fit-content',

    '&:hover': {
      zIndex: 1,

      '.DataTableCell': {
        background: '#EDEFF0',

        '&.is-sticky': {
          background: `${lighten(0.34, COLORS.vividBlue)} !important`,
        },
      },
    },

    '&.is-selected': {
      background: `${tint(0.9, COLORS.green)} !important`,

      '&:hover': {
        background: `${tint(0.8, COLORS.green)} !important`,
        boxShadow: `0 1px 0 0 ${tint(0.4, COLORS.green)}, 0 -1px 0 0 ${tint(0.4, COLORS.green)} !important`,
      },
    },

    '&:last-child': {
      boxShadow: `0 1px 0 0 ${COLORS.divider}`,
    },
  },

  content: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(120px, 1fr)',
    gridTemplateColumns: 'var(--grid-template-columns)',
    flex: '1 1 auto',
    alignItems: 'stretch',
    alignSelf: 'stretch',
  },

  checkbox: {
    userSelect: 'none', // prevent text selection
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'var(--checkbox-width)',
    alignSelf: 'stretch',
    paddingLeft: 'var(--padding-x)',
    paddingRight: 'var(--padding-x)',
    cursor: 'pointer',
    flex: '0 0 auto',
    position: 'sticky',
    left: 0,
    background: COLORS.white,
    zIndex: 2,
  },
}
