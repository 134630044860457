import React from 'react'

import Checkbox from '../Checkbox'
import CheckboxGroup from '../CheckboxGroup'
import ContextHide from '../ContextHide'

const LegalRequirementsInputs = ({ label, model, isRequired }) => (
  <CheckboxGroup
    label={label}
    layout="vertical-dense"
    validations={
      isRequired && {
        presence: {
          message: 'Please select at least one option',
        },
      }
    }
  >
    <Checkbox label="None Applicable" model={`${model}.none`} />
    <ContextHide when={`${model}.none`} is={true}>
      <Checkbox label="House Arrest" model={`${model}.house_arrest`} />
      <Checkbox label="Probation" model={`${model}.probation`} />
      <Checkbox label="Parole" model={`${model}.parole`} />
      <Checkbox label="Drug Court" model={`${model}.drug_court`} />
      <Checkbox label="Other" model={`${model}.other`} />
    </ContextHide>
  </CheckboxGroup>
)

LegalRequirementsInputs.defaultProps = {
  model: 'legal_requirements',
}

export default LegalRequirementsInputs
