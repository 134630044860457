import React from 'react'
import { parse } from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { isUUID } from '../../utils/functions'
import { NotionBrowser } from '../../constructs/Notion'
import type { NotionPage } from '../../constructs/Notion/NotionBrowser'

import Button from '../Button'
import Flex from '../Flex'
import OverlaySubHeader from '../Overlay/OverlaySubHeader'
import SummonOverlay from '../SummonOverlay'

import { NotionHelpOverlay } from '../Help/NotionHelpOverlay'

import { AppSidebarContext } from './context'
import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

const HOME_TAG = 'help_center_home'

const HelpCenterView: React.FC<any> = () => {
  const location = useLocation()
  const history = useHistory()

  const { isFullScreen }: any = React.useContext(AppSidebarContext)

  const user = useSelector((state: any) => state.me?.user)
  const isBehave = user?.type === 'bh_employee'

  let params: any = parse(location.search)
  const helpTag: any = isUUID(params.guide) ? '' : params.guide

  const helpPages = useSelector((state: any) => state.me?.settings?.help_center)
  const helpTags = useSelector((state: any) => state.me?.settings?.help_center_tags)

  const page = helpPages?.[helpTag]
  const homePage = helpPages?.[HOME_TAG]

  const handlePageChange = (page: NotionPage) => {
    if (!page?.id) return

    const tag = helpTags[page.id]

    const searchParams = new URLSearchParams({ guide: tag || page.id })
    history.replace({ search: searchParams.toString() })
  }

  return (
    <>
      <AppSidebarView>
        <AppSidebarHeader
          title="Help Center"
          icon="help_center"
          actions={!isFullScreen && <Button hideLabel color="text" glyph="settings" size={200} link="/settings/help-center" />}
        />

        {(page || homePage) && (
          <>
            {isBehave && (
              <OverlaySubHeader css={styles.subHeader}>
                <Flex centerY gap={8} justifyContent="space-between">
                  <SummonOverlay isDisabled={!page} overlay={<NotionHelpOverlay title={page?.name} url={page?.url} helpTag={helpTag} />}>
                    <Button
                      css={!page && { opacity: '0 !important' }}
                      label="Edit Notion URL"
                      glyph="edit"
                      type="minimal"
                      size={200}
                      isDisabled={!page}
                    />
                  </SummonOverlay>

                  <Button
                    useGlyphForTarget
                    label="Open in Notion"
                    color="text"
                    glyph="notion"
                    href={`https://www.notion.so/${page?.page_id}`}
                    size={200}
                    target="_blank"
                    type="minimal"
                  />
                </Flex>
              </OverlaySubHeader>
            )}

            <NotionBrowser
              homeTitle={homePage?.name}
              homeID={homePage?.page_id}
              pageTitle={page?.name}
              pageID={page?.page_id}
              onPageChange={handlePageChange}
              css={styles.notionBrowser}
              feedbackCategory="help_article"
            />
          </>
        )}
      </AppSidebarView>
    </>
  )
}

const styles = {
  subHeader: {
    padding: '0.5rem',
  },

  notionBrowser: {
    padding: '1rem 1rem 0',
  },
}

export default HelpCenterView
