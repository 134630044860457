import React from 'react'
import { DateTime } from 'luxon'
import size from 'lodash/size'

import { DataTable } from '../../components/DataTable/DataTable'

import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Icon from '../../components/Icon'
import CardTitle from '../../components/CardTitle'
import PageSection from '../../components/PageSection/PageSection'
import CardHeader from '../../components/CardHeader'

import SummonOverlay from '../../components/SummonOverlay'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useGet, useDelete } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import { CommunityLocationEditorOverlay } from './CommunityLocationEditorOverlay'
import { useCommunityEditor } from './useCommunityEditor'
import { countWord } from '../../utils/functions'
import { useCommunityPermissions } from './useCommunityPermissions'

const RootCommunityProfileLocationsDataTable: React.FC<any> = (props: any) => {
  const { entityId } = props

  const { canEditCommunityProfiles } = useCommunityPermissions()

  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  const { data, isLoading }: any = useGet({
    name: ['community', entityId, 'community-locations'],
    url: `/community/entities/${entityId}/locations`,
  })

  const { mutateAsync: deleteLocation } = useDelete({
    name: ['delete-location'],
    url: `/community/locations`,
    invalidate: ['community', entityId, 'community-locations'],
    onSuccess: () => {
      setUpdatedAt(DateTime.now().toMillis())
    },
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 200,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <SummonOverlay overlay={<CommunityLocationEditorOverlay dataID={data.id} initialData={data} />}>
            <MainCell id={data.id} value={data.name} />
          </SummonOverlay>
        ),
      },
      {
        title: 'Full Address',
        model: 'normalized_address',
      },
      {
        title: 'Phone No',
        model: 'phone_no',
      },
      {
        title: 'Email',
        model: 'email',
      },
      {
        title: 'Actions',
        id: 'actions',
        flexChildren: true,
        disableSort: true,
        width: 100,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          if (!canEditCommunityProfiles) return null

          return (
            <DeleteDialog
              title="Delete Location?"
              message="Are you sure you want to delete this Location?"
              onYes={async () => await deleteLocation(data.id)}
            >
              <Button glyph="delete" label="Delete" type="minimal" color="red" size={100} />
            </DeleteDialog>
          )
        },
      },
    ]
  }, [canEditCommunityProfiles])

  return (
    <div className="grid grid-cols-[100%]">
      <PageSection>
        <PageSection.Header
          graphic={<Icon icon="locations" />}
          after={
            canEditCommunityProfiles && (
              <SummonOverlay overlay={<CommunityLocationEditorOverlay entityId={entityId} dataID="new" />}>
                <Button label="Add New Location" glyph="add" size={200} type="primary" display="inline-flex" />
              </SummonOverlay>
            )
          }
        >
          <PageSection.Title title={size(data) ? countWord('Locations', size(data)) : 'Locations'} />
        </PageSection.Header>
      </PageSection>

      <DataTable
        asCard
        data={data}
        isLoading={isLoading}
        title="Locations"
        icon="locations"
        columns={columns}
        mainHeaderHidden
        className="mt-4"
      />
    </div>
  )
}

export const CommunityProfileLocationsDataTable = withOverlayError(RootCommunityProfileLocationsDataTable)
