import React from 'react'

import Card from './Card'
import CardHeader from './CardHeader'
import CardMeta from './CardMeta'
import CardSubtitle from './CardSubtitle'
import CardTitle from './CardTitle'
import Glyph from './Glyph'
import Text from './Typography/Text'

import { isURL, usDate, usTime } from '../utils/functions'
import { useSettings } from '../hooks/useSettings'

const SignatureView = ({ title, showSubtitle, showSignedBy, label, signedAt, signedBy, value, width }: any) => {
  const { timezone } = useSettings()
  const isSigned = value && (isURL(value) || value.includes('data:image/png;base64'))

  return (
    <>
      {isSigned ? (
        <div css={{ width: width }}>
          <CardHeader
            contentStyles={{ padding: 0 }}
            after={
              <div css={styles.signature}>
                <img src={value} alt="signature" />
              </div>
            }
          >
            {(label || title) && (
              <>
                <CardTitle title={label || title} />
                {showSubtitle && (
                  <CardMeta>
                    {showSignedBy && <Text glyph="signature" description={`Signed by ${signedBy?.name}`} />}
                    <Text glyph="date" description={usDate(signedAt, timezone)} />
                    <Text glyph="time" description={usTime(signedAt, timezone)} />
                  </CardMeta>
                )}
              </>
            )}
          </CardHeader>
        </div>
      ) : (
        <Card css={{ width: width }}>
          {(label || title) && (
            <CardHeader graphic={<Glyph glyph="signature" />}>
              <CardTitle title={label || title} />
              <CardSubtitle subtitle="No signature added yet…" />
            </CardHeader>
          )}
        </Card>
      )}
    </>
  )
}

const styles = {
  content: {
    boxShadow: 'none',
    background: 'none',
  },

  signature: {
    width: 150,
    img: {
      display: 'block',
      width: '100%',
      height: 'auto',
      maxWidth: 400,
      maxHeight: 200,
    },
  },
}

SignatureView.defaultProps = {
  showSubtitle: true,
  showSignedBy: true,
}

export default SignatureView
