import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'

import { RecordTooltip } from '../components/RecordTooltip'
import { AgreementInfo } from '../../StaffJourney/records/AgreementInfo'

export const Agreement = ({ data, client }: any) => {
  if (!data) return null

  return (
    <RecordTooltip
      title={data.name}
      icon="legal_agreement_alt"
      color={tint(0.7, COLORS.orange)}
      startDate={data.dated_at}
      recordLink={`/agreements/${data.id}`}
      client={client}
    >
      <AgreementInfo data={data} />
    </RecordTooltip>
  )
}
