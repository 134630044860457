import React from 'react'

import { withOverlayError } from '../../components/../hocs/withOverlayError'

import Chotomate from '../../components/Chotomate'
import ContextHide from '../../components/Forms/ContextHide'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Medications from '../../components/SmartContent/Medications'
import Section from '../../components/Section'
import Switch from '../../components/Forms/Switch'
import Textarea from '../../components/Forms/Textarea'

import ASAMCriteria from '../../components/Overlays/pages/DataForms/common/ASAMCriteria'
import Assignments from '../../components/Overlays/pages/DataForms/common/Assignments'
import GeneralInfo from '../../components/Overlays/pages/DataForms/common/GeneralInfo'
import MentalStatusForm from '../../components/Overlays/pages/DataForms/common/MentalStatusForm'
import MotivationForChangeForm from '../../components/Overlays/pages/DataForms/common/MotivationForChangeForm'
import RecurringBioMedical from '../../components/Overlays/pages/DataForms/common/RecurringBioMedical'
import RecurringRecoveryEnvironment from '../../components/Overlays/pages/DataForms/common/RecurringRecoveryEnvironment'
import RecurringRelapsePotential from '../../components/Overlays/pages/DataForms/common/RecurringRelapsePotential'
import SuicideSafetyRisk from '../../components/Overlays/pages/DataForms/common/SuicideSafetyRisk'
import WithdrawalSymptomsForm from '../../components/Overlays/pages/DataForms/common/WithdrawalSymptomsForm'

import { DataFormOverlay } from '../DataFormOverlay'

const RootProgressReviewOverlay = () => (
  <DataFormOverlay
    title="Progress Review"
    category="progress_review"
    subcategory="progress_review"
    icon="progress_reviews"
    requestClientSignature={false}
    requireSupervisor={true}
    enableTreatmentPlanSelector={true}
    helpID="progress_reviews"
  >
    {({ client, isEditable }: any) => (
      <>
        <Chotomate ready name="progress_review_overlay" />

        <Section
          title="General Info"
          aside={<Switch horizontal inverse model="data.general_info.not_applicable" />}
          scrollview={{
            id: 'general_info',
            name: 'General Info',
          }}
          commentsModel="data.general_info"
        >
          <ContextHide when="data.general_info.not_applicable" is={true}>
            <GeneralInfo client={client} />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Medications"
          aside={<Switch horizontal inverse model="data.medications.not_applicable" />}
          scrollview={{
            id: 'medications',
            name: 'Medications',
          }}
          commentsModel="data.medications"
        >
          <ContextHide when="data.medications.not_applicable" is={true} declineUndefined>
            <Medications
              model="data.medications.meds"
              isEditable={isEditable}
              client={client}
              icon="medications"
              title="Medications"
              emptyDescription="No medications added yet"
            />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Withdrawal / Post-acute Symptoms"
          aside={<Switch horizontal inverse model="data.withdrawal_symptoms.not_applicable" />}
          scrollview={{
            id: 'withdrawal_symptoms',
            name: 'Withdrawal/PA Symptoms',
          }}
          commentsModel="data.withdrawal_symptoms"
        >
          <ContextHide when="data.withdrawal_symptoms.not_applicable" is={true}>
            <WithdrawalSymptomsForm />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Bio-Medical"
          aside={<Switch horizontal inverse model="data.bio_medical.not_applicable" />}
          scrollview={{
            id: 'bio_medical',
            name: 'Bio-Medical',
          }}
          commentsModel="data.bio_medical"
        >
          <ContextHide when="data.bio_medical.not_applicable" is={true}>
            <RecurringBioMedical />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Suicide & Safety Risk"
          aside={<Switch horizontal inverse model="data.suicide_safety_risk.not_applicable" />}
          scrollview={{
            id: 'suicide_safety_risk',
            name: 'Suicide & Safety Risk',
          }}
          commentsModel="data.suicide_safety_risk"
        >
          <ContextHide when="data.suicide_safety_risk.not_applicable" is={true}>
            <SuicideSafetyRisk />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Current Mental Status"
          description="Does the client report or appear to be experiencing any of the following:"
          aside={<Switch horizontal inverse model="data.mental_status.not_applicable" />}
          scrollview={{
            id: 'mental_status',
            name: 'Current Mental Status',
          }}
          commentsModel="data.mental_status"
        >
          <ContextHide when="data.mental_status.not_applicable" is={true}>
            <MentalStatusForm />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Motivation For Change"
          aside={<Switch horizontal inverse model="data.motivation_for_change.not_applicable" />}
          scrollview={{
            id: 'motivation_for_change',
            name: 'Motivation For Change',
          }}
          commentsModel="data.motivation_for_change"
        >
          <ContextHide when="data.motivation_for_change.not_applicable" is={true}>
            <MotivationForChangeForm />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Assignments & Appointments"
          aside={<Switch horizontal inverse model="data.assignments.not_applicable" />}
          scrollview={{
            id: 'assignments',
            name: 'Assignments & Appointments',
          }}
          help={<HelpTagIframe id="progress_review_assignments_and_appointments" />}
          commentsModel="data.assignments"
        >
          <ContextHide when="data.assignments.not_applicable" is={true}>
            <Assignments />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Relapse Potential"
          aside={<Switch horizontal inverse model="data.relapse_potential.not_applicable" />}
          scrollview={{
            id: 'relapse_potential',
            name: 'Relapse Potential',
          }}
          commentsModel="data.relapse_potential"
        >
          <ContextHide when="data.relapse_potential.not_applicable" is={true}>
            <RecurringRelapsePotential />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Recovery Environment"
          aside={<Switch horizontal inverse model="data.recovery_environment.not_applicable" />}
          scrollview={{
            id: 'recovery_environment',
            name: 'Recovery Environment',
          }}
          help={<HelpTagIframe id="progress_review_recovery_environment" />}
          commentsModel="data.recovery_environment"
        >
          <ContextHide when="data.recovery_environment.not_applicable" is={true}>
            <RecurringRecoveryEnvironment />
          </ContextHide>
        </Section>

        <Divider />

        <ASAMCriteria />

        <Divider />

        <Section
          title="Summary"
          scrollview={{
            id: 'summary',
            name: 'Summary',
          }}
          commentsModel="data.summary"
        >
          <ContextHide when="data.summary.not_applicable" is={true}>
            <FormSection layout="vertical">
              <Textarea label="Clinician Perspective" model="data.summary.clinician_perspective" />
              <Textarea label="Interpretive Summary" model="data.summary.interpretive_summary" />
            </FormSection>
          </ContextHide>
        </Section>
      </>
    )}
  </DataFormOverlay>
)

export const ProgressReviewOverlay = withOverlayError(RootProgressReviewOverlay)
