import React from 'react'
import { tint } from 'polished'
import { v4 as uuid } from 'uuid'
import get from 'lodash/get'
import set from 'lodash/set'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { mapToArray, arrayToInternalMap } from '../../../utils/functions'
import { withFormContext } from '../../Forms/context'

import AmountInput from '../../Forms/AmountInput'
import Button from '../../Button'
import Card from '../../Card'
import CardHeader from '../../CardHeader'
import CardContent from '../../CardContent'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Grid from '../../Grid'
import GridTable from '../../GridTable'
import Input from '../../Forms/Input'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import RadioGroup from '../../Forms/RadioGroup'
import PhoneInput from '../../Forms/PhoneInput'
import PercentageInput from '../../Forms/PercentageInput'
import Textarea from '../../Forms/Textarea'
import ContextShow from '../../Forms/ContextShow'
import Radio from '../../Forms/Radio'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Section from '../../Section'
import Tabs from '../../Tabs'
import Status from '../../Status'

const processInitialValue = (value: any) => {
  if (!value) return null

  return produce(value, (draft: any) => {
    if (Array.isArray(draft)) {
      draft?.map((o: any) => {
        if (!o._id) o._id = o.id || uuid()
      })
    }
  })
}

const AmountCalculator = ({ model, label }: any) => (
  <>
    <AmountInput label={label} model={`${model}.total`} />
    <AmountInput label="Met So Far" model={`${model}.met`} />
    <AmountInput label="Remaining" model={`${model}.remaining`} />
  </>
)

const VOBServices = ({ value }: any) => {
  const initialData = processInitialValue(value)
  const isEmpty = size(initialData) === 0

  return (
    <Section title="Services">
      <Form useFullModel initialModel={initialData} isEditable={false}>
        <div className="!mt-3">
          {isEmpty && (
            <Card css={styles.emptyState}>
              <div>No service lines added yet</div>
            </Card>
          )}

          {!isEmpty && (
            <Card>
              <GridTable templateColumns="40px repeat(2, minmax(200px, 1fr)) 170px 170px 170px">
                <GridTable.Header css={{ borderWidth: 1 }}>
                  <GridTable.Cell></GridTable.Cell>
                  <GridTable.Cell>Service</GridTable.Cell>
                  <GridTable.Cell>Covered</GridTable.Cell>
                  <GridTable.Cell>Pre-Cert</GridTable.Cell>
                </GridTable.Header>

                {Object.keys(initialData)?.map((id: any) => {
                  const item = initialData[id]
                  if (!item) return null

                  return (
                    <>
                      <ServiceRow
                        key={id}
                        cells={
                          <>
                            <GridTable.Cell>
                              <ObjectSelector
                                label=""
                                icon="financials"
                                type="insurance_services"
                                model={`${id}.service`}
                                selectTitle={(data: any) => data.name}
                                selectDescription={(data: any) => <Status label={data.collection_type} color="gray" />}
                              />
                            </GridTable.Cell>

                            <GridTable.Cell>
                              <RadioGroup model={`${id}.is_covered`} layout="horizontal-dense">
                                <Radio label="Yes" value={true} />
                                <Radio label="No" value={false} />
                              </RadioGroup>
                            </GridTable.Cell>

                            <GridTable.Cell>
                              <RadioGroup model={`${id}.requires_precert`} layout="horizontal-dense">
                                <Radio label="Yes" value={true} />
                                <Radio label="No" value={false} />
                              </RadioGroup>
                            </GridTable.Cell>
                          </>
                        }
                      >
                        {item.is_covered && (
                          <Tabs defaultTab="cost_sharing">
                            <Tabs.List css={styles.tabsList}>
                              <Tabs.Item label="Cost Sharing" icon="financials" name="cost_sharing" />
                              {item.requires_precert && <Tabs.Item label="Pre-Cert" icon="checklist" name="pre_cert" />}
                              <Tabs.Item label="Insurance Visits" icon="financials" name="insurance_visits" />
                              <Tabs.Item label="Max Reimbursements" icon="financials" name="max_reimbursements" />
                            </Tabs.List>

                            <Tabs.Panels>
                              <Tabs.Panel name="cost_sharing">
                                <Grid gap="0.5rem">
                                  <Flex gap="1rem">
                                    <FormSection maxWidth="100%" css={{ borderRight: `1px solid ${COLORS.divider}`, paddingRight: '1rem' }}>
                                      <Grid gap={12} columns="1fr 1fr 1fr">
                                        <AmountCalculator
                                          label="Individual Deductible"
                                          model={`${id}.cost_sharing.individual_deductible`}
                                        />
                                        <AmountCalculator label="Family Deductible" model={`${id}.cost_sharing.family_deductible`} />
                                        <AmountCalculator
                                          label="Individual Out-of-Pocket"
                                          model={`${id}.cost_sharing.individual_out_of_pocket`}
                                        />
                                        <AmountCalculator label="Family Out-of-Pocket" model={`${id}.cost_sharing.family_out_of_pocket`} />
                                      </Grid>
                                    </FormSection>

                                    <Grid fixChildrenY gap="1rem">
                                      <RadioGroup
                                        label="Does the Deductible apply to the Out of Pocket maximum?"
                                        layout="horizontal-dense"
                                        model={`${id}.cost_sharing.deductible_applies_to_oop_max`}
                                      >
                                        <Radio label="Yes" value={true} />
                                        <Radio label="No" value={false} />
                                      </RadioGroup>
                                      <PercentageInput label="Co-Insurance" model={`${id}.cost_sharing.co_insurance_percentage`} />
                                      <AmountInput label="Co-Pay Amount" model={`${id}.cost_sharing.copay_amount`} />
                                    </Grid>
                                  </Flex>
                                </Grid>
                              </Tabs.Panel>

                              <Tabs.Panel name="pre_cert">
                                <Grid gap="0.5rem">
                                  <FormSection horizontal maxWidth="100%">
                                    <Input label="Pre-Certification Company Name" model={`${id}.pre_cert.company_name`} />
                                    <PhoneInput label="Pre-Cert Company Phone Number" model={`${id}.pre_cert.phone_no`} />
                                  </FormSection>

                                  <FormSection>
                                    <RadioGroup
                                      label="Is there a Pre-Cert Penalty?"
                                      layout="horizontal-dense"
                                      model={`${id}.pre_cert.has_penalty`}
                                    >
                                      <Radio label="Yes" value={true} />
                                      <Radio label="No" value={false} />
                                    </RadioGroup>

                                    {item.pre_cert?.has_penalty && (
                                      <Textarea label="Pre-Cert Penalty Terms" model={`${id}.pre_cert.penalty_terms`} />
                                    )}
                                  </FormSection>
                                </Grid>
                              </Tabs.Panel>

                              <Tabs.Panel name="insurance_visits">
                                <FormSection horizontal maxWidth="100%">
                                  <Input size={6} suffix="days" label="Days / Year" model={`${id}.insurance_visits.days_per_year`} />
                                  <Input size={6} suffix="days" label="Days Used" model={`${id}.insurance_visits.days_used`} />
                                  <Input size={6} suffix="days" label="Lifetime Max" model={`${id}.insurance_visits.lifetime_max`} />
                                  <Input
                                    size={6}
                                    suffix="days"
                                    label="Lifetime Limits Used"
                                    model={`${id}.insurance_visits.lifetime_limits_used`}
                                  />
                                </FormSection>
                              </Tabs.Panel>

                              <Tabs.Panel name="max_reimbursements">
                                <FormSection horizontal maxWidth="100%">
                                  <AmountInput size={7} label="Max Amount / Day" model={`${id}.max_reimbursements.max_amount_per_day`} />
                                  <AmountInput size={7} label="Annual Max" model={`${id}.max_reimbursements.annual_max`} />
                                  <AmountInput size={7} label="Annual Used" model={`${id}.max_reimbursements.annual_used`} />
                                  <AmountInput size={7} label="Lifetime Max" model={`${id}.max_reimbursements.lifetime_max`} />
                                  <AmountInput size={7} label="Lifetime Used" model={`${id}.max_reimbursements.lifetime_used`} />
                                </FormSection>
                              </Tabs.Panel>
                            </Tabs.Panels>
                          </Tabs>
                        )}
                      </ServiceRow>
                    </>
                  )
                })}
              </GridTable>
            </Card>
          )}
        </div>
      </Form>
    </Section>
  )
}

const ServiceRow: React.FC<any> = ({ children, cells }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleOpen = () => {
    setIsOpen((c) => !c)
  }

  return (
    <>
      <GridTable.Row css={styles.mainRow}>
        <GridTable.Cell centerX centerY>
          <Button
            hideLabel
            glyph="triangle_down"
            glyphSize={14}
            type="minimal"
            color="blue"
            onClick={toggleOpen}
            className={isOpen ? 'is-open' : ''}
            css={styles.toggleButton}
            size={200}
          />
        </GridTable.Cell>

        {cells}
      </GridTable.Row>

      <div className={isOpen ? '' : 'is-hidden'} css={styles.subRow}>
        <div css={{ gridColumn: '2 / -2' }}>{children}</div>
      </div>
    </>
  )
}

const styles = {
  mainRow: {
    '& > *, &:hover > *': {
      background: `${COLORS.white} !important`,
    },
  },

  subRow: {
    display: 'grid',
    gridTemplateColumns: 'var(--template-columns)',
    background: `${tint(0.25, COLORS.lightBackground)}`,
    borderBottom: `1px solid ${COLORS.divider}`,
    paddingBottom: '1rem',
  },

  toggleButton: {
    svg: {
      transform: 'rotate(-90deg) !important',
    },

    '&.is-open svg': {
      transform: 'rotate(0) !important',
    },
  },

  tabsList: {
    marginBottom: '1rem',
  },

  emptyState: {
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
    padding: '1rem',
    color: COLORS.textMuted,
    opacity: 0.8,
  },
}

export default withFormContext(VOBServices)
