import React from 'react'

import { COLORS } from '../../theme'
import { nicePercentage } from '../../utils/functions'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'

import Button from '../Button'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'
import SigneeStatus from '../Statuses/SigneeStatus'
import Status from '../Status'

const PayerCard = ({ payer, to, variant }) => {
  const { copy } = useCopyToClipboard({ text: payer?.ledger_link })

  return (
    <CardLink
      to={to}
      variant={variant}
      after={
        <Button label="Copy Ledger Link" onClick={copy} type="minimal" size={200} color="text" glyph="copy" glyphColor={COLORS.blue} />
      }
    >
      <CardTitle title={payer.reference.name} />
      <CardMeta>
        <SigneeStatus signee={payer.reference} />
        <Status label={`${nicePercentage(payer.percentage)}%`} color="green" />

        {/* TODO (Andrei): fix signature statuses after API updates */}

        {/* {payer.signed_at && <Status label="Signed" color="green" />}
        {!payer.signed_at && (payer.should_sign || payer.is_required) && (
          <Status label="Awaiting Signature" color="gray" />
        )} */}

        {/* {(!payer.should_sign || !payer.is_required) && (
          <Status label="Signature Not Required" color="gray" />
        )} */}
      </CardMeta>
    </CardLink>
  )
}

export default PayerCard
