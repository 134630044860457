import React from 'react'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Input from '../../../components/Forms/Input'
import Label from '../../../components/Label'
import Overlay from '../../../components/Overlay'
import Section from '../../../components/Section'
import Textarea from '../../../components/Forms/Textarea'

import { formatVariableKey } from '../utils/functions'
import { useFormBuilder } from '../useFormBuilder'
import { VariableKey } from './VariableKey'
import BaseInput from '../../../components/Forms/BaseInput'

import { useSettings } from '../../../hooks/useSettings'

export const FormVariableOverlay = ({ data, onClose, onSave }: any) => {
  const isNew = !data
  const form = React.useRef(null)

  const { timezone } = useSettings()

  const addFormVariable: any = useFormBuilder((state: any) => state.addFormVariable)
  const editFormVariable: any = useFormBuilder((state: any) => state.editFormVariable)
  const checkVariableKeyDuplicate: any = useFormBuilder((state: any) => state.checkVariableKeyDuplicate)

  const [name, setName] = React.useState(data?.name || '')
  const [description, setDescription] = React.useState(data?.description || '')
  const [variableKey, setVariableKey] = React.useState(data?.variable_key?.replace('FV_', '') || '')
  const [variableValue, setVariableValue] = React.useState(data?.variable_value || '')

  const [duplicate, setDuplicate] = React.useState(false)

  const isInSync = React.useMemo(() => {
    const keyByName = formatVariableKey(name)
    return keyByName === variableKey
  }, [name, variableKey])

  const handleSave = () => {
    const saveData = {
      ...data,
      name,
      description,
      variable_key: `FV_${variableKey}`,
      variable_value: variableValue,
      category: 'form',
    }

    const { isDuplicate } = checkVariableKeyDuplicate(`FV_${variableKey}`)

    if (isNew && isDuplicate) {
      setDuplicate(true)
      return
    }

    if (isNew) {
      addFormVariable(saveData)
    } else {
      editFormVariable(saveData)
    }

    onSave?.(saveData)
    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center">
      <Overlay.Header title={isNew ? 'Add Variable' : 'Edit Variable'} glyph="variables" />

      <Overlay.Content>
        <Form isCompact getForm={form} initialModel={data} timezone={timezone}>
          <Section>
            <FormSection>
              <Input
                label="Name"
                value={name}
                onUpdate={({ value }) => {
                  setName(value)

                  if (isNew && isInSync) {
                    setVariableKey(formatVariableKey(value))
                  }
                }}
                validations={{
                  presence: {
                    message: 'Please enter a variable name',
                  },
                }}
              />

              <div>
                <Label isRequired label="Variable Key" isValid={!!variableKey} />
                <BaseInput
                  value={variableKey}
                  onChange={(event) => {
                    const newValue = event.target.value || ''
                    setVariableKey(formatVariableKey(newValue?.toUpperCase()))
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter a variable key',
                    },
                  }}
                />
              </div>

              <div className="text-text-muted -mt-2">
                Variable key will be formatted as <VariableKey canCopy={false} variable={`FV_${variableKey || ''}`} />, where{' '}
                <span className="text-text bg-divider px-1.5 py-0.5 rounded-[4px] text-[0.8rem] font-mono">FV</span> is a prefix applied to
                all Form Variables
              </div>

              <Input
                label="Value"
                value={variableValue}
                onUpdate={({ value }) => {
                  setVariableValue(value)
                }}
                validations={{
                  presence: {
                    message: 'Please enter a variable value',
                  },
                }}
              />

              <Textarea
                label="Description"
                value={description}
                onUpdate={({ value }) => {
                  setDescription(value)
                }}
              />

              {duplicate && (
                <Alert small contrast glyph="warning" type="warning">
                  Variable key <b>{variableKey}</b> already exists, please use a different one.
                </Alert>
              )}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={isNew ? 'Add Variable' : 'Save Changes'}
          type="primary"
          color="green"
          size={300}
          onClick={handleSave}
          isDisabled={!name || !variableKey || !variableValue}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
