import React from 'react'
import startCase from 'lodash/startCase'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useSettings } from '../../hooks/useSettings'

import EmployeeStatus from '../../components/Statuses/EmployeeStatus'
import EmployeeQuickView from '../../components/Overlays/quick/EmployeeQuickView'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { FILTERS } from '../Filters/config'

export const EmployeesReportDataTable = (props: any) => {
  const { to } = props

  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.data.id}
            value={data.data.name}
            avatar={data.data.avatar}
            to={to?.(data.data)}
            quickViewOverlay={<EmployeeQuickView employee={data.data} />}
          />
        ),
      },
      {
        width: 160,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ data, value }: any) => <EmployeeStatus status={value} />,
      },
      {
        width: 160,
        id: 'position',
        model: 'data.position',
        title: 'Position',
        formatValue: ({ data, value }: any) => startCase(value),
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'data.phone_no',
        title: 'Phone',
      },
      {
        width: 160,
        id: 'email',
        model: 'data.email',
        title: 'Email',
      },
      {
        width: 180,
        id: 'is_shared_with_portal',
        model: 'data.is_shared_with_portal',
        title: 'Client Portal Sharing',
        type: 'boolean',
      },
      {
        width: 150,
        id: 'is_blocked',
        model: 'data.is_blocked',
        title: 'Is Blocked',
      },
      {
        width: 190,
        id: 'last_logged_in_at',
        model: 'data.last_logged_in_at',
        title: 'Last Logged in At',
        type: 'date_time',
      },
      {
        width: 140,
        id: 'last_name',
        model: 'data.last_name',
        title: 'Last Name',
      },
    ]
  }, [to])

  return (
    <DataTable
      testKey="employees_report_data_table"
      asCard
      title="Staff"
      icon="employees"
      columns={columns}
      filtersConfig={FILTERS.live_reports.employees}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="staff"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=staff'}
        />
      }
    />
  )
}
