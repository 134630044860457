import React from 'react'
import compact from 'lodash/compact'
import isEqual from 'lodash/isEqual'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { isDefined } from '../../../utils/functions'
import { useGet } from '../../../hooks/useNewAPI'

import Badge from '../../Badge'
import Button from '../../Button'
import Flex from '../../Flex'
import Glyph from '../../../components/Glyph'
import State from '../../State'

import { FilterDropdown } from './FilterDropdown'
import { FilterDropdownGroup } from '../filters/FilterDropdownGroup'
import { PopoverItem } from './PopoverItem'

import { useStore } from '../useStore'

const CONDITIONS: any = {
  in: 'includes',
  not_in: 'not includes',
}

const CONDITION_GLYPHS: any = {
  in: 'between',
  not_in: 'not_equal',
}

export const SmartFlags = (props: any) => {
  const { config, onUpdate, filter, filterKey } = props

  const initialCondition = Object.keys(CONDITIONS).includes(filter?.condition) ? filter?.condition : 'in'

  const [isOpen, setIsOpen] = React.useState(false)
  const [flagIds, setFlagIds]: any = React.useState(filter?.value || [])

  const [condition, setCondition] = React.useState(initialCondition)
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  const removeFilter: any = useStore((state: any) => state.removeFilter)

  const { data, isLoading }: any = useGet({
    name: ['smart-flags'],
    url: `/smart_flags`,
  })

  const isEmpty = size(data) === 0

  const activeFlags = React.useMemo(() => {
    const result: any = []
    const flagsMap: any = {}

    if (size(filter?.value) === 0 || size(data) === 0) return result

    for (const flag of data) {
      flagsMap[flag.id] = flag
    }

    for (const id of filter.value) {
      if (!flagsMap?.[id]) continue

      result.push(flagsMap[id])
    }

    return result
  }, [data, filter])

  const activeFlagsCount = size(activeFlags)
  const hasActiveFlags = activeFlagsCount >= 1

  const handleApply = () => {
    onUpdate?.({ condition, value: flagIds })
    setIsOpen(false)
  }

  const handleClear = () => {
    setFlagIds([])
    removeFilter(filterKey)
    setIsOpen(false)
  }

  React.useEffect(() => {
    if (!isEqual(flagIds, filter?.value)) setFlagIds(filter?.value || [])
  }, [filter?.value])

  React.useEffect(() => {
    if (isDefined(filter?.condition) && !isEqual(condition, filter?.condition)) setCondition(filter?.condition)
  }, [filter?.condition])

  return (
    <div className="flex items-center flex-nowrap">
      <FilterDropdown
        label={CONDITIONS[condition]}
        hasActiveFilters={hasActiveFlags}
        open={isDropdownOpen}
        className="mr-2"
        onOpenChange={setIsDropdownOpen}
      >
        {Object.keys(CONDITIONS).map((key) => (
          <PopoverItem
            key={key}
            title={CONDITIONS[key]}
            isActive={condition === key}
            onClick={() => {
              setCondition(key)
              setIsDropdownOpen(false)

              if (size(flagIds) > 0 && !isEqual(key, filter?.condition)) {
                onUpdate?.({ condition: key, value: flagIds })
              }
            }}
            graphic={<Glyph glyph={CONDITION_GLYPHS[key]} color={COLORS.textMuted} size={16} />}
          />
        ))}
      </FilterDropdown>

      <FilterDropdown
        className="grow"
        open={isOpen}
        onOpenChange={(newIsOpen: boolean) => {
          // apply filter when dropdown is closed and values are different
          if (!newIsOpen && !isEqual(flagIds, filter?.value)) {
            onUpdate?.({ condition, value: flagIds })
          }

          if (size(flagIds) === 0) removeFilter(filterKey)

          setIsOpen(newIsOpen)
        }}
        testKey="smart_flags_filter_dropdown"
        isEmpty={isEmpty}
        isLoading={isLoading}
        hasActiveFilters={hasActiveFlags}
        label={
          activeFlagsCount ? (
            <>
              <Badge size={16} children={activeFlagsCount} className="mr-1" /> selected
            </>
          ) : (
            'Select…'
          )
        }
      >
        {!data || isLoading || isEmpty ? (
          <State title="Smart Flags" isEmpty={isEmpty} isLoading={isLoading} emptyDescription="No smart flags created yet" />
        ) : (
          data.map((flag: any) => {
            return (
              <PopoverItem
                key={flag.id}
                title={flag.name}
                graphic={<Glyph glyph={flag.glyph || 'flag'} size={14} color={flag.color || COLORS.textStronglyMuted} />}
                isActive={flagIds.includes(flag.id)}
                onClick={() => {
                  setFlagIds((currentValue: any = {}) => {
                    return produce(currentValue, (draft: any) => {
                      if (draft.includes(flag.id)) {
                        const index = draft.indexOf(flag.id)
                        draft.splice(index, 1)
                        return
                      }

                      draft.push(flag.id)
                    })
                  })
                }}
              />
            )
          })
        )}

        {size(data) > 0 && (
          <div className="flex items-center px-2 py-2 sticky bottom-0 bg-white border-t border-0 border-solid border-divider overflow-hidden">
            <Button
              label="Apply"
              glyph="tick_circle"
              color="green"
              type="primary"
              size={100}
              className="flex-grow-3"
              onClick={handleApply}
            />
            <Button
              label="Clear All"
              glyph="cross"
              color="red"
              size={100}
              className="flex-grow-0 ml-2"
              isDisabled={size(flagIds) === 0}
              onClick={handleClear}
            />
          </div>
        )}
      </FilterDropdown>
    </div>
  )
}
