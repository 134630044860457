import { COLORS, FEATURES } from '../../theme'

// status from: pending, cancelled, accepted, declined, archived
// status_to: pending, accepted_data, accepted_referral, declined

export const REFERRAL_STATUSES = {
  all: {
    label: 'All',
    color: 'orange',
  },
  pending: {
    label: 'Pending',
    color: 'orange',
  },
  in_progress: {
    label: 'In Progress',
    color: 'blue',
  },
  accepted: {
    label: 'Accepted',
    color: 'green',
  },
  declined: {
    label: 'Declined',
    color: 'green',
  },
  accepted_data: {
    label: 'Confirmed Availability',
    color: 'green',
  },
  declined_data: {
    label: 'Declined Referral',
    color: 'red',
  },
  accepted_referral: {
    label: 'Accepted Referral',
    color: 'green',
  },
  declined_referral: {
    label: 'Declined Referral',
    color: 'red',
  },
  cancelled: {
    label: 'Cancelled',
    color: 'red',
  },
  // archived: {
  //   label: 'Archived',
  //   color: 'gray',
  // },
}

export const DATA_TRANSFER_GROUPS = [
  {
    id: 'general',
    label: 'General',
    items: [
      // { id: 'client_face_sheet', label: 'Client Face Sheet', icon: FEATURES.clients.icon },
      { id: 'to_do_s', label: "To-Do's", icon: FEATURES.todos.icon },
      { id: 'events', label: 'Events', icon: FEATURES.calendar.icon },
      { id: 'files', label: 'Files', icon: FEATURES.files.icon },
      { id: 'location_check_ins', label: 'Location Check-Ins', icon: FEATURES.location_checkin.icon },
    ],
  },
  {
    id: 'care_coordination',
    label: 'Care Coordination',
    items: [
      { id: 'intake_applications', label: 'Intake Applications', icon: FEATURES.applications.icon },
      { id: 'intakes', label: 'Intakes', icon: FEATURES.intake_forms.icon },
      { id: 'agreements', label: 'Agreements', icon: FEATURES.agreements.icon },
      { id: 'discharges', label: 'Discharges', icon: FEATURES.discharges.icon },
    ],
  },
  {
    id: 'contacts',
    label: 'Contacts',
    items: [
      { id: 'contacts', label: 'Contacts', icon: FEATURES.contacts.icon },
      { id: 'communications', label: 'Communications', icon: FEATURES.communications.icon },
    ],
  },
  {
    id: 'financials',
    label: 'Financials',
    items: [
      { id: 'client_charges_summary', label: 'Client Charges Summary', icon: FEATURES.financials.icon },
      { id: 'client_payments_summary', label: 'Client Payments Summary', icon: FEATURES.financials.icon },
    ],
  },
  {
    id: 'insurance',
    label: 'Insurance',
    items: [{ id: 'insurance_policies', label: 'Insurance Policies', icon: FEATURES.insurance_policies.icon }],
  },
  {
    id: 'notes',
    label: 'Notes',
    items: [
      { id: 'shift_notes', label: 'Shift Notes', icon: FEATURES.shift_notes.icon },
      { id: 'recovery_coaching', label: 'Recovery Notes', icon: FEATURES.recovery_coaching.icon },
      { id: 'custom_notes', label: 'Custom Notes', icon: FEATURES.custom_notes.icon },
    ],
  },
  {
    id: 'clinical',
    label: 'Clinical',
    items: [
      { id: 'clinical_assessments', label: 'Clinical Assessments', icon: FEATURES.clinical_assessments.icon },
      { id: 'treatment_plans', label: 'Treatment Plans', icon: FEATURES.treatment_plans.icon },
      { id: 'progress_reviews', label: 'Progress Reviews', icon: FEATURES.progress_reviews.icon },
      { id: 'clinical_notes', label: 'Clinical Notes', icon: FEATURES.clinical_notes.icon },
      { id: 'clinical_measures', label: 'Clinical Measures', icon: FEATURES.clinical_measures.icon },
      { id: 'outcome_measures', label: 'Outcome Measures', icon: FEATURES.outcome_measures.icon },
    ],
  },
  {
    id: 'medical',
    label: 'Medical',
    items: [
      { id: 'ua_tests', label: 'UA Tests', icon: FEATURES.test_results.icon },
      { id: 'breathalyzer_tests', label: 'Breathalyzer Tests', icon: FEATURES.test_results.icon },
      { id: 'vital_signs', label: 'Vital Signs', icon: FEATURES.test_results.icon },
      { id: 'communicable_disease_tests', label: 'Communicable Disease Tests', icon: FEATURES.test_results.icon },
      { id: 'medications', label: 'Medications', icon: FEATURES.medical_assessments.icon },
      { id: 'medication_logs', label: 'Medication Logs', icon: FEATURES.medication_logs.icon },
      { id: 'medication_destruction_logs', label: 'Medication Destruction Logs', icon: FEATURES.medications.icon },
      { id: 'medical_assessments', label: 'Medical Assessments', icon: FEATURES.medical_assessments.icon },
      { id: 'physician_orders', label: 'Physician Orders', icon: FEATURES.physician_orders.icon },
      { id: 'nursing', label: 'Nursing', icon: FEATURES.nursing.icon },
    ],
  },
]

export const DATA_TRANSFER_CATEGORIES = DATA_TRANSFER_GROUPS.reduce((acc, group) => {
  return [...acc, ...group.items]
}, [])
