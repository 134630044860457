import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { TOKENS } from '../theme'

const animations = {
  fade: {
    opacity: 1,
    transition: `opacity 250ms ${TOKENS.easeOutCubic}`,

    '&.fade-enter': { opacity: 0 },
    '&.fade-enter.fade-enter-active': { opacity: 0 },

    '&.fade-exit': { opacity: 0 },
    '&.fade-exit.fade-exit-active': { opacity: 1 },
    '&.fade-exit-done': { opacity: 1 },
  },
}

type Props = {
  animation: keyof typeof animations
  timeout: any
  show: boolean
}

const Animated: React.FC<Props> = ({ animation = 'fade', children, timeout = { enter: 400, exit: 400 }, show }) => (
  <TransitionGroup component={null}>
    {show && (
      <CSSTransition classNames={animation} timeout={timeout} css={animations[animation]}>
        {children}
      </CSSTransition>
    )}
  </TransitionGroup>
)

export default Animated
