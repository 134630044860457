import React from 'react'
import clsx from 'clsx'

import Card from '../../components/Card'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Status from '../../components/Status'
import URLInput from '../../components/Forms/URLInput'

import { PlainSwitch } from '../../components/Forms/Switch'

export const QuoteIframeForm = (props: any) => {
  const { model, formData = {}, isEditable, onUpdate } = props

  const switchModel = `use_${model}_iframe`
  const labelModel = `${model}_label`
  const urlModel = `${model}_url`

  const isEnabled = !!formData?.iframes_settings?.[switchModel]

  const handleIframeSwitch = () => {
    onUpdate({
      iframes_settings: {
        ...formData.iframes_settings,
        [switchModel]: !isEnabled,
        [labelModel]: formData?.iframes_settings?.[labelModel] || 'More Details',
        [urlModel]: formData?.iframes_settings?.[urlModel] || '',
      },
    })
  }

  if (!model) return null

  return (
    <Card className="px-3 py-2">
      {!isEditable && !isEnabled && <Status small label="Iframe Not Enabled" />}
      {!isEditable && isEnabled && <Status small label="Iframe Enabled" color="blue" className="mb-2" />}

      <div className={clsx('flex-nowrap items-center whitespace-nowrap', isEditable ? 'flex' : 'hidden')}>
        <PlainSwitch isChecked={isEnabled} size={100} withHover={false} onCheckedChange={handleIframeSwitch} />
        <Label isCompact label="Enable Section Iframe" className="!p-0 ml-1.5 !cursor-pointer" onClick={handleIframeSwitch} />
      </div>

      {isEnabled && (
        <div className="grid gap-3 pb-1 mt-3">
          <Input
            label="Iframe Button Label"
            defaultValue="More Details"
            value={formData?.iframes_settings?.[labelModel]}
            onUpdate={({ value }) => {
              onUpdate({
                iframes_settings: {
                  ...formData?.iframes_settings,
                  [labelModel]: value,
                },
              })
            }}
          />

          <URLInput
            label="Iframe URL"
            value={formData?.iframes_settings?.[urlModel]}
            onUpdate={({ value }) => {
              onUpdate({
                iframes_settings: {
                  ...formData?.iframes_settings,
                  [urlModel]: value,
                },
              })
            }}
          />
        </div>
      )}
    </Card>
  )
}
