import React from 'react'

import { titleCase } from '../../../../utils/functions'
import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

export const ProvidersTable = ({ onSelect, dependentValue, tenant, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-providers'],
    endpoint: `/facilities/${tenant?.id}/credentials`,
    params: { category: 'npi', level: 'organization' },
    ...(tenant && {
      headers: {
        'X-Tenant': tenant?.subdomain,
      },
    }),
    localStorageKey: 'selector_providers_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'credential',
        model: 'credential',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell testKey="main_cell" id={data.id} value={data.credential} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'NPI',
        model: 'license_number',
        width: 200,
      },
      {
        title: 'Level',
        model: 'level',
        width: 150,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return titleCase(value)
        },
      },
      {
        title: 'First Name',
        model: 'first_name',
        width: 150,
      },
      {
        title: 'Last Name',
        model: 'last_name',
        width: 150,
      },
      {
        title: 'Organization Name',
        model: 'organization',
        width: 150,
      },
    ],
    [],
  )

  return <DataTable {...tableProps} {...rest} icon="staff_credentials" title="Providers" columns={columns} filtersConfig={FILTERS_CONFIG} />
}

const FILTERS_CONFIG = {
  credential: {
    title: 'Name',
    type: 'string',
  },
  license_number: {
    title: 'NPI',
    type: 'string',
  },
  level: {
    label: 'Level',
    type: 'multi_select',
    options: [
      {
        label: 'Individual',
        value: 'individual',
      },
      {
        label: 'Organization',
        value: 'organization',
      },
    ],
  },
  first_name: {
    title: 'First Name',
    type: 'string',
  },
  last_name: {
    title: 'Last Name',
    type: 'string',
  },
  organization: {
    title: 'Organization Name',
    type: 'string',
  },
}
