import React from 'react'

import withSettings from '../../../hocs/withSettings'

import Alert from '../../../components/Alert'
import Card from '../../../components/Card'
import ContextShow from '../../../components/Forms/ContextShow'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Flex from '../../../components/Flex'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Grid from '../../../components/Grid'
import Icon from '../../../components/Icon'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import SeatSelector from '../../../components/Forms/SeatSelector'
import TreeItem from '../../../components/TreeItem'
import Workflow from '../../../components/Workflow/Workflow'
import { VisualHide } from '../../../components/VisualHide'

import { ProgramAssignmentInfo } from '../../Programs/ProgramAssignmentInfo'

const ProgramMove = ({ data, setData, setStatus, timezone, occupancy }: any) => {
  const [valid, setValid] = React.useState()

  const { goNext }: any = React.useContext(Workflow.Context)

  const [place, setPlace] = React.useState(data?.place)
  const [showCurrentDetails, setShowCurrentDetails] = React.useState(false)
  const [showNextDetails, setShowNextDetails] = React.useState(false)

  const toggleCurrentDetails = () => setShowCurrentDetails((c) => !c)
  const toggleNextDetails = () => setShowNextDetails((c) => !c)

  const handleUpdate = (value: any) => {
    setData({
      ...value,
      place: place,
    })
  }

  React.useEffect(() => {
    valid ? setStatus('completed') : setStatus('todo')
  }, [valid])

  return (
    <Form useLocalModel initialModel={data} onUpdate={handleUpdate} onValidationUpdate={setValid} timezone={timezone}>
      <FormSection>
        <Card>
          <Flex centerY gap="0.75rem" css={{ padding: '0.7rem 1rem 0.4rem' }}>
            <Icon icon="programs" size={20} />
            <div className="text-[1.1rem] font-[700]">Current Program Assignment</div>
          </Flex>

          <ProgramAssignmentInfo data={occupancy} showCurrentDetails={showCurrentDetails} toggleCurrentDetails={toggleCurrentDetails} />

          <VisualHide when={!showCurrentDetails}>
            <Grid gap="1rem" css={{ padding: '0.5rem 1rem 0.75rem' }}>
              <RadioGroup
                label="When would you like to end the Current Program Assignment?"
                model="use_custom_move_date"
                layout="horizontal-dense"
                defaultValue={false}
              >
                <Radio label="End Now" value={false} />
                <Radio label="Another Time" value={true} />
              </RadioGroup>

              <ContextShow when="use_custom_move_date" is={true}>
                <DateTimeInput
                  defaultToNow
                  label="Current Program Assignment End Date"
                  model="custom_moved_at"
                  validations={{
                    presence: {
                      message: 'Please enter an end date',
                    },
                  }}
                />

                <Alert type="warning">
                  Please select the date when the above <strong>Program Assignment</strong> should end.
                </Alert>
              </ContextShow>
            </Grid>
          </VisualHide>
        </Card>

        <Card>
          <Flex centerY gap="0.75rem" css={{ padding: '0.7rem 1rem 0.4rem' }}>
            <Icon icon="programs" size={20} />
            <div className="text-[1.1rem] font-[700]">New Program Assignment</div>
          </Flex>

          <Grid gap="1rem" css={{ padding: '0 1rem 0.75rem' }}>
            <SeatSelector
              isPolymorphic
              label="New Program Seat"
              fromDate={data?.started_at}
              untilDate={data?.ended_at}
              fromLabel="Occupy From"
              untilLabel="Occupy Until"
              setFromDate={(date) => {
                setData({ started_at: date })
              }}
              setUntilDate={(date) => {
                setData({ ended_at: date })
              }}
              model="place"
              validations={{
                presence: {
                  message: 'Please select a seat',
                },
              }}
              afterChange={({ value }: any) => setPlace(value)}
            />
          </Grid>

          <TreeItem title="More Options" isOpen={showNextDetails} onClick={toggleNextDetails} />

          <VisualHide when={!showNextDetails}>
            <Grid gap="1rem" css={{ padding: '0 1rem 0.75rem' }}>
              <DateTimeInput
                defaultToNow
                label="Occupy From"
                model="started_at"
                value={data?.started_at}
                onUpdate={({ value }: any) => {
                  setData({ started_at: value })
                }}
                validations={{
                  presence: {
                    message: 'Please enter a start date',
                  },
                }}
              />

              <RadioGroup label="Occupy Period" model="period_type" layout="horizontal-dense" defaultValue="indefinite">
                <Radio label="Until Discharged" value="indefinite" />
                <Radio label="Until Date" value="set_datetime" />
              </RadioGroup>

              <ContextShow when="period_type" is="set_datetime">
                <DateTimeInput
                  defaultToTomorrow
                  label="Occupy Until"
                  model="ended_at"
                  value={data?.ended_at}
                  onUpdate={({ value }: any) => {
                    setData({ ended_at: value })
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter an end date',
                    },
                    datetime: {
                      earliest: data?.started_at,
                      message: `Please enter a date after "Occupy From" date`,
                    },
                  }}
                />
              </ContextShow>
            </Grid>
          </VisualHide>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={goNext} isDisabled={!valid} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default withSettings(ProgramMove)
