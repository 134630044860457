import React from 'react'

import SmartStatus from '../../components/SmartStatus'

const statuses = {
  active: {
    label: 'Active',
    color: 'green',
  },
  inactive: {
    label: 'Inactive',
    color: 'gray',
  },
}

export const DataFormSettingsStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}
