import React from 'react'

import ContextShow from '../ContextShow'
import Input from '../Input'
import Option from '../Option'
import Select from '../Select'

const GenderIdentitySelect = ({ testKey, label, model, isRequired }) => (
  <>
    <Select
      allowEmpty
      testKey={testKey}
      label={label}
      model={model}
      validations={
        isRequired && {
          presence: {
            message: 'Please select a gender identity',
          },
        }
      }
    >
      <Option label="Male" value="male" />
      <Option label="Female" value="female" />
      <Option label="Transgender Male / Transman / FTM" value="transman" />
      <Option label="Transgender Female / Transwoman / MTF" value="transwoman" />
      <Option label="Gender Queer" value="queer" />
      <Option label="Decline to respond" value="decline_to_respond" />
      <Option label="Other" value="other" />
    </Select>

    <ContextShow when={model} is="other">
      <Input label="Please specify the other gender identity" model={`${model}_other`} />
    </ContextShow>
  </>
)

GenderIdentitySelect.defaultProps = {
  label: 'Gender Identity',
  model: 'gender_identity',
  validateOn: 'blur-change',
}

export default GenderIdentitySelect
