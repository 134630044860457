import React from 'react'

import SmartStatus from '../../../components/SmartStatus'

const statuses = {
  plan_create: {
    label: 'Plan Create',
    color: 'blue',
  },
  plan_update: {
    label: 'Plan Update',
    color: 'yellow',
  },
  plan_delete: {
    label: 'Plan Delete',
    color: 'red',
  },
  plan_settings_changed: {
    label: 'Plan Settings Changed',
    color: 'gray',
  },
  plan_custom_sections_changed: {
    label: 'Plan Custom Sections Changed',
    color: 'gray',
  },
  staff_signed: {
    label: 'Staff Signed',
    color: 'blue',
  },
  supervisor_signed: {
    label: 'Supervisor Signed',
    color: 'blue',
  },
  plan_status_change: {
    label: 'Plan Status Change',
    color: 'gray',
  },
  client_signature_status_change: {
    label: 'Plan Status Change',
    color: 'gray',
  },
  supervisor_signature_status_change: {
    label: 'Plan Status Change',
    color: 'gray',
  },
  staff_signature_status_change: {
    label: 'Plan Status Change',
    color: 'gray',
  },
  problem_create: {
    label: 'Problem Create',
    color: 'blue',
  },
  problem_update: {
    label: 'Problem Update',
    color: 'yellow',
  },
  problem_delete: {
    label: 'Problem Delete',
    color: 'red',
  },
  goal_create: {
    label: 'Goal Create',
    color: 'blue',
  },
  goal_update: {
    label: 'Goal Update',
    color: 'yellow',
  },
  goal_delete: {
    label: 'Goal Delete',
    color: 'red',
  },
  objective_create: {
    label: 'Objective Create',
    color: 'blue',
  },
  objective_update: {
    label: 'Objective Update',
    color: 'yellow',
  },
  objective_delete: {
    label: 'Objective Delete',
    color: 'red',
  },
  intervention_create: {
    label: 'Intervention Create',
    color: 'blue',
  },
  intervention_update: {
    label: 'Intervention Update',
    color: 'yellow',
  },
  intervention_delete: {
    label: 'Intervention Delete',
    color: 'red',
  },
  appointment_create: {
    label: 'Appointment Create',
    color: 'blue',
  },
  appointment_update: {
    label: 'Appointment Update',
    color: 'yellow',
  },
  appointment_delete: {
    label: 'Appointment Delete',
    color: 'red',
  },
  diagnosis_create: {
    label: 'Diagnosis Create',
    color: 'blue',
  },
  diagnosis_delete: {
    label: 'Diagnosis Delete',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
