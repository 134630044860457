import React from 'react'
import { Route, Switch, Redirect, useRouteMatch, useLocation, useParams } from 'react-router-dom'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Grid, Page, Tab, TabList, Tabs, HelpTagIframe } from '@behavehealth/components'

import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

import BreathalyzerTests from './test_results/breathalyzer_tests'
import CommunicableDiseaseTests from './test_results/communicable_disease_tests'
import Labs from './test_results/labs'
import UATests from './test_results/ua_tests'
import VitalSigns from './test_results/vital_signs'

const pageConfig = {
  feature: 'test_results',
  help: <HelpTagIframe id="test_results" />,
  marketingID: 'test_results',
}

type Props = {
  canCreate: boolean
}

const TestResults: React.FC<Props> = ({ canCreate = true }) => {
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const uaSelected = pathname.includes(`${url}/ua-tests`)
  const breathalyzerSelected = pathname.includes(`${url}/breathalyzer-tests`)
  const vitalSignsSelected = pathname.includes(`${url}/vital-signs`)
  const communicableDiseaseTestsSelected = pathname.includes(`${url}/communicable-disease-tests`)
  const labsSelected = pathname.includes(`${url}/labs`)

  return (
    <Page
      actions={
        canCreate && (
          <>
            {uaSelected && (
              <Dropdown
                label="Add UA Tests…"
                glyph="add"
                buttonType="primary"
                permission="test_results.ua_tests.create"
              >
                <DropdownItem
                  label="Add UA Test"
                  icon="test_results"
                  color="blue"
                  link={{
                    pathname: `${url}/ua-tests/new`,
                    data: {
                      reference: client,
                    },
                  }}
                />

                <DropdownItem
                  label="Add Many UA Tests…"
                  glyph="stack"
                  color="blue"
                  featureFlagV2="bulk_add"
                  link={{
                    pathname: `${url}/ua-tests/new-batch`,
                    data: {
                      reference: client,
                    },
                  }}
                />
              </Dropdown>
            )}

            {breathalyzerSelected && (
              <Dropdown label="Add Breathalyzer Test…" buttonType="primary" permission="test_results.breathalyzer_tests.create">
                <DropdownItem
                  label="Add Breathalyzer Test"
                  icon="test_results"
                  color="blue"
                  link={{
                    pathname: `${url}/breathalyzer-tests/new`,
                    data: {
                      resident: client,
                    },
                  }}
                />

                <DropdownItem
                  label="Add Many Breathalyzer Tests…"
                  glyph="stack"
                  color="blue"
                  featureFlagV2="bulk_add"
                  link={{
                    pathname: `${url}/breathalyzer-tests/new-batch`,
                  }}
                />
              </Dropdown>
            )}

            {vitalSignsSelected && (
              <Button
                label="Add Vital Signs"
                glyph="add"
                type="primary"
                link={{
                  pathname: `${url}/vital-signs/new`,
                  data: {
                    client: client,
                  },
                }}
                permission="test_results.vital_signs.create"
              />
            )}

            {communicableDiseaseTestsSelected && (
              <Button
                label="Add Communicable Disease Test"
                glyph="add"
                type="primary"
                link={{
                  pathname: `${url}/communicable-disease-tests/new`,
                  data: {
                    client: client,
                  },
                }}
                permission="test_results.communicable_disease_tests.create"
              />
            )}

            {labsSelected && (
              <Button
                label="Add Lab Test"
                glyph="add"
                type="primary"
                link={{
                  pathname: `${url}/labs/new`,
                  data: {
                    client: client,
                  },
                }}
                permission="test_results.labs.create"
              />
            )}
          </>
        )
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        <Tabs>
          <TabList>
            <Tab
              showForCommunity
              label="UA Tests"
              to={`${url}/ua-tests`}
              permission="test_results.ua_tests.view"
              featureFlagV2="ua_tests"
            />
            <Tab
              showForCommunity
              label="Breathalyzer Tests"
              to={`${url}/breathalyzer-tests`}
              permission="test_results.breathalyzer_tests.view"
              featureFlagV2="breathalyzer_tests"
            />
            <Tab
              label="Vital Signs"
              to={`${url}/vital-signs`}
              newFeatureFlag="lab_tests_2"
              permission="test_results.vital_signs.view"
              featureFlagV2="vital_signs"
            />
            <Tab
              label="Communicable Disease Tests"
              to={`${url}/communicable-disease-tests`}
              newFeatureFlag="lab_tests_2"
              permission="test_results.communicable_disease_tests.view"
              featureFlagV2="communicable_disease_tests"
            />
            <Tab
              label="Labs"
              to={`${url}/labs`}
              newFeatureFlag="lab_tests_2"
              permission="test_results.labs.view"
              featureFlagV2="labs"
            />
          </TabList>
        </Tabs>

        <Switch>
          <Route
            path={`/(admissions|clients|alumni)/:resource_id/test-results/ua-tests`}
            render={() => <UATests permission="test_results.ua_tests.view" />}
          />
          <Route
            path={`/(admissions|clients|alumni)/:resource_id/test-results/breathalyzer-tests`}
            render={() => <BreathalyzerTests permission="test_results.breathalyzer_tests.view" />}
          />
          <Route
            path={`/(admissions|clients|alumni)/:resource_id/test-results/vital-signs`}
            render={() => <VitalSigns permission="test_results.vital_signs.view" />}
          />
          <Route
            path={`/(admissions|clients|alumni)/:resource_id/test-results/communicable-disease-tests`}
            render={() => <CommunicableDiseaseTests permission="test_results.communicable_disease_tests.view" />}
          />
          <Route
            path={`/(admissions|clients|alumni)/:resource_id/test-results/labs`}
            render={() => <Labs permission="test_results.labs.view" />}
          />

          <Redirect exact from={url} to={`${url}/ua-tests`} />
        </Switch>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(TestResults, pageConfig))
