import React from 'react'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Flex from '@behavehealth/components/Flex'
import { BedsTimeline } from '@behavehealth/constructs/BedManagement/BedsTimeline'
import { NotionNotice } from '@behavehealth/components/NotionNotice'

const RootBedManagementTimeline = () => {
  const [dates, setDates]: any = React.useState({})
  const [filters, setFilters] = React.useState({})

  const hasDates = !!dates?.startDate && !!dates?.endDate

  const queryParams = {
    filters,
    ...(hasDates && {
      from: dates.startDate,
      until: dates.endDate,
    }),
  }

  const { data, isLoading, isRefetching }: any = useGet({
    name: ['bed-management-timeline', queryParams],
    url: `/bed_management/timeline`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: hasDates },
  })

  const { bedOccupancies, stats } = React.useMemo(() => {
    return processData(data)
  }, [data])

  return (
    <div css={styles.root}>
      <div css={styles.timelineWrapper}>
        <BedsTimeline
          css={styles.timeline}
          dates={dates}
          setDates={setDates}
          houses={data?.houses}
          applicants={data?.applicants}
          unassigned={data?.unassigned}
          bedOccupancies={bedOccupancies}
          dailyActiveHouseOccupancies={data?.daily_active_house_occupancies}
          todayOccupancyStats={data?.today_occupancy_stats}
          isLoading={isLoading}
          isRefetching={isRefetching}
          stats={stats}
        />
      </div>
    </div>
  )
}

const processData = (data: any) => {
  const result = {
    stats: { beds: {}, rooms: {}, houses: {} },
    bedOccupancies: {},
  }

  if (!data?.occupancies || !data?.houses) return result

  // TODO: remove occupied beds calculations (handled by API now)
  // set up houses data
  for (const house of data.houses) {
    result.stats.houses[house.id] = {
      occupiedBedIds: [],
      bedsCount: house.beds_count,
      occupiedBedsCount: 0,
    }

    // set up rooms data
    for (const floor of house.floors) {
      for (const room of floor.rooms) {
        result.stats.rooms[room.id] = {
          occupiedBedIds: [],
          bedsCount: room.beds_count,
          occupiedBedsCount: 0,
        }

        // set up beds data
        for (const bed of room.beds) {
          result.bedOccupancies[bed.id] = []
          result.stats.beds[bed.id] = { isOccupied: false }
        }
      }
    }
  }

  for (const occupancy of data.occupancies) {
    const bed = occupancy?.place
    const room = bed?.room
    const house = bed?.house

    if (!bed || !room || !house) {
      console.error(`Occupancy "${occupancy.id}" does not have a Bed, Room or House`)
      continue
    }

    // occupancy must be linked to a place of type bed
    if (bed.type !== 'bed') {
      console.error(`Occupancy "${occupancy.id}" is linked to a place of type "${bed.type}" and not "bed"`)
      continue
    }

    // add bed ID to house if it doesn't already exist and increment count
    if (result.stats.houses[house.id]?.occupiedBedIds && !result.stats.houses[house.id]?.occupiedBedIds?.includes?.(bed.id)) {
      result.stats.houses[house.id].occupiedBedIds.push(bed.id)

      const currentCount = result.stats.houses[house.id].occupiedBedsCount
      result.stats.houses[house.id].occupiedBedsCount = currentCount + 1
    }

    // add bed ID to room if it doesn't already exist and increment count
    if (result.stats.rooms[room.id]?.occupiedBedIds && !result.stats.rooms[room.id]?.occupiedBedIds?.includes?.(bed.id)) {
      result.stats.rooms[room.id].occupiedBedIds.push(bed.id)

      const currentCount = result.stats.rooms[room.id].occupiedBedsCount
      result.stats.rooms[room.id].occupiedBedsCount = currentCount + 1
    }

    // flag bed as occupied
    if (result.stats.beds[bed.id]) {
      result.stats.beds[bed.id].isOccupied = true
    }

    // add occupancy
    if (result.bedOccupancies[bed.id]) {
      result.bedOccupancies[bed.id].push(occupancy)
    }
  }

  return result
}

const styles: any = {
  root: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    overflow: 'hidden',
  },

  section: {
    paddingTop: '1rem',
    paddingLeft: '1rem',
  },

  timeline: {
    flex: '1 1 auto',
  },

  timelineWrapper: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    paddingTop: '1rem',
    paddingLeft: '1rem',
    overflow: 'hidden',
    flex: '1 1 auto',
  },
}

export const BedManagementTimeline = withPageError(RootBedManagementTimeline)
