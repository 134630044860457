import React from 'react'

import { COLORS } from '../../theme'
import { useCreate } from '../../hooks/useNewAPI'
import { titleCase } from '../../utils/functions'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Overlay from '../../components/Overlay'
import Status from '../../components/Status'
import Logo from '../../components/Logo'

export const AppInviteOverlay = (props: any) => {
  const { data, onClose } = props

  const { mutateAsync: acceptAsync, isLoading: isAccepting } = useCreate({
    name: ['invites', 'accept'],
    url: `/invites/${data?.id}/accept`,
  })

  const { mutateAsync: declineAsync, isLoading: isDeclining } = useCreate({
    name: ['invites', 'accept'],
    url: `/invites/${data?.id}/decline`,
  })

  const handleAccept = async () => {
    try {
      await acceptAsync({})

      window.location.reload()
    } catch (error) {
      console.debug(error)
    }
  }

  const handleDecline = async () => {
    try {
      await declineAsync({})

      window.location.reload()
    } catch (error) {
      console.debug(error)
    }
  }

  if (!data) return null

  const { subject, tenant, message } = data

  return (
    <Overlay showBackdrop position="center" onClose={onClose} maxWidth={35}>
      <Overlay.Header title="New Invite" icon="community" />

      <Overlay.Content>
        <div css={{ padding: '1rem 2rem', display: 'grid', gridGap: '1rem' }}>
          <header css={STYLES.header}>
            {tenant.logo ? (
              <img src={tenant.logo} css={STYLES.tenantLogo} draggable={false} />
            ) : (
              <Logo logo="behave_health" css={STYLES.logo} />
            )}
          </header>

          <h1 css={STYLES.title}>{subject || `You've been invited to join ${tenant?.name}`}</h1>

          {message && <div css={STYLES.quote}>{message}</div>}

          <Flex gap="1.5rem">
            <div css={STYLES.profile}>
              <Avatar src="" initials={data.sent_by?.name} size={40} css={STYLES.profileAvatar} />
              <div css={STYLES.profileText}>
                <div>
                  <b>Invited by:</b> {data.sent_by?.name || '–'}
                </div>
                <Status label="Invitee" color="blue" />
              </div>
            </div>

            <div css={STYLES.profile}>
              <Avatar src="" initials="P Q" size={40} css={STYLES.profileAvatar} />
              <div css={STYLES.profileText}>
                <div>
                  <b>Join as:</b> {data.sent_to_details.full_name}
                </div>

                {(data.category === 'owner' || data.category === 'staff') && (
                  <Status label={titleCase(data.settings?.position)} color="blue" />
                )}
              </div>
            </div>
          </Flex>
        </div>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          fullWidth
          label="Accept"
          size={300}
          type="primary"
          glyph="tick_circle"
          color="green"
          display="inline-flex"
          onClick={handleAccept}
          isLoading={isAccepting}
        />

        <Button
          label="Decline"
          size={200}
          type="minimal"
          glyph="cross"
          color="red"
          display="inline-flex"
          onClick={handleDecline}
          className="!mt-2"
          isLoading={isDeclining}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const STYLES = {
  header: {
    padding: '2rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  logo: {
    margin: '0 auto',
  },

  tenantLogo: {
    margin: '0 auto',
    maxWidth: 220,
    maxHeight: 160,
    userSelect: 'none',
  },

  main: {
    display: 'grid',
    gridGap: '1.25rem',
    width: '100%',
    maxWidth: 600,
    padding: '1rem',
    paddingBottom: '3rem',
    margin: '0 auto',

    b: { fontWeight: 600 },
  },

  title: {
    fontSize: '2rem',
  },

  subtitle: {
    fontSize: '1.15rem',
    lineHeight: '1.5em',
    color: COLORS.textMuted,
  },

  quote: {
    fontSize: '1.2rem',
    position: 'relative',
    color: COLORS.textMuted,
    fontWeight: 400,
    lineHeight: '1.5em',
    fontStyle: 'italic',
  },

  graphicItem: {
    fontSize: '1.05rem',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginTop: '0.5rem',

    svg: {
      width: 20,
      height: 20,
      marginRight: '0.25rem',
    },
  },

  profile: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    fontSize: '1.1rem',
  },

  profileAvatar: {
    marginRight: '0.5rem',
  },

  profileText: {
    display: 'grid',
    gridGap: '0.2rem',
  },

  monoTypeBlock: {
    display: 'inline-flex',
    fontSize: '1.05rem',
    fontFamily: 'monospace',
    fontWeight: 400,
    borderRadius: 5,
    background: COLORS.hover,
    padding: '0.5rem 1rem',
    color: COLORS.text,
    letterSpacing: 2,

    '@media(min-width: 600px)': {
      fontSize: '1.2rem',
    },
  },
}
