import React from 'react'

import { useDataTable } from '../../../DataTable/useDataTable'

import { HubspotCommunityProfilesDataTable } from '../../../../constructs/Hubspot/HubspotCommunityProfilesDataTable'

export const HubspotCommunityProfilesTable = ({ onSelect, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector_hubspot_community_profiles'],
    endpoint: '/apps/hubspot/community_profiles',
    localStorageKey: 'selector_hubspot_community_profiles_v1',
  })

  return <HubspotCommunityProfilesDataTable {...tableProps} {...rest} onSelect={onSelect} />
}
