import DateFilter from './Filters/DateFilter'
import NumberRangeFilter from './Filters/NumberRangeFilter'
import SelectFilter from './Filters/SelectFilter'
import TextFilter from './Filters/TextFilter'
import HumanizedTextFilter from './Filters/HumanizedTextFilter'
import TrueFalseFilter from './Filters/TrueFalseFilter'
import CollectionStatusFilter from './Filters/CollectionStatusFilter'
import TestResultFilter from './Filters/TestResultFilter'
import DataFormStatusFilter from './Filters/DataFormStatusFilter'
import ContentTypeFilter from './Filters/ContentTypeFilter'
import LevelOfCareFilter from './Filters/LevelOfCareFilter'
import ColorSelectFilter from './Filters/ColorSelectFilter'
import ActiveInactiveFilter from './Filters/ActiveInactiveFilter'

const TableFilter = ({ children }) => children

TableFilter.Date = DateFilter
TableFilter.Date.displayName = 'TableDateFilter'

TableFilter.NumberRange = NumberRangeFilter
TableFilter.NumberRange.displayName = 'TableNumberRangeFilter'

TableFilter.Select = SelectFilter
TableFilter.Select.displayName = 'TableSelectFilter'

TableFilter.Text = TextFilter
TableFilter.Text.displayName = 'TableTextFilter'

TableFilter.HumanizedText = HumanizedTextFilter
TableFilter.HumanizedText.displayName = 'TableHumanizedTextFilter'


TableFilter.TrueFalse = TrueFalseFilter
TableFilter.TrueFalse.displayName = 'TableTrueFalseFilter'


TableFilter.CollectionStatus = CollectionStatusFilter
TableFilter.CollectionStatus.displayName = 'TableCollectionStatusFilter'

TableFilter.TestResult = TestResultFilter
TableFilter.TestResult.displayName = 'TableTestResultFilter'

TableFilter.DataFormStatus = DataFormStatusFilter
TableFilter.DataFormStatus.displayName = 'TableDataFormStatusFilter'

TableFilter.ContentType = ContentTypeFilter
TableFilter.ContentType.displayName = 'TableContentTypeFilter'

TableFilter.LevelOfCare = LevelOfCareFilter
TableFilter.LevelOfCare.displayName = 'TableLevelOfCareFilter'

TableFilter.ColorSelect = ColorSelectFilter
TableFilter.ColorSelect.displayName = 'TableColorSelectFilter'

TableFilter.ActiveInactive = ActiveInactiveFilter
TableFilter.ActiveInactive.displayName = 'TableActiveInactiveFilter'


export default TableFilter
