import React from 'react'

import { usDateTime, countWord } from '../../utils/functions'
import { useSettings } from '../../hooks'

import HelpTagIframe from '../Help/HelpTagIframe'
import Status from '../Status'
import Flex from '../Flex'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const billingColumn = (timezone: string) => ({
  width: 140,
  accessor: 'billed_at',
  Header: 'Billed',
  Cell: ({ row, value }) => {
    if (!row.original.is_billed) return '–'
    return usDateTime(value, timezone)
  },
  Filter: TableFilter.Date,
  filter: 'date',
})

const columns = (to: Function = () => {}, timezone: string, isBehave: boolean) => [
  {
    width: 220,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'collected_at',
    Header: 'Date & Time',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ row, value }) => <TableCell.MainLink to={to(row.original.id)} label={usDateTime(value, timezone)} />,
  },
  ...(isBehave ? [billingColumn(timezone)] : []),
  {
    width: 180,
    id: 'reference',
    accessor: 'reference',
    Header: 'Client',
    Cell: ({ value }: any) => <TableCell.Profile avatar={value?.avatar} name={value?.name} />,
  },
  {
    width: 180,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ row, value }) => {
      if (value === 'refused') return <Status small color="red" label="Refused Collection" />
      if (value === 'collected') return <Status small color="blue" label="Collected" />
      return <TableCell.NotApplicable />
    },
  },
  {
    width: 120,
    accessor: 'result',
    Header: 'Result',
    Cell: ({ row, value }) => {
      if (row.original.status === 'refused') return <TableCell.NotApplicable />
      if (value === 'positive') return <Status small color="red" label="Positive" />
      if (value === 'negative') return <Status small color="green" label="Negative" />
      if (value === 'not_available') return <Status small color="grey" label="N/A" />

      return <TableCell.NotApplicable />
    },
  },
  {
    width: 220,
    accessor: 'positive_due_medication_notes',
    Header: 'Positive due to Prescription',
    Cell: ({ row }) => {
      const isPositive = row.original.positive_due_medication === true

      if (!isPositive) return <TableCell.Empty />

      return (
        <div>
          <Status small color="green" label="Positive due to Prescription" />

          {row.original.positive_due_medication_notes && (
            <div className="mt-1.5 text-[0.9rem]">{row.original.positive_due_medication_notes}</div>
          )}
        </div>
      )
    },
  },
  {
    width: 160,
    accessor: 'test_results',
    Header: 'Positive Results',
    Cell: ({ value }) => {
      if (!value) return <TableCell.Empty />

      const positiveResults = value?.filter((result) => result.checked)

      if (!positiveResults || positiveResults.length === 0) return <TableCell.NotApplicable />
      return countWord('positive results', positiveResults.length)
    },
  },
  {
    width: 180,
    accessor: 'other_positive_results',
    Header: 'Other Positive Results',
    // Cell: ({ value }) => {
    //   if (!value) return <TableCell.Empty />

    //   const positiveResults = value?.filter((result) => result.checked)

    //   if (!positiveResults || positiveResults.length === 0) return <TableCell.NotApplicable />
    //   return countWord('positive results', positiveResults.length)
    // },
  },
  {
    width: 180,
    accessor: 'additional_info',
    Header: 'Note',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const UATestsTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, hiddenColumns, batchActionsConfig, localStorageKey, titleAfter } = props
  const { timezone, user } = useSettings()

  const isBehave = user?.type === 'bh_employee'

  return (
    <Table
      title="UA Tests"
      titleAfter={titleAfter}
      icon="test_results"
      data={data}
      columns={columns(to, timezone, isBehave)}
      hiddenColumns={hiddenColumns}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No UA tests added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      help={<HelpTagIframe id="test_results_ua_tests" />}
    />
  )
}

UATestsTable.defaultProps = {
  localStorageKey: 'ua_tests',
}

export default UATestsTable
