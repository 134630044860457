class AnnounceKit {
  constructor() {
    this.url = ''
    this.name = Math.random().toString(36).substring(10)
    this.selector = `.ak-${this.name}`
    this.updates = 0
    this.widget = null
  }

  init = (url) => {
    this.url = url

    if (!window['announcekit']) {
      window['announcekit'] = window['announcekit'] || {
        queue: [],
        push: (x) => window['announcekit'].queue.push(x),
        on: (n, x) => window['announcekit'].queue.push([n, x]),
      }

      let scripttag = document.createElement('script')
      scripttag['async'] = true
      scripttag['src'] = `https://cdn.announcekit.app/widget.js`
      let scr = document.getElementsByTagName('script')[0]
      scr.parentNode.insertBefore(scripttag, scr)
    }

    window['announcekit'].push({
      widget: this.url,
      name: this.name,
      version: 2,
      selector: this.selector,
      onInit: (_widget) => {
        if (_widget.conf.name !== this.name) return _widget.destroy()
        const ann = window['announcekit']

        this.widget = _widget

        // if (this.props.catchClick) {
        //   const elem = document.querySelector(this.props.catchClick)
        //   if (elem) elem.addEventListener('click', () => _widget.open())
        // }

        ann.on('widget-unread', ({ widget, unread }) => {
          this.updates = unread
        })
      },
    })
  }

  getUpdates = () => this.updates

  open = () => this.widget?.open()
}

export default new AnnounceKit()
