import React from 'react'
import produce from 'immer'
import size from 'lodash/size'

import { getPrefix, useUpdate } from '../../hooks/useNewAPI'

import Avatar from '../Avatar'
import Flex from '../Flex'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

import { countWord } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

const CATEGORIES = {
  detox: 'Detox',
  residential: 'Residential',
  partial_hospitalization: 'Partial Hospitalization',
  intensive_outpatient: 'Intensive Outpatient',
  outpatient: 'Outpatient',
  sober_living: 'Sober Living',
  outdoors_adventure: 'Outdoors Adventure',
  gym_fitness: 'Gym & Fitness',
  employment_training: 'Employment Training',
  primary_group: 'Primary Group',
  case_management: 'Case Management',
  medication_management: 'Medication Management',
  life_coaching: 'Life Coaching',
  recovery_coaching: 'Recovery Coaching',
  career_counselor: 'Career Counselor',
  other: 'Other',
}

const tagsColumn = (apiKey: any) => ({
  width: 200,
  Header: 'Tags',
  accessor: 'tags',
  disableFilters: true,
  Cell: ({ value, row, testKey }: any) => {
    const { mutateAsync, isLoading }: any = useUpdate({
      name: ['program', row.original.id],
      url: `/programs/${row.original.id}`,
      invalidateKeys: ['programs', 'tag-live-reports'],
      onSuccess: (_data: any, variables: any, queryClient: any) => {
        const prefix = getPrefix()

        queryClient.setQueryData([prefix, apiKey].flat(), (oldData: any) => {
          if (!oldData?.data) return

          const index = oldData.data.findIndex((o) => o.id === row.original.id)
          if (index === -1) return

          const newData = produce(oldData, (draft: any) => {
            draft.data[index].tags = variables.tags
          })

          return newData
        })
      },
    })

    return (
      <TableCell.Tags
        value={value}
        save={(tagIDs: any, tags: any) => mutateAsync({ tag_ids: tagIDs, tags })}
        isLoading={isLoading}
        smartCategories="programs"
      />
    )
  },
})

const columns = (to: Function = () => {}, apiKey: any, mainLinkAs) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink as={mainLinkAs} to={to(row.original.id)} label={value} avatar={null} />,
  },
  ...(apiKey ? [tagsColumn(apiKey)] : []),
  // {
  //   width: 160,
  //   accessor: 'subcategory',
  //   Header: 'Category',
  //   Cell: ({ value }: any) => {
  //     if (!(value in CATEGORIES)) return <TableCell.Empty />
  //     return <TableCell value={CATEGORIES[value]} />
  //   },
  // },
  // {
  //   width: 160,
  //   accessor: 'weeks_duration',
  //   Header: 'Schedule Duration',
  //   Cell: ({ value }: any) => {
  //     if (!value) return <TableCell.Empty />
  //     return <TableCell value={countWord('week', value)} />
  //   },
  // },
  {
    width: 400,
    accessor: 'description',
    Header: 'Description',
  },
  {
    width: 300,
    accessor: 'managing_staff',
    Header: 'Managing Staff',
    Cell: ({ value }) => {
      if (size(value) === 0) return <TableCell.Empty />

      return (
        <Flex gap="0.75rem">
          {value.map((record) => (
            <div key={`${record.type}-${record.id}`}>
              <Flex nowrap centerY gap="0.4rem">
                <Avatar src={record.avatar} initials={record.name} size={20} />
                <div>{record.name}</div>
              </Flex>
            </div>
          ))}
        </Flex>
      )
    },
  },
  {
    width: 120,
    accessor: 'phase_count',
    Header: 'Program List Count',
    // Cell: ({ value }) => `${countWord('Program Lists', size(value))}`,
    Cell: ({ value }) => `${countWord('Program Lists', value)}`,
  },
]

const ProgramsTable = (props: any) => {
  const {
    apiKey,
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    timezone,
    title,
    titleAfter,
    to,
    testKey,
    ...rest
  } = props

  return (
    <Table
      testKey={testKey}
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, apiKey, props.mainLinkAs)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

ProgramsTable.defaultProps = {
  title: 'Programs',
  icon: 'checklist',
  emptyDescription: 'No programs added yet',
  localStorageKey: 'programs',
}

export default withSettings(ProgramsTable)
