import React from 'react'
import { v4 as uuid } from 'uuid'

import { COLORS } from '../../theme'

import Button from '../Button'
import BedAvailabilityWidget from '../BedAvailabilityWidget'
import Grid from '../Grid'
import Form from '../Forms/Form'
import Input from '../Forms/Input'
import PhoneInput from '../Forms/PhoneInput'
import URLInput from '../Forms/URLInput'
import Checkbox from '../Forms/Checkbox'
import CheckboxGroup from '../Forms/CheckboxGroup'
import Radio from '../Forms/Radio'
import RadioGroup from '../Forms/RadioGroup'
import ContextShow from '../Forms/ContextShow'

import { H3, Text } from '../Typography'

import withSettings from '../../hocs/withSettings'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'

type Props = {
  subdomain: string
  timezone: string
  tenant: any
}

const ShareBedAvailabilityForm = ({ subdomain, timezone, tenant }: Props) => {
  const initialData = {
    showTotalBeds: true,
    showPercentage: true,
    showBreakdown: true,
    callToAction: 'application',
    websiteLabel: `Go to ${tenant?.name}`,
    phone_no: tenant?.phone_no,
  }

  const [data, setData] = React.useState(initialData)
  const [valid, setValid] = React.useState()
  const identifier = React.useRef(uuid())

  const embed = `<div id="behave-bed-availability-${
    identifier.current
  }"></div><script type="text/javascript">!function(){function e(){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.onload=function(){BedAvailability.init({parentElement:"behave-bed-availability-${
    identifier.current
  }",tenant:"${subdomain}",tenantID:"${tenant.id}",externalID:"${tenant.external_id}",timezone:"${timezone}",showPercentage:${
    data?.showPercentage
  },showTotalBeds:${data?.showTotalBeds},showBreakdown:${data?.showBreakdown},callToAction:"${data?.callToAction}",${
    data?.phone_no ? `phone_no:"${data?.phone_no}",` : ''
  }${data?.website ? `website:"${data?.website}",` : ''}${data?.websiteLabel ? `websiteLabel:"${data?.websiteLabel}"` : ''}})},e.src="${
    process.env.BH_APP_WIDGETS_URL
  }/BedAvailability.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}window.attachEvent?window.attachEvent("onload",e):window.addEventListener("load",e,!1)}();</script>`

  const { copy, didCopy: codeCopied } = useCopyToClipboard({
    text: valid ? embed : 'Complete all the required files to generate an embed code',
    notification: 'Code copied!',
  })

  return (
    <Grid gap="1.25rem">
      <Form initialModel={initialData} onUpdate={setData} onValidationUpdate={setValid}>
        <Grid gap=".5rem">
          <BedAvailabilityWidget tenant={tenant.subdomain} tenantID={tenant.id} externalID={tenant.external_id} {...data} />

          <CheckboxGroup label="Widget Settings" layout="vertical-dense" maxWidth="auto">
            <Checkbox label="Show number of total beds" model="showTotalBeds" />
            <Checkbox label="Show availability percentage" model="showPercentage" />
            <Checkbox label="Show breakdown by accepted gender" model="showBreakdown" />
          </CheckboxGroup>

          <RadioGroup label="Call to Action" model="callToAction" layout="vertical-dense" maxWidth="auto">
            <Radio label="Link to application form" value="application" />
            <Radio label="Call a phone number" value="phone" />
            <Radio label="Link to a website" value="website" />
          </RadioGroup>

          <ContextShow when="callToAction" is="phone">
            <PhoneInput
              label="Phone Number"
              model="phone_no"
              maxWidth="auto"
              validations={{
                presence: {
                  message: 'Please enter a number phone',
                },
              }}
            />
          </ContextShow>

          <ContextShow when="callToAction" is="website">
            <URLInput
              label="Website"
              model="website"
              maxWidth="auto"
              validations={{
                presence: {
                  message: 'Please enter a website url',
                },
              }}
            />

            <Input
              label="Button Text"
              model="websiteLabel"
              maxWidth="auto"
              validations={{
                presence: {
                  message: 'Please enter the label for the button',
                },
              }}
            />
          </ContextShow>
        </Grid>
      </Form>

      <Grid gap="0.5rem">
        <H3>Share Your Widget</H3>
        <Text>Embed this code on any website to show your beds availability</Text>
        <pre
          css={{
            width: '100%',
            wordBreak: 'break-all',
            whiteSpace: 'pre-wrap',
            color: COLORS.textMuted,
            background: '#EEF0F7',
            border: `1px solid ${COLORS.divider}`,
            borderRadius: 5,
            padding: 10,
            margin: 0,
          }}
        >
          {code}
        </pre>
      </Grid>

      <Button
        type="default"
        glyph={codeCopied ? 'check' : 'copy'}
        label={codeCopied ? 'Embed Code Copied to Clipboard!' : 'Copy Embed Code'}
        onClick={copy}
        isDisabled={!valid}
      />
    </Grid>
  )
}

export default withSettings(ShareBedAvailabilityForm)
