import React from 'react'

import { titleCase, mapToArray } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate } from '../../hooks/useNewAPI'
import Overlay from '../../components/Overlay'

import Button from '../../components/Button'
import DailyNoteStatus from '../../components/Statuses/DailyNoteStatus'
import Grid from '../../components/Grid'

import { Worksheet } from '../../components/Worksheet/Worksheet'

export const DailyNotesGroupWorksheetOverlay = (props: any) => {
  const { initialData, onClose, onSuccess, invalidateKeys } = props

  const form = React.useRef()
  const { isBehave } = useSettings()

  const [isValid, setIsValid] = React.useState(false)

  const { mutate: save, isLoading: isSaving }: any = useUpdate({
    name: ['batch-update-daily-notes'],
    url: `/daily_notes/batch`,
    invalidate: 'daily_notes',
    invalidateKeys: invalidateKeys,
    onSuccess: () => {
      if (onSuccess) onSuccess()
      if (onClose) onClose()
    },
  })

  const handleSave = () => {
    const data = form.current.getFormValue()

    save(mapToArray(data))
  }

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        model: 'client',
        type: 'object_selector',
        disableEdit: true,
      },
      {
        title: 'Status',
        model: 'status',
        disableEdit: true,
        width: 200,
        formatDisplayValue: ({ value }: any) => <DailyNoteStatus small status={value} />,
      },
      {
        title: 'Session Date and Time',
        model: 'started_at',
        type: 'date_time',
        config: {
          validations: {
            presence: {
              message: 'Please enter a session date and time',
            },
          },
        },
      },
      {
        title: 'Duration',
        model: 'duration',
        type: 'number',
        formatDisplayValue: ({ value }: any) => `${value || 0} min`,
        config: {
          validations: {
            presence: {
              message: 'Please enter a session duration',
            },
            numericality: {
              greaterThanOrEqualTo: 1,
              message: 'The duration must be higher than 0 minutes',
            },
          },
        },
      },
      {
        title: 'Supervisor',
        model: 'supervisor',
        type: 'object_selector',
        config: {
          endpoint: '/employees',
          queryKey: 'employees',
          queryParams: { clinical_supervisors: true },
          selectTitle: (data: any) => data.name,
          selectDescription: (data: any) => data.position && titleCase(data.position),
          validations: {
            presence: {
              message: 'Please select a supervisor',
            },
          },
        },
      },
      {
        title: 'Group Topic',
        model: 'topic',
      },
      {
        title: 'Group Discussion',
        model: 'description',
        type: 'rich_text',
      },
      {
        title: 'Treatment Goal Addressed',
        model: 'treatment_goal',
      },
      {
        title: 'Presence',
        model: 'is_attending',
        type: 'select',
        config: {
          options: [
            { label: 'Present', value: true, glyph: 'check', color: 'green' },
            { label: 'Absent', value: false, glyph: 'cross', color: 'red' },
          ],
          validations: {
            presence: {
              message: 'Please set presence',
            },
          },
        },
      },
      {
        title: 'Individual Assessment',
        model: 'assessment',
        type: 'rich_text',
        config: {
          getIsApplicable: ({ rowData }) => rowData.is_attending,
          validations: {
            presence: {
              message: 'Please provide an individual assessment',
            },
          },
        },
      },
      {
        title: 'Absentee Note',
        model: 'absentee_note',
        type: 'rich_text',
        config: {
          getIsApplicable: ({ rowData }) => !rowData.is_attending,
          validations: {
            presence: {
              message: 'Please provide an absentee note',
            },
          },
        },
      },
    ] as const
  }, [])

  return (
    <Overlay showBackdrop onClose={onClose} position="center" maxWidth={140}>
      <Overlay.Header title="Batch Edit Daily Notes" icon="daily_notes" />

      <Overlay.Content className="!p-4">
        <Grid gap="1rem" className="!pb-3">
          <Worksheet
            asCard
            title="Daily Notes"
            allow="update"
            getForm={form}
            columns={columns}
            initialData={initialData}
            onValidationUpdate={setIsValid}
            processCanEdit={({ rowData }: any) => isBehave || rowData?.status !== 'signed_off'}
          />
        </Grid>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Save Daily Notes`}
          glyph="check"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={isSaving}
          isDisabled={!isValid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
