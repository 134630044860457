import React from 'react'

import { useUpdate } from '../../../../hooks/useNewAPI'
import Notifications from '../../../../modules/notifications'

import Button from '../../../Button'
import Section from '../../../Section'
import Form from '../../../Forms/Form'
import Overlay from '../../../Overlay'
import MiniRichTextEditor from '../../../Forms/MiniRichTextEditor'

export const EventAttendanceNotesOverlay = (props: any) => {
  const { onClose, onSuccess, invalidateKeys, event } = props

  const form = React.useRef(null)

  const { mutateAsync, isLoading: isSaving }: any = useUpdate({
    name: ['event', event.id, 'event_attendance_notes'],
    url: `/events/${event.id}`,
    invalidate: 'event',
    invalidateKeys: invalidateKeys,
    onSuccess: () => {
      Notifications.send(`Event attendance notes updated`, 'positive')

      if (onSuccess) onSuccess()
      if (onClose) onClose()
    },
  })

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    await mutateAsync(formData)

    onClose()
  }

  return (
    <Overlay showBackdrop onClose={onClose} position="center">
      <Overlay.Header title="Event Attendance Notes" icon="calendar" />

      <Overlay.Content>
        <Form
          isEditable
          getForm={form}
          initialModel={{ attendance_notes: event?.attendance_notes || '' }}
          key={`updated-${event?.updated_at}`}
        >
          <Section>
            <MiniRichTextEditor useQuickText label="Attendance Notes" model="attendance_notes" />
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label={`Save Attendance Notes`} glyph="check" type="primary" color="green" onClick={handleSave} isLoading={isSaving} />
      </Overlay.Footer>
    </Overlay>
  )
}
