import React from 'react'
import { keyframes } from '@emotion/react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import pluralize from 'pluralize'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS, FEATURES } from '../../theme'
import { request } from '../../modules/axios'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../Button'
import Flex from '../Flex'
import Icon from '../Icon'
import Link from '../Link'
import State from '../State'
import Overlay from '../Overlay'
import OverlaySubHeader from '../Overlay/OverlaySubHeader'
import SummonOverlay from '../SummonOverlay'

import { CIRCLE_HELP_PAGES } from './constants'
import { NotionHelpOverlay } from './NotionHelpOverlay'
import { NotionRenderer } from '../Notion/NotionRenderer'

const NOTION_URL = process.env.BH_NOTION_URL

const getFeatureBySlug = (slug: string) => {
  var arr = slug.split('/')

  for (var i = arr.length - 1; i >= 0; i--) {
    const item = arr[i].replaceAll('-', '_')
    const itemPlural = pluralize(item)

    if (item in FEATURES) return FEATURES[item]
    if (itemPlural in FEATURES) return FEATURES[itemPlural]
  }
}

const HelpOverlay = ({ id }: any) => {
  const location = useLocation()
  const history = useHistory()

  const user = useSelector((state: any) => state.me?.user)
  const isBehave = user?.type === 'bh_employee'
  const helpCenter = useSelector((state: any) => state.me?.settings?.help_center)

  const [isLoading, setIsLoading] = React.useState(false)
  const [notionData, setNotionData] = React.useState(null)

  const [navStack, setNavStack]: any = React.useState([id])
  const canGoBack = size(navStack) >= 2
  const canGoHome = id !== 'help_center_home'

  const circlePage = CIRCLE_HELP_PAGES[id]
  const notionPage = helpCenter[id]

  if (!circlePage && !notionPage) return null

  const circleURL = circlePage && `https://help.behavehealth.com/c/help/${circlePage.slug}`

  React.useEffect(() => {
    if (!notionPage || !notionPage.page_id) return

    const getNotionData = async () => {
      setIsLoading(true)

      try {
        const response = await request.get(`${process.env.BH_NOTION_API_BASE}/api/page/${notionPage.page_id}`, {
          headers: null,
        })
        setNotionData(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    getNotionData()
  }, [notionPage])

  const goTo = (tag: string) => {
    setNavStack((current: any) => {
      if (current[current.length - 1] === tag) return current

      history.push(`?help=${tag}`)

      return [...current, tag]
    })
  }

  const goBack = () => {
    setNavStack((current: any) => {
      const backTag = current[current.length - 2]

      if (!backTag) return current

      history.push(`?help=${backTag}`)

      return produce(current, (draft: any) => {
        draft.pop()
      })
    })
  }

  const onClose = () => {
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('help')) {
      queryParams.delete('help')
      history.replace({ search: queryParams.toString() })
    }
  }

  const components = React.useMemo(
    () => ({
      link: ({ className, children, href = '' }: any) => {
        if (href.includes('app.behavehealth.com')) {
          const to = href.split('app.behavehealth.com')[1]
          const feature = getFeatureBySlug(to) || FEATURES['default']

          return (
            <span css={styles.linkWrapper}>
              <Icon icon={feature.icon} size={16} css={styles.linkIcon} />
              <Link className={className} children={children} to={to} />
            </span>
          )
        }

        return <a className={className} children={children} href={href} target="_blank" />
      },
      pageLink: ({ className, children, href = '' }: any) => {
        const helpTags = useSelector((state: any) => state.me?.settings?.help_center_tags)

        const pageId = href.replace('/', '')
        const helpTag = helpTags[pageId]
        const isActive = helpTag === id

        if (!helpTag) return <a href={`${NOTION_URL}/${pageId}`} className={className} children={children} target="_blank" />

        return (
          <Link
            className={clsx(isActive && 'is-active', className)}
            children={children}
            onClick={() => goTo(helpTag)}
            css={styles.notionPageLink}
          />
        )
      },
    }),
    [id],
  )

  if (notionPage) {
    return (
      <Overlay fullheight stopPropagation position="right" onClose={onClose} maxWidth={54} css={styles.notionOverlay}>
        <Overlay.Header
          title={
            notionPage.name && (
              <>
                Help Center | <span css={styles.pageTitle}>{notionPage.name}</span>
              </>
            )
          }
          glyph="info"
        />

        {isBehave && (
          <OverlaySubHeader css={styles.subHeader}>
            <Flex centerY gap={8} justifyContent="space-between">
              <SummonOverlay overlay={<NotionHelpOverlay title={notionPage.name} url={notionPage.url} />}>
                <Button label="Change Tag Page" glyph="edit" type="minimal" size={200} />
              </SummonOverlay>

              <Button
                useGlyphForTarget
                label="Open in Notion"
                color="text"
                glyph="notion"
                href={`https://www.notion.so/${notionPage.page_id}`}
                size={200}
                target="_blank"
                type="minimal"
              />
            </Flex>
          </OverlaySubHeader>
        )}

        <Overlay.Content css={styles.notionOverlayContent}>
          {!notionData || isLoading ? (
            <State isLoading />
          ) : (
            <>
              <Flex centerY gap={8} justifyContent="space-between" css={styles.navBar}>
                <Button label="Back" glyph="chevron_left" type="link" size={300} onClick={goBack} isDisabled={!canGoBack} />
                <Button
                  label="Help Center Home"
                  glyph="home"
                  type="link"
                  size={300}
                  onClick={() => goTo('help_center_home')}
                  isDisabled={!canGoHome}
                />
              </Flex>

              <NotionRenderer fullPage disableHeader showTableOfContents darkMode={false} recordMap={notionData} components={components} />
            </>
          )}
        </Overlay.Content>
      </Overlay>
    )
  }

  return (
    <Overlay fullheight stopPropagation position="right" onClose={onClose} maxWidth={60}>
      <Overlay.Header title={circlePage.name} glyph="info" />

      {isBehave && (
        <OverlaySubHeader css={styles.subHeader}>
          <Flex centerY gap={8}>
            <SummonOverlay overlay={<NotionHelpOverlay />}>
              <Button label="Link Notion Page" glyph="notion" type="default" color="text" size={200} />
            </SummonOverlay>
          </Flex>
        </OverlaySubHeader>
      )}

      <Overlay.Content style={styles.circleOverlayContent}>
        {circleURL && (
          <iframe
            title="iframe"
            src={circleURL}
            frameBorder="0"
            border="0"
            cellSpacing="0"
            css={styles.iframe}
            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
          />
        )}
      </Overlay.Content>
    </Overlay>
  )
}

const overlayAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const styles = {
  linkWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  linkIcon: {
    marginRight: '0.25rem',
  },

  pageTitle: {
    fontWeight: 400,
  },

  subHeader: {
    padding: '0.5rem',
  },

  notionOverlay: {
    '.dialog': {
      animation: `${overlayAnimation} 150ms cubic-bezier(0.78, 0.14, 0.15, 0.86)`,
    },
  },

  notionOverlayContent: {
    background: COLORS.white,

    'main.notion-page': {
      marginTop: '1rem !important',
    },
  },

  notionPageLink: {
    '&.is-active': {
      color: COLORS.text,
      cursor: 'default',

      '&:hover': {
        border: 'none !important',
        textDecoration: 'none !important',
      },
    },
  },

  circleOverlayContent: {
    position: 'relative',
    padding: '1rem',
    overflow: 'hidden',
  },

  iframe: {
    borderStyle: 'none',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },

  navBar: {
    width: '100%',
    maxWidth: 'var(--notion-max-width)',
    paddingLeft: 'calc(min(12px, 8vw))',
    paddingRight: 'calc(min(12px, 8vw))',
    margin: '2rem auto 0 !important',

    '& > .is-disabled': {
      color: COLORS.textMuted,
      svg: {
        fill: COLORS.textMuted,
      },
    },

    '& > *:first-of-type': {
      paddingLeft: 0,
    },

    '& > *:last-of-type': {
      paddingRight: 0,
    },
  },
}

export default withOverlayError(HelpOverlay)
