import React from 'react'

import { withFormContext } from './context'

import Grid from '../Grid'
import DigitsPin from './DigitsPin'
import Button from '../Button'
import Alert from '../Alert'

import Notifications from '../../modules/notifications'
import { create } from '../../modules/api/requests'

import { useFormField } from './hooks/useFormField'

const PinSignature = (props: any) => {
  const { formActions } = useFormField({
    model: `${props.model}_url`,
    form: props.form,
    defaultEmptyValue: null,
    afterChange: props.onUpdate,
  })

  const [isLoading, setIsLoading] = React.useState(false)
  const [pin, setPin] = React.useState(null)
  const [image, setImage] = React.useState(null)
  const [error, setError] = React.useState(null)

  const checkPIN = async () => {
    setIsLoading(true)
    setError(null)

    try {
      const params = {
        secure_pin: pin?.value,
      }

      let response = await create('/me/check_pin', params)
      setImage(response?.signature)

      Notifications.send('PIN OK', 'positive')
      setIsLoading(false)
    } catch (error) {
      setError(error?.data?.message)
      setIsLoading(false)
    }
  }

  const clear = () => {
    setImage(null)
  }

  // UPDATE FIELD
  React.useEffect(() => {
    formActions.setValue(image)
  }, [image])

  return (
    <>
      <Grid className="py-8 px-0">
        {!image && <DigitsPin onUpdate={setPin} value={pin?.value} />}
        {image && (
          <div css={styles.signature}>
            <img src={image} alt="signature" />
          </div>
        )}
      </Grid>

      {error && (
        <div className="!p-4">
          <Alert type="negative">{error}</Alert>
        </div>
      )}

      <Grid css={styles.footer} autoFlow="column">
        {!image && (
          <div className="p-4">
            <Button
              fullWidth
              type={pin?.isValid ? 'primary' : 'minimal'}
              color="blue"
              glyph="lock"
              label="Check PIN"
              onClick={checkPIN}
              isLoading={isLoading}
              isDisabled={pin?.isInvalid || isLoading}
            />
          </div>
        )}

        {image && <Button fullWidth type="minimal" color="red" glyph="cross" label="Clear" onClick={clear} isDisabled={isLoading} />}
      </Grid>
    </>
  )
}

const styles = {
  footer: {
    '.Button': {
      boxShadow: '1px 0 0 @separator_color',
      borderRadius: 0,
    },
  },

  signature: {
    padding: '1rem 1rem 1rem 2rem',

    img: {
      display: 'block',
      width: '100%',
      height: 'auto',
      maxWidth: 400,
      maxHeight: 200,
    },
  },
}

PinSignature.defaultProps = {
  isEditable: true,
  digits: 4,
  digitsOnly: true,
  checkValidity: true,
  validateOn: 'blur-change',
}

export default withFormContext(PinSignature)
