import { useMemo } from 'react'
import size from 'lodash/size'

export const useGridColumns = (columnsMap: any) => {
  return useMemo(() => {
    if (size(columnsMap) === 0) ''

    const columnWidths = []

    for (const id in columnsMap) {
      const column = columnsMap[id]
      columnWidths.push(`minmax(${column.width}px, ${column.maxWidth || '1fr'})`)
    }

    return columnWidths.join(' ')
  }, [columnsMap])
}
