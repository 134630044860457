import React from 'react'

import { useDataTable } from '../../../DataTable/useDataTable'

import { HubspotCompaniesDataTable } from '../../../../constructs/Hubspot/HubspotCompaniesDataTable'

export const HubspotCompaniesTable = ({ onSelect, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector_hubspot_companies'],
    endpoint: '/apps/hubspot/companies',
    localStorageKey: 'selector_hubspot_companies_v1',
  })

  return <HubspotCompaniesDataTable {...tableProps} {...rest} onSelect={onSelect} />
}
