import React from 'react'

import withPermissions from '../../hocs/withPermissions'

import Address from './Cells/Address'
import Amount from './Cells/Amount'
import DaysBetween from './Cells/DaysBetween'
import Email from './Cells/Email'
import Empty from './Cells/Empty'
import Faded from './Cells/Faded'
import GlyphValue from './Cells/GlyphValue'
import IconValue from './Cells/IconValue'
import JSON from './Cells/JSON'
import Link from './Cells/Link'
import MainLink from './Cells/MainLink'
import Markup from './Cells/Markup'
import NotApplicable from './Cells/NotApplicable'
import OpeningTime from './Cells/OpeningTime'
import Phone from './Cells/Phone'
import Profile from './Cells/Profile'
import ProfileQuickViewOverlay from './Cells/ProfileQuickViewOverlay'
import QuickViewOverlay from './Cells/QuickViewOverlay'
import Swatch from './Cells/Swatch'
import Tags from './Cells/Tags'
import TrueFalse from './Cells/TrueFalse'
import Truncated from './Cells/Truncated'
import UsDate from './Cells/UsDate'
import UsDateTime from './Cells/UsDateTime'
import UsTime from './Cells/UsTime'

import { TableCellEditButton } from './TableCellEditButton'
import { TableContext } from './context'

const TableCell: any = (props: any) => {
  const { value, edit } = props
  const { openEditOverlay }: any = React.useContext(TableContext)

  const isEditable = edit && edit.initialModel && edit.input && edit.options

  const handleEditClick = () => {
    if (!isEditable) return

    openEditOverlay({
      initialModel: edit.initialModel,
      input: edit.input,
      options: edit.options,
    })
  }

  const content = value ? value : <Empty />

  if (isEditable) {
    return (
      <>
        <div css={styles.editCell}>{content}</div>
        <TableCellEditButton onClick={handleEditClick} />
      </>
    )
  }

  return content
}

const styles = {
  editCell: {
    paddingRight: '1rem',
  },
}

TableCell.Amount = Amount
TableCell.Amount.displayName = 'TableCellAmount'

TableCell.Address = Address
TableCell.Address.displayName = 'TableCellAddress'

TableCell.Email = Email
TableCell.Email.displayName = 'TableCellEmail'

TableCell.Empty = Empty
TableCell.Empty.displayName = 'TableCellEmpty'

TableCell.Faded = Faded
TableCell.Faded.displayName = 'TableCellFaded'

TableCell.GlyphValue = GlyphValue
TableCell.GlyphValue.displayName = 'TableCellGlyphValue'

TableCell.IconValue = IconValue
TableCell.IconValue.displayName = 'TableCellIconValue'

TableCell.Link = Link
TableCell.Link.displayName = 'TableCellLink'

TableCell.LinkWithPermissions = withPermissions(Link)
TableCell.LinkWithPermissions.displayName = 'TableCellLinkWithPermissions'

TableCell.Markup = Markup
TableCell.Markup.displayName = 'TableCellMarkup'

TableCell.MainLink = MainLink
TableCell.MainLink.displayName = 'TableCellMainLink'

TableCell.NotApplicable = NotApplicable
TableCell.NotApplicable.displayName = 'TableCellNotApplicable'

TableCell.Phone = Phone
TableCell.Phone.displayName = 'TableCellPhone'

TableCell.OpeningTime = OpeningTime
TableCell.OpeningTime.displayName = 'TableCellOpeningTime'

TableCell.Profile = Profile
TableCell.Profile.displayName = 'TableCellProfile'

TableCell.ProfileQuickViewOverlay = ProfileQuickViewOverlay
TableCell.ProfileQuickViewOverlay.displayName = 'TableCellProfileQuickViewOverlay'

TableCell.QuickViewOverlay = QuickViewOverlay
TableCell.QuickViewOverlay.displayName = 'TableCellQuickViewOverlay'

TableCell.TrueFalse = TrueFalse
TableCell.TrueFalse.displayName = 'TableCellTrueFalse'

TableCell.Truncated = Truncated
TableCell.Truncated.displayName = 'TableCellTruncated'

TableCell.UsDate = UsDate
TableCell.UsDate.displayName = 'TableCellUsDate'

TableCell.UsDateTime = UsDateTime
TableCell.UsDateTime.displayName = 'TableCellUsDateTime'

TableCell.UsTime = UsTime
TableCell.UsTime.displayName = 'TableCellUsTime'

TableCell.DaysBetween = DaysBetween
TableCell.DaysBetween.displayName = 'TableCellDaysBetween'

TableCell.Swatch = Swatch
TableCell.Swatch.displayName = 'TableCellSwatch'

TableCell.Tags = Tags
TableCell.Tags.displayName = 'TableCellTags'

TableCell.JSON = JSON
TableCell.JSON.displayName = 'TableCellJSON'

export default TableCell
