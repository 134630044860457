import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Textarea from '../../components/Forms/Textarea'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Notifications from '../../modules/notifications'

import { useSimpleOverlay } from '../../hooks/useSimpleOverlay'
import { useCreate } from '../../hooks/useNewAPI'

const RootClaimsNotesOverlay = (props: any) => {
  const { form, isInvalid, onValidationUpdate } = useSimpleOverlay({})

  const [isSaving, setIsSaving] = React.useState(false)
  const [error, setError] = React.useState(null)

  const { selectedClaims } = props

  const { timezone } = useSettings()

  const { mutateAsync: createClaimNotes }: any = useCreate({
    name: ['insurance_claim_notes'],
    url: '/insurance_claim_notes/batch',
    invalidate: ['insurance_claims'],
  })

  if (size(selectedClaims) === 0) {
    throw new Error('Select one or more roster items to update the biller notes.')
  }

  const save = async () => {
    setIsSaving(true)
    const formData = form.current.getFormValue()

    try {
      const notes = formData.notes
      const finalBody = selectedClaims.map((o: any) => ({
        insurance_claim_id: o.id,
        notes: notes,
      }))

      await createClaimNotes(finalBody)

      Notifications.send('Successfully created Claim Notes', 'positive')

      props.onClose()
    } catch (error) {
      console.error(error)
      setError(error?.error?.data?.errors?.[0])
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={props.onClose} position="center">
      <Overlay.Header icon="accounting" title="Add Claim Notes" />

      <Overlay.Content>
        <Form isEditable getForm={form} timezone={timezone} onValidationUpdate={onValidationUpdate}>
          <Section>
            <FormSection maxWidth="100%">
              <Alert contrast type="default" glyph="info">
                Add Claim Notes for the selected {countWord('claims', size(selectedClaims))}.
              </Alert>

              <Textarea
                label="Notes"
                model="notes"
                validations={{
                  presence: {
                    message: 'Please enter your Notes',
                  },
                }}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {error && (
          <Alert contrast type="negative" glyph="warning">
            {error}
          </Alert>
        )}

        <Button
          label="Save"
          glyph="check"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={save}
          isDisabled={isInvalid}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClaimsNotesOverlay = withOverlayError(RootClaimsNotesOverlay)
