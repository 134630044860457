import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import Alert from '@behavehealth/components/Alert'

import { EOBDataTable } from '@behavehealth/constructs/RCM/EOBDataTable'
import DnDEobsUploader from '@behavehealth/constructs/RCM/DnDEobsUploader'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import Permission from '@behavehealth/components/Permission'

const EOBs = () => {
  const match = useRouteMatch()

  const to = React.useCallback((o: any) => `${match.url}/${o.id}`, [match.url])

  const tableProps = useDataTable({
    name: ['insurance_eobs'],
    endpoint: '/insurance_eobs',
    localStorageKey: 'insurance_eobs_v1',
  })

  return (
    <EOBDataTable
      {...tableProps}
      to={to}
      footer={
        <div className="grid gap-3 p-2 bg-white border-t border-0 border-solid border-divider">
          <Alert type="warning" glyph="warning">
            <b>Please Note:</b> Individual file uploaded will be assigned its own EOB ID.
          </Alert>

          <Permission permission="insurance_posting.create">
            <DnDEobsUploader isEditable />
          </Permission>
        </div>
      }
    />
  )
}

export default EOBs
