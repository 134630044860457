import React from 'react'
import { useLocation } from 'react-router-dom'

import { usDateTime } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import { Text } from '../Typography'
import DataFormStatus from '../Statuses/DataFormStatus'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'

const ASSESSMENTS = {
  prescreen: 'Pre-Screen',
  biopsychosocial: 'Biopsychosocial',
  psychiatric_assessment: 'Psychiatric Assessment',
  suicide_assessment: 'Suicide Assessment',
  asam_criteria: 'ASAM criteria',
}

type Props = {
  data: any
  location: any
  timezone: any
  to: any
}

const ClinicalAssessment = ({ data, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={ASSESSMENTS[data?.subcategory]} />
      <CardMeta>
        <DataFormStatus status={data?.status} />
        <Text avatar={data?.author?.avatar} label={data?.author?.name} />
        <Text glyph="date" label="Last Edited: " description={usDateTime(data?.updated_at, timezone)} />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(ClinicalAssessment)
