import React from 'react'

import { Heading } from '../../../../Typography'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import FormSection from '../../../../Forms/FormSection'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'

const MentalStatusExam = () => (
  <FormSection layout="vertical">
    <Heading is="h3">General Appearance</Heading>

    <CheckboxGroup label="Hygiene" layout="vertical-dense">
      <Checkbox label="Clean" model="data.mental_status_exam.general_appearance.hygiene.clean" />
      <Checkbox label="Unkept" model="data.mental_status_exam.general_appearance.hygiene.unkept" />
      <Checkbox label="Dirty" model="data.mental_status_exam.general_appearance.hygiene.dirty" />
    </CheckboxGroup>

    <RadioGroup label="Grooming" model="data.mental_status_exam.general_appearance.grooming" layout="vertical-dense">
      <Radio label="Meticulously groomed" value="meticulous" />
      <Radio label="Well-groomed" value="well" />
      <Radio label="Average / Within normal limits" value="average" />
      <Radio label="Poorly groomed" value="poor" />
    </RadioGroup>

    <CheckboxGroup label="Odor" layout="vertical-dense">
      <Checkbox label="None" model="data.mental_status_exam.general_appearance.odor.none" />
      <Checkbox label="Malodorous" model="data.mental_status_exam.general_appearance.odor.malodorous" />
    </CheckboxGroup>

    <ContextShow when="data.mental_status_exam.general_appearance.odor.malodorous" is={true}>
      <SmartTextarea useDictation label="Describe Odor" model="data.mental_status_exam.general_appearance.odor.malodorous_extra" />
    </ContextShow>

    <CheckboxGroup label="Clothing" layout="vertical-dense">
      <Checkbox label="Clean" model="data.mental_status_exam.general_appearance.clothing.clean" />
      <Checkbox label="Neat" model="data.mental_status_exam.general_appearance.clothing.neat" />
      <Checkbox label="Meticulous" model="data.mental_status_exam.general_appearance.clothing.meticulous" />
      <Checkbox label="Dirty" model="data.mental_status_exam.general_appearance.clothing.dirty" />
      <Checkbox label="Disheveled" model="data.mental_status_exam.general_appearance.clothing.disheveled" />
      <Checkbox label="Worn out" model="data.mental_status_exam.general_appearance.clothing.worn_out" />
      <Checkbox label="Flamboyant" model="data.mental_status_exam.general_appearance.clothing.flamboyant" />
      <Checkbox label="Eccentric" model="data.mental_status_exam.general_appearance.clothing.eccentric" />
      <Checkbox label="Unconventional" model="data.mental_status_exam.general_appearance.clothing.unconventional" />
      <Checkbox label="Fashionable" model="data.mental_status_exam.general_appearance.clothing.fashionable" />
      <Checkbox label="Professional" model="data.mental_status_exam.general_appearance.clothing.professional" />
    </CheckboxGroup>

    <RadioGroup
      label="Appearance in relation to chronological age"
      model="data.mental_status_exam.general_appearance.age"
      layout="vertical-dense"
    >
      <Radio label="Appears OLDER than age" value="older" />
      <Radio label="Appears to be of age" value="of" />
      <Radio label="Appears YOUNGER than age" value="younger" />
    </RadioGroup>

    <RadioGroup
      label="Clothing is appropriate to season, setting, and occasion?"
      model="data.mental_status_exam.general_appearance.clothing_appropriate_to_season"
      layout="horizontal-dense"
    >
      <Radio label="Yes" value={true} />
      <Radio label="No" value={false} />
    </RadioGroup>

    <RadioGroup
      label="Clothing is appropriate to age?"
      model="data.mental_status_exam.general_appearance.clothing_appropriate_to_age"
      layout="horizontal-dense"
    >
      <Radio label="Yes" value={true} />
      <Radio label="No" value={false} />
    </RadioGroup>

    <SmartTextarea useDictation label="General Appearance Narrative" model="data.mental_status_exam.general_appearance.narrative" />

    <Heading is="h3">Psychomotor Behavior</Heading>

    <CheckboxGroup label="Coordination of Movements / Gait" layout="vertical-dense">
      <Checkbox label="Within Normal Limits" model="data.mental_status_exam.psychomotor.gait.normal" />
      <Checkbox label="Clumsy and Falling easily" model="data.mental_status_exam.psychomotor.gait.clumsy" />
      <Checkbox label="Needed assistance" model="data.mental_status_exam.psychomotor.gait.need_assistance" />
      <Checkbox label="Bed ridden" model="data.mental_status_exam.psychomotor.gait.bed_ridden" />
      <Checkbox label="Uses wheelchair" model="data.mental_status_exam.psychomotor.gait.wheelchair" />
      <Checkbox label="Uses walker" model="data.mental_status_exam.psychomotor.gait.walker" />
    </CheckboxGroup>

    <RadioGroup label="Rate of movements" model="data.mental_status_exam.psychomotor.rate_of_movements" layout="vertical-dense">
      <Radio label="Within Normal Limits" value="normal" />
      <Radio label="Hyperactive" value="hyperactive" />
      <Radio label="Slow" value="slow" />
      <Radio label="Agitated" value="agitated" />
    </RadioGroup>

    <CheckboxGroup label="Posture" layout="vertical-dense">
      <Checkbox label="Within Normal Limits" model="data.mental_status_exam.psychomotor.posture.normal" />
      <Checkbox label="Stooped" model="data.mental_status_exam.psychomotor.posture.stooped" />
      <Checkbox label="Relaxed" model="data.mental_status_exam.psychomotor.posture.relaxed" />
      <Checkbox label="Stiff" model="data.mental_status_exam.psychomotor.posture.stiff" />
      <Checkbox label="Shaky" model="data.mental_status_exam.psychomotor.posture.shaky" />
      <Checkbox label="Slouched" model="data.mental_status_exam.psychomotor.posture.slouched" />
      <Checkbox label="Bizarre mannerisms" model="data.mental_status_exam.psychomotor.posture.bizarre" />
      <Checkbox label="Posturing" model="data.mental_status_exam.psychomotor.posture.posturing" />
      <Checkbox label="Crouching" model="data.mental_status_exam.psychomotor.posture.crouching" />
      <Checkbox label="Erect" model="data.mental_status_exam.psychomotor.posture.erect" />
      <Checkbox label="Rigid" model="data.mental_status_exam.psychomotor.posture.rigid" />
      <Checkbox label="Restless" model="data.mental_status_exam.psychomotor.posture.restless" />
    </CheckboxGroup>

    <CheckboxGroup label="Abnormal movements" layout="vertical-dense">
      <Checkbox label="None" model="data.mental_status_exam.psychomotor.abnormal_movements.none" />
      <Checkbox label="Tremors" model="data.mental_status_exam.psychomotor.abnormal_movements.tremors" />
      <Checkbox label="Catatonic" model="data.mental_status_exam.psychomotor.abnormal_movements.catatonic" />
      <Checkbox label="Foot tapping" model="data.mental_status_exam.psychomotor.abnormal_movements.foot_tapping" />
      <Checkbox label="Hand wringing" model="data.mental_status_exam.psychomotor.abnormal_movements.hand_wringing" />
      <Checkbox label="Ritualistic behavior" model="data.mental_status_exam.psychomotor.abnormal_movements.ritualistic_behavior" />
      <Checkbox label="Nail biting" model="data.mental_status_exam.psychomotor.abnormal_movements.nail_biting" />
      <Checkbox label="Chewing movements" model="data.mental_status_exam.psychomotor.abnormal_movements.chewing_movements" />
      <Checkbox label="Grimaces, Tics, or Twitches" model="data.mental_status_exam.psychomotor.abnormal_movements.grimaces" />
      <Checkbox
        label="Echopraxia (meaningless repetition or imitation of the movements of others)"
        model="data.mental_status_exam.psychomotor.abnormal_movements.echopraxia"
      />
      <Checkbox
        label="Tardive dyskinesia (repetitive, jerking movements that occur in the face, neck, and tongue)"
        model="data.mental_status_exam.psychomotor.abnormal_movements.dyskinesia"
      />
    </CheckboxGroup>

    <SmartTextarea useDictation label="Psychomotor Behavior Narrative" model="data.mental_status_exam.psychomotor.narrative" />

    <Heading is="h3">Mood and Affect</Heading>

    <CheckboxGroup label="Mood / feelings reported" layout="vertical-dense">
      <Checkbox label="I'm Angry" model="data.mental_status_exam.mood.mood.angry" />
      <Checkbox label="I'm Anxious" model="data.mental_status_exam.mood.mood.anxious" />
      <Checkbox label="I'm Ashamed" model="data.mental_status_exam.mood.mood.ashamed" />
      <Checkbox label="I'm Happy" model="data.mental_status_exam.mood.mood.happy" />
      <Checkbox label="I'm Depressed" model="data.mental_status_exam.mood.mood.depressed" />
      <Checkbox label="I'm Fearful" model="data.mental_status_exam.mood.mood.fearful" />
      <Checkbox label="I'm Irritable" model="data.mental_status_exam.mood.mood.irritable" />
      <Checkbox label="I'm Hopeless" model="data.mental_status_exam.mood.mood.hopeless" />
      <Checkbox label="I'm Sad" model="data.mental_status_exam.mood.mood.sad" />
      <Checkbox label="I'm Pessimistic" model="data.mental_status_exam.mood.mood.pessimistic" />
      <Checkbox label="I'm Optimistic" model="data.mental_status_exam.mood.mood.optimistic" />
      <Checkbox label="I'm Regretful" model="data.mental_status_exam.mood.mood.regretful" />
      <Checkbox label="Other" model="data.mental_status_exam.mood.mood.other" />
    </CheckboxGroup>

    <ContextShow when="data.mental_status_exam.mood.mood.other" is={true}>
      <SmartTextarea useDictation label="Please explain:" model="data.mental_status_exam.mood.mood.other_extra" />
    </ContextShow>

    <RadioGroup label="Anxiety Level" model="data.mental_status_exam.mood.anxiety_level" layout="vertical-dense">
      <Radio label="Within Normal Limits" value="normal" />
      <Radio label="Mild" value="mild" />
      <Radio label="Moderate" value="moderate" />
      <Radio label="Severe" value="severe" />
      <Radio label="Panic" value="panic" />
    </RadioGroup>

    <RadioGroup label="Depression Level" model="data.mental_status_exam.mood.depression_level" layout="vertical-dense">
      <Radio label="Within Normal Limits" value="normal" />
      <Radio label="Mild" value="mild" />
      <Radio label="Moderate" value="moderate" />
      <Radio label="Severe" value="severe" />
      <Radio label="Panic" value="panic" />
    </RadioGroup>

    <CheckboxGroup label="Facial expression" layout="vertical-dense">
      <Checkbox label="Excited" model="data.mental_status_exam.mood.expression.excited" />
      <Checkbox label="Elated" model="data.mental_status_exam.mood.expression.elated" />
      <Checkbox label="Smiling" model="data.mental_status_exam.mood.expression.smiling" />
      <Checkbox label="Animated" model="data.mental_status_exam.mood.expression.animated" />
      <Checkbox label="Sad" model="data.mental_status_exam.mood.expression.sad" />
      <Checkbox label="Perplexed" model="data.mental_status_exam.mood.expression.perplexed" />
      <Checkbox label="Worried" model="data.mental_status_exam.mood.expression.worried" />
      <Checkbox label="Fearful" model="data.mental_status_exam.mood.expression.fearful" />
      <Checkbox label="Scowling" model="data.mental_status_exam.mood.expression.scowling" />
      <Checkbox label="Bored" model="data.mental_status_exam.mood.expression.nored" />
      <Checkbox label="Blank" model="data.mental_status_exam.mood.expression.blank" />
      <Checkbox label="Dazed" model="data.mental_status_exam.mood.expression.dazed" />
      <Checkbox label="Tearful" model="data.mental_status_exam.mood.expression.tearful" />
      <Checkbox label="Tense" model="data.mental_status_exam.mood.expression.tense" />
      <Checkbox label="Preoccupied" model="data.mental_status_exam.mood.expression.preoccupied" />
    </CheckboxGroup>

    <CheckboxGroup label="Affect" layout="vertical-dense">
      <Checkbox label="Full-range of affect" model="data.mental_status_exam.mood.affect.full_range" />
      <Checkbox label="Euphoric" model="data.mental_status_exam.mood.affect.euphoric" />
      <Checkbox label="Irritable" model="data.mental_status_exam.mood.affect.irritable" />
      <Checkbox label="Expansive" model="data.mental_status_exam.mood.affect.expansive" />
      <Checkbox label="Flat" model="data.mental_status_exam.mood.affect.flat" />
      <Checkbox label="Blunted" model="data.mental_status_exam.mood.affect.blunted" />
      <Checkbox label="Superficial" model="data.mental_status_exam.mood.affect.superficial" />
      <Checkbox label="Constricted" model="data.mental_status_exam.mood.affect.constricted" />
      <Checkbox label="Emotionally withdrawn" model="data.mental_status_exam.mood.affect.withdrawn" />
      <Checkbox label="Dysphoric (sad, angry, or anxious)" model="data.mental_status_exam.mood.affect.dysphoric" />
      <Checkbox label="Anhedonic" model="data.mental_status_exam.mood.affect.anhedonic" />
      <Checkbox label="Congruent with mood" model="data.mental_status_exam.mood.affect.congruent" />
      <Checkbox label="Not congruent with mood" model="data.mental_status_exam.mood.affect.not_congruent" />
      <Checkbox label="Stable" model="data.mental_status_exam.mood.affect.stable" />
      <Checkbox label="Labile" model="data.mental_status_exam.mood.affect.labile" />
      <Checkbox label="Appropriate" model="data.mental_status_exam.mood.affect.appropriate" />
      <Checkbox label="Not appropriate" model="data.mental_status_exam.mood.affect.not_appropriate" />
    </CheckboxGroup>

    <SmartTextarea useDictation label="Mood and Affect Narrative" model="data.mental_status_exam.mood.narrative" />

    <Heading is="h3">Thought</Heading>

    <CheckboxGroup label="Thought Form, Flow, and Process" layout="vertical-dense">
      <Checkbox label="Logical" model="data.mental_status_exam.thought.thought_form.logical" />
      <Checkbox label="Linear" model="data.mental_status_exam.thought.thought_form.linear" />
      <Checkbox label="Goal-directed" model="data.mental_status_exam.thought.thought_form.goal_directed" />
      <Checkbox label="Anxiously-tangential" model="data.mental_status_exam.thought.thought_form.anxiously_tangential" />
      <Checkbox label="Blocking" model="data.mental_status_exam.thought.thought_form.blocking" />
      <Checkbox label="Circumstantial" model="data.mental_status_exam.thought.thought_form.circumstantial" />
      <Checkbox label="Clang Associations" model="data.mental_status_exam.thought.thought_form.clang_associations" />
      <Checkbox label="Flight of Ideas" model="data.mental_status_exam.thought.thought_form.flight_of_ideas" />
      <Checkbox label="Fragmented" model="data.mental_status_exam.thought.thought_form.fragmented" />
      <Checkbox label="Hopeful" model="data.mental_status_exam.thought.thought_form.hopeful" />
      <Checkbox label="Illogical" model="data.mental_status_exam.thought.thought_form.illogical" />
      <Checkbox label="Impoverished" model="data.mental_status_exam.thought.thought_form.impoverished" />
      <Checkbox label="Incoherent" model="data.mental_status_exam.thought.thought_form.incoherent" />
      <Checkbox label="Indecisive" model="data.mental_status_exam.thought.thought_form.indecisive" />
      <Checkbox label="Loose associations" model="data.mental_status_exam.thought.thought_form.loose_associations" />
      <Checkbox label="Neologism" model="data.mental_status_exam.thought.thought_form.neologism" />
      <Checkbox label="Overvalued ideas" model="data.mental_status_exam.thought.thought_form.overvalued_ideas" />

      <Checkbox label="Past orientated" model="data.mental_status_exam.thought.thought_form.past_orientated" />
      <Checkbox label="Perservations" model="data.mental_status_exam.thought.thought_form.perservations" />
      <Checkbox label="Poverty of Thought" model="data.mental_status_exam.thought.thought_form.poverty_of_thought" />
      <Checkbox label="Pressured" model="data.mental_status_exam.thought.thought_form.pressured" />
      <Checkbox label="Racing Thoughts" model="data.mental_status_exam.thought.thought_form.racing_thoughts" />
      <Checkbox label="Rapid Reaction" model="data.mental_status_exam.thought.thought_form.rapid_reaction" />
      <Checkbox label="Remorseful" model="data.mental_status_exam.thought.thought_form.remorseful" />
      <Checkbox label="Required Frequent Redirection" model="data.mental_status_exam.thought.thought_form.required_frequent_redirection" />
      <Checkbox label="Scattered" model="data.mental_status_exam.thought.thought_form.scattered" />
      <Checkbox label="Slow Reaction" model="data.mental_status_exam.thought.thought_form.slow_reaction" />
      <Checkbox label="Spontaneous" model="data.mental_status_exam.thought.thought_form.spontaneous" />
      <Checkbox label="Tangential" model="data.mental_status_exam.thought.thought_form.tangential" />
      <Checkbox label="Thought Blocking" model="data.mental_status_exam.thought.thought_form.thought_blocking" />
      <Checkbox label="Word Salad" model="data.mental_status_exam.thought.thought_form.word_salad" />
    </CheckboxGroup>

    <CheckboxGroup label="Content" layout="vertical-dense">
      <Checkbox label="Compulsions" model="data.mental_status_exam.thought.content.compulsions" />
      <Checkbox label="Delusional guilt" model="data.mental_status_exam.thought.content.delusional_guilt" />
      <Checkbox label="Delusions" model="data.mental_status_exam.thought.content.delusions" />
      <Checkbox label="Derealization" model="data.mental_status_exam.thought.content.derealization" />
      <Checkbox label="Depersonalization" model="data.mental_status_exam.thought.content.depersonalization" />
      <Checkbox label="Grandeur" model="data.mental_status_exam.thought.content.grandeur" />
      <Checkbox label="Grandiose delusions" model="data.mental_status_exam.thought.content.grandiose_delusions" />
      <Checkbox label="Hallucinations" model="data.mental_status_exam.thought.content.hallucinations" />
      <Checkbox label="Ideas of inference" model="data.mental_status_exam.thought.content.ideas_of_inference" />
      <Checkbox label="Ideas of reference" model="data.mental_status_exam.thought.content.ideas_of_reference" />
      <Checkbox label="Illusions" model="data.mental_status_exam.thought.content.illusions" />
      <Checkbox label="Impulsivity" model="data.mental_status_exam.thought.content.impulsivity" />
      <Checkbox label="Indifference" model="data.mental_status_exam.thought.content.indifference" />
      <Checkbox label="Nihilistic delusions" model="data.mental_status_exam.thought.content.nihilistic_delusions" />
      <Checkbox label="Obsessions" model="data.mental_status_exam.thought.content.obsessions" />
      <Checkbox label="Paranoia" model="data.mental_status_exam.thought.content.paranoia" />
      <Checkbox label="Persecution" model="data.mental_status_exam.thought.content.persecution" />
      <Checkbox label="Phobias" model="data.mental_status_exam.thought.content.phobias" />
      <Checkbox label="Preoccupied" model="data.mental_status_exam.thought.content.preoccupied" />
      <Checkbox label="Self destructive" model="data.mental_status_exam.thought.content.self_destructive" />
      <Checkbox label="Somatic delusions" model="data.mental_status_exam.thought.content.somatic_delusions" />
      <Checkbox label="Suspiciousness" model="data.mental_status_exam.thought.content.suspiciousness" />
      <Checkbox label="Thought broadcast" model="data.mental_status_exam.thought.content.thought_broadcast" />
      <Checkbox label="Thought derailment" model="data.mental_status_exam.thought.content.thought_derailment" />
      <Checkbox label="Thought insertion" model="data.mental_status_exam.thought.content.thought_insertion" />
      <Checkbox label="Thought withdrawal" model="data.mental_status_exam.thought.content.thought_withdrawal" />
      <Checkbox label="Unreality" model="data.mental_status_exam.thought.content.unreality" />
      <Checkbox label="Within normal limits" model="data.mental_status_exam.thought.content.within_normal_limits" />
      <Checkbox label="Other" model="data.mental_status_exam.thought.content.other" />
    </CheckboxGroup>

    <ContextShow when="data.mental_status_exam.thought.content.other" is={true}>
      <SmartTextarea useDictation label="Please explain:" model="data.mental_status_exam.thought.content.other_extra" />
    </ContextShow>

    <CheckboxGroup label="Clarity" layout="vertical-dense">
      <Checkbox label="Coherent" model="data.mental_status_exam.thought.clarity.coherent" />
      <Checkbox label="Incoherent" model="data.mental_status_exam.thought.clarity.incoherent" />
      <Checkbox label="Cloudy" model="data.mental_status_exam.thought.clarity.cloudy" />
      <Checkbox label="Confused" model="data.mental_status_exam.thought.clarity.confused" />
      <Checkbox label="Vague" model="data.mental_status_exam.thought.clarity.vague" />
      <Checkbox label="Other" model="data.mental_status_exam.thought.clarity.other" />
    </CheckboxGroup>

    <ContextShow when="data.mental_status_exam.thought.clarity.other" is={true}>
      <SmartTextarea useDictation label="Please explain:" model="data.mental_status_exam.thought.clarity.other_extra" />
    </ContextShow>

    <CheckboxGroup label="Other Behaviors" layout="vertical-dense">
      <Checkbox label="None" model="data.mental_status_exam.thought.other_behaviors.none" />
      <Checkbox label="Aggressive" model="data.mental_status_exam.thought.other_behaviors.aggressive" />
      <Checkbox label="Attentive" model="data.mental_status_exam.thought.other_behaviors.attentive" />
      <Checkbox label="Belligerent" model="data.mental_status_exam.thought.other_behaviors.belligerent" />
      <Checkbox label="Blaming" model="data.mental_status_exam.thought.other_behaviors.blaming" />
      <Checkbox label="Calm" model="data.mental_status_exam.thought.other_behaviors.calm" />
      <Checkbox label="Complaining" model="data.mental_status_exam.thought.other_behaviors.complaining" />
      <Checkbox label="Defensive" model="data.mental_status_exam.thought.other_behaviors.defensive" />
      <Checkbox label="Disrespectful" model="data.mental_status_exam.thought.other_behaviors.disrespectful" />
      <Checkbox label="Dramatic" model="data.mental_status_exam.thought.other_behaviors.dramatic" />
      <Checkbox label="Impatient" model="data.mental_status_exam.thought.other_behaviors.impatient" />
      <Checkbox label="Inferior" model="data.mental_status_exam.thought.other_behaviors.inferior" />
      <Checkbox label="Judgemental" model="data.mental_status_exam.thought.other_behaviors.judgemental" />
      <Checkbox label="Rebellious" model="data.mental_status_exam.thought.other_behaviors.rebellious" />
      <Checkbox label="Superior" model="data.mental_status_exam.thought.other_behaviors.superior" />
    </CheckboxGroup>

    <CheckboxGroup label="Suicidality" model="data.mental_status_exam.thought.suicidality" layout="vertical-dense">
      <Checkbox
        label="No current ideation, plan, or intent"
        model="data.mental_status_exam.thought.suicidality.no_current_ideation_plan_or_intent"
      />
      <Checkbox label="Past ideation only" model="data.mental_status_exam.thought.suicidality.past_ideation_only" />
      <Checkbox label="Past ideation and plan only" model="data.mental_status_exam.thought.suicidality.past_ideation_and_plan_only" />
      <Checkbox label="Past ideation, plan, and intent" model="data.mental_status_exam.thought.suicidality.past_ideation_plan_and_intent" />
      <Checkbox label="Previous attempt" model="data.mental_status_exam.thought.suicidality.previous_attempt" />

      <Checkbox
        label="Current ideation, no current plan or intent"
        model="data.mental_status_exam.thought.suicidality.current_ideation_no_current_plan_or_intent"
      />
      <Checkbox
        label="Current ideation and plan, no current intent"
        model="data.mental_status_exam.thought.suicidality.current_ideation_and_plan_no_current_intent"
      />
      <Checkbox
        label="Current ideation, plan, and intent"
        model="data.mental_status_exam.thought.suicidality.current_ideation_plan_and_intent"
      />
    </CheckboxGroup>

    <SmartTextarea
      useDictation
      label="Please explain current plan, means, and history of suicide"
      model="data.mental_status_exam.thought.suicidality_narrative"
    />

    <CheckboxGroup label="Self-injurious behavior" model="data.mental_status_exam.thought.self_injurious_behavior" layout="vertical-dense">
      <Checkbox
        label="No current ideation, plan, or intent"
        model="data.mental_status_exam.thought.self_injurious_behavior.no_current_ideation_plan_or_intent"
      />
      <Checkbox label="Past ideation only" model="data.mental_status_exam.thought.self_injurious_behavior.past_ideation_only" />
      <Checkbox
        label="Past ideation and plan only"
        model="data.mental_status_exam.thought.self_injurious_behavior.past_ideation_and_plan_only"
      />
      <Checkbox
        label="Past ideation, plan, and intent"
        model="data.mental_status_exam.thought.self_injurious_behavior.past_ideation_plan_and_intent"
      />
      <Checkbox label="Previous attempt" model="data.mental_status_exam.thought.self_injurious_behavior.previous_attempt" />

      <Checkbox
        label="Current ideation, no current plan or intent"
        model="data.mental_status_exam.thought.self_injurious_behavior.current_ideation_no_current_plan_or_intent"
      />
      <Checkbox
        label="Current ideation and plan, no current intent"
        model="data.mental_status_exam.thought.self_injurious_behavior.current_ideation_and_plan_no_current_intent"
      />
      <Checkbox
        label="Current ideation, plan, and intent"
        model="data.mental_status_exam.thought.self_injurious_behavior.current_ideation_plan_and_intent"
      />
    </CheckboxGroup>

    <SmartTextarea
      useDictation
      label="Please explain current plan, means, and history of self-injurious behavior"
      model="data.mental_status_exam.thought.self_injurious_behavior_narrative"
    />

    <CheckboxGroup
      label="Homicidality / bodily injury towards others"
      model="data.mental_status_exam.thought.homicidality_bodily_injury_towards_others"
      layout="vertical-dense"
    >
      <Checkbox
        label="No current ideation, plan, or intent"
        model="data.mental_status_exam.thought.homicidality_bodily_injury_towards_others.no_current_ideation_plan_or_intent"
      />
      <Checkbox
        label="Past ideation only"
        model="data.mental_status_exam.thought.homicidality_bodily_injury_towards_others.past_ideation_only"
      />
      <Checkbox
        label="Past ideation and plan only"
        model="data.mental_status_exam.thought.homicidality_bodily_injury_towards_others.past_ideation_and_plan_only"
      />
      <Checkbox
        label="Past ideation, plan, and intent"
        model="data.mental_status_exam.thought.homicidality_bodily_injury_towards_others.past_ideation_plan_and_intent"
      />
      <Checkbox
        label="Previous attempt"
        model="data.mental_status_exam.thought.homicidality_bodily_injury_towards_others.previous_attempt"
      />

      <Checkbox
        label="Current ideation, no current plan or intent"
        model="data.mental_status_exam.thought.homicidality_bodily_injury_towards_others.current_ideation_no_current_plan_or_intent"
      />
      <Checkbox
        label="Current ideation and plan, no current intent"
        model="data.mental_status_exam.thought.homicidality_bodily_injury_towards_others.current_ideation_and_plan_no_current_intent"
      />
      <Checkbox
        label="Current ideation, plan, and intent"
        model="data.mental_status_exam.thought.homicidality_bodily_injury_towards_others.current_ideation_plan_and_intent"
      />
    </CheckboxGroup>

    <SmartTextarea
      useDictation
      label="Please explain current plan, means, and history of homicidality/bodily injury towards others"
      model="data.mental_status_exam.thought.homicidality_bodily_injury_towards_others_narrative"
    />

    <SmartTextarea useDictation label="Thought Narrative" model="data.mental_status_exam.thought.narrative" />

    <Heading is="h3">Speech</Heading>

    <CheckboxGroup label="Speech" layout="vertical-dense">
      <Checkbox label="Accented" model="data.mental_status_exam.speech.speech.accented" />
      <Checkbox label="Articulate" model="data.mental_status_exam.speech.speech.articulate" />
      <Checkbox label="Canned speech" model="data.mental_status_exam.speech.speech.canned_speech" />
      <Checkbox label="Confused" model="data.mental_status_exam.speech.speech.confused" />
      <Checkbox label="Disorganized" model="data.mental_status_exam.speech.speech.disorganized" />
      <Checkbox label="Emotional" model="data.mental_status_exam.speech.speech.emotional" />
      <Checkbox label="Evasive" model="data.mental_status_exam.speech.speech.evasive" />
      <Checkbox label="Excessive profanity" model="data.mental_status_exam.speech.speech.excessive_profanity" />
      <Checkbox label="Hyper / Hypo" model="data.mental_status_exam.speech.speech.hyper_hypo" />
      <Checkbox label="Impoverished" model="data.mental_status_exam.speech.speech.impoverished" />
      <Checkbox label="Monotonous" model="data.mental_status_exam.speech.speech.monotonous" />
      <Checkbox label="Neologisms" model="data.mental_status_exam.speech.speech.neologisms" />
      <Checkbox label="Over responsive" model="data.mental_status_exam.speech.speech.over_responsive" />
      <Checkbox label="Paucity of speech" model="data.mental_status_exam.speech.speech.paucity_of_speech" />
      <Checkbox label="Scanning speech" model="data.mental_status_exam.speech.speech.scanning_speech" />
      <Checkbox label="Spontaneous" model="data.mental_status_exam.speech.speech.spontaneous" />
      <Checkbox label="Tangential" model="data.mental_status_exam.speech.speech.tangential" />
      <Checkbox label="Under responsive" model="data.mental_status_exam.speech.speech.under_responsive" />
      <Checkbox label="Voice Tremor" model="data.mental_status_exam.speech.speech.voice_tremor" />
      <Checkbox label="Aphasia" model="data.mental_status_exam.speech.speech.aphasia" />
      <Checkbox label="Broca's Aphasia" model="data.mental_status_exam.speech.speech.broca_s_aphasia" />
      <Checkbox label="Dysarthria" model="data.mental_status_exam.speech.speech.dysarthria" />
      <Checkbox label="Global Aphasia" model="data.mental_status_exam.speech.speech.global_aphasia" />
      <Checkbox label="Perseveration" model="data.mental_status_exam.speech.speech.perseveration" />
      <Checkbox label="Stereotypy" model="data.mental_status_exam.speech.speech.stereotypy" />
      <Checkbox label="Wernicke's aphasia" model="data.mental_status_exam.speech.speech.wernickes_aphasia" />
      <Checkbox label="Other" model="data.mental_status_exam.speech.speech.other" />
    </CheckboxGroup>

    <ContextShow when="data.mental_status_exam.speech.speech.other" is={true}>
      <SmartTextarea useDictation label="Please explain:" model="data.mental_status_exam.speech.speech.other_extra" />
    </ContextShow>

    <CheckboxGroup label="Flow of speech" layout="vertical-dense">
      <Checkbox label="Hesitant" model="data.mental_status_exam.speech.speech_flow.hesitant" />
      <Checkbox label="Expansive" model="data.mental_status_exam.speech.speech_flow.expansive" />
      <Checkbox label="Rambling" model="data.mental_status_exam.speech.speech_flow.rambling" />
      <Checkbox label="Halting" model="data.mental_status_exam.speech.speech_flow.halting" />
      <Checkbox label="Stuttering" model="data.mental_status_exam.speech.speech_flow.stuttering" />
      <Checkbox label="Lilting" model="data.mental_status_exam.speech.speech_flow.lilting" />
      <Checkbox label="Jerky" model="data.mental_status_exam.speech.speech_flow.jerky" />
      <Checkbox label="Long pauses" model="data.mental_status_exam.speech.speech_flow.long_pauses" />
      <Checkbox label="Forgetful" model="data.mental_status_exam.speech.speech_flow.forgetful" />
      <Checkbox label="Other" model="data.mental_status_exam.speech.speech_flow.other" />
    </CheckboxGroup>

    <ContextShow when="data.mental_status_exam.speech.speech_flow.other" is={true}>
      <SmartTextarea useDictation label="Please explain:" model="data.mental_status_exam.speech.speech_flow.other_extra" />
    </ContextShow>

    <CheckboxGroup label="Clarity" layout="vertical-dense">
      <Checkbox label="Clear / Coherent" model="data.mental_status_exam.speech.clarity.clear_coherent" />
      <Checkbox label="Slurred" model="data.mental_status_exam.speech.clarity.slurred" />
      <Checkbox label="Mumbled" model="data.mental_status_exam.speech.clarity.mumbled" />
      <Checkbox label="Lisping" model="data.mental_status_exam.speech.clarity.lisping" />
      <Checkbox label="Rambling" model="data.mental_status_exam.speech.clarity.rambling" />
      <Checkbox label="Relevant" model="data.mental_status_exam.speech.clarity.relevant" />
      <Checkbox
        label="Incoherent / Difficult to understand"
        model="data.mental_status_exam.speech.clarity.incoherent_difficult_to_understand"
      />
      <Checkbox label="Other" model="data.mental_status_exam.speech.clarity.other" />
    </CheckboxGroup>

    <ContextShow when="data.mental_status_exam.speech.clarity.other" is={true}>
      <SmartTextarea useDictation label="Please explain:" model="data.mental_status_exam.speech.clarity.other_extra" />
    </ContextShow>

    <CheckboxGroup label="Intensity of volume" layout="vertical-dense">
      <Checkbox label="Yelling" model="data.mental_status_exam.speech.volume.yelling" />
      <Checkbox label="Loud" model="data.mental_status_exam.speech.volume.loud" />
      <Checkbox label="Normal rate and volume" model="data.mental_status_exam.speech.volume.normal_rate_and_volume" />
      <Checkbox label="Soft" model="data.mental_status_exam.speech.volume.soft" />
      <Checkbox label="Whispered" model="data.mental_status_exam.speech.volume.whispered" />
      <Checkbox label="Inaudible" model="data.mental_status_exam.speech.volume.inaudible" />
      <Checkbox label="Other" model="data.mental_status_exam.speech.volume.other" />
    </CheckboxGroup>

    <ContextShow when="data.mental_status_exam.speech.volume.other" is={true}>
      <SmartTextarea useDictation label="Please explain:" model="data.mental_status_exam.speech.volume.other_extra" />
    </ContextShow>

    <CheckboxGroup label="Liveliness" layout="vertical-dense">
      <Checkbox label="Within normal limits" model="data.mental_status_exam.speech.liveliness.within_normal_limits" />
      <Checkbox label="Lively" model="data.mental_status_exam.speech.liveliness.lively" />
      <Checkbox label="Dull" model="data.mental_status_exam.speech.liveliness.dull" />
      <Checkbox label="Monotonous" model="data.mental_status_exam.speech.liveliness.monotonous" />
      <Checkbox label="Intense" model="data.mental_status_exam.speech.liveliness.intense" />
      <Checkbox label="Pressured" model="data.mental_status_exam.speech.liveliness.pressured" />
      <Checkbox label="Explosive" model="data.mental_status_exam.speech.liveliness.explosive" />
      <Checkbox label="Other" model="data.mental_status_exam.speech.liveliness.other" />
    </CheckboxGroup>

    <ContextShow when="data.mental_status_exam.speech.liveliness.other" is={true}>
      <SmartTextarea useDictation label="Please explain:" model="data.mental_status_exam.speech.liveliness.other_extra" />
    </ContextShow>

    <CheckboxGroup label="Quantity" layout="vertical-dense">
      <Checkbox label="Within normal limits" model="data.mental_status_exam.speech.quantity.within_normal_limits" />
      <Checkbox label="Responds only to questions" model="data.mental_status_exam.speech.quantity.responds_only_to_questions" />
      <Checkbox label="Offers information" model="data.mental_status_exam.speech.quantity.offers_information" />
      <Checkbox label="Scant" model="data.mental_status_exam.speech.quantity.scant" />
      <Checkbox label="Mute" model="data.mental_status_exam.speech.quantity.mute" />
      <Checkbox label="Verbose" model="data.mental_status_exam.speech.quantity.verbose" />
      <Checkbox label="Repetitive" model="data.mental_status_exam.speech.quantity.repetitive" />
      <Checkbox label="Other" model="data.mental_status_exam.speech.quantity.other" />
    </CheckboxGroup>

    <ContextShow when="data.mental_status_exam.speech.quantity.other" is={true}>
      <SmartTextarea useDictation label="Please explain:" model="data.mental_status_exam.speech.quantity.other_extra" />
    </ContextShow>

    <CheckboxGroup label="Rate of speech" layout="vertical-dense">
      <Checkbox label="Rapid" model="data.mental_status_exam.speech.rate.rapid" />
      <Checkbox label="Ordinary" model="data.mental_status_exam.speech.rate.ordinary" />
      <Checkbox label="Slow" model="data.mental_status_exam.speech.rate.slow" />
    </CheckboxGroup>

    <Heading is="h3">Judgement and Insight</Heading>

    <RadioGroup label="Judgment" model="data.mental_status_exam.judgment.judgement" layout="vertical-dense">
      <Radio label="Good Judgement" value="good_judgement" />
      <Radio label="Improving Judgement" value="improving_judgement" />
      <Radio label="Fair Judgement" value="fair_judgement" />
      <Radio label="Limited Judgement" value="limited_judgement" />
      <Radio label="Poor Judgement" value="poor_judgement" />
      <Radio label="Judgement Impaired by illness" value="judgement_impaired_by_illness" />
    </RadioGroup>

    <RadioGroup label="Insight" model="data.mental_status_exam.judgment.insight" layout="vertical-dense">
      <Radio label="Good Insight" value="good_insight" />
      <Radio label="Improving Insight" value="improving_insight" />
      <Radio label="Fair Insight" value="fair_insight" />
      <Radio label="Limited Insight" value="limited_insight" />
      <Radio label="Poor Insight" value="poor_insight" />
      <Radio label="Denys illness" value="denys_illness" />
    </RadioGroup>

    <SmartTextarea useDictation label="Judgement and Insight Narrative" model="data.mental_status_exam.judgment.narrative" />

    <Heading is="h3">Cognition and Memory</Heading>

    <RadioGroup label="Attention & Concentration" model="data.mental_status_exam.cognition.attention" layout="vertical-dense">
      <Radio label="Sufficient / Able to focus" value="sufficient" />
      <Radio label="Deficient / Unable to focus" value="deficient" />
      <Radio label="Short span of attention / Easily distracted" value="short_span" />
      <Radio label="Poor or adequate concentration" value="poor" />
      <Radio label="Preoccupation" value="preoccupation" />
    </RadioGroup>

    <RadioGroup label="Immediate memory recall" model="data.mental_status_exam.cognition.immediate_memory_recall" layout="horizontal">
      <Radio label="Intact" value="intact" />
      <Radio label="Average" value="average" />
    </RadioGroup>

    <RadioGroup label="Short term memory" model="data.mental_status_exam.cognition.short_term_memory" layout="horizontal">
      <Radio label="Intact" value="intact" />
      <Radio label="Average" value="average" />
    </RadioGroup>

    <RadioGroup label="Long term memory" model="data.mental_status_exam.cognition.long_term_memory" layout="horizontal">
      <Radio label="Intact" value="intact" />
      <Radio label="Average" value="average" />
    </RadioGroup>

    <CheckboxGroup label="Orientation" layout="vertical-dense">
      <Checkbox label="Person (i.e. What is your name?)" model="data.mental_status_exam.cognition.orientation.person" />
      <Checkbox label="Time (i.e. What is today’s date?)" model="data.mental_status_exam.cognition.orientation.time" />
      <Checkbox label="Place (i.e. What is the name of this place?)" model="data.mental_status_exam.cognition.orientation.place" />
      <Checkbox label="Situation (i.e. Why are you in this place?)" model="data.mental_status_exam.cognition.orientation.situation" />
    </CheckboxGroup>

    <RadioGroup label="Impulse Control" model="data.mental_status_exam.cognition.impulse_control" layout="horizontal">
      <Radio label="Above average" value="above" />
      <Radio label="Average" value="average" />
      <Radio label="Below average" value="below" />
    </RadioGroup>

    <RadioGroup label="Cognitive Capability" model="data.mental_status_exam.cognition.cognitive_capability" layout="horizontal">
      <Radio label="Above average" value="above" />
      <Radio label="Average" value="average" />
      <Radio label="Below average" value="below" />
    </RadioGroup>

    <CheckboxGroup label="Amnesia (If Applicable)" layout="vertical-dense">
      <Checkbox label="Amnesia" model="data.mental_status_exam.cognition.amnesia.amnesia" />
      <Checkbox label="Anterograde amnesia" model="data.mental_status_exam.cognition.amnesia.anterograde_amnesia" />
      <Checkbox label="Retrograde amnesia" model="data.mental_status_exam.cognition.amnesia.retrograde_amnesia" />
      <Checkbox label="History of head trauma" model="data.mental_status_exam.cognition.amnesia.history_of_head_trauma" />
      <Checkbox label="Transient global amnesia" model="data.mental_status_exam.cognition.amnesia.transient_global_amnesia" />
    </CheckboxGroup>

    <SmartTextarea useDictation label="Cognition and Memory Narrative" model="data.mental_status_exam.cognition.narrative" />

    <Heading is="h3">Engagement with Interviewer</Heading>

    <CheckboxGroup label="Attitude toward clinician during encounter" layout="vertical-dense">
      <Checkbox label="Angry" model="data.mental_status_exam.engagement.attitude.angry" />
      <Checkbox label="Contemptuous" model="data.mental_status_exam.engagement.attitude.contemptuous" />
      <Checkbox label="Demanding" model="data.mental_status_exam.engagement.attitude.demanding" />
      <Checkbox label="Fearful" model="data.mental_status_exam.engagement.attitude.fearful" />
      <Checkbox label="Frank" model="data.mental_status_exam.engagement.attitude.frank" />
      <Checkbox label="Friendly" model="data.mental_status_exam.engagement.attitude.friendly" />
      <Checkbox label="Hostile" model="data.mental_status_exam.engagement.attitude.hostile" />
      <Checkbox label="Inappropriate boundaries" model="data.mental_status_exam.engagement.attitude.inappropriate_boundaries" />
      <Checkbox label="Ingratiating" model="data.mental_status_exam.engagement.attitude.ingratiating" />
      <Checkbox
        label="Irritated but not to the level of anger"
        model="data.mental_status_exam.engagement.attitude.irritated_but_not_to_the_level_of_anger"
      />
      <Checkbox label="Manipulative" model="data.mental_status_exam.engagement.attitude.manipulative" />
      <Checkbox label="Negative" model="data.mental_status_exam.engagement.attitude.negative" />
      <Checkbox label="Open" model="data.mental_status_exam.engagement.attitude.open" />
      <Checkbox label="Overly familiar" model="data.mental_status_exam.engagement.attitude.overly_familiar" />
      <Checkbox label="Playful" model="data.mental_status_exam.engagement.attitude.playful" />
      <Checkbox label="Pleasant" model="data.mental_status_exam.engagement.attitude.pleasant" />
      <Checkbox label="Seductive" model="data.mental_status_exam.engagement.attitude.seductive" />
      <Checkbox label="Shy" model="data.mental_status_exam.engagement.attitude.shy" />
      <Checkbox label="Silent" model="data.mental_status_exam.engagement.attitude.silent" />
      <Checkbox label="Sullen" model="data.mental_status_exam.engagement.attitude.sullen" />
      <Checkbox label="Warm" model="data.mental_status_exam.engagement.attitude.warm" />
      <Checkbox label="Withdrawn" model="data.mental_status_exam.engagement.attitude.withdrawn" />
    </CheckboxGroup>

    <CheckboxGroup label="Eye Contact" layout="vertical-dense">
      <Checkbox label="Within Normal Limits" model="data.mental_status_exam.engagement.eye_contact.normal" />
      <Checkbox label="Indirect" model="data.mental_status_exam.engagement.eye_contact.indirect" />
      <Checkbox label="Fixed" model="data.mental_status_exam.engagement.eye_contact.fixed" />
      <Checkbox label="Fleeting" model="data.mental_status_exam.engagement.eye_contact.fleeting" />
      <Checkbox label="Glaring" model="data.mental_status_exam.engagement.eye_contact.hlaring" />
      <Checkbox label="Darting" model="data.mental_status_exam.engagement.eye_contact.darting" />
      <Checkbox label="No contact" model="data.mental_status_exam.engagement.eye_contact.no_contact" />
    </CheckboxGroup>

    <CheckboxGroup label="Cooperation" layout="vertical-dense">
      <Checkbox label="Appropriate" model="data.mental_status_exam.engagement.cooperation.appropriate" />
      <Checkbox label="Guarded" model="data.mental_status_exam.engagement.cooperation.guarded" />
      <Checkbox label="Evasive" model="data.mental_status_exam.engagement.cooperation.evasive" />
      <Checkbox label="Suspicious" model="data.mental_status_exam.engagement.cooperation.suspicious" />
      <Checkbox label="Challenging" model="data.mental_status_exam.engagement.cooperation.challenging" />
      <Checkbox label="Uncooperative" model="data.mental_status_exam.engagement.cooperation.uncooperative" />
    </CheckboxGroup>

    <RadioGroup label="Reliability" model="data.mental_status_exam.engagement.reliability" layout="horizontal">
      <Radio label="Reliable" value="reliable" />
      <Radio label="Contradictory" value="contradictory" />
      <Radio label="Unreliable" value="unreliable" />
    </RadioGroup>

    <SmartTextarea useDictation label="Engagement with Interviewer Narrative" model="data.mental_status_exam.engagement.narrative" />
  </FormSection>
)

export default MentalStatusExam
