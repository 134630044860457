import React from 'react'
import { tint } from 'polished'
import { useMedia } from 'use-media'
import { useRouteMatch } from 'react-router-dom'
import * as HoverCard from '@radix-ui/react-hover-card'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { age, countWord, mapToArray, titleCase, usDateTime } from '../../utils/functions'
import { css, COLORS, SHADOW } from '../../theme'
import { useSettings } from '../../hooks/useSettings'
import { CLIENT_SLUG_BY_STATUS } from '../../utils/constants'

import ApplicationStatus from '../../components/Statuses/ApplicationStatus'
import Avatar from '../../components/Avatar'
import BedOccupancyStatus from '../../components/Statuses/BedOccupancyStatus'
import Button from '../../components/Button'
import CardHeader from '../../components/CardHeader'
import CardSubtitle from '../../components/CardSubtitle'
import CardTitle from '../../components/CardTitle'
import DataList from '../../components/DataList'
import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Link from '../../components/Link'
import Roadmap from '../../components/Roadmap/Roadmap'
import RoadmapBar from '../../components/Roadmap/RoadmapBar'
import RoadmapHeaderRow from '../../components/Roadmap/RoadmapHeaderRow'
import RoadmapRow from '../../components/Roadmap/RoadmapRow'
import SummonOverlay from '../../components/SummonOverlay'
import Tooltip from '../../components/Tooltip'

import AdmissionQuickView from '../../components/Overlays/quick/AdmissionQuickView'
import ClientQuickView from '../../components/Overlays/quick/ClientQuickView'
import PropertyQuickView from '../../components/Overlays/quick/PropertyQuickView'

export const BED_ASSIGNMENT_COLORS = {
  occupied: tint(0.7, COLORS.green),
  reserved: tint(0.8, COLORS.blue),
}

const handleSummonOpen = (event: any) => {
  event.stopPropagation()
  event.nativeEvent.stopImmediatePropagation()
}

export const BedsTimeline: React.FC<any> = (props) => {
  const {
    applicants,
    bedOccupancies,
    className,
    dailyActiveHouseOccupancies,
    houses,
    isLoading,
    isRefetching,
    setDates,
    stats,
    todayOccupancyStats,
    unassigned,
  } = props

  const match = useRouteMatch()

  const incomingCount = size(applicants?.incoming)
  const newApplicantsCount = size(applicants?.new_applicants)
  const pendingCount = size(applicants?.pending)
  const waitlistCount = size(applicants?.waitlist)

  const applicantsCount = newApplicantsCount + pendingCount + incomingCount + waitlistCount

  const [isAllOpen, setIsAllOpen] = React.useState(true)
  const [bedsFilter, setBedsFilter] = React.useState('all')
  const [view, setView] = React.useState('clients')

  return (
    <Roadmap showTodayLine onDatesChange={setDates} className={className} isLoading={isLoading} isRefetching={isRefetching}>
      {/* Applicants */}
      {applicantsCount >= 1 && (
        <RoadmapRow isOpen hideGridLines level={1} icon="applicants" title={`Applicants (${applicantsCount})`}>
          <ApplicantsRoadmapRow title={`New (${newApplicantsCount})`} data={applicants.new_applicants} />
          <ApplicantsRoadmapRow title={`Pending (${pendingCount})`} data={applicants.pending} />
          <ApplicantsRoadmapRow title={`Incoming (${incomingCount})`} data={applicants.incoming} />
          <ApplicantsRoadmapRow title={`Waitlist (${waitlistCount})`} data={applicants.waitlist} />
        </RoadmapRow>
      )}

      {/* Unassigned Clients */}
      {unassigned && (
        <RoadmapRow
          hideGridLines
          level={1}
          icon="clients"
          title={`Currently Unassigned (${size(unassigned)})`}
          after={
            <Tooltip
              css={{ marginLeft: 'auto', marginRight: '0.5rem' }}
              content="Current clients who do not have an active bed occupancy / reservation today."
            />
          }
        >
          {unassigned?.map?.((client: any) => (
            <RoadmapRow
              key={client.id}
              level={3}
              avatar={client.avatar}
              title={client.name}
              after={<ApplicationStatus status={client.status} />}
              actions={
                <>
                  <SummonOverlay overlay={<ClientQuickView client={client} />} onOpen={handleSummonOpen} portalType="iframe">
                    <DropdownItem label="Quick View" color="blue" glyph="quick_view" />
                  </SummonOverlay>

                  <DropdownItem
                    label="Assign Bed…"
                    icon="bed_management"
                    permission="bed_management.edit"
                    link={{
                      pathname: `${match.url}/assign-bed/${client.id}`,
                      parent: match,
                      data: {
                        reference: client,
                        status: 'occupied',
                      },
                    }}
                  />
                </>
              }
            />
          ))}
        </RoadmapRow>
      )}

      <div className={STYLES.divider()} />

      <RoadmapHeaderRow className={STYLES.locationsHeaderRoot().className}>
        <div className={STYLES.locationsHeader()}>
          <Icon icon="locations" size={18} className={STYLES.locationsHeaderIcon().className} />

          <Flex centerY stretchSelf justifyContent="space-between">
            <div className={STYLES.locationsHeaderTitle()}>Locations</div>
          </Flex>

          <Button
            onClick={() => setIsAllOpen((c) => !c)}
            label={`${isAllOpen ? 'Close' : 'Open'} All`}
            size={100}
            glyph={isAllOpen ? 'close_all_rows' : 'open_all_rows'}
            display="inline-block"
            type="minimal"
          />
        </div>

        <div className={STYLES.locationsSubheader()}>
          <Dropdown
            buttonSize={100}
            buttonType="link"
            position="bottom"
            buttonClassName={STYLES.bedsFilterButton().className}
            label={
              <>
                <span className={STYLES.bedsFilterLabel()}>View:</span>
                <span className={STYLES.bedsFilterValue()}>{titleCase(view)}</span>
              </>
            }
          >
            <DropdownItem
              label="Beds"
              isActive={view === 'beds'}
              onClick={() => {
                setView('beds')
              }}
            />
            <DropdownItem
              label="Clients"
              isActive={view === 'clients'}
              onClick={() => {
                setView('clients')
              }}
            />
          </Dropdown>

          <Dropdown
            buttonSize={100}
            buttonType="link"
            position="bottom"
            buttonClassName={STYLES.bedsFilterButton().className}
            label={
              <>
                <span className={STYLES.bedsFilterLabel()}>Show:</span>
                <span className={STYLES.bedsFilterValue()}>{titleCase(bedsFilter)} Beds</span>
              </>
            }
          >
            <DropdownItem
              label="All Beds"
              isActive={bedsFilter === 'all'}
              onClick={() => {
                setBedsFilter('all')
              }}
            />
            <DropdownItem
              label="Available Beds"
              isActive={bedsFilter === 'available'}
              onClick={() => {
                setBedsFilter('available')
              }}
            />
            <DropdownItem
              label="Occupied Beds"
              isActive={bedsFilter === 'occupied'}
              onClick={() => {
                setBedsFilter('occupied')
              }}
            />
          </Dropdown>
        </div>
      </RoadmapHeaderRow>

      {/* Houses */}
      {mapToArray(houses).map((house) => {
        const occupiedBedsCount = todayOccupancyStats?.houses?.[house.id] || 0
        const totalBedsCount = house.beds_count
        const availableBedsCount = totalBedsCount - occupiedBedsCount

        const occupancyDescription =
          view === 'clients'
            ? `${countWord('Clients', occupiedBedsCount)}`
            : bedsFilter === 'occupied'
            ? `${occupiedBedsCount} Occupied Today`
            : `${availableBedsCount} Open 1 Today`

        const occupanciesPerDay = dailyActiveHouseOccupancies?.[house.id]

        return (
          <RoadmapRow
            expandAsCard
            hideGridLines
            isOpen={isAllOpen}
            key={house.id}
            level={1}
            title={house.name}
            subtitle={`${occupancyDescription} / ${countWord('Beds', house.beds_count)}`}
            avatar={house.avatar}
            className={STYLES.houseRow().className}
            actions={
              <>
                {/* <DropdownItem
                  label="Add Floor"
                  color="blue"
                  glyph="add"
                  link={{
                    pathname: `${match.url}/${house.id}/floors/new`,
                    parent: match,
                  }}
                  permission="bed_management.create"
                /> */}

                <SummonOverlay overlay={<PropertyQuickView property={house} />} onOpen={handleSummonOpen} portalType="iframe">
                  <DropdownItem label="Quick View" color="blue" glyph="quick_view" />
                </SummonOverlay>
              </>
            }
            renderBars={() => {
              if (size(occupanciesPerDay) === 0) return null

              return (
                <div className={STYLES.dayOccupancyRow()}>
                  {occupanciesPerDay.map((occupiedCount: number) => {
                    const availableCount = house.beds_count - occupiedCount

                    return (
                      <div className={STYLES.dayOccupancyCell()}>
                        <span>{bedsFilter === 'occupied' ? occupiedCount : availableCount}</span>
                      </div>
                    )
                  })}
                </div>
              )
            }}
          >
            {size(house.floors) >= 1 &&
              house.floors?.map((floor: any) => (
                <RoadmapRow
                  hideGridLines
                  key={floor.id}
                  level={2}
                  title={floor.name}
                  icon="floors"
                  isOpen
                  enableDropdown={false}
                  // actions={
                  //   <>
                  //     <DropdownItem
                  //       color="blue"
                  //       glyph="add"
                  //       label="Add Room"
                  //       link={{
                  //         pathname: `${match.url}/${house.id}/floors/${floor.id}/rooms/new`,
                  //         parent: match,
                  //         data: {
                  //           place: floor,
                  //         },
                  //       }}
                  //       permission="bed_management.create"
                  //     />

                  //     <DropdownItem
                  //       color="blue"
                  //       glyph="edit"
                  //       label="Edit Floor"
                  //       link={{
                  //         pathname: `${match.url}/${house.id}/floors/${floor.id}`,
                  //         parent: match,
                  //       }}
                  //       permission="bed_management.edit"
                  //     />
                  //   </>
                  // }
                >
                  {size(floor.rooms) >= 1 &&
                    floor.rooms?.map?.((room: any) => {
                      const occupiedBedsCount = todayOccupancyStats?.rooms?.[room.id] || 0
                      const totalBedsCount = room.beds_count
                      const availableBedsCount = totalBedsCount - occupiedBedsCount

                      const occupancyDescription =
                        view === 'clients'
                          ? `${countWord('Clients', occupiedBedsCount)}`
                          : bedsFilter === 'occupied'
                          ? `${occupiedBedsCount} Occupied Today`
                          : `${availableBedsCount} Open Today`

                      // hide if room has no available beds to display
                      if (bedsFilter === 'available' && occupiedBedsCount === totalBedsCount) return null

                      // hide if room has no occupied beds to display
                      if (bedsFilter === 'occupied' && occupiedBedsCount === 0) return null

                      return (
                        <RoadmapRow
                          hideGridLines
                          enableMobileView
                          key={room.id}
                          level={2}
                          icon="rooms"
                          title={room.name}
                          isOpen
                          enableDropdown={false}
                          subtitle={`${occupancyDescription} / ${countWord('Beds', room.beds_count)}`}
                        >
                          {view === 'clients' && size(room.beds) >= 1 && (
                            <>
                              {room.beds?.map?.((bed: any) => {
                                return (
                                  <React.Fragment key={bed.id}>
                                    {size(bedOccupancies?.[bed.id]) >= 1 &&
                                      bedOccupancies?.[bed.id]?.map?.((occupancy: any) => (
                                        <RoadmapRow
                                          key={occupancy.id}
                                          enableMobileView
                                          level={2}
                                          avatar=""
                                          title={occupancy?.reference?.name}
                                          subtitle={bed.name}
                                          renderBars={() => <OccupancyBar key={occupancy.id} occupancy={occupancy} />}
                                        />
                                      ))}
                                  </React.Fragment>
                                )
                              })}
                            </>
                          )}

                          {view === 'beds' &&
                            size(room.beds) >= 1 &&
                            room.beds?.map?.((bed: any) => {
                              const bedStats = stats?.beds?.[bed.id]
                              const isOccupied = bedStats.isOccupied

                              const showBed =
                                bedsFilter === 'all' ||
                                (bedsFilter === 'available' && !isOccupied) ||
                                (bedsFilter === 'occupied' && isOccupied)

                              if (!showBed) return null

                              return (
                                <RoadmapRow
                                  key={bed.id}
                                  enableMobileView
                                  level={2}
                                  icon="bed_management"
                                  title={bed.name}
                                  renderBars={({ isOpen }) => {
                                    if (isOpen || !bedOccupancies?.[bed.id]) return null

                                    return bedOccupancies[bed.id]?.map?.((occupancy: any) => (
                                      <OccupancyBar key={occupancy.id} occupancy={occupancy} />
                                    ))
                                  }}
                                  actions={
                                    <>
                                      <DropdownItem
                                        label="Reserve Bed"
                                        icon="bed_management"
                                        link={{
                                          pathname: `${match.url}/occupancies/new`,
                                          parent: match,
                                          data: {
                                            status: 'reserved',
                                          },
                                        }}
                                        permission="bed_management.edit"
                                      />

                                      {/* <DropdownItem
                                        color="blue"
                                        glyph="edit"
                                        label="Edit Bed"
                                        link={{
                                          pathname: `${match.url}/${house.id}/floors/${floor.id}/rooms/${room.id}/beds/${bed.id}`,
                                          parent: match,
                                        }}
                                        permission="bed_management.edit"
                                      /> */}
                                    </>
                                  }
                                >
                                  {size(bedOccupancies?.[bed.id]) >= 1 &&
                                    bedOccupancies?.[bed.id]?.map?.((occupancy: any) => (
                                      <RoadmapRow
                                        key={occupancy.id}
                                        enableMobileView
                                        level={3}
                                        avatar=""
                                        title={occupancy?.reference?.name}
                                        renderBars={() => <OccupancyBar key={occupancy.id} occupancy={occupancy} />}
                                      />
                                    ))}
                                </RoadmapRow>
                              )
                            })}
                        </RoadmapRow>
                      )
                    })}
                </RoadmapRow>
              ))}
          </RoadmapRow>
        )
      })}
    </Roadmap>
  )
}

export const HoverCardRoot: React.FC = ({ children, ...rest }) => {
  return (
    <HoverCard.Root openDelay={100} closeDelay={100} {...rest}>
      {children}
    </HoverCard.Root>
  )
}

export const HoverCardContent: React.FC = ({ children }) => {
  const isDesktop = useMedia({ minWidth: 600 })

  const container = document?.getElementById?.('portal-radix')
  const collisionBoundary = document?.getElementById?.('roadmap')

  return (
    <HoverCard.Portal container={container}>
      <HoverCard.Content
        className={STYLES.hoverCard()}
        collisionBoundary={collisionBoundary}
        collisionPadding={{ left: isDesktop ? 200 : 20 }}
      >
        <HoverCard.Arrow width={14} height={8} className={STYLES.hoverCardArrow()} />
        {children}
      </HoverCard.Content>
    </HoverCard.Portal>
  )
}

const ApplicantsRoadmapRow = ({ title, data }: any) => {
  const match = useRouteMatch()

  const { timezone } = useSettings()

  return (
    <>
      <RoadmapRow hideGridLines level={2} title={title}>
        {data?.map?.((applicant: any) => (
          <RoadmapRow
            key={applicant.id}
            level={3}
            avatar={applicant.avatar}
            title={applicant.name}
            renderBars={() => {
              if (!applicant.estimated_arrival) return null

              const noEndDate = !applicant.estimated_discharge
              const background = tint(0.7, noEndDate ? COLORS.orange : COLORS.green)

              return (
                <HoverCardRoot>
                  <HoverCard.Trigger asChild>
                    <RoadmapBar
                      asGradient={noEndDate}
                      background={background}
                      endDate={applicant.estimated_discharge}
                      startDate={applicant.estimated_arrival}
                      width={noEndDate && 100}
                    >
                      <RoadmapBarContent avatar={applicant.avatar} title={applicant.name} />
                    </RoadmapBar>
                  </HoverCard.Trigger>

                  <HoverCardContent>
                    <ClientHeader client={applicant} />

                    <DataList isCompact labelWidth={120} withPadding>
                      <DataList.Item label="Est. Arrival" value={usDateTime(applicant.estimated_arrival, timezone)} />
                      <DataList.Item label="Est. Discharge" value={usDateTime(applicant.estimated_discharge, timezone)} />
                    </DataList>
                  </HoverCardContent>
                </HoverCardRoot>
              )
            }}
            actions={
              <>
                <SummonOverlay overlay={<AdmissionQuickView client={applicant} />} onOpen={handleSummonOpen} portalType="iframe">
                  <DropdownItem label="Quick View" color="blue" glyph="quick_view" />
                </SummonOverlay>

                <DropdownItem
                  label="Reserve Bed"
                  icon="bed_management"
                  link={{
                    pathname: `${match.url}/occupancies/new`,
                    parent: match,
                    data: {
                      reference: applicant,
                      status: 'reserved',
                    },
                  }}
                  permission="bed_management.edit"
                />
              </>
            }
          />
        ))}
      </RoadmapRow>
    </>
  )
}

const RoadmapBarContent = (props: any) => {
  const { avatar, title } = props

  return (
    <div className={STYLES.roadmapBarContent()}>
      <Avatar src={avatar} initials={title} size={20} />
      <div className={STYLES.roadmapBarTitle()}>{title}</div>
    </div>
  )
}

const ClientHeader = ({ client }: any) => {
  if (!client) return null

  return (
    <Flex centerY nowrap gap="0.5rem" css={{ padding: '0.75rem 1rem 0.5rem' }}>
      <Avatar src={client.avatar} initials={client.name} size={40} />
      <CardHeader>
        <Link to={`/${CLIENT_SLUG_BY_STATUS[client.status]}/${client.id}/occupancies`}>
          <CardTitle title={client.name} css={{ fontWeight: 700, fontSize: '1.2rem', color: COLORS.blue }} />
        </Link>
        <CardSubtitle subtitle={`${startCase(client.sex) || '–'}, ${age(client.dob)} y/o, #${client.behave_id}`} />
      </CardHeader>
    </Flex>
  )
}

const OccupancyBar = ({ occupancy }: any) => {
  const match = useRouteMatch()
  const { timezone } = useSettings()

  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  if (!occupancy) return null

  return (
    <HoverCardRoot open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
      <HoverCard.Trigger asChild>
        <RoadmapBar
          key={occupancy.id}
          background={BED_ASSIGNMENT_COLORS[occupancy.status]}
          endDate={occupancy.ended_at}
          startDate={occupancy.started_at}
          onClick={openTooltip}
        >
          <RoadmapBarContent avatar={occupancy.reference.avatar} title={occupancy?.reference?.name} />
        </RoadmapBar>
      </HoverCard.Trigger>

      <HoverCardContent>
        <ClientHeader client={occupancy.reference} />

        <DataList isCompact labelWidth={100} withPadding>
          <DataList.Item label="Status" value={<BedOccupancyStatus status={occupancy.status} />} />
          <DataList.Item label="From" value={usDateTime(occupancy.started_at, timezone)} />
          <DataList.Item
            label="Until"
            value={occupancy.period_type === 'indefinite' ? 'Discharged' : usDateTime(occupancy.ended_at, timezone)}
          />
        </DataList>

        <Grid gap="0.75rem" css={{ padding: '0.2rem 1rem 0.4rem' }}>
          <Button
            label="Edit Bed Assignment"
            glyph="edit"
            size={200}
            link={{
              pathname: `${match.url}/occupancies/${occupancy.id}`,
              parent: match,
            }}
            onClick={closeTooltip}
            permission="bed_management.edit"
          />

          <Button
            label="Move Beds"
            glyph="swap"
            color="green"
            size={200}
            link={{
              pathname: `${match.url}/${occupancy.place.house.id}/move-beds/${occupancy.reference.id}`,
              parent: match,
            }}
            onClick={closeTooltip}
            permission="bed_management.edit"
          />
        </Grid>
      </HoverCardContent>
    </HoverCardRoot>
  )
}

const STYLES = {
  hoverCard: css({
    minWidth: 280,
    maxWidth: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    borderRadius: 7,
    background: COLORS.white,
    boxShadow: SHADOW(10, COLORS.divider),
    border: 'none',
    paddingBottom: '0.5rem',

    '@media(min-width: 600px)': {
      minWidth: 360,
    },
  }),

  hoverCardArrow: css({
    color: COLORS.white,
    margin: '0 1rem',
  }),

  tabsList: css({
    padding: '0 0.75rem',

    '& > *': {
      padding: '0.8rem 0.25rem 0.6rem !important',
    },
  }),

  locationsHeaderRoot: css({
    padding: '0.3rem 0.75rem !important',
  }),

  locationsHeader: css({
    display: 'flex',
    alignItems: 'center',
  }),

  locationsSubheader: css({
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0',
    justifyContent: 'space-between',
  }),

  locationsHeaderIcon: css({
    marginRight: '0.5rem',
  }),

  locationsHeaderTitle: css({
    fontSize: '0.95rem',
    fontWeight: 700,
    color: COLORS.text,
    textTransform: 'uppercase',
    marginRight: 'auto',
  }),

  bedsFilterButton: css({
    padding: '0 !important',
  }),

  bedsFilterLabel: css({
    fontWeight: 700,
    color: COLORS.text,
    display: 'inline-flex',
    marginRight: '0.2em',
  }),

  bedsFilterValue: css({
    fontWeight: 500,
  }),

  roadmapBarContent: css({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  }),

  roadmapBarTitle: css({
    fontSize: '0.9rem',
    fontWeight: 600,
    marginLeft: '0.4rem',
  }),

  dayOccupancyRow: css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexWrap: 'nowrap',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
  }),

  dayOccupancyCell: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.86rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    color: COLORS.textMuted,
    opacity: 0.7,
  }),

  houseRow: css({
    // borderTop: `1.5px solid ${COLORS.divider}`,
  }),

  divider: css({
    borderTop: `1px solid ${COLORS.divider}`,
    marginTop: 4,
  }),
}
