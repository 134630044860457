import React from 'react'
import sum from 'lodash/sum'

import Divider from '../../../../Divider'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'

import Score from './Score'

const FallRiskAssessmentForm = () => {
  const [score, setScore] = React.useReducer(reducer, initialState)

  const finalScore = sum(Object.values(score)) || 0

  return (
    <>
      <RadioGroup label="Mobility" model="data.mobility" layout="vertical-dense" onUpdate={(state) => setScore(['mobility', state.value])}>
        <Radio label="0 = Ambulates without gait disturbance" value="ambulates_without_gait_disturbance" />
        <Radio
          label="1 = Ambulates or transfers with assertive devices or assistance/unsteady gait"
          value="ambulates_with_assertive_devices"
        />
        <Radio label="1 = Ambulates with an unsteady gait and no assistance" value="ambulates_with_unsteady_gait" />
        <Radio label="1 = Unable to ambulate or transfer" value="unable_to_ambulate" />
      </RadioGroup>

      <RadioGroup
        label="Mentation"
        model="data.mentation"
        layout="vertical-dense"
        onUpdate={(state) => setScore(['mentation', state.value])}
      >
        <Radio label="0 = None present" value="none" />
        <Radio label="1 = Alert, oriented X 3" value="alert" />
        <Radio label="1 = Confused at times" value="confused" />
        <Radio label="1 = Periodic confusion" value="periodic_confusion" />
        <Radio label="0 = Comatose/Unresponsive" value="comatose" />
      </RadioGroup>

      <RadioGroup
        label="Medication"
        model="data.medication"
        layout="vertical-dense"
        onUpdate={(state) => setScore(['medication', state.value])}
      >
        <Radio
          label="0 = No anticonvulsants, tranquilizers, psychotropics, hypnotics diuretics, antihypertensives, opiates, antihistamines, sedatives, insulin"
          value="no_anticonvulsants"
        />
        <Radio
          label="1 = Anticonvulsants, tranquilizers, psychotropics, hypnotics, diuretics, antihypertensives, opiates, antihistamines, sedatives, insulin"
          value="anticonvulsants"
        />
      </RadioGroup>

      <RadioGroup
        label="Elimination"
        model="data.elimination"
        layout="vertical-dense"
        onUpdate={(state) => setScore(['elimination', state.value])}
      >
        <Radio label="0 = Independent in elimination" value="independent" />
        <Radio label="1 = Independent with frequency or diarrhea" value="independent_with_frequency" />
        <Radio label="1 = Needs assistance in toileting" value="needs_assistance" />
        <Radio label="1 = Incontinent" value="incontinent" />
      </RadioGroup>

      <RadioGroup label="Medical" model="data.medical" layout="vertical-dense" onUpdate={(state) => setScore(['medical', state.value])}>
        <Radio label="0 = No contributing medical history" value="no_medical_history" />
        <Radio label="1 = Syncopal episode within past 30 days" value="syncopal_episode" />
        <Radio label="1 = Seizure diagnosis" value="seizure_diagnosis" />
        <Radio label="1 = Loss of consciousness within past 30 days" value="loss_of_consciousness" />
        <Radio
          label="1 = Diagnosis of cardiac arrhythmia, hypertension, hypotension or diabetes within past 30 days"
          value="diagnosis_of_cardiac_arrhythmia"
        />
        <Radio label="1 = Thoracic or abdominal surgery in past 14 days" value="thoracic_or_abdominal_surgery" />
      </RadioGroup>

      <RadioGroup label="Other" model="data.other" layout="vertical-dense" onUpdate={(state) => setScore(['other', state.value])}>
        <Radio label="2 = Acute intoxication" value="acute_intoxication" />
        <Radio label="1 = Impaired hearing" value="impaired_hearing" />
        <Radio label="1 = Impaired vision" value="impaired_vision" />
        <Radio label="1 = Language difficulty" value="language_difficulty" />
        <Radio label="1 = Inability or unwillingness to follow instruction" value="inability_to_follow_instruction" />
        <Radio label="1 = Acute pain" value="acute_pain" />
        <Radio label="1 = A distinct negative change in metal status" value="distinct_metal_status_negative_change" />
      </RadioGroup>

      <RadioGroup
        label="Prior Fall History"
        model="data.prior_fall_history"
        layout="vertical-dense"
        onUpdate={(state) => setScore(['prior_fall_history', state.value])}
      >
        <Radio label="0 = No prior history" value="no_prior_history" />
        <Radio label="1 = Unknown/Unable to obtain" value="unknown" />
        <Radio label="1 = Yes, before admission in past 12 months" value="before_admission_in_past_12_months" />
        <Radio label="2 = Yes, during this admission" value="during_admission" />
      </RadioGroup>

      <Divider />

      <Score title="Final Score" finalScore={finalScore} scoring={SCORING} />
    </>
  )
}

const SCORING = {
  0: 'none',
  2: 'low',
  5: 'moderate',
  9: 'severe',
}

const SCORES = {
  mobility: {
    ambulates_without_gait_disturbance: 0,
    ambulates_with_assertive_devices: 1,
    ambulates_with_unsteady_gait: 1,
    unable_to_ambulate: 1,
  },
  mentation: {
    none: 0,
    alert: 1,
    confused: 1,
    periodic_confusion: 1,
    comatose: 0,
  },
  medication: {
    no_anticonvulsants: 0,
    anticonvulsants: 1,
  },
  elimination: {
    independent: 0,
    independent_with_frequency: 1,
    needs_assistance: 1,
    incontinent: 1,
  },
  medical: {
    no_medical_history: 0,
    syncopal_episode: 1,
    seizure_diagnosis: 1,
    loss_of_consciousness: 1,
    diagnosis_of_cardiac_arrhythmia: 1,
    thoracic_or_abdominal_surgery: 1,
  },
  other: {
    acute_intoxication: 2,
    impaired_hearing: 1,
    impaired_vision: 1,
    language_difficulty: 1,
    inability_to_follow_instruction: 1,
    acute_pain: 1,
    distinct_metal_status_negative_change: 1,
  },
  prior_fall_history: {
    no_prior_history: 0,
    unknown: 1,
    before_admission_in_past_12_months: 1,
    during_admission: 2,
  },
}

const initialState = {
  mobility: 0,
  mentation: 0,
  medication: 0,
  elimination: 0,
  medical: 0,
  other: 0,
  prior_fall_history: 0,
}

const reducer = (state, payload) => {
  const model = payload[0]
  const value = payload[1]

  return {
    ...state,
    [model]: SCORES[model][value],
  }
}

export default FallRiskAssessmentForm
