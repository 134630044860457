import React from 'react'
import { DateTime } from 'luxon'
import { Route, NavLink, Navigate, Routes, useParams } from 'react-router-dom-v5-compat'

import { APP_URLS } from '../MyAccount/utils'
import { COLORS, HARD_SHADOW } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import CardTreeItem from '../../components/CardTreeItem'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import EmailInput from '../../components/Forms/EmailInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Portal from '../../components/Portal'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Link from '../../components/Link'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PageSection from '../../components/PageSection/PageSection'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import Tabs from '../../components/Tabs'
import URLInput from '../../components/Forms/URLInput'
import Value from '../../components/Value'

import { CommunityProfileHealthcareServicesTab } from './CommunityProfileHealthcareServicesTab'
import { CommunityProfileInsurancePayersTab } from './CommunityProfileInsurancePayersTab'
import { CommunityProfileNonHealthcareServicesTab } from './CommunityProfileNonHealthcareServicesTab'
import { CommunityProfileStaffDataTable } from './CommunityProfileStaffDataTable'

import { CommunityProfileLocationsTab } from './CommunityProfileLocationsTab'
import { CommunityProfileMessagesTab } from './CommunityProfileMessagesTab'
import { CommunityProfilePartnersTab } from './CommunityProfilePartnersTab'
import { CommunityProfileWebsiteTab } from './CommunityProfileWebsiteTab'

import { POPULATIONS_SERVED_GROUPS } from './constants'
import { useCommunityEditor } from './useCommunityEditor'
import { useCommunityPermissions } from './useCommunityPermissions'
import { CommunityMessageOverlay } from './CommunityMessageOverlay'

const CustomAlert = ({ publicURL }: any) => {
  return (
    <div className="px-3 pt-3">
      <Alert contrast small glyph="info" className="mb-4" type="negative">
        <span className="text-[600]">Please note:</span> all information entered on your profile pages here will be publicly visible on your
        community profile website located here:{' '}
        <Link href={publicURL} target="_blank">
          View Profile
        </Link>
      </Alert>
    </div>
  )
}

const RootCommunityProfileEditorOverlay = (props: any) => {
  const { slug } = useParams()

  const { connectedEntities } = useSettings()

  const activeEntity = React.useMemo(() => {
    return connectedEntities?.find?.((entity: any) => entity.slug === slug) || null
  }, [slug])

  const entityId = activeEntity?.id
  const entityName = activeEntity?.name

  const { canEditCommunityProfiles } = useCommunityPermissions()

  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  const {
    edit,
    cancel,
    data,
    form,
    onValidationUpdate,
    isOverlayLoading,
    save,
    saveWithData,
    isSaving,
    isEditable,
    isLoading,
    refetch,
    isRefetching,
    close,
  } = useOverlay({
    name: 'community-entity',
    endpoint: `/community/entities`,
    invalidate: 'community-entities',
    disableParentRequest: true,
    options: {
      dataID: entityId,
      ...props,
    },
    onSaveSuccessful: (newData: any) => {
      setUpdatedAt(DateTime.now().toMillis())
    },
  })

  const { isBehave, timezone } = useSettings()

  const [avatarData, setAvatarData] = React.useState<any>(null)
  const [avatarName, setAvatarName] = React.useState<any>(null)

  const handleSave = () => {
    const formData = form.current.getFormValue()

    saveWithData({
      ...formData,
      ...(avatarData &&
        avatarName && {
          avatar_data: avatarData,
          avatar_name: avatarName,
        }),
    })
  }

  if (!data || isOverlayLoading) return <OverlayLoader position="right" maxWidth={50} />

  const publicURL = data.slug && `${APP_URLS.community}/profiles/${data.slug}`

  return (
    <Overlay position="right" maxWidth={50} onClose={close}>
      <Overlay.Header title="Edit Community Profile" icon="community_profile" />

      {/* <Overlay.SubHeader className="flex flex-nowrap justify-between items-center">
        <Text label={entityName} avatar="" />
        <Button
          useGlyphForTarget
          label="Open Profile in New Tab"
          icon="community_profile"
          type="minimal"
          size={200}
          href={publicURL}
          target="_blank"
        />
      </Overlay.SubHeader> */}

      <div className="px-3 py-2 flex flex-nowrap justify-between items-center border-b border-0 border-solid border-divider">
        <div className="flex flex-nowrap items-center">
          <Avatar size={22} initials={entityName} src="" />
          <div className="ml-2 text-[1.05rem] font-[700]">{entityName}</div>
        </div>

        <Button useGlyphForTarget label="Open Profile in New Tab" type="default" size={100} href={publicURL} target="_blank" />
      </div>

      <Tabs css={STYLES.tabs}>
        <Tabs.Item as={NavLink} label="Profile Details" icon="community_profile" size={200} css={STYLES.tab} to={`details`} />

        <Tabs.Item
          as={NavLink}
          label="Message Board"
          icon="message_board"
          color={COLORS.orange}
          size={200}
          css={STYLES.tab}
          to={`message-board`}
        />
        <Tabs.Item as={NavLink} label="Locations" icon="locations" color={COLORS.red} size={200} css={STYLES.tab} to={`locations`} />

        <Tabs.Item as={NavLink} label="Team" icon="employees" size={200} css={STYLES.tab} to={`team`} />

        <Tabs.Item
          as={NavLink}
          label="Insurance Payers"
          icon="bank_building"
          color={COLORS.green}
          size={200}
          css={STYLES.tab}
          to={`insurance-payers`}
        />
        <Tabs.Item
          as={NavLink}
          label="Healthcare Services"
          icon="healthcare_services"
          color={COLORS.red}
          size={200}
          css={STYLES.tab}
          to={`healthcare-services`}
        />
        <Tabs.Item
          as={NavLink}
          label="Services"
          icon="non_healthcare_services"
          color={COLORS.red}
          size={200}
          css={STYLES.tab}
          to={`services`}
        />
        {isBehave && (
          <Tabs.Item
            as={NavLink}
            label="Populations Served"
            icon="patient"
            color={COLORS.purple}
            size={200}
            css={STYLES.tab}
            to={`populations-served`}
          />
        )}

        {isBehave && (
          <Tabs.Item
            as={NavLink}
            label="Partners"
            icon="community_partners"
            color={COLORS.purple}
            size={200}
            css={STYLES.tab}
            to={`partners`}
          />
        )}

        {/* {isBehave && (
          <Tabs.Item as={NavLink} label="Gallery" icon="gallery" color={COLORS.gray} size={200} css={STYLES.tab} to={`gallery`} />
        )} */}

        <Tabs.Item as={NavLink} label="Website" icon="web_form" size={200} css={STYLES.tab} to={`website`} />

        {/* {isBehave && <Tabs.Item as={NavLink} label="Assets" icon="assets" color={COLORS.gray} size={200} css={STYLES.tab} to={`assets`} />} */}
      </Tabs>

      <Routes>
        <Route
          path={`details`}
          element={
            <>
              <Overlay.Content>
                <Form
                  isCompact
                  isEditable={isEditable}
                  getForm={form}
                  initialModel={data}
                  timezone={timezone}
                  onValidationUpdate={onValidationUpdate}
                  className="pb-4"
                >
                  <CustomAlert publicURL={publicURL} />

                  <div className="px-4 grid gap-3">
                    <CardTreeItem isOpen title="Pages Visibility" glyph="website">
                      <FormSection maxWidth="100%">
                        <div className="grid pt-3 pb-5 pr-3">
                          <CheckboxGroup layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="none">
                            <Checkbox label="Locations" model="view_settings.locations" icon="locations" />
                            <Checkbox label="Team" model="view_settings.team" icon="employees" />
                            <Checkbox label="Insurance Payers" model="view_settings.insurance_payers" icon="bank_building" />
                            <Checkbox label="Healthcare Services" model="view_settings.healthcare_services" icon="healthcare_services" />
                            <Checkbox label="Services" model="view_settings.non_healthcare_services" icon="non_healthcare_services" />
                            <Checkbox label="Populations Served" model="view_settings.populations_served" icon="patient" />
                            <Checkbox label="Website" model="view_settings.website" icon="web_form" />
                            {isBehave && <Checkbox label="Partners" model="view_settings.partners" icon="community_partners" />}
                            <Checkbox label="Bed Availability" model="view_settings.bed_availability" icon="bed_management" />
                            <Checkbox label="Contact Us" model="view_settings.contact_us" icon="communications" />
                            <Checkbox
                              label="Full Application Form"
                              model="view_settings.full_application_form"
                              icon="admissions_department"
                            />
                            <Checkbox label="EVOB Application Form" model="view_settings.evob_application_form" icon="insurance" />
                          </CheckboxGroup>
                        </div>
                      </FormSection>
                    </CardTreeItem>

                    <CardTreeItem isOpen title="Business Details" glyph="organization_details">
                      <FormSection maxWidth="100%" className="pt-3 pb-5 pr-3">
                        {isBehave && (
                          <div>
                            <Label isCompact label="Logo" />
                            {!isEditable && !data?.avatar && <Value value={null} />}

                            {(isEditable || !!data?.avatar) && (
                              <Avatar
                                isEditable={isEditable}
                                src={avatarData || data?.avatar}
                                width={120}
                                height={80}
                                recordId={data?.id}
                                onUpload={(params) => {
                                  setAvatarData(params.avatar_data)
                                  setAvatarName(params.avatar_name)
                                }}
                              />
                            )}
                          </div>
                        )}

                        <Input label="Name" model="name" />
                        <Input label="License Number" model="license_number" />

                        <CheckboxGroup
                          label="Accreditations & Certifications"
                          layout="vertical-dense"
                          trueIcon="check"
                          falseIcon="cross"
                          falseStyle="none"
                        >
                          <Checkbox label="CARF" model="certifications.carf" />
                          <Checkbox label="JCAHO" model="certifications.jcaho" />
                          <Checkbox label="NCQA" model="certifications.ncqa" />
                          <Checkbox label="COA" model="certifications.coa" />
                          <Checkbox label="ASAM Certified" model="certifications.asam" />
                          <Checkbox label="NAATP" model="certifications.naatp" />
                          <Checkbox label="ACHC" model="certifications.achc" />
                          <Checkbox label="State Licensed" model="certifications.state_licensed" />
                          <Checkbox label="NARR Certified" model="certifications.narr" />
                          <Checkbox label="Legitscript" model="certifications.legitscript" />
                        </CheckboxGroup>
                      </FormSection>
                    </CardTreeItem>

                    <CardTreeItem isOpen title="Contact Details" glyph="contact_details">
                      <FormSection maxWidth="100%" className="pt-3 pb-5 pr-3">
                        <PhoneInput label="Primary Phone Number" glyph="landline" model="phone_no" />
                        <EmailInput label="Primary Email Address" glyph="email" model="email" />
                      </FormSection>
                    </CardTreeItem>

                    <CardTreeItem isOpen title="Website & Social Media" glyph="website">
                      <FormSection maxWidth="100%" className="pt-3 pb-5 pr-3">
                        <URLInput label="Website" glyph="website" model="url" />
                        <URLInput label="Facebook" glyph="facebook" model="facebook_url" />
                        <URLInput label="Instagram" glyph="instagram" model="instagram_url" />
                        <URLInput label="LinkedIn" glyph="linkedin" model="linkedin_url" />
                        <URLInput label="Twitter" glyph="twitter" model="twitter_url" />
                        <URLInput label="Google" glyph="googleplus" model="google_url" />
                        <URLInput label="YouTube" glyph="youtube" model="youtube_url" />
                      </FormSection>
                    </CardTreeItem>
                  </div>
                </Form>
              </Overlay.Content>

              <Overlay.Footer>
                {canEditCommunityProfiles && (
                  <Flex gap="0.75rem" className="bg-[#F7F8FB]">
                    {isEditable && (
                      <>
                        <Button
                          label="Save Community Profile"
                          glyph="check"
                          type="primary"
                          color="green"
                          isLoading={isSaving}
                          onClick={handleSave}
                          flex="100 1 auto"
                        />

                        <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />
                      </>
                    )}

                    {!isEditable && (
                      <>
                        <Button
                          glyph="edit"
                          label="Edit Community Profile"
                          type="primary"
                          isDisabled={isLoading}
                          onClick={edit}
                          flex="100 1 auto"
                        />
                      </>
                    )}
                  </Flex>
                )}
              </Overlay.Footer>
            </>
          }
        />

        <Route
          path={`message-board`}
          element={
            <Overlay.Content>
              <CustomAlert publicURL={publicURL} />

              <Section>
                <CommunityProfileMessagesTab entityId={entityId} data={data} />
              </Section>
            </Overlay.Content>
          }
        />

        <Route
          path={`locations`}
          element={
            <Overlay.Content>
              <CustomAlert publicURL={publicURL} />

              <CommunityProfileLocationsTab entityId={entityId} data={data} />
            </Overlay.Content>
          }
        />

        <Route
          path={`team`}
          element={
            <Overlay.Content>
              <CustomAlert publicURL={publicURL} />

              <Section>
                <CommunityProfileStaffDataTable entityId={entityId} />
              </Section>
            </Overlay.Content>
          }
        />

        <Route
          path={`insurance-payers`}
          element={
            <Overlay.Content>
              <CustomAlert publicURL={publicURL} />

              <Section>
                <CommunityProfileInsurancePayersTab entityId={entityId} />
              </Section>
            </Overlay.Content>
          }
        />

        <Route
          path={`healthcare-services`}
          element={
            <Overlay.Content>
              <CustomAlert publicURL={publicURL} />

              <Section>
                <CommunityProfileHealthcareServicesTab entityId={entityId} />
              </Section>
            </Overlay.Content>
          }
        />

        <Route
          path={`services`}
          element={
            <Overlay.Content>
              <CustomAlert publicURL={publicURL} />

              <Section>
                <CommunityProfileNonHealthcareServicesTab entityId={entityId} />
              </Section>
            </Overlay.Content>
          }
        />

        <Route
          path={`gallery`}
          element={
            <>
              <CustomAlert publicURL={publicURL} />

              <GalleryTab />
            </>
          }
        />

        <Route
          path={`website`}
          element={
            <>
              <CustomAlert publicURL={publicURL} />

              <CommunityProfileWebsiteTab
                entityId={entityId}
                data={data}
                isSaving={isSaving}
                updateEntity={saveWithData}
                refetchEntity={refetch}
                isRefetchingEntity={isRefetching}
              />
            </>
          }
        />

        <Route
          path={`assets`}
          element={
            <Overlay.Content>
              <CustomAlert publicURL={publicURL} />

              <Section>
                <PageSection>
                  <PageSection.Header graphic={<Icon icon="assets" />}>
                    <PageSection.Title title="Assets" />
                  </PageSection.Header>
                </PageSection>
              </Section>
            </Overlay.Content>
          }
        />

        <Route
          path={`populations-served`}
          element={
            <>
              <Overlay.Content>
                <Form
                  isCompact
                  isEditable={isEditable}
                  getForm={form}
                  initialModel={data}
                  timezone={timezone}
                  onValidationUpdate={onValidationUpdate}
                  className="pb-4"
                >
                  <CustomAlert publicURL={publicURL} />

                  <div className="px-4 grid gap-3">
                    <Alert small contrast glyph="info">
                      Populations served are managed separately at the main Organization Level and each{' '}
                      <Link to={`locations`}>individual location</Link> level.
                    </Alert>

                    <CardTreeItem isOpen title="Populations Served">
                      <FormSection maxWidth="100%" className="pt-3 pr-4 pb-5">
                        {POPULATIONS_SERVED_GROUPS.map((group) => (
                          <CheckboxGroup
                            key={group.model}
                            layout="vertical-dense"
                            trueIcon="check"
                            falseIcon="cross"
                            falseStyle="faded"
                            label={group.label}
                            withHover={false}
                          >
                            {group.groupItems.map((o) => (
                              <Checkbox key={o.model} label={o.label} model={`populations_served.${group.model}.${o.model}`} />
                            ))}
                          </CheckboxGroup>
                        ))}
                      </FormSection>
                    </CardTreeItem>
                  </div>
                </Form>
              </Overlay.Content>

              <Overlay.Footer>
                {canEditCommunityProfiles && (
                  <Flex gap="0.75rem" className="bg-[#F7F8FB]">
                    {isEditable && (
                      <>
                        <Button
                          label="Save Populations Served"
                          glyph="check"
                          type="primary"
                          color="green"
                          isLoading={isSaving}
                          onClick={handleSave}
                          flex="100 1 auto"
                        />

                        <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />
                      </>
                    )}

                    {!isEditable && (
                      <>
                        <Button
                          glyph="edit"
                          label="Edit Populations Served"
                          type="primary"
                          isDisabled={isLoading}
                          onClick={edit}
                          flex="100 1 auto"
                        />
                      </>
                    )}
                  </Flex>
                )}
              </Overlay.Footer>
            </>
          }
        />

        <Route
          path={`partners`}
          element={
            <Overlay.Content>
              <CustomAlert publicURL={publicURL} />

              <CommunityProfilePartnersTab
                entityId={entityId}
                data={data}
                isSaving={isSaving}
                updateEntity={saveWithData}
                refetchEntity={refetch}
                isRefetchingEntity={isRefetching}
              />
            </Overlay.Content>
          }
        />

        <Route index element={<Navigate to="details" replace />} />

        <Route
          path={`message-board/:id`}
          element={
            <Portal type="z100">
              <CommunityMessageOverlay entityId={entityId} />
            </Portal>
          }
        />
      </Routes>
    </Overlay>
  )
}

export const CommunityProfileEditorOverlay = withOverlayError(RootCommunityProfileEditorOverlay)

const GalleryTab = ({ entityId, data }: any) => {
  return (
    <Overlay.Content>
      <Section>
        <PageSection>
          <PageSection.Header graphic={<Icon icon="dashboard" />}>
            <PageSection.Title title="Gallery" />
          </PageSection.Header>
        </PageSection>

        <div className="grid grid-cols-3 grid-rows-auto gap-[2px] align-start">
          {IMAGES.map((o) => {
            if (!o)
              return (
                <div
                  className={
                    'h-[180px] relative overflow-hidden border-2 border-dashed border-divider flex items-center justify-center hover:bg-hover cursor-pointer'
                  }
                >
                  <Glyph glyph="add" color={COLORS.blue} size={18} />
                </div>
              )

            return (
              <div className="h-[180px] relative overflow-hidden">
                <img src={o} alt="Gallery Image" className="w-full h-full object-cover" />
              </div>
            )
          })}
        </div>
      </Section>
    </Overlay.Content>
  )
}

const IMAGES = [
  'https://images.unsplash.com/photo-1416331108676-a22ccb276e35?auto=format&fit=crop&q=80&w=3872&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1528255671579-01b9e182ed1d?auto=format&fit=crop&q=80&w=4240&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1448697138198-9aa6d0d84bf4?auto=format&fit=crop&q=80&w=5070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1507086182422-97bd7ca2413b?auto=format&fit=crop&q=80&w=4928&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  'https://images.unsplash.com/photo-1532323544230-7191fd51bc1b?auto=format&fit=crop&q=80&w=3648&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  // 'https://images.unsplash.com/photo-1472224371017-08207f84aaae?auto=format&fit=crop&q=80&w=4896&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  '',
]

const STYLES = {
  tabs: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-0.25rem',
    padding: '0.5rem 1rem',
    background: COLORS.white,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    position: 'relative',
    zIndex: 10,
  },

  tab: {
    border: '1px solid rgba(6, 170, 241, 0.2)',
    padding: '0.4rem 0.8rem',
    borderRadius: 5,
    margin: '0.25rem',
    whiteSpace: 'nowrap',

    '&.is-active': {
      background: 'rgba(6, 170, 241, 0.2)',
    },

    '&::after': {
      content: '"none"',
      display: 'none',
    },
  },
}
