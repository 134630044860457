import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  processing: {
    label: 'Processing…',
    color: 'orange',
  },
  issue: {
    label: 'Issue',
    color: 'red',
  },
  processed: {
    label: 'Processed',
    color: 'green',
  },
}

const EVOBStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default EVOBStatus
