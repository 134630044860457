import React from 'react'

import { css, COLORS, SHADOW } from '../../../theme'

import Avatar from '../../../components/Avatar'
import DataList from '../../../components/DataList'

export const ProfileContactDetails = (props: any) => {
  const { name, description, avatar = '', phone, email, after } = props

  return (
    <div>
      <div className="px-4 pt-3 pb-2 flex items-center">
        <Avatar src="" initials={name} size={30} className="mr-2" />
        <div>
          <div className="text-text font-[600]">{name}</div>
          <div className="text-text-muted">{description}</div>
        </div>
      </div>

      <DataList isCompact labelWidth={120} withPadding>
        <DataList.Item label="Phone No." value={<a href="tel:1231-231-231">1231-231-231</a>} />
        <DataList.Item label="Email" value={<a href="mailto:contact@facility.com">contact@facility.com</a>} />
      </DataList>

      {after && <div className="px-4 pb-2 mt-2">{after}</div>}
    </div>
  )
}
