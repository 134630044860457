import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Card, Grid, Page } from '@behavehealth/components'

import { ArchivedFeeScheduleOverlay } from '@behavehealth/constructs/Insurance/ArchivedFeeScheduleOverlay'
import FeeSchedulesTable from '@behavehealth/components/Tables/FeeSchedulesTable'

const pageConfig = {
  title: 'Fee Schedules',
  feature: 'insurance',
}

const FeeSchedules = () => {
  return (
    <>
      <FeeSchedulesIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<ArchivedFeeScheduleOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const FeeSchedulesIndex: React.FC = () => {
  const { data, isLoading }: any = useGet({
    name: ['insurance-fee-schedules'],
    url: `/insurance_fee_schedules`,
  })

  return (
    <Page
      {...pageConfig}
      actions={
        <>
          <Button as={Link} label="Add Fee Schedule" type="primary" glyph="add" link={`new`} />
        </>
      }
    >
      <Grid gap="1rem">
        <Card>
          <FeeSchedulesTable
            mainLinkAs={NavLink}
            data={data}
            to={(id: string) => `${id}`}
            isLoading={isLoading}
            duplicateFormLinkProps={(duplicated) => ({
              as: Link,
              link: 'new',
              state: { data: duplicated },
            })}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(FeeSchedules, pageConfig))
