export const checkValid = (formData: any) => {
  let valid = true

  if (!formData?.selected_icd10) return false

  // check if at least one diagnosis is selected
  if (!Object.values(formData.selected_icd10).some((isSelected) => isSelected)) {
    return false
  }

  // check if each diagnosis selected has a group ID and problem number
  for (const icd10 in formData.selected_icd10) {
    const problemGroup = formData?.selected_diagnoses?.[icd10]
    const isSelected = formData.selected_icd10[icd10]

    if (!problemGroup || !isSelected) continue

    if (!problemGroup.problem_group_id || !problemGroup.problem_id) valid = false
  }

  return valid
}

export const icd10ToKey = (code: string) => code.replace('.', '_')
export const icd10ToCode = (code: string) => code.replace('_', '.')
export const keyToID = (id: string) => parseInt(id.replace('id_', ''))
