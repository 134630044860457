import React from 'react'

import { COLORS } from '../../theme'
import { PopoverMenu, PopoverMenuItem } from '../../components/PopoverMenu'
import { titleCase } from '../../utils/functions'

import Avatar from '../../components/Avatar'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'

import { AppsHeaderButton } from './AppsHeaderButton'

import useStore from '../../modules/store'

const BEHAVE_CATEGORGIES = {
  super_admin: 'Super Admin',
  dev: 'Dev',
  support: 'Support',
}

export const AppsHeaderUser = ({ isDesktop, settingsLinkTo }: any) => {
  const user = useStore((state: any) => state.user)
  const newUser = useStore((state: any) => state.newUser)

  if (!newUser || !user) {
    console.error(`[AppsHeaderUser] Missing user data`, { user, newUser })
    return null
  }

  if (isDesktop) {
    return (
      <PopoverMenu
        trigger={
          <div css={STYLES.trigger} className={isDesktop ? 'is-desktop' : 'is-mobile'}>
            <Avatar src={newUser.avatar} glyph="user_neutral" initials={newUser.name} size={24} />

            <div css={STYLES.triggerContent}>
              <div css={STYLES.triggerTitle}>
                {newUser.name} <Glyph glyph="triangle_down" size={9} />
              </div>

              {newUser.is_behave ? (
                <>{newUser.category && <div css={STYLES.triggerDescription}>{BEHAVE_CATEGORGIES[newUser.category]}</div>}</>
              ) : (
                <>{user.position && <div css={STYLES.triggerDescription}>{titleCase(user.position)}</div>}</>
              )}
            </div>
          </div>
        }
      >
        <MenuItems settingsLinkTo={settingsLinkTo} />
      </PopoverMenu>
    )
  }

  return (
    <PopoverMenu
      trigger={
        <AppsHeaderButton>
          <Avatar src={newUser.avatar} initials={newUser.name} size={24} />
        </AppsHeaderButton>
      }
    >
      <MenuItems settingsLinkTo={settingsLinkTo} />
    </PopoverMenu>
  )
}

const MenuItems = ({ settingsLinkTo }: any) => {
  const newUser = useStore((state: any) => state.newUser)

  return (
    <>
      <Flex nowrap centerY css={STYLES.user}>
        <Avatar src={newUser.avatar} glyph="user_neutral" initials={newUser.name} size="2rem" css={STYLES.avatar} />
        <div>
          <div css={STYLES.name}>{newUser.name || 'My Account'}</div>
          <div css={STYLES.username}>
            {newUser.email_verified ? newUser.email : newUser.phone_no_verified ? newUser.phone_no : newUser.email}
          </div>
        </div>
      </Flex>

      <Divider />

      {settingsLinkTo && <PopoverMenuItem label="Settings" icon="settings" to={settingsLinkTo} />}
      <PopoverMenuItem label="Log Out" icon="log_out" to="/logout" />
    </>
  )
}

const STYLES = {
  trigger: {
    display: 'flex',
    alignItems: 'center',

    '&.is-mobile': {
      padding: '0 0.5rem',
    },

    '&.is-desktop': {
      padding: '0 1rem',
    },
  },

  triggerContent: {
    marginLeft: '0.5rem',
  },

  triggerTitle: {
    fontSize: '0.94rem',
    fontWeight: 600,
    lineHeight: '1.2em',
    color: COLORS.blue,
  },

  triggerDescription: {
    fontSize: '0.9rem',
    fontWeight: 400,
    lineHeight: '1.2em',
    color: COLORS.textMuted,
  },

  user: {
    padding: '0.5rem',
  },

  avatar: {
    marginRight: '0.5rem',
  },

  name: {
    fontWeight: 600,
    lineHeight: 1,
  },

  username: {
    fontweight: 400,
    color: COLORS.textMuted,
    lineHeight: 1,
    marginTop: '0.32rem',
  },
}
