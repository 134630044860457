import React from 'react'
import size from 'lodash/size'

import State from '../../../components/State'
import GridTable from '../../../components/GridTable'
import { VariableKey } from './VariableKey'

export const SystemVariablesTable = (props: any) => {
  const { showDescription = true, title = 'Variables', variables, valueColumnWidth = '250px' } = props

  if (!variables) return null

  const isEmpty = size(variables) === 0

  if (isEmpty) {
    return (
      <State
        isEmpty
        minHeight={120}
        emptyDescription={
          <div>
            <div className="text-text text-[1.04rem] font-[600]">{title}</div>
            <div>No variables have been added yet</div>
          </div>
        }
        glyph="variables"
      />
    )
  }

  return (
    <GridTable
      testKey="signees_table"
      useBanding={false}
      templateColumns={`minmax(200px, 1fr) minmax(${valueColumnWidth}, 1fr) ${
        showDescription ? 'minmax(250px, 1fr)' : ''
      } minmax(280px, 1.5fr)`}
      className="text-[0.86rem]"
    >
      <GridTable.Header>
        <GridTable.Cell>Name</GridTable.Cell>
        <GridTable.Cell>Value</GridTable.Cell>
        {showDescription && <GridTable.Cell>Description</GridTable.Cell>}
        <GridTable.Cell>Key</GridTable.Cell>
      </GridTable.Header>

      {variables?.map?.((variable: any) => {
        const { variable_key, variable_value, description } = variable

        return (
          <GridTable.Row key={variable_key}>
            <GridTable.Cell centerY className="font-[600]">
              {variable.name}
            </GridTable.Cell>
            <GridTable.Cell>{variable_value || '–'}</GridTable.Cell>
            {showDescription && <GridTable.Cell>{description}</GridTable.Cell>}
            <GridTable.Cell centerY className="truncate">
              <VariableKey variable={variable_key} />
            </GridTable.Cell>
          </GridTable.Row>
        )
      })}
    </GridTable>
  )
}
