import hotkeys from 'hotkeys-js'
import { v4 as uuid } from 'uuid'
import clone from 'lodash/clone'

interface ScopeManager {
  scopeLayers: string[]
  add: Function
  remove: Function
  scopes: object
  instances: object
  onUpdate: Function
}

class HotkeyScopes implements ScopeManager {
  scopeLayers = ['global']
  scopes = {
    global: {},
  }
  instances = {}

  onUpdate = () => {}

  getUpperLayer = () => this.scopeLayers[this.scopeLayers.length - 1]

  getMergedScopes = () => {
    const upperLayer = this.getUpperLayer().split('.')[0]

    const result = this.scopeLayers.map((scopeName) => {
      const scope = clone(this.scopes[scopeName])

      Object.keys(scope).forEach((value) => {
        const keyConf = scope[value]
        let shouldHideScope = false

        if (keyConf.restrict) {
          if (keyConf.scopeName !== upperLayer) shouldHideScope = true
        }

        // To use this one instead of hide, we would need a way to update this
        // whenever a change occurs on the router. Now this be only executed
        // when a change occurs in the scope list (added or removed scopes).
        if (keyConf.preventScope) {
          if (keyConf.scopeName === upperLayer) shouldHideScope = true
        }

        if (keyConf.hide) {
          if (keyConf.hide(keyConf.instance)) shouldHideScope = true
        }

        if (shouldHideScope) delete scope[value]
      })

      return scope
    })

    return result.reduce(
      (mergedScopesAcc, currentScopeConfiguration) => ({
        ...mergedScopesAcc,
        ...currentScopeConfiguration,
      }),
      {},
    )
  }

  update = () => {
    hotkeys.deleteScope(this.currentScope)

    this.currentScope = this.scopeLayers.join('-')
    const mergedScopes = this.getMergedScopes()

    this.register(this.currentScope, mergedScopes)

    hotkeys.setScope(this.currentScope)

    this.onUpdate(mergedScopes)
  }

  add = (scopeName: string, scopeConfiguration, instance): string => {
    const newScope = `${scopeName}.${uuid()}`
    const hotkeys = scopeConfiguration.reduce(
      (resultAcc, hotkeyDef) => ({
        ...resultAcc,
        [hotkeyDef.key]: {
          ...hotkeyDef,
          scopeName: scopeName,
          instance: instance,
        },
      }),
      {},
    )

    this.scopeLayers.push(newScope)
    this.scopes[newScope] = hotkeys

    this.update()

    return newScope
  }

  remove = (scopeName: string) => {
    if (this.scopeLayers.length > 1) {
      this.scopeLayers.splice(this.scopeLayers.indexOf(scopeName), 1)
      delete this.scopes[scopeName]

      this.update()
    }
  }

  register = (scopeName, scopeConfiguration) => {
    const keys = Object.keys(scopeConfiguration)

    for (let i = 0, key, hotkeyDef; i < keys.length; i++) {
      key = keys[i]
      hotkeyDef = scopeConfiguration[key]

      hotkeys(hotkeyDef.key, scopeName, (event, handler) => {
        event.preventDefault()
        hotkeyDef.action(hotkeyDef.instance, event, handler)
      })
    }
  }
}

export default new HotkeyScopes()
