import React from 'react'

import FormSection from '../../../../Forms/FormSection'
import ContextShow from '../../../../Forms/ContextShow'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'

const AdvanceDirectivePOA = () => {
  return (
    <FormSection layout="vertical">
      <RadioGroup label="Advance Directive" model="data.advance_directive_poa.advance_directive" layout="vertical-dense">
        <Radio label="Has Living Will" value="has_living_will" />
        <Radio label="No Advance Directive" value="no_advance_directive" />
        <Radio label="Requests assistance with Advance Directive" value="requests_assistance" />
        <Radio label="Declines assistance with Advance Directive" value="declines_assistance" />
      </RadioGroup>

      <ContextShow when="data.advance_directive_poa.advance_directive" is="has_living_will">
        <RadioGroup
          label="Confirm copy of living will is in the chart"
          model="data.advance_directive_poa.have_copy_of_living_will"
          layout="vertical-dense"
        >
          <Radio label="Yes, copy of living will is in the chart" value={true} />
          <Radio label="No, copy of living will is NOT in the chart" value={false} />
        </RadioGroup>
      </ContextShow>

      <RadioGroup label="Does the client have a Medical Power of Attorney?" model="data.advance_directive_poa.medical_poa" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <RadioGroup
        label="Does the client have a Mental Health Power of Attorney?"
        model="data.advance_directive_poa.mental_poa"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>
    </FormSection>
  )
}

export default AdvanceDirectivePOA
