import React from 'react'
import { keyframes } from '@emotion/react'
import { Manager, Reference } from 'react-popper'
import { tint, transparentize } from 'polished'
import classNames from 'classnames'
import { Placement } from '@popperjs/core'

import { Glyph as GlyphType } from '../declarations/types'

import Glyph from './Glyph'
import Flex from './Flex'
import SmartPortal from './SmartPortal'

const pulseAnimation = (color) => {
  if (!color) return

  const phase1 = transparentize(0.3, color)
  const phase2 = transparentize(1, color)

  const animation = keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${phase1};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 5px ${phase2};
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${phase2};
    }
  `

  return { '&.animated': { animation: `${animation} 2s infinite` } }
}

type Props = {
  show?: boolean
  glyph?: GlyphType
  content?: React.ReactNode
  customContent?: React.ReactNode
  position?: Placement
  color?: string
  animate?: boolean
  css?: object
  tooltipCss?: any
}

const Tooltip: React.FC<Props> = ({
  show = true,
  glyph = 'info',
  animate = false,
  children,
  content,
  customContent,
  position = 'bottom',
  css,
  color,
  tooltipCss,
  portal = 'tooltip',
  glyphSize = 16,
  glyphColor,
  fallbackPositions,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const open = () => {
    if (!show) return
    setIsOpen(true)
  }

  const close = () => {
    if (!show) return
    setIsOpen(false)
  }

  if (!show) return children

  const classes = classNames({
    'no-children': !children,
    animated: animate,
  })

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div
            data-test="tooltip_trigger"
            ref={ref}
            onClick={open}
            onMouseOver={open}
            onMouseLeave={close}
            onTouchStartCapture={open}
            css={styles.trigger}
            {...rest}
          >
            {children || (
              <Flex
                centerX
                centerY
                className={classes}
                css={[{ display: 'inline-flex', width: 16, height: 16, willChange: 'transform' }, pulseAnimation(color)]}
              >
                <Glyph size={glyphSize} color={glyphColor || color} glyph={glyph} css={styles.icon} />
              </Flex>
            )}

            {isOpen && (
              <SmartPortal portal={portal} position={position} fallbackPositions={fallbackPositions}>
                {content && (
                  <div css={styles.tooltipWrapper} onClick={close} onTouchStart={close}>
                    <div css={{ ...styles.tooltip, ...tooltipCss }} style={{ background: color && tint(0.9, color) }}>
                      {content}
                    </div>
                  </div>
                )}

                {customContent}
              </SmartPortal>
            )}
          </div>
        )}
      </Reference>
    </Manager>
  )
}

const tooltipAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8) translateY(-8px);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translateY(0);
  }
`

const styles: { trigger: any; tooltipWrapper: any; icon: any; tooltip: any } = {
  trigger: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    transition: 'all 120ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    verticalAlign: 'bottom',

    '&:hover': {
      svg: {
        opacity: 0.9,
        transition: 'opacity 200ms cubic-bezier(0.39, 0.575, 0.565, 1)',
        transform: 'scale(1.1)',
      },
    },

    '&:active': {
      // transform: 'scale(0.99)',
      transition: 'all 20ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    },

    '.animated': { borderRadius: '50%' },

    '&.no-children::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 44,
      height: 44,
      transform: 'translate3d(-50%, -50%, 0)',
    },
  },

  icon: {
    opacity: 0.85,
    transition: 'all 200ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  tooltipWrapper: {
    position: 'relative',
    zIndex: 100,
    margin: '0.5rem 1rem',

    // pointerEvents: 'none',

    transformOrigin: 'center top',
    animation: `${tooltipAnimation} 160ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards`,
  },

  tooltip: {
    width: 'max-content',
    maxWidth: 1200,
    maxHeight: 400,
    overflowY: 'auto',
    overflowX: 'hidden',
    whiteSpace: 'pre-wrap',

    padding: '0.5em 0.75em',

    borderRadius: 7,
    background: '#EBF9FF',
    boxShadow: `
      0 1px 2px rgba(52, 73, 129, 0.16),
      0 4px 8px rgba(52, 73, 129, 0.1),
      0 8px 12px rgba(52, 73, 129, 0.06),
      0 16px 24px rgba(52, 73, 129, 0.02)
    `,
  },
}

export default Tooltip
