import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'

import withPermissions from '../hocs/withPermissions'
import { COLORS } from '../theme'
import { Color } from '../declarations/types/Color'

import Button from './Button'

type Props = {
  label: string
  color: Color
  isActive: boolean
} & React.HTMLAttributes<any>

const DropdownItem: React.FC<Props> = ({ label, color, className, isActive, testKey, ...rest }) => {
  const accentColor = color in COLORS ? COLORS[color] : COLORS.gray

  const classNames = clsx({
    'is-active': isActive,
    [className]: className,
  })

  return (
    <Button
      testKey={testKey || 'dropdown_item'}
      glyphSize={20}
      justifyContent="flex-start"
      borderRadius="0"
      css={styles(accentColor)}
      label={label}
      type={false}
      className={classNames}
      {...rest}
    />
  )
}

export const styles = (accentColor: string) => ({
  borderRadius: '0',
  minHeight: 42,

  justifyContent: 'flex-start',
  transform: 'none !important',

  color: COLORS.text,
  fontWeight: '500 !important',

  '&:hover': {
    color: '#111',
    cursor: 'pointer',
    background: tint(0.95, accentColor),
    transform: 'translateY(-1px)',
  },

  '&.is-active': {
    background: tint(0.9, COLORS.blue),
    boxShadow: `inset 2px 0 0 ${tint(0.2, COLORS.blue)}`,
  },

  svg: {
    fill: accentColor,
  },
})

export default withPermissions(DropdownItem)
