import React from 'react'
import Status from './Status'
import size from 'lodash/size'
import { keyframes } from '@emotion/react'
import { transparentize, darken, desaturate } from 'polished'

import { COLORS, ICONS } from '../theme'
import Card from './Card'
import CardContent from './CardContent'
import Flex from './Flex'
import Grid from './Grid'
import Header from '../components/Header'

import HotkeyScopes from '../modules/hotkey_scopes'
import hotkeys from 'hotkeys-js'

export class Cheatsheet extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hotkeys: HotkeyScopes.getMergedScopes(),
      $visible: false,
    }

    HotkeyScopes.onUpdate = this.onHotkeysUpdated

    hotkeys('shift+/', { keyup: true }, this.handleToggleCheatsheet)
  }

  handleToggleCheatsheet = (event) => {
    event.preventDefault()

    switch (event.type) {
      case 'keyup':
        this.setState({ $visible: false })
        break
      case 'keydown': {
        this.setState({ $visible: true })
        break
      }
    }
  }

  onHotkeysUpdated = (hotkeys) => {
    this.setState({ hotkeys })
  }

  render = () => {
    const { hotkeys, $visible } = this.state
    const thereAreHotkeys = size(hotkeys) > 0

    return (
      $visible &&
      thereAreHotkeys && (
        <Flex alignItems="flex-end" justifyContent="center" css={styles.container}>
          <Card css={styles.base}>
            <Header
              title="Hotkeys"
              description="Here's what you can do using your keyboard"
              icon={ICONS.keyboard}
              className="text-blue-500"
            />
            <CardContent className="py-[10px] px-[22px]">
              <Grid gap={5}>
                {Object.keys(hotkeys).map((value) => (
                  <Flex justifyContent="space-between" css={styles.hotkey}>
                    <div css={styles.innerText}>{hotkeys[value]?.description}</div>
                    <Status label={hotkeys[value].key} css={styles.status} />
                  </Flex>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Flex>
      )
    )
  }
}

const animation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`
const backdropAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const styles = {
  base: {
    position: 'relative',
    minWidth: 300,
    maxWidth: 400,
    fontSize: '.85rem',
    animation: `${animation} 150ms cubic-bezier(0.05, 0.49, 0.35, 0.95) forwards`,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    '& h1': {
      color: COLORS.blue,
    },
  },
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 100,
    pointerEvents: 'none',
    background: `linear-gradient(0deg, 
    ${darken(0.5, transparentize(0.7, desaturate(0.85, COLORS.blue)))} 0%,
      ${COLORS.transparent} 200px
    )`,
    animation: `${backdropAnimation} 250ms cubic-bezier(0.26, 0.28, 0.61, 0.62) forwards`,
  },
  status: {
    marginLeft: '1rem',
    minWidth: 32,
    justifyContent: 'center',
    fontSize: 'inherit',
  },
  hotkey: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  innerText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 250,
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
}

export default Cheatsheet
