import React from 'react'
import { connect } from 'react-redux'

import { withOverlayError } from '../../../hocs/withOverlayError'

import { Text } from '../../Typography'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ContextHide from '../../Forms/ContextHide'
import DateInput from '../../Forms/DateInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Grid from '../../Grid'
import Input from '../../Forms/Input'
import LevelOfCareSelect from '../../Forms/LevelOfCareSelect'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import PhoneInput from '../../Forms/PhoneInput'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'
import TimeInputSelector from '../../Forms/TimeInputSelector/TimeInputSelector'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'

class Authorization extends OverlayBase {
  renderHeader = () => <Overlay.Header icon="authorizations" title={this.state.$new ? 'Add Authorization' : 'Authorization'} />

  renderContent = () => {
    const { $editable, $new } = this.state
    const { reference, record, timezone } = this.props

    const isBehave = this.props?.user?.type === 'bh_employee'

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={record}
          timezone={timezone}
          isEditable={$editable}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
          linked={
            $new && {
              admission_id: reference?.current_admission?.id,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              {!$new && <ObjectSelector isEditable={false} model="author" label="Author" type="employees" icon="employees" />}

              <Grid columns="1fr 1fr" gap={16}>
                <CheckboxGroup label="Is Auth Required" trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox label="Change to Yes / No" model="not_required" />
                </CheckboxGroup>

                <ContextHide when="not_required" is={true}>
                  <Input model="identifier" label="Authorization #" />
                </ContextHide>
              </Grid>

              <LevelOfCareSelect label="Level of Care" model="level" />

              <Grid columns="1fr 1fr" gap={16}>
                <DateInput label="From" model="started_at" />
                <DateInput label="Until" model="ended_at" />
              </Grid>

              <CheckboxGroup
                label="Notifications"
                trueIcon="check"
                falseIcon="cross"
                falseStyle="none"
                tooltip={
                  <Text muted={false}>
                    If enabled, the <strong>Primary Therapist</strong> from <strong>Connections</strong> receive automated reminders to
                    complete the Progress Review required for the next Authorization
                  </Text>
                }
              >
                <Checkbox defaultChecked label="Send Progress Review Reminders" model="send_reminders" />
              </CheckboxGroup>

              <Flex stretchChildrenX gap={16}>
                <Input model="required_daily_hours" label="Daily Hours Required" type="number" min="0" max="24" suffix="hours" />
                <Input model="required_weekly_hours" label="Weekly Hours Required" type="number" min="0" max="168" suffix="hours" />
              </Flex>

              <Flex stretchChildrenX gap={16}>
                <Input label="Sessions Authorized" model="sessions" />
                <Input label="Sessions Used" model="used_sessions" />
                {!$new && <Input isEditable={false} label="Sessions Billed" model="billed_sessions" />}
              </Flex>

              <RadioGroup label="UR Communication Method" layout="vertical-dense" model="ur_communication_method">
                <Radio label="Phone Call" value="phone_call" />
                <Radio label="Faxing Clinical" value="faxing_clinical" />
                <Radio label="Web Portal" value="web_portal" />
              </RadioGroup>

              <Divider />

              <FormSection heading="Care Manager">
                <Input label="Care Manager Contact" model="care_manager_name" />

                <Flex gap={16}>
                  <PhoneInput label="Care Manager Phone Number" model="care_manager_phone_no" />
                  <Input glyph="phone_ext" label="Phone No Extension" size={10} model="care_manager_phone_ext" />
                </Flex>

                <PhoneInput label="Care Manager Fax Number" model="care_manager_fax_no" />
              </FormSection>

              <Divider />

              <FormSection heading="Peer to Peer Contact">
                <Input label="Peer to Peer Contact" model="peer_to_peer_name" />

                <Flex gap={16}>
                  <PhoneInput label="Peer to Peer Phone Number" model="peer_to_peer_phone_no" />
                  <Input glyph="phone_ext" label="Phone No Extension" size={10} model="peer_to_peer_phone_ext" />
                </Flex>

                <PhoneInput label="Peer to Peer Fax Number" model="peer_to_peer_fax_no" />
              </FormSection>

              <Divider />

              <Input label="Pre-cert Number" model="pre_cert_no" />

              <TimeInputSelector label="UR Activity Start Time" model="ur_activity_started_at" />
              <TimeInputSelector label="UR Activity End Time" model="ur_activity_ended_at" />

              {isBehave && <Textarea label="Internal Notes" model="internal_notes" />}
              <Textarea label="Customer Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    const isBehave = this.props?.user?.type === 'bh_employee'

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission={isBehave ? true : $new ? 'insurance_authorizations.create' : 'insurance_authorizations.edit'}
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Authorization"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission={isBehave || 'insurance_authorizations.edit'}
            />

            <DeleteDialog
              title="Delete Authorization?"
              message="Are you sure you want to delete this authorization? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                permission={isBehave || 'insurance_authorizations.delete'}
                isLoading={this.props.loading}
                fullWidth
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'insurance_authorizations')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_authorizations')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(Authorization))
