import React from 'react'

import ContextShow from '../ContextShow'
import Input from '../Input'
import Option from '../Option'
import Select from '../Select'

const RelationshipSelect = ({ label, model, defaultValue, isRequired }) => (
  <>
    <Select
      allowEmpty
      label={label}
      model={model}
      defaultValue={defaultValue}
      validations={
        isRequired && {
          presence: {
            message: 'Please select a relationship',
          },
        }
      }
    >
      <option disabled>— Friends & Family</option>
      <Option label="Friend" value="friend" />

      <Option label="Mother" value="mother" />
      <Option label="Father" value="father" />
      <Option label="Brother" value="brother" />
      <Option label="Sister" value="sister" />
      <Option label="Child" value="child" />
      <Option label="Grandfather" value="grandfather" />
      <Option label="Grandmother" value="grandmother" />
      <Option label="Aunt" value="aunt" />
      <Option label="Uncle" value="uncle" />
      <Option label="Niece" value="niece" />
      <Option label="Nephew" value="nephew" />
      <Option label="Cousin" value="cousin" />
      <Option label="Relative" value="relative" />

      <option disabled></option>
      <option disabled>— Care Team</option>
      <Option label="Sponsor" value="sponsor" />
      <Option label="Case Manager" value="case_manager" />
      <Option label="Recovery Coach" value="recovery_coach" />
      <Option label="Medical Doctor (PCP)" value="medical_doctor" />
      <Option label="Medication-Assisted-Treatment Doctor (MAT)" value="mat_doctor" />
      <Option label="Psychiatrist" value="psychiatrist" />
      <Option label="Psychologist" value="psychologist" />
      <Option label="Therapist" value="therapist" />
      <Option label="Nutritionist" value="nutritionist" />

      <option disabled></option>
      <option disabled>— Employment</option>
      <Option label="Employer" value="employer" />
      <Option label="Employee-Assistance-Program Contact" value="eap_contact" />
      <Option label="Union Representative" value="union_representative" />

      <option disabled></option>
      <option disabled>— Government Officials</option>
      <Option label="Probation Officer" value="probation_officer" />
      <Option label="Parole Officer" value="parole_officer" />
      <Option label="House Arrest Contact" value="house_arrest_contact" />
      <Option label="Drug Court Contact" value="drug_court_contact" />
      <Option label="Social Services Case Worker" value="social_services_case_worker" />
      <Option label="Domestic Violence Case Worker" value="domestic_violence_case_worker" />

      <option disabled></option>
      <option disabled>— Legal</option>
      <Option label="Attorney" value="attorney" />
      <Option label="Legal Contact" value="legal_contact" />

      <option disabled></option>
      <option disabled>— Other</option>
      <Option label="Other" value="other" />
    </Select>

    <ContextShow when={model} is="other">
      <Input
        label="Other Relationship"
        model={`${model}_other`}
        validations={
          isRequired && {
            presence: {
              message: 'Please enter a relationship',
            },
          }
        }
      />
    </ContextShow>
  </>
)

RelationshipSelect.defaultProps = {
  label: 'Relationship',
  model: 'relationship',
}

export default RelationshipSelect
