import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../..//theme'
import withPermissions from '../../hocs/withPermissions'

import Checkbox from '../Forms/Checkbox'
import CheckboxGroup from '../Forms/CheckboxGroup'
import Icon from '../Icon'
import Status from '../Status'
import Tooltip from '../Tooltip'

import PermissionCheckbox from './PermissionCheckbox'

const PermissionSubRow = ({ actions, title, icon, models, isCustom, warningTooltip, trueValue, falseValue }: any) => (
  <div css={styles.root}>
    <header css={styles.header} className="header">
      {icon && <Icon icon={icon} className="!ml-3" size={18} />}
      <div>
        <h3 css={styles.title}>- {title}</h3>
        {isCustom && <Status label="Custom" color="blue" className="mt-1 mr-0 mb-0 ml-3" />}
      </div>

      {warningTooltip && (
        <div className="ml-auto px-4">
          <Tooltip glyph="warning" content={warningTooltip} color={COLORS.orange} />
        </div>
      )}
    </header>

    <div css={styles.columns} className="columns">
      {models?.view && (
        <>
          <PermissionCheckbox
            label="View"
            model={models.view}
            className="col-start-1 col-span-1"
            true_value={trueValue}
            false_value={falseValue}
          />

          <PermissionCheckbox label="Create" model={models.create} className="col-start-2 col-span-1" />
          <PermissionCheckbox
            label="Edit"
            model={models.edit}
            className="col-start-3 col-span-1"
            true_value={trueValue}
            false_value={falseValue}
          />
          <PermissionCheckbox
            label="Delete"
            model={models.delete}
            className="col-start-4 col-span-1"
            true_value={trueValue}
            false_value={falseValue}
          />
        </>
      )}

      {actions && (
        <CheckboxGroup layout="vertical-dense" falseStyle="faded-linethrough" className="col-start-1 col-end-4">
          {actions.map((action: any) => (
            <Checkbox
              key={action.model}
              label={action.label}
              model={action.model}
              description={action.description}
              isDisabled={action.isDisabled}
              className="flex"
              {...(action.isDisabled && { value: false })}
            />
          ))}
        </CheckboxGroup>
      )}
    </div>
  </div>
)

const styles = {
  root: {
    display: 'flex',
    alignItems: 'stretch',
    fontSize: '1rem',

    '&:hover > *': {
      background: tint(0.25, COLORS.lightBackground),
    },

    '&:first-of-type > *': {
      border: 'none !important',
    },
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 270px',
    padding: '0.75rem 0',

    background: COLORS.white,
    borderTop: `1px dashed ${COLORS.divider}`,

    '@media (min-width: 700px)': {
      position: 'sticky',
      left: 0,
      zIndex: 1,
      boxShadow: `
        1px 0 0 ${COLORS.divider},
        3px 0 0 ${COLORS.shadow}
      `,
    },
  },

  title: {
    fontSize: '1rem',
    fontWeight: 400,
    margin: 0,
    lineHeight: 1,
    paddingLeft: '0.75rem',
  },

  columns: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '100px 100px 100px 100px',
    gridGap: '1rem',
    flex: '1 1 750px',

    fontSize: '0.95rem',
    padding: '0.5rem 1rem',
    borderTop: `1px dashed ${COLORS.divider}`,

    zIndex: 0,
  },
}

PermissionSubRow.defaultProps = {
  trueValue: true,
  falseValue: false,
}

export default withPermissions(PermissionSubRow)
