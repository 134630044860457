import React from 'react'

import { NotionBrowser } from '../../constructs/Notion'

import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

const RCM_NOTION_ID = 'a9e8ec0829c74488833052bbab2184b4'

const RCMHelpCenterView: React.FC<any> = () => {
  return (
    <>
      <AppSidebarView>
        <AppSidebarHeader title="RCM Help Center" icon="insurance" />

        <NotionBrowser
          homeTitle={'RCM Help Center'}
          homeID={RCM_NOTION_ID}
          css={styles.notionBrowser}
          feedbackCategory="rcm_help_article"
        />
      </AppSidebarView>
    </>
  )
}

const styles = {
  subHeader: {
    padding: '0.5rem',
  },

  notionBrowser: {
    padding: '1rem 1rem 0',
  },
}

export default RCMHelpCenterView
