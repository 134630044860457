import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { getVersionSlug } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { ClinicalAssessmentsReportDataTable } from '@behavehealth/constructs/LiveReports/ClinicalAssessmentsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ASSESSMENTS = {
  asam_criteria: 'asam-criteria',
  biopsychosocial: 'biopsychosocial-assessment',
  mental_status_exam: 'mental-status-exam',
  prescreen: 'pre-screen',
  psychiatric_assessment: 'psychiatric-assessment',
  suicide_assessment: 'suicide-assessment',
  eating_disorder_bps: 'eating-disorder-bps',
  mental_health_prescreen: 'mental-health-pre-screen',
}

const ClinicalAssessments = () => {
  const tableProps = useDataTable({
    name: ['reports', 'clinical-assessments'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_clinical_assessments_v1',
    params: { category: 'clinical_assessments' },
  })

  const to = React.useMemo(
    () => (rowData: any) => {
      const formattedVersion = !rowData.version || rowData.version === '1.0' ? '' : `-${getVersionSlug(rowData.version)}`

      return `${getResourceLink(rowData.client)}/clinical-assessments/${ASSESSMENTS[rowData.subcategory]}/${rowData.id}${formattedVersion}`
    },
    [],
  )

  return (
    <Page feature="clinical_assessments" title="Live Clinical Assessments Report">
      <Grid>
        <ClinicalAssessmentsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ClinicalAssessments)
