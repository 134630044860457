import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'

import { RecordTooltip } from '../components/RecordTooltip'
import { TodoInfo } from '../../StaffJourney/records/TodoInfo'

export const Todo = ({ data, client }: any) => {
  if (!data) return null

  return (
    <RecordTooltip
      title={data.title}
      icon="tasks"
      color={tint(0.7, COLORS.green)}
      startDate={data.started_at}
      recordLink={`/todo/active/${data.id}`}
      client={client}
    >
      <TodoInfo data={data} />
    </RecordTooltip>
  )
}
