import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, CardContent, Grid, Page } from '@behavehealth/components'
import FeeSchedulesTable from '@behavehealth/components/Tables/FeeSchedulesTable'

import useAPI from '@behavehealth/hooks/useAPI'

const pageConfig = {
  feature: 'fee_schedules',
}

const FeeSchedules: React.FC = () => {
  const { url } = useRouteMatch()
  const { resource_id }: any = useParams()

  const loading = useSelector((state) => state.data.fee_schedules?.loading)
  const codes = useSelector((state) => state.data.fee_schedules?.data)

  useAPI('fee_schedules', `/insurance_local_payers/${resource_id}/insurance_fee_schedules`)

  return (
    <Page {...pageConfig} actions={<Button label="Add Fee Schedule" type="primary" glyph="add" link={`${url}/new`} />}>
      <Grid gap="1rem">
        <Card>
          <CardContent padding="0">
            <FeeSchedulesTable data={DATA} to={(id: string) => `${url}/${id}`} isLoading={loading} />
          </CardContent>
        </Card>
      </Grid>
    </Page>
  )
}

const DATA = [
  {
    id: 'a18ea3c5c',
    name: 'Fee Schedule 1',
    updated_at: '2022-01-19T11:39:53.895-05:00',
    created_at: '2022-01-19T11:39:53.895-05:00',
    author: {
      name: 'John Doe',
      avatar: '',
    },
    services: [
      {
        service: {
          name: 'Service 1',
        },
        insurance_code: {
          name: 'Code 1',
          code: '100',
          code_type: 'cpt',
        },
        price: '100',
      },
    ],
  },
  {
    id: 'a141cfa79',
    name: 'Fee Schedule 2',
    updated_at: '2022-01-19T11:39:53.895-05:00',
    created_at: '2022-01-19T11:39:53.895-05:00',
    author: {
      name: 'John Doe',
      avatar: '',
    },
    services: [
      {
        service: {
          name: 'Service 1',
        },
        insurance_code: {
          name: 'Code 1',
          code: '100',
          code_type: 'cpt',
        },
        price: '100',
      },
    ],
  },
  {
    id: 'a9096a3b2',
    name: 'Fee Schedule 3',
    updated_at: '2022-01-19T11:39:53.895-05:00',
    created_at: '2022-01-19T11:39:53.895-05:00',
    author: {
      name: 'John Doe',
      avatar: '',
    },
    services: [
      {
        service: {
          name: 'Service 1',
        },
        insurance_code: {
          name: 'Code 1',
          code: '100',
          code_type: 'cpt',
        },
        price: '100',
      },
    ],
  },
]

export default withMarketing(FeeSchedules, pageConfig)
