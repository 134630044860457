import React from 'react'

import { COLORS } from '../theme'
import Card from './Card'

export const SectionCard = ({ title, children, className, aside }: any) => {
  return (
    <Card css={STYLES.root} className={className}>
      {(title || aside) && (
        <header css={STYLES.header}>
          {title && <SectionCardTitle title={title} />}
          {aside}
        </header>
      )}

      {children}
    </Card>
  )
}

export const SectionCardTitle = ({ title, children, className }: any) => {
  return (
    <div css={STYLES.title} className={className}>
      {title}
    </div>
  )
}

const STYLES = {
  root: {
    background: 'white',
    overflow: 'hidden',
    padding: '0.25rem 0.5rem 0.4rem',
    boxShadow: 'none',
    border: `1px solid ${COLORS.divider}`,
    flex: '1 1 320px',
  },

  title: {
    fontSize: '0.84rem',
    fontWeight: 700,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: '0.5rem',
    color: COLORS.textMuted,
    opacity: 0.88,
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}
