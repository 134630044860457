import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5-compat'
import produce from 'immer'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { useSettings } from '../../hooks/useSettings'

import ApplicationFormOverlayExample from '../../components/ApplicationForm/ApplicationFormOverlayExample'
import Button from '../../components/Button'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import { APP_URLS } from '../MyAccount/utils'
import { DEFAULT_FILTERS } from '../Filters/constants'

export const ApplicationFormsDataTable = (props: any) => {
  const { tenant } = useSettings()

  const [preview, setPreview]: any = React.useState(null)

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 280,
        disableHide: true,
        disableSelect: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            as={NavLink}
            id={data.id}
            value={
              <span className="flex items-center flex-nowrap">
                <span className="truncate min-w-0 mr-1.5">{value}</span>
                {data.is_default && <Status small color="blue" label="Default" />}
              </span>
            }
            to={data.id}
          />
        ),
      },
      {
        title: 'Preview',
        model: 'schema',
        disableSort: true,
        hoverExpand: false,
        formatValue: ({ data }: any) => (
          <>
            <Button label="Preview" glyph="view" size={100} type="default" onClick={() => setPreview(data)} />
          </>
        ),
      },
      {
        title: 'Public Link',
        model: 'slug',
        type: 'link',
        formatValue: ({ value, data }: any) => {
          if (!value) return null

          const link = `${APP_URLS.ehr}/${tenant.id}/forms/${value}`

          return <LinkCell isExternal value="Open Form" href={link} />
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Actions',
        id: 'duplicate',
        width: 130,
        disableSort: true,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          const duplicated = produce(data, (draft: any) => {})

          return <Button as={Link} label="Duplicate" glyph="add" size={100} type="default" link="new" state={{ data: duplicated }} />
        },
      },
    ]
  }, [tenant])

  return (
    <>
      <DataTable asCard title="Application Forms" icon="applications" columns={columns} {...props} filtersConfig={FILTERS_CONFIG} />

      <SummonOverlay
        isOpen={!!preview}
        onClose={() => setPreview(null)}
        overlay={
          <ApplicationFormOverlayExample
            schema={preview?.schema}
            logo={tenant?.avatar}
            companyName={tenant?.name}
            firstName={tenant?.first_name}
            welcomeMessage={preview?.welcome_message}
            thankYouMessage={preview?.thank_you_message}
          />
        }
      />
    </>
  )
}

const FILTERS_CONFIG = {
  name: {
    title: 'Name',
    type: 'string',
  },
  author: DEFAULT_FILTERS.author,
  created_at: {
    label: 'Date Added',
    type: 'date_time',
  },
  updated_at: {
    label: 'Date Updated',
    type: 'date_time',
  },
}
