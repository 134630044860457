import React from 'react'
import { useParams } from 'react-router-dom'

import { isDefined } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import PageHeader from '@behavehealth/components/PageHeader'
import Card from '@behavehealth/components/Card'

export const Website = () => {
  const { slug }: any = useParams()

  const queryKey = ['community-entity', slug]

  const { data, isLoading, isRefetching, isError } = useGet({
    name: queryKey,
    url: `/community/entities/${slug}`,
    options: { enabled: isDefined(slug) },
  })

  return (
    <div css={STYLES.root}>
      <PageHeader icon="web_form" title="Website" />

      <div css={STYLES.content}>
        {data?.url && (
          <Card>
            <iframe
              title="iframe"
              src={data?.url}
              frameBorder="0"
              border="0"
              cellSpacing="0"
              className="border-none w-full h-full min-h-[80vh]"
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
            />
          </Card>
        )}
      </div>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    overflow: 'hidden',
  },

  content: {
    padding: '1rem',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
  },
}
