import { createStore, applyMiddleware, compose } from 'redux'
import { v4 as uuid } from 'uuid'
import get from 'lodash/get'
import { routerMiddleware } from 'connected-react-router'
import * as Sentry from '@sentry/react'
import { handleRequests, clearRequestsCache } from '@redux-requests/core'
import { createDriver } from '@redux-requests/axios'

import { isPast } from '../utils/functions'
import createReducer from './reducers'
import { request, startRequestInterceptors, refreshToken } from '../modules/axios'
import { addNotification } from '../actions/common'

// Build the middleware for intercepting and dispatching navigation actions
const sentryReduxEnhancer = Sentry.createReduxEnhancer()

let refreshingToken = false

const onRequest = (request: any, action: any, store: any) => {
  if (action.type.includes('api/UPDATE_') || action.type.includes('api/CREATE_') || action.type.includes('api/DELETE_')) {
    // clear out all cache in case of update/create/delete
    store.dispatch(clearRequestsCache())
  }

  return request
}

const onError = async (error: any, action: any, store: any) => {
  const tokenExpiresOn = localStorage.getItem('bh.ea')
  if (isPast(tokenExpiresOn) && !refreshingToken) {
    refreshingToken = true

    // token expired, try to refresh it
    const resp = await refreshToken()
    if (resp) {
      request.defaults.headers.common['Authorization'] = `Bearer ${resp.data?.access_token}`
    }

    refreshingToken = false

    const newResponse = await store.dispatch({
      ...action,
      request: {
        ...action.request,
        data: {
          ...action.request.data,
        },
      },
    })

    if (newResponse.data) {
      return { data: newResponse.data }
    }
  }

  // skip if not sending notification
  if (!action.meta.notify) return { error }

  // in case of a known error
  if (get(error, 'data.type') === 'unauthorized_error') {
    store.dispatch(
      addNotification({
        id: uuid(),
        description: error.data.errors.join('. '),
        type: 'negative',
      }),
    )
  } else if (get(error, 'data.type') === 'object_error') {
    store.dispatch(
      addNotification({
        id: uuid(),
        description: error.data.validations[0].errors[0],
        type: 'negative',
      }),
    )
  } else if (get(error, 'data.errors')) {
    store.dispatch(
      addNotification({
        id: uuid(),
        description: error.data.errors.join('. '),
        type: 'negative',
      }),
    )
  } else {
    // cover unknown errors
    store.dispatch(
      addNotification({
        id: uuid(),
        description: "Something didn't go as planned. We made a note of this error and we'll look into it soon.",
        type: 'negative',
      }),
    )
  }

  // not related token error, we pass it like nothing happened
  return { error }
}

const onSuccess = (response: any, action: any, store: any) => {
  // skip if not sending notification
  if (!action.meta.notify) return response

  if (action.type.includes('api/UPDATE_')) {
    store.dispatch(
      addNotification({
        id: uuid(),
        description: 'Data updated successfully',
        type: 'positive',
      }),
    )
  } else if (action.type.includes('api/CREATE_')) {
    store.dispatch(
      addNotification({
        id: uuid(),
        description: 'Data created successfully',
        type: 'positive',
      }),
    )
  } else if (action.type.includes('api/DELETE_')) {
    store.dispatch(
      addNotification({
        id: uuid(),
        description: 'Data deleted successfully',
        type: 'positive',
      }),
    )
  }

  return response
}

const storeConfig = (history: any, useRequestInterceptios = 'true') => {
  const { requestsReducer, requestsMiddleware } = handleRequests({
    driver: createDriver(request),
    onRequest: onRequest,
    onSuccess: onSuccess,
    onError: onError,
  })

  const middlewares = [...requestsMiddleware, routerMiddleware(history)]
  const reducers = createReducer(history, { requests: requestsReducer })
  const enhancers = [applyMiddleware(...middlewares), sentryReduxEnhancer]

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
        })
      : compose
  /* eslint-enable */

  const store = createStore(reducers, {}, composeEnhancers(...enhancers))

  // Extensions
  if (useRequestInterceptios === 'true') startRequestInterceptors(store, history)

  return store
}

export const getAllAction = (name) => `api/GET_ALL_${name.toUpperCase()}`
export const getAction = (name) => `api/GET_${name.toUpperCase()}`
export const createAction = (name) => `api/CREATE_${name.toUpperCase()}`
export const updateAction = (name) => `api/UPDATE_${name.toUpperCase()}`
export const destroyAction = (name) => `api/DELETE_${name.toUpperCase()}`

export default storeConfig
