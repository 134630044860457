import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { COLORS, SHADOW } from '../../theme'

import Alert from '../Alert'
import Glyph from '../Glyph'
import Overlay from '../Overlay'
import RadioCheckElement from '../Forms/RadioCheckElement'
import Card from '../Card'

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const [removed] = list.splice(startIndex, 1)
  list.splice(endIndex, 0, removed)

  return list.map((o: any) => o.id)
}

const TableOptionsOverlay = (props: any) => {
  const { columns, hiddenColumns, close, onColumnReorder, onClose } = props
  const [ref, setRef] = React.useState(null)

  React.useEffect(() => {
    if (!ref) return

    const handleOutsideClick = (event: any) => {
      if (ref?.contains?.(event.target)) return
      close?.()
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [ref])

  const onDragEnd = (result: any) => {
    if (!result.destination) return // dragged outside the list

    const reorderedColumns = reorder(columns, result.source.index, result.destination.index)

    onColumnReorder(reorderedColumns)
  }

  return (
    <Overlay onClose={onClose}>
      <Overlay.Header title="Column Options" />

      <Overlay.Content css={styles.content}>
        <Alert glyph="info" className="!mb-4">
          Click on column names to show/hide or drag to re-order them.
        </Alert>

        <Card>
          <div ref={setRef}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="table_columns_reorder">
                {(droppableProvided: any) => (
                  <div ref={droppableProvided.innerRef}>
                    {columns.map((column: any, index: number) => {
                      if (!column.canToggleVisible || (hiddenColumns && hiddenColumns?.includes?.(column.id))) return null
                      const toggleHiddenProps = column.getToggleHiddenProps()

                      return (
                        <Draggable key={column.id} draggableId={column.id} index={index}>
                          {(draggableProvided: any, draggableSnapshot: any) => (
                            <label
                              key={column.id}
                              ref={draggableProvided.innerRef}
                              css={styles.item}
                              style={draggableProvided.draggableProps.style}
                              className={draggableSnapshot.isDragging ? 'is-dragging' : ''}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <Glyph glyph="drag_and_drop" size={18} css={styles.moveGlyph} />
                              <input type="checkbox" css={styles.hiddenInput} {...toggleHiddenProps} />
                              <RadioCheckElement type="checkbox" isChecked={toggleHiddenProps.checked} />
                              <span css={styles.label}>{column.Header}</span>
                            </label>
                          )}
                        </Draggable>
                      )
                    })}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Card>
      </Overlay.Content>
    </Overlay>
  )
}

const styles = {
  content: {
    padding: '1rem',
  },

  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.4em 0.75em',
    borderBottom: `1px solid ${COLORS.divider}`,
    cursor: 'pointer !important',

    '&:hover': {
      background: COLORS.hover,
    },

    '&:last-of-type': {
      borderBottom: 'none',
    },

    '&.is-dragging': {
      borderRadius: 4,
      background: COLORS.white,
      boxShadow: SHADOW(3, COLORS.divider),

      // quick fix: when used inside a portal container, the placeholder element's position is not calculated correctly
      left: 'auto !important',
      right: '0 !important',
    },
  },

  hiddenInput: {
    display: 'none',
    WebkitAppearance: 'none',
  },

  label: {
    display: 'inline-block',
    marginLeft: '0.4em',
    fontWeight: 500,
  },

  moveGlyph: {
    opacity: 0.7,
    marginLeft: '-0.1rem',
    marginRight: '0.5rem',
    cursor: 'move !important',

    '&:hover': {
      cursor: 'move !important',
    },
  },
}

export default TableOptionsOverlay
