import React from 'react'

import { withOverlayError } from '../../hocs/withOverlayError'

import Card from '../../components/Card'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import { DataFormOverlay } from '../DataFormOverlay'

const RootRecoveryCapitalPlanOverlay = (props: any) => {
  const { category = 'recovery_coaching', icon = 'recovery_coaching', permissionName } = props

  return (
    <DataFormOverlay
      minimizeEnabled
      clientPortalCanEdit
      enableTreatmentPlanSelector
      title="Recovery Capital Plan"
      category={category}
      subcategory="recovery_capital_plan"
      icon={icon}
      requestClientSignature={false}
      requestAuthorSignature={false}
      requestSupervisorSignature={false}
      requireSupervisor={false}
      permissionName={permissionName}
      {...props}
    >
      <>
        <Section
          title="Description"
          headingType="h2"
          scrollview={{
            id: 'description',
            name: 'Description',
          }}
        >
          <p>
            Robert Granfield and William Cloud introduced and elaborated on the concept of “recovery capital” in a series of articles and a
            1999 book, Coming Clean: Overcoming Addiction without Treatment. They define recovery capital as the volume of internal and
            external assets that can be brought to bear to initiate and sustain recovery from alcohol and other drug problems. Recovery
            capital, or recovery capacity, differs from individual to individual and differs within the same individual at multiple points
            in time. Recovery capital also interacts with problem severity to shape the intensity and duration of supports needed to achieve
            recovery. This interaction dictates the intensity or level of care one needs in terms of professional treatment and the
            intensity and duration of post-treatment recovery support services. The figure below indicates how these combinations of problem
            severity and recovery capital could differ.
          </p>

          <p>
            People with high problem severity but very high recovery capital may require few resources to initiate and sustain recovery than
            an individual with moderate problem severity but very low recovery capital. Where the former may respond very well to outpatient
            counseling, linkage to recovery mutual aid groups and a moderate level of ongoing supervision, the latter may require a higher
            intensity of treatment, greater enmeshment in a culture of recovery (e.g., placement in a recovery home, greater intensity of
            mutual aid involvement, involvement in recovery-based social activities), and a more rigorous level of ongoing monitoring and
            supervision.
          </p>

          <p>
            Traditional addiction assessment instruments do a reasonably good job of evaluating problem severity and some of the newer
            instruments improve the assessment of problem complexity (e.g., co-occurring medical/psychiatric problems), but few instruments
            measure recovery capital. The scale on the following page is intended as a self-assessment instrument to help a client measure
            his or her degree of recovery capital. The scale can be completed and discussed in an interview format, or it can be completed
            by the client and then discussed with the professional helper.
          </p>

          <Card className="!max-w-[500px]">
            <img
              src="https://behave-images.s3.amazonaws.com/recovery_capital_scale_diagram.png"
              alt="Recovery Capital Scale Diagram"
              className="!w-full !block"
            />
          </Card>
        </Section>

        <Divider />

        <Section
          title="Recovery Capital Plan"
          headingType="h2"
          scrollview={{
            id: 'recovery_capital_plan',
            name: 'Recovery Capital Plan',
          }}
          commentsModel="data.recovery_capital_plan"
        >
          <FormSection>
            <p className="!m-0">
              After completing and reviewing the Recovery Capital Scale, below are my recovery goals for the next month. To move closer to
              each goal, I will increase my recovery capital by doing the following daily and/or weekly activities.
            </p>

            <h3 className="!-mb-3">Goal #1</h3>
            <SmartTextarea useDictation label="Goal #1 – What" model="data.recovery_capital_plan.goal_1.what" />
            <SmartTextarea useDictation label="Goal #1 – When" model="data.recovery_capital_plan.goal_1.when" />
            <SmartTextarea useDictation label="Goal #1 – How Often" model="data.recovery_capital_plan.goal_1.how_often" />
            <SmartTextarea useDictation label="Goal #1 – With Whom" model="data.recovery_capital_plan.goal_1.with_whom" />

            <h3 className="!-mb-3">Goal #2</h3>
            <SmartTextarea useDictation label="Goal #2 – What" model="data.recovery_capital_plan.goal_2.what" />
            <SmartTextarea useDictation label="Goal #2 – When" model="data.recovery_capital_plan.goal_2.when" />
            <SmartTextarea useDictation label="Goal #2 – How Often" model="data.recovery_capital_plan.goal_2.how_often" />
            <SmartTextarea useDictation label="Goal #2 – With Whom" model="data.recovery_capital_plan.goal_2.with_whom" />

            <h3 className="!-mb-3">Goal #3</h3>
            <SmartTextarea useDictation label="Goal #3 – What" model="data.recovery_capital_plan.goal_3.what" />
            <SmartTextarea useDictation label="Goal #3 – When" model="data.recovery_capital_plan.goal_3.when" />
            <SmartTextarea useDictation label="Goal #3 – How Often" model="data.recovery_capital_plan.goal_3.how_often" />
            <SmartTextarea useDictation label="Goal #3 – With Whom" model="data.recovery_capital_plan.goal_3.with_whom" />
          </FormSection>
        </Section>

        <Divider />

        <Section title="References" headingType="h2">
          <p>Cloud, W. (1987). From down under: A qualitative study on heroin addiction recovery. Ann Arbor, MI: Dissertation Abstracts.</p>
          <p>
            Cloud, W. & Granfield, R. (1994). Natural recovery from addictions: Treatment implications. <i>Addictions Nursing</i>, 6,
            112-116.
          </p>
          <p>
            Cloud, W. & Granfield, R. (1994). Terminating addiction naturally: Post-addict identity and the avoidance of treatment.{' '}
            <i>Clinical Sociology Review</i>, 12, 159-174.
          </p>
          <p>
            Cloud, W. & Granfield, R. (2001). Natural recovery from substance dependency: Lessons for treatment providers.{' '}
            <i>Journal of Social Work Practice in the Addictions</i>, 1(1), 83-104.
          </p>
          <p>
            Granfield, R. & Cloud, W. (1996). The elephant that no one sees: Natural recovery among middle-class addicts.{' '}
            <i>Journal of Drug Issues</i>, 26(1), 45-61.
          </p>
          <p>
            Granfield, R. & Cloud, W. (1999). <i>Coming Clean: Overcoming Addiction Without Treatment</i>. New York: New York University
            Press.
          </p>
        </Section>
      </>
    </DataFormOverlay>
  )
}

export const RecoveryCapitalPlanOverlay = withOverlayError(RootRecoveryCapitalPlanOverlay)
