import React from 'react'
import { useLocation } from 'react-router-dom'

import Divider from '../../components/Divider'
import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'

export const ClinicalNotesDropdown = ({ client }: any) => {
  const location = useLocation()

  return (
    <Dropdown label="Add Clinical Note…" buttonType="primary" glyph="add" permission="clinical_notes.create">
      <DropdownItem
        label="Group Notes"
        glyph="stack"
        color="red"
        link={{
          pathname: `${location.pathname}/gn/builder`,
          parent: { url: location.pathname },
          ...(client && {
            data: {
              group_notes: [{ client, client_id: client?.id }],
            },
          }),
        }}
        permission="clinical_notes.create"
      />

      <Divider />

      <DropdownItem
        label="Individual"
        icon="clinical_notes"
        link={{
          pathname: `${location.pathname}/i/new`,
          parent: { url: location.pathname },
          ...(client && { data: { client } }),
        }}
        permission="clinical_notes.create"
      />

      <DropdownItem
        label="Case Management"
        icon="clinical_notes"
        link={{
          pathname: `${location.pathname}/cm/new`,
          parent: { url: location.pathname },
          ...(client && { data: { client } }),
        }}
        permission="clinical_notes.create"
      />

      <DropdownItem
        label="Family Session"
        icon="clinical_notes"
        link={{
          pathname: `${location.pathname}/fs/new`,
          parent: { url: location.pathname },
          ...(client && { data: { client } }),
        }}
        permission="clinical_notes.create"
      />
    </Dropdown>
  )
}
