import React from 'react'

const WorkflowTitle = ({ title }: any) => {
  return (
    <h3 css={styles} className="workflow-title">
      {title}
    </h3>
  )
}

const styles = {
  margin: 0,
  // color: COLORS.text,
  fontSize: '1.05rem',
  fontWeight: 600,
}

export default WorkflowTitle
