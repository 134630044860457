import React from 'react'

import { useSettings } from '../../hooks/useSettings'

import Accordion from '../../components/Accordion'
import AddressInputs from '../../components/Forms/elements/AddressInputs'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DateOfBirthInput from '../../components/Forms/DateOfBirthInput'
import EmailInput from '../../components/Forms/EmailInput'
import Form from '../../components/Forms/Form'
import FormCard from '../../components/Forms/FormCard'
import FormSection from '../../components/Forms/FormSection'
import GenderIdentitySelect from '../../components/Forms/elements/GenderIdentitySelect'
import Input from '../../components/Forms/Input'
import InsurancePolicyInputs from '../../components/Forms/elements/InsurancePolicyInputs'
import PhoneInput from '../../components/Forms/PhoneInput'
import PreferredPaymentMethodRadioGroup from '../../components/Forms/elements/PreferredPaymentMethodRadioGroup'
import RelationshipSelect from '../../components/Forms/elements/RelationshipSelect'
import SexSelect from '../../components/Forms/elements/SexSelect'
import Textarea from '../../components/Forms/Textarea'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'

export const EVOBApplicationForm = (props: any) => {
  const form: any = React.useRef()

  const { timezone } = useSettings()

  return (
    <Form
      isEditable
      getForm={props.form || form}
      timezone={props.timezone || timezone}
      onValidationUpdate={props.onValidationUpdate}
      onUpdate={props.onUpdate}
    >
      {/* <div className="mb-4">
        <Markup value={settings.header_message} />
      </div> */}

      <Accordion isOpen title="Personal Details">
        <FormSection maxWidth="100%">
          <Input
            label="First Name"
            model="personal_details.first_name"
            validations={{
              presence: {
                message: 'Please enter your first name',
              },
            }}
          />

          <Input label="Middle Name" model="personal_details.middle_name" />

          <Input
            label="Last Name"
            model="personal_details.last_name"
            validations={{
              presence: {
                message: 'Please enter your last name',
              },
            }}
          />

          <YesNoRadioGroup label="Have you ever been known by an alternate name?" model="personal_details.has_alternate_name" />

          <ContextShow when="personal_details.has_alternate_name" is={true}>
            <Input
              label="Alternate Name"
              model="personal_details.alternate_name"
              validations={{
                presence: {
                  message: 'Please enter your alternate name',
                },
              }}
            />
          </ContextShow>

          <DateOfBirthInput
            label="Date of Birth"
            model="personal_details.dob"
            validations={{
              presence: {
                message: 'Please enter a date of birth',
              },
            }}
          />
        </FormSection>
      </Accordion>

      <Accordion isOpen title="Demographics">
        <FormSection maxWidth="100%">
          <SexSelect label="Sex" model="demographics.sex" />
          <GenderIdentitySelect label="Gender Identity" model="demographics.gender_identity" />
        </FormSection>
      </Accordion>

      <Accordion isOpen title="Program Cost">
        <FormSection maxWidth="100%">
          <CheckboxGroup
            label="How will you pay for the program?"
            description="You can select one or multiple options"
            layout="vertical-dense"
            trueIcon="check"
            falseIcon="cross"
            falseStyle="faded"
            validations={{
              presence: {
                message: 'Please select at least one payment option',
              },
            }}
          >
            <Checkbox label="I will pay myself" model="program_cost.has_self_pay" />
            <Checkbox label="Someone else will pay" model="program_cost.has_private_payer" />
            <Checkbox label="Insurance will pay" model="program_cost.has_insurance" />
            <Checkbox label="I need financial assistance" model="program_cost.need_financial_assistance" />
            <Checkbox label="Other" model="program_cost.has_other_payment_method" />
          </CheckboxGroup>

          <ContextShow when="program_cost.has_private_payer" is={true}>
            <FormCard title="Private Payer Details">
              <Input
                label="First Name"
                model="program_cost.private_payer.first_name"
                validations={{
                  presence: {
                    message: 'Please enter a first name',
                  },
                }}
              />
              <Input
                label="Last Name"
                model="program_cost.private_payer.last_name"
                validations={{
                  presence: {
                    message: 'Please enter a last name',
                  },
                }}
              />
              <RelationshipSelect label="Relationship" model="program_cost.private_payer.relationship" />
              <PhoneInput label="Phone Number" model="program_cost.private_payer.phone_no" />
              <EmailInput label="Email Address" model="program_cost.private_payer.email" />
              <AddressInputs model="program_cost.private_payer.address" />
              <PreferredPaymentMethodRadioGroup
                label="What is the likely preferred payment method? (can be changed later)"
                model="program_cost.private_payer.preferred_payment_method"
              />
            </FormCard>
          </ContextShow>

          <ContextShow when="program_cost.has_insurance" is={true}>
            <FormCard title="Insurance Details">
              <InsurancePolicyInputs model="program_cost.insurance_policy" />
            </FormCard>
          </ContextShow>

          <ContextShow when="program_cost.need_financial_assistance" is={true}>
            <FormCard title="Financial Assistance">
              <Textarea label="Please describe your financial situation:" model="program_cost.financial_assistance_notes" minRows={10} />
            </FormCard>
          </ContextShow>

          <ContextShow when="program_cost.has_other_payment_method" is={true}>
            <FormCard title="Other Payment Method">
              <Textarea
                label="Please describe how you would like to pay for the program"
                model="program_cost.other_payment_method"
                minRows={10}
                validations={{
                  presence: {
                    message: 'Please enter a payment description',
                  },
                }}
              />
            </FormCard>
          </ContextShow>
        </FormSection>
      </Accordion>

      <Accordion isOpen title="Sensitive Information">
        <FormSection maxWidth="100%">
          <Input label="Drivers License Number" model="sensitive_information.drivers_license_no" />
          <Input label="State ID Number" model="sensitive_information.state_id_no" />
        </FormSection>
      </Accordion>

      <Accordion isOpen title="Additional Info">
        <FormSection maxWidth="100%">
          <Textarea
            label="Please enter any other information about yourself or your situation that you feel we need to know"
            model="additional_information.other_information"
          />
        </FormSection>
      </Accordion>

      {/* <div className="mt-4">
        <Markup value={settings.footer_message} />
      </div> */}
    </Form>
  )
}
