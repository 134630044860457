import React from 'react'
import { parse } from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { isUUID } from '../../utils/functions'
import { NotionBrowser } from '../../constructs/Notion'
import type { NotionPage } from '../../constructs/Notion/NotionBrowser'

import Button from '../Button'
import Flex from '../Flex'
import OverlaySubHeader from '../Overlay/OverlaySubHeader'
import SummonOverlay from '../SummonOverlay'

import { NotionHelpOverlay } from '../Help/NotionHelpOverlay'

import { AppSidebarContext } from './context'
import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

const HOME_TAG = 'admin_help_center_home'

const HOME_PAGE = {
  name: 'Admin Help Center Home',
  page_id: '9042ce0f86dc45d9968c886a88feb0d0',
  url: 'https://www.notion.so/behave-help/Admin-Help-Center-9042ce0f86dc45d9968c886a88feb0d0',
}

const AdminHelpCenterView: React.FC<any> = () => {
  const location = useLocation()
  const history = useHistory()

  const { isFullScreen }: any = React.useContext(AppSidebarContext)

  const user = useSelector((state: any) => state.me?.user)
  const isBehave = user?.type === 'bh_employee'

  let params: any = parse(location.search)
  const helpTag: any = isUUID(params.internal) ? '' : params.internal

  const internalPages = useSelector((state: any) => state.me?.settings?.admin_help_center)
  const internalTags = useSelector((state: any) => state.me?.settings?.admin_help_center_tags)

  const page = internalPages?.[helpTag]
  const homePage = internalPages?.[HOME_TAG] || HOME_PAGE

  const handlePageChange = (page: NotionPage) => {
    if (!page?.id) return

    const tag = internalTags?.[page.id]

    const searchParams = new URLSearchParams({ internal: tag || page.id })
    history.replace({ search: searchParams.toString() })
  }

  if (!page && !homePage) return null

  return (
    <>
      <AppSidebarView>
        <AppSidebarHeader
          title="Admin Help Center"
          icon="behave_health"
          actions={!isFullScreen && <Button hideLabel color="text" glyph="settings" size={200} link="/settings/admin-help-center" />}
        />

        {isBehave && (
          <OverlaySubHeader css={styles.subHeader}>
            <Flex centerY gap={8} justifyContent="space-between">
              <SummonOverlay isDisabled={!page} overlay={<NotionHelpOverlay title={page?.name} url={page?.url} helpTag={helpTag} />}>
                <Button
                  css={!page && { opacity: '0 !important' }}
                  label="Edit Notion URL"
                  glyph="edit"
                  type="minimal"
                  size={200}
                  isDisabled={!page}
                />
              </SummonOverlay>

              <Button
                useGlyphForTarget
                label="Open in Notion"
                color="text"
                glyph="notion"
                href={`https://www.notion.so/${page?.page_id}`}
                size={200}
                target="_blank"
                type="minimal"
              />
            </Flex>
          </OverlaySubHeader>
        )}

        <NotionBrowser
          homeTitle={homePage?.name}
          homeID={homePage?.page_id}
          pageTitle={page?.name}
          pageID={page?.page_id}
          onPageChange={handlePageChange}
          css={styles.notionBrowser}
          feedbackCategory="internal_article"
        />
      </AppSidebarView>
    </>
  )
}

const styles = {
  subHeader: {
    padding: '0.5rem',
  },

  notionBrowser: {
    padding: '1rem 1rem 0',
  },
}

export default AdminHelpCenterView
