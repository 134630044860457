import React from 'react'

import create from 'zustand'

import { isDefined } from '../../utils/functions'

export const FormContext = React.createContext({})
export const CheckboxGroupContext = React.createContext({})

export const useFormStore = (store: any) => create(store)

export const withFormContext = (Wrapped: any) => {
  return class extends React.Component {
    render = () => (
      <FormContext.Consumer>
        {(context: any) => (
          <Wrapped
            {...this.props}
            store={context.store}
            form={context.form}
            data={context.data}
            initialData={context.initialData}
            lastUpdatedInput={context.lastUpdatedInput}
            timezone={this.props.timezone || context.timezone}
            defaultDebounce={context.defaultDebounce}
            isEditable={isDefined(this.props.isEditable) ? this.props.isEditable : context.isEditable}
            isCompact={context.isCompact}
          />
        )}
      </FormContext.Consumer>
    )
  }
}

export const withCheckboxGroupFormContext = (Wrapped: any) => {
  return class extends React.Component {
    render = () => (
      <CheckboxGroupContext.Consumer>
        {(context: any) => (
          <Wrapped
            {...this.props}
            trueIcon={isDefined(this.props.trueIcon) ? this.props.trueIcon : context.trueIcon}
            falseIcon={isDefined(this.props.falseIcon) ? this.props.falseIcon : context.falseIcon}
            falseStyle={isDefined(this.props.falseStyle) ? this.props.falseStyle : context.falseStyle}
            isEditable={isDefined(this.props.isEditable) ? this.props.isEditable : context.isEditable}
            registerCheckbox={context.registerCheckbox}
            onUpdate={isDefined(this.props.onUpdate) ? this.props.onUpdate : context.onUpdate}
          />
        )}
      </CheckboxGroupContext.Consumer>
    )
  }
}
