import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { SafetyChecksReportDataTable } from '@behavehealth/constructs/LiveReports/SafetyChecksReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ShiftNotes = () => {
  const tableProps = useDataTable({
    name: ['reports', 'safety-checks'],
    endpoint: `/live_reports?category=safety_checks`,
    localStorageKey: 'report_safety_checks_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.author)}/safety-checks/${rowData.id}`, [])

  return (
    <Page feature="housing_notes" title="Live Safety Check Report">
      <Grid>
        <SafetyChecksReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ShiftNotes)
