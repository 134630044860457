import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

import Overlay from '../../components/Overlay'
import Section from '../../components/Section'

import { TreatmentPlanUpdatesDataTable } from './TreatmentPlanUpdatesDataTable'

export const TreatmentPlanUpdatesOverlay = (props: any) => {
  const { planId, onClose } = props

  const location: any = useLocation()
  const navigate: any = useNavigate()

  const close = () => {
    if (props.type === 'summon' && onClose) return onClose()

    const url = location.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    navigate(url)
  }

  if (!planId) return null

  return (
    <Overlay onClose={close} position="right" maxWidth={80}>
      <Overlay.Header icon="treatment_plans" title="Treatment Plan Updates" />

      <Overlay.Content>
        <Section>
          <TreatmentPlanUpdatesDataTable asCard planId={planId} pageSize={100} />
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}
