import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useLocation } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useStore from '@behavehealth/modules/store'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Page, Grid, SummonOverlay } from '@behavehealth/components'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { NewIntegrationOverlay } from '@behavehealth/constructs/Integrations/NewIntegrationOverlay'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import { IntegrationOverlay } from '@behavehealth/constructs/Integrations/IntegrationOverlay'

const pageConfig = {
  feature: 'company_integrations',
  title: 'Company Integrations',
}

const CompanyIntegrations = () => {
  return (
    <div>
      <CompanyIntegrationsIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<IntegrationOverlay useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const CompanyIntegrationsIndex: React.FC = () => {
  const tenant = useStore((state: any) => state.tenant)

  const tableProps = useDataTable({
    name: ['company_integrations'],
    endpoint: `/facilities/${tenant?.id}/integrations`,
    enabled: !!tenant?.id,
    params: { level: 'account' },
    localStorageKey: 'company_integrations',
  })

  const existingIntegrations = tableProps?.data?.map?.((integration: any) => integration.provider)

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell as={NavLink} id={data.id} to={data.id} value={value} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        type: 'title',
      },
      {
        title: 'Category',
        model: 'category',
        type: 'title',
      },
      {
        title: 'Level',
        model: 'level',
        type: 'title',
      },
      {
        title: 'Provider',
        model: 'provider',
        type: 'title',
      },
      {
        title: 'Notes',
        model: 'notes',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [],
  )

  const actions = (
    <SummonOverlay overlay={<NewIntegrationOverlay level="account" existingIntegrations={existingIntegrations} />}>
      <Button label="Set Up Integration" type="primary" glyph="add" />
    </SummonOverlay>
  )

  const isEmpty = size(tableProps?.data) === 0

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Grid gap="1rem">
        <DataTable
          asCard
          title="Company Integrations"
          icon="company_integrations"
          columns={columns}
          emptyDescription="No integrations set up yet"
          emptyActions={actions}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CompanyIntegrations, pageConfig))
