import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import compact from 'lodash/compact'
import sortBy from 'lodash/sortBy'

import { COLORS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import State from '../../components/State'

import { CATEGORIES, DATA_FORM_SETTINGS } from './constants'

export const DataFormAddDropdown = (props: any) => {
  const { category, permission = 'assessments_builder.view', featureFlagV2 = 'assessments_builder', manageLink, setLink, onClick } = props

  const match = useRouteMatch()

  const { data, isLoading } = useGet({
    name: ['data_form_settings', { category, status: 'active' }],
    url: `/data_form_settings`,
    params: { category, status: 'active' },
    options: { enabled: !!category },
  })

  const categoryConfig = React.useMemo(() => {
    return Object.values(CATEGORIES).find((o) => o.id === category) || null
  }, [category])

  const forms = DATA_FORM_SETTINGS[category]

  const sortedData = React.useMemo(() => {
    if (!data) return null

    return sortBy(data, 'name')
  }, [data])

  if (!forms || !categoryConfig) return null

  return (
    <>
      <Button
        label="Manage Assessments"
        glyph="settings"
        type="minimal"
        link={manageLink || `/settings/assessments-builder/${categoryConfig.slug}`}
        permission={permission}
        featureFlagV2={featureFlagV2}
      />

      <Dropdown
        testKey="add_new_dropdown"
        label={`Add New…`}
        buttonType="primary"
        glyph="add"
        permission={`${categoryConfig.permissionName}.create`}
      >
        {isLoading || !sortedData ? (
          <State isLoading />
        ) : (
          sortedData.map((form) => {
            const recordKey = compact([form.subcategory, form.variant]).join('_')
            const formConfig = DATA_FORM_SETTINGS[form.category]?.[recordKey]

            if (!formConfig) return null

            return (
              <DropdownItem
                key={form.id}
                link={
                  setLink
                    ? setLink(form)
                    : {
                        pathname: `${match.url}/${formConfig.slug}/new-30-${form.id}`,
                        parent: match,
                      }
                }
                onClick={onClick ? () => onClick(form) : undefined}
                label={<span className={clsx(form.variant ? 'font-[400]' : 'font-[600]')}>{form.name}</span>}
                before={
                  form.variant ? (
                    <Glyph glyph="curved_arrow_down_right" size={12} color={`${COLORS.blue} !important`} className="!ml-4" />
                  ) : (
                    <Icon icon={categoryConfig.icon} size={20} />
                  )
                }
              />
            )
          })
        )}
      </Dropdown>
    </>
  )
}
