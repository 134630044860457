// TODO @Andrei: Replace Text component with Type and rename it back to Text
import React from 'react'

import { getTypographyStyles, COLORS, TYPOGRAPHY_STYLES } from '../../theme'

import { Color } from '../../declarations/types'

type Props = {
  as: string | React.ReactNode
  color?: Color
  variant: keyof typeof TYPOGRAPHY_STYLES
} & React.HTMLAttributes<any>

const Type: React.FC<Props> = ({ as, variant, color, className, children }) => {
  const Tag: any = as

  return (
    <Tag css={{ color: color ? COLORS[color] : COLORS.text, ...getTypographyStyles(variant) }} className={className}>
      {children}
    </Tag>
  )
}

Type.defaultProps = {
  as: 'span',
}

export default Type
