import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { PaymentsReportDataTable } from '@behavehealth/constructs/Financials/PaymentsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Payments = () => {
  const tableProps = useDataTable({
    name: ['reports', 'payments'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_payments_v1',
    params: { category: 'payments' },
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.reference)}/financials/payments/${rowData.id}`, [])

  return (
    <Page feature="financials" title="Live Payments Report">
      <Grid>
        <PaymentsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Payments)
