import React from 'react'
import size from 'lodash/size'

import { apiGet } from '../../modules/api'

import State from '../State'

type Props = {
  children: Function
  emptyDescription?: string
  fetchName: string
  fetchURL: string
  icon?: string
  title?: string
}

const ViewContent = (props: Props) => {
  const { children, emptyDescription, fetchName, fetchURL, icon, title } = props

  const [loading, setLoading] = React.useState(true)
  // const [errors, setErrors] = React.useState(null)
  const [data, setData] = React.useState(null)

  const isEmpty = size(data) === 0

  const fetchData = React.useCallback(async () => {
    if (!fetchURL) return

    setLoading(true)

    try {
      const res = await apiGet({ name: fetchName, url: fetchURL })
      setData(res.data.data)
    } catch (err) {
      // TODO (Andrei): add error handling
      setLoading(false)
    } finally {
      setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchURL])

  React.useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isEmpty || loading) {
    return (
      <State
        icon={icon}
        title={title}
        isLoading={isEmpty && loading}
        isEmpty={isEmpty}
        emptyDescription={emptyDescription}
        minHeight={100}
      />
    )
  }

  return children?.(data)
}

ViewContent.defaultProps = {
  emptyDescription: 'No data available to display',
}

export default ViewContent
