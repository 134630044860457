import React from 'react'
import { useStore as useZustandStore } from 'zustand'

import { WorksheetContext } from './context'

export const useStore = (selector: any, equalityFn?: any) => {
  const store: any = React.useContext(WorksheetContext)

  if (!store) throw new Error('Missing WorksheetContext.Provider in the tree')

  return useZustandStore(store, selector, equalityFn)
}
