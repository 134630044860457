import React from 'react'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'

import withMarketing from '@behavehealth/hocs/withMarketing'
import useAPI from '@behavehealth/hooks/useAPI'

import { useSettings } from '@behavehealth/hooks/useSettings'
import { Card, HelpTagIframe, Page, Tabs } from '@behavehealth/components'

import AgreementTemplatesTable from '@behavehealth/components/Templates/tables/AgreementTemplatesTable'

const pageConfig = {
  feature: 'staff_agreement_templates',
  title: 'Staff Agreement Templates',
  help: <HelpTagIframe id="settings_employee_agreement_templates" />,
}

const EmployeeAgreementTemplates = () => {
  const { isBehave, isManagement } = useSettings()

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Tabs defaultTab="support_main_plan">
        <Tabs.List className="-mt-4 mb-3">
          <Tabs.Item as={NavLink} label="Agreements" icon="legal_agreement_alt" to={`agreements`} />

          {(isBehave || isManagement) && (
            <Tabs.Item as={NavLink} label="Admin Agreements" icon="legal_agreement_alt" to={`admin-agreements`} />
          )}
        </Tabs.List>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to="agreements" replace />} />

        <Route path={`agreements/*`} element={<AgreementTemplates />} />
        <Route path={`admin-agreements/*`} element={isBehave || isManagement ? <AdminAgreementTemplates /> : null} />
      </Routes>
    </Page>
  )
}

const AgreementTemplates = () => {
  const data = useSelector((state: any) => state.data?.agreement_templates?.data)
  const loading = useSelector((state: any) => state.data?.agreement_templates?.loading)
  const isEmpty = size(data) === 0

  useAPI('agreement_templates', '/agreement_templates?variant=employee')

  const sortedData = React.useMemo(() => {
    if (!data) return []
    return sortBy(data, 'order', 'created_at')
  }, [data])

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Card testKey="staff_agreement_template_table">
        <AgreementTemplatesTable
          mainLinkAs={NavLink}
          title="Staff Agreement Templates"
          data={sortedData}
          isLoading={isEmpty && loading}
          to={(id: string) => id}
        />
      </Card>
    </Page>
  )
}

const AdminAgreementTemplates = () => {
  const data = useSelector((state: any) => state.data?.agreement_templates?.data)
  const loading = useSelector((state: any) => state.data?.agreement_templates?.loading)
  const isEmpty = size(data) === 0

  useAPI('agreement_templates', '/agreement_templates?variant=employee&agreement_type=admin')

  const sortedData = React.useMemo(() => {
    if (!data) return []
    return sortBy(data, 'order', 'created_at')
  }, [data])

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Card testKey="staff_agreement_template_table">
        <AgreementTemplatesTable
          mainLinkAs={NavLink}
          title="Staff Admin Agreement Templates"
          data={sortedData}
          isLoading={isEmpty && loading}
          to={(id: string) => id}
        />
      </Card>
    </Page>
  )
}

export default withMarketing(EmployeeAgreementTemplates, pageConfig)
