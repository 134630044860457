import React from 'react'

import { COLORS } from '../../theme'
import { useAppSidebarStore } from '../../hooks/useAppSidebarStore'

import { AppsHeaderButton } from './AppsHeaderButton'
import Glyph from '../../components/Glyph'

export const AppsSidebarHeaderButton = (props: any) => {
  const { isDesktop } = props

  const { open } = useAppSidebarStore()

  const openAppSidebar = () => {
    open('todo')
  }

  return (
    <AppsHeaderButton isDesktop={isDesktop} onClick={openAppSidebar}>
      <Glyph glyph="app_sidebar" size={20} color={COLORS.blue} />
    </AppsHeaderButton>
  )
}
