import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { usDateTime } from '../../utils/functions'
import { LOC } from '../../utils/constants'
import useActivePath from '../../hooks/useActivePath'
import withSettings from '../../hocs/withSettings'

import ClinicalNoteStatus from '../Statuses/ClinicalNoteStatus'
import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'

type Props = {
  location: any
  note: any
  showOnlyId: any
  timezone: any
  to: any
  user: any
}

const ClinicalNote = ({ note, showOnlyId, timezone, to, user, onClick }: Props) => {
  const location = useLocation()

  const drawAttendee = (attendee, isBehave) => {
    if (showOnlyId && attendee?.resident?.id !== showOnlyId) return null

    return (
      <Text
        key={attendee.id}
        avatar={attendee?.resident?.avatar}
        description={`${attendee?.resident_name} ${
          isBehave ? `(${attendee.is_attending ? 'Present' : 'Absent'}, ${LOC[attendee.level] || ' – '})` : ''
        }`}
      />
    )
  }

  let title = 'Clinical Note'
  if (note.category === 'individual') title = 'Individual Note'
  else if (note.category === 'group') title = 'Group Note'
  else if (note.category === 'family_session') title = 'Family Session'
  else if (note.category === 'case_management') title = 'Case Management'

  const isBehave = user?.type === 'bh_employee'
  const isBilled = note.is_billed ? `Yes (${usDateTime(note.billed_at, timezone)})` : 'No'
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} onClick={onClick} isActive={isActive}>
      <CardTitle title={title} />

      <CardMeta>
        <ClinicalNoteStatus status={note.status} />
        <Text avatar={note.author?.avatar} label={note.author?.name} />
        <Text glyph="date" label="Session: " description={usDateTime(note.started_at, timezone)} />
        <Text glyph="time" label="Duration: " description={`${note.duration} minutes`} />
        {isBehave && <Text icon="behave_health" label="Billed: " description={isBilled} />}
      </CardMeta>

      <CardMeta>
        <Text label="Attendees: " glyph="user_group" muted={false} />
        {note?.attendees.map((attendee: any) => drawAttendee(attendee, isBehave))}
      </CardMeta>
    </CardLink>
  )
}

const mapStateToProps = (state) => ({
  user: state.me.user,
})

export default withSettings(connect(mapStateToProps)(ClinicalNote))
