import React from "react";
import { useRouteMatch } from "react-router-dom";
import size from "lodash/size";

import { useGet, useUpdate } from "@behavehealth/hooks/useNewAPI";
import { withPageError } from "@behavehealth/hocs/withPageError";
import { useDataTable } from "@behavehealth/components/DataTable/useDataTable";

import withMarketing from "@behavehealth/hocs/withMarketing";

import {
	Button,
	Card,
	Chotomate,
	Grid,
	HelpTagIframe,
	Icon,
	Page,
	PageLayout,
	Accordion,
	ObjectSelector,
	CardHeader,
	CardTitle,
	CardContent,
	Form,
	FormSection,
} from "@behavehealth/components";

import PageLoader from "@behavehealth/components/Loaders/PageLoader";

import { ServiceEpisodesDataTable } from "@behavehealth/constructs/ServiceEpisodes/ServiceEpisodesDataTable";

import { ICONS } from "@behavehealth/theme";
import { SERVICE_EPISODE_LOC_SUBCATEGORIES } from "@behavehealth/utils/constants";

const pageConfig = {
	feature: "service_episodes",
	help: <HelpTagIframe id="service_episodes" />,
	marketingID: "service_episodes",
};

const ServiceEpisodes = () => {
	const match = useRouteMatch();
	const resourceID = match.params?.resource_id;

	const { data: client }: any = useGet({
		name: ["client", resourceID],
		url: `/residents/${resourceID}`,
	});

	const tableProps = useDataTable({
		name: ["client", resourceID, "service-episodes"],
		endpoint: `/residents/${resourceID}/service_episodes`,
		localStorageKey: "clients_service_episodes_v1",
	});

	const { mutateAsync: updateClient }: any = useUpdate({
		name: ["client", resourceID],
		url: `/residents/${resourceID}`,
		invalidate: ["clients"],
	});

	const to = React.useMemo(
		() => (rowData: any) => ({
			pathname: `${match.url}/${rowData.id}`,
			parent: match,
			source: "ehr",
		}),
		[match.url],
	);

	const actions = (
		<Button
			label="Add Service Episode"
			type="primary"
			glyph="add"
			link={{
				pathname: `${match.url}/new`,
				parent: match,
			}}
		/>
	);

	if (!(client && tableProps?.data)) return <PageLoader />;

	const emptyServiceEpisodes = size(tableProps?.data) === 0;

	return (
		<Page actions={actions} {...pageConfig}>
			<Grid gap="1rem">
				<Chotomate name="service_episodes" ready={!tableProps?.isLoading} />

				<PageLayout>
					{/* Billing */}
					<Accordion
						isOpen
						activateEditMode
						title="Primary Service Episode"
						permission="service_episodes.view"
						editPermission="service_episodes.edit"
						initialModel={client}
						onSubmit={updateClient}
					>
						<Form>
							<FormSection layout="vertical" maxWidth="650px">
								<ObjectSelector
									model="active_level_of_care"
									label="Primary Service"
									type="service_episodes.active"
									icon={ICONS.treatment_data}
									dependentValue={client?.id}
									selectTitle={(data) => data.name}
									selectDescription={(data: any) =>
										SERVICE_EPISODE_LOC_SUBCATEGORIES?.[data?.loc_category]?.[
											data?.loc_subcategory
										] || "–"
									}
									description="Your current Primary Service. Please add more below to select it as a Primary Service."
								/>
							</FormSection>
						</Form>
					</Accordion>

					<Card>
						{!emptyServiceEpisodes && (
							<CardHeader graphic={<Icon icon="treatment_data" size={20} />}>
								<CardTitle title="Service Episodes" />
							</CardHeader>
						)}

						<CardContent padding="0" showDivider={!emptyServiceEpisodes}>
							<ServiceEpisodesDataTable
								{...tableProps}
								emptyActions={actions}
								to={to}
								headerLinksConfig={[
									{
										type: "report",
										to: "/reports/service-episodes",
									},
								]}
							/>
						</CardContent>
					</Card>
				</PageLayout>
			</Grid>
		</Page>
	);
};

export default withPageError(withMarketing(ServiceEpisodes, pageConfig));
