import React from 'react'
import { v4 as uuid } from 'uuid'
import produce from 'immer'

import { isDefined } from '../../utils/functions'
import { withFormContext } from './context'
import { validate } from './validators'
import Value from '../Value'

import FieldBase from './FieldBase'

class RadioGroup extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    const vs = props.validations
    let value = props.value

    if (!isDefined(props.value)) {
      if (props.model) {
        const modelVal = props.form?.getField(props.model)
        const initialModelVal = props.form?.getInitialInputFieldValue(props.model)
        const modelValDefined = isDefined(modelVal)
        const initialModelValDefined = isDefined(initialModelVal)

        value = modelValDefined ? modelVal : initialModelValDefined ? initialModelVal : props.defaultValue
      } else if (isDefined(props.defaultValue)) {
        // check if defaultValue is defined
        value = props.defaultValue
      }
    }

    // check if type of value matches expectations: string or boolean
    const validType = typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number'
    if (!validType) value = null

    if (vs) errors = validate(value, vs)

    this.state = {
      type: 'RADIO_GROUP',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value,
      isNested: props.isNested || false,
      isMounted: false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }
    this.updateType = 'DATA'
  }

  /*
    RENDER
  */
  processUpdate = (_queueEvent: any) => {
    const vs = { ...this.props.defaultValidations, ...this.props.validations }

    const newState = produce(this.state, (draft: any) => {
      draft.model = this.props.model
      draft.value = this.props.value
      draft.isRequired = vs?.hasOwnProperty('presence')
      draft.defaultValue = this.props.defaultValue
    })

    this.setState({
      isHighlighted: false,
      model: this.props.model,
      value: newState.value,
      isRequired: newState.isRequired,
      defaultValue: newState.defaultValue,
    })

    return newState
  }

  editRender = () => {
    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {
        group_id: this.state.id,
        parentModel: this.props.model,
        onChange: this.changeValue,
        parentValue: this.state.value,
      }),
    )

    return childrenWithProps
  }

  readOnlyRender = () => {
    const { children } = this.props
    const { value } = this.state

    let displayValue = value
    for (let i = 0; i < children.length; i++) {
      if (children[i].props.value === value) {
        displayValue = children[i].props.label
        break
      }
    }

    return <Value value={displayValue} />
  }
}

RadioGroup.defaultProps = {
  layout: 'vertical',
  isEditable: true,
  validateOn: 'blur-change',
}

export default withFormContext(RadioGroup)
