import React from 'react'

import CardsGrid from '../CardsGrid'
import FinancialCard from '../Cards/FinancialCard'

const ClaimFinancialStats = () => {
  return (
    <CardsGrid columnWidth={200}>
      <FinancialCard title="Current Balance" value={1600} valueColor="text" />
      <FinancialCard title="Total Charges" value={5400} valueColor="red" prefix="-" />
      <FinancialCard title="Client Payments" value={1000} valueColor="green" prefix="+" />
      <FinancialCard title="Insurance Payments" value={2000} valueColor="green" prefix="+" />
      <FinancialCard title="Write-Off's" value={800} valueColor="text" prefix="+" />
      <FinancialCard title="Patient Responsibility" value={1200} valueColor="orange" prefix="+" />
    </CardsGrid>
  )
}

export default ClaimFinancialStats
