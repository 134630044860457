import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../../theme'

import { inputStyles } from './Input'
import ClearButton from '../../Forms/ClearButton'
import Glyph from '../../Glyph'

const Select = (props: any) => {
  const { filterValue, filterOptions, setFilter, defaultLabel, className } = props

  const classNames = clsx({
    'has-value': !!filterValue,
    [className]: className,
  })

  const handleChange = (e: any) => setFilter(e.target.value || undefined)

  const clearFilter = () => setFilter(undefined)

  return (
    <div className="!relative">
      <select value={filterValue} onChange={handleChange} css={selectStyles} className={classNames}>
        <option value="" selected={!filterValue}>
          {defaultLabel}
        </option>

        {filterOptions.map((option: any) => (
          <option key={option.value} value={String(option.value)}>
            {option.label}
          </option>
        ))}
      </select>

      <Glyph glyph="triangle_down" size={12} css={glyphStyles} color={COLORS.blue} className={filterValue ? 'is-hidden' : ''} />

      <ClearButton onClick={clearFilter} isVisible={!!filterValue} />
    </div>
  )
}

const selectStyles = {
  ...inputStyles,

  display: 'block',
  width: '100%',
  maxWidth: '100%',
  paddingRight: '2rem',

  WebkitAppearance: 'none',
  cursor: 'pointer',
}

const glyphStyles = {
  position: 'absolute',
  top: '50%',
  right: '0.5rem',
  zIndex: 0,
  transform: 'translateY(-50%)',
  pointerEvents: 'none',

  '&.is-hidden': {
    display: 'none',
  },
}

Select.defaultProps = {
  defaultLabel: 'All',
}

export default Select
