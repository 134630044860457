import React from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import produce from 'immer'

import { COLORS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import NavGroup from '../../components/NavGroup'
import NavItem from '../../components/NavItem'
import Overlay from '../../components/Overlay'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import SignaturePad from '../../components/Forms/SignaturePad'
import Textarea from '../../components/Forms/Textarea'
import Tooltip from '../../components/Tooltip'

import { Attachments } from './constructs/Attachments'
import { ConsentForms } from './constructs/ConsentForms'
import { DataCategories } from './constructs/DataCategories'
import { ReferralColumn } from './components/ReferralColumn'
import { TransportDetails } from './constructs/TransportDetails'

import { useMockReferralsStore } from './useMockReferralsStore'

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const RootReferralBuilderOverlay = (props: any) => {
  const { dataID } = props

  const match = useRouteMatch()

  const { data: partnership }: any = useGet({
    name: ['organizations', match.params?.['resource_id']],
    url: `/organizations/${match.params?.['resource_id']}`,
  })

  const partnerName = partnership?.other_partner?.name

  const { timezone, tenant, user } = useSettings()

  const isNew = !dataID || dataID === 'new'

  const referralsData = useMockReferralsStore((state) => state.data)
  const saveReferral = useMockReferralsStore((state) => state.saveReferral)
  const deleteReferral = useMockReferralsStore((state) => state.deleteReferral)
  const addUpdate = useMockReferralsStore((state) => state.addUpdate)

  const storeRecord = React.useMemo(() => {
    return referralsData?.find((o: any) => o.id === dataID)
  }, [referralsData, dataID])

  const [initialData] = React.useState(isNew ? props.initialData : storeRecord)

  const location = useLocation()
  const history = useHistory()

  const [tab, setTab] = React.useState(TABS[0].id)
  const [data, setData] = React.useReducer(dataReducer, initialData)

  const [client, setClient] = React.useState(data?.client)
  const [partners, setPartners] = React.useState(data?.partners || [])

  const [isEditable, setIsEditable] = React.useState(isNew)
  const [formValid, setFormValid] = React.useState(false)

  const activeTabConfig = React.useMemo(() => {
    return TABS.find((o) => o.id === tab)
  }, [tab])

  const ActiveComponent = React.useMemo(() => {
    return activeTabConfig?.component
  }, [activeTabConfig])

  const close = () => {
    if (props.onClose) return props.onClose()

    if (location.parent) {
      if (location.parent.url) history.push(location.parent.url)
      else history.push(location.parent)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  const handleSave = () => {
    const saveData = {
      ...data,
      ...(isNew && {
        client,
        partners,
        sent_from: tenant,
        sent_from_id: tenant.id,
        author: user,
      }),
    }

    saveReferral(saveData)
    close()
  }

  const canSave = !!data.client || !!client
  const canSubmit = canSave && !!data.client_did_agree && !!data.client_signature

  React.useEffect(() => {
    if (storeRecord) setData(storeRecord)
  }, [storeRecord])

  const initialModel = produce(data, (draft) => {
    delete draft.client
    delete draft.partners
    delete draft.sent_from
    delete draft.sent_from_id
    delete draft.sent_to
    delete draft.sent_to_id
    delete draft.author
  })

  if (!isNew && !initialData) return null

  return (
    <Overlay fullheight showBackdrop={isEditable} closeOnBackdrop onClose={close} position="right" maxWidth={90}>
      <Overlay.Header icon="referrals" title={isNew ? 'Send New Referral' : 'Referral'} />

      <Overlay.Content className="flex flex-nowrap !overflow-hidden">
        <nav className="p-4 h-full overflow-y-auto grow-0 shrink-0 basis-[220px] border-r border-solid border-0 border-divider">
          {TAB_GROUPS.map((group) => (
            <NavGroup key={group.label} label={group.label}>
              {group.tabs.map((o) => (
                <NavItem
                  key={o.id}
                  isActive={tab === o.id}
                  label={o.label}
                  icon={o.icon}
                  color={o.color}
                  onClick={() => {
                    setTab(o.id)
                  }}
                />
              ))}
            </NavGroup>
          ))}
        </nav>

        {activeTabConfig && ActiveComponent && (
          <ActiveComponent
            isNew={isNew}
            icon={activeTabConfig.icon}
            title={activeTabConfig.label}
            data={data}
            setData={setData}
            timezone={timezone}
            initialData={initialData}
            isEditable={isEditable}
            client={client}
            setClient={setClient}
            partners={partners}
            setPartners={setPartners}
            setFormValid={setFormValid}
            initialModel={initialModel}
          />
        )}
      </Overlay.Content>

      <Grid gap="0.75rem" className="px-4 pb-4 bg-[#F7F8FB]">
        <Flex gap="0.75rem">
          <Button
            label="Save as Draft"
            glyph="check"
            color="green"
            type="default"
            flex="3 1 auto"
            isDisabled={!canSave}
            onClick={() => {
              handleSave()
            }}
          />

          <Tooltip
            show={!canSubmit}
            glyph="warning"
            color="orange"
            content={`Client needs to agree to release the information and provide a signature to send the referral out`}
            className="flex-[2_1_auto]"
          >
            <Button label="Save & Send Referral" glyph="check" color="green" type="primary" flex="3 1 auto" isDisabled={!canSubmit} />
          </Tooltip>
        </Flex>
      </Grid>
    </Overlay>
  )
}

const ReferralDetails = (props: any) => {
  const {
    isNew,
    data,
    setData,
    timezone,
    initialData,
    isEditable,
    setFormValid,
    client,
    setClient,
    partners,
    setPartners,
    partner,
    setPartner,
    initialModel,
  } = props

  return (
    <ReferralColumn icon={props.icon} title={props.title}>
      <Form isEditable={isEditable} onValidationUpdate={setFormValid} timezone={timezone} initialModel={initialModel} defaultDebounce={300}>
        <FormSection>
          <OverlaySelector
            isEditable={isNew}
            label="Referred Client"
            icon="clients"
            type="clients.current"
            value={client}
            onUpdate={(o) => {
              setClient(o.object)
            }}
            validations={{
              presence: {
                message: 'Please select a client',
              },
            }}
          />

          <SignaturePad
            isEditable
            label="Client Signature"
            allowPin={false}
            maxWidth="100%"
            showHeader={false}
            showAccept={false}
            value={data.client_signature}
            onUpdate={({ value }) => setData({ client_signature: value })}
          />

          <Checkbox
            label="By signing below, I authorize the release of my medical information as necessary for the referral process to the designated healthcare facility. I understand that this information will be used solely for the purpose of coordinating my care between providers."
            value={!!data.client_did_agree}
            onUpdate={({ value }) => {
              setData({ client_did_agree: value })
            }}
          />

          {isNew && (
            <MultiOverlaySelector
              label="Send to Partners"
              icon="community_partners"
              type="partnerships.all"
              value={partners}
              validations={{
                presence: {
                  message: 'Please select at least one partner',
                },
              }}
              selectTitle={(o) => o.other_partner?.name}
              onUpdate={(o) => {
                setPartners(o.object)
              }}
            />
          )}

          {!isNew && initialData && (
            <>
              <OverlaySelector
                isEditable={false}
                label="Sent From"
                icon="community_partners"
                type="partnerships.all"
                selectTitle={(o) => o.name}
                value={initialData.sent_from}
                validations={{
                  presence: {
                    message: 'Please select a partner',
                  },
                }}
              />

              <OverlaySelector
                isEditable={false}
                label="Sent To"
                icon="community_partners"
                type="partnerships.all"
                value={initialData.sent_to}
                selectTitle={(o) => o.name}
                validations={{
                  presence: {
                    message: 'Please select a partner',
                  },
                }}
              />

              <OverlaySelector
                isEditable={false}
                label="Sent By"
                icon="community_partners"
                type="partnerships.all"
                value={initialData.author}
                validations={{
                  presence: {
                    message: 'Please select a partner',
                  },
                }}
              />
            </>
          )}

          <DateTimeInput
            label="Requested First Appointment Date"
            model="referred_at"
            value={data.referred_at}
            onUpdate={({ value }) => {
              setData({ referred_at: value })
            }}
          />

          <Textarea
            label="Notes"
            model="notes"
            value={data.notes}
            onUpdate={({ value }) => {
              setData({ notes: value })
            }}
          />
        </FormSection>
      </Form>
    </ReferralColumn>
  )
}

const TAB_GROUPS = [
  {
    label: '1. Referral & Consent',
    tabs: [
      {
        id: 'referral_details',
        label: 'Referral Details',
        icon: 'referrals',
        color: COLORS.violet,
        component: ReferralDetails,
      },
      {
        id: 'consent_form',
        label: 'Consent Forms',
        icon: 'checklist',
        color: COLORS.green,
        component: ConsentForms,
      },
    ],
  },
  {
    label: '2. Client Records',
    tabs: [
      {
        id: 'data_transfer',
        label: 'Client Records',
        icon: 'web_form',
        color: COLORS.blue,
        component: DataCategories,
      },
      {
        id: 'attachments',
        label: 'Attachments',
        icon: 'files',
        color: COLORS.vividBlue,
        component: Attachments,
      },
    ],
  },
  {
    label: '3. Transport Details',
    tabs: [
      {
        id: 'transport_details',
        label: 'Transport Details',
        icon: 'vehicles',
        color: COLORS.vividBlue,
        component: TransportDetails,
      },
    ],
  },
]

export const TABS = TAB_GROUPS.reduce((acc, group) => {
  return [...acc, ...group.tabs]
}, [])

export const ReferralBuilderOverlay = withOverlayError(RootReferralBuilderOverlay)
