import React from 'react'

import Checkbox from '../../../../components/Forms/Checkbox'
import Input from '../../../../components/Forms/Input'
import Radio from '../../../../components/Forms/Radio'
import RadioGroup from '../../../../components/Forms/RadioGroup'
import Textarea from '../../../../components/Forms/Textarea'

import { VariablesDropdownMenu } from '../../components/VariablesDropdownMenu'

export const SingleSelectInputEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate, activeElementId, appendVariableToElementModel } = props

  const {
    description,
    is_full_width,
    is_required,
    label,
    options_layout = 'vertical-dense',
    single_select_input_type = 'radio',
    tooltip,
  } = activeElement.config

  return (
    <>
      <RadioGroup
        label="Display Options"
        model="single_select_input_type"
        layout="vertical-dense"
        value={single_select_input_type}
        onUpdate={onUpdate}
      >
        <Radio label="List" value="radio" />
        <Radio label="Dropdown" value="select" />
      </RadioGroup>

      <Checkbox
        label="Full-Width"
        model="is_full_width"
        onUpdate={onUpdate}
        // value={!!is_full_width}
      />

      {single_select_input_type === 'radio' && (
        <RadioGroup label="Options Layout" model="options_layout" layout="vertical-dense" value={options_layout} onUpdate={onUpdate}>
          <Radio label="Vertical 1 Column" value="vertical-dense" />
          <Radio label="Vertical 2 Columns" value="vertical-2-columns" />
          <Radio label="Vertical 3 Columns" value="vertical-3-columns" />
          <Radio label="Horizontal" value="horizontal" />
        </RadioGroup>
      )}

      <Input
        label="Label"
        model="label"
        value={label}
        onUpdate={onUpdate}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'label' })
            }}
          />
        }
      />

      <Checkbox
        label="Required Field"
        model="is_required"
        onUpdate={onUpdate}
        // value={is_required}
      />

      <Textarea
        label="Description"
        model="description"
        value={description}
        onUpdate={onUpdate}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'description' })
            }}
          />
        }
      />

      <Textarea
        label="Info Tooltip"
        model="tooltip"
        value={tooltip}
        onUpdate={onUpdate}
        labelAfter={
          <VariablesDropdownMenu
            onSelect={(variableKey) => {
              appendVariableToElementModel({ variableKey, elementId: activeElementId, model: 'tooltip' })
            }}
          />
        }
      />
    </>
  )
}
