import React from 'react'
import sortBy from 'lodash/sortBy'

import { DataTable } from '../../components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '../Filters/constants'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { withOverlayError } from '../../hocs/withOverlayError'

import MessageStatus from '../../components/Statuses/MessageStatus'

const RootClientsMessageBoardDataTable: React.FC<any> = (props: any) => {
  const { title = 'Messages' } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        model: 'title',
        width: 200,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            as={props.mainLinkAs}
            id={data.id}
            value={<span className={data.is_expired ? 'italic line-through opacity-50' : ''}>{value}</span>}
            to={props.to?.(data)}
          />
        ),
      },
      {
        title: 'Client',
        model: 'reference',
        type: 'profile',
      },
      {
        title: 'Category',
        model: 'category',
        formatValue: ({ data, value }: any) => <MessageStatus status={value} className={data.is_expired ? 'opacity-50' : ''} />,
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'messages.edit',
      },
      {
        title: 'Description',
        model: 'description',
        type: 'rich_text',
      },
      {
        title: 'Link',
        model: 'link',
        type: 'external_url',
      },
      {
        title: 'Expires On',
        model: 'expires_at',
        type: 'date',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
    ]
  }, [props.to])

  const data = React.useMemo(() => {
    if (!props.data) return []

    return sortBy(props.data, 'order')
  }, [props.data])

  return (
    <DataTable
      asCard
      emptyStateMinHeight={120}
      {...props}
      data={data}
      title={title}
      icon="message_board"
      columns={columns}
      filtersConfig={FILTERS}
      updateDeleteEndpoint="/messages"
    />
  )
}

export const FILTERS = {
  title: {
    label: 'Title',
    type: 'string',
  },
  category: {
    label: 'Category',
    type: 'multi_select',
    options: [
      { label: 'Note', value: 'note' },
      { label: 'Info', value: 'info' },
      { label: 'Warning', value: 'warning' },
      { label: 'Alert', value: 'alert' },
    ],
  },
  tags: DEFAULT_FILTERS.tags,
  reference: {
    label: 'Client',
    type: 'multi_object',
    endpoint: '/residents',
    apiKey: 'clients',
    glyph: 'user_neutral',
    showAvatar: true,
    polymorphic: true,
    selectTitle: (data: any) => data.name,
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  link: {
    label: 'Link',
    type: 'string',
  },
  expires_at: {
    label: 'Expires On',
    type: 'date_time',
  },
  // author: {
  //   ...DEFAULT_FILTERS.author,
  //   label: 'Author',
  // },
}

export const ClientsMessageBoardDataTable = withOverlayError(RootClientsMessageBoardDataTable)
