import React from 'react'
import { DateTime } from 'luxon'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PayerNetworkStatus from '../../components/Statuses/PayerNetworkStatus'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Textarea from '../../components/Forms/Textarea'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

import { useCommunityEditor } from './useCommunityEditor'

const RootCommunityProfileInsurancePayerOverlay = (props: any) => {
  const { entityId } = props

  const setUpdatedAt = useCommunityEditor((store) => store.setUpdatedAt)

  const { isNew, close, saveWithData, form, data, onValidationUpdate, isOverlayLoading, initialModel, isInvalid, isSaving } = useOverlay({
    name: 'entity_insurance_payer',
    endpoint: `/community/entity_insurance_payers`,
    invalidate: 'entity_insurance_payers',
    invalidateKeys: ['community-entity', 'entity_insurance_payers'],
    isEditable: true,
    options: props,
    closeOnSave: true,
    onSaveSuccessful: (newData: any) => {
      setUpdatedAt(DateTime.now().toMillis())
    },
  })

  const { timezone, isEHRApp } = useSettings()

  const [imported, setImported] = React.useState<any>(null)

  const [name, setName] = React.useState(initialModel?.name || '')
  const [description, setDescription] = React.useState(initialModel?.description || '')
  const [networkStatus, setNetworkStatus] = React.useState(initialModel?.network_status || 'out_of_network')

  const handleSave = () => {
    saveWithData({
      name,
      description,
      network_status: networkStatus,

      ...(isNew && { entity_id: entityId }),
      ...(isNew && imported && { reference_type: imported.type, reference_id: imported.id }),
    })
  }

  if (isOverlayLoading) return <OverlayLoader position="center" maxWidth={40} />

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" maxWidth={40} onClose={close}>
      <Overlay.Header title="Insurance Payer" icon="bank_building" />

      <Overlay.Content>
        <Section>
          <Form
            // isCompact
            isEditable
            key={imported?.id}
            getForm={form}
            initialModel={initialModel}
            timezone={timezone}
            onValidationUpdate={onValidationUpdate}
            className="grid gap-4"
          >
            <FormSection maxWidth="100%">
              {isEHRApp && (
                <SummonOverlay
                  overlay={
                    <InsurancePayerSelectorOverlay
                      onSelect={(data: any) => {
                        setImported(data)

                        setName(data.name)
                        setNetworkStatus(data.network_status)
                      }}
                    />
                  }
                >
                  <div>
                    <Button label="Copy from Insurance Payers…" icon="bank_building" size={300} type="default" display="inline-flex" />
                  </div>
                </SummonOverlay>
              )}

              <Input
                label="Name"
                value={name}
                onUpdate={({ value }) => {
                  setName(value)
                }}
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <RadioGroup
                label="Network Status"
                value={networkStatus}
                onUpdate={({ value }) => {
                  setNetworkStatus(value)
                }}
                layout="vertical-dense"
              >
                <Radio label="Out Of Network" value="out_of_network" />
                <Radio label="In Network" value="in_network" />
              </RadioGroup>

              <Textarea
                label="Description"
                value={description}
                onUpdate={({ value }) => {
                  setDescription(value)
                }}
              />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save Insurance Payer"
          glyph="tick_circle"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={isSaving}
          isDisabled={!name}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const InsurancePayerSelectorOverlay = (props: any) => {
  const { onClose, onSelect } = props

  const tableProps = useDataTable({
    name: ['insurance-payers-selector-overlay'],
    endpoint: '/insurance_local_payers',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            onClick={
              onSelect
                ? () => {
                    onSelect(data)
                    onClose?.()
                  }
                : undefined
            }
          />
        ),
      },
      {
        title: 'Network Status',
        model: 'network_status',
        formatValue: ({ value }: any) => <PayerNetworkStatus status={value} />,
      },
    ],
    [],
  )

  return (
    <Overlay showBackdrop closeOnBackdrop maxWidth={80} position="center" onClose={onClose}>
      <Overlay.Header title="Select Insurance Payer" icon="bank_building" />

      <Overlay.Content>
        <DataTable {...tableProps} title="Insurance Payers" icon="bank_building" columns={columns} />
      </Overlay.Content>
    </Overlay>
  )
}

export const CommunityProfileInsurancePayerOverlay = withOverlayError(RootCommunityProfileInsurancePayerOverlay)
