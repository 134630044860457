import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../theme'

const GridTableRow: React.FC<any> = React.forwardRef((props, ref) => {
  const { children, className, paddingY = '0.4rem', paddingX = '0.5rem', ...rest } = props

  return (
    <div
      data-test="grid_table_row"
      ref={ref}
      css={styles}
      className={className}
      style={{
        ...rest.style,
        '--padding-y': paddingY,
        '--padding-x': paddingX,
      }}
      {...rest}
    >
      {children}
    </div>
  )
})

const styles = {
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'var(--template-columns)',
  borderBottom: `1px solid ${COLORS.divider}`,

  '.use-equal-columns &': {
    // gridTemplateColumns: 'none',
    gridAutoColumns: 'var(--columns-width)',
  },

  '.use-banding &:nth-child(even) > *': {
    background: `${tint(0.25, COLORS.lightBackground)}`,
  },

  '.use-row-hover &:hover > *': {
    // background: `${tint(0.9, COLORS.blue)}`,
    background: COLORS.hover,
  },

  '&:last-of-type': {
    borderBottom: 'none',
  },
}

export default GridTableRow
