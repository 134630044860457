import React from 'react'

import HotkeyScopes from '../modules/hotkey_scopes'

const useHotkeys = (scope, hotkeysConfiguration, skip = false) => {
  const mounted = React.useRef(null)

  React.useEffect(() => {
    mounted.current = true
    if (!skip) HotkeyScopes.add(scope, hotkeysConfiguration)

    return () => {
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(
    () => () => {
      if (!mounted.current && !skip) HotkeyScopes.remove(scope)
    },
    [scope, skip, hotkeysConfiguration],
  )
}

export default useHotkeys
