import React from 'react'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import FormSection from '../../../../Forms/FormSection'
import Grid from '../../../../Grid'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const MedicalSystemsReview = () => {
  return (
    <Grid gap={16}>
      <FormSection layout="vertical">
        <CheckboxGroup label="Constitutional:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.constitutional.none" />
          <Checkbox label="Flu-like illness" model="data.medical_systems_review.constitutional.flu_like_illness" />
          <Checkbox label="Dysphoria" model="data.medical_systems_review.constitutional.dysphoria" />
          <Checkbox label="Craving" model="data.medical_systems_review.constitutional.craving" />
          <Checkbox label="Sweats" model="data.medical_systems_review.constitutional.sweats" />
          <Checkbox label="Chills" model="data.medical_systems_review.constitutional.chills" />
          <Checkbox label="Insomnia" model="data.medical_systems_review.constitutional.insomnia" />
          <Checkbox label="Fatigue" model="data.medical_systems_review.constitutional.fatigue" />
          <Checkbox label="Weight loss" model="data.medical_systems_review.constitutional.weight_loss" />
          <Checkbox label="Weight gain" model="data.medical_systems_review.constitutional.weight_gain" />
          <Checkbox label="Other" model="data.medical_systems_review.constitutional.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.constitutional.other" is={true}>
          <SmartTextarea useDictation label="Other Constitutional:" model="data.medical_systems_review.constitutional.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection layout="vertical">
        <CheckboxGroup label="Eyes:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.eyes.none" />
          <Checkbox label="Tearing" model="data.medical_systems_review.eyes.tearing" />
          <Checkbox label="Photophobia" model="data.medical_systems_review.eyes.photophobia" />
          <Checkbox label="Other" model="data.medical_systems_review.eyes.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.eyes.other" is={true}>
          <SmartTextarea useDictation label="Other Eyes:" model="data.medical_systems_review.eyes.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection layout="vertical">
        <CheckboxGroup label="ENT:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.ent.none" />
          <Checkbox label="Rhinorrhea" model="data.medical_systems_review.ent.rhinorrhea" />
          <Checkbox label="Sneezing" model="data.medical_systems_review.ent.sneezing" />
          <Checkbox label="Nasal congestion" model="data.medical_systems_review.ent.nasal_congestion" />
          <Checkbox label="Dental pain/problems" model="data.medical_systems_review.ent.dental_pain_problems" />
          <Checkbox label="Other" model="data.medical_systems_review.ent.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.ent.other" is={true}>
          <SmartTextarea useDictation label="Other ENT:" model="data.medical_systems_review.ent.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection layout="vertical">
        <CheckboxGroup label="Cardiovascular:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.cardiovascular.none" />
          <Checkbox label="Palpitations" model="data.medical_systems_review.cardiovascular.palpitations" />
          <Checkbox label="Tachycardia" model="data.medical_systems_review.cardiovascular.tachycardia" />
          <Checkbox label="Murmur" model="data.medical_systems_review.cardiovascular.murmur" />
          <Checkbox label="Heart disease" model="data.medical_systems_review.cardiovascular.heart_disease" />
          <Checkbox label="Chest pain" model="data.medical_systems_review.cardiovascular.chest_pain" />
          <Checkbox label="Other" model="data.medical_systems_review.cardiovascular.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.cardiovascular.other" is={true}>
          <SmartTextarea useDictation label="Other Cardiovascular:" model="data.medical_systems_review.cardiovascular.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection layout="vertical">
        <CheckboxGroup label="Respiratory:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.respiratory.none" />
          <Checkbox label="Asthma" model="data.medical_systems_review.respiratory.asthma" />
          <Checkbox label="Dyspena" model="data.medical_systems_review.respiratory.dyspena" />
          <Checkbox label="COPD" model="data.medical_systems_review.respiratory.copd" />
          <Checkbox label="Other" model="data.medical_systems_review.respiratory.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.respiratory.other" is={true}>
          <SmartTextarea useDictation label="Other Respiratory:" model="data.medical_systems_review.respiratory.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection layout="vertical">
        <CheckboxGroup label="Gastrointestinal:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.gastrointestinal.none" />
          <Checkbox label="Nausea" model="data.medical_systems_review.gastrointestinal.nausea" />
          <Checkbox label="Diarrhea" model="data.medical_systems_review.gastrointestinal.diarrhea" />
          <Checkbox label="Abdominal cramping" model="data.medical_systems_review.gastrointestinal.abdominal_cramping" />
          <Checkbox label="GERD" model="data.medical_systems_review.gastrointestinal.gerd" />
          <Checkbox label="Vomiting blood" model="data.medical_systems_review.gastrointestinal.vomiting_blood" />
          <Checkbox label="Blood in stool" model="data.medical_systems_review.gastrointestinal.blood_in_stool" />
          <Checkbox label="Other" model="data.medical_systems_review.gastrointestinal.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.gastrointestinal.other" is={true}>
          <SmartTextarea useDictation label="Other Gastrointestinal:" model="data.medical_systems_review.gastrointestinal.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection layout="vertical">
        <CheckboxGroup label="Genitourinary:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.genitourinary.none" />
          <Checkbox label="Problem voiding" model="data.medical_systems_review.genitourinary.problem_voiding" />
          <Checkbox label="Kidney disease" model="data.medical_systems_review.genitourinary.kidney_disease" />
          <Checkbox label="Other" model="data.medical_systems_review.genitourinary.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.genitourinary.other" is={true}>
          <SmartTextarea useDictation label="Other Genitourinary:" model="data.medical_systems_review.genitourinary.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection layout="vertical">
        <CheckboxGroup label="Musculoskeletal:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.musculoskeletal.none" />
          <Checkbox label="Arthritis" model="data.medical_systems_review.musculoskeletal.arthritis" />
          <Checkbox label="Deformities" model="data.medical_systems_review.musculoskeletal.deformities" />
          <Checkbox label="Chronic pain" model="data.medical_systems_review.musculoskeletal.chronic_pain" />
          <Checkbox
            label="Withdrawal muscle or bone pain"
            model="data.medical_systems_review.musculoskeletal.withdrawal_muscle_or_bone_pain"
          />
          <Checkbox label="Other" model="data.medical_systems_review.musculoskeletal.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.musculoskeletal.other" is={true}>
          <SmartTextarea useDictation label="Other Musculoskeletal:" model="data.medical_systems_review.musculoskeletal.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection layout="vertical">
        <CheckboxGroup label="Skin:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.skin.none" />
          <Checkbox label="Piloerection" model="data.medical_systems_review.skin.piloerection" />
          <Checkbox label="Rash" model="data.medical_systems_review.skin.rash" />
          <Checkbox label="Infection/abscess" model="data.medical_systems_review.skin.infection_abscess" />
          <Checkbox label="Other" model="data.medical_systems_review.skin.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.skin.other" is={true}>
          <SmartTextarea useDictation label="Other Skin:" model="data.medical_systems_review.skin.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection layout="vertical">
        <CheckboxGroup label="Neuro:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.neuro.none" />
          <Checkbox label="Yawning" model="data.medical_systems_review.neuro.yawning" />
          <Checkbox label="Tremor" model="data.medical_systems_review.neuro.tremor" />
          <Checkbox label="Gait" model="data.medical_systems_review.neuro.gait" />
          <Checkbox label="Myoclonic jerks" model="data.medical_systems_review.neuro.myoclonic_jerks" />
          <Checkbox label="Seizures" model="data.medical_systems_review.neuro.seizures" />
          <Checkbox label="Other" model="data.medical_systems_review.neuro.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.neuro.other" is={true}>
          <SmartTextarea useDictation label="Other Neuro:" model="data.medical_systems_review.neuro.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection layout="vertical">
        <CheckboxGroup label="Psych:" layout="vertical-dense">
          <Checkbox label="None" model="data.medical_systems_review.psych.none" />
          <Checkbox label="Restlessness" model="data.medical_systems_review.psych.restlessness" />
          <Checkbox label="Anxiety" model="data.medical_systems_review.psych.anxiety" />
          <Checkbox label="Fatigue and depression" model="data.medical_systems_review.psych.fatigue_and_depression" />
          <Checkbox label="DTS" model="data.medical_systems_review.psych.dts" />
          <Checkbox label="DTO" model="data.medical_systems_review.psych.dto" />
          <Checkbox label="Hallucinations" model="data.medical_systems_review.psych.hallucinations" />
          <Checkbox label="Phono-phobia" model="data.medical_systems_review.psych.phono_phobia" />
          <Checkbox label="Photophobia" model="data.medical_systems_review.psych.photophobia" />
          <Checkbox label="Other" model="data.medical_systems_review.psych.other" />
        </CheckboxGroup>

        <ContextShow when="data.medical_systems_review.psych.other" is={true}>
          <SmartTextarea useDictation label="Other Psych:" model="data.medical_systems_review.psych.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection layout="vertical">
        <RadioGroup label="Allergies" model="data.medical_systems_review.allergies.has_allergies" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.medical_systems_review.allergies.has_allergies" is={true}>
          <SmartTextarea useDictation label="Please explain:" model="data.medical_systems_review.allergies.description" />
        </ContextShow>
      </FormSection>
    </Grid>
  )
}

export default MedicalSystemsReview
