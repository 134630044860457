import React from 'react'

import RichTextEditor from '../../components/Forms/RichTextEditor'
import Section from '../../components/Section'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootProgressNoteOverlay = () => (
  <DataFormOverlay
    title="Progress Note"
    category="progress_note"
    subcategory="progress_note"
    icon="progress_notes"
    requestClientSignature={false}
    requestAuthorSignature={true}
    requireSupervisor={true}
    enableTreatmentPlanSelector
  >
    <>
      <Section
        title="Notes"
        scrollview={{
          id: 'notes',
          name: 'Notes',
        }}
        commentsModel="data.progress_note_notes"
      >
        <RichTextEditor useQuickText maxRows={20} model="data.notes" />
      </Section>
    </>
  </DataFormOverlay>
)

export const ProgressNoteOverlay = withOverlayError(RootProgressNoteOverlay)
