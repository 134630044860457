import React from 'react'

import withPermissions from '../../hocs/withPermissions'

import ContextShow from '../Forms/ContextShow'
import PermissionRow from './PermissionRow'

const PermissionSection = ({ title, icon, models, children, true_value, false_value, warningTooltip }: any) => {
  return (
    <>
      <PermissionRow
        title={title}
        icon={icon}
        models={models}
        true_value={true_value}
        false_value={false_value}
        warningTooltip={warningTooltip}
      />

      {!models?.view && children}

      {models?.view && children && (
        <ContextShow when={models.view} is={true}>
          {children}
        </ContextShow>
      )}
    </>
  )
}

PermissionSection.defaultProps = {
  true_value: true,
  false_value: false,
  permission: true,
  featureFlag: true,
}

export default withPermissions(PermissionSection)
