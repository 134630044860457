import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { apiGet } from '../../../modules/api'
import { usDateTime } from '../../../utils/functions'
import { useAPI, useSettings } from '../../../hooks'
import withSettings from '../../../hocs/withSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Grid from '../../../components/Grid'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import TableFilter from '../../../components/Table/TableFilter'
import Workflow from '../../../components/Workflow/Workflow'

import ApplicationStatus from '../../../components/Statuses/ApplicationStatus'
import ApplicationReviewStatus from '../../../components/Statuses/ApplicationReviewStatus'

import { IntakeApplicationOverlay } from '../../IntakeApplications/IntakeApplicationOverlay'

const onSaveSuccessful = (clientId: any) => {
  apiGet({
    name: 'intake_applications',
    url: `/residents/${clientId}/intake_applications`,
  })
}

const columns = (client: any, match: any, timezone: string) => [
  // {
  //   width: 220,
  //   isSticky: true,
  //   canToggleVisible: false,
  //   accessor: 'applied_at',
  //   Header: 'Date & Time',
  //   Filter: TableFilter.Date,
  //   filter: 'date',
  //   Cell: ({ row, value }: any) => <TableCell.MainLink label={usDateTime(value, timezone)} />,
  // },

  {
    width: 220,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'applied_at',
    Header: 'Date & Time',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value, row }: any) => {
      return (
        <SummonOverlay
          overlay={
            <IntakeApplicationOverlay
              showBackdrop
              dataID={row.original.id}
              match={match}
              onSaveSuccessful={() => onSaveSuccessful(client.id)}
              onDeleteSuccessful={() => onSaveSuccessful(client.id)}
            />
          }
        >
          <TableCell.MainLink label={usDateTime(value, timezone)} />
        </SummonOverlay>
      )
    },
  },
  {
    width: 160,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <ApplicationStatus status={value} />,
  },
  {
    width: 160,
    accessor: 'review_status',
    Header: 'Application Review Status',
    Cell: ({ value }: any) => (value ? <ApplicationReviewStatus status={value} /> : '–'),
  },
  {
    width: 190,
    accessor: 'reminded_at',
    Header: 'Last Reminded At',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

const AddButton = ({ client, match }: any) => {
  return (
    <SummonOverlay
      overlay={
        <IntakeApplicationOverlay
          dataID="new"
          match={match}
          onSaveSuccessful={() => onSaveSuccessful(client.id)}
          onDeleteSuccessful={() => onSaveSuccessful(client.id)}
        />
      }
    >
      <Button label="Add Application" glyph="add" type="primary" display="inline-flex" permission="applications.create" />
    </SummonOverlay>
  )
}

const Applications = ({ client, setStatus }: any) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { isRequired }: any = React.useContext(Workflow.PanelContext)

  const tableData = useSelector((state: any) => state.data?.intake_applications?.data)
  const loading = useSelector((state: any) => state.data?.intake_applications?.loading)
  const isEmpty = size(tableData) === 0

  const { timezone } = useSettings()

  useAPI('intake_applications', `/residents/${resource_id}/intake_applications`, false)

  const onContinue = async () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  if (isEmpty || loading) {
    return (
      <State
        isLoading={loading}
        isEmpty={isEmpty}
        title="Applications"
        icon="applications"
        emptyDescription="No applications completed yet"
        emptyActions={<AddButton client={client} match={match} />}
      />
    )
  }

  return (
    <Grid gap="1rem">
      <Card testKey="applications_table">
        <Table
          title="Applications"
          icon="applications"
          localStorageKey="clients_applications"
          data={tableData}
          columns={columns(client, match, timezone)}
          isLoading={loading}
          showSettings={false}
        />

        <CardContent>
          <AddButton client={client} match={match} />
        </CardContent>
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        {!isRequired && <Workflow.SkipButton onClick={onSkip} />}
      </Workflow.Buttons>
    </Grid>
  )
}

export default withSettings(Applications)
