import React, { Component } from 'react'

import Fuse from 'fuse.js'
import isEqual from 'react-fast-compare'
import clone from 'lodash/clone'

import { MEDIA_QUERY } from '../theme'
import { mapToArray } from '../utils/functions'

import Search from './Search'
import State from './State'

class DataGrid extends Component {
  constructor(props) {
    super(props)

    const data = mapToArray(props.data)
    this.state = {
      origin: clone(props.data),
      search: '',
      data: data,
      results: data,
      showControls: false,
      filters: {},
      activeFilters: {},
    }

    this.fuseOptions = {
      shouldSort: true,
      keys: props.searchKeys,
      threshold: 0.4,
    }

    this.fuse = new Fuse(data, this.fuseOptions)
  }

  /*
    LIFECYCLE
  */
  componentDidUpdate = () => {
    const { data } = this.props
    const { origin, search } = this.state

    if (!isEqual(data, origin)) {
      const arrayData = mapToArray(data)
      let results = arrayData

      this.fuse = new Fuse(arrayData, this.fuseOptions)

      if (search?.length > 0) {
        results = []
        const searchResults = this.fuse.search(search)
        for (let i = 0; i < searchResults.length; i++) results.push(searchResults[i].item)
      }

      this.setState({ origin: clone(data), data: arrayData, results: results })
    }
  }

  /*
    CUSTOM FUNCTIONS
  */
  onSearch = (search: string) => {
    const { data } = this.state

    if (data && data.length === 0) return
    let results = []

    if (search?.length > 0) {
      const searchResults = this.fuse.search(search)
      for (let i = 0; i < searchResults.length; i++) results.push(searchResults[i].item)
    } else {
      results = this.state.data
    }

    this.setState({ results, search })
  }

  /*
    RENDER
  */
  render() {
    const { layout, actions, testKey, render, useSearch, useEmptyState, icon, emptyDescription, emptyActions } = this.props

    const { results } = this.state

    if (!results) return null

    const isEmpty = results.length === 0

    return (
      <>
        {useSearch && (
          <div className="!mb-4">
            <Search testKey={testKey} onChange={this.onSearch} />
          </div>
        )}

        {useEmptyState && isEmpty && (
          <State isEmpty={true} icon={icon} emptyDescription={emptyDescription} emptyActions={emptyActions} minHeight={200} />
        )}

        {/* Content */}
        {!isEmpty && (
          <div css={styles} className={`layout-${layout}`}>
            {results.map((item) => render(item, { actions }))}
          </div>
        )}
      </>
    )
  }
}

const styles = {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: 8,

  '&.layout-grid': {
    [MEDIA_QUERY[1]]: {
      gridTemplateColumns: `repeat(auto-fit, minmax(280px, 1fr))`,
    },
  },
}

DataGrid.defaultProps = {
  layout: 'list',
  title: 'Items',
  searchKeys: ['name', 'title', 'description'],
  useSearch: true,
  useEmptyState: true,
  gap: 8,
  emptyDescription: 'No data found',
}

export default DataGrid
