import React from 'react'

import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
import padStart from 'lodash/padStart'
import produce from 'immer'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { isDefined } from '../../utils/functions'
import { validate } from './validators'
import { withFormContext } from './context'

import BaseInput from './BaseInput'
import Flex from '../Flex'
import Option from './Option'
import Select from './Select'
import Value from '../Value'

import FieldBase from './FieldBase'

const processHour = (hour: any, meridiem: any) => {
  if (isNaN(hour)) return null

  if (meridiem === 'AM') {
    if (hour < 0) return 11
    if (hour > 11) return 0
  } else if (meridiem === 'PM') {
    if (hour > 12) return 1
    if (hour < 1) return 12
  }

  return hour
}

const processMinute = (minute: any) => {
  if (isNaN(minute)) return null
  else if (minute < 0) 59
  else if (minute > 59) 0
  else return minute
}

const allVisualFieldsCompleted = (visual: any) => {
  if (!visual) return false

  return visual.hour.length === 2 && visual.minute.length === 2
}

class TimeInput extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    let timezone = props.timezone

    const vs = props.validations
    let date = DateTime.fromISO(props.value, { zone: timezone })

    if (!date.isValid) {
      const model = props.form?.getField(props.model)
      const initialModel = props.form?.getInitialInputFieldValue(props.model)
      const val = model || initialModel || props.default

      if (val) {
        date = DateTime.fromISO(val, { zone: timezone })
      } else {
        let localTime = DateTime.local()

        if (props.defaultToNow) {
          date = DateTime.fromObject({
            year: 1970,
            month: 1,
            day: 1,
            hour: localTime.hour,
            minute: localTime.minute,
            zone: timezone,
          })
        } else if (props.defaultToInOneHour) {
          localTime = localTime.plus({ hours: 1 })
          date = DateTime.fromObject({
            year: 1970,
            month: 1,
            day: 1,
            hour: localTime.hour,
            minute: localTime.minute,
            zone: timezone,
          })
        }
      }
    }

    const value = date.toISO()
    if (vs) errors = validate(value, vs)

    const meridiem = date.hour >= 12 ? 'PM' : 'AM'
    const finalHour = date.hour - (meridiem === 'PM' && date.hour > 12 ? 12 : 0)

    const hour = isNaN(date.hour) ? '' : padStart(finalHour, 2, '0')
    const minute = isNaN(date.minute) ? '' : padStart(date.minute.toString(), 2, '0')

    this.state = {
      type: 'DATEINPUT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      date: date,
      value: value,
      day: 1,
      month: 1,
      year: 1970,
      hour: date.hour,
      minute: date.minute,
      meridiem: meridiem,
      visual: {
        hour: hour,
        minute: minute,
      },
      smartValue: props.smartDescription ? props.smartDescription(null) : '',
      allowErrors: value ? true : false,
      allFieldsCompleted: value ? true : false,
      isNested: props.isNested || false,
      inFocus: null,
      focused: {
        hour: true,
        minute: true,
      },
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      date: date,
      value: value,
      hour: date.hour,
      minute: date.minute,
      meridiem: meridiem,
      visual: {
        hour: hour,
        minute: minute,
      },
      smartValue: props.smartDescription ? props.smartDescription(null) : '',
      allowErrors: value ? true : false,
      allFieldsCompleted: value ? true : false,
      inFocus: null,
      focused: {
        hour: true,
        minute: true,
      },
    }

    this.updateType = 'DATA'
  }

  /*
    CUSTOM FUNCTIONS
  */
  onChange = (event: any) => {
    const target = event.target

    // if the target is not a number return
    if (target.id !== 'meridiem' && isNaN(parseInt(target.value))) return

    let date = null

    let hour = this.state.hour
    let minute = this.state.minute
    let meridiem = this.state.meridiem

    if (target.id === 'hour') hour = parseInt(target.value)
    else if (target.id === 'minute') minute = parseInt(target.value)
    else if (target.id === 'meridiem') meridiem = target.value

    const hourDefined = !isNaN(hour)
    const minuteDefined = !isNaN(minute)
    const meridiemDefined = isDefined(meridiem)

    if (hourDefined && minuteDefined && meridiemDefined) {
      let meridiemExtra = 0

      const parsedHour = hour

      // add meridiems
      if (meridiem === 'PM' && parsedHour < 12) meridiemExtra = 12
      if (meridiem === 'AM' && parsedHour >= 12) meridiemExtra = -12

      date = DateTime.fromObject({
        year: 1970,
        month: 1,
        day: 1,
        hour: parsedHour + meridiemExtra,
        minute: minute,
        zone: this.props.timezone,
      })

      if (date && date.isValid) {
        this.queue({ type: 'CHANGE_VALUE', value: date.toJSDate() })
      } else {
        this.queue({ type: 'CHANGE_VALUE', value: null })
      }
    }
  }

  processChangeValue = (value: any) => {
    const newState = produce(this.state, (draft: any) => {
      if (value) {
        const time = DateTime.fromJSDate(value, { zone: this.props.timezone })

        draft.hour = time.hour
        draft.minute = time.minute
        draft.meridiem = time.toFormat('a')

        const hourDefined = !isNaN(draft.hour)
        const minuteDefined = !isNaN(draft.minute)
        const meridiemDefined = isDefined(draft.meridiem)

        if (hourDefined && minuteDefined && meridiemDefined) {
          draft.date = DateTime.fromObject({
            year: 1970,
            month: 1,
            day: 1,
            hour: draft.hour,
            minute: draft.minute,
            zone: this.props.timezone,
          })

          draft.allFieldsCompleted = true
          draft.value = draft.date.toISO()

          if (this.props.smartDescription) {
            draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
          }
        } else {
          draft.date = null
          draft.value = null
          draft.allFieldsCompleted = false
        }

        draft.visual = this.getVisualStrings(draft, draft.inFocus)

        draft.isPristine = false
        draft.isDirty = true
      }
    })

    this.setState({
      hour: newState.hour,
      minute: newState.minute,
      meridiem: newState.meridiem,
      visual: newState.visual,
      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      smartValue: newState.smartValue,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })

    return newState
  }

  processFocus = (queueEvent: any) => {
    if (queueEvent.event.target) queueEvent.event.target.select()

    const newState = produce(this.state, (draft: any) => {
      if (queueEvent.event.target.id === 'houe') {
        draft.focused.hour = true
        draft.inFocus = 'hour'
      } else if (queueEvent.event.target.id === 'minute') {
        draft.focused.minute = true
        draft.inFocus = 'minute'
      }

      draft.isTouched = true
      draft.isUntouched = true
    })

    this.setState({
      focused: newState.focused,
      inFocus: newState.inFocus,
      isTouched: true,
      isUntouched: true,
    })

    return newState
  }

  processBlur = (_queueEvent: any) => {
    const newState = produce(this.state, (draft: any) => {
      draft.isBlur = true
      draft.isHighlighted = false
      draft.visual = this.getVisualStrings(draft, null)
    })

    this.setState({
      visual: newState.visual,
      isBlur: true,
      isHighlighted: false,
    })

    return newState
  }

  getVisualStrings = (state: any, focus: any) => {
    const finalHour = state.hour - (state.meridiem === 'PM' && state.hour > 12 ? 12 : 0)

    return {
      hour: focus === 'hour' ? finalHour.toString() : padStart(finalHour.toString(), 2, '0'),
      minute: focus === 'minute' ? state.minute.toString() : padStart(state.minute.toString(), 2, '0'),
    }
  }

  onKeyDown = (event: any) => {
    const upOrDownPressed = event.keyCode === 38 || event.keyCode === 40
    if (!upOrDownPressed) return

    const newState = produce(this.state, (draft: any) => {
      if (event.keyCode === 40) {
        // DOWN
        if (event.target.id === 'hour') {
          draft.hour = processHour(draft.hour - 1, draft.meridiem)
        } else if (event.target.id === 'minute') {
          draft.minute = processMinute(draft.minute - 1)
        }
      } else if (event.keyCode === 38) {
        // UP
        if (event.target.id === 'hour') {
          draft.hour = processHour(draft.hour + 1, draft.meridiem)
        } else if (event.target.id === 'minute') {
          draft.minute = processMinute(draft.minute + 1)
        }
      }

      const hourDefined = !isNaN(parseInt(draft.hour))
      const minuteDefined = !isNaN(parseInt(draft.minute))
      const meridiemDefined = isDefined(draft.meridiem)

      if (hourDefined && minuteDefined && meridiemDefined) {
        let meridiemExtra = 0

        const parsedHour = parseInt(draft.hour)

        // add meridiems
        if (draft.meridiem === 'AM' && parsedHour === 12) meridiemExtra = -12
        else if (draft.meridiem === 'PM' && parsedHour !== 12) meridiemExtra = 12

        draft.date = DateTime.fromObject({
          year: 1970,
          month: 1,
          day: 1,
          hour: parsedHour + meridiemExtra,
          minute: parseInt(draft.minute),
          zone: this.props.timezone,
        })

        draft.allFieldsCompleted = true
        draft.value = draft.date.toISO()

        if (this.props.smartDescription) {
          draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
        }
      } else {
        draft.date = null
        draft.value = null
        draft.allFieldsCompleted = false
      }

      draft.visual = this.getVisualStrings(draft, null)

      draft.isPristine = false
      draft.isDirty = true
    })

    this.setState({
      hour: newState.hour,
      minute: newState.minute,
      meridiem: newState.meridiem,
      visual: newState.visual,
      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      smartValue: newState.smartValue,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })

    if (this.props.validateOn?.includes('blur')) this.queue({ type: 'VALIDATE', value: newState.value })

    // update Form Engine
    if (this.props.form && this.props.model) this.props.form.change(newState)
  }

  /*
    RENDER
  */
  editRender = () => (
    <Flex className="TimeInput" alignItems="center" gap={2} nowrap>
      <BaseInput
        id="hour"
        type="text"
        placeholder="HH"
        value={this.state.visual?.hour || ''}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        size={2}
      />
      <BaseInput
        id="minute"
        type="text"
        placeholder="MM"
        value={this.state.visual?.minute || ''}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        size={2}
      />
      <Select id="meridiem" defaultValue="AM" value={this.state.meridiem} onChange={this.onChange}>
        <Option label="AM" value="AM" />
        <Option label="PM" value="PM" />
      </Select>
    </Flex>
  )

  readOnlyRender = () => <Value value={this.state.date?.isValid ? this.state.date.toFormat('hh:mma') : DEFAULT_EMPTY_VALUE} />
}

TimeInput.defaultProps = {
  isEditable: true,
  validateOn: 'blur-change',
}

export default withFormContext(TimeInput)
