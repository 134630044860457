import React from 'react'
import { connect } from 'react-redux'

import Button from '../Button'
import Card from '../Card'
import Checkbox from '../Forms/Checkbox'
import Form from '../Forms/Form'
import GridTable from '../GridTable'
import Icon from '../Icon'
import Overlay from '../Overlay'
import Section from '../Section'
import Type from '../Typography/Type'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../Overlays/pages/OverlayBase'

import {
  GENERAL_FEATURES,
  ERP_FEATURES,
  CONTACTS_FEATURES,
  CHAT_FEATURES,
  CARE_COORDINATION_FEATURES,
  RCM_FEATURES,
  INSURANCE_FEATURES,
  NOTES_FEATURES,
  MEDICAL_FEATURES,
  CLINICAL_FEATURES,
  SECURITY_FEATURES,
} from './constants'

import withRouterV6 from '../../hocs/withRouterV6'

const FeatureRows = ({ label, features, products }: any) => {
  if (!(features && products)) return null

  return (
    <>
      <GridTable.Row>
        <GridTable.Cell css={styles.featureGroupCell}>
          <Type as="div" variant="CAPS_TITLE_SMALL">
            {label}
          </Type>
        </GridTable.Cell>
      </GridTable.Row>

      {features.map((feature: any) => (
        <GridTable.Row key={feature.model}>
          <GridTable.Cell css={styles.featureCell}>
            <Icon icon={feature.icon?.id || 'default'} size={20} />
            <div css={styles.featureTitle}>{feature.title}</div>
          </GridTable.Cell>

          {products.map((product: any) => (
            <GridTable.Cell key={product.id} css={styles.checkboxCell}>
              <Checkbox trueIcon="check" falseIcon="cross" falseStyle="faded" model={`${product.id}.${feature.model}`} />
            </GridTable.Cell>
          ))}
        </GridTable.Row>
      ))}
    </>
  )
}

const PRODUCTS: any = [
  {
    id: 'b0122529',
    name: 'LITE',
    icon: 'behave_health',
    created_at: '2021-07-06T06:30:42.474-04:00',
    description: 'Plan Description',
    features_count: '12 features',
    pricing_strategy: 'Monthly Subscription',
    price: 'Free',
    discount: null,
  },
  {
    id: 'aa3c81c5',
    name: 'PLUS',
    icon: 'behave_health',
    created_at: '2021-07-06T06:30:42.474-04:00',
    description: 'Plan Description',
    features_count: '22 features',
    pricing_strategy: 'Monthly Subscription',
    price: '$12',
    priceSuffix: ' / month',
    discount: null,
  },
  {
    id: '91e1a921',
    name: 'PRO',
    icon: 'behave_health',
    created_at: '2021-07-06T06:30:42.474-04:00',
    description: 'Plan Description',
    features_count: '32 features',
    pricing_strategy: 'Monthly Subscription',
    price: '$15',
    priceSuffix: ' / month',
    discount: null,
  },
]

class ProductFeaturesOverlay extends OverlayBase {
  render() {
    const { $new, $editable, isInvalid, params } = this.state
    const { record, current, online, timezone, loading } = this.props

    return (
      <Overlay showBackdrop closeOnBackdrop={!$editable} onClose={this.close} position="center" maxWidth={PRODUCTS.length * 20}>
        <Overlay.Header title="Product Features" />

        <Overlay.Content css={styles.overlayContent}>
          <Section>
            <Form getForm={this.form} isEditable={$editable} onValidationUpdate={this.onValidationUpdate}>
              <Card>
                <GridTable firstColumnSticky templateColumns={`220px repeat(${PRODUCTS.length}, minmax(140px, 1fr)`}>
                  <GridTable.Header>
                    <GridTable.Cell>Features Comparison</GridTable.Cell>

                    {PRODUCTS.map((product) => (
                      <GridTable.Cell key={product.id} css={styles.productHeader}>
                        <Type as="div" variant="CAPS_TITLE_SMALL">
                          {product.name}
                        </Type>

                        <div>
                          <Type as="span" variant="H3">
                            {product.price}
                          </Type>

                          {product.priceSuffix && (
                            <Type as="span" variant="TEXT_SMALL" color="textMuted">
                              {' '}
                              {product.priceSuffix}
                            </Type>
                          )}
                        </div>
                      </GridTable.Cell>
                    ))}
                  </GridTable.Header>

                  <FeatureRows label="General" features={GENERAL_FEATURES} products={PRODUCTS} />
                  <FeatureRows label="ERP" features={ERP_FEATURES} products={PRODUCTS} />
                  <FeatureRows label="Contacts" features={CONTACTS_FEATURES} products={PRODUCTS} />
                  <FeatureRows label="Chat" features={CHAT_FEATURES} products={PRODUCTS} />
                  <FeatureRows label="Care Coordination" features={CARE_COORDINATION_FEATURES} products={PRODUCTS} />
                  <FeatureRows label="RCM" features={RCM_FEATURES} products={PRODUCTS} />
                  <FeatureRows label="Insurance" features={INSURANCE_FEATURES} products={PRODUCTS} />
                  <FeatureRows label="Notes" features={NOTES_FEATURES} products={PRODUCTS} />
                  <FeatureRows label="Medical" features={MEDICAL_FEATURES} products={PRODUCTS} />
                  <FeatureRows label="Clinical" features={CLINICAL_FEATURES} products={PRODUCTS} />
                  <FeatureRows label="Security" features={SECURITY_FEATURES} products={PRODUCTS} />
                </GridTable>
              </Card>
            </Form>
          </Section>
        </Overlay.Content>

        <Overlay.Footer online={online}>
          {$editable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                onClick={() => this.setState({ $editable: false })}
                isLoading={loading}
                isDisabled={isInvalid}
              />
              <Button label="Cancel" glyph="cross" onClick={this.cancel} isDisabled={loading} />
            </>
          )}

          {!$editable && <Button label="Edit" glyph="edit" onClick={this.edit} flex="100 1 auto" />}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const styles = {
  productHeader: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    // minWidth: 140,
  },

  featureGroupCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    gridColumn: '1 / -1',
  },

  featureCell: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingLeft: '1rem',

    img: {
      marginRight: '0.5rem',
    },
  },

  featureTitle: {
    fontWeight: 500,
  },

  checkboxCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  overlayContent: {
    zIndex: 0,
  },
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'billing_tier_features')

export default connect(mapStateToProps, mapDispatchToProps)(ProductFeaturesOverlay)

export const ProductFeaturesOverlayV6 = withRouterV6(connect(mapStateToProps, mapDispatchToProps)(ProductFeaturesOverlay))
