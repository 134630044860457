import React from 'react'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Status from '../../../components/Status'
import Text from '../../../components/Typography/Text'
import SummonOverlay from '../../../components/SummonOverlay'

import { CustomTermsList } from './CustomTermsList'
import { ReviewSection } from './ReviewSection'
import { SigningOverlay } from './SigningOverlay'

import { usDateTime } from '../../../utils/functions'
import { useCreate } from '../../../hooks/useNewAPI'
import { QuoteIframeButton } from '../../QuoteElements/QuoteIframeButton'

export const Signing = ({ isPublic, isOpen, data, didSign, setDidSign, canViewQuoteDetails }: any) => {
  const { id, external_id } = data

  const timezone = data?.timezone || data?.facility?.timezone

  const { mutateAsync: signQuote, isLoading: isSigningQuote }: any = useCreate({
    name: ['sign-quote'],
    url: isPublic ? `/apps/quotes/${external_id}/sign` : `/quotes/${id}/sign`,
    invalidate: 'quotes',
  })

  if (!data) return null

  return (
    <ReviewSection
      isOpen={isOpen}
      data={data}
      title="Signing"
      icon="signature"
      titleAfter={<QuoteIframeButton data={data} model="signing" />}
      sectionModel="signing"
    >
      {!canViewQuoteDetails ? (
        <Alert glyph="info">Please complete the previous sections to view the signing details</Alert>
      ) : (
        <div className="grid gap-4 w-full max-w-[500px]">
          {didSign ? (
            <div className="flex items-center">
              <Status label="Completed" glyph="tick_circle" color="green" className="mr-2" />
              <Text glyph="date" label="Signed On:" description={usDateTime(data.signing_date, timezone)} />
            </div>
          ) : (
            <Status label="Action Required" glyph="warning" color="orange" />
          )}

          <CustomTermsList data={data} />

          <SummonOverlay
            overlay={
              <SigningOverlay
                didSign={didSign}
                data={data}
                isLoading={isSigningQuote}
                onSign={async (data: any) => {
                  try {
                    await signQuote(data)

                    setDidSign(true)
                  } catch (error) {
                    console.error(error)
                  }
                }}
              />
            }
          >
            <Button label={didSign ? 'Review Signing Details' : 'Review & Sign'} type="primary" size={200} glyph="view" />
          </SummonOverlay>
        </div>
      )}
    </ReviewSection>
  )
}
