import React from 'react'
import { useLocation } from 'react-router-dom'

import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const RootBARC10Overlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Brief Assessment of Recovery Capital (BARC-10)"
      category="clinical_measurement"
      subcategory="barc10"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <Questionnaire />
      </Section>

      <Divider />

      <Section
        title="More Info"
        headingType="h2"
        scrollview={{
          id: 'more_info',
          name: 'More Info',
        }}
      >
        <p>
          <b>Background & Rationale for the Measure:</b> Remission from substance use disorder (SUD) is increasingly recognized as a process
          that results in, and is supported by, the accrual of personal, social, environmental, and cultural resources that aid the recovery
          journey. Collectively, these resources have been termed, “recovery capital”. Recovery capital represents the quantity and quality
          of internal and external resources that can be brought to bear to initiate and sustain recovery from SUD. The accrual of recovery
          capital is theoretically important because greater assets will influence resiliency and coping, and help mitigate the high burden
          of biological and psychological stress associated with the adaptation to abstinence and remission from SUD. The significance of
          the construct of recovery capital has led to the development and testing of psychometrically and conceptually sound assessment
          tools. For example, a recovery capital assessment tool (Assessment of Recovery Capital: ARC; Groshkova, Best, & White, 2013) was
          validated recently showing good psychometric properties and consisted of 50 items representing 10 subscales. The addition of a
          briefer version of the ARC, could increase its adoption and implementation in busy clinical and recovery support service settings
          by increasing speed of administration and scoring.
        </p>
        <p>
          <b>What this Measure is & How it can Help:</b> Recovery capital is a construct central to the substance use disorder treatment and
          recovery field and the brief assessment of recovery capital was operationalized in a 10-item measure. The BARC-10 is a
          strength-based measure that is completed via self-report to assess the level of broader personal, social, physical, and
          professional resources in an individual’s environment that are used to initiate and sustain recovery including structural supports
          such as a recovery-supportive living space and community relationships. Item response modeling was used for the scale reduction
          phase and independent validation due to its wide acceptance as the gold standard for refining and reducing the length of existing
          scales. The findings indicate that the abbreviated 10-item measure of recovery capital captured item representation from all 10
          original subscales, was invariant across participant’s geographic locality, gender, had high internal consistency (α = .90), and
          concurrent validity with the original measure (rpb =.90). As such, the briefer BARC10 may serve as a potentially helpful
          additional tool for researchers and theoreticians to explain how individuals achieve recovery, and clinicians may find the BARC-10
          helpful in establishing care plans and ranking priorities in ongoing client support. There is additional utility in settings where
          brevity is valued such as health care systems, electronic health records, as well as peer-to-peer recovery support services.
        </p>
        <p>
          <b>BARC-10 Original and Validation Samples:</b> Items were pooled from the original treatment samples from Scotland and Australia
          (N=450) for scale reduction. Sixty-six percent were male, 34% had a primary alcohol use disorder, the average age was 35. A
          reduced version of the BARC-10 was validated in an independent sample (N=123), recruited online from a recovery website in which
          43% were male, 64% had a primary alcohol use disorder, the average age was 51, 50% had used medical detoxification, and the
          average length of time in recovery was 7 years.
        </p>
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <ol>
          <li>
            VILSAINT, Corrie L., KELLY, John F., BERGMAN, Brandon G., GROSHKOVA, Teodora, BEST, David
            &lt;http://orcid.org/0000-0002-6792-916X&gt; and WHITE, William
          </li>
          <li>
            Available from Sheffield Hallam University Research Archive (SHURA) at:
            <a target="_blank" href="http://shura.shu.ac.uk/15835/">
              http://shura.shu.ac.uk/15835/
            </a>
          </li>
        </ol>

        <p>
          <b>Copyright and re-use policy:</b> See{' '}
          <a target="_blank" href="http://shura.shu.ac.uk/information.html">
            http://shura.shu.ac.uk/information.html
          </a>
        </p>
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState({
    has_more_important_things_than_using_substances: 0,
    is_generally_happy_with_life: 0,
    has_enough_energy_for_tasks: 0,
    is_proud_of_belonging_community: 0,
    has_friends_support: 0,
    has_fulfilling_life: 0,
    did_living_space_help_recovery: 0,
    does_take_responsibility_for_actions: 0,
    is_happy_dealing_with_professional_people: 0,
    is_making_good_recovery_progress: 0,
  })

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <FormSection>
        <RadioGroup
          label="1. There are more important things to me in life than using substances"
          model="data.questionnaire.has_more_important_things_than_using_substances"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, has_more_important_things_than_using_substances: state.value }))}
        >
          <Radio label="1 = Strongly Disagree" value={1} />
          <Radio label="2 = Disagree Somewhat" value={2} />
          <Radio label="3 = Disagree" value={3} />
          <Radio label="4 = Somewhat Agree" value={4} />
          <Radio label="5 = Agree Strongly" value={5} />
          <Radio label="6 = Agree" value={6} />
        </RadioGroup>

        <RadioGroup
          label="2. In general I am happy with my life"
          model="data.questionnaire.is_generally_happy_with_life"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, is_generally_happy_with_life: state.value }))}
        >
          <Radio label="1 = Strongly Disagree" value={1} />
          <Radio label="2 = Disagree Somewhat" value={2} />
          <Radio label="3 = Disagree" value={3} />
          <Radio label="4 = Somewhat Agree" value={4} />
          <Radio label="5 = Agree Strongly" value={5} />
          <Radio label="6 = Agree" value={6} />
        </RadioGroup>

        <RadioGroup
          label="3. I have enough energy to complete the tasks I set for myself"
          model="data.questionnaire.has_enough_energy_for_tasks"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, has_enough_energy_for_tasks: state.value }))}
        >
          <Radio label="1 = Strongly Disagree" value={1} />
          <Radio label="2 = Disagree Somewhat" value={2} />
          <Radio label="3 = Disagree" value={3} />
          <Radio label="4 = Somewhat Agree" value={4} />
          <Radio label="5 = Agree Strongly" value={5} />
          <Radio label="6 = Agree" value={6} />
        </RadioGroup>

        <RadioGroup
          label="4. I am proud of the community I live in and feel a part of it"
          model="data.questionnaire.is_proud_of_belonging_community"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, is_proud_of_belonging_community: state.value }))}
        >
          <Radio label="1 = Strongly Disagree" value={1} />
          <Radio label="2 = Disagree Somewhat" value={2} />
          <Radio label="3 = Disagree" value={3} />
          <Radio label="4 = Somewhat Agree" value={4} />
          <Radio label="5 = Agree Strongly" value={5} />
          <Radio label="6 = Agree" value={6} />
        </RadioGroup>

        <RadioGroup
          label="5. I get lots of support from friends"
          model="data.questionnaire.has_friends_support"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, has_friends_support: state.value }))}
        >
          <Radio label="1 = Strongly Disagree" value={1} />
          <Radio label="2 = Disagree Somewhat" value={2} />
          <Radio label="3 = Disagree" value={3} />
          <Radio label="4 = Somewhat Agree" value={4} />
          <Radio label="5 = Agree Strongly" value={5} />
          <Radio label="6 = Agree" value={6} />
        </RadioGroup>

        <RadioGroup
          label="6. I regard my life as challenging and fulfilling without the need for using drugs or alcohol"
          model="data.questionnaire.has_fulfilling_life"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, has_fulfilling_life: state.value }))}
        >
          <Radio label="1 = Strongly Disagree" value={1} />
          <Radio label="2 = Disagree Somewhat" value={2} />
          <Radio label="3 = Disagree" value={3} />
          <Radio label="4 = Somewhat Agree" value={4} />
          <Radio label="5 = Agree Strongly" value={5} />
          <Radio label="6 = Agree" value={6} />
        </RadioGroup>

        <RadioGroup
          label="7. My living space has helped to drive my recovery journey"
          model="data.questionnaire.did_living_space_help_recovery"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, did_living_space_help_recovery: state.value }))}
        >
          <Radio label="1 = Strongly Disagree" value={1} />
          <Radio label="2 = Disagree Somewhat" value={2} />
          <Radio label="3 = Disagree" value={3} />
          <Radio label="4 = Somewhat Agree" value={4} />
          <Radio label="5 = Agree Strongly" value={5} />
          <Radio label="6 = Agree" value={6} />
        </RadioGroup>

        <RadioGroup
          label="8. I take full responsibility for my actions"
          model="data.questionnaire.does_take_responsibility_for_actions"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, does_take_responsibility_for_actions: state.value }))}
        >
          <Radio label="1 = Strongly Disagree" value={1} />
          <Radio label="2 = Disagree Somewhat" value={2} />
          <Radio label="3 = Disagree" value={3} />
          <Radio label="4 = Somewhat Agree" value={4} />
          <Radio label="5 = Agree Strongly" value={5} />
          <Radio label="6 = Agree" value={6} />
        </RadioGroup>

        <RadioGroup
          label="9. I am happy dealing with a range of professional people"
          model="data.questionnaire.is_happy_dealing_with_professional_people"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, is_happy_dealing_with_professional_people: state.value }))}
        >
          <Radio label="1 = Strongly Disagree" value={1} />
          <Radio label="2 = Disagree Somewhat" value={2} />
          <Radio label="3 = Disagree" value={3} />
          <Radio label="4 = Somewhat Agree" value={4} />
          <Radio label="5 = Agree Strongly" value={5} />
          <Radio label="6 = Agree" value={6} />
        </RadioGroup>

        <RadioGroup
          label="10. I am making good progress on my recovery journey"
          model="data.questionnaire.is_making_good_recovery_progress"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, is_making_good_recovery_progress: state.value }))}
        >
          <Radio label="1 = Strongly Disagree" value={1} />
          <Radio label="2 = Disagree Somewhat" value={2} />
          <Radio label="3 = Disagree" value={3} />
          <Radio label="4 = Somewhat Agree" value={4} />
          <Radio label="5 = Agree Strongly" value={5} />
          <Radio label="6 = Agree" value={6} />
        </RadioGroup>
      </FormSection>

      {!isPortal && (
        <>
          <Divider />

          <Score title="Final Score" finalScore={finalScore}>
            <p>
              <b>Scoring for the BARC-10:</b> Total scores can range from a minimum of 10 to a maximum of 60. A ROC Curve Analysis showed
              the BARC-10 had predictive validity with sustained remission (i.e., 1 year or more) using a cut-off score of 47 in a sample
              whose average length of recovery time was 7 years.
            </p>
          </Score>
        </>
      )}
    </>
  )
}

export const BARC10Overlay = withOverlayError(RootBARC10Overlay)
