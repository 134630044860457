import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  collaboratemd: {
    label: 'CollaborateMD',
    color: 'green',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus glyph="insurance_information" glyphColor="black" statuses={statuses} {...rest} />
}

export default Status
