import React from 'react'
import dompurify from 'dompurify'

import InputSelector from '../Selectors/InputSelector/InputSelector'

const sanitize = dompurify.sanitize

const RevenueCodeSelector = ({ label = 'Revenue Codes', model = 'revenue_code', ...rest }) => (
  <InputSelector
    showAvatars={false}
    modelSelector="code"
    label={label}
    model={model}
    type="insurance_codes.revenue_code"
    selectTitle={(data, highlights) => {
      let title = data?.code
      if (!highlights) return title

      for (let i = 0; i < highlights?.length; i++) {
        if (highlights[i].field === 'code') {
          title = highlights[i].snippet
          break
        }
      }

      return <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
    }}
    selectDescription={(data, highlights) => {
      let description = data?.description
      if (!highlights) return description

      for (let i = 0; i < highlights?.length; i++) {
        if (highlights[i].field === 'description') {
          description = highlights[i].snippet
          break
        }
      }

      return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
    }}
    {...rest}
  />
)

export default RevenueCodeSelector
