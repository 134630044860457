import React from 'react'

import { useSettings } from '../../hooks'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'service_episodes',
    Header: 'Authorized Service Episodes',
    width: 350,
    Cell: ({ value, row }: any) => {
      return (
        <TableCell.MainLink
          to={to(row.original.id)}
          label={!value || value?.length === 0 ? '–' : value?.map?.((o: any) => o?.name)?.join(', ')}
        />
      )
    },
  },
  {
    width: 150,
    accessor: 'identifier',
    Header: 'Authorization #',
  },
  {
    width: 150,
    accessor: 'sessions',
    Header: 'Sessions Authorized',
  },
  {
    width: 100,
    accessor: 'used_sessions',
    Header: 'Sessions Used',
  },
  {
    width: 170,
    accessor: 'notes',
    Header: 'Notes',
  },
  {
    width: 80,
    accessor: 'started_at',
    Header: 'Started On',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 80,
    accessor: 'ended_at',
    Header: 'Ending On',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  selectActions?: Function
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const InsuranceAuthorizationsTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, localStorageKey, selectActions, batchActionsConfig, hiddenColumns, titleAfter } = props
  const { timezone } = useSettings()

  return (
    <Table
      showFilters
      title="Authorizations"
      testKey="authorizations_table"
      titleAfter={titleAfter}
      icon="checklist"
      data={data}
      columns={columns(to, timezone)}
      hiddenColumns={hiddenColumns}
      selectActions={selectActions}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No authorizations added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

InsuranceAuthorizationsTable.defaultProps = {
  localStorageKey: 'authorizations',
}

export default InsuranceAuthorizationsTable
