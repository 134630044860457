import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useNavigate, useParams, useLocation } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { COLORS } from '@behavehealth/theme'
import { countWord } from '@behavehealth/utils/functions'
import { useCreate, useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Avatar from '@behavehealth/components/Avatar'
import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import CardTreeItem from '@behavehealth/components/CardTreeItem'
import DeleteDialog from '@behavehealth/components/Dialogs/DeleteDialog'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Flex from '@behavehealth/components/Flex'
import Glyph from '@behavehealth/components/Glyph'
import Grid from '@behavehealth/components/Grid'
import Icon from '@behavehealth/components/Icon'
import Loader from '@behavehealth/components/Loader'
import Page from '@behavehealth/components/Page'
import State from '@behavehealth/components/State'

const ProgramPlan: React.FC = () => {
  const { resource_id }: any = useParams()

  const queryKey = ['program', resource_id, 'phases-with-seats']

  const { data, isLoading }: any = useGet({
    name: queryKey,
    url: `/programs/${resource_id}/phases_with_seats`,
    options: { enabled: !!resource_id, keepPreviousData: true },
  })

  const phasesCount = size(data)
  const isPhasesEmpty = data && phasesCount === 0

  const { mutate: deleteList, isLoading: isDeletingList } = useDelete({
    name: ['location', resource_id, 'delete-list'],
    url: `/phases`,
    invalidate: queryKey,
  })

  const { mutate: deleteSeat, isLoading: isDeletingSeat } = useDelete({
    name: ['location', resource_id, 'delete-seat'],
    url: `/seats`,
    invalidate: queryKey,
  })

  const actions = (
    <>
      {phasesCount >= 2 && (
        <Button as={Link} label="Reorder Lists" glyph="move_up_down" type="default" link="reorder" permission="programs.edit" />
      )}

      <Button as={Link} label="Add List" glyph="add" type="primary" link="new" permission="programs.edit" />
    </>
  )

  return (
    <Page icon="program_plan" title="Program Plan" plan="lite" actions={actions}>
      {isPhasesEmpty ? (
        <Card>
          <State
            isEmpty={isPhasesEmpty}
            isLoading={isLoading}
            emptyActions={actions}
            icon="program_lists"
            title="Program Lists"
            emptyDescription="This program has no lists yet"
          />
        </Card>
      ) : (
        <Grid gap="1rem">
          {/* PHASES */}
          {data?.map((phase: any) => {
            const seatsCount = size(phase.seats)
            const isSeatsEmpty = seatsCount === 0
            const isPhaseDeletable = phase?.is_deletable

            return (
              <CardTreeItem
                isOpen
                key={phase.id}
                title={`${phase.name} (${seatsCount})`}
                titleBefore={<Icon icon="program_lists" size={18} css={STYLES.icon} />}
                shouldIndent={false}
                after={
                  <div css={{ marginLeft: 'auto' }}>
                    <DeleteDialog
                      title="Delete List?"
                      message="Are you sure you want to delete this list? This action cannot be undone."
                      onYes={async () => {
                        await deleteList(phase.id)
                      }}
                      isDisabled={!isPhaseDeletable}
                    >
                      <Button
                        label="Delete List"
                        glyph="delete"
                        type="default"
                        size={200}
                        color="red"
                        display="inline-flex"
                        css={STYLES.headerAction}
                        isLoading={isDeletingList}
                        isDisabled={!isPhaseDeletable}
                      />
                    </DeleteDialog>
                  </div>
                }
                titleAfter={
                  <Button
                    as={Link}
                    hideLabel
                    size={100}
                    type="minimal"
                    glyph="edit"
                    glyphSize={12}
                    css={STYLES.squareButton}
                    onClick={(event) => {
                      event.stopPropagation()
                      event.nativeEvent.stopImmediatePropagation()
                    }}
                    link={phase.id}
                  />
                }
              >
                {size(phase.employees) > 0 && (
                  <div className="px-4  ">
                    <Flex centerY gap="0.5rem" className="border-b border-solid border-0 border-divider pt-2.5 pb-2 text-[0.9rem]">
                      <div css={{ fontWeight: 500, fontSize: '0.94rem' }}>Managing Staff: </div>

                      {phase.employees.map((record: any) => (
                        <div key={record.id}>
                          <Flex centerY gap="0.4rem">
                            <Avatar src={record.avatar} initials={record.name} size={20} />
                            <div>{record.name}</div>
                          </Flex>
                        </div>
                      ))}
                    </Flex>
                  </div>
                )}

                {isSeatsEmpty ? (
                  <State
                    isEmpty
                    title="Seats"
                    glyph="chair"
                    emptyDescription="This List doesn't have Seats yet"
                    emptyActions={
                      <Flex centerY gap="0.5rem">
                        <Button
                          as={Link}
                          label="Add Seat"
                          glyph="add"
                          color="blue"
                          display="inline-flex"
                          size={200}
                          css={{ marginLeft: '1rem', marginTop: '0.25rem', marginBottom: '1.25rem' }}
                          link={`${phase.id}/seats/new`}
                        />

                        <QuickAddButton phaseId={phase.id} invalidate={queryKey} />
                      </Flex>
                    }
                  />
                ) : (
                  <>
                    <div css={{ padding: '1rem', display: 'grid', gridGap: '0.75rem' }}>
                      <div>
                        {phase.seats.map((seat: any) => (
                          <NavLink key={seat.id} css={STYLES.row} to={`${phase.id}/seats/${seat.id}`}>
                            <Flex centerY nowrap gap="0.5rem">
                              <Glyph glyph="chair" size={18} />
                              <div>{seat.name}</div>
                            </Flex>
                          </NavLink>
                        ))}

                        <div className="!mt-2">
                          <Flex centerY gap="0.5rem">
                            <Button
                              as={Link}
                              label="Add Seat"
                              glyph="add"
                              color="blue"
                              display="inline-flex"
                              size={200}
                              link={`${phase.id}/seats/new`}
                            />

                            <QuickAddButton phaseId={phase.id} invalidate={queryKey} />
                          </Flex>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </CardTreeItem>
            )
          })}
        </Grid>
      )}
    </Page>
  )
}

const QuickAddButton = ({ phaseId, invalidate }: any) => {
  const { resource_id: programId }: any = useParams()

  const { mutateAsync: batchCreateSeats, isLoading: isCreatingSeats }: any = useCreate({
    name: ['phase', phaseId, 'bulk-create-seats'],
    url: `/phases/${phaseId}/bulk_create_seats`,
    invalidate: invalidate,
    invalidateKeys: ['programs-timeline', 'program-timeline', ['program', programId, 'phases-with-residents']],
  })

  if (!programId || !phaseId) return null

  if (isCreatingSeats) {
    return (
      <Flex centerY gap="0.4rem">
        <Loader />
        <div>Creating Seats…</div>
      </Flex>
    )
  }

  return (
    <Dropdown label="Quick Add" glyph="quick_view" buttonSize={200} isLoading={isCreatingSeats}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => (
        <DropdownItem
          glyph="chair"
          label={countWord('Seat', count)}
          onClick={() => {
            batchCreateSeats({ amount: count })
          }}
        />
      ))}
    </Dropdown>
  )
}

const STYLES = {
  icon: {
    marginRight: '0.4rem',
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
    fontWeight: 400,
    padding: '0.3rem 0.75rem',
    borderTop: `1px solid ${COLORS.divider}`,
    color: COLORS.text,

    '&:first-of-type': {
      borderTop: 'none',
    },

    '&:hover': {
      fontWeight: 600,
      color: COLORS.blue,
      background: COLORS.white,
      // borderRadius: '4px 0 0 4px',
      // boxShadow: `0 0 0 1px ${COLORS.divider}`,
    },

    '&.is-active': {
      fontWeight: 600,
      color: COLORS.blue,
      background: COLORS.white,
      boxShadow: `inset 3px 0 0 ${COLORS.blue}`,
    },
  },

  headerAction: {
    marginLeft: 'auto',
  },

  seatTitle: {
    fontSize: '0.9rem',
    fontWeight: 600,
    color: COLORS.text,
  },

  squareButton: {
    width: 24,
    minWidth: 24,
    height: 24,
    minHeight: 24,
    marginLeft: '0.4rem',
  },
}

export default withPageError(ProgramPlan)
