import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../theme'
import Glyph from './Glyph'

type Props = {
  isCompleted: boolean
  isLoading: boolean
  onClick: Function
} & React.HTMLAttributes<any>

const CheckButton: React.FC<Props> = ({ isCompleted, isLoading, onClick, className }) => {
  const classes = clsx({
    'is-completed': isCompleted,
    'is-loading': isLoading,
    [className]: className,
  })

  return (
    <button css={styles} onClick={onClick} className={classes}>
      <Glyph glyph="check" color="transparent" />
    </button>
  )
}

const styles: any = {
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
  flex: '0 0 auto',

  padding: 0,
  position: 'relative',

  border: 'none',
  background: COLORS.white,

  outline: 'none',
  cursor: 'pointer',

  marginRight: '8px !important',

  svg: {
    background: COLORS.white,
    transition: 'all 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    width: 18,
    height: 18,
    padding: 2,
    borderRadius: 4,
    boxShadow: `0 0 0 1px ${COLORS.lightGray}`,
  },

  '&:hover svg': {
    fill: COLORS.green,
    transform: 'scale3d(1.08, 1.08, 1.08)',
  },

  '&:active': {
    background: COLORS.hover,

    svg: {
      transform: 'scale3d(0.95, 0.95, 0.95)',
    },
  },

  '&.is-completed svg': {
    fill: `${COLORS.white} !important`,
    background: COLORS.green,
    boxShadow: `0 0 0 1px ${COLORS.green}`,
  },

  '&.is-loading svg': {
    fill: `${COLORS.white} !important`,
    background: COLORS.green,
    boxShadow: `0 0 0 1px ${COLORS.green}`,
    opacity: 0.4,
    transform: 'scale3d(0.95, 0.95, 0.95)',
  },

  // increase hit area
  '&::before': {
    content: '""',
    position: 'absolute',
    width: 32,
    height: 32,
  },
}

export default CheckButton
