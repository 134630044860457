import React from 'react'

import { age, usDate } from '../../../utils/functions'
import { DataTable } from '../../../components/DataTable/DataTable'
import { useSettings } from '../../../hooks/useSettings'

import Accordions from '../../../components/Accordions'
import DataList from '../../../components/DataList'

import { ReferralColumn } from '../components/ReferralColumn'

export const ClientDetails = (props: any) => {
  const {
    isNew,
    data,
    setData,
    timezone,
    initialData,
    isEditable,
    setFormValid,
    client,
    setClient,
    partners,
    setPartners,
    partner,
    setPartner,
  } = props

  const { tenant } = useSettings()

  const handleUpdate = (value: any) => {
    setData(value)
  }

  return (
    <ReferralColumn icon="clients" title="Client Details">
      <Accordions>
        {/* <Accordions.Item isOpen size={200} icon="referrals" title="Referral Details">
          <DataList>
            <DataList.Item
              label="Sent From"
              value={
                <div className="flex flex-nowrap items-center">
                  <Avatar src="" size={20} initials={tenant.name} className="mr-1.5" /> {tenant.name}
                </div>
              }
            />
            <DataList.Item
              label="Sent By"
              value={
                <div className="flex flex-nowrap items-center">
                  <Avatar src="" size={20} initials={initialData.author.name} className="mr-1.5" /> {initialData.author.name}
                </div>
              }
            />
            <DataList.Item
              label="Sent To"
              value={
                <div className="flex flex-nowrap items-center">
                  <Avatar src="" size={20} initials={partner.other_partner.name} className="mr-1.5" /> {partner.other_partner.name}
                </div>
              }
            />
            <DataList.Item label="Requested First Appointment Date" value={usDateTime(data.referred_at)} />
            <DataList.Item label="Notes" value={<div className="whitespace-pre">{data.notes}</div>} />
          </DataList>
        </Accordions.Item> */}

        <Accordions.Item isOpen size={200} icon="general_info" title="General Info">
          <DataList>
            <DataList.Item label="Client Full Name" value={client.name} />
            <DataList.Item label="Date of Birth" value={usDate(client.dob)} />
            <DataList.Item label="Age" value={`${age(client.dob)} y/o`} />
          </DataList>
        </Accordions.Item>

        <Accordions.Item minimal size={200} icon="sell" title="Insurance Information">
          <DataTable title="Insurance Information" icon="sell" data={[]} columns={[]} />
        </Accordions.Item>

        <Accordions.Item minimal size={200} icon="medications" title="Medications">
          <DataTable title="Medications" icon="medications" data={[]} columns={[]} />
        </Accordions.Item>

        <Accordions.Item minimal size={200} icon="treatment_data" title="Allergies">
          <DataTable title="Allergies" icon="treatment_data" data={[]} columns={[]} />
        </Accordions.Item>

        <Accordions.Item minimal size={200} icon="contacts" title="Emergency Contacts">
          <DataTable title="Emergency Contacts" icon="contacts" data={[]} columns={[]} />
        </Accordions.Item>

        {/* <Accordions.Item isOpen size={200} minimal icon="referrals" title="Referral Updates">
          <ReferralUpdatesDataTable data={data.updates} />
        </Accordions.Item> */}
      </Accordions>
    </ReferralColumn>
  )
}
