import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const RootBillLines = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting', 'bill_lines'],
    endpoint: `/accounting/resource_lines?resource_type=bill`,
    // params: {...},
    localStorageKey: 'accounting_bill_lines_v1',
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Vendor',
        model: 'resource_ref_name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.resource_ref_name} />,
      },
      {
        title: 'Description',
        model: 'description',
        width: 350,
        disableHide: true,
      },
      {
        title: 'Quantity',
        model: 'quantity',
        width: 160,
        disableHide: true,
        type: 'number',
      },
      {
        title: 'Unit Amount',
        model: 'unit_amount',
        width: 160,
        disableHide: true,
        type: 'number',
      },
      {
        title: 'Sub Total',
        model: 'sub_total',
        width: 250,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Total Amount',
        model: 'total_amount',
        width: 250,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Tax Amount',
        model: 'tax_amount',
        width: 250,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Discount Percentage',
        model: 'discount_percentage',
        width: 250,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Discount Amount',
        model: 'discount_amount',
        width: 250,
        disableHide: true,
        type: 'amount',
      },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Bill Lines">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Bill Lines"
          emptyDescription="No bill lines have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          icon="accounting"
          columns={columns}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

export const BillLines = withPageError(RootBillLines)

const FILTERS_CONFIG = {
  resource_ref_name: {
    label: 'Vendor Invoice Number',
    type: 'string',
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  quantity: {
    label: 'Quantity',
    type: 'string',
  },
  unit_amount: {
    label: 'Unit Amount',
    type: 'string',
  },
  sub_total: {
    label: 'Sub Total',
    type: 'string',
  },
  total_amount: {
    label: 'Total Amount',
    type: 'string',
  },
  tax_amount: {
    label: 'Tax Amount',
    type: 'string',
  },
  discount_amount: {
    label: 'Discount Amount',
    type: 'string',
  },
  discount_percentage: {
    label: 'Discount Percentage',
    type: 'string',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'date_time',
  },
}
