import React from 'react'
import { Link, NavLink, useParams } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { apiUpdate } from '@behavehealth/modules/api'
import useAPI from '@behavehealth/hooks/useAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Alert, Button, Card, Form, Grid, HelpTagIframe, Page, Radio, RadioGroup } from '@behavehealth/components'

import AccessListTable from '@behavehealth/components/Tables/AccessListTable'

const pageConfig = {
  feature: 'staff_clients_access',
  title: 'Staff Access',
  help: <HelpTagIframe id="settings_staff_access" />,
}

const AccessList: React.FC = () => {
  const { id }: any = useParams()

  const form = React.useRef()

  const loading = useSelector((state: any) => state.data?.access_controls?.loading)
  const data: any = useSelector((state: any) => state.data?.access_controls?.data)

  const employee: any = useSelector((state: any) => state.data?.employees?.data?.[id])
  const loadingEmployee: any = useSelector((state: any) => state.data?.employees?.loading)

  useAPI('access_controls', `/employees/${id}/access_controls`)

  const isEmpty = size(data) === 0

  if (!(data && employee)) return <Page isLoading {...pageConfig} />

  const hasFullAccess = employee.access_level === 'full'
  const isOwner = employee.position === 'owner'

  return (
    <Page
      actions={
        !hasFullAccess && (
          <Button as={Link} label="Add Clients" type="primary" glyph="add" permission="access_controls.actions.import" link={`import`} />
        )
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        {isOwner && (
          <Alert contrast glyph="info">
            Owners have full access to all clients by default. This setting cannot be changed.
          </Alert>
        )}

        {!isOwner && (
          <>
            <Form getForm={form} initialModel={{ access_level: employee.access_level }}>
              <RadioGroup
                label="Client Access Level"
                layout="vertical-dense"
                model="access_level"
                isDisabled={loadingEmployee}
                onChange={({ value }: any) => apiUpdate({ name: 'employees', url: `/employees/${id}`, params: { access_level: value } })}
              >
                <Radio label="Full access to All Clients" value="full" />
                <Radio label="Restricted access to selected Clients only" value="restricted" />
              </RadioGroup>
            </Form>

            {!hasFullAccess && (
              <Card>
                <AccessListTable mainLinkAs={NavLink} data={data} isLoading={isEmpty && loading} to={(id: string) => `/clients/${id}`} />
              </Card>
            )}
          </>
        )}
      </Grid>
    </Page>
  )
}

export default withMarketing(AccessList, pageConfig)
