import React from 'react'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { countWord } from '../../utils/functions'
import { useSearchStore } from './store'

import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'

import { SearchResult } from './SearchResult'

export const SearchResults: React.FC<any> = ({ results, close, isLoading }) => {
  const searchInput = useSearchStore((state: any) => state.searchInput)
  const setSearchInput = useSearchStore((state: any) => state.setSearchInput)

  const resultsView = useSearchStore((state: any) => state.resultsView)
  const setResultsView = useSearchStore((state: any) => state.setResultsView)

  const resultsCount = size(results)
  const hasResults = resultsCount > 0
  const hasEnoughInput = size(searchInput) >= 2

  return (
    <div css={styles.root}>
      <header css={styles.header}>
        <div css={styles.headerTitle}>{hasEnoughInput && !isLoading && countWord('Results', resultsCount)}</div>

        <div css={styles.buttonsGroup}>
          <Button
            label="Compact"
            onClick={() => setResultsView('compact')}
            type={resultsView === 'compact' ? 'primary' : 'default'}
            color="text"
            size={100}
          />

          <Button
            label="Details"
            onClick={() => setResultsView('details')}
            type={resultsView === 'details' ? 'primary' : 'default'}
            color="text"
            size={100}
          />
        </div>
      </header>

      {hasEnoughInput && hasResults ? (
        <>
          {results.map((result) => (
            <>
              {result.document.type === 'resident' && (
                <SearchResult key={result.document.type + result.document.id} close={close} result={result} />
              )}
            </>
          ))}
        </>
      ) : (
        <Flex css={{ justifyContent: 'center', alignItems: 'center', padding: '5rem 0' }}>
          <Flex vertical alignItems="center" css={styles.notFound}>
            <Glyph glyph="search" color={COLORS.textMuted} size={24} css={{ marginBottom: '0.5rem', opacity: 0.5 }} />
            <p css={{ margin: 0, opacity: 0.6 }}>Search for applicants, clients and alumni</p>
          </Flex>
        </Flex>
      )}
    </div>
  )
}

const styles = {
  root: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.25rem 0.75rem',

    minHeight: 'var(--header-height)',
    borderBottom: `1px solid ${COLORS.divider}`,
    background: COLORS.white,
  },

  headerTitle: {
    fontWeight: 600,
  },

  notFound: {},

  buttonsGroup: {
    display: 'flex',
    flexWrap: 'nowrap',

    '& > *:first-of-type': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    '& > *:last-of-type': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}
