import React from 'react'
import dompurify from 'dompurify'

import MultiSearchPillSelector from '../Selectors/MultiSearchObject/MultiSearchPillSelector'

const sanitize = dompurify.sanitize

const ModifierCodesSelector = ({ label = 'Modifier Codes', model = 'modifier_codes', ...rest }) => (
  <MultiSearchPillSelector
    isRelations={false}
    showAvatars={false}
    label={label}
    model={model}
    type="insurance_codes.modifier_code"
    selectTitle={(data, highlights) => {
      let title = data?.code
      if (!highlights) return title

      for (let i = 0; i < highlights?.length; i++) {
        if (highlights[i].field === 'code') {
          title = highlights[i].snippet
          break
        }
      }

      return <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
    }}
    selectDescription={(data, highlights) => {
      let description = data?.description
      if (!highlights) return description

      for (let i = 0; i < highlights?.length; i++) {
        if (highlights[i].field === 'description') {
          description = highlights[i].snippet
          break
        }
      }

      return <span dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
    }}
    {...rest}
  />
)

export default ModifierCodesSelector
