import React from 'react'
import { connect } from 'react-redux'
import pluralize from 'pluralize'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { ICONS } from '../../../../theme'
import { SERVICE_CATEGORIES } from '../../../../utils/constants'
import { apiCreate, apiGet } from '../../../../modules/api'
import { RECURRING_DAYS, RECURRING_FREQUENCIES } from '../../../../utils/recurrence'

import { niceAmount, usDate, isPast, plural, DT } from '../../../../utils/functions'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import { niceOccurrenceText } from './utils'

import Alert from '../../../Alert'
import Button from '../../../Button'
import Chotomate from '../../../Chotomate'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import Grid from '../../../Grid'
import Overlay from '../../../Overlay'
import Section from '../../../Section'
import Status from '../../../Status'

import AmountInput from '../../../Forms/AmountInput'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import DateInput from '../../../Forms/DateInput'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Option from '../../../Forms/Option'
import Select from '../../../Forms/Select'
import Textarea from '../../../Forms/Textarea'
import ContextShow from '../../../Forms/ContextShow'
import Radio from '../../../Forms/Radio'
import RadioGroup from '../../../Forms/RadioGroup'

import BuilderPayersSection from './components/BuilderPayersSection'
import PreviewRecurringChargesSection from './components/PreviewRecurringChargesSection'

import { OverlayBase, defaultMapDispatchToProps, defaultMapStateToProps } from '../OverlayBase'

const FREQUENCIES = {
  weekly: 'recurring_weekly_due_day',
  biweekly: 'recurring_biweekly_due_day',
  monthly: 'recurring_monthly_due_day',
}

class ChargeBuilderOverlay extends OverlayBase {
  save = async () => {
    const { current } = this.props

    await apiCreate({
      name: 'financial_transactions',
      url: `/financial_transactions/charge_builder`,
      params: this.form.current.getFormValue(),
    })

    apiGet({
      name: 'financial_transactions_stats',
      url: `/${plural(current.type)}/${current.id}/financial_transactions/stats`,
    })

    this.close()
  }

  renderHeader = () => <Overlay.Header icon="financials" title="Add Charges" />

  renderContent = () => {
    const { formData, service } = this.state
    const { current, timezone } = this.props

    const employees = service?.employees
    const organizations = service?.organizations

    const isRecurring = service?.is_recurring
    const hasEmployees = size(employees) > 0
    const hasOrganizations = size(organizations) > 0
    const lastDayOfRecurrence =
      isRecurring &&
      formData &&
      formData?.transactioned_at &&
      formData?.end_in_months &&
      DT(formData?.transactioned_at)
        .plus({ months: parseInt(formData?.end_in_months) })
        .minus({ days: 1 })

    const dayOfNextRecurrence =
      isRecurring &&
      formData &&
      formData?.transactioned_at &&
      formData?.end_in_months &&
      DT(formData?.transactioned_at).plus({ months: parseInt(formData?.end_in_months) })

    return (
      <Overlay.Content>
        <Chotomate ready name="charge_builder_overlay" />

        <Form
          useFullModel
          getForm={this.form}
          timezone={timezone}
          onValidationUpdate={this.onValidationUpdate}
          onUpdate={(state: any) => {
            this.setState({ formData: state.model })
          }}
          decorate={() => ({
            category: 'charge',
            financial_plan_id: current?.financial_plan?.id,
            end_in_months: 1,
          })}
        >
          {/* General */}
          <Section>
            <FormSection>
              <ObjectSelector
                model="service"
                label="Service"
                type="client_services"
                icon={ICONS.financials}
                selectTitle={(data) => data.name}
                selectDescription={(data) => (
                  <Flex gap={6} alignItems="center">
                    {SERVICE_CATEGORIES[data.category] && <Status label={SERVICE_CATEGORIES[data.category].label} color="green" />}
                    <span>
                      {niceAmount(data.amount)}{' '}
                      {niceOccurrenceText(data?.is_recurring, data?.recurring_frequency, data?.[FREQUENCIES[data?.recurring_frequency]])}
                    </span>
                  </Flex>
                )}
                validations={{
                  presence: {
                    message: 'Please select a service',
                  },
                }}
                emptyActions={
                  <Button
                    label="Manage Services Book"
                    link="/settings/client-services"
                    glyph="settings"
                    featureFlag="services_book"
                    permission="settings.services_book.view"
                  />
                }
                onUpdate={(model: any) => {
                  this.setState({ service: model.object })
                }}
              />

              {!service && <Alert glyph="info">Select a Service above to start</Alert>}

              {service && (
                <>
                  {!formData?.is_custom_amount && (
                    <AmountInput
                      isEditable={false}
                      label="Amount"
                      suffix={niceOccurrenceText(
                        service?.is_recurring,
                        this.state.useCustomFrequency ? formData?.recurring_frequency : service?.recurring_frequency,
                        this.state.useCustomFrequency
                          ? formData?.[FREQUENCIES[formData?.recurring_frequency]]
                          : service?.[FREQUENCIES[service?.recurring_frequency]],
                      )}
                      value={service?.amount}
                    />
                  )}

                  {formData?.is_custom_amount && (
                    <AmountInput
                      debounce={300}
                      label="Custom Amount"
                      model="amount"
                      suffix={niceOccurrenceText(
                        service?.is_recurring,
                        this.state.useCustomFrequency ? formData?.recurring_frequency : service?.recurring_frequency,
                        this.state.useCustomFrequency
                          ? formData?.[FREQUENCIES[formData?.recurring_frequency]]
                          : service?.[FREQUENCIES[service?.recurring_frequency]],
                      )}
                      defaultValue={service?.amount}
                      validations={{
                        presence: {
                          message: 'Please enter an amount',
                        },
                        numericality: {
                          greaterThan: 0,
                          message: 'Please enter an amount',
                        },
                      }}
                    />
                  )}

                  <Flex gap="1rem" stretchChildrenX>
                    <Checkbox label="Set Custom Amount" model="is_custom_amount" />
                    {isRecurring && (
                      <Checkbox
                        label="Set Custom Frequency"
                        model="use_custom_frequency"
                        onUpdate={(model: any) => {
                          this.setState({ useCustomFrequency: model.value })
                        }}
                      />
                    )}
                  </Flex>

                  {this.state?.useCustomFrequency && (
                    <>
                      <RadioGroup
                        label="Repeating Frequency"
                        model="recurring_frequency"
                        layout="vertical-dense"
                        defaultValue={service?.recurring_frequency || 'monthly'}
                        validations={{
                          presence: {
                            message: 'Please select a billing frequency',
                          },
                        }}
                      >
                        {Object.keys(RECURRING_FREQUENCIES).map((key) => (
                          <Radio key={key} label={RECURRING_FREQUENCIES[key].label} value={key} />
                        ))}
                      </RadioGroup>

                      <ContextShow when="recurring_frequency" is="weekly">
                        <Select
                          asNumber
                          label="Weekly Due Day"
                          model="recurring_weekly_due_day"
                          defaultValue={service?.recurring_weekly_due_day || 0}
                          validations={{
                            presence: {
                              message: 'Please select a due day',
                            },
                          }}
                        >
                          {RECURRING_DAYS.weekly.map((key, idx) => (
                            <Option key={idx} label={`Every ${key}`} value={idx} />
                          ))}
                        </Select>
                      </ContextShow>

                      <ContextShow when="recurring_frequency" is="biweekly">
                        <Select
                          asNumber
                          label="Biweekly Due Day"
                          model="recurring_biweekly_due_day"
                          defaultValue={service?.recurring_biweekly_due_day || 0}
                          validations={{
                            presence: {
                              message: 'Please select a due day',
                            },
                          }}
                        >
                          {RECURRING_DAYS.biweekly.map((key, idx) => (
                            <Option key={idx} label={`Every other ${key}`} value={idx} />
                          ))}
                        </Select>
                      </ContextShow>

                      <ContextShow when="recurring_frequency" is="monthly">
                        <Select
                          asNumber
                          label="Monthly Due Day"
                          model="recurring_monthly_due_day"
                          defaultValue={service?.recurring_monthly_due_day || 1}
                          validations={{
                            presence: {
                              message: 'Please select a due day',
                            },
                          }}
                        >
                          {RECURRING_DAYS.monthly.map((key, idx) => (
                            <Option key={idx} label={key} value={idx + 1} />
                          ))}
                        </Select>
                      </ContextShow>
                    </>
                  )}

                  {!isRecurring ? (
                    <>
                      <DateInput label="Charge Date" model="transactioned_at" defaultToNow />
                    </>
                  ) : (
                    <FormSection>
                      <DateInput
                        defaultToNow
                        label="Starting From"
                        model="transactioned_at"
                        validations={{
                          presence: {
                            message: 'Please add a start date',
                          },
                        }}
                      />
                    </FormSection>
                  )}

                  {hasEmployees && (
                    <ObjectSelector
                      model="employees"
                      label="Staff Member"
                      type="employees"
                      apiData={employees}
                      icon={ICONS.employees}
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => startCase(data.position)}
                    />
                  )}

                  {hasOrganizations && (
                    <ObjectSelector
                      model="organizations"
                      label="Organization"
                      type="organizations"
                      apiData={organizations}
                      icon={ICONS.organizations}
                      selectTitle={(data: any) => data.name}
                      selectDescription={() => null}
                    />
                  )}
                </>
              )}
            </FormSection>
          </Section>

          {service && (
            <>
              <Section>
                <Grid gap={8}>
                  <Textarea useQuickText label="Notes" model="notes" />
                </Grid>
              </Section>

              <Divider />

              <Section title="Automatic Collection">
                <FormSection maxWidth="100%">
                  <Grid gap={8}>
                    <CheckboxGroup>
                      <Checkbox
                        label="Use Automatic Payment Collection"
                        model="should_auto_charge"
                        description="Attempt to collect from the Payment Method selected below"
                      />
                    </CheckboxGroup>

                    {formData?.should_auto_charge && (
                      <>
                        {isRecurring ? (
                          <>
                            {isPast(formData?.transactioned_at, timezone) ? (
                              <Alert type="negative" glyph="info">
                                We'll attempt to capture all Back-Dated Charges on Save
                              </Alert>
                            ) : (
                              <Alert type="default" glyph="info">
                                We'll attempt to capture Planned Charges starting from <b>{usDate(formData?.transactioned_at, timezone)}</b>
                              </Alert>
                            )}
                          </>
                        ) : (
                          <>
                            {isPast(formData?.transactioned_at, timezone) ? (
                              <Alert type="negative" glyph="info">
                                <strong>Charge Date</strong> is Today or in the Past. We'll attempt to capture Payment on Save
                              </Alert>
                            ) : (
                              <Alert type="default" glyph="info">
                                We'll attempt to capture Payment on <b>{usDate(formData?.transactioned_at, timezone)}</b>
                              </Alert>
                            )}
                          </>
                        )}
                      </>
                    )}

                    <ContextShow when="should_auto_charge" is={true}>
                      <ObjectSelector
                        icon="billing"
                        type="client.payment_methods"
                        label="Payment Method to Charge"
                        model="payment_method"
                        dependentValue={current?.id}
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => data?.reference?.name}
                        validations={{
                          presence: {
                            message: 'Please select a Payment Method to use',
                          },
                        }}
                      />

                      <Alert type="warning" glyph="info">
                        Any captured payments must be refunded through Stripe
                      </Alert>
                    </ContextShow>
                  </Grid>
                </FormSection>
              </Section>

              <Divider />

              {/* Auto-repeat */}
              {isRecurring && (
                <>
                  <Section title="Recurring Charges">
                    <Checkbox
                      defaultChecked
                      model="repeat"
                      label={`Auto-Repeat "${service?.name}" Charges (Recurring Charges)`}
                      description={
                        <>
                          If this client is not discharged on or before <b>{usDate(lastDayOfRecurrence)}</b> the next batch of charges for{' '}
                          <b>
                            {formData?.end_in_months} {pluralize('month', parseInt(formData?.end_in_months))}
                          </b>{' '}
                          will be created on <b>{usDate(dayOfNextRecurrence)}</b>
                        </>
                      }
                    />
                  </Section>

                  <Divider />

                  <PreviewRecurringChargesSection
                    usingCustomFrequency={this.state.useCustomFrequency}
                    from={formData?.transactioned_at}
                    endIn={formData?.end_in_months}
                    serviceID={service?.id}
                    timezone={timezone}
                    amount={formData?.is_custom_amount ? formData?.amount : service?.amount}
                    onUpdate={(dates) => this.setState({ recurring_dates: dates })}
                    onClear={() => this.setState({ recurring_dates: [] })}
                  />

                  <Divider />
                </>
              )}

              <Section title="Send Notifications">
                <Grid gap={8}>
                  <Alert glyph="notification">Send a notification alert via SMS / Email to the Payer(s) above</Alert>

                  <CheckboxGroup layout="vertical-dense">
                    <Checkbox defaultChecked label="Send SMS Notification" model="should_sms" />
                    <Checkbox defaultChecked label="Send Email Notification" model="should_email" />
                  </CheckboxGroup>
                </Grid>
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const isRecurring = this.state?.service?.is_recurring
    const hasNoCharges = this.state?.recurring_dates?.length === 0

    return (
      <Overlay.Footer>
        <Button
          label="Save Charges"
          glyph="check"
          type="primary"
          color="green"
          flex="100 1 auto"
          onClick={this.save}
          isLoading={this.props.loading}
          isDisabled={this.state.isInvalid || (isRecurring && hasNoCharges)}
          permission="ledger.create"
        />
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state: any, props: any) => defaultMapStateToProps(state, props.match, 'financial_transactions')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(ChargeBuilderOverlay))
