import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import { State } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

import BillingIssues from './pages/billing_issues'
import TrialEnded from './pages/trial_ended'

class Issues extends Component {
  render() {
    const { match } = this.props

    return (
      <State isLoading={false} isEmpty={false} isBlocked={false}>
        <Route path={`${match.url}/billing`} component={BillingIssues} />
        <Route path={`${match.url}/trial-ended`} component={TrialEnded} />
      </State>
    )
  }
}
export default withPageError(Issues)
