import React from 'react'
import startCase from 'lodash/startCase'

import Flex from '../Flex'
import Icon from '../Icon'
import Status from '../Status'
import ClientPortalStatus from '../Statuses/ClientPortalStatus'

import { fullname } from '../../utils/functions'

import CardLinkPhoto from '../CardLinkPhoto'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'

type Props = {
  to: any
  employee: any
  actions: React.ReactNode
}

const Employee = ({ to, employee, actions, showIcon = true, ...rest }: Props) => (
  <CardLinkPhoto showChevron to={to} testKey={fullname(employee)} actions={actions} src={employee.avatar} {...rest}>
    <Flex gap="5">
      {showIcon && <Icon icon="employees" size={16} />}
      <CardTitle title={fullname(employee)} />
    </Flex>
    <CardMeta>
      <Status color="blue" label={startCase(employee.position)} />
      {employee.is_blocked && <Status color="red" label="Blocked" />}
      {employee.is_shared_with_portal && <ClientPortalStatus status="shared" />}
    </CardMeta>
  </CardLinkPhoto>
)

export default Employee
