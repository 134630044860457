import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { OUTCOME_MEASURES_SLUGS } from '@behavehealth/utils/constants'

import { OutcomesSurveyReportDataTable } from '@behavehealth/constructs/LiveReports/OutcomesSurveyReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const OutcomesSurvey = () => {
  const tableProps = useDataTable({
    name: ['reports', 'outcomes_survey'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_outcomes_survey_v1',
    params: { category: 'outcomes_survey' },
  })

  const to = React.useMemo(
    () => (rowData: any) =>
      `${getResourceLink(rowData.client)}/outcome-measures/${OUTCOME_MEASURES_SLUGS?.[rowData?.subcategory]}/${rowData.id}`,
    [],
  )

  return (
    <Page feature="outcome_measures" title="Live Outcome Survey Report">
      <Grid>
        <OutcomesSurveyReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(OutcomesSurvey)
