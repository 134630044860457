import React from 'react'
import Fuse from 'fuse.js'
import produce from 'immer'
import size from 'lodash/size'

import { mapToArray } from '../../../utils/functions'
import { useGet } from '../../../hooks/useNewAPI'
import Avatar from '../../../components/Avatar'

import { FilterCondition } from './FilterCondition'
import { FilterDropdown } from './FilterDropdown'
import { PopoverItem } from './PopoverItem'
import { FilterDropdownSearch } from './FilterDropdownSearch'
import { FilterDropdownValue } from './FilterDropdownValue'

const DEFAULT_SEARCH_KEYS = ['name', 'title', 'description']

const getInitialValue = (data: any, isPolymorphic) => {
  const result = {}

  if (size(data) === 0) return result

  for (const item of data) {
    // TODO: revisit/refactor fix below for:
    // https://linear.app/behave-health/issue/PRO-4574/filter-on-clients-dashboard-gives-oops-error
    if (typeof item === 'string') {
      result[item] = { id: item }
    } else {
      const itemKey = isPolymorphic ? `${item.id}-${item.type}` : item.id
      result[itemKey] = { id: item.id, type: item.type }
    }
  }

  return result
}

export const MultiObjectFilter = (props: any) => {
  const { config, filter, onClear, onUpdate } = props

  const initialValue = getInitialValue(filter?.value, config.polymorphic)

  const [value, setValue]: any = React.useState(initialValue)
  const [searchTerm, setSearchTerm]: any = React.useState('')

  const { data, isLoading } = useGet({
    name: ['filters', config.endpoint, config.apiKey],
    url: config.endpoint,
  })

  const fuse = React.useMemo(() => {
    return new Fuse(data, { keys: config?.searchKeys || DEFAULT_SEARCH_KEYS })
  }, [data, config?.searchKeys])

  const searchData = React.useMemo(() => {
    if (!searchTerm) return data

    let searchResults: any[] = []
    let fuseResults = fuse.search(searchTerm)

    for (let i = 0; i < fuseResults.length; i++) {
      searchResults.push(fuseResults[i].item)
    }

    return searchResults
  }, [fuse, searchTerm])

  const clearSearch = () => {
    setSearchTerm('')
  }

  const activeValues = React.useMemo(() => {
    const result: any = []

    if (size(data) === 0 || size(filter?.value) === 0) return result

    // construct map from data array
    const dataMap: any = {}

    for (const item of data) {
      const itemKey = config?.polymorphic ? `${item.id}-${item.type}` : item.id
      dataMap[itemKey] = item
    }

    // construct active ids
    const activeValuesKeys: string[] = []

    for (const item of filter.value) {
      const itemKey = config?.polymorphic ? `${item.id}-${item.type}` : item
      activeValuesKeys.push(itemKey)
    }

    // construct active items array
    for (const key of activeValuesKeys) {
      if (!dataMap?.[key]) continue

      result.push(dataMap[key])
    }

    return result
  }, [config, data, filter])

  React.useEffect(() => {
    const valueArray = mapToArray(value)

    onUpdate?.({
      condition: 'in',
      value: config?.polymorphic ? valueArray : valueArray.map((o) => o.id),
    })
  }, [value])

  if (!config) return null

  const { polymorphic, selectDescription, selectTitle = (obj: any) => obj.name, showAvatar } = config

  const filtersCount = size(filter?.value)
  const hasActiveValues = size(activeValues) >= 1
  const isDataEmpty = size(data) === 0
  const isSearchEmpty = size(searchData) === 0

  return (
    <>
      <FilterCondition condition={filtersCount >= 2 ? 'is either of' : 'is'} />

      <FilterDropdown
        testKey="multi_object_filter_dropdown"
        isLoading={isLoading}
        isEmpty={isDataEmpty}
        isSearchEmpty={isSearchEmpty}
        activeValues={
          hasActiveValues &&
          activeValues?.map?.((item) => (
            <FilterDropdownValue key={item?.id} value={selectTitle?.(item)} showAvatar={showAvatar} avatar={item.avatar} />
          ))
        }
      >
        <FilterDropdownSearch value={searchTerm} onChange={setSearchTerm} onClear={clearSearch} />

        {searchData?.map?.((item: any) => {
          const itemKey = polymorphic ? `${item.id}-${item.type}` : item.id
          const isActive = !!value?.[itemKey]

          return (
            <PopoverItem
              showSeparator
              key={item.id}
              isActive={isActive}
              title={selectTitle?.(item)}
              description={selectDescription?.(item)}
              graphic={showAvatar && <Avatar src={item.avatar} initials={selectTitle?.(item)} size={22} />}
              onClick={() => {
                setValue((currentValue: any = {}) => {
                  return produce(currentValue, (draft: any) => {
                    if (!!draft?.[itemKey]) {
                      delete draft[itemKey]
                      return
                    }

                    draft[itemKey] = { id: item.id, type: item.type }
                  })
                })
              }}
            />
          )
        })}
      </FilterDropdown>
    </>
  )
}
