import React from 'react'
import { isBrowser } from 'react-device-detect'
import isFunction from 'lodash/isFunction'

import HotkeyScopes from '../modules/hotkey_scopes'

const withHotkeys = (Wrapped, scope, hotkeysConfiguration) => {
  class Wrapper extends React.Component {
    componentDidMount = () => {
      if (isBrowser && this.props.setScopeAutomatically) this.setupHotkeyScope()
    }

    componentWillUnmount = () => {
      if (isBrowser && this.props.setScopeAutomatically) this.destroyHotkeyScope()
    }

    updateHotkeys = () => {
      HotkeyScopes.update()
    }

    setupHotkeyScope = () => {
      let hotkeys = hotkeysConfiguration

      if (isFunction(hotkeysConfiguration)) hotkeys = hotkeysConfiguration(this.instance)

      this.scope = HotkeyScopes.add(scope, hotkeys, this.instance)
    }

    destroyHotkeyScope = () => {
      HotkeyScopes.remove(this.scope)
    }

    render = () => {
      return (
        <Wrapped
          ref={(ref: any) => (this.instance = ref)}
          setupHotkeyScope={this.setupHotkeyScope}
          destroyHotkeyScope={this.destroyHotkeyScope}
          updateHotkeys={this.updateHotkeys}
          {...this.props}
        />
      )
    }
  }

  Wrapper.defaultProps = {
    setScopeAutomatically: true,
  }

  return Wrapper
}

export default withHotkeys
