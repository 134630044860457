import React from 'react'

import withSettings from '../../../hocs/withSettings'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'
import Textarea from '../../../components/Forms/Textarea'
import ContextShow from '../../../components/Forms/ContextShow'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Divider from '../../../components/Divider'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Workflow from '../../../components/Workflow/Workflow'
import { Text } from '../../../components/Typography'

const DischargeDetails = ({ data, setData, setStatus, timezone, firstName }: any) => {
  const [valid, setValid] = React.useState()

  const { goNext }: any = React.useContext(Workflow.Context)

  React.useEffect(() => {
    valid ? setStatus('completed') : setStatus('todo')
  }, [valid])

  return (
    <Form useLocalModel initialModel={data} onUpdate={setData} onValidationUpdate={setValid} timezone={timezone}>
      <FormSection>
        <DateTimeInput
          defaultToNow
          model="discharged_at"
          label="Discharge Date"
          validations={{
            presence: {
              message: 'Please enter a discharge date',
            },
          }}
        />

        <Select allowEmpty label="Discharge Type" model="discharge_type" layout="vertical-dense">
          <Option label="Successfully Completed" value="successfully_completed" />
          <Option label="Therapeutic Discharge" value="therapeutic_discharge" />
          <Option label="AMA" value="ama" />
          <Option label="MIA" value="mia" />
          <Option label="Administrative Discharge" value="administrative_discharge" />
          <Option label="Other" value="other" />
        </Select>

        <ContextShow when="discharge_type" is="other">
          <Textarea useQuickText label="Other Discharge Type" model="other_discharge_type" />
        </ContextShow>

        <Select allowEmpty label="Return Eligibility" model="return_eligibility" layout="vertical-dense">
          <Option label="Eligible" value="eligible" />
          <Option label="Not eligible" value="not_eligible" />
          <Option label="Pending decision" value="pending_decision" />
        </Select>

        <Textarea useQuickText model="discharge_notes" label="Discharge Notes" />

        <Divider />

        <FormSection maxWidth="100%">
          <Text>{`If you check this, ${firstName} won't receive notifications from us anymore`}</Text>

          <CheckboxGroup>
            <Checkbox
              testKey="remove_consent_checkbox"
              label="Remove Consent to Contact from Client"
              model="is_allowed_to_contact"
              value={true}
            />
          </CheckboxGroup>
        </FormSection>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={goNext} isDisabled={!valid} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default withSettings(DischargeDetails)
