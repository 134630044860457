import React from 'react'

import SmartTextarea from '../../../components/Forms/SmartTextarea'

import { MEDICAL_EVALUATION_FIELDS, MENTAL_STATUS_EXAM_FIELDS, VITAL_SIGNS_FIELDS } from './common'

export const NURSING_REASSESSMENT_SECTIONS = [
  {
    title: 'Vital Signs',
    model: 'vital_signs',
    fields: VITAL_SIGNS_FIELDS,
  },
  {
    title: 'Medical Evaluation',
    model: 'medical_evaluation',
    fields: MEDICAL_EVALUATION_FIELDS,
  },
  {
    title: 'Mental Status Exam',
    model: 'mental_status_exam',
    fields: MENTAL_STATUS_EXAM_FIELDS,
  },
  {
    title: 'Summary',
    model: 'summary',
    fields: [
      {
        label: 'Interpretive Summary',
        model: 'interpretive_summary',
        component: () => <SmartTextarea useQuickText useDictation label="Interpretive Summary" model="data.summary.interpretive_summary" />,
      },
      {
        label: 'Additional Relevant Information',
        model: 'additional_relevant_info',
        component: () => (
          <SmartTextarea useQuickText useDictation label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
        ),
      },
      {
        label: 'Recommendations',
        model: 'recommendations',
        component: () => <SmartTextarea useQuickText useDictation label="Recommendations" model="data.summary.recommendations" />,
      },
    ],
  },
]
