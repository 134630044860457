import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../theme'
import { WorkflowContext, WorkflowPanelContext } from './context'

import withPermissions from '../../hocs/withPermissions'
import { usePermissions } from '../../hooks/usePermissions'

const WorkflowPanel = (props: any) => {
  const { children, className, testKey, featureFlag, featureFlagV2, onOpen, permission, step, noPermissionUI } = props

  const { currentStep, setStep, goNext, hiddenSteps, requiredSteps }: any = React.useContext(WorkflowContext)

  const { allowed: hasPermission } = usePermissions({ permission })
  const { allowed: hasFeatureFlag } = usePermissions({ featureFlag: featureFlagV2 || featureFlag })

  const isOpen = currentStep === step
  const isRequired = requiredSteps?.includes?.(step)

  const classNames = clsx({
    'is-open': isOpen,
    [className]: className,
  })

  React.useEffect(() => {
    const request = async () => {
      await onOpen()
    }

    if (isOpen && onOpen) request()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (!hasFeatureFlag) return null

  if (hiddenSteps?.includes?.(step)) return null

  return (
    <WorkflowPanelContext.Provider value={{ step, isRequired, hasPermission, noPermissionUI }}>
      <section data-test={testKey} css={styles} className={classNames}>
        {React.Children.map(children, (child) => {
          if (child.type.displayName === 'WorkflowHeader') {
            return React.cloneElement(child, {
              step,
              isOpen,
              onClick: () => setStep(step),
            })
          } else if (child.type.displayName === 'WorkflowContent') {
            return React.cloneElement(child, {
              isOpen,
              goNext: () => goNext(step),
            })
          }

          return child
        })}
      </section>
    </WorkflowPanelContext.Provider>
  )
}

const styles = {
  '&.is-open': {
    borderBottom: `1px solid ${COLORS.divider}`,
  },
}

export default WorkflowPanel
// export default withPermissions(WorkflowPanel)
