import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import RichTextEditor from '../../components/Forms/RichTextEditor'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'

export const QuickTextOverlay = (props: any) => {
  const {
    id,
    data,
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    isValid,
    save,
  } = useOverlay({
    name: 'quick-paragraph',
    endpoint: '/quick_paragraphs',
    options: props,
  })

  if (isOverlayLoading) return <OverlayLoader position="right" maxWidth={34} />

  return (
    <Overlay maxWidth={34} showBackdrop={isEditable} onClose={close}>
      <Overlay.Header icon="quick_text" title="Quick Text" />

      <Overlay.Content>
        <Section>
          <Form getForm={form} onValidationUpdate={onValidationUpdate} initialModel={initialModel} isEditable={isEditable}>
            <FormSection>
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name',
                  },
                }}
              />

              <Input
                label="Category"
                model="subcategory"
                description="Quick-Text items with the same Category will be displayed together in quick-text menus and the rest under 'Other' category."
              />

              <Select
                label="Visibility"
                model="category"
                defaultValue="personal"
                validations={{
                  presence: {
                    message: 'Please select a visibility level',
                  },
                }}
              >
                <Option label="Personal" value="personal" />
                <Option label="Company" value="company" />
              </Select>

              <RichTextEditor
                label="Quick Text"
                model="notes"
                validations={{
                  presence: {
                    message: 'Please enter some content',
                  },
                }}
              />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
            />

            {!isNew && <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" permission="settings.quick_text.edit" />

            <DeleteDialog
              title="Delete Quick Text?"
              message="Are you sure you want to delete this quick text? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} permission="settings.quick_text.delete" />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}
