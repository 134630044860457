import React from 'react'
import compact from 'lodash/compact'

import { age, titleCase, usDateTime } from '../../../utils/functions'
import { COLORS } from '../../../theme'
import { useSettings } from '../../../hooks/useSettings'
import { useUpdate } from '../../../hooks/useNewAPI'

import Alert from '../../../components/Alert'
import Avatar from '../../../components/Avatar'
import BedOccupancyStatus from '../../../components/Statuses/BedOccupancyStatus'
import Card from '../../../components/Card'
import CardHeader from '../../../components/CardHeader'
import CardSubtitle from '../../../components/CardSubtitle'
import CardTitle from '../../../components/CardTitle'
import DataList from '../../../components/DataList'
import Flex from '../../../components/Flex'
import FormSection from '../../../components/Forms/FormSection'
import Glyph from '../../../components/Glyph'
import Grid from '../../../components/Grid'
import Icon from '../../../components/Icon'
import Workflow from '../../../components/Workflow/Workflow'

const ReviewSave = ({ data, client, close }: any) => {
  const [loading, setLoading] = React.useState(false)

  const { timezone } = useSettings()

  const currentOccupancy = client?.current_bed_occupancy

  const isValid = data?.skip_new_occupancy || !!data?.place

  const { mutateAsync }: any = useUpdate({
    name: ['move-client'],
    url: `/residents/${client?.id}/move`,
    invalidateKeys: [
      'clients-unassigned-stats',
      'location',
      'locations',
      'bed-management-timeline',
      'bed-management-board',
      'rooms-with-residents',
      'occupancy',
      'occupancies',
      'client-to-move',
      ['clients', { unassigned: true }],
    ],
  })

  const moveClient = async () => {
    setLoading(true)

    let accessList = null
    let toAdd: any = []
    let toRemove: any = []

    if (data.staff_access_list) {
      if (data?.staff_access_list?.add) {
        toAdd = Object.entries(data?.staff_access_list?.add).map((arr) => {
          if (arr[1] === true) return arr[0]
        })
      }

      if (data?.staff_access_list?.remove) {
        toRemove = Object.entries(data?.staff_access_list?.remove).map((arr) => {
          if (arr[1] === true) return arr[0]
        })
      }

      accessList = {
        add: compact(toAdd),
        remove: compact(toRemove),
      }
    }

    await mutateAsync({
      cancel_future_charges: data?.cancel_future_charges || null,
      current_bed_occupancy_id: currentOccupancy?.id,
      skip_new_occupancy: data.skip_new_occupancy,
      should_end_current_bed_occupancy: data.should_end_current_bed_occupancy,
      staff_access_list: accessList,

      ...(!data.skip_new_occupancy && {
        place_id: data.place_id,
        place_type: data.place_type,
        started_at: data.started_at,
        ended_at: data.ended_at,
        status: data.status,
        period_type: data.period_type,
      }),

      ...(data.should_end_current_bed_occupancy === false && {
        current_bed_occupancy_ended_at: data.current_bed_occupancy_ended_at,
      }),
    })

    setLoading(false)

    close()
  }

  if (!client) return null

  return (
    <FormSection maxWidth="100%">
      {!isValid && (
        <Alert glyph="warning" type="warning">
          Please select a new bed to move {client.first_name} or skip the bed assignment.
        </Alert>
      )}

      <Grid gap="0.75rem">
        {isValid && currentOccupancy && (
          <>
            <Card baseline="50px">
              <CardHeader graphic={<Avatar initials={client.name} src={client.avatar} size={32} />}>
                <CardTitle title={client.name} className="text-[1.04rem]" />
                <CardSubtitle
                  subtitle={`${titleCase(client.sex) || '–'}, ${age(client.dob)} y/o, #${client.behave_id}`}
                  className="text-[0.95rem]"
                />
              </CardHeader>
            </Card>

            <Card css={{ padding: '0.2rem 0' }} paddingY="0.2rem">
              <Flex centerY gap="0.75rem" css={{ padding: '0.2rem 1rem' }}>
                <Icon icon="beds" />
                <div className="text-[1.1rem] font-[700]">Previous Bed Assignment</div>
              </Flex>

              <DataList isCompact withPadding>
                <DataList.Item label="Status" value={<BedOccupancyStatus status={currentOccupancy.status} />} />

                <DataList.Item
                  label="Location"
                  value={
                    <Flex centerY gap="0.4rem">
                      <Avatar src={currentOccupancy.place?.house.avatar} initials={currentOccupancy.place?.house?.name} size={24} />
                      <div className="!font-[600]">{currentOccupancy.place?.house?.name}</div>
                    </Flex>
                  }
                />

                <DataList.Item
                  label="Bed"
                  value={
                    <Flex centerY gap="0.6rem">
                      <div>
                        <Flex nowrap centerY gap="0.4rem">
                          <Icon icon="floors" size={20} />
                          <div>{currentOccupancy.place?.floor?.name}</div>
                        </Flex>
                      </div>

                      <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                      <div>
                        <Flex nowrap centerY gap="0.4rem">
                          <Icon icon="rooms" size={20} />
                          <div>{currentOccupancy.place?.room?.name}</div>
                        </Flex>
                      </div>

                      <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                      <div>
                        <Flex nowrap centerY gap="0.4rem">
                          <Icon icon="beds" size={20} />
                          <div>{currentOccupancy.place?.name}</div>
                        </Flex>
                      </div>
                    </Flex>
                  }
                />

                <DataList.Item
                  label={currentOccupancy.status === 'occupied' ? 'Occupied From' : 'Reserved From'}
                  value={usDateTime(currentOccupancy.started_at, timezone)}
                />
                <DataList.Item
                  label={currentOccupancy.status === 'occupied' ? 'Occupied Until' : 'Reserved Until'}
                  value={data.should_end_current_bed_occupancy ? 'Now' : usDateTime(data.current_bed_occupancy_ended_at, timezone)}
                />
              </DataList>
            </Card>
          </>
        )}

        {data?.place && !data.skip_new_occupancy && (
          <Card css={{ padding: '0.2rem 0' }} paddingY="0.2rem">
            <Flex centerY gap="0.75rem" css={{ padding: '0.2rem 1rem' }}>
              <Icon icon="beds" />
              <div className="text-[1.1rem] font-[700]">Moving To</div>
            </Flex>

            <DataList isCompact withPadding>
              <DataList.Item label="Status" value={<BedOccupancyStatus status={data.status} />} />

              <DataList.Item
                label="Location"
                value={
                  <Flex centerY gap="0.4rem">
                    <Avatar src={data.place?.house.avatar} initials={data.place?.house?.name} size={24} />
                    <div className="!font-[600]">{data.place?.house?.name}</div>
                  </Flex>
                }
              />

              <DataList.Item
                label="Bed"
                value={
                  <Flex centerY gap="0.6rem">
                    <div>
                      <Flex nowrap centerY gap="0.4rem">
                        <Icon icon="floors" size={20} />
                        <div>{data.place?.floor?.name}</div>
                      </Flex>
                    </div>

                    <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                    <div>
                      <Flex nowrap centerY gap="0.4rem">
                        <Icon icon="rooms" size={20} />
                        <div>{data.place?.room?.name}</div>
                      </Flex>
                    </div>

                    <Glyph glyph="chevron" size={12} color={COLORS.textMuted} />

                    <div>
                      <Flex nowrap centerY gap="0.4rem">
                        <Icon icon="beds" size={20} />
                        <div>{data.place?.name}</div>
                      </Flex>
                    </div>
                  </Flex>
                }
              />

              <DataList.Item
                label={data.status === 'occupied' ? 'Occupied From' : 'Reserved From'}
                value={usDateTime(data.started_at, timezone)}
              />

              <DataList.Item
                label={data.status === 'occupied' ? 'Occupied Until' : 'Reserved Until'}
                value={data.period_type === 'indefinite' ? 'Discharged' : usDateTime(data.ended_at, timezone)}
              />
            </DataList>
          </Card>
        )}
      </Grid>

      <Workflow.Buttons>
        <Workflow.ContinueButton fullWidth label="Save" glyph="check" onClick={moveClient} isDisabled={!isValid} isLoading={loading} />
      </Workflow.Buttons>
    </FormSection>
  )
}

export default ReviewSave
