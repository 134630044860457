import React from 'react'

import Checkbox from '../Checkbox'
import CheckboxGroup from '../CheckboxGroup'
import ContextHide from '../ContextHide'

const MedicalConditionsCheckboxGroup = ({ label, model, isRequired }) => (
  <CheckboxGroup
    label={label}
    layout="vertical-dense"
    validations={
      isRequired && {
        presence: {
          message: 'Please select at least one option',
        },
      }
    }
  >
    <Checkbox label="None Apply" model={`${model}.none`} />
    <ContextHide when={`${model}.none`} is={true}>
      <Checkbox label="Hepatitis A" model={`${model}.hepatitis_a`} />
      <Checkbox label="Hepatitis B" model={`${model}.hepatitis_b`} />
      <Checkbox label="Hepatitis C" model={`${model}.hepatitis_c`} />
      <Checkbox label="Immune System Disorder" model={`${model}.immune_system_disorder`} />
      <Checkbox label="Sexually Transmitted Diseases (STDs)" model={`${model}.sexually_transmitted_diseases`} />
      <Checkbox label="Tuberculosis (TB)" model={`${model}.tuberculosis`} />
      <Checkbox label="Pregnant" model={`${model}.pregnant`} />
    </ContextHide>
  </CheckboxGroup>
)

MedicalConditionsCheckboxGroup.defaultProps = {
  model: 'medical_conditions',
}

export default MedicalConditionsCheckboxGroup
