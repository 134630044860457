import React from 'react'
import { keyframes } from '@emotion/react'
import * as Popover from '@radix-ui/react-popover'
import clsx from 'clsx'
import produce from 'immer'

import { arrayToMapWithKey } from '../../../utils/functions'
import { COLORS, SHADOW } from '../../../theme'
import { usePortal } from '../../../hooks/usePortal'
import { useUpdate, getPrefix } from '../../../hooks/useNewAPI'

import { PopoverMenuItem } from '../../PopoverMenu'
import Card from '../../Card'
import Glyph from '../../Glyph'
import Loader from '../../Loader'

import { DataTableCell } from '../DataTableCell'
import { EmptyCell } from './EmptyCell'
import { useStore } from '../useStore'

export const SelectCell = (props: any) => {
  const { canEdit, invalidate, invalidateKeys, queryKey, record, value, config, column } = props

  const updateId = config?.getId ? config.getId(record) : record?.id
  const updateKey: any = useStore((state: any) => state.updateKey)
  const updateDeleteEndpoint: any = config?.updateDeleteEndpoint || useStore((state: any) => state.updateDeleteEndpoint)

  const [isOpen, setIsOpen] = React.useState(false)

  const portalContainer = usePortal('default')

  const rootClasses = clsx(canEdit && isOpen && 'is-open')

  const toggleOpen = (event) => {
    event.stopPropagation()

    setIsOpen((c) => !c)
  }

  const handleSelect = async (selectValue: any) => {
    if (!selectValue || !column?.model || value === selectValue) return

    await mutateAsync({
      [column.model]: selectValue,
    })

    setIsOpen(false)
  }

  const optionsMap = React.useMemo(() => {
    return arrayToMapWithKey(config?.options, 'value')
  }, [config?.options])

  const displayValue = optionsMap?.[value]?.label

  const { mutateAsync, isLoading: isUpdating }: any = useUpdate({
    name: [updateKey, updateId].flat(),
    url: `${updateDeleteEndpoint}/${updateId}`,
    invalidate: invalidate,
    invalidateKeys: [queryKey, invalidateKeys],
    onSuccess: (_data: any, variables: any, queryClient: any) => {
      if (config?.skipOptimisticUpdate || !queryKey || !updateId || !variables.global_tags) return

      const prefix = getPrefix()

      // update tags in queryKey cache
      queryClient.setQueryData([prefix, queryKey].flat(), (oldData: any) => {
        if (!oldData?.data) return

        const index = oldData.data.findIndex((o) => o.id === updateId)
        if (index === -1) return

        const newData = produce(oldData, (draft: any) => {
          draft.data[index].global_tags = variables.global_tags
        })

        return newData
      })
    },
  })

  return (
    <DataTableCell {...props} className={rootClasses} css={STYLES.cellRoot} onClick={() => setIsOpen(true)}>
      {isUpdating && (
        <div style={{ marginRight: '0.5rem' }}>
          <Loader size={18} color="blue" />
        </div>
      )}

      {displayValue || <EmptyCell />}

      {canEdit && config?.options && (
        <Popover.Root asChild open={isOpen} onOpenChange={setIsOpen}>
          <div css={STYLES.root}>
            <Popover.Trigger asChild>
              <button type="button" onClick={toggleOpen} className="edit-button">
                <Glyph glyph="edit" size={12} />
              </button>
            </Popover.Trigger>

            <Popover.Portal container={portalContainer}>
              <Popover.Content asChild align="start" side="right">
                <div css={STYLES.menu}>
                  <Card css={STYLES.card}>
                    {config?.options.map((option) => (
                      <PopoverMenuItem
                        key={option.value}
                        label={option.label}
                        onClick={() => handleSelect(option.value)}
                        isActive={value === option.value}
                      />
                    ))}
                  </Card>
                </div>
              </Popover.Content>
            </Popover.Portal>
          </div>
        </Popover.Root>
      )}
    </DataTableCell>
  )
}

const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translateY(-12px);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translateY(0);
  }
`

const STYLES = {
  cellRoot: {
    paddingRight: 0,
    color: COLORS.blue,

    '& > .DataTableCell-value': {
      display: 'flex',
      alignItems: 'center',
    },

    '&.can-edit': {
      '&:hover, &.is-open': {
        cursor: 'pointer',
        boxShadow: `inset 0 0 0 1px ${COLORS.divider}`,
        background: COLORS.white,
        overflow: 'visible',
        zIndex: 10,

        '.tags-wrapper': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: 'fit-content',
          padding: '0.25rem var(--padding-x)',
          background: COLORS.white,
          boxShadow: `0 0 0 1px ${COLORS.divider}, ${SHADOW(3)}`,
          borderRadius: 4,
        },

        '.tags-inner': {
          flexWrap: 'wrap',
        },

        '.edit-button': {
          display: 'flex',
        },
      },
    },

    '.tags-inner': {
      display: 'flex',
      flexWrap: 'nowrap',
      paddingRight: 1,
      margin: '-0.2rem',

      '& > *': { margin: '0.2rem' },
    },

    '.edit-button': {
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      width: '1.5rem',
      position: 'absolute',
      border: 'none',
      background: 'white',
      right: 0,
      top: 1,
      bottom: 1,
      zIndex: 1,
    },
  },

  root: {
    whiteSpace: 'nowrap',
  },

  popover: {
    background: 'white',
    width: '100%',
    maxWidth: 300,
    outline: 'none',
    borderRadius: 5,
    boxShadow: SHADOW(3),
    border: `1px solid ${COLORS.divider}`,

    fontSize: '0.96rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    padding: '0.4em 0.75em',
    zIndex: 0,
  },

  menu: {
    padding: '0 0.25rem',
    animation: `${animation} 100ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards`,
    outline: 'none',
  },

  card: {
    minWidth: '15rem',
    maxWidth: '20rem',
    maxHeight: 400,
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    overflow: 'auto !important',
  },

  content: {
    overflowY: 'auto',
  },

  groupHeader: {
    padding: '0.3rem 0.75rem',
    fontWeight: 600,
    fontSize: '0.92rem',
    lineHeight: 'normal',
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    borderTop: `1px solid ${COLORS.divider}`,

    '&:first-of-type': {
      borderTop: 'none',
      marginTop: '0.25rem',
    },
  },

  groupRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0.75rem',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '0.95rem',
    width: '100%',

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-added': {
      fontWeight: 500,

      '.row-swatch': {
        boxShadow: `
          0 0 0 2px ${COLORS.white},
          0 0 0 4px var(--color)
        `,
      },

      '.close-button': {
        marginLeft: 'auto',
      },

      '&:hover .close-button svg': {
        transform: 'scale3d(1.25, 1.25, 1.25)',
        fill: COLORS.red,
      },
    },
  },

  glyph: {
    marginRight: '0.25rem',
  },
}
