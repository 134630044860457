import React from 'react'
import { tint, transparentize } from 'polished'

import { COLORS } from '../theme'
import { isDefined } from '../utils/functions'

import Flex from './Flex'
import Glyph from './Glyph'
import Markup from './Markup'

export const TopBarMessage = (props: any) => {
  const {
    children,
    className,
    color = COLORS.blue,
    currentCount,
    glyph,
    onDismissClick,
    onNextClick,
    onPrevClick,
    richText,
    totalCount,
  } = props

  const style: any = React.useMemo(() => {
    return {
      '--top-bar-background': tint(0.8, color),
      '--top-bar-border-color': tint(0.5, color),
    }
  }, [color])

  return (
    <div css={STYLES.root} className={className} style={style}>
      <Flex centerY flex="1 1 auto">
        <Flex centerY nowrap flex="1 1 300px">
          {glyph && <Glyph glyph={glyph} color={color} size="1.5rem" css={STYLES.glyph} />}

          <div data-test="top_bar_message" css={STYLES.content}>
            {children}
            {richText && <Markup css={STYLES.richText} value={richText} />}
          </div>
        </Flex>

        {totalCount > 1 && onNextClick && onPrevClick && (
          <div css={STYLES.navButtons}>
            <button type="button" onClick={onPrevClick} css={STYLES.navButton}>
              ←
            </button>

            {isDefined(currentCount) && isDefined(totalCount) && (
              <div css={STYLES.navContent}>
                {currentCount} of {totalCount}
              </div>
            )}

            <button type="button" onClick={onNextClick} css={STYLES.navButton}>
              →
            </button>
          </div>
        )}
      </Flex>

      <button data-test="close_message_button" type="button" onClick={onDismissClick} css={STYLES.dismissButton}>
        <Glyph glyph="cross" color={COLORS.text} size="0.8rem" />
      </button>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    fontSize: '0.95rem',
    background: 'var(--top-bar-background)',
    borderBottom: '1px solid var(--top-bar-border-color)',
  },

  glyph: {
    marginLeft: '0.5rem',
  },

  content: {
    flex: '1 1 auto',
    paddingRight: '0.5rem',
    padding: '0.5rem',
  },

  richText: {
    '.ProseMirror': {
      '& > :first-of-type': { marginTop: 0 },
      '& > :last-of-type': { marginBottom: 0 },
    },
  },

  navButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
  },

  navContent: {
    margin: '0 0.25rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    flex: '0 0 auto',
  },

  navButton: {
    width: '1.75rem',
    height: '1.75rem',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 4,
    flex: '0 0 auto',

    '&:hover': {
      background: transparentize(0.8, COLORS.text),
    },
  },

  dismissButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '1.8rem',
    minHeight: '1.8rem',
    alignSelf: 'stretch',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    flex: '0 0 auto',
    borderLeft: `1px solid ${COLORS.divider}`,

    '&:hover': {
      background: transparentize(0.9, COLORS.text),
    },
  },
}
