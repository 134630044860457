import React, { Component } from 'react'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import YesNoRadioGroupWithTextarea from '../../../../Forms/elements/YesNoRadioGroupWithTextarea'

class MotivationForChangeForm extends Component {
  render() {
    return (
      <FormSection>
        <CheckboxGroup label="Contemplation stage?" layout="vertical-dense">
          <Checkbox label="Precontemplation" model="data.motivation_for_change.contemplation_stage.precontemplation" />
          <Checkbox label="Contemplation" model="data.motivation_for_change.contemplation_stage.contemplation" />
          <Checkbox label="Preparation" model="data.motivation_for_change.contemplation_stage.preparation" />
          <Checkbox label="Action" model="data.motivation_for_change.contemplation_stage.action" />
          <Checkbox label="Maintenance" model="data.motivation_for_change.contemplation_stage.maintenance" />
        </CheckboxGroup>

        <SmartTextarea
          useDictation
          label="What are the client's internal factors (for example, client wants to change but doesn't know how)?"
          model="data.motivation_for_change.internal_factors"
        />

        <CheckboxGroup label="What are the client's external factors?" layout="vertical-dense">
          <Checkbox label="Unemployment" model="data.motivation_for_change.external_factors.unemployment" />
          <Checkbox label="Financial Strain" model="data.motivation_for_change.external_factors.financial_strain" />
          <Checkbox label="Legal Issues" model="data.motivation_for_change.external_factors.legal_issues" />
          <Checkbox label="Job Jeopardy" model="data.motivation_for_change.external_factors.job_jeopardy" />
          <Checkbox label="Family Strain" model="data.motivation_for_change.external_factors.family_strain" />
          <Checkbox label="Environmental / Location" model="data.motivation_for_change.external_factors.environmental_location" />
        </CheckboxGroup>

        <SmartTextarea useDictation label="Notes on external factors" model="data.motivation_for_change.external_factors_extra" />

        <CheckboxGroup label="Engagement in Sessions:" layout="vertical-dense">
          <Checkbox label="Fully engaged" model="data.motivation_for_change.engagement.fully_engaged" />
          <Checkbox label="Requires frequent prompting" model="data.motivation_for_change.engagement.requires_frequent_prompting" />
          <Checkbox label="Provides appropriate feedback" model="data.motivation_for_change.engagement.provides_appropriate_feedback" />
          <Checkbox label="Inappropriate" model="data.motivation_for_change.engagement.inappropriate" />
          <Checkbox label="Attentive" model="data.motivation_for_change.engagement.attentive" />
          <Checkbox label="Interrupts" model="data.motivation_for_change.engagement.interrupts" />
          <Checkbox label="Deceptive" model="data.motivation_for_change.engagement.deceptive" />
          <Checkbox label="Late more than twice per week" model="data.motivation_for_change.engagement.late_more_than_twice_per_week" />
          <Checkbox label="Distracted" model="data.motivation_for_change.engagement.distracted" />
          <Checkbox label="On time" model="data.motivation_for_change.engagement.on_time" />
          <Checkbox label="Honest" model="data.motivation_for_change.engagement.honest" />
          <Checkbox label="Completes assignments" model="data.motivation_for_change.engagement.completes_assignments" />
          <Checkbox label="Respectful" model="data.motivation_for_change.engagement.respectful" />
          <Checkbox label="Vulnerable" model="data.motivation_for_change.engagement.vulnerable" />
          <Checkbox label="Aggressive" model="data.motivation_for_change.engagement.aggressive" />
          <Checkbox label="Poor eye contact" model="data.motivation_for_change.engagement.poor_eye_contact" />
          <Checkbox label="Restless" model="data.motivation_for_change.engagement.restless" />
          <Checkbox label="Invested" model="data.motivation_for_change.engagement.invested" />
          <Checkbox label="Falling asleep" model="data.motivation_for_change.engagement.falling_asleep" />
          <Checkbox label="Avoidant" model="data.motivation_for_change.engagement.avoidant" />
        </CheckboxGroup>

        <SmartTextarea useDictation placeholder="Other" model="data.motivation_for_change.engagement.other" rows={2} />

        <SmartTextarea
          useDictation
          label="Please list client's involvement with 12 step meetings or community:"
          model="data.motivation_for_change.client_involvement_with_12_step_meetings_or_community"
        />

        <RadioGroup label="Does the client have a sponsor?" model="data.motivation_for_change.has_sponsor" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <YesNoRadioGroupWithTextarea
          label="Does the client identify with any particular religion or spiritual group?"
          model="data.motivation_for_change.does_identify_with_religious_or_spiritual_group"
          textareaLabel="Which one?"
        />

        <YesNoRadioGroupWithTextarea
          label="Are there any religious, cultural, or spiritual needs that the client would like to be taken into consideration to enhance their treatment experience?"
          model="data.motivation_for_change.religious_or_spiritual_needs"
        />

        <SmartTextarea
          useDictation
          label="How has the client's value system (i.e., honesty, integrity, respect, etc.) been impacted by their substance use, and what are the related personal consequences (i.e., decreased self-worth, shame, guilt)?"
          model="data.motivation_for_change.substance_use_impact_on_value_system"
        />

        <ContextShow when="data.motivation_for_change.has_sponsor" is={true}>
          <SmartTextarea
            useDictation
            label="When was the client's last meeting with a sponsor and what step are they currently on?"
            model="data.motivation_for_change.last_time_met_with_sponsor_and_what_step_at"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <ContextShow when="data.motivation_for_change.has_sponsor" is={false}>
          <SmartTextarea
            useDictation
            label="What is the plan for obtaining a sponsor? Include deadline, if one exists:"
            model="data.motivation_for_change.plan_for_obtaining_sponsor"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>

        <SmartTextarea useDictation label="List client's short and long term goals:" model="data.motivation_for_change.client_goals" />

        <SmartTextarea
          useDictation
          label="What leisure activities and/or hobbies does the client enjoy?"
          model="data.motivation_for_change.leisure_activities_and_or_hobbies"
        />

        <SmartTextarea
          useDictation
          label="How has your using affected your ability to participate in these activities/hobbies?"
          model="data.motivation_for_change.usage_affection_on_activities_and_hobbies"
        />

        <SmartTextarea useDictation label="Notes" model="data.motivation_for_change.notes" />
      </FormSection>
    )
  }
}

export default MotivationForChangeForm
