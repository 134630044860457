import React from 'react'

import { COLORS } from '../../../theme'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import ButtonGroup from '../../../components/ButtonGroup'

import { useFormBuilder } from '../useFormBuilder'
import { INSERT_MENUS, SMART_ELEMENTS_MENU } from '../utils/constants'

import { SidebarGroup } from '../components/SidebarGroup'
import { ELEMENTS, SMART_ELEMENTS } from '../elements/elements'

import { InsertMenuItem } from './InsertMenuItem'

export const InsertMenu = (props: any) => {
  const { className } = props

  const addElement: any = useFormBuilder((state: any) => state.addElement)
  const insertTab: any = useFormBuilder((state: any) => state.insertTab)
  const setInsertTab: any = useFormBuilder((state: any) => state.setInsertTab)

  const { isBehave, isHQApp } = useSettings()

  const showBehaveMenus = isBehave || isHQApp

  return (
    <div className={className}>
      <div className="px-3 pt-4 mb-1 bg-white sticky top-0 z-10">
        <ButtonGroup stretchSelf>
          <Button
            label="Basic Elements"
            color="text"
            glyph="form_builder_basic_elements"
            size={200}
            onClick={() => setInsertTab('basic_elements')}
            type={insertTab === 'basic_elements' ? 'primary' : 'default'}
          />
          <Button
            label="Smart Elements"
            color="text"
            glyph="form_builder_smart_elements"
            size={200}
            onClick={() => setInsertTab('smart_elements')}
            type={insertTab === 'smart_elements' ? 'primary' : 'default'}
          />
        </ButtonGroup>
      </div>

      {insertTab === 'basic_elements' &&
        INSERT_MENUS.map((menu: any, menuIndex: number) => {
          if (menu.isBehaveOnly && !showBehaveMenus) return null

          return (
            <React.Fragment key={`${menu.title}-${menuIndex}`}>
              <SidebarGroup
                title={menu.title}
                css={{ paddingTop: menuIndex !== 0 && '1.75rem', borderBottom: `1px solid ${COLORS.divider}` }}
              />

              {menu.elements.map((elementKey) => {
                const insertElement = ELEMENTS[elementKey]

                if (!insertElement) return null

                const AfterTag = insertElement.menuAfterComponent

                return (
                  <InsertMenuItem
                    key={elementKey}
                    onClick={() => {
                      addElement({ category: elementKey, insertAfterActiveElement: true })
                    }}
                    elementCategory={elementKey}
                    label={insertElement.name}
                    glyph={insertElement.glyph}
                    color={insertElement.color}
                    tooltip={insertElement.insertTooltip}
                    description={insertElement.insertDescription}
                    after={AfterTag && <AfterTag />}
                  />
                )
              })}
            </React.Fragment>
          )
        })}

      {insertTab === 'smart_elements' &&
        SMART_ELEMENTS_MENU.map((menu: any, menuIndex: number) => {
          if (menu.isBehaveOnly && !showBehaveMenus) return null

          return (
            <>
              <SidebarGroup
                title={menu.title}
                css={{ paddingTop: menuIndex !== 0 && '1.75rem', borderBottom: `1px solid ${COLORS.divider}` }}
              />

              {menu.elements.map((smartElementKey: any) => {
                const smartElement = SMART_ELEMENTS[smartElementKey]
                const elementConfig = ELEMENTS?.[smartElement.category]

                if (!smartElement || !elementConfig) return null

                return (
                  <InsertMenuItem
                    key={smartElementKey}
                    onClick={() => {
                      addElement({
                        category: smartElement.category,
                        config: smartElement.defaultConfig,
                        insertSiblings: smartElement.insertSiblings,
                        insertAfterActiveElement: true,
                      })
                    }}
                    elementCategory={smartElement.category}
                    label={smartElement.name}
                    glyph={smartElement.glyph}
                    color={smartElement.color}
                  />
                )
              })}
            </>
          )
        })}
    </div>
  )
}
