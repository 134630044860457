import React from 'react'

import Flex from '../../../../components/Flex'
import Input from '../../../../components/Forms/Input'
import Option from '../../../../components/Forms/Option'
import Select from '../../../../components/Forms/Select'
import Textarea from '../../../../components/Forms/Textarea'

export const ImageEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate } = props

  const { image_description, image_max_width = 100, image_max_width_type = 'percentage', image_url } = activeElement.config

  return (
    <>
      <Input label="Image URL" model="image_url" value={image_url} onUpdate={onUpdate} />

      <Textarea
        label="Image Description"
        model="image_description"
        tooltip="Add an image description for screen readers and improved SEO"
        value={image_description}
        onUpdate={onUpdate}
      />

      <Flex nowrap gap="0.75rem">
        <Input label="Max Width" type="number" model="image_max_width" size={4} value={image_max_width} onUpdate={onUpdate} />
        <Select label="Type" model="image_max_width_type" value={image_max_width_type} onUpdate={onUpdate} flex="1 1 auto">
          <Option label="Percent" value="percentage" />
          <Option label="Pixels" value="pixels" />
        </Select>
      </Flex>
    </>
  )
}
