import React from 'react'
import startCase from 'lodash/startCase'

import { useSettings } from '../../hooks'
import { fullname } from '../../utils/functions'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    Header: 'Name',
    Cell: ({ row }: any) => <TableCell.MainLink to={to(row.original.id)} label={fullname(row.original)} />,
  },
  {
    width: 100,
    accessor: 'sex',
    Header: 'Sex',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'male',
        label: 'Male',
      },
      {
        value: 'female',
        label: 'Female',
      },
      {
        value: 'decline_to_respond',
        label: 'Decline To Respond',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  {
    width: 130,
    accessor: 'role',
    Header: 'Role',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 240,
    accessor: 'email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
  {
    width: 160,
    accessor: 'notes',
    Header: 'Notes',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  selectActions?: Function
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const ContactsTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, localStorageKey, selectActions, batchActionsConfig, hiddenColumns, titleAfter } = props
  const { timezone } = useSettings()

  return (
    <Table
      showFilters
      title="Contacts"
      titleAfter={titleAfter}
      icon="housing_notes"
      data={data}
      columns={columns(to, timezone)}
      hiddenColumns={hiddenColumns}
      selectActions={selectActions}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No contacts added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

ContactsTable.defaultProps = {
  localStorageKey: 'contacts',
}

export default ContactsTable
