import React from 'react'
import { v4 as uuid } from 'uuid'

import { withFormContext } from './context'
import { isDefined } from '../../utils/functions'

class ContextShow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      type: 'CONTEXT_SHOW',
      id: `IF-${props.when}-${uuid()}`,
      when: props.when,
      show: false,
      values: [null],
      onRegistrationCompleted: this.onRegistrationCompleted,
    }
  }

  componentDidMount = () => {
    if (this.props.form) this.props.form.register(this.state)
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextState.show !== this.state.show) {
      this.updateType = 'STATE'
      return true
    }

    // check against the Last Update Input first
    const whenIsArray = Array.isArray(this.props.when)

    // check against last updated Input
    if (whenIsArray) {
      // check if the last updated input is found under the array whens
      const index = this.props.when.indexOf(nextProps.lastUpdatedInput)
      if (index === -1) return false

      // check if the value of the last updated input matches the current state value
      const value = nextProps.form?.getField(nextProps.lastUpdatedInput)
      if (value !== this.state.values[index]) {
        this.updateType = 'DATA'
        return true
      }
    } else {
      // check to see if the last updated input matches the current state value
      if (nextProps.lastUpdatedInput === this.props.when) {
        const value = nextProps.form?.getField(this.props.when)
        if (value !== this.state.values[0]) {
          this.updateType = 'DATA'
          return true
        }
      } else {
        // check if the final value field matches the local state value
        const value = nextProps.form?.getField(this.props.when)
        if (value !== this.state.values[0]) {
          this.updateType = 'DATA'
          return true
        }
      }
    }

    return false
  }

  componentDidUpdate = (_prevProps, _prevState) => {
    if (this.updateType === 'DATA') {
      const { show, values } = this.process()
      this.setState({ show, values })
    }
  }

  componentWillUnmount = () => {
    if (this.props.form) this.props.form.deregister(this.state)
  }

  /*
    CUSTOM
  */
  checkValidity = (value: any) => {
    const { is, or, not, within, notWithin, greaterThan, lessThan } = this.props

    if (value === undefined) return false
    if (isDefined(is) && value !== is) return false
    if (isDefined(or) && (value !== is || value !== or)) return false
    // not is usually being used with null; it will not work with isDefined
    if (value === not) return false
    if (isDefined(within) && !within.includes(value)) return false
    if (isDefined(notWithin) && notWithin.includes(value)) return false
    if (isDefined(greaterThan) && value <= greaterThan) return false
    if (isDefined(lessThan) && value >= lessThan) return false

    return true
  }

  process = () => {
    const { when, orMode } = this.props

    let values = []
    const isArray = Array.isArray(when)

    if (isArray) {
      values = when.map((o) => this.props.form.getFieldValue(o))
    } else {
      values = [this.props.form.getFieldValue(when)]
    }

    if (values.length === 0) return { show: false, value: null }

    // these cannot be both true in the same time, but we start from that premise
    let onlyTrues = true
    let onlyFalses = true

    for (let i = 0; i < values.length; i++) {
      const validity = this.checkValidity(values[i])

      // if false, update initial ideas
      if (validity === false) onlyTrues = false

      // if true, update initial ideas
      if (validity === true) onlyFalses = false
    }

    const show = orMode ? !onlyFalses : onlyTrues

    return { show, values: values }
  }

  onRegistrationCompleted = () => {
    const { show, values } = this.process()
    this.setState({ show, values })
  }

  /*
    RENDER
  */
  render = () => {
    if (!this.state.show) return null
    else return this.props.children
  }
}

ContextShow.defaultProps = {
  orMode: false,
}

const wrappedContextShow = withFormContext(ContextShow)
wrappedContextShow.displayName = 'ContextShow'

export default wrappedContextShow
