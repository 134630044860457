import React from 'react'

import Button from '../Button'
import EmailInput from '../Forms/EmailInput'
import Form from '../Forms/Form'
import FormSection from '../Forms/FormSection'
import Grid from '../Grid'
import Overlay from '../Overlay'
import Section from '../Section'

export const InviteParticipantsOverlay = ({ onClose }: any) => {
  const form = React.useRef()

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center">
      <Overlay.Header glyph="user_group" title="Invite Participants" />

      <Overlay.Content>
        <Section>
          <Form getForm={form}>
            <FormSection>
              <div>
                <h3>Invite by Email</h3>
                <Grid gap="0.75rem">
                  <EmailInput placeholder="Email address" />
                  <EmailInput placeholder="Email address" />
                  <EmailInput placeholder="Email address" />
                  <Button
                    label="Add Email Address"
                    glyph="add"
                    type="link"
                    display="inline-flex"
                    size={300}
                    css={{ justifySelf: 'start', paddingLeft: 0 }}
                  />
                </Grid>
              </div>
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Invite Participants" glyph="user_group" type="primary" color="green" />
      </Overlay.Footer>
    </Overlay>
  )
}
