import React from 'react'

import PieChart from '../ChartTypes/PieChart'

export const ClientAgeBracketsChart = (props: any) => {
  const data: any = [
    {
      name: 'Under 18 y/o',
      value: props.data?.under_18,
    },
    {
      name: '18 to 24 y/o',
      value: props.data?.between_18_and_24,
    },
    {
      name: '25 to 35 y/o',
      value: props.data?.between_25_and_35,
    },
    {
      name: '36 to 50 y/o',
      value: props.data?.between_36_and_50,
    },
    {
      name: '51 to 70 y/o',
      value: props.data?.between_51_and_70,
    },
    {
      name: 'Above 70 y/o',
      value: props.data?.above_70,
    },
  ]

  return <PieChart {...props} data={data} />
}
