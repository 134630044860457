import React from 'react'

import { Heading } from '../../../../Typography'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import DateInput from '../../../../Forms/DateInput'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import FormSection from '../../../../Forms/FormSection'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'

const MedicalEvaluation = () => {
  return (
    <FormSection layout="vertical">
      <Heading is="h3">Cardiovascular Assessment</Heading>
      <RadioGroup label="Heart rhythm" model="data.medical_evaluation.heart_rhythm" layout="horizontal">
        <Radio label="Regular" value="regular" />
        <Radio label="Irregular" value="irregular" />
      </RadioGroup>

      <RadioGroup label="Capillary refill" model="data.medical_evaluation.capillary_refill" layout="horizontal">
        <Radio label="Brisk (<3 secs)" value="brisk" />
        <Radio label="Sluggish (>3 secs)" value="sluggish" />
      </RadioGroup>

      <RadioGroup label="Edema" model="data.medical_evaluation.edema" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.medical_evaluation.edema" is={true}>
        <SmartTextarea useDictation label="Where?" model="data.medical_evaluation.edema_where" />
        <RadioGroup label="Pitting?" model="data.medical_evaluation.edema_pitting" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>
      </ContextShow>

      <Heading is="h3">Respiratory Assessment</Heading>

      <RadioGroup label="Shortness of breath" model="data.medical_evaluation.shortness_of_breath" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <RadioGroup label="Lungs" model="data.medical_evaluation.lungs" layout="vertical-dense">
        <Radio label="Within normal limits" value="normal" />
        <Radio label="Diminished" value="diminished" />
        <Radio label="Crackles" value="crackles" />
        <Radio label="Wheezes" value="wheezes" />
        <Radio label="Rhonchi" value="rhonchi" />
        <Radio label="Other" value="other" />
      </RadioGroup>

      <ContextShow when="data.medical_evaluation.lungs" is="other">
        <SmartTextarea useDictation label="Please explain:" model="data.medical_evaluation.lungs_extra" />
      </ContextShow>

      <RadioGroup label="Cough" model="data.medical_evaluation.cough" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.medical_evaluation.cough" is={true}>
        <RadioGroup label="Cough Type" model="data.medical_evaluation.cough_type" layout="horizontal">
          <Radio label="Productive" value="productive" />
          <Radio label="Non-productive" value="non_productive" />
        </RadioGroup>
      </ContextShow>

      <Heading is="h3">Gastrointestinal Assessment</Heading>

      <RadioGroup label="Abdomen" model="data.medical_evaluation.abdomen" layout="vertical-dense">
        <Radio label="Within normal limits" value="normal" />
        <Radio label="Tender" value="tender" />
        <Radio label="Guarded" value="guarded" />
        <Radio label="Firm" value="firm" />
        <Radio label="Distended" value="distended" />
        <Radio label="Other" value="other" />
      </RadioGroup>

      <ContextShow when="data.medical_evaluation.abdomen" is="other">
        <SmartTextarea useDictation label="Please explain:" model="data.medical_evaluation.abdomen_extra" />
      </ContextShow>

      <RadioGroup label="Bowels" model="data.medical_evaluation.bowels" layout="horizontal">
        <Radio label="Within normal limits" value="normal" />
        <Radio label="Diarrhea" value="diarrhea" />
        <Radio label="Constipation" value="constipation" />
      </RadioGroup>

      <DateInput label="Date of last bowel movement" model="data.medical_evaluation.bowel_date" />

      <Heading is="h3">Genitourinary Assessment</Heading>

      <RadioGroup label="Urination" model="data.medical_evaluation.urination" layout="vertical-dense">
        <Radio label="Within normal limits" value="normal" />
        <Radio label="Blood in urine" value="blood_in_urine" />
        <Radio label="Frequency" value="frequency" />
        <Radio label="Urgency" value="urgency" />
        <Radio label="Burning" value="burning" />
        <Radio label="Difficulty voiding" value="difficulty_voiding" />
      </RadioGroup>

      <DateInput
        label="Reproductive - When was the client's last menstrual period?"
        model="data.medical_evaluation.last_menstrual_period"
      />

      <Heading is="h3">Skin Integrity</Heading>

      <RadioGroup label="Skin integrity/abnormalities" model="data.medical_evaluation.skin_integrity" layout="vertical-dense">
        <Radio label="Within normal limits" value="normal" />
        <Radio label="Rash" value="rash" />
        <Radio label="Bruises" value="bruises" />
        <Radio label="Sores/Open areas" value="sores" />
        <Radio label="Redness/swelling" value="redness" />
        <Radio label="Other" value="other" />
      </RadioGroup>

      <ContextShow when="data.medical_evaluation.skin_integrity" is="other">
        <SmartTextarea useDictation label="Please explain:" model="data.medical_evaluation.skin_integrity_extra" />
      </ContextShow>

      <Heading is="h3">Mobility</Heading>

      <RadioGroup
        label="Has the client been performing ADLs independently recently? (Showering, eating, getting out of bed, etc.)"
        model="data.medical_evaluation.performed_adl_independently"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.medical_evaluation.performed_adl_independently" is={true}>
        <SmartTextarea useDictation label="Please explain:" model="data.medical_evaluation.performed_adl_independently_extra" />
      </ContextShow>

      <CheckboxGroup label="Has the client experienced any of the following recently?" layout="vertical-dense">
        <Checkbox label="Decreased motiviation" model="data.medical_evaluation.decreased_motiviation" />
        <Checkbox label="Decreased physical ability" model="data.medical_evaluation.decreased_physical_ability" />
        <Checkbox label="Decreased mobility" model="data.medical_evaluation.decreased_mobility" />
      </CheckboxGroup>

      <ContextShow when="data.medical_evaluation.decreased_motiviation" is={true}>
        <SmartTextarea useDictation label="Please explain:" model="data.medical_evaluation.decreased_motiviation_extra" />
      </ContextShow>

      <ContextShow when="data.medical_evaluation.decreased_physical_ability" is={true}>
        <SmartTextarea useDictation label="Please explain:" model="data.medical_evaluation.decreased_physical_ability_extra" />
      </ContextShow>

      <ContextShow when="data.medical_evaluation.decreased_mobility" is={true}>
        <SmartTextarea useDictation label="Please explain:" model="data.medical_evaluation.decreased_mobility_extra" />
      </ContextShow>

      <RadioGroup label="Gait" model="data.medical_evaluation.gait" layout="vertical-dense">
        <Radio label="Steady" value="steady" />
        <Radio label="Shuffling" value="shuffling" />
        <Radio label="Limping" value="limping" />
        <Radio label="Other" value="other" />
      </RadioGroup>

      <ContextShow when="data.medical_evaluation.gait" is="other">
        <SmartTextarea useDictation label="Please explain:" model="data.medical_evaluation.gait_extra" />
      </ContextShow>
    </FormSection>
  )
}

export default MedicalEvaluation
