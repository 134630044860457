import React from 'react'

const PageSectionSubtitle = ({ subtitle, className }: any) => {
  return (
    <p css={styles} className={className}>
      {subtitle}
    </p>
  )
}

const styles = {
  margin: 0,
  fontSize: '1rem',
  fontWeight: 400,
  opacity: 0.9,
}

export default PageSectionSubtitle
