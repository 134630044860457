import React from 'react'
import clsx from 'clsx'
import { transparentize, opacify, tint } from 'polished'

import { COLORS, SHADOW, getColor } from '../theme'
import { Color } from '../declarations/types/Color'

import Card, { CARD_CLASSES, CARD_CSS_VARS } from './Card'
import CardActions from './CardActions'
import CardHeader from './CardHeader'
import Link from './Link'
import Loader from './Loader'

type Props = {
  actions?: React.ReactNode
  activeColor?: Color
  after?: React.ReactNode
  baseline?: String | Number
  before?: React.ReactNode
  children?: React.ReactNode
  className?: string
  testKey?: string
  graphic?: React.ReactNode
  href?: string
  isActive?: boolean
  isDisabled?: boolean
  isLink?: boolean
  onClick?: any
  paddingX?: string | number
  paddingY?: string | number
  showChevron?: boolean
  target?: string
  to?: string
  variant?: string
  isLoading?: boolean
}

const CardLink: React.FC<Props> = (props) => {
  const {
    actions,
    activeColor = 'blue',
    after,
    baseline = '4rem',
    before,
    children,
    className,
    testKey,
    graphic,
    href,
    isActive,
    isDisabled,
    isLink,
    onClick,
    paddingX,
    paddingY,
    showChevron,
    target,
    to,
    variant,
    isLoading,
  } = props

  const classNames = clsx(
    {
      'is-active': isActive,
      'is-disabled': isDisabled,
    },
    className,
  )

  const _activeColor = getColor(activeColor)

  const hasLink = isLink || !!to || !!onClick || !!href

  const LinkTag = props.as || Link

  return (
    <Card
      css={styles.root(_activeColor, hasLink)} // TODO @Andrei: improve missing link styles and functionality
      baseline={baseline}
      testKey={testKey}
      className={classNames}
      variant={variant}
      paddingX={paddingX}
      paddingY={paddingY}
    >
      {before}

      {/* TODO @Andrei: improve missing link styles and functionality */}
      {href ? (
        <a css={styles.link(hasLink)} onClick={!isDisabled && onClick} target={target} href={!isDisabled && href}>
          <CardHeader graphic={graphic} children={children} showChevron={showChevron && !isLoading && !actions && hasLink} />
        </a>
      ) : to ? (
        <LinkTag
          css={styles.link(hasLink)}
          to={!isDisabled && to}
          onClick={!isDisabled && onClick}
          target={target}
          href={!isDisabled && href}
        >
          <CardHeader graphic={graphic} children={children} showChevron={showChevron && !isLoading && !actions && hasLink} />
        </LinkTag>
      ) : (
        <div css={styles.link(hasLink)} onClick={!isDisabled && onClick} target={target} href={!isDisabled && href}>
          <CardHeader graphic={graphic} children={children} showChevron={showChevron && !isLoading && !actions && hasLink} />
        </div>
      )}

      {after && <div css={styles.after}>{after}</div>}
      {isLoading && (
        <div css={styles.after}>
          <Loader />
        </div>
      )}

      {actions && <CardActions actions={actions} />}
    </Card>
  )
}

const styles = {
  root: (activeColor: string, hasLink: boolean) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    transition: 'box-shadow 120ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    position: 'relative',
    background: tint(0.5, COLORS.lightBackground),
    boxShadow: `0 0 0 1px ${opacify(-0.05, COLORS.divider)}`,

    ...(hasLink && {
      background: COLORS.white,
      boxShadow: `
          0 0 0 1px ${opacify(-0.05, COLORS.divider)},
          0 3px 0 1px ${opacify(-0.07, COLORS.divider)}
        `,

      '&:hover': {
        zIndex: 1,
        boxShadow: `
            0 0 0 1px ${COLORS.divider},
            ${SHADOW(5)}
          `,
      },

      '&.is-active, &.active': {
        boxShadow: `
            0 0 0 1px ${activeColor},
            0 0 0 3px ${transparentize(0.65, activeColor)},
            ${SHADOW(5)}
          `,
      },
    }),

    '&.is-disabled': {
      opacity: 0.6,
      pointerEvents: 'none',
    },
  }),

  link: (hasLink: boolean) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: '1 1 auto',
    color: COLORS.text,
    cursor: 'default !important', // TODO @Andrei: update together with 'hasLink' prop

    ...(hasLink && {
      [`.${CARD_CLASSES.TITLE}`]: {
        color: COLORS.blue,
      },

      cursor: 'pointer !important', // TODO @Andrei: update together with 'hasLink' prop

      // [`.${CARD_CLASSES.GRAPHIC}, .${CARD_CLASSES.HEADER_CONTENT}, .${CARD_CLASSES.CHEVRON}`]: {
      //   transition: 'transform 160ms cubic-bezier(0.39, 0.575, 0.565, 1)',
      // },

      '&:hover': {
        [`.${CARD_CLASSES.TITLE}`]: {
          color: COLORS.link,
        },

        // [`.${CARD_CLASSES.GRAPHIC}`]: {
        //   transform: 'translateX(-3px)',
        // },

        // [`.${CARD_CLASSES.HEADER_CONTENT}`]: {
        //   transform: 'translateX(-2px)',
        // },

        // [`.${CARD_CLASSES.CHEVRON}`]: {
        //   transform: 'translateX(3px)',
        // },
      },
    }),
  }),

  after: {
    paddingRight: `var(${CARD_CSS_VARS.PADDING_X})`,
  },
}

export default CardLink
