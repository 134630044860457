import React from 'react'

import { MEDICATION_TIMES } from '../../utils/constants'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Status from '../../components/Status'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'

export const MedicationLogsDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Medication',
        id: 'med_name',
        model: 'med',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell as={props.mainLinkAs} id={value?.id} value={value?.name} to={to?.(data)} />,
      },
      {
        width: 180,
        id: 'resident',
        model: 'resident',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 200,
        id: 'did_administer',
        model: 'did_administer',
        title: 'Status',
        formatValue: ({ value }: any) => {
          if (typeof value !== 'boolean') return null

          return <Status label={value ? 'Administered' : 'Refused Administration'} color={value ? 'green' : 'red'} />
        },
      },
      {
        width: 200,
        id: 'administered_at',
        model: 'administered_at',
        title: 'Administered / Refused At',
        type: 'date_time',
      },
      {
        title: 'Medication Time',
        id: 'medication_time',
        model: 'medication_time',
        formatValue: ({ value }: any) => {
          if (!value) return null

          const checkedResults = []

          for (const key in value) {
            if (value[key]) {
              checkedResults.push(MEDICATION_TIMES[key])
            }
          }

          return checkedResults.join(', ')
        },
      },
      {
        width: 100,
        id: 'dosage',
        model: 'dosage',
        title: 'Dosage',
      },
      {
        width: 100,
        id: 'unit',
        model: 'unit',
        title: 'Unit',
      },
      {
        width: 130,
        id: 'remaining_units',
        model: 'remaining_units',
        title: 'Remaining Units',
      },
      {
        width: 200,
        id: 'author',
        model: 'author',
        title: 'Logged By',
        type: 'profile',
      },
      {
        title: 'Created At',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated At',
        model: 'updated_at',
        type: 'date_time',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Medication Logs"
      testKey="medication_logs_data_table"
      icon="med_pass"
      columns={columns}
      filtersConfig={FILTERS.medication_logs}
      {...props}
    />
  )
}
