import React from 'react'

import { useSettings } from '../../hooks'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import LevelOfCareStatus from '../Statuses/LevelOfCareStatus'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'client',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(value.id)} label={value.name} avatar={value.avatar} />,
  },
  {
    width: 100,
    accessor: 'level',
    Header: 'Level of Care',
    Cell: ({ value }: any) => <LevelOfCareStatus status={value} />,
  },
  {
    width: 100,
    accessor: 'sessions',
    Header: 'Sessions Authorized',
  },
  {
    width: 200,
    accessor: 'notes',
    Header: 'Notes',
  },
  {
    width: 100,
    accessor: 'started_at',
    Header: 'Started On',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 100,
    accessor: 'ended_at',
    Header: 'Ending On',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  selectActions?: Function
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const InsuranceLocalPayerAuthorizationsTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, localStorageKey, selectActions, batchActionsConfig, hiddenColumns, titleAfter } = props
  const { timezone } = useSettings()

  return (
    <Table
      showFilters
      title="Authorizations"
      titleAfter={titleAfter}
      icon="checklist"
      data={data}
      columns={columns(to, timezone)}
      hiddenColumns={hiddenColumns}
      selectActions={selectActions}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No authorizations added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

InsuranceLocalPayerAuthorizationsTable.defaultProps = {
  localStorageKey: 'local_payer_authorizations',
}

export default InsuranceLocalPayerAuthorizationsTable
