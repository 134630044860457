import React from 'react'

import SmartStatus from '../../../components/SmartStatus'

const statuses = {
  closed: {
    label: 'Closed',
    color: 'gray',
  },
  draft: {
    label: 'Draft',
    color: 'blue',
  },
  active: {
    label: 'Active',
    color: 'green',
  },
}

export const TreatmentPlanStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}
