import React from 'react'

import Select from './Select'
import Option from './Option'
import { COMMON_TIMEZONES, TIMEZONES } from '../../utils/constants'

const TimezoneInput = ({ ...rest }: any) => {
  return (
    <Select {...rest}>
      <option disabled>Most-Used Timezones</option>
      {COMMON_TIMEZONES.map(({ value, name }) => (
        <Option label={name} value={value} />
      ))}
      {TIMEZONES.map(({ name, zones }) => (
        <>
          <option disabled> </option>
          <option disabled>{name}</option>
          {zones.map(({ value, name }) => (
            <Option label={name} value={value} />
          ))}
        </>
      ))}
    </Select>
  )
}

export default TimezoneInput
