import React from 'react'
import 'regenerator-runtime/runtime'
import { DateTime } from 'luxon'
import { persist } from 'zustand/middleware'
import { useQuery } from 'react-query'
import create from 'zustand'
import createSpeechServicesPonyfill from 'web-speech-cognitive-services'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import { DT } from '../utils/functions'
import { get, refreshToken } from '../modules/api/requests'
import { usePermissions } from '../hooks/usePermissions'
import { useSettings } from '../hooks/useSettings'

const REGION = 'eastus'

const useTokenStore = create(
  persist(
    (set: any, get: any) => ({
      token: null,
      expires_at: null,
      setToken: ({ token, expires_at }: any) => set({ token, expires_at }),
    }),
    { name: 'bh.speech_recognition_token' },
  ),
)

export const useDictation = (args = {}) => {
  const { onComplete }: any = args

  const token = useTokenStore((state) => state.token)
  const tokenExpiresAt = useTokenStore((state) => state.expires_at)
  const setToken = useTokenStore((state) => state.setToken)

  const { timezone } = useSettings()
  const { allowed: isDictationAllowed } = usePermissions({ featureFlag: 'dictation' })

  const [isStarting, setIsStarting] = React.useState(false)
  const [isStopping, setIsStopping] = React.useState(false)
  const [didStartDictation, setDidStartDictation] = React.useState(false)

  const isTokenValid = React.useMemo(() => {
    if (!token || !tokenExpiresAt) return false

    const now = DateTime.local().setZone(timezone)
    const expiryDate = DT(tokenExpiresAt, timezone)
    const minutesToExpiry = expiryDate.diff(now, 'minutes').toObject().minutes

    return minutesToExpiry > 1
  }, [token, tokenExpiresAt])

  const shouldGetNewToken = isDictationAllowed && !isTokenValid

  const { isLoading, isRefetching, refetch } = useQuery(
    ['dictation-token'],
    async () => {
      await refreshToken()
      const result: any = await get(`/apps/speech/token`)

      if (result?.token && result?.expires_at) {
        setToken(result)

        const { SpeechRecognition: AzureSpeechRecognition } = createSpeechServicesPonyfill({
          credentials: {
            region: REGION,
            authorizationToken: result.token,
          },
        })

        SpeechRecognition.applyPolyfill(AzureSpeechRecognition)
      }

      return result
    },
    { keepPreviousData: true },
  )

  React.useEffect(() => {
    if (shouldGetNewToken) {
      refetch()
    }
  }, [shouldGetNewToken])

  const {
    browserSupportsContinuousListening,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
    listening,
    resetTranscript,
    transcript,
  } = useSpeechRecognition()

  const isProcessingToken = isLoading || isRefetching
  const isButtonDisabled = isProcessingToken || isStarting || isStopping

  const startListening = async () => {
    if (!isDictationAllowed || !token || isProcessingToken) return

    try {
      resetTranscript()
      setIsStarting(true)

      await SpeechRecognition.startListening({ continuous: browserSupportsContinuousListening, language: 'en-US' })

      setIsStarting(false)
      setDidStartDictation(true)
    } catch (error) {
      console.error(error)
      setIsStarting(false)
      setDidStartDictation(false)
    } finally {
      setIsStarting(false)
    }
  }

  const stopListening = async () => {
    try {
      setIsStopping(true)

      await SpeechRecognition.abortListening()

      if (onComplete) await onComplete(transcript)

      setIsStopping(false)
      setDidStartDictation(false)
      resetTranscript()
    } catch (error) {
      console.error(error)
      setIsStopping(false)
      setDidStartDictation(false)
    } finally {
      setIsStopping(false)
      setDidStartDictation(false)
    }
  }

  const toggleListening = async () => {
    if (isStarting || isStopping) return

    if (didStartDictation && listening) {
      await stopListening()
    } else {
      await startListening()
    }
  }

  return {
    browserSupportsContinuousListening,
    browserSupportsSpeechRecognition,
    canStartDictation: browserSupportsSpeechRecognition && isDictationAllowed && isTokenValid,
    didStartDictation,
    isButtonDisabled,
    isMicrophoneAvailable,
    isProcessing: isStarting || isStopping,
    isStarting,
    isStopping,
    listening,
    resetTranscript,
    startListening,
    stopListening,
    toggleListening,
    transcript,
  }
}
