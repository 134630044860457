import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { AutoRepeatingChargesReportDataTable } from '@behavehealth/constructs/LiveReports/AutoRepeatingChargesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const AutoRepeatingCharges = () => {
  const tableProps = useDataTable({
    name: ['reports', 'auto-repeating-charges'],
    endpoint: `/live_reports?category=auto_repeating_charges`,
    localStorageKey: 'report_auto_repeating_charges_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData)}/financials`, [])

  return (
    <Page feature="financials" title="Live Auto-Repeating Charges Report">
      <Grid>
        <AutoRepeatingChargesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(AutoRepeatingCharges)
