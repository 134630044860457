import React from 'react'

import { useSettings } from '../../hooks/useSettings'
import { usDateTime } from '../../utils/functions'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'

import VOBStatus from '../../components/Statuses/VOBStatus'
import Status from '../../components/Status'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const InsuranceVobsReportDataTable = (props: any) => {
  const { to } = props

  const { timezone, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Date Created',
        id: 'created_at',
        model: 'data.created_at',
        width: 260,
        disableHide: true,
        type: 'date_time',
        disableSort: false,
        formatValue: ({ data, value }: any) => <MainCell id={data.data.id} value={usDateTime(value, timezone)} to={to?.(data.data)} />,
      },
      {
        title: 'Client',
        id: 'client',
        model: 'data.client.name',
        type: 'profile',
      },
      {
        width: 160,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ data, value }: any) => <VOBStatus status={value} />,
      },
      {
        width: 160,
        id: 'insurance_local_payer',
        model: 'data.insurance_local_payer.name',
        title: 'Insurance Payer',
      },
      {
        width: 160,
        id: 'insurance_policy',
        model: 'data.insurance_policy.name',
        title: 'Plan Name',
      },
      {
        width: 160,
        id: 'network_status',
        model: 'data.network_status',
        title: 'Network Status',
        formatValue: ({ data, value }: any) =>
          value ? <Status label={value?.replace('_', ' ')} color={value === 'in_network' ? 'green' : 'grey'} /> : '–',
      },
      {
        width: 200,
        id: 'effective_at',
        model: 'data.insurance_policy.effective_at',
        title: 'Policy Effective Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 200,
        id: 'resets_at',
        model: 'data.insurance_policy.resets_at',
        title: 'Policy Reset Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 200,
        id: 'terminates_at',
        model: 'data.insurance_policy.terminates_at',
        title: 'Policy Termination Date',
        type: 'date_time',
        disableSort: false,
      },
      {
        width: 160,
        id: 'author',
        model: 'data.author.name',
        title: 'Created By',
        type: 'profile',
      },
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="Insurance VOBs"
      icon="insurance"
      columns={columns}
      filtersConfig={FILTERS.live_reports.insurance_vobs}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="insurance_vobs"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=insurance_vobs'}
        />
      }
    />
  )
}
