import React from 'react'
import { useLocation } from 'react-router-dom'

import Card from '../../components/Card'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'
import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const SCORING_WITHOUT_AGORAPHOBIA = {
  1: 'normal',
  5: 'border-line',
  9: 'slightly ill',
  13: 'moderately ill',
  24: 'markedly ill',
}

const SCORING_WITH_AGORAPHOBIA = {
  2: 'normal',
  7: 'border-line',
  10: 'slightly ill',
  15: 'moderately ill',
  24: 'markedly ill',
}

const RootPDSSOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="PDSS Panic Disorder Severity Scale (Self Report)"
      category="clinical_measurement"
      subcategory="pdss"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Introduction"
        headingType="h2"
        scrollview={{
          id: 'introduction',
          name: 'Introduction',
        }}
      >
        <p>
          Several of the following questions refer to panic attacks and limited symptom attacks. For this questionnaire we define a panic
          attack as a sudden rush of fear or discomfort accompanied by at least 4 of the symptoms listed below. In order to qualify as a
          sudden rush, the symptoms must peak within 10 minutes. Episodes like panic attacks but having fewer than 4 of the listed symptoms
          are called limited symptom attacks. Here are the symptoms to count:
        </p>
        <ul>
          <li>Rapid or pounding heartbeat</li>
          <li>Chest pain or discomfort</li>
          <li>Chills or hot flushes</li>
          <li>Sweating</li>
          <li>Nausea </li>
          <li>Fear of losing control or</li>
          <li>Trembling or shaking</li>
          <li>Dizziness or faintness going crazy</li>
          <li>Breathlessness</li>
          <li>Feelings of unreality</li>
          <li>Fear of dying</li>
          <li>Feeling of choking</li>
          <li>Numbness or tingling</li>
        </ul>
      </Section>

      <Divider />

      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <Questionnaire />
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <ol>
          <li>
            <b>Copyright notice:</b> The Panic Disorder Severity Scale – Self Report Form is copyrighted by M. Katherine Shear, M.D.
            Permission has been granted to reproduce the scale on this website for clinicians to use in their practice and for researchers
            to use in non-industry studies. For other uses of the scale, the owner of the copyright should be contacted.
          </li>
          <li>
            <b>Citation:</b> Shear MK, Brown TA, Barlow DH, Money R, Sholomskas DE, Woods SW, Gorman JM, Papp LA. Multicenter collaborative
            Panic Disorder Severity Scale. American Journal of Psychiatry 1997;154:1571-1575
          </li>
          <li>
            The scale was developed by Shear and colleagues (1997), tested for reliability & validity (2001), and now has evidence-based
            guidelines for interpreting the scores (2009).
          </li>
        </ol>
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState({
    number_panic_attacks_this_week: 0,
    distress_of_panic_attacks: 0,
    worry_of_future_panic_attacks: 0,
    level_avoidance_places_situations: 0,
    level_avoidance_activities: 0,
    interference_with_work_home_responsibilities: 0,
    interference_with_social_life: 0,
  })

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <RadioGroup
          label="1. How many panic and limited symptoms attacks did you have during the week?"
          model="data.questionnaire.number_panic_attacks_this_week"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, number_panic_attacks_this_week: state.value }))}
        >
          <Radio label="0 = No panic or limited symptom episodes" value={0} />
          <Radio label="1 = Mild: no full panic attacks and no more than 1 limited symptom attack/day" value={1} />
          <Radio label="2 = Moderate: 1 or 2 full panic attacks and/or multiple limited symptom attacks/day" value={2} />
          <Radio label="3 = Severe: more than 2 full attacks but not more than 1/day on average" value={3} />
          <Radio label="4 = Extreme: full panic attacks occurred more than once a day, more days than not" value={4} />
        </RadioGroup>

        <RadioGroup
          label="2. If you had any panic attacks during the past week, how distressing (uncomfortable, frightening) were they while they were happening? (If you had more than one, give an average rating. If you didn’t have any panic attacks but did have limited symptom attacks, answer for the limited symptom attacks.)"
          model="data.questionnaire.distress_of_panic_attacks"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, distress_of_panic_attacks: state.value }))}
        >
          <Radio label="0 = Not at all distressing, or no panic or limited symptom attacks during the past week" value={0} />
          <Radio label="1 = Mildly distressing (not too intense)" value={1} />
          <Radio label="2 = Moderately distressing (intense, but still manageable)" value={2} />
          <Radio label="3 = Severely distressing (very intense)" value={3} />
          <Radio label="4 = Extremely distressing (extreme distress during all attacks)" value={4} />
        </RadioGroup>

        <RadioGroup
          label="3. During the past week, how much have you worried or felt anxious about when your next panic attack would occur or about fears related to the attacks (for example, that they could mean you have physical or mental health problems or could cause you social embarrassment)?"
          model="data.questionnaire.worry_of_future_panic_attacks"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, worry_of_future_panic_attacks: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Occasionally or only mildly" value={1} />
          <Radio label="2 = Frequently or moderately" value={2} />
          <Radio label="3 = Very often or to a very disturbing degree" value={3} />
          <Radio label="4 = Nearly constantly and to a disabling extent" value={4} />
        </RadioGroup>

        <RadioGroup
          label="4. During the past week were there any places or situations (e.g., public transportation, movie theaters, crowds, bridges, tunnels, shopping malls, being alone) you avoided, or felt afraid of (uncomfortable in, wanted to avoid or leave), because of fear of having a panic attack? Are there any other situations that you would have avoided or been afraid of if they had come up during the week, for the same reason? If yes to either question, please rate your level of fear and avoidance this past week."
          model="data.questionnaire.level_avoidance_places_situations"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, level_avoidance_places_situations: state.value }))}
        >
          <Radio label="0 = None: no fear or avoidance" value={0} />
          <Radio
            label="1 = Mild: occasional fear and/or avoidance but I could usually confront or endure the situation. There was little or no modification of my lifestyle due to this."
            value={1}
          />
          <Radio
            label="2 = Moderate: noticeable fear and/or avoidance but still manageable. I avoided some situations, but I could confront them with a companion. There was some modification of my lifestyle because of this, but my overall functioning was not impaired."
            value={2}
          />
          <Radio
            label="3 = Severe: extensive avoidance. Substantial modification of my lifestyle was required to accommodate the avoidance making it difficult to manage usual activities."
            value={3}
          />
          <Radio
            label="4 = Extreme: pervasive disabling fear and/or avoidance. Extensive modification in my lifestyle was required "
            value={4}
          />
        </RadioGroup>

        <RadioGroup
          label="5. During the past week, were there any activities (e.g., physical exertion, sexual relations, taking a hot shower or bath, drinking coffee, watching an exciting or scary movie) that you avoided, or felt afraid of (uncomfortable doing, wanted to avoid or stop), because they caused physical sensations like those you feel during panic attacks or that you were afraid might trigger a panic attack? Are there any other activities that you would have avoided or been afraid of if they had come up during the week for that reason? If yes to either question, please rate your level of fear and avoidance of those activities this past week."
          model="data.questionnaire.level_avoidance_activities"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, level_avoidance_activities: state.value }))}
        >
          <Radio label="0 = No fear or avoidance of situations or activities because of distressing physical sensations" value={0} />
          <Radio
            label="1 = Mild: occasional fear and/or avoidance, but usually I could confront or endure with little distress activities that cause physical sensations. There was little modification of my lifestyle due to this."
            value={1}
          />
          <Radio
            label="2 = Moderate: noticeable avoidance but still manageable. There was definite, but limited, modification of my lifestyle such that my overall functioning was not impaired."
            value={2}
          />
          <Radio
            label="3 = Severe: extensive avoidance. There was substantial modification of my lifestyle or interference in my functioning."
            value={3}
          />
          <Radio
            label="4 = Extreme: pervasive and disabling avoidance. There was extensive modification in my lifestyle due to this such that important tasks or activities were not performed."
            value={4}
          />
        </RadioGroup>

        <RadioGroup
          label="6. During the past week, how much did the above symptoms altogether (panic and limited symptom attacks, worry about attacks, and fear of situations and activities because of attacks) interfere with your ability to work or carry out your responsibilities at home? (If your work or home responsibilities were less than usual this past week, answer how you think you would have done if the responsibilities had been usual.)"
          model="data.questionnaire.interference_with_work_home_responsibilities"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, interference_with_work_home_responsibilities: state.value }))}
        >
          <Radio label="0 = No interference with work or home responsibilities" value={0} />
          <Radio
            label="1 = Slight interference with work or home responsibilities, but I could do nearly everything I could if I didn’t have these problems."
            value={1}
          />
          <Radio
            label="2 = Significant interference with work or home responsibilities, but I still could manage to do the things I needed to do."
            value={2}
          />
          <Radio
            label="3 = Substantial impairment in work or home responsibilities; there were many important things I couldn’t do because of these problems."
            value={3}
          />
          <Radio
            label="4 = Extreme, incapacitating impairment such that I was essentially unable to manage any work or home responsibilities. "
            value={4}
          />
        </RadioGroup>

        <RadioGroup
          label="7. During the past week, how much did panic and limited symptom attacks, worry about attacks and fear of situations and activities because of attacks interfere with your social life? (If you didn’t have many opportunities to socialize this past week, answer how you think you would have done if you did have opportunities.)"
          model="data.questionnaire.interference_with_social_life"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, interference_with_social_life: state.value }))}
        >
          <Radio label="0 = No interference" value={0} />
          <Radio
            label="1 = Slight interference with social activities, but I could do nearly everything I could if I didn’t have these problems."
            value={1}
          />
          <Radio
            label="2 = Significant interference with social activities but I could manage to do most things if I made the effort."
            value={2}
          />
          <Radio
            label="3 = Substantial impairment in social activities; there are many social things I couldn’t do because of these problems."
            value={3}
          />
          <Radio label="4 = Extreme, incapacitating impairment, such that there was hardly anything social I could do." value={4} />
        </RadioGroup>
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Grid gap="1rem">
            <Score title="Scoring without Agoraphobia" finalScore={finalScore} scoring={SCORING_WITHOUT_AGORAPHOBIA} />

            <Score title="Scoring with Agoraphobia" finalScore={finalScore} scoring={SCORING_WITH_AGORAPHOBIA} />

            <Card className="!p-4">
              <h3>Composite Scoring</h3>
              <p>
                In scoring the Panic Disorder Severity Scale, items are rated on a scale of 0 to 4. A composite score is established by
                averaging the scores of the seven items.
              </p>

              <ul>
                <li>Raw 0 → Composite 0</li>
                <li>Raw 1 → Composite .14</li>
                <li>Raw 2 → Composite .28</li>
                <li>Raw 3 → Composite .42</li>
                <li>Raw 4 → Composite .57</li>
                <li>Raw 5 → Composite .71</li>
                <li>Raw 6 → Composite .85</li>
                <li>Raw 7 → Composite 1.00</li>
                <li>Raw 8 → Composite 1.14</li>
                <li>Raw 9 → Composite 1.28</li>
                <li>Raw 10 → Composite 1.42</li>
                <li>Raw 11 → Composite 1.57</li>
                <li>Raw 12 → Composite 1.71</li>
                <li>Raw 13 → Composite 1.85</li>
                <li>Raw 14 → Composite 2.00</li>
                <li>Raw 15 → Composite 2.14</li>
                <li>Raw 16 → Composite 2.28</li>
                <li>Raw 17 → Composite 2.42</li>
                <li>Raw 18 → Composite 2.57</li>
                <li>Raw 19 → Composite 2.71</li>
                <li>Raw 20 → Composite 2.85</li>
                <li>Raw 21 → Composite 3.00</li>
                <li>Raw 22 → Composite 3.14</li>
                <li>Raw 23 → Composite 3.28</li>
                <li>Raw 24 → Composite 3.42</li>
                <li>Raw 25 → Composite 3.57</li>
                <li>Raw 26 → Composite 3.71</li>
                <li>Raw 27 → Composite 3.85</li>
                <li>Raw 28 → Composite 4.00 </li>
              </ul>
            </Card>
          </Grid>
        </>
      )}
    </>
  )
}

export const PDSSOverlay = withOverlayError(RootPDSSOverlay)
