import React from 'react'

import { COLORS } from '../../../theme'
import { usDate } from '../../../utils/functions'

import { DatePicker } from '../../../components/DatePicker'
import { DateRangeSelector } from '../../../components/Forms/DateRangeSelector'
import Glyph from '../../../components/Glyph'

import { FilterDropdown } from './FilterDropdown'
import { PopoverItem } from './PopoverItem'
import { PopoverTrigger } from './PopoverTrigger'

export const CONDITIONS: any = {
  between: 'between',
  less_than: 'before',
  greater_than: 'after',
  eq: 'on',
}

const CONDITION_GLYPHS: any = {
  between: 'between',
  less_than: 'less_than',
  greater_than: 'greater_than',
  eq: 'equals',
}

export const DateTimeFilter: React.FC<any> = (props) => {
  const { config, onClear, filter, onUpdate, timezone } = props

  const [condition, setCondition] = React.useState(filter?.condition || 'between')
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  const initialStartDate = filter?.condition === 'between' ? filter?.value?.from : filter?.value
  const initialEndDate = filter?.value?.until

  const [startDate, setStartDate] = React.useState(initialStartDate)
  const [endDate, setEndDate] = React.useState(initialEndDate)

  React.useEffect(() => {
    if (condition === 'between' && startDate && endDate) {
      onUpdate?.({ condition, value: { from: startDate, until: endDate } })
      return
    }

    onUpdate?.({ condition, value: startDate })
  }, [condition, startDate, endDate])

  return (
    <>
      <FilterDropdown label={CONDITIONS[condition]} open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        {Object.keys(CONDITIONS).map((key) => (
          <PopoverItem
            key={key}
            title={CONDITIONS[key]}
            isActive={condition === key}
            onClick={() => {
              setCondition(key)
              setIsDropdownOpen(false)
            }}
            graphic={<Glyph glyph={CONDITION_GLYPHS[key]} color={COLORS.textMuted} size={16} />}
          />
        ))}
      </FilterDropdown>

      {condition === 'between' ? (
        <DateRangeSelector
          timezone={timezone}
          startDate={initialStartDate}
          endDate={initialEndDate}
          defaultPeriod={null}
          onChange={(rangeDates) => {
            setStartDate(rangeDates?.[0])
            setEndDate(rangeDates?.[1])
          }}
          renderTrigger={() => {
            return (
              <PopoverTrigger label={startDate && endDate ? `${usDate(startDate, timezone)} → ${usDate(endDate, timezone)}` : 'Select…'} />
            )
          }}
          dropdownCss={STYLES.dropdown}
        />
      ) : (
        <>
          <DatePicker
            stopPropagation
            timezone={timezone}
            date={startDate}
            maxYear={2030}
            minYear={1950}
            trigger={
              <div>
                <PopoverTrigger label={startDate ? usDate(startDate, timezone) : 'Select…'} />
              </div>
            }
            onSelect={(date) => {
              if (!date) return
              setStartDate(date.toFormat('yyyy-MM-dd'))
            }}
          />
        </>
      )}
    </>
  )
}

const STYLES = {
  dropdown: {
    width: 'auto',
  },
}
