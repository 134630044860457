import React from 'react'

import { formatURL } from '../../../../utils/functions'

import { DataTable } from '../../../DataTable/DataTable'
import { DEFAULT_FILTERS } from '../../../../constructs/Filters/constants'
import { LinkCell } from '../../../DataTable/cells/LinkCell'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

export const CommunityAccountsTable = ({ onSelect, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-community-entities'],
    endpoint: `/community/entities`,
    localStorageKey: 'selector_community_entities_v2',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.name} avatar={data.avatar} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'URL Slug',
        model: 'slug',
        width: 200,
      },
      {
        title: 'Website',
        model: 'url',
        formatValue: ({ value, data }: any) => {
          if (!value) return null

          return <LinkCell isExternal value={data.domain} href={formatURL(value)} />
        },
      },
      {
        title: 'Domain',
        model: 'domain',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal value={value} href={formatURL(value)} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        type: 'title',
        width: 150,
      },
      {
        title: 'Type',
        model: 'entity_type',
        type: 'title',
        width: 150,
      },
      {
        title: 'Business Status',
        model: 'business_status',
        type: 'title',
        width: 150,
      },
      {
        title: 'Marketing Status',
        model: 'marketing_status',
        type: 'title',
        width: 150,
      },
      {
        title: 'Visibility',
        model: 'show_status',
        type: 'title',
        width: 100,
      },
      {
        title: 'Connected EHR',
        model: 'connected_ehr',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Has EHR?',
        model: 'has_ehr',
        disableSort: true,
        formatValue: ({ data }: any) => {
          return data.connected_ehr === null ? 'No' : 'Yes'
        },
      },
      {
        title: 'Primary State',
        model: 'primary_state',
        width: 120,
      },
      {
        title: 'All Covered States',
        model: 'covered_states',
        width: 160,
      },
      {
        title: 'Smart Flags',
        model: 'smart_flags',
        type: 'smart_flags',
        disableSort: true,
        editPermission: true,
        width: 300,
      },
      {
        title: 'Duplicate Of',
        model: 'duplicate_of',
      },
      {
        title: 'Hubspot Company',
        model: 'hubspot_company_id',
        type: 'hubspot_company',
      },
      {
        title: 'Hubspot Profile',
        model: 'hubspot_profile_id',
        type: 'hubspot_community_profile',
      },
      {
        title: 'Hubspot Deal',
        model: 'hubspot_deal_id',
        type: 'hubspot_deal',
      },
      {
        title: 'Import Name',
        id: 'import_name',
        model: 'import_history.name',
      },
      {
        title: 'Import File',
        id: 'import_file',
        model: 'import_history.source_file_name',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable
      {...tableProps}
      {...rest}
      title="Community Accounts"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      searchBarFilterKey="name"
    />
  )
}

const FILTERS_CONFIG = {
  name: DEFAULT_FILTERS.name,
  slug: {
    label: 'URL Slug',
    type: 'string',
  },
  url: {
    label: 'Website',
    type: 'string',
  },
  domain: {
    label: 'Domain',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Unclaimed', value: 'unclaimed' },
      { label: 'Outreach', value: 'outreach' },
      { label: 'Never Connected', value: 'never_connected' },
      { label: 'Declined', value: 'declined' },
      { label: 'Claimed', value: 'claimed' },
    ],
  },
  business_status: {
    label: 'Business Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ],
  },
  marketing_status: {
    label: 'Marketing Status',
    type: 'multi_select',
    options: [
      { label: 'Marketing', value: 'marketing' },
      { label: 'Non Marketing', value: 'non_marketing' },
    ],
  },
  entity_type: {
    label: 'Type',
    type: 'multi_select',
    options: [
      { label: 'Organization', value: 'organization' },
      { label: 'Individual', value: 'individual' },
    ],
  },
  connected_ehr: {
    label: 'Connected EHR',
    type: 'multi_object',
    endpoint: '/facilities',
    apiKey: 'facilities',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (item: any) => item.name,
  },
  has_ehr: {
    label: 'Has EHR?',
    type: 'boolean',
  },
  show_status: {
    label: 'Visibility',
    type: 'multi_select',
    options: [
      { label: 'Show', value: 'show' },
      { label: 'Hide', value: 'hide' },
    ],
  },
  primary_state: {
    label: 'Primary State',
    type: 'string',
  },
  hubspot_company_id: {
    label: 'Hubspot Company Id',
    type: 'string',
  },
  hubspot_profile_id: {
    label: 'Hubspot Profile Id',
    type: 'string',
  },
  hubspot_deal_id: {
    label: 'Hubspot Deal Id',
    type: 'string',
  },
  // smart_flags: {
  //   label: 'Smart Flags',
  //   type: 'smart_flags',
  //   glyph: 'flag',
  // },
  import_name: {
    label: 'Import Name',
    type: 'string',
  },
  import_file: {
    label: 'Import File',
    type: 'string',
  },
  covered_states: {
    label: 'All Covered State',
    type: 'string',
  },
  duplicate_of: {
    label: 'Duplicate Of',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
