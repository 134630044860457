import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'

const RootClientWorkflowsMessageTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'secure_message_templates',
    endpoint: `/secure_message_templates`,
    invalidate: 'secure_message_templates',
    options: props,
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) return <OverlayLoader position="right" />

  return (
    <Overlay showBackdrop={isEditable} closeOnBackdrop position="right" onClose={close}>
      <Overlay.Header title="Client Workflows Message Template" icon="client_workflows_message_templates" />

      <Overlay.Content>
        <Form
          isEditable={isEditable}
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <Grid gap="0.75rem">
              <Input
                label="Template Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name for this template',
                  },
                }}
              />

              <Select label="Workflow" model="category" defaultValue="accept">
                <Option label="Accept" value="accept" />
                <Option label="Decline" value="decline" />
                <Option label="Admit" value="admit" />
                <Option label="Discharge" value="discharge" />
                <Option label="Readmit" value="readmit" />
              </Select>

              <Textarea
                label="Message"
                model="message"
                validations={{
                  presence: {
                    message: 'Please enter a message for this template',
                  },
                }}
              />
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission={isNew ? 'settings.client_workflows_message_templates.create' : 'settings.client_workflows_message_templates.edit'}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Message Template"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="settings.client_workflows_message_templates.edit"
            />

            <DeleteDialog
              title="Delete Message Template?"
              message="Are you sure you want to delete this Message Template? This action cannot be undone."
              onYes={deleteRecord}
              permission="settings.client_workflows_message_templates.delete"
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                permission="settings.client_workflows_message_templates.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClientWorkflowsMessageTemplateOverlay = withOverlayError(RootClientWorkflowsMessageTemplateOverlay)
