import React from 'react'

import InsurancePoliciesSummonTable from '../Tables/InsurancePoliciesSummonTable'

import DataView from './DataView'

type Props = {
  clientID: string
}

const InsurancePoliciesDataView = ({ clientID }: Props) => {
  return (
    <DataView
      fetchName="insurance_policies"
      fetchURL={`/residents/${clientID}/insurance_policies`}
      emptyDescription="No insurance policies to display"
    >
      {(data: any) => <InsurancePoliciesSummonTable data={data} isLoading={false} />}
    </DataView>
  )
}

export default InsurancePoliciesDataView
