import produce from 'immer'

const KEY = 'community_map'

export const saveSettings = (subdomain: string, settings?: any) => {
  if (!subdomain) return

  const key = `bh.${subdomain}.${KEY}`

  const initial = JSON.stringify({})
  const current = JSON.parse(localStorage.getItem(key) || initial)

  localStorage.setItem(key, JSON.stringify({ ...current, ...settings }))
}

export const getSettings = (subdomain: string) => {
  if (!subdomain) return null

  const key = `bh.${subdomain}.${KEY}`
  const current = localStorage.getItem(key)

  return current ? JSON.parse(current) : null
}

export const clearSettings = (subdomain: string) => {
  if (!subdomain) return

  const key = `bh.${subdomain}.${KEY}`

  localStorage.setItem(key, JSON.stringify({}))
}
