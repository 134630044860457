import React from 'react'
import { useMedia } from 'use-media'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'

import { BREAKPOINT, TOKENS, COLORS } from '../theme'

import Dropdown from './Dropdown'
import Glyph from './Glyph'
import NavLink from './NavLink'

const SettingsMenuLink: React.FC<any> = ({ className, to, navItems }) => {
  const isExpanded = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavExpandBreakpoint] })
  const isCompact = useMedia({ minWidth: BREAKPOINT[TOKENS.appNavCompactBreakpoint] })
  const location = useLocation()
  const isActive = location.pathname.includes('/settings')

  const classNames = clsx(className, {
    'hide-label': isCompact && !isExpanded,
    'is-nav-active': isActive,
  })

  if (navItems) {
    return (
      <Dropdown
        position="right"
        trigger={
          <NavLink css={styles.root} className={classNames}>
            <Glyph glyph="settings" size={20} color={COLORS.blue} />
            <span className="label" css={styles.label}>
              Settings
            </span>
            <Glyph glyph="triangle_down" size={12} color={COLORS.blue} css={styles.triangle} />
          </NavLink>
        }
      >
        <div css={styles.menu}>{navItems}</div>
      </Dropdown>
    )
  }

  return (
    <NavLink css={styles.root} className={classNames} to={to}>
      <Glyph glyph="settings" size={20} color={COLORS.blue} />
      <span className="label" css={styles.label}>
        Settings
      </span>
      <Glyph glyph="triangle_down" size={12} color={COLORS.blue} css={styles.triangle} />
    </NavLink>
  )
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: 42,
    padding: '0.4em 1em',
    cursor: 'pointer',
    background: COLORS.white,
    boxShadow: `inset 0 -1px 0 ${COLORS.divider}`,
    color: `${COLORS.text} !important`,

    '&:hover': {
      background: COLORS.hover,
    },

    '&.hide-label': {
      justifyContent: 'center',

      '.label': {
        display: 'none',
      },
    },

    '&.is-nav-active': {
      background: COLORS.lightBackground,
    },
  },

  label: {
    display: 'inline-block',
    marginLeft: '0.5em',
    fontWeight: 500,
  },

  menu: {
    padding: '1rem',
  },

  triangle: {
    marginLeft: 'auto',
    transform: 'rotate(-90deg)',
  },
}

export default SettingsMenuLink
