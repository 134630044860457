import React from 'react'

import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Input from '../../Forms/Input'
import Textarea from '../../Forms/Textarea'
import Tooltip from '../../Tooltip'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

type Props = {
  isHealthcare: boolean
}

export const StaffFields: React.FC<Props> = ({ isHealthcare }) => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="employees" />
        <Type as="h2" variant="H3">
          Staff
        </Type>
      </Flex>

      <FormSection horizontal>
        <Input
          type="number"
          label="Total Staff Headcount"
          size={6}
          defaultValue={1}
          suffix="staff"
          model="questionnaire.staff_information.total_headcount"
        />

        {isHealthcare && (
          <Input
            type="number"
            label="Provider Staff Headcount"
            size={6}
            defaultValue={1}
            suffix="staff"
            model="questionnaire.staff_information.provider_headcount"
          />
        )}
      </FormSection>

      {isHealthcare && (
        <Textarea
          label="Staff Description – What type of providers and staff do you have?"
          labelAfter={
            <Tooltip content="e.g. our clinical director has a PsyD, and we have two counselors with CADAC, and a case manager." />
          }
          model="questionnaire.staff_information.description"
          validations={{
            length: {
              minimum: 10,
              tooShort: 'Please enter %{count} or more characters',
            },
          }}
        />
      )}
    </FormSection>
  )
}

export const Staff: React.FC<Props> = ({ isHealthcare }) => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <StaffFields isHealthcare={isHealthcare} />
    </Form>
  )
}
