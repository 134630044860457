import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

export const ERADataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'ERA ID',
        model: 'identifier',
        width: 200,
        disableHide: true,
        disableSelect: true,
        formatValue: ({ data, value }: any) => <MainCell testKey="main_cell" id={data.id} value={value} to={props?.to?.(data)} />,
      },
      {
        width: 300,
        title: 'Insurance Payer',
        model: 'insurance_local_payer',
        type: 'profile',
        config: {
          setLink: ({ rowData }: any) => `/insurance-payers/${rowData?.insurance_local_payer?.id}/general-info`,
        },
      },
      {
        width: 300,
        title: 'Facility Provider',
        model: 'provider.credential',
        type: 'profile',
        config: {
          setLink: ({ rowData }: any) => `/settings/providers/${rowData?.provider?.id}`,
        },
      },
      {
        title: 'Clearing House ID',
        model: 'clearing_house_id',
      },
      {
        title: 'Clearing House Payer ID',
        model: 'clearing_house_payer_id',
      },
      {
        width: 150,
        title: 'Payment Method',
        model: 'payment_method',
      },
      {
        width: 150,
        title: 'Payment Format',
        model: 'payment_format',
      },
      {
        width: 150,
        title: 'Payment Date',
        model: 'paid_date',
        type: 'date',
      },
      {
        title: 'Check Number',
        model: 'check_number',
      },
      {
        title: 'Paid Amount',
        id: 'paid_amount',
        model: 'paid_amount',
        width: 200,
        type: 'amount',
      },
    ],
    [],
  )

  return <DataTable asCard title="Insurance ERAs" icon="accounting" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
}

const FILTERS_CONFIG = {
  identifier: {
    title: 'ERA ID',
    type: 'string',
  },
  name: {
    title: 'Name',
    type: 'string',
  },
  internal_name: {
    title: 'Clearing House ID',
    type: 'string',
  },
}
