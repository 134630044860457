import React from 'react'
import { transparentize } from 'polished'

import Icon from './Icon'
import Loader from './Loader'

import { Icon as IconType } from '../declarations/types'

type Props = {
  icon: IconType
}

const AppLoader: React.FC<Props> = ({ icon }) => {
  return (
    <div css={styles.root}>
      <Loader css={styles.loader} size={64} />
      <Icon icon={icon} css={styles.icon} size={40} />
    </div>
  )
}

const styles: any = {
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 0,
  },

  loader: {
    position: 'absolute',
    background: transparentize(0.5, 'white'),
    borderRadius: '100%',
  },

  icon: {
    position: 'relative',
    zIndex: 1,
  },
}

AppLoader.defaultProps = {
  icon: 'behave_health',
}

export default AppLoader
