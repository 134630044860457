import React from 'react'
import { transparentize } from 'polished'
import { PanelGroup, Panel } from 'react-resizable-panels'

import { COLORS, SHADOW } from '../../../theme'
import { useFormBuilder } from '../useFormBuilder'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import ButtonGroup from '../../../components/ButtonGroup'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import Flex from '../../../components/Flex'
import Permission from '../../../components/Permission'
import Tooltip from '../../../components/Tooltip'

import { FormsBehaveAIChat } from './FormsBehaveAIChat'
import { FormSettingsMain } from './FormSettingsMain'
import { InsertMenu } from './InsertMenu'
import { NameEditInput } from './NameEditInput'
import { PagesNav } from './PagesNav'
import { PanelResize } from './PanelResize'
import { VariablesMenu } from './VariablesMenu'

export const MainMenu = (props: any) => {
  const { isNew, allowEditing = true, isDeleting, deleteRecord, onSave, cancel, edit, pagesBefore } = props

  const { isBehave, isHQApp } = useSettings()
  const [isSaving, setIsSaving] = React.useState(!!props.isSaving)

  const activeTab: any = useFormBuilder((state: any) => state.activeTab)
  const isEditable: any = useFormBuilder((state: any) => state.isEditable)
  const setActiveTab: any = useFormBuilder((state: any) => state.setActiveTab)
  const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)

  const renders = React.useRef(0)

  return (
    <div className="grid grid-cols-[100%] grid-rows-[auto_1fr] bg-white bg-opacity-100 relative z-[3] w-full overflow-hidden">
      <div className="px-3 pt-2 bg-white sticky top-0 z-10">
        <MainMenuHeader />

        <ButtonGroup stretchSelf className="mt-3" css={STYLES.buttonsGroup}>
          <Button
            label="Pages"
            color="blue"
            glyph="documents"
            size={200}
            onClick={() => setActiveTab('pages')}
            type={activeTab === 'pages' ? 'primary' : 'default'}
            className={activeTab === 'pages' ? 'is-active' : 'is-inactive'}
          />

          <Permission featureFlagV2="variables">
            <Button
              label="Variables"
              color="blue"
              glyph="variables"
              size={200}
              onClick={() => setActiveTab('variables')}
              type={activeTab === 'variables' ? 'primary' : 'default'}
              className={activeTab === 'variables' ? 'is-active' : 'is-inactive'}
            />
          </Permission>
          <Button
            label="Settings"
            color="blue"
            glyph="settings"
            size={200}
            onClick={() => setActiveTab('settings')}
            type={activeTab === 'settings' ? 'primary' : 'default'}
            className={activeTab === 'settings' ? 'is-active' : 'is-inactive'}
          />
          {(isBehave || isHQApp) && (
            <Button
              label="Behave AI"
              color="blue"
              glyph="quick_view"
              size={200}
              onClick={() => setActiveTab('behave_ai')}
              type={activeTab === 'behave_ai' ? 'primary' : 'default'}
              className={activeTab === 'behave_ai' ? 'is-active' : 'is-inactive'}
            />
          )}
        </ButtonGroup>
      </div>

      {activeTab === 'pages' && (
        <PanelGroup direction="vertical">
          <Panel id="pages" order={1} maxSize={85} className="relative z-0">
            <div className="w-full h-full px-3 pb-3 mt-2 overflow-y-auto">
              {pagesBefore}
              <PagesNav />
            </div>
          </Panel>

          {isEditable && !isPreviewMode && (
            <>
              <PanelResize direction="horizontal" />

              <Panel id="insert" order={2} maxSize={85} defaultSize={60} className="relative z-0 shadow-top-hard-4">
                <InsertMenu className="w-full h-full pb-6 overflow-y-auto" />
              </Panel>
            </>
          )}
        </PanelGroup>
      )}

      {activeTab === 'settings' && (
        <div className="w-full h-full px-3 py-3 overflow-y-auto">
          <div className="pt-2">
            <FormSettingsMain isNew={isNew} />
          </div>
        </div>
      )}

      {activeTab === 'variables' && (
        <div className="w-full h-full px-3 pb-3 overflow-y-auto">
          <div className="pt-2">
            <VariablesMenu />
          </div>
        </div>
      )}

      {activeTab === 'behave_ai' && (isBehave || isHQApp) && <FormsBehaveAIChat />}

      {allowEditing && (
        <footer className="grid gap-3 px-3 pb-3">
          {isEditable && (
            <Flex gap="0.75rem">
              <Button
                label="Save Form"
                size={300}
                type="primary"
                color="green"
                glyph="check"
                onClick={async () => {
                  setIsSaving(true)

                  await onSave({ keepEditing: false })

                  setIsSaving(false)
                }}
                isLoading={isSaving}
                isDisabled={props.isSaving}
                flex="10 1 auto"
                permission="forms_builder.edit"
              />

              {!isNew && (
                <Button
                  label="Cancel"
                  size={300}
                  type="default"
                  color="blue"
                  glyph="cross"
                  onClick={cancel}
                  flex="0 0 auto"
                  isDisabled={isSaving || props.isSaving}
                />
              )}
            </Flex>
          )}

          {!isEditable && (
            <Flex gap="0.75rem">
              <Button
                key="readonly-state"
                label="Edit Form"
                size={300}
                type="primary"
                color="blue"
                glyph="edit"
                onClick={edit}
                flex="10 1 auto"
                permission="forms_builder.edit"
              />

              <Permission permission="forms_builder.delete">
                <DeleteDialog
                  title="Delete Form?"
                  message="Are you sure you want to delete this form? This action cannot be undone."
                  onYes={deleteRecord}
                  yesLabel="Delete Form"
                >
                  <Button label="Delete…" size={300} type="default" color="red" glyph="delete" flex="0 0 auto" isLoading={isDeleting} />
                </DeleteDialog>
              </Permission>
            </Flex>
          )}
        </footer>
      )}
    </div>
  )
}

const MainMenuHeader = () => {
  const addPage: any = useFormBuilder((state: any) => state.addPage)
  const isEditable: any = useFormBuilder((state: any) => state.isEditable)
  const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)

  const value = useFormBuilder((state: any) => state.formSettings?.name || 'New Form')
  const setFormSettings: any = useFormBuilder((state: any) => state.setFormSettings)

  return (
    <div className="flex items-center justify-between">
      <div className="pr-2 flex-[1_1_auto] min-w-0">
        <NameEditInput
          className="text-[1.3rem] font-[700] -ml-1.5"
          value={value}
          onSave={(newName) => {
            if (!newName) return

            setFormSettings({ name: newName })
          }}
        />
      </div>

      {isEditable && (
        <Tooltip show={isPreviewMode} content="Exit preview mode to add pages">
          <Button
            nowrap
            label="Add Page"
            glyph="add"
            type="primary"
            size={100}
            display="inline-flex"
            onClick={() => addPage()}
            isDisabled={isPreviewMode}
            className={`!whitespace-nowrap !flex-[0_0_auto]`}
          />
        </Tooltip>
      )}
    </div>
  )
}

const STYLES = {
  buttonsGroup: {
    borderRadius: 5,
    overflow: 'hidden',
    boxShadow: `
    	inset 0 0 0 1px ${transparentize(0.85, COLORS.gray)},
    	${SHADOW(1, transparentize(0.95, COLORS.blue))}
    `,
    flexWrap: 'wrap !important',
    '--modifier': 'calc(415px - 100%)',

    '& > *': {
      borderRadius: '0 !important',
      flexGrow: '1 !important',
      flexBasis: 'calc(var(--modifier) * 999) !important',
      border: 'none !important',
      boxShadow: `1px 1px 0 0 ${transparentize(0.85, COLORS.gray)} !important`,

      '&.is-inactive': {
        background: 'none !important',
      },
    },
  },
}
