import React from 'react'
import { parse } from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { SummonOverlay, Button, PageSection } from '@behavehealth/components'
import { useGet, useCreate } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import Notifications from '@behavehealth/modules/notifications'

import Accordion from '@behavehealth/components/Accordion'
import Card from '@behavehealth/components/Card'
import DeleteDialog from '@behavehealth/components/Dialogs/DeleteDialog'
import Flex from '@behavehealth/components/Flex'
import Icon from '@behavehealth/components/Icon'
import PillTab from '@behavehealth/components/PillTab'
import PillTabList from '@behavehealth/components/PillTabList'
import State from '@behavehealth/components/State'
import Status from '@behavehealth/components/Status'
import Tabs from '@behavehealth/components/Tabs'

import { EHRImportQuoteTemplateOverlay } from '@behavehealth/constructs/QuoteTemplates/EHRImportQuoteTemplateOverlay'
import { QuoteAdditionalProducts } from '@behavehealth/constructs/QuoteReview/QuoteAdditionalProducts'
import { QuoteOverlay } from '@behavehealth/constructs/Quotes/QuoteOverlay'
import { QuoteReview } from '@behavehealth/constructs/QuoteReview/QuoteReview'
import { QuoteRefreshVariablesButton } from '@behavehealth/constructs/Quotes/QuoteRefreshVariablesButton'
import { STATUSES } from '@behavehealth/constructs/Quotes/QuoteStatus'

const ALL_STATUSES = Object.keys(STATUSES)

export const FacilityQuotes = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { tenant, isBehave } = useSettings()

  const quoteParams = {
    status: isBehave ? ALL_STATUSES.join(',') : ALL_STATUSES.filter((o) => o !== 'draft' && o !== 'archived').join(','),
  }

  const { data: quotes, isLoading: isLoadingQuotes } = useGet({
    name: ['quotes', quoteParams],
    url: '/quotes',
    params: quoteParams,
  })

  const { mutateAsync: importQuoteTemplate } = useCreate({
    name: ['import-quote'],
    url: '/quotes/import',
    invalidate: 'quotes',
  })

  const [activeQuoteId, setActiveQuoteId] = React.useState(null)

  const activeQuote = React.useMemo(() => {
    return quotes?.find?.((quote: any) => quote.id === activeQuoteId) || null
  }, [activeQuoteId, quotes])

  const isActiveQuoteOpen =
    activeQuote?.status !== 'paid' &&
    activeQuote?.status !== 'completed' &&
    activeQuote?.status !== 'expired' &&
    activeQuote?.status !== 'archived'

  const handleImportQuoteTemplate = async (template: any) => {
    if (!template) return

    try {
      await importQuoteTemplate(template)

      Notifications.send('Quote Template Imported Successfully', 'positive')
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (size(quotes) === 0 || !!activeQuote) return

    const { quote: quoteId }: any = parse(window.location.search)

    if (!!quoteId && quoteId !== activeQuoteId) {
      setActiveQuoteId(quoteId)
    } else if (!activeQuoteId) {
      const firstQuoteId = quotes?.[0]?.id

      setActiveQuoteId(firstQuoteId)

      navigate({
        pathname: location.pathname,
        search: `?quote=${firstQuoteId}`,
      })
    }
  }, [activeQuote, activeQuoteId, quotes])

  return (
    <PageSection className="!grid-cols-[100%]">
      <PageSection.Header
        graphic={
          <div className="flex items-center justify-center w-6 h-6">
            <Icon icon="quotes" size={20} />
          </div>
        }
        after={
          isBehave && (
            <>
              <Flex gap="0.75rem">
                <SummonOverlay overlay={<EHRImportQuoteTemplateOverlay onImport={handleImportQuoteTemplate} />}>
                  <Button label="Import from Quote Templates…" glyph="add" type="default" size={200} />
                </SummonOverlay>

                <SummonOverlay overlay={<QuoteOverlay dataID="new" initialData={{ facility: tenant }} />}>
                  <Button label="Add New Quote" glyph="add" type="primary" size={200} />
                </SummonOverlay>
              </Flex>
            </>
          )
        }
      >
        <Flex centerY gap="0.75rem">
          <PageSection.Title title={size(quotes) === 1 ? 'Quote' : 'Quotes'} />
        </Flex>
      </PageSection.Header>

      {size(quotes) > 0 && (
        <Tabs
          activeTab={activeQuoteId}
          onChange={(tabId) => {
            navigate({
              pathname: location.pathname,
              search: `?quote=${tabId}`,
            })
            setActiveQuoteId(tabId)
          }}
        >
          <PillTabList className="px-0 pb-4 !-m-1">
            {quotes?.map?.((quote: any) => {
              const isDraft = quote?.status === 'draft'
              const isProcessingPayment = quote?.status === 'payment_processing'
              const isPaymentFailed = quote?.status === 'payment_failed'
              const isCompleted = quote?.status === 'paid' || quote?.status === 'completed'
              const isExpired = quote?.status === 'expired'
              const isArchived = quote?.status === 'archived'

              return (
                <PillTab
                  key={quote.id}
                  name={quote.id}
                  className="!bg-white !rounded-[5px] !shadow-soft-2 border-[0.5px] border-solid border-white !m-1 [&.is-active]:ring-2 [&.is-active]:border-blue-400"
                  label={
                    <span className="flex items-center flex-nowrap">
                      {quote?.name}

                      {isDraft ? (
                        <Status small className="ml-1.5" label="Draft" color="blue" glyph="behave_health" />
                      ) : isProcessingPayment ? (
                        <Status small className="ml-1.5" label="Processing Payment" color="orange" />
                      ) : isPaymentFailed ? (
                        <Status small className="ml-1.5" label="Payment Failed" color="red" />
                      ) : isCompleted ? (
                        <Status small className="ml-1.5" label="Done" color="green" />
                      ) : isExpired ? (
                        <Status small className="ml-1.5" label="Expired" color="red" />
                      ) : isArchived ? (
                        <Status small label="Archived" color="gray" glyph="behave_health" className="ml-1.5 [&_svg]:!fill-text" />
                      ) : (
                        <Status small className="ml-1.5" label="New" color="violet" />
                      )}
                    </span>
                  }
                />
              )
            })}
          </PillTabList>
        </Tabs>
      )}

      {isLoadingQuotes || !activeQuote ? (
        <Card>
          <State
            isLoading={isLoadingQuotes}
            isEmpty={!isLoadingQuotes && !activeQuote}
            title="Quote"
            icon="quotes"
            emptyDescription="No quote added yet"
            minHeight={180}
          />
        </Card>
      ) : (
        <div className="grid grid-cols-[100%]">
          <Accordion
            minimal
            isOpen
            icon="quotes"
            title={activeQuote?.name || 'Quote'}
            aside={
              isBehave && (
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <Flex gap="0.75rem">
                    <Button
                      useGlyphForTarget
                      label="Open Public Quote"
                      glyph="link"
                      type="minimal"
                      target="_blank"
                      size={200}
                      href={activeQuote?.public_url}
                    />

                    {activeQuote && <QuoteRefreshVariablesButton data={activeQuote} />}

                    {activeQuote?.status !== 'paid' && activeQuote?.status !== 'completed' && <QuoteReset quoteId={activeQuote?.id} />}

                    <SummonOverlay overlay={<QuoteOverlay dataID={activeQuote?.id} />}>
                      <Button label="Edit Quote" glyph="edit" type="default" size={200} />
                    </SummonOverlay>
                  </Flex>
                </div>
              )
            }
          >
            <QuoteReview
              key={activeQuote?.id}
              isLoading={isLoadingQuotes}
              dataID={activeQuote?.id}
              isSectionsOpen={isActiveQuoteOpen}
              after={
                false && (
                  <>
                    <div className="flex justify-center relative">
                      <Status
                        label="Additional Products"
                        className="!bg-text !text-white !rounded-full !text-[0.85rem] !px-2 !py-0.5 relative z-[3]"
                      />
                      <div className="absolute left-0 right-0 h-[1px] w-full bg-divider top-[50%]" />
                    </div>

                    <div className="px-5 pb-5">
                      <QuoteAdditionalProducts quote={activeQuote} className="mt-6" />
                    </div>
                  </>
                )
              }
            />
          </Accordion>
        </div>
      )}
    </PageSection>
  )
}

const QuoteReset = ({ quoteId }) => {
  const { mutateAsync: resetQuote } = useCreate({
    name: ['reset-quote'],
    url: `/quotes/${quoteId}/reset`,
    invalidate: 'quotes',
  })

  if (!quoteId) return null

  return (
    <DeleteDialog
      title="Reset Quote?"
      glyph="reset"
      message="Are you sure you want to reset this Quote?"
      yesLabel="Reset Quote"
      onYes={async () => await resetQuote({})}
    >
      <Button label="Reset Quote…" glyph="reset" type="default" color="text" glyphColor="red" size={200} />
    </DeleteDialog>
  )
}
