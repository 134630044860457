import React from 'react'
import { darken } from 'polished'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { useSettings } from '../../hooks/useSettings'

import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Loader from '../../components/Loader'
import Tooltip from '../../components/Tooltip'

import { FormSubmissionStatus } from './FormSubmissionStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  pending: {
    label: 'Pending Review',
    color: 'yellow',
    isReviewStatus: true,
  },
  updates_required: {
    label: 'Updates Required',
    color: 'orange',
    isReviewStatus: true,
  },
  in_progress: {
    label: 'In Progress',
    color: 'blue',
    isReviewStatus: true,
  },
  approved: {
    label: 'Approved',
    color: 'paleGreen',
    isReviewStatus: true,
    isSupervisorOnly: true,
  },
  rejected: {
    label: 'Rejected',
    color: 'red',
    isReviewStatus: true,
  },
  closed: {
    label: 'Closed…',
    color: 'green',
  },
  signed_off: {
    label: 'Signed Off…',
    color: 'green',
  },
}

export const FormSubmissionStatusSelector = (props: any) => {
  const {
    allowed,
    isSupervisor,
    submissionData,
    isLoading,
    onUpdate,
    value,
    canSignOff,
    cannotSignOffReasons,
    canCloseOff,
    cannotCloseOffReasons,
    isFinalStatus,
  } = props

  const showReviewStatuses =
    submissionData?.use_review_and_signoff_workflow && submissionData?.review_and_signoff_workflow_settings?.enable_supervisor_review

  const { isBehave, isManagement } = useSettings()

  const isEditable = isFinalStatus ? isBehave || isManagement : true

  if (!isEditable) {
    return (
      <Flex horizontal nowrap centerY gap="0.5rem">
        <div css={styles.triggerLabel}>Status:</div>

        <FormSubmissionStatus inline before={isLoading && <Loader color="text" size={12} css={styles.loader} />} status={value} />
      </Flex>
    )
  }

  return (
    <Dropdown
      position="bottom-start"
      minWidth={200}
      maxWidth={250}
      trigger={
        <Flex horizontal nowrap centerY gap="0.5rem">
          <div css={styles.triggerLabel}>Status:</div>

          <FormSubmissionStatus
            inline
            before={isLoading && <Loader color="text" size={12} css={styles.loader} />}
            status={value}
            after={<Glyph glyph="triangle_down" color="text" size={10} css={styles.glyph} />}
          />
        </Flex>
      }
    >
      {Object.keys(statuses).map((statusKey) => {
        const statusConfig: any = statuses[statusKey]

        if (statusKey === 'closed' && allowed?.signoff) return null

        if (statusKey === 'signed_off' && !allowed?.signoff) return null

        if (!showReviewStatuses && statusConfig.isReviewStatus) return null

        const menuItem = ({ isDisabled }: any) => (
          <DropdownItem
            key={statusKey}
            label={statusConfig.label}
            isActive={value === statusKey}
            css={styles.dropdownItem}
            isDisabled={isDisabled}
            glyphSize={16}
            glyph={isDisabled ? 'lock' : null}
            className="w-full [&>*]:flex-[1_1_auto] [&.is-disabled_.button-label]!opacity-80"
            onClick={() => {
              if (isDisabled) return

              onUpdate?.(statusKey)
            }}
            after={isDisabled && <Glyph size={16} color={`${darken(0.03, COLORS.orange)} !important`} glyph="info" />}
          />
        )

        if (statusConfig.isSupervisorOnly && !isSupervisor) {
          return (
            <Tooltip
              color={COLORS.orange}
              className="w-full"
              glyph="info"
              content={
                <div className="flex flex-nowrap items-center">
                  <Glyph glyph="info" color={darken(0.05, COLORS.orange)} size={16} className="mr-1.5" />
                  Only a supervisor can approve this Form Submission
                </div>
              }
            >
              {menuItem({ isDisabled: true })}
            </Tooltip>
          )
        }

        if (statusKey === 'signed_off' && !canSignOff) {
          return (
            <Tooltip
              show={!canSignOff}
              color={COLORS.orange}
              className="w-full"
              content={
                size(cannotSignOffReasons) > 0 && (
                  <div className="grid gap-2">
                    <div className="font-[600]">Sign-Off Requirements:</div>

                    {cannotSignOffReasons.map((message) => (
                      <div key={message} className="flex flex-nowrap items-center">
                        <Glyph glyph="info" color={darken(0.05, COLORS.orange)} size={16} className="mr-1.5" />
                        {message}
                      </div>
                    ))}
                  </div>
                )
              }
            >
              {menuItem({ isDisabled: true })}
            </Tooltip>
          )
        }

        if (statusKey === 'closed' && !canCloseOff) {
          return (
            <Tooltip
              show={!canCloseOff}
              color={COLORS.orange}
              className="w-full"
              content={
                size(cannotCloseOffReasons) > 0 && (
                  <div className="grid gap-2">
                    <div className="font-[600]">Close-Off Requirements:</div>

                    {cannotCloseOffReasons.map((message) => (
                      <div key={message} className="flex flex-nowrap items-center">
                        <Glyph glyph="info" color={darken(0.05, COLORS.orange)} size={16} className="mr-1.5" />
                        {message}
                      </div>
                    ))}
                  </div>
                )
              }
            >
              {menuItem({ isDisabled: true })}
            </Tooltip>
          )
        }

        return menuItem({ isDisabled: false })
      })}
    </Dropdown>
  )
}

const styles = {
  triggerLabel: {
    fontWeight: 600,
    marginRight: '0.5rem',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },

  glyph: {
    marginRight: '0.5rem',
  },

  dropdownItem: {
    minHeight: 32,
  },

  loader: {
    marginLeft: '0.3rem',
  },
}
