import React from 'react'
import clsx from 'clsx'

const PillTabList = ({ caps, isCentered, className, children, style, testKey }) => {
	const classNames = clsx({
		caps: caps,
		'is-centered': isCentered,
		[className]: className
	})

	return (
		<div data-test={testKey} css={styles} className={classNames} style={style}>
			{children}
		</div>
	)
}

const styles = {
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',

	'&.is-centered': {
		justifyContent: 'center'
	}
}

export default PillTabList
