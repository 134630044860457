import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const PARTNERSHIP_STATUSES = {
  not_invited: {
    label: 'Not Invited',
    color: 'gray',
  },
  invited: {
    label: 'Invite Requested',
    color: 'orange',
  },
  suggested: {
    label: 'Suggested',
    color: 'orange',
  },
  partnership_active: {
    label: 'Partnership Active',
    color: 'green',
  },
  partnership_inactive: {
    label: 'Partnership Inactive',
    color: 'gray',
  },
}

export const PartnershipStatus = ({ ...rest }) => {
  return <SmartStatus statuses={PARTNERSHIP_STATUSES} {...rest} />
}
