import React from 'react'

import Divider from '../../../../Divider'
import Grid from '../../../../Grid'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'

import Score from './Score'

const SCORING = {
  9: 'very mild withdrawal',
  15: 'mild withdrawal',
  20: 'modest withdrawal',
  67: 'severe withdrawal',
}

const CIWASymptoms = () => {
  const [score, setScore] = React.useState({
    nausea_vomiting: 0,
    tremors: 0,
    paroxysmal_sweats: 0,
    anxiety: 0,
    agitation: 0,
    tactile_disturbances: 0,
    auditory_disturbances: 0,
    visual_disturbances: 0,
    headache_fullness_in_head: 0,
    orientation_clouding_of_sensorium: 0,
  })

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  return (
    <>
      <Grid gap={32}>
        <RadioGroup
          layout="vertical-dense"
          label="Nausea/vomiting"
          description="During the last 30 minutes"
          model="data.ciwa_symptoms.nausea_vomiting"
          onUpdate={(state: any) => setScore((o) => ({ ...o, nausea_vomiting: state.value }))}
        >
          <Radio label="0 = None present" value={0} />
          <Radio label="1 = Mild nausea and no vomiting" value={1} />
          <Radio label="2 = Slightly more than 1, not quite 3" value={2} />
          <Radio label="3 = Slightly more than 2, not quite 4" value={3} />
          <Radio label="4 = Intermittent nausea with dry heaves" value={4} />
          <Radio label="5 = Slightly more than 4, not quite 6" value={5} />
          <Radio label="6 = Slightly more than 5, not quite 7" value={6} />
          <Radio label="7 = Constant nausea, frequent dry heaves and vomiting" value={7} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Tremors"
          description="Observation of outstretched hands"
          model="data.ciwa_symptoms.tremors"
          onUpdate={(state: any) => setScore((o) => ({ ...o, tremors: state.value }))}
        >
          <Radio label="0 = None present" value={0} />
          <Radio label="1 = Tremor can be felt, but not observed" value={1} />
          <Radio label="2 = Slightly more than 1, not quite 3" value={2} />
          <Radio label="3 = Slightly more than 2, not quite 4" value={3} />
          <Radio label="4 = Moderate with patient's arms extended" value={4} />
          <Radio label="5 = Slightly more than 4, not quite 6" value={5} />
          <Radio label="6 = Slightly more than 5, not quite 7" value={6} />
          <Radio label="7 = Severe, even with arms not extended" value={7} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Paroxysmal sweats"
          description="Sweating not accounted for by room temperature or patient activity over the last 30 minutes"
          model="data.ciwa_symptoms.paroxysmal_sweats"
          onUpdate={(state: any) => setScore((o) => ({ ...o, paroxysmal_sweats: state.value }))}
        >
          <Radio label="0 = No sweat visible" value={0} />
          <Radio label="1 = Barely perceptible sweating, palms moist" value={1} />
          <Radio label="2 = Slightly more than 1, not quite 3" value={2} />
          <Radio label="3 = Slightly more than 2, not quite 4" value={3} />
          <Radio label="4 = Beads of sweat obvious on forehead" value={4} />
          <Radio label="5 = Slightly more than 4, not quite 6" value={5} />
          <Radio label="6 = Slightly more than 5, not quite 7" value={6} />
          <Radio label="7 = Drenching sweats" value={7} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Anxiety"
          model="data.ciwa_symptoms.anxiety"
          description="Ask, 'Do you feel nervous?'"
          onUpdate={(state: any) => setScore((o) => ({ ...o, anxiety: state.value }))}
        >
          <Radio label="0 = No anxiety, at ease" value={0} />
          <Radio label="1 = Mildly anxious" value={1} />
          <Radio label="2 = Slightly more than 1, not quite 3" value={2} />
          <Radio label="3 = Slightly more than 2, not quite 4" value={3} />
          <Radio label="4 = Moderately anxious, or guarded, so anxiety is inferred" value={4} />
          <Radio label="5 = Slightly more than 4, not quite 6" value={5} />
          <Radio label="6 = Slightly more than 5, not quite 7" value={6} />
          <Radio label="7 = Equivalent to acute panic states as seen in severe delirium or acute schizophrenic reactions" value={7} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Agitation"
          model="data.ciwa_symptoms.agitation"
          onUpdate={(state: any) => setScore((o) => ({ ...o, agitation: state.value }))}
        >
          <Radio label="0 = Normal activity" value={0} />
          <Radio label="1 = Somewhat more activity than normal" value={1} />
          <Radio label="2 = Slightly more than 1, not quite 3" value={2} />
          <Radio label="3 = Slightly more than 2, not quite 4" value={3} />
          <Radio label="4 = Moderately fidgety and restless" value={4} />
          <Radio label="5 = Slightly more than 4, not quite 6" value={5} />
          <Radio label="6 = Slightly more than 5, not quite 7" value={6} />
          <Radio label="7 = Paces back and forth during most of the interview, or constantly thrashes about" value={7} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Tactile disturbances"
          description="Ask, 'Have you any itching, pins and needles sensations, any burning, any numbness, or do you feel bugs crawling on or under your skin?'"
          model="data.ciwa_symptoms.tactile_disturbances"
          onUpdate={(state: any) => setScore((o) => ({ ...o, tactile_disturbances: state.value }))}
        >
          <Radio label="0 = None" value={0} />
          <Radio label="1 = Very mild itching, pin and needles, burning, or numbness" value={1} />
          <Radio label="2 = Mild itching, pin and needles, burning, or numbness" value={2} />
          <Radio label="3 = Moderate itching, pin and needles, burning, or numbness" value={3} />
          <Radio label="4 = Moderately severe hallucinations" value={4} />
          <Radio label="5 = Severe hallucinations" value={5} />
          <Radio label="6 = Extremely severe hallucinations" value={6} />
          <Radio label="7 = Continuous hallucinations" value={7} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Auditory disturbances"
          description="Ask, 'Are you more aware of sounds around you? Are they harsh? Do they frighten you? Are you hearing anything that is disturbing to you? Are you hearing things you know are not there?'"
          model="data.ciwa_symptoms.auditory_disturbances"
          onUpdate={(state: any) => setScore((o) => ({ ...o, auditory_disturbances: state.value }))}
        >
          <Radio label="0 = Not present" value={0} />
          <Radio label="1 = Very mild harshness or ability frighten" value={1} />
          <Radio label="2 = Mild harshness or ability or frighten" value={2} />
          <Radio label="3 = Moderate harshness or ability or frighten" value={3} />
          <Radio label="4 = Moderately severe hallucinations" value={4} />
          <Radio label="5 = Severe hallucinations" value={5} />
          <Radio label="6 = Extremely severe hallucinations" value={6} />
          <Radio label="7 = Continuous hallucinations" value={7} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Visual disturbances"
          description="Ask 'Does the light appear to be too bright? Is its color different? Does it hurt your eyes? Are you seeing anything that is disturbing to you? Are you seeing things you know are not there?'"
          model="data.ciwa_symptoms.visual_disturbances"
          onUpdate={(state: any) => setScore((o) => ({ ...o, visual_disturbances: state.value }))}
        >
          <Radio label="0 = Not present" value={0} />
          <Radio label="1 = Very mild sensitivity" value={1} />
          <Radio label="2 = Mild sensitivity" value={2} />
          <Radio label="3 = Moderate sensitivity" value={3} />
          <Radio label="4 = Moderately severe hallucinations" value={4} />
          <Radio label="5 = Severe hallucinations" value={5} />
          <Radio label="6 = Extremely severe hallucinations" value={6} />
          <Radio label="7 = Continuous hallucinations" value={7} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Headache/fullness in head"
          description="Ask 'Does your head feel different? Does it feel like there is a band around your head?' Do not rate for dizziness or lightheadedness. Otherwise, rate 'severity.'"
          model="data.ciwa_symptoms.headache_fullness_in_head"
          onUpdate={(state: any) => setScore((o) => ({ ...o, headache_fullness_in_head: state.value }))}
        >
          <Radio label="0 = Not present" value={0} />
          <Radio label="1 = Very mild" value={1} />
          <Radio label="2 = Mild" value={2} />
          <Radio label="3 = Moderate" value={3} />
          <Radio label="4 = Moderately severe" value={4} />
          <Radio label="5 = Severe" value={5} />
          <Radio label="6 = Very severe" value={6} />
          <Radio label="7 = Extremely severe" value={7} />
        </RadioGroup>

        <RadioGroup
          layout="vertical-dense"
          label="Orientation/clouding of sensorium"
          description="Ask 'What day is this? Where are you? Who am I?'"
          model="data.ciwa_symptoms.orientation_clouding_of_sensorium"
          onUpdate={(state: any) => setScore((o) => ({ ...o, orientation_clouding_of_sensorium: state.value }))}
        >
          <Radio label="0 = Oriented, can do serial additions" value={0} />
          <Radio label="1 = Can't do serial additions or is uncertain about date" value={1} />
          <Radio label="2 = Disoriented for date by no more than 2 calendar days" value={2} />
          <Radio label="3 = Disoriented for date by more than 2 calendar days" value={3} />
          <Radio label="4 = Disoriented to place or person" value={4} />
        </RadioGroup>
      </Grid>

      <Divider />

      <Score title="CIWA Score" finalScore={finalScore} scoring={SCORING} />
    </>
  )
}

export default CIWASymptoms
