import React from 'react'
import { useMedia } from 'use-media'
import produce from 'immer'

import { ICONS } from '../../theme'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate, getPrefix } from '../../hooks/useNewAPI'
import startCase from 'lodash/startCase'
import withSettings from '../../hocs/withSettings'

import ClientColorPicker from '../ColorPickers/ClientColorPicker'
import Flex from '../Flex'
import HelpTagIframe from '../Help/HelpTagIframe'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'
import Textarea from '../Forms/Textarea'

import AdmissionQuickView from '../Overlays/quick/AdmissionQuickView'
import IntakeStatus from '../Statuses/IntakeStatus'

const mainColumn = (to) => ({
  width: 240,
  isSticky: true,
  canToggleVisible: false,
  accessor: 'name',
  Header: 'Name',
  Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} avatar={row.original.avatar} />,
})

const columns = (to: Function = () => {}, isDesktop: boolean, showERXFeatures: boolean, apiKey: any, timezone: any) => [
  ...(isDesktop ? [mainColumn(to)] : []),
  {
    width: isDesktop ? 160 : 240,
    disableFilters: true,
    disableSortBy: true,
    id: 'quick_view',
    accessor: 'id',
    Header: 'Quick View',
    Cell: ({ row }: any) => (
      <TableCell.QuickViewOverlay overlay={<AdmissionQuickView client={row.original} />}>
        {!isDesktop && <TableCell.MainLink label={row.original.name} avatar={row.original.avatar} glyphAfter="quick_view" />}
      </TableCell.QuickViewOverlay>
    ),
  },
  {
    width: 200,
    Header: 'Tags',
    accessor: 'tags',
    disableSortBy: true,
    Cell: ({ value, row }: any) => {
      const { mutateAsync, isLoading }: any = useUpdate({
        name: ['client', row.original.id],
        url: `/residents/${row.original.id}`,
        invalidate: ['clients', { status: 'intake' }],
        invalidateKeys: ['tag-live-reports'],
        onSuccess: (_data: any, variables: any, queryClient: any) => {
          const prefix = getPrefix()

          // update clients list
          queryClient.setQueryData([prefix, apiKey].flat(), (oldData: any) => {
            if (!oldData?.data) return

            const index = oldData.data.findIndex((o) => o.id === row.original.id)
            if (index === -1) return

            const newData = produce(oldData, (draft: any) => {
              draft.data[index].tags = variables.tags
            })

            return newData
          })
        },
      })

      return (
        <TableCell.Tags
          value={value}
          save={(tag_ids: any, tags: any) => mutateAsync({ tag_ids, tags })}
          isLoading={isLoading}
          smartCategories="accepted"
        />
      )
    },
  },
  {
    width: 140,
    accessor: 'color_code',
    Header: 'Color',
    Cell: ({ row, value }: any) => (
      <Flex centerX>
        <ClientColorPicker name="leads" clientID={row.original?.id} color={value} css={{ margin: '0 auto' }} />
      </Flex>
    ),
  },
  {
    width: 200,
    accessor: 'current_intake_application.created_at',
    Header: 'Last Application Date',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 220,
    id: 'admission_status',
    accessor: 'current_admission_status',
    Header: 'Admission Status',
    Cell: ({ value }: any) => (value ? <IntakeStatus status={value} /> : '–'),
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'pending',
        label: 'Pending',
      },
      {
        value: 'incoming',
        label: 'Incoming',
      },
      {
        value: 'waitlist',
        label: 'Waitlisted',
      },
      {
        value: 'no_show',
        label: 'No-show',
      },
      {
        value: 'declined',
        label: 'Declined',
      },
      {
        value: 'admitted',
        label: 'Admitted',
      },
      {
        value: 'discharged',
        label: 'Discharged',
      },
      {
        value: 'closed',
        label: 'Closed',
      },
    ],
  },
  {
    width: 400,
    accessor: 'current_admission_notes',
    Header: 'Admission Notes',
    Cell: ({ value, row }: any) => (
      <TableCell
        value={value}
        edit={{
          initialModel: { notes: value },
          input: <Textarea label="Admission Notes" model="notes" />,
          options: {
            name: ['admissions', row.original.current_admission_id],
            url: `/admissions/${row.original.current_admission_id}`,
            invalidate: ['clients'],
          },
        }}
      />
    ),
  },
  {
    width: 140,
    accessor: 'behave_id',
    Header: 'Client ID',
    Cell: ({ value }: any) => <TableCell value={value && `#${value}`} />,
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 240,
    accessor: 'email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
  {
    width: 100,
    accessor: 'sex',
    Header: 'Sex',
    Cell: ({ value }: any) => <TableCell value={startCase(value)} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'male',
        label: 'Male',
      },
      {
        value: 'female',
        label: 'Female',
      },
      {
        value: 'decline_to_respond',
        label: 'Decline To Respond',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ],
  },
  ...(showERXFeatures
    ? [
        {
          width: 140,
          accessor: 'erx_status',
          Header: 'Synced with eRX',
          Cell: ({ value }: any) => {
            if (value === 'synced') return 'Yes'
            else if (value === 'not_synced') return 'No'

            return '–'
          },
          Filter: TableFilter.Select,
          filter: 'boolean',
          filterOptions: [
            {
              value: true,
              label: 'Synced',
            },
            {
              value: false,
              label: 'Not Synced',
            },
          ],
        },
      ]
    : []),
  {
    width: 220,
    accessor: 'referral_source',
    Header: 'Referral In',
    Cell: ({ value }: any) => <TableCell.IconValue icon={ICONS.properties} value={value?.name} />,
  },
  {
    width: 220,
    accessor: 'referral_notes',
    Header: 'Referral In Notes',
    Cell: ({ value }: any) => <TableCell value={value} />,
  },
  {
    width: 220,
    accessor: 'referral_source',
    Header: 'Referral Out',
    Cell: ({ value }: any) => <TableCell.IconValue icon={ICONS.properties} value={value?.name} />,
  },
  {
    width: 220,
    accessor: 'referral_out_notes',
    Header: 'Referral Out Notes',
    Cell: ({ value }: any) => <TableCell value={value} />,
  },
  {
    width: 140,
    accessor: 'last_name',
    Header: 'Last Name',
    Cell: ({ value }: any) => <TableCell value={value} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  tenant?: Object
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const IntakesTable = (props: Props) => {
  const { apiKey, to, data, isLoading, emptyActions, hiddenColumns, localStorageKey, batchActionsConfig, tenant, titleAfter, ...rest } =
    props

  const { timezone } = useSettings()
  const isDesktop = useMedia({ minWidth: 600 })
  const showERXFeatures = tenant?.feature_flags?.erx === true

  return (
    <Table
      title="Accepted Applicant"
      titleAfter={titleAfter}
      icon="intake_forms"
      data={data}
      columns={columns(to, isDesktop, showERXFeatures, apiKey, timezone)}
      hiddenColumns={hiddenColumns}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No accepted applications to display"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      help={<HelpTagIframe id="admissions_accepted" />}
      {...rest}
    />
  )
}

IntakesTable.defaultProps = {
  localStorageKey: 'intakes',
}

export default withSettings(IntakesTable)
