import React from 'react'

import { COLORS } from '../../theme'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Glyph from '../../components/Glyph'

import ApplicationStatus, { statuses as APPLICATION_STATUSES } from '../../components/Statuses/ApplicationStatus'
import ApplicationReviewStatus, { statuses as REVIEW_STATUSES } from '../../components/Statuses/ApplicationReviewStatus'

import { useSettings } from '../../hooks/useSettings'
import { usDateTime } from '../../utils/functions'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { DEFAULT_FILTERS } from '../Filters/constants'

export const IntakeApplicationsDataTable = (props: any) => {
  const { to } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Date & Time',
        model: 'applied_at',
        width: 200,
        disableHide: true,
        // disableSort: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={usDateTime(value, timezone)} to={to?.(data)} />,
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <ApplicationStatus status={value} />,
      },
      {
        title: 'Application Review Status',
        model: 'review_status',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <ApplicationReviewStatus status={value} />
        },
      },
      {
        title: 'Application Form Used',
        model: 'application_form.id',
        disableSort: true,
        formatValue: ({ data, value }: any) => {
          if (!value) return null

          return (
            <LinkCell
              isExternal
              value={data?.application_form?.name}
              to={`/settings/application-forms/${value}`}
              before={<Glyph glyph="settings" size={14} color={COLORS.blue} className="mr-1.5" />}
            />
          )
        },
      },
      {
        title: 'Last Reminded At',
        model: 'reminded_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ]
  }, [to])

  return (
    <>
      <DataTable asCard title="Applications" icon="applications" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
    </>
  )
}

const FILTERS_CONFIG = {
  applied_at: {
    title: 'Date & Time',
    type: 'date_time',
  },
  status: {
    title: 'Status',
    type: 'multi_select',
    options: Object.entries(APPLICATION_STATUSES).map(([value, { label }]) => ({ value, label })),
  },
  review_status: {
    title: 'Application Review Status',
    type: 'multi_select',
    options: Object.entries(REVIEW_STATUSES).map(([value, { label }]) => ({ value, label })),
  },
  author: DEFAULT_FILTERS.author,
  created_at: {
    label: 'Date Added',
    type: 'date_time',
  },
  updated_at: {
    label: 'Date Updated',
    type: 'date_time',
  },
  reminded_at: {
    label: 'Last Reminded At',
    type: 'date_time',
  },
}
