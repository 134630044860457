import React from 'react'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

export const TreatmentPlanRecordStatus = ({ label = 'Status', model = 'status', defaultValue = 'in_progress', ...rest }) => {
  return (
    <Select label={label} model={model} defaultValue={defaultValue} {...rest}>
      <Option label="Inactive" value="inactive" />
      <Option label="In Progress" value="in_progress" />
      <Option label="Completed" value="completed" />
      <Option label="Extended" value="extended" />
      <Option label="Cancelled" value="cancelled" />
      <Option label="Unmet" value="unmet" />
    </Select>
  )
}
