import React from 'react'

import { useSettings } from '../../hooks/useSettings'

import Flex from '../Flex'
import NoInternet from '../Alerts/NoInternet'

type Props = {
  children?: React.ReactNode
  className?: any
  online: boolean
  withGradient?: boolean
}

const OverlayFooter = (props: Props) => {
  const { online } = useSettings()
  const isOnline = props.online || online

  const { withGradient = true } = props

  return (
    <div css={styles} className={props.className}>
      <div css={styles.gradient} className={withGradient ? 'with-gradient' : 'without-gradient'}></div>
      {!isOnline && <NoInternet />}

      {isOnline && (
        <Flex gap={8} stretchChildrenX>
          {props.children}
        </Flex>
      )}
    </div>
  )
}

const styles = {
  display: 'grid',
  gridGap: '1rem',
  gridAutoFlow: 'column',
  flex: '0 0 auto',

  padding: '1rem 1.5rem',
  paddingTop: 0,

  position: 'relative',
  zIndex: 100,
  background: '#f7f8fb',

  gradient: {
    width: '100%',
    height: 28,
    position: 'absolute',
    top: -28,
    left: 0,

    // skip pointer events (scrolling)
    pointerEvents: 'none',

    '&.with-gradient': {
      backgroundImage: 'linear-gradient(0deg, #f7f8fb, #f7f8fb00)',
    },
  },
}

export default OverlayFooter
