import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useSettings } from '../../hooks'

import FileStatus from '../Statuses/FileStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const previewColumn = (match: any) => ({
  width: 130,
  id: 'view_file',
  Header: 'View File',
  disableSortBy: true,
  Cell: ({ row }: any) => (
    <TableCell.Link
      label="View File"
      to={{
        pathname: `${match.url}/preview-${row.original.id}`,
        parent: match,
      }}
    />
  ),
})

const columns = (to: Function = () => {}, match: any, isEHRApp: boolean, showPreviewColumn: boolean, timezone: string) => [
  {
    width: 300,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'title',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value || row.original?.upload_name} />,
  },
  ...(isEHRApp && showPreviewColumn ? [previewColumn(match)] : []),
  {
    width: 140,
    id: 'file_type',
    accessor: 'content_type',
    Header: 'Type',
    Cell: ({ value }: any) => <FileStatus status={value} />,
  },
  {
    width: 140,
    disableFilters: true,
    accessor: 'file_tags',
    Header: 'Tags',
    Cell: ({ value }: any) => {
      if (!value || value.length === 0) return <TableCell.Empty />
      return value.map((tag: any) => tag.name).join(', ')
    },
  },
  {
    width: 140,
    accessor: 'notes',
    Header: 'Notes',
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
  showPreviewColumn?: boolean
}

const FilesTable = (props: Props) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    batchActionsConfig,
    title,
    to,
    titleAfter,
    showPreviewColumn = true,
    testKey,
    ...rest
  } = props

  const match = useRouteMatch()
  const { isEHRApp, timezone } = useSettings()

  return (
    <Table
      testKey={testKey}
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, match, isEHRApp, showPreviewColumn, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

FilesTable.defaultProps = {
  title: 'Files',
  icon: 'files',
  emptyDescription: 'No files added yet',
  localStorageKey: 'files',
}

export default FilesTable
