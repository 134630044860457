import React from 'react'
import { useLocation } from 'react-router-dom'

import { H4 } from '../../components/Typography'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const SCORING = {
  4: 'minimal anxiety',
  9: 'mild anxiety',
  14: 'moderate anxiety',
  21: 'severe anxiety',
}

const INITIAL_STATE = {
  nervous: 0,
  control_worrying: 0,
  over_worrying: 0,
  trouble_relaxing: 0,
  restless: 0,
  annoyed: 0,
  afraid: 0,
}

const RootGAD7Overlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="GAD-7 Anxiety"
      category="clinical_measurement"
      subcategory="gad7"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <H4>Over the last 2 weeks, how often have you been bothered by any of the following problems?</H4>

        <RadioGroup
          label="Feeling nervous, anxious or on edge"
          model="data.questionnaire.nervous"
          layout="vertical-dense"
          onUpdate={(state) => setScore((o) => ({ ...o, nervous: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Not being able to stop or control worrying"
          model="data.questionnaire.control_worrying"
          layout="vertical-dense"
          onUpdate={(state) => setScore((o) => ({ ...o, control_worrying: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Worrying too much about different things"
          model="data.questionnaire.over_worrying"
          layout="vertical-dense"
          onUpdate={(state) => setScore((o) => ({ ...o, over_worrying: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Trouble relaxing"
          model="data.questionnaire.trouble_relaxing"
          layout="vertical-dense"
          onUpdate={(state) => setScore((o) => ({ ...o, trouble_relaxing: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Being so restless that it is hard to sit still"
          model="data.questionnaire.restless"
          layout="vertical-dense"
          onUpdate={(state) => setScore((o) => ({ ...o, restless: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Becoming easily annoyed or irritable"
          model="data.questionnaire.annoyed"
          layout="vertical-dense"
          onUpdate={(state) => setScore((o) => ({ ...o, annoyed: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="Feeling afraid as if something awful might happen"
          model="data.questionnaire.afraid"
          layout="vertical-dense"
          onUpdate={(state) => setScore((o) => ({ ...o, afraid: state.value }))}
        >
          <Radio label="0 = Not at all" value={0} />
          <Radio label="1 = Several days" value={1} />
          <Radio label="2 = More than half the days" value={2} />
          <Radio label="3 = Nearly every day" value={3} />
        </RadioGroup>

        <RadioGroup
          label="If you checked any problems, how difficult have they made it for you to do your work, take care of things at home, or get along with other people?"
          model="data.difficulty"
          layout="vertical-dense"
        >
          <Radio label="Not difficult at all" value={0} />
          <Radio label="Somewhat difficult" value={1} />
          <Radio label="Very difficult" value={2} />
          <Radio label="Extremely difficult" value={3} />
        </RadioGroup>
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Score title="Anxiety Severity Score" finalScore={finalScore} scoring={SCORING} />
        </>
      )}
    </>
  )
}

export const GAD7Overlay = withOverlayError(RootGAD7Overlay)
