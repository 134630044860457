import React from 'react'

import FormSection from '../../../../Forms/FormSection'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import DateInput from '../../../../Forms/DateInput'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'

const RecurringRelapsePotential = () => {
  return (
    <FormSection layout="vertical">
      <RadioGroup
        label="What is the client's current craving level? (1 = low, 5 = high)"
        model="data.relapse_potential.craving_level"
        layout="horizontal"
      >
        <Radio label="1" value="1" />
        <Radio label="2" value="2" />
        <Radio label="3" value="3" />
        <Radio label="4" value="4" />
        <Radio label="5" value="5" />
      </RadioGroup>

      <SmartTextarea
        useDictation
        label="What types of things trigger the client to want to relapse?"
        model="data.relapse_potential.what_triggers_client_to_relapse"
      />
      <SmartTextarea
        useDictation
        label="How is treatment helping to manage and reduce the effect of triggers?"
        model="data.relapse_potential.how_is_treatment_helping_with_triggers"
      />
      <SmartTextarea
        useDictation
        label="How is facility/providers helping client to meet these specific goal?"
        model="data.relapse_potential.how_is_facility_helping_to_meet_goals"
      />

      <SmartTextarea
        useDictation
        label="List any triggers the client has identified: "
        model="data.relapse_potential.identified_triggers"
      />
      <SmartTextarea
        useDictation
        label="List any coping skills learned or demonstrated by the client: "
        model="data.relapse_potential.coping_triggers"
      />
      <SmartTextarea
        useDictation
        label="What is the client's current risk of relapse (low, medium, high) and why?"
        model="data.relapse_potential.risk_of_relapse"
      />
      <DateInput label="Date of last use:" model="data.relapse_potential.date_of_last_use" />
    </FormSection>
  )
}

export default RecurringRelapsePotential
