import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'

import Button from '../../components/Button'
import DateInput from '../../components/Forms/DateInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SummonOverlay from '../../components/SummonOverlay'

import { useSettings } from '../../hooks/useSettings'

import { DiscountOverlay } from '../InternalBilling/DiscountOverlay'
import { ProductOverlay } from '../InternalBilling/ProductOverlay'
import { QuoteDiscountsImportOverlay } from '../QuoteElements/QuoteDiscountsImportOverlay'
import { QuoteProductsImportOverlay } from '../QuoteElements/QuoteProductsImportOverlay'

export const SubscriptionBuilderOverlay = (props: any) => {
  const {
    cancel,
    close,
    edit,
    form,
    initialModel,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'subscription',
    endpoint: '/subscriptions',
    options: props,
    invalidateKeys: ['subscriptions'],
  })

  const { timezone } = useSettings()

  const [product, setProduct] = React.useState(null)
  const [discount, setDiscount] = React.useState(null)

  const save = async () => {
    const formData = form.current?.getFormValue()

    await saveWithData({
      ...formData,
      product_id: product?.id,
      discount_id: discount?.id,
    })
  }

  if (isOverlayLoading) return <OverlayLoader position="right" maxWidth={34} />

  return (
    <Overlay maxWidth={34} showBackdrop={isEditable} onClose={close}>
      <Overlay.Header icon="certificate" title="Subscription" />

      <Overlay.Content>
        <Section>
          <Form
            getForm={form}
            onValidationUpdate={onValidationUpdate}
            initialModel={initialModel}
            isEditable={isEditable}
            timezone={timezone}
          >
            <FormSection>
              <div>
                <Label label="Product" isRequired isValid={!!product?.id} />

                <Input
                  isEditable={false}
                  value={product?.name}
                  validations={{
                    presence: {
                      message: 'Please add a product',
                    },
                  }}
                />
              </div>

              <Flex gap="0.75rem" stretchChildrenX>
                {product?.id ? (
                  <SummonOverlay
                    className="flex-[0_0_auto]"
                    overlay={
                      <ProductOverlay
                        dataID={product?.id}
                        onSaveSuccessful={(newProduct) => {
                          setProduct(newProduct)
                        }}
                      />
                    }
                  >
                    <Button
                      label="Edit Product"
                      size={100}
                      type="default"
                      glyph="edit"
                      display="inline-flex"
                      className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                    />
                  </SummonOverlay>
                ) : (
                  <SummonOverlay
                    className="flex-[0_0_auto]"
                    overlay={
                      <ProductOverlay
                        onSaveSuccessful={(newProduct) => {
                          setProduct(newProduct)
                        }}
                      />
                    }
                  >
                    <Button
                      label="Add Product"
                      size={100}
                      type="default"
                      glyph="add"
                      display="inline-flex"
                      className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                    />
                  </SummonOverlay>
                )}

                <SummonOverlay
                  className="flex-[0_0_auto]"
                  overlay={
                    <QuoteProductsImportOverlay
                      onImport={(newProduct) => {
                        setProduct(newProduct)
                      }}
                    />
                  }
                >
                  <Button
                    label="Import Product"
                    size={100}
                    type="default"
                    glyph="add_file"
                    display="inline-flex"
                    className="normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                  />
                </SummonOverlay>
              </Flex>

              <div>
                <Label label="Discount" />
                <Input
                  isEditable={false}
                  // label="Discount"
                  value={discount?.name}
                  validations={{
                    presence: {
                      message: 'Please add a discount',
                    },
                  }}
                />
              </div>

              <Flex gap="0.75rem" stretchChildrenX>
                {discount?.id ? (
                  <SummonOverlay
                    className="flex-[0_0_auto]"
                    overlay={
                      <DiscountOverlay
                        dataID={discount?.id}
                        onSaveSuccessful={(newDiscount) => {
                          setDiscount(newDiscount)
                        }}
                      />
                    }
                  >
                    <Button
                      label="Edit Discount"
                      size={100}
                      type="default"
                      glyph="edit"
                      display="inline-flex"
                      className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                    />
                  </SummonOverlay>
                ) : (
                  <SummonOverlay
                    className="flex-[0_0_auto]"
                    overlay={
                      <DiscountOverlay
                        onSaveSuccessful={(newDiscount) => {
                          setDiscount(newDiscount)
                        }}
                      />
                    }
                  >
                    <Button
                      label="Add Discount"
                      size={100}
                      type="default"
                      glyph="add"
                      display="inline-flex"
                      className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                    />
                  </SummonOverlay>
                )}

                <SummonOverlay
                  className="flex-[0_0_auto]"
                  overlay={
                    <QuoteDiscountsImportOverlay
                      onImport={(newDiscount) => {
                        setDiscount(newDiscount)
                      }}
                    />
                  }
                >
                  <Button
                    label="Import Discount"
                    size={100}
                    type="default"
                    glyph="add_file"
                    display="inline-flex"
                    className="flex-[0_0_auto] normal-case tracking-[0] !font-[600] !text-[0.9rem] self-center"
                  />
                </SummonOverlay>
              </Flex>

              <Select allowEmpty label="Status" model="status" defaultValue="active">
                <Option label="Active" value="active" />
                <Option label="Cancelled" value="canceled" />
                <Option label="Unpaid" value="unpaid" />
              </Select>

              <DateInput
                label="Current Billing Period Start Day"
                model="current_period_start"
                validations={{
                  presence: {
                    message: 'Please enter a Start Day',
                  },
                }}
              />

              <DateInput
                label="Current Billing Period End Day"
                model="current_period_end"
                validations={{
                  presence: {
                    message: 'Please enter an End Day',
                  },
                }}
              />
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid || !product?.id}
            />

            {!isNew && <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" />
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}
