import React from 'react'

import { H3, P } from '../Typography'
import Form from '../Forms/Form'
import Glyph from '../Glyph'
import Grid from '../Grid'
import Status from '../Status'

import Card from '../Card'
import Checkbox from '../Forms/Checkbox'
import ContextShow from '../Forms/ContextShow'
import Input from '../Forms/Input'
import MiniRichTextEditor from '../Forms/MiniRichTextEditor'
import Switch from '../Forms/Switch'
import TableArrayForm from '../Forms/TableArrayForm'

import { data } from './data'

type GroupProps = {
  children: React.ReactNode
  glyph: string
  isEditable: boolean
  isOpen?: boolean
  isRequired: boolean
  model: string
  subtitle: string
  title: string
}

type ApplicationFormSettingsProps = {
  className: string
  companyName: string
  form: any
  initialModel: any
  isEditable: boolean
  onValidationUpdate: Function
  timezone: string
}

const Group = (props: GroupProps) => {
  const { title, subtitle, glyph, children, isRequired, isEditable, model, isOpen } = props
  const [open, setOpen] = React.useState(isOpen)

  return (
    <section>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        {glyph && <Glyph glyph={glyph} css={{ marginRight: '0.75rem' }} />}
        <div>
          <H3>{title}</H3>

          {subtitle && (
            <div className="overflow-hidden" style={{ height: open ? 'auto' : 0, opacity: open ? 1 : 0 }}>
              <P color="textMuted">{subtitle}</P>
            </div>
          )}

          <div className="overflow-hidden" style={{ height: open ? 0 : 'auto', opacity: open ? 0 : 1 }}>
            <P color="textMuted">Will not show up on the application form</P>
          </div>
        </div>

        {isEditable && isRequired && <Status label="Required" color="red" css={{ marginLeft: 'auto' }} />}

        <div css={{ marginLeft: 'auto', display: isRequired || !isEditable ? 'none' : 'block' }}>
          <Switch
            withHover={false}
            size={200}
            label={false}
            value={open}
            model={`${model}.show`}
            onUpdate={(state) => setOpen(state.value)}
          />
        </div>
      </div>

      <div style={{ overflow: open ? 'visible' : 'hidden', height: open ? 'auto' : 0, opacity: open ? 1 : 0 }}>
        <div className="pt-4">{children}</div>
      </div>
    </section>
  )
}

const ApplicationFormSettings = (props: ApplicationFormSettingsProps) => {
  const { className, companyName, form, initialModel, isEditable, onValidationUpdate, timezone, before, onUpdate } = props

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formRef = form || React.useRef()

  const setFieldLabel = (label) => {
    if (typeof label === 'function') {
      return label({ companyName })
    }

    return label
  }

  return (
    <Form
      isCompact
      getForm={formRef}
      timezone={timezone}
      className={className}
      initialModel={initialModel}
      onValidationUpdate={onValidationUpdate}
      onUpdate={onUpdate}
      isEditable={isEditable}
    >
      <div className="grid gap-4" style={{ '--field-max-width': '100%' }}>
        {before}

        <MiniRichTextEditor
          useDictation
          useQuickText
          label="Welcome Message"
          tooltip="This message is displayed at the top of the application form"
          model="welcome_message"
          defaultValue="Below you will find some questions that help us learn a bit about you so we can determine how we can help."
        />
        <MiniRichTextEditor
          useDictation
          useQuickText
          label="SMS Message"
          tooltip="This will be sent when an applicant provides their cell phone number"
          model="sms_message"
          defaultValue="Please complete your application:"
        />

        <MiniRichTextEditor
          useDictation
          useQuickText
          label="Thank You Message"
          tooltip="This message is displayed after the client successfully submitted the form"
          model="thank_you_message"
          defaultValue="Thank you for submitting your Application. We'll read through it shortly and get in touch with you soon."
        />

        <MiniRichTextEditor
          useDictation
          useQuickText
          label="Release of Information Disclaimer Message"
          tooltip={`This message is displayed on all Contact Details forms when answering "Yes" to the "Can we release information to this person?" question`}
          model="roi_disclaimer_message"
          defaultValue={`By checking yes on release of information, you authorize ${
            companyName || 'this organization'
          } to disclose to or obtain information regarding my treatment, housing, payment, legal, and/or any other information deemed relevant. This information may be used or disclosed in connection with treatment, payment, or in order to provide quality service. I understand the submission of this form acts as a digital signature. I understand that I may revoke this consent at any time except in writing.`}
        />

        {data.map((group) => {
          const showCustomQuestions = group.model !== 'custom' && !group?.disableCustomQuestion

          return (
            <Card key={group.model} className="px-3 py-2">
              <Group
                key={group.model}
                title={group.title}
                subtitle={group.subtitle}
                isRequired={group.isRequired}
                model={`schema.${group.model}`}
                isEditable={isEditable}
                isOpen={group.isOpen}
              >
                <Grid gap="0.5rem">
                  {group.description && (
                    <MiniRichTextEditor
                      useDictation
                      useQuickText
                      label={group.description.label}
                      model={`schema.${group.model}.description`}
                      placeholder={group.description.placeholder}
                    />
                  )}

                  {group.fields?.map((field) => (
                    <Grid key={`${group.model}-${field.model}`} autoFlow="column" autoColumns="1fr auto" gap="0.5rem">
                      <>
                        <Checkbox
                          key={field.label}
                          label={setFieldLabel(field.label)}
                          description={field.description}
                          model={`schema.${group.model}.fields.${field.model}.show`}
                          isDisabled={field.isDisabled}
                          trueIcon="check"
                          falseIcon="cross"
                          falseStyle="faded"
                          value={field.isChecked}
                        />

                        {!group.hideIsRequired && (
                          <ContextShow when={`schema.${group.model}.fields.${field.model}.show`} is={true}>
                            <Checkbox
                              key={setFieldLabel(field.label)}
                              label="Required"
                              model={`schema.${group.model}.fields.${field.model}.required`}
                              isDisabled={field.isDisabled || field.isRequiredDisabled}
                              value={field.isRequired}
                              trueIcon="check"
                              falseIcon="cross"
                              falseStyle="faded"
                            />
                          </ContextShow>
                        )}
                      </>
                    </Grid>
                  ))}

                  {showCustomQuestions && (
                    <>
                      <Grid autoFlow="column" autoColumns="1fr auto" gap="0.5rem">
                        <Checkbox
                          label="Custom Questions"
                          model={`schema.${group.model}.fields.custom_questions.show`}
                          trueIcon="check"
                          falseIcon="cross"
                          falseStyle="faded"
                          // value={field.isChecked}
                        />
                        <ContextShow when={`schema.${group.model}.fields.custom_questions.show`} is={true}>
                          <Checkbox
                            isDisabled
                            label="Required"
                            model={`schema.${group.model}.fields.custom_questions.required`}
                            // value={field.isRequired}
                            trueIcon="check"
                            falseIcon="cross"
                            falseStyle="faded"
                          />
                        </ContextShow>
                      </Grid>

                      <ContextShow when={`schema.${group.model}.fields.custom_questions.show`} is={true}>
                        <TableArrayForm
                          isCompact
                          name="Custom Questions"
                          model={`schema.${group.model}.fields.custom_questions.questions`}
                          inputs={INPUTS}
                        />
                      </ContextShow>
                    </>
                  )}

                  {group.renderSettings?.()}
                </Grid>
              </Group>
            </Card>
          )
        })}
      </div>
    </Form>
  )
}

const INPUTS = [
  {
    name: 'Label',
    width: '2fr',
    element: (id: string) => <Input withHover={false} model={`${id}.question`} placeholder="Enter your question here…" />,
  },
  {
    name: 'Required',
    width: '1fr',
    element: (id: string) => (
      <Checkbox isCompact label="Required" model={`${id}.required`} trueIcon="check" falseIcon="cross" falseStyle="faded" />
    ),
  },
]

ApplicationFormSettings.defaultProps = {
  isEditable: true,
}

export default ApplicationFormSettings
