import React from 'react'
import { transparentize } from 'polished'

import { COLORS } from '../../theme'

export const DataTableColumnResizer = (props: any) => {
  const { className, column, index } = props

  if (!column) return null

  return <div data-id={column.id} data-type="resizer" data-index={index} className={className} css={STYLES.root} />
}

const STYLES = {
  root: {
    width: 2,
    minHeight: 12,
    background: COLORS.transparent,
    cursor: 'ew-resize',

    '&:hover': {
      background: transparentize(0.2, COLORS.blue),
      boxShadow: `-2px 0 0 ${transparentize(0.8, COLORS.vividBlue)}, 2px 0 0 ${transparentize(0.8, COLORS.vividBlue)}`,
    },
  },
}
