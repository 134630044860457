import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../../theme'
import BarChart from '../ChartTypes/BarChart'

export const AverageChargeAmountsChart = (props: any) => {
  return <BarChart color={COLORS.orange} showSymbol={false} {...props} />
}

export default AverageChargeAmountsChart
