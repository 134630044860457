import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { Button, Page, PageLayout, Card, HelpTagIframe } from '@behavehealth/components'
import { InsuranceAuthorizationsTable } from '@behavehealth/components/Tables'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'

const pageConfig = {
  feature: 'insurance_authorizations',
  title: 'Insurance Authorizations',
  help: <HelpTagIframe id="client_insurance_authorizations" />,
}

const Authorizations = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()
  const { isBehave } = useSettings()

  // const [filters, setFilters] = React.useState({})

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'insurance_authorizations'],
    url: `/residents/${resource_id}/insurance_authorizations`,
    // params: { filters: btoa(JSON.stringify({ filters })) },
  })

  const isEmpty = size(data) === 0

  const actions = (
    <Button
      type="primary"
      label="+ Add Authorization"
      link={`${match.url}/new`}
      permission={isBehave || 'insurance_authorizations.create'}
    />
  )

  return (
    <Page
      feature="authorizations"
      isLoading={isLoading}
      isEmpty={isEmpty}
      actions={actions}
      help={<HelpTagIframe id="client_insurance_authorizations" />}
    >
      <PageLayout>
        <Card>
          <InsuranceAuthorizationsTable
            localStorageKey="insurance_authorizations"
            data={data}
            isLoading={isEmpty && isLoading}
            emptyActions={actions}
            to={(id: any) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
          />
        </Card>
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(Authorizations, pageConfig))
