import React from 'react'

import { usDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

const columns = (to?: Function, timezone?: string) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'dated_at',
    Header: 'Date Added',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to?.(row.original.id)} label={usDate(value, timezone)} />,
  },
  {
    width: 160,
    accessor: 'insurance_policy.insurance_name',
    Header: 'Insurance Payer',
  },
  {
    width: 160,
    accessor: 'insurance_policy.plan',
    Header: 'Plan Name',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription?: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  showSettings?: boolean
  timezone?: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const VOBSummariesTable = (props: Props) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    batchActionsConfig,
    localStorageKey,
    showSettings,
    timezone,
    title,
    to,
    titleAfter,
  } = props

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      showSettings={showSettings}
    />
  )
}

VOBSummariesTable.defaultProps = {
  emptyDescription: 'No VOB summaries added yet',
  icon: 'vob',
  localStorageKey: 'insurance_vob_summaries',
  title: 'VOB Summaries',
}

export default withSettings(VOBSummariesTable)
