import React from 'react'

import { TabsContext } from '../hocs/withTabsContext'

import Tab from './Tab'
import TabList from './TabList'
import TabPanel from './TabPanel'
import TabPanels from './TabPanels'
import TabDivider from './TabDivider'

const Tabs = ({ defaultTab, activeTab, children, onChange, className }: any) => {
  const [selected, setSelected] = React.useState(defaultTab)
  const tabsList = React.useRef([])

  const registerTab = (tab: string) => {
    tabsList.current.push(tab)
  }

  const onSelect = (name) => {
    setSelected(name)
    if (onChange) onChange(name)
  }

  React.useEffect(() => {
    if (!activeTab) return

    setSelected(activeTab)
  }, [activeTab])

  return (
    <TabsContext.Provider
      value={{
        currentTab: selected,
        onSelect: onSelect,
        tabsList: tabsList.current,
        registerTab: registerTab,
      }}
    >
      <div className={className} css={styles}>
        {children}
      </div>
    </TabsContext.Provider>
  )
}

const styles = {
  position: 'relative',
  zIndex: 1,
}

Tabs.Item = Tab
Tabs.Item.displayName = 'Tab'

Tabs.List = TabList
Tabs.List.displayName = 'TabList'

Tabs.Panel = TabPanel
Tabs.Panel.displayName = 'TabPanel'

Tabs.Panels = TabPanels
Tabs.Panels.displayName = 'TabPanels'

Tabs.Divider = TabDivider
Tabs.Divider.displayName = 'TabDivider'

export default Tabs
