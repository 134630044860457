import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { withPageError } from '@behavehealth/hocs/withPageError'

import { Button, Card, Page, Grid, HelpTagIframe } from '@behavehealth/components'
import useStore from '@behavehealth/modules/store'

import { EPrescribingAccess } from '@behavehealth/constructs/ERX/EPrescribingAccess'
import { DiagnosesDataTable } from '@behavehealth/constructs/Diagnoses/DiagnosesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const pageConfig = {
  feature: 'diagnoses',
  help: <HelpTagIframe id="diagnoses" />,
}

const Diagnoses = () => {
  const match = useRouteMatch()
  const clientId = match.params?.resource_id

  const tenant = useStore((state) => state.tenant)
  const client = useSelector((state) => state.data.clients?.data[match.params?.resource_id])

  const tableProps = useDataTable({
    name: ['client', clientId, 'diagnoses'],
    endpoint: `/residents/${clientId}/diagnoses`,
    localStorageKey: 'client_diagnoses_v1',
  })

  const isEmpty = size(tableProps.data) === 0

  const actions = (
    <Button
      label="Add Diagnosis"
      type="primary"
      glyph="add"
      link={{
        pathname: `${match.url}/new`,
        data: {
          client: [client],
        },
      }}
      permission="diagnoses.create"
    />
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Grid gap="1rem">
        <EPrescribingAccess tenant={tenant} client={client} />

        <Card>
          <DiagnosesDataTable
            {...tableProps}
            emptyActions={actions}
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(Diagnoses)
