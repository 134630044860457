import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../theme'

type Props = {
  centerX?: boolean
  centerY?: boolean
  className?: string
}

const GridTableCell = React.forwardRef((props: any, ref: any) => {
  const { children, className, centerX, centerY, onClick, onBlur, onFocus, onPointerEnter, onPointerLeave, onTouchStart } = props

  const classNames = clsx(className, centerX && 'center-x', centerY && 'center-y')

  return (
    <div
      data-test="grid_table_cell"
      ref={ref}
      css={styles}
      className={classNames}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onTouchStart={onTouchStart}
    >
      {children}
    </div>
  )
})

const styles = {
  padding: 'var(--padding-y) var(--padding-x)',
  borderRight: `1px solid ${COLORS.divider}`,

  '&:last-of-type': {
    borderRight: 'none',
  },

  '.first-column-sticky &:first-of-type': {
    background: COLORS.white,
    position: 'sticky',
    left: 0,

    zIndex: 2,
    boxShadow: `inset -1px 0 0 ${COLORS.divider}, 3px 0 0 ${COLORS.shadow}`,
  },

  '&.center-x': {
    display: 'flex',
    justifyContent: 'center',
  },

  '&.center-y': {
    display: 'flex',
    alignItems: 'center',
  },
}

export default GridTableCell
