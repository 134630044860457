import React from 'react'
import { useSelector } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { apiDelete } from '@behavehealth/modules/api'
import { getVersionSlug } from '@behavehealth/utils/functions'
import { useAPI } from '@behavehealth/hooks'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import withMarketing from '@behavehealth/hocs/withMarketing'
import { mapToArray } from '@behavehealth/utils/functions'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { Button, Card, Chotomate, Divider, HelpTagIframe, Page, Dropdown, DropdownItem, Link } from '@behavehealth/components'
import { TreatmentPlansTable } from '@behavehealth/components/Tables'

const pageConfig = {
  feature: 'treatment_plans',
  help: <HelpTagIframe id="treatment_plans" />,
  marketingID: 'treatment_plans',
}

type Props = {
  canCreate: boolean
}

const AddDropdown = ({ match, templates }: any) => (
  <Dropdown label="Add Treatment Plan…" glyph="add" buttonType="primary" buttonSize={300}>
    <DropdownItem
      label="Empty Treatment Plan"
      glyph="add"
      color="paleBlue"
      link={{
        pathname: `${match.url}/new-20`,
        parent: match,
      }}
    />

    <Divider />

    {mapToArray(templates).map((template) => (
      <DropdownItem
        key={template.id}
        label={template.name}
        icon="treatment_plans"
        color="paleBlue"
        link={{
          pathname: `${match.url}/new-20`,
          parent: match,
          data: {
            ...template,
            data_form_template_id: template.id,
          },
        }}
      />
    ))}
  </Dropdown>
)

const TreatmentPlans: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()
  const { isBehave } = useSettings()

  const templates = useSelector((state) => state.data.data_form_templates?.data)

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'data_forms', { category: 'treatment_plan' }],
    url: `/residents/${resource_id}/data_forms`,
    params: {
      category: 'treatment_plan',
    },
  })

  useAPI('data_form_templates', `/data_form_templates?category=treatment_plan`)

  const isEmpty = size(data) === 0

  const actions = (
    <>
      <Button
        label="Manage Treatment Plan Templates"
        glyph="settings"
        type="default"
        link="/settings/archived-treatment-plan-templates"
        permission="settings.treatment_plan_templates.edit"
      />

      <AddDropdown match={match} templates={templates} />
    </>
  )

  return (
    <Page actions={!isEmpty && canCreate && actions} {...pageConfig}>
      <Chotomate name="treatment_plans" ready={!isLoading} />

      <div className="grid grid-cols-[100%]">
        <Card>
          <TreatmentPlansTable
            data={data}
            isLoading={isEmpty && isLoading}
            isBehave={isBehave}
            emptyActions={canCreate && actions}
            localStorageKey="clients_treatment_plans"
            to={({ version, id }: any) => ({
              pathname: `${match.url}/${id}-${getVersionSlug(version)}`,
              parent: match,
            })}
            titleAfter={<Link to="/reports/treatment-plans">View Full Report →</Link>}
            duplicatePlanLink={(plan) => ({
              pathname: `${match.url}/new`,
              parent: match,
              data: {
                supervisor: plan.supervisor,
                data: plan.data,
              },
            })}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'treatment_plans.delete',
                action: async ({ ids }: any) => {
                  await apiDelete({ name: 'data_forms', url: `/data_forms/${ids.join(',')}` })
                },
              },
            ]}
          />
        </Card>
      </div>
    </Page>
  )
}

export default withPageError(withMarketing(TreatmentPlans, pageConfig))
