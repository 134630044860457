import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { useDataForms } from '@behavehealth/hooks/useDataForms'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'
import withSettings from '@behavehealth/hocs/withSettings'

import { Card, Dropdown, DropdownItem, HelpTagIframe, Page } from '@behavehealth/components'
import { OUTCOME_MEASURES_SLUGS } from '@behavehealth/utils/constants'

import OutcomeMeasuresTable from '@behavehealth/components/Tables/OutcomeMeasuresTable'

const pageConfig = {
  feature: 'outcome_measures',
  help: <HelpTagIframe id="outcome_measures" />,
}

const OutcomeMeasures = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()
  const { isBehave, formSettings } = useSettings()

  const { data, isLoading, batchDelete } = useDataForms({ category: 'outcome_measure', clientID: resource_id })

  const actions = (
    <Dropdown label="Add New…" buttonType="primary" glyph="add" position="bottom" permission="outcome_measures.create">
      <DropdownItem
        label="Admit Survey"
        icon="outcome_measures"
        link={{
          pathname: `${match.url}/admit-survey/new`,
          parent: match,
        }}
      />
      <DropdownItem
        label="Demographic Information Form"
        icon="outcome_measures"
        link={{
          pathname: `${match.url}/demographic-information/new`,
          parent: match,
        }}
      />
      <DropdownItem
        label="Progress Monitoring Survey"
        icon="outcome_measures"
        link={{
          pathname: `${match.url}/progress-monitoring-survey/new`,
          parent: match,
        }}
      />
      <DropdownItem
        label="Outcomes Survey"
        icon="outcome_measures"
        link={{
          pathname: `${match.url}/outcomes-survey/new`,
          parent: match,
        }}
      />
      <DropdownItem
        label="Discharge Survey"
        icon="outcome_measures"
        link={{
          pathname: `${match.url}/discharge-survey/new`,
          parent: match,
        }}
      />

      {formSettings?.outcome_measures_recovery_capital_scale && (
        <DropdownItem
          label="Recovery Capital Scale"
          icon="outcome_measures"
          link={{
            pathname: `${match.url}/recovery-capital-scale/new`,
            parent: match,
          }}
        />
      )}

      {formSettings?.outcome_measures_mirc && (
        <DropdownItem
          label="Multidimensional Inventory of Recovery Capital"
          icon="outcome_measures"
          link={{
            pathname: `${match.url}/mirc/new`,
            parent: match,
          }}
        />
      )}
    </Dropdown>
  )

  return (
    <Page breakpoint="0" actions={actions} {...pageConfig}>
      <div className="grid grid-cols-[100%]">
        <Card>
          <OutcomeMeasuresTable
            data={data}
            isLoading={isLoading}
            isBehave={isBehave}
            localStorageKey="clients_outcome_measures"
            to={(id: string, subcategory: string) => ({
              pathname: `${match.url}/${OUTCOME_MEASURES_SLUGS[subcategory]}/${id}`,
              parent: match,
            })}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'outcome_measures.delete',
                action: batchDelete,
              },
            ]}
          />
        </Card>
      </div>
    </Page>
  )
}

export default withPageError(withSettings(withMarketing(OutcomeMeasures, pageConfig)))
