import { FILTERS } from '../../constructs/Filters/config'

export const QUICK_ADD = {
  all_clients: {
    endpoint: '/residents',
    queryKey: 'residents',
    filtersConfig: FILTERS.clients,
    columns: [
      {
        title: 'Name',
        model: 'name',
        type: 'profile',
        width: 300,
        disableHide: true,
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
      },
      {
        title: 'Sex',
        model: 'sex',
        type: 'title',
        width: 140,
      },
      {
        id: 'house',
        title: 'House',
        model: 'current_bed_occupancy.place.house',
        type: 'profile',
      },
      {
        id: 'room',
        title: 'Room',
        model: 'current_bed_occupancy.place.room',
        type: 'profile',
      },
      {
        title: 'Client ID',
        model: 'behave_id',
        width: 180,
        formatValue: ({ data }: any) => `#${data.behave_id}`,
      },
    ],
  },
}
