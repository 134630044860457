import React from 'react'
import size from 'lodash/size'
import { v4 as uuid } from 'uuid'
import produce from 'immer'

import { withFormContext } from './context'

import Button from '../Button'
import Field from './Field'
import FieldBase from './FieldBase'
import Flex from '../Flex'
import Grid from '../Grid'
import URLInput from './URLInput'

const LinkItem = (props) => {
  const { item, index, isEditable, handleDelete, handleUpdate } = props

  return (
    <Flex nowrap alignItems="flex-start">
      <URLInput
        value={item?.url}
        isEditable={isEditable}
        css={styles.input}
        onUpdate={(input: any) => {
          handleUpdate(index, { url: input.value })
        }}
      />

      {isEditable && (
        <Button
          hideLabel
          glyph="delete"
          color="red"
          onClick={() => handleDelete(index)}
          css={styles.deleteButton}
          type="minimal"
          size={400}
        />
      )}
    </Flex>
  )
}

class Links extends FieldBase {
  constructor(props) {
    super(props)

    this.state = {
      type: 'LINKS',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: props.value || props.form?.getInitialInputFieldValue(props.model, []),
      isValid: true,
      isInvalid: false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      newURL: '',
      reset: this.onReset,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: props.value || props.form?.getInitialInputFieldValue(props.model, []),
      isValid: true,
      isInvalid: false,
    }
    this.updateType = 'DATA'
  }

  /*
    LIFECYCLE
  */
  componentDidMount = async () => {
    // register element with the form
    if (this.props.form) this.props.form.register(this.state)
    if (this.props.onUpdate) this.props.onUpdate(this.state)
  }

  /*
    CUSTOM
  */
  handleAdd = (event: any) => {
    event.preventDefault()

    this.changeValue([...this.state.value, { uuid: uuid(), url: this.state.newURL }])
    if (this.state.newURL) this.setState({ newURL: '' })
  }

  handleEnterKey = (event: any) => {
    if (event.keyCode === 13) this.handleAdd(event)
  }

  handleDelete = (idx: any) => {
    this.changeValue(
      produce(this.state.value, (draft: any) => {
        draft.splice(idx, 1)
      }),
    )
  }

  handleUpdate = (idx: any, value: any) => {
    const newValue = produce(this.state.value, (draft: any) => {
      draft[idx] = { ...draft[idx], ...value }
    })

    this.changeValue(newValue)
  }

  /*
    RENDER
  */
  render() {
    const { isEditable, model, ...others } = this.props
    const { id, value, isRequired, isValid, errors, newURL } = this.state
    const isEmpty = size(value) === 0

    return (
      <Field {...others} id={id} errors={errors} isValid={isValid} isRequired={isRequired} model={model}>
        {!isEditable && isEmpty && <div>None</div>}

        <Grid gap="0.5rem">
          {value?.map?.((item: any, idx: number) => (
            <LinkItem
              key={item.uuid}
              index={idx}
              handleDelete={this.handleDelete}
              handleUpdate={this.handleUpdate}
              isEditable={isEditable}
              item={item}
            />
          ))}

          {isEditable && (
            <Flex nowrap gap={8} alignItems="flex-start">
              <URLInput
                placeholder="New link"
                value={newURL}
                onKeyDown={this.handleEnterKey}
                onUpdate={(input: any) => this.setState({ newURL: input.value })}
                css={styles.input}
              />
              <Button label="Add Link" glyph="add" type="primary" onClick={this.handleAdd} isDisabled={newURL.length === 0} size={400} />
            </Flex>
          )}
        </Grid>
      </Field>
    )
  }
}

const styles = {
  input: {
    flex: '1 1 auto',
    transform: 'none !important',
    marginRight: '0.5rem',
  },

  deleteButton: {
    padding: '0.5em',
  },
}

export default withFormContext(Links)
