import React from 'react'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { transparentize } from 'polished'

import { readFileAsDataURL, readFileAsDataBlob } from '../../utils/functions'
import { queueUpload } from '../../actions/common'
import Icon from '../Icon'
import Button from '../Button'
import { Text } from '../Typography'
import Flex from '../Flex'

import { COLORS } from '../../theme'

class LocalDnD extends React.Component {
  onChange = async (event) => {
    let objects = event.target.files
    let files = []

    for (let i = 0; i < objects.length; i++) {
      const file = objects[i]
      files.push({
        provisionalID: uuid(),
        file_name: file.name,
        file_data: await readFileAsDataURL(file),
        file_blob: file,
        title: file.name.replace(/\.[^/.]+$/, ''),
        content_type: file.type,
      })
    }

    if (this.props.onChange) this.props.onChange(files)
  }

  render = () => {
    const { isDisabled, isEditable, acceptMultiple } = this.props

    const canUpload = isEditable && !isDisabled
    const icon = canUpload ? 'files' : 'permissions'
    const title = canUpload ? `Drag & Drop File${acceptMultiple ? 's' : ''}` : 'Uploading Blocked'
    const description = canUpload
      ? `Or click the button below to upload your file${acceptMultiple ? 's' : ''}`
      : 'You do not have permission to edit these files'

    return (
      <div css={styles.root}>
        <Flex gap={8} vertical centerX centerY>
          {canUpload && <input css={styles.input} type="file" multiple={acceptMultiple} onChange={this.onChange} />}

          <Icon icon={icon} />
          {title && <h3 css={{ marginTop: 8 }}>{title}</h3>}
          {description && <Text css={{ marginTop: 4 }}>{description}</Text>}

          {canUpload && <Button glyph="upload" label="Upload" type="default" css={styles.button} />}
        </Flex>
      </div>
    )
  }
}

const styles = {
  root: {
    padding: '2rem 2rem',
    borderRadius: '5px',
    border: `2px dashed ${COLORS.divider}`,
    position: 'relative',

    '&:hover': {
      background: transparentize(0.1, COLORS.divider),
      border: `2px dashed ${COLORS.divider}`,
    },

    '&:active': {
      background: transparentize(0.9, COLORS.green),
      border: `2px dashed ${COLORS.green}`,
    },
  },

  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: '1px solid #000',
    opacity: 0,
  },

  button: {
    pointerEvents: 'none',
  },
}

LocalDnD.defaultProps = {
  acceptMultiple: true,
  isEditable: true,
  isDisabled: false,
}

const mapDispatchToProps = (dispatch) => ({
  queueUpload: (data) => dispatch(queueUpload(data)),
})

export default connect(null, mapDispatchToProps)(LocalDnD)
