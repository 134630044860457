import React from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { useGet, useCreate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import ProgramProfileHeader from '../../components/ProgramProfileHeader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import SeatsAssignmentSelector from '../../components/Forms/SeatsAssignmentSelector'
import Section from '../../components/Section'

const getAllowedAssignees = (assignees: any) => {
  const result: any = []

  if (!assignees) return result

  for (const key in assignees) {
    if (assignees[key] === true) result.push(key)
  }

  return result
}

const RootProgramAssignSeatsOverlay = (props: any) => {
  const { resource_id, phase_id }: any = useParams()

  const navigate: any = useNavigate()
  const location: any = useLocation()

  const form: any = React.useRef()
  const initialData = location.data

  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [assigneesBySeatId, setAssigneesBySeatId]: any = React.useState({})

  const close = () => {
    if (props.onClose) return props.onClose()

    if (location.parent) navigate(location.parent.url)
    else navigate(-1)
  }

  // get program data from API if not passed via router
  const { data: apiProgram, isLoading: isProgramLoading }: any = useGet({
    name: ['program', resource_id],
    url: `/programs/${resource_id}`,
    options: { enabled: !!resource_id && !initialData?.program },
  })

  const { data: phaseSeats, isLoading: isPhaseLoading }: any = useGet({
    name: ['phase', phase_id, 'seats'],
    url: `/phases/${phase_id}/seats`,
    options: { enabled: !!phase_id },
  })

  const { mutateAsync, isLoading: isSaving }: any = useCreate({
    name: ['phase', phase_id, 'assign-seats'],
    url: `/phases/${phase_id}/assign_seats`,
    invalidateKeys: ['program-timeline', 'programs-timeline', ['program', resource_id, 'phases-with-residents']],
  })

  const save = async () => {
    const model = form.current.getFormValue()
    const assignments = {}

    for (const id in assigneesBySeatId) {
      const assignee = assigneesBySeatId[id]
      if (!assignee) continue

      assignments[id] = { id: assignee.id, type: assignee.type }
    }

    try {
      await mutateAsync({ ...model, assignments })
      close()
    } catch (error) {
      console.error(error)
    }
  }

  // get program data from router or from API
  const program = initialData?.program || apiProgram

  const { timezone } = useSettings()

  if (isPhaseLoading || isProgramLoading || !program) {
    return <OverlayLoader position="center" />
  }

  const allowedAssignees = getAllowedAssignees(program?.assignees)

  return (
    <Overlay showBackdrop position="center" onClose={close} maxWidth={150}>
      <Overlay.Header glyph="chair" title="Add Assignment" description={program && <ProgramProfileHeader program={program} />} />

      <Overlay.Content>
        <Form useFullModel isEditable getForm={form} timezone={timezone}>
          <Section>
            <Grid gap="1rem">
              <Flex centerY gap="1rem">
                <DateTimeInput
                  defaultToNow
                  label="Assign From"
                  model="started_at"
                  validations={{
                    presence: {
                      message: 'Please enter a start date',
                    },
                  }}
                  onUpdate={({ value }) => {
                    setStartDate(value)
                  }}
                  css={{ flex: '0 1 470px' }}
                />

                <RadioGroup label="Assign Until" model="period_type" layout="horizontal-dense" defaultValue="indefinite">
                  <Radio label="Until Discharged" value="indefinite" />
                  <Radio label="Until Date" value="set_datetime" />
                </RadioGroup>
              </Flex>

              <ContextShow when="period_type" is="set_datetime">
                <DateTimeInput
                  defaultToTomorrow
                  label="Until Date"
                  model="ended_at"
                  validations={{
                    presence: {
                      message: 'Please enter an end date',
                    },
                    datetime: {
                      earliest: startDate,
                      message: `Please enter a date after "Assign From" date`,
                    },
                  }}
                  onUpdate={({ value }) => {
                    setEndDate(value)
                  }}
                />
              </ContextShow>

              <SeatsAssignmentSelector
                phaseId={phase_id}
                programId={resource_id}
                allowedAssignees={allowedAssignees}
                onUpdate={setAssigneesBySeatId}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Add Assignment"
          glyph="check"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={save}
          isDisabled={size(assigneesBySeatId) === 0}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const ProgramAssignSeatsOverlay = withOverlayError(RootProgramAssignSeatsOverlay)
