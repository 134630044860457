export const setHelpCenterTags = (helpCenter: any) => {
  const result: any = {}
  if (!helpCenter) return result

  const localHelpCenter = Object.entries(helpCenter)

  for (let i = 0; i < localHelpCenter.length; i++) {
    const tag = localHelpCenter[i][0]
    const page = localHelpCenter[i][1]

    if (!tag || !page || !page?.page_id) continue

    result[page.page_id] = tag
  }

  return result
}
