import React from 'react'

import { DEFAULT_EMPTY_VALUE } from '../../../utils/constants'

const Empty: React.FC<any> = ({ className }) => (
  <span css={{ opacity: 0.55 }} className={className}>
    {DEFAULT_EMPTY_VALUE}
  </span>
)

export default Empty
