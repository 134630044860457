import React from 'react'

import Flex from '../../../../components/Flex'
import Input from '../../../../components/Forms/Input'

export const SliderInputEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate } = props

  const { min = '0', max = '100', step = '1' } = activeElement.config

  return (
    <Flex stretchChildrenX gap="0.5rem">
      <Input size={5} label="Min" model="min" type="number" value={min} onUpdate={onUpdate} />
      <Input size={5} label="Max" model="max" type="number" value={max} onUpdate={onUpdate} />
      <Input size={5} label="Step" model="step" type="number" value={step} onUpdate={onUpdate} />
    </Flex>
  )
}
