import React from 'react'

import Checkbox from '../Checkbox'
import CheckboxGroup from '../CheckboxGroup'
import ContextHide from '../ContextHide'

const ProviderTypesCheckboxGroup = ({ label, model, isRequired }) => (
  <CheckboxGroup
    label={label}
    layout="vertical-dense"
    validations={
      isRequired && {
        presence: {
          message: 'Please select at least one option',
        },
      }
    }
  >
    <Checkbox label="None Applicable" model={`${model}.none`} />
    <ContextHide when={`${model}.none`} is={true}>
      <Checkbox label="Medical Doctor (PCP)" model={`${model}.medical_doctor`} />
      <Checkbox label="Psychiatrist" model={`${model}.psychiatrist`} />
      <Checkbox label="Psychologist" model={`${model}.psychologist`} />
      <Checkbox label="Therapist" model={`${model}.therapist`} />
      <Checkbox label="Nutritionist" model={`${model}.nutritionist`} />
    </ContextHide>
  </CheckboxGroup>
)

ProviderTypesCheckboxGroup.defaultProps = {
  model: 'provider_types',
}

export default ProviderTypesCheckboxGroup
