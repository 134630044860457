import React from 'react'
import clsx from 'clsx'

import { DropdownMenu } from '../../components/DropdownMenu'

import Button from '../../components/Button'
import Card from '../../components/Card'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Label from '../../components/Label'
import PageSection from '../../components/PageSection/PageSection'
import State from '../../components/State'

import { PlainSwitch } from '../../components/Forms/Switch'
import { SystemVariablesTable } from '../FormBuilder/components/SystemVariablesTable'
import { useQuoteContext } from './context'

export const QuoteSection = (props: any) => {
  const {
    children,
    emptyDescription = 'This section will not be visible in the Quote',
    enabledModel,
    isEditable,
    isEnabled,
    onUpdate,
    title,
    showVariables,
  } = props

  const { variables } = useQuoteContext()

  const handleSwitch = () => {
    onUpdate({ [enabledModel]: !isEnabled })
  }

  return (
    <PageSection className="grid gap-4 p-5">
      <PageSection.Header
        className="!m-0"
        after={
          enabledModel && (
            <>
              <div className={clsx('flex-nowrap items-center whitespace-nowrap', isEditable ? 'flex' : 'hidden')}>
                <PlainSwitch isChecked={isEnabled} size={100} withHover={false} onCheckedChange={handleSwitch} />
                <Label isCompact label={`Enable "${title}" Section`} className="!p-0 ml-1.5 !cursor-pointer" onClick={handleSwitch} />
              </div>

              {!isEditable && !isEnabled && (
                <div className="flex items-center">
                  <Glyph glyph="cross" size={16} />
                  <Label isCompact label={`Not Enabled`} className="!p-0 ml-1.5 whitespace-nowrap" />
                </div>
              )}
            </>
          )
        }
      >
        <PageSection.Title
          title={title}
          className={clsx('!text-[1.65rem]', enabledModel && !isEnabled && 'opacity-50')}
          after={
            showVariables && (
              <DropdownMenu
                trigger={
                  <div>
                    <Button label="Show Variables" size={100} icon="variables" type="default" />
                  </div>
                }
              >
                <div className="max-w-[80vw] overflow-auto">
                  <SystemVariablesTable variables={variables} showDescription={false} />
                </div>
              </DropdownMenu>
            )
          }
        />
      </PageSection.Header>

      {enabledModel && isEditable && !isEnabled && (
        <Card className={clsx(enabledModel && !isEditable && !isEnabled && 'opacity-70')}>
          <State isEmpty icon="quotes" title={title} emptyDescription={emptyDescription} minHeight={200} />
        </Card>
      )}

      <div className={clsx(!enabledModel || (enabledModel && isEnabled) ? 'block' : 'hidden')}>
        <FormSection maxWidth="100%">{children}</FormSection>
      </div>
    </PageSection>
  )
}
