import React from 'react'

import { COLORS } from '../../../theme'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

export const PhoneCell: React.FC = (props: any) => {
  return (
    <DataTableCell hoverExpand {...props}>
      {props.value ? (
        <a href={`tel:${props.value}`} css={STYLES.link}>
          {props.value}
        </a>
      ) : (
        <EmptyCell />
      )}
    </DataTableCell>
  )
}

const STYLES = {
  link: {
    color: COLORS.text,

    '&:hover': {
      color: COLORS.blue,
    },
  },
}
