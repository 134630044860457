import React from 'react'
import { useRouteMatch, useLocation, useParams } from 'react-router-dom'

import { initials } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import HelpTagIframe from '@behavehealth/components/Help/HelpTagIframe'

import { ClinicalNotesDataTable } from '@behavehealth/constructs/ClinicalNotes/ClinicalNotesDataTable'
import { ClinicalNotesDropdown } from '@behavehealth/constructs/ClinicalNotes/ClinicalNotesDropdown'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

type Props = {
  canCreate: boolean
}

const CaseManagementClinicalNotes: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const location = useLocation()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const tableProps = useDataTable({
    name: ['client', resource_id, 'clinical-notes'],
    endpoint: `/residents/${resource_id}/clinical_notes`,
    updateDeleteEndpoint: `/clinical_notes`,
    params: { category: 'case_management' },
    localStorageKey: 'client_clinical_notes_case_management_v1',
  })

  const { deleteRecords, batchUpdateRecords } = tableProps

  const to = React.useMemo(
    () => (id: string, category: string) => ({
      pathname: `${match.url}/${initials(category)}/${id}`,
      parent: match,
    }),
    [match.url],
  )

  const duplicateFormLink = React.useMemo(
    () => (record: any) => {
      let routePrefix = ''

      if (record.category === 'individual') routePrefix = 'i'
      else if (record.category === 'group_note') routePrefix = 'gn'
      else if (record.category === 'case_management') routePrefix = 'cm'
      else if (record.category === 'family_session') routePrefix = 'fs'

      return {
        pathname: `${match.url}/${routePrefix}/new`,
        parent: match,
        data: record,
      }
    },
    [],
  )

  return (
    <Grid gap="1rem" columns="100%">
      <ClinicalNotesDataTable
        {...tableProps}
        title="Case Management Notes"
        to={to}
        hiddenColumnIds={['client']}
        duplicateFormLink={duplicateFormLink}
        help={<HelpTagIframe id="clinical_notes_case_management" />}
        getCanSelect={(data: any) =>
          data.status === 'signed_off' || data.status === 'closed'
            ? { canSelect: false, message: 'Signed Off / Closed records cannot be edited' }
            : { canSelect: true }
        }
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/clinical-notes',
          },
        ]}
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'clinical_notes.delete',
            action: async ({ ids }: any) => {
              await deleteRecords(ids.join(','))
            },
          },
          {
            type: 'employee_sign_off',
            permission: 'clinical_notes.edit',
            action: async ({ ids, data: signature }: any) => {
              await batchUpdateRecords(ids, { [signature.model]: signature.value })
            },
          },
        ]}
        emptyActions={
          canCreate && (
            <Button
              label="Add Case Management Note"
              type="primary"
              glyph="add"
              link={{
                pathname: `${location.pathname}/cm/new`,
                parent: { url: location.pathname },
                data: { client },
              }}
              permission="clinical_notes.create"
            />
          )
        }
      />
    </Grid>
  )
}

export default CaseManagementClinicalNotes
