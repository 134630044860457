import React from 'react'

export const TabsContext = React.createContext({})

const withTabsContext = (Wrapped) => (props) =>
  (
    <TabsContext.Consumer>
      {(context: any) => (
        <Wrapped
          {...props}
          currentTab={context.currentTab}
          onSelect={context.onSelect}
          tabsList={context.tabsList}
          registerTab={context.registerTab}
        />
      )}
    </TabsContext.Consumer>
  )

export default withTabsContext
