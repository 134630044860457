import React from 'react'

import { useGet } from '../../../hooks/useNewAPI'

import Alert from '../../../components/Alert'
import Card from '../../../components/Card'
import FormSection from '../../../components/Forms/FormSection'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import State from '../../../components/State'

import { TreatmentPlanSection } from './TreatmentPlanSection'
import { SECTIONS } from '../constants'

export const TreatmentPlanSummary = (props: any) => {
  const { isOpen, planId, canEdit, permissionBase, isEditable } = props

  // const form = React.useRef(null)
  // const [isEditable, setIsEditable] = React.useState(false)

  const { data: treatmentPlan, isLoading: isLoadingTreatmentPlan } = useGet({
    name: ['treatment_plans', planId],
    url: `/treatment_plans/${planId}`,
    options: { enabled: !!planId },
  })

  // const { mutateAsync: updatePlan, isLoading: isSaving }: any = useUpdate({
  //   name: ['treatment_plans', planId],
  //   url: `/treatment_plans/${planId}`,
  //   invalidate: ['treatment_plans'],
  //   invalidateKeys: ['treatment-plan-updates'],
  // })

  // const handleSave = async () => {
  //   await updatePlan(form.current?.getFormValue())
  // }

  return (
    <TreatmentPlanSection isOpen={isOpen} {...SECTIONS.summary}>
      <Card className="px-4">
        {!treatmentPlan || isLoadingTreatmentPlan ? (
          <State isLoading />
        ) : (
          <>
            <FormSection layout="vertical" maxWidth="100%" className="py-4">
              <div className="grid gap-2">
                {isEditable && treatmentPlan?.settings?.instructions?.interpretative_summary && (
                  <Alert small glyph="info">
                    {treatmentPlan.settings.instructions.interpretative_summary}
                  </Alert>
                )}
                <SmartTextarea useQuickText useDictation label="Interpretive Summary" model="interpretative_summary" />
              </div>

              <div className="grid gap-2">
                {isEditable && treatmentPlan?.settings?.instructions?.additional_notes && (
                  <Alert small glyph="info">
                    {treatmentPlan.settings.instructions.additional_notes}
                  </Alert>
                )}
                <SmartTextarea useQuickText useDictation label="Additional Relevant Information" model="additional_notes" />
              </div>

              <div className="grid gap-2">
                {isEditable && treatmentPlan?.settings?.instructions?.recommendations && (
                  <Alert small glyph="info">
                    {treatmentPlan.settings.instructions.recommendations}
                  </Alert>
                )}
                <SmartTextarea useQuickText useDictation label="Recommendations" model="recommendations" />
              </div>

              {/* {canEdit && (
                <>
                  {isEditable ? (
                    <Flex gap="1rem">
                      <Button
                        label="Save"
                        glyph="tick_circle"
                        color="green"
                        type="primary"
                        className="!min-w-[80px]"
                        size={200}
                        onClick={handleSave}
                        isLoading={isSaving}
                        permission={`${permissionBase}.edit`}
                      />
                      <Button
                        label="Cancel"
                        glyph="cross"
                        size={200}
                        onClick={() => {
                          setIsEditable(false)
                        }}
                        isDisabled={isSaving}
                      />
                    </Flex>
                  ) : (
                    <div>
                      <Button
                        testKey="edit_treatment_plan_summary_button"
                        label="Edit"
                        glyph="edit"
                        type="primary"
                        className="!min-w-[80px]"
                        display="inline-flex"
                        size={200}
                        onClick={() => {
                          setIsEditable(true)
                        }}
                        permission={`${permissionBase}.edit`}
                      />
                    </div>
                  )}
                </>
              )} */}
            </FormSection>
          </>
        )}
      </Card>
    </TreatmentPlanSection>
  )
}
