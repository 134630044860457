import React from 'react'
import { NavLink, useLocation } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { DataTable } from '../../components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '../../constructs/Filters/constants'
import { EmptyCell } from '../../components/DataTable/cells/EmptyCell'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useUpdate } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Flex from '../../components/Flex'
import Link from '../../components/Link'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'

import { CommunityAccountsTable } from '../../components/Forms/Selectors/tables/CommunityAccountsTable'

const SALES_STEP = {
  signed_up: 'Account just signed up',
  invited: 'Owner invited to create password',
  registered: 'Owner password created',
  profile_completed: 'Owner completed the Business Profile',
  activated: 'Trial/Account activated',
}

const ACCOUNT_TYPES: any = {
  healthcare_facility: 'Healthcare Facility',
  healthcare_practice: 'Healthcare Practice',
  support_facility: 'Support Facility',
  support_practice: 'Support Practice',
  provider_affiliate: 'Provider Partner',
}

export const FacilitiesDataTable = (props: any) => {
  const location = useLocation()

  const [editRow, setEditRow]: any = React.useState(null)
  const [notesData, setNotesData]: any = React.useState(null)
  const [isNotesOverlayOpen, setIsNotesOverlayOpen] = React.useState(false)

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            as={NavLink}
            cypress="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            to={props.onClick ? undefined : `${data.id}/${data.subdomain}`}
            onClick={props.onClick ? () => props.onClick(data) : undefined}
            quickLinksVendors={['hubspot', 'google', 'gmail', 'google_calendar', 'helpscout']}
          />
        ),
      },
      {
        title: 'Tenant / Subdomain',
        model: 'subdomain',
        id: 'tenant',
      },
      // {
      //   width: 140,
      //   id: 'account_setup_action',
      //   title: 'Account Setup',
      //   hoverExpand: false,
      //   formatValue: ({ data }: any) => {
      //     if (process.env.NODE_ENV !== 'development') return null

      //     return (
      //       <Button
      //         label="Account Setup"
      //         icon="enterprise"
      //         size={100}
      //         link={`${match.url}/setup/${data.id}`}
      //         type={location.pathname.includes(`/setup/${data.id}`) ? 'primary' : 'default'}
      //       />
      //     )
      //   },
      // },
      {
        title: 'Tags',
        model: 'global_tags',
        type: 'global_tags',
        editPermission: true,
      },
      {
        title: 'Community Profiles',
        model: 'connected_entities',
        disableSort: true,
        formatValue: ({ value }: any) => {
          if (size(value) === 0) return null

          return (
            <div className="absolute py-1.5 px-2 top-0 bottom-0 left-0 right-0 hover:relative hover:!py-0 hover:!px-0 w-full">
              <Grid gap="0.5rem">
                {value.map((o: any) => (
                  <Link to={`/community/${o.id}`}>{o.name}</Link>
                ))}
              </Grid>
            </div>
          )
        },
      },
      {
        title: 'State',
        model: 'state',
      },
      {
        title: 'Primary Profile',
        model: 'primary_profile',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Community Admin Email',
        id: 'community_admin_email',
        model: 'contacts.community.email',
        type: 'email',
        width: 200,
      },
      {
        title: 'Community Admin Phone No',
        id: 'community_admin_phone_no',
        model: 'contacts.community.phone_no',
        type: 'phone',
        width: 210,
      },
      {
        title: 'Account Type',
        model: 'category',
        width: 210,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return ACCOUNT_TYPES[value] || value
        },
      },
      {
        title: 'Account Status',
        model: 'plan_status',
        type: 'title',
      },
      {
        title: 'Sales Status',
        model: 'sales_status',
        type: 'title',
      },
      {
        title: 'First Name',
        model: 'first_name',
      },
      {
        title: 'Last Name',
        model: 'last_name',
      },
      {
        title: 'Account Email',
        model: 'email',
        type: 'email',
      },
      {
        title: 'Account Phone No',
        model: 'phone_no',
        type: 'phone',
      },
      {
        title: 'Has Community?',
        model: 'has_community',
        disableSort: true,
        formatValue: ({ data }: any) => {
          return size(data.connected_entities) === 0 ? 'No' : 'Yes'
        },
      },
      {
        title: 'Smart Flags',
        model: 'smart_flags',
        type: 'smart_flags',
        editPermission: true,
      },

      {
        title: 'Sales Step',
        id: 'sales_step',
        model: 'system_prefs',
        formatValue: ({ value }: any) => SALES_STEP?.[value?.['sales_step']] || null,
      },
      {
        title: 'Timezone',
        model: 'timezone',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return value.replaceAll('_', ' ')
        },
      },
      {
        title: 'Trial Started On',
        model: 'trial_started_at',
        type: 'date',
        width: 140,
      },
      {
        title: 'Trial Ends On',
        model: 'trial_ended_at',
        type: 'date',
        width: 140,
      },
      // {
      //   title: 'Hubspot Deal',
      //   model: 'hubspot_deal_id',
      //   type: 'hubspot_deal',
      // },
      // {
      //   title: 'Hubspot Company',
      //   id: 'hubspot_company',
      //   model: 'hubspot_company_id',
      //   type: 'hubspot_company',
      //   disableSort: true,
      // },
      {
        title: 'Hubspot EHR Account',
        model: 'hubspot_ehr_account_id',
        type: 'hubspot_ehr_account',
      },
      {
        title: 'Stripe Customer',
        id: 'stripe_customer_id',
        model: 'stripe_customer_id',
        width: 200,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <LinkCell isExternal value={value} href={`https://dashboard.stripe.com/customers/${value}`} />
        },
      },
      {
        title: 'Account Notes',
        id: 'account_notes',
        model: 'metadata.account_notes',
        width: 200,
        hoverExpand: false,
        formatValue: ({ value, data }) => (
          <AccountNotesCell
            value={value}
            data={data}
            onEdit={() => {
              setEditRow(data)
              setNotesData(value)
              setIsNotesOverlayOpen(true)
            }}
          />
        ),
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ]
  }, [props.onClick, location.pathname])

  return (
    <>
      <DataTable asCard title="Companies" icon="enterprise" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />

      <SummonOverlay
        isOpen={isNotesOverlayOpen}
        onClose={() => {
          setEditRow(null)
          setNotesData(null)
          setIsNotesOverlayOpen(false)
        }}
        overlay={<AccountNotesOverlay value={notesData} data={editRow} />}
      />
    </>
  )
}

const AccountNotesOverlay = ({ value, onClose, data }: any) => {
  const form: any = React.useRef()

  const { mutateAsync, isLoading }: any = useUpdate({
    name: ['facilities', data.id],
    url: `/facilities/${data.id}`,
    invalidate: ['facilities'],
  })

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    await mutateAsync(formData)

    if (onClose) onClose()
  }

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header glyph="edit" title="Edit Account Notes" />

      <Overlay.Content>
        <Section>
          <Form getForm={form}>
            <Textarea label="Account Notes" model="metadata.account_notes" value={value} />
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" color="green" type="primary" glyph="check" onClick={handleSave} isLoading={isLoading} />
      </Overlay.Footer>
    </Overlay>
  )
}

const AccountNotesCell = ({ data, value, onEdit }: any) => {
  if (!data) return null

  return (
    <div css={STYLES.notesCell}>
      <div css={STYLES.notesValue}>{value || <EmptyCell />}</div>

      <Button
        hideLabel
        glyph="edit"
        size={100}
        type="minimal"
        css={STYLES.editButton}
        permission="treatment_episodes.edit"
        onClick={onEdit}
      />
    </div>
  )
}

const FILTERS_CONFIG = {
  name: DEFAULT_FILTERS.name,
  tenant: {
    label: 'Tenant / Subdomain',
    type: 'string',
  },
  first_name: DEFAULT_FILTERS.first_name,
  last_name: DEFAULT_FILTERS.last_name,
  global_tags: DEFAULT_FILTERS.global_tags,
  smart_flags: {
    label: 'Smart Flags',
    type: 'smart_flags',
    glyph: 'flag',
  },
  connected_entities: {
    label: 'Community Profiles',
    type: 'multi_overlay',
    polymorphic: false,
    selectTitle: (item: any) => item.name,
    icon: 'enterprise',
    table: <CommunityAccountsTable />,
  },
  primary_profile: {
    label: 'Primary Profile',
    type: 'multi_overlay',
    polymorphic: false,
    selectTitle: (item: any) => item.name,
    icon: 'enterprise',
    table: <CommunityAccountsTable />,
  },
  has_community: {
    label: 'Has Community?',
    type: 'boolean',
  },
  email: DEFAULT_FILTERS.email,
  state: {
    label: 'State',
    type: 'string',
  },
  community_admin_phone_no: {
    label: 'Community Admin Phone No',
    type: 'string',
  },
  community_admin_email: {
    label: 'Community Admin Email',
    type: 'string',
  },
  phone_no: DEFAULT_FILTERS.phone_no,
  timezone: DEFAULT_FILTERS.timezone,
  category: {
    label: 'Account Type',
    type: 'multi_select',
    options: [
      { label: 'Healthcare Facility', value: 'healthcare_facility' },
      { label: 'Healthcare Practice', value: 'healthcare_practice' },
      { label: 'Support Facility', value: 'support_facility' },
      { label: 'Support Practice', value: 'support_practice' },
      { label: 'Provider Partner', value: 'provider_affiliate' },
    ],
  },
  plan_status: {
    label: 'Account Status',
    type: 'multi_select',
    options: [
      { label: 'Trial Requested', value: 'trial_requested' },
      { label: 'Trialing', value: 'trialing' },
      { label: 'Trial Expired', value: 'trial_expired' },
      { label: 'Unpaid', value: 'unpaid' },
      { label: 'Cancelled', value: 'canceled' },
      { label: 'Active', value: 'active' },
      { label: 'Community', value: 'community' },
      { label: 'Archived', value: 'archived' },
    ],
  },
  sales_status: {
    label: 'Sales Status',
    type: 'multi_select',
    options: [
      { label: '–', value: null },
      { label: 'Yes on Value', value: 'yes_on_value' },
      { label: 'Yes on Price/Unpaid', value: 'yes_on_price_unpaid' },
      { label: 'Onboarding', value: 'onboarding' },
    ],
  },
  // hubspot_deal_id: {
  //   label: 'Hubspot Deal',
  //   type: 'string',
  // },
  // hubspot_company: {
  //   label: 'Hubspot Company',
  //   type: 'string',
  // },
  hubspot_ehr_account_id: {
    label: 'Hubspot EHR Account',
    type: 'string',
  },
  stripe_customer_id: {
    label: 'Stripe Customer',
    type: 'string',
  },
  account_notes: {
    label: 'Account Notes',
    type: 'string',
  },
  trial_started_at: {
    label: 'Trial Started On',
    type: 'date_time',
    glyph: 'date',
  },
  trial_ended_at: {
    label: 'Trial Ends On',
    type: 'date_time',
    glyph: 'date',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}

const STYLES = {
  notesCell: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },

  notesValue: {
    flex: '1 1 auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  editButton: {
    svg: {
      margin: '0 !important',
    },
  },

  smartFlag: { marginRight: '0.5rem' },
}
