import size from 'lodash/size'

import { isBlank, DT } from '../../utils/functions'

export const validate = (value: any, validations: any) => {
  if (!validations) return []

  let errors = []

  const validationKeys = Object.keys(validations)
  for (let i = 0; i < validationKeys.length; i++) {
    const validationKey = validationKeys[i]
    const validation = validations[validationKey]

    switch (validationKey) {
      case 'presence':
        const presenceFailed = isBlank(value)
        if (presenceFailed) errors.push({ validation: validationKey, message: validation.message })

        break

      case 'length':
        let lengthSuccess = false
        if ('minimum' in validation) lengthSuccess = size(value) >= validation.minimum
        if ('maximum' in validation) lengthSuccess = size(value) <= validation.maximum

        if (!lengthSuccess) errors.push({ validation: validationKey, message: validation.message || validation.tooShort })

        break

      case 'greaterThan':
        const greaterThanSuccess = value > validation.value
        if (!greaterThanSuccess) errors.push({ validation: validationKey, message: validation.message })

        break

      case 'lessThan':
        const lessThanSuccess = value < validation.value
        if (!lessThanSuccess) errors.push({ validation: validationKey, message: validation.message })

        break

      case 'greaterThanOrEqualTo':
        const greaterThanOrEqualToSuccess = value >= validation.value
        if (!greaterThanOrEqualToSuccess) errors.push({ validation: validationKey, message: validation.message })

        break

      case 'lessThanOrEqualTo':
        const lessThanOrEqualToSuccess = value <= validation.value
        if (!lessThanOrEqualToSuccess) errors.push({ validation: validationKey, message: validation.message })

        break

      case 'format':
        const regex = new RegExp(validation.pattern)
        const formatPassed = regex.test(value)
        if (!formatPassed) errors.push({ validation: validationKey, message: validation.message })

        break

      case 'datetime':
        let datetimeSuccess = true

        if ('earliest' in validation) datetimeSuccess = DT(validation.earliest) <= DT(value)

        if (!datetimeSuccess) errors.push({ validation: validationKey, message: validation.message })

        break
    }
  }

  return errors
}
