import React from 'react'
import { keyframes } from '@emotion/react'
import { transparentize } from 'polished'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../../theme'

import Glyph from '../Glyph'

import { HUBSPOT_ID, CONTACT_EMAIL } from './constants'

type Props = {
  className: string
  hasLink: boolean
  hubspotCTAID?: string
  glyph?: string
  size: string | number
  title: string
  variant: 'white' | 'gold'
}

const MarketingStar = ({ className, hasLink, hubspotCTAID, size, title, variant, glyph = 'behave_health' }: Props) => {
  const Tag = hasLink || hubspotCTAID ? 'a' : 'div'

  const classNames = clsx(className, {
    [`variant-${variant}`]: variant,
  })

  let href = ''
  if (hubspotCTAID) {
    href = `https://cta-redirect.hubspot.com/cta/redirect/${HUBSPOT_ID}/${hubspotCTAID}`
  } else if (hasLink) {
    href = `mailto:${CONTACT_EMAIL}?subject=${title}`
  }

  return (
    <Tag css={styles(size)} className={classNames} href={href} target={hubspotCTAID && '_blank'}>
      <Glyph glyph={glyph} />
    </Tag>
  )
}

const pulseAnimation = (color: string) => {
  return keyframes({
    '0%': {
      transform: 'scale(0.9)',
      boxShadow: `0 0 0 0 ${transparentize(0.3, color)}`,
    },

    '70%': {
      transform: 'scale(1.2)',
      boxShadow: `0 0 0 7px ${transparentize(1, color)}`,
    },

    '100%': {
      transform: 'scale(0.9)',
      boxShadow: `0 0 0 0 ${transparentize(1, color)}`,
    },
  })
}

const styles = (size) => ({
  flex: '0 0 auto',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',

  width: size,
  height: size,
  borderRadius: '50%',

  svg: {
    flex: '0 0 auto',
    width: '75%',
    height: '75%',
    maxWidth: '2rem',
    maxHeight: '2rem',
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderRadius: '50%',
    animation: `${pulseAnimation(COLORS.yellow)} 2s infinite`,
  },

  '&.variant-white': {
    background: COLORS.white,
    boxShadow: SHADOW(2, transparentize(0.85, COLORS.yellow)),

    svg: {
      fill: '#ffb224',
    },
  },

  '&.variant-gold': {
    background: 'linear-gradient(#ffda68, #ffae19)',
    boxShadow: SHADOW(2, transparentize(0.85, COLORS.yellow)),

    svg: {
      fill: COLORS.white,
    },
  },
})

MarketingStar.defaultProps = {
  hasLink: true,
  size: '2rem',
  title: 'Behave Health Feature Request',
  variant: 'gold',
}

export default MarketingStar
