import React from 'react'

import { SectionCard } from '../../components/SectionCard'

import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import NumberInput from '../../components/Forms/NumberInput'
import Option from '../../components/Forms/Option'
import PageSection from '../../components/PageSection/PageSection'
import Select from '../../components/Forms/Select'
import TableArrayForm from '../../components/Forms/TableArrayForm'
import Textarea from '../../components/Forms/Textarea'

export const QuoteSettingsForm = (props: any) => {
  const { isEditable, formData, onValidationUpdate, onUpdate } = props

  const form = React.useRef(null)

  return (
    <Form
      isCompact
      getForm={form}
      isEditable={isEditable}
      initialModel={formData}
      onValidationUpdate={onValidationUpdate}
      onUpdate={onUpdate}
    >
      <div className="px-5 py-5">
        <FormSection maxWidth="100%">
          <PageSection>
            <PageSection.Header>
              <PageSection.Title title="Settings" className="!text-[1.65rem]" />
            </PageSection.Header>

            <div className="grid grid-cols-1 mq1400:grid-cols-3 gap-5">
              {/* <SectionCard title="Payments" className="!p-3">
                <CheckboxGroup
                  label={null}
                  withHover={false}
                  layout="vertical"
                  trueIcon="check"
                  falseIcon="empty_checkbox"
                  falseStyle="faded"
                >
                  <Checkbox label="Auto-Collect Payment" model="use_auto_collection" />
                  <Checkbox label="Allow Partial Collection" model="use_auto_collection" />
                </CheckboxGroup>
              </SectionCard> */}

              <SectionCard title="Customer Reminders" className="!p-3">
                <CheckboxGroup
                  label={null}
                  withHover={false}
                  layout="vertical"
                  trueIcon="check"
                  falseIcon="empty_checkbox"
                  falseStyle="faded"
                >
                  <Checkbox label="Enable Reminders Before Due Date" model="should_send_reminders_before" />
                  <ContextShow when="should_send_reminders_before" is={true}>
                    <div className="mt-2 !mb-4">
                      <TableArrayForm
                        title="Reminders Before Due Date"
                        name="Reminders"
                        model="send_reminders_to_before"
                        inputs={REMINDERS_BEFORE_INPUTS}
                      />
                    </div>
                  </ContextShow>

                  <Checkbox label="Enable Reminders After Due Date" model="should_send_reminders_after" />
                  <ContextShow when="should_send_reminders_after" is={true}>
                    <div className="mt-2">
                      <TableArrayForm
                        title="Reminders After Due Date"
                        name="Reminders"
                        model="send_reminders_to_after"
                        inputs={REMINDERS_AFTER_INPUTS}
                      />
                    </div>
                  </ContextShow>
                </CheckboxGroup>
              </SectionCard>

              <SectionCard title="Behave Notifications" className="!p-3">
                <CheckboxGroup isCompact withHover={false} trueIcon="check" falseIcon="empty_checkbox" falseStyle="faded" layout="vertical">
                  <Checkbox
                    label="Notify on Offer Selected"
                    model="send_notifications.offer_selected"
                    tooltip="When checked, the emails and phone numbers below will be notified when the Billed To selects an Offer "
                  />

                  <ContextShow when="send_notifications.offer_selected" is={true}>
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model="send_notifications.offer_selected_emails"
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                      />

                      <Input
                        alwaysShowDescription
                        model="send_notifications.offer_selected_phone_nos"
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                      />
                    </div>
                  </ContextShow>

                  <Checkbox
                    label="Notify on Signed"
                    model="send_notifications.signed"
                    tooltip="When checked, the emails and phone numbers below will be notified when the Billed To signs the Quote"
                  />

                  <ContextShow when="send_notifications.signed" is={true}>
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model="send_notifications.signed_emails"
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                      />

                      <Input
                        alwaysShowDescription
                        model="send_notifications.signed_phone_nos"
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                      />
                    </div>
                  </ContextShow>

                  <Checkbox
                    label="Notify on Expired"
                    model="send_notifications.expired"
                    tooltip="When checked, the emails and phone numbers below will be notified if the Quote Expires"
                  />

                  <ContextShow when="send_notifications.expired" is={true}>
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model="send_notifications.expired_emails"
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                      />

                      <Input
                        alwaysShowDescription
                        model="send_notifications.expired_phone_nos"
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                      />
                    </div>
                  </ContextShow>

                  <Checkbox
                    label="Notify on Payment Attempted"
                    model="send_notifications.payment_attempted"
                    tooltip="When checked, the emails and phone numbers below will be notified when a payment is attempted"
                  />

                  <ContextShow when="send_notifications.payment_attempted" is={true}>
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model="send_notifications.payment_attempted_emails"
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                      />

                      <Input
                        alwaysShowDescription
                        model="send_notifications.payment_attempted_phone_nos"
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                      />
                    </div>
                  </ContextShow>

                  <Checkbox
                    label="Notify on Payment Succeeded"
                    model="send_notifications.payment_succeeded"
                    tooltip="When checked, the emails and phone numbers below will be notified when a payment is successful"
                  />

                  <ContextShow when="send_notifications.payment_succeeded" is={true}>
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model="send_notifications.payment_succeeded_emails"
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                      />

                      <Input
                        alwaysShowDescription
                        model="send_notifications.payment_succeeded_phone_nos"
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                      />
                    </div>
                  </ContextShow>

                  <Checkbox
                    label="Notify on Payment Failed"
                    model="send_notifications.payment_failed"
                    tooltip="When checked, the emails and phone numbers below will be notified when a payment has failed"
                  />

                  <ContextShow when="send_notifications.payment_failed" is={true}>
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model="send_notifications.payment_failed_emails"
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                      />

                      <Input
                        alwaysShowDescription
                        model="send_notifications.payment_failed_phone_nos"
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                      />
                    </div>
                  </ContextShow>

                  <Checkbox
                    label="Notify on EHR Connected"
                    model="send_notifications.ehr_connected"
                    tooltip="When checked, the emails and phone numbers below will be notified when an EHR is connected to the Offer "
                  />

                  <ContextShow when="send_notifications.ehr_connected" is={true}>
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model="send_notifications.ehr_connected_emails"
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                      />

                      <Input
                        alwaysShowDescription
                        model="send_notifications.ehr_connected_phone_nos"
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                      />
                    </div>
                  </ContextShow>

                  <Checkbox
                    label="Notify on EHR Disconnected"
                    model="send_notifications.ehr_disconnected"
                    tooltip="When checked, the emails and phone numbers below will be notified when an EHR is disconnected from the Offer "
                  />

                  <ContextShow when="send_notifications.ehr_disconnected" is={true}>
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model="send_notifications.ehr_disconnected_emails"
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                      />

                      <Input
                        alwaysShowDescription
                        model="send_notifications.ehr_disconnected_phone_nos"
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                      />
                    </div>
                  </ContextShow>

                  <Checkbox
                    label="Notify on Completed / Subscription Started"
                    model="send_notifications.completed"
                    tooltip="When checked, the emails and phone numbers below will be notified when a Quote is Completed and the Subscription is started"
                  />

                  <ContextShow when="send_notifications.completed" is={true}>
                    <div className="grid gap-3 grid-cols-[100%] pl-6 pt-1 pb-3">
                      <Input
                        alwaysShowDescription
                        model="send_notifications.completed_emails"
                        type="email"
                        label="Email Addresses"
                        description="Emails support multiple recipients, separated by a comma (,)"
                      />

                      <Input
                        alwaysShowDescription
                        model="send_notifications.completed_phone_nos"
                        type="phone"
                        label="Phone Number"
                        description="SMS supports only a single recipient"
                      />
                    </div>
                  </ContextShow>
                </CheckboxGroup>
              </SectionCard>
            </div>
          </PageSection>
        </FormSection>
      </div>
    </Form>
  )
}

const REMINDERS_BEFORE_INPUTS = [
  {
    name: 'Reminder Type',
    width: '2fr',
    element: (id: string) => (
      <Select isCompact withHover={false} model={`${id}.reminder_type`} defaultValue="email">
        <Option label="Email" value="email" />
        <Option label="SMS" value="sms" />
        <Option label="Email + SMS" value="email_sms" />
      </Select>
    ),
  },
  {
    name: 'Days Before',
    width: '90px',
    element: (id: string, _element, index) => {
      return <NumberInput defaultValue={(index + 1) * 3} isCompact withHover={false} model={`${id}.days_before_due_date`} />
    },
  },
  {
    name: 'Description',
    width: '2fr',
    element: (id: string) => <Textarea isCompact withHover={false} model={`${id}.description`} minRows={1} />,
  },
]

const REMINDERS_AFTER_INPUTS = [
  {
    name: 'Reminder Type',
    width: '2fr',
    element: (id: string) => (
      <Select isCompact withHover={false} model={`${id}.reminder_type`} defaultValue="email">
        <Option label="Email" value="email" />
        <Option label="SMS" value="sms" />
        <Option label="Email + SMS" value="email_sms" />
      </Select>
    ),
  },
  {
    name: 'Days After',
    width: '90px',
    element: (id: string, _element, index) => {
      return <NumberInput defaultValue={(index + 1) * 3} isCompact withHover={false} model={`${id}.days_after_due_date`} />
    },
  },
  {
    name: 'Description',
    width: '2fr',
    element: (id: string) => <Textarea isCompact withHover={false} model={`${id}.description`} minRows={1} />,
  },
]
