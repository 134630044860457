import { arrayToTree } from 'performant-array-to-tree'
import size from 'lodash/size'

import { isDefined } from '../../utils/functions'

export const DEBUG = false

export const processFoldersData = (data: any, folders: any) => {
  if (DEBUG) console.debug('🗂 getDataByFolderId INPUT', { data, folders })

  const result: any = { dataMap: {}, rootDataIds: [], folderDataIds: {} }

  if (!data) return result

  for (const record of data) {
    const folderId = record.folder_id

    result.dataMap[record.id] = record

    if (!folderId) {
      result.rootDataIds.push(record.id)
      continue
    }

    if (!result[folderId]) result.folderDataIds[folderId] = []

    result.folderDataIds[folderId].push(record.id)
  }

  if (DEBUG) console.debug('🗂 getDataByFolderId OUTPUT', result)

  return result
}

export const getFoldersTree = (folders: any) => {
  if (!folders) return []

  const result = arrayToTree(folders, {
    id: 'id',
    parentId: 'parent_id',
    childrenField: 'subfolders',
    dataField: null,
  })

  return result
}

export const parseCellCoords = (coords: string) => {
  if (!coords) return null

  const splitCoords = coords.split('-')

  if (size(splitCoords) !== 2) return null

  return {
    x: parseInt(splitCoords[0]),
    y: parseInt(splitCoords[1]),
  }
}

export const stringifyCellCoords = ({ x, y }: any) => {
  if (!isDefined(x) || !isDefined(y)) return null

  return `${x}-${y}`
}
