import React from 'react'

import { COLORS } from '../theme'

const TabDivider = ({ className }) => {
  return <span css={styles} className={className} />
}

const styles = {
  display: 'inline-flex',
  marginRight: '1.75rem',
  width: 1,
  height: '1.5rem',
  background: COLORS.divider,
}

export default TabDivider
