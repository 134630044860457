import React from 'react'
import { useDispatch } from 'react-redux'

import { apiGetAll } from '../modules/api'
import { abortDataFetching, clearData } from '../actions/data'

export const useAPI = (name, url, clear = true, skip = false) => {
  const dispatch = useDispatch()

  return React.useEffect(() => {
    if (!skip) {
      apiGetAll({ name, url })
    }

    return () => {
      dispatch(abortDataFetching())
      if (clear) dispatch(clearData(name))
    }
  }, [dispatch, name, url, clear, skip])
}

export const useAPIwithNoAbort = (name, url, clear = true, skip = false) => {
  const dispatch = useDispatch()

  return React.useEffect(() => {
    if (!skip) {
      apiGetAll({ name, url })
    }

    return () => {
      if (clear) dispatch(clearData(name))
    }
  }, [dispatch, name, url, clear])
}

export const useAPIwithInitialClear = (name, url, clear = true, skip = false) => {
  const dispatch = useDispatch()

  return React.useEffect(() => {
    if (!skip) {
      dispatch(clearData(name))
      apiGetAll({ name, url })
    }
    return () => {
      if (clear) dispatch(clearData(name))
    }
  }, [dispatch, name, url, clear])
}

export default useAPI
