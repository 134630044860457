import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { ActiveInsurancePayersDataTable } from '@behavehealth/constructs/Insurance/ActiveInsurancePayersDataTable'

const ActiveInsurancePayers: React.FC = () => {
  return <ActiveInsurancePayersDataTable />
}

export default withPageError(ActiveInsurancePayers)
