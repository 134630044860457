import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Timeline, Page, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

const ActivityTimeline: React.FC = () => {
  const match = useRouteMatch()
  return (
    <Page feature="activity_timeline" plan="lite" help={<HelpTagIframe id="activity_timeline" />}>
      <Timeline asAuthor recordType="employee" recordID={match?.params?.resource_id} />
    </Page>
  )
}

export default withPageError(ActivityTimeline)
