import React from 'react'

import Empty from './Empty'

const Address: React.FC = ({ value }) => {
  if (!value) return <Empty />
  const address = []

  if (value.address_line_1) address.push(value.address_line_1)
  if (value.address_line_2) address.push(value.address_line_2)
  if (value.city) address.push(value.city)
  if (value.state) address.push(value.state)
  if (value.zip_code) address.push(value.zip_code)

  return address.join(', ')
}

export default Address
