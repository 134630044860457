import React from 'react'
import { EditorContent } from '@tiptap/react'

import { COLORS } from '../../../../theme'

export const TiptapPromptInput = (props: any) => {
  const { editor, appendContent } = props

  const [isMenuVisible, setIsMenuVisible] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)

  // const [content, setContent] = React.useState('')
  // React.useEffect(() => {
  //   if (!editor) return

  //   editor.on('update', ({ getText }: any) => {
  //     setContent(getText())
  //   })
  // }, [editor, content])

  if (!editor) return null

  return (
    <>
      {/* <PromptsMenu
        preventAutoFocus
        isOpen={isMenuVisible}
        onOpenUpdated={setIsMenuVisible}
        side="top"
        onSelect={(prompt: any) => {
          editor?.commands.setContent(prompt?.prompt)
        }}
      /> */}

      <EditorContent editor={editor} css={STYLES.editor} />
    </>
  )
}

const STYLES = {
  editor: {
    width: '100%',
    background: COLORS.white,
    borderBottom: `1px solid ${COLORS.divider}`,

    '.ProseMirror': {
      minHeight: 50,
      outline: 'none',
      cursor: 'text',
      padding: '0.5rem',
    },

    '.ProseMirror > *:first-child': {
      marginTop: 0,
    },

    '.ProseMirror > *:last-child': {
      marginBottom: 0,
    },

    '.ProseMirror p.is-editor-empty:first-child::before': {
      color: '#adb5bd',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: '0',
      pointerEvents: 'none',
    },

    // '.ProseMirror p.is-empty::before': {
    //   color: '#adb5bd',
    //   content: 'attr(data-placeholder)',
    //   float: 'left',
    //   height: '0',
    //   pointerEvents: 'none',
    // },
  },
}
