import React from 'react'

import { titleCase } from '../../../utils/functions'

import ResourceQuickLink from '../../QuickLinks/ResourceQuickLink'

export const MESSAGES: any = {
  batch_create: (props: any) => (
    <>
      {props.author} invited <ResourceQuickLink resource={props.event.trackable} disableLink={props.disableLink} /> (
      {titleCase(props.event.trackable?.position)}) to the app.
    </>
  ),
  create: (props: any) => (
    <>
      {props.author} invited <ResourceQuickLink resource={props.event.trackable} disableLink={props.disableLink} /> (
      {titleCase(props.event.trackable?.position)}) to the app.
    </>
  ),
  block: (props: any) => (
    <>
      {props.author} blocked <ResourceQuickLink resource={props.event.trackable} disableLink={props.disableLink} /> from using the app.
    </>
  ),
  unblock: (props: any) => (
    <>
      {props.author} unblocked <ResourceQuickLink resource={props.event.trackable} disableLink={props.disableLink} />.
    </>
  ),
  update: (props: any) => (
    <>
      {props.author} updated the details of <ResourceQuickLink resource={props.event.trackable} disableLink={props.disableLink} />.
    </>
  ),
  destroy: (props: any) => (
    <>
      {props.author} deleted a staff member ({props.event.trackable?.name}) from the app.
    </>
  ),
}

export default (props: any) => MESSAGES[props.event?.action_type]?.(props) || null
