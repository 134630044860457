import React from 'react'

import ContextShow from '../ContextShow'
import Input from '../Input'
import Option from '../Option'
import Select from '../Select'

const PreferredPronounsSelect = ({ testKey, label, model, isRequired }: any) => (
  <>
    <Select
      allowEmpty
      testKey={testKey}
      label={label}
      model={model}
      validations={
        isRequired && {
          presence: {
            message: 'Please select a pronoun preference',
          },
        }
      }
    >
      <Option label="She/her/hers" value="she_her" />
      <Option label="He/him/his" value="he_him" />
      <Option label="They/them/theirs" value="they_them" />
      <Option label="Other" value="other" />
    </Select>

    <ContextShow when={model} is="other">
      <Input label="Please specify a pronoun preference" model={`${model}_other`} />
    </ContextShow>
  </>
)

PreferredPronounsSelect.defaultProps = {
  label: 'Preferred Pronouns',
  model: 'preferred_pronouns',
}

export default PreferredPronounsSelect
