import React from 'react'

import { COLORS } from '../../../theme'

export const FilterSegment: React.FC<any> = ({ children, className }) => {
  return (
    <div css={STYLES.segment} className={className}>
      {children}
    </div>
  )
}

const STYLES = {
  segment: {
    display: 'inline-flex',
    alignItems: 'center',
    minHeight: 'var(--filter-height)',
    borderRight: `1px solid ${COLORS.divider}`,
  },
}
