import React from 'react'
import { persist } from 'zustand/middleware'
import create from 'zustand'
import size from 'lodash/size'

import useStore from '../../modules/store'

import { BannerMessage } from '../../components/BannerMessage'
import { TopBarMessage } from '../../components/TopBarMessage'

export const AppMessages = () => {
  const dismissForever = useAppMessagesStore((state: any) => state.dismissForever)
  const foreverDismissedIds = useAppMessagesStore((state: any) => state.foreverDismissedIds)

  const [dismissedIds, setDismissedIds]: any = React.useState(foreverDismissedIds)

  const notifications: any = useStore((state) => state.notifications)
  const appMessages = notifications?.app_messages

  const grouped: any = React.useMemo(() => {
    if (!appMessages) return {}

    const result: any = {
      top_bar: [],
      banner: [],
      full_screen: [],
    }

    const filtered = appMessages.filter((o: any) => !dismissedIds.includes(o.id))

    for (const message of filtered) {
      if (message.start_position === 'center') result.full_screen.push(message)
      else if (message.appearance === 'top_bar') result.top_bar.push(message)
      else if (message.appearance === 'banner') result.banner.push(message)
    }

    return result
  }, [appMessages, dismissedIds, foreverDismissedIds])

  const handleDismiss: any = (message: any) => {
    if (!message?.id) return

    setDismissedIds((c) => [...c, message.id])

    if (message.is_dismissible) dismissForever(message?.id)
  }

  if (size(appMessages) === 0) return null

  return (
    <>
      {size(grouped?.top_bar) > 0 && <TopBarMessages messages={grouped.top_bar} onDismiss={handleDismiss} />}
      {size(grouped?.banner) > 0 && <BannerMessages messages={grouped.banner} onDismiss={handleDismiss} />}
      {size(grouped?.full_screen) > 0 && <FullScreenMessages messages={grouped.full_screen} onDismiss={handleDismiss} />}
    </>
  )
}

const BannerMessages = (props: any) => {
  const { messages, onDismiss } = props

  const [activeIndex, setActiveIndex] = React.useState(0)

  const count = size(messages)

  const goNext = () => {
    setActiveIndex((c) => {
      if (c < count - 1) return c + 1
      return 0
    })
  }

  const goPrev = () => {
    setActiveIndex((c) => {
      if (c > 0) return c - 1
      return count - 1
    })
  }

  const activeMessage = messages?.[activeIndex]

  if (!activeMessage) return null

  return (
    <BannerMessage
      isLarge={activeMessage.start_position === 'center'}
      color={activeMessage.color}
      currentCount={activeIndex + 1}
      glyph={activeMessage.glyph}
      title={activeMessage.title}
      iframeURL={activeMessage.iframe_url}
      iframeWidth={activeMessage.iframe_width}
      iframeHeight={activeMessage.iframe_height}
      onDismissClick={() => {
        onDismiss(activeMessage)

        setActiveIndex((c) => {
          const nextCount = count - 1

          if (c < nextCount - 1) return c + 1
          else if (c > 0) return c - 1
          else return 0
        })
      }}
      onNextClick={goNext}
      onPrevClick={goPrev}
      richText={activeMessage.content}
      totalCount={count}
    />
  )
}

const FullScreenMessages = (props: any) => {
  const { messages, onDismiss } = props

  const [activeIndex, setActiveIndex] = React.useState(0)

  const count = size(messages)

  const goNext = () => {
    setActiveIndex((c) => {
      if (c < count - 1) return c + 1
      return 0
    })
  }

  const goPrev = () => {
    setActiveIndex((c) => {
      if (c > 0) return c - 1
      return count - 1
    })
  }

  const activeMessage = messages?.[activeIndex]

  if (!activeMessage) return null

  return (
    <BannerMessage
      isLarge={activeMessage.start_position === 'center'}
      color={activeMessage.color}
      currentCount={activeIndex + 1}
      glyph={activeMessage.glyph}
      title={activeMessage.title}
      iframeURL={activeMessage.iframe_url}
      onDismissClick={() => {
        onDismiss(activeMessage)

        setActiveIndex((c) => {
          const nextCount = count - 1

          if (c < nextCount - 1) return c + 1
          else if (c > 0) return c - 1
          else return 0
        })
      }}
      onNextClick={goNext}
      onPrevClick={goPrev}
      richText={activeMessage.content}
      totalCount={count}
    />
  )
}

const TopBarMessages = (props: any) => {
  const { messages, onDismiss } = props

  const [activeIndex, setActiveIndex] = React.useState(0)

  const count = size(messages)

  const goNext = () => {
    setActiveIndex((c) => {
      if (c < count - 1) return c + 1
      return 0
    })
  }

  const goPrev = () => {
    setActiveIndex((c) => {
      if (c > 0) return c - 1
      return count - 1
    })
  }

  const activeMessage = messages?.[activeIndex]

  if (!activeMessage) return null

  return (
    <TopBarMessage
      color={activeMessage.color}
      currentCount={activeIndex + 1}
      glyph={activeMessage.glyph}
      onDismissClick={() => {
        onDismiss(activeMessage)

        setActiveIndex((c) => {
          const nextCount = count - 1

          if (c < nextCount - 1) return c + 1
          else if (c > 0) return c - 1
          else return 0
        })
      }}
      onNextClick={goNext}
      onPrevClick={goPrev}
      richText={activeMessage.content}
      totalCount={count}
    />
  )
}

const store = (set: any, get: any) => ({
  foreverDismissedIds: [],
  dismissForever: (id: string) => {
    set((state: any) => ({ foreverDismissedIds: [...state.foreverDismissedIds, id] }))
  },
})

const useAppMessagesStore = create(persist(store, { name: 'bh.dismissed_app_messages' }))
