import React from 'react'
import { v4 as uuid } from 'uuid'
import get from 'lodash/get'

import { withFormContext } from './context'

class ContextHide extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      type: 'CONTEXT_HIDE',
      id: `IF-${props.when}-${uuid()}`,
      when: props.when,
      hide: false,
      value: null,
      onRegistrationCompleted: this.onRegistrationCompleted,
    }

    this.value = null
  }

  componentDidMount = () => {
    if (this.props.form) this.props.form.register(this.state)
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextState.hide !== this.state.hide) {
      this.updateType = 'STATE'
      return true
    }

    if (nextProps.lastUpdatedInput === this.props.when) {
      const value = this.props.form?.getField(this.props.when)
      if (value !== this.state.value) {
        this.updateType = 'DATA'
        return true
      }
    }

    return false
  }

  componentDidUpdate = () => {
    if (this.updateType === 'DATA') {
      let { hide, value } = this.process()
      this.setState({ hide, value })
    }
  }

  componentWillUnmount = () => {
    if (this.props.form) this.props.form.deregister(this.state)
  }

  /*
    CUSTOM
  */
  process = () => {
    const { is, when, declineUndefined } = this.props

    const value = this.props.form.getFieldValue(when)

    if (value === is) return { hide: true, value: value }
    if (declineUndefined && value === undefined) return { hide: true, value: value }

    return { hide: false, value: value }
  }

  onRegistrationCompleted = () => {
    const { hide, value } = this.process()
    this.setState({ hide: hide, value })
  }

  /*
    RENDER
  */
  render() {
    return this.state.hide ? null : this.props.children
  }
}

export default withFormContext(ContextHide)
