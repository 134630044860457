import React from 'react'
import { v4 as uuid } from 'uuid'
import size from 'lodash/size'

import { usDateTime, countWord } from '../../utils/functions'

import { DataTable } from '../../components/DataTable/DataTable'
import { useDataTable } from '../../components/DataTable/useDataTable'
import Tooltip from '../../components/Tooltip'
import Glyph from '../../components/Glyph'

const UPDATES = {
  started_at: 'Start Date',
  target_at: 'Target Date',
  last_reviewed_at: 'Last Review Date',
  completed_at: 'Completion Date',
}

export const FormSubmissionUpdatesDataTable = (props: any) => {
  const { submissionId, pageSize = 5 } = props

  const tableProps = useDataTable({
    name: ['form_submissions', submissionId, 'form_submissions_updates'],
    endpoint: `/form_submissions/${submissionId}/form_submissions_events`,
    enabled: false,
    pageSize,
  })

  const dataColumns = React.useMemo(
    () => [
      {
        title: 'Label',
        model: 'name',
        width: 155,
        disableSort: true,
        disableHide: true,
      },
      {
        title: 'Changed From',
        model: 'from',
        width: 130,
        disableSort: true,
        disableHide: true,
      },
      {
        title: 'Changed To',
        model: 'to',
        width: 130,
        disableSort: true,
        disableHide: true,
      },
    ],
    [],
  )

  const columns = React.useMemo(
    () => [
      {
        title: 'Event Name',
        model: 'name',
        width: 600,
      },
      {
        title: 'Updates',
        model: 'data',
        width: 150,
        formatValue: ({ data, value }: any) => {
          const updates = size(value)

          let tableData = []
          for (let key in value) {
            if (['started_at', 'target_at', 'last_reviewed_at', 'completed_at'].includes(key)) {
              tableData.push({
                id: uuid(),
                name: UPDATES[key],
                from: usDateTime(value[key].from !== '–' ? value[key].from : null) || '–',
                to: usDateTime(value[key].to !== '–' ? value[key].to : null) || '–',
              })
            } else {
              tableData.push({
                id: uuid(),
                name: UPDATES[key],
                from: value[key].from,
                to: value[key].to,
              })
            }
          }

          return (
            <Tooltip position="right" content={<DataTable asCard mainHeaderHidden data={tableData} columns={dataColumns} />}>
              <div className="flex flex-nowrap items-center">
                {countWord('Updates', updates)}
                <Glyph glyph="info" className="ml-1" size={12} />
              </div>
            </Tooltip>
          )
        },
      },
      {
        title: 'Type',
        model: 'action',
        width: 150,
        // formatValue: ({ value }: any) => TODO
      },
      {
        title: 'Triggered By',
        model: 'author',
        type: 'profile',
        width: 200,
      },
      {
        title: 'Triggered At',
        model: 'triggered_at',
        type: 'date_time',
        width: 250,
      },
    ],
    [],
  )

  return <DataTable title="Form Submission Updates" icon="web_form" columns={columns} {...props} {...tableProps} />
}
