import React from 'react'

import { COLORS } from '../../theme'
import { useSettings } from '../../hooks/useSettings'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Divider from '../../components/Divider'
import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'

export const EmailDropdownMenu = ({ email }: any) => {
  const { isEHRApp, isHQApp } = useSettings()

  const { copy } = useCopyToClipboard({
    text: email,
    notification: 'Email address copied',
  })

  if (!isEHRApp || !isHQApp) return null

  return (
    <Dropdown trigger={<Button hideLabel type="minimal" glyph="more_vertical" size={100} />}>
      <div className="px-3 py-3 -mb-2">
        <Alert small glyph="warning" type="warning">
          Be cautious about sharing sensitive information through external email applications.
        </Alert>
      </div>

      <DropdownItem glyph="email" label="Open Default Mail App" href={`mailto:${email}`} />
      <DropdownItem
        icon="gmail"
        target="_blank"
        label="Open in Gmail (Web)"
        href={`https://mail.google.com/mail/u/0/?ui=2&tf=cm&fs=1&to=${email}`}
      />
      <DropdownItem
        icon="outlook"
        target="_blank"
        label="Open in Outlook (Web)"
        href={`https://outlook.live.com/mail/0/deeplink/compose?popoutv2=1&to=${email}`}
      />
      <Divider />
      <DropdownItem glyph="copy" color="blue" glyphColor={COLORS.blue} label="Copy Email Address" onClick={copy} />
    </Dropdown>
  )
}
