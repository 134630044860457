import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'
import { usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'
import DataList from '../../../components/DataList'
import TestResultStatus from '../../../components/Statuses/TestResultStatus'

import { RecordTooltip } from '../components/RecordTooltip'

export const VitalSign = ({ data, client }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <RecordTooltip
      title="Vital Signs"
      icon="test_results"
      color={tint(0.4, COLORS.blue)}
      startDate={data.recorded_at}
      recordLink={`/test-results/vital-signs/${data.id}`}
      client={client}
    >
      <DataList isCompact withPadding labelWidth={120}>
        <DataList.Item label="Status" value={<TestResultStatus status={data.status} />} />
        <DataList.Item label="Date & Time" value={usDateTime(data.recorded_at, timezone)} />
      </DataList>
    </RecordTooltip>
  )
}
