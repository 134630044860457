import React from 'react'
import { connect } from 'react-redux'

import { Heading, Text, P, Ul, Ol, Li } from '../../../Typography'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import ClientProfileHeader from '../../../ClientProfileHeader'
import DataFormStatus from '../../../Statuses/DataFormStatus'
import Divider from '../../../Divider'
import Grid from '../../../Grid'
import Overlay from '../../../Overlay'
import Section from '../../../Section'

import Chotomate from '../../../Chotomate'

import { DataFormsBase, mapDispatchToProps, mapStateToProps } from './DataFormsBase'
import { withOverlayError } from '../../../../hocs/withOverlayError'

class IntakeForm extends DataFormsBase {
  renderHeader = () => (
    <Overlay.Header
      icon="clinical_assessments"
      title={this.props.data_form?.name || this.props.title}
      titleAside={<DataFormStatus status={this.props.data_form?.status} />}
      description={<ClientProfileHeader client={this.props.data_form?.client || this.props.client} />}
    />
  )

  renderContent = () => {
    return (
      <>
        <Chotomate ready name="intake_form_overlay" />

        <Section
          scrollview={{
            id: 'client_rights',
            name: 'Client Rights',
          }}
        >
          <Grid gap={16}>
            <Heading is="h1">CLIENT RIGHTS</Heading>

            <Ol>
              <Li>A client should be treated with dignity, respect, and consideration;</Li>
              <Li>
                A client is not subject to:
                <Ol>
                  <Li>Abuse;</Li>
                  <Li>Neglect;</Li>
                  <Li>Exploitation;</Li>
                  <Li>Coercion;</Li>
                  <Li>Manipulation;</Li>
                  <Li>Sexual abuse;</Li>
                  <Li>Sexual assault;</Li>
                  <Li>Retaliation for submitting a complaint to the Department or another entity; or</Li>
                  <Li>
                    Misappropriation of personal and private property by an outpatient treatment center’s personnel member, employee,
                    volunteer, or student;
                  </Li>
                </Ol>
              </Li>

              <Li>
                A client or the client’s representative:
                <Ol>
                  <Li>Except in an emergency, either consents to or refuses treatment;</Li>
                  <Li>May refuse or withdraw consent for treatment before treatment is initiated;</Li>
                  <Li>
                    Except in an emergency, is informed of alternatives to a proposed psychotropic medication and associated risks and
                    possible complications of a proposed psychotropic medication;
                  </Li>
                  <Li>
                    Is informed of the following:
                    <Ol>
                      <Li>The outpatient treatment center’s policy on health care directives, and</Li>
                      <Li>The client complaint process;</Li>
                    </Ol>
                  </Li>
                  <Li>
                    Consents to photographs of the client before a client is photographed, except that a client may be photographed when
                    admitted to an outpatient treatment center for identification and administrative purposes; and
                  </Li>
                  <Li>
                    Except as otherwise permitted by law, provides written consent to the release of information in the client’s:
                    <Ol>
                      <Li>Medical record, or</Li>
                      <Li>Financial records.</Li>
                    </Ol>
                  </Li>
                </Ol>
              </Li>

              <Li>
                A client has the following rights:
                <Ol>
                  <Li>
                    Not to be discriminated against based on race, national origin, religion, gender, sexual orientation, age, disability,
                    marital status, or diagnosis;
                  </Li>
                  <Li>To receive treatment that supports and respects the client’s individuality, choices, strengths, and abilities;</Li>
                  <Li>To receive privacy in treatment and care for personal needs;</Li>
                  <Li>To review, upon written request, the clients own medical record according to state and/or federal law;</Li>
                  <Li>
                    To receive a referral to another health care institution if the outpatient treatment center is not authorized or not
                    able to provide physical health services or behavioral health services needed by the client;
                  </Li>
                  <Li>
                    To participate or have the client’s representative participate in the development of, or decisions concerning,
                    treatment;
                  </Li>
                  <Li>To participate or refuse to participate in research or experimental treatment; and</Li>
                  <Li>
                    To receive assistance from a family member, the client’s representative, or other individual in understanding,
                    protecting, or exercising the client’s rights; such as the use of an interpreter if the client does not speak English.
                  </Li>
                </Ol>
              </Li>
            </Ol>

            <Heading is="h3">I hereby acknowledge that I have received, read, and understand the Client Rights.</Heading>
          </Grid>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'hipaa_and_phi_disclosure',
            name: 'HIPAA & PHI Disclosure',
          }}
        >
          <Grid gap={16}>
            <Heading is="h1">HIPAA AND PHI DISCLOSURE</Heading>
            <P>
              This Notice of Privacy Practices describes how we may use and disclose your protected health information (PHI) to carry
              treatment payment or health care options and for other purposes that are permitted or required by law. Protected health
              information is your information such as demographics, other health conditions, and health care services.
            </P>

            <Heading is="h3">Users and Disclosures of Protected Health Information.</Heading>
            <P>
              Your PHI may be used by anyone involved in your care and treatment in order to provide health care services to you, pay your
              health care bills, or any other use required by law.
            </P>

            <P>
              <Text bold>Treatment:</Text> We will use and disclose your PHI to provide, coordinate, or manage your health care and any
              related services which includes the coordination or management of your health care with a third party.
            </P>

            <P>
              <Text bold>Payment:</Text> Your PHI will be used, as needed to obtain payment for your health care services.
            </P>

            <P>
              <Text bold>Healthcare Operations:</Text> We may use or disclose, as needed, your PHI in order to support the business
              activities of your physician's practice. These activities include, but are not limited to quality assessment activities,
              employee review activities, training of medical students, licensing, and conducing or arranging for other business activities.
            </P>

            <P>
              <Text italic>
                We may use or disclose your PHI in the following situations without your authorization. These situations include:
              </Text>{' '}
              as Required by Law, Public Health issues as required by law, Communicable diseases; Health Oversight Abuse or Neglect; FDA
              requirements; Legal Proceedings; Law Enforcement; Coroners; Funeral Directors, and Organ Donation; Research; Criminal
              Activity; Military Activities and National Security; Worker's Compensation; Inmates; Required Uses and Disclosures. Under the
              Law, we must disclose to you and when required by the Secretary of the Department of Health and Human Services to investigate
              or determine our compliance with the requirements of Section 164.500 will be made only with your consent, authorization or
              opportunity to object unless required by law.
            </P>

            <Heading is="h3">Other Permitted and Required Uses and Disclosures:</Heading>

            <P>
              <Text bold>Your Rights: The following is a statement of your rights with respect to your PHI.</Text>
            </P>

            <P>
              <Text italic>You have the right to inspect and copy your PHI:</Text> Under federal law, however, you may not inspect or copy
              the following records: psychotherapy notes; information compiled in reasonable anticipation of, or use in a civil, criminal,
              or administrative action or proceeding, and PHI that is subject to law that prohibits access to PHI.
            </P>

            <P>
              <Text italic>You have the right to request a restriction of your PHI:</Text> Your request must state the specific restriction
              requested and to whom you want the restriction to apply. Your physician is not required to agree to a restriction that you may
              request. If the physician believes it is in your best interest to permit use and disclosure of your PHI, your PHI will not be
              restricted. You then have the right to use another Healthcare Professional. You have the right to request to receive
              confidential communications form us by alternative means or at an alternative location.
            </P>

            <P>
              <Text italic>You may have the right to have your physician amend your PHI.</Text>
            </P>

            <P>
              <Text italic>You have the right to receive an accounting of certain disclosures we have made, if any, of your PHI.</Text>
            </P>

            <P>
              <Text bold>
                We reserve the right to change the terms of this notice and will inform you, by mail, of any changes. You then have the
                right to object or withdraw as provided in this notice.
              </Text>
            </P>

            <P>
              <Text bold>Complaints:</Text> You may complain to us or to the Secretary of Health and Human Services if you believe your
              privacy rights have been violated by us. We will not retaliate against you for filing a complaint. We are required by law to
              maintain the privacy of, and provide individuals with, this notice of our legal duties and privacy practices with respect to
              PHI. If you have any objections to this form, please ask to speak with our HIPAA Compliance Officer in person or by phone at
              our main phone number.
            </P>
          </Grid>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'naloxone_consent',
            name: 'Naloxone Consent',
          }}
        >
          <Grid gap={16}>
            <Heading is="h1">NALOXONE (NARCAN) CONSENT AND LIABILITY RELEASE</Heading>
            <Heading is="h3">
              I hereby give my consent for the administration of Naloxone (Narcan) while at Treatment Center in the event of a suspected
              opioid overdose and do hereby release Treatment Center and all its volunteers, sponsors, staff, officers, directors, agents,
              and other representatives from any liability as a result of the administration of Naloxone (Narcan).
            </Heading>
          </Grid>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'consent_for_care',
            name: 'Consent for Care & Treatment',
          }}
        >
          <Grid gap={16}>
            <Heading is="h1">GENERAL CONSENT FOR CARE AND TREATMENT</Heading>
            <P>
              I hereby give my authorization and consent to receive Behavioral Health services at Treatment Center. I acknowledge that the
              goal of treatment will be stated in my treatment plan and I will participate per program guidelines.
            </P>
            <P>This authorization and consent is given with my understanding of the following:</P>

            <Ul>
              <Li>
                I have the right to participate in decisions regarding my health care, including the right to refuse treatment, and to
                express preferences about future treatment decisions;
              </Li>
              <Li>
                I have the right to information about my diagnosis and the proposed treatment, including the intended outcome, nature and
                all available procedures involved in the proposed treatment;
              </Li>
              <Li>
                I have the right to be informed of risks, including any side effects, of the proposed treatment, as well as the risks of not
                proceeding;
              </Li>
              <Li>I understand that any consent given may be withheld or withdrawn in writing or orally at any time.</Li>
            </Ul>

            <Heading is="h3">
              I have read and understand this authorization and consent to treat form. This consent is voluntary and may be withheld or
              withdrawn at any time.
            </Heading>
          </Grid>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'healthcare_directives',
            name: 'Health Care Directives',
          }}
        >
          <Grid gap={16}>
            <Heading is="h1">HEALTH CARE DIRECTIVES</Heading>
            <P>Client has a right to the following in regard to Health Care Directives:</P>

            <Ol>
              <Li>A statement of one’s intent in creating an advance directive for behavioral health care decision-making</Li>
              <Li>
                The designation of another person to make decisions for an individual if he or she is determined to be legally incompetent
                to make choices. Generally, this designation also includes provisions for who should be appointed as guardian if a court
                decides to name one.
              </Li>
              <Li>
                Specific instructions about preferences for hospitalization and alternatives to hospitalization, medications,
                electroconvulsive therapy, and emergency interventions, including seclusion, restraint, medication, and participation in
                experimental studies or drug trials
              </Li>
              <Li>
                Instructions about who should be notified immediately if and when the person is admitted to a psychiatric facility.
                Instructions should also include who should be prohibited from visiting and who should have temporary custody of minor
                children or pets.
              </Li>
              <Li>Personal rights to suspend or terminate an advance directive while incapacitated, if allowed by the law in the state</Li>
              <Li>A signature page with two witnesses and a notary who sign the advance directive</Li>
            </Ol>

            <Heading is="h3">I hereby acknowledge that I have received, read, and understand the Health Care Directives notice.</Heading>
          </Grid>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'financial_policy',
            name: 'Financial Policy',
          }}
        >
          <Grid gap={16}>
            <Heading is="h1">FINANCIAL POLICY AND AGREEMENT</Heading>
            <P>
              We are pleased that you have chosen our facility as your treatment provider. Involvement in treatment/counseling services is a
              partnership between you and our organization. Upon your agreement to pay for care, we agree to provide you that care. The
              information below is intended to briefly explain our billing and payment policies.
            </P>

            <Heading is="h3">Billing for Our Services:</Heading>
            <Ul>
              <Li>Payment of Deductibles/Co-insurance and other non-covered services are expected at the time service is rendered.</Li>
              <Li>Clients without insurance are expected to pay for services at time of admission, or as agreed to prior to admission.</Li>
              <Li>You will receive a monthly statement indicating the status of your account and your current balance due.</Li>
              <Li>Monthly payment arrangements may be made at time of admission.</Li>
              <Li>We accept: Cash, Check, Money Order.</Li>
              <Li>If a check is returned for insufficient funds, your account will be charged for the fees we are charged by our bank.</Li>
            </Ul>

            <Heading is="h3">Insurance:</Heading>
            <Ul>
              <Li>Your insurance policy is a contract between you and your insurance company.</Li>
              <Li>Regardless of the type of insurance coverage, clients are ultimately responsible for payment of their medical bills.</Li>
              <Li>
                Although we will bill on your behalf and make all reasonable efforts to obtain payment from your insurance, if they reject
                the claim, or delay payment, we will look to you for payment in full.
              </Li>
              <Li>Submission of claims does not guarantee payment by the insurance company.</Li>
              <Li>
                Health insurance does not relieve the client of financial responsibility for services rendered. There is always a portion
                that is a client’s responsibility.
              </Li>
            </Ul>

            <Heading is="h3">Client Responsibility:</Heading>
            <Ul>
              <Li>The client agrees to provide accurate contact information (phone numbers and addresses.)</Li>
              <Li>The client agrees to provide a next of kin that can be reached if client cannot after being discharged.</Li>
              <Li>The client agrees to provide true and accurate insurance information to the best of their ability.</Li>
              <Li>
                The client agrees to sign over any checks from Blue Cross or any other insurer that may send the checks directly to the
                client.
              </Li>
            </Ul>

            <Heading is="h3">
              I have read this Financial Policy and Agreement and affirm, to the best of my knowledge and belief, that the
              insurance/financial information provided is correct and that I understand this policy. If any information changes, I realize
              it is my responsibility to notify Treatment Center of these changes. I agree to all conditions set forth in this agreement.
            </Heading>
          </Grid>
        </Section>

        <Divider />

        <Section
          scrollview={{
            id: 'client_confirmation',
            name: 'Client Confirmation',
          }}
        >
          <Grid gap={16}>
            <Heading is="h1">Client Confirmation of Reviewed Material</Heading>
            <CheckboxGroup
              trueIcon="check"
              falseIcon="cross"
              falseStyle="faded"
              description="Client will check each box that has been reviewed and agreed to:"
              layout="vertical-dense"
              largeLabel
            >
              <Checkbox label="Client Rights" model="data.client_confirmation.client_rights" />
              <Checkbox label="HIPAA and PHI Disclosure" model="data.client_confirmation.hipaa_disclosure" />
              <Checkbox label="Naloxone (Narcan) Consent And Liability Release" model="data.client_confirmation.naloxone_consent" />
              <Checkbox label="General Consent For Care And Treatment" model="data.client_confirmation.consent_for_care" />
              <Checkbox label="Health Care Directives" model="data.client_confirmation.healthcare_directives" />
              <Checkbox label="Financial Policy and Agreement" model="data.client_confirmation.financial_policy" />
            </CheckboxGroup>
          </Grid>
        </Section>
      </>
    )
  }
}

IntakeForm.defaultProps = {
  title: 'Intake Form',
  category: 'intake_form',
  subcategory: 'intake_form',
  signoffWithoutSupervisor: true,
  requireSupervisor: false,
  requestClientSignature: true,
  requestAuthorSignature: false,
  requestSupervisorSignature: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(IntakeForm))
