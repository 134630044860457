import React from 'react'
import { transparentize, tint } from 'polished'

import { COLORS } from '../theme'

import Card from './Card'
import TreeItem from './TreeItem'

type Props = {
  children: React.ReactNode
  indentPadding?: any
  isOpen?: boolean
  paddingY?: any
  shouldIndent?: boolean
  shouldUnmount?: boolean
  showDivider?: boolean
  title: string
  subtitle?: any
  className?: any
  after?: any
}

const CardTreeItem: React.FC<Props> = ({ paddingY = 0, className, ...rest }) => {
  return (
    <Card css={styles} style={{ '--padding-y': paddingY }} className={className}>
      <TreeItem {...rest} />
    </Card>
  )
}

const styles = {
  '.children': {
    paddingLeft: 0,
    // background: transparentize(0.04, tint(0.5, COLORS.lightBackground)),
    borderTop: `1px solid ${COLORS.divider}`,
    paddingTop: 'var(--padding-y)',
    paddingBottom: 'var(--padding-y)',
  },
}

export default CardTreeItem
