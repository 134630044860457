import React from 'react'

import SmartStatus from '../../components/SmartStatus'

const statuses = {
  sent: {
    label: 'sent',
    color: 'blue',
  },
  cancelled: {
    label: 'cancelled',
    color: 'red',
  },
}

export const PartnershipInviteFromStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}
