import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useHistory } from 'react-router-dom'
import * as SwitchUI from '@radix-ui/react-switch'
import size from 'lodash/size'

import { COLORS, SHADOW, HARD_SHADOW } from '../../theme'
import { countWord } from '../../utils/functions'

import { PopoverMenu } from '../PopoverMenu'
import Alert from '../Alert'
import Button from '../Button'
import Dialog from '../Dialog'
import Glyph from '../Glyph'

import { clearTableSettings } from './localStorage'
import { useStore } from './useStore'

export const DataTableColumns = () => {
  const history = useHistory()

  const columnIds: any = useStore((state: any) => state.columnIds)
  const columnsMap: any = useStore((state: any) => state.columnsMap)
  const filtersConfig: any = useStore((state: any) => state.filtersConfig)
  const hiddenColumnIds: any = useStore((state: any) => state.hiddenColumnIds)
  const localStorageKey: any = useStore((state: any) => state.localStorageKey)
  const reorderColumns: any = useStore((state: any) => state.reorderColumns)
  const toggleHideColumn: any = useStore((state: any) => state.toggleHideColumn)

  const [didReset, setDidReset] = React.useState(false)

  const columnsCount = size(columnIds)

  const handleReset = () => {
    setDidReset(true)
    clearTableSettings(localStorageKey)
    history.go(0)
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return // dragged outside the list

    reorderColumns(result.source.index, result.destination.index)
  }

  if (!columnIds) return null

  return (
    <PopoverMenu
      testKey="columns_menu"
      menuCss={STYLES.menuWrapper}
      trigger={
        <div>
          <Button testKey="data_table_columns_button" label="Columns" glyph="settings" size={100} type="minimal" />
        </div>
      }
    >
      <header css={STYLES.menuHeader}>
        <Glyph glyph="settings" color={COLORS.blue} size={16} css={STYLES.menuHeaderGlyph} />
        <div css={STYLES.menuHeaderTitle}>{countWord('Columns', columnsCount)}</div>
      </header>

      <main css={STYLES.menuContent}>
        <div className="!p-2">
          <Alert small glyph="info">
            Click on column names to show/hide or drag to re-order them.
          </Alert>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="table_columns_reorder">
            {(droppableProvided: any) => (
              <div ref={droppableProvided.innerRef}>
                {columnIds.map((columnId: any, index: number) => {
                  const column = columnsMap[columnId]
                  const config = filtersConfig?.[columnId]
                  const isHidden = hiddenColumnIds?.includes?.(columnId)
                  const disableHide = column?.disableHide

                  if (!column) return null

                  return (
                    <Draggable key={columnId} draggableId={columnId} index={index}>
                      {(draggableProvided: any, draggableSnapshot: any) => {
                        return (
                          <div
                            data-test="draggable_item"
                            key={columnId}
                            ref={draggableProvided.innerRef}
                            css={STYLES.dragItem}
                            className={draggableSnapshot.isDragging ? 'is-dragging' : ''}
                            style={draggableProvided.draggableProps.style}
                            onClick={() => {
                              if (disableHide) return

                              toggleHideColumn(columnId)
                            }}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <Glyph glyph="drag_and_drop" size={16} css={STYLES.moveGlyph} />
                            {/* <Glyph glyph={config?.glyph || 'column'} size={18} color={COLORS.blue} /> */}
                            <span css={STYLES.label}>{column.title}</span>

                            {!disableHide && (
                              <div data-test="switch">
                                <SwitchUI.Root css={STYLES.switch} checked={!isHidden}>
                                  <SwitchUI.Thumb css={STYLES.thumb} />
                                </SwitchUI.Root>
                              </div>
                            )}
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </main>

      {localStorageKey && (
        <footer css={STYLES.menuFooter}>
          <Dialog
            title="Reset Table Settings"
            glyph="reset"
            message={
              <>
                Are you sure you want to reset all sorting, filtering and columns? <b>This action will refresh the page.</b>
              </>
            }
            onYes={handleReset}
            portal="radix"
            yesLabel="Reset Table & Refresh"
            yesColor="red"
          >
            <Button
              testKey="reset_table_settings_button"
              label="Reset Table Settings"
              glyph="reset"
              type="minimal"
              color="red"
              size={100}
              display="inline-flex"
            />
          </Dialog>
        </footer>
      )}
    </PopoverMenu>
  )
}

const STYLES = {
  menuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'hidden !important',
    minHeight: 280,
    maxWidth: '80vw',

    '@media(min-width: 600px)': {
      maxWidth: 400,
    },
  },

  menuHeader: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    overflow: 'hidden',
    padding: '0.4rem 0.75rem',
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(3),
    fontWeight: 600,
  },

  menuHeaderGlyph: {
    marginRight: '0.25rem',
  },

  menuHeaderTitle: {
    marginRight: '0.25rem',
  },

  menuContent: {
    '--filter-height': '26px',
    flex: '1 1 auto',
    overflowY: 'auto',
    // padding: '0.25rem 0.75rem',
  },

  menuFooter: {
    flex: '0 0 auto',
    overflow: 'hidden',
    padding: '0.4rem 0.75rem',
    borderTop: `1px solid ${COLORS.divider}`,
  },

  dragItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.3rem 0.75rem',
    borderBottom: `1px solid ${COLORS.divider}`,
    cursor: 'pointer !important',
    outline: 'none',

    '&:hover': {
      background: COLORS.hover,
    },

    '&:last-of-type': {
      borderBottom: 'none',
    },

    '&.is-dragging': {
      borderRadius: 4,
      background: COLORS.white,
      boxShadow: SHADOW(3, COLORS.divider),
      top: 'auto !important',
      left: 'auto !important',
    },
  },

  label: {
    display: 'inline-block',
    marginLeft: '0.4em',
    fontWeight: 500,
    outline: 'none',
    flex: '1 1 auto',
    fontSize: '0.85rem',
  },

  moveGlyph: {
    opacity: 0.7,
    marginLeft: '-0.1rem',
    marginRight: '0.5rem',
    cursor: 'move !important',

    '&:hover': {
      cursor: 'move !important',
    },

    '&.hide': {
      opacity: 0,
      pointerEvents: 'none',
    },
  },

  section: {
    padding: '0.55rem 0.75rem',
    borderTop: `1px solid ${COLORS.divider}`,
  },

  switch: {
    all: 'unset',
    backgroundColor: COLORS.lightGray,
    borderRadius: '9999px',
    position: 'relative',
    boxShadow: SHADOW(1),
    cursor: 'pointer',
    '&[data-state="checked"]': { backgroundColor: COLORS.green },
    width: 30,
    height: 18,
  },

  thumb: {
    display: 'block',
    backgroundColor: 'white',
    borderRadius: '9999px',
    boxShadow: SHADOW(1),
    transition: 'transform 100ms',
    transform: 'translateX(2px)',
    willChange: 'transform',
    width: 14,
    height: 14,
    '&[data-state="checked"]': { transform: 'translateX(14px)' },
  },
}
