import React from 'react'
import { NavLink, Navigate, Route, Routes, useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { PartnershipInviteFromStatus } from '@behavehealth/constructs/Partnerships/PartnershipInviteFromStatus'
import { PartnershipInviteToStatus } from '@behavehealth/constructs/Partnerships/PartnershipInviteToStatus'
import { titleCase } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

import Tabs from '@behavehealth/components/Tabs'
import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

const STATUS_FILTER = {
  pending: {
    status_from: 'sent',
    status_to: 'pending',
  },
  cancelled: {
    status_from: 'cancelled',
  },
  accepted: {
    status_from: 'sent',
    status_to: 'accepted',
  },
  declined: {
    status_from: 'sent',
    status_to: 'declined',
  },
}

export const CommunityInvitesReceived = () => {
  return (
    <>
      <Tabs>
        <Tabs.List className="px-0 -mt-3 mb-4">
          <Tabs.Item as={NavLink} label="Pending" to="pending" />
          <Tabs.Item as={NavLink} label="Accepted" to="accepted" />
          <Tabs.Item as={NavLink} label="Declined" to="declined" />
        </Tabs.List>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to="pending" replace />} />

        <Route path=":status" element={<CommunityInvitesReceivedDataTable />} />
      </Routes>
    </>
  )
}

export const CommunityInvitesReceivedDataTable = () => {
  const { status }: any = useParams()
  const statusFilter = STATUS_FILTER[status]

  const tableProps = useDataTable({
    name: ['partnership-invites'],
    endpoint: '/partnership_invites',
    params: {
      direction: 'received',
      ...statusFilter,
    },
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'sent_from.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} id={data.id} value={value} to={`${data?.id}`} />,
      },
      {
        title: 'Notifications',
        model: 'sent_to_emails',
        formatValue: ({ value }: any) => {
          const count = size(value)

          if (count === 0) return '–'

          return value.join(', ')
        },
      },
      {
        title: 'Sent Status',
        model: 'status_from',
        width: 200,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <PartnershipInviteFromStatus status={value} />
        },
      },
      {
        title: 'Reply Status',
        model: 'status_to',
        width: 200,
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <PartnershipInviteToStatus status={value} />
        },
      },
      {
        title: 'Subject',
        model: 'subject',
        width: 260,
      },
      {
        title: 'Message',
        model: 'message',
        width: 260,
      },
      {
        title: 'Date Invited',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Invited By',
        model: 'sent_by',
        type: 'profile',
      },
    ],
    [],
  )

  return (
    <>
      <DataTable
        {...tableProps}
        asCard
        title={`${titleCase(status)} Community Invites`}
        icon="community_invites"
        columns={columns}
        filtersConfig={FILTERS}
      />
    </>
  )
}

const FILTERS = {
  // 'sent_from.name': {},
  // 'contacts': {},
  status_to: {
    type: 'multi_select',
    label: 'Reply Status',
    options: [
      { label: 'Pending', value: 'pending' },
      { label: 'Accepted', value: 'accepted' },
      { label: 'Declined', value: 'declined' },
      { label: 'Empty', value: null },
    ],
  },
  title: {
    label: 'Subject',
    type: 'string',
  },
  message: {
    label: 'Message',
    type: 'string',
  },
  created_at: {
    ...DEFAULT_FILTERS.created_at,
    label: 'Date Invited',
  },
  sent_by: {
    ...DEFAULT_FILTERS.author,
    label: 'Sent by',
  },
}
