import React from 'react'
import { v4 as uuid } from 'uuid'

import Field from './Field'
import { withFormContext } from './context'

export class PillBoxGroup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      id: `pillbox-group-${uuid()}`,
      inputs: {},
      isPristine: true,
      isDirty: false,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
    }

    this.mounted = true
  }

  /*
    LIFECYCLE
  */
  componentWillUnmount = () => (this.mounted = false)

  /*
    RENDER
  */
  render() {
    const { children, ...other } = this.props
    const { id, type, isValid, isRequired, errors, inputs } = this.state

    return (
      <Field {...other} id={id} errors={errors} isValid={isValid} isRequired={isRequired} value={inputs} valueType={type}>
        {this.mounted && children}
      </Field>
    )
  }
}

PillBoxGroup.defaultProps = {
  layout: 'horizontal',
  isEditable: true,
}

export default withFormContext(PillBoxGroup)
