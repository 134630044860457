import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'

import { DataFormTooltip } from '../components/DataFormTooltip'
import { DischargeInfo } from '../../StaffJourney/records/DischargeInfo'

export const Discharge = ({ data, client }: any) => {
  if (!data) return null

  return (
    <DataFormTooltip
      title={data.name}
      icon="discharge_forms"
      color={tint(0.7, COLORS.orange)}
      startDate={data.dated_at}
      recordLink={`/discharges/${data.id}`}
      status={data.status}
      client={client}
    >
      <DischargeInfo data={data} />
    </DataFormTooltip>
  )
}
