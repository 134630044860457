import React, { Component, Fragment } from 'react'

import { Button, Card, Heading, Page, P, Section } from '@behavehealth/components'

class TrialEnded extends Component {
  render() {
    return (
      <Page centerContent>
        <Card className="!max-w-[512px]">
          <Section>
            <Heading is="h3" style={{ marginTop: 0 }}>
              Hello [firstName],
            </Heading>

            {/* For users WITH billing permissions */}
            {true && (
              <Fragment>
                <P>
                  The latest invoice for [currentFacilityName] is overdue. Please submit a payment as soon as possible to continue using
                  your Behave Health App.
                </P>
                <P>
                  For any questions and inquiries, please don't hesitate to reach us at{' '}
                  <a href="mailto:contact@behavehealth.com">contact@behavehealth.com</a>.
                </P>
                <P>
                  Best Regards,
                  <br />
                  The Behave Health Team
                </P>
                <Button
                  label="Contact Behave Health Billing"
                  type="primary"
                  color="green"
                  href="mailto:contact@behavehealth.com"
                  fullWidth
                />
              </Fragment>
            )}

            {/* For users WITHOUT billing permissions */}
            {false && (
              <Fragment>
                <P>
                  The latest invoice for [currentFacilityName] is overdue. Access to the Behave Health App is currently restricted until the
                  company's administrator resolves the outstanding payment.
                </P>
                <P>
                  For any questions and inquiries, please don't hesitate to reach us at{' '}
                  <a href="mailto:contact@behavehealth.com">contact@behavehealth.com</a>.
                </P>
                <P>
                  Best Regards,
                  <br />
                  The Behave Health Team
                </P>
              </Fragment>
            )}
          </Section>
        </Card>
      </Page>
    )
  }
}

export default TrialEnded
