import React from 'react'

import { DataFormOverlay } from '../DataFormOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'

import Score from './common/Score'

const SCORING = {
  4: 'No or Minimal depression',
  9: 'Mild depression',
  14: 'Moderate depression',
  19: 'Moderately severe depression',
  27: 'Severe depression',
}

const INITIAL_STATE = {
  q1: 0,
  q2: 0,
  q3: 0,
  q4: 0,
  q5: 0,
  q7: 0,
  q6: 0,
  q8: 0,
  q9: 0,
}

const QUESTIONS = [
  `Feeling down, depressed, irritable, or hopeless?`,
  `Little interest or pleasure in doing things?`,
  `Trouble falling asleep, staying asleep, or sleeping too much?`,
  `Poor appetite, weight loss, or overeating?`,
  `Feeling tired, or having little energy?`,
  `Trouble concentrating on things like school work, reading, or watching TV?`,
  `Feeling bad about yourself – or feeling that you are a failure, or that you have let yourself or your family down?`,
  `Moving or speaking so slowly that other people could have noticed? Or the opposite – being so fidgety or restless that you were moving around a lot more than usual?`,
  `Thoughts that you would be better off dead, or of hurting yourself in some way?`,
]

const RootPHQ9TeensOverlay = (props: any) => {
  const { isPortal } = useSettings()

  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  return (
    <DataFormOverlay
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="PHQ-9 Modified for Teens"
      category="clinical_measurement"
      subcategory="phq9_teens"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Grid gap="1.5rem">
          <Alert contrast glyph="info" className="mt-2 -mb-2">
            <span className="font-[600]">Instructions:</span> How often have you been bothered by each of the following symptoms during the
            past two weeks? For each symptom select the answer that best describes how you have been feeling.
          </Alert>

          {QUESTIONS.map((question, index) => (
            <RadioGroup
              key={`q-${index}`}
              label={question}
              model={`data.questionnaire.q${index + 1}`}
              layout="vertical-dense"
              onUpdate={(state: any) => setScore((o) => ({ ...o, [`q${index + 1}`]: state.value }))}
            >
              <Radio label="0 – Not At Al" value={0} />
              <Radio label="1 – Several Days" value={1} />
              <Radio label="2 – More Than Half the Days" value={2} />
              <Radio label="3 – Nearly Every Day" value={3} />
            </RadioGroup>
          ))}

          <Divider />

          <YesNoRadioGroup
            label="In the past year have you felt depressed or sad most days, even if you felt okay sometimes?"
            model="data.questionnaire_extra.q10"
          />

          <RadioGroup
            label="If you are experiencing any of the problems on this form, how difficult have these problems made it for you to do your work, take care of things at home or get along with other people?"
            model="data.questionnaire_extra.q11"
            layout="vertical-dense"
          >
            <Radio label="Not difficult at all " value={0} />
            <Radio label="Somewhat difficult " value={1} />
            <Radio label="Very difficult " value={2} />
            <Radio label="Extremely difficult" value={3} />
          </RadioGroup>

          <YesNoRadioGroup
            label="Has there been a time in the past month when you have had serious thoughts about ending your life?"
            model="data.questionnaire_extra.q12"
          />

          <YesNoRadioGroup
            label="Have you EVER, in your WHOLE LIFE, tried to kill yourself or made a suicide attempt?"
            model="data.questionnaire_extra.q13"
          />
        </Grid>
      </Section>

      {!isPortal && (
        <>
          <Divider />

          <Section
            title="Scoring the PHQ-9 modified for Teens"
            headingType="h2"
            scrollview={{
              id: 'scoring',
              name: 'Scoring',
            }}
          >
            <Grid gap="1rem">
              <Input type="number" label="Severity score (office use only)" model="data.severity_score" size={5} />

              <div>Scoring the PHQ-9 modified for teens is easy but involves thinking about several different aspects of depression.</div>

              <div>
                <div>To use the PHQ-9 as a diagnostic aid for Major Depressive Disorder:</div>

                <ul>
                  <li>Questions 1 and/or 2 need to be endorsed as a “2” or “3”</li>
                  <li>
                    Need five or more positive symptoms (positive is defined by a “2” or “3” in questions 1-8 and by a “1”, “2”, or “3” in
                    question 9).
                  </li>
                  <li>The functional impairment question (How difficult…) needs to be rated at least as “somewhat difficult.”</li>
                </ul>
              </div>

              <div>
                <div>To use the PHQ-9 to screen for all types of depression or other mental illness:</div>
                <ul>
                  <li>
                    All positive answers (positive is defined by a “2” or “3” in questions 1-8 and by a “1”, “2”, or “3” in question 9)
                    should be followed up by interview.
                  </li>
                  <li>
                    A total PHQ-9 score {'>'} 10 (see below for instructions on how to obtain a total score) has a good sensitivity and
                    specificity for MDD.
                  </li>
                </ul>
              </div>

              <div>
                <div>To use the PHQ-9 to aid in the diagnosis of dysthymia:</div>
                <ul>
                  <li>The dysthymia question (In the past year...) should be endorsed as “yes.”</li>
                </ul>
              </div>

              <div>
                <div>To use the PHQ-9 to screen for suicide risk:</div>
                <ul>
                  <li>
                    All positive answers to question 9 as well as the two additional suicide items MUST be followed up by a clinical
                    interview.
                  </li>
                </ul>
              </div>

              <div>
                <div>To use the PHQ-9 to obtain a total score and assess depressive severity:</div>
                <ul>
                  <li>Add up the numbers endorsed for questions 1-9 and obtain a total score.</li>
                  <li>See Table below:</li>
                </ul>
              </div>

              <Score title="Depression Severity Score" finalScore={finalScore} scoring={SCORING} />
            </Grid>
          </Section>
        </>
      )}
    </DataFormOverlay>
  )
}

export const PHQ9TeensOverlay = withOverlayError(RootPHQ9TeensOverlay)
