import React from 'react'
import { Marker } from 'react-map-gl'

import Glyph from './Glyph'
import CardLinkPhoto from './CardLinkPhoto'
import CardTitle from './CardTitle'
import CardSubtitle from './CardSubtitle'
import Tooltip from './Tooltip'
import { GLYPHS } from '../theme'

const REDIRECT_BASE_URL = {
  house: 'properties',
  organization: 'organizations',
}

const MapMarker = ({ lat, lon, record }) => {
  if (!lat && !lon) return null

  let content = <Glyph glyph={GLYPHS.map_pin} size={44} />

  if (record?.id) {
    content = (
      <Tooltip
        position="top"
        customContent={
          <CardLinkPhoto to={`/${REDIRECT_BASE_URL[record.type]}/${record.id}`} src={record.avatar}>
            <CardTitle title={record.name} />
            <CardSubtitle subtitle={`${record.address?.address_line_1}, ${record.address?.state}`} />
          </CardLinkPhoto>
        }
      >
        <Glyph glyph={GLYPHS.map_pin} size={44} />
      </Tooltip>
    )
  }

  return (
    <Marker longitude={lon} latitude={lat} offsetTop={-20} offsetLeft={-10} draggable={false}>
      {content}
    </Marker>
  )
}

export default MapMarker
