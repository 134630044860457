import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useParams, useLocation } from 'react-router-dom-v5-compat'

import HelpTagIframe from '@behavehealth/components/Help/HelpTagIframe'
import Nav from '@behavehealth/components/Nav'
import NavGroup from '@behavehealth/components/NavGroup'
import NavItem from '@behavehealth/components/NavItem'
import PageGrid from '@behavehealth/components/PageGrid'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'

import { AccountingCustomerOverlay } from '@behavehealth/constructs/Accounting/AccountingCustomerOverlay'
import { AccountingInvoiceCreditNoteOverlay } from '@behavehealth/constructs/Accounting/AccountingInvoiceCreditNoteOverlay'
import { AccountingInvoiceLineOverlay } from '@behavehealth/constructs/Accounting/AccountingInvoiceLineOverlay'
import { AccountingInvoicePaymentOverlay } from '@behavehealth/constructs/Accounting/AccountingInvoicePaymentOverlay'
import { AccountingRefundOverlay } from '@behavehealth/constructs/Accounting/AccountingRefundOverlay'
import { AccountingVendorOverlay } from '@behavehealth/constructs/Accounting/AccountingVendorOverlay'

import { Accounts } from './pages/accounts'
import { ApAgingReports } from './pages/ap_aging_reports'
import { ArAgingReports } from './pages/ar_aging_reports'
import { BankAccounts } from './pages/bank_accounts'
import { BankTransactions } from './pages/bank_transactions'
import { BillCreditNotes } from './pages/bill_credit_notes'
import { BillLines } from './pages/bill_lines'
import { BillPayments } from './pages/bill_payments'
import { Bills } from './pages/bills'
import { Customers } from './pages/customers'
import { Estimates } from './pages/estimates'
import { Inventory } from './pages/inventory'
import { InvoiceCreditNotes } from './pages/invoice_credit_notes'
import { InvoiceLines } from './pages/invoice_lines'
import { InvoicePayments } from './pages/invoice_payments'
import { Invoices } from './pages/invoices'
import { Refunds } from './pages/refunds'
import { TrackingCategories } from './pages/tracking_categories'
import { Vendors } from './pages/vendors'

export const Accounting = () => {
  return (
    <>
      <PageGrid breakpoint={3} columns="280px 1fr">
        <Nav breakpoint={3} headingSize={300} title="Accounting" icon="accounting" help={<HelpTagIframe id="accounting" />}>
          <NavGroup label="Customers & leads">
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Customers" to="customers" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Accounts" to="accounts" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Vendors" to="vendors" />
          </NavGroup>

          <NavGroup label="Invoices">
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Invoices" to="invoices" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Invoice Lines" to="invoice-lines" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Invoice Payments" to="invoice-payments" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Invoice Credit Notes" to="invoice-credit-notes" />
          </NavGroup>

          <NavGroup label="Bills">
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Bills" to="bills" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Bill Lines" to="bill-lines" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Bill Payments" to="bill-payments" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Bill Credit Notes" to="bill-credit-notes" />
          </NavGroup>

          <NavGroup label="Aging Reports">
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Accounts Payable" to="ap-aging-reports" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Accounts Receivable" to="ar-aging-reports" />
          </NavGroup>

          <NavGroup label="Banking">
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Bank Accounts" to="bank-accounts" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Bank Transactions" to="bank-transactions" />
          </NavGroup>

          <NavGroup label="Other">
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Estimates" to="estimates" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Inventory" to="inventory" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Refunds" to="refunds" />
            <NavItem as={NavLink} featureFlagV2="accounting" feature="accounting" label="Tracking Categories" to="tracking-categories" />
          </NavGroup>
        </Nav>

        <Routes>
          <Route index element={<Navigate to="customers" replace />} />

          <Route path="customers/*" element={<Customers />} />
          <Route path="accounts/*" element={<Accounts />} />
          <Route path="estimates/*" element={<Estimates />} />
          <Route path="inventory/*" element={<Inventory />} />

          <Route path="invoices/*" element={<Invoices />} />
          <Route path="invoice-lines/*" element={<InvoiceLines />} />
          <Route path="invoice-payments/*" element={<InvoicePayments />} />
          <Route path="invoice-credit-notes/*" element={<InvoiceCreditNotes />} />

          <Route path="bills/*" element={<Bills />} />
          <Route path="bill-lines/*" element={<BillLines />} />
          <Route path="bill-payments/*" element={<BillPayments />} />
          <Route path="bill-credit-notes/*" element={<BillCreditNotes />} />

          <Route path="refunds/*" element={<Refunds />} />
          <Route path="vendors/*" element={<Vendors />} />
          <Route path="tracking-categories/*" element={<TrackingCategories />} />

          <Route path="ap-aging-reports/*" element={<ApAgingReports />} />
          <Route path="ar-aging-reports/*" element={<ArAgingReports />} />

          <Route path="bank-accounts/*" element={<BankAccounts />} />
          <Route path="bank-transactions/*" element={<BankTransactions />} />
        </Routes>
      </PageGrid>

      <AnimatedRoutes>
        <Route
          path="customers/:id"
          element={
            <ProtectedRouteV6 featureFlag="accounting">
              <AccountingCustomerOverlay useV6Router />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="vendors/:id"
          element={
            <ProtectedRouteV6 featureFlag="accounting">
              <AccountingVendorOverlay useV6Router />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="invoice-payments/:id"
          element={
            <ProtectedRouteV6 featureFlag="accounting">
              <AccountingInvoicePaymentOverlay useV6Router />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="refunds/:id"
          element={
            <ProtectedRouteV6 featureFlag="accounting">
              <AccountingRefundOverlay useV6Router />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="invoice-lines/:id"
          element={
            <ProtectedRouteV6 featureFlag="accounting">
              <AccountingInvoiceLineOverlay useV6Router />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="invoice-credit-notes/:id"
          element={
            <ProtectedRouteV6 featureFlag="accounting">
              <AccountingInvoiceCreditNoteOverlay useV6Router />
            </ProtectedRouteV6>
          }
        />
      </AnimatedRoutes>
    </>
  )
}
