import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  insurance: {
    label: 'Insurance',
    color: 'blue',
  },
  client: {
    label: 'Client',
    color: 'green',
  },
  write_off: {
    label: 'Write Off',
    color: 'grey',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
