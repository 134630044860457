import React from 'react'
import size from 'lodash/size'
import { v4 as uuid } from 'uuid'

import { useGet } from '../../hooks/useNewAPI'

import Card from '../../components/Card'
import GoalObjectiveInterventionStatus from '../../components/Statuses/GoalObjectiveInterventionStatus'
import GridTable from '../../components/GridTable'
import RadioCheckElement from '../../components/Forms/RadioCheckElement'
import State from '../../components/State'

import { DateCell, ToggleCell, NavLinkCell, LinkCell, ToggleHeaderCell } from '../TreatmentPlans/components/TreeComponents'
import { produce } from 'immer'

const getObjectiveIds = (goal: any) => {
  return goal.treatment_book_objectives?.map?.((objective: any) => objective._id)
}

const getInterventionIdsForObjective = (objective: any) => {
  return objective.treatment_book_interventions?.map?.((intervention: any) => intervention._id)
}

const getInterventionIdsForGoal = (goal: any) => {
  const result = []

  for (const objective of goal.treatment_book_objectives) {
    result.push(...getInterventionIdsForObjective(objective))
  }

  return result
}

export const TreatmentPlanBookCascadeImporter = (props: any) => {
  const { category } = props

  const { data, isLoading } = useGet({
    name: ['treatment_book', { category }],
    url: `/treatment_book`,
    params: { category },
  })

  const processedData = React.useMemo(() => {
    if (!data) return []

    return data.map((goal: any) => {
      return {
        ...goal,
        _id: uuid(),
        treatment_book_objectives: goal.treatment_book_objectives.map((objective: any) => {
          return {
            ...objective,
            _id: uuid(),
            treatment_book_interventions: objective.treatment_book_interventions.map((intervention: any) => {
              return {
                ...intervention,
                _id: uuid(),
              }
            }),
          }
        }),
      }
    })
  }, [data])

  const isEmpty = size(processedData) === 0

  const [openGoalIds, setOpenGoalIds] = React.useState<any>([])
  const [checkedGoalIds, setCheckedGoalIds] = React.useState<any>([])

  const [openObjectiveIds, setOpenObjectiveIds] = React.useState<any>([])
  const [checkedObjectiveIds, setCheckedObjectiveIds] = React.useState<any>([])
  const [checkedInterventionIds, setCheckedInterventionIds] = React.useState<any>([])

  const { goalIds, objectiveIds, interventionIds } = React.useMemo(() => {
    const result: any = { goalIds: [], objectiveIds: [], interventionIds: [] }

    if (!processedData) return result

    for (const goal of processedData) {
      result.goalIds.push(goal._id)

      if (!goal.treatment_book_objectives) continue

      for (const objective of goal.treatment_book_objectives) {
        result.objectiveIds.push(objective._id)

        if (!objective.treatment_book_interventions) continue

        for (const intervention of objective.treatment_book_interventions) {
          result.interventionIds.push(intervention._id)
        }
      }
    }

    return result
  }, [processedData])

  const isAllGoalsOpen = size(openGoalIds) === size(goalIds)

  const isAllChecked =
    size(checkedGoalIds) === size(goalIds) &&
    size(checkedObjectiveIds) === size(objectiveIds) &&
    size(checkedInterventionIds) === size(interventionIds)

  const isSomeChecked = size(checkedGoalIds) > 0 || size(checkedObjectiveIds) > 0 || size(checkedInterventionIds) > 0

  const toggleAllOpen = () => {
    setOpenGoalIds(goalIds)
    setOpenObjectiveIds(objectiveIds)
  }

  const toggleAllClosed = () => {
    setOpenGoalIds([])
    setOpenObjectiveIds([])
  }

  const toggleAllChecked = () => {
    toggleAllOpen()
    setCheckedGoalIds(goalIds)
    setCheckedObjectiveIds(objectiveIds)
    setCheckedInterventionIds(interventionIds)
  }

  const toggleAllUnchecked = () => {
    setCheckedGoalIds([])
    setCheckedObjectiveIds([])
    setCheckedInterventionIds([])
  }

  const openGoal = (goalId: any) => {
    setOpenGoalIds((c: any) => {
      if (!c.includes(goalId)) {
        return [...c, goalId]
      } else {
        return c
      }
    })
  }

  const toggleGoalOpen = (goalId: any) => {
    setOpenGoalIds((c: any) => {
      if (c.includes(goalId)) {
        return c.filter((id: any) => id !== goalId)
      } else {
        return [...c, goalId]
      }
    })
  }

  const toggleGoalChecked = (goal: any) => {
    const goalId = goal._id
    const objectiveIds = getObjectiveIds(goal)
    const interventionIds = getInterventionIdsForGoal(goal)

    const isChecked = checkedGoalIds.includes(goalId)

    if (isChecked) {
      // Uncheck goal with all objectives and interventions
      setCheckedGoalIds(checkedGoalIds.filter((id: any) => id !== goalId))
      setCheckedObjectiveIds(checkedObjectiveIds.filter((id: any) => !objectiveIds.includes(id)))
      setCheckedInterventionIds(checkedInterventionIds.filter((id: any) => !interventionIds.includes(id)))

      // close goal
      setOpenGoalIds((c) => {
        return produce(c, (draft: any) => {
          return draft.filter((id: any) => id !== goalId)
        })
      })

      // close all interventions
      setOpenObjectiveIds((c) => {
        return produce(c, (draft: any) => {
          for (const objectiveId of objectiveIds) {
            return draft.filter((id: any) => id !== objectiveId)
          }
        })
      })
    } else {
      // Check goal with all objectives and interventions
      setCheckedGoalIds([...checkedGoalIds, goalId])
      setCheckedObjectiveIds([...checkedObjectiveIds, ...objectiveIds])
      setCheckedInterventionIds([...checkedInterventionIds, ...interventionIds])

      // open goal
      setOpenGoalIds((c) => {
        return produce(c, (draft: any) => {
          if (!draft.includes(goalId)) {
            draft.push(goalId)
          }
        })
      })

      // open all interventions
      setOpenObjectiveIds((c) => {
        return produce(c, (draft: any) => {
          for (const objectiveId of objectiveIds) {
            if (!draft.includes(objectiveId)) {
              draft.push(objectiveId)
            }
          }
        })
      })
    }
  }

  const openObjective = (objectiveId: any) => {
    setOpenObjectiveIds((c: any) => {
      if (!c.includes(objectiveId)) {
        return [...c, objectiveId]
      } else {
        return c
      }
    })
  }

  const toggleObjectiveOpen = (objectiveId: any) => {
    setOpenObjectiveIds((c: any) => {
      if (c.includes(objectiveId)) {
        return c.filter((id: any) => id !== objectiveId)
      } else {
        return [...c, objectiveId]
      }
    })
  }

  const toggleObjectiveChecked = (objective: any) => {
    const objectiveId = objective._id
    const interventionIds = getInterventionIdsForObjective(objective)

    const isChecked = checkedObjectiveIds.includes(objectiveId)

    if (isChecked) {
      // Uncheck objective with all interventions
      setCheckedObjectiveIds(checkedObjectiveIds.filter((id: any) => id !== objectiveId))
      setCheckedInterventionIds(checkedInterventionIds.filter((id: any) => !interventionIds.includes(id)))
    } else {
      // Check objective with all interventions
      setCheckedObjectiveIds([...checkedObjectiveIds, objectiveId])
      setCheckedInterventionIds([...checkedInterventionIds, ...interventionIds])
    }
  }

  const toggleInterventionChecked = (interventionId: any) => {
    setCheckedInterventionIds((c: any) => {
      if (c.includes(interventionId)) {
        return c.filter((id: any) => id !== interventionId)
      } else {
        return [...c, interventionId]
      }
    })
  }

  return (
    <Card>
      {(isLoading || isEmpty) && (
        <State
          title="Goals, Objectives & Interventions"
          icon="treatment_plans"
          emptyDescription="No goals, objectives or interventions added to this treatment plan yet"
          isLoading={isLoading}
          isEmpty={isEmpty}
          minHeight={200}
        />
      )}

      {!isEmpty && (
        <GridTable useBanding={false} templateColumns={`35px 110px minmax(280px, 5fr) `}>
          <GridTable.Header>
            <GridTable.Cell
              onClick={() => {
                if (isAllChecked) {
                  toggleAllUnchecked()
                } else {
                  toggleAllChecked()
                }
              }}
            >
              <RadioCheckElement type="checkbox" isChecked={isAllChecked} isIndeterminate={isSomeChecked && !isAllChecked} />
            </GridTable.Cell>

            <ToggleHeaderCell
              isAllOpen={isAllGoalsOpen}
              onClick={() => {
                if (isAllGoalsOpen) {
                  toggleAllClosed()
                } else {
                  toggleAllOpen()
                }
              }}
            />

            <GridTable.Cell>Name</GridTable.Cell>
          </GridTable.Header>

          {processedData?.map?.((goal: any, goalIndex: number) => {
            const isOpen = openGoalIds.includes(goal._id)
            const isChecked = checkedGoalIds.includes(goal._id)
            const hasChildren = size(goal.treatment_book_objectives) > 0

            return (
              <>
                <GridTable.Row>
                  <GridTable.Cell className="cursor-pointer" onClick={() => toggleGoalChecked(goal)}>
                    <RadioCheckElement type="checkbox" isChecked={isChecked} />
                  </GridTable.Cell>

                  <ToggleCell
                    isOpen={isOpen}
                    onClick={() => toggleGoalOpen(goal._id)}
                    label={`Goal ${goalIndex + 1}`}
                    hasChildren={hasChildren}
                  />

                  <GridTable.Cell>{goal.name || goal.description}</GridTable.Cell>
                </GridTable.Row>

                {isOpen &&
                  goal.treatment_book_objectives?.map?.((objective: any, objectiveIndex: number) => {
                    const isOpen = openObjectiveIds.includes(objective._id)
                    const isChecked = checkedObjectiveIds.includes(objective._id)
                    const hasChildren = size(objective.treatment_book_interventions) > 0

                    return (
                      <>
                        <GridTable.Row>
                          <GridTable.Cell className="cursor-pointer" onClick={() => toggleObjectiveChecked(objective)}>
                            <RadioCheckElement type="checkbox" isChecked={isChecked} />
                          </GridTable.Cell>

                          <ToggleCell
                            isOpen={isOpen}
                            onClick={() => toggleObjectiveOpen(objective._id)}
                            label={`Obj ${goalIndex + 1}.${objectiveIndex + 1}`}
                            hasChildren={hasChildren}
                            className="!pl-6"
                          />

                          <GridTable.Cell>{objective.name || objective.description}</GridTable.Cell>
                        </GridTable.Row>

                        {isOpen &&
                          objective.treatment_book_interventions?.map?.((intervention: any, interventionIndex: number) => {
                            const isChecked = checkedInterventionIds.includes(intervention._id)

                            return (
                              <>
                                <GridTable.Row>
                                  <GridTable.Cell className="cursor-pointer" onClick={() => toggleInterventionChecked(intervention._id)}>
                                    <RadioCheckElement type="checkbox" isChecked={isChecked} />
                                  </GridTable.Cell>

                                  <ToggleCell
                                    isOpen={isOpen}
                                    label={`Int ${goalIndex + 1}.${objectiveIndex + 1}.${interventionIndex + 1}`}
                                    hasChildren={false}
                                    className="!pl-10"
                                  />

                                  <GridTable.Cell>{intervention.name || intervention.description}</GridTable.Cell>
                                </GridTable.Row>
                              </>
                            )
                          })}
                      </>
                    )
                  })}
              </>
            )
          })}
        </GridTable>
      )}
    </Card>
  )
}
