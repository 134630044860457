import React from 'react'
import { darken, lighten } from 'polished'
import { useMedia } from 'use-media'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { NavGroupLabel } from '../../components/NavGroup'
import { usDateTime, countWord } from '../../utils/functions'
import { useCreate, useGet, useUpdate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { DropdownMenu } from '../../components/DropdownMenu'
import Accordions from '../../components/Accordions'
import AddressInput from '../../components/Forms/AddressInput'
import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import Badge from '../../components/Badge'
import Button from '../../components/Button'
import Card from '../../components/Card'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import EmailInput from '../../components/Forms/EmailInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import GridTable from '../../components/GridTable'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import Link from '../../components/Link'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import NavItem from '../../components/NavItem'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import PhoneInput from '../../components/Forms/PhoneInput'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SmartStatus from '../../components/SmartStatus'
import State from '../../components/State'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'
import Tooltip from '../../components/Tooltip'
import TreeItem from '../../components/TreeItem'

import { FORM_ELEMENTS } from '../FormBuilder/elements/elements'
import { FormRenderer } from '../FormBuilder/components/FormRenderer'

import { FormSubmissionAddendum } from './FormSubmissionAddendum'
import { FormSubmissionAddendumOverlay } from './FormSubmissionAddendumOverlay'
import { FormSubmissionAssignmentSelector } from './FormSubmissionAssignmentSelector'
import { FormSubmissionFileUnderSelector } from './FormSubmissionFileUnderSelector'
import { FormSubmissionSettings } from './FormSubmissionSettings'
import { FormSubmissionShareOverlay } from './FormSubmissionShareOverlay'
import { FormSubmissionShareStatus } from './FormSubmissionShareStatus'
import { FormSubmissionSignatureOverlay } from './FormSubmissionSignatureOverlay'
import { FormSubmissionStatusSelector } from './FormSubmissionStatusSelector'
import { FormSubmissionUpdatesDataTable } from './FormSubmissionUpdatesDataTable'

import { processSignatureSettings, processSigneesToSign } from './functions'

import { NewFormBuilderProvider, useFormBuilder } from '../FormBuilder/useFormBuilder'
import { AnchorDatePicker } from '../FormBuilder/components/AnchorDatePicker'
import { TimeVariablesTable } from '../FormBuilder/components/TimeVariablesTable'
import { SubmissionFormVariablesTable } from './SubmissionFormVariablesTable'
import { SystemVariablesTable } from '../FormBuilder/components/SystemVariablesTable'

const FormSubmissionOverlayWithContext = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    highlightRequired,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    params,
  } = useOverlay({
    name: 'form_submissions',
    endpoint: '/form_submissions',
    invalidate: 'form_submissions',
    options: props,
    closeOnSave: false,
  })

  const { form_id, id }: any = params
  const isNewForm = !!(isNew && form_id)

  const { data: formData, isLoading: isLoadingForm }: any = useGet({
    name: ['form', form_id],
    url: `/forms/${form_id}`,
    options: { enabled: isNewForm },
  })

  const apiData = React.useMemo(() => {
    if (isNewForm) return formData

    return {
      ...data?.schema,
      form_variables: data?.form_variables,
      global_ai_inputs: data?.global_ai_inputs,
      ai_inputs: data?.ai_inputs,
    }
  }, [isNewForm, formData, data])

  if (isOverlayLoading || !apiData) return <OverlayLoader showBackdrop={isEditable} maxWidth={120} position="right" />

  return (
    <NewFormBuilderProvider apiData={apiData} isEditable={false} isNew={false} environment="submission">
      <InnerFormSubmissionOverlay
        cancel={cancel}
        close={close}
        data={data}
        deleteRecord={deleteRecord}
        edit={edit}
        form={form}
        formData={formData}
        highlightRequired={highlightRequired}
        initialModel={initialModel}
        isDeleting={isDeleting}
        isEditable={isEditable}
        isInvalid={isInvalid}
        isLoadingForm={isLoadingForm}
        isNew={isNew}
        isOverlayLoading={isOverlayLoading}
        isSaving={isSaving}
        onValidationUpdate={onValidationUpdate}
        saveWithData={saveWithData}
        params={params}
      />
    </NewFormBuilderProvider>
  )
}

const InnerFormSubmissionOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    formData,
    highlightRequired,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoadingForm,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    params,
  } = props

  const { form_id, id }: any = params

  const activePageId: any = useFormBuilder((state: any) => state.activePageId)
  const setActivePageId: any = useFormBuilder((state: any) => state.setActivePageId)
  const getFormattedApiData: any = useFormBuilder((state: any) => state.getFormattedApiData)
  const parseAllElementConfigVariables: any = useFormBuilder((state: any) => state.parseAllElementConfigVariables)

  const isDesktop = useMedia({ minWidth: 900 })
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const isNewForm = !!(isNew && form_id)
  const isSignedOff = data?.status === 'signed_off'
  const isClosedOff = data?.status === 'closed'

  const [status, setStatus] = React.useState(isNewForm ? 'draft' : data?.status)
  const [closeOffDialogOpen, setCloseOffDialogOpen] = React.useState(false)
  const [signoffDialogOpen, setSignoffDialogOpen] = React.useState(false)

  const { record: user, isBehave, isManagement, timezone } = useSettings()

  const { mutateAsync: updateForm }: any = useUpdate({
    name: ['form_submissions', id],
    url: `/form_submissions/${id}`,
    invalidate: 'form_submissions',
    invalidateKeys: ['form_submissions'],
  })

  const { mutateAsync: closeSubmission }: any = useCreate({
    name: ['form_submissions', id],
    url: `/form_submissions/${id}/close`,
    invalidate: 'form_submissions',
    invalidateKeys: ['form_submissions'],
  })

  const { addendums, signees } = React.useMemo(() => {
    return {
      addendums: data?.addendums || null,
      signees: data?.form_submission_signees || null,
    }
  }, [data])

  const isSupervisor = React.useMemo(() => {
    if (!data?.supervisors) return false

    for (const supervisor of data.supervisors) {
      if (supervisor.id === user.id && supervisor.type === user.type) return true
    }

    return false
  }, [data, user])

  const mustApprove =
    data?.use_review_and_signoff_workflow &&
    data?.review_and_signoff_workflow_settings?.enable_supervisor_review &&
    data?.review_and_signoff_workflow_settings?.signoff_settings?.supervisor_must_approve

  const isApproved = data?.status === 'approved'

  const addendumsCount = size(addendums)

  const [supervisors, setSupervisors] = React.useState<any>(null)
  const [staff, setStaff] = React.useState<any>(null)
  const [submissionSettings, setSubmissionSettings] = React.useState<any>({})

  const signeesToSign = React.useMemo(() => {
    if (!submissionSettings || !signees) return []

    return processSigneesToSign({ data: submissionSettings, signees })
  }, [submissionSettings, signees])

  const signeesCount = size(signees)
  const signeesToSignCount = size(signeesToSign)
  const hasSigneesToSign = signeesToSignCount > 0

  const allowed = React.useMemo(() => {
    const res = {
      pdf_export: false,
      addendums: isClosedOff || isSignedOff,
      signatures: false,
      signoff: false,
      supervisor_review: false,
      supervisor_must_approve: false,
    }

    if (!submissionSettings) return res

    const { use_pdf_export, pdf_export_settings, use_review_and_signoff_workflow, review_and_signoff_workflow_settings } =
      submissionSettings

    const signoffSettings = review_and_signoff_workflow_settings?.signoff_settings || {}
    const signoffUpdateSettings = review_and_signoff_workflow_settings?.updates_after_signoff

    if (use_pdf_export) {
      res['pdf_export'] = !!pdf_export_settings?.form_submission_pdf_export
    }

    if (use_review_and_signoff_workflow) {
      res['signoff'] = !!review_and_signoff_workflow_settings?.enable_signoff

      res['supervisor_review'] = !!review_and_signoff_workflow_settings?.enable_supervisor_review

      res['supervisor_must_approve'] = !!review_and_signoff_workflow_settings?.enable_supervisor_review

      res['signatures'] = !!(
        signoffSettings?.reference_must_sign ||
        signoffSettings?.staff_must_sign ||
        signoffSettings?.supervisors_must_sign ||
        signoffUpdateSettings?.reference_must_sign ||
        signoffUpdateSettings?.staff_must_sign ||
        signoffUpdateSettings?.supervisors_must_sign
      )
    }

    return res
  }, [submissionSettings, isClosedOff, isSignedOff])

  const handleSave = async () => {
    const storeData = await getFormattedApiData()

    saveWithData({
      ...submissionSettings,
      ...form.current?.getFormValue?.(),
      ...(!isNew && {
        form_variables_attributes: storeData?.form_variables_attributes || undefined,
        variables_time_anchor: storeData?.variables_time_anchor || undefined,
      }),
      ...(isNewForm && {
        form_id,
        ...(initialModel?.reference && {
          reference_id: initialModel.reference.id,
          reference_type: initialModel.reference.type,
        }),
      }),
    })
  }

  const signOff = async () => {
    await updateForm({ status: 'signed_off' })
  }

  const closeOff = async () => {
    await closeSubmission()
  }

  const elementAddendums = React.useMemo(() => {
    const result: any = {}

    if (!addendums) return result

    for (const addendum of addendums) {
      if (!addendum.connected_input) continue

      if (!result[addendum.connected_input]) result[addendum.connected_input] = []

      result[addendum.connected_input].push(addendum)
    }

    return result
  }, [addendums])

  const showOverlayLoader = isNewForm ? isLoadingForm || !formData : isOverlayLoading || !data

  const [internalPageId, setInternalPageId] = React.useState(null)

  const internalPage = !activePageId && INTERNAL_PAGES.find((page) => page.id === internalPageId)
  const InternalPageComponent = internalPage?.component

  const canSignOff = !isEditable && !hasSigneesToSign && (!mustApprove || (mustApprove && isApproved))
  const canCloseOff = !isEditable

  const isFinalStatus = data?.status === 'closed' || data?.status === 'signed_off'

  let cannotSignOffReasons = React.useMemo(() => {
    const result: any = []

    if (canSignOff) return []

    if (isEditable) result.push('Save the Form Submission first to sign it off')

    if (hasSigneesToSign)
      result.push(
        <>
          All signees must add their signature before the form can be signed-off (
          <b>{signeesToSign?.map((o) => o.reference?.name)?.join(', ')}</b>)
        </>,
      )

    if (mustApprove && !isApproved) result.push('A supervisor must approve this Form Submission before it can be signed-off')

    return result
  }, [canSignOff, isEditable, hasSigneesToSign, mustApprove, isApproved, signeesToSign])

  let cannotCloseOffReasons = React.useMemo(() => {
    const result: any = []

    if (canCloseOff) return []

    if (isEditable) result.push('Save the Form Submission first to close it off')

    return result
  }, [canCloseOff, isEditable])

  React.useEffect(() => {
    if (activePageId) setInternalPageId(null)

    setIsMenuOpen(false)
  }, [activePageId])

  React.useEffect(() => {
    if (internalPageId) setActivePageId(null)

    setIsMenuOpen(false)
  }, [internalPageId])

  // sync API status with local one
  React.useEffect(() => {
    if (!!data?.status) setStatus(data.status)
  }, [data?.status])

  React.useEffect(() => {
    parseAllElementConfigVariables()
  }, [])

  React.useEffect(() => {
    // handle new form submission
    if (isNewForm && formData) {
      // add permission doesn't apply to form submissions; replace with view/edit if it's set
      const portal_permissions_settings =
        formData?.portal_permissions_settings === 'can_view_edit_add' ? 'can_view_edit' : formData?.portal_permissions_settings

      const {
        pdf_export_settings,
        public_sharing_settings,
        reference_settings,
        review_and_signoff_workflow_settings,
        use_pdf_export,
        use_portal_permissions,
        use_reference,
        use_review_and_signoff_workflow,
        use_notifications,
        notification_settings,
      } = formData

      setSubmissionSettings({
        pdf_export_settings,
        portal_permissions_settings,
        public_sharing_settings,
        reference_settings,
        review_and_signoff_workflow_settings,
        use_pdf_export,
        use_portal_permissions,
        use_public_sharing: false, // don't apply public sharing by default
        use_reference,
        use_review_and_signoff_workflow,
        use_notifications,
        notification_settings,
      })
    }
    // handle existing form submission
    else if (!isNewForm && data) {
      if (data?.staff) setStaff(data.staff)
      if (data?.supervisors) setSupervisors(data.supervisors)

      // add permission doesn't apply to form submissions; replace with view/edit if it's set
      const portal_permissions_settings =
        data?.portal_permissions_settings === 'can_view_edit_add' ? 'can_view_edit' : data?.portal_permissions_settings

      const {
        pdf_export_settings,
        public_sharing_settings,
        reference_settings,
        review_and_signoff_workflow_settings,
        use_pdf_export,
        use_portal_permissions,
        use_public_sharing,
        use_reference,
        use_review_and_signoff_workflow,
        use_notifications,
        notification_settings,
      } = data

      setSubmissionSettings({
        pdf_export_settings,
        portal_permissions_settings,
        public_sharing_settings,
        reference_settings,
        review_and_signoff_workflow_settings,
        use_pdf_export,
        use_portal_permissions,
        use_public_sharing,
        use_reference,
        use_review_and_signoff_workflow,
        use_notifications,
        notification_settings,
      })
    }
  }, [isNewForm, data, formData])

  if (showOverlayLoader) return <OverlayLoader maxWidth={90} position="right" />

  const formSidebar = (
    <FormSidebar
      data={data}
      isNew={isNewForm}
      allowed={allowed}
      addendumsCount={addendumsCount}
      signees={signees}
      signeesCount={signeesCount}
      signeesToSign={signeesToSign}
      signeesToSignCount={signeesToSignCount}
      internalPageId={internalPageId}
      onInternalPageChange={setInternalPageId}
      isFinalStatus={isFinalStatus}
    />
  )

  const submissionActions = (
    <>
      {allowed.supervisor_review && !isSupervisor && !isSignedOff && (
        <Tooltip show={isEditable} color={COLORS.orange} content="Save the form submission first to send it for review">
          <SignatureDialog
            model="author"
            title="Sign & Send for Review"
            yesLabel="Apply Signature & Send for Review"
            onYes={async () => {
              await updateForm({ status: 'pending' })
            }}
            isDisabled={isEditable || isSaving}
            onDisabledClick={highlightRequired}
          >
            <Button
              fullWidth
              label="Sign & Send for Supervisor Review…"
              glyph="signature"
              type="default"
              isDisabled={isEditable || isSaving}
              show={isInvalid}
              message="Click to highlight required fields"
              size={isDesktop ? 200 : 300}
            />
          </SignatureDialog>
        </Tooltip>
      )}

      {!allowed.signoff && !isClosedOff && (
        <Tooltip
          show={!canCloseOff}
          color={COLORS.orange}
          content={
            size(cannotCloseOffReasons) > 0 && (
              <div className="grid gap-2">
                <div className="font-[600]">Close-Off Requirements:</div>

                {cannotCloseOffReasons.map((message) => (
                  <div key={message} className="flex flex-nowrap items-center">
                    <Glyph glyph="info" color={darken(0.05, COLORS.orange)} size={16} className="mr-1.5" />
                    {message}
                  </div>
                ))}
              </div>
            )
          }
        >
          <Button
            display="inline-flex"
            label="Close Form Submission…"
            glyph="close"
            color="green"
            type="primary"
            size={isDesktop ? 200 : 300}
            isDisabled={!canCloseOff}
            onClick={() => {
              setCloseOffDialogOpen(true)
            }}
          />
        </Tooltip>
      )}

      {allowed.signoff && !isSignedOff && (
        <Tooltip
          show={!canSignOff}
          color={COLORS.orange}
          className={isDesktop ? '' : 'order-first'}
          content={
            size(cannotSignOffReasons) > 0 && (
              <div className="grid gap-2">
                <div className="font-[600]">Sign-Off Requirements:</div>

                {cannotSignOffReasons.map((message) => (
                  <div key={message} className="flex flex-nowrap items-center">
                    <Glyph glyph="info" color={darken(0.05, COLORS.orange)} size={16} className="mr-1.5" />
                    {message}
                  </div>
                ))}
              </div>
            )
          }
        >
          <Button
            display="inline-flex"
            label="Sign Off…"
            glyph="signature"
            type="primary"
            color="green"
            isDisabled={isEditable || isSaving || hasSigneesToSign || (mustApprove && !isApproved)}
            flex="100 1 auto"
            size={isDesktop ? 200 : 300}
            onClick={() => {
              setSignoffDialogOpen(true)
            }}
            className={isDesktop ? '' : 'order-first'}
          />
        </Tooltip>
      )}
    </>
  )

  return (
    <>
      <Overlay
        fullheight
        closeOnEscape={false}
        showBackdrop={isEditable}
        closeOnBackdrop={!isEditable}
        maxWidth={120}
        position="right"
        onClose={close}
        css={STYLES.root}
      >
        <Overlay.Header
          icon="web_form"
          title="Form Submission"
          titleAside={
            !isNew && (
              <Flex centerY className="ml-2">
                <FormSubmissionShareStatus status={data?.use_public_sharing ? 'public' : 'private'} />

                {!isFinalStatus && (
                  <SummonOverlay overlay={<FormSubmissionShareOverlay dataID={data?.id} />}>
                    <Button label={data?.use_public_sharing ? 'Share Link' : 'Get Share Link'} glyph="share" type="link" size={200} />
                  </SummonOverlay>
                )}

                {allowed.pdf_export && (
                  <Permission featureFlagV2="form_submissions_pdf_export">
                    <div className="h-[16px] w-[1px] bg-divider mx-2" />

                    <div className="!mr-4">
                      <ExportPDFButton
                        url={`/form_submissions/${id}/pdf`}
                        label={
                          <div className="flex items-center">
                            Download PDF <Status small label="Beta" color="blue" className="ml-1.5" />
                          </div>
                        }
                      />
                    </div>
                  </Permission>
                )}
              </Flex>
            )
          }
        />

        <Overlay.SubHeader className="relative z-10 !px-3 !py-1.5 border-b border-0 border-solid border-divider shadow-hard-3">
          <Flex centerY gap="0.5rem" justifyContent="space-between">
            <div>
              <Flex centerY gap="1rem">
                {!isDesktop && (
                  <DropdownMenu
                    isOpen={isMenuOpen}
                    onOpenChange={setIsMenuOpen}
                    trigger={
                      <div>
                        <Button display="inline-flex" size={200} glyph="menu" label="Menu" type="primary" />
                      </div>
                    }
                  >
                    {formSidebar}
                  </DropdownMenu>
                )}

                <div className="flex flex-nowrap items-center">
                  <Glyph glyph="selector" size={16} color={COLORS.gray} className="mr-1" />

                  <FormSubmissionStatusSelector
                    isFinalStatus={isFinalStatus}
                    allowed={allowed}
                    canCloseOff={canCloseOff}
                    cannotCloseOffReasons={cannotCloseOffReasons}
                    canSignOff={canSignOff}
                    cannotSignOffReasons={cannotSignOffReasons}
                    isSupervisor={isSupervisor}
                    submissionData={data}
                    value={status}
                    signeesToSign={signeesToSign}
                    onUpdate={async (newStatus: any) => {
                      if (newStatus === 'signed_off' && canSignOff) {
                        setSignoffDialogOpen(true)
                        return
                      }

                      if (newStatus === 'closed' && canCloseOff) {
                        setCloseOffDialogOpen(true)
                        return
                      }

                      if (isNewForm) {
                        setStatus(newStatus)
                        form.current?.setFieldValue('status', newStatus)
                      } else {
                        setStatus(newStatus)
                        await updateForm({ status: newStatus })
                      }
                    }}
                  />

                  <Tooltip
                    glyphSize={14}
                    glyphColor={COLORS.paleBlue}
                    className="ml-1"
                    content={
                      <div className="grid gap-3 pb-3">
                        <div>
                          Choose the submission's status. This will determine the next steps and actions available for this submission
                        </div>

                        <div>
                          <NavGroupLabel label="Initial Status" className="mb-1" />
                          <div>
                            <b>Draft:</b> The submission is still in progress and not yet ready for review
                          </div>
                        </div>

                        <Divider className="!my-0" />

                        <div className="grid gap-1">
                          <NavGroupLabel label="Review Statuses" />

                          <div className="mb-1 italic text-text-muted">
                            Only available when the "Supervisor Review" workflow is enabled under Settings
                          </div>

                          <div>
                            <b>Pending Review:</b> The submission is ready for review by the supervisor
                          </div>

                          <div>
                            <b>Updates Required:</b> The submission requires updates before it can be approved
                          </div>

                          <div>
                            <b>In Progress:</b> The submission is currently being worked on
                          </div>

                          <div>
                            <b>Approved:</b> The submission has been approved and can be closed or signed-off
                          </div>

                          <div>
                            <b>Rejected:</b> The submission has been rejected and cannot be closed or signed-off
                          </div>
                        </div>

                        <Divider className="!my-0" />

                        <div className="grid gap-1">
                          <NavGroupLabel label="Final Statuses" className="mb-1" />
                          <div>
                            <div>
                              <b>Signed-Off:</b> The submission has been signed-off and is now closed{' '}
                            </div>

                            <div className="italic text-text-muted">
                              (available when the "Sign-Off Workflow" <b>is enabled</b> for this submission)
                            </div>
                          </div>

                          <div>
                            <div>
                              <b>Closed:</b> The submission has been closed and is no longer editable{' '}
                            </div>

                            <div className="italic text-text-muted">
                              (available when the "Sign-Off Workflow" <b>is not</b> enabled for this submission)
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                </div>

                {submissionSettings.use_reference && (
                  <>
                    {/* <div className="text-text-strongly-muted">•</div> */}

                    <div className="flex flex-nowrap items-center">
                      <Glyph glyph="folder_open" size={16} color={COLORS.gray} className="mr-1" />

                      <FormSubmissionFileUnderSelector
                        isFinalStatus={isFinalStatus}
                        value={initialModel?.reference || data?.reference}
                        referenceSettings={submissionSettings.reference_settings}
                        onUpdate={async (record: any) => {
                          if (isNewForm) {
                            form.current?.setFieldValue('reference_id', record.id)
                            form.current?.setFieldValue('reference_type', record.type)
                          } else {
                            // ⏰ TODO: refactor
                            const isAssignmentSameAsReference =
                              !!data?.assignee?.id &&
                              data?.reference?.id === data?.assignee?.id &&
                              data?.reference?.type === data?.assignee?.type

                            const canAssign = record?.type === 'employee' || record?.type === 'resident'

                            await updateForm({
                              reference_id: record.id,
                              reference_type: record.type,
                              ...(isAssignmentSameAsReference &&
                                canAssign && {
                                  assignee_id: record.id,
                                  assignee_type: record.type,
                                }),
                            })

                            if (isAssignmentSameAsReference && canAssign) {
                              form.current?.setFieldValue('assignee_id', record.id)
                              form.current?.setFieldValue('assignee_type', record.type)
                            }
                          }
                        }}
                      />

                      <Tooltip
                        glyphSize={14}
                        glyphColor={COLORS.paleBlue}
                        className="ml-1"
                        content="Specify where this submission is categorized within the facility. This will help with organization and retrieval of submissions"
                      />
                    </div>
                  </>
                )}

                <div className="flex flex-nowrap items-center">
                  <Glyph glyph="user_neutral" size={16} color={COLORS.gray} className="mr-1" />

                  <FormSubmissionAssignmentSelector
                    key={`reference-${data?.reference?.id}`}
                    filedUnderClient={data?.reference?.type === 'resident' && data?.reference}
                    isFinalStatus={isFinalStatus}
                    value={data?.assignee || initialModel?.assignee}
                    onUpdate={async (record: any) => {
                      if (isNewForm) {
                        form.current?.setFieldValue('assignee_id', record.id)
                        form.current?.setFieldValue('assignee_type', record.type)
                      } else {
                        await updateForm({
                          assignee_id: record.id,
                          assignee_type: record.type,
                        })
                      }
                    }}
                  />
                  <Tooltip
                    glyphSize={14}
                    glyphColor={COLORS.paleBlue}
                    className="ml-1"
                    content="Select who is responsible for next steps on this submission to ensure progress and accountability"
                  />
                </div>
              </Flex>
            </div>

            {isDesktop && (
              <div>
                <Flex centerY gap="1rem">
                  {submissionActions}
                </Flex>
              </div>
            )}
          </Flex>
        </Overlay.SubHeader>

        {(isSignedOff || isClosedOff) && (
          <Overlay.SubHeader className="relative z-10 !px-0 !py-0 border-b border-0 border-solid border-divider shadow-hard-3">
            <Alert small glyph="info" type="warning" className="!rounded-none">
              This Form Submission is now {isSignedOff ? 'signed-off' : isClosedOff ? 'closed' : ''}. Only Owners and Administrators are
              allowed to make further changes. You can also use the Addendums page to create any addendums.
            </Alert>
          </Overlay.SubHeader>
        )}

        <Overlay.Content css={STYLES.overlayContent}>
          <Form
            key={`updated-${data?.updated_at}`}
            getForm={form}
            isEditable={isEditable}
            initialModel={initialModel}
            deregisterOnUnmount={false}
            onValidationUpdate={onValidationUpdate}
            css={STYLES.grid}
            timezone={timezone}
          >
            <main css={STYLES.main} className={isDesktop ? 'is-desktop' : 'is-mobile'}>
              {isDesktop && formSidebar}

              <div className="overflow-y-auto">
                {!internalPage && activePageId && (
                  <div className="flex justify-center px-4 py-8">
                    <FormRenderer
                      renderElementWrapper={(elementNode, element) => {
                        return (
                          <ElementWrapper
                            data={data}
                            isEditable={isEditable}
                            element={element}
                            addendums={elementAddendums?.[element?.uuid]}
                          >
                            {elementNode}
                          </ElementWrapper>
                        )
                      }}
                    />
                  </div>
                )}

                {InternalPageComponent && internalPage && (
                  <InternalPageComponent
                    isNew={isNew}
                    data={data}
                    isFinalStatus={isFinalStatus}
                    formData={isNewForm ? formData : data?.form}
                    isEditable={isEditable}
                    initialModel={initialModel}
                    addendums={addendums}
                    allowed={allowed}
                    submissionSettings={submissionSettings}
                    setSubmissionSettings={setSubmissionSettings}
                    supervisors={supervisors}
                    setSupervisors={setSupervisors}
                    staff={staff}
                    setStaff={setStaff}
                    signees={signees}
                    externalId={data?.external_id}
                  />
                )}
              </div>
            </main>
          </Form>
        </Overlay.Content>

        <Overlay.Footer className="!px-4">
          {!isDesktop && (
            <div className="!mb-4 w-full">
              <Flex centerY stretchChildrenX gap="0.75rem">
                {submissionActions}
              </Flex>
            </div>
          )}

          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                onClick={handleSave}
                isLoading={isSaving}
                isDisabled={isSaving}
                flex="100 1 auto"
              />

              {isInvalid && (
                <Button
                  label="Highlight Required Fields"
                  glyph="view"
                  type="default"
                  color="orange"
                  onClick={highlightRequired}
                  isDisabled={isSaving}
                />
              )}

              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              {isClosedOff || isSignedOff ? (
                <>
                  {isBehave || isManagement ? (
                    <>
                      <ConfirmDialog
                        title="Edit Form Submission?"
                        message={`This Form Submission is currently ${
                          isSignedOff ? 'signed-off' : isClosedOff ? 'closed' : ''
                        }. Are you sure you want to edit it?`}
                        yesColor="blue"
                        yesLabel="Edit Form Submission"
                        onYes={edit}
                      >
                        <Button label="Edit Form Submission" glyph="edit" permission="form_submissions.edit" flex="3 1 auto" />
                      </ConfirmDialog>

                      <Permission permission="form_submissions.delete">
                        <DeleteDialog
                          title="Delete Form Submission?"
                          message="Are you sure you want to delete this form submission? This action cannot be undone."
                          onYes={deleteRecord}
                        >
                          <Button label="Delete" glyph="delete" color="red" isDisabled={isDeleting} permission="form_submissions.delete" />
                        </DeleteDialog>
                      </Permission>
                    </>
                  ) : (
                    <Tooltip
                      content={`This Form Submission is now ${
                        isSignedOff ? 'signed-off' : isClosedOff ? 'closed' : ''
                      }. Only Owners and Administrators are allowed to make further changes. You can also use the Addendums page to create any addendums.`}
                    >
                      <Flex stretchChildrenX gap="0.75rem" flex="1 1 auto">
                        <Button label="Edit Form Submission" glyph="edit" isDisabled permission="form_submissions.edit" flex="3 1 auto" />
                        <Button label="Delete" glyph="delete" color="red" isDisabled permission="form_submissions.delete" />
                      </Flex>
                    </Tooltip>
                  )}
                </>
              ) : (
                <Flex stretchChildrenX gap="0.75rem">
                  <Button label="Edit Form Submission" glyph="edit" onClick={edit} permission="form_submissions.edit" flex="3 1 auto" />

                  <Permission permission="form_submissions.delete">
                    <DeleteDialog
                      title="Delete Form Submission?"
                      message="Are you sure you want to delete this form submission? This action cannot be undone."
                      onYes={deleteRecord}
                    >
                      <Button label="Delete" glyph="delete" color="red" isDisabled={isDeleting} permission="form_submissions.delete" />
                    </DeleteDialog>
                  </Permission>
                </Flex>
              )}
            </>
          )}
        </Overlay.Footer>
      </Overlay>

      <SignatureDialog
        setOpen={signoffDialogOpen}
        model="supervisor"
        title="Sign Off"
        yesLabel="Apply Signature & Sign Off"
        onYes={signOff}
        isDisabled={isEditable || isSaving || hasSigneesToSign || (mustApprove && !isApproved)}
        onClose={() => {
          setSignoffDialogOpen(false)
        }}
        before={
          <div className="grid gap-4 mb-4 text-text">
            <Alert contrast glyph="warning" type="warning" className="text-text">
              <b>Please note,</b> upon signing-off this Form Submission, the following will occur:
            </Alert>

            <Card className="px-4 py-3 grid gap-3 leading-normal">
              <div className="flex items-center flex-nowrap">
                <Glyph glyph="lock" size={18} color={COLORS.text} className="flex-0 mr-3" />
                <div>Only owners and administrators will be able to make any further edits</div>
              </div>

              <div className="flex items-center flex-nowrap">
                <Glyph glyph="add_file" size={18} color={COLORS.green} className="flex-0 mr-3" />
                <div>Addendums page will be enabled to create any addendums</div>
              </div>

              <div className="flex items-center flex-nowrap">
                <Glyph glyph="share" size={18} color={COLORS.blue} className="flex-0 mr-3" />
                <div>If a share link has been created, it will be disabled</div>
              </div>
            </Card>
          </div>
        }
      />

      <ConfirmDialog
        setOpen={closeOffDialogOpen}
        title="Close Form Submission?"
        message={
          <div className="grid gap-4 text-text">
            <Alert contrast glyph="warning" type="warning" className="text-text">
              <b>Please note,</b> upon closing this Form Submission, the following will occur:
            </Alert>

            <Card className="px-4 py-3 grid gap-3 leading-normal">
              <div className="flex items-center flex-nowrap">
                <Glyph glyph="lock" size={18} color={COLORS.text} className="flex-0 mr-3" />
                <div>Only owners and administrators will be able to make any further edits</div>
              </div>

              <div className="flex items-center flex-nowrap">
                <Glyph glyph="add_file" size={18} color={COLORS.green} className="flex-0 mr-3" />
                <div>Addendums page will be enabled to create any addendums</div>
              </div>

              <div className="flex items-center flex-nowrap">
                <Glyph glyph="share" size={18} color={COLORS.blue} className="flex-0 mr-3" />
                <div>If a share link has been created, it will be disabled</div>
              </div>
            </Card>
          </div>
        }
        yesColor="green"
        yesLabel="Yes, Close Form Submission"
        onYes={closeOff}
        onClose={() => {
          setCloseOffDialogOpen(false)
        }}
      />
    </>
  )
}

const ElementWrapper = (props: any) => {
  const { children, element, addendums, isEditable, data } = props

  if (!element) return children

  const isFormElement = FORM_ELEMENTS.includes(element.category)
  const isActionsVisible = !isEditable && isFormElement

  const hasAddendums = size(addendums) > 0

  return (
    <div css={isActionsVisible && elementWrapperStyles}>
      {children}

      {isActionsVisible && (
        <div className="element-actions">
          <SummonOverlay overlay={<FormSubmissionAddendumOverlay reference={data} data={{ connected_input: element.uuid }} />}>
            <Button size={100} label="Add Addendum" glyph="add" type="default" display="inline-flex" />
          </SummonOverlay>
        </div>
      )}

      {hasAddendums && (
        <div className="mt-2">
          <TreeItem withHover={false} title={<span className="text-blue-500">{countWord('Addendums', size(addendums))}</span>}>
            <div className="grid gap-3">
              {addendums.map((addendum: any) => (
                <FormSubmissionAddendum data={addendum} />
              ))}
            </div>
          </TreeItem>
        </div>
      )}
    </div>
  )
}

const elementWrapperStyles = {
  position: 'relative',
  borderRadius: 2,

  '.element-actions': {
    opacity: 0,
    visibility: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
  },

  '&:hover': {
    background: lighten(0.1, COLORS.divider),
    boxShadow: `0 0 0 5px ${lighten(0.1, COLORS.divider)}`,

    '& > .element-actions': {
      opacity: 1,
      visibility: 'visible',
    },
  },
}

const InternalPage = (props: any) => {
  const { title, icon, children, headerAside, className } = props

  const rootClasses = clsx('pt-4 pb-8 px-6 w-full max-w-[100%] mx-auto', className)

  return (
    <div className={rootClasses}>
      <header className="flex flex-nowrap items-center mb-4">
        <Icon icon={icon} className="mr-3 shrink-0" size={20} />
        <h1 className="text-[1.6rem] ">{title}</h1>

        {headerAside && <div className="ml-auto">{headerAside}</div>}
      </header>

      {children}
    </div>
  )
}

const GeneralPage = (props: any) => {
  const { data, formData, isNew, setStaff, setSupervisors, staff, supervisors } = props

  return (
    <InternalPage title="General" icon="general_info">
      <div className="grid gap-2 -mt-8">
        <Accordions>
          <Accordions.Item isOpen minimal size={200} title="Internal Details" glyph="info">
            <FormSection className="px-4 pt-1 pb-3">
              <Input
                label="Name"
                model="name"
                defaultValue={data?.name || formData?.name}
                validations={{
                  presence: {
                    message: 'Please enter a form submission name',
                  },
                }}
              />

              <MultiObjectSelector
                isPolymorphic
                label="Supervisors"
                blankLabel="Select Supervisor…"
                icon="employees"
                type="employees"
                model="supervisors"
                value={supervisors}
                onUpdate={(o) => setSupervisors(o.value)}
                selectTitle={(data) => data?.name}
                selectDescription={() => null}
              />

              <MultiObjectSelector
                isPolymorphic
                label="Staff Members"
                blankLabel="Select Staff Members…"
                icon="employees"
                type="employees"
                model="staff"
                value={staff}
                onUpdate={(o) => setStaff(o.value)}
                selectTitle={(data) => data?.name}
                selectDescription={() => null}
              />
            </FormSection>
          </Accordions.Item>

          {!isNew && data?.author && (
            <Accordions.Item isOpen minimal size={200} title="Authorship Details" glyph="user_neutral">
              <FormSection className="px-4 pt-1 pb-3">
                <ObjectSelector
                  label="Created By"
                  icon="employees"
                  type="employees"
                  model="author"
                  selectTitle={(data) => data?.name}
                  selectDescription={() => null}
                  isEditable={false}
                  disableLink
                />

                <DateTimeInput isEditable={false} label="Date Created" model="created_at" />
                <DateTimeInput isEditable={false} label="Date Updated" model="updated_at" />
              </FormSection>
            </Accordions.Item>
          )}

          {!isNew && data?.data?.submitter_name && (
            <Accordions.Item
              isOpen
              minimal
              size={200}
              title="Online Submission Details"
              description="This form was submitted online with the following details"
              glyph="website"
            >
              <FormSection className="px-4 pt-1 pb-3">
                <Input isEditable={false} label="Submitter Title" value={data?.submitter_title || data?.data?.submitter_title} />
                <Input isEditable={false} label="Submitter Name" value={data?.submitter_name || data?.data?.submitter_name} />
                <EmailInput isEditable={false} label="Submitter Email" value={data?.submitter_email || data?.data?.submitter_email} />
                <PhoneInput isEditable={false} label="Submitter Phone" value={data?.submitter_phone_no || data?.data?.submitter_phone_no} />
                <Textarea
                  isEditable={false}
                  label="Organization Name"
                  value={data?.submitter_organization_name || data?.data?.submitter_organization_name}
                />
                <AddressInput
                  isEditable={false}
                  label="Organization Address"
                  model="submitter_organization_address"
                  // value={data?.submitter_organization_address || data?.data?.submitter_organization_address}
                />
                <Textarea isEditable={false} label="Submitter Notes" value={data?.submitter_notes || data?.data?.submitter_notes} />
              </FormSection>
            </Accordions.Item>
          )}

          {!isNew && (
            <Accordions.Item
              isOpen
              minimal
              size={200}
              title={
                <div className="flex items-center">
                  <div>Submission Updates</div>

                  <div className="flex justify-center ml-2">
                    <Status small color="orange" label="Coming Soon" />
                  </div>
                </div>
              }
              glyph="time"
            >
              <FormSubmissionUpdatesDataTable />
            </Accordions.Item>
          )}
        </Accordions>
      </div>
    </InternalPage>
  )
}

const SignaturesPage = (props: any) => {
  const { signees, data = {} } = props

  const { record: user } = useSettings()

  const [currentSignatureType, setCurrentSignatureType]: any = React.useState(null)
  const [currentSignee, setCurrentSignee]: any = React.useState(null)

  const signatureSettings = React.useMemo(() => {
    return processSignatureSettings(data)
  }, [data])

  const anyShouldSignInitially = signatureSettings.supervisor.shouldSignInitially || signatureSettings.staff.shouldSignInitially

  const anyShouldSignUpdates = signatureSettings.supervisor.shouldSignUpdates || signatureSettings.staff.shouldSignUpdates

  const isEmpty = size(signees) === 0
  const isSignedOff = data?.status === 'signed_off'

  const tableConfig = React.useMemo(() => {
    const result: any = [
      {
        title: 'Role',
        width: '100px',
        render: (signee: any) => (
          <GridTable.Cell centerY>
            <SmartStatus small statuses={signatureSettings} status={signee.category} />
          </GridTable.Cell>
        ),
      },
      {
        title: 'Name',
        width: 'minmax(180px, 3fr)',
        render: (signee: any) => <ProfileCell avatar={signee.reference?.avatar} name={signee.reference?.name} />,
      },
    ]

    if (anyShouldSignInitially) {
      result.push({
        title: 'Initial Signature',
        width: 'minmax(300px, 2fr)',
        render: (signee: any) => {
          const shouldSign = signatureSettings[signee.category]?.shouldSignInitially
          const isSelf = signee.reference && user && signee.reference.id === user.id && signee.reference.type === user.type

          return (
            <SignCell
              canEdit={!isSignedOff && isSelf}
              shouldSign={shouldSign}
              signature={signee.initial_signature}
              signedAt={signee.initial_signed_at}
              onClick={() => {
                setCurrentSignatureType('initial')
                setCurrentSignee(signee)
              }}
            />
          )
        },
      })
    }

    // TODO: enable once API is ready
    // if (anyShouldSignUpdates) {
    //   result.push({
    //     title: 'Last Updates Signature',
    //     width: 'minmax(300px, 2fr)',
    //     render: (signee: any) => {
    //       const shouldSign = signatureSettings[signee.category]?.shouldSignUpdates

    //       return (
    //         <SignCell
    //           canEdit
    //           shouldSign={shouldSign}
    //           signature={signee.last_signature}
    //           signedAt={signee.last_signed_at}
    //           onClick={() => {
    //             setCurrentSignatureType('updates')
    //             setCurrentSignee(signee)
    //           }}
    //         />
    //       )
    //     },
    //   })
    // }

    return result
  }, [data, isSignedOff, anyShouldSignInitially, anyShouldSignUpdates, user, signatureSettings])

  const templateColumns = React.useMemo(() => {
    return tableConfig.map((col: any) => col.width).join(' ')
  }, [tableConfig])

  return (
    <InternalPage title="Signatures" icon="signature">
      {isEmpty ? (
        <Card key="empty-loading-state">
          <State isEmpty icon="signature" emptyDescription="No signees have been added yet" />
        </Card>
      ) : (
        <Card key="data-state">
          <div className="text-[0.92rem]">
            <GridTable useBanding={false} templateColumns={templateColumns}>
              <GridTable.Header>
                {tableConfig.map((col: any) => (
                  <GridTable.Cell key={col.title}>{col.title}</GridTable.Cell>
                ))}
              </GridTable.Header>

              {signees?.map?.((signee: any) => {
                return (
                  <GridTable.Row key={signee.id}>
                    {tableConfig.map((col: any) => (
                      <React.Fragment key={`${signee.id}-${col.title}`}>{col.render?.(signee)}</React.Fragment>
                    ))}
                  </GridTable.Row>
                )
              })}
            </GridTable>
          </div>
        </Card>
      )}

      <SummonOverlay
        isOpen={!!currentSignee}
        onClose={() => {
          setCurrentSignee(null)
        }}
        overlay={
          <FormSubmissionSignatureOverlay
            dataID={currentSignee?.id}
            signatureType={currentSignatureType}
            legalCopy={currentSignee && signatureSettings?.[currentSignee?.category]?.signatureLegalCopy}
          />
        }
      />
    </InternalPage>
  )
}

const VariablesPage = (props: any) => {
  const { isEditable, isNew, isFinalStatus } = props

  const variables: any = useFormBuilder((state: any) => state.variables) || []
  const variablesByCategory = React.useMemo(() => groupBy(variables, 'category'), [variables])

  const accountVariables = React.useMemo(() => {
    return variablesByCategory?.account || []
  }, [variablesByCategory])

  const customAccountVariables = React.useMemo(() => {
    return variablesByCategory?.custom_account || []
  }, [variablesByCategory])

  return (
    <InternalPage title="Variables" icon="variables" className="!max-w-[100%]">
      <div className="grid gap-6">
        {isNew && (
          <Alert small contrast glyph="info">
            Save the form submission to enable editing of the Time and Form Variables
          </Alert>
        )}

        <div className="grid gap-2">
          <header className="flex items-center justify-between">
            <div className="flex items-center flex-nowrap">
              <h3 className="text-[1.1rem]">Form Variables</h3>
            </div>
          </header>

          <Card>
            <SubmissionFormVariablesTable isEditable={isEditable && !isNew && !isFinalStatus} />
          </Card>
        </div>

        <div className="grid gap-2">
          <header className="flex items-center justify-between">
            <div className="flex items-center flex-nowrap">
              <h3 className="text-[1.1rem]">Time Variables</h3>
            </div>

            <AnchorDatePicker isEditable={isEditable && !isNew && !isFinalStatus} />
          </header>

          <Card>
            <TimeVariablesTable />
          </Card>
        </div>

        <div className="grid gap-2">
          <header className="flex items-center justify-between">
            <div className="flex items-center flex-nowrap">
              <h3 className="text-[1.1rem]">Custom Account Variables</h3>
            </div>
          </header>

          <Card>
            <SystemVariablesTable title="Custom Account Variables" variables={customAccountVariables} />
          </Card>
        </div>

        <div className="grid gap-2">
          <header>
            <div className="flex items-center flex-nowrap">
              <h3 className="text-[1.1rem]">Account Variables</h3>
            </div>
          </header>

          <Card>
            <SystemVariablesTable title="Account Variables" variables={accountVariables} />
          </Card>
        </div>
      </div>
    </InternalPage>
  )
}

const SettingsPage = (props) => {
  const { externalId, isFinalStatus, formData, isEditable, submissionSettings, setSubmissionSettings } = props

  return (
    <InternalPage title="Settings" icon="settings" className="!max-w-[600px]">
      <Alert small contrast glyph="info" className="mb-4">
        <b>Please note:</b> these settings were copied over from the form used to create this submission (
        <Link to={`/settings/forms-builder/${formData?.id}`} target="_blank" className="hover:underline">
          {formData?.name} <Glyph glyph="external_link" color={COLORS.blue} size={12} className="inline-block ml-0.5 relative -top-0.5" />
        </Link>
        ). Any changes made to the form settings will not affect this submission's settings.
      </Alert>

      <FormSubmissionSettings
        // form={form}
        externalId={externalId}
        isFinalStatus={isFinalStatus}
        initialModel={submissionSettings}
        submissionSettings={submissionSettings}
        setSubmissionSettings={setSubmissionSettings}
        isEditable={isEditable}
      />
    </InternalPage>
  )
}

const AddendumsPage = (props: any) => {
  const { data, allowed } = props

  const addendums = data?.addendums
  const isEmpty = size(addendums) === 0

  return (
    <InternalPage
      title="Addendums"
      icon="addendums"
      headerAside={
        allowed.addendums && (
          <SummonOverlay overlay={<FormSubmissionAddendumOverlay reference={data} />}>
            <Button size={200} label="Add New Addendum" glyph="add" type="primary" display="inline-flex" />
          </SummonOverlay>
        )
      }
    >
      <div className="grid gap-4">
        {isEmpty ? (
          <Card>
            <State isEmpty={isEmpty} icon="addendums" emptyDescription="No addendums have been added yet" />
          </Card>
        ) : (
          addendums.map((addendum: any) => {
            return <FormSubmissionAddendum key={`addendum-${addendum.id}`} data={addendum} />
          })
        )}
      </div>
    </InternalPage>
  )
}

const ProfileCell = ({ avatar, name, className }: any) => {
  const classNames = clsx('!flex !items-center !flex-nowrap', className)

  return (
    <GridTable.Cell className={classNames}>
      {name ? (
        <>
          <Avatar src={avatar} initials={name} size={20} />
          <div className="font-[500] ml-1">{name}</div>
        </>
      ) : (
        <div className="italic opacity-50">N/A</div>
      )}
    </GridTable.Cell>
  )
}

const SignCell = ({ shouldSign, signature, signedAt, onClick, canEdit }: any) => {
  const didSign = !!signature && !!signedAt

  return (
    <GridTable.Cell centerY>
      {didSign && (
        <div className="flex flex-nowrap items-center w-full">
          <Status small label={'Signed'} color="green" glyph="tick_circle" className="mr-1" />
          <Status small label={usDateTime(signedAt)} color="lightGray" glyph="date" />

          {canEdit && (
            <div className="ml-auto pl-2">
              <Button
                label="Edit"
                glyph="edit"
                size={100}
                onClick={onClick}
                css={{ svg: { margin: '0 !important' } }}
                permission="form_submissions.edit"
              />
            </div>
          )}
        </div>
      )}

      {!didSign && (
        <div className="flex flex-nowrap items-center justify-between w-full">
          {shouldSign ? <Status small label="Signature Required" color="red" /> : <div />}
          {canEdit && <Button glyph="signature" label="Add Signature" size={100} onClick={onClick} permission="form_submissions.edit" />}
        </div>
      )}
    </GridTable.Cell>
  )
}

const INTERNAL_PAGES = [
  {
    id: 'general',
    label: 'General',
    icon: 'general_info',
    color: COLORS.blue,
    component: GeneralPage,
  },
  {
    id: 'variables',
    label: 'Variables',
    icon: 'variables',
    color: COLORS.blue,
    component: VariablesPage,
    featureFlagV2: 'variables',
  },
  {
    id: 'signatures',
    label: 'Signatures',
    icon: 'signature',
    color: COLORS.orange,
    component: SignaturesPage,
  },
  {
    id: 'addendums',
    label: 'Addendums',
    icon: 'addendums',
    color: COLORS.blue,
    component: AddendumsPage,
  },
  {
    id: 'settings',
    label: 'Settings',
    icon: 'settings',
    color: COLORS.violet,
    component: SettingsPage,
  },
]

const FormSidebar = (props: any) => {
  const {
    isNew,
    data,
    internalPageId,
    onInternalPageChange,
    allowed,
    addendumsCount,
    signeesCount,
    signeesToSignCount,
    signees,
    signeesToSign,
    isFinalStatus,
  } = props

  const [currentSignee, setCurrentSignee] = React.useState(null)

  const signatureSettings = React.useMemo(() => {
    return processSignatureSettings(data)
  }, [data])

  const signeesByReference = React.useMemo(() => {
    const result: any = {}

    if (!signees) return result

    for (const signee of signees) {
      if (!signee?.reference?.id) continue

      result[`${signee.category}_${signee.reference.id}`] = signee
    }

    return result
  }, [signees])

  const activePageId: any = useFormBuilder((state: any) => state.activePageId)
  const pages: any = useFormBuilder((state: any) => state.pages)
  const pagesOrder: any = useFormBuilder((state: any) => state.pagesOrder)
  const setActivePageId: any = useFormBuilder((state: any) => state.setActivePageId)

  const { record: user } = useSettings()

  const hasAddendums = addendumsCount > 0

  return (
    <>
      <aside css={STYLES.aside}>
        <div className="px-4 py-3">
          <NavGroupLabel label="Form Pages" className="mb-1" />

          {pagesOrder?.map?.((pageId, index) => {
            const isActive = activePageId === pageId
            const page = pages[pageId]

            if (!page || page._destroy) return null

            return (
              <NavItem
                key={pageId}
                glyph="document"
                color={COLORS.vividBlue}
                isActive={isActive}
                label={page.name}
                onClick={() => setActivePageId(page.uuid)}
              />
            )
          })}
        </div>

        <Divider className="!m-0" />

        <>
          <div className="px-4 py-3">
            <NavGroupLabel label="Internal Only" className="mb-1" />
            {INTERNAL_PAGES.map((page) => {
              if (page.id === 'addendums' && !allowed.addendums && !hasAddendums) return null
              if (page.id === 'signatures' && !allowed.signatures) return null

              const navItem = (
                <NavItem
                  key={page.id}
                  label={page.label}
                  icon={page.icon}
                  color={page.color}
                  onClick={() => {
                    onInternalPageChange?.(page.id)
                  }}
                  isActive={internalPageId && internalPageId === page.id}
                  aside={
                    <>
                      {page.id === 'addendums' && hasAddendums && <Badge>{addendumsCount}</Badge>}
                      {page.id === 'signatures' && (
                        <Badge color={darken(0.1, COLORS.orange)} className="!w-auto !px-1.5 !rounded-[100px]">
                          {signeesCount - signeesToSignCount}/{signeesCount}
                        </Badge>
                      )}
                    </>
                  }
                />
              )

              if (page.featureFlagV2)
                return (
                  <Permission key={page.id} featureFlagV2={page.featureFlagV2}>
                    {navItem}
                  </Permission>
                )

              return navItem
            })}
          </div>

          {!isNew && (
            <>
              <Divider className="!m-0" />

              <div className="px-4 pt-2 pb-4">
                <Label label="Supervisors" className="!mb-0.5" />

                {size(data?.supervisors) > 0 ? (
                  <div className="grid gap-3">
                    {data?.supervisors.map((supervisor: any) => {
                      const signee = signeesByReference[`supervisor_${supervisor.id}`]

                      const shouldSignInitially = signatureSettings['supervisor']?.shouldSignInitially
                      const didSignInitially = signee?.initial_signature && signee?.initial_signed_at

                      const isSelf = user && supervisor.id === user.id && supervisor.type === user.type

                      return (
                        <ProfileItem
                          key={supervisor.id}
                          name={supervisor.name}
                          avatar={supervisor.avatar}
                          after={
                            shouldSignInitially &&
                            signee && (
                              <div className="ml-auto pl-2">
                                {didSignInitially ? (
                                  <Status small label="Signed" color="green" glyph="tick_circle" className="mr-1" />
                                ) : !isFinalStatus && isSelf ? (
                                  <Button
                                    label="Add Signature"
                                    type="default"
                                    glyph="signature"
                                    size={100}
                                    onClick={() => {
                                      setCurrentSignee(signee)
                                    }}
                                  />
                                ) : (
                                  <Status small label="Not Signed" color="red" />
                                )}
                              </div>
                            )
                          }
                        />
                      )
                    })}
                  </div>
                ) : (
                  <div className="text-text-muted">–</div>
                )}
              </div>

              <Divider className="!m-0" />

              <div className="px-4 pt-2 pb-4">
                <Label label="Staff Members" className="!mb-0.5" />

                {size(data?.staff) > 0 ? (
                  <div className="grid gap-3">
                    {data?.staff.map((staff: any) => {
                      const signee = signeesByReference[`staff_${staff.id}`]

                      const shouldSignInitially = signatureSettings['staff']?.shouldSignInitially
                      const didSignInitially = signee?.initial_signature && signee?.initial_signed_at

                      const isSelf = user && staff.id === user.id && staff.type === user.type

                      return (
                        <ProfileItem
                          key={staff.id}
                          name={staff.name}
                          avatar={staff.avatar}
                          after={
                            shouldSignInitially &&
                            signee && (
                              <div className="ml-auto pl-2">
                                {didSignInitially ? (
                                  <Status small label="Signed" color="green" glyph="tick_circle" className="mr-1" />
                                ) : !isFinalStatus && isSelf ? (
                                  <Button
                                    label="Add Signature"
                                    type="default"
                                    glyph="signature"
                                    size={100}
                                    onClick={() => {
                                      setCurrentSignee(signee)
                                    }}
                                  />
                                ) : (
                                  <Status small label="Not Signed" color="red" />
                                )}
                              </div>
                            )
                          }
                        />
                      )
                    })}
                  </div>
                ) : (
                  <div className="text-text-muted">–</div>
                )}
              </div>

              {data?.data?.submitter_name && (
                <>
                  <Divider className="!m-0" />

                  <div className="px-4 pt-2 pb-4">
                    <Label label="Online Submitter" className="!mb-0.5" />
                    <ProfileItem name={data?.data?.submitter_name} avatar={''} />
                  </div>
                </>
              )}

              {data?.author && (
                <>
                  <Divider className="!m-0" />

                  <div className="px-4 pt-2 pb-4">
                    <Label label="Author" className="!mb-0.5" />
                    <ProfileItem name={data.author.name} avatar={data.author.avatar} />
                  </div>
                </>
              )}
            </>
          )}
        </>
      </aside>

      <SummonOverlay
        isOpen={!!currentSignee}
        onClose={() => {
          setCurrentSignee(null)
        }}
        overlay={
          <FormSubmissionSignatureOverlay
            dataID={currentSignee?.id}
            signatureType={'initial'}
            legalCopy={currentSignee && signatureSettings?.[currentSignee?.category]?.signatureLegalCopy}
          />
        }
      />
    </>
  )
}

const ProfileItem = (props) => {
  const { name, avatar, after } = props

  if (!name) return null

  return (
    <div className="flex items-center flex-nowrap font-[400] text-text-muted min-w-0">
      <Avatar src={avatar} initials={name} size={20} className="mr-1.5" />
      <div className="truncate">{name}</div>
      {after}
    </div>
  )
}

const SIDEBAR_SHADOW = `
  2px 0 6px ${COLORS.shadow},
  4px 0 12px ${COLORS.shadow},
  8px 0 24px ${COLORS.shadow}
`

const STYLES = {
  root: {
    '--header-height': '2.8rem',
    '--header-background': COLORS.white,
    '--subheader-height': '2.4rem',
    '--subheader-background': lighten(0.01, '#F7F8FB'),
    '--field-max-width': '100% !important',
  },

  overlayContent: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    zIndex: 0,

    '@media(min-width: 800px)': {
      overflow: 'hidden !important',
    },
  },

  grid: {
    '@media(min-width: 800px)': {
      display: 'grid',
      overflow: 'hidden',
      gridTemplateRows: '100%',
      gridTemplateColumns: '1fr',
    },
  },

  aside: {
    boxShadow: SIDEBAR_SHADOW,
    background: COLORS.white,
    borderRight: `1px solid ${COLORS.divider}`,
    zIndex: 3,
    overflowY: 'auto',
    paddingBottom: '2rem',

    // '@media(min-width: 800px)': {
    //   overflowY: 'auto',
    //   borderBottom: 'none',
    //   borderRight: `1px solid ${COLORS.divider}`,
    // },
  },

  main: {
    display: 'grid',
    alignContent: 'start',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
    overflow: 'hidden',

    '&.is-desktop': {
      gridTemplateColumns: '250px 1fr',
    },
  },

  subheader: {
    label: {
      display: 'flex',
      alignItems: 'center',
      flexBasis: 'var(--field-label-width)',

      fontWeight: 600,
    },
  },
}

export const FormSubmissionOverlay = withOverlayError(FormSubmissionOverlayWithContext)
