import React from 'react'
import startCase from 'lodash/startCase'
import { usDateTime } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import EVOBCoverageStatus from '../Statuses/EVOBCoverageStatus'
import EVOBStatus from '../Statuses/EVOBStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import { CLEARING_HOUSES } from '../../utils/constants'

const columns = (to?: Function, timezone?: string) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'created_at',
    Header: 'Date Requested',
    Cell: ({ value, row }: any) => {
      return <TableCell.MainLink to={to?.(row.original.id)} label={usDateTime(value, timezone)} />
    },
  },
  {
    width: 160,
    accessor: 'status',
    Header: 'eVOB Status',
    Cell: ({ value }: any) => <EVOBStatus status={value} />,
  },
  {
    width: 160,
    accessor: 'clearing_house',
    Header: 'Clearing House',
    Cell: ({ value }: any) => <TableCell value={CLEARING_HOUSES[value] || '–'} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'Clearing House 1 (CH)', value: 'change_health' },
      { label: 'Clearing House 2 (PV)', value: 'pverify' },
    ],
  },
  {
    width: 160,
    accessor: 'insurance_eligibility_summary.plan_coverage_summary.status',
    Header: 'Coverage Status',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell.Empty />
      return <EVOBCoverageStatus status={value} />
    },
  },
  {
    width: 160,
    accessor: 'date_of_service',
    Header: 'Date of Service',
  },
  {
    width: 160,
    accessor: 'payer_name',
    Header: 'Insurance Company',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription?: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  showSettings?: boolean
  timezone: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const EVOBTable = (props: Props) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    batchActionsConfig,
    showSettings,
    timezone,
    title,
    to,
    titleAfter,
  } = props

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      showSettings={showSettings}
    />
  )
}

EVOBTable.defaultProps = {
  emptyDescription: 'No eVOBs added yet',
  icon: 'vob',
  localStorageKey: 'evob',
  title: 'eVOBs',
}

export default withSettings(EVOBTable)
