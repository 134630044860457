import React from 'react'
import { useSelector } from 'react-redux'

import { Accordion, Form, Page, Button, ConfirmDialog, HelpTagIframe } from '@behavehealth/components'
import { apiCreate, apiUpdate } from '@behavehealth/modules/api'
import { Tenant } from '@behavehealth/declarations'
import { withPageError } from '@behavehealth/hocs/withPageError'
import OnboardingForm from '@behavehealth/components/OnboardingForm'

const BusinessProfile: React.FC = () => {
  const record: Tenant = useSelector((state) => state.me.tenant)
  const user: Tenant = useSelector((state) => state.me.user)

  const isBehave = user?.type === 'bh_employee'
  const salesStep = record.system_prefs?.sales_step

  const sendRegistration = async () => {
    try {
      await apiCreate({
        url: '/facilities/current/registration_invite',
      })

      window.location.reload()
    } catch (error) {
      console.debug(error)
    }
  }

  const sendBusinessProfileReminder = async () => {
    try {
      await apiCreate({
        url: '/facilities/current/business_profile_reminder',
      })

      window.location.reload()
    } catch (error) {
      console.debug(error)
    }
  }

  const activateAccount = async () => {
    try {
      await apiCreate({
        name: 'tenant',
        url: '/facilities/current/activate_account',
        reducer: 'me',
      })

      window.location.reload()
    } catch (error) {
      console.debug(error)
    }
  }

  return (
    <Page
      title="Business Profile"
      icon="enterprise"
      help={<HelpTagIframe id="settings_business_profile" />}
      actions={
        isBehave && (
          <>
            <ConfirmDialog
              glyph="behave_health"
              title="Send Registration Invite"
              yesColor="green"
              message="Are you sure you want to send registration invite?"
              onYes={sendRegistration}
              isDisabled={salesStep !== 'signed_up'}
            >
              <Button type="primary" glyph="tick" label="Send Registration Invite…" color="green" isDisabled={salesStep !== 'signed_up'} />
            </ConfirmDialog>

            {salesStep === 'invited' && (
              <ConfirmDialog
                glyph="behave_health"
                title="Re-Send Registration Invite"
                yesColor="green"
                message="Are you sure you want to re-send registration invite?"
                onYes={sendRegistration}
              >
                <Button type="primary" glyph="tick" label="Re-Send Registration Invite…" color="green" />
              </ConfirmDialog>
            )}

            {salesStep === 'registered' && (
              <ConfirmDialog
                glyph="behave_health"
                title="Remind to complete Business Profile"
                yesColor="green"
                message="Are you sure you want to send a reminder to complete the Business Profile?"
                onYes={sendBusinessProfileReminder}
              >
                <Button type="primary" glyph="tick" label="Remind to complete Business Profile…" color="green" />
              </ConfirmDialog>
            )}
          </>
        )
      }
    >
      <Accordion
        isOpen
        isNew
        activateEditMode
        initialModel={record}
        title="Questionnaire"
        icon="enterprise"
        onSubmit={async (data: any) => {
          await apiUpdate({
            name: 'tenant',
            url: '/facilities/current/questionnaire',
            params: {
              questionnaire: data.questionnaire,
            },
            reducer: 'me',
          })
        }}
      >
        <Form>
          <OnboardingForm accountType={record.category} />
        </Form>
      </Accordion>
    </Page>
  )
}

export default withPageError(BusinessProfile)
