import React from 'react'

import SmartStatus from '../../../components/SmartStatus'

const statuses = {
  successful: {
    label: 'Successful',
    color: 'green',
  },
  errored: {
    label: 'Errored',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
