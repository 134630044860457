import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { DEFAULT_FILTERS } from '../Filters/constants'

export const RoundLinesDataTable = (props) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Round',
        id: 'round',
        model: 'round.name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={props.to?.(data)} value={value} />
        },
      },
      {
        title: 'Note',
        id: 'round_note',
        model: 'round_note.notes',
      },
      {
        title: 'Presence',
        id: 'presence',
        model: 'round_note.presence',
        type: 'title',
      },
      {
        title: 'Date & Time',
        id: 'round_note_dated_at',
        model: 'round_note.dated_at',
        type: 'date_time',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        id: 'round_note_author',
        model: 'round_note.author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [props?.to],
  )

  return <DataTable asCard title="Rounds" icon="rounds" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
}

const FILTERS_CONFIG = {
  round: {
    label: 'Round Name',
    type: 'string',
  },
  round_note: {
    label: 'Note',
    type: 'string',
  },
  presence: {
    type: 'multi_select',
    label: 'Presence',
    options: [
      { label: 'Present', value: 'present' },
      { label: 'Absent', value: 'absent' },
      { label: 'Unset', value: null },
    ],
  },
  round_note_dated_at: {
    type: 'date_time',
    label: 'Date & Time',
    glyph: 'date',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  round_note_author: DEFAULT_FILTERS.author,
}
