import React from 'react'
import { tint } from 'polished'

import { COLORS, INPUT_STYLES } from '../../../theme'
import { isDefined } from '../../../utils/functions'
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard'
import { useCreate } from '../../../hooks/useNewAPI'
import { useFormBuilder } from '../useFormBuilder'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import Markup from '../../../components/Markup'

const copyRichTextToClipboard = async (htmlString) => {
  try {
    const item = new ClipboardItem({
      'text/html': new Blob([htmlString], { type: 'text/html' }),
      'text/plain': new Blob([htmlString], { type: 'text/plain' }),
    })

    await navigator.clipboard.write([item])
  } catch (err) {
    console.error('Failed to copy: ', err)
  }
}

export const AIInput: React.FC<any> = (props) => {
  const { getInput, onInsert, onReplace, elementConfig, input, isRichText } = props

  const { isBehave, isHQApp } = useSettings()

  const [aiResult, setAIResult]: any = React.useState('')
  const [aiError, setAIError]: any = React.useState('')
  const [isUserInputEmpty, setIsUserInputEmpty] = React.useState(false)

  const localAIInputs: any = useFormBuilder((state: any) => state.localAIInputs)
  const globalAIInputs: any = useFormBuilder((state: any) => state.globalAIInputs)

  const [didCopy, setDidCopy] = React.useState(false)

  const aiInput = React.useMemo(() => {
    if (elementConfig?.ai_input_category === 'local') {
      return localAIInputs?.find((input: any) => input.external_id === elementConfig?.local_ai_input_value) || null
    }

    if (elementConfig?.ai_input_category === 'global') {
      return globalAIInputs?.find((input: any) => input.external_id === elementConfig?.global_ai_input_value) || null
    }

    return null
  }, [
    localAIInputs,
    globalAIInputs,
    elementConfig?.global_ai_input_value,
    elementConfig?.local_ai_input_value,
    elementConfig?.ai_input_category,
  ])

  const { mutateAsync: runInputAsync, isLoading: isRunningAction } = useCreate({
    name: ['perform-ai-action'],
    url: `/apps/ai/inputs/${aiInput?.external_id}/run`,
  })

  const runAction = async () => {
    const userInput = isDefined(getInput) ? getInput() : input

    if (!userInput || userInput === '<p></p>') {
      setIsUserInputEmpty(true)

      setTimeout(() => {
        setIsUserInputEmpty(false)
      }, 2000)

      return
    }

    try {
      const response = await runInputAsync({
        input: userInput,
      })

      setAIResult(response.data.result)
    } catch (error) {
      setAIError('The AI system could not perform this action')
      console.error(error)
    }
  }

  const clearAIResult = () => {
    setAIResult('')
    setAIError('')
    setIsUserInputEmpty(false)
  }

  const handleAIResultInsert = () => {
    if (!aiResult) return

    onInsert?.(aiResult)
    clearAIResult()
  }

  const handleAIResultReplace = () => {
    if (!aiResult) return

    onReplace?.(aiResult)
    clearAIResult()
  }

  const handleAIResultCopy = async () => {
    if (!aiResult) return

    try {
      copyRichTextToClipboard(aiResult)
      setDidCopy(true)

      setTimeout(() => {
        setDidCopy(false)
      }, 1000)
    } catch (error) {
      console.error('Failed to copy AI result', error)
    }
  }

  // clear AI result when the input changes
  React.useEffect(() => {
    clearAIResult()
  }, [elementConfig?.ai_input_category, elementConfig?.global_ai_input_value, elementConfig?.local_ai_input_value])

  if (!(isBehave || isHQApp)) return null

  if (!aiInput) return null

  return (
    <div>
      <div className="flex items-center mt-1.5">
        <Button
          label={aiInput.name}
          color="text"
          size={100}
          glyphColor={aiInput.color}
          glyph={aiInput.icon}
          onClick={runAction}
          isLoading={isRunningAction}
        />

        {isUserInputEmpty && (
          <div className="flex items-center flex-nowrap ml-2 animate-shortSlideInFromLeft">
            <Glyph glyph="warning" color={COLORS.orange} size={14} className="mr-1.5" />
            Please type something to run this AI Input
          </div>
        )}
      </div>

      {(aiResult || aiError) && (
        <div
          css={STYLES.aiResult}
          style={{ '--ai-result-background': tint(0.9, aiError ? COLORS.red : aiInput.color || COLORS.blue) } as any}
        >
          <div className="flex-auto">
            <Flex centerY nowrap>
              <div className="w-6 mr-1">
                <Glyph glyph={aiError ? 'circle_error' : aiInput.icon} color={aiError ? COLORS.red : aiInput.color} size={16} />
              </div>

              <div className="font-bold">{aiInput.name}</div>
            </Flex>

            <div className="pl-6 ml-1">
              {aiResult ? (
                <div className="mt-4">{isRichText ? <Markup value={aiResult} /> : aiResult}</div>
              ) : aiError ? (
                <div className="italic">{aiError}</div>
              ) : null}

              {aiResult && (
                <div className="mt-2">
                  <Flex gap="0.5rem">
                    <Button
                      label="Insert Text Below"
                      size={100}
                      color="text"
                      glyph="insert_below"
                      display="inline-flex"
                      onClick={handleAIResultInsert}
                    />

                    <Button
                      label="Replace Current Text"
                      size={100}
                      color="text"
                      glyph="replace"
                      display="inline-flex"
                      onClick={handleAIResultReplace}
                    />

                    <Button
                      label={didCopy ? 'Text copied' : 'Copy Text'}
                      size={100}
                      color="text"
                      glyph={didCopy ? 'tick_circle' : 'copy'}
                      display="inline-flex"
                      onClick={handleAIResultCopy}
                    />
                  </Flex>
                </div>
              )}
            </div>
          </div>

          <button type="button" onClick={clearAIResult} css={STYLES.clearButton}>
            <Glyph glyph="circle_error" color={COLORS.text} size={14} />
          </button>
        </div>
      )}
    </div>
  )
}

const STYLES = {
  aiResult: {
    ...INPUT_STYLES,
    position: 'relative',
    background: `var(--ai-result-background)`,
    fontWeight: 500,
    minHeight: 'auto',
    whiteSpace: 'pre-wrap',
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0.4rem',
    fontSize: '0.94rem',
    flexWrap: 'nowrap',
    overflow: 'hidden',

    '& > svg': {
      marginRight: '0.5rem',
    },

    '&.has-transcript': {
      fontWeight: 400,
      fontStyle: 'italic',
    },

    '&:hover': {},
  },

  clearButton: {
    background: 'none',
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 4,
    outline: 'none',
    width: 24,
    height: 24,
    opacity: 0.8,

    '&:hover': {
      opacity: 1,
      background: COLORS.hover,
      svg: { fill: COLORS.red },
    },
  },
}
