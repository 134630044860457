import React from 'react'
import { useSelector } from 'react-redux'

import { AlumniDataTable } from '@behavehealth/constructs/Clients/AlumniDataTable'
import { Grid, HelpTagIframe, Link, PageHeader, TipAlert } from '@behavehealth/components'
import { Tenant } from '@behavehealth/declarations'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { ClearSampleDataBanner } from '../../components/ClearSampleDataBanner'

const Alumni: React.FC = () => {
  const tenant: Tenant = useSelector((state) => state.me.tenant)

  return (
    <div css={STYLES.root}>
      <PageHeader showFeedbackTool icon="alumni_relations" title="Past Clients" help={<HelpTagIframe id="alumni" />} />

      <Grid>{tenant?.has_seed_data && <ClearSampleDataBanner isFullWidth />}</Grid>

      <AlumniDataTable testKey="past_clients_data_table" />
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'min-content min-content minmax(100px, 1fr)',
    overflow: 'hidden',
  },
}

export default withPageError(Alumni)
