import React from 'react'

import Alert from '../Alert'
import ApplicationForm from './ApplicationForm'
import Markup from '../Markup'

type Props = {
  companyName: string
  firstName: string
  logo: string
  onClose: Function
  schema: any
  thankYouMessage: string
  welcomeMessage: string
}

export const ApplicationFormExample = (props: Props) => {
  const { companyName, logo, onClose, schema, thankYouMessage, welcomeMessage, roiDisclaimerMessage } = props

  const form = React.useRef()

  return (
    <div css={{ padding: '2.5rem', maxWidth: 600, margin: '0 auto' }}>
      {logo && (
        <div css={styles.header}>
          <img src={logo} alt={`${companyName} logo`} css={styles.logo} />
        </div>
      )}

      {welcomeMessage && <Markup className="text-[1.1rem] mt-0 mx-0 mb-4" value={welcomeMessage} />}

      <ApplicationForm
        form={form}
        schema={schema}
        companyName={companyName}
        canUpload={false}
        roiDisclaimerMessage={roiDisclaimerMessage}
      />

      {thankYouMessage && (
        <Alert contrast glyph="tick_circle" type="positive" className="mt-6">
          <Markup value={thankYouMessage} />
        </Alert>
      )}
    </div>
  )
}

const styles = {
  header: {
    margin: '0 0 2rem',
  },

  logo: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    maxWidth: 120,
    maxHeight: 80,
    margin: '0 auto',
  },
}
