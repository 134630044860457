import React from 'react'

import { usDateTime } from '../../../../../utils/functions'
import { useSettings } from '../../../../../hooks/useSettings'
import { CLINICAL_NOTES_TITLES } from '../../../../../utils/constants'

import Text from '../../../../Typography/Text'
import ClinicalNoteStatus from '../../../..//Statuses/ClinicalNoteStatus'
import { EventOutcomeItem } from '../EventOutcomeItem'

export const ClinicalNoteOutcomeItem = ({ data, onClick }: any) => {
  const { timezone, isBehave } = useSettings()

  if (!data) return null

  const isBilled = data.is_billed ? `Yes (${usDateTime(data.billed_at, timezone)})` : 'No'

  return (
    <EventOutcomeItem
      title={CLINICAL_NOTES_TITLES[data.category]}
      titleAfter={<ClinicalNoteStatus small status={data.status} />}
      icon="clinical_notes"
      onClick={onClick}
      meta={
        <>
          {data.client && (
            <Text avatar={data.client?.avatar} label="Client:" description={data.client?.name} avatarInitials={data.client?.name} />
          )}
          {data.author && (
            <Text avatar={data.author?.avatar} label="Created by:" description={data.author?.name} avatarInitials={data.author?.name} />
          )}
          <Text glyph="date" label="Session: " description={usDateTime(data.started_at, timezone)} />
          <Text glyph="time" label="Duration: " description={`${data.duration} minutes`} />
          {isBehave && <Text icon="behave_health" label="Billed: " description={isBilled} />}
        </>
      }
    />
  )
}
