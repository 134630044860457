import React from 'react'

import Button from '../../Button'

import Stepper from '../../Stepper/Stepper'

export const SubmitButton: React.FC<any> = ({ onClick, isLoading }) => {
  const { isLast, data }: any = React.useContext(Stepper.Context)

  if (!isLast) return null

  return (
    <Button
      type="primary"
      glyph="check"
      color="green"
      label="Save & Submit Business Profile"
      isLoading={isLoading}
      onClick={() => onClick(data)}
      css={styles}
    />
  )
}

const styles = {
  marginTop: '1rem',
}
