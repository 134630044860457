import React from 'react'

import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import Diagnoses from '../../../components/SmartContent/Diagnoses'
import Grid from '../../../components/Grid'
import Input from '../../../components/Forms/Input'
import Label from '../../../components/Label'
import Medications from '../../../components/SmartContent/Medications'
import Textarea from '../../../components/Forms/Textarea'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../../components/Forms/elements/YesNoRadioGroupWithTextarea'

import {
  CIWA_SYMPTOMS_FIELDS,
  COWS_SYMPTOMS_FIELDS,
  MEDICAL_SYSTEMS_REVIEW_FIELDS,
  MENTAL_STATUS_EXAM_FIELDS,
  PHYSICAL_EXAM_FIELDS,
  SUBSTANCE_USE_HISTORY,
  VITAL_SIGNS_FIELDS,
} from './common'

export const HISTORY_AND_PHYSICAL_SECTIONS = [
  {
    title: 'Precipitating Event',
    model: 'precipitating_event',
    fields: [
      {
        label: 'Why is the client seeking treatment at this time? What makes this time different, if anything? (Please be specific)',
        model: 'precipitating_event_description',
        component: () => (
          <Textarea
            useQuickText
            label="Why is the client seeking treatment at this time? What makes this time different, if anything? (Please be specific)"
            model="data.precipitating_event.description"
          />
        ),
      },
    ],
  },
  {
    title: 'Medical Systems Review',
    model: 'medical_systems_review',
    fields: MEDICAL_SYSTEMS_REVIEW_FIELDS,
  },
  {
    title: 'Vital Signs',
    model: 'vital_signs',
    fields: VITAL_SIGNS_FIELDS,
  },
  {
    title: 'Physical Exam',
    model: 'physical_exam',
    fields: PHYSICAL_EXAM_FIELDS,
  },
  {
    title: 'Substance Use History',
    model: 'substance_use_history',
    fields: SUBSTANCE_USE_HISTORY,
  },
  {
    title: 'Client History',
    model: 'client_history',
    fields: [
      {
        label: 'Has the client been abused or exploited?',
        model: 'has_been_abused_or_exploited',
        component: () => (
          <>
            <YesNoRadioGroup label="Has the client been abused or exploited?" model="data.client_history.has_been_abused_or_exploited" />

            <ContextShow when="data.client_history.has_been_abused_or_exploited" is={true}>
              <CheckboxGroup label="Type of abuse:" layout="vertical-dense">
                <Checkbox label="Physical" model="data.client_history.abuse_type.physical" />
                <Checkbox label="Emotional" model="data.client_history.abuse_type.emotional" />
                <Checkbox label="Verbal" model="data.client_history.abuse_type.verbal" />
                <Checkbox label="Sexual" model="data.client_history.abuse_type.sexual" />
                <Checkbox label="Other" model="data.client_history.abuse_type.other" />
              </CheckboxGroup>

              <ContextShow when="data.client_history.abuse_type.other" is={true}>
                <Input label="Other:" model="data.client_history.abuse_type.other_extra" />
              </ContextShow>

              <Textarea useQuickText label="Please list age, duration and examples:" model="data.client_history.abuse_details" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Does the client have any current or past legal issues?',
        model: 'has_legal_issues',
        component: () => (
          <>
            <YesNoRadioGroup label="Does the client have any current or past legal issues?" model="data.client_history.has_legal_issues" />
            <ContextShow when="data.client_history.has_legal_issues" is={true}>
              <Textarea
                useQuickText
                label="Please list offenses, dates and status of charges:"
                model="data.client_history.legal_issues_details"
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: "Please list any medical issues evident in the client's family history. (include specific conditions and family members)",
        model: 'family_medical_issues',
        component: () => (
          <Textarea
            useQuickText
            label="Please list any medical issues evident in the client's family history. (include specific conditions and family members):"
            model="data.client_history.family_medical_issues"
          />
        ),
      },
      {
        label: 'Please list any medical issues currently or in the past',
        model: 'medical_issues',
        component: () => (
          <Textarea
            useQuickText
            label="Please list any medical issues currently or in the past:"
            model="data.client_history.medical_issues"
          />
        ),
      },
      {
        label: 'Please list any past surgeries',
        model: 'past_surgeries',
        component: () => <Textarea useQuickText label="Please list any past surgeries:" model="data.client_history.past_surgeries" />,
      },
      {
        label: 'Please list any family history of substance abuse',
        model: 'family_substance_abuse',
        component: () => (
          <Textarea
            useQuickText
            label="Please list any family history of substance abuse"
            model="data.client_history.family_substance_abuse"
          />
        ),
      },
      {
        label: "Please describe the client's educational history, including any problems due to substance use.",
        model: 'educational_history',
        component: () => (
          <Textarea
            useQuickText
            label="Please describe the client's educational history, including any problems due to substance use:"
            model="data.client_history.educational_history"
          />
        ),
      },
      {
        label: "Please describe the client's occupational history including problems due to substance use.",
        model: 'occupational_history',
        component: () => (
          <Textarea
            useQuickText
            label="Please describe the client's occupational history including problems due to substance use:"
            model="data.client_history.occupational_history"
          />
        ),
      },
      {
        label: "Please describe the client's social environment (friends, hobbies, primary relationships)",
        model: 'social_environment',
        component: () => (
          <Textarea
            useQuickText
            label="Please describe the client's social environment (friends, hobbies, primary relationships)"
            model="data.client_history.social_environment"
          />
        ),
      },
    ],
  },
  {
    title: 'Mental Status Exam',
    model: 'mental_status_exam',
    fields: MENTAL_STATUS_EXAM_FIELDS,
  },
  {
    title: 'COWS Symptoms',
    model: 'cows_symptoms',
    fields: COWS_SYMPTOMS_FIELDS,
  },
  {
    title: 'CIWA Symptoms',
    model: 'ciwa_symptoms',
    fields: CIWA_SYMPTOMS_FIELDS,
  },
  {
    title: 'Current Medications',
    model: 'medications',
    fields: [
      {
        label: 'Medications List',
        model: 'medications_list',
        component: ({ isEditable, client }: any) => (
          <Medications
            model="data.medications.meds"
            isEditable={isEditable}
            client={client}
            icon="medications"
            title="Medications"
            emptyDescription="No medications added yet"
          />
        ),
      },
    ],
  },
  {
    title: 'Diagnoses',
    model: 'diagnoses',
    fields: [
      {
        label: 'Diagnoses',
        model: 'diagnoses',
        component: ({ isEditable, client }) => (
          <Diagnoses
            model="data.diagnoses.diagnoses"
            isEditable={isEditable}
            client={client}
            icon="diagnosis"
            title="Diagnoses"
            emptyDescription="No diagnoses added yet"
          />
        ),
      },
      {
        label: 'Over the last 12 months, have substances been taken in larger amounts or over a longer period of time than intended?',
        model: 'did_take_substances_in_past_year',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Over the last 12 months, have substances been taken in larger amounts or over a longer period of time than intended?"
            model="data.diagnoses.did_take_substances_in_past_year"
          />
        ),
      },
      {
        label: 'Over the last 12 months, has there been a persistent desire or unsuccessful efforts to cut down or control substance use?',
        model: 'did_try_substance_use_control_in_past_year',
        component: () => (
          <YesNoRadioGroupWithTextarea
            label="Over the last 12 months, has there been a persistent desire or unsuccessful efforts to cut down or control substance use?"
            model="data.diagnoses.did_try_substance_use_control_in_past_year"
          />
        ),
      },
      {
        label: 'Number of times client has attempted to quit on their own',
        model: 'number_quit_attempts',
        component: () => (
          <Input
            label="Number of times client has attempted to quit on their own:"
            model="data.diagnoses.number_quit_attempts"
            suffix="times"
            type="number"
            size={4}
          />
        ),
      },
      {
        label: 'Total number of substance abuse admissions',
        model: 'number_substance_abuse_admissions',
        component: () => (
          <Input
            label="Total number of substance abuse admissions:"
            model="data.diagnoses.number_substance_abuse_admissions"
            suffix="admissions"
            type="number"
            size={4}
          />
        ),
      },
      {
        label: 'Total number of psychiatric admissions',
        model: 'number_psychiatric_admissions',
        component: () => (
          <Input
            label="Total number of psychiatric admissions:"
            model="data.diagnoses.number_psychiatric_admissions"
            suffix="admissions"
            type="number"
            size={4}
          />
        ),
      },
      {
        label: 'Total number of eating disorder admissions',
        model: 'number_eating_disorder_admissions',
        component: () => (
          <Input
            label="Total number of eating disorder admissions:"
            model="data.diagnoses.number_eating_disorder_admissions"
            suffix="admissions"
            type="number"
            size={4}
          />
        ),
      },
      {
        label: 'Please check any that apply',
        model: 'applicable_items',
        component: () => (
          <Grid gap="0.5rem">
            <Label label="Please check any that apply:" />

            <Checkbox
              label="A large amount of time is spent to obtain substance, use substance or recover from the effects"
              model="data.diagnoses.applicable.does_spend_large_time_on_substance_use"
            />

            <ContextShow when="data.diagnoses.applicable.does_spend_large_time_on_substance_use" is={true}>
              <Textarea
                useQuickText
                label="Please give example:"
                model="data.diagnoses.applicable.does_spend_large_time_on_substance_use_description"
              />
            </ContextShow>

            <Checkbox
              label="Client has craving or strong desire to use substance"
              model="data.diagnoses.applicable.has_substance_craving"
            />

            <Checkbox
              label="Substance use has resulted in failure to fulfill major role obligations at work, home or school"
              model="data.diagnoses.applicable.did_fail_obligations_due_to_substance_use"
            />

            <ContextShow when="data.diagnoses.applicable.did_fail_obligations_due_to_substance_use" is={true}>
              <Textarea
                useQuickText
                label="Please give example:"
                model="data.diagnoses.applicable.did_fail_obligations_due_to_substance_use_description"
              />
            </ContextShow>

            <Checkbox
              label="Substance use continues in spite of persistent or recurring social or interpersonal problems caused or exacerbated by the effects of the substance"
              model="data.diagnoses.applicable.does_continue_substance_use_despite_negatives"
            />

            <ContextShow when="data.diagnoses.applicable.does_continue_substance_use_despite_negatives" is={true}>
              <Textarea
                useQuickText
                label="Please give example:"
                model="data.diagnoses.applicable.does_continue_substance_use_despite_negatives_description"
              />
            </ContextShow>

            <Checkbox
              label="Continued use in dangerous situations or despite having knowledge of the consequences physically or psychologically"
              model="data.diagnoses.applicable.does_continue_substance_use_despite_dangers"
            />

            <ContextShow when="data.diagnoses.applicable.does_continue_substance_use_despite_dangers" is={true}>
              <CheckboxGroup label="The above is evidenced by the following (check any that apply):" layout="vertical-dense">
                <Checkbox label="Overdose" model="data.diagnoses.applicable.dangers.overdose" />
                <Checkbox label="Intravenous drug use" model="data.diagnoses.applicable.dangers.intravenous_drug_use" />
                <Checkbox
                  label="Shared needles or paraphernalia"
                  model="data.diagnoses.applicable.dangers.shared_needles_or_paraphernalia"
                />
                <Checkbox label="Abscesses or other infections" model="data.diagnoses.applicable.dangers.abscesses_or_other_infections" />
                <Checkbox label="Unsafe sex" model="data.diagnoses.applicable.dangers.unsafe_sex" />
                <Checkbox label="Criminal activity" model="data.diagnoses.applicable.dangers.criminal_activity" />
                <Checkbox label="Drive impaired" model="data.diagnoses.applicable.dangers.drive_impaired" />
                <Checkbox label="HIV" model="data.diagnoses.applicable.dangers.hiv" />
                <Checkbox label="Hepatitis C" model="data.diagnoses.applicable.dangers.hepatitis_c" />
                <Checkbox label="Seizure" model="data.diagnoses.applicable.dangers.seizure" />
                <Checkbox label="Blackouts" model="data.diagnoses.applicable.dangers.blackouts" />
                <Checkbox label="Liver disease" model="data.diagnoses.applicable.dangers.liver_disease" />
                <Checkbox label="Tremors" model="data.diagnoses.applicable.dangers.tremors" />
                <Checkbox label="Anxiety" model="data.diagnoses.applicable.dangers.anxiety" />
                <Checkbox label="Depression" model="data.diagnoses.applicable.dangers.depression" />
                <Checkbox label="Other" model="data.diagnoses.applicable.dangers.other" />
              </CheckboxGroup>

              <ContextShow when="data.diagnoses.applicable.dangers.other" is={true}>
                <Textarea useQuickText label="Other:" model="data.diagnoses.applicable.dangers.other_extra" />
              </ContextShow>
            </ContextShow>
          </Grid>
        ),
      },
    ],
  },
  {
    title: 'Summary',
    model: 'summary',
    fields: [
      {
        label: 'Assessment Summary',
        model: 'assessment_summary',
        component: () => <Textarea useQuickText label="Assessment Summary" model="data.summary.description" />,
      },
    ],
  },
]
