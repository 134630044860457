import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useDataForms } from '@behavehealth/hooks/useDataForms'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Chotomate, Button, Card, Grid, Page, HelpTagIframe, Link } from '@behavehealth/components'
import { PhysicianOrdersTable } from '@behavehealth/components/Tables'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { Filters } from '@behavehealth/constructs/Filters/Filters'
import { FILTERS } from '@behavehealth/constructs/Filters/config'

const pageConfig = {
  feature: 'physician_orders',
  help: <HelpTagIframe id="physician_orders" />,
  marketingID: 'physician_orders',
}

type Props = {
  canCreate: boolean
}

const PhysicianOrders: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()

  const { data, isEmpty, isLoading, batchDelete, setFilters } = useDataForms({ category: 'physician_order' })
  const { isBehave } = useSettings()

  const actions = (
    <Button
      label="Add Physician Order"
      type="primary"
      glyph="add"
      link={{
        pathname: `${match.url}/new`,
        parent: match,
      }}
      permission="physician_orders.create"
    />
  )

  return (
    <Page actions={!isEmpty && canCreate && actions} {...pageConfig}>
      <Grid gap="1rem">
        <Chotomate name="physician_orders" ready={!isLoading} />

        <Filters config={FILTERS.physician_orders} onUpdate={setFilters} localStorageKey="physician_orders" />

        <Card>
          <PhysicianOrdersTable
            data={data}
            isLoading={isEmpty && isLoading}
            isBehave={isBehave}
            emptyActions={canCreate && actions}
            localStorageKey="clients_physician_orders"
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            titleAfter={<Link to="/reports/physicians-orders">View Full Report →</Link>}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'physician_orders.delete',
                action: batchDelete,
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(PhysicianOrders, pageConfig))
