import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useAPI } from '@behavehealth/hooks'

import { Card, CardContent, Chotomate, DnDUploader } from '@behavehealth/components'

import { FilesTable } from '@behavehealth/components/Tables'

import { Tenant } from '@behavehealth/declarations/Tenant.d'
import { Documents } from '@behavehealth/declarations/Document.d'

const Reports: React.FC = () => {
  const match = useRouteMatch()
  const tenant: Tenant = useSelector((state: any) => state.me.tenant)
  const data: Documents = useSelector((state: any) => state.data.documents?.data)
  const loading = useSelector((state: any) => state.data.documents?.loading)

  useAPI('documents', `/facilities/${tenant.id}/documents?category=rcm`)

  const isEmpty = size(data) === 0

  return (
    <Card>
      <Chotomate name="rcm" ready={!loading} />

      <FilesTable
        title="Reports"
        data={data}
        isLoading={isEmpty && loading}
        localStorageKey="rcm_reports"
        to={(id: string) => ({
          pathname: `${match.url}/${id}`,
          parent: match,
        })}
      />

      <CardContent>
        <DnDUploader isEditable category="rcm" resource={tenant} isDisabled={false} />
      </CardContent>
    </Card>
  )
}

export default Reports
