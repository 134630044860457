import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { titleCase } from '../../utils/functions'

import { countWord, minutesBetween } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { ProfileCell } from '../../components/DataTable/cells/ProfileCell'

import ServiceEpisodeStatus from '../../components/Statuses/ServiceEpisodeStatus'
import ServiceEpisodeTypeStatus from '../../components/Statuses/ServiceEpisodeTypeStatus'

import { SERVICE_EPISODE_CATEGORIES, SERVICE_EPISODE_LOC_CATEGORIES, SERVICE_EPISODE_LOC_SUBCATEGORIES } from '../../utils/constants'

export const ServiceEpisodesDataTable = (props: any) => {
  const match = useRouteMatch()
  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          if (props.mainCell) return props.mainCell({ data, value })

          return <MainCell id={data.id} to={props.to ? props.to(data) : `${match.url}/${data.id}`} value={value} />
        },
      },
      {
        title: 'Status',
        id: 'status',
        model: 'status',
        formatValue: ({ value }: any) => <ServiceEpisodeStatus status={value} />,
      },
      {
        title: 'Episode Type',
        id: 'category',
        model: 'category',
        formatValue: ({ value }: any) => <ServiceEpisodeTypeStatus status={value} />,
      },
      {
        title: 'Level of Care Category',
        id: 'loc_category',
        model: 'loc_category',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return SERVICE_EPISODE_LOC_CATEGORIES[value]
        },
      },
      {
        title: 'Level of Care',
        id: 'loc_subcategory',
        model: 'loc_subcategory',
        formatValue: ({ data, value }: any) => {
          if (!value) return null

          return SERVICE_EPISODE_LOC_SUBCATEGORIES[data.loc_category][value]
        },
      },
      {
        title: 'Episode From',
        id: 'started_at',
        model: 'started_at',
        type: 'date_time',
      },
      {
        title: 'Episode Until',
        id: 'ended_at',
        model: 'ended_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated At',
        id: 'updated_at',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Created At',
        id: 'created_at',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        id: 'author',
        model: 'author',
        type: 'profile',
      },
    ],
    [match, timezone, props.to],
  )

  return (
    <DataTable
      asCard
      title="Service Episodes"
      testKey="service_episodes_table"
      icon="service_episodes"
      columns={columns}
      {...props}
      updateKey={props.queryKey}
      updateDeleteEndpoint="/service_episodes"
      filtersConfig={FILTERS_CONFIG}
    />
  )
}

const ASAM_OPTIONS = Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['asam_substance_use_disorder_treatment']).map(([key, value]) => ({
  label: value,
  value: key,
}))

const OLD_ASAM_OPTIONS = Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['asam_substance_use_disorder_treatment_old']).map(
  ([key, value]) => ({
    label: value,
    value: key,
  }),
)

const MENTAL_HEALTH_OPTIONS = Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['mental_health']).map(([key, value]) => ({
  label: value,
  value: key,
}))

const NARR_LEVEL_OPTIONS = Object.entries(SERVICE_EPISODE_LOC_SUBCATEGORIES['narr_levels_of_support']).map(([key, value]) => ({
  label: value,
  value: key,
}))

const FILTERS_CONFIG = {
  author: {
    type: 'multi_object',
    label: 'Added By',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: true,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => titleCase(item.position),
  },
  name: {
    label: 'Name',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ],
  },
  category: {
    label: 'Type',
    type: 'multi_select',
    options: Object.keys(SERVICE_EPISODE_CATEGORIES).map((key) => ({ label: SERVICE_EPISODE_CATEGORIES[key], value: key })),
  },
  loc_category: {
    label: 'Category',
    type: 'multi_select',
    options: Object.keys(SERVICE_EPISODE_LOC_CATEGORIES).map((key) => ({ label: SERVICE_EPISODE_LOC_CATEGORIES[key], value: key })),
  },
  loc_subcategory: {
    label: 'Subcategory',
    type: 'multi_select',
    options: [
      { groupTitle: 'ASAM Substance Use Disorder Treatment' },
      ...ASAM_OPTIONS,

      { groupTitle: 'Old Substance Use Disorder Treatment' },
      ...OLD_ASAM_OPTIONS,

      { groupTitle: 'Mental Health' },
      ...MENTAL_HEALTH_OPTIONS,

      { groupTitle: 'NARR Levels of Support' },
      ...NARR_LEVEL_OPTIONS,
    ],
  },
  started_at: {
    title: 'Episode From',
    type: 'date_time',
  },
  ended_at: {
    title: 'Episode Until',
    type: 'date_time',
  },
  created_at: {
    label: 'Created At',
    type: 'date_time',
  },
  updated_at: {
    label: 'Updated At',
    type: 'date_time',
  },
}
