import React from 'react'
import startCase from 'lodash/startCase'

import { Property } from '../declarations/Property'

import { Text } from './Typography'
import Flex from './Flex'

type Props = {
  property: Property
}

const PropertyProfileHeader: React.FC<Props> = ({ property }) => {
  if (!property) return null

  return (
    <Flex gap={6}>
      <Text avatar={property.avatar} label={property.name} />
      <Text>{`${startCase(property.gender)}`}</Text>
    </Flex>
  )
}

export default PropertyProfileHeader
