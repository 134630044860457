import React from 'react'
import produce from 'immer'

import { addMonths } from '../../../utils/functions'
import withSettings from '../../../hocs/withSettings'

import Alert from '../../../components/Alert'
import ContextShow from '../../../components/Forms/ContextShow'
import DateTimeInput from '../../../components/Forms/DateTimeInput'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Option from '../../../components/Forms/Option'
import Select from '../../../components/Forms/Select'
import Workflow from '../../../components/Workflow/Workflow'

const LENGTH_OF_STAYS_TO_MONTHS = {
  '30_days': 1,
  '60_days': 2,
  '90_days': 3,
  '180_days': 6,
  '365_days': 12,
  '540_days': 18,
}

const getInitialLengthOfStay = (applicationForm: any) => {
  if (!applicationForm?.data?.admissions?.preferred_length_of_stay) return

  return LENGTH_OF_STAYS_TO_MONTHS[applicationForm.data.admissions.preferred_length_of_stay]
}

const ArrivalDetails = ({ data, setData, setStatus, timezone, client }: any) => {
  const [valid, setValid] = React.useState(false)

  const initialLengthOfStay = getInitialLengthOfStay(client.current_intake_application)

  const handleUpdate = (value: any) => {
    const newValue = produce(value, (draft: any) => {
      if (draft.months_to_discharge === 'custom') {
        draft['estimated_discharge'] = draft.estimated_discharge
      } else if (draft.months_to_discharge) {
        draft['estimated_discharge'] = addMonths(draft.estimated_arrival, draft.months_to_discharge)
      }
    })

    setData(newValue)
  }

  React.useEffect(() => {
    if (valid) setStatus('completed')
    else setStatus('todo')
  }, [valid])

  return (
    <Form initialModel={data} onValidationUpdate={setValid} onUpdate={handleUpdate} timezone={timezone}>
      <FormSection layout="vertical" maxWidth="100%">
        <DateTimeInput
          label="Estimated Arrival Date"
          model="estimated_arrival"
          defaultToNow
          validations={{
            presence: {
              message: 'Please enter an arrival date',
            },
          }}
        />

        <Select asNumber label="Estimated Length Of Stay" model="months_to_discharge" defaultValue={initialLengthOfStay || 6}>
          <Option label="1 Month" value={1} />
          <Option label="2 Months" value={2} />
          <Option label="3 Months" value={3} />
          <Option label="6 Months" value={6} />
          <Option label="9 Months" value={9} />
          <Option label="12 Months" value={12} />
          <Option label="18 Months" value={18} />
          <Option label="Custom Date" value="custom" />
        </Select>

        <ContextShow when="months_to_discharge" is="custom">
          <DateTimeInput
            label="Est. Discharge Date"
            model="estimated_discharge"
            defaultInOneMonth
            validations={{
              presence: {
                message: 'Please enter an Est. Discharge Date',
              },
            }}
          />
        </ContextShow>

        <Alert contrast glyph="info">
          Estimates can be changed later
        </Alert>

        <Workflow.Buttons>
          <Workflow.ContinueButton isDisabled={!valid} />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default withSettings(ArrivalDetails)
