import React from 'react'
import { useSelector } from 'react-redux'

import { Tenant } from '@behavehealth/declarations'

import { titleCase, daysBetween, usDate, numberToWords } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Card, Chotomate, Heading, P, Section, DescriptionList, DescriptionItem, Page, HelpTagIframe } from '@behavehealth/components'

const LegalAgreement: React.FC = () => {
  const record: Tenant = useSelector((state) => state.me.tenant)

  return (
    <Page title="Legal Agreement" feature="legal_agreement" help={<HelpTagIframe id="settings_signup_agreement" />}>
      <Card>
        <Chotomate ready name="legal_agreement" />

        {/* Registration Details */}
        <Section>
          <Heading is="h2" css={{ margin: '1.25rem 0 1rem' }}>
            Registration Details
          </Heading>

          <DescriptionList>
            <DescriptionItem title="Name" description={`${record.first_name} ${record.last_name}`} />
            <DescriptionItem title="Email" description={record.email} />
            <DescriptionItem title="Phone" description={record.phone_no} />
            <DescriptionItem title="Company" description={record.name} />
            <DescriptionItem title="Subdomain" description={`https://${record.subdomain}.behavehealth.com`} />
            <DescriptionItem title="Registration Date" description={usDate(record.created_at)} />
          </DescriptionList>
        </Section>

        {/* Pricing Overview */}
        <Section>
          <Heading is="h2" css={{ margin: '1.75rem 0 1rem' }}>
            Pricing Overview
          </Heading>

          <DescriptionList>
            <DescriptionItem title="Plan Name" description={record.plan?.name} />
            <DescriptionItem title="Plan Status" description={titleCase(record.plan_status)} />
            <DescriptionItem
              title="Plan Length"
              description={`${daysBetween(record.plan_started_at, record.plan_ended_at)} days (ends ${usDate(record.plan_ended_at)})`}
            />
          </DescriptionList>
        </Section>

        {/* Free Trial Terms */}
        <Section>
          <Heading is="h2" css={{ margin: '1.75rem 0 1rem' }}>
            Free Trial Terms
          </Heading>

          <P className="!my-4 !mx-0">
            If you are requesting a free trial, then BHC will provide access to the Software Application for evaluation purposes without any
            otherwise applicable service fee for up to 7 days (the "Trial Period"), during which time the Software Application will be
            available for use in tandem with your existing record and billing system (whether paper-based or electronic).
          </P>

          <P className="!my-4 !mx-0">
            You agree to run the Software Application alongside your existing record and billing system, and you acknowledge that (a)
            certain features or functions of the Software Application may be unavailable; (b) the Software Application itself is subject to
            intermittent and unscheduled periods of unavailability; and (c) BHC does not provide any service uptime assurances or any other
            warranties or guaranties regarding the Software Application.
          </P>

          <P className="!my-4 !mx-0">
            Upon the expiration of the Trial Period, your access to and use of the Software Application will terminate, and you will have no
            further access to the Software Application or information that you stored in the Software Application prior to expiration. We
            reserve the right to limit trial versions of the Software Application to one user per organization, and to limit an
            organization’s access to trial versions in our sole discretion. Except as modified above, our Access Agreement will apply to
            your use of the Software Application during the Trial Period.
          </P>
        </Section>

        {/* Software Access Agreement */}
        <Section>
          <Heading is="h2" css={{ margin: '1.75rem 0 1rem' }}>
            Software Access Agreement
          </Heading>
          <Heading is="h3" size={400}>
            SOFTWARE ACCESS AGREEMENT
          </Heading>
          <P className="!my-4 !mx-0">
            This SOFTWARE ACCESS AGREEMENT (this <strong>"Agreement"</strong>) is made and entered into between Behave Health Corp., a
            Delaware corporation ("
            <strong>BHC</strong>") as of the date (the "<strong>Effective Date</strong>") accepted electronically by {record.name} ("
            <strong>Customer</strong>"),.
          </P>

          <Heading is="h3" size={400}>
            RECITALS
          </Heading>
          <ol className="letters">
            <li>
              BHC has developed a cloud-based based software as a service (SaaS) system, (the "<strong>Software Application</strong>") which
              enables BHC to provide certain record keeping services and debit/credit card and ACH processing, all as described herein (the
              "<strong>Service</strong>").
            </li>
            <li>
              BHC desires to grant Customer access to the Service for the sole purpose of allowing Customer and Customer's employees and
              contractors who are bound by BHC's Terms of Use ("<strong>End Users</strong>") to maintain certain records for sober living
              services rendered by Customer and its Providers and to provide related debit/credit card and ACH processing in connection with
              the sober living services (the "<strong>Purpose</strong>"), upon the terms and conditions set forth herein. "
              <strong>Providers</strong>" shall refer to all employees and independent contractors who provide sober living services for, on
              behalf of, or under arrangements with Customer.
            </li>
          </ol>

          <Heading is="h3" size={400}>
            AGREEMENT
          </Heading>
          <P className="!my-4 !mx-0">
            NOW, THEREFORE, in consideration of the foregoing, and for other good and valuable consideration, the receipt and sufficiency of
            which is hereby acknowledged, the parties agree as follows:
          </P>

          <ol>
            <li>
              <span>Service.</span>
              <ol>
                <li>
                  <span>Software License.</span>&nbsp;&nbsp;Subject to and in accordance with the Terms of this Agreement, BHC agrees to and
                  hereby does grant to Customer a limited, non-exclusive, non-assignable and otherwise non-transferable license to use the
                  Service to fulfill the purpose of this Agreement. Customer may permit access to the Software Application only to its
                  employees and contractors who are bound by BHC's Terms of Use and who are (i) designated by Customer on control screens
                  and who are (A) employees of Customer, or (B) other individuals, corporations, or entities that are not, and are not
                  affiliated with, third party payers or competitors of BHC; and (ii) who have been granted access to the Service by
                  Customer in its exercise of reasonable discretion relating to the receipt of Service hereunder by Customer. No other
                  rights to the Software Application are conveyed to Customer, and BHC expressly retains all such other rights not
                  specifically granted in this <span>Section 1.1</span>. Nothing in the Agreement shall be deemed to require BHC to render
                  services or to license the Software Application exclusively to the Customer or to preclude BHC from rendering services or
                  licensing the Software Application to any other entity or person.
                </li>
              </ol>
            </li>
            <li>
              <span>Compensation.</span>
              <ol>
                <li>
                  <span>Fees.</span>&nbsp;&nbsp;As consideration for the Service, Customer shall pay to BHC, on a monthly basis, a fee (the
                  "<strong>BHC Fees</strong>") equal to the following:
                  <ol>
                    <li>
                      The Service Fee set forth in "Pricing Overview" above. For these purposes, a resident shall be considered to be
                      residing at the Customer’s facility on both the date that their record is activated on their "admit" day and on the
                      date that their record is de-activated on their "move-out" day. BHC will invoice Customer monthly for the Service Fee.
                      The Service Fee will be automatically deducted by BHC from the payments processed by BHC for Customer shortly
                      following delivery of the invoice by BHC to Customer or, if BHC does not process payments for Customer, then the
                      Service Fee shall be due and payable via ACH deposit within ten (10) days of the date of the invoice therefor. Late
                      payments by Customer will result in a late payment charge equal to one and a half percent (1.5%) per month or the
                      maximum monthly rate allowed by applicable law, whichever is lower, of the remaining amount due.
                    </li>
                    <li>
                      The debit/credit card processing fee in the percentage set forth in "Pricing Overview" above with respect to all
                      payments processed by BHC via debit/credit card, and the ACH processing fee in the percentage set forth in "Pricing
                      Overview" above with respect to all payments processed by BHC via ACH (the "<strong>Processing Fee</strong>"). The
                      Processing Fee and other applicable credit card company charges shall automatically be deducted from payments remitted
                      to Customer.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>Taxes.</span>&nbsp;&nbsp;Customer shall be responsible for any applicable sales, use, value added or similar taxes
                  payable with respect to the Service, or arising out of or in connection with this Agreement, other than taxes levied or
                  imposed based upon BHC's income. In the event that BHC pays or reimburses any such taxes on behalf of Customer, BHC shall
                  invoice Customer for such taxes and Customer agrees to pay such taxes in accordance with this Agreement.
                </li>
              </ol>
            </li>
            <li>
              <span>Responsibilities of Customer.</span>
              <ol>
                <li>
                  <span>Customer Contacts.</span>&nbsp;&nbsp;Customer shall identify one representative to whom BHC may address all matters
                  related to the Service. Customer may change such representative by providing written notice of the same to BHC. The
                  Customer representative will provide responses to BHC inquiries in a reasonably timely manner.
                </li>
                <li>
                  <span>No Guarantee of Results.</span>&nbsp;&nbsp;Customer acknowledges and agrees that except as expressly provided
                  herein, BHC: (a) has not provided any guarantee of success or of specific results in connection with the performance of
                  the Service; (b) has not provided any representations or warranties regarding the outcome from such Service; and (c) is
                  not responsible for any uncollected claims (or any portions thereof).
                </li>
                <li>
                  <span>Access to and Copies of Records.</span>&nbsp;&nbsp;Customer understands that Customer, and not BHC, is responsible
                  for maintaining records for all services provided by Customers to residents.
                </li>
                <li>
                  <span>Breach of Warranties.</span>&nbsp;&nbsp;Customer shall, except to the extent caused by BHC's gross negligence or
                  willful misconduct, indemnify and hold harmless BHC, and all its officers, directors, employees and agents, from and
                  against any losses, claims, damages, judgments, assessments, costs and other liabilities, including reasonable
                  out-of-pocket costs and expenses as they are incurred by BHC in connection with any demands, law suits and other legal
                  actions by third parties against BHC arising out of or alleged to arise out of: (i) the breach by Customer, its officers,
                  directors, employees, contractors or agents (collectively referred to as "<strong>Representatives</strong>") of any
                  representation or warranty contained herein; (ii) the gross negligence or willful misconduct of Customer or its
                  Representatives in the performance of this Agreement; or (iii) any failure by Customer or its Representatives to comply
                  with applicable law.
                </li>
                <li>
                  <span>No Violation of Privacy/Confidentiality Laws.</span>&nbsp;&nbsp;The parties acknowledge their respective obligations
                  for security and privacy of protected health information under the Health Insurance Portability and Accountability Act of
                  1996 ("HIPAA"). The parties agree to the terms and conditions of the Business Associate Agreement attached hereto and
                  incorporated by reference as Exhibit A.
                </li>
              </ol>
            </li>
            <li>
              <span>BHC Software.</span>
              <ol>
                <li>
                  <span>Service Uptime.</span>&nbsp;&nbsp;BHC will use commercially reasonable efforts to ensure that the Software
                  Application is available seven (7) days a week, twenty-four (24) hours a day except for (i) scheduled maintenance windows;
                  or (ii) any unavailability beyond BHC's actions (including, for example, actions or omissions by BHC's service providers);
                  or (iii) beyond BHC's reasonable control, caused by an act of God, act of government, flood, fire, earthquake, civil
                  unrest, act of terror or other force majeure. Disruptions in the Service caused by the foregoing shall not be considered a
                  breach by BHC. BHC will schedule reasonable maintenance windows for the Software Application related to the Service.
                  Customer should expect for the Software Application and Service to be unavailable at any time one of the maintenance
                  windows has been activated.
                </li>
                <li>
                  <span>Hosting and Third Party Service.</span>&nbsp;&nbsp;BHC will provide the Service using a third party cloud hosting
                  service to provide a hosted environmental pursuant to this Agreement. BHC may, and shall, also subcontract other services
                  and payment processing to third parties.
                </li>
              </ol>
            </li>
            <li>
              <span>Term and Termination.</span>
              <ol>
                <li>
                  <span>Term.</span>&nbsp;&nbsp;The initial term of this Agreement shall begin upon Customer's acceptance of this Agreement
                  and shall continue until terminated (the "Term"). Either party may terminate this Agreement at any time by providing the
                  other party written notice at least ninety (90) days prior to the termination date.
                </li>
                <li>
                  <span>Immediate Termination by BHC.</span>&nbsp;&nbsp;Upon written notice to Customer, BHC shall have the right to
                  immediately terminate this Agreement upon the occurrence of any of the following: (a) Any of Customer's Providers loses
                  any of his/her applicable professional licenses, unless the same is corrected during the period of time provided by the
                  licensing agency for correction or Customer removes such Provider from providing sober living services; (b) Customer or
                  any of Customer's Providers is suspended or excluded from participation in Medicare or any other government payment
                  program or commercial insurance program, unless the same is corrected during the period of time provided by the relevant
                  agency for correction or Customer removes such provider from providing sober living services; (c) Customer or any of
                  Customer's Providers is otherwise subject to discipline by Medicare or any other government payment program or commercial
                  insurance program unless Customer removes such physician or other provider from providing sober living services; (d)
                  Customer or any of Customer's Providers is found by a court of law to have committed fraud unless Customer removes such
                  Provider from providing sober living services; (e) Customer fails to pay when due any payment due under this Agreement and
                  such failure is not cured within thirty (30) days after delivery of written notice to Customer of such failure; (f)
                  Customer fails to submit new charges or residents for a period of 90 days; or (g) BHC reasonably and in good faith
                  concludes that Customer has engaged in any act of fraud, defalcation or gross misconduct or has not complied with the
                  applicable laws and regulations in any material respect.
                </li>
                <li>
                  <span>Immediate Termination by Customer.</span>&nbsp;&nbsp;Upon written notice to BHC, Customer shall have the right to
                  immediately terminate this Agreement if Customer reasonably and in good faith concludes that BHC has engaged in any act of
                  fraud or has not complied with applicable laws and regulations in any material respect.
                </li>
                <li>
                  <span>Termination for Breach.</span>&nbsp;&nbsp;Either party may terminate this Agreement at any time upon thirty (30)
                  days' written notice if the other party is in material breach of this Agreement (other than a breach described in{' '}
                  <span>Section 5.2 or 5.3</span>), unless the breaching party cures such breach prior to expiration of the thirty (30) day
                  notice (or such longer period as may be required to cure the breach, but not to exceed 90 days, if the breach is not
                  reasonably capable of being cured within such 30 day period provided that the allegedly breaching party diligently pursues
                  a remedy during such period).
                </li>
                <li>
                  <span>Effects of Termination.</span>&nbsp;&nbsp;Upon termination of this Agreement for any reason:
                  <ol>
                    <li>
                      BHC shall discontinue providing Service as of the termination date, except as specifically provided in this{' '}
                      <span>Section 5.5</span>.
                    </li>
                    <li>
                      Upon expiration of such Transition Period and after full payment of fees owed, regardless of the reason for
                      termination, BHC will deliver to Customer, as selected by BHC, either (i) a complete database file which includes all
                      data, information, documents, scanned images or other materials entered into the database during the Term in an
                      electronic format reasonably selected by BHC; or (ii) a PDF file for each resident containing all records maintained
                      by BHC for Customer. BHC shall have no further obligations to Customer following termination except as otherwise set
                      forth in this Agreement.
                    </li>
                    <li>
                      BHC shall be entitled to recover from Customer, and Customer shall promptly pay, all fees and other compensation owed
                      to BHC, which had accrued but remained unpaid as of the termination date, and for all previously contracted services
                      provided during the transition period under this Agreement; and
                    </li>
                    <li>
                      Additional transitional services, if any, shall only be provided upon such terms and conditions, including additional
                      compensation, as may be mutually agreed to in writing by the parties.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <span>Confidentiality/Proprietary Information</span>
              <ol>
                <li>
                  <span>Confidentiality.</span>&nbsp;&nbsp;"
                  <strong>Confidential Information</strong>" of a party shall mean the software, documentation, and terms and conditions of
                  this Agreement, and any information or material disclosed by one party ("<strong>disclosing party</strong>") to the other
                  party ("<strong>recipient</strong>") (a) in written, graphic, electronic or any other tangible form that is marked in
                  writing as confidential or proprietary, or (b) in oral or other intangible form that is (i) identified as confidential at
                  the time of the initial disclosure in oral or other intangible form; and (ii) documented in a writing that is so marked
                  and transmitted to the recipient within thirty (30) days after such initial disclosure. Notwithstanding the foregoing,
                  "Confidential Information" does not include any information or material which (A) now is or hereafter becomes available to
                  the public other than as the result of a disclosure by the recipient in breach hereof; (B) is developed by the recipient
                  independently of any Confidential Information provided hereunder by the disclosing party; (C) was known by the recipient
                  prior to any disclosure made by the other party to the recipient; (D) is disclosed with the written consent of the
                  disclosing party; (E) becomes rightfully known to the recipient from a source, other than the disclosing other party, that
                  does not owe a duty of confidentiality to the disclosing party with respect to such information or material; or (F) is an
                  aggregation created by recipient provided the underlying Confidential Information is not readily identifiable in such
                  aggregation.
                </li>
                <li>
                  <span>Confidentiality Obligations.</span>&nbsp;&nbsp;Each party shall safeguard and protect the Confidential Information
                  from disclosure, theft, piracy or unauthorized access in a manner at least consistent with the protections such party uses
                  to protect its own most confidential information, provided that a party may disclose Confidential Information to a third
                  party in connection with performance of its obligations or exercise of its rights hereunder, if such third party is
                  required to treat the Confidential Information in the same manner as the comparable information of the party disclosing
                  such Confidential Information to such third party. In addition, the recipient will have the right to disclose Confidential
                  Information (1) to the extent required by order of a court, administrative agency or governmental body, or by any law,
                  rule or regulation, or by subpoena, or any other administrative or legal process, provided that the recipient shall give
                  the disclosing party, to the extent reasonable, prompt notice of the pending disclosure so that the other party may seek a
                  protective order or other appropriate protection; (2) in confidence, to lawyers and accountants and to banks,
                  underwriters, investors and other financing sources (and their advisors); and (3) in connection with the enforcement of
                  this Agreement or any rights hereunder. The parties shall inform their respective employees of their obligations under
                  this Agreement, and shall take such steps as may be reasonable in the circumstances, or as may be reasonably requested by
                  the other party, to prevent any unauthorized disclosure, copying or use of the Confidential Information. Each party
                  acknowledges the confidential and proprietary nature of the Confidential Information and agrees that it shall not reveal
                  or disclose any Confidential Information for any purpose to any other person, firm, corporation or other entity, other
                  than such party's employees with a need to know such Confidential Information to perform employment responsibilities
                  consistent with such party's rights under this Agreement. Each party acknowledges and agrees that in the event of the
                  other party's breach of this Agreement, such party will suffer irreparable injuries not compensated by money damages and
                  therefore shall not have an adequate remedy at law. Accordingly, such party shall be entitled to a preliminary and final
                  injunction without the necessity of posting any bond or undertaking in connection therewith to prevent any further breach
                  of these confidentiality obligations or further unauthorized use of Confidential Information. This remedy is separate and
                  apart from any other remedy the parties may have. A party shall notify the other party immediately upon discovery of any
                  prohibited use or disclosure of the Confidential Information, or any other breach of these confidentiality obligations,
                  and shall fully cooperate with the other party to help the other party regain possession of the Confidential Information
                  and prevent the further prohibited use or disclosure of the Confidential Information.
                </li>
                <li>
                  <span>Intellectual Property.</span>&nbsp;&nbsp;All rights in the Software Application or the Service and any related
                  Derivative Works (as defined by Title 17 of the United States Code) conceived by or produced by BHC or Customer related to
                  the Software Application or the Service shall be owned by BHC. Any Derivative Works of the Software Application or the
                  Service conceived by or produced during the Term of the Agreement shall be owned by BHC. To the extent Customer obtains
                  any interest in or to the Software Application or any Derivative Work, Customer shall assign, and hereby assigns, all
                  rights therein to BHC. Customer shall not: (1) make available nor distribute all or part of the Software Application or
                  the Service to any third party by assignment, sublicense or by any other means; (2) copy, adapt, reverse engineer,
                  decompile, disassemble, or modify, in whole or in part, any of the Software Application or the Service; (3) use the
                  Software Application or the Service to operate in or as a time-sharing provider, service bureau, or the like; or (4) in
                  any way allow third party access to the Software Application or the Service (except pursuant to Terms of Use Agreement
                  described in <span>Section 1.1</span>) or (5) share usernames and passwords.
                </li>
              </ol>
            </li>
            <li>
              <span>Warranty; Limitation of Liability.</span>
              <ol>
                <li>
                  <span>Warranty.</span>&nbsp;&nbsp;BHC warrants that Software Application or the Service will not corrupt the accuracy or
                  completeness of the information transmitted or stored by the Software Application. BHC is not responsible for the accuracy
                  of information, except to the extent the inaccuracy is caused by BHC's negligence or willful misconduct in recording,
                  transmitting or storing information provided by Customer or any insurance carrier. BHC makes no representation or warranty
                  regarding the timing, amount or percentage of Customer's receivables that may be collectible.
                </li>
                <li>
                  <span>Sole and Exclusive Remedy.</span>&nbsp;&nbsp;Upon notification by Customer of any error, issue or concern in
                  connection with the Service or this Agreement, BHC's sole liability, and Customer's sole remedy, will be BHC's use of
                  reasonable efforts to correct such error, issue or concern at no additional cost to Customer. BHC'S LIMITED WARRANTY IS IN
                  LIEU OF ALL LIABILITIES OR OBLIGATIONS OF BHC FOR DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE SERVICES.
                </li>
                <li>
                  <span>Warranty Disclaimer.</span>&nbsp;&nbsp;THE WARRANTY SET FORTH IN THIS SECTION 7 IS A LIMITED WARRANTY AND IT IS THE
                  ONLY WARRANTY MADE BY BHC. BHC EXPRESSLY DISCLAIMS, AND CLIENT HEREBY EXPRESSLY WAIVES, ALL OTHER WARRANTIES EXPRESS OR
                  IMPLIED. EXCEPT FOR THE LIMITED WARRANTY SET FORTH HEREIN, BHC EXPRESSLY DISCLAIMS ANY IMPLIED WARRANTIES, INCLUDING BUT
                  NOT LIMITED TO THOSE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, DUTY TO INVESTIGATE, AND NONINFRINGEMENT. THE
                  SOFTWARE APPLICATION AND THE SERVICES, PROVIDED HEREIN ARE, EXCEPT AS SET FORTH IN <span>SECTION 7.1</span>, PROVIDED
                  AS-IS, WHERE-IS, AS-AVAILABLE AND WITH ALL FAULTS.
                </li>
                <li>
                  <span>Limitation.</span>&nbsp;&nbsp;The aggregate liability of BHC and its licensors to Customer arising from its access
                  to or use of the Software Application, or BHC's provision of the Service in connection therewith, or otherwise in
                  connection with this Agreement or its subject matter, however caused, and on any theory of liability, including without
                  limitation contract, strict liability, negligence and/or other tort, shall in no event exceed the amount of fees that have
                  been paid to BHC from Customer under this Agreement during the twelve (12) months immediately preceding the first event
                  giving rise to such liability. NEITHER PARTY, NOR ANY OF ITS OFFICERS, EMPLOYEES, AFFILIATES, SUBSIDIARIES, AGENTS OR
                  REPRESENTATIVES, SHALL HAVE LIABILITY WITH RESPECT TO ITS OBLIGATIONS UNDER THIS AGREEMENT OR OTHERWISE FOR CONSEQUENTIAL,
                  EXEMPLARY, SPECIAL, INCIDENTAL OR PUNITIVE DAMAGES OF ANY KIND EVEN IF IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES.
                </li>
              </ol>
            </li>
            <li>
              <span>Miscellaneous.</span>
              <ol>
                <li>
                  <span>Entire Agreement.</span>&nbsp;&nbsp;This Agreement, including its Exhibits, contains the entire agreement of the
                  parties with regard to the subject matter of this Agreement and no representations, inducements, promises or agreements,
                  oral or otherwise, between the parties not expressly provided for in this Agreement will be of any force or effect. This
                  Agreement specifically supersedes any prior written or oral agreements, or understandings, between the parties relating to
                  the subject matter of this Agreement.
                </li>
                <li>
                  <span>Binding on Successors; Assignment.</span>&nbsp;&nbsp;Customer may not assign its rights or obligations under this
                  Agreement without the prior written consent of BHC. This Agreement will be binding upon and inure to the benefit of the
                  parties hereto, their respective successors and assigns.
                </li>
                <li>
                  <span>Severability.</span>&nbsp;&nbsp;In the event any one or more of the provisions contained in this Agreement shall,
                  for any reason, be determined to be invalid, illegal, or unenforceable in any respect, such invalidity, illegality, or
                  unenforceability shall not affect any other provision hereof. The parties shall endeavor in good faith negotiations to
                  modify the invalid, illegal or unenforceable provision to the extent necessary to make such provision, valid, legal and
                  enforceable and to cause this Agreement to operate in a fair and equitable manner between them.
                </li>
                <li>
                  <span>No Waiver.</span>&nbsp;&nbsp;No waiver of any provision of this Agreement shall be valid unless in writing and
                  signed by the party granting such waiver, and in no event shall any such waiver be deemed to be a waiver of any other
                  term, covenant or condition of this Agreement nor shall such waiver be deemed effective as to future events.
                </li>
                <li>
                  <span>Force Majeure.</span>&nbsp;&nbsp;Each party acknowledges that performance of duties hereunder may be impeded by
                  occurrences beyond the control of one or both parties. Events such as flood, earthquake, hurricane, tornado, blizzard and
                  other natural disasters; fire, riot, acts of terrorism, war or civil disturbance; strikes by common carriers; extended
                  loss (more than 48 hours) of utilities (except for non-payment); and similar events shall excuse the affected party from
                  performance of services impeded by such event(s). Nevertheless, each party has a duty to use reasonable efforts to prevent
                  or mitigate such impediments.
                </li>
                <li>
                  <span>Relationship of the Parties.</span>&nbsp;&nbsp;BHC's relationship with the Customer under this Agreement is solely
                  that of an independent contractor, and no other relationship is intended to be created by and between the parties hereto.
                  Nothing in the Agreement shall be construed so as to give to BHC any rights as an employee, agent, partner or joint
                  venture participant of, with or in the business of the Customer or to entitle BHC to control in any manner the conduct of
                  the Customer's business. BHC and its employees shall not be regarded as employees of the Customer for any purpose
                  whatsoever. BHC and its employees shall not be entitled to participate in any "fringe benefit" programs including, but not
                  limited to, any pension, life insurance, health insurance or disability insurance programs, now or hereafter available to
                  employees of the Customer. BHC shall be responsible for payment of all employment tax with respect to the compensation
                  payable to employees of BHC, and shall be responsible for the filing of any and all state and federal tax and information
                  returns relating thereto.
                </li>
                <li>
                  <span>Dispute Resolution; Governing Law and Forum.</span>&nbsp;&nbsp;This Agreement and performance hereunder shall be
                  governed by and construed in accordance with the laws of the State of Arizona. The parties agree to attempt to resolve any
                  dispute arising out of or related to this Agreement through good faith negotiation. If the dispute cannot be resolved
                  within thirty (30) days of the initial attempts to so resolve the dispute, either party may submit it to non-binding
                  mediation administered by JAMS, the Resolution Experts under its published non-binding mediation rules in effect as of the
                  beginning of such mediation process in Maricopa County, Arizona. If the matter is not resolved following at least one
                  mediation session, then any and all proceedings relating to the subject matter hereof shall be maintained in the courts of
                  the State of Arizona or the U.S. District Court sitting in Phoenix, Arizona, which courts shall have exclusive
                  jurisdiction for such purpose. For the avoidance of doubt, neither good faith negotiation nor mediation shall be required
                  prior to any action seeking injunctive relief, and any action seeking injunctive relief may be brought in any court having
                  of competent jurisdiction.
                </li>
                <li>
                  <span>Headings.</span>&nbsp;&nbsp;Section headings in this Agreement are solely for the convenience of the parties and not
                  intended to modify the content of the Agreement.
                </li>
                <li>
                  <span>Amendments.</span>&nbsp;&nbsp;Any amendments or changes to this Agreement must be in writing and will not be
                  effective until executed by an authorized officer of BHC and by that person authorized to execute such an instrument on
                  behalf of Customer.
                </li>
                <li>
                  <span>Attorneys' Fees.</span>&nbsp;&nbsp;If BHC must retain counsel to enforce this Agreement with or without suit being
                  instituted; the Customer shall be responsible for reimbursing BHC for reasonable attorneys' fees and costs.
                </li>
                <li>
                  <span>Notices.</span>&nbsp;&nbsp;Any notice or communication required or permitted to be given by the provisions of this
                  Agreement will be effective three (3) business days following its deposit in the U.S. mail directed to the address set
                  forth below on the signature page to this Agreement, or to such other address of which the party has been notified
                  pursuant to this paragraph. Any notice or communication also may be personally delivered or sent via telex, telegram,
                  facsimile, email, or courier service, in which case notice shall be effective upon delivery.
                </li>
                <li>
                  <span>Survival.</span>&nbsp;&nbsp;<span>Sections 3, 4, 6, 7, 8 and 9</span> of this Agreement shall survive the
                  termination or expiration of this Agreement and shall continue to be binding on the parties.
                </li>
              </ol>
            </li>
          </ol>
          <P className="!my-4 !mx-0">
            <strong>Exhibit A:</strong> Business Associate Agreement{' '}
          </P>
        </Section>

        {/* Business Associate Agreement */}
        <Section>
          <Heading is="h2" css={{ margin: '1.75rem 0 1rem' }}>
            Business Associate Agreement
          </Heading>
          <Heading is="h3" size={400}>
            BUSINESS ASSOCIATE AGREEMENT AND QUALIFIED SERVICES ORGANIZATION AGREEMENT
          </Heading>

          <P className="!my-4 !mx-0">
            This Business Associate Agreement ("<strong>BAA</strong>") is made and entered into concurrently with and is an integral part of
            the Software Access Agreement (the "<strong>Primary Agreement</strong>") by and between Client (as defined in the Primary
            Agreement, and Behave Health Corp. ("<strong>BHC</strong>"), and shall be effective upon Client's electronic acceptance of this
            BAA, with reference to the following facts:
          </P>

          <ol className="letters">
            <li>
              BHC and Client have entered into the Primary Agreement whereby BHC will be providing goods and/or services to Client
              pertaining to Client's provision of health care services to various individuals.
            </li>
            <li>
              Client is required to enter into this BAA in order to comply with the federal health care Privacy Rule as defined below and
              federal health care Security Rule as defined below.
            </li>
            <li>
              Client will or may also disclose to BHC "alcohol abuse patient records" and "drug abuse patient records" as those terms are
              defined in 42 C.F.R. Part 2 (the "<strong>Confidentiality Rule</strong>").
            </li>
            <li>
              BHC acknowledges that it is a "qualified service organization" of Client as that term is defined in 42 C.F.R. § 2.11, and
              that, in receiving, storing, processing or otherwise dealing with any alcohol or drug abuse patient records from Client, it is
              fully bound by the Confidentiality Rule.
            </li>
          </ol>

          <ol>
            <li>
              <span>Definitions.</span>
              <ol>
                <li>
                  "<strong>Confidentiality Rule</strong>" shall mean the regulations governing "Confidentiality of Alcohol and Drug Abuse
                  Patient Records" codified at 42 C.F.R. Part 2, as amended.
                </li>
                <li>
                  "<strong>Designated Record Set</strong>" shall mean a group of records maintained by or for Client that is (i) the medical
                  records and billing records about individuals maintained by or for Client, (ii) the enrollment, payment, claims
                  adjudication, and case or medical management record systems maintained by or for Client or a health plan; or (iii) used,
                  in whole or in part, by or for Client to make decisions about individuals. As used herein, the term "Record" means any
                  item, collection, or grouping of information that includes Protected Health Information and is maintained, collected,
                  used, or disseminated by or for Client.
                </li>
                <li>
                  "<strong>Individual</strong>" shall have the same meaning as the term "individual" in 45 CFR § 160.103 and shall include a
                  person who qualifies as a personal representative in accordance with 45 CFR § 164.502(g).
                </li>
                <li>
                  "<strong>Privacy Rule</strong>" shall mean the Standards for Privacy of Individually Identifiable Health Information at 45
                  CFR Part 160 and Part 164, Subparts A and E.
                </li>
                <li>
                  "<strong>Protected Health Information</strong>" shall have the same meaning as the term "protected health information" in
                  45 CFR § 160.103, limited to the information created or received by BHC from or on behalf of Client. "Electronic Protected
                  Health Information" shall be a subset of Protected Health Information as defined in 45 CFR 160.103.
                </li>
                <li>
                  "<strong>Regulations</strong>" shall mean collectively the Privacy Rule, the Security Rule, any regulations promulgated
                  pursuant to the American Recovery and Reinvestment Act of 2009 (P.L. 111-5), the Confidentiality Rule, and any amendments
                  to the foregoing.
                </li>
                <li>
                  "<strong>Required By Law</strong>" shall have the same meaning as the term "required by law" in 45 CFR § 164.103.
                </li>
                <li>
                  "<strong>Secretary</strong>" shall mean the Secretary of the Department of Health and Human Services or his designee.
                </li>
                <li>
                  "<strong>Security Incident</strong>" shall have the same meaning as the term "security incident" in 45 CFR § 164.304,
                  which generally means the attempted or successful unauthorized access, use, disclosure, modification or destruction of
                  information or interference with system operations in an information system.
                </li>
                <li>
                  "<strong>Security Rule</strong>" shall mean the Security Standards for protection of Electronic Protected Health
                  Information at 45 CFR Part 164, Subparts A and C.
                </li>
                <li>
                  "<strong>Transaction Standards</strong>" shall mean the Standards for Electronic Transactions, 45 CFR §§ 160 and 162.
                </li>
                <li>
                  <span>Additional Terms</span>.&nbsp;&nbsp;Terms used, but not otherwise defined, in this BAA shall have the same meaning
                  as those terms in the Regulations.
                </li>
              </ol>
            </li>
            <li>
              <span>Obligations and Activities of BHC.</span>
              <ol>
                <li>
                  BHC agrees to not use or disclose Protected Health Information other than as permitted or required by the BAA or as
                  Required By Law.
                </li>
                <li>
                  BHC agrees to use reasonable administrative, physical, and technical safeguards to prevent use or disclosure of the
                  Protected Health Information other than as provided for by this BAA.
                </li>
                <li>
                  BHC agrees to mitigate, to the extent practicable, any harmful effect that is known to BHC of a use or disclosure of
                  Protected Health Information by BHC in violation of the requirements of this BAA.
                </li>
                <li>
                  BHC agrees to report to Client any use or disclosure of the Protected Health Information not provided for by this BAA of
                  which it becomes aware, including breaches of unsecured Protected Health Information as required by 45 CFR § 164.410.
                </li>
                <li>
                  BHC agrees, in accordance with 45 CFR § 164.502(e)(1)(ii), to ensure that any subcontractors that create receive, maintain
                  or transmit Protected Health Information on behalf of BHC agree to the same or substantially similar restrictions and
                  conditions that apply through this BAA to BHC with respect to such information.
                </li>
                <li>
                  BHC agrees to provide access, at the request of Client, on not less than 48 hours advance notice and during BHC's regular
                  business hours, to Protected Health Information in a Designated Record Set, to Client in order to meet the requirements
                  under 45 CFR § 164.524. BHC agrees to make any amendment(s) to Protected Health Information in a Designated Record Set
                  that the Client directs or agrees to pursuant to 45 CFR § 164.526 at the request of Client, and in the time and manner as
                  mutually agreed by the parties. In the event an Individual requests such access or amendment directly from BHC, BHC shall,
                  in its sole discretion, either provide the requested access or amendment or promptly forward such request to Client. Any
                  denials of requests by Individuals for access or amendment shall be the responsibility of Client.
                </li>
                <li>
                  To the extent that BHC is to carry out an obligation of Client under the Privacy Rule, BHC agrees to comply with the
                  requirements of the Privacy Rule that apply to Client in the performance of such obligation.
                </li>
                <li>
                  BHC agrees to make internal practices, books, and records, including policies and procedures and Protected Health
                  Information, relating to the use and disclosure of Protected Health Information received from, or created or received by
                  BHC on behalf of, Client available to the Secretary, in a time and manner designated by the Secretary, for purposes of the
                  Secretary determining Client's compliance with the Privacy Rule.
                </li>
                <li>
                  BHC agrees to document such disclosures of Protected Health Information made by BHC and information related to such
                  disclosures as would be required for Client to respond to a request by an Individual for an accounting of disclosures of
                  Protected Health Information in accordance with 45 CFR § 164.528 and other HIPAA requirements.
                </li>
                <li>
                  BHC agrees to provide to Client, on not less than 48 hours advance notice and during BHC's regular business hours,
                  information collected in accordance with Section 2.i. of this BAA, to permit Client to respond to a request by an
                  Individual for an accounting of disclosures of Protected Health Information in accordance with 45 CFR § 164.528. In the
                  event an Individual requests an accounting of disclosures of Protected Health Information directly from BHC, BHC shall
                  promptly forward such request to Client and provide the information on BHC's disclosures needed by Client to respond to
                  the Individual's request.
                </li>
                <li>
                  BHC hereby represents and warrants that to the extent it is transmitting any of the HIPAA Transactions for Client, BHC
                  will comply with the Transactions Standards and require any agent or subcontractor to do the same. With respect to any
                  such Transactions, neither Party shall: (i) change the definition, data, condition, or use of a data element or segment in
                  a Transaction Standard; (ii) add any data elements or segments to the maximum defined data set; (iii) use any code or data
                  elements that are either marked "not used" in the Transaction Standard's implementation specification or are not in the
                  Transaction Standard's implementation specification(s); or (iv) change the meaning or intent of the Transaction Standard's
                  implementation specification(s).
                </li>
                <li>
                  With respect to Electronic Protected Health Information, BHC will:
                  <ol>
                    <li>
                      Comply with the applicable requirements of the Security Rule, including implementing administrative, physical, and
                      technical safeguards that reasonably and appropriately protect the confidentiality, integrity, and availability of the
                      Electronic Protected Health Information it creates, receives, maintains, or transmits on behalf of Client;
                    </li>
                    <li>
                      In accordance with 45 C.F.R. § 164.308(b)(2), ensure that any subcontractors that create, receive, maintain or
                      transmit Electronic Protected Health Information on behalf of BHC agree to comply with the applicable requirements of
                      the Security Rule by entering into a contract or other arrangement that complies with 45 CFR § 164.314;
                    </li>
                    <li>
                      Report to Client any Security Incident of which BHC becomes aware, including breaches of unsecured Protected Health
                      Information as required by 45 CFR § 164.410.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <span>Permitted Uses and Disclosures by BHC.</span>
              <ol>
                <li>
                  Except as otherwise limited in this BAA, BHC may use or disclose Protected Health Information to perform functions,
                  activities, or services for, or on behalf of, Client as specified in the Primary Agreement, provided that such use or
                  disclosure would not violate the Privacy Rule or the Confidentiality Rule if done by Client or the minimum necessary
                  policies and procedures of the Client.
                </li>
              </ol>
            </li>
            <li>
              <span>Specific Use and Disclosure Provisions.</span>
              <ol>
                <li>
                  Except as otherwise limited in this BAA, BHC may use Protected Health Information for the proper management and
                  administration of the BHC or to carry out the legal responsibilities of the BHC.
                </li>
                <li>
                  Except as otherwise limited in this BAA, BHC may disclose Protected Health Information for the proper management and
                  administration of the BHC, or to carry out the legal responsibilities of the BHC, provided that disclosures are Required
                  By Law, or BHC obtains reasonable assurances from the person to whom the information is disclosed that it will remain
                  confidential and used or further disclosed only as Required By Law or for the purpose for which it was disclosed to the
                  person, and the person notifies the BHC of any instances of which it is aware in which the confidentiality of the
                  information has been breached.
                </li>
                <li>
                  Except as otherwise limited in this BAA, BHC may use Protected Health Information to provide data aggregation services to
                  Client as permitted by 45 CFR § 164.504(e)(2)(i)(B).
                </li>
                <li>
                  BHC may use Protected Health Information to report violations of law to appropriate Federal and State authorities,
                  consistent with 45 CFR § 164.502(j)(1), except that BHC may not use Protected Health Information or any other data to
                  report violations of the law in a manner inconsistent with the Confidentiality Rule unless otherwise required by operation
                  of law.
                </li>
                <li>
                  BHC may de-identify Protected Health Information provided that de-identification conforms to the requirements of the
                  Privacy Rule. The parties acknowledge and agree that such de-identified data is not subject to the terms of this BAA.
                </li>
                <li>
                  Subject to the exceptions described in 45 CFR § 164.502(b)(2), BHC must make reasonable efforts to limit Protected Health
                  Information to the minimum necessary to accomplish the intended purpose of a use, disclosure or request otherwise
                  permitted by this BAA, as required by the Privacy Rule.
                </li>
                <li>
                  BHC shall following the discovery of a breach of unsecured Protected Health Information, notify the Client of the breach.
                  BHC shall provide the notification without unreasonable delay and in no case later than 30 calendar days after discovery
                  of a breach. The notification shall include, to the extent possible, the identification of each individual whose unsecured
                  PHI has been, or is reasonably believed by the BHC to have been, accessed, acquired, used, or disclosed during the breach.
                  BHC shall also provide the Client with any other available information that the Client is required to provide to the
                  individual(s) who are the subject of the breach. BHC shall cooperate with the Client to take (i) prompt corrective action
                  to cure any such breach and (ii) any action required by the Regulations and applicable federal or state laws, rules or
                  regulations as a result of such breach. If the BHC solely causes the breach, BHC shall pay any required notification costs
                  for purposes of complying with the regulations or any other applicable federal or state laws, rules or regulations.
                </li>
                <li>
                  BHC acknowledges that in receiving, storing, processing or otherwise dealing with any patient records relating to Client's
                  provision of health care services to various individuals, it is fully bound by the applicable provisions of the
                  Confidentiality Rule and agrees to comply with such provisions of the Confidentiality Rule. If the consent of an
                  Individual or patient is required prior to the use or disclosure of Protected Health Information received by BHC from or
                  on behalf of Client or other information under the Confidentiality Rule, BHC specifically agrees not to use or disclose
                  the information without the required consent; provided however, subject to the Primary Agreement, the parties agree
                  hereunder that such consent shall be deemed given unless Client otherwise notifies BHC in writing.
                </li>
                <li>
                  As required by applicable law, the BHC agrees to resist any judicial proceedings to obtain access to patient records
                  except as permitted by the applicable provisions of the Confidentiality Rule or as Required by Law.
                </li>
                <li>
                  As required by applicable law, the BHC agrees to securely store written records subject to the Confidentiality Rule as
                  required by 42 C.F.R. § 2.16.
                </li>
                <li>
                  Despite anything to the contrary in this Agreement, BHC may use and disclose PHI only if each such use and disclosure is
                  in compliance with each applicable requirement of the Confidentiality Rule.
                </li>
              </ol>
            </li>
            <li>
              <span>Obligations of Client.</span>
              <ol>
                <li>
                  Client shall provide BHC with written notice of any limitation(s) in its notice of privacy practices of Client in
                  accordance with 45 CFR § 164.520, to the extent that such limitation may affect BHC's use or disclosure of Protected
                  Health Information.
                </li>
                <li>
                  Client shall provide BHC with written notice of any changes in, or revocation of, permission by Individual to use or
                  disclose Protected Health Information, to the extent that such changes may affect BHC's use or disclosure of Protected
                  Health Information.
                </li>
                <li>
                  Client shall provide BHC with written notice of any restriction on the use or disclosure of Protected Health Information
                  that Client has agreed to or must comply with in accordance with the Confidentiality Rule and/or 45 CFR § 164.522,
                  including but not limited to a restriction under 45 CFR § 164.522(a)(1)(vi), to the extent that such restriction may
                  affect BHC's use or disclosure of Protected Health Information. Client will defend, indemnify and hold harmless BHC from
                  and against any claims, causes of action, liabilities, losses, penalties, demands, costs or expenses (including reasonable
                  attorneys' fees) arising out of or in connection with BHC's noncompliance with a restriction under the Confidentiality
                  Rule and/or 45 CFR § 164.522 if caused solely by Client's failure to provide BHC with written notice of same.
                </li>
              </ol>
            </li>
            <li>
              <span>Permissible Requests by Client.</span>
              <ol>
                <li>
                  Client shall not request BHC to use or disclose Protected Health Information in any manner that would not be permissible
                  under the Privacy Rule or the Confidentiality Rule if done by Client, except to the extent that BHC is permitted to use or
                  disclose protected health information for data aggregation or management and administrative activities of BHC or to carry
                  out the legal responsibilities of the BHC, under the terms of the Primary Agreement.
                </li>
              </ol>
            </li>
            <li>
              <span>Term and Termination.</span>
              <ol>
                <li>
                  <span>Term.</span> The Term of this BAA shall be effective as of the Effective Date of the Primary Agreement, and shall
                  terminate when all of the Protected Health Information provided by Client to BHC, or created or received by BHC on behalf
                  of Client, is destroyed or returned to Client, or, if it is infeasible to return or destroy Protected Health Information,
                  protections are extended to such information, in accordance with the termination provisions in this Section.
                </li>
                <li>
                  <span>Termination for Cause.</span> Upon Client's knowledge of a material breach by BHC, Client shall either:
                  <ol>
                    <li>
                      Provide an opportunity for BHC to cure the breach for not less than 30 days, and end the violation and terminate this
                      BAA and the Primary Agreement if BHC does not cure the breach or end the violation within the foregoing time period;
                    </li>
                    <li>
                      Immediately terminate this BAA and the Primary Agreement if BHC has breached a material term of this BAA and cure is
                      not possible; or
                    </li>
                    <li>If neither termination nor cure are feasible, Client shall report the violation to the Secretary.</li>
                  </ol>
                </li>
                <li>
                  <span>Effect of Termination.</span>
                  <ol>
                    <li>
                      Except as provided in paragraph (ii) of this section, upon termination of this BAA, for any reason, BHC shall return
                      or destroy all Protected Health Information received from Client, or created or received by BHC on behalf of Client.
                      BHC shall retain no copies of the Protected Health Information.
                    </li>
                    <li>
                      In the event that BHC determines that returning or destroying the Protected Health Information is infeasible, BHC
                      shall provide to Client notification of the conditions that make return or destruction infeasible. Upon BHC's
                      determination that return or destruction of Protected Health Information is infeasible, BHC shall extend the
                      protections of this BAA to such Protected Health Information and limit further uses and disclosures of such Protected
                      Health Information to those purposes that make the return or destruction infeasible, for so long as BHC maintains such
                      Protected Health Information.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <span>Miscellaneous.</span>
              <ol>
                <li>
                  <span>Regulatory References.</span>&nbsp;&nbsp;A reference in this BAA to a section in the Regulations means the section
                  as in effect or as amended.
                </li>
                <li>
                  <span>Amendment.</span>&nbsp;&nbsp;The parties agree to take such action as is necessary to amend this BAA from time to
                  time as is necessary for Client to comply with the requirements of the Regulations and the Health Insurance Portability
                  and Accountability Act of 1996, Pub. L. No. 104-191.
                </li>
                <li>
                  <span>Survival.</span>&nbsp;&nbsp;The respective rights and obligations of BHC under Section 7.c.ii. of this BAA shall
                  survive the termination of this BAA.
                </li>
                <li>
                  <span>Interpretation.</span>&nbsp;&nbsp;Any ambiguity in this BAA shall be resolved to permit Client to comply with the
                  Regulations.
                </li>
                <li>
                  <span>Notices.</span>&nbsp;&nbsp;All notices contemplated by the terms of this BAA shall be in writing and shall be given
                  and effective as provided in the Primary Agreement.
                </li>
                <li>
                  <span>No Third Party Beneficiaries.</span>&nbsp;&nbsp;It is the intent of the Parties that this BAA is to be effective
                  only in regards to their rights and obligations with respect to each other. It is expressly not the intent of the Parties
                  to create any independent rights in any third party or to make any third-party beneficiary of this BAA and no privity of
                  contract shall exist between third parties and each party.
                </li>
              </ol>
            </li>
          </ol>
        </Section>

        {/* Software Terms of Use Agreement */}
        <Section>
          <Heading is="h2" css={{ margin: '1.75rem 0 1rem' }}>
            Software Terms of Use Agreement
          </Heading>
          <Heading is="h3" size={400}>
            BEHAVEHEALTH CORP. SOFTWARE – TERMS OF USE AGREEMENT
          </Heading>
          <P className="!my-4 !mx-0">
            By using the Product (defined below), you are agreeing to be bound by this Agreement, and this Agreement this will become a
            legally binding agreement between you and Behave Health Corp. ("<strong>BHC</strong>").
          </P>
          <P className="!my-4 !mx-0">
            By using the Product, you ("<strong>User</strong>") represent and warrant that you are authorized to enter into this Agreement
            as an individual and as a representative of the organization ("<strong>Institutional Client</strong>") for which you are using
            the Product, either as an employee or an independent contractor.
          </P>
          <P className="!my-4 !mx-0">IF USER DOES NOT AGREE, USER MAY NOT USE THE PRODUCT.</P>

          <ol>
            <li>
              Definitions
              <ol>
                <li>
                  <span>BHC Proprietary Items.</span>&nbsp;&nbsp;The term "<strong>BHC Proprietary Item</strong>(s)" shall mean the Product
                  and all Derivatives thereof, any patents, copyrighted materials, trade secrets, proprietary data, know-how, ideas,
                  concepts, methodologies, processes, practices, techniques, written products, materials, training materials, and other
                  Documentation, tools, procedures, and other intellectual capital that are proprietary to BHC.
                </li>
                <li>
                  <span>Derivatives.</span>&nbsp;&nbsp;The term "<strong>Derivative</strong>" shall mean: (i) for copyrightable or
                  copyrighted material, any derivative work thereof, including any translation, abridgement, revision or other form in which
                  an existing work may be recast, transformed or adapted; (ii) for patentable or patented material, any improvement thereon;
                  and (iii) for material which is protected by trade secret laws, any new material derived from such existing trade secret
                  material, including new material which may be protected by copyright, patent and/or trade secret laws.
                </li>
                <li>
                  <span>Designated Systems.</span>&nbsp;&nbsp;The term "<strong>Designated Systems</strong>" shall mean, individually and
                  collectively, mobile devices or standalone computer system(s) controlled by User where the Product is installed or from
                  which the Product is accessed pursuant to this Agreement.
                </li>
                <li>
                  Documentation. The term "<strong>Documentation</strong>" shall mean any and all written or electronic documentation made
                  available to User with respect to the Product, including, without limitation, resources related to the installation access
                  to, properties, or use of the Product.
                </li>
                <li>
                  <span>Product.</span>&nbsp;&nbsp;The term "<strong>Product</strong>" shall mean the computer program(s) (software) product
                  that accompanies this Agreement, written or provided as "software as a service" or "SaaS", and/or in object code,
                  intermediate code, source code, or other programming methodology, in any media, plus all related materials, including,
                  without limitation, all definition files, configuration scripts, usage scripts, and modules used to control compilation,
                  installation, execution, and management of the Product, and all related Internet-based services (if any), related BHC
                  Proprietary Items and Documentation received by User from BHC pursuant to this Agreement, either before or after the
                  effective date of this Agreement.
                </li>
                <li>
                  <span>Proprietary Information.</span>&nbsp;&nbsp;The term "<strong>Proprietary Information</strong>" shall mean documented
                  information or software provided by BHC to User or otherwise made available in connection with or as part of the Product,
                  which the User reasonably should know to be proprietary due to its nature or the circumstances surrounding its disclosure.
                </li>
              </ol>
            </li>
            <li>
              Access
              <ol>
                <li>
                  <span>Grant.</span>&nbsp;&nbsp;In accordance with the terms herein, BHC grants to User a revocable, non-assignable,
                  non-transferable right to access the Product on behalf of Institutional Client, subject to the restrictions set forth in
                  this Agreement, for the Term of the Software Access and Billing Services Agreement between BHC and the Institutional
                  Client.
                </li>
                <li>
                  <span>Scope of Use.</span>&nbsp;&nbsp;The right of access granted herein only gives User the right:
                  <ol>
                    <li>to access and use the Product internally within User’s organization;</li>
                    <li>
                      to access and use only the portions and features of the Product which User’s organization designates as being
                      necessary or appropriate for User, as determined by the access level granted to User by the organization;
                    </li>
                    <li>
                      to use the Product solely in User’s capacity as an employee, agent or independent contractor of the Institutional
                      Client;
                    </li>
                    <li>
                      to use the Documentation solely for purposes of supporting User’s use of the Product as permitted under this
                      Agreement; and
                    </li>
                    <li>to use any training materials solely for purposes of supporting User.</li>
                  </ol>
                </li>
                <li>
                  <span>User Restrictions.</span>&nbsp;&nbsp;User is responsible for: (a) ensuring that all use of the Product by User, and
                  by anyone else who obtains access to the Product through User, is in compliance with the terms and conditions of this
                  Agreement; (b) monitoring, controlling access to and maintaining the strict confidentiality of the Product; (c) promptly
                  notifying BHC if User has reason to believe that there has been or will be any use, duplication or dissemination of the
                  Product that is not in accordance with the terms of this Agreement or any other breach of confidentiality with respect to
                  the Product.
                </li>
                <li>
                  <span>Further Restrictions.</span>&nbsp;&nbsp;User acknowledges BHC's representation that the Product includes trade
                  secrets and other valuable BHC Proprietary Items. Except as expressly authorized herein, User will not do any of the
                  following:
                  <ol>
                    <li>
                      sublicense, sell, lease, let, rent, publish, use for commercial time sharing or bureau use, or otherwise transfer the
                      Product, or any portion or copy thereof, to any third party;
                    </li>
                    <li>decompile, disassemble, reverse engineer, or otherwise attempt to derive any software component of the Product;</li>
                    <li>take any action that jeopardizes BHC's proprietary rights in the Product;</li>
                    <li>acquire or seek to acquire any ownership interest in or to the Product or any component thereof;</li>
                    <li>
                      create a Derivative of the Product, except as required for its own internal use of the Product in accordance with the
                      terms of this Agreement;
                    </li>
                    <li>provide any third party (including service providers or consultants) remote access to the Product.</li>
                  </ol>
                </li>
                <li>
                  User shall further use its best efforts to prevent its employees, contractors and anyone else who obtains access to the
                  Product through User from attempting to do any of the foregoing actions.
                </li>
              </ol>
            </li>
            <li>
              Product Ownership and Confidentiality
              <ol>
                <li>
                  <span>Ownership.</span>&nbsp;&nbsp;BHC owns and retains all right, title and interest in and to the Product and any other
                  programs developed hereunder (including, without limitation, all translations, compilations, partial copies with
                  modifications and updated works), all copies, modifications and Derivatives thereof and all intellectual property rights
                  therein, other than any rights expressly granted to User in this Agreement. Without limiting the generality of the
                  foregoing, User acknowledges and agrees that the Product (i) is, and shall be, owned solely by BHC, and (ii) is protected
                  by copyright and other intellectual property laws. Except as expressly provided herein, nothing in this Agreement shall be
                  interpreted as granting to User or any other entity, any right, title or interest in or to the Product. User agrees to not
                  contest BHC's intellectual property rights in the Product.
                </li>
                <li>
                  <span>Duties of User.</span>&nbsp;&nbsp;Except as otherwise provided herein, User will:
                  <ol>
                    <li>
                      afford BHC's Proprietary Information the same degree of protection against unauthorized use or disclosure as User
                      normally provides for its own or the Institutional Client's confidential and Proprietary Information, subject to a
                      minimum standard of reasonable diligence and protection;
                    </li>
                    <li>
                      use and disclose BHC's confidential and/or Proprietary Information only in the course of performing its obligations
                      pursuant to this Agreement; and
                    </li>
                    <li>
                      disclose BHC's confidential and/or Proprietary Information within its organization only to those of its employees and
                      contractors who need to know it to perform the obligations of User and have a legal duty to protect it comparable to
                      that of User.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>Equitable Relief.</span>&nbsp;&nbsp;User recognizes and acknowledges that any use or disclosure of BHC's
                  confidential and/or Proprietary Information in a manner inconsistent with the provisions of this Agreement may cause BHC
                  irreparable damage for which remedies at law may be inadequate and User agrees that in any request to a court of competent
                  jurisdiction by BHC for injunctive or other equitable relief seeking to restrain such use or disclosure, User will not
                  maintain that such remedy is not appropriate under the circumstances.
                </li>
                <li>
                  <span>Confidentiality Term.</span>&nbsp;&nbsp;User's obligations under this Article 3 shall survive the expiration or
                  earlier termination of this Agreement.
                </li>
              </ol>
            </li>
            <li>
              Disclaimer
              <ol>
                <li>
                  <span>AS-IS.</span>&nbsp;&nbsp;User acknowledges and agrees that access to the Product is being given hereunder on an
                  AS-IS, WHERE-IS basis, and "WITH ALL FAULTS."
                </li>
                <li>
                  <span>No Obligation.</span>&nbsp;&nbsp;BHC SHALL HAVE NO OBLIGATION TO DEFEND OR HOLD USER HARMLESS IF ANY ALLEGED
                  INFRINGEMENT OF PROPRIETARY RIGHTS IS BASED UPON (i) USE OF THE PRODUCT IN OTHER THAN ITS SPECIFIED OPERATING ENVIRONMENT
                  OR IN COMBINATION WITH ANY EQUIPMENT, DEVICES, OR SOFTWARE NOT SPECIFIED IN THE DOCUMENTATION AS SUITABLE FOR SUCH USE;
                  (ii) USE OF THE PRODUCT IN A MANNER FOR WHICH IT WAS NOT INTENDED; (iii) USE OF OTHER THAN THE MOST CURRENT RELEASE OF THE
                  PRODUCT IF SUCH CLAIM WOULD HAVE BEEN PREVENTED BY THE USE OF SUCH RELEASE OR (iv) USE OF THE PRODUCT WITH MEDIA, GRAPHICS
                  OR CONTENT SUPPLIED BY USER.
                </li>
                <li>
                  <span>User Obligations.</span>&nbsp;&nbsp;User agrees that it is responsible for ensuring that any third party usage of
                  the Product is in accordance with the terms and conditions of this Agreement. User agrees that it has all rights to, or
                  obtained licenses for, all intellectual property and other rights to any media, graphics or content provided by User for
                  use with the Product, if applicable. Therefore, User agrees to indemnify and hold harmless BHC, and any shareholder,
                  director, officer, agent or employee thereof, from any and all liabilities, damages, costs and expenses, including
                  reasonable attorneys' fees, incurred by any such party as a result of any third-party claims arising out of or related to
                  any third party usage of the Product that is not in accordance with this Agreement, or any infringement or
                  misappropriation of third party intellectual property rights media, graphics or content provided by User for use with the
                  Product.
                </li>
                <li>
                  <span>Limitation of Liability.</span>&nbsp;&nbsp;IN NO EVENT WILL BHC BE LIABLE TO USER OR ANY THIRD PARTY FOR ANY USE,
                  INTERRUPTION, DELAY, OR INABILITY TO USE THE PRODUCT; LOST REVENUES OR PROFITS; DELAYS, INTERRUPTION, OR LOSS OF SERVICES,
                  BUSINESS, OR GOODWILL; LOSS OR CORRUPTION OF DATA; LOSS RESULTING FROM SYSTEM OR SYSTEM SERVICE FAILURE, MALFUNCTION, OR
                  SHUTDOWN; FAILURE TO ACCURATELY TRANSFER, READ, OR TRANSMIT INFORMATION; FAILURE TO UPDATE OR PROVIDE CORRECT INFORMATION;
                  SYSTEM INCOMPATIBILITY OR PROVISION OF INCORRECT COMPATIBILITY INFORMATION; OR BREACHES IN SYSTEM SECURITY; OR FOR ANY
                  CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES, WHETHER ARISING OUT OF OR IN CONNECTION WITH
                  THIS AGREEMENT, BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE
                  FORESEEABLE AND WHETHER OR NOT BHC WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </li>
              </ol>
            </li>
            <li>
              Termination
              <ol>
                <li>
                  <span>BHC Termination.</span>&nbsp;&nbsp;BHC may immediately terminate this Agreement in the event that User violates any
                  provision of this Agreement; upon termination of User's relationship with the Institutional Client; or upon termination of
                  the Institutional Client's Software Access and Billing Services Agreement.
                </li>
                <li>
                  <span>Rights upon Termination.</span>&nbsp;&nbsp;In the event of termination of the agreement with Institutional Client
                  for any reason, BHC shall have the right, at any time, to terminate this Agreement and require or take immediate
                  possession of the Product and Documentation and all copies wherever located. BHC shall have the right upon termination to
                  immediately discontinue User's access to the Product. Termination shall not relieve User of its obligations regarding
                  confidentiality of the Product.
                </li>
                <li>
                  <span>User Termination.</span>&nbsp;&nbsp;User may terminate this Agreement at any time provided User permanently
                  discontinues use of the Product. Termination shall not terminate any obligations of User hereunder that by their terms
                  survive termination, expiration or cancellation of this Agreement or that must otherwise survive termination, expiration
                  or cancellation to give effect to such terms.
                </li>
              </ol>
            </li>
            <li>
              Miscellaneous Provisions
              <ol>
                <li>
                  <span>Amendment.</span>&nbsp;&nbsp;This Agreement may be amended at any time and from time to time by BHC, upon notice to
                  User.
                </li>
                <li>
                  <span>Export Restrictions.</span>&nbsp;&nbsp;User acknowledges that the Product, or portions thereof, are subject to U.S.
                  export jurisdiction. User agrees to comply with all international and national laws that apply to the Product, including
                  the U.S. Export Administration Act and Regulations, destination restrictions issued by the U.S. and other governments.
                </li>
                <li>
                  <span>Additional Software/Services.</span>&nbsp;&nbsp;This Agreement applies to any updates, supplements, add-on
                  components, and Internet-based services related to the Product that BHC may provide to User under this Agreement, or in
                  accordance with BHC's then current maintenance and support policies, unless such updates, supplements, add-on components
                  or services are provided under a separate agreement. User may use such updates and upgrades only in conjunction with the
                  Product. Access to the Product and all updates and upgrades are provided as a single product and such updates and upgrades
                  may not be separated from the Product to exceed the scope of User's original rights.
                </li>
                <li>
                  <span>Consent to Use of Data.</span>&nbsp;&nbsp;User hereby agrees that BHC may collect and use technical information
                  gathered as part of any Product support services that may be provided to User, if any, in order to improve BHC's products,
                  including the Product, create new products, or to provide customized services or technologies. BHC will not disclose such
                  information in a form that identifies User.
                </li>
                <li>
                  <span>Protected Health Information.</span>&nbsp;&nbsp;Federal law and regulations governing the privacy of certain health
                  information would require a Business Associate Agreement between BHC and the Institutional Client providing health care
                  services and storing and transmitting personal health information through use of the Product. User will not enter,
                  transmit, or store protected health information under HIPAA unless a Business Associate Agreement has been executed
                  between Institutional Client and BHC.
                </li>
                <li>
                  <span>No Conflict.</span>&nbsp;&nbsp;Notwithstanding anything to the contrary contained herein or in any other agreement,
                  exhibit, work order or statement of work ("<strong>Other Agreements</strong>") to which BHC or User are parties or are to
                  become parties contemporaneously herewith, User hereby waives any and all claims, of any kind whatsoever, to ownership of
                  the BHC Proprietary Items, including specifically any such claims arising under the Other Agreements, and acknowledges
                  that User's rights shall arise solely by virtue of the grants contained herein.
                </li>
                <li>
                  <span>Notice.</span>&nbsp;&nbsp;Notices from User to BHC must be in writing and addressed to BHC at:
                  <P className="!my-4 !mx-0">
                    BehaveHealth Corp.
                    <br />
                    548 MARKET ST NUM 24019
                    <br />
                    SAN FRANCISCO, CA 94104
                    <br />
                    Attention: Legal Notice
                    <br />
                  </P>
                </li>
                <li>
                  Any notice or communication required or permitted to be given by the provisions of this Agreement will be effective upon
                  delivery. Any notice or communication may be sent through U.S. mail, personally delivered or sent via telex, telegram,
                  facsimile, email, or courier service, in which case notice shall be effective upon delivery.
                </li>
                <li>
                  <span>Governing Law.</span>&nbsp;&nbsp;This Agreement shall be construed in accordance with and governed by the laws of
                  the State of Arizona. User and BHC agree to attempt to resolve any dispute arising out of or related to this Agreement
                  through good faith negotiation. If the dispute cannot be resolved within thirty (30) days of the initial attempts to so
                  resolve the dispute, either party may submit it to non-binding mediation administered by JAMS, the Resolution Experts
                  under its published non-binding mediation rules in effect as of the beginning of such mediation process in Maricopa
                  County, Arizona. If the matter is not resolved following at least one mediation session, then any and all proceedings
                  relating to the subject matter hereof shall be maintained in the courts of the State of Arizona or the U.S. District Court
                  sitting in Phoenix, Arizona, which courts shall have exclusive jurisdiction for such purpose. For the avoidance of doubt,
                  neither good faith negotiation nor mediation shall be required prior to any action seeking injunctive relief may be
                  brought in any court having competent jurisdiction.
                </li>
                <li>
                  <span>Limitation.</span>&nbsp;&nbsp;No action, regardless of form, arising out of or related to this Agreement may be
                  brought by User more than one year after the cause of action has arisen.
                </li>
                <li>
                  <span>Unenforceability.</span>&nbsp;&nbsp;If any provision of this Agreement shall be adjudged by any court of competent
                  jurisdiction to be unenforceable or invalid, that provision shall be modified to the minimum extent necessary to achieve
                  the purpose originally intended, if possible, and the remaining provisions of this Agreement shall remain in full force
                  and effect and enforceable.
                </li>
                <li>
                  <span>Assignment.</span>&nbsp;&nbsp;User may not assign or sub-license, or otherwise grant rights of access to the
                  Product, by operation of law or otherwise, without the prior written consent of BHC, its rights, duties or obligations
                  under this Agreement to any person or entity, in whole or in part.
                </li>
                <li>
                  <span>Enforcement.</span>&nbsp;&nbsp;The failure or delay of BHC to enforce at any time any provision of this Agreement
                  shall not be construed to be a waiver of such provision, nor in any way to affect the validity of this Agreement or any
                  part hereof or the right of BHC thereafter to enforce each and every such provision.
                </li>
                <li>
                  <span>No Third Party Beneficiaries.</span>&nbsp;&nbsp;Nothing contained in this Agreement will be deemed to create, or be
                  construed as creating, any third party beneficiary right of action upon any third party or entity whatsoever, in any
                  manner whatsoever.
                </li>
                <li>
                  <span>Entire Agreement.</span>&nbsp;&nbsp;Each party acknowledges that it has read this Agreement, understands it, and
                  agrees to be bound by its terms, and further agrees that this Agreement is the complete and exclusive statement of the
                  agreement between the parties, which supersedes and merges all prior and contemporaneous proposals, understandings and all
                  other agreements, whether verbal or written, express or implied, relating to the subject matter herein. Without limiting
                  the generality of the foregoing, no term or condition contained in any User document that is inconsistent with this
                  Agreement shall be binding upon BHC, regardless whether such purchase order or other document has already been or is
                  hereafter acknowledged by BHC, unless such term or condition is expressly agreed in writing by both parties hereto. This
                  Agreement may not be modified or altered except by a written instrument duly executed by the authorized representatives of
                  both parties hereto.
                </li>
              </ol>
            </li>
          </ol>

          <P className="!my-4 !mx-0">
            <strong>
              Your use of this Product confirms that you accept and agree to be bound by all of the terms and conditions of this Agreement.
              Do not proceed with use of the Product if you are not authorized to use this Product by the Institutional Client or if you do
              not agree to the terms and conditions of this Agreement.
            </strong>
          </P>
        </Section>
      </Card>
    </Page>
  )
}

export default withPageError(LegalAgreement)
