import React from 'react'

import { apiGet } from '@behavehealth/modules/api'
import { Card, ChartCard, State, Tabs, Tab, TabList, TabPanels, TabPanel } from '@behavehealth/components'

import { IntakesChart, NewApplicantsChart, NewApplicantsSourceChart } from '@behavehealth/components/Charts'

import { AnalyticsContext } from '../context'

const AdmissionsAnalytics = () => {
  const { timeframe, renderer } = React.useContext(AnalyticsContext)

  const [loading, setLoading] = React.useState(false)
  const [stats, setStats]: any = React.useState({})

  React.useEffect(() => {
    const getStats = async (timeframe: any) => {
      try {
        setLoading(true)

        const result = await apiGet({
          url: '/metrics_stats/admissions',
          params: {
            timeframe: timeframe,
          },
          notify: false,
        })

        // update state
        setStats(result.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (!loading) getStats(timeframe)
  }, [timeframe])

  if (loading || Object.keys(stats).length === 0) {
    return (
      <Card>
        <State isLoading />
      </Card>
    )
  } else {
    return (
      <Tabs defaultTab="page_1">
        <TabList css={styles.tabList}>
          <Tab label="Page 1" name="page_1" />
          <Tab label="Page 2" name="page_2" />
          <Tab label="Page 3" name="page_3" />
        </TabList>

        <TabPanels>
          <TabPanel name="page_1">
            <div css={styles.grid}>
              <ChartCard title="New Applicants" subtitle="What is the count of new applicants over time?">
                <NewApplicantsChart
                  timeframe={timeframe}
                  renderer={renderer}
                  xData={stats.new_applicants?.x}
                  yData={stats.new_applicants?.y}
                />
              </ChartCard>

              <ChartCard title="Intakes" subtitle="How many applicants have been accepted or declined?">
                <IntakesChart
                  timeframe={timeframe}
                  renderer={renderer}
                  xData={stats.new_accepted?.x}
                  yData1={stats.new_accepted?.y}
                  yData2={stats.new_declined?.y}
                />
              </ChartCard>

              <ChartCard title="New Applicants Source" subtitle="What is the referral source of new applicants over time?">
                <NewApplicantsSourceChart timeframe={timeframe} renderer={renderer} data={stats.new_applicants_source} />
              </ChartCard>
            </div>
          </TabPanel>
          <TabPanel name="page_2"></TabPanel>
          <TabPanel name="page_3"></TabPanel>
        </TabPanels>
      </Tabs>
    )
  }
}

const styles = {
  tabList: {
    marginBottom: '1rem',
  },

  grid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '100%',

    '@media (min-width: 768px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
    },
  },
}

export default AdmissionsAnalytics
