import React from 'react'

import FormSection from '../../../../Forms/FormSection'
import ContextShow from '../../../../Forms/ContextShow'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'

const ProsthesisApplianceInventory = () => {
  return (
    <FormSection layout="vertical">
      <RadioGroup label="Contacts" model="data.prosthesis_appliance_inventory.contacts" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.prosthesis_appliance_inventory.contacts" is={true}>
        <RadioGroup label="Are contacts with patient?" model="data.prosthesis_appliance_inventory.contacts_extra" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>
      </ContextShow>

      <RadioGroup label="Glasses" model="data.prosthesis_appliance_inventory.glasses" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.prosthesis_appliance_inventory.glasses" is={true}>
        <RadioGroup label="Are glasses with patient?" model="data.prosthesis_appliance_inventory.glasses_extra" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>
      </ContextShow>

      <RadioGroup label="Hearing aid" model="data.prosthesis_appliance_inventory.hearing_aid" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.prosthesis_appliance_inventory.hearing_aid" is={true}>
        <RadioGroup
          label="Are hearing aids with patient?"
          model="data.prosthesis_appliance_inventory.hearing_aid_extra"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>
      </ContextShow>

      <RadioGroup label="Cane / walker / crutches" model="data.prosthesis_appliance_inventory.cane" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.prosthesis_appliance_inventory.cane" is={true}>
        <RadioGroup
          label="Are cane / walker / crutches with patient?"
          model="data.prosthesis_appliance_inventory.cane_extra"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>
      </ContextShow>

      <RadioGroup label="Dentures / bridge / partial" model="data.prosthesis_appliance_inventory.dentures" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.prosthesis_appliance_inventory.dentures" is={true}>
        <RadioGroup
          label="Are dentures / bridge / partial with patient?"
          model="data.prosthesis_appliance_inventory.dentures_extra"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>
      </ContextShow>
    </FormSection>
  )
}

export default ProsthesisApplianceInventory
