import React from 'react'

import SmartStatus from '../../components/SmartStatus'

export const REQUEST_STATUSES = {
  pending: {
    label: 'Pending',
    color: 'orange',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
  cancelled: {
    label: 'Cancelled',
    color: 'red',
  },
  archived: {
    label: 'Archived',
    color: 'gray',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={REQUEST_STATUSES} {...rest} />
}

export default Status
