import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { AuthorizationsReportDataTable } from '@behavehealth/constructs/LiveReports/AuthorizationsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const InsuranceAuthorizations = () => {
  const tableProps = useDataTable({
    name: ['reports', 'authorizations'],
    endpoint: `/live_reports?category=authorizations`,
    localStorageKey: 'report_authorizations_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getClientLink(rowData.client)}/authorizations/${rowData.id}`, [])

  return (
    <Page feature="authorizations" title="Live Authorizations Report">
      <Grid>
        <AuthorizationsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(InsuranceAuthorizations)
