import React from 'react'

import Select from '../Select'
import Option from '../Option'

const MedicationDescriptionSelect = ({ label, model, validations, testKey }) => (
  <Select fullWidth testKey={testKey} label={label} model={model} validations={validations} defaultValue="medical">
    <Option label="Medical" value="medical" />
    <Option label="Psychiatric" value="psychiatric" />
    <Option label="Taper" value="taper" />
    <Option label="Palliatives" value="palliatives" />
    <Option label="MAT (Vivitrol, Suboxone, etc)" value="mat" />
    <Option label="Vaccine" value="vaccine" />
  </Select>
)

MedicationDescriptionSelect.defaultProps = {
  label: 'Medication Description',
}

export default MedicationDescriptionSelect
