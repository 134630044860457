import React from 'react'
import compact from 'lodash/compact'

import Accordion from './Accordion'
import DonutChart from './DonutChart'

type Props = {
  title?: string
  data?: object
  children?: React.ReactNode
  list?: string
  isOpen?: boolean
}

const Checklist: React.FC<Props> = ({ isOpen, title, data, list, children }) => {
  const checklist = {}
  const [percentage, setPercentage] = React.useState(0)

  const updateChecklist = (id, value) => {
    if (id) {
      checklist[id] = value

      const values = Object.values(checklist)
      const checkedAmount = compact(values).length
      const checklistLength = values.length

      setPercentage(Math.round((checkedAmount * 100) / checklistLength))
    }
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, { updateChecklist, data, list })
  })

  return (
    <Accordion
      isOpen={isOpen}
      minimal
      title={title}
      description={`${percentage}% completed`}
      permission={true}
      graphic={<DonutChart value={percentage} size={20} labelSize="0px" strokeWidth={4} letterSpacing={-1} />}
    >
      {childrenWithProps}
    </Accordion>
  )
}

export default Checklist
