import React from 'react'

import Tooltip from './Tooltip'
import Button from './Button'

const TooltipButton = ({ message, position, show, ...rest }) => (
  <Tooltip content={message} position={position} show={show}>
    <Button {...rest} />
  </Tooltip>
)

TooltipButton.defaultProps = {
  position: 'top',
}

export default TooltipButton
