import React from 'react'
import debounce from 'lodash/debounce'

import { COLORS } from '../../theme'
import { formatURL, isURL } from '../../utils/functions'
import { useCreate, useGet } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Loader from '../../components/Loader'
import Overlay from '../../components/Overlay'
import PageSection from '../../components/PageSection/PageSection'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import URLInput from '../../components/Forms/URLInput'

import { useCommunityPermissions } from './useCommunityPermissions'

const RootCommunityProfileWebsiteTab: React.FC<any> = (props: any) => {
  const { entityId, data, refetchEntity, updateEntity, isSaving } = props

  const { canEditCommunityProfiles } = useCommunityPermissions()

  const form = React.useRef(null)

  const [url, setUrl] = React.useState(data.url)
  const [webRenderingFormat, setWebRenderingFormat] = React.useState(data.web_rendering_format)
  const [isWebsitePageVisible, setIsWebsitePageVisible] = React.useState(!!data.view_settings?.website)

  const isUrlValid = isURL(url)

  const savedFormattedUrl = React.useMemo(() => {
    return formatURL(data?.url)
  }, [data?.url])

  const savedScreenshotUrl = React.useMemo(() => {
    return formatURL(data?.screenshot_source_url)
  }, [data?.screenshot_source_url])

  const formattedUrl = React.useMemo(() => {
    return formatURL(url)
  }, [url])

  const shouldCheckIframe = savedFormattedUrl !== formattedUrl
  const isScreenshotUrlDifferent = formattedUrl !== savedScreenshotUrl

  const debouncedSetUrl = React.useCallback(
    debounce((value) => {
      setUrl(value)
    }, 350),
    [],
  )

  const [isEditable, setIsEditable] = React.useState(false)

  const [isIframeAllowed, setIsIframeAllowed] = React.useState(true)

  const [wasGeneratingScreenshot, setWasGeneratingScreenshot] = React.useState(false)
  const [shouldCheckScreenshot, setShouldCheckScreenshot] = React.useState(false)

  const screenshotsMutation: any = useCreate({
    name: ['generate_website_screenshots', url],
    url: `/community/entities/${entityId}/generate_website_screenshots`,
    // invalidate: 'community-entities',
  })

  const checkIframeQuery = useGet({
    name: ['community-entity', formattedUrl],
    url: `/apps/utils/check_iframe`,
    params: { url: formattedUrl },
    options: { enabled: isEditable && !!formattedUrl && webRenderingFormat === 'url' },
  })

  const screenshotStatusQuery: any = useGet({
    name: ['community-entity', entityId, 'screenshot-status'],
    url: `/community/entities/${entityId}/screenshot_status`,
    options: {
      refetchInterval: 5 * 1000,
      refetchOnMount: true,
      staleTime: 0,
      enabled: !!shouldCheckScreenshot,
      keepPreviousData: false,
    },
  })

  const save = async () => {
    await updateEntity({
      url,
      web_rendering_format: webRenderingFormat,
      view_settings: {
        website: isWebsitePageVisible,
      },
    })

    setIsEditable(false)
  }

  const currentScreenshot = data.screenshot_variants?.['600']
  const isProcessingScreenshots =
    shouldCheckScreenshot && (data?.screenshot_status === 'processing' || screenshotStatusQuery.data?.status === 'processing')
  const isProcessingIframe = checkIframeQuery.isLoading

  const shouldCaptureScreenshots = !!url && isScreenshotUrlDifferent && webRenderingFormat === 'screenshot' && !isProcessingScreenshots

  React.useEffect(() => {
    if (!checkIframeQuery.data) return

    setIsIframeAllowed(!!checkIframeQuery.data?.iframe_allowed)
  }, [checkIframeQuery.data])

  React.useEffect(() => {
    if (wasGeneratingScreenshot && shouldCheckScreenshot && screenshotStatusQuery.data?.status === 'generated') {
      setShouldCheckScreenshot(false)
      setWasGeneratingScreenshot(false)
      screenshotStatusQuery.remove()
      refetchEntity()
    }
  }, [wasGeneratingScreenshot, shouldCheckScreenshot, screenshotStatusQuery.data])

  return (
    <>
      <Overlay.Content>
        <Section>
          <PageSection>
            <PageSection.Header graphic={<Icon icon="web_form" />}>
              <PageSection.Title title="Website" />
            </PageSection.Header>
          </PageSection>

          <Form isCompact getForm={form} initialModel={data} isEditable={isEditable}>
            <FormSection maxWidth="100%" className="pt-3 pb-5 pr-3">
              <URLInput
                label="Website"
                glyph="website"
                value={url}
                onUpdate={({ value }) => {
                  debouncedSetUrl(value)
                }}
              />

              <CheckboxGroup label="Page Visibility" trueIcon="check" falseIcon="cross" falseStyle="none">
                <Checkbox
                  label="Show Website Page"
                  icon="web_form"
                  value={isWebsitePageVisible}
                  onUpdate={({ value }) => {
                    setIsWebsitePageVisible(value)
                  }}
                />
              </CheckboxGroup>

              {isWebsitePageVisible && (
                <>
                  <RadioGroup
                    label="Website Embed Type"
                    layout="vertical-dense"
                    value={webRenderingFormat}
                    onUpdate={({ value }) => {
                      setWebRenderingFormat(value)
                    }}
                  >
                    <Radio label="Dynamic Website" value="url" />
                    <Radio label="Screenshot" value="screenshot" />
                  </RadioGroup>

                  {webRenderingFormat === 'screenshot' && (
                    <>
                      {isProcessingScreenshots ? (
                        <Card className="px-4 py-8 flex flex-col items-center justify-center text-center">
                          <Loader />
                          <h4 className="font-[600] mt-3">Processing Screenshots…</h4>
                          <div className="text-text-muted mt-2">This may take a few minutes</div>
                        </Card>
                      ) : isEditable && shouldCaptureScreenshots ? (
                        <>
                          <Card className="px-4 py-8 flex flex-col items-center justify-center text-center">
                            <Glyph glyph="website" size={20} />
                            <h4 className="font-[600] mt-3">Capture Website Screenshot</h4>

                            {isUrlValid && (
                              <div className="text-text-muted mt-2">
                                Click the button below to capture screenshots for{' '}
                                <a href={formattedUrl} target="_blank" className="hover:underline">
                                  {url}
                                  <Glyph glyph="external_link" size={12} className="ml-1.5 relative -top-0.5" color={COLORS.blue} />
                                </a>
                              </div>
                            )}

                            {isUrlValid ? (
                              <Button
                                label="Capture Website Screenshots"
                                glyph="photo"
                                type="primary"
                                size={300}
                                className="mt-3"
                                onClick={async () => {
                                  if (!isUrlValid) return

                                  await screenshotsMutation.mutateAsync({ url })
                                  setWasGeneratingScreenshot(true)
                                  setShouldCheckScreenshot(true)
                                }}
                                isLoading={screenshotsMutation.isLoading}
                                display="inline-flex"
                              />
                            ) : (
                              url && (
                                <Alert small type="negative" className="mt-2">
                                  {url} is not a valid URL
                                </Alert>
                              )
                            )}
                          </Card>
                        </>
                      ) : (
                        <>
                          <Card>
                            <div className="flex px-2 py-2 font-[600] flex-nowrap justify-center text-center bg-white shadow-soft-2 relative z-[20]">
                              <Glyph glyph="website" size={20} className="mr-2" />
                              <div>Website Screenshot Preview</div>
                            </div>

                            {currentScreenshot ? (
                              <img src={currentScreenshot} alt="Website Screenshot" className="w-full h-full object-cover" />
                            ) : (
                              <>
                                <Glyph glyph="website" size={20} />
                                <h4 className="font-[600] mt-3">Website Screenshot</h4>
                                <div className="text-text-muted mt-2">No screenshot has yet been generated for {formattedUrl}</div>
                              </>
                            )}
                          </Card>
                        </>
                      )}
                    </>
                  )}

                  {webRenderingFormat === 'url' && (
                    <>
                      {!isUrlValid ? (
                        <Card className="px-4 py-8 flex flex-col items-center justify-center">
                          <Glyph glyph="website" size={20} />
                          <h4 className="font-[600] mt-3 text-center">Dynamic Website Embed</h4>
                          {url && !isUrlValid ? (
                            <Alert small type="negative" className="mt-2">
                              {url} is not a valid URL
                            </Alert>
                          ) : (
                            <Alert small className="mt-2">
                              Enter a website URL to embed
                            </Alert>
                          )}
                        </Card>
                      ) : isProcessingIframe ? (
                        <Card className="p-4 flex flex-col items-center justify-center text-center">
                          <Loader />
                          <h4 className="font-[600] mt-3">Processing…</h4>
                          <div className="text-text-muted mt-2">Checking if the website can be dynamically embedded</div>
                        </Card>
                      ) : !isIframeAllowed ? (
                        <Card className="px-4 py-8 flex flex-col items-center justify-center">
                          <Glyph glyph="website" size={20} />
                          <h4 className="font-[600] mt-3 text-center">Dynamic Website Embed</h4>
                          <a className="mt-2 hover:underline" href={formattedUrl} target="_blank">
                            {url}
                            <Glyph glyph="external_link" size={12} className="ml-1.5 relative -top-0.5" color={COLORS.blue} />
                          </a>
                          <Alert small type="negative" glyph="cross" className="mt-3">
                            <div>This website cannot be embedded dynamically.</div>
                            <div>Please use the screenshot option instead.</div>
                          </Alert>
                        </Card>
                      ) : (
                        <Card className="relative grid grid-cols-[100%]">
                          <div className="flex px-2 py-2 font-[600] flex-nowrap justify-center text-center bg-white shadow-soft-2 relative z-[20]">
                            <Glyph glyph="website" size={20} className="mr-2" />
                            <div>Dynamic Website Preview</div>
                          </div>

                          <div className="relative min-h-[1000px]">
                            <iframe src={formattedUrl} className="absolute w-full h-full top-0 bottom-0 left-0 right-0 z-10" />
                            <Loader className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
                          </div>
                        </Card>
                      )}
                    </>
                  )}
                </>
              )}
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      {canEditCommunityProfiles && (
        <Flex gap="0.75rem" className="px-5 pb-4 bg-[#F7F8FB]">
          {isEditable && (
            <>
              <Button
                label="Save Website Page"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={(!!url && !isUrlValid) || isProcessingScreenshots}
                flex="100 1 auto"
              />
              <Button
                label="Cancel"
                glyph="cross"
                type="default"
                isDisabled={isSaving}
                onClick={() => {
                  setIsEditable(false)
                }}
              />
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label="Edit Website Page"
                type="primary"
                onClick={() => {
                  setIsEditable(true)
                }}
                flex="100 1 auto"
              />
            </>
          )}
        </Flex>
      )}
    </>
  )
}

export const CommunityProfileWebsiteTab = withOverlayError(RootCommunityProfileWebsiteTab)
