import React from 'react'
import { v4 as uuid } from 'uuid'

import { isDefined } from '../../utils/functions'
import { withFormContext } from './context'
import { validate } from './validators'
import { COLORS, INPUT_STYLES } from '../../theme'

import Value from '../Value'
import Glyph from '../Glyph'

import FieldBase from './FieldBase'

const DEFAULT_EMPTY_VALUE = null

class Select extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    const vs = props.validations

    // set the initial value to the input field value
    let value = props.value || props.form?.getInitialInputFieldValue(props.model)

    // check if the above should be overwritten by a custom value prop passed
    if (isDefined(props.value)) value = props.value

    // if the value is still undefined here, try to set it to the default value
    if (!isDefined(value)) value = props.defaultValue

    // if the value is still undefined, just set it to the default empty value
    if (!isDefined(value)) value = DEFAULT_EMPTY_VALUE

    if (vs) errors = validate(value, vs)

    this.state = {
      type: 'SELECT',
      id: props.id || `${props.model}-${uuid()}`,
      model: props.model,
      value: value,
      isNested: props.isNested || false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValidations: props.validations,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.updateType = 'DATA'
  }

  /*
    CUSTOM FUNCTIONS
  */
  onChange = (event: any) => {
    const target = event.target
    let value = target.value || DEFAULT_EMPTY_VALUE

    if (this.props.asNumber) {
      const parsedValue = parseInt(value)
      if (!isNaN(parsedValue)) value = parseInt(value)
    }

    this.queue({ type: 'CHANGE_VALUE', value: value })
  }

  /*
    RENDER
  */
  editRender = () => {
    return (
      <div css={styles.root}>
        <select
          css={styles.select}
          id={this.state.id}
          name={this.state.id}
          size={this.props.size}
          value={this.state.value}
          onChange={this.props.onChange || this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        >
          {!isDefined(this.props.defaultValue) && this.props.allowEmpty && <option defaultValue />}
          {this.props.children}
        </select>
        <Glyph glyph="triangle_down" size={10} css={styles.glyph} />
      </div>
    )
  }

  readOnlyRender = () => {
    let displayValue = this.state.value

    // check if the children are an array or not
    const isChildrenArray = Array.isArray(this.props.children)

    if (isChildrenArray) {
      for (let i = 0; i < this.props.children?.length; i++) {
        if (this.props.children[i]?.type === React.Fragment) {
          for (let j = 0; j < this.props.children[i]?.props?.children?.length; j++) {
            if (!this.props.children[i]?.props?.children[j]?.props) continue

            if (this.props.children[i].props?.children[j]?.props?.value === this.state.value) {
              displayValue = this.props.children[i].props?.children[j]?.props?.label
            }
          }
        } else {
          if (!this.props.children[i]?.props) continue

          if (this.props.children[i].props?.value === this.state.value) {
            displayValue = this.props.children[i].props?.label
          }
        }
      }
    } else {
      if (this.props.children.props?.value === this.state.value) {
        displayValue = this.props.children.props?.label
      }
    }

    return <Value testKey={this.props.testKey} value={displayValue} />
  }
}

const styles = {
  root: {
    width: '100%',
  },

  select: {
    ...INPUT_STYLES,
    WebkitAppearance: 'none',
    background: `linear-gradient(white, ${COLORS.lightBackground})`,
    paddingRight: '2rem',
    maxWidth: '100%',
    cursor: 'pointer',
    fontSize: 'var(--input-font-size)',
  },

  glyph: {
    top: '50%',
    transform: 'translateY(-50%)',
    right: 12,
    position: 'absolute',
    pointerEvents: 'none',
  },
}

Select.defaultProps = {
  isEditable: true,
  validateOn: 'blur-change',
}

export default withFormContext(Select)
