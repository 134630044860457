import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DEFAULT_FILTERS } from '../Filters/constants'
import { niceAmount, nicePercentage, titleCase } from '../../utils/functions'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { FacilitiesTable } from '../../components/Forms/Selectors/tables/FacilitiesTable'

export const DiscountsDataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            as={NavLink}
            id={data.id}
            value={data.name}
            to={props.to ? props.to(data) : undefined}
            onClick={props.onClick ? () => props.onClick(data) : undefined}
          />
        ),
      },
      {
        title: 'Connected EHR',
        model: 'facility',
        width: 300,
        type: 'profile',
      },
      {
        title: 'Status',
        model: 'status',
        type: 'title',
      },
      {
        title: 'SKU',
        model: 'sku',
      },
      {
        title: 'Discount Type',
        model: 'discount_type',
        formatValue: ({ value }: any) => titleCase(value),
      },
      {
        title: 'Discounted Amount',
        model: 'amount_off',
        formatValue: ({ data }: any) => {
          if (data?.discount_type === 'fixed') {
            return niceAmount(data.amount_off)
          }

          return null
        },
      },
      {
        title: 'Discounted Percentage',
        model: 'percent_off',
        formatValue: ({ data }: any) => {
          if (data?.discount_type === 'percentage') {
            return `${nicePercentage(data.percent_off)}%`
          }

          return null
        },
      },
      {
        title: 'Duration',
        model: 'duration',
        formatValue: ({ value, data }: any) => {
          if (value === 'number_of_months') {
            return `${data?.max_redemptions} Months`
          }

          return titleCase(value)
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return <DataTable asCard title="Discounts" icon="certificate" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Inactive', value: 'inactive' },
      { label: 'Active', value: 'active' },
      { label: 'In Use', value: 'in_use' },
      { label: 'Used', value: 'used' },
      { label: 'Archived', value: 'archived' },
    ],
  },
  sku: {
    label: 'SKU',
    type: 'string',
  },
  facility: {
    label: 'Connected EHR',
    type: 'multi_overlay',
    polymorphic: false,
    selectTitle: (item: any) => item.name,
    icon: 'enterprise',
    table: <FacilitiesTable />,
  },
  discount_type: {
    label: 'Discount Type',
    type: 'multi_select',
    options: [
      { label: 'Fixed', value: 'fixed' },
      { label: 'Percentage', value: 'percentage' },
    ],
  },
  amount_off: {
    label: 'Discounted Amount',
    type: 'number',
  },
  percent_off: {
    label: 'Discounted Percentage',
    type: 'number',
  },
  duration: {
    label: 'Duration',
    type: 'multi_select',
    options: [
      { label: 'Once', value: 'once' },
      { label: 'Max Redemptions', value: 'number_of_months' },
      { label: 'Forever', value: 'forever' },
    ],
  },
  max_redemptions: {
    label: 'Max Redemptions',
    type: 'number',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
