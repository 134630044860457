import React from 'react'

import { address, titleCase } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Overlay from '../../components/Overlay'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'
import OverlayLoader from '../../components/OverlayLoader'

const RootClientConnectionOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    parent,
    save,
  } = useOverlay({
    name: 'connection',
    endpoint: '/connections',
    invalidate: 'connections',
    options: props,
  })

  const { timezone } = useSettings()

  const referenceID = parent?.id
  const referenceType = parent?.type === 'client' ? 'resident' : parent?.type

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="connections" title={isNew ? 'Add Connection' : 'Connection'} />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              reference_id: referenceID,
              reference_type: referenceType,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              <RadioGroup
                model="category"
                label="Category"
                layout="vertical-dense"
                defaultValue="care_team"
                validations={{
                  presence: {
                    message: 'Please select a connection category',
                  },
                }}
              >
                <Radio label="Care Team" value="care_team" />
                <Radio label="Organization" value="organization" />
              </RadioGroup>

              <ContextShow when="category" is={'care_team'}>
                <RadioGroup
                  model="subcategory"
                  label="Relation Type"
                  layout="vertical-dense"
                  defaultValue="therapist"
                  validations={{
                    presence: {
                      message: 'Please select a connection category',
                    },
                  }}
                >
                  <Radio label="Addiction Counselor" value="addiction_counselor" />
                  <Radio label="Case Manager" value="case_manager" />
                  <Radio label="Interventionist" value="interventionist" />
                  <Radio label="Nurse" value="nurse" />
                  <Radio label="Peer Provider" value="peer_provider" />
                  <Radio label="Primary Care Physician" value="care_physician" />
                  <Radio label="Primary Therapist" value="therapist" />
                  <Radio label="Probation Officer" value="probation_officer" />
                  <Radio label="Psychiatrist" value="psychiatrist" />
                  <Radio label="Recovery Coach" value="recovery_coach" />
                  <Radio label="Sponsor" value="sponsor" />
                </RadioGroup>

                <OverlaySelector
                  isPolymorphic
                  model="to"
                  label="Connection"
                  type="employees.active"
                  icon="employees"
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => titleCase(data.position)}
                  validations={{
                    presence: {
                      message: 'Please select a staff member',
                    },
                  }}
                />
              </ContextShow>

              <ContextShow when="category" is={'organization'}>
                <RadioGroup
                  model="subcategory"
                  label="Connection Type"
                  layout="vertical-dense"
                  defaultValue="treatment_center"
                  validations={{
                    presence: {
                      message: 'Please select a connection subcategory',
                    },
                  }}
                >
                  <Radio label="Treatment Center" value="treatment_center" />
                  <Radio label="Sober Living Home" value="sober_living_home" />
                  <Radio label="Employer" value="employer" />
                  <Radio label="School" value="school" />
                </RadioGroup>

                <ObjectSelector
                  isPolymorphic
                  model="to"
                  label="Connection"
                  type="organizations"
                  icon="organizations"
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => address(data.address)}
                  validations={{
                    presence: {
                      message: 'Please select an organization',
                    },
                  }}
                />
              </ContextShow>

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          {!isNew && data && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              glyph="check"
              label="Save"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="connections.create"
            />
            {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              label="Edit"
              glyph="edit"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="connections.edit"
            />

            <DeleteDialog
              title="Delete Connection?"
              message="Are you sure you want to delete this connection? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                label="Delete"
                type="default"
                glyph="delete"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="connections.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClientConnectionOverlay = withOverlayError(RootClientConnectionOverlay)
