// TODO: remove after verifying this is not used anywhere
// new component is under constructs

import React from 'react'
import { connect } from 'react-redux'

import { apiUpdate } from '../../../modules/api'
import { ICONS } from '../../../theme'
import { isBlank } from '../../../utils/functions'
import { RECURRING_DAYS, RECURRING_FREQUENCIES } from '../../../utils/recurrence'
import { SERVICE_CATEGORIES } from '../../../utils/constants'

import Alert from '../../Alert'
import Button from '../../Button'
import Divider from '../../Divider'
import Overlay from '../../Overlay'
import Section from '../../Section'
import ServiceSelector from '../../ServiceSelector'
import Status from '../../Status'

import AmountInput from '../../Forms/AmountInput'
import Attachments from '../../Forms/Attachments'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ContextShow from '../../Forms/ContextShow'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import Option from '../../Forms/Option'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Select from '../../Forms/Select'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../../Overlays/pages/OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class Service extends OverlayBase {
	toggleArchive = async () => {
		if (this.props.record.archived_at) {
			await apiUpdate({
				name: 'services',
				url: `/services/${this.props.match.params.id}/unarchive`
			})
		} else {
			await apiUpdate({
				name: 'services',
				url: `/services/${this.props.match.params.id}/archive`
			})
		}
	}

	renderHeader = () => {
		return <Overlay.Header icon="financials" title={this.state.$new ? 'Add Service' : 'Service'} />
	}

	renderContent = () => {
		const { $new, $editable, params, subcategory } = this.state
		const { record, loading } = this.props

		return (
			<Overlay.Content>
				<Form
					getForm={this.form}
					initialModel={{
						...record,
						...params
					}}
					isEditable={$editable}
					onValidationUpdate={this.onValidationUpdate}
					decorate={(model: any) => {
						const { category, subcategory } = this.state
						const subcategoryText = SERVICE_CATEGORIES[category]?.subcategories[subcategory].label

						return {
							name: subcategory === 'custom' ? model.name : subcategoryText,
							category: category,
							subcategory: subcategory
						}
					}}
				>
					<Section>
						<FormSection>
							{record.archived_at && (
								<Alert glyph="info" type="neutral">
									This service is archived. No new charges can be created based on it.
								</Alert>
							)}

							{!$editable && (
								<div>
									<h2 css={{ margin: '0 0 0.4rem' }}>{record.name}</h2>
									<Status label={SERVICE_CATEGORIES[record.category]?.label} color="green" />
								</div>
							)}

							<FormSection css={!$editable && { display: 'none' }}>
								<ServiceSelector
									isOpen={$new}
									category={record.category}
									subcategory={record.subcategory}
									onUpdate={(data) =>
										this.setState({
											category: data.category,
											subcategory: data.subcategory
										})
									}
								/>

								{subcategory === 'custom' && (
									<Input
										label="Name"
										model="name"
										validations={{
											presence: {
												message: 'Please enter a service name'
											}
										}}
									/>
								)}
							</FormSection>

							<Textarea label="Description" model="description" />

							<RadioGroup
								label="Is this a one-time or repeating service?"
								model="is_recurring"
								layout="horizontal-dense"
								defaultValue={false}
								validations={{
									presence: {
										message: 'Please select an answer'
									}
								}}
							>
								<Radio label="One-time" value={false} />
								<Radio label="Repeating" value={true} />
							</RadioGroup>

							{/* Amount */}
							<FormSection horizontal>
								<AmountInput
									label="Amount"
									model="amount"
									validations={{
										presence: {
											message: 'Please enter a service amount'
										}
									}}
								/>

								<ContextShow when="is_time_based_amount" is={true}>
									<Select label="Per" model="amount_time" defaultValue="hour">
										<Option label="Hour" value="hour" />
										<Option label="Min" value="min" />
									</Select>
								</ContextShow>
							</FormSection>

							{/* One-time */}
							<ContextShow when="is_recurring" is={false}>
								<CheckboxGroup
									isDisabled
									label={$editable ? 'Enable Charge Notes' : 'Charge Notes'}
									trueIcon="check"
									falseIcon="cross"
									falseStyle="faded"
								>
									<Checkbox
										label="Allow Staff to create charges for this service via Charge Notes"
										model="is_charge_note_enabled"
									/>
								</CheckboxGroup>
							</ContextShow>

							{/* Recurring */}
							<ContextShow when="is_recurring" is={true}>
								<RadioGroup
									label="Repeating Frequency"
									model="recurring_frequency"
									layout="vertical-dense"
									defaultValue="monthly"
									validations={{
										presence: {
											message: 'Please select a billing frequency'
										}
									}}
								>
									{Object.keys(RECURRING_FREQUENCIES).map((key) => (
										<Radio key={key} label={RECURRING_FREQUENCIES[key].label} value={key} />
									))}
								</RadioGroup>

								<ContextShow when="recurring_frequency" is="weekly">
									<Select
										asNumber
										label="Weekly Due Day"
										model="recurring_weekly_due_day"
										defaultValue={0}
										validations={{
											presence: {
												message: 'Please select a due day'
											}
										}}
									>
										{RECURRING_DAYS.weekly.map((key, idx) => (
											<Option key={idx} label={`Every ${key}`} value={idx} />
										))}
									</Select>
								</ContextShow>

								<ContextShow when="recurring_frequency" is="biweekly">
									<Select
										asNumber
										label="Biweekly Due Day"
										model="recurring_biweekly_due_day"
										defaultValue={0}
										validations={{
											presence: {
												message: 'Please select a due day'
											}
										}}
									>
										{RECURRING_DAYS.biweekly.map((key, idx) => (
											<Option key={idx} label={`Every other ${key}`} value={idx} />
										))}
									</Select>
								</ContextShow>

								<ContextShow when="recurring_frequency" is="monthly">
									<Select
										asNumber
										label="Monthly Due Day"
										model="recurring_monthly_due_day"
										defaultValue={1}
										validations={{
											presence: {
												message: 'Please select a due day'
											}
										}}
									>
										{RECURRING_DAYS.monthly.map((key, idx) => (
											<Option key={idx} label={key} value={idx + 1} />
										))}
									</Select>
								</ContextShow>
							</ContextShow>
						</FormSection>
					</Section>

					<Divider />

					<Section title="Programs & Locations">
						<MultiObjectSelector model="programs" label="Programs" type="programs" icon={ICONS.checklist} />
						<MultiObjectSelector model="houses" label="Locations" type="properties" icon={ICONS.properties} />
					</Section>

					<Divider />

					<Section title="Staff & Organizations">
						<FormSection>
							<MultiObjectSelector model="employees" label="Staff" type="employees" icon={ICONS.employees} />
							<MultiObjectSelector
								model="organizations"
								label="Organizations"
								type="organizations"
								icon={ICONS.organizations}
							/>
						</FormSection>
					</Section>

					<Divider />

					<Section title="Internal Cost & Notes">
						<FormSection>
							<Alert glyph="info">
								This information is <span css={{ fontStyle: 'italic' }}>not</span> displayed to any clients or payers.
							</Alert>

							<FormSection horizontal>
								<AmountInput label="Service Cost" model="internal_cost" />

								<ContextShow when="is_time_based_amount" is={true}>
									<Select label="Per" model="internal_amount_time" defaultValue="hour">
										<Option label="Hour" value="hour" />
										<Option label="Min" value="min" />
									</Select>
								</ContextShow>
							</FormSection>

							<Textarea label="Internal Notes" model="internal_notes" />
						</FormSection>
					</Section>

					<Divider />

					<Section title="Attachments">
						<Attachments model="documents" />
					</Section>

					{!$new && (
						<>
							<Divider />

							<Section headingType="h2" title="Timeline">
								<Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
							</Section>
						</>
					)}
				</Form>
			</Overlay.Content>
		)
	}

	renderFooter = () => {
		const { $new, $editable, isInvalid, category } = this.state
		const { record } = this.props

		return (
			<Overlay.Footer>
				{$editable && (
					<>
						<Button
							label="Save"
							glyph="check"
							type="primary"
							color="green"
							flex="100 1 auto"
							onClick={this.save}
							isDisabled={isInvalid || isBlank(category || record.category)}
							isLoading={this.props.loading}
						/>
						{!$new && <Button label="Cancel" glyph="cross" type="default" onClick={this.cancel} />}
					</>
				)}

				{!$editable && (
					<>
						<Button
							label="Edit"
							glyph="edit"
							type="default"
							onClick={this.edit}
							isDisabled={this.props.loading}
							flex="100 1 auto"
						/>

						{record.archived_at ? (
							<Button
								label="Un-archive"
								glyph="delete"
								type="default"
								color="gray"
								onClick={this.toggleArchive}
								isLoading={this.props.loading}
							/>
						) : (
							<Button
								label="Archive"
								glyph="delete"
								type="default"
								color="gray"
								onClick={this.toggleArchive}
								isLoading={this.props.loading}
							/>
						)}
					</>
				)}
			</Overlay.Footer>
		)
	}
}

const mapDispatchToProps = (dispatch: any) => defaultMapDispatchToProps(dispatch)

const mapStateToProps = (state: any, props: any) => defaultMapStateToProps(state, props.match, 'services')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(Service))
