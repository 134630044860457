import React from 'react'
import clsx from 'clsx'

import { COLORS, MEDIA_QUERY } from '../theme'

import { PLANS, ADDONS } from '../utils/constants'
import { Plan } from '../declarations/types/Plan'
import { Addon } from '../declarations/types/Addon'

import Flex from './Flex'
import Glyph from './Glyph'

type ItemProps = {
  plan?: Plan
  addon?: Addon
  required: Plan
} & React.HTMLAttributes<any>

const isPlanAboveRequired = (requiredPlan: Plan, currentPlan: Plan): boolean => {
  if (!requiredPlan || !currentPlan) return false

  const requiredPlanValue = PLANS[requiredPlan]?.value
  const currentPlanValue = PLANS[currentPlan]?.value

  return currentPlanValue >= requiredPlanValue
}

const ChecklistItemPlan: React.FC<ItemProps> = (props) => {
  const { className, plan, addon, required } = props

  const isActive = isPlanAboveRequired(required, plan) || addon
  const icon = isActive ? 'check' : 'cross'
  const color = isActive ? COLORS.green : COLORS.textMuted

  const classNames = clsx({
    [className]: className,
    'is-active': isActive,
  })

  return (
    <Flex css={itemStyles} className={classNames}>
      <Glyph glyph={icon} size={14} css={{ marginRight: 2 }} color={color} />

      <div>
        {plan && <span>{plan}</span>}
        {addon && <span>{ADDONS[addon]} add-on</span>}
      </div>
    </Flex>
  )
}

const itemStyles = {
  marginLeft: 20,
  fontWeight: 600,
  color: COLORS.textMuted,
  opacity: 0.7,

  '&.is-active': {
    color: COLORS.text,
    opacity: 1,
  },
}

type Props = {
  plan?: Plan
  addon?: Addon
}

const ChecklistItemPlans: React.FC<Props> = ({ plan, addon }) => (
  <Flex css={styles.root}>
    {plan && (
      <>
        <ChecklistItemPlan plan="lite" required={plan} />
        <ChecklistItemPlan plan="basic" required={plan} />
        <ChecklistItemPlan plan="plus" required={plan} />
        <ChecklistItemPlan plan="pro" required={plan} />
      </>
    )}

    {addon && <ChecklistItemPlan addon={addon} />}
  </Flex>
)

const styles = {
  root: {
    display: 'none',
    textTransform: 'uppercase',
    fontSize: 12,
    marginRight: 25,

    [`${MEDIA_QUERY[2]}`]: {
      display: 'flex',
    },
  },
}

export default ChecklistItemPlans
