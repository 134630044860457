import React from 'react'

import TypeBase from './TypeBase'
import { TYPOGRAPHY } from '../../theme'

const sizes = {
  h1: 800,
  h2: 700,
  h3: 600,
  h4: 500,
  h5: 400,
  h6: 300,
}

type Props = {
  is: keyof typeof TYPOGRAPHY.headings
  size: keyof typeof sizes
  lineHeight: number | string
} & React.HTMLAttributes<any>

const Heading: React.FC<Props> = ({ is, size, lineHeight, className, ...rest }) => (
  <TypeBase
    is={is || 'h3'}
    defaultMargin="0.4em"
    className={className}
    css={[
      TYPOGRAPHY.headings[size || sizes[is]],
      {
        lineHeight: lineHeight || '1.2em',
      },
    ]}
    {...rest}
  />
)

const H1 = (props) => <Heading is="h1" {...props} />
const H2 = (props) => <Heading is="h2" {...props} />
const H3 = (props) => <Heading is="h3" {...props} />
const H4 = (props) => <Heading is="h4" {...props} />
const H5 = (props) => <Heading is="h5" {...props} />
const H6 = (props) => <Heading is="h6" {...props} />

const P = (props) => <TypeBase is="p" css={{ lineHeight: 1.5, margin: '0' }} {...props} />
const Ul = (props) => <TypeBase is="ul" {...props} />
const Ol = (props) => <TypeBase is="ol" {...props} />
const Li = (props) => <TypeBase is="li" {...props} />
const Span = (props) => <TypeBase is="span" {...props} />
const Strong = (props) => <TypeBase is="strong" {...props} />
const Italic = (props) => <TypeBase is="i" {...props} />

export { H1, H2, H3, H4, H5, H6, Heading, Ul, Li, Ol, P, Span, Strong, Italic }
export { default as Text } from './Text'
