import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'

import { apiCreate, apiUpdate, apiDelete } from '../../../modules/api'

import Button from '../../Button'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Dialog from '../../Dialog'
import Divider from '../../Divider'
import Overlay from '../../Overlay'
import OverlayLoader from '../../OverlayLoader'
import Section from '../../Section'

import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Textarea from '../../Forms/Textarea'

import AgreementSections from '../../Elements/AgreementSections'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../../Overlays/pages/OverlayBase'

class LegalAgreementTemplateOverlay extends OverlayBase {
  onPreviewOpen = () => {
    this.setState({ formData: this.form.current?.getFormValue() })
  }

  render = () => {
    const { $new, $editable, isInvalid, params } = this.state
    const { dataID, initialData, loading, online, record, user, timezone } = this.props

    const data = { ...params, ...record }
    const hasInitialData = initialData && dataID
    const isBehave = user?.type === 'bh_employee'

    if (!$new && !hasInitialData && size(data) === 0) {
      return <OverlayLoader position="right" showBackdrop={this.state.$editable} />
    }

    return (
      <Overlay
        position="right"
        isLoading={!data}
        showBackdrop={this.state.$editable}
        maxWidth={82}
        closeWrapper={(element) => (
          <Dialog
            glyph="delete"
            title="Close without saving?"
            message="All changes will be lost. This action cannot be undone."
            yesColor="red"
            yesLabel="Yes, Close Without Saving"
            onYes={this.close}
            skip={!$editable}
          >
            {element}
          </Dialog>
        )}
      >
        <Overlay.Header title={data?.data?.name || 'New Legal Agreement Template'} icon="legal_agreement_alt" />

        <Overlay.Content>
          <Form
            useFullModel
            getForm={this.form}
            timezone={timezone}
            initialModel={data}
            isEditable={$editable}
            onValidationUpdate={this.onValidationUpdate}
            linked={{
              template_type: 'custom',
              variant: 'legal',
            }}
          >
            <Section title="Agreement Details" headingType="h2">
              <FormSection maxWidth="100%">
                <Input
                  label="Agreement Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a template name',
                    },
                  }}
                  className="!grow-[2]"
                />

                <Textarea useQuickText label="Notes" model="notes" />
              </FormSection>
            </Section>

            <Divider />

            <AgreementSections
              model="sections"
              validations={{
                presence: {
                  message: 'Please add at least one section',
                },
              }}
            />

            <Section
              title="Company Signature"
              headingType="h2"
              aside={
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox
                    label="Require Company Signature"
                    model="request_cosigner_signature"
                    onUpdate={(state) => this.setState({ requestCoSignerSignature: state.value })}
                  />
                </CheckboxGroup>
              }
            />

            <Divider />

            <Section
              title="Behave Health Signature"
              headingType="h2"
              aside={
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox
                    label="Require Behave Health Signature"
                    model="should_sign"
                    onUpdate={(state) => this.setState({ shouldSign: state.value })}
                  />
                </CheckboxGroup>
              }
            />
          </Form>
        </Overlay.Content>

        <Overlay.Footer online={online}>
          {$editable && (
            <>
              <Button
                glyph="check"
                label="Save Agreement"
                type="primary"
                color="green"
                isLoading={loading}
                onClick={this.save}
                isDisabled={isInvalid}
                flex="100 1 240px"
              />

              {!$new && <Button glyph="cross" label="Cancel" type="default" isDisabled={loading} onClick={this.cancel} />}
            </>
          )}

          {!$editable && isBehave && (
            <>
              <Button
                label="Edit"
                glyph="edit"
                type="default"
                isDisabled={loading}
                onClick={this.edit}
                flex="100 1 auto"
                permission="settings.agreements.edit"
              />

              <DeleteDialog
                title="Delete Agreement?"
                message="Are you sure you want to delete this agreement? This action cannot be undone."
                onYes={this.delete}
              >
                <Button
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  isLoading={loading}
                  fullWidth
                  permission="settings.agreements.edit"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'agreements')

export default connect(mapStateToProps, mapDispatchToProps)(LegalAgreementTemplateOverlay)
