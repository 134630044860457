import React from 'react'
import { connect } from 'react-redux'

import { getFirstValueFromMap, plural, titleCase } from '../../../../utils/functions'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import Alert from '../../../Alert'
import Button from '../../../Button'
import Chotomate from '../../../Chotomate'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import Grid from '../../../Grid'
import Overlay from '../../../Overlay'
import Section from '../../../Section'
import SigneeStatus from '../../../Statuses/SigneeStatus'
import Link from '../../../Link'

import AmountInput from '../../../Forms/AmountInput'
import Attachments from '../../../Forms/Attachments'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import DateInput from '../../../Forms/DateInput'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Input from '../../../Forms/Input'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Textarea from '../../../Forms/Textarea'
import ContextShow from '../../../Forms/ContextShow'
import Radio from '../../../Forms/Radio'
import RadioGroup from '../../../Forms/RadioGroup'

import { apiGet } from '../../../../modules/api'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../OverlayBase'

const ORGANIZATION_TYPES = {
  vendor: 'Vendor',
  resource: 'Community Resource',
  provider: 'Provider',
}

class CreditOverlay extends OverlayBase {
  onSaveSuccessful = () => {
    const { current } = this.props

    apiGet({
      name: 'financial_transactions_stats',
      url: `/${plural(current.type)}/${current.id}/financial_transactions/stats`,
    })
  }

  renderHeader = () => <Overlay.Header icon="financials" title={this.state.$new ? 'Add Credit' : 'Credit'} />

  renderContent = () => {
    const { $editable, $new, params, anonymize } = this.state
    const { record, timezone, current } = this.props

    return (
      <Overlay.Content>
        <Chotomate ready name="credit_overlay" />

        <Form
          getForm={this.form}
          timezone={timezone}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          linked={{
            category: 'credit',
            financial_plan_id: current?.financial_plan?.id,
          }}
        >
          <Section>
            <FormSection layout="vertical">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a charge name',
                  },
                }}
              />

              <Flex gap={16}>
                <AmountInput
                  label="Amount"
                  model="amount"
                  isEditable={$new || ($editable && record.source === 'app')}
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                    numericality: {
                      greaterThan: 0,
                      message: 'Please enter an amount',
                    },
                  }}
                />

                <DateInput
                  defaultToNow
                  label="Credit Date"
                  model="transactioned_at"
                  fullWidth
                  validations={{
                    presence: {
                      message: 'Please set a credit date',
                    },
                  }}
                />
              </Flex>

              <Divider className="!m-0" />

              <RadioGroup label="Payer Type" model="reference_category" layout="horizontal-dense" defaultValue="client">
                <Radio label="Client" value="client" />
                <Radio label="Contact" value="contact" />
                <Radio label="Organization" value="organization" />
              </RadioGroup>

              <ContextShow when="reference_category" is="client">
                <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="reference" value={current} />
              </ContextShow>

              <ContextShow when="reference_category" is="contact">
                <ObjectSelector
                  isPolymorphic
                  icon="contacts"
                  label="Payer"
                  model="reference"
                  type="client.contacts"
                  description={
                    <>
                      Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                    </>
                  }
                  dependentValue={current?.id}
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => titleCase(data?.relationship)}
                />
              </ContextShow>

              <ContextShow when="reference_category" is="organization">
                <ObjectSelector
                  isPolymorphic
                  icon="organizations"
                  label="Payer"
                  model="reference"
                  type="organizations"
                  dependentValue={current?.id}
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => ORGANIZATION_TYPES[data?.category]}
                  description={
                    <>
                      Can't find the Organization you're looking for? Go to <Link to="/community/organizations">Organizations</Link> to add
                      it.
                    </>
                  }
                />
              </ContextShow>

              <Divider className="!mt-2 !mx-0 !mb-0" />

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          {record.payer && (
            <>
              <Section title="Payer">
                <Grid gap="1rem">
                  <Alert glyph="warning" type="warning">
                    We replaced <strong>Payers</strong> with <strong>Payment Methods</strong>. Payers will not show up for new Transactions.
                  </Alert>

                  <ObjectSelector
                    isEditable={false}
                    apiData={current?.financial_plan?.payers}
                    icon="financials"
                    model="payer"
                    label="Payer"
                    type="client.payers"
                    selectTitle={(data: any) => data?.reference?.name}
                    selectDescription={(data: any) => <SigneeStatus signee={data?.reference} />}
                  />
                </Grid>
              </Section>

              <Divider />
            </>
          )}

          {!anonymize && (
            <>
              <Divider />

              <Section hide={anonymize} headingType="h2" title="Attachments" description="Upload the files related to this credit">
                <FormSection layout="vertical">
                  <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
                </FormSection>
              </Section>

              {$new && (
                <>
                  <Divider />

                  <Section title="Send Notifications">
                    <Grid gap={8}>
                      <Alert glyph="notification">Send a notification alert via SMS / Email to the Payer(s) above</Alert>

                      <CheckboxGroup layout="vertical-dense">
                        <Checkbox defaultChecked label="Send SMS Notification" model="should_sms" />
                        <Checkbox defaultChecked label="Send Email Notification" model="should_email" />
                      </CheckboxGroup>
                    </Grid>
                  </Section>
                </>
              )}

              {/* {!$new && (
                <>
                  <Divider />

                  <Section headingType="h2" title="Timeline">
                    <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
                  </Section>
                </>
              )} */}
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid, anonymize } = this.state

    if (anonymize) return null

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save Credit"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="ledger.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Credit"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="ledger.edit"
            />

            <DeleteDialog
              title="Delete Credit?"
              message="Are you sure you want to delete this credits record? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                permission="ledger.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => ({
  ...defaultMapStateToProps(state, props.match, 'financial_transactions'),
  financial_plan: getFirstValueFromMap(state.data.financial_plan?.data),
})

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(CreditOverlay))
