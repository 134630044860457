import React from 'react'
import { useMedia } from 'use-media'
import { Route, Switch, Redirect, useLocation, useRouteMatch } from 'react-router-dom'

import { BREAKPOINT } from '@behavehealth/theme'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import All from './clinical_notes/all'
import Individual from './clinical_notes/individual'
import Group from './clinical_notes/group'
import CaseManagement from './clinical_notes/case_management'
import FamilySession from './clinical_notes/family_session'

import { Dropdown, DropdownItem, Grid, HelpTagIframe, Page, Tab, TabList, Tabs } from '@behavehealth/components'
import { ClinicalNotesDropdown } from '@behavehealth/constructs/ClinicalNotes/ClinicalNotesDropdown'

const pageConfig = {
  feature: 'clinical_notes',
  help: <HelpTagIframe id="clinical_notes" />,
  marketingID: 'clinical_notes',
}

const ClinicalNotes: React.FC = () => {
  const { url } = useRouteMatch()

  const mq4 = useMedia({ minWidth: BREAKPOINT[4] })

  return (
    <Page {...pageConfig} actions={<ClinicalNotesDropdown />}>
      <Grid gap={16}>
        {mq4 ? (
          <Tabs>
            <TabList className="-mt-4">
              <Tab label="All Clinical Notes" to={`${url}/all`} />
              <Tab label="Individual" to={`${url}/individual`} />
              <Tab label="Group" to={`${url}/group`} />
              <Tab label="Case Management" to={`${url}/case-management`} />
              <Tab label="Family Session" to={`${url}/family-session`} />
            </TabList>
          </Tabs>
        ) : (
          <Dropdown label="Select Note Type" icon="clinical_notes" position="bottom">
            <DropdownItem label="All Clinical Notes" icon="clinical_notes" link={`${url}/all`} />
            <DropdownItem label="Individual" icon="clinical_notes" link={`${url}/individual`} />
            <DropdownItem label="Group" icon="clinical_notes" link={`${url}/group`} />
            <DropdownItem label="Case Management" icon="clinical_notes" link={`${url}/case-management`} />
            <DropdownItem label="Family Session" icon="clinical_notes" link={`${url}/family-session`} />
          </Dropdown>
        )}

        <Switch>
          <Route path="/(employees|me)/:resource_id/clinical-notes/all" component={All} />
          <Route path="/(employees|me)/:resource_id/clinical-notes/individual" component={Individual} />
          <Route path="/(employees|me)/:resource_id/clinical-notes/group" component={Group} />
          <Route path="/(employees|me)/:resource_id/clinical-notes/case-management" component={CaseManagement} />
          <Route path="/(employees|me)/:resource_id/clinical-notes/family-session" component={FamilySession} />

          <Redirect exact from={url} to={`${url}/all`} />
        </Switch>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ClinicalNotes, pageConfig))
