import React from 'react'
import { tint } from 'polished'
import { useHistory } from 'react-router-dom'

import { COLORS } from '../../theme'
import Glyph from '../../components/Glyph'

import { AppsHeaderButton } from './AppsHeaderButton'

import { useAppSidebarStore } from '../../hooks/useAppSidebarStore'

export const AppsHeaderHelp = ({ isDesktop }: any) => {
  const open = useAppSidebarStore((state) => state.open)
  const history = useHistory()

  const handleOpen = () => {
    open('help_center')
    history.push(`?guide=help_center_home`)
  }

  if (isDesktop) {
    return (
      <div css={STYLES.root} onClick={handleOpen}>
        <div css={STYLES.badge}>
          <Glyph glyph="info" color={COLORS.blue} size="1.4em" />
          <span>Help</span>
        </div>
      </div>
    )
  }

  return (
    <AppsHeaderButton isDesktop={isDesktop} onClick={handleOpen}>
      <Glyph glyph="info" color={COLORS.blue} size={20} />
    </AppsHeaderButton>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 0.4rem',
  },

  badge: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.2em 0.7em 0.2em 0.5em',
    borderRadius: 100,
    background: tint(0.8, COLORS.blue),

    color: COLORS.black,
    fontSize: '0.8rem',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: 1,
    textTransform: 'uppercase',
    cursor: 'pointer',

    svg: {
      flex: '0 0 auto',
      marginRight: '0.25em',
    },
  },
}
