import React from 'react'

import { useUpdate } from '../../hooks/useNewAPI'
import { COLORS } from '../../theme'

import Button from '../../components/Button'
import Status from '../../components/Status'

export const QuoteLineActions = (props: any) => {
  const { isPublic, quote, quoteLine, canEdit } = props

  const [isSelected, setIsSelected] = React.useState(quoteLine?.is_selected)

  const { mutateAsync, isLoading } = useUpdate({
    name: ['quote_lines', 'delete_quote_lines'],
    url: isPublic ? `/apps/quotes/${quote?.external_id}/quote_lines/${quoteLine?.id}` : `/quote_lines/${quoteLine?.id}`,
    invalidate: 'quotes',
  })

  const handleSubscribe = async () => {
    try {
      await mutateAsync({
        is_selected: true,
      })

      setIsSelected(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleUnsubscribe = async () => {
    try {
      await mutateAsync({
        is_selected: false,
      })

      setIsSelected(false)
    } catch (error) {
      console.error(error)
    }
  }

  if (!quoteLine) return null

  const isRequired = !!quoteLine?.product?.is_required

  if (isRequired) {
    return <Status label="Selected" color={COLORS.green} className="!justify-center !text-center !min-w-[120px] mr-3" />
  }

  return (
    <>
      {isSelected ? (
        <>
          <Status label="Selected" color={COLORS.green} className="!justify-center !text-center !min-w-[120px] mr-3" />

          {canEdit && (
            <Button
              label="Remove Add-On"
              type="default"
              glyph="cross"
              glyphColor="text"
              color="text"
              size={100}
              onClick={handleUnsubscribe}
              isLoading={isLoading}
            />
          )}
        </>
      ) : (
        <>
          <Status label="Not Selected" color={COLORS.red} className="!justify-center !text-center !min-w-[120px] mr-3" />

          {canEdit && (
            <Button
              label="Select Add-On"
              type="primary"
              glyph="tick_circle"
              color="green"
              size={100}
              onClick={handleSubscribe}
              isLoading={isLoading}
            />
          )}
        </>
      )}
    </>
  )
}
