import React from 'react'
import { tint } from 'polished'
import startCase from 'lodash/startCase'

import { COLORS } from '../../../theme'
import { COMMUNICABLE_DISEASE_TESTS } from '../../../utils/constants'
import { usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import DataList from '../../../components/DataList'
import Status from '../../../components/Status'
import TestResultStatus from '../../../components/Statuses/TestResultStatus'

export const CommunicableDiseaseTestInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item label="Test Type" value={COMMUNICABLE_DISEASE_TESTS[data.category]} />
      <DataList.Item label="Status" value={<TestResultStatus status={data.status} />} />
      {data.client && <DataList.Item label="Client" value={data.client.name} avatar={data.client.avatar} />}

      {data.status !== 'refused' && (
        <DataList.Item
          label="Result"
          value={
            <>
              {data.result === 'positive' ? (
                <Status color="red" label="Positive" />
              ) : data.result === 'negative' ? (
                <Status color="green" label="Negative" />
              ) : data.result === 'not_available' ? (
                <Status color="grey" label="N/A" />
              ) : null}
            </>
          }
        />
      )}

      <DataList.Item label="Quantitative Measure" value={data.quantitative_measure && startCase(data.quantitative_measure)} />

      <DataList.Item label="Date & Time" value={usDateTime(data.recorded_at, timezone)} />
    </DataList>
  )
}
