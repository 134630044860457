import React from 'react'

import { withOverlayError } from '../../hocs/withOverlayError'

import Card from '../../components/Card'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Score from '../../components/Overlays/pages/DataForms/common/Score'
import Section from '../../components/Section'
import GridTable from '../../components/GridTable'

import { DataFormOverlay } from '../DataFormOverlay'

const RootMIRCOverlay = (props: any) => {
  const { category = 'recovery_coaching', icon = 'recovery_coaching', permissionName } = props

  return (
    <DataFormOverlay
      minimizeEnabled
      clientPortalCanEdit
      enableTreatmentPlanSelector
      title="Multidimensional Inventory of Recovery Capital (MIRC)"
      category={category}
      subcategory="mirc"
      icon={icon}
      requestClientSignature={false}
      requestAuthorSignature={false}
      requestSupervisorSignature={false}
      requireSupervisor={false}
      permissionName={permissionName}
      {...props}
    >
      <>
        <Section
          title="Instructions"
          headingType="h2"
          scrollview={{
            id: 'instructions',
            name: 'Instructions',
          }}
        >
          <p>
            This survey asks how strongly you agree or disagree with each statement based on your current situation or how you feel right
            now. Your answers help identify resources and challenges in recovery. There are no right or wrong answers.
          </p>
        </Section>

        <Divider />

        <Section
          title="Questionnaire & Scoring"
          headingType="h2"
          scrollview={{
            id: 'questionnaire_scoring',
            name: 'Questionnaire & Scoring',
          }}
          commentsModel="data.questionnaire_scoring"
        >
          <Questionnaire />
        </Section>

        <Divider />

        <Section title="References" headingType="h2">
          <p>
            Bowen, E., Irish, A., Wilding, G., LaBarre, C., Capozziello, N., Nochajski, T., Granfield, R., & Kaskutas, L. A. (2023).
            Development and psychometric properties of the Multidimensional Inventory of Recovery Capita (MIRC). Drug and Alcohol
            Dependence. Advance online publication.{' '}
            <a href="https://doi.org/10.1016/j.drugalcdep.2023.109875" target="_blank">
              https://doi.org/10.1016/j.drugalcdep.2023.109875
            </a>
          </p>
        </Section>
      </>
    </DataFormOverlay>
  )
}

const RadioQuestion = ({ label, model, setScore }: any) => (
  <RadioGroup
    label={label}
    model={`data.questionnaire.${model}`}
    layout="vertical-dense"
    onUpdate={({ value }: any) => setScore((o: any) => ({ ...o, [model]: value || 0 }))}
  >
    <Radio label="1 = Strongly Disagree" value={1} />
    <Radio label="2 = Disagree" value={2} />
    <Radio label="3 = Agree" value={3} />
    <Radio label="4 = Strongly Agree" value={4} />
  </RadioGroup>
)

const INITIAL_STATE = {
  q1: 0,
  q2: 0,
  q3: 0,
  q4: 0,
  q5: 0,
  q6: 0,
  q7: 0,
  q8: 0,
  q9: 0,
  q10: 0,
  q11: 0,
  q12: 0,
  q13: 0,
  q14: 0,
  q15: 0,
  q16: 0,
  q17: 0,
  q18: 0,
  q19: 0,
  q20: 0,
  q21: 0,
  q22: 0,
  q23: 0,
  q24: 0,
  q25: 0,
  q26: 0,
  q27: 0,
  q28: 0,
}

const REVERSED_QUESTIONS = ['q2', 'q5', 'q6', 'q9', 'q10', 'q12', 'q15', 'q17', 'q18', 'q20', 'q22', 'q24', 'q25', 'q27']

const REVERSED_SCORES = {
  4: 1,
  3: 2,
  2: 3,
  1: 4,
} as const

const SECTIONS = [
  {
    name: 'Social Capital',
    questions: ['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'q7'],
  },
  {
    name: 'Physical Capital',
    questions: ['q8', 'q9', 'q10', 'q11', 'q12', 'q13', 'q14'],
  },
  {
    name: 'Human Capital',
    questions: ['q15', 'q16', 'q17', 'q18', 'q19', 'q20', 'q21'],
  },
  {
    name: 'Cultural Capital',
    questions: ['q22', 'q23', 'q24', 'q25', 'q26', 'q27', 'q28'],
  },
]

const Questionnaire = () => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.keys(score).reduce((acc: any, q: any) => {
    const reversed = REVERSED_QUESTIONS.includes(q)
    const value = reversed ? REVERSED_SCORES[score[q]] : score[q]

    return acc + value
  }, 0)

  return (
    <>
      <FormSection>
        <h3 className="mt-4 -mb-2">Social Capital</h3>
        <RadioQuestion label="1. I actively support other people who are in recovery." model="q1" setScore={setScore} />

        <RadioQuestion label="2. My family makes my recovery more difficult." model="q2" setScore={setScore} />

        <RadioQuestion label="3. I have at least one friend who supports my recovery." model="q3" setScore={setScore} />

        <RadioQuestion label="4. My family supports my recovery." model="q4" setScore={setScore} />

        <RadioQuestion label="5. Some people in my life do not think I'll make it in my recovery." model="q5" setScore={setScore} />

        <RadioQuestion label="6. I feel alone." model="q6" setScore={setScore} />

        <RadioQuestion label="7. I feel like I'm part of a recovery community." model="q7" setScore={setScore} />

        <h3 className="mt-4 -mb-2">Physical Capital</h3>
        <RadioQuestion label="8. My housing situation is helpful for my recovery." model="q8" setScore={setScore} />

        <RadioQuestion label="9. I have difficulty getting transportation." model="q9" setScore={setScore} />

        <RadioQuestion label="10. My housing situation is unstable." model="q10" setScore={setScore} />

        <RadioQuestion label="11. I have enough money every week to buy the basic things I need." model="q11" setScore={setScore} />

        <RadioQuestion label="12. Not having enough money makes my recovery more difficult." model="q12" setScore={setScore} />

        <RadioQuestion
          label="13. I can afford the care I need for my health, mental health, and recovery."
          model="q13"
          setScore={setScore}
        />

        <RadioQuestion label="14. I have reliable access to a phone and the internet." model="q14" setScore={setScore} />

        <h3 className="mt-4 -mb-2">Human Capital</h3>
        <RadioQuestion label="15. I find it hard to have fun." model="q15" setScore={setScore} />

        <RadioQuestion label="16. I feel physically healthy most days." model="q16" setScore={setScore} />

        <RadioQuestion label="17. I am struggling with guilt or shame." model="q17" setScore={setScore} />

        <RadioQuestion label="18. I am experiencing a lot of stress." model="q18" setScore={setScore} />

        <RadioQuestion
          label="19. My education and training have prepared me to handle life's challenges."
          model="q19"
          setScore={setScore}
        />

        <RadioQuestion label="20. I have problems with my mental health." model="q20" setScore={setScore} />

        <RadioQuestion label="21. I feel my life has purpose and meaning." model="q21" setScore={setScore} />

        <h3 className="mt-4 -mb-2">Cultural Capital</h3>
        <RadioQuestion label="22. It's hard for me to trust others." model="q22" setScore={setScore} />

        <RadioQuestion
          label="23. I have opportunities to participate in fun activities that do not involve drugs and alcohol."
          model="q23"
          setScore={setScore}
        />

        <RadioQuestion label="24. I feel disconnected from my culture or not part of any culture." model="q24" setScore={setScore} />

        <RadioQuestion label="25. I feel like an outcast." model="q25" setScore={setScore} />

        <RadioQuestion label="26. There are helpful services and resources accessible to me." model="q26" setScore={setScore} />

        <RadioQuestion
          label="27. It's hard to let go of the part of my identity that was linked to my drinking or drug use."
          model="q27"
          setScore={setScore}
        />

        <RadioQuestion label="28. My neighborhood or town feels safe." model="q28" setScore={setScore} />
      </FormSection>

      <Divider />

      <Grid gap="1rem">
        <Score hideScoreHeader finalScore={finalScore}>
          <h3>Scoring Instructions:</h3>

          <p>
            <span className="font-[600]">Step 1.</span> Write the score for each survey item in the 'Starting Score' column
          </p>

          <p>
            <span className="font-[600]">Step 2.</span> For the items highlighted in yellow (items 2, 5, 6, 9, 10, 12, 15, 17, 18, 20, 22,
            24, 25, and 27), change the scores from 4 to 1, 3 to 2, 2 to 3, or 1 to 4. Write the new score in the 'Reverse Score' column.
          </p>

          <p>
            <span className="font-[600]">Step 3.</span> If the item has a 'Reverse Score' write that in the 'Final Score' column. If it does
            not, write the 'Starting Score' in the 'Final Score' column
          </p>

          <p>
            <span className="font-[600]">Step 4.</span> Add the final scores in each section together. Write this in the light gray Social,
            Physical, Human, and Cultural Capital 'Total' boxes. The minimum score is 7, the middle score is 17-18, and the maximum score is
            28.
          </p>

          <p>
            <span className="font-[600]">Step 5.</span> Add the 'Total' scores from each section and write this in the dark gray 'Overall
            Recovery Capital' 'Total' box. The minimum score is 28, the middle score is 70, and the maximum score is 112.{' '}
          </p>

          <GridTable
            useBanding={false}
            useRowHover={false}
            templateColumns="220px repeat(4, minmax(100px, 1fr))"
            className="mt-4 -ml-4 -mr-4 border-t border-0 border-solid border-divider"
          >
            <GridTable.Header>
              <GridTable.Cell>Item #</GridTable.Cell>
              <GridTable.Cell>Starting Score</GridTable.Cell>
              <GridTable.Cell>Reverse Score</GridTable.Cell>
              <GridTable.Cell>Final Score</GridTable.Cell>
              <GridTable.Cell>Total</GridTable.Cell>
            </GridTable.Header>

            {SECTIONS.map(({ name, questions }) => (
              <ScoreSection key={name} name={name} questions={questions} score={score} />
            ))}

            <GridTable.Row className="bg-gray text-white font-[700] text-[1.05rem]">
              <GridTable.Cell>Overall Recovery Capital</GridTable.Cell>
              <GridTable.Cell></GridTable.Cell>
              <GridTable.Cell></GridTable.Cell>
              <GridTable.Cell></GridTable.Cell>
              <GridTable.Cell>{finalScore || '–'}</GridTable.Cell>
            </GridTable.Row>
          </GridTable>
        </Score>
      </Grid>
    </>
  )
}

const ScoreSection = (props: any) => {
  const { name, questions, score } = props

  const sectionScore = questions.reduce((acc: any, q: any) => {
    const reversed = REVERSED_QUESTIONS.includes(q)
    const value = reversed ? REVERSED_SCORES[score[q]] : score[q]
    return acc + value || 0
  }, 0)

  return (
    <>
      {questions.map((q: any) => {
        const startScore = score[q]
        const isReversed = REVERSED_QUESTIONS.includes(q)
        const reversedScore = isReversed ? REVERSED_SCORES[startScore] : null
        const finalScore = isReversed ? reversedScore : startScore

        const className = isReversed ? 'bg-yellow bg-opacity-30' : ''

        return (
          <GridTable.Row key={q}>
            <GridTable.Cell className={className}>
              <span className="font-[600]">{q.replace('q', '')}</span>
            </GridTable.Cell>
            <GridTable.Cell className={className}>{startScore || '–'}</GridTable.Cell>
            <GridTable.Cell className={className}>{isReversed && (reversedScore || '–')}</GridTable.Cell>
            <GridTable.Cell className={className}>{finalScore || '–'}</GridTable.Cell>
            <GridTable.Cell></GridTable.Cell>
          </GridTable.Row>
        )
      })}

      <GridTable.Row className="bg-light-gray bg-opacity-70 font-[700] text-[1.05rem]">
        <GridTable.Cell>{name}</GridTable.Cell>
        <GridTable.Cell></GridTable.Cell>
        <GridTable.Cell></GridTable.Cell>
        <GridTable.Cell></GridTable.Cell>
        <GridTable.Cell>{sectionScore || '–'}</GridTable.Cell>
      </GridTable.Row>
    </>
  )
}

export const MIRCOverlay = withOverlayError(RootMIRCOverlay)
