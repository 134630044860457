import React from 'react'
import { useLocation } from 'react-router-dom'

import { usDateTime, usDate, titleDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import { Text } from '../Typography'
import DataFormStatus from '../Statuses/DataFormStatus'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'

type Props = {
  data: any
  location: any
  timezone: any
  to: any
}

const ProgressReview = ({ data, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={titleDate(data?.created_at, timezone)} />
      <CardMeta>
        <DataFormStatus status={data?.status} />
        <Text avatar={data?.author?.avatar} label={data?.author?.name} />
        <Text glyph="date" label="Last Edited: " description={usDateTime(data?.updated_at, timezone)} />
        <Text glyph="date" label="Next Review Date: " description={usDate(data?.data?.summary?.next_review_date, timezone)} />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(ProgressReview)
