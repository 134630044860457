import React from 'react'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

const ClaimFilingCodeSelect: React.FC<any> = ({ model = 'claim_filing_code', ...rest }) => (
  <Select label="Claim Filing Code" model={model} defaultValue="CI" {...rest}>
    <Option label="11 - Other Non-Federal Programs" value="11" />
    <Option label="12 - Preferred Provider Organization (PPO)" value="12" />
    <Option label="13 - Point of Service (POS)" value="13" />
    <Option label="14 - Exclusive Provider Organization (EPO)" value="14" />
    <Option label="15 - Indemnity Insurance" value="15" />
    <Option label="16 - Health Maintenance Organization (HMO) Medicare Risk" value="16" />
    <Option label="17 - Dental Maintenance Organization" value="17" />
    <Option label="AM - Automobile Medical" value="AM" />
    <Option label="BL - Blue Cross/Blue Shield" value="BL" />
    <Option label="CH - Champus" value="CH" />
    <Option label="CI - Commercial Insurance Co." value="CI" />
    <Option label="DS - Disability" value="DS" />
    <Option label="FI - Federal Employees Program" value="FI" />
    <Option label="HM - Health Maintenance Organization" value="HM" />
    <Option label="LM - Liability Medical" value="LM" />
    <Option label="MA - Medicare Part A" value="MA" />
    <Option label="MB - Medicare Part B" value="MB" />
    <Option label="MC - Medicaid" value="MC" />
    <Option label="OF - Other Federal Program" value="OF" />
    <Option label="TV - Title V" value="TV" />
    <Option label="VA - Veterans Affairs Plan" value="VA" />
    <Option label="WC - Workers' Compensation Health Claim" value="WC" />
    <Option label="ZZ - Mutually Defined" value="ZZ" />
  </Select>
)

export default ClaimFilingCodeSelect
