import React from 'react'
import size from 'lodash/size'

import { apiGet, apiUpdate } from '../../modules/api'
import { countWord } from '../../utils/functions'
import { useSettings } from '../../hooks'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../Alert'
import Button from '../Button'
import Form from '../Forms/Form'
import FormSection from '../Forms/FormSection'
import Label from '../Label'
import Link from '../Link'
import ObjectSelector from '../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../Overlay'
import Section from '../Section'
import StaffProfileHeader from '../StaffProfileHeader'

const RootBatchApplyPermissionTemplateOverlay = ({ employees = [], onClose, onSuccess }: any) => {
  const { online } = useSettings()

  const form = React.useRef()
  const [valid, setValid] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const applyTemplate = async () => {
    setLoading(true)

    const formData = form.current.getFormValue()

    const saveData = {
      employee_ids: employees?.map?.((o) => o.id),
      permissions_template_id: formData.permissions_template_id,
    }

    try {
      await apiUpdate({
        name: 'employees',
        url: `/employees/apply_permission_templates`,
        params: saveData,
      })

      await apiGet({ url: '/me', reducer: 'me' })

      onSuccess?.()
      onClose?.()

      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  if (size(employees) === 0) return null

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header icon="permissions" title="Apply Staff Permissions" />

      <Overlay.Content>
        <Form isEditable getForm={form} onValidationUpdate={setValid}>
          <Section>
            <FormSection>
              <Alert contrast type="warning">
                Permission Templates are editable at <Link to="/settings/permission-templates">Settings / Staff Permissions</Link>
              </Alert>

              <ObjectSelector
                label="Apply Permission Template"
                icon="permissions"
                type="permission_templates"
                model="permissions_template"
                selectTitle={(data: any) => data?.name}
                selectDescription={() => null}
                validations={{
                  presence: {
                    message: 'Please select a permission to apply',
                  },
                }}
              />

              <div>
                <Label label={`To ${countWord('Staff Members', size(employees))}`} />

                <div className="grid gap-3 mt-1">
                  {employees.map((employee) => (
                    <StaffProfileHeader key={employee.id} employee={employee} />
                  ))}
                </div>
              </div>

              <Alert glyph="warning" type="negative">
                The selected Permission template will be applied immediately on this record. The previous settings will be replaced.
              </Alert>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer online={online}>
        <Button
          label="Apply new Permissions"
          type="primary"
          color="green"
          glyph="warning"
          onClick={applyTemplate}
          isLoading={loading}
          isDisabled={!valid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const BatchApplyPermissionTemplateOverlay = withOverlayError(RootBatchApplyPermissionTemplateOverlay)
