import React from 'react'

import { request } from '../../modules/axios'
import useIsMounted from '../../hooks/useIsMounted'
import withPermissions from '../../hocs/withPermissions'

import Animated from '../Animated'
import Overlay from '../Overlay'
import Portal from '../Portal'

import { NotionRenderer } from './NotionRenderer'

const NotionOverlay = (props: any) => {
  const {
    children,
    className,
    closeOnBackdrop,
    glyph,
    icon,
    overlayPosition = 'center',
    overlayWidth,
    show,
    title,
    blockID,
    stopPropagation,
  } = props

  const [isOpen, setIsOpen] = React.useState(show)
  const [isLoading, setIsLoading] = React.useState(false)
  const [blockData, setBlockData] = React.useState(null)

  const isMounted = useIsMounted()

  const open = async (event: any) => {
    if (stopPropagation) {
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()
    }

    setIsOpen(true)
    await getBlock(blockID)
  }

  const close = () => {
    if (!isMounted) return
    setIsOpen(false)
  }

  const getBlock = async (blockID: string) => {
    setIsLoading(true)
    try {
      const response = await request.get(`${process.env.BH_NOTION_API_BASE}/api/page/${blockID}`, {
        headers: null,
      })
      setBlockData(response.data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div onClick={open} className={className}>
        {children}
      </div>

      <Portal type="notion">
        <Animated show={isOpen} animation="animation">
          <Overlay
            fullheight
            showBackdrop
            stopPropagation
            closeOnBackdrop={closeOnBackdrop}
            position={overlayPosition}
            onClose={close}
            maxWidth={overlayWidth}
            isLoading={isLoading}
          >
            <Overlay.Header title={title} glyph={glyph} icon={icon} />

            <Overlay.Content>
              {blockData && <NotionRenderer disableHeader fullPage recordMap={blockData} darkMode={false} />}
            </Overlay.Content>
          </Overlay>
        </Animated>
      </Portal>
    </>
  )
}

NotionOverlay.defaultProps = {
  title: 'Title',
  overlayWidth: 80,
  closeOnBackdrop: true,
  show: false,
  stopPropagation: true,
}

export default withPermissions(NotionOverlay)
