import React from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import merge from 'lodash/merge'
import produce from 'immer'

import { H3, Text } from '../../components/Typography'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ClientStatus from '../../components/Statuses/ClientStatus'
import DataList from '../../components/DataList'
import Dialog from '../../components/Dialog'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import IntakeStatus from '../../components/Statuses/IntakeStatus'
import Overlay from '../../components/Overlay'
import Workflow from '../../components/Workflow/Workflow'

import BedDischarge from './common/BedDischarge'
import ClientPortalDischarge from './common/ClientPortalDischarge'
import ClinicalMeasures from './common/ClinicalMeasures'
import Contacts from './common/Contacts'
import DischargeDetails from './common/DischargeDetails'
import DischargeSummaries from './common/DischargeSummaries'
import Events from './common/Events'
import FinancialsWithCancel from './common/FinancialsWithCancel'
import HomeAddress from './common/HomeAddress'
import Medications from './common/Medications'
import Messages from './common/Messages'
import OutcomeMeasures from './common/OutcomeMeasures'
import PersonalDetails from './common/PersonalDetails'
import ProgramsDischarge from './common/ProgramsDischarge'
import RecoveryNotes from './common/RecoveryNotes'
import ServiceEpisodes from './common/ServiceEpisodes'
import StaffAccess from './common/StaffAccess'
import Todos from './common/Todos'
import TreatmentPlans from './common/TreatmentPlans'

import { usDateTime } from '../../utils/functions'
import { useGet, useCreate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { useTreatmentPlanPermissions } from '../../constructs/TreatmentPlans/useTreatmentPlanPermissions'
import { withOverlayError } from '../../hocs/withOverlayError'
import withSettings from '../../hocs/withSettings'

import { SecureMessage } from './common/SecureMessage'

export const DISCHARGE_STEPS: any = {
  discharge_details: {
    label: 'Discharge Details',
    isRequired: true,
  },
  discharge_summaries: {
    label: 'Discharge Summary',
    isRequiredOptional: true,
    featureFlagV2: 'discharges',
  },
  bed_assignment: {
    label: 'Bed Discharge',
    isRequiredOptional: true,
    featureFlagV2: 'bed_management',
  },
  service_episodes: {
    label: 'Service Episodes',
    isRequiredOptional: true,
    featureFlagV2: 'service_episodes',
  },
  personal_details: {
    label: 'Personal Details',
    isRequiredOptional: true,
  },
  home_address: {
    label: 'Home Address',
    isRequiredOptional: true,
  },
  contacts: {
    label: 'Contacts',
    isRequiredOptional: true,
    featureFlagV2: 'contacts',
  },
  programs_discharge: {
    label: 'Programs Discharge',
    featureFlagV2: 'programs',
  },
  financials: {
    label: 'Financials',
    featureFlagV2: 'financials',
  },
  treatment_plans: {
    label: 'Treatment Plans',
    featureFlagV2: 'treatment_plans',
  },
  todos: {
    label: 'Todos',
    isRequiredOptional: true,
    featureFlagV2: 'todos',
  },
  events: {
    label: 'Events',
    isRequiredOptional: true,
    featureFlagV2: 'calendar',
  },
  staff_access: {
    label: 'Staff Access',
    featureFlagV2: 'staff_clients_access',
  },
  client_portal: {
    label: 'Client Portal',
    featureFlagV2: 'client_portal',
  },
  message_board: {
    label: 'Message Board',
    isRequiredOptional: true,
    featureFlagV2: 'message_board',
  },
  medications: {
    label: 'Medications',
    isRequiredOptional: true,
    featureFlagV2: 'medications',
  },
  recovery_notes: {
    label: 'Recovery Notes',
    isRequiredOptional: true,
    featureFlagV2: 'recovery_coaching',
  },
  clinical_measures: {
    label: 'Clinical Measures',
    isRequiredOptional: true,
    featureFlagV2: 'clinical_measures',
  },
  outcome_measures: {
    label: 'Outcome Measures',
    isRequiredOptional: true,
    featureFlagV2: 'outcome_measures',
  },
  discharge: {
    label: 'Discharge',
    isMandatory: true,
  },
}

const stepsReducer = (state: any, payload: any) => {
  const { step, status } = payload
  if (!status || !state.hasOwnProperty(step)) return state

  return produce(state, (draft: any) => {
    draft[step] = status
  })
}

const initialData = {
  remove_access_controls: true,
  remove_from_programs: true,
  remove_client_portal_access: true,
  should_end_current_bed_occupancy: 'on_discharge_date',
  should_end_current_program_occupancies: 'on_discharge_date',
  close_active_treatment_plans: true,
  expire_messages: true,
}

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const RootIntakeDischarge = ({ online, timezone }: any) => {
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()

  const { resource_id }: any = match.params

  const { tenant, isBehave, isSelfServiceBilling } = useSettings()

  const initialSteps = React.useMemo(() => {
    const result: any = {}

    for (const step of Object.keys(DISCHARGE_STEPS)) {
      result[step] = 'todo'
    }

    return result
  }, [DISCHARGE_STEPS])

  const [steps, setSteps] = React.useReducer(stepsReducer, initialSteps)
  const [data, setData] = React.useReducer(dataReducer, initialData)

  const canDischarge = data?.discharged_at

  const { viewSomeTreatmentPlans } = useTreatmentPlanPermissions()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const { mutateAsync, isLoading: isDischarging }: any = useCreate({
    name: ['client', resource_id],
    url: `/residents/${resource_id}/discharge`,
    invalidate: 'clients',
    invalidateKeys: [['client', resource_id]],
  })

  React.useEffect(() => {
    if (!client) return

    updateData({
      occupancy: client.current_bed_occupancy || client.next_bed_occupancy,
    })
  }, [client])

  const close = () => {
    if (location.parent) {
      if (location.parent.url) history.push(location.parent.url)
      else history.push(location.parent)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  const discharge = async () => {
    await mutateAsync(data)

    close()

    history.push(`/alumni/${resource_id}/general-info`) // go to the Alumni page
  }

  const updateData = (newValue: any) => {
    const newData = produce(data, (draft: any) => {
      draft = merge(draft, newValue)
    })

    setData(newData)
  }

  if (!client) return null

  return (
    <Overlay
      fullheight
      showBackdrop
      onClose={close}
      position="center"
      maxWidth={44}
      closeOnBackdrop={false}
      closeWrapper={(closeElement: any) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost if not saved on the Review & Save step"
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={close}
        >
          {closeElement}
        </Dialog>
      )}
    >
      <Overlay.Header glyph="discharge" title="Discharge Client" />

      <Overlay.Content>
        <Workflow
          name="Discharge Client"
          config={DISCHARGE_STEPS}
          stepStatuses={steps}
          visibilityOptions={tenant?.client_workflow_options?.discharge}
          requiredOptions={tenant?.client_workflow_options?.discharge_required_steps}
          stepInstructions={tenant?.client_workflow_options?.discharge_instructions}
        >
          {/* Discharge Details */}
          <Workflow.Panel step="discharge_details">
            <Workflow.Header title="Discharge Details" after={<Workflow.Status status={steps.discharge_details} />} />
            <Workflow.Content>
              <DischargeDetails
                data={data}
                setData={updateData}
                firstName={client?.first_name}
                setStatus={(status: string) => {
                  setSteps({ step: 'discharge_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Discharge Summaries */}
          <Workflow.Panel showForCommunity step="discharge_summaries" permission="discharges.view" featureFlagV2="discharges">
            <Workflow.Header title="Discharge Summary" after={<Workflow.Status status={steps.discharge_summaries} />} />
            <Workflow.Content>
              <DischargeSummaries
                data={data}
                setData={updateData}
                firstName={client?.first_name}
                setStatus={(status: string) => {
                  setSteps({ step: 'discharge_summaries', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Bed Assignment */}
          <Workflow.Panel showForCommunity step="bed_assignment" featureFlagV2="bed_management">
            <Workflow.Header title="Bed Discharge" after={<Workflow.Status status={steps.bed_assignment} />} />
            <Workflow.Content>
              <BedDischarge
                data={data}
                hasCurrentOccupancy={!!client.current_bed_occupancy || !!client.next_bed_occupancy}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'bed_assignment', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Service Episodes */}
          <Workflow.Panel
            step="service_episodes"
            permission={isBehave ? true : isSelfServiceBilling ? 'service_episodes.view' : false}
            featureFlagV2="service_episodes"
          >
            <Workflow.Header title="Service Episodes" after={<Workflow.Status status={steps.service_episodes} />} />
            <Workflow.Content>
              <ServiceEpisodes
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'service_episodes', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Personal Details */}
          <Workflow.Panel step="personal_details">
            <Workflow.Header title="Personal Details" after={<Workflow.Status status={steps.personal_details} />} />
            <Workflow.Content>
              <PersonalDetails
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'personal_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>
          {/* Home Address */}
          <Workflow.Panel step="home_address">
            <Workflow.Header title="Home Address" after={<Workflow.Status status={steps.home_address} />} />
            <Workflow.Content>
              <HomeAddress
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'home_address', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Contacts */}
          <Workflow.Panel step="contacts" featureFlagV2="contacts">
            <Workflow.Header title="Contacts" after={<Workflow.Status status={steps.contacts} />} />
            <Workflow.Content>
              <Contacts
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'contacts', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Programs Discharge */}
          <Workflow.Panel step="programs_discharge" featureFlagV2="programs">
            <Workflow.Header title="Programs" after={<Workflow.Status status={steps.programs_discharge} />} />
            <Workflow.Content>
              <ProgramsDischarge
                client={client}
                data={data}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'programs_discharge', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Financials */}
          <Workflow.Panel step="financials" newFeatureFlag="rcm_consumer">
            <Workflow.Header title="Financials" after={<Workflow.Status status={steps.financials} />} />
            <Workflow.Content>
              <FinancialsWithCancel
                match={match}
                location={location}
                firstName={client?.first_name}
                data={data}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'financials', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Treatment Plans */}
          <Workflow.Panel step="treatment_plans" permission={viewSomeTreatmentPlans} featureFlagV2="treatment_plans">
            <Workflow.Header title="Treatment Plans" after={<Workflow.Status status={steps.treatment_plans} />} />
            <Workflow.Content>
              <TreatmentPlans
                match={match}
                location={location}
                client={client}
                firstName={client?.first_name}
                data={data}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'treatment_plans', status })
                }}
              >
                <Divider className="!my-4" />

                <div className="grid gap-4">
                  <Text>{`On Discharge, close all Active Treatment Plans that ${client?.first_name || 'the client'} has`}</Text>

                  <CheckboxGroup>
                    <Checkbox defaultChecked label="Close All Active Treatment Plans" model="close_active_treatment_plans" />
                  </CheckboxGroup>
                </div>
              </TreatmentPlans>
            </Workflow.Content>
          </Workflow.Panel>

          {/* Todos */}
          <Workflow.Panel showForCommunity step="todos">
            <Workflow.Header title="To-Do's" after={<Workflow.Status status={steps.todos} />} />
            <Workflow.Content>
              <Todos
                match={match}
                timezone={timezone}
                setStatus={(status: string) => {
                  setSteps({ step: 'todos', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Events */}
          <Workflow.Panel showForCommunity step="events">
            <Workflow.Header title="Events" after={<Workflow.Status status={steps.events} />} />
            <Workflow.Content>
              <Events
                match={match}
                timezone={timezone}
                setStatus={(status: string) => {
                  setSteps({ step: 'events', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Staff Access */}
          <Workflow.Panel step="staff_access">
            <Workflow.Header title="Staff Access" after={<Workflow.Status status={steps.staff_access} />} />
            <Workflow.Content>
              <StaffAccess
                data={data}
                setData={updateData}
                firstName={client?.first_name}
                setStatus={(status: string) => {
                  setSteps({ step: 'staff_access', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Client Portal */}
          <Workflow.Panel step="client_portal">
            <Workflow.Header title="Client Portal" after={<Workflow.Status status={steps.client_portal} />} />
            <Workflow.Content>
              <ClientPortalDischarge
                data={data}
                client={client}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'client_portal', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Message Board */}
          <Workflow.Panel step="message_board" permission="messages.view" featureFlagV2="message_board">
            <Workflow.Header title="Message Board" after={<Workflow.Status status={steps.message_board} />} />
            <Workflow.Content>
              <Messages
                match={match}
                timezone={timezone}
                data={data}
                client={client}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'message_board', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Medications */}
          <Workflow.Panel step="medications" permission="medications.view" featureFlagV2="medications">
            <Workflow.Header title="Medications" after={<Workflow.Status status={steps.medications} />} />
            <Workflow.Content>
              <Medications
                match={match}
                timezone={timezone}
                data={data}
                client={client}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'medications', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Recovery Notes */}
          <Workflow.Panel step="recovery_notes" permission="recovery_coaching.view" featureFlagV2="recovery_coaching">
            <Workflow.Header title="Recovery Notes" after={<Workflow.Status status={steps.recovery_notes} />} />
            <Workflow.Content>
              <RecoveryNotes
                match={match}
                timezone={timezone}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'recovery_notes', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Outcome Measures */}
          <Workflow.Panel step="clinical_measures" permission="clinical_measurements.view" featureFlagV2="clinical_measures">
            <Workflow.Header title="Clinical Measures" after={<Workflow.Status status={steps.clinical_measures} />} />
            <Workflow.Content>
              <ClinicalMeasures
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'clinical_measures', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Outcome Measures */}
          <Workflow.Panel step="outcome_measures" permission="outcome_measures.view" featureFlagV2="outcome_measures">
            <Workflow.Header title="Outcome Measures" after={<Workflow.Status status={steps.outcome_measures} />} />
            <Workflow.Content>
              <OutcomeMeasures
                match={match}
                timezone={timezone}
                client={client}
                subcategories={['forse_discharge_survey']}
                setStatus={(status: string) => {
                  setSteps({ step: 'outcome_measures', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Discharge */}
          <Workflow.Panel step="discharge">
            <Workflow.Header title="Discharge" after={<Workflow.Status status={steps.discharge} />} />
            <Workflow.Content>
              <DischargeStep
                canDischarge={canDischarge}
                data={data}
                setData={updateData}
                timezone={timezone}
                discharge={discharge}
                isDischarging={isDischarging}
                steps={steps}
              />
            </Workflow.Content>
          </Workflow.Panel>
        </Workflow>
      </Overlay.Content>
    </Overlay>
  )
}

const DischargeStep = (props: any) => {
  const { canDischarge, data, setData, timezone, discharge, isDischarging } = props

  const { isAllCompleted }: any = React.useContext(Workflow.Context)

  return (
    <FormSection maxWidth="100%">
      {!canDischarge && (
        <Alert glyph="warning" type="warning">
          Please set a discharge date.
        </Alert>
      )}

      {!isAllCompleted && (
        <Alert contrast glyph="warning" type="warning">
          Please complete all required steps.
        </Alert>
      )}

      {canDischarge && isAllCompleted && (
        <>
          <DataList>
            <DataList.Item label="Discharge Date" value={usDateTime(data?.discharged_at, timezone)} />
            <DataList.Item label="Cancel Planned Charges" value="Yes" />
            <DataList.Item label="Remove from Staff Access Lists" value={data?.remove_access_controls ? 'Yes' : 'No'} />
            <DataList.Item label="Remove from All Programs" value={data?.remove_from_programs ? 'Yes' : 'No'} />
          </DataList>

          <Grid gap={16} style={{ marginBottom: '2rem' }}>
            <H3>Upon Discharge…</H3>
            <Text
              glyph="check"
              layout="vertical"
              description={
                <span style={{ marginRight: '0.5rem' }}>
                  The client's status will be set to <ClientStatus status="past" inline />
                </span>
              }
              setIconColor={false}
            />
            <Text
              glyph="check"
              layout="vertical"
              description={
                <span style={{ marginRight: '0.5rem' }}>
                  This episode will be set to <IntakeStatus status="discharged" inline />
                </span>
              }
              setIconColor={false}
            />
            <Text
              glyph="check"
              layout="vertical"
              description={<span style={{ marginRight: '0.5rem' }}>The client will be unassigned from their Bed (if assigned one)</span>}
              setIconColor={false}
            />
          </Grid>

          <SecureMessage data={data} setData={setData} category="discharge" />

          <Button
            label="Discharge Client"
            glyph="check_in"
            type="primary"
            color="green"
            onClick={discharge}
            isLoading={isDischarging}
            isDisabled={!canDischarge}
          />
        </>
      )}
    </FormSection>
  )
}

export const IntakeDischarge = withOverlayError(withSettings(RootIntakeDischarge))
