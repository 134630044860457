import React from 'react'

import { titleCase } from '../../../utils/functions'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

export const ColorCell: React.FC<any> = (props) => {
  return (
    <DataTableCell {...props} css={STYLES.root} hoverExpand={false}>
      {props.value ? <ColorSwatch color={props.value} /> : <EmptyCell />}
    </DataTableCell>
  )
}

const ColorSwatch: React.FC<any> = (props) => {
  return (
    <div
      style={{
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: props.color,
      }}
    />
  )
}

const STYLES = {
  root: {
    textAlign: 'center',
    justifyContent: 'center',

    '& > *': {
      flex: '0 0 auto !important',
    },
  },
}
