import React from 'react'
import { Global } from '@emotion/react'
import { useParams } from 'react-router-dom'

import { request, buildBaseUrl } from '@behavehealth/modules/axios'

import { COLORS } from '@behavehealth/theme'
import { Grid, Button, Alert, PageLoader, Markup } from '@behavehealth/components'

import { ApplicationForm, NotificationsContainer, PortalsContainer } from '@behavehealth/components'
import Notifications from '@behavehealth/modules/notifications'

const logoUrl = (tenant_id) => {
  return `${process.env.BH_API_BASE_PROTOCOL}://${process.env.BH_API_BASE_URL}/v1/logo/${tenant_id}`
}

export const ApplicationForms: React.FC = () => {
  const { tenant_id, slug }: any = useParams()

  const [applicationForm, setApplicationForm]: any = React.useState(null)

  const [data, setData]: any = React.useState(null)
  const [valid, setValid]: any = React.useState(false)
  const [loading, setLoading]: any = React.useState(true)
  const [settings, setSettings]: any = React.useState({})
  const [updating, setUpdating]: any = React.useState(false)
  const [submitted, setSubmitted]: any = React.useState(false)
  const [hasLogo, setHasLogo]: any = React.useState(true)
  const [toggleAccordions, setToggleAccordions]: any = React.useState(false)

  const form = React.useRef()

  React.useEffect(() => {
    const dataFetch = async () => {
      await buildBaseUrl()

      try {
        await request.get(logoUrl(tenant_id))
      } catch (error) {
        setHasLogo(false)
      }

      try {
        const settings = await request.get(`/apps/account/tenant/${tenant_id}`)
        setSettings(settings.data)
      } catch (err) {
        console.error(err)
      }
    }

    dataFetch()
  }, [])

  React.useEffect(() => {
    if (!settings?.subdomain) return

    const dataFetch = async () => {
      await buildBaseUrl(settings.subdomain)

      try {
        const res = await request.get(`/apps/application_forms/${slug}`)

        setApplicationForm(res.data.data)
      } catch (err) {
        console.error(err)
      }

      setLoading(false)
    }

    dataFetch()
  }, [settings?.subdomain])

  const submitForm = async () => {
    setUpdating(true)

    // add subdomain
    buildBaseUrl()

    try {
      await request.post(
        '/intake_applications/submit',
        {
          status: 'submitted',
          source: 'form',
          data: data,
          client_signature_data: data?.client_signature_data,
          client_signature_signed_at: data?.client_signature_signed_at,
          application_form_id: applicationForm?.id,
        },
        {
          headers: {
            'X-Tenant': settings.subdomain,
          },
        },
      )

      Notifications.send('Your data has been submitted successfully', 'positive')
      setSubmitted(true)
    } catch (errors) {
      // setErrors(errors)
      console.error(errors)
      Notifications.send('Submit Failed', 'negative')
    }

    setUpdating(false)
  }

  const highlightRequired = () => {
    form.current?.highlightInvalid()
    setToggleAccordions((o) => !o)
  }

  if (loading) return <PageLoader />

  return (
    <>
      <Global styles={globalStyles} />

      <div css={styles.root} className="application-form-root">
        <div css={styles.wrapper}>
          {/* {loading && (
            <Flex centerX centerY>
              <Loader color={COLORS.blue} size={24} speed={500} />
            </Flex>
          )} */}

          {hasLogo && (
            <div css={styles.header}>
              <img src={logoUrl(tenant_id)} alt="logo" css={styles.logo} />
            </div>
          )}

          {submitted && (
            <Alert contrast type="positive" glyph="tick_circle" className="!my-5 !mx-0">
              <Markup value={applicationForm?.thank_you_message || 'Your application has been submitted.'} />
            </Alert>
          )}

          {!submitted && (
            <>
              {applicationForm?.welcome_message && <Markup value={applicationForm?.welcome_message} className="mb-4" />}

              <ApplicationForm
                form={form}
                onUpdate={setData}
                timezone={settings.timezone}
                schema={applicationForm?.schema}
                companyName={settings.company}
                onValidationUpdate={setValid}
                forceOpenAccordion={toggleAccordions}
                roiDisclaimerMessage={applicationForm?.roi_disclaimer_message}
              />

              <div css={styles.footer}>
                <Grid gap={10}>
                  {!valid && (
                    <Button
                      fullWidth
                      color="orange"
                      type="default"
                      glyph="view"
                      label="Highlight Required Fields"
                      isLoading={updating}
                      className="application-form-button"
                      onClick={highlightRequired}
                    />
                  )}
                  <Button
                    fullWidth
                    color="green"
                    type="primary"
                    label="Submit Application"
                    isLoading={updating}
                    isDisabled={!valid}
                    onClick={submitForm}
                    className="application-form-button"
                  />
                </Grid>
              </div>
            </>
          )}
        </div>
      </div>

      <NotificationsContainer />
      <PortalsContainer />
    </>
  )
}

const globalStyles = {
  '@media print': {
    'html, body': {
      height: 'auto',
      overflow: 'auto',
    },

    '.application-form-root': {
      height: 'auto',
      overflow: 'visible',
    },

    '.application-form-button': {
      display: 'none',
    },

    '.accordion': {
      border: `1px solid ${COLORS.divider}`,
    },

    '*': {
      boxShadow: 'none !important',
    },
  },
}

const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    background: COLORS.lightBackground,
  },

  wrapper: {
    padding: '2.5rem 1em',
    width: '100%',
    margin: 'auto',
    maxWidth: 600,
  },

  header: {
    margin: '1rem 0 2rem',
  },

  logo: {
    display: 'block',
    width: '100%',
    height: 'auto',
    maxWidth: 180,
    maxHeight: 120,
    objectFit: 'contain',
    margin: '0 auto',
  },

  footer: {
    position: 'sticky',
    bottom: 0,
    marginTop: '1rem',
    paddingBottom: '1rem',
    background: COLORS.lightBackground,
  },
}
