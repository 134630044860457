import React from 'react'
import { v4 as uuid } from 'uuid'
import cloneDeep from 'lodash/cloneDeep'

import Grid from '../Grid'
import Flex from '../Flex'
import Field from './Field'
import { getTokenPromise } from '../../modules/axios'
import { withFormContext } from './context'

import LocalDnD from './LocalDnD'
import AttachmentCard from './AttachmentCard'
import QuickView from '../QuickView'

import FieldBase from './FieldBase'

class Attachments extends FieldBase {
  constructor(props) {
    super(props)

    this.state = {
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: props.value || props.form?.getInitialInputFieldValue(props.model, []),
      isNested: !props.isInline,
      isValid: true,
      isInvalid: false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      reset: this.onReset,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    getTokenPromise().then((token) => {
      this.token = token
    })

    this.initialData = {
      value: props.value || props.form?.getInitialInputFieldValue(props.model, []),
      isValid: true,
      isInvalid: false,
    }

    this.updateType = 'DATA'
  }

  removeFile = (file) => {
    let files = cloneDeep(this.state.value)
    let idx = null

    if (file.id) idx = files.findIndex((o) => o.id === file.id)
    else idx = files.findIndex((o) => o.provisionalID === file.provisionalID)

    // if it has been uploaded set for destroy, otherwise remove it
    if (files[idx].id) files[idx]._destroy = 1
    else files.splice(idx, 1)

    this.changeValue(files)
  }

  onSelect = (file) => {
    if (this.props.isEditable) return

    if (this.props.isInline) {
      let image = new Image()
      image.src = file.file_data

      let w = window.open('')
      w.document.write(image.outerHTML)
      w.document.close()
    } else {
      this.open(file.url)
    }
  }

  open = (url) => {
    if (!url) return
    window.open(url + `?token=${this.token}`)
  }

  download = (url) => {
    if (!url) return
    window.open(url + `?download=1&token=${this.token}`)
  }

  render() {
    const { model, placeholder, prefix, size, suffix, type, validations, isEditable, isDisabled, isInline, ...others } = this.props
    const { id, value, isRequired, isValid, errors } = this.state

    const isEmpty = value?.length === 0

    return (
      <Field {...others} id={id} errors={errors} isValid={isValid} isRequired={isRequired}>
        <Flex vertical gap={8}>
          <Grid gap={!isEditable && 8}>
            {value
              ?.filter((file) => file._destroy !== 1)
              ?.map((file, idx) => (
                <QuickView key={`${idx}-${file.file_name}`} record={file}>
                  {({ open }) => (
                    <AttachmentCard
                      isProvisional={!!file.provisionalID}
                      title={file.title || file.file_name || file.filename}
                      description={file.content_type}
                      isEditable={isEditable}
                      isInline={isInline}
                      onClick={open}
                      onRemove={() => this.removeFile(file)}
                      url={file.url}
                    />
                  )}
                </QuickView>
              ))}
          </Grid>

          {isEditable && (
            <LocalDnD
              onChange={(files) => {
                this.changeValue(value.concat(files))
              }}
            />
          )}
        </Flex>

        {!isEditable && isEmpty && <p>No files attached yet</p>}
      </Field>
    )
  }
}

Attachments.defaultProps = {
  type: 'text',
  isEditable: true,
  isBlocked: false,
  isInline: false,
}

export default withFormContext(Attachments)
