import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { age } from '../../utils/functions'

import AddressInput from '../../components/Forms/AddressInput'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import EmailInput from '../../components/Forms/EmailInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import GoogleMeetInput from '../../components/Forms/GoogleMeetInput'
import Input from '../../components/Forms/Input'
import MessengerInput from '../../components/Forms/MessengerInput'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import SexSelect from '../../components/Forms/elements/SexSelect'
import SkypeInput from '../../components/Forms/SkypeInput'
import Status from '../../components/Status'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'
import WhatsAppInput from '../../components/Forms/WhatsAppInput'
import ZoomInput from '../../components/Forms/ZoomInput'
import DateOfBirthInput from '../../components/Forms/DateOfBirthInput'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Link from '../../components/Link'
import Alert from '../../components/Alert'

import { withOverlayError } from '../../hocs/withOverlayError'

const RootContactOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    parent,
    save,
  } = useOverlay({
    name: 'contact',
    endpoint: '/contacts',
    invalidate: 'contacts',
    parentType: props.parentType,
    parentRequest: props.parentRequest,
    options: props,
    onSaveSuccessful: props.onSaveSuccessful,
    onDeleteSuccessful: props.onDeleteSuccessful,
  })

  const { timezone, isPortal } = useSettings()

  const referenceID = props?.referenceID || parent?.id
  const referenceType = props?.referenceType || parent?.type === 'client' ? 'resident' : parent?.type

  // TODO: handle reference type and id for client portal

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header
        icon="contacts"
        title={isNew ? 'Add Contact' : data?.name}
        subtitle={
          !isEditable &&
          data && (
            <Flex gap={6} fixChildrenX>
              {data.relationship && <Status color="orange" label={`${data.relationship}`} small />}
              <Status color={data.roi ? 'green' : 'red'} label={data.roi ? 'R.O.I. Allowed' : 'R.O.I. NOT Allowed'} small />
              {data.is_emergency && <Status color="yellow" label="Emergency Contact" small />}
            </Flex>
          )
        }
      />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              reference_id: referenceID,
              reference_type: referenceType,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              {referenceType === 'resident' && (
                <>
                  <Alert glyph="info" type="neutral">
                    This is reference information only
                  </Alert>

                  <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded">
                    <Checkbox model="roi" label="Release of Information Allowed" />
                  </CheckboxGroup>

                  <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded">
                    <Checkbox model="roi_revoked" label="Release of Information Revoked" />
                  </CheckboxGroup>

                  <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded">
                    <Checkbox model="is_emergency" label="Emergency Contact" />
                  </CheckboxGroup>

                  {!isPortal && (
                    <MultiObjectSelector
                      label="Linked Agreements"
                      icon="legal_agreement_alt"
                      type="client.agreements"
                      model="agreements"
                      dependentValue={referenceID}
                    />
                  )}
                </>
              )}

              <Permission featureFlagV2="accounting">
                <Alert glyph="info" type="neutral">
                  <div>
                    Visit <Link to={'/settings/accounting'}>https://app.behavehealth.com/settings/accounting</Link> to decide what
                    information is sent to accounting system.
                  </div>
                </Alert>

                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox label="Sync with Accounting Platform" description="" model="sync_with_accounting" />
                </CheckboxGroup>
              </Permission>

              <Input
                label="First Name"
                model="first_name"
                validations={{
                  presence: {
                    message: "Please enter the contact's first name",
                  },
                }}
              />

              <Input label="Middle Name" model="middle_name" />

              <Input
                label="Last Name"
                model="last_name"
                validations={{
                  presence: {
                    message: "Please enter the contact's last name",
                  },
                }}
              />

              <Select model="status" label="Status" defaultValue="active">
                <Option label="Active" value="active" />
                <Option label="Inactive" value="inactive" />
              </Select>

              <SexSelect label="Sex" model="sex" />

              <DateOfBirthInput vertical label="Date of Birth" model="dob" smartDescription={(date) => `Age: ${age(date)} y/o`} />

              {referenceType === 'organization' && (
                <>
                  <Input model="role" label="Role" />
                  <Input label="National Provider Identifier (NPI)" model="npi" />
                </>
              )}

              {referenceType === 'resident' && (
                <Input
                  model="relationship"
                  label="Relationship to Client"
                  validations={{
                    presence: {
                      message: "Please enter the contact's relationship to client",
                    },
                  }}
                />
              )}

              <PhoneInput model="phone_no" label="Phone Number" />
              <Input glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />
              <EmailInput model="email" label="Email" />

              <AddressInput label="Contact Address" actionLabel="Find Address" model="address" />

              <Permission newFeatureFlag="zoom_gmeet">
                <WhatsAppInput label="Whatsapp" model="whatsapp" />
                <MessengerInput label="Facebook ID" model="facebook" />
                <SkypeInput label="Skype ID" model="skype" />
                <ZoomInput label="Zoom URL" model="zoom" />
                <GoogleMeetInput label="Google Meet URL" model="google_meet" />
              </Permission>

              <Select
                model="preferred_contact_method"
                label="Preferred Method of Communication"
                defaultValue="any"
                validations={{
                  presence: {
                    message: "Please select the contact's preferred method of communication",
                  },
                }}
              >
                <Option label="Any" value="any" />
                <Option label="Phone" value="phone" />
                <Option label="Text" value="text" />
                <Option label="Email" value="email" />
              </Select>

              <Textarea useQuickText model="notes" label="Notes" minRows={4} maxWidth="100%" />
            </FormSection>
          </Section>

          {referenceType === 'organization' && (
            <>
              <Divider />

              <Section headingType="h2" title="Client Portal">
                <FormSection labelWidth="150px">
                  <CheckboxGroup label="Access" trueIcon="check" falseIcon="cross" falseStyle="none">
                    <Checkbox label="Share details with the Client Portal?" model="is_shared_with_portal" />
                  </CheckboxGroup>

                  <Textarea useQuickText isNested model="portal_notes" label="Portal Notes" minRows={4} maxWidth="100%" />
                </FormSection>
              </Section>
            </>
          )}

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this contact">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} disableLinks={isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="contacts.create"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Contact"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="contacts.edit"
            />

            <DeleteDialog
              title="Delete Contact?"
              message="Are you sure you want to delete this contact? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                permission="contacts.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ContactOverlay = withOverlayError(RootContactOverlay)
