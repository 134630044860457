import React from 'react'

import Select from '../common/Select'

type Props = { column: any }

const AcriveInactiveFilter = (props: Props) => {
  const {
    column: { filterValue, setFilter },
  } = props

  const filterOptions = [
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
    ]

  return <Select setFilter={setFilter} filterValue={filterValue} filterOptions={filterOptions} />
}

export default AcriveInactiveFilter
