import React from 'react'

import { COLORS } from '../../theme'
import SankeyChart from '../ChartTypes/SankeyChart'

export const AlumniGraduationCohortsChart = (props: any) => {
  const links: any = []
  const data: any = [...MONTHS]

  const setLinks = () => {
    for (let i = 0; i < MONTHS.length; i++) {
      data.push({ name: `${MONTHS[i].name} Contact Made`, depth: 1, itemStyle: { color: COLORS.mintGreen } })
      data.push({ name: `${MONTHS[i].name} Contact Not Made`, depth: 1, itemStyle: { color: COLORS.red } })
      data.push({ name: `${MONTHS[i].name} Readmitted`, depth: 2, itemStyle: { color: COLORS.vividBlue } })
      data.push({ name: `${MONTHS[i].name} Still Sober`, depth: 2, itemStyle: { color: COLORS.purple } })

      const contactMade = Math.floor(Math.random() * 100)
      const contactNotMade = Math.floor(Math.random() * 100)

      links.push({
        source: MONTHS[i].name,
        target: `${MONTHS[i].name} Contact Made`,
        value: contactMade,
      })

      links.push({
        source: MONTHS[i].name,
        target: `${MONTHS[i].name} Contact Not Made`,
        value: contactNotMade,
      })

      links.push({
        source: `${MONTHS[i].name} Contact Made`,
        target: `${MONTHS[i].name} Readmitted`,
        value: contactMade / 2,
      })

      links.push({
        source: `${MONTHS[i].name} Contact Made`,
        target: `${MONTHS[i].name} Still Sober`,
        value: contactMade / 2,
      })
    }
  }

  setLinks()

  return (
    <SankeyChart
      data={data}
      links={links}
      series={{
        // layoutIterations: 0,
        levels: [
          { depth: 0, lineStyle: { color: 'source', opacity: 0.3 } },
          { depth: 1, lineStyle: { color: 'source', opacity: 0.3 } },
          { depth: 2, lineStyle: { color: 'source', opacity: 0.3 } },
        ],
      }}
      height={600}
      {...props}
    />
  )
}

const MONTHS = [
  { name: 'Jan 2021', itemStyle: { color: COLORS.paleBlue } },
  { name: 'Feb 2021', itemStyle: { color: COLORS.paleBlue } },
  { name: 'Mar 2021', itemStyle: { color: COLORS.paleBlue } },
  { name: 'Apr 2021', itemStyle: { color: COLORS.paleBlue } },
  { name: 'May 2021', itemStyle: { color: COLORS.paleBlue } },
  { name: 'Jun 2021', itemStyle: { color: COLORS.paleBlue } },
  { name: 'Jul 2021', itemStyle: { color: COLORS.paleBlue } },
  { name: 'Aug 2021', itemStyle: { color: COLORS.paleBlue } },
  { name: 'Sep 2021', itemStyle: { color: COLORS.paleBlue } },
  { name: 'Oct 2021', itemStyle: { color: COLORS.paleBlue } },
  { name: 'Nov 2021', itemStyle: { color: COLORS.paleBlue } },
  { name: 'Dec 2021', itemStyle: { color: COLORS.paleBlue } },
]
