import React from 'react'
import { connect } from 'react-redux'

import { defaultMapStateToProps } from '../Overlays/pages/OverlayBase'
import EventBuilderOverlay from '../Overlays/pages/Calendar/EventBuilderOverlay'

const SidebarEventBuilderOverlay = (props: any) => <EventBuilderOverlay reduxName="sidebar_events" {...props} />

const mapStateToProps = (state: any, props: any) => defaultMapStateToProps(state, props.match, 'sidebar_events')

export default connect(mapStateToProps)(SidebarEventBuilderOverlay)
