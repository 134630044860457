import React from 'react'
import { connect } from 'react-redux'

import { COLORS } from '../../theme'
import { withOverlayError } from '../../hocs/withOverlayError'

import AmountInput from '../Forms/AmountInput'
import Attachments from '../Forms/Attachments'
import Button from '../Button'
import Card from '../Card'
import CardHeader from '../CardHeader'
import CardContent from '../CardContent'
import DateInput from '../Forms/DateInput'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Flex from '../Flex'
import Form from '../Forms/Form'
import FormSection from '../Forms/FormSection'
import GridTable from '../GridTable'
import Input from '../Forms/Input'
import ObjectSelector from '../Forms/Selectors/Object/ObjectSelector'
import Option from '../Forms/Option'
import Overlay from '../Overlay'
import Section from '../Section'
import Select from '../Forms/Select'
import Textarea from '../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../Overlays/pages/OverlayBase'

class ClaimClientsPaymentsBuilderOverlay extends OverlayBase {
  render = () => {
    const { params, $editable, isInvalid }: any = this.state
    const { record, timezone, loading }: any = this.props

    const data = { ...params, ...record }

    const claim = CLAIM

    if (!claim) return null

    // if (!$new && size(record) === 0) {
    //   return <OverlayLoader position={this.props.position} showBackdrop={this.props.showBackdrop} />
    // }

    return (
      <Overlay showBackdrop position="center" maxWidth={80} onClose={this.close}>
        <Overlay.Header icon="financials" title="Claim Payments Builder" />

        <Overlay.Content>
          <Form
            getForm={this.form}
            initialModel={data}
            isEditable={$editable}
            timezone={timezone}
            onValidationUpdate={this.onValidationUpdate}
          >
            <Section>
              <Flex gap="1rem">
                <ObjectSelector label="Claim" model="payer" icon="insurance" css={{ flex: '0 1 240px' }} />

                <DateInput defaultToNow label="Payment Date" model="paid_at" />

                <Select label="Payment Method" model="payment_method">
                  <Option label="Check for Payment" value="check" />
                  <Option label="Bank Transfer" value="bank_transfer" />
                  <Option label="Other" value="other" />
                </Select>

                <Input label="Reference" model="reference" />
              </Flex>

              {[1, 2, 3, 4, 5].map((i) => (
                <React.Fragment key={i}>
                  <h3 css={{ margin: '1.25rem 0 0.5rem' }}>Client #{i}</h3>

                  <Card key={i}>
                    <div css={{ padding: '0.5rem ' }}>
                      <ObjectSelector
                        label=""
                        model="client"
                        icon="clients"
                        type="clients"
                        placeholder="Select Client…"
                        css={{ flex: '0 1 240px' }}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => data.behave_id}
                      />
                    </div>

                    <div css={{ borderTop: `1px solid ${COLORS.divider}`, borderBottom: `1px solid ${COLORS.divider}` }}>
                      <GridTable templateColumns="200px 150px 150px 1fr 1fr 1fr 1fr">
                        <GridTable.Header>
                          <GridTable.Cell>Service</GridTable.Cell>
                          <GridTable.Cell>Total Billed</GridTable.Cell>
                          <GridTable.Cell>Current Balance</GridTable.Cell>
                          <GridTable.Cell>Insurance Paid</GridTable.Cell>
                          <GridTable.Cell>Co-Pay</GridTable.Cell>
                          <GridTable.Cell>Write-Off</GridTable.Cell>
                          <GridTable.Cell>Notes</GridTable.Cell>
                        </GridTable.Header>

                        {claim.service_lines.map((line, index) => (
                          <GridTable.Row key={index}>
                            <GridTable.Cell>
                              <ObjectSelector
                                label=""
                                model="service"
                                icon="financials"
                                validations={{
                                  presence: {
                                    message: 'Please select a service',
                                  },
                                }}
                              />
                            </GridTable.Cell>

                            <GridTable.Cell>$375.00</GridTable.Cell>

                            <GridTable.Cell>$245.00</GridTable.Cell>

                            <GridTable.Cell>
                              <AmountInput model="insurance_paid" />
                            </GridTable.Cell>

                            <GridTable.Cell>
                              <AmountInput model="co_pay" />
                            </GridTable.Cell>

                            <GridTable.Cell>
                              <AmountInput model="write_off" />
                            </GridTable.Cell>

                            <GridTable.Cell>
                              <Textarea model="notes" minRows={2} />
                            </GridTable.Cell>
                          </GridTable.Row>
                        ))}
                      </GridTable>
                    </div>

                    <div className="!p-2">
                      <Button label="Add New Service" type="link" display="inline-flex" glyph="add" size={200} />
                    </div>
                  </Card>
                </React.Fragment>
              ))}

              <h3 css={{ margin: '1.25rem 0 0.5rem' }}>Attachments</h3>

              <FormSection maxWidth="100%">
                <Attachments model="documents" label="" labelAlign="top" labelJustify="top" />
              </FormSection>
            </Section>
          </Form>
        </Overlay.Content>

        <Overlay.Footer>
          {$editable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                onClick={this.save}
                isLoading={loading}
                isDisabled={isInvalid}
                permission="todos.edit"
              />
              <Button label="Cancel" glyph="cross" onClick={this.cancel} isDisabled={loading} />
            </>
          )}

          {!$editable && (
            <>
              <Button label="Edit" glyph="edit" onClick={this.edit} flex="100 1 auto" permission="todos.edit" />

              <DeleteDialog
                title="Delete payment?"
                message="Are you sure you want to delete this payment? This action cannot be undone."
                onYes={this.delete}
              >
                <Button label="Delete" glyph="delete" color="red" isLoading={this.props.loading} permission="todos.delete" />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const CLAIM = {
  id: '23',
  service_lines: [
    {
      id: 'f01defd3-4a6c-4872-9552-6a8ceeec2cfa',
      service_date_start: '2022-01-01T00:00:00.000-05:00',
      service_date_end: '2022-01-03T00:00:00.000-05:00',
      units: 1,
      unit_price: 100,
      modifier_codes: [],
    },
    {
      id: 'e30ae1ee-9230-41f0-b971-159fc8b09c6b',
      service_date_start: '2022-01-04T00:00:00.000-05:00',
      service_date_end: '2022-01-07T00:00:00.000-05:00',
      units: 1,
      unit_price: 100,
      modifier_codes: [],
    },
    {
      id: 'c2623073-80e9-4630-9d7f-be501b40af74',
      service_date_start: '2022-01-10T00:00:00.000-05:00',
      service_date_end: '2022-01-12T00:00:00.000-05:00',
      units: 1,
      unit_price: 100,
      modifier_codes: [],
    },
  ],
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'claim_notes')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(ClaimClientsPaymentsBuilderOverlay))
