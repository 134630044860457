import React from 'react'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

import { countWord } from '../../../../utils/functions'
import Status from '../../../Status'

export const InsuranceFeeSchedulesTable = ({ onSelect, dependentValue, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-insurance-fee-schedules'],
    endpoint: '/insurance_new_fee_schedules',
    localStorageKey: 'selector_insurance_fee_schedules_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 200,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell id={data.id} value={value} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'Status',
        model: 'status',
        width: 120,
        formatValue: ({ value }: any) => (
          <Status label={value === 'active' ? 'Active' : 'Archived'} color={value === 'active' ? 'green' : 'grey'} />
        ),
      },
      {
        title: 'Payers',
        model: 'insurance_local_payers',
        width: 300,
        formatValue: ({ value }: any) => value?.map((o: any) => o.name)?.join(', ') || '–',
      },
      {
        title: 'Healthcare Providers',
        id: 'employees_count',
        model: 'employees',
        width: 170,
        formatValue: ({ value }: any) => countWord('Providers', value?.length),
      },
      {
        title: 'Healthcare Locations',
        id: 'houses_count',
        model: 'houses',
        width: 170,
        formatValue: ({ value }: any) => countWord('Locations', value?.length),
      },
      {
        title: 'Services',
        id: 'services_count',
        model: 'insurance_new_fee_schedule_services',
        width: 100,
        formatValue: ({ value }: any) => countWord('Services', value?.length),
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return <DataTable {...tableProps} {...rest} icon="insurance" title="Fee Schedules" columns={columns} searchBarFilterKey="name" />
}
