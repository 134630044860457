import React from 'react'
import clsx from 'clsx'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import withScrollContext from '../../hocs/withScrollContext'
import { OverlayContext } from './context'

type Props = {
  id: string
  children: React.ReactNode
  className: string
  key?: any
  onScroll?: any
  registerContainer?: any
  getReference?: any
}

const OverlayContent = ({ key, style, children, registerContainer, getReference, className, onScroll, id }: Props) => {
  const ref = React.useRef(null)
  const { position }: any = React.useContext(OverlayContext)

  const classNames = clsx('overlay-content', className)

  // React.useEffect(() => {
  //   if (!ref?.current || position !== 'center') return

  //   disableBodyScroll(ref.current)

  //   return clearAllBodyScrollLocks
  // }, [ref?.current, position])

  if (registerContainer) registerContainer(ref)
  if (getReference) getReference(ref)

  return (
    <div id="overlay-content" key={key} className={classNames} css={[styles, style]} ref={ref} onScroll={onScroll}>
      {children}
    </div>
  )
}

const styles = {
  flex: '1 1 auto',
  background: '#F7F8FB',
  overflowY: 'auto',
  overflowScrolling: 'touch',
  WebkitOverflowScrolling: 'touch',
  position: 'relative',
  zIndex: 1,
}

export default withScrollContext(OverlayContent)
