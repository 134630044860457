import { useEffect } from 'react'
import { useResolvedPath } from 'react-router-dom-v5-compat'
import create from 'zustand'

export const useBackListener = () => {
  const resolved = useResolvedPath('')

  const url = resolved?.pathname || ''

  const back = useBackListenerStore((state: any) => state.back)
  const setBack = useBackListenerStore((state: any) => state.setBack)

  useEffect(() => {
    setBack(url)

    return () => setBack('')
  }, [url, setBack])
}

export const useBackListenerStore = create((set: any) => ({
  back: '',
  setBack: (path: string) => set({ back: path }),
}))
