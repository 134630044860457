import React from 'react'
import { tint } from 'polished'
import { useMedia } from 'use-media'
import { useRouteMatch } from 'react-router-dom'
import * as HoverCard from '@radix-ui/react-hover-card'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { age, usDate, usDateTime } from '../../utils/functions'
import { css, COLORS, SHADOW } from '../../theme'
import { useSettings } from '../../hooks/useSettings'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import CardHeader from '../../components/CardHeader'
import CardSubtitle from '../../components/CardSubtitle'
import CardTitle from '../../components/CardTitle'
import DataList from '../../components/DataList'
import Flex from '../../components/Flex'
import Icon from '../../components/Icon'
import IntakeStatus from '../../components/Statuses/IntakeStatus'
import LevelOfCareStatus from '../../components/Statuses/LevelOfCareStatus'
import Permission from '../../components/Permission'
import Roadmap from '../../components/Roadmap/Roadmap'
import RoadmapBar from '../../components/Roadmap/RoadmapBar'
import RoadmapRow from '../../components/Roadmap/RoadmapRow'
import RoadmapHeaderRow from '../../components/Roadmap/RoadmapHeaderRow'

import { BED_ASSIGNMENT_COLORS } from '../BedManagement/BedsTimeline'
import { BedAssignmentInfo } from '../BedManagement/BedAssignmentInfo'

export const ClientsJourneyTimeline: React.FC<any> = (props) => {
  const { className, data, isLoading, isRefetching, dates, setDates } = props

  const [isAllOpen, setIsAllOpen] = React.useState(false)

  return (
    <Roadmap showTodayLine className={className} isLoading={isLoading} isRefetching={isRefetching} dates={dates} onDatesChange={setDates}>
      {data && (
        <>
          <RoadmapHeaderRow className={STYLES.headerRow().className}>
            <Button
              onClick={() => setIsAllOpen((c) => !c)}
              label={`${isAllOpen ? 'Close' : 'Open'} All`}
              size={100}
              glyph={isAllOpen ? 'close_all_rows' : 'open_all_rows'}
              display="inline-block"
              type="minimal"
            />
          </RoadmapHeaderRow>

          {data.map((record: any) => {
            const { client, treatment_episodes, bed_assignments, authorizations } = record

            const hasBedAssignments = size(bed_assignments) >= 1
            const hasTreatmentEpisodes = size(treatment_episodes) >= 1
            const hasAuthorizations = size(authorizations) >= 1

            return (
              <RoadmapRow
                isOpen={isAllOpen}
                key={client.id}
                title={client.name}
                className={STYLES.clientRow().className}
                level={1}
                graphic={<Avatar src={client.avatar} initials={client.name} size={24} />}
              >
                <Permission featureFlagV2="insurance_authorizations">
                  <RoadmapRow
                    isOpen
                    level={2}
                    title="Authorizations"
                    icon="authorizations"
                    isDisabled={!hasAuthorizations}
                    renderBars={({ isOpen }: any) => {
                      if (isOpen || !hasAuthorizations) return null

                      return authorizations.map((authorization: any) => (
                        <AuthorizationBar key={authorization.id} data={authorization} client={client} />
                      ))
                    }}
                  >
                    {hasAuthorizations &&
                      authorizations.map((authorization: any) => (
                        <RoadmapRow
                          isOpen
                          key={authorization.id}
                          level={3}
                          title={authorization.title || 'Treatment Episode'}
                          renderBars={() => <AuthorizationBar data={authorization} client={client} />}
                        />
                      ))}
                  </RoadmapRow>
                </Permission>

                <RoadmapRow
                  isOpen
                  level={2}
                  title="Treatment Episodes"
                  icon="treatment_episodes"
                  isDisabled={!hasTreatmentEpisodes}
                  renderBars={({ isOpen }: any) => {
                    if (isOpen || !hasTreatmentEpisodes) return null

                    return treatment_episodes.map((treatmentEpisode: any) => (
                      <TreatmentEpisodeBar key={treatmentEpisode.id} data={treatmentEpisode} client={client} />
                    ))
                  }}
                >
                  {hasTreatmentEpisodes &&
                    treatment_episodes.map((treatmentEpisode: any) => (
                      <RoadmapRow
                        isOpen
                        key={treatmentEpisode.id}
                        level={3}
                        title={treatmentEpisode.title || 'Treatment Episode'}
                        renderBars={() => <TreatmentEpisodeBar data={treatmentEpisode} client={client} />}
                      />
                    ))}
                </RoadmapRow>

                <RoadmapRow
                  isOpen
                  level={2}
                  title="Bed Assignments"
                  icon="beds"
                  isDisabled={!hasBedAssignments}
                  renderBars={({ isOpen }: any) => {
                    if (isOpen || !hasBedAssignments) return null

                    return bed_assignments.map((bedAssignment: any) => (
                      <BedAssignmentBar key={bedAssignment.id} data={bedAssignment} client={client} />
                    ))
                  }}
                >
                  {hasBedAssignments &&
                    bed_assignments.map((bedAssignment: any) => (
                      <RoadmapRow
                        isOpen
                        key={bedAssignment.id}
                        level={3}
                        title={bedAssignment.place.name}
                        renderBars={() => <BedAssignmentBar data={bedAssignment} client={client} />}
                      />
                    ))}
                </RoadmapRow>
              </RoadmapRow>
            )
          })}
        </>
      )}
    </Roadmap>
  )
}

const ClientHeader = ({ client }: any) => {
  if (!client) return null

  return (
    <Flex centerY nowrap gap="0.5rem" css={{ padding: '0.75rem 1rem 0.5rem' }}>
      <Avatar src={client.avatar} initials={client.name} size={40} />
      <CardHeader>
        <CardTitle title={client.name} />
        <CardSubtitle subtitle={`${startCase(client.sex) || '–'}, ${age(client.dob)} y/o, #${client.behave_id}`} />
      </CardHeader>
    </Flex>
  )
}

const HoverCardRoot: React.FC = ({ children, ...rest }) => {
  return (
    <HoverCard.Root openDelay={100} closeDelay={100} {...rest}>
      {children}
    </HoverCard.Root>
  )
}

const HoverCardContent: React.FC = ({ children }) => {
  const isDesktop = useMedia({ minWidth: 600 })

  const container = document?.getElementById?.('portal-default')
  const collisionBoundary = document?.getElementById?.('roadmap')

  return (
    <HoverCard.Portal container={container}>
      <HoverCard.Content
        className={STYLES.hoverCard()}
        collisionBoundary={collisionBoundary}
        collisionPadding={{ left: isDesktop ? 200 : 20 }}
      >
        <HoverCard.Arrow width={14} height={8} className={STYLES.hoverCardArrow()} />
        {children}
      </HoverCard.Content>
    </HoverCard.Portal>
  )
}

const BedAssignmentBar = ({ data, client }: any) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const { timezone } = useSettings()

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  if (!data) return null

  return (
    <HoverCardRoot open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
      <HoverCard.Trigger asChild>
        <RoadmapBar
          minWidth={7}
          background={BED_ASSIGNMENT_COLORS[data.status]}
          endDate={data.ended_at}
          startDate={data.started_at}
          onClick={openTooltip}
        >
          <Icon icon="beds" size={15} />
        </RoadmapBar>
      </HoverCard.Trigger>

      <HoverCardContent>
        <ClientHeader client={client} />
        <BedAssignmentInfo data={data} />
      </HoverCardContent>
    </HoverCardRoot>
  )
}

const TreatmentEpisodeBar = ({ data, client }: any) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const { timezone } = useSettings()

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  if (!data) return null

  const startDate = data.admitted_at || data.estimated_arrival
  const endDate = data.discharged_at || data.estimated_discharge

  return (
    <HoverCardRoot open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
      <HoverCard.Trigger asChild>
        <RoadmapBar
          minWidth={7}
          background={tint(0.7, COLORS.vividBlue)}
          startDate={startDate}
          endDate={endDate}
          onClick={openTooltip}
          asGradient={!endDate}
          width={!endDate && 50}
        >
          <Icon icon="treatment_episodes" size={15} />
        </RoadmapBar>
      </HoverCard.Trigger>

      <HoverCardContent>
        <ClientHeader client={client} />
        <DataList isCompact withPadding labelWidth={120}>
          <DataList.Item label="Status" value={<IntakeStatus status={data.status} />} />

          {data.admitted_at ? (
            <DataList.Item label="Admit Date" value={usDateTime(data.admitted_at, timezone)} />
          ) : (
            <DataList.Item label="Est. Arrival Date" value={usDateTime(data.estimated_arrival, timezone)} />
          )}

          {data.discharged_at ? (
            <DataList.Item label="Discharge Date" value={usDateTime(data.discharged_at, timezone)} />
          ) : (
            <DataList.Item label="Est. Discharge Date" value={usDateTime(data.estimated_discharge, timezone)} />
          )}
        </DataList>
      </HoverCardContent>
    </HoverCardRoot>
  )
}

const AuthorizationBar = ({ data, client }: any) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false)

  const { timezone } = useSettings()

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }

  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  if (!data) return null

  return (
    <HoverCardRoot open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
      <HoverCard.Trigger asChild>
        <RoadmapBar
          minWidth={7}
          background={tint(0.8, COLORS.mintGreen)}
          startDate={data.started_at}
          endDate={data.ended_at}
          onClick={openTooltip}
          asGradient={!data.ended_at}
          width={!data.ended_at && 50}
        >
          <Icon icon="authorizations" size={15} />
        </RoadmapBar>
      </HoverCard.Trigger>

      <HoverCardContent>
        <ClientHeader client={client} />
        <DataList isCompact withPadding labelWidth={140}>
          <DataList.Item label="Level of Care" value={<LevelOfCareStatus />} />
          <DataList.Item label="Authorization #" value={data.identifier} />
          <DataList.Item label="Sessions Authorized" value={data.sessions} />
          <DataList.Item label="Sessions Used" value={data.used_sessions} />
          <DataList.Item label="Notes" value={data.notes} />
          <DataList.Item label="Started On" value={usDate(data.started_at, timezone)} />
          <DataList.Item label="Ending On" value={usDate(data.ended_at, timezone)} />
        </DataList>
      </HoverCardContent>
    </HoverCardRoot>
  )
}

const STYLES = {
  hoverCard: css({
    minWidth: 280,
    maxWidth: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    borderRadius: 7,
    background: COLORS.white,
    boxShadow: SHADOW(10, COLORS.divider),
    border: 'none',
    paddingBottom: '0.5rem',

    '@media(min-width: 600px)': {
      minWidth: 360,
    },
  }),

  hoverCardArrow: css({
    color: COLORS.white,
    margin: '0 1rem',
  }),

  clientRow: css({
    borderTop: `1.5px solid ${COLORS.divider}`,

    '&:first-child': { borderTop: 'none' },
  }),

  headerRow: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
}
