import React from 'react'

const PortalsContainer = () => (
  <div id="portals" className="portals">
    <div id="portal-default" />
    <div id="portal-tooltip" />
    <div id="portal-avatar" />
    <div id="portal-dropdown" />
    <div id="portal-selector" />
    <div id="portal-dialog" />
    <div id="portal-sheet" />
    <div id="portal-overlay" />
    <div id="portal-iframe" />
    <div id="portal-notion" />
    <div id="portal-telehealth" />
    <div id="portal-radix" />
    <div id="portal-z80" />
    <div id="portal-z90" />
    <div id="portal-z100" />
    <div id="portal-z120" />
  </div>
)

export default PortalsContainer
