import React from 'react'
import clsx from 'clsx'
import dompurify from 'dompurify'

import { SearchResultContext } from './context'

const sanitizer = dompurify.sanitize

export const SearchHighlight: React.FC<any> = ({ fieldName, capitalize, prefix, className }) => {
  const { document, highlights }: any = React.useContext(SearchResultContext)

  if (!fieldName && !document && !highlights) return null

  const result = {
    [fieldName]: document[fieldName],
  }

  for (let i = 0; i < highlights.length; i++) {
    result[highlights[i].field] = highlights[i].snippet
  }

  const classNames = clsx(capitalize && 'capitalize', className)

  return (
    <>
      {prefix}
      <span dangerouslySetInnerHTML={{ __html: sanitizer(result[fieldName]) }} className={classNames} css={styles.root} />
    </>
  )
}

const styles = {
  root: {
    '&.capitalize': {
      textTransform: 'capitalize',
    },
  },
}
