import React from 'react'
import { useParams } from 'react-router-dom'

import { useGet } from '@behavehealth/hooks/useNewAPI'

import BedAvailabilityWidget from '@behavehealth/components/BedAvailabilityWidget'
import Card from '@behavehealth/components/Card'
import Page from '@behavehealth/components/Page'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import State from '@behavehealth/components/State'

export const BedAvailability = () => {
  const params: any = useParams()
  const { resource_id: id } = params

  const { data: partnership, isLoading, isError, error }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  if (!partnership || isLoading) return <PageLoader />

  const partner = partnership.other_partner
  const isShared = partnership.other_partner_settings?.bed_availability === true

  if (!isShared) {
    return (
      <Page icon="beds" title="Bed Availability">
        <Card>
          <State
            isEmpty
            icon="beds"
            title="Bed Availability"
            emptyDescription={`${partner?.name || '–'} has not shared their bed availability`}
          />
        </Card>
      </Page>
    )
  }

  return (
    <Page icon="beds" title="Bed Availability">
      <BedAvailabilityWidget
        showPercentage
        showTotalBeds
        showBreakdown
        tenant={partner.subdomain}
        tenantID={partner.id}
        externalID={partner.external_id}
      />
    </Page>
  )
}
