import React from 'react'
import { connect } from 'react-redux'

import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { ICONS } from '../../../../theme'

import { apiGet, apiUpdate } from '../../../../modules/api'
import { plural, isPast } from '../../../../utils/functions'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import Button from '../../../Button'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Divider from '../../../Divider'
import Overlay from '../../../Overlay'
import Section from '../../../Section'

import Attachments from '../../../Forms/Attachments'
import AmountInput from '../../../Forms/AmountInput'
import Checkbox from '../../../Forms/Checkbox'
import DateInput from '../../../Forms/DateInput'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Input from '../../../Forms/Input'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Textarea from '../../../Forms/Textarea'
import Timeline from '../../../Timeline/Timeline'
import CheckboxGroup from '../../../Forms/CheckboxGroup'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../OverlayBase'
import { niceOccurrenceText } from './utils'

import ChargePayersSection from './components/ChargePayersSection'

class ChargeOverlay extends OverlayBase {
  toggleCancel = async () => {
    const { current, record, match } = this.props

    await apiUpdate({
      name: 'financial_transactions',
      url: `/financial_transactions/${match.params.id}`,
      params: {
        status: record.status === 'charged' ? 'cancelled' : 'charged',
      },
    })

    apiGet({
      name: 'financial_transactions_stats',
      url: `/${plural(current.type)}/${current.id}/financial_transactions/stats`,
    })

    this.close()
  }

  onSaveSuccessful = () => {
    const { current } = this.props

    apiGet({
      name: 'financial_transactions_stats',
      url: `/${plural(current.type)}/${current.id}/financial_transactions/stats`,
    })
  }

  onDeleteSuccessful = () => {
    const { current } = this.props

    apiGet({
      name: 'financial_transactions_stats',
      url: `/${plural(current.type)}/${current.id}/financial_transactions/stats`,
    })
  }

  renderHeader = () => {
    return <Overlay.Header glyph="tag" title="Charge" />
  }

  renderContent = () => {
    const { $editable, formData } = this.state
    const { record, timezone, current } = this.props

    const service = record?.service

    const employees = record?.service?.employees
    const hasEmployees = size(employees) > 0

    const organizations = record?.service?.organizations
    const hasOrganizations = size(organizations) > 0

    const isCharged = record.status === 'charged'
    const hasPayers = size(record.subcharges) > 0

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          timezone={timezone}
          initialModel={record}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          onUpdate={(value: any) => {
            this.setState({ formData: value })
          }}
        >
          <Section>
            <FormSection>
              <ObjectSelector
                isEditable={false}
                model="service"
                label="Service"
                type="client_services"
                icon={ICONS.financials}
                selectTitle={(data) => data.name}
              />

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a charge name',
                  },
                }}
              />

              {!formData?.is_custom_amount && (
                <AmountInput isEditable={false} label="Amount" suffix={niceOccurrenceText(service)} value={service?.amount} />
              )}

              {formData?.is_custom_amount && (
                <AmountInput
                  debounce={300}
                  label="Custom Amount"
                  model="amount"
                  suffix={niceOccurrenceText(service)}
                  defaultValue={service?.amount}
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                    numericality: {
                      greaterThan: 0,
                      message: 'Please enter an amount',
                    },
                  }}
                />
              )}

              {!isCharged && $editable && <Checkbox skipDeregister label="Set Custom Amount" model="is_custom_amount" />}

              <DateInput
                label="Due Date"
                model="transactioned_at"
                validations={{
                  presence: {
                    message: 'Please enter a due date',
                  },
                }}
              />

              {hasEmployees && (
                <ObjectSelector
                  model="employees"
                  label="Staff Member"
                  type="employees"
                  apiData={employees}
                  icon={ICONS.employees}
                  selectTitle={(data: any) => data.name}
                  selectDescription={(data: any) => startCase(data.position)}
                />
              )}

              {hasOrganizations && (
                <ObjectSelector
                  model="organizations"
                  label="Organization"
                  type="organizations"
                  apiData={organizations}
                  icon={ICONS.organizations}
                  selectTitle={(data: any) => data.name}
                  selectDescription={() => null}
                />
              )}

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <Section title="Automatic Collection">
            <FormSection maxWidth="100%">
              <CheckboxGroup isEditable={isCharged ? false : $editable} trueIcon="check" falseIcon="cross" falseStyle="faded">
                <Checkbox
                  isEditable={isCharged ? false : $editable}
                  label={isCharged ? 'Used Automatic Payment Collection' : 'Use Automatic Payment Collection'}
                  model="should_auto_charge"
                  description="Attempt to collect from the Payment Method selected below"
                />
              </CheckboxGroup>

              {formData?.should_auto_charge && (
                <ObjectSelector
                  isEditable={isCharged ? false : $editable}
                  icon="billing"
                  type="client.payment_methods"
                  label={isCharged ? 'Charged Payment Method' : 'Payment Method to Charge'}
                  model="payment_method"
                  dependentValue={current?.id}
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => data?.reference?.name}
                  validations={{
                    presence: {
                      message: 'Please select a Payment Method to use',
                    },
                  }}
                />
              )}
            </FormSection>
          </Section>

          <Divider />

          {hasPayers && (
            <>
              <ChargePayersSection subcharges={record.subcharges} />
              <Divider />
            </>
          )}

          <Section headingType="h2" title="Attachments" description="Upload files related to this charge">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {/* {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
              </Section>
            </>
          )} */}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $editable, isInvalid } = this.state
    const { record, loading } = this.props

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={this.save}
              isLoading={loading}
              isDisabled={isInvalid}
              permission="ledger.create"
            />
            <Button label="Cancel" glyph="cross" type="default" onClick={this.cancel} isDisabled={loading} />
          </>
        )}

        {!$editable && (
          <>
            <Button label="Edit" glyph="edit" type="default" onClick={this.edit} flex="100 1 auto" permission="ledger.edit" />

            {record.status === 'cancelled' && (
              <Button
                label="Re-apply Charge"
                glyph="circle_add"
                type="default"
                color="green"
                onClick={this.toggleCancel}
                permission="ledger.edit"
              />
            )}

            {record.status === 'charged' && (
              <Button
                label="Cancel Charge"
                glyph="circle_error"
                type="default"
                color="red"
                onClick={this.toggleCancel}
                permission="ledger.edit"
              />
            )}

            {(record.status === 'future' || record.status === 'cancelled') && (
              <DeleteDialog
                title="Delete Charge?"
                message="Are you sure you want to delete this charge? This action cannot be undone."
                onYes={this.delete}
              >
                <Button
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  fullWidth
                  isLoading={this.props.loading}
                  permission="ledger.delete"
                />
              </DeleteDialog>
            )}
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'financial_transactions')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(ChargeOverlay))
