import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'grey',
  },
  processed: {
    label: 'Processed',
    color: 'blue',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
}

const InsuranceERAReportStatus = ({ isLocked, ...rest }) => {
  return <SmartStatus statuses={statuses} glyph={isLocked ? 'lock' : undefined} glyphColor="textMuted" {...rest} />
}

export default InsuranceERAReportStatus
