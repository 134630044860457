import React from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useParams as useParamsV6, useLocation as useLocationV6, useNavigate } from 'react-router-dom-v5-compat'
import merge from 'lodash/merge'
import produce from 'immer'
import startCase from 'lodash/startCase'

import { age, usDateTime } from '../../utils/functions'
import { COLORS } from '../../theme'
import { useCreate, useGet } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'
import withSettings from '../../hocs/withSettings'

import { H2, Text } from '../../components/Typography'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import ClientStatus from '../../components/Statuses/ClientStatus'
import DataList from '../../components/DataList'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Workflow from '../../components/Workflow/Workflow'

import Events from './common/Events'
import ProgramRemove from './common/ProgramRemove'
import Todos from './common/Todos'

const stepsReducer = (state: any, payload: any) => {
  const { step, status } = payload
  if (!status || !state.hasOwnProperty(step)) return state

  return produce(state, (draft: any) => {
    draft[step] = status
  })
}

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const INITIAL_DATA = {}

const INITIAL_STEPS = {
  move_date: 'todo',
  todos: 'todo',
  events: 'todo',
  confirm: 'todo',
}

const RootProgramRemoveOverlay = ({ initialData, onSaveSuccessful, timezone, useV6Router }: any) => {
  const params = useV6Router ? useParamsV6() : useParams()
  const location: any = useV6Router ? useLocationV6() : useLocation()
  const navigate: any = useV6Router ? useNavigate() : useHistory().push

  const { occupancy_id, resource_id: programId }: any = params

  const [steps, setSteps] = React.useReducer(stepsReducer, INITIAL_STEPS)
  const [data, setData] = React.useReducer(dataReducer, INITIAL_DATA)

  const { data: occupancy, isLoading }: any = useGet({
    name: ['occupancy', occupancy_id],
    url: `/occupancies/${occupancy_id}`,
  })

  const { mutateAsync, isLoading: isSaving }: any = useCreate({
    name: ['remove-from-seat'],
    url: `/seats/${occupancy?.place?.id}/remove`,
    invalidateKeys: ['programs-timeline', 'program-timeline', ['program', programId, 'phases-with-residents']],
  })

  if (!occupancy || isLoading) return <OverlayLoader />

  const { reference } = occupancy

  const close = () => {
    if (location.parent) {
      navigate(location.parent.url)
    } else {
      const path = location.pathname
      const secondLastIndex = path.lastIndexOf('/', path.lastIndexOf('/') - 1)
      navigate(path.substr(0, secondLastIndex))
    }
  }

  const onSave = async () => {
    await mutateAsync({
      seat_id: occupancy.place.id,
      use_custom_move_date: data.use_custom_move_date,
      custom_moved_at: data.custom_moved_at,
      reference_id: reference.id,
      reference_type: reference.type,
    })

    if (onSaveSuccessful) await onSaveSuccessful()

    close()
  }

  const updateData = (newValue: any) => {
    const newData = produce(data, (draft: any) => {
      draft = merge(draft, newValue)
    })

    setData(newData)
  }

  return (
    <Overlay fullheight showBackdrop position="center" onClose={close}>
      <Overlay.Header icon="housing_shifts" title="Remove from Program" />

      <Overlay.Content>
        <Section>
          <Flex nowrap gap={12} marginBottom="0.75rem">
            <Avatar size={72} src={reference.avatar} magnify />

            <Grid gap={4}>
              <ClientStatus status={reference.status} />
              <H2>{reference.name}</H2>
              <Text description={`${startCase(reference.sex) || '–'}, ${age(reference.dob)} y/o, #${reference.behave_id}`} />
            </Grid>
          </Flex>
        </Section>

        <Workflow>
          {/* Move Date */}
          <Workflow.Panel step="move_date">
            <Workflow.Header title="Move Date" after={<Workflow.Status status={steps.move_date} />} />
            <Workflow.Content>
              <ProgramRemove
                useV6Router={useV6Router}
                data={data}
                setData={updateData}
                initialData={initialData}
                occupancy={occupancy}
                setStatus={(status: string) => {
                  setSteps({ step: 'move_date', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Todos */}
          <Workflow.Panel step="todos">
            <Workflow.Header title="To-Do's" after={<Workflow.Status status={steps.todos} />} />
            <Workflow.Content>
              <Todos
                useV6Router={useV6Router}
                timezone={timezone}
                client={reference}
                setStatus={(status: string) => {
                  setSteps({ step: 'todos', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Events */}
          <Workflow.Panel step="events">
            <Workflow.Header title="Events" after={<Workflow.Status status={steps.events} />} />
            <Workflow.Content>
              <Events
                useV6Router={useV6Router}
                timezone={timezone}
                client={reference}
                setStatus={(status: string) => {
                  setSteps({ step: 'events', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Confirm */}
          <Workflow.Panel step="confirm">
            <Workflow.Header title="Confirm" after={<Workflow.Status status={steps.confirm} />} />
            <Workflow.Content>
              <Grid gap="1rem">
                <Card>
                  <Flex centerY gap="0.75rem" css={{ padding: '0.7rem 1rem 0.4rem' }}>
                    <Icon icon="programs" size={20} />
                    <div className="text-[1.1rem] font-[700]">Previous Program Assignment</div>
                  </Flex>

                  <DataList isCompact withPadding labelWidth={120}>
                    <DataList.Item
                      label="Program"
                      value={
                        <Flex centerY nowrap gap="0.4rem">
                          <Icon icon="programs" size={18} />
                          <div className="!font-[600]">{occupancy.place.program.name}</div>
                        </Flex>
                      }
                    />

                    <DataList.Item
                      label="Program List"
                      value={
                        <Flex nowrap centerY gap="0.3rem">
                          <Icon icon="housing_shifts" size={18} />
                          <div>{occupancy.place.phase.name}</div>
                        </Flex>
                      }
                    />

                    <DataList.Item
                      label="List Seat"
                      value={
                        <Flex nowrap centerY gap="0.3rem">
                          <Glyph glyph="chair" size={16} color={COLORS.text} />
                          <div>{occupancy.place.name}</div>
                        </Flex>
                      }
                    />

                    <DataList.Item label="Occupied From" value={usDateTime(occupancy.started_at, timezone)} />

                    <DataList.Item
                      label="Occupied Until"
                      value={data.use_custom_move_date ? usDateTime(data.custom_moved_at, timezone) : 'Now'}
                    />
                  </DataList>
                </Card>

                <Button label="Remove from Program" type="primary" color="red" glyph="check_in" isLoading={isSaving} onClick={onSave} />
              </Grid>
            </Workflow.Content>
          </Workflow.Panel>
        </Workflow>
      </Overlay.Content>
    </Overlay>
  )
}

export const ProgramRemoveOverlay = withOverlayError(withSettings(RootProgramRemoveOverlay))
