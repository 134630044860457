import { request } from './axios'

const INTERVAL = 5 * 60 * 1000 // every 5 minutes

class Version {
  interval = null
  service = null

  start = (service = 'ehr') => {
    if (process.env.BH_ENV !== 'production') return
    if (this.interval) return

    this.service = service

    this.interval = setInterval(async () => {
      await this.checkForUpdate(this.service)
    }, INTERVAL)
  }

  stop = () => {
    if (this.interval) clearInterval(this.interval)
  }

  getVersion = async (service) => {
    try {
      const req = await request.get(`https://version.behavehealth.com/api/v1/version/${service}`)
      return req.data
    } catch (error) {
      console.error(error)
    }
  }

  checkForUpdate = async (service = 'ehr') => {
    if (process.env.BH_ENV !== 'production') return

    try {
      const version = await this.getVersion(service)

      console.log(`Checking ${service} version: `, version)

      // check if versions are different
      if (version !== process.env.BH_VERSION) window.updateAvailable = true
    } catch (error) {
      console.error(error)
    }
  }
}

export default new Version()
