import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useAPI } from '@behavehealth/hooks'
import { apiDelete } from '@behavehealth/modules/api'

import { Documents, Tenant } from '@behavehealth/declarations'

import { Chotomate, DnDUploader, Card, CardContent } from '@behavehealth/components'
import { FilesTable } from '@behavehealth/components/Tables'

const ClientPortal: React.FC = () => {
  const match = useRouteMatch()
  const tenant: Tenant = useSelector((state: any) => state.me.tenant)
  const data: Documents = useSelector((state: any) => state.data.documents?.data)
  const loading = useSelector((state: any) => state.data.documents?.loading)

  useAPI('documents', `/facilities/${tenant.id}/documents?category=client_portal`)

  const isEmpty = size(data) === 0

  return (
    <Card>
      <Chotomate name="files_client_portal" ready={!loading} />

      <FilesTable
        title="Client Portal Files"
        data={data}
        isLoading={isEmpty && loading}
        to={(id: string) => ({
          pathname: `${match.url}/${id}`,
          parent: match,
        })}
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'files.delete',
            action: async ({ ids }: any) => {
              await apiDelete({ name: 'documents', url: `/documents/${ids.join(',')}` })
            },
          },
        ]}
      />
      <CardContent>
        <DnDUploader isEditable category="client_portal" resource={tenant} isDisabled={false} />
      </CardContent>
    </Card>
  )
}

export default ClientPortal
