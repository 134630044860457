import React from 'react'

import { TipAlert, Link } from '@behavehealth/components'
import { DeclinedDataTable } from '@behavehealth/constructs/Clients/DeclinedDataTable'

const Applicants: React.FC = () => {
  return <DeclinedDataTable />
}

const STYLES = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
}

export default Applicants
