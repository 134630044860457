import React from 'react'

import { TreeChart } from '@behavehealth/components'

const data = {
  name: 'Angels Hands Recovery',
  children: [
    {
      name: 'Acadia House',
      children: [
        {
          name: 'Staff',
          children: [
            {
              name: 'Dianne Grey',
            },
            {
              name: 'Edgar Blackwell',
            },
          ],
        },
        {
          name: 'Clients',
        },
      ],
    },
    {
      name: 'Biscayne House',
      children: [
        {
          name: 'Staff',
        },
        {
          name: 'Clients',
        },
      ],
    },
    {
      name: 'Canyonlands House',
      children: [
        {
          name: 'Staff',
        },
        {
          name: 'Clients',
        },
      ],
    },
    {
      name: 'Guadelupe House',
      children: [
        {
          name: 'Staff',
        },
        {
          name: 'Clients',
          children: [
            {
              name: 'Edgar Blackwell',
            },
          ],
        },
      ],
    },
    {
      name: 'Unassigned Clients',
      children: [
        {
          name: 'Staff',
        },
        {
          name: 'Clients',
        },
      ],
    },
  ],
}

const TreeChartExample = (props: any) => {
  return <TreeChart data={data} {...props} />
}

export default TreeChartExample
