import React from 'react'

import Button from './Button'
import Card from './Card'
import CardHeader from './CardHeader'
import CardTitle from './CardTitle'
import Glyph from './Glyph'
import Loader from './Loader'
import Status from './Status'

const statuses = {
  canceled: {
    name: 'Canceled',
    color: 'red',
    glyph: 'cross',
  },
  completed: {
    name: 'Completed',
    color: 'green',
    glyph: 'tick',
  },
  error: {
    name: 'Error',
    color: 'red',
    glyph: 'circle_error',
  },
  loading: {
    name: 'Loading',
    color: 'blue',
    glyph: 'more_vertical',
  },
  pending: {
    name: 'Pending',
    color: 'orange',
    glyph: 'time',
  },
}

const UploadFile = ({ onRemove, file }) => {
  const onRemoveCallback = () => {
    if (onRemove) onRemove(file.id)
  }

  if (!file) return null

  let after = null
  const status = file.status

  if (status === 'loading') after = <Loader />
  else if (status === 'pending') after = <Button glyph="cross" color="red" onClick={onRemoveCallback} />

  return (
    <Card variant="variant-list">
      <CardHeader graphic={<Glyph glyph={statuses[status].glyph} />} after={after}>
        <CardTitle title={file.name} />
        {status && <Status label={statuses[status].name} color={statuses[status].color} />}
      </CardHeader>
    </Card>
  )
}

export default UploadFile
