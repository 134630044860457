import React from 'react'

import Grid from '../../Grid'
import Flex from '../../Flex'
import Status from '../../Status'
import Overlay from '../../Overlay'
import Section from '../../Section'

const MessagePollOverlay = ({ message, onClose }: any) => {
  const title = 'Message Reactions'

  return (
    <Overlay showBackdrop onClose={onClose}>
      <Overlay.Header title={title} />

      <Overlay.Content>
        <Section>
          <Grid gap="1rem">
            <div css={{ marginBottom: '1rem', fontStyle: 'italic' }}>{message?.text}</div>

            {/* {[DUMMY_USER_1, DUMMY_USER_3, DUMMY_USER_2, DUMMY_USER_3].map((user) => (
              <Flex gap={8}>
                <Status color="green" label="Yes" />
                <span>{user.name}</span>
              </Flex>
            ))}

            {[DUMMY_USER_2].map((user) => (
              <Flex gap={8}>
                <Status color="red" label="No" />
                <span>{user.name}</span>
              </Flex>
            ))} */}
          </Grid>
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}

export default MessagePollOverlay
