import React from 'react'

import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../../components/Forms/CheckboxGroup'
import Dropdown from '../../../../components/Dropdown'
import DropdownItem from '../../../../components/DropdownItem'
import Flex from '../../../../components/Flex'
import Input from '../../../../components/Forms/Input'
import Label from '../../../../components/Label'
import Option from '../../../../components/Forms/Option'
import Radio from '../../../../components/Forms/Radio'
import RadioGroup from '../../../../components/Forms/RadioGroup'
import Select from '../../../../components/Forms/Select'
import URLInput from '../../../../components/Forms/URLInput'

export const IframeEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate, editElementConfig } = props

  const {
    aspect_ratio_high = 1,
    aspect_ratio_wide = 3,
    iframe_height = 800,
    iframe_height_type = 'pixels',
    iframe_sizing_strategy = 'custom',
    iframe_width = 100,
    iframe_width_type = 'percentage',
    label,
    loading_strategy = 'none',
    show_reload_button,
    show_width_controls,
    url,
  } = activeElement.config

  return (
    <>
      <URLInput label="Website URL" model="url" value={url} onUpdate={onUpdate} />

      <Input label="Label" model="label" value={label} onUpdate={onUpdate} />

      <Select label="Sizing Strategy" model="iframe_sizing_strategy" value={iframe_sizing_strategy} onUpdate={onUpdate}>
        <Option label="Aspect Ratio" value="aspect_ratio" />
        <Option label="Custom Width/Height" value="custom" />
      </Select>

      <Flex nowrap gap="0.75rem">
        <Input label="Width" type="number" model="iframe_width" size={4} value={iframe_width || 100} onUpdate={onUpdate} />
        <Select label="Type" model="iframe_width_type" value={iframe_width_type} onUpdate={onUpdate} flex="1 1 auto">
          <Option label="Percent" value="percentage" />
          <Option label="Pixels" value="pixels" />
          <Option label="Screen Percent" value="viewport_width" />
        </Select>
      </Flex>

      {iframe_sizing_strategy === 'custom' && (
        <Flex nowrap gap="0.75rem">
          <Input label="Height" type="number" model="iframe_height" size={4} value={iframe_height || 100} onUpdate={onUpdate} />
          <Select label="Type" model="iframe_height_type" value={iframe_height_type} onUpdate={onUpdate} flex="1 1 auto">
            <Option label="Percent" value="percentage" />
            <Option label="Pixels" value="pixels" />
            <Option label="Screen Percent" value="viewport_height" />
          </Select>
        </Flex>
      )}

      {iframe_sizing_strategy === 'aspect_ratio' && (
        <div>
          <Label
            label="Aspect Ratio"
            after={
              <Dropdown
                triggerStyles={STYLES.dropdownTrigger}
                trigger={<Button hideLabel glyph="triangle_down" size={100} css={STYLES.dropdownButton} />}
              >
                <DropdownItem
                  label="Square (1:1)"
                  onClick={() => {
                    editElementConfig({
                      uuid: activeElement.uuid,
                      config: {
                        aspect_ratio_wide: 1,
                        aspect_ratio_high: 1,
                      },
                    })
                  }}
                />
                <DropdownItem
                  label="Video (16:9)"
                  onClick={() => {
                    editElementConfig({
                      uuid: activeElement.uuid,
                      config: {
                        aspect_ratio_wide: 16,
                        aspect_ratio_high: 9,
                      },
                    })
                  }}
                />
                <DropdownItem
                  label="Portrait (2:4)"
                  onClick={() => {
                    editElementConfig({
                      uuid: activeElement.uuid,
                      config: {
                        aspect_ratio_wide: 2,
                        aspect_ratio_high: 4,
                      },
                    })
                  }}
                />
                <DropdownItem
                  label="Landscape (4:2)"
                  onClick={() => {
                    editElementConfig({
                      uuid: activeElement.uuid,
                      config: {
                        aspect_ratio_wide: 4,
                        aspect_ratio_high: 2,
                      },
                    })
                  }}
                />
              </Dropdown>
            }
          />
          <Flex gap="0.75rem">
            <Input suffix="wide" type="number" model="aspect_ratio_wide" size={2} value={aspect_ratio_wide} onUpdate={onUpdate} />
            <Input suffix="high" type="number" model="aspect_ratio_high" size={2} value={aspect_ratio_high} onUpdate={onUpdate} />
          </Flex>
        </div>
      )}

      <CheckboxGroup layout="vertical-dense">
        <Checkbox
          label="Show Reload Button"
          model="show_reload_button"
          onUpdate={onUpdate}
          // value={show_reload_button}
        />
        <Checkbox
          label="Show Width Controls"
          model="show_width_controls"
          onUpdate={onUpdate}
          // value={show_width_controls}
        />
      </CheckboxGroup>

      <RadioGroup label="Loading Strategy" model="loading_strategy" value={loading_strategy} onUpdate={onUpdate} layout="vertical-dense">
        <Radio label="On page load" value="none" />
        <Radio label="On button click" value="on_click" />
      </RadioGroup>
    </>
  )
}

const STYLES = {
  dropdownTrigger: {
    marginLeft: 'auto',
  },

  dropdownButton: {
    width: 22,
    height: 22,
    minWidth: 22,
    minHeight: 22,
    svg: { width: 14, height: 14 },
  },
}
