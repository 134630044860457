import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  inactive: {
    label: 'Inactive',
    color: 'gray',
  },
  in_progress: {
    label: 'In Progress',
    color: 'yellow',
  },
  extended: {
    label: 'Extended',
    color: 'orange',
  },
  cancelled: {
    label: 'Cancelled',
    color: 'red',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
  unmet: {
    label: 'Unmet',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
