import React from 'react'
import size from 'lodash/size'
import { useRouteMatch } from 'react-router-dom'

import { mapToArray } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, Divider, Grid, Dropdown, DropdownItem, Page, HelpTagIframe } from '@behavehealth/components'
import { CustomNotesDataTable } from '@behavehealth/constructs/CustomNotes/CustomNotesDataTable'

const AddDropdown = ({ templates }: any) => {
  const match = useRouteMatch()

  return (
    <Dropdown label="Add Custom Note…" glyph="add" buttonType="primary" buttonSize={300} permission="organization_custom_notes.create">
      {mapToArray(templates).map((template) => (
        <DropdownItem
          key={template.id}
          label={template.name}
          glyph="documents"
          color="paleBlue"
          link={{
            pathname: `${match.url}/new`,
            parent: match,
            data: template,
          }}
        />
      ))}

      <Divider />

      <DropdownItem
        label="Empty Custom Note"
        glyph="add"
        color="paleBlue"
        link={{
          pathname: `${match.url}/new`,
          parent: match,
          data: {
            name: 'New Custom Note',
          },
        }}
      />
    </Dropdown>
  )
}

const pageConfig = {
  feature: 'custom_notes',
  marketingID: 'client_financials',
}

type Props = {
  canCreate: boolean
}

const CustomNotes: React.FC<Props> = () => {
  const match = useRouteMatch()

  const id = match.params?.resource_id

  const tableProps = useDataTable({
    name: ['organization', id, 'custom_notes'],
    endpoint: `/organizations/${id}/custom_notes`,
    params: { variant: 'organization' },
    updateDeleteEndpoint: '/custom_notes',
    localStorageKey: 'organization_custom_notes',
  })

  const { data: templates, isLoading: isLoadingTemplates } = useGet({
    name: ['custom_note_templates', { variant: 'organization' }],
    url: `/custom_note_templates`,
    params: { variant: 'organization' },
  })

  const isTemplatesEmpty = size(templates) === 0

  if (!isLoadingTemplates && isTemplatesEmpty) {
    return (
      <>
        <Page
          isEmpty={true}
          emptyDescription="No custom note templates have been added yet. Go to Settings to create the first template."
          help={<HelpTagIframe id="custom_notes" />}
          actions={
            <Button
              size={300}
              label="Manage Custom Note Templates"
              glyph="settings"
              type="primary"
              link="/settings/custom-note-templates/organizations"
              permission="settings.organization_agreement_templates.view"
            />
          }
          {...pageConfig}
        />
      </>
    )
  }

  return (
    <Page
      feature="custom_notes"
      plan="basic"
      actions={
        <>
          <Button
            size={300}
            glyph="settings"
            type="default"
            label="Manage Templates"
            link="/settings/custom-note-templates/organizations"
            permission="settings.organization_agreement_templates.view"
          />

          <AddDropdown templates={templates} />
        </>
      }
    >
      <Grid>
        <Card>
          <CustomNotesDataTable
            {...tableProps}
            to={(record: any) => ({
              pathname: `${match.url}/${record.id}`,
              parent: match,
            })}
            localStorageKey="organization_custom_notes"
            duplicatePermission="organization_custom_notes.create"
            duplicateLink={(record) => {
              const {
                client,
                employees,
                ended_at,
                name,
                notes,
                office,
                organization,
                portal_settings,
                property,
                reference,
                sections,
                settings,
                started_at,
                supervisor,
                tags,
                variant,
              } = record

              return {
                pathname: `${match.url}/new`,
                parent: match,
                data: {
                  status: 'draft',
                  client,
                  employees,
                  ended_at,
                  name,
                  notes,
                  office,
                  organization,
                  portal_settings,
                  property,
                  reference,
                  sections,
                  settings,
                  started_at,
                  supervisor,
                  tags,
                  variant,
                },
              }
            }}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'organization_custom_notes.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CustomNotes, pageConfig))
