import React from 'react'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

import { ROSTER_SERVICE_STATUSES } from '../constants'

export const RosterServiceStatusSelect = ({ isEditable, label, model, validations, onUpdate, value, defaultValue = 'draft' }: any) => (
  <Select
    fullWidth
    allowEmpty
    isEditable={isEditable}
    label={label}
    model={model}
    validations={validations}
    onUpdate={onUpdate}
    value={value}
    defaultValue={defaultValue}
  >
    {Object.keys(ROSTER_SERVICE_STATUSES).map((key: any) => (
      <Option key={key} label={ROSTER_SERVICE_STATUSES[key].label} value={key} />
    ))}
  </Select>
)

RosterServiceStatusSelect.defaultProps = {
  label: 'Service Status',
  model: 'service_status',
}
