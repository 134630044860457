import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'

import { apiCreate, apiGet } from '../../../modules/api'
import { COLORS, ICONS } from '../../../theme'

import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ContextHide from '../../Forms/ContextHide'
import ContextShow from '../../Forms/ContextShow'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import Section from '../../Section'
import TimeInputSelector from '../../Forms/TimeInputSelector/TimeInputSelector'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const getWeeks = (count) => {
  let c = 1
  let weeks = []

  while (c <= count) {
    weeks.push(c)
    c++
  }

  return weeks
}

class ProgramEventsBuilder extends OverlayBase {
  save = async () => {
    const formData = this.form.current.getFormValue()

    const data: any = []

    for (let i = 1; i <= size(formData.weeks); i++) {
      const week = formData.weeks[`week_${i}`]

      if (!week.is_active) continue

      for (let j = 0; j <= 6; j++) {
        if (week?.days[`day_${j}`]) {
          data.push({
            reference_id: formData.event_id,
            week: i,
            day: j,
            is_all_day: formData.is_all_day,
            started_at: formData.started_at,
            ended_at: formData.ended_at,
            programId: formData.programId,
          })
        }
      }
    }

    await apiCreate({ name: 'events', url: `/events`, params: data })
    this.close()

    await apiGet({ name: 'events', url: `/programs/${this.props.match.params.program_id}/events` })
  }

  renderHeader = () => <Overlay.Header icon="calendar" title="Add Program Events" />

  renderContent = () => {
    const { record, program, match } = this.props

    const weeks = getWeeks(program?.schedule_duration)

    return (
      <Overlay.Content>
        {program && (
          <Form
            isEditable
            getForm={this.form}
            initialModel={record}
            onValidationUpdate={this.onValidationUpdate}
            linked={{
              programId: match.params.program_id,
            }}
          >
            <Section>
              <FormSection>
                <ObjectSelector
                  label="Event"
                  model="event"
                  type="manager_events"
                  icon={ICONS.schedule}
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => data.description}
                  validations={{
                    presence: {
                      message: 'Please select an event',
                    },
                  }}
                />

                <Checkbox model="is_all_day" label="All Day Event" trueIcon="check" />

                <ContextHide when="is_all_day" is={true}>
                  <FormSection horizontal>
                    <TimeInputSelector
                      defaultToNow
                      label="From"
                      model="started_at"
                      validations={{
                        presence: {
                          message: 'Please enter a start time',
                        },
                      }}
                    />

                    <TimeInputSelector
                      label="To"
                      model="ended_at"
                      validations={{
                        presence: {
                          message: 'Please enter an end time',
                        },
                      }}
                    />
                  </FormSection>
                </ContextHide>

                <CheckboxGroup label="Weeks">
                  {weeks?.map((week) => (
                    <React.Fragment key={week}>
                      <Checkbox label={`Week ${week}`} model={`weeks.week_${week}.is_active`} defaultChecked={weeks.length === 1} />
                      <ContextShow when={`weeks.week_${week}.is_active`} is={true}>
                        <div
                          css={{
                            position: 'relative',
                            paddingLeft: '2rem',

                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              width: 2,
                              height: '100%',
                              top: 0,
                              left: '1rem',
                              background: COLORS.divider,
                            },
                          }}
                        >
                          <CheckboxGroup layout="vertical-dense">
                            {WEEK_DAYS.map((day, index) => (
                              <Checkbox key={`${week}-${day}`} label={day} model={`weeks.week_${week}.days.day_${index}`} />
                            ))}
                          </CheckboxGroup>
                        </div>
                      </ContextShow>
                    </React.Fragment>
                  ))}
                </CheckboxGroup>
              </FormSection>
            </Section>
          </Form>
        )}
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    return (
      <Overlay.Footer>
        <Button
          label="Save Event"
          type="primary"
          color="green"
          glyph="check"
          isLoading={this.props.loading}
          onClick={this.save}
          isDisabled={this.state.isInvalid}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => ({
  ...defaultMapStateToProps(state, props.match, 'events'),
  program: state.data.programs?.data?.[props.match?.params?.program_id],
})

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(ProgramEventsBuilder))
