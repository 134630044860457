import React from 'react'
import { connect } from 'react-redux'

import { withFormContext } from '../Forms/context'
import { Medication as MedicationCard } from '../Cards'

import Button from '../Button'
import SummonOverlay from '../SummonOverlay'
import { MedicationOverlay } from '../../constructs/Medications/MedicationOverlay'

import { SmartContentBase, defaultMapDispatchToProps, defaultMapStateToProps } from './SmartContentBase'

import { apiGet } from '../../modules/api'

class Medications extends SmartContentBase {
  getData = (id) => apiGet({ name: 'meds', url: `/residents/${id}/meds` })

  renderElement = (element, disabled = false) => (
    <SummonOverlay
      key={element.id}
      overlay={
        <MedicationOverlay
          type="summon"
          dataID={element.id}
          initialData={element}
          onSaveSuccessful={this.fetchData}
          onDeleteSuccessful={this.fetchData}
        />
      }
      isDisabled={disabled}
    >
      <MedicationCard showActions={false} medication={element} />
    </SummonOverlay>
  )

  renderTrigger = () => (
    <SummonOverlay
      overlay={
        <MedicationOverlay
          type="summon"
          dataID="new"
          initialData={{ resident: this.props.client }}
          onSaveSuccessful={this.fetchData}
          onDeleteSuccessful={this.fetchData}
        />
      }
    >
      <Button type="default" glyph="add" label="Add Medication" permission="medications.create" />
    </SummonOverlay>
  )
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'meds')
const mapStateToProps = (state) => defaultMapStateToProps(state, 'meds')

export default withFormContext(connect(mapStateToProps, mapDispatchToProps)(Medications))
