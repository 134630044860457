import React from 'react'

import Select from '../common/Select'

type Props = { column: any }

const TrueFalseFilter = (props: Props) => {
  const {
    column: { filterValue, setFilter },
  } = props

  const filterOptions = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ]

  return <Select setFilter={setFilter} filterValue={filterValue} filterOptions={filterOptions} />
}

export default TrueFalseFilter
