import React from 'react'
import { connect } from 'react-redux'

import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import ContextHide from '../../Forms/ContextHide'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Overlay from '../../Overlay'
import RosterCategorySelect from '../../Forms/elements/RosterCategorySelect'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'
import TimeInputSelector from '../../Forms/TimeInputSelector/TimeInputSelector'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class ProgramEvent extends OverlayBase {
  renderHeader = () => <Overlay.Header icon="calendar" title="Program Event" />

  renderContent = () => {
    const { $editable } = this.state
    const { record } = this.props

    if (!record) return null

    return (
      <Overlay.Content>
        <Form getForm={this.form} initialModel={record} isEditable={$editable} onValidationUpdate={this.onValidationUpdate}>
          <Section>
            <FormSection>
              <Input isEditable={false} label="Title" model="reference.name" />
              <Textarea isEditable={false} label="Description" model="reference.description" />
              <RosterCategorySelect isEditable={false} label="Roster Type" model="reference.roster_category" />

              <Checkbox model="is_all_day" label="All Day Event" trueIcon="check" />

              <ContextHide when="is_all_day" is={true}>
                <FormSection horizontal>
                  <TimeInputSelector
                    defaultToNow
                    label="From"
                    model="started_at"
                    validations={{
                      presence: {
                        message: 'Please enter a start time',
                      },
                    }}
                  />

                  <TimeInputSelector
                    label="To"
                    model="ended_at"
                    validations={{
                      presence: {
                        message: 'Please enter an end time',
                      },
                    }}
                  />
                </FormSection>
              </ContextHide>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save Event"
              type="primary"
              color="green"
              glyph="check"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              label="Complete Roster…"
              icon="roster"
              flex="10 1 100%"
              link={{
                pathname: '/rosters/active/new',
                data: {
                  name: this.props.record.reference.name,
                  category: this.props.record.reference.roster_category,
                  program: this.props.program,
                },
              }}
            />

            <Button label="Edit Event" glyph="edit" isDisabled={this.props.loading} onClick={this.edit} flex="10 1 auto" />

            <DeleteDialog
              title="Delete Event?"
              message="Are you sure you want to delete this event? This action cannot be undone."
              onYes={this.delete}
            >
              <Button glyph="delete" label="Delete" color="red" isLoading={this.props.loading} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => ({
  ...defaultMapStateToProps(state, props.match, 'events'),
  program: state.data.programs?.data?.[props.match?.params?.program_id],
})

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(ProgramEvent))
