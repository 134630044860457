import React from 'react'
import { COLORS } from '../theme'

const PlanTag = () => <span css={styles}>Upgrade</span>

const styles = {
  alignSelf: 'baseline',
  display: 'inline-block',
  marginLeft: '0.2em',

  fontSize: '.75rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  linearHeight: '1rem',
  whiteSpace: 'nowrap',
  color: COLORS.textMuted,
}

export default PlanTag
