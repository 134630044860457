import React from 'react'
import { Route, Switch, Redirect, useRouteMatch, useLocation } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Page, Grid, Tab, TabList, Tabs, HelpTagIframe } from '@behavehealth/components'

import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

import BreathalyzerTests from './test_results/breathalyzer_tests'
import CommunicableDiseaseTests from './test_results/communicable_disease_tests'
import UATests from './test_results/ua_tests'
import VitalSigns from './test_results/vital_signs'

const pageConfig = {
  feature: 'test_results',
  help: <HelpTagIframe id="test_results" />,
  marketingID: 'test_results',
}

const TestResults: React.FC = () => {
  const { url } = useRouteMatch()
  const { pathname } = useLocation()

  const uaSelected = pathname.includes(`${url}/ua-tests`)
  const breathalyzerSelected = pathname.includes(`${url}/breathalyzer-tests`)
  const vitalSignsSelected = pathname.includes(`${url}/vital-signs`)
  const communicableDiseaseTestsSelected = pathname.includes(`${url}/communicable-disease-tests`)

  return (
    <Page
      actions={
        <>
          {uaSelected && (
            <Dropdown label="Add UA Test…" buttonType="primary" permission="test_results.ua_tests.create">
              <DropdownItem label="Add UA Test" icon="test_results" color="blue" link={`${url}/ua-tests/new`} />
              <DropdownItem
                label="Add Many UA Tests…"
                glyph="stack"
                color="blue"
                link={`${url}/ua-tests/new-batch`}
                featureFlagV2="bulk_add"
              />
            </Dropdown>
          )}

          {breathalyzerSelected && (
            <Dropdown label="Add Breathalyzer Test…" buttonType="primary" permission="test_results.breathalyzer_tests.create">
              <DropdownItem label="Add Breathalyzer Test" icon="test_results" color="blue" link={`${url}/breathalyzer-tests/new`} />
              <DropdownItem
                label="Add Many Breathalyzer Tests…"
                glyph="stack"
                color="blue"
                link={`${url}/breathalyzer-tests/new-batch`}
                featureFlagV2="bulk_add"
              />
            </Dropdown>
          )}

          {vitalSignsSelected && (
            <Button
              label="Add Vital Signs"
              type="primary"
              glyph="add"
              link={`${url}/vital-signs/new`}
              permission="test_results.vital_signs.create"
            />
          )}

          {communicableDiseaseTestsSelected && (
            <Button
              label="Add Communicable Disease Test"
              type="primary"
              glyph="add"
              link={`${url}/communicable-disease-tests/new`}
              permission="test_results.communicable_disease_tests.create"
            />
          )}
        </>
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        <Tabs>
          <TabList className="-mt-4">
            <Tab
              showForCommunity
              label="UA Tests"
              to={`${url}/ua-tests`}
              permission="test_results.ua_tests.view"
              featureFlagV2="ua_tests"
            />
            <Tab
              showForCommunity
              label="Breathalyzer Tests"
              to={`${url}/breathalyzer-tests`}
              permission="test_results.breathalyzer_tests.view"
              featureFlagV2="breathalyzer_tests"
            />
            <Tab
              label="Vital Signs"
              to={`${url}/vital-signs`}
              newFeatureFlag="lab_tests_2"
              permission="test_results.vital_signs.view"
              featureFlagV2="vital_signs"
            />
            <Tab
              label="Communicable Disease Tests"
              to={`${url}/communicable-disease-tests`}
              newFeatureFlag="lab_tests_2"
              permission="test_results.communicable_disease_tests.view"
              featureFlagV2="communicable_disease_tests"
            />
          </TabList>
        </Tabs>

        <Switch>
          <Route
            path={`/(employees|me)/:resource_id/test-results/ua-tests`}
            render={() => <UATests permission="test_results.ua_tests.view" />}
          />
          <Route
            path={`/(employees|me)/:resource_id/test-results/breathalyzer-tests`}
            render={() => <BreathalyzerTests permission="test_results.breathalyzer_tests.view" />}
          />
          <Route
            path={`/(employees|me)/:resource_id/test-results/vital-signs`}
            render={() => <VitalSigns permission="test_results.vital_signs.view" />}
          />
          <Route
            path={`/(employees|me)/:resource_id/test-results/communicable-disease-tests`}
            render={() => <CommunicableDiseaseTests permission="test_results.communicable_disease_tests.view" />}
          />

          <Redirect exact from={url} to={`${url}/ua-tests`} />
        </Switch>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(TestResults, pageConfig))
