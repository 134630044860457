import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useAPI, useSettings } from '../../../hooks'
import withSettings from '../../../hocs/withSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import DataFormStatus from '../../../components/Statuses/DataFormStatus'
import Grid from '../../../components/Grid'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import TableFilter from '../../../components/Table/TableFilter'
import Workflow from '../../../components/Workflow/Workflow'

import { PreScreenOverlayV1 } from '../../ClinicalAssessments/PreScreenOverlayV1'
import { PreScreenOverlayV2 } from '../../ClinicalAssessments/PreScreenOverlayV2'
import { apiGet } from '../../../modules/api'

const onSaveSuccessful = (clientId: any) => {
  apiGet({
    name: 'pre_screen',
    url: `/residents/${clientId}/data_forms?category=clinical_assessment&subcategory=prescreen`,
  })
}

const columns = (client: any, timezone: any) => [
  {
    width: 180,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => {
      if (row.original.version === '1.0') {
        return (
          <SummonOverlay
            overlay={
              <PreScreenOverlayV1
                showBackdrop
                dataID={row.original.id}
                reference={client}
                onSaveSuccessful={() => onSaveSuccessful(client.id)}
                onDeleteSuccessful={() => onSaveSuccessful(client.id)}
              />
            }
          >
            <TableCell.MainLink label={value} />
          </SummonOverlay>
        )
      }

      return (
        <SummonOverlay
          overlay={
            <PreScreenOverlayV2
              showBackdrop
              dataID={row.original.id}
              reference={client}
              onSaveSuccessful={() => onSaveSuccessful(client.id)}
              onDeleteSuccessful={() => onSaveSuccessful(client.id)}
            />
          }
        >
          <TableCell.MainLink label={value} />
        </SummonOverlay>
      )
    },
  },
  {
    width: 200,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <DataFormStatus status={value} />,
    Filter: TableFilter.DataFormStatus,
    filter: 'dataFormStatus',
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Last Edited',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original?.author?.avatar} name={row.original.author?.name} />,
  },
]

const AddButton = ({ client }: any) => {
  return (
    <SummonOverlay
      overlay={
        <PreScreenOverlayV2
          dataID="new"
          reference={client}
          onSaveSuccessful={() => onSaveSuccessful(client.id)}
          onDeleteSuccessful={() => onSaveSuccessful(client.id)}
        />
      }
    >
      <Button label="Add Pre-Screen" glyph="add" type="primary" display="inline-flex" permission="clinical_assessments.create" />
    </SummonOverlay>
  )
}

const PreScreen = ({ client, setStatus }: any) => {
  const { resource_id }: any = useParams()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { isRequired }: any = React.useContext(Workflow.PanelContext)

  const tableData = useSelector((state: any) => state.data?.pre_screen?.data)
  const loading = useSelector((state: any) => state.data?.pre_screen?.loading)
  const isEmpty = size(tableData) === 0

  const { timezone } = useSettings()

  useAPI('pre_screen', `/residents/${resource_id}/data_forms?category=clinical_assessment&subcategory=prescreen`)

  const onContinue = async () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  if (isEmpty || loading) {
    return (
      <State
        isLoading={loading}
        isEmpty={isEmpty}
        title="Pre-Screen"
        icon="clinical_assessments"
        emptyDescription="No Pre-Screen forms completed yet"
        emptyActions={<AddButton client={client} />}
      />
    )
  }

  return (
    <Grid gap="1rem">
      <Card testKey="pre_screen_table">
        <Table
          title="Pre-Screen Forms"
          icon="clinical_assessments"
          localStorageKey="clients_pre_screen"
          data={tableData}
          columns={columns(client, timezone)}
          isLoading={loading}
          showSettings={false}
        />

        <CardContent>
          <AddButton client={client} />
        </CardContent>
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        {!isRequired && <Workflow.SkipButton onClick={onSkip} />}
      </Workflow.Buttons>
    </Grid>
  )
}

export default withSettings(PreScreen)
