import React from 'react'
import difference from 'lodash/difference'
import size from 'lodash/size'

import { apiGet } from '../../../../../modules/api'
import { TabsContext } from '../../../../../hocs/withTabsContext'

import Alert from '../../../../Alert'
import Button from '../../../../Button'
import Card from '../../../../Card'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import Form from '../../../../Forms/Form'
import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import State from '../../../../State'
import SummonOverlay from '../../../../SummonOverlay'

import AddDiagnosesOverlay from '../components/AddDiagnosesOverlay'
import Footer from '../components/Footer'
import Main from '../components/Main'
import Subsection from '../components/Subsection'

import { checkValid, icd10ToKey, icd10ToCode } from './utils'

const Diagnoses = ({ formData, setFormData, setWileyData, diagnosisCodes, addDiagnosesCodes, initialDiagnosisCodes }: any) => {
  const { onSelect: selectTab }: any = React.useContext(TabsContext)

  const form: any = React.useRef()
  const valid = checkValid(formData)

  const [loading, setLoading] = React.useState(false)
  const [apiData, setAPIData]: any = React.useState([])

  const isEmpty = size(apiData) === 0
  const emptyDiagnosisCodes = size(diagnosisCodes) === 0

  const missingDiagnosisCodes = difference(
    diagnosisCodes,
    apiData?.map?.((o: any) => o.icd10),
  )
  const hasMissingDiagnosisCodes = size(missingDiagnosisCodes) >= 1

  // Get Wiley Data
  React.useEffect(() => {
    if (emptyDiagnosisCodes) return

    const getWileyData = async () => {
      try {
        setLoading(true)

        const result = await apiGet({ url: `/wiley/problem_groups_by_diagnoses?icd10=${diagnosisCodes.join(',')}` })

        setAPIData(result?.data?.data)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    getWileyData()
  }, [diagnosisCodes])

  const updateWileyData = () => {
    if (!valid) return

    const { selected_icd10, selected_diagnoses } = form.current?.getFormValue()

    const icd10: any = []
    const result: any = {}

    for (const icd10Key in selected_icd10) {
      if (!selected_icd10[icd10Key]) continue

      const { problem_group_id, problem_id } = selected_diagnoses[icd10Key]

      const apiDiagnosis = apiData.find((o: any) => o.icd10 === icd10ToCode(icd10Key))
      const apiProblemGroup = apiDiagnosis.problem_groups.find((o: any) => o.group_id === problem_group_id)
      const apiProblem = apiProblemGroup.problems.find((o: any) => o.problem_id === problem_id)

      icd10.push(icd10ToCode(icd10Key))

      result[icd10Key] = {
        // diagnosis
        icd10: apiDiagnosis.icd10,
        description: apiDiagnosis.description,

        // problem group
        problem_group_id: problem_group_id,
        problem_group_description: apiProblemGroup.description,

        // problem
        problem_id: problem_id,
        problem_description: apiProblem.description,
      }
    }

    setWileyData(result)
  }

  const goNext = () => {
    updateWileyData()
    selectTab('behavior')
  }

  const emptyInitialDiagnosisCodes = size(initialDiagnosisCodes) === 0

  const missingDiagnosesAlert = hasMissingDiagnosisCodes && !loading && (
    <Alert contrast small glyph="warning" type="warning" css={styles.alert}>
      <b>Please note:</b> we couldn't find any Wiley data for the following diagnosis codes: <i>{missingDiagnosisCodes.join(', ')}</i>.
    </Alert>
  )

  if (loading || isEmpty) {
    return (
      <Main>
        {emptyInitialDiagnosisCodes ? (
          <Alert contrast small type="warning" glyph="warning" className="mt-1.5">
            <span className="font-[600]">Please note:</span> the Treatment Plan does not have any imported diagnosis codes. You can go back
            and import them, or use the search function below to search by diagnosis codes.
          </Alert>
        ) : (
          <Alert contrast small glyph="info" className="mt-1.5">
            Searching within Wiley Practice Planner using the following diagnosis codes imported in the Treatment Plan:{' '}
            <i>{initialDiagnosisCodes.join(', ')}</i>. You can manually search for other diagnosis codes using the search functionality
            below.
          </Alert>
        )}

        {missingDiagnosesAlert}

        <Card css={styles.emptyCard}>
          <State
            isEmpty={isEmpty}
            isLoading={loading}
            icon="diagnosis"
            title="Diagnoses"
            emptyDescription="Please search by at least one diagnosis code to use the Wiley Practice Planner"
            emptyActions={
              <SummonOverlay
                portalType="z100"
                overlay={
                  <AddDiagnosesOverlay
                    title="Search Diagnoses"
                    onSave={addDiagnosesCodes}
                    buttonLabel="Search by Selected Diagnoses…"
                    buttonGlyph="search"
                    before={
                      <Alert contrast small glyph="info" className="mb-3">
                        <span className="font-[600]">Please note: </span> these diagnoses will not be saved to the client's treatment plan,
                        they are used only to search within Wiley Practice Planner.
                      </Alert>
                    }
                  />
                }
              >
                <Button label="Search by Diagnosis Codes" glyph="search" type="primary" />
              </SummonOverlay>
            }
          />
        </Card>
      </Main>
    )
  }

  return (
    <>
      <Main>
        <Form useFullModel getForm={form} initialModel={formData} onUpdate={setFormData}>
          <FormSection maxWidth="100%">
            {missingDiagnosesAlert}

            <CheckboxGroup label="Diagnoses:" withHover={false}>
              {apiData?.map?.((diagnosis: any) => {
                if (!diagnosis?.icd10) return null

                const icd10_key = icd10ToKey(diagnosis.icd10)

                return (
                  <Checkbox
                    key={diagnosis.icd10}
                    label={`(${diagnosis.icd10}) ${diagnosis.description}`}
                    model={`selected_icd10.${icd10_key}`}
                  >
                    <ContextShow when={`selected_icd10.${icd10_key}`} is={true}>
                      <Subsection>
                        <RadioGroup label="Problem Groups:" model={`selected_diagnoses.${icd10_key}.problem_group_id`} withHover={false}>
                          {diagnosis.problem_groups?.map?.((problemGroup: any) => {
                            return (
                              <Radio key={problemGroup.group_id} label={problemGroup.description} value={problemGroup.group_id}>
                                <ContextShow when={`selected_diagnoses.${icd10_key}.problem_group_id`} is={problemGroup.group_id}>
                                  <Subsection>
                                    <RadioGroup
                                      label="Problems:"
                                      model={`selected_diagnoses.${icd10_key}.problem_id`}
                                      layout="vertical-dense"
                                      withHover={false}
                                    >
                                      {problemGroup?.problems?.map?.((problem: any) => (
                                        <Radio
                                          label={problem.description}
                                          value={problem.problem_id}
                                          key={`${problem.problem_group_id}-${problem.problem_id}`}
                                        />
                                      ))}
                                    </RadioGroup>
                                  </Subsection>
                                </ContextShow>
                              </Radio>
                            )
                          })}
                        </RadioGroup>
                      </Subsection>
                    </ContextShow>
                  </Checkbox>
                )
              })}
            </CheckboxGroup>

            <div>
              <SummonOverlay overlay={<AddDiagnosesOverlay onSave={addDiagnosesCodes} />}>
                <Button label="Search by Other Diagnosis Codes…" glyph="search" type="default" display="inline-flex" />
              </SummonOverlay>
            </div>
          </FormSection>
        </Form>
      </Main>

      <Footer>
        <Button label="Continue →" type="primary" onClick={goNext} isDisabled={!valid} />
      </Footer>
    </>
  )
}

const styles = {
  emptyCard: {
    marginTop: '0.5rem',
  },

  alert: {
    marginTop: '0.5rem',
  },
}

export default Diagnoses
