import React from 'react'
import { useLocation } from 'react-router-dom'

import { amount, usDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'
import Status from '../Status'

type Props = {
  location: any
  timezone: any
  to: any
  transaction: any
}

const FinancialTransaction = ({ transaction, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  let valueColor = 'red'
  let sign = '+'

  if (transaction.category === 'payment') valueColor = 'green'
  else if (transaction.category === 'credit') valueColor = 'orange'
  else sign = '-'

  return (
    <CardLink to={to} isActive={isActive} activeColor={valueColor}>
      <CardTitle title={`${sign}$${amount(transaction.amount)}`} color={valueColor} className="!text-[1.3em] font-[700]" />
      <CardMeta>
        <Status color={valueColor} label={transaction.category} />
        <Text glyph="date" description={usDate(transaction.transactioned_at, timezone)} />
      </CardMeta>
      <CardSubtitle subtitle={transaction.notes} />
    </CardLink>
  )
}

export default withSettings(FinancialTransaction)
