import React from 'react'

import { COLORS, getColor } from '../theme'
import { CARD_CSS_VARS } from './Card'
import CheckButton from './CheckButton'

type Props = {
  className?: string
  color: string
  isCompleted: boolean
  isLoading: boolean
  onClick: any
}

const TaskCheckButton = ({ isCompleted, isLoading, onClick, color, className }: Props) => {
  return <CheckButton className={className} isCompleted={isCompleted} isLoading={isLoading} onClick={onClick} css={styles(color)} />
}

const styles = (color: string) => ({
  alignSelf: 'stretch',
  width: `var(${CARD_CSS_VARS.BASELINE})`,
  background: COLORS.transparent,
  borderRight: `1px solid ${COLORS.divider}`,
  outline: 'none',

  ...(color && {
    paddingLeft: 3,
    boxShadow: `inset 4px 0 0 ${getColor(color)}`,
  }),

  '&:hover': {
    background: COLORS.hover,
  },
})

export default TaskCheckButton
