import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../../theme'
import Chart from '../Chart'

export const PaymentsChargesScatterChart = (props: any) => {
  const days: any = props.data.days
  const payments_data: any = props.data.payments_data
  const charges_data: any = props.data.charges_data
  const resident_names: any = props.data.resident_names
  const option = {
    tooltip: {
      position: 'top',
    },
    title: [],
    singleAxis: [],
    series: [],
  }

  resident_names.forEach(function (resident_name, idx) {
    option.title.push({
      textBaseline: 'middle',
      top: ((idx + 0.5) * 100) / 7 + '%',
      text: resident_name,
    })
    option.singleAxis.push({
      left: 150,
      type: 'category',
      data: days,
      boundaryGap: false,
      top: (idx * 100) / 7 + 5 + '%',
      height: 100 / 7 - 10 + '%',
    })
    option.series.push({
      singleAxisIndex: idx,
      coordinateSystem: 'singleAxis',
      type: 'scatter',
      data: [],
      color: '#c40000',
      symbolSize: function (dataItem) {
        return dataItem[1]
      },
    })
    option.series.push({
      singleAxisIndex: idx,
      coordinateSystem: 'singleAxis',
      type: 'scatter',
      data: [],
      color: '#45ba45',
      symbolSize: function (dataItem) {
        return dataItem[1]
      },
    })
    payments_data.forEach(function (dataItem) {
      if (dataItem[0] != idx) {
        return
      }
      option.series[idx * 2].data.push([dataItem[1], dataItem[2]])
    })
    charges_data.forEach(function (dataItem) {
      if (dataItem[0] != idx) {
        return
      }
      option.series[idx * 2 + 1].data.push([dataItem[1], dataItem[2]])
    })
  })
  return <Chart option={option} {...props} />
}
