import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import { useLocation, useParams } from 'react-router-dom'
import clsx from 'clsx'
import compact from 'lodash/compact'
import pluralize from 'pluralize'

import { useUpdate } from '../../hooks/useNewAPI'
import { usTime } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import CardLink from '../CardLink'
import TaskCheckButton from '../TaskCheckButton'
import CardTitle from '../CardTitle'
import CardMeta from '../CardMeta'
import Status from '../Status'
import TaskStatus from '../Statuses/TaskStatus'
import { Text } from '../Typography'
import { COLORS } from '../../theme'

type Props = {
  link?: string
  onClick?: any
  task: any
  timezone?: string
  useV6Router?: boolean
}

const STATUS_COLORS: any = {
  done: 'green',
  due_today: 'orange',
  to_do: 'blue',
  overdue: 'red',
}

const CalendarTodoCard = ({ link, onClick, task, timezone, useV6Router }: Props) => {
  const status = task?.display_status

  const location = useLocation()
  const isActive = location.pathname?.includes?.(link)
  const isCompleted = status === 'done'

  const classNames = clsx({ 'is-active': isActive, 'is-completed': isCompleted })

  const { mutate, isLoading } = useUpdate({
    name: ['todo', task.id],
    url: `/events/${task.id}`,
    invalidate: 'todos',
  })

  const toggleTask = () => {
    mutate({ action_status: task.action_status === 'done' ? 'due' : 'done' })
  }

  const hasSubtasks = task?.subtasks_no > 0
  const subtasksMeta = `${task?.subtasks_completed_no}/${task?.subtasks_no} Completed`

  return (
    <CardLink
      as={useV6Router ? NavLink : undefined}
      css={styles.root}
      className={classNames}
      activeColor={task?.color || STATUS_COLORS[status]}
      baseline="3.5rem"
      isActive={isActive}
      to={link}
      onClick={onClick}
      before={
        <TaskCheckButton
          color={task?.color || STATUS_COLORS[status]}
          isCompleted={isCompleted}
          isLoading={isLoading}
          onClick={toggleTask}
        />
      }
    >
      <CardTitle
        css={styles.title}
        title={task?.title}
        after={
          <>
            <Status color={STATUS_COLORS[status]} label={status?.replace('_', ' ')} className="text-[0.75rem]" />
            <TaskStatus status={task?.sharing_type} className="text-[0.75rem]" />
          </>
        }
      />
      <CardMeta css={styles.meta}>
        <Text glyph="time" label="Due By: " description={usTime(task.started_at, timezone)} glyphSize={14} glyphColor={COLORS.text} />
        {hasSubtasks && <Text glyph="check" label="Tasks: " description={subtasksMeta} glyphSize={14} glyphColor={COLORS.text} />}
      </CardMeta>
    </CardLink>
  )
}

const styles = {
  root: {
    borderRadius: 4,
  },

  title: {
    fontSize: '1rem',
  },

  meta: {
    fontSize: '0.9rem',
    lineHeight: '0.9rem',
    paddingBottom: '0.1rem',
  },
}

export default withSettings(CalendarTodoCard)
