import { request } from 'modules/axios'
import React from 'react'

import Alert from './Alert'

type Props = {
  glyph?: string
  type?: string
}

const MyIPAddress: React.FC<Props> = ({ glyph = 'info', type = 'default' }) => {
  const [address, setAddress] = React.useState(null)

  React.useEffect(() => {
    const findIPAddress = async () => {
      try {
        const response = await fetch(`${process.env.BH_API_BASE_PROTOCOL}://api.ipify.org?format=json`)
        const body = await response.json()

        console.log(body.ip)

        setAddress(body.ip)
      } catch (error) {
        console.error(error)
      }
    }

    if (!address) findIPAddress()
  }, [])

  return (
    <Alert glyph={glyph} type={type}>
      Your Current IP address is: <b>{address}</b>
    </Alert>
  )
}

export default MyIPAddress
