import React from 'react'
import size from 'lodash/size'

import { mapToArray } from '../../../utils/functions'

import Card from '../../../components/Card'
import State from '../../../components/State'

import { Element } from '../elements/Element'
import { FORM_CONTENT_WIDTHS } from '../utils/constants'
import { useFormBuilder } from '../useFormBuilder'

export const FormRenderer = React.forwardRef((props: any, ref: any) => {
  const { before, after, showAllPages, className, pageStyles, renderElementWrapper, getScrollParentRef } = props

  const activePageId: any = useFormBuilder((state: any) => state.activePageId)
  const activePage: any = useFormBuilder((state: any) => state.pages?.[activePageId])
  const pages: any = useFormBuilder((state: any) => state.pages)
  const allElements: any = useFormBuilder((state: any) => state.allElements)
  const environment: any = useFormBuilder((state: any) => state.environment)

  const isEmpty = size(activePage?.elements_order) === 0
  const pagesArray = mapToArray(pages)

  if (!activePage) return null

  if (showAllPages) {
    return (
      <div ref={ref} className={className} css={STYLES.root}>
        {pagesArray.map((page: any, index: number) => (
          <div
            key={page.uuid}
            css={STYLES.page}
            style={{
              width: '100%',
              maxWidth: FORM_CONTENT_WIDTHS?.[page?.config?.content_width] || '520px',
              ...pageStyles,
              '--field-max-width': '100% !important',
            }}
          >
            {index === 0 && before}

            <div css={STYLES.formContent}>
              {page.elements_order?.map?.((elementId: string) => {
                const elementRender = <Element key={elementId} elementId={elementId} getScrollParentRef={getScrollParentRef} />

                if (renderElementWrapper) return renderElementWrapper(elementRender, allElements?.[elementId])

                return elementRender
              })}
            </div>

            {index === pagesArray.length - 1 && after}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div
      ref={ref}
      className={className}
      css={STYLES.root}
      style={{
        width: '100%',
        maxWidth: FORM_CONTENT_WIDTHS?.[activePage?.config?.content_width] || '520px',
        '--field-max-width': '100% !important',
        ...pageStyles,
      }}
    >
      {before}

      {isEmpty && (
        <Card>
          <State isEmpty icon="web_form" title="Empty Page" emptyDescription="This page has no content" />
        </Card>
      )}

      <div css={STYLES.formContent}>
        {!isEmpty &&
          activePage?.elements_order?.map?.((elementId: string) => {
            const elementRender = <Element key={elementId} elementId={elementId} getScrollParentRef={getScrollParentRef} />

            if (renderElementWrapper) return renderElementWrapper(elementRender, allElements?.[elementId])

            return elementRender
          })}
      </div>

      {after}
    </div>
  )
})

const STYLES = {
  root: {
    width: '100%',
    maxWidth: '100%',
    '--field-max-width': '100% !important',
  },

  page: {
    width: '100%',
    maxWidth: '100%',
    '--field-max-width': '100% !important',
  },

  formContent: {
    display: 'grid',
    gridGap: '1rem',
  },
}
