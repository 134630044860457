import React from 'react'
import isBoolean from 'lodash/isBoolean'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

export const BooleanCell: React.FC = (props: any) => {
  const { value, trueLabel = 'Yes', falseLabel = 'No' } = props

  return <DataTableCell {...props}>{isBoolean(value) ? <>{value ? trueLabel : falseLabel}</> : <EmptyCell />}</DataTableCell>
}
