import React from 'react'

import { request } from '../modules/axios'

import AccordionAlert from './AccordionAlert'
import Flex from './Flex'
import Loader from './Loader'
import { NotionRenderer } from './Notion/NotionRenderer'

export const NotionNotice: React.FC<any> = ({ notionID, type = 'neutral', header, isOpen, className }: any) => {
  const [notionData, setNotionData] = React.useState(null)
  const [notionLoading, setNotionLoading] = React.useState(false)

  React.useEffect(() => {
    const getNotionData = async () => {
      setNotionLoading(true)

      try {
        const response = await request.get(`${process.env.BH_NOTION_API_BASE}/api/page/${notionID}`, {
          headers: null,
        })
        setNotionData(response.data)
      } catch (err) {
        console.error(err)
      } finally {
        setNotionLoading(false)
      }
    }

    getNotionData()
  }, [])

  return (
    <AccordionAlert contrast isOpen={isOpen} type={type} header={header} className={className}>
      {notionLoading ? (
        <Flex centerX>
          <Loader />
        </Flex>
      ) : (
        notionData && (
          <NotionRenderer css={styles} disableHeader fullPage={false} hideBlockId={true} darkMode={false} recordMap={notionData} />
        )
      )}
    </AccordionAlert>
  )
}

const styles = {
  width: '100% !important',
  minHeight: 'auto !important',
  background: 'none',
}
