import React from 'react'

import { address } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useCreate } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'

import { useAccountingStore } from './store'
import { Alert } from '@behavehealth/components'

const RootAccountingCustomerOverlay = (props: any) => {
  const {
    cancel,
    close,
    edit,
    form,
    initialModel,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    id,
    data,
  } = useOverlay({
    name: 'accounting-customer',
    endpoint: '/accounting/customers',
    // invalidate: ['accounting', 'status', { category: 'customers' }],
    invalidate: ['accounting-customers'],
    options: props,
  })

  const addCategoryStatusCheck = useAccountingStore((state: any) => state.addCategoryStatusCheck)

  const syncMutation = useCreate({
    name: ['sync-accounting-customer'],
    url: `/accounting/customers/${id}/sync`,
    invalidate: ['accounting', 'status', { category: 'customers' }],
    invalidateKeys: ['accounting-customers'],
    onSuccess: () => {
      addCategoryStatusCheck('customers')
    },
  })

  const [referenceType, setReferenceType]: any = React.useState('resident')

  const [client, setClient]: any = React.useState(null)
  const [clientContact, setClientContact]: any = React.useState(null)

  const [organization, setOrganization]: any = React.useState(null)
  const [organizationContact, setOrganizationContact]: any = React.useState(null)

  const [selectedReference, setSelectedReference]: any = React.useState(null)

  const sync = () => {
    syncMutation.mutateAsync({})
  }

  const save = () => {
    let reference_id = ''
    let reference_type = ''

    if (referenceType === 'resident') {
      reference_id = client?.id
      reference_type = 'resident'
    } else if (referenceType === 'resident_contact') {
      reference_id = clientContact?.id
      reference_type = 'contact'
    } else if (referenceType === 'organization') {
      reference_id = organization?.id
      reference_type = 'organization'
    } else if (referenceType === 'organization_contact') {
      reference_id = organizationContact?.id
      reference_type = 'contact'
    }

    saveWithData({ reference_id, reference_type })
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const reference = initialModel?.reference

  const syncEnabled = selectedReference?.sync_with_accounting === true

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="accounting" title="Customer" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            reference_type: reference?.type || 'resident',

            ...(reference?.type === 'resident' && {
              resident: reference,
              resident_id: reference.id,
            }),

            ...(reference?.type === 'organization' && {
              organization: reference,
              organization_id: reference.id,
            }),

            ...(reference?.type === 'contact' &&
              reference?.reference?.type === 'resident' && {
                resident: reference.reference,
                resident_id: reference.reference.id,
                resident_contact: reference,
                resident_contact_id: reference.id,
                reference_type: 'resident_contact',
              }),

            ...(reference?.type === 'contact' &&
              reference?.reference?.type === 'organization' && {
                organization: reference.reference,
                organization_id: reference.reference.id,
                organization_contact: reference,
                organization_contact_id: reference.id,
                reference_type: 'organization_contact',
              }),
          }}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection>
              <Select
                label="Associated Record Type"
                model="reference_type"
                defaultValue="resident"
                className="!flex-1"
                onUpdate={({ value }) => {
                  setReferenceType(value)
                }}
              >
                <Option label="Client" value="resident" />
                <Option label="Client Contact" value="resident_contact" />
                <Option label="Organization" value="local_organization" />
                <Option label="Organization Contact" value="organization_contact" />
              </Select>

              {(referenceType === 'resident' || referenceType === 'resident_contact') && (
                <ObjectSelector
                  icon={ICONS.clients}
                  label="Client"
                  type="clients"
                  model="resident"
                  onUpdate={({ object }) => {
                    setClientContact(null)
                    setClient(object)
                    setSelectedReference(object)
                  }}
                  selectTitle={(data: any) => data.name}
                  validations={{
                    presence: {
                      message: 'Please select a client',
                    },
                  }}
                />
              )}

              {(referenceType === 'local_organization' || referenceType === 'organization_contact') && (
                <ObjectSelector
                  icon={ICONS.organizations}
                  label="Organization"
                  type="local_organizations"
                  model="local_organization"
                  onUpdate={({ object }) => {
                    setOrganizationContact(null)
                    setOrganization(object)
                    setSelectedReference(object)
                  }}
                  selectTitle={(data: any) => data.name}
                  selectDescription={(data: any) => address(data.address)}
                  validations={{
                    presence: {
                      message: 'Please select a organization',
                    },
                  }}
                />
              )}

              {referenceType === 'resident_contact' && (
                <ObjectSelector
                  key={client?.id}
                  icon={ICONS.contacts}
                  label="Client's Contact"
                  type="client.contacts"
                  model="resident_contact"
                  onUpdate={({ object }) => {
                    setClientContact(object)
                    setSelectedReference(object)
                  }}
                  dependentValue={client?.id}
                  selectTitle={(data: any) => data.name}
                  validations={{
                    presence: {
                      message: 'Please select a contact',
                    },
                  }}
                />
              )}

              {referenceType === 'organization_contact' && (
                <ObjectSelector
                  key={organization?.id}
                  icon={ICONS.contacts}
                  label="Contact"
                  type="organization.contacts"
                  model="organization_contact"
                  onUpdate={({ object }) => {
                    setOrganizationContact(object)
                    setSelectedReference(object)
                  }}
                  dependentValue={organization?.id}
                  selectTitle={(data: any) => data.name}
                  validations={{
                    presence: {
                      message: 'Please select a contact',
                    },
                  }}
                />
              )}

              {!isEditable && (
                <>
                  {syncEnabled && selectedReference ? (
                    <Button
                      glyph="reset"
                      label="Sync"
                      type="default"
                      isDisabled={isLoading}
                      onClick={sync}
                      isLoading={syncMutation.isLoading}
                      flex="100 1 auto"
                    />
                  ) : (
                    <>
                      {selectedReference ? (
                        <Alert type="warning" children={`Sync is disabled for ${selectedReference.name}`} />
                      ) : (
                        <Alert type="warning" children={`Please select an associated record to enable sync `} />
                      )}
                    </>
                  )}
                </>
              )}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit Customer" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const AccountingCustomerOverlay = withOverlayError(RootAccountingCustomerOverlay)
