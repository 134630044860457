import React from 'react'

import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Status from '../../components/Status'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const LabTestsReportDataTable = (props: any) => {
  const { to } = props

  const { timezone, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Date & Time',
        id: 'recorded_at',
        model: 'data.recorded_at',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.data.id} value={usDateTime(value, timezone)} to={to?.(data.data)} />,
      },
      {
        width: 180,
        id: 'name',
        model: 'data.name',
        title: 'Name',
      },
      {
        width: 180,
        id: 'notes',
        model: 'data.notes',
        title: 'Notes',
      },
      {
        width: 180,
        id: 'client',
        model: 'data.client',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 180,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ data, value }) => {
          if (value === 'refused') return <Status color="red" label="Not Collected" />
          if (value === 'collected') return <Status color="blue" label="Collected" />

          // NOTE @Andrei: Implement NotApplicable component
          return 'N/A'
        },
      },
      {
        width: 160,
        id: 'result',
        model: 'data.result',
        title: 'Result',
        formatValue: ({ data, value }) => {
          if (data.data.status === 'refused') return 'N/A'
          if (value === 'positive') return <Status color="red" label="Positive" />
          if (value === 'negative') return <Status color="green" label="Negative" />
          if (value === 'not_available') return <Status color="grey" label="N/A" />

          // NOTE @Andrei: Implement NotApplicable component
          return 'N/A'
        },
      },
      {
        width: 180,
        id: 'author',
        model: 'data.author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="Lab Tests"
      icon="test_results"
      columns={columns}
      filtersConfig={FILTERS.lab_tests}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="labs"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=labs'}
        />
      }
    />
  )
}
