import React from 'react'
import { tint } from 'polished'
import { Link, NavLink, Navigate, Route, Routes, useParams, useLocation } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { COLORS } from '@behavehealth/theme'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'

import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import Divider from '@behavehealth/components/Divider'
import Flex from '@behavehealth/components/Flex'
import Glyph from '@behavehealth/components/Glyph'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import PageSectionHeader from '@behavehealth/components/PageSection/PageSectionHeader'
import PageSectionTitle from '@behavehealth/components/PageSection/PageSectionTitle'
import State from '@behavehealth/components/State'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Type from '@behavehealth/components/Typography/Type'

import { AIActionsReorderOverlay } from '@behavehealth/constructs/AIActions/AIActionsReorderOverlay'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { CustomAIActionOverlay } from '@behavehealth/constructs/AIActions/CustomAIActionOverlay'

export const AIActions = () => {
  const { url } = useRouteURL()

  return (
    <>
      <AIActionsIndex />

      <AnimatedRoutes>
        <Route path="actions/:id" element={<CustomAIActionOverlay useV6Router back={url} />} />
      </AnimatedRoutes>
    </>
  )
}

const AIActionsIndex = () => {
  const { data, isLoading } = useGet({
    name: ['custom-ai-actions'],
    url: '/custom_ai_actions',
  })

  const { mutateAsync: reorderActionsAsync }: any = useUpdate({
    name: ['reorder-ai-actions'],
    url: `/ai_actions/reorder`,
    invalidate: 'custom-ai-actions',
  })

  const { mutateAsync: reorderCustomActionsAsync }: any = useUpdate({
    name: ['reorder-custom-ai-actions'],
    url: `/custom_ai_actions/reorder`,
    invalidate: 'custom-ai-actions',
  })

  const { aiActions, aiCustomActions, actionGroups, remainingActions, customActionGroups, remainingCustomActions } = React.useMemo(() => {
    let aiActions = data?.ai_actions
    let aiCustomActions = data?.custom_ai_actions

    let actionGroups: any = {}
    let remainingActions: any = []

    let customActionGroups: any = {}
    let remainingCustomActions: any = []

    // group AI actions
    if (size(aiActions) > 0) {
      for (const action of aiActions) {
        if (!action.nav_group) {
          remainingActions.push(action)
          continue
        }

        // if there is nothing in the nav_group, initialize it as an empty array
        if (size(actionGroups[action.nav_group]) === 0) actionGroups[action.nav_group] = []

        // push the element into the nav_group array
        actionGroups[action.nav_group].push(action)
      }
    }

    // group custom AI actions
    if (size(aiCustomActions) > 0) {
      for (const action of aiCustomActions) {
        if (!action.nav_group) {
          remainingCustomActions.push(action)
          continue
        }

        // if there is nothing in the nav_group, initialize it as an empty array
        if (size(customActionGroups[action.nav_group]) === 0) customActionGroups[action.nav_group] = []

        // push the element into the nav_group array
        customActionGroups[action.nav_group].push(action)
      }
    }

    return {
      aiActions,
      aiCustomActions,
      actionGroups,
      remainingActions,
      customActionGroups,
      remainingCustomActions,
    }
  }, [data])

  const aiActionsEmpty = size(aiActions) === 0
  const aiCustomActionsEmpty = size(aiCustomActions) === 0

  const reorderActions = async (items: any) => {
    await reorderActionsAsync({ reorder: items })
  }

  const reorderCustomActions = async (items: any) => {
    await reorderCustomActionsAsync({ reorder: items })
  }

  if (isLoading || !data) return <PageLoader />

  return (
    <Page
      title="AI Actions"
      icon="behave_ai"
      actions={
        <Flex gap="0.75rem">
          <Button as={Link} label="Add Custom Action" type="primary" glyph="add" link="actions/new" />
        </Flex>
      }
    >
      <Grid gap="1.25rem">
        {!aiActionsEmpty && (
          <div>
            <PageSectionHeader
              after={
                <SummonOverlay overlay={<AIActionsReorderOverlay data={aiActions} onSave={reorderActions} />}>
                  <Button label="Reorder" glyph="drag_and_drop" size={200} />
                </SummonOverlay>
              }
            >
              <PageSectionTitle title="System Actions" />
            </PageSectionHeader>

            <Card className="mt-2">
              <AIActionsList groups={actionGroups} remaining={remainingActions} />
            </Card>
          </div>
        )}

        <div>
          <PageSectionHeader
            after={
              <SummonOverlay overlay={<AIActionsReorderOverlay data={aiCustomActions} onSave={reorderCustomActions} />}>
                <Button label="Reorder" glyph="drag_and_drop" size={200} />
              </SummonOverlay>
            }
          >
            <PageSectionTitle title="Custom Actions" />
          </PageSectionHeader>

          <Card className="mt-2">
            {aiCustomActionsEmpty ? (
              <State key="empty" title="AI Actions" icon="behave_ai" emptyDescription="No custom AI Actions added yet" />
            ) : (
              <AIActionsList groups={customActionGroups} remaining={remainingCustomActions} />
            )}
          </Card>
        </div>
      </Grid>
    </Page>
  )
}

const AIActionsList = ({ groups, remaining }: any) => {
  return (
    <>
      {groups &&
        Object.entries(groups).map(([label, actions]: any) => {
          return (
            <>
              <div key={label} className="mb-2">
                <Type as="h3" variant="CAPS" className="pl-4 mt-2">
                  {label}
                </Type>

                {actions.map((action: any) => (
                  <AIAction key={action.id} data={action} />
                ))}
              </div>

              {remaining && <Divider className="!m-0" />}
            </>
          )
        })}

      {remaining && (
        <div className="mt-2">
          {remaining.map((action: any) => (
            <AIAction key={action.id} data={action} />
          ))}
        </div>
      )}
    </>
  )
}

const AIAction = ({ data }: any) => {
  if (!data) return null

  return (
    <NavLink css={STYLES.action} to={`actions/${data.id}`}>
      <div css={STYLES.actionGraphic}>
        <Glyph glyph={data.glyph} color={data.color} size={16} />
      </div>
      <div>{data.name}</div>
    </NavLink>
  )
}

const STYLES = {
  action: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    paddingLeft: 20,
    fontWeight: 500,

    '&:hover': {
      background: tint(0.95, COLORS.vividBlue),
    },

    '&.active': {
      color: COLORS.text,
      background: tint(0.9, COLORS.vividBlue),
    },
  },

  actionGraphic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '0.5rem',
  },
}
