import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5-compat'

import { Button, Card, Grid } from '@behavehealth/components'
import { ProgramsTable } from '@behavehealth/components/Tables'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useServerFunctionality } from '@behavehealth/hooks/useServerFunctionality'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { ExportCSVButton } from '@behavehealth/components/Buttons/ExportCSVButton'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

const RootProgramsTablePage: React.FC = () => {
  const { server, sets, tableProps } = useServerFunctionality()

  const apiKey = ['programs', server.keys]

  const { data, meta, isLoading, isFetching }: any = useGet({
    name: apiKey,
    url: `/programs`,
    params: server.params,
    options: server.options,
  })

  const actions = <Button as={Link} label="Add Program" type="primary" glyph="add" link="new" permission="programs.create" />

  return (
    <div css={STYLES.root}>
      <Grid gap="1rem" columns="100%">
        <Filters config={FILTERS.programs} onUpdate={sets.filters} localStorageKey="programs" />

        <Card>
          <ProgramsTable
            {...tableProps(meta)}
            mainLinkAs={NavLink}
            titleAfter={<ExportCSVButton url={`/programs/csv`} />}
            testKey="programs_table"
            useAutoPageSize
            data={data}
            apiKey={apiKey}
            isLoading={isLoading}
            isFetching={!isLoading && isFetching}
            emptyActions={actions}
            to={(id) => `/programs/${id}`}
            localStorageKey="programs"
          />
        </Card>
      </Grid>
    </div>
  )
}

const STYLES = {
  root: {
    padding: '1rem',
  },
}

export const ProgramsTablePage = withPageError(RootProgramsTablePage)
