import React from 'react'

import { FEATURES } from '../../theme'
import withSettings from '../../hocs/withSettings'

import Glyph from '../Glyph'
import ReportStatus from '../Statuses/ReportStatus'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, mainLinkAs: any, timezone: string) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink as={mainLinkAs} label={value} to={to(row.original.id)} />,
  },
  {
    width: 140,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <ReportStatus status={value} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'generated',
        label: 'Generated',
      },
      {
        value: 'processing',
        label: 'Processing',
      },
      {
        value: 'issue',
        label: 'Issue',
      },
    ],
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Requested At',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 160,
    accessor: 'starting_at',
    Header: 'Start Date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 160,
    accessor: 'ending_at',
    Header: 'End Date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Requested By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
  {
    width: 160,
    accessor: 'attachment',
    disableFilters: true,
    canToggleVisible: false,
    Header: 'Download',
    Cell: ({ value }: any) => {
      if (!value) return 'Generating…'
      return <TableCell.Link href={value} label="Download" glyph={<Glyph glyph="download" />} />
    },
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  isLoading: boolean
  localStorageKey: string
  timezone: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const ReportsTable = ({
  to,
  data,
  isLoading,
  emptyActions,
  batchActionsConfig,
  localStorageKey,
  timezone,
  titleAfter,
  mainLinkAs,
}: Props) => {
  return (
    <Table
      title="Reports"
      titleAfter={titleAfter}
      icon={FEATURES.live_reports.icon}
      data={data}
      columns={columns(to, mainLinkAs, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No reports created yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

ReportsTable.defaultProps = {
  localStorageKey: 'reports',
}

export default withSettings(ReportsTable)
