import React from 'react'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { apiUpdate } from '@behavehealth/modules/api'
import { isDefined } from '@behavehealth/utils/functions'
import { PlainSwitch } from '@behavehealth/components/Forms/Switch'
import { useFormField } from '@behavehealth/components/Forms/hooks/useFormField'
import { usePermissions } from '@behavehealth/hooks/usePermissions'
import { withFormContext } from '@behavehealth/components/Forms/context'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useStore from '@behavehealth/modules/store'

import Accordion from '@behavehealth/components/Accordion'
import Form from '@behavehealth/components/Forms/Form'
import Glyph from '@behavehealth/components/Glyph'
import MiniRichTextEditor from '@behavehealth/components/Forms/MiniRichTextEditor'
import Page from '@behavehealth/components/Page'
import TreeItem from '@behavehealth/components/TreeItem'

import { ACCEPT_STEPS } from '@behavehealth/constructs/ClientWorkflows/AcceptForAdmission'
import { ADMIT_STEPS } from '@behavehealth/constructs/ClientWorkflows/IntakeAdmit'
import { DISCHARGE_STEPS } from '@behavehealth/constructs/ClientWorkflows/IntakeDischarge'
import { READMIT_STEPS } from '@behavehealth/constructs/ClientWorkflows/IntakeReadmit'

const animationProps = {
  type: 'spring',
  duration: 0.35,
  bounce: 0,
  // stiffness: 1500,
  // damping: 300,
}

const ClientWorkflows = () => {
  const record: any = useSelector((state: any) => state.me?.tenant)

  const updateStore = useStore((state) => state.update)

  const handleSubmit = async (data) => {
    const response = await apiUpdate({
      name: 'tenant',
      url: '/me/tenant',
      params: {
        client_workflow_options: {
          ...record.client_workflow_options,
          ...data.client_workflow_options,
        },
      },
      reducer: 'me',
    })

    if (response?.data?.data) {
      updateStore({ tenant: response?.data?.data })
    }
  }

  return (
    <Page breakpoint={3} title="Client Workflow Settings" feature="client_workflows">
      <Accordion
        size={200}
        isOpen
        activateEditMode
        editPermission="settings.client_workflows.edit"
        initialModel={{ client_workflow_options: record?.client_workflow_options }}
        glyph="tick_circle"
        title="Accept Applicant Workflow"
        onSubmit={handleSubmit}
      >
        <Form>
          {Object.keys(ACCEPT_STEPS).map((key) => {
            const stepConfig = ACCEPT_STEPS[key]

            if (stepConfig.isMandatory) return null

            return <WorkflowStep key={key} step={key} stepConfig={stepConfig} model="client_workflow_options.accept" />
          })}
        </Form>
      </Accordion>

      <Accordion
        size={200}
        isOpen
        activateEditMode
        editPermission="settings.client_workflows.edit"
        initialModel={{ client_workflow_options: record?.client_workflow_options }}
        glyph="check_in"
        title="Admit Applicant Workflow"
        onSubmit={handleSubmit}
      >
        <Form>
          {Object.keys(ADMIT_STEPS).map((key) => {
            const stepConfig = ADMIT_STEPS[key]

            if (stepConfig.isMandatory) return null

            return <WorkflowStep key={key} step={key} stepConfig={stepConfig} model="client_workflow_options.admit" />
          })}
        </Form>
      </Accordion>

      <Accordion
        size={200}
        isOpen
        activateEditMode
        editPermission="settings.client_workflows.edit"
        initialModel={{ client_workflow_options: record?.client_workflow_options }}
        glyph="discharge"
        title="Discharge Client Workflow"
        onSubmit={handleSubmit}
      >
        <Form>
          {Object.keys(DISCHARGE_STEPS).map((key) => {
            const stepConfig = DISCHARGE_STEPS[key]

            if (stepConfig.isMandatory) return null

            return <WorkflowStep key={key} step={key} stepConfig={stepConfig} model="client_workflow_options.discharge" />
          })}
        </Form>
      </Accordion>

      <Accordion
        size={200}
        isOpen
        activateEditMode
        editPermission="settings.client_workflows.edit"
        initialModel={{ client_workflow_options: record?.client_workflow_options }}
        glyph="readmit"
        title="Re-admit Past Client Workflow"
        onSubmit={handleSubmit}
      >
        <Form>
          {Object.keys(READMIT_STEPS).map((key) => {
            const stepConfig = READMIT_STEPS[key]

            if (stepConfig.isMandatory) return null

            return <WorkflowStep key={key} step={key} stepConfig={stepConfig} model="client_workflow_options.readmit" />
          })}
        </Form>
      </Accordion>
    </Page>
  )
}

const RootWorkflowStep = (props: any) => {
  const { model, form, isEditable, step, stepConfig = {} } = props

  const { label, isRequired: isAlwaysRequired, isRequiredOptional, featureFlag, featureFlagV2 } = stepConfig

  const { allowed: hasFeatureFlag } = usePermissions({ featureFlag: featureFlag || featureFlagV2 })

  const visibilityModel = `${model}.${step}`
  const requiredModel = `${model}_required_steps.${step}`
  const instructionsModel = `${model}_instructions.${step}`

  const [visibility, setVisibility] = React.useState(true)
  const [required, setRequired] = React.useState(!!isAlwaysRequired)
  const [instructions, setInstructions] = React.useState('')

  const { formActions: visibilityActions } = useFormField({
    model: visibilityModel,
    form,
    afterReset: () => {
      let initialVisibility = form?.getInitialInputFieldValue(visibilityModel)

      setVisibility(!!initialVisibility)
    },
  })

  const { formActions: requiredActions } = useFormField({
    model: requiredModel,
    form,
    afterReset: () => {
      let initialRequired = form?.getInitialInputFieldValue(requiredModel)

      setRequired(!!initialRequired)
    },
  })

  const { formActions: instructionsActions } = useFormField({
    model: instructionsModel,
    form,
    afterReset: () => {
      let initialInstructions = form?.getInitialInputFieldValue(instructionsModel)

      setInstructions(initialInstructions)
    },
  })

  // ON MOUNT
  React.useEffect(() => {
    let initialVisibility = form?.getInitialInputFieldValue(visibilityModel)
    let initialRequired = form?.getInitialInputFieldValue(requiredModel)
    let initialInstructions = form?.getInitialInputFieldValue(instructionsModel)

    if (isDefined(initialVisibility)) {
      setVisibility(!!initialVisibility)
    }

    if (isDefined(initialRequired)) {
      setRequired(!!initialRequired)
    }

    if (isDefined(initialInstructions)) {
      setInstructions(initialInstructions)
    }
  }, [])

  // UPDATE FORM
  React.useEffect(() => {
    visibilityActions.setValue(visibility)
  }, [visibility])

  React.useEffect(() => {
    requiredActions.setValue(required)
  }, [required])

  React.useEffect(() => {
    instructionsActions.setValue(instructions)
  }, [instructions])

  const showInstructions = isEditable ? visibility : visibility && instructions

  if (!hasFeatureFlag) return null

  return (
    <div className="max-w-[520px] border-b border-0 border-solid border-divider">
      <div className="flex items-center py-3">
        <h4 className={clsx('text-[1.1rem] mr-auto', !visibility && 'opacity-50')}>{label}</h4>

        {isAlwaysRequired ? (
          <div className="flex items-center flex-nowrap">
            <Glyph glyph="check" size={16} />
            <div className="font-[500] text-[0.95rem] ml-1.5">Always Visible & Required</div>
          </div>
        ) : (
          <>
            {isEditable && !isRequiredOptional && (
              <div className="flex items-center flex-nowrap">
                <Glyph glyph="info" size={16} />
                <div className="font-[400] italic text-[0.95rem] ml-1.5">Required Option Coming Soon</div>
              </div>
            )}

            {isRequiredOptional && (
              <div className={clsx('flex items-center flex-nowrap', !visibility && 'hidden')}>
                {!isEditable && <Glyph glyph={required ? 'check' : 'cross'} size={16} />}

                {isEditable && (
                  <PlainSwitch
                    size={100}
                    isChecked={required && visibility}
                    onCheckedChange={(value) => {
                      setRequired(value && visibility)
                    }}
                    isDisabled={!visibility}
                  />
                )}

                <div className="font-[500] text-[0.95rem] ml-1.5">Required</div>
              </div>
            )}

            <div className="flex items-center flex-nowrap ml-4">
              {!isEditable && <Glyph glyph={visibility ? 'check' : 'cross'} size={16} />}
              {isEditable && <PlainSwitch size={100} isChecked={visibility} onCheckedChange={setVisibility} />}
              <div className="font-[500] text-[0.95rem] ml-1.5">Visible</div>
            </div>
          </>
        )}
      </div>

      {(isEditable || showInstructions) && (
        <motion.div
          initial={false}
          className={clsx(!showInstructions && 'overflow-hidden')}
          animate={isEditable && { height: showInstructions ? 'auto' : 0, opacity: showInstructions ? 1 : 0 }}
          transition={{
            height: animationProps,
            opacity: animationProps,
          }}
        >
          <TreeItem
            title={<span className="text-[0.95rem] text-text-muted">Instructions</span>}
            withHover={false}
            headerClassName="!pt-0 !pb-3"
          >
            <div className={clsx(isEditable ? 'mb-4' : 'mb-2')}>
              <MiniRichTextEditor
                isCompact
                withHover={false}
                actions={EDITOR_ACTIONS}
                value={instructions}
                onUpdate={({ value }: any) => {
                  setInstructions(value)
                }}
              />
            </div>
          </TreeItem>
        </motion.div>
      )}
    </div>
  )
}

const EDITOR_ACTIONS = ['inline', 'color']

const WorkflowStep = withFormContext(RootWorkflowStep)

export default withPageError(ClientWorkflows)
