import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { FormSubmissionOverlayRoutes } from '@behavehealth/constructs/FormSubmissions/FormSubmissionOverlayRoutes'
import { FormSubmissionPageActionsV6 } from '@behavehealth/constructs/FormSubmissions/FormSubmissionPageActionsV6'
import { FormSubmissionsDataTable } from '@behavehealth/constructs/FormSubmissions/FormSubmissionsDataTable'

const FormSubmissions = () => {
  return (
    <>
      <FormSubmissionsIndex />
      <FormSubmissionOverlayRoutes />
    </>
  )
}

const FormSubmissionsIndex = () => {
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['location', resource_id, 'form_submissions'],
    endpoint: `/houses/${resource_id}/form_submissions`,
    updateDeleteEndpoint: `/form_submissions`,
    localStorageKey: 'form_submissions_v2',
  })

  const { data: property }: any = useGet({
    name: ['location', resource_id],
    url: `/houses/${resource_id}`,
  })

  return (
    <Page
      title="Form Inbox"
      icon="web_form"
      actions={
        <>
          <FormSubmissionPageActionsV6
            allowedReference="locations"
            data={{
              reference: property,
              reference_id: property?.id,
              reference_type: 'house',
            }}
          />
        </>
      }
    >
      <Grid>
        <FormSubmissionsDataTable
          asCard
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'form_submissions.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

export default FormSubmissions
