import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../../theme'
import { isDefined, niceAmount } from '../../../utils/functions'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

export const AmountCell: React.FC = (props: any) => {
  const { value, column } = props

  const valueColor = column?.config?.valueColor
  const sign = column?.config?.sign
  const prefix = column?.config?.prefix
  const negativeColor = column?.config?.negativeColor
  const positiveColor = column?.config?.positiveColor

  let finalSign = sign || prefix || ''
  let color = COLORS[valueColor]

  const hasNoValue = !value || parseFloat(value) === 0

  if (hasNoValue) {
    finalSign = ''
    color = COLORS.textMuted
  } else if (!finalSign && value > 0) {
    finalSign = '+'
    color = COLORS[positiveColor]
  } else if (!finalSign && value < 0) {
    finalSign = '-'
    color = COLORS[negativeColor]
  }

  const rootClasses = clsx(!hasNoValue && 'has-value', props.className)

  const style = React.useMemo(() => {
    return { '--amount-color': color || COLORS.text }
  }, [color])

  return (
    <DataTableCell {...props} hoverExpand className={rootClasses} css={STYLES.root} style={style} align="right">
      {isDefined(value) ? (
        <>
          {finalSign}
          {niceAmount(value)}
        </>
      ) : (
        <EmptyCell defaultEmptyValue={niceAmount(0)} />
      )}
    </DataTableCell>
  )
}

const STYLES = {
  root: {
    textAlign: 'right',
    color: 'var(--amount-color)',
    justifyContent: 'flex-end',

    '&.has-value': {
      fontWeight: 600,
    },
  },
}
