import React from 'react'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import Icon from '../../../components/Icon'

export const ReferralCard = (props: any) => {
  const { title, icon, children, contentPadding } = props

  return (
    <Card baseline="2.75rem">
      <CardHeader graphic={<Icon icon={icon} size={18} />}>
        <CardTitle title={title} className="!font-[700]" />
      </CardHeader>

      <CardContent padding="0" className="!h-full">
        {children}
      </CardContent>
    </Card>
  )
}
