import React from 'react'
import { v4 as uuid } from 'uuid'
import debounce from 'lodash/debounce'
import isEqual from 'react-fast-compare'
import produce from 'immer'

import Field from './Field'
import Checkbox from './Checkbox'
import { withFormContext, CheckboxGroupContext } from './context'

export class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      type: 'CHECKBOX_GROUP',
      id: `checkbox-group-${uuid()}`,
      isPristine: true,
      isDirty: false,
      isValidations: props.validations,
      isRequired: props.validations?.hasOwnProperty('presence'),
      isValid: props.validations ? false : true,
      isInvalid: props.validations ? true : false,
      errors: [],
      inputs: {},
      selectToggle: props.defaultToggle || false,
      validate: this.onValidate,
      isRegistered: false,
    }

    this.mounted = true
    this.inputs = {}
    this.debounceRegistrationFinish = debounce(this.registrationFinished, 100)

    this.updateType = 'DATA'
  }

  /*
    LIFECYCLE
  */
  componentDidMount = () => {
    // do not register right away, but rather registered through the registrationFinished func
    if (this.props.onUpdate) this.props.onUpdate(this.state)
    if (this.afterMount) this.afterMount(this.state)
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      nextProps.isEditable !== this.props.isEditable ||
      nextProps.isDisabled !== this.props.isDisabled ||
      nextProps.layout !== this.props.layout ||
      nextProps.maxWidth !== this.props.maxWidth
    ) {
      this.updateType = 'STATE'
      return true
    }

    if (
      nextProps.label !== this.props.label ||
      nextProps.description !== this.props.description ||
      !isEqual(nextProps.validations, this.props.validations)
    ) {
      this.updateType = 'PROPS'
      return true
    }

    // TODO (Robert): to check
    if (!isEqual(nextProps.children, this.props.children)) {
      this.updateType = 'EXTERNAL'
      return true
    }

    if (!isEqual(this.state, nextState)) {
      this.updateType = 'DATA'
      return true
    }

    return false
  }

  componentDidUpdate = () => {
    if (this.updateType === 'STATE') return
    else if (this.updateType === 'PROPS') {
      this.setState({
        isValidations: this.props.validations,
        isRequired: this.props.validations?.hasOwnProperty('presence'),
        isPristine: true,
        isDirty: false,
        isValid: this.props.validations ? false : true,
        isInvalid: this.props.validations ? true : false,
      })
    } else if (this.updateType === 'DATA') this.onUpdate()
  }

  /*
    CUSTOM
  */
  registerCheckbox = (id, callback, checked) => {
    if (!id) return

    this.inputs = produce(this.inputs, (draft) => {
      draft[id] = { callback: callback, value: checked }
    })

    if (this.debounceRegistrationFinish) this.debounceRegistrationFinish()
  }

  registrationFinished = () => {
    let errors = []
    const vs = this.props.validations

    if (vs) {
      let hasAtLeastOneCheckedValue = false

      for (const id in this.inputs) {
        if (this.inputs[id].value) {
          hasAtLeastOneCheckedValue = true
          break
        }
      }

      if (!hasAtLeastOneCheckedValue) {
        errors = ['Please select at least one item']
      }
    }

    this.setState({
      inputs: this.inputs,
      isRegistered: true,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    })
  }

  updateCheckbox = (state) => {
    if (!this.mounted) return
    if (!this.inputs) return
    if (!this.inputs.hasOwnProperty(state.id)) return

    this.inputs = produce(this.inputs, (draft) => {
      draft[state.id].value = state?.isChecked
    })

    if (this.state.isRegistered) {
      this.onValidate()
    }
  }

  onValidate = () => {
    let errors = []
    const vs = this.props.validations
    if (vs) {
      let hasAtLeastOneCheckedValue = false

      for (const id in this.inputs) {
        if (this.inputs[id].value === true) {
          hasAtLeastOneCheckedValue = true
          break
        }
      }

      if (!hasAtLeastOneCheckedValue) {
        errors = [vs?.presence?.message || 'Please select at least one item']
      }
    }

    this.setState({
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      errors,
    })
  }

  onUpdate = () => {
    if (this.onUpdated) this.onUpdated(this.state)
  }

  /*
    RENDER
  */
  render() {
    const { children, withToggle, ...other } = this.props
    const { id, type, isValid, isRequired, errors, inputs, selectToggle } = this.state

    return (
      <CheckboxGroupContext.Provider
        value={{
          trueIcon: this.props.trueIcon,
          falseIcon: this.props.falseIcon,
          falseStyle: this.props.falseStyle,
          isEditable: this.props.isEditable,
          registerCheckbox: this.registerCheckbox,
          onUpdate: this.updateCheckbox,
        }}
      >
        <Field
          {...other}
          id={id}
          errors={errors}
          isValid={isValid}
          isRequired={isRequired}
          valueType={type}
          labelClassName={this.props.labelClassName}
          isCompact={this.props.isCompact}
          labelAfter={
            withToggle && (
              <Checkbox
                variant="skinny"
                label={selectToggle ? this.props.toggleOn : this.props.toggleOff}
                css={{ whiteSpace: 'nowrap', border: 'none', boxShadow: 'none !important', background: 'none', marginLeft: 'auto' }}
                value={selectToggle}
                onUpdate={(state: any) => {
                  for (let key in inputs) {
                    if (inputs[key].callback) inputs[key].callback(state.value)
                  }

                  this.setState({ selectToggle: state.value })
                }}
              />
            )
          }
        >
          {children}
        </Field>
      </CheckboxGroupContext.Provider>
    )
  }
}

CheckboxGroup.defaultProps = {
  layout: 'vertical',
  isEditable: true,
  withToggle: false,
  toggleOn: 'Select None',
  toggleOff: 'Select All',
}

export default withFormContext(CheckboxGroup)
