import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { initials } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import HelpTagIframe from '@behavehealth/components/Help/HelpTagIframe'

import { DailyNotesDataTable } from '@behavehealth/constructs/DailyNotes/DailyNotesDataTable'
import { DailyNotesDropdown } from '@behavehealth/constructs/DailyNotes/DailyNotesDropdown'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

type Props = {
  canCreate: boolean
}

const AllDailyNotes: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const tableProps = useDataTable({
    name: ['client', resource_id, 'daily_notes'],
    endpoint: `/residents/${resource_id}/daily_notes`,
    updateDeleteEndpoint: `/daily_notes`,
    // localStorageKey: 'client_daily_notes_v1',
  })

  const { deleteRecords } = tableProps

  const to = React.useMemo(
    () => (id: string, category: string) => ({
      pathname: `${match.url}/${initials(category)}/${id}`,
      parent: match,
    }),
    [match.url],
  )

  const duplicateFormLink = React.useMemo(
    () => (record: any) => {
      let routePrefix = ''

      if (record.category === 'individual') routePrefix = 'i'
      else if (record.category === 'group_note') routePrefix = 'gn'

      return {
        pathname: `${match.url}/${routePrefix}/new`,
        parent: match,
        data: record,
      }
    },
    [],
  )

  return (
    <Grid gap="1rem" columns="100%">
      <DailyNotesDataTable
        {...tableProps}
        to={to}
        testKey="all_daily_notes_table"
        hiddenColumnIds={['client']}
        duplicateFormLink={duplicateFormLink}
        help={<HelpTagIframe id="daily_notes" />}
        getCanSelect={(data: any) =>
          data.status === 'signed_off'
            ? { canSelect: false, message: 'Signed Off records cannot be edited' }
            : data.status === 'closed'
            ? { canSelect: false, message: 'Closed records cannot be edited' }
            : { canSelect: true }
        }
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/daily-notes',
          },
        ]}
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'daily_notes.delete',
            action: async ({ ids }: any) => {
              await deleteRecords(ids.join(','))
            },
          },
        ]}
        emptyActions={canCreate && <DailyNotesDropdown client={client} />}
      />
    </Grid>
  )
}

export default AllDailyNotes
