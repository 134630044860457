import React from 'react'

import { COLORS } from '../../theme'

import Glyph from '../../components/Glyph'
import Markup from '../../components/Markup'
import Tooltip from '../../components/Tooltip'

export const HighRiskAlertStatus = ({ title, notes, className }: any) => {
  if (!title) return null

  return (
    <Tooltip content={notes && <Markup value={notes} />} color="red" tooltipCss={styles.tooltip}>
      <div css={styles.root} className={className}>
        <Glyph glyph="warning" color={COLORS.white} css={styles.glyph} size={16} />
        {title}
      </div>
    </Tooltip>
  )
}

const styles = {
  root: {
    background: COLORS.red,
    color: COLORS.white,
    padding: '0.1rem 0.4rem',
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    borderRadius: '4px',
    fontSize: '0.92rem',
    fontWeight: 600,
  },

  tooltip: {
    p: {
      margin: 0,
    },

    'p + p': {
      marginTop: '0.5rem',
    },
  },

  glyph: {
    marginRight: '0.5rem',
  },
}
