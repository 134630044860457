import React from 'react'

import { SERVICE_CATEGORIES } from '../../../../../utils/constants'
import { amount, usDate, daysToWords } from '../../../../../utils/functions'
import withSettings from '../../../../../hocs/withSettings'

import ChargeStatus from '../../../../Statuses/ChargeStatus'
import Status from '../../../../Status'
import Table from '../../../../Table/Table'
import TableCell from '../../../../Table/TableCell'
import TableFilter from '../../../../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 200,
    isSticky: true,
    canToggleVisible: false,
    id: 'client',
    Header: 'Client',
    accessor: 'client',
    Cell: ({ value }: any) => <TableCell.MainLink to={to(value)} label={value.name} avatar={value.avatar} />,
  },
  {
    width: 250,
    id: 'name',
    Header: 'Name',
    accessor: 'name',
  },
  {
    width: 100,
    accessor: 'total_amount',
    Header: 'Amount',
    Cell: ({ value }: any) => <Status color="red" label={`-$${amount(value)}`} />,
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <ChargeStatus status={value} />,
  },
  {
    accessor: 'service',
    Header: 'Category',
    Cell: ({ value }: any) => {
      return SERVICE_CATEGORIES[value?.category]?.subcategories[value.subcategory].label || '–'
    },
  },
  {
    width: 200,
    accessor: 'transactioned_at',
    Header: 'Due Date',
    Cell: ({ value }: any) => (
      <>
        <span>{usDate(value, timezone)} </span>
        <span css={{ opacity: 0.6, fontSize: '0.9em' }}>({daysToWords(value, timezone)})</span>
      </>
    ),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    id: 'author',
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  timezone: string
  to?: Function
}

const ChargesTable = ({ to, hiddenColumns, data, isLoading, localStorageKey, timezone }: Props) => {
  return (
    <Table
      title="Charges"
      icon="financials"
      data={data}
      hiddenColumns={hiddenColumns}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyDescription="No charges added yet"
      localStorageKey={localStorageKey}
    />
  )
}

ChargesTable.defaultProps = {
  localStorageKey: 'charges',
}

export default withSettings(ChargesTable)
