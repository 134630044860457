import React from 'react'
import { connect } from 'react-redux'

import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import Chotomate from '../../Chotomate'
import ContextShow from '../../Forms/ContextShow'
import DateTimeInput from '../../Forms/DateTimeInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Field from '../../Forms/Field'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import HelpTagIframe from '../../Help/HelpTagIframe'
import Input from '../../Forms/Input'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import Permission from '../../Permission'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Section from '../../Section'
import SignaturePad from '../../Forms/SignaturePad'
import Switch from '../../Forms/Switch'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { ExportPDFButton } from '../../Buttons/ExportPDFButton'

class VitalSigns extends OverlayBase {
  onSaveSuccessful = () => {
    if (this.props.onSaveSuccessful) this.props.onSaveSuccessful()
  }

  renderHeader = () => {
    const { tenant } = this.props
    const isTrialing = tenant?.plan_status === 'trialing'

    return (
      <>
        <Overlay.Header icon="test_results" title="Vital Signs" help={<HelpTagIframe id="test_results_vital_signs" />} />

        {!this.state.$editable && !isTrialing && (
          <Permission permission="clients.actions.export">
            <Overlay.SubHeader>
              <ExportPDFButton url={`/vital_signs/${this.state.id}/pdf`} />
            </Overlay.SubHeader>
          </Permission>
        )}
      </>
    )
  }

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { record, timezone, tenant, loading } = this.props

    return (
      <Overlay.Content>
        <Chotomate ready name="vital_signs_overlay" />

        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          timezone={timezone}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
        >
          <Section>
            <FormSection>
              <ObjectSelector
                initialModelRequired={$new}
                model="client"
                label="Client"
                type="current_clients"
                icon="clients"
                selectTitle={(data) => data.name}
                selectDescription={(data) => data.behave_id}
                disableLink={this.isPortal}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
              />

              <DateTimeInput label="Date & Time" model="recorded_at" defaultToNow />

              <RadioGroup
                model="status"
                label="Status"
                layout="vertical-dense"
                validations={{
                  presence: {
                    message: 'Please select a status',
                  },
                }}
              >
                <Radio label="Collected" value="collected" />
                <Radio label="Not Collected" value="refused" />
              </RadioGroup>

              <ContextShow when="status" is="collected">
                <Field label="Blood Pressure" labelWidth="120px" labelAlign="right">
                  <Flex gap={8} alignItems="center">
                    <Input type="number" model="blood_pressure_systolic" size={4} />
                    <span>/</span>
                    <Input type="number" model="blood_pressure_diastolic" suffix="mm Hg" size={4} />
                  </Flex>
                </Field>
                <Input label="Temp." type="number" model="temperature" suffix="°F" size={4} />
                <Input label="O2 Sat" type="number" model="oxygen_saturation" suffix="%" size={4} />
                <Input label="Pulse" type="number" model="pulse" suffix="beats / min" size={4} />
                <Input label="Respiration" type="number" model="respiration" suffix="breaths / min" size={4} />

                <Input label="Pain Scale" type="number" model="pain_scale" suffix="/ 10" step={1} min={0} max={10} size={4} />

                <FormSection horizontal>
                  <Input label="Weight" model="weight" suffix="pounds" size={4} />

                  <Field label="Height" labelWidth="120px" labelAlign="right" className="!flex-auto">
                    <Flex gap={8} alignItems="center">
                      <Input type="number" model="height_ft" suffix="ft" size={4} />
                      <Input type="number" model="height_in" suffix="in" size={4} />
                    </Flex>
                  </Field>
                </FormSection>
              </ContextShow>

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          {tenant.is_billed && (
            <>
              <Section
                headingType="h2"
                title="Billing"
                description="Use this section to record the billable service"
                aside={<Switch defaultValue={false} model="is_billable" />}
              >
                <ContextShow when="is_billable" is={true}>
                  <FormSection maxWidth="100%">
                    <ObjectSelector
                      dependent="client_id"
                      model="insurance_service"
                      label="Rendered Insurance Service"
                      type="client.insurance_services"
                      icon="billing"
                      searchKeys={['code', 'short_description', 'long_description']}
                      selectTitle={(o: any) => `${o?.code} - ${o?.short_description}`}
                      selectDescription={(o: any) => o?.long_description}
                      groupBy={{
                        accessor: 'service_type',
                        sections: {
                          in_network: 'In Network',
                          out_of_network: 'Out of Network',
                          global_oon: 'Global Out of Network',
                        },
                      }}
                      validations={{
                        presence: {
                          message: 'Please select a service',
                        },
                      }}
                    />

                    <Textarea useQuickText model="billing_notes" label="Notes" />
                  </FormSection>
                </ContextShow>
              </Section>

              <Divider />
            </>
          )}

          <Section headingType="h2" title="Attachments" description="Upload any files related to this Vital Signs record">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          <Divider />

          <Section
            headingType="h2"
            title="Signatures"
            description="Use this section to record signatures"
            aside={<Switch defaultValue={false} model="is_signable" />}
          >
            <ContextShow when="is_signable" is={true}>
              <FormSection layout="vertical" className="!pt-4">
                <SignaturePad
                  showSignedBy={false}
                  label="Client Signature"
                  model="client_signature"
                  signedAtModel="client_signed_at"
                  allowPin={false}
                  showAccept={false}
                />

                <SignaturePad
                  allowPin
                  person={record.signed_by}
                  label="Staff Signature"
                  model="employee_signature"
                  signedAtModel="author_signed_at"
                  showAccept={false}
                />
              </FormSection>
            </ContextShow>
          </Section>

          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} disableLinks={this.isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    // skip rendering footer if Portal
    if (this.isPortal) return null

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save Vital Signs"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="test_results.vital_signs.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Vital Signs"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="test_results.vital_signs.edit"
            />

            <DeleteDialog
              title="Delete Vital Signs?"
              message="Are you sure you want to delete this Vital Signs record? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                fullWidth
                permission="test_results.vital_signs.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'vital_signs')

export default connect(mapStateToProps, defaultMapDispatchToProps)(withOverlayError(VitalSigns))
