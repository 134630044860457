import React from 'react'

import Button from '../Button'
import Link from '../Link'

import { COLORS } from '../../theme'

type Props = {
  className?: any
  css?: any
  label: string
  zoom: string
}

const ZoomButton = ({ zoom, className, ...rest }: Props) => {
  return (
    <Link href={zoom} target="_blank" className={className}>
      <Button type="default" glyph="zoom" glyphColor={COLORS.zoom} {...rest} />
    </Link>
  )
}

export default ZoomButton
