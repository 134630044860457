import React from 'react'

import { COLORS, HARD_SHADOW } from '../../theme'

export const WorksheetHeaderRow = (props: any) => {
  const { children } = props

  return (
    <div css={STYLES.root} data-type="worksheet-header-row" data-test="worksheet_header_row">
      {children}
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'var(--grid-template-columns)',
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(3),
    minWidth: 'fit-content',
  },
}
