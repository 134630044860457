import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useDataForms } from '../../../hooks/useDataForms'
import { useSettings } from '../../../hooks/useSettings'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Dropdown from '../../../components/Dropdown'
import DropdownItem from '../../../components/DropdownItem'
import FormSection from '../../../components/Forms/FormSection'
import RecoveryCoachingTable from '../../../components/Tables/RecoveryCoachingTable'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { RecoveryCapitalScaleOverlay } from '../../RecoveryNotes/RecoveryCapitalScaleOverlay'
import { CaseManagementChecklistOverlay } from '../../RecoveryNotes/CaseManagementChecklistOverlay'
import { MIRCOverlay } from '../../RecoveryNotes/MIRCOverlay'

const RecoveryNotes = ({ client, setStatus }: any) => {
  const match = useRouteMatch()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = match.params

  const clientID = client?.id || resource_id

  const { formSettings } = useSettings()

  const { batchDelete, data, isEmpty, isLoading, setFilters } = useDataForms({ clientID, category: 'recovery_coaching' })

  const [isCaseManagementOpen, setIsCaseManagementOpen] = React.useState(false)
  const [caseManagementData, setCaseManagementData]: any = React.useState(null)

  const [isRecoveryCapitalScaleOpen, setIsRecoveryCapitalScaleOpen] = React.useState(false)
  const [recoveryCapitalScaleData, setRecoveryCapitalScaleData]: any = React.useState(null)

  const [isMircOpen, setIsMircOpen] = React.useState(false)
  const [mircData, setMircData]: any = React.useState(null)

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  const action = (
    <div className="inline-flex">
      <Dropdown label="Add New…" buttonType="primary" glyph="add" position="bottom" permission="recovery_coaching.create">
        <DropdownItem
          label="Case Management Checklist"
          color="orange"
          icon="recovery_coaching"
          onClick={() => {
            setIsCaseManagementOpen(true)
          }}
        />

        {formSettings?.recovery_notes_recovery_capital_scale && (
          <DropdownItem
            label="Recovery Capital Scale"
            icon="recovery_coaching"
            onClick={() => {
              setIsRecoveryCapitalScaleOpen(true)
            }}
          />
        )}

        {formSettings?.recovery_notes_mirc && (
          <DropdownItem
            label="Multidimensional Inventory of Recovery Capital"
            icon="recovery_coaching"
            onClick={() => {
              setIsMircOpen(true)
            }}
          />
        )}
      </Dropdown>
    </div>
  )

  return (
    <FormSection maxWidth="100%">
      <Card>
        <RecoveryCoachingTable
          data={data}
          isLoading={isEmpty && isLoading}
          emptyActions={action}
          localStorageKey="clients_recovery_coaching"
          onClick={(record: any) => {
            const subcategory = record?.subcategory

            if (subcategory === 'case_management_checklist') {
              setCaseManagementData(record)
            } else if (subcategory === 'recovery_capital_scale') {
              setRecoveryCapitalScaleData(record)
            } else if (subcategory === 'mirc') {
              setMircData(record)
            }
          }}
          onDuplicateFormClick={(record: any) => {
            const subcategory = record?.subcategory

            const duplicatedRecord = { data: record.data }

            if (subcategory === 'case_management_checklist') {
              setCaseManagementData(duplicatedRecord)
            } else if (subcategory === 'recovery_capital_scale') {
              setRecoveryCapitalScaleData(duplicatedRecord)
            } else if (subcategory === 'mirc') {
              setMircData(duplicatedRecord)
            }
          }}
        />

        {!isEmpty && <CardContent>{action}</CardContent>}
      </Card>

      {/* Update Record Overlays */}
      <SummonOverlay
        key={`summon-${recoveryCapitalScaleData?.id}`}
        isOpen={!!recoveryCapitalScaleData}
        onClose={() => {
          setRecoveryCapitalScaleData(null)
        }}
        overlay={
          <RecoveryCapitalScaleOverlay
            permissionName="recovery_coaching"
            position="right"
            type="summon"
            dataID={recoveryCapitalScaleData?.id || 'new'}
            initialData={recoveryCapitalScaleData}
          />
        }
      />

      <SummonOverlay
        key={`summon-${caseManagementData?.id}`}
        isOpen={!!caseManagementData}
        onClose={() => {
          setCaseManagementData(null)
        }}
        overlay={
          <CaseManagementChecklistOverlay
            position="right"
            type="summon"
            dataID={caseManagementData?.id || 'new'}
            initialData={caseManagementData}
          />
        }
      />

      <SummonOverlay
        key={`summon-${mircData?.id}`}
        isOpen={!!mircData}
        onClose={() => {
          setMircData(null)
        }}
        overlay={<MIRCOverlay position="right" type="summon" dataID={mircData?.id || 'new'} initialData={mircData} />}
      />

      {/* Create Record Overlays */}
      <SummonOverlay
        isOpen={isRecoveryCapitalScaleOpen}
        onClose={() => {
          setIsRecoveryCapitalScaleOpen(false)
        }}
        overlay={<RecoveryCapitalScaleOverlay permissionName="recovery_coaching" position="right" type="summon" dataID="new" />}
      />

      <SummonOverlay
        isOpen={isCaseManagementOpen}
        onClose={() => {
          setIsCaseManagementOpen(false)
        }}
        overlay={<CaseManagementChecklistOverlay position="right" type="summon" dataID="new" />}
      />

      <SummonOverlay
        isOpen={isMircOpen}
        onClose={() => {
          setIsMircOpen(false)
        }}
        overlay={<MIRCOverlay position="right" type="summon" dataID="new" />}
      />

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        <Workflow.SkipButton onClick={onSkip} />
      </Workflow.Buttons>
    </FormSection>
  )
}

export default RecoveryNotes
