import React from 'react'

import Grid from '../../components/Grid'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Glyph from '../../components/Glyph'
import Flex from '../../components/Flex'

import useStore from '../../modules/store'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'

const OrganizationOnlineFinancialCards = ({ organization }: any) => {
  const tenant = useStore((state) => state.tenant)

  const paymentLink = `${process.env.BH_APP_LEDGER_URL}/${tenant?.subdomain}/org/${organization?.external_id}`

  const { copy: copyPaymentLink, didCopy: paymentLinkCopied } = useCopyToClipboard({
    text: paymentLink,
    notification: 'Payment Link copied!',
  })

  return (
    <Grid horizontal gap="1rem" templateColumns="1fr 1fr">
      <Card css={{ padding: '1rem', height: '100%' }}>
        <Grid gap="1rem">
          <Flex gap="1rem" justifyContent="space-between">
            <h3>
              <Flex gap="0.5rem" alignItems="center">
                <Glyph glyph="cash_hand" />
                <span>Online Payments</span>
              </Flex>
            </h3>
          </Flex>

          <span>
            Use <strong>Online Payments</strong> to securely collect payments from Organizations
          </span>

          <Flex gap="0.5rem">
            <Button
              glyph={paymentLinkCopied ? 'check' : 'copy'}
              label={paymentLinkCopied ? 'Link Copied!' : 'Copy Payments Link'}
              onClick={copyPaymentLink}
            />

            <Button type="link" label="Open in New Tab" href={paymentLink} target="_blank" />
          </Flex>
        </Grid>
      </Card>
    </Grid>
  )
}

export default OrganizationOnlineFinancialCards
