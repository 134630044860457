import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { getVersionSlug } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Card, Chotomate, Dropdown, DropdownItem, HelpTagIframe, Page } from '@behavehealth/components'
import { NURSING_SLUGS } from '@behavehealth/utils/constants'

import { DataFormAddDropdown } from '@behavehealth/constructs/DataFormsBuilder/DataFormAddDropdown'
import { NursingDataTable } from '@behavehealth/constructs/Nursing/NursingDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const pageConfig = {
  feature: 'nursing',
  help: <HelpTagIframe id="nursing" />,
  marketingID: 'nursing',
}

type Props = {
  canCreate: boolean
}

const Nursing: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const resourceID = match.params?.resource_id

  const tableProps = useDataTable({
    name: ['client', resourceID, 'data_forms'],
    endpoint: `/residents/${resourceID}/data_forms`,
    params: { category: 'nursing' },
    updateDeleteEndpoint: '/data_forms',
    localStorageKey: 'client_nursing_forms_v1',
  })

  const to = React.useMemo(
    () => (record: any) => {
      const { version, subcategory, id } = record

      const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

      return {
        pathname: `${match.url}/${NURSING_SLUGS[subcategory]}/${id}${formattedVersion}`,
        parent: match,
      }
    },
    [match.url],
  )

  const formsDropdown = (
    <>
      <DataFormAddDropdown category="nursing" />

      {/* <Dropdown
        testKey="add_new_nursing_form"
        label="Add New…"
        buttonType="primary"
        glyph="add"
        position="bottom"
        permission="nursing.create"
      >
        <DropdownItem
          label="Nursing Assessment"
          icon="nursing"
          link={{
            pathname: `${match.url}/nursing-assessment/new`,
            parent: match,
          }}
        />
        <DropdownItem
          label="Nursing Re-Assessment"
          icon="nursing"
          link={{
            pathname: `${match.url}/nursing-reassessment/new`,
            parent: match,
          }}
        />
        <DropdownItem
          label="COWS"
          icon="nursing"
          link={{
            pathname: `${match.url}/cows/new`,
            parent: match,
          }}
        />
        <DropdownItem
          label="CIWA"
          icon="nursing"
          link={{
            pathname: `${match.url}/ciwa/new`,
            parent: match,
          }}
        />
        <DropdownItem
          label="Progress Note"
          icon="nursing"
          link={{
            pathname: `${match.url}/progress-note/new`,
            parent: match,
          }}
        />
        <DropdownItem
          label="Fall Risk Assessment"
          icon="nursing"
          link={{
            pathname: `${match.url}/fall-risk-assessment/new`,
            parent: match,
          }}
        />
      </Dropdown> */}
    </>
  )

  return (
    <Page actions={canCreate && formsDropdown} {...pageConfig}>
      <Chotomate name="nursing" ready={!tableProps.isLoading} />
      <div className="grid grid-cols-[100%]">
        <Card>
          <NursingDataTable
            {...tableProps}
            to={to}
            testKey="nursing_data_table"
            headerLinksConfig={[{ type: 'report', to: '/reports/nursing' }]}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'nursing.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </div>
    </Page>
  )
}

export default withPageError(withMarketing(Nursing, pageConfig))
