import React from 'react'

const ProductsGrid: React.FC = ({ children }) => {
  return <div css={styles}>{children}</div>
}

const styles = {
  display: 'grid',
  alignItems: 'stretch',
  rowGap: '0.5rem',
  columnGap: '0.75rem',

  '@media (min-width: 600px)': {
    gridTemplateColumns: 'repeat(auto-fit, minmax(340px, 1fr))',
  },
}

export default ProductsGrid
