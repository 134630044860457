import React from 'react'
import snakeCase from 'lodash/snakeCase'

import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Dialog from '../../components/Dialog'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Switch from '../../components/Forms/Switch'
import ContextShow from '../../components/Forms/ContextShow'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Permission from '../../components/Permission'

import { TreatmentPlanSettings } from './form_elements/TreatmentPlanSettings'
import { TreatmentPlanSignatureSettings } from './form_elements/TreatmentPlanSignatureSettings'
import { TreatmentPlanPlanReviewsSettings } from './form_elements/TreatmentPlanPlanReviewsSettings'

import { TreatmentPlanCustomSections } from './form_elements/TreatmentPlanCustomSections'
import { TreatmentPlanRecordCategorySelect } from './form_elements/TreatmentPlanRecordCategorySelect'

const RootTreatmentPlanTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    params,
  } = useOverlay({
    name: 'treatment-plan-template',
    endpoint: '/treatment_plan_templates',
    invalidate: 'treatment-plan-templates',
    options: props,
  })

  const category = snakeCase(props.category || params.category)
  const [useCustomSection, setUseCustomSection] = React.useState(initialModel?.use_custom_sections)

  const permissionBase = `${category}_treatment_plan_templates`

  if (isOverlayLoading) return <OverlayLoader showBackdrop={isEditable} position="right" maxWidth={45} />

  return (
    <Overlay
      showBackdrop={isEditable}
      position="right"
      onClose={close}
      maxWidth={45}
      closeWrapper={(element: any, onClose: any) => (
        <Dialog
          glyph="check"
          title="Close without saving?"
          message="All changes will be lost. This action cannot be undone."
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          skip={!isEditable || !onClose}
          onYes={onClose}
        >
          {element}
        </Dialog>
      )}
    >
      <Overlay.Header icon="treatment_plans" title="Treatment Plan Template" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && { category }}
        >
          <Section>
            <FormSection maxWidth="100%">
              <TreatmentPlanRecordCategorySelect isEditable={false} label="Category" model="category" />

              <Input
                label="Template Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a Template Name',
                  },
                }}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section isOpen size={200} title="Settings">
            <FormSection maxWidth="100%">
              <TreatmentPlanSettings prefix="data" />
            </FormSection>
          </Section>

          <Divider />

          <Section
            isOpen
            size={200}
            title="Signatures"
            aside={<Switch size={200} defaultValue={false} model="data.settings.use_signatures" />}
          >
            <ContextShow when="data.settings.use_signatures" is={true}>
              <FormSection maxWidth="100%">
                <TreatmentPlanSignatureSettings prefix="data" />
              </FormSection>
            </ContextShow>
          </Section>

          <Divider />

          <Section
            title="Custom Sections"
            aside={
              <Switch
                size={200}
                defaultValue={false}
                model="data.settings.use_custom_sections"
                onUpdate={({ value }: any) => setUseCustomSection(value)}
              />
            }
          >
            {useCustomSection && (
              <FormSection maxWidth="100%">
                <TreatmentPlanCustomSections isEditable={isEditable} model="data.custom_sections" />
              </FormSection>
            )}
          </Section>

          <Divider />

          <Section title="Plan Reviews" aside={<Switch size={200} defaultValue={false} model="data.settings.use_plan_reviews" />}>
            <ContextShow when="data.settings.use_plan_reviews" is={true}>
              <FormSection maxWidth="100%">
                <TreatmentPlanPlanReviewsSettings isEditable={isEditable} prefix="data" />
              </FormSection>
            </ContextShow>
          </Section>

          <Divider />

          <Section title="Appointments" aside={<Switch size={200} defaultValue={false} model="data.settings.use_appointments" />} />

          <Divider />

          <Section title="Updates" aside={<Switch size={200} defaultValue={false} model="data.settings.use_updates" />} />
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission={isNew ? `${permissionBase}.create` : `${permissionBase}.edit`}
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Treatment Plan"
              type="default"
              isDisabled={isDeleting || isLoading || isSaving}
              onClick={edit}
              flex="3 1 auto"
              permission={`${permissionBase}.edit`}
            />

            <Permission permission={`${permissionBase}.delete`}>
              <DeleteDialog
                title="Delete Treatment Plan Template?"
                message="Are you sure you want to delete this Treatment Plan? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  fullWidth
                  glyph="cross"
                  label="Delete"
                  type="default"
                  color="red"
                  isDisabled={isLoading || isSaving || isDeleting}
                  isLoading={isDeleting}
                />
              </DeleteDialog>
            </Permission>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const TreatmentPlanTemplateOverlay = withOverlayError(RootTreatmentPlanTemplateOverlay)
