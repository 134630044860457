import React from 'react'

import SmartStatus from '../../components/SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  to_review: {
    label: 'To Review',
    color: 'orange',
  },
  published: {
    label: 'Published',
    color: 'green',
  },
}

export const FormBuilderPublishStatus = (props) => {
  if (!props.status) return <SmartStatus {...props} statuses={statuses} status="draft" />

  return <SmartStatus statuses={statuses} {...props} />
}
