import React from 'react'

import Divider from '../../components/Divider'
import Field from '../../components/Forms/Field'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootEAT26Overlay = (props: any) => {
  return (
    <DataFormOverlay
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Eating Attitudes Test (EAT-26)"
      category="clinical_measurement"
      subcategory="eat26"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <div>
          This is a screening measure to help you determine whether you might have an eating disorder that needs professional attention.
          This screening measure is not designed to make a diagnosis of an eating disorder or take the place of a professional consultation.
          Please fill out the below form as accurately, honestly and completely as possible. There are no right or wrong answers. All of
          your responses are confidential.
        </div>
      </Section>

      <Divider />

      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  return (
    <>
      <Grid gap="1rem">
        <h3>Part A: Complete the following questions:</h3>

        <Field label="Height" labelWidth="120px" labelAlign="right" className="!flex-auto">
          <Flex gap={8} alignItems="center">
            <Input type="number" model="data.questionnaire.part_a.height_ft" suffix="ft" size={4} />
            <Input type="number" model="data.questionnaire.part_a.height_in" suffix="in" size={4} />
          </Flex>
        </Field>

        <Input label="Current Weight" type="number" model="data.questionnaire.part_a.current_weight" suffix="lbs" size={4} />
        <Input
          label="Highest Weight (excluding pregnancy)"
          type="number"
          model="data.questionnaire.part_a.highest_weight"
          suffix="lbs"
          size={4}
        />
        <Input label="Lowest Adult Weight" type="number" model="data.questionnaire.part_a.lowest_adult_weight" suffix="lbs" size={4} />
        <Input label="Ideal Weight" type="number" model="data.questionnaire.part_a.ideal_weight" suffix="lbs" size={4} />
      </Grid>

      <Divider />

      <Grid gap="1rem">
        <h3>Part B: Check a response for each of the following statements:</h3>

        <RadioGroup
          label="1. Am terrified about being overweight."
          model="data.questionnaire.part_b.terrified_about_being_overweight"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="2. Avoid eating when I am hungry."
          model="data.questionnaire.part_b.avoid_eating_when_hungry"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="3. Find myself preoccupied with food."
          model="data.questionnaire.part_b.preoccupied_with_food"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="4. Have gone on eating binges where I feel that I may not be able to stop."
          model="data.questionnaire.part_b.eating_binges"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="5. Cut my food into small pieces."
          model="data.questionnaire.part_b.cut_food_into_small_pieces"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="6. Aware of the calorie content of foods that I eat."
          model="data.questionnaire.part_b.aware_of_the_calorie_content"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="7. Particularly avoid food with a high carbohydrate content (i.e. bread, rice, potatoes, etc.)"
          model="data.questionnaire.part_b.avoid_food_with_many_carbohydrates"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="8. Feel that others would prefer if I ate more."
          model="data.questionnaire.part_b.feel_others_would_prefer_me_eat_more"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup label="9. Vomit after I have eaten." model="data.questionnaire.part_b.vomit_after_eating" layout="vertical-dense">
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="10. Feel extremely guilty after eating."
          model="data.questionnaire.part_b.feeling_guilty_after_eating"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="11. Am preoccupied with a desire to be thinner."
          model="data.questionnaire.part_b.desire_to_be_thinner"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="12. Think about burning up calories when I exercise."
          model="data.questionnaire.part_b.think_about_burning_calories_when_exercise"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="13. Other people think that I am too thin."
          model="data.questionnaire.part_b.others_think_i_am_too_thin"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="14. Am preoccupied with the thought of having fat on my body."
          model="data.questionnaire.part_b.preoccupied_with_having_fat_on_body"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="15. Take longer than others to eat my meals."
          model="data.questionnaire.part_b.take_longer_than_others_to_eat"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="16. Avoid foods with sugar in them."
          model="data.questionnaire.part_b.avoid_foods_with_sugar"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup label="17. Eat diet foods." model="data.questionnaire.part_b.eat_diet_foods" layout="vertical-dense">
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="18. Feel that food controls my life."
          model="data.questionnaire.part_b.feel_food_controls_my_life"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="19. Display self-control around food."
          model="data.questionnaire.part_b.self_control_around_food"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="20. Feel that others pressure me to eat."
          model="data.questionnaire.part_b.feel_others_pressure_me_to_eat"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="21. Give too much time and thought to food."
          model="data.questionnaire.part_b.give_too_much_time_and_thought_to_food"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="22. Feel uncomfortable after eating sweets."
          model="data.questionnaire.part_b.feel_uncomfortable_after_eating_sweets"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="23. Engage in dieting behavior."
          model="data.questionnaire.part_b.engage_in_dieting_behavior"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="24. Like my stomach to be empty."
          model="data.questionnaire.part_b.like_my_stomach_to_be_empty"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="25. Have the impulse to vomit after meals."
          model="data.questionnaire.part_b.have_vomit_impulse_after_meals"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>

        <RadioGroup
          label="26. Enjoy trying new rich foods."
          model="data.questionnaire.part_b.enjoy_trying_new_rich_foods"
          layout="vertical-dense"
        >
          <Radio label="Always" value={5} />
          <Radio label="Usually" value={4} />
          <Radio label="Often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Rarely" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>
      </Grid>

      <Divider />

      <Grid gap="1rem">
        <h3>Part C: Behavioral Questions: In the past 6 months have you:</h3>

        <RadioGroup
          label="A Gone on eating binges where you feel that you may not be able to stop?"
          model="data.questionnaire.part_c.eating_binges"
          layout="vertical-dense"
          tooltip="Defined as eating much more than most people would under the same circumstances and feeling that eating is out of control"
        >
          <Radio label="Never" value={0} />
          <Radio label="Once a month or less" value={1} />
          <Radio label="2-3 times a month" value={2} />
          <Radio label="Once a week" value={3} />
          <Radio label="2-6 times a week" value={4} />
          <Radio label="Once a day or more" value={5} />
        </RadioGroup>

        <RadioGroup
          label="B Ever made yourself sick (vomited) to control your weight or shape?"
          model="data.questionnaire.part_c.vomited_to_control_weight"
          layout="vertical-dense"
        >
          <Radio label="Never" value={0} />
          <Radio label="Once a month or less" value={1} />
          <Radio label="2-3 times a month" value={2} />
          <Radio label="Once a week" value={3} />
          <Radio label="2-6 times a week" value={4} />
          <Radio label="Once a day or more" value={5} />
        </RadioGroup>

        <RadioGroup
          label="C Ever used laxatives, diet pills or diuretics (water pills) to control your weight or shape?"
          model="data.questionnaire.part_c.used_laxatives_to_control_weight"
          layout="vertical-dense"
        >
          <Radio label="Never" value={0} />
          <Radio label="Once a month or less" value={1} />
          <Radio label="2-3 times a month" value={2} />
          <Radio label="Once a week" value={3} />
          <Radio label="2-6 times a week" value={4} />
          <Radio label="Once a day or more" value={5} />
        </RadioGroup>

        <RadioGroup
          label="D Exercised more than 60 minutes a day to lose or to control your weight?"
          model="data.questionnaire.part_c.exercised_more_than_one_hour_per_day"
          layout="vertical-dense"
        >
          <Radio label="Never" value={0} />
          <Radio label="Once a month or less" value={1} />
          <Radio label="2-3 times a month" value={2} />
          <Radio label="Once a week" value={3} />
          <Radio label="2-6 times a week" value={4} />
          <Radio label="Once a day or more" value={5} />
        </RadioGroup>

        <RadioGroup
          label="E Lost 20 pounds or more in the past 6 months"
          model="data.questionnaire.part_c.lost_20_pounds_or_more"
          layout="vertical-dense"
        >
          <Radio label="Never" value={0} />
          <Radio label="Once a month or less" value={1} />
          <Radio label="2-3 times a month" value={2} />
          <Radio label="Once a week" value={3} />
          <Radio label="2-6 times a week" value={4} />
          <Radio label="Once a day or more" value={5} />
        </RadioGroup>
      </Grid>

      <div className="!mt-4">
        © Copyright: EAT-26: (Garner et al. 1982, Psychological Medicine, 12, 871-878); adapted by D. Garner with permission.
      </div>
    </>
  )
}

export const EAT26Overlay = withOverlayError(RootEAT26Overlay)
