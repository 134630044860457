import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { ERADataTable } from '@behavehealth/constructs/RCM/ERADataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ERAs = () => {
  const match = useRouteMatch()

  const to = React.useCallback((o: any) => `${match.url}/${o.id}`, [match.url])

  const tableProps = useDataTable({
    name: ['insurance_eras'],
    endpoint: '/insurance_eras',
    params: { status: 'processed', category: 'remittance_reconciliation' },
    localStorageKey: 'insurance_eras_v1',
  })

  return <ERADataTable {...tableProps} to={to} />
}

export default ERAs
