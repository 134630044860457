import React from 'react'
import { v4 as uuid } from 'uuid'
import produce from 'immer'
import size from 'lodash/size'
import isLodashEmpty from 'lodash/isEmpty'

import { COLORS, SHADOW } from '../../../theme'
import { arrayToInternalMap, mapToArray } from '../../../utils/functions'
import { withFormContext } from '../../../components/Forms/context'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import Grid from '../../../components/Grid'
import GridTable from '../../../components/GridTable'
import AmountInput from '../../../components/Forms/AmountInput'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import Status from '../../../components/Status'
import Tooltip from '../../../components/Tooltip'
import Glyph from '../../../components/Glyph'

import { useFormField } from '../../../components/Forms/hooks/useFormField'

const columns = [
  {
    name: 'Insurance Code*',
    width: 'minmax(300px, 1fr)',
  },
  {
    name: 'Description',
    width: 'minmax(250px, 1fr)',
  },
  {
    name: 'Procedure Code',
    width: 'minmax(130px, 1fr)',
  },
  {
    name: 'Revenue Code',
    width: 'minmax(130px, 1fr)',
  },
  {
    name: 'Unit',
    width: 'minmax(130px, 1fr)',
  },
  {
    name: 'Modifier Code',
    width: 'minmax(130px, 1fr)',
  },
  {
    name: 'Code Type',
    width: 'minmax(130px, 1fr)',
  },
  {
    name: 'UCR',
    description: 'Usual Customary Rate',
    width: 'minmax(150px, 1fr)',
  },
  {
    name: 'Contract Rate',
    width: 'minmax(150px, 1fr)',
  },
  {
    name: '',
    width: '50px',
    hideOnReadonly: true,
  },
]

const gridColumnsEdit = columns.map((column) => column.width).join(' ')
const readonlyColumns = [...columns].slice(0, -1)
const gridColumnsReadonly = readonlyColumns.map((column) => column.width).join(' ')

const UNITS = {
  per_day: 'Per Day',
  per_unit: 'Per Unit',
}

const CODE_TYPES = {
  cpt: 'CPT',
  hcpc_mod: 'HCPC/MOD',
  hcpcs: 'HCPCS',
}

const FeeScheduleTable = ({ value, model = 'insurance_new_fee_schedule_services', form, isEditable, className }: any) => {
  const [initialData, setInitialData]: any = React.useState({})
  const [data, setData]: any = React.useState({})

  const isEmpty = size(data) === 0

  const { formActions } = useFormField({
    model: model,
    form: form,
    isNested: true,
  })

  // ON VALUE CHANGE
  React.useEffect(() => {
    let initialDta = value
    if (!initialDta) return

    initialDta = produce(initialDta, (draft: any) => {
      if (!Array.isArray(draft)) return

      draft?.map((o: any) => {
        o._id = uuid()

        // set defaults
        o.price ||= null
        o.contractable_rate ||= null

        return o
      })
    })

    const initData = arrayToInternalMap(initialDta)
    if (isLodashEmpty(initialData)) setInitialData(initData)
    setData(initData)
  }, [value])

  // UPDATE FORM
  React.useEffect(() => {
    formActions.setValue(mapToArray(data))
  }, [data])

  const add = () => {
    const newID = uuid()

    setData((o: any) => ({
      ...o,
      [newID]: {
        _id: newID,
        insurance_new_code_id: null,
        insurance_new_code: null,
        price: null,
        contractable_rate: null,
      },
    }))
  }

  const remove = (id: string) => {
    const newData = produce(data, (draft: any) => {
      if (draft[id].id) {
        draft[id]._destroy = true
      } else {
        delete draft[id]
      }
    })

    setData(newData)
  }

  const handleUpdate = (model: string, id: string, value: any) => {
    const newData = produce(data, (draft: any) => {
      draft[id][model] = value
    })
    setData(newData)
  }

  const handleMultiUpdate = (id: string, updates: object) => {
    const newData = produce(data, (draft: any) => {
      draft[id] = Object.assign({}, draft[id], updates)
    })

    setData(newData)
  }

  return (
    <Card className={className}>
      <CardHeader
        after={isEditable && <Button label="Add Service" glyph="add" type="minimal" display="inline-flex" size={200} onClick={add} />}
      >
        <CardTitle title="Services" css={styles.cardTitle} />
      </CardHeader>

      <CardContent padding="0" css={styles.cardContent}>
        <GridTable templateColumns={isEditable ? gridColumnsEdit : gridColumnsReadonly}>
          <GridTable.Header>
            {columns?.map((column) => {
              if (!isEditable && column.hideOnReadonly) return null
              if (column.description)
                return (
                  <Tooltip content="Usual Customary Rate" position="top">
                    <GridTable.Cell key={column.name}>
                      {column.name} <Glyph glyph="info" size={12} color={COLORS.violet} />
                    </GridTable.Cell>
                  </Tooltip>
                )

              return <GridTable.Cell key={column.name}>{column.name}</GridTable.Cell>
            })}
          </GridTable.Header>

          {isEmpty && (
            <div css={styles.emptyState}>
              <div>No services added yet</div>
            </div>
          )}

          {!isEmpty &&
            mapToArray(data)
              ?.filter((o) => o._destroy !== true)
              ?.map((item: any) => (
                <GridTable.Row key={item?.id} css={styles.gridTableRow} className={isEditable ? 'center-line' : ''}>
                  <GridTable.Cell>
                    <ObjectSelector
                      label=""
                      icon="insurance"
                      type="insurance_new_codes"
                      value={data[item._id].insurance_new_code}
                      selectTitle={(data: any) => `${data.service_name}`}
                      selectDescription={(data: any) => (
                        <Grid gap="0.2rem">
                          <span>{data?.procedure_code}</span>
                          <Status label={`Rev: ${data.revenue_code || '–'}`} />
                        </Grid>
                      )}
                      onUpdate={(state: any) => {
                        if (!state.object) return

                        handleMultiUpdate(item._id, {
                          insurance_new_code_id: state.object.id,
                          insurance_new_code: state.object,
                        })
                      }}
                    />
                  </GridTable.Cell>

                  <GridTable.Cell>{data[item._id]?.insurance_new_code?.description || '–'}</GridTable.Cell>
                  <GridTable.Cell>{data[item._id]?.insurance_new_code?.procedure_code || '–'}</GridTable.Cell>
                  <GridTable.Cell>{data[item._id]?.insurance_new_code?.revenue_code || '–'}</GridTable.Cell>
                  <GridTable.Cell>{UNITS[data[item._id]?.insurance_new_code?.unit] || '–'}</GridTable.Cell>
                  <GridTable.Cell>
                    {data[item._id]?.insurance_new_code?.modifier_codes?.map((o: any) => o.code)?.join(', ') || '–'}
                  </GridTable.Cell>
                  <GridTable.Cell>{CODE_TYPES[data[item._id]?.insurance_new_code?.code_type] || '–'}</GridTable.Cell>

                  <GridTable.Cell>
                    <AmountInput value={data[item._id]?.price} onUpdate={({ value }: any) => handleUpdate('price', item._id, value)} />
                  </GridTable.Cell>

                  <GridTable.Cell>
                    <AmountInput
                      value={data[item._id]?.contractable_rate}
                      onUpdate={({ value }: any) => handleUpdate('contractable_rate', item._id, value)}
                    />
                  </GridTable.Cell>

                  {isEditable && (
                    <GridTable.Cell>
                      <Button hideLabel glyph="delete" type="minimal" color="red" onClick={() => remove(item._id)} />
                    </GridTable.Cell>
                  )}
                </GridTable.Row>
              ))}
        </GridTable>
      </CardContent>
    </Card>
  )
}

const styles = {
  emptyState: {
    display: 'grid',
    alignItems: 'center',
    textAlign: 'center',
    padding: '1rem',
    color: COLORS.textMuted,
    opacity: 0.8,
  },

  cardTitle: {
    fontSize: '1rem',
  },

  cardContent: {
    height: '100%',
  },

  gridTableRow: {
    '&:last-of-type': {
      borderBottom: `1px solid ${COLORS.divider}`,
    },

    '&.center-line': {
      lineHeight: '38px',
    },
  },

  draggable: {
    left: 'auto !important',
    right: '0 !important',

    '&.is-dragging': {
      borderRadius: 4,
      background: COLORS.white,
      boxShadow: SHADOW(3, COLORS.divider),
    },
  },

  moveGlyphCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      svg: {
        opacity: 1,
        fill: COLORS.blue,
      },
    },
  },

  moveGlyph: {
    opacity: 0.7,
    cursor: 'drag',
  },
}

export default withFormContext(FeeScheduleTable)
