import React from 'react'

import withSettings from '../../../hocs/withSettings'

import Table from '../../Table/Table'
import TableCell from '../../Table/TableCell'

const columns = (to, onClick, mainLinkAs) => [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Name',
    width: 240,
    Cell: ({ cell, row }: any) => {
      return <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} onClick={() => onClick?.(row.original)} label={cell?.value} />
    },
  },
  {
    id: 'require_reference_signature',
    accessor: 'settings.require_reference_signature',
    Header: 'Client Signature',
    width: 100,
    Cell: ({ value }: any) => <TableCell.TrueFalse isTrue={value} />,
  },
  {
    id: 'author.name',
    accessor: 'author',
    Header: 'Created by',
    width: 160,
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original?.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  onSelect?: Function
  title?: string
  titleAfter?: React.ReactNode
  to?: Function
}

const CustomNoteTemplatesTable = (props: Props) => {
  const { batchActionsConfig, data, isLoading, localStorageKey, onClick, title, titleAfter, to, ...rest } = props

  return (
    <Table
      data={data}
      columns={columns(to, onClick, props.mainLinkAs)}
      testKey="custom_note_templates_table"
      title={title}
      titleAfter={titleAfter}
      icon="clinical_assessments"
      isLoading={isLoading}
      emptyDescription="No custom note templates have been added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

CustomNoteTemplatesTable.defaultProps = {
  title: 'Custom Note Templates',
  localStorageKey: 'custom_note_templates',
}

export default withSettings(CustomNoteTemplatesTable)
