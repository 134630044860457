import React from 'react'

import { DataTableCell } from '../DataTableCell'

import { EmptyCell } from './EmptyCell'
import { LinkCell } from './LinkCell'

import { GlobalSummonOverlayContext } from '../../GlobalSummonOverlay'
import { IframeOverlay } from '../../IframeOverlay'

export const HubspotEHRAccountCell: React.FC = (props: any) => {
  const { value: id, rowData } = props

  const name = rowData?.hubspot_ehr_account?.name
  const label = id && name ? `${name} (${id})` : id

  const globalSummonOverlayContext: any = React.useContext(GlobalSummonOverlayContext)
  const setOverlay = globalSummonOverlayContext?.setOverlay

  return (
    <DataTableCell hoverExpand {...props}>
      {id ? (
        <LinkCell
          isExternal
          glyph="hubspot"
          value={label}
          onClick={() => {
            setOverlay?.(
              <IframeOverlay
                showExternalLink
                icon="hubspot"
                title="Hubspot EHR Account"
                subtitle={label}
                url={`https://app.hubspot.com/contacts/2828210/company/${id}`}
                maxWidth={80}
              />,
            )
          }}
        />
      ) : (
        <EmptyCell />
      )}
    </DataTableCell>
  )
}
