import React from 'react'

import Button from '../Button'
import { StepperContext } from './context'

const StepperBackButton: React.FC<any> = ({ onClick, isLoading, isDisabled, label = '← Back', color = 'text', glyph, className }) => {
  const { goBack, isFirst }: any = React.useContext(StepperContext)

  if (isFirst) return null

  return (
    <div>
      <Button
        label={label}
        color={color}
        glyph={glyph}
        type="link"
        display="inline-flex"
        onClick={onClick ? onClick : goBack}
        isDisabled={isDisabled}
        isLoading={isLoading}
        size={300}
        css={styles}
        className={className}
      />
    </div>
  )
}

const styles = {
  marginLeft: '-1rem',
}

export default StepperBackButton
