import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  out_of_network: {
    label: 'Out Of Network',
    color: 'red',
  },
  in_network: {
    label: 'In Network',
    color: 'green',
  },
}

const PayerNetworkStatus = (props) => {
  return <SmartStatus statuses={statuses} {...props} />
}

export default PayerNetworkStatus
