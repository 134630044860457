import React from 'react'

import { apiGet } from '@behavehealth/modules/api'
import { MEDIA_QUERY } from '@behavehealth/theme'
import { Organization, Property, Tenant, User } from '@behavehealth/declarations'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withSettings from '@behavehealth/hocs/withSettings'

import { Card, Chotomate, HelpTagIframe, Map, MapPopup, Page, PageLayout } from '@behavehealth/components'

const REDIRECT_BASE_URL = {
  house: '/locations',
  office: '/offices',
  organization: '/community/organizations',
}

interface PlacesResponse {
  houses: Property[]
  organizations: Organization[]
}

type Props = {
  tenant: Tenant
  user: User
}

const Places: React.FC<Props> = ({ tenant, user }) => {
  const [data, setData] = React.useState<PlacesResponse>({})
  const [loading, setLoading] = React.useState(false)
  const [, setError] = React.useState()

  React.useEffect(() => {
    const getPlaces = async () => {
      let results
      setLoading(true)

      try {
        results = await apiGet<PlacesResponse>({ name: 'places', url: '/places', reducer: 'data' })
      } catch (e) {
        setError(e)
      }

      setData({
        properties: results.data.houses,
        organizations: results.data.organizations,
      })
      setLoading(false)
    }

    getPlaces()
  }, [])

  return (
    <Page feature="places" help={<HelpTagIframe id="places" />} breakpoint="0">
      <Chotomate name="places" ready={!loading} />

      <PageLayout>
        <Card>
          <Map
            css={mapStyles}
            lat={tenant?.lat}
            lon={tenant?.lon}
            data={data}
            layers={['properties', 'offices', 'organizations']}
            popup={(location: any) => <MapPopup location={location} to={`${REDIRECT_BASE_URL[location.type]}/${location.id}`} />}
            theme={user?.prefs?.map_style}
          />
        </Card>
      </PageLayout>
    </Page>
  )
}

const mapStyles = {
  width: '100%',
  minHeight: '30rem',
  height: 'calc(100vh - 8.5rem)',

  [MEDIA_QUERY[1]]: {
    height: 'calc(100vh - 6.5rem)',
  },
}

export default withPageError(withSettings(Places))
