import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  invited: {
    label: 'Invited to Portal',
    color: 'yellow',
  },
  access: {
    label: 'Has Portal Access',
    color: 'green',
  },
  blocked: {
    label: 'Portal Access Revoked',
    color: 'red',
  },
  shared: {
    label: 'Shared with Portal',
    color: 'green',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus glyph="portal" glyphColor="black" statuses={statuses} {...rest} />
}

export default Status
