import React from 'react'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import sortBy from 'lodash/sortBy'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import AnimatedSwitch from '@behavehealth/components/AnimatedSwitch'
import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import PageHeader from '@behavehealth/components/PageHeader'
import Portal from '@behavehealth/components/Portal'
import State from '@behavehealth/components/State'
import Tabs from '@behavehealth/components/Tabs'

import { MessageBoardDataTable } from '@behavehealth/constructs/MessageBoard/MessageBoardDataTable'
import { PartnershipMessageOverlay } from '@behavehealth/constructs/Partnerships/PartnershipMessageOverlay'

export const RootMessages: React.FC = () => {
  const match = useRouteMatch()

  const { resource_id: id }: any = useParams()

  const { data: organization }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  if (!organization) return null

  const isMessagesSharedOut = organization?.other_partner_settings?.message_board === true
  const isMessagesSharedIn = organization?.self_partner_settings?.message_board === true

  return (
    <>
      <div css={STYLES.root}>
        <div className="bg-white sticky top-0 z-10 flex-0">
          <PageHeader
            title="Message Board"
            icon="message_board"
            aside={isMessagesSharedOut && <Button label="Add New Message" glyph="add" type="primary" link={`${match.url}/sent/new`} />}
          />

          <Tabs>
            <Tabs.List className="px-4">
              <Tabs.Item label="Messages Sent" to={`${match.url}/sent`} />
              <Tabs.Item label="Messages Received" to={`${match.url}/received`} />
            </Tabs.List>
          </Tabs>
        </div>

        <div css={STYLES.content}>
          <Switch>
            <Route path={`${match.path}/sent`}>
              {isMessagesSharedOut ? (
                <SentPage />
              ) : (
                <div className="pt-4">
                  <Card>
                    <State
                      isEmpty
                      title="Message Board"
                      icon="message_board"
                      emptyDescription="You have not enabled message board sharing"
                      emptyActions={
                        <Button
                          label="Manage Sharing"
                          glyph="settings"
                          type="default"
                          link={match.url.replace('/message-board', '/share-settings')}
                        />
                      }
                    />
                  </Card>
                </div>
              )}
            </Route>

            <Route path={`${match.path}/received`}>
              {isMessagesSharedIn ? (
                <ReceivedPage />
              ) : (
                <div className="pt-4">
                  <Card>
                    <State
                      isEmpty
                      title="Message Board"
                      icon="message_board"
                      emptyDescription="This partner has not enabled message board sharing"
                    />
                  </Card>
                </div>
              )}
            </Route>

            <Redirect exact from={match.url} to={`${match.url}/sent`} />
          </Switch>
        </div>
      </div>

      <Portal type="z90">
        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          <Route exact path={`${match.path}/sent/:id`}>
            <PartnershipMessageOverlay canEdit partnershipId={organization?.id} />
          </Route>

          <Route exact path={`${match.path}/received/:id`}>
            <PartnershipMessageOverlay partnershipId={organization?.id} />
          </Route>
        </AnimatedSwitch>
      </Portal>
    </>
  )
}

const SentPage = () => {
  const match = useRouteMatch()
  const { resource_id: id }: any = useParams()

  const tableProps: any = useDataTable({
    name: ['organization', id, 'profile_messages', 'shared_out'],
    endpoint: `/organizations/${id}/self_partner_profile_messages`,
  })

  const to = React.useMemo(() => (rowData: any) => `${match.url}/${rowData?.id}`, [match.url])

  const data = React.useMemo(() => {
    if (!tableProps.data) return []

    return sortBy(tableProps.data, 'order')
  }, [tableProps.data])

  return (
    <div className="pt-4">
      <MessageBoardDataTable title="Sent Partner Messages" {...tableProps} data={data} to={to} />
    </div>
  )
}

const ReceivedPage = () => {
  const match = useRouteMatch()
  const { resource_id: id }: any = useParams()

  const tableProps: any = useDataTable({
    name: ['organization', id, 'profile_messages', 'other_partner'],
    endpoint: `/organizations/${id}/other_partner_profile_messages`,
  })

  const data = React.useMemo(() => {
    if (!tableProps.data) return []

    return sortBy(tableProps.data, 'order')
  }, [tableProps.data])

  const to = React.useMemo(() => (rowData: any) => `${match.url}/${rowData?.id}`, [match.url])

  return (
    <div className="pt-4">
      <MessageBoardDataTable title="Received Partner Messages" {...tableProps} data={data} to={to} />
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%',
    overflow: 'hidden',
  },

  content: {
    padding: '1rem',
    paddingTop: 0,
    overflowY: 'auto',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
  },
}

export const Messages = withPageError(RootMessages)
