import React from 'react'
import { tint } from 'polished'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { COLORS } from '../../theme'
import { titleCase } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { NotionHelpOverlay } from '../Help/NotionHelpOverlay'
import { NotionInternalHelpOverlay } from '../Help/NotionInternalHelpOverlay'
import Glyph from '../Glyph'
import Flex from '../Flex'
import SummonOverlay from '../SummonOverlay'

import { CIRCLE_HELP_PAGES } from './constants'

type Props = {
  id: keyof typeof CIRCLE_HELP_PAGES
  label?: string
  className?: any
}

const HelpTagIframe: React.FC<Props> = ({ id, label = 'Help', className }) => {
  const location = useLocation()
  const history = useHistory()

  const { isBehave } = useSettings()

  const helpPages = useSelector((state: any) => state.me?.settings?.help_center)
  const helpTagsByID = useSelector((state: any) => state.me?.settings?.help_center_tags)
  const currentPage = helpPages?.[id]

  const internalPages = useSelector((state: any) => state.me?.settings?.internal_center)
  const currentInternalPage = internalPages?.[id]

  const helpClassNames = clsx(isBehave && 'is-behave', !currentPage && 'is-empty', className)
  const internalClassNames = clsx(isBehave && 'is-behave', !currentInternalPage && 'is-empty', 'internal-tag', className)

  const onHelpClick = () => {
    if (!currentPage) return

    const queryParams = new URLSearchParams(location.search)
    if (!queryParams.has('guide')) {
      const newQueryParams = new URLSearchParams({ ...location.search, guide: id })
      history.replace({ search: newQueryParams.toString() })
    }
  }

  const onInternalClick = () => {
    if (!currentInternalPage) return

    const queryParams = new URLSearchParams(location.search)
    if (!queryParams.has('internal')) {
      const newQueryParams = new URLSearchParams({ ...location.search, internal: id })
      history.replace({ search: newQueryParams.toString() })
    }
  }

  if (!id || (!currentPage && !isBehave)) return null

  return (
    <Flex css={styles.root} gap={4}>
      <div css={styles.tag} className={helpClassNames}>
        <div className="help-tag" onClick={onHelpClick}>
          <Glyph glyph="info" color={COLORS.blue} size="1.4em" />
          <span>{label}</span>
        </div>

        {isBehave && (
          <SummonOverlay overlay={<NotionHelpOverlay title={currentPage?.name || titleCase(id)} url={currentPage?.url} helpTag={id} />}>
            <div className="help-edit">
              <Glyph glyph="edit" size={14} />
            </div>
          </SummonOverlay>
        )}
      </div>

      {isBehave && (
        <div css={styles.tag} className={internalClassNames}>
          <div className="help-tag" onClick={onInternalClick}>
            <Glyph glyph="behave_health" color={COLORS.blue} size="1.4em" />
            <span>Internal</span>
          </div>

          <SummonOverlay
            overlay={
              <NotionInternalHelpOverlay title={currentInternalPage?.name || titleCase(id)} url={currentInternalPage?.url} helpTag={id} />
            }
          >
            <div className="help-edit">
              <Glyph glyph="edit" size={14} />
            </div>
          </SummonOverlay>
        </div>
      )}
    </Flex>
  )
}

const styles: any = {
  root: {
    display: 'inline-flex',
  },

  tag: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    borderRadius: 100,
    background: tint(0.8, COLORS.blue),

    color: COLORS.black,
    fontSize: '0.8rem',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: 1,
    textTransform: 'uppercase',
    cursor: 'pointer',
    overflow: 'hidden',

    svg: {
      marginRight: '0.25em',
    },

    '.help-tag': {
      display: 'inline-flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      padding: '0.2em',
      paddingRight: '0.4em',
    },

    '.help-edit': {
      display: 'inline-flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      padding: '0 0.5em',
      borderLeft: `1px solid ${tint(0.6, COLORS.blue)}`,

      '&:hover': {
        background: tint(0.6, COLORS.blue),
      },
    },

    '&.is-behave': {
      '.help-tag': {
        '&:hover': {
          background: tint(0.6, COLORS.blue),
        },
      },
    },

    '&.is-empty': {
      color: COLORS.gray,
      background: tint(0.8, COLORS.gray),
      svg: { fill: tint(0.4, COLORS.gray) },

      '.help-tag': {
        '&:hover': {
          cursor: 'default',
          background: 'none',
        },
      },

      '.help-edit': {
        borderLeft: `1px solid ${tint(0.6, COLORS.gray)}`,
        svg: { fill: COLORS.blue },
      },
    },
  },

  // '&.internal-tag': {
  //   background: tint(0.8, COLORS.violet),

  //   '&.is-behave .help-tag:hover': {
  //     background: tint(0.6, COLORS.violet),
  //   },

  //   '.help-edit': {
  //     borderLeft: `1px solid ${tint(0.6, COLORS.violet)}`,

  //     '&:hover': {
  //       background: tint(0.6, COLORS.violet),
  //     },
  //   },

  //   '&.is-empty': {
  //     color: COLORS.gray,
  //     background: tint(0.8, COLORS.gray),
  //     svg: { fill: tint(0.4, COLORS.gray) },

  //     '.help-tag': {
  //       '&:hover': {
  //         cursor: 'default',
  //         background: 'none',
  //       },
  //     },

  //     '.help-edit': {
  //       borderLeft: `1px solid ${tint(0.6, COLORS.gray)}`,
  //       svg: { fill: COLORS.blue },
  //     },
  //   },
  // },
}

export default HelpTagIframe
