import React from 'react'

import { amount, niceAmount, usDate, daysToWords } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import ChargeStatus from '../Statuses/ChargeStatus'
import Flex from '../Flex'
import Status from '../Status'
import Glyph from '../Glyph'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import { PAYMENT_METHODS } from '../../utils/constants'

const columns = (to: Function = () => {}, isSelectable: boolean, timezone: string) => [
	{
		isSticky: true,
		canToggleVisible: false,
		accessor: 'name',
		Header: 'Name',
		Cell: ({ value, row }: any) => <TableCell.MainLink to={isSelectable && to(row.original.id)} label={value} />
	},
	{
		accessor: 'total_amount',
		Header: 'Amount',
		width: 250,
		Cell: ({ row, value }: any) => {
			const hasCustomCollection = row.original.is_custom_collection

			return (
				<Flex gap="0.5rem" nowrap justifyContent="flex-end">
					<Status color="red" label={`-$${amount(value)}`} />
					{hasCustomCollection && (
						<Status
							color="green"
							label={`-$${amount(row.original.collection_amount)}`}
							infoTooltip={`Collecting ${niceAmount(row.original.collection_amount)} when Charged`}
						/>
					)}
				</Flex>
			)
		}
	},
	{
		accessor: 'status',
		Header: 'Status',
		Cell: ({ value }: any) => <ChargeStatus status={value} />
	},
	{
		width: 250,
		accessor: 'payed_with',
		Header: 'Payed With',
		Cell: ({ value }: any) => {
			if (!value) return '–'

			return (
				<>
					<Glyph glyph={PAYMENT_METHODS[value]?.glyph} /> {PAYMENT_METHODS[value]?.label}
				</>
			)
		}
	},
	{
		width: 250,
		accessor: 'reference',
		Header: 'Payer',
		Cell: ({ value }: any) => (value ? <TableCell.Profile avatar={value?.avatar} name={value?.name} /> : '–')
	},
	{
		width: 200,
		accessor: 'should_auto_charge',
		Header: 'Automatic Collection',
		Cell: ({ value }: any) => <TableCell.TrueFalse isTrue={value} />,
		Filter: TableFilter.TrueFalse
	},
	{
		width: 200,
		accessor: 'payment_method',
		Header: 'Payment Method',
		Cell: ({ value }: any) => <TableCell value={value?.name || '–'} />
	},
	{
		width: 200,
		accessor: 'transactioned_at',
		Header: 'Transaction Date',
		Cell: ({ value }: any) => (
			<>
				<span>{usDate(value, timezone)} </span>
				<span css={{ opacity: 0.6, fontSize: '0.9em' }}>({daysToWords(value, timezone)})</span>
			</>
		),
		Filter: TableFilter.Date,
		filter: 'date'
	},
	{
		width: 200,
		accessor: 'created_at',
		Header: 'Date Added',
		Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
		Filter: TableFilter.Date,
		filter: 'date'
	},
	{
		width: 220,
		accessor: 'updated_at',
		Header: 'Date Updated',
		Filter: TableFilter.Date,
		filter: 'date',
		Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />
	},
	{
		width: 200,
		accessor: 'client_location',
		Header: 'Client Location',
		Cell: ({ row, value }: any) => (
			<TableCell.Profile avatar={row.original.client_location?.avatar} name={row.original.client_location?.name} />
		)
	},
	{
		id: 'author',
		width: 180,
		accessor: 'author',
		Header: 'Added By',
		Cell: ({ row, value }: any) => (
			<TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />
		)
	}
]

type Props = {
	data: Object
	hiddenColumns?: string[]
	isLoading: boolean
	isSelectable: boolean
	localStorageKey: string
	timezone: string
	to?: Function
	titleAfter?: React.ReactNode
	batchActionsConfig?: Object
}

const ChargesTable = ({
	to,
	hiddenColumns,
	data,
	isLoading,
	isSelectable,
	batchActionsConfig,
	localStorageKey,
	timezone,
	titleAfter,
	testKey
}: Props) => {
	return (
		<Table
			testKey={testKey}
			title="Charges"
			titleAfter={titleAfter}
			icon="financials"
			data={data}
			hiddenColumns={hiddenColumns}
			columns={columns(to, isSelectable, timezone)}
			isLoading={isLoading}
			emptyDescription="No charges added yet"
			batchActionsConfig={batchActionsConfig}
			localStorageKey={localStorageKey}
		/>
	)
}

ChargesTable.defaultProps = {
	localStorageKey: 'charges'
}

export default withSettings(ChargesTable)
