import React from 'react'

import SmartStatus from '../../components/SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'grey',
  },
  validated: {
    label: 'Validated',
    color: 'yellow',
  },
  validation_error: {
    label: 'Validation Error',
    color: 'orange',
  },
  submitted: {
    label: 'Submitted',
    color: 'green',
  },
  submission_error: {
    label: 'Submission Error',
    color: 'orange',
  },
  denied: {
    label: 'Denied',
    color: 'red',
  },
  balance_due_client: {
    label: 'Balance Due Client',
    color: 'blue',
  },
  paid: {
    label: 'Paid',
    color: 'blue',
  },
  written_off: {
    label: 'Written Off',
    color: 'grey',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
