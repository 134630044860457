import React from 'react'
import Fuse from 'fuse.js'
import isEqual from 'lodash/isEqual'
import produce from 'immer'
import size from 'lodash/size'

import { mapToArray } from '../../../utils/functions'
import { useGet } from '../../../hooks/useNewAPI'
import Avatar from '../../../components/Avatar'
import Button from '../../Button'
import Flex from '../../Flex'
import Badge from '../../Badge'

import { FilterDropdown } from './FilterDropdown'
import { PopoverItem } from './PopoverItem'
import { FilterDropdownSearch } from './FilterDropdownSearch'

import { useStore } from '../useStore'

const DEFAULT_SEARCH_KEYS = ['name', 'title', 'description']

const getInitialValue = (data: any, isPolymorphic) => {
  const result = {}

  if (size(data) === 0) return result

  for (const item of data) {
    // TODO: revisit/refactor fix below for:
    // https://linear.app/behave-health/issue/PRO-4574/filter-on-clients-dashboard-gives-oops-error
    if (typeof item === 'string') {
      result[item] = { id: item }
    } else {
      const itemKey = isPolymorphic ? `${item.id}-${item.type}` : item.id
      result[itemKey] = { id: item.id, type: item.type }
    }
  }

  return result
}

export const MultiObjectFilter = (props: any) => {
  const { config, filter, onClear, onUpdate, filterKey } = props

  const initialValue = getInitialValue(filter?.value, config.polymorphic)
  const removeFilter: any = useStore((state: any) => state.removeFilter)

  const [isOpen, setIsOpen] = React.useState(false)
  const [value, setValue]: any = React.useState(initialValue)
  const [searchTerm, setSearchTerm]: any = React.useState('')

  const { data, isLoading } = useGet({
    name: ['filters', config.endpoint, config.apiKey],
    url: config.endpoint,
    options: {
      enabled: !!config.endpoint && isOpen,
    },
  })

  const fuse = React.useMemo(() => {
    return new Fuse(data, { keys: config?.searchKeys || DEFAULT_SEARCH_KEYS })
  }, [data, config?.searchKeys])

  const searchData = React.useMemo(() => {
    if (!searchTerm) return data

    let searchResults: any[] = []
    let fuseResults = fuse.search(searchTerm)

    for (let i = 0; i < fuseResults.length; i++) {
      searchResults.push(fuseResults[i].item)
    }

    return searchResults
  }, [fuse, searchTerm])

  const isAllSelected = size(value) === size(data)

  const clearSearch = () => {
    setSearchTerm('')
  }

  const handleApply = () => {
    const valueArray = mapToArray(value)

    onUpdate?.({
      condition: 'in',
      value: config?.polymorphic ? valueArray : valueArray.map((o) => o.id),
    })

    setIsOpen(false)
  }

  const handleSelectAll = () => {
    setValue((currentValue: any = {}) => {
      return produce(currentValue, (draft: any) => {
        for (const item of data) {
          const itemKey = polymorphic ? `${item.id}-${item.type}` : item.id

          draft[itemKey] = { id: item.id, type: item.type }
        }
      })
    })
  }

  const handleClear = () => {
    setValue({})
    removeFilter(filterKey)
    setIsOpen(false)
  }

  const activeValues = React.useMemo(() => {
    const result: any = []

    if (size(data) === 0 || size(filter?.value) === 0) return result

    // construct map from data array
    const dataMap: any = {}

    for (const item of data) {
      const itemKey = config?.polymorphic ? `${item.id}-${item.type}` : item.id
      dataMap[itemKey] = item
    }

    // construct active ids
    const activeValuesKeys: string[] = []

    for (const item of filter.value) {
      const itemKey = config?.polymorphic ? `${item.id}-${item.type}` : item
      activeValuesKeys.push(itemKey)
    }

    // construct active items array
    for (const key of activeValuesKeys) {
      if (!dataMap?.[key]) continue

      result.push(dataMap[key])
    }

    return result
  }, [config, data, filter])

  React.useEffect(() => {
    setValue(getInitialValue(filter?.value, config.polymorphic))
  }, [filter])

  if (!config) return null

  const { polymorphic, selectDescription, selectTitle = (obj: any) => obj.name, showAvatar } = config

  const filtersCount = size(filter?.value)
  const hasActiveFilters = filtersCount > 0
  const isDataEmpty = data && size(data) === 0
  const isSearchEmpty = searchData && size(searchData) === 0

  return (
    <>
      {/* <FilterCondition condition={filtersCount >= 2 ? 'is either of' : 'is'} /> */}

      <FilterDropdown
        open={isOpen}
        onOpenChange={(newIsOpen: boolean) => {
          // apply filter when dropdown is closed and values are different
          if (!newIsOpen && !isEqual(value, filter?.value)) {
            handleApply()
          }

          if (size(value) === 0) removeFilter(filterKey)

          setIsOpen(newIsOpen)
        }}
        testKey="multi_object_filter_dropdown"
        isLoading={isLoading}
        isEmpty={isDataEmpty}
        isSearchEmpty={isSearchEmpty}
        hasActiveFilters={hasActiveFilters}
        label={
          filtersCount ? (
            <>
              <Badge size={16} children={filtersCount} className="mr-1" /> selected
            </>
          ) : (
            'Select…'
          )
        }
      >
        <FilterDropdownSearch value={searchTerm} onChange={setSearchTerm} onClear={clearSearch} />

        {searchData?.map?.((item: any) => {
          const itemKey = polymorphic ? `${item.id}-${item.type}` : item.id
          const isActive = !!value?.[itemKey]

          return (
            <PopoverItem
              showSeparator
              key={item.id}
              isActive={isActive}
              title={selectTitle?.(item)}
              description={selectDescription?.(item)}
              graphic={showAvatar && <Avatar src={item.avatar} initials={selectTitle?.(item)} size={22} />}
              onClick={() => {
                setValue((currentValue: any = {}) => {
                  return produce(currentValue, (draft: any) => {
                    if (!!draft?.[itemKey]) {
                      delete draft[itemKey]
                      return
                    }

                    draft[itemKey] = { id: item.id, type: item.type }
                  })
                })
              }}
            />
          )
        })}

        <div className="grid gap-2 px-2 py-2 sticky bottom-0 bg-white border-t border-0 border-solid border-divider">
          {!searchTerm && size(data) > 0 && (
            <>
              {!isAllSelected && (
                <Button
                  key="select-all"
                  label={`Select All (${size(data)})`}
                  glyph="multi_select"
                  color="blue"
                  size={100}
                  className="flex-grow-0"
                  onClick={handleSelectAll}
                />
              )}
            </>
          )}

          {size(searchData) > 0 && (
            <Flex gap="0.5rem">
              <Button
                label="Apply"
                glyph="tick_circle"
                color="green"
                type="primary"
                size={100}
                className="flex-grow-3"
                onClick={handleApply}
              />
              <Button
                label="Clear All"
                glyph="cross"
                color="red"
                size={100}
                className="flex-grow-0"
                isDisabled={size(value) === 0}
                onClick={handleClear}
              />
            </Flex>
          )}
        </div>
      </FilterDropdown>
    </>
  )
}
