import React from 'react'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import Notifications from '../../modules/notifications'
import { titleCase } from '../../utils/functions'

import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import FormSection from '../../components/Forms/FormSection'
import Form from '../../components/Forms/Form'
import Overlay from '../../components/Overlay'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Section from '../../components/Section'

import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import StripeCardElement from '../../components/Stripe/StripeCardElement'

import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'

import Link from '../../components/Link'
import Divider from '../../components/Divider'
import ContextShow from '../../components/Forms/ContextShow'
import Input from '../../components/Forms/Input'
import EmailInput from '../../components/Forms/EmailInput'

import { create } from '../../modules/api/requests'
import { useCreate } from '../../hooks/useNewAPI'

let stripePromise
let usedStripeID

const buildStripe = (stripeConnectID) => {
  if (!stripeConnectID) return null
  if (stripePromise && usedStripeID === stripeConnectID) return stripePromise

  usedStripeID = stripeConnectID
  stripePromise = loadStripe(process.env.BH_STRIPE_PUBLIC_KEY, { stripeAccount: stripeConnectID })

  return stripePromise
}

const ORGANIZATION_TYPES = {
  vendor: 'Vendor',
  resource: 'Community Resource',
  provider: 'Provider',
}

const PaymentMethodOverlay = ({ paymentMethod, organization, tenant, onClose, onSuccess }: any) => {
  const closeOverlay = async () => {
    if (onClose) onClose()
  }

  const stripe = useStripe()
  const elements = useElements()

  const [formValid, setFormValid] = React.useState(false)
  const [valid, setValid] = React.useState(false)
  const [error, setError] = React.useState('')
  const [isProcessing, setIsProcessing] = React.useState(false)

  const [setupIntent, setSetupIntent] = React.useState(null)

  const intentUsed = React.useRef(null)
  const setupIntentID = React.useRef(null)

  const [data, setData] = React.useState(null)
  const [reference, setReference] = React.useState(organization)

  // ach data points
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)

  const hipaaCompliant = tenant?.financial_prefs?.hipaa_compliant

  const { mutate: createPaymentMethod } = useCreate({
    name: ['organization', organization.id, 'payment_methods'],
    url: `/payment_methods`,
    invalidate: 'payment_methods',
  })

  const saveCard = async () => {
    setIsProcessing(true)

    try {
      const response = await stripe.confirmCardSetup(setupIntent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          ...(!hipaaCompliant && {
            billing_details: {
              name: data.name || reference?.name,
              email: data.email || reference?.email,
            },
          }),
          metadata: {
            profile_id: organization.id,
            profile_type: organization.type,
            reference_id: reference?.id,
            reference_type: reference?.type,
          },
        },
      })

      // if there are any errors show them
      if (response.error) {
        setError(response.error.message)
        setIsProcessing(false)

        console.error(response.error.message)

        return
      }

      if (response.setupIntent.status !== 'succeeded') {
        // Send Notification
        Notifications.send('The Payment Processor declined your card. Please try again.', 'warning')

        setIsProcessing(false)
        return
      }

      // request is successful

      // cleanup errors
      if (error) setError(null)

      // create Payment Method
      createPaymentMethod({
        name: data?.name,
        service_identifier: response.setupIntent.payment_method,
        category: data?.category,
        profile_id: organization.id,
        profile_type: organization.type,
        reference_category: data?.reference_category,
        reference_id: reference?.id,
        reference_type: reference?.type,
      })

      // run callback for success
      if (onSuccess) await onSuccess()

      // clean up processing dialog
      intentUsed.current = true
      setIsProcessing(false)

      // Send Notification
      Notifications.send('Payment Method Added Successfully', 'positive')

      onClose()
    } catch (error) {
      setIsProcessing(false)
      console.debug(error)
    }
  }

  const connectBankAccount = async () => {
    setIsProcessing(true)

    try {
      // Calling this method will trigger the Connections modal to be displayed.
      const response = await stripe.collectBankAccountForSetup({
        clientSecret: setupIntent.client_secret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: {
              name: data.name || reference?.name,
              email: data.email || reference?.email,
            },
          },
        },
      })

      // support errors
      if (response.error) {
        setError(response.error.message)
        setIsProcessing(false)

        console.error(response.error.message)

        return
      }

      // request is successful

      // cleanup errors
      if (error) setError(null)

      if (response.setupIntent.status === 'requires_payment_method') {
        // Customer canceled the Connections modal. Present them with other
        // payment method type options.
        setIsProcessing(false)
        return
      }

      if (response.setupIntent.status === 'requires_confirmation') {
        // We collected an account - possibly instantly verified, but possibly
        // manually-entered. Display payment method details and mandate text
        // to the customer and confirm the intent once they accept
        // the mandate.

        setShowConfirmDialog(true)

        setIsProcessing(false)
        return
      }

      if (onSuccess) await onSuccess()

      setIsProcessing(false)
    } catch (error) {
      setIsProcessing(false)
      console.debug(error)
    }
  }

  const acceptMandate = async () => {
    setIsProcessing(true)

    try {
      // Calling this method will trigger the Connections modal to be displayed.
      const response = await stripe.confirmUsBankAccountSetup(setupIntent.client_secret)

      // support errors
      if (response.error) {
        setError(response.error.message)
        setIsProcessing(false)

        console.error(response.error.message)

        return
      }

      if (error) setError(null)

      if (response.setupIntent.status === 'requires_payment_method') {
        // Customer canceled the Connections modal. Present them with other
        // payment method type options.
        setIsProcessing(false)
        onClose()

        return
      }

      if (response.setupIntent.status === 'succeeded') {
        // create Payment Method
        createPaymentMethod({
          name: data?.name,
          service_identifier: response.setupIntent.payment_method,
          category: data?.category,
          profile_id: organization.id,
          profile_type: organization.type,
          reference_category: data?.reference_category,
          reference_id: reference?.id,
          reference_type: reference?.type,
        })

        // successful
        intentUsed.current = true
        setIsProcessing(false)

        // Send Notification
        Notifications.send('Payment Method Added Successfully', 'positive')

        onClose()

        return
      }

      if (response.setupIntent.next_action?.type === 'verify_with_microdeposits') {
        // The account needs to be verified via microdeposits.
        // Display a message to consumer with next steps (consumer waits for
        // microdeposits, then enters an amount on a page sent to them via email).
        intentUsed.current = true

        setIsProcessing(false)
        onClose()

        return
      }

      setIsProcessing(false)
      onClose()
    } catch (error) {
      setIsProcessing(false)
      console.debug(error)
    }
  }

  React.useEffect(() => {
    const setupStripe = async () => {
      // create setup intent
      const result = await create(`/apps/stripe/connect/create_setup_intent`, {
        reference_id: organization.id,
        reference_type: organization.type,
      })

      setSetupIntent(result)
      setupIntentID.current = result.id
    }

    setupStripe()

    return () => {
      // cancel setup intent if not used
      if (!intentUsed.current) {
        create(`/apps/stripe/connect/cancel_setup_intent`, { intent: setupIntentID.current })
      }
    }
  }, [])

  return (
    <Overlay showBackdrop position="center" onClose={closeOverlay} maxWidth={35}>
      <Overlay.Header title="Add Payment Method" glyph="dollar" />
      <Overlay.Content>
        <Form initialModel={paymentMethod} onValidationUpdate={setFormValid} onUpdate={setData}>
          <Section>
            <FormSection>
              <Input
                label="Payment Method Name"
                model="name"
                defaultValue={`${organization.name}'s Payment Method`}
                validations={{
                  presence: {
                    message: 'Please add a Payment Method name',
                  },
                }}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section>
            <FormSection>
              <RadioGroup
                label="Who is the payee of this Payment Method?"
                model="reference_category"
                layout="horizontal-dense"
                defaultValue="organization"
              >
                <Radio label="Organization" value="organization" />
                <Radio label="Contact" value="contact" />
              </RadioGroup>

              <ContextShow when="reference_category" is="organization">
                <ObjectSelector
                  isPolymorphic
                  isEditable={false}
                  icon="organizations"
                  label="Organization"
                  model="reference"
                  type="organizations"
                  value={organization}
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => ORGANIZATION_TYPES[data?.category]}
                  onUpdate={(model: any) => {
                    setReference(model.object)
                  }}
                />
              </ContextShow>

              <ContextShow when="reference_category" is="contact">
                <ObjectSelector
                  isPolymorphic
                  icon="contacts"
                  label="Contact"
                  model="reference"
                  type="organization.contacts"
                  description={
                    <>
                      Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                    </>
                  }
                  dependentValue={organization?.id}
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => titleCase(data?.relationship)}
                  validations={{
                    presence: {
                      message: 'Please select a Contact',
                    },
                  }}
                  onUpdate={(model: any) => {
                    setReference(model.object)
                  }}
                />
              </ContextShow>
            </FormSection>
          </Section>

          <Divider />

          <Section>
            <FormSection>
              <RadioGroup label="Payment Method Type" model="category" layout="horizontal-dense" defaultValue="card">
                <Radio label="Card" value="card" />
                <Radio label="Bank Account (ACH)" value="ach" />
              </RadioGroup>

              {data?.category === 'card' && (
                <>
                  <Flex gap="1rem" stretchChildrenX>
                    {data?.reference_category === 'organization' && !hipaaCompliant && (
                      <>
                        <Input
                          label="Name on Card"
                          model="name"
                          validations={{
                            presence: {
                              message: 'Please provide your name',
                            },
                          }}
                          value={reference?.name}
                        />

                        <EmailInput
                          isEditable
                          label="Email"
                          model="email"
                          validations={{
                            presence: {
                              message: 'Please provide a valid email address',
                            },
                          }}
                          value={reference?.email}
                        />
                      </>
                    )}
                  </Flex>

                  <StripeCardElement valid={valid} error={error} setValid={setValid} setError={setError} />

                  <Checkbox
                    value={true}
                    label={`I authorize ${tenant?.name} to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.`}
                    model="did_agree"
                    validations={{
                      presence: {
                        message: 'Please tick the checkbox to save the payment method',
                      },
                    }}
                  />

                  <Button
                    label="Save Payment Method"
                    glyph="check"
                    color="green"
                    type="primary"
                    onClick={saveCard}
                    isLoading={isProcessing}
                    isDisabled={!valid || !formValid || isProcessing}
                  />
                </>
              )}

              {data?.category === 'ach' && (
                <>
                  <Grid gap="1rem">
                    {data?.reference_category === 'organization' && (
                      <>
                        <Input
                          label="Account Holder Name"
                          model="name"
                          value={reference?.name}
                          validations={{
                            presence: {
                              message: 'Please provide your name',
                            },
                          }}
                        />

                        <EmailInput
                          isEditable
                          label="Account Holder Email"
                          model="email"
                          value={reference?.email}
                          validations={{
                            presence: {
                              message: 'Please provide a valid email address',
                            },
                          }}
                        />
                      </>
                    )}

                    <Checkbox
                      value={true}
                      label={`I authorize ${tenant?.name} to send instructions to the financial institution that I use to take payments from my bank account in accordance with the terms of my agreement with you.`}
                      model="did_agree"
                      validations={{
                        checked: {
                          message: 'Please accept the Terms',
                        },
                      }}
                    />
                  </Grid>

                  <Button
                    type="primary"
                    label="Connect Bank Account"
                    onClick={connectBankAccount}
                    isLoading={isProcessing}
                    isDisabled={!formValid || isProcessing}
                  />

                  {showConfirmDialog && (
                    <ConfirmDialog
                      setOpen
                      yesLabel="I Accept"
                      onYes={acceptMandate}
                      message={
                        <>
                          <p>
                            By clicking 'I Accept', you authorize <b>{tenant.name}</b> to debit the bank account selected before for any
                            amount owed for charges arising from your use of <b>{tenant.name}’</b> services and/or purchase of products from{' '}
                            <b>{tenant.name}</b>, pursuant to <b>{tenant.name}’</b> website and terms, until this authorization is revoked.
                            You may amend or cancel this authorization at any time by providing notice to <b>{tenant.name}</b> with 30
                            (thirty) days notice.
                          </p>
                          <p>
                            If you use <b>{tenant.name}’</b> services or purchase additional products periodically pursuant to{' '}
                            <b>{tenant.name}’</b> terms, you authorize <b>{tenant.name}</b> to debit your bank account periodically.
                            Payments that fall outside of the regular debits authorized above will only be debited after your authorization
                            is obtained.
                          </p>
                        </>
                      }
                    />
                  )}
                </>
              )}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    </Overlay>
  )
}

const OrganizationPaymentMethodOverlay = ({ stripeConnectID, ...rest }: any) => (
  <Elements stripe={buildStripe(stripeConnectID)}>
    <PaymentMethodOverlay {...rest} />
  </Elements>
)

export default OrganizationPaymentMethodOverlay
