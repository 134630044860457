import React from 'react'
import size from 'lodash/size'

import { usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'
import DataList from '../../../components/DataList'
import Status from '../../../components/Status'
import TestResultStatus from '../../../components/Statuses/TestResultStatus'

export const UATestInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item label="Status" value={<TestResultStatus status={data.status} />} />

      {data.client && <DataList.Item label="Client" value={data.client.name} avatar={data.client.avatar} />}

      {data.status !== 'refused' && (
        <DataList.Item
          label="Result"
          value={
            <>
              {data.result === 'positive' ? (
                <Status color="red" label="Positive" />
              ) : data.result === 'negative' ? (
                <Status color="green" label="Negative" />
              ) : data.result === 'not_available' ? (
                <Status color="grey" label="N/A" />
              ) : null}
            </>
          }
        />
      )}
      {data.result === 'positive' && (
        <DataList.Item
          label="Positive Results"
          value={size(data.test_results) >= 1 && data.test_results.map((result) => result.name).join(', ')}
        />
      )}
      <DataList.Item label="Collection Date" value={usDateTime(data.collected_at, timezone)} />
    </DataList>
  )
}
