import React from 'react'
import { connect } from 'react-redux'
import startCase from 'lodash/startCase'

import { age, titleCase } from '../../../utils/functions'

import DeleteDialog from '../../Dialogs/DeleteDialog'
import Overlay from '../../Overlay'
import Section from '../../Section'

import Attachments from '../../Forms/Attachments'
import Button from '../../Button'
import DateTimeInput from '../../Forms/DateTimeInput'
import Divider from '../../Divider'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class CommunicationLog extends OverlayBase {
  renderHeader = () => <Overlay.Header title="Communication Log" icon="communications" />

  renderContent = () => {
    const { $editable, $new, params } = this.state
    const { reference, record, timezone, loading } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          timezone={timezone}
          isEditable={$editable}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
          linked={
            $new &&
            reference?.id && {
              customer_id: reference?.id,
              customer_type: reference?.type,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              {$new && !reference?.id && (
                <>
                  <ObjectSelector
                    isPolymorphic
                    model="customer"
                    label="Client"
                    type="clients"
                    icon="clients"
                    showDescription={false}
                    selectTitle={(data: any) => data.name}
                    selectDescription={(data: any) => `${startCase(data.sex) || '–'}, ${age(data.dob)} y/o, #${data.behave_id}`}
                    validations={{
                      presence: {
                        message: 'Please select a client',
                      },
                    }}
                  />
                  <ObjectSelector
                    isPolymorphic
                    model="contact"
                    label="Contact"
                    type="client.contactable"
                    dependentValue={reference?.id}
                    disableUnless="customer_id"
                    selectTitle={(data) => data?.name}
                    selectDescription={(data) => titleCase(data?.type)}
                    icon="contacts"
                    validations={{
                      presence: {
                        message: 'Please select a contact person',
                      },
                    }}
                  />
                </>
              )}

              {reference?.type === 'resident' && (
                <ObjectSelector
                  isPolymorphic
                  model="contact"
                  label="Contact"
                  type="client.contactable"
                  dependentValue={reference?.id}
                  selectTitle={(data) => data?.name}
                  selectDescription={(data) => titleCase(data?.type)}
                  icon="contacts"
                  validations={{
                    presence: {
                      message: 'Please select a contact person',
                    },
                  }}
                />
              )}

              {reference?.type === 'organization' && (
                <ObjectSelector
                  isPolymorphic
                  model="contact"
                  label="Contact"
                  type="organization.contacts"
                  dependentValue={reference?.id}
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => data.role}
                  icon="contacts"
                  validations={{
                    presence: {
                      message: 'Please select a contact person',
                    },
                  }}
                />
              )}

              <DateTimeInput
                defaultToNow
                model="contacted_at"
                label="Contact Date and Time"
                validations={{
                  presence: {
                    message: 'Please enter the date and time of contact',
                  },
                }}
              />

              <RadioGroup
                model="category"
                label="Contact Method"
                layout="vertical-dense"
                validations={{
                  presence: {
                    message: 'Please select the method of contact',
                  },
                }}
              >
                <Radio label="Phone" value="phone" />
                <Radio label="Text" value="text_message" />
                <Radio label="Email" value="email" />
                <Radio label="Meeting" value="meeting" />
                <Radio label="Web Meeting" value="web" />
                <Radio label="Other" value="other" />
              </RadioGroup>

              <Textarea
                useQuickText
                model="reason"
                label="Notes"
                validations={{
                  presence: {
                    message: 'Please write a note for this communication log',
                  },
                }}
              />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this communication log">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button glyph="edit" label="Edit" type="default" isDisabled={this.props.loading} onClick={this.edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Communication Log?"
              message="Are you sure you want to delete this communication log? This action cannot be undone."
              onYes={this.delete}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={this.props.loading} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'communications')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'communications')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(CommunicationLog))
