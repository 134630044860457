import React from 'react'
import { connect } from 'react-redux'

import { apiUpdate } from '../../../../modules/api'
import { getFirstValueFromMap } from '../../../../utils/functions'
import { PAYMENT_METHODS } from '../../../../utils/constants'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import Button from '../../../Button'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import Chotomate from '../../../Chotomate'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Overlay from '../../../Overlay'
import Section from '../../../Section'

import { OverlayBase, defaultMapDispatchToProps } from '../OverlayBase'

class PaymentMethods extends OverlayBase {
  save = async () => {
    const model = this.form.current.getFormValue()

    await apiUpdate({
      name: 'financial_plan',
      url: `/financial_plans/${model.id}`,
      params: {
        payment_methods: model.payment_methods,
      },
    })

    this.close()
  }

  renderHeader = () => <Overlay.Header icon="financials" title="Payment Methods" />

  renderContent = () => {
    const { tenant } = this.props
    const noStripeConnection = !tenant?.is_stripe_connected

    return (
      <Overlay.Content>
        <Chotomate ready name="payment_methods_overlay" />

        <Form
          isEditable
          getForm={this.form}
          initialModel={this.props.data}
          onValidationUpdate={this.onValidationUpdate}
          linked={{ id: this.props.data.id }}
        >
          <Section>
            <FormSection>
              <CheckboxGroup label="Payment Methods" layout="vertical-dense">
                {Object.entries(PAYMENT_METHODS).map(([key, value]) => (
                  <Checkbox
                    key={key}
                    model={`payment_methods.${key}`}
                    label={value.label}
                    glyph={value.glyph}
                    description={
                      key === 'online' && noStripeConnection
                        ? 'Set up Online Payments from Settings to activate this option'
                        : value.description
                    }
                    isDisabled={key === 'online' && noStripeConnection}
                  />
                ))}
              </CheckboxGroup>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    return (
      <Overlay.Footer>
        <Button
          label="Save"
          glyph="check"
          type="primary"
          color="green"
          flex="100 1 auto"
          onClick={this.save}
          isDisabled={this.state.isInvalid}
          isLoading={this.props.loading}
        />
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state: any) => ({
  tenant: state.me?.tenant,
  data: getFirstValueFromMap(state.data.financial_plan?.data),
  loading: state.data.financial_plan?.loading,
  online: state.common?.global?.online,
  idle: state.common?.global.idle,
})

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(PaymentMethods))
