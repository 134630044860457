import React from 'react'

import GlobalTags from '../../../Table/Cells/GlobalTags'
import SmartFlags from '../../../Table/Cells/SmartFlags'
import Status from '../../../Status'
import Table from '../../../Table/Table'
import TableCell from '../../../Table/TableCell'
import TableFilter from '../../../Table/TableFilter'

import { AGREEMENTS } from '../../../../utils/constants'
import withSettings from '../../../../hocs/withSettings'

const STATUSES: any = {
  active: 'Active',
  archived: 'Archived',
}

const LEVELS: any = {
  public_template: 'Public',
  behave_template: 'Behave',
}

const columns = (to, onClick, mainLinkAs) => [
  {
    id: 'name',
    accessor: 'data.name',
    Header: 'Name',
    width: 240,
    Cell: ({ cell, row }: any) => {
      return <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} onClick={() => onClick?.(row.original)} label={cell?.value} />
    },
  },
  {
    width: 110,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <TableCell value={STATUSES[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'active', value: 'active' },
      { label: 'archived', value: 'archived' },
    ],
  },
  {
    width: 240,
    Header: 'Tags',
    accessor: 'global_tags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <GlobalTags
        // queryKey={queryKey} // TODO
        tags={row.original.global_tags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 240,
    Header: 'Smart Flags',
    accessor: 'smart_flags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <SmartFlags
        // queryKey={queryKey}
        value={row.original.smart_flags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 140,
    accessor: 'level',
    Header: 'Access',
    Cell: ({ value }: any) => <TableCell value={LEVELS[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'Public', value: 'public_template' },
      { label: 'Behave', value: 'behave_template' },
    ],
  },
  {
    id: 'category',
    accessor: 'data.category',
    Header: 'Category',
    width: 160,
    Cell: ({ value }: any) => AGREEMENTS?.[value] || '–',
  },
  {
    id: 'should_sign',
    accessor: 'data.should_sign',
    Header: 'Signature',
    width: 140,
    Cell: ({ value }: any) => {
      if (value === true) return <Status label="Required" color="green" />
      return <Status label="Not Required" color="gray" />
    },
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  title?: string
  titleAfter?: React.ReactNode
  to?: Function
}

const AgreementTemplatesTable = (props: Props) => {
  const { batchActionsConfig, data, isLoading, localStorageKey, onClick, title, titleAfter, to, ...rest } = props

  return (
    <Table
      data={data}
      columns={columns(to, onClick, props.mainLinkAs)}
      title={title}
      titleAfter={titleAfter}
      icon="legal_agreement_alt"
      isLoading={isLoading}
      emptyDescription="No agreement templates have been added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

AgreementTemplatesTable.defaultProps = {
  title: 'Agreement Templates',
  localStorageKey: 'agreement_internal_templates',
}

export default withSettings(AgreementTemplatesTable)
