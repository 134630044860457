import React from 'react'
import { opacify } from 'polished'

import { COLORS } from '../../theme'
import { isURL, isBase64Image, usDate, usTime } from '../../utils/functions'

import withSettings from '../../hocs/withSettings'

import Flex from '../Flex'
import Button from '../Button'
import Card from '../Card'
import CardHeader from '../CardHeader'
import CardMeta from '../CardMeta'
import CardSubtitle from '../CardSubtitle'
import Field from '../Forms/Field'
import Glyph from '../Glyph'
import Grid from '../Grid'
import SignatureCanvas from './SignatureCanvas'
import PinSignature from './PinSignature'
import Text from '../Typography/Text'

import { withFormContext } from './context'
import { useFormField } from './hooks/useFormField'
import snakeCase from 'lodash/snakeCase'

const SignaturePad = (props: any) => {
  const {
    form,
    model,
    allowPin,
    checkSignatureByTime,
    isEditable,
    isUpdateable,
    title,
    showSignature,
    showHeader,
    showFooter,
    showSubtitle,
    showSignedBy,
    person,
    label,
    timezone,
    width,
    height,
    description,
    onUpdate,
    testKey,
    ...others
  } = props

  const { initialValue, formActions } = useFormField({
    model: model,
    form: form,
    isValid: true,
    defaultEmptyValue: null,
    afterReset: () => {
      setData(form?.getInitialInputFieldValue(model))
    },
  })

  const [view, setView] = React.useState('manual')
  const [data, setData]: any = React.useState(initialValue)
  const [isUpdated, setIsUpdated] = React.useState(false)

  const signedAt = React.useRef(props.signedAt || props.form?.getInitialInputFieldValue(props.signedAtModel))

  React.useEffect(() => {
    if (!isEditable && isBase64Image(data)) setIsUpdated(true)
  }, [isEditable])

  React.useEffect(() => {
    if (onUpdate) onUpdate({ model: `${model}_data`, value: data })
  }, [data])

  const isSigned = isURL(data) || isUpdated

  return (
    <Field label={label || title} description={description} isEditable={isEditable} {...others}>
      {!isEditable && (
        <Card testKey={`${snakeCase(label)}`}>
          {isSigned && (
            <>
              {signedAt.current && (
                <CardHeader
                  paddingY="0.4rem"
                  css={{ borderRadius: 0, background: COLORS.white, boxShadow: `0 0 0 1px ${opacify(-0.05, COLORS.divider)}` }}
                >
                  {showSubtitle && (
                    <CardMeta>
                      {showSignedBy && <Text testKey="signed_by_details" glyph="signature" description={`Signed by ${person?.name}`} />}
                      <Text testKey="signed_date_details" glyph="date" description={usDate(signedAt.current, timezone)} />
                      <Text testKey="signed_time_details" glyph="time" description={usTime(signedAt.current, timezone)} />
                    </CardMeta>
                  )}
                </CardHeader>
              )}

              <div css={styles.signature}>
                <img src={data} alt="signature" />
              </div>
            </>
          )}

          {!isSigned && (
            <CardHeader graphic={<Glyph glyph="signature" size={18} />}>
              {/* <CardTitle title={label || title} /> */}
              <CardSubtitle subtitle="No signature added yet…" />
            </CardHeader>
          )}
        </Card>
      )}

      {isEditable && (
        <Card testKey={testKey} baseline="2.5rem" paddingY="0.4rem">
          {isSigned && (
            <>
              {signedAt.current && (
                <CardHeader
                  paddingY="0.4rem"
                  css={{ borderRadius: 0, background: COLORS.white, boxShadow: `0 0 0 1px ${opacify(-0.05, COLORS.divider)}` }}
                >
                  {showSubtitle && (
                    <CardMeta>
                      {showSignedBy && <Text glyph="signature" description={`Signed by ${person?.name}`} />}
                      <Text testKey="signed_date_details" glyph="date" description={usDate(signedAt.current, timezone)} />
                      <Text testKey="signed_time_details" glyph="time" description={usTime(signedAt.current, timezone)} />
                    </CardMeta>
                  )}
                </CardHeader>
              )}

              <div css={styles.signature}>
                <img src={data} alt="signature" />
              </div>

              <Grid css={styles.footer} autoFlow="column">
                <Button
                  fullWidth
                  testKey="clear_signature_button"
                  type="minimal"
                  color="red"
                  glyph="cross"
                  label="Clear"
                  onClick={() => {
                    setData(null)
                    setIsUpdated(false)
                  }}
                />
              </Grid>
            </>
          )}

          {!isSigned && (
            <>
              {allowPin && (
                <Flex css={styles.tabs}>
                  <Button
                    label="PIN Signature"
                    type="minimal"
                    onClick={() => setView('pin')}
                    className={'tab ' + (view === 'pin' && 'is-active')}
                  />
                  <Button
                    label="Manual Signature"
                    type="minimal"
                    onClick={() => setView('manual')}
                    className={'tab ' + (view === 'manual' && 'is-active')}
                  />
                </Flex>
              )}

              {view === 'pin' && <PinSignature model={props.model} onUpdate={onUpdate} />}
              {view === 'manual' && (
                <SignatureCanvas
                  model={props.model}
                  width={width}
                  height={height}
                  validations={props.validations}
                  onValidationUpdate={formActions.setValidity}
                  onUpdate={(state: any) => {
                    setData(state.value)
                  }}
                />
              )}
            </>
          )}
        </Card>
      )}
    </Field>
  )
}

const styles = {
  content: {
    background: '#fdfdfe',
    boxShadow: `0 -1px 0 ${COLORS.divider}, 0 1px 0 ${COLORS.divider}`,

    '&.is-signed': {
      boxShadow: 'none',
      background: 'none',
    },
  },

  tabs: {
    display: 'flex',

    '.tab': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 1 auto',
      minHeight: '2.75rem',
      transition: 'all 100ms',
      background: 'none !important',
      border: 'none',
      borderRadius: 0,

      '&:hover': {
        transform: 'translateY(-2px)',
      },

      cursor: 'pointer',
      color: COLORS.link,
      textAlign: 'center',
      fontSize: 15,
      textDecoration: 'none',
      fontWeight: 500,

      '&.is-active': {
        boxShadow: `inset 0 -2px 0 ${COLORS.link}`,

        '&:hover': {
          transform: 'translateY(0)',
        },
      },
    },
  },

  photo: {
    padding: '0.5rem !important',

    '.DnDUploader': {
      margin: 0,
    },
  },

  signature: {
    padding: '1rem 1rem 1rem 2rem',

    img: {
      display: 'block',
      width: '100%',
      height: 'auto',
      maxWidth: 400,
      maxHeight: 200,
    },
  },

  footer: {
    '.Button': {
      boxShadow: '1px 0 0 @separator_color',
      borderRadius: 0,
    },
  },
}

SignaturePad.defaultProps = {
  allowPin: false,
  checkSignatureByTime: true,
  showHeader: true,
  showSignature: true,
  showFooter: true,
  showSubtitle: true,
  showSignedBy: true,
  isEditable: true,
  isUpdateable: false,
}

export default withSettings(withFormContext(SignaturePad))
