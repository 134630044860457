import React from 'react'

import { COLORS } from '../theme'

import Glyph from './Glyph'
import Dropdown from './Dropdown'

type Props = {
  actions: React.ReactNode
}

const CardActions: React.FC<Props> = ({ actions }) => {
  return (
    <Dropdown
      css={styles.root}
      trigger={
        <button type="button" data-test="dropdown" css={styles.button}>
          <Glyph glyph="more_vertical" size={16} />
        </button>
      }
    >
      {actions}
    </Dropdown>
  )
}

const styles = {
  root: {
    alignSelf: 'stretch',
  },

  button: {
    width: `2rem !important`,
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    borderLeft: `1px solid ${COLORS.divider}`,
    background: COLORS.transparent,
    outline: 'none',

    '&:hover': {
      background: COLORS.hover,
      cursor: 'pointer',
      overflow: 'hidden',
    },

    '&.is-not-default': {
      borderRadius: '0 7px 7px 0',
      boxShadow: `-0.5px 0 0 ${COLORS.divider}`,
    },
  },
}

export default CardActions
