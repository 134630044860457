import React from 'react'
import { transparentize, tint } from 'polished'

import { COLORS } from '../theme'
import { CARD_CSS_VARS } from './Card'

type Props = {
  alignToBaseline?: boolean
  padding?: string | number
  showDivider?: boolean
} & React.HTMLAttributes<any>

const CardContent: React.FC<Props> = ({ showDivider = true, children, alignToBaseline, padding, className }) => {
  return (
    <div css={styles({ alignToBaseline, showDivider, padding })} className={className}>
      {children}
    </div>
  )
}

const styles = ({ alignToBaseline, padding, showDivider }: any) => ({
  borderTop: showDivider ? `1px solid ${COLORS.divider}` : 'none',
  padding: padding || `var(${CARD_CSS_VARS.PADDING_Y}) var(${CARD_CSS_VARS.PADDING_X})`,
  paddingLeft: alignToBaseline ? `var(${CARD_CSS_VARS.BASELINE})` : undefined,
  background: transparentize(0.04, tint(0.5, COLORS.lightBackground)),

  '&:only-child': {
    boxShadow: 'none',
  },
})

export default CardContent
