import React from 'react'
import { useMedia } from 'use-media'
import clsx from 'clsx'

import { COLORS, BREAKPOINT } from '../theme'

const PageGrid = ({ children, breakpoint, scroll, columns, className }) => {
  const isBreakpointActive = useMedia({ minWidth: BREAKPOINT[breakpoint] })

  const classNames = clsx({
    'is-scrolling': scroll,
    'has-breakpoint': isBreakpointActive,
    [className]: className,
  })

  return (
    <div css={[styles, { gridTemplateColumns: columns }]} className={classNames}>
      {children}
    </div>
  )
}

const styles = {
  background: COLORS.lightBackground,
  position: 'relative',
  zIndex: 1,
  overflowY: 'auto',

  '&.is-scrolling': {
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
  },

  '&.has-breakpoint': {
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
    gridTemplateRows: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',

    '& > *': {
      maxHeight: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
    },
  },
}

PageGrid.defaultProps = {
  breakpoint: 1,
  columns: 'min-content 1fr',
}

export default PageGrid
