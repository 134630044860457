import Flex from './Flex'
import FlexItem from './FlexItem'

import Grid from './Grid'
import GridItem from './GridItem'

const Layout = {}

Layout.Vertical = Grid
Layout.Vertical.displayName = 'LayoutVertical'

Layout.VerticalItem = GridItem
Layout.VerticalItem.displayName = 'LayoutVerticalItem'

Layout.Horizontal = Flex
Layout.Horizontal.displayName = 'LayoutHorizontal'

Layout.HorizontalItem = FlexItem
Layout.HorizontalItem.displayName = 'LayoutHorizontalItem'

export default Layout
