import React from 'react'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { apiCreate, apiUpdate } from '../../../modules/api'

import Alert from '../../Alert'
import Button from '../../Button'
import FormSection from '../../Forms/FormSection'
import Form from '../../Forms/Form'
import Overlay from '../../Overlay'
import Flex from '../../Flex'
import Section from '../../Section'
import Grid from '../../Grid'
import StripeCardElement from '../StripeCardElement'
import Tabs from '../../Tabs'
import TabPanels from '../../TabPanels'
import TabPanel from '../../TabPanel'
import TabList from '../../TabList'
import Tab from '../../Tab'
import ConfirmDialog from '../../Dialogs/ConfirmDialog'

import Input from '../../Forms/Input'
import EmailInput from '../../Forms/EmailInput'
import useStore from '../../../modules/store'

const stripePromise = loadStripe(process.env.BH_STRIPE_BEHAVE_PUBLIC_KEY)

export const StripeForm = ({ details, onClose, onSuccess }: any) => {
  const stripe = useStripe()
  const elements = useElements()

  const updateStore = useStore((state) => state.update)
  const tenant = useStore((state) => state.tenant)

  const [valid, setValid] = React.useState(false)
  const [error, setError] = React.useState('')
  const [processing, setProcessing] = React.useState(false)

  const [setupIntent, setSetupIntent] = React.useState(null)

  const [data, setData] = React.useState(null)
  const [dataValid, setDataValid] = React.useState(true)

  // ach data points
  const [achData, setACHData] = React.useState(null)
  const [achDataValid, setACHDataValid] = React.useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)

  const person = details || { name: `${tenant?.first_name} ${tenant?.last_name}`, email: tenant?.email }

  const connectBankAccount = async () => {
    setProcessing(true)

    if (!stripe || !achData) return
    let intent = null

    try {
      const result = await apiCreate({
        url: `/apps/stripe/create_setup_intent`,
        notify: false,
      })

      // save setup intent
      intent = result.data
      setSetupIntent(intent)

      // Calling this method will trigger the Connections modal to be displayed.
      const response = await stripe.collectBankAccountForSetup({
        clientSecret: intent.client_secret,
        params: {
          payment_method_type: 'us_bank_account',
          payment_method_data: {
            billing_details: {
              name: achData.name,
              email: achData.email,
            },
          },
        },
      })

      // support errors
      if (response.error) {
        setError(response.error.message)
        setProcessing(false)

        console.error(response.error.message)

        return
      }

      if (error) setError(null)

      if (response.setupIntent.status === 'requires_payment_method') {
        // Customer canceled the Connections modal. Present them with other
        // payment method type options.
        setProcessing(false)
        return
      }

      if (response.setupIntent.status === 'requires_confirmation') {
        // We collected an account - possibly instantly verified, but possibly
        // manually-entered. Display payment method details and mandate text
        // to the customer and confirm the intent once they accept
        // the mandate.

        setShowConfirmDialog(true)

        setProcessing(false)
        return
      }

      if (onSuccess) await onSuccess()

      setProcessing(false)
      // onClose()
    } catch (error) {
      try {
        apiCreate({
          url: `/apps/stripe/connect/cancel_setup_intent`,
          params: { intent: intent.id },
          notify: false,
        })
      } catch (error) {
        console.debug(error)
      }

      setProcessing(false)
      console.debug(error)
    }
  }

  const acceptMandate = async () => {
    setProcessing(true)

    if (!stripe) return

    try {
      // Calling this method will trigger the Connections modal to be displayed.
      const response = await stripe.confirmUsBankAccountSetup(setupIntent.client_secret)

      // support errors
      if (response.error) {
        setError(response.error.message)
        setProcessing(false)

        console.error(response.error.message)

        return
      }

      if (error) setError(null)

      if (response.setupIntent.status === 'requires_payment_method') {
        // Customer canceled the Connections modal. Present them with other
        // payment method type options.
        setProcessing(false)
        onClose()

        return
      }

      if (response.setupIntent.status === 'succeeded') {
        // successful
        setProcessing(false)
        onClose()

        if (onSuccess) await onSuccess()

        return
      }

      if (response.setupIntent.next_action?.type === 'verify_with_microdeposits') {
        // The account needs to be verified via microdeposits.
        // Display a message to consumer with next steps (consumer waits for
        // microdeposits, then enters an amount on a page sent to them via email).

        setProcessing(false)
        onClose()

        return
      }

      setProcessing(false)
      onClose()
    } catch (error) {
      setProcessing(false)
      console.debug(error)
    }
  }

  const cancelSetupIntent = async () => {
    if (!setupIntent) return

    setProcessing(true)

    try {
      apiCreate({
        url: `/apps/stripe/connect/cancel_setup_intent`,
        params: { intent: setupIntent.id },
        notify: false,
      })
    } catch (error) {
      console.debug(error)
    }

    setProcessing(false)
  }

  const save = async () => {
    setProcessing(true)

    if (!stripe || !elements) return
    let setupIntent = null

    try {
      const result = await apiCreate({
        url: `/apps/stripe/create_setup_intent`,
        notify: false,
      })

      setupIntent = result.data

      const response = await stripe.confirmCardSetup(setupIntent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: data,
        },
      })

      // support errors
      if (response.error) {
        setError(response.error.message)
        setProcessing(false)
        return
      }

      if (error) setError(null)

      const tenant = await apiUpdate({
        url: '/me/tenant',
        params: { stripe_default_payment_id: response.setupIntent?.payment_method },
        notify: false,
      })

      // update zustand
      updateStore({ tenant: tenant?.data?.data })

      if (onSuccess) await onSuccess()

      setProcessing(false)
      onClose()
    } catch (error) {
      setProcessing(false)
      console.debug(error)

      try {
        apiCreate({
          url: `/apps/stripe/cancel_setup_intent`,
          params: { intent: setupIntent.id },
          notify: false,
        })
      } catch (error) {
        console.debug(error)
      }
    }
  }

  return (
    <FormSection css={{ marginTop: 0 }}>
      <Tabs defaultTab="ach">
        <TabList className="!mb-4">
          <Tab label="ACH (Bank Account)" name="ach" />
          <Tab label="Card" name="card" />
        </TabList>

        <TabPanels>
          <TabPanel name="card">
            <FormSection>
              <Form initialModel={{ name: person?.name, email: person?.email }} onValidationUpdate={setDataValid} onUpdate={setData}>
                <Flex gap="1rem" stretchChildrenX>
                  <Input
                    label="Name on Card"
                    model="name"
                    validations={{
                      presence: {
                        message: 'Please provide your name',
                      },
                    }}
                  />

                  <EmailInput
                    isEditable
                    label="Email"
                    model="email"
                    validations={{
                      presence: {
                        message: 'Please provide a valid email address',
                      },
                    }}
                  />
                </Flex>
              </Form>

              <StripeCardElement valid={valid} error={error} setValid={setValid} setError={setError} />

              <Button
                label="Save Payment Method"
                glyph="check"
                color="green"
                type="primary"
                onClick={save}
                isDisabled={!valid || processing || !dataValid}
                isLoading={processing}
              />
            </FormSection>
          </TabPanel>

          <TabPanel name="ach">
            <FormSection>
              <Form initialModel={{ name: person?.name, email: person?.email }} onValidationUpdate={setACHDataValid} onUpdate={setACHData}>
                <Grid gap="1rem">
                  <Input
                    label="Account Holder Name"
                    model="name"
                    validations={{
                      presence: {
                        message: 'Please provide your name',
                      },
                    }}
                  />

                  <EmailInput
                    isEditable
                    label="Account Holder Email"
                    model="email"
                    validations={{
                      presence: {
                        message: 'Please provide a valid email address',
                      },
                    }}
                  />
                </Grid>
              </Form>

              {error && (
                <Alert type="negative" glyph="circle_error" className="!mt-4">
                  {error}
                </Alert>
              )}

              <Button
                type="primary"
                label="Connect Bank Account"
                onClick={connectBankAccount}
                isDisabled={!achDataValid || processing}
                isLoading={processing}
              />

              {showConfirmDialog && (
                <ConfirmDialog
                  setOpen
                  yesLabel="I Accept"
                  onYes={acceptMandate}
                  onNo={cancelSetupIntent}
                  message={
                    <>
                      <p>
                        By clicking 'I Accept', you authorize <b>Behave Health Corp.</b> to debit the bank account selected before for any
                        amount owed for charges arising from your use of <b>Behave Health Corp.'</b>s services and/or purchase of products
                        from <b>Behave Health Corp.</b>, pursuant to <b>Behave Health Corp.'</b>s website and terms, until this
                        authorization is revoked. You may amend or cancel this authorization at any time by providing notice to{' '}
                        <b>Behave Health Corp.</b> with 30 (thirty) days notice.
                      </p>
                      <p>
                        If you use <b>Behave Health Corp.'</b>s services or purchase additional products periodically pursuant to{' '}
                        <b>Behave Health Corp.'</b>s terms, you authorize <b>Behave Health Corp.</b> to debit your bank account
                        periodically. Payments that fall outside of the regular debits authorized above will only be debited after your
                        authorization is obtained.
                      </p>
                    </>
                  }
                />
              )}
            </FormSection>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FormSection>
  )
}

const StripeAddCardOverlay = ({ details, onClose, onSuccess }: any) => {
  const closeOverlay = async () => {
    if (onClose) onClose()
  }

  return (
    <Overlay showBackdrop position="center" onClose={closeOverlay} maxWidth={35}>
      <Overlay.Header title="Add Payment Method" glyph="dollar" />
      <Overlay.Content>
        <Section css={{ marginTop: 0 }}>
          <Elements stripe={stripePromise}>
            <StripeForm details={details} onClose={onClose} onSuccess={onSuccess} />
          </Elements>
        </Section>
      </Overlay.Content>
    </Overlay>
  )
}

export default StripeAddCardOverlay
