import React from 'react'
import { useLocation } from 'react-router-dom'

import Status from '../Status'
import { Text } from '../Typography'

import { fullname } from '../../utils/functions'
import { usDate, usTime } from '../../utils/functions'
import useActivePath from '../../hooks/useActivePath'
import withSettings from '../../hocs/withSettings'

import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'

type Props = {
  communication: any
  location: any
  timezone: any
  to: any
}

const CommunicationLog = ({ communication, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={communication.reason} />
      <CardMeta>
        <Status color="orange" label={`via ${communication.category}`} />
        <Text glyph="user_neutral" label="Staff: " description={fullname(communication.employee)} />
        <Text glyph="user_neutral" label="Contact: " description={fullname(communication.contact)} />
        <Text glyph="date" label="Date: " description={usDate(communication.contacted_at, timezone)} />
        <Text glyph="time" label="Time: " description={usTime(communication.contacted_at, timezone)} />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(CommunicationLog)
