import React from 'react'
import size from 'lodash/size'

const CalendarEventsList = ({ className, events, renderEvent = () => {} }: any) => {
  const isEmpty = size(events) === 0

  if (isEmpty) return null

  return (
    <div className={className}>
      {events.map((event: any) => (
        <div key={event.id} css={styles}>
          {renderEvent(event)}
        </div>
      ))}
    </div>
  )
}

const styles = {
  margin: '2px 0',
}

export default CalendarEventsList
