import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import NumberInput from '../../components/Forms/NumberInput'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import Select from '../../components/Forms/Select'
import TableArrayForm from '../../components/Forms/TableArrayForm'
import Textarea from '../../components/Forms/Textarea'

import { SectionCard } from '../../components/SectionCard'

const RootInvoiceSettingsOverlay = (props: any) => {
  const { cancel, close, edit, form, initialModel, isEditable, isInvalid, isLoading, isNew, isSaving, onValidationUpdate, save } =
    useOverlay({
      name: 'invoices',
      endpoint: '/invoices',
      options: props,
    })

  const { timezone, isBehave } = useSettings()

  return (
    <Overlay onClose={close} position="right" showBackdrop={isEditable}>
      <Overlay.Header title="🚧 Invoice Settings" icon="settings" />

      <Overlay.Content className="px-5 py-5">
        <Form
          isCompact
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
        >
          <FormSection maxWidth="100%">
            <div className="grid gap-5">
              <InvoiceSettingsSections />
            </div>
          </FormSection>
        </Form>
      </Overlay.Content>

      {isBehave && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 auto"
              />

              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button glyph="edit" label="Edit Invoice Settings" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const InvoiceSettingsSections = () => {
  return (
    <>
      {false && (
        <SectionCard title="Payments" className="!p-3">
          <div className="grid gap-[6px] items-start content-start">
            {/* <Checkbox
            label="Auto-Collect Payment"
            model="use_auto_collection"
            trueIcon="check"
            falseIcon="empty_checkbox"
            falseStyle="faded"
          />

          <ContextShow when="use_auto_collection" is={true}>
            <div className="-mt-3 pl-4">
              <ObjectSelector withHover={false} icon="online_card_payment" label="Payment Method" type="" />
            </div>
          </ContextShow> */}

            <Checkbox
              label="Allow Partial Collection"
              model="use_partial_collection"
              trueIcon="check"
              falseIcon="empty_checkbox"
              falseStyle="faded"
            />
          </div>
        </SectionCard>
      )}

      <SectionCard title="Reminders">
        <CheckboxGroup label={null} withHover={false} layout="vertical" trueIcon="check" falseIcon="empty_checkbox" falseStyle="faded">
          <Checkbox label="Enable Reminders Before Due Date" model="should_send_reminders_before" />
          <ContextShow when="should_send_reminders_before" is={true}>
            <div className="mt-2 !mb-4">
              <TableArrayForm
                title="Reminders Before Due Date"
                name="Reminders"
                model="send_reminders_to_before"
                inputs={REMINDERS_BEFORE_INPUTS}
              />
            </div>
          </ContextShow>

          <Checkbox label="Enable Reminders After Due Date" model="should_send_reminders_after" />
          <ContextShow when="should_send_reminders_after" is={true}>
            <div className="mt-2">
              <TableArrayForm
                title="Reminders After Due Date"
                name="Reminders"
                model="send_reminders_to_after"
                inputs={REMINDERS_AFTER_INPUTS}
              />
            </div>
          </ContextShow>
        </CheckboxGroup>
      </SectionCard>

      <SectionCard title="Notifications" className="!p-3">
        <CheckboxGroup
          isCompact
          withHover={false}
          // label="Send Notification When:"
          trueIcon="check"
          falseIcon="empty_checkbox"
          falseStyle="faded"
          layout="vertical"
        >
          <Checkbox
            label="Notify on Payment Attempted"
            model="send_notifications.payment_attempted"
            tooltip="When checked, the emails and phone numbers below will be notified when a payment is attempted"
          />

          <ContextShow when="send_notifications.payment_attempted" is={true}>
            <div className="grid gap-4 grid-cols-[100%] pl-6 pt-1 pb-3">
              <Input
                alwaysShowDescription
                model="notification_settings.payment_attempted.email"
                type="email"
                label="Email Addresses"
                description="Emails support multiple recipients, separated by a comma (,)"
              />

              <Input
                alwaysShowDescription
                model="notification_settings.payment_attempted.phone_no"
                type="phone"
                label="Phone Number"
                description="SMS supports only a single recipient"
              />
            </div>
          </ContextShow>

          <Checkbox
            label="Notify on Payment Succeeded"
            model="send_notifications.payment_succeeded"
            tooltip="When checked, the emails and phone numbers below will be notified when a payment is successful"
          />

          <ContextShow when="send_notifications.payment_succeeded" is={true}>
            <div className="grid gap-4 grid-cols-[100%] pl-6 pt-1 pb-3">
              <Input
                alwaysShowDescription
                model="notification_settings.payment_succeeded.email"
                type="email"
                label="Email Addresses"
                description="Emails support multiple recipients, separated by a comma (,)"
              />

              <Input
                alwaysShowDescription
                model="notification_settings.payment_succeeded.phone_no"
                type="phone"
                label="Phone Number"
                description="SMS supports only a single recipient"
              />
            </div>
          </ContextShow>

          <Checkbox
            label="Notify on Payment Failed"
            model="send_notifications.payment_failed"
            tooltip="When checked, the emails and phone numbers below will be notified when a payment has failed"
          />

          <ContextShow when="send_notifications.payment_failed" is={true}>
            <div className="grid gap-4 grid-cols-[100%] pl-6 pt-1 pb-3">
              <Input
                alwaysShowDescription
                model="notification_settings.payment_failed.email"
                type="email"
                label="Email Addresses"
                description="Emails support multiple recipients, separated by a comma (,)"
              />

              <Input
                alwaysShowDescription
                model="notification_settings.payment_failed.phone_no"
                type="phone"
                label="Phone Number"
                description="SMS supports only a single recipient"
              />
            </div>
          </ContextShow>
        </CheckboxGroup>
      </SectionCard>
    </>
  )
}

const REMINDERS_BEFORE_INPUTS = [
  {
    name: 'Reminder Type',
    width: '2fr',
    element: (id: string) => (
      <Select isCompact withHover={false} model={`${id}.reminder_type`} defaultValue="email">
        <Option label="Email" value="email" />
        <Option label="SMS" value="sms" />
        <Option label="Email + SMS" value="email_sms" />
      </Select>
    ),
  },
  {
    name: 'Days Before',
    width: '90px',
    element: (id: string, _element, index) => {
      return <NumberInput defaultValue={(index + 1) * 3} isCompact withHover={false} model={`${id}.days_before_due_date`} />
    },
  },
  {
    name: 'Description',
    width: '2fr',
    element: (id: string) => <Textarea isCompact withHover={false} model={`${id}.description`} minRows={1} />,
  },
]

const REMINDERS_AFTER_INPUTS = [
  {
    name: 'Reminder Type',
    width: '2fr',
    element: (id: string) => (
      <Select isCompact withHover={false} model={`${id}.reminder_type`} defaultValue="email">
        <Option label="Email" value="email" />
        <Option label="SMS" value="sms" />
        <Option label="Email + SMS" value="email_sms" />
      </Select>
    ),
  },
  {
    name: 'Days After',
    width: '90px',
    element: (id: string, _element, index) => {
      return <NumberInput defaultValue={(index + 1) * 3} isCompact withHover={false} model={`${id}.days_after_due_date`} />
    },
  },
  {
    name: 'Description',
    width: '2fr',
    element: (id: string) => <Textarea isCompact withHover={false} model={`${id}.description`} minRows={1} />,
  },
]

export const InvoiceSettingsOverlay = withOverlayError(RootInvoiceSettingsOverlay)
