import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import pluralize from "pluralize";
import size from "lodash/size";

import { countWord } from "../../utils/functions";
import { COLORS, SHADOW } from "../../theme";
import { DEFAULT_EMPTY_VALUE } from "../../utils/constants";
import { withFormContext } from "./context";

import Button from "../Button";
import Card from "../Card";
import CardContent from "../CardContent";
import CardHeader from "../CardHeader";
import CardTitle from "../CardTitle";
import DataArray from "./DataArray";
import Glyph from "../Glyph";
import GridTable from "../GridTable";

const getColumns = ({ inputs, isEditable }: any) => {
	const columns = [];

	// add reorder column
	if (isEditable) columns.push("32px");

	for (const input of inputs) {
		columns.push(input.width || "1fr");
	}

	// add delete column
	if (isEditable) columns.push("44px");

	return columns.join(" ");
};

const TableArrayForm = (props: any) => {
	const {
		isCompact,
		inputs,
		title,
		name = "Row",
		model,
		value,
		buttonLabel,
	}: any = props;
	const columns = getColumns({ inputs, isEditable: props.isEditable });

	return (
		<DataArray
			isCompact={isCompact}
			isEditable={props.isEditable}
			model={model}
			value={value}
			onUpdate={props.onUpdate}
		>
			{({
				orderedIds,
				elements,
				add,
				remove,
				isEditable,
				onDragAndDrop,
			}: any) => {
				const elementSize = size(orderedIds);
				const isEmpty = elementSize === 0;

				return (
					<Card>
						<CardHeader
							after={
								isEditable && (
									<Button
										testKey="add_button"
										label={buttonLabel || `Add ${pluralize.singular(name)}`}
										glyph="add"
										type="minimal"
										display="inline-flex"
										size={200}
										onClick={add}
									/>
								)
							}
						>
							<CardTitle
								title={
									title || isEmpty
										? pluralize(name)
										: countWord(name, elementSize)
								}
								css={styles.cardTitle}
							/>
						</CardHeader>

						<CardContent padding="0">
							{isEmpty && (
								<div css={styles.emptyState}>
									<div>No {pluralize(name)} added yet</div>
								</div>
							)}

							{!isEmpty && (
								<GridTable templateColumns={columns}>
									<GridTable.Header>
										{isEditable && (
											<GridTable.Cell>{/* Reorder Column */}</GridTable.Cell>
										)}

										{inputs.map((input: any, index: number) => (
											<GridTable.Cell
												key={`${index}`}
												className="!text-[0.9rem]"
											>
												{input.name}
												{isEditable && input.isRequired && (
													<Glyph glyph="label_required" size={15} />
												)}
											</GridTable.Cell>
										))}

										{isEditable && (
											<GridTable.Cell>{/* Remove Column */}</GridTable.Cell>
										)}
									</GridTable.Header>

									<DragDropContext onDragEnd={onDragAndDrop}>
										<Droppable droppableId={uuid()}>
											{(droppableProvided: any) => (
												<div ref={droppableProvided.innerRef}>
													{orderedIds?.map((id: string, idx: number) => {
														const element = elements[id];

														return (
															<Draggable
																key={id}
																draggableId={id}
																index={idx}
																isDragDisabled={!isEditable}
															>
																{(
																	draggableProvided: any,
																	draggableSnapshot: any,
																) => (
																	<GridTable.Row
																		key={id}
																		css={styles.row}
																		ref={draggableProvided.innerRef}
																		className={
																			draggableSnapshot.isDragging
																				? "is-dragging"
																				: ""
																		}
																		{...draggableProvided.draggableProps}
																		{...draggableProvided.dragHandleProps}
																	>
																		{/* Reorder Column */}
																		{isEditable && (
																			<GridTable.Cell css={styles.dragCell}>
																				<Glyph
																					glyph="drag_and_drop"
																					size={15}
																					color="textMuted"
																				/>
																			</GridTable.Cell>
																		)}

																		{/* Form Inputs */}
																		{inputs.map((input: any, index: number) => (
																			<GridTable.Cell
																				key={`${id}-${index}`}
																				css={styles.cell}
																			>
																				{input.element(id, element, idx) ||
																					DEFAULT_EMPTY_VALUE}
																			</GridTable.Cell>
																		))}

																		{/* Remove Column */}
																		{isEditable && (
																			<GridTable.Cell centerY centerX>
																				<Button
																					hideLabel
																					glyph="delete"
																					type="minimal"
																					color="red"
																					size={100}
																					onClick={() => remove(id)}
																				/>
																			</GridTable.Cell>
																		)}
																	</GridTable.Row>
																)}
															</Draggable>
														);
													})}

													{droppableProvided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
								</GridTable>
							)}
						</CardContent>
					</Card>
				);
			}}
		</DataArray>
	);
};

const styles = {
	emptyState: {
		display: "grid",
		alignItems: "center",
		textAlign: "center",
		padding: "1rem",
		color: COLORS.textMuted,
		opacity: 0.8,
	},

	cardTitle: {
		fontSize: "1rem",
	},

	row: {
		"&.is-dragging": {
			background: `${COLORS.white} !important`,
			boxShadow: SHADOW(4),
			borderRadius: 5,

			left: "auto !important",
			right: "0 !important",
		},
	},

	cell: {
		padding: "0.2rem 0.5rem",
		cursor: "default !important",

		"&:hover": {
			cursor: "default !important",
		},

		".is-dragging &": {
			background: `${COLORS.white} !important`,
		},
	},

	dragCell: {
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",

		"&:hover": {
			cursor: "move !important",
			svg: { fill: COLORS.blue },
		},
	},
};

TableArrayForm.defaultProps = {
	local: false,
	emptyModel: {},
};

export default withFormContext(TableArrayForm);
