import React from 'react'
import size from 'lodash/size'

import { COLORS } from '../../theme'

import Divider from '../Divider'
import Dropdown from '../Dropdown'
import DropdownGroup from '../DropdownGroup'
import DropdownItem from '../DropdownItem'

const ChannelDropdownItem = ({ name, clientName, onClick }: any) => (
  <DropdownItem
    onClick={onClick}
    avatar=""
    avatarInitials={name}
    color="vividBlue"
    label={
      <>
        <span css={{ color: COLORS.text, fontWeight: 700 }}>{name}</span>
        <span css={{ color: COLORS.textMuted, opacity: 0.9 }}> to {clientName}</span>
      </>
    }
  />
)

const SubchannelsDropdown = ({ selectedChannel, mainChannel, secondaryChannels, onSelect }: any) => {
  const hasSecondaryChannels = size(secondaryChannels) > 0

  return (
    <Dropdown avatar="" label={selectedChannel?.name} position="bottom" buttonSize={200}>
      <ChannelDropdownItem name={mainChannel?.name} clientName={mainChannel?.client_name} onClick={() => onSelect(mainChannel)} />

      {hasSecondaryChannels && (
        <>
          <Divider />

          <DropdownGroup title="Channels">
            {secondaryChannels.map((channel: any) => (
              <ChannelDropdownItem name={channel?.name} clientName={mainChannel?.client_name} onClick={() => onSelect(channel)} />
            ))}
          </DropdownGroup>
        </>
      )}
    </Dropdown>
  )
}

export default SubchannelsDropdown
