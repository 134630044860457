import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import Button from '../../Button'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Link from '../../Link'
import Overlay from '../../Overlay'
import Section from '../../Section'
import { Text } from '../../Typography'

import NoInternet from '../../Alerts/NoInternet'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'

import withSettings from '../../../hocs/withSettings'

import { clientSelector } from '../../../reducers/selectors'
import { apiUpdate } from '../../../modules/api'
import { withOverlayError } from '../../../hocs/withOverlayError'

import { ICONS } from '../../../theme'
import { SERVICE_EPISODE_LOC_SUBCATEGORIES } from '../../../utils/constants'

const UpdateLevelOfCare = ({ online, location, history }: any) => {
  const match = useRouteMatch()

  console.log({ match, location })

  const client = useSelector((state) => clientSelector(state, match))
  const loading = useSelector((state) => state.data.clients?.loading)

  const form = React.useRef()
  const [valid, setValid] = React.useState(false)

  const close = () => {
    const path = location.pathname
    history.push(path.substr(0, path.lastIndexOf('/')))
  }

  const update = async () => {
    await apiUpdate({
      name: 'clients',
      url: `/residents/${client.id}`,
      params: form.current.getFormValue(),
    })
    close()
  }

  return (
    <Overlay onClose={close} position="center" maxWidth={32} showBackdrop>
      <Overlay.Header glyph="recurring_charge" title="Update Primary Service" />

      <Overlay.Content>
        <Form getForm={form} initialModel={client} onValidationUpdate={setValid}>
          <Section>
            <FormSection layout="vertical">
              <Text
                layout="vertical"
                description={
                  <Text>
                    Your current Primary Service is <strong>{client?.active_level_of_care?.name}</strong>
                  </Text>
                }
              />

              <ObjectSelector
                model="active_level_of_care"
                label="New Primary Service"
                type="service_episodes.active"
                icon={ICONS.service_episodes}
                dependentValue={client?.id}
                selectTitle={(data) => data.name}
                selectDescription={(data: any) => SERVICE_EPISODE_LOC_SUBCATEGORIES?.[data?.loc_category]?.[data?.loc_subcategory] || '–'}
                validations={{
                  presence: {
                    message: 'Please select a new Primary Service',
                  },
                }}
                description={
                  <>
                    Go to <Link to={`/${match?.params?.resource}/${match?.params?.resource_id}/service-episodes`}>Service Episodes</Link> to
                    Add / Update any Service Episodes
                  </>
                }
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {online && (
          <Button
            label={'Update Primary Service'}
            type="primary"
            color={'green'}
            isLoading={loading}
            onClick={update}
            isDisabled={!valid}
          />
        )}
        {!online && <NoInternet />}
      </Overlay.Footer>
    </Overlay>
  )
}

export default withOverlayError(withSettings(UpdateLevelOfCare))
