import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useAPI from '@behavehealth/hooks/useAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Card, Chotomate, Button, Grid, Page, HelpTagIframe } from '@behavehealth/components'

import { TreatmentPlanTemplateOverlayV6 } from '@behavehealth/components/Templates/overlays/TreatmentPlanTemplateOverlay'
import TreatmentPlanTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/TreatmentPlanTemplatesImportOverlay'
import TreatmentPlanTemplatesTable from '@behavehealth/components/Templates/tables/TreatmentPlanTemplatesTable'

const pageConfig = {
  feature: 'treatment_plan_templates',
  title: 'Treatment Plan Templates',
  help: <HelpTagIframe id="settings_treatment_plan_templates" />,
}

const TreatmentPlanTemplates = () => {
  return (
    <div>
      <TreatmentPlanTemplatesIndex />

      <AnimatedRoutes>
        <Route path="import" element={<TreatmentPlanTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<TreatmentPlanTemplateOverlayV6 useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const TreatmentPlanTemplatesIndex = () => {
  const { isBehave } = useSettings()

  const data = useSelector((state) => state.data.data_form_templates?.data)
  const loading = useSelector((state) => state.data.data_form_templates?.loading)
  const isEmpty = size(data) === 0

  useAPI('data_form_templates', '/data_form_templates?category=treatment_plan')

  return (
    <Page
      breakpoint={3}
      actions={
        <>
          {isBehave && <Button as={Link} label="Import Behave Templates" type="default" glyph="add" link="import" />}

          <Button
            as={Link}
            label="Add Treatment Plan Template"
            glyph="add"
            type="primary"
            link="new"
            state={{ data: { templateType: 'custom' } }}
          />
        </>
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        <Chotomate name="treatment_plan_templates" ready={!loading} />

        <Card>
          <TreatmentPlanTemplatesTable
            mainLinkAs={NavLink}
            title="Treatment Plan Templates"
            data={data}
            isLoading={isEmpty && loading}
            to={(id: string) => id}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(TreatmentPlanTemplates, pageConfig))
