import React from 'react'

import Chotomate from '../../components/Chotomate'
import ContextHide from '../../components/Forms/ContextHide'
import ContextShow from '../../components/Forms/ContextShow'
import Diagnoses from '../../components/SmartContent/Diagnoses'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import HelpTag from '../../components/HelpTag'
import Input from '../../components/Forms/Input'
import Medications from '../../components/SmartContent/Medications'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Switch from '../../components/Forms/Switch'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import BioMedicalV1 from '../../components/Overlays/pages/DataForms/common/BioMedicalV1'
import CognitiveEmotionalBehavioralV1 from '../../components/Overlays/pages/DataForms/common/CognitiveEmotionalBehavioralV1'
import GeneralInfo from '../../components/Overlays/pages/DataForms/common/GeneralInfo'
import MentalHealthHistory from '../../components/Overlays/pages/DataForms/common/MentalHealthHistory'
import MentalStatusForm from '../../components/Overlays/pages/DataForms/common/MentalStatusForm'
import MotivationForChangeForm from '../../components/Overlays/pages/DataForms/common/MotivationForChangeForm'
import OtherDiagnosisCodes from '../../components/Overlays/pages/DataForms/common/OtherDiagnosisCodes'
import PhysicalHealthHistory from '../../components/Overlays/pages/DataForms/common/PhysicalHealthHistory'
import RecoveryEnvironmentV1 from '../../components/Overlays/pages/DataForms/common/RecoveryEnvironmentV1'
import RelapsePotentialV1 from '../../components/Overlays/pages/DataForms/common/RelapsePotentialV1'
import SNAP from '../../components/Overlays/pages/DataForms/common/SNAP'
import SubstanceUseDiagnosisCodes from '../../components/Overlays/pages/DataForms/common/SubstanceUseDiagnosisCodes'
import SubstanceUseHistoryV1 from '../../components/Overlays/pages/DataForms/common/SubstanceUseHistoryV1'
import TreatmentHistoryFormV1 from '../../components/Overlays/pages/DataForms/common/TreatmentHistoryFormV1'
import WithdrawalSymptomsForm from '../../components/Overlays/pages/DataForms/common/WithdrawalSymptomsForm'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootBiopsychosocialOverlayV1 = () => (
  <DataFormOverlay
    title="Biopsychosocial Assessment"
    category="clinical_assessment"
    subcategory="biopsychosocial"
    icon="clinical_assessments"
    helpID="clinical_assessments_biopsychosocial_assessment"
    version="1.0"
    requestClientSignature={false}
    requestAuthorSignature={true}
    requireSupervisor={true}
    enableTreatmentPlanSelector
  >
    {({ client, data, isEditable }) => (
      <>
        <Chotomate ready name="biopsychosocial_assessment_overlay" />

        <Section
          title="General Info"
          aside={<Switch horizontal inverse model="data.general_info.not_applicable" />}
          scrollview={{
            id: 'general_info',
            name: 'General Info',
          }}
          commentsModel="data.general_info"
        >
          <ContextHide when="data.general_info.not_applicable" is={true}>
            <GeneralInfo showAllergies showSymptomology client={client} />
          </ContextHide>
        </Section>

        <Divider />

        {/* Substance use history */}
        <Section
          title="Substance Use History"
          aside={<Switch horizontal inverse model="data.substance_use_history.not_applicable" />}
          scrollview={{
            id: 'substance_use_history',
            name: 'Substance Use History',
            parent: { id: 'history', name: 'History' },
          }}
          commentsModel="data.substance_use_history"
        >
          <ContextHide when="data.substance_use_history.not_applicable" is={true}>
            <SubstanceUseHistoryV1 />
          </ContextHide>
        </Section>

        <Divider />

        {/* Mental Health History */}
        <Section
          title="Mental Health History"
          aside={<Switch horizontal inverse model="data.mental_health_history.not_applicable" />}
          scrollview={{
            id: 'mental_health_history',
            name: 'Mental Health History',
            parent: { id: 'history', name: 'History' },
          }}
          commentsModel="data.mental_health_history"
        >
          <ContextHide when="data.mental_health_history.not_applicable" is={true}>
            <MentalHealthHistory />
          </ContextHide>
        </Section>

        <Divider />

        {/* Physical Health History */}
        <Section
          title="Physical Health History"
          aside={<Switch horizontal inverse model="data.physical_health_history.not_applicable" />}
          scrollview={{
            id: 'physical_health_history',
            name: 'Physical Health History',
            parent: { id: 'history', name: 'History' },
          }}
          commentsModel="data.physical_health_history"
        >
          <ContextHide when="data.physical_health_history.not_applicable" is={true}>
            <PhysicalHealthHistory />
          </ContextHide>
        </Section>

        <Divider />

        {/* Treatment History */}
        <Section
          title="Treatment History"
          aside={<Switch horizontal inverse model="data.treatment_history.not_applicable" />}
          scrollview={{
            id: 'treatment_history',
            name: 'Treatment History',
            parent: { id: 'history', name: 'History' },
          }}
          commentsModel="data.treatment_history"
        >
          <ContextHide when="data.treatment_history.not_applicable" is={true}>
            <TreatmentHistoryFormV1 />
          </ContextHide>
        </Section>

        <Divider />

        {/* Work and education history */}
        <Section
          title="Work and Education History"
          aside={<Switch horizontal inverse model="data.work_and_education_history.not_applicable" />}
          scrollview={{
            id: 'work_and_education_history',
            name: 'Work and Education History',
            parent: { id: 'history', name: 'History' },
          }}
          commentsModel="data.work_and_education_history"
        >
          <ContextHide when="data.work_and_education_history.not_applicable" is={true}>
            <FormSection layout="vertical">
              <Input
                label="What is the highest level of schooling completed by the client?"
                model="data.work_and_education_history.highest_level_of_schooling"
              />

              <RadioGroup
                label="Did the client's substance use have any impact on their schooling?"
                model="data.work_and_education_history.has_using_affected_school"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.work_and_education_history.has_using_affected_school" is={true}>
                <SmartTextarea
                  useDictation
                  label="Please explain:"
                  model="data.work_and_education_history.has_using_affected_school_extra"
                  validations={{
                    presence: {
                      message: 'Please provide this information',
                    },
                  }}
                />
              </ContextShow>

              <RadioGroup
                label="Has the client's substance use affected their work in any way?"
                model="data.work_and_education_history.has_using_affected_work"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.work_and_education_history.has_using_affected_work" is={true}>
                <SmartTextarea
                  useDictation
                  label="Please explain:"
                  model="data.work_and_education_history.has_using_affected_work_extra"
                  validations={{
                    presence: {
                      message: 'Please provide this information',
                    },
                  }}
                />
              </ContextShow>

              <RadioGroup
                label="Has the client ever used drugs or alcohol at work?"
                model="data.work_and_education_history.has_used_at_work"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.work_and_education_history.has_used_at_work" is={true}>
                <SmartTextarea
                  useDictation
                  label="Please explain:"
                  model="data.work_and_education_history.has_used_at_work_extra"
                  validations={{
                    presence: {
                      message: 'Please provide this information',
                    },
                  }}
                />
              </ContextShow>

              <RadioGroup
                label="Has the client ever been fired or quit a job due to using alcohol or drugs?"
                model="data.work_and_education_history.has_been_fired_for_using"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.work_and_education_history.has_been_fired_for_using" is={true}>
                <SmartTextarea
                  useDictation
                  label="Please explain:"
                  model="data.work_and_education_history.has_been_fired_for_using_extra"
                  validations={{
                    presence: {
                      message: 'Please provide this information',
                    },
                  }}
                />
              </ContextShow>

              <RadioGroup
                label="Has the client ever used drugs or alcohol with spouse or significant other?"
                model="data.work_and_education_history.has_used_with_spouse"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.work_and_education_history.has_used_with_spouse" is={true}>
                <SmartTextarea
                  useDictation
                  label="Please explain:"
                  model="data.work_and_education_history.has_used_with_spouse_extra"
                  validations={{
                    presence: {
                      message: 'Please provide this information',
                    },
                  }}
                />
              </ContextShow>

              <RadioGroup
                label="Has the client ever been involved with AA/NA?"
                model="data.work_and_education_history.has_been_involved_with_aa_na"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>

              <ContextShow when="data.work_and_education_history.has_been_involved_with_aa_na" is={true}>
                <SmartTextarea
                  useDictation
                  label="How often attended?"
                  model="data.work_and_education_history.aa_na.how_often"
                  validations={{
                    presence: {
                      message: 'Please provide this information',
                    },
                  }}
                />

                <RadioGroup
                  label="Did the client have a sponsor?"
                  model="data.work_and_education_history.aa_na.have_sponsor"
                  layout="horizontal-dense"
                >
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>

                <RadioGroup
                  label="Did the client work the 12 steps?"
                  model="data.work_and_education_history.aa_na.has_worked_12_steps"
                  layout="horizontal-dense"
                >
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>

                <ContextShow when="data.work_and_education_history.aa_na.has_worked_12_steps" is={true}>
                  <Input
                    label="Highest step completed?"
                    model="data.work_and_education_history.aa_na.highest_step_completed"
                    type="number"
                    size={4}
                  />
                </ContextShow>
              </ContextShow>
            </FormSection>
          </ContextHide>
        </Section>

        <Divider />

        {/* Medications */}
        <Section
          title="Current Medications"
          aside={<Switch horizontal inverse model="data.medications.not_applicable" />}
          scrollview={{
            id: 'medications',
            name: 'Current Medications',
            parent: { id: 'history', name: 'History' },
          }}
          commentsModel="data.medications"
        >
          <ContextHide when="data.medications.not_applicable" is={true} declineUndefined>
            <Medications
              model="data.medications.meds"
              isEditable={isEditable}
              client={client}
              icon="medications"
              title="Medications"
              emptyDescription="No medications added yet"
            />
          </ContextHide>
        </Section>

        <Divider />

        {/* D1: Withdrawal sympts */}
        <Section
          title="Dimension 1: Withdrawal Symptoms"
          aside={<Switch horizontal inverse model="data.withdrawal_symptoms.not_applicable" />}
          scrollview={{
            id: 'withdrawal_symptoms',
            name: 'Withdrawal Symptoms',
            parent: { id: 'dimensions', name: 'Dimensions' },
          }}
          commentsModel="data.withdrawal_symptoms"
        >
          <ContextHide when="data.withdrawal_symptoms.not_applicable" is={true}>
            <WithdrawalSymptomsForm />
          </ContextHide>
        </Section>

        <Divider />

        {/* D2: Bio-medical */}
        <Section
          title="Dimension 2: Bio-Medical"
          aside={<Switch horizontal inverse model="data.bio_medical.not_applicable" />}
          scrollview={{
            id: 'bio_medical',
            name: 'Bio-Medical',
            parent: { id: 'dimensions', name: 'Dimensions' },
          }}
          commentsModel="data.bio_medical"
        >
          <ContextHide when="data.bio_medical.not_applicable" is={true}>
            <BioMedicalV1 />
          </ContextHide>
        </Section>

        <Divider />

        {/* D3: Cognitive Emotional Behavioral */}
        <Section
          title="Dimension 3: Cognitive, Emotional, Behavioral"
          aside={<Switch horizontal inverse model="data.cognitive_emotional_behavioral.not_applicable" />}
          scrollview={{
            id: 'cognitive_emotional_behavioral',
            name: 'Cognitive, Emotional, Behavioral',
            parent: { id: 'dimensions', name: 'Dimensions' },
          }}
          commentsModel="data.cognitive_emotional_behavioral"
        >
          <ContextHide when="data.cognitive_emotional_behavioral.not_applicable" is={true}>
            <CognitiveEmotionalBehavioralV1 />
          </ContextHide>
        </Section>

        <Divider />

        {/* D3: Current Mental Status */}
        <Section
          title="Dimension 3: Current Mental Status"
          aside={<Switch horizontal inverse model="data.current_mental_status.not_applicable" />}
          scrollview={{
            id: 'current_mental_status',
            name: 'Current Mental Status',
            parent: { id: 'dimensions', name: 'Dimensions' },
          }}
          commentsModel="data.current_mental_status"
        >
          <ContextHide when="data.current_mental_status.not_applicable" is={true}>
            <MentalStatusForm />
          </ContextHide>
        </Section>

        <Divider />

        {/* D4: Motivation for Change */}
        <Section
          title="Dimension 4: Motivation For Change"
          aside={<Switch horizontal inverse model="data.motivation_for_change.not_applicable" />}
          scrollview={{
            id: 'motivation_for_change',
            name: 'Motivation For Change',
            parent: { id: 'dimensions', name: 'Dimensions' },
          }}
          commentsModel="data.motivation_for_change"
        >
          <ContextHide when="data.motivation_for_change.not_applicable" is={true}>
            <MotivationForChangeForm />
          </ContextHide>
        </Section>

        <Divider />

        {/* D5: Relapse potential */}
        <Section
          title="Dimension 5: Relapse Potential"
          aside={<Switch horizontal inverse model="data.relapse_potential.not_applicable" />}
          scrollview={{
            id: 'relapse_potential',
            name: 'Relapse Potential',
            parent: { id: 'dimensions', name: 'Dimensions' },
          }}
          commentsModel="data.relapse_potential"
        >
          <ContextHide when="data.relapse_potential.not_applicable" is={true}>
            <RelapsePotentialV1 />
          </ContextHide>
        </Section>

        <Divider />

        {/* D6: Recovery Environment */}
        <Section
          title="Recovery Environment"
          aside={<Switch horizontal inverse model="data.recovery_environment.not_applicable" />}
          scrollview={{
            id: 'recovery_environment',
            name: 'Recovery Environment',
            parent: { id: 'dimensions', name: 'Dimensions' },
          }}
          commentsModel="data.recovery_environment"
        >
          <ContextHide when="data.recovery_environment.not_applicable" is={true}>
            <RecoveryEnvironmentV1 />
          </ContextHide>
        </Section>

        <Divider />

        <Section
          title="Strengths, Needs, Abilities, Preferences"
          aside={<Switch horizontal inverse model="data.snap.not_applicable" />}
          scrollview={{
            id: 'snap',
            name: 'S.N.A.P.',
          }}
          commentsModel="data.snap"
        >
          <ContextHide when="data.snap.not_applicable" is={true}>
            <SNAP />
          </ContextHide>
        </Section>

        <Divider />

        {/* Diagnoses */}
        <Section
          title="Diagnoses"
          aside={<Switch horizontal inverse model="data.diagnoses.not_applicable" />}
          scrollview={{
            id: 'diagnoses',
            name: 'Diagnoses',
          }}
          commentsModel="data.diagnoses_section"
        >
          <ContextHide when="data.diagnoses.not_applicable" is={true}>
            <Diagnoses
              model="data.diagnoses.diagnoses"
              isEditable={isEditable}
              client={client}
              icon="diagnosis"
              title="Diagnoses"
              emptyDescription="No diagnoses added yet"
            />
          </ContextHide>
        </Section>

        <Divider />

        {/* Old Diagnoses */}
        {data?.diagnoses?.substance && (
          <>
            <Section
              title="Previous Diagnoses Section"
              scrollview={{
                id: 'old_diagnoses',
                name: 'Previous Diagnoses Section',
              }}
              commentsModel="data.previous_diagnoses_section"
            >
              <ContextHide when="data.diagnoses.not_applicable" is={true}>
                <FormSection>
                  <SubstanceUseDiagnosisCodes />
                  <OtherDiagnosisCodes />
                </FormSection>
              </ContextHide>
            </Section>

            <Divider />
          </>
        )}

        {/* Summary */}
        <Section
          title="Summary"
          aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
          scrollview={{
            id: 'summary',
            name: 'Summary',
          }}
          commentsModel="data.summary"
        >
          <ContextHide when="data.summary.not_applicable" is={true}>
            <FormSection layout="vertical">
              <SmartTextarea useDictation label="Interpretive Summary" model="data.summary.interpretive_summary" />
              <SmartTextarea useDictation label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
              <SmartTextarea useDictation label="Recommendations" model="data.summary.recommendations" />
            </FormSection>
          </ContextHide>
        </Section>
      </>
    )}
  </DataFormOverlay>
)

export const BiopsychosocialOverlayV1 = withOverlayError(RootBiopsychosocialOverlayV1)
