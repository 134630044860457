import React from 'react'
import { v4 as uuid } from 'uuid'
import size from 'lodash/size'

import { BehaveAIChat } from '../../BehaveAI/BehaveAIChat'
import { useCreate } from '../../../hooks/useNewAPI'

import Button from '../../../components/Button'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Mermaid from '../../../components/Mermaid'
import Overlay from '../../../components/Overlay'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Textarea from '../../../components/Forms/Textarea'

import { useFormBuilder } from '../useFormBuilder'

export const MermaidAIChatOverlay = (props: any) => {
  const { onClose } = props
  const form = React.useRef()

  const addElement: any = useFormBuilder((state: any) => state.addElement)

  const [chatId, setChatId] = React.useState('')
  const [messagesCount, setMessagesCount] = React.useState(0)

  const [value, setValue] = React.useState('')
  const [mermaid, setMermaid]: any = React.useState(null)
  const [isMermaidValid, setIsMermaidValid] = React.useState(false)

  const { mutateAsync: createChat, isLoading: isCreatingChat }: any = useCreate({
    name: ['start-new-chat'],
    url: `/ai/chats`,
    invalidate: 'ai-chats',
  })

  const startNewChat = async () => {
    try {
      const { data: newChat }: any = await createChat({
        ai_bot_identifier: 'forms_mermaid',
        category: 'form_builder_mermaid_chat',
      })

      setChatId(newChat.id)
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (!chatId) startNewChat()
  }, [chatId])

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="right" maxWidth={50}>
      <Overlay.Header title="Behave AI Mermaid Content Generator" glyph="mermaid" />

      <Overlay.Content>
        {chatId ? (
          <BehaveAIChat
            chatId={chatId}
            className="min-h-full"
            onMessagesUpdate={(messages) => {
              setMessagesCount(size(messages))
            }}
            footerClassName="px-5 pb-1"
            afterMessages={
              messagesCount > 1 && (
                <Form getForm={form}>
                  <FormSection maxWidth="100%" className="p-5">
                    <Textarea
                      label="Paste Mermaid Content Here to Test:"
                      value={value}
                      minRows={5}
                      onUpdate={(state) => setValue(state.value)}
                      className="[&_textarea]:!font-mono"
                    />

                    <Button
                      size={200}
                      label="Test Mermaid Code"
                      isDisabled={!value}
                      color="blue"
                      type="primary"
                      glyph="mermaid"
                      onClick={() => {
                        setMermaid({
                          id: uuid(),
                          value,
                        })
                      }}
                    />

                    {mermaid && (
                      <>
                        <Mermaid
                          key={`mermaid-${mermaid.id}`}
                          id={mermaid.id}
                          value={mermaid.value}
                          onValidationUpdate={setIsMermaidValid}
                        />

                        {isMermaidValid && (
                          <Button
                            size={200}
                            label="Insert Mermaid to Form & Close"
                            color="pink"
                            type="primary"
                            glyph="mermaid"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()

                              console.log('onClick')

                              addElement({
                                category: 'mermaid',
                                config: {
                                  mermaid_value: mermaid.value,
                                },
                              })

                              onClose()
                            }}
                          />
                        )}
                      </>
                    )}
                  </FormSection>
                </Form>
              )
            }
          />
        ) : (
          <State isLoading />
        )}
      </Overlay.Content>
    </Overlay>
  )
}
