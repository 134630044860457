export const processSigneesToSign = ({ data = {}, signees = [] }: any) => {
  let result: any[] = []

  const { use_review_and_signoff_workflow, review_and_signoff_workflow_settings } = data

  if (!use_review_and_signoff_workflow || !review_and_signoff_workflow_settings) return result

  const { signoff_settings } = review_and_signoff_workflow_settings

  const supervisorsSignatureRequired = !!signoff_settings?.supervisors_must_sign
  const staffSignatureRequired = !!signoff_settings?.staff_must_sign

  if (!supervisorsSignatureRequired && !staffSignatureRequired) {
    return result
  }

  for (const signee of signees) {
    const { category } = signee

    if (signee.initial_signature && signee.initial_signed_at) continue

    const shouldSign = (category === 'supervisor' && supervisorsSignatureRequired) || (category === 'staff' && staffSignatureRequired)

    if (!shouldSign) continue

    result.push(signee)
  }

  return result
}

export const processSignatureSettings = (data: any = {}) => {
  const { review_and_signoff_workflow_settings: settings = {} } = data

  return {
    supervisor: {
      label: 'Supervisor',
      color: 'orange',
      shouldSignInitially: !!settings?.signoff_settings?.supervisors_must_sign,
      shouldSignUpdates: !!settings?.updates_after_signoff?.supervisors_must_sign,
      signatureLegalCopy: settings?.signatures_legal_text?.supervisors || '',
    },
    staff: {
      label: 'Staff',
      color: 'blue',
      shouldSignInitially: !!settings?.signoff_settings?.staff_must_sign,
      shouldSignUpdates: !!settings?.updates_after_signoff?.staff_must_sign,
      signatureLegalCopy: settings?.signatures_legal_text?.staff || '',
    },
  }
}
