import React from 'react'

import ResourceQuickLink from '../../QuickLinks/ResourceQuickLink'

// TODO (Andrei): add to overlay and update messages after the API resident-move bugs are fixes
const MESSAGES: any = {
  create: (props: any) => <>{props.author} added Client(s) to a Program List.</>,
  update: (props: any) => (
    <>
      {props.author} updated the {props.record} <i>"{props.event.trackable?.program?.name}"</i> for{' '}
      <ResourceQuickLink resource={props.event.trackable?.resident} disableLink={props.disableLink} />.
    </>
  ),
}

export default (props: any) => MESSAGES[props.event?.action_type]?.(props) || null
