import React from 'react'
import { useLocation } from 'react-router-dom'

import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import Score from './common/Score'
import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const INITIAL_STATE = {
  disturbing_memories: 0,
  disturbing_dreams: 0,
  fear_of_reliving_stressful_experience: 0,
  upset_about_past_stressful_experience: 0,
  physical_reactions: 0,
  thinking_talking_avoidance: 0,
  activities_avoidance: 0,
  trouble_remembering: 0,
  loss_of_interest: 0,
  feeling_distant: 0,
  feeling_emotionally_numb: 0,
  feeling_future_will_be_cut_short: 0,
  trouble_sleeping: 0,
  feeling_irritable: 0,
  difficulty_concentrating: 0,
  being_super_alert: 0,
  easily_startled: 0,
}

const RootPCLCOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="PCL-C PTSD CheckList Civilian Version"
      category="clinical_measurement"
      subcategory="pclc"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <p>
          {' '}
          Below is a list of problems and complaints that veterans sometimes have in response to stressful life experiences. Please read
          each one carefully, put an “X” in the box to indicate how much you have been bothered by that problem in the last month.
        </p>
      </Section>

      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <Questionnaire />
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <p>PCL-M for DSM-IV (11/1/94) Weathers, Litz, Huska, & Keane National Center for PTSD - Behavioral Science Division</p>

        <p>
          <b>Copyright:</b> This is a Government document in the public domain.
        </p>
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <RadioGroup
          label="1. Repeated, disturbing memories, thoughts, or images of a stressful experience from the past?"
          model="data.questionnaire.disturbing_memories"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, disturbing_memories: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="2. Repeated, disturbing dreams of a stressful experience from the past?"
          model="data.questionnaire.disturbing_dreams"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, disturbing_dreams: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="3. Suddenly acting or feeling as if a stressful experience were happening again (as if you were reliving it)?"
          model="data.questionnaire.fear_of_reliving_stressful_experience"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, fear_of_reliving_stressful_experience: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="4. Feeling very upset when something reminded you of a stressful experience from the past?"
          model="data.questionnaire.upset_about_past_stressful_experience"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, upset_about_past_stressful_experience: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="5. Having physical reactions (e.g., heart pounding, trouble breathing, or sweating) when something reminded you of a stressful experience from the past?"
          model="data.questionnaire.physical_reactions"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, physical_reactions: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="6. Avoid thinking about or talking about a stressful experience from the past or avoid having feelings related to it?"
          model="data.questionnaire.thinking_talking_avoidance"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, thinking_talking_avoidance: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="7. Avoid activities or situations because they remind you of a stressful experience from the past?"
          model="data.questionnaire.activities_avoidance"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, activities_avoidance: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="8. Trouble remembering important parts of a stressful experience from the past?"
          model="data.questionnaire.trouble_remembering"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, trouble_remembering: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="9. Loss of interest in things that you used to enjoy?"
          model="data.questionnaire.loss_of_interest"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, loss_of_interest: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="10. Feeling distant or cut off from other people?"
          model="data.questionnaire.feeling_distant"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, feeling_distant: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="11. Feeling emotionally numb or being unable to have loving feelings for those close to you?"
          model="data.questionnaire.feeling_emotionally_numb"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, feeling_emotionally_numb: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="12. Feeling as if your future will somehow be cut short?"
          model="data.questionnaire.feeling_future_will_be_cut_short"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, feeling_future_will_be_cut_short: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="13. Trouble falling or staying asleep?"
          model="data.questionnaire.trouble_sleeping"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, trouble_sleeping: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="14. Feeling irritable or having angry outbursts?"
          model="data.questionnaire.feeling_irritable"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, feeling_irritable: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="15. Having difficulty concentrating?"
          model="data.questionnaire.difficulty_concentrating"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, difficulty_concentrating: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="16. Being “super alert” or watchful on guard?"
          model="data.questionnaire.being_super_alert"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, being_super_alert: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="17. Feeling jumpy or easily startled?"
          model="data.questionnaire.easily_startled"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, easily_startled: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little bit" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Quite a bit" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Score title="Final Score" finalScore={finalScore}>
            <p>
              The PCL is a standardized self-report rating scale for PTSD comprising 17 items that correspond to the key symptoms of PTSD.
              Two versions of the PCL exist: 1) PCL-M is specific to PTSD caused by military experiences and 2) PCL-C is applied generally
              to any traumatic event.{' '}
            </p>

            <p>
              The PCL can be easily modified to fit specific time frames or events. For example, instead of asking about “the past month,”
              questions may ask about “the past week” or be modified to focus on events specific to a deployment.{' '}
            </p>

            <h4>Additional Follow-up</h4>
            <ul>
              <li>
                All military health system beneficiaries with health concerns they believe are deployment-related are encouraged to seek
                medical c
              </li>
              <li>Patients should be asked, “Is your health concern today related to a deployment?” during all primary care visits.</li>
              <li>
                If the patient replies “yes,” the provider should follow the Post-Deployment Health Clinical Practice Guideline (PDH-CPG)
                and supporting guidelines available through the DHCC and{' '}
                <a href="http://www.pdhealth.mil/" target="_blank">
                  http://www.pdhealth.mil/
                </a>
              </li>
            </ul>
          </Score>
        </>
      )}
    </>
  )
}

export const PCLCOverlay = withOverlayError(RootPCLCOverlay)
