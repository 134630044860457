import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { IntakesReportDataTable } from '@behavehealth/constructs/LiveReports/IntakesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Accepted = () => {
  const queryParams = { status: 'intake', category: 'intake' }

  const tableProps = useDataTable({
    name: ['reports', 'clients'],
    // endpoint: `/live_reports/clients`,
    endpoint: `/live_reports`,
    updateDeleteEndpoint: `/residents`,
    params: queryParams,
    localStorageKey: 'report_intakes_v3',
  })

  const to = React.useMemo(() => (rowData: any) => getClientLink(rowData), [])

  return (
    <Page feature="admissions" title="Live Accepted Report">
      <Grid gap="1rem">
        <IntakesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Accepted)
