import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../../theme'
import { titleDate, daysBetween } from '../../utils/functions'

const MessagesGroup = ({ date, timezone, children }: any) => {
  const luxonDate = DateTime.fromFormat(date, 'yyyy-MM-dd')
  const now = DateTime.local().setZone(timezone)
  const inLastTwoDays = daysBetween(luxonDate, now, timezone) <= 2

  const label = inLastTwoDays ? luxonDate.toRelativeCalendar() : titleDate(luxonDate, timezone)

  return (
    <div css={styles.root}>
      <div css={styles.wrapper}>
        <span css={styles.label}>{label}</span>
      </div>
      {children}
    </div>
  )
}

const styles = {
  root: {
    width: '100%',
    maxWidth: 1000,

    padding: '1rem',
    marginTop: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',

    '&:first-child': {
      marginTop: 'auto',
    },
  },

  wrapper: {
    position: 'relative',
    textAlign: 'center',
    zIndex: 0,
    marginBottom: '1rem',

    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
      height: 1,
      background: COLORS.lightGray,

      zIndex: 0,
    },
  },

  label: {
    textTransform: 'uppercase',
    color: COLORS.textStronglyMuted,
    backgroundColor: COLORS.lightBackground,
    position: 'relative',
    padding: '.5rem 1rem',
    zIndex: 1,
    fontWeight: 500,
    fontSize: '0.9rem',
  },
}

export default MessagesGroup
