import React from 'react'

import { initials } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { PeerNotesReportDataTable } from '@behavehealth/constructs/LiveReports/PeerNotesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const PeerNotes = () => {
  const tableProps = useDataTable({
    name: ['reports', 'peer_notes'],
    endpoint: `/live_reports?category=peer_notes`,
    updateDeleteEndpoint: `/peer_notes`,
    localStorageKey: 'report_peer_notes_v1',
  })

  const to = React.useMemo(
    () => (rowData: any) => {
      return `/clients/${rowData.client?.id}/peer-notes/all/${initials(rowData.category)}/${rowData.id}`
    },
    [],
  )

  return (
    <Page feature="peer_notes" title="Live Peer Notes Report">
      <Grid gap="1rem" columns="100%">
        <PeerNotesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(PeerNotes)
