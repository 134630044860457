import React from 'react'
import size from 'lodash/size'

import Badge from '../../Badge'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Overlay from '../../../components/Overlay'
import SummonOverlay from '../../../components/SummonOverlay'

import { FilterCondition } from './FilterCondition'
import { FilterDropdownTrigger } from './FilterDropdown'

import { useStore } from '../useStore'

export const MultiOverlayFilter = (props: any) => {
  const { config, filter, onClear, onUpdate, filterKey } = props

  const removeFilter: any = useStore((state: any) => state.removeFilter)

  const [isOpen, setIsOpen] = React.useState(false)

  const handleApply = (newValues: any) => {
    if (!newValues) return

    onUpdate?.({
      condition: 'in',
      value: config?.polymorphic ? newValues : newValues.map((o) => o.id),
    })

    setIsOpen(false)
  }

  const handleClear = () => {
    removeFilter(filterKey)
    setIsOpen(false)
  }

  if (!config) return null

  const { polymorphic, selectDescription, selectTitle = (obj: any) => obj.name, showAvatar, table, icon } = config

  const filtersCount = size(filter?.value)

  return (
    <>
      <FilterCondition condition={filtersCount >= 2 ? 'is either of' : 'is'} />

      {/* <SummonOverlay overlay={<SelectorOverlay table={table} />}>
        <FilterDropdown
          isLoading={isLoading}
          isEmpty={isDataEmpty}
          isSearchEmpty={isSearchEmpty}
          activeValues={
            hasActiveValues &&
            activeValues?.map?.((item) => (
              <FilterDropdownValue key={item?.id} value={selectTitle?.(item)} showAvatar={showAvatar} avatar={item.avatar} />
            ))
          }
        ></FilterDropdown>
      </SummonOverlay> */}

      <FilterDropdownTrigger
        testKey="filter_dropdown"
        onClick={() => {
          setIsOpen(true)
        }}
        label={
          filtersCount ? (
            <>
              <Badge size={16} children={filtersCount} className="mr-1" /> selected
            </>
          ) : (
            'Select…'
          )
        }
      />

      <SummonOverlay
        isOpen={isOpen}
        portalType="radix"
        onClose={() => {
          setIsOpen(false)
        }}
        overlay={<SelectorOverlay title={config.label} icon={icon} table={table} onApply={handleApply} onClear={handleClear} />}
      />
    </>
  )
}

const SelectorOverlay = ({ table, onApply, onClose, onClear, title, icon }: any) => {
  const [selectedRows, setSelectedRows] = React.useState([])

  const handleApplyOne = (selectedRow: any) => {
    if (!selectedRow) return

    onApply([{ id: selectedRow.id, type: selectedRow.type }])
    onClose()
  }

  const handleApplyAll = () => {
    onApply(selectedRows.map((o: any) => ({ id: o.id, type: o.type })))
    onClose()
  }

  const handleClear = () => {
    onClear()
    onClose()
  }

  return (
    <Overlay showBackdrop stopPropagation position="center" maxWidth={100} onClose={onClose}>
      <Overlay.Header title={`Select ${title || 'records…'}`} icon={icon} />

      <Overlay.Content>
        <Card className="m-4">
          {React.cloneElement(table, {
            canBatchSelect: true,
            onRowSelectionUpdate: setSelectedRows,
            onSelect: handleApplyOne,
          })}
        </Card>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Apply Filter" type="primary" color="green" flex="3 1 auto" onClick={handleApplyAll} />
        <Button label="Clear All" type="default" color="red" glyph="cross" flex="1 1 auto" onClick={handleClear} />
      </Overlay.Footer>
    </Overlay>
  )
}
