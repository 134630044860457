import React from 'react'
import startCase from 'lodash/startCase'

import { useSettings } from '../../hooks'
import { daysBetween, countWord } from '../../utils/functions'

import DataFormStatus from '../Statuses/DataFormStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original)} label={value || startCase(row.original?.subcategory)} />,
  },
  {
    width: 220,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <DataFormStatus status={value} />,
    Filter: TableFilter.DataFormStatus,
    filter: 'dataFormStatus',
  },
  {
    width: 150,
    accessor: 'data.general.admitted_at',
    Header: 'Admission Date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 150,
    accessor: 'data.general.discharged_at',
    Header: 'Discharge Date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 150,
    id: 'length_of_stay',
    Header: 'Length of Stay',
    accessor: 'data.general',
    Cell: ({ value }: any) => {
      if (!value || !value.admitted_at || !value.discharged_at) return <TableCell.Empty />
      return <TableCell value={countWord('days', daysBetween(value.admitted_at, value.discharged_at, timezone))} />
    },
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Last Edited',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  isLoading: boolean
  localStorageKey: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const DischargesTable = ({ to, data, isLoading, emptyActions, batchActionsConfig, localStorageKey, titleAfter }: Props) => {
  const { timezone } = useSettings()

  return (
    <Table
      title="Discharges"
      titleAfter={titleAfter}
      testKey="discharges_table"
      icon="discharge_forms"
      data={data}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No discharge summaries added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

DischargesTable.defaultProps = {
  localStorageKey: 'discharges',
}

export default DischargesTable
