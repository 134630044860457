import React from 'react'

import FormSection from '../../components/Forms/FormSection'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootFoRSEProgressMonitoringSurveyOverlay = (props: any) => {
  return (
    <DataFormOverlay
      disableSignOff
      minimizeEnabled
      clientPortalCanEdit
      enableTreatmentPlanSelector
      title="Progress Monitoring Survey"
      category="outcome_measure"
      subcategory="forse_progress_monitoring_survey"
      icon="outcome_measures"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      requestSupervisorSignature={false}
      signoffWithoutSupervisor={true}
      {...props}
    >
      <Section
        title="Questions"
        headingType="h2"
        scrollview={{
          id: 'questions',
          name: 'Questions',
        }}
      >
        <FormSection>
          <RadioGroup
            label="Over the last 7 days, how many days have you used alcohol or other drugs?"
            model="data.past_week_alcohol_drugs_use_days"
            layout="vertical-2-columns"
          >
            <Radio label="0" value={0} />
            <Radio label="1" value={1} />
            <Radio label="2" value={2} />
            <Radio label="3" value={3} />
            <Radio label="4" value={4} />
            <Radio label="5" value={5} />
            <Radio label="6" value={6} />
            <Radio label="7" value={7} />
          </RadioGroup>

          <RadioGroup
            label="Over the last 7 days, how much were you bothered by cravings or urges to drink alcohol or use drugs?"
            model="data.past_week_cravings_days"
            layout="vertical-2-columns"
          >
            <Radio label="1 - Not at all bothered" value={1} />
            <Radio label="2" value={2} />
            <Radio label="3" value={3} />
            <Radio label="4" value={4} />
            <Radio label="5" value={5} />
            <Radio label="6" value={6} />
            <Radio label="7" value={7} />
            <Radio label="8" value={8} />
            <Radio label="9" value={9} />
            <Radio label="10 - Very bothered" value={10} />
          </RadioGroup>

          <RadioGroup
            label="How confident are you that you will be able to stay in recovery for the next 7 days or one week?"
            model="data.confidence_in_recovery_for_next_week"
            layout="vertical-2-columns"
          >
            <Radio label="1 - Not at all confident" value={1} />
            <Radio label="2" value={2} />
            <Radio label="3" value={3} />
            <Radio label="4" value={4} />
            <Radio label="5" value={5} />
            <Radio label="6" value={6} />
            <Radio label="7" value={7} />
            <Radio label="8" value={8} />
            <Radio label="9" value={9} />
            <Radio label="10 - Very confident" value={10} />
          </RadioGroup>

          <RadioGroup
            label="How much do you feel understood and supported by your counselor or primary caregiver?"
            model="data.primary_caregiver_understanding_support"
            layout="vertical-2-columns"
          >
            <Radio label="1 - Not at all supported and understood" value={1} />
            <Radio label="2" value={2} />
            <Radio label="3" value={3} />
            <Radio label="4" value={4} />
            <Radio label="5" value={5} />
            <Radio label="6" value={6} />
            <Radio label="7" value={7} />
            <Radio label="8" value={8} />
            <Radio label="9" value={9} />
            <Radio label="10 - Very supported and understood" value={10} />
          </RadioGroup>

          <RadioGroup
            label="To what extent do you feel that physical pain prevents you from doing what you need to do?"
            model="data.physical_pain"
            layout="vertical-2-columns"
          >
            <Radio label="1 - Not at all" value={1} />
            <Radio label="2" value={2} />
            <Radio label="3" value={3} />
            <Radio label="4" value={4} />
            <Radio label="5" value={5} />
            <Radio label="6" value={6} />
            <Radio label="7" value={7} />
            <Radio label="8" value={8} />
            <Radio label="9" value={9} />
            <Radio label="10 - An extreme amount" value={10} />
          </RadioGroup>

          <RadioGroup
            label="Over the last 7 days, how much have you been troubled or bothered by psychological or emotional problems?"
            model="data.past_week_psychological_or_emotional_problems"
            layout="vertical-2-columns"
          >
            <Radio label="1 - Not at all" value={1} />
            <Radio label="2" value={2} />
            <Radio label="3" value={3} />
            <Radio label="4" value={4} />
            <Radio label="5" value={5} />
            <Radio label="6" value={6} />
            <Radio label="7" value={7} />
            <Radio label="8" value={8} />
            <Radio label="9" value={9} />
            <Radio label="10 - An extreme amount" value={10} />
          </RadioGroup>
        </FormSection>
      </Section>
    </DataFormOverlay>
  )
}

export const FoRSEProgressMonitoringSurveyOverlay = withOverlayError(RootFoRSEProgressMonitoringSurveyOverlay)
