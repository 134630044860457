import React from 'react'

import Checkbox from '../Checkbox'
import CheckboxGroup from '../CheckboxGroup'
import ContextShow from '../ContextShow'
import EmailInput from '../EmailInput'
import PhoneInput from '../PhoneInput'

const ContactMethodsCheckboxGroup = ({ label, model, isRequired }: any) => (
  <>
    <CheckboxGroup
      label={label}
      layout="vertical-dense"
      trueIcon="check"
      falseIcon="cross"
      falseStyle="faded"
      validations={
        isRequired && {
          presence: {
            message: 'Please select at least one item',
          },
        }
      }
    >
      <Checkbox label="Cell Phone Number" model={`${model}.has_phone_no`} />
      <Checkbox label="Landline Phone Number" model={`${model}.has_landline_no`} />
      <Checkbox label="Email Address" model={`${model}.has_email`} />
    </CheckboxGroup>

    <ContextShow when={`${model}.has_phone_no`} is={true}>
      <PhoneInput
        label="Cell Phone Number"
        model={`${model}.phone_no`}
        validations={{
          presence: {
            message: 'Please enter your cell phone number',
          },
        }}
      />
    </ContextShow>

    <ContextShow when={`${model}.has_landline_no`} is={true}>
      <PhoneInput
        label="Landline Phone Number"
        model={`${model}.landline_no`}
        glyph="landline"
        validations={{
          presence: {
            message: 'Please enter your landline phone number',
          },
        }}
      />
    </ContextShow>

    <ContextShow when={`${model}.has_email`} is={true}>
      <EmailInput
        label="Email Address"
        model={`${model}.email`}
        validations={{
          presence: {
            message: 'Please enter your email address',
          },
        }}
      />
    </ContextShow>
  </>
)

ContactMethodsCheckboxGroup.defaultProps = {
  label: 'Contact Methods',
}

export default ContactMethodsCheckboxGroup
