import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { NewInsuranceBillingReportDataTable } from '@behavehealth/constructs/LiveReports/NewInsuranceBillingReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const NewInsuranceBilling = () => {
  const tableProps = useDataTable({
    name: ['reports', 'new-insurance-billing'],
    endpoint: `/live_reports?category=new_insurance_billing`,
    localStorageKey: 'report_new_insurance_billing_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getClientLink(rowData.client)}/insurance`, [])

  return (
    <Page icon="insurance" title="Live Insurance Billing Report">
      <Grid>
        <NewInsuranceBillingReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(NewInsuranceBilling)
