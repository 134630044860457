const LOCAL_STORAGE_KEY = 'bh.tables'

export const setTableSettings = (localStorageKey: string, settings: any) => {
  if (!localStorageKey || !settings) return null

  // set main key if it doesn't exist
  if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({}))
  }

  // update table settings
  const current = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
  current[localStorageKey] = settings

  // save updated settings
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(current))
}

export const getTableSettings = (localStorageKey: string) => {
  if (!localStorageKey || !localStorage.getItem(LOCAL_STORAGE_KEY)) return null

  const current = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
  return current[localStorageKey]
}

export const clearTableSettings = (localStorageKey: string) => {
  if (!localStorageKey) return null

  const current = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
  if (current[localStorageKey]) delete current[localStorageKey]

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(current))
}

export const clearTableSortingSettings = (localStorageKey: string) => {
  if (!localStorageKey) return null

  const current = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
  if (current[localStorageKey] && current[localStorageKey]['sortBy']) {
    delete current[localStorageKey]['sortBy']
  }

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(current))
}
