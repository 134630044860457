import React from 'react'

const Verifications = () => {
  return (
    <div>
      <h1>Verifications</h1>
    </div>
  )
}

export default Verifications
