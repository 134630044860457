import React from 'react'

import { countWord } from '../utils/functions'
import { Program } from '../declarations/Program'

import { Text } from './Typography'
import Flex from './Flex'

type Props = {
  program: Program
}

const ProgramProfileHeader: React.FC<Props> = ({ program }) => {
  if (!program) return null

  return (
    <Flex gap={6}>
      <Text avatar={program.avatar} label={program.name} />
      {/* <Text>{`${countWord('week', program.weeks_duration)}`}</Text> */}
    </Flex>
  )
}

export default ProgramProfileHeader
