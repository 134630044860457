import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useAPI from '@behavehealth/hooks/useAPI'

import { Button, Card, Page, HelpTagIframe } from '@behavehealth/components'
import LegalAgreementsTable from '@behavehealth/components/Tables/LegalAgreementsTable'

import LegalAgreementTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/LegalAgreementTemplatesImportOverlay'
import { LegalAgreementOverlayV6 } from '@behavehealth/components/Overlays/pages/Agreements/LegalAgreementOverlay'

const LegalAgreements = () => {
  return (
    <>
      <LegalAgreementsIndex />

      <AnimatedRoutes>
        <Route path="import" element={<LegalAgreementTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<LegalAgreementOverlayV6 useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const LegalAgreementsIndex: React.FC = () => {
  const user = useSelector((state: any) => state.me?.user)
  const isBehave = user?.type === 'bh_employee'

  const data = useSelector((state: any) => state.data?.agreements?.data)
  const loading = useSelector((state: any) => state.data?.agreements?.loading)
  const isEmpty = size(data) === 0

  useAPI('agreements', '/agreements?variant=legal')

  return (
    <Page
      title="Legal Agreements"
      feature="legal_agreements"
      actions={isBehave && <Button as={Link} label="Import Behave Templates" glyph="add" color="blue" type="primary" link="import" />}
      help={<HelpTagIframe id="settings_legal_agreements" />}
    >
      <Card>
        <LegalAgreementsTable mainLinkAs={NavLink} data={data} isLoading={isEmpty && loading} to={(id: string) => id} />
      </Card>
    </Page>
  )
}

export default withPageError(LegalAgreements)
