import React from 'react'
import { Tenant } from '@behavehealth/declarations'
import { useSelector } from 'react-redux'

import { apiUpdate } from '@behavehealth/modules/api'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Form, FormSection, Input, Page, Grid, HelpTagIframe, Chotomate, Accordion } from '@behavehealth/components'

const pageConfig = {
  feature: 'company_integrations',
  title: 'Company Integrations',
}

const Notifications: React.FC = () => {
  const record: Tenant = useSelector((state: any) => state.me?.tenant)

  return (
    <Page title="Notifications" feature="notifications" plan="lite" help={<HelpTagIframe id="settings_company_settings" />}>
      <Grid>
        <Chotomate name="company_profile" ready={record} />

        <Accordion
          isOpen={true}
          activateEditMode
          initialModel={record}
          title="Account Owner"
          description="Set the company's preference for Owner contact details"
          permission="settings.company_profile.account_owner.view"
          editPermission="settings.company_profile.account_owner.edit"
          onSubmit={(data) =>
            apiUpdate({
              name: 'tenant',
              url: '/me/tenant',
              params: { contacts: Object.assign({}, record.contacts, data.contacts) },
              reducer: 'me',
            })
          }
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input
                model="contacts.owner.email"
                type="email"
                label="Email Address"
                validations={{
                  presence: {
                    message: 'Please enter a valid email',
                  },
                }}
              />
              <Input
                model="contacts.owner.phone_no"
                type="phone"
                label="Phone Number"
                validations={{
                  presence: {
                    message: 'Please enter a valid phone number',
                  },
                }}
              />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen={true}
          activateEditMode
          initialModel={record}
          title="Roles & Permissions"
          description="Set the company's preference for Roles & Permissions contact details"
          permission="settings.company_profile.roles_and_permissions.view"
          editPermission="settings.company_profile.roles_and_permissions.edit"
          onSubmit={(data) =>
            apiUpdate({
              name: 'tenant',
              url: '/me/tenant',
              params: { contacts: Object.assign({}, record.contacts, data.contacts) },
              reducer: 'me',
            })
          }
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input
                model="contacts.roles_permissions.email"
                type="email"
                label="Email Address"
                validations={{
                  presence: {
                    message: 'Please enter a valid email',
                  },
                }}
              />

              <Input
                model="contacts.roles_permissions.phone_no"
                type="phone"
                label="Phone Number"
                validations={{
                  presence: {
                    message: 'Please enter a valid phone number',
                  },
                }}
              />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen={true}
          activateEditMode
          initialModel={record}
          title="Admissions"
          description="Set the company's preference for Admissions contact details"
          permission="settings.company_profile.admissions.view"
          editPermission="settings.company_profile.admissions.edit"
          onSubmit={(data) =>
            apiUpdate({
              name: 'tenant',
              url: '/me/tenant',
              params: { contacts: Object.assign({}, record.contacts, data.contacts) },
              reducer: 'me',
            })
          }
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input
                model="contacts.admissions.email"
                type="email"
                label="Email Addresses"
                description="Emails support multiple recipients, separated by a comma (,)"
              />
              <Input model="contacts.admissions.phone_no" type="phone" label="Phone Number" />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen={true}
          activateEditMode
          initialModel={record}
          title="Billing"
          description="Set the company's preference for Inbound Billing Communications from Clients or other Organizations"
          permission="settings.company_profile.billing.view"
          editPermission="settings.company_profile.billing.edit"
          onSubmit={(data) =>
            apiUpdate({
              name: 'tenant',
              url: '/me/tenant',
              params: { contacts: Object.assign({}, record.contacts, data.contacts) },
              reducer: 'me',
            })
          }
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input model="contacts.billing.email" type="email" label="Email Address" />
              <Input model="contacts.billing.phone_no" type="phone" label="Phone Number" />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen={true}
          activateEditMode
          initialModel={record}
          title="SMS Auto-Reply"
          description="Set the company's preference for SMS Auto-Reply contact details"
          permission="settings.company_profile.sms_auto_reply.view"
          editPermission="settings.company_profile.sms_auto_reply.edit"
          onSubmit={(data) =>
            apiUpdate({
              name: 'tenant',
              url: '/me/tenant',
              params: { contacts: Object.assign({}, record.contacts, data.contacts) },
              reducer: 'me',
            })
          }
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input model="contacts.sms_auto_reply.email" type="email" label="Email Address" />
              <Input model="contacts.sms_auto_reply.phone_no" type="phone" label="Phone Number" />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen={true}
          activateEditMode
          initialModel={record}
          title="Forms Submissions"
          description="Set the company's preference for Form Submissions contact details"
          permission="settings.company_profile.forms_submissions.view"
          editPermission="settings.company_profile.forms_submissions.edit"
          onSubmit={(data) =>
            apiUpdate({
              name: 'tenant',
              url: '/me/tenant',
              params: { contacts: Object.assign({}, record.contacts, data.contacts) },
              reducer: 'me',
            })
          }
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input
                alwaysShowDescription
                model="contacts.form_submissions.email"
                type="email"
                label="Email Address"
                description="Emails support multiple recipients, separated by a comma (,)"
              />
              <Input
                alwaysShowDescription
                model="contacts.form_submissions.phone_no"
                type="phone"
                label="Phone Number"
                description="SMS supports only a single recipient"
              />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen={true}
          activateEditMode
          initialModel={record}
          title="Community Profile Admin Notifications"
          description="Set the company's preference for Community Notifications"
          permission="settings.company_profile.community_profile_admin_notifications.view"
          editPermission="settings.company_profile.community_profile_admin_notifications.edit"
          onSubmit={(data) =>
            apiUpdate({
              name: 'tenant',
              url: '/me/tenant',
              params: { contacts: Object.assign({}, record.contacts, data.contacts) },
              reducer: 'me',
            })
          }
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input
                alwaysShowDescription
                model="contacts.community.email"
                type="email"
                label="Email Addresses"
                description="Emails support multiple recipients, separated by a comma (,)"
              />
              <Input
                alwaysShowDescription
                model="contacts.community.phone_no"
                type="phone"
                label="Phone Numbers"
                description="SMS supports multiple recipients, separated by a comma (,)"
              />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen={true}
          activateEditMode
          initialModel={record}
          title="Company Credentials"
          description="Set the company's preference for Company Credential Notifications"
          permission="settings.company_profile.credential_notifications.view"
          editPermission="settings.company_profile.credential_notifications.edit"
          onSubmit={(data) =>
            apiUpdate({
              name: 'tenant',
              url: '/me/tenant',
              params: { contacts: Object.assign({}, record.contacts, data.contacts) },
              reducer: 'me',
            })
          }
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input
                alwaysShowDescription
                model="contacts.company_credentials.email"
                type="email"
                label="Email Addresses"
                description="Emails support multiple recipients, separated by a comma (,)"
              />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen={true}
          activateEditMode
          initialModel={record}
          title="Staff Credentials"
          description="Set the company's preference for Staff Credential Notifications"
          permission="settings.company_profile.credential_notifications.view"
          editPermission="settings.company_profile.credential_notifications.edit"
          onSubmit={(data) =>
            apiUpdate({
              name: 'tenant',
              url: '/me/tenant',
              params: { contacts: Object.assign({}, record.contacts, data.contacts) },
              reducer: 'me',
            })
          }
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input
                alwaysShowDescription
                model="contacts.staff_credentials.email"
                type="email"
                label="Email Addresses"
                description="Emails support multiple recipients, separated by a comma (,)"
              />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen={true}
          activateEditMode
          initialModel={record}
          title="Agreement Reminders"
          description="Set the company's preference for Agreement Reminder Notifications"
          permission="settings.company_profile.agreement_reminders.view"
          editPermission="settings.company_profile.agreement_reminders.edit"
          onSubmit={(data) =>
            apiUpdate({
              name: 'tenant',
              url: '/me/tenant',
              params: { contacts: Object.assign({}, record.contacts, data.contacts) },
              reducer: 'me',
            })
          }
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input
                alwaysShowDescription
                model="contacts.agreement_reminders.email"
                type="email"
                label="Email Addresses"
                description="Emails support multiple recipients, separated by a comma (,)"
              />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion
          isOpen={true}
          activateEditMode
          initialModel={record}
          title="File Reminders"
          description="Set the company's preference for File Reminder Notifications"
          permission="settings.company_profile.file_reminders.view"
          editPermission="settings.company_profile.file_reminders.edit"
          onSubmit={(data) =>
            apiUpdate({
              name: 'tenant',
              url: '/me/tenant',
              params: { contacts: Object.assign({}, record.contacts, data.contacts) },
              reducer: 'me',
            })
          }
        >
          <Form>
            <FormSection layout="horizontal" labelWidth={150}>
              <Input
                alwaysShowDescription
                model="contacts.file_reminders.email"
                type="email"
                label="Email Addresses"
                description="Emails support multiple recipients, separated by a comma (,)"
              />
            </FormSection>
          </Form>
        </Accordion>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Notifications, pageConfig))
