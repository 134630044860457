import React from 'react'

import SmartStatus from '../SmartStatus'

export const statuses = {
  new: {
    label: '0. Application Not Sent',
    color: 'red',
  },
  lead: {
    label: '0. Application Not Sent',
    color: 'red',
  },
  sent: {
    label: '1. Application Sent',
    color: 'blue',
  },
  opened: {
    label: '2. Application Opened',
    color: 'blue',
  },
  partial_completion: {
    label: '3. Partial Completion',
    color: 'yellow',
  },
  submitted: {
    label: '4. Application Submitted',
    color: 'green',
  },
  intake: {
    label: 'Accepted For Intake',
    color: 'green',
  },
  declined: {
    label: 'Application Declined',
    color: 'red',
  },
  archived: {
    label: 'Application Archived',
    color: 'gray',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
