import React from 'react'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import FormSection from '../../../../Forms/FormSection'
import Input from '../../../../Forms/Input'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const RelapsePotentialV1 = () => {
  return (
    <FormSection layout="vertical">
      <CheckboxGroup layout="vertical-dense" label="Current Progress Barriers:">
        <Checkbox label="Anxiety" model="data.relapse_potential.anxiety" />
        <Checkbox label="Anger management" model="data.relapse_potential.anger_management" />
        <Checkbox label="Impulsivity" model="data.relapse_potential.impulsivity" />
        <Checkbox label="Depression" model="data.relapse_potential.depression" />
        <Checkbox
          label="Home environment not conducive to recovery"
          model="data.relapse_potential.home_environment_not_conducive_to_recovery"
        />
        <Checkbox label="Poor engagement" model="data.relapse_potential.poor_engagement" />
        <Checkbox label="Unresolved trauma" model="data.relapse_potential.unresolved_trauma" />
        <Checkbox label="Inability to admit past trauma" model="data.relapse_potential.inability_to_admit_past_trauma" />
        <Checkbox label="Limited insight" model="data.relapse_potential.limited_insight" />
        <Checkbox label="Lack of sober support" model="data.relapse_potential.lack_of_sober_support" />
        <Checkbox label="Non-compliance with prior treatment" model="data.relapse_potential.non_compliance_with_prior_treatment" />
        <Checkbox label="Defiant attitude" model="data.relapse_potential.defiant_attitude" />
        <Checkbox label="Family dysfunction" model="data.relapse_potential.family_dysfunction" />
        <Checkbox label="Co-occurring disorders" model="data.relapse_potential.co_occurring_disorders" />
        <Checkbox label="Poor judgment" model="data.relapse_potential.poor_judgment" />
        <Checkbox label="Difficulty managing distractions" model="data.relapse_potential.difficulty_managing_distractions" />
        <Checkbox label="Limited knowledge of recovery" model="data.relapse_potential.limited_knowledge_of_recovery" />
        <Checkbox
          label="Non-compliance with prescribed psychotropic medication"
          model="data.relapse_potential.non_compliance_with_prescribed_psychotropic_medication"
        />
        <Checkbox label="Denial" model="data.relapse_potential.denial" />
        <Checkbox label="Co-dependence issues" model="data.relapse_potential.co_dependence_issues" />
        <Checkbox label="Other" model="data.relapse_potential.other" />
      </CheckboxGroup>

      <ContextShow when="data.relapse_potential.other" is={true}>
        <SmartTextarea
          useDictation
          label="Other"
          model="data.relapse_potential.other_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        label="Has the client ever tried to quit using drugs/alcohol on their own?"
        model="data.relapse_potential.has_tried_using_on_their_own"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.relapse_potential.has_tried_using_on_their_own" is={true}>
        <Input label="How many times?" model="data.relapse_potential.has_tried_using_on_their_own_times" size={4} suffix="times" />
      </ContextShow>

      <RadioGroup
        label="Has the client had any significant periods of sobriety, either on their own or with treatment?"
        model="data.relapse_potential.has_significant_lengths_of_sobriety"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.relapse_potential.has_significant_lengths_of_sobriety" is={true}>
        <SmartTextarea
          useDictation
          label="Please describe the reasons the client believes these attempts at sobriety failed."
          model="data.relapse_potential.has_significant_lengths_of_sobriety_reasons"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        label="Is the client aware of any situations or feelings that they think would provoke a relapse?"
        model="data.relapse_potential.has_relapse_feelings"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.relapse_potential.has_relapse_feelings" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.relapse_potential.has_relapse_feelings_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        label="What is your current craving level? (1 = low, 5 = high)"
        model="data.relapse_potential.craving_level"
        layout="horizontal"
      >
        <Radio label="1" value="1" />
        <Radio label="2" value="2" />
        <Radio label="3" value="3" />
        <Radio label="4" value="4" />
        <Radio label="5" value="5" />
      </RadioGroup>

      <RadioGroup
        label="Is the client interested in anti-craving medication?"
        model="data.relapse_potential.is_interested_in_anti_craving_medication"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.relapse_potential.is_interested_in_anti_craving_medication" is={true}>
        <SmartTextarea
          useDictation
          label="What medications would the client consider?"
          model="data.relapse_potential.anti_craving_medications_considered"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <ContextShow when="data.relapse_potential.is_interested_in_anti_craving_medication" is={false}>
        <SmartTextarea
          useDictation
          label="Why not?"
          model="data.relapse_potential.reason_for_no_interest_in_anti_craving_medication"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>
    </FormSection>
  )
}

export default RelapsePotentialV1
