import React from 'react'

import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ContextShow from '../../Forms/ContextShow'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Textarea from '../../Forms/Textarea'
import Tooltip from '../../Tooltip'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

type Props = {
  isHealthcare: boolean
  isHealthcareFacility: boolean
}

export const GeneralInformationShort: React.FC = () => {
  return (
    <>
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="general_info" />
        <Type as="h2" variant="H3">
          General Information
        </Type>
      </Flex>

      <Textarea
        label="How do you currently manage your information? Do you have any unmet needs you are looking to resolve?"
        model="questionnaire.general_information.how_do_you_manage_information"
        labelAfter={
          <Tooltip content="e.g. We currently use XYZ software and have paper HR records. We are always looking for information and we need one place to store it all." />
        }
        validations={{
          length: {
            minimum: 10,
            tooShort: 'Please enter %{count} or more characters',
          },
        }}
      />

      <Textarea
        label="Who is involved in the evaluation and decision around a new information management system?"
        labelAfter={
          <Tooltip content="e.g. I am the office manager and I am handling the research phase.  The owner and clinical director will be making the decision together." />
        }
        model="questionnaire.general_information.who_is_involved"
        validations={{
          length: {
            minimum: 10,
            tooShort: 'Please enter %{count} or more characters',
          },
        }}
      />

      <Textarea
        label="What is your timeline for having a new software solution in place?"
        labelAfter={
          <Tooltip content="e.g. We really need a solution in place before the start of the new year and before our next certification inspection." />
        }
        model="questionnaire.general_information.what_is_your_timeline"
        validations={{
          length: {
            minimum: 10,
            tooShort: 'Please enter %{count} or more characters',
          },
        }}
      />
    </>
  )
}

export const GeneralInformationFields: React.FC<Props> = ({ isHealthcareFacility, isHealthcare }) => {
  return (
    <FormSection maxWidth="650px">
      <GeneralInformationShort />

      <CheckboxGroup
        trueIcon="check"
        falseIcon="cross"
        falseStyle="faded-linethrough"
        label="Which best describes the operator of this account?"
        layout="vertical-dense"
      >
        <Checkbox
          label="A private for-profit organization"
          model="questionnaire.general_information.operator.private_for_profit_organization"
        />
        <Checkbox
          label="A private non-profit organization"
          model="questionnaire.general_information.operator.private_non_profit_organization"
        />
        <Checkbox label="A government organization" model="questionnaire.general_information.operator.government_organization" />
      </CheckboxGroup>

      <ContextShow when="questionnaire.general_information.operator.government_organization" is={true}>
        <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" layout="vertical-dense">
          <Checkbox label="Federal Government" model="questionnaire.general_information.operator.federal_government" />
          <Checkbox label="State government" model="questionnaire.general_information.operator.state_government" />
          <Checkbox
            label="Local, county, or community government"
            model="questionnaire.general_information.operator.local_county_or_community_government"
          />
          <Checkbox label="Tribal government" model="questionnaire.general_information.operator.tribal_government" />
          <Checkbox label="Other" model="questionnaire.general_information.operator.other" />
        </CheckboxGroup>
      </ContextShow>

      <ContextShow when="questionnaire.general_information.operator.other" is={true}>
        <Textarea label="Other" model="questionnaire.general_information.operator.other_notes" />
      </ContextShow>

      {isHealthcareFacility && (
        <>
          <CheckboxGroup
            trueIcon="check"
            falseIcon="cross"
            falseStyle="faded-linethrough"
            label="From which of these agencies or organizations does this facility have licensing, certification, or accreditation?"
            layout="vertical-dense"
          >
            <Checkbox
              label="State mental health authority"
              model="questionnaire.general_information.licensing.state_mental_health_authority"
            />
            <Checkbox
              label="State substance use treatment agency"
              model="questionnaire.general_information.licensing.state_substance_use_treatment_agency"
            />
            <Checkbox label="State department of health" model="questionnaire.general_information.licensing.state_department_of_health" />
            <Checkbox
              label="State or local Department of Family and Children’s Services"
              model="questionnaire.general_information.licensing.state_or_local_department"
            />
          </CheckboxGroup>

          <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" label=" " layout="vertical-dense">
            <Checkbox
              label="Hospital licensing authority"
              model="questionnaire.general_information.licensing.hospital_licensing_authority"
            />
            <Checkbox label="The Joint Commission" model="questionnaire.general_information.licensing.joint_commission" />
            <Checkbox
              label="Commission on Accreditation of Rehabilitation Facilities (CARF)"
              model="questionnaire.general_information.licensing.carf"
            />
            <Checkbox label="Council on Accreditation (COA)" model="questionnaire.general_information.licensing.coa" />
          </CheckboxGroup>

          <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" label=" " layout="vertical-dense">
            <Checkbox label="Centers for Medicare and Medicaid Services (CMS)" model="questionnaire.general_information.licensing.cms" />
            <Checkbox
              label="Other national organization, or federal, state, or local agency"
              model="questionnaire.general_information.licensing.other"
            />
            <Checkbox
              label="This facility does not have licensing, certification, or accreditation from any of these organizations"
              model="questionnaire.general_information.licensing.no_licensing"
            />
          </CheckboxGroup>

          <ContextShow when="questionnaire.general_information.licensing.other" is={true}>
            <Textarea label="Other" model="questionnaire.general_information.licensing.other_notes" />
          </ContextShow>
        </>
      )}

      {isHealthcare && (
        <>
          <CheckboxGroup
            trueIcon="check"
            falseIcon="cross"
            falseStyle="faded-linethrough"
            label="What is the primary focus of this account?"
            layout="vertical-dense"
          >
            <Checkbox
              label="Substance abuse treatment services"
              model="questionnaire.general_information.primary_focus.substance_abuse_treatment_services"
            />
            <Checkbox label="Mental health services" model="questionnaire.general_information.primary_focus.mental_health_services" />
            <Checkbox
              label="Mix of mental health and substance abuse treatment services (neither is primary)"
              model="questionnaire.general_information.primary_focus.mental_health_and_substance_abuse_treatment_services"
            />
            <Checkbox label="General health care" model="questionnaire.general_information.primary_focus.general_health_care" />
            <Checkbox label="Other" model="questionnaire.general_information.primary_focus.other" />
          </CheckboxGroup>

          <ContextShow when="questionnaire.general_information.primary_focus.other" is={true}>
            <Textarea label="Other" model="questionnaire.general_information.primary_focus.other_notes" />
          </ContextShow>
        </>
      )}
    </FormSection>
  )
}

export const GeneralInformation: React.FC<Props> = ({ isHealthcareFacility, isHealthcare }: any) => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <GeneralInformationFields isHealthcareFacility={isHealthcareFacility} isHealthcare={isHealthcare} />
    </Form>
  )
}
