import size from 'lodash/size'

export const formatCellCoords = (columnIndex: number, rowIndex: number) => {
  return `${columnIndex}-${rowIndex}`
}

export const parseCellCoords = (coords: string) => {
  const result: any = { x: null, y: null }

  if (!coords) return result

  const splitCoords = coords.split('-')

  if (size(splitCoords) === 2) {
    result.x = parseInt(splitCoords[0])
    result.y = parseInt(splitCoords[1])
  }

  return result
}
