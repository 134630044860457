import set from 'lodash/set'
import produce from 'immer'
import createVanilla from 'zustand/vanilla'
import get from 'lodash/get'

import { devtools } from 'zustand/middleware'
import { v4 as uuid } from 'uuid'
import { singular, objectToMap } from '../../utils/functions'

const DEBUG = false
const DEBOUNCE = 0

export const formStoreFunction = (set: Function) => ({
	value: {},

	isValid: false,
	isInvalid: true,

	isPristine: true,
	isDirty: false,

	lastUpdatedInput: null,

	update: (newState: any) => {
		set(
			produce((draft: any) => {
				Object.assign(draft, newState)
			})
		)
	}
})

export class BHFormEngine {
	constructor({ initialValue = {}, linkedValue = {}, useFullModel = false, deregisterOnUnmount = true }) {
		this.id = uuid()

		// options
		this.useFullModel = useFullModel
		this.deregisterOnUnmount = deregisterOnUnmount
		this.initialValue = produce(initialValue, (_draft: any) => {})

		// inputs maps
		this.inputs = new Map()
		this.values = new Map()
		this.initialValues = objectToMap(this.initialValue)
		this.unmappedValues = objectToMap(this.initialValue)
		this.linkedValues = objectToMap(linkedValue)
		this.contextInputs = new Map()
		this.requiredInputs = new Map()
		this.invalidInputs = new Map()

		this.applyDecorations = null

		// values
		this.value = {}

		// valid
		this.isValid = true
		this.isInvalid = !this.isValid

		// pristine
		this.isPristine = true
		this.isDirty = !this.isPristine

		// touched
		this.isTouched = false
		this.isUntouched = !this.isTouched

		// queues
		this.urgentEventsQueue = []
		this.eventsQueue = []
		this.processedEventsQueue = []

		this.store =
			process.env.NODE_ENV !== 'production'
				? createVanilla(devtools(formStoreFunction, { name: `BHForm - ${this.id}` }))
				: createVanilla(formStoreFunction)
	}

	// * Queue Events
	resetForm = () => this.queue('urgent', { type: 'RESET_FORM' })
	highlightInvalid = () => this.queue('urgent', { type: 'HIGHLIGHT_INVALID' })

	register = (input: any) => this.queue('urgent', { type: 'REGISTER', model: input.model, input: input })
	deregister = (input: any) => this.queue('urgent', { type: 'DEREGISTER', model: input.model, input: input })
	updateInitialValue = (value: any) => this.queue('urgent', { type: 'UPDATE_INITIAL_VALUE', value: value })

	focus = (input: any) => this.queue('normal', { type: 'FOCUS', input: input })
	blur = (input: any) => this.queue('normal', { type: 'BLUR', input: input })
	change = (input: any) => this.queue('normal', { type: 'CHANGE', input: input })
	validate = (input: any) => this.queue('normal', { type: 'VALIDATE', input: input })
	reset = (input: any) => this.queue('normal', { type: 'RESET', input: input })

	// * Queue
	queue = (type = 'normal', data: any) => {
		if (type === 'urgent') {
			this.urgentEventsQueue.push(data)
		} else if (type === 'normal') {
			this.eventsQueue.push(data)
		}

		if (DEBUG) console.debug('QUEUED: ', data)

		this.process()
	}

	// * Process
	process = () => {
		// run all the Input Level functionality
		if (this.urgentEventsQueue.length > 0) {
			// process important queue
			let event = this.urgentEventsQueue.shift()

			// process
			switch (event.type) {
				case 'REGISTER':
					this.registerInput(event.input)
					this.updateInputValue(event.input)
					this.updateInputValidation(event.input)

					break

				case 'DEREGISTER':
					this.deregisterInput(event.input)

					break

				case 'RESET_FORM':
					this.inputs.forEach((input: any) => {
						if (input?.reset) input.reset()
					})

					break

				case 'HIGHLIGHT_INVALID':
					let firstFound = false
					this.invalidInputs.forEach((input: any) => {
						if (input.highlight) input.highlight()

						// set first found
						if (!firstFound && input.scrollIntoView) input.scrollIntoView()
						firstFound = true
					})

					break

				case 'UPDATE_INITIAL_VALUE':
					this.initialValue = produce(event.value, (_draft: any) => {})
					this.initialValues = objectToMap(event.value)

					break
			}

			this.processedEventsQueue.push(event)

			if (DEBUG) console.debug('URGENT: ', event)

			// go recursively through it until there is nothing to process
			this.process()
		} else if (this.eventsQueue.length > 0) {
			// process normal queue
			let event = this.eventsQueue.shift()

			// process
			switch (event.type) {
				case 'FOCUS':
					this.updateInputValidation(event.input)

					// update touched
					this.isTouched = true
					this.isUntouched = false

					break
				case 'BLUR':
					this.updateInputValue(event.input)
					this.updateInputValidation(event.input)

					break
				case 'CHANGE':
					this.updateInputValue(event.input)

					// update pristine
					this.isPristine = false
					this.isDirty = true

					break
				case 'RESET':
					this.updateInputValue(event.input)

					break
				case 'VALIDATE':
					this.updateInputValidation(event.input)
					break
			}

			this.processedEventsQueue.push(event)

			if (DEBUG) console.debug('NORMAL: ', event)

			// go recursively through it until there is nothing to process
			this.process()
		}

		// run all the Form Level functionality
		this.updateFormValues()
		this.updateFormValidation()
		this.updateFormChangeStatus()
	}

	// * INPUTS type functions
	registerInput = (input: any) => {
		this.inputs.set(input.id, input)
		this.unmappedValues.delete(input.model)

		// check if we should add it to contexts
		if (input.type === 'CONTEXT_SHOW' || input.type === 'CONTEXT_HIDE') {
			this.contextInputs.set(input.id, input)
		}
	}

	deregisterInput = (input: any) => {
		if (!this.deregisterOnUnmount) return

		this.inputs.delete(input.id)
		this.contextInputs.delete(input.id)
		this.requiredInputs.delete(input.id)
		this.invalidInputs.delete(input.id)

		// clean up the values based on models
		const models = this.findAllModelsForInput(input)
		models.forEach((model: any) => this.values.delete(model))
	}

	updateInputValue = (input: any) => {
		if (!input.model) return // skip if the input doesn't have a model defined
		if (!this.inputs.has(input.id)) return // skip value if the input is not in the list anymore

		const asRelation = !!input?.isRelation
		const asRelations = !!input?.isRelations
		const asPolymorphic = !!input?.isPolymorphic
		const asNested = !!input?.isNested

		// process as value or as object
		if (asRelation) {
			if (input.model) this.values.set(`${input.model}_id`, input?.value?.[input?.modelSelector || 'id'] || null)
		} else if (asRelations) {
			if (!Array.isArray(input.value)) {
				console.error(`Input (${input.model}) has the wrong type of value: ${input.value}`)
			}

			this.values.set(`${singular(input.model)}_ids`, input.value?.map((o: any) => o.id) || [])
		} else if (asNested) {
			// add _attributes if nested
			let modelBefore = input.model
			let modelAfter = ''

			// process model if using array notation
			const modelArrayIndex = input.model.indexOf('[')
			const hasArrayInModel = modelArrayIndex > 0
			if (hasArrayInModel) {
				modelBefore = input.model.slice(0, modelArrayIndex)
				modelAfter = input.model.slice(modelArrayIndex)
			}

			// process model if using dot notation
			const modelDotNotationIndex = input.model.indexOf('.')
			const hasDotNotation = modelDotNotationIndex > 0
			if (!hasArrayInModel && hasDotNotation) {
				modelBefore = input.model.slice(0, modelDotNotationIndex)
				modelAfter = input.model.slice(modelDotNotationIndex)
			}

			// build final nested model + value
			const nestedModel = [modelBefore, '_attributes', modelAfter].join('')
			this.values.set(nestedModel, input.value || null)
		} else {
			// default
			this.values.set(input.model, input.value)
		}

		// add Type if polymorphic
		if (asPolymorphic && input.value) this.values.set(`${input.model}_type`, input.value.type || null)

		const updateStore = this.store.getState().update
		updateStore({ lastUpdatedInput: input.model })
	}

	updateInputState = (input: any) => {
		this.inputs.set(input.id, input)
	}

	updateInputValidation = (input: any) => {
		if (!input) return

		// check if we should add it to requireds
		if (input.isValidation && input.isValidations.hasOwnProperty('presence')) {
			this.requiredInputs.set(input.id, input)
		}

		// process validity
		if (input.hasOwnProperty('isValid')) {
			if (input.isValid) this.invalidInputs.delete(input.id)
			else this.invalidInputs.set(input.id, input)
		}
	}

	findAllModelsForInput = (input: any) => {
		let models = []

		const asRelation = !!input?.isRelation
		const asRelations = !!input?.isRelations
		const asPolymorphic = !!input?.isPolymorphic
		const asNested = !!input?.isNested

		// process as value or as object
		if (asRelation) models.push(`${input.model}_id`)
		else if (asRelations) models.push(`${singular(input.model)}_ids`)
		else models.push(input.model)

		if (asPolymorphic) models.push(`${input.model}_type`)

		// add _attributes if nested
		if (asNested) {
			let modelBefore = input.model
			let modelAfter = ''

			// process model if using array notation
			const modelArrayIndex = input.model.indexOf('[')
			const hasArrayInModel = modelArrayIndex > 0
			if (hasArrayInModel) {
				modelBefore = input.model.slice(0, modelArrayIndex)
				modelAfter = input.model.slice(modelArrayIndex)
			}

			// process model if using dot notation
			const modelDotNotationIndex = input.model.indexOf('.')
			const hasDotNotation = modelDotNotationIndex > 0
			if (!hasArrayInModel && hasDotNotation) {
				modelBefore = input.model.slice(0, modelDotNotationIndex)
				modelAfter = input.model.slice(modelDotNotationIndex)
			}

			models.push([modelBefore, '_attributes', modelAfter].join(''))
		}

		return models
	}

	// * FORM type functions
	updateFormValues = () => {
		// concatenate all values into one object
		this.value = produce({}, (draft: any) => {
			// include the unmapped values to the final value if wanting to have a full model
			if (this.useFullModel) {
				this.unmappedValues.forEach((value: any, key: string) => {
					set(draft, key, value)
				})
			} else {
				// include the IDs automatically
				this.unmappedValues.forEach((value: any, key: string) => {
					if (key.endsWith('.id') || key.endsWith('_id')) set(draft, key, value)
				})
			}

			// set the new values
			try {
				this.values.forEach((value: any, key: string) => {
					set(draft, key, value)
				})
			} catch (error) {
				console.error(error)
			}

			if (this.linkedValues.size > 0) {
				this.linkedValues.forEach((value: any, key: string) => {
					set(draft, key, value)
				})
			}
		})

		const updateStore = this.store.getState().update
		updateStore({ value: this.value })
	}

	updateFormValidation = () => {
		this.isValid = this.invalidInputs.size === 0
		this.isInvalid = !this.isValid

		const updateStore = this.store.getState().update
		updateStore({ isValid: this.isValid, isInvalid: this.isInvalid })
	}

	updateFormChangeStatus = () => {
		const updateStore = this.store.getState().update
		updateStore({
			isPristine: this.isPristine,
			isDirty: this.isDirty,
			isTouched: this.isTouched,
			isUntouched: this.isUntouched
		})
	}

	// * GETTERS
	// deprecated
	getField = (field: string) => this.values.get(field)
	getInitialModel = () => this.initialValue
	decorate = () => this.initialValue

	// new
	getForm = () => this
	getFormValue = () => {
		if (!this.applyDecorations) return this.value

		// return decorated value
		return Object.assign({}, this.value, this.applyDecorations(this.value))
	}
	getFieldValue = (field: string) => this.values?.get(field)
	getInitialInputFieldValue = (field: string, def = null) => get(this.initialValue, field, def)

	//  * SETTERS
	setFieldValue = (model: string, value: any) => {
		this.values.set(model, value)
		this.updateFormValues()
	}
	setDecorate = (newDecorate: Function) => {
		this.applyDecorations = newDecorate
	}

	// * CALLBACKS
	afterChange = () => {}
}
