import React from 'react'
import size from 'lodash/size'
import { PanelGroup, Panel } from 'react-resizable-panels'
import { DndContext, DragOverlay, PointerSensor, useDraggable, useDroppable, useSensor, useSensors } from '@dnd-kit/core'

import { COLORS } from '../../../theme'
import useSettings from '../../../hooks/useSettings'

import { DropdownMenu, DropdownMenuItem } from '../../../components/DropdownMenu'
import { ExportPDFButton } from '../../../components/Buttons/ExportPDFButton'
import { PlainSwitch } from '../../../components/Forms/Switch'
import { styles } from '../../../components/DropdownItem'
import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog'
import DeleteDialog from '../../../components/Dialogs/DeleteDialog'
import Dropdown from '../../../components/Dropdown'
import Flex from '../../../components/Flex'
import Form from '../../../components/Forms/Form'
import Glyph from '../../../components/Glyph'
import Label from '../../../components/Label'
import Permission from '../../../components/Permission'
import Portal from '../../../components/Portal'
import Status from '../../../components/Status'
import SummonOverlay from '../../../components/SummonOverlay'
import Tooltip from '../../../components/Tooltip'

import { ElementPreview } from '../elements/ElementDragAndDrop'
import { FORM_CONTENT_WIDTHS_LABELS } from '../utils/constants'
import { useFormBuilder } from '../useFormBuilder'

import { ElementEditorStateDropdown } from './ElementEditorStateDropdown'
import { FormBuilderPage } from './FormBuilderPage'
import { FormPreviewPage } from './FormPreviewPage'
import { NameEditInput } from './NameEditInput'
import { SaveAndContinueButton } from './SaveAndContinueButton'
import { SideMenu } from './SideMenu'
import { MermaidAIChatOverlay } from './MermaidAIChatOverlay'

import { Debug } from './Debug'

export const ActivePage = (props: any) => {
  const {
    allowEditing = true,
    cancel,
    data,
    edit,
    isEditablePDFExportEnabled,
    isNew,
    isPDFExportEnabled,
    isReadonlyPDFExportEnabled,
    isSaving,
    onSave,
  } = props

  const form = React.useRef(null)
  const formPreview = React.useRef(null)
  const [draggingElementId, setDraggingElementId] = React.useState(null)
  const { timezone } = useSettings()

  const activePageId: any = useFormBuilder((state: any) => state.activePageId)
  const setActiveElementId: any = useFormBuilder((state: any) => state.setActiveElementId)
  const moveElementToPage: any = useFormBuilder((state: any) => state.moveElementToPage)
  const moveElementToParent: any = useFormBuilder((state: any) => state.moveElementToParent)
  const isEditable: any = useFormBuilder((state: any) => state.isEditable)
  const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)

  const isMermaidOpen: any = useFormBuilder((state: any) => state.isMermaidOpen)
  const setIsMermaidOpen: any = useFormBuilder((state: any) => state.setIsMermaidOpen)

  const formPageRef: any = React.useRef(null)
  const richTextToolbarRef: any = React.useRef(null)

  const [richTextEditor, setRichTextEditor] = React.useState(null)

  const renders = React.useRef(0)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  )

  const handleBlankAreaClick = (event: any) => {
    const radixPortal = document.getElementById('portal-radix')

    const clickedOnRichTextToolbar = richTextToolbarRef.current?.contains?.(event.target)
    const clickedWithinPortal = radixPortal?.contains?.(event.target)

    if (clickedOnRichTextToolbar || clickedWithinPortal) return

    // clicked on the form page
    if (formPageRef.current?.contains?.(event.target)) return

    setTimeout(() => {
      setActiveElementId(null)
    }, 0)
  }

  const onDragStart = React.useCallback((event) => {
    const { active } = event

    if (!active) return

    const { id: activeId } = active
    const { type: activeType } = active.data.current

    if (activeType === 'ELEMENT') {
      setDraggingElementId(activeId)
    }
  }, [])

  const onDragEnd = (event) => {
    const { active, over } = event

    setDraggingElementId(null)

    const currentActive = active.data.current
    const currentOver = over.data.current

    const { type: activeType } = currentActive
    const { index: toIndex, pageId: toPageId, elementId: toElementId, type: overType } = currentOver

    if (!currentActive || !currentOver) return

    // handle element drag and drop
    if (activeType !== 'ELEMENT') return

    const { elementId } = currentActive

    // element dropped within page elements list at specific index
    if (overType === 'PAGE_ELEMENT_DROPPABLE') {
      moveElementToPage({ elementId, toPageId, toIndex })

      return
    }

    // element dropped within another element's children list at specific index
    if (overType === 'CHILD_ELEMENT_DROPPABLE') {
      moveElementToParent({ elementId, toElementId, toIndex })

      return
    }
  }

  return (
    <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} sensors={sensors}>
      <div className="relative z-0 h-full overflow-hidden grid grid-rows-[auto_1fr] flex-[1_1_auto]">
        <ActivePageHeader
          isEditable={isEditable}
          isNew={isNew}
          cancel={cancel}
          onSave={onSave}
          isSaving={isSaving}
          edit={edit}
          allowEditing={allowEditing}
          isPDFExportEnabled={isPDFExportEnabled}
          isReadonlyPDFExportEnabled={isReadonlyPDFExportEnabled}
          isEditablePDFExportEnabled={isEditablePDFExportEnabled}
          data={data}
        />

        <PanelGroup direction="horizontal">
          <Panel id="main" order={1} minSize={50} maxSize={80} className="grid grid-rows-[100%] grid-cols-[100%] z-[3]">
            <div className="h-full flex flex-nowrap overflow-hidden">
              <main className="h-full overflow-y-auto flex-[1_1_auto] relative" onPointerDown={handleBlankAreaClick}>
                <div ref={richTextToolbarRef} id="portal-rich-text-toolbar" className="sticky top-0 z-[1]" />

                {isEditable && !isPreviewMode ? (
                  <Form
                    isEditable
                    timezone={timezone}
                    key={`form-${data?.updated_at}`}
                    getForm={form}
                    className="pt-24 px-16 pb-16 relative z-[0]"
                  >
                    <FormBuilderPage
                      ref={formPageRef}
                      pageId={activePageId}
                      draggingElementId={draggingElementId}
                      getRichTextEditor={setRichTextEditor}
                    />
                  </Form>
                ) : (
                  <Form
                    isEditable
                    timezone={timezone}
                    key={`form-${Math.random()}-preview`}
                    getForm={formPreview}
                    className="pt-24 px-10 pb-16 relative z-[0]"
                  >
                    <FormPreviewPage pageId={activePageId} />
                  </Form>
                )}

                {/* <Debug /> */}
              </main>
            </div>
          </Panel>

          <SideMenu richTextEditor={richTextEditor} />
        </PanelGroup>
      </div>

      <Portal type="radix">
        <DragOverlay>{draggingElementId && <ElementPreview elementId={draggingElementId} />}</DragOverlay>
      </Portal>

      <SummonOverlay
        isOpen={isMermaidOpen}
        onClose={() => {
          setIsMermaidOpen(false)
        }}
        overlay={<MermaidAIChatOverlay />}
      />
    </DndContext>
  )
}

const ActivePageHeader = (props: any) => {
  const {
    allowEditing = true,
    cancel,
    data,
    edit,
    isEditable,
    isEditablePDFExportEnabled,
    isNew,
    isPDFExportEnabled,
    isReadonlyPDFExportEnabled,
    isSaving,
    onSave,
  } = props

  const { isHQApp } = useSettings()

  const activePageId: any = useFormBuilder((state: any) => state.activePageId)
  const activePage: any = useFormBuilder((state: any) => state.pages?.[activePageId])

  const deletePage: any = useFormBuilder((state: any) => state.deletePage)
  const pagesOrder: any = useFormBuilder((state: any) => state.pagesOrder)
  const updatePage: any = useFormBuilder((state: any) => state.updatePage)

  const isPreviewMode: any = useFormBuilder((state: any) => state.isPreviewMode)
  const exitPreviewMode: any = useFormBuilder((state: any) => state.exitPreviewMode)
  const togglePreviewMode: any = useFormBuilder((state: any) => state.togglePreviewMode)
  const disablePage: any = useFormBuilder((state: any) => state.disablePage)
  const activatePage: any = useFormBuilder((state: any) => state.activatePage)

  const pagesCount = size(pagesOrder)

  const contentWidth = FORM_CONTENT_WIDTHS_LABELS[activePage?.config?.content_width] || FORM_CONTENT_WIDTHS_LABELS.narrow

  return (
    <div>
      <header className="flex items-center bg-white px-4 relative z-[3] shadow-hard-2 h-[--header-height] border-b border-0 border-solid border-divider">
        {activePage && (
          <div className="flex items-center flex-nowrap">
            <Glyph glyph="document" size={18} color={COLORS.blue} />

            <NameEditInput
              truncate
              className="text-[1.05rem] font-[600] truncate max-w-[200px]"
              value={activePage.name}
              onSave={(newName) => {
                if (!newName) return

                updatePage({
                  pageId: activePageId,
                  name: newName,
                })
              }}
            />
          </div>
        )}

        <div className="h-[16px] w-[1px] bg-divider mx-2" />

        {isHQApp && data?.id && (
          <div className="!mr-4">
            <Dropdown label={<div className="flex items-center text-[0.9rem]">Download Page PDF</div>} buttonType="link" glyph="download">
              <ExportPDFButton
                url={`/internal_templates/${data.id}/form_pages/${activePage.uuid}/pdf`}
                label="Readonly PDF"
                css={dropdownItemStyles}
              />

              <ExportPDFButton
                url={`/internal_templates/${data.id}/form_pages/${activePage.uuid}/pdf`}
                label="Editable PDF"
                css={dropdownItemStyles}
                params={{ editable: true }}
              />
            </Dropdown>
          </div>
        )}

        {!isEditable && activePage?.id && isPDFExportEnabled && (
          <Permission featureFlagV2="forms_builder_pdf_export">
            <div className="h-[16px] w-[1px] bg-divider mx-2" />

            <div className="!mr-4">
              <Dropdown label={<div className="flex items-center text-[0.9rem]">Download Page PDF</div>} buttonType="link" glyph="download">
                {isReadonlyPDFExportEnabled && (
                  <ExportPDFButton url={`/form_pages/${activePage.id}/pdf`} label="Readonly PDF" css={dropdownItemStyles} />
                )}

                {isEditablePDFExportEnabled && (
                  <ExportPDFButton
                    url={`/form_pages/${activePage.id}/pdf`}
                    label="Editable PDF"
                    css={dropdownItemStyles}
                    params={{ editable: true }}
                  />
                )}
              </Dropdown>
            </div>
          </Permission>
        )}

        <div className="flex justify-center mx-auto">{allowEditing && <SaveAndContinueButton onSave={onSave} isSaving={isSaving} />}</div>

        {isEditable && (
          <div className="flex items-center flex-nowrap cursor-pointer py-1.5">
            <Label
              isCompact
              label="Preview Mode"
              onClick={togglePreviewMode}
              className="whitespace-nowrap truncate min-w-0 flex-[1_1_auto] mr-1 cursor-pointer"
            />

            <PlainSwitch size={100} isChecked={isPreviewMode} onCheckedChange={togglePreviewMode} />
          </div>
        )}
      </header>

      {isEditable && activePage && (
        <>
          {isPreviewMode ? (
            <Alert small centerX glyph="info" type="warning" className="!rounded-none border-b border-0 border-solid border-divider">
              <b>Preview Mode: </b> you are viewing the form as it will appear to users.{' '}
              <span className="font-[600] text-blue-500 cursor-pointer" onClick={() => exitPreviewMode()}>
                Turn Preview Off
              </span>
            </Alert>
          ) : (
            <div className="px-2 py-1 bg-white bg-opacity-50 border-b border-0 border-solid border-divider">
              <Flex nowrap stretchSelf gap="0.35rem" className="w-full">
                <DropdownMenu
                  trigger={
                    <div>
                      <Button
                        label={
                          <span className="whitespace-nowrap">
                            <span className="font-[600]">Width: </span>
                            <span className="font-[400]">{contentWidth}</span>
                          </span>
                        }
                        glyph="enlarge"
                        type="minimal"
                        size={100}
                        color="text"
                        glyphColor={COLORS.blue}
                        after={<Glyph glyph="triangle_down" size={9} className="!-ml-0" />}
                      />
                    </div>
                  }
                >
                  {Object.entries(FORM_CONTENT_WIDTHS_LABELS).map(([value, label]) => (
                    <DropdownMenuItem
                      key={value}
                      isActive={activePage.config?.content_width ? activePage.config?.content_width === value : value === 'narrow'}
                      label={label}
                      className="[&.is-active]:!bg-vivid-blue-100"
                      onClick={() => {
                        updatePage({
                          pageId: activePageId,
                          config: {
                            content_width: value,
                          },
                        })
                      }}
                    />
                  ))}
                </DropdownMenu>

                {/* <Button
                    nowrap
                    label="Duplicate Page…"
                    glyph="duplicate"
                    type="minimal"
                    size={100}
                    color="text"
                    glyphColor={COLORS.blue}
                    className="!whitespace-nowrap"
                  /> */}

                {activePage?.config?.is_disabled ? (
                  <ConfirmDialog
                    glyph="tick_circle"
                    title={`Activate Page "${activePage?.name || ''}"?`}
                    message="Activating this page will make it visible again to users when submitting a form"
                    yesLabel={`Activate ${activePage?.name ? `"${activePage.name}"` : 'Page'}`}
                    yesColor="green"
                    onYes={() => {
                      activatePage(activePageId)
                    }}
                    isDisabled={isSaving}
                  >
                    <Button
                      nowrap
                      label="Activate Page…"
                      glyph="tick_circle"
                      type="minimal"
                      size={100}
                      color="text"
                      glyphColor={COLORS.green}
                      isDisabled={isSaving}
                      className="!whitespace-nowrap"
                    />
                  </ConfirmDialog>
                ) : (
                  <ConfirmDialog
                    glyph="hide"
                    title={`Disable Page "${activePage?.name || ''}"?`}
                    message="Are you sure you want to disable this page? You can still edit it, however it will not be visible to users when submitting a form."
                    yesLabel={`Disable ${activePage?.name ? `"${activePage.name}"` : 'Page'}`}
                    yesColor="gray"
                    onYes={() => {
                      disablePage(activePageId)
                    }}
                    isDisabled={isSaving}
                  >
                    <Button
                      nowrap
                      label="Disable Page…"
                      glyph="hide"
                      type="minimal"
                      size={100}
                      color="text"
                      glyphColor={COLORS.blue}
                      isDisabled={isSaving}
                      className="!whitespace-nowrap"
                    />
                  </ConfirmDialog>
                )}

                <Tooltip show={pagesCount < 2} color={COLORS.red} content="Cannot delete the only page in the form">
                  <DeleteDialog
                    title={`Delete Page "${activePage?.name || ''}"?`}
                    message="Are you sure you want to delete this page? This action cannot be undone."
                    yesLabel={`Delete ${activePage?.name ? `"${activePage.name}"` : 'Page'}`}
                    onYes={() => {
                      deletePage(activePageId)
                    }}
                    isDisabled={isSaving || pagesCount < 2}
                  >
                    <Button
                      nowrap
                      label="Delete Page…"
                      glyph="delete"
                      type="minimal"
                      size={100}
                      color="text"
                      glyphColor={COLORS.red}
                      isDisabled={isSaving || pagesCount < 2}
                      className="!whitespace-nowrap"
                    />
                  </DeleteDialog>
                </Tooltip>

                <div className="!mx-auto"></div>

                <ElementEditorStateDropdown />
              </Flex>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const dropdownItemStyles = {
  ...styles(COLORS.blue),
  display: 'flex !important',
}
