import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'

import { apiCreate, apiUpdate, apiDelete } from '../../../../modules/api'
import { RECURRING_DAYS } from '../../../../utils/recurrence'

import Alert from '../../../Alert'
import Button from '../../../Button'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import ContextShow from '../../../Forms/ContextShow'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Input from '../../../Forms/Input'
import URLInput from '../../../Forms/URLInput'
import Label from '../../../Label'
import Links from '../../../Forms/Links'
import Option from '../../../Forms/Option'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import Section from '../../../Section'
import Select from '../../../Forms/Select'
import Subtasks from '../../../Forms/Subtasks'
import Textarea from '../../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../../../Overlays/pages/OverlayBase'

import withRouterV6 from '../../../../hocs/withRouterV6'

class TodoInternalTemplateOverlay extends OverlayBase {
  save = async () => {
    const { $new } = this.state
    const { match } = this.props

    let model = this.form.current.getFormValue()

    if ($new)
      await apiCreate({
        name: 'internal_todo_templates',
        url: `/internal_templates`,
        params: {
          ...model,
          category: 'todo',
        },
      })
    else
      await apiUpdate({
        name: 'internal_todo_templates',
        url: `/internal_templates/${match?.params?.id}`,
        params: model,
      })

    this.setState({ $editable: false })
    this.close()
  }

  delete = async () => {
    const { match } = this.props
    await apiDelete({
      name: 'internal_todo_templates',
      url: `/internal_templates/${this.state.id || match?.params?.id}`,
    })

    this.close()
  }

  render = () => {
    const { $new, isInvalid, $editable } = this.state
    const { dataID, initialData, loading, online, record, timezone } = this.props

    const hasInitialData = initialData && dataID

    if (!$new && !hasInitialData && size(record) === 0) {
      return <OverlayLoader position="right" showBackdrop={this.state.$editable} />
    }

    return (
      <Overlay showBackdrop={this.state.$editable} maxWidth={37} onClose={this.close}>
        <Overlay.Header icon="tasks" title="To-Do Template" />

        <Overlay.Content>
          <Form
            getForm={this.form}
            initialModel={record}
            onValidationUpdate={this.onValidationUpdate}
            timezone={timezone}
            isEditable={$editable}
            decorate={(model: any) => ({
              data: { ...model.data, event_type: 'task' },
            })}
          >
            <Section>
              <FormSection layout="vertical" maxWidth="100%">
                <Flex stretchChildrenX gap="1rem">
                  <Select model="status" label="Status" fullWidth defaultValue="active">
                    <Option label="Active" value="active" />
                    <Option label="Archived" value="archived" />
                  </Select>

                  <Select model="level" label="Level" fullWidth defaultValue="behave_template">
                    <Option label="Behave Template" value="behave_template" />
                    <Option label="Public Template" value="public_template" />
                  </Select>
                </Flex>
              </FormSection>
            </Section>

            <Divider />

            <Section title="Data">
              <FormSection layout="vertical" maxWidth="100%">
                <Input
                  label="Title"
                  model="data.title"
                  validations={{
                    presence: {
                      message: 'Please enter a task title',
                    },
                  }}
                />

                <Flex stretchChildrenX gap="1rem">
                  <Select model="data.priority" label="Priority" fullWidth defaultValue="normal">
                    <Option label="Low" value="low" />
                    <Option label="Normal" value="normal" />
                    <Option label="High" value="high" />
                  </Select>

                  <Select
                    label="Repeat"
                    model="data.recurring_frequency"
                    defaultValue="never"
                    onUpdate={(model: any) => {
                      this.setState({ recurringFrequency: model.value })
                    }}
                  >
                    <Option label="Never" value="never" />
                    <Option label="Daily" value="daily" />
                    <Option label="Weekly" value="weekly" />
                    <Option label="Bi-Weekly" value="biweekly" />
                    <Option label="Monthly" value="monthly" />
                    <Option label="Quarterly" value="quarterly" />
                    <Option label="Yearly" value="yearly" />
                  </Select>
                </Flex>

                <ContextShow when="data.recurring_frequency" within={['weekly', 'biweekly']}>
                  <div>
                    <Label label="Repeat On" />
                    <Flex nowrap>
                      {RECURRING_DAYS.weekly.map((day: string) => (
                        <Checkbox
                          key={day}
                          label={day.charAt(0)}
                          model={`data.weekly_repeat_days.${day?.toLowerCase()}`}
                          css={styles.dayCheckbox}
                        />
                      ))}
                    </Flex>
                  </div>
                </ContextShow>

                <Checkbox label="All Day Task" model="data.is_all_day" defaultValue={false} />

                <Textarea useQuickText label="Public Description" model="data.public_description" />
              </FormSection>
            </Section>

            <Divider />

            <Section title="Tasks">
              <Subtasks endpoint="events" model="data.subtasks" taskId={record.id} />
            </Section>

            <Divider />

            <Section title="Clients">
              <FormSection maxWidth="100%">
                <ContextShow when="data.sharing_type" is="internal">
                  <Alert glyph="info">This To-Do will be visible only to Staff</Alert>
                </ContextShow>

                <ContextShow when="data.sharing_type" is="client">
                  <Alert glyph="info">This To-Do will be visible to the Client in their Portal</Alert>
                </ContextShow>

                <FormSection horizontal maxWidth="100%">
                  <div css={{ display: 'block' }}>
                    <Select fullWidth label="Sharing Permissions" model="data.sharing_type" defaultValue="internal">
                      <Option label="Staff Only" value="internal" />
                      <Option label="Client Portal" value="client" />
                    </Select>
                  </div>

                  <ContextShow when="data.sharing_type" is="internal">
                    <Select label="Task Type" model="data.meeting_type" defaultValue="one_to_one" className="!flex-1">
                      <Option label="One-to-One" value="one_to_one" />
                      <Option label="Group Task" value="group_meeting" />
                    </Select>
                  </ContextShow>
                </FormSection>
              </FormSection>
            </Section>

            <Divider />

            <Section title="Notifications">
              <FormSection>
                <Alert glyph="notification">Send a notification alert via SMS / Email to staff and clients</Alert>

                <CheckboxGroup layout="vertical-dense">
                  <Checkbox defaultChecked label="Send SMS Notification" model="data.should_sms" />
                  <Checkbox defaultChecked label="Send Email Notification" model="data.should_email" />
                </CheckboxGroup>
              </FormSection>
            </Section>

            <Divider />

            <Section title="Reminders">
              <FormSection>
                <Checkbox label="Send SMS and Email Reminders" model="data.should_send_reminders" />

                <ContextShow when="data.should_send_reminders" is={true}>
                  <div className="!pl-6">
                    <CheckboxGroup
                      label="Send Reminders Options"
                      layout="vertical-dense"
                      validations={{
                        presence: {
                          message: 'Please select at least one option',
                        },
                      }}
                    >
                      <Checkbox label="Three days before the todo" model="data.send_reminders.three_days" />
                      <Checkbox label="Two days before the todo" model="data.send_reminders.two_days" />
                      <Checkbox label="One day before the todo" model="data.send_reminders.one_day" />
                      <Checkbox label="Three hours before the todo" model="data.send_reminders.three_hours" />
                      <Checkbox label="Two hours before the todo" model="data.send_reminders.two_hours" />
                      <Checkbox label="One hour before the todo" model="data.send_reminders.one_hour" />
                      <Checkbox label="15 min before todo" model="data.send_reminders.15_minutes" />
                    </CheckboxGroup>
                  </div>
                </ContextShow>
              </FormSection>
            </Section>

            <Divider />

            <Section title="Place">
              <FormSection>
                <Select label="Meeting Place" model="data.meeting_place" defaultValue="none" className="!flex-1">
                  <Option label="None" value="none" />
                  <Option label="Online" value="online" />
                  <Option label="Location" value="property" />
                  <Option label="Organization" value="organization" />
                  <Option label="Other" value="other" />
                </Select>

                <ContextShow when="data.meeting_place" is="online">
                  <URLInput
                    label="Meeting Link"
                    model="data.meeting_url"
                    validations={{
                      presence: {
                        message: 'Please enter a meeting link',
                      },
                    }}
                  />
                </ContextShow>

                <ContextShow when="data.meeting_place" not="none">
                  <Textarea useQuickText label="More Details" model="data.place_details" />
                </ContextShow>
              </FormSection>
            </Section>

            <Divider />

            <Section title="Links">
              <Links model="data.urls" />
            </Section>

            <Divider />

            <Section title="Notes">
              <FormSection>
                <Textarea useQuickText label="Internal Notes" model="data.internal_notes" />
              </FormSection>
            </Section>
          </Form>
        </Overlay.Content>

        <Overlay.Footer online={online}>
          {$editable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                onClick={this.save}
                isLoading={loading}
                isDisabled={isInvalid}
                permission="todo_templates.edit"
              />
              <Button label="Cancel" glyph="cross" onClick={this.cancel} isDisabled={loading} />
            </>
          )}

          {!$editable && (
            <>
              <Button label="Edit" glyph="edit" onClick={this.edit} flex="100 1 auto" permission="todo_templates.edit" />

              <DeleteDialog
                title="Delete Event Template?"
                message="Are you sure you want to delete this event template? This action cannot be undone."
                onYes={this.delete}
              >
                <Button label="Delete" glyph="delete" color="red" isLoading={loading} permission="todo_templates.delete" />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const styles = {
  dayCheckbox: {
    width: 'auto !important',
    flex: '1 1 auto !important',
    marginLeft: 4,
    marginRight: 4,

    '@media (max-width: 550px)': {
      marginLeft: 2,
      marginRight: 2,
    },

    '&:first-of-type': { marginLeft: 0 },
    '&:last-of-type': { marginRight: 0 },

    label: {
      fontSize: '0.9rem',

      '@media (max-width: 550px)': {
        padding: '0.5em 0.4em',
        flexDirection: 'column',
      },
    },

    '.check-element': {
      width: 18,
      height: 18,

      '@media (max-width: 550px)': {
        margin: '0 0 0.4rem 0',
      },
    },
  },
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'internal_todo_templates')

export default connect(mapStateToProps, mapDispatchToProps)(TodoInternalTemplateOverlay)

export const TodoInternalTemplateOverlayV6 = withRouterV6(connect(mapStateToProps, mapDispatchToProps)(TodoInternalTemplateOverlay))
