import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Dropdown, DropdownItem, Card, Grid, HelpTagIframe, Link, Page } from '@behavehealth/components'
import { CommunicationsTable } from '@behavehealth/components/Tables'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'

type Props = {
  canCreate: boolean
}

const pageConfig = {
  feature: 'communications',
  title: 'Communications',
}

const RootCommunications: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { user } = useSettings()
  const { resource_id }: any = useParams()

  const [filters, setFilters] = React.useState({})

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'communications', { filters }],
    url: `/residents/${resource_id}/communications`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const { mutate: deleteCommunications } = useDelete({
    name: ['client', resource_id, 'communications'],
    url: '/communications',
    invalidate: 'communications',
  })

  const isEmpty = size(data) === 0

  const actions = canCreate && (
    <Dropdown label="Add Communication Logs…" glyph="add" buttonType="primary" permission="communications.create">
      <DropdownItem
        label="Add Communication Log"
        icon="communications"
        color="orange"
        link={{
          pathname: `${match.url}/new`,
          data: {
            employee: user,
          },
        }}
      />

      <DropdownItem
        label="Add Many Communication Logs…"
        glyph="stack"
        color="orange"
        featureFlagV2="bulk_add"
        link={{
          pathname: `${match.url}/new-batch`,
        }}
      />
    </Dropdown>
  )

  return (
    <Page
      title="Communications"
      feature="communications"
      plan="lite"
      help={<HelpTagIframe id="client_contacts_and_communications" />}
      actions={!isEmpty && actions}
    >
      <Grid gap="1rem" columns="100%">
        <Filters config={FILTERS.client_communications} onUpdate={setFilters} localStorageKey="client_communications" />

        <Card>
          <CommunicationsTable
            data={data}
            isLoading={isEmpty && isLoading}
            localStorageKey="clients_communications"
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            titleAfter={<Link to="/reports/clients-communications">View Full Report →</Link>}
            emptyActions={actions}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'communications.delete',
                action: async ({ ids }: any) => {
                  await deleteCommunications(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(RootCommunications, pageConfig))
