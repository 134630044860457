import React from 'react'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { DropdownMenu, DropdownMenuItem, DropdownMenuSub } from '../../components/DropdownMenu'
import { useGet, useCreate } from '../../hooks/useNewAPI'
import { isDefined } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Button from '../../components/Button'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import State from '../../components/State'
import Tooltip from '../../components/Tooltip'
import Type from '../../components/Typography/Type'

export const AIActionsMenu = (props: any) => {
  const {
    buttonClassName,
    buttonLabel = 'Behave AI',
    buttonSize = 100,
    className,
    getInput,
    input,
    onCompleteAction,
    onStartAction,
    showChevron,
  } = props

  const { isPortal } = useSettings()

  const { data, isLoading } = useGet({
    name: ['custom-ai-actions'],
    url: '/custom_ai_actions',
  })

  const { mutateAsync: runActionAsync, isLoading: isRunningAction } = useCreate({
    name: ['perform-ai-action'],
    url: '/ai/run',
  })

  const { mutateAsync: runCustomActionAsync, isLoading: isRunningCustomAction } = useCreate({
    name: ['perform-custom-ai-action'],
    url: '/ai/run',
  })

  const { aiActions, aiCustomActions, actionGroups, remainingActions, customActionGroups, remainingCustomActions } = React.useMemo(() => {
    let aiActions = data?.ai_actions
    let aiCustomActions = data?.custom_ai_actions

    let actionGroups: any = {}
    let remainingActions: any = []

    let customActionGroups: any = {}
    let remainingCustomActions: any = []

    // group AI actions
    if (size(aiActions) > 0) {
      for (const action of aiActions) {
        if (!action.nav_group) {
          remainingActions.push(action)
          continue
        }

        // if there is nothing in the nav_group, initialize it as an empty array
        if (size(actionGroups[action.nav_group]) === 0) actionGroups[action.nav_group] = []

        // push the element into the nav_group array
        actionGroups[action.nav_group].push(action)
      }
    }

    // group custom AI actions
    if (size(aiCustomActions) > 0) {
      for (const action of aiCustomActions) {
        if (!action.nav_group) {
          remainingCustomActions.push(action)
          continue
        }

        // if there is nothing in the nav_group, initialize it as an empty array
        if (size(customActionGroups[action.nav_group]) === 0) customActionGroups[action.nav_group] = []

        // push the element into the nav_group array
        customActionGroups[action.nav_group].push(action)
      }
    }

    return {
      aiActions,
      aiCustomActions,
      actionGroups,
      remainingActions,
      customActionGroups,
      remainingCustomActions,
    }
  }, [data])

  const aiActionsEmpty = size(aiActions) === 0
  const aiCustomActionsEmpty = size(aiCustomActions) === 0

  const runAction = async (action: any) => {
    const finalInput = isDefined(getInput) ? getInput() : input

    if (!action || !finalInput) return

    let result = ''

    try {
      if (onStartAction) onStartAction({ action })

      if (action.type === 'global/ai_action') {
        const response = await runActionAsync({
          input: finalInput,
          id: action.id,
          action_kind: 'global',
        })

        result = response.data.result
      } else if (action.type === 'custom_ai_action') {
        const response = await runCustomActionAsync({
          input: finalInput,
          action_kind: 'custom',
          id: action.id,
        })

        result = response.data.result
      }
    } catch (error) {
      console.error(error)
    } finally {
      if (onCompleteAction) onCompleteAction({ action, result })
    }
  }

  if (isPortal) return null

  return (
    <Flex centerY nowrap className={className}>
      <DropdownMenu
        isDisabled={isRunningAction || isRunningCustomAction}
        trigger={
          <div className="flex-1">
            <Button
              label={
                <span className="flex items-center">
                  {buttonLabel}
                  {showChevron && <Glyph glyph="triangle_down" size={10} className="ml-1" />}
                </span>
              }
              size={buttonSize}
              icon="behave_ai"
              color="violet"
              isLoading={isRunningAction || isRunningCustomAction}
              className={buttonClassName}
            />
          </div>
        }
      >
        {isLoading || (aiActionsEmpty && aiCustomActionsEmpty) ? (
          <State
            key="empty-state"
            title="Behave AI"
            isLoading={isLoading}
            isEmpty={aiActionsEmpty && aiCustomActionsEmpty}
            emptyDescription="No AI actions added yet"
            icon="ai_prompts"
          />
        ) : (
          <>
            {/* {!input && <State isEmpty glyph="textarea_input" emptyDescription="Type something to see the AI actions" />} */}

            <>
              {!aiActionsEmpty && <AIActionsList groups={actionGroups} remaining={remainingActions} onSelect={runAction} />}

              <Divider className="!m-0" />

              {!aiCustomActionsEmpty && (
                <AIActionsList groups={customActionGroups} remaining={remainingCustomActions} onSelect={runAction} />
              )}
            </>
          </>
        )}
      </DropdownMenu>

      <Tooltip
        color={COLORS.violet}
        className="ml-1"
        content={
          <div>
            <div>
              Private Beta Test – BEHAVE+ only,
              <br />
              <a href="https://behavehealth.com/vip" target="_blank">
                click to learn more
              </a>
            </div>

            <Divider className="!my-2" />

            <div className="italic font-small">
              The text generated by this AI system is the result of complex algorithms interpreting and processing input data. While we
              strive for accuracy and relevance, we cannot guarantee the content to be free from errors, biases, or outdated information.
              The AI-generated text should not be relied upon as the sole source of information and should be supplemented by other reliable
              sources or professional advice where appropriate. The creators and operators of this AI system assume no responsibility or
              liability for any decisions made or actions taken based on the generated text. By using the AI-generated text, users
              acknowledge and accept the inherent limitations and potential risks associated with artificial intelligence.
            </div>
          </div>
        }
      >
        <Glyph glyph="info" size={12} color={COLORS.violet} />
      </Tooltip>
    </Flex>
  )
}

const AIActionsList = ({ groups, remaining, onSelect }: any) => {
  return (
    <>
      {groups &&
        Object.entries(groups).map(([label, actions]: any) => {
          return (
            <>
              <div key={label} className="mb-2">
                <Type as="h3" variant="CAPS" className="pl-4 mt-2">
                  {label}
                </Type>

                {actions.map((action: any) => (
                  <MenuItem key={action.id} data={action} onClick={() => onSelect?.(action)} />
                ))}
              </div>

              {remaining && <Divider className="!m-0" />}
            </>
          )
        })}

      {remaining && (
        <div className="mt-2">
          {remaining.map((action: any) => (
            <MenuItem key={action.id} data={action} onClick={() => onSelect?.(action)} />
          ))}
        </div>
      )}
    </>
  )
}

const MenuItem = ({ data, onClick }: any) => {
  if (!data) return null

  return <DropdownMenuItem label={data.name} glyph={data.glyph} glyphColor={data.color} onClick={onClick} />
}
