import React from 'react'

import { COLORS } from '../../../theme'
import { niceAmount } from '../../../utils/functions'

const Amount: React.FC<any> = ({ negativeColor, positiveColor, prefix, sign, value, valueColor }) => {
  let finalSign = sign || prefix || ''
  let color = COLORS[valueColor]

  const hasNoValue = !value || parseFloat(value) === 0

  if (hasNoValue) {
    finalSign = ''
    color = COLORS.text
  } else if (!finalSign && value > 0) {
    finalSign = '+'
    color = COLORS[positiveColor]
  } else if (!finalSign && value < 0) {
    finalSign = '-'
    color = COLORS[negativeColor]
  }

  return (
    <div css={{ color: color || COLORS.text, textAlign: 'right', fontWeight: hasNoValue ? 400 : 600 }}>
      {finalSign}
      {niceAmount(value)}
    </div>
  )
}

export default Amount
