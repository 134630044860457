import React from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { usDate, titleCase } from '../../utils/functions'
import MedicationStatus from '../Statuses/MedicationStatus'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import DropdownItem from '../DropdownItem'
import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'
import CardSubtitle from '../CardSubtitle'
import snakeCase from 'lodash/snakeCase'

const SOURCE = {
  internal: 'Current Facility',
  'external-during': 'External Provider (during treatment)',
  'external-prior': 'External Provider (prior to treatment)',
}

type Props = {
  location: any
  match: any
  medication: any
  showActions: any
  timezone: any
  to: any
  onClick: Function
}

const Medication = ({ medication, showActions, timezone, to, onClick }: Props) => {
  const match = useRouteMatch()
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink
      testKey={`${snakeCase(medication.name)}`}
      to={to}
      isActive={isActive}
      onClick={onClick}
      actions={
        showActions && (
          <DropdownItem
            glyph="add"
            label="Log Medication"
            color="blue"
            link={{
              pathname: `${match.url}/logs/new`,
              data: {
                med: { ...medication, description: titleCase(medication.status) },
                quantity: medication.quantity,
                dosage: medication.dosage,
                unit: medication.unit,
              },
              parent: match,
            }}
          />
        )
      }
    >
      <CardTitle title={medication.name} />
      <CardMeta>
        <MedicationStatus status={medication.status} />
        <Text glyph="date" label="Start Date: " description={usDate(medication.started_at, timezone)} />
        <Text glyph="date" label="End Date: " description={usDate(medication.ended_at, timezone)} />
        <Text label="Dosage: " description={medication.dosage} />
        <Text label="Unit: " description={medication.unit} />
        <Text label="Source: " description={SOURCE[medication.source]} />
      </CardMeta>
      <CardSubtitle subtitle={medication.notes} />
    </CardLink>
  )
}

export default withSettings(Medication)
