import React from 'react'

const PageSectionHeader = (props: any) => {
  const { graphic, children, after, className } = props

  return (
    <header css={styles.root} className={className}>
      <div css={styles.flex}>
        <div css={styles.inner}>
          {graphic && <div css={styles.graphic}>{graphic}</div>}
          <div css={styles.innerContent}>{children}</div>
        </div>

        {after && <div css={styles.after}>{after}</div>}
      </div>
    </header>
  )
}

const styles = {
  root: {
    marginBottom: '1rem',
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    margin: '-0.5rem',
    flexWrap: 'wrap',
  },

  inner: {
    display: 'flex',
    flexWrap: 'nowrap',
    flex: '999 1 300px',
    margin: '0.5rem',
  },

  innerContent: {
    display: 'grid',
    gridGap: '0.25rem',
  },

  after: {
    flex: '1 1 auto',
    margin: '0.5rem',
  },

  graphic: {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',

    flex: '0 0 auto',
    marginRight: '0.5rem',
    position: 'relative',
  },
}

export default PageSectionHeader
