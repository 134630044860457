import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useDataForms } from '@behavehealth/hooks/useDataForms'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Chotomate, Button, Card, Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { DischargesTable } from '@behavehealth/components/Tables'
import { DataFormAddDropdown } from '@behavehealth/constructs/DataFormsBuilder/DataFormAddDropdown'

import { Filters } from '@behavehealth/constructs/Filters/Filters'
import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { getVersionSlug } from '@behavehealth/utils/functions'
import { DISCHARGE_FORMS_SLUGS } from '@behavehealth/utils/constants'

type Props = {
  canCreate: boolean
}

const pageConfig = {
  feature: 'discharges',
  title: 'Discharges',
}

const Discharges: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()

  const { data, isEmpty, isLoading, batchDelete, setFilters } = useDataForms({ category: 'discharge' })

  const to = React.useMemo(
    () => (record: any) => {
      const { version, subcategory, id } = record

      const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`
      const slug = !version || version === '1.0' ? '' : `/${DISCHARGE_FORMS_SLUGS[subcategory]}`

      return {
        pathname: `${match.url}${slug}/${id}${formattedVersion}`,
        parent: match,
      }
    },
    [match.url],
  )

  const action = (
    <DataFormAddDropdown
      category="discharge"
      permission="discharges_builder.view"
      featureFlagV2="discharges_builder"
      manageLink="/settings/discharges-builder"
    />
  )

  return (
    <Page feature="discharges" plan="lite" actions={!isEmpty && canCreate && action} help={<HelpTagIframe id="discharges" />}>
      <Grid gap="1rem" columns="100%">
        <Chotomate name="discharges" ready={!isLoading} />

        <Filters config={FILTERS.discharges} onUpdate={setFilters} localStorageKey="discharges" />

        <Card>
          <DischargesTable
            data={data}
            isLoading={isLoading}
            emptyActions={canCreate && action}
            localStorageKey="clients_discharges"
            to={to}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'discharges.delete',
                action: batchDelete,
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Discharges, pageConfig))
