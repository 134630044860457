import React from 'react'
import clsx from 'clsx'
import Lottie from 'lottie-react'

import { Box } from './Box'
import { request } from '../../../../modules/axios'

import State from '../../../../components/State'

export const LottieAnimation = React.forwardRef((props: any, ref: any) => {
  const { element, className, hoverElement } = props

  const [data, setData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (data || !element?.config?.lottie_url) return

    const dataFetch = async () => {
      setIsLoading(true)

      try {
        const response = await request.get(element?.config?.lottie_url)

        setData(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    dataFetch()
  }, [data, element?.config?.lottie_url])

  if (!element?.config) return null

  const { lottie_background_color, lottie_max_width = 100, lottie_max_width_type = 'percentage' } = element.config

  const rootClasses = clsx('LOTTIE_ANIMATION', className)

  return (
    <Box
      ref={ref}
      element={element}
      css={STYLES.root}
      className={rootClasses}
      hoverElement={hoverElement}
      style={{
        backgroundColor: lottie_background_color,
        maxWidth: `${lottie_max_width}${WIDTH_VALUE_TYPES[lottie_max_width_type]}`,
      }}
    >
      {hoverElement}

      {isLoading || !data ? (
        <State
          isLoading={isLoading}
          isEmpty={!data}
          title="Lottie Animation"
          glyph="lottie"
          emptyDescription="Enter a Lottie Animation URL"
        />
      ) : (
        <Lottie autoplay animationData={data} loop={true} />
      )}
    </Box>
  )
})

const WIDTH_VALUE_TYPES = {
  percentage: '%',
  pixels: 'px',
}

const STYLES = {
  root: {
    width: '100%',
    margin: '0 auto',

    '.hover-element': {
      // remove hover background so it doesn't affect the element's background
      background: 'none !important',
    },
  },
}
