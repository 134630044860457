import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import size from 'lodash/size'
import { useSelector } from 'react-redux'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useAPI from '@behavehealth/hooks/useAPI'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, Chotomate, Grid, Page, Table, TableCell, HelpTagIframe } from '@behavehealth/components'

import { HealthcareServiceV6 } from '@behavehealth/components/Overlays/pages/HealthcareService'
import InsuranceServiceTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/InsuranceServiceTemplatesImportOverlay'

const COLLECTION_TYPE = {
  insurance: 'Insurance',
  cash: 'Cash',
}

const UNITS = {
  per_occurrence: 'per occurrence',
  per_day: 'per day',
  per_hour: 'per hour',
  per_test: 'per test',
}

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ cell }) => {
      const isArchived = cell.row.original.is_archived

      return (
        <TableCell.MainLink
          as={NavLink}
          to={`${cell.row.original.id}`}
          cell={cell}
          label={isArchived ? `${cell.value} (Archived)` : cell.value}
        />
      )
    },
  },
  {
    Header: 'Unit',
    accessor: 'unit',
    Cell: ({ value }) => UNITS[value] || '–',
  },
  {
    Header: 'Collection Type',
    accessor: 'collection_type',
    Cell: ({ value }) => COLLECTION_TYPE[value] || '–',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: TableCell.Amount,
  },
]

const pageConfig = {
  title: 'Healthcare Services Book',
  feature: 'services_book',
  help: <HelpTagIframe id="settings_services_book" />,
  marketingID: 'settings_services_book',
}

const FeeSchedule = () => {
  return (
    <>
      <FeeScheduleIndex />

      <AnimatedRoutes>
        <Route path="import" element={<InsuranceServiceTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<HealthcareServiceV6 useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const FeeScheduleIndex: React.FC = () => {
  const data = useSelector((state) => state.data.services?.data)
  const loading = useSelector((state) => state.data.services?.loading)

  useAPI('services', '/services?service_type=healthcare')

  const isEmpty = size(data) === 0

  return (
    <Page
      isLoading={isEmpty && loading}
      isEmpty={isEmpty}
      actions={
        <>
          <Button as={Link} label="Import Behave Templates" type="default" glyph="add" link={`import`} />
          <Button
            as={Link}
            label="Add Service"
            link="new"
            type="primary"
            glyph="add"
            size={300}
            display="inline-flex"
            permission="settings.services_book.edit"
          />
        </>
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        <Chotomate name="services_book" ready={!loading} />

        <Card>
          <Table data={data} columns={columns} localStorageKey="settings_services_book" />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(FeeSchedule, pageConfig))
