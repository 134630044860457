import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'
import { useSettings } from '../../../hooks/useSettings'
import { usDate } from '../../../utils/functions'
import DataList from '../../../components/DataList'
import MedicationStatus from '../../../components/Statuses/MedicationStatus'

import { RecordTooltip } from '../components/RecordTooltip'

export const Medication = ({ data, client }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <RecordTooltip
      title="Medication"
      icon="medication"
      color={tint(0.4, COLORS.red)}
      startDate={data.started_at}
      recordLink={`/medications/${data.id}`}
      client={client}
    >
      <DataList isCompact withPadding labelWidth={120}>
        <DataList.Item label="Name" value={data.name} />
        <DataList.Item label="Status" value={<MedicationStatus status={data.status} />} />
        <DataList.Item label="Dosage" value={data.dosage} />
        <DataList.Item label="Unit" value={data.unit} />
        <DataList.Item label="Quantity" value={data.quantity} />
        <DataList.Item label="Start Date" value={usDate(data.started_at, timezone)} />
        <DataList.Item label="End Date" value={usDate(data.ended_at, timezone)} />
      </DataList>
    </RecordTooltip>
  )
}
