import React from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import isBoolean from 'lodash/isBoolean'
import isString from 'lodash/isString'
import size from 'lodash/size'

import { isDefined } from '../utils/functions'
import useStore from '../modules/store'

const processFeatureFlag = (key, flags) => (isBoolean(key) ? key : get(flags, key))

const processPermission = (key, permissions) => {
  let ps = true

  // process permission
  if (isBoolean(key)) {
    ps = key
  } else if (key) {
    if (key === 'test_results') {
      let uat_view = get(permissions, 'test_results.ua_tests.view')
      let bt_view = get(permissions, 'test_results.breathalyzer_tests.view')
      let vs_view = get(permissions, 'test_results.vital_signs.view')
      let cdt_view = get(permissions, 'test_results.communicable_disease_tests.view')

      if (uat_view === 'all') uat_view = true
      if (bt_view === 'all') bt_view = true
      if (vs_view === 'all') vs_view = true
      if (cdt_view === 'all') cdt_view = true

      ps = uat_view || bt_view || vs_view || cdt_view
    } else {
      // check if view is on
      let viewKeyArray = key.split('.')
      viewKeyArray.splice(-1, 1)
      viewKeyArray.push('view')

      // check that the view key is allowed; we do this to block create/update/delete without view
      const viewKey = viewKeyArray.join('.')
      const viewPermissions = get(permissions, viewKey)
      if (isString(viewPermissions) && viewPermissions === 'none') return false
      else if (viewPermissions === false) return false

      // check that the key is allowed
      ps = get(permissions, key, false)
      if (isString(ps)) {
        if (ps === 'all') ps = true
        else if (ps === 'none') ps = false
      }
    }
  }

  return ps
}

const withPermissions = (Wrapped) => (props: any) => {
  const user = useSelector((state) => state.me?.user)
  const tenant = useSelector((state) => state.me?.tenant)

  const debug = useStore((state: any) => state.debug)

  const permissions = useSelector((state) => state.me?.permissions)
  const featureFlagsV2 = useSelector((state) => state.me?.tenant?.feature_flags)

  const checkDev = isDefined(props.isDev)
  const checkPermission = isDefined(props.permission)
  const checkFeatureFlagV2 = isDefined(props.featureFlagV2) && size(featureFlagsV2) > 0

  const isTrialing = tenant?.plan_status === 'trialing'
  const showMarketing = isTrialing && props.showMarketing

  const isCommunity = tenant?.plan_status === 'community'

  let flag = true
  let permission = true

  // if on community, and not whitelisted, hide it
  if (isCommunity && checkFeatureFlagV2) {
    // if it's set up for community, show it
    if (props.showForCommunity) return <Wrapped {...props} />

    // if the debug community permissions are on, show opaque version
    if (debug?.community_permissions === true) {
      return (
        <div style={{ opacity: 0.25 }}>
          <Wrapped {...props} />
        </div>
      )
    }

    // don't show anything
    return null
  }

  if (checkDev) {
    const isDev = user?.type === 'bh_employee'
    if (props.isVisible) props.isVisible(isDev)

    if (!isDev) return null
  }

  if (checkFeatureFlagV2) {
    flag = processFeatureFlag(props.featureFlagV2, featureFlagsV2)
  }

  if (!flag && !showMarketing) {
    if (props.isVisible) props.isVisible(false)
    return null
  }

  if (checkPermission) {
    permission = processPermission(props.permission, permissions)
  }

  if (!permission) {
    if (props.isVisible) props.isVisible(false)
    return null
  }

  if (props.isVisible) props.isVisible(true)

  return <Wrapped {...props} />
}

export default withPermissions
