import React from 'react'
import { useMedia } from 'use-media'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import Button from '../../components/Button'
import Card from '../../components/Card'
import DateInput from '../../components/Forms/DateInput'
import Flex from '../../components/Flex'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import State from '../../components/State'
import Status from '../../components/Status'

import { FormReadonlyReviewCard } from './components/FormReadonlyReviewCard'
import { TreatmentPlanRecordStatus } from './form_elements/TreatmentPlanRecordStatus'
import { useTreatmentPlanStore } from './store'

export const TreatmentPlanGOIForm = (props: any) => {
  const {
    data = [],
    onUpdate,
    planId,
    showGoalDates,
    showInterventionDates,
    showObjectiveDates,
    useGoals,
    useInterventions,
    useObjectives,
    usePlanReviews,
    useProblems,
    goalsShowSettings,
    objectivesShowSettings,
    interventionsShowSettings,
  } = props

  const [formData, setFormData]: any = React.useState()

  const isDesktop = useMedia({ minWidth: 1500 })
  const reviewsVisible = useTreatmentPlanStore((state: any) => state.problemReviewsVisible)

  React.useEffect(() => {
    const treatment_goals_attributes: any = []

    if (!data) return { treatment_goals_attributes }

    for (const goal of data) {
      const treatment_objectives_attributes: any = []

      if (size(goal.treatment_objectives) > 0) {
        for (const objective of goal.treatment_objectives) {
          const treatment_interventions_attributes: any = []

          if (size(objective.treatment_interventions) > 0) {
            for (const intervention of objective.treatment_interventions) {
              treatment_interventions_attributes.push({
                id: intervention.id,
                name: intervention.name,
                description: intervention.description,
                status: intervention.status,
                started_at: intervention.started_at,
                target_at: intervention.target_at,
                completed_at: intervention.completed_at,
                last_treatment_review: intervention.last_treatment_review,
                next_treatment_review: intervention.next_treatment_review,
              })
            }
          }

          treatment_objectives_attributes.push({
            id: objective.id,
            name: objective.name,
            description: objective.description,
            status: objective.status,
            started_at: objective.started_at,
            target_at: objective.target_at,
            completed_at: objective.completed_at,
            last_treatment_review: objective.last_treatment_review,
            next_treatment_review: objective.next_treatment_review,
            treatment_interventions_attributes,
          })
        }
      }

      treatment_goals_attributes.push({
        id: goal.id,
        name: goal.name,
        description: goal.description,
        status: goal.status,
        started_at: goal.started_at,
        target_at: goal.target_at,
        completed_at: goal.completed_at,
        treatment_objectives_attributes,
        last_treatment_review: goal.last_treatment_review,
        next_treatment_review: goal.next_treatment_review,
        treatment_problems: goal.treatment_problems,
      })
    }

    setFormData({ treatment_goals_attributes })
  }, [data])

  React.useEffect(() => {
    if (!formData) return

    onUpdate(formData)
  }, [formData])

  if (!formData) return null

  const addGoalAction = (
    <Button
      label="Add Goal"
      glyph="add"
      size={100}
      type="primary"
      display="inline-flex"
      className="mt-3"
      onClick={() => {
        setFormData((prev: any) => {
          const updated = produce(prev, (draft) => {
            draft.treatment_goals_attributes.push({
              _id: uuid(),
              name: `Goal ${size(draft.treatment_goals_attributes) + 1}`,
              description: '',
              status: 'in_progress',
              started_at: '',
              target_at: '',
              completed_at: '',
              treatment_objectives_attributes: [],
            })
          })

          return updated
        })
      }}
    />
  )

  return (
    <>
      {size(formData.treatment_goals_attributes) === 0 ? (
        <Card>
          <State
            isEmpty
            icon="treatment_plans"
            title="Goals, Objectives & Interventions"
            emptyDescription="Start by adding the first goal"
            emptyActions={useGoals && addGoalAction}
          />
        </Card>
      ) : (
        <>
          {reviewsVisible && isDesktop && usePlanReviews && (
            <Card className="px-3 py-1.5 sticky top-2 z-[3] font-[600] mb-2">
              <div className="grid grid-cols-[1fr_220px_220px] mq1600:grid-cols-[1fr_280px_280px] mq1800:grid-cols-[1fr_340px_340px] gap-3">
                <div>Goals, Objectives & Interventions</div>
                <div className="pl-3 border-l border-0 border-solid border-divider text-center">Last Reviews</div>
                <div className="pl-3 border-l border-0 border-solid border-divider text-center">Next Reviews</div>
              </div>
            </Card>
          )}
          {formData.treatment_goals_attributes?.map?.((goal: any, goalIndex: number) => {
            return (
              <div key={goal.id || goal._id} className={goal?._destroy ? 'hidden' : ''}>
                <CardItem
                  data={goal}
                  planId={planId}
                  showDates={showGoalDates}
                  showSettings={goalsShowSettings}
                  identifier={`Goal ${goalIndex + 1}`}
                  showProblemSelector={useProblems}
                  onUpdate={(key, value) => {
                    setFormData((prev: any) => {
                      return produce(prev, (draft) => {
                        draft.treatment_goals_attributes[goalIndex][key] = value
                      })
                    })
                  }}
                  onDelete={() => {
                    setFormData((prev: any) => {
                      const isAPIRecord = !!goal.id

                      if (isAPIRecord) {
                        return produce(prev, (draft) => {
                          draft.treatment_goals_attributes[goalIndex]._destroy = true
                        })
                      }

                      return produce(prev, (draft) => {
                        draft.treatment_goals_attributes.splice(goalIndex, 1)
                      })
                    })
                  }}
                />

                {useObjectives && (
                  <div className="ml-3 pl-4 border-l border-0 border-solid border-divider">
                    {goal.treatment_objectives_attributes?.map((objective: any, objectiveIndex: number) => {
                      return (
                        <div key={objective.id || objective._id} className={objective?._destroy ? 'hidden' : ''}>
                          <CardItem
                            data={objective}
                            showDates={showObjectiveDates}
                            showSettings={objectivesShowSettings}
                            identifier={`Obj ${goalIndex + 1}.${objectiveIndex + 1}`}
                            onUpdate={(key, value) => {
                              setFormData((prev: any) => {
                                return produce(prev, (draft) => {
                                  draft.treatment_goals_attributes[goalIndex].treatment_objectives_attributes[objectiveIndex][key] = value
                                })
                              })
                            }}
                            onDelete={() => {
                              setFormData((prev: any) => {
                                const isAPIRecord = !!objective.id

                                if (isAPIRecord) {
                                  return produce(prev, (draft) => {
                                    draft.treatment_goals_attributes[goalIndex].treatment_objectives_attributes[objectiveIndex]._destroy =
                                      true
                                  })
                                }

                                return produce(prev, (draft) => {
                                  draft.treatment_goals_attributes[goalIndex].treatment_objectives_attributes.splice(objectiveIndex, 1)
                                })
                              })
                            }}
                          />

                          {useInterventions && (
                            <div className="ml-3 pl-4 border-l border-0 border-solid border-divider">
                              {objective?.treatment_interventions_attributes?.map((intervention: any, interventionIndex: number) => {
                                return (
                                  <div key={intervention.id || intervention._id} className={intervention?._destroy ? 'hidden' : ''}>
                                    <CardItem
                                      data={intervention}
                                      showDates={showInterventionDates}
                                      showSettings={interventionsShowSettings}
                                      identifier={`Int ${goalIndex + 1}.${objectiveIndex + 1}.${interventionIndex + 1}`}
                                      onUpdate={(key, value) => {
                                        setFormData((prev: any) => {
                                          return produce(prev, (draft) => {
                                            draft.treatment_goals_attributes[goalIndex].treatment_objectives_attributes[
                                              objectiveIndex
                                            ].treatment_interventions_attributes[interventionIndex][key] = value
                                          })
                                        })
                                      }}
                                      onDelete={() => {
                                        setFormData((prev: any) => {
                                          const isAPIRecord = !!intervention.id

                                          if (isAPIRecord) {
                                            return produce(prev, (draft) => {
                                              draft.treatment_goals_attributes[goalIndex].treatment_objectives_attributes[
                                                objectiveIndex
                                              ].treatment_interventions_attributes[interventionIndex]._destroy = true
                                            })
                                          }

                                          return produce(prev, (draft) => {
                                            draft.treatment_goals_attributes[goalIndex].treatment_objectives_attributes[
                                              objectiveIndex
                                            ].treatment_interventions_attributes.splice(interventionIndex, 1)
                                          })
                                        })
                                      }}
                                    />
                                  </div>
                                )
                              })}

                              {useInterventions && (
                                <div>
                                  <Button
                                    label="Add Intervention"
                                    glyph="add"
                                    size={100}
                                    type="minimal"
                                    display="inline-flex"
                                    className="mt-3"
                                    onClick={() => {
                                      setFormData((prev: any) => {
                                        const nextInterventionIndex =
                                          size(
                                            prev.treatment_goals_attributes[goalIndex].treatment_objectives_attributes[objectiveIndex]
                                              .treatment_interventions_attributes,
                                          ) + 1

                                        const newIntervention = {
                                          _id: uuid(),
                                          name: `Intervention ${goalIndex + 1}.${objectiveIndex + 1}.${nextInterventionIndex}`,
                                          description: '',
                                          status: 'in_progress',
                                          started_at: '',
                                          target_at: '',
                                          completed_at: '',
                                        }

                                        return produce(prev, (draft) => {
                                          draft.treatment_goals_attributes[goalIndex].treatment_objectives_attributes[
                                            objectiveIndex
                                          ].treatment_interventions_attributes.push(newIntervention)
                                        })
                                      })
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )
                    })}

                    {useObjectives && (
                      <div>
                        <Button
                          label="Add Objective"
                          glyph="add"
                          size={100}
                          type="default"
                          display="inline-flex"
                          className="mt-3"
                          onClick={() => {
                            setFormData((prev: any) => {
                              return produce(prev, (draft) => {
                                draft.treatment_goals_attributes[goalIndex].treatment_objectives_attributes.push({
                                  _id: uuid(),
                                  name: `Objective ${goalIndex + 1}.${
                                    size(draft.treatment_goals_attributes[goalIndex].treatment_objectives_attributes) + 1
                                  }`,
                                  description: '',
                                  status: 'in_progress',
                                  started_at: '',
                                  target_at: '',
                                  completed_at: '',
                                  treatment_interventions_attributes: [],
                                })
                              })
                            })
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          })}

          {useGoals && <div className="pl-2">{addGoalAction}</div>}
        </>
      )}
    </>
  )
}

const CardItem = (props: any) => {
  const { data, identifier, onDelete, onUpdate, showDates, showSettings, usePlanReviews, planId, showProblemSelector } = props

  const isDesktop = useMedia({ minWidth: 1500 })
  const reviewsVisible = useTreatmentPlanStore((state: any) => state.problemReviewsVisible)

  const lastReview = data?.last_treatment_review
  const nextReview = data?.next_treatment_review

  return (
    <div
      className={clsx(
        'pt-4',
        reviewsVisible &&
          isDesktop &&
          'grid grid-cols-[1fr_220px_220px] mq1600:grid-cols-[1fr_280px_280px] mq1800:grid-cols-[1fr_340px_340px] gap-3',
      )}
    >
      <Card className="block px-3 py-3 border-solid border-transparent [&.active]:!border-blue-300 [&.active]:ring-2 ring-blue-100 text-text">
        <FormSection className="!gap-2" maxWidth="100%">
          <Flex centerY stretchSelfX gap="0.75rem" className="!flex-[1_1_auto]">
            <Status small label={identifier} color="textMuted" className="!text-text" />

            <TreatmentPlanRecordStatus
              label={null}
              value={data.status}
              onUpdate={(updated) => onUpdate('status', updated.value)}
              className="[&_select]:!min-h-[1.8rem] [&_select]:!py-0"
            />

            <Button label="Delete" glyph="delete" onClick={onDelete} type="minimal" color="red" size={100} className="!ml-auto" />
          </Flex>

          <Flex gap="1rem">
            <div className="!flex-[1_1_auto]">
              <Input label="Name" value={data.name} onUpdate={(updated) => onUpdate('name', updated.value)} />
            </div>

            {showDates && (
              <>
                {showSettings?.start_date && (
                  <DateInput
                    defaultToNow
                    label="Start"
                    value={data.started_at}
                    onUpdate={(updated) => onUpdate('started_at', updated.value)}
                  />
                )}

                {showSettings?.target_date && (
                  <DateInput label="Target" value={data.target_at} onUpdate={(updated) => onUpdate('target_at', updated.value)} />
                )}

                {showSettings?.completion_date && (
                  <DateInput label="Completion" value={data.completed_at} onUpdate={(updated) => onUpdate('completed_at', updated.value)} />
                )}
              </>
            )}
          </Flex>

          {showProblemSelector && (
            <MultiObjectSelector
              label="Linked Problems"
              dependentValue={planId}
              type="treatment_plan.treatment_problems"
              selectTitle={(data) => data.name || <i>No title</i>}
              selectDescription={(data) => data.behavioral_definitions}
              value={data.treatment_problems}
              onUpdate={(update) => {
                const newIds = update?.value?.map?.((o) => o.id) || []
                onUpdate('treatment_problem_ids', newIds)
              }}
            />
          )}

          <SmartTextarea
            useQuickText
            useDictation
            label="Description"
            value={data.description}
            onUpdate={(updated) => onUpdate('description', updated.value)}
          />
        </FormSection>
      </Card>

      {reviewsVisible && isDesktop && usePlanReviews && (
        <>
          <FormReadonlyReviewCard data={lastReview} type="last" />
          <FormReadonlyReviewCard data={nextReview} type="next" />
        </>
      )}
    </div>
  )
}
