import GLYPHS from './glyphs'

export const USER_GLYPHS = {
  circle: GLYPHS.circle,
  accreditation: GLYPHS.accreditation,
  add: GLYPHS.add,
  add_file: GLYPHS.add_file,
  additional_information: GLYPHS.additional_information,
  address: GLYPHS.address,
  asterisk: GLYPHS.asterisk,
  attachment: GLYPHS.attachment,
  balance_scale: GLYPHS.balance_scale,
  bank: GLYPHS.bank,
  car: GLYPHS.car,
  card: GLYPHS.card,
  card_payment: GLYPHS.card_payment,
  cards: GLYPHS.cards,
  cash: GLYPHS.cash,
  cash_hand: GLYPHS.cash_hand,
  cash_stack: GLYPHS.cash_stack,
  chat: GLYPHS.chat,
  check: GLYPHS.check,
  check_in: GLYPHS.check_in,
  cheque: GLYPHS.cheque,
  chevron_left: GLYPHS.chevron_left,
  chevron_right: GLYPHS.chevron_right,
  circle_add: GLYPHS.circle_add,
  circle_error: GLYPHS.circle_error,
  circle_subtract: GLYPHS.circle_subtract,
  circle_tick: GLYPHS.circle_tick,
  copy: GLYPHS.copy,
  coupon: GLYPHS.coupon,
  cross: GLYPHS.cross,
  current_risk: GLYPHS.current_risk,
  date: GLYPHS.date,
  decline: GLYPHS.decline,
  delete: GLYPHS.delete,
  discharge: GLYPHS.discharge,
  documents: GLYPHS.documents,
  dollar: GLYPHS.dollar,
  download: GLYPHS.download,
  edit: GLYPHS.edit,
  email: GLYPHS.email,
  emoji: GLYPHS.emoji,
  empty: GLYPHS.empty,
  fax: GLYPHS.fax,
  file_pdf: GLYPHS.file_pdf,
  file_txt: GLYPHS.file_txt,
  gender_all: GLYPHS.gender_all,
  gender_female: GLYPHS.gender_female,
  gender_male: GLYPHS.gender_male,
  home_address: GLYPHS.home_address,
  in_progress: GLYPHS.in_progress,
  info: GLYPHS.info,
  intake_application: GLYPHS.intake_application,
  intake_discharge: GLYPHS.intake_discharge,
  landline: GLYPHS.landline,
  legal_history: GLYPHS.legal_history,
  lock: GLYPHS.lock,
  map: GLYPHS.map,
  map_pin: GLYPHS.map_pin,
  menu: GLYPHS.menu,
  money_order: GLYPHS.money_order,
  move: GLYPHS.move,
  move_up_down: GLYPHS.move_up_down,
  not_verified_person: GLYPHS.not_verified_person,
  not_verified_shield: GLYPHS.not_verified_shield,
  note: GLYPHS.note,
  notification: GLYPHS.notification,
  office_details: GLYPHS.office_details,
  offices: GLYPHS.offices,
  online_payments: GLYPHS.online_payments,
  organizations: GLYPHS.organizations,
  password: GLYPHS.password,
  personal_details: GLYPHS.personal_details,
  phone: GLYPHS.phone,
  phone_ext: GLYPHS.phone_ext,
  photo: GLYPHS.photo,
  question: GLYPHS.question,
  quick_view: GLYPHS.quick_view,
  recurring_charge: GLYPHS.recurring_charge,
  referral_details: GLYPHS.referral_details,
  reset: GLYPHS.reset,
  search: GLYPHS.search,
  selector: GLYPHS.selector,
  sensitive_information: GLYPHS.sensitive_information,
  settings: GLYPHS.settings,
  signature: GLYPHS.signature,
  star: GLYPHS.star,
  subtract: GLYPHS.subtract,
  tag: GLYPHS.tag,
  text: GLYPHS.text,
  time: GLYPHS.time,
  unlock: GLYPHS.unlock,
  upload: GLYPHS.upload,
  user_group: GLYPHS.user_group,
  user_neutral: GLYPHS.user_neutral,
  verified_person: GLYPHS.verified_person,
  verified_shield: GLYPHS.verified_shield,
  video_circle: GLYPHS.video_circle,
  view: GLYPHS.view,
  warning: GLYPHS.warning,
  website: GLYPHS.website,
  tasks_list: GLYPHS.tasks_list,
  highlight: GLYPHS.highlight,
  redo: GLYPHS.redo,
  undo: GLYPHS.undo,
  quote: GLYPHS.quote,
  grid: GLYPHS.grid,
  link: GLYPHS.link,
  check_out: GLYPHS.check_out,
  video_call: GLYPHS.video_call,
  hide: GLYPHS.hide,
  public: GLYPHS.public,
  voucher: GLYPHS.voucher,
  flag: GLYPHS.flag,
}
