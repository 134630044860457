import React from 'react'
import clsx from 'clsx'

import useCopyToClipboard from '@behavehealth/hooks/useCopyToClipboard'

import Button from '@behavehealth/components/Button'
import Tooltip from '@behavehealth/components/Tooltip'

export const VariableKey = ({ variable, canCopy = true, className }: any) => {
  const displayVariable = `{{ ${variable} }}`

  const { copy, didCopy } = useCopyToClipboard({
    text: displayVariable,
    dismissAfter: 1000,
  })

  const rootClasses = clsx('w-full flex-[1_1_auto]', className)
  const innerClasses = clsx('w-full flex-[1_1_auto] flex items-center justify-between flex-nowrap', className)

  const tagClasses = clsx(
    'inline-flex items-center min-w-0 font-mono text-text bg-divider px-1.5 py-0.5 rounded-[4px] text-[0.8rem] whitespace-nowrap truncate',
    didCopy ? '!bg-[#ceefcf]' : '!bg-blue-100',
    canCopy && 'cursor-pointer',
  )

  if (!canCopy) {
    return (
      <div className={tagClasses}>
        <div className="truncate">{displayVariable}</div>
      </div>
    )
  }

  return (
    <Tooltip className={rootClasses} content={didCopy ? 'Variable Copied' : 'Click to copy'}>
      <div className={innerClasses} onClick={copy}>
        <div className={tagClasses}>
          <div className="truncate">{displayVariable}</div>
        </div>

        <Button
          glyph={didCopy ? 'tick_circle' : 'copy'}
          label={didCopy ? 'Copied' : 'Copy'}
          color={didCopy ? 'green' : 'blue'}
          type="default"
          size={100}
          className="ml-2 flex-[0_0_auto]"
        />
      </div>
    </Tooltip>
  )
}
