import React from 'react'
import MapGL from 'react-map-gl'
import { tint, lighten } from 'polished'
import { COLORS, GLYPHS } from '../theme'

import { isWebGLAvailable } from '../utils/functions'

import Flex from './Flex'
import Box from './Box'
import Glyph from './Glyph'
import Marker from './Marker'

const TOKEN = process.env.BH_MAPBOX
export const US_CENTER = {
  lat: 40.329796,
  lon: -96.196289,
}

const MapboxMap = ({ lat, lon, zoom = 12, data, settings, viewport, className, children }: any) => {
  const [localViewport, setLocalViewport] = React.useState({
    latitude: US_CENTER.lat,
    longitude: US_CENTER.lon,
    bearing: 0,
    pitch: 0,
    zoom,
  })

  React.useEffect(() => {
    if (lat && lon) {
      setLocalViewport({ latitude: lat, longitude: lon, zoom, bearing: 0, pitch: 0, ...viewport })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lon, zoom])

  React.useEffect(() => {
    if (!lat && !lon) return

    setLocalViewport({ latitude: lat, longitude: lon, zoom, bearing: 0, pitch: 0, ...viewport })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const hasCoordinates = lat && lon
  if (!hasCoordinates) {
    return (
      <Flex alignItems="center" justifyContent="center" css={emptyStyle} className={className}>
        <Box textAlign="center">
          <Glyph glyph={GLYPHS.map} size={32} color={tint(0.5, COLORS.gray)} />
        </Box>
      </Flex>
    )
  }

  if (!isWebGLAvailable) return null

  return (
    <div className={className}>
      <MapGL
        scrollZoom
        {...localViewport}
        {...settings}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onViewportChange={setLocalViewport}
        mapboxApiAccessToken={TOKEN}
      >
        {data?.map((point, idx) => (
          <Marker key={idx} lat={point?.lat} lon={point?.lon} record={point} />
        ))}
        {children}
      </MapGL>
    </div>
  )
}

const emptyStyle = {
  height: '100%',
  backgroundColor: lighten(0.05, COLORS.lightGray),
  borderRadius: 10,
}

MapboxMap.defaultProps = {
  zoom: 13,
  lat: US_CENTER.lat,
  lon: US_CENTER.lon,
}

export default MapboxMap
