import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import compact from 'lodash/compact'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { isDefined, usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Glyph from '../Glyph'
import Tooltip from '../Tooltip'

import { DisplayValue } from './cells/DisplayValue'
import { formatCellCoords, parseCellCoords } from './utils/functions'
import { useStore } from './utils/useStore'

export const WorksheetCell = (props: any) => {
  const { isEditable, column, rowData, className, coords } = props

  const [isEditing, setIsEditing] = React.useState(false)

  const { timezone: settingsTimezone } = useSettings()
  const timezone: any = useStore((state: any) => state.timezone)

  const dataMap: any = useStore((state: any) => state.dataMap)
  const dataIds: any = useStore((state: any) => state.dataIds)
  const processCanEdit: any = useStore((state: any) => state.processCanEdit)
  const updateRow: any = useStore((state: any) => state.updateRow)
  const editingCellCoords: any = useStore((state: any) => state.editingCellCoords)
  const editCellByCoords: any = useStore((state: any) => state.editCellByCoords)
  const editDisabledColumns: any = useStore((state: any) => state.editDisabledColumns)

  const model = compact([rowData._id, column.model]).join('.')

  const initialValue = get(dataMap, model)
  const [value, setValue] = React.useState(initialValue)

  const cellTooltip = React.useMemo(() => {
    if (!column?.config?.renderTooltip) return null

    return column.config.renderTooltip({ value, rowData })
  }, [column?.config?.renderTooltip, value, rowData])

  React.useEffect(() => {
    if (!column?.config?.onUpdate || !updateRow) return

    column.config.onUpdate({
      value,
      rowData,
      updateRow,
      rowId: rowData?._id,
    })
  }, [value, updateRow, column?.config?.onUpdate])

  React.useEffect(() => {
    if (isUndefined(initialValue)) return

    if (!isEqual(value, initialValue)) setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    if (!coords || !editingCellCoords) return

    if (coords === editingCellCoords) enterEdit()
  }, [coords, editingCellCoords])

  if (!column || !rowData) return null

  const { formatDisplayValue, config, component } = column

  const isApplicable = !isUndefined(config?.getIsApplicable) ? config.getIsApplicable({ value, rowData }) : true
  const isTextCell = !column.type || column.type === 'input' || column.type === 'textarea'

  let canEdit = !column.disableEdit && isApplicable && !editDisabledColumns?.includes(column.model)

  if (!isUndefined(processCanEdit)) {
    const processedCanEdit = processCanEdit({ rowData })
    canEdit = canEdit && processedCanEdit
  }

  const rootClasses = clsx(
    'worksheet-cell',
    !canEdit && 'is-locked',
    canEdit && 'can-edit',
    isEditing && 'is-editing',
    isTextCell && 'is-text-cell',
    className,
  )

  const InnerCellTag = component

  const enterEdit = () => {
    if (!isEditable) return

    if (!!canEdit && isApplicable) {
      setIsEditing(true)
    }
  }

  const exitEdit = () => {
    setIsEditing(false)
    editCellByCoords(null)
  }

  const onKeyDown = (e) => {
    if (e.key !== 'Enter') return

    const { x, y } = parseCellCoords(coords)

    if (!isDefined(x) || !isDefined(y)) return

    const nextY = e.shiftKey ? y - 1 : y + 1
    const lastY = size(dataIds) - 1

    if (nextY < 0 || nextY > lastY) return

    const nextCoords = formatCellCoords(x, nextY)

    editCellByCoords(nextCoords)
  }

  return (
    <>
      <div
        data-type="worksheet-cell"
        data-test="worksheet_cell"
        css={STYLES.root}
        className={rootClasses}
        onClick={enterEdit}
        style={cellTooltip?.message && cellTooltip?.color ? ({ '--cell-bg': tint(0.85, cellTooltip.color) } as any) : undefined}
      >
        {cellTooltip?.message && (
          <div css={STYLES.tooltip}>
            <Tooltip glyph={cellTooltip.glyph} content={cellTooltip.message} color={cellTooltip.color} />
          </div>
        )}

        {!isEditable && (
          <>
            {column.type === 'date_time'
              ? usDateTime(value, timezone || settingsTimezone)
              : typeof formatDisplayValue === 'function'
              ? formatDisplayValue({ value, rowData })
              : isObject(value)
              ? null
              : value}
          </>
        )}

        {isEditable && (
          <>
            {isApplicable && InnerCellTag ? (
              <>
                <InnerCellTag
                  key="cell"
                  canEdit={canEdit}
                  column={column}
                  formatDisplayValue={formatDisplayValue}
                  isEditing={isEditing}
                  model={model}
                  onBlur={exitEdit}
                  rowData={rowData}
                  setValue={setValue}
                  validations={config?.validations}
                  value={value}
                  enterEdit={enterEdit}
                  onKeyDown={onKeyDown}
                  cellTooltip={cellTooltip}
                  onCellUpdate={column?.config?.onCellUpdate}
                  renderMenuBefore={column?.config?.renderMenuBefore}
                  renderAfterCell={
                    column?.config?.renderAfterCell
                      ? () =>
                          column.config.renderAfterCell({
                            value,
                            rowData,
                            isEditable,
                          })
                      : undefined
                  }
                />
              </>
            ) : (
              <DisplayValue isEmpty>N/A</DisplayValue>
            )}
          </>
        )}

        {!canEdit && <Glyph glyph="lock" size={12} color={COLORS.textStronglyMuted} css={STYLES.lockGlyph} />}
      </div>
    </>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '1.75rem',
    padding: '0.2rem',
    borderRight: `1px solid ${COLORS.divider}`,
    cursor: 'default !important',
    position: 'relative',
    background: 'var(--cell-bg, white)',
    overflow: 'hidden',

    '&::before': {
      content: '""',
      boxShadow: `0 0 0 1px ${COLORS.blue}`,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      opacity: 0,
    },

    '&.is-editing': {
      zIndex: 3,
      overflow: 'visible',
      background: `${COLORS.white} !important`,
    },

    '&.is-locked': {
      background: COLORS.hover,
      cursor: 'not-allowed !important',
    },

    '&.can-edit:hover': {
      background: tint(0.9, COLORS.vividBlue),
      boxShadow: `0 0 0 1px ${tint(0.7, COLORS.blue)}`,
    },

    '&.can-edit.is-text-cell': {
      cursor: 'text !important',
    },

    '.wrapping-wrap &': {
      whiteSpace: 'pre-line',
    },
  },

  lockGlyph: {
    position: 'absolute',
    right: '0.25rem',
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
  },

  tooltip: {
    position: 'relative',
    zIndex: 1,
    marginRight: '0.25rem',
  },
}
