import React from 'react'
import { darken, tint } from 'polished'

import { COLORS } from '../theme'

import Flex from './Flex'
import Grid from './Grid'
import Graphic from './Graphic'
import Glyph from './Glyph'

export const TYPES = {
  default: 'blue',
  negative: 'red',
  neutral: 'gray',
  positive: 'green',
  warning: 'orange',
}

type Props = {
  actions?: React.ReactNode
  children?: React.ReactNode
  testKey?: string
  glyph?: string
  type?: keyof typeof TYPES
  color?: string
  contrast?: string
  header?: React.ReactNode
  compact?: boolean
  hide?: boolean
  isOpen?: boolean
  className?: string
}

const AccordionIcon = ({ isOpen }: any) => (
  <div className={isOpen ? 'is-open' : ''} css={accordionIconStyles}>
    <Glyph className="icon" glyph="add" color="red" size={16} />
  </div>
)

const AccordionAlert: React.FC<Props> = ({
  actions,
  children,
  testKey,
  glyph,
  type = 'default',
  color,
  contrast,
  compact,
  header,
  hide,
  isOpen = true,
  className,
}) => {
  const glyphColor: string = color ? color : COLORS[TYPES[type]]
  const [open, setOpen] = React.useState(isOpen)

  if (hide) return null

  return (
    <div
      className={className}
      css={{
        borderRadius: compact ? 2 : 7,
        alignItems: 'center',
        background: tint(0.9, glyphColor),
        boxShadow: contrast ? `inset 0 0 1px ${darken(0.16, glyphColor)}` : undefined,

        '.notion': {
          width: '100%',
        },
      }}
    >
      <Flex nowrap className="!w-full">
        <Grid gap="0.5rem" className="!w-full">
          <Flex
            justifyContent="space-between"
            css={{
              borderRadius: compact ? 2 : 7,
              padding: '0.5em 0.75em',
              margin: '1px 1px 0',
              background: tint(0.85, glyphColor),

              '&:hover': {
                background: tint(0.8, glyphColor),
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              setOpen((o: boolean) => !o)
            }}
          >
            <Flex gap="0.5rem">
              {glyph && <Graphic glyph={glyph} glyphColor={darken(0.05, glyphColor)} />}
              {header && <div css={{ textTransform: 'uppercase', fontSize: '0.95rem', fontWeight: 'bold' }}>{header}</div>}
            </Flex>

            <AccordionIcon isOpen={open} />
          </Flex>

          {open && (
            <div testKey={testKey} css={{ fontWeight: 500, width: '100%', padding: '0 0.5rem 0.75rem 0.5rem' }}>
              {children}
            </div>
          )}

          {actions && <Flex>{actions}</Flex>}
        </Grid>
      </Flex>
    </div>
  )
}

const accordionIconStyles = {
  width: 20,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 160ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  borderRadius: '50%',

  '.icon': {
    transform: 'scale3d(1,1,1)',
    fill: COLORS.blue,
    transition: 'all 160ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  '&.is-open': {
    opacity: 0.6,
    background: COLORS.text,
    transform: 'scale3d(0.85,0.85,0.85) rotateZ(135deg)',

    '.icon': {
      fill: 'white',
    },
  },
}

export default AccordionAlert
