import React from 'react'

import Select from '../Select'
import Option from '../Option'

const RosterCategorySelect = ({ label, model, validations, ...rest }) => (
  <Select fullWidth allowEmpty label={label} model={model} validations={validations} {...rest}>
    <Option label="Attendance" value="attendance" />
    <Option label="Bed Check" value="bed_check" />
    <Option label="Visitor Logs" value="visitor_log" />
    <Option label="Shift Notes" value="shift_note" />

    <option disabled></option>
    <option disabled>— Test Results</option>
    <Option label="UA Tests" value="ua_test" />
    <Option label="Breathalyzer Tests" value="breathalyzer_test" />
    <Option label="Vital Signs" value="vital_sign" />
    <Option label="Communicable Disease Tests" value="communicable_disease" />

    <option disabled></option>
    <option disabled>— Clinical Notes</option>
    <Option label="Group Notes" value="group_note" />
    <Option label="Individual Notes" value="individual_note" />
    <Option label="Case Management" value="case_management" />
    <Option label="Family Session" value="family_session" />
  </Select>
)

RosterCategorySelect.defaultProps = {
  label: 'Roster Type',
}

export default RosterCategorySelect
