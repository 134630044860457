import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { ClientProgramsTable } from '@behavehealth/components/Tables'

const pageConfig = {
  feature: 'programs',
  help: <HelpTagIframe id="client_programs" />,
  marketingID: 'client_programs',
}

const Programs: React.FC<any> = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const queryParams = { category: 'program_management' }

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'occupancies', queryParams],
    url: `/residents/${resource_id}/occupancies`,
    params: queryParams,
  })

  const isEmpty = size(data) === 0

  const actions = (
    <Button
      label="Add Program Assignment"
      type="primary"
      glyph="add"
      link={{
        pathname: `${match.url}/new`,
        parent: match,
        data: { reference: client },
      }}
    />
  )

  return (
    <Page title="Program Assignments" actions={!isEmpty && actions} {...pageConfig}>
      <Grid gap="1rem">
        <Card>
          <ClientProgramsTable
            data={data}
            isLoading={isLoading}
            localStorageKey="clients_programs"
            moveLink={(id: any) => `${match.url}/${id}/move`}
            removeLink={(id: any) => `${match.url}/${id}/remove`}
            emptyActions={actions}
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Programs, pageConfig))
