import { COLOR_CODES } from '../../components/ColorPicker'
import { FINANCIAL_STATUS, LOC } from '../../utils/constants'
import { titleCase } from '../../utils/functions'

export const DEFAULT_FILTERS = {
	name: {
		label: 'Name',
		type: 'string'
	},
	first_name: {
		label: 'First Name',
		type: 'string'
	},
	last_name: {
		label: 'Last Name',
		type: 'string'
	},
	phone_no: {
		type: 'string',
		label: 'Phone',
		glyph: 'phone'
	},
	email: {
		type: 'string',
		label: 'Email',
		glyph: 'email'
	},
	timezone: {
		type: 'string',
		label: 'Timezone',
		glyph: 'time'
	},
	address: {
		label: 'Address',
		type: 'string',
		glyph: 'map_pin'
	},
	tags: {
		type: 'tags',
		label: 'Tags',
		glyph: 'tag'
	},
	global_tags: {
		type: 'global_tags',
		label: 'Tags',
		glyph: 'tag'
	},
	notes: {
		label: 'Notes',
		type: 'string',
		glyph: 'note'
	},
	created_at: {
		type: 'date_time',
		label: 'Date Created',
		glyph: 'date'
	},
	updated_at: {
		type: 'date_time',
		label: 'Last Updated',
		glyph: 'date'
	},
	collected_at: {
		type: 'date_time',
		label: 'Date Collected',
		glyph: 'date'
	},
	client_portal_status: {
		type: 'multi_select',
		label: 'Using Portal',
		glyph: 'portal',
		options: [
			{ label: 'Yes', value: 'access' },
			{ label: 'Invited', value: 'invited' },
			{ label: 'No', value: null }
		]
	},
	test_result_status: {
		label: 'Status',
		type: 'multi_select',
		options: [
			{ label: 'Collected', value: 'collected' },
			{ label: 'Not Collected', value: 'refused' }
		]
	},
	test_result: {
		label: 'Result',
		type: 'multi_select',
		options: [
			{ label: 'Positive', value: 'positive' },
			{ label: 'Negative', value: 'negative' },
			{ label: 'Not Applicable', value: 'not_available' }
		]
	},
	// level_of_care: {
	//   type: 'multi_select',
	//   label: 'Level of Care',
	//   options: [...Object.entries(LOC).map(([value, label]) => ({ value, label })), { label: 'Empty', value: null }],
	// },
	level_of_care: {
		type: 'string',
		label: 'Level of Care'
	},
	color_code: {
		label: 'Color',
		type: 'multi_select',
		options: Object.keys(COLOR_CODES).map((key) => ({ label: titleCase(key), value: key })),
		renderGraphic: ({ value }) => (
			<div style={{ width: 12, height: 12, backgroundColor: COLOR_CODES[value], borderRadius: '100%' }} />
		)
	},
	author: {
		type: 'multi_object',
		label: 'Added By',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_group',
		showAvatar: true,
		polymorphic: true,
		selectTitle: (item: any) => item.name,
		selectDescription: (item: any) => titleCase(item.position)
	},
	employees: {
		type: 'multi_object',
		label: 'Staff',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_group',
		showAvatar: true,
		polymorphic: true,
		selectTitle: (item: any) => item.name,
		selectDescription: (item: any) => titleCase(item.position)
	},
	organization: {
		label: 'Organization',
		type: 'multi_object',
		endpoint: '/organizations',
		apiKey: 'organizations',
		glyph: 'user_group',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (item: any) => item.name
	},
	house: {
		label: 'Location',
		type: 'multi_object',
		endpoint: '/houses',
		apiKey: 'houses',
		glyph: 'organizations',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name
	}
}

export const PEOPLE_FILTERS = {
	name: {
		type: 'string',
		label: 'Full Name',
		glyph: 'user_neutral'
	},
	first_name: {
		type: 'string',
		label: 'First Name',
		glyph: 'user_neutral'
	},
	last_name: {
		type: 'string',
		label: 'Last Name',
		glyph: 'user_neutral'
	},
	phone_no: {
		type: 'string',
		label: 'Phone',
		glyph: 'phone'
	},
	email: {
		type: 'string',
		label: 'Email',
		glyph: 'email'
	},
	dob: {
		type: 'date',
		label: 'Date of Birth',
		glyph: 'date'
	},
	sex: {
		type: 'multi_select',
		label: 'Sex',
		options: [
			{ label: 'Male', value: 'male' },
			{ label: 'Female', value: 'female' },
			{ label: 'Decline to respond', value: 'decline_to_respond' },
			{ label: 'Other', value: 'other' },
			{ label: 'Empty', value: null }
		]
	},
	last_logged_in_at: {
		label: 'Last Logged in At',
		type: 'date_time',
		glyph: 'date'
	}
}

export const RESIDENTS_FILTERS = {
	preferred_name: {
		label: 'Preferred Name',
		type: 'string'
	},
	tags: DEFAULT_FILTERS.tags,
	color_code: DEFAULT_FILTERS.color_code,
	referral_source: {
		label: 'Referral In',
		type: 'multi_object',
		endpoint: '/organizations',
		apiKey: 'organizations',
		glyph: 'organizations',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name,
		selectDescription: (data: any) => `${titleCase(data.category)} - ${titleCase(data.subcategory?.name)}`
	},
	referral_out: {
		label: 'Referral Out',
		type: 'multi_object',
		endpoint: '/organizations',
		apiKey: 'organizations',
		glyph: 'user_group',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (item: any) => item.name
	},
	bed: {
		label: 'Bed',
		type: 'multi_object',
		endpoint: '/beds',
		apiKey: 'beds',
		glyph: 'organizations',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name,
		selectDescription: (data: any) => data.place?.name
	},
	room: {
		label: 'Room',
		type: 'multi_object',
		endpoint: '/rooms',
		apiKey: 'rooms',
		glyph: 'organizations',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name,
		selectDescription: (data: any) => data.place?.name
	},
	house: {
		label: 'House',
		type: 'multi_object',
		endpoint: '/houses',
		apiKey: 'houses',
		glyph: 'organizations',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name
	},
	referral_notes: {
		label: 'Referral In Notes',
		type: 'string'
	},
	referral_out_notes: {
		label: 'Referral Out Notes',
		type: 'string'
	},
	financial_data_filter: {
		label: 'Financial Data Filter',
		type: 'string'
	},
	financial_status: {
		label: 'Financial Status',
		type: 'multi_select',
		options: [
			...Object.entries(FINANCIAL_STATUS).map(([value, label]) => ({ value, label })),
			{ label: 'N/A', value: null }
		]
	},
	erx_status: {
		label: 'Synced with eRX',
		type: 'multi_select',
		options: [
			{ label: 'Synced', value: 'synced' },
			{ label: 'Not Synced', value: 'not_synced' }
		]
	},

	first_contact_at: {
		label: 'First Contact At',
		type: 'date_time',
		glyph: 'date'
	},

	last_contact_at: {
		label: 'Last Contact At',
		type: 'date_time',
		glyph: 'date'
	},

	last_contact_reason: {
		label: 'Last Contact Notes',
		type: 'string'
	},

	last_charged_at: {
		label: 'Last Charget At',
		type: 'date_time',
		glyph: 'date'
	},

	last_ua_test_at: {
		label: 'Last UA Test At',
		type: 'date_time',
		glyph: 'date'
	},

	last_payment_at: {
		label: 'Last Payment At',
		type: 'date_time',
		glyph: 'date'
	},

	last_payment_transactioned_at: {
		label: 'Last Payment Transactioned At',
		type: 'date_time',
		glyph: 'date'
	},

	last_payment_amount: {
		label: 'Last Payment Amount',
		type: 'number'
	},

	estimated_length_of_stay_days: {
		label: 'Estimated length Of Stay',
		type: 'number'
	},

	sober_for_days: {
		label: 'Sober for Days',
		type: 'number'
	},

	admission_status: {
		label: 'Admission Status',
		type: 'multi_select',
		options: [
			{ label: 'Pending', value: 'pending' },
			{ label: 'Incoming', value: 'incoming' },
			{ label: 'Waitlist', value: 'waitlist' },
			{ label: 'No Show', value: 'no_show' },
			{ label: 'Declined', value: 'declined' },
			{ label: 'Admitted', value: 'admitted' },
			{ label: 'Discharged', value: 'discharged' },
			{ label: 'Closed', value: 'closed' }
		]
	},
	admission_accepted_by: {
		label: 'Accepted By',
		type: 'multi_object',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_group',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name
	},
	admission_admitted_by: {
		label: 'Admitted By',
		type: 'multi_object',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_group',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name
	},
	admission_estimated_arrival: {
		label: 'Estimated Arrival',
		type: 'date_time',
		glyph: 'date'
	},
	admission_estimated_discharge: {
		label: 'Estimated Discharge',
		type: 'date_time',
		glyph: 'date'
	},
	admission_decline_reason: {
		label: 'Decline Reason',
		type: 'string'
	},
	admission_discharged_at: {
		label: 'Discharged At',
		type: 'date_time',
		glyph: 'date'
	},
	admission_discharge_type: {
		label: 'Discharge Type',
		type: 'string'
	},
	aftercare_plan: {
		label: 'Aftercare Plan',
		type: 'string'
	},
	admission_return_eligibility: {
		label: 'Return Eligibility',
		type: 'multi_select',
		options: [
			{ label: 'Eligible', value: 'eligible' },
			{ label: 'Not Eligible', value: 'not_eligible' },
			{ label: 'Pending Decision', value: 'pending_decision' }
		]
	},
	admission_discharge_notes: {
		label: 'Discharge Notes',
		type: 'string'
	},
	intake_application_status: {
		label: 'Application Status',
		type: 'multi_select',
		options: [
			{ label: 'Lead', value: 'lead' },
			{ label: 'Sent', value: 'sent' },
			{ label: 'Opened', value: 'opened' },
			{ label: 'Partial Completion', value: 'partial_completion' },
			{ label: 'Submitted', value: 'submitted' },
			{ label: 'Accepted', value: 'accepted' },
			{ label: 'Intake', value: 'intake' },
			{ label: 'Declined', value: 'declined' },
			{ label: 'Archived', value: 'archived' }
		]
	},
	intake_application_source: {
		label: 'ApplicationSource',
		type: 'multi_select',
		options: [
			{ label: 'Online', value: 'online' },
			{ label: 'Embed', value: 'embed' },
			{ label: 'Form', value: 'form' },
			{ label: 'App', value: 'app' },
			{ label: 'Mobile', value: 'mobile' }
		]
	},
	intake_application_last_viewed_at: {
		label: 'Application Last Viewed',
		type: 'date_time',
		glyph: 'date'
	},
	intake_application_last_viewed_by_id: {
		label: 'Last Viewed By',
		type: 'multi_object',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_group',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name
	},
	intake_application_review_status: {
		label: 'Review Status',
		type: 'multi_select',
		options: [
			{ label: 'Not Reviewed', value: 'not_reviewed' },
			{ label: 'Reviewed', value: 'reviewed' },
			{ label: 'More Info Required', value: 'more_info_required' }
		]
	},
	intake_application_applied_at: {
		label: 'Applied At',
		type: 'date_time',
		glyph: 'date'
	},
	admission_declined_at: {
		label: 'Declined At',
		type: 'date_time',
		glyph: 'date'
	},
	intake_application_declined_by: {
		label: 'Declined By',
		type: 'multi_object',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_group',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name
	},
	charges_balance: {
		label: 'Total Charges',
		type: 'number'
	},
	payments_balance: {
		label: 'Total Payments',
		type: 'number'
	},
	current_balance: {
		label: 'Current Balance',
		type: 'number'
	},
	length_of_stay_days: {
		label: 'Length Of Stay',
		type: 'number'
	},
	high_risk_title: {
		label: 'High Risk Alert',
		type: 'string'
	},
	sobriety_date: {
		label: 'Sobriety Date',
		type: 'date_time'
	},
	behave_id: {
		label: 'Client ID',
		type: 'string'
	},
	is_billed_by_behave: {
		label: 'Billed by Behave',
		type: 'boolean'
	},
	dob: {
		label: 'Date of Birth',
		type: 'date_time',
		glyph: 'date'
	},
	admission_admitted_at: {
		label: 'Admitted On',
		type: 'date_time',
		glyph: 'date'
	},
	admission_accepted_at: {
		label: 'Accepted On',
		type: 'date_time',
		glyph: 'date'
	},
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at
}

export const DATA_FORM_FILTERS = {
	client: {
		label: 'Client',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_neutral',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name
	},
	supervisor: {
		label: 'Supervisor',
		type: 'multi_object',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_neutral',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name
	},
	name: DEFAULT_FILTERS.name,
	score: {
		label: 'Score',
		type: 'number'
	},
	status: {
		label: 'Status',
		type: 'multi_select',
		options: [
			{ label: 'Draft', value: 'draft' },
			{ label: 'Client Draft', value: 'client_draft' },
			{ label: 'Employee Draft', value: 'employee_draft' },
			{ label: 'Pending Review', value: 'pending_review' },
			{ label: 'Pending Staff Review', value: 'employee_pending_review' },
			{ label: 'Pending Supervisor Review', value: 'supervisor_pending_review' },
			{ label: 'Updates Required', value: 'updates_required' },
			{ label: 'Client Updates Required', value: 'client_updates_required' },
			{ label: 'Staff Updates Required', value: 'employee_updates_required' },
			{ label: 'Signed Off', value: 'signed_off' },
			{ label: 'Closed', value: 'closed' }
		]
	},
	author: DEFAULT_FILTERS.author,
	author_signed_at: {
		label: 'Author Sign Date',
		type: 'date_time',
		glyph: 'date'
	},
	supervisor_signed_at: {
		label: 'Supervisor Sign Date',
		type: 'date_time',
		glyph: 'date'
	},
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at
}

export const FILES_FILTERS = {
  title: {
    label: 'Name',
    type: 'string',
  },
	content_type: {
		type: 'multi_select',
		label: 'File Type',
		options: [
      { label: 'Excel File', value: 'application/vnd.ms-excel' },
      { label: 'Word File', value: 'application/msword' },
      { label: 'PDF File', value: 'application/pdf' },
      { label: 'CSV File', value: 'text/csv' },
      { label: 'JPEG Image', value: 'image/jpeg' },
      { label: 'PNG Image', value: 'image/png' },
      { label: 'SVG Image', value: 'image/svg+xml' },
      { label: 'BMP Image', value: 'image/bmp' },
      { label: 'GIF Image', value: 'image/gif' },
      { label: 'M4A Audio', value: 'audio/x-m4a' },
      { label: 'MP3 Audio', value: 'audio/mpeg' },
      { label: 'WAV Audio', value: 'audio/wav' },
		],
		glyph: 'tag'
	},
	file_tags: {
    label: 'Tags',
		type: 'multi_object',
		endpoint: '/file_tags',
		apiKey: 'file_tags',
		glyph: 'tags',
		showAvatar: true,
		polymorphic: false,
		selectTitle: (data: any) => data.name
	},
	author: {
		type: 'multi_object',
		label: 'Added By',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_group',
		showAvatar: true,
		polymorphic: true,
		selectTitle: (item: any) => item.name,
		selectDescription: (item: any) => titleCase(item.position)
	},
	notes: {
		type: 'string',
		label: 'Notes'
	},
	created_at: {
		type: 'date_time',
		label: 'Date Created',
		glyph: 'date'
	},
	updated_at: {
		type: 'date_time',
		label: 'Date Updated',
		glyph: 'date'
	}
}

export const AGREEMENTS_FILTERS = {
	name: DEFAULT_FILTERS.name,
	status: {
		label: 'Signature Status',
		type: 'multi_select',
		glyph: 'signature',
		options: [
			{ label: 'Signed', value: 'signed' },
			{ label: 'Not Signed', value: 'not_signed' },
			{ label: 'Not Required', value: 'not_required' }
		]
	},
	review_cycle: {
		label: 'Review Cycle',
		type: 'multi_select',
		options: [
			{ label: 'Daily', value: 'daily' },
			{ label: 'Weekly', value: 'weekly' },
			{ label: 'Monthly', value: 'monthly' },
			{ label: 'Quarterly', value: 'quarterly' },
			{ label: 'Semi-annually', value: 'semi_annually' },
			{ label: 'Annually', value: 'annually' }
		]
	},
	contracts_count: {
		label: '# Signees',
		type: 'number'
	},
	dated_at: {
		label: 'Effective Date',
		type: 'date_time',
		glyph: 'date'
	},
	expired_at: {
		label: 'Expired At',
		type: 'date_time',
		glyph: 'date'
	},
	reviewed_at: {
		label: 'Last Review Date',
		type: 'date_time',
		glyph: 'date'
	},
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at,
	author: DEFAULT_FILTERS.author,
	cosigner: {
		label: 'Staff Signee',
		type: 'multi_object',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_neutral',
		showAvatar: true,
		polymorphic: true,
		selectTitle: (data: any) => data.name
	},
	reference: {
		label: 'Client',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_neutral',
		showAvatar: true,
		polymorphic: true,
		selectTitle: (data: any) => data.name
	}
}

export const INSURANCE_EVOB_FILTERS = {
	status: {
		label: 'eVOB Status',
		type: 'multi_select',
		options: [
			{ label: 'Processing', value: 'processing' },
			{ label: 'Issue', value: 'issue' },
			{ label: 'Processed', value: 'processed' }
		]
	},
	date_of_service: {
		label: 'Date Of Service',
		type: 'date_time',
		glyph: 'date'
	},
	payer_name: {
		label: 'Insurance Company',
		type: 'string'
	},
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at,
	resident: {
		label: 'Client',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_group',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	}
}

export const INSURANCE_POLICY_FILTERS = {
	category: {
		label: 'Type',
		type: 'multi_select',
		options: [
			{ label: 'Primary', value: 'primary' },
			{ label: 'Secondary', value: 'secondary' },
			{ label: 'Tertiary', value: 'tertiary' },
			{ label: 'Unknown', value: 'unknown' }
		]
	},
	plan: {
		label: 'Plan Name',
		type: 'string'
	},
	member_id: {
		label: 'Member ID',
		type: 'string'
	},
	group_id: {
		label: 'Group ID',
		type: 'string'
	},
	is_client_policy_holder: {
		label: 'Policy Holder',
		type: 'multi_select',
		options: [
			{ label: 'Client', value: true },
			{ label: 'Other', value: false }
		]
	},
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at,
	insurance_local_payer: {
		label: 'Insurance Payer Name',
		type: 'multi_object',
		endpoint: '/insurance_local_payers',
		apiKey: 'insurance_local_payers',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	},
	resident: {
		label: 'Client',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_group',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	}
}

export const INSURANCE_VOB_FILTERS = {
	network_status: {
		label: 'Network Status',
		type: 'multi_select',
		options: [
			{ label: 'In Network', value: 'in_network' },
			{ label: 'Out of Network', value: 'out_network' }
		]
	},
	dated_at: {
		label: 'Date Added',
		type: 'date_time',
		glyph: 'date'
	},
	effective_at: {
		label: 'Policy Effective Date',
		type: 'date_time',
		glyph: 'date'
	},
	resets_at: {
		label: 'Policy Reset Date',
		type: 'date_time',
		glyph: 'date'
	},
	terminates_at: {
		label: 'Policy Termination Date',
		type: 'date_time',
		glyph: 'date'
	},
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at,
	author: DEFAULT_FILTERS.author,
	client: {
		label: 'Client',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_neutral',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	},
	employee: {
		label: 'Assigned To',
		type: 'multi_object',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_neutral',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	},
	insurance_local_payer: {
		label: 'Insurance Payer',
		type: 'multi_object',
		endpoint: '/insurance_local_payers',
		apiKey: 'insurance_local_payers',
		glyph: 'user_neutral',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	},
	insurance_policy: {
		label: 'Plan Name',
		type: 'multi_object',
		endpoint: '/insurance_policies',
		apiKey: 'insurance_policies',
		glyph: 'user_neutral',
		selectTitle: (item: any) => item.plan
	}
}

export const CLIENT_CONNECTION_FILTERS = {
	reference: {
		label: 'Client Name',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_group',
		showAvatar: true,
		polymorphic: true,
		selectTitle: (item: any) => item.name
	},
	to: {
		label: 'Connection Name',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_group',
		showAvatar: true,
		polymorphic: true,
		selectTitle: (item: any) => item.name
	},
	category: {
		label: 'Category',
		type: 'multi_select',
		options: [
			{ label: 'Care Team', value: 'care_team' },
			{ label: 'Organization', value: 'organization' }
		]
	},
	subcategory: {
		label: 'Relation Type',
		type: 'multi_select',
		options: [
			{ value: 'admissions_coordinator', label: 'Admissions Coordinator' },
			{ value: 'care_physician', label: 'Care Physician' },
			{ value: 'case_manager', label: 'Case Manager' },
			{ value: 'employer', label: 'Employer' },
			{ value: 'parole_officer', label: 'Parole Officer' },
			{ value: 'peer_provider', label: 'Peer Provider' },
			{ value: 'interventionist', label: 'Interventionist' },
			{ value: 'probation_officer', label: 'Probation_officer' },
			{ value: 'psychiatrist', label: 'Psychiatrist' },
			{ value: 'recovery_coach', label: 'Recovery Coach' },
			{ value: 'school', label: 'School' },
			{ value: 'sober_living_home', label: 'Sober Living Home' },
			{ value: 'sponsor', label: 'Sponsor' },
			{ value: 'therapist', label: 'Therapist' },
			{ value: 'treatment_center', label: 'Treatment Center' }
		]
	},

	notes: DEFAULT_FILTERS.notes,
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at
}

export const PROGRAM_LIST_FILTERS = {
	name: {
		label: 'Name',
		type: 'string'
	},
	program: {
		label: 'Program',
		type: 'multi_object',
		endpoint: '/programs',
		apiKey: 'programs',
		selectTitle: (item: any) => item.name
	},
	residents_count: {
		label: '# Clients',
		type: 'number'
	},
	description: {
		label: 'Description',
		type: 'string'
	},
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at
}

export const SHIFT_NOTE_FILTERS = {
	category: {
		label: 'Category',
		type: 'multi_select',
		options: [
			{ label: 'General Note', value: 'general_note' },
			{ label: 'Incident Report', value: 'incident_report' }
		]
	},
	description: DEFAULT_FILTERS.notes,
	author: DEFAULT_FILTERS.author,
	dated_at: {
		label: 'Date & Time',
		type: 'date_time',
		glyph: 'date'
	},
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at,
	employees: {
		label: 'Employees',
		type: 'multi_object',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_group',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	},
	residents: {
		label: 'Clients',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_group',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	}
}

export const SAFETY_CHECK_FILTERS = {
	notes: DEFAULT_FILTERS.notes,
	author: DEFAULT_FILTERS.author,
	dated_at: {
		label: 'Date & Time',
		type: 'date_time',
		glyph: 'date'
	},
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at,
	employees: {
		label: 'Employees',
		type: 'multi_object',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_group',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	},
	resident: {
		label: 'Client',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_group',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	}
}

export const CLINICAL_NOTE_FILTERS = {
	client: {
		label: 'Clients',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_group',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	},
	tags: DEFAULT_FILTERS.tags,
	status: {
		label: 'Status',
		type: 'multi_select',
		options: [
			{ label: 'Draft', value: 'draft' },
			{ label: 'Pending Review', value: 'pending_review' },
			{ label: 'Updates Required', value: 'updates_required' },
			{ label: 'Signed Off', value: 'signed_off' },
			{ label: 'Closed', value: 'closed' }
		]
	},
	category: {
		label: 'Note Type',
		type: 'multi_select',
		options: [
			{ label: 'Individual', value: 'individual' },
			{ label: 'Group Note', value: 'group_note' },
			{ label: 'Case Management', value: 'case_management' },
			{ label: 'Family Session', value: 'family_session' }
		]
	},
	duration: {
		label: 'Duration',
		type: 'number'
	},
	started_at: {
		label: 'Session Start Date',
		glyph: 'date',
		type: 'date_time'
	},
	ended_at: {
		label: 'Session End Date',
		glyph: 'date',
		type: 'date_time'
	},
	supervisor_signed_at: {
		label: 'Supervisor Signed At',
		glyph: 'date',
		type: 'date_time'
	},
	submitted_at: {
		label: 'Submit Date',
		glyph: 'date',
		type: 'date_time'
	},
	author: DEFAULT_FILTERS.author,
	// service_to_submit: {
	//   label: 'Service to Submit',
	//   glyph: 'date',
	//   type: 'date_time',
	// },
	// submit_to_signed: {
	//   label: 'Submit to Signed',
	//   glyph: 'date',
	//   type: 'date_time',
	// },
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at
}

export const PEER_NOTE_FILTERS = {
	client: {
		label: 'Clients',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_group',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	},
	tags: DEFAULT_FILTERS.tags,
	status: {
		label: 'Status',
		type: 'multi_select',
		options: [
			{ label: 'Draft', value: 'draft' },
			{ label: 'Pending Review', value: 'pending_review' },
			{ label: 'Updates Required', value: 'updates_required' },
			{ label: 'Signed Off', value: 'signed_off' },
			{ label: 'Closed', value: 'closed' }
		]
	},
	category: {
		label: 'Note Type',
		type: 'multi_select',
		options: [
			{ label: 'Individual', value: 'individual' },
			{ label: 'Group Note', value: 'group_note' }
		]
	},
	duration: {
		label: 'Duration',
		type: 'number'
	},
	started_at: {
		label: 'Session Start Date',
		glyph: 'date',
		type: 'date_time'
	},
	ended_at: {
		label: 'Session End Date',
		glyph: 'date',
		type: 'date_time'
	},
	supervisor_signed_at: {
		label: 'Supervisor Signed At',
		glyph: 'date',
		type: 'date_time'
	},
	submitted_at: {
		label: 'Submit Date',
		glyph: 'date',
		type: 'date_time'
	},
	author: DEFAULT_FILTERS.author,
	// service_to_submit: {
	//   label: 'Service to Submit',
	//   glyph: 'date',
	//   type: 'date_time',
	// },
	// submit_to_signed: {
	//   label: 'Submit to Signed',
	//   glyph: 'date',
	//   type: 'date_time',
	// },
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at
}

export const OCCUPANCY_FILTERS = {
	name: {
		label: 'Name',
		type: 'string'
	},
	started_at: {
		label: 'Started At',
		type: 'date_time',
		glyph: 'date'
	},
	ended_at: {
		label: 'Ended At',
		type: 'date_time',
		glyph: 'date'
	},
	reference: {
		label: 'Client',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_neutral',
		showAvatar: true,
		selectTitle: (item: any) => item.name
	}
}

export const ALLERGY_FILTERS = {
	author: {
		label: 'Added By',
		type: 'multi_object',
		endpoint: '/employees',
		apiKey: 'employees',
		glyph: 'user_neutral',
		showAvatar: true,
		polymorphic: true,
		selectTitle: (data: any) => data.name
	},
	reference: {
		label: 'Client',
		type: 'multi_object',
		endpoint: '/residents',
		apiKey: 'residents',
		glyph: 'user_neutral',
		showAvatar: true,
		polymorphic: true,
		selectTitle: (data: any) => data.name
	},
	name: {
		label: 'Name',
		type: 'string'
	},
	criticality: {
		label: 'Criticality',
		type: 'string'
	},
	severity: {
		label: 'Severity',
		type: 'string'
	},
	source: {
		label: 'Source',
		type: 'multi_select',
		options: [
			{ label: 'Current Facility', value: 'internal' },
			{ label: 'eRX', value: 'erx' }
		]
	},
	status: {
		label: 'Status',
		type: 'multi_select',
		options: [
			{ label: 'Active', value: 'active' },
			{ label: 'Inactive', value: 'inactive' },
			{ label: 'Canceled', value: 'canceled' },
			{ label: 'Resolved', value: 'resolved' }
		]
	},
	category: {
		label: 'Category',
		type: 'multi_select',
		options: [
			{ label: 'Drug', value: 'drug' },
			{ label: 'Food', value: 'food' },
			{ label: 'Environmental', value: 'environmental' }
		]
	},
	reaction_type: {
		label: 'Reaction Type',
		type: 'multi_select',
		options: [
			{ label: 'Allergy', value: 'allergy' },
			{ label: 'Intolerance', value: 'intolerance' }
		]
	},
	started_on: {
		label: 'Started On',
		type: 'date_time',
		glyph: 'date'
	},
	ended_on: {
		label: 'Ended On',
		type: 'date_time',
		glyph: 'date'
	},
	created_at: DEFAULT_FILTERS.created_at,
	updated_at: DEFAULT_FILTERS.updated_at
}
