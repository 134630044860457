import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import GridTable from '../../components/GridTable'
import Section from '../../components/Section'
import SmartStatus from '../../components/SmartStatus'
import State from '../../components/State'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'

import { DataFormSignatureOverlay } from './DataFormSignatureOverlay'

export const processSignatureSettings = (settings: any = {}) => {
  const signoffWorkflowEnabled = !!settings.enable_signoff_workflow

  return {
    supervisor: {
      label: 'Supervisor',
      color: 'orange',
      shouldSign: signoffWorkflowEnabled && !!settings?.signoff_settings?.supervisors_must_sign,
      legalCopy: settings?.signatures_legal_text?.supervisors || '',
    },
    staff: {
      label: 'Staff',
      color: 'blue',
      shouldSign: signoffWorkflowEnabled && !!settings?.signoff_settings?.staff_must_sign,
      legalCopy: settings?.signatures_legal_text?.staff || '',
    },
  }
}

export const DataFormSignaturesSection = (props: any) => {
  const { signees, settings, isFinalStatus, permissionName } = props

  const { record: user } = useSettings()

  const [currentSignee, setCurrentSignee]: any = React.useState(null)

  const signatureSettings = React.useMemo(() => {
    return processSignatureSettings(settings)
  }, [settings])

  const tableConfig = React.useMemo(() => {
    const result: any = [
      {
        title: 'Role',
        width: '100px',
        render: (signee: any) => (
          <GridTable.Cell centerY>
            <SmartStatus small statuses={signatureSettings} status={signee.category} />
          </GridTable.Cell>
        ),
      },
      {
        title: 'Name',
        width: 'minmax(180px, 1fr)',
        render: (signee: any) => <ProfileCell avatar={signee.reference?.avatar} name={signee.reference?.name} />,
      },
      {
        title: 'Signature',
        width: 'minmax(300px, 1fr)',
        render: (signee: any) => {
          const shouldSign = signatureSettings[signee.category]?.shouldSign
          const isSelf = signee.reference && user && signee.reference.id === user.id && signee.reference.type === user.type

          return (
            <SignCell
              permissionName={permissionName}
              shouldSign={shouldSign}
              canEdit={!isFinalStatus && isSelf}
              signature={signee.initial_signature}
              signedAt={signee.initial_signed_at}
              onClick={() => {
                setCurrentSignee(signee)
              }}
            />
          )
        },
      },
    ]

    return result
  }, [isFinalStatus, user, signatureSettings, permissionName])

  const templateColumns = React.useMemo(() => {
    return tableConfig.map((col: any) => col.width).join(' ')
  }, [tableConfig])

  const isEmpty = size(signees) === 0

  return (
    <Section
      scrollview={{
        id: 'signatures',
        name: 'Signatures',
      }}
      headingType="h2"
      title="Signatures"
    >
      {isEmpty ? (
        <div className="grid gap-4">
          <Alert small contrast glyph="info" className="text-left">
            Add a Supervisor and/or Staff Members to this form to enable signatures
          </Alert>

          <Card key="empty-loading-state">
            <State isEmpty icon="signature" title="Signatures" emptyDescription="No signees have been added to this form yet" />
          </Card>
        </div>
      ) : (
        <Card key="data-state">
          <div className="text-[0.92rem]">
            <GridTable testKey="signees_table" useBanding={false} templateColumns={templateColumns}>
              <GridTable.Header>
                {tableConfig.map((col: any) => (
                  <GridTable.Cell key={col.title}>{col.title}</GridTable.Cell>
                ))}
              </GridTable.Header>

              {signees?.map?.((signee: any) => {
                return (
                  <GridTable.Row key={signee.id}>
                    {tableConfig.map((col: any) => (
                      <React.Fragment key={`${signee.id}-${col.title}`}>{col.render?.(signee)}</React.Fragment>
                    ))}
                  </GridTable.Row>
                )
              })}
            </GridTable>
          </div>
        </Card>
      )}

      <SummonOverlay
        isOpen={!!currentSignee}
        onClose={() => {
          setCurrentSignee(null)
        }}
        overlay={
          <DataFormSignatureOverlay
            permissionName={permissionName}
            dataID={currentSignee?.id}
            legalCopy={currentSignee && signatureSettings?.[currentSignee?.category]?.legalCopy}
          />
        }
      />
    </Section>
  )
}

const ProfileCell = ({ avatar, name, className }: any) => {
  const classNames = clsx('!flex !items-center !flex-nowrap', className)

  return (
    <GridTable.Cell className={classNames}>
      {name ? (
        <>
          <Avatar src={avatar} initials={name} size={20} />
          <div className="font-[500] ml-1">{name}</div>
        </>
      ) : (
        <div className="italic opacity-50">N/A</div>
      )}
    </GridTable.Cell>
  )
}

const SignCell = (props: any) => {
  const { shouldSign, signature, signedAt, onClick, canEdit, permissionName } = props
  const didSign = !!signature && !!signedAt

  return (
    <GridTable.Cell centerY>
      {didSign && (
        <div className="flex flex-nowrap items-center w-full">
          <Status small label={'Signed'} color="green" glyph="tick_circle" className="mr-1" />
          <Status small label={usDateTime(signedAt)} color="lightGray" glyph="date" />

          {canEdit && (
            <div className="ml-auto pl-2">
              <Button
                label="Edit"
                glyph="edit"
                size={100}
                onClick={onClick}
                css={{ svg: { margin: '0 !important' } }}
                permission={`${permissionName}.edit`}
              />
            </div>
          )}
        </div>
      )}

      {!didSign && (
        <div className="flex flex-nowrap items-center justify-between w-full">
          {shouldSign ? <Status small label="Signature Required" color="red" /> : <div>–</div>}
          {canEdit && <Button glyph="signature" label="Add Signature" size={100} onClick={onClick} permission={`${permissionName}.edit`} />}
        </div>
      )}
    </GridTable.Cell>
  )
}
