import React from 'react'

import { TenantNotionPage } from '../../constructs/TenantNotionPage'

import { AppSidebarContent } from './AppSidebarContent'
import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

const BedDashboardView: React.FC<any> = () => {
  return (
    <AppSidebarView>
      <AppSidebarHeader title="Bed Dashboard" icon="bed_management" />

      <AppSidebarContent css={styles.content}>
        <TenantNotionPage tag="bed_dashboard" />
      </AppSidebarContent>
    </AppSidebarView>
  )
}

const styles = {
  content: {
    paddingTop: '0.5rem',
  },
}

export default BedDashboardView
