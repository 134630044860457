import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../theme'

import Icon from '../../components/Icon'

export const AIBotIcon = (props: any) => {
  const { color = COLORS.blue, icon = 'behave_health', size = '24px' } = props

  return (
    <div style={{ '--icon-size': size, '--icon-color': color, '--icon-background': color && tint(0.8, color) }} css={STYLES.root}>
      <Icon icon={icon || 'behave_health'} css={STYLES.icon} />

      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" css={STYLES.svg}>
        <g clip-path="url(#clip0_1207_116)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15 30.0029C23.283 30.0029 30 23.2853 30 15.0015C30 6.71765 23.283 0 15 0C6.717 0 0 6.71765 0 15.0015C0 23.2853 6.717 30.0029 15 30.0029ZM14.1269 17.1875L12.2519 27.1875H12.3363L21.9019 13.4375H15.8688L17.7438 4.0625H17.6594L8.11314 17.1875H14.1269Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_1207_116">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

const STYLES = {
  root: {
    width: 'var(--icon-size)',
    height: 'var(--icon-size)',
    background: 'var(--icon-background)',
    position: 'relative',
    borderRadius: '50%',
  },

  svg: {
    top: 0,
    right: 0,
    transform: 'translate(25%, -15%)',
    position: 'absolute',
    width: '40%',
    height: '40%',
    zIndex: 1,
  },

  icon: {
    position: 'absolute',
    width: '65%',
    height: '65%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}
