import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { countWord } from '../../../utils/functions'
import useAPI from '../../../hooks/useAPI'

import Button from '../../Button'
import Card from '../../Card'
import Overlay from '../../Overlay'
import Section from '../../Section'
import InsuranceCodesTable from '../../Tables/InsuranceCodesTable'

import { apiCreate, apiGet } from '../../../modules/api'

const PayerInsuranceCodeImportOverlay = () => {
  const history = useHistory()
  const { resource_id }: any = useParams()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [loading, setLoading]: any = React.useState(false)

  const data = useSelector((state: any) => state.data?.tenant_insurance_codes?.data)
  const dataLoading = useSelector((state: any) => state.data?.tenant_insurance_codes?.loading)
  const isEmpty = size(data) === 0

  useAPI('tenant_insurance_codes', `/insurance_codes`)

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    setLoading(true)

    try {
      const ids = selectedRows.map((o: any) => o.original.id)
      await apiCreate({
        url: '/insurance_codes/import',
        params: {
          ids: ids,
          reference_id: resource_id,
          reference_type: 'insurance_local_payer',
        },
      })
      await apiGet({ name: 'insurance_codes', url: `/insurance_local_payers/${resource_id}/insurance_codes` })

      history.goBack()
    } catch (errors) {
      console.debug(errors)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={97}>
      <Overlay.Header title="Import Insurance Codes" />

      <Overlay.Content>
        <Section>
          <Card className="!mt-4">
            <InsuranceCodesTable title="Insurance Codes" data={data} isLoading={isEmpty && dataLoading} onSelect={setSelectedRows} />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Codes', selectedRowsCount)}` : 'Select Codes to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={loading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default PayerInsuranceCodeImportOverlay
