import React from 'react'

import { titleCase } from '../../utils/functions'
import { withFormContext } from '../../components/Forms/context'

import Alert from '../../components/Alert'
import Checkbox from '../../components/Forms/Checkbox'
import ContextShow from '../../components/Forms/ContextShow'
import FormSection from '../../components/Forms/FormSection'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'

import { useSettings } from '../../hooks/useSettings'

const MODELS_CONFIG = {
  out: {
    base: 'self_partner_settings',
    staff: 'self_partner_employees',
    locations: 'self_partner_locations',
    forms: 'self_partner_forms',
    partners: 'self_partner_partners',
  },
  in: {
    base: 'other_partner_settings',
    staff: 'other_partner_employees',
    locations: 'other_partner_locations',
    forms: 'other_partner_forms',
    partners: 'other_partner_partners',
  },
}

const STAFF_SHARE_INFO = ['Title', 'Name', 'Role', 'Phone Number', 'Email Address']
const LOCATIONS_SHARE_INFO = ['Name', 'Accepted Gender', 'Phone Number', 'Address', 'Notes']

const STAFF_INCLUDE_COLUMNS = ['name', 'position', 'phone_no', 'email']
const LOCATIONS_INCLUDE_COLUMNS = ['name', 'category', 'gender', 'phone_no', 'email', 'address']

export const PartnershipShareSettings = (props: any) => {
  const { direction, showInfo, showSelectors, excludePartnershipIds } = props

  const models = MODELS_CONFIG?.[direction] || props.models

  const { isBehave } = useSettings()

  if (!models) return null

  return (
    <FormSection maxWidth="100%" className="!gap-1">
      <ShareSetting label="Message Board" model={`${models.base}.message_board`} icon="message_board" />

      <ShareSetting
        label="Staff"
        model={`${models.base}.employees`}
        icon="employees"
        shareInfo={showInfo && STAFF_SHARE_INFO}
        contextShow={
          showSelectors && (
            <MultiOverlaySelector
              label="Shared Staff"
              model={models.staff}
              type="employees.active"
              icon="employees"
              selectTitle={(data: any) => data.name}
              selectDescription={(data: any) => titleCase(data.position)}
              includeColumns={STAFF_INCLUDE_COLUMNS}
            />
          )
        }
      />

      <ShareSetting
        label="Locations"
        model={`${models.base}.locations`}
        icon="locations"
        shareInfo={showInfo && LOCATIONS_SHARE_INFO}
        contextShow={
          showSelectors && (
            <MultiOverlaySelector
              label="Shared Locations"
              model={models.locations}
              type="properties.all"
              icon="locations"
              selectTitle={(data: any) => data.name}
              includeColumns={LOCATIONS_INCLUDE_COLUMNS}
            />
          )
        }
      />

      {isBehave && (
        <ShareSetting
          label="Partners"
          model={`${models.base}.partners`}
          icon="community_partners"
          contextShow={
            showSelectors && (
              <MultiOverlaySelector
                label="Shared Partners"
                model={models.partners}
                type="partnerships.shareable"
                icon="community_partners"
                selectTitle={(data: any) => data.other_partner?.name}
                excludeRowIds={excludePartnershipIds}
              />
            )
          }
          editableContextShow={
            <Alert small type="default" glyph="info" className="mb-2">
              <span className="font-[600]">Please note:</span> you can only share partners who allowed you to share their partnership.
            </Alert>
          }
        />
      )}

      <ShareSetting
        label="Shared Files"
        model={`${models.base}.files`}
        icon="files"
        editableContextShow={
          <Alert small type="negative" glyph="warning">
            <div>
              <span className="font-[600]">Please note:</span> both partners need to enable this feature to share files.
            </div>
            <div>Files uploaded are viewable in both companies. You are responsible for anything you share.</div>
            <div>We recommend waiting for our referrals feature coming soon to share client information with your partner.</div>
          </Alert>
        }
      />

      <ShareSetting
        label="Forms"
        model={`${models.base}.forms`}
        icon="web_form"
        contextShow={
          showSelectors && (
            <MultiOverlaySelector
              label="Shared Public Forms"
              model={models.forms}
              type="forms.public"
              icon="web_form"
              selectTitle={(data: any) => data.name}
            />
          )
        }
      />

      <ShareSetting label="Full Application Form" model={`${models.base}.full_application_form`} icon="admissions_department" />
      <ShareSetting label="EVOB Application Form" model={`${models.base}.evob_application_form`} icon="insurance" />
      <ShareSetting label="Contact Us" model={`${models.base}.contact_us`} icon="communications" />
      <ShareSetting label="Bed Availability" model={`${models.base}.bed_availability`} icon="bed_management" />

      {isBehave && (
        <ShareSetting
          label="Allow Partner to Share Your Partnership"
          model={`${models.base}.partnership_share_allowed`}
          icon="community_partners"
        />
      )}
    </FormSection>
  )
}

const ShareSetting = withFormContext(({ label, model, icon, shareInfo, contextShow, editableContextShow, isEditable }: any) => {
  return (
    <div>
      <Checkbox label={label} model={model} icon={icon} trueIcon="check" falseIcon="cross" falseStyle="faded" />

      {(shareInfo || contextShow || (isEditable && editableContextShow)) && (
        <ContextShow when={model} is={true}>
          <div className="mt-2 mb-4 pl-5">
            {isEditable && editableContextShow && <div>{editableContextShow}</div>}

            {contextShow && <div>{contextShow}</div>}

            {shareInfo && (
              <Alert small glyph="info" className="mt-2">
                <div className="font-[600]">{label} information shared with this partner:</div>
                <div className="font-[400]">{shareInfo?.join(', ')}</div>
              </Alert>
            )}
          </div>
        </ContextShow>
      )}
    </div>
  )
})
