import React from 'react'

import { isDefined } from '../../../utils/functions'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

const formatValue = ({ value }: any) => {
  if (!isDefined(value)) return <EmptyCell />
  else if (value == 0) return '0'
  else return value
}

export const NumberCell: React.FC = (props: any) => {
  return <DataTableCell {...props} hoverExpand formatValue={formatValue} align="right" />
}
