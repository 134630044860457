import React from 'react'
import { Global } from '@emotion/react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { MEDIA_QUERY } from '@behavehealth/theme'

import { PortalsContainer } from '@behavehealth/components'

import SupportPlans from './support_plans'
import HealthcarePlans from './healthcare_plans'
import Forms from './forms'

const Testing = () => {
  return (
    <>
      <Global styles={globalStyles} />

      <div css={styles.root}>
        <main css={styles.main}>
          <Switch>
            <Route path="/testing/billflow-support" children={<SupportPlans />} />
            <Route path="/testing/billflow-healthcare" children={<HealthcarePlans />} />
            <Route path="/testing/forms" children={<Forms />} />

            <Redirect exact from="/testing" to="/testing/billflow-support" />
          </Switch>
        </main>
      </div>

      <PortalsContainer />
    </>
  )
}

const styles = {
  root: {
    padding: '1rem 1rem 3rem',
    width: '100%',
  },

  nav: {
    display: 'none',

    [`${MEDIA_QUERY[2]}`]: {
      display: 'block',
    },
  },

  main: {
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    zIndex: 0,
  },
}

const globalStyles = {
  'html, body, #app': {
    overflow: 'auto',
  },
}

export default Testing
