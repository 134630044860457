import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../../../theme'
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard'

import { AIInput } from '../../components/AIInput'
import { getFormElementProps } from '../../utils/functions'
import { FormContext } from '../../../../components/Forms/context'

import BHTextarea from '../../../../components/Forms/Textarea'
import Button from '../../../../components/Button'

export const TextareaInput: React.FC<any> = (props) => {
  const { children, element, className, hoverElement, useParsedConfig, environment, isEditable, editInputDefaultValue } = props

  const [value, setValue] = React.useState('')

  const formContext = React.useContext(FormContext)

  const { copy, didCopy } = useCopyToClipboard({
    text: value,
    notification: null,
    dismissAfter: 1000,
  })

  if (!element?.config) return null

  const rootClasses = clsx('TEXTAREA_INPUT', className)
  const inputProps: any = getFormElementProps(element, { useParsedConfig, environment })

  const { ai_input_enabled, copy_enabled } = element.config

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}

      <BHTextarea
        {...inputProps}
        key={`max-rows-${element.config.textarea_max_rows}`}
        value={value}
        onUpdate={(input) => {
          setValue(input.value)
          if (isEditable) editInputDefaultValue?.(input)
        }}
        rows={element.config.textarea_max_rows ? element.config.textarea_max_rows + 1 : undefined}
        maxRows={element.config.textarea_max_rows ? element.config.textarea_max_rows + 1 : undefined}
      />

      {copy_enabled && (
        <div className="flex justify-end mt-2">
          <Button
            label={didCopy ? 'Copied!' : 'Copy'}
            glyph={didCopy ? 'tick_circle' : 'copy'}
            glyphColor={didCopy ? COLORS.green : COLORS.blue}
            color="text"
            size={100}
            isDisabled={!value}
            onClick={copy}
          />
        </div>
      )}

      {ai_input_enabled && formContext.isEditable && (
        <AIInput
          key={`ai-input-${element.config.local_ai_input_value}-${element.config.global_ai_input_value}`}
          elementConfig={element.config}
          getInput={() => value}
          onInsert={(aiResult) => {
            setValue(value + ' ' + aiResult)
          }}
          onReplace={(aiResult) => {
            setValue(aiResult)
          }}
        />
      )}
    </div>
  )
}
