import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { apiCreate } from '../../../modules/api'
import { ICONS } from '../../../theme'
import { withOverlayError } from '../../../hocs/withOverlayError'

import Button from '../../Button'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import PhoneInput from '../../Forms/PhoneInput'
import Select from '../../Forms/Select'
import Option from '../../Forms/Option'
import EmailInput from '../../Forms/EmailInput'
import RadioGroup from '../../Forms/RadioGroup'
import Textarea from '../../Forms/Textarea'
import Radio from '../../Forms/Radio'
import Overlay from '../../Overlay'
import Section from '../../Section'
import SexSelect from '../../Forms/elements/SexSelect'

const NewFacilityContact = ({ history, location }: any) => {
  const [valid, setValid] = React.useState(false)
  const loading = useSelector((state: any) => state.data?.contacts?.loading)
  const form = React.useRef()
  const match: any = useRouteMatch()

  const payerID = match?.params?.resource_id

  const onSave = async () => {
    await apiCreate({
      name: 'contacts',
      url: '/contacts',
      params: {
        ...form.current.getFormValue(),
        reference_id: payerID,
        reference_type: 'insurance_local_payer',
      },
    })

    history.goBack()
  }

  return (
    <Overlay>
      <Overlay.Header icon={ICONS.contacts} title="Add Facility Contact" />

      <Overlay.Content>
        <Form getForm={form} initialModel={location?.data} onValidationUpdate={setValid}>
          <Section>
            <FormSection>
              {/* <Select model="type" label="Type" defaultValue="facility">
                <Option label="Facility" value="facility" />
                <Option label="Public" value="public" />
              </Select> */}

              <Input
                label="First Name"
                model="first_name"
                validations={{
                  presence: {
                    message: "Please enter the contact's first name",
                  },
                }}
              />

              <Input label="Middle Name" model="middle_name" />

              <Input
                label="Last Name"
                model="last_name"
                validations={{
                  presence: {
                    message: "Please enter the contact's last name",
                  },
                }}
              />

              <SexSelect label="Sex" model="sex" />

              <Input model="role" label="Role" />

              <PhoneInput model="phone_no" label="Phone Number" />
              <Input glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />
              <EmailInput model="email" label="Email" />

              <Textarea useQuickText model="notes" label="Notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" glyph="check" type="primary" color="green" isLoading={loading} onClick={onSave} isDisabled={!valid} />
      </Overlay.Footer>
    </Overlay>
  )
}

export default withOverlayError(NewFacilityContact)
