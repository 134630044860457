import React from 'react'

import Alert, { TYPES } from '../Alert'

type Props = {
  percentage: number
  className: string
}

type PercentageStatus = {
  glyph: string
  color: string
  type: keyof typeof TYPES
  text: React.ReactNode
}

const getPercentageStatus = (percentage: number): PercentageStatus => {
  if (percentage < 100) {
    return {
      glyph: 'warning',
      color: 'orange',
      type: 'warning',
      text: (
        <>
          Distribute the remaining
          <strong> {100 - percentage}%</strong> among payers.
        </>
      ),
    }
  }

  if (percentage > 100) {
    return {
      glyph: 'circle_error',
      color: 'red',
      type: 'negative',
      text: (
        <>
          Remove <strong>{percentage - 100}%</strong> from one or more payers.
        </>
      ),
    }
  }

  if (percentage === 100) {
    return {
      glyph: 'tick_circle',
      color: 'green',
      type: 'positive',
      text: 'Total Percentage: 100%',
    }
  }

  return {
    glyph: 'warning',
    color: 'orange',
    type: 'warning',
    text: 'Set a percentage for every payer (can be zero).',
  }
}

const HundredPercentage: React.FC<Props> = ({ percentage, className }) => {
  const status = getPercentageStatus(percentage)

  return (
    <Alert glyph={status.glyph} type={status.type} className={className}>
      {status.text}
    </Alert>
  )
}

export default HundredPercentage
