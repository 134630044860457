import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS, SHADOW } from '../../../theme'
import { formatURL, isURL } from '../../../utils/functions'

import Button from '../../../components/Button'
import EmailInput from '../../../components/Forms/EmailInput'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import PhoneInput from '../../../components/Forms/PhoneInput'
import Tooltip from '../../../components/Tooltip'
import URLInput from '../../../components/Forms/URLInput'

import { VariablesDropdownMenu } from './VariablesDropdownMenu'

const DEFAULT_TOOLBAR = [
  'headings',
  'paragraph',
  'inline',
  'link',
  'color',
  'highlight',
  'quote',
  'divider',
  'table',
  'lists',
  'tasks',
  'alignment',
  'undo',
]

const TEXT_COLORS = [COLORS.red, COLORS.orange, COLORS.gold, COLORS.green, COLORS.vividBlue, COLORS.blue, COLORS.purple, COLORS.text]

export const RichTextToolbar = React.forwardRef((props: any, ref: any) => {
  const { editor, toolbar = DEFAULT_TOOLBAR, className, children } = props

  const [subMenu, setSubMenu]: any = React.useState(null)
  const [linkType, setLinkType]: any = React.useState('url')

  const [url, setUrl]: any = React.useState('')
  const [email, setEmail]: any = React.useState('')
  const [phone, setPhone]: any = React.useState('')

  const activeHref = editor?.getAttributes?.('link').href

  const showHeadings = toolbar.includes('headings')
  const showParagraph = toolbar.includes('paragraph')
  const showInline = toolbar.includes('inline')
  const showLink = toolbar.includes('link')
  const showColor = toolbar.includes('color')
  const showHighlight = toolbar.includes('highlight')
  const showQuote = toolbar.includes('quote')
  const showDivider = toolbar.includes('divider')
  const showTable = toolbar.includes('table')
  const showLists = toolbar.includes('lists')
  const showTasks = toolbar.includes('tasks')
  const showAlignment = toolbar.includes('alignment')
  const showUndo = toolbar.includes('undo')

  React.useEffect(() => {
    if (!activeHref) return

    if (activeHref.startsWith('mailto:')) {
      setLinkType('email')
    } else if (activeHref.startsWith('tel:')) {
      setLinkType('phone')
    } else {
      setLinkType('url')
    }
  }, [activeHref])

  if (!editor) return null

  return (
    <div ref={ref} className={className} css={STYLES.menuBar}>
      <div className="toolbar">
        {(showHeadings || showParagraph) && (
          <>
            {showHeadings && (
              <>
                <MenuButton
                  tooltip="Heading 1"
                  glyph="heading_1"
                  onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                  isActive={editor.isActive('heading', { level: 1 })}
                />
                <MenuButton
                  tooltip="Heading 2"
                  glyph="heading_2"
                  onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                  isActive={editor.isActive('heading', { level: 2 })}
                />
                <MenuButton
                  tooltip="Heading 3"
                  glyph="heading_3"
                  onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                  isActive={editor.isActive('heading', { level: 3 })}
                />
              </>
            )}

            {showParagraph && (
              <MenuButton
                tooltip="Paragraph"
                glyph="paragraph"
                onClick={() => editor.chain().focus().setParagraph().run()}
                isActive={editor.isActive('paragraph')}
              />
            )}
          </>
        )}

        {(showInline || showLink) && (
          <>
            <MenuDivider />

            {showInline && (
              <>
                <MenuButton
                  tooltip="Bold"
                  glyph="bold"
                  isActive={editor.isActive('bold')}
                  onClick={() => editor.chain().focus().toggleBold().run()}
                />
                <MenuButton
                  tooltip="Italic"
                  glyph="italic"
                  isActive={editor.isActive('italic')}
                  onClick={() => editor.chain().focus().toggleItalic().run()}
                />
                <MenuButton
                  tooltip="Strike"
                  glyph="strikethrough"
                  isActive={editor.isActive('strike')}
                  onClick={() => editor.chain().focus().toggleStrike().run()}
                />
                <MenuButton
                  tooltip="Underline"
                  glyph="underline"
                  onClick={() => editor.chain().focus().toggleUnderline().run()}
                  isActive={editor.isActive('underline')}
                />
              </>
            )}

            {showLink && (
              <MenuButton
                isDropdown
                tooltip="Link"
                glyph="link"
                onClick={() => {
                  setSubMenu(subMenu === 'link' ? null : 'link')
                }}
                isActive={subMenu === 'link'}
                isHighlighted={!!activeHref}
              />
            )}
          </>
        )}

        {editor?.commands?.insertContent && (
          <>
            <MenuDivider />

            <VariablesDropdownMenu
              onSelect={(variableKey) => {
                editor.commands.insertContent(` {{ ${variableKey} }}`)
              }}
              trigger={<MenuButton isDropdown tooltip="Add Variable" glyph="variables" />}
            />
          </>
        )}

        {(showColor || showHighlight) && (
          <>
            <MenuDivider />

            {showColor && size(TEXT_COLORS) >= 1 && (
              <MenuButton
                isDropdown
                tooltip="Color Picker"
                glyph="color_picker"
                onClick={() => {
                  setSubMenu(subMenu === 'color' ? null : 'color')
                }}
                isActive={subMenu === 'color'}
              />
            )}

            {showHighlight && (
              <MenuButton
                tooltip="Highlight"
                glyph="highlight"
                onClick={() => editor.chain().focus().toggleHighlight().run()}
                isActive={editor.isActive('highlight')}
              />
            )}
          </>
        )}

        {(showQuote || showDivider || showTable) && (
          <>
            <MenuDivider />

            {showQuote && (
              <MenuButton
                tooltip="Quote"
                glyph="quote"
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                isActive={editor.isActive('blockquote')}
              />
            )}

            {showDivider && (
              <MenuButton
                tooltip="Horizontal Line"
                glyph="horizontal_line"
                onClick={() => editor.chain().focus().setHorizontalRule().run()}
              />
            )}

            {showTable && (
              <MenuButton
                isDropdown
                tooltip="Table Options"
                glyph="table"
                onClick={() => {
                  setSubMenu(subMenu === 'table' ? null : 'table')
                }}
                isActive={subMenu === 'table'}
              />
            )}
          </>
        )}

        {(showLists || showTasks) && (
          <>
            <MenuDivider />

            {showLists && (
              <>
                <MenuButton
                  tooltip="Bullet List"
                  glyph="bullet_list"
                  onClick={() => editor.chain().focus().toggleBulletList().run()}
                  isActive={editor.isActive('bulletList')}
                />
                <MenuButton
                  tooltip="Ordered List"
                  glyph="ordered_list"
                  onClick={() => editor.chain().focus().toggleOrderedList().run()}
                  isActive={editor.isActive('orderedList')}
                />
              </>
            )}

            {showTasks && (
              <MenuButton
                tooltip="Tasks List"
                glyph="tasks_list"
                onClick={() => editor.chain().focus().toggleTaskList().run()}
                isActive={editor.isActive('taskList')}
              />
            )}
          </>
        )}

        {showAlignment && (
          <>
            <MenuDivider />

            <MenuButton
              tooltip="Align Left"
              glyph="text_align_left"
              onClick={() => editor.chain().focus().setTextAlign('left').run()}
              isActive={editor.isActive({ textAlign: 'left' })}
            />
            <MenuButton
              tooltip="Center"
              glyph="text_align_center"
              onClick={() => editor.chain().focus().setTextAlign('center').run()}
              isActive={editor.isActive({ textAlign: 'center' })}
            />
            <MenuButton
              tooltip="Align Right"
              glyph="text_align_right"
              onClick={() => editor.chain().focus().setTextAlign('right').run()}
              isActive={editor.isActive({ textAlign: 'right' })}
            />
            <MenuButton
              tooltip="Justify"
              glyph="text_align_justify"
              onClick={() => editor.chain().focus().setTextAlign('justify').run()}
              isActive={editor.isActive({ textAlign: 'justify' })}
            />
          </>
        )}

        {showUndo && (
          <>
            <MenuDivider />

            <MenuButton tooltip="Undo" glyph="undo" onClick={() => editor.chain().focus().undo().run()} />
            <MenuButton tooltip="Redo" glyph="redo" onClick={() => editor.chain().focus().redo().run()} />
          </>
        )}
      </div>

      {showLink && subMenu === 'link' && (
        <div className="toolbar">
          <div className="!p-1">
            <Flex centerY gap="0.5rem">
              {!activeHref && (
                <div css={STYLES.buttonsGroup}>
                  <Button
                    label="URL"
                    color="text"
                    size={100}
                    onClick={() => setLinkType('url')}
                    type={linkType === 'url' ? 'primary' : 'default'}
                  />

                  <Button
                    label="Email"
                    color="text"
                    size={100}
                    onClick={() => setLinkType('email')}
                    type={linkType === 'email' ? 'primary' : 'default'}
                  />

                  <Button
                    label="Phone"
                    color="text"
                    size={100}
                    onClick={() => setLinkType('phone')}
                    type={linkType === 'phone' ? 'primary' : 'default'}
                  />
                </div>
              )}

              {linkType === 'url' && (
                <>
                  <URLInput
                    isEditable
                    placeholder="Add link…"
                    value={activeHref?.startsWith?.('//') ? activeHref?.replace?.('//', '') : activeHref}
                    css={STYLES.linkInput}
                    model="url"
                    withHover={false}
                    onUpdate={({ value }) => {
                      setUrl(value)
                    }}
                  />

                  <Button
                    label={activeHref ? 'Update Link' : 'Set Link'}
                    size={200}
                    type="primary"
                    color="green"
                    onClick={() => editor.commands.setLink({ href: formatURL(url), target: '_blank' })}
                    isDisabled={!isURL(url)}
                  />
                </>
              )}

              {linkType === 'email' && (
                <>
                  <EmailInput
                    isEditable
                    placeholder="Add email…"
                    value={activeHref?.startsWith?.('mailto:') ? activeHref?.replace?.('mailto:', '') : ''}
                    css={STYLES.linkInput}
                    model="email"
                    withHover={false}
                    onUpdate={({ value }) => {
                      setEmail(value)
                    }}
                  />

                  <Button
                    label={activeHref ? 'Update Email' : 'Set Email'}
                    size={200}
                    type="primary"
                    color="green"
                    onClick={() => editor.commands.setLink({ href: `mailto:${email}`, target: null })}
                    isDisabled={!email}
                  />
                </>
              )}

              {linkType === 'phone' && (
                <>
                  <PhoneInput
                    isEditable
                    placeholder="Add phone…"
                    value={activeHref?.startsWith?.('tel:') ? activeHref?.replace?.('tel:', '') : ''}
                    css={STYLES.linkInput}
                    model="phone"
                    withHover={false}
                    onUpdate={({ value }) => {
                      setPhone(value)
                    }}
                  />

                  <Button
                    label={activeHref ? 'Update Phone' : 'Set Phone'}
                    size={200}
                    type="primary"
                    color="green"
                    onClick={() => editor.commands.setLink({ href: `tel:${phone}`, target: null })}
                    isDisabled={!phone}
                  />
                </>
              )}

              {activeHref && (
                <>
                  <Tooltip content="Open Link">
                    <Button
                      hideLabel
                      glyph="external_link"
                      color="blue"
                      size={200}
                      href={activeHref}
                      target="_blank"
                      useGlyphForTarget={false}
                    />
                  </Tooltip>

                  <Tooltip content="Remove Link">
                    <Button hideLabel glyph="delete" color="red" size={200} onClick={() => editor.chain().focus().unsetLink().run()} />
                  </Tooltip>
                </>
              )}
            </Flex>
          </div>
        </div>
      )}

      {showTable && subMenu === 'table' && (
        <div className="toolbar">
          <MenuButton
            tooltip="Add Table"
            glyph="table_add"
            onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
          />
          <MenuButton
            tooltip="Insert Column Before"
            glyph="table_insert_column_before"
            onClick={() => editor.chain().focus().addColumnBefore().run()}
            isDisabled={!editor.can().addColumnBefore()}
          />
          <MenuButton
            tooltip="Insert Column After"
            glyph="table_insert_column_after"
            onClick={() => editor.chain().focus().addColumnAfter().run()}
            isDisabled={!editor.can().addColumnAfter()}
          />
          <MenuButton
            tooltip="Insert Row Before"
            glyph="table_insert_row_before"
            onClick={() => editor.chain().focus().addRowBefore().run()}
            isDisabled={!editor.can().addRowBefore()}
          />
          <MenuButton
            tooltip="Insert Row After"
            glyph="table_insert_row_after"
            onClick={() => editor.chain().focus().addRowAfter().run()}
            isDisabled={!editor.can().addRowAfter()}
          />
          <MenuButton
            tooltip="Merge Cells"
            glyph="table_merge_cells"
            onClick={() => editor.chain().focus().mergeCells().run()}
            isDisabled={!editor.can().mergeCells()}
          />
          <MenuButton
            tooltip="Split Cells"
            glyph="table_split_cells"
            onClick={() => editor.chain().focus().splitCell().run()}
            isDisabled={!editor.can().splitCell()}
          />
          <MenuButton
            tooltip="Delete Column"
            glyph="table_delete_column"
            onClick={() => editor.chain().focus().deleteColumn().run()}
            isDisabled={!editor.can().deleteColumn()}
          />
          <MenuButton
            tooltip="Delete Row"
            glyph="table_delete_row"
            onClick={() => editor.chain().focus().deleteRow().run()}
            isDisabled={!editor.can().deleteRow()}
          />

          <MenuButton
            tooltip="Delete Table"
            glyph="table_delete"
            onClick={() => editor.chain().focus().deleteTable().run()}
            isDisabled={!editor.can().deleteTable()}
          />
        </div>
      )}

      {showColor && subMenu === 'color' && (
        <div className="toolbar">
          <div css={STYLES.colorsMenu}>
            {TEXT_COLORS.map((color) => {
              return (
                <MenuButton
                  key={color}
                  onClick={() => editor.chain().focus().setColor(color).run()}
                  isActive={editor.isActive('textStyle', { color })}
                  children={<div css={STYLES.colorSwatch} style={{ background: color }} />}
                  className="!m-0"
                />
              )
            })}
          </div>
        </div>
      )}

      {children && <div className="toolbar">{children}</div>}
    </div>
  )
})

const MenuDivider = () => <div css={STYLES.menuDivider} />

const MenuButton = (props: any) => {
  const { children, glyph, isActive, isDisabled, isDropdown, isHighlighted, onClick, tooltip } = props

  const classes = clsx({
    'is-active': isActive,
    'is-highlighted': isHighlighted,
    'is-disabled': isDisabled,
    'is-dropdown': isDropdown,
  })

  return (
    <Tooltip content={tooltip}>
      <button css={STYLES.menuButton} className={classes} type="button" onClick={onClick}>
        {glyph && <Glyph glyph={glyph} size={16} color={COLORS.text} />}
        {children}
        {isDropdown && <Glyph glyph="triangle_down" size={7} color={COLORS.text} />}
      </button>
    </Tooltip>
  )
}

const STYLES = {
  menuBar: {
    borderRadius: 5,
    boxShadow: SHADOW(4),
    background: COLORS.white,

    '.toolbar': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },

    '.toolbar + .toolbar': {
      borderTop: `1px solid ${COLORS.divider}`,
    },
  },

  menuDivider: {
    width: 1,
    height: 20,
    background: COLORS.divider,
    margin: '0 0.5rem',
  },

  menuButton: {
    margin: '0.25rem',
    padding: '0.4rem',
    border: 'none',
    background: 'transparent',
    borderRadius: 3,
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.hover,
      svg: { fill: COLORS.blue },
    },

    '&.is-active': {
      background: tint(0.8, COLORS.vividBlue),
      svg: { fill: COLORS.blue },
    },

    '&.is-highlighted': {
      // boxShadow: `0 0 0 1px ${tint(0.5, COLORS.blue)}`,
      background: tint(0.8, COLORS.vividBlue),
      svg: { fill: COLORS.blue },
    },

    '&.is-dropdown': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',

      '&.is-active': {
        background: COLORS.blue,
        svg: { fill: COLORS.white },
      },
    },
  },

  colorSwatch: {
    display: 'inline-block',
    width: 16,
    height: 16,
    borderRadius: '50%',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  colorsMenu: {
    // padding: '0.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  buttonsGroup: {
    display: 'flex',
    flexWrap: 'nowrap',

    '& > *': {
      marginLeft: -1,
      borderRadius: '0 !important',

      '&:first-child': {
        borderRadius: '5px 0 0 5px !important',
      },

      '&:last-child': {
        borderRadius: '0 5px 5px 0 !important',
      },
    },
  },

  linkInput: {
    input: {
      height: 28,
      minHeight: 'auto',
      fontSize: '0.9rem',
    },
  },
}
