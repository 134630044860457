import React from 'react'
import { useSelector } from 'react-redux'

import { Chotomate, Grid, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import ClientsPermissionList from '@behavehealth/components/Permissions/ClientsPermissionList'

import useAPI from '@behavehealth/hooks/useAPI'

const pageConfig = {
  feature: 'client_portal_permissions',
  title: 'Clients Permissions',
  help: <HelpTagIframe id="settings_client_portal_permissions" />,
}

const ClientsPermission: React.FC = () => {
  const data = useSelector((state: any) => state.data?.permissions_resident?.data)
  const loading = useSelector((state: any) => state.data?.permissions_resident?.loading)

  useAPI(`permissions_resident`, `/permissions/resident`)

  return (
    <Grid gap="1rem">
      <Chotomate name="clients_permissions" ready={!loading} />

      <ClientsPermissionList rules={data?.rules} custom_rules={data?.custom_rules} isLoading={loading} />
    </Grid>
  )
}

export default withPageError(withMarketing(ClientsPermission, pageConfig))
