import React from 'react'

import { Button, Card, Page, PageLayout, SummonOverlay, HelpTagIframe } from '@behavehealth/components'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { VOBsTable } from '@behavehealth/components/Tables'
import { NewVOBOverlay } from '@behavehealth/constructs/VOB/NewVOBOverlay'
import Permission from '@behavehealth/components/Permission'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const pageConfig = {
  feature: 'full_vobs',
  title: 'VOBs',
}

const VOB = ({ match }: any) => {
  const { isBehave, isSelfServiceBilling } = useSettings()

  const tableProps = useDataTable({
    name: ['insurance_vobs'],
    endpoint: `/residents/${match.params?.resource_id}/insurance_vobs`,
    params: { version: 'v2' },
    localStorageKey: 'insurance_vobs_v2',
  })

  return (
    <Page
      icon="insurance"
      title="VOBs"
      actions={
        <Permission permission={isBehave ? true : isSelfServiceBilling ? 'full_vobs.create' : false} featureFlagV2="full_vobs">
          <SummonOverlay overlay={<NewVOBOverlay />}>
            <Button type="primary" color="blue" glyph="add" label="Add New VOB…" />
          </SummonOverlay>
        </Permission>
      }
      help={<HelpTagIframe id="client_behave_vobs" />}
    >
      <PageLayout>
        <Card>
          <VOBsTable
            title="VOBs"
            icon="insurance"
            emptyDescription="Create the first VOB for this client"
            localStorageKey="insurance_vobs"
            to={(id: string) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            {...tableProps}
          />
        </Card>
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(VOB, pageConfig))
