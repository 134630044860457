import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import Markup from '../../components/Markup'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import SignaturePad from '../../components/Forms/SignaturePad'

const RootDataFormSignatureOverlay = (props: any) => {
  const {
    close,
    data,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'data_form_signees',
    endpoint: '/data_form_signees',
    invalidate: 'data_forms',
    options: props,
  })

  const { legalCopy, permissionName } = props

  const [signature, setSignature] = React.useState(null)

  if (isOverlayLoading || !data) {
    return <OverlayLoader position="center" onClose={close} />
  }

  const handleSave = async () => {
    if (isInvalid) return

    await saveWithData({ initial_signature_data: signature })

    close()
  }

  const handleDelete = async () => {
    await saveWithData({ initial_signature_data: null })

    close()
  }

  const didSign = !!data?.initial_signature
  const signatureImage = data?.initial_signature

  return (
    <Overlay showBackdrop onClose={close} position="center">
      <Overlay.Header glyph="signature" title="Form Submission Signature" />

      <Overlay.Content>
        <Form getForm={form} initialModel={initialModel} isEditable={isEditable} onValidationUpdate={onValidationUpdate}>
          <Section>
            <div className="flex item-center flex-nowrap pb-3">
              <Avatar src={data.reference.avatar} initials={data.reference.name} size={22} />
              <div className="font-[600] ml-2">{data.reference.name}'s Signature:</div>
            </div>

            {didSign ? (
              <Card>
                <img src={signatureImage} alt="Signature" className="block w-full" />
              </Card>
            ) : (
              <>
                <SignaturePad
                  isEditable
                  allowPin
                  maxWidth="100%"
                  showHeader={false}
                  showAccept={false}
                  onUpdate={({ value }) => setSignature(value)}
                />

                {legalCopy && (
                  <Alert contrast glyph="warning" type="warning" className="mt-4">
                    <Markup value={legalCopy} />
                  </Alert>
                )}
              </>
            )}
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {didSign ? (
          <DeleteDialog
            title="Delete Signature?"
            message="Are you sure you want to delete this Signature? This action cannot be undone."
            onYes={handleDelete}
          >
            <Button
              fullWidth
              label="Delete"
              type="default"
              glyph="delete"
              color="red"
              isLoading={isSaving}
              isDisabled={isSaving || isDeleting}
            />
          </DeleteDialog>
        ) : (
          <Button
            glyph="check"
            label="Save Signature"
            type="primary"
            color="green"
            isLoading={isSaving}
            onClick={handleSave}
            isDisabled={isInvalid}
            flex="100 1 auto"
            permission={`${permissionName}.edit`}
          />
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const DataFormSignatureOverlay = withOverlayError(RootDataFormSignatureOverlay)
