import { MEDIA_QUERY } from './media'

const TOKENS = {
  headerHeight: 50,
  appHeaderHeight: 50,
  appNavExpandBreakpoint: 3,
  appNavCompactBreakpoint: 1,

  wrapperPadding: {
    padding: '0.75rem',

    [MEDIA_QUERY[1]]: {
      padding: '1.2rem',
    },
  },

  easeOutCubic: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  easeInSine: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
}

export default TOKENS
