import React from 'react'
import sortBy from 'lodash/sortBy'

import { useGet } from '../../hooks/useNewAPI'

import Card from '../Card'
import CardLink from '../CardLink'
import CardTitle from '../CardTitle'
import Grid from '../Grid'
import State from '../State'

const BookmarksGrid = ({ category }: any) => {
  const { data, isLoading }: any = useGet({
    name: ['bookmarks'],
    url: `/bookmarks`,
  })

  let bookmarks: any[] = data

  if (isLoading || !data) {
    return (
      <Card>
        <State isLoading minHeight={100} />
      </Card>
    )
  }

  if (category) {
    bookmarks = sortBy(
      data.filter((o: any) => o.category === category),
      'order',
    )
  }

  return (
    <Grid columns="repeat(auto-fit, minmax(240px, 1fr))" gap="0.5rem">
      {bookmarks?.map((bookmark: any) => {
        if (!bookmark.is_active) return null

        return (
          <CardLink
            showChevron
            key={`${bookmark.id}-${bookmark.order}`}
            graphic={<img src={bookmark.icon} css={styles.logo} />}
            href={bookmark.url}
            target="_blank"
          >
            <CardTitle title={bookmark.name} css={styles.title} />
          </CardLink>
        )
      })}
    </Grid>
  )
}

const styles = {
  title: {
    fontSize: '1rem',
  },

  logo: {
    width: '2rem',
  },
}

export default BookmarksGrid
