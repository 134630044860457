import React from 'react'
import { useParams } from 'react-router-dom'
import { useParams as useParamsV6 } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { mapToArray, usDate } from '../../../utils/functions'
import { useGet } from '../../../hooks/useNewAPI'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Divider from '../../../components/Divider'
import Dropdown from '../../../components/Dropdown'
import DropdownItem from '../../../components/DropdownItem'
import FormSection from '../../../components/Forms/FormSection'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import TableFilter from '../../../components/Table/TableFilter'
import Workflow from '../../../components/Workflow/Workflow'

import TodoBuilderOverlay from '../../../components/Overlays/pages/Calendar/TodoBuilderOverlay'
import TodoOverlay from '../../../components/Overlays/pages/Calendar/TodoOverlay'

const columns = (timezone: string) => [
  {
    accessor: 'title',
    Header: 'Title',
    width: 150,
    Cell: ({ value, row }: any) => (
      <SummonOverlay overlay={<TodoOverlay showBackdrop dataID={row.original.id} initialData={row.original} />}>
        <TableCell.MainLink label={value} />
      </SummonOverlay>
    ),
  },
  {
    width: 160,
    accessor: 'started_at',
    Header: 'Date',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    accessor: 'author.name',
    Header: 'Added by',
    width: 140,
  },
]

const AddTodoButton = ({ client: clientFromProps, useV6Router }: any) => {
  const { resource_id }: any = useV6Router ? useParamsV6() : useParams()

  const clientFromState = useSelector((state: any) => state.data?.clients?.data?.[resource_id])
  const client = clientFromProps || clientFromState

  const { data: templates }: any = useGet({
    name: 'todo-templates',
    url: `/event_templates`,
    params: { event_type: 'task' },
  })

  const [isOpen, setIsOpen] = React.useState(false)
  const [initialData, setInitialData] = React.useState({})

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  return (
    <>
      <SummonOverlay isOpen={isOpen} onClose={close} overlay={<TodoBuilderOverlay dataID="new" initialData={initialData} />} />

      <Dropdown label="Add Todo…" glyph="add" buttonType="primary" buttonSize={200} display="inline-flex" css={styles.dropdown}>
        {mapToArray(templates).map((template) => (
          <DropdownItem
            label={template.title}
            glyph="edit"
            color="paleBlue"
            permission="todos.create"
            onClick={() => {
              setInitialData({
                ...template,
                resident: client,
                template_id: template.id,
              })
              open()
            }}
          />
        ))}

        <Divider />

        <DropdownItem
          label="New To-Do"
          glyph="add"
          color="paleBlue"
          permission="todos.create"
          onClick={() => {
            setInitialData({ resident: client })
            open()
          }}
        />
      </Dropdown>
    </>
  )
}

const Todos = ({ client, setStatus, timezone, useV6Router }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = useV6Router ? useParamsV6() : useParams()

  const clientID = client?.id || resource_id
  const filters = { event_type: 'task' }

  const { data, isLoading }: any = useGet({
    name: ['client', clientID, 'todos', filters],
    url: `/residents/${clientID}/events`,
    params: filters,
  })

  const isEmpty = size(data) === 0

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <FormSection maxWidth="100%">
      <Card>
        <Table
          icon="tasks"
          title="To-Do's"
          showSettings={false}
          data={data}
          isLoading={isLoading}
          columns={columns(timezone)}
          emptyDescription="No to-do's have been added yet."
          emptyActions={<AddTodoButton client={client} useV6Router={useV6Router} />}
        />
        <CardContent>
          <AddTodoButton client={client} useV6Router={useV6Router} />
        </CardContent>
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        <Workflow.SkipButton onClick={onSkip} />
      </Workflow.Buttons>
    </FormSection>
  )
}

const styles = {
  dropdown: {
    display: 'inline-flex',
  },
}

export default Todos
