import React from 'react'

import { CLAIM_PLACES_OF_SERVICE } from '../../../utils/constants'

import Select from '../../../components/Forms/Select'
import Option from '../../../components/Forms/Option'

const ClaimPlaceOfServiceSelect: React.FC<any> = ({ label = 'Place of Service', model = 'place_of_service_code', ...rest }) => (
  <Select label={label} model={model} defaultValue="21" {...rest}>
    {Object.keys(CLAIM_PLACES_OF_SERVICE)
      .sort()
      .map((key: string) => (
        <Option key={key} label={`${key} – ${CLAIM_PLACES_OF_SERVICE[key]}`} value={key} />
      ))}
  </Select>
)

export default ClaimPlaceOfServiceSelect
