import React from 'react'

import Button from '../Button'
import Overlay from '../Overlay'
import { P } from '../Typography'
import Portal from '../Portal'
import Section from '../Section'
import Animated from '../Animated'

export default class BlockDialog extends React.Component {
  state = { $open: false, $loading: false }
  mounted = true

  componentWillUnmount = () => (this.mounted = false)

  /*
    CUSTOM FUNCTIONS
  */
  open = (event) => {
    if (this.props.isDisabled) return

    event.stopPropagation()

    this.setState({ $open: true })
  }

  close = () => {
    if (!this.mounted) return
    this.setState({ $open: false })
  }

  negative = () => {
    if (this.props.onNo) this.props.onNo()
    this.close()
  }

  positive = async () => {
    if (this.props.onYes) {
      try {
        this.setState({ $loading: true })
        await this.props.onYes()
        if (!this.mounted) return
        this.setState({ $loading: false })
      } catch (error) {
        this.setState({ $loading: false })
      }
    }
    this.close()
  }

  /*
    RENDER
  */
  render() {
    const { children, title, message, glyph, yesButtonLabel } = this.props
    const { $open, $loading } = this.state

    return (
      <>
        <div onClick={this.open} className="!flex-auto">
          {children}
        </div>

        <Portal type="dialog">
          <Animated show={$open} animation="animation" timeout={{ enter: 400, exit: 400 }}>
            <Overlay stopPropagation closeOnBackdrop position="center" showBackdrop={true} onClose={this.close}>
              <Overlay.Header glyph={glyph} title={title} />

              <Overlay.Content>
                <Section>
                  <P>{message}</P>
                </Section>
              </Overlay.Content>

              <Overlay.Footer>
                <Button
                  label="No, Go Back"
                  onClick={this.negative}
                  type="default"
                  color="gray"
                  isDisabled={$loading}
                  css={{
                    flex: '3 1 auto',
                    width: 'auto',
                  }}
                />
                <Button
                  label={yesButtonLabel}
                  onClick={this.positive}
                  glyph={glyph}
                  type="primary"
                  color="green"
                  isLoading={$loading}
                  isDisabled={$loading}
                  css={{
                    flex: '1 1 auto',
                    width: 'auto',
                  }}
                />
              </Overlay.Footer>
            </Overlay>
          </Animated>
        </Portal>
      </>
    )
  }
}
