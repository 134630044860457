import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../theme'
import Dropdown from './Dropdown'

export const COLOR_CODES: any = {
  vivid_blue: COLORS.vividBlue,
  blue: COLORS.blue,
  purple: COLORS.purple,
  green: COLORS.green,
  red: COLORS.red,
  orange: COLORS.orange,
  gold: COLORS.gold,
  silver: '#bdc3c7',
}

export type ColorPickerProps = {
  color?: keyof typeof COLOR_CODES
  isDisabled?: boolean
  isLoading?: boolean
  onSelect: (selected: string) => void
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, isDisabled, isLoading, onSelect }) => {
  const cssVar: any = { '--selected-color': color ? COLOR_CODES[color] : '' }

  const handleClick = (name: string) => {
    if (isDisabled) return
    if (onSelect) onSelect(name)
  }

  const classNames = clsx({
    'has-selection': !!color,
    'is-loading': isLoading,
  })

  return (
    <div style={cssVar}>
      {isDisabled ? (
        <div css={styles.trigger} className={classNames} />
      ) : (
        <Dropdown
          minWidth={190}
          maxWidth={190}
          trigger={<button data-test="color_picker_button" type="button" css={styles.trigger} className={classNames} />}
        >
          <div css={styles.menu} style={cssVar}>
            {Object.keys(COLOR_CODES).map((key) => (
              <button
                data-test={`${key}_button`}
                key={key}
                type="button"
                css={[styles.swatch, { background: COLOR_CODES[key] }]}
                className={key === color ? 'is-active' : ''}
                onClick={() => handleClick(key)}
              />
            ))}
          </div>
        </Dropdown>
      )}
    </div>
  )
}

const styles = {
  menu: {
    padding: '0.5rem',
  },

  swatch: {
    display: 'inline-block',
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: 'none',
    margin: '0.4rem',
    cursor: 'pointer',
    transition: 'all 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',

    '&:hover': {
      transform: 'scale3d(1.15, 1.15, 1.15)',
    },

    '&.is-active': {
      boxShadow: `
        0 0 0 2px ${COLORS.white},
        0 0 0 4px var(--selected-color)
      `,
    },
  },

  trigger: {
    display: 'inline-block',
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: `2px dotted ${COLORS.divider}`,

    '&.has-selection': {
      background: 'var(--selected-color)',
      border: 'none',
    },

    '&.is-loading': {
      opacity: 0.5,
    },
  },
}

export default ColorPicker
