import React from 'react'

import { useSettings } from '../../../hooks/useSettings'
import { usDate, usDateTime } from '../../../utils/functions'
import DataList from '../../../components/DataList'

export const AgreementInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item label="Name" value={data.name} />
      {/* TODO */}
      {/* <DataList.Item label="Effective Date" value={usDateTime(data.effective_at, timezone)} /> */}
      <DataList.Item label="Date Added" value={usDateTime(data.created_at, timezone)} />
      <DataList.Item label="Last Edited" value={usDateTime(data.updated_at, timezone)} />
    </DataList>
  )
}
