import React from 'react'

import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootProviderOverlay = (props: any) => {
  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'credential',
    endpoint: '/credentials',
    invalidate: 'credentials',
    options: props,
    onSaveSuccessful: props.onSaveSuccessful,
    onDeleteSuccessful: props.onDeleteSuccessful,
  })

  const { reference } = props

  const { tenant } = useSettings()

  if (isOverlayLoading) return <OverlayLoader position="right" />

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="staff_credentials" title="Provider" />

      <Overlay.Content>
        <Form
          getForm={form}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              reference_id: reference?.id || tenant?.id,
              reference_type: reference?.type || tenant?.type,
              category: 'npi',
            }
          }
        >
          <Section>
            <FormSection maxWidth="100%">
              <Flex gap="1rem">
                <Input
                  className="!flex-auto"
                  label="Name"
                  model="credential"
                  validations={{
                    presence: {
                      message: 'Please enter a name',
                    },
                  }}
                />

                <Select
                  label="Type"
                  model="level"
                  defaultValue="individual"
                  validations={{
                    presence: {
                      message: 'Please select a type',
                    },
                  }}
                >
                  <Option label="Individual" value="individual" />
                  <Option label="Organization" value="organization" />
                </Select>
              </Flex>

              <ContextShow when="level" is="individual">
                <Flex gap="1rem" stretchChildrenX>
                  <Input
                    label="First Name"
                    model="first_name"
                    validations={{
                      presence: {
                        message: 'Please enter a first name',
                      },
                    }}
                  />
                  <Input
                    label="Last Name"
                    model="last_name"
                    validations={{
                      presence: {
                        message: 'Please enter a last name',
                      },
                    }}
                  />
                </Flex>
              </ContextShow>

              <ContextShow when="level" is="organization">
                <Input
                  label="Organization Name"
                  model="organization"
                  validations={{
                    presence: {
                      message: 'Please enter an organization name',
                    },
                  }}
                />
              </ContextShow>

              <Flex stretchChildrenX gap="1rem">
                <ContextShow when="level" is="individual">
                  <Input label="Employee ID" model="employee_identifier" />
                </ContextShow>

                <ContextShow when="level" is="individual">
                  <Input
                    label="Tax ID"
                    model="tax_identifier"
                    validations={{
                      presence: {
                        message: 'Please enter a valid tax ID',
                      },
                    }}
                  />
                </ContextShow>

                <ContextShow when="level" is="organization">
                  <Input
                    label="Tax ID"
                    model="tax_identifier"
                    validations={{
                      presence: {
                        message: 'Please enter a valid Tax ID',
                      },
                    }}
                  />
                </ContextShow>

                <Input
                  label="NPI Number"
                  model="license_number"
                  validations={{
                    presence: {
                      message: 'Please enter a valid NPI',
                    },
                  }}
                />
              </Flex>

              <Input
                label="Taxonomy Code"
                model="taxonomy_code"
                validations={{
                  presence: {
                    message: 'Please enter a valid taxonomy code',
                  },
                }}
              />

              <DateInput label="Issue Date" model="issued_at" />

              <Textarea label="Notes" model="notes" />

              <PhoneInput label="Phone No" model="phone_no" />

              <AddressSelectorInput model="address" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload files related to this Credential">
            <FormSection maxWidth="100%" layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save Credential"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="settings.insurance_providers.create"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Credential"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="settings.insurance_providers.edit"
            />

            <DeleteDialog
              title="Delete Credential?"
              message="Are you sure you want to delete this credential? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="settings.insurance_providers.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ProviderOverlay = withOverlayError(RootProviderOverlay)
