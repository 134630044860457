import React from 'react'

import Radio from '../../../../components/Forms/Radio'
import RadioGroup from '../../../../components/Forms/RadioGroup'

export const DateTimeEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput } = props

  const { config } = activeElement

  return (
    <RadioGroup
      model="date_time_input_type"
      layout="vertical-dense"
      value={config.date_time_input_type}
      onUpdate={editActiveElementFromInput}
    >
      <Radio label="Date & Time" value="date_time_input" />
      <Radio label="Date Only" value="date_input" />
      <Radio label="Time Only" value="time_input" />
    </RadioGroup>
  )
}
