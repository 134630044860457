import React from 'react'
import { useMedia } from 'use-media'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import Draggable from 'react-draggable'
import size from 'lodash/size'

import { COLORS } from '../../theme'

import Button from '../Button'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Icon from '../Icon'
import Overlay from '../Overlay'
import SummonOverlay from '../SummonOverlay'
import { InviteParticipantsOverlay } from './InviteParticipantsOverlay'
import { MeetingContext } from './context'

import { copyToClipboard } from '../../utils/functions'

type Layout = 'vertical' | 'horizontal' | 'small' | 'center' | 'fullscreen'

const DEFAULT_POSITION = { x: 0, y: 0 }

const DIALOG_LAYOUTS = {
  vertical: {
    name: 'vertical',
    width: '370px',
    height: '100%',
  },
  horizontal: {
    name: 'horizontal',
    width: '100%',
    height: '280px',
  },
  small: {
    name: 'small',
    width: '350px',
    height: '50%',
  },
  center: {
    name: 'center',
    width: '900px',
    height: '720px',
  },
  fullscreen: {
    name: 'fullscreen',
    width: '100%',
    height: '100%',
  },
}

export const MeetingOverlay = () => {
  const { leaveMeeting, isOverlayOpen, closeOverlay } = React.useContext(MeetingContext)

  const [inviteOverlayOpen, setInviteOverlayOpen] = React.useState(false)

  const openInviteOverlay = () => {
    setInviteOverlayOpen(true)
  }

  const closeInviteOverlay = () => {
    setInviteOverlayOpen(false)
  }

  return (
    <>
      <SummonOverlay
        isOpen={isOverlayOpen}
        onClose={() => {
          leaveMeeting()
          closeOverlay()
        }}
        overlay={<MeetingSummonOverlay openInviteOverlay={openInviteOverlay} />}
        portalType="telehealth"
      />
      <SummonOverlay
        isOpen={inviteOverlayOpen}
        onClose={closeInviteOverlay}
        overlay={<InviteParticipantsOverlay />}
        portalType="telehealth"
      />
    </>
  )
}

const MeetingSummonOverlay = ({ onClose }: any) => {
  const user = useSelector((state: any) => state.me?.user)
  const isBehave = user?.type === 'bh_employee'

  const isMobile = useMedia({ maxWidth: 600 })

  const { setRef, meetingRoom, meetingName, callFrame, transcript } = React.useContext(MeetingContext)

  const [layout, setLayout] = React.useState(DIALOG_LAYOUTS.center)
  const [position, setPosition]: any = React.useState(DEFAULT_POSITION)
  const [linkCopied, setLinkCopied]: any = React.useState(false)
  const [isTranscribing, setIsTranscribing]: any = React.useState(null)
  const [showTranscript, setShowTranscript]: any = React.useState(false)

  const dynamicStyles: any = {
    '--overlay-width': layout.width,
    '--overlay-height': layout.height,
  }

  const classNames = clsx(`layout-${layout.name}`, { 'is-mobile': isMobile })

  const copyLinkToClipboard = () => {
    copyToClipboard(meetingRoom.share_url)

    setLinkCopied(true)

    setTimeout(() => {
      setLinkCopied(false)
    }, 1000)
  }

  const updateLayout = (name: Layout) => {
    setLayout(DIALOG_LAYOUTS[name])
    setPosition(DEFAULT_POSITION)
  }

  const handleDrag = (_: any, data: any) => {
    setPosition({ x: data.x, y: data.y })
  }

  React.useEffect(() => {
    if (!isMobile) return

    setLayout(DIALOG_LAYOUTS.fullscreen)
  }, [isMobile])

  React.useEffect(() => {
    if (isTranscribing === true) callFrame?.startTranscription?.()
    else if (isTranscribing === false) callFrame?.stopTranscription?.()

    if (isTranscribing) setShowTranscript(true)
  }, [isTranscribing])

  const headerClasses = clsx('overlay-header', { 'is-drag-enabled': layout.name !== 'fullscreen' })

  return (
    <div css={styles.root} style={dynamicStyles} className={classNames}>
      <Draggable
        disabled={isMobile && layout.name === 'fullscreen'}
        bounds="body"
        position={position}
        onDrag={handleDrag}
        handle={isMobile && layout.name !== 'fullscreen' ? '.overlay-header-icon' : '.overlay-header'}
      >
        <Overlay onClose={onClose} position="center" css={styles.overlay}>
          <header css={styles.overlayHeader} className={headerClasses}>
            <div css={styles.overlayIcon} className="overlay-header-icon">
              {isMobile && layout.name !== 'fullscreen' ? <Glyph glyph="move" color="#5ca4ff" size={20} /> : <Icon icon="video_call" />}
            </div>

            <div css={styles.overlayHeaderContent}>
              <h3 css={styles.headerTitle}>{meetingName}</h3>

              <Flex centerY gap={8}>
                {isMobile && (
                  <Flex nowrap gap={8}>
                    <LayoutButton updateLayout={updateLayout} layout="small" isActive={layout.name === 'small'} />
                    <LayoutButton updateLayout={updateLayout} layout="fullscreen" isActive={layout.name === 'fullscreen'} />
                  </Flex>
                )}

                {linkCopied && (
                  <Button
                    label="Link Copied"
                    size={200}
                    type="link"
                    glyph="check"
                    color="green"
                    css={styles.greenLink}
                    className="copy-link-btn"
                  />
                )}

                {!linkCopied && (
                  <Button
                    label="Copy Link"
                    size={200}
                    type="link"
                    glyph="copy"
                    css={styles.link}
                    onClick={copyLinkToClipboard}
                    className="copy-link-btn"
                  />
                )}
              </Flex>
            </div>

            {!isMobile && (
              <div css={styles.layoutButtons}>
                <Flex gap={8} justifyContent="flex-end">
                  <LayoutButton updateLayout={updateLayout} layout="small" isActive={layout.name === 'small'} />
                  <LayoutButton updateLayout={updateLayout} layout="horizontal" isActive={layout.name === 'horizontal'} />
                  <LayoutButton updateLayout={updateLayout} layout="vertical" isActive={layout.name === 'vertical'} />
                  <LayoutButton updateLayout={updateLayout} layout="center" isActive={layout.name === 'center'} />
                  <LayoutButton updateLayout={updateLayout} layout="fullscreen" isActive={layout.name === 'fullscreen'} />
                </Flex>
              </div>
            )}
          </header>

          <Overlay.Content css={styles.overlayContent} className={showTranscript ? 'sidebar-visible' : ''}>
            <div ref={setRef} css={styles.callWrapper} />

            {showTranscript && (
              <div css={styles.sidebar} className="overlay-content-sidebar">
                <h3 css={styles.transcriptionTitle}>Transcription</h3>

                {size(transcript) === 0 && <div css={styles.emptyTranscription}>No transcription messages yet</div>}

                <div>
                  {transcript.map((message: any) => (
                    <div className="!pb-3">
                      <div>{message.name}:</div>
                      <div css={{ padding: '5px 0 10px 0' }}>{message.text}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Overlay.Content>

          {isBehave && (
            <div css={styles.overlayFooter}>
              <Flex justifyContent="space-between">
                <Button
                  label={isTranscribing ? 'Stop Transcribing' : 'Start Transcribing'}
                  onClick={() => setIsTranscribing(!isTranscribing)}
                  glyph="note"
                  type="link"
                  css={isTranscribing ? styles.redLink : styles.link}
                  display="inline-flex"
                  size={200}
                />
                <Button
                  label={showTranscript ? 'Hide Transcript' : 'Show Transcript'}
                  onClick={() => setShowTranscript(!showTranscript)}
                  glyph={showTranscript ? 'hide' : 'view'}
                  type="link"
                  css={styles.link}
                  display="inline-flex"
                  size={200}
                />
              </Flex>
            </div>
          )}
        </Overlay>
      </Draggable>
    </div>
  )
}

const LayoutButton: React.FC<any> = ({ layout = 'center', onClick, isActive, updateLayout }) => {
  const classNames = clsx(`layout-button`, { 'is-active': isActive })

  const handleClick = () => {
    onClick()
    updateLayout(layout)
  }

  return (
    <div className={classNames} css={styles.layoutButton} onClick={handleClick}>
      <Glyph glyph={`dialog_${layout}`} size={24} />
    </div>
  )
}

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',

    '&.layout-center': {
      alignItems: 'center',
      justifyContent: 'center',

      '#overlay-content.sidebar-visible': {
        gridTemplateRows: '60% 40%',
        gridTemplateColumns: '1fr',

        '@media(min-width: 800px)': {
          gridTemplateRows: '100%',
          gridTemplateColumns: '1fr 300px',

          '.overlay-content-sidebar': {
            borderLeft: '1px solid #435178',
            borderTop: 'none',
          },
        },
      },

      '.overlay-content-sidebar': {
        borderTop: '1px solid #435178',
      },
    },

    '&.layout-vertical': {
      alignItems: 'center',
      justifyContent: 'flex-end',

      '#overlay-content.sidebar-visible': {
        gridTemplateRows: '60% 40%',
        gridTemplateColumns: '1fr',
      },

      '.overlay-content-sidebar': {
        borderTop: '1px solid #435178',
      },
    },

    '&.layout-horizontal': {
      alignItems: 'flex-end',
      justifyContent: 'center',

      '#overlay-content.sidebar-visible': {
        gridTemplateRows: '100%',
        gridTemplateColumns: '1fr 300px',
      },

      '.overlay-content-sidebar': {
        borderLeft: '1px solid #435178',
      },
    },

    '&.layout-small': {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',

      '#overlay-content.sidebar-visible': {
        gridTemplateRows: '60% 40%',
        gridTemplateColumns: '1fr',
      },

      '.overlay-content-sidebar': {
        borderTop: '1px solid #435178',
      },
    },

    '&.layout-fullscreen': {
      '#overlay-content.sidebar-visible': {
        gridTemplateRows: '60% 40%',
        gridTemplateColumns: '1fr',

        '@media(min-width: 800px)': {
          gridTemplateRows: '100%',
          gridTemplateColumns: '1fr 300px',

          '.overlay-content-sidebar': {
            borderLeft: '1px solid #435178',
            borderTop: 'none',
          },
        },
      },

      '.overlay-content-sidebar': {
        borderTop: '1px solid #435178',
      },
    },
  },

  overlay: {
    position: 'static !important',

    width: 'var(--overlay-width) !important',
    maxWidth: '100% !important',

    height: 'var(--overlay-height) !important',
    maxHeight: '100% !important',

    '.dialog': {
      background: '#1E2434 !important',

      width: '100% !important',
      maxWidth: '100% !important',

      height: '100% !important',
      maxHeight: '100% !important',
    },

    '.overlay-close-glyph': {
      fill: `${COLORS.white} !important`,
    },

    '.close': {
      height: 44,

      '&:hover': {
        svg: {
          fill: '#ff5c4d !important',
        },
      },
    },
  },

  overlayHeader: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    padding: '0.5rem',
    paddingRight: '60px',
    color: COLORS.white,
    background: '#1E2434 !important',
    boxShadow: 'none !important',
    minHeight: 44,
    borderBottom: '1px solid #435178',

    '&.is-drag-enabled': {
      cursor: 'move',

      '&:hover': {
        background: '#151A27 !important',
      },
    },

    '& > img': {
      marginRight: '0.5rem',
    },
  },

  overlayHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    flexWrap: 'wrap',

    '.copy-link-btn': {
      paddingLeft: 0,
    },
  },

  overlayIcon: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: '0 0 auto',
    width: 32,

    '@media (max-width: 600px)': {
      width: 50,
      alignSelf: 'stretch',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '.layout-fullscreen &': {
      width: 38,
    },
  },

  headerTitle: {
    fontSize: '1.1rem',
    marginRight: '1rem',
  },

  overlayContent: {
    display: 'grid',
    position: 'relative',
    background: '#1E2434',

    iframe: {
      borderRadius: '0 !important',
    },

    '&.sidebar-visible': {
      overflow: 'hidden',
    },
  },

  sidebar: {
    color: '#f5f6fa',
    padding: '0.75rem',
    overflow: 'auto',
  },

  transcriptionTitle: {
    fontSize: '0.85rem',
    fontWeight: 600,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: COLORS.lightGray,
  },

  emptyTranscription: {
    color: COLORS.lightGray,
    opacity: 0.7,
    fontSize: '0.95rem',
    marginTop: '0.5rem',
  },

  overlayFooter: {
    padding: '0.25rem',
    borderTop: '1px solid #435178',
  },

  overlayFoter: {
    padding: '0.25rem',
  },

  callWrapper: {
    position: 'relative',
    zIndex: 1,
  },

  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },

  layoutButton: {
    opacity: 0.95,
    cursor: 'pointer',
    background: '#405381',
    width: 24,
    height: 24,
    borderRadius: 3,
    svg: { fill: '#D2E4FF' },

    '&:hover': {
      opacity: 1,
      svg: { fill: '#5ca4ff' },
    },

    '&.is-active': {
      opacity: 1,
      background: '#111',
      boxShadow: '0 0 0 1px #5ca4ff',
      svg: { fill: '#5ca4ff' },
    },
  },

  layoutButtons: {
    marginLeft: 'auto !important',
  },

  link: {
    color: '#5ca4ff !important',
    svg: { fill: '#5ca4ff !important' },
  },

  redLink: {
    color: '#ff5c4d !important',
    svg: { fill: '#ff5c4d !important' },
  },

  greenLink: {
    color: '#46e965 !important',
    svg: { fill: '#46e965 !important' },
  },
}
