import React from 'react'

import { TOKENS } from '../theme'

const Wrapper = ({ children, childrenGap, className }) => {
  const dynamicStyles = childrenGap && {
    display: 'grid',
    gridGap: childrenGap,
  }

  return (
    <div css={[styles, dynamicStyles]} className={className}>
      {children}
    </div>
  )
}

const styles = {
  position: 'relative',
  zIndex: 0,

  ...TOKENS.wrapperPadding,
}

export default Wrapper
