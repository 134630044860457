import { ICONS } from '../../theme'

export const getCustomPageIcon = (page: any) => {
  if (!page) return ICONS.blank_pages
  else if (page.icon) return page.icon
  else if (page.category === 'sub_block') return page.icon || ICONS.blank_pages
  else if (page.parent_type === 'Facility' || page.parent_type === 'facility') return ICONS.company_pages
  else if (page.level === 'behave') return ICONS.global_pages
  else if (page.level === 'ehr') return ICONS.ehr_pages
  else if (page.level === 'myaccount') return ICONS.myaccount_pages
  else if (page.level === 'members') return ICONS.community_pages
  else if (page.level === 'hq') return ICONS.hq_pages
  else if (page.level === 'community_admin') return ICONS.community_pages
  else return ICONS.blank_pages
}
