import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useAPIwithInitialClear } from '../../../hooks/useAPI'
import { useSettings } from '../../../hooks'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import FormSection from '../../../components/Forms/FormSection'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import TableFilter from '../../../components/Table/TableFilter'
import Workflow from '../../../components/Workflow/Workflow'

import { InsuranceAuthorizationOverlay } from '../../RCM/InsuranceAuthorizationOverlay'

const columns = (match: any, timezone: string) => [
  {
    width: 220,
    accessor: 'service_episodes',
    Header: 'Authorized Service Episodes',
    Cell: ({ value, row }: any) => {
      // if (!value || value?.length === 0) return '–'

      return (
        <SummonOverlay
          overlay={<InsuranceAuthorizationOverlay showBackdrop dataID={row.original.id} initialData={row.original} match={match} />}
        >
          <TableCell.MainLink label={value?.length > 0 ? value?.map((o: any) => o?.name) : '–'} />
        </SummonOverlay>
      )
    },
  },
  {
    width: 150,
    accessor: 'identifier',
    Header: 'Authorization #',
  },
  {
    width: 150,
    accessor: 'sessions',
    Header: 'Sessions Authorized',
  },
  {
    width: 100,
    accessor: 'used_sessions',
    Header: 'Sessions Used',
  },
  {
    width: 170,
    accessor: 'notes',
    Header: 'Notes',
  },
  {
    width: 80,
    accessor: 'started_at',
    Header: 'Started On',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 80,
    accessor: 'ended_at',
    Header: 'Ending On',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

const AddButton = () => {
  const match = useRouteMatch()

  return (
    <SummonOverlay overlay={<InsuranceAuthorizationOverlay dataID="new" match={match} />}>
      <Button
        label="Add Insurance Authorization"
        glyph="add"
        size={200}
        type="primary"
        display="inline-flex"
        permission="insurance_authorizations.create"
      />
    </SummonOverlay>
  )
}

const InsuranceAuthorizations = ({ setStatus }: any) => {
  const match = useRouteMatch()
  const { timezone } = useSettings()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = match.params

  const data = useSelector((state: any) => state.data.insurance_authorizations?.data)
  const loading = useSelector((state: any) => state.data.insurance_authorizations?.loading)
  const isEmpty = size(data) === 0

  useAPIwithInitialClear('insurance_authorizations', `/residents/${resource_id}/insurance_authorizations`, false)

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  if (isEmpty || loading) {
    return (
      <FormSection maxWidth="100%">
        <Card>
          <State
            isLoading={loading}
            isEmpty={isEmpty}
            title="Insurance Authorizations"
            icon="authorizations"
            emptyDescription="No insurance authorizations have been added yet"
            emptyActions={<AddButton />}
          />
        </Card>
      </FormSection>
    )
  }

  return (
    <FormSection maxWidth="100%">
      <Card>
        <Table
          icon="insurance"
          title="Insurance Authorizations"
          showSettings={false}
          data={data}
          columns={columns(match, timezone)}
          emptyDescription="No insurance authorizations have been added yet."
          emptyActions={<AddButton />}
        />
        <CardContent>
          <AddButton />
        </CardContent>
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        <Workflow.SkipButton onClick={onSkip} />
      </Workflow.Buttons>
    </FormSection>
  )
}

export default InsuranceAuthorizations
