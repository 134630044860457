import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import {
  OUTCOME_MEASURE_ADMISSION_LEVEL_OF_CARE_TITLES,
  OUTCOME_MEASURE_EMPLOYMENT_STATUS_TITLES,
  OUTCOME_MEASURE_HOUSING_STATUS_TITLES,
} from './constants'

import { useSettings } from '../../hooks/useSettings'
import { countWord } from '@behavehealth/utils/functions'

import DataFormStatus from '@behavehealth/components/Statuses/DataFormStatus'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const AdmitSurveyReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.name} to={to?.(data.data)} />,
      },
      {
        width: 180,
        id: 'client',
        model: 'data.client.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 180,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ value }) => {
          if (!value) return null

          return <DataFormStatus status={value} />
        },
      },
      {
        width: 200,
        id: 'dated_at',
        model: 'data.dated_at',
        title: 'Date and Time',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'ended_at',
        model: 'data.ended_at',
        title: 'Session End Time',
        type: 'date_time',
      },
      {
        width: 220,
        id: 'level_of_care',
        model: 'data.level_of_care',
        title: 'Level Of Care',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_ADMISSION_LEVEL_OF_CARE_TITLES[value]
        },
      },
      {
        width: 220,
        id: 'employment_status',
        model: 'data.employment_status',
        title: 'Employment Status',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_EMPLOYMENT_STATUS_TITLES[value]
        },
      },
      {
        width: 220,
        id: 'housing_status',
        model: 'data.housing_status',
        title: 'Housing Status',
        formatValue: ({ value }) => {
          if (!value) return null

          return OUTCOME_MEASURE_HOUSING_STATUS_TITLES[value]
        },
      },
      {
        width: 250,
        id: 'alcohol_days',
        model: 'data.alcohol_days',
        title: 'Prior Month Alcohol Use',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 250,
        id: 'cannabis_days',
        model: 'data.cannabis_days',
        title: 'Prior Month Cannabis Use',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 250,
        id: 'hallucinogens_days',
        model: 'data.hallucinogens_days',
        title: 'Prior Month Hallucinogens Use',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 250,
        id: 'inhalants_days',
        model: 'data.inhalants_days',
        title: 'Prior Month Inhalants Use',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 250,
        id: 'opioids_days',
        model: 'data.opioids_days',
        title: 'Prior Month Opioids Use',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 250,
        id: 'sedatives_hypnotics_anxiolytics_days',
        model: 'data.sedatives_hypnotics_anxiolytics_days',
        title: 'Prior Month Sedatives',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 220,
        id: 'stimulants_days',
        model: 'data.stimulants_days',
        title: 'Prior Month Stimulants Use',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 250,
        id: 'tobacco_days',
        model: 'data.tobacco_days',
        title: 'Prior Month Tobacco Use',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 250,
        id: 'other_substances',
        model: 'data.other_substances',
        title: 'Other Substance Use',
        formatValue: ({ value }) => {
          if (!value) return null

          return countWord('Day', value)
        },
      },
      {
        width: 250,
        id: 'history_of_drug_use',
        model: 'data.history_of_drug_use',
        title: 'History Of Drug Use',
        type: 'boolean',
      },
      {
        width: 220,
        id: 'past_overdoses_number',
        model: 'data.past_overdoses_number',
        title: '# Past Alcohol Overdoses',
      },
      {
        width: 220,
        id: 'previous_sud_treatments_number',
        model: 'data.previous_sud_treatments_number',
        title: '# Previous Sub Treatments',
      },
      {
        width: 200,
        id: 'created_at',
        model: 'data.created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'updated_at',
        model: 'data.updated_at',
        title: 'Last Updated',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'data.author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Admit Survey"
      icon="outcome_measures"
      columns={columns}
      filtersConfig={FILTERS.live_reports.admit_survey}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="admit_survey"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=admit_survey'}
        />
      }
      {...props}
    />
  )
}
