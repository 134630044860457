import React from 'react'
import { lighten } from 'polished'
import clsx from 'clsx'

import { COLORS } from '../../../../theme'
import { formatURL } from '../../../../utils/functions'

import Button from '../../../../components/Button'
import Loader from '../../../../components/Loader'
import State from '../../../../components/State'

export const Iframe: React.FC<any> = (props) => {
  const { children, element, className, hoverElement, isEditable } = props

  const iframeRef = React.useRef()

  const [isVisible, setIsVisible] = React.useState(true)
  const [width, setWidth] = React.useState('default')

  React.useEffect(() => {
    const loadingStrategy = element?.config?.loading_strategy

    // load iframe immediately
    if (!loadingStrategy || loadingStrategy === 'none') return

    // hide iframe until user click
    if (loadingStrategy === 'on_click') {
      setIsVisible(false)
    }
  }, [element?.config?.loading_strategy])

  const showIframe = () => {
    setIsVisible(true)
  }

  const {
    aspect_ratio_high = 1,
    aspect_ratio_wide = 3,
    iframe_height = 800,
    iframe_height_type = 'pixels',
    iframe_sizing_strategy = 'custom',
    iframe_width = 100,
    iframe_width_type = 'percentage',
    loading_strategy = 'none',
    show_reload_button = false,
    show_width_controls = false,
    url,
    label,
  } = element.config

  let aspectRatioWide = parseFloat(aspect_ratio_wide) || 3
  let aspectRatioHigh = parseFloat(aspect_ratio_high) || 1
  let iframeWidth = getSizeValue(iframe_width, iframe_width_type)
  let iframeHeight = getSizeValue(iframe_height, iframe_height_type)

  const aspectRatio = (aspectRatioHigh / aspectRatioWide) * 100

  const formattedURL = formatURL(url)

  const reloadIframe = () => {
    if (!iframeRef.current?.src) return

    iframeRef.current.src = formattedURL
  }

  if (!isEditable && !formattedURL) return null

  const showLoadButton = loading_strategy === 'on_click' && formattedURL && !isVisible

  const rootClasses = clsx('IFRAME', showLoadButton && 'show-load-button', className)
  const iframeWrapperClasses = clsx(show_width_controls && `user-width-${width}`)

  return (
    <div
      css={STYLES.root}
      className={rootClasses}
      style={{
        width: iframeWidth,
      }}
    >
      {hoverElement}
      {children}

      {isEditable && !formattedURL && <State isEmpty glyph="website" title="Iframe" emptyDescription="Please enter a website URL" />}

      {formattedURL && (
        <>
          {(show_reload_button || show_width_controls) && (
            <div css={STYLES.actions}>
              {show_reload_button && (
                <Button label="Reload" glyph="in_progress" color="text" size={100} css={STYLES.reloadButton} onClick={reloadIframe} />
              )}

              {show_width_controls && <UserWidthControls id={element.uuid} width={width} setWidth={setWidth} />}
            </div>
          )}

          <div
            onClick={() => {
              if (isVisible) return
              showIframe()
            }}
            css={STYLES.iframeWrapper}
            className={iframeWrapperClasses}
            style={{
              ...(iframe_sizing_strategy === 'custom' && {
                height: iframeHeight,
              }),

              ...(iframe_sizing_strategy === 'aspect_ratio' && {
                paddingBottom: `${aspectRatio}%`,
              }),
            }}
          >
            {showLoadButton ? (
              <State
                isEmpty
                icon="web_form"
                title={label}
                emptyDescription={null}
                emptyActions={<Button label="Load Content" glyph="in_progress" size={200} onClick={showIframe} />}
              />
            ) : (
              <>
                <iframe
                  ref={iframeRef}
                  src={formattedURL}
                  css={STYLES.iframe}
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                />

                {loading_strategy === 'on_click' && <Loader css={STYLES.loader} />}
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

const UserWidthControls = (props: any) => {
  const { id, width = 'default', setWidth } = props

  const handleChange = (newWidth: string) => {
    setWidth(newWidth)

    if (id) setLocalStorageWidth(id, newWidth)
  }

  React.useEffect(() => {
    const localStorageWidth = getLocalStorageWidth(id)
    if (localStorageWidth) setWidth(localStorageWidth)
  }, [])

  return (
    <div css={STYLES.widthControls}>
      <div css={STYLES.widthControlsLabel}>Width:</div>
      <div css={STYLES.widthControlsButtons}>
        <Button
          label="Default"
          color="text"
          size={100}
          onClick={() => handleChange('default')}
          type={width === 'default' ? 'primary' : 'default'}
        />
        <Button
          label="Small"
          color="text"
          size={100}
          onClick={() => handleChange('small')}
          type={width === 'small' ? 'primary' : 'default'}
        />
        <Button
          label="Medium"
          color="text"
          size={100}
          onClick={() => handleChange('medium')}
          type={width === 'medium' ? 'primary' : 'default'}
        />
        <Button
          label="Large"
          color="text"
          size={100}
          onClick={() => handleChange('large')}
          type={width === 'large' ? 'primary' : 'default'}
        />
      </div>
    </div>
  )
}

const SIZE_VALUE_TYPES = {
  percentage: '%',
  pixels: 'px',
  viewport_width: 'vw',
  viewport_height: 'vh',
}

const getSizeValue = (value: any, type: 'percentage' | 'pixels' | 'viewport_width' | 'viewport_height') => {
  const valueNumber = parseFloat(value) || 100
  const valueType = SIZE_VALUE_TYPES[type] || SIZE_VALUE_TYPES.pixels

  return `${valueNumber}${valueType}`
}

const DEFAULT_WIDTH = 'default'
const LOCAL_STORAGE_KEY = 'bh.web_builder_iframe_widths'

const setLocalStorageWidth = (id: string, width: string) => {
  if (!id || !width) return

  const value = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (value) {
    const parsedValue = JSON.parse(value)
    const newValue = { ...parsedValue, [id]: width }

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newValue))
  } else {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ [id]: width }))
  }
}

const getLocalStorageWidth = (id: string) => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (!id || !value) return DEFAULT_WIDTH

  const parsedValue = JSON.parse(value)

  return parsedValue[id] || DEFAULT_WIDTH
}

const STYLES = {
  root: {
    position: 'relative',
    margin: '0 auto',

    '&.show-load-button': {
      border: `1px solid ${COLORS.divider}`,
      background: COLORS.hover,
      cursor: 'pointer',
      borderRadius: 5,

      '&:hover': {
        background: lighten(0.4, COLORS.hover),
      },
    },
  },

  iframeWrapper: {
    width: '100%',
    position: 'relative',
    margin: '0 auto',

    '&.user-width-small': {
      maxWidth: '599px',
    },

    '&.user-width-medium': {
      maxWidth: '800px',
    },

    '&.user-width-large': {
      maxWidth: '100%',
    },
  },

  iframe: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.75rem',
    flexWrap: 'wrap',
  },

  widthControls: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0',
    flexWrap: 'wrap',
    marginLeft: 'auto',
  },

  widthControlsLabel: {
    fontWeight: 600,
    marginRight: '0.5rem',
    fontSize: '0.92rem',
  },

  widthControlsButtons: {
    display: 'flex',
    alignItems: 'center',

    '& > *': {
      marginLeft: -1,
      borderRadius: '0 !important',

      '&:first-child': {
        borderRadius: '5px 0 0 5px !important',
      },

      '&:last-child': {
        borderRadius: '0 5px 5px 0 !important',
      },
    },
  },

  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    zIndex: 0,
  },

  reloadButton: {
    width: 'fit-content',
  },
}
