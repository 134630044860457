import React from 'react'

import { useSettings } from '../../hooks'

import Table from '../../components/Table/Table'
import TableCell from '../../components/Table/TableCell'
import TableFilter from '../../components/Table/TableFilter'

import { QuickTextCategoryStatus } from './QuickTextCategoryStatus'

export const QuickTextTable = (props: any) => {
  const {
    data,
    emptyDescription = 'No quick texts created yet',
    localStorageKey = 'quick_texts',
    title = 'Quick Text',
    to,
    ...rest
  } = props

  const { timezone } = useSettings()

  return (
    <Table
      data={data}
      testKey="quick_texts_table"
      title={title}
      icon="quick_text"
      columns={columns(to, timezone, props.mainLinkAs)}
      emptyDescription={emptyDescription}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

const columns = (to: Function = () => {}, timezone: string, mainLinkAs: any) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink as={mainLinkAs} to={to(row.original.id, row.original.category)} label={value} />,
  },
  {
    width: 120,
    accessor: 'category',
    Header: 'Visibility',
    Cell: ({ value }: any) => <QuickTextCategoryStatus status={value} />,
  },
  {
    width: 200,
    accessor: 'subcategory',
    Header: 'Category',
  },
  {
    width: 260,
    accessor: 'notes',
    Header: 'Notes',
    Cell: ({ value }: any) => <TableCell.Markup value={value} />,
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Date Updated',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  // {
  //   width: 180,
  //   accessor: 'author.name',
  //   Header: 'Added By',
  //   Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={value} />,
  // },
]
