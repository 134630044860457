import React from 'react'

import { useCreate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Link from '../../components/Link'
import DataList from '../../components/DataList'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'
import ConfirmDialog from '@behavehealth/components/Dialogs/ConfirmDialog'

import Notifications from '../../modules/notifications'

import { PartnershipInviteToStatus } from './PartnershipInviteToStatus'
import { PartnershipInviteFromStatus } from './PartnershipInviteFromStatus'

import { useCommunityPermissions } from '../Community/useCommunityPermissions'

const RootPartnershipInviteSentOverlay = (props: any) => {
  const { close, form, onValidationUpdate, isOverlayLoading, saveWithData, initialModel, isSaving, data, deleteRecord, isDeleting } =
    useOverlay({
      name: 'partnership-invite',
      endpoint: `/partnership_invites`,
      invalidate: 'partnership-invites',
      options: props,
    })

  const { mutateAsync: asyncCancel, isLoading: isCancelling }: any = useCreate({
    name: ['cancel-partnership-invite', data?.id],
    url: `/partnership_invites/${data?.id}/cancel`,
    invalidate: ['partnership-invite', data?.id],
    invalidateKeys: ['partnership-invites'],
  })

  const { mutateAsync: asyncResend, isLoading: isResending }: any = useCreate({
    name: ['resend-partnership-invite', data?.id],
    url: `/partnership_invites/${data?.id}/resend`,
    invalidate: ['partnership-invite', data?.id],
    invalidateKeys: ['partnership-invites'],
  })

  const { canAcceptDeclineCancelCommunityInvites } = useCommunityPermissions()

  const { timezone } = useSettings()

  const handleCancel = async () => {
    try {
      await asyncCancel({})

      Notifications.send('Successfully Cancelled Invite', 'positive')

      close()
    } catch (error) {
      console.error(error)
      Notifications.send(error?.data?.errors?.[0], 'negative')
    }
  }

  const handleResend = async () => {
    try {
      await asyncResend({})

      Notifications.send('Successfully Re-Sent Invite', 'positive')

      close()
    } catch (error) {
      console.error(error)
      Notifications.send(error?.data?.errors?.[0], 'negative')
    }
  }

  if (isOverlayLoading || !data) return <OverlayLoader position="right" />

  const sentFrom = data.sent_from
  const sentTo = data.sent_to
  const organization = data.organization

  return (
    <Overlay onClose={close}>
      <Overlay.Header
        title="Partnership Invite Sent"
        icon="community_invites"
        subtitle={
          <Flex gap="0.5rem">
            <PartnershipInviteFromStatus status={data.status_from} />
            {data?.status_from !== 'cancelled' && <PartnershipInviteToStatus status={data.status_to} />}
          </Flex>
        }
      />

      <Overlay.Content>
        <Form isEditable={false} getForm={form} timezone={timezone} initialModel={initialModel} onValidationUpdate={onValidationUpdate}>
          <Section>
            <Grid gap="1rem">
              <div className="grid grid-cols-2 gap-5">
                {/* SENT FROM */}
                <Card className="pr-3 py-2 flex items-center">
                  <div className="w-[3.25rem] flex items-center justify-center shrink-0">
                    <Icon icon="enterprise" size={20} />
                  </div>

                  <div>
                    <div className="uppercase font-[600] text-[0.82rem] text-text-muted opacity-80 tracking-[1px]">Sent From</div>
                    <h3 className="font-[700] text-[1.15rem]">{sentFrom.name}</h3>
                    <div>The invite was sent on behalf of your EHR</div>
                  </div>
                </Card>

                {/* SENT TO */}
                <Link to={`/community/organizations/${organization.id}`} className="flex flex-nowrap items-center">
                  <Card className="pr-3 py-2 flex items-center">
                    <div className="w-[3.25rem] flex items-center justify-center shrink-0">
                      <Icon icon="enterprise" size={20} />
                    </div>

                    <div>
                      <div className="uppercase font-[600] text-[0.82rem] text-text-muted opacity-80 tracking-[1px]">Sent To</div>
                      <h3 className="font-[700] text-[1.15rem]">{sentTo.name}</h3>
                      <div>The invite was sent to this EHR</div>
                    </div>
                  </Card>
                </Link>
              </div>

              <Card className="pr-3 py-2 flex items-center">
                <div className="w-[3.25rem] flex items-center justify-center shrink-0">
                  <Icon icon="communications" size={20} />
                </div>

                <div>
                  <div className="uppercase font-[600] text-[0.82rem] text-text-muted opacity-80 tracking-[1px]">Notifications</div>
                  <h3 className="font-[700] text-[1.15rem]">{}</h3>

                  <div className="mt-1">
                    Email notifications were sent to the following addresses set up in <strong>{sentTo.name}</strong>:
                  </div>

                  <DataList isCompact labelWidth={60} className="mt-1">
                    <DataList.Item label="Emails: " value={data.sent_to_emails.join(', ') || '–'} />
                  </DataList>
                </div>
              </Card>

              <Input label="Subject" model="subject" />

              <Textarea label="Message" model="message" />

              {/* <Grid gap="0.25rem">
                <Label label="Contacts Invited" />

                {data.contacts?.map((contact, index) => (
                  <Card key={`${contact.name} ${index}`} className="px-4 pt-3 pb-4">
                    <div className="flex justify-between">
                      <div className="font-[600] mb-2">Contact #{index + 1}</div>
                    </div>

                    <Grid gap="0.75rem">
                      <Input label="Name" value={contact.name} />
                      <EmailInput label="Email" value={contact.email} />
                      <PhoneInput label="Phone Number" value={contact.phone_no} />
                      <URLInput label="Web Presence URL" value={contact.url} />
                    </Grid>
                  </Card>
                ))}
              </Grid> */}
            </Grid>
          </Section>
        </Form>
      </Overlay.Content>

      {canAcceptDeclineCancelCommunityInvites && (
        <Overlay.Footer>
          {data.status_from === 'sent' && (
            <ConfirmDialog
              glyph="cross"
              title="Cancel Invite?"
              message="Are you sure you want to cancel this Invite? You will be able to re-send it later."
              yesColor="green"
              onYes={handleCancel}
            >
              <Button label="Cancel Invite" glyph="cross" type="default" color="text" glyphColor="red" isLoading={isCancelling} />
            </ConfirmDialog>
          )}

          {data.status_from === 'cancelled' && (
            <>
              <ConfirmDialog
                glyph="tick_circle"
                title="Re-Send Invite?"
                message="Are you sure you want to re-send this Invite? You will be able to cancel it later."
                yesColor="green"
                onYes={handleResend}
              >
                <Button label="Re-send Invite" glyph="tick_circle" type="primary" color="green" isLoading={isResending} />
              </ConfirmDialog>

              <DeleteDialog
                title="Delete Partnership Invite?"
                message="Are you sure you want to delete this Partnership Invite? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const PartnershipInviteSentOverlay = withOverlayError(RootPartnershipInviteSentOverlay)
