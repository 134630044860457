import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  active: {
    label: 'active',
    color: 'green',
  },
  inactive: {
    label: 'inactive',
    color: 'orange',
  },
}

const ScheduledReportStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default ScheduledReportStatus
