import React from 'react'
import { css } from '@emotion/react'
import { tint, transparentize, lighten, darken } from 'polished'
import * as AspectRatio from '@radix-ui/react-aspect-ratio'
import clsx from 'clsx'
import isFinite from 'lodash/isFinite'

import { COLORS, SHADOW } from '../theme'
import { formatURL, isDefined } from '../utils/functions'

import Flex from './Flex'
import Glyph from './Glyph'
import Loader from './Loader'
import Markup from './Markup'

export const BannerMessage = (props: any) => {
  const {
    children,
    color = COLORS.blue,
    currentCount,
    enlargeEnabled = true,
    glyph,
    iframeURL,
    onDismissClick,
    onNextClick,
    onPrevClick,
    richText,
    title,
    totalCount,
    iframeWidth = 535,
    iframeHeight = 400,
  } = props

  const [isLarge, setIsLarge] = React.useState(props.isLarge || false)
  const isNavVisible = totalCount > 1 && onNextClick && onPrevClick

  const toggleEnlarge = () => {
    setIsLarge((c) => !c)
  }

  const commonClasses = clsx(isLarge && 'is-large')

  const handleBackdropClick = () => {
    setIsLarge(false)
    onDismissClick?.()
  }

  const iframeRatio = React.useMemo(() => {
    if (!isFinite(iframeWidth) || !isFinite(iframeHeight)) return 1

    return parseInt(iframeWidth) / parseInt(iframeHeight)
  }, [iframeWidth, iframeHeight])

  const style: any = React.useMemo(() => {
    if (!color) return null

    const bgColor = tint(0.9, color)
    const borderColor = tint(0.45, color)

    return {
      '--banner-background': bgColor,
      '--banner-border-color': lighten(0.2, color),
      '--banner-color-1': lighten(0.2, color),
      '--banner-color-2': lighten(0.1, color),
      '--banner-color-3': darken(0, color),
      '--banner-color-4': darken(0.05, color),
    }
  }, [color])

  return (
    <div css={STYLES.viewport} className={commonClasses}>
      <div style={style} css={[STYLES.root, borderAngle, {}]} className={commonClasses}>
        <header css={STYLES.header}>
          <Flex centerY nowrap css={STYLES.titleWrapper}>
            {glyph && <Glyph glyph={glyph} color={color} size="1.5rem" css={STYLES.glyph} />}
            {title && <div css={STYLES.title}>{title}</div>}
          </Flex>

          <div css={STYLES.actions}>
            {enlargeEnabled && (
              <button type="button" onClick={toggleEnlarge} css={STYLES.dismissButton}>
                <Glyph glyph={isLarge ? 'compress' : 'enlarge'} color={COLORS.text} size="0.8rem" />
              </button>
            )}

            <button type="button" onClick={onDismissClick} css={STYLES.dismissButton}>
              <Glyph glyph="cross" color={COLORS.text} size="0.8rem" />
            </button>
          </div>
        </header>

        <div data-test={`${props.isLarge ? 'app_message_center' : 'app_message_corner'}`} css={STYLES.main}>
          {children}

          {richText && <Markup css={STYLES.richText} value={richText} />}

          {iframeURL && (
            <AspectRatio.Root asChild ratio={iframeRatio}>
              <div css={STYLES.iframeWrapper}>
                <iframe
                  frameBorder="0"
                  border="0"
                  cellSpacing="0"
                  src={formatURL(iframeURL)}
                  css={STYLES.iframe}
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                />

                <Loader css={STYLES.iframeLoader} />
              </div>
            </AspectRatio.Root>
          )}
        </div>

        {isNavVisible && (
          <div css={STYLES.navButtons}>
            <button type="button" onClick={onPrevClick} css={STYLES.navButton}>
              ←
            </button>

            {isDefined(currentCount) && isDefined(totalCount) && (
              <div css={STYLES.navContent}>
                {currentCount} of {totalCount}
              </div>
            )}

            <button type="button" onClick={onNextClick} css={STYLES.navButton}>
              →
            </button>
          </div>
        )}
      </div>

      {isLarge && <div css={STYLES.backdrop} onClick={handleBackdropClick} />}
    </div>
  )
}

const STYLES: any = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    fontWeight: 500,
    boxShadow: SHADOW(2),
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    background: 'var(--banner-border-color)',
    paddingBottom: '1rem',

    '&.is-large': {
      width: '100%',
      maxWidth: 800,
      maxHeight: '80vh',
    },

    // '@media (max-width: 600px)': {
    //   gridTemplateColumns: '1fr',
    // },

    '&::before': {
      content: "''",
      position: 'absolute',
      zIndex: -2,
      left: '-50%',
      top: '-200%',
      width: '200%',
      height: '500%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '50% 50%, 50% 50%',
      backgroundPosition: '0 0, 100% 0, 100% 100%, 0 100%',
      backgroundImage: `linear-gradient(var(--banner-color-1), var(--banner-color-1)),
                         linear-gradient(var(--banner-color-2), var(--banner-color-2)),
                         linear-gradient(var(--banner-color-3), var(--banner-color-3)),
                         linear-gradient(var(--banner-color-4), var(--banner-color-4))`,
      animation: 'rotate 5s linear infinite',
    },

    '&::after': {
      content: "''",
      position: 'absolute',
      zIndex: -1,
      left: '4px',
      top: '4px',
      width: 'calc(100% - 8px)',
      height: 'calc(100% - 8px)',
      background: 'var(--banner-background)',
      borderRadius: '5px',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '1.25rem',
    paddingTop: 4,
    paddingRight: 4,
    marginBottom: '0.5rem',
    flex: '0 0 auto',
  },

  titleWrapper: {
    alignSelf: 'flex-end',
  },

  main: {
    display: 'grid',
    fontSize: '0.95rem',
    paddingRight: '2rem',
    overflowY: 'auto',
    padding: '1.25rem',
    paddingTop: 0,
    paddingBottom: 0,
    flex: '1 1 auto',
  },

  glyph: {
    marginRight: '0.5rem',
  },

  richText: {
    '.ProseMirror': {
      '& > :first-of-type': { marginTop: 0 },
      '& > :last-of-type': { marginBottom: 0 },
    },
  },

  navButtons: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    paddingLeft: '1rem',
    paddingTop: '0.5rem',
  },

  navContent: {
    margin: '0 0.25rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    flex: '0 0 auto',
  },

  navButton: {
    width: '1.75rem',
    height: '1.75rem',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 4,
    flex: '0 0 auto',

    '&:hover': {
      background: transparentize(0.8, COLORS.text),
    },
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginLeft: 'auto',

    '& > *:first-of-type': {
      borderBottomLeftRadius: 4,
    },

    '& > *:last-of-type': {},
  },

  dismissButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '2.25rem',
    minHeight: '2.25rem',
    alignSelf: 'stretch',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    flex: '0 0 auto',
    borderLeft: `1px solid ${COLORS.divider}`,
    borderBottom: `1px solid ${COLORS.divider}`,

    '&:hover': {
      background: transparentize(0.9, COLORS.text),
    },
  },

  viewport: {
    '--viewport-padding': '25px',

    position: 'fixed',
    bottom: 10,
    right: 10,

    display: 'flex',
    flexDirection: 'column',
    gap: '10px',

    width: 590,
    maxWidth: '100vw',
    maxHeight: '80vh',
    margin: 0,
    listStyle: 'none',
    zIndex: 2147483647,
    paddingLeft: 20,
    outline: 'none',

    '@media(min-height: 400px)': {
      maxHeight: '50vh',
    },

    '&.is-large': {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      padding: 20,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  },

  backdrop: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: COLORS.backdrop,
    position: 'fixed',
    zIndex: 0,
  },

  title: {
    fontWeight: 700,
    fontSize: '1.1rem',
  },

  description: {
    margin: 0,

    color: COLORS.textLight,
    fontWeight: 400,
  },

  iframeWrapper: {
    position: 'relative',

    iframe: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      width: '100%',
      height: '100%',
    },
  },

  iframeLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 0,
  },

  iframe: {
    width: '100%',
    borderRadius: 5,
    marginTop: '0.5rem',
    height: 320,
    maxHeight: '50vh',
    border: `1px solid ${COLORS.shadow}`,
  },
}

const borderAngle = css`
  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`
