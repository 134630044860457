import React from 'react'

import Button from '../../components/Button'
import Card from '../../components/Card'
import CardContent from '../../components/CardContent'
import CardHeader from '../../components/CardHeader'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Option from '../../components/Forms/Option'
import Select from '../../components/Forms/Select'
import State from '../../components/State'
import Textarea from '../../components/Forms/Textarea'

import Notifications from '../../modules/notifications'

import { FINANCIAL_STATUS, PAYMENT_METHODS_LIST } from '../../utils/constants'
import { useGet, useUpdate } from '../../hooks/useNewAPI'

const OrganizationFinancialPlanCard = ({ organization }: any) => {
  const [isEditable, setIsEditable] = React.useState(false)
  const form = React.useRef(null)

  const { data, isLoading }: any = useGet({
    name: ['organization', organization?.id, 'financial_plan'],
    url: `/organizations/${organization?.id}/financial_plan`,
  })

  const { mutateAsync: updateFinancialPlan, isLoading: isUpdating }: any = useUpdate({
    name: ['organization', organization?.id, 'financial_plan'],
    url: `/financial_plans/${data?.id}`,
  })

  const toggleEditable = () => {
    setIsEditable((editable) => !editable)
  }

  const save = async () => {
    try {
      await updateFinancialPlan(form.current.getFormValue())
      toggleEditable()

      Notifications.send('Data updated successfully', 'positive')
    } catch (errors) {
      console.debug(errors)
    }
  }

  const cancel = () => {
    if (form.current) form.current.resetForm()
    toggleEditable()
  }

  return (
    <Card>
      {isLoading ? (
        <State isLoading />
      ) : (
        <>
          <CardHeader className="!p-2">
            <Grid gap="1rem">
              <h3>
                <Flex gap="0.5rem" alignItems="center">
                  <span>Financial Plan Details</span>
                </Flex>
              </h3>

              <Form getForm={form} initialModel={data} isEditable={isEditable}>
                <FormSection maxWidth="100%">
                  <div className="grid gap-4 grid-cols-1 mq800:grid-cols-2 mq1024:grid-cols-1 mq1200:grid-cols-2 mq1500:grid-cols-3 mq1800:grid-cols-4">
                    <Select label="Financial Status" model="status" defaultValue="up_to_date">
                      {Object.entries(FINANCIAL_STATUS).map(([key, value]) => (
                        <Option key={key} label={value} value={key} />
                      ))}
                    </Select>

                    <Input label="Reports Data Filter" model="data_filter" description="This is an internal note, used in Reports" />

                    <Textarea useQuickText label="Notes" model="notes" />
                    <Textarea useQuickText label="Message" model="message" />

                    <MultiObjectSelector
                      isRelations={false}
                      model="payment_methods"
                      label="Accepted Payment Methods"
                      apiData={PAYMENT_METHODS_LIST}
                      showAvatars={false}
                      selectTitle={(data: any) => (
                        <>
                          <Glyph glyph={data.glyph} /> {data.name}
                        </>
                      )}
                      selectDescription={(data: any) => data.description}
                    />
                  </div>
                </FormSection>
              </Form>
            </Grid>
          </CardHeader>

          <CardContent>
            {isEditable ? (
              <Flex gap="0.5rem">
                <Button
                  size={200}
                  color="green"
                  type="primary"
                  glyph="check"
                  label="Save"
                  onClick={save}
                  flex="0 0 100px"
                  display="inline-flex"
                  isLoading={isUpdating}
                  isDisabled={isUpdating}
                />
                <Button
                  size={200}
                  glyph="cross"
                  type="default"
                  color="blue"
                  label="Cancel"
                  onClick={cancel}
                  flex="0 0 auto"
                  display="inline-flex"
                />
              </Flex>
            ) : (
              <Button size={200} type="primary" glyph="edit" label="Edit" onClick={toggleEditable} flex="0 0 100px" display="inline-flex" />
            )}
          </CardContent>
        </>
      )}
    </Card>
  )
}

export default OrganizationFinancialPlanCard
