import React from 'react'
import WebViewer from '@pdftron/pdfjs-express-viewer'

import AccordionAlert from '../../../components/AccordionAlert'
import Animated from '../../../components/Animated'
import Overlay from '../../../components/Overlay'
import Portal from '../../../components/Portal'
import Button from '../../../components/Button'
import Grid from '../../../components/Grid'
import Flex from '../../../components/Flex'
import Input from '../../../components/Forms/Input'
import useIsMounted from '../../../hooks/useIsMounted'
import { Text } from '../../../components/Typography'

const PDFJS_EXPRESS_OPTIONS = {
  licenseKey: process.env.BH_PDF_EXPRESS_KEY,
  path: '/webviewer/lib',
}

const PDF_OPTIONS = {
  customHeaders: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Request-Method': '*',
    'Access-Control-Allow-Headers': '*',
  },
  extension: 'pdf',
}

const Renderer = ({ url }: any) => {
  const viewer = React.useRef(null)
  const [pdfInstance, setPdfInstance] = React.useState(null)

  React.useEffect(() => {
    WebViewer(PDFJS_EXPRESS_OPTIONS, viewer.current).then((instance: any) => {
      setPdfInstance(instance)
    })
  }, [])

  React.useEffect(() => {
    if (pdfInstance && url) pdfInstance.loadDocument(url, PDF_OPTIONS)
  }, [pdfInstance, url])

  return <div className="webviewer w-full h-full" ref={viewer}></div>
}

const PDFRender = ({ url }: any) => {
  const [finalURL, setFinalURL] = React.useState(url)

  const [marginLeft, setMarginLeft] = React.useState(0)
  const [marginTop, setMarginTop] = React.useState(0)

  const applyMargins = () => {
    const newURL = url + `&margin_left=${marginLeft}&margin_top=${marginTop}`
    setFinalURL(newURL)
  }

  return (
    <>
      <Overlay.Header title="Print PDF Claim" glyph="behave_health" />

      <AccordionAlert
        isOpen={false}
        glyph="remove_margins"
        type="default"
        header="Margins Editor"
        css={{
          margin: '0.5rem',
        }}
      >
        <Grid gap="0.5rem">
          <Flex gap="1rem" alignItems="flex-end">
            <Input
              horizontal
              isEditable
              model="margin_left"
              label="Margin Left"
              type="number"
              step="1"
              min="0"
              suffix="points"
              defaultValue={0}
              value={marginLeft}
              onUpdate={({ value }: any) => {
                setMarginLeft(value)
              }}
            />
            <Input
              horizontal
              isEditable
              model="margin_top"
              label="Margin Top"
              type="number"
              step="1"
              min="0"
              suffix="points"
              defaultValue={0}
              value={marginTop}
              onUpdate={({ value }: any) => {
                setMarginTop(value)
              }}
            />
            <Button type="primary" label="Apply Print Margins" onClick={applyMargins} />
          </Flex>
          <Text>Each point represents 1 / 72 of an inch</Text>
        </Grid>
      </AccordionAlert>

      <Overlay.Content style={{ position: 'relative' }}>
        <Flex centerX centerY className="!h-full">
          <Renderer url={finalURL} />
        </Flex>
      </Overlay.Content>
    </>
  )
}

const PrintQuickView = ({ record, url, title, children, width }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const isMounted = useIsMounted()
  const givenTitle = title || record?.title

  const open = async () => {
    setIsOpen(true)
    return
  }

  const close = () => {
    if (!isMounted) return
    setIsOpen(false)
  }

  return (
    <>
      <div onClick={open}>{children}</div>

      <Portal type="iframe">
        <Animated show={isOpen} animation="animation">
          <Overlay fullheight showBackdrop stopPropagation closeOnBackdrop position="center" onClose={close} maxWidth={width}>
            <PDFRender url={url} givenTitle={givenTitle} record={record} />
          </Overlay>
        </Animated>
      </Portal>
    </>
  )
}

PrintQuickView.defaultProps = {
  width: 100,
}

export default PrintQuickView
