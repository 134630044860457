import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Alert, Button, Card, Grid, Page } from '@behavehealth/components'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { InsuranceCodeOverlay } from '@behavehealth/constructs/RCM/InsuranceCodeOverlay'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const pageConfig = {
  title: 'Insurance Codes',
  plan: 'basic',
  feature: 'insurance',
}

const CODE_TYPES = {
  cpt: 'CPT',
  hcpc_mod: 'HCPC/MOD',
  hcpcs: 'HCPCS',
}

const InsuranceCodes = () => {
  return (
    <>
      <InsuranceCodesIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<InsuranceCodeOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const InsuranceCodesIndex: React.FC = () => {
  const tableProps: any = useDataTable({
    name: ['insurance_new_codes'],
    endpoint: `/insurance_new_codes`,
    localStorageKey: 'insurance_new_codes_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Service Name',
        model: 'service_name',
        width: 300,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} to={data.id} value={value} />,
      },
      {
        title: 'Description',
        model: 'description',
        width: 300,
      },
      {
        title: 'Procedure Code',
        model: 'procedure_code',
        width: 130,
      },
      {
        title: 'Revenue Code',
        model: 'revenue_code',
        width: 130,
      },
      {
        title: 'Unit Type',
        model: 'unit',
        width: 130,
        formatValue: ({ value }: any) => {
          return value ? value.replace('_', ' ') : null
        },
      },
      {
        title: 'Modifier Codes',
        model: 'modifier_codes',
        width: 160,
        formatValue: ({ value }: any) => {
          return value ? value.map((o: any) => o?.code).join(', ') : null
        },
      },
      {
        title: 'Notes',
        model: 'notes',
        width: 180,
      },
      {
        title: 'Code Type',
        model: 'code_type',
        width: 140,
        formatValue: ({ value }: any) => {
          return value ? CODE_TYPES[value] : null
        },
      },
    ],
    [],
  )

  return (
    <Page
      {...pageConfig}
      actions={
        <Button as={Link} label="Add Insurance Code" type="primary" glyph="add" link="new" permission="settings.insurance_codes.create" />
      }
    >
      <Grid gap="1rem">
        <Alert contrast glyph="info">
          This list is used when defining the Fee Schedule for your payers
        </Alert>

        <Card>
          <DataTable
            {...tableProps}
            title="Insurance Codes"
            icon="insurance"
            columns={columns}
            filtersConfig={FILTERS_CONFIG}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'settings.out_of_network_services.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

const FILTERS_CONFIG = {
  service_name: {
    label: 'Service Name',
    type: 'string',
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  procedure_code: {
    label: 'Procedure Code',
    type: 'string',
  },
  revenue_code: {
    label: 'Revenue Code',
    type: 'string',
  },
  unit: {
    label: 'Unit Type',
    type: 'string',
  },
  modifier_codes: {
    label: 'Modifier Codes',
    type: 'string',
  },
  notes: {
    label: 'Notes',
    type: 'string',
  },
  code_type: {
    label: 'Code Type',
    type: 'multi_select',
    options: [
      {
        label: 'CPT',
        value: 'cpt',
      },
      {
        label: 'HCPC/MOD',
        value: 'hcpc_mod',
      },
      {
        label: 'HCPCS',
        value: 'hcpcs',
      },
    ],
  },
}

export default withPageError(withMarketing(InsuranceCodes, pageConfig))
