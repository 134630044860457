import React from 'react'

export const usePortal = (type) => {
  const [portal, setPortal] = React.useState(null)

  React.useEffect(() => {
    setPortal(document.getElementById(`portal-${type}`))
  }, [type])

  return portal
}

export default usePortal
