import React from 'react'
import size from 'lodash/size'
import { Global } from '@emotion/react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  Button,
  PageLayout,
  PageSection,
  State,
  SummonOverlay,
  Card,
  Grid,
  Divider,
  Flex,
  Logo,
  PortalsContainer,
  Markup,
} from '@behavehealth/components'

import {
  FeaturesComparisonOverlay,
  FeaturesOverlay,
  ProductCard,
  ProductsGrid,
  SubscriptionPaymentOverlay,
  PurchasePaymentOverlay,
} from '@behavehealth/components/Billing'

import { initialize, setup } from '../modules/init'
import { apiGet } from '@behavehealth/modules/api'

const Proposal = () => {
  const tenant = useSelector((state) => state.me.tenant)
  const history = useHistory()

  const plansLoading = useSelector((state) => state.data.plans?.loading)
  const addonsLoading = useSelector((state) => state.data.addons?.loading)
  const servicesLoading = useSelector((state) => state.data.services?.loading)

  const plans = useSelector((state) => state.data.plans?.data)
  const addons = useSelector((state) => state.data.addons?.data)
  const services = useSelector((state) => state.data.services?.data)

  const isPlansEmpty = size(plans) <= 0
  const isAddonsEmpty = size(addons) <= 0
  const isServicesEmpty = size(services) <= 0

  const onSuccess = () => {
    history.push('/dashboard')
  }

  const PLANS = () => {
    if (plansLoading) return <State isLoading={true} />
    if (isPlansEmpty) return <State isEmpty={true} />
    if (!plans) return null

    return Object.values(plans).map((product: any) => (
      <ProductCard
        product={product}
        showFeatures={false}
        actions={
          <>
            <SummonOverlay overlay={<SubscriptionPaymentOverlay product={product} onSuccess={onSuccess} />}>
              <Button rounded label="Subscribe" color="green" />
            </SummonOverlay>

            <SummonOverlay overlay={<FeaturesComparisonOverlay plans={plans} />}>
              <Button rounded type="primary" glyph="info" label="View All Features" />
            </SummonOverlay>
          </>
        }
      />
    ))
  }

  const ADDONS = () => {
    if (addonsLoading) return <State isLoading={true} />
    if (!addons) return null

    return Object.values(addons).map((addon) => (
      <ProductCard
        key={addon.id}
        product={addon}
        showFeatures={false}
        actions={
          <>
            <SummonOverlay overlay={<FeaturesOverlay product={addon} />}>
              <Button rounded type="primary" glyph="info" label="Learn More" />
            </SummonOverlay>
          </>
        }
      />
    ))
  }

  const SERVICES = () => {
    if (servicesLoading) return <State isLoading={true} />
    if (!services) return null

    return Object.values(services).map((service) => (
      <ProductCard
        key={service.id}
        product={service}
        showFeatures={false}
        actions={
          <>
            <SummonOverlay overlay={<PurchasePaymentOverlay product={service} />}>
              <Button rounded label="Purchase" color="green" />
            </SummonOverlay>

            <SummonOverlay overlay={<FeaturesOverlay product={service} />}>
              <Button rounded type="primary" glyph="info" label="Learn More" />
            </SummonOverlay>
          </>
        }
      />
    ))
  }

  React.useEffect(() => {
    const init = async () => {
      await initialize()
      await setup()

      await apiGet({ name: 'plans', url: `/products?status=in_use,active&category=plan` })
      await apiGet({ name: 'addons', url: `/products?status=in_use,active&category=addon` })
      await apiGet({ name: 'services', url: `/products?status=in_use,active&category=service` })
    }

    init()
  }, [])

  return (
    <PageLayout className="max-w-[1200px] p-4 my-0 mx-auto">
      <Global styles={globalStyles} />

      <Grid gap="5rem">
        <Flex justifyContent="center" className="!my-8 !mx-0">
          <Logo logo="ehr" />
        </Flex>

        {tenant?.metadata?.plans_notes && (
          <PageSection>
            <PageSection.Header>
              <PageSection.Title title="Notes" />
            </PageSection.Header>

            {tenant?.metadata?.plans_notes && (
              <PageSection.Content>
                <Card className="!py-0 !px-4">
                  <Markup value={tenant?.metadata?.plans_notes} />
                </Card>
              </PageSection.Content>
            )}
          </PageSection>
        )}

        {/* PLANS */}
        <PageSection>
          <PageSection.Header>
            <PageSection.Title title="Main Plans" />
            <PageSection.Subtitle subtitle="Choose one of the main plans to get started" />
          </PageSection.Header>

          <PageSection.Content>
            {isPlansEmpty ? (
              <Card>
                <State
                  isEmpty
                  icon="certificate"
                  title="Main Plans"
                  emptyDescription="There are no Main Plans to choose from. Please contact us to set your plans up."
                  emptyActions={<Button glyph="chat" label="Contact Us" onClick={() => window.Beacon('open')} />}
                />
              </Card>
            ) : (
              <ProductsGrid>{PLANS()}</ProductsGrid>
            )}
          </PageSection.Content>
        </PageSection>

        <Divider css={styles.divider} />

        {/* ADDONS */}
        <PageSection>
          <PageSection.Header>
            {/* <PageSection.Title title="Other Add-On’s" /> */}
            <PageSection.Title title="Add-On’s" />
            <PageSection.Subtitle subtitle="Choose any number of add-on's to fit your needs" />
          </PageSection.Header>

          <PageSection.Content>
            {isAddonsEmpty ? (
              <Card>
                <State
                  isEmpty
                  icon="certificate"
                  title="Add-On's"
                  emptyDescription="There are no Add-On's to choose from. Please contact us to set your addons up."
                  emptyActions={<Button glyph="chat" label="Contact Us" onClick={() => window.Beacon('open')} />}
                />
              </Card>
            ) : (
              <ProductsGrid>{ADDONS()}</ProductsGrid>
            )}
          </PageSection.Content>
        </PageSection>

        <Divider css={styles.divider} />

        {/* SERVICES */}
        {!isServicesEmpty && (
          <PageSection>
            <PageSection.Header>
              {/* <PageSection.Title title="Other Add-On’s" /> */}
              <PageSection.Title title="Services" />
              <PageSection.Subtitle subtitle="Choose any number of services to fit your needs" />
            </PageSection.Header>

            <PageSection.Content>
              {isServicesEmpty ? (
                <Card>
                  <State
                    isEmpty
                    icon="certificate"
                    title="Services"
                    emptyDescription="There are no Services to choose from. Please contact us to set your services up."
                    emptyActions={<Button glyph="chat" label="Contact Us" onClick={() => window.Beacon('open')} />}
                  />
                </Card>
              ) : (
                <ProductsGrid>{SERVICES()}</ProductsGrid>
              )}
            </PageSection.Content>
          </PageSection>
        )}

        <Grid gap="1rem" centerX>
          <h3>Have questions?</h3>
          <Grid gap="1rem">
            <a href="tel:(650) 338-4113">Call us at (650) 338-4113</a>
            <Button glyph="chat" type="primary" label="Message us" color="green" onClick={() => window.Beacon('open')} />
          </Grid>
          <Button type="link" label="Log Out" color="red" link="/logout" className="mt-[100px]" />
        </Grid>
      </Grid>

      <PortalsContainer />
    </PageLayout>
  )
}

const styles = {
  divider: {
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
  },
}

const globalStyles = {
  'html, body': {
    height: 'auto',
    overflow: 'auto',
  },
}

export default Proposal
