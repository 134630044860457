import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useParams, useLocation } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useGet, useDelete, invalidateQueries } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Button, Card, HelpTagIframe, Page, Grid } from '@behavehealth/components'
import { ExcelReportOverlay } from '@behavehealth/constructs/ExcelReports/ExcelReportOverlay'
import { ScheduledExcelReportOverlay } from '@behavehealth/constructs/ExcelReports/ScheduledExcelReportOverlay'
import ReportsTable from '@behavehealth/components/Tables/ReportsTable'
import ScheduledReportsTable from '@behavehealth/components/Tables/ScheduledReportsTable'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'

const CHECK_GENERATING = 5000

const Reports = () => {
  const actions = (
    <>
      <SummonOverlay overlay={<ScheduledExcelReportOverlay dataID="new" />}>
        <Button label="Schedule Report" type="default" glyph="time" permission="generated_reports.create" size={200} />
      </SummonOverlay>

      <Button
        as={Link}
        label="Request New Report"
        type="primary"
        glyph="add"
        link="generated/new"
        permission="generated_reports.create"
        size={200}
      />
    </>
  )

  return (
    <Page feature="reports" title="Excel Reports" breakpoint="0" actions={actions} help={<HelpTagIframe id="reports" />}>
      <Tabs>
        <Tabs.List css={STYLES.tabsList}>
          <Tabs.Item as={NavLink} label="Generated" to="generated" />
          <Tabs.Item as={NavLink} label="Scheduled" to="scheduled" />
        </Tabs.List>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to="generated" replace />} />

        <Route path="generated/*" element={<GeneratedReports />} />
        <Route path="scheduled/*" element={<ScheduledReports />} />
      </Routes>

      <AnimatedRoutes>
        <Route path="generated/:id" element={<ExcelReportOverlay useV6Router />} />
        <Route path="scheduled/:id" element={<ScheduledExcelReportOverlay useV6Router />} />
      </AnimatedRoutes>
    </Page>
  )
}

const GeneratedReports = () => {
  const timer = React.useRef(null)
  const [filters, setFilters] = React.useState({})
  const [hasGeneratingReports, setHasGeneratingReports] = React.useState(false)

  const { data, isLoading }: any = useGet({
    name: ['reports', 'excel-reports', { filters }],
    url: `/reports`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const { mutateAsync: deleteRecords } = useDelete({
    name: ['reports', 'excel-reports'],
    url: '/reports',
    invalidate: ['reports', 'excel-reports'],
  })

  React.useEffect(() => {
    if (!data) return

    let isGenerating = false

    for (const key in data) {
      if (data[key].status === 'processing') {
        isGenerating = true
        break
      }
    }

    setHasGeneratingReports(isGenerating)
  }, [data])

  React.useEffect(() => {
    if (hasGeneratingReports) {
      timer.current = setTimeout(() => invalidateQueries(['reports', 'excel-reports']), CHECK_GENERATING)
    } else if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }

    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [hasGeneratingReports])

  return (
    <Grid>
      <Card>
        <ReportsTable
          data={data}
          isLoading={isLoading}
          mainLinkAs={NavLink}
          to={(id: string) => id}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'generated_reports.delete',
              action: async ({ ids }: any) => {
                await deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Card>
    </Grid>
  )
}

const ScheduledReports = () => {
  const [filters, setFilters] = React.useState({})

  const { data, isLoading }: any = useGet({
    name: ['reports', 'scheduled-reports', { filters }],
    url: `/scheduled_reports`,
    params: {
      filters: btoa(JSON.stringify({ filters })),
    },
  })

  const { mutateAsync: deleteRecords } = useDelete({
    name: ['reports', 'scheduled-reports'],
    url: '/scheduled_reports',
    invalidate: ['reports', 'scheduled-reports'],
  })

  return (
    <Grid>
      <Card>
        <ScheduledReportsTable
          data={data}
          isLoading={isLoading}
          mainLinkAs={NavLink}
          to={(id: string) => id}
          batchActionsConfig={[
            {
              type: 'delete',
              action: async ({ ids }: any) => {
                await deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Card>
    </Grid>
  )
}

const STYLES = {
  tabsList: {
    margin: '-1rem 0 1rem',
  },
}

export default withPageError(Reports)
