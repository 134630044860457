import React from 'react'

import { COLORS } from '../theme'

import GridTableRow from './GridTableRow'

const GridTableHeader: React.FC = ({ children, className }: any) => {
  return (
    <GridTableRow css={styles} className={className}>
      {children}
    </GridTableRow>
  )
}

const styles = {
  fontWeight: 600,
  borderBottom: `2px solid ${COLORS.divider}`,
  boxShadow: `0 3px 0 ${COLORS.shadow}`,
  background: `${COLORS.white} !important`,

  '& > *, &:hover > *': {
    background: `${COLORS.white}`,
  },
}

export default GridTableHeader
