import React from 'react'
import produce from 'immer'
import size from 'lodash/size'
import compact from 'lodash/compact'

import { COLORS } from '../../../theme'
import { useGet } from '../../../hooks/useNewAPI'
import Glyph from '../../../components/Glyph'

import { FilterDropdown } from './FilterDropdown'
import { FilterDropdownGroup } from './FilterDropdownGroup'
import { FilterDropdownValue } from './FilterDropdownValue'
import { PopoverItem } from './PopoverItem'

const CONDITIONS: any = {
  in: 'includes',
  not_in: 'not includes',
}

const CONDITION_GLYPHS: any = {
  in: 'between',
  not_in: 'not_equal',
}

export const GlobalTagsFilter = (props: any) => {
  const { config, onUpdate, filter, onClear } = props

  const initialCondition = Object.keys(CONDITIONS).includes(filter?.condition) ? filter?.condition : 'in'

  const [tagIds, setTagsIds]: any = React.useState(filter?.value || [])
  const [condition, setCondition] = React.useState(initialCondition)
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  const { data, isLoading }: any = useGet({
    name: compact(['global-tag-groups', config?.identifier]),
    url: `/global_tag_groups`,
    ...(config?.identifier && {
      params: {
        identifier: config?.identifier,
      },
    }),
  })

  const isEmpty = size(data) === 0

  const activeTags = React.useMemo(() => {
    const result: any = []
    const tagsMap: any = {}

    if (size(filter?.value) === 0 || size(data) === 0) return result

    for (const tagGroup of data) {
      if (!tagGroup.global_tags) continue

      for (const tag of tagGroup.global_tags) {
        tagsMap[tag.id] = tag
      }
    }

    for (const id of filter.value) {
      if (!tagsMap?.[id]) continue

      result.push(tagsMap[id])
    }

    return result
  }, [data, filter])

  const hasActiveTags = size(activeTags) >= 1

  React.useEffect(() => {
    if (onUpdate) onUpdate({ condition, value: tagIds })
  }, [condition, tagIds])

  return (
    <>
      <FilterDropdown label={CONDITIONS[condition]} open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        {Object.keys(CONDITIONS).map((key) => (
          <PopoverItem
            key={key}
            title={CONDITIONS[key]}
            isActive={condition === key}
            onClick={() => {
              setCondition(key)
              setIsDropdownOpen(false)
            }}
            graphic={<Glyph glyph={CONDITION_GLYPHS[key]} color={COLORS.textMuted} size={16} />}
          />
        ))}
      </FilterDropdown>

      <FilterDropdown
        isEmpty={isEmpty}
        isLoading={isLoading}
        activeValues={
          hasActiveTags &&
          activeTags?.map?.((tag) => (
            <FilterDropdownValue value={tag.name} graphic={<Glyph glyph={tag.glyph} size={14} color={tag.color} />} />
          ))
        }
      >
        {!isEmpty &&
          data.map((tagGroup: any) => {
            if (size(tagGroup?.global_tags) === 0) return null

            return (
              <FilterDropdownGroup key={tagGroup.id} label={tagGroup.name}>
                {tagGroup.global_tags.map((tag: any) => (
                  <PopoverItem
                    key={tag.id}
                    title={tag.name}
                    graphic={<Glyph glyph={tag.glyph} size={14} color={tag.color} />}
                    isActive={tagIds.includes(tag.id)}
                    onClick={() => {
                      setTagsIds((currentValue: any = {}) => {
                        return produce(currentValue, (draft: any) => {
                          if (draft.includes(tag.id)) {
                            const index = draft.indexOf(tag.id)
                            draft.splice(index, 1)
                            return
                          }

                          draft.push(tag.id)
                        })
                      })
                    }}
                  />
                ))}
              </FilterDropdownGroup>
            )
          })}
      </FilterDropdown>
    </>
  )
}
