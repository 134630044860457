import React from 'react'

import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import ContextShow from '../../../components/Forms/ContextShow'
import Diagnoses from '../../../components/SmartContent/Diagnoses'
import Input from '../../../components/Forms/Input'
import Medications from '../../../components/SmartContent/Medications'
import PastMedications from '../../../components/Overlays/pages/DataForms/common/PastMedications'
import PhoneInput from '../../../components/Forms/PhoneInput'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Textarea from '../../../components/Forms/Textarea'
import YesNoRadioGroup from '../../../components/Forms/elements/YesNoRadioGroup'

import {
  CARE_COORDINATION_FIELDS,
  MENTAL_STATUS_EXAM_FIELDS,
  PAST_MEDICAL_HISTORY_FIELDS,
  SUBSTANCE_USE_HISTORY,
  SUICIDE_SAFETY_RISK_FIELDS,
  TREATMENT_HISTORY_FIELDS,
} from './common'

export const PSYCHIATRIC_ASSESSMENT_SECTIONS = [
  {
    title: 'Suicide/Safety Risk',
    model: 'suicide_safety_risk',
    fields: SUICIDE_SAFETY_RISK_FIELDS,
  },
  {
    title: 'Current Psychiatric Medications',
    model: 'medications',
    fields: [
      {
        label: 'Medications List',
        model: 'medications_list',
        component: ({ isEditable, client }: any) => (
          <Medications
            model="data.medications.meds"
            isEditable={isEditable}
            client={client}
            icon="medications"
            title="Medications"
            emptyDescription="No medications added yet"
          />
        ),
      },
      {
        label: 'Does the client feel that their current medications are effective?',
        model: 'are_current_meds_effective',
        component: () => (
          <YesNoRadioGroup
            label="Does the client feel that their current medications are effective?"
            model="data.medications.are_current_meds_effective"
          />
        ),
      },
    ],
  },
  {
    title: 'Past Medications',
    model: 'past_medications',
    fields: [
      {
        label: 'Past Medications',
        model: 'past_medications',
        component: () => <PastMedications />,
      },
    ],
  },
  {
    title: 'Medical History',
    model: 'past_medical_history',
    fields: PAST_MEDICAL_HISTORY_FIELDS,
  },
  {
    title: 'Psychiatric History',
    model: 'psychiatric_history',
    fields: [
      {
        label: 'Has client been hospitalized for psychiatric reasons?',
        model: 'has_been_hospitalized',
        component: () => (
          <>
            <RadioGroup
              label="Has client been hospitalized for psychiatric reasons?"
              model="data.psychiatric_history.has_been_hospitalized"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.psychiatric_history.has_been_hospitalized" is={true}>
              <Textarea
                useQuickText
                label="Please explain (include dates, location and length of stay)"
                model="data.psychiatric_history.has_been_hospitalized_extra"
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Does client have a current therapist or counselor?',
        model: 'has_therapist_or_counselor',
        component: () => (
          <>
            <RadioGroup
              label="Does client have a current therapist or counselor?"
              model="data.psychiatric_history.has_therapist_or_counselor"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.psychiatric_history.has_therapist_or_counselor" is={true}>
              <Input label="Therapist or Counselor Name" model="data.psychiatric_history.therapist_counselor.name" />
              <PhoneInput label="Therapist or Counselor Phone" model="data.psychiatric_history.therapist_counselor.phone_no" />

              <RadioGroup
                label="Will care be coordinated with this provider?"
                model="data.psychiatric_history.therapist_counselor.will_care_coordinate"
                layout="horizontal-dense"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Substance Use History',
    model: 'substance_use_history',
    fields: SUBSTANCE_USE_HISTORY,
  },
  {
    title: 'Treatment History',
    model: 'treatment_history',
    fields: TREATMENT_HISTORY_FIELDS,
  },
  {
    title: 'Mental Status Exam',
    model: 'mental_status_exam',
    fields: MENTAL_STATUS_EXAM_FIELDS,
  },
  {
    title: 'Care Coordination',
    model: 'care_coordination',
    fields: CARE_COORDINATION_FIELDS,
  },
  {
    title: 'Diagnoses',
    model: 'diagnoses',
    fields: [
      {
        label: 'Diagnoses',
        model: 'diagnoses',
        component: ({ client, isEditable }: any) => (
          <Diagnoses
            model="data.diagnoses.diagnoses"
            isEditable={isEditable}
            client={client}
            icon="diagnosis"
            title="Diagnoses"
            emptyDescription="No diagnoses added yet"
          />
        ),
      },
    ],
  },
  {
    title: 'Summary',
    model: 'summary',
    fields: [
      {
        label: 'Strengths & Assets',
        model: 'strengths_assets',
        component: () => (
          <>
            <CheckboxGroup label="Strengths & Assets" layout="vertical-dense">
              <Checkbox label="Physical Health" model="data.summary.strengths_assets.physical_health" />
              <Checkbox label="Family Support" model="data.summary.strengths_assets.family_support" />
              <Checkbox label="Cooperative" model="data.summary.strengths_assets.cooperative" />
              <Checkbox label="Communication Skils" model="data.summary.strengths_assets.communication_skils" />
              <Checkbox label="Capacity to perform ADL's" model="data.summary.strengths_assets.capacity_to_perform_adl_s" />
              <Checkbox label="Other" model="data.summary.strengths_assets.other" />
            </CheckboxGroup>

            <ContextShow when="data.summary.strengths_assets.other" is={true}>
              <Textarea useQuickText label="Other:" model="data.summary.strengths_assets.other_extra" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Liabilities & Barriers to Recovery',
        model: 'liabilities_barriers_to_recovery',
        component: () => (
          <Textarea useQuickText label="Liabilities & Barriers to Recovery" model="data.summary.liabilities_barriers_to_recovery" />
        ),
      },
      {
        label: 'Interpretation',
        model: 'interpretation',
        component: () => <Textarea useQuickText label="Interpretation" model="data.summary.interpretation" />,
      },
      {
        label: 'Justification for Treatment',
        model: 'justification_for_treatment',
        component: () => (
          <>
            <CheckboxGroup label="Justification for Treatment" layout="vertical-dense">
              <Checkbox
                label="Acute history of psychoactive substance abuse"
                model="data.summary.justification_for_treatment.acute_psychoactive_substance_abuse"
              />
              <Checkbox
                label="Acute history of inability to maintain long-term abstinence from the use of psychoactive substances"
                model="data.summary.justification_for_treatment.acute_inability_for_long_term_abstinence_from_psychoactive_substances"
              />
              <Checkbox
                label="Danger to self or others with need for controlled environment"
                model="data.summary.justification_for_treatment.danger_to_self_or_others"
              />
              <Checkbox label="Other:" model="data.summary.justification_for_treatment.other" />
            </CheckboxGroup>

            <ContextShow when="data.summary.justification_for_treatment.other" is={true}>
              <Textarea useQuickText label="Other:" model="data.summary.justification_for_treatment.other_extra" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Additional Relevant Information',
        model: 'additional_information',
        component: () => <Textarea useQuickText label="Additional Relevant Information" model="data.summary.additional_information" />,
      },
      {
        label: 'Recommendation - Appropriate Levels of Care',
        model: 'recommended_level_of_care',
        component: () => (
          <CheckboxGroup label="Recommendation - Appropriate Levels of Care" layout="vertical-dense">
            <Checkbox label="Detoxification (DTX)" model="data.summary.recommended_level_of_care.dtx" />
            <Checkbox label="24 hour monitoring in Residential (RTC) Enviroment" model="data.summary.recommended_level_of_care.rtc" />
            <Checkbox label="Partial Hospitalization (PHP) with Supportive Housing" model="data.summary.recommended_level_of_care.php" />
            <Checkbox label="Intensive Outpatient (IOP)" model="data.summary.recommended_level_of_care.iop" />
            <Checkbox label="Outpatient (OP)" model="data.summary.recommended_level_of_care.op" />
          </CheckboxGroup>
        ),
      },
      {
        label: 'Recommendation - Appropriate Treatment Services',
        model: 'recommended_treatment_services',
        component: () => (
          <>
            <CheckboxGroup label="Recommendation - Appropriate Treatment Services" layout="vertical-dense">
              <Checkbox label="Medication Management" model="data.summary.recommended_treatment_services.medication_management" />
              <Checkbox
                label="Health and Physical Evaluation"
                model="data.summary.recommended_treatment_services.health_and_physical_evaluation"
              />
              <Checkbox label="Individual Psychotherapy" model="data.summary.recommended_treatment_services.individual_psychotherapy" />
              <Checkbox label="Group Psychotherapy" model="data.summary.recommended_treatment_services.group_psychotherapy" />
              <Checkbox label="Family Psychotherapy" model="data.summary.recommended_treatment_services.family_psychotherapy" />
              <Checkbox label="Psychoeducational Groups" model="data.summary.recommended_treatment_services.psychoeducational_groups" />
              <Checkbox label="Case Management" model="data.summary.recommended_treatment_services.case_management" />
              <Checkbox label="Aftercare Plan" model="data.summary.recommended_treatment_services.aftercare_plan" />
              <Checkbox label="Other:" model="data.summary.recommended_treatment_services.other" />
            </CheckboxGroup>

            <ContextShow when="data.summary.recommended_treatment_services.other" is={true}>
              <Textarea useQuickText label="Other:" model="data.summary.recommended_treatment_services.other_extra" />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Additional Recommendations',
        model: 'additional_recommendations',
        component: () => <Textarea useQuickText label="Additional Recommendations" model="data.summary.additional_recommendations" />,
      },
      {
        label:
          'Client has been fully informed by the provider regarding the risks and benefits of the treatment offered by the facility. The client has expressed to the provider an understanding of the explanations of the provider',
        model: 'has_client_been_informed_of_risks_and_benefits',
        component: () => (
          <YesNoRadioGroup
            label="Client has been fully informed by the provider regarding the risks and benefits of the treatment offered by the facility. The client has expressed to the provider an understanding of the explanations of the provider."
            model="data.summary.has_client_been_informed_of_risks_and_benefits"
          />
        ),
      },
      {
        label: 'Was greater than 50% of appointment spent on counseling and coordination of care?',
        model: 'did_spend_half_or_more_on_counseling_and_coordination_of_care',
        component: () => (
          <YesNoRadioGroup
            label="Was greater than 50% of appointment spent on counseling and coordination of care?"
            model="data.summary.did_spend_half_or_more_on_counseling_and_coordination_of_care"
          />
        ),
      },
    ],
  },
]
