import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { useAPI } from '@behavehealth/hooks'
import withMarketing from '@behavehealth/hocs/withMarketing'
import useStore from '@behavehealth/modules/store'

import { Button, Card, Grid, Page } from '@behavehealth/components'
import { AllergiesTable } from '@behavehealth/components/Tables'

import { EPrescribingAccess } from '@behavehealth/constructs/ERX/EPrescribingAccess'

type Props = {
  canCreate: boolean
}

const pageConfig = {
  feature: 'allergies',
  title: 'Allergies',
}

const Allergies: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()

  const tenant = useStore((state) => state.tenant)
  const client = useSelector((state) => state.data.clients?.data[match.params?.resource_id])
  const data = useSelector((state) => state.data.allergies?.data)
  const loading = useSelector((state) => state.data.allergies?.loading)

  useAPI('allergies', `/residents/${match.params?.resource_id}/allergies`)

  const usesERX = tenant?.feature_flags?.erx === true
  const isEmpty = size(data) === 0

  const action = (
    <Button
      label="Add Allergy"
      glyph="add"
      type="primary"
      link={{
        pathname: `${match.url}/new`,
        parent: match,
      }}
    />
  )

  return (
    <Page feature="allergies" actions={!isEmpty && canCreate && !usesERX && action}>
      <Grid gap="1rem">
        <EPrescribingAccess tenant={tenant} client={client} />

        <Card>
          <AllergiesTable
            data={data}
            isLoading={isEmpty && loading}
            emptyActions={canCreate && !usesERX && action}
            localStorageKey="clients_allergies"
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withMarketing(Allergies, pageConfig)
