import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'
import { useSettings } from '../../../hooks/useSettings'
import { usDateTime } from '../../../utils/functions'
import DataList from '../../../components/DataList'

import { RecordTooltip } from '../components/RecordTooltip'

export const MedicationLog = ({ data, client }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <RecordTooltip
      title={data.name || 'Medication Log'}
      icon="med_pass"
      color={tint(0.4, COLORS.red)}
      startDate={data.administered_at}
      recordLink={`/medications/logs/${data.id}`}
      client={client}
    >
      <DataList isCompact withPadding labelWidth={140}>
        <DataList.Item label="Medication" value={data.med?.name} />
        <DataList.Item label="Administered At" value={usDateTime(data.administered_at, timezone)} />
        <DataList.Item label="Dosage" value={data.dosage} />
        <DataList.Item label="Quantity" value={data.unit} />
        <DataList.Item label="Quantity Remaining" value={data.remaining_units} />
      </DataList>
    </RecordTooltip>
  )
}
