import React from 'react'

import Button from '../../../components/Button'
import Status from '../../../components/Status'
import Text from '../../../components/Typography/Text'
import SummonOverlay from '../../../components/SummonOverlay'

import { ConfidentialityOverlay } from './ConfidentialityOverlay'
import { NDAOverlay } from './NDAOverlay'
import { ReviewSection } from './ReviewSection'
import { QuoteIframeButton } from '../../QuoteElements/QuoteIframeButton'

import { useCreate } from '../../../hooks/useNewAPI'
import { usDateTime } from '../../../utils/functions'

export const ConfidentialityNDA = (props: any) => {
  const { data, isPublic, didAgreeConfidentiality, didAgreeNDA, setDidAgreeConfidentiality, setDidAgreeNDA, isOpen } = props
  const { use_confidentiality_section, use_nda_section, external_id } = data

  const timezone = data?.timezone || data?.facility?.timezone

  const { mutateAsync: agreeToConfidentialityNotice, isLoading: isAgreeingToConfidentialityNotice }: any = useCreate({
    name: ['agree-to-confidentiality-notice'],
    url: isPublic
      ? `/apps/quotes/${data?.external_id}/agree_to_confidentiality_notice`
      : `/quotes/${data?.id}/agree_to_confidentiality_notice`,
    invalidate: 'quotes',
  })

  const { mutateAsync: agreeToNDA, isLoading: isAgreeingToNDA }: any = useCreate({
    name: ['agree-to-nda'],
    url: isPublic ? `/apps/quotes/${data?.external_id}/agree_to_nda` : `/quotes/${data?.id}/agree_to_nda`,
    invalidate: 'quotes',
  })

  if (!data) return null

  if (!use_confidentiality_section && !use_nda_section) return null

  const title =
    use_confidentiality_section && use_nda_section
      ? 'Confidentiality Notice & NDA'
      : use_confidentiality_section
      ? 'Confidentiality Notice'
      : 'NDA'

  return (
    <ReviewSection
      isOpen={isOpen}
      title={title}
      data={data}
      icon="legal_agreement_alt"
      titleAfter={<QuoteIframeButton data={data} model="confidentiality_notice_nda" />}
      sectionModel="confidentiality_notice_nda"
    >
      <div className="grid gap-4">
        {use_confidentiality_section && (
          <div className="border-b border-0 border-solid border-divider pb-4">
            {didAgreeConfidentiality ? (
              <div className="flex items-center">
                <Status label="Completed" glyph="tick_circle" color="green" className="mr-2" />
                <Text glyph="date" label="Agreed On:" description={usDateTime(data?.agreed_to_confidentiality_notice_at, timezone)} />
              </div>
            ) : (
              <Status label="Action Required" glyph="warning" color="orange" />
            )}

            <SummonOverlay
              overlay={
                <ConfidentialityOverlay
                  didAgree={didAgreeConfidentiality}
                  data={data}
                  isLoading={isAgreeingToConfidentialityNotice}
                  onAgree={async () => {
                    try {
                      await agreeToConfidentialityNotice()

                      setDidAgreeConfidentiality(true)
                    } catch (error) {
                      console.error(error)
                    }
                  }}
                />
              }
            >
              <Button label="Review Confidentiality Notice" size={200} glyph="view" className="mt-3 !max-w-[320px]" type="primary" />
            </SummonOverlay>
          </div>
        )}

        {use_nda_section && (
          <div>
            {didAgreeNDA ? (
              <div className="flex items-center">
                <Status label="Completed" glyph="tick_circle" color="green" className="mr-2" />
                <Text glyph="date" label="Agreed On:" description={usDateTime(data?.agreed_to_nda_at, timezone)} />
              </div>
            ) : (
              <Status label="Action Required" glyph="warning" color="orange" />
            )}

            <SummonOverlay
              overlay={
                <NDAOverlay
                  didAgree={didAgreeNDA}
                  data={data}
                  isLoading={isAgreeingToNDA}
                  onAgree={async () => {
                    try {
                      await agreeToNDA()

                      setDidAgreeNDA(true)
                    } catch (error) {
                      console.error(error)
                    }
                  }}
                />
              }
            >
              <Button label="Review NDA" size={200} glyph="view" className="mt-3 !max-w-[320px]" type="primary" />
            </SummonOverlay>
          </div>
        )}
      </div>
    </ReviewSection>
  )
}
