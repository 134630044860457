import React from 'react'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import FormSection from '../../../../Forms/FormSection'
import Input from '../../../../Forms/Input'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import Type from '../../../../Typography/Type'
import YesNoRadioGroup from '../../../../Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupExtra from '../../../../Forms/elements/YesNoRadioGroupExtra'

import VitalSignsForm from './VitalSignsForm'

const BioMedical = () => {
  return (
    <FormSection layout="vertical">
      <VitalSignsForm showDateTime={false} showPainScale={false} showComments={false} requireHeight />

      <RadioGroup
        label="Will the client be seen by a psychologist, psychiatrist or other outside physician during treatment?"
        model="data.bio_medical.assignments.has_appointments"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.assignments.has_appointments" is={true}>
        <SmartTextarea
          useDictation
          label="Please list provider name and reason for appointment:"
          model="data.bio_medical.assignments.provider_name_and_reason"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />

        <RadioGroup
          label="Has an appointment already been scheduled?"
          model="data.bio_medical.assignments.is_physician_appointment_scheduled"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.bio_medical.assignments.is_physician_appointment_scheduled" is={true}>
          <SmartTextarea
            useDictation
            label="Please list provider name and date of appointment:"
            model="data.bio_medical.assignments.provider_name_and_appointment"
            validations={{
              presence: {
                message: 'Please provide this information',
              },
            }}
          />
        </ContextShow>
      </ContextShow>

      <RadioGroup
        model="data.bio_medical.general_condition"
        label="What is the general condition of the client's health?"
        layout="horizontal"
      >
        <Radio label="Poor" value="poor" />
        <Radio label="Average" value="average" />
        <Radio label="Good" value="good" />
      </RadioGroup>

      <Input
        model="data.bio_medical.ongoing_conditions"
        label="Does the client have any ongoing medical conditions? (i.e. asthma, high blood pressure, diabetes, etc.)"
      />

      <Input model="data.bio_medical.undiagnosed_medical_complaints" label="Does the client have any undiagnosed medical complaints?" />

      <YesNoRadioGroup label="Is the client experiencing any chronic physical pain?" model="data.bio_medical.has_chronic_physical_pain" />

      <ContextShow when="data.bio_medical.has_chronic_physical_pain" is={true}>
        <SmartTextarea
          useDictation
          label=""
          placeholder="Refer to get a chronic pain physical assessment"
          model="data.bio_medical.has_chronic_physical_pain_extra"
        />
      </ContextShow>

      <RadioGroup
        model="data.bio_medical.had_seizure"
        label="Has the client ever had a seizure as a result of substance abuse?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.had_seizure" is={true}>
        <SmartTextarea
          useDictation
          label="In the box list the circumstance that precipitated the seizure, the amount of episodes, approximate date(s) and if hospitalization was needed:"
          model="data.bio_medical.had_seizure_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.bio_medical.had_dts"
        label="Has the client ever experienced DT's (delirium tremens)?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.had_dts" is={true}>
        <CheckboxGroup label="Check all that apply:" layout="vertical-dense">
          <Checkbox label="Tremors" model="data.bio_medical.dts.dt_tremors" />
          <Checkbox label="Nightmares" model="data.bio_medical.dts.dt_nightmares" />
          <Checkbox label="Confusion" model="data.bio_medical.dts.dt_confusion" />
          <Checkbox label="Paranoia" model="data.bio_medical.dts.dt_paranoia" />
          <Checkbox label="Other" model="data.bio_medical.dts.dt_other" />
        </CheckboxGroup>
      </ContextShow>

      <ContextShow when="data.bio_medical.dts.dt_other" is={true}>
        <SmartTextarea
          useDictation
          label="Other"
          model="data.bio_medical.dts.dt_other_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup model="data.bio_medical.has_overdosed" label="Has the client ever overdosed?" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.has_overdosed" is={true}>
        <SmartTextarea useDictation label="What substance did the client overdose on?" model="data.bio_medical.has_overdosed_on" />

        <SmartTextarea
          useDictation
          label="How many times has the client experienced an overdose?"
          model="data.bio_medical.has_overdosed_frequency"
        />

        <YesNoRadioGroupExtra
          label="Was this intentional or unintentional?"
          yesLabel="Intentional"
          noLabel="Unintentional"
          model="data.bio_medical.has_overdosed_intention"
        />

        <SmartTextarea useDictation label="What caused the overdose?" model="data.bio_medical.has_overdosed_cause" />

        <YesNoRadioGroupExtra label="Did it require hospitalization?" model="data.bio_medical.has_overdosed_hospitalization" />
      </ContextShow>

      <RadioGroup
        label="Was the client chemically revived (with Narcan, etc.)?"
        model="data.bio_medical.has_been_chemically_revived"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.has_been_chemically_revived" is={true}>
        <SmartTextarea
          useDictation
          label="Please give a short description of event:"
          model="data.bio_medical.has_been_chemically_revived_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        model="data.bio_medical.sleep_without_substances"
        label="How is the client's sleep without alcohol, drugs, or medication?"
        layout="horizontal"
      >
        <Radio label="Poor" value="poor" />
        <Radio label="Average" value="average" />
        <Radio label="Good" value="good" />
      </RadioGroup>

      <Input model="data.bio_medical.sleep_hours" label="How many hours does the client sleep in a night?" size={2} suffix="hours" />

      <CheckboxGroup label="Does the client have trouble falling asleep or staying asleep?" layout="vertical-dense">
        <Checkbox label="Falling asleep" model="data.bio_medical.sleep.trouble_falling_asleep" />
        <Checkbox label="Staying asleep" model="data.bio_medical.sleep.trouble_staying_asleep" />
        <Checkbox label="Waking up frequently" model="data.bio_medical.sleep.trouble_waking_up_frequently" />
        <Checkbox label="Nightmares" model="data.bio_medical.sleep.trouble_nightmares" />
        <Checkbox label="Using dreams" model="data.bio_medical.sleep.trouble_using_dreams" />
      </CheckboxGroup>

      <RadioGroup model="data.bio_medical.has_ben_hospitalized" label="Has the client ever been hospitalized?" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.has_ben_hospitalized" is={true}>
        <SmartTextarea
          useDictation
          label="Please provide the details of all hospitalizations (include dates, reasons and any other relevant context)"
          model="data.bio_medical.has_ben_hospitalized_extra"
        />
      </ContextShow>

      <Type as="h4" variant="H4">
        Nutrition
      </Type>

      <YesNoRadioGroupExtra label="Does the client have any food allergies?" model="data.bio_medical.sleep.has_food_allergies" />

      <Input
        model="data.bio_medical.meals_per_day"
        label="How many meals per day is the client currently eating?"
        suffix="meals"
        size={2}
      />

      <Input
        model="data.bio_medical.percentage_meals_eating"
        label="What percentage of meals is the client currently eating?"
        suffix="%"
        size={2}
      />

      <RadioGroup
        model="data.bio_medical.has_significant_weight_changes"
        label="Has the client experienced weight gain or loss of 10 pounds or more in the last 3 months?"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.bio_medical.has_significant_weight_changes" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.bio_medical.has_significant_weight_changes_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <YesNoRadioGroupExtra
        label="Has the client experienced a decrease in food intake or appetite?"
        model="data.bio_medical.sleep.has_food_intake_appetite_decrease"
      />

      <YesNoRadioGroupExtra label="Is the client experiencing any dental problems?" model="data.bio_medical.sleep.has_dental_problems" />

      <YesNoRadioGroupExtra
        label="Are there any habits or behaviors indicating an eating disorder (such as binging or induced vomiting)?"
        model="data.bio_medical.sleep.has_eating_disorder"
      />

      <YesNoRadioGroup label="Nutrition referral needed?" model="data.bio_medical.does_require_nutrition_referral" />

      <SmartTextarea useDictation label="Notes" model="data.bio_medical.notes" />
    </FormSection>
  )
}

export default BioMedical
