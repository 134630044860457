import React from 'react'
import { NavLink, Route, useLocation } from 'react-router-dom-v5-compat'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Card, Page, HelpTagIframe } from '@behavehealth/components'
import { ExportCSVButton } from '@behavehealth/components/Buttons/ExportCSVButton'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'

import EventTemplatesTable from '@behavehealth/components/Templates/tables/EventTemplatesTable'
import useStore from '@behavehealth/modules/store'

import EventTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/EventTemplatesImportOverlay'
import EventTemplateOverlay from '@behavehealth/components/Templates/overlays/EventTemplateOverlay'

const pageConfig = {
  feature: 'calendar_templates',
  title: 'Calendar Templates',
  help: <HelpTagIframe id="settings_calendar_templates" />,
}

const CalendarTemplates = () => {
  return (
    <div>
      <CalendarTemplatesIndex />

      <AnimatedRoutes>
        <Route path="import" element={<EventTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<EventTemplateOverlay useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const CalendarTemplatesIndex = () => {
  const { pathname } = useLocation()
  const isBehave = useStore((state) => state?.isBehave)

  const { data, isLoading }: any = useGet({
    name: 'event-templates',
    url: `/event_templates`,
    params: { event_type: 'event' },
  })

  return (
    <Page
      breakpoint={3}
      actions={
        <>
          {isBehave && (
            <Button
              label="Import Behave Templates"
              type="default"
              glyph="add"
              link={`${pathname}/import`}
              permission="calendar_templates.create"
            />
          )}
          <Button label="Add Event Template" type="primary" glyph="add" link={`${pathname}/new`} permission="calendar_templates.create" />
        </>
      }
      {...pageConfig}
    >
      <Card>
        <EventTemplatesTable
          mainLinkAs={NavLink}
          title="Event Templates"
          testKey="event_templates_table"
          titleAfter={<ExportCSVButton url={`/event_templates/csv`} params={{ event_type: 'event' }} />}
          data={data}
          isLoading={isLoading}
          to={(id: string) => id}
        />
      </Card>
    </Page>
  )
}

export default withPageError(withMarketing(CalendarTemplates, pageConfig))
