import React from 'react'

import Alert from '../../../../components/Alert'
import Input from '../../../../components/Forms/Input'
import Attachments from '../../../../components/Forms/Attachments'
import Textarea from '../../../../components/Forms/Textarea'

export const DocumentsEditor = (props: any) => {
  const { activeElement, editActiveElementFromInput: onUpdate, editElement } = props

  if (!activeElement?.config) return null

  const { label, description, tooltip } = activeElement.config

  return (
    <>
      <Input label="Label" model="label" value={label} onUpdate={onUpdate} />
      <Textarea label="Description" model="description" value={description} onUpdate={onUpdate} />
      <Textarea label="Info Tooltip" model="tooltip" value={tooltip} onUpdate={onUpdate} />

      <Attachments
        label="Documents"
        model="documents"
        value={activeElement?.documents}
        onUpdate={(o) => {
          editElement({ uuid: activeElement.uuid, data: { documents_attributes: o.value } })
        }}
      />

      <Alert small contrast glyph="info">
        <b>Please note: </b> newly uploaded documents will be visible after the form is saved.
      </Alert>
    </>
  )
}
