import React from 'react'
import size from 'lodash/size'
import { v4 as uuid } from 'uuid'

import { countWord } from '../../../utils/functions'
import { DataTable } from '../../../components/DataTable/DataTable'
import { useDataTable } from '../../../components/DataTable/useDataTable'

import ClaimEventAction from './ClaimEventAction'
import ClaimEventStatus from './ClaimEventStatus'

import { usDateTime } from '../../../utils/functions'

import Glyph from '../../../components/Glyph'
import Tooltip from '../../../components/Tooltip'
import Alert from '../../../components/Alert'

const UPDATES = {
  assignee: 'Assignee',

  resident: 'Client',

  primary_insurance: 'Primary Insurance',
  secondary_insurance: 'Secondary Insurance',
  tertiary_insurance: 'Tertiary Insurance',

  insurance_local_payer: 'Insurance Provider',

  insurance_new_fee_schedule: 'Fee Schedule',
  claim_filing_code: 'Filing Code',
  claim_frequency_code: 'Frequency Code',
  place_of_service_code: 'Place of Service',
  insurance_authorization: 'Authorization',
  bill_type: 'Type of Bill',
  treatment_episode: 'Treatment Episode',
  admitted_at: 'Admit Date',
  discharged_at: 'Discharged Date',
  admission_type_code: 'Admission Type Code',
  admission_source_code: 'Admission Source Code',
  condition_codes: 'Condition Codes',
  patient_status_code: 'Patient Status Code',
  original_claim_no: 'Original Claim #',

  rendering_provider: 'Rendering Provider',
  billing_provider: 'Billing Provider',
  ordering_provider: 'Ordering Provider',
  attending_provider: 'Attending Provider',
  referring_provider: 'Referring Provider',
  supervising_provider: 'Supervising Provider',
}

export const ClaimEventsDataTable = ({ claimID }: any) => {
  const tableProps = useDataTable({
    name: ['insurance_claims', claimID, 'insurance_claim_events'],
    endpoint: `/insurance_claims/${claimID}/insurance_claim_events`,
  })

  const dataColumns = React.useMemo(
    () => [
      {
        title: 'Label',
        model: 'name',
        width: 155,
      },
      {
        title: 'Changed From',
        model: 'from',
        width: 130,
      },
      {
        title: 'Changed To',
        model: 'to',
        width: 130,
      },
    ],
    [],
  )

  const columns = React.useMemo(
    () => [
      {
        title: 'Event Name',
        model: 'name',
        width: 600,
      },
      {
        title: 'Updates',
        model: 'data',
        width: 150,
        formatValue: ({ data, value }: any) => {
          if (data.action === 'claim_validate' && data.status === 'errored') {
            const issuesCount = size(value?.errors)

            return (
              <Tooltip
                position="right"
                color="red"
                content={
                  <Alert type="negative" header={countWord('Issues', issuesCount)}>
                    The Clearing House reported the following issues:
                    <ul>
                      {value?.errors?.map((error: any) => (
                        <li>
                          <strong>{error.field}</strong>: {error.description}
                        </li>
                      ))}
                    </ul>
                  </Alert>
                }
              >
                <div className="flex flex-nowrap items-center">
                  {countWord('Issues', issuesCount)}
                  <Glyph glyph="info" className="ml-1" size={12} />
                </div>
              </Tooltip>
            )
          }

          if (data.action !== 'claim_update') return '1 Update'
          if (!value) return '–'

          const updates = size(Object.keys(value))
          if (updates === 0) return '0 Updates'

          // value
          let tableData = []
          for (let key in value) {
            if (['treatment_episode', 'admitted_at', 'discharged_at'].includes(key)) {
              tableData.push({
                id: uuid(),
                name: UPDATES[key],
                from: usDateTime(value[key].from !== '–' ? value[key].from : null) || '–',
                to: usDateTime(value[key].to !== '–' ? value[key].to : null) || '–',
              })
            } else {
              tableData.push({
                id: uuid(),
                name: UPDATES[key],
                from: value[key].from,
                to: value[key].to,
              })
            }
          }

          return (
            <Tooltip position="right" content={<DataTable asCard mainHeaderHidden data={tableData} columns={dataColumns} />}>
              <div className="flex flex-nowrap items-center">
                {countWord('Updates', updates)}
                <Glyph glyph="info" className="ml-1" size={12} />
              </div>
            </Tooltip>
          )
        },
      },
      {
        title: 'Type',
        model: 'action',
        width: 150,
        formatValue: ({ value }: any) => <ClaimEventAction status={value} />,
      },
      {
        title: 'Status',
        model: 'status',
        width: 130,
        formatValue: ({ value }: any) => <ClaimEventStatus status={value} />,
      },
      {
        title: 'Triggered By',
        model: 'author',
        type: 'profile',
        width: 200,
      },
      {
        title: 'Triggered At',
        model: 'triggered_at',
        type: 'date_time',
        width: 250,
      },
    ],
    [],
  )

  return <DataTable {...tableProps} title="Events" icon="calendar" columns={columns} />
}
