import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { mapToArray } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { ProgramTimeline } from '@behavehealth/constructs/Programs/ProgramTimeline'

const compare = (a: any, b: any) => {
  if (a.order < b.order) return -1
  else return 1
}

const RootListsTimeline: React.FC = ({ setPhasesCount }: any) => {
  const { resource_id }: any = useParams()

  const [dates, setDates]: any = React.useState({})
  const [filters, setFilters] = React.useState({})

  const hasDates = !!dates?.startDate && !!dates?.endDate

  const queryParams = {
    filters,
    ...(hasDates && {
      from: dates.startDate,
      until: dates.endDate,
    }),
  }

  const { data, isLoading, isRefetching }: any = useGet({
    name: ['program-timeline', resource_id, queryParams],
    url: `/programs/${resource_id}/timeline`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: !!resource_id && hasDates },
  })

  const program = data?.program
  const phases = program?.phases

  const phasesSize = size(phases)
  const isEmpty = phasesSize === 0

  const occupanciesByPhaseIds = React.useMemo(() => {
    const result = {}

    if (size(data?.occupancies) === 0) return result

    for (const occupancy of data.occupancies) {
      if (!occupancy.place?.phase?.id) continue
      if (!result[occupancy.place.phase.id]) result[occupancy.place.phase.id] = []

      result[occupancy.place.phase.id].push(occupancy)
    }

    return result
  }, [data])

  const phasesArray = mapToArray(phases).sort(compare)

  React.useEffect(() => {
    if (!data?.program?.phases) return

    setPhasesCount(size(data.program.phases))
  }, [data])

  return (
    <div css={STYLES.main}>
      <ProgramTimeline
        phases={phasesArray}
        occupanciesByPhaseIds={occupanciesByPhaseIds}
        isLoading={isLoading}
        isRefetching={isRefetching}
        dates={dates}
        setDates={setDates}
        isEmpty={isEmpty}
        title="Program Lists"
        icon="housing_shifts"
        emptyDescription="No program lists created yet"
      />
    </div>
  )
}

const STYLES: any = {
  main: {
    paddingTop: '1rem',
    paddingLeft: '1rem',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    overflow: 'hidden !important',
  },
}

export const ListsTimeline = withPageError(RootListsTimeline)
