import React from 'react'
import { darken, tint } from 'polished'
import clsx from 'clsx'

import { COLORS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Loader from '../../components/Loader'
import Overlay from '../../components/Overlay'
import Status from '../../components/Status'

import { INTEGRATIONS } from './constants'

const Integration = ({ onClick, type, existingIntegrations, isLoading }: any) => {
  const integration = INTEGRATIONS[type]
  if (!integration) return null

  const status = integration.status

  const isSetup = existingIntegrations?.includes(type)
  const allowMultipleSetups = integration.multi_setups
  const lockSetup = isSetup && !allowMultipleSetups

  const classNames = clsx({
    'coming-soon': status === 'coming_soon',
    'is-set-up': lockSetup,
  })

  return (
    <Card css={styles.integrationCard} onClick={onClick} className={classNames}>
      <Grid centerX gap="0.5rem">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Icon icon={{ file: integration.icon }} height="3rem" width="auto" />
            <div css={styles.title}>{integration.label}</div>
            <div css={styles.description}>{integration.description}</div>
            {lockSetup && <Status inline color="green" label="Connected" />}
            {status === 'coming_soon' ? (
              <Status inline color="orange" label="Coming Soon" />
            ) : (
              <Button label="Set Up →" display="inline-flex" type="primary" size={200} />
            )}
          </>
        )}
      </Grid>
    </Card>
  )
}

export const NewIntegrationOverlay = (props: any) => {
  const { level, existingIntegrations } = props

  const [selectedIntegration, setSelectedIntegration]: any = React.useState(null)

  const integrationKeys = Object.keys(INTEGRATIONS)
  let filteredIntegrations = integrationKeys.filter((key) => INTEGRATIONS[key].level === level)

  const { close, form, initialModel, isEditable, isInvalid, isSaving, onValidationUpdate, saveWithData } = useOverlay({
    name: 'integrations',
    endpoint: '/integrations',
    invalidate: 'integrations',
    invalidateKeys: ['company_integrations', 'personal_integrations'],
    options: props,
  })

  const integrationConfig: any = selectedIntegration && INTEGRATIONS?.[selectedIntegration]
  const IntegrationForm: any = integrationConfig?.component

  const handleSave = async () => {
    try {
      await saveWithData(form.current.getFormValue())
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay showBackdrop onClose={close}>
      <Overlay.Header icon="company_integrations" title="Set Up Integration" />

      <Overlay.Content className="p-4">
        {selectedIntegration && integrationConfig ? (
          <>
            <Button
              label="← Back"
              display="inline-flex"
              type="link"
              size={200}
              onClick={() => {
                setSelectedIntegration(null)
              }}
              className="-ml-2 mb-2"
            />

            <Card className="py-3 px-4">
              <div className="flex justify-center mt-3 mb-1">
                <Icon icon={{ file: integrationConfig.icon }} size={22} />
              </div>

              <div className="text-center mb-4">
                <h2 className="text-[1.45rem] m-0">{integrationConfig.label}</h2>
                <div className="text-text-muted mt-1">{integrationConfig.description}</div>
              </div>

              {IntegrationForm && (
                <IntegrationForm form={form} initialModel={initialModel} isEditable={isEditable} onValidationUpdate={onValidationUpdate} />
              )}
            </Card>
          </>
        ) : (
          <Grid gap="1rem" columns="repeat(auto-fit, minmax(250px, 1fr))">
            {filteredIntegrations.map((key: string) => (
              <Integration key={key} type={key} existingIntegrations={existingIntegrations} onClick={() => setSelectedIntegration(key)} />
            ))}
          </Grid>
        )}
      </Overlay.Content>

      {selectedIntegration && integrationConfig && (
        <Overlay.Footer>
          <Button
            label="Save Integration"
            type="primary"
            color="green"
            glyph="tick_circle"
            onClick={handleSave}
            isDisabled={isInvalid}
            isLoading={isSaving}
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const styles = {
  integrationCard: {
    height: 180,
    textAlign: 'center',
    padding: '1rem',
    cursor: 'pointer',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      background: COLORS.lightBackground,
    },

    '&.coming-soon': {
      background: tint(0.95, COLORS.gold),
      cursor: 'default',
      boxShadow: `inset 0 0 1px ${darken(0.3, COLORS.gold)}`,
      pointerEvents: 'none',
    },

    '&.is-set-up': {
      background: tint(0.95, COLORS.green),
      boxShadow: `inset 0 0 1px ${darken(0.2, COLORS.green)}`,
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
  title: {
    fontWeight: 600,
  },
  description: {
    color: COLORS.textMuted,
  },
}
