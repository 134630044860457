import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { countWord, mapToArray } from '../../utils/functions'
import { css } from '../../theme'
import { useCreate } from '../../hooks/useNewAPI'
import Notifications from '../../modules/notifications'

import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import Status from '../../components/Status'
import useAnalytics from '../../hooks/useAnalytics'

import { QUICK_ADD } from '../../components/Worksheet/config'
import { Worksheet } from '../../components/Worksheet/Worksheet'

export const CommunicationWorksheetOverlay = (props: any) => {
  const { onClose, onSuccess, invalidateKeys } = props

  const { trackEvent } = useAnalytics()

  const form = React.useRef()
  const history = useHistory()
  const location = useLocation()

  const [isValid, setIsValid] = React.useState(false)
  const [rowsCount, setRowsCount] = React.useState(0)

  const { mutateAsync: save, isLoading: isSaving }: any = useCreate({
    name: ['create-communications'],
    url: `/communications/batch`,
    invalidate: 'communications',
    invalidateKeys: invalidateKeys,
    onSuccess: () => {
      Notifications.send(`Successfully created ${countWord('Communications', rowsCount)}`, 'positive')

      trackEvent({ name: 'Worksheet Saved', params: { worksheetType: 'Communication' } })

      if (onSuccess) onSuccess()
    },
  })

  const close = () => {
    if (onClose) return onClose()

    const url = location?.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    history.push(url)
  }

  const handleSave = async () => {
    const data = form.current.getFormValue()

    await save(mapToArray(data))

    close()
  }

  React.useEffect(() => {
    trackEvent({ name: 'Worksheet Opened', params: { worksheetType: 'Communication' } })
  }, [])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        model: 'customer',
        type: 'object_selector',
        config: {
          quickAdd: QUICK_ADD.all_clients,
          isPolymorphic: true,
          shouldHideSelected: true,
          endpoint: '/residents',
          queryKey: 'residents',
          selectTitle: (data: any) => data.name,
          validations: {
            presence: {
              message: 'Please select a client',
            },
          },
        },
      },
      {
        title: 'Contact',
        model: 'contact',
        type: 'object_selector',
        config: {
          showAvatars: true,
          isPolymorphic: true,
          shouldHideSelected: true,
          endpoint: ({ rowData }: any) => rowData?.customer?.id && `/residents/${rowData.customer.id}/contactable`,
          queryKey: ({ rowData }: any) => rowData?.customer?.id && ['client', rowData.customer.id, 'contactable'],
          selectTitle: (data: any) => data.name,
          selectDescription: (data: any) => (
            <Flex centerY gap="0.5rem">
              <Status small label={data.type === 'resident' ? 'Client' : 'Contact'} color={data.type === 'resident' ? 'blue' : 'orange'} />
              {data.type === 'contact' && data.relationship && `(${data.relationship})`}
            </Flex>
          ),
          getIsApplicable: ({ rowData }: any) => !!rowData?.customer?.id,
          validations: {
            presence: {
              message: 'Please select a client',
            },
          },
        },
      },
      {
        title: 'Contact Date and Time',
        model: 'contacted_at',
        type: 'date_time',
        config: {
          defaultTo: 'now',
          validations: {
            presence: {
              message: 'Please enter the date and time of contact',
            },
          },
        },
      },
      {
        title: 'Contact Method',
        model: 'category',
        type: 'select',
        config: {
          options: [
            { label: 'Phone', value: 'phone' },
            { label: 'Text', value: 'text_message' },
            { label: 'Email', value: 'email' },
            { label: 'Meeting', value: 'meeting' },
            { label: 'Web Meeting', value: 'web' },
            { label: 'Other', value: 'other' },
          ],
          validations: {
            presence: {
              message: 'Please select a contact method',
            },
          },
        },
      },
      {
        title: 'Notes',
        model: 'reason',
        type: 'textarea',
        config: {
          validations: {
            presence: {
              message: 'Please write a note for this communication log',
            },
          },
        },
      },
    ] as const
  }, [])

  return (
    <Overlay showBackdrop onClose={onClose} position="center" maxWidth={140}>
      <Overlay.Header title="Create Communication Logs" icon="communications" />

      <Overlay.Content className="!p-4">
        <Grid gap="1rem" className="!pb-3">
          <Worksheet
            asCard
            getForm={form}
            title="Communication Logs"
            allow="create-update-delete"
            columns={columns}
            onValidationUpdate={setIsValid}
            onRowsCountUpdate={setRowsCount}
          />
        </Grid>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Save ${countWord('Communication Logs', rowsCount)}`}
          glyph="check"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={isSaving}
          isDisabled={!isValid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
