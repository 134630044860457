export const ROSTER_SERVICE_STATUSES = {
  draft: {
    label: 'New',
    color: 'blue',
  },
  processed: {
    label: 'Processed',
    color: 'green',
  },
  flagged: {
    label: 'Flagged',
    color: 'orange',
  },
  archived: {
    label: 'Archived',
    color: 'gray',
  },
}

export const CLAIM_STATUSES = {
  // state_draft
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  validated: {
    label: 'Validated',
    color: 'gray',
  },
  validation_error: {
    label: 'Validation Error',
    color: 'orange',
  },

  // state_in_progress
  submitted: {
    label: 'Submitted',
    color: 'blue',
  },
  submission_error: {
    label: 'Submission Error',
    color: 'orange',
  },
  denied: {
    label: 'Denied',
    color: 'red',
  },

  // state_ended
  balance_due_client: {
    label: 'Balance Due Client',
    color: 'green',
  },
  paid: {
    label: 'Paid',
    color: 'green',
  },
  written_off: {
    label: 'Written Off',
    color: 'green',
  },
}

export const CLAIM_FLAGS = {
  flagged_to_review: {
    label: 'To Review',
    color: 'orange',
  },
  flagged_pending: {
    label: 'Pending',
    color: 'blue',
  },
  flagged_finalized: {
    label: 'Finalized',
    color: 'green',
  },
}

export const INSURANCE_REPORT_STATUSES = {
  draft: {
    label: 'Pending',
    color: 'grey',
  },
  processed: {
    label: 'New',
    color: 'blue',
  },
  done: {
    label: 'Done',
    color: 'green',
  },
  archived: {
    label: 'Archived',
    color: 'gray',
  },
}
