import React from 'react'
import { Link, NavLink, useMatch, Route, Routes } from 'react-router-dom-v5-compat'

import { Button, Page, HelpTagIframe, Tabs, PageHeader } from '@behavehealth/components'
import { NewProgramOverlay } from '@behavehealth/constructs/Programs/NewProgramOverlay'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { ProgramsTablePage } from './programs_table'
import { ProgramsTimelinePage } from './programs_timeline'

const pageConfig = {
  feature: 'programs',
  help: <HelpTagIframe id="programs" />,
  marketingID: 'programs',
}

const ProgramsPage = () => {
  return (
    <>
      <ProgramsPageIndex />

      <Routes>
        <Route
          path=":id"
          element={
            <ProtectedRouteV6 permission="programs.create">
              <NewProgramOverlay useV6Router />
            </ProtectedRouteV6>
          }
        />
      </Routes>
    </>
  )
}

const ProgramsPageIndex: React.FC = () => {
  const match = useMatch(`/programs/:tab/*`)
  const tab = match?.params.tab || 'table'

  const actions = <Button as={Link} label="Add Program" type="primary" glyph="add" link="new" permission="programs.create" />

  return (
    <Page useWrapper={false} css={STYLES.root}>
      <PageHeader showFeedbackTool icon="programs" title="Programs" css={STYLES.header} aside={actions} />

      <Tabs css={STYLES.tabs}>
        <Tabs.List css={STYLES.tabList}>
          <Tabs.Item as={NavLink} label="Table" icon="programs_table" to={`/programs/table`} />
          <Tabs.Item as={NavLink} label="Timeline" icon="programs_timeline" to={`/programs/timeline`} />
        </Tabs.List>
      </Tabs>

      {tab === 'table' ? <ProgramsTablePage /> : tab === 'timeline' ? <ProgramsTimelinePage /> : null}
    </Page>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
  },

  header: {
    zIndex: 1,
    position: 'relative',
  },

  tabs: {
    zIndex: 2,
  },

  tabList: {
    paddingLeft: '1rem',
    // marginBottom: '0.75rem',
  },
}

export default withPageError(withMarketing(ProgramsPage, pageConfig))
