import React from 'react'
import { Input } from './Input'
import { withFormContext } from './context'

const TenantInput = (props: any) => <Input {...props} />

TenantInput.defaultProps = {
  prefix: 'https://app.behavehealth.com/',
  defaultValidations: {
    presence: {
      message: 'Please enter a Company URL',
    },
    length: {
      minimum: 3,
      message: 'Your Company URL should have at least 3 characters',
    },
    format: {
      // eslint-disable-next-line no-useless-escape
      pattern: /^[a-z0-9]+(([a-z0-9]*)-?)*[a-z0-9]+$/,
      message: 'Please use only lowercase characters (a-z), digits (0-9) and dashes (-)',
    },
  },
}

export default withFormContext(TenantInput)
