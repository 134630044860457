import React from 'react'

import { COLORS, HARD_SHADOW } from '../../../theme'
import Glyph from '../../../components/Glyph'

export const FilterDropdownSearch: React.FC<any> = ({ value = '', onChange, onClear }) => {
  return (
    <div css={STYLES.root}>
      <Glyph glyph="search" css={STYLES.glyph} size={18} />
      <input
        data-test="filter_search_input"
        type="text"
        placeholder="Search…"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
        css={STYLES.input}
      />
      {value && onClear && (
        <div css={STYLES.clearButton} onClick={onClear}>
          <Glyph glyph="circle_error" size={14} color={COLORS.textMuted} />
        </div>
      )}
    </div>
  )
}

const STYLES = {
  root: {
    position: 'sticky',
    top: 0,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    zIndex: 1,
  },

  glyph: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '0.5rem',
  },

  input: {
    padding: '0.5rem 2rem',
    border: 'none',
    outline: 'none',
  },

  clearButton: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '2rem',

    svg: {
      transition: 'transform 80ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    },

    '&:hover': {
      svg: {
        fill: COLORS.text,
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
    },
  },
}
