import React from 'react'
import { Link, NavLink, Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { ShortLinkOverlay } from '@behavehealth/constructs/ShortLinks/ShortLinkOverlay'
import { ShortLinksDataTable } from '@behavehealth/constructs/ShortLinks/ShortLinksDataTable'

const RootShortLinks = () => {
  const tableProps = useDataTable({
    name: ['short_links'],
    endpoint: `/short_links`,
    params: {
      category: 'ehr',
    },
    // localStorageKey: 'short_links_v1',
  })

  const to = React.useCallback((data) => data.id, [])

  return (
    <>
      <Page
        title="Short Links"
        icon="short_links"
        actions={<Button as={Link} label="Add Short Link" type="primary" glyph="add" link="new" permission="settings.short_links.create" />}
      >
        <Grid>
          <ShortLinksDataTable
            asCard
            to={to}
            mainLinkAs={NavLink}
            batchActionsConfig={[
              {
                type: 'delete',
                // permission: 'short_links.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
            {...tableProps}
          />
        </Grid>
      </Page>

      <AnimatedRoutes>
        <Route path=":id" element={<ShortLinkOverlay useV6Router category="ehr" />} />
      </AnimatedRoutes>
    </>
  )
}

export const ShortLinks = withPageError(RootShortLinks)
