import React from 'react'
import get from 'lodash/get'
import { withFormContext } from '../../../../Forms/context'

import Button from '../../../../Button'
import ContextShow from '../../../../Forms/ContextShow'
import DateInput from '../../../../Forms/DateInput'
import FormSection from '../../../../Forms/FormSection'
import Input from '../../../../Forms/Input'
import LevelOfCareSelect from '../../../../Forms/LevelOfCareSelect'
import Option from '../../../../Forms/Option'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Section from '../../../../Section'
import Select from '../../../../Forms/Select'
import State from '../../../../State'

class TreatmentHistoryForm extends React.Component {
  state = {
    episodes: get(this.props.form.getInitialModel(), 'data.treatment_history.episodes', []),
  }

  /*
    CUSTOM
  */
  addEpisode = () => {
    let episodes = this.state.episodes

    episodes.push({
      name: '',
      level_of_care: '',
      episode_length: '',
      abstinence_days: 0,
    })

    this.setState({ episodes: episodes })
  }

  removeEpisode = (idx) => {
    let episodes = this.state.episodes
    episodes.splice(idx, 1)
    this.setState({ episodes: episodes })
  }

  renderTrigger = () =>
    this.props.isEditable && <Button type="minimal" glyph="add" label="Add Treatment Episode" onClick={this.addEpisode} />

  /*
    RENDER
  */
  render() {
    const { episodes } = this.state
    const { isEditable } = this.props

    const isEmpty = episodes.length === 0

    return (
      <State
        isEmpty={isEmpty}
        icon="clinical_assessments"
        title="Treatment History for Mental Health and Addiction"
        emptyDescription="No treatment episodes added yet"
        emptyActions={this.renderTrigger()}
      >
        {episodes.map((_episode, idx) => (
          <Section
            key={idx}
            title="Treatment Episode"
            aside={
              isEditable && <Button label="Delete" glyph="delete" type="minimal" color="red" onClick={() => this.removeEpisode(idx)} />
            }
          >
            <FormSection>
              <RadioGroup
                label="Episode Type"
                model={`data.treatment_history.episodes[${idx}].category`}
                defaultValue="addiction_treatment"
                layout="vertical-dense"
              >
                <Radio label="Addiction Treatment" value="addiction_treatment" />
                <Radio label="Mental Health Treatment" value="mental_health_treatment" />
              </RadioGroup>

              <Input model={`data.treatment_history.episodes[${idx}].name`} label="Provider Name" />

              <FormSection horizontal>
                <DateInput label="Start Date" model={`data.treatment_history.episodes[${idx}].start_date`} />
                <DateInput label="End Date" model={`data.treatment_history.episodes[${idx}].end_date`} />
              </FormSection>

              <ContextShow when={`data.treatment_history.episodes[${idx}].category`} is="addiction_treatment">
                <LevelOfCareSelect
                  label="Level of Care (Addiction Treatment)"
                  model={`data.treatment_history.episodes[${idx}].addiction_treatment_level_of_care`}
                />
              </ContextShow>

              <ContextShow when={`data.treatment_history.episodes[${idx}].category`} is="mental_health_treatment">
                <Select
                  label="Level of Care (Mental Health Treatment)"
                  model={`data.treatment_history.episodes[${idx}].mental_health_treatment_level_of_care`}
                >
                  <Option label="Inpatient" value="inpatient" />
                  <Option label="Residential" value="residential" />
                  <Option label="Partial Hospitalization" value="partial_hospitalization" />
                  <Option label="Intensive Outpatient" value="intensive_outpatient" />
                  <Option label="Outpatient" value="outpatient" />
                  <Option label="Private Therapist" value="private_therapist" />
                  <Option label="Psychologist" value="psychologist" />
                  <Option label="Psychiatrist" value="psychiatrist" />
                  <Option label="EMDR" value="emdr" />
                </Select>
              </ContextShow>

              <Input label="Length of Treatment Episode" model={`data.treatment_history.episodes[${idx}].length`} size={2} suffix="Days" />

              <ContextShow when={`data.treatment_history.episodes[${idx}].category`} is="addiction_treatment">
                <Input
                  label="Length of Abstinence After Treatment"
                  model={`data.treatment_history.episodes[${idx}].abstinence_days`}
                  size={2}
                  suffix="Days"
                />
              </ContextShow>
            </FormSection>
          </Section>
        ))}
        {this.renderTrigger()}
      </State>
    )
  }
}

TreatmentHistoryForm.defaultProps = {
  isEditable: false,
}

export default withFormContext(TreatmentHistoryForm)
