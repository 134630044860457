import React from 'react'
import * as HoverCard from '@radix-ui/react-hover-card'

export const CardTooltip: React.FC = ({ children, ...rest }) => {
  return (
    <HoverCard.Root openDelay={100} closeDelay={100} {...rest}>
      {children}
    </HoverCard.Root>
  )
}
