import React from 'react'
import { connect } from 'react-redux'

import { address } from '../../../utils/functions'

import Button from '../../Button'
import MapboxMap from '../../MapboxMap'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Select from '../../Forms/Select'
import Option from '../../Forms/Option'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Textarea from '../../Forms/Textarea'
import DateTimeInput from '../../Forms/DateTimeInput'
import Overlay from '../../Overlay'
import Section from '../../Section'
import ContextShow from '../../Forms/ContextShow'
import Input from '../../Forms/Input'
import Grid from '../../Grid'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { apiUpdate } from '../../../modules/api'
import { withOverlayError } from '../../../hocs/withOverlayError'

class LocationCheckIn extends OverlayBase {
  checkout = async () => {
    apiUpdate({
      name: 'location_checks',
      url: `/location_checks/${this.props.record.id}`,
      params: {
        ...this.form.current.getFormValue(),
        status: 'out',
      },
    })
    // if (this.form.current) this.form.current.submitted()

    this.close()
  }

  renderHeader = () => {
    const { record } = this.props
    return <Overlay.Header glyph={record.status === 'in' ? 'check_out' : 'check_in'} title="Location Check-In" />
  }

  renderContent = () => {
    const { $editable, params } = this.state
    const { record, timezone } = this.props

    const pin = {
      lat: record.lat,
      lon: record.lon,
    }

    return (
      <Overlay.Content>
        <MapboxMap css={mapStyles} lat={pin?.lat} lon={pin?.lon} data={[pin]} />

        <Form
          getForm={this.form}
          initialModel={{ ...record, ...params }}
          isEditable={$editable}
          timezone={timezone}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
        >
          <Section>
            <FormSection>
              <Grid columns="1fr 1fr">
                <DateTimeInput defaultToNow label="Check-In Date" model="checkin_at" isEditable={false} />
                {record.status === 'out' && <DateTimeInput label="Check-Out Date" model="checkout_at" isEditable={false} />}
              </Grid>

              <Select
                fullWidth
                label="Category"
                model="category"
                defaultValue="location"
                validations={{
                  presence: {
                    message: 'Please select a category',
                  },
                }}
              >
                <Option label="Location" value="location" />
                <Option label="Meeting" value="meeting" />
                <Option label="Other" value="other" />
              </Select>

              <ObjectSelector
                model="resident"
                label="Client"
                type="clients"
                icon="clients"
                isDisabled={true}
                selectTitle={(data) => data.name}
                validations={{
                  presence: {
                    message: 'Please select a property',
                  },
                }}
              />

              <ContextShow when="category" is="location">
                <ObjectSelector
                  isPolymorphic
                  model="location"
                  label="Location"
                  type="properties"
                  icon="properties"
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => address(data.address)}
                  validations={{
                    presence: {
                      message: 'Please select a property',
                    },
                  }}
                />
              </ContextShow>

              <ContextShow when="category" is="meeting">
                <Input
                  model="name"
                  label="Meeting Name"
                  validations={{
                    presence: {
                      message: 'Please enter a meeting name',
                    },
                  }}
                />
              </ContextShow>

              <ContextShow when="category" is="other">
                <Input
                  model="name"
                  label="Location Description"
                  validations={{
                    presence: {
                      message: 'Please enter a location description',
                    },
                  }}
                />
              </ContextShow>

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state
    const { record, isGeolocationAvailable, location } = this.props

    if (!isGeolocationAvailable) return null
    const source = location.source || 'ehr'

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              glyph="check"
              label="Save"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="location_checkin.create"
            />
            {!$new && <Button glyph="cross" label="Cancel" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            {!record.status && (
              <Button
                label="Check-In ➝"
                type="primary"
                color="green"
                isLoading={this.props.loading}
                onClick={this.save}
                isDisabled={isInvalid}
                flex="100 1 auto"
                glyph="check_in"
                permission="location_checkin.create"
              />
            )}
            {source === 'portal' && record.status === 'in' && (
              <Button
                label="Check-Out ➝"
                type="primary"
                color="orange"
                isLoading={this.props.loading}
                onClick={this.checkout}
                isDisabled={isInvalid}
                flex="100 1 auto"
                glyph="check_out"
                permission="location_checkin.create"
              />
            )}
            <Button label="Edit" glyph="edit" type="default" isDisabled={this.props.loading} onClick={this.edit} flex="100 1 auto" />
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapStyles = {
  display: 'grid',
  height: 250,
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'location_checks')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'location_checks')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(LocationCheckIn))
