import React from 'react'
import { useMedia } from 'use-media'

import { COLORS } from '../../theme'

import { apiDelete } from '../../modules/api'
import withSettings from '../../hocs/withSettings'

import ConfirmDialog from '../Dialogs/ConfirmDialog'
import Glyph from '../Glyph'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

const STATUSES = {
  active: 'Active',
  inactive: 'Inactive',
}

const mainColumn = (to: any, mainLinkAs: any) => ({
  width: 160,
  isSticky: true,
  canToggleVisible: false,
  accessor: 'ip',
  Header: 'IP',
  Cell: ({ row, value }: any) => <TableCell.MainLink as={mainLinkAs} to={to(row.original?.id)} label={value} />,
})

const removeLinkStyles = {
  margin: '0 auto',
  color: COLORS.red,

  '&:hover': {
    color: COLORS.text,
  },
}

const columns = (to: Function = () => {}, mainLinkAs, isDesktop: boolean) => [
  ...(isDesktop ? [mainColumn(to, mainLinkAs)] : []),
  {
    width: 100,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <TableCell value={STATUSES[value]} />,
  },
  {
    width: 60,
    id: 'actions',
    Header: 'Actions',
    Cell: ({ row }: any) => (
      <ConfirmDialog
        title="Remove IP?"
        message={`Remove ${row.original.ip} from the whitelist? This IP will not be able to access their profile anymore.`}
        yesLabel="Remove"
        noLabel="Cancel"
        yesColor="red"
        glyph="delete"
        onYes={() => apiDelete({ name: 'allowed_ips', url: `/allowed_ips/${row.original?.id}` })}
      >
        <TableCell.LinkWithPermissions label="Remove" type="link" glyph={<Glyph glyph="delete" size={20} />} css={removeLinkStyles} />
      </ConfirmDialog>
    ),
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  title: string
  to?: Function
  timezone: string
  user: Object
  batchActionsConfig?: Object
}

const AllowedIPsTable = (props: Props) => {
  const { data, emptyActions, emptyDescription, icon, isLoading, batchActionsConfig, localStorageKey, title, to, timezone } = props

  const isDesktop = useMedia({ minWidth: 600 })

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={columns(to, props.mainLinkAs, isDesktop)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

AllowedIPsTable.defaultProps = {
  title: 'IP Addresses',
  icon: 'permissions',
  emptyDescription: "This staff member doesn't have any IP addresses",
  localStorageKey: 'settings_ip_addresses',
}

export default withSettings(AllowedIPsTable)
