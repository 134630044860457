import React from 'react'

import { DEFAULT_FILTERS } from '../../constructs/Filters/constants'
import { FORM_SHARE_STATUSES } from '../../utils/constants'
import { useSettings } from '../../hooks'
import withSettings from '../../hocs/withSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { formatShortLink } from './functions'

const RootShortLinksDataTable = (props: any) => {
  const { tenant } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        width: 260,
        isSticky: true,
        canToggleVisible: false,
        model: 'name',
        title: 'Name',
        formatValue: ({ data, value }: any) => (
          <MainCell as={props.mainLinkAs} to={props.to ? props.to(data) : undefined} onClick={props.onClick} value={value} />
        ),
      },
      {
        width: 180,
        model: 'code',
        title: 'Short Link',
        disableSort: true,
        formatValue: ({ data }: any) => {
          const { code, domain } = data

          if (!domain || !code) return null

          return <LinkCell isExternal href={formatShortLink(domain, code)} value={`${domain}/${code}`} />
        },
      },
      {
        width: 200,
        model: 'description',
        title: 'Notes',
        type: 'rich_text',
      },
      {
        model: 'expired_at',
        title: 'Expiry Date',
        type: 'date_time',
      },
      {
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        model: 'updated_at',
        title: 'Date Updated',
        type: 'date_time',
      },
    ],
    [tenant, props.to, props.onClick],
  )

  return (
    <>
      <DataTable title="Short Links" icon="short_links" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
    </>
  )
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  internal_notes: {
    label: 'Internal Notes',
    type: 'string',
  },
  share_status: {
    label: 'Share Status',
    type: 'multi_select',
    options: Object.entries(FORM_SHARE_STATUSES).map(([value, label]) => ({ value, label })),
  },
  shared_links: {
    label: 'Shared Links',
    type: 'string',
  },
  expired_at: {
    type: 'date_time',
    label: 'Expiry Date',
    glyph: 'date',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}

export const ShortLinksDataTable = withSettings(RootShortLinksDataTable)
