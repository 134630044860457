import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import State from '../../../components/State'

import { BuilderElement } from '../elements/BuilderElement'
import { FORM_CONTENT_WIDTHS } from '../utils/constants'
import { PageElementDroppable } from '../elements/ElementDragAndDrop'
import { useFormBuilder } from '../useFormBuilder'

export const FormBuilderPage = React.forwardRef((props: any, ref: any) => {
  const { after, before, className, pageId, draggingElementId, getRichTextEditor } = props

  const page: any = useFormBuilder((state: any) => state.pages?.[pageId])
  const elementsOrder: any = useFormBuilder((state: any) => state.pages?.[pageId]?.elements_order)

  const style = React.useMemo(() => {
    return {
      width: '100%',
      maxWidth: FORM_CONTENT_WIDTHS?.[page?.config?.content_width] || '500px',
      '--field-max-width': '100% !important',
    }
  }, [page?.config?.content_width])

  if (!page) return null

  const isEmpty = size(elementsOrder) === 0
  const rootClasses = clsx('w-full max-w-full mx-auto [&_label]:!pt-0', className)

  return (
    <div ref={ref} className={rootClasses} style={style}>
      {before}

      {isEmpty ? (
        <State
          key="page-empty-state"
          isEmpty
          glyph="documents"
          glyphColor={COLORS.text}
          title="Empty Page"
          emptyDescription="This page has no content"
        />
      ) : (
        <>
          <PageElementDroppable pageId={pageId} index={0} isEmpty={isEmpty} />

          <div className="grid gap-1 grid-cols-[100%]">
            {elementsOrder?.map?.((elementId: string, index: number) => {
              return (
                <React.Fragment key={`fragment-${elementId}`}>
                  <BuilderElement
                    id={elementId}
                    elementId={elementId}
                    draggingElementId={draggingElementId}
                    getRichTextEditor={getRichTextEditor}
                  />
                  <PageElementDroppable pageId={pageId} index={index + 1} />
                </React.Fragment>
              )
            })}
          </div>
        </>
      )}

      {after}
    </div>
  )
})
