import React from 'react'

import Flex from '../../Flex'
import Input from '../Input'

const HeightInput = ({ model, isRequired }) => (
  <Flex
    alignItems="flex-end"
    columns="repeat(2, max-content)"
    css={{
      width: 'fit-content',
      gridRowGap: '0.75rem',
      alignItems: 'center',
    }}
  >
    <div css={STYLES.firstInput}>
      <Input
        label="Height"
        type="number"
        model={`${model}_ft`}
        suffix="ft"
        size={3}
        validations={
          isRequired && {
            presence: {
              message: 'Please enter a height',
            },
          }
        }
      />
    </div>
    <Input type="number" model={`${model}_in`} suffix="in" size={3} />
  </Flex>
)

const STYLES = {
  firstInput: { marginRight: '0.75rem' },
}

HeightInput.defaultProps = {
  model: 'height',
}

export default HeightInput
