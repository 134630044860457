import React from 'react'
import size from 'lodash/size'
import { useParams } from 'react-router-dom'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

import Button from '../../components/Button'
import Card from '../../components/Card'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Select from '../../components/Forms/Select'
import Option from '../../components/Forms/Option'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import State from '../../components/State'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import DnDUploader from '../../components/DnDUploader'

import { REQUESTS_DATA_BY_STATUS } from './mockData'
import PartnershipRequestStatus, { REQUEST_STATUSES } from './PartnershipRequestStatus'
import { titleCase } from '../../utils/functions'

export const REQUEST_TYPES = {
  form: {
    label: 'Form Request',
    value: 'form',
    icon: 'web_form',
  },
  file: {
    label: 'File Exchange',
    value: 'file',
    icon: 'files',
  },
  communication: {
    label: 'Communication Request',
    value: 'communication',
    icon: 'communications',
  },
  todo: {
    label: 'Todo Request',
    value: 'todo',
    icon: 'tasks',
  },
  event: {
    label: 'Event Attendance',
    value: 'event',
    icon: 'calendar',
  },
}

const REQUEST_TYPE_LABELS = Object.values(REQUEST_TYPES).map((o) => o.label)

const RequestStatusSelect = (props) => {
  return (
    <Select label="Status" {...props}>
      {Object.keys(REQUEST_STATUSES).map((o) => (
        <Option key={o} value={o} label={REQUEST_STATUSES[o]?.label} />
      ))}
    </Select>
  )
}

export const PartnershipRequestOverlay = (props: any) => {
  const { id, resource_id, request_type, request_status }: any = useParams()

  const initialModel = REQUESTS_DATA_BY_STATUS?.[request_type]?.[request_status]?.find((o) => o.id === id) || null

  const isNew = id === 'new'

  const { close, form, onValidationUpdate, isEditable, data, deleteRecord, isDeleting, cancel, edit } = useOverlay({
    name: 'partnership',
    endpoint: `/organizations`,
    invalidate: 'partnerships',
    options: { ...props, dataID: resource_id },
    disableParentRequest: true,
    isEditable: size(initialModel) === 0,
  })

  const { timezone } = useSettings()

  const [requestType, setRequestType] = React.useState<any>(initialModel?.request_type || '')
  const [title, setTitle] = React.useState<string>(initialModel?.name || '')

  const sentTo = initialModel?.sent_to || null
  const sentBy = initialModel?.sent_by || null

  const config = REQUEST_TYPES?.[requestType] || null

  const save = () => {
    if (isNew) close()
    else cancel()
  }

  return (
    <Overlay showBackdrop={isEditable} closeOnBackdrop position="right" onClose={close}>
      <Overlay.Header
        title="Send New Request"
        subtitle={initialModel?.status && <PartnershipRequestStatus status={initialModel.status} />}
        icon="web_form"
      />

      <Overlay.Content>
        <Section>
          <Form
            isCompact
            isEditable={isEditable}
            getForm={form}
            timezone={timezone}
            onValidationUpdate={onValidationUpdate}
            initialModel={initialModel}
          >
            <FormSection>
              <RadioGroup
                label="Request Type"
                layout="vertical-dense"
                model="request_type"
                onUpdate={({ value }) => {
                  setRequestType(value)

                  if (REQUEST_TYPES?.[value] && (!title || REQUEST_TYPE_LABELS.includes(title))) setTitle(REQUEST_TYPES[value].label)
                }}
              >
                {Object.values(REQUEST_TYPES).map((o) => (
                  <Radio key={o.value} label={o.label} value={o.value} icon={o.icon} />
                ))}
              </RadioGroup>

              <Input
                label="Title"
                value={title}
                onUpdate={({ value }: any) => {
                  setTitle(value)
                }}
              />

              {requestType === 'form' && (
                <>
                  <MultiOverlaySelector
                    label="Forms to Complete"
                    type="forms.public"
                    icon="web_form"
                    selectTitle={(data: any) => data.name}
                  />
                </>
              )}

              {/* {requestType === 'file' && <>file</>} */}
              {/* {requestType === 'communication' && <>communication</>} */}

              {requestType === 'todo' && (
                <>
                  <MultiOverlaySelector label="Todo's to Complete" type="events" icon="tasks" selectTitle={(data: any) => data.name} />
                </>
              )}

              {requestType === 'event' && (
                <>
                  <MultiOverlaySelector label="Events to Attend" type="events" icon="calendar" selectTitle={(data: any) => data.name} />
                </>
              )}

              <Textarea label="Notes" model="notes" />

              <DateTimeInput label="Due Date" model="due_at" />

              <ObjectSelector label="Sent by" value={sentBy} />
              <ObjectSelector label="Sent to" value={sentTo} />

              {!isEditable && (
                <div className="mt-2">
                  {request_type === 'received' && (
                    <Card>
                      <State
                        isEmpty
                        icon={config?.icon}
                        title="Respond to Request"
                        emptyDescription="You have not yet responded to this request"
                        minHeight={200}
                        emptyActions={
                          <SummonOverlay overlay={<ResponseOverlay requestType={requestType} data={initialModel} />}>
                            <Button label="Send Response" glyph="discharge" type="primary" size={200} flex="100 1 auto" />
                          </SummonOverlay>
                        }
                      />
                    </Card>
                  )}

                  {request_type === 'sent' && (
                    <Card>
                      <State
                        isEmpty
                        icon={config?.icon}
                        title="Request Response"
                        emptyDescription={`${sentTo?.name} has not yet responded to this request`}
                        minHeight={200}
                        emptyActions={
                          <SummonOverlay overlay={<CommentOverlay requestType={requestType} data={initialModel} />}>
                            <Button label="Add Comment" glyph="chat" type="default" size={200} flex="100 1 auto" />
                          </SummonOverlay>
                        }
                      />
                    </Card>
                  )}
                </div>
              )}
            </FormSection>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label={isNew ? 'Send Request' : 'Save Changes'}
              glyph="check"
              type="primary"
              color="green"
              onClick={save}
              // isLoading={isSaving}
              // isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && (
              <Button
                label="Cancel"
                glyph="cross"
                type="default"
                // isDisabled={isSaving}
                onClick={cancel}
              />
            )}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label="Edit Request"
              type="default"
              // isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
            />

            <DeleteDialog
              title="Delete Request?"
              message="Are you sure you want to delete this Request? This action cannot be undone."
              onYes={save}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const ResponseOverlay = (props: any) => {
  const { data, requestType } = props

  const config = REQUEST_TYPES?.[requestType] || null

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={props.onClose}>
      <Overlay.Header title={`${titleCase(requestType)} Request`} icon={config?.icon} />

      <Overlay.Content>
        <Form isEditable>
          <Section>
            <FormSection>
              <RequestStatusSelect label="Update Request Status" value={data?.status} />

              {requestType === 'file' && (
                <>
                  <ObjectSelector icon="files" label="Select Files" />
                  <DnDUploader isEditable />
                </>
              )}

              <MiniRichTextEditor label="Comments" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Send Reply" type="primary" color="green" glyph="check" />
      </Overlay.Footer>
    </Overlay>
  )
}

const CommentOverlay = (props: any) => {
  const { data, requestType } = props

  const config = REQUEST_TYPES?.[requestType] || null

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={props.onClose}>
      <Overlay.Header title={`${titleCase(requestType)} Request`} icon={config?.icon} />

      <Overlay.Content>
        <Form isEditable>
          <Section>
            <FormSection>
              <RequestStatusSelect label="Update Request Status" value={data?.status} />
              <MiniRichTextEditor label="Comments" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Add Comment" type="primary" color="green" glyph="check" />
      </Overlay.Footer>
    </Overlay>
  )
}
