import React from 'react'

import { usDate } from '../../../utils/functions'
import Empty from './Empty'

const UsDate: React.FC = ({ value, timezone }) => {
  if (!value) return <Empty />
  return usDate(value, timezone)
}

export default UsDate
