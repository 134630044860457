import React from 'react'

import { useSettings } from '../../../hooks/useSettings'
import { usDate, usDateTime, titleCase } from '../../../utils/functions'
import DataList from '../../../components/DataList'

export const CommunicationInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <DataList isCompact withPadding labelWidth={120}>
      {data.contact?.name && <DataList.Item label="Contact Name" value={data.contact?.name} />}
      <DataList.Item label="Contact Method" value={titleCase(data.category)} />
      {data.client && <DataList.Item label="Client" value={data.client.name} avatar={data.client.avatar} />}
      {data.organization && <DataList.Item label="Organization" value={data.organization.name} avatar={data.organization.avatar} />}
      <DataList.Item label="Date Contacted" value={usDateTime(data.contacted_at, timezone)} />
      <DataList.Item label="Date Added" value={usDateTime(data.created_at, timezone)} />
      <DataList.Item label="Last Edited" value={usDateTime(data.updated_at, timezone)} />
    </DataList>
  )
}
