import React from 'react'
import { tint } from 'polished'

import { CLINICAL_NOTES_TITLES } from '../../../utils/constants'
import { COLORS } from '../../../theme'
import { initials, usDateTime } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import ClinicalNoteStatus from '../../../components/Statuses/ClinicalNoteStatus'
import DataList from '../../../components/DataList'
import Glyph from '../../../components/Glyph'
import RoadmapGraphic from '../../../components/Roadmap/RoadmapGraphic'

import { RecordTooltip } from '../components/RecordTooltip'

const STATUSES = {
  draft: {
    label: 'Draft',
    color: 'gray',
    glyph: 'more_horizontal',
  },
  pending_review: {
    label: 'Pending Review',
    color: 'yellow',
    glyph: 'time',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'red',
    glyph: 'cross',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
    glyph: 'check',
  },
}

export const ClinicalNote = ({ data, client }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  const statusConfig = STATUSES[data.status]

  return (
    <RecordTooltip
      title={data.name || CLINICAL_NOTES_TITLES[data.category] || 'Clinical Note'}
      icon="clinical_notes"
      color={tint(0.4, COLORS.red)}
      startDate={data.started_at}
      recordLink={`/clinical-notes/all/${initials(data.category)}/${data.id}`}
      client={client}
      renderGraphic={({ openTooltip }) => (
        <RoadmapGraphic
          startDate={data.started_at}
          onClick={openTooltip}
          background={statusConfig?.color && tint(0.9, statusConfig.color)}
          graphic={statusConfig?.glyph && <Glyph glyph={statusConfig.glyph} size={12} />}
          size="20px"
        />
      )}
    >
      <DataList isCompact withPadding labelWidth={120}>
        <DataList.Item label="Status" value={<ClinicalNoteStatus status={data.status} />} />
        <DataList.Item label="Session Date" value={usDateTime(data.started_at, timezone)} />
        <DataList.Item label="Duration" value={`${data.duration} min`} />
        <DataList.Item label="Added By" value={data.author?.name} />
      </DataList>
    </RecordTooltip>
  )
}
