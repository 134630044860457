import React from 'react'
import clsx from 'clsx'

import Card from '../Card'
import CardHeader from '../CardHeader'
import CardTitle from '../CardTitle'
import CardSubtitle from '../CardSubtitle'
import Graphic from '../Graphic'

import { COLORS } from '../../theme'

type Props = {
  title?: string
  description?: string
  action?: React.ReactNode
  icon?: string
  glyph?: string
  className?: string
  isFullWidth?: boolean
}

const MarketingBanner = ({ icon, glyph = 'info', title, description, action, className, isFullWidth }: Props) => {
  const rootClasses = clsx('flex-[0_0_auto]', isFullWidth && 'is-full-width', className)

  return (
    <Card css={styles.root} className={rootClasses}>
      <CardHeader after={action} graphic={(icon || glyph) && <Graphic icon={icon} glyph={glyph} size={28} />} contentStyles={styles.header}>
        <CardTitle title={title} css={styles.subtitle} />
        <CardSubtitle subtitle={description} css={styles.subtitle} />
      </CardHeader>
    </Card>
  )
}

const styles = {
  root: {
    position: 'relative',
    padding: 10,
    // marginBottom: 20,

    '&.is-full-width': {
      borderRadius: '0 !important',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      height: 4,
      backgroundColor: COLORS.blue,
    },
  },
  header: {
    flex: '999 1 250px',
  },
  title: {
    color: COLORS.deepBlack,
  },
  subtitle: {
    color: COLORS.deepBlack,
  },
}

export default MarketingBanner
