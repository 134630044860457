import React from 'react'

import Status from './Status'
import snakeCase from 'lodash/snakeCase'

const SmartStatus = ({ statuses, status, ...rest }) => {
  if (!statuses) return null

  if (!statuses[status]) {
    if (!statuses['default']) return null

    return (
      <Status
        color={statuses['default'].color}
        label={statuses['default'].label}
        glyph={statuses['default']?.glyph}
        infoTooltip={statuses['default']?.infoTooltip}
        glyphColor={statuses['default'].glyphColor || statuses['default'].color}
        {...rest}
      />
    )
  }

  return (
    <Status
      testKey={`${snakeCase(statuses[status].label)}_status`}
      color={statuses[status].color}
      label={statuses[status].label}
      glyph={statuses[status]?.glyph}
      infoTooltip={statuses[status]?.infoTooltip}
      glyphColor={statuses[status].glyphColor || statuses[status].color}
      {...rest}
    />
  )
}

export default SmartStatus
