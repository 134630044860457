import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { countWord, mapToArray } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'
import Notifications from '../../modules/notifications'

import Button from '../../components/Button'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import useAnalytics from '../../hooks/useAnalytics'

import { QUICK_ADD } from '../../components/Worksheet/config'
import { Worksheet } from '../../components/Worksheet/Worksheet'

export const BreathalyzerTestWorksheetOverlay = (props: any) => {
  const { onClose, onSuccess, invalidateKeys, initialData, save, isSaving, allow = 'create-update-delete', editDisabledColumns } = props

  const { trackEvent } = useAnalytics()

  const form = React.useRef()
  const history = useHistory()
  const location = useLocation()

  const [isValid, setIsValid] = React.useState(false)
  const [rowsCount, setRowsCount] = React.useState(0)

  const createMutation: any = useCreate({
    name: ['create-breathalyzer-tests'],
    url: `/breathalyzer_tests/batch`,
    invalidate: 'breathalyzer-tests',
    invalidateKeys: invalidateKeys,
    onSuccess: () => {
      Notifications.send(`Successfully created ${countWord('Breathalyzer Tests', rowsCount)}`, 'positive')

      trackEvent({ name: 'Worksheet Saved', params: { worksheetType: 'Breathalyzer Test' } })

      if (onSuccess) onSuccess()
      if (onClose) onClose()
    },
  })

  const close = () => {
    if (onClose) return onClose()

    const url = location?.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    history.push(url)
  }

  const handleSave = async () => {
    const data = form.current.getFormValue()

    if (save) {
      await save(mapToArray(data))
    } else {
      await createMutation.mutateAsync(mapToArray(data))
    }

    close()
  }

  React.useEffect(() => {
    trackEvent({ name: 'Worksheet Opened', params: { worksheetType: 'Breathalyzer Test' } })
  }, [])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        model: 'resident',
        type: 'object_selector',
        config: {
          quickAdd: QUICK_ADD.all_clients,
          shouldHideSelected: true,
          endpoint: '/residents',
          queryKey: 'residents',
          queryParams: { status: 'current' },
          selectTitle: (data: any) => data.name,
          validations: {
            presence: {
              message: 'Please select a client',
            },
          },
        },
      },
      {
        title: 'Collection Date',
        model: 'collected_at',
        type: 'date_time',
        config: {
          defaultTo: 'now',
          validations: {
            presence: {
              message: 'Please enter a collection date',
            },
          },
        },
      },
      {
        title: 'Status',
        model: 'status',
        type: 'select',
        config: {
          options: [
            { label: 'Collected', value: 'collected', glyph: 'check', color: 'green' },
            { label: 'Not Collected', value: 'refused', glyph: 'cross', color: 'red' },
          ],
          validations: {
            presence: {
              message: 'Please select a status',
            },
          },
        },
      },
      {
        title: 'Result',
        model: 'result',
        type: 'select',
        config: {
          getIsApplicable: ({ rowData }: any) => rowData.status === 'collected',
          options: [
            { label: 'Positive', value: 'positive' },
            { label: 'Negative', value: 'negative' },
            { label: 'Not Available', value: 'not_available' },
          ],
          validations: {
            presence: {
              message: 'Please select a result',
            },
          },
        },
      },
      {
        title: 'Result Value',
        model: 'value',
        type: 'number',
        config: {
          getIsApplicable: ({ rowData }: any) => rowData.result === 'positive',
          validations: {
            presence: {
              message: 'Please enter a result value',
            },
          },
        },
      },
      {
        title: 'Notes',
        model: 'notes',
        type: 'textarea',
      },
    ] as const
  }, [])

  return (
    <Overlay showBackdrop onClose={onClose} position="center" maxWidth={140}>
      <Overlay.Header title="Create Breathalyzer Tests" icon="test_results" />

      <Overlay.Content className="!p-4">
        <Grid gap="1rem" className="!pb-3">
          <Worksheet
            asCard
            getForm={form}
            title="Breathalyzer Tests"
            allow={allow}
            columns={columns}
            onValidationUpdate={setIsValid}
            onRowsCountUpdate={setRowsCount}
            initialData={initialData}
            editDisabledColumns={editDisabledColumns}
          />
        </Grid>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Save ${countWord('Breathalyzer Tests', rowsCount)}`}
          glyph="check"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={createMutation.isLoading || isSaving}
          isDisabled={!isValid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
