import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useDataForms } from '@behavehealth/hooks/useDataForms'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Card, Chotomate, Dropdown, DropdownItem, Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { RecoveryCoachingTable } from '@behavehealth/components/Tables'

import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { Filters } from '@behavehealth/constructs/Filters/Filters'
import { RECOVERY_NOTES_SLUGS } from '@behavehealth/utils/constants'

const pageConfig = {
  feature: 'recovery_coaching',
  help: <HelpTagIframe id="recovery_coaching" />,
  marketingID: 'recovery_coaching',
}

const RecoveryCoaching: React.FC = ({ canCreate = true }: any) => {
  const match = useRouteMatch()

  const { batchDelete, data, isEmpty, isLoading, setFilters } = useDataForms({ category: 'recovery_coaching' })
  const { formSettings } = useSettings()

  const formsDropdown = (
    <Dropdown
      testKey="add_new_recovery_note"
      label="Add New…"
      buttonType="primary"
      glyph="add"
      position="bottom"
      permission="recovery_coaching.create"
    >
      <DropdownItem
        label="Case Management Checklist"
        color="orange"
        icon="recovery_coaching"
        link={{
          pathname: `${match.url}/case-management-checklist/new`,
          parent: match,
        }}
      />

      {formSettings?.recovery_notes_recovery_capital_scale && (
        <DropdownItem
          label="Recovery Capital Scale"
          icon="recovery_coaching"
          link={{
            pathname: `${match.url}/recovery-capital-scale/new`,
            parent: match,
          }}
        />
      )}

      {formSettings?.recovery_notes_recovery_capital_plan && (
        <DropdownItem
          label="Recovery Capital Plan"
          icon="recovery_coaching"
          link={{
            pathname: `${match.url}/recovery-capital-plan/new`,
            parent: match,
          }}
        />
      )}

      {formSettings?.recovery_notes_recovery_capital_scale_and_plan && (
        <DropdownItem
          label="Recovery Capital Scale & Plan"
          icon="recovery_coaching"
          link={{
            pathname: `${match.url}/recovery-capital-scale-and-plan/new`,
            parent: match,
          }}
        />
      )}

      {formSettings?.recovery_notes_mirc && (
        <DropdownItem
          label="Multidimensional Inventory of Recovery Capital"
          icon="recovery_coaching"
          link={{
            pathname: `${match.url}/mirc/new`,
            parent: match,
          }}
        />
      )}
    </Dropdown>
  )

  return (
    <Page actions={!isEmpty && canCreate && formsDropdown} {...pageConfig}>
      <Grid gap="1rem">
        <Chotomate name="recovery_coaching" ready={!isLoading} />

        <Filters config={FILTERS.recovery_notes} onUpdate={setFilters} localStorageKey="client_recovery_notes" />

        <Card>
          <RecoveryCoachingTable
            data={data}
            isLoading={isEmpty && isLoading}
            emptyActions={canCreate && formsDropdown}
            localStorageKey="clients_recovery_coaching"
            to={(id: string, subcategory: string) => ({
              pathname: `${match.url}/${RECOVERY_NOTES_SLUGS[subcategory]}/${id}`,
              parent: match,
            })}
            duplicateFormLink={(record: any) => ({
              pathname: `${match.url}/case-management-checklist/new`,
              parent: match,
              data: {
                data: record.data,
              },
            })}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'recovery_coaching.delete',
                action: batchDelete,
              },
            ]}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(RecoveryCoaching, pageConfig))
