import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import SmartStatus from '@behavehealth/components/SmartStatus'

const STATUSES = {
  paid: {
    label: 'Paid',
    color: 'green',
  },
  open: {
    label: 'Open',
    color: 'yellow',
  },
  partiallyPaid: {
    label: 'Partially Paid',
    color: 'yellow',
  },
  void: {
    label: 'Void',
    color: 'yellow',
  },
  draft: {
    label: 'Archived',
    color: 'gray',
  },
  unknown: {
    label: 'Unknown',
    color: 'gray',
  },
}

const RootBankTransactions = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting', 'bank_transactions'],
    endpoint: `/accounting/bank_transactions`,
    // params: {...},
    localStorageKey: 'accounting_bank_transactions_v1',
  })

  const { mutateAsync: syncRailzToBh, isLoading: isSyncingRailz } = useCreate({
    name: ['sync-railz-to-bh'],
    url: '/accounting/bank_transactions/sync_railz_to_bh',
    invalidate: tableProps.queryKey,
  })

  const { mutateAsync: syncQbToRailz, isLoading: isSyncingQb } = useCreate({
    name: ['sync-qb-to-railz'],
    url: '/accounting/bank_transactions/sync_qb_to_railz',
  })

  const handleSyncRailzToBh = async () => {
    await syncRailzToBh()
    tableProps.refetch()
  }

  const handleSyncQbToRailz = () => {
    syncQbToRailz()
  }

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Merchant Name',
        model: 'merchant_name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell value={data.merchant_name} />,
      },
      {
        title: 'Bank Account ID',
        model: 'bank_account_id',
        width: 200,
      },
      {
        title: 'Date',
        model: 'date',
        width: 260,
        type: 'date_time',
      },
      {
        title: 'Amount',
        model: 'amount',
        width: 200,
        type: 'amount',
      },
      {
        title: 'Description',
        model: 'description',
        width: 200,
      },
      {
        title: 'Is Pending',
        model: 'is_pending',
        width: 260,
        type: 'boolean',
      },
      {
        title: 'Authorized On Date',
        model: 'authorized_on_date',
        width: 260,
        type: 'date_time',
      },
      {
        title: 'Merchant Store Number',
        model: 'merchant_store_number',
        width: 260,
      },
      {
        title: 'Location',
        model: 'location',
        width: 200,
      },
      {
        title: 'Categories',
        model: 'categories',
        width: 200,
      },
      {
        title: 'Payment Channel',
        model: 'payment_channel',
        width: 200,
      },
      {
        title: 'Transaction Type',
        model: 'transaction_type',
        width: 200,
      },
      {
        title: 'Currency',
        model: 'currency',
        width: 200,
      },
      {
        title: 'Section',
        model: 'section',
        width: 200,
      },
      {
        title: 'Sub Section',
        model: 'sub_section',
        width: 200,
      },
      {
        title: 'Group',
        model: 'group',
        width: 200,
      },
      {
        title: 'Sub Group',
        model: 'sub_group',
        width: 200,
      },
      {
        title: 'Last Updated',
        model: 'source_modified_date',
        width: 260,
        type: 'date_time',
      },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Bank Transactions">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Bank Transactions"
          emptyDescription="No bank transactions have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          icon="accounting"
          columns={columns}
          {...tableProps}
          headerAfter={
            isBehave && (
              <Flex gap="0.5rem">
                <AccountingSyncButton
                  forceShow={true}
                  category="bank_transactions"
                  invalidate={tableProps.queryKey}
                  refetchUrl={'/accounting/sync?category=bank_transactions'}
                />
              </Flex>
            )
          }
        />
      </Grid>
    </Page>
  )
}

export const BankTransactions = withPageError(RootBankTransactions)

const FILTERS_CONFIG = {
  merchant_name: {
    label: 'Merchant Name',
    type: 'string',
  },
  bank_account_id: {
    label: 'Bank Account ID',
    type: 'string',
  },
  date: {
    label: 'Date',
    type: 'date_time',
  },
  amount: {
    label: 'Amount',
    type: 'number',
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  is_pending: {
    label: 'Is Pending',
    type: 'boolean',
  },
  authorized_on_date: {
    label: 'Authorized On Date',
    type: 'date_time',
  },
  merchant_store_number: {
    label: 'Merchant Store Number',
    type: 'string',
  },
  location: {
    label: 'Location',
    type: 'string',
  },
  categories: {
    label: 'Categories',
    type: 'string',
  },
  payment_channel: {
    label: 'Payment Channel',
    type: 'string',
  },
  transaction_type: {
    label: 'Transaction Type',
    type: 'string',
  },
  currency: {
    label: 'Currency',
    type: 'string',
  },
  section: {
    label: 'Section',
    type: 'string',
  },
  sub_section: {
    label: 'Sub Section',
    type: 'string',
  },
  group: {
    label: 'Group',
    type: 'string',
  },
  sub_group: {
    label: 'Sub Group',
    type: 'string',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'date_time',
  },
}
