import React from 'react'

import Alert from '../../Alert'
import Grid from '../../Grid'
import Glyph from '../../Glyph'

const InsurancePayerSupport = ({ payer, ...rest }: any) => (
  <Alert type="warning" {...rest}>
    <span>Features supported by {payer?.name}:</span>
    <Grid className="!mt-1.5">
      <div>
        <Glyph glyph={payer?.supports?.eligibility ? 'check' : 'cross'} size="1.1rem" /> Electronic Verification of Benefits (eVOB)
      </div>
      <div>
        <Glyph glyph={payer?.supports?.claims ? 'check' : 'cross'} size="1.1rem" /> Claims Submission
      </div>
      <div>
        <Glyph glyph={payer?.supports?.secondary_claims ? 'check' : 'cross'} size="1.1rem" /> Secondary Claims
      </div>
      <div>
        <Glyph glyph={payer?.supports?.claim_status || payer?.supports?.claims_status ? 'check' : 'cross'} size="1.1rem" /> Claim Status
      </div>
    </Grid>
  </Alert>
)

export default InsurancePayerSupport
