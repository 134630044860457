import React from 'react'
import clsx from 'clsx'

type Props = {
  layout: string
  isReadonly: boolean
} & React.HTMLAttributes<any>

const GroupLayout: React.FC<Props> = ({ children, className, layout, isReadonly }) => {
  const classNames = clsx({
    [layout]: layout,
    [className]: className,
    'is-readonly': isReadonly,
  })

  return (
    <div css={styles} className={classNames}>
      {children}
    </div>
  )
}

const styles: any = {
  width: '100%',
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexWrap: 'nowrap',
  lineHeight: '1em',

  '& > *': {
    width: '100%',
  },

  '&.grid': {
    display: 'grid',
  },

  '&.vertical': {
    flexDirection: 'column',

    '& > *': {
      marginBottom: 6,
    },

    '& > *:last-child': {
      marginBottom: 0,
    },
  },

  '&.vertical-2-columns': {
    display: 'grid',
    gridGap: '0.5rem',

    '@media(min-width: 500px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },

  '&.vertical-3-columns': {
    display: 'grid',
    gridGap: '0.5rem',

    '@media(min-width: 500px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },

    '@media(min-width: 800px)': {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },

  '&.vertical-dense': {
    flexDirection: 'column',

    '& > *': {
      width: '100%',
      marginTop: -1,
      borderRadius: '0 !important',
    },

    '&.is-readonly > *': {
      marginBottom: 6,
    },

    '& > *:first-child': {
      marginTop: 0,
      borderRadius: '5px 5px 0 0 !important',
    },

    '& > *:last-child': {
      marginBottom: 0,
      borderRadius: '0 0 5px 5px !important',
    },

    '& > *:only-child': {
      borderRadius: '5px !important',
    },
  },

  '&.horizontal': {
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin: '-0.25rem',

    '& > *': {
      width: 'auto',
      margin: '0.25rem',
      position: 'relative',
    },
  },

  '&.horizontal-wrap': {
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin: '-0.25rem',

    '& > *': {
      width: 'auto',
      margin: '0.25rem',
    },
  },

  '&.horizontal-dense': {
    flexDirection: 'row',

    '& > *': {
      width: 'fit-content',
      flex: '0 0 auto',
      marginLeft: -1,
      borderRadius: 0,
    },
    '& > *:first-child': {
      marginLeft: 0,
      borderRadius: '5px 0 0 5px',
    },

    '& > *:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },

  '&.list': {
    flexDirection: 'column',

    '& > *': {
      width: '100%',
      marginTop: -1,
      borderRadius: '0 !important',
    },

    '&.is-readonly > *': {
      marginBottom: 6,
    },

    '& > *:first-child': {
      marginTop: 0,
    },

    '& > *:last-child': {
      marginBottom: 0,
    },
  },
}

GroupLayout.defaultProps = {
  layout: 'vertical',
}

export default GroupLayout
