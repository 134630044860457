import React from 'react'
import { v4 as uuid } from 'uuid'
import isEqual from 'react-fast-compare'
import size from 'lodash/size'
import snakeCase from 'lodash/snakeCase'

import { countWord } from '../../../../utils/functions'
import { Worksheet as BHWorksheet } from '../../../../components/Worksheet/Worksheet'

import Alert from '../../../../components/Alert'
import Button from '../../../../components/Button'
import Card from '../../../../components/Card'
import Form from '../../../../components/Forms/Form'
import Overlay from '../../../../components/Overlay'
import Section from '../../../../components/Section'
import State from '../../../../components/State'
import SummonOverlay from '../../../../components/SummonOverlay'
import Textarea from '../../../../components/Forms/Textarea'
import Tooltip from '../../../../components/Tooltip'

const toSnakeCase = (str: string) => {
  const formatted = str?.trim()?.toLowerCase?.() || ''

  return snakeCase(formatted)
}

export const ObjectSelectorEditor = (props: any) => {
  const [updated, setUpdated]: any = React.useState(null)

  const { activeElement, editElementConfig } = props

  const { object_selector_options } = activeElement.config

  return (
    <>
      <SummonOverlay
        overlay={
          <BatchEditOverlay
            initialValues={object_selector_options}
            onUpdate={(options) => {
              editElementConfig({
                uuid: activeElement.uuid,
                config: {
                  object_selector_options: options,
                },
              })

              setUpdated(Math.random())
            }}
          />
        }
      >
        <Button label="Batch Edit Options" glyph="multi_select" size={200} />
      </SummonOverlay>

      <BHWorksheet
        key={`updated-${updated}`}
        asCard
        withFullScreenToggle
        withWrapToggle={false}
        withShowInvalidToggle={false}
        title="Options"
        allow="create-update-delete"
        titleClassName="!mr-auto"
        columns={COLUMNS}
        initialData={object_selector_options}
        defaultNewRow={DEFAULT_NEW_ROW}
        onDataUpdate={(dataMap: any, dataIds: any) => {
          const newOptions: any = []

          if (!dataMap || !dataIds) return newOptions

          for (let i = 0; i < dataIds.length; i++) {
            const _id = dataIds[i]
            const row = dataMap[_id]

            newOptions.push({
              _id,
              id: i + 1,
              name: row.name,
              description: row.description,
              model: toSnakeCase(row.model || row.name),
            })
          }

          if (isEqual(object_selector_options, newOptions)) return

          editElementConfig({
            uuid: activeElement.uuid,
            config: {
              ...activeElement.config,
              object_selector_options: newOptions,
            },
          })
        }}
      />
    </>
  )
}

const BatchEditOverlay = ({ initialValues, onUpdate, onClose }) => {
  const form = React.useRef()

  const [previewData, setPreviewData]: any = React.useState({})

  const formattedPreviewData = React.useMemo(() => {
    const result: any = []

    if (!previewData?.names) return result

    const names = previewData.names?.split?.('\n') || []
    const descriptions = previewData.descriptions?.split?.('\n') || []

    for (let i = 0; i < names.length; i++) {
      const name = names[i]

      if (!name) continue

      result.push({
        name,
        description: descriptions[i] || '',
      })
    }

    return result
  }, [previewData])

  const { initialNames, initialDescriptions, idsByName } = React.useMemo(() => {
    const initialDescriptions: any = [] // used to pre-fill the descriptions textarea with the current options
    const initialNames: any = [] // used to pre-fill the names textarea with the current options
    const idsByName: any = {} // used to maintain previous IDs for the same names

    if (size(initialValues) === 0) return { initialNames, idsByName }

    for (const value of initialValues) {
      idsByName[value.name] = value._id
      initialNames.push(value.name)
      initialDescriptions.push(value.description || '')
    }

    return { initialNames, initialDescriptions, idsByName }
  }, [initialValues])

  const handleSave = () => {
    const model = form.current.getFormValue()

    if (!model.names) return

    // get all name entries
    const namesArray = model.names?.split?.('\n') || []
    const descriptionsArray = model.descriptions?.split?.('\n') || []

    const data: any = []
    const usedIds: any = []

    for (let i = 0; i < namesArray.length; i++) {
      const name = namesArray[i]

      if (!name) continue

      const prevId = idsByName[name]
      const newId = !prevId || usedIds.includes(prevId) ? uuid() : prevId

      if (prevId) usedIds.push(prevId)

      data.push({
        _id: newId,
        name: name,
        description: descriptionsArray[i] || '',
      })
    }

    onUpdate(data)
    onClose()
  }

  const isPreviewDataEmpty = size(formattedPreviewData) === 0

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="right" maxWidth={60}>
      <Overlay.Header title="Batch Edit Options" glyph="multi_select" />

      <Overlay.Content>
        <Form
          getForm={form}
          onUpdate={setPreviewData}
          initialModel={{ names: initialNames.join('\n'), descriptions: initialDescriptions.join('\n') }}
        >
          <Section>
            <div className="grid gap-5 grid-cols-2">
              <Textarea label="Option Names" description="Separate values with new lines" model="names" minRows={24} />
              <Textarea
                label="Descriptions"
                description="Separate values with new lines. Please note: only descriptions that have a corresponding label will be saved"
                model="descriptions"
                minRows={24}
              />
            </div>

            <Card className="mt-4 px-3 py-2">
              <h4 className="text-[0.88rem] text-text-muted font-[700] tracking-[1px] uppercase">Options Preview</h4>

              <Alert small glyph="info" className="my-2">
                Please ensure the preview below matches your intended changes before saving
              </Alert>

              {isPreviewDataEmpty ? (
                <State isEmpty emptyDescription="Add names on new lines to batch-create options" />
              ) : (
                <>
                  {formattedPreviewData.map((row: any, index: number) => (
                    <div
                      key={`${row.name}-${index}`}
                      className="py-1 px-0.5 border-b border-0 border-solid border-divider last:border-none"
                    >
                      <div className="text-[0.9rem] font-[600]">{row.name}</div>
                      <div className="text-[0.8rem] text-text-muted opacity-90">{row.description}</div>
                    </div>
                  ))}
                </>
              )}
            </Card>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Save ${countWord('Options', size(formattedPreviewData))}`}
          type="primary"
          color="green"
          glyph="multi_select"
          onClick={handleSave}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const DEFAULT_NEW_ROW = {
  name: 'New Option',
  description: '',
}

const COLUMNS = [
  {
    title: 'Name',
    model: 'name',
    width: 150,
    config: {
      onUpdate: ({ value, rowId, updateRow }: any) => {
        if (!value) return

        updateRow(rowId, { model: toSnakeCase(value) })
      },
    },
  },
  {
    title: 'Description',
    model: 'description',
  },
  {
    title: (
      <>
        Model
        <Tooltip content="This is the model name that will be used to reference each option in the form submission data" className="ml-1" />
      </>
    ),
    model: 'model',
    width: 120,
    config: {
      onUpdate: ({ value, rowId, updateRow }: any) => {
        if (!value) return

        updateRow(rowId, { model: toSnakeCase(value) })
      },
    },
  },
]
