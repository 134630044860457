import React, { Component } from 'react'

import Button from '../../../Button'
import Divider from '../../../Divider'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Overlay from '../../../Overlay'
import Radio from '../../../Forms/Radio'
import RadioGroup from '../../../Forms/RadioGroup'
import Section from '../../../Section'
import Show from '../../../Forms/Show'
import SignaturePad from '../../../Forms/SignaturePad'
import SmartTextarea from '../../../Forms/SmartTextarea'

import VitalSignsForm from './common/VitalSignsForm'
import { withOverlayError } from '../../../../hocs/withOverlayError'

class InitialPainScaleAssessment extends Component {
  state = {
    data: {},
  }

  render() {
    const { data } = this.state

    return (
      <Overlay position="center" showBackdrop={true} maxWidth={34}>
        <Overlay.Header icon="nursing" title={this.props.data_form?.name || 'Initial Pain Scale Assessment'} />

        <Overlay.Content>
          <Form onUpdate={(form) => this.setState({ data: form.model })}>
            <Section>
              <FormSection layout="vertical">
                <RadioGroup label="Are you currently having pain?" model="has_pain" layout="horizontal-dense">
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>

                <Show when={data.has_pain}>
                  <RadioGroup label="Is this pain related to withdrawal?" model="model" layout="horizontal-dense">
                    <Radio label="Yes" value={true} />
                    <Radio label="No" value={false} />
                  </RadioGroup>

                  <RadioGroup
                    label="What is your pain level on a scale of 1 to 10 (10 being the highest level of pain)?"
                    model="pain_level"
                    layout="vertical-dense"
                  >
                    <Radio value="0" label="0 – Pain Free" />
                    <Radio value="1" label="1 – Very Mild" />
                    <Radio value="2" label="2 – Discomforting" />
                    <Radio value="3" label="3 – Tolerable" />
                    <Radio value="4" label="4 – Distressing" />
                    <Radio value="5" label="5 – Very Distressing" />
                    <Radio value="6" label="6 – Intense" />
                    <Radio value="7" label="7 – Very Intense" />
                    <Radio value="8" label="8 – Utterly Horrible" />
                    <Radio value="9" label="9 – Excruciating Unbearable" />
                    <Radio value="10" label="10 – Unimaginable Unspeakable" />
                  </RadioGroup>
                </Show>

                <SmartTextarea useDictation label="Additional Details" model="model" />

                <RadioGroup label="Assess further for pain?" model="model" layout="horizontal-dense">
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>
              </FormSection>
            </Section>

            <Divider />

            <Section headingType="h2" title="Vital Signs">
              <VitalSignsForm />
            </Section>

            <Divider />

            <Section headingType="h2" title="Assessment Status">
              <FormSection layout="vertical">
                <RadioGroup model="status" layout="vertical-dense" defaultValue="to_complete">
                  <Radio label="To Complete" value="to_complete" />
                  <Radio label="To Review" value="to_review" />
                  <Radio label="Updates Required" value="updates_required" />
                  <Radio label="Signed Off" value="signed_off" />
                </RadioGroup>

                {data.status === 'signed_off' && <SignaturePad title="Clinical Staff Signature" />}
              </FormSection>
            </Section>
          </Form>
        </Overlay.Content>

        <Overlay.Footer>
          <Button label="Save" glyph="check" type="primary" color="green" flex="10 1 auto" />
          <Button label="Cancel" glyph="cross" type="default" />
        </Overlay.Footer>
      </Overlay>
    )
  }
}

export default withOverlayError(InitialPainScaleAssessment)
