import React from 'react'
import { keyframes } from '@emotion/react'

import { COLORS } from '../../theme'
import { mapToArray, daysToWords } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Button from '../Button'
import ColorSelector from '../Forms/ColorSelector'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Flex from '../Flex'
import RichTextEditor, { MenuButton, MenuDivider } from '../Forms/RichTextEditor'
import State from '../State'
import TipAlert from '../TipAlert'

import { useGet, useCreate, useDelete, useUpdate } from '../../hooks/useNewAPI'

import { AppSidebarContent } from './AppSidebarContent'
import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

const StickyNotesView: React.FC<any> = () => {
  const [view, setView]: any = React.useState('list')

  const { user, isBehave } = useSettings()

  const { data, isLoading }: any = useGet({
    name: 'sticky-notes',
    url: `/${isBehave ? 'bh_employees' : 'employees'}/${user.id}/sticky_notes`,
  })

  const { mutate: createNote, isLoading: isCreating } = useCreate({
    name: ['sticky-note'],
    url: `/sticky_notes`,
    invalidate: 'sticky-notes',
  })

  const handleAddClick = () => {
    createNote({
      color: '#ffeb3b',
    })
  }

  return (
    <AppSidebarView>
      <AppSidebarHeader title="Sticky Notes" icon="sticky_notes" />

      <AppSidebarContent css={styles.content}>
        <TipAlert contrast type="default" glyph="info" localStorageKey="app_sidebar_sticky_notes_privacy">
          <b>Info:</b> these notes are private to you
        </TipAlert>

        <Flex centerY fixChildrenX justifyContent="space-between" gap={8}>
          <Button label="Add Note" onClick={handleAddClick} isLoading={isCreating} size={200} glyph="add" type="primary" />

          <div css={styles.toggleButtons}>
            <Button label="List" color="text" size={200} onClick={() => setView('list')} type={view === 'list' ? 'primary' : 'default'} />
            <Button label="Grid" color="text" size={200} onClick={() => setView('grid')} type={view === 'grid' ? 'primary' : 'default'} />
          </div>
        </Flex>

        {isLoading && <State isLoading />}

        <div css={styles.grid} className={`view-${view}`}>
          {mapToArray(data).map((note: any) => (
            <Note key={note.id} data={note} />
          ))}
        </div>
      </AppSidebarContent>
    </AppSidebarView>
  )
}

const Note: React.FC<any> = ({ data = {} }) => {
  const { timezone } = useSettings()

  const { mutate: updateNote, isLoading: isUpdating } = useUpdate({
    name: ['sticky-note', data.id],
    url: `/sticky_notes/${data.id}`,
    invalidate: 'sticky-notes',
  })

  const { mutate: deleteNote, isLoading: isDeleting } = useDelete({
    name: ['sticky-note', data.id],
    url: `/sticky_notes`,
    invalidate: 'sticky-notes',
  })

  const handleUpdate = (value: any) => {
    if (data.content === value?.value) return

    updateNote({ content: value?.value })
  }

  const handleColorClick = (color: any) => {
    if (data.color === color) return

    updateNote({ color: color })
  }

  const style: any = {
    '--note-background': isDeleting ? 'red' : data.color,
    opacity: isDeleting ? 0.5 : 1,
    pointerEvents: isDeleting ? 'none' : 'all',
  }

  return (
    <div css={styles.note} style={style}>
      <RichTextEditor
        autoHideToolbar
        value={data.content}
        className={data.color ? `color-${data.color}` : ''}
        actions={EDITOR_ACTIONS}
        withHover={false}
        onBlur={handleUpdate}
        actionsBefore={
          <>
            <MenuButton css={styles.colorButton}>
              <ColorSelector isSimplified withHover={false} value={data.color} onUpdate={({ value }: any) => handleColorClick(value)} />
            </MenuButton>
            <MenuDivider />
          </>
        }
        actionsAfter={
          <>
            <MenuDivider />

            <DeleteDialog
              title="Delete Note?"
              message="Are you sure you want to delete this note? This action cannot be undone."
              onYes={() => {
                deleteNote(data.id)
              }}
            >
              <MenuButton testKey="delete_menu_button" css={styles.deleteButton} glyph="delete" />
            </DeleteDialog>
          </>
        }
      />

      <div css={styles.noteInfo}>{isUpdating ? 'Saving…' : `Updated ${daysToWords(data.updated_at, timezone)}`}</div>
      <div css={styles.noteBackground} />
    </div>
  )
}

const EDITOR_ACTIONS = ['block', 'inline', 'list']

const animation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-30px) scale3d(0.85, 0.85, 0.85);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale3d(1, 1, 1);
  }
`

const styles = {
  content: {
    display: 'grid',
    gridGap: '0.75rem',
    paddingTop: '0.75rem',
  },

  grid: {
    display: 'grid',
    gridGap: '0.75rem',

    '&.view-list': {
      gridTemplateColumns: '1fr',
    },

    '&.view-grid': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    },
  },

  toggleButtons: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',

    '& > *:first-of-type': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    '& > *:last-of-type': {
      marginLeft: -1,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },

  colorButton: {
    marginRight: '-0.25rem',
  },

  deleteButton: {
    svg: {
      fill: COLORS.text,
    },

    '&:hover': {
      svg: {
        fill: COLORS.red,
      },
    },
  },

  note: {
    position: 'relative',
    borderRadius: 5,
    overflow: 'hidden',
    animation: `${animation} 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)`,
    transformOrigin: 'center top',

    '.RichTextEditor': {
      '--background-color': 'none',
    },
  },

  noteInfo: {
    position: 'absolute',
    right: '0.5rem',
    bottom: '0.5rem',
    pointerEvents: 'none',
    fontSize: '0.85rem',
    fontStyle: 'italic',
    opacity: 0.65,
  },

  noteBackground: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'var(--note-background)',
    display: 'block',
    pointerEvents: 'none',
    opacity: 0.12,
  },
}

export default StickyNotesView
