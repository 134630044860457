import React from 'react'

import useWindowFocus from '../hooks/useWindowFocus'

export const FocusedSession: React.FC<any> = () => {
  const windowFocused = useWindowFocus()

  React.useEffect(() => {
    if (!windowFocused) return
    if (!!window.ReactNativeWebView) return

    const selectedTenant = sessionStorage.getItem('bh.stn')
    if (selectedTenant) {
      localStorage.setItem('bh.ftn', selectedTenant)
    }
  }, [windowFocused])

  return null
}
