import React from 'react'

import ActivePlanStatus from '../Statuses/ActivePlanStatus'
import { Text } from '../Typography'

import { usDate, daysTo } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import { Tenant, Plan } from '../../declarations'

import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'

type Props = {
  plan: Plan
  tenant: Tenant
  timezone: string
}

const ActivePlan = ({ plan, tenant, timezone }: Props) => {
  const startDate = usDate(tenant.plan_started_at, timezone)
  const endDate = usDate(tenant.plan_ended_at, timezone)
  const daysRemaining = daysTo(tenant.plan_ended_at, timezone)

  return (
    <CardLink>
      <CardTitle title={plan.public_name} />
      <CardMeta>
        <ActivePlanStatus status={tenant.plan_status} />
        {tenant.plan_status === 'trialing' && (
          <>
            <Text glyph="date" label="Trial Started On: " description={startDate} />
            <Text glyph="date" label="Trial Ends On: " description={`${endDate} (${daysRemaining} days remaining)`} />
          </>
        )}

        {tenant.plan_status === 'active' && (
          <>
            <Text glyph="date" label="Plan Started On: " description={startDate} />
            <Text glyph="date" label="Plan Ends On: " description={`${endDate} (${daysRemaining} days remaining)`} />
          </>
        )}
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(ActivePlan)
