import React from 'react'
import { tint, transparentize } from 'polished'
import clsx from 'clsx'

import { COLORS, SHADOW } from '../../theme'
import Glyph from '../Glyph'

type Props = {
  className?: any
  isVisible?: boolean
  onClick?: any
}

const ClearButton = ({ isVisible, onClick, className }: Props) => {
  const classNames = clsx({
    'is-visible': isVisible,
    [className]: className,
  })

  return (
    <button data-test="clear_button" type="button" css={styles} onClick={onClick} className={classNames}>
      <Glyph glyph="close" />
    </button>
  )
}

const styles = {
  width: '1.75rem',
  height: '100%',

  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',

  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,

  border: 'none',
  outline: 'none',
  background: COLORS.white,
  borderRadius: 0,
  cursor: 'pointer',

  svg: {
    width: '0.8rem',
    height: '0.8rem',
    fill: COLORS.text,
    transition: 'transform 80ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  '&.is-visible': {
    display: 'inline-flex',
  },

  '&:focus, &:hover': {
    background: tint(0.95, COLORS.red),
    boxShadow: `0 0 0 1px ${transparentize(0.75, COLORS.red)}`,

    svg: {
      fill: COLORS.red,
      transform: 'scale3d(1.15, 1.15, 1.15)',
    },
  },
}

ClearButton.defaultProps = {
  isVisible: true,
}

export default ClearButton
