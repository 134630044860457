import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../theme'

const Divider: React.FC<any> = ({ invisible, className }) => {
  const classNames = clsx({
    'is-invisible': invisible,
    [className]: className,
  })

  return <hr css={styles} className={classNames} />
}

const styles = {
  height: 1,
  width: '100%',
  border: 'none',
  flex: ' 0 0 auto',
  background: COLORS.divider,

  margin: '1rem 0',

  '&.is-invisible': {
    background: 'transparent',
  },
}

export default Divider
