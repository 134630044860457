import React from 'react'
import compact from 'lodash/compact'
import { keyframes } from '@emotion/react'
import { Manager, Reference } from 'react-popper'
import Fuse from 'fuse.js'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS, SHADOW } from '../theme'
import { mapToArray } from '../utils/functions'
import { useGet } from '../hooks/useNewAPI'

import Button from './Button'
import Card from './Card'
import Flex from './Flex'
import Glyph from './Glyph'
import Loader from './Loader'
import SmartPortal from './SmartPortal'
import State from './State'
import Tooltip from './Tooltip'
import Permission from './Permission'

const TagsSelector: React.FC<any> = (props) => {
  const { onSave, isEditable = true, smartCategories, editPermission, buttonLabel = 'Tags', buttonClassName, isSaving } = props

  const [tags, setTags]: any = React.useState(props.tags || [])
  const [isOpen, setIsOpen] = React.useState(false)
  const [didChange, setDidChange] = React.useState(false)
  const [menuRef, setMenuRef] = React.useState(null)

  const { data, isLoading }: any = useGet({
    name: compact(['tag-groups', smartCategories]),
    url: `/tag_groups`,
    params: {
      smart_categories: smartCategories,
    },
  })

  const isEmpty = size(data) === 0

  const hasTags = size(tags) > 0
  const tagIDs = tags?.map?.((o) => o.id)

  const toggle = (event: any) => {
    event.stopPropagation()

    setIsOpen((c) => !c)
  }
  const close = () => setIsOpen(false)

  React.useEffect(() => {
    if (!menuRef) return

    const handleOutsideClick = (event: any) => {
      if (menuRef?.contains?.(event.target)) return

      if (didChange && onSave) {
        onSave(
          tags.map((o) => o.id),
          tags,
        )
      }
      close()
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [tags, menuRef])

  React.useEffect(() => {
    if (!props.tags) return
    setTags(props.tags)
  }, [props.tags])

  const addTag = (tag: any) => {
    setDidChange(true)

    const newTags = produce(tags, (draft: any) => {
      draft.push(tag)
    })
    setTags(newTags)
  }

  // const removeTag = (id: number) => {
  //   const newTags: any = produce(tags, (draft: any) => {
  //     const index = draft.findIndex((o) => o.id === id)
  //     draft.splice(index, 1)
  //   })
  //   setTags(newTags)
  //   onSave?.(
  //     newTags.map((o) => o.id),
  //     newTags,
  //   )
  // }

  const removeMenuTag = (id: number) => {
    setDidChange(true)

    const newTags: any = produce(tags, (draft: any) => {
      const index = draft.findIndex((o) => o.id === id)
      draft.splice(index, 1)
    })
    setTags(newTags)
  }

  return (
    <>
      <Manager>
        <Flex gap="0.4rem">
          {mapToArray(tags).map?.((tag: any) => (
            <Tooltip
              key={tag.id}
              content={
                tag.description && (
                  <>
                    <b>{tag.name}: </b>
                    {tag.description}
                  </>
                )
              }
            >
              <div css={styles.tag} style={{ '--color': tag.color }}>
                <Glyph glyph={tag.glyph} size={14} color={tag.color} css={styles.glyph} />

                <div css={styles.tagName}>{tag.name}</div>

                {/* {isEditable && (
                <div className="close-button" onClick={() => removeTag(tag.id)}>
                  <Glyph glyph="cross" size={10} color={COLORS.textMuted} />
                </div>
              )} */}
              </div>
            </Tooltip>
          ))}

          {isEditable && (
            <Permission permission={editPermission}>
              <Reference>
                {({ ref }) => (
                  <div data-test="add_tags_button" ref={ref} onClick={toggle} css={styles.trigger} className={buttonClassName}>
                    {isSaving ? (
                      <Loader size={12} />
                    ) : (
                      <>
                        <Glyph glyph={hasTags ? 'edit' : 'add'} size={12} color={COLORS.blue} />
                        <span>{buttonLabel}</span>
                      </>
                    )}
                  </div>
                )}
              </Reference>
            </Permission>
          )}
        </Flex>

        {isOpen && (
          <>
            <SmartPortal portal="tooltip" position="bottom">
              <div ref={setMenuRef} css={styles.menu}>
                <Card testKey="tags_selector" css={styles.card}>
                  {isEmpty || isLoading ? (
                    <State
                      title="Tags Manager"
                      minHeight={150}
                      emptyDescription="No tags have been created yet"
                      emptyActions={
                        <Button label="Manage Global Tags →" size={200} type="link" glyph="settings" link="/settings/tags-manager" />
                      }
                      glyph="tag"
                      isEmpty={isEmpty}
                      isLoading={isLoading}
                    />
                  ) : (
                    <>
                      <div css={styles.content}>
                        {mapToArray(data).map((group: any) => (
                          <React.Fragment key={group.id}>
                            <div css={styles.groupHeader}>{group.name}</div>

                            {group.tags.map((tag: any) => {
                              const isAdded = tagIDs.includes(tag.id)

                              return (
                                <div
                                  data-test="tag_selector_item"
                                  key={tag.id}
                                  css={styles.groupRow}
                                  style={{ '--color': tag.color }}
                                  className={isAdded ? 'is-added' : ''}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    event.nativeEvent.stopImmediatePropagation()

                                    if (isAdded) removeMenuTag(tag.id)
                                    else addTag(tag)
                                  }}
                                >
                                  <Glyph glyph={tag.glyph} size={14} color={tag.color} css={styles.glyph} />

                                  <div>{tag.name}</div>

                                  {isAdded && (
                                    <div className="close-button">
                                      <Glyph glyph="cross" size={10} color={COLORS.textMuted} />
                                    </div>
                                  )}
                                </div>
                              )
                            })}
                          </React.Fragment>
                        ))}
                      </div>
                    </>
                  )}
                </Card>
              </div>
            </SmartPortal>
          </>
        )}
      </Manager>
    </>
  )
}

const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translateY(-12px);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translateY(0);
  }
`

const styles = {
  trigger: {
    display: 'inline-flex',
    borderRadius: 100,
    border: `2px dotted ${COLORS.divider}`,
    padding: '0.1rem 0.5rem',
    paddingRight: '0.75rem',
    height: '1.6rem',
    alignItems: 'center',

    fontWeight: 600,
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    lineHeight: 'normal',
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.hover,
    },

    svg: {
      marginRight: '0.25rem',
    },
  },

  tag: {
    position: 'relative',
    display: 'inline-flex',
    borderRadius: 100,
    border: `1px solid ${COLORS.divider}`,
    background: COLORS.white,
    boxShadow: SHADOW(1),
    minHeight: '1.5rem',
    paddingLeft: '0.3rem',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '0.8rem',
    lineHeight: 'normal',
    overflow: 'hidden',

    '& .close-button': {
      display: 'none',
      pointerEvents: 'none',

      borderLeft: `1px solid ${COLORS.divider}`,
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      width: '1.75rem',
      alignSelf: 'stretch',

      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      background: COLORS.white,

      '&:hover': {
        svg: { fill: COLORS.red },
      },
    },

    '&:hover .close-button': {
      display: 'flex',
    },
  },

  tagName: {
    paddingRight: '0.4rem',
  },

  menu: {
    padding: '0.5rem',
    animation: `${animation} 100ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards`,
  },

  card: {
    minWidth: '15rem',
    maxWidth: '20rem',
    maxHeight: 400,
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    overflow: 'auto',
  },

  content: {
    overflowY: 'auto',
  },

  groupHeader: {
    padding: '0.3rem 0.75rem',
    fontWeight: 600,
    fontSize: '0.85rem',
    textTransform: 'uppercase',
    lineHeight: 'normal',
    letterSpacing: 0.75,
    marginTop: '0.5rem',

    '&:first-of-type': {
      marginTop: '0.25rem',
    },
  },

  groupRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0.75rem',
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-added': {
      fontWeight: 500,

      '.row-swatch': {
        boxShadow: `
          0 0 0 2px ${COLORS.white},
          0 0 0 4px var(--color)
        `,
      },

      '.close-button': {
        marginLeft: 'auto',
      },

      '&:hover .close-button svg': {
        transform: 'scale3d(1.25, 1.25, 1.25)',
        fill: COLORS.red,
      },
    },
  },

  glyph: {
    marginRight: '0.25rem',
  },

  search: {
    input: {
      borderRadius: 0,
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      boxShadow: 'none !important',
      borderColor: 'var(--input-border-color) !important',
    },
  },
}

export default TagsSelector
