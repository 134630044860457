import React from 'react'
import startCase from 'lodash/startCase'

import { useSettings } from '../../hooks'
import { titleCase } from '../../utils/functions'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const SOURCE: any = {
  internal: 'Current Facility',
  erx: 'eRX',
}

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ row, value }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} />,
  },
  {
    width: 130,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <TableCell value={titleCase(value)} />,
  },
  {
    width: 130,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ value }: any) => <TableCell value={titleCase(value)} />,
  },
  {
    width: 130,
    accessor: 'criticality',
    Header: 'Risk',
  },
  {
    width: 130,
    accessor: 'severity',
    Header: 'Severity',
  },
  {
    width: 130,
    accessor: 'source',
    Header: 'Source',
    Cell: ({ value }: any) => <TableCell value={SOURCE[value]} />,
  },
  {
    width: 130,
    accessor: 'reaction_type',
    Header: 'Reaction Type',
    Cell: ({ value }: any) => <TableCell value={titleCase(value)} />,
  },
  {
    width: 130,
    accessor: 'started_on',
    Header: 'Onset On',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
  },
  {
    width: 130,
    accessor: 'ended_on',
    Header: 'Resolved On',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  selectActions?: Function
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const AllergiesTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, localStorageKey, selectActions, batchActionsConfig, hiddenColumns, titleAfter } = props
  const { timezone } = useSettings()

  return (
    <Table
      showFilters
      title="Allergies"
      testKey="allergies_table"
      titleAfter={titleAfter}
      icon="treatment_data"
      data={data}
      columns={columns(to, timezone)}
      hiddenColumns={hiddenColumns}
      selectActions={selectActions}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No allergies added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

AllergiesTable.defaultProps = {
  localStorageKey: 'allergies',
}

export default AllergiesTable
