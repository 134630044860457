import React from 'react'

import Message from './Message'
import MessagesDateGroup from './MessagesGroup'
import InfiniteScrollLoader, { SLIDE_FROM } from '../InfiniteScrollLoader'
import Glyph from '../Glyph'

import { COLORS } from '../../theme'

const Messages = ({ timezone, messages, loading, page, meta, triggerMoreMessages, permissions }: any) => {
  const [ref, setRef] = React.useState(null)
  const atTop = React.useRef(false)
  const maxHeight = React.useRef(false)

  // update scrolling position when receving more messages
  React.useEffect(() => {
    if (!ref) return

    // scroll to the next section
    if (!loading && maxHeight.current) {
      const oldHeight = maxHeight.current
      const newHeight = ref.scrollHeight
      const difference = newHeight - oldHeight

      ref.scrollTop = difference
    }
  }, [loading])

  React.useEffect(() => {
    if (page !== 1) return
    if (!ref) return

    ref.scrollTop = ref.scrollHeight
    window.scrollTo(0, document?.body?.scrollHeight)
  }, [ref, page])

  const onScroll = (event: any) => {
    const isAtTheTop = event.target.scrollTop <= 75

    if (isAtTheTop && !atTop.current && triggerMoreMessages) {
      maxHeight.current = event.target.scrollHeight
      triggerMoreMessages()
    }

    atTop.current = isAtTheTop
  }

  if (!messages) return null

  const isLastPage = meta.page === meta.pages

  return (
    <div ref={setRef} css={styles} onScroll={onScroll}>
      {isLastPage && (
        <div css={{ textAlign: 'center', padding: '4rem 0 3rem' }}>
          <Glyph glyph="chat" color={COLORS.lightGray} css={{ marginBottom: 10 }} />
          <div className="text-text">This is the beginning of the chat history</div>
        </div>
      )}

      {Object.keys(messages).map((date) => (
        <MessagesDateGroup key={date} date={date} timezone={timezone}>
          {messages[date].map((message: any) => (
            <Message key={message.id} message={message} timezone={timezone} permissions={permissions} />
          ))}
        </MessagesDateGroup>
      ))}

      <InfiniteScrollLoader loading={loading} slideFrom={SLIDE_FROM.TOP} />
    </div>
  )
}

const styles = {
  position: 'relative',
  flex: '1 1 auto',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
}

export default Messages
