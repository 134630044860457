import React from 'react'

import { formatURL } from '../utils/functions'

import Button from './Button'
import Overlay from './Overlay'

export const IframeOverlay = (props: any) => {
  const {
    closeOnBackdrop = true,
    glyph,
    icon,
    maxWidth,
    onClose,
    position = 'right',
    showBackdrop,
    showExternalLink,
    title,
    subtitle,
    url,
  } = props

  const formattedURL = formatURL(url)

  if (!formattedURL) return null

  return (
    <Overlay
      fullheight
      stopPropagation
      showBackdrop={showBackdrop}
      closeOnBackdrop={closeOnBackdrop}
      position={position}
      onClose={onClose}
      maxWidth={maxWidth}
    >
      <Overlay.Header
        title={title}
        subtitle={subtitle}
        glyph={glyph}
        icon={icon}
        aside={showExternalLink && <Button label="Open in New Tab" target="_blank" useGlyphForTarget size={200} href={formattedURL} />}
      />

      <Overlay.Content style={{ position: 'relative', padding: '1rem', overflow: 'hidden' }}>
        <iframe
          title="iframe"
          src={formattedURL}
          frameBorder="0"
          border="0"
          cellSpacing="0"
          style={{
            borderStyle: 'none',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
        />
      </Overlay.Content>
    </Overlay>
  )
}
