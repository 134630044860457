import React from 'react'

const PageSectionTitle = ({ after, title, help, className }: any) => {
  return (
    <h3 css={styles(!!(after || help))} className={className}>
      {after ? <span>{title}</span> : title}
      {after}
      {help}
    </h3>
  )
}

const styles = (hasAfter: boolean) => ({
  margin: 0,
  fontSize: '1.2rem',
  fontWeight: 700,
  lineHeight: 1.3,

  ...(hasAfter && {
    display: 'flex',
    alignItems: 'center',
    margin: '-0.25rem',
    flexWrap: 'wrap',

    '& > *': {
      margin: '0.25rem',
    },
  }),
})

export default PageSectionTitle
