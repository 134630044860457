import React from 'react'
import { NavLink, Navigate, Route, Routes, useLocation } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { InviteStaffMemberAction } from '@behavehealth/constructs/Staff/InviteStaffMemberAction'
import { Page, Tab, TabList, Tabs } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

import ClientsUserInvites from './clients_user_invites'
import StaffUserInvites from './staff_user_invites'

import { ClientPortalInviteOverlay } from '@behavehealth/constructs/UserInvites/ClientPortalInviteOverlay'
import { EmployeeInviteOverlay } from '@behavehealth/constructs/UserInvites/EmployeeInviteOverlay'
import { NewEmployeeInviteOverlay } from '@behavehealth/constructs/UserInvites/NewEmployeeInviteOverlay'

const UserInvites: React.FC = () => {
  const location = useLocation()

  const actions = (
    <>
      <InviteStaffMemberAction link={`${location.pathname}/new`} />

      {/* {isBehave && (
        <Dropdown label="Invite Staff…" glyph="add" buttonType="primary" permission="employees.actions.invite">
          <DropdownItem label="Invite Staff Member…" icon="employees" color="blue" link={`${match.url}/invite`} />
          <DropdownItem label="Invite Many Staff Members…" glyph="stack" color="blue" link={`${match.url}/new-batch`} />
        </Dropdown>
      )} */}

      {/* {(isBehave || isManagement) && (
        <Button
          label="Invite Staff Member"
          type="primary"
          glyph="add"
          link={`${location.pathname}/new`}
          permission="employees.actions.invite"
        />
      )} */}
    </>
  )

  return (
    <>
      <Page feature="user_invites" breakpoint="0" actions={actions}>
        <Tabs>
          <TabList css={STYLES.tabsList}>
            <Tab as={NavLink} label="Staff" to="staff" />
            <Tab as={NavLink} label="Client Portal" to="client-portal" featureFlagV2="client_portal" />
          </TabList>
        </Tabs>

        <div className="!mt-[1em]">
          <Routes>
            <Route index element={<Navigate to="staff" replace />} />

            <Route path="staff/*" element={<StaffUserInvites />} />
            <Route path="client-portal/*" element={<ClientsUserInvites />} />
          </Routes>
        </div>
      </Page>

      <AnimatedRoutes>
        <Route path={`staff/new`} element={<NewEmployeeInviteOverlay useV6Router />} />
        <Route path={`client-portal/new`} element={<NewEmployeeInviteOverlay useV6Router />} />
        <Route path={`staff/:id`} element={<EmployeeInviteOverlay useV6Router />} />
        <Route path={`client-portal/:id`} element={<ClientPortalInviteOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const STYLES = {
  tabsList: {
    marginTop: '-1rem',
  },
}

export default withPageError(UserInvites)
