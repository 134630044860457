import React from 'react'

import Input from '../../../../components/Forms/Input'

export const NumberInputDefaultValue = (props: any) => {
  const { activeElement, editActiveElementFromInput } = props

  const { default_value } = activeElement.config

  return <Input label="Default Value" type="number" model="default_value" value={default_value} onUpdate={editActiveElementFromInput} />
}
