import { Liquid } from 'liquidjs'

import { VARIABLE_SUPPORT_FIELDS } from './constants'

const liquid = new Liquid()

export const parseQuoteVariables = async (data: any, variables: any) => {
  if (!data || !variables) return {}

  const dataToParse = {}

  // Parse variables for all supported fields
  for (const model of VARIABLE_SUPPORT_FIELDS) {
    if (data[model]) {
      const parseKey = `parsed_${model}`
      dataToParse[parseKey] = data[model]
    }
  }

  const parseResult: any = await liquid.parse(JSON.stringify(dataToParse))
  const renderResult = await liquid.render(parseResult, variables)
  const parsedData = renderResult ? JSON.parse(renderResult) : {}

  return parsedData
}
