import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { initializeStore } from './store'

const DragAndDropContext: React.FC = ({ children }) => {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>
}

export const WorksheetContext: any = React.createContext(null)

export const WorksheetProvider = ({ children, ...props }: any) => {
  const storeRef = React.useRef<any>()

  if (!storeRef.current) {
    storeRef.current = initializeStore(props)
  }

  return (
    <WorksheetContext.Provider value={storeRef.current}>
      <DragAndDropContext>{children}</DragAndDropContext>
    </WorksheetContext.Provider>
  )
}
