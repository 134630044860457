import { formatURL } from '../../utils/functions'

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'

export const formatShortLink = (domain?: string, code?: string) => {
  if (!domain || !code) return null

  if (IS_DEVELOPMENT) return `http://localhost:3700/${code}`

  return formatURL(`${domain}/${code}`)
}
