import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom-v5-compat'

import { mapToArray } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Button from '@behavehealth/components/Button'
import Divider from '@behavehealth/components/Divider'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

export const TodoPageActionsV6: React.FC<any> = ({ data, featureFlag }) => {
  const { pathname } = useLocation()

  const { data: templates }: any = useGet({
    name: 'todo-templates',
    url: `/event_templates`,
    params: { event_type: 'task' },
  })

  return (
    <>
      <Button
        as={Link}
        label="Manage Todo Templates"
        glyph="settings"
        type="default"
        link="/settings/todo-templates"
        permission="todo_templates.edit"
      />

      <Dropdown label="Add Todo…" glyph="add" buttonType="primary" buttonSize={300} permission="todos.create">
        <DropdownItem
          as={NavLink}
          label="New To-Do"
          glyph="add"
          color="paleBlue"
          link={`${pathname}/todo-builder`}
          state={{ data }}
          permission="todos.create"
          featureFlag={featureFlag}
        />

        <Divider />

        {mapToArray(templates).map((template) => {
          const { id, ...templateKeys } = template

          return (
            <DropdownItem
              as={NavLink}
              key={template.id}
              label={template.title}
              icon="tasks"
              color="paleBlue"
              link={`${pathname}/todo-builder`}
              state={{
                data: {
                  ...templateKeys,
                  ...data,
                  template_id: template.id,
                },
              }}
              permission="todos.create"
              featureFlag={featureFlag}
            />
          )
        })}
      </Dropdown>
    </>
  )
}
