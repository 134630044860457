import React from 'react'

const Main: React.FC<any> = ({ children }) => {
  return <div css={styles}>{children}</div>
}

const styles = {
  padding: '0.5rem 1rem 1rem',
}

export default Main
