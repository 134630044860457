import React from 'react'
import { v4 as uuid } from 'uuid'

import { useIsMounted } from '../hooks/useIsMounted'

const PARENT_ID = `behave-application-form-embed`
const SCRIPT_ID = `behave-application-form-${uuid}`
const SCRIPT_URL = 'https://embeds.behavehealth.com/ApplicationForm.js'

const createWidget = ({ tenant, timezone }: any) => {
  var s = document.createElement('script')
  s.id = SCRIPT_ID
  s.src = SCRIPT_URL
  s.async = true
  s.type = 'text/javascript'

  document.body.appendChild(s)

  s.addEventListener('load', () => {
    const ApplicationForm: any = window?.['ApplicationForm']

    if (ApplicationForm) {
      ApplicationForm.init({
        parentElement: PARENT_ID,
        tenant,
        timezone,
      })
    }
  })

  var x = document.getElementsByTagName('script')[0]
  x.parentNode?.insertBefore(s, x)
}

const removeWidget = () => {
  let element = document.getElementById(SCRIPT_ID)
  if (!element?.parentNode) return

  element.parentNode.removeChild(element)
}

export const QuickContactWidget = ({ tenant, timezone }: any) => {
  const isMounted = useIsMounted()
  const didCreate = React.useRef(false)

  React.useEffect(() => {
    if (!isMounted || !tenant || !timezone || didCreate.current) return

    createWidget({ tenant, timezone })
    didCreate.current = true

    return removeWidget
  }, [isMounted, tenant, timezone, didCreate.current])

  if (!tenant || !timezone) return null

  return <div id={PARENT_ID}></div>
}
