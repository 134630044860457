import React from 'react'
import clsx from 'clsx'

import { COLORS, GLYPHS } from '../theme'

import Flex from './Flex'
import Graphic from './Graphic'
import Loader from './Loader'

const StateSection = ({ testKey, icon, glyph, glyphColor, title, description, actions, help, isCompact }: any) => (
  <div data-test={testKey}>
    {(icon || glyph) && <Graphic icon={icon} glyph={glyph} size={22} glyphColor={glyphColor} css={{ marginBottom: '0.75rem' }} />}

    {title && <h1 css={{ textTransform: 'uppercase', fontSize: isCompact ? '1em' : '1.15em', letterSpacing: '1px' }}>{title}</h1>}

    {description && <div css={{ marginTop: '0.5em', color: COLORS.textMuted }}>{description}</div>}

    {actions && (
      <div css={{ marginTop: '1.25em' }}>
        <Flex vertical centerX centerY gap={8}>
          {actions}
        </Flex>
      </div>
    )}

    {help && <div className="!mt-4">{help}</div>}
  </div>
)

const State = (props: any) => {
  const {
    blockedDescription = 'Your account does not have permission to view this information',
    children,
    className,
    emptyActions,
    emptyDescription = 'No data found',
    errorActions,
    errorDescription = 'Something went wrong…',
    glyph,
    glyphColor,
    hasError,
    help,
    icon,
    isBlocked,
    isCompact,
    isEmpty,
    isLoading,
    loaderColor,
    minHeight = 300,
    testKey,
    title,
  } = props

  const isProcessing = isBlocked || isEmpty || isLoading || hasError

  const classNames = clsx('flex items-center justify-center z-[0] relative p-4', {
    'is-processing min-h-full': isProcessing,
    [className]: className,
  })

  if (!isProcessing) return children || null

  return (
    <div className={classNames}>
      <div
        className="flex flex-cols items-center justify-center w-full h-full max-w-[380px] leading-[1.5] text-center"
        style={{ minHeight }}
      >
        {isLoading ? (
          <Loader color={loaderColor} />
        ) : isBlocked ? (
          <StateSection glyph={GLYPHS.cross} glyphColor={glyphColor} title={title} description={blockedDescription} isCompact={isCompact} />
        ) : hasError ? (
          <StateSection
            glyph={GLYPHS.circle_error}
            glyphColor={glyphColor}
            title={title}
            description={errorDescription}
            actions={errorActions}
            isCompact={isCompact}
          />
        ) : isEmpty ? (
          <StateSection
            testKey={testKey}
            glyph={glyph}
            glyphColor={glyphColor}
            icon={icon}
            title={title}
            description={emptyDescription}
            actions={emptyActions}
            help={help}
            isCompact={isCompact}
          />
        ) : null}
      </div>
    </div>
  )
}

export default State
