import React from 'react'

import { COLORS } from '../../theme'

import Button from '../Button'
import Dropdown from '../Dropdown'
import DropdownItem from '../DropdownItem'
import Glyph from '../Glyph'

const TablePagination = (props: any) => {
  const { canNextPage, canPreviousPage, gotoPage, nextPage, pageIndex, pageCount, previousPage, isLoading = false } = props

  // React.useEffect(() => {
  //   if (pageIndex + 1 > pageCount) gotoPage(0)
  // }, [pageCount, gotoPage, pageIndex])

  if (pageCount === 1) return null

  const pages = Array.from({ length: pageCount })

  return (
    <div css={styles.nav}>
      <Button
        label="Back"
        type="minimal"
        size={200}
        before={<Glyph glyph="chevron_left" size={12} />}
        isDisabled={!canPreviousPage}
        onClick={() => previousPage()}
      />

      {!isLoading && (
        <Dropdown
          trigger={
            <div css={styles.dropdownLabel}>
              <span>Page </span>
              <strong>
                {pageIndex} <Glyph glyph="triangle_down" size={12} /> of {pageCount}
              </strong>
            </div>
          }
          css={styles.dropdown}
        >
          {pages.map((_, idx) => (
            <DropdownItem key={idx} label={`Page ${idx + 1}`} onClick={() => gotoPage(idx)} />
          ))}
        </Dropdown>
      )}

      <Button
        label="Next"
        type="minimal"
        size={200}
        after={<Glyph glyph="chevron_right" size={12} />}
        isDisabled={!canNextPage}
        onClick={() => nextPage()}
      />
    </div>
  )
}

const styles = {
  nav: {
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0.25em',
  },

  dropdown: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.85rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    margin: '0 0.75rem',

    svg: {
      marginLeft: '0.25rem',
    },
  },

  dropdownLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '0.85rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',

    span: {
      color: COLORS.textMuted,
      marginRight: '0.25rem',
    },

    strong: {
      display: 'inline-flex',
      alignItems: 'center',
      svg: { margin: '0 0.25rem' },
    },
  },
}

export default TablePagination
