import { CIWA_SYMPTOMS_FIELDS, VITAL_SIGNS_FIELDS } from './common'

export const CIWA_SECTIONS = [
  {
    title: 'Vital Signs',
    model: 'vital_signs',
    fields: VITAL_SIGNS_FIELDS,
  },
  {
    title: 'CIWA Symptoms',
    model: 'ciwa_symptoms',
    fields: CIWA_SYMPTOMS_FIELDS,
  },
]
