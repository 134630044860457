import { DateTime } from 'luxon'

import { DT } from '../../../utils/functions'

const isInLastNWeeks = (date: string, n: number, timezone: string) => {
  date = DT(date, timezone)
  if (!date) return null

  const compareDate = DateTime.local().minus({ weeks: n })

  if (date >= compareDate) return true
  else return false
}

const isInLastNMonths = (date: string, n: number, timezone: string) => {
  date = DT(date, timezone)
  if (!date) return null

  const compareDate = DateTime.local().minus({ months: n })

  if (date >= compareDate) return true
  else return false
}

const isExcelFile = (contentType: string) => {
  if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') return true
  else if (contentType === 'excel_file') return true
  else if (contentType === 'application/vnd.ms-excel') return true
  else if (contentType === 'text/csv') return true
  else return false
}

const isWordFile = (contentType: string) => {
  if (contentType === 'application/msword') return true
  else if (contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return true
  else return false
}

const isImageFile = (contentType: string) => {
  if (contentType === 'image/jpeg') return true
  else if (contentType === 'image/png') return true
  else if (contentType === 'image/bmp') return true
  else if (contentType === 'image/gif') return true
  else return false
}

const isAudioFile = (contentType: string) => {
  if (contentType === 'audio/x-m4a') return true
  else if (contentType === 'audio/mpeg') return true
  else if (contentType === 'audio/wav') return true
  else return false
}

const isPdfFile = (contentType: string) => {
  if (contentType === 'application/pdf') return true
  else return false
}

const isOtherFile = (contentType: string) => {
  const definedContentTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'excel_file',
    'application/vnd.ms-excel',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'text/csv',
    'image/jpeg',
    'image/png',
    'image/bmp',
    'image/gif',
    'audio/x-m4a',
    'audio/mpeg',
    'audio/wav',
  ]
  if (definedContentTypes.includes(contentType)) return false
  else return true
}

const isDraft = (status: string) => {
  if (status === 'client_draft' || status === 'employee_draft' || status === 'draft') return true
  else return false
}

const isPendingReview = (status: string) => {
  if (status === 'supervisor_pending_review' || status === 'employee_pending_review' || status === 'pending_review') return true
  else return false
}

const isUpdatesRequired = (status: string) => {
  if (status === 'client_updates_required' || status === 'employee_updates_required' || status === 'updates_required') return true
  else return false
}

const isSignedOff = (status: string) => {
  if (status === 'signed_off') return true
  else return false
}

export const filterTypes = (timezone: string) => ({
  boolean: (rows: any, id: string, filterValue: any) => {
    let value = filterValue

    if (value === 'true') value = true
    else if (value === 'false') value = false
    else if (value === 'null') value = null
    else if (value === 'undefined') value = undefined
    else if (value === '') value = undefined

    return rows.filter((row: any) => !!row.values[id] === !!value)
  },

  date: (rows: any, id: string, filterValue: string | undefined) => {
    if (filterValue === 'last_week') {
      return rows.filter((row: any) => isInLastNWeeks(row.values[id], 1, timezone))
    }

    if (filterValue === 'last_two_weeks') {
      return rows.filter((row: any) => isInLastNWeeks(row.values[id], 2, timezone))
    }

    if (filterValue === 'last_month') {
      return rows.filter((row: any) => isInLastNMonths(row.values[id], 1, timezone))
    }

    if (filterValue === 'last_3_months') {
      return rows.filter((row: any) => isInLastNMonths(row.values[id], 3, timezone))
    }

    if (filterValue === 'last_6_months') {
      return rows.filter((row: any) => isInLastNMonths(row.values[id], 6, timezone))
    }

    if (filterValue === 'last_12_months') {
      return rows.filter((row: any) => isInLastNMonths(row.values[id], 12, timezone))
    }

    return rows
  },

  contentType: (rows: any, id: string, filterValue: string | undefined) => {
    if (filterValue === 'excel') {
      return rows.filter((row: any) => isExcelFile(row.values.content_type))
    }
    if (filterValue === 'word') {
      return rows.filter((row: any) => isWordFile(row.values.content_type))
    }
    if (filterValue === 'image') {
      return rows.filter((row: any) => isImageFile(row.values.content_type))
    }
    if (filterValue === 'audio') {
      return rows.filter((row: any) => isAudioFile(row.values.content_type))
    }
    if (filterValue === 'pdf') {
      return rows.filter((row: any) => isPdfFile(row.values.content_type))
    }
    if (filterValue === 'other') {
      return rows.filter((row: any) => isOtherFile(row.values.content_type))
    }
    return rows
  },

  dataFormStatus: (rows: any, id: string, filterValue: string | undefined) => {
    if (filterValue === 'draft') {
      return rows.filter((row: any) => isDraft(row.values.status))
    }
    if (filterValue === 'pending_review') {
      return rows.filter((row: any) => isPendingReview(row.values.status))
    }
    if (filterValue === 'updates_required') {
      return rows.filter((row: any) => isUpdatesRequired(row.values.status))
    }
    if (filterValue === 'signed_off') {
      return rows.filter((row: any) => isSignedOff(row.values.status))
    }
    return rows
  },
})
