import React from 'react'
import isUndefined from 'lodash/isUndefined'

import { COLORS } from '../../../theme'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'
import { VendorQuickLinks } from '../../../constructs/VendorQuickLinks/VendorQuickLinks'
import { EmailDropdownMenu } from '../../../constructs/Common/EmailDropdownMenu'

export const EmailCell: React.FC = (props: any) => {
  const quickLinksVendorsConfig = props.config?.quickLinksVendors

  return (
    <DataTableCell hoverExpand {...props}>
      {props.value ? (
        <>
          <div className="flex items-center flex-nowrap truncate min-w-full">
            <a href={`mailto:${props.value}`} className="flex items-center flex-nowrap truncate grow pr-2">
              <span className="truncate grow">{props.value}</span>

              <VendorQuickLinks
                value={props.value}
                vendors={
                  !isUndefined(quickLinksVendorsConfig)
                    ? quickLinksVendorsConfig
                    : ['hubspot', 'google', 'gmail', 'google_calendar', 'helpscout', 'postmark']
                }
              />
            </a>

            <EmailDropdownMenu email={props.value} />
          </div>
        </>
      ) : (
        <EmptyCell />
      )}
    </DataTableCell>
  )
}
