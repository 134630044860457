import { useState } from 'react'
import { useParams } from 'react-router-dom'
import size from 'lodash/size'

import { useGet, useDelete } from './useNewAPI'

type Category =
  | 'clinical_assessment'
  | 'clinical_measurement'
  | 'discharge'
  | 'intake_form'
  | 'medical_assessment'
  | 'nursing'
  | 'outcome_measure'
  | 'physician_order'
  | 'progress_review'
  | 'recovery_coaching'
  | 'treatment_plan'

type Args = {
  category: Category
  clientID?: string
  subcategory?: string
  options?: any
}

export const useDataForms = ({ clientID, category, subcategory, options }: Args) => {
  const { resource_id = '' }: any = useParams()
  const client_id = clientID || resource_id

  const [filters, setFilters] = useState({})

  const queryParams = { category, subcategory, filters }

  const {
    data = {},
    isLoading,
    isRefetching,
  }: any = useGet({
    name: ['client', client_id, 'data_forms', queryParams],
    url: `/residents/${client_id}/data_forms`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: options || { enabled: !!category },
  })

  const { mutate: deleteRecords, isLoading: isDeleting } = useDelete({ name: ['client', client_id, 'data_forms'], url: '/data_forms' })

  return {
    data,
    filters,
    setFilters,
    isLoading: isLoading || isDeleting,
    isRefetching,
    isEmpty: size(data) === 0,
    batchDelete: async ({ ids }: any) => {
      await deleteRecords(ids.join(','))
    },
  }
}
