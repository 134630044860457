import React from 'react'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Flex from '@behavehealth/components/Flex'
import Tabs from '@behavehealth/components/Tabs'

import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import SmartStatus from '@behavehealth/components/SmartStatus'

const STATUSES = {
  success: {
    label: 'Success',
    color: 'green',
  },
}

const REPORT_FREQUENCIES = {
  month: {
    label: 'Monthly',
    color: 'gray',
  },
  quarter: {
    label: 'Quarterly',
    color: 'gray',
  },
  year: {
    label: 'Yearly',
    color: 'gray',
  },
}

const SYNC_STATUSES = {
  synced: {
    label: 'Synced',
    color: 'green',
  },
  processing: {
    label: 'Processing',
    color: 'yellow',
  },
  error: {
    label: 'Error',
    color: 'red',
  },
}

const ApAgingReportsTable = ({ frequency }: any) => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting', 'ap_aging_reports'],
    endpoint: `/accounting/ap_aging_reports`,
    params: { frequency },
    localStorageKey: 'accounting_ap_aging_reports_v1',
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Vendor',
        model: 'vendor_ref_name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.vendor_ref_name} />,
      },
      {
        title: 'Report Frequency',
        model: 'report_frequency',
        width: 160,
        disableHide: true,
        formatValue: ({ value }) => <SmartStatus statuses={REPORT_FREQUENCIES} status={value} />,
      },
      {
        title: 'Quickbooks Status',
        model: 'status',
        width: 160,
        disableHide: true,
        formatValue: ({ value }) => <SmartStatus statuses={STATUSES} status={value} />,
      },
      {
        title: 'Sync Status',
        model: 'sync_status',
        width: 160,
        formatValue: ({ value }) => <SmartStatus statuses={SYNC_STATUSES} status={value} />,
      },
      {
        title: 'Last Synced At',
        model: 'last_railz_sync_at',
        width: 160,
        type: 'date_time',
      },
      {
        title: 'Start Date',
        model: 'start_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
      {
        title: 'End Date',
        model: 'end_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
      {
        title: 'Current',
        model: 'current',
        width: 160,
        disableHide: true,
        type: 'number',
      },
      {
        title: '0-30 Days',
        model: 'dpd30',
        width: 160,
        disableHide: true,
        type: 'number',
      },
      {
        title: '31-60 Days',
        model: 'dpd60',
        width: 160,
        disableHide: true,
        type: 'number',
      },
      {
        title: '61-90 Days',
        model: 'dpd90',
        width: 160,
        disableHide: true,
        type: 'number',
      },
      {
        title: '90+ Days',
        model: 'dpd90_plus',
        width: 160,
        disableHide: true,
        type: 'number',
      },
      {
        title: 'Total Past Due',
        model: 'total',
        width: 160,
        disableHide: true,
        type: 'number',
      },
    ]
  }, [])

  return (
    <Grid gap="1rem" columns="100%">
      <DataTable
        asCard
        title="Accounts Payable"
        emptyDescription="No reports have been sync yet."
        filtersConfig={FILTERS_CONFIG}
        icon="accounting"
        columns={columns}
        {...tableProps}
        headerAfter={
          isBehave && (
            <Flex gap="0.5rem">
              <AccountingSyncButton
                forceShow={true}
                category="ap_aging"
                invalidate={tableProps.queryKey}
                refetchUrl={'/accounting/sync?category=ap_aging'}
              />
            </Flex>
          )
        }
      />
    </Grid>
  )
}

const RootApAgingReports = () => {
  return (
    <Page feature="accounting" title="Accounts Payable">
      <Tabs>
        <Tabs.List className="-mt-4 mb-3">
          <Tabs.Item as={NavLink} label="Monthly" to="monthly" />
          <Tabs.Item as={NavLink} label="Quarterly" to="quarterly" />
          <Tabs.Item as={NavLink} label="Yearly" to="yearly" />
        </Tabs.List>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to="monthly" replace />} />

        <Route path="monthly/*" element={<ApAgingReportsTable frequency="month" />} />
        <Route path="quarterly/*" element={<ApAgingReportsTable frequency="quarter" />} />
        <Route path="yearly/*" element={<ApAgingReportsTable frequency="year" />} />
      </Routes>
    </Page>
  )
}

export const ApAgingReports = withPageError(RootApAgingReports)

const FILTERS_CONFIG = {
  vendor_ref_name: {
    label: 'Vendor',
    type: 'string',
  },
  report_frequency: {
    label: 'Report Frequency',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'string',
  },
  start_date: {
    label: 'Start Date',
    type: 'string',
  },
  end_date: {
    label: 'End Date',
    type: 'string',
  },
  current: {
    label: 'Current',
    type: 'string',
  },
  dpd30: {
    label: '0-30 Days',
    type: 'string',
  },
  dpd60: {
    label: '31-60 Days',
    type: 'string',
  },
  dpd90: {
    label: '61-90 Day',
    type: 'string',
  },
  dpd90_plus: {
    label: '90+ Days',
    type: 'string',
  },
  total: {
    label: 'Total Past Due',
    type: 'string',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'date_time',
  },
}
