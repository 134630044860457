import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { InsuranceVobSummariesReportDataTable } from '@behavehealth/constructs/LiveReports/InsuranceVobSummariesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const InsuranceVobSummaries = () => {
  const tableProps = useDataTable({
    name: ['reports', 'insurance-vob-summaries'],
    endpoint: `/live_reports?category=vob_summaries`,
    localStorageKey: 'report_insurance_vob_summaries_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getClientLink(rowData.client)}/insurance`, [])

  return (
    <Page icon="insurance" title="Live VOB Summaries Report">
      <Grid>
        <InsuranceVobSummariesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(InsuranceVobSummaries)
