import React from 'react'

import Grid from '../../../components/Grid'

import FormSection from '../../../components/Forms/FormSection'
import OverlaySelector from '../../../components/Forms/Selectors/OverlaySelector/OverlaySelector'

import { Text } from '../../../components/Typography'

const CODE_TYPES = {
  cpt: 'CPT',
  hcpc_mod: 'HCPC/MOD',
  hcpcs: 'HCPCS',
}

const CODE_UNIT_TYPES = {
  per_unit: 'Per Unit',
  per_day: 'Per Day',
}

export const InlineInsuranceBillingFormSection = ({
  initialModel,
  isBillable = false,
  isRequired = true,
  feeScheduleModel = 'insurance_new_fee_schedule',
  model = 'insurance_new_fee_schedule_service',
}: any) => {
  if (!isBillable) return null

  const [feeSchedule, setFeeSchedule] = React.useState(initialModel?.[feeScheduleModel])
  const [insuranceCode, setInsuranceCode] = React.useState(initialModel?.[model])

  return (
    <FormSection maxWidth="100%">
      <OverlaySelector
        maxWidth="100%"
        label="Fee Schedule"
        type="insurance.fee_schedules"
        model={feeScheduleModel}
        icon="insurance"
        validations={
          isRequired && {
            presence: {
              message: 'Please select a Fee Schedule',
            },
          }
        }
        onUpdate={({ object }: any) => setFeeSchedule?.(object)}
        onClear={() => {
          setFeeSchedule?.(null)
          setInsuranceCode?.(null)
        }}
      />

      <OverlaySelector
        label="Insurance Code"
        type="insurance.fee_schedules_insurance_codes"
        model={model}
        icon="insurance"
        dependentValue={feeSchedule?.id}
        isDisabled={!feeSchedule?.id}
        onUpdate={(object: any) => setInsuranceCode(object)}
        onClear={() => setInsuranceCode(null)}
        selectTitle={(object) => object?.insurance_new_code?.service_name || '–'}
        validations={
          isRequired && {
            presence: {
              message: 'Please select an Insurance Code',
            },
          }
        }
        tooltip={
          insuranceCode && (
            <Grid gap="0.5rem">
              <Text muted label="Procedure Code: " description={insuranceCode.procedure_code || '–'} />
              <Text muted label="Revenue Code: " description={insuranceCode.revenue_code || '–'} />
              <Text muted label="Modifier Codes: " description={insuranceCode.modifier_codes?.map((o: any) => o.code).join(', ') || '–'} />
              <Text muted label="Unit Type: " description={CODE_UNIT_TYPES[insuranceCode.unit] || '–'} />
              <Text muted label="Code Type: " description={CODE_TYPES[insuranceCode.code_type] || '–'} />
              <Text muted label="Description: " description={insuranceCode.description || '–'} />
            </Grid>
          )
        }
      />
    </FormSection>
  )
}
