import React from 'react'

import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'

import Input from '../../../components/Forms/Input'
import Divider from '../../../components/Divider'

import { useSettings } from '../../../hooks/useSettings'

export const ZoomIntegrationSettings = ({ form, isEditable, initialModel, onValidationUpdate }) => {
  const { tenant } = useSettings()

  return (
    <>
      <Form
        getForm={form}
        initialModel={{ name: 'Zoom', ...initialModel }}
        isEditable={isEditable}
        onValidationUpdate={onValidationUpdate}
        linked={{
          provider: 'zoom',
          reference_id: tenant.id,
          reference_type: tenant.type,
          level: 'account',
          category: 'contacts',
        }}
      >
        <FormSection>
          <Input
            validations={{
              presence: {
                message: 'Please enter a name',
              },
            }}
            label="Name"
            model="name"
          />

          <Input
            validations={{
              presence: {
                message: 'Please enter a valid Zoom App Name',
              },
            }}
            label="Zoom App Name"
            model="config.app_name"
          />

          <Input
            validations={{
              presence: {
                message: 'Please enter a valid API Token',
              },
            }}
            label="API Token"
            model="config.token"
          />

          <Input
            validations={{
              presence: {
                message: 'Please enter a valid API Secret',
              },
            }}
            label="API Secret"
            model="config.secret"
          />

          <Input
            validations={{
              presence: {
                message: 'Please enter a valid API Webhook URL',
              },
            }}
            label="API Webhook URL"
            model="config.webhook_url"
          />
        </FormSection>
      </Form>
    </>
  )
}
