import React from 'react'

import { FormBuilderShareStatus } from '../../../constructs/FormBuilder/FormBuilderShareStatus'
import ApplicationStatus from '../../../components/Statuses/ApplicationStatus'
import Grid from '../../Grid'

import { usDate, usDateTime, titleCase } from '../../../utils/functions'
import { INSURANCE_CODE_TYPES, LOC } from '../../../utils/constants'

const CLIENTS_CONFIG = {
  icon: 'clients',
  endpoint: '/residents',
  selectTitle: (data: any) => data?.name,
  selectDescription: (data: any) => data?.behave_id,
}

const PROPERTIES_CONFIG = {
  icon: 'locations',
  endpoint: '/houses',
  selectTitle: (data: any) => data?.name,
  selectDescription: (data: any) => data?.id,
}

export const CONFIG: any = {
  clients: {
    all: {
      ...CLIENTS_CONFIG,
      label: 'All Clients',
    },
    current: {
      ...CLIENTS_CONFIG,
      label: 'Current Clients',
      params: { status: 'current' },
    },
    alumni: {
      ...CLIENTS_CONFIG,
      label: 'Alumni',
      icon: 'alumni_relations',
      params: { status: 'alumni' },
    },
    admissions: {
      ...CLIENTS_CONFIG,
      label: 'Applicants',
      icon: 'admissions',
      params: { status: 'lead,intake' },
    },
    admissions_and_current: {
      ...CLIENTS_CONFIG,
      label: 'Applicants and Current Clients',
      icon: 'clients',
      params: { status: 'lead,intake,current' },
    },
  },
  clients_by_status: {
    applicants: {
      ...CLIENTS_CONFIG,
      label: 'Applicants',
      icon: 'admissions',
      params: { status: 'lead' },
    },
    accepted: {
      ...CLIENTS_CONFIG,
      label: 'Accepted Clients',
      icon: 'admissions',
      params: { status: 'intake' },
    },
    declined: {
      ...CLIENTS_CONFIG,
      label: 'Declined Clients',
      icon: 'admissions',
      params: { status: 'declined' },
    },
    current: {
      ...CLIENTS_CONFIG,
      label: 'Current Clients',
      icon: 'clients',
      params: { status: 'current' },
    },
    alumni: {
      ...CLIENTS_CONFIG,
      label: 'Alumni',
      icon: 'alumni_relations',
      params: { status: 'alumni' },
    },
  },

  // Single-client resources
  client: {
    insurance_policies: {
      label: 'Insurance Policies',
      icon: 'insurance',
      endpoint: (id: any) => `/residents/${id}/insurance_policies`,
      selectTitle: (data: any) => data?.insurance_local_payer?.name,
      selectDescription: (data: any) => `${data?.plan || '–'}, ${data?.member_id || '–'}`,
      searchKeys: ['plan'],
    },
    insurance_authorizations: {
      label: 'Insurance Authorization',
      icon: 'authorizations',
      endpoint: (id: any) => `/residents/${id}/insurance_authorizations`,
      selectTitle: (data: any) => data?.identifier || data?.service_episodes?.map((o: any) => o.name),
      selectDescription: (data: any) => (
        <Grid gap="0.25rem">
          <div>Levels of Care: {data?.service_episodes?.map((o: any) => o.name)}</div>
          <div>
            Time covered: {usDate(data?.started_at)} to {usDate(data?.ended_at)}
          </div>
          <div>
            Sessions Authorized: {data?.sessions || 0} Used: {data?.used_sessions || 0}
          </div>
        </Grid>
      ),
      searchKeys: ['level', 'identifier'],
    },
    admissions: {
      label: 'Treatment Episode',
      icon: 'treatment_data',
      endpoint: (id: any) => `/residents/${id}/admissions`,
      selectTitle: (data: any) => usDateTime(data?.admitted_at),
      selectDescription: (data: any) => titleCase(data?.status),
      searchKeys: ['status'],
    },
    intake_applications: {
      label: 'Application Forms',
      icon: 'admissions',
      endpoint: (id: any) => `/residents/${id}/intake_applications`,
      selectTitle: (data: any) => usDateTime(data?.applied_at),
      selectDescription: (data: any) => <ApplicationStatus status={data.status} />,
      searchKeys: ['status'],
    },
    diagnoses: {
      label: 'Diagnosis',
      icon: 'diagnosis',
      endpoint: (id: any) => `/residents/${id}/diagnoses`,
      selectTitle: (data: any) => data?.code,
      selectDescription: (data: any) => data?.description,
      searchKeys: ['code', 'description'],
    },
  },

  properties: {
    all: {
      ...PROPERTIES_CONFIG,
    },
  },

  company: {
    providers: {
      label: 'Provider',
      icon: 'staff_credentials',
      endpoint: (id: any) => `/facilities/${id}/credentials?category=npi`,
      searchKeys: ['credential', 'first_name', 'last_name', 'employee_identifier'],
    },
    organization_providers: {
      label: 'Provider',
      icon: 'staff_credentials',
      endpoint: (id: any) => `/facilities/${id}/credentials?category=npi&level=organization`,
      searchKeys: ['credential', 'first_name', 'last_name', 'employee_identifier'],
    },
    individual_providers: {
      label: 'Provider',
      icon: 'staff_credentials',
      endpoint: (id: any) => `/facilities/${id}/credentials?category=npi&level=individual`,
      searchKeys: ['credential', 'first_name', 'last_name', 'employee_identifier'],
    },
    global_payment_methods: {
      label: 'Payment Method',
      icon: 'billing',
      endpoint: (id: any) => `/facilities/${id}/global_payment_methods?category=internal_billing`,
      searchKeys: ['name', 'notes'],
    },
  },

  // Employees
  employees: {
    label: 'Staff',
    icon: 'employees',
    endpoint: `/employees?status=active`,
    selectTitle: (data: any) => data?.name,
    selectDescription: (data: any) => titleCase(data?.position),
    searchKeys: ['name', 'position'],
  },

  // Organizations
  organizations: {
    label: 'Organizations',
    icon: 'organizations',
    endpoint: `/organizations`,
    selectTitle: (data: any) => data?.name,
    selectDescription: (data: any) => `${titleCase(data.category)} - ${titleCase(data.subcategory)}`,
    searchKeys: ['name', 'category', 'subcategory'],
  },

  organization: {
    contacts: {
      label: 'Organization Contacts',
      icon: 'contacts',
      endpoint: (id: any) => `/organizations/${id}/contacts`,
      selectTitle: (data: any) => data?.name,
      selectDescription: (data: any) => data?.role,
      searchKeys: ['name', 'role'],
    },
  },

  // Insurance
  insurance: {
    fee_schedules: {
      all: {
        label: 'Fee Schedule',
        icon: 'insurance',
        endpoint: `/insurance_fee_schedules`,
        selectTitle: (data: any) => data?.name,
        searchKeys: ['name'],
      },
    },

    new_fee_schedules: {
      all: {
        label: 'Fee Schedule',
        icon: 'insurance',
        endpoint: `/insurance_new_fee_schedules?status=active`,
        selectTitle: (data: any) => data?.name,
        searchKeys: ['name'],
      },

      insurance_new_codes: {
        label: 'Insurance Codes',
        icon: 'financials',
        endpoint: (id: string) => `/insurance_new_fee_schedules/${id}/insurance_new_codes?status=active`,
        selectTitle: (data: any) => `${data?.service_name} (${data?.procedure_code})`,
        selectDescription: (data: any) => `${INSURANCE_CODE_TYPES[data?.code_type]}`,
        searchKeys: ['procedure_code', 'service_name'],
      },
    },
  },

  insurance_local_payers: {
    label: 'Insurance Provider',
    icon: 'insurance',
    endpoint: `/insurance_local_payers?status=active`,
    selectTitle: (data: any) => data?.name,
    searchKeys: ['name'],
  },

  forms: {
    label: 'Forms',
    icon: 'web_form',
    endpoint: `/forms`,
    selectTitle: (data: any) => data?.name,
    selectDescription: (data: any) => <FormBuilderShareStatus status={data.use_public_sharing ? 'public' : 'private'} />,
    searchKeys: ['name'],
  },
}
