import React from 'react'
import clsx from 'clsx'

import { DEFAULT_EMPTY_VALUE } from '../utils/constants'
import { formatURL } from '../utils/functions'

import Glyph from './Glyph'

const LinkValue = ({ href, url, value, glyph, className }: any) => {
  const classNames = clsx(className, {
    'has-glyph': glyph,
  })

  return (
    <a href={href || formatURL(url)} css={styles.root} className={classNames} target="_blank" rel="noopener noreferrer">
      {glyph && <Glyph glyph={glyph} css={styles.glyph} size="1.25rem" />}
      {value || DEFAULT_EMPTY_VALUE}
    </a>
  )
}

const styles = {
  root: {
    '&.has-glyph': {
      display: 'inline-flex',
      alignItems: 'center',
    },

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  glyph: {
    marginRight: '0.5rem',
  },
}

export default LinkValue
