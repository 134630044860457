import { transparentize } from 'polished'

import COLORS from './colors'
import { FONTS } from './fonts'

export const getTypographyStyles = (variant: keyof typeof TYPOGRAPHY_STYLES) => {
  return TYPOGRAPHY_STYLES[variant] || TYPOGRAPHY_STYLES.DEFAULT
}

export const TYPOGRAPHY_VARIANTS = {
  DEFAULT: 'DEFAULT',

  H1: 'H1',
  H2: 'H2',
  H3: 'H3',
  H4: 'H4',
  H5: 'H5',
  H6: 'H6',

  TEXT: 'TEXT',
  TEXT_SMALL: 'TEXT_SMALL',
  TEXT_LARGE: 'TEXT_LARGE',

  CAPS_TITLE: 'CAPS_TITLE',
  CAPS_TITLE_SMALL: 'CAPS_TITLE_SMALL',

  CARD_TITLE: 'CARD_TITLE',
  CARD_QUOTE: 'CARD_QUOTE',
  CARD_SUBTITLE: 'CARD_SUBTITLE',

  SECTION_TITLE: 'SECTION_TITLE',
  SECTION_SUBTITLE: 'SECTION_SUBTITLE',

  BUTTON: 'BUTTON',
  BUTTON_SMALL: 'BUTTON_SMALL',
  BUTTON_LARGE: 'BUTTON_LARGE',

  META_DATA_TITLE: 'META_DATA_TITLE',
  META_DATA_SUBTITLE: 'META_DATA_SUBTITLE',

  FORM_LABEL: 'FORM_LABEL',
  FORM_VALUE: 'FORM_VALUE',

  DESCRIPTION_LIST_TITLE: 'DESCRIPTION_LIST_TITLE',
  DESCRIPTION_LIST_ITEM: 'DESCRIPTION_LIST_ITEM',

  QUOTE: 'QUOTE',
  STATUS: 'STATUS',
  NAV_ITEM: 'NAV_ITEM',
  DROPDOWN_ITEM: 'DROPDOWN_ITEM',
}

export const LINE_HEIGHTS = {
  NORMAL: 'normal',
  HEADING: 1.2,
  BODY: 1.4,
}

export const TYPOGRAPHY_STYLES = {
  // Default
  DEFAULT: {
    color: `white !important`,
    fontWeight: '700 !important',
    background: `${COLORS.default} !important`,
  },

  // Headings
  H1: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: LINE_HEIGHTS.HEADING,
  },
  H2: {
    fontSize: '1.75rem',
    fontWeight: 700,
    lineHeight: LINE_HEIGHTS.HEADING,
  },
  H3: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: LINE_HEIGHTS.HEADING,
  },
  H4: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: LINE_HEIGHTS.HEADING,
  },
  H5: {
    fontSize: '1.1rem',
    fontWeight: 600,
    lineHeight: LINE_HEIGHTS.HEADING,
  },
  H6: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: LINE_HEIGHTS.HEADING,
  },

  // Text
  TEXT: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  TEXT_SMALL: {
    fontSize: '0.95rem',
    fontWeight: 400,
  },
  TEXT_LARGE: {
    fontSize: '1.1rem',
    fontWeight: 400,
  },

  // Caps Title
  CAPS: {
    fontSize: '0.9rem',
    fontWeight: 700,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  CAPS_TITLE: {
    fontSize: '1.1rem',
    fontWeight: 700,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  CAPS_TITLE_SMALL: {
    fontSize: '1rem',
    fontWeight: 600,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },

  // Card
  CARD_TITLE: {
    fontSize: '1.1rem',
    fontWeight: 600,
    lineHeight: LINE_HEIGHTS.HEADING,
  },
  CARD_QUOTE: {
    fontSize: '1.1rem',
    fontWeight: 400,
    fontStyle: 'italic',
  },
  CARD_SUBTITLE: {
    fontSize: '1.05rem',
    fontWeight: 400,
    color: COLORS.textMuted,
  },

  // Section
  SECTION_TITLE: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: LINE_HEIGHTS.HEADING,
  },
  SECTION_SUBTITLE: {
    fontSize: '1.05rem',
    fontWeight: 500,
    color: COLORS.textMuted,
  },

  // Button
  BUTTON: {
    fontSize: '1.05rem',
    fontWeight: 600,
  },
  BUTTON_SMALL: {
    fontSize: '0.96em',
    fontWeight: 700,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  BUTTON_LARGE: {
    fontSize: '1.1rem',
    fontWeight: 600,
  },

  // Meta Data
  META_DATA_TITLE: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  META_DATA_SUBTITLE: {
    fontSize: '1rem',
    fontWeight: 400,
  },

  // Form
  FORM_LABEL: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  FORM_VALUE: {
    fontSize: '1rem',
    fontWeight: 400,
  },

  // Description List
  DESCRIPTION_LIST_TITLE: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  DESCRIPTION_LIST_ITEM: {
    fontSize: '1rem',
    fontWeight: 400,
  },

  // Quote
  QUOTE: {
    fontSize: '1.14rem',
    fontWeight: 400,
    fontStyle: 'italic',
  },

  // Status
  STATUS: {
    fontSize: '0.85rem',
    fontWeight: 600,
    lineHeight: LINE_HEIGHTS.NORMAL,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
    color: COLORS.black,
  },

  // Nav Item
  NAV_ITEM: {
    fontSize: '1rem',
    fontWeight: 500,
  },

  // Dropdown Item
  DROPDOWN_ITEM: {
    fontSize: '1.04rem',
    fontWeight: 500,
  },
}

const TYPOGRAPHY = {
  FONTS,

  headings: {
    label: {
      fontSize: '1rem',
      fontWeight: 600,
    },

    100: {
      fontSize: '0.95rem',
      fontWeight: 400,
      color: transparentize(0.25, COLORS.text),
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },

    200: {
      fontSize: '1rem',
      fontWeight: 600,
      letterSpacing: '0.75px',
      textTransform: 'uppercase',
    },

    300: {
      fontSize: '1.05rem',
      fontWeight: 700,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },

    400: {
      fontSize: '1.14rem',
      fontWeight: 600,
    },

    500: {
      fontSize: '1.2rem',
      fontWeight: 700,
    },

    600: {
      fontSize: '1.3rem',
      fontWeight: 700,
    },

    700: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },

    800: {
      fontSize: '1.6rem',
      fontWeight: 700,
    },

    900: {
      fontSize: '1.8rem',
      fontWeight: 700,
    },
  },

  text: {
    100: {
      fontSize: '0.8rem',
    },

    200: {
      fontSize: '0.9rem',
    },

    300: {
      fontSize: '0.95rem',
    },

    400: {
      fontSize: '1rem',
    },

    500: {
      fontSize: '1.1rem',
    },

    600: {
      fontSize: '1.2rem',
    },

    700: {
      fontSize: '1.3rem',
    },
  },
}

export default TYPOGRAPHY
