import React from 'react'
import { v4 as uuid } from 'uuid'
import get from 'lodash/get'
import isEqual from 'react-fast-compare'
import produce from 'immer'
import size from 'lodash/size'

import { titleCase, usDate } from '../../../../../utils/functions'
import { usePrevious } from '../../../../../hooks/usePrevious'
import { withFormContext } from '../../../../Forms/context'
import withSettings from '../../../../../hocs/withSettings'

import Button from '../../../../Button'
import CardTreeItem from '../../../../CardTreeItem'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import RadioGroup from '../../../../Forms/RadioGroup'
import Radio from '../../../../Forms/Radio'
import DataList from '../../../../DataList'
import DateInput from '../../../../Forms/DateInput'
import Flex from '../../../../Flex'
import FormSection from '../../../../Forms/FormSection'
import Grid from '../../../../Grid'
import Option from '../../../../Forms/Option'
import Section from '../../../../Section'
import Select from '../../../../Forms/Select'
import State from '../../../../State'
import Status from '../../../../Status'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import TreeItem from '../../../../TreeItem'

import { COLORS } from '../../../../../theme'
import { useFormField } from '../../../../Forms/hooks/useFormField'

const GoalsAndObjectivesV2 = ({ model, form, wileyData = [], isEditable, timezone }: any) => {
  const { formActions } = useFormField({
    model: model,
    form: form,
    defaultEmptyValue: [],
  })

  const [data, setData] = React.useState(wileyData)
  const prevWileyData = usePrevious(wileyData)

  const isEmpty = size(data) === 0

  const addGoal = () => {
    setData(
      produce(data, (draft: any) => {
        draft.push({
          id: `goal-${uuid()}`,
          status: 'inactive',
          description: '',
          objectives: [],
        })
      }),
    )
  }

  const addObjective = (index) => {
    setData(
      produce(data, (draft: any) => {
        if (!draft[index].objectives) {
          draft[index].objectives = []
        }

        draft[index].objectives.push({
          id: `objective-${uuid()}`,
          status: 'inactive',
          description: '',
          interventions: [],
        })
      }),
    )
  }

  const addIntervention = (goalIndex: any, objectiveIndex: any) => {
    setData(
      produce(data, (draft: any) => {
        if (!draft[goalIndex].objectives[objectiveIndex].interventions) {
          draft[goalIndex].objectives[objectiveIndex].interventions = []
        }

        draft[goalIndex].objectives[objectiveIndex].interventions.push({
          id: `intervention-${uuid()}`,
          description: '',
        })
      }),
    )
  }

  const removeGoal = (index: any) => {
    setData(
      produce(data, (draft: any) => {
        draft.splice(index, 1)
      }),
    )
  }

  const removeObjective = (goalIndex: any, objectiveIndex: any) => {
    setData(
      produce(data, (draft: any) => {
        draft[goalIndex].objectives.splice(objectiveIndex, 1)
      }),
    )
  }

  const removeIntervention = (goalIndex: any, objectiveIndex: any, interventionIndex: any) => {
    setData(
      produce(data, (draft: any) => {
        draft[goalIndex].objectives[objectiveIndex].interventions.splice(interventionIndex, 1)
      }),
    )
  }

  const updateGoal = (goalIndex: any, model: any, value: any) => {
    setData(
      produce(data, (draft: any) => {
        draft[goalIndex][model] = value
      }),
    )
  }

  const updateObjective = (goalIndex: any, objectiveIndex: any, model: any, value: any) => {
    setData(
      produce(data, (draft: any) => {
        draft[goalIndex].objectives[objectiveIndex][model] = value
      }),
    )
  }

  const updateIntervention = (goalIndex: any, objectiveIndex: any, interventionIndex: any, model: any, value: any) => {
    setData(
      produce(data, (draft: any) => {
        draft[goalIndex].objectives[objectiveIndex].interventions[interventionIndex][model] = value
      }),
    )
  }

  const renderAction = () => {
    if (!isEditable) return null
    return <Button type="default" glyph="add" label="Add Goal" onClick={addGoal} display="inline-flex" />
  }

  // ON MOUNT
  React.useEffect(() => {
    let localData = form?.getInitialInputFieldValue(model)
    if (localData) setData(localData)
  }, [])

  // UPDATE FIELD
  React.useEffect(() => {
    formActions.setValue(data)
  }, [JSON.stringify(data)])

  // ADD WILEY DATA
  React.useEffect(() => {
    if (isEqual(wileyData, prevWileyData)) return

    const newGoals: any = []

    for (const icd10 in wileyData) {
      const diagnosis = wileyData[icd10]

      for (const goal_id in diagnosis.goals) {
        const goal = diagnosis.goals[goal_id]
        if (!goal) continue

        const newObjectives: any = []

        for (const objective_id in goal.objectives) {
          const objective = goal.objectives[objective_id]
          if (!objective) continue

          const newInterventions: any = []

          for (const intervention_id in objective.interventions) {
            const intervention = objective.interventions[intervention_id]
            if (!intervention) continue

            newInterventions.push({
              id: `intervention-${uuid()}`,
              status: 'in_progress',
              description: intervention.description.description,
            })
          }

          newObjectives.push({
            id: `objective-${uuid()}`,
            status: 'in_progress',
            description: objective.description,
            ebt: objective.is_evidence_based,
            interventions: newInterventions,
          })
        }

        newGoals.push({
          id: `goal-${uuid()}`,
          status: 'inactive',
          description: goal.description,
          objectives: newObjectives,
        })
      }
    }

    setData(
      produce(data, (draft: any) => {
        draft.push(...newGoals)
      }),
    )
  }, [wileyData])

  if (isEmpty) {
    return <State isEmpty emptyDescription="No goals added yet" emptyActions={renderAction()} />
  }

  if (isEditable) {
    return (
      <div>
        {data?.map?.((goal: any, goalIndex: any) => (
          <Section
            key={goal.id}
            css={styles.editCard}
            title={`Goal #${goalIndex + 1}`}
            aside={
              isEditable && (
                <Button label="Remove" glyph="delete" type="minimal" color="red" size="small" onClick={() => removeGoal(goalIndex)} />
              )
            }
          >
            <FormSection labelWidth="120px" maxWidth="100%">
              <Select
                allowEmpty
                label="Status"
                value={data[goalIndex].status}
                onUpdate={(state) => updateGoal(goalIndex, 'status', state.value)}
              >
                <Option label="Inactive" value="inactive" />
                <Option label="In Progress" value="in_progress" />
                <Option label="Completed" value="completed" />
                <Option label="Extended" value="extended" />
                <Option label="Cancelled" value="cancelled" />
                <Option label="Unmet" value="unmet" />
              </Select>

              <SmartTextarea
                useDictation
                value={data[goalIndex].description}
                onUpdate={(state) => updateGoal(goalIndex, 'description', state.value)}
                label="Description"
              />

              {data[goalIndex]?.objectives?.map((objective: any, objectiveIndex: any) => (
                <Section
                  key={objective.id}
                  css={{ ...styles.editCard, margin: 0, marginLeft: 20, padding: '1rem' }}
                  title={`Objective #${goalIndex + 1}.${objectiveIndex + 1}`}
                  aside={
                    isEditable && (
                      <Button
                        label="Remove"
                        glyph="delete"
                        type="minimal"
                        color="red"
                        size="small"
                        onClick={() => removeObjective(goalIndex, objectiveIndex)}
                      />
                    )
                  }
                >
                  <FormSection labelWidth="120px">
                    <Flex gap={8} stretchChildrenX>
                      <Select
                        allowEmpty
                        label="Status"
                        value={data[goalIndex].objectives[objectiveIndex].status}
                        onUpdate={(state) => updateObjective(goalIndex, objectiveIndex, 'status', state.value)}
                      >
                        <Option label="In Progress" value="in_progress" />
                        <Option label="Extended" value="extended" />
                        <Option label="Cancelled" value="cancelled" />
                        <Option label="Completed" value="completed" />
                        <Option label="Unmet" value="unmet" />
                      </Select>

                      <RadioGroup
                        label="Evidence Based Treatment (EBT)"
                        layout="horizontal-dense"
                        value={data[goalIndex].objectives[objectiveIndex].ebt}
                        onUpdate={(state) => updateObjective(goalIndex, objectiveIndex, 'ebt', state.value)}
                      >
                        <Radio label="Yes" value={true} />
                        <Radio label="No" value={false} />
                      </RadioGroup>
                    </Flex>

                    <Flex gap={8}>
                      <DateInput
                        label="Start Date"
                        value={data[goalIndex].objectives[objectiveIndex].started_at}
                        onUpdate={(state) => updateObjective(goalIndex, objectiveIndex, 'started_at', state.value)}
                      />
                      <DateInput
                        label="Target Date"
                        value={data[goalIndex].objectives[objectiveIndex].target_at}
                        onUpdate={(state) => updateObjective(goalIndex, objectiveIndex, 'target_at', state.value)}
                      />
                    </Flex>

                    <SmartTextarea
                      useDictation
                      value={data[goalIndex].objectives[objectiveIndex].description}
                      onUpdate={(state) => updateObjective(goalIndex, objectiveIndex, 'description', state.value)}
                      label="Description"
                    />

                    {data[goalIndex]?.objectives[objectiveIndex]?.interventions?.map((intervention: any, interventionIndex: any) => (
                      <Section
                        key={intervention.id}
                        css={{ ...styles.editCard, margin: 0, marginLeft: 20, padding: '1rem' }}
                        title={`Intervention #${goalIndex + 1}.${objectiveIndex + 1}.${interventionIndex + 1}`}
                        aside={
                          isEditable && (
                            <Button
                              label="Remove"
                              glyph="delete"
                              type="minimal"
                              color="red"
                              size="small"
                              onClick={() => removeIntervention(goalIndex, objectiveIndex, interventionIndex)}
                            />
                          )
                        }
                      >
                        <FormSection layout="vertical">
                          <Select
                            allowEmpty
                            label="Status"
                            value={data[goalIndex].objectives[objectiveIndex].interventions[interventionIndex].status}
                            onUpdate={(state) => updateIntervention(goalIndex, objectiveIndex, interventionIndex, 'status', state.value)}
                          >
                            <Option label="In Progress" value="in_progress" />
                            <Option label="Extended" value="extended" />
                            <Option label="Cancelled" value="cancelled" />
                            <Option label="Completed" value="completed" />
                            <Option label="Unmet" value="unmet" />
                          </Select>

                          <Flex gap={8}>
                            <DateInput
                              label="Start Date"
                              value={data[goalIndex].objectives[objectiveIndex].interventions[interventionIndex].started_at}
                              onUpdate={(state) =>
                                updateIntervention(goalIndex, objectiveIndex, interventionIndex, 'started_at', state.value)
                              }
                            />
                            <DateInput
                              label="Target Date"
                              value={data[goalIndex].objectives[objectiveIndex].interventions[interventionIndex].target_at}
                              onUpdate={(state) =>
                                updateIntervention(goalIndex, objectiveIndex, interventionIndex, 'target_at', state.value)
                              }
                            />
                          </Flex>

                          <SmartTextarea
                            useDictation
                            value={data[goalIndex].objectives[objectiveIndex].interventions[interventionIndex].description}
                            onUpdate={(state) =>
                              updateIntervention(goalIndex, objectiveIndex, interventionIndex, 'description', state.value)
                            }
                            label="Description"
                          />
                        </FormSection>
                      </Section>
                    ))}

                    {isEditable && (
                      <Button
                        type="link"
                        glyph="add"
                        label="Add Intervention"
                        size="small"
                        onClick={() => addIntervention(goalIndex, objectiveIndex)}
                      />
                    )}
                  </FormSection>
                </Section>
              ))}

              {isEditable && <Button type="link" glyph="add" label="Add Objective" size="small" onClick={() => addObjective(goalIndex)} />}
            </FormSection>
          </Section>
        ))}

        {renderAction()}
      </div>
    )
  }

  return (
    <Grid gap="0.5rem">
      {data?.map?.((goal: any, goalIndex: number) => (
        <CardTreeItem
          isOpen
          key={goal.id}
          title={goal.description}
          subtitle={<Status small label={`Goal #${goalIndex + 1}: ${goal.status}`} color="blue" />}
        >
          {size(goal?.objectives) === 0 && <div css={styles.empty}>No objectives added yet</div>}

          {goal?.objectives?.map((objective: any, objectiveIndex: number) => (
            <TreeItem
              showDivider
              isOpen={objectiveIndex === 0}
              key={objective.id}
              title={<span css={styles.objectiveTitle}>{objective.description}</span>}
              subtitle={<Status small label={`Objective #${goalIndex + 1}.${objectiveIndex + 1}`} color="purple" />}
            >
              <DataList css={styles.dataList}>
                <DataList.Item label="Status" value={titleCase(objective.status)} />
                <DataList.Item label="Start Date" value={usDate(objective.started_at, timezone)} />
                <DataList.Item label="Target Date" value={usDate(objective.target_at, timezone)} />
                <DataList.Item label="Evidence Based Treatment (EBT)" value={objective.ebt ? 'Yes' : 'No'} />
              </DataList>

              {size(objective?.interventions) === 0 ? (
                <div className="-mt-3 mb-4 opacity-70 text-[13px] italic">No interventions added yet</div>
              ) : (
                <>
                  {objective?.interventions?.map?.((intervention: any, interventionIndex: number) => (
                    <TreeItem
                      isOpen
                      key={intervention.id}
                      title={intervention.description}
                      subtitle={
                        <Status
                          inline
                          small
                          label={`Intervention #${goalIndex + 1}.${objectiveIndex + 1}.${interventionIndex + 1}`}
                          color="orange"
                          css={styles.interventionStatus}
                        />
                      }
                    >
                      <DataList css={styles.dataList}>
                        <DataList.Item label="Status" value={titleCase(intervention.status)} />
                        <DataList.Item label="Start Date" value={usDate(intervention.started_at, timezone)} />
                        <DataList.Item label="Target Date" value={usDate(intervention.target_at, timezone)} />
                      </DataList>
                    </TreeItem>
                  ))}
                </>
              )}
            </TreeItem>
          ))}
        </CardTreeItem>
      ))}
    </Grid>
  )
}

GoalsAndObjectivesV2.defaultProps = {
  isEditable: false,
}

const styles = {
  editCard: {
    border: `2px solid ${COLORS.divider}`,
    borderRadius: 7,
    padding: '0.75rem',
  },

  empty: {
    textAlign: 'center',
    padding: '1.5rem 1rem',
    opacity: 0.6,
  },

  objectiveTitle: {
    fontWeight: 400,
    marginBottom: '0.25rem',
  },

  dataList: {
    fontSize: '0.9rem',
    paddingBottom: '1rem',
  },

  interventionsList: {
    '&::before': {
      display: 'none !important',
    },

    li: {
      marginBottom: '0.2rem',
    },
  },

  interventionStatus: {
    marginRight: '0.25rem',
  },
}

export default withFormContext(withSettings(GoalsAndObjectivesV2))
