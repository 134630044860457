import React from 'react'

import { APP_URLS } from '../MyAccount/utils'
import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'

import { QuoteReview } from './QuoteReview'

export const RootQuoteReviewOverlay = (props: any) => {
  const { close, data, isOverlayLoading } = useOverlay({
    name: 'quotes',
    endpoint: '/quotes',
    invalidate: 'quotes',
    options: props,
  })

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={70} />
  }

  return (
    <Overlay onClose={close} maxWidth={70}>
      <Overlay.Header
        title="Quote"
        icon="quotes"
        titleAside={
          <Button
            useGlyphForTarget
            label="Open Public Quote"
            glyph="link"
            target="_blank"
            className="!ml-2"
            size={100}
            href={`${APP_URLS.mini_apps}/quotes/${data.external_id}`}
          />
        }
      />

      <Overlay.Content className="p-5">
        <Card>
          <QuoteReview dataID={data?.id} />
        </Card>
      </Overlay.Content>
    </Overlay>
  )
}

export const QuoteReviewOverlay = withOverlayError(RootQuoteReviewOverlay)
