import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'

export const InsuranceClaimNotesReportDataTable = (props: any) => {
  const { to } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        model: 'insurance_claim.resident',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value?.name} avatar={value?.avatar} to={to?.(data)} />,
      },
      {
        title: 'Column Name',
        model: '__MODEL__',
        type: '__TYPE__',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Insurance Claim Notes"
      icon="insurance"
      columns={columns}
      // filtersConfig={FILTERS.__FILTERS_CONFIG__}
      {...props}
    />
  )
}
