import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
  billed: {
    label: 'Billed',
    color: 'blue',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus glyph="behave_health" glyphColor="black" statuses={statuses} {...rest} />
}

export default Status
