import React from 'react'
import { v4 as uuid } from 'uuid'
import cloneDeep from 'lodash/cloneDeep'

import RadioCheckBase from './RadioCheckBase'

export default class Radio extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      type: 'RADIO',
      id: `radio-${uuid()}`,
      value: props.value,
      isTouched: false,
      isUntouched: true,
    }
  }

  componentDidMount = () => {
    if (this.props.onUpdate) this.props.onUpdate(this.state)
  }

  componentDidUpdate = () => {
    if (this.props.onUpdate) this.props.onUpdate(this.state)
  }

  /*
    CUSTOM
  */
  onChange = () => {
    this.setState({ value: this.props.value })

    if (this.props.onChange) this.props.onChange(this.props.value)
  }

  onFocus = () => {
    if (this.state.isTouched) return
    this.setState({ isTouched: true, isUntouched: false })
  }

  /*
    RENDER
  */
  render() {
    const {
      after,
      children,
      description,
      glyph,
      glyphColor,
      group_id,
      icon,
      isDisabled,
      label,
      maxWidth,
      parentValue,
      showCheck = true,
      tooltip,
      value,
      testKey,
      className,
      graphic,
    } = this.props

    const { id } = this.state

    const $checked = parentValue === value

    return (
      <RadioCheckBase
        id={id}
        after={after}
        group_id={group_id}
        isChecked={$checked}
        label={label}
        onChange={this.onChange}
        onFocus={this.onFocus}
        type="radio"
        value={value}
        description={description}
        tooltip={tooltip}
        maxWidth={maxWidth}
        icon={icon}
        glyph={glyph}
        glyphColor={glyphColor}
        showCheck={showCheck}
        isDisabled={isDisabled}
        children={children}
        testKey={testKey || `radio_button_${value}`}
        className={className}
        graphic={graphic}
      />
    )
  }
}
