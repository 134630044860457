import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Page from '@behavehealth/components/Page'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import useStore from '@behavehealth/modules/store'

type Props = {
  canCreate: boolean
}

const Invoices: React.FC<Props> = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const tenant = useStore((state) => state.tenant)

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  return (
    <Page
      feature="accounting"
      title="Invoices"
      actions={<Button label="Add Invoice" glyph="add" type="primary" link={`${match.url}/new`} />}
    >
      {/* <div>Invoices</div> */}
    </Page>
  )
}

export default withPageError(Invoices)
