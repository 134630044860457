import React from 'react'
import Fuse from 'fuse.js'
import isEqual from 'lodash/isEqual'
import produce from 'immer'
import size from 'lodash/size'

import Badge from '../../Badge'
import Button from '../../Button'
import Flex from '../../Flex'

import { FilterDropdown } from './FilterDropdown'
import { PopoverItem } from './PopoverItem'
import { FilterDropdownSearch } from './FilterDropdownSearch'

import { useStore } from '../useStore'

export const MultiSelectFilter = (props: any) => {
  const { config, onUpdate, filter, filterKey } = props

  const [isOpen, setIsOpen] = React.useState(false)
  const [value, setValue]: any = React.useState(filter?.value)
  const [searchTerm, setSearchTerm]: any = React.useState('')

  const removeFilter: any = useStore((state: any) => state.removeFilter)

  const isEmpty = size(filter?.value) === 0

  const optionsMap: any = React.useMemo(() => {
    const result: any = {}

    if (size(config?.options) === 0) return result

    for (const option of config.options) {
      result[option.value] = option
    }

    return result
  }, [config?.options])

  React.useEffect(() => {
    if (!isEqual(value, filter?.value)) setValue(filter?.value)
  }, [filter?.value])

  const fuse = React.useMemo(() => {
    return new Fuse(config?.options, { keys: ['label'] })
  }, [config?.options, config?.searchKeys])

  const searchData = React.useMemo(() => {
    if (!searchTerm) return config?.options

    let searchResults: any[] = []
    let fuseResults = fuse.search(searchTerm)

    for (let i = 0; i < fuseResults.length; i++) {
      searchResults.push(fuseResults[i].item)
    }

    return searchResults
  }, [fuse, searchTerm])

  const clearSearch = () => {
    setSearchTerm('')
  }

  const handleApply = () => {
    onUpdate?.({ condition: 'in', value })
    setIsOpen(false)
  }

  const handleClear = () => {
    setValue([])
    removeFilter(filterKey)
    setIsOpen(false)
  }

  if (!config || size(config?.options) === 0) return null

  const { renderGraphic } = config

  const filtersCount = size(filter?.value)
  const hasActiveFilters = filtersCount >= 1
  const isSearchEmpty = size(searchData) === 0

  return (
    <>
      <FilterDropdown
        open={isOpen}
        onOpenChange={(newIsOpen: boolean) => {
          // apply filter when dropdown is closed and values are different
          if (!newIsOpen && !isEqual(value, filter?.value)) {
            onUpdate?.({ condition: 'in', value })
          }

          if (size(value) === 0) removeFilter(filterKey)

          setIsOpen(newIsOpen)
        }}
        testKey="multi_select_filter_dropdown"
        isSearchEmpty={isSearchEmpty}
        hasActiveFilters={hasActiveFilters}
        label={
          hasActiveFilters ? (
            <>
              <Badge size={16} children={filtersCount} className="mr-1" /> selected
            </>
          ) : (
            'Select…'
          )
        }
      >
        <FilterDropdownSearch value={searchTerm} onChange={setSearchTerm} onClear={clearSearch} />

        {searchData.map((option: any) => {
          if (option?.groupTitle) {
            return (
              <div className="uppercase font-[600] text-[0.82rem] first:!mt-2 mt-3 mb-1 pl-[1.25rem] text-text-muted opacity-80 tracking-[1px]">
                {option.groupTitle}
              </div>
            )
          }

          return (
            <PopoverItem
              key={option.value}
              title={option.label}
              isActive={value?.includes?.(option.value)}
              graphic={renderGraphic?.(option)}
              onClick={() => {
                setValue((currentValue: any = []) => {
                  return produce(currentValue, (draft: any) => {
                    if (draft.includes(option.value)) {
                      const index = draft.indexOf(option.value)
                      draft.splice(index, 1)
                      return
                    }

                    draft.push(option.value)
                  })
                })
              }}
            />
          )
        })}

        <Flex gap="0.5rem" className="p-2">
          <Button label="Apply" glyph="tick_circle" color="green" type="primary" size={100} className="flex-grow-3" onClick={handleApply} />
          <Button
            label="Clear All"
            glyph="cross"
            color="red"
            size={100}
            className="flex-grow-0"
            isDisabled={size(value) === 0}
            onClick={handleClear}
          />
        </Flex>
      </FilterDropdown>
    </>
  )
}
