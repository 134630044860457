import React from 'react'
import startCase from 'lodash/startCase'
import { useLocation } from 'react-router-dom'

import { usDateTime, titleCase } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import Status from '../Status'
import { Text } from '../Typography'

import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'

type Props = {
  file: any
  location: any
  timezone: string
  to: any
}

const File = ({ file, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  return (
    <CardLink to={to} testKey={startCase(file.title)} isActive={isActive}>
      <CardTitle title={startCase(file.title)} />
      <CardSubtitle subtitle={file.name} />
      <CardMeta>
        {file.subcategory && <Status color="vividBlue" label={titleCase(file.subcategory)} />}
        {file.content_type && <Status color="vividBlue" label={file.content_type} />}
        <Text glyph="date" description={usDateTime(file.updated_at, timezone)} />
        <Text avatar={file?.author?.avatar} label={file?.author?.name} />
        {file.file_tags?.length > 0 && <Text glyph="tag" label="Tags: " description={file.file_tags.map((tag) => tag.name).join(', ')} />}
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(File)
