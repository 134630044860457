import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useMatch } from 'react-router-dom-v5-compat'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { Button, HelpTagIframe, PageHeader, Tabs } from '@behavehealth/components'

import { ListsTable } from './lists_table'
import { ListsTimeline } from './lists_timeline'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'

const Phases: React.FC = () => {
  const { url } = useRouteURL()

  const match = useMatch(`${url}/:view`)
  const view = match?.params.view

  const [phasesCount, setPhasesCount] = React.useState(0)

  const actions = (
    <>
      {phasesCount >= 2 && (
        <Button as={Link} label="Reorder Lists" glyph="move_up_down" type="default" link={`${view}/reorder`} permission="programs.edit" />
      )}

      <Button as={Link} label="Add List" glyph="add" type="primary" link={`${view}/new`} permission="programs.edit" />
    </>
  )

  return (
    <div css={STYLES.root}>
      <PageHeader
        showFeedbackTool
        icon="program_lists"
        title="Program Lists"
        plan="lite"
        aside={actions}
        help={<HelpTagIframe id="program_lists" />}
      />

      <Tabs css={STYLES.tabs}>
        <Tabs.List css={STYLES.tabList}>
          <Tabs.Item as={NavLink} label="Today" icon="programs_table" to={'today'} />
          <Tabs.Item as={NavLink} label="Timeline" icon="programs_timeline" to={'timeline'} />
        </Tabs.List>
      </Tabs>

      <Routes>
        <Route path={'today/*'} element={<ListsTable setPhasesCount={setPhasesCount} />} />
        <Route path={'timeline/*'} element={<ListsTimeline setPhasesCount={setPhasesCount} />} />

        <Route index element={<Navigate to="today" replace />} />
      </Routes>
    </div>
  )
}

const STYLES: any = {
  root: {
    display: 'grid',
    gridTemplateRows: 'min-content min-content 1fr',
    gridTemplateColumns: '100%',
    overflow: 'hidden !important',
  },

  main: {
    padding: '1rem',
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    overflow: 'hidden !important',
  },

  tabs: {
    zIndex: 2,
  },

  tabList: {
    paddingLeft: '1rem',
  },
}

export default withPageError(Phases)
