import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../theme'

import Glyph from './Glyph'
import Iframe from './Iframe'

export const ARTICLES = {
  active_plans_healthcare_main_plan_coupon: {
    name: 'Healthcare Main Plan Special Programs',
    slug: 'active-plans-healthcare-main-plan-coupon',
  },
  active_plans_support_main_plan_coupon: {
    name: 'Support Main Plan Special Programs',
    slug: 'active-plans-support-main-plan-coupon',
  },
  active_plans_rcm_consumer_coupon: {
    name: 'RCM Consumer Special Programs',
    slug: 'active-plans-rcm-consumer-coupon',
  },
  active_plans_chat_coupon: {
    name: 'Chat Special Programs',
    slug: 'active-plans-chat-coupon',
  },
  active_plans_healthcare_account_setup_coupon: {
    name: 'Healthcare Account Setup Special Programs',
    slug: 'active-plans-healthcare-account-setup-coupon',
  },
  active_plans_training_coupon: {
    name: 'Training Special Programs',
    slug: 'active-plans-training-coupon',
  },
  active_plans_support_account_setup_coupon: {
    name: 'Support Account Setup Special Programs',
    slug: 'active-plans-support-account-setup-coupon',
  },
}

type Props = {
  id: keyof typeof ARTICLES
}

const CouponTagIframe: React.FC<Props> = ({ id }) => {
  const article = ARTICLES[id]
  if (!article) return null

  return (
    <Iframe
      title={article.name}
      url={`https://help.behavehealth.com/c/help/${article.slug}`}
      glyph="info"
      overlayPosition="right"
      overlayWidth={60}
      css={wrapperStyles}
    >
      <div css={styles}>
        <Glyph glyph="coupon" color={COLORS.orange} size="1.4em" />
        <span>Special Programs</span>
      </div>
    </Iframe>
  )
}

const wrapperStyles = {
  display: 'inline-flex',
  alignItems: 'center',
}

const styles: any = {
  display: 'inline-flex',
  alignItems: 'center',
  padding: '0.2em 0.7em 0.2em 0.25em',
  borderRadius: 100,
  background: tint(0.8, COLORS.yellow),

  color: COLORS.black,
  fontSize: '0.8rem',
  fontWeight: 600,
  letterSpacing: '0.5px',
  lineHeight: 1,
  textTransform: 'uppercase',
  cursor: 'pointer',

  svg: {
    marginRight: '0.25em',
  },
}

export default CouponTagIframe
