import React from 'react'
import isFinite from 'lodash/isFinite'
import isEqual from 'lodash/isEqual'

import { COLORS } from '../../../theme'
import { isDefined } from '../../../utils/functions'

import { DatePicker } from '../../../components/DatePicker'
import { DateRangeSelector } from '../../../components/Forms/DateRangeSelector'
import Button from '../../../components/Button'
import ButtonGroup from '../../../components/ButtonGroup'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'

import { FilterDropdown } from './FilterDropdown'

import { useStore } from '../useStore'
import { INPUT_STYLES } from '../styles'

const CONDITIONS: any = {
  between: 'Between',
  less_than: 'Less Than',
  greater_than: 'Greater Than',
  eq: 'Equal To',
}

const CONDITION_GLYPHS: any = {
  between: 'between',
  less_than: 'less_than',
  greater_than: 'greater_than',
  eq: 'equals',
}

export const NumberFilter: React.FC<any> = (props) => {
  const { filter, filterKey, onUpdate } = props

  const removeFilter: any = useStore((state: any) => state.removeFilter)

  const [isOpen, setIsOpen] = React.useState(false)

  const defaultCondition = CONDITIONS.hasOwnProperty(filter?.condition) ? filter?.condition : 'between'
  const [condition, setCondition] = React.useState(defaultCondition)

  const [firstNumber, setFirstNumber]: any = React.useState(filter?.condition === 'between' ? filter?.value?.from : filter?.value || 0)
  const [secondNumber, setSecondNumber]: any = React.useState(filter?.value?.until || 1)

  const handleApply = () => {
    const firstNumberInvalid = !isFinite(firstNumber)
    const secondNumberInvalid = condition === 'between' && !isFinite(secondNumber)

    if (firstNumberInvalid || secondNumberInvalid) return

    let updateValue: any = firstNumber

    if (condition === 'between') {
      updateValue = { from: firstNumber, until: secondNumber }
    }

    onUpdate?.({ value: updateValue, condition })
    setIsOpen(false)
  }

  const handleClear = () => {
    setFirstNumber(0)
    setSecondNumber(1)
    removeFilter(filterKey)
    setIsOpen(false)
  }

  const label = React.useMemo(() => {
    if (!filter) return null

    const conditionLabel = CONDITIONS?.[filter.condition]
    const firstNumber = parseFloat(filter.condition === 'between' ? filter.value?.from : filter.value)
    const secondNumber = parseFloat(filter.value?.until)

    if (!isFinite(firstNumber) || !conditionLabel) return null

    if (filter.condition === 'between' && isFinite(secondNumber)) {
      return `${conditionLabel} ${firstNumber} and ${secondNumber}`
    }

    return `${conditionLabel} ${firstNumber}`
  }, [filter])

  React.useEffect(() => {
    if (!filter) return
    if (filter?.condition && filter.condition !== condition) setCondition(filter.condition)

    if (filter?.condition === 'between') {
      if (isDefined(filter?.value?.from) && !isEqual(filter?.value?.from, firstNumber)) {
        setFirstNumber(filter?.value?.from)
      }

      if (isDefined(filter?.value?.until) && !isEqual(filter?.value?.until, secondNumber)) {
        setSecondNumber(filter?.value?.until)
      }

      return
    }

    if (!isEqual(filter?.value, firstNumber)) setFirstNumber(filter?.value)
  }, [filter])

  return (
    <FilterDropdown
      open={isOpen}
      onOpenChange={(newIsOpen: boolean) => {
        if (!newIsOpen) handleApply() // apply filter when dropdown is closed

        setIsOpen(newIsOpen)
      }}
      testKey="numbers_filter_dropdown"
      hasActiveFilters={!!label}
      label={label || 'Select…'}
    >
      <div className="px-3 py-1.5 border-b border-solid border-0 border-divider flex justify-center">
        <ButtonGroup>
          {Object.keys(CONDITIONS).map((key) => (
            <Button
              key={key}
              size={100}
              color="gray"
              label={CONDITIONS[key]}
              type={condition === key ? 'primary' : 'default'}
              onClick={() => {
                setCondition(key)
              }}
              graphic={<Glyph glyph={CONDITION_GLYPHS[key]} color={COLORS.textMuted} size={16} />}
            />
          ))}
        </ButtonGroup>
      </div>

      <div className="flex flex-nowrap justify-center px-3 py-3">
        <input
          type="number"
          css={INPUT_STYLES}
          className="max-w-[80px] !min-h-[1.75rem]"
          value={isNaN(firstNumber) ? '' : firstNumber}
          onChange={(event) => {
            const inputValue = parseFloat(event.target.value)

            if (isNaN(inputValue)) {
              setFirstNumber(0)
              return
            }

            setFirstNumber(inputValue)
          }}
        />

        {condition === 'between' && (
          <>
            <div css={STYLES.arrowSegment}>→</div>

            <input
              type="number"
              css={INPUT_STYLES}
              className="max-w-[80px] !min-h-[1.75rem]"
              value={isNaN(secondNumber) ? '' : secondNumber}
              onChange={(event) => {
                const inputValue = parseFloat(event.target.value)

                if (isNaN(inputValue)) {
                  setSecondNumber(null)
                  return
                }

                setSecondNumber(inputValue)
              }}
            />
          </>
        )}
      </div>

      <Flex gap="0.5rem" className="p-2">
        <Button
          label="Apply"
          glyph="tick_circle"
          color="green"
          type="primary"
          size={100}
          className="flex-grow-3"
          onClick={handleApply}
          // isDisabled={!startDate || condition === 'between' ? !endDate : false}
        />
        <Button label="Clear Filter" glyph="cross" color="red" size={100} className="flex-grow-0" onClick={handleClear} />
      </Flex>
    </FilterDropdown>
  )
}

const STYLES = {
  dropdown: {
    width: 'auto',
  },

  arrowSegment: {
    opacity: 0.6,
    color: COLORS.textMuted,
    padding: '0 0.5rem',
  },
}
