import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'

import { titleCase, address } from '@behavehealth/utils/functions'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import {
  Accordion,
  AddressSelectorInput,
  Alert,
  Checkbox,
  CheckboxGroup,
  Chotomate,
  ContextShow,
  DescriptionItem,
  DescriptionList,
  Divider,
  EmailInput,
  Flex,
  Form,
  FormSection,
  Grid,
  HelpTagIframe,
  Input,
  MessageBoard,
  MultiObjectSelector,
  Option,
  Page,
  Permission,
  PhoneInput,
  Select,
  Textarea,
} from '@behavehealth/components'

const GeneralInfo: React.FC = () => {
  const { resource_id: id }: any = useParams()

  const { data: location, isLoading }: any = useGet({
    name: ['location', id],
    url: `/houses/${id}`,
  })

  const { mutateAsync: updateLocation }: any = useUpdate({
    name: ['location', id],
    url: `/houses/${id}`,
    invalidate: ['locations'],
  })

  if (!location || isLoading) return null

  return (
    <Page feature="general_info" help={<HelpTagIframe id="location_general_info" />}>
      <Grid gap="1.5rem">
        <Chotomate name="general_info" ready={location} />

        <Permission featureFlagV2="message_board">
          <MessageBoard name={['property', location.id, 'messages']} url={`/houses/${location.id}/messages`} />
        </Permission>

        <div>
          {/* Location Details */}
          <Accordion
            isOpen
            title="Location Details"
            initialModel={location}
            glyph="property_details"
            activateEditMode
            onSubmit={updateLocation}
            permission="properties.general_info.property_details.view"
            editPermission="properties.general_info.property_details.edit"
          >
            <Form>
              <FormSection labelWidth="180px">
                <Input label="Location Name" model="name" />

                <Flex gap="1rem">
                  <Select
                    className="!flex-1"
                    label="Category"
                    model="category"
                    defaultValue="housing"
                    validations={{
                      presence: {
                        message: 'Please select a use',
                      },
                    }}
                  >
                    <Option label="Housing" value="housing" />
                    <Option label="Office" value="office" />
                    <Option label="Mixed (Housing & Office)" value="mixed_category" />
                  </Select>

                  <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="faded">
                    <Checkbox label="Enable Healthcare" model="insurance_billing_enabled" />
                  </CheckboxGroup>
                </Flex>

                <ContextShow when="category" within={['housing', 'mixed_category']}>
                  <Select label="Accepted Gender" model="gender">
                    <Option label="Male" value="male" />
                    <Option label="Female" value="female" />
                    <Option label="Mixed" value="mixed" />
                  </Select>
                </ContextShow>

                <ContextShow when="insurance_billing_enabled" is={true}>
                  <Select label="Organization Type" model="organization_type" defaultValue="facility">
                    <Option label="Facility" value="facility" />
                    <Option label="Practice" value="practice" />
                  </Select>
                </ContextShow>

                <ContextShow when="organization_type" is="practice">
                  <Select label="Practice Type" model="practice_type" defaultValue="solo_practice">
                    <Option label="Solo Practice" value="solo_practice" />
                    <Option label="Partnership" value="partnership" />
                    <Option label="Professional Association" value="professional_association" />
                    <Option label="Clinic" value="clinic" />
                    <Option label="Hospital / Single Entity Facility" value="hospital" />
                    <Option label="Hospital Unit / Distinct Part Facility" value="hospital_unit" />
                    <Option label="Individual (Corporation filling under an Individual)" value="individual" />
                  </Select>
                </ContextShow>

                <MultiObjectSelector
                  model="employees"
                  label="Assigned Staff"
                  blankLabel="Select Staff…"
                  type="employees"
                  icon="employees"
                />
              </FormSection>
            </Form>
          </Accordion>

          {location?.insurance_billing_enabled && (
            <Accordion
              glyph="insurance_information"
              initialModel={location}
              title="Healthcare Details"
              activateEditMode
              onSubmit={updateLocation}
              permission="properties.general_info.healthcare_details.view"
              editPermission="properties.general_info.healthcare_details.edit"
            >
              <Form>
                <FormSection labelWidth="180px">
                  <Flex stretchChildrenX gap="1rem">
                    <Input label="NPI" model="npi" />
                    <Input label="Taxonomy" model="taxonomy" />
                  </Flex>

                  <Divider className="!m-0" />

                  <Flex stretchChildrenX gap="1rem">
                    <Input label="Tax ID" model="tax_identifier" />
                    <Input label="Site ID" model="site_identifier" />
                  </Flex>

                  <Flex stretchChildrenX gap="1rem">
                    <Input label="BlueCross ID" model="bluecross_identifier" />
                    <Input label="BlueShield ID" model="blueshield_identifier" />
                  </Flex>

                  <Flex stretchChildrenX gap="1rem">
                    <Input label="Medicare ID" model="medicare_identifier" />
                    <Input label="Medicaid ID" model="medicaid_identifier" />
                  </Flex>
                </FormSection>
              </Form>
            </Accordion>
          )}

          <Accordion
            glyph="contact_details"
            initialModel={location}
            title="Contact Details"
            activateEditMode
            onSubmit={updateLocation}
            permission="properties.general_info.contact_details.view"
            editPermission="properties.general_info.contact_details.edit"
          >
            <Form>
              <FormSection labelWidth="180px">
                <EmailInput label="Email" model="email" />

                <Flex gap="1rem">
                  <PhoneInput className="!flex-auto" label="Phone Number" model="phone_no" />
                  <Input size={5} glyph="phone_ext" label="Phone Ext" model="phone_no_ext" />
                </Flex>
              </FormSection>
            </Form>
          </Accordion>

          {/* Address */}
          <Accordion title="Address" glyph="address" permission="properties.general_info.address.view">
            <FormSection labelWidth="150px">
              <AddressSelectorInput
                value={location?.address}
                actionLabel="Update Location Address"
                permission="properties.general_info.address.edit"
                coords={{
                  lat: location?.lat,
                  lon: location?.lon,
                }}
                afterAddressSelect={(address) =>
                  updateLocation({
                    address: address,
                    lat: address?.coords?.lat,
                    lon: address?.coords?.lon,
                  })
                }
              />
            </FormSection>
          </Accordion>

          {/* Automatic Check-In/Out */}
          {/* <Accordion
            activateEditMode
            glyph="map"
            initialModel={record}
            title="Automatic Check-In/Out"
            onSubmit={(data) => apiUpdate({ name: 'locations', url: `/houses/${record.id}`, params: data })}
          >
            <Form>
              <FormSection>
                <CheckboxGroup trueIcon="check" falseStyle="faded" falseIcon="cross">
                  <Checkbox
                    label="Enable Automatic Client Check-In/Out"
                    description="Check-in clients who accepted live location tracking when they are at this property for more than 3 minutes and check them out after leaving."
                    model="has_automatic_checkin"
                  />
                </CheckboxGroup>

                <Alert glyph="info">
                  When enabled, all clients who have this option as well will be automatically checked in when visiting this property and
                  checked out when they leave.
                </Alert>
              </FormSection>
            </Form>
          </Accordion> */}

          {/* Client Portal Share */}
          <Accordion
            glyph="portal"
            initialModel={location}
            title="Client Portal Details"
            featureFlag="client_portal"
            newFeatureFlag="client_portal"
            featureFlagV2="client_portal"
            activateEditMode
            onSubmit={updateLocation}
            permission="properties.general_info.client_portal_details.view"
            editPermission="properties.general_info.client_portal_details.edit"
          >
            <Form>
              <FormSection labelWidth="150px">
                <Textarea useQuickText model="portal_notes" label="Portal Notes" minRows={4} maxWidth="100%" />

                <CheckboxGroup label="Access" trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox label="Share details below with the Client Portal?" model="is_shared_with_portal" />

                  <Alert type="neutral" className="!my-8 !mx-0">
                    <DescriptionList>
                      <DescriptionItem title="Name" description={location?.name} />
                      <DescriptionItem title="Accepted Gender" description={titleCase(location?.gender)} />
                      <DescriptionItem title="Phone Number" description={location?.phone_no} />
                      <DescriptionItem title="Address" description={address(location?.address)} />
                      <DescriptionItem
                        title="Assigned Staff"
                        description={location?.employees.map((employee) => employee.name).join(', ')}
                      />
                      <DescriptionItem title="Notes" description={location?.portal_notes} />
                    </DescriptionList>
                  </Alert>
                </CheckboxGroup>
              </FormSection>
            </Form>
          </Accordion>

          {/* Additional Information */}
          <Accordion
            glyph="additional_information"
            initialModel={location}
            title="Additional Information"
            activateEditMode
            onSubmit={updateLocation}
            permission="properties.general_info.other_information.view"
            editPermission="properties.general_info.other_information.edit"
          >
            <Form>
              <Textarea useQuickText model="additional_info" label="Additional Info" minRows={4} maxWidth="100%" />
            </Form>
          </Accordion>
        </div>
      </Grid>
    </Page>
  )
}

export default withPageError(GeneralInfo)
