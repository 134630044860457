import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { countWord, mapToArray } from '../../utils/functions'
import { css } from '../../theme'
import { useCreate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import Notifications from '../../modules/notifications'

import Button from '../../components/Button'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import useAnalytics from '../../hooks/useAnalytics'

import { QUICK_ADD } from '../../components/Worksheet/config'
import { Worksheet } from '../../components/Worksheet/Worksheet'

export const MedicationWorksheetOverlay = (props: any) => {
  const { onClose, onSuccess, invalidateKeys, initialData } = props

  const { tenant } = useSettings()
  const { trackEvent } = useAnalytics()

  const form = React.useRef()
  const history = useHistory()
  const location = useLocation()

  const [isValid, setIsValid] = React.useState(false)
  const [rowsCount, setRowsCount] = React.useState(0)

  const { mutate: save, isLoading: isSaving }: any = useCreate({
    name: ['create-meds'],
    url: `/meds/batch`,
    invalidate: 'meds',
    invalidateKeys: invalidateKeys,
    onSuccess: () => {
      Notifications.send(`Successfully created ${countWord('Medications', rowsCount)}`, 'positive')

      trackEvent({ name: 'Worksheet Saved', params: { worksheetType: 'Medication' } })

      if (onSuccess) onSuccess()
      if (onClose) onClose()
    },
  })

  const close = () => {
    if (onClose) return onClose()

    const url = location?.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    history.push(url)
  }

  const handleSave = async () => {
    const data = form.current.getFormValue()

    await save(mapToArray(data))

    close()
  }

  React.useEffect(() => {
    trackEvent({ name: 'Worksheet Opened', params: { worksheetType: 'Medication' } })
  }, [])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        model: 'resident',
        type: 'object_selector',
        config: {
          quickAdd: QUICK_ADD.all_clients,
          isPolymorphic: true,
          shouldHideSelected: false,
          showHideSelectedToggle: true,
          endpoint: '/residents',
          queryKey: 'residents',
          queryParams: { status: 'current' },
          selectTitle: (data: any) => data.name,
          validations: {
            presence: {
              message: 'Please select a client',
            },
          },
        },
      },
      {
        title: 'Status',
        model: 'status',
        type: 'select',
        config: {
          options: [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
          ],
          validations: {
            presence: {
              message: 'Please select a status',
            },
          },
        },
      },
      {
        title: 'Source',
        model: 'source',
        type: 'select',
        config: {
          options: [
            { label: tenant?.name, value: 'internal' },
            { label: 'eRX (Dr.First)', value: 'erx' },
            { label: 'External Provider (during treatment)', value: 'external_during' },
            { label: 'External Provider (prior to treatment)', value: 'external_prior' },
            { label: 'Private Providers', value: 'private_provider' },
          ],
          validations: {
            presence: {
              message: 'Please select a source',
            },
          },
        },
      },
      {
        title: 'Medication Name',
        model: 'name',
        config: {
          validations: {
            presence: {
              message: 'Please enter a medication name',
            },
          },
        },
      },
      {
        title: 'Medication Description',
        model: 'description',
        type: 'select',
        config: {
          options: [
            { label: 'Medical', value: 'medical' },
            { label: 'Psychiatric', value: 'psychiatric' },
            { label: 'Taper', value: 'taper' },
            { label: 'Palliatives', value: 'palliatives' },
            { label: 'MAT (Vivitrol, Suboxone, etc)', value: 'mat' },
            { label: 'Vaccine', value: 'vaccine' },
          ],
          validations: {
            presence: {
              message: 'Please select a description',
            },
          },
        },
      },
      {
        title: 'Dosage',
        model: 'dosage',
        type: 'number',
        config: {
          validations: {
            presence: {
              message: 'Please enter a dosage',
            },
          },
        },
      },
      {
        title: 'Unit Type',
        model: 'unit',
        config: {
          validations: {
            presence: {
              message: 'Please enter a unit',
            },
          },
        },
      },
      {
        title: 'Quantity',
        model: 'quantity',
        type: 'number',
        width: 150,
        config: {
          validations: {
            presence: {
              message: 'Please enter a quantity',
            },
          },
        },
      },
      {
        title: 'Start Date',
        model: 'started_at',
        type: 'date_time',
        config: {
          defaultTo: 'now',
          validations: {
            presence: {
              message: 'Please enter a start date',
            },
          },
        },
      },
      {
        title: 'End Date',
        model: 'ended_at',
        type: 'date_time',
        config: {
          validations: {
            presence: {
              message: 'Please enter an end date',
            },
          },
        },
      },
      {
        title: 'Medication Time',
        model: 'medication_time',
        type: 'multi_select',
        config: {
          options: [
            { label: 'AM', model: 'am' },
            { label: 'PM', model: 'pm' },
            { label: 'Morning', model: 'morning' },
            { label: 'Afternoon', model: 'afternoon' },
            { label: 'Evening', model: 'evening' },
            { label: 'Bedtime', model: 'bedtime' },
            { label: 'PRN', model: 'prn' },
          ],
        },
      },
      {
        title: 'Prescription #',
        model: 'prescription_no',
      },
      {
        title: 'Prescribing Physician',
        model: 'prescription_by',
      },
      {
        title: 'Instructions / Notes',
        model: 'notes',
        type: 'textarea',
      },
      {
        title: 'Refill Notes',
        model: 'refill',
        type: 'textarea',
      },
    ] as const
  }, [tenant])

  return (
    <Overlay showBackdrop onClose={onClose} position="center" maxWidth={140}>
      <Overlay.Header title="Create Medications" icon="medication" />

      <Overlay.Content className="!p-4">
        <Grid gap="1rem" className="!pb-3">
          <Worksheet
            asCard
            getForm={form}
            title="Medications"
            allow="create-update-delete"
            columns={columns}
            onValidationUpdate={setIsValid}
            onRowsCountUpdate={setRowsCount}
            initialData={initialData}
          />
        </Grid>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Save ${countWord('Medication', rowsCount)}`}
          glyph="check"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={isSaving}
          isDisabled={!isValid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
