import React from 'react'

import { usDate } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'
import { withFormContext } from '../../../components/Forms/context'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import Status from '../../../components/Status'
import Tooltip from '../../../components/Tooltip'

const SelectDescription = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <Flex gap="0.25rem">
      <Status label={data.category} color="gray" />

      <div>
        <span style={{ fontWeight: 600 }}>ID #:</span> {data.member_id || '–'}
      </div>

      <div>
        <span style={{ fontWeight: 600 }}>Effective Date:</span> {usDate(data.effective_at, timezone)}
      </div>
      <div>
        <span style={{ fontWeight: 600 }}>Reset Date:</span> {usDate(data.resets_at, timezone)}
      </div>
      <div>
        <span style={{ fontWeight: 600 }}>Termination Date:</span> {usDate(data.terminates_at, timezone)}
      </div>
    </Flex>
  )
}

const ClaimInsurancePolicies = ({ className, client, onUpdate }: any) => {
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle title="Insurance Policies" css={styles.cardTitle} />
      </CardHeader>

      <CardContent css={styles.cardContent}>
        <Grid gap="1rem" alignItems="normal">
          <Tooltip show={!client} content="Please select a Client first">
            <ObjectSelector
              label="Primary Insurance"
              model="primary_insurance"
              icon="insurance"
              type="client.insurance_policies"
              dependentValue={client?.id}
              selectTitle={(data: any) => data?.insurance_name}
              selectDescription={(data: any) => <SelectDescription data={data} />}
              validations={{
                presence: {
                  message: 'Please select a Primary Insurance',
                },
              }}
              onUpdate={(state: any) => {
                if (!onUpdate || !state?.object?.id) return

                onUpdate(state.object)
              }}
            />
          </Tooltip>

          <ObjectSelector
            label="Secondary Insurance"
            model="secondary_insurance"
            icon="insurance"
            type="client.insurance_policies"
            dependentValue={client?.id}
            selectTitle={(data: any) => data?.insurance_name}
            selectDescription={(data: any) => <SelectDescription data={data} />}
          />

          <ObjectSelector
            label="Tertiary Insurance"
            model="tertiary_insurance"
            icon="insurance"
            type="client.insurance_policies"
            dependentValue={client?.id}
            selectTitle={(data: any) => data?.insurance_name}
            selectDescription={(data: any) => <SelectDescription data={data} />}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}

const styles = {
  height: '100%',

  cardTitle: {
    fontSize: '1rem',
  },

  cardContent: {
    height: '100%',
  },
}

export default withFormContext(ClaimInsurancePolicies)
