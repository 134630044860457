import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'

import Button from '../../../Button'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Dialog from '../../../Dialog'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import Section from '../../../Section'

import { apiCreate, apiUpdate, apiDelete } from '../../../../modules/api'

import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Input from '../../../Forms/Input'
import Option from '../../../Forms/Option'
import Select from '../../../Forms/Select'
import Textarea from '../../../Forms/Textarea'

import CustomNoteSections from '../../../Elements/CustomNoteSections'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../../../Overlays/pages/OverlayBase'

import withRouterV6 from '../../../../hocs/withRouterV6'

class CustomNoteTemplateOverlay extends OverlayBase {
  save = async () => {
    const { $new } = this.state
    const { match } = this.props

    let model = this.form.current.getFormValue()

    if ($new)
      await apiCreate({
        name: 'internal_team_custom_note_templates',
        url: `/internal_templates`,
        params: {
          ...model,
          category: 'custom_note',
          subcategory: 'team',
        },
      })
    else
      await apiUpdate({
        name: 'internal_team_custom_note_templates',
        url: `/internal_templates/${match?.params?.id}`,
        params: model,
      })

    this.setState({ $editable: false })
    this.close()
  }

  delete = async () => {
    const { match } = this.props
    await apiDelete({
      name: 'internal_team_custom_note_templates',
      url: `/internal_templates/${this.state.id || match?.params?.id}`,
    })

    this.close()
  }

  render = () => {
    const { $new, $editable, isInvalid, params } = this.state
    const { dataID, initialData, record, timezone, online, loading } = this.props

    const data = { ...params, ...record }
    const hasInitialData = initialData && dataID

    if (!$new && !hasInitialData && size(data) === 0) {
      return <OverlayLoader position="right" showBackdrop={this.state.$editable} />
    }

    return (
      <Overlay
        position="right"
        isLoading={!data}
        showBackdrop={this.state.$editable}
        maxWidth={82}
        closeWrapper={(element) => (
          <Dialog
            glyph="delete"
            title="Close without saving?"
            message="All changes will be lost. This action cannot be undone."
            yesColor="red"
            yesLabel="Yes, Close Without Saving"
            onYes={this.close}
            skip={!$editable}
          >
            {element}
          </Dialog>
        )}
      >
        <Overlay.Header title={data?.data?.name || 'New Custom Note Template'} icon="custom_notes" />

        <Overlay.Content>
          <Form
            useFullModel
            getForm={this.form}
            timezone={timezone}
            initialModel={data}
            isEditable={$editable}
            onValidationUpdate={this.onValidationUpdate}
            decorate={(model: any) => ({
              data: { ...model.data, variant: 'team' },
            })}
          >
            <Section title="Template Details" headingType="h2">
              <FormSection maxWidth="100%">
                <Flex gap="1rem" alignItems="flex-end">
                  <Input
                    label="Template Name"
                    model="data.name"
                    validations={{
                      presence: {
                        message: 'Please enter a template name',
                      },
                    }}
                    className="!grow-[2]"
                  />

                  <CheckboxGroup layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                    <Checkbox label="Require Supervisor Signature" model="data.settings.require_supervisor_signature" />
                  </CheckboxGroup>
                </Flex>

                <Flex stretchChildrenX gap="1rem">
                  <Select model="status" label="Status" fullWidth defaultValue="active">
                    <Option label="Active" value="active" />
                    <Option label="Archived" value="archived" />
                  </Select>

                  <Select model="level" label="Level" fullWidth defaultValue="behave_template">
                    <Option label="Behave Template" value="behave_template" />
                    <Option label="Public Template" value="public_template" />
                  </Select>
                </Flex>

                <Textarea useQuickText label="Description" model="data.notes" className="!grow !basis-[300px] !self-start" />
              </FormSection>
            </Section>

            <Divider />

            <CustomNoteSections
              model="data.sections"
              validations={{
                presence: {
                  message: 'Please add at least one section',
                },
              }}
            />

            <Section
              headingType="h2"
              title="Signatures"
              aside={
                <CheckboxGroup layout="horizontal-dense" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox label="Client Signature" model="data.settings.require_reference_signature" />
                  <Checkbox label="Staff Signature" model="data.settings.require_staff_signature" />
                </CheckboxGroup>
              }
            ></Section>
          </Form>
        </Overlay.Content>

        <Overlay.Footer online={online}>
          {$editable && (
            <>
              <Button
                glyph="check"
                label="Save Template"
                type="primary"
                color="green"
                isLoading={loading}
                onClick={this.save}
                isDisabled={isInvalid}
                flex="100 1 240px"
              />

              {!$new && <Button glyph="cross" label="Cancel" type="default" isDisabled={loading} onClick={this.cancel} />}
            </>
          )}

          {!$editable && (
            <>
              <Button
                label="Edit"
                glyph="edit"
                type="default"
                isDisabled={loading}
                onClick={this.edit}
                flex="100 1 auto"
                permission="settings.team_custom_note_templates.edit"
              />

              <DeleteDialog
                title="Delete Template?"
                message="Are you sure you want to delete this template? This action cannot be undone."
                onYes={this.delete}
              >
                <Button
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  isLoading={loading}
                  fullWidth
                  permission="settings.team_custom_note_templates.edit"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'internal_team_custom_note_templates')

export default connect(mapStateToProps, mapDispatchToProps)(CustomNoteTemplateOverlay)

export const TeamCustomNoteInternalTemplateOverlayV6 = withRouterV6(connect(mapStateToProps, mapDispatchToProps)(CustomNoteTemplateOverlay))
