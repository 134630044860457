import React from 'react'

import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Icon from '../../Icon'
import Type from '../../Typography/Type'

import Stepper from '../../Stepper/Stepper'

export const RecoveryAccountabilityFields: React.FC<any> = () => {
  return (
    <FormSection maxWidth="650px">
      <Flex nowrap centerY gap="0.75rem">
        <Icon icon="care_level_4" />
        <Type as="h2" variant="H3">
          Recovery & Accountability
        </Type>
      </Flex>

      <CheckboxGroup withToggle label=" " trueIcon="check" falseIcon="cross" falseStyle="faded-linethrough" layout="vertical-dense">
        <Checkbox
          label="Track client UA & Breathalyzer tests to easily monitor client sobriety "
          model="questionnaire.recovery_accountability.track_ua_and_breathalyzer_tests"
        />
        <Checkbox
          label="Your clients use our mobile app to check-in at meetings, treatment or housing"
          model="questionnaire.recovery_accountability.client_mobile_app"
        />
        <Checkbox
          label="Help clients set recovery goals and stay on track with recovery coaching"
          model="questionnaire.recovery_accountability.recovery_coaching"
        />
        <Checkbox
          label="Have your team complete shift notes and incident reports "
          model="questionnaire.recovery_accountability.shift_notes"
        />
        <Checkbox
          label="Centralize accountability company-wide to-do list for both staff and clients"
          model="questionnaire.recovery_accountability.to_dos"
        />
      </CheckboxGroup>
    </FormSection>
  )
}

export const RecoveryAccountability: React.FC<any> = () => {
  const form = React.useRef()

  const { data, setData }: any = React.useContext(Stepper.Context)

  return (
    <Form useFullModel getForm={form} initialModel={data} onUpdate={setData}>
      <RecoveryAccountabilityFields />
    </Form>
  )
}
