import React from 'react'
import { Link } from 'react-router-dom-v5-compat'
import clsx from 'clsx'

import { daysTo, usDateShort } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'
import SmartStatus from '../../../components/SmartStatus'
import Status from '../../../components/Status'

import { STATUSES, REVIEW_STATUSES } from './TreatmentPlanReviews'

export const FormReadonlyReviewCard = (props: any) => {
  const { data, type } = props

  const { timezone } = useSettings()

  const classes = clsx(
    'block px-2 py-2 border-solid border-transparent [&.active]:!border-blue-300 [&.active]:ring-2 ring-blue-100 text-text !bg-white !bg-opacity-40',
  )

  const days = daysTo(data?.dated_at, timezone)

  let daysText =
    typeof days === 'number' &&
    (days === -1
      ? `yesterday`
      : days < 0
      ? `${Math.abs(days)} days ago`
      : days === 0
      ? 'today'
      : days === 1
      ? 'tomorrow'
      : `in ${days} days`)

  return (
    <Card className={classes}>
      {data ? (
        <div>
          <Flex centerY gap="0.25rem">
            <Status small label={`${type} review`} color="textMuted" className="!text-text" />
            <SmartStatus small statuses={REVIEW_STATUSES} status={data?.status || 'draft'} />
            {data?.score && <SmartStatus small statuses={STATUSES} status={data.score} />}

            {type === 'next' && typeof days === 'number' && data?.status !== 'completed' && days < 2 && (
              <>
                <Status small glyph={days > 0 ? 'warning' : 'circle_error'} color={days > 0 ? 'orange' : 'red'} label={`Due ${daysText}`} />
              </>
            )}

            {data?.dated_at && <DateItem value={data.dated_at} />}
          </Flex>

          <h3 className="text-[1.04rem] font-[700] mt-1">{data.name}</h3>
          {data.notes && <div className="text-text-muted whitespace-pre-wrap">{data.notes}</div>}
        </div>
      ) : (
        <div className="grid gap-2">
          <Status small label={`${type} review`} color="textMuted" className="!text-text" />

          <div className="text-text-muted italic text-[0.92rem]">
            {type === 'last' ? 'Not yet added' : type === 'next' ? 'Not yet planned' : 'Not yet added'}
          </div>
        </div>
      )}
    </Card>
  )
}

const DateItem = ({ label, value, after }: any) => {
  const { timezone } = useSettings()

  if (!label && !value) return null

  return (
    <div className="flex items-center flex-nowrap text-[0.9rem] tabular-nums min-w-[120px] text-text">
      <Glyph glyph="date" size="1em" className="mr-1" />
      {label && <div className="font-[500] mr-0.5">{label}:</div>}
      <div className={!value && 'text-text-strongly-muted'}>
        {value ? usDateShort(value, timezone) : '–'}
        {after}
      </div>
    </div>
  )
}
