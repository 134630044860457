import React from 'react'
import size from 'lodash/size'
import { useParams } from 'react-router-dom'

import Alert from '../../components/Alert'
import AccordionAlert from '../../components/AccordionAlert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardContent from '../../components/CardContent'
import CardTitle from '../../components/CardTitle'
import ClaimTypeStatus from '../../components/Statuses/ClaimTypeStatus'
import ContextShow from '../../components/Forms/ContextShow'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import SummonOverlay from '../../components/SummonOverlay'
import Tabs from '../../components/Tabs'
import ClientProfileHeader from '../../components/ClientProfileHeader'

import ClaimFinancialStats from './components/ClaimFinancialStats'
import ClaimPaymentsTable from './components/ClaimPaymentsTable'

import { ClaimNotes } from './components/ClaimNotes'
import { ClaimNoteOverlay } from './components/ClaimNoteOverlay'
import { ClaimTasks } from './components/ClaimTasks'
import { ClaimTaskOverlay } from './components/ClaimTaskOverlay'
import { ChangeHealthRequests } from './components/ChangeHealthRequests'
import { ElectronicStatuses } from './components/ElectronicStatuses'

import ClaimPayerDetails from './components/ClaimPayerDetails'
import ClaimBehaveDetails from './components/ClaimBehaveDetails'
import ClaimDetails from './components/ClaimDetails'
import ClaimInsurancePolicies from './components/ClaimInsurancePolicies'
import ClaimTreatmentEpisodeDetails from './components/ClaimTreatmentEpisodeDetails'
import InstitutionalServiceLines from './components/InstitutionalServiceLines'
import ProfessionalServiceLines from './components/ProfessionalServiceLines'

import ClaimProfessionalProviders from './components/ClaimProfessionalProviders'
import ClaimInstitutionalProviders from './components/ClaimInstitutionalProviders'

import { useUpdate, useCreate } from '@behavehealth/hooks/useNewAPI'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootClaimOverlay = (props: any) => {
	const {
		cancel,
		close,
		deleteRecord,
		edit,
		form,
		client,
		initialModel,
		isDeleting,
		isEditable,
		isNew,
		isOverlayLoading,
		isSaving,
		onValidationUpdate,
		save
	} = useOverlay({
		name: 'insurance-claims',
		endpoint: '/insurance_claims',
		invalidate: 'insurance-claims',
		options: props
	})

	const { timezone, tenant, isBehave } = useSettings()
	const { resource_id }: any = useParams()

	const [claimCategory, setClaimCategory] = React.useState(null)
	const [feeSchedule, setFeeSchedule] = React.useState(null)
	const [primaryInsurance, setPrimaryInsurance] = React.useState(null)
	const [selectedClaimNote, setSelectedClaimNote] = React.useState(null)
	const [selectedClaimTask, setSelectedClaimTask] = React.useState(null)

	const hasWarnings = size(initialModel?.warnings) > 0
	const canRunElectronicStatuses = initialModel.last_submission_at

	const { mutateAsync: validateClaim, isLoading: isValidating }: any = useUpdate({
		name: ['client', resource_id, 'insurance-claims'],
		url: `/insurance_claims/${initialModel.id}/validate`,
		invalidate: ['insurance-claims']
	})

	const { mutateAsync: submitClaim, isLoading: isSubmitting }: any = useUpdate({
		name: ['client', resource_id, 'insurance-claims'],
		url: `/insurance_claims/${initialModel.id}/submit`,
		invalidate: ['insurance-claims']
	})

	const { mutateAsync: verifyStatus, isLoading: isVerifying } = useCreate({
		name: ['insurance-claims', initialModel.id, 'insurance-claim-statuses'],
		url: `/insurance_claims/${initialModel.id}/verify_status`,
		invalidate: ['client', resource_id, 'insurance-claims']
	})

	React.useEffect(() => {
		if (!initialModel) return

		if (!setClaimCategory && initialModel?.category) setClaimCategory(initialModel?.category)
		if (!feeSchedule && initialModel?.insurance_fee_schedule) setFeeSchedule(initialModel?.insurance_fee_schedule)
		if (!primaryInsurance && initialModel?.primary_insurance) setPrimaryInsurance(initialModel?.primary_insurance)
	}, [initialModel])

	if (isOverlayLoading) {
		return <OverlayLoader showBackdrop position="center" />
	}

	return (
		<Overlay showBackdrop fullheight position="center" maxWidth={125} onClose={close} isDirty={isEditable}>
			<Overlay.Header
				icon="invoices"
				title="Claim"
				titleAside={!isNew && <ClaimTypeStatus status={initialModel.category} />}
				description={<ClientProfileHeader client={client} />}
			/>

			<Overlay.Content>
				<Form
					useFullModel
					getForm={form}
					initialModel={initialModel}
					isEditable={isEditable}
					timezone={timezone}
					onValidationUpdate={onValidationUpdate}
					linked={{ resident_id: resource_id }}
				>
					<Tabs defaultTab="claim_info">
						{!isNew && (
							<Tabs.List css={styles.tabsList}>
								<Tabs.Item label="Claim Info" name="claim_info" icon="general_info" />
								<Tabs.Item label="Notes" name="notes" icon="clinical_assessments" />
								<Tabs.Item label="Tasks" name="tasks" icon="tasks" />
								<Tabs.Item label="Electronic Statuses" name="electronic_statuses" icon="intake_forms" />
								<Tabs.Item isDisabled label="Financials" name="financials" icon="financials" />
								<Tabs.Item isDisabled label="Timeline" name="timeline" />
								{isBehave && <Tabs.Item label="🧬 Logs" name="logs" />}
							</Tabs.List>
						)}

						<Tabs.Panels>
							<Tabs.Panel name="claim_info">
								<Grid gap="1rem" css={styles.wrapper}>
									<RadioGroup
										model="category"
										layout="horizontal"
										defaultValue="professional"
										maxWidth="100%"
										withHover={false}
										isEditable={isNew}
										className={isNew ? '' : 'is-hidden'}
										onUpdate={(state: any) => {
											setClaimCategory(state.value)
										}}
									>
										<Radio label="Professional Claim (CMS-1500)" value="professional" />
										<Radio label="Institutional Claim (UB-04)" value="institutional" />
									</RadioGroup>

									<Grid gap="1rem" horizontal templateColumns="1fr 1fr">
										{initialModel.status === 'error_validation' && (
											<AccordionAlert
												glyph="warning"
												type="negative"
												header={`${size(initialModel.validation?.errors)} Issues`}
											>
												The Clearing House reported the following issues with the validation:
												<ul>
													{initialModel.validation?.errors?.map((error: any) => (
														<li>
															<strong>{error.field}</strong>: {error.description}
														</li>
													))}
												</ul>
											</AccordionAlert>
										)}

										{hasWarnings && (
											<AccordionAlert glyph="warning" type="warning" header={`${size(initialModel.warnings)} Warnings`}>
												The next items could have a negative impact on getting your Insurance Claim approved:
												<ul>
													{initialModel.warnings?.map((warning: any) => (
														<li>{warning.message}</li>
													))}
												</ul>
											</AccordionAlert>
										)}
									</Grid>

									{initialModel.status === 'error_submission' && (
										<Alert type="negative">
											The Clearing House reported the following issues with the submission:
											<ul>
												{initialModel.response?.errors?.map((error: any) => (
													<li>
														<strong>{error.field}</strong>: {error.description}
													</li>
												))}
											</ul>
										</Alert>
									)}

									<div css={styles.claimGrid}>
										<Grid gap="1rem" alignItems="normal">
											<ClaimInsurancePolicies client={client} onUpdate={setPrimaryInsurance} />
											<ClaimPayerDetails payer={primaryInsurance?.insurance_local_payer} />
										</Grid>

										<Grid gap="1rem">
											<ClaimBehaveDetails isNew={isNew} claimID={initialModel.id} />

											<Grid gap="1rem" columns="1fr 1fr" alignItems="normal">
												<ClaimDetails client={client} feeSchedule={feeSchedule} onUpdate={setFeeSchedule} />
												<ClaimTreatmentEpisodeDetails data={initialModel} client={client} />
											</Grid>
										</Grid>

										<div css={styles.gridColumn}>
											<ContextShow when="category" is="professional">
												<ClaimProfessionalProviders tenant={tenant} />
											</ContextShow>

											<ContextShow when="category" is="institutional">
												<ClaimInstitutionalProviders tenant={tenant} />
											</ContextShow>

											{/* <ClaimValueCodes model="value_codes" /> */}
										</div>
									</div>

									{claimCategory === 'professional' && (
										<ProfessionalServiceLines
											isNested
											model="insurance_claim_service_lines"
											client={client}
											feeSchedule={feeSchedule}
										/>
									)}

									{claimCategory === 'institutional' && (
										<InstitutionalServiceLines
											isNested
											model="insurance_claim_service_lines"
											client={client}
											feeSchedule={feeSchedule}
										/>
									)}
								</Grid>
							</Tabs.Panel>

							<Tabs.Panel name="notes" type="mount">
								<div className="!p-4">
									<Card>
										<ClaimNotes
											claimID={initialModel.id}
											onClick={(note: any) => {
												setSelectedClaimNote(note)
											}}
											titleAfter={
												<SummonOverlay
													overlay={<ClaimNoteOverlay showBackdrop dataID="new" claimID={initialModel.id} />}
												>
													<Button label="Add Claim Note" glyph="add" type="primary" size={100} />
												</SummonOverlay>
											}
										/>

										<SummonOverlay
											isOpen={selectedClaimNote}
											overlay={
												<ClaimNoteOverlay showBackdrop dataID={selectedClaimNote?.id} initialData={selectedClaimNote} />
											}
											onClose={() => {
												setSelectedClaimNote(null)
											}}
										/>
									</Card>
								</div>
							</Tabs.Panel>

							<Tabs.Panel name="tasks" type="mount">
								<div className="!p-4">
									<Card>
										<ClaimTasks
											claimID={initialModel.id}
											onClick={(task: any) => {
												setSelectedClaimTask(task)
											}}
											titleAfter={
												<SummonOverlay
													overlay={<ClaimTaskOverlay showBackdrop dataID="new" claimID={initialModel.id} />}
												>
													<Button label="Add Claim Task" glyph="add" type="primary" size={100} />
												</SummonOverlay>
											}
										/>

										<SummonOverlay
											isOpen={selectedClaimTask}
											overlay={
												<ClaimTaskOverlay showBackdrop dataID={selectedClaimTask?.id} initialData={selectedClaimTask} />
											}
											onClose={() => {
												setSelectedClaimTask(null)
											}}
										/>
									</Card>
								</div>
							</Tabs.Panel>

							<Tabs.Panel name="electronic_statuses" type="mount">
								<div className="!p-4">
									<Card>
										<CardHeader>
											<CardTitle
												title="Electronic Statuses"
												after={
													canRunElectronicStatuses && (
														<Button
															className="!ml-2"
															size={100}
															glyph="reset"
															type="primary"
															label="Run Status"
															onClick={async () => {
																await verifyStatus({})
															}}
															isLoading={isVerifying}
														/>
													)
												}
											/>
										</CardHeader>

										<CardContent padding="0" showDivider>
											<ElectronicStatuses claim={initialModel} />
										</CardContent>
									</Card>
								</div>
							</Tabs.Panel>

							<Tabs.Panel name="financials" type="mount">
								<Grid gap="1rem" className="!p-4">
									<ClaimFinancialStats />

									<Card>
										<ClaimPaymentsTable
										// onClick={(claimPayment: any) => {
										//   this.setState({ claimPayment: claimPayment })
										// }}
										// titleAfter={
										//   <>
										//     <SummonOverlay overlay={<ClaimPaymentOverlay dataID="new" />}>
										//       <Dropdown label="Add Payment…" buttonType="primary" buttonSize={200}>
										//         <DropdownItem
										//           label="Batch Payments"
										//           glyph="circle_add"
										//           color="blue"
										//           onClick={() => {
										//             this.setState({ claimPaymentsBuilder: { id: 'new' } })
										//           }}
										//         />
										//         <Divider />
										//         <DropdownItem
										//           label="Insurance Payment"
										//           glyph="add"
										//           color="blue"
										//           onClick={() => {
										//             this.setState({ claimPayment: { id: 'new', category: 'insurance_payment' } })
										//           }}
										//         />
										//         <DropdownItem
										//           label="Client Payment"
										//           glyph="add"
										//           color="blue"
										//           onClick={() => {
										//             this.setState({ claimPayment: { id: 'new', category: 'client_payment' } })
										//           }}
										//         />
										//         <DropdownItem
										//           label="Write-Off"
										//           glyph="add"
										//           color="blue"
										//           onClick={() => {
										//             this.setState({ claimPayment: { id: 'new', category: 'write_off' } })
										//           }}
										//         />
										//         <DropdownItem isDisabled label="Patient Responsibility" glyph="add" color="gray" />
										//       </Dropdown>
										//     </SummonOverlay>
										//   </>
										// }
										/>

										{/* <SummonOverlay
                      isOpen={this.state.claimPayment}
                      overlay={<ClaimPaymentOverlay dataID={this.state?.claimPayment?.id} initialData={this.state.claimPayment} />}
                      onClose={() => {
                        this.setState({ claimPayment: null })
                      }}
                    /> */}

										{/* <SummonOverlay
                      isOpen={this.state.claimPaymentsBuilder}
                      overlay={
                        <ClaimPaymentsBuilderOverlay
                          dataID={this.state?.claimPaymentsBuilder?.id}
                          initialData={this.state.claimPaymentsBuilder}
                        />
                      }
                      onClose={() => {
                        this.setState({ claimPaymentsBuilder: null })
                      }}
                    /> */}
									</Card>
								</Grid>
							</Tabs.Panel>

							<Tabs.Panel name="timeline" type="mount">
								<h2>Timeline</h2>
							</Tabs.Panel>

							{isBehave && (
								<Tabs.Panel name="logs" type="mount">
									<div className="!p-4">
										<Card>
											<ChangeHealthRequests claimID={initialModel.id} />
										</Card>
									</div>
								</Tabs.Panel>
							)}
						</Tabs.Panels>
					</Tabs>
				</Form>
			</Overlay.Content>

			<Overlay.Footer css={styles.footer}>
				{isEditable && (
					<Flex stretchChildrenX gap={8}>
						<Button
							label="Save"
							glyph="check"
							type="primary"
							color="green"
							isLoading={isSaving}
							onClick={save}
							flex="3 1 auto"
						/>
						{!isNew && (
							<Button
								label="Cancel"
								glyph="cross"
								type="default"
								isDisabled={isSaving}
								onClick={cancel}
								flex="0 1 auto"
							/>
						)}
					</Flex>
				)}

				{!isEditable && (
					<Flex stretchChildrenX gap={8}>
						<Button glyph="edit" label="Edit" type="default" isDisabled={isSaving} onClick={edit} flex="1 1 auto" />

						<DeleteDialog
							title="Delete Contact?"
							message="Are you sure you want to delete this contact? This action cannot be undone."
							onYes={deleteRecord}
						>
							<Button fullWidth glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} />
						</DeleteDialog>

						<Button
							label={initialModel.status === 'error_validation' ? 'Revalidate Claim' : 'Validate Claim'}
							glyph="check"
							type="default"
							color="green"
							isLoading={isValidating}
							isDisabled={initialModel.status === 'validated'}
							onClick={async () => {
								await validateClaim()
							}}
							flex="1 1 auto"
						/>

						<Button
							label="Submit Claim"
							glyph="check"
							type="primary"
							color="blue"
							isLoading={isSubmitting}
							isDisabled={initialModel.status !== 'validated'}
							onClick={async () => {
								await submitClaim()
								close()
							}}
							flex="1 1 auto"
						/>
					</Flex>
				)}
			</Overlay.Footer>
		</Overlay>
	)
}

const styles = {
	wrapper: {
		padding: '1rem'
	},

	tabsList: {
		padding: '0 1rem'
	},

	claimGrid: {
		display: 'grid',
		gridGap: '1rem',
		gridTemplateColumns: '1fr',

		'@media (min-width: 1000px)': {
			gridTemplateColumns: '360px 1fr'
		},

		'@media (min-width: 1500px)': {
			gridTemplateColumns: '360px 1fr 460px'
		}
	},

	gridColumn: {
		display: 'grid',
		gridGap: '1rem',
		gridTemplateColumns: '1fr',

		'@media (min-width: 1000px)': {
			gridColumn: '-1 / 1',
			gridTemplateColumns: '1fr 1fr'
		},

		'@media (min-width: 1500px)': {
			gridTemplateColumns: '1fr',
			gridColumn: '3'
		}
	}
}

export const ClaimOverlay = withOverlayError(RootClaimOverlay)
