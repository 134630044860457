import React from 'react'
import clsx from 'clsx'

type Props = {
  label?: string
  skipVisibleCheck?: boolean
} & React.HTMLAttributes<any>

const NavGroup: React.FC<Props> = ({ children, className, label, skipVisibleCheck, labelAfter, testKey }) => {
  const [visible, setVisible] = React.useState(false)

  const checkVisibility = (isVisible: boolean) => {
    if (skipVisibleCheck) return
    if (isVisible) setVisible(true)
  }

  const childrenWithProps: any = React.Children.map(children, (child) => {
    if (!child) return
    return React.cloneElement(child, { isVisible: checkVisibility })
  })

  const classNames = clsx('avatar', {
    'nav-group': true,
    [className]: className,
  })

  return (
    <div data-test={testKey} css={styles} className={classNames}>
      {(skipVisibleCheck || (label && visible)) && (
        <h4 css={styles.label} className="nav-group-label">
          {label}
          {labelAfter}
        </h4>
      )}

      <div className="nav-group-content">{childrenWithProps}</div>
    </div>
  )
}

export const NavGroupLabel = ({ label, className }: any) => {
  const classes = clsx('nav-group-label', className)

  return (
    <h4 css={styles.label} className={classes}>
      {label}
    </h4>
  )
}

const styles = {
  marginTop: '1.5rem',

  '&:first-child': {
    marginTop: 0,
  },

  label: {
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    opacity: 0.8,
    whiteSpace: 'nowrap',
  },
}

export default NavGroup
