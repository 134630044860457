import React from 'react'
import { darken, opacify, tint } from 'polished'
import clsx from 'clsx'

import { INPUT_STYLES, COLORS } from '../../theme'

import Glyph from '../Glyph'

type Props = {
  className?: string
  isChecked: boolean
  isDisabled?: boolean
  isIndeterminate?: boolean
  onClick?: any
  size?: number
  type: 'radio' | 'checkbox'
}

const RadioCheckElement = ({ isChecked, isDisabled, isIndeterminate, type, className, onClick, size = 18 }: Props) => {
  const classNames = clsx({
    'is-checked': isChecked,
    'is-disabled': isDisabled,
    'is-indeterminate': isIndeterminate,
    'is-checkbox': type === 'checkbox',
    'is-radio': type === 'radio',
    [className]: className,
  })

  return (
    <div data-test="radio_check_element" css={styles} className={classNames} onClick={onClick} style={{ width: size, height: size }}>
      {type === 'checkbox' && (
        <>
          {isChecked && <Glyph glyph="check" size={14} color={COLORS.white} />}
          {isIndeterminate && <Glyph glyph="subtract" size={12} color={COLORS.green} />}
        </>
      )}
    </div>
  )
}

const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '0 0 auto',
  position: 'relative',

  borderRadius: 4,
  background: COLORS.white,
  border: `1px solid ${opacify(0.08, COLORS.divider)}`,
  transition: INPUT_STYLES.transition,

  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },

  '&.is-checked': {
    transition: 'all 50ms ease',
    background: COLORS.green,
    boxShadow: `0 0 0 0 ${COLORS.green} !important`,
  },

  'label:hover &, .input:hover &': {
    borderColor: tint(0.1, COLORS.green),
    boxShadow: `0 0 0 2px ${tint(0.5, COLORS.green)}`,

    '&.is-checked': {
      borderColor: darken(0.04, COLORS.green),
      background: darken(0.04, COLORS.green),
    },
  },

  '&.is-disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
    background: tint(0.92, COLORS.gray),
  },

  '&.is-radio': {
    borderRadius: '50%',

    '&.is-checked': {
      '&::after': {
        content: '""',
        display: 'inline-block',
        width: 8,
        height: 8,
        background: 'white',
        borderRadius: '50%',
      },
    },
  },
}

export default RadioCheckElement
