import React from 'react'

import size from 'lodash/size'

import { usDate, countWord } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import IntakeStatus from '../../components/Statuses/IntakeStatus'

import { FILTERS } from '../Filters/config'

import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

import { DISCHARGE_TYPES } from '../../utils/constants'

export const TreatmentEpisodesReportDataTable = (props: any) => {
  const { to } = props

  const { timezone, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        width: 180,
        id: 'title',
        model: 'data.title',
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell id={data.data.id} value={value || usDate(data.data.created_at, timezone)} to={to?.(data.data)} />
        ),
        disableSort: false,
      },
      {
        title: 'Client',
        width: 180,
        id: 'customer',
        model: 'data.customer.name',
        type: 'profile',
      },
      {
        title: 'Status',
        width: 140,
        id: 'status',
        model: 'data.status',
        formatValue: ({ value }: any) => (value ? <IntakeStatus status={value} /> : null),
        disableSort: false,
      },
      {
        title: 'Authorizations',
        width: 200,
        id: 'insurance_authorization_count',
        model: 'data.insurance_authorization_count',
        formatValue: ({ value }: any) => countWord('Authorizations', value),
        disableSort: false,
      },
      {
        title: 'Est. Arrival Date',
        width: 200,
        id: 'estimated_arrival',
        model: 'data.estimated_arrival',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Admit Date',
        width: 200,
        id: 'admitted_at',
        model: 'data.admitted_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Est. Discharge Date',
        width: 200,
        id: 'estimated_discharge',
        model: 'data.estimated_discharge',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Discharge Date',
        width: 200,
        id: 'discharged_at',
        model: 'data.discharged_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Decline Date',
        width: 200,
        id: 'declined_at',
        model: 'data.declined_at',
        type: 'date_time',
        disableSort: false,
      },
      // {
      //   title: 'Referral In',
      //   id: 'referral_in',
      //   model: 'data.referral_in',
      //   type: 'profile',
      // },
      // {
      //   title: 'Referral In Contact',
      //   id: 'referral_in_contact',
      //   model: 'data.referral_in_contact',
      //   type: 'profile',
      // },
      // {
      //   title: 'Referral In Notes',
      //   id: 'referral_in_notes',
      //   model: 'data.referral_in_notes',
      // },
      // {
      //   title: 'Referral Out',
      //   id: 'referral_out',
      //   model: 'data.referral_out',
      //   type: 'profile',
      // },
      // {
      //   title: 'Referral Out Contact',
      //   id: 'referral_out_contact',
      //   model: 'data.referral_out_contact',
      //   type: 'profile',
      // },
      // {
      //   title: 'Referral Out Notes',
      //   id: 'referral_out_notes',
      //   model: 'data.referral_out_notes',
      // },
      {
        title: 'Incoming Notes',
        width: 200,
        id: 'incoming_notes',
        model: 'data.incoming_notes',
        disableSort: false,
      },
      {
        title: 'Waitlist Notes',
        width: 200,
        id: 'waitlist_notes',
        model: 'data.waitlist_notes',
        disableSort: false,
      },
      {
        title: 'No Show Notes',
        width: 200,
        id: 'no_show_notes',
        model: 'data.no_show_notes',
        disableSort: false,
      },
      {
        title: 'Discharge Notes',
        width: 200,
        id: 'discharge_notes',
        model: 'data.discharge_notes',
        disableSort: false,
      },
      {
        title: 'Discharge Type',
        width: 200,
        id: 'discharge_type',
        model: 'data.discharge_type',
        disableSort: false,
        formatValue: ({ value }: any) => (value ? DISCHARGE_TYPES[value] : '-'),
      },
      {
        title: 'Additional Information',
        width: 200,
        id: 'notes',
        model: 'data.notes',
        disableSort: false,
      },
      {
        title: 'Accepted By',
        width: 180,
        id: 'accepted_by',
        model: 'data.accepted_by.name',
        type: 'profile',
        disableSort: false,
      },
      {
        title: 'Admitted By',
        width: 180,
        id: 'admitted_by',
        model: 'data.admitted_by.name',
        type: 'profile',
        disableSort: false,
      },
      {
        title: 'Discharged By',
        width: 180,
        id: 'discharged_by',
        model: 'data.discharged_by.name',
        type: 'profile',
        disableSort: false,
      },
      {
        title: 'Declined By',
        width: 180,
        id: 'declined_by',
        model: 'data.declined_by.name',
        type: 'profile',
        disableSort: false,
      },
      {
        title: 'Date Added',
        width: 200,
        id: 'created_at',
        model: 'data.created_at',
        type: 'date_time',
        disableSort: false,
      },
      {
        title: 'Last Updated',
        width: 220,
        id: 'updated_at',
        model: 'data.updated_at',
        type: 'date_time',
        disableSort: false,
      },
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="Treatment Episodes"
      icon="treatment_episodes"
      columns={columns}
      filtersConfig={FILTERS.live_reports.treatment_episodes}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="treatment_episodes"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=treatment_episodes'}
        />
      }
    />
  )
}
