import React from 'react'

import Button from './Button'
import Card from './Card'
import CardHeader from './CardHeader'
import CardSubtitle from './CardSubtitle'
import CardTitle from './CardTitle'
import Link from './Link'
import Overlay from './Overlay'
import Section from './Section'
import SummonOverlay from './SummonOverlay'

const ChartCard: React.FC = ({ className, title, subtitle, linkLabel = 'Full Report →', linkTo = '/reports', children }: any) => {
  return (
    <Card css={styles.card} className={className}>
      <CardHeader
        after={
          <SummonOverlay
            overlay={
              <Overlay showBackdrop closeOnBackdrop position="center" maxWidth={140}>
                <Overlay.Header title={title} subtitle={subtitle} />
                <Overlay.Content>
                  <Section>{children}</Section>
                </Overlay.Content>
              </Overlay>
            }
          >
            <Button hideLabel color="gray" glyph="expand" size={200} type="minimal" />
          </SummonOverlay>
        }
        css={styles.cardHeader}
      >
        <CardTitle title={title} after={linkLabel && linkTo && <Link children={linkLabel} to={linkTo} css={styles.link} />} />
        <CardSubtitle subtitle={subtitle} />
      </CardHeader>

      {children}
    </Card>
  )
}

const styles = {
  card: {
    overflow: 'visible',
    flex: '1 1 400px',
  },

  cardHeader: {
    flexWrap: 'nowrap',
  },

  link: {
    fontSize: '0.9rem',
    marginLeft: '0.5rem',
  },
}

export default ChartCard
