import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../../../theme'

import Avatar from '../../../Avatar'
import ClearButton from '../../ClearButton'

type Props = {
  avatar?: string
  isDisabled: boolean
  label: string
  onClear: Function
}

const MultiObjectItem = (props: Props) => {
  const { avatar, isDisabled, label, onClear } = props

  const hasAvatar = avatar !== undefined

  const classNames = clsx({
    'has-avatar': hasAvatar,
    'is-disabled': isDisabled,
  })

  return (
    <div css={styles.root} className={classNames}>
      {hasAvatar && (
        <div css={styles.graphic}>
          <Avatar magnify src={avatar} initials={label} size="1.75rem" magnifyPlacement="left" />
        </div>
      )}

      <h3 css={styles.label}>{label}</h3>

      {!isDisabled && <ClearButton onClick={onClear} className="w-[2.5rem]" />}
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    minHeight: 40,
    padding: '0.35rem 0',
    borderBottom: `1px solid ${COLORS.divider}`,

    '&.is-disabled': {
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },

    '&:last-of-type': {
      borderBottom: 'none',
    },
  },

  graphic: {
    width: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  label: {
    fontSize: '1rem',
    fontWeight: 400,
  },
}

export default MultiObjectItem
