import React from 'react'

import IndividualTherapyOutcome, { AddMore as IndividualTherapyAddMore } from './outcomes/IndividualTherapyOutcome'
import GroupTherapyOutcome, { AddMore as GroupTherapyAddMore } from './outcomes/GroupTherapyOutcome'
import CaseManagementOutcome, { AddMore as CaseManagementAddMore } from './outcomes/CaseManagementOutcome'
import IndividualPeerNoteOutcome, { AddMore as IndividualPeerNoteAddMore } from './outcomes/IndividualPeerNoteOutcome'
import GroupPeerNoteOutcome, { AddMore as GroupPeerNoteAddMore } from './outcomes/GroupPeerNoteOutcome'

type Props = {
  category: 'group_therapy' | 'individual_therapy' | 'case_management' | 'individual_peer_note' | 'group_peer_notes'
  event: any
}

export const OUTCOMES: any = {
  group_therapy: GroupTherapyOutcome,
  individual_therapy: IndividualTherapyOutcome,
  case_management: CaseManagementOutcome,
  individual_peer_note: IndividualPeerNoteOutcome,
  group_peer_notes: GroupPeerNoteOutcome,
}

export const OUTCOMES_ADD_MORE: any = {
  group_therapy: GroupTherapyAddMore,
  individual_therapy: IndividualTherapyAddMore,
  case_management: CaseManagementAddMore,
  individual_peer_note: IndividualPeerNoteAddMore,
  group_peer_notes: GroupPeerNoteAddMore,
}

const EventOutcomes: React.FC<Props> = ({ category, event }) => {
  const Component = OUTCOMES[category]

  if (!Component) return null

  return <Component event={event} outcomes={event.event_outcomes} />
}

export const EventOutcomesAddMore: React.FC<Props> = ({ category, event, className }) => {
  const Component = OUTCOMES_ADD_MORE[category]

  if (!Component) return null

  return <Component event={event} outcomes={event.event_outcomes} className={className} />
}

export default EventOutcomes
