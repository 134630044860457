import React from 'react'

import Chotomate from '../../components/Chotomate'
import ContextHide from '../../components/Forms/ContextHide'
import Diagnoses from '../../components/SmartContent/Diagnoses'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import GenderIdentitySelect from '../../components/Forms/elements/GenderIdentitySelect'
import Input from '../../components/Forms/Input'
import Medications from '../../components/SmartContent/Medications'
import PreferredPronounsSelect from '../../components/Forms/elements/PreferredPronounsSelect'
import Section from '../../components/Section'
import SexSelect from '../../components/Forms/elements/SexSelect'
import Switch from '../../components/Forms/Switch'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import YesNoRadioGroupWithTextarea from '../../components/Forms/elements/YesNoRadioGroupWithTextarea'

import BioMedical from '../../components/Overlays/pages/DataForms/common/BioMedical'
import CognitiveEmotionalBehavioral from '../../components/Overlays/pages/DataForms/common/CognitiveEmotionalBehavioral'
import GeneralInfo from '../../components/Overlays/pages/DataForms/common/GeneralInfo'
import LevelOfRiskAndInterventions from '../../components/Overlays/pages/DataForms/common/LevelOfRiskAndInterventions'
import MentalHealthHistory from '../../components/Overlays/pages/DataForms/common/MentalHealthHistory'
import MotivationForChangeForm from '../../components/Overlays/pages/DataForms/common/MotivationForChangeForm'
import OtherDiagnosisCodes from '../../components/Overlays/pages/DataForms/common/OtherDiagnosisCodes'
import PhysicalHealthHistory from '../../components/Overlays/pages/DataForms/common/PhysicalHealthHistory'
import ProtectiveFactors from '../../components/Overlays/pages/DataForms/common/ProtectiveFactors'
import RecoveryEnvironment from '../../components/Overlays/pages/DataForms/common/RecoveryEnvironment'
import RelapsePotential from '../../components/Overlays/pages/DataForms/common/RelapsePotential'
import RiskFactors from '../../components/Overlays/pages/DataForms/common/RiskFactors'
import SNAP from '../../components/Overlays/pages/DataForms/common/SNAP'
import SubstanceUseDiagnosisCodes from '../../components/Overlays/pages/DataForms/common/SubstanceUseDiagnosisCodes'
import SuicideRiskDocumentation from '../../components/Overlays/pages/DataForms/common/SuicideRiskDocumentation'
import ThoughtsPlansSuicidalIntent from '../../components/Overlays/pages/DataForms/common/ThoughtsPlansSuicidalIntent'
import TreatmentHistoryForm from '../../components/Overlays/pages/DataForms/common/TreatmentHistoryForm'
import WithdrawalSymptomsForm from '../../components/Overlays/pages/DataForms/common/WithdrawalSymptomsForm'

import { DataFormOverlay } from '../DataFormOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootEatingDisorderBPSOverlay = () => {
  const { isBehave } = useSettings()

  return (
    <DataFormOverlay
      title="Eating Disorder Biopsychosocial Assessment"
      category="clinical_assessment"
      subcategory="eating_disorder_bps"
      icon="clinical_assessments"
      version="2.0"
      requestClientSignature={false}
      requestAuthorSignature={true}
      requireSupervisor={true}
      enableTreatmentPlanSelector
    >
      {({ client, data, isEditable, isNew }) => (
        <>
          <Chotomate ready name="eating_disorder_bps_overlay" />

          <Section
            title="General Info"
            aside={<Switch horizontal inverse model="data.general_info.not_applicable" />}
            scrollview={{
              id: 'general_info',
              name: 'General Info',
            }}
            commentsModel="data.general_info"
          >
            <ContextHide when="data.general_info.not_applicable" is={true}>
              <FormSection>
                <GeneralInfo showAllergies client={client} />

                <Input label="Preferred Name" model="data.general_info.preferred_name" />

                <SexSelect label="Assigned Sex at Birth" model="data.general_info.sex" />

                <GenderIdentitySelect label="Gender Identity" model="data.general_info.gender_identity" />

                <PreferredPronounsSelect label="Preferred Pronouns" model="data.general_info.preferred_pronouns" />

                <Input label="Sexuality" model="data.general_info.sexuality" />

                <SmartTextarea
                  useDictation
                  label="What made the client decide to seek treatment now? (i.e. family intervention, medical problem, legal issues, etc.)"
                  description="Please be specific"
                  model="data.general_info.reason_for_seeking_treatment"
                />
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          {/* Substance use history */}
          <Section
            title="Substance Use History"
            aside={<Switch horizontal inverse model="data.substance_use_history.not_applicable" />}
            scrollview={{
              id: 'substance_use_history',
              name: 'Substance Use History',
              parent: { id: 'history', name: 'History' },
            }}
            commentsModel="data.substance_use_history"
          >
            <FormSection>
              <ContextHide when="data.substance_use_history.not_applicable" is={true}>
                <YesNoRadioGroupWithTextarea
                  label="Do you have a history of substance abuse?"
                  textareaLabel="What types of substances has the client used?"
                  model="data.substance_use_history.has_history"
                />
              </ContextHide>
            </FormSection>
          </Section>

          <Divider />

          {/* Mental Health History */}
          <Section
            title="Mental Health History"
            aside={<Switch horizontal inverse model="data.mental_health_history.not_applicable" />}
            scrollview={{
              id: 'mental_health_history',
              name: 'Mental Health History',
              parent: { id: 'history', name: 'History' },
            }}
            commentsModel="data.mental_health_history"
          >
            <ContextHide when="data.mental_health_history.not_applicable" is={true}>
              <MentalHealthHistory />
            </ContextHide>
          </Section>

          <Divider />

          {/* Physical Health History */}
          <Section
            title="Physical Health History"
            aside={<Switch horizontal inverse model="data.physical_health_history.not_applicable" />}
            scrollview={{
              id: 'physical_health_history',
              name: 'Physical Health History',
              parent: { id: 'history', name: 'History' },
            }}
            commentsModel="data.physical_health_history"
          >
            <ContextHide when="data.physical_health_history.not_applicable" is={true}>
              <PhysicalHealthHistory />
            </ContextHide>
          </Section>

          <Divider />

          {/* Treatment History for Mental Health and Addiction */}
          <Section
            title="Treatment History for Mental Health and Addiction"
            aside={<Switch horizontal inverse model="data.treatment_history.not_applicable" />}
            scrollview={{
              id: 'treatment_history',
              name: 'Treatment History for Mental Health and Addiction',
              parent: { id: 'history', name: 'History' },
            }}
            commentsModel="data.treatment_history"
          >
            <ContextHide when="data.treatment_history.not_applicable" is={true}>
              <TreatmentHistoryForm />
            </ContextHide>
          </Section>

          <Divider />

          {/* Medications */}
          <Section
            title="Current Medications"
            aside={<Switch horizontal inverse model="data.medications.not_applicable" />}
            scrollview={{
              id: 'medications',
              name: 'Current Medications',
              parent: { id: 'history', name: 'History' },
            }}
            commentsModel="data.medications"
          >
            <ContextHide when="data.medications.not_applicable" is={true} declineUndefined>
              <Medications
                model="data.medications.meds"
                isEditable={isEditable}
                client={client}
                icon="medications"
                title="Medications"
                emptyDescription="No medications added yet"
              />
            </ContextHide>
          </Section>

          <Divider />

          {/* D1: Withdrawal Symptoms */}
          <Section
            title="Dimension 1: Withdrawal Symptoms"
            aside={<Switch horizontal inverse model="data.withdrawal_symptoms.not_applicable" />}
            scrollview={{
              id: 'withdrawal_symptoms',
              name: 'Dimension 1: Withdrawal Symptoms',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.withdrawal_symptoms"
          >
            <ContextHide when="data.withdrawal_symptoms.not_applicable" is={true}>
              <WithdrawalSymptomsForm />
            </ContextHide>
          </Section>

          <Divider />

          {/* D2: Bio-medical */}
          <Section
            title="Dimension 2: Bio-Medical"
            aside={<Switch horizontal inverse model="data.bio_medical.not_applicable" />}
            scrollview={{
              id: 'bio_medical',
              name: 'Dimension 2: Bio-Medical',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.bio_medical"
          >
            <ContextHide when="data.bio_medical.not_applicable" is={true}>
              <BioMedical />
            </ContextHide>
          </Section>

          <Divider />

          {/* D3: Emotional, Behavioral or Cognitive Conditions and Complications */}
          <Section
            title="Dimension 3: Emotional, Behavioral or Cognitive Conditions and Complications"
            aside={<Switch horizontal inverse model="data.cognitive_emotional_behavioral.not_applicable" />}
            scrollview={{
              id: 'cognitive_emotional_behavioral',
              name: 'Dimension 3: Emotional, Behavioral or Cognitive Conditions and Complications',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.cognitive_emotional_behavioral"
          >
            <ContextHide when="data.cognitive_emotional_behavioral.not_applicable" is={true}>
              <CognitiveEmotionalBehavioral />
            </ContextHide>
          </Section>

          <Divider />

          {/* D4: Readiness for Change */}
          <Section
            title="Dimension 4: Readiness for Change"
            aside={<Switch horizontal inverse model="data.motivation_for_change.not_applicable" />}
            scrollview={{
              id: 'motivation_for_change',
              name: 'Dimension 4: Readiness for Change',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.motivation_for_change"
          >
            <ContextHide when="data.motivation_for_change.not_applicable" is={true}>
              <MotivationForChangeForm />
            </ContextHide>
          </Section>

          <Divider />

          {/* D5: Relapse, Continued Use, or Continued Problem Potential */}
          <Section
            title="Dimension 5: Relapse, Continued Use, or Continued Problem Potential"
            aside={<Switch horizontal inverse model="data.relapse_potential.not_applicable" />}
            scrollview={{
              id: 'relapse_potential',
              name: 'Dimension 5: Relapse, Continued Use, or Continued Problem Potential',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.relapse_potential"
          >
            <ContextHide when="data.relapse_potential.not_applicable" is={true}>
              <RelapsePotential />
            </ContextHide>
          </Section>

          <Divider />

          {/* D6: Recovery / Living Environment */}
          <Section
            title="Dimension 6: Recovery / Living Environment"
            aside={<Switch horizontal inverse model="data.recovery_environment.not_applicable" />}
            scrollview={{
              id: 'recovery_environment',
              name: 'Dimension 6: Recovery / Living Environment',
              parent: { id: 'dimensions', name: 'Dimensions' },
            }}
            commentsModel="data.recovery_environment"
          >
            <ContextHide when="data.recovery_environment.not_applicable" is={true}>
              <RecoveryEnvironment />
            </ContextHide>
          </Section>

          <Divider />

          {/* Risk Factors */}
          <Section
            title="Risk Factors"
            scrollview={{
              id: 'risk_factors',
              name: 'Risk Factors',
              parent: { id: 'suicide_assessment', name: 'Suicide Assessment' },
            }}
            commentsModel="data.risk_factors"
          >
            <RiskFactors />
          </Section>

          <Divider />

          {/* Protective Factors */}
          <Section
            title="Protective Factors"
            description="Protective factors may not counteract significant acute suicide risk factors"
            scrollview={{
              id: 'protective_factors',
              name: 'Protective Factors',
              parent: { id: 'suicide_assessment', name: 'Suicide Assessment' },
            }}
            commentsModel="data.protective_factors"
          >
            <ProtectiveFactors />
          </Section>

          <Divider />

          {/* Thoughts, Plans, and Suicidal Intent */}
          <Section
            title="Thoughts, Plans, and Suicidal Intent"
            scrollview={{
              id: 'thoughts_plans_and_suicidal_intent',
              name: 'Thoughts, Plans, and Suicidal Intent',
              parent: { id: 'suicide_assessment', name: 'Suicide Assessment' },
            }}
            commentsModel="data.thoughts_plans_and_suicidal_intent"
          >
            <ThoughtsPlansSuicidalIntent data={data?.data?.thoughts_plans_and_suicidal_intent} />
          </Section>

          <Divider />

          {/* Level of Risk and Interventions */}
          <Section
            title="Level of Risk and Interventions"
            scrollview={{
              id: 'level_of_risk_and_interventions',
              name: 'Level of Risk and Interventions',
              parent: { id: 'suicide_assessment', name: 'Suicide Assessment' },
            }}
            commentsModel="data.level_of_risk_and_interventions"
          >
            <LevelOfRiskAndInterventions />
          </Section>

          <Divider />

          {/* Documentation */}
          <Section
            title="Documentation"
            scrollview={{
              id: 'suicide_risk_documentation',
              name: 'Documentation',
              parent: { id: 'suicide_assessment', name: 'Suicide Assessment' },
            }}
            commentsModel="data.suicide_risk_documentation"
          >
            <SuicideRiskDocumentation />
          </Section>

          <Section
            title="Strengths, Needs, Abilities, Preferences"
            aside={<Switch horizontal inverse model="data.snap.not_applicable" />}
            scrollview={{
              id: 'snap',
              name: 'S.N.A.P.',
            }}
            commentsModel="data.snap"
          >
            <ContextHide when="data.snap.not_applicable" is={true}>
              <SNAP />
            </ContextHide>
          </Section>

          <Divider />

          {/* Diagnoses */}
          <Section
            title="Diagnoses"
            aside={<Switch horizontal inverse model="data.diagnoses.not_applicable" />}
            scrollview={{
              id: 'diagnoses',
              name: 'Diagnoses',
            }}
            commentsModel="data.diagnoses_section"
          >
            <ContextHide when="data.diagnoses.not_applicable" is={true}>
              <Diagnoses
                model="data.diagnoses.diagnoses"
                isEditable={isEditable}
                client={client}
                icon="diagnosis"
                title="Diagnoses"
                emptyDescription="No diagnoses added yet"
              />
            </ContextHide>
          </Section>

          <Divider />

          {/* Old Diagnoses */}
          {data?.diagnoses?.substance && (
            <>
              <Section
                title="Previous Diagnoses Section"
                scrollview={{
                  id: 'old_diagnoses',
                  name: 'Previous Diagnoses Section',
                }}
                commentsModel="data.previous_diagnoses_section"
              >
                <ContextHide when="data.diagnoses.not_applicable" is={true}>
                  <FormSection>
                    <SubstanceUseDiagnosisCodes />
                    <OtherDiagnosisCodes />
                  </FormSection>
                </ContextHide>
              </Section>

              <Divider />
            </>
          )}

          {/* Summary */}
          <Section
            title="Summary"
            aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
            scrollview={{
              id: 'summary',
              name: 'Summary',
            }}
            commentsModel="data.summary"
          >
            <ContextHide when="data.summary.not_applicable" is={true}>
              <FormSection layout="vertical">
                <SmartTextarea useDictation label="Interpretive Summary" model="data.summary.interpretive_summary" />
                <SmartTextarea useDictation label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
                <SmartTextarea useDictation useQuickText label="Recommendations" model="data.summary.recommendations" />
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          {/* Single Case Agreement */}
          <Section
            title="Single Case Agreement"
            aside={<Switch horizontal inverse model="data.single_case_agreement.not_applicable" value={true} />}
            scrollview={{
              id: 'single_case_agreement',
              name: 'Single Case Agreement',
            }}
            commentsModel="data.single_case_agreement"
          >
            <ContextHide when="data.single_case_agreement.not_applicable" is={true}>
              <FormSection layout="vertical">
                <SmartTextarea useDictation label="Agreement Justification" model="data.single_case_agreement.justification" />

                {!isNew && (
                  <SmartTextarea
                    useDictation
                    isEditable={isBehave}
                    label="Behave Health Notes"
                    model="data.single_case_agreement.behave_notes"
                  />
                )}
              </FormSection>
            </ContextHide>
          </Section>
        </>
      )}
    </DataFormOverlay>
  )
}

export const EatingDisorderBPSOverlay = withOverlayError(RootEatingDisorderBPSOverlay)
