import React from 'react'
import { useQueryClient } from 'react-query'
import compact from 'lodash/compact'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { age, address, usDateTime } from '../../../../utils/functions'
import { ICONS } from '../../../../theme'
import { useOverlay } from '../../../../hooks/useOverlay'
import { useSettings } from '../../../../hooks/useSettings'
import { useCreate, useUpdate } from '../../../../hooks/useNewAPI'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import Accordions from '../../../Accordions'
import Alert from '../../../Alert'
import Attachments from '../../../Forms/Attachments'
import Avatar from '../../../Avatar'
import Button from '../../../Button'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import ColorSelector from '../../../Forms/ColorSelector'
import ConfirmDialog from '../../../Dialogs/ConfirmDialog'
import ContextShow from '../../../Forms/ContextShow'
import DataList from '../../../DataList'
import DateInput from '../../../Forms/DateInput'
import DeleteDialog from '../../../Dialogs/DeleteDialog'
import Divider from '../../../Divider'
import EventCategorySelect from '../../../Elements/EventCategorySelect'
import EventStatus from '../../../Statuses/EventStatus'
import Flex from '../../../Flex'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Glyph from '../../../Glyph'
import Grid from '../../../Grid'
import HelpTagIframe from '../../../Help/HelpTagIframe'
import Icon from '../../../Icon'
import Input from '../../../Forms/Input'
import Label from '../../../Label'
import Links from '../../../Forms/Links'
import Markup from '../../../Markup'
import MiniRichTextEditor from '../../../Forms/MiniRichTextEditor'
import MultiObjectSelector from '../../../Forms/Selectors/MultiObject/MultiObjectSelector'
import MultiOverlaySelector from '../../../Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Option from '../../../Forms/Option'
import Overlay from '../../../Overlay'
import OverlayLoader from '../../../OverlayLoader'
import OverlaySelector from '../../../Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../../Permission'
import Radio from '../../../Forms/Radio'
import RadioGroup from '../../../Forms/RadioGroup'
import Section from '../../../Section'
import Select from '../../../Forms/Select'
import State from '../../../State'
import Status from '../../../Status'
import SummonOverlay from '../../../SummonOverlay'
import TimeInputSelector from '../../../Forms/TimeInputSelector/TimeInputSelector'
import Timeline from '../../../Timeline/Timeline'
import URLInput from '../../../Forms/URLInput'

import EventOutcomes from './EventOutcomes'

import { JoinMeetingButton } from '../../../Buttons/JoinMeetingButton'

import { OUTCOMES } from './EventOutcomes'

import client_portal_event_example from '../../../../assets/images/client_portal_event_example.png'
import { EventAttendanceWorksheetOverlay } from './EventAttendanceWorksheetOverlay'

const EventOverlay = (props: any) => {
  const {
    isNew,
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialData,
    isDeleting,
    isEditable,
    isEmpty,
    isInvalid,
    isLoading,
    isSaving,
    onValidationUpdate,
    queryKey,
    save,
  } = useOverlay({
    name: 'event',
    endpoint: '/events',
    invalidate: 'events',
    invalidateKeys: compact(['event', props.invalidateKeys].flat()),
    onSaveSuccessful: props.onSaveSuccessful,
    options: props,
  })

  const { maxWidth = 40, position = 'right', showBackdrop = false } = props

  const queryClient = useQueryClient()

  const { timezone, isBehave, tenant } = useSettings()
  const [startedAt, setStartedAt]: any = React.useState()

  const { mutate: activateEvent, isLoading: isActivating }: any = useUpdate({
    name: queryKey,
    url: `/events/${id}/activate`,
    invalidate: 'events',
    invalidateKeys: ['event', id],
    onSuccess: ({ data }: any) => {
      queryClient.setQueryData(queryKey, data)
    },
  })

  const { mutate: cancelEvent, isLoading: isCancelling }: any = useUpdate({
    name: queryKey,
    url: `/events/${id}/cancel`,
    invalidate: 'events',
    invalidateKeys: ['event', id],
    onSuccess: ({ data }: any) => {
      queryClient.setQueryData(queryKey, data)
    },
  })

  const { mutateAsync: completeEvent, isLoading: isCompletingEvent }: any = useCreate({
    name: queryKey.push('complete'),
    url: `/events/${id}/complete`,
    invalidateKeys: ['event', 'events'],
  })

  const isHealthcare = tenant?.category === 'healthcare_facility' || tenant?.category === 'healthcare_practice'
  const isOutcomesEnabled = isHealthcare && data?.category && OUTCOMES.hasOwnProperty(data?.category) // show outcomes only for categories that support it
  const isCategoryDisabled = isOutcomesEnabled && size(data?.event_outcomes) > 0 // event category should not change if documentation was already created

  if (isLoading || isEmpty) {
    return <OverlayLoader position={position} maxWidth={maxWidth} showBackdrop={showBackdrop} />
  }

  const isCompleted = data?.status === 'completed'
  const isUsingDynamicImport = data?.clients_import_type === 'dynamic'
  const showAttendance =
    (data?.meeting_type === 'one_to_one' && !!data?.resident) || (data?.meeting_type === 'group_meeting' && size(data?.residents) > 0)

  const isAttendanceEmpty = size(data?.event_attendances) === 0

  return (
    <Overlay
      onClose={close}
      maxWidth={maxWidth}
      position={position}
      showBackdrop={showBackdrop || isEditable}
      transformOrigin={props.transformOrigin}
    >
      <Overlay.Header
        icon="calendar"
        title="Event"
        titleAside={<EventStatus status={data?.status} />}
        help={<HelpTagIframe id="event" />}
      />

      {data?.personal_meeting_room?.id && (
        <Permission featureFlagV2="telehealth" permission="telehealth.view">
          <Overlay.SubHeader>
            <JoinMeetingButton
              type="link"
              meetingID={data?.personal_meeting_room?.id}
              meetingName={`${data?.title} – Event Meeting Room`}
              label="Join Meeting Room"
              size={200}
            />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            ...initialData,
            ...data,
          }}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          timezone={timezone}
          linked={
            isNew && {
              recurring_frequency: 'never',
              event_type: 'event',
            }
          }
          key={`updated-${data?.updated_at}`}
        >
          <Section>
            <FormSection layout="vertical" maxWidth="100%">
              <Input
                label="Title"
                model="title"
                validations={{
                  presence: {
                    message: 'Please enter an event name',
                  },
                }}
              />

              <Flex gap="1rem">
                <div className="!flex-auto">
                  <EventCategorySelect
                    label="Event Category"
                    model="category"
                    defaultValue="general"
                    isDisabled={isCategoryDisabled}
                    tooltip={
                      isCategoryDisabled &&
                      'Category cannot be changed as documentation was already created for this event. To change category, please delete all documentation below.'
                    }
                  />
                </div>

                <ColorSelector label="Color" model="color" />
              </Flex>

              <DateInput
                label="Date"
                model="started_at"
                onUpdate={(target: any) => {
                  setStartedAt(target.value)
                }}
                validations={{
                  presence: {
                    message: 'Please select an event date',
                  },
                }}
              />

              <ContextShow when="is_all_day" is={false}>
                <FormSection horizontal>
                  <TimeInputSelector
                    grow
                    defaultToNow
                    label="Start Time"
                    model="start_time"
                    validations={{
                      presence: {
                        message: 'Please select when the event starts',
                      },
                    }}
                  />
                  <TimeInputSelector
                    grow
                    label="End Time"
                    model="end_time"
                    validations={{
                      presence: {
                        message: 'Please select when the event ends',
                      },
                    }}
                  />
                </FormSection>
              </ContextShow>

              <Checkbox label="All Day Event" model="is_all_day" defaultValue={false} />

              <MiniRichTextEditor useDictation useQuickText label="Public Description" model="public_description" />

              {isBehave && (
                <Select isEditable={isEditable} label="Billing Status" model="billing_status" defaultValue="non_billable">
                  <Option label="Non-billable" value="non_billable" />
                  <Option label="Billed" value="billed" />
                  <Option label="Not Billed" value="not_billed" />
                </Select>
              )}

              <div>
                <Accordions>
                  <Accordions.Item title="Staff" isOpen={size(data?.employees)}>
                    <FormSection>
                      <MultiOverlaySelector
                        model="employees"
                        type="employees.active"
                        icon={ICONS.employees}
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => startCase(data?.position)}
                      />
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Clients" isOpen={size(data?.resident) || size(data?.residents)}>
                    <FormSection>
                      <Checkbox
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="none"
                        label="Shared with Client Portal"
                        description={`Show this Event individually in each Clients' Client Portal`}
                        after={<Glyph glyph="portal" css={styles.meetingIcon} />}
                        model="share_with_client_portal"
                        tooltip={
                          <>
                            <div className="font-[600] mb-2">Example of how events appear in the Client Portal App:</div>

                            <img
                              src={client_portal_event_example}
                              alt="Client Portal Event Example"
                              width={518}
                              height={1200}
                              className="block w-[400px] h-auto rounded-md overflow-hidden shadow-soft-3"
                            />
                          </>
                        }
                      />

                      <Select label="Event Type" model="meeting_type" defaultValue="one_to_one" className="!flex-1">
                        <Option label="One-to-One" value="one_to_one" />
                        <Option label="Group Event" value="group_meeting" />
                      </Select>

                      <ContextShow when="meeting_type" is="one_to_one">
                        <OverlaySelector label="Client" type="clients.admissions_and_current" model="resident" icon={ICONS.clients} />
                      </ContextShow>

                      <ContextShow when="meeting_type" is="group_meeting">
                        {!isCompleted ? (
                          <>
                            <RadioGroup label="Attendees Import" model="clients_import_type" layout="vertical-dense" defaultValue="dynamic">
                              <Radio label="Automatic from Locations / Programs" value="dynamic" />
                              <Radio label="Manual from clients list" value="manual" />
                            </RadioGroup>

                            <ContextShow when="clients_import_type" is="dynamic">
                              <MultiObjectSelector
                                label="Import from Locations"
                                model="imported_houses"
                                type="properties"
                                icon={ICONS.properties}
                                selectTitle={(data: any) => data?.name}
                                selectDescription={(data: any) => address(data?.address)}
                              />

                              <RadioGroup
                                label="Programs Import Type"
                                model="programs_import_type"
                                layout="horizontal-dense"
                                defaultValue="programs"
                              >
                                <Radio label="Programs" value="programs" />
                                <Radio label="Program Lists" value="program_lists" />
                              </RadioGroup>

                              <ContextShow when="programs_import_type" is="programs">
                                <MultiObjectSelector
                                  label="Import from Programs"
                                  type="programs"
                                  model="imported_programs"
                                  icon={ICONS.checklist}
                                  selectTitle={(data: any) => data.name}
                                />
                              </ContextShow>

                              <ContextShow when="programs_import_type" is="program_lists">
                                <MultiObjectSelector
                                  label="Import from Program Lists"
                                  type="program_lists"
                                  model="imported_phases"
                                  icon={ICONS.checklist}
                                  selectTitle={(data: any) => data.name}
                                  selectDescription={(data: any) => data?.program?.name || '–'}
                                />
                              </ContextShow>

                              <Alert glyph="info">
                                <strong>Clients</strong> from the above <i>Locations</i>, <i>Programs</i> or <i>Program Lists</i> and will
                                be imported here when the event is Completed
                              </Alert>
                            </ContextShow>
                          </>
                        ) : (
                          <>
                            <RadioGroup
                              isEditable={false}
                              label="Attendees Import"
                              model="clients_import_type"
                              layout="vertical-dense"
                              defaultValue="dynamic"
                            >
                              <Radio label="Automatically from Locations / Programs" value="dynamic" />
                              <Radio label="Manually selected" value="manual" />
                            </RadioGroup>

                            <ContextShow when="clients_import_type" is="dynamic">
                              <MultiObjectSelector
                                isEditable={false}
                                label="Import from Locations"
                                model="imported_houses"
                                type="properties"
                                icon={ICONS.properties}
                                selectTitle={(data: any) => data?.name}
                                selectDescription={(data: any) => address(data?.address)}
                              />

                              <RadioGroup
                                isEditable={false}
                                label="Programs Import Type"
                                model="programs_import_type"
                                layout="horizontal-dense"
                                defaultValue="programs"
                              >
                                <Radio label="Programs" value="programs" />
                                <Radio label="Program Lists" value="program_lists" />
                              </RadioGroup>

                              <ContextShow when="programs_import_type" is="programs">
                                <MultiObjectSelector
                                  isEditable={false}
                                  label="Import from Programs"
                                  type="programs"
                                  model="imported_programs"
                                  icon={ICONS.checklist}
                                  selectTitle={(data: any) => data.name}
                                />
                              </ContextShow>

                              <ContextShow when="programs_import_type" is="program_lists">
                                <MultiObjectSelector
                                  isEditable={false}
                                  label="Import from Program Lists"
                                  type="program_lists"
                                  model="imported_phases"
                                  icon={ICONS.checklist}
                                  selectTitle={(data: any) => data.name}
                                  selectDescription={(data: any) => data?.program?.name || '–'}
                                />
                              </ContextShow>
                            </ContextShow>
                          </>
                        )}

                        {!isUsingDynamicImport && (
                          <MultiObjectSelector
                            label="Client Attendees"
                            type="applicants_and_clients"
                            model="residents"
                            icon={ICONS.clients}
                            selectTitle={(data: any) => data?.name}
                            selectDescription={(data: any) => `${startCase(data?.sex) || '–'}, ${age(data?.dob)} y/o, #${data?.behave_id}`}
                          />
                        )}

                        {isUsingDynamicImport && isCompleted && (
                          <>
                            <Alert glyph="info">
                              <b>Imported Clients</b> from the above <i>Locations</i>, <i>Programs</i> or <i>Program Lists</i> at{' '}
                              <strong>{usDateTime(data.processed_at)}</strong>
                            </Alert>

                            <MultiObjectSelector
                              label="Imported Client Attendees"
                              type="applicants_and_clients"
                              model="residents"
                              icon={ICONS.clients}
                              selectTitle={(data: any) => data?.name}
                              selectDescription={(data: any) =>
                                `${startCase(data?.sex) || '–'}, ${age(data?.dob)} y/o, #${data?.behave_id}`
                              }
                            />
                          </>
                        )}
                      </ContextShow>
                    </FormSection>
                  </Accordions.Item>

                  {!isCompleted && (
                    <Accordions.Item title="Notifications" isOpen={data?.should_send_reminders}>
                      <FormSection>
                        <Alert glyph="info" type="positive">
                          Notifications are being sent to the above <b>Staff & Clients</b>
                        </Alert>

                        <Label label="Time-based Reminders:" className="!pb-0" />

                        <Checkbox
                          label="Send SMS and Email Reminders"
                          model="should_send_reminders"
                          trueIcon="check"
                          falseIcon="cross"
                          falseStyle="faded-linethrough"
                        />

                        <ContextShow when="should_send_reminders" is={true}>
                          <div className="!pl-6">
                            <CheckboxGroup
                              label="Send Reminders Options"
                              layout="vertical-dense"
                              validations={{
                                presence: {
                                  message: 'Please select at least one option',
                                },
                              }}
                            >
                              <Checkbox label="Three days before the event" model="send_reminders.three_days" />
                              <Checkbox label="Two days before the event" model="send_reminders.two_days" />
                              <Checkbox label="One day before the event" model="send_reminders.one_day" />
                              <Checkbox label="Three hours before the event" model="send_reminders.three_hours" />
                              <Checkbox label="Two hours before the event" model="send_reminders.two_hours" />
                              <Checkbox label="One hour before the event" model="send_reminders.one_hour" />
                              <Checkbox label="15 min before event" model="send_reminders.15_minutes" />
                            </CheckboxGroup>
                          </div>
                        </ContextShow>
                      </FormSection>
                    </Accordions.Item>
                  )}

                  <Accordions.Item isOpen title="Attendance" featureFlagV2="attendance">
                    <FormSection>
                      <MiniRichTextEditor useQuickText label="Attendance Notes" model="attendance_notes" />

                      <Divider />

                      {isAttendanceEmpty && (
                        <State
                          isEmpty
                          minHeight={50}
                          emptyDescription="No attendance has been taken yet"
                          glyph={data?.meeting_type === 'one_to_one' ? 'user_neutral' : 'user_group'}
                        />
                      )}

                      <DataList>
                        {!isAttendanceEmpty &&
                          data.event_attendances.map((attendance: any) => {
                            return (
                              <DataList.Item
                                key={attendance.id}
                                label={
                                  <div className="flex flex-nowrap items-center">
                                    <Avatar
                                      src={attendance?.resident?.avatar}
                                      initials={attendance?.resident?.name}
                                      size={18}
                                      className="mr-1.5"
                                    />
                                    {attendance?.resident?.name}
                                  </div>
                                }
                                value={
                                  <div>
                                    {attendance.status === 'present' ? (
                                      <Status small label="Present" color="green" glyph="tick_circle" />
                                    ) : attendance.status === 'absent' ? (
                                      <Status small label="Absent" color="red" glyph="decline" />
                                    ) : (
                                      <div className="text-text-muted">–</div>
                                    )}

                                    {attendance.status === 'absent' && attendance.absentee_notes && (
                                      <div>
                                        <div className="font-[600] text-[0.88rem] mt-1">Absentee Note</div>
                                        <Markup value={attendance.absentee_notes} />
                                      </div>
                                    )}
                                  </div>
                                }
                              />
                            )
                          })}
                      </DataList>

                      {isEditable ? (
                        <Alert small glyph="info">
                          Save the event to take attendance
                        </Alert>
                      ) : (
                        <SummonOverlay
                          overlay={
                            <EventAttendanceWorksheetOverlay
                              event={data}
                              initialData={
                                size(data?.event_attendances) > 0
                                  ? data.event_attendances
                                  : data?.meeting_type === 'one_to_one'
                                  ? [{ resident: data?.resident, status: 'present' }]
                                  : data?.residents.map((resident: any) => ({ resident, status: 'present' }))
                              }
                            />
                          }
                        >
                          <Button
                            label={isAttendanceEmpty ? 'Take Attendance' : 'Edit Attendance'}
                            size={200}
                            type="primary"
                            glyph={data?.meeting_type === 'one_to_one' ? 'user_neutral' : 'user_group'}
                          />
                        </SummonOverlay>
                      )}
                    </FormSection>
                  </Accordions.Item>

                  {isOutcomesEnabled && data && !isEditable && (
                    <Accordions.Item minimal title="Documentation" isOpen={size(data?.event_outcomes) > 0}>
                      <EventOutcomes event={data} category={data?.category} />
                    </Accordions.Item>
                  )}

                  <Accordions.Item title="Place" isOpen={data?.meeting_place !== 'none'}>
                    <FormSection>
                      <Select label="Meeting Place" model="meeting_place" defaultValue="none" className="!flex-1">
                        <Option label="None" value="none" />
                        <Option label="Online" value="online" />
                        <Option label="Housing" value="property" />
                        <Option label="Office" value="office" />
                        <Option label="Organization" value="organization" />
                        <Option label="Other" value="other" />
                      </Select>

                      <ContextShow when="meeting_place" is="online">
                        <URLInput
                          label="Meeting Link"
                          model="meeting_url"
                          validations={{
                            presence: {
                              message: 'Please enter a meeting link',
                            },
                          }}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" is="property">
                        <ObjectSelector
                          icon={ICONS.properties}
                          label="Housing"
                          type="properties"
                          model="house"
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => address(data?.address)}
                          validations={{
                            presence: {
                              message: 'Please select a property',
                            },
                          }}
                        />

                        <ObjectSelector
                          icon={ICONS.rooms}
                          label="Room (optional)"
                          type="property.rooms"
                          model="room"
                          dependent="house_id"
                          disableUnless="house_id"
                          selectTitle={(data: any) => data?.name}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" is="office">
                        <ObjectSelector
                          icon={ICONS.properties}
                          label="Office"
                          type="offices"
                          model="house"
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => address(data?.address)}
                          validations={{
                            presence: {
                              message: 'Please select a property',
                            },
                          }}
                        />

                        <ObjectSelector
                          icon={ICONS.rooms}
                          label="Room (optional)"
                          type="property.rooms"
                          model="room"
                          dependent="house_id"
                          disableUnless="house_id"
                          selectTitle={(data: any) => data?.name}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" is="organization">
                        <ObjectSelector
                          label="Organization"
                          model="organization"
                          type="organizations"
                          icon={ICONS.organizations}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => address(data?.address)}
                          validations={{
                            presence: {
                              message: 'Please select an organization',
                            },
                          }}
                        />
                      </ContextShow>

                      <ContextShow when="meeting_place" within={['property', 'organization', 'online', 'other']}>
                        <MiniRichTextEditor useQuickText label="More Details" model="place_details" />
                      </ContextShow>
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Links" isOpen={size(data?.urls)}>
                    <Links model="urls" />
                  </Accordions.Item>

                  <Accordions.Item title="Notes" isOpen={!!data?.internal_notes}>
                    <FormSection>
                      <MiniRichTextEditor useQuickText label="Internal Notes" model="internal_notes" />

                      {isEditable && data?.status === 'active' && (
                        <Alert glyph="info">After-session notes will be visible after event is completed.</Alert>
                      )}

                      {data?.status === 'completed' && (
                        <>
                          <MiniRichTextEditor
                            useQuickText
                            isEditable={isEditable}
                            label="After-Session Notes"
                            model="after_session_notes"
                          />
                          <CheckboxGroup isEditable={isEditable} trueIcon="check" falseStyle="faded" falseIcon="cross">
                            <Checkbox isEditable={isEditable} label="After-Session Notes Completed" model="is_after_session_completed" />
                          </CheckboxGroup>
                        </>
                      )}
                    </FormSection>
                  </Accordions.Item>

                  <Accordions.Item title="Attachments" isOpen={size(data?.documents)}>
                    <FormSection layout="vertical">
                      <Attachments model="documents" labelAlign="top" labelJustify="top" />
                    </FormSection>
                  </Accordions.Item>
                </Accordions>
              </div>

              {data && <Timeline recordID={data?.id} recordType={data?.type} isLoadingRecord={isLoading} />}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Grid vertical gap="1rem">
          {!isEditable && isUsingDynamicImport && !isCompleted && (
            <ConfirmDialog
              glyph="check"
              title={`Import & Complete Event?`}
              onYes={completeEvent}
              yesLabel="Yes, Import Clients & Complete Event"
              message={
                <div>
                  <span>This will:</span>
                  <ol className="pl-[30px]">
                    <li className="mt-[10px] mb-[7px]">
                      Find <strong>matching Clients</strong> based on the selected <strong>Import</strong> configuration
                      <div>
                        <i>Please note:</i> we'll use your Today's Bed Management & Program Management data but we are working on recreating
                        it fully with historical data
                      </div>
                    </li>
                    <li className="mb-[7px]">
                      Import <strong>matching Clients</strong> as Client Attendees
                    </li>
                    <li className="mb-[7px]">
                      Update the <strong>Status</strong> of the Event to <EventStatus inline status="completed" />
                    </li>
                  </ol>
                  <div className="mt-4">Would you like to proceed?</div>
                </div>
              }
            >
              <Button
                label="Import Client Attendees & Complete Event…"
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                isLoading={isCompletingEvent}
                isDisabled={isInvalid}
                permission="events.edit"
              />
            </ConfirmDialog>
          )}

          {!isEditable && !isUsingDynamicImport && !isCompleted && (
            <ConfirmDialog
              glyph="check"
              title={`Complete Event?`}
              onYes={completeEvent}
              yesLabel="Yes, Complete Event"
              message={
                <div>
                  <span>
                    This will update the <strong>Status</strong> of the Event to <EventStatus inline status="completed" />
                  </span>

                  <div className="mt-4">Would you like to proceed?</div>
                </div>
              }
            >
              <Button
                label="Complete Event…"
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                onClick={save}
                isLoading={isSaving}
                isDisabled={isInvalid}
                permission="events.edit"
              />
            </ConfirmDialog>
          )}

          <Flex gap="0.5rem">
            {isEditable && (
              <>
                <Button
                  label="Save"
                  glyph="check"
                  type="primary"
                  color="green"
                  flex="100 1 auto"
                  onClick={save}
                  isLoading={isSaving}
                  isDisabled={isInvalid}
                  permission="events.edit"
                />
                <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
              </>
            )}

            {!isEditable && (
              <>
                <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" permission="events.edit" />

                {data?.status === 'cancelled' && (
                  <Button
                    label="Activate Event"
                    glyph="check"
                    color="green"
                    isLoading={isActivating}
                    onClick={() => {
                      activateEvent()
                    }}
                    permission="events.actions.activate"
                  />
                )}

                {(data?.status === 'active' || data?.status === 'completed') && (
                  <Button
                    label="Cancel Event"
                    glyph="decline"
                    glyphColor="red"
                    color="text"
                    isLoading={isCancelling}
                    onClick={() => {
                      cancelEvent()
                    }}
                    permission="events.actions.cancel"
                  />
                )}

                <DeleteDialog
                  title="Delete Event?"
                  message="Are you sure you want to delete this event? This action cannot be undone."
                  onYes={deleteRecord}
                >
                  <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} permission="events.delete" />
                </DeleteDialog>
              </>
            )}
          </Flex>
        </Grid>
      </Overlay.Footer>
    </Overlay>
  )
}

const styles = {
  meetingIcon: {
    marginLeft: 'auto',
  },
}

export default withOverlayError(EventOverlay)
