import React from 'react'

import SmartStatus from '../SmartStatus'

export const statuses = {
  not_reviewed: {
    label: 'Not Reviewed',
    color: 'gray',
  },
  reviewed: {
    label: 'Reviewed',
    color: 'green',
  },
  more_info_required: {
    label: 'More Info Required',
    color: 'yellow',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
