import React from 'react'
import { connect } from 'react-redux'

import { withFormContext } from '../Forms/context'
import ClientContact from '../Cards/ClientContact'

import Button from '../Button'
import SummonOverlay from '../SummonOverlay'
import { ContactOverlay } from '../../constructs/Contacts/ContactOverlay'

import { SmartContentBase, defaultMapDispatchToProps, defaultMapStateToProps } from './SmartContentBase'

import { apiGet } from '../../modules/api'

class Contacts extends SmartContentBase {
  getData = (id) => apiGet({ name: 'contacts', url: `/residents/${id}/contacts` })

  renderElement = (element, disabled = false) => (
    <SummonOverlay
      key={element.id}
      overlay={
        <ContactOverlay
          type="summon"
          dataID={element.id}
          referenceID={this.props.client?.id}
          referenceType="resident"
          initialData={element}
          onSaveSuccessful={this.fetchData}
          onDeleteSuccessful={this.fetchData}
        />
      }
      isDisabled={disabled}
    >
      <ClientContact showActions={false} contact={element} />
    </SummonOverlay>
  )

  renderTrigger = () => (
    <SummonOverlay
      overlay={
        <ContactOverlay
          type="summon"
          dataID="new"
          referenceID={this.props.client?.id}
          referenceType="resident"
          onSaveSuccessful={this.fetchData}
          onDeleteSuccessful={this.fetchData}
          // initialData={{ resident: this.props.client }}
        />
      }
    >
      <Button type="default" glyph="add" label="Add Contact" permission="contacts.create" />
    </SummonOverlay>
  )
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'contacts')
const mapStateToProps = (state) => defaultMapStateToProps(state, 'contacts')

export default withFormContext(connect(mapStateToProps, mapDispatchToProps)(Contacts))
