import React from 'react'
import get from 'lodash/get'
import { withFormContext } from '@behavehealth/components/Forms/context'

import Attachments from '@behavehealth/components/Forms/Attachments'
import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import FormSection from '@behavehealth/components/Forms/FormSection'
import Grid from '@behavehealth/components/Grid'
import Input from '@behavehealth/components/Forms/Input'
import State from '@behavehealth/components/State'
import Textarea from '@behavehealth/components/Forms/Textarea'
import { Heading } from '@behavehealth/components/Typography'

const H3 = ({ children }) => {
  return (
    <Heading is="h3" className="!m-0">
      {children}
    </Heading>
  )
}

class ProgramHighlights extends React.Component {
  constructor(props) {
    super(props)

    const modelVal = props.form?.getField(props.model)
    const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

    this.state = {
      program_highlights: modelVal || initialModelVal || [],
    }
  }

  /*
    CUSTOM
  */
  addProgramHighlight = () => {
    this.setState({
      program_highlights: [
        ...this.state.program_highlights,
        {
          name: '',
          description: '',
          gallery: [],
        },
      ],
    })
  }

  removeProgramHighlight = (idx) => {
    let program_highlights = this.state.program_highlights
    program_highlights.splice(idx, 1)
    this.setState({ program_highlights: program_highlights })
  }

  renderTrigger = () => {
    return (
      this.props.isEditable && (
        <div className="!mt-4">
          <Button glyph="add" label="Add Program Highlight" onClick={this.addProgramHighlight} />
        </div>
      )
    )
  }

  /*
    RENDER
  */
  render() {
    const { program_highlights } = this.state
    const isEmpty = program_highlights.length === 0

    return (
      <State isEmpty={isEmpty} emptyDescription="No program highlights" emptyActions={this.renderTrigger()}>
        <FormSection layout="vertical" maxWidth={600}>
          {program_highlights.map((_member, idx) => (
            <Grid gap={16} key={idx}>
              <Flex wrap justifyContent="space-between" alignItems="center">
                <H3>Program Highlight #{idx + 1}</H3>
                {this.props.isEditable && (
                  <Button
                    label="Remove"
                    glyph="delete"
                    type="minimal"
                    color="red"
                    size="small"
                    onClick={() => this.removeProgramHighlight(idx)}
                  />
                )}
              </Flex>

              <Input
                label="Title"
                model={`program_highlights[${idx}].name`}
                description="Example: 'Immersive Outdoor Program: From forest to beach'"
              />
              <Textarea
                label="Description"
                model={`program_highlights[${idx}].description`}
                description="Example: 'Our innovative Immersive Outdoor Program is therapeutic and fun!'"
              />
              <Attachments isInline label="Photos" model={`program_highlights[${idx}].gallery`} />
            </Grid>
          ))}
        </FormSection>

        {this.renderTrigger()}
      </State>
    )
  }
}

export default withFormContext(ProgramHighlights)
