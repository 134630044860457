import gmail from '../../assets/logos/third_party/gmail.svg'
import google_calendar from '../../assets/logos/third_party/google_calendar.svg'
import google_contacts from '../../assets/logos/third_party/google_contacts.svg'
import google_drive from '../../assets/logos/third_party/google_drive.svg'
import office from '../../assets/logos/third_party/office.svg'
import dropbox from '../../assets/logos/third_party/dropbox.svg'
import box_com from '../../assets/logos/third_party/box_com.svg'
import quickbooks from '../../assets/logos/third_party/quickbooks.svg'
import microsoft_outlook from '../../assets/logos/third_party/microsoft_outlook.svg'
import microsoft_exchange from '../../assets/logos/third_party/microsoft_exchange.svg'
import one_password from '../../assets/logos/third_party/one_password.svg'
import stripe from '../../assets/logos/third_party/stripe.svg'
import outlook_calendar from '../../assets/logos/third_party/outlook_calendar.svg'
import claim_md from '../../assets/logos/third_party/claim_md.png'
import behave from '../../assets/logos/logo_behave_health_small.svg'
import behave_ai from '../../assets/icons/ic_behave_ai.svg'
import ic_patient_outcome from '../../assets/icons/ic_patient_outcome.svg'

import { GoogleCalendarIntegrationSettings } from './settings/GoogleCalendarIntegrationSettings'
import { QuickbooksIntegrationSettings } from './settings/QuickbooksIntegrationSettings'
import { ZoomIntegrationSettings } from './settings/ZoomIntegrationSettings'
import { ClaimMdIntegrationSettings } from './settings/ClaimMdIntegrationSettings'
import { NaatpIntegrationSettings } from './settings/NaatpIntegrationSettings'
import { AnthropicClaudeIntegrationSettings } from './settings/AnthropicClaudeIntegrationSettings'
import { MicrosoftAzureIntegrationSettings } from './settings/MicrosoftAzureIntegrationSettings'

export const INTEGRATIONS: any = {
  google_calendar: {
    status: 'active',
    multi_setups: false,
    level: 'personal',
    category: 'calendar',
    label: 'Google Calendar',
    description: 'Automatically sync events to and from Google Calendar',
    icon: google_calendar,
    component: GoogleCalendarIntegrationSettings,
  },
  google_contacts: {
    status: 'coming_soon',
    multi_setups: false,
    level: 'personal',
    category: 'contacts',
    label: 'Google Contacts',
    description: 'Automatically sync contacts from Google Contacts',
    icon: google_contacts,
    component: GoogleCalendarIntegrationSettings,
  },
  microsoft_calendar: {
    status: 'coming_soon',
    multi_setups: false,
    level: 'personal',
    category: 'calendar',
    label: 'Microsoft Calendar',
    description: 'Automatically sync events to and from Microsoft Calendar',
    icon: outlook_calendar,
    component: GoogleCalendarIntegrationSettings,
  },
  microsoft_exchange_contacts: {
    status: 'coming_soon',
    multi_setups: false,
    level: 'personal',
    category: 'contacts',
    label: 'Microsoft Exchange Contacts',
    description: 'Automatically sync contacts from Microsoft Exchange',
    icon: microsoft_exchange,
    component: GoogleCalendarIntegrationSettings,
  },
  microsoft_outlook_contacts: {
    status: 'coming_soon',
    multi_setups: false,
    level: 'personal',
    category: 'contacts',
    label: 'Microsoft Outlook Contacts',
    description: 'Automatically sync contacts from Microsoft Outlook',
    icon: microsoft_outlook,
    component: GoogleCalendarIntegrationSettings,
  },
  quickbooks: {
    status: 'active',
    multi_setups: false,
    level: 'account',
    category: 'accounting',
    label: 'Quickbooks',
    description: 'Preview your Financial Health dashboard inside your app',
    icon: quickbooks,
    component: QuickbooksIntegrationSettings,
  },
  stripe: {
    status: 'coming_soon',
    multi_setups: false,
    level: 'account',
    category: 'payments',
    label: 'Stripe',
    description: 'Collect ACH and Card payments online',
    icon: stripe,
    component: GoogleCalendarIntegrationSettings,
  },
  rcopia: {
    status: 'active',
    multi_setups: false,
    level: 'account',
    category: 'erx',
    label: 'e-Prescribing',
    description: "E-Prescribe using DrFirst's Rcopia",
    icon: behave,
    component: GoogleCalendarIntegrationSettings,
  },
  change_health: {
    status: 'active',
    multi_setups: false,
    level: 'account',
    category: 'insurance',
    label: 'Change Healthcare',
    description: 'Automate your Insurance RCM',
    icon: behave,
    component: GoogleCalendarIntegrationSettings,
  },
  claim_md: {
    status: 'active',
    multi_setups: true,
    level: 'account',
    category: 'insurance',
    label: 'Claim MD',
    description: 'Automate your Insurance RCM',
    icon: claim_md,
    component: ClaimMdIntegrationSettings,
  },
  naatp: {
    status: 'active',
    multi_setups: false,
    level: 'account',
    category: 'treatment',
    label: 'NAATP',
    description: 'The National Association of Addiction Treatment Providers API',
    icon: ic_patient_outcome,
    component: NaatpIntegrationSettings,
  },
  anthropic_claude: {
    status: 'active',
    multi_setups: false,
    level: 'account',
    category: 'ai',
    label: 'Anthropic Claude',
    description: 'AI-powered personal assistant by Anthropic',
    icon: behave_ai,
    component: AnthropicClaudeIntegrationSettings,
  },
  microsoft_azure: {
    status: 'active',
    multi_setups: false,
    level: 'account',
    category: 'ai',
    label: 'Microsoft Azure OpenAI 1',
    description: 'Integrate OpenAI capabilities with Microsoft Azure',
    icon: behave_ai,
    component: MicrosoftAzureIntegrationSettings,
  },
  zoom: {
    status: 'active',
    multi_setups: false,
    level: 'account',
    category: 'contacts',
    label: 'Zoom',
    description: 'Automatically sync contacts from Zoom',
    icon: behave,
    component: ZoomIntegrationSettings,
  },
}
