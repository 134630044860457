import React from 'react'
import { useParams } from 'react-router-dom'

import { COLORS } from '@behavehealth/theme'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import Flex from '@behavehealth/components/Flex'
import Form from '@behavehealth/components/Forms/Form'
import Page from '@behavehealth/components/Page'
import PageLoader from '@behavehealth/components/Loaders/PageLoader'

import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'

import { PartnershipShareSettings } from '@behavehealth/constructs/Partnerships/PartnershipShareSettings'
import { useCommunityPermissions } from '@behavehealth/constructs/Community/useCommunityPermissions'

const RootShareSettings = () => {
  const params: any = useParams()

  const { canEditPartnerShareSettings } = useCommunityPermissions()

  const form = React.useRef(null)
  const [isEditable, setIsEditable] = React.useState(false)

  const { resource_id: id } = params

  const { data: organization, isLoading }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  const { mutateAsync: updatePartnership, isLoading: isUpdating }: any = useUpdate({
    name: ['update-partnership'],
    url: `/organizations/${id}`,
    invalidate: ['organization', id],
  })

  const edit = () => {
    setIsEditable(true)
  }

  const cancel = () => {
    setIsEditable(false)
  }

  const save = async () => {
    const values = form.current.getFormValue()

    await updatePartnership(values)

    setIsEditable(false)
  }

  if (!organization || isLoading) return <PageLoader />

  const partner = organization.other_partner

  return (
    <Page title="Share Settings" icon="settings">
      <Card css={STYLES.columns}>
        <div css={STYLES.column}>
          <div className="px-4 py-2 font-[600] border-b border-0 border-solid border-divider">What you share with {partner?.name}</div>

          <Form isCompact getForm={form} isEditable={isEditable} className="px-4 py-3" initialModel={organization}>
            <PartnershipShareSettings showInfo showSelectors direction="out" excludePartnershipIds={[organization.id]} />

            {canEditPartnerShareSettings && (
              <div className="mt-3">
                {isEditable ? (
                  <Flex gap="0.75rem">
                    <Button
                      label="Save"
                      glyph="check"
                      type="primary"
                      color="green"
                      onClick={save}
                      isLoading={isUpdating}
                      display="inline-flex"
                      size={200}
                      className="!min-w-[100px]"
                    />
                    <Button label="Cancel" glyph="cross" type="default" onClick={cancel} size={200} />
                  </Flex>
                ) : (
                  <>
                    <Button glyph="edit" label="Edit Share Settings" type="primary" onClick={edit} display="inline-flex" size={200} />
                  </>
                )}
              </div>
            )}
          </Form>
        </div>

        <div css={STYLES.column}>
          <div className="px-4 py-2 font-[600] border-b border-0 border-solid border-divider">What {partner?.name} shares with you</div>
          <Form isCompact isEditable={false} className="px-4 py-3" initialModel={organization}>
            <PartnershipShareSettings direction="in" />
          </Form>
        </div>
      </Card>
    </Page>
  )
}

const STYLES = {
  columns: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  column: {
    flex: '1 1 440px',
    boxShadow: `1px 1px 0 0 ${COLORS.divider}`,
  },
}

export const ShareSettings = withPageError(RootShareSettings)
