import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'

import { Nav, PageGrid, HelpTagIframe } from '@behavehealth/components'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import SettingsNavItems from '@behavehealth/components/Navs/SettingsNavItems'

import { AIActions } from './pages/ai_actions'
import { ApplicationForms } from './pages/application_forms'
import { AssessmentsBuilder } from './pages/assessments_builder'
import { DischargesBuilder } from './pages/discharges_builder'
import { FormsBuilder } from './pages/forms_builder'
import { QuickText } from './pages/quick_text'
import { RoundsTemplates } from './pages/rounds_templates'
import { ShortLinks } from './pages/short_links'
import { Variables } from './pages/variables'

import Account from './pages/account'
import Accounting from './pages/accounting'
import AdminFiles from './pages/admin_files'
import AgreementTemplates from './pages/agreement_templates/agreement_templates'
import ArchivedFeeSchedules from './pages/archived/fee_schedules'
import ArchivedInsuranceCodes from './pages/archived/insurance_codes'
import ArchivedInsuranceServicesBook from './pages/archived/insurance_services_book'
import BehaveAgreements from './pages/behave_agreements'
import BehaveFiles from './pages/behave_files'
import Billing from './pages/billing/billing'
import BookmarksManager from './pages/bookmarks_manager'
import BusinessProfile from './pages/business_profile'
import CalendarTemplates from './pages/calendar_templates'
import ClientServices from './pages/client_services'
import ClientsPermissions from './pages/clients_permissions'
import ClientWorkflows from './pages/client_workflows'
import ClientWorkflowsMessageTemplates from './pages/client_workflows_message_templates'
import CollaborateMD from './pages/collaborate_md'
import CommunityInviteTemplates from './pages/community_invite_templates'
import CompanyContacts from './pages/company_contacts'
import CompanyCredentials from './pages/company_credentials'
import CompanyIntegrations from './pages/company_integrations'
import CompanyProfile from './pages/company_profile'
import CustomNoteTemplates from './pages/custom_note_templates/custom_note_templates'
import FeeSchedules from './pages/fee_schedules'
import InsuranceCodes from './pages/insurance_codes'
import LegalAgreement from './pages/legal_agreement'
import LegalAgreements from './pages/legal_agreements'
import MeasureSettings from './pages/measure_settings'
import Notifications from './pages/notifications'
import OnlinePayments from './pages/online_payments'
import OrganizationServices from './pages/organization_services'
import PermissionTemplates from './pages/permission_templates'
import PersonalIntegrations from './pages/personal_integrations'
import Providers from './pages/providers'
import ReferralConsentFormTemplates from './pages/referral_consent_form_templates'
import Referrals from './pages/referrals'
import StaffAccess from './pages/access_lists/access_lists'
import StaffIPRestrictions from './pages/staff_ip_restrictions/staff_ip_restrictions'
import TagsManager from './pages/tags_manager'
import TodoTemplates from './pages/todo_templates'
import TreatmentPlanBook from './pages/treatment_plan_book/treatment_plan_book'
import TreatmentPlanTemplates from './pages/treatment_plan_templates'
import TreatmentPlanTemplatesV2 from './pages/treatment_plan_templates_v2'
import Widgets from './pages/widgets/widgets'
import YourSettings from './pages/your_settings'
import { useTreatmentPlanPermissions } from '@behavehealth/constructs/TreatmentPlans/useTreatmentPlanPermissions'

const Settings: React.FC = () => {
  const { isBehave, isOwner, isSelfServiceBilling } = useSettings()

  const user = useSelector((state: any) => state.me.user)
  const isManagement = ['owner', 'administrator'].includes(user?.position)
  const isAdministrator = ['owner', 'administrator'].includes(user?.position)
  const { viewSomeTreatmentPlanTemplates, viewSomeTreatmentBooks } = useTreatmentPlanPermissions()

  return (
    <PageGrid breakpoint={3} className="SettingsPage">
      <Nav title="Settings" icon="settings" breakpoint={3} headingSize={300} help={<HelpTagIframe id="settings_menu" />}>
        <SettingsNavItems />
      </Nav>

      <Routes>
        <Route index element={<Navigate to="your-profile" replace />} />
        <Route path="/settings/*" element={<Navigate to="/settings/your-profile" replace />} />

        <Route
          path="/settings/application-forms/*"
          element={
            <ProtectedRouteV6 permission="settings.application_form.view" featureFlagV2="custom_application_form">
              <ApplicationForms />
            </ProtectedRouteV6>
          }
        />
        <Route path="/settings/business-profile" element={<BusinessProfile />} />
        <Route
          path="/settings/company-credentials/*"
          element={
            <ProtectedRouteV6 permission="settings.credentials.view" featureFlagV2="company_credentials">
              <CompanyCredentials />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/company-integrations/*"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <CompanyIntegrations />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/company-profile"
          element={
            <ProtectedRouteV6 permission="settings.company_profile.company_settings.view">
              <CompanyProfile />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/notifications"
          element={
            <ProtectedRouteV6 permission={isAdministrator || isBehave}>
              <Notifications />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/widgets/*"
          element={
            <ProtectedRouteV6 featureFlagV2="admissions" permission="settings.embeds.view">
              <Widgets />
            </ProtectedRouteV6>
          }
        />
        <Route path="/settings/your-profile" element={<YourSettings />} />
        <Route
          path="/settings/measure-settings/*"
          element={
            <ProtectedRouteV6 featureFlagV2="outcome_measures" permission="settings.form_settings.view">
              <MeasureSettings />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/forms-builder/*"
          element={
            <ProtectedRouteV6 featureFlagV2="forms_builder" permission="forms_builder.view">
              <FormsBuilder />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/short-links/*"
          element={
            <ProtectedRouteV6 featureFlagV2="short_links" permission="settings.short_links.view">
              <ShortLinks />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/tags-manager/*"
          element={
            <ProtectedRouteV6 featureFlagV2="tags" permission="tag_groups.view">
              <TagsManager />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/bookmarks-manager/*"
          element={
            <ProtectedRouteV6 featureFlagV2="bookmarks" permission="settings.bookmarks_manager.view">
              <BookmarksManager />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/quick-text/*"
          element={
            <ProtectedRouteV6 featureFlagV2="quick_text" permission="settings.quick_text.view">
              <QuickText />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/todo-templates/*"
          element={
            <ProtectedRouteV6 permission="todo_templates.view" featureFlagV2="todo_templates">
              <TodoTemplates />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/calendar-templates/*"
          element={
            <ProtectedRouteV6 permission="calendar_templates.view" featureFlagV2="calendar_templates">
              <CalendarTemplates />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/agreement-templates/*"
          element={
            <ProtectedRouteV6 featureFlagV2="client_agreement_templates">
              <AgreementTemplates />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/custom-note-templates/*"
          element={
            <ProtectedRouteV6 featureFlagV2="client_custom_note_templates">
              <CustomNoteTemplates />
            </ProtectedRouteV6>
          }
        />
        <Route path="/settings/company-contacts/*" element={<CompanyContacts />} />
        <Route
          path="/settings/treatment-plan-templates/*"
          element={
            <ProtectedRouteV6 permission={viewSomeTreatmentPlanTemplates} featureFlagV2="treatment_plans">
              <TreatmentPlanTemplatesV2 />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/referral-consent-form-templates/*"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <ReferralConsentFormTemplates />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/community/invite-templates/*"
          element={
            <ProtectedRouteV6 featureFlagV2="organizations" permission="settings.community_invite_templates.view">
              <CommunityInviteTemplates />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/archived-treatment-plan-templates/*"
          element={
            <ProtectedRouteV6 permission="settings.treatment_plan_templates.view" featureFlagV2="treatment_plan_templates">
              <TreatmentPlanTemplates />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/client-services/*"
          element={
            <ProtectedRouteV6 permission="settings.services_book.view" featureFlagV2="services_book">
              <ClientServices />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/organization-services/*"
          element={
            <ProtectedRouteV6 permission="settings.services_book.view" featureFlagV2="organization_services">
              <OrganizationServices />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/online-payments/*"
          element={
            <ProtectedRouteV6 permission="settings.online_payments.view" featureFlagV2="online_payments_collection_with_stripe">
              <OnlinePayments />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/accounting/*"
          element={
            <ProtectedRouteV6 featureFlagV2="accounting" permission="settings.accounting.view">
              <Accounting />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/providers/*"
          element={
            <ProtectedRouteV6 permission="settings.credentials.view" featureFlagV2="company_credentials">
              <Providers />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/fee-schedules/*"
          element={
            <ProtectedRouteV6
              featureFlagV2="fee_schedules"
              permission={isBehave ? true : isSelfServiceBilling ? 'settings.fee_schedules.view' : false}
            >
              <FeeSchedules />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/old-insurance-services-book/*"
          element={
            <ProtectedRouteV6 permission={isBehave} featureFlagV2="insurance_services">
              <ArchivedInsuranceServicesBook />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/old-insurance-codes/*"
          element={
            <ProtectedRouteV6 permission={isBehave} featureFlagV2="insurance_services">
              <ArchivedInsuranceCodes />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/old-fee-schedules/*"
          element={
            <ProtectedRouteV6
              featureFlagV2="fee_schedules"
              permission={isBehave ? true : isSelfServiceBilling ? 'settings.fee_schedules.view' : false}
            >
              <ArchivedFeeSchedules />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/clients-permissions/*"
          element={
            <ProtectedRouteV6 permission="settings.client_portal_permissions.view" featureFlagV2="client_portal_permissions">
              <ClientsPermissions />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/ip-restrictions/*"
          element={
            <ProtectedRouteV6 permission="access_controls.view" featureFlagV2="staff_ip_restrictions">
              <StaffIPRestrictions />
            </ProtectedRouteV6>
          }
        />
        <Route path="/settings/referrals/*" element={<Referrals />} />
        <Route
          path="/settings/legal-agreement/*"
          element={
            <ProtectedRouteV6 permission="settings.legal_agreement.view">
              <LegalAgreement />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/treatment-plan-book/*"
          element={
            <ProtectedRouteV6 permission={viewSomeTreatmentBooks} featureFlagV2="treatment_plans">
              <TreatmentPlanBook />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/legal-agreements/*"
          element={
            <ProtectedRouteV6 permission="settings.legal_agreement.view">
              <LegalAgreements />
            </ProtectedRouteV6>
          }
        />
        <Route path="/settings/personal-integrations/*" element={<PersonalIntegrations />} />
        <Route
          path="/settings/insurance-codes/*"
          element={
            <ProtectedRouteV6 permission="settings.insurance_codes.view" featureFlagV2="insurance_services">
              <InsuranceCodes />
            </ProtectedRouteV6>
          }
        />
        <Route
          path="/settings/billing/*"
          element={
            <ProtectedRouteV6 permission={isBehave || isAdministrator}>
              <Billing />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/admin-files/*"
          element={
            <ProtectedRouteV6 permission={isManagement || isBehave}>
              <AdminFiles />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/behave-files/*"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <BehaveFiles />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/behave-agreements/*"
          element={
            <ProtectedRouteV6 permission={isBehave || isOwner}>
              <BehaveAgreements />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/collaborate-md/*"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <CollaborateMD />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/account/*"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <Account />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/ai-actions/*"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <AIActions />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/rounds-templates/*"
          element={
            <ProtectedRouteV6 featureFlag="rounds_templates" permission="rounds_templates.view">
              <RoundsTemplates />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/variables/*"
          element={
            <ProtectedRouteV6 featureFlag="variables" permission="form_variables.view">
              <Variables />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/client-workflows/*"
          element={
            <ProtectedRouteV6 featureFlag="client_workflows" permission="settings.client_workflows.view">
              <ClientWorkflows />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/client-workflows-message-templates/*"
          element={
            <ProtectedRouteV6
              featureFlag="client_workflows_message_templates"
              permission="settings.client_workflows_message_templates.view"
            >
              <ClientWorkflowsMessageTemplates />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/assessments-builder/*"
          element={
            <ProtectedRouteV6 featureFlag="assessments_builder" permission="assessments_builder.view">
              <AssessmentsBuilder />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/discharges-builder/*"
          element={
            <ProtectedRouteV6 featureFlag="discharges_builder" permission="discharges_builder.view">
              <DischargesBuilder />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/staff-access/*"
          element={
            <ProtectedRouteV6 featureFlag="staff_clients_access" permission="access_controls.view">
              <StaffAccess />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/settings/permission-templates/*"
          element={
            <ProtectedRouteV6 featureFlag="permission_templates" permission="settings.permissions.view">
              <PermissionTemplates />
            </ProtectedRouteV6>
          }
        />
      </Routes>
    </PageGrid>
  )
}

export default withPageError(Settings)
