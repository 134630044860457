import React from 'react'

import { Link, NavLink } from 'react-router-dom-v5-compat'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import { nicePhoneNo } from '@behavehealth/utils/functions'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import LinkValue from '@behavehealth/components/LinkValue'
import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import SmartStatus from '@behavehealth/components/SmartStatus'

const STATUSES = {
  active: {
    label: 'Active',
    color: 'green',
  },
  archived: {
    label: 'Archived',
    color: 'red',
  },
  unknown: {
    label: 'Unknown',
    color: 'gray',
  },
}

const SYNC_STATUSES = {
  synced: {
    label: 'Synced',
    color: 'green',
  },
  processing: {
    label: 'Processing',
    color: 'yellow',
  },
  error: {
    label: 'Error',
    color: 'red',
  },
}

const referenceUrl = (data: object) => {
  let url = ''

  let residentStatusUrlPaths = {
    alumni: 'alumni',
    current: 'clients',
    intake: 'admissions',
  }

  if (data.reference.type == 'resident') {
    url = `/${residentStatusUrlPaths[data.reference.status]}/${data.reference.id}`
  } else if (data.reference.type == 'contact') {
    if (data.reference.reference.type == 'resident') {
      let resident = data.reference.reference
      url = `/${residentStatusUrlPaths[resident.status]}/${resident.id}/contacts/${data.reference.id}`
    } else if (data.reference.reference.type == 'local_organization') {
      let organization = data.reference.reference
      url = `/local_organizations/${organization.id}/contacts/${data.reference.id}`
    }
  } else if (data.reference.type == 'local_organization') {
    url = `/local_organizations/${data.reference.id}`
  }

  return url
}

const RootCustomers = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting-customers'],
    endpoint: `/accounting/customers`,
    // params: {...},
    localStorageKey: 'accounting_customers_v1',
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Quickbooks Name',
        model: 'display_name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell as={NavLink} id={data.id} value={data.display_name} to={`${data.id}`} />,
      },
      {
        title: 'Behave Record',
        model: 'reference.name',
        width: 260,
        disableHide: true,
        disableSort: true,
        // type: 'profile',
        formatValue: ({ value, data }: any) => {
          if (!data.reference) return null

          let url = referenceUrl(data)

          return <LinkCell isExternal value={value} href={url} avatar={data.reference.avatar || ''} />
        },
      },
      {
        title: 'Quickbooks',
        model: 'external_url',
        width: 160,
        disableHide: true,
        disableSort: true,
        formatValue: ({ value, data }: any) => {
          if (!value) return null

          return <LinkCell isExternal value="View Link" href={value} />
        },
      },
      {
        title: 'First Name',
        model: 'first_name',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Last Name',
        model: 'last_name',
        width: 260,
        disableHide: true,
      },
      {
        title: 'Quickbooks Status',
        model: 'status',
        width: 160,
        disableHide: true,
        formatValue: ({ value }) => <SmartStatus statuses={STATUSES} status={value} />,
      },
      {
        title: 'Sync Status',
        model: 'sync_status',
        width: 160,
        formatValue: ({ value }) => <SmartStatus statuses={SYNC_STATUSES} status={value} />,
      },
      {
        title: 'Last Synced At',
        model: 'last_railz_sync_at',
        width: 160,
        type: 'date_time',
      },
      {
        title: 'Phone',
        model: 'phone_no',
        width: 260,
        disableHide: true,
        formatValue: ({ value }) => {
          if (!value) return '-'

          return <LinkValue href={`tel:${value}`} value={nicePhoneNo(value)} />
        },
      },
      {
        title: 'Email',
        model: 'email',
        width: 260,
        disableHide: true,
        type: 'email',
      },
      {
        title: 'Website',
        model: 'website',
        width: 260,
        disableHide: true,
        formatValue: ({ value, data }: any) => {
          if (!value) return null

          return <LinkCell isExternal value={data.website} href={value} />
        },
      },
      {
        title: 'Balance',
        model: 'balance',
        width: 160,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Tax Number',
        model: 'tax_number',
        width: 160,
        disableHide: true,
      },
      {
        title: 'Quickbooks Last Updated',
        model: 'source_modified_date',
        width: 260,
        disableHide: true,
        type: 'date_time',
      },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Customers">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Customers"
          icon="clients"
          emptyDescription="No customers have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          columns={columns}
          {...tableProps}
          headerAfter={
            isBehave && (
              <Flex gap="0.5rem">
                <AccountingSyncButton
                  forceShow={true}
                  category="customers"
                  invalidate={tableProps.queryKey}
                  refetchUrl={'/accounting/sync?category=customers'}
                />
              </Flex>
            )
          }
        />
      </Grid>
    </Page>
  )
}

export const Customers = withPageError(RootCustomers)

const FILTERS_CONFIG = {
  display_name: {
    label: 'Display Name',
    type: 'string',
  },
  first_name: {
    label: 'First Name',
    type: 'string',
  },
  last_name: {
    label: 'Last Name',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'string',
  },
  default_currency_ref_id: {
    label: 'Default Currency',
    type: 'string',
  },
  phone_no: {
    label: 'Phone',
    type: 'string',
  },
  email: {
    label: 'Email',
    type: 'string',
  },
  website: {
    label: 'Website',
    type: 'string',
  },
  tax_number: {
    label: 'Tax Number',
    type: 'string',
  },
  balance: {
    label: 'Balance',
    type: 'number',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'datetime',
  },
}
