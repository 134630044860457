import React from 'react'
import clsx from 'clsx'
import pluralize from 'pluralize'

import { INPUT_STYLES } from '../../theme'
import Flex from '../Flex'
import Glyph from '../Glyph'

type Props = {
  autocomplete?: string
  autoFocus?: boolean
  className: string
  testKey?: any
  glyph?: string
  graphic?: any
  id: any
  max?: any
  maxLength?: any
  min?: any
  onBlur?: any
  onChange?: any
  onFocus?: any
  onKeyDown?: any
  onKeyUp?: any
  placeholder?: string
  prefix?: string
  readOnly?: boolean
  size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 12
  step?: number
  suffix?: string
  type: 'text' | 'number' | 'password' | 'email' | 'tel' | 'url'
  value: string
  autoCapitalize?: string
}

const BaseInput = (props: Props) => {
  const {
    autocomplete,
    autoFocus,
    className,
    testKey,
    glyph,
    graphic,
    id,
    max,
    maxLength,
    min,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    onKeyUp,
    placeholder,
    prefix,
    readOnly,
    size,
    step,
    suffix,
    type,
    value,
    autoCapitalize,
    ...rest
  } = props

  const ref: any = React.useRef(null)

  const shouldRenderWrapper = prefix || suffix || glyph || graphic

  const classNames = clsx({
    'has-graphic': !!(glyph || graphic),
    'has-prefix': prefix,
    'has-size': size,
    'has-suffix': suffix,
    [`size-${size}`]: size,
    [className]: className,
  })

  React.useEffect(() => {
    if (!autoFocus || !ref.current) return
    ref.current.focus()
  }, [])

  React.useEffect(() => {
    if (type !== 'number' || !ref.current) return

    const preventNumberScroll = (event: any) => {
      event.preventDefault()
    }

    // Prevent the value from changing when scrolling within a number input
    ref.current.addEventListener('wheel', preventNumberScroll)

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('wheel', preventNumberScroll)
      }
    }
  }, [type])

  const input = (
    <input
      ref={ref}
      id={id}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      value={value}
      step={step}
      min={min}
      max={max}
      maxLength={maxLength}
      autoFocus={autoFocus}
      readOnly={readOnly}
      autoComplete={autocomplete}
      css={styles.input}
      className={classNames}
      data-test={testKey}
      autoCapitalize={autoCapitalize}
    />
  )

  if (shouldRenderWrapper) {
    let pref = prefix
    let suff = suffix

    if (value && !isNaN(value) && value === 1 && pref) pref = pluralize.singular(pref)
    if (value && !isNaN(value) && value === 1 && suff) suff = pluralize.singular(suff)

    return (
      <Flex nowrap {...rest}>
        {glyph && <Glyph glyph={glyph} css={styles.glyph} size="1.25rem" />}
        {graphic && <div css={styles.graphic}>{graphic}</div>}

        {pref && (
          <div testKey={testKey} css={styles.prefix_suffix} className={clsx('input-prefix', !!(glyph || graphic) && 'has-graphic')}>
            {pref}
          </div>
        )}

        {input}

        {suff && (
          <div css={styles.prefix_suffix} className={clsx('input-suffix', !!(glyph || graphic) && 'has-graphic')}>
            {suff}
          </div>
        )}
      </Flex>
    )
  }

  return input
}

const styles: any = {
  prefix_suffix: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.05em 0.75em',
    background: 'var(--input-prefix-background)',
    boxShadow: 'inset 0 0 0 1px var(--input-prefix-border-color)',
    borderRadius: 'var(--input-border-radius)',
    textAlign: 'center',
    minHeight: INPUT_STYLES.minHeight,
    position: 'relative',

    '&.input-prefix': {
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',

      '&.has-graphic': {
        paddingLeft: '2.25rem',
      },
    },

    '&.input-suffix': {
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
    },
  },

  input: {
    ...INPUT_STYLES,
    paddingRight: 0,
    flex: '1 1 auto',

    '&.has-graphic': {
      paddingLeft: '2rem',

      '&.has-prefix': {
        paddingLeft: '0.75rem',
      },
    },

    '&.has-size': {
      flex: '0 0 auto',
    },

    '&.has-suffix': {
      width: 'auto',
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
    },

    '&.has-prefix': {
      width: 'auto',
      borderTopLeftRadius: '0 !important',
      borderBottomLeftRadius: '0 !important',
    },

    '&.size-1': { width: '2.5em !important' },
    '&.size-2': { width: '3.5em !important' },
    '&.size-3': { width: '4.5em !important' },
    '&.size-4': { width: '5.5em !important' },
    '&.size-5': { width: '6.5em !important' },
    '&.size-6': { width: '7.5em !important' },
    '&.size-7': { width: '8.5em !important' },
    '&.size-8': { width: '9.5em !important' },
    '&.size-9': { width: '10.5em !important' },
    '&.size-10': { width: '12em !important' },
    '&.size-12': { width: '14em !important' },
  },

  glyph: {
    position: 'absolute',
    top: '50%',
    left: '0.5rem',
    transform: 'translateY(-50%)',
    zIndex: 1,
  },

  graphic: {
    position: 'absolute',
    top: '50%',
    left: '0.5rem',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

BaseInput.defaultProps = {
  value: '',
}

export default BaseInput
