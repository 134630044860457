import React from 'react'
import { DateTime } from 'luxon'

import Text from '../Typography/Text'
import DateInput from './DateInput'
import FormSection from './FormSection'

import { DT } from '../../utils/functions'

const WeekStartInput = ({ model, label, initialDate }) => {
  const isDayMonday = (date) => date.getDay() === 1

  return (
    <FormSection horizontal>
      <DateInput label={label} model={model} value={initialDate} filterDate={isDayMonday} allowInput={false} css={styles} />
    </FormSection>
  )
}

const styles = {
  minWidth: 140,
}

WeekStartInput.defaultProps = {
  model: 'start_date',
  label: 'Start Date',
  initialDate: DateTime.local().plus({ weeks: 1 }).startOf('week').toISO(),
}

export default WeekStartInput
