import React from 'react'

import ContextHide from '../../components/Forms/ContextHide'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import Switch from '../../components/Forms/Switch'

import MedicalEvaluation from '../../components/Overlays/pages/DataForms/common/MedicalEvaluation'
import MentalStatusExam from '../../components/Overlays/pages/DataForms/common/MentalStatusExam'
import VitalSignsForm from '../../components/Overlays/pages/DataForms/common/VitalSignsForm'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootNursingReassessmentOverlay = () => (
  <DataFormOverlay
    title="Nursing Re-Assessment"
    category="nursing"
    subcategory="nursing_reassessment"
    requestClientSignature={false}
    requestAuthorSignature={true}
    requireSupervisor={false}
    signoffWithoutSupervisor={true}
    enableTreatmentPlanSelector
    icon="nursing"
  >
    <Section
      title="Vital Signs"
      aside={<Switch horizontal inverse model="data.bio_medical.not_applicable" />}
      scrollview={{
        id: 'vital_signs',
        name: 'Vital Signs',
      }}
      commentsModel="data.vital_signs"
    >
      <ContextHide when="data.bio_medical.not_applicable" is={true}>
        <VitalSignsForm showDateTime={false} showPainScale showComments requireHeight />
      </ContextHide>
    </Section>

    <Divider />

    {/* Medical Evaluation */}
    <Section
      title="Medical Evaluation"
      aside={<Switch horizontal inverse model="data.medical_evaluation.not_applicable" />}
      scrollview={{
        id: 'medical_evaluation',
        name: 'Medical Evaluation',
      }}
      commentsModel="data.medical_evaluation"
    >
      <ContextHide when="data.medical_evaluation.not_applicable" is={true}>
        <MedicalEvaluation />
      </ContextHide>
    </Section>

    <Divider />

    {/* Mental Status Exam */}
    <Section
      title="Mental Status Exam"
      aside={<Switch horizontal inverse model="data.mental_status_exam.not_applicable" />}
      scrollview={{
        id: 'mental_status_exam',
        name: 'Mental Status Exam',
      }}
      commentsModel="data.mental_status_exam"
    >
      <ContextHide when="data.mental_status_exam.not_applicable" is={true}>
        <MentalStatusExam />
      </ContextHide>
    </Section>

    <Divider />

    {/* Summary */}
    <Section
      title="Summary"
      aside={<Switch horizontal inverse model="data.summary.not_applicable" />}
      scrollview={{
        id: 'summary',
        name: 'Summary',
      }}
      commentsModel="data.summary"
    >
      <ContextHide when="data.summary.not_applicable" is={true}>
        <FormSection layout="vertical">
          <SmartTextarea useDictation label="Interpretive Summary" model="data.summary.interpretive_summary" />
          <SmartTextarea useDictation label="Additional Relevant Information" model="data.summary.additional_relevant_info" />
          <SmartTextarea useDictation useQuickText label="Recommendations" model="data.summary.recommendations" />
        </FormSection>
      </ContextHide>
    </Section>
  </DataFormOverlay>
)

export const NursingReassessmentOverlay = withOverlayError(RootNursingReassessmentOverlay)
