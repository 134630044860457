import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Form from '../../components/Forms/Form'
import Input from '../../components/Forms/Input'
import DateInput from '../../components/Forms/DateInput'
import AmountInput from '../../components/Forms/AmountInput'

import Overlay from '../../components/Overlay'

import SummonOverlay from '../../components/SummonOverlay'
import Section from '../../components/Section'
import OverlayLoader from '../../components/OverlayLoader'

import { SectionCard } from '../../components/SectionCard'
import { ERAServiceLineStatusOverlay } from './ERAServiceLineStatusOverlay'
import { ERAViewer } from './components/ERAViewer'
import { ERAClaims } from './components/ERAClaims'

const RootERAOverlay = (props: any) => {
  const { close, data, form, initialModel, isOverlayLoading, isEditable, isNew, onValidationUpdate } = useOverlay({
    name: 'insurance_era',
    endpoint: '/insurance_eras',
    invalidate: 'insurance_eras',
    options: props,
  })

  const { timezone } = useSettings()

  if (isOverlayLoading) return <OverlayLoader position="right" />

  return (
    <>
      <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable} position="right" maxWidth={86}>
        <Overlay.Header icon="accounting" title="ERA" />

        {initialModel?.insurance_new_payment && (
          <Overlay.SubHeader>
            <Flex centerY gap={8} justifyContent="space-between">
              <Button
                label={`Payment: ${initialModel?.insurance_new_payment?.identifier}`}
                link={`/posting/payments/${initialModel?.insurance_new_payment?.id}`}
                size={200}
                type="minimal"
                color="blue"
              />
            </Flex>
          </Overlay.SubHeader>
        )}

        <Overlay.Content
          css={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            display: 'grid',
            gridTemplateRows: '100%',
            gridTemplateColumns: '1fr',
          }}
        >
          <Form
            isCompact
            getForm={form}
            timezone={timezone}
            isEditable={false}
            initialModel={initialModel}
            onValidationUpdate={onValidationUpdate}
            maxWidth="100%"
            className="overflow-y-auto"
          >
            <Section>
              <Grid gap="0.75rem">
                <Flex gap="0.5rem">
                  <SectionCard title="ERA" className="!flex-grow-4">
                    <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2 mq1024:grid-cols-3 mq1440:grid-cols-4">
                      <Input label="Insurance Payer" value={data.insurance_local_payer?.name || '–'} />
                      <Input label="Facility Provider" value={data.provider?.credential || '–'} />

                      <Input label="ERA ID" value={data.identifier} />
                      <Input label="Clearing House Payer ID" value={data.clearing_house_payer_id} />

                      <Input label="Payment Method" value={data.payment_method} />
                      <Input label="Payment Format" value={data.payment_format} />
                      <DateInput label="Payment Date" value={data.paid_date} />

                      <Input label="Check Number" value={data.check_number} />
                      <AmountInput label="Paid Amount" value={data.paid_amount} />
                    </div>
                  </SectionCard>

                  <SectionCard title="Info" className="!flex-grow-1">
                    <div className="grid gap-4 grid-cols-1 mq600:grid-cols-2">
                      <DateTimeInput label="Created At" value={data.created_at} />
                      <DateTimeInput label="Last Updated At" value={data.updated_at} />
                    </div>
                  </SectionCard>
                </Flex>

                {/* <ERAViewer content={data?.content?.report_content} /> */}

                <div className="grid gap-3">
                  <ERAClaims asCard data={data?.insurance_era_claims} />
                </div>
              </Grid>
            </Section>
          </Form>
        </Overlay.Content>
      </Overlay>
    </>
  )
}

const SERVICE_LINES_DATA = [
  {
    id: '1',
    client: { name: 'John Doe', avatar: '' },
    insurance_claim_id: 'X5876',
    service_line_status: 'new',
    adjudicated_procedure_code: 'D4342',
    adj_amount: '125',
    adj_reason_code: '45',
    claim_adj_group_code: 'CO',
    claim_adj_group_code_value: 'Contractual Obligations',
    line_item_charge_amount: '125',
    line_item_provider_payment_amount: '0',
    service_date: '05-06-2023',
  },
  {
    id: '2',
    client: { name: 'Tianna Woods', avatar: '' },
    insurance_claim_id: 'X5876',
    service_line_status: 'new',
    adjudicated_procedure_code: 'D4342',
    adj_amount: '125',
    adj_reason_code: '45',
    claim_adj_group_code: 'CO',
    claim_adj_group_code_value: 'Contractual Obligations',
    line_item_charge_amount: '125',
    line_item_provider_payment_amount: '0',
    service_date: '05-06-2023',
  },
  {
    id: '3',
    client: { name: 'Cassandra Griffith', avatar: '' },
    insurance_claim_id: 'X5876',
    service_line_status: 'done',
    adjudicated_procedure_code: 'D4342',
    adj_amount: '125',
    adj_reason_code: '45',
    claim_adj_group_code: 'CO',
    claim_adj_group_code_value: 'Contractual Obligations',
    line_item_charge_amount: '125',
    line_item_provider_payment_amount: '0',
    service_date: '05-06-2023',
  },
  {
    id: '4',
    client: { name: 'Jakob Bullockds', avatar: '' },
    insurance_claim_id: 'X5876',
    service_line_status: 'done',
    adjudicated_procedure_code: 'D4342',
    adj_amount: '125',
    adj_reason_code: '45',
    claim_adj_group_code: 'CO',
    claim_adj_group_code_value: 'Contractual Obligations',
    line_item_charge_amount: '125',
    line_item_provider_payment_amount: '0',
    service_date: '05-06-2023',
  },
  {
    id: '5',
    client: { name: 'Rita Crosby', avatar: '' },
    insurance_claim_id: 'X5876',
    service_line_status: 'done',
    adjudicated_procedure_code: 'D4342',
    adj_amount: '125',
    adj_reason_code: '45',
    claim_adj_group_code: 'CO',
    claim_adj_group_code_value: 'Contractual Obligations',
    line_item_charge_amount: '125',
    line_item_provider_payment_amount: '0',
    service_date: '05-06-2023',
  },
]

export const ERAOverlay = withOverlayError(RootERAOverlay)
