import React from 'react'
import clsx from 'clsx'

import Glyph from './Glyph'
import Tooltip from './Tooltip'

const Label = (props: any) => {
  const {
    after,
    htmlFor,
    isRequired,
    isValid,
    label,
    description,
    tooltip,
    width,
    className,
    hideAsterisk,
    isCompact,
    tooltipTrigger,
    onClick,
    truncate,
  } = props

  const classNames = clsx(truncate && 'truncate', isCompact && 'is-compact', className)

  const Tag = props.as || 'label'

  return (
    <Tag
      htmlFor={htmlFor}
      css={styles}
      className={classNames}
      style={{
        ...(width && { '--field-label-width': width }),
      }}
      onClick={onClick}
    >
      <span className="flex-grow">
        {label}
        {isRequired && !hideAsterisk && <Glyph glyph={`label_${isValid ? 'valid' : 'required'}`} size={15} />}
        {description && <span> {description}</span>}
        {tooltip && (
          <Tooltip position="top" content={tooltip} css={tooltipStyles}>
            {tooltipTrigger}
          </Tooltip>
        )}
      </span>

      {after}
    </Tag>
  )
}

const styles = {
  display: 'flex',
  alignItems: 'center',
  flexBasis: 'var(--field-label-width)',

  fontWeight: 600,

  padding: 6,
  paddingLeft: 0,
  marginBottom: 0,

  '&.is-compact': {
    padding: '2px 4px 2px 0',
    fontSize: '0.88rem',
  },
}

const tooltipStyles = {
  position: 'relative',
  top: -2,
  marginLeft: '0.5rem',
}

export default Label
