import React from 'react'

import { COLORS, HARD_SHADOW } from '../../theme'

import Button from '../Button'
import Dropdown from '../Dropdown'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Icon from '../Icon'

import { AppSidebarContext } from './context'
import { AppSidebarNavItems } from './AppSidebarNavItems'

type Props = {
  actions?: React.ReactNode
  icon?: any
  glyph?: any
  glyphColor?: any
  title: string
}

export const AppSidebarHeader: React.FC<Props> = ({ icon, glyph, glyphColor, title, actions }) => {
  const { isFullScreen, toggleFullScreen, closeSidebar }: any = React.useContext(AppSidebarContext)

  return (
    <header css={styles.root}>
      <Flex centerY gap={8}>
        <div css={styles.menuButtonWrapper}>
          {!isFullScreen && (
            <Dropdown trigger={<Button hideLabel color="blue" glyph="menu" size={200} />}>
              <AppSidebarNavItems asDropdown />
            </Dropdown>
          )}
        </div>

        {icon && <Icon icon={icon} size={20} />}
        {glyph && <Glyph glyph={glyph} size={20} color={glyphColor} />}
        <h3 css={styles.title}>{title}</h3>
      </Flex>

      <Flex gap={8}>
        {actions}

        <Button
          hideLabel
          color="text"
          glyph={isFullScreen ? 'compress' : 'expand'}
          size={200}
          onClick={toggleFullScreen}
          css={styles.fullScreenButton}
        />

        {!isFullScreen && <Button hideLabel color="text" glyph="close" size={200} onClick={closeSidebar} css={styles.closeScreenButton} />}
      </Flex>
    </header>
  )
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.2rem 1rem',
    height: 50,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    background: COLORS.white,
    position: 'sticky',
    top: 0,
    zIndex: 3,
    flex: '0 0 auto',
  },

  title: {
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    fontSize: '1rem',
  },

  fullScreenButton: {
    display: 'none',

    '@media (min-width: 800px)': {
      display: 'inline-flex',
    },
  },

  closeScreenButton: {
    '@media(min-width: 600px)': {
      display: 'none',
    },
  },

  menuButtonWrapper: {
    '@media(min-width: 600px)': {
      display: 'none',
    },
  },
}
