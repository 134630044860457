import React from 'react'

import Button from '../../../components/Button'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Form from '../../../components/Forms/Form'
import Grid from '../../../components/Grid'
import Overlay from '../../../components/Overlay'
import Section from '../../../components/Section'

import { DATA_TRANSFER_GROUPS } from '../constants'

export const DataCategoriesOverlay = (props: any) => {
  const { initialModel, onSave, onClose } = props

  const form = React.useRef()
  const [formData, setFormData] = React.useState()

  const handleSave = () => {
    onSave?.(formData)
    onClose?.()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose}>
      <Overlay.Header title="Client Record Categories" icon="web_form" />

      <Overlay.Content>
        <Section>
          <Form isCompact isEditable getForm={form} initialModel={{ data_transfer_categories: initialModel }} onUpdate={setFormData}>
            <Grid gap="0.75rem">
              {DATA_TRANSFER_GROUPS.map((group) => (
                <CheckboxGroup key={group.id} label={group.label} layout="vertical-dense" trueIcon="check" falseStyle="hidden">
                  {group.items?.map?.((category: any) => (
                    <Checkbox
                      key={category.id}
                      label={category.label}
                      model={`data_transfer_categories.${category.id}`}
                      icon={category.icon}
                    />
                  ))}
                </CheckboxGroup>
              ))}
            </Grid>
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" type="primary" color="green" onClick={() => handleSave()} />
      </Overlay.Footer>
    </Overlay>
  )
}
