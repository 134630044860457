import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../theme'
import Glyph from '../../components/Glyph'

import { AppsHeaderButton } from './AppsHeaderButton'

import { useFeedbackStore } from '../../components/FeedbackTool'

export const AppsHeaderFeedbackTool = ({ isDesktop }: any) => {
  const open = useFeedbackStore((state) => state.open)

  if (isDesktop) {
    return (
      <div css={STYLES.root} onClick={open}>
        {isDesktop ? (
          <div css={STYLES.badge}>
            <Glyph glyph="star" color={COLORS.orange} size="1.4em" />
            <span>Support</span>
          </div>
        ) : (
          <AppsHeaderButton isDesktop={isDesktop}>
            <Glyph glyph="star" color={COLORS.orange} size={20} />
          </AppsHeaderButton>
        )}
      </div>
    )
  }

  return (
    <AppsHeaderButton isDesktop={isDesktop} onClick={open}>
      <Glyph glyph="star" color={COLORS.orange} size={20} />
    </AppsHeaderButton>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  badge: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.2em 0.7em 0.2em 0.5em',
    borderRadius: 100,
    background: tint(0.6, COLORS.gold),

    color: COLORS.black,
    fontSize: '0.8rem',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: 1,
    textTransform: 'uppercase',
    cursor: 'pointer',

    svg: {
      flex: '0 0 auto',
      marginRight: '0.25em',
    },
  },
}
