import React from 'react'
import { useAsyncDebounce } from 'react-table'

import Select from '../common/Select'
import ColorPicker, { ColorPickerProps } from '../../ColorPicker'
import ClearButton from '../../Forms/ClearButton'

const ColorSelectFilter = (props: any) => {
  const {
    column: { filterValue, setFilter },
  } = props

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 200)

  const clear = () => {
    setFilter(undefined)
  }

  return (
    <div css={styles.root}>
      <ColorPicker color={filterValue} onSelect={onChange} />
      <ClearButton isVisible={!!filterValue} onClick={clear} />
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
}

export default ColorSelectFilter
