import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { TIMEZONE } from '../utils/constants'

import { Tenant } from '../declarations/Tenant'
import { User } from '../declarations/User'

type Props = {
  idle: boolean
  online: boolean
  tenant: Tenant
  user: User
  timezone: string
}

type HOCWrapped<T = any> = (props: T & Props) => React.ReactNode

type HOC = <T = any>(Wrapped: React.FC<T>) => HOCWrapped<T>

const withSettings: HOC = (Wrapped: any) => (props: any) => <Wrapped {...props} />

const mapStateToProps = (state: any) => ({
  idle: state.common.global?.idle,
  online: state.common.global?.online,
  tenant: state.me.tenant,
  user: state.me.user,
  timezone: state.me.tenant?.timezone || TIMEZONE,
})

export default compose<React.FC<Props>>(connect(mapStateToProps), withSettings)
