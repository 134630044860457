import React from 'react'

import FormSection from '../../../../Forms/FormSection'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import ContextShow from '../../../../Forms/ContextShow'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'

const PastMedicalHistory = ({ showBPSHistory, showFamilyHistory, showSympthoms, showPhysicalSympthoms }) => {
  return (
    <FormSection layout="vertical">
      <RadioGroup
        label="Does the client have a history of medical issues?"
        model="data.past_medical_history.has_history_of_medical_issues"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.past_medical_history.has_history_of_medical_issues" is={true}>
        <SmartTextarea useDictation label="Please explain:" model="data.past_medical_history.has_history_of_medical_issues_extra" />
      </ContextShow>

      <RadioGroup label="Has the client ever had surgery?" model="data.past_medical_history.has_had_surgery" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.past_medical_history.has_had_surgery" is={true}>
        <SmartTextarea
          useDictation
          label="Please list surgery, approximate date and reason:"
          model="data.past_medical_history.has_had_surgery_extra"
        />
      </ContextShow>

      <RadioGroup
        label="Has the client ever had an injury to their head or neck?"
        model="data.past_medical_history.has_had_head_or_neck_injury"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.past_medical_history.has_had_head_or_neck_injury" is={true}>
        <SmartTextarea
          useDictation
          label="Please list circumstance of injury and approximate date:"
          model="data.past_medical_history.has_had_head_or_neck_injury_extra"
        />
      </ContextShow>

      <RadioGroup label="Has the client ever had a seizure?" model="data.past_medical_history.has_had_seizure" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.past_medical_history.has_had_seizure" is={true}>
        <SmartTextarea
          useDictation
          label="Please list circumstance of seizure and approximate date:"
          model="data.past_medical_history.has_had_seizure_extra"
        />
      </ContextShow>

      <RadioGroup
        label="Has the client ever had a positive TB test?"
        model="data.past_medical_history.positive_tb_test"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.past_medical_history.positive_tb_test" is={true}>
        <RadioGroup label="Has the client ever had a chest X-ray?" model="data.past_medical_history.chest_xray" layout="horizontal-dense">
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <ContextShow when="data.past_medical_history.chest_xray" is={true}>
          <SmartTextarea useDictation label="What were the results of the chest Xray?" model="data.past_medical_history.chest_xray_extra" />
        </ContextShow>
      </ContextShow>

      {showBPSHistory && (
        <SmartTextarea useDictation label="Biopsychosocial History:" model="data.past_medical_history.biopsychosocial_history" />
      )}

      {showFamilyHistory && (
        <SmartTextarea
          useDictation
          label="Family & Psychosocial History (abuse, neglect, substance abuse issues):"
          model="data.past_medical_history.family_biopsychosocial_history"
        />
      )}

      {showSympthoms && (
        <>
          <CheckboxGroup label="Is there any history or current symptoms of the following?" layout="vertical-dense">
            <Checkbox label="Weight loss" model="data.past_medical_history.symptoms.weight_loss" />
            <Checkbox label="Weight gain" model="data.past_medical_history.symptoms.weight_gain" />
            <Checkbox label="Decreased appetite" model="data.past_medical_history.symptoms.decreased_appetite" />
            <Checkbox label="Increased appetite" model="data.past_medical_history.symptoms.increased_appetite" />
            <Checkbox label="Decreased sleep" model="data.past_medical_history.symptoms.decreased_sleep" />
            <Checkbox label="Increased sleep" model="data.past_medical_history.symptoms.increased_sleep" />
            <Checkbox label="Crying spells" model="data.past_medical_history.symptoms.crying_spells" />
            <Checkbox label="Anhedonia" model="data.past_medical_history.symptoms.anhedonia" />
            <Checkbox label="Anergia" model="data.past_medical_history.symptoms.anergia" />
            <Checkbox label="Preoccupation" model="data.past_medical_history.symptoms.preoccupation" />
            <Checkbox label="Other" model="data.past_medical_history.symptoms.other" />
          </CheckboxGroup>

          <ContextShow when="data.past_medical_history.symptoms.other" is={true}>
            <SmartTextarea useDictation label="Other:" model="data.past_medical_history.symptoms.other_extra" />
          </ContextShow>
        </>
      )}

      {showPhysicalSympthoms && (
        <>
          <CheckboxGroup label="Are there concerning physical symptoms present in any of the following areas?" layout="vertical-dense">
            <Checkbox label="General" model="data.past_medical_history.physical_symptoms.general" />
            <Checkbox label="Dermatologic" model="data.past_medical_history.physical_symptoms.dermatologic" />
            <Checkbox label="Gastrointenstinal" model="data.past_medical_history.physical_symptoms.gastrointenstinal" />
            <Checkbox label="Cardiovascular" model="data.past_medical_history.physical_symptoms.cardiovascular" />
            <Checkbox label="Genitourinary" model="data.past_medical_history.physical_symptoms.genitourinary" />
            <Checkbox label="Musculoskeletal" model="data.past_medical_history.physical_symptoms.musculoskeletal" />
            <Checkbox label="Eyes/Ears/Nose" model="data.past_medical_history.physical_symptoms.eyes_ears_nose" />
            <Checkbox label="Hematological" model="data.past_medical_history.physical_symptoms.hematological" />
            <Checkbox label="Respiratory" model="data.past_medical_history.physical_symptoms.respiratory" />
            <Checkbox label="Throat/Mouth" model="data.past_medical_history.physical_symptoms.throat_mouth" />
            <Checkbox label="Other" model="data.past_medical_history.physical_symptoms.other" />
          </CheckboxGroup>
          <ContextShow when="data.past_medical_history.physical_symptoms.other" is={true}>
            <SmartTextarea useDictation label="Other:" model="data.past_medical_history.physical_symptoms.other_extra" />
          </ContextShow>
        </>
      )}
    </FormSection>
  )
}

PastMedicalHistory.defaultProps = {
  showBPSHistory: true,
  showFamilyHistory: true,
  showSympthoms: true,
  showPhysicalSympthoms: true,
}

export default PastMedicalHistory
