import React from 'react'
import { tint, transparentize } from 'polished'
import clsx from 'clsx'
import gfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'

import { COLORS } from '../../../../theme'
import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard'
import useStore from '../../../../modules/store'

import Avatar from '../../../../components/Avatar'
import Button from '../../../../components/Button'
import Card from '../../../../components/Card'
import CardTreeItem from '../../../../components/CardTreeItem'
import Glyph from '../../../../components/Glyph'
import Icon from '../../../../components/Icon'

const remarkPlugins = [gfm]

export const ChatMessage = ({ isStreaming, assistantGraphic, message, className, paddingX = '2rem' }: any) => {
  const newUser = useStore((state: any) => state.newUser)

  if (!message || !newUser) return null

  const { role, content } = message

  const author = message?.author || message?.user

  const isUser = role === 'user'
  const isAssistant = role === 'assistant'
  const isSettingChange = role === 'setting_change'

  const classNames = clsx(
    {
      'is-setting-change': isSettingChange,
      'is-assistant': isAssistant,
      'is-user': isUser,
    },
    className,
  )

  return (
    <div css={STYLES.root} className={classNames} style={{ '--padding-x': paddingX }}>
      <div css={STYLES.content}>
        <div css={STYLES.graphic}>
          {isAssistant ? (
            assistantGraphic || <Icon icon="behave_ai" />
          ) : isUser ? (
            <Avatar size="2rem" src="" glyph="user_neutral" initials={author?.name} />
          ) : isSettingChange ? (
            <Glyph glyph="info" color={COLORS.text} size={18} />
          ) : null}
        </div>

        <div css={STYLES.message}>
          <ReactMarkdown remarkPlugins={remarkPlugins} components={COMPONENTS}>
            {content}
          </ReactMarkdown>
        </div>

        {/* With time: enabled if needed */}
        {/* <Grid>
          <div css={STYLES.messageMeta}>{daysToWords(message.created_at, newUser.timezone)}</div>
          <div css={STYLES.message}>
            <ReactMarkdown remarkPlugins={remarkPlugins}>{content}</ReactMarkdown>
          </div>
        </Grid> */}
      </div>
    </div>
  )
}

const IframeLink = (props: any) => {
  const { children, href } = props

  return (
    <>
      <a target="_blank" rel="noopener noreferrer" href={href}>
        {children}
      </a>

      <CardTreeItem title="Preview Website" className="my-2 [&_ul]:!m-0">
        <iframe
          title="iframe"
          frameBorder="0"
          border="0"
          cellSpacing="0"
          css={STYLES.iframe}
          src={href}
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
        />
      </CardTreeItem>
    </>
  )
}

const CodeBlock = (props: any) => {
  const { children } = props

  const codeContent = children?.[0]?.props?.children || ''

  const { copy, didCopy } = useCopyToClipboard({
    text: codeContent,
    dismissAfter: 1000,
    notification: null,
  })

  return (
    <Card className="relative px-3 py-2 text-[0.9rem] my-3">
      <pre className="m-0 text-text opacity-90 cursor-text">{children}</pre>

      {codeContent && (
        <div className="absolute top-[0.5rem] right-[0.5rem]">
          <Button
            onClick={copy}
            glyph={didCopy ? 'tick_circle' : 'copy'}
            label={didCopy ? 'Copied' : 'Copy'}
            color={didCopy ? 'green' : 'blue'}
            type="default"
            size={100}
            display="inline-flex"
          />
        </div>
      )}
    </Card>
  )
}

const COMPONENTS = {
  a: IframeLink,
  pre: CodeBlock,
}

const STYLES = {
  root: {
    padding: '1rem var(--padding-x)',
    borderBottom: `1px solid ${COLORS.lightGray}`,

    '&.is-assistant': {
      background: COLORS.hover, // transparentize(0.25, COLORS.lightGray),
    },

    '&.is-setting-change': {
      background: tint(0.8, COLORS.vividBlue),
    },

    '&.is-user': {},
  },

  content: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    maxWidth: 640,
    margin: '0 auto',
  },

  graphic: {
    flex: '0 0 auto',
    width: '2rem',
    height: '2rem',
    marginRight: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  message: {
    // whiteSpace: 'pre-line',
    display: 'grid',
    flex: '1 1 auto',

    '& > *': {
      marginBottom: 0,
    },

    '& > *:first-child': {
      marginTop: 0,
    },

    '& > *:last-child': {
      marginBottom: 0,
    },

    table: {
      borderSpacing: 0,
      borderCollapse: 'collapse',
      fontVariant: 'tabular-nums',
      fontFeatureSettings: 'tnum',
      minWidth: 200,
      tableLayout: 'fixed',
    },

    'td, th': {
      padding: '0.5rem',
      textAlign: 'left',
      border: `1px solid ${COLORS.divider}`,

      '&.selectedCell': {
        background: transparentize(0.85, COLORS.blue),
      },

      p: {
        margin: 0,
      },
    },

    th: {
      background: COLORS.lightBackground,
    },
  },

  messageMeta: {
    fontSize: '0.8rem',
    color: COLORS.textMuted,
  },

  iframe: {
    borderStyle: 'none',
    width: '100%',
    minHeight: '650px',
    height: '100%',
  },
}
