import React from 'react'
import size from 'lodash/size'
import { useParams, useRouteMatch } from 'react-router-dom'

import { useDelete, useGet } from '@behavehealth/hooks/useNewAPI'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Chotomate, Button, DnDUploader, Card, CardContent, Page, Uploader, HelpTagIframe, Permission } from '@behavehealth/components'

import { FilesTable } from '@behavehealth/components/Tables'

const pageConfig = {
  feature: 'files',
  help: <HelpTagIframe id="files" />,
  marketingID: 'files',
}

const Files: React.FC = () => {
  const match = useRouteMatch()
  const params: any = useParams()

  const { data: organization }: any = useGet({
    name: ['organization', params?.resource_id],
    url: `/organizations/${params?.resource_id}`,
  })

  const { data, isLoading }: any = useGet({
    name: ['organization', params?.resource_id, 'files'],
    url: `/organizations/${params?.resource_id}/documents`,
  })

  const { mutate: deleteFiles } = useDelete({
    name: ['client', params?.resource_id, 'files'],
    url: '/documents',
    invalidate: 'files',
  })

  const isEmpty = size(data) === 0

  const actions = (
    <Uploader category="general" resource={organization}>
      <Button type="primary" glyph="upload" label="Upload Files" permission="organizations.files.create" />
    </Uploader>
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Chotomate name="files" ready={!isLoading} />

      <div className="grid grid-cols-[100%]">
        <Card>
          <FilesTable
            data={data}
            isLoading={isEmpty && isLoading}
            emptyActions={actions}
            localStorageKey="organizations_files"
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'organizations.files.delete',
                action: async ({ ids }: any) => {
                  await deleteFiles(ids.join(','))
                },
              },
            ]}
          />
          <CardContent>
            <Permission permission="organizations.files.create">
              <DnDUploader category="general" resource={organization} isEditable={true} isDisabled={false} />
            </Permission>
          </CardContent>
        </Card>
      </div>
    </Page>
  )
}

export default withPageError(withMarketing(Files, pageConfig))
