import React from 'react'
import { connect } from 'react-redux'
import startCase from 'lodash/startCase'

import { apiUpdate } from '../../../modules/api'
import { withOverlayError } from '../../../hocs/withOverlayError'

import Button from '../../Button'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import Overlay from '../../Overlay'
import Section from '../../Section'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'

const RESOURCE_TYPES: any = {
  properties: 'houses',
  programs: 'programs',
}
class AssignedEmployeesImportOverlay extends OverlayBase {
  save = async () => {
    await apiUpdate({
      name: 'programs',
      url: `/${RESOURCE_TYPES[this.props.match?.params?.resource]}/${this.props.match?.params?.resource_id}/assign_employees`,
      params: this.form.current.getFormValue(),
    })
    this.close()
  }

  renderHeader = () => <Overlay.Header icon="clients" title="Assign Staff Members" />

  renderContent = () => {
    const { record } = this.props

    return (
      <Overlay.Content>
        <Form isEditable getForm={this.form} initialModel={record} onValidationUpdate={this.onValidationUpdate}>
          <Section>
            <FormSection layout="vertical">
              <MultiObjectSelector
                label="Assign Staff Members"
                type="employees"
                model="employees"
                icon="employees"
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => startCase(data.position)}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { loading } = this.props

    return (
      <Overlay.Footer>
        <Button
          label="Assign Staff Members"
          glyph="check"
          type="primary"
          color="green"
          isLoading={loading}
          onClick={this.save}
          flex="3 1 auto"
          permission="files.edit"
        />
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'programs')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(AssignedEmployeesImportOverlay))
