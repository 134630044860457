import React from 'react'
import dompurify from 'dompurify'

import SingleSearchSelector from '../Selectors/SingleSearchObject/SingleSearchSelector'
import { CLEARING_HOUSES } from '../../../utils/constants'

const sanitize = dompurify.sanitize

const InsurancePayerSelector = ({
  icon = 'insurance',
  label = 'Insurance Payers',
  model = 'insurance_clearing_house_config',
  type,
  ...rest
}: any) => (
  <SingleSearchSelector
    isRelation
    showAvatars={false}
    label={label}
    model={model}
    type={type}
    modelSelector="api_encoded_id"
    selectTitle={(data: any, highlights: any) => {
      let title = data?.name
      if (!highlights) return title

      for (let i = 0; i < highlights?.length; i++) {
        if (highlights[i].field === 'name') {
          title = highlights[i].snippet
          break
        }
      }

      return <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
    }}
    selectDescription={(data: any) => {
      const isAllStates = data.states.length === 59

      let description = `House: ${CLEARING_HOUSES[data.category]}, eVOB: ${data.supports_eligibility ? 'Yes' : 'No'}, Claims: ${
        data.supports_claims ? 'Yes' : 'No'
      }`

      if (isAllStates) {
        return `${description}, National`
      } else {
        return `${description}, ${data.states.join(', ')}`
      }
    }}
    {...rest}
  />
)

export default InsurancePayerSelector
