import React from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'

import { COLORS } from '../../../theme'

import Button from '../../Button'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Dialog from '../../Dialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Glyph from '../../Glyph'
import Overlay from '../../Overlay'
import OverlayLoader from '../../OverlayLoader'
import Section from '../../Section'
import SummonOverlay from '../../SummonOverlay'
import Timeline from '../../Timeline/Timeline'

import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Option from '../../Forms/Option'
import Select from '../../Forms/Select'
import Textarea from '../../Forms/Textarea'

import AgreementSections from '../../Elements/AgreementSections'
import SignAgreementOverlay from '../../Overlays/pages/Agreements/SignAgreementOverlay'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../../Overlays/pages/OverlayBase'

import withRouterV6 from '../../../hocs/withRouterV6'

const PreviewLink = ({ onClick }) => {
  return (
    <div onClick={onClick} css={{ display: 'flex', alignItems: 'center' }}>
      <Glyph glyph="view" size={16} css={{ marginRight: '0.25rem' }} />
      <span
        css={{
          fontWeight: 500,
          color: COLORS.blue,
          cursor: 'pointer',
          '&:hover': { textDecoration: 'underline' },
        }}
      >
        Preview Agreement
      </span>
    </div>
  )
}

class OrganizationAgreementTemplateOverlay extends OverlayBase {
  onPreviewOpen = () => {
    this.setState({ formData: this.form.current?.getFormValue() })
  }

  render = () => {
    const { $new, $editable, isInvalid, params, formData } = this.state
    const { allowEditing = true, dataID, initialData, loading, online, record, timezone } = this.props

    const data = { ...params, ...record }
    const hasInitialData = initialData && dataID

    if (!$new && !hasInitialData && size(data) === 0) {
      return <OverlayLoader position="right" showBackdrop={this.state.$editable} />
    }

    return (
      <Overlay
        position="right"
        isLoading={!data}
        showBackdrop={this.state.$editable}
        maxWidth={82}
        closeWrapper={(element) => (
          <Dialog
            glyph="delete"
            title="Close without saving?"
            message="All changes will be lost. This action cannot be undone."
            yesColor="red"
            yesLabel="Yes, Close Without Saving"
            onYes={this.close}
            skip={!$editable}
          >
            {element}
          </Dialog>
        )}
      >
        <Overlay.Header
          title={data?.name || 'New Organization Agreement Template'}
          icon="legal_agreement_alt"
          description={
            <SummonOverlay onOpen={this.onPreviewOpen} overlay={<SignAgreementOverlay isPreviewMode showBackdrop agreement={formData} />}>
              <PreviewLink />
            </SummonOverlay>
          }
        />

        <Overlay.Content>
          <Form
            useFullModel
            getForm={this.form}
            timezone={timezone}
            initialModel={data}
            isEditable={$editable}
            onValidationUpdate={this.onValidationUpdate}
            linked={{
              template_type: 'custom',
              variant: 'organization',
            }}
          >
            <Section title="Agreement Details" headingType="h2">
              <FormSection maxWidth="100%">
                <Flex gap="1rem">
                  <Input
                    autoFocus
                    label="Agreement Name"
                    model="name"
                    validations={{
                      presence: {
                        message: 'Please enter a template name',
                      },
                    }}
                    className="!grow-[2]"
                  />

                  <Input
                    label="Order"
                    type="number"
                    model="order"
                    tooltip="Add a number to set the order of the agreement in the list"
                    size={4}
                  />
                </Flex>

                <Select label="Review Cycle" model="review_cycle">
                  <Option label="Daily" value="daily" />
                  <Option label="Weekly" value="weekly" />
                  <Option label="Monthly" value="monthly" />
                  <Option label="Quarterly" value="quarterly" />
                  <Option label="Semi-annually" value="semi_annually" />
                  <Option label="Annually" value="annually" />
                </Select>

                <Textarea useQuickText label="Notes" model="notes" />
              </FormSection>
            </Section>

            <Divider />

            <div>
              <AgreementSections
                model="sections"
                validations={{
                  presence: {
                    message: 'Please add at least one section',
                  },
                }}
              />
            </div>

            <Section
              title="Company"
              headingType="h2"
              aside={
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox
                    label="Require Company Signature"
                    model="request_cosigner_signature"
                    onUpdate={(state) => this.setState({ requestCoSignerSignature: state.value })}
                  />
                </CheckboxGroup>
              }
            />

            <Divider />

            <Section
              title="Signees"
              headingType="h2"
              aside={
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                  <Checkbox
                    label="Require Signees signature"
                    model="should_sign"
                    onUpdate={(state) => this.setState({ shouldSign: state.value })}
                  />
                </CheckboxGroup>
              }
            />

            {!$new && (
              <>
                <Divider />

                <Section headingType="h2" title="Timeline">
                  <Timeline isLoadingRecord={loading} recordID={data.id} recordType={data.type} />
                </Section>
              </>
            )}
          </Form>
        </Overlay.Content>

        {allowEditing && (
          <Overlay.Footer online={online}>
            {$editable && (
              <>
                <Button
                  glyph="check"
                  label="Save Agreement"
                  type="primary"
                  color="green"
                  isLoading={loading}
                  onClick={this.save}
                  isDisabled={isInvalid}
                  flex="100 1 240px"
                />

                {!$new && <Button glyph="cross" label="Cancel" type="default" isDisabled={loading} onClick={this.cancel} />}
              </>
            )}

            {!$editable && (
              <>
                <Button
                  label="Edit"
                  glyph="edit"
                  type="default"
                  isDisabled={loading}
                  onClick={this.edit}
                  flex="100 1 auto"
                  permission="settings.organization_agreement_templates.edit"
                />

                <DeleteDialog
                  title="Delete Agreement?"
                  message="Are you sure you want to delete this agreement? This action cannot be undone."
                  onYes={this.delete}
                >
                  <Button
                    label="Delete"
                    type="default"
                    glyph="delete"
                    color="red"
                    isLoading={loading}
                    fullWidth
                    permission="settings.organization_agreement_templates.delete"
                  />
                </DeleteDialog>
              </>
            )}
          </Overlay.Footer>
        )}
      </Overlay>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'agreement_templates')

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationAgreementTemplateOverlay)

export const OrganizationAgreementTemplateOverlayV6 = withRouterV6(
  connect(mapStateToProps, mapDispatchToProps)(OrganizationAgreementTemplateOverlay),
)
