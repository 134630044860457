import React from 'react'

import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import Input from '../../components/Forms/Input'
import RichTextEditor from '../../components/Forms/RichTextEditor'

import { QuoteSection } from './QuoteSection'
import { QuoteIframeForm } from './QuoteIframeForm'
import { PreviewData } from './PreviewData'

export const QuoteConfidentialityNDAForm = (props: any) => {
  const { isEditable, formData, onValidationUpdate, onUpdate } = props

  const form = React.useRef(null)

  return (
    <Form
      isCompact
      getForm={form}
      isEditable={isEditable}
      initialModel={formData}
      onValidationUpdate={onValidationUpdate}
      onUpdate={onUpdate}
    >
      <div className="p-5 pb-2">
        <QuoteIframeForm model="confidentiality_notice_nda" isEditable={isEditable} onUpdate={onUpdate} formData={formData} />
      </div>

      <QuoteSection
        showVariables
        title="Confidentiality Notice"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={!!formData?.use_confidentiality_section}
        enabledModel="use_confidentiality_section"
      >
        <RichTextEditor label="Confidentiality Notice" model="confidentiality_text" />
        <PreviewData isEditable={isEditable} model="confidentiality_text" />

        <Input label="Confidentiality Acknowledgement Legal Text" model="confidentiality_acknowledgement_legal_text" />
        <PreviewData isEditable={isEditable} model="confidentiality_acknowledgement_legal_text" />
      </QuoteSection>

      <Divider className="!my-0" />

      <QuoteSection
        showVariables
        title="NDA"
        onUpdate={onUpdate}
        isEditable={isEditable}
        isEnabled={!!formData?.use_nda_section}
        enabledModel="use_nda_section"
      >
        <RichTextEditor label="NDA" model="nda_text" />
        <PreviewData isEditable={isEditable} model="nda_text" />

        <Input label="NDA Acknowledgement Legal Text" model="nda_acknowledgement_legal_text" />
        <PreviewData isEditable={isEditable} model="nda_acknowledgement_legal_text" />
      </QuoteSection>
    </Form>
  )
}
