import React from 'react'

import { ROSTER_CATEGORIES } from '../../utils/constants'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

import withSettings from '../../hocs/withSettings'

const columns = (to?: Function, timezone?: string) => [
  {
    width: 200,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Title',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to && to(row.original.id)} label={value} />,
  },
  {
    width: 240,
    accessor: 'description',
    Header: 'Description',
  },
  // {
  //   width: 160,
  //   accessor: 'started_at',
  //   Header: 'Start Time',
  //   Cell: ({ value }: any) => <TableCell.UsTime value={value} timezone={timezone} />,
  // },
  // {
  //   width: 160,
  //   accessor: 'ended_at',
  //   Header: 'End Time',
  //   Cell: ({ value }: any) => <TableCell.UsTime value={value} timezone={timezone} />,
  // },
  {
    width: 160,
    accessor: 'roster_category',
    Header: 'Roster Type',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell.Empty />
      return <TableCell value={ROSTER_CATEGORIES[value]} />
    },
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  icon: string
  isLoading: boolean
  localStorageKey: string
  timezone: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const EventManagerTable = (props: Props) => {
  const { data, emptyActions, emptyDescription, icon, isLoading, localStorageKey, batchActionsConfig, title, to, timezone, titleAfter } =
    props

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

EventManagerTable.defaultProps = {
  title: 'Event Manager',
  icon: 'schedule',
  emptyDescription: 'No events have been created yet',
  localStorageKey: 'manager_events',
}

export default withSettings(EventManagerTable)
