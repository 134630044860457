import React from 'react'

import CardTreeItem from '../../../../CardTreeItem'

const DiagnosisAccordion: React.FC<any> = (props) => {
  const { children, icd10, icd10Description, isOpen = true, problemDescription, problemGroupDescription } = props

  return (
    <CardTreeItem
      isOpen={isOpen}
      title={`(${icd10}) ${icd10Description}`}
      subtitle={`${problemGroupDescription} → ${problemDescription}`}
      paddingY={0}
    >
      {children}
    </CardTreeItem>
  )
}

export default DiagnosisAccordion
