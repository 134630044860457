import React from 'react'
import { animateScroll, scroller, Element } from 'react-scroll'
import { v4 as uuid } from 'uuid'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import size from 'lodash/size'
import produce from 'immer'

import { COLORS } from '../../theme'
import { validate } from '../Forms/validators'
import { withFormContext } from '../Forms/context'

import Button from '../Button'
import Divider from '../Divider'
import Section from '../Section'
import State from '../State'
import Tooltip from '../Tooltip'

import DeleteDialog from '../Dialogs/DeleteDialog'
import FieldBase from '../Forms/FieldBase'
import FormSection from '../Forms/FormSection'
import Input from '../Forms/Input'
import SmartRichTextEditor from '../Forms/SmartRichTextEditor'

const NEW_SECTION = (id?: string) => ({ id: id || uuid(), name: '', content: '' })
const INITIAL_MODEL = [{ ...NEW_SECTION() }]

class CustomNoteSections extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    const vs = props.validations
    let value = props.value
    if (!value) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)
      value = modelVal || initialModelVal || INITIAL_MODEL
    }

    if (vs) errors = validate(value, vs)

    value = produce(value, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        draft[i].id = uuid()
      }
    })

    this.state = {
      type: 'CUSTOM_NOTE_SECTIONS',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value,
      isNested: props.isNested || false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isBlur: false,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
    }

    this.initialData = {
      value: value,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.debouncedScroll = debounce(
      () =>
        animateScroll.scrollToBottom({
          containerId: 'overlay-content',
          isDynamic: true,
          duration: 200,
        }),
      500,
    )

    this.scrollToElement = (id) => {
      const element = document.getElementById(id)

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }

    this.debouncedScrollTo = (to) =>
      debounce(
        () =>
          scroller.scrollTo(to, {
            containerId: 'overlay-content',
            duration: 200,
            smooth: 'easeInOutQuart',
            isDynamic: true,
            offset: 200,
          }),
        500,
      )

    this.updateType = 'DATA'
  }

  addNewSection = () => {
    const sectionId = uuid()

    const newValue = produce(this.state.value, (draft: any) => {
      draft.push(NEW_SECTION(sectionId))
    })

    this.changeValue(newValue)

    setTimeout(() => this.scrollToElement(sectionId), 0)
  }

  removeSection = (idx: number) => {
    if (size(this.state.value) === 0) return

    const newValue = produce(this.state.value, (draft: any) => {
      draft.splice(idx, 1)
    })

    this.changeValue(newValue)
  }

  updateSection = (idx: number, model: string, value: any) => {
    const newValue = produce(this.state.value, (draft: any) => {
      draft[idx][model] = value
    })

    this.changeValue(newValue)
  }

  moveSectionUp = (idx: number) => {
    if (idx === 0) return

    const newValue = produce(this.state.value, (draft: any) => {
      const toMove = draft[idx]

      draft.splice(idx, 1)
      draft.splice(idx - 1, 0, toMove)
    })

    this.changeValue(newValue)
  }

  moveSectionDown = (idx: number) => {
    if (idx >= size(this.state.value)) return

    const newValue = produce(this.state.value, (draft: any) => {
      const toMove = draft[idx]

      draft.splice(idx, 1)
      draft.splice(idx + 1, 0, toMove)
    })

    this.changeValue(newValue)
  }

  render = () => {
    const { value } = this.state

    const sectionsLength = size(value)
    const isEmpty = sectionsLength === 0

    if (!this.props.isEditable) {
      return value.map((section, idx) => (
        <React.Fragment key={section.id}>
          <Section>
            <h1
              css={{
                display: 'inline-block',
                marginBottom: '1rem',
                padding: '0.24em 0.6em',

                fontSize: '0.9rem',
                letterSpacing: 1,
                textTransform: 'uppercase',

                background: COLORS.text,
                color: COLORS.white,
                borderRadius: 5,
              }}
            >
              {section.name || `Section #${idx + 1}`}
            </h1>

            <SmartRichTextEditor useQuickText useDictation maxRows={20} value={section?.content} isEditable={false} />
          </Section>

          <Divider />
        </React.Fragment>
      ))
    }

    return (
      <>
        {!isEmpty && (
          <>
            {value.map((section, idx) => (
              <Element key={section.id} name={section.id}>
                <Section
                  id={section?.id}
                  title={value[idx].name || <span className="opacity-60">Section #{idx + 1}</span>}
                  headingType="h2"
                  aside={
                    <>
                      {value.length > 1 && (
                        <>
                          <DeleteDialog
                            glyph="delete"
                            title="Delete Section?"
                            message="This action cannot be undone."
                            noLabel="No, Cancel"
                            yesLabel="Delete Section"
                            onYes={() => this.removeSection(idx)}
                          >
                            <Button type="minimal" label="Remove" glyph="delete" color="red" />
                          </DeleteDialog>

                          <Tooltip content="Move section up">
                            <Button
                              glyph="triangle_up"
                              className="!p-0"
                              onClick={() => this.moveSectionUp(idx)}
                              isDisabled={idx === 0}
                              hideLabel
                            />
                          </Tooltip>

                          <Tooltip content="Move section down">
                            <Button
                              glyph="triangle_down"
                              className="!p-0"
                              onClick={() => this.moveSectionDown(idx)}
                              size={300}
                              isDisabled={idx === value.length - 1}
                              hideLabel
                            />
                          </Tooltip>
                        </>
                      )}
                    </>
                  }
                >
                  <FormSection maxWidth="100%" className="!mt-4">
                    <Input
                      label="Section Name"
                      validations={{
                        presence: {
                          message: 'Please enter a section name',
                        },
                      }}
                      value={value[idx].name}
                      className="!grow-[2]"
                      onUpdate={(state) => this.updateSection(idx, 'name', state.value)}
                    />

                    <SmartRichTextEditor
                      useQuickText
                      useDictation
                      onUpdate={(state) => this.updateSection(idx, 'content', state.value)}
                      maxRows={20}
                      value={value[idx]?.content}
                      validations={{
                        presence: {
                          message: 'Please add the section content',
                        },
                      }}
                    />
                  </FormSection>
                </Section>

                {idx + 1 !== sectionsLength && <Divider />}
              </Element>
            ))}

            <Section>
              <Button type="default" label="Add New Section" glyph="add" onClick={this.addNewSection} display="inline-flex" />
            </Section>
          </>
        )}

        {isEmpty && (
          <State
            isEmpty
            icon="legal_agreement_alt"
            title="Sections"
            emptyDescription="Create the first section"
            emptyActions={<Button type="default" label="Add First Section" glyph="add" onClick={this.addNewSection} />}
          />
        )}

        <Divider />
      </>
    )
  }
}

CustomNoteSections.defaultProps = {
  maxWidth: 'initial',
  withHover: false,
}

export default withFormContext(CustomNoteSections)
