import React from 'react'
import size from 'lodash/size'

import { CLINICAL_NOTES_TITLES, COMMUNICABLE_DISEASE_TESTS } from '../../utils/constants'
import { titleCase, usDate } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Permission from '../../components/Permission'
import Roadmap from '../../components/Roadmap/Roadmap'
import RoadmapRow from '../../components/Roadmap/RoadmapRow'

import { Allergy } from './records/Allergy'
import { Authorization } from './records/Authorization'
import { BedAssignment } from './records/BedAssignment'
import { BreathalyzerTest } from './records/BreathalyzerTest'
import { ClinicalAssessment } from './records/ClinicalAssessment'
import { ClinicalMeasure } from './records/ClinicalMeasure'
import { ClinicalNote } from './records/ClinicalNote'
import { CommunicableDiseaseTest } from './records/CommunicableDiseaseTest'
import { Diagnosis } from './records/Diagnosis'
import { Lab } from './records/Lab'
import { MedicalAssessment } from './records/MedicalAssessment'
import { Medication } from './records/Medication'
import { MedicationLog } from './records/MedicationLog'
import { NursingAssessment } from './records/NursingAssessment'
import { OutcomeMeasure } from './records/OutcomeMeasure'
import { PhysicianOrder } from './records/PhysicianOrder'
import { ProgressNote } from './records/ProgressNote'
import { ProgressReview } from './records/ProgressReview'
import { TreatmentEpisode } from './records/TreatmentEpisode'
import { UATest } from './records/UATest'
import { VitalSign } from './records/VitalSign'

import { Todo } from './records/Todo'
import { Event } from './records/Event'
import { FinancialTransaction } from './records/FinancialTransaction'
import { Application } from './records/Application'
import { Agreement } from './records/Agreement'
import { Communication } from './records/Communication'
import { Discharge } from './records/Discharge'
import { ShiftNote } from './records/ShiftNote'
import { RecoveryNote } from './records/RecoveryNote'
import { CustomNote } from './records/CustomNote'

import { GroupHeader } from './components/GroupHeader'

export const ClientJourneyTimeline: React.FC<any> = (props) => {
  const { className, client, data, dates, isLoading, isRefetching, setDates } = props

  const { isBehave, timezone } = useSettings()

  const [isNotesOpen, setIsNotesOpen] = React.useState(false)
  const [isGeneralOpen, setIsGeneralOpen] = React.useState(false)
  const [isCareCoordinationOpen, setIsCareCoordinationOpen] = React.useState(false)
  const [isClinicalOpen, setIsClinicalOpen] = React.useState(false)
  const [isMedicalOpen, setIsMedicalOpen] = React.useState(false)

  return (
    <Roadmap
      hideThreeMonthView
      showTodayLine
      className={className}
      isLoading={isLoading}
      isRefetching={isRefetching}
      dates={dates}
      onDatesChange={setDates}
    >
      {data && (
        <>
          {data.general && (
            <>
              <GroupHeader
                icon="general_info"
                title="General"
                isAllOpen={isGeneralOpen}
                onToggleOpen={() => {
                  setIsGeneralOpen((c) => !c)
                }}
              />

              {data.general.todos && (
                <Permission permission="todos.view" featureFlagV2="todos">
                  <RoadmapRow
                    isOpen={isGeneralOpen}
                    isDisabled={size(data.general.todos) === 0}
                    level={1}
                    icon="tasks"
                    title="To-Do's"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.general.todos.map((record: any) => <Todo key={record.id} data={record} client={client} />)
                    }
                  >
                    {data.general.todos.map((record: any) => (
                      <RoadmapRow level={3} title={record.title} renderBars={() => <Todo data={record} client={client} />} />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.general.events && (
                <Permission permission="events.view" featureFlagV2="calendar">
                  <RoadmapRow
                    isOpen={isGeneralOpen}
                    isDisabled={size(data.general.events) === 0}
                    level={1}
                    icon="calendar"
                    title="Events"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.general.events.map((record: any) => <Event key={record.id} data={record} client={client} />)
                    }
                  >
                    {data.general.events.map((record: any) => (
                      <RoadmapRow level={3} title={record.title} renderBars={() => <Event data={record} client={client} />} />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.general.financial_transactions && (
                <Permission permission="ledger.view" featureFlagV2="financials">
                  <RoadmapRow
                    isOpen={isGeneralOpen}
                    isDisabled={size(data.general.financial_transactions) === 0}
                    level={1}
                    icon="financials"
                    title="Financial Transactions"
                    renderBars={({ isOpen }: any) =>
                      !isOpen &&
                      data.general.financial_transactions.map((record: any) => (
                        <FinancialTransaction key={record.id} data={record} client={client} />
                      ))
                    }
                  >
                    {data.general.financial_transactions.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={titleCase(record.category)}
                        renderBars={() => <FinancialTransaction data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}
            </>
          )}

          {data.care_coordinations && (
            <>
              <GroupHeader
                icon="community"
                title="Care Coordination"
                isAllOpen={isCareCoordinationOpen}
                onToggleOpen={() => {
                  setIsCareCoordinationOpen((c) => !c)
                }}
              />

              {data.care_coordinations.applications && (
                <Permission permission="applications.view" featureFlagV2="admissions">
                  <RoadmapRow
                    isOpen={isCareCoordinationOpen}
                    isDisabled={size(data.care_coordinations.applications) === 0}
                    level={1}
                    icon="checklist"
                    title="Applications"
                    renderBars={({ isOpen }: any) =>
                      !isOpen &&
                      data.care_coordinations.applications.map((record: any) => (
                        <Application key={record.id} data={record} client={client} />
                      ))
                    }
                  >
                    {data.care_coordinations.applications.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={usDate(record.created_at, timezone)}
                        renderBars={() => <Application data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.care_coordinations.agreements && (
                <Permission permission="agreements.view" featureFlagV2="client_agreements">
                  <RoadmapRow
                    isOpen={isCareCoordinationOpen}
                    isDisabled={size(data.care_coordinations.agreements) === 0}
                    level={1}
                    icon="legal_agreement_alt"
                    title="Agreements"
                    renderBars={({ isOpen }: any) =>
                      !isOpen &&
                      data.care_coordinations.agreements.map((record: any) => <Agreement key={record.id} data={record} client={client} />)
                    }
                  >
                    {data.care_coordinations.agreements.map((record: any) => (
                      <RoadmapRow level={3} title={record.name} renderBars={() => <Agreement data={record} client={client} />} />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.care_coordinations.communications && (
                <Permission permission="communications.view" featureFlagV2="communication_logs">
                  <RoadmapRow
                    isOpen={isCareCoordinationOpen}
                    isDisabled={size(data.care_coordinations.communications) === 0}
                    level={1}
                    icon="communications"
                    title="Communications"
                    renderBars={({ isOpen }: any) =>
                      !isOpen &&
                      data.care_coordinations.communications.map((record: any) => (
                        <Communication key={record.id} data={record} client={client} />
                      ))
                    }
                  >
                    {data.care_coordinations.communications.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={data.contact?.name || 'Communication Log'}
                        renderBars={() => <Communication data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.care_coordinations.discharges && (
                <Permission permission="discharges.view" featureFlagV2="discharges">
                  <RoadmapRow
                    isOpen={isCareCoordinationOpen}
                    isDisabled={size(data.care_coordinations.discharges) === 0}
                    level={1}
                    icon="discharge_forms"
                    title="Discharges"
                    renderBars={({ isOpen }: any) =>
                      !isOpen &&
                      data.care_coordinations.discharges.map((record: any) => <Discharge key={record.id} data={record} client={client} />)
                    }
                  >
                    {data.care_coordinations.discharges.map((record: any) => (
                      <RoadmapRow level={3} title={record.name} renderBars={() => <Discharge data={record} client={client} />} />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {/* Authorizations */}
              {data.care_coordinations.authorizations && (
                <Permission permission={isBehave} featureFlagV2="insurance_authorizations">
                  <RoadmapRow
                    isOpen={isCareCoordinationOpen}
                    isDisabled={size(data.care_coordinations.authorizations) === 0}
                    level={1}
                    icon="authorizations"
                    title="Authorizations"
                    renderBars={({ isOpen }: any) =>
                      !isOpen &&
                      data.care_coordinations.authorizations.map((record: any) => (
                        <Authorization key={record.id} data={record} client={client} />
                      ))
                    }
                  >
                    {data.care_coordinations.authorizations.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={usDate(record.started_at, timezone)}
                        renderBars={() => <Authorization data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {/* Bed Assignments */}
              {data.care_coordinations.bed_assignments && (
                <Permission permission="bed_management.view" featureFlagV2="bed_management">
                  <RoadmapRow
                    isOpen={isCareCoordinationOpen}
                    isDisabled={size(data.care_coordinations.bed_assignments) === 0}
                    level={1}
                    icon="beds"
                    title="Bed Assignments"
                    renderBars={({ isOpen }: any) => {
                      if (isOpen) return null

                      return data.care_coordinations.bed_assignments.map((record: any) => (
                        <BedAssignment key={record.id} data={record} client={client} />
                      ))
                    }}
                  >
                    {data.care_coordinations.bed_assignments.map((record: any) => (
                      <RoadmapRow
                        isOpen
                        key={record.id}
                        level={3}
                        title={record.place?.name || 'Bed Assignment'}
                        renderBars={() => <BedAssignment data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {/* Treatment Episodes */}
              {data.care_coordinations.treatment_episodes && (
                <Permission>
                  <RoadmapRow
                    isOpen={isCareCoordinationOpen}
                    isDisabled={size(data.care_coordinations.treatment_episodes) === 0}
                    level={1}
                    icon="treatment_episodes"
                    title="Treatment Episodes"
                    renderBars={({ isOpen }: any) =>
                      !isOpen &&
                      data.care_coordinations.treatment_episodes.map((record: any) => (
                        <TreatmentEpisode key={record.id} data={record} client={client} />
                      ))
                    }
                  >
                    {data.care_coordinations.treatment_episodes.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.title || 'Treatment Episode'}
                        renderBars={() => <TreatmentEpisode data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}
            </>
          )}

          {/* Notes */}
          {data.notes && (
            <>
              <GroupHeader
                icon="custom_notes"
                title="Notes"
                isAllOpen={isNotesOpen}
                onToggleOpen={() => {
                  setIsNotesOpen((c) => !c)
                }}
              />

              {data.notes.shift_notes && (
                <Permission permission="shift_notes.view" featureFlagV2="shift_notes">
                  <RoadmapRow
                    isOpen={isNotesOpen}
                    isDisabled={size(data.notes.shift_notes) === 0}
                    level={1}
                    icon="housing_notes"
                    title="Shift Notes"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.notes.shift_notes.map((record: any) => <ShiftNote key={record.id} data={record} client={client} />)
                    }
                  >
                    {data.notes.shift_notes.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={titleCase(record.category)}
                        renderBars={() => <ShiftNote data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.notes.recovery_notes && (
                <Permission permission="recovery_coaching.view" featureFlagV2="recovery_coaching">
                  <RoadmapRow
                    isOpen={isNotesOpen}
                    isDisabled={size(data.notes.recovery_notes) === 0}
                    level={1}
                    icon="recovery_coaching"
                    title="Recovery Notes"
                    renderBars={({ isOpen }: any) =>
                      !isOpen &&
                      data.notes.recovery_notes.map((record: any) => <RecoveryNote key={record.id} data={record} client={client} />)
                    }
                  >
                    {data.notes.recovery_notes.map((record: any) => (
                      <RoadmapRow level={3} title={record.name} renderBars={() => <RecoveryNote data={record} client={client} />} />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.notes.custom_notes && (
                <Permission permission="client_custom_notes.view" featureFlagV2="client_custom_notes">
                  <RoadmapRow
                    isOpen={isNotesOpen}
                    isDisabled={size(data.notes.custom_notes) === 0}
                    level={1}
                    icon="custom_notes"
                    title="Custom Notes"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.notes.custom_notes.map((record: any) => <CustomNote key={record.id} data={record} client={client} />)
                    }
                  >
                    {data.notes.custom_notes.map((record: any) => (
                      <RoadmapRow level={3} title={record.name} renderBars={() => <CustomNote data={record} client={client} />} />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}
            </>
          )}

          {/* Clinical */}
          {data.clinical && (
            <>
              <GroupHeader
                icon="clinical_department"
                title="Clinical"
                isAllOpen={isClinicalOpen}
                onToggleOpen={() => {
                  setIsClinicalOpen((c) => !c)
                }}
              />

              {/* Clinical Assessments */}
              {data.clinical.clinical_assessments && (
                <Permission permission="clinical_assessments.view" featureFlagV2="clinical_assessments">
                  <RoadmapRow
                    isOpen={isClinicalOpen}
                    isDisabled={size(data.clinical.clinical_assessments) === 0}
                    level={1}
                    icon="clinical_assessments"
                    title="Clinical Assessments"
                    renderBars={({ isOpen }: any) =>
                      !isOpen &&
                      data.clinical.clinical_assessments.map((record: any) => <ClinicalAssessment data={record} client={client} />)
                    }
                  >
                    {data.clinical.clinical_assessments.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.name || 'Clinical Assessment'}
                        renderBars={() => <ClinicalAssessment data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {/* Clinical Measures */}
              {data.clinical.clinical_measures && (
                <Permission permission="clinical_measurements.view" featureFlagV2="clinical_measures">
                  <RoadmapRow
                    isOpen={isClinicalOpen}
                    isDisabled={size(data.clinical.clinical_measures) === 0}
                    level={1}
                    icon="clinical_measurements"
                    title="Clinical Measures"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.clinical.clinical_measures.map((record: any) => <ClinicalMeasure data={record} client={client} />)
                    }
                  >
                    {data.clinical.clinical_measures.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.name || 'Clinical Measure'}
                        renderBars={() => <ClinicalMeasure data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {/* Clinical Notes */}
              {data.clinical.clinical_notes && (
                <Permission permission="clinical_notes.view" featureFlagV2="clinical_notes">
                  <RoadmapRow
                    isOpen={isClinicalOpen}
                    isDisabled={size(data.clinical.clinical_notes) === 0}
                    level={1}
                    icon="clinical_notes"
                    title="Clinical Notes"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.clinical.clinical_notes.map((record: any) => <ClinicalNote data={record} client={client} />)
                    }
                  >
                    {data.clinical.clinical_notes.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.name || CLINICAL_NOTES_TITLES[record.category] || 'Clinical Note'}
                        renderBars={() => <ClinicalNote data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.clinical.diagnoses && (
                <Permission permission="diagnoses.view" featureFlagV2="diagnoses">
                  <RoadmapRow
                    isOpen={isClinicalOpen}
                    isDisabled={size(data.clinical.diagnoses) === 0}
                    level={1}
                    icon="diagnosis"
                    title="Diagnoses"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.clinical.diagnoses.map((record: any) => <Diagnosis data={record} client={client} />)
                    }
                  >
                    {data.clinical.diagnoses.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.name || 'Diagnosis'}
                        renderBars={() => <Diagnosis data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {/* Outcome Measures */}
              {data.clinical.outcome_measures && (
                <Permission permission="outcome_measures.view" featureFlagV2="outcome_measures">
                  <RoadmapRow
                    isOpen={isClinicalOpen}
                    isDisabled={size(data.clinical.outcome_measures) === 0}
                    level={1}
                    icon="outcome_measures"
                    title="Outcome Measures"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.clinical.outcome_measures.map((record: any) => <OutcomeMeasure data={record} client={client} />)
                    }
                  >
                    {data.clinical.outcome_measures.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.name || 'Outcome Measure'}
                        renderBars={() => <OutcomeMeasure data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {/* Progress Reviews */}
              {data.clinical.progress_reviews && (
                <Permission permission="progress_reviews.view" featureFlagV2="progress_reviews">
                  <RoadmapRow
                    isOpen={isClinicalOpen}
                    isDisabled={size(data.clinical.progress_reviews) === 0}
                    level={1}
                    icon="progress_reviews"
                    title="Progress Reviews"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.clinical.progress_reviews.map((record: any) => <ProgressReview data={record} client={client} />)
                    }
                  >
                    {data.clinical.progress_reviews.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.name || 'Clinical Note'}
                        renderBars={() => <ProgressReview data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}
            </>
          )}

          {data.medical && (
            <>
              <GroupHeader
                icon="medical_department"
                title="Medical"
                isAllOpen={isMedicalOpen}
                onToggleOpen={() => {
                  setIsMedicalOpen((c) => !c)
                }}
              />

              {data.medical.allergies && (
                <RoadmapRow
                  isOpen={isMedicalOpen}
                  isDisabled={size(data.medical.allergies) === 0}
                  level={1}
                  icon="treatment_episodes"
                  title="Allergies"
                  renderBars={({ isOpen }: any) =>
                    !isOpen && data.medical.allergies.map((record: any) => <Allergy data={record} client={client} />)
                  }
                >
                  {data.medical.allergies.map((record: any) => (
                    <RoadmapRow level={3} title={record.name || 'Allergy'} renderBars={() => <Allergy data={record} client={client} />} />
                  ))}
                </RoadmapRow>
              )}

              {data.medical.meds && (
                <Permission permission="medications.view" featureFlagV2="medications">
                  <RoadmapRow
                    isOpen={isMedicalOpen}
                    isDisabled={size(data.medical.meds) === 0}
                    level={1}
                    icon="medications"
                    title="Medications"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.medical.meds.map((record: any) => <Medication data={record} client={client} />)
                    }
                  >
                    {data.medical.meds.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.name || 'Medication'}
                        renderBars={() => <Medication data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.medical.medication_logs && (
                <Permission permission="medication_logs.view" featureFlagV2="medication_logs">
                  <RoadmapRow
                    isOpen={isMedicalOpen}
                    isDisabled={size(data.medical.medication_logs) === 0}
                    level={1}
                    icon="med_pass"
                    title="Medications Logs"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.medical.medication_logs.map((record: any) => <MedicationLog data={record} client={client} />)
                    }
                  >
                    {data.medical.medication_logs.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.med?.name || 'Medication Log'}
                        renderBars={() => <MedicationLog data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.medical.medical_assessments && (
                <Permission permission="medical_assessments.view" featureFlagV2="medical_assessments">
                  <RoadmapRow
                    isOpen={isMedicalOpen}
                    isDisabled={size(data.medical.medical_assessments) === 0}
                    level={1}
                    icon="medical_assessments"
                    title="Medical Assessments"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.medical.medical_assessments.map((record: any) => <MedicalAssessment data={record} client={client} />)
                    }
                  >
                    {data.medical.medical_assessments.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.name || 'Medical Assessment'}
                        renderBars={() => <MedicalAssessment data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.medical.progress_notes && (
                <Permission permission="progress_notes.view" featureFlagV2="progress_notes">
                  <RoadmapRow
                    isOpen={isMedicalOpen}
                    isDisabled={size(data.medical.progress_notes) === 0}
                    level={1}
                    icon="progress_notes"
                    title="Progress Notes"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.medical.progress_notes.map((record: any) => <ProgressNote data={record} client={client} />)
                    }
                  >
                    {data.medical.progress_notes.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.name || 'Progress Note'}
                        renderBars={() => <ProgressNote data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              <Permission permission="test_results" featureFlagV2="test_results">
                {data.medical.ua_tests && (
                  <Permission permission="test_results.ua_tests.view" featureFlagV2="ua_tests">
                    <RoadmapRow
                      isOpen={isMedicalOpen}
                      isDisabled={size(data.medical.ua_tests) === 0}
                      level={1}
                      icon="test_results"
                      title="UA Tests"
                      renderBars={({ isOpen }: any) =>
                        !isOpen && data.medical.ua_tests.map((record: any) => <UATest data={record} client={client} />)
                      }
                    >
                      {data.medical.ua_tests.map((record: any) => (
                        <RoadmapRow
                          level={3}
                          title={usDate(record.collected_at, timezone)}
                          renderBars={() => <UATest data={record} client={client} />}
                        />
                      ))}
                    </RoadmapRow>
                  </Permission>
                )}

                {data.medical.breathalyzer_tests && (
                  <Permission permission="test_results.breathalyzer_tests.view" featureFlagV2="breathalyzer_tests">
                    <RoadmapRow
                      isOpen={isMedicalOpen}
                      isDisabled={size(data.medical.breathalyzer_tests) === 0}
                      level={1}
                      icon="test_results"
                      title="Breathalyzer Tests"
                      renderBars={({ isOpen }: any) =>
                        !isOpen && data.medical.breathalyzer_tests.map((record: any) => <BreathalyzerTest data={record} client={client} />)
                      }
                    >
                      {data.medical.breathalyzer_tests.map((record: any) => (
                        <RoadmapRow
                          level={3}
                          title={usDate(record.collected_at, timezone)}
                          renderBars={() => <BreathalyzerTest data={record} client={client} />}
                        />
                      ))}
                    </RoadmapRow>
                  </Permission>
                )}

                {data.medical.vital_signs && (
                  <Permission permission="test_results.vital_signs.view" featureFlagV2="vital_signs">
                    <RoadmapRow
                      isOpen={isMedicalOpen}
                      isDisabled={size(data.medical.vital_signs) === 0}
                      level={1}
                      icon="test_results"
                      title="Vital Signs"
                      renderBars={({ isOpen }: any) =>
                        !isOpen && data.medical.vital_signs.map((record: any) => <VitalSign data={record} client={client} />)
                      }
                    >
                      {data.medical.vital_signs.map((record: any) => (
                        <RoadmapRow
                          level={3}
                          title={usDate(record.recorded_at, timezone)}
                          renderBars={() => <VitalSign data={record} client={client} />}
                        />
                      ))}
                    </RoadmapRow>
                  </Permission>
                )}

                {data.medical.communicable_disease_tests && (
                  <Permission permission="test_results.communicable_disease_tests.view" featureFlagV2="communicable_disease_tests">
                    <RoadmapRow
                      isOpen={isMedicalOpen}
                      isDisabled={size(data.medical.communicable_disease_tests) === 0}
                      level={1}
                      icon="test_results"
                      title="Communicable Disease Tests"
                      renderBars={({ isOpen }: any) =>
                        !isOpen &&
                        data.medical.communicable_disease_tests.map((record: any) => (
                          <CommunicableDiseaseTest data={record} client={client} />
                        ))
                      }
                    >
                      {data.medical.communicable_disease_tests.map((record: any) => (
                        <RoadmapRow
                          level={3}
                          title={COMMUNICABLE_DISEASE_TESTS[record.category] || 'Communicable Disease Test'}
                          renderBars={() => <CommunicableDiseaseTest data={record} client={client} />}
                        />
                      ))}
                    </RoadmapRow>
                  </Permission>
                )}

                {data.medical.labs && (
                  <Permission permission="test_results.labs.view" featureFlagV2="labs">
                    <RoadmapRow
                      isOpen={isMedicalOpen}
                      isDisabled={size(data.medical.labs) === 0}
                      level={1}
                      icon="test_results"
                      title="Labs"
                      renderBars={({ isOpen }: any) =>
                        !isOpen && data.medical.labs.map((record: any) => <Lab data={record} client={client} />)
                      }
                    >
                      {data.medical.labs.map((record: any) => (
                        <RoadmapRow level={3} title={record.name || 'Lab Test'} renderBars={() => <Lab data={record} client={client} />} />
                      ))}
                    </RoadmapRow>
                  </Permission>
                )}
              </Permission>

              {data.medical.physician_orders && (
                <Permission permission="physician_orders.view" featureFlagV2="physician_orders">
                  <RoadmapRow
                    isOpen={isMedicalOpen}
                    isDisabled={size(data.medical.physician_orders) === 0}
                    level={1}
                    icon="physician_orders"
                    title="Physician Orders"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.medical.physician_orders.map((record: any) => <PhysicianOrder data={record} client={client} />)
                    }
                  >
                    {data.medical.physician_orders.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.name || 'Physician Order'}
                        renderBars={() => <PhysicianOrder data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}

              {data.medical.nursing_assessments && (
                <Permission permission="nursing.view" featureFlagV2="nursing">
                  <RoadmapRow
                    isOpen={isMedicalOpen}
                    isDisabled={size(data.medical.nursing_assessments) === 0}
                    level={1}
                    icon="nursing"
                    title="Nursing Assessments"
                    renderBars={({ isOpen }: any) =>
                      !isOpen && data.medical.nursing_assessments.map((record: any) => <NursingAssessment data={record} client={client} />)
                    }
                  >
                    {data.medical.nursing_assessments.map((record: any) => (
                      <RoadmapRow
                        level={3}
                        title={record.name || 'Nursing Assessment'}
                        renderBars={() => <NursingAssessment data={record} client={client} />}
                      />
                    ))}
                  </RoadmapRow>
                </Permission>
              )}
            </>
          )}
        </>
      )}
    </Roadmap>
  )
}
