import React from 'react'

export const useServerFunctionality = () => {
  const [filters, setFilters] = React.useState({})
  const [sorting, setSorting] = React.useState({})
  const [pagination, setPagination] = React.useState({ page: 1, items: 25 })

  return {
    server: {
      keys: { filters, sorting, ...pagination },
      params: {
        ...pagination,
        filters: btoa(JSON.stringify({ filters })),
        sorting: btoa(JSON.stringify({ sorting })),
      },
      options: {
        keepPreviousData: true,
      },
    },
    sets: {
      pagination: setPagination,
      sorting: setSorting,
      filters: setFilters,
    },
    tableProps: (meta: any) => ({
      showFilters: false,
      useServerSorting: true,
      useServerPagination: true,
      serverPagination: {
        pagination: pagination,
        meta: meta,
      },
      onPaginationUpdate: setPagination,
      onSortingUpdate: setSorting,
    }),
  }
}
