import React from 'react'

import SmartStatus from '../../../components/SmartStatus'

const statuses = {
  claim_create: {
    label: 'Claim Create',
    color: 'grey',
  },
  claim_update: {
    label: 'Claim Update',
    color: 'yellow',
  },
  claim_delete: {
    label: 'Claim Delete',
    color: 'red',
  },

  claim_manual_status_change: {
    label: 'Status Change',
    color: 'orange',
  },
  claim_validate: {
    label: 'Claim Validation',
    color: 'blue',
  },
  claim_submit: {
    label: 'Claim Submit',
    color: 'green',
  },
  claim_flagged: {
    label: 'Claim Flagged',
    color: 'orange',
  },
  claim_end: {
    label: 'Done',
    color: 'green',
  },
  tracker_change: {
    label: 'Tracker Status Change',
    color: 'blue',
  },

  note_create: {
    label: 'Note Create',
    color: 'grey',
  },
  note_update: {
    label: 'Note Update',
    color: 'yellow',
  },
  note_delete: {
    label: 'Note Delete',
    color: 'red',
  },

  task_create: {
    label: 'Task Create',
    color: 'grey',
  },
  task_update: {
    label: 'Task Update',
    color: 'yellow',
  },
  task_delete: {
    label: 'Task Delete',
    color: 'red',
  },

  payment_attached_to_service_line: {
    label: 'Payment Attached',
    color: 'grey',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
