import React from 'react'
import { connect } from 'react-redux'

import { COLORS } from '../theme'
import Grid from './Grid'
import Loader from './Loader'
import Analytics from '../modules/analytics'

const NetworkStatus = ({ online, isOnline }) => {
  const [prevOnline, setPrevOnline] = React.useState(true)

  React.useEffect(() => {
    if (prevOnline && !online) {
      Analytics.queue({
        type: 'track',
        name: 'Internet Connection Lost',
      })
    }

    if (!prevOnline && online) {
      Analytics.queue({
        type: 'track',
        name: 'Internet Re-connected',
      })
    }

    setPrevOnline(online)
  }, [online, prevOnline])

  if (isOnline && online) return null

  return (
    <Grid css={styles} justifyContent="space-between" columns="1fr min-content">
      <div className="center">
        Your device lost Internet connection. Trying to reconnect…{' '}
        <Loader css={{ marginLeft: '10px' }} color={COLORS.white} size={16} speed={500} />
      </div>
    </Grid>
  )
}

const styles = {
  width: '100%',
  borderBottom: `1px solid ${COLORS.divider}`,
  padding: '8px 8px 8px 8px',
  height: '40px',
  backgroundColor: '#FF4136',
  color: '#fff',

  '.center': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

NetworkStatus.defaultProps = {
  isOnline: true,
}

const mapStateToProps = (state) => {
  return {
    online: state?.common?.global?.online,
  }
}

export default connect(mapStateToProps)(NetworkStatus)
