import React from 'react'
import { connect } from 'react-redux'

import { withOverlayError } from '../../../hocs/withOverlayError'

import { Text } from '../../Typography'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ContextHide from '../../Forms/ContextHide'
import DateInput from '../../Forms/DateInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import LevelOfCareSelect from '../../Forms/LevelOfCareSelect'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../../Overlays/pages/OverlayBase'

class InitialAuthorizationOverlay extends OverlayBase {
  renderHeader = () => (
    <Overlay.Header icon="authorizations" title={this.state.$new ? 'Add Initial Authorization' : 'Initial Authorization'} />
  )

  renderContent = () => {
    const { $editable, $new } = this.state
    const { reference, record, timezone } = this.props

    const isBehave = this.props?.user?.type === 'bh_employee'

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={record}
          timezone={timezone}
          isEditable={$editable}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
          linked={
            $new && {
              admission_id: reference?.current_admission?.id,
              title: 'Initial Authorization',
              category: 'initial_authorization',
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              {!$new && <ObjectSelector isEditable={false} model="author" label="Author" type="employees" icon="employees" />}

              <Flex stretchChildrenX gap={16}>
                <CheckboxGroup label="Requirement" trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox label="Not Required" model="data.not_required" />
                </CheckboxGroup>

                <ContextHide when="not_required" is={true}>
                  <Input model="data.identifier" label="Authorization #" />
                </ContextHide>
              </Flex>

              <LevelOfCareSelect label="Level of Care" model="data.level" />

              <Flex stretchChildrenX gap={16}>
                <DateInput label="From" model="data.started_at" />
                <DateInput label="Until" model="data.ended_at" />
              </Flex>

              <CheckboxGroup
                label="Notifications"
                trueIcon="check"
                falseIcon="cross"
                falseStyle="none"
                tooltip={
                  <Text muted={false}>
                    If enabled, the <strong>Primary Therapist</strong> from <strong>Connections</strong> receive automated reminders to
                    complete the Progress Review required for the next Authorization
                  </Text>
                }
              >
                <Checkbox defaultChecked label="Send Progress Review Reminders" model="data.send_reminders" />
              </CheckboxGroup>

              <Flex stretchChildrenX gap={16}>
                <Input model="data.required_daily_hours" label="Daily Hours Required" type="number" min="0" max="24" suffix="hours" />
                <Input model="data.required_weekly_hours" label="Weekly Hours Required" type="number" min="0" max="168" suffix="hours" />
              </Flex>

              <Flex stretchChildrenX gap={16}>
                <Input label="Sessions Authorized" model="data.sessions" />
                <Input model="data.used_sessions" label="Sessions Used" />
              </Flex>

              <RadioGroup label="UR Communication Method" layout="vertical-dense" model="data.ur_communication_method">
                <Radio label="Phone Call" value="phone_call" />
                <Radio label="Faxing Clinical" value="faxing_clinical" />
                <Radio label="Web Portal" value="web_portal" />
              </RadioGroup>
            </FormSection>

            <Divider />

            <FormSection heading="Notes">
              {isBehave && <Textarea label="Internal Notes" model="data.internal_notes" />}
              <Textarea label="Customer Notes" model="data.notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="authorizations.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Initial Authorization"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="authorizations.edit"
            />

            <DeleteDialog
              title="Delete Initial Authorization?"
              message="Are you sure you want to delete this Initial Authorization? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                fullWidth
                permission="authorizations.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'insurance_authorizations')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_authorizations')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(InitialAuthorizationOverlay))
