import produce from 'immer'

const LOCAL_STORAGE_KEY = 'bh.spreadsheets'

export const setSettings = (localStorageKey: string, settings: any) => {
  if (!localStorageKey || !settings) return

  // set main key if it doesn't exist
  if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({}))
  }

  // update table settings
  const current = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (!current) return

  const parsed = JSON.parse(current)

  const updated = produce(parsed, (draft: any) => {
    draft[localStorageKey] = {
      ...draft[localStorageKey],
      ...settings,
    }
  })

  // save updated settings
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updated))
}

export const getSettings = (localStorageKey?: string) => {
  if (!localStorageKey) return

  const current = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (!current) return

  const parsed = JSON.parse(current)

  return parsed[localStorageKey] || null
}

export const clearSettings = (localStorageKey?: string) => {
  if (!localStorageKey) return

  const current = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (!current) return

  const parsed = JSON.parse(current)

  const updated = produce(parsed, (draft: any) => {
    if (draft[localStorageKey]) delete draft[localStorageKey]
  })

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updated))
}
