import React from 'react'
import { lighten, opacify } from 'polished'
import clsx from 'clsx'
import size from 'lodash/size'

import { arrayToInternalMap } from '../../../../utils/functions'
import { COLORS, SHADOW } from '../../../../theme'
import { CONDITIONS_CONFIG } from '../../utils/constants'
import { FORM_ELEMENTS_VALUE_TYPES } from '../../utils/constants'
import { formatInputModel } from '../../utils/functions'
import { getConditionValueLabel } from '../../utils/functions'

import ContextShow from '../../../../components/Forms/ContextShow'
import Glyph from '../../../../components/Glyph'

import { Box } from './Box'
import { useFormBuilder } from '../../useFormBuilder'

const getArrayValues = (conditionalElement: any, formElement: any) => {
  const result: any = []

  if (size(conditionalElement) === 0 || size(formElement) === 0) return result

  // handle radio conditional
  if (FORM_ELEMENTS_VALUE_TYPES[formElement.category] === 'radio') {
    const radiosMap = arrayToInternalMap(formElement?.config?.single_select_input_values)
    const conditionValues = conditionalElement?.config?.radio_checkbox_options

    if (size(radiosMap) === 0 || size(conditionValues) === 0) return result

    for (const item of conditionValues) {
      const radioValue = radiosMap[item._id]

      if (item.is_active && radioValue) result.push(radioValue.value)
    }
  }
  // handle array value
  else {
    const arrayValue = conditionalElement?.config?.array_value
    if (size(arrayValue) === 0) return result

    return arrayValue.map((o) => o.value)
  }

  return result
}

export const Conditional = React.forwardRef((props: any, ref) => {
  const { isEditable, children, className, element: conditionalElement, hoverElement } = props

  const formElement = useFormBuilder((state: any) => state.allElements[conditionalElement?.config?.element_uuid])

  const when = React.useMemo(() => {
    if (!formElement) return null

    return formatInputModel(formElement)
  }, [formElement])

  if (!conditionalElement?.config) return null

  const { condition, value } = conditionalElement.config

  const arrayValue = getArrayValues(conditionalElement, formElement)
  const isCheckbox = formElement?.category === 'checkbox_input'
  const isActive = conditionalElement?._isActive

  const rootClasses = clsx('CONDITIONAL', isEditable && 'is-editable', isActive && 'is-active', isEditable ? 'gap-1' : 'gap-4', className)
  const tagClasses = clsx(!!formElement && 'is-valid', isActive && 'is-active')

  if (isEditable) {
    const conditionValueLabel = getConditionValueLabel(conditionalElement, formElement)
    const conditionLabel = CONDITIONS_CONFIG?.[condition]

    return (
      <Box
        // showEmptyState
        ref={ref}
        css={STYLES.root}
        element={conditionalElement}
        className={rootClasses}
        hoverElement={hoverElement}
        emptyDescription="Drag an drop content here and create a condition to show or hide it"
      >
        <div css={STYLES.tag} className={tagClasses}>
          <Glyph glyph={formElement ? 'code_fork' : 'warning'} size={14} />

          <div>
            {formElement?.config?.label ? (
              <>
                <span className="uppercase opacity-70">Show when</span>
                <span> "{formElement.config.label}" </span>
                {conditionLabel && <span className="uppercase opacity-70"> {conditionLabel} </span>}
                <span>{conditionValueLabel ? ` "${conditionValueLabel}" ` : '…'}</span>
              </>
            ) : (
              'Select a condition trigger…'
            )}
          </div>
        </div>

        {children}
      </Box>
    )
  }

  if (isCheckbox) {
    return (
      <CheckboxConditional when={when} condition={condition} conditionalElement={conditionalElement} formElement={formElement}>
        {children}
      </CheckboxConditional>
    )
  }

  return (
    <ContextShow
      when={when}
      is={condition === 'is' ? value : undefined}
      not={condition === 'is_not' ? value : undefined}
      greaterThan={condition === 'is_greater_than' ? value : undefined}
      lessThan={condition === 'is_less_than' ? value : undefined}
      within={condition === 'is_within' ? arrayValue : undefined}
      notWithin={condition === 'is_not_within' ? arrayValue : undefined}
    >
      {children}
    </ContextShow>
  )
})

const CHECKBOX_IS_VALUES = {
  is_all_selected: true,
  is_all_not_selected: false,
  is_some_selected: true,
  is_some_not_selected: false,
}

const CheckboxConditional = (props: any) => {
  const { when, condition, conditionalElement, formElement, children } = props

  const orMode = condition === 'is_some_selected' || condition === 'is_some_not_selected'
  const isValue = CHECKBOX_IS_VALUES[condition]

  // Define array of models to check against
  const whenValue = React.useMemo(() => {
    const result: any = []

    const optionsMap = arrayToInternalMap(conditionalElement?.config?.radio_checkbox_options)
    const checkboxesMap = arrayToInternalMap(formElement?.config?.multi_input_values)

    if (!when || size(optionsMap) === 0 || size(checkboxesMap) === 0) return result

    for (const id in optionsMap) {
      const option = optionsMap[id]
      const checkboxOption = checkboxesMap[id]

      // option is not active OR previously saved option was removed from the dependent checkbox input
      if (!option?.is_active || !checkboxOption) continue

      // the `when` prop represents the model of the parent Checkbox Group
      // each Checkbox model follows the format `${CHECKBOX_GROUP_ID}.${CHECKBOX_ID}`
      result.push(`${when}.${id}`)
    }

    return result
  }, [when, formElement, conditionalElement])

  return (
    <ContextShow orMode={orMode} when={whenValue} is={isValue}>
      {children}
    </ContextShow>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    border: 'none !important',

    '&.is-editable': {
      position: 'relative',
      marginTop: '0.5rem',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        border: `1px dashed ${lighten(0.03, COLORS.gray)}`,
        borderRadius: 5,
        opacity: 0.4,
        pointerEvents: 'none !important',
      },
    },
  },

  tag: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '0.5rem',
    zIndex: 3,
    background: COLORS.white,
    color: COLORS.text,
    fontSize: '0.8rem',
    borderRadius: 4,
    fontWeight: 600,
    padding: '0.05rem 0.25rem',
    pointerEvents: 'none !important',
    boxShadow: `0 0 0 1px ${opacify(0.1, COLORS.divider)}, ${SHADOW(2)}`,

    svg: {
      fill: COLORS.orange,
      marginRight: '0.25rem',
    },

    '&.is-valid': {
      svg: { fill: COLORS.text },

      '&.is-active': {
        color: COLORS.white,
        background: COLORS.blue,
        boxShadow: 'none',

        svg: { fill: COLORS.white },
      },
    },
  },
}
