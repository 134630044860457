import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../theme'

type Props = {
  stepNumber: number
  isOpen: boolean
}

const WorkflowStepBadge = ({ stepNumber, isOpen }: Props) => {
  const classNames = clsx({
    'is-open': isOpen,
  })

  return (
    <div css={styles} className={classNames}>
      {stepNumber}
    </div>
  )
}

const styles = {
  flex: '0 0 auto',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',

  width: '1.65rem',
  height: '1.65rem',
  border: `1px solid ${COLORS.divider}`,
  background: COLORS.white,
  borderRadius: '50%',

  fontWeight: 600,
  color: COLORS.text,
  lineHeight: 0,
  fontSize: '0.9rem',

  '&.is-open': {
    color: COLORS.white,
    background: COLORS.blue,
    borderColor: COLORS.transparent,
  },
}

export default WorkflowStepBadge
