import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import useAPI from '@behavehealth/hooks/useAPI'

import { Card } from '@behavehealth/components'
import ClaimsTable from '@behavehealth/components/RCM/ClaimsTable'

const Billing = () => {
  const match = useRouteMatch()

  const loading = useSelector((state: any) => state.data?.insurance_claims?.loading)
  const data = useSelector((state: any) => state.data?.insurance_claims?.data)

  useAPI('insurance_claims', '/insurance_claims')

  return (
    <Card>
      <ClaimsTable
        title="Claims"
        data={data}
        isLoading={loading}
        to={(id: string) => ({
          pathname: `${match.url}/${id}`,
          parent: match,
        })}
      />
    </Card>
  )
}

export default Billing
