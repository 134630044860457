import React from 'react'

import { Heading } from '../../../../Typography'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import FormSection from '../../../../Forms/FormSection'
import Grid from '../../../../Grid'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const PhysicalExam = () => {
  return (
    <Grid gap="1rem">
      <FormSection>
        <Heading is="h3">General Appearance</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="General Appearance" model="data.physical_exam.general.general_appearance" />
          <Checkbox label="Well developed" model="data.physical_exam.general.well_developed" />
          <Checkbox label="Well nourished" model="data.physical_exam.general.well_nourished" />
          <Checkbox label="Underweight" model="data.physical_exam.general.underweight" />
          <Checkbox label="Severely Malnourished" model="data.physical_exam.general.severely_malnourished" />
          <Checkbox label="Obese" model="data.physical_exam.general.obese" />
          <Checkbox label="Alert" model="data.physical_exam.general.alert" />
          <Checkbox label="Drowsy" model="data.physical_exam.general.drowsy" />
          <Checkbox label="Nodding Off" model="data.physical_exam.general.nodding_off" />
          <Checkbox label="Repeatedly falling asleep" model="data.physical_exam.general.repeatedly_falling_asleep" />
          <Checkbox label="Responsive to name" model="data.physical_exam.general.responsive_to_name" />
          <Checkbox label="Responsive to shaking" model="data.physical_exam.general.responsive_to_shaking" />
          <Checkbox label="Unresponsive/Comatose" model="data.physical_exam.general.unresponsive_comatose" />
          <Checkbox label="No apparent distress" model="data.physical_exam.general.no_apparent_distress" />
          <Checkbox label="Mild distress" model="data.physical_exam.general.mild_distress" />
          <Checkbox label="Moderate distress" model="data.physical_exam.general.moderate_distress" />
          <Checkbox label="Extremis" model="data.physical_exam.general.extremis" />
        </CheckboxGroup>

        <SmartTextarea useDictation label="Additional commentary for General" model="data.physical_exam.general.additional_info" />
      </FormSection>

      <FormSection>
        <Heading is="h3">Head</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Normocephalic" model="data.physical_exam.head.normocephalic" />
          <Checkbox label="Atraumatic" model="data.physical_exam.head.atraumatic" />
          <Checkbox label="Bruising" model="data.physical_exam.head.bruising" />
          <Checkbox label="Abrasions" model="data.physical_exam.head.abrasions" />
          <Checkbox label="No facial erythema" model="data.physical_exam.head.no_facial_erythema" />
          <Checkbox label="Facial erythema" model="data.physical_exam.head.facial_erythema" />
          <Checkbox label="No facial edema" model="data.physical_exam.head.no_facial_edema" />
          <Checkbox label="Facial edema" model="data.physical_exam.head.facial_edema" />
        </CheckboxGroup>
        <SmartTextarea useDictation label="Additional commentary for Head" model="data.physical_exam.head.additional_info" />
      </FormSection>

      <FormSection>
        <Heading is="h3">Eyes</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Eyes and lids symmetric" model="data.physical_exam.eyes.eyes_and_lids_symmetric" />
          <Checkbox label="No tearing" model="data.physical_exam.eyes.no_tearing" />
          <Checkbox label="Tearing" model="data.physical_exam.eyes.tearing" />
          <Checkbox label="Conjunctiva clear" model="data.physical_exam.eyes.conjunctiva_clear" />
          <Checkbox label="Conjuntiva injected" model="data.physical_exam.eyes.conjuntiva_injected" />
          <Checkbox label="Conjuntiva injected with exudate" model="data.physical_exam.eyes.conjuntiva_injected_with_exudate" />
          <Checkbox label="Pupils normal for ambient light" model="data.physical_exam.eyes.pupils_normal_for_ambient_light" />
          <Checkbox label="Mydriasis" model="data.physical_exam.eyes.mydriasis" />
          <Checkbox label="Miosis" model="data.physical_exam.eyes.miosis" />
          <Checkbox
            label="PERRLA - Pupils are equal, round and reactive to light and accommodation"
            model="data.physical_exam.eyes.perrla"
          />
          <Checkbox label="EOMI - Extra-ocular movements are intact" model="data.physical_exam.eyes.eomi" />
        </CheckboxGroup>
        <SmartTextarea useDictation label="Additional commentary for Eyes" model="data.physical_exam.eyes.additional_info" />
      </FormSection>

      <FormSection>
        <Heading is="h3">ENT</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Nasal mucosa normal" model="data.physical_exam.ent.nasal_mucosa_normal" />
          <Checkbox label="Nasal mucosa red, swollen" model="data.physical_exam.ent.nasal_mucosa_red_swollen" />
          <Checkbox label="Nasal mucosa pale, swollen" model="data.physical_exam.ent.nasal_mucosa_pale_swollen" />
          <Checkbox label="Nasal septal defect" model="data.physical_exam.ent.nasal_septal_defect" />
          <Checkbox label="No rhinorrhea" model="data.physical_exam.ent.no_rhinorrhea" />
          <Checkbox label="Rhinorrhea" model="data.physical_exam.ent.rhinorrhea" />
          <Checkbox label="Good dentition" model="data.physical_exam.ent.good_dentition" />
          <Checkbox label="Poor dentition" model="data.physical_exam.ent.poor_dentition" />
          <Checkbox label="Uvula rises in midline" model="data.physical_exam.ent.uvula_rises_in_midline" />
          <Checkbox label="Tongue protrusion normal" model="data.physical_exam.ent.tongue_protrusion_normal" />
        </CheckboxGroup>
        <SmartTextarea useDictation label="Additional commentary for ENT" model="data.physical_exam.ent.additional_info" />
      </FormSection>

      <FormSection>
        <Heading is="h3">Neck</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Nontender, No masses or adenopathy" model="data.physical_exam.neck.nontender_no_masses_or_adenopathy" />
          <Checkbox label="Nontender, no masses, normal size" model="data.physical_exam.neck.nontender_no_masses_normal_size" />
        </CheckboxGroup>
        <SmartTextarea useDictation label="Additional commentary for Neck" model="data.physical_exam.neck.additional_info" />
      </FormSection>

      <FormSection>
        <Heading is="h3">Respiratory/Chest</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Respirations regular" model="data.physical_exam.respiratory_chest.respirations_regular" />
          <Checkbox label="Respirations irregular" model="data.physical_exam.respiratory_chest.respirations_irregular" />
          <Checkbox label="No respiratory distress" model="data.physical_exam.respiratory_chest.no_respiratory_distress" />
          <Checkbox label="Mild respiratory distress" model="data.physical_exam.respiratory_chest.mild_respiratory_distress" />
          <Checkbox label="Moderate respiratory distress" model="data.physical_exam.respiratory_chest.moderate_respiratory_distress" />
          <Checkbox label="Clear to auscultation" model="data.physical_exam.respiratory_chest.clear_to_auscultation" />
          <Checkbox label="Diffuse rhonchi" model="data.physical_exam.respiratory_chest.diffuse_rhonchi" />
          <Checkbox label="Diffuse wheezes" model="data.physical_exam.respiratory_chest.diffuse_wheezes" />
          <Checkbox label="Basal rales" model="data.physical_exam.respiratory_chest.basal_rales" />
        </CheckboxGroup>
        <SmartTextarea
          useDictation
          label="Additional commentary for Respiratory/Chest"
          model="data.physical_exam.respiratory_chest.additional_info"
        />
      </FormSection>

      <FormSection>
        <Heading is="h3">Heart</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Regular rhythm" model="data.physical_exam.heart.regular_rhythm" />
          <Checkbox label="Normal to auscultation" model="data.physical_exam.heart.normal_to_auscultation" />
          <Checkbox label="No peripheral edema" model="data.physical_exam.heart.no_peripheral_edema" />
        </CheckboxGroup>
        <SmartTextarea useDictation label="Additional commentary for Heart" model="data.physical_exam.heart.additional_info" />
      </FormSection>

      <FormSection>
        <Heading is="h3">Abdomen</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="Soft, nontender" model="data.physical_exam.abdomen.soft_nontender" />
          <Checkbox label="No masses" model="data.physical_exam.abdomen.no_masses" />
          <Checkbox label="No organomegaly" model="data.physical_exam.abdomen.no_organomegaly" />
        </CheckboxGroup>
        <SmartTextarea useDictation label="Additional commentary for Abdomen" model="data.physical_exam.abdomen.additional_info" />
      </FormSection>

      <FormSection>
        <Heading is="h3">Skin</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="No rash" model="data.physical_exam.skin.no_rash" />
          <Checkbox label="No diaphoresis" model="data.physical_exam.skin.no_diaphoresis" />
          <Checkbox label="No lesions, cellulitis or abscesses" model="data.physical_exam.skin.no_lesions_cellulitis_or_abscesses" />
          <Checkbox label="Recent tract marks" model="data.physical_exam.skin.recent_tract_marks" />
          <Checkbox label="Normal temperature" model="data.physical_exam.skin.normal_temperature" />
        </CheckboxGroup>
        <SmartTextarea useDictation label="Additional commentary for Skin" model="data.physical_exam.skin.additional_info" />
      </FormSection>

      <FormSection>
        <Heading is="h3">Neuro</Heading>
        <CheckboxGroup layout="vertical-dense">
          <Checkbox label="CN II to XII intact" model="data.physical_exam.neuro.cn_ii_to_xii_intact" />
          <Checkbox label="No tremor or involuntary movement" model="data.physical_exam.neuro.no_tremor_or_involuntary_movement" />
          <Checkbox label="Gait normal" model="data.physical_exam.neuro.gait_normal" />
        </CheckboxGroup>
        <SmartTextarea useDictation label="Additional commentary for Neuro" model="data.physical_exam.neuro.additional_info" />
      </FormSection>

      <SmartTextarea useDictation label="Physical Exam Comments" model="data.physical_exam.additional_info" />
    </Grid>
  )
}

export default PhysicalExam
