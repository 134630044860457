import React from 'react'
import { tint } from 'polished'

import { COLORS } from '../../../theme'
import { usDate, usDateTime, titleCase } from '../../../utils/functions'
import { useSettings } from '../../../hooks/useSettings'
import DataList from '../../../components/DataList'

import { RecordTooltip } from '../components/RecordTooltip'

export const Allergy = ({ data, client }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <RecordTooltip
      title="Allergy"
      icon="treatment_episodes"
      color={tint(0.4, COLORS.vividBlue)}
      startDate={data.started_on || data.created_at}
      recordLink={`/allergies/${data.id}`}
      client={client}
    >
      <DataList isCompact withPadding labelWidth={120}>
        <DataList.Item label="Name" value={data.name} />
        <DataList.Item label="Status" value={titleCase(data.status)} />
        <DataList.Item label="Category" value={titleCase(data.category)} />
        <DataList.Item label="Onset On" value={usDate(data.started_on, timezone)} />
      </DataList>
    </RecordTooltip>
  )
}
