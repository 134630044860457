import React from 'react'

export const VisualHide = ({ when, children }: any) => {
  if (!when) return children

  return <div css={STYLES.hidden}>{children}</div>
}

const STYLES = {
  hidden: {
    display: 'none',
  },
}
