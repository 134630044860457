import React from 'react'

import Dialog from '../Dialog'

const ConfirmDialog = ({ children, ...rest }) => {
  return <Dialog {...rest}>{children}</Dialog>
}

ConfirmDialog.defaultProps = {
  glyph: 'check',
  title: 'Please confirm…',
  message: 'Are you sure you want to do this?',
  yesColor: 'green',
}

export default ConfirmDialog
