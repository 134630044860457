import React from 'react'

import ContextShow from '../../../../Forms/ContextShow'
import FormSection from '../../../../Forms/FormSection'
import Grid from '../../../../Grid'
import Input from '../../../../Forms/Input'
import Label from '../../../../Label'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import TableArrayForm from '../../../../Forms/TableArrayForm'
import DataArray from '../../../../Forms/DataArray'
import SmartTextarea from '../../../../Forms/SmartTextarea'
import YesNoRadioGroupWithTextarea from '../../../../Forms/elements/YesNoRadioGroupWithTextarea'

import Button from '../../../../Button'
import Card from '../../../../Card'
import CardHeader from '../../../../CardHeader'
import CardTitle from '../../../../CardTitle'
import CardContent from '../../../../CardContent'

const RecoveryEnvironment = () => {
  return (
    <FormSection layout="vertical">
      <Input label="Who primarily raised the client?" model="data.recovery_environment.who_raised_you" />

      <RadioGroup
        label="Does the client get along with their parents and/or siblings?"
        model="data.recovery_environment.is_getting_along_with_parents_and_siblings"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.recovery_environment.is_getting_along_with_parents_and_siblings" is={false}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.recovery_environment.is_getting_along_with_parents_and_siblings_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <SmartTextarea
        useDictation
        label="Where was the client living prior to coming here? If transferred from another facility, please specify where they lived prior to treatment."
        model="data.recovery_environment.living_prior_coming"
      />

      <RadioGroup
        label="Did any of the people the client lived with use drugs/alcohol?"
        model="data.recovery_environment.have_others_you_lived_with_used"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.recovery_environment.have_others_you_lived_with_used" is={true}>
        <SmartTextarea
          useDictation
          label="Please explain:"
          model="data.recovery_environment.have_others_you_lived_with_used_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup label="Does the client have children?" model="data.recovery_environment.has_children" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.recovery_environment.has_children" is={true}>
        <SmartTextarea
          useDictation
          label="Number of children, ages and genders:"
          model="data.recovery_environment.has_children_number"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
        <SmartTextarea
          useDictation
          label="Where do they live?"
          model="data.recovery_environment.has_children_location"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup label="Is the client employed?" model="data.recovery_environment.is_employed" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.recovery_environment.is_employed" is={true}>
        <SmartTextarea
          useDictation
          label="Employer and type of work:"
          model="data.recovery_environment.employer_and_work"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
        <RadioGroup
          label="Is the client on approved leave from their job?"
          model="data.recovery_environment.approved_leave_from_job"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>

        <RadioGroup
          label="Does the client plan to return to this job?"
          model="data.recovery_environment.plan_to_return_to_job"
          layout="horizontal-dense"
        >
          <Radio label="Yes" value={true} />
          <Radio label="No" value={false} />
        </RadioGroup>
      </ContextShow>

      <ContextShow when="data.recovery_environment.is_employed" is={false}>
        <SmartTextarea
          useDictation
          label="How long has the client been unemployed?"
          model="data.recovery_environment.how_long_unemployed"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup label="Has the client ever been arrested?" model="data.recovery_environment.has_been_arrested" layout="horizontal-dense">
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.recovery_environment.has_been_arrested" is={true}>
        <SmartTextarea
          useDictation
          label="Please list past arrests, charges and results of charges:"
          model="data.recovery_environment.arrests_and_charges"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        label="Does the client have any pending charges or upcoming court dates?"
        model="data.recovery_environment.has_pending_charges_or_upcoming_court_dates"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.recovery_environment.has_pending_charges_or_upcoming_court_dates" is={true}>
        <SmartTextarea
          useDictation
          label="Please list:"
          model="data.recovery_environment.has_pending_charges_or_upcoming_court_dates_extra"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <RadioGroup
        label="Has the client ever been incarcerated?"
        model="data.recovery_environment.has_been_incarcerated"
        layout="horizontal-dense"
      >
        <Radio label="Yes" value={true} />
        <Radio label="No" value={false} />
      </RadioGroup>

      <ContextShow when="data.recovery_environment.has_been_incarcerated" is={true}>
        <SmartTextarea
          useDictation
          label="Please list incarcerations (include location and length of incarceration):"
          model="data.recovery_environment.has_been_incarcerated_list"
          validations={{
            presence: {
              message: 'Please provide this information',
            },
          }}
        />
      </ContextShow>

      <Input label="Name of parole or probation officer, if applicable:" model="data.recovery_environment.parole_or_probation_officer" />

      <YesNoRadioGroupWithTextarea
        label="Is the client currently employed?"
        model="data.recovery_environment.is_currently_employed"
        textareaLabel="By whom is the client employed, and is their position secure while they're in treatment?"
      />

      <Grid gap="0.25rem">
        <Label label="Please provide information about the client's employment history?" />

        <DataArray model="data.recovery_environment.employment_history">
          {({ orderedIds, add, remove }: any) => (
            <Grid gap="0.8rem">
              {orderedIds.map((id: any, index: number) => (
                <Card key={id}>
                  <CardHeader
                    after={
                      <Button
                        size={200}
                        type="minimal"
                        glyph="delete"
                        color="red"
                        testKey={`delete_employment_history_${index + 1}`}
                        label="Remove"
                        display="inline-flex"
                        onClick={(event: any) => {
                          event.preventDefault()
                          remove(id)
                        }}
                      />
                    }
                  >
                    <CardTitle title={`Employment #${index + 1}`} />
                  </CardHeader>

                  <CardContent>
                    <FormSection>
                      <Input testKey={`input_employment_${index + 1}_history_employer`} label="Employer" model={`${id}.employer`} />
                      <Input testKey={`input_employment_${index + 1}_history_dates`} label="Dates" model={`${id}.dates`} />
                      <Input testKey={`input_employment_${index + 1}_history_position`} label="Type of position" model={`${id}.position`} />
                      <SmartTextarea
                        useDictation
                        testKey={`input_employment_${index + 1}_reason_for_leaving`}
                        label="Reason for leaving"
                        model={`${id}.reason_for_leaving`}
                      />
                    </FormSection>
                  </CardContent>
                </Card>
              ))}

              <div>
                <Button label="Add Employment" size={200} glyph="add" onClick={add} display="inline-flex" />
              </div>
            </Grid>
          )}
        </DataArray>
      </Grid>

      <SmartTextarea useDictation label="Notes" model="data.recovery_environment.notes" />
    </FormSection>
  )
}

export default RecoveryEnvironment
