import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import compact from 'lodash/compact'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { DEFAULT_FILTERS } from '../Filters/constants'

export const QuoteTemplatesDataTable = (props: any) => {
  const { to, activeId, onSelect } = props

  const columns = React.useMemo(
    () =>
      compact([
        {
          title: 'Name',
          model: 'name',
          width: 300,
          disableHide: true,
          formatValue: ({ data, value }: any) => (
            <MainCell
              as={NavLink}
              id={data.id}
              value={value}
              to={to ? to(data) : undefined}
              isActive={activeId === data.id}
              onClick={onSelect ? () => onSelect(data) : undefined}
            />
          ),
        },
        {
          title: 'Tags',
          model: 'global_tags',
          type: 'global_tags',
          editPermission: true,
        },
        {
          title: 'Description',
          model: 'description',
          type: 'rich_text',
          width: 300,
        },
        {
          title: 'Date Added',
          model: 'created_at',
          type: 'date_time',
        },
        {
          title: 'Date Updated',
          model: 'updated_at',
          type: 'date_time',
        },
        props.renderDuplicateCell && {
          title: 'Actions',
          id: 'duplicate',
          width: 130,
          disableSort: true,
          hoverExpand: false,
          formatValue: ({ data }: any) => {
            return props.renderDuplicateCell(data)
          },
        },
      ]),
    [to, onSelect, activeId],
  )

  return <DataTable asCard title="Quote Templates" icon="quotes" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  global_tags: {
    label: 'Tags',
    type: 'global_tags',
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
