import React from 'react'
import startCase from 'lodash/startCase'

import { useSettings } from '../../hooks'

import Button from '../Button'
import DataFormStatus from '../Statuses/DataFormStatus'
import Flex from '../Flex'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (
  to: Function = () => {},
  duplicateFormLink: Function = () => {},
  onClick: any,
  onDuplicateFormClick: any,
  timezone: string,
) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink
        to={to(row.original.id, row.original.subcategory)}
        label={value || startCase(row.original?.subcategory)}
        onClick={onClick ? () => onClick(row.original) : undefined}
      />
    ),
  },
  {
    width: 140,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <DataFormStatus status={value} />,
    Filter: TableFilter.DataFormStatus,
    filter: 'dataFormStatus',
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Last Edited',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
  {
    width: 180,
    id: 'duplicate',
    accessor: 'id',
    Header: 'Create New From',
    Cell: ({ row }: any) => {
      return (
        <Flex gap="0.5rem">
          <Button
            label="Duplicate Form"
            glyph="add"
            size={200}
            link={duplicateFormLink(row.original)}
            onClick={onDuplicateFormClick ? () => onDuplicateFormClick(row.original) : undefined}
            permission="recovery_coaching.create"
          />
        </Flex>
      )
    },
  },
]

type Props = {
  data: Object
  duplicateFormLink?: Function
  emptyActions?: React.ReactNode
  isLoading: boolean
  localStorageKey: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
  hiddenColumns?: any
}

const RecoveryCoachingTable = ({
  data,
  duplicateFormLink,
  emptyActions,
  isLoading,
  batchActionsConfig,
  localStorageKey,
  to,
  titleAfter,
  hiddenColumns,
  onClick,
  onDuplicateFormClick,
}: Props) => {
  const { timezone } = useSettings()

  return (
    <Table
      showFilters
      testKey="recovery_coaching_table"
      title="Recovery Note Forms"
      titleAfter={titleAfter}
      icon="recovery_coaching"
      data={data}
      columns={columns(to, duplicateFormLink, onClick, onDuplicateFormClick, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No recovery coaching records added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      hiddenColumns={hiddenColumns}
    />
  )
}

RecoveryCoachingTable.defaultProps = {
  localStorageKey: 'recovery_coaching',
}

export default RecoveryCoachingTable
