import React from 'react'

import AmountInput from '../../components/Forms/AmountInput'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'

import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootInvoiceWriteOffOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'TODO',
    endpoint: '/TODO',
    invalidate: 'TODO',
    options: props,
  })

  const [formData, setFormData] = React.useState(initialModel)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(null)

  const { timezone } = useSettings()

  const { client } = props

  const save = () => {
    close()
    // saveWithData({ ...formData, payed_with: formData.payed_with?.model })
  }

  // if (isOverlayLoading) {
  //   return <OverlayLoader position="right" />
  // }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon={ICONS.online_card_payment} title={isNew ? 'Add Write-Off' : 'Write-Off'} />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          initialModel={initialModel}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          onUpdate={setFormData}
        >
          <Section>
            <FormSection layout="vertical">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a charge name',
                  },
                }}
              />

              <Flex gap={16}>
                <AmountInput
                  label="Amount"
                  model="amount"
                  isEditable={isNew || (isEditable && initialModel.source === 'app')}
                  validations={{
                    presence: {
                      message: 'Please enter an amount',
                    },
                    numericality: {
                      greaterThan: 0,
                      message: 'Please enter an amount greater than 0',
                    },
                  }}
                />

                <DateInput
                  defaultToNow
                  label="Write-Off Date"
                  model="transactioned_at"
                  fullWidth
                  validations={{
                    presence: {
                      message: 'Please set a write-off date',
                    },
                  }}
                />
              </Flex>

              {/* <RadioGroup
                
                label="Payer Type"
                model="reference_category"
                layout="horizontal-dense"
                defaultValue="client"
              >
                <Radio  label="Client" value="client" />
                <Radio  label="Contact" value="contact" />
              </RadioGroup>

              <ContextShow when="reference_category" is="client">
                <ObjectSelector
                  isEditable={false}
                  isPolymorphic
                  
                  label="Payer"
                  model="reference"
                  value={client}
                />
              </ContextShow>

              <ContextShow when="reference_category" is="contact">
                <ObjectSelector
                  isPolymorphic
                  icon="contacts"
                  label="Payer"
                  model="reference"
                  type="client.contacts"
                  description={
                    <>
                      Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                    </>
                  }
                  dependentValue={client?.id}
                  selectTitle={(data: any) => data?.name}
                  selectDescription={(data: any) => titleCase(data?.relationship)}
                />
              </ContextShow> */}

              {/* <Divider className="!mt-2 !mx-0 !mb-0" /> */}

              <Textarea useQuickText label="Notes" model="notes" />

              {!isNew && data && (
                <Flex gap="1rem">
                  <DateTimeInput isEditable={false} label="Date Created" model="created_at" />
                  <DateTimeInput isEditable={false} label="Date Updated" model="updated_at" />
                </Flex>
              )}
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this payment">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable ? (
          <>
            <Button
              label="Save Write-Off"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              flex="100 1 auto"
              isDisabled={isInvalid}
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" onClick={cancel} isDisabled={isSaving} />}
          </>
        ) : (
          <>
            <Button glyph="edit" label="Edit Write-Off" type="default" isDisabled={isSaving} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Write-Off?"
              message="Are you sure you want to delete this Write-Off? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button fullWidth glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const InvoiceWriteOffOverlay = withOverlayError(RootInvoiceWriteOffOverlay)
