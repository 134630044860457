import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'

import withMarketing from '@behavehealth/hocs/withMarketing'
import { User } from '@behavehealth/declarations'

import { Grid, Page, Tabs, TabList, TabPanels, TabPanel, Tab, HelpTagIframe } from '@behavehealth/components'

import EVOBs from './benefits/evobs'
import VOBs from './benefits/vobs'
import Summaries from './benefits/summaries'

const pageConfig = {
  feature: 'payer_benefits',
  help: <HelpTagIframe id="payer_benefits" />,
  marketingID: 'payer_benefits',
}

const Benefits: React.FC = () => {
  const { url } = useRouteMatch()

  const user: User = useSelector((state: any) => state.me?.user)
  const isBehave = user?.type === 'bh_employee'

  return (
    <Page {...pageConfig}>
      <Grid gap={16}>
        <Tabs>
          <TabList>
            <Tab label="eVOBs" to={`${url}/evobs`} />
            <Tab label="VOB Summaries" to={`${url}/summaries`} />
            {isBehave && <Tab label="VOBs" to={`${url}/vobs`} />}
          </TabList>
        </Tabs>

        <TabPanels>
          <TabPanel>
            <Switch>
              <Route path="/:resource_type/:resource_id/benefits/evobs" component={EVOBs} />
              <Route path="/:resource_type/:resource_id/benefits/summaries" component={Summaries} />
              <Route path="/:resource_type/:resource_id/benefits/vobs" component={VOBs} />

              <Redirect exact from={url} to={`${url}/evobs`} />
            </Switch>
          </TabPanel>
        </TabPanels>
      </Grid>
    </Page>
  )
}

export default withMarketing(Benefits, pageConfig)
