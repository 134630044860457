import React from 'react'
import size from 'lodash/size'

import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import FormSection from '../../../components/Forms/FormSection'
import State from '../../../components/State'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'

import { useGet, useUpdate, useDelete } from '../../../hooks/useNewAPI'

import ClientPaymentMethodOverlay from '../../Stripe/ClientPaymentMethodOverlay'
import { PaymentMethod } from '../../Stripe/ClientPaymentMethodsSection'

const PaymentMethods = (props: any) => {
  const { client, setStatus } = props

  const { tenant } = useSettings()

  const { data, isLoading }: any = useGet({
    name: ['client', client?.id, 'payment_methods'],
    url: `/residents/${client?.id}/payment_methods`,
    options: { enabled: !!client?.id },
  })

  const { mutateAsync: updateClient } = useUpdate({
    name: ['client', client.id],
    url: `/residents/${client?.id}`,
    invalidate: ['clients'],
  })

  const { mutateAsync: deletePaymentMethod } = useDelete({
    name: ['client', client.id, 'payment_methods'],
    url: '/payment_methods',
    invalidate: 'payment_methods',
  })

  const isEmpty = size(data) === 0

  const { goNext }: any = React.useContext(Workflow.Context)

  if (!tenant?.is_stripe_connected) return null

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  if (isLoading) return <State isLoading />

  const addAction = (
    <SummonOverlay overlay={<ClientPaymentMethodOverlay stripeConnectID={tenant?.stripe_account_id} customer={client} tenant={tenant} />}>
      <Button
        label="Add Payment Method"
        glyph="add"
        type="primary"
        permission="ledger.create"
        display="inline-flex"
      />
    </SummonOverlay>
  )

  return (
    <FormSection maxWidth="100%">
      <div>
        {isLoading || isEmpty ? (
          <Card className="mb-3">
            <State
              icon="billing"
              title="Payment Methods"
              emptyDescription="No payment method added yet"
              isLoading={isLoading}
              isEmpty={isEmpty}
              minHeight={180}
              emptyActions={addAction}
            />
          </Card>
        ) : (
          <div>
            <div className="grid gap-4 grid-cols-1">
              {Object.keys(data).map((key: any) => (
                <PaymentMethod
                  key={key}
                  paymentMethod={data[key]}
                  client={client}
                  updateDefaultPaymentMethod={updateClient}
                  deletePaymentMethod={deletePaymentMethod}
                />
              ))}
            </div>

            <div className="mt-4">{addAction}</div>
          </div>
        )}
      </div>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        <Workflow.SkipButton onClick={onSkip} />
      </Workflow.Buttons>
    </FormSection>
  )
}

export default PaymentMethods
