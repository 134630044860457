import React from 'react'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import { useSettings } from '../../hooks'
import IntegrationStatus from '../Statuses/IntegrationStatus'

const columns = (to: Function, mainLinkAs: any, timezone: string) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ row, value }: any) => <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} label={value} />,
  },
  {
    width: 150,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <IntegrationStatus status={value} />,
  },
  {
    width: 200,
    accessor: 'notes',
    Header: 'Notes',
    Cell: ({ value }: any) => <TableCell value={value} />,
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author.name',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={value} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  title: string
  to: Function
  batchActionsConfig?: Object
}

const IntegrationsTable = (props: Props) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    batchActionsConfig,
    title,
    hiddenColumns,
    to,
    mainLinkAs,
  } = props

  const { timezone } = useSettings()

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={columns(to, mainLinkAs, timezone)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      showSettings={false}
    />
  )
}

IntegrationsTable.defaultProps = {
  title: 'Integrations',
  icon: 'connections',
  emptyDescription: 'No Integrations added yet',
  localStorageKey: 'my_integrations',
}

export default IntegrationsTable
