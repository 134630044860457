import React from 'react'
import { useParams } from 'react-router-dom'
import startCase from 'lodash/startCase'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../Button'
import DeleteDialog from '../Dialogs/DeleteDialog'
import Form from '../Forms/Form'
import FormSection from '../Forms/FormSection'
import ObjectSelector from '../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../Overlay'
import OverlayLoader from '../OverlayLoader'
import Section from '../Section'
import Textarea from '../Forms/Textarea'

import ClaimTaskStatusSelect from './components/ClaimTaskStatusSelect'

const RootClaimTaskOverlay = (props: any) => {
  const { timezone } = useSettings()
  const { insurance_claim_id }: any = useParams()

  const claimID = props.claimID || insurance_claim_id

  const {
    cancel,
    close,
    deleteRecord,
    edit,
    form,
    initialModel,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    data,
  } = useOverlay({
    name: 'insurance-claim-task',
    endpoint: '/insurance_claim_tasks',
    invalidate: 'insurance-claim-tasks',
    options: props,
    Overlay: ClaimTaskOverlay,
  })

  if (isOverlayLoading) return <OverlayLoader showBackdrop={props.showBackdrop} position="right" maxWidth={35} />

  return (
    <Overlay showBackdrop={isEditable || props.showBackdrop} closeOnBackdrop={!isEditable} onClose={close} maxWidth={35}>
      <Overlay.Header icon="tasks" title="Claim Task" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          linked={isNew && { insurance_claim_id: claimID }}
        >
          <Section>
            <FormSection>
              <ClaimTaskStatusSelect
                model="status"
                {...(initialModel.status && { value: initialModel.status })} // update status after the query is re-fetched with the overlay open
              />
              <ObjectSelector
                isPolymorphic
                label="Assignee"
                model="assignee"
                type="bh_employees.all"
                icon="employees"
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => startCase(data.position)}
              />
              <Textarea label="Task Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
            />
            <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
          </>
        )}

        {!isEditable && (
          <>
            <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete claim task?"
              message="Are you sure you want to delete this claim task? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button label="Delete" glyph="delete" color="red" isLoading={props.loading} />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClaimTaskOverlay = withOverlayError(RootClaimTaskOverlay)
