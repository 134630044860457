import React from 'react'

import { Link, NavLink } from 'react-router-dom-v5-compat'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import { nicePhoneNo } from '@behavehealth/utils/functions'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'
import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import LinkValue from '@behavehealth/components/LinkValue'
import { AccountingSyncButton } from '@behavehealth/constructs/Accounting/AccountingSyncButton'

import { DEFAULT_FILTERS } from '@behavehealth/constructs/Filters/constants'

import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { LinkCell } from '@behavehealth/components/DataTable/cells/LinkCell'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import SmartStatus from '@behavehealth/components/SmartStatus'

const referenceUrl = (data: object) => {
  let url = ''

  let residentStatusUrlPaths = {
    alumni: 'alumni',
    current: 'clients',
    intake: 'admissions',
  }

  let resident = data.reference.client
  url = `/${residentStatusUrlPaths[resident.status]}/${resident.id}/financials/charges/${data.reference.id}`

  return url
}

const RootInvoiceLines = () => {
  const { isBehave } = useSettings()

  const tableProps = useDataTable({
    name: ['accounting', 'invoice_lines'],
    endpoint: `/accounting/resource_lines?resource_type=invoice`,
    // params: {...},
    localStorageKey: 'accounting_invoice_lines_v1',
  })

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Customer',
        model: 'resource_ref_name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell as={NavLink} id={data.id} value={data.resource_ref_name} to={`${data.id}`} />,
      },
      {
        title: 'Behave Record',
        model: 'reference.name',
        width: 260,
        disableSort: true,
        // type: 'profile',
        formatValue: ({ value, data }: any) => {
          if (!data.reference) return null

          let url = referenceUrl(data)

          return <LinkCell isExternal value={value} href={url} avatar={data.reference.avatar || ''} />
        },
      },
      {
        title: 'Description',
        model: 'description',
        width: 350,
        disableHide: true,
      },
      {
        title: 'Quantity',
        model: 'quantity',
        width: 160,
        disableHide: true,
        type: 'number',
      },
      {
        title: 'Unit Amount',
        model: 'unit_amount',
        width: 160,
        disableHide: true,
        type: 'number',
      },
      {
        title: 'Sub Total',
        model: 'sub_total',
        width: 250,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Total Amount',
        model: 'total_amount',
        width: 250,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Tax Amount',
        model: 'tax_amount',
        width: 250,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Discount Percentage',
        model: 'discount_percentage',
        width: 250,
        disableHide: true,
        type: 'amount',
      },
      {
        title: 'Discount Amount',
        model: 'discount_amount',
        width: 250,
        disableHide: true,
        type: 'amount',
      },
    ]
  }, [])

  return (
    <Page feature="accounting" title="Invoice Lines">
      <Grid gap="1rem" columns="100%">
        <DataTable
          asCard
          title="Invoice Lines"
          emptyDescription="No invoice lines have been sync yet."
          filtersConfig={FILTERS_CONFIG}
          icon="accounting"
          columns={columns}
          {...tableProps}
        />
      </Grid>
    </Page>
  )
}

export const InvoiceLines = withPageError(RootInvoiceLines)

const FILTERS_CONFIG = {
  resource_ref_name: {
    label: 'Invoice Number',
    type: 'string',
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  quantity: {
    label: 'Quantity',
    type: 'string',
  },
  unit_amount: {
    label: 'Unit Amount',
    type: 'string',
  },
  sub_total: {
    label: 'Sub Total',
    type: 'string',
  },
  total_amount: {
    label: 'Total Amount',
    type: 'string',
  },
  tax_amount: {
    label: 'Tax Amount',
    type: 'string',
  },
  discount_amount: {
    label: 'Discount Amount',
    type: 'string',
  },
  discount_percentage: {
    label: 'Discount Percentage',
    type: 'string',
  },
  source_modified_date: {
    label: 'Source Modified Date',
    type: 'date_time',
  },
}
