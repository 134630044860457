import React from 'react'

import { amount, usDate, titleCase } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import BillingDiscountStatus from '../Statuses/BillingDiscountStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const columns = (to: Function = () => {}, timezone: string, mainLinkAs) => [
	{
		isSticky: true,
		canToggleVisible: false,
		accessor: 'name',
		Header: 'Name',
		Cell: ({ row, value }: any) => <TableCell.MainLink as={mainLinkAs} to={to(row.original.id)} label={value} />
	},
	{
		accessor: 'status',
		Header: 'Status',
		Cell: ({ value }: any) => <BillingDiscountStatus status={value} />
	},
	{
		accessor: 'discount_type',
		Header: 'Discount Type',
		Cell: ({ value }: any) => <TableCell value={titleCase(value)} />
	},
	{
		accessor: 'amount_off',
		Header: 'Discount',
		Cell: ({ row }: any) => (
			<TableCell
				value={
					row.original.discount_type === 'fixed'
						? `$${amount(row.original.amount_off)}`
						: `${row.original.percent_off}%`
				}
			/>
		)
	},
	{
		accessor: 'products_count',
		Header: 'Applied Products',
		Cell: ({ value }: any) => value || '–'
	},
	{
		accessor: 'duration',
		Header: 'Duration',
		Cell: ({ value }: any) => <TableCell value={titleCase(value)} />
	},
	{
		accessor: 'created_at',
		Header: 'Date Added',
		Cell: ({ value }: any) => <TableCell value={usDate(value, timezone)} />,
		Filter: TableFilter.Date,
		filter: 'date'
	}
]

type Props = {
	data: Object
	hiddenColumns?: string[]
	isLoading: boolean
	isSelectable: boolean
	localStorageKey: string
	timezone: string
	to?: Function
	titleAfter?: React.ReactNode
	batchActionsConfig?: Object
}

const BillingDiscountsTable = (props: Props) => {
	const { to, hiddenColumns, data, isLoading, localStorageKey, batchActionsConfig, timezone, titleAfter } = props

	return (
		<Table
			title="Discounts"
			titleAfter={titleAfter}
			icon="financials"
			data={data}
			hiddenColumns={hiddenColumns}
			columns={columns(to, timezone, props.mainLinkAs)}
			isLoading={isLoading}
			emptyDescription="No discounts added yet"
			batchActionsConfig={batchActionsConfig}
			localStorageKey={localStorageKey}
		/>
	)
}

BillingDiscountsTable.defaultProps = {
	localStorageKey: 'billing_discounts'
}

export default withSettings(BillingDiscountsTable)
