import React from 'react'
import clsx from 'clsx'

import { TYPOGRAPHY_VARIANTS } from '../theme'
import { Color } from '../declarations/types/Color'

import { CARD_CLASSES } from './Card'
import Type from './Typography/Type'

type Props = {
  after?: React.ReactNode
  color?: Color
  title: string
} & React.HTMLAttributes<any>

const CardTitle: React.FC<Props> = ({ title, after, color, className }) => {
  const classNames = clsx(CARD_CLASSES.TITLE, {
    [className]: className,
  })

  return (
    <Type as="h3" css={styles(!!after)} className={classNames} variant={TYPOGRAPHY_VARIANTS.CARD_TITLE} color={color}>
      {after ? <span>{title}</span> : title}
      {after}
    </Type>
  )
}

const styles = (hasAfter: boolean) => ({
  margin: 0,

  ...(hasAfter && {
    display: 'flex',
    alignItems: 'center',
    margin: '-0.1rem',
    flexWrap: 'wrap',

    '& > *': {
      margin: '0.1rem',
    },
  }),
})

export default CardTitle
