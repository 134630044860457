import React from 'react'
import { connect } from 'react-redux'

import Card from '../../../../Card'
import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import Divider from '../../../../Divider'
import FormSection from '../../../../Forms/FormSection'
import GridTable from '../../../../GridTable'
import HelpTagIframe from '../../../../Help/HelpTagIframe'
import Input from '../../../../Forms/Input'
import Label from '../../../../Label'
import Option from '../../../../Forms/Option'
import Overlay from '../../../../Overlay'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import Section from '../../../../Section'
import Select from '../../../../Forms/Select'
import Textarea from '../../../../Forms/Textarea'

import ClientProfileHeader from '../../../../ClientProfileHeader'
import DataFormStatus from '../../../../Statuses/DataFormStatus'

import { DataFormsBase, mapDispatchToProps, mapStateToProps } from '../DataFormsBase'
import { withOverlayError } from '../../../../../hocs/withOverlayError'

const SUBSTANCES = [
  { name: '11. Alcohol', model: 'alcohol' },
  { name: '12. Marijuana', model: 'marijuana' },
  { name: '13. Cocaine', model: 'cocaine' },
  { name: '14. Heroin', model: 'heroin' },
  { name: '15. Prescription Opioids', model: 'prescription_opioids' },
  { name: '16. Other Opioids', model: 'other_opioids' },
  { name: '17. Methamphetamine', model: 'methamphetamine' },
  { name: '18. Benzodiazepines', model: 'benzodiazepines' },
  { name: '19. Amphetamines', model: 'amphetamines' },
  { name: '20. Designer drugs such as hallucinogens', model: 'designer_drugs' },
  { name: '21. Other drugs', model: 'other_drugs' },
]

const SUBSTANCE_USE_QUESTIONS = [
  { name: <b>Used in the past week?</b>, model: 'did_use_past_week' },
  { name: <b>Used in the past month?</b>, model: 'did_use_past_month' },
  { name: <b>Used in the past year?</b>, model: 'did_use_past_year' },
  { name: 'a. Unplanned use', model: 'unplanned_use' },
  { name: 'b. Desire/effort to cut down', model: 'desire_effort_to_cut_down' },
  { name: 'c. Time spent using/recovering', model: 'time_spent_using_recovering' },
  { name: 'd. Craving/compulsion to use', model: 'craving_compulsion_to_use' },
  { name: 'e. Role obligation failures', model: 'role_obligation_failures' },
  { name: 'f. Social/Interpersonal Problems', model: 'social_interpersonal_problems' },
  { name: 'g. Sacrificing activities', model: 'sacrificing_activities' },
  { name: 'h. Dangerous use', model: 'dangerous_use' },
  { name: 'i. Physical/emotional problems', model: 'physical_emotional_problems' },
  { name: 'j. Tolerance', model: 'tolerance' },
  { name: 'k. Withdrawal symptoms', model: 'withdrawal_symptoms' },
  { name: 'l. Legal problems', model: 'legal_problems' },
]

class IntakeFormOverlay extends DataFormsBase {
  renderHeader = () => (
    <Overlay.Header
      icon="outcome_measures"
      title={this.props.data_form?.name || this.props.title}
      titleAside={<DataFormStatus status={this.props.data_form?.status} />}
      description={<ClientProfileHeader client={this.props.data_form?.client || this.props.client} />}
      help={<HelpTagIframe id="outcome_measures_intake_form" />}
    />
  )

  renderContent = () => (
    <>
      <Section
        title="Introduction"
        headingType="h2"
        scrollview={{
          id: 'introduction',
          name: 'Introduction',
        }}
      >
        <p>
          Thank you for participating in this research study! The survey should take less than 30 minutes to complete. As a reminder, the
          information you submit is confidential and all contact information is used for the sole purpose of locating you for the follow-up
          interviews, and it will not be released except as required by law. Upon discharge, you will be asked to update any contact
          information. If you have any questions about the study, please ask the research coordinator.
        </p>
      </Section>

      <Divider />

      <Section
        title="Demographics and employment status"
        headingType="h2"
        scrollview={{
          id: 'demographics_and_employment_status',
          name: 'Demographics and employment status',
        }}
        commentsModel="data.demographics_and_employment_status"
      >
        <FormSection>
          <Select allowEmpty label="1. Gender" model="data.demographics_and_employment_status.gender">
            <Option label="Male" value="male" />
            <Option label="Female" value="female" />
            <Option label="Non-binary/third gender" value="non_binary_third_gender" />
            <Option label="Prefer to self-describe:" value="prefer_to_self_describe" />
            <Option label="Prefer not to say" value="prefer_not_to_say" />
          </Select>

          <Input label="2. How old are you?" type="number" suffix="y/o" size={4} model="data.demographics_and_employment_status.age" />

          <Select allowEmpty label="3. What is your ethnicity?" model="data.demographics_and_employment_status.ethnicity">
            <Option label="Hispanic or Latino" value="hispanic_or_latino" />
            <Option label="Not Hispanic or Latino" value="not_hispanic_or_latino" />
          </Select>

          <CheckboxGroup label="4. What is your race? (Check all that apply.)" layout="vertical-dense">
            <Checkbox label="Black or African-American" model="data.demographics_and_employment_status.race.black_or_african_american" />
            <Checkbox
              label="American Indian or Alaskan Native"
              model="data.demographics_and_employment_status.race.american_indian_or_alaskan_native"
            />
            <Checkbox
              label="Native Hawaiian or Other Pacific Islander"
              model="data.demographics_and_employment_status.race.native_hawaiian_or_other_pacific_islander"
            />
            <Checkbox label="Asian" model="data.demographics_and_employment_status.race.asian" />
            <Checkbox label="White" model="data.demographics_and_employment_status.race.white" />
            <Checkbox label="Other" model="data.demographics_and_employment_status.race.other" />
          </CheckboxGroup>

          <ContextShow when="data.demographics_and_employment_status.race.other" is={true}>
            <Textarea label="Other race:" model="data.demographics_and_employment_status.race_other" />
          </ContextShow>

          <Select allowEmpty label="5. What is your current marital status?" model="data.demographics_and_employment_status.marital_status">
            <Option label="Never married" value="never_married" />
            <Option label="Divorced" value="divorced" />
            <Option label="Separated" value="separated" />
            <Option label="Widowed" value="widowed" />
            <Option label="Married or living as married" value="married_or_living_as_married" />
          </Select>

          <Input
            label="5b. If ever married, how many times have you been married?"
            suffix="times"
            type="number"
            size={4}
            model="data.demographics_and_employment_status.number_marriages"
          />

          <Select
            allowEmpty
            label="6. What Is the highest degree you have earned?"
            model="data.demographics_and_employment_status.highest_degree"
          >
            <Option label="No high school diploma or GED" value="no_high_school_diploma_or_ged" />
            <Option label="High school diploma or GED" value="high_school_diploma_or_ged" />
            <Option label="Vocational/technical school/business school" value="vocational_technical_school_business_school" />
            <Option label="Associate's degree" value="associate_s_degree" />
            <Option label="Bachelor's degree" value="bachelor_s_degree" />
            <Option label="Master's degree" value="master_s_degree" />
            <Option label="Doctoral-level degree" value="doctoral_level_degree" />
          </Select>

          <Select
            allowEmpty
            label="7. What is your current employment status?"
            model="data.demographics_and_employment_status.employment_status"
          >
            <Option label="Working full time for pay (35 hr./wk. or more)" value="working_full_time_for_pay_35_hr_wk_or_more" />
            <Option label="Working part time for pay (< 35 hr./wk.)" value="working_part_time_for_pay_35_hr_wk" />
            <Option label="Unemployed" value="unemployed" />
            <Option label="Not for pay by choice" value="not_for_pay_by_choice" />
            <Option label="Disabled" value="disabled" />
            <Option label="Retired " value="retired" />
          </Select>

          <Select
            allowEmpty
            label="8. What is your primary job type when working for pay?"
            model="data.demographics_and_employment_status.primary_job_type"
          >
            <Option label="Professional" value="professional" />
            <Option label="Upper-level management / business owner" value="upper_level_management_business_owner" />
            <Option label="Mid-level management" value="mid_level_management" />
            <Option label="Sales / marketing" value="sales_marketing" />
            <Option label="Supervisory" value="supervisory" />
            <Option label="Craft / skilled trades / technical" value="craft_skilled_trades_technical" />
            <Option label="Office / white collar / clerical" value="office_white_collar_clerical" />
            <Option label="Transportation / equipment operator" value="transportation_equipment_operator" />
            <Option label="Laborer / unskilled worker" value="laborer_unskilled_worker" />
            <Option label="Service worker (waiter / waitress)" value="service_worker_waiter_waitress" />
            <Option label="Domestic worker (housekeeper, etc.)" value="domestic_worker_housekeeper_etc" />
            <Option label="Military service" value="military_service" />
          </Select>

          <Select
            allowEmpty
            label="9. In what range was your personal income in the past year?"
            model="data.demographics_and_employment_status.personal_income_range"
          >
            <Option label="$10,000 or less" value="10_000_or_less" />
            <Option label="$10,001 to $20,000" value="10_001_to_20_000" />
            <Option label="$20,001 to $35,000" value="20_001_to_35_000" />
            <Option label="$35,001 to $60,000" value="35_001_to_60_000" />
            <Option label="$60,001 to $90,000" value="60_001_to_90_000" />
            <Option label="Over $90,000" value="over_90_000" />
          </Select>

          <Input
            label="10. What Is the longest period of time (in months) that you were employed in your lifetime?"
            type="number"
            suffix="months"
            model="data.demographics_and_employment_status.longest_employment_period_months"
          />
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Substance Use History"
        headingType="h2"
        scrollview={{
          id: 'substance_use_history',
          name: 'Substance Use History',
        }}
        commentsModel="data.substance_use_history"
      >
        <Card className="!mb-4">
          <GridTable firstColumnSticky templateColumns="180px repeat(11, 140px)">
            <GridTable.Header>
              <GridTable.Cell>
                <i>
                  <b>Note:</b> answer follow-up questions (a-l) only if used in the past year:
                </i>
              </GridTable.Cell>
              <GridTable.Cell>11. Alcohol</GridTable.Cell>
              <GridTable.Cell>12. Marijuana</GridTable.Cell>
              <GridTable.Cell>13. Cocaine</GridTable.Cell>
              <GridTable.Cell>14. Heroin</GridTable.Cell>
              <GridTable.Cell>15. Prescription Opioids</GridTable.Cell>
              <GridTable.Cell>16. Other Opioids</GridTable.Cell>
              <GridTable.Cell>17. Methamphetamine</GridTable.Cell>
              <GridTable.Cell>18. Benzodiazepines</GridTable.Cell>
              <GridTable.Cell>19. Amphetamines</GridTable.Cell>
              <GridTable.Cell>20. Designer drugs such as hallucinogens</GridTable.Cell>
              <GridTable.Cell>21. Other drugs</GridTable.Cell>
            </GridTable.Header>

            {SUBSTANCE_USE_QUESTIONS.map((question) => (
              <GridTable.Row key={question.model}>
                <GridTable.Cell>{question.name}</GridTable.Cell>

                {SUBSTANCES.map((substance) => (
                  <GridTable.Cell key={substance.model} css={checkboxCellStyles}>
                    <Checkbox trueIcon="check" model={`data.substance_use_history.substance_use.${substance.model}.${question.model}`} />
                  </GridTable.Cell>
                ))}
              </GridTable.Row>
            ))}
          </GridTable>
        </Card>

        <FormSection>
          <Input
            label="22. Age of first standard drink:"
            type="number"
            suffix="years old"
            size={4}
            model="data.substance_use_history.first_standard_drink_age"
          />

          <Input
            label="23. Age of first drug use:"
            type="number"
            suffix="years old"
            size={4}
            model="data.substance_use_history.first_drug_use_age"
          />

          <Input
            label="24. Number of different substances used in past week including alcohol:"
            type="number"
            suffix="total substances"
            size={4}
            model="data.substance_use_history.number_substances_used_past_week"
          />

          <Select allowEmpty label="25. Injection history:" model="data.substance_use_history.injection_history">
            <Option label="Never injected" value="never_injected" />
            <Option label="Have injusted in the past" value="have_injusted_in_the_past" />
            <Option label="Currently injecting" value="currently_injecting" />
          </Select>

          <Select allowEmpty label="25b. How often do you inject?" model="data.substance_use_history.injection_frequency">
            <Option label="Never" value="never" />
            <Option label="Less than once in a month" value="less_than_once_in_a_month" />
            <Option label="Greater than once in a month" value="greater_than_once_in_a_month" />
            <Option label="Once a week or more" value="once_a_week_or_more" />
            <Option label="Daily" value="daily" />
          </Select>

          <RadioGroup
            label="25c. Do you share materials (needles, straws, or other tools for using drugs)?"
            model="data.substance_use_history.is_sharing_materials"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value="yes" />
            <Radio label="No" value="no" />
          </RadioGroup>

          <RadioGroup
            label="26. Have you received treatment for a substance use disorder before?"
            model="data.substance_use_history.did_receive_substance_abuse_treatment"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value="yes" />
            <Radio label="No" value="no" />
          </RadioGroup>

          <Input
            label="26b. How many months has it been since your last treatment:"
            type="number"
            suffix="months"
            size={4}
            model="data.substance_use_history.months_since_last_treatment"
          />

          <Label label="27. How supportive or non-supportive do you think the following people would be to you during treatment?" />

          <FormSection css={{ paddingLeft: '1rem', marginBottom: '0.5rem' }}>
            <RadioGroup
              label="27a. Spouse/mate/partner"
              model="data.substance_use_history.people_support.spouse_mate_partner"
              layout="vertical-dense"
            >
              <Radio label="0 = No contact / NA" value={0} />
              <Radio label="1 = Not supportive" value={1} />
              <Radio label="2 = Somewhat unsupportive" value={2} />
              <Radio label="3 = Neutral" value={3} />
              <Radio label="4 = Somewhat supportive" value={4} />
              <Radio label="5 = Very supportive" value={5} />
            </RadioGroup>

            <RadioGroup
              label="27b. Closest friend"
              model="data.substance_use_history.people_support.closest_friend"
              layout="vertical-dense"
            >
              <Radio label="0 = No contact / NA" value={0} />
              <Radio label="1 = Not supportive" value={1} />
              <Radio label="2 = Somewhat unsupportive" value={2} />
              <Radio label="3 = Neutral" value={3} />
              <Radio label="4 = Somewhat supportive" value={4} />
              <Radio label="5 = Very supportive" value={5} />
            </RadioGroup>

            <RadioGroup label="27c. Father" model="data.substance_use_history.people_support.father" layout="vertical-dense">
              <Radio label="0 = No contact / NA" value={0} />
              <Radio label="1 = Not supportive" value={1} />
              <Radio label="2 = Somewhat unsupportive" value={2} />
              <Radio label="3 = Neutral" value={3} />
              <Radio label="4 = Somewhat supportive" value={4} />
              <Radio label="5 = Very supportive" value={5} />
            </RadioGroup>

            <RadioGroup label="27d. Mother" model="data.substance_use_history.people_support.mother" layout="vertical-dense">
              <Radio label="0 = No contact / NA" value={0} />
              <Radio label="1 = Not supportive" value={1} />
              <Radio label="2 = Somewhat unsupportive" value={2} />
              <Radio label="3 = Neutral" value={3} />
              <Radio label="4 = Somewhat supportive" value={4} />
              <Radio label="5 = Very supportive" value={5} />
            </RadioGroup>

            <RadioGroup label="27e. Brother" model="data.substance_use_history.people_support.brother" layout="vertical-dense">
              <Radio label="0 = No contact / NA" value={0} />
              <Radio label="1 = Not supportive" value={1} />
              <Radio label="2 = Somewhat unsupportive" value={2} />
              <Radio label="3 = Neutral" value={3} />
              <Radio label="4 = Somewhat supportive" value={4} />
              <Radio label="5 = Very supportive" value={5} />
            </RadioGroup>

            <RadioGroup label="27f. Sister" model="data.substance_use_history.people_support.sister" layout="vertical-dense">
              <Radio label="0 = No contact / NA" value={0} />
              <Radio label="1 = Not supportive" value={1} />
              <Radio label="2 = Somewhat unsupportive" value={2} />
              <Radio label="3 = Neutral" value={3} />
              <Radio label="4 = Somewhat supportive" value={4} />
              <Radio label="5 = Very supportive" value={5} />
            </RadioGroup>

            <RadioGroup
              label="27g. Other close family member"
              model="data.substance_use_history.people_support.other_close_family_member"
              layout="vertical-dense"
            >
              <Radio label="0 = No contact / NA" value={0} />
              <Radio label="1 = Not supportive" value={1} />
              <Radio label="2 = Somewhat unsupportive" value={2} />
              <Radio label="3 = Neutral" value={3} />
              <Radio label="4 = Somewhat supportive" value={4} />
              <Radio label="5 = Very supportive" value={5} />
            </RadioGroup>

            <RadioGroup
              label="27h. Other close friends"
              model="data.substance_use_history.people_support.other_close_friends"
              layout="vertical-dense"
            >
              <Radio label="0 = No contact / NA" value={0} />
              <Radio label="1 = Not supportive" value={1} />
              <Radio label="2 = Somewhat unsupportive" value={2} />
              <Radio label="3 = Neutral" value={3} />
              <Radio label="4 = Somewhat supportive" value={4} />
              <Radio label="5 = Very supportive" value={5} />
            </RadioGroup>

            <RadioGroup
              label="27i. Fellow workers"
              model="data.substance_use_history.people_support.fellow_workers"
              layout="vertical-dense"
            >
              <Radio label="0 = No contact / NA" value={0} />
              <Radio label="1 = Not supportive" value={1} />
              <Radio label="2 = Somewhat unsupportive" value={2} />
              <Radio label="3 = Neutral" value={3} />
              <Radio label="4 = Somewhat supportive" value={4} />
              <Radio label="5 = Very supportive" value={5} />
            </RadioGroup>
          </FormSection>
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Mental Health Concerns"
        headingType="h2"
        scrollview={{
          id: 'mental_health_concerns',
          name: 'Mental Health Concerns',
        }}
        commentsModel="data.mental_health_concerns"
      >
        <FormSection>
          <Label label="28. In the past 30 days have you experienced the following (not due to substances)?" />

          <FormSection className="!pl-4">
            <RadioGroup
              label="28a. Serious Depression"
              model="data.mental_health_concerns.did_experience_serious_depression"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.mental_health_concerns.did_experience_serious_depression" is={true}>
              <RadioGroup
                label="How bothered are you about the serious depression?"
                model="data.mental_health_concerns.serious_depression"
                layout="vertical-dense"
              >
                <Radio label="Not at all" value={0} />
                <Radio label="Slightly" value={1} />
                <Radio label="Moderately" value={2} />
                <Radio label="Considerably" value={3} />
                <Radio label="Extremely" value={4} />
              </RadioGroup>
            </ContextShow>

            <RadioGroup
              label="28b. Anxiety or tension"
              model="data.mental_health_concerns.did_experience_anxiety_or_tension"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.mental_health_concerns.did_experience_anxiety_or_tension" is={true}>
              <RadioGroup
                label="How bothered are you about the anxiety or tension?"
                model="data.mental_health_concerns.anxiety_or_tension"
                layout="vertical-dense"
              >
                <Radio label="Not at all" value={0} />
                <Radio label="Slightly" value={1} />
                <Radio label="Moderately" value={2} />
                <Radio label="Considerably" value={3} />
                <Radio label="Extremely" value={4} />
              </RadioGroup>
            </ContextShow>

            <RadioGroup
              label="28c. Hallucinations"
              model="data.mental_health_concerns.did_experience_hallucinations"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.mental_health_concerns.did_experience_hallucinations" is={true}>
              <RadioGroup
                label="How bothered are you about the hallucinations?"
                model="data.mental_health_concerns.hallucinations"
                layout="vertical-dense"
              >
                <Radio label="Not at all" value={0} />
                <Radio label="Slightly" value={1} />
                <Radio label="Moderately" value={2} />
                <Radio label="Considerably" value={3} />
                <Radio label="Extremely" value={4} />
              </RadioGroup>
            </ContextShow>

            <RadioGroup
              label="28d. Trouble concentrating"
              model="data.mental_health_concerns.did_have_trouble_concentrating"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.mental_health_concerns.did_have_trouble_concentrating" is={true}>
              <RadioGroup
                label="How bothered are you about the trouble concentrating?"
                model="data.mental_health_concerns.trouble_concentrating"
                layout="vertical-dense"
              >
                <Radio label="Not at all" value={0} />
                <Radio label="Slightly" value={1} />
                <Radio label="Moderately" value={2} />
                <Radio label="Considerably" value={3} />
                <Radio label="Extremely" value={4} />
              </RadioGroup>
            </ContextShow>

            <RadioGroup
              label="28e. Trouble remembering"
              model="data.mental_health_concerns.did_have_trouble_remembering"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.mental_health_concerns.did_have_trouble_remembering" is={true}>
              <RadioGroup
                label="How bothered are you about the trouble remembering?"
                model="data.mental_health_concerns.trouble_remembering"
                layout="vertical-dense"
              >
                <Radio label="Not at all" value={0} />
                <Radio label="Slightly" value={1} />
                <Radio label="Moderately" value={2} />
                <Radio label="Considerably" value={3} />
                <Radio label="Extremely" value={4} />
              </RadioGroup>
            </ContextShow>

            <RadioGroup
              label="28f. Intrusive thoughts of past events"
              model="data.mental_health_concerns.did_experience_intrusive_thoughts"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.mental_health_concerns.did_experience_intrusive_thoughts" is={true}>
              <RadioGroup
                label="How bothered are you about the intrusive thoughts of past events?"
                model="data.mental_health_concerns.intrusive_thoughts"
                layout="vertical-dense"
              >
                <Radio label="Not at all" value={0} />
                <Radio label="Slightly" value={1} />
                <Radio label="Moderately" value={2} />
                <Radio label="Considerably" value={3} />
                <Radio label="Extremely" value={4} />
              </RadioGroup>
            </ContextShow>
          </FormSection>

          <RadioGroup
            label="29. Are you currently seeing a mental health professional?"
            model="data.mental_health_concerns.is_seeing_mental_health_professional"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>

          <Select
            label="30. Have you ever taken or are you currently taking any medication for psychological/psychiatric conditions?"
            model="data.mental_health_concerns.psychological_psychiatric_meds_use"
          >
            <Option label="Never" value="never" />
            <Option label="In past" value="in_past" />
            <Option label="Currently" value="currently" />
            <Option label="Both past and currently" value="both_past_and_currently" />
          </Select>

          <RadioGroup
            label="31. Are you currently taking medication for pain?"
            model="data.mental_health_concerns.is_taking_pain_medication"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>

          <Select
            allowEmpty
            label="31b. If taking medication for pain, are you taking it:"
            model="data.mental_health_concerns.pain_medication_intake"
          >
            <Option label="As prescribed" value="as_prescribed" />
            <Option label="Less than prescribed" value="less_than_prescribed" />
            <Option label="More than prescribed" value="more_than_prescribed" />
          </Select>

          <RadioGroup
            label="31c. If taking medication for pain is it opioid based?"
            model="data.mental_health_concerns.is_pain_medication_opioid_based"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Legal Issues"
        headingType="h2"
        scrollview={{
          id: 'legal_issues',
          name: 'Legal Issues',
        }}
        commentsModel="data.legal_issues"
      >
        <FormSection>
          <Input
            label="32. How many times have you been arrested in the last 12 months?"
            type="number"
            suffix="times"
            size={4}
            model="data.legal_issues.number_times_arrested"
          />

          <RadioGroup
            label="33. Are you mandated to treatment by the court or correctional system?"
            model="data.legal_issues.is_mandated_to_treatment"
            layout="horizontal-dense"
          >
            <Radio label="Yes" value={true} />
            <Radio label="No" value={false} />
          </RadioGroup>
        </FormSection>
      </Section>

      <Divider />

      <Section>
        <p>
          <b>Copyright:</b> The NAATP Addiction Treatment Outcomes Measurement Program
        </p>
      </Section>
    </>
  )
}

const checkboxCellStyles = {
  display: 'flex',
  justifyContent: 'center',
}

IntakeFormOverlay.defaultProps = {
  title: 'Intake Form',
  category: 'outcome_measure',
  subcategory: 'intake_form',
  requestClientSignature: false,
  requestAuthorSignature: false,
  requireSupervisor: false,
  maxWidth: 85,
}

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(IntakeFormOverlay))
