import React from 'react'

import { useSettings } from '../../hooks'

import Status from '../../components/Status'
import Table from '../../components/Table/Table'
import TableCell from '../../components/Table/TableCell'
import TableFilter from '../../components/Table/TableFilter'

import SubscriptionStatus from './SubscriptionStatus'

const columns = (to: Function = () => {}, mainLinkAs, timezone: string) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'product.name',
    Header: 'Name',
    Cell: ({ value, row }: any) => {
      return <TableCell.MainLink isTruncated as={mainLinkAs} row={row} to={to(row.original.id)} label={value} />
    },
  },
  {
    width: 120,
    accessor: 'product.category',
    Header: 'Category',
    Cell: ({ value }: any) => (value ? <Status label={value.replace('_', ' ')} color="blue" /> : null),
    Filter: TableFilter.Select,
    filterOptions: [
      {
        value: 'incident_report',
        label: 'Incident Report',
      },
      {
        value: 'general_note',
        label: 'General Note',
      },
    ],
  },
  {
    width: 160,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <SubscriptionStatus status={value} />,
  },
  {
    width: 200,
    accessor: 'current_period_start',
    Header: 'Current Billing Period Start',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'current_period_end',
    Header: 'Current Billing Period End',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'current_period_estimated_cost',
    Header: 'Current Billing Period',
    Cell: ({ row }: any) => <TableCell.Amount value={row.value} />,
  },
  {
    width: 200,
    accessor: 'started_at',
    Header: 'Subscription Started On',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'cancel_at_period_end',
    Header: 'Will Cancel at Period End',
    Cell: ({ value }: any) => <TableCell.TrueFalse isTrue={value} />,
  },
  {
    width: 200,
    accessor: 'cancelled_at',
    Header: 'Subscription Ended On',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'should_cancel_at',
    Header: 'Cancel Subscription On',
    Cell: ({ value }: any) => <TableCell.UsDate value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  selectActions?: Function
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const SubscriptionsTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, localStorageKey, selectActions, batchActionsConfig, hiddenColumns, titleAfter, mainLinkAs } =
    props
  const { timezone } = useSettings()

  return (
    <Table
      showFilters
      title="Subscriptions"
      titleAfter={titleAfter}
      icon="billing"
      data={data}
      columns={columns(to, mainLinkAs, timezone)}
      hiddenColumns={hiddenColumns}
      selectActions={selectActions}
      batchActionsConfig={batchActionsConfig}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No subscriptions found yet"
      localStorageKey={localStorageKey}
    />
  )
}

SubscriptionsTable.defaultProps = {
  localStorageKey: 'subscriptions',
}

export default SubscriptionsTable
