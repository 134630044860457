import React from 'react'

import { COLORS } from '../../theme'

import Flex from '../Flex'
import Loader from '../Loader'

const PageLoader = ({ message }) => (
  <Flex centerX centerY css={{ background: COLORS.lightBackground, height: '100%', minHeight: 400 }}>
    <Flex vertical centerX centerY gap={16}>
      <Loader />
      {message}
    </Flex>
  </Flex>
)

export default PageLoader
