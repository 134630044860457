import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Alert, MarketingBanner, Button } from '@behavehealth/components'
import { apiCreate } from '@behavehealth/modules/api'

export const ClearSampleDataBanner = ({ isFullWidth }: any) => {
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const user = useSelector((state) => state.me.user)

  const clearSampleData = async () => {
    setLoading(true)

    try {
      await apiCreate({ url: '/apps/seeds/delete_defaults' })
      history.go(0)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  // if not management or bh employee, don't show
  if (!['owner', 'administrator', 'dev'].includes(user?.position)) return null

  return (
    <MarketingBanner
      isFullWidth={isFullWidth}
      description={
        <>
          <div>
            We populated your account with some <strong>sample data</strong> to make it easy to see how our system works.
          </div>
          <p>Once you are ready to get started, use the button on the right to clear our sample data.</p>
          <Alert>
            <b>Note:</b> only the sample data will be removed. You will keep any data you have added.
          </Alert>
        </>
      }
      action={
        <Button color="green" type="primary" glyph="behave" label="Clear Sample Data" onClick={clearSampleData} isLoading={loading} />
      }
    />
  )
}
