import React from 'react'
import groupBy from 'lodash/groupBy'

import { SystemVariablesTable } from './SystemVariablesTable'
import { useFormBuilder } from '../useFormBuilder'

export const TimeVariablesTable = () => {
  const variables: any = useFormBuilder((state: any) => state.variables) || []
  const variableValues: any = useFormBuilder((state: any) => state.variableValues)
  const variablesByCategory = React.useMemo(() => groupBy(variables, 'category'), [variables])

  const timeVariables = React.useMemo(() => {
    const result: any = []

    if (variablesByCategory?.time) {
      for (const variable of variablesByCategory.time) {
        result.push({
          ...variable,
          variable_value: variableValues[variable.variable_key] || variable.variable_value,
        })
      }
    }

    return result
  }, [variablesByCategory, variableValues])

  return <SystemVariablesTable title="Time Variables" variables={timeVariables} valueColumnWidth="110px" />
}
