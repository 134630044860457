import { persist } from 'zustand/middleware'
import create from 'zustand'

import { ELEMENT_EDITOR_STATES } from './utils/constants'

export const useFormOptionsStore = create(
  persist(
    (set: any, get: any) => ({
      elementEditorState: 'auto',

      setElementEditorState: (newState: string) => {
        if (!Object.keys(ELEMENT_EDITOR_STATES).includes(newState)) return

        set({ elementEditorState: newState })
      },
    }),
    { name: 'bh.form-builder-options' },
  ),
)
