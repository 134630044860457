import React from 'react'

import Alert from '../../../components/Alert'
import CardTreeItem from '../../../components/CardTreeItem'
import FormSection from '../../../components/Forms/FormSection'

import { ElectronicStatusesView } from './ElectronicStatusesView'

import { useGet } from '../../../hooks/useNewAPI'

export const ElectronicStatuses = ({ claim }: any) => {
	const { data }: any = useGet({
		name: ['insurance-claims', claim?.id, 'insurance-claim-statuses'],
		url: `/insurance_claims/${claim?.id}/insurance_claim_statuses`
	})

	if (!claim) return null

	return (
		<CardTreeItem isOpen title="Treatment Plan Reviews">
			<FormSection className="pt-3 pb-5 pr-4">
				<Alert small contrast glyph="info">
					Set up review periods for the treatment plan to generate review records automatically.
				</Alert>
			</FormSection>
		</CardTreeItem>
	)
}
