import React from 'react'

import Section from '../../../components/Section'

import { useTreatmentPlanStore } from '../store'

export const TreatmentPlanSection = (props: any) => {
  const { id, icon, after, title, glyph, children } = props

  const sectionsOpen = useTreatmentPlanStore((state: any) => state.sectionsOpen)
  const toggleSection = useTreatmentPlanStore((state: any) => state.toggleSection)

  const isOpen = React.useMemo(() => {
    return sectionsOpen.includes(id)
  }, [sectionsOpen, id])

  const handleToggleOpen = () => {
    toggleSection(id)
  }

  return (
    <Section
      // icon={icon}
      // glyph={glyph}
      headingType="h2"
      title={title}
      aside={after}
      className="!my-0 !py-8 border-b border-0 border-solid border-divider"
      scrollview={
        (id || title) && {
          id: id,
          name: title,
        }
      }
    >
      <div className="grid grid-cols-[100%] relative z-0">{children}</div>
    </Section>
  )
}
