import React from 'react'

import SmartStatus from '../SmartStatus'

const statuses = {
	acknowledged: {
		label: 'Acknowledged',
		color: 'green'
	},
	rejected: {
		label: 'Rejected',
		color: 'red'
	}
}

const Status = ({ ...rest }) => {
	return <SmartStatus statuses={statuses} {...rest} />
}

export default Status
