import React from 'react'
import { NavLink, Navigate, Route, Routes, useMatch } from 'react-router-dom-v5-compat'

import { FormSubmissionOverlay } from '@behavehealth/constructs/FormSubmissions/FormSubmissionOverlay'

import PageHeader from '@behavehealth/components/PageHeader'
import Tabs from '@behavehealth/components/Tabs'
import TabList from '@behavehealth/components/TabList'
import Tab from '@behavehealth/components/Tab'

import { FormInbox } from './form_inbox'
import { FormAssigned } from './form_assigned'

import { FormSubmissionPageActionsV6 } from '@behavehealth/constructs/FormSubmissions/FormSubmissionPageActionsV6'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'

export const FormSubmissions = () => {
  const match = useMatch('/form-submissions/:view/*')

  const back = `/form-submissions/${match?.params.view}`

  return (
    <>
      <div>
        <PageHeader showFeedbackTool title="Form Inbox" icon="web_form" aside={<FormSubmissionPageActionsV6 />} />

        <div>
          <Tabs className="!px-4 !py-0">
            <TabList>
              <Tab as={NavLink} label="Inbox" to="inbox" />
              <Tab as={NavLink} label="Filed Forms" to="assigned" />
            </TabList>
          </Tabs>
        </div>

        <div className="!p-4">
          <Routes>
            <Route index element={<Navigate to="inbox" replace />} />

            <Route path="inbox/*" element={<FormInbox />} />
            <Route path="assigned/*" element={<FormAssigned />} />
          </Routes>
        </div>
      </div>

      <AnimatedRoutes>
        <Route path=":view/new/:form_id" element={<FormSubmissionOverlay useV6Router back={back} />} />
        <Route path=":view/:id" element={<FormSubmissionOverlay useV6Router back={back} />} />
      </AnimatedRoutes>
    </>
  )
}
