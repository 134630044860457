import React from 'react'

import { COLORS } from '../../../../../theme'

const Subsection: React.FC<any> = ({ children }) => {
  return <div css={styles.root}>{children}</div>
}

const styles = {
  root: {
    marginLeft: '0.75rem',
    paddingLeft: '0.75rem',
    borderLeft: `1px solid ${COLORS.divider}`,
    width: `auto !important`,
  },
}

export default Subsection
