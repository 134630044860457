import React from 'react'

import SmartStatus from '../SmartStatus'

const STATUSES = {
  todo: {
    label: 'To do',
    color: 'blue',
  },
  in_progress: {
    label: 'In Progress',
    color: 'orange',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
  overdue: {
    label: 'Overdue',
    color: 'red',
  },
  skipped: {
    label: 'Skipped',
    color: 'gray',
  },
}

type Props = {
  status: string
}

const WorkflowStatus = ({ status }: Props) => {
  return <SmartStatus status={status} statuses={STATUSES} />
}

export default WorkflowStatus
