import React from 'react'

import { useGet } from '../../../hooks/useNewAPI'

import Card from '../../../components/Card'
import ClaimTasksTable from './ClaimTasksTable'

export const ClaimTasks = ({ claimID, to, onClick, titleAfter }: any) => {
  const { data: tasks, isLoading: isLoadingTasks }: any = useGet({
    name: ['insurance-claims', claimID, 'insurance-claim-tasks'],
    url: `/insurance_claims/${claimID}/insurance_claim_tasks`,
    options: { enabled: !!claimID },
  })

  if (!claimID) return null

  return (
    <Card>
      <ClaimTasksTable data={tasks} claimID={claimID} isLoading={isLoadingTasks} to={to} onClick={onClick} titleAfter={titleAfter} />
    </Card>
  )
}
