import React from 'react'

import { Status } from '../'

export const STATUSES = {
  admitting: {
    label: 'Admitting',
    color: 'blue',
  },
  primary: {
    label: 'Primary',
    color: 'green',
  },
  principal: {
    label: 'Principal',
    color: 'green',
  },
  secondary: {
    label: 'Secondary',
    color: 'gray',
  },
}

const DiagnosisCategoryStatus = ({ status }: any) => <Status label={STATUSES[status]?.label} color={STATUSES[status]?.color} />
export default DiagnosisCategoryStatus
