import React from 'react'
import produce from 'immer'

import { apiCreate } from '../../modules/api'
import { COLORS } from '../../theme'
import { notBlank } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import withSettings from '../../hocs/withSettings'

import Accordion from '../../components/Accordion'
import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContactMethodsCheckboxGroup from '../../components/Forms/elements/ContactMethodsCheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DateOfBirthInput from '../../components/Forms/DateOfBirthInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Link from '../../components/Link'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import SexSelect from '../../components/Forms/elements/SexSelect'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'

import { DuplicateClientOverlay } from './DuplicateClientOverlay'

const RootNewApplicantOverlay = (props: any) => {
  const { close, form, isInvalid, onValidationUpdate, saveWithData } = useOverlay({
    name: 'intake_applications',
    endpoint: '/intake_applications',
    invalidate: 'intake_applications',
    invalidateKeys: ['clients'],
    options: {
      ...props,
      dataID: 'new',
    },
  })

  const { timezone, tenant } = useSettings()

  const [duplicateCheckLoading, setDuplicateCheckLoading]: any = React.useState(false)
  const [showDuplicateOverlay, setShowDuplicateOverlay]: any = React.useState(false)
  const [duplicateClient, setDuplicateClient]: any = React.useState(null)
  const [applicationForm, setApplicationForm]: any = React.useState(null)

  const checkDuplicateAndSave = async () => {
    const model = form.current.getFormValue()

    try {
      setDuplicateCheckLoading(true)

      let duplicateClient = await apiCreate({
        notify: false,
        url: '/residents/check_duplicate',
        params: {
          first_name: model.data.personal_details.first_name,
          last_name: model.data.personal_details.last_name,
        },
      })

      console.log('duplicateClient', duplicateClient)

      setDuplicateCheckLoading(false)
      setShowDuplicateOverlay(true)

      setDuplicateClient(duplicateClient.data.data[0])
    } catch {
      await save()

      setDuplicateCheckLoading(false)
      setShowDuplicateOverlay(false)
    }
  }

  const save = async () => {
    await saveWithData({
      ...form.current.getFormValue(),
    })

    close()
  }

  const createNew = async () => {
    const formData = form.current.getFormValue()

    const params = produce(formData, (draft) => {
      draft.data.personal_details.allow_duplicate = true
    })

    await saveWithData(params)

    close()
  }

  return (
    <Overlay showBackdrop onClose={close}>
      <Overlay.Header icon="admissions" title="Add Applicant" help={<HelpTagIframe id="add_applicant" />} />

      <Overlay.Content className="py-4 px-5">
        <SummonOverlay
          isOpen={showDuplicateOverlay}
          overlay={
            <DuplicateClientOverlay
              client={duplicateClient}
              save={save}
              close={() => setShowDuplicateOverlay(false)}
              createNew={createNew}
            />
          }
        />

        <Form
          getForm={form}
          onValidationUpdate={onValidationUpdate}
          timezone={timezone}
          initialModel={{
            application_form: tenant.default_application_form,
          }}
        >
          {(data) => (
            <>
              <Accordion isOpen title="Personal Details" glyph="personal_details">
                <FormSection>
                  <Input
                    autoFocus
                    label="First Name"
                    model="data.personal_details.first_name"
                    validations={{
                      presence: {
                        message: 'Please enter a first name',
                      },
                      length: {
                        maximum: 35,
                        message: 'Your first name should not be more than 35 characters',
                      },
                    }}
                  />

                  <Input label="Middle Name" model="data.personal_details.middle_name" />

                  <Input
                    label="Last Name"
                    model="data.personal_details.last_name"
                    validations={{
                      presence: {
                        message: 'Please enter a last name',
                      },
                      length: {
                        maximum: 35,
                        message: 'Your last name should not be more than 35 characters',
                      },
                    }}
                  />

                  <SexSelect label="Sex Assigned at Birth" model="data.demographics.sex" />
                  <DateOfBirthInput label="Date of Birth" model="data.personal_details.dob" />

                  {tenant?.feature_flags?.erx === true && (
                    <>
                      {notBlank(data?.data?.data?.personal_details?.first_name) &&
                      notBlank(data?.data?.data?.personal_details?.last_name) &&
                      (data?.data?.data?.demographics?.sex === 'male' || data?.data?.data?.demographics?.sex === 'female') &&
                      notBlank(data?.data?.data?.personal_details?.dob) ? (
                        <Alert glyph="pill_bottle" type="positive" className="!mt-2">
                          This Client will be automatically registered for e-Prescribing
                        </Alert>
                      ) : (
                        <Alert glyph="pill_bottle" type="warning" className="!mt-2">
                          This Client is missing the data required for e-Prescribing. The Client must have a valid <b>First Name</b>,{' '}
                          <b>Last Name</b>, <b>Sex</b> and <b>Date of Birth</b>. <br />
                          <i>If you don't know this info now, you can always Sync later</i>
                        </Alert>
                      )}
                    </>
                  )}
                </FormSection>
              </Accordion>

              <Accordion isOpen title="Address">
                <FormSection>
                  <AddressSelectorInput model="data.address" actionLabel="Find Address" />
                </FormSection>
              </Accordion>

              <Accordion isOpen title="Contact Details" glyph="contact_details">
                <FormSection>
                  <ContactMethodsCheckboxGroup label="Contact Methods" model="data.contact.primary_contact" />

                  <CheckboxGroup
                    label="Consent to Contact"
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="faded"
                    description={
                      <Alert glyph="info" type="warning" className="!mt-2">
                        Do not enable consent to contact without a valid, signed authorization from the client
                      </Alert>
                    }
                  >
                    <Checkbox
                      label="I acknowledge that the Client has given valid Consent to be contacted via SMS or email"
                      model="data.contact.is_allowed_to_contact"
                    />
                  </CheckboxGroup>
                </FormSection>
              </Accordion>

              <Accordion isOpen title="Send Application Form" glyph="intake_application" newFeatureFlag="application_form">
                <FormSection>
                  <ObjectSelector
                    label="Application Form"
                    model="application_form"
                    type="application_forms"
                    icon="applications"
                    selectTitle={(item: any) => {
                      return (
                        <span className="flex items-center">
                          {item.name}
                          {item.is_default && <Status small label="Default" color="blue" className="ml-1.5" />}
                        </span>
                      )
                    }}
                    selectDescription={() => ''}
                    validations={{
                      presence: {
                        message: 'Please select an application form',
                      },
                    }}
                    onUpdate={(value: any) => {
                      // update on mount
                      setApplicationForm(value.value)
                    }}
                    onChanged={(value: any) => {
                      // update on every change
                      setApplicationForm(value.value)
                    }}
                  />

                  {data?.data?.application_form && (
                    <Alert small contrast type="warning">
                      <div className="grid gap-3">
                        <div className="flex flex-nowrap items-center">
                          <Icon icon="admissions_department" size={16} className="mr-3 flex-0" />
                          <div>Before saving, a duplicate check will be performed using the "First Name" and "Last Name" fields</div>
                        </div>

                        <div className="flex flex-nowrap items-center">
                          <Icon icon="applications" size={16} className="mr-3 flex-0" />
                          <div>
                            Upon saving, an Application Form will be created based on{' '}
                            <Link
                              to={`/settings/application-forms/${applicationForm?.id}`}
                              className="font-[600] inline-flex items-center"
                              target="_blank"
                            >
                              "{applicationForm?.name}" <Glyph size={12} glyph="external_link" className="ml-1" color={COLORS.blue} />
                            </Link>
                          </div>
                        </div>

                        <div className="flex flex-nowrap items-center">
                          <Icon icon="medications" size={16} className="mr-3 flex-0" />
                          <div>
                            Any medications entered in the Application Form will{' '}
                            {applicationForm?.should_process_medications ? 'be' : <b>NOT be</b>} automatically added to the applicant's
                            Medications page
                          </div>
                        </div>

                        <div className="flex flex-nowrap items-center">
                          <Icon icon="contacts" size={16} className="mr-3 flex-0" />
                          <div>
                            Any contacts entered in the Application Form will{' '}
                            {applicationForm?.should_process_contacts ? 'be' : <b>NOT be</b>} automatically added to the applicant's
                            Contacts page
                          </div>
                        </div>
                      </div>
                    </Alert>
                  )}

                  <CheckboxGroup layout="vertical-dense">
                    <ContextShow when="data.contact.primary_contact.has_phone_no" is={true}>
                      <Checkbox label="Send application form via SMS" model="should_sms" />
                    </ContextShow>

                    <ContextShow when="data.contact.primary_contact.has_email" is={true}>
                      <Checkbox label="Send application form via Email" model="should_email" />
                    </ContextShow>
                  </CheckboxGroup>

                  <Alert glyph="info">Add a cell phone number and/or email address above to text or email the full application form.</Alert>

                  {(data?.data?.should_email || data?.data?.should_sms) && tenant?.default_application_form?.sms_message && (
                    <Alert type="neutral" glyph="note">
                      <p css={{ margin: 0, fontWeight: 600 }}>The following message will be sent to the client:</p>
                      <p css={{ margin: '0.5em 0', fontStyle: 'italic' }}>{tenant.default_application_form.sms_message}</p>
                    </Alert>
                  )}
                </FormSection>
              </Accordion>
            </>
          )}
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Add Applicant"
          glyph="check"
          type="primary"
          color="green"
          isLoading={duplicateCheckLoading}
          isDisabled={isInvalid}
          onClick={checkDuplicateAndSave}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const NewApplicantOverlay = withOverlayError(withSettings(RootNewApplicantOverlay))
