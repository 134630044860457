import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import compact from 'lodash/compact'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Textarea from '../../components/Forms/Textarea'
import Glyph from '../../components/Glyph'

import Divider from '../../components/Divider'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

import { COLORS } from '../../theme'
import { get, create } from '../../modules/api/requests'
import { nicePhoneNo, usDateTime } from '../../utils/functions'

import Notifications from '../../modules/notifications'

export const MagicLoginOverlay = (props: any) => {
  const history: any = useHistory()
  const location: any = useLocation()

  const { resource_id, resource }: any = useParams()

  const [isSuccessful, setIsSuccessful] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isGenerating, setIsGenerating] = React.useState(false)
  const [errors, setErrors]: any = React.useState(null)
  const [user, setUser]: any = React.useState(null)
  const [notes, setNotes] = React.useState(null)

  const close = () => {
    if (location?.parent) {
      if (location.parent.url) history.push(location.parent.url)
      else history.push(location.parent)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  React.useEffect(() => {
    const request = async () => {
      setIsLoading(true)

      try {
        if (resource === 'admissions' || resource === 'clients' || resource === 'alumni') {
          const result = await get(`/residents/${resource_id}/user`)
          setUser(result?.data)
        } else if (resource === 'employees') {
          const result = await get(`/employees/${resource_id}/user`)
          setUser(result?.data)
        }
      } catch (errors) {
        console.error(errors)
      } finally {
        setIsLoading(false)
      }
    }

    request()
  }, [])

  const sendMagicLink = async () => {
    setIsGenerating(true)

    try {
      await create(`/users/${user.id}/send_magic_link`, { notes })

      Notifications.send('Magic Login Sent Successfully', 'positive')
      setIsSuccessful(true)
    } catch (errors) {
      console.error(errors)
      setErrors(errors)
    } finally {
      setIsGenerating(false)
    }
  }

  if (isLoading) {
    return <OverlayLoader position="center" onClose={close} maxWidth={35} />
  }

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={close} maxWidth={35}>
      <Overlay.Header title="🔮 Send Magic Login" />

      <Overlay.Content>
        <Section>
          {isSuccessful ? (
            <>
              <Grid gap="0.5rem" justifyItems="center" alignItems="flex-start" className="p-4">
                <Glyph glyph="tick_circle" size={28} />
                <div>
                  <p>
                    We sent a Magic Login to <strong>{compact([user?.email, user?.phone_no]).join(' and ')}</strong>.
                  </p>
                  <p>Please ask them to check their email or SMS messages to access their account.</p>
                </div>
              </Grid>
            </>
          ) : (
            <FormSection>
              {user?.last_magic_link && (
                <>
                  <Alert glyph="info" type="warning">
                    The last Magic Link this user received was sent to{' '}
                    <strong>{compact([user.last_magic_link?.email, user.last_magic_link?.phone_no]).join(' and ')}</strong> on{' '}
                    <strong>{usDateTime(user.last_magic_link.created_at)}</strong>.
                  </Alert>

                  <Divider className="!m-0" />
                </>
              )}

              <div>You are about to send a new Magic Login to the following contact details:</div>
              {user?.email && <div css={STYLES.monoTypeBlock}>{user?.email}</div>}
              {user?.phone_no && <div css={STYLES.monoTypeBlock}>{nicePhoneNo(user?.phone_no)}</div>}

              <Alert glyph="info" type="default">
                This is designed to provide <u>secure access</u> and allow convenient login without the need for passwords. It's important
                to note that we are recording the use of this Magic Link for security purposes.
              </Alert>

              <Alert glyph="warning" type="warning">
                Please instruct the user to check their email or SMS messages for the Magic Link. If they do not receive it, please ask to
                check their spam folder.
              </Alert>

              <Textarea
                label="What is the reason for sending this Magic Login link?"
                model="reason"
                onChange={({ value }: any) => setNotes(value)}
              />
            </FormSection>
          )}
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        {isSuccessful ? (
          <Button label="Close" onClick={close} />
        ) : (
          <Button label="Send Magic Link" type="primary" color="green" onClick={sendMagicLink} isLoading={isGenerating} />
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const STYLES = {
  monoTypeBlock: {
    display: 'inline-flex',
    fontSize: '1.05rem',
    fontFamily: 'monospace',
    fontWeight: 400,
    borderRadius: 5,
    background: COLORS.hover,
    border: `1px solid ${COLORS.divider}`,
    padding: '0.5rem 1rem',
    color: COLORS.text,
    letterSpacing: 2,

    '@media(min-width: 600px)': {
      fontSize: '1.2rem',
    },
  },
}
