import React from 'react'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Page, Tabs, TabList, Tab, Status, Iframe, Button, HelpTagIframe } from '@behavehealth/components'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useCopyToClipboard from '@behavehealth/hooks/useCopyToClipboard'

import ActiveSubscriptions from './pages/active_subscriptions'
import Discounts from './pages/discounts'
import InvoicesPayments from './pages/invoices_payments'
import PlansAddons from './pages/plans_addons'
import ProductsManager from './pages/products_manager'

import { AddonsImportOverlay } from '@behavehealth/constructs/InternalBilling/AddonsImportOverlay'
import { BillingCreditOverlayV6 } from '@behavehealth/components/Billing/BillingCreditOverlay'
import { EHRInvoiceOverlay } from '@behavehealth/constructs/InternalBilling/EHRInvoiceOverlay'
import { PlansImportOverlay } from '@behavehealth/constructs/InternalBilling/PlansImportOverlay'
import { ProductFeaturesOverlayV6 } from '@behavehealth/components/Billing/ProductFeaturesOverlay'
import { ProductOverlay } from '@behavehealth/constructs/InternalBilling/ProductOverlay'
import { QuoteOverlay } from '@behavehealth/constructs/Quotes/QuoteOverlay'
import { QuoteReviewOverlay } from '@behavehealth/constructs/QuoteReview/QuoteReviewOverlay'
import { ServiceOverlay } from '@behavehealth/constructs/InternalBilling/ServiceOverlay'
import { ServicesImportOverlay } from '@behavehealth/constructs/InternalBilling/ServicesImportOverlay'
import { TierOverlayV6 } from '@behavehealth/components/Billing/TierOverlay'
import DiscountsImportOverlay from '@behavehealth/components/Overlays/pages/Subscriptions/DiscountsImportOverlay'
import { DiscountOverlay } from '@behavehealth/constructs/InternalBilling/DiscountOverlay'

import { PlansBilling } from './pages/plans_billing'
import { SubscriptionsManager } from './pages/subscriptions_manager'

const ACCOUNT_TYPES = {
  healthcare_facility: 'Healthcare Facility',
  healthcare_practice: 'Healthcare Practice',
  support_facility: 'Support Facility',
  support_practice: 'Support Practice',
  provider_affiliate: 'Provider Partner',
}

const Billing = () => {
  const { url } = useRouteURL()

  const tenant = useSelector((state) => state.me.tenant)
  const user = useSelector((state: any) => state.me.user)

  const isBehave = user?.type === 'bh_employee'
  const subscriptions = useSelector((state) => state.me.subscriptions)
  const hasActivePlan = subscriptions?.filter?.((sub) => sub?.status === 'active' && sub?.product?.category === 'platform_plan').length > 0

  const { copy, didCopy } = useCopyToClipboard({
    text: `${process.env.BH_APP_PROPOSAL_URL}/${tenant.external_id}`,
    notification: 'URL copied!',
  })

  const { copy: shortCopy, didCopy: didShortCopy } = useCopyToClipboard({
    text: `${process.env.BH_APP_SHORT_PROPOSAL_URL}/${tenant.external_id}`,
    notification: 'URL copied!',
  })

  return (
    <Page
      icon="certificate"
      title="Billing"
      titleAside={<Status label={ACCOUNT_TYPES[tenant?.category]} color="blue" />}
      help={<HelpTagIframe id="settings_billing" />}
    >
      <Tabs>
        <TabList className="mb-4 -mt-4">
          <Tab as={NavLink} label="Quotes & Subscriptions" to={`active-subscriptions`} />
          <Tab as={NavLink} label="Invoices & Payments" to={`invoices-payments`} />
          {isBehave && <Tab as={NavLink} label="Subscriptions Manager" glyph="behave_health" to={`subscriptions-manager`} />}

          {/* <Tab as={NavLink} label="Products" to={`products`} /> */}
          {/* <Tab as={NavLink} label="Plans & Billing" to={`plans-billing`} /> */}

          {/* {isBehave && (
            <>
              <Tab as={NavLink} glyph="behave_health" label="Products Manager" to={`manager`} />
              <Tab as={NavLink} glyph="behave_health" label="Discounts" to={`discounts`} />
            </>
          )} */}
        </TabList>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to={'active-subscriptions'} replace />} />

        <Route path="active-subscriptions/*" element={<ActiveSubscriptions />} />
        <Route path="products/*" element={<PlansAddons />} />
        <Route path="invoices-payments/*" element={<InvoicesPayments />} />

        <Route
          path="/plans-billing/*"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <PlansBilling />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/manager/*"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <ProductsManager />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/discounts/*"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <Discounts />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="/subscriptions-manager/*"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <SubscriptionsManager />
            </ProtectedRouteV6>
          }
        />
      </Routes>

      <AnimatedRoutes>
        <Route path="discounts/credits/:id" element={<BillingCreditOverlayV6 useV6Router />} />
        <Route path="discounts/discounts/:id" element={<DiscountOverlay useV6Router />} />
        <Route path="discounts/import-discounts" element={<DiscountsImportOverlay useV6Router />} />

        <Route path="manager/base-plans/:id" element={<ProductOverlay useV6Router />} />
        <Route path="manager/import-base-plans" element={<PlansImportOverlay useV6Router />} />

        <Route path="manager/plans-addons-services/:id" element={<ProductOverlay useV6Router />} />
        <Route path="manager/import-plans-addons-services" element={<AddonsImportOverlay useV6Router />} />

        <Route path="manager/edit-plans" element={<ProductFeaturesOverlayV6 useV6Router />} />
        <Route path="manager/import-services" element={<ServicesImportOverlay useV6Router />} />
        <Route path="manager/services/:id" element={<ServiceOverlay useV6Router />} />
        <Route path="manager/tiers/:id" element={<TierOverlayV6 useV6Router />} />

        <Route path="invoices-payments/invoices/:id" element={<EHRInvoiceOverlay useV6Router />} />
        <Route path="plans-billing/invoices/:id" element={<EHRInvoiceOverlay useV6Router />} />
        <Route path="plans-billing/import-quote-template/:quote_template_id" element={<QuoteOverlay useV6Router back={url} />} />
        <Route path="plans-billing/internal-quotes/:id" element={<QuoteOverlay useV6Router back={url} />} />
        <Route path="plans-billing/quotes/:id" element={<QuoteReviewOverlay useV6Router back={url} />} />
      </AnimatedRoutes>
    </Page>
  )
}

export default withPageError(Billing)
