import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { ApplicationsReportDataTable } from '@behavehealth/constructs/LiveReports/ApplicationsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Applications = () => {
  const tableProps = useDataTable({
    name: ['reports', 'applications'],
    endpoint: `/live_reports?category=applications`,
    localStorageKey: 'report_applications_v1',
  })

  const to = React.useMemo(
    () => (rowData: any) => {
      const clientLink = getClientLink(rowData.reference)

      if (clientLink) return `${clientLink}/applications/${rowData.id}`
    },
    [],
  )

  return (
    <Page feature="applications" title="Live Applications Report">
      <Grid>
        <ApplicationsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Applications)
