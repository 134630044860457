import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { IntakeApplicationsDataTable } from '@behavehealth/constructs/IntakeApplications/IntakeApplicationsDataTable'
import { Status, Button, Page, Grid, HelpTagIframe, Dropdown, DropdownItem, Divider } from '@behavehealth/components'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'

const pageConfig = {
  feature: 'custom_application_form',
  help: <HelpTagIframe id="client_applications" />,
  marketingID: 'client_applications',
}

const Applications = () => {
  const match = useRouteMatch()

  const tableProps = useDataTable({
    name: ['clients', match.params?.resource_id, 'intake_applications'],
    endpoint: `/residents/${match.params?.resource_id}/intake_applications`,
    localStorageKey: 'client_intake_applications_v2',
  })

  const { data: client }: any = useGet({
    name: ['client', match.params.resource_id],
    url: `/residents/${match.params.resource_id}`,
  })

  const { data: applicationForms, isLoading: isLoadingApplicationForms } = useGet({
    name: 'application_forms',
    url: `/application_forms`,
  })

  const { defaultForm, otherForms } = React.useMemo(() => {
    let defaultForm = null
    const otherForms: any = []

    if (!applicationForms) return { defaultForm, otherForms }

    for (const applicationForm of applicationForms) {
      if (applicationForm.is_default) {
        defaultForm = applicationForm
      } else {
        otherForms.push(applicationForm)
      }
    }

    return { defaultForm, otherForms }
  }, [applicationForms])

  const initialData = React.useMemo(() => {
    if (!client) return null

    return {
      personal_details: {
        first_name: client.first_name,
        middle_name: client.middle_name,
        last_name: client.last_name,
        dob: client.dob,
      },
      demographics: {
        sex: client.sex,
        gender_identity: client.gender_identity,
        ethnicity: client.ethnicity,
      },
      contact: {
        primary_contact: {
          phone_no: client.phone_no,
          has_phone_no: !!client.phone_no,
          landline_no: client.landline_no,
          has_landline_no: !!client.landline_no,
          email: client.email,
          has_email: !!client.email,
        },
      },
    }
  }, [client])

  const hasOtherForms = size(otherForms) > 0

  const actions = !isLoadingApplicationForms && (
    <>
      <Button
        label="Manage Application Forms"
        glyph="settings"
        type="minimal"
        link="/settings/application-forms"
        featureFlagV2="custom_application_form"
      />

      {defaultForm && hasOtherForms ? (
        <Dropdown label="Add Application" permission="applications.create" buttonType="primary" glyph="add">
          <DropdownItem
            icon="applications"
            label={
              <span>
                <span className="block mb-1">{defaultForm.name}</span>
                <Status small color="blue" label="Default" />
              </span>
            }
            link={{
              pathname: `${match.url}/new`,
              parent: match,
              data: { application_form: defaultForm, data: initialData },
            }}
          />

          <>
            <Divider />
            <div className="px-4 pt-3 pb-1 text-[0.84rem] tracking-[1px] uppercase text-text-muted font-[700] opacity-[0.85]">
              Other Application Forms
            </div>
            {otherForms?.map?.((o) => (
              <DropdownItem
                key={o.id}
                icon="applications"
                label={o.name}
                link={{
                  pathname: `${match.url}/new`,
                  parent: match,
                  data: { application_form: o, data: initialData },
                }}
              />
            ))}
          </>
        </Dropdown>
      ) : (
        <Button
          label="Add Application"
          type="primary"
          glyph="add"
          link={{
            pathname: `${match.url}/new`,
            parent: match,
            data: { application_form: defaultForm, data: initialData },
          }}
          permission="applications.create"
        />
      )}
    </>
  )

  return (
    <Page actions={actions} {...pageConfig}>
      <Grid gap="1rem">
        <IntakeApplicationsDataTable
          {...tableProps}
          to={(record: any) => ({
            pathname: `${match.url}/${record?.id}`,
            parent: match,
          })}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Applications, pageConfig))
