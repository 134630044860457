import React from 'react'

import { LOC } from '../../utils/constants'
import { usDate } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Table from '../../components/Table/Table'
import TableCell from '../../components/Table/TableCell'

const columns = (timezone: string) => [
  {
    width: 160,
    accessor: 'level',
    Header: 'Level',
    Cell: ({ value }: any) => <TableCell value={LOC[value]} />,
  },
  {
    width: 160,
    accessor: 'started_at',
    Header: 'Authorized From',
    Cell: ({ value }: any) => usDate(value, timezone),
  },
  {
    width: 160,
    accessor: 'ended_at',
    Header: 'Authorized Until',
    Cell: ({ value }: any) => usDate(value, timezone),
  },
  {
    width: 100,
    accessor: 'sessions',
    Header: 'Sessions',
  },
  {
    width: 340,
    accessor: 'notes',
    Header: 'Notes',
  },
]

export const TreatmentEpisodeAuthorizationsTable = (props: any) => {
  const {
    data,
    emptyDescription = 'No authorizations have been added for this treatment episode yet',
    icon = 'authorizations',
    localStorageKey = 'treatment_episode_authorizations',
    title = 'Authorizations',
    to,
    ...rest
  } = props

  const { timezone } = useSettings()

  return (
    <Table
      columns={columns(timezone)}
      data={data}
      emptyDescription={emptyDescription}
      icon={icon}
      localStorageKey={localStorageKey}
      title={title}
      {...rest}
    />
  )
}
