import React from 'react'

import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootNursingProgressNoteOverlay = () => (
  <DataFormOverlay
    title="Progress Note"
    category="nursing"
    subcategory="progress_note"
    requestClientSignature={false}
    requestAuthorSignature={true}
    requireSupervisor={true}
    enableTreatmentPlanSelector
    icon="nursing"
  >
    <Section
      title="Progress Note"
      scrollview={{
        id: 'progress_note',
        name: 'Progress Note',
      }}
      commentsModel="data.progress_note"
    >
      <SmartTextarea useDictation label="Note Description" model="data.notes" />
    </Section>
  </DataFormOverlay>
)

export const NursingProgressNoteOverlay = withOverlayError(RootNursingProgressNoteOverlay)
