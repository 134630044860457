import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { Page, Grid } from '@behavehealth/components'

import { InsuranceEvobsReportDataTable } from '@behavehealth/constructs/LiveReports/InsuranceEvobsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const InsuranceEvobs = () => {
  const tableProps = useDataTable({
    name: ['reports', 'insurance-evobs'],
    endpoint: `/live_reports?category=insurance_evobs`,
    localStorageKey: 'report_insurance_evobs_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getClientLink(rowData.resident)}/insurance/evob-summaries/${rowData.id}`, [])

  return (
    <Page feature="insurance_evobs" title="Live Insurance EVOB Report">
      <Grid>
        <InsuranceEvobsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(InsuranceEvobs)
