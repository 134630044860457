export const FILTERS_KEY = 'bh.filters'

export const setLocalStorageFilters = (localStorageKey: string, filters: any) => {
  if (!localStorageKey || !filters) return {}

  // set main key if it doesn't exist
  if (!localStorage.getItem(FILTERS_KEY)) {
    localStorage.setItem(FILTERS_KEY, JSON.stringify({}))
  }

  // update filters
  const current = JSON.parse(localStorage.getItem(FILTERS_KEY))
  current[localStorageKey] = filters

  localStorage.setItem(FILTERS_KEY, JSON.stringify(current))
}

export const getLocalStorageFilters = (localStorageKey?: string) => {
  if (!localStorageKey || !localStorage.getItem(FILTERS_KEY)) return {}

  const current = JSON.parse(localStorage.getItem(FILTERS_KEY))

  return current[localStorageKey]
}
