import React from 'react'
import { useHistory } from 'react-router-dom'
import { useRegisterSW } from 'virtual:pwa-register/react'

import Version from '../modules/version'

const INTERVAL = 5 * 60 * 1000 // every 5 minutes

const VersionUpdater = () => {
  const history = useHistory()

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    // check Service Worker at different intervals
    onRegisteredSW: (swUrl: any, r: any) => {
      if (!r) return

      setInterval(() => {
        r.update()
      }, INTERVAL)
    },
  })

  React.useEffect(() => {
    Version.checkForUpdate(process.env.BH_VERSION_NAME)
    Version.start(process.env.BH_VERSION_NAME)

    const unlisten = history.listen(() => {
      if (window.updateAvailable) {
        updateServiceWorker(true)
        window.location.reload()
      }
    })

    return () => {
      unlisten()
      Version.stop()
    }
  }, [])

  return null
}

export default VersionUpdater
