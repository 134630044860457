import React from 'react'

import Status from '../Status'
import ApplicationStatus from '../Statuses/ApplicationStatus'

import { usDate } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import CardLink from '../CardLink'
import CardSubtitle from '../CardSubtitle'
import CardTitle from '../CardTitle'

type Props = {
  to: any
  data: any
  timezone: string
}

const Applicant = ({ to, data, timezone }: Props) => (
  <CardLink to={to} testKey={data?.name} showChevron>
    <CardTitle
      title={data?.name}
      after={
        <>
          {data?.has_phone_no && <Status color="white" glyph="phone" />}
          {data?.has_landline_no && <Status color="white" glyph="landline" />}
          {data?.has_email && <Status color="white" glyph="email" />}
        </>
      }
    />
    <ApplicationStatus status={data?.status} />
    <CardSubtitle subtitle={`Added on ${usDate(data?.created_at, timezone)}`} />
  </CardLink>
)

export default withSettings(Applicant)
