import React from 'react'
import { createContext } from 'react'
import { useMedia } from 'use-media'

export const AppsUIContext = createContext({})

export const AppsUIContextProvider = ({ children }: any) => {
  const [isAppSidebarVisible, setIsAppSidebarVisible] = React.useState(false)

  const toggleAppSidebar = () => {
    setIsAppSidebarVisible((c) => !c)
  }

  const isMobile = useMedia({ minWidth: 0, maxWidth: 599 })
  const isTablet = useMedia({ minWidth: 600, maxWidth: 1023 })
  const isDesktop = useMedia({ minWidth: 1024 })

  return (
    <AppsUIContext.Provider
      value={{
        // media queries
        isMobile,
        isTablet,
        isDesktop,

        // app sidebar
        isAppSidebarVisible,
        setIsAppSidebarVisible,
        toggleAppSidebar,
      }}
    >
      {children}
    </AppsUIContext.Provider>
  )
}
