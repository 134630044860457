import React from 'react'

import RadioGroup from '../RadioGroup'
import Radio from '../Radio'

const ProgramTypeRadioGroup = ({ label, model }) => (
  <RadioGroup label={label} model={model} layout="vertical-dense">
    <Radio label="Inpatient" value="inpatient" />
    <Radio label="Outpatient" value="outpatient" />
    <Radio label="Sober Living" value="sober_living" />
  </RadioGroup>
)

ProgramTypeRadioGroup.defaultProps = {
  label: 'Program Type',
  model: 'program_type',
}

export default ProgramTypeRadioGroup
