import React from 'react'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'

import { Page, Grid, Tab, TabList, Tabs, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { TodoPageActionsV6 } from '../../../../components/TodoPageActionsV6'

import Active from './active'
import Completed from './completed'

const pageConfig = {
  feature: 'todos',
  help: <HelpTagIframe id="todos" />,
  marketingID: 'todos',
}

const Tasks = ({ canCreate = true }) => {
  return (
    <Page breakpoint="0" actions={canCreate && <TodoPageActionsV6 />} {...pageConfig}>
      <Grid gap={16}>
        <Tabs>
          <TabList css={styles.tabList}>
            <Tab as={NavLink} label="Active" to="active" />
            <Tab as={NavLink} label="Completed" to="completed" />
          </TabList>
        </Tabs>

        <Routes>
          <Route index element={<Navigate to="active" replace />} />

          <Route path="active/*" element={<Active canCreate={canCreate} />} />
          <Route path="completed/*" element={<Completed canCreate={canCreate} />} />
        </Routes>
      </Grid>
    </Page>
  )
}

const styles = {
  tabList: {
    marginTop: '-1rem',
  },
}

export default withPageError(withMarketing(Tasks, pageConfig))
