import React from 'react'
import clsx from 'clsx'

import BHMermaid from '../../../../components/Mermaid'
import State from '../../../../components/State'

import { Box } from './Box'

export const Mermaid = React.forwardRef((props: any, ref) => {
  const { isEditable, children, element, className, hoverElement, useParsedConfig } = props

  if (!element || !element.config) return null

  const {
    mermaid_value,
    aspect_ratio_high = 9,
    aspect_ratio_wide = 16,
    mermaid_height = 800,
    mermaid_height_type = 'pixels',
    mermaid_sizing_strategy = 'aspect_ratio',
    mermaid_width = 100,
    mermaid_width_type = 'percentage',
  } = element.config

  const rootClasses = clsx('MERMAID', !mermaid_value && 'is-empty', isEditable && 'is-editable', className)

  let aspectRatioWide = parseFloat(aspect_ratio_wide) || 3
  let aspectRatioHigh = parseFloat(aspect_ratio_high) || 1
  let mermaidWidth = getSizeValue(mermaid_width, mermaid_width_type)
  let mermaidHeight = getSizeValue(mermaid_height, mermaid_height_type)

  const aspectRatio = (aspectRatioHigh / aspectRatioWide) * 100

  return (
    <Box
      ref={ref}
      element={element}
      className={rootClasses}
      hoverElement={hoverElement}
      css={STYLES.root}
      style={{
        width: mermaidWidth,
      }}
    >
      {mermaid_value && (
        <BHMermaid
          css={STYLES.mermaidWrapper}
          style={{
            ...(mermaid_sizing_strategy === 'custom' && {
              height: mermaidHeight,
            }),

            ...(mermaid_sizing_strategy === 'aspect_ratio' && {
              paddingBottom: `${aspectRatio}%`,
            }),
          }}
          id={element.uuid}
          key={`${element.uuid}-${element?.config?.updated_at}`}
          value={mermaid_value}
        />
      )}

      {children}

      {isEditable && !mermaid_value && <State isEmpty glyph="mermaid" title="Mermaid" emptyDescription="Set a value using the editor" />}
    </Box>
  )
})

const SIZE_VALUE_TYPES = {
  percentage: '%',
  pixels: 'px',
  viewport_width: 'vw',
  viewport_height: 'vh',
}

const getSizeValue = (value: any, type: 'percentage' | 'pixels' | 'viewport_width' | 'viewport_height') => {
  const valueNumber = parseFloat(value) || 100
  const valueType = SIZE_VALUE_TYPES[type] || SIZE_VALUE_TYPES.pixels

  return `${valueNumber}${valueType}`
}

const STYLES = {
  root: {
    position: 'relative',
    margin: '0 auto',

    '&.is-empty.is-editable': {
      minHeight: 100,
      borderRadius: 4,
      position: 'relative',
    },
  },

  mermaidWrapper: {
    width: '100%',
    position: 'relative',
    margin: '0 auto',

    svg: {
      position: 'absolute',
      width: '100% !important',
      height: '100% !important',
    },

    '.MERMAID_INNER': {
      position: 'absolute',
      width: '100% !important',
      height: '100% !important',
      top: 0,
      left: 0,
    },
  },

  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}
