import React from 'react'

import { withFormContext } from '../../../components/Forms/context'

import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import Card from '../../../components/Card'
import CardHeader from '../../../components/CardHeader'
import CardTitle from '../../../components/CardTitle'
import CardContent from '../../../components/CardContent'
import ObjectSelector from '../../../components/Forms/Selectors/Object/ObjectSelector'
import SummonOverlay from '../../../components/SummonOverlay'
import Input from '../../../components/Forms/Input'

import ClaimBillTypeSelector from '../../../components/Forms/ClaimBillTypeSelector'
import ConditionCodesSelector from '../../../components/Forms/elements/ConditionCodesSelector'

import ClaimFrequencySelect from './ClaimFrequencySelect'
import ClaimFilingCodeSelect from './ClaimFilingCodeSelect'
import ClaimPlaceOfServiceSelect from './ClaimPlaceOfServiceSelect'
import ClaimTypeOfServiceSelect from './ClaimTypeOfServiceSelect'
import ClaimPatientStatusSelect from './ClaimPatientStatusSelect'

import { PreviewFeeScheduleOverlay } from './PreviewFeeScheduleOverlay'

const ClaimDetails = ({ isEditable, data, client, feeSchedule, onUpdate }: any) => {
	if (!data) return null
	const { category } = data

	return (
		<Card>
			<CardHeader>
				<CardTitle title="Claim Details" css={styles.cardTitle} />
			</CardHeader>

			<CardContent css={styles.cardContent}>
				<Grid gap="1rem" columns="1fr">
					<Flex gap="0.5rem" justifyContent="space-between" alignItems="flex-end" centerY={isEditable}>
						<ObjectSelector
							className="!flex-auto"
							label="Fee Schedule"
							model="insurance_fee_schedule"
							type="insurance.fee_schedules.all"
							selectTitle={(data: any) => data?.name}
							validations={{
								presence: {
									message: 'Please select a Fee Schedule'
								}
							}}
							onUpdate={(state: any) => {
								if (!onUpdate || !state.object?.id) return

								onUpdate(state.object)
							}}
							onClear={() => {
								if (!onUpdate) return

								onUpdate(null)
							}}
						/>

						{feeSchedule && (
							<SummonOverlay overlay={<PreviewFeeScheduleOverlay feeSchedule={feeSchedule} />}>
								<Button
									size={isEditable ? 300 : 200}
									glyph="view"
									label="Quickview"
									type="link"
									css={{ minHeight: isEditable ? 'var(--input-min-height)' : 'auto' }}
								/>
							</SummonOverlay>
						)}
					</Flex>

					<ClaimFilingCodeSelect model="claim_filing_code" />

					{category === 'institutional' && <ClaimBillTypeSelector model="bill_type" />}

					{category === 'professional' && (
						<>
							<ClaimFrequencySelect model="claim_frequency_code" />
							<ClaimPlaceOfServiceSelect model="place_of_service_code" />
						</>
					)}

					<ConditionCodesSelector model="condition_codes" />

					{category === 'institutional' && (
						<>
							<ObjectSelector
								label="Principal Diagnosis"
								icon="diagnosis"
								type="client.diagnoses"
								model="principal_diagnosis"
								dependentValue={client?.id}
								selectTitle={(data: any) => data.code}
								selectDescription={(data: any) => data.description}
								validations={{
									presence: {
										message: 'Please select a principal diagnosis'
									}
								}}
							/>

							<ClaimPatientStatusSelect model="patient_status_code" />
						</>
					)}

					<Input label="Insurance Claim Number" model="original_claim_no" />
				</Grid>
			</CardContent>
		</Card>
	)
}

const styles = {
	cardTitle: {
		fontSize: '1rem'
	},

	cardContent: {
		height: '100%'
	}
}

export default withFormContext(ClaimDetails)
