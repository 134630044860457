import React from 'react'

import { withFormContext } from '../../../../Forms/context'
import { niceAmount } from '../../../../../utils/functions'

import Alert from '../../../../Alert'
import Flex from '../../../../Flex'
import Grid from '../../../../Grid'
import Section from '../../../../Section'

import PercentageInput from '../../../../Forms/PercentageInput'
import SigneeStatus from '../../../../Statuses/SigneeStatus'

const PayerInput = ({ payer, percentage, amount }: any) => {
  const isDisabled = percentage === 0

  return (
    <div>
      <Flex gap={8} alignItems="center" justifyContent="space-between" nowrap>
        <div css={isDisabled && { opacity: 0.5 }}>
          <Flex gap={8} alignItems="center">
            <h4
              css={{
                fontSize: '1.05rem',
                marginBottom: '0.25rem',
                textDecoration: isDisabled && 'line-through',
              }}
            >
              {payer?.reference?.name}
            </h4>
            <SigneeStatus signee={payer?.reference} />
          </Flex>

          <p
            css={{
              margin: 0,
              fontSize: '1.05rem',
            }}
          >
            1 Charge of {niceAmount(amount)}
          </p>
        </div>

        <PercentageInput isEditable={false} debounce={500} defaultValue={parseFloat(percentage) || 0} />
      </Flex>
    </div>
  )
}

const ChargePayersSection = ({ subcharges }: any) => (
  <Section glyph="warning" title="Payers">
    <Grid gap="1rem">
      <Alert glyph="unavailable" type="negative">
        We replaced <strong>Payers</strong> with <strong>Payment Methods</strong>. Payers will not show up for new Transactions.
      </Alert>

      {subcharges?.map((payer: any) => (
        <PayerInput key={payer.id} isEditable={false} payer={payer.payer} percentage={payer.percentage} amount={payer.amount} />
      ))}
    </Grid>
  </Section>
)

export default withFormContext(ChargePayersSection)
