import React from 'react'
import { useParams } from 'react-router-dom'

import { request, buildBaseUrl } from '@behavehealth/modules/axios'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import Glyph from '@behavehealth/components/Glyph'
import Page from '@behavehealth/components/Page'
import State from '@behavehealth/components/State'

import { EVOBApplicationForm } from '@behavehealth/constructs/EVOB/EVOBApplicationForm'

const STEPS = {
  form: 'form',
  thank_you: 'thank_you',
}

export const EVOBApplicationFormPage = () => {
  const form = React.useRef(null)

  const [step, setStep] = React.useState(STEPS.form)
  const [data, setData] = React.useState(null)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const params: any = useParams()
  const { resource_id: id } = params

  const { data: partnership, isLoading, isError, error }: any = useGet({
    name: ['organization', id],
    url: `/organizations/${id}`,
  })

  // const partnerTenantId = partnership?.other_partner?.id
  const partnerSubdomain = partnership?.other_partner?.subdomain

  const handleSubmit = async () => {
    setIsSubmitting(true)

    // add subdomain
    buildBaseUrl()

    try {
      await request.post(
        '/intake_applications/submit',
        {
          status: 'submitted',
          source: 'form',
          data: data,
        },
        {
          headers: {
            'X-Tenant': partnerSubdomain,
          },
        },
      )

      setStep(STEPS.thank_you)
    } catch (errors) {
      console.error(errors)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleReset = () => {
    setStep(STEPS.form)
    setData(null)
  }

  if (!partnership || isLoading) return <PageLoader />

  const partner = partnership.other_partner
  const isShared = partnership.other_partner_settings?.evob_application_form === true

  if (!isShared) {
    return (
      <Page icon="insurance" title="EVOB Application Form">
        <Card>
          <State
            isEmpty
            title="EVOB Application Form"
            icon="insurance"
            emptyDescription={`${partner?.name || '–'} has not shared their EVOB Application Form.`}
          />
        </Card>
      </Page>
    )
  }

  return (
    <Page icon="insurance" title="EVOB Application Form">
      <div className="w-full max-w-[640px] mx-auto">
        {step === STEPS.form && <EVOBApplicationForm form={form} onUpdate={setData} />}

        {step === STEPS.thank_you && (
          <div className="p-4 w-full max-w-[400px] mt-12 mx-auto text-center">
            <Glyph glyph="tick_circle" size={24} className="mx-auto mb-3" />
            <div>Your form has been submitted</div>

            <div className="flex justify-center mt-4">
              <Button label="Complete another form" onClick={handleReset} size={300} display="inline-flex" type="default" glyph="reset" />
            </div>
          </div>
        )}

        {step === STEPS.form && <Button label="Submit" color="green" type="primary" onClick={handleSubmit} isLoading={isSubmitting} />}
      </div>
    </Page>
  )
}
