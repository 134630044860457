import React from 'react'

import Flex from './Flex'

const ButtonGroup = ({ children, stretchSelf, className }: any) => {
  return (
    <Flex nowrap css={STYLES} stretchSelf={stretchSelf} className={className}>
      {children}
    </Flex>
  )
}

const STYLES = {
  '& > *': {
    marginLeft: -1,
    borderRadius: '0 !important',

    '&:first-child': {
      borderRadius: '5px 0 0 5px !important',
    },

    '&:last-child': {
      borderRadius: '0 5px 5px 0 !important',
    },
  },

  '&.stretch-self > *': {
    flex: '1 1 auto',
  },
}

export default ButtonGroup
