import React from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isBoolean from 'lodash/isBoolean'
import isString from 'lodash/isString'

const withHasPermissions = (Wrapped) => (props) => {
  const permissions = useSelector((state) => state.me?.permissions)
  const featureFlags = useSelector((state) => state.me?.tenant?.feature_flags)

  const [featureFlag, setFeatureFlag] = React.useState(false)
  const [permission, setPermission] = React.useState(false)

  React.useEffect(() => {
    let ff = true
    let ps = true

    // process feature flag
    if (isBoolean(props.featureFlag)) {
      ff = props.featureFlag
    } else if (props.featureFlag) {
      ff = get(featureFlags, props.featureFlag)
    }

    // process permission
    if (isBoolean(props.permission)) {
      ps = props.permission
    } else if (props.permission) {
      if (props.permission === 'test_results') {
        let uat_view = get(permissions, 'test_results.ua_tests.view')
        let bt_view = get(permissions, 'test_results.breathalyzer_tests.view')

        if (includes(['all', 'assigned'], uat_view)) uat_view = true
        if (includes(['all', 'assigned'], bt_view)) bt_view = true

        ps = uat_view || bt_view
      } else {
        ps = get(permissions, props.permission)
        if (isString(ps)) {
          if (includes(['all', 'assigned'], ps)) ps = true
          else if (ps === 'none') ps = false
        }
      }
    }

    setFeatureFlag(ff)
    setPermission(ps)
  }, [props.featureFlag, props.permission, featureFlags, permissions, setFeatureFlag, setPermission])

  return <Wrapped {...props} hasPermission={permission} hasFeatureFlag={featureFlag} />
}

export default withHasPermissions
