import React from 'react'

export const AppSidebarContent: React.FC<any> = ({ children, className }) => {
  return (
    <>
      <main css={styles.root} className={className}>
        {children}
      </main>
    </>
  )
}

const styles = {
  root: {
    padding: '1rem',
  },
}
