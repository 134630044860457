import React from 'react'
import { useRouteMatch, useHistory, useParams } from 'react-router-dom'
import { geolocated } from 'react-geolocated'

import { address, isDefined, isWebGLAvailable } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import Notifications from '../../modules/notifications'

import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Map from '../../components/Map'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'

const CATEGORIES = {
  property: 'Location',
  location: 'Location',
  organization: 'Organization',
  meeting: 'Meeting',
  other: 'Location',
}

export const RootGeolocatedLocationCheckInOverlay = (props: any) => {
  const match = useRouteMatch()
  const history = useHistory()

  const { resource_id }: any = useParams()
  const hasCanvasSupport = isWebGLAvailable()

  const {
    cancel,
    data,
    edit,
    form,
    initialData,
    initialModel,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    updateAsync,
    close,
  } = useOverlay({
    name: 'location-check',
    endpoint: '/location_checks',
    invalidate: 'location-checks',
    invalidateKeys: [['client', resource_id, 'location-tracking']],
    options: props,
    skipClose: true,
    closeOnSave: false,
    onSaveSuccessful: (apiData: any) => {
      if (!apiData) return

      if (isNew) {
        const newURL = match.url.replace(`/new`, `/${apiData.id}`)
        history.replace(newURL)
      }
    },
  })

  const { timezone, tenant, isPortal } = useSettings()
  const { isGeolocationAvailable, isGeolocationEnabled, coords } = props

  const lat = isNew ? coords?.latitude : data?.lat
  const lon = isNew ? coords?.longitude : data?.lon
  const altitude = isNew ? coords?.altitude : data?.altitude
  const accuracy = isNew ? coords?.accuracy : data?.accuracy

  const hasPlaceCoordinates = isDefined(data?.place_lat) && isDefined(data?.place_lon)
  const hasDeviceCoordinates = isDefined(data?.lat) && isDefined(data?.lon)

  const checkout = async () => {
    await updateAsync({
      ...form.current.getFormValue(),
      status: 'out',
    })

    // if (form.current) form.current.submitted()
  }

  if (isOverlayLoading) return <OverlayLoader position="right" />

  return (
    <Overlay onClose={close}>
      <Overlay.Header title="Location Check-In" icon="recovery_navigation" />

      <Overlay.Content>
        {!isGeolocationAvailable && (
          <Section>
            <Alert type="warning" glyph="info">
              The device you are currently using does not have support for Location Checks. Please use a different device or get in touch
              with {tenant.name} at <a href={`mailto:${tenant.email}`}>{tenant.email}</a> or{' '}
              <a href={`tel:${tenant.phone_no}`}>{tenant.phone_no}</a>
            </Alert>
          </Section>
        )}

        {isGeolocationAvailable && (
          <>
            {isNew && (
              <>
                {isGeolocationEnabled && hasCanvasSupport && <Map css={mapStyles} lat={lat} lon={lon} data={[{ lat: lat, lon: lon }]} />}

                {!isGeolocationEnabled && (
                  <>
                    {hasCanvasSupport && <Map css={mapStyles} lat={null} lon={null} />}
                    <Alert type="warning" glyph="info">
                      The Map is not available because your Geolocation is disabled. Please activate your device's Geolocation and then try
                      again.
                    </Alert>
                  </>
                )}
              </>
            )}

            {!isNew && (
              <>
                {hasCanvasSupport && (
                  <Map
                    css={mapStyles}
                    lat={hasPlaceCoordinates ? data.place_lat : hasDeviceCoordinates ? data.lat : null}
                    lon={hasPlaceCoordinates ? data.place_lon : hasDeviceCoordinates ? data.lon : null}
                    markers={{
                      ...(hasPlaceCoordinates && {
                        locations: {
                          visible: true,
                          data: [{ id: 'place', lat: data.place_lat, lon: data.place_lon }],
                        },
                      }),
                      ...(hasDeviceCoordinates && {
                        devices: {
                          visible: true,
                          data: [{ id: 'device', lat: data.lat, lon: data.lon }],
                        },
                      }),
                    }}
                    arcs={
                      hasPlaceCoordinates &&
                      hasDeviceCoordinates && [
                        {
                          origin: {
                            id: 'a',
                            lat: data.place_lat,
                            lon: data.place_lon,
                          },
                          destination: {
                            id: 'b',
                            lat: data.lat,
                            lon: data.lon,
                          },
                        },
                      ]
                    }
                  />
                )}

                {!hasPlaceCoordinates && (
                  <Alert type="warning" glyph="info">
                    {!hasPlaceCoordinates && !hasDeviceCoordinates
                      ? `The location of the Device and ${
                          CATEGORIES[initialModel.category]
                        } was not available when creating the Location Check-In.`
                      : !hasPlaceCoordinates
                      ? `The ${
                          CATEGORIES[initialModel.category]
                        }'s address was not found when creating the Check-In. Please check & update it from the Profile page.`
                      : !hasDeviceCoordinates
                      ? `The Device's location was not available when creating the Location Check-In.`
                      : ''}
                  </Alert>
                )}
              </>
            )}

            <Form
              getForm={form}
              initialModel={initialModel}
              isEditable={isEditable}
              timezone={timezone}
              onValidationUpdate={onValidationUpdate}
              decorate={() => ({
                resident_id: initialData?.resident_id,
                lat: lat,
                lon: lon,
                altitude: altitude,
                accuracy: accuracy,
              })}
            >
              <Section>
                <FormSection>
                  {!isNew && (
                    <Input
                      defaultToNow
                      isEditable={false}
                      label="Device Distance from Location"
                      suffix="miles"
                      model="direct_miles_between_coords"
                      tooltip={
                        <>
                          Distance between the{' '}
                          <span className="!font-[600]">
                            <Icon icon="mobile" size={20} /> Device's
                          </span>{' '}
                          location and the{' '}
                          <span className="!font-[600]">
                            <Icon icon="gps_check_in_out" size={20} /> Location
                          </span>{' '}
                          of the check-in.
                        </>
                      }
                    />
                  )}

                  <Grid gap="1rem">
                    <DateTimeInput defaultToNow isEditable={!isPortal && isEditable} label="Check-In Date" model="checkin_at" />

                    {data?.status === 'out' && (
                      <DateTimeInput label="Check-Out Date" model="checkout_at" isEditable={!isPortal && isEditable} />
                    )}
                  </Grid>

                  <Select
                    fullWidth
                    label="Category"
                    model="category"
                    defaultValue="property"
                    validations={{
                      presence: {
                        message: 'Please select a category',
                      },
                    }}
                  >
                    <Option label="Location" value="property" />
                    <Option label="Organization" value="organization" />
                    <Option label="Meeting" value="meeting" />
                    <Option label="Other" value="other" />
                  </Select>

                  <ContextShow when="category" is="property">
                    <ObjectSelector
                      isPolymorphic
                      model="location"
                      label="Location"
                      type="all_portal_shared_properties"
                      icon="properties"
                      selectTitle={(data) => data.name}
                      selectDescription={(data) => address(data.address)}
                      validations={{
                        presence: {
                          message: 'Please select a property',
                        },
                      }}
                    />
                  </ContextShow>

                  <ContextShow when="category" is="organization">
                    <ObjectSelector
                      isPolymorphic
                      model="location"
                      label="Organization"
                      type="all_portal_shared_organizations"
                      icon="organizations"
                      selectTitle={(data) => data.name}
                      selectDescription={(data) => address(data.address)}
                      validations={{
                        presence: {
                          message: 'Please select an organization',
                        },
                      }}
                    />
                  </ContextShow>

                  <ContextShow when="category" is="meeting">
                    <Input
                      model="name"
                      label="Meeting Name"
                      validations={{
                        presence: {
                          message: 'Please enter a meeting name',
                        },
                      }}
                    />

                    <AddressSelectorInput label="Address" model="address" actionLabel="Find Address" />
                  </ContextShow>

                  <ContextShow when="category" is="other">
                    <Input
                      model="name"
                      label="Location Description"
                      validations={{
                        presence: {
                          message: 'Please enter a location description',
                        },
                      }}
                    />

                    <AddressSelectorInput label="Address" model="address" actionLabel="Find Address" />
                  </ContextShow>

                  <Textarea label="Notes" model="notes" />
                </FormSection>
              </Section>
            </Form>
          </>
        )}
      </Overlay.Content>

      {isGeolocationAvailable && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                glyph="check"
                label="Save"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={async () => {
                  await save()

                  if (isNew) Notifications.send('Check-In Successful', 'positive')
                }}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission="location_checkin.create"
              />
              {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              {!data?.status && (
                <Button
                  label="Check-In ➝"
                  type="primary"
                  color="green"
                  isLoading={isSaving}
                  onClick={async () => {
                    await save()
                    Notifications.send('Check-In Successful', 'positive')
                  }}
                  isDisabled={isInvalid}
                  flex="100 1 auto"
                  glyph="check_in"
                />
              )}

              {data?.status === 'in' && (
                <Button
                  label="Check-Out ➝"
                  type="primary"
                  color="orange"
                  isLoading={isSaving}
                  onClick={async () => {
                    await checkout()
                    Notifications.send('Check-Out Successful', 'positive')
                  }}
                  isDisabled={isInvalid}
                  flex="100 1 auto"
                  glyph="check_out"
                />
              )}

              <Button
                label="Edit"
                glyph="edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="100 1 auto"
                permission="location_checkin.edit"
              />
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const mapStyles = {
  display: 'grid',
  height: 250,
}

export const GeolocatedLocationCheckInOverlay = geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
})(withOverlayError(RootGeolocatedLocationCheckInOverlay))
