import React from 'react'
import { connect } from 'react-redux'

import { withOverlayError } from '../../../hocs/withOverlayError'

import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ContextHide from '../../Forms/ContextHide'
import DateInput from '../../Forms/DateInput'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../Overlay'
import PhoneInput from '../../Forms/PhoneInput'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'
import TimeInputSelector from '../../Forms/TimeInputSelector/TimeInputSelector'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from '../../Overlays/pages/OverlayBase'

import DocumentationMultiSelector from '../components/DocumentationMultiSelector'

class PreCertificationOverlay extends OverlayBase {
  renderHeader = () => <Overlay.Header icon="authorizations" title={this.state.$new ? 'Add Pre-certification' : 'Pre-certification'} />

  renderContent = () => {
    const { $editable, $new } = this.state
    const { reference, record, timezone } = this.props

    const isBehave = this.props?.user?.type === 'bh_employee'

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          initialModel={record}
          timezone={timezone}
          isEditable={$editable}
          onValidationUpdate={(valid) => this.onValidationUpdate(valid)}
          linked={
            $new && {
              admission_id: reference?.current_admission?.id,
              title: 'Pre-certification',
              category: 'pre_certification',
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              {!$new && <ObjectSelector isEditable={false} model="author" label="Author" type="employees" icon="employees" />}

              <Flex stretchChildrenX gap={16}>
                <CheckboxGroup label="Is Auth Required?" trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox label="Change to Yes / No" model="data.not_required" />
                </CheckboxGroup>

                <ContextHide when="not_required" is={true}>
                  <Input model="data.identifier" label="Authorization #" />
                </ContextHide>
              </Flex>

              <Flex stretchChildrenX gap={16}>
                <DateInput label="From" model="data.started_at" />
                <DateInput label="Until" model="data.ended_at" />
              </Flex>

              <MultiObjectSelector
                label="Insurance Services"
                model="data.insurance_services"
                type="insurance_local_payer.insurance_services"
              />

              <Input label="Pre-cert Number" model="data.pre_cert_no" />

              <Flex stretchChildrenX gap={16}>
                <TimeInputSelector label="UR Activity Start Time" model="data.ur_activity_started_at" />
                <TimeInputSelector label="UR Activity End Time" model="data.ur_activity_ended_at" />
              </Flex>

              <DocumentationMultiSelector model="data.documentation_required" />

              <Divider />

              <FormSection heading="Care Manager">
                <Input label="Care Manager Contact" model="data.care_manager_name" />

                <Flex gap={16}>
                  <PhoneInput label="Care Manager Phone Number" model="data.care_manager_phone_no" />
                  <Input glyph="phone_ext" label="Phone No Extension" size={10} model="data.care_manager_phone_ext" />
                </Flex>

                <PhoneInput label="Care Manager Fax Number" model="data.care_manager_fax_no" />
              </FormSection>
            </FormSection>

            <Divider />

            <FormSection heading="Notes">
              {isBehave && <Textarea label="Internal Notes" model="data.internal_notes" />}
              <Textarea label="Customer Notes" model="data.notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="authorizations.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Pre-certification"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="authorizations.edit"
            />

            <DeleteDialog
              title="Delete Pre-certification?"
              message="Are you sure you want to delete this Pre-certification? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                fullWidth
                permission="authorizations.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'insurance_authorizations')
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'insurance_authorizations')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(PreCertificationOverlay))
