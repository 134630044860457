import React from 'react'

import { amount, usDate, daysToWords } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

export const NaatpReportDataTable = (props: any) => {
  const { timezone, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        width: 260,
        title: 'Survey Title',
        model: 'title',
        disableHide: true,
      },
      {
        title: 'Survey ID',
        model: 'instrumentId',
        width: 100,
      },

      {
        title: 'Amount Uploaded',
        width: 100,
        model: 'qty_saved',
      },
    ]
  }, [])

  return <DataTable asCard title="NAATP Surveys" icon="outcome_measures" columns={columns} {...props} />
}
