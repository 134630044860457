import React from 'react'
import { tint } from 'polished'
import { useParams, useHistory } from 'react-router-dom'
import { useParams as useParamsV6, useNavigate } from 'react-router-dom-v5-compat'

import { COLORS, SHADOW, HARD_SHADOW } from '../../theme'
import { isDefined, isURL } from '../../utils/functions'
import { useGet, useCreate } from '../../hooks/useNewAPI'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Iframe from '../../components/Iframe'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import State from '../../components/State'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Tabs from '../../components/Tabs'
import Tooltip from '../../components/Tooltip'

import { CommunityOwnershipRequestOverlay } from './CommunityOwnershipRequestOverlay'

import { APP_URLS } from '../MyAccount/utils'
import { useCommunityPermissions } from './useCommunityPermissions'
import { useSettings } from '../../hooks/useSettings'

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'

import { OrganizationInviteRequestOverlay } from '@behavehealth/constructs/Community/OrganizationInviteRequestOverlay'
import { OrganizationDirectInviteOverlay } from '@behavehealth/constructs/Community/OrganizationDirectInviteOverlay'
import { EHROrganizationStatus } from '../Organizations/EHROrganizationStatus'

export const CommunityMapFeatureOverlay = (props: any) => {
  const { className } = props

  const params: any = props.useV6Router ? useParamsV6() : useParams()
  const navigate: any = props.useV6Router ? useNavigate() : useHistory().push

  const { isBehave } = useSettings()

  const slug = params?.slug || props?.slug

  const queryKey = ['community-entity', slug]

  const [tab, setTab] = React.useState('public_profile')

  const { data, isLoading, isError } = useGet({
    name: queryKey,
    url: `/community/entities/${slug}`,
    options: { enabled: isDefined(slug) },
  })

  const { mutateAsync: createOrganization, isLoading: isSaving }: any = useCreate({
    name: ['community-organizations'],
    url: `/organizations`,
    invalidate: 'partnership-invites',
    invalidateKeys: ['community-entity', 'organizations'],
  })

  const { canSaveCommunityOrganizations, canInviteToPartnerUp, canViewCommunityInvites } = useCommunityPermissions()

  const close = () => {
    if (props.type === 'summon' && props.onClose) return props.onClose()

    const url = location.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    navigate(url)
  }

  if (isLoading || !data) return <OverlayLoader position="right" />

  const hasWebsite = isURL(data?.url)
  const showWebsite = !hasWebsite ? false : isDefined(data?.view_settings?.website) ? data?.view_settings?.website : true
  const publicURL = `${APP_URLS.community}/profiles/${data.slug}?embed=true`

  const message = ''

  const saveToOrganization = () => {
    try {
      createOrganization({
        name: data?.name,
        category: 'provider',
        subcategory: 'community',
        email: data?.email,
        phone_no: data?.phone_no,
        selected_profile_2_id: data?.id,
        website_url: data?.url,
        facebook: data?.facebook_url,
        instagram: data?.instagram_url,
        google_business: data?.google_url,
        address: data?.primary_location?.address,
        lat: data?.primary_location?.lat,
        lon: data?.primary_location?.lon,
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Overlay onClose={close}>
      <Overlay.Header
        icon="community"
        title={data.name}
        subtitle={<EHROrganizationStatus status={data?.organization?.internal_status} />}
      />

      <div css={STYLES.root} className={className}>
        {isError ? (
          <State
            key="error-state"
            isEmpty
            glyph="circle_error"
            title="Community Profile"
            emptyDescription="There was a problem loading this community profile"
          />
        ) : isLoading || !data ? (
          <State key="loading-state" isLoading />
        ) : (
          <>
            <Tabs activeTab={tab} onChange={setTab} css={STYLES.tabs}>
              {showWebsite && (
                <Tabs.List
                  css={{ padding: '0 1rem', background: 'white', justifyContent: 'center', boxShadow: HARD_SHADOW(3), zIndex: 12 }}
                >
                  <Tabs.Item label="Community Profile" name="public_profile" />

                  <Tabs.Item label="Website" name="website" />
                </Tabs.List>
              )}

              <Tabs.Panel type="mount" name="public_profile" css={STYLES.tabsPanelFlex}>
                <div css={STYLES.websiteHeader}>
                  <Flex justifyContent="space-between" gap="0.5rem">
                    <Iframe title={data?.name} url={publicURL} overlayWidth={80}>
                      <Button label="Enlarge" glyph="expand" size={100} />
                    </Iframe>

                    <Button useGlyphForTarget label="Open in New Tab" target="_blank" type="link" size={100} href={publicURL} />
                  </Flex>
                </div>

                <div css={STYLES.claimHeader}>
                  <Flex justifyContent="space-between" gap="0.5rem">
                    <p className="opacity-80 pl-[2px]">Is this your profile?</p>

                    <SummonOverlay
                      overlay={<CommunityOwnershipRequestOverlay entityId={data?.id} entitySlug={data?.slug} entityName={data?.name} />}
                    >
                      <Button icon="star" label="Request Ownership Today" type="default" color="green" size={100} />
                    </SummonOverlay>
                  </Flex>
                </div>

                <iframe
                  title="iframe"
                  src={publicURL}
                  frameBorder="0"
                  border="0"
                  cellSpacing="0"
                  css={STYLES.websiteIframe}
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                />

                {isBehave && (
                  <div css={STYLES.claimHeader}>
                    <Flex centerX gap="1rem">
                      <p className="opacity-80 pl-[2px] !mt-[5px] !mr-4">Share with others:</p>

                      <Flex gap="1.5rem" className="!mt-[-8px]">
                        <WhatsappShareButton url={publicURL}>
                          <WhatsappIcon size={24} round={true} />
                        </WhatsappShareButton>

                        <FacebookShareButton url={publicURL} quote={message} hashtag="JoinTheCommunity">
                          <FacebookIcon size={24} round={true} />
                        </FacebookShareButton>

                        <LinkedinShareButton url={publicURL}>
                          <LinkedinIcon size={24} round={true} />
                        </LinkedinShareButton>

                        <TwitterShareButton url={publicURL} title="Shared Profile">
                          <TwitterIcon size={24} round={true} />
                        </TwitterShareButton>

                        <EmailShareButton url={publicURL} subject="Shared Profile" body={message}>
                          <EmailIcon size={24} round={true} />
                        </EmailShareButton>
                      </Flex>
                    </Flex>
                  </div>
                )}
              </Tabs.Panel>

              {showWebsite && tab === 'website' && <WebsitePanel data={data} />}
            </Tabs>
          </>
        )}
      </div>

      <div className="p-4">
        <div className="!grid !gap-2">
          {data?.organization?.internal_status === 'partner_invite_sent' && (
            <Alert glyph="info" className="mb-1">
              <div>You invited this community to partner up with you.</div>
            </Alert>
          )}

          <Flex stretchChildrenX horizontal gap="0.5rem">
            {!data?.organization && (
              <Button
                flex="1 1 auto"
                label="Save to Organizations"
                glyph="circle_add"
                color="orange"
                type="primary"
                size={300}
                permission={canSaveCommunityOrganizations}
                onClick={saveToOrganization}
                isLoading={isSaving}
              />
            )}

            {data?.organization && (
              <Button
                flex="1 1 auto"
                label={data?.organization?.internal_status === 'active_partnership' ? 'View Partner →' : 'View Organization →'}
                icon="organizations"
                type="default"
                color="blue"
                size={300}
                link={`/community/organizations/${data?.organization?.id}`}
              />
            )}

            {data?.organization?.internal_status === 'ready_to_partner' && (
              <Permission permission={canInviteToPartnerUp}>
                {data?.organization?.is_ready_for_direct_invite ? (
                  <SummonOverlay overlay={<OrganizationDirectInviteOverlay orgId={data?.organization?.id} />}>
                    <Button
                      className="!max-w-[190px]"
                      label="Invite to Partner Up Now…"
                      glyph="chat"
                      color="orange"
                      type="primary"
                      size={300}
                    />
                  </SummonOverlay>
                ) : (
                  <SummonOverlay overlay={<OrganizationInviteRequestOverlay orgId={data?.organization?.id} />}>
                    <Button label="Submit Partnership Request…" glyph="chat" color="orange" type="primary" size={300} />
                  </SummonOverlay>
                )}
              </Permission>
            )}

            {data?.organization?.internal_status === 'partner_invite_request' && (
              <Tooltip
                color="green"
                glyph="info"
                content="We are currently reviewing your request. We'll reach out to this Organization in your name and invite them to connect with you. We will let you know about the status of your request as soon as we hear back from them."
              >
                <Button label="Reviewing your Request" glyph="verified_shield" color="green" type="link" size={300} />
              </Tooltip>
            )}
          </Flex>

          {/* <Button
            label="Manage invites sent →"
            size={300}
            icon="community_invites"
            link={`/community/invites/sent`}
            permission={canViewCommunityInvites}
          /> */}
        </div>
      </div>
    </Overlay>
  )
}

const WebsitePanel = ({ data }: any) => {
  const url = data?.url
  const isUrlValid = isURL(url)

  const formattedUrl = !isUrlValid ? null : url?.includes('://') ? url : `https://${url}`

  const webRenderingFormat = data?.web_rendering_format
  const websiteScreenshot = data?.screenshot_variants?.['600']

  const shouldUseIframe = isUrlValid && webRenderingFormat !== 'screenshot'
  const shouldUseScreenshot = !!websiteScreenshot && webRenderingFormat === 'screenshot'

  const checkIframeQuery = useGet({
    name: ['community-entity', formattedUrl],
    url: `/apps/utils/check_iframe`,
    params: { url: formattedUrl },
    options: { enabled: shouldUseIframe },
  })

  const isIframeAllowed = checkIframeQuery.data?.iframe_allowed

  return (
    <Tabs.Panel type="mount" name="website" css={STYLES.tabsPanelFlex}>
      {shouldUseIframe && (
        <>
          {isIframeAllowed ? (
            <>
              <div css={STYLES.websiteHeader}>
                <Flex justifyContent="space-between" gap="0.5rem">
                  <Iframe title={data?.domain} url={formattedUrl} overlayWidth={80}>
                    <Button label="Enlarge" glyph="expand" size={100} />
                  </Iframe>

                  <Button useGlyphForTarget label="Open in New Tab" target="_blank" type="link" size={100} href={data?.url} />
                </Flex>
              </div>

              <iframe
                title="iframe"
                src={formattedUrl}
                frameBorder="0"
                border="0"
                cellSpacing="0"
                css={STYLES.websiteIframe}
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              />
            </>
          ) : checkIframeQuery.isLoading ? (
            <State isLoading />
          ) : (
            <State
              isEmpty
              icon="web_form"
              title="Website"
              emptyDescription="This website cannot be dynamically embedded. Click the button below to open it in a new tab."
              emptyActions={<Button useGlyphForTarget label="Open Website in New Tab" target="_blank" size={200} href={formattedUrl} />}
            />
          )}
        </>
      )}

      {shouldUseScreenshot && (
        <div className="p-4 grid grid-rows-[100%] grid-cols-[100%] overflow-hidden">
          <a
            className="grid grid-rows-[auto_1fr] rounded-md border border-solid border-divider shadow-hard-2 overflow-hidden"
            href={formattedUrl}
            target="_blank"
          >
            <div className="sticky top-0 px-2 py-2 text-center bg-white shadow-soft-2 relative z-[20]">
              <div className="flex flex-nowrap justify-center items-center">
                <Glyph glyph="website" size={16} className="mr-2" />
                <div className="font-[600]">Website Screenshot Preview</div>
              </div>
              <div className="text-[0.92rem] text-text-muted">Click on image to open website in a new tab</div>
            </div>

            <div className="overflow-auto">
              <img src={websiteScreenshot} alt="Website Screenshot" className="block w-full" />
            </div>
          </a>
        </div>
      )}
    </Tabs.Panel>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    background: '#F7F8FB',
  },

  tabs: {
    flex: '1 1 auto',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },

  tabsPanelScrollPadding: {
    flex: '1 1 auto',
    overflowY: 'auto',
    padding: '1rem',
  },

  tabsPanelScroll: {
    flex: '1 1 auto',
    overflowY: 'auto',
  },

  tabsPanelFlex: {
    display: 'flex !important',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flex: '1 1 auto',
    overflow: 'hidden',
  },

  header: {
    padding: '0.85rem 1rem',
    background: COLORS.white,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: SHADOW(3),
  },

  headerContent: {
    marginTop: '0.5rem',
  },

  headerActions: {
    marginTop: '0.75rem',
  },

  avatar: {
    marginTop: '0.35rem',
    marginRight: '0.5rem',
  },

  title: {
    fontSize: '1.35rem',
    fontWeight: 700,

    '& > *': { fontSize: '1.35rem' },
  },

  description: {
    fontSize: '0.95rem',
    color: COLORS.textMuted,
  },

  quote: {
    fontSize: '1rem',
    color: COLORS.textMuted,
    fontStyle: 'italic',
  },

  actionsFooter: {
    marginTop: '1rem',
  },

  content: {
    padding: '0.5rem 1rem',
  },

  verticalDivider: {
    width: 1,
    height: 24,
    background: COLORS.divider,
    flex: '0 0 auto !important',
  },

  claimFooter: {
    padding: '0.5rem 1.25rem',
    borderTop: `1px solid ${COLORS.divider}`,
    background: tint(0.9, COLORS.blue),
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    zIndex: 1,
    cursor: 'pointer',

    img: { marginRight: '0.75rem' },
    h3: { fontSize: '1.06rem', margin: 0 },
    p: { fontSize: '0.92rem', fontWeight: 500, color: COLORS.blue, margin: '0.1rem 0 0' },

    '&:hover': {
      background: COLORS.hover,
    },

    '.arrow': {
      marginLeft: 'auto',
      color: COLORS.blue,
    },
  },

  card: {
    '&.is-active': {
      background: tint(0.9, COLORS.vividBlue),
    },
  },

  cardTitle: {
    fontSize: '1rem',
  },

  cardSubtitle: {
    fontSize: '0.9rem',
  },

  accordionContent: {
    marginTop: '-0.5rem',
  },

  websiteHeader: {
    background: COLORS.white,
    padding: '0.5rem',
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(3),
    position: 'relative',
    zIndex: 1,
  },

  claimHeader: {
    background: COLORS.white,
    padding: '0.75rem 0.5rem',
    boxShadow: HARD_SHADOW(4),
    borderBottom: `1px solid ${COLORS.divider}`,
    position: 'relative',
    zIndex: 1,
  },

  websiteIframe: {
    flex: '1 1 auto',
    borderStyle: 'none',
  },
}
