import React from 'react'
import size from 'lodash/size'
import isEqual from 'react-fast-compare'

import { apiGet, apiUpdate } from '../../modules/api'

import Button from '../Button'
import Card from '../Card'
import Form from '../Forms/Form'
import Grid from '../Grid'
import Page from '../Page'
import State from '../State'
import HelpTagIframe from '../Help/HelpTagIframe'

import PermissionSection from './PermissionSection'
import PermissionSubRow from './PermissionSubRow'

class ClientsPermissionList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      $editable: false,
    }

    this.form = React.createRef()
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (!isEqual(nextProps.rules, this.props.rules)) return true
    if (nextProps.isLoading !== this.props.isLoading) return true
    if (nextState.$editable !== this.state.$editable) return true
    return false
  }

  /*
    CUSTOM FUNCTIONS
  */
  edit = () => {
    this.setState({ $editable: true })
  }
  cancel = () => {
    this.setState({ $editable: false })
  }

  updatePermissions = async () => {
    await apiUpdate({
      name: `permissions_resident`,
      url: `/permissions/resident`,
      params: {
        custom_rules: this.form.current?.getFormValue(),
      },
    })
    this.cancel()
    await apiGet({ url: '/me', reducer: 'me' })
  }

  reset = async () => {
    await apiUpdate({
      name: `permissions_resident`,
      url: `/permissions/resident`,
      params: {
        custom_rules: {},
      },
    })
    await apiGet({ url: '/me', reducer: 'me' })
  }

  /*
    RENDER
  */
  render() {
    const { $editable } = this.state
    const { rules, custom_rules, isLoading } = this.props

    const isCustom = size(custom_rules) > 0
    const customSections = custom_rules && Object.keys(custom_rules)

    return (
      <Page
        title="Client Portal Permissions"
        feature="permissions"
        breakpoint={4}
        help={<HelpTagIframe id="settings_client_portal_permissions" />}
        actions={
          <>
            {!$editable && (
              <>
                {isCustom && (
                  <Button
                    label="Reset to Defaults"
                    glyph="reset"
                    onClick={this.reset}
                    isLoading={isLoading}
                    permission="settings.client_portal_permissions.edit"
                  />
                )}
                <Button
                  label="Edit Permissions"
                  testKey="edit_permissions_button"
                  glyph="edit"
                  onClick={this.edit}
                  isDisabled={isLoading}
                  permission="settings.client_portal_permissions.edit"
                />
              </>
            )}

            {$editable && (
              <>
                <Button label="Cancel" glyph="cross" onClick={this.cancel} isDisabled={isLoading} />
                <Button label="Save" glyph="check" color="green" type="primary" onClick={this.updatePermissions} isLoading={isLoading} />
              </>
            )}
          </>
        }
      >
        <Grid>
          <Card css={{ overflow: 'auto' }}>
            {isLoading && <State isLoading={true} />}

            {rules && !isLoading && (
              <Form getForm={this.form} isEditable={$editable} initialModel={rules}>
                <PermissionSection title="General Info" icon="clients">
                  <PermissionSubRow
                    title="Personal Details"
                    models={{
                      view: 'clients.general_info.personal_details.view',
                      edit: 'clients.general_info.personal_details.edit',
                    }}
                  />
                  <PermissionSubRow
                    title="Contact Details"
                    models={{
                      view: 'clients.general_info.contact_details.view',
                      edit: 'clients.general_info.contact_details.edit',
                    }}
                  />
                  <PermissionSubRow
                    title="Sensitive Information"
                    models={{
                      view: 'clients.general_info.sensitive_data.view',
                      edit: 'clients.general_info.sensitive_data.edit',
                    }}
                  />
                  <PermissionSubRow
                    title="Home Address"
                    models={{
                      view: 'clients.general_info.home_address.view',
                      edit: 'clients.general_info.home_address.edit',
                    }}
                  />
                  <PermissionSubRow
                    title="Vehicle Information"
                    models={{
                      view: 'clients.general_info.vehicle_information.view',
                      edit: 'clients.general_info.vehicle_information.edit',
                    }}
                  />
                </PermissionSection>

                <PermissionSection
                  title="Message Board"
                  icon="checklist"
                  isCustom={customSections.includes('messages')}
                  models={{ view: 'messages.view' }}
                />

                {/* TODO (Andrei): which chat permissions to include? */}
                <PermissionSection
                  title="Clients Chat"
                  icon="clients_chat"
                  isCustom={customSections.includes('clients_chat')}
                  models={{
                    view: 'clients_chat.view',
                    create: 'clients_chat.create',
                    edit: 'clients_chat.edit',
                  }}
                />

                <PermissionSection
                  title="Calendar"
                  icon="calendar"
                  featureFlag="events"
                  newFeatureFlag="events"
                  isCustom={customSections.includes('events')}
                  models={{ view: 'events.view', edit: 'events.edit', create: 'events.create', delete: 'events.delete' }}
                />

                <PermissionSection
                  title="To-Do's"
                  icon="tasks"
                  isCustom={customSections.includes('todos')}
                  models={{ view: 'todos.view', edit: 'todos.edit', create: 'todos.create', delete: 'todos.delete' }}
                />

                <PermissionSection
                  title="Location Check-In"
                  icon="recovery_navigation"
                  featureFlag="location_checkin"
                  isCustom={customSections.includes('location_checkin')}
                  models={{
                    view: 'location_checkin.view',
                    create: 'location_checkin.create',
                    edit: 'location_checkin.edit',
                  }}
                />

                <PermissionSection
                  title="Agreements"
                  icon="legal_agreement_alt"
                  isCustom={customSections.includes('agreements')}
                  models={{
                    view: 'agreements.view',
                  }}
                />

                <PermissionSection
                  title="Discharges"
                  icon="discharge_forms"
                  isCustom={customSections.includes('discharges')}
                  models={{ view: 'discharges.view', edit: 'discharges.edit' }}
                />

                <PermissionSection
                  title="Contacts"
                  icon="contacts"
                  isCustom={customSections.includes('contacts')}
                  models={{
                    view: 'contacts.view',
                    create: 'contacts.create',
                    edit: 'contacts.edit',
                  }}
                />

                <PermissionSection
                  title="Ledger"
                  icon="financials"
                  featureFlag="ledger"
                  isCustom={customSections.includes('ledger')}
                  models={{ view: 'ledger.view' }}
                />

                <PermissionSection
                  title="Payment Methods"
                  icon="financials"
                  featureFlag="ledger"
                  isCustom={customSections.includes('payment_methods')}
                  models={{
                    view: 'payment_methods.view',
                    create: 'payment_methods.create',
                    edit: 'payment_methods.edit',
                    delete: 'payment_methods.delete',
                  }}
                />

                <PermissionSection
                  title="Treatment Plans"
                  icon="treatment_plans"
                  addon="ehr"
                  isCustom={customSections.includes('treatment_plans')}
                  models={{ view: 'treatment_plans.view' }}
                />

                <PermissionSection
                  title="Medications"
                  icon="medications"
                  featureFlag="medications"
                  isCustom={customSections.includes('medications')}
                  models={{
                    view: 'medications.view',
                    create: 'medications.create',
                    edit: 'medications.edit',
                    delete: 'medications.delete',
                  }}
                />

                <PermissionSection
                  title="Medication Logs"
                  icon="med_pass"
                  featureFlag="medications"
                  isCustom={customSections.includes('medication_logs')}
                  models={{
                    view: 'medication_logs.view',
                    create: 'medication_logs.create',
                    edit: 'medication_logs.edit',
                    delete: 'medication_logs.delete',
                  }}
                />

                <PermissionSection
                  title="Clinical Measures"
                  icon="clinical_measurements"
                  addon="ehr"
                  isCustom={customSections.includes('clinical_measurements')}
                  models={{
                    view: 'clinical_measurements.view',
                    create: 'clinical_measurements.create',
                  }}
                />

                <PermissionSection
                  title="Recovery Notes"
                  icon="recovery_coaching"
                  addon="ehr"
                  isCustom={customSections.includes('recovery_coaching')}
                  models={{
                    view: 'recovery_coaching.view',
                    create: 'recovery_coaching.create',
                    edit: 'recovery_coaching.edit',
                  }}
                />

                <PermissionSection
                  title="Outcome Measures"
                  icon="outcome_measures"
                  addon="ehr"
                  isCustom={customSections.includes('outcome_measures')}
                  models={{
                    view: 'outcome_measures.view',
                    create: 'outcome_measures.create',
                    edit: 'outcome_measures.edit',
                  }}
                />

                <PermissionSection
                  title="Client Custom Notes"
                  icon="clinical_assessments"
                  featureFlag="custom_notes"
                  isCustom={customSections.includes('client_custom_notes')}
                  models={{
                    view: 'client_custom_notes.view',
                    create: 'client_custom_notes.create',
                    edit: 'client_custom_notes.edit',
                    delete: 'client_custom_notes.delete',
                  }}
                />

                <PermissionSection
                  title="Test Results"
                  icon="test_results"
                  featureFlag="test_results"
                  isCustom={customSections.includes('test_results')}
                >
                  <PermissionSubRow title="UA Tests" models={{ view: 'test_results.ua_tests.view' }} />
                  <PermissionSubRow title="Breathalyzer Tests" models={{ view: 'test_results.breathalyzer_tests.view' }} />
                  <PermissionSubRow title="Vital Signs" models={{ view: 'test_results.vital_signs.view' }} />
                  <PermissionSubRow title="Communicable Disease Tests" models={{ view: 'test_results.communicable_disease_tests.view' }} />
                </PermissionSection>

                <PermissionSection
                  title="Files"
                  icon="files"
                  featureFlag="files"
                  isCustom={customSections.includes('files')}
                  models={{ view: 'files.view' }}
                />

                <PermissionSection
                  title="Staff"
                  icon="employees"
                  isCustom={customSections.includes('employees')}
                  models={{ view: 'employees.view' }}
                >
                  <PermissionSubRow
                    title="Personal Details"
                    models={{
                      view: 'employees.general_info.personal_details.view',
                    }}
                  />
                  <PermissionSubRow
                    title="Employment Information"
                    models={{
                      view: 'employees.general_info.employment_information.view',
                    }}
                  />
                  <PermissionSubRow
                    title="Contact Details"
                    models={{
                      view: 'employees.general_info.contact_details.view',
                    }}
                  />
                </PermissionSection>

                <PermissionSection
                  title="Locations"
                  icon="properties"
                  isCustom={customSections.includes('properties')}
                  models={{ view: 'properties.view' }}
                >
                  <PermissionSubRow
                    title="Location Details"
                    models={{
                      view: 'properties.general_info.property_details.view',
                    }}
                  />
                  <PermissionSubRow
                    title="Location Address"
                    models={{
                      view: 'properties.general_info.address.view',
                    }}
                  />
                </PermissionSection>

                <PermissionSection
                  title="Organizations"
                  icon="organizations"
                  isCustom={customSections.includes('organizations')}
                  models={{ view: 'organizations.view' }}
                >
                  <PermissionSubRow
                    title="Organization Details"
                    models={{
                      view: 'organizations.general_info.organization_details.view',
                    }}
                  />
                  <PermissionSubRow
                    title="Organization Address"
                    models={{
                      view: 'organizations.general_info.address.view',
                    }}
                  />
                  <PermissionSubRow title="Organization Contacts" models={{ view: 'organizations.contacts.view' }} />
                </PermissionSection>

                <PermissionSection
                  title="Form Submissions"
                  icon="web_form"
                  featureFlagV2="form_submissions"
                  isCustom={customSections.includes('form_submissions')}
                  models={{
                    view: 'form_submissions.view',
                    create: 'form_submissions.create',
                    edit: 'form_submissions.edit',
                  }}
                />
              </Form>
            )}
          </Card>
        </Grid>
      </Page>
    )
  }
}

export default ClientsPermissionList
