import React from 'react'
import clsx from 'clsx'
import Embed, { defaultProviders } from 'react-tiny-oembed'

import State from '../../../../components/State'

import { Box } from './Box'

const providers = [
  ...defaultProviders,
  {
    provider_name: 'Canva',
    provider_url: 'https://www.canva.com',
    endpoints: [
      {
        schemes: ['https://www.canva.com/design/*/view'],
        url: 'https://www.canva.com/_oembed',
        discovery: true,
      },
    ],
  },
]

export const OEmbed = React.forwardRef((props: any, ref) => {
  const { isEditable, children, className, element, hoverElement } = props

  if (!element || !element.config) return null

  const { oembed_url } = element.config

  const rootClasses = clsx('OEMBED', !oembed_url && 'is-empty', isEditable && 'is-editable', className)

  return (
    <Box ref={ref} element={element} className={rootClasses} hoverElement={hoverElement} css={STYLES.root}>
      {oembed_url && <Embed url={oembed_url} providers={providers}></Embed>}

      {children}

      {isEditable && !oembed_url && <State isEmpty glyph="embed" title="OEmbed" emptyDescription="Enter an OEmbed URL" />}
    </Box>
  )
})

const STYLES = {
  root: {
    '&.is-empty.is-editable': {
      minHeight: 100,
      borderRadius: 4,
      position: 'relative',
    },
  },
}
