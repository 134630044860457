import React from 'react'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useAPIwithNoAbort } from '../../../../hooks/useAPI'

import Card from '../../../Card'
import Tabs from '../../../Tabs'

import ChargesTable from './tables/ChargesTable'
import RefundsTable from './tables/RefundsTable'
import WriteOffsTable from './tables/WriteOffsTable'
import TransactionsTable from './tables/TransactionsTable'
import CreditsTable from './tables/CreditsTable'
import PaymentsTable from './tables/PaymentsTable'

import PayerTransactionStats from '../PayerTransactionStats'

const CLIENTS_STATUSES = {
  lead: 'admissions',
  intake: 'admissions',
  declined: 'admissions',
  current: 'clients',
  alumni: 'alumni',
}

const OrganizationTransactions = ({ name, url, useStats, hiddenColumns }: any) => {
  const transactions = useSelector((state: any) => state.data[name]?.data)
  const transactionsLoading = useSelector((state: any) => state.data[name]?.loading)

  const allTransactionsCategory = 'charge,payment,credit,refund'
  const [transactionsCategory, setTransactionsCategory] = React.useState(allTransactionsCategory)

  useAPIwithNoAbort(name, `${url}?category=${transactionsCategory ? transactionsCategory : ''}`)

  const filteredTransactions = React.useMemo(() => {
    const result: any = {}

    if (size(transactions) === 0) return result

    for (const key in transactions) {
      // skip zero amount transactions
      if (parseFloat?.(transactions?.[key]?.amount) === 0) continue

      result[key] = transactions[key]
    }

    return result
  }, [transactions])

  return (
    <>
      {useStats && <PayerTransactionStats name={name} url={url} />}

      <Card className="!mt-4">
        <Tabs defaultTab="all_transactions">
          <Tabs.List caps className="!pl-4">
            <Tabs.Item
              label="All Transactions"
              name="all_transactions"
              onSelected={() => setTransactionsCategory(allTransactionsCategory)}
            />

            <Tabs.Divider />

            <Tabs.Item label="Charges" name="charges" onSelected={() => setTransactionsCategory('charge')} />
            <Tabs.Item label="Payments" name="payments" onSelected={() => setTransactionsCategory('payment')} />
            <Tabs.Item label="Credits" name="credits" onSelected={() => setTransactionsCategory('credit')} />
            <Tabs.Item label="Refunds" name="refunds" onSelected={() => setTransactionsCategory('refund')} />
            <Tabs.Item label="Write Off's" name="write-offs" onSelected={() => setTransactionsCategory('write_off')} />
          </Tabs.List>

          <Tabs.Panels>
            <Tabs.Panel name="all_transactions">
              <TransactionsTable
                isLoading={transactionsLoading}
                hiddenColumns={hiddenColumns}
                data={filteredTransactions}
                to={({ id, status }: any) => `/${CLIENTS_STATUSES[status]}/${id}/financials`}
              />
            </Tabs.Panel>

            <Tabs.Panel name="charges">
              <ChargesTable
                isLoading={transactionsLoading}
                hiddenColumns={hiddenColumns}
                data={filteredTransactions}
                to={({ id, status }: any) => `/${CLIENTS_STATUSES[status]}/${id}/financials`}
              />
            </Tabs.Panel>

            <Tabs.Panel name="payments">
              <PaymentsTable
                isLoading={transactionsLoading}
                hiddenColumns={hiddenColumns}
                data={filteredTransactions}
                to={({ id, status }: any) => `/${CLIENTS_STATUSES[status]}/${id}/financials`}
              />
            </Tabs.Panel>

            <Tabs.Panel name="credits">
              <CreditsTable
                isLoading={transactionsLoading}
                hiddenColumns={hiddenColumns}
                data={filteredTransactions}
                to={({ id, status }: any) => `/${CLIENTS_STATUSES[status]}/${id}/financials`}
              />
            </Tabs.Panel>

            <Tabs.Panel name="refunds">
              <RefundsTable
                isLoading={transactionsLoading}
                hiddenColumns={hiddenColumns}
                data={filteredTransactions}
                to={({ id, status }: any) => `/${CLIENTS_STATUSES[status]}/${id}/financials`}
              />
            </Tabs.Panel>

            <Tabs.Panel name="write-offs">
              <WriteOffsTable
                isLoading={transactionsLoading}
                hiddenColumns={hiddenColumns}
                data={filteredTransactions}
                to={({ id, status }: any) => `/${CLIENTS_STATUSES[status]}/${id}/financials`}
              />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </Card>
    </>
  )
}

OrganizationTransactions.defaultProps = {
  name: 'financial_transactions',
  useStats: true,
  isSelectable: true,
}

export default OrganizationTransactions
