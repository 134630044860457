import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../theme'
import { usTime } from '../../utils/functions'

import Avatar from '../Avatar'
import ClientQuickView from '../Overlays/quick/ClientQuickView'
import EmployeeQuickView from '../Overlays/quick/EmployeeQuickView'
import Flex from '../Flex'
import SummonOverlay from '../SummonOverlay'

const Author = ({ author, sentAt, asLink, onClick }: any) => {
  const classNames = clsx({
    'as-link': asLink,
  })

  return (
    <Flex gap={8} alignItems="center" css={styles} className={classNames} onClick={onClick}>
      <Avatar magnify src={author.avatar} initials={author.name} size={24} />
      <div>
        <span css={styles.name}>{author.name}</span>
        <span css={styles.info}>{sentAt}</span>
      </div>
    </Flex>
  )
}

const MessageAuthor = ({ author, sentAt, timezone }: any) => {
  if (!author) return null

  if (author.type === 'employee') {
    return (
      <SummonOverlay overlay={<EmployeeQuickView employee={author} />}>
        <Author asLink author={author} sentAt={usTime(sentAt, timezone)} />
      </SummonOverlay>
    )
  }

  if (author.type === 'resident') {
    return (
      <SummonOverlay overlay={<ClientQuickView client={author} />}>
        <Author asLink author={author} sentAt={usTime(sentAt, timezone)} />
      </SummonOverlay>
    )
  }

  return <Author author={author} />
}

const styles = {
  marginRight: '0.5rem',

  name: {
    fontWeight: 600,
    color: COLORS.link,
  },

  info: {
    fontSize: '0.9rem',
    color: COLORS.textMuted,
    display: 'inline-block',
    marginLeft: '0.4rem',
    opacity: 0.8,
  },

  '&.as-link': {
    cursor: 'pointer',

    '.author-name': {
      color: COLORS.link,
    },
  },
}

export default MessageAuthor
