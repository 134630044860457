import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import useAPI from '@behavehealth/hooks/useAPI'

import Card from '@behavehealth/components/Card'
import GrievanceCustomNoteTemplatesTable from '@behavehealth/components/Templates/tables/GrievanceCustomNoteTemplatesTable'
import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

const GrievanceCustomNoteTemplates = () => {
  const data = useSelector((state: any) => state.data?.custom_note_templates?.data)
  const loading = useSelector((state: any) => state.data?.custom_note_templates?.loading)
  const isEmpty = size(data) === 0

  useAPI('custom_note_templates', '/custom_note_templates?variant=grievance')

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} title="Grievance Custom Note Templates" icon="grievance_notes">
      <Grid>
        <Card>
          <GrievanceCustomNoteTemplatesTable
            mainLinkAs={NavLink}
            title="Grievance Custom Note Templates"
            data={data}
            isLoading={isEmpty && loading}
            to={(id: string) => id}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default GrievanceCustomNoteTemplates
