import React from 'react'
import size from 'lodash/size'

import { useSettings } from '../../hooks'

import Status from '../Status'
import Markup from '../Markup'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

const STATUSES = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'red',
  },
  pending_review: {
    label: 'Pending Review',
    color: 'yellow',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
  completed: {
    label: 'Completed',
    color: 'green',
  },
  closed: {
    label: 'Closed',
    color: 'green',
  },
}

const columns = (to: Function = () => {}, timezone: string, mainLinkAs) => [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'description',
    Header: 'Note',
    Cell: ({ value, row }: any) => {
      return (
        <TableCell.MainLink isTruncated as={mainLinkAs} row={row} to={to(row.original.id)} label={<TableCell.Markup value={value} />} />
      )
    },
  },
  {
    width: 160,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ value }: any) => <Status label={value.replace('_', ' ')} color={value === 'incident_report' ? 'red' : 'blue'} />,
    Filter: TableFilter.Select,
    filterOptions: [
      {
        value: 'incident_report',
        label: 'Incident Report',
      },
      {
        value: 'general_note',
        label: 'General Note',
      },
    ],
  },
  {
    width: 160,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => (STATUSES[value] ? <Status label={STATUSES[value]?.label} color={STATUSES[value]?.color} /> : '–'),
    Filter: TableFilter.Select,
    filterOptions: [
      {
        value: 'draft',
        label: 'Draft',
      },
      {
        value: 'updates_required',
        label: 'Updates Required',
      },
      {
        value: 'pending_review',
        label: 'Pending Review',
      },
      {
        value: 'signed_off',
        label: 'Signed Off',
      },
      {
        value: 'completed',
        label: 'Completed',
      },
      {
        value: 'closed',
        label: 'Closed',
      },
    ],
  },
  {
    width: 200,
    accessor: 'dated_at',
    Header: 'Dated At',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  selectActions?: Function
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const ShiftNotesTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, localStorageKey, selectActions, batchActionsConfig, hiddenColumns, titleAfter } = props
  const { timezone } = useSettings()

  return (
    <Table
      showFilters
      title="Shift Notes"
      testKey="shift_notes_table"
      titleAfter={titleAfter}
      icon="housing_notes"
      data={data}
      columns={columns(to, timezone, props.mainLinkAs)}
      hiddenColumns={hiddenColumns}
      selectActions={selectActions}
      batchActionsConfig={batchActionsConfig}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No shift notes added yet"
      localStorageKey={localStorageKey}
    />
  )
}

ShiftNotesTable.defaultProps = {
  localStorageKey: 'shift_notes',
}

export default ShiftNotesTable
