import React from 'react'
import { useSelector } from 'react-redux'

import { COLORS } from '@behavehealth/theme'
import { URL } from '@behavehealth/utils/globals'
import useCopyToClipboard from '@behavehealth/hooks/useCopyToClipboard'

import { Button, Card, CardContent, CardHeader, Flex, Glyph, Grid, PageLayout, PageSection, Text } from '@behavehealth/components'

const getFormURL = (tenantID: string) => `${URL.app}/${tenantID}/forms/full-application`

const FullApplicationForm = () => {
  const tenant = useSelector((state: any) => state.me?.tenant)
  const link = getFormURL(tenant.id)

  const { copy, didCopy: linkCopied } = useCopyToClipboard({
    text: link,
    notification: 'Link copied!',
  })

  return (
    <PageLayout>
      <PageSection>
        <PageSection.Header>
          <PageSection.Title title="Widget Description" />
        </PageSection.Header>

        <PageSection.Content>
          <Card>
            <CardHeader className="!px-2 !py-[0.65rem]">
              <Grid gap="1rem">
                <Text
                  nowrap
                  glyph="tick"
                  description="Send the link directly to anyone (e.g. referral partners) and receive submissions in the app"
                />
                <Text
                  nowrap
                  glyph="tick"
                  description="All the information gets saved directly to your app, and even if they don't finish up submissions you can always continue."
                />
              </Grid>
            </CardHeader>
          </Card>
        </PageSection.Content>
      </PageSection>

      <PageSection>
        <PageSection.Header>
          <PageSection.Title title="Usage Instructions" />
          <PageSection.Subtitle subtitle="Copy the link below and send directly to anyone (e.g. referral partners) and receive submissions in the app" />
        </PageSection.Header>

        <PageSection.Content>
          <Grid gap="1.25rem">
            <Card>
              <CardHeader>
                <pre
                  css={{
                    width: '100%',
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-wrap',
                    color: COLORS.textMuted,
                    borderRadius: 5,
                    padding: 10,
                    margin: 0,
                  }}
                >
                  <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    css={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      svg: { marginLeft: '0.25em' },
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    {link} <Glyph glyph="external_link" size={14} color={COLORS.blue} />
                  </a>
                </pre>
              </CardHeader>

              <CardContent>
                <Flex gap="1rem">
                  <Button
                    glyph={linkCopied ? 'check' : 'copy'}
                    label={linkCopied ? 'Link Copied!' : 'Copy Link'}
                    onClick={copy}
                    type={linkCopied ? 'default' : 'primary'}
                  />

                  <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    css={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      svg: { marginLeft: '0.25em' },
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    Open in New Tab <Glyph glyph="external_link" size={14} color={COLORS.blue} />
                  </a>
                </Flex>
              </CardContent>
            </Card>
          </Grid>
        </PageSection.Content>
      </PageSection>
    </PageLayout>
  )
}

export default FullApplicationForm
