import React from 'react'
import startCase from 'lodash/startCase'
import compact from 'lodash/compact'

import { CONFIG } from '../../utils/globals'
import { DataTable } from '../../components/DataTable/DataTable'
import { LinkCell } from '../../components/DataTable/cells/LinkCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useSettings } from '../../hooks/useSettings'
import { usePermissions } from '../../hooks/usePermissions'

import EmployeeStatus from '../../components/Statuses/EmployeeStatus'
import Permission from '../../components/Permission'
import SummonOverlay from '../../components/SummonOverlay'
import EmployeeQuickView from '../../components/Overlays/quick/EmployeeQuickView'

import { StaffPermissionsOverlay } from '../Staff/StaffPermissionsOverlay'

import { FILTERS } from '../Filters/config'
import { DEFAULT_FILTERS } from '../Filters/constants'

const { name, first_name, last_name, position, ...filtersRest }: any = FILTERS.employees

export const EmployeesDataTable = (props: any) => {
  const { to, showPermissionsColumn } = props

  const { isBehave, tenant } = useSettings()
  const { allowed: canViewPermissions } = usePermissions({ permission: 'employees.permissions.view' })

  const [staffPermissionsId, setStaffPermissionsId] = React.useState(null)

  const columns = React.useMemo(() => {
    return compact([
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => {
          if (props.renderMainCell) {
            return props.renderMainCell(data)
          }

          return (
            <MainCell
              testKey="main_cell"
              id={data.id}
              value={data.name}
              avatar={data.avatar}
              to={to?.(data)}
              quickViewOverlay={<EmployeeQuickView employee={data} />}
            />
          )
        },
      },
      {
        width: 160,
        id: 'position',
        model: 'position',
        title: 'Role',
        formatValue: ({ data, value }: any) => startCase(value),
      },
      isBehave && {
        width: 120,
        id: 'log_rocket',
        model: 'email',
        title: 'Sessions',
        formatValue: ({ value }: any) => (
          <LinkCell
            isExternal
            value="Open"
            glyph="link"
            href={`https://logrocket.behavehealth.com/behavehealth/ehr-new/?filters=[{"type":"userTrait","operator":{"name":"is","type":"IS","hasStrings":true,"autocompleteEnabled":true},"strings":["tenant:${tenant?.subdomain}"]},{"type":"email","operator":{"name":"is","type":"IS","hasStrings":true,"autocompleteEnabled":true},"strings":["${value}"]}]`}
          />
        ),
      },
      canViewPermissions &&
        showPermissionsColumn && {
          width: 160,
          id: '_permissions',
          model: '_permissions',
          title: 'Permissions',
          formatValue: ({ data }: any) => (
            <MainCell
              value="View Permissions"
              onClick={() => setStaffPermissionsId(data.id)}
              isActive={staffPermissionsId === data.id}
              glyph="lock"
            />
          ),
        },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
        tagSmartCategories: 'employees',
      },
      {
        title: 'Title',
        model: 'title',
      },
      {
        width: 160,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <EmployeeStatus status={value} />,
      },
      {
        model: 'houses',
        title: 'Assigned Locations',
        type: 'profiles',
        disableSort: true,
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'phone_no',
        title: 'Phone',
        type: 'phone',
      },
      {
        width: 160,
        id: 'email',
        model: 'email',
        title: 'Email',
        type: 'email',
      },
      {
        width: 180,
        id: 'is_shared_with_portal',
        model: 'is_shared_with_portal',
        title: 'Client Portal Sharing',
        type: 'boolean',
      },
      {
        width: 150,
        id: 'is_blocked',
        model: 'is_blocked',
        title: 'Is Blocked',
      },
      {
        width: 150,
        model: 'is_clinical_supervisor',
        title: 'Is Supervisor',
        type: 'boolean',
      },
      {
        width: 150,
        model: 'is_healthcare_provider',
        title: 'Is Healthcare Provider',
        type: 'boolean',
      },
      {
        width: 190,
        id: 'last_logged_in_at',
        model: 'last_logged_in_at',
        title: 'Last Logged in At',
        type: 'date_time',
      },
      {
        width: 140,
        id: 'last_name',
        model: 'last_name',
        title: 'Last Name',
      },
    ])
  }, [to, isBehave, tenant?.subdomain, canViewPermissions, showPermissionsColumn, staffPermissionsId])

  return (
    <>
      <DataTable
        testKey="employees_report_data_table"
        asCard
        title="Staff"
        icon="employees"
        columns={columns}
        filtersConfig={{
          name,
          first_name,
          last_name,
          position: {
            label: 'Role',
            type: 'multi_select',
            options: CONFIG?.active_employee_roles?.map?.(({ text, value }) => ({ label: text, value: value })) || [],
          },
          tags: DEFAULT_FILTERS.tags,
          ...filtersRest,
        }}
        {...props}
      />

      <Permission permission="employees.permissions.view">
        <SummonOverlay
          key={`id-${staffPermissionsId}`}
          isOpen={!!staffPermissionsId}
          onClose={() => setStaffPermissionsId(null)}
          overlay={<StaffPermissionsOverlay key={`id-${staffPermissionsId}`} dataID={staffPermissionsId} />}
        />
      </Permission>
    </>
  )
}
