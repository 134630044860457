import React from 'react'
import { useMedia } from 'use-media'
import produce from 'immer'
import startCase from 'lodash/startCase'

import { formatURL, usDateTime, address } from '../../utils/functions'
import { getPrefix, useUpdate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import withSettings from '../../hocs/withSettings'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import OrganizationQuickView from '../Overlays/quick/OrganizationQuickView'

const mainColumn = (to) => ({
  width: 240,
  isSticky: true,
  canToggleVisible: false,
  accessor: 'name',
  Header: 'Name',
  Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} avatar={row.original.avatar} />,
})

const tagsColumn = (apiKey: any) => ({
  width: 200,
  Header: 'Tags',
  accessor: 'tags',
  disableSortBy: true,
  disableFilters: true,
  Cell: ({ value, row }: any) => {
    const { mutateAsync, isLoading }: any = useUpdate({
      name: ['organization', row.original.id],
      url: `/organizations/${row.original.id}`,
      invalidateKeys: ['organizations', 'tag-live-reports'],
      onSuccess: (_data: any, variables: any, queryClient: any) => {
        const prefix = getPrefix()

        queryClient.setQueryData([prefix, apiKey].flat(), (oldData: any) => {
          if (!oldData?.data) return

          const index = oldData.data.findIndex((o) => o.id === row.original.id)
          if (index === -1) return

          const newData = produce(oldData, (draft: any) => {
            draft.data[index].tags = variables.tags
          })

          return newData
        })
      },
    })

    return (
      <TableCell.Tags
        value={value}
        save={(tagIDs: any, tags: any) => mutateAsync({ tag_ids: tagIDs, tags })}
        isLoading={isLoading}
        smartCategories="organizations"
      />
    )
  },
})

const columns = (to: Function = () => {}, isDesktop: boolean, isPortal: boolean, timezone: string, apiKey: any) => [
  ...(isDesktop || isPortal ? [mainColumn(to)] : []),
  {
    width: isDesktop ? 160 : 240,
    disableFilters: true,
    disableSortBy: true,
    id: 'quick_view',
    accessor: 'id',
    Header: 'Quick View',
    Cell: ({ row }: any) => (
      <TableCell.QuickViewOverlay overlay={<OrganizationQuickView organization={row.original} />}>
        {!isDesktop && <TableCell.MainLink label={row.original.name} avatar={row.original.avatar} glyphAfter="quick_view" />}
      </TableCell.QuickViewOverlay>
    ),
  },
  ...(apiKey ? [tagsColumn(apiKey)] : []),
  {
    width: 140,
    accessor: 'category',
    Header: 'Type',
    Cell: ({ value }: any) => startCase(value),
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      {
        value: 'vendor',
        label: 'Vendor',
      },
      {
        value: 'resource',
        label: 'Resource',
      },
      {
        value: 'provider',
        label: 'Provider',
      },
    ],
  },
  {
    width: 180,
    disableSortBy: true,
    id: 'category_type',
    accessor: 'subcategory.name',
    Header: 'Category',
  },
  {
    width: 160,
    accessor: 'phone_no',
    Header: 'Phone',
    Cell: TableCell.Phone,
  },
  {
    width: 200,
    accessor: 'email',
    Header: 'Email',
    Cell: TableCell.Email,
  },
  {
    width: 200,
    accessor: 'website',
    Header: 'Website',
    Cell: ({ value }: any) => <TableCell.Link isExternal label={value} href={formatURL(value)} />,
  },
  {
    width: 180,
    accessor: 'contacts_count',
    disableSortBy: true,
    Header: 'Number of Contacts',
    Cell: ({ value }: any) => value || '0',
  },
  {
    width: 240,
    accessor: 'address',
    Header: 'Address',
    Cell: ({ value }: any) => <TableCell.Truncated value={address(value)} />,
  },
  {
    width: 200,
    accessor: 'last_contacted_at',
    Header: 'Last Contacted',
    Cell: ({ value }: any) => <TableCell value={usDateTime(value, timezone)} />,
  },
  {
    width: 180,
    accessor: 'is_shared_with_portal',
    Header: 'Client Portal Sharing',
    Cell: ({ value }: any) => {
      if (!value) return 'Not shared'
      return <TableCell.GlyphValue glyph="portal" value="Shared" />
    },
    Filter: TableFilter.Select,
    filter: 'boolean',
    filterOptions: [
      {
        value: true,
        label: 'Shared',
      },
      {
        value: false,
        label: 'Not shared',
      },
    ],
  },
]

const OrganizationsTable = (props: any) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    isLoading,
    localStorageKey,
    timezone,
    title,
    titleAfter,
    to,
    apiKey,
    testKey,
    ...rest
  } = props

  const isDesktop = useMedia({ minWidth: 600 })
  const { isPortal } = useSettings()

  return (
    <Table
      testKey={testKey}
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to, isDesktop, isPortal, timezone, apiKey)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

OrganizationsTable.defaultProps = {
  title: 'Organizations',
  icon: 'organizations',
  emptyDescription: 'No organizations added yet',
  localStorageKey: 'organizations',
}

export default withSettings(OrganizationsTable)
