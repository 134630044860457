import React from 'react'

import Checkbox from '../../../../Forms/Checkbox'
import CheckboxGroup from '../../../../Forms/CheckboxGroup'
import ContextShow from '../../../../Forms/ContextShow'
import FormSection from '../../../../Forms/FormSection'
import Grid from '../../../../Grid'
import Input from '../../../../Forms/Input'
import Radio from '../../../../Forms/Radio'
import RadioGroup from '../../../../Forms/RadioGroup'
import SmartTextarea from '../../../../Forms/SmartTextarea'

const CareCoordination = () => {
  return (
    <Grid gap="1rem">
      <FormSection>
        <CheckboxGroup label="Treatment goals include a decrease in the following:" layout="vertical-dense">
          <Checkbox label="Anger/irritability" model="data.care_coordination.treatment_decrease_goals.anger_irritability" />
          <Checkbox label="Anxiety" model="data.care_coordination.treatment_decrease_goals.anxiety" />
          <Checkbox label="Depressive symptoms" model="data.care_coordination.treatment_decrease_goals.depressive_symptoms" />
          <Checkbox label="Decrease or quit smoking" model="data.care_coordination.treatment_decrease_goals.decrease_or_quit_smoking" />
          <Checkbox label="Fatigue" model="data.care_coordination.treatment_decrease_goals.fatigue" />
          <Checkbox label="Hyperactivity" model="data.care_coordination.treatment_decrease_goals.hyperactivity" />
          <Checkbox label="Impulsivity" model="data.care_coordination.treatment_decrease_goals.impulsivity" />
          <Checkbox label="Inattention" model="data.care_coordination.treatment_decrease_goals.inattention" />
          <Checkbox label="Insomnia" model="data.care_coordination.treatment_decrease_goals.insomnia" />
          <Checkbox label="Mania" model="data.care_coordination.treatment_decrease_goals.mania" />
          <Checkbox label="Mood instability" model="data.care_coordination.treatment_decrease_goals.mood_instability" />
          <Checkbox label="Obsessions/compulsions" model="data.care_coordination.treatment_decrease_goals.obsessions_compulsions" />
          <Checkbox label="Psychosis" model="data.care_coordination.treatment_decrease_goals.psychosis" />
          <Checkbox label="Rumination" model="data.care_coordination.treatment_decrease_goals.rumination" />
          <Checkbox label="Self-harm" model="data.care_coordination.treatment_decrease_goals.self_harm" />
          <Checkbox label="Substance dependence" model="data.care_coordination.treatment_decrease_goals.substance_dependence" />
          <Checkbox label="Suicidal ideations" model="data.care_coordination.treatment_decrease_goals.suicidal_ideations" />
          <Checkbox label="Thought distortion" model="data.care_coordination.treatment_decrease_goals.thought_distortion" />
          <Checkbox label="Other" model="data.care_coordination.treatment_decrease_goals.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.treatment_decrease_goals.other" is={true}>
          <SmartTextarea useDictation label="Other:" model="data.care_coordination.treatment_decrease_goals.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection>
        <CheckboxGroup label="Potential medication side effects discussed include but are not limited to:" layout="vertical-dense">
          <Checkbox
            label="Addictive potential/tolerance"
            model="data.care_coordination.medication_side_effects.addictive_potential_tolerance"
          />
          <Checkbox label="Agitation" model="data.care_coordination.medication_side_effects.agitation" />
          <Checkbox label="Agranulocytosis" model="data.care_coordination.medication_side_effects.agranulocytosis" />
          <Checkbox label="Akathesia/TD/EPS/NMS" model="data.care_coordination.medication_side_effects.akathesia_td_eps_nms" />
          <Checkbox label="Avoid ETOH/other drugs" model="data.care_coordination.medication_side_effects.avoid_etoh_other_drugs" />
          <Checkbox label="Cardiac impact" model="data.care_coordination.medication_side_effects.cardiac_impact" />
          <Checkbox label="Diabetes" model="data.care_coordination.medication_side_effects.diabetes" />
          <Checkbox label="Dietary restrictions" model="data.care_coordination.medication_side_effects.dietary_restrictions" />
          <Checkbox label="Dizziness/orthostasis" model="data.care_coordination.medication_side_effects.dizziness_orthostasis" />
          <Checkbox
            label="Driving caution/restrictions"
            model="data.care_coordination.medication_side_effects.driving_caution_restrictions"
          />
          <Checkbox label="Dry mouth" model="data.care_coordination.medication_side_effects.dry_mouth" />
          <Checkbox label="Electrolyte abnormalities" model="data.care_coordination.medication_side_effects.electrolyte_abnormalities" />
          <Checkbox label="Fall risk increased" model="data.care_coordination.medication_side_effects.fall_risk_increased" />
          <Checkbox label="Hepatic impairment" model="data.care_coordination.medication_side_effects.hepatic_impairment" />
          <Checkbox label="Hydration needs" model="data.care_coordination.medication_side_effects.hydration_needs" />
          <Checkbox label="Hyperlipidemia" model="data.care_coordination.medication_side_effects.hyperlipidemia" />
          <Checkbox label="Hypothyroidism" model="data.care_coordination.medication_side_effects.hypothyroidism" />
          <Checkbox label="Increased sun sensitivity" model="data.care_coordination.medication_side_effects.increased_sun_sensitivity" />
          <Checkbox label="Medication interactions" model="data.care_coordination.medication_side_effects.medication_interactions" />
          <Checkbox label="Nausea/GI upset" model="data.care_coordination.medication_side_effects.nausea_gi_upset" />
          <Checkbox label="Pancreatitis" model="data.care_coordination.medication_side_effects.pancreatitis" />
          <Checkbox label="Paresthesias" model="data.care_coordination.medication_side_effects.paresthesias" />
          <Checkbox label="Pregnancy/lactation effect" model="data.care_coordination.medication_side_effects.pregnancy_lactation_effect" />
          <Checkbox label="Priapism" model="data.care_coordination.medication_side_effects.priapism" />
          <Checkbox label="Rash" model="data.care_coordination.medication_side_effects.rash" />
          <Checkbox label="Sedative effects" model="data.care_coordination.medication_side_effects.sedative_effects" />
          <Checkbox label="Seizures" model="data.care_coordination.medication_side_effects.seizures" />
          <Checkbox label="Serotonin discontinuation" model="data.care_coordination.medication_side_effects.serotonin_discontinuation" />
          <Checkbox label="Sexual dysfunction" model="data.care_coordination.medication_side_effects.sexual_dysfunction" />
          <Checkbox label="Sleep disturbance" model="data.care_coordination.medication_side_effects.sleep_disturbance" />
          <Checkbox label="Suicidal ideation" model="data.care_coordination.medication_side_effects.suicidal_ideation" />
          <Checkbox label="Tremor" model="data.care_coordination.medication_side_effects.tremor" />
          <Checkbox label="Weight changes" model="data.care_coordination.medication_side_effects.weight_changes" />
          <Checkbox label="Other" model="data.care_coordination.medication_side_effects.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.medication_side_effects.other" is={true}>
          <SmartTextarea useDictation label="Other:" model="data.care_coordination.medication_side_effects.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection>
        <CheckboxGroup label="Side effects addressed by" layout="vertical-dense">
          <Checkbox label="Watchful waiting" model="data.care_coordination.side_effects_addressed_by.watchful_waiting" />
          <Checkbox label="Medication change" model="data.care_coordination.side_effects_addressed_by.medication_change" />
          <Checkbox label="Dose change" model="data.care_coordination.side_effects_addressed_by.dose_change" />
          <Checkbox label="N/A" model="data.care_coordination.side_effects_addressed_by.not_applicable" />
          <Checkbox label="Other" model="data.care_coordination.side_effects_addressed_by.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.side_effects_addressed_by.other" is={true}>
          <SmartTextarea useDictation label="Other:" model="data.care_coordination.side_effects_addressed_by.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection>
        <CheckboxGroup label="Refer to or continue with:" layout="vertical-dense">
          <Checkbox
            label="Psychotherapeutic education/reading on illness"
            model="data.care_coordination.refer_or_continue.psychotherapeutic_education_reading_on_illness"
          />
          <Checkbox label="Family therapy" model="data.care_coordination.refer_or_continue.family_therapy" />
          <Checkbox label="Group therapy" model="data.care_coordination.refer_or_continue.group_therapy" />
          <Checkbox label="Individual therapy" model="data.care_coordination.refer_or_continue.individual_therapy" />
          <Checkbox label="Weight" model="data.care_coordination.refer_or_continue.weight" />
          <Checkbox label="Aims" model="data.care_coordination.refer_or_continue.aims" />
          <Checkbox label="Vitals" model="data.care_coordination.refer_or_continue.vitals" />
          <Checkbox label="Chemical dependency treatment" model="data.care_coordination.refer_or_continue.chemical_dependency_treatment" />
          <Checkbox label="MMSE" model="data.care_coordination.refer_or_continue.mmse" />
          <Checkbox label="Sober living" model="data.care_coordination.refer_or_continue.sober_living" />
          <Checkbox
            label="Admission to an inpatient psychiatric hospital was recommended/offered"
            model="data.care_coordination.refer_or_continue.admission_to_an_inpatient_psychiatric_hospital_was_recommended_offered"
          />
          <Checkbox label="N/A" model="data.care_coordination.refer_or_continue.not_applicable" />
          <Checkbox label="Other" model="data.care_coordination.refer_or_continue.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.refer_or_continue.other" is={true}>
          <SmartTextarea useDictation label="Other:" model="data.care_coordination.refer_or_continue.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection>
        <CheckboxGroup label="Education was provided to the client for the following:" layout="vertical-dense">
          <Checkbox label="Illness" model="data.care_coordination.education_provided.illness" />
          <Checkbox label="Medications" model="data.care_coordination.education_provided.medications" />
          <Checkbox label="Co-Occurring Disorders" model="data.care_coordination.education_provided.co_occurring_disorders" />
          <Checkbox label="Coping skills" model="data.care_coordination.education_provided.coping_skills" />
          <Checkbox label="Other" model="data.care_coordination.education_provided.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.education_provided.other" is={true}>
          <SmartTextarea useDictation label="Other:" model="data.care_coordination.education_provided.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection>
        <CheckboxGroup label="Lab data ordered" layout="vertical-dense">
          <Checkbox label="CBC" model="data.care_coordination.lab_data_ordered.cbc" />
          <Checkbox label="SMAC" model="data.care_coordination.lab_data_ordered.smac" />
          <Checkbox label="TFT’s" model="data.care_coordination.lab_data_ordered.tft_s" />
          <Checkbox label="Lipids" model="data.care_coordination.lab_data_ordered.lipids" />
          <Checkbox label="Serum level:" model="data.care_coordination.lab_data_ordered.serum_level" />
          <Checkbox label="Lithium" model="data.care_coordination.lab_data_ordered.lithium" />
          <Checkbox label="Tegretol" model="data.care_coordination.lab_data_ordered.tegretol" />
          <Checkbox label="Lamictal" model="data.care_coordination.lab_data_ordered.lamictal" />
          <Checkbox label="Valproic acid" model="data.care_coordination.lab_data_ordered.valproic_acid" />
          <Checkbox label="Pregnancy testing" model="data.care_coordination.lab_data_ordered.pregnancy_testing" />
          <Checkbox label="UDS" model="data.care_coordination.lab_data_ordered.uds" />
          <Checkbox label="EKG" model="data.care_coordination.lab_data_ordered.ekg" />
          <Checkbox label="UA" model="data.care_coordination.lab_data_ordered.ua" />
          <Checkbox label="LFTS" model="data.care_coordination.lab_data_ordered.lfts" />
          <Checkbox label="HIV" model="data.care_coordination.lab_data_ordered.hiv" />
          <Checkbox label="RPR" model="data.care_coordination.lab_data_ordered.rpr" />
          <Checkbox label="Vitamin D level" model="data.care_coordination.lab_data_ordered.vitamin_d_level" />
          <Checkbox label="Hepatitis panel" model="data.care_coordination.lab_data_ordered.hepatitis_panel" />
          <Checkbox label="N/A" model="data.care_coordination.lab_data_ordered.not_applicable" />
          <Checkbox label="Other" model="data.care_coordination.lab_data_ordered.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.lab_data_ordered.other" is={true}>
          <SmartTextarea useDictation label="Other:" model="data.care_coordination.lab_data_ordered.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection>
        <CheckboxGroup label="Reason for polypharmacy/ above PDR recommended dosing:" layout="vertical-dense">
          <Checkbox
            label="Insufficient effect of lower doses"
            model="data.care_coordination.polypharmacy_pdr_dosing_reason.insufficient_effect_of_lower_doses"
          />
          <Checkbox label="Patient preference" model="data.care_coordination.polypharmacy_pdr_dosing_reason.patient_preference" />
          <Checkbox
            label="Insufficient improvement on other protocols"
            model="data.care_coordination.polypharmacy_pdr_dosing_reason.insufficient_improvement_on_other_protocols"
          />
          <Checkbox label="Evidence based practice" model="data.care_coordination.polypharmacy_pdr_dosing_reason.evidence_based_practice" />
          <Checkbox label="N/A" model="data.care_coordination.polypharmacy_pdr_dosing_reason.not_applicable" />
          <Checkbox label="Other" model="data.care_coordination.polypharmacy_pdr_dosing_reason.other" />
        </CheckboxGroup>

        <ContextShow when="data.care_coordination.polypharmacy_pdr_dosing_reason.other" is={true}>
          <SmartTextarea useDictation label="Other:" model="data.care_coordination.polypharmacy_pdr_dosing_reason.other_extra" />
        </ContextShow>
      </FormSection>

      <FormSection>
        <RadioGroup
          label="Emergency procedures reviewed:"
          model="data.care_coordination.emergency_procedures_reviewed"
          layout="vertical-dense"
        >
          <Radio label="Yes" value="yes" />
          <Radio label="No" value="no" />
          <Radio label="N/A" value="not_applicable" />
          <Radio label="Other" value="other" />
        </RadioGroup>

        <ContextShow when="data.care_coordination.emergency_procedures_reviewed" is="other">
          <SmartTextarea useDictation label="Other:" model="data.care_coordination.emergency_procedures_reviewed_extra" />
        </ContextShow>
      </FormSection>

      <FormSection>
        <RadioGroup label="Return to clinic:" model="data.care_coordination.return_to_clinic" layout="vertical-dense">
          <Radio label="Days" value="days" />
          <Radio label="Weeks" value="weeks" />
          <Radio label="Months" value="months" />
          <Radio label="As needed" value="as_needed" />
          <Radio label="Other" value="other" />
        </RadioGroup>

        <ContextShow when="data.care_coordination.return_to_clinic" is="days">
          <Input
            label="Number of days"
            model="data.care_coordination.return_to_clinic_extra.number_days"
            suffix="days"
            type="number"
            size={4}
          />
        </ContextShow>

        <ContextShow when="data.care_coordination.return_to_clinic" is="weeks">
          <Input
            label="Number of weeks"
            model="data.care_coordination.return_to_clinic_extra.number_weeks"
            suffix="weeks"
            type="number"
            size={4}
          />
        </ContextShow>

        <ContextShow when="data.care_coordination.return_to_clinic" is="months">
          <Input
            label="Number of months"
            model="data.care_coordination.return_to_clinic_extra.number_months"
            suffix="months"
            type="number"
            size={4}
          />
        </ContextShow>

        <ContextShow when="data.care_coordination.return_to_clinic" is="other">
          <SmartTextarea useDictation label="Other:" model="data.care_coordination.return_to_clinic_extra.other_extra" />
        </ContextShow>
      </FormSection>
    </Grid>
  )
}

export default CareCoordination
