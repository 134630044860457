import React from 'react'
import produce from 'immer'

import { useSettings } from '../../hooks'
import { useUpdate } from '../../hooks/useNewAPI'

import Button from '../Button'

import ClinicalNoteStatus from '../Statuses/ClinicalNoteStatus'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import withSettings from '../../hocs/withSettings'

const TITLE: any = {
  all: 'Clinical Notes',
  individual: 'Individual Note',
  group_note: 'Group Note',
  family_session: 'Family Session',
  case_management: 'Case Management',
}

const columns = (to: Function = () => {}, duplicateFormLink: Function = () => {}, timezone: string, resourceKey?: string[]) => [
  {
    width: 200,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'category',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id, row.original.category)} label={TITLE[value]} />,
  },
  {
    width: 250,
    accessor: 'client',
    Header: 'Client',
    Cell: ({ value }: any) => <TableCell.Profile avatar={value?.avatar} name={value?.name} />,
  },
  {
    width: 150,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <ClinicalNoteStatus status={value} />,
  },
  {
    width: 200,
    accessor: 'started_at',
    Header: 'Session Date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 90,
    accessor: 'duration',
    Header: 'Duration',
    Cell: ({ value }: any) => `${value} min`,
  },
  ...(resourceKey
    ? [
        {
          width: 200,
          Header: 'Tags',
          accessor: 'tags',
          disableFilters: true,
          Cell: ({ value, row }: any) => {
            const { mutateAsync, isLoading }: any = useUpdate({
              name: [...resourceKey, 'clinical-notes', row.original.id],
              url: `/clinical_notes/${row.original.id}`,
              invalidateKeys: [...resourceKey, 'clinical-notes'],
            })

            return (
              <TableCell.Tags
                value={value}
                save={(tagIDs: any, tags: any) => mutateAsync({ tag_ids: tagIDs, tags })}
                isLoading={isLoading}
                smartCategories="clinical_notes"
              />
            )
          },
        },
      ]
    : []),
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
  {
    width: 130,
    id: 'duplicate',
    accessor: 'id',
    Header: '',
    Cell: ({ row }: any) => {
      const duplicated = produce(row.original, (draft: any) => {
        delete draft.documents
        delete draft.documents_attributes
      })

      return <Button label="Duplicate" glyph="add" size={200} link={duplicateFormLink(duplicated)} />
    },
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  help?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  duplicateFormLink?: Function
  batchActionsConfig?: Object
  resourceKey?: string[]
}

const ClinicalNotesTable = (props: Props) => {
  const {
    data,
    duplicateFormLink,
    emptyActions,
    emptyDescription,
    help,
    hiddenColumns,
    isLoading,
    batchActionsConfig,
    localStorageKey,
    title,
    to,
    titleAfter,
    resourceKey, // used for optimistic React Query updates
    ...rest
  } = props

  const { timezone } = useSettings()

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon="clinical_notes"
      data={data}
      columns={columns(to, duplicateFormLink, timezone, resourceKey)}
      hiddenColumns={hiddenColumns}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      help={help}
      {...rest}
    />
  )
}

ClinicalNotesTable.defaultProps = {
  title: 'Clinical Notes',
  emptyDescription: 'No clinical notes added yet',
  localStorageKey: 'clinical_notes',
}

export default withSettings(ClinicalNotesTable)
