import React from 'react'

import { titleCase } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { DropdownMenu, DropdownMenuItem } from '../../components/DropdownMenu'
import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PasswordInput from '../../components/Forms/PasswordInput'
import Status from '../../components/Status'
import Switch from '../../components/Forms/Switch'
import Textarea from '../../components/Forms/Textarea'
import Tooltip from '../../components/Tooltip'
import TreeItem from '../../components/TreeItem'
import URLInput from '../../components/Forms/URLInput'

const DOMAINS = ['behavelink.co', 'bhapp.com']

const RootShortLinkOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'short_links',
    endpoint: '/short_links',
    invalidate: 'short_links',
    options: props,
  })

  const { tenant, timezone } = useSettings()

  const { category } = props

  const [domain, setDomain] = React.useState(DOMAINS[0])

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  const isIncidentReport = data?.category === 'incident_report'
  const isTrialing = tenant?.plan_status === 'trialing'

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header
        icon="short_links"
        title="Short Links"
        subtitle={!isEditable && data?.category && <Status label={titleCase(data.category)} color={isIncidentReport ? 'red' : 'blue'} />}
      />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          className="grid gap-3 px-5 pt-4 pb-6"
          linked={
            isNew && {
              category,
              ...(category === 'ehr' && { tenant_id: tenant.id }),
            }
          }
        >
          <Card className="px-3 pb-2 pt-0">
            <header className="py-2 mt-1">
              <h3>Link Setup</h3>
            </header>

            <FormSection maxWidth="100%">
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a name for this link',
                  },
                }}
              />

              <URLInput
                label="Link To"
                model="link"
                validations={{
                  presence: {
                    message: 'Please enter a link',
                  },
                }}
              />

              <div className={isEditable ? 'block' : 'hidden'}>
                <Input
                  key={domain}
                  label="Short Link"
                  model="code"
                  labelAfter={isEditable && <Button size={100} glyph="reset" label="Generate" className="!ml-auto" onClick={() => {}} />}
                  prefix={
                    isEditable && (
                      <span className="flex items-center font-[500]">
                        <Glyph glyph="public" size={18} className="mr-1.5" />
                        <span>{domain}</span>
                        <span className="opacity-50 inline-block ml-2 mr-1"> / </span>
                      </span>
                    )
                  }
                  validations={{
                    presence: {
                      message: 'Please enter a link',
                    },
                  }}
                  validateOn="blur-change-update-focus"
                />
              </div>

              {!isNew && !isEditable && data && (
                <URLInput label="Short Link" url={`http://localhost:3700/${data.code}`} value={`${data.domain}/${data.code}`} />
              )}

              {/* <Input
                key={domain}
                label="Short Link"
                model="slug"
                labelAfter={isEditable && <Button size={100} glyph="reset" label="Generate" className="!ml-auto" onClick={() => {}} />}
                prefix={
                  <DropdownMenu
                    trigger={
                      <div>
                        <Button
                          type="link"
                          size={200}
                          glyph="public"
                          label={domain}
                          after={<Glyph glyph="triangle_down" size={10} className="!m-1" />}
                        />
                      </div>
                    }
                  >
                    {DOMAINS.map((d) => (
                      <DropdownMenuItem key={d} isActive={d === domain} onClick={() => setDomain(d)} glyph="public" label={d} />
                    ))}
                  </DropdownMenu>
                }
                validations={{
                  presence: {
                    message: 'Please enter a link',
                  },
                }}
                validateOn="blur-change-update-focus"
              /> */}

              <DateTimeInput
                defaultToNow
                label="Expiry Date"
                model="expired_at"
                validations={{
                  presence: {
                    message: 'Please add an expiry date',
                  },
                }}
              />

              <MiniRichTextEditor useQuickText useDictation label="Note" model="description" />
            </FormSection>
          </Card>

          <Card className="px-3">
            <header className="flex items-center flex-nowrap justify-between py-2">
              <div className="flex items-center">
                <h3 className="truncate mr-1">Social Media Cards</h3>
                <Tooltip content="Create custom images and descriptions for your links that display on social media platforms" />
              </div>
              <Switch label={null} withHover={false} size={200} model="is_social_cards_enabled" />
            </header>

            <ContextShow when="is_social_cards_enabled" is={true}>
              <FormSection maxWidth="100%" className="pb-3">
                <div>
                  <Label label="Image" />
                  <Avatar isEditable width="100%" height={200} />
                </div>

                <Input
                  label="Title"
                  model="social_media_title"
                  validations={{
                    length: {
                      maximum: 120,
                      message: 'Please enter a title with less than 120 characters',
                    },
                  }}
                />

                <Textarea
                  label="Description"
                  model="social_media_description"
                  validations={{
                    length: {
                      maximum: 240,
                      message: 'Please enter a description with less than 240 characters',
                    },
                  }}
                />

                <TreeItem title="Show Preview" headerClassName="text-blue-500" withHover={false}>
                  <></>
                </TreeItem>
              </FormSection>
            </ContextShow>
          </Card>

          <Card className="px-3">
            <header className="flex items-center flex-nowrap justify-between py-2">
              <div className="flex items-center">
                <h3 className="truncate mr-1">UTM Tags</h3>
                <Tooltip content="Add tracking codes to your URLs for analytics monitoring" />
              </div>
              <Switch label={null} withHover={false} size={200} model="is_utm_enabled" />
            </header>

            <ContextShow when="is_utm_enabled" is={true}>
              <FormSection maxWidth="100%" className="pb-3">
                <Input label="Referral (ref)" model="utm_ref" />
                <Input label="UTM Source" model="utm_source" />
                <Input label="UTM Medium" model="utm_medium" />
                <Input label="UTM Campaign" model="utm_campaign" />
                <Input label="UTM Term" model="utm_term" />
                <Input label="UTM Content" model="utm_content" autocomplete="off" />
              </FormSection>
            </ContextShow>
          </Card>

          <Card className="px-3">
            <header className="flex items-center flex-nowrap justify-between py-2">
              <div className="flex items-center">
                <h3 className="truncate mr-1">Password Protection</h3>
                <Tooltip content="Secure your links with a password, restricting access to authorized users only" />
              </div>
              <Switch label={null} withHover={false} size={200} model="is_password_enabled" />
            </header>

            <ContextShow when="is_password_enabled" is={true}>
              <FormSection maxWidth="100%" className="pb-3">
                <PasswordInput label="Password" model="secure_pw" autocomplete="off" />
              </FormSection>
            </ContextShow>
          </Card>

          <Card className="px-3">
            <header className="flex items-center flex-nowrap justify-between py-2">
              <div className="flex items-center">
                <h3 className="truncate mr-1">Hide Original Link</h3>
                <Tooltip content="Hide the original URL and display only the short URL to users" />
              </div>
              <Switch label={null} withHover={false} size={200} model="is_cloaking_enabled" />
            </header>

            <ContextShow when="is_cloaking_enabled" is={true}>
              <FormSection className="pb-3 mt-1">
                <Alert glyph="info">
                  <span className="font-[600]">Please note: </span> this feature might not work for all websites. Make sure to test the
                  short link before sharing it.
                </Alert>
              </FormSection>
            </ContextShow>
          </Card>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit Short Link" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />

            <DeleteDialog
              title="Delete Short Link?"
              message="Are you sure you want to delete this Short Link? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button glyph="delete" label="Delete" type="default" color="red" isLoading={isDeleting} fullWidth />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ShortLinkOverlay = withOverlayError(RootShortLinkOverlay)
