import React from 'react'

import { formatURL } from '../../../../utils/functions'

import { DataTable } from '../../../DataTable/DataTable'
import { DEFAULT_FILTERS } from '../../../../constructs/Filters/constants'
import { LinkCell } from '../../../DataTable/cells/LinkCell'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

export const AdminContactsTable = ({ onSelect, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-admin-contacts'],
    endpoint: `/persons`,
    localStorageKey: 'selector_admin_contacts_v1',
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.id} value={data.name} avatar={data.avatar} onClick={onSelect ? () => onSelect(data) : undefined} />
        ),
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <DataTable
      {...tableProps}
      {...rest}
      title="Community Accounts"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      searchBarFilterKey="name"
    />
  )
}

const FILTERS_CONFIG = {
  name: DEFAULT_FILTERS.name,
  slug: {
    label: 'URL Slug',
    type: 'string',
  },
  url: {
    label: 'Website',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Unclaimed', value: 'unclaimed' },
      { label: 'Outreach', value: 'outreach' },
      { label: 'Never Connected', value: 'never_connected' },
      { label: 'Declined', value: 'declined' },
      { label: 'Claimed', value: 'claimed' },
    ],
  },
  business_status: {
    label: 'Business Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ],
  },
  marketing_status: {
    label: 'Marketing Status',
    type: 'multi_select',
    options: [
      { label: 'Marketing', value: 'marketing' },
      { label: 'Non Marketing', value: 'non_marketing' },
    ],
  },
  entity_type: {
    label: 'Type',
    type: 'multi_select',
    options: [
      { label: 'Organization', value: 'organization' },
      { label: 'Individual', value: 'individual' },
    ],
  },
  connected_ehr: {
    label: 'Connected EHR',
    type: 'multi_object',
    endpoint: '/facilities',
    apiKey: 'facilities',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (item: any) => item.name,
  },
  has_ehr: {
    label: 'Has EHR?',
    type: 'boolean',
  },
  show_status: {
    label: 'Visibility',
    type: 'multi_select',
    options: [
      { label: 'Show', value: 'show' },
      { label: 'Hide', value: 'hide' },
    ],
  },
  primary_state: {
    label: 'Primary State',
    type: 'string',
  },
  hubspot_company_id: {
    label: 'Hubspot Company Id',
    type: 'string',
  },
  hubspot_profile_id: {
    label: 'Hubspot Profile Id',
    type: 'string',
  },
  hubspot_deal_id: {
    label: 'Hubspot Deal Id',
    type: 'string',
  },
  // smart_flags: {
  //   label: 'Smart Flags',
  //   type: 'smart_flags',
  //   glyph: 'flag',
  // },
  import_name: {
    label: 'Import Name',
    type: 'string',
  },
  import_file: {
    label: 'Import File',
    type: 'string',
  },
  covered_states: {
    label: 'All Covered State',
    type: 'string',
  },
  duplicate_of: {
    label: 'Duplicate Of',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
