import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { request } from '../../modules/axios'
import { apiUpdate } from '../../modules/api'
import { updateMe } from '../../actions/me'

import Alert from '../Alert'
import Button from '../Button'
import Card from '../Card'
import Form from '../Forms/Form'
import Input from '../Forms/Input'
import Overlay from '../Overlay'
import State from '../State'
import Status from '../Status'
import URLInput from '../Forms/URLInput'

import { NotionRenderer } from '../Notion/NotionRenderer'
import { setHelpCenterTags } from './utils'

export const NotionInternalHelpOverlay = ({ title = '', url = '', helpTag = '', onClose }: any) => {
  const form = React.useRef()
  const location = useLocation()
  const dispatch = useDispatch()

  const [pageId, setPageId] = React.useState('')
  const [notionData, setNotionData] = React.useState(null)

  const [isLoading, setIsLoading] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [valid, setValid] = React.useState(false)

  React.useEffect(() => {
    if (!pageId) return

    const getNotionData = async () => {
      setIsLoading(true)

      try {
        const response = await request.get(`${process.env.BH_NOTION_API_BASE}/api/page/${pageId}`, {
          headers: null,
        })

        setHasError(false)
        setNotionData(response.data)
      } catch (error) {
        setHasError(true)
        setNotionData(null)
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    getNotionData()
  }, [pageId])

  const save = async () => {
    setIsSaving(true)

    const model = form.current.getFormValue()

    const queryParams = new URLSearchParams(location.search)
    const tag: any = helpTag || queryParams.get('internal')

    try {
      const response = await apiUpdate({
        url: '/apps/settings/update_internal_center_tag',
        params: {
          tag: tag,
          page_id: pageId,
          url: model.url,
          name: model.name,
        },
      })

      dispatch(
        updateMe({
          settings: {
            internal_center: response.data,
            internal_center_tags: setHelpCenterTags(response.data),
          },
        }),
      )
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }

    if (onClose) onClose()
  }

  return (
    <Overlay showBackdrop fullheight position="center" maxWidth={54} onClose={onClose}>
      <Overlay.Header title="Link Notion Page" titleAside={<Status color="blue" label={helpTag.replaceAll('_', ' ')} />} glyph="notion" />

      <Overlay.Content>
        <section css={styles.section}>
          <Form getForm={form} onValidationUpdate={setValid}>
            <Input
              label="Title"
              model="name"
              defaultValue={title}
              maxWidth="100%"
              validations={{
                presence: {
                  message: 'Please enter a page title',
                },
              }}
            />

            <URLInput
              label="Notion URL"
              model="url"
              glyph="notion"
              maxWidth="100%"
              defaultValue={url}
              isDisabled={isLoading}
              onUpdate={({ value }: any) => {
                if (value.length < 32) return

                const segments = value.split('/')
                const lastSlug = segments[segments.length - 1]

                const slugSegments = lastSlug.split('-')
                const notionPageId = slugSegments[slugSegments.length - 1]

                if (notionPageId.length !== 32) return

                setPageId(notionPageId)
              }}
              validations={{
                presence: {
                  message: 'Please enter a valid public Notion URL',
                },
              }}
            />

            {!notionData && (
              <Card css={styles.emptyWrapper}>
                <State
                  isEmpty
                  isLoading={isLoading}
                  glyph="notion"
                  title="Notion Page Preview"
                  css={styles.state}
                  emptyDescription={
                    hasError ? (
                      <Alert type="negative" glyph="circle_error">
                        This URL doesn't link to a valid or public Notion page.
                      </Alert>
                    ) : (
                      'Please enter a valid public Notion URL to preview'
                    )
                  }
                />
              </Card>
            )}

            {notionData && !hasError && (
              <Card css={styles.notionWrapper}>
                <NotionRenderer fullPage disableHeader showTableOfContents recordMap={notionData} darkMode={false} />
              </Card>
            )}
          </Form>
        </section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save"
          glyph="check"
          color="green"
          type="primary"
          onClick={save}
          isLoading={isSaving}
          isDisabled={hasError || !notionData || !valid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const styles = {
  section: {
    height: '100%',
    padding: '1rem',

    form: {
      height: '100%',
      display: 'grid',
      gridGap: '1rem',
      gridTemplateRows: 'min-content min-content 1fr',
    },
  },

  emptyWrapper: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
  },

  notionWrapper: {
    overflow: 'auto',

    '.notion-app': {
      pointerEvents: 'none !important',
    },

    'main.notion-page': {
      marginTop: '32px !important',
    },
  },

  state: {
    '& > div': {
      maxWidth: '450px !important',
    },
  },
}
