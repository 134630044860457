import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import Notifications from '../../modules/notifications'

import Button from '../../components/Button'
import Card from '../../components/Card'

import Form from '../../components/Forms/Form'
import DataArray from '../../components/Forms/DataArray'
import Icon from '../../components/Icon'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import EmailInput from '../../components/Forms/EmailInput'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'
import FormSection from '../../components/Forms/FormSection'

import { useCreate } from '../../hooks/useNewAPI'

const RootOrganizationInviteRequestOverlay = (props: any) => {
  const { orgId } = props

  const { close, data, form, onValidationUpdate, isInvalid, isOverlayLoading, isSaving, initialModel } = useOverlay({
    name: 'organizations-invite',
    endpoint: `/organizations`,
    invalidate: 'organizations',
    disableParentRequest: true,
    options: {
      dataID: props.dataID || orgId,
      ...props,
    },
  })

  const { mutateAsync: sendInvite, isLoading: isSendingInvite } = useCreate({
    name: ['send_community_profile_request'],
    url: `/organizations/${orgId}/send_invite_request`,
    invalidateKeys: ['community-entity', 'organizations', 'organization'],
  })

  const { timezone } = useSettings()

  const handleInvite = async () => {
    try {
      await sendInvite(form.current.getFormValue())
      Notifications.send('Request to Partner Up has been sent successfully', 'positive')
    } catch (error) {
      console.error(error)
      Notifications.send('Request to Partner Up was unsuccessful', 'negative')
    }

    close()
  }

  if (!data || isOverlayLoading) return <OverlayLoader position="center" />

  return (
    <Overlay showBackdrop closeOnBackdrop position="center" onClose={close} maxWidth={48}>
      <Overlay.Header title="Request to Partner Up" icon="community_invites" />

      <Overlay.Content>
        <Form isEditable initialModel={initialModel} getForm={form} timezone={timezone} onValidationUpdate={onValidationUpdate}>
          <Section>
            <FormSection maxWidth="100%">
              <Grid gap="0.75rem">
                <div className="grid grid-cols-2 gap-5">
                  <Card className="pr-3 py-2 flex items-center">
                    <div className="w-12 flex items-center justify-center shrink-0">
                      <Icon icon="organizations" size={22} />
                    </div>

                    <div>
                      <h3 className="font-[700] text-[1.15rem]">{data.name}</h3>
                      <div>This is an internal organization that is not yet a member of the Behave Health Community.</div>
                    </div>
                  </Card>

                  <Card className="pr-3 py-2 flex items-center">
                    <div className="w-12 flex items-center justify-center shrink-0">
                      <Icon icon="community" size={22} />
                    </div>

                    <div>
                      <h3 className="font-[700] text-[1.15rem]">Next Step</h3>
                      <div>Complete this form and Behave Health will reach out and invite them to partner up with you.</div>
                    </div>
                  </Card>
                </div>

                <Textarea
                  label="Describe your relationship with this organization and why you want to partner up"
                  model="request_notes"
                  validations={{
                    presence: {
                      message: 'Please write a message',
                    },
                  }}
                />

                {orgId && (
                  <MultiObjectSelector
                    icon="contacts"
                    label="Contacts"
                    model="request_contacts"
                    type="organization.contacts"
                    dependentValue={orgId}
                    description={`Select contacts to send the invite to, or click the "Add Custom Contact" button to add a custom contact.`}
                  />
                )}

                <DataArray model="request_custom_contacts">
                  {({ orderedIds, add, remove }: any) => (
                    <>
                      {orderedIds.map((id: any, index: number) => (
                        <Card key={id} className="px-4 pt-3 pb-4">
                          <div className="flex justify-between">
                            <div className="font-[600] mb-2">Contact #{index + 1}</div>

                            <Button
                              hideLabel
                              glyph="cross"
                              size={100}
                              color="text"
                              type="minimal"
                              onClick={() => {
                                remove(id)
                              }}
                              css={{
                                width: '16px !important',
                                height: '16px !important',
                                svg: { margin: '0 !important' },
                              }}
                            />
                          </div>

                          <Grid gap="0.75rem">
                            <Input
                              label="Name"
                              model={`${id}.name`}
                              validations={{
                                presence: { message: 'Please enter a name' },
                              }}
                            />

                            <EmailInput
                              label="Email"
                              model={`${id}.email`}
                              description="Please include either a contact email or phone number"
                              validations={{
                                presence: { message: 'Please enter an email address' },
                              }}
                            />

                            <PhoneInput label="Phone Number" model={`${id}.phone_no`} />
                          </Grid>
                        </Card>
                      ))}

                      <div>
                        <Button label="Add Custom Contact" glyph="add" type="default" display="inline-flex" size={200} onClick={add} />
                      </div>
                    </>
                  )}
                </DataArray>
              </Grid>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Send Invite Request to Behave Health"
          glyph="tick_circle"
          type="primary"
          color="green"
          onClick={handleInvite}
          isLoading={isSaving || isSendingInvite}
          isDisabled={isInvalid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const OrganizationInviteRequestOverlay = withOverlayError(RootOrganizationInviteRequestOverlay)
