import React from 'react'
import produce from 'immer'

import { COLORS } from '../../theme'

import Button from '../../components/Button'
import Flex from '../../components/Flex'
import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'

import { NotionPage } from './NotionPage'
import { NotionFeedback } from './NotionFeedback'

type Props = {
  className?: string
  feedbackCategory?: string
  homeID: string
  homeTitle?: string
  onPageChange?: (page: NotionPage) => void
  pageID?: string
  pageTitle?: string
  actionsAfter?: React.ReactNode
}

export type NotionPage = {
  id: string
  title?: string
}

const DEBUG = false

export const NotionBrowser: React.FC<Props> = (props) => {
  const { homeID, homeTitle = '🏡 Home', onPageChange, pageID, pageTitle, className, feedbackCategory, actionsAfter } = props

  // Setup
  const startPage = {
    id: pageID || homeID,
    title: pageTitle || homeTitle,
  }

  const homePage = {
    id: homeID,
    title: homeTitle,
  }

  const [navStack, setNavStack] = React.useState<NotionPage[]>([startPage])
  const [currentPage, setCurrentPage] = React.useState<NotionPage>(startPage)

  const canGoHome = currentPage.id !== homeID
  const canGoBack = navStack.length >= 2
  const showNav = canGoHome || canGoBack

  // Functions
  const goHome = () => {
    if (!canGoHome) return

    setCurrentPage(homePage)
    setNavStack((current) => [...current, homePage])
  }

  const goBack = () => {
    if (!canGoBack) return

    const previous = navStack[navStack.length - 2]

    setCurrentPage(previous)

    setNavStack(
      produce(navStack, (draft) => {
        draft.pop()
      }),
    )
  }

  const jumpTo = (index: number) => {
    const newPage = navStack[index]

    if (!newPage) return

    setCurrentPage(newPage)

    setNavStack(
      produce(navStack, (draft) => {
        draft.length = index + 1
      }),
    )
  }

  const navigateTo = (item: NotionPage) => {
    if (!item) return

    if (navStack[navStack.length - 1].id === item.id) return

    setCurrentPage(item)
    setNavStack((current: NotionPage[]) => [...current, item])
  }

  // Update current page based on props
  React.useEffect(() => {
    if (!(pageID && pageTitle)) return

    setCurrentPage({ id: pageID, title: pageTitle })
  }, [pageID, pageTitle])

  // Callback
  React.useEffect(() => {
    if (!onPageChange) return

    onPageChange(currentPage)
  }, [currentPage])

  if (!homeID) {
    return <div>Set up a home page link</div>
  }

  return (
    <div className={className}>
      <nav css={styles.nav}>
        <Flex centerY gap={4}>
          <Button
            label="Back"
            glyph="chevron_left"
            type="default"
            size={200}
            onClick={goBack}
            css={styles.backButton}
            isDisabled={!canGoHome || !canGoBack}
          />

          {DEBUG && (
            <Dropdown trigger={<Button hideLabel glyph="menu" type="default" size={200} />}>
              {navStack.map((item, index) => {
                if (index === navStack.length - 1) return null

                return <DropdownItem key={item.id} label={item.title} onClick={() => jumpTo(index)} />
              })}
            </Dropdown>
          )}
        </Flex>

        <Flex centerY gap={4}>
          <Button label="Home" glyph="home" type="default" size={200} onClick={goHome} isDisabled={!canGoHome} />

          {actionsAfter}
        </Flex>
      </nav>

      <NotionPage id={currentPage.id} navigateTo={navigateTo} />

      {feedbackCategory && <NotionFeedback pageID={currentPage.id} category={feedbackCategory} css={styles.feedback} />}
    </div>
  )
}

const styles = {
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  backButton: {
    marginRight: '0.5rem',
  },

  feedback: {
    position: 'sticky',
    bottom: 0,
    background: COLORS.white,
    borderTop: `1px solid ${COLORS.divider}`,
    padding: '0.6rem 0',
    marginTop: '1rem',
  },
}
