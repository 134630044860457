import React from 'react'
import { useLocation } from 'react-router-dom'

import Alert from '../../components/Alert'
import Card from '../../components/Card'
import CardContent from '../../components/CardContent'
import CardHeader from '../../components/CardHeader'
import CardSubtitle from '../../components/CardSubtitle'
import CardTitle from '../../components/CardTitle'
import DataList from '../../components/DataList'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SmartTextarea from '../../components/Forms/SmartTextarea'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const RootWHOQOLOverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="The World Health Organization Quality of Life (WHOQOL)-BREF"
      category="clinical_measurement"
      subcategory="whoqol"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <p>
          The following questions ask how you feel about your quality of life, health, or other areas of your life. I will read out each
          question to you, along with the response options. <b>Please choose the answer that appears most appropriate.</b> If you are unsure
          about which response to give to a question, the first response you think of is often the best one.
        </p>
        <p>
          Please keep in mind your standards, hopes, pleasures and concerns. We ask that you think about your life{' '}
          <b>in the last four weeks</b>.
        </p>
      </Section>

      <Divider />

      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <Questionnaire />
      </Section>

      <Divider />

      <Section title="Sources" headingType="h2">
        <p>
          <b>Copyright:</b> World Health Organization 2004
        </p>
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState({
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    q5: 0,
    q6: 0,
    q7: 0,
    q8: 0,
    q9: 0,
    q10: 0,
    q11: 0,
    q12: 0,
    q13: 0,
    q14: 0,
    q15: 0,
    q16: 0,
    q17: 0,
    q18: 0,
    q19: 0,
    q20: 0,
    q21: 0,
    q22: 0,
    q23: 0,
    q24: 0,
    q25: 0,
    q26: 0,
  })

  const scoreDimension1 = 6 - score.q3 + (6 - score.q4) + score.q10 + score.q15 + score.q16 + score.q17 + score.q18
  const scoreDimension2 = score.q5 + score.q6 + score.q7 + score.q11 + score.q19 + (6 - score.q26)
  const scoreDimension3 = score.q20 + score.q21 + score.q22
  const scoreDimension4 = score.q8 + score.q9 + score.q12 + score.q13 + score.q14 + score.q23 + score.q24 + score.q25

  const { isPortal } = useSettings()

  return (
    <>
      <Grid gap="1.5rem">
        <RadioGroup
          label="1. How would you rate your quality of life?"
          model="data.questionnaire.q1"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q1: state.value }))}
        >
          <Radio label="1 = Very poor" value={1} />
          <Radio label="2 = Poor" value={2} />
          <Radio label="3 = Neither poor nor good" value={3} />
          <Radio label="4 = Good" value={4} />
          <Radio label="5 = Very good" value={5} />
        </RadioGroup>

        <RadioGroup
          label="2. How satisfied are you with your health?"
          model="data.questionnaire.q2"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q2: state.value }))}
        >
          <Radio label="1 = Very dissatisfied" value={1} />
          <Radio label="2 = Dissatisfied" value={2} />
          <Radio label="3 = Neither satisfied nor dissatisfied" value={3} />
          <Radio label="4 = Satisfied" value={4} />
          <Radio label="5 = Very satisfied" value={5} />
        </RadioGroup>

        <RadioGroup
          label="3. To what extent do you feel that physical pain prevents you from doing what you need to do?"
          model="data.questionnaire.q3"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q3: state.value }))}
        >
          <Radio label="5 = Not at all" value={5} />
          <Radio label="4 = A little" value={4} />
          <Radio label="3 = A moderate amount" value={3} />
          <Radio label="2 = Very much" value={2} />
          <Radio label="1 = An extreme amount" value={1} />
        </RadioGroup>

        <RadioGroup
          label="4. How much do you need any medical treatment to function in your daily life?"
          model="data.questionnaire.q4"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q4: state.value }))}
        >
          <Radio label="5 = Not at all" value={5} />
          <Radio label="4 = A little" value={4} />
          <Radio label="3 = A moderate amount" value={3} />
          <Radio label="2 = Very much" value={2} />
          <Radio label="1 = An extreme amount" value={1} />
        </RadioGroup>

        <RadioGroup
          label="5. How much do you enjoy life?"
          model="data.questionnaire.q5"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q5: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little" value={2} />
          <Radio label="3 = A moderate amount" value={3} />
          <Radio label="4 = Very much" value={4} />
          <Radio label="5 = An extreme amount" value={5} />
        </RadioGroup>

        <RadioGroup
          label="6. To what extent do you feel your life to be meaningful?"
          model="data.questionnaire.q6"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q6: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little" value={2} />
          <Radio label="3 = A moderate amount" value={3} />
          <Radio label="4 = Very much" value={4} />
          <Radio label="5 = An extreme amount" value={5} />
        </RadioGroup>

        <RadioGroup
          label="7. How well are you able to concentrate?"
          model="data.questionnaire.q7"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q7: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little" value={2} />
          <Radio label="3 = A moderate amount" value={3} />
          <Radio label="4 = Very much" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="8. How safe do you feel in your daily life?"
          model="data.questionnaire.q8"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q8: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little" value={2} />
          <Radio label="3 = A moderate amount" value={3} />
          <Radio label="4 = Very much" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="9. How healthy is your physical environment?"
          model="data.questionnaire.q9"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q9: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little" value={2} />
          <Radio label="3 = A moderate amount" value={3} />
          <Radio label="4 = Very much" value={4} />
          <Radio label="5 = Extremely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="10. Do you have enough energy for everyday life?"
          model="data.questionnaire.q10"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q10: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Mostly" value={4} />
          <Radio label="5 = Completely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="11. Are you able to accept your bodily appearance?"
          model="data.questionnaire.q11"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q11: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Mostly" value={4} />
          <Radio label="5 = Completely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="12. Have you enough money to meet your needs?"
          model="data.questionnaire.q12"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q12: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Mostly" value={4} />
          <Radio label="5 = Completely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="13. How available to you is the information that you need in your day-to-day life?"
          model="data.questionnaire.q13"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q13: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Mostly" value={4} />
          <Radio label="5 = Completely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="14. To what extent do you have the opportunity for leisure activities?"
          model="data.questionnaire.q14"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q14: state.value }))}
        >
          <Radio label="1 = Not at all" value={1} />
          <Radio label="2 = A little" value={2} />
          <Radio label="3 = Moderately" value={3} />
          <Radio label="4 = Mostly" value={4} />
          <Radio label="5 = Completely" value={5} />
        </RadioGroup>

        <RadioGroup
          label="15. How well are you able to get around?"
          model="data.questionnaire.q15"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q15: state.value }))}
        >
          <Radio label="1 = Very poor" value={1} />
          <Radio label="2 = Poor" value={2} />
          <Radio label="3 = Neither poor nor good" value={3} />
          <Radio label="4 = Good" value={4} />
          <Radio label="5 = Very good" value={5} />
        </RadioGroup>

        <RadioGroup
          label="16. How satisfied are you with your sleep?"
          model="data.questionnaire.q16"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q16: state.value }))}
        >
          <Radio label="1 = Very dissatisfied" value={1} />
          <Radio label="2 = Dissatisfied" value={2} />
          <Radio label="3 = Neither satisfied nor dissatisfied" value={3} />
          <Radio label="4 = Satisfied" value={4} />
          <Radio label="5 = Very satisfied" value={5} />
        </RadioGroup>

        <RadioGroup
          label="17. How satisfied are you with your ability to perform your daily living activities?"
          model="data.questionnaire.q17"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q17: state.value }))}
        >
          <Radio label="1 = Very dissatisfied" value={1} />
          <Radio label="2 = Dissatisfied" value={2} />
          <Radio label="3 = Neither satisfied nor dissatisfied" value={3} />
          <Radio label="4 = Satisfied" value={4} />
          <Radio label="5 = Very satisfied" value={5} />
        </RadioGroup>

        <RadioGroup
          label="18. How satisfied are you with your capacity for work?"
          model="data.questionnaire.q18"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q18: state.value }))}
        >
          <Radio label="1 = Very dissatisfied" value={1} />
          <Radio label="2 = Dissatisfied" value={2} />
          <Radio label="3 = Neither satisfied nor dissatisfied" value={3} />
          <Radio label="4 = Satisfied" value={4} />
          <Radio label="5 = Very satisfied" value={5} />
        </RadioGroup>

        <RadioGroup
          label="19. How satisfied are you with yourself?"
          model="data.questionnaire.q19"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q19: state.value }))}
        >
          <Radio label="1 = Very dissatisfied" value={1} />
          <Radio label="2 = Dissatisfied" value={2} />
          <Radio label="3 = Neither satisfied nor dissatisfied" value={3} />
          <Radio label="4 = Satisfied" value={4} />
          <Radio label="5 = Very satisfied" value={5} />
        </RadioGroup>

        <RadioGroup
          label="20. How satisfied are you with your personal relationships?"
          model="data.questionnaire.q20"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q20: state.value }))}
        >
          <Radio label="1 = Very dissatisfied" value={1} />
          <Radio label="2 = Dissatisfied" value={2} />
          <Radio label="3 = Neither satisfied nor dissatisfied" value={3} />
          <Radio label="4 = Satisfied" value={4} />
          <Radio label="5 = Very satisfied " value={5} />
        </RadioGroup>

        <RadioGroup
          label="21. How satisfied are you with your sex life?"
          model="data.questionnaire.q21"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q21: state.value }))}
        >
          <Radio label="1 = Very dissatisfied" value={1} />
          <Radio label="2 = Dissatisfied" value={2} />
          <Radio label="3 = Neither satisfied nor dissatisfied" value={3} />
          <Radio label="4 = Satisfied" value={4} />
          <Radio label="5 = Very satisfied " value={5} />
        </RadioGroup>

        <RadioGroup
          label="22. How satisfied are you with the support you get from your friends?"
          model="data.questionnaire.q22"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q22: state.value }))}
        >
          <Radio label="1 = Very dissatisfied" value={1} />
          <Radio label="2 = Dissatisfied" value={2} />
          <Radio label="3 = Neither satisfied nor dissatisfied" value={3} />
          <Radio label="4 = Satisfied" value={4} />
          <Radio label="5 = Very satisfied " value={5} />
        </RadioGroup>

        <RadioGroup
          label="23. How satisfied are you with the conditions of your living place?"
          model="data.questionnaire.q23"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q23: state.value }))}
        >
          <Radio label="1 = Very dissatisfied" value={1} />
          <Radio label="2 = Dissatisfied" value={2} />
          <Radio label="3 = Neither satisfied nor dissatisfied" value={3} />
          <Radio label="4 = Satisfied" value={4} />
          <Radio label="5 = Very satisfied " value={5} />
        </RadioGroup>

        <RadioGroup
          label="24. How satisfied are you with your access to health services?"
          model="data.questionnaire.q24"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q24: state.value }))}
        >
          <Radio label="1 = Very dissatisfied" value={1} />
          <Radio label="2 = Dissatisfied" value={2} />
          <Radio label="3 = Neither satisfied nor dissatisfied" value={3} />
          <Radio label="4 = Satisfied" value={4} />
          <Radio label="5 = Very satisfied " value={5} />
        </RadioGroup>

        <RadioGroup
          label="25. How satisfied are you with your transport?"
          model="data.questionnaire.q25"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q25: state.value }))}
        >
          <Radio label="1 = Very dissatisfied" value={1} />
          <Radio label="2 = Dissatisfied" value={2} />
          <Radio label="3 = Neither satisfied nor dissatisfied" value={3} />
          <Radio label="4 = Satisfied" value={4} />
          <Radio label="5 = Very satisfied " value={5} />
        </RadioGroup>

        <RadioGroup
          label="26. How often do you have negative feelings such as blue mood, despair, anxiety, depression?"
          model="data.questionnaire.q26"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q26: state.value }))}
        >
          <Radio label="5 = Never" value={5} />
          <Radio label="4 = Seldom" value={4} />
          <Radio label="3 = Quite often" value={3} />
          <Radio label="2 = Very often" value={2} />
          <Radio label="1 = Always" value={1} />
        </RadioGroup>

        <SmartTextarea useDictation label="Do you have any comments about the assessment?" model="data.questionnaire.comments" />
      </Grid>

      {!isPortal && (
        <>
          <Divider />

          <Grid gap="1.5rem">
            <Card>
              <CardHeader>
                <CardTitle title="Dimension 1" />
                <CardSubtitle subtitle="(6-Q3) + (6-Q4) + Q10 + Q15 + Q16 + Q17 + Q18" />
              </CardHeader>

              <CardContent>
                {scoreDimension1 ? (
                  <DataList layout="horizontal">
                    <DataList.Item label="Raw Score" value={scoreDimension1} />
                    <DataList.Item label="4–20 Score" value={dimension1_4_20_transform[scoreDimension1]} />
                    <DataList.Item label="0–100 Score" value={dimension1_0_100_transform[scoreDimension1]} />
                  </DataList>
                ) : (
                  <Alert glyph="info" children="Please answer all questions to calculate the scores" />
                )}
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle title="Dimension 2" />
                <CardSubtitle subtitle="Q5 + Q6 + Q7 + Q11 + Q19 + (6-Q26)" />
              </CardHeader>

              <CardContent>
                {scoreDimension2 ? (
                  <DataList layout="horizontal">
                    <DataList.Item label="Raw Score" value={scoreDimension2} />
                    <DataList.Item label="4–20 Score" value={dimension2_4_20_transform[scoreDimension2]} />
                    <DataList.Item label="0–100 Score" value={dimension2_0_100_transform[scoreDimension2]} />
                  </DataList>
                ) : (
                  <Alert glyph="info" children="Please answer all questions to calculate the scores" />
                )}
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle title="Dimension 3" />
                <CardSubtitle subtitle="Q20 + Q21 + Q22" />
              </CardHeader>

              <CardContent>
                {scoreDimension3 ? (
                  <DataList layout="horizontal">
                    <DataList.Item label="Raw Score" value={scoreDimension3} />
                    <DataList.Item label="4–20 Score" value={dimension3_4_20_transform[scoreDimension3]} />
                    <DataList.Item label="0–100 Score" value={dimension3_0_100_transform[scoreDimension3]} />
                  </DataList>
                ) : (
                  <Alert glyph="info" children="Please answer all questions to calculate the scores" />
                )}
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle title="Dimension 4" />
                <CardSubtitle subtitle="Q8 + Q9 + Q12 + Q13 + Q14 + Q23 + Q24 + Q25" />
              </CardHeader>

              <CardContent>
                {scoreDimension4 ? (
                  <DataList layout="horizontal">
                    <DataList.Item label="Raw Score" value={scoreDimension4} />
                    <DataList.Item label="4–20 Score" value={dimension4_4_20_transform[scoreDimension4]} />
                    <DataList.Item label="0–100 Score" value={dimension4_0_100_transform[scoreDimension4]} />
                  </DataList>
                ) : (
                  <Alert glyph="info" children="Please answer all questions to calculate the scores" />
                )}
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </>
  )
}

const dimension1_4_20_transform: any = {
  7: 4,
  8: 5,
  9: 5,
  10: 6,
  11: 6,
  12: 7,
  13: 7,
  14: 8,
  15: 9,
  16: 9,
  17: 10,
  18: 10,
  19: 11,
  20: 11,
  21: 12,
  22: 13,
  23: 13,
  24: 14,
  25: 14,
  26: 15,
  27: 15,
  28: 16,
  29: 17,
  30: 17,
  31: 18,
  32: 18,
  33: 19,
  34: 19,
  35: 20,
}

const dimension1_0_100_transform: any = {
  7: 0,
  8: 6,
  9: 6,
  10: 13,
  11: 13,
  12: 19,
  13: 19,
  14: 25,
  15: 31,
  16: 31,
  17: 38,
  18: 38,
  19: 44,
  20: 44,
  21: 50,
  22: 56,
  23: 56,
  24: 63,
  25: 63,
  26: 69,
  27: 69,
  28: 75,
  29: 81,
  30: 81,
  31: 88,
  32: 88,
  33: 94,
  34: 94,
  35: 100,
}

const dimension2_4_20_transform: any = {
  6: 4,
  7: 5,
  8: 5,
  9: 6,
  10: 7,
  11: 7,
  12: 8,
  13: 9,
  14: 9,
  15: 10,
  16: 11,
  17: 11,
  18: 12,
  19: 13,
  20: 13,
  21: 14,
  22: 15,
  23: 15,
  24: 16,
  25: 17,
  26: 17,
  27: 18,
  28: 19,
  29: 19,
  30: 20,
}

const dimension2_0_100_transform: any = {
  6: 0,
  7: 6,
  8: 6,
  9: 13,
  10: 19,
  11: 19,
  12: 25,
  13: 31,
  14: 31,
  15: 38,
  16: 44,
  17: 44,
  18: 50,
  19: 56,
  20: 56,
  21: 63,
  22: 69,
  23: 69,
  24: 75,
  25: 81,
  26: 81,
  27: 88,
  28: 94,
  29: 94,
  30: 100,
}

const dimension3_4_20_transform: any = {
  3: 4,
  4: 5,
  5: 7,
  6: 8,
  7: 9,
  8: 11,
  9: 12,
  10: 13,
  11: 15,
  12: 16,
  13: 17,
  14: 19,
  15: 20,
}

const dimension3_0_100_transform: any = {
  3: 0,
  4: 6,
  5: 19,
  6: 25,
  7: 31,
  8: 44,
  9: 50,
  10: 56,
  11: 69,
  12: 75,
  13: 81,
  14: 94,
  15: 100,
}

const dimension4_4_20_transform: any = {
  8: 4,
  9: 5,
  10: 5,
  11: 6,
  12: 6,
  13: 7,
  14: 7,
  15: 8,
  16: 8,
  17: 9,
  18: 9,
  19: 10,
  20: 10,
  21: 11,
  22: 11,
  23: 12,
  24: 12,
  25: 13,
  26: 13,
  27: 14,
  28: 14,
  29: 15,
  30: 15,
  31: 16,
  32: 16,
  33: 17,
  34: 17,
  35: 18,
  36: 18,
  37: 19,
  38: 19,
  39: 20,
  40: 20,
}

const dimension4_0_100_transform: any = {
  8: 0,
  9: 6,
  10: 6,
  11: 13,
  12: 13,
  13: 19,
  14: 19,
  15: 25,
  16: 25,
  17: 31,
  18: 31,
  19: 38,
  20: 38,
  21: 44,
  22: 44,
  23: 50,
  24: 50,
  25: 56,
  26: 56,
  27: 63,
  28: 63,
  29: 69,
  30: 69,
  31: 75,
  32: 75,
  33: 81,
  34: 81,
  35: 88,
  36: 88,
  37: 94,
  38: 94,
  39: 100,
  40: 100,
}

export const WHOQOLOverlay = withOverlayError(RootWHOQOLOverlay)
