import { request, refreshToken as refresh } from '../axios'

export const get = async (url: string, data: any = {}, headers: any = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request.get(url, { params: data, headers })
      resolve(response.data)
    } catch (errors) {
      console.error(errors)
      reject(errors)
    }
  })
}

export const update = async (url: string, data: any) => {
  try {
    const response = await request.put(url, data)
    return response.data
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const create = async (url: string, data?: any, headers?: any) => {
  try {
    const response = await request.post(url, data, headers ? { headers } : undefined)
    return response.data
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const destroy = async (url: string) => {
  try {
    const response = await request.delete(url)
    return response.data
  } catch (error: any) {
    console.error(error)
    throw error
  }
}

export const refreshToken = refresh
