import React from 'react'

import withSettings from '../../../hocs/withSettings'

import Alert from '../../../components/Alert'
import Flex from '../../../components/Flex'
import Grid from '../../../components/Grid'
import Loader from '../../../components/Loader'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import Workflow from '../../../components/Workflow/Workflow'

import { Text } from '../../../components/Typography'

import { useGet } from '../../../hooks/useNewAPI'

const ClientPortalDischarge = ({ data, setData, client, setStatus, firstName, timezone }: any) => {
  const form = React.useRef()
  const [valid, setValid] = React.useState()

  const { goNext }: any = React.useContext(Workflow.Context)

  const { data: user, isLoading }: any = useGet({
    name: ['client', client?.id, 'user'],
    url: `/residents/${client?.id}/user`,
  })

  const handleUpdate = () => {
    setData(form.current?.getFormValue())
    valid ? setStatus('completed') : setStatus('todo')
  }

  if (isLoading)
    return (
      <Flex centerX centerY>
        <Loader />
      </Flex>
    )

  if (!user)
    return (
      <Alert type="neutral" glyph="info">
        {client?.first_name} has not registered for their MyAccount yet. There is no Client Portal Access to Revoke.
      </Alert>
    )

  if (user.status === 'blocked') {
    return (
      <Grid gap="1rem">
        <Alert glyph="warning" type="negative">
          {client?.first_name}'s access to the Client Portal is Blocked. There is no further action to take. Please get in touch with your
          Administrator for further assistance.
        </Alert>
      </Grid>
    )
  }

  if (client?.client_portal_status === 'blocked') {
    return (
      <Grid gap="1rem">
        <Alert glyph="warning" type="negative">
          {client?.first_name}'s access to the Client Portal is Revoked. There is no further action to take. You can always re-instate it
          from the <strong>Client Portal</strong> page.
        </Alert>
      </Grid>
    )
  }

  return (
    <Form
      getForm={form}
      initialModel={data}
      onUpdate={handleUpdate}
      onValidationUpdate={setValid}
      timezone={timezone}
      defaultDebounce={300}
    >
      <FormSection>
        <Text>{`Revoke ${client?.first_name}'s Client Portal Access:`}</Text>

        <CheckboxGroup>
          <Checkbox label="Revoke Client Portal Access" model="remove_client_portal_access" />
        </CheckboxGroup>

        <Alert glyph="circle_error" type="negative">
          Access to the Client Portal will be blocked immediately after Discharging. You can re-enable Portal Access at any time.
        </Alert>

        <Workflow.Buttons>
          <Workflow.ContinueButton
            onClick={() => {
              handleUpdate()
              goNext()
            }}
            isDisabled={!valid}
          />
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default withSettings(ClientPortalDischarge)
