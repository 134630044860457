import React from 'react'

import Button from '../Button'

import { MeetingContext } from '../Meeting/context'

export const JoinMeetingButton = (props: any) => {
  return null

  // const { label = 'Join Meeting Room', meetingID, meetingName, type = 'default', ...rest } = props

  // const { isMeetingActive, joinMeetingByID } = React.useContext(MeetingContext)

  // if (!meetingID) return null

  // return (
  //   <Button
  //     label={label}
  //     type={type}
  //     icon="telehealth"
  //     isDisabled={isMeetingActive}
  //     onClick={() => joinMeetingByID(meetingID, meetingName)}
  //     permission="telehealth.view"
  //     {...rest}
  //   />
  // )
}
