import React from 'react'
import { v4 as uuid } from 'uuid'
import isEqual from 'react-fast-compare'
import size from 'lodash/size'
import isFinite from 'lodash/isFinite'
import isString from 'lodash/isString'

import { Worksheet as BHWorksheet } from '../../../../components/Worksheet/Worksheet'

import Alert from '../../../../components/Alert'
import Button from '../../../../components/Button'
import Card from '../../../../components/Card'
import Form from '../../../../components/Forms/Form'
import Overlay from '../../../../components/Overlay'
import Section from '../../../../components/Section'
import State from '../../../../components/State'
import SummonOverlay from '../../../../components/SummonOverlay'
import Textarea from '../../../../components/Forms/Textarea'
import Tooltip from '../../../../components/Tooltip'

import { countWord } from '../../../../utils/functions'

export const SingleSelectInputNumberEditor = (props: any) => {
  const { activeElement, editElementConfig } = props

  const { single_select_input_values } = activeElement.config

  const [updated, setUpdated]: any = React.useState(null)

  return (
    <>
      <SummonOverlay
        overlay={
          <BatchEditOverlay
            initialOptions={single_select_input_values}
            onUpdate={(options) => {
              editElementConfig({
                uuid: activeElement.uuid,
                config: {
                  single_select_input_values: options,
                },
              })

              setUpdated(Math.random())
            }}
          />
        }
      >
        <Button label="Batch Edit Options" glyph="multi_select" size={200} />
      </SummonOverlay>

      <BHWorksheet
        asCard
        withFullScreenToggle
        withWrapToggle={false}
        withShowInvalidToggle={false}
        key={`updated-${updated}`}
        title="Options"
        allow="create-update-delete"
        titleClassName="!mr-auto"
        columns={COLUMNS}
        initialData={single_select_input_values}
        defaultNewRow={DEFAULT_NEW_ROW}
        onDataUpdate={(dataMap: any, dataIds: any) => {
          const newOptions: any = []

          if (!dataMap || !dataIds) return newOptions

          for (let i = 0; i < dataIds.length; i++) {
            const _id = dataIds[i]
            const row = dataMap[_id]

            newOptions.push({
              _id,
              id: i + 1,
              label: row.label || i + 1,
              value: isFinite(row.value) ? row.value : i + 1,
            })
          }

          if (isEqual(single_select_input_values, newOptions)) return

          editElementConfig({
            uuid: activeElement.uuid,
            config: {
              ...activeElement.config,
              single_select_input_values: newOptions,
            },
          })
        }}
      />
    </>
  )
}

const BatchEditOverlay = ({ initialOptions, onUpdate, onClose }) => {
  const form = React.useRef()

  const [previewData, setPreviewData]: any = React.useState({})

  const formattedPreviewData = React.useMemo(() => {
    const result: any = []

    if (!previewData?.labels) return result

    const labels = previewData.labels?.split?.('\n') || []
    const values = previewData.values?.split?.('\n') || []

    for (let i = 0; i < labels.length; i++) {
      const label = labels[i]

      if (!label) continue

      const parsedValue = convertToNumber(values[i])

      result.push({
        label,
        value: isFinite(parsedValue) ? parsedValue : i + 1,
      })
    }

    return result
  }, [previewData])

  const { initialLabels, idsByLabel, initialValues } = React.useMemo(() => {
    const initialLabels: any = []
    const initialValues: any = []
    const idsByLabel: any = {}

    if (size(initialOptions) === 0) return { initialLabels, initialValues, idsByLabel }

    for (const value of initialOptions) {
      idsByLabel[value.label] = value._id
      initialLabels.push(value.label)
      initialValues.push(value.value)
    }

    return { initialLabels, initialValues, idsByLabel }
  }, [initialOptions])

  const handleSave = () => {
    const model = form.current.getFormValue()

    if (!model.labels) return

    // get all label entries
    const labelsArray = model.labels?.split?.('\n') || []
    const valuesArray = model.values?.split?.('\n') || []

    const data: any = []
    const usedIds: any = []

    for (let i = 0; i < labelsArray.length; i++) {
      const label = labelsArray[i]
      const value = valuesArray[i]

      if (!label) continue

      const prevId = idsByLabel[label]
      const newId = !prevId || usedIds.includes(prevId) ? uuid() : prevId

      if (prevId) usedIds.push(prevId)

      const parsedValue = convertToNumber(value)

      data.push({
        id: i + 1,
        _id: newId,
        label: label || i + 1,
        value: isFinite(parsedValue) ? parsedValue : i + 1,
      })
    }

    onUpdate(data)
    onClose()
  }

  const isPreviewDataEmpty = size(formattedPreviewData) === 0

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="right" maxWidth={60}>
      <Overlay.Header title="Batch Edit Options" glyph="multi_select" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            labels: initialLabels.join('\n'),
            values: initialValues.join('\n'),
          }}
          onUpdate={setPreviewData}
        >
          <Section>
            <div className="grid gap-5 grid-cols-2">
              <Textarea label="Labels" description="Separate values with new lines" model="labels" minRows={24} />
              <Textarea label="Values" description="Separate values with new lines" model="values" minRows={24} />
            </div>

            <Card className="mt-4 px-3 py-2">
              <h4 className="text-[0.88rem] text-text-muted font-[700] tracking-[1px] uppercase">Options Preview</h4>

              <Alert small glyph="info" className="my-2">
                Please ensure the preview below matches your intended changes before saving
              </Alert>

              {isPreviewDataEmpty ? (
                <State isEmpty emptyDescription="Add labels on new lines to batch-create options" />
              ) : (
                <>
                  {formattedPreviewData.map((row: any, index: number) => (
                    <div
                      key={`${row.label}-${index}`}
                      className="py-1 px-0.5 border-b border-0 border-solid border-divider last:border-none"
                    >
                      <div className="text-[0.9rem] font-[600]">{row.label}</div>
                      <div className="text-[0.8rem] text-text-muted opacity-90">Value: {row.value}</div>
                    </div>
                  ))}
                </>
              )}
            </Card>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Save ${countWord('Options', size(formattedPreviewData))}`}
          type="primary"
          color="green"
          glyph="multi_select"
          onClick={handleSave}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const convertToNumber = (value: any) => {
  if (isFinite(value) || !value || !isString(value)) return value

  let res = 0

  if (value.includes('.')) {
    // Convert to float if the string contains a decimal point
    res = parseFloat(value)
  } else {
    // Convert to int if the string does not contain a decimal point
    res = parseInt(value, 10)
  }

  if (!isFinite(res)) return value

  return res
}

const DEFAULT_NEW_ROW = (_dataMap, dataIds) => ({
  label: size(dataIds) + 1,
  value: size(dataIds) + 1,
})

const COLUMNS = [
  {
    title: 'Label',
    model: 'label',
    width: 150,
  },
  {
    title: (
      <>
        Value
        <Tooltip
          content="This is the internal form value that will be used to reference each option in the form submission data"
          className="ml-1"
        />
      </>
    ),
    model: 'value',
    width: 120,
    type: 'number',
    config: {
      onUpdate: ({ value, rowId, updateRow }: any) => {
        updateRow(rowId, { value: convertToNumber(value) })
      },
    },
  },
]
