import React from 'react'

import { useSettings } from '../../hooks'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

const columns = (to: Function = () => {}, timezone: string) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} label={value} />,
  },
  {
    width: 300,
    accessor: 'address',
    Header: 'Address',
    Cell: TableCell.Address,
  },
  {
    width: 300,
    accessor: 'notes',
    Header: 'Notes',
    Cell: TableCell,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  hiddenColumns?: string[]
  isLoading: boolean
  localStorageKey: string
  selectActions?: Function
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const AddressBookTable = (props: Props) => {
  const { to, data, isLoading, emptyActions, localStorageKey, selectActions, batchActionsConfig, hiddenColumns, titleAfter } = props
  const { timezone } = useSettings()

  return (
    <Table
      showFilters
      title="Address Book"
      titleAfter={titleAfter}
      icon="housing_notes"
      data={data}
      columns={columns(to, timezone)}
      hiddenColumns={hiddenColumns}
      selectActions={selectActions}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription="No addresses added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

AddressBookTable.defaultProps = {
  localStorageKey: 'address_book',
}

export default AddressBookTable
