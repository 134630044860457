import React from 'react'
import { Global } from '@emotion/react'

import { initialize, setup } from '../modules/init'

import useStore from '@behavehealth/modules/store'
import { PasswordResetPage } from '@behavehealth/constructs/Auth/PasswordResetPage'

const Security = () => {
  const newUser = useStore((state: any) => state.newUser)
  const updateStore = useStore((state) => state.update)

  React.useEffect(() => {
    const init = async () => {
      await initialize()
      await setup()
    }

    init()
  }, [])

  // React.useEffect(() => {
  //   if (newUser && newUser?.auth?.supported?.includes('password')) {
  //     return history.push('/dashboard')
  //   }
  // }, [newUser])

  return (
    <div>
      <Global styles={styles.main} />

      <PasswordResetPage user={newUser} updateStore={updateStore} />
    </div>
  )
}

const styles = {
  page: { padding: '1rem', maxWidth: 1000, width: '100%' },
  main: {
    'html, body, #app': {
      overflow: 'unset',
      width: 'auto',
      height: 'auto',
    },
  },
  success: {
    maxWidth: 600,
    margin: '0 auto',
    textAlign: 'center',
    padding: '100px 20px',
  },
}

export default Security
