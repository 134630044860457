import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { useAPIwithInitialClear } from '../../../hooks/useAPI'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import FormSection from '../../../components/Forms/FormSection'
import Status from '../../../components/Status'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import Workflow from '../../../components/Workflow/Workflow'

import ClientConnection from '../../../components/Overlays/pages/ClientConnection'

const columns = [
  {
    width: 260,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'to.name',
    Header: 'Name',
    Cell: ({ value, row }: any) => {
      return (
        <SummonOverlay overlay={<ClientConnection showBackdrop dataID={row.original.id} initialData={row.original} />}>
          <TableCell.MainLink label={value} />
        </SummonOverlay>
      )
    },
  },
  {
    width: 160,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ value }: any) => {
      if (value === 'care_team') return <Status label="Care Team" color="orange" />
      if (value === 'organization') return <Status label="Organization" color="purple" />
      return <TableCell.Empty />
    },
  },
  {
    width: 200,
    accessor: 'subcategory',
    Header: 'Relation Type',
    Cell: ({ value }: any) => startCase(value),
  },
  {
    width: 200,
    accessor: 'notes',
    Header: 'Notes',
  },
]

const AddButton = () => {
  const match = useRouteMatch()

  return (
    <SummonOverlay overlay={<ClientConnection dataID="new" match={match} />}>
      <Button label="Add Connection" glyph="add" size={200} type="primary" display="inline-flex" permission="connections.create" />
    </SummonOverlay>
  )
}

const CareTeam = ({ setStatus }: any) => {
  const match = useRouteMatch()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { resource_id }: any = match.params

  const data = useSelector((state: any) => state.data.connections?.data)
  const loading = useSelector((state: any) => state.data.connections?.loading)
  const isEmpty = size(data) === 0

  useAPIwithInitialClear('connections', `/residents/${resource_id}/connections`, false)

  const onContinue = () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <FormSection maxWidth="100%">
      <Card>
        <Table
          icon="connections"
          title="Connections"
          showSettings={false}
          isLoading={loading}
          data={data}
          columns={columns}
          emptyActions={<AddButton />}
          emptyDescription="No care team members added yet"
        />
      </Card>

      <Workflow.Buttons>
        <Workflow.ContinueButton onClick={onContinue} />
        <Workflow.SkipButton onClick={onSkip} />
      </Workflow.Buttons>
    </FormSection>
  )
}

export default CareTeam
