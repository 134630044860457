import React from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import produce from 'immer'

import { COLORS } from '../../theme'
import { useGet } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import NavGroup from '../../components/NavGroup'
import NavItem from '../../components/NavItem'
import Overlay from '../../components/Overlay'
import SummonOverlay from '../../components/SummonOverlay'

import { ProfileContactDetails } from './components/ProfileContactDetails'
import { ProfileWithTooltip } from './components/ProfileWithTooltip'
import { ReferralMessages } from './components/ReferralMessages'

import { Attachments } from './constructs/Attachments'
import { ClientDetails } from './constructs/ClientDetails'
import { ConsentForms } from './constructs/ConsentForms'
import { DataCategories } from './constructs/DataCategories'
import { PartnerServicesOverlay } from './constructs/PartnerServicesOverlay'
import { ReferralDetails } from './constructs/ReferralDetails'
import { TransportDetails } from './constructs/TransportDetails'

import { useMockReferralsStore } from './useMockReferralsStore'

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const RootReferralSentOverlay = (props: any) => {
  const { dataID } = props

  const match = useRouteMatch()

  const { data: partnership }: any = useGet({
    name: ['organizations', match.params?.['resource_id']],
    url: `/organizations/${match.params?.['resource_id']}`,
  })

  const [messagesVisible, setMessagesVisible] = React.useState(true)

  const partnerName = partnership?.other_partner?.name

  const { timezone, tenant, user } = useSettings()

  const isNew = !dataID || dataID === 'new'

  const referralsData = useMockReferralsStore((state) => state.data)
  const saveReferral = useMockReferralsStore((state) => state.saveReferral)
  const deleteReferral = useMockReferralsStore((state) => state.deleteReferral)
  const addUpdate = useMockReferralsStore((state) => state.addUpdate)

  const storeRecord = React.useMemo(() => {
    return referralsData?.find((o: any) => o.id === dataID)

    // if (found) return { ...found, sent_from: tenant, sent_from_id: tenant.id }
  }, [referralsData, dataID])

  const [initialData] = React.useState(isNew ? props.initialData : storeRecord)

  const location = useLocation()
  const history = useHistory()

  const [tab, setTab] = React.useState(TABS[0].id)
  const [data, setData] = React.useReducer(dataReducer, initialData)

  const [client, setClient] = React.useState(data?.client)
  const [partners, setPartners] = React.useState(data?.partners || [])
  // const [partner, setPartner] = React.useState(data?.partner || [])

  const [isEditable, setIsEditable] = React.useState(isNew)
  const [formValid, setFormValid] = React.useState(false)

  const [selectedPartner, setSelectedPartner] = React.useState<any>(null)

  const activeTabConfig = React.useMemo(() => {
    return TABS.find((o) => o.id === tab)
  }, [tab])

  const ActiveComponent = React.useMemo(() => {
    return activeTabConfig?.component
  }, [activeTabConfig])

  const close = () => {
    if (props.onClose) return props.onClose()

    if (location.parent) {
      if (location.parent.url) history.push(location.parent.url)
      else history.push(location.parent)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  const edit = () => setIsEditable(true)
  const cancel = () => setIsEditable(false)

  const handleSave = () => {
    const saveData = {
      ...data,
      ...(isNew && {
        client,
        partners,
        sent_from: tenant,
        sent_from_id: tenant.id,
        author: user,
      }),
    }

    saveReferral(saveData)
    close()
  }

  const handleAccept = () => {
    saveReferral({
      id: data.id,
      sent_from_status: 'accepted',
    })

    addUpdate(data.id, {
      name: `${tenant.name} accepted referral`,
      author: user,
      action: 'sent_from_accepted',
    })
  }

  const handleDecline = () => {
    saveReferral({
      id: data.id,
      sent_from_status: 'declined',
    })

    addUpdate(data.id, {
      name: `${tenant.name} declined referral`,
      author: user,
      action: 'sent_from_declined',
    })
  }

  const handleCancel = () => {
    saveReferral({
      id: data.id,
      sent_from_status: 'cancelled',
    })

    addUpdate(data.id, {
      name: `${tenant.name} cancelled referral`,
      author: user,
      action: 'sent_from_cancelled',
    })
  }

  const handleUnCancel = () => {
    saveReferral({
      id: data.id,
      sent_from_status: 'pending',
    })

    addUpdate(data.id, {
      name: `${tenant.name} uncancelled referral`,
      author: user,
      action: 'sent_from_uncancelled',
    })
  }

  const deleteRecord = () => {
    if (!data?.id) return

    deleteReferral(data.id)
    close()
  }

  React.useEffect(() => {
    if (storeRecord) setData(storeRecord)
  }, [storeRecord])

  const initialModel = produce(data, (draft) => {
    delete draft.client
    delete draft.partners
    delete draft.sent_from
    delete draft.sent_from_id
    delete draft.sent_to
    delete draft.sent_to_id
    delete draft.author
  })

  if (!isNew && !initialData) return null

  return (
    <Overlay fullheight showBackdrop={isEditable} closeOnBackdrop onClose={close} position="right" maxWidth={messagesVisible ? 110 : 90}>
      <Overlay.Header icon="referrals" title={'Referral Sent'} />

      <SummonOverlay
        isOpen={!!selectedPartner}
        onClose={() => {
          setSelectedPartner(null)
        }}
        overlay={<PartnerServicesOverlay name={selectedPartner?.name} />}
      ></SummonOverlay>

      <Overlay.SubHeader className="flex items-center text-[0.92rem] !py-2 !px-3">
        <div className="flex items-center mr-5">
          <div className="font-[600] mr-1">Referring Client:</div>
          <ProfileWithTooltip name={data.client.name} tooltipContent={<ProfileContactDetails name={data.client.name} />} />
        </div>

        <div className="flex items-center mr-5">
          <div className="font-[600] mr-1">Referring From:</div>
          <ProfileWithTooltip
            name={data.sent_from.name}
            description="Healthcare Facility"
            tooltipContent={
              <ProfileContactDetails
                name={data.sent_from.name}
                after={
                  <Button
                    label="View Services"
                    type="primary"
                    size={200}
                    onClick={() => {
                      setSelectedPartner(data.sent_from)
                    }}
                  />
                }
              />
            }
          />
        </div>

        <div className="flex items-center mr-5">
          <div className="font-[600] mr-1">Referring To:</div>
          <ProfileWithTooltip
            name={data.sent_to.name}
            description="Healthcare Facility"
            tooltipContent={
              <ProfileContactDetails
                name={data.sent_to.name}
                after={
                  <Button
                    label="View Services"
                    type="primary"
                    size={200}
                    onClick={() => {
                      setSelectedPartner(data.sent_to)
                    }}
                  />
                }
              />
            }
          />
        </div>

        <div className="ml-auto">
          <Button
            label={messagesVisible ? 'Hide Messages' : 'Show Messages'}
            size={100}
            type="minimal"
            icon="community_invites"
            onClick={() => {
              setMessagesVisible((c) => !c)
            }}
          />
        </div>
      </Overlay.SubHeader>

      <Overlay.Content className="flex flex-nowrap !overflow-hidden">
        <nav className="p-4 h-full overflow-y-auto grow-0 shrink-0 basis-[220px] border-r border-solid border-0 border-divider">
          {TAB_GROUPS.map((group) => (
            <NavGroup key={group.label} label={group.label}>
              {group.tabs.map((o) => (
                <NavItem
                  key={o.id}
                  size={200}
                  isActive={tab === o.id}
                  label={o.label}
                  icon={o.icon}
                  color={o.color}
                  onClick={() => {
                    setTab(o.id)
                  }}
                />
              ))}
            </NavGroup>
          ))}
        </nav>

        {activeTabConfig && ActiveComponent && (
          <ActiveComponent
            isNew={isNew}
            icon={activeTabConfig.icon}
            title={activeTabConfig.label}
            data={data}
            setData={setData}
            timezone={timezone}
            initialData={initialData}
            isEditable={isEditable}
            client={client}
            setClient={setClient}
            // partner={partner}
            // setPartner={setPartner}
            partners={partners}
            setPartners={setPartners}
            setFormValid={setFormValid}
            initialModel={initialModel}
          />
        )}

        {messagesVisible && <ReferralMessages />}
      </Overlay.Content>

      <Grid gap="0.75rem" className="px-4 pb-4 bg-[#F7F8FB]">
        {data.sent_to_status === 'declined_data' && (
          <Alert contrast type="negative" glyph="decline">
            {partnerName} has declined this referral Client Records.
          </Alert>
        )}

        {data.sent_to_status === 'declined_referral' && (
          <Alert contrast type="negative" glyph="decline">
            {partnerName} has declined this referral.
          </Alert>
        )}

        {/* <Flex gap="0.75rem">
          {data.sent_from_status === 'pending' && (
            <>
              {data.sent_to_status === 'accepted_data' && (
                <>
                  <ConfirmDialog
                    title="Accept Referral"
                    message={`Are you sure you want to accept referral to ${partnerName}?`}
                    onYes={handleAccept}
                    className="flex-[4_1_auto]"
                    yesLabel="Accept Referral"
                  >
                    <Button label={`Accept Referral to ${partnerName}`} glyph="check" color="green" type="primary" />
                  </ConfirmDialog>

                  <ConfirmDialog
                    title="Decline Referral"
                    message={`Are you sure you want to decline referral to ${partnerName}?`}
                    onYes={handleDecline}
                    className="flex-[1_1_auto]"
                    yesLabel="Decline Referral"
                    yesColor="red"
                  >
                    <Button label={`Decline Referral to ${partnerName}`} glyph="check" color="red" type="primary" />
                  </ConfirmDialog>
                </>
              )}
            </>
          )}
        </Flex> */}

        <Flex gap="0.75rem">
          {isNew ? (
            <Button
              label="Save & Send Referral"
              glyph="check"
              color="green"
              type="primary"
              flex="3 1 auto"
              isDisabled={!formValid}
              onClick={() => {
                handleSave()
              }}
            />
          ) : isEditable ? (
            <>
              <Button
                label="Save & Send Referral"
                glyph="check"
                color="green"
                type="primary"
                flex="3 1 auto"
                onClick={() => {
                  handleSave()
                }}
              />

              <Button
                label="Cancel"
                color="blue"
                type="default"
                flex="1 1 auto"
                onClick={() => {
                  cancel()
                }}
              />
            </>
          ) : (
            <>
              <Button
                label="Edit"
                glyph="edit"
                color="blue"
                type="default"
                flex="3 1 auto"
                onClick={() => {
                  edit()
                }}
              />

              {data.sent_from_status === 'pending' && (
                <>
                  <ConfirmDialog
                    title="Cancel Referral"
                    glyph="decline"
                    message="Are you sure you want to cancel this referral?"
                    onYes={handleCancel}
                    className="flex-[1_1_auto]"
                    yesLabel="Cancel Referral"
                    yesColor="red"
                  >
                    <Button label="Cancel Referral" glyph="decline" color="text" glyphColor="red" type="default" />
                  </ConfirmDialog>
                </>
              )}

              {data.sent_from_status === 'cancelled' && (
                <>
                  <ConfirmDialog
                    title="Un-cancel Referral"
                    glyph="decline"
                    message="Are you sure you want to un-cancel this referral?"
                    onYes={handleUnCancel}
                    className="flex-[1_1_auto]"
                    yesLabel="Un-cancel Referral"
                    yesColor="red"
                  >
                    <Button label="Un-cancel Referral" glyph="check" color="text" glyphColor="green" type="default" />
                  </ConfirmDialog>
                </>
              )}

              <Button
                label="Delete"
                glyph="cross"
                color="red"
                type="default"
                flex="1 1 auto"
                onClick={() => {
                  deleteRecord()
                }}
              />
            </>
          )}
        </Flex>
      </Grid>
    </Overlay>
  )
}

const TAB_GROUPS = [
  {
    // label: '1. Referral Details',
    tabs: [
      {
        id: 'referral_details',
        label: 'Summary',
        icon: 'dashboard',
        color: COLORS.vividBlue,
        component: ReferralDetails,
      },
    ],
  },
  {
    label: '1. Client & Consent',
    tabs: [
      {
        id: 'client_details',
        label: 'Client Details',
        icon: 'clients',
        color: COLORS.vividBlue,
        component: ClientDetails,
      },
      {
        id: 'consent_form',
        label: 'Consent Forms',
        icon: 'checklist',
        color: COLORS.green,
        component: ConsentForms,
      },
    ],
  },
  {
    label: '2. Client Records',
    tabs: [
      {
        id: 'data_transfer',
        label: 'Client Records',
        icon: 'web_form',
        color: COLORS.blue,
        component: DataCategories,
      },
      {
        id: 'attachments',
        label: 'Attachments',
        icon: 'files',
        color: COLORS.vividBlue,
        component: Attachments,
      },
    ],
  },
  {
    label: '3. Transport Details',
    tabs: [
      {
        id: 'transport_details',
        label: 'Transport Details',
        icon: 'vehicles',
        color: COLORS.vividBlue,
        component: TransportDetails,
      },
    ],
  },
]

export const TABS = TAB_GROUPS.reduce((acc, group) => {
  return [...acc, ...group.tabs]
}, [])

export const ReferralSentOverlay = withOverlayError(RootReferralSentOverlay)
