import React from 'react'
import { useParams } from 'react-router-dom'
import size from 'lodash/size'

import { CLINICAL_MEASURES_TITLES } from '../../../utils/constants'
import { useDataForms } from '../../../hooks/useDataForms'
import { useSettings } from '../../../hooks/useSettings'

import { ACEScoreOverlay } from '../../ClinicalMeasures/ACEScoreOverlay'
import { ASI5Overlay } from '../../ClinicalMeasures/ASI5Overlay'
import { ASILiteOverlay } from '../../ClinicalMeasures/ASILiteOverlay'
import { ASRMOverlay } from '../../ClinicalMeasures/ASRMOverlay'
import { AUDITCOverlay } from '../../ClinicalMeasures/AUDITCOverlay'
import { BAMOverlay } from '../../ClinicalMeasures/BAMOverlay'
import { BARC10Overlay } from '../../ClinicalMeasures/BARC10Overlay'
import { DAST10Overlay } from '../../ClinicalMeasures/DAST10Overlay'
import { DimensionsOfWellnessOverlay } from '../../ClinicalMeasures/DimensionsOfWellnessOverlay'
import { EDEQ6Overlay } from '../../ClinicalMeasures/EDEQ6Overlay'
import { GAD7Overlay } from '../../ClinicalMeasures/GAD7Overlay'
import { MDIOverlay } from '../../ClinicalMeasures/MDIOverlay'
import { PCLCOverlay } from '../../ClinicalMeasures/PCLCOverlay'
import { PDSSOverlay } from '../../ClinicalMeasures/PDSSOverlay'
import { PHQ15Overlay } from '../../ClinicalMeasures/PHQ15Overlay'
import { PHQ9Overlay } from '../../ClinicalMeasures/PHQ9Overlay'
import { TEAOverlay } from '../../ClinicalMeasures/TEAOverlay'
import { WHOQOLOverlay } from '../../ClinicalMeasures/WHOQOLOverlay'

import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import DataFormStatus from '../../../components/Statuses/DataFormStatus'
import Dropdown from '../../../components/Dropdown'
import DropdownItem from '../../../components/DropdownItem'
import SummonOverlay from '../../../components/SummonOverlay'
import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import TableFilter from '../../../components/Table/TableFilter'

const OVERLAYS: any = {
  ace_score: ACEScoreOverlay,
  asi_lite: ASILiteOverlay,
  asi5: ASI5Overlay,
  asrm: ASRMOverlay,
  audit_c: AUDITCOverlay,
  bam: BAMOverlay,
  barc10: BARC10Overlay,
  dast10: DAST10Overlay,
  dow: DimensionsOfWellnessOverlay,
  edeq6: EDEQ6Overlay,
  gad7: GAD7Overlay,
  mdi: MDIOverlay,
  pclc: PCLCOverlay,
  pdss: PDSSOverlay,
  phq15: PHQ15Overlay,
  phq9: PHQ9Overlay,
  tea: TEAOverlay,
  whoqol: WHOQOLOverlay,
}

const handleSummonOpen = (event: any) => {
  event.stopPropagation()
}

const ClinicalMeasures = ({ client, setStatus }: any) => {
  const { resource_id }: any = useParams()
  const { timezone, formSettings }: any = useSettings()

  const clientId = client?.id || resource_id

  const { data, isLoading, isRefetching } = useDataForms({
    clientID: clientId,
    category: 'clinical_measurement',
    options: { enabled: !!clientId },
  })

  const isEmpty = size(data) === 0

  const [openOverlay, setOpenOverlay] = React.useState('')

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <>
      {Object.keys(OVERLAYS).map((key) => {
        const OverlayTag = OVERLAYS[key]

        return (
          <SummonOverlay
            key={key}
            isOpen={openOverlay === key}
            onClose={() => setOpenOverlay('')}
            overlay={<OverlayTag showBackdrop dataID="new" />}
          />
        )
      })}

      <Card testKey="clinical_measures_table">
        <Table
          icon="clinical_measurements"
          title="Clinical Measures"
          showSettings={false}
          data={data}
          isLoading={isLoading || isRefetching}
          columns={columns(timezone)}
          emptyDescription="No clinical measures have been added yet."
        />
        <CardContent>
          <Dropdown
            label="Add New…"
            buttonType="primary"
            glyph="add"
            position="bottom"
            buttonSize={200}
            display="inline-flex"
            permission="clinical_measurements.create"
            css={STYLES.dropdown}
          >
            {Object.entries(CLINICAL_MEASURES_TITLES).map(([key, label]) => {
              if (!formSettings?.[`clinical_measures_${key}`]) return null

              return (
                <DropdownItem
                  key={key}
                  label={label}
                  icon="clinical_measurements"
                  onClick={() => {
                    setOpenOverlay(key)
                  }}
                />
              )
            })}
          </Dropdown>
        </CardContent>
      </Card>
    </>
  )
}

const columns = (timezone: string) => [
  {
    accessor: 'name',
    Header: 'Name',
    width: 240,
    Cell: ({ value, row }: any) => {
      const OverlayTag = OVERLAYS?.[row.original.subcategory]

      if (!OverlayTag) return null

      return (
        <SummonOverlay
          onOpen={handleSummonOpen}
          overlay={<OverlayTag showBackdrop dataID={row.original.id} initialData={row.original} type="summon" />}
        >
          <TableCell.MainLink label={value} />
        </SummonOverlay>
      )
    },
  },
  {
    width: 120,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <DataFormStatus status={value} />,
    Filter: TableFilter.DataFormStatus,
    filter: 'dataFormStatus',
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

const STYLES = {
  dropdown: {
    display: 'inline-flex',
    width: 'fit-content',
  },
}

export default ClinicalMeasures
