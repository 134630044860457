import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import BHCheckbox from '../../../../components/Forms/Checkbox'
import BHCheckboxGroup from '../../../../components/Forms/CheckboxGroup'

import { getFormElementProps, formatInputModel } from '../../utils/functions'
import { isURL } from '../../../../utils/functions'

export const CheckboxInput: React.FC<any> = (props) => {
  const { isEditable, children, element, className, hoverElement, useParsedConfig, environment } = props

  const multi_input_values = React.useMemo(() => {
    if (!element?.config?.multi_input_values) return []

    const { parsed, multi_input_values } = element.config

    if (useParsedConfig && size(parsed?.multi_input_values) === size(multi_input_values)) {
      return parsed.multi_input_values || []
    }

    return multi_input_values || []
  }, [element, useParsedConfig])

  if (!element || !element.config) return null

  const rootClasses = clsx('CHECKBOX_INPUT', className)

  const { options_layout = 'vertical-dense', is_full_width } = element.config

  const { description, label, tooltip, validations }: any = getFormElementProps(element, { useParsedConfig, environment })

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}

      <BHCheckboxGroup
        key={isEditable ? `updated-${element.config?.updated_at}` : undefined}
        label={label}
        description={description}
        validations={validations}
        tooltip={tooltip}
        layout={options_layout}
        {...(is_full_width && { maxWidth: '100%' })}
        trueIcon="check"
        falseIcon="empty_checkbox"
        falseStyle="faded"
      >
        {multi_input_values?.map((option: any) => (
          <CustomCheckbox
            key={option._id}
            label={option.label}
            description={option.description}
            model={`${formatInputModel(element)}.${option._id}`}
          />
        ))}
      </BHCheckboxGroup>
    </div>
  )
}

const CustomCheckbox = (props: any) => {
  const { label, description, model } = props

  const descriptionElement = React.useMemo(() => {
    if (!description) return null

    if (isURL(description)) {
      return (
        <a
          href={description}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {description}
        </a>
      )
    }

    return description
  }, [description])

  return <BHCheckbox label={label} description={descriptionElement} model={model} />
}
