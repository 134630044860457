import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../../../theme'

import { Box } from './Box'

export const Divider = React.forwardRef((props: any, ref) => {
  const { className, element, hoverElement } = props

  if (!element) return null

  const rootClasses = clsx('DIVIDER', className)

  return (
    <Box ref={ref} as="div" className={rootClasses} hoverElement={hoverElement} element={element}>
      <hr css={STYLES.divider} />
    </Box>
  )
})

const STYLES = {
  divider: {
    height: 2,
    width: '100%',
    border: 'none',
    flex: ' 0 0 auto',
    background: COLORS.gray,
    opacity: 0.24,
  },
}
