import React from 'react'
import { niceAmount } from '../../utils/functions'

export const QuoteLinesAmounts = ({ amounts = {} }: any) => {
  if (!amounts) return null

  return (
    <div className="grid gap-2 grid-cols-[200px_80px] tabular-nums">
      <div className="text-text-muted pr-2 text-right">Subtotal (before discount)</div>
      <div>{niceAmount(amounts.before_discount)}</div>

      <div className="text-text-muted pr-2 text-right">Discount</div>
      <div>{niceAmount(amounts.discount)}</div>

      <div className="text-text-muted pr-2 text-right">Subtotal (after discount)</div>
      <div>{niceAmount(amounts.subtotal)}</div>

      <div className="text-text-muted pr-2 text-right">Tax</div>
      <div>{niceAmount(amounts.tax)}</div>

      <div className="font-[500] pr-2 text-right text-[1.12em]">Total</div>
      <div className="text-[1.12em]">{niceAmount(amounts.total)}</div>
    </div>
  )
}
