import React from 'react'

import { COLORS } from '../../theme'

import Chart from '../Chart'
import Switch from '../Forms/Switch'

type Props = {
  data: any
}

const TreeChart: React.FC<Props> = ({ data = {}, initialTreeDepth, ...rest }) => {
  const [roamEnabled, setRoamEnabled] = React.useState(false)

  const option = {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
    },
    series: [
      {
        type: 'tree',
        id: 0,
        name: 'tree1',
        data: [data],

        top: '20%',
        bottom: '20%',
        left: '20%',
        right: '20%',

        symbol: 'emptyCircle',
        symbolSize: 7,

        edgeShape: 'polyline',
        edgeForkPosition: '63%',
        initialTreeDepth: initialTreeDepth,

        itemStyle: {
          color: COLORS.blue,
        },

        lineStyle: {
          width: 2,
        },

        roam: roamEnabled,

        label: {
          backgroundColor: '#fff',
          position: 'left',
          verticalAlign: 'middle',
          align: 'right',
          color: COLORS.text,
          fontSize: 12,
        },

        leaves: {
          label: {
            position: 'right',
            verticalAlign: 'middle',
            align: 'left',
          },
        },

        emphasis: {
          focus: 'descendant',
        },

        expandAndCollapse: true,
        animationDuration: 550,
        animationDurationUpdate: 750,
      },
    ],
  }
  return (
    <>
      <div css={styles.actions}>
        <div css={styles.label}>Enable Chart Zoom</div>
        <Switch value={roamEnabled} onUpdate={({ value }) => setRoamEnabled(value)} css={styles.switch} />
      </div>
      <Chart option={option} {...rest} />
    </>
  )
}

const styles = {
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0.75rem 1rem',
  },

  label: {
    fontWeight: 600,
    marginRight: '0.5rem',
  },

  button: {
    marginLeft: 'auto',
    display: 'inline-flex',
  },

  switch: {
    label: { display: 'none' },
  },
}

export default TreeChart
