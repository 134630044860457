import { BooleanFilter } from './filters/BooleanFilter'
import { DateTimeFilter } from './filters/DateTimeFilter'
import { GlobalTagsFilter } from './filters/GlobalTagsFilter'
import { MultiObjectFilter } from './filters/MultiObjectFilter'
import { MultiOverlayFilter } from './filters/MultiOverlayFilter'
import { MultiSelectFilter } from './filters/MultiSelectFilter'
import { NumberFilter } from './filters/NumberFilter'
import { SmartFlagsFilter } from './filters/SmartFlagsFilter'
import { StringFilter } from './filters/StringFilter'
import { TagsFilter } from './filters/TagsFilter'

export const FILTERS: any = {
  boolean: BooleanFilter,
  date: DateTimeFilter,
  date_time: DateTimeFilter,
  global_tags: GlobalTagsFilter,
  multi_object: MultiObjectFilter,
  multi_overlay: MultiOverlayFilter,
  multi_select: MultiSelectFilter,
  number: NumberFilter,
  smart_flags: SmartFlagsFilter,
  string: StringFilter,
  tags: TagsFilter,
}
