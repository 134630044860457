import React from 'react'

import Flex from '../Flex'
import Icon from '../Icon'
import Glyph from '../Glyph'

const FlowChartContent = ({ icon, glyph, number, description }: any) => {
  return (
    <Flex nowrap gap="0.5rem" alignItems="baseline">
      {icon && <Icon icon={icon} css={styles.graphic} />}
      {glyph && <Glyph glyph={glyph} css={styles.graphic} />}
      <h4 css={styles.number}>{number}</h4>
      <div css={styles.description}>{description}</div>
    </Flex>
  )
}

const styles = {
  graphic: {
    alignSelf: 'center',
  },

  number: {
    fontSize: '1.2rem',
    fontWeight: 700,
  },

  description: {
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
}

export default FlowChartContent
