import React from 'react'
import Fuse from 'fuse.js'
import groupBy from 'lodash/groupBy'
import size from 'lodash/size'

import { COLORS } from '../../../theme'
import { DropdownMenu, DropdownMenuItem } from '../../../components/DropdownMenu'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../../components/Button'
import DataList from '../../../components/DataList'
import Glyph from '../../../components/Glyph'
import Permission from '../../../components/Permission'
import State from '../../../components/State'
import Tooltip from '../../../components/Tooltip'

import { useFormBuilder } from '../useFormBuilder'
import { VariableKey } from './VariableKey'
import { HQ_ACCOUNT_VARIABLES } from '../utils/constants'

const VARIABLE_CATEGORIES = ['form', 'time', 'custom_account', 'account']

const VARIABLE_CATEGORY_LABELS = {
  form: 'Form Variables',
  time: 'Time Variables',
  custom_account: 'Custom Account Variables',
  account: 'Account Variables',
}

export const VariablesDropdownMenu = ({ trigger, onSelect }: any) => {
  const { isHQApp } = useSettings()

  const storeVariables: any = useFormBuilder((state: any) => state.variables)

  const variables = React.useMemo(() => {
    const result: any = [...storeVariables]

    if (isHQApp) {
      result.push(...HQ_ACCOUNT_VARIABLES)
    }

    return result
  }, [storeVariables, isHQApp])

  const [searchRef, setSearchRef] = React.useState(null)
  const [search, setSearch] = React.useState('')

  const handleClear = () => {
    setSearch('')
  }

  const fuse = React.useMemo(() => {
    return new Fuse(variables, { keys: ['name', 'category', 'variable_key', 'variable_value', 'description'] })
  }, [variables])

  const searchData = React.useMemo(() => {
    if (!search) return variables || []

    let searchResults: any[] = []
    let fuseResults = fuse.search(search)

    for (let i = 0; i < fuseResults.length; i++) {
      searchResults.push(fuseResults[i].item)
    }

    return searchResults
  }, [fuse, search])

  const groupedVariables = React.useMemo(() => {
    return groupBy(searchData, 'category')
  }, [searchData])

  React.useEffect(() => {
    if (!searchRef) return

    setTimeout(() => {
      searchRef?.focus?.()
    }, 0)
  }, [searchRef])

  if (size(variables) === 0) return null

  return (
    <Permission featureFlagV2="variables">
      <DropdownMenu
        side="left"
        align="start"
        trigger={
          <div>
            {trigger || (
              <Button
                hideLabel
                glyph="variables"
                glyphColor={COLORS.blue}
                type="minimal"
                size={100}
                color="text"
                after={<Glyph glyph="triangle_down" size={9} className="!-ml-0" />}
              />
            )}
          </div>
        }
      >
        <div className="relative w-full border-b border-0 border-solid border-divider">
          <div className="absolute left-0 top-0 h-[100%] w-8 flex items-center justify-center">
            <Glyph glyph="search" size={18} />
          </div>

          <input
            ref={setSearchRef}
            type="text"
            placeholder="Search…"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className="pl-8 py-2 border-none w-full outline-none"
          />

          {search && (
            <div onClick={handleClear} className="absolute right-0 top-0 h-[100%] w-8 flex items-center justify-center">
              <Glyph glyph="circle_error" size={14} color={COLORS.textMuted} />
            </div>
          )}
        </div>

        {search && size(searchData) === 0 && (
          <State
            isEmpty
            glyph="variables"
            emptyDescription={
              <>
                <div>No variables found for</div>
                <div>
                  "<b>{search}</b>"
                </div>
              </>
            }
          />
        )}

        {VARIABLE_CATEGORIES.map((category: any) => {
          if (size(groupedVariables[category]) === 0) return null

          return (
            <div key={category} className="truncate mb-4">
              <h4 className="text-[0.88rem] text-text-muted mt-2 opacity-80 font-[600] tracking-[1px] uppercase px-[0.6rem]">
                {VARIABLE_CATEGORY_LABELS[category]}
              </h4>

              {groupedVariables[category].map((variable: any) => {
                if (variable?._destroy) return null

                return (
                  <Tooltip
                    key={variable.variable_key}
                    portal="radix"
                    position="left-start"
                    fallbackPositions={['top', 'bottom', 'auto']}
                    className="!flex"
                    content={
                      <>
                        <DataList isCompact layout="vertical">
                          <DataList.Item label="Variable Key" value={<VariableKey canCopy={false} variable={variable.variable_key} />} />
                          <DataList.Item label="Value" value={variable.variable_value} />
                          <DataList.Item label="Description" value={variable.description} />
                        </DataList>
                      </>
                    }
                  >
                    <DropdownMenuItem
                      className="flex flex-nowrap w-full max-w-[280px] items-center truncate"
                      onClick={(e) => {
                        onSelect?.(variable.variable_key)
                        handleClear()
                      }}
                    >
                      <div className="min-w-0 flex-[1_1_auto] truncate">{variable.name}</div>
                      <Glyph glyph="info" size={14} />
                    </DropdownMenuItem>
                  </Tooltip>
                )
              })}
            </div>
          )
        })}
      </DropdownMenu>
    </Permission>
  )
}
