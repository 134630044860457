import React from 'react'

import { COLORS } from '../../theme'

import { H4, P } from '../Typography'
import FormSection from './FormSection'

type Props = {
  children: React.ReactNode
  className?: string
  description?: string
  headerAside?: React.ReactNode
  title: string
}

const FormCard = (props: Props) => {
  const { title, description, headerAside, children, className } = props

  return (
    <div css={styles.root} className={className}>
      <div css={styles.header}>
        <div className="!my-2 !mx-0">
          {title && <H4>{title}</H4>}
          {description && <P css={{ margin: '0.25em 0 0' }}>{description}</P>}
        </div>
        {headerAside}
      </div>

      <FormSection>{children}</FormSection>
    </div>
  )
}

const styles = {
  root: {
    borderRadius: 5,
    border: `2px solid ${COLORS.divider}`,
    padding: '0.5rem 1.25rem 1.25rem',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.5rem',
  },
}

export default FormCard
