import React from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import compact from 'lodash/compact'

import { get, create, update, destroy, refreshToken } from '../modules/api/requests'
import { queryClient } from '../modules/api/client'

import Notifications from '../modules/notifications'

const DEBUG = false

type QueryParams = {
  name: string | Array<string | object>
  url: string
  params?: object
  options?: any
  headers?: object
  onSuccess?: Function
  onError?: Function
}

type MutationParams = {
  name?: string | Array<string | object>
  url: string
  invalidate?: string | Array<string | object>
  invalidateKeys?: Array<any>
  headers?: object
  onSuccess?: Function
  onMutate?: Function
  onError?: Function
}

type CRUDParams = {
  id?: string
  name: string
  onCreate?: any
  onUpdate?: any
  url: string
}

let prefix: string | null = null

export const setTenantPrefix = (tenant: string) => {
  prefix = `@` + tenant
}

export const getPrefix = () => prefix

export const useGet = ({ name, url, params, options, headers, onSuccess, onError }: QueryParams) => {
  const result = useQuery(
    [prefix, name].flat(),
    async () => {
      await refreshToken()
      return await get(url, params, headers)
    },
    {
      onSuccess: (data: any) => {
        if (onSuccess) onSuccess(data)
      },
      onError: (data: any) => {
        if (onError) onError(data)
      },
      ...options,
    },
  )

  return {
    ...result,
    data: result.data?.data,
    meta: result.data?.meta,
    folders: result.data?.folders,
  }
}

export const useCreate = ({ name, url, invalidate, headers, invalidateKeys, onSuccess, onMutate, onError }: MutationParams) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (data: any) => {
      await refreshToken()
      return await create(url, data, headers)
    },
    {
      onMutate: (variables) => {
        onMutate?.(variables, queryClient)
      },
      onSuccess: (data, variables) => {
        if (!name) return

        if (onSuccess) onSuccess(data, variables, queryClient)

        // clear item cache & list
        queryClient.invalidateQueries([prefix, name].flat())

        // invalidate matching cache
        if (invalidate) {
          if (Array.isArray(invalidate)) queryClient.invalidateQueries([prefix, invalidate].flat())
          else {
            const queries = queryClient.getQueryCache().queries
            for (let i = 0; i < queries.length; i++) {
              const query = queries[i]
              if (query.queryKey.includes(invalidate)) {
                queryClient.invalidateQueries(query.queryKey)
              }
            }
          }
        }

        if (invalidateKeys) {
          if (DEBUG) console.log('INVALIDATE MULTIPLE KEYS: ', invalidateKeys)

          const queries = queryClient.getQueryCache().queries

          for (let i = 0; i < invalidateKeys.length; i++) {
            const key = invalidateKeys[i]
            queryClient.invalidateQueries([prefix, key].flat())

            for (let i = 0; i < queries.length; i++) {
              const query = queries[i]
              if (query.queryKey.includes(key)) {
                queryClient.invalidateQueries(query.queryKey)
                if (DEBUG) console.log('KEY INVALIDATED: ', query.queryKey)
              }
            }
          }
        }
      },
      onError: (error) => {
        console.error(error)

        onError?.(error)

        if (error?.data?.type === 'unauthorized_error') {
          Notifications.send(error?.data?.errors?.join('. '), 'negative')
        } else if (error?.data?.type === 'object_error') {
          Notifications.send(error?.data?.validations[0]?.errors[0], 'negative')
        } else if (error?.data?.errors) {
          Notifications.send(error?.data?.errors?.join('. '), 'negative')
        } else {
          // cover unknown errors
          Notifications.send("Something didn't go as planned. We made a note of this error and we'll look into it soon.", 'negative')
        }
      },
    },
  )
}

export const useUpdate = ({ name, url, invalidate, invalidateKeys, onSuccess, onError }: MutationParams) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (data: any) => {
      await refreshToken()
      return await update(url, data)
    },
    {
      onSuccess: (data, variables) => {
        if (!name) return

        if (onSuccess) onSuccess(data, variables, queryClient)

        // clear item cache & list
        queryClient.invalidateQueries([prefix, name].flat())

        // invalidate matching cache
        if (invalidate) {
          if (DEBUG) console.log('INVALIDATE: ', invalidate)

          if (Array.isArray(invalidate)) {
            queryClient.invalidateQueries([prefix, invalidate].flat())
            if (DEBUG) console.log('ARRAY INVALIDATED: ', [prefix, invalidate].flat())
          } else {
            const queries = queryClient.getQueryCache().queries
            for (let i = 0; i < queries.length; i++) {
              const query = queries[i]
              if (query.queryKey.includes(invalidate)) {
                queryClient.invalidateQueries(query.queryKey)
                if (DEBUG) console.log('KEY INVALIDATED: ', query.queryKey)
              }
            }
          }
        }

        if (invalidateKeys) {
          if (DEBUG) console.log('INVALIDATE MULTIPLE KEYS: ', invalidateKeys)

          const queries = queryClient.getQueryCache().queries

          for (let i = 0; i < invalidateKeys.length; i++) {
            const key = invalidateKeys[i]
            queryClient.invalidateQueries([prefix, key].flat())

            for (let i = 0; i < queries.length; i++) {
              const query = queries[i]
              if (query.queryKey.includes(key)) {
                queryClient.invalidateQueries(query.queryKey)
                if (DEBUG) console.log('KEY INVALIDATED: ', query.queryKey)
              }
            }
          }
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error)
          return
        }

        if (error?.data?.type === 'unauthorized_error') {
          Notifications.send(error?.data?.errors?.join('. '), 'negative')
        } else if (error?.data?.type === 'object_error') {
          Notifications.send(error?.data?.validations[0]?.errors[0], 'negative')
        } else if (error?.data?.errors) {
          Notifications.send(error?.data?.errors?.join('. '), 'negative')
        } else {
          // cover unknown errors
          Notifications.send("Something didn't go as planned. We made a note of this error and we'll look into it soon.", 'negative')
        }
      },
    },
  )
}

export const useDelete = ({ name, url, invalidate, invalidateKeys, onSuccess }: MutationParams) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (ids: any) => {
      await refreshToken()
      return await destroy(compact([url, ids]).join('/'))
    },
    {
      onSuccess: (data: any, variables: any) => {
        if (!name) return

        if (onSuccess) onSuccess(data, variables, queryClient)

        // clear item cache & list
        queryClient.removeQueries([prefix, name].flat())

        // invalidate matching cache
        if (invalidate) {
          if (Array.isArray(invalidate)) queryClient.invalidateQueries([prefix, invalidate].flat())
          else {
            const queries = queryClient.getQueryCache().queries
            for (let i = 0; i < queries.length; i++) {
              const query = queries[i]
              if (query.queryKey.includes(invalidate)) {
                queryClient.invalidateQueries(query.queryKey)
              }
            }
          }
        }

        if (invalidateKeys) {
          if (DEBUG) console.log('INVALIDATE MULTIPLE KEYS: ', invalidateKeys)

          const queries = queryClient.getQueryCache().queries

          for (let i = 0; i < invalidateKeys.length; i++) {
            const key = invalidateKeys[i]
            queryClient.invalidateQueries([prefix, key].flat())

            for (let i = 0; i < queries.length; i++) {
              const query = queries[i]
              if (query.queryKey.includes(key)) {
                queryClient.invalidateQueries(query.queryKey)
                if (DEBUG) console.log('KEY INVALIDATED: ', query.queryKey)
              }
            }
          }
        }
      },
      onError: (error) => {
        console.error(error)

        if (error?.data?.type === 'unauthorized_error') {
          Notifications.send(error?.data?.errors?.join('. '), 'negative')
        } else if (error?.data?.type === 'object_error') {
          Notifications.send(error?.data?.validations[0]?.errors[0], 'negative')
        } else if (error?.data?.errors) {
          Notifications.send(error?.data?.errors?.join('. '), 'negative')
        } else {
          // cover unknown errors
          Notifications.send("Something didn't go as planned. We made a note of this error and we'll look into it soon.", 'negative')
        }
      },
    },
  )
}

export const useCRUD = ({ id, name, url, onCreate, onUpdate }: CRUDParams) => {
  const get = useGet({
    name,
    url,
    options: {
      enabled: Boolean(id),
    },
  })
  const create = useCreate({ name, url })
  const update = useUpdate({ name, url: `${url}/${id}` })
  const destroy = useDelete({ name, url: `${url}/${id}` })

  // onCreate callback
  React.useEffect(() => {
    if (onCreate && create.isSuccess && create.data) onCreate(create.data)
  }, [onCreate, create.isSuccess, create.data])

  // onUpdate callback
  React.useEffect(() => {
    if (onUpdate && update.isSuccess && update.data) onUpdate(update.data)
  }, [onUpdate, update.isSuccess, update.data])

  return {
    get,
    create,
    update,
    destroy,
  }
}

export const invalidateQueries = (name: any, invalidate: any = null) => {
  queryClient.invalidateQueries([prefix, name].flat())

  // invalidate matching cache
  if (invalidate) {
    if (DEBUG) console.log('INVALIDATE: ', invalidate)

    if (Array.isArray(invalidate)) {
      queryClient.invalidateQueries([prefix, invalidate].flat())
      if (DEBUG) console.log('ARRAY INVALIDATED: ', [prefix, invalidate].flat())
    } else {
      const queries = queryClient.getQueryCache().queries
      for (let i = 0; i < queries.length; i++) {
        const query = queries[i]
        if (query.queryKey.includes(invalidate)) {
          queryClient.invalidateQueries(query.queryKey)
          if (DEBUG) console.log('KEY INVALIDATED: ', query.queryKey)
        }
      }
    }
  }
}
