import React from 'react'
import { tint } from 'polished'
import clsx from 'clsx'

import { COLORS, GLYPHS } from '../../../theme'
import { ELEMENTS } from '../elements/elements'

import Glyph from '../../../components/Glyph'

export const ElementIcon = ({ elementCategory, className }: any) => {
  const ELEMENT = React.useMemo(() => ELEMENTS?.[elementCategory], [elementCategory])

  if (!ELEMENT) return null

  const elementColor = COLORS[ELEMENT.color] || ELEMENT.color || COLORS.default
  const elementGlyph = ELEMENT.glyph || GLYPHS.default

  const rootClasses = clsx('element-icon flex items-center justify-center w-[20px] h-[20px] rounded-[4px] flex-[0_0_auto]', className)

  return (
    <div className={rootClasses} style={{ backgroundColor: tint(0.1, elementColor) }}>
      <Glyph glyph={elementGlyph} size={16} color={`${COLORS.white} !important`} />
    </div>
  )
}
