import React from 'react'

import { COLORS } from '../../../../../theme'

const Footer: React.FC<any> = ({ children }) => {
  return <div css={styles}>{children}</div>
}

const styles = {
  padding: '1rem 0.75rem',
  position: 'sticky',
  bottom: 0,
  zIndex: 1,
  background: '#F7F8FB',
  borderTop: `1px solid ${COLORS.divider}`,
}

export default Footer
