import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { Page, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'
import OrganizationTransactions from '@behavehealth/components/Elements/transactions/organizations/OrganizationTransactions'
import withMarketing from '@behavehealth/hocs/withMarketing'

const pageConfig = {
  feature: 'financials',
  help: <HelpTagIframe id="organization_financials" />,
  marketingID: 'organization_financials',
}

type Props = {
  canCreate: boolean
}

const ClientFinancials: React.FC<Props> = () => {
  const match = useRouteMatch()
  const { resource_id } = match.params

  return (
    <Page {...pageConfig}>
      <OrganizationTransactions subcharges url={`/organizations/${resource_id}/financial_transactions`} />
    </Page>
  )
}

export default withPageError(withMarketing(ClientFinancials, pageConfig))
