import React from 'react'

import Button from './Button'

const SubmitButton = (props) => <Button is="button" buttonType="submit" css={styles.default} {...props} />

const styles = {
  default: {
    border: 0,
  },
}

export default SubmitButton
