import React from 'react'

import withSettings from '../../../hocs/withSettings'

import Table from '../../../components/Table/Table'
import TableCell from '../../../components/Table/TableCell'
import TableFilter from '../../../components/Table/TableFilter'

import ClaimEditableTaskStatus from '../../../components/Statuses/ClaimEditableTaskStatus'

const columns = (to: Function = () => {}, onClick: any, timezone: string, claimID: string) => [
  {
    width: 400,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'notes',
    Header: 'Task',
    Cell: ({ value, row }: any) => <TableCell.MainLink to={to(row.original.id)} onClick={() => onClick?.(row.original)} label={value} />,
  },
  {
    width: 140,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value, row }: any) => <ClaimEditableTaskStatus status={value} claimID={claimID} taskID={row.original.id} />,
  },
  {
    width: 160,
    accessor: 'assignee.name',
    Header: 'Assignee',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={value} />,
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Created',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Last Updated',
    Filter: TableFilter.Date,
    filter: 'date',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
  },
  {
    width: 160,
    accessor: 'author',
    Header: 'Created By',
    Cell: ({ row, value }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

const ClaimTasksTable: React.FC<any> = (props) => {
  const {
    emptyDescription = 'No claim tasks added yet',
    icon = 'tasks',
    localStorageKey = 'claim_tasks',
    onClick,
    timezone,
    title = 'Claim Tasks',
    to,
    claimID,
    ...rest
  } = props

  return (
    <Table
      title={title}
      icon={icon}
      columns={columns(to, onClick, timezone, claimID)}
      emptyDescription={emptyDescription}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

export default withSettings(ClaimTasksTable)
