import { immer } from 'zustand/middleware/immer'
import { persist } from 'zustand/middleware'
import create from 'zustand'

import { TREATMENT_PLAN_VIEWS, SECTIONS } from './constants'

export const useTreatmentPlanStore = create(
  persist(
    immer((set: any) => ({
      // Goals, Objectives & Interventions
      reviewsVisible: true,

      toggleReviewsVisible: (value: boolean) => {
        set((state: any) => {
          state.reviewsVisible = !state.reviewsVisible
        })
      },

      setReviewsVisible: (value: boolean) => {
        set((state: any) => {
          state.reviewsVisible = !!value
        })
      },

      problemReviewsVisible: true,

      toggleProblemReviewsVisible: (value: boolean) => {
        set((state: any) => {
          state.problemReviewsVisible = !state.problemReviewsVisible
        })
      },

      setProblemReviewsVisible: (value: boolean) => {
        set((state: any) => {
          state.problemReviewsVisible = !!value
        })
      },

      // Problems
      problemsView: TREATMENT_PLAN_VIEWS.cards,

      setProblemsView: (view: string) => {
        set((state: any) => {
          state.problemsView = TREATMENT_PLAN_VIEWS?.[view] || TREATMENT_PLAN_VIEWS.cards
        })
      },

      // Goals, Objectives & Interventions
      goalsObjectivesInterventionsView: TREATMENT_PLAN_VIEWS.cards,

      setGoalsObjectivesInterventionsView: (view: string) => {
        set((state: any) => {
          state.goalsObjectivesInterventionsView = TREATMENT_PLAN_VIEWS?.[view] || TREATMENT_PLAN_VIEWS.cards
        })
      },

      // Sections
      sectionsOpen: [...Object.keys(SECTIONS)],

      openAllSections: () => {
        set((state: any) => {
          state.sectionsOpen = Object.keys(SECTIONS)
        })
      },

      closeAllSections: () => {
        set((state: any) => {
          state.sectionsOpen = []
        })
      },

      toggleSection: (id: string) => {
        set((state: any) => {
          const index = state.sectionsOpen.indexOf(id)

          if (index === -1) {
            state.sectionsOpen.push(id)
          } else {
            state.sectionsOpen.splice(index, 1)
          }
        })
      },

      openSection: (id: string) => {
        set((state: any) => {
          state.sectionsOpen.push(id)
        })
      },

      closeSection: (id: string) => {
        set((state: any) => {
          const index = state.sectionsOpen.indexOf(id)

          if (index === -1) return

          state.sectionsOpen.splice(index, 1)
        })
      },
    })),
    { name: 'bh.treatment-plans-store' },
  ),
)
