import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import Attachments from '../../Forms/Attachments'
import AddressInput from '../../Forms/AddressInput'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import Chotomate from '../../Chotomate'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Divider from '../../Divider'
import EmailInput from '../../Forms/EmailInput'
import Permission from '../../Permission'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import GoogleMeetInput from '../../Forms/GoogleMeetInput'
import Input from '../../Forms/Input'
import MessengerInput from '../../Forms/MessengerInput'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import PhoneInput from '../../Forms/PhoneInput'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Section from '../../Section'
import Select from '../../Forms/Select'
import SkypeInput from '../../Forms/SkypeInput'
import Status from '../../Status'
import Textarea from '../../Forms/Textarea'
import Timeline from '../../Timeline/Timeline'
import WhatsAppInput from '../../Forms/WhatsAppInput'
import ZoomInput from '../../Forms/ZoomInput'
import SexSelect from '../../Forms/elements/SexSelect'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import withRouter from '../../../hocs/withRouter'
import { withOverlayError } from '../../../hocs/withOverlayError'

class Contact extends OverlayBase {
  renderHeader = () => {
    const { $editable, $new } = this.state
    const { record } = this.props

    return (
      <Overlay.Header
        icon="contacts"
        title={$new ? 'Add Contact' : record.name}
        subtitle={
          !$editable && (
            <Flex gap={6} fixChildrenX>
              {record.relationship && <Status color="orange" label={`${record.relationship}`} small />}
              <Status color={record.roi ? 'green' : 'red'} label={record.roi ? 'R.O.I. Allowed' : 'R.O.I. NOT Allowed'} small />
              {record.is_emergency && <Status color="yellow" label="Emergency Contact" small />}
            </Flex>
          )
        }
      />
    )
  }

  renderContent = () => {
    const { $editable, $new } = this.state
    const { client, record, reference, loading } = this.props

    const isPortal = this.props.location?.source === 'portal'
    const localReference = isPortal ? client : reference

    return (
      <Overlay.Content>
        <Chotomate ready name="contact_overlay" />

        <Form
          getForm={this.form}
          initialModel={record}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          linked={
            $new && {
              reference_id: localReference?.id,
              reference_type: localReference?.type,
            }
          }
        >
          <Section>
            <FormSection layout="vertical">
              {$editable && localReference?.type === 'resident' && (
                <CheckboxGroup>
                  <Checkbox model="roi" label="Release of Information Allowed" />
                </CheckboxGroup>
              )}

              {$editable && localReference?.type === 'resident' && (
                <CheckboxGroup>
                  <Checkbox model="is_emergency" label="Emergency Contact" />
                </CheckboxGroup>
              )}

              <Input
                label="First Name"
                model="first_name"
                validations={{
                  presence: {
                    message: "Please enter the contact's first name",
                  },
                }}
              />

              <Input label="Middle Name" model="middle_name" />

              <Input
                label="Last Name"
                model="last_name"
                validations={{
                  presence: {
                    message: "Please enter the contact's last name",
                  },
                }}
              />

              <SexSelect label="Sex" model="sex" />

              {localReference?.type === 'organization' && (
                <>
                  <Input model="role" label="Role" />
                  <Input label="National Provider Identifier (NPI)" model="npi" />
                </>
              )}

              {localReference?.type === 'resident' && (
                <Input
                  model="relationship"
                  label="Relationship to Client"
                  validations={{
                    presence: {
                      message: "Please enter the contact's relationship to client",
                    },
                  }}
                />
              )}

              <PhoneInput model="phone_no" label="Phone Number" />
              <Input glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />
              <EmailInput model="email" label="Email" />

              <AddressInput label="Contact Address" actionLabel="Find Address" model="address" />

              <Permission newFeatureFlag="zoom_gmeet">
                <WhatsAppInput label="Whatsapp" model="whatsapp" />
                <MessengerInput label="Facebook ID" model="facebook" />
                <SkypeInput label="Skype ID" model="skype" />
                <ZoomInput label="Zoom URL" model="zoom" />
                <GoogleMeetInput label="Google Meet URL" model="google_meet" />
              </Permission>

              <Select
                model="preferred_contact_method"
                label="Preferred Method of Communication"
                defaultValue="any"
                validations={{
                  presence: {
                    message: "Please select the contact's preferred method of communication",
                  },
                }}
              >
                <Option label="Any" value="any" />
                <Option label="Phone" value="phone" />
                <Option label="Text" value="text" />
                <Option label="Email" value="email" />
              </Select>
            </FormSection>
          </Section>

          {localReference?.type === 'organization' && (
            <>
              <Divider />

              <Section headingType="h2" title="Client Portal">
                <FormSection labelWidth="150px">
                  <CheckboxGroup label="Access" trueIcon="check" falseIcon="cross" falseStyle="none">
                    <Checkbox label="Share details with the Client Portal?" model="is_shared_with_portal" />
                  </CheckboxGroup>

                  <Textarea useQuickText isNested model="portal_notes" label="Portal Notes" minRows={4} maxWidth="100%" />
                </FormSection>
              </Section>
            </>
          )}

          <Divider />

          <Section headingType="h2" title="Attachments" description="Upload the files related to this contact">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={record.id} recordType={record.type} disableLinks={isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    const { $new, $editable, isInvalid } = this.state

    return (
      <Overlay.Footer>
        {$editable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={this.props.loading}
              onClick={this.save}
              isDisabled={isInvalid}
              flex="100 1 auto"
              permission="contacts.create"
            />
            {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={this.props.loading} onClick={this.cancel} />}
          </>
        )}

        {!$editable && (
          <>
            <Button
              glyph="edit"
              label="Edit Contact"
              type="default"
              isDisabled={this.props.loading}
              onClick={this.edit}
              flex="100 1 auto"
              permission="contacts.edit"
            />

            <DeleteDialog
              title="Delete Contact?"
              message="Are you sure you want to delete this contact? This action cannot be undone."
              onYes={this.delete}
            >
              <Button
                fullWidth
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={this.props.loading}
                //permission="contacts.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'contacts')

const mapStateToProps = (state, props) => ({
  ...defaultMapStateToProps(state, props.match, 'contacts'),
  client: state.me.client,
  record: get(state, `data.contacts.data.${props.dataID || props.match?.params?.id}`, {}),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withOverlayError(Contact)))
