import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { Button, Card, Link, Page, PageLayout, HelpTagIframe } from '@behavehealth/components'
import { CustomNotesTable } from '@behavehealth/components/Tables'
import { withPageError } from '@behavehealth/hocs/withPageError'

import useAPI from '@behavehealth/hooks/useAPI'
import useStore from '@behavehealth/modules/store'

const InternalNotes: React.FC = () => {
  const match = useRouteMatch()
  const isBehave = useStore((state) => state?.isBehave)

  const data = useSelector((state) => state.data.custom_notes?.data)
  const loading = useSelector((state) => state.data.custom_notes?.loading)

  useAPI('custom_notes', '/custom_notes?variant=internal')

  const isEmpty = size(data) === 0
  const actions = (
    <>
      {isBehave && <Button label="Import Behave Templates" type="default" glyph="add" link={`${match.url}/import`} />}
      <Button label="Add Note" type="primary" glyph="add" link={`${match.url}/new`} />
    </>
  )

  return (
    <Page feature="internal_notes" breakpoint="0" actions={!isEmpty && actions} help={<HelpTagIframe id="internal_notes" />}>
      <PageLayout>
        <Card>
          <CustomNotesTable
            useAutoPageSize
            title="Internal Notes"
            emptyDescription="No internal notes have been added yet"
            icon="behave_health"
            data={data}
            isLoading={isEmpty && loading}
            emptyActions={actions}
            to={(id) => `internal-notes/${id}`}
            localStorageKey="internal_notes"
          />
        </Card>
      </PageLayout>
    </Page>
  )
}

export default withPageError(InternalNotes)
