import React from 'react'

import { useSettings } from '../../../hooks/useSettings'
import { usDate, usDateTime } from '../../../utils/functions'
import DataList from '../../../components/DataList'
import Status from '../../../components/Status'

const STATUS_COLORS: any = {
  done: 'green',
  due_today: 'orange',
  to_do: 'blue',
  overdue: 'red',
}

export const TodoInfo = ({ data }: any) => {
  const { timezone } = useSettings()

  if (!data) return null

  return (
    <DataList isCompact withPadding labelWidth={120}>
      <DataList.Item label="Title" value={data.title} />
      <DataList.Item
        label="Status"
        value={<Status color={STATUS_COLORS[data.display_status]} label={data.display_status?.replace('_', ' ')} />}
      />
      <DataList.Item label="Date Added" value={usDateTime(data.created_at, timezone)} />
      <DataList.Item label="Last Edited" value={usDateTime(data.updated_at, timezone)} />
    </DataList>
  )
}
