import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DEFAULT_FILTERS } from '../Filters/constants'
import { niceAmount, nicePercentage, titleCase } from '../../utils/functions'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

export const AdminDiscountTemplatesDataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            as={NavLink}
            id={data.id}
            value={data.name}
            icon={data?.icon}
            to={props.to ? props.to(data) : undefined}
            onClick={props.onClick ? () => props.onClick(data) : undefined}
          />
        ),
      },
      {
        title: 'Status',
        model: 'status',
        type: 'title',
        width: 120,
      },
      {
        title: 'SKU',
        id: 'sku',
        model: 'data.sku',
      },
      {
        title: 'Tags',
        model: 'global_tags',
        type: 'global_tags',
        editPermission: true,
      },
      {
        title: 'Smart Flags',
        model: 'smart_flags',
        type: 'smart_flags',
        disableSort: true,
        editPermission: true,
        width: 300,
      },
      {
        title: 'Discount Type',
        id: 'discount_type',
        model: 'data.discount_type',
        formatValue: ({ value }: any) => titleCase(value),
      },
      {
        title: 'Discounted Amount',
        id: 'amount_off',
        model: 'data.amount_off',
        formatValue: ({ data }: any) => {
          if (data?.data?.discount_type === 'fixed') {
            return niceAmount(data?.data?.amount_off)
          }

          return null
        },
      },
      {
        title: 'Discounted Percentage',
        id: 'percent_off',
        model: 'data.percent_off',
        formatValue: ({ data }: any) => {
          if (data?.data?.discount_type === 'percentage') {
            return `${nicePercentage(data?.data?.percent_off)}%`
          }

          return null
        },
      },
      {
        title: 'Duration',
        id: 'duration',
        model: 'data.duration',
        formatValue: ({ value, data }: any) => {
          if (value === 'number_of_months') {
            return `${data?.data?.max_redemptions} Months`
          }

          return titleCase(value)
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return <DataTable asCard title="Discount Templates" icon="certificate" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Archived', value: 'archived' },
    ],
  },
  sku: {
    label: 'SKU',
    type: 'string',
  },
  global_tags: DEFAULT_FILTERS.global_tags,
  smart_flags: {
    label: 'Smart Flags',
    type: 'smart_flags',
    glyph: 'flag',
  },
  discount_type: {
    label: 'Discount Type',
    type: 'multi_select',
    options: [
      { label: 'Fixed', value: 'fixed' },
      { label: 'Percentage', value: 'percentage' },
    ],
  },
  amount_off: {
    label: 'Discounted Amount',
    type: 'number',
  },
  percent_off: {
    label: 'Discounted Percentage',
    type: 'number',
  },
  duration: {
    label: 'Duration',
    type: 'multi_select',
    options: [
      { label: 'Once', value: 'once' },
      { label: 'Max Redemptions', value: 'number_of_months' },
      { label: 'Forever', value: 'forever' },
    ],
  },
  max_redemptions: {
    label: 'Max Redemptions',
    type: 'number',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
