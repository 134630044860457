import React from 'react'

import ContextShow from '../../../components/Forms/ContextShow'
import Medications from '../../../components/SmartContent/Medications'
import Option from '../../../components/Forms/Option'
import Radio from '../../../components/Forms/Radio'
import RadioGroup from '../../../components/Forms/RadioGroup'
import Select from '../../../components/Forms/Select'
import SmartTextarea from '../../../components/Forms/SmartTextarea'
import TreatmentHistoryForm from '../../../components/Overlays/pages/DataForms/common/TreatmentHistoryForm'

import {
  BIO_MEDICAL_FIELDS,
  COGNITIVE_EMOTIONAL_BEHAVIORAL_FIELDS,
  MOTIVATION_FOR_CHANGE_FIELDS,
  RECOVERY_ENVIRONMENT_FIELDS,
  RELAPSE_POTENTIAL_FIELDS,
  SNAP_FIELDS,
  SUBSTANCE_USE_HISTORY,
  WITHDRAWAL_SYMPTOMS_FIELDS,
} from './common'

export const PRE_SCREEN_SECTIONS = [
  {
    title: 'Current Medications',
    model: 'medications',
    fields: [
      {
        label: 'Medications List',
        model: 'medications_list',
        component: ({ isEditable, client }: any) => (
          <Medications
            model="data.medications.meds"
            isEditable={isEditable}
            client={client}
            icon="medications"
            title="Medications"
            emptyDescription="No medications added yet"
          />
        ),
      },
    ],
  },
  {
    title: 'Data',
    model: 'data',
    fields: [
      {
        label: 'What made the client decide to seek treatment now? (i.e. family intervention, medical problem, legal issues, etc.)',
        model: 'reason_for_seeking_treatment',
        component: () => (
          <SmartTextarea
            useQuickText
            useDictation
            label="What made the client decide to seek treatment now? (i.e. family intervention, medical problem, legal issues, etc.)"
            description="Please be specific"
            model="data.data.reason_for_seeking_treatment"
          />
        ),
      },
      {
        label: 'Has the client ever had a seizure as a result of withdrawals?',
        model: 'has_had_seizure_from_withdrawal',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever had a seizure as a result of withdrawals?"
              model="data.data.has_had_seizure_from_withdrawal"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.data.has_had_seizure_from_withdrawal" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="When?"
                model="data.data.seizures_when"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: "Has the client ever had any other types of withdrawal symptoms? (DT's, hallucinations, etc.)",
        model: 'has_had_withdrawal_symptoms',
        component: () => (
          <>
            <RadioGroup
              label="Has the client ever had any other types of withdrawal symptoms? (DT's, hallucinations, etc.)"
              model="data.data.has_had_withdrawal_symptoms"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.data.has_had_withdrawal_symptoms" is={true}>
              <SmartTextarea
                useQuickText
                useDictation
                label="Please explain:"
                model="data.data.withdrawal_symptoms_extra"
                validations={{
                  presence: {
                    message: 'Please provide this information',
                  },
                }}
              />
            </ContextShow>
          </>
        ),
      },
      {
        label: 'Does the client have any ongoing medical conditions?',
        model: 'has_ongoing_medical_conditions',
        component: () => (
          <>
            <RadioGroup
              label="Does the client have any ongoing medical conditions?"
              model="data.data.has_ongoing_medical_conditions"
              layout="horizontal-dense"
            >
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.data.has_ongoing_medical_conditions" is={true}>
              <>
                <RadioGroup
                  label="Has the client received medical treatment for these conditions?"
                  model="data.data.has_received_treatment_for_medical_conditions"
                  layout="horizontal-dense"
                >
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>

                <ContextShow when="data.data.has_received_treatment_for_medical_conditions" is={true}>
                  <SmartTextarea
                    useQuickText
                    useDictation
                    label="Please explain:"
                    model="data.data.treatment_extra"
                    validations={{
                      presence: {
                        message: 'Please provide this information',
                      },
                    }}
                  />
                </ContextShow>
              </>
            </ContextShow>
          </>
        ),
      },
      {
        label: 'How would the client rate their cravings on a scale of 1 to 10 (1 = lowest, 10 = highest)?',
        model: 'craving_scale',
        component: () => (
          <Select
            label="How would the client rate their cravings on a scale of 1 to 10 (1 = lowest, 10 = highest)?"
            model="data.data.craving_scale"
          >
            <Option label="1" value={1} />
            <Option label="2" value={2} />
            <Option label="3" value={3} />
            <Option label="4" value={4} />
            <Option label="5" value={5} />
            <Option label="6" value={6} />
            <Option label="7" value={7} />
            <Option label="8" value={8} />
            <Option label="9" value={9} />
            <Option label="10" value={10} />
          </Select>
        ),
      },
      {
        label: 'Is the client currently experiencing cravings?',
        model: 'has_cravings',
        component: () => (
          <>
            <RadioGroup label="Is the client currently experiencing cravings?" model="data.data.has_cravings" layout="horizontal-dense">
              <Radio label="Yes" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="data.data.has_cravings" is={true}>
              <Select label="To what degree? (1 = lowest, 10 = highest)?" model="data.data.current_craving_scale">
                <Option label="1" value={1} />
                <Option label="2" value={2} />
                <Option label="3" value={3} />
                <Option label="4" value={4} />
                <Option label="5" value={5} />
                <Option label="6" value={6} />
                <Option label="7" value={7} />
                <Option label="8" value={8} />
                <Option label="9" value={9} />
                <Option label="10" value={10} />
              </Select>
            </ContextShow>
          </>
        ),
      },
    ],
  },
  {
    title: 'Substance Use History',
    model: 'substance_use_history',
    fields: SUBSTANCE_USE_HISTORY,
  },
  {
    title: 'Treatment History for Mental Health and Addiction',
    model: 'treatment_history',
    fields: [
      {
        label: 'Treatment History Form',
        model: 'treatment_history_form',
        component: () => <TreatmentHistoryForm />,
      },
    ],
  },
  {
    title: 'Dimension 1: Withdrawal Symptoms',
    model: 'withdrawal_symptoms',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: WITHDRAWAL_SYMPTOMS_FIELDS,
  },
  {
    title: 'Dimension 2: Bio-Medical',
    model: 'bio_medical',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: BIO_MEDICAL_FIELDS,
  },
  {
    title: 'Dimension 3: Emotional, Behavioral or Cognitive Conditions and Complications',
    model: 'cognitive_emotional_behavioral',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: COGNITIVE_EMOTIONAL_BEHAVIORAL_FIELDS,
  },
  {
    title: 'Dimension 4: Readiness for Change',
    model: 'motivation_for_change',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: MOTIVATION_FOR_CHANGE_FIELDS,
  },
  {
    title: 'Dimension 5: Relapse, Continued Use, or Continued Problem Potential',
    model: 'relapse_potential',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: RELAPSE_POTENTIAL_FIELDS,
  },
  {
    title: 'Dimension 6: Recovery / Living Environment',
    model: 'recovery_environment',
    navParent: { id: 'dimensions', name: 'Dimensions' },
    fields: RECOVERY_ENVIRONMENT_FIELDS,
  },
  {
    title: 'Strengths, Needs, Abilities, Preferences',
    model: 'snap',
    fields: SNAP_FIELDS,
  },
  {
    title: 'Summary',
    model: 'summary',
    fields: [
      {
        label: 'Interpretive Summary',
        model: 'interpretive_summary',
        component: () => <SmartTextarea useQuickText useDictation label="Interpretive Summary" model="data.summary.interpretive_summary" />,
      },
    ],
  },
  {
    title: 'Single Case Agreement',
    model: 'single_case_agreement',
    fields: [
      {
        label: 'Agreement Justification',
        model: 'agreement_justification',
        component: () => (
          <SmartTextarea useQuickText useDictation label="Agreement Justification" model="data.single_case_agreement.justification" />
        ),
      },
    ],
  },
]
