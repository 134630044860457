import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { countWord, mapToArray } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'
import Notifications from '../../modules/notifications'

import Button from '../../components/Button'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import useAnalytics from '../../hooks/useAnalytics'

import { Worksheet } from '../../components/Worksheet/Worksheet'

export const ShiftNoteWorksheetOverlay = (props: any) => {
  const { onClose, onSuccess, invalidateKeys } = props

  const { trackEvent } = useAnalytics()

  const form = React.useRef()
  const history = useHistory()
  const location = useLocation()

  const [isValid, setIsValid] = React.useState(false)
  const [rowsCount, setRowsCount] = React.useState(0)

  const { mutateAsync: save, isLoading: isSaving }: any = useCreate({
    name: ['create-shift-notes'],
    url: `/housing_notes/batch`,
    invalidate: 'shift-notes',
    invalidateKeys: invalidateKeys,
    onSuccess: () => {
      Notifications.send(`Successfully created ${countWord('Shift Notes', rowsCount)}`, 'positive')

      trackEvent({ name: 'Worksheet Saved', params: { worksheetType: 'Shift Note' } })

      if (onSuccess) onSuccess()
      if (onClose) onClose()
    },
  })

  const close = () => {
    if (onClose) return onClose()

    const url = location?.parent ? location.parent.url : location.pathname.substr(0, location.pathname.lastIndexOf('/'))
    history.push(url)
  }

  const handleSave = async () => {
    const data = form.current.getFormValue()

    await save(mapToArray(data))

    close()
  }

  React.useEffect(() => {
    trackEvent({ name: 'Worksheet Opened', params: { worksheetType: 'Shift Note' } })
  }, [])

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Category',
        model: 'category',
        type: 'select',
        config: {
          options: [
            { label: 'General Note', value: 'general_note' },
            { label: 'Incident Report', value: 'incident_report' },
          ],
          validations: {
            presence: {
              message: 'Please select a category',
            },
          },
        },
      },
      {
        title: 'Status',
        model: 'status',
        type: 'select',
        config: {
          options: [
            { label: 'Draft', value: 'draft' },
            { label: 'Pending Review', value: 'pending_review' },
            { label: 'Updated Required', value: 'updates_required' },
            { label: 'Signed Off', value: 'signed_off' },
            { label: 'Completed', value: 'completed' },
            { label: 'Closed', value: 'closed' },
          ],
        },
      },
      {
        title: 'Note',
        model: 'description',
        type: 'rich_text',
        config: {
          validations: {
            presence: {
              message: 'Please add a note description',
            },
          },
        },
      },
      {
        title: 'Date & Time',
        model: 'dated_at',
        type: 'date_time',
        config: {
          defaultTo: 'now',
          validations: {
            presence: {
              message: 'Please add a date & time',
            },
          },
        },
      },
      {
        title: 'Staff',
        model: 'employees',
        type: 'multi_object_selector',
        config: {
          endpoint: '/employees',
          queryKey: 'employees',
          queryParams: { status: 'active' },
          selectTitle: (data: any) => data.name,
          validations: {},
        },
      },
      {
        title: 'Clients',
        model: 'residents',
        type: 'multi_object_selector',
        config: {
          endpoint: '/residents',
          queryKey: 'residents',
          queryParams: { status: 'current' },
          selectTitle: (data: any) => data.name,
          validations: {
            presence: {
              message: 'Please select at least one client',
            },
          },
        },
      },
      {
        title: 'Location',
        model: 'house',
        type: 'object_selector',
        config: {
          endpoint: '/houses',
          queryKey: 'locations',
          queryParams: { category: 'housing,mixed_category' },
          selectTitle: (data: any) => data.name,
          validations: {},
        },
      },
      {
        title: 'Organization',
        model: 'organization',
        type: 'object_selector',
        config: {
          endpoint: '/organizations',
          queryKey: 'organizations',
          selectTitle: (data: any) => data.name,
          validations: {},
        },
      },
    ] as const
  }, [])

  return (
    <Overlay showBackdrop onClose={onClose} position="center" maxWidth={140}>
      <Overlay.Header title="Create Shift Notes" icon="housing_notes" />

      <Overlay.Content className="!p-4">
        <Grid gap="1rem" className="!pb-3">
          <Worksheet
            asCard
            getForm={form}
            title="Shift Notes"
            allow="create-update-delete"
            columns={columns}
            onValidationUpdate={setIsValid}
            onRowsCountUpdate={setRowsCount}
          />
        </Grid>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={`Save ${countWord('Shift Notes', rowsCount)}`}
          glyph="check"
          type="primary"
          color="green"
          onClick={handleSave}
          isLoading={isSaving}
          isDisabled={!isValid}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
