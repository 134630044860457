import React from 'react'
import { NavLink, Navigate, Route, Routes, useParams } from 'react-router-dom-v5-compat'
import snakeCase from 'lodash/snakeCase'

import { withPageError } from '@behavehealth/hocs/withPageError'

import Tabs from '@behavehealth/components/Tabs'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'

import { TreatmentBookGoalOverlay } from '@behavehealth/constructs/TreatmentBook/TreatmentBookGoalOverlay'
import { TreatmentBookInterventionOverlay } from '@behavehealth/constructs/TreatmentBook/TreatmentBookInterventionOverlay'
import { TreatmentBookObjectiveOverlay } from '@behavehealth/constructs/TreatmentBook/TreatmentBookObjectiveOverlay'
import { TreatmentBookProblemOverlay } from '@behavehealth/constructs/TreatmentBook/TreatmentBookProblemOverlay'

import { TreatmentPlanBookGoals } from '../pages/treatment_plan_book_goals'
import { TreatmentPlanBookInterventions } from '../pages/treatment_plan_book_interventions'
import { TreatmentPlanBookObjectives } from '../pages/treatment_plan_book_objectives'
import { TreatmentPlanBookProblems } from '../pages/treatment_plan_book_problems'
import { TreatmentPlanBookTree } from '../pages/treatment_plan_book_tree'

const RootTreatmentBookCategoryPage = () => {
  const params: any = useParams()
  const category = snakeCase(params?.category)

  return (
    <>
      <div>
        <Tabs>
          <Tabs.List className="px-4">
            <Tabs.Item as={NavLink} label="Problems" to={`problems`} />
            <Tabs.Item as={NavLink} label="Goals" to={`goals`} />
            <Tabs.Item as={NavLink} label="Objectives" to={`objectives`} />
            <Tabs.Item as={NavLink} label="Interventions" to={`interventions`} />
            <Tabs.Divider />
            <Tabs.Item as={NavLink} label="Tree View" to={`tree`} />
          </Tabs.List>
        </Tabs>

        <div className="p-4">
          <Routes>
            <Route index element={<Navigate to="problems" replace />} />

            <Route path={`problems/*`} element={<TreatmentPlanBookProblems category={category} />} />
            <Route path={`goals/*`} element={<TreatmentPlanBookGoals category={category} />} />
            <Route path={`objectives/*`} element={<TreatmentPlanBookObjectives category={category} />} />
            <Route path={`interventions/*`} element={<TreatmentPlanBookInterventions category={category} />} />
            <Route path={`tree/*`} element={<TreatmentPlanBookTree category={category} />} />
          </Routes>
        </div>
      </div>

      <AnimatedRoutes>
        <Route exact path={`problems/:id`} element={<TreatmentBookProblemOverlay useV6Router category={category} />} />
        <Route exact path={`goals/:id`} element={<TreatmentBookGoalOverlay useV6Router category={category} />} />
        <Route exact path={`objectives/:id`} element={<TreatmentBookObjectiveOverlay useV6Router category={category} />} />
        <Route exact path={`interventions/:id`} element={<TreatmentBookInterventionOverlay useV6Router category={category} />} />
      </AnimatedRoutes>
    </>
  )
}

export const TreatmentBookCategoryPage = withPageError(RootTreatmentBookCategoryPage)
