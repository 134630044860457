import React from 'react'

import Avatar from '../../Avatar'
import Empty from './Empty'

const Profile: React.FC = ({ avatar, name }) => {
  if (!name) return <Empty />

  return (
    <div css={{ display: 'flex', alignItem: 'center' }}>
      <Avatar magnify src={avatar} size="1.5rem" initials={name} css={{ marginRight: '0.4rem' }} />
      {name}
    </div>
  )
}

export default Profile
