import React from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import merge from 'lodash/merge'
import produce from 'immer'

import { H3, Text } from '../../components/Typography'
import { useGet, useCreate } from '../../hooks/useNewAPI'

import Button from '../../components/Button'
import DataList from '../../components/DataList'
import Dialog from '../../components/Dialog'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import NoInternet from '../../components/Alerts/NoInternet'
import Overlay from '../../components/Overlay'
import ClientStatus from '../../components/Statuses/ClientStatus'
import Workflow from '../../components/Workflow/Workflow'

import BedDischarge from './common/BedDischarge'
import DeclineDetails from './common/DeclineDetails'
import Events from './common/Events'
import FinancialsWithCancel from './common/FinancialsWithCancel'
import ProgramsDischarge from './common/ProgramsDischarge'
import Todos from './common/Todos'

import withSettings from '../../hocs/withSettings'

import { usDateTime } from '../../utils/functions'
import { withOverlayError } from '../../hocs/withOverlayError'

import { SecureMessage } from './common/SecureMessage'

const initialSteps = {
  decline_details: 'todo',
  bed_assignment: 'todo',
  programs_discharge: 'todo',
  financials: 'todo',
  todos: 'todo',
  events: 'todo',
  decline: 'todo',
}

const stepsReducer = (state: any, payload: any) => {
  const { step, status } = payload
  if (!status || !state.hasOwnProperty(step)) return state

  return produce(state, (draft: any) => {
    draft[step] = status
  })
}

const initialData = {
  remove_access_controls: true,
  remove_from_programs: true,
  should_end_current_bed_occupancy: 'on_discharge_date',
  should_end_current_program_occupancies: 'on_discharge_date',
}

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const RootAdmissionDecline = ({ online, timezone }: any) => {
  const match = useRouteMatch()
  const location = useLocation()
  const history = useHistory()

  const { resource_id } = match.params

  const { data: client, isLoading }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const [steps, setSteps] = React.useReducer(stepsReducer, initialSteps)
  const [data, setData] = React.useReducer(dataReducer, initialData)

  const { mutateAsync, isLoading: isUpdating }: any = useCreate({
    name: ['client', resource_id],
    url: `/residents/${resource_id}/decline`,
    invalidateKeys: ['admissions', 'clients', ['client', resource_id]],
  })

  React.useEffect(() => {
    if (!client) return

    updateData({
      occupancy: client.current_bed_occupancy || client.next_bed_occupancy,
    })
  }, [client])

  const close = () => {
    if (location.parent) {
      if (location.parent.url) history.push(location.parent.url)
      else history.push(location.parent)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  const decline = async () => {
    await mutateAsync(data)
    close()
  }

  const updateData = (newValue: any) => {
    const newData = produce(data, (draft: any) => {
      draft = merge(draft, newValue)
    })

    setData(newData)
  }

  if (!client) return null

  return (
    <Overlay
      fullheight
      showBackdrop
      onClose={close}
      position="center"
      maxWidth={44}
      closeOnBackdrop={false}
      closeWrapper={(closeElement: any) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost if not saved on the Review & Save step"
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={close}
        >
          {closeElement}
        </Dialog>
      )}
    >
      <Overlay.Header glyph="decline" title="Decline Client" />

      <Overlay.Content>
        <Workflow>
          {/* Discharge Details */}
          <Workflow.Panel step="decline_details">
            <Workflow.Header title="Decline Details" after={<Workflow.Status status={steps.decline_details} />} />
            <Workflow.Content>
              <DeclineDetails
                data={data}
                setData={updateData}
                firstName={client?.first_name}
                setStatus={(status: string) => {
                  setSteps({ step: 'decline_details', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Bed Assignment */}
          <Workflow.Panel showForCommunity step="bed_assignment" featureFlagV2="bed_management">
            <Workflow.Header title="Bed Discharge" after={<Workflow.Status status={steps.bed_assignment} />} />
            <Workflow.Content>
              <BedDischarge
                data={data}
                hasCurrentOccupancy={!!client.current_bed_occupancy || !!client.next_bed_occupancy}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'bed_assignment', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Programs Discharge */}
          <Workflow.Panel step="programs_discharge" featureFlagV2="programs">
            <Workflow.Header title="Programs" after={<Workflow.Status status={steps.programs_discharge} />} />
            <Workflow.Content>
              <ProgramsDischarge
                client={client}
                data={data}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'programs_discharge', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Financials */}
          <Workflow.Panel step="financials" newFeatureFlag="rcm_consumer">
            <Workflow.Header title="Financials" after={<Workflow.Status status={steps.financials} />} />
            <Workflow.Content>
              <FinancialsWithCancel
                match={match}
                location={location}
                firstName={client?.first_name}
                data={data}
                setData={updateData}
                setStatus={(status: string) => {
                  setSteps({ step: 'financials', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Todos */}
          <Workflow.Panel showForCommunity step="todos">
            <Workflow.Header title="To-Do's" after={<Workflow.Status status={steps.todos} />} />
            <Workflow.Content>
              <Todos
                match={match}
                timezone={timezone}
                setStatus={(status: string) => {
                  setSteps({ step: 'todos', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Events */}
          <Workflow.Panel showForCommunity step="events">
            <Workflow.Header title="Events" after={<Workflow.Status status={steps.events} />} />
            <Workflow.Content>
              <Events
                match={match}
                timezone={timezone}
                setStatus={(status: string) => {
                  setSteps({ step: 'events', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Discharge */}
          <Workflow.Panel step="decline">
            <Workflow.Header title="Decline" after={<Workflow.Status status={steps.decline} />} />
            <Workflow.Content>
              <FormSection maxWidth="100%">
                <DataList>
                  <DataList.Item label="Decline Date" value={usDateTime(data?.declined_at, timezone)} />
                  <DataList.Item label="Decline Notes" value={data?.decline_reason} />
                  <DataList.Item label="Cancel Future Planned Charges" value="Yes" />
                  <DataList.Item label="Remove from All Programs" value={data?.remove_from_programs ? 'Yes' : 'No'} />
                </DataList>

                <Grid gap={16} style={{ marginBottom: '2rem' }}>
                  <H3>Upon Declining…</H3>
                  <Text
                    glyph="check"
                    layout="vertical"
                    description={
                      <span style={{ marginRight: '0.5rem' }}>
                        The client's status will be set to <ClientStatus status="declined" inline />
                      </span>
                    }
                    setIconColor={false}
                  />
                  <Text
                    glyph="check"
                    layout="vertical"
                    description={
                      <span style={{ marginRight: '0.5rem' }}>The client will be unassigned from their Bed (if assigned one)</span>
                    }
                    setIconColor={false}
                  />
                </Grid>

                <SecureMessage data={data} setData={updateData} category="decline" />

                {online && (
                  <Button label="Decline Client" type="primary" color="green" onClick={decline} isLoading={isLoading || isUpdating} />
                )}

                {!online && <NoInternet />}
              </FormSection>
            </Workflow.Content>
          </Workflow.Panel>
        </Workflow>
      </Overlay.Content>
    </Overlay>
  )
}

export const AdmissionDecline = withOverlayError(withSettings(RootAdmissionDecline))
