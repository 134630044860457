import React, { useState } from 'react'
import produce from 'immer'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'

import { setLocalStorageFilters, getLocalStorageFilters } from './localStorage'

const DEFAULT_FILTERS = {}

export const useFilters = (onUpdate: any, localStorageKey?: string, defaultFilters?: any) => {
  const localStorageFilters = getLocalStorageFilters(localStorageKey)

  const initialFilters = {
    ...defaultFilters,
    ...localStorageFilters,
    ...DEFAULT_FILTERS,
  }

  const [filters, setFilters]: any = useState(initialFilters)

  const isEmpty = size(filters) === 0
  const encodedFilters = btoa(JSON.stringify({ filters }))

  const addFilter = (searchKey: any, value: any) => {
    setFilters(
      produce(filters, (draft: any) => {
        draft[searchKey] = value
      }),
    )
  }

  const setFilter = (searchKey: any, value: any) => {
    setFilters(
      produce(filters, (draft: any) => {
        draft[searchKey] = value
      }),
    )
  }

  const removeFilter = (searchKey: any) => {
    setFilters(
      produce(filters, (draft: any) => {
        delete draft[searchKey]
      }),
    )
  }

  const resetFilters = () => {
    setFilters(DEFAULT_FILTERS)
  }

  React.useEffect(() => {
    const trimmedFilters = produce(filters, (draft: any) => {
      for (const key in draft) {
        const value = draft[key]?.value

        if (typeof value === 'boolean') continue

        if (typeof value === 'string' && size(value) === 0) {
          delete draft[key]
          continue
        }

        if (Array.isArray(value) && size(value) === 0) {
          delete draft[key]
          continue
        }

        if (isUndefined(value)) {
          delete draft[key]
        }
      }
    })

    if (onUpdate) onUpdate(trimmedFilters)
    if (localStorageKey) setLocalStorageFilters(localStorageKey, trimmedFilters)
  }, [filters])

  return {
    addFilter,
    encodedFilters,
    filters,
    isEmpty,
    removeFilter,
    resetFilters,
    setFilter,
    setFilters,
  }
}
