import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { beautifulAmount } from '@behavehealth/utils/functions'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { InvoicesDataTable } from '@behavehealth/constructs/InternalBilling/InvoicesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks'

import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Flex from '@behavehealth/components/Flex'
import Glyph from '@behavehealth/components/Glyph'
import Icon from '@behavehealth/components/Icon'
import Markup from '@behavehealth/components/Markup'
import PageLayout from '@behavehealth/components/PageLayout'
import PageSection from '@behavehealth/components/PageSection/PageSection'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'

import { EHRInvoiceBuilderOverlay } from '@behavehealth/constructs/InternalBilling/EHRInvoiceBuilderOverlay'
import { EHRInvoiceOverlay } from '@behavehealth/constructs/InternalBilling/EHRInvoiceOverlay'
import { getInvoiceDetailsFromFacility } from '@behavehealth/constructs/InternalBilling/functions'
import InternalBillingPaymentMethods from '@behavehealth/constructs/PaymentMethods/InternalBillingPaymentMethods'
import InternalNotesOverlay from '@behavehealth/components/Billing/InternalNotesOverlay'

const InvoicesPayments = () => {
  const { isBehave, tenant } = useSettings()

  // const [tab, setTab] = React.useState('invoices')

  const [invoiceOverlayOpen, setInvoiceOverlayOpen] = React.useState(false)
  const [recurringInvoiceOverlayOpen, setRecurringInvoiceOverlayOpen] = React.useState(false)

  const invoicesTableProps = useDataTable({
    name: ['invoices'],
    endpoint: `/facilities/${tenant?.id}/invoices`,
  })

  return (
    <PageLayout>
      {(isBehave || tenant?.metadata?.invoices_notes) && (
        <PageSection>
          <PageSection.Header
            graphic={
              <div className="flex items-center justify-center w-6 h-6">
                <Glyph glyph="note" size={18} />
              </div>
            }
            after={
              <Flex gap="0.5rem">
                <SummonOverlay
                  isPermissible={isBehave}
                  overlay={<InternalNotesOverlay title="Edit Notes" data={tenant} model="metadata.invoices_notes" />}
                >
                  <Button label="Edit Notes" glyph="edit" type="default" size={200} permission={isBehave} />
                </SummonOverlay>
              </Flex>
            }
          >
            <PageSection.Title title="Notes" />
          </PageSection.Header>

          {tenant?.metadata?.invoices_notes && (
            <PageSection.Content>
              <Card className="!py-3 !px-4">
                <Markup value={tenant?.metadata?.invoices_notes} />
              </Card>
            </PageSection.Content>
          )}
        </PageSection>
      )}

      <PageSection>
        <InternalBillingPaymentMethods record={tenant} buttonType="primary" />
      </PageSection>

      <PageSection>
        <PageSection.Header
          graphic={
            <div className="flex items-center justify-center w-6 h-6">
              <Icon icon="invoices" size={20} />
            </div>
          }
          after={
            isBehave && (
              <Dropdown label="Add Invoice…" buttonType="primary" glyph="add">
                <DropdownItem label="Single Invoice" icon="invoices" size={200} onClick={() => setInvoiceOverlayOpen(true)} />
                <DropdownItem
                  label="Multiple Invoices"
                  glyph="stack"
                  color="green"
                  size={200}
                  onClick={() => setRecurringInvoiceOverlayOpen(true)}
                />
              </Dropdown>
            )
          }
        >
          <PageSection.Title title="Invoices" />
        </PageSection.Header>

        {/* {isBehave && (
          <Tabs activeTab={tab} onChange={setTab}>
            <Tabs.List className="-mt-3 mb-4">
              <Tabs.Item label="Invoices" name="invoices" />
              <Tabs.Item label="🚧 Recurring Invoices" name="recurring_invoices" />
            </Tabs.List>
          </Tabs>
        )} */}

        <InvoicesDataTable {...invoicesTableProps} to={(data: any) => `invoices/${data.id}`} hiddenColumnIds={['facility']} />

        {/* {tab === 'recurring_invoices' && <RecurringInvoices />} */}

        {isBehave && (
          <>
            <SummonOverlay
              isOpen={invoiceOverlayOpen}
              onClose={() => setInvoiceOverlayOpen(false)}
              overlay={<EHRInvoiceOverlay dataID="new" initialData={getInvoiceDetailsFromFacility(tenant)} />}
            />

            <SummonOverlay
              isOpen={recurringInvoiceOverlayOpen}
              onClose={() => setRecurringInvoiceOverlayOpen(false)}
              overlay={<EHRInvoiceBuilderOverlay dataID="new" initialData={getInvoiceDetailsFromFacility(tenant)} />}
            />
          </>
        )}
      </PageSection>
    </PageLayout>
  )
}

const RecurringInvoices = (props: any) => {
  const { isBehave, tenant } = useSettings()

  const invoicesTableProps = useDataTable({
    name: ['invoices'],
    endpoint: `/facilities/${tenant?.id}/invoices`,
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Recurring Invoice #',
        model: 'invoice_number',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell as={NavLink} id={data.id} to={props.to ? props.to(data) : undefined} value={value || data.identifier} />
        },
      },
      {
        title: 'Total Amount',
        model: 'total_amount',
        width: 140,
        hoverExpand: false,
        formatValue: ({ data }: any) => {
          if (!data) return null

          return (
            <span className="flex justify-end font-[600]">
              {beautifulAmount(data?.category === 'legacy' ? data.amount : data?.calculated_amounts?.total)}
            </span>
          )
        },
      },
      {
        title: 'Billed To Name',
        model: 'billed_to_name',
      },
      {
        title: 'Billed To Company',
        model: 'billed_to_company_name',
      },
      {
        title: 'Billed To Email',
        model: 'billed_to_email',
        type: 'email',
      },
      {
        title: 'Billed To Phone',
        model: 'billed_to_phone_no',
        type: 'phone',
      },
      {
        title: 'Billed By Name',
        model: 'billed_by_name',
      },
      {
        title: 'Billed By Company',
        model: 'billed_by_company_name',
      },
      {
        title: 'Billed By Email',
        model: 'billed_by_email',
        type: 'email',
      },
      {
        title: 'Billed By Phone',
        model: 'billed_by_phone_no',
        type: 'phone',
      },
      {
        title: 'Next Due Date',
        model: 'due_date',
        type: 'date',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [],
  )

  if (!isBehave) return null

  return <DataTable asCard title="Recurring Invoices" icon="invoices" columns={columns} {...invoicesTableProps} />
}

export default InvoicesPayments
