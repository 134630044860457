import React from 'react'

import { Grid, Form, FormSection, Input, SubmitButton } from '@behavehealth/components'

const INPUTS_NO = 1000
const CHANGE_SPEED = 1000

const INPUTS = Array.from(Array(INPUTS_NO).keys())

let DATA = []
for (let i = 0; i < INPUTS.length; i++) {
  DATA.push((Math.random() + 1).toString(36).substring(7))
}

const Forms = () => {
  const form = React.createRef()
  const [valid, setValid] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const [data, setData] = React.useState({})

  const onSubmit = async (event) => {
    console.log(event)
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      const newData = {}

      for (let i = 0; i < INPUTS.length; i++) {
        const random = Math.floor(Math.random() * INPUTS_NO)
        newData[`id_${INPUTS[i]}`] = DATA[random]
      }

      setData(newData)
    }, CHANGE_SPEED)

    return () => clearInterval(interval)
  }, [data])

  return (
    <div>
      <div className="!w-full">
        <Form debug getForm={form} onValidationUpdate={setValid} onSubmit={onSubmit}>
          <FormSection>
            <Grid columns="repeat(7, 200px)" gap={8}>
              {INPUTS.map((id) => {
                return <Input debug={id === 0} model={`input_${id}`} label={`Input ${id + 1}`} type="text" value={data[`id_${id}`]} />
              })}
            </Grid>

            <SubmitButton
              glyph="lock"
              label="Log In Securely"
              type="primary"
              color="green"
              fullWidth
              isDisabled={!valid}
              isLoading={isLoading}
            />
          </FormSection>
        </Form>
      </div>
    </div>
  )
}

export default Forms
