import React from 'react'

import { ICONS } from '../../theme'
import { isBlank } from '../../utils/functions'
import { RECURRING_DAYS, RECURRING_FREQUENCIES } from '../../utils/recurrence'
import { SERVICE_CATEGORIES } from '../../utils/constants'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import AmountInput from '../../components/Forms/AmountInput'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Checkbox from '../../components/Forms/Checkbox'
import Glyph from '../../components/Glyph'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import Flex from '../../components/Flex'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import ServiceSelector from '../../components/ServiceSelector'
import Status from '../../components/Status'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'

import { PAYMENT_METHODS_LIST } from '../../utils/constants'

const RootClientServiceOverlay = (props: any) => {
	const {
		cancel,
		close,
		data,
		edit,
		form,
		id,
		initialModel,
		isEditable,
		isInvalid,
		isLoading,
		isNew,
		isOverlayLoading,
		isSaving,
		onValidationUpdate,
		saveWithData
	} = useOverlay({
		name: 'service',
		endpoint: '/services',
		invalidate: 'services',
		options: props
	})

	const { timezone } = useSettings()

	const [formData, setFormData] = React.useState(initialModel)
	const [category, setCategory] = React.useState('')
	const [subcategory, setSubcategory] = React.useState('')

	const { mutateAsync: archiveAsync, isLoading: isArchiving } = useUpdate({
		name: ['service', id],
		url: `/services/${id}/archive`,
		invalidate: 'services'
	})

	const { mutateAsync: unarchiveAsync, isLoading: isUnarchiving } = useUpdate({
		name: ['service', id],
		url: `/services/${id}/unarchive`,
		invalidate: 'services'
	})

	const save = () => {
		const subcategoryText = SERVICE_CATEGORIES[category]?.subcategories[subcategory].label

		saveWithData({
			...formData,
			expected_payment: formData.expected_payment?.model,
			name: subcategory === 'custom' ? formData?.name : subcategoryText,
			category: category,
			subcategory: subcategory,
			service_type: 'non_healthcare'
		})
	}

	const toggleArchive = async () => {
		if (data?.archived_at) {
			await unarchiveAsync({})
		} else {
			await archiveAsync({})
		}
	}

	React.useEffect(() => {
		if (!data?.category || !data?.subcategory) return

		setCategory(data?.category)
		setSubcategory(data?.subcategory)
	}, [data?.category, data?.subcategory])

	if (isOverlayLoading) {
		return <OverlayLoader position="right" />
	}

	return (
		<Overlay onClose={close}>
			<Overlay.Header icon="financials" title={isNew ? 'Add Service' : 'Service'} />

			<Overlay.Content>
				<Form
					getForm={form}
					onUpdate={setFormData}
					timezone={timezone}
					initialModel={initialModel}
					isEditable={isEditable}
					onValidationUpdate={onValidationUpdate}
				>
					<Section>
						<FormSection>
							{data?.archived_at && (
								<Alert glyph="info" type="neutral">
									This service is archived. No new charges can be created based on it.
								</Alert>
							)}

							{!isEditable && (
								<div>
									<h2 css={{ margin: '0 0 0.4rem' }}>{data?.name}</h2>
									<Status label={SERVICE_CATEGORIES?.[data?.category]?.label} color="green" />
								</div>
							)}

							<FormSection css={!isEditable && { display: 'none' }}>
								<ServiceSelector
									isOpen={isNew}
									category={data?.category}
									subcategory={data?.subcategory}
									onUpdate={(o) => {
										setCategory(o.category)
										setSubcategory(o.subcategory)
									}}
								/>

								{subcategory === 'custom' && (
									<Input
										label="Name"
										model="name"
										validations={{
											presence: {
												message: 'Please enter a service name'
											}
										}}
									/>
								)}
							</FormSection>

							<Input
								label="Order"
								type="number"
								model="order"
								tooltip="Add a number to set the order of the service in the list"
								size={4}
							/>

							<Textarea label="Description" model="description" />

							<RadioGroup
								label="Is this a one-time or repeating service?"
								model="is_recurring"
								layout="horizontal-dense"
								validations={{
									presence: {
										message: 'Please select an answer'
									}
								}}
							>
								<Radio label="One-time" value={false} />
								<Radio label="Repeating" value={true} />
							</RadioGroup>

							{/* Amount */}
							<FormSection horizontal className="items-end">
								<AmountInput
									label="Service Amount"
									model="amount"
									validations={{
										presence: {
											message: 'Please enter a service amount'
										}
									}}
								/>

								<Checkbox
									trueIcon="check"
									falseIcon="cross"
									falseStyle="faded"
									label="Set Custom Collection Amount"
									model="use_custom_collection"
								/>

								<ContextShow when="use_custom_collection" is={true}>
									<AmountInput
										label="Custom Collection Amount"
										model="custom_collection_amount"
										tooltip="Use this to Collect a different amount than the Service Amount when using Automatic Online Payments"
										validations={{
											presence: {
												message: 'Please enter a custom collection amount'
											}
										}}
									/>
								</ContextShow>

								<ContextShow when="is_time_based_amount" is={true}>
									<Select label="Per" model="amount_time" defaultValue="hour">
										<Option label="Hour" value="hour" />
										<Option label="Min" value="min" />
									</Select>
								</ContextShow>
							</FormSection>

							<ObjectSelector
								isRelation={false}
								className="!flex-auto"
								model="expected_payment"
								label="Expected Payment"
								apiData={PAYMENT_METHODS_LIST}
								showAvatars={false}
								selectTitle={(data: any) => (
									<>
										<Glyph glyph={data.glyph} /> {data.name}
									</>
								)}
								selectDescription={(data: any) => data.description}
								validations={{
									presence: {
										message: 'Please select an option'
									}
								}}
							/>

							{/* One-time */}
							{/* <ContextShow when="is_recurring" is={false}>
                <CheckboxGroup
                  label={isEditable ? 'Enable Charge Notes' : 'Charge Notes'}
                  trueIcon="check"
                  falseIcon="cross"
                  falseStyle="faded"
                >
                  <Checkbox
                    isDisabled
                    label="Allow Staff to create charges for this service via Charge Notes"
                    model="is_charge_note_enabled"
                  />
                </CheckboxGroup>
              </ContextShow> */}

							{/* Recurring */}
							<ContextShow when="is_recurring" is={true}>
								<RadioGroup
									label="Repeating Frequency"
									model="recurring_frequency"
									layout="vertical-dense"
									defaultValue="monthly"
									validations={{
										presence: {
											message: 'Please select a billing frequency'
										}
									}}
								>
									{Object.keys(RECURRING_FREQUENCIES).map((key) => (
										<Radio key={key} label={RECURRING_FREQUENCIES[key].label} value={key} />
									))}
								</RadioGroup>

								<ContextShow when="recurring_frequency" is="weekly">
									<Select
										asNumber
										label="Weekly Due Day"
										model="recurring_weekly_due_day"
										defaultValue={0}
										validations={{
											presence: {
												message: 'Please select a due day'
											}
										}}
									>
										{RECURRING_DAYS.weekly.map((key, idx) => (
											<Option key={idx} label={`Every ${key}`} value={idx} />
										))}
									</Select>
								</ContextShow>

								<ContextShow when="recurring_frequency" is="biweekly">
									<Select
										asNumber
										label="Biweekly Due Day"
										model="recurring_biweekly_due_day"
										defaultValue={0}
										validations={{
											presence: {
												message: 'Please select a due day'
											}
										}}
									>
										{RECURRING_DAYS.biweekly.map((key, idx) => (
											<Option key={idx} label={`Every other ${key}`} value={idx} />
										))}
									</Select>
								</ContextShow>

								<ContextShow when="recurring_frequency" is="monthly">
									<Select
										asNumber
										label="Monthly Due Day"
										model="recurring_monthly_due_day"
										defaultValue={1}
										validations={{
											presence: {
												message: 'Please select a due day'
											}
										}}
									>
										{RECURRING_DAYS.monthly.map((key, idx) => (
											<Option key={idx} label={key} value={idx + 1} />
										))}
									</Select>
								</ContextShow>
							</ContextShow>
						</FormSection>
					</Section>

					<Divider />

					<Section title="Programs & Locations">
						<MultiObjectSelector model="programs" label="Programs" type="programs" icon={ICONS.checklist} />
						<MultiObjectSelector model="houses" label="Locations" type="properties" icon={ICONS.properties} />
					</Section>

					<Divider />

					<Section title="Staff & Organizations">
						<FormSection>
							<MultiObjectSelector model="employees" label="Staff" type="employees" icon={ICONS.employees} />
							<MultiObjectSelector
								model="organizations"
								label="Organizations"
								type="organizations"
								icon={ICONS.organizations}
							/>
						</FormSection>
					</Section>

					<Divider />

					<Section title="Internal Cost & Notes">
						<FormSection>
							<Alert glyph="info">
								This information is <span css={{ fontStyle: 'italic' }}>not</span> displayed to any clients or payers.
							</Alert>

							<FormSection horizontal>
								<AmountInput label="Service Cost" model="internal_cost" />

								<ContextShow when="is_time_based_amount" is={true}>
									<Select label="Per" model="internal_amount_time" defaultValue="hour">
										<Option label="Hour" value="hour" />
										<Option label="Min" value="min" />
									</Select>
								</ContextShow>
							</FormSection>

							<Textarea label="Internal Notes" model="internal_notes" />
						</FormSection>
					</Section>

					<Divider />

					<Section title="Attachments">
						<Attachments model="documents" />
					</Section>

					{!isNew && data && (
						<>
							<Divider />

							<Section headingType="h2" title="Timeline">
								<Timeline isLoadingRecord={isLoading} recordID={data.id} recordType={data.type} />
							</Section>
						</>
					)}
				</Form>
			</Overlay.Content>

			<Overlay.Footer>
				{isEditable && (
					<>
						<Button
							label="Save"
							glyph="check"
							type="primary"
							color="green"
							flex="100 1 auto"
							onClick={save}
							isLoading={isSaving}
							isDisabled={isInvalid || isBlank(category || data?.category)}
						/>
						{!isNew && <Button label="Cancel" glyph="cross" type="default" onClick={cancel} isDisabled={isLoading} />}
					</>
				)}

				{!isEditable && (
					<>
						<Button
							label="Edit"
							glyph="edit"
							type="default"
							onClick={edit}
							isDisabled={isLoading}
							flex="100 1 auto"
							permission="settings.services_book.edit"
						/>

						{data?.archived_at ? (
							<Button
								label="Un-archive"
								glyph="delete"
								type="default"
								color="gray"
								onClick={toggleArchive}
								isLoading={isUnarchiving}
								permission="settings.services_book.edit"
							/>
						) : (
							<Button
								label="Archive"
								glyph="delete"
								type="default"
								color="gray"
								onClick={toggleArchive}
								isLoading={isArchiving}
								permission="settings.services_book.edit"
							/>
						)}
					</>
				)}
			</Overlay.Footer>
		</Overlay>
	)
}

export const ClientServiceOverlay = withOverlayError(RootClientServiceOverlay)
