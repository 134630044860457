import React from 'react'
import { DateTime } from 'luxon'
import { Element } from 'react-scroll'
import { v4 as uuid } from 'uuid'
import produce from 'immer'
import set from 'lodash/set'
import size from 'lodash/size'

import { apiUpdate } from '../../../modules/api'
import { COLORS } from '../../../theme'
import { countWord } from '../../../utils/functions'
import { validate } from '../../Forms/validators'
import { withFormContext } from '../../Forms/context'
import withSettings from '../../../hocs/withSettings'

import FieldBase from '../../Forms/FieldBase'
import Flex from '../../Flex'
import Glyph from '../../Glyph'

import AddComment from './AddComment'
import Comment from './Comment'

const DEFAULT_EMPTY_VALUE = []

class Comments extends FieldBase {
  constructor(props) {
    super(props)

    let value = props.value
    const model = `${props.model}._comments`

    if (!value) {
      const modelVal = props.form?.getField(model)
      const initialModelVal = props.form?.getInitialInputFieldValue(model)
      value = modelVal || initialModelVal || DEFAULT_EMPTY_VALUE
    }

    value = produce(value, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        draft[i].id = uuid()
      }
    })

    this.state = {
      type: 'COMMENTS',
      id: `${model}-${uuid()}`,
      model: model,
      value: value || DEFAULT_EMPTY_VALUE,
      isNested: props.isNested || false,
      isValid: true,
      isInvalid: false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isBlur: false,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
      isOpen: false,
    }

    this.initialData = {
      value: value,
    }
    this.updateType = 'DATA'

    if (props.openComments) props.openComments({ open: this.openComments })
  }

  addComment = async ({ content, status }: any) => {
    const newValue = produce(this.state.value, (draft: any) => {
      const now = DateTime.local().toISO()
      draft.push({
        id: uuid(),
        content: content,
        status: status,
        author: this.props.user,
        created_at: now,
        updated_at: now,
      })
    })

    this.changeValue(newValue)
  }

  removeComment = (idx: number) => {
    if (this.state.value.length === 0) return

    const newValue = produce(this.state.value, (draft: any) => {
      draft.splice(idx, 1)
    })

    this.changeValue(newValue)
  }

  updateComment = (index: any, content: any, status: any) => {
    const newValue = produce(this.state.value, (draft: any) => {
      draft[index].content = content
      draft[index].status = status
      draft[index].updated_at = DateTime.local().toISO()
    })

    this.changeValue(newValue)
  }

  onChanged = (state: any) => {
    const id = this.props.match?.params?.id
    if (id === 'new') return

    const name = this.props.name || 'data_forms'
    const url = this.props.url || '/data_forms'

    const initialValue = this.props.form?.getInitialModel()
    const newData = produce(initialValue, (draft: any) => {
      set(draft, this.state.model, state.value)
    })

    apiUpdate({
      name: name,
      url: `${url}/${id}`,
      params: newData,
    })
  }

  openComments = () => {
    this.setState({ isOpen: true })
  }

  render = () => {
    const { isOpen, value } = this.state
    const { user } = this.props

    const commentsCount = size(value)

    if (!user || (user.type !== 'employee' && user.type !== 'bh_employee')) return null

    return (
      <Element name={this.props.model}>
        <div css={styles.root} className={this.props.className}>
          <Flex
            nowrap
            centerY
            css={styles.header}
            onClick={() => this.setState({ isOpen: !isOpen })}
            className={commentsCount >= 1 ? 'has-comments' : ''}
          >
            <Glyph glyph="chat" size="1.5rem" css={styles.glyph} />

            <div>
              <h4 css={styles.heading}>{countWord('Comments', commentsCount)}</h4>
              <div css={styles.showHideLink} className="show-hide-link">
                {isOpen ? 'Hide' : 'Show'}
              </div>
            </div>
          </Flex>

          {isOpen && (
            <>
              {value?.map?.((comment, index) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  onSave={({ content, status }) => this.updateComment(index, content, status)}
                  onDelete={() => this.removeComment(index)}
                  canEdit={user.type === comment.author.type && user.id === comment.author.id}
                />
              ))}

              <AddComment onAdd={this.addComment} />
            </>
          )}
        </div>
      </Element>
    )
  }
}

const styles = {
  root: {
    marginTop: '1.5rem',
  },

  header: {
    marginBottom: '1rem',
    cursor: 'pointer',
    svg: { fill: COLORS.textMuted },

    '&:hover .show-hide-link': {
      textDecoration: 'underline',
    },

    '&.has-comments': {
      svg: { fill: COLORS.link },
    },
  },

  glyph: {
    marginRight: '0.5rem',
  },

  heading: {
    color: COLORS.text,
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '0.92rem',
    letterSpacing: 1,
    margin: 0,
  },

  showHideLink: {
    color: COLORS.link,
    fontWeight: 600,
    fontSize: '0.92rem',
  },
}

export default withSettings(withFormContext(Comments))
