import { DateTime } from 'luxon'
import { persist } from 'zustand/middleware'
import { v4 as uuid } from 'uuid'
import create from 'zustand'
import produce from 'immer'

export const useMockReferralsStore = create(
  persist(
    (set: any) => ({
      data: [],
      saveReferral: (referral: any) =>
        set((state: any) => ({
          data: produce(state.data, (draft: any) => {
            const index = draft.findIndex((r: any) => r.id === referral.id)
            if (index !== -1) {
              draft[index] = { ...draft[index], ...referral }
            } else {
              for (const partnership of referral.partners) {
                const id = uuid()

                const referralSent = {
                  ...referral,
                  id,
                  direction: 'sent',
                  partnership,
                  sent_to: partnership.other_partner,
                  sent_to_id: partnership.other_partner.id,
                  sent_from: referral.sent_from,
                  sent_from_id: referral.sent_from_id,
                  sent_from_status: 'pending',
                  sent_to_status: 'pending',
                  status: 'pending',
                  created_at: DateTime.local().toISO(),
                  updated_at: DateTime.local().toISO(),
                  updates: [
                    {
                      id: uuid(),
                      name: `${referral.author.name} sent referral to ${partnership.other_partner?.name || '–'}`,
                      triggered_at: DateTime.local().toISO(),
                      action: 'referral_create',
                      author: referral.author,
                    },
                  ],
                }

                draft.push(referralSent)
              }
            }
          }),
        })),
      addUpdate: (id: any, update: any) => {
        set((state: any) => ({
          data: produce(state.data, (draft: any) => {
            const index = draft.findIndex((r: any) => r.id === id)
            if (index !== -1) {
              draft[index].updates.push({ id: uuid(), ...update, triggered_at: DateTime.local().toISO() })
            }
          }),
        }))
      },
      deleteReferral: (id: any) => set((state: any) => ({ data: state.data.filter((referral: any) => referral.id !== id) })),
      removeReferral: (referralId: string) =>
        set((state: any) => ({
          data: state.data.filter((referral: any) => referral.id !== referralId),
        })),
    }),
    { name: 'mock-referrals' },
  ),
)
