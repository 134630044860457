import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Employee } from '@behavehealth/declarations'

import {
  Button,
  Card,
  CardContent,
  Chotomate,
  DnDUploader,
  HelpTagIframe,
  Page,
  PageLayout,
  Uploader,
  Link,
  Permission,
} from '@behavehealth/components'

import { FilesTable } from '@behavehealth/components/Tables'

const pageConfig = {
  feature: 'files',
  help: <HelpTagIframe id="files" />,
  marketingID: 'files',
}

const Files: React.FC = () => {
  const match = useRouteMatch()
  const employee: Employee = useSelector((state) => state.data?.employees?.data?.[match?.params?.resource_id])

  const params = { category: 'general' }

  const { data, isLoading }: any = useGet({
    name: ['employees', match.params?.resource_id, 'files', params],
    url: `/employees/${match.params?.resource_id}/documents`,
    params,
  })

  const { mutate: deleteFiles } = useDelete({
    name: ['employees', match.params?.resource_id, 'files'],
    url: '/documents',
    invalidate: 'files',
  })

  const isEmpty = size(data) === 0

  const actions = (
    <Uploader category="general" resource={employee}>
      <Button type="primary" glyph="upload" label="Upload Files" permission="employees.files.create" />
    </Uploader>
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Chotomate name="files" ready={!isLoading} />

      <PageLayout>
        <Card>
          <FilesTable
            data={data}
            isLoading={isEmpty && isLoading}
            emptyActions={actions}
            localStorageKey="employees_files"
            to={(id) => ({
              pathname: `${match.url}/${id}`,
              parent: match,
            })}
            titleAfter={<Link to="/reports/employees-files">View Full Report →</Link>}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'employees.files.delete',
                action: async ({ ids }: any) => {
                  await deleteFiles(ids.join(','))
                },
              },
            ]}
          />
          <CardContent>
            <Permission permission="employees.files.create">
              <DnDUploader category="general" resource={employee} isEditable={true} isDisabled={false} />
            </Permission>
          </CardContent>
        </Card>
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(Files, pageConfig))
