import React from 'react'
import { useLocation } from 'react-router-dom'

import { usDateTime, titleCase, minutesBetween } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'
import useActivePath from '../../hooks/useActivePath'

import { Text } from '../Typography'
import CardLink from '../CardLink'
import CardMeta from '../CardMeta'
import CardTitle from '../CardTitle'
import LocationCheckStatus from '../Statuses/LocationCheckStatus'

type Props = {
  check: any
  location: any
  timezone: any
  to: any
}

const LocationCheckIn = ({ check, timezone, to }: Props) => {
  const location = useLocation()
  const isActive = useActivePath({ location, to })

  const title = `${check.category === 'property' ? check.house.name : check.name} (${titleCase(check.category)})`

  return (
    <CardLink to={to} isActive={isActive}>
      <CardTitle title={title} />
      <CardMeta>
        <LocationCheckStatus status={check.status} />
        <Text glyph="check_in" label="In: " description={usDateTime(check.checkin_at, timezone)} />
        <Text glyph="discharge" label="Out: " description={usDateTime(check.checkout_at, timezone)} />
        <Text glyph="time" label="Duration: " description={`${minutesBetween(check.checkin_at, check.checkout_at, timezone)} minutes`} />
      </CardMeta>
    </CardLink>
  )
}

export default withSettings(LocationCheckIn)
