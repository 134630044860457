import React from 'react'

import { address, titleCase } from '../../utils/functions'
import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useCreate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import ContextShow from '../../components/Forms/ContextShow'
import Button from '../../components/Button'
import Chotomate from '../../components/Chotomate'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Input from '../../components/Forms/Input'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import MultiObjectSelector from '../../components/Forms/Selectors/MultiObject/MultiObjectSelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Status from '../../components/Status'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import Timeline from '../../components/Timeline/Timeline'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'

const RootAccountingInvoiceLineOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'accounting-resource-line',
    endpoint: '/accounting/resource_lines',
    invalidate: 'accounting',
    options: props,
  })

  const syncMutation = useCreate({
    name: ['sync-accounting-invoice-line'],
    url: `/accounting/resource_lines/${id}/sync`,
    invalidate: 'accounting-invoice-line',
  })

  const [client, setClient]: any = React.useState(null)
  const [reference, setReference]: any = React.useState(null)

  const sync = () => {
    syncMutation.mutateAsync({})
  }

  const save = () => {
    let reference_id = ''

    reference_id = reference?.id

    saveWithData({ reference_id, reference_type: 'FinancialTransaction' })
  }

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="accounting" title="Invoice Line" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            ...initialModel,
            client: initialModel?.reference?.client,
          }}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
        >
          <Section>
            <FormSection>
              <ObjectSelector
                icon={ICONS.clients}
                label="Client"
                type="clients"
                model="client"
                onUpdate={({ object }) => {
                  setReference(null)
                  setClient(object)
                }}
                selectTitle={(data: any) => data.name}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
              />

              <ObjectSelector
                key={client?.id}
                icon={ICONS.financials}
                label="Charge"
                type="client.financial_transactions.charges"
                model="reference"
                onUpdate={({ object }) => {
                  setReference(object)
                }}
                dependentValue={client?.id}
                selectTitle={(data: any) => data.name}
                validations={{
                  presence: {
                    message: 'Please select a charge',
                  },
                }}
              />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 auto"
            />
            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {/* <Button
          glyph="refresh"
          label="Sync"
          type="default"
          isDisabled={isLoading}
          onClick={sync}
          isLoading={syncMutation.isLoading}
          flex="100 1 auto"
        /> */}

        {!isEditable && (
          <>
            <Button glyph="edit" label="Edit Charge" type="default" isDisabled={isLoading} onClick={edit} flex="100 1 auto" />
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const AccountingInvoiceLineOverlay = withOverlayError(RootAccountingInvoiceLineOverlay)
