import React from 'react'

import { formatURL } from '../../utils/functions'
import withSettings from '../../hocs/withSettings'

import Button from '../Button'
import Table from '../Table/Table'
import TableCell from '../Table/TableCell'

import { PRICING_STRATEGIES, PRICING_UNIT_TYPES, YEARLY_PRICING_UNIT_TYPES } from '../Billing/constants'
import { useUpdate } from '../../hooks/useNewAPI'

const ArchiveButton = ({ id, status }: any) => {
  const { mutateAsync: updateAsync, isLoading: isUpdating } = useUpdate({
    name: ['template_products', 'archive-button'],
    url: `template_products/${id}`,
    invalidateKeys: ['template_products'],
  })

  const toggleArchive = async () => {
    try {
      await updateAsync({ status: status === 'archived' ? 'active' : 'archived' })
    } catch (errors) {
      console.error(errors)
    }
  }

  return (
    <Button
      label={status === 'archived' ? 'Activate' : 'Archive'}
      glyph="empty"
      color={status === 'archived' ? 'green' : 'gray'}
      size={200}
      onClick={toggleArchive}
      isLoading={isUpdating}
    />
  )
}

const columns = (to: Function = () => {}, mainLinkAs) => [
  {
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Internal Name',
    width: 300,
    Cell: ({ row, value }: any) => <TableCell.MainLink as={mainLinkAs} icon={row.original.icon} to={to(row.original.id)} label={value} />,
  },
  {
    id: 'actions',
    Header: 'Actions',
    Cell: ({ row }: any) => {
      if (row.original.status === 'in_use') return null
      return <ArchiveButton id={row.original.id} status={row.original.status} />
    },
  },
  {
    width: 180,
    accessor: 'dynamic_filters.filter_1',
    Header: 'Filter 1',
  },
  {
    width: 180,
    accessor: 'dynamic_filters.filter_2',
    Header: 'Filter 2',
  },
  {
    width: 180,
    accessor: 'dynamic_filters.filter_3',
    Header: 'Filter 3',
  },
  {
    width: 180,
    accessor: 'notion_url',
    Header: 'Notion Link',
    Cell: ({ value }: any) => <TableCell.Link isExternal label={value} href={formatURL(value)} />,
  },
  {
    width: 200,
    accessor: 'public_name',
    Header: 'Public Name',
  },
  {
    accessor: 'price',
    Header: 'Price',
    Cell: ({ value }: any) => (value ? <TableCell.Amount value={value} /> : '–'),
  },
  {
    accessor: 'discounted_price',
    Header: 'Discounted Price',
    Cell: ({ value }: any) => (value ? <TableCell.Amount value={value} /> : '–'),
  },
  {
    accessor: 'price_unit_type',
    Header: 'Unit Type',
    Cell: ({ value, row }: any) => {
      if (row.original.pricing_type === 'metered_annually') {
        return <TableCell value={YEARLY_PRICING_UNIT_TYPES[value] || '–'} />
      }

      return <TableCell value={PRICING_UNIT_TYPES[value] || '–'} />
    },
  },
  {
    width: 200,
    accessor: 'pricing_type',
    Header: 'Pricing Strategy',
    Cell: ({ value }: any) => <TableCell value={PRICING_STRATEGIES[value] || '–'} />,
  },
]

type Props = {
  data: Object
  hiddenColumns?: string[]
  isLoading: boolean
  isSelectable: boolean
  localStorageKey: string
  timezone: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const TemplateServicesTable = (props: Props) => {
  const { to, hiddenColumns, data, isLoading, localStorageKey, batchActionsConfig, titleAfter } = props

  return (
    <Table
      title="Services"
      titleAfter={titleAfter}
      icon="financials"
      data={data}
      pageSize={30}
      hiddenColumns={hiddenColumns}
      columns={columns(to, props.mainLinkAs)}
      sortBy={{ id: 'order' }}
      isLoading={isLoading}
      emptyDescription="No services added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
    />
  )
}

TemplateServicesTable.defaultProps = {
  localStorageKey: 'template_services_v1',
}

export default withSettings(TemplateServicesTable)
