export const DEFAULT_EMPTY_VALUE = '–'
export const TIMEZONE = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone
export const DEBOUNCE_DELAY_SHORT = 50
export const DEBOUNCE_DELAY_LONG = 100

export const DEMO_ACCOUNTS = ['test', 'outpatient', 'detox', 'startup', 'community', 'members', 'davidsrecovery', 'demo']

export const SERVICE_EPISODE_CATEGORIES = {
  level_of_care: 'Level Of Care',
  ancillary: 'Ancillary',
}

export const SERVICE_EPISODE_LOC_CATEGORIES = {
  asam_substance_use_disorder_treatment: 'ASAM Substance Use Disorder Levels of Care',
  asam_substance_use_disorder_treatment_old: '(Old) ASAM Substance Use Disorder Levels of Care',
  mental_health: 'Mental Health Levels of Care',
  narr_levels_of_support: 'NARR Levels of Support',
}

export const SERVICE_EPISODE_LOC_SUBCATEGORIES = {
  asam_substance_use_disorder_treatment: {
    '1_0_outpatient_services': '1.0 - Outpatient Services',
    '1_5_outpatient_therapy': '1.5 - Outpatient Therapy',
    '1_7_medically_managed_outpatient': '1.7 - Medically Managed Outpatient',
    '2_1_intensive_outpatient': '2.1 - Intensive Outpatient',
    '2_5_high_intensity_outpatient': '2.5 - High-Intensity Outpatient',
    '2_7_medically_managed_intensive_outpatient': '2.7 - Medically Managed Intensive Outpatient',
    recovery_residence: 'RR - Recovery Residence',
    '3_1_clinically_managed_low_intensity_residential': '3.1 - Clinically Managed Low-Intensity Residential',
    '3_5_clinically_managed_high_intensity_residential': '3.5 - Clinically Managed High-Intensity Residential',
    '3_7_medically_managed_residential': '3.7 - Medically Managed Residential',
    '3_7_medically_managed_residential_biomedically_enhanced': '3.7 - Medically Managed Residential (Biomedically Enhanced)',
    '4_medically_managed_intensive_inpatient': '4 - Medically Managed Intensive Inpatient',
  },
  asam_substance_use_disorder_treatment_old: {
    level_0_5: 'Level 0.5: Early Intervention',
    level_I: 'Level I: Outpatient Services',
    level_II_1: 'Level II.1: Intensive Outpatient Services',
    level_II_5: 'Level II.5: Partial Hospitalization Services',
    level_III_1: 'Level III.1: Clinically Managed Low-Intensity Residential Services',
    level_III_3: 'Level III.3: Clinically Managed Medium-Intensity Residential Services',
    level_III_5: 'Level III.5: Clinically Managed High-Intensity Residential Services',
    level_III_7: 'Level III.7: Medically Monitored Intensive Inpatient Services',
    level_IV: 'Level IV: Medically Managed Intensive Inpatient Services',
  },
  mental_health: {
    outpatient_treatment: 'Outpatient Treatment',
    intensive_outpatient_programs: 'Intensive Outpatient Programs (IOP)',
    partial_hospitalization_programs: 'Partial Hospitalization Programs (PHP)',
    residential_treatment: 'Residential Treatment',
    inpatient_hospitalization: 'Inpatient Hospitalization',
    crisis_services: 'Crisis Services',
    assertive_community_treatment: 'Assertive Community Treatment (ACT)',
    community_support_services: 'Community Support Services',
  },
  narr_levels_of_support: {
    level_1: 'Level 1 (e.g., Oxford Houses)',
    level_2: 'Level 2 (e.g., sober living homes)',
    level_3: 'Level 3',
    level_4: 'Level 4 (e.g., therapeutic community)',
  },
}

export const PLANS = {
  lite: { name: 'lite', value: 0 },
  basic: { name: 'basic', value: 1 },
  plus: { name: 'plus', value: 2 },
  pro: { name: 'pro', value: 3 },
  addon: { name: 'add-on', value: 4 },
}

export const ADDONS = {
  chat: 'Chat',
  clients_chat: 'Clients Chat',
  rcm_insurance: 'RCM Insurance',
  rcm_consumer: 'RCM Consumer',
  ehr: 'EHR',
  credentials: 'Credentials',
}

export const COMMON_TIMEZONES = [
  {
    name: '(GMT-07:00) Pacific Time - Los Angeles',
    value: 'America/Los_Angeles',
  },
  {
    name: '(GMT-07:00) Mountain Time - Phoenix',
    value: 'America/Phoenix',
  },
  {
    name: '(GMT-06:00) Mountain Time - Denver',
    value: 'America/Denver',
  },
  {
    name: '(GMT-05:00) Central Time - Chicago',
    value: 'America/Chicago',
  },
  {
    name: '(GMT-04:00) Eastern Time - Detroit',
    value: 'America/Detroit',
  },
  {
    name: '(GMT-04:00) Eastern Time - New York',
    value: 'America/New_York',
  },
]

export const TIMEZONES = [
  {
    name: 'Hawaii',
    zones: [
      {
        name: '(GMT-10:00) Hawaii-Aleutian Time - Honolulu',
        value: 'Pacific/Honolulu',
      },
      {
        name: '(GMT-09:00) Hawaii-Aleutian Time - Adak',
        value: 'America/Adak',
      },
    ],
  },
  {
    name: 'Alaska Time',
    zones: [
      {
        name: '(GMT-08:00) Alaska Time - Anchorage',
        value: 'America/Anchorage',
      },
      {
        name: '(GMT-08:00) Alaska Time - Juneau',
        value: 'America/Juneau',
      },
      {
        name: '(GMT-08:00) Alaska Time - Metlakatla',
        value: 'America/Metlakatla',
      },
      {
        name: '(GMT-08:00) Alaska Time - Nome',
        value: 'America/Nome',
      },
      {
        name: '(GMT-08:00) Alaska Time - Sitka',
        value: 'America/Sitka',
      },
      {
        name: '(GMT-08:00) Alaska Time - Yakutat',
        value: 'America/Yakutat',
      },
    ],
  },
  {
    name: 'Pacific Time',
    zones: [
      {
        name: '(GMT-07:00) Pacific Time - Los Angeles',
        value: 'America/Los_Angeles',
      },
    ],
  },
  {
    name: 'Mountain Time',
    zones: [
      {
        name: '(GMT-07:00) Mountain Standard Time - Phoenix',
        value: 'America/Phoenix',
      },
      {
        name: '(GMT-06:00) Mountain Time - Boise',
        value: 'America/Boise',
      },
      {
        name: '(GMT-06:00) Mountain Time - Denver',
        value: 'America/Denver',
      },
    ],
  },
  {
    name: 'Central Time',
    zones: [
      {
        name: '(GMT-05:00) Central Time - Beulah, North Dakota',
        value: 'America/North_Dakota/Beulah',
      },
      {
        name: '(GMT-05:00) Central Time - Center, North Dakota',
        value: 'America/North_Dakota/Center',
      },
      {
        name: '(GMT-05:00) Central Time - Chicago',
        value: 'America/Chicago',
      },
      {
        name: '(GMT-05:00) Central Time - Knox, Indiana',
        value: 'America/Indiana/Knox',
      },
      {
        name: '(GMT-05:00) Central Time - Menominee',
        value: 'America/Menominee',
      },
      {
        name: '(GMT-05:00) Central Time - New Salem, North Dakota',
        value: 'America/North_Dakota/New_Salem',
      },
      {
        name: '(GMT-05:00) Central Time - Tell City, Indiana',
        value: 'America/Indiana/Tell_City',
      },
    ],
  },
  {
    name: 'Eastern Time',
    zones: [
      {
        name: '(GMT-04:00) Eastern Time - Detroit',
        value: 'America/Detroit',
      },
      {
        name: '(GMT-04:00) Eastern Time - Indianapolis',
        value: 'America/Indiana/Indianapolis',
      },
      {
        name: '(GMT-04:00) Eastern Time - Louisville',
        value: 'America/Kentucky/Louisville',
      },
      {
        name: '(GMT-04:00) Eastern Time - Marengo, Indiana',
        value: 'America/Indiana/Marengo',
      },
      {
        name: '(GMT-04:00) Eastern Time - Monticello, Kentucky',
        value: 'America/Kentucky/Monticello',
      },
      {
        name: '(GMT-04:00) Eastern Time - New York',
        value: 'America/New_York',
      },
      {
        name: '(GMT-04:00) Eastern Time - Petersburg, Indiana',
        value: 'America/Indiana/Petersburg',
      },
      {
        name: '(GMT-04:00) Eastern Time - Vevay, Indiana',
        value: 'America/Indiana/Vevay',
      },
      {
        name: '(GMT-04:00) Eastern Time - Vincennes, Indiana',
        value: 'America/Indiana/Vincennes',
      },
      {
        name: '(GMT-04:00) Eastern Time - Winamac, Indiana',
        value: 'America/Indiana/Winamac',
      },
    ],
  },
]

export const UATests = [
  {
    id: 1,
    name: 'Amphetamines',
    model: 'amphetamines',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 2,
    name: 'Barbiturates',
    model: 'barbituates',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 3,
    name: 'Benzodiazepines',
    model: 'benzodiazepines',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 4,
    name: 'Buprenorphine',
    model: 'buprenorphine',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 5,
    name: 'Cocaine',
    model: 'cocaine',
    category: 'ua_test',
    is_prescriptible: false,
  },
  {
    id: 6,
    name: 'Ecstasy (MDMA)',
    model: 'mdma',
    category: 'ua_test',
    is_prescriptible: false,
  },
  {
    id: 7,
    name: 'Methadone',
    model: 'methadone',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 8,
    name: 'Methamphetamine',
    model: 'metamphetamine',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 9,
    name: 'Morphine',
    model: 'morphine',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 10,
    name: 'Oxy',
    model: 'oxy',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 11,
    name: 'PCP',
    model: 'pcp',
    category: 'ua_test',
    is_prescriptible: false,
  },
  {
    id: 12,
    name: 'THC',
    model: 'thc',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 13,
    name: 'Alcohol',
    model: 'alcohol',
    category: 'ua_test',
    is_prescriptible: false,
  },
  {
    id: 14,
    name: 'Bath Salts',
    model: 'bath_salts',
    category: 'ua_test',
    is_prescriptible: false,
  },
  {
    id: 15,
    name: 'K2',
    model: 'k2',
    category: 'ua_test',
    is_prescriptible: false,
  },
  {
    id: 16,
    name: 'Kratom',
    model: 'kratom',
    category: 'ua_test',
    is_prescriptible: false,
  },
  {
    id: 18,
    name: 'Heroin',
    model: 'heroin',
    category: 'ua_test',
    is_prescriptible: false,
  },
  {
    id: 19,
    name: 'Opiates',
    model: 'opiates',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 20,
    name: 'Ketamine',
    model: 'ketamine',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 21,
    name: 'TCA',
    model: 'tca',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 22,
    name: 'Fentanyl',
    model: 'fentanyl',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 23,
    name: 'Inhalants',
    model: 'inhalant',
    category: 'ua_test',
    is_prescriptible: true,
  },
  {
    id: 24,
    name: 'Nicotine',
    model: 'nicotine',
    category: 'ua_test',
    is_prescriptible: false,
  },
  {
    id: 25,
    name: 'Psychedelics',
    model: 'psychedelics',
    category: 'ua_test',
    is_prescriptible: false,
  },
]

export const ROSTER_CATEGORIES = {
  attendance: 'Attendance',
  bed_check: 'Bed Check',
  breathalyzer_test: 'Breathalyzer Tests',
  case_management: 'Case Management',
  communicable_disease: 'Communicable Disease Tests',
  family_session: 'Family Session',
  group_note: 'Group Notes',
  individual_note: 'Individual Notes',
  shift_note: 'Shift Notes',
  ua_test: 'UA Tests',
  visitor_log: 'Visitor Logs',
  vital_sign: 'Vital Signs',
}

export const LOC = {
  medically_managed_intensive_inpatient_detoxification: '4-D Medically Managed Intensive Inpatient Detoxification',
  medically_managed_intensive_inpatient_services: '4 Medically Managed Intensive Inpatient Services',
  medically_monitored_inpatient_detoxification: '3.7-D Medically Monitored Inpatient Detoxification',
  medically_monitored_high_intensity_inpatient_services: '3.7 Medically Monitored High-Intensity Inpatient Services',
  clinically_managed_medium_intensity_residential_services: '3.5 Clinically Managed Medium-Intensity Residential Services',
  clinically_managed_population_specific_high_intensity_residential_services:
    '3.3 Clinically Managed Population-Specific High-Intensity Residential Services',
  clinically_managed_residential_detoxification: '3.2-D Clinically Managed Residential Detoxification',
  clinically_managed_low_intensity_residential_services: '3.1 Clinically Managed Low-Intensity Residential Services',
  residential_inpatient_detoxification: '3-D Residential/Inpatient Detoxification',
  partial_hospitalization_services: '2.5 Partial Hospitalization Services',
  intensive_outpatient_services: '2.1 Intensive Outpatient Services',
  ambulatory_detoxification_with_extended_on_site_monitoring: '2-D Ambulatory Detoxification With Extended On-Site Monitoring',
  ambulatory_detoxification_without_extended_on_site_monitoring: '1-D Ambulatory Detoxification Without Extended On-Site Monitoring',
  outpatient_services: '1 Outpatient Services',
  early_intervention: '0.5 Early Intervention',
}

export const ADMISSION_SOURCE = {
  '1': '1 - Non-Health Care Facility',
  '2': "2 - Clinic or Physician's Office",
  '4': '4 - Transfer from a Hospital (Different Facility)',
  '5': '5 - Transfer from a Skilled Nursing Facility (SNF), Intermediate Care Facility (ICF) or Assisted Living Facility (ALF)',
  '6': '6 - Transfer from another Health Care Facility',
  '8': '8 - Court/Law Enforcement',
  '9': '9 - Information not Available',
  D: 'D - Transfer from one distinct unit of the hospital to another distinct unit of the same hospital resulting in a separate claim to the Payer',
  E: 'E - Transfer from Ambulatory Surgery Center',
  F: 'F - Transfer from a Hospice Facility',
  G: 'G - Transfer from a Designated Disaster Alternate Care Site',
}

export const DISCHARGE_TYPES = {
  successfully_completed: 'Successful program completion',
  successful_discharge: 'Successful discharge',
  unsuccessful_discharge_with_option_to_return: 'Unsuccessful discharge with option to return',
  unsuccessful_discharge_without_option_to_return: 'Unsuccessful discharge without option to return',
  therapeutic_discharge: 'Therapeutic Discharge',
  ama: 'AMA',
  mia: 'MIA',
  administrative_discharge: 'Administrative Discharge',
  other: 'Other',
}

export const ADMISSION_TYPE = {
  '1': '1 - Emergency',
  '2': '2 - Urgent',
  '3': '3 - Elective',
  '4': '4 - Newborn',
  '5': '5 - Trauma Center',
  '9': '9 - Information not available',
}

export const SERVICE_CATEGORIES = {
  admission: {
    id: 'admission',
    label: 'Admission/Intake',
    is_recurring: false,
    subcategories: {
      intake_fee: {
        id: 'intake_fee',
        label: 'Intake Fee',
      },
      security_deposit: {
        id: 'security_deposit',
        label: 'Security Deposit',
      },
      sobriety_deposit: {
        id: 'sobriety_deposit',
        label: 'Sobriety Deposit',
      },
      custom: {
        id: 'custom',
        label: 'Custom Admission/Intake',
      },
    },
  },

  housing: {
    id: 'housing',
    label: 'Housing',
    is_recurring: true,
    subcategories: {
      rent: {
        id: 'rent',
        label: 'Rent',
      },
      program_fee: {
        id: 'program_fee',
        label: 'Program Fee',
      },
      custom: {
        id: 'custom',
        label: 'Custom Housing',
      },
    },
  },

  fees: {
    id: 'fees',
    label: 'Fees',
    is_recurring: false,
    subcategories: {
      admin_fee: {
        id: 'admin_fee',
        label: 'Admin Fee',
      },
      late_fee: {
        id: 'late_fee',
        label: 'Late Fee',
      },
      custom: {
        id: 'custom',
        label: 'Custom Fees',
      },
    },
  },

  coaching: {
    id: 'coaching',
    label: 'Coaching',
    is_recurring: false,
    subcategories: {
      recovery_coaching: {
        id: 'recovery_coaching',
        label: 'Recovery Coaching',
      },
      career_coaching: {
        id: 'career_coaching',
        label: 'Career Coaching ',
      },
      life_coaching: {
        id: 'life_coaching',
        label: 'Life Coaching',
      },
      custom: {
        id: 'custom',
        label: 'Custom Coaching',
      },
    },
  },

  other: {
    id: 'other',
    label: 'Other',
    is_recurring: false,
    subcategories: {
      custom: {
        id: 'custom',
        label: 'Custom',
      },
    },
  },
}

export const PAYMENT_METHODS = {
  online: {
    label: 'Automatic Online Payments',
    description: 'Using our Behave Portal or EHR',
    glyph: 'stripe',
  },
  online_apps: {
    label: 'Online Payments',
    description: 'Using our Behave Online Payments or Online Ledger App',
    glyph: 'stripe',
  },
  card: {
    label: 'Manual Card',
    description: null,
    glyph: 'cards',
  },
  cash: {
    label: 'Cash',
    description: null,
    glyph: 'cash_stack',
  },
  check: {
    label: 'Check',
    description: null,
    glyph: 'cheque',
  },
  money_order: {
    label: 'Money Order',
    description: null,
    glyph: 'money_order',
  },
  ach: {
    label: 'Manual Bank Account',
    description: null,
    glyph: 'bank',
  },
  venmo: {
    label: 'Venmo',
    description: null,
    glyph: 'venmo',
  },
  cash_app: {
    label: 'Cash App',
    description: null,
    glyph: 'cash_app',
  },
  pay_pal: {
    label: 'PayPal',
    description: null,
    glyph: 'paypal',
  },
  zelle: {
    label: 'Zelle',
    description: null,
    glyph: 'zelle',
  },
  other: {
    label: 'Other',
    description: null,
    glyph: 'online_payments',
  },
}

export const PAYMENT_METHODS_LIST = [
  {
    id: 12,
    name: 'Online Payments',
    description: 'Using our Behave Online Payments or Online Ledger App',
    model: 'online_apps',
    glyph: 'stripe',
    category: 'payment_method',
  },
  {
    id: 1,
    name: 'Automatic Online Payments',
    description: 'Using our Behave Portal or EHR',
    model: 'online',
    glyph: 'stripe',
    category: 'payment_method',
  },
  {
    id: 2,
    name: 'Manual Card',
    description: null,
    model: 'card',
    glyph: 'cards',
    category: 'payment_method',
  },
  {
    id: 3,
    name: 'Cash',
    description: null,
    model: 'cash',
    glyph: 'cash_stack',
    category: 'payment_method',
  },
  {
    id: 4,
    name: 'Check',
    description: null,
    model: 'check',
    glyph: 'cheque',
    category: 'payment_method',
  },
  {
    id: 5,
    name: 'Money Order',
    description: null,
    model: 'money_order',
    glyph: 'money_order',
    category: 'payment_method',
  },
  {
    id: 6,
    name: 'Manual Bank Account',
    description: null,
    model: 'ach',
    glyph: 'bank',
    category: 'payment_method',
  },
  {
    id: 7,
    name: 'Venmo',
    description: null,
    model: 'venmo',
    glyph: 'venmo',
    category: 'payment_method',
  },
  {
    id: 8,
    name: 'Cash App',
    description: null,
    model: 'cash_app',
    glyph: 'cash_app',
    category: 'payment_method',
  },
  {
    id: 9,
    name: 'PayPal',
    description: null,
    model: 'pay_pal',
    glyph: 'paypal',
    category: 'payment_method',
  },
  {
    id: 10,
    name: 'Zelle',
    description: null,
    model: 'zelle',
    glyph: 'zelle',
    category: 'payment_method',
  },
  {
    id: 11,
    name: 'Other',
    description: null,
    model: 'other',
    glyph: 'online_payments',
    category: 'payment_method',
  },
]

export const FINANCIAL_PLAN_STATUSES = {
  draft: 'draft',
  approval: 'approval',
  pending: 'pending',
  current: 'current',
  past: 'past',
}

export const FINANCIAL_STATUS = {
  up_to_date: 'Up To Date',
  partially_paid: 'Partially Paid',
  due: 'Due',
  overdue: 'Overdue',
  challenged: 'Challenged',
  in_debt: 'In Debt',
  written_off: 'Debt Written Off',
  other: 'Other',
}

export const AGREEMENTS = {
  advance_recovery_directive: 'Advance Recovery Directive',
  ama_waiver: 'AMA Waiver',
  case_management_services: 'Case Management Services',
  client_handbook: 'Client Handbook',
  client_rights: 'Client Rights',
  confidentiality_agreement: 'Confidentiality Agreement',
  consent_for_drug_screening: 'Consent for Drug Screening',
  consent_for_narcan: 'Consent for Narcan',
  consent_for_treatment_of_minors: 'Consent for Treatment of Minors',
  consent_to_process_belongings: 'Consent to Process Belongings',
  coronavirus_policy: 'Coronavirus Policy',
  drug_and_alcohol_policy: 'Drug and Alcohol Policy',
  fee_for_service_agreement: 'Fee for Service Agreement',
  hipaa_consent: 'HIPAA Consent',
  house_rules: 'House Rules',
  housing_activities: 'Housing Activities',
  housing_treatment_policies_and_procedures: 'Housing / Treatment Policies and Procedures',
  informed_consent: 'Informed Consent',
  lease_agreement: 'Lease Agreement',
  liability_waiver_for_activities_and_transportation: 'Liability Waiver for Activities and Transportation',
  medical_risk_waiver: 'Medical Risk Waiver',
  release_of_information: 'Release of Information',
  release_of_liability: 'Release of Liability',
  telehealth_informed_consent: 'Telehealth Informed Consent',
  universal_precautions: 'Universal Precautions',
  other: 'Other',
}

export const COMMUNICABLE_DISEASE_TESTS = {
  aids_hiv: 'AIDS/HIV',
  chickenpox: 'Chickenpox',
  cholera: 'Cholera',
  chronic_sinusitis: 'Chronic Sinusitis',
  coronavirus_antibodies: 'COVID - Antibodies/Serology',
  coronavirus_pcr: 'COVID - Active Infection (PCR)',
  influenza: 'Flu (Influenza)',
  lyme_disease: 'Lyme Disease',
  measles: 'Measles',
  meningitis: 'Meningitis',
  mumps: 'Mumps',
  pneumonia: 'Pneumonia',
  rubella: 'Rubella',
  smallpox: 'Smallpox',
  std: 'Sexually Transmitted Diseases (STD)',
  tuberculosis: 'Tuberculosis',
  viral_hepatitis: 'Viral Hepatitis',
  whooping_cough: 'Whooping Cough / Pertussis',
}

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const ROLES = {
  owner: 'Owner',
  administrator: 'Administrator',
  medical_director: 'Medical Director',
  psychiatrist: 'Psychiatrist',
  nurse: 'Nurse',
  medical_technician: 'Medical Technician',
  clinical_director: 'Clinical Director',
  therapist: 'Therapist',
  counselor: 'Counselor',
  behavioral_health_technician: 'Behavioral Health Technician',
  housing_director: 'Housing Director',
  house_manager: 'House Manager',
  program_director: 'Program Director',
  admissions_director: 'Admissions Director',
  housing_supervisor: 'Housing Supervisor',
  accounting: 'Accounting',
  support_staff: 'Support Staff',
  // Behave Health roles
  admin: 'Admin',
  billing: 'Billing',
  dev: 'Dev',
}

export const STAFF_ROLES = {
  owner: 'Account Owner User',
  billing: 'Billing User',
  clinical: 'Clinical User',
  coordinator: 'Operations User',
  management: 'Management User',
  medical: 'Medical User',
  part_time_clinical: 'Part-Time Clinical User',
  part_time_medical: 'Part-Time Medical User',
}

export const EVENT_CATEGORIES = {
  general: 'General',
  show_as_busy: 'Show as Busy',
  client_admission: 'New Admission',
  housing_intake: 'New Intake',
  housing_activity: 'Housing Activity',
  bed_check: 'Bed Check',
  recovery_coaching: 'Recovery Coaching',
  discharge: 'Discharge Planning',
  contact_appointment: 'Contact Appointment',
  connections_appointment: 'Care Team Appointment',
  clinical_assessment: 'Clinical Assessment',
  treatment_plan: 'Treatment Plan',
  individual_therapy: 'Individual Therapy ★',
  group_therapy: 'Group Therapy ★',
  family_session: 'Family Session',
  case_management: 'Case Management ★',
  individual_peer_note: 'Individual Peer Note ★',
  group_peer_notes: 'Group Peer Note ★',
  individual_daily_note: 'Individual Daily Note ★',
  group_daily_notes: 'Group Daily Note ★',
  progress: 'Progress Appointment',
  other_clinical: 'Other Clinical',
  physician: 'Physician Appointment',
  psych: 'Psych Appointment',
  medical: 'Medical Appointment',
  nursing: 'Nursing Appointment',
  meds_pass: 'Medication Pass',
  urinalysis: 'Urinalysis',
  breathalyzer: 'Breathalyzer',
  vitals: 'Vital Signs',
  communicable_tests: 'Communicable Tests',
  other_medical: 'Other Medical',
}

export const EVENT_ATTENDANCE_PRESENT_STATUSES = {
  attend_partial: 'Attend Partial',
  attend_partial_due_to_emotional_distress: 'Attend Partial/Due to Emotional Distress',
  call_out: 'Call-Out',
  call_out_by_provider: 'Call-Out by Provider',
  late: 'Late',
  make_up_session: 'Make-up Session',
  medication_management: 'Medication Management',
  non_participatory: 'Non-Participatory',
  partial_day: 'Partial Day',
  participated_actively: 'Participated Actively',
  telehealth_remote: 'Telehealth/Remote',
  under_influence: 'Under Influence',
  with_support_person: 'With Support Person',
  left_early_excused: 'Left Early - Excused',
  left_early_unexcused: 'Left Early - Unexcused',
}

export const EVENT_ATTENDANCE_ABSENT_STATUSES = {
  authorized_leave: 'Authorized Leave',
  crisis_intervention: 'Crisis Intervention',
  excused: 'Excused',
  inpatient_residential_admission: 'Inpatient/Residential Admission',
  legal_court_situation: 'Legal/Court Situation',
  medical_situation: 'Medical Situation',
  relapse: 'Relapse',
  unexcused_no_call_no_show: 'Unexcused (No-call, No-show)',
  cancelled_by_client: 'Cancelled - by Client',
  cancelled_by_provider: 'Cancelled - by Provider',
  rescheduled: 'Rescheduled',
}

export const EVENTS_CATEGORIES = {
  general: 'General',
  show_as_busy: 'Show as Busy',

  admissions: {
    name: 'Admission',
    subcategories: {
      client_admission: 'New Admission',
      housing_intake: 'New Intake',
    },
  },

  other: {
    name: 'Care Coordination',
    subcategories: {
      housing_activity: 'Housing Activity',
      bed_check: 'Bed Check',
      recovery_coaching: 'Recovery Coaching',
      discharge: 'Discharge Planning',
    },
  },

  contacts: {
    name: 'Contacts',
    subcategories: {
      contact_appointment: 'Contact Appointment',
      connections_appointment: 'Care Team Appointment',
    },
  },

  clinical: {
    name: 'Clinical',
    subcategories: {
      clinical_assessment: 'Clinical Assessment',
      treatment_plan: 'Treatment Plan',
      individual_therapy: 'Individual Therapy ★',
      group_therapy: 'Group Therapy ★',
      family_session: 'Family Session',
      case_management: 'Case Management ★',
      progress: 'Progress Appointment',
      other_clinical: 'Other Clinical',
    },
  },

  medical: {
    name: 'Medical',
    subcategories: {
      physician: 'Physician Appointment',
      psych: 'Psych Appointment',
      medical: 'Medical Appointment',
      nursing: 'Nursing Appointment',
      meds_pass: 'Medication Pass',
      urinalysis: 'Urinalysis',
      breathalyzer: 'Breathalyzer',
      vitals: 'Vital Signs',
      communicable_tests: 'Communicable Tests',
      other_medical: 'Other Medical',
    },
  },

  peer: {
    name: 'Peer',
    subcategories: {
      individual_peer_note: 'Individual Peer Note ★',
      group_peer_notes: 'Group Peer Note ★',
    },
  },

  daily: {
    name: 'Daily',
    subcategories: {
      individual_daily_note: 'Individual Daily Note ★',
      group_daily_notes: 'Group Daily Note ★',
    },
  },
}

// https://gist.githubusercontent.com/a8dx/2340f9527af64f8ef8439366de981168/raw/81d876daea10eab5c2675811c39bcd18a79a9212/US_State_Bounding_Boxes.csv
export const STATE_BOUNDARIES = {
  AL: { bbox: [-88.473227, 30.223334, -84.88908, 35.008028], center: [] },
  AK: { bbox: [-179.148909, 51.214183, 179.77847, 71.365162], center: [] },
  AS: { bbox: [-171.089874, -14.548699, -168.1433, -11.046934], center: [] },
  AZ: { bbox: [-114.81651, 31.332177, -109.045223, 37.00426], center: [] },
  AR: { bbox: [-94.617919, 33.004106, -89.644395, 36.4996], center: [] },
  CA: { bbox: [-124.409591, 32.534156, -114.131211, 42.009518], center: [] },
  CO: { bbox: [-109.060253, 36.992426, -102.041524, 41.003444], center: [] },
  CT: { bbox: [-73.727775, 40.980144, -71.786994, 42.050587], center: [] },
  DE: { bbox: [-75.788658, 38.451013, -75.048939, 39.839007], center: [] },
  DC: { bbox: [-77.119759, 38.791645, -76.909395, 38.99511], center: [] },
  FL: { bbox: [-87.634938, 24.523096, -80.031362, 31.000888], center: [] },
  GA: { bbox: [-85.605165, 30.357851, -80.839729, 35.000659], center: [] },
  GU: { bbox: [144.618068, 13.234189, 144.956712, 13.654383], center: [] },
  HI: { bbox: [-178.334698, 18.910361, -154.806773, 28.402123], center: [] },
  ID: { bbox: [-117.243027, 41.988057, -111.043564, 49.001146], center: [] },
  IL: { bbox: [-91.513079, 36.970298, -87.494756, 42.508481], center: [] },
  IN: { bbox: [-88.09776, 37.771742, -84.784579, 41.760592], center: [] },
  IA: { bbox: [-96.639704, 40.375501, -90.140061, 43.501196], center: [] },
  KS: { bbox: [-102.051744, 36.993016, -94.588413, 40.003162], center: [] },
  KY: { bbox: [-89.571509, 36.497129, -81.964971, 39.147458], center: [] },
  LA: { bbox: [-94.043147, 28.928609, -88.817017, 33.019457], center: [] },
  ME: { bbox: [-71.083924, 42.977764, -66.949895, 47.459686], center: [] },
  MD: { bbox: [-79.487651, 37.911717, -75.048939, 39.723043], center: [] },
  MA: { bbox: [-73.508142, 41.237964, -69.928393, 42.886589], center: [] },
  MI: { bbox: [-90.418136, 41.696118, -82.413474, 48.2388], center: [] },
  MN: { bbox: [-97.239209, 43.499356, -89.491739, 49.384358], center: [] },
  MS: { bbox: [-91.655009, 30.173943, -88.097888, 34.996052], center: [] },
  MO: { bbox: [-95.774704, 35.995683, -89.098843, 40.61364], center: [] },
  MT: { bbox: [-116.050003, 44.358221, -104.039138, 49.00139], center: [] },
  NE: { bbox: [-104.053514, 39.999998, -95.30829, 43.001708], center: [] },
  NV: { bbox: [-120.005746, 35.001857, -114.039648, 42.002207], center: [] },
  NH: { bbox: [-72.557247, 42.69699, -70.610621, 45.305476], center: [] },
  NJ: { bbox: [-75.559614, 38.928519, -73.893979, 41.357423], center: [] },
  NM: { bbox: [-109.050173, 31.332301, -103.001964, 37.000232], center: [] },
  NY: { bbox: [-79.762152, 40.496103, -71.856214, 45.01585], center: [] },
  NC: { bbox: [-84.321869, 33.842316, -75.460621, 36.588117], center: [] },
  ND: { bbox: [-104.0489, 45.935054, -96.554507, 49.000574], center: [] },
  OH: { bbox: [-84.820159, 38.403202, -80.518693, 41.977523], center: [] },
  OK: { bbox: [-103.002565, 33.615833, -94.430662, 37.002206], center: [] },
  OR: { bbox: [-124.566244, 41.991794, -116.463504, 46.292035], center: [] },
  PA: { bbox: [-80.519891, 39.7198, -74.689516, 42.26986], center: [] },
  PR: { bbox: [-67.945404, 17.88328, -65.220703, 18.515683], center: [] },
  RI: { bbox: [-71.862772, 41.146339, -71.12057, 42.018798], center: [] },
  SC: { bbox: [-83.35391, 32.0346, -78.54203, 35.215402], center: [] },
  SD: { bbox: [-104.057698, 42.479635, -96.436589, 45.94545], center: [] },
  TN: { bbox: [-90.310298, 34.982972, -81.6469, 36.678118], center: [] },
  TX: { bbox: [-106.645646, 25.837377, -93.508292, 36.500704], center: [] },
  VI: { bbox: [-65.085452, 17.673976, -64.564907, 18.412655], center: [] },
  UT: { bbox: [-114.052962, 36.997968, -109.041058, 42.001567], center: [] },
  VT: { bbox: [-73.43774, 42.726853, -71.464555, 45.016659], center: [] },
  VA: { bbox: [-83.675395, 36.540738, -75.242266, 39.466012], center: [] },
  WA: { bbox: [-124.763068, 45.543541, -116.915989, 49.002494], center: [] },
  WV: { bbox: [-82.644739, 37.201483, -77.719519, 40.638801], center: [] },
  WI: { bbox: [-92.888114, 42.491983, -86.805415, 47.080621], center: [] },
  WY: { bbox: [-111.056888, 40.994746, -104.05216, 45.005904], center: [] },
}

// adapted from: https://gist.githubusercontent.com/claraj/3880cd48b3d8cb3a7f900aeb30b18fdd/raw/e9b5ca44aaed5de3107c10fe61a7dd248edfc1c9/formatted_with_suggested_zoom.json
export const STATE_COORDINATES: any = {
  AL: { lat: 32.7794, lon: -86.8287, zoom: 7 },
  AK: { lat: 64.0685, lon: -152.2782, zoom: 6 },
  AZ: { lat: 34.2744, lon: -111.6602, zoom: 7 },
  AR: { lat: 34.8938, lon: -92.4426, zoom: 7 },
  CA: { lat: 37.1841, lon: -119.4696, zoom: 7 },
  CO: { lat: 38.9972, lon: -105.5478, zoom: 7 },
  CT: { lat: 41.6219, lon: -72.7273, zoom: 7 },
  DE: { lat: 38.9896, lon: -75.505, zoom: 7 },
  FL: { lat: 28.6305, lon: -82.4497, zoom: 7 },
  GA: { lat: 32.6415, lon: -83.4426, zoom: 7 },
  HI: { lat: 20.2927, lon: -156.3737, zoom: 7 },
  ID: { lat: 44.3509, lon: -114.613, zoom: 7 },
  IL: { lat: 40.0417, lon: -89.1965, zoom: 7 },
  IN: { lat: 39.8942, lon: -86.2816, zoom: 7 },
  IA: { lat: 42.0751, lon: -93.496, zoom: 7 },
  KS: { lat: 38.4937, lon: -98.3804, zoom: 7 },
  KY: { lat: 37.5347, lon: -85.3021, zoom: 7 },
  LA: { lat: 31.0689, lon: -91.9968, zoom: 7 },
  ME: { lat: 45.3695, lon: -69.2428, zoom: 6 },
  MD: { lat: 39.055, lon: -76.7909, zoom: 7 },
  MA: { lat: 42.2596, lon: -71.8083, zoom: 6 },
  MI: { lat: 44.3467, lon: -85.4102, zoom: 7 },
  MN: { lat: 46.2807, lon: -94.3053, zoom: 7 },
  MS: { lat: 32.7364, lon: -89.6678, zoom: 7 },
  MO: { lat: 38.3566, lon: -92.458, zoom: 7 },
  MT: { lat: 47.0527, lon: -109.6333, zoom: 7 },
  NE: { lat: 41.5378, lon: -99.7951, zoom: 7 },
  NV: { lat: 39.3289, lon: -116.6312, zoom: 7 },
  NH: { lat: 43.6805, lon: -71.5811, zoom: 6 },
  NJ: { lat: 40.1907, lon: -74.6728, zoom: 7 },
  NM: { lat: 34.4071, lon: -106.1126, zoom: 7 },
  NY: { lat: 42.9538, lon: -75.5268, zoom: 7 },
  NC: { lat: 35.5557, lon: -79.3877, zoom: 7 },
  ND: { lat: 47.4501, lon: -100.4659, zoom: 7 },
  OH: { lat: 40.2862, lon: -82.7937, zoom: 7 },
  OK: { lat: 35.5889, lon: -97.4943, zoom: 7 },
  OR: { lat: 43.9336, lon: -120.5583, zoom: 7 },
  PA: { lat: 40.8781, lon: -77.7996, zoom: 7 },
  RI: { lat: 41.6762, lon: -71.5562, zoom: 7 },
  SC: { lat: 33.9169, lon: -80.8964, zoom: 7 },
  SD: { lat: 44.4443, lon: -100.2263, zoom: 7 },
  TN: { lat: 35.858, lon: -86.3505, zoom: 7 },
  TX: { lat: 31.4757, lon: -99.3312, zoom: 7 },
  UT: { lat: 39.3055, lon: -111.6703, zoom: 7 },
  VT: { lat: 44.0687, lon: -72.6658, zoom: 6 },
  VA: { lat: 37.5215, lon: -78.8537, zoom: 7 },
  WA: { lat: 47.3826, lon: -120.4472, zoom: 7 },
  WV: { lat: 38.6409, lon: -80.6227, zoom: 7 },
  WI: { lat: 44.6243, lon: -89.9941, zoom: 7 },
  WY: { lat: 42.9957, lon: -107.5512, zoom: 7 },
}

export const STATE_NAMES_BY_CODE = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const STATE_CODES_BY_NAME = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
}

export const STATES = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District of Columbia', abbreviation: 'DC' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
]

export const CLEARING_HOUSES = {
  change_health: 'Clearing House 1 (CH) (Beta)',
  pverify: 'Clearing House 2 (PV)',
  claim_md: 'Clearing House 3 (CM)',
}

export const PVERIFY_PRACTICE_TYPE_CODES = {
  '17': { label: 'Primary Care', description: 'Returns Basic Eligibility Benefits and Primary Care Visit Related Information' },
  '21': {
    label: 'Mental Health',
    description: 'Returns Basic Eligibility Benefits and Mental Health Office Visit Related Information',
  },
  '24': {
    label: 'Diagnostic Lab - Testing',
    description: 'Returns Basic Eligibility Benefits and Diagnostic Lab Service-Related Information',
  },
  '54': { label: 'Emergency Medical', description: 'Returns Basic Eligibility Benefits & Emergency Medical Service info' },
  '55': { label: 'Wellness/Routine Visit', description: 'Returns Basic Eligibility Benefits & Wellness/Routine Benefits info' },
  '57': { label: 'Outpatient Professional', description: 'Returns Basic Eligibility Benefits & Outpatient Professional Benefits' },
  '59': {
    label: 'Substance Abuse Professional',
    description: 'Returns Basic Eligibility Benefits & Substance Abuse Benefits in Professional sitting',
  },
  '60': {
    label: 'Substance Abuse In-Patient Facility',
    description: 'Returns Basic Eligibility Benefits & Substance Abuse Benefits for In-Patient Facility',
  },
  '61': {
    label: 'Substance Abuse Out-Patient Facility',
    description: 'Returns Basic Eligibility Benefits & Substance Abuse Benefits for Out-Patient Facility Service',
  },
  '64': {
    label: 'Menstal Health Substance Abuse Professional',
    description:
      'Returns Basic Eligibility Benefits & Mental Health Benefits & Substance Abuse Professional Service Benefits, combined in a single result',
  },
  '65': {
    label: 'Mental Health Substance Abuse In-Patient & Out-Patient Facility',
    description: 'Returns Basic Eligibility Benefits & Substance Benefits for In-Patient & Out-Patient, combined in a single result',
  },
  '66': {
    label: 'Basic Substance Abuse In-Patient & Out-Patient Facility',
    description:
      'Returns Basic Eligibility Benefits & Substance Abuse Professional Benefits & Substance Abuse In-Patient and Out-Patient benefits, combined in a single result',
  },
  '67': {
    label: 'Mental Health Substance Abuse In-Patient Facility',
    description:
      'Returns Basic Eligibility Benefits & Mental Health Professional Benefits & Substance Benefits In-Patient Facility, combined in a single result',
  },
}

export const INSURANCE_CODE_TYPES = {
  cpt: 'CPT',
  hcpc_mod: 'HCPC/MOD',
  hcpcs: 'HCPCS',
}

export const CLAIM_STATUSES = {
  // state_draft
  draft: 'Draft',
  validated: 'Validated',
  validation_error: 'Validation Error',

  // state_in_progress
  submitted: 'Submitted',
  submission_error: 'Submission Error',
  denied: 'Denied',

  // state_ended
  balance_due_client: 'Balance Due Client',
  paid: 'Paid',
  written_off: 'Written Off',

  // OLD statuses
  // pending: 'Pending',
  // processed: 'Processed',
  // finalized: 'Finalized',
  // submitted_to_clearinghouse: 'Submitted To Clearinghouse',
  // documentation_required: 'Documentation Required',
  // updates_required: 'Updates Required',
  // to_review: 'To Review',
  // fully_paid: 'Paid',
  // error_submission: 'Submission Error',
  // error_validation: 'Validation Error',
  // error_status: 'Claim Status Error',
}

export const CLAIM_TASK_STATUSES = {
  todo: 'To-Do',
  done: 'Done',
  in_progress: 'In Progress',
  on_hold: 'On Hold',
  urgent: 'Urgent',
}

export const CLAIM_PLACES_OF_SERVICE = {
  '01': 'Pharmacy',
  '02': 'Telehealth',
  '03': 'School',
  '04': 'Homeless Shelter',
  '05': 'Indian Health Service Free-standing Facility',
  '06': 'Indian Health Service Provider-based Facility',
  '07': 'Tribal 638 Free-standing Facility',
  '08': 'Tribal 638 Provider-based Facility',
  '09': 'Prison-Correctional Facility',
  '11': 'Office',
  '12': 'Home',
  '13': 'Assisted Living Facility',
  '14': 'Group Home',
  '15': 'Mobile Unit',
  '16': 'Temporary Lodging',
  '17': 'Walk-in Retail Health Clinic',
  '18': 'Place of Employment/Worksite',
  '19': 'Off Campus-Outpatient Hospital',
  '20': 'Urgent Care Facility',
  '21': 'Inpatient Hospital',
  '22': 'On Campus-Outpatient Hospital',
  '23': 'Emergency Room - Hospital',
  '24': 'ASC (Ambulatory Surgical Center)',
  '25': 'Birthing Center',
  '26': 'Military Treatment Center',
  '31': 'Skilled Nursing Facility',
  '32': 'Nursing Facility',
  '33': 'Custodial Care Facility',
  '34': 'Hospice',
  '41': 'Ambulance - Land',
  '42': 'Ambulance - Air/Water',
  '49': 'Independent Clinic',
  '50': 'Federally Qualified Health Center',
  '51': 'Inpatient Psychiatric Facility',
  '52': 'Psychiatric Facility - Partial Hospital',
  '53': 'Community Mental Health Center',
  '54': 'Intermediate Care Facility/Indv w/ Intellectual Disabilities',
  '55': 'Residential Substance Abuse Treatment Facility',
  '56': 'Psychiatric Residential Treatment Center',
  '57': 'Non-residential Substance Abuse Treatment Facility',
  '58': 'Non-residential Opioid Treatment Facility',
  '60': 'Mass Immunization Center',
  '61': 'Comprehensive Inpatient Rehabilitation Facility',
  '62': 'Comprehensive Outpatient Rehabilitation Facility',
  '65': 'End-Stage Renal Disease Treatment Facility',
  '71': 'State or Local Public Health Clinic',
  '72': 'Rural Health Clinic',
  '81': 'Independent Laboratory',
  '99': 'Other Place of Service',
}

export const CLAIM_TYPES_OF_SERVICE = {
  '0': 'Whole Blood',
  '1': 'Medical Care',
  '2': 'Surgery',
  '3': 'Consultation',
  '4': 'Diagnostic Radiology',
  '5': 'Diagnostic Laboratory',
  '6': 'Therapeutic Radiology',
  '7': 'Anesthesia',
  '8': 'Assistant at Surgery',
  '9': 'Other Medical Items or Services',
  A: 'Used DME',
  B: 'High Risk Screening Mammography',
  C: 'Low Risk Screening Mammography ',
  D: 'Ambulance',
  E: 'Enteral/Parenteral Nutrients/Supplies',
  F: 'Ambulatory Surgical Center',
  G: 'Immunosuppressive Drugs',
  H: 'Hospice',
  J: 'Diabetic Shoes',
  K: 'Hearing Items and Services',
  L: 'ESRD Supplies',
  M: 'Monthly Capitation Payment for Dialysis',
  N: 'Kidney Donor',
  P: 'Lump Sum Purchase of DME, Prosthetics, Orthotics',
  Q: 'Vision Items or Services',
  R: 'Rental of DME',
  S: 'Surgical Dressings or Other Medical Supplies',
  T: 'Outpatient Mental Health Treatment Limitation',
  U: 'Occupational Therapy',
  V: 'Pneumococcal/Flu Vaccine',
  W: 'Physical Therapy',
}

export const CLAIM_PATIENT_STATUSES = {
  '01': 'Discharged to home or self care (routine discharge)',
  '02': 'Discharged/Transferred to a short term general hospital for inpatient care',
  '03': 'Discharged/Transferred to skilled nursing facility (SNF) with Medicare certification',
  '04': 'Discharged/Transferred to a Facility that provides Custodial or Supportive Care',
  '05': 'Discharged/Transferred to a Designated Cancer Center or Children`s Hospital',
  '06': 'Discharged/Transferred to home under care of organized home health service organization',
  '07': 'Left against medical advice or discontinued care',
  '09': 'Admitted as an inpatient to this hospital',
  '20': 'Expired (did not recover - Christian Science patient)',
  '21': 'Discharged/Transferred to Court/Law Enforcement',
  '30': 'Still Patient',
  '40': 'Expired at home (hospice claims only)',
  '41': 'Expired in a medical facility such as hospital, SNF, ICF, or freestanding hospice. (Hospice claims only)',
  '42': 'Expired - place unknown (Hospice claims only)',
  '43': 'Discharged/transferred to a federal hospital (eff. 10/1/03)',
  '50': 'Hospice - home (eff. 10/96)',
  '51': 'Hospice-Medical Facility(Certified) Providing Hospice Level of Care',
  '61': 'Discharged/Transferred to hospital-based Medicare approved swing bed',
  '62': 'Discharged/Transferred to an inpatient rehabilitation facility (IRF) including Rehabilitation Distinct Part Units of a Hospital',
  '63': 'Discharged/Transferred to a Medicare Certified Long Term Care Hospital(LTCH)',
  '64': 'Discharged/Transferred to a Nursing Facility Certified under Medicaid but not Certified under Medicare',
  '65': 'Discharged/Transferred to a psychiatric hospital or Psychiatric Distinct Part Unit of a Hospital',
  '66': 'Discharged/Transferred to a Critical Access Hospital(CAH)',
  '69': 'Discharged/Transferred to a designated disaster alternative care site',
  '70': 'Discharged/Transferred to another type of health care institution not defined elsewhere in this code list',
  '71': 'Discharged/transferred/referred to another institution for outpatient services as specified by the discharge plan of care (eff. 9/01) (discontinued effective 10/1/05)',
  '72': 'Discharged/transferred/referred to this institution for outpatient services as specified by the discharge plan of care (eff. 9/01) (discontinued effective 10/1/05)',
  '81': 'Discharged to Home or Self Care with a Planned Acute Care Hospital Inpatient Readmission',
  '82': 'Discharged/Transferred to a Short Term General Hospital for Inpatient Care with a Planned Acute Care Hospital Inpatient Readmission',
  '83': 'Discharged/Transferred to a Skilled Nursing Facility(SNF) with Medicare Certification with a Planned Acute Care Hospital Inpatient Readmisison',
  '84': 'Discharged/Transferred to a Facility that Provides Custodial or Supportive Care with a Planned Acute Care Hospital Inpatient Readmission',
  '85': 'Discharged/Transferred to a Designated Cancer Center or Children`s Hospital with a Planned Acute Care Hospital Inpatient Readmission',
  '86': 'Discharged/Transferred to Home Under Care of Organized Home Health Service Organization with a Planned Acute Care Hospital Inpatient Readmission',
  '87': 'Discharged/Transferred to Court/Law Enforcement with a Planned Acute Care Hospital Inpatient Readmission',
  '88': 'Discharged/Transferred to a Federal Health Care Facility with a Planned Acute Care Hospital Inpatient Readmission',
  '89': 'Discharged/Transferred to a Hospital-based Medicare Approved Swing Bed with a Planned Acute Care Hospital Inpatient Readmission',
  '90': 'Discharged/Transferred to an Inpatient Rehabilitation Facility(IRF) including Rehabilitation Distinct Part Units of a Hospital with a Planned Acute Care Hospital Inpatient Readmission',
  '91': 'Discharged/Transferred to a Medicare Certified Long Term Care Hospital(LTCH) with a Planned Acute Care Hospital Inpatient Readmission',
  '92': 'Discharged/Transferred to a Nursing Facility Certified Under Medicaid but not Certified Under Medicare with a Planned Acute Care Hospital Inpatient Readmission',
  '93': 'Discharged/Transferred to a Psychiatric Hospital or Psychiatric Distinct Part Unit of a Hospital with a Planned Acute Care Hospital Inpatient Readmission',
  '94': 'Discharged/Transferred to a Critical Access Hospital(CAH) with a Planned Acute Care Hospital Inpatient Readmission',
  '95': 'Discharged/Transferred to Another Type of Health Care Institution not Defined Elsewhere in this Code List with a Planned Acute Care Hospital Inpatient Readmission',
}

export const CUSTOM_NOTE_PORTAL_SETTINGS = {
  staff_only: 'Staff only, no Client view (eg. Internal Notes)',
  view_only: 'Clients can only view (eg. Meeting Notes)',
  self_add: 'Clients can view, and self-add (eg. Overnight Request Forms)',
  assigned: 'Clients can view, and be asked to complete by Staff (eg. specific Homework Assignments)',
}

export const STAFF_USER_ROLES = [
  { value: 'owner', name: 'Account Owner User' },
  { value: 'billing', name: 'Billing User' },
  { value: 'clinical', name: 'Clinical User' },
  { value: 'coordinator', name: 'Operations User' },
  { value: 'management', name: 'Management User' },
  { value: 'medical', name: 'Medical User' },
  { value: 'part_time_clinical', name: 'Part-Time Clinical User' },
  { value: 'part_time_medical', name: 'Part-Time Medical User' },
]

export const DATA_FORMS = {
  clinical_assessment: {
    asam_criteria: 'ASAM Criteria',
    biopsychosocial: 'Biopsychosocial Assessment',
    mental_status_exam: 'Mental Status Exam',
    prescreen: 'Pre-Screen',
    suicide_assessment: 'Suicide Assessment',
  },
  clinical_measurement: {
    ace_score: 'ACE Score',
    asi5: 'Addiction Severity Index, 5th Edition',
    asi_lite: 'ASI The Addiction Severity Index - Lite',
    asrm: 'ASRM Altman Self-Rating Mania Scale',
    audit_c: 'AUDIT-C',
    bam: 'BAM Brief Addiction Monitor',
    barc10: 'Brief Assessment of Recovery Capital (BARC-10)',
    dast10: 'DAST-10 Somatic / Physical Symptoms',
    dow: 'Dimensions of Wellness',
    gad7: 'GAD-7 Anxiety',
    mdi: 'MDI Major Depression Inventory',
    pclc: 'PCL-C PTSD CheckList Civilian Version',
    pdss: 'PDSS Panic Disorder Severity Scale (Self Report)',
    phq9: 'PHQ-9 Depression',
    phq15: 'PHQ-15 Somatic / Physical Symptoms',
    whoqol: 'The World Health Organization Quality of Life (WHOQOL)-BREF',
  },
  discharge: {
    discharge_summary: 'Discharge Summary',
  },
  medical_assessment: {
    client_health_questionnaire_initial_screening: 'Client Health Questionnaire & Initial Screening',
    client_health_questionnaire: 'Client Health Questionnaire',
    history_and_physical_follow_up: 'History And Physical Follow Up',
    history_and_physical: 'History And Physical',
    psychiatric_assessment: 'Psychiatric Evaluation',
  },
  physician_order: {
    physician_order: 'Physician Order',
  },
  progress_note: {
    progress_note: 'Progress Note',
  },
  progress_review: {
    progress_review: 'Progress Review',
  },
  treatment_plan: {
    treatment_plan: 'Treatment Plan',
  },
}

export const FORM_SUBMISSION_STATUSES = {
  draft: 'Draft',
  pending: 'Pending',
  updates_required: 'Updates Required',
  in_progress: 'In Progress',
  approved: 'Approved',
  rejected: 'Rejected',
  done: 'Done',
  archived: 'Archived',
}

export const FORM_SHARE_STATUSES = {
  private_status: 'Private Status',
  public_status: 'Public Status',
}

export const FORM_SUBMISSION_CATEGORIES = {
  client: 'Client',
  employee: 'Staff',
  location: 'Location',
  organization: 'Organization',
}

export const CLIENT_SLUG_BY_STATUS: any = {
  lead: 'admissions',
  intake: 'admissions',
  declined: 'admissions',
  current: 'clients',
  alumni: 'alumni',
}

export const DISCHARGE_FORMS_SLUGS: any = {
  discharge_summary: 'discharge-summary',
  clinical_discharge_summary: 'clinical-discharge-summary',
  non_clinical_discharge_summary: 'non-clinical-discharge-summary',
}

export const CLINICAL_ASSESSMENTS_SLUGS: any = {
  asam_criteria: 'asam-criteria',
  biopsychosocial: 'biopsychosocial-assessment',
  eating_disorder_bps: 'eating-disorder-bps',
  mental_health_prescreen: 'mental-health-pre-screen',
  mental_status_exam: 'mental-status-exam',
  prescreen: 'pre-screen',
  psychiatric_assessment: 'psychiatric-assessment',
  suicide_assessment: 'suicide-assessment',
}

export const CLINICAL_ASSESSMENTS_TITLES: any = {
  asam_criteria: 'ASAM Criteria',
  biopsychosocial: 'Biopsychosocial',
  eating_disorder_bps: 'Eating Disorder Biopsychosocial Assessment',
  mental_health_prescreen: 'Mental Health Pre-Screen',
  mental_status_exam: 'Mental Status Exam',
  prescreen: 'Pre-Screen',
  psychiatric_assessment: 'Psychiatric Assessment',
  suicide_assessment: 'Suicide Assessment',
}

export const CLINICAL_MEASURES_SLUGS = {
  gad7: 'gad7',
  phq9: 'phq9',
  phq15: 'phq15',
  dast10: 'dast10',
  mdi: 'mdi',
  bam: 'bam',
  audit_c: 'audit-c',
  pclc: 'pclc',
  ace_score: 'ace-score',
  asrm: 'asrm',
  asi_lite: 'asi-lite',
  pdss: 'pdss',
  barc10: 'barc10',
  whoqol: 'whoqol',
  asi5: 'asi5',
  dow: 'dow',
  tea: 'tea',
  eat26: 'eat26',
  edeq6: 'edeq6',
  scoff: 'scoff',

  // NEW Forms:
  // cls: 'cls',
  // proqol: 'proqol',
  // mscsb: 'mscsb',
  // phq9_teens: 'phq9-teens',
  // cpss_v_sr: 'cpss-v-sr',
}

export const CLINICAL_MEASURES_TITLES: any = {
  gad7: 'GAD-7 Anxiety',
  phq9: 'PHQ-9 Depression',
  phq15: 'PHQ-15 Somatic / Physical Symptoms',
  dast10: 'DAST-10 Drug Abuse Screening Test',
  mdi: 'MDI Major Depression Inventory',
  bam: 'BAM Brief Addiction Monitor',
  audit_c: 'AUDIT-C',
  pclc: 'PCL-C PTSD CheckList Civilian Version',
  ace_score: 'ACE Score',
  asrm: 'ASRM Altman Self-Rating Mania Scale',
  asi_lite: 'ASI The Addiction Severity Index (Lite)',
  pdss: 'PDSS Panic Disorder Severity Scale (Self Report)',
  barc10: 'BARC-10 Brief Assessment of Recovery Capital',
  whoqol: 'The World Health Organization Quality of Life (WHOQOL)-BREF',
  asi5: 'ASI-5 Addiction Severity Index',
  dow: 'Dimensions of Wellness',
  tea: 'Treatment Effectiveness Assessment (TEA)',
  // eat26: 'Eating Attitudes Test (EAT-26)',
  edeq6: 'Eating Disorder Examination Questionnaire (EDE-Q 6.0)',
  // scoff: 'SCOFF Questionnaire',

  // NEW Forms:
  // cls: 'The Casey Life Skills (CLS)',
  // proqol: 'Professional Quality Of Life Scale (PROQOL)',
  // mscsb: 'Mindful Self-Care Scale – Brief',
  // phq9_teens: 'PHQ-9 Modified for Teens',
  // cpss_v_sr: 'The Child PTSD Symptom Scale For DSM-V (CPSS-V SR)',
}

export const CLINICAL_NOTES_TITLES: any = {
  all: 'Clinical Notes',
  individual: 'Individual Note',
  group_note: 'Group Note',
  family_session: 'Family Session',
  case_management: 'Case Management',
}

export const PEER_NOTES_TITLES: any = {
  all: 'Clinical Notes',
  individual: 'Individual Note',
  group_note: 'Group Note',
}

export const OUTCOME_MEASURES_SLUGS: any = {
  discharge_reminder_form: 'discharge-reminder',
  follow_up_form: 'follow-up',
  forse_admit_survey: 'admit-survey',
  forse_demographic_information: 'demographic-information',
  forse_discharge_survey: 'discharge-survey',
  forse_outcomes_survey: 'outcomes-survey',
  forse_progress_monitoring_survey: 'progress-monitoring-survey',
  intake_form: 'intake',
  locator_form: 'locator',
  mirc: 'mirc',
  recovery_capital_scale: 'recovery-capital-scale',
  recovery_capital_plan: 'recovery-capital-plan',
  recovery_capital_scale_and_plan: 'recovery-capital-scale-and-plan',
  service_summary: 'service-summary',
}

export const OUTCOME_MEASURES_TITLES: any = {
  discharge_reminder_form: 'Discharge Reminder Form',
  follow_up_form: 'Follow-up Form',
  forse_admit_survey: 'Admit Survey',
  forse_demographic_information: 'Demographic Information Form',
  forse_discharge_survey: 'Discharge Survey',
  forse_outcomes_survey: 'Outcomes Survey',
  forse_progress_monitoring_survey: 'Progress Monitoring Survey',
  intake_form: 'Intake Form',
  locator_form: 'Locator Form',
  mirc: 'Multidimensional Inventory of Recovery Capital',
  recovery_capital_scale: 'Recovery Capital Scale',
  recovery_capital_plan: 'Recovery Capital Plan',
  recovery_capital_scale_and_plan: 'Recovery Capital Scale & Plan',
  service_summary: 'Service Summary Form',
}

export const MEDICAL_ASSESSMENTS_TITLES: any = {
  history_and_physical: 'History & Physical',
  history_and_physical_follow_up: 'History & Physical Follow Up',
  psychiatric_assessment: 'Psychiatric Assessment',
  client_health_questionnaire: 'Client Health Questionnaire',
  client_health_questionnaire_initial_screening: 'Client Health Questionnaire & Initial Screening',
}

export const MEDICAL_ASSESSMENTS_SLUGS: any = {
  history_and_physical: 'history-and-physical',
  history_and_physical_follow_up: 'history-and-physical-follow-up',
  psychiatric_assessment: 'psychiatric-assessment',
  client_health_questionnaire: 'client-health-questionnaire',
  client_health_questionnaire_initial_screening: 'client-health-questionnaire-initial-screening',
}

export const NURSING_SLUGS = {
  ciwa: 'ciwa',
  cows: 'cows',
  fall_risk_assessment: 'fall-risk-assessment',
  nursing_assessment: 'nursing-assessment',
  nursing_reassessment: 'nursing-reassessment',
  progress_note: 'progress-note',
}

export const NURSING_TITLES = {
  ciwa: 'CIWA',
  cows: 'COWS',
  fall_risk_assessment: 'Fall Risk Assessment',
  nursing_assessment: 'Nursing Assessment',
  nursing_reassessment: 'Nursing Re-Assessment',
  progress_note: 'Progress Note',
}

export const DIAGNOSES_SOURCES = {
  erx: 'eRX',
  internal: 'Custom',
}

export const PROGRAM_ASSIGNEE_CATEGORIES: any = {
  applicants: {
    name: 'Applicants',
    model: 'applicants',
    icon: 'applicants',
  },
  current_clients: {
    name: 'Current Clients',
    model: 'current_clients',
    icon: 'clients',
  },
  past_clients: {
    name: 'Past Clients',
    model: 'past_clients',
    icon: 'alumni_relations',
  },
  staff: {
    name: 'Staff',
    model: 'staff',
    icon: 'employees',
  },
  client_contacts: {
    name: 'Client Contacts',
    model: 'client_contacts',
    icon: 'contacts',
  },
  organization_contacts: {
    name: 'Organization Contacts',
    model: 'organization_contacts',
    icon: 'contacts',
  },
}

export const RECOVERY_NOTES_SLUGS: any = {
  case_management_checklist: 'case-management-checklist',
  recovery_capital_scale: 'recovery-capital-scale',
  recovery_capital_plan: 'recovery-capital-plan',
  recovery_capital_scale_and_plan: 'recovery-capital-scale-and-plan',
  mirc: 'mirc',
}

export const MEDICATION_TIMES: any = {
  am: 'AM',
  pm: 'PM',
  morning: 'Morning',
  afternoon: 'Afternoon',
  evening: 'Evening',
  bedtime: 'Bedtime',
  prn: 'PRN',
}

export const SLUG_RESOURCE_TYPES: any = {
  admissions: 'resident',
  clients: 'resident',
  alumni: 'resident',
  employees: 'employee',
  locations: 'house',
  organizations: 'organization',
}

export const FILE_TYPES: any = {
  // ms office
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    name: 'Excel File',
    color: 'vividBlue',
  },
  'application/vnd.ms-excel': {
    name: 'Excel File',
    color: 'vividBlue',
  },
  'application/msword': {
    name: 'Word File',
    color: 'vividBlue',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    name: 'Word File',
    color: 'vividBlue',
  },
  // document files
  'application/pdf': {
    name: 'PDF File',
    color: 'vividBlue',
  },
  'text/csv': {
    name: 'CSV File',
    color: 'vividBlue',
  },
  // images
  'image/jpeg': {
    name: 'JPEG Image',
    color: 'vividBlue',
  },
  'image/png': {
    name: 'PNG Image',
    color: 'vividBlue',
  },
  'image/bmp': {
    name: 'BMP Image',
    color: 'vividBlue',
  },
  'image/gif': {
    name: 'GIF Image',
    color: 'vividBlue',
  },
  'audio/x-m4a': {
    name: 'M4A Audio',
    color: 'orange',
  },
  'audio/mpeg': {
    name: 'MP3 Audio',
    color: 'orange',
  },
  'audio/wav': {
    name: 'WAV Audio',
    color: 'orange',
  },
  // default
  default: {
    name: 'File',
    color: 'vividBlue',
  },
}

export const PROPERTY_CATEGORIES: any = {
  housing: 'Housing',
  office: 'Office',
  mixed_category: 'Mixed (Housing & Office)',
}

export const PROFILE_FORMS = {
  donation_form: {
    id: 'donation_form',
    title: 'Donation Form',
    description: 'Used by individuals or corporations to provide financial support to the organization.',
    slug: 'donation-form',
  },
  transportation_form: {
    id: 'transportation_form',
    title: 'Transportation Form',
    description: 'For external entities to organize and schedule transportation for individuals to the addiction treatment center.',
    slug: 'transportation-form',
  },
  confidentiality_agreement_form: {
    id: 'confidentiality_agreement_form',
    title: 'Confidentiality Agreement Form',
    description: 'An agreement to be filled out by external entities guaranteeing the confidential handling of sensitive information.',
    slug: 'confidentiality-agreement-form',
  },
  vendor_application_form: {
    id: 'vendor_application_form',
    title: 'Vendor Application Form',
    description: 'Used by businesses that wish to offer their services or products to the addiction treatment center.',
    slug: 'vendor-application-form',
  },
  job_application_form: {
    id: 'job_application_form',
    title: 'Job Application Form',
    description: 'A form to be filled out by potential candidates seeking employment with the organization.',
    slug: 'job-application-form',
  },
  conference_event_participation_form: {
    id: 'conference_event_participation_form',
    title: 'Conference/Event Participation Form',
    description:
      'For organizations or individuals wishing to participate in a conference or event organized by the addiction treatment center.',
    slug: 'conference-event-participation-form',
  },
  professional_consultation_request_form: {
    id: 'professional_consultation_request_form',
    title: 'Professional Consultation Request Form',
    description: 'External entities can use this form to request professional consultation from the addiction treatment center.',
    slug: 'professional-consultation-request-form',
  },
  referral_partner_form: {
    id: 'referral_partner_form',
    title: 'Referral Partner Form',
    description: 'Used by outside organizations to refer individuals to the addiction treatment center.',
    slug: 'referral-partner-form',
  },
  training_request_form: {
    id: 'training_request_form',
    title: 'Training Request Form',
    description:
      'Used by external organizations or individuals to request training programs or sessions from the addiction treatment center.',
    slug: 'training-request-form',
  },
  research_collaboration_form: {
    id: 'research_collaboration_form',
    title: 'Research Collaboration Form',
    description:
      'A form for other organizations or researchers to propose or initiate research collaborations with the addiction treatment center.',
    slug: 'research-collaboration-form',
  },
  corporate_partnership_form: {
    id: 'corporate_partnership_form',
    title: 'Corporate Partnership Form',
    description: 'A form for corporate entities wishing to establish a partnership with the addiction treatment center.',
    slug: 'corporate-partnership-form',
  },
  medical_records_request_form: {
    id: 'medical_records_request_form',
    title: 'Medical Records Request Form',
    description: 'A form for patients or authorized individuals to request access to specific medical records.',
    slug: 'medical-records-request-form',
  },
  medical_records_submission_form: {
    id: 'medical_records_submission_form',
    title: 'Medical Records Submission Form',
    description: 'A form used by healthcare professionals or patients to submit medical records.',
    slug: 'medical-records-submission-form',
  },
  appointment_cancellation_rescheduling_form: {
    id: 'appointment_cancellation_rescheduling_form',
    title: 'Appointment Cancellation/Rescheduling Form',
    description: 'For patients to cancel or reschedule their appointments at the addiction treatment center.',
    slug: 'appointment-cancellation-rescheduling-form',
  },
}

export const ORGANIZATIONS_TYPE_VENDORS = {
  billing_service: 'Billing Service',
  cable_internet_phone: 'Cable, Internet & Phone',
  computers_electronics: 'Computers & Electronics',
  donor: 'Donor',
  electrical_service: 'Electrical Service',
  electrician: 'Electrician',
  financial_institution: 'Financial Institution',
  food_groceries: 'Food & Groceries',
  furniture_vendor_repair: 'Furniture Vendor/Repair',
  garbage_service: 'Garbage Service',
  gas_service: 'Gas Service',
  government_organization: 'Government Organization',
  heating_cooling: 'Heating/Cooling',
  hoa_fees: 'HOA Fees',
  home_repair_supplies: 'Home Repair & Supplies',
  human_resources: 'Human Resources',
  industry_association: 'Industry Association',
  insurance: 'Insurance',
  landlord: 'Landlord',
  landscaping: 'Landscaping',
  large_appliance_vendor_repair: 'Large Appliance Vendor/Repair',
  legal: 'Legal',
  licensing_compliance: 'Licensing & Compliance',
  marketing: 'Marketing',
  mobile_phone_data: 'Mobile Phone/Data',
  other: 'Other',
  plumber: 'Plumber',
  recycling_service: 'Recycling Service',
  software: 'Software',
  water_service: 'Water Service',
}

export const ORGANIZATIONS_TYPE_RESOURCES = {
  education_training: 'Education & Training',
  employer: 'Employer',
  general_resource: 'General Resource',
  government_program: 'Government Program',
  health_wellness_recreation: 'Health, Wellness & Recreation',
  mutual_aid_peer_community_support: 'Mutual Aid, Peer & Community Support',
  religious_organization: 'Religious Organization',
  volunteer_service_organization: 'Volunteer & Service Organization',
}

export const ORGANIZATIONS_TYPE_PROVIDERS = {
  clinic: 'Clinic',
  community: 'Community',
  hospital: 'Hospital',
  individual_provider: 'Individual Providers',
  laboratory: 'Laboratory',
  sober_living_home: 'Sober Living Homes',
  treatment_center: 'Treatment Centers',
}

export const RELATIONSHIP_OPTIONS = [
  { label: 'Friend', value: 'friend', group: 'Friends & Family' },
  { label: 'Mother', value: 'mother', group: 'Friends & Family' },
  { label: 'Father', value: 'father', group: 'Friends & Family' },
  { label: 'Brother', value: 'brother', group: 'Friends & Family' },
  { label: 'Sister', value: 'sister', group: 'Friends & Family' },
  { label: 'Child', value: 'child', group: 'Friends & Family' },
  { label: 'Grandfather', value: 'grandfather', group: 'Friends & Family' },
  { label: 'Grandmother', value: 'grandmother', group: 'Friends & Family' },
  { label: 'Aunt', value: 'aunt', group: 'Friends & Family' },
  { label: 'Uncle', value: 'uncle', group: 'Friends & Family' },
  { label: 'Niece', value: 'niece', group: 'Friends & Family' },
  { label: 'Nephew', value: 'nephew', group: 'Friends & Family' },
  { label: 'Cousin', value: 'cousin', group: 'Friends & Family' },
  { label: 'Relative', value: 'relative', group: 'Friends & Family' },
  { label: 'Sponsor', value: 'sponsor', group: 'Care Team' },
  { label: 'Case Manager', value: 'case_manager', group: 'Care Team' },
  { label: 'Recovery Coach', value: 'recovery_coach', group: 'Care Team' },
  { label: 'Medical Doctor (PCP)', value: 'medical_doctor', group: 'Care Team' },
  { label: 'Medication-Assisted-Treatment Doctor (MAT)', value: 'mat_doctor', group: 'Care Team' },
  { label: 'Psychiatrist', value: 'psychiatrist', group: 'Care Team' },
  { label: 'Psychologist', value: 'psychologist', group: 'Care Team' },
  { label: 'Therapist', value: 'therapist', group: 'Care Team' },
  { label: 'Nutritionist', value: 'nutritionist', group: 'Care Team' },
  { label: 'Employer', value: 'employer', group: 'Employment' },
  { label: 'Employee-Assistance-Program Contact', value: 'eap_contact', group: 'Employment' },
  { label: 'Union Representative', value: 'union_representative', group: 'Employment' },
  { label: 'Probation Officer', value: 'probation_officer', group: 'Government Officials' },
  { label: 'Parole Officer', value: 'parole_officer', group: 'Government Officials' },
  { label: 'House Arrest Contact', value: 'house_arrest_contact', group: 'Government Officials' },
  { label: 'Drug Court Contact', value: 'drug_court_contact', group: 'Government Officials' },
  { label: 'Social Services Case Worker', value: 'social_services_case_worker', group: 'Government Officials' },
  { label: 'Domestic Violence Case Worker', value: 'domestic_violence_case_worker', group: 'Government Officials' },
  { label: 'Attorney', value: 'attorney', group: 'Legal' },
  { label: 'Legal Contact', value: 'legal_contact', group: 'Legal' },
  { label: 'Other', value: 'other', group: 'Other' },
]

export const ETHNICITY = [
  { label: 'American Indian or Alaska Native', value: 'american_indian_or_alaska_native' },
  { label: 'Asian', value: 'asian' },
  { label: 'Black or African American', value: 'black_or_african_american' },
  { label: 'Hispanic or Latino', value: 'hispanic_or_latino' },
  { label: 'Native Hawaiian or Other Pacific Islander', value: 'native_hawaiian_or_other_pacific_islander' },
  { label: 'White', value: 'white' },
  { label: 'Other', value: 'other' },
]

export const SEX_ASSIGNED_AT_BIRTH = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Decline to respond', value: 'decline_to_respond' },
  { label: 'Other', value: 'other' },
]

export const GENDER_IDENTITY = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Transgender Male / Transman / FTM', value: 'transman' },
  { label: 'Transgender Female / Transwoman / MTF', value: 'transwoman' },
  { label: 'Gender Queer', value: 'queer' },
  { label: 'Decline to respond', value: 'decline_to_respond' },
  { label: 'Other', value: 'other' },
]

export const PREFERRED_PRONOUNS = [
  { label: 'She/her/hers', value: 'she_her' },
  { label: 'He/him/his', value: 'he_him' },
  { label: 'They/them/theirs', value: 'they_them' },
  { label: 'Other', value: 'other' },
]

export const HIGHEST_LEVEL_OF_EDUCATION = [
  { label: 'Elementary or High school, no diploma', value: 'elementary_or_high_school_no_diploma' },
  { label: 'Elementary or High school, GED', value: 'elementary_or_high_school_ged' },
  { label: 'High school diploma', value: 'high_school_diploma' },
  { label: 'College, no degree', value: 'college_no_degree' },
  { label: "Associate's degree, vocational", value: 'associates_degree_vocational' },
  { label: "Associate's degree, academic", value: 'associates_degree_academic' },
  { label: "Bachelor's degree", value: 'bachelors_degree' },
  { label: "Master's degree", value: 'masters_degree' },
  { label: 'Professional degree', value: 'professional_degree' },
  { label: 'Doctorate degree', value: 'doctorate_degree' },
  { label: 'Other', value: 'other' },
]

const GROUP_1 = ' '
const GROUP_2 = '  '
const GROUP_3 = '   '

export const RELIGIOUS_PREFERENCE = [
  { label: 'Decline to respond', value: 'decline_to_respond', group: GROUP_1 },
  { label: 'Agnostic', value: 'agnostic', group: GROUP_2 },
  { label: 'Apostolic/New Apostolic', value: 'apostolic_new_apostolic', group: GROUP_2 },
  { label: 'Assemblies of God', value: 'assemblies_of_god', group: GROUP_2 },
  { label: 'Atheist', value: 'atheist', group: GROUP_2 },
  { label: 'Baptist', value: 'baptist', group: GROUP_2 },
  { label: 'Buddhist', value: 'buddhist', group: GROUP_2 },
  { label: 'Catholic', value: 'catholic', group: GROUP_2 },
  { label: 'Christian Reform', value: 'christian_reform', group: GROUP_2 },
  { label: 'Christian Science', value: 'christian_science', group: GROUP_2 },
  { label: 'Christian', value: 'christian', group: GROUP_2 },
  { label: 'Church of God', value: 'church_of_god', group: GROUP_2 },
  { label: 'Church of the Brethren', value: 'church_of_the_brethren', group: GROUP_2 },
  { label: 'Church of the Nazarene', value: 'church_of_the_nazarene', group: GROUP_2 },
  { label: 'Churches of Christ', value: 'churches_of_christ', group: GROUP_2 },
  { label: 'Congregational/United Church of Christ', value: 'congregational_united_church_of_christ', group: GROUP_2 },
  { label: 'Disciples of Christ', value: 'disciples_of_christ', group: GROUP_2 },
  { label: 'Episcopalian/Anglican', value: 'episcopalian_anglican', group: GROUP_2 },
  { label: 'Evangelical/Born Again', value: 'evangelical_born_again', group: GROUP_2 },
  { label: 'Foursquare Gospel', value: 'foursquare_gospel', group: GROUP_2 },
  { label: 'Hindu', value: 'hindu', group: GROUP_2 },
  { label: 'Holiness/Holy', value: 'holiness_holy', group: GROUP_2 },
  { label: 'Humanist', value: 'humanist', group: GROUP_2 },
  { label: 'Independent Christian Church', value: 'independent_christian_church', group: GROUP_2 },
  { label: 'Jewish', value: 'jewish', group: GROUP_2 },
  { label: 'Lutheran', value: 'lutheran', group: GROUP_2 },
  { label: 'Mennonite', value: 'mennonite', group: GROUP_2 },
  { label: 'Methodist/Wesleyan', value: 'methodist_wesleyan', group: GROUP_2 },
  { label: 'Mormon/Latter-Day Saints', value: 'mormon_latter_day_saints', group: GROUP_2 },
  { label: 'Muslim', value: 'muslim', group: GROUP_2 },
  { label: 'Native American', value: 'native_american', group: GROUP_2 },
  { label: 'Nondenominational', value: 'nondenominational', group: GROUP_2 },
  { label: 'Orthodox (Eastern)', value: 'orthodox_eastern', group: GROUP_2 },
  { label: 'Other Christian', value: 'other_christian', group: GROUP_2 },
  { label: 'Pagan', value: 'pagan', group: GROUP_2 },
  { label: 'Pentecostal/Charismatic', value: 'pentecostal_charismatic', group: GROUP_2 },
  { label: 'Presbyterian', value: 'presbyterian', group: GROUP_2 },
  { label: 'Protestant', value: 'protestant', group: GROUP_2 },
  { label: 'Quaker', value: 'quaker', group: GROUP_2 },
  { label: 'Reformed/Dutch Reform', value: 'reformed_dutch_reform', group: GROUP_2 },
  { label: 'Seventh-Day Adventist', value: 'seventh_day_adventist', group: GROUP_2 },
  { label: 'Sikh', value: 'sikh', group: GROUP_2 },
  { label: 'Spiritualist', value: 'spiritualist', group: GROUP_2 },
  { label: 'Unitarian/Universalist', value: 'unitarian_universalist', group: GROUP_2 },
  { label: 'Wiccan', value: 'wiccan', group: GROUP_2 },
  { label: "Jehovah's Witness", value: 'jehovah_s_witness', group: GROUP_2 },
  { label: 'No Religion', value: 'no_religion', group: GROUP_3 },
  { label: 'Other', value: 'other', group: GROUP_3 },
]

export const PRIMARY_LANGUAGE = [
  { label: 'English', value: 'english', group: GROUP_1 },
  { label: 'African languages', value: 'african_languages', group: GROUP_2 },
  { label: 'Arabic', value: 'arabic', group: GROUP_2 },
  { label: 'Armenian', value: 'armenian', group: GROUP_2 },
  { label: 'Chinese', value: 'chinese', group: GROUP_2 },
  { label: 'French (including Patois, Cajun)', value: 'french_including_patois_cajun', group: GROUP_2 },
  { label: 'French Creole', value: 'french_creole', group: GROUP_2 },
  { label: 'German', value: 'german', group: GROUP_2 },
  { label: 'Greek', value: 'greek', group: GROUP_2 },
  { label: 'Gujarathi', value: 'gujarathi', group: GROUP_2 },
  { label: 'Hebrew', value: 'hebrew', group: GROUP_2 },
  { label: 'Hindi', value: 'hindi', group: GROUP_2 },
  { label: 'Hmong', value: 'hmong', group: GROUP_2 },
  { label: 'Hungarian', value: 'hungarian', group: GROUP_2 },
  { label: 'Italian', value: 'italian', group: GROUP_2 },
  { label: 'Japanese', value: 'japanese', group: GROUP_2 },
  { label: 'Korean', value: 'korean', group: GROUP_2 },
  { label: 'Laotian', value: 'laotian', group: GROUP_2 },
  { label: 'Mon-Khmer, Cambodian', value: 'mon_khmer_cambodian', group: GROUP_2 },
  { label: 'Navajo', value: 'navajo', group: GROUP_2 },
  { label: 'Other Asian languages', value: 'other_asian_languages', group: GROUP_2 },
  { label: 'Other Indic languages', value: 'other_indic_languages', group: GROUP_2 },
  { label: 'Other Indo-European languages', value: 'other_indo_european_languages', group: GROUP_2 },
  { label: 'Other Native North American languages', value: 'other_native_north_american_languages', group: GROUP_2 },
  { label: 'Other Pacific Island languages', value: 'other_pacific_island_languages', group: GROUP_2 },
  { label: 'Other Slavic languages', value: 'other_slavic_languages', group: GROUP_2 },
  { label: 'Other West Germanic languages', value: 'other_west_germanic_languages', group: GROUP_2 },
  { label: 'Persian', value: 'persian', group: GROUP_2 },
  { label: 'Polish', value: 'polish', group: GROUP_2 },
  { label: 'Portuguese or Portuguese Creole', value: 'portuguese_or_portuguese_creole', group: GROUP_2 },
  { label: 'Russian', value: 'russian', group: GROUP_2 },
  { label: 'Scandinavian languages', value: 'scandinavian_languages', group: GROUP_2 },
  { label: 'Serbo-Croatian', value: 'serbo_croatian', group: GROUP_2 },
  { label: 'Spanish or Spanish Creole', value: 'spanish_or_spanish_creole', group: GROUP_2 },
  { label: 'Tagalog', value: 'tagalog', group: GROUP_2 },
  { label: 'Thai', value: 'thai', group: GROUP_2 },
  { label: 'Urdu', value: 'urdu', group: GROUP_2 },
  { label: 'Vietnamese', value: 'vietnamese', group: GROUP_2 },
  { label: 'Yiddish', value: 'yiddish', group: GROUP_2 },
  { label: 'Other', value: 'other', group: GROUP_3 },
]

export const MARITAL_STATUS = [
  { label: 'Single', value: 'single' },
  { label: 'Married', value: 'married' },
  { label: 'Engaged', value: 'engaged' },
  { label: 'Divorced', value: 'divorced' },
  { label: 'Separated', value: 'separated' },
  { label: 'Domestic Partnered', value: 'domestic_partnered' },
  { label: 'Widowed', value: 'widowed' },
]

export const CARE_TEAM_RELATION_TYPES = {
  therapist: 'Primary Therapist',
  psychiatrist: 'Psychiatrist',
  recovery_coach: 'Recovery Coach',
  care_physician: 'Primary Care Physician',
  nurse: 'Nurse',
  case_manager: 'Case Manager',
  interventionist: 'Interventionist',
  probation_officer: 'Probation Officer',
  peer_provider: 'Peer Provider',
  sponsor: 'Sponsor',
}

export const WITHDRAWAL_SYMPTOMS = [
  { label: 'Depression', model: 'depression' },
  { label: 'Anxiety', model: 'anxiety' },
  { label: 'Irritability', model: 'irritability' },
  { label: 'Stress sensitivity', model: 'stress_sensitivity' },
  { label: 'Hopelessness', model: 'hopelessness' },
  { label: 'Difficulty concentrating', model: 'difficulty_concentrating' },
  { label: 'Fearfulness', model: 'fearfulness' },
  { label: 'Mood swings', model: 'mood_swings' },
  { label: 'Nausea', model: 'nausea' },
  { label: 'Vomiting', model: 'vomiting' },
  { label: 'Tremors', model: 'tremors' },
  { label: 'Runny nose', model: 'runny_nose' },
  { label: 'Body aches', model: 'body_aches' },
  { label: 'Auditory or visual disturbances', model: 'auditory_or_visual_disturbances' },
  { label: 'Tactile disturbances (skin crawling, etc)', model: 'tactile_disturbances' },
  { label: 'Dizziness', model: 'dizziness' },
  { label: 'Headache', model: 'headache' },
  { label: 'Disorientation or cloudiness', model: 'disorientation_or_cloudiness' },
  { label: 'Agitation or restlessness', model: 'agitation_or_restlessness' },
  { label: 'Upset stomach/Cramps', model: 'upset_stomach_cramps' },
]
