import React from 'react'

import { address, isDefined } from '../../../utils/functions'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

const formatValue = ({ value }: any) => {
  if (!isDefined(value)) return <EmptyCell />

  return address(value)
}

export const AddressCell: React.FC = (props: any) => {
  return <DataTableCell {...props} hoverExpand formatValue={formatValue} />
}
