import React from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'

const ANIMATION_TIME = 8000

const randomInRange = (min, max) => Math.random() * (max - min) + min

const getAnimationSettings = (originXA, originXB) => ({
  startVelocity: 30,
  spread: 460,
  ticks: 120,
  zIndex: 0,
  particleCount: 150,
  origin: {
    x: randomInRange(originXA, originXB),
    y: Math.random() - 0.2,
  },
})

const ConfettiFireworks = ({ runs = 1, style }: any) => {
  const [animationEnabled, setAnimationEnabled] = React.useState(true)
  const confetti = React.useRef(null)
  const interval = React.useRef(null)

  const nextTickAnimation = () => {
    if (!confetti.current) return

    confetti.current(getAnimationSettings(0.2, 0.4))
    confetti.current(getAnimationSettings(0.6, 0.9))
  }

  React.useEffect(() => {
    const runOnceInterval = setInterval(() => {
      setAnimationEnabled(false)

      if (runOnceInterval) clearInterval(runOnceInterval)
    }, 5000)

    return () => {
      if (interval.current) clearInterval(interval.current)
      if (runOnceInterval) clearInterval(runOnceInterval)
    }
  }, [])

  React.useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current)
    }

    let runOnceInterval = null
    if (animationEnabled) {
      interval.current = setInterval(nextTickAnimation, 1000)

      runOnceInterval = setInterval(() => {
        setAnimationEnabled(false)

        if (runOnceInterval) clearInterval(runOnceInterval)
      }, ANIMATION_TIME)
    }

    return () => {
      if (runOnceInterval) clearInterval(runOnceInterval)
    }
  }, [animationEnabled])

  React.useEffect(() => {
    if (!animationEnabled) setAnimationEnabled(true)
  }, [runs])

  return (
    <ReactCanvasConfetti
      refConfetti={(ref) => {
        confetti.current = ref
      }}
      style={style || canvasStyles}
    />
  )
}

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
}

export default ConfettiFireworks
