import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { countWord, titleCase } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Chotomate,
  Dropdown,
  DropdownItem,
  Grid,
  HelpTagIframe,
  Icon,
  Page,
  PageLayout,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@behavehealth/components'

import { EPrescribingAccess } from '@behavehealth/constructs/ERX/EPrescribingAccess'
import SummonOverlay from '@behavehealth/components/SummonOverlay'

import DestructionLogs from './medications/destruction_logs'
import IncidentLogs from './medications/incident_logs'
import MedicationLogs from './medications/medication_logs'

import { MedicationLogWorksheetOverlay } from '@behavehealth/constructs/Medications/MedicationLogWorksheetOverlay'
import { MedicationsDataTable } from '@behavehealth/constructs/Medications/MedicationsDataTable'

const pageConfig = {
  feature: 'medications',
  help: <HelpTagIframe id="medication_list_and_logs" />,
  marketingID: 'medication_list_and_logs',
}

type Props = {
  canCreate: boolean
}

const Medications: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { tenant } = useSettings()

  const { mutate: deleteRecords } = useDelete({
    name: ['client', resource_id, 'meds'],
    url: '/meds',
    invalidate: 'meds',
  })

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const [medLogsData, setMedLogsData] = React.useState<any>(null)

  const tableProps = useDataTable({
    name: ['client', resource_id, 'meds'],
    endpoint: `/residents/${resource_id}/meds`,
    localStorageKey: 'client_meds_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${match.url}/${rowData.id}`, [match.url])

  const isEmpty = size(tableProps.data) === 0
  const usesERX = tenant?.feature_flags?.erx === true

  const addAction = (
    <Dropdown testKey="add_medication_dropdown" label="Add Medication…" glyph="add" buttonType="primary" permission="medications.create">
      <DropdownItem
        testKey="add_medication_dropdown_item"
        label="Add Medication"
        icon="med_pass"
        color="red"
        link={{
          pathname: `${match.url}/new`,
          data: {
            resident: client,
          },
        }}
      />
      <DropdownItem
        testKey="add_many_medications_dropdown_item"
        label="Add Many Medications…"
        glyph="stack"
        color="red"
        link={`${match.url}/new-batch`}
        featureFlagV2="bulk_add"
      />
    </Dropdown>
  )

  return (
    <Page
      actions={
        canCreate && (
          <>
            <Dropdown
              testKey="log_medication_dropdown"
              label="Log Medication…"
              glyph="add"
              buttonType="default"
              permission="medication_logs.create"
            >
              <DropdownItem
                testKey="add_medication_log"
                label="Add Medication Log"
                icon="med_pass"
                color="red"
                link={{ pathname: `${match.url}/logs/new`, parent: match }}
              />
              <DropdownItem
                testKey="add_many_medication_log"
                label="Add Many Medication Logs…"
                glyph="stack"
                color="red"
                link={`${match.url}/logs/new-batch`}
                featureFlagV2="bulk_add"
              />
            </Dropdown>

            <Button
              testKey="log_destruction_button"
              glyph="add"
              label="Log Destruction"
              link={{ pathname: `${match.url}/destruction-logs/new`, parent: match }}
              permission="medication_destruction_logs.create"
            />

            <Button
              testKey="log_incident_button"
              glyph="add"
              label="Log Incident"
              link={{ pathname: `${match.url}/incident-logs/new`, parent: match }}
              permission="medication_incident_logs.create"
            />

            {!usesERX && addAction}
          </>
        )
      }
      {...pageConfig}
    >
      <Chotomate name="medications" ready={!tableProps.isLoading} />

      <PageLayout>
        <EPrescribingAccess tenant={tenant} client={client} />

        <Card>
          {!isEmpty && (
            <CardHeader graphic={<Icon icon="medications" size={20} />}>
              <CardTitle title="Medication List" />
            </CardHeader>
          )}

          <CardContent className="!p-0" showDivider={!isEmpty}>
            <MedicationsDataTable
              {...tableProps}
              to={to}
              testKey="medications_table"
              asCard={false}
              hiddenColumnIds={['resident']}
              headerLinksConfig={[
                {
                  type: 'report',
                  to: '/reports/medications',
                },
              ]}
              logLink={(medication: any) => ({
                pathname: `${match.url}/logs/new`,
                data: {
                  med: { ...medication, description: titleCase(medication.status) },
                  dosage: medication.dosage,
                  unit: medication.unit,
                },
                parent: match,
              })}
              logDestructionLink={(medication: any) => ({
                pathname: `${match.url}/destruction-logs/new`,
                data: {
                  med: { ...medication, description: titleCase(medication.status) },
                },
                parent: match,
              })}
              batchActionsConfig={[
                {
                  label: (selectedCount: number) => `Log Medications${selectedCount ? ` (${selectedCount})` : ''}`,
                  permission: 'medication_logs.create',
                  keepSelected: true,
                  glyph: 'add',
                  buttonType: 'primary',
                  action: ({ selectedRows }: any) => {
                    const rowsData = selectedRows.map((med: any) => ({
                      resident: med.resident,
                      med: med,
                      unit: med.unit,
                      dosage: med.dosage,
                      latest_remaining_units: med.latest_remaining_units,
                      latest_administered_at: med.latest_administered_at,
                      default_quantity: med.default_quantity,
                    }))

                    setMedLogsData(rowsData)
                  },
                },
                {
                  type: 'delete',
                  permission: 'medications.delete',
                  action: async ({ ids }: any) => {
                    await deleteRecords(ids.join(','))
                  },
                },
              ]}
            />
          </CardContent>

          <SummonOverlay
            isOpen={!!medLogsData}
            onClose={() => setMedLogsData(null)}
            overlay={<MedicationLogWorksheetOverlay initialData={medLogsData} invalidateKeys={['meds']} />}
          />
        </Card>

        <Tabs defaultTab="logs">
          <TabList className="!mb-4">
            <Tab label="Medication Logs" name="logs" permission="medication_logs.view" featureFlagV2="medication_logs" />
            <Tab
              testKey="destruction_logs_tab"
              label="Destruction Logs"
              name="destruction-logs"
              permission="medication_destruction_logs.view"
              featureFlagV2="medication_destruction_logs"
            />
            <Tab
              label="Incident Logs"
              name="incident-logs"
              permission="medication_incident_logs.view"
              featureFlagV2="medication_incident_logs"
            />
          </TabList>

          <TabPanels>
            <TabPanel name="logs" type="mount">
              <Grid>
                <MedicationLogs canCreate={canCreate} />
              </Grid>
            </TabPanel>

            <TabPanel name="destruction-logs" type="mount">
              <Grid>
                <DestructionLogs canCreate={canCreate} />
              </Grid>
            </TabPanel>

            <TabPanel name="incident-logs" type="mount">
              <Grid>
                <IncidentLogs canCreate={canCreate} />
              </Grid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PageLayout>
    </Page>
  )
}

export default withPageError(withMarketing(Medications, pageConfig))
